import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { RootState } from "../../redux/store";
import {
  getAllInitialShips,
  getEvents,
  getObjectsForShips,
  getSatelliteImage,
  getShipAttributes,
  getShipInfo,
  getShipObject,
  getShipPath,
  getSimilarShips,
} from "./RenderShips/apiCalls";
import {
  changeIntervalStatus,
  setChangeSaved,
  setDetections,
} from "./Theia.store";
import MapboxRender from "./RenderShips/MapboxRender";
import DeckglRender from "./RenderShips/DeckglRender";
import { api } from "../../services/api";
import {
  changeLoaderState,
  setMapIntervalID,
} from "../../redux/globalState.store";
import { updateAllProjects } from "../../common/Common.store";
import {
  setIsDashboard,
  setIsDashboardClick,
  setIsSaveMap,
} from "../../layout/Header/Header.store";
import { setIsFileAdded } from "./MainMap.store";
import { filterShips, getDeckGLLayers } from "./RenderShips/deckglLayers";
import FilterArea, {
  ICurrentEventTypeFilterOptions,
} from "./MapComponents/FilterArea/FilterArea";
import RightNavigationBar from "./RightNavigationBar/RightNavigationBar";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Snackbar,
} from "@mui/material";
import {
  CalendarTodayOutlined,
  ContentCopyOutlined,
  EditOutlined,
  LanguageOutlined,
  MoreHorizOutlined,
  SaveOutlined,
  ShareOutlined,
  VisibilityOutlined,
  SignalCellularAltOutlined,
  Publish,
  FileDownload,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
import Sidebar from "../../components/Sidebar";
import Drawer from "../../components/Drawer";
import WorldNews from "../../assets/icons/WorldNews";
import Shapes from "../../assets/icons/Shapes";
import TrendingShip from "../../assets/icons/TrendingShip";
import TrendingPorts from "../../assets/icons/TrendingPorts";
import Satellite from "../../assets/icons/Satellite";
import MyShips from "./SidebarComponents/MyShips";
import ShipDetails, { EventData } from "./ShipDetails/ShipDetails";
import MainSearch from "./Search/MainSearch";
import axios from "axios";
import ShipTools from "./ShipTools/ShipTools";
import PolygonShipAnalysis from "./PolygonShipAnalysis/PolygonShipAnalysis";
import { ShipSvgType } from "../../utils/ShipSvgs";
import PolygonShipAnalysisMinimize from "./PolygonShipAnalysis/PolygonShipAnalysisMinimize/PolygonShipAnalysisMinimize";
import EventsIcon from "../../assets/icons/Events";
import MyEvents from "./SidebarComponents/MyEvents";
import styles from "./MainMap.module.scss";
import MainMapHeaderShipCounts from "./MainMapShipCounts";
import moment from "moment";
import DropPin from "./MapComponents/DropPIn/DropPin";
import * as XLSX from "xlsx";
import useSatelliteImageTimeline from "./ShipDetails/hooks/useSatelliteImageTimeline";
import { getShipStatusByType, updateActiveStatus } from "./utils";
import useShipIconClick from "./hooks/useShipIconClick";
import useFireBase from "../../context/useFireBase";
import useShipDetails, {
  BunkeringInterface,
  ShipDetailsInterFace,
} from "../../context/useShipDetails";
import useCurrentMapState from "../../context/useCurrentMapState";
import useGlobalFilters from "../../context/useGlobalFilter";

const REACT_APP_BASE_URL =
  process.env.REACT_APP_ENV === "dev"
    ? "https://theia-web-api-j75nm34yfa-uc.a.run.app/"
    : "https://theia-web-api-y5rlib2gfa-uc.a.run.app";
console.log(`REACT_APP_BASE_URL: ${REACT_APP_BASE_URL}`);

export const MapContext = React.createContext({} as any);

export enum RightSideNavigationSelectedFilter {
  Ships = "ships",
  Layers = "layers",
}

export interface ShipImages {
  object_id: number;
  image: string;
  longitude?: number;
  latitude?: number;
  acquired?: number;
  id: string;
  name: string;
}

export interface DateRange {
  startDate: Date;
  endDate: Date;
}
export interface ShipCountsInView {
  total: number;
  ais: number;
  light: number;
  dark: number;
  unattributed: number;
  spoofing: number;
  bunkering: number;
  sanctioned: number;
}

export interface PathData {
  synmax_ship_id: string;
  timestamp: number;
  heading: number | null;
  speed: number;
  longitude: number;
  latitude: number;
}

export interface FilterLayer {
  id: string;
  label: string;
  value: any;
  onChange: () => void;
}

interface DynamicData {
  synmax_ship_id: string;
  timestamp: number;
  mmsi?: number;
  imo?: number;
  name?: string;
  call_sign?: string;
  destination?: string;
  eta?: number;
  length?: number;
  width?: number;
  draught?: number;
}

export interface ShipDetail {
  dynamic: DynamicData[];
  [key: string]: string | number | DynamicData[] | undefined;
}

export interface ObjectData {
  object_id: number;
  attribution: string;
  source_id?: string;
  provider?: string;
  status?: string;
  acquired?: number;
  dark_time?: number;
  dark?: boolean;
  spoofing?: boolean;
  longitude?: number;
  latitude?: number;
  length?: number;
  width?: number;
  heading?: number;
  ship_type?: string;
  moving?: boolean;
  sts?: boolean;
  aoi?: string | null;
}
export interface AISDarkEvent {
  latitude_t0: number;
  latitude_t1: number;
  longitude_t0: number;
  longitude_t1: number;
  synmax_ship_id: string;
}

const MainMap = () => {
  const { setSatelliteImageTimeline, setIsSatelliteImageLoading } =
    useSatelliteImageTimeline();

  const {
    zoomLevel,
    setZoomLevel,
    mapLatLng,
    setMapLatLng,
    loading,
    setMapViewState,
    mapViewState,
    setMapSnapshot,
    setDeckCanvas,
    deckCanvas,
    mapRef,
  } = useCurrentMapState();
  const [searchIsExpanded, setSearchIsExpanded] = useState<boolean>(false);
  const [showGlobeView, setShowGlobeView] = useState(false);
  const [currentEventTypeFilterOptions, setCurrentEventTypeFilterOptions] =
    useState<ICurrentEventTypeFilterOptions>({});
  const [currentCoveraData, setCurrentCoverageData] = useState<any>(null);
  const token = localStorage.getItem("token");
  const [selectedShape, setSelectedShape] = useState<ShipSvgType | null>(null);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: new Date(new Date().getTime() - 60 * 60 * 1000),
    endDate: new Date(),
  });
  const [features, setFeatures] = useState<{ data: any; count: number }>({
    data: {},
    count: 0,
  });
  const { saveStateToFirebase, hanldeSetDataFromFirebase } = useFireBase(
    features,
    setFeatures
  );
  const [showShips, setShowShips] = useState(true);
  const [showSimilarShips, setShowSimilarShips] = useState(true);

  const [filteredDetection, setFilteredDetection] = useState<any>({
    filteredDarkShips: [],
    filteredLightShips: [],
    filteredSpoofingShips: [],
    filteredUnattributedShips: [],
  });
  const [shipCountsInCurrentView, setShipCountsInCurrentView] =
    useState<ShipCountsInView>({
      total: 0,
      ais: 0,
      light: 0,
      dark: 0,
      unattributed: 0,
      spoofing: 0,
      bunkering: 0,
      sanctioned: 0,
    });
  const [droppedPins, setDroppedPins] = useState<
    {
      lat: number;
      lng: number;
      title: string;
    }[]
  >([]);
  const {
    slider: sliderforPolygonShipAnalysis,
    layers: layerforPolygonShipAnalysis,
    isOpenPolygonShipAnalysisModal,
  } = useSelector((state: RootState) => state.polygonShipAnalysis);
  const [shipCountsAreShown, setShipCountsAreShown] = useState<boolean>(false);
  const [showDeckglPolygons, setShowDeckglPolygons] = useState(true);
  const [liveAISShips, setLiveAISShips] = useState([]);
  const [isLiveShipLoading, setIsLiveShipLoading] = useState(false);
  const { layerToggleSwitches, setShowCursor, onLayerToggle, date, setDate } =
    useGlobalFilters();

  useEffect(() => {
    getLiveAISShips();
  }, [zoomLevel, mapLatLng]);

  const getLiveAISShips = async () => {
    if (
      date &&
      date.format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD") &&
      currentEventTypeFilterOptions?.AIS?.checked
    ) {
      if (zoomLevel > 9 && !isLiveShipLoading) {
        setIsLiveShipLoading(true);
        if (mapRef.current) {
          const { _sw: _southWest, _ne: _northEast } = mapRef.current
            .getMap()
            .getBounds();
          const { lat: lat1, lng: lng1 } = _northEast;
          const { lat: lat2, lng: lng2 } = _southWest;
          const coordinates = [
            [
              [lng2, lat1],
              [lng1, lat1],
              [lng1, lat2],
              [lng2, lat2],
              [lng2, lat1],
            ],
          ];

          try {
            const liveShipsRes = await api.post(
              "/ship-tools/live-area",
              coordinates
            );
            setLiveAISShips(liveShipsRes.data);
          } catch (error) {
            console.error("Failed to fetch live ships:", error);
          }
        } else {
          console.warn("Map reference is not available.");
        }
        setIsLiveShipLoading(false);
      }
    }
  };

  const filterLayers: FilterLayer[] = [
    {
      id: "layer1",
      label: "Territories",
      value: layerToggleSwitches.territories,
      onChange: () => onLayerToggle("territories"),
    },
    {
      id: "layer2",
      label: "Ports",
      value: layerToggleSwitches.ports,
      onChange: () => onLayerToggle("ports"),
    },
    {
      id: "layer3",
      label: "Infrastructure",
      value: layerToggleSwitches.infrastructure,
      onChange: () => {
        onLayerToggle("infrastructure");
      },
    },
    {
      id: "layer4",
      label: "Submarine Cables",
      value: layerToggleSwitches.submarineCablesVisible,
      onChange: () => onLayerToggle("submarineCablesVisible"),
    },
    {
      id: "layer5",
      label: "Coverage",
      value: layerToggleSwitches.coverage,
      onChange: () => onLayerToggle("coverage"),
    },
    {
      id: "layer6",
      label: "High Risk Areas",
      value: layerToggleSwitches.highRisk,
      onChange: () => onLayerToggle("highRisk"),
    },
  ];

  function handleSearchExpand(searchIsExpanded: boolean): void {
    setSearchIsExpanded(searchIsExpanded);
    if (searchIsExpanded) {
      setShipCountsAreShown(false);
    }
  }

  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  const {
    detections,
    intervalIsSet,
    changesSaved,
    spoofingEvents,
    AISSTS,
    opticalSTS,
    sanctionedShips,
  } = useSelector((state: RootState) => state.theia);

  const { tabs, selectedTabId, isSaveMap, prevTabId, isDashboardClick } =
    useSelector((state: RootState) => state.header);
  const { mapIntervalId } = useSelector(
    (state: RootState) => state.globalTheiaState
  );
  const {
    isFileAdded,
    fileData,
    loading: saveFileLoading,
    minimizePolygonModal,
  } = useSelector((state: RootState) => state.mainMap);
  const { allProjects } = useSelector((state: RootState) => state.common);
  const { futurePathData, slider, layers } = useSelector(
    (state: RootState) => state.futurePathPrediction
  );

  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [currentSelectedFilter, setCurrentSelectedFilter] =
    useState<RightSideNavigationSelectedFilter | null>(null);

  useEffect(() => {
    const abortController = new AbortController();

    const getMapCoverage = async () => {
      try {
        const res = await axios.get(
          `${REACT_APP_BASE_URL}/v1/images/coverage?date=${date?.format(
            "YYYY-MM-DD"
          )}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
            signal: abortController.signal,
          }
        );
        const geojsonData = {
          type: "FeatureCollection",
          features: res.data.coverage.map((feature: any) => ({
            type: "Feature",
            properties: { ...feature },
            geometry: feature.geometry,
          })),
        };

        setCurrentCoverageData(geojsonData);
      } catch (error: any) {
        console.log("error:", error?.message);
      }
    };
    if (date) {
      getMapCoverage();
    }

    return () => {
      abortController.abort();
    };
  }, [date]);

  const menuRef = useRef<HTMLDivElement>(null);
  const {
    detailsTabs,
    setDetailsTabs,
    shipDetailTabValue,
    setShipDetailTabValue,
    selectedShip,
    setSelectedShip,
    shipDetails,
    setShipDetails,
    selectedEvent,
    setSelectedEvent,
    shipPaths,
    setShipPaths,
    handleShipDetailsTabChange,
    shipObjects,
    setShipObjects,
    bunkeringValue,
    setBunkeringValue,
    fetchAndSetShipPath,
    handleShipDetailsTabClose,
    setDarkEventOnMap,
    darkEventOnMap,
    collapsed,
    setSidebarCollapsed,
    shipCollapsed,
    setShipCollapsed,
    active,
    setActive,
  } = useShipDetails();

  const [workStationMode, setWorkStationMode] = useState("");
  const [sanctionVessels, setSanctionVessels] = useState<string[]>([]);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showTemporaryCircle, setShowTemporaryCircle] = useState(false);
  const uploadInputRef = useRef<any>(null);
  const [layersForFuturePathPrediction, setLayersForFuturePathPrediction] =
    useState<any>([]);
  // TODO: fix any type
  const [
    selectedShipEstimatedaLocationPath,
    setSelectedShipEstimatedaLocationPath,
  ] = useState<any>([]);
  const [estimatedLocationNotFound, setEstimatedLocationNotFound] =
    useState<boolean>(false);

  const [
    coordinatesForEstimatedLocationInfo,
    setCoordinatesForEstimatedLocationInfo,
  ] = useState<[number, number] | null>(null);

  const handleSetCoordinatesForEstimatedLocationInfo = (
    coordinatesForInfo: [[number, number], [number, number]]
  ): void => {
    const midPontLattitude =
      (coordinatesForInfo[0][0] + coordinatesForInfo[1][0]) / 2;
    const midPontLongtitude =
      (coordinatesForInfo[0][1] + coordinatesForInfo[1][1]) / 2;

    setCoordinatesForEstimatedLocationInfo([
      midPontLattitude,
      midPontLongtitude,
    ]);
  };

  const [
    selectedShipEstimatedLoactionGEOJSON,
    setSelectedShipEstimatedLocationGEOJSON,
  ] = useState<{ startDate: string; data: any }[]>([]);

  const [
    selectedShipEstimatedLoactionLayer,
    setSelectedShipEstimatedLocationLayer,
  ] = useState(null);

  useEffect(() => {
    if (
      selectedShipEstimatedaLocationPath.length > 0 &&
      selectedShipEstimatedLoactionLayer !== null
    ) {
      handleSetCoordinatesForEstimatedLocationInfo(
        selectedShipEstimatedaLocationPath
      );
    }
  }, [selectedShipEstimatedaLocationPath, selectedShipEstimatedLoactionLayer]);

  const handleEstmatedShipLayerSelect = (date: string) => {
    const selcetedShipLayer = selectedShipEstimatedLoactionGEOJSON.find(
      (layerData: any) =>
        moment(layerData?.startDate).format("MM/DD/YYYY") === date
    );
    // TODO fix starting point. This is currently using the most outward coordinates of the polygon, Need to add additional parsing to calculate the
    // center point of the coordinates with the highest confidence score
    let startingPoint =
      selcetedShipLayer?.data?.features[0].geometry.coordinates[0][0];

    if (selcetedShipLayer?.data?.features[0].geometry.type === "MultiPolygon") {
      startingPoint =
        selcetedShipLayer?.data?.features[0].geometry.coordinates[0][0][0];
    }

    let shipforLatLng =
      shipDetailTabValue && (selectedShip[shipDetailTabValue] as any);

    if (shipDetailTabValue && selectedShip[shipDetailTabValue] === undefined) {
      shipforLatLng = selectedEvent[shipDetailTabValue];
    }
    if (
      shipDetailTabValue &&
      startingPoint !== undefined &&
      startingPoint[0] !== undefined &&
      startingPoint[1] !== undefined
    ) {
      setSelectedShipEstimatedaLocationPath([
        [startingPoint[0], startingPoint[1]],
        [shipforLatLng.longitude, shipforLatLng.latitude],
      ]);
    }

    if (selcetedShipLayer === null) {
      setEstimatedLocationNotFound(false);
    }
    console.log(selcetedShipLayer?.data, ":final data point");
    setSelectedShipEstimatedLocationLayer(selcetedShipLayer?.data);
  };

  const [sliderValue, setSliderValue] = useState<number>(0);
  const pathRef = useRef<number | null>(null);
  const [showDropPinDialog, setshowDropPinDialog] = useState(false);
  const drawRef = useRef(null) as any;
  const [mapStyle, setMapStyle] = useState("mapbox://styles/mapbox/light-v11");
  const [extendedPathLoading, setExtendedPathLoading] = useState(false);
  const [similarShips, setSimilarShips] = useState<{
    [key: string]: ShipDetailsInterFace[];
  }>({});
  const [hoveredId, setHoveredId] = useState<number | null>(null);

  const { analyzeShape } = useSelector(
    (state: RootState) => state.polygonShipAnalysis
  );

  const [lastSelectedOption, setLastSelectedOption] = useState(
    analyzeShape.length > 0 ? analyzeShape.at(-1).value : ""
  );

  useEffect(() => {
    if (analyzeShape.length > 0) {
      setLastSelectedOption(analyzeShape.at(-1).value);
    }
  }, [analyzeShape]);

  const area = features?.data?.[lastSelectedOption]?.geometry;

  useEffect(() => {
    if (allProjects && tabs && selectedTabId && tabs[selectedTabId]?.folderId) {
      const folder = allProjects[tabs[selectedTabId].folderId];
      const workStationId = tabs[selectedTabId].workStationId;

      if (
        folder &&
        folder.workStations &&
        workStationId in folder.workStations
      ) {
        const mode = folder.workStations[workStationId]?.mode;
        setWorkStationMode(mode);
      }
    }
  }, [allProjects, tabs, selectedTabId]);

  const handleTableNameClick = (
    shipId: string,
    type: string,
    tabIndex?: string
  ) => {
    onShipClick();
    setShipDetailTabValue(shipId);
    if (tabIndex) {
      setBunkeringValue(() => tabIndex);
    }
    setSelectedShip((prevSelectedShip) => ({
      ...prevSelectedShip,
      ...updateActiveStatus(prevSelectedShip, shipId),
    }));
    setSelectedEvent((prevSelectedEvents) => ({
      ...prevSelectedEvents,
      ...updateActiveStatus(prevSelectedEvents, shipId),
    }));
  };

  const getEventDetails = (
    eventType: string
  ): {
    name: string;
    color: string;
  } => {
    const eventData: {
      [key: string]: {
        name: string;
        color: string;
      };
    } = {
      light: {
        name: "Light",
        color: currentEventTypeFilterOptions?.light?.markerColor,
      },
      unattributed: {
        name: "Unattributed",
        color: currentEventTypeFilterOptions?.unattributed?.markerColor,
      },
      attributed: {
        name: "Dark",
        color: currentEventTypeFilterOptions?.dark?.markerColor,
      },
      dark: {
        name: "Dark",
        color: currentEventTypeFilterOptions?.dark?.markerColor,
      },
      spoofing: {
        name: "Spoofing",
        color: currentEventTypeFilterOptions?.spoofing?.markerColor,
      },
      sanctioned: {
        name: "Sanctioned",
        color: currentEventTypeFilterOptions?.sanctioned?.markerColor,
      },
      AISSTS: {
        name: "Bunkering",
        color: currentEventTypeFilterOptions?.AIS?.markerColor,
      },
      opticalSTS: {
        name: "Bunkering",
        color: currentEventTypeFilterOptions?.bunkering?.markerColor,
      },
      AIS: {
        name: "AIS",
        color: currentEventTypeFilterOptions?.AIS?.markerColor,
      },
    };

    const event = eventData[eventType];
    if (!event) return { name: "Unknown", color: "rgb(0, 0, 0)" };
    return {
      name: event.name,
      color: event.color,
    };
  };

  const myEvents: {
    [key: string]: ShipDetailsInterFace | BunkeringInterface;
  } = {
    ...selectedEvent,
    ...Object.fromEntries(
      Object.entries(selectedShip).filter(
        ([key, value]) => value.type === "opticalSTS" || value.type === "AISSTS"
      )
    ),
  };
  const menuList = [
    {
      id: "Trending Ship",
      icon: <TrendingShip />,
      component: (
        <MyShips
          ships={selectedShip}
          handleTableRowClick={handleTableNameClick}
          getEventDetails={getEventDetails}
        />
      ),
      disabled: false,
    },
    {
      id: "My Events",
      icon: <EventsIcon />,
      component: (
        <MyEvents
          events={myEvents}
          handleTableRowClick={handleTableNameClick}
          getEventDetails={getEventDetails}
        />
      ),
      disabled: false,
    },
    {
      id: "World News",
      icon: <WorldNews />,
      component: <Box sx={{ padding: "15px 5px" }}>World News</Box>,
      disabled: true,
    },
    {
      id: "My Ports",
      icon: <TrendingPorts />,
      component: <Box sx={{ padding: "15px 5px" }}>My Ports</Box>,
      disabled: true,
    },

    {
      id: "My Shapes",
      icon: <Shapes />,
      component: <Box sx={{ padding: "15px 5px" }}>My Shapes</Box>,
      disabled: true,
    },
    {
      id: "Satellite",
      icon: <Satellite />,
      component: <Box sx={{ padding: "15px 5px" }}>My Satellite Images</Box>,
      disabled: true,
    },
  ];

  useEffect(() => {
    if (
      Object.keys(tabs).length > 0 &&
      selectedTabId &&
      tabs?.[selectedTabId]
    ) {
      const { folderId, workStationId } = tabs[selectedTabId];
      if (folderId && workStationId && allProjects[folderId]) {
        const folder = allProjects[folderId];
        if (folder.workStations && folder.workStations[workStationId]) {
          const mapFiltersRedux =
            folder.workStations[workStationId].mapState?.mapFilters;
          setCurrentEventTypeFilterOptions(mapFiltersRedux);
          if (Object.keys(tabs).length > 0) {
            hanldeSetDataFromFirebase();
          }
        }
      }
    }
  }, [selectedTabId, tabs]);

  useEffect(() => {
    const token = localStorage.getItem("token") as string;

    if (detections && !intervalIsSet) {
      console.log("setting interval");
      const intervalInstance = setInterval(function () {
        getAllInitialShips(token, setDetections, dispatch, date, false);
      }, 50000);
      dispatch(changeIntervalStatus(true));
      dispatch(setMapIntervalID(intervalInstance));
    }
  }, [detections]);

  useEffect(() => {
    const token = localStorage.getItem("token") as string;
    mapIntervalId && clearInterval(mapIntervalId);
    dispatch(changeIntervalStatus(false));
    date && getAllInitialShips(token, setDetections, dispatch, date, true);
  }, [date]);

  useEffect(() => {
    if (selectedTabId && !prevTabId) {
      mapIntervalId && clearInterval(mapIntervalId);
      dispatch(changeIntervalStatus(false));
      dispatch(setDetections(null));
      if (Object.keys(tabs).length > 0) {
        hanldeSetDataFromFirebase();
      }
    }
  }, [selectedTabId, prevTabId]);

  useEffect(() => {
    if (
      isFileAdded &&
      fileData.folderId &&
      fileData.workStationId &&
      fileData.thumbnail
    ) {
      const updatedProjects = {
        ...allProjects,
        [fileData.folderId]: {
          ...allProjects[fileData.folderId],
          workStations: {
            ...allProjects[fileData.folderId].workStations,
            [fileData.workStationId]: {
              ...allProjects[fileData.folderId].workStations[
                fileData.workStationId
              ],
              thumbnail: fileData.thumbnail,
            },
          },
        },
      };
      dispatch(updateAllProjects(updatedProjects));
      dispatch(setIsFileAdded(false));
      if (isDashboardClick) {
        dispatch(setIsDashboard(true));
        dispatch(setIsDashboardClick(false));
      }
    }
  }, [isFileAdded, allProjects, dispatch, fileData, isDashboardClick]);

  const downloadFile = ({ data, fileName, fileType }: any) => {
    const blob = new Blob([data], { type: fileType });
    const mapElement = document.createElement("a");
    mapElement.download = fileName;
    mapElement.href = window.URL.createObjectURL(blob);
    const uploadEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    mapElement.dispatchEvent(uploadEvent);
    mapElement.remove();
  };

  const exportToJson = (data: any, folderId: string, workStationId: string) => {
    const workStationName =
      allProjects[folderId].workStations[workStationId].name;
    downloadFile({
      data: JSON.stringify(data),
      fileName: `${workStationName}.json`,
      fileType: "text/json",
    });
  };

  const downloadStateInJson = () => {
    const currentTab = tabs[prevTabId ? prevTabId : selectedTabId];
    const { folderId, workStationId } = currentTab;
    const mapState = {
      ...allProjects[folderId].workStations[workStationId].mapState,
      showGlobeView,
      zoomLevel,
      mapLatLng,
      date: date?.format("YYYY-MM-DD"),
    };
    exportToJson(mapState, folderId, workStationId);
  };

  const uploadMapState = (e: any) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      const mapState: string = e?.target?.result as string;
      const mapStateObj = JSON.parse(mapState);
      setShowGlobeView(mapStateObj.showGlobeView);
      setZoomLevel(mapStateObj.zoomLevel);
      setMapViewState({
        latitude: mapStateObj.mapLatLng.lat,
        longitude: mapStateObj.mapLatLng.lng,
        zoom: mapStateObj.zoomLevel,
      });
    };
  };

  useEffect(() => {
    if (isSaveMap) {
      saveStateToFirebase();
    }

    dispatch(setIsSaveMap(false));
  }, [dispatch, isSaveMap, saveStateToFirebase]);

  const onShipClick = (tabId?: string) => {
    if (!shipCollapsed) setShipCollapsed(true);
    if (tabId) {
      handleShipDetailsTabChange(undefined, tabId);
    }
  };

  useEffect(() => {
    setLayersForFuturePathPrediction(layers[slider] ? layers[slider] : []);
  }, [slider, futurePathData]);
  const [colorArr, setColorArr] = useState<string[]>([]);
  const getRandomColor = (): string => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    const c = color.substring(1); // strip #
    const rgb = parseInt(c, 16); // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    if (luma < 40) {
      return getRandomColor();
    } else {
      return color;
    }
  };
  const getUniqueColor = (): string => {
    let candidateColor = "";
    const maxIterations = 20;
    let iterationCounter = 0;
    do {
      candidateColor = getRandomColor(); // get a new random color
    } while (
      iterationCounter++ < maxIterations &&
      colorArr.includes(candidateColor)
    ); // repeat if the color is already used
    setColorArr((pastColors: string[]) => {
      const newColors = [...pastColors, candidateColor];
      return newColors;
    });
    return candidateColor || "#ccc"; // Return the color that has not been used already or a default color
  };

  const selectedPath = useMemo(() => {
    if (!shipDetailTabValue) return [];

    if (
      shipDetailTabValue.includes("_") &&
      (!selectedShip[shipDetailTabValue]?.isObservationShip ||
        (selectedShip[shipDetailTabValue]?.isObservationShip &&
          (selectedShip[shipDetailTabValue]?.type === "opticalSTS" ||
            selectedShip[shipDetailTabValue]?.type === "AISSTS")))
    ) {
      const shipIds = selectedShip[shipDetailTabValue];
      const { synmax_ship_id_1 = "", synmax_ship_id_2 = "" } = shipIds || {};
      return bunkeringValue === "1"
        ? shipPaths[synmax_ship_id_1]?.path || []
        : shipPaths[synmax_ship_id_2]?.path || [];
    }
    return shipPaths[shipDetailTabValue]?.path || [];
  }, [shipPaths, shipDetailTabValue, bunkeringValue, selectedShip]);

  const {
    handleShipIconClick,
    shipDetailsTabLoading,
    setShipDetailsTabLoading,
  } = useShipIconClick(
    setShipDetailTabValue,
    setSelectedShip,
    setSelectedEvent,
    setShipDetails,
    getShipInfo,
    fetchAndSetShipPath,
    getUniqueColor,
    onShipClick
  );

  const deckGlLayers = getDeckGLLayers(
    detections,
    zoomLevel,
    spoofingEvents,
    opticalSTS,
    AISSTS,
    currentEventTypeFilterOptions,
    sanctionedShips,
    setSelectedShip,
    shipDetailTabValue,
    setShipDetailTabValue,
    selectedShip,
    setShowCursor,
    currentCoveraData,
    layerToggleSwitches,
    setSelectedEvent,
    selectedEvent,
    onShipClick,
    shipPaths,
    selectedPath,
    sliderValue,
    pathRef,
    shipObjects,
    similarShips,
    showSimilarShips,
    area,
    hoveredId,
    droppedPins,
    features.data,
    darkEventOnMap,
    showTemporaryCircle,
    coordinatesForEstimatedLocationInfo,
    handleShipIconClick,
    layersForFuturePathPrediction,
    drawRef,
    showDeckglPolygons,
    setShowDeckglPolygons,
    showGlobeView,
    layerforPolygonShipAnalysis[sliderforPolygonShipAnalysis],
    showShips,
    selectedShipEstimatedLoactionLayer,
    selectedShipEstimatedaLocationPath,
    liveAISShips
  );
  useEffect(() => {
    if (currentEventTypeFilterOptions && detections) {
      const { spoofing, light, dark, unattributed } =
        currentEventTypeFilterOptions;
      const filteredLightShips = filterShips(
        detections?.lightGeoJson,
        light,
        "light",
        true
      );

      const filteredDarkShips = filterShips(
        detections?.attributedGeoJson,
        dark,
        "dark",
        true
      );
      const filteredSpoofingShips = filterShips(
        spoofingEvents,
        spoofing,
        "spoofing",
        true
      );
      const filteredUnattributedShips = filterShips(
        detections?.unattributedGeoJson,
        unattributed,
        "unattributed",
        true
      );
      setFilteredDetection({
        filteredDarkShips,
        filteredLightShips,
        filteredSpoofingShips,
        filteredUnattributedShips,
      });
    }
  }, [currentEventTypeFilterOptions, detections, spoofingEvents]);
  const calculateShipCounts = (filterToApply: (ship: any) => any): void => {
    const filteredLightDetections = currentEventTypeFilterOptions?.light
      ?.checked
      ? filteredDetection?.filteredLightShips.filter(filterToApply).length
      : 0;
    const filteredUnattributed = currentEventTypeFilterOptions?.unattributed
      ?.checked
      ? filteredDetection?.filteredUnattributedShips.filter(filterToApply)
          .length
      : 0;
    const sanctionedShipsInView = currentEventTypeFilterOptions?.sanctioned
      ?.checked
      ? sanctionedShips.features.filter(filterToApply).length
      : 0;
    const spoofingShipsInCurrentView = currentEventTypeFilterOptions?.spoofing
      ?.checked
      ? filteredDetection?.filteredSpoofingShips.filter(filterToApply).length
      : 0;
    const bunkeringAISShipsIncCurrentView = currentEventTypeFilterOptions?.AIS
      ?.checked
      ? AISSTS.features.filter(filterToApply).length
      : 0;
    const darkShipsInCurrentView = currentEventTypeFilterOptions?.dark?.checked
      ? filteredDetection?.filteredDarkShips.filter(filterToApply).length
      : 0;
    const opticalSTSBunnkeringInCurrentView = currentEventTypeFilterOptions
      ?.bunkering?.checked
      ? opticalSTS.features.filter(filterToApply).length
      : 0;

    const totalShipsInCurrentView =
      filteredLightDetections +
      filteredUnattributed +
      sanctionedShipsInView +
      spoofingShipsInCurrentView +
      bunkeringAISShipsIncCurrentView +
      darkShipsInCurrentView +
      opticalSTSBunnkeringInCurrentView;

    setShipCountsInCurrentView({
      total: totalShipsInCurrentView,
      light: filteredLightDetections,
      unattributed: filteredUnattributed,
      sanctioned: sanctionedShipsInView,
      spoofing: spoofingShipsInCurrentView,
      bunkering: opticalSTSBunnkeringInCurrentView,
      ais: bunkeringAISShipsIncCurrentView,
      dark: darkShipsInCurrentView,
    });
  };

  useEffect(() => {
    if (
      showGlobeView &&
      detections &&
      sanctionedShips &&
      spoofingEvents &&
      AISSTS &&
      opticalSTS
    ) {
      calculateShipCounts((ship: any) => ship);
    }
  }, [
    detections,
    sanctionedShips,
    spoofingEvents,
    AISSTS,
    opticalSTS,
    showGlobeView,
    currentEventTypeFilterOptions,
  ]);

  const mapProps = {
    zoomLevel,
    setZoomLevel,
    setMapLatLng,
    mapViewState,
    key: mapLatLng,
    selectedTabId,
    setChangeSaved,
    deckGlLayers,
    currentEventTypeFilterOptions,
    mapRef,
    setMapSnapshot,
    setDeckCanvas,
    deckCanvas,
    mapLatLng,
    detections,
    calculateShipCounts,
    coordinatesForEstimatedLocationInfo,
    droppedPins,
    setDroppedPins,
    setMapViewState,
    shipPaths,
    mapStyle,
  };

  const onSidebarClick = (index: number) => {
    setActive(index);
    if (!collapsed) setSidebarCollapsed(true);
    dispatch(setChangeSaved(true));
  };

  const handleViewExtendedPath = async (dateRange: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }) => {
    if (shipDetailTabValue === null) return;

    setExtendedPathLoading(true);
    try {
      let shipId = "";
      let currentShipObject: number = 0;
      let isBunkeringShip: boolean = false;
      let isEvent: boolean = Object.keys(selectedEvent)?.includes(
        shipDetailTabValue
      )
        ? true
        : false;
      const isObservationShip =
        selectedShip[shipDetailTabValue]?.isObservationShip;
      const isBunkering: boolean =
        selectedShip[shipDetailTabValue]?.type === "opticalSTS" ||
        selectedShip[shipDetailTabValue]?.type === "AISSTS";
      if (
        shipDetailTabValue.includes("_") &&
        !selectedShip[shipDetailTabValue]?.isObservationShip
      ) {
        const shipIds = selectedShip[shipDetailTabValue];
        shipId =
          bunkeringValue === "1"
            ? shipIds?.synmax_ship_id_1 || ""
            : shipIds?.synmax_ship_id_2 || "";
        currentShipObject = +(shipDetails?.[shipId]?.object_id || 0);
        isBunkeringShip = true;
      } else if (isObservationShip) {
        if (isBunkering) {
          const shipIds = selectedShip[shipDetailTabValue];
          shipId =
            bunkeringValue === "1"
              ? shipIds?.synmax_ship_id_1 || ""
              : shipIds?.synmax_ship_id_2 || "";
          currentShipObject = +(shipDetails?.[shipId]?.object_id || 0);
          isBunkeringShip = true;
        } else {
          shipId = shipDetailTabValue.substring(
            shipDetailTabValue.indexOf("_") + 1
          );
          currentShipObject = +(
            selectedShip?.[shipDetailTabValue]?.object_id || 0
          );
        }
      } else {
        shipId = shipDetailTabValue || "";
        if (!shipId && Object.keys(selectedShip || {}).length <= 0) return;
        currentShipObject = +(
          selectedShip?.[shipDetailTabValue]?.object_id || 0
        );
      }

      const start = moment
        .utc(`${dateRange.startDate.format("YYYY-MM-DD")} 00:00:00`)
        .format("YYYY-MM-DD HH:mm:ss");
      const end = moment
        .utc(`${dateRange.endDate.format("YYYY-MM-DD")} 23:59:59`)
        .format("YYYY-MM-DD HH:mm:ss");

      const shipPath = await getShipPath([shipId], start, end);
      const pathObjects = await getObjectsForShips([shipId], start, end);
      const eventTypes = ["optical-sts", "ais-sts", "spoofing"];
      const eventsDataArray = await Promise.all(
        eventTypes.map((type) =>
          getEvents({
            event_types: [type],
            ship_ids: [shipId],
            start_date: start,
            end_date: end,
          })
        )
      );
      const combinedEventsData = eventsDataArray.reduce((acc, curr) => {
        return { ...acc, ...curr };
      }, {});
      const spoofingEventsOnPath = Array.isArray(
        combinedEventsData?.spoofing?.results
      )
        ? combinedEventsData?.spoofing?.results.map((ship: any) => {
            const {
              synmax_ship_id,
              longitude,
              latitude,
              ship_info,
              ...restShipInfo
            } = ship;
            if (!synmax_ship_id || !longitude || !latitude) return null;
            const shipPathColor = getUniqueColor();
            return {
              ...restShipInfo,
              ...ship_info,
              synmax_ship_id,
              active: true,
              isObservationShip: true,
              longitude,
              latitude,
              type: "spoofing",
              pathColor: shipPathColor,
              isEvent: true,
            };
          })
        : [];
      const id =
        isObservationShip && !isBunkering ? shipDetailTabValue : shipId;
      setShipObjects((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          objects: [...pathObjects, ...spoofingEventsOnPath],
          isBunkeringShip,
          isEvent,
          parentShipObject: currentShipObject,
        },
      }));
      setShipPaths((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          path: shipPath,
          showPath: true,
          dateRange: { startDate: start, endDate: end },
          showShip: true,
        },
      }));
    } catch (error) {
      console.error("Error fetching ship path:", error);
    } finally {
      setExtendedPathLoading(false);
    }
  };
  const handleViewPathAnalysis = async () => {
    let shipId = "";
    if (shipDetailTabValue === null) return;
    if (
      shipDetailTabValue.includes("_") &&
      (!selectedShip[shipDetailTabValue]?.isObservationShip ||
        (selectedShip[shipDetailTabValue]?.isObservationShip &&
          (selectedShip[shipDetailTabValue]?.type === "opticalSTS" ||
            selectedShip[shipDetailTabValue]?.type === "AISSTS")))
    ) {
      const shipIds = selectedShip[shipDetailTabValue];
      shipId =
        bunkeringValue === "1"
          ? shipIds?.synmax_ship_id_1 || ""
          : shipIds?.synmax_ship_id_2 || "";
    } else {
      shipId = shipDetailTabValue;
    }

    setShipPaths((prev) => {
      const currentAnalysisState = prev[shipId]?.analysis || false;
      return {
        ...prev,
        [shipId]: {
          ...prev[shipId],
          analysis: !currentAnalysisState,
        },
      };
    });
  };

  const handleDownloadXLS = () => {
    if (!shipDetailTabValue) return;
    const shipPath: PathData[] = selectedPath;
    let shipId: string = "";
    if (
      shipDetailTabValue.includes("_") &&
      (!selectedShip[shipDetailTabValue]?.isObservationShip ||
        (selectedShip[shipDetailTabValue]?.isObservationShip &&
          (selectedShip[shipDetailTabValue]?.type === "opticalSTS" ||
            selectedShip[shipDetailTabValue]?.type === "AISSTS")))
    ) {
      const shipIds = selectedShip[shipDetailTabValue];
      const { synmax_ship_id_1 = "", synmax_ship_id_2 = "" } = shipIds || {};
      shipId = bunkeringValue === "1" ? synmax_ship_id_1 : synmax_ship_id_2;
    } else {
      shipId = shipDetailTabValue;
    }
    const ship = shipDetails[shipId];
    const { dynamic, ...shipData } = ship || {};

    const updatedShipPath = shipPath?.map((f) => ({
      ...f,
      timestamp: `${moment
        .utc(new Date(f.timestamp * 1000))
        .format("YYYY-MM-DD HH:mm:ss")}`,
    }));
    const shipSheet = updatedShipPath
      ? XLSX.utils.json_to_sheet(updatedShipPath)
      : XLSX.utils.json_to_sheet([]);

    const updatedDynamicShipData = dynamic?.map((f) => ({
      ...f,
      timestamp: `${moment
        .utc(new Date(f?.timestamp * 1000))
        .format("YYYY-MM-DD HH:mm:ss")}`,
    }));
    const dynamicShipDataSheet = updatedDynamicShipData
      ? XLSX.utils.json_to_sheet(updatedDynamicShipData)
      : XLSX.utils.json_to_sheet([]);

    const lastQuery =
      typeof shipData?.last_query === "string" ||
      typeof shipData?.last_query === "number"
        ? moment
            .utc(new Date(shipData.last_query))
            .format("YYYY-MM-DD HH:mm:ss")
        : undefined;

    const updatedStaticShipData = {
      ...shipData,
      last_query: lastQuery,
      speeds: Array.isArray(shipData?.speeds)
        ? shipData.speeds.join(",")
        : undefined,
    };

    const staticShipDataSheet = XLSX.utils.json_to_sheet([
      updatedStaticShipData,
    ]);

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, shipSheet, "Path Data");
    XLSX.utils.book_append_sheet(
      workBook,
      dynamicShipDataSheet,
      "Dynamic Data"
    );
    XLSX.utils.book_append_sheet(workBook, staticShipDataSheet, "Static Data");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    const fileName = selectedPath[0]?.synmax_ship_id;
    XLSX.writeFile(workBook, `${fileName}-pathdata.xlsx`);
  };

  const handleSatelliteImageClick = async (objectId: number, name: string) => {
    try {
      const isSimilarShip = name === "Similar Ships";
      const shipData = await getShipObject([objectId]);
      let { synmax_ship_id, timestamp, attribution, acquired, day, dark } =
        shipData;
      if (!synmax_ship_id) {
        synmax_ship_id = attribution;
      }
      const shipId: string = isSimilarShip
        ? `unattributed-${objectId}`
        : `${objectId}_${synmax_ship_id}`;
      setSelectedShip((prevShips) => ({
        ...(prevShips as { [key: string]: ShipDetailsInterFace }),
        [shipId]: {} as ShipDetailsInterFace,
      }));

      setShipDetailTabValue(shipId);

      if (!acquired) {
        acquired = timestamp * 1000;
      }
      if (!acquired && !timestamp) {
        acquired = day;
      }
      let type;
      if (attribution === "None") {
        type = "unattributed";
      } else if (attribution && !dark) {
        type = "light";
      } else if (attribution && dark) {
        type = "attributed";
      }

      const dateSting = moment.utc(acquired).format("YYYY-MM-DD");
      const startTimestamp = acquired - 24 * 60 * 60 * 1000;
      const shipInfo = await getShipInfo([synmax_ship_id], dateSting);
      setShipDetails((prev) => ({
        ...prev,
        [shipId]: {
          ...shipInfo[synmax_ship_id],
        },
      }));
      setMapLatLng({
        lat: shipData?.latitude,
        lng: shipData?.longitude,
      });
      setMapViewState((prev: any) => ({
        zoom: zoomLevel,
        longitude: shipData?.longitude,
        latitude: shipData?.latitude,
      }));
      const startDate = moment
        .utc(startTimestamp)
        .format("YYYY-MM-DD HH:mm:ss");
      const endDate = moment.utc(acquired).format("YYYY-MM-DD HH:mm:ss");
      const shipPath = await getShipPath([synmax_ship_id], startDate, endDate);
      const shipPathColor = getUniqueColor();
      setShipPaths((prev) => ({
        ...prev,
        [shipId]: {
          color: shipPathColor,
          analysis: false,
          path: shipPath,
          dateRange: { startDate, endDate },
          showShip: true,
        },
      }));

      const updatedShipDetails = {
        [shipId]: {
          ...shipInfo[synmax_ship_id],
          ...shipData,
          synmax_ship_id: shipId,
          type,
          active: true,
          ...(isSimilarShip
            ? { isSimilarShip: true }
            : { isObservationShip: true }),
        },
      };
      setSelectedShip((prevShips) => ({
        ...updateActiveStatus(prevShips, shipId),
        ...updatedShipDetails,
      }));
    } catch (error) {
      console.error("Error fetching ship data:", error);
    }
  };

  const handleSimilarShips = async () => {
    if (!shipDetailTabValue) return;
    let shipId: string = "";
    if (
      shipDetailTabValue.includes("_") &&
      (!selectedShip[shipDetailTabValue]?.isObservationShip ||
        (selectedShip[shipDetailTabValue]?.isObservationShip &&
          (selectedShip[shipDetailTabValue]?.type === "opticalSTS" ||
            selectedShip[shipDetailTabValue]?.type === "AISSTS")))
    ) {
      const shipIds = selectedShip[shipDetailTabValue];
      const { synmax_ship_id_1 = "", synmax_ship_id_2 = "" } = shipIds || {};
      shipId = bunkeringValue === "1" ? synmax_ship_id_1 : synmax_ship_id_2;
    } else {
      shipId = shipDetailTabValue;
    }
    const { startDate, endDate } = dateRange;
    const start =
      moment.utc(new Date(startDate)).format("YYYY-MM-DD") + " 00:00:00";
    const end =
      moment.utc(new Date(endDate)).format("YYYY-MM-DD") + " 23:59:59";
    const shipData = await getSimilarShips(shipId, start, end, area);
    setSimilarShips((prevShips) => ({
      ...prevShips,
      [shipId]: shipData,
    }));
    let isDataSet = false;
    shipData?.length > 0 && setShipDetailTabValue(`sim:${shipId}`);
    await Promise.all(
      shipData.map(async (item: ShipImages) => {
        const data = await getSatelliteImage(+item.object_id);
        if (data && !isDataSet) {
          setIsSatelliteImageLoading(false);
          isDataSet = true;
        }
        if (data) {
          setSatelliteImageTimeline(
            (prevState: { [key: string]: ShipImages[] }) => {
              const updatedState = { ...prevState };
              const id = `sim:${shipDetailTabValue}`;
              if (!updatedState[id]) {
                updatedState[id] = [];
              }
              updatedState[id].push({
                object_id: item.object_id,
                longitude: item.longitude,
                latitude: item.latitude,
                acquired: item.acquired,
                image: data,
                name: "Similar Ships",
                id,
              });
              return updatedState;
            }
          );
        }
      })
    );
  };

  const updateMapLocation = (latitude: number, longitude: number) => {
    setMapLatLng({ lat: latitude, lng: longitude });
    setMapViewState((prev: any) => ({
      ...prev,
      zoom: zoomLevel,
      longitude,
      latitude,
    }));
  };

  const handleViewEventClick = async (eventData: EventData) => {
    setShipDetailsTabLoading(true);
    try {
      if (eventData.event === "Spoofing") {
        const { synmax_ship_id, timestamp, longitude, latitude } = eventData;
        if (!synmax_ship_id || !longitude || !latitude) return;
        const shipId: string = `spoofing_${synmax_ship_id}`;
        setSelectedShip((prevShips) => ({
          ...(prevShips as { [key: string]: ShipDetailsInterFace }),
          [shipId]: {} as ShipDetailsInterFace,
        }));

        setShipDetailTabValue(shipId);

        const dateSting = moment.utc(timestamp).format("YYYY-MM-DD");
        const startTimestamp =
          moment.utc(timestamp).valueOf() - 24 * 60 * 60 * 1000;
        const shipInfo = await getShipInfo([synmax_ship_id], dateSting);
        setShipDetails((prev) => ({
          ...prev,
          [shipId]: {
            ...shipInfo[synmax_ship_id],
          },
        }));
        updateMapLocation(latitude, longitude);
        const startDate = moment
          .utc(startTimestamp)
          .format("YYYY-MM-DD HH:mm:ss");
        const endDate = moment.utc(timestamp).format("YYYY-MM-DD HH:mm:ss");
        const shipPath = await getShipPath(
          [synmax_ship_id],
          startDate,
          endDate
        );
        const shipPathColor = getUniqueColor();
        setShipPaths((prev) => ({
          ...prev,
          [shipId]: {
            color: shipPathColor,
            analysis: false,
            path: shipPath,
            dateRange: { startDate, endDate },
            showShip: true,
          },
        }));

        const updatedShipDetails = {
          [shipId]: {
            ...shipInfo[synmax_ship_id],
            synmax_ship_id: shipId,
            active: true,
            isObservationShip: true,
            timestamp,
            longitude,
            latitude,
            type: "spoofing",
            pathColor: shipPathColor,
          },
        };
        setSelectedShip((prevShips) => ({
          ...updateActiveStatus(prevShips, shipId),
          ...updatedShipDetails,
        }));
      } else if (eventData.event === "Bunkering") {
        if (eventData.type === "Optical Detection") {
          const {
            bunkeringId,
            timestamp,
            longitude,
            latitude,
            ship2Attri,
            ship2ObjId,
            ship2Dark,
            ship1Attri,
            ship1ObjId,
            ship1Dark,
            frontendRotation,
          } = eventData || {};
          if (!bunkeringId || !longitude || !latitude) return;
          const shipId: string = `bunkering_${bunkeringId}`;
          setSelectedShip((prevShips) => ({
            ...(prevShips as { [key: string]: ShipDetailsInterFace }),
            [shipId]: {} as ShipDetailsInterFace,
          }));

          setShipDetailTabValue(shipId);

          updateMapLocation(latitude, longitude);

          const dateSting = moment.utc(timestamp).format("YYYY-MM-DD");
          const startTimestamp =
            moment.utc(timestamp).valueOf() - 24 * 60 * 60 * 1000;
          const shipIds: string[] = [];
          if (ship2Attri && ship2Attri !== "None") {
            shipIds.push(ship2Attri);
          }
          if (ship1Attri && ship1Attri !== "None") {
            shipIds.push(ship1Attri);
          }
          const shipInfo = await getShipInfo(shipIds, dateSting);
          setShipDetails((prev) => {
            const updatedDetails = { ...prev };
            shipIds.forEach((id) => {
              updatedDetails[id] = {
                ...(shipInfo[id] || {}),
              };
            });
            return updatedDetails;
          });

          const startDate = moment
            .utc(startTimestamp)
            .format("YYYY-MM-DD HH:mm:ss");
          const endDate = moment.utc(timestamp).format("YYYY-MM-DD HH:mm:ss");
          for (const id of shipIds) {
            const shipPathColor = getUniqueColor();
            await fetchAndSetShipPath([id], startDate, endDate, shipPathColor);
          }

          const ship1Type =
            ship1Attri && ship1Attri !== "None"
              ? ship1Dark
                ? "Dark"
                : "Light"
              : "Unattributed";

          const ship2Type =
            ship2Attri && ship2Attri !== "None"
              ? ship2Dark
                ? "Dark"
                : "Light"
              : "Unattributed";
          const synmax_ship_id1 =
            ship1Attri && ship1Attri !== "None"
              ? ship1Attri
              : `unattributed-${ship1ObjId}`;
          const synmax_ship_id2 =
            ship2Attri && ship2Attri !== "None"
              ? ship2Attri
              : `unattributed-${ship2ObjId}`;

          const ship1Attributes = ship1ObjId
            ? await getShipAttributes(+ship1ObjId as number)
            : {};
          const ship2Attributes = ship2ObjId
            ? await getShipAttributes(+ship2ObjId as number)
            : {};
          const updatedShipDetails = {
            [shipId]: {
              [synmax_ship_id1]: {
                ...shipInfo?.[synmax_ship_id1],
                ...ship1Attributes?.auto,
                object_id: ship1ObjId,
                type: ship1Type,
                longitude,
                latitude,
              },
              [synmax_ship_id2]: {
                ...shipInfo?.[synmax_ship_id2],
                ...ship2Attributes?.auto,
                object_id: ship1ObjId,
                type: ship2Type,
                longitude,
                latitude,
              },
              synmax_ship_id: shipId,
              synmax_ship_id_1: synmax_ship_id1,
              synmax_ship_id_2: synmax_ship_id2,
              active: true,
              isObservationShip: true,
              timestamp,
              longitude,
              latitude,
              type: "opticalSTS",
              ship1Status: getShipStatusByType(ship1Type),
              ship2Status: getShipStatusByType(ship2Type),
              frontend_rotation: frontendRotation || 0,
              ship1_status: ship1Type,
              ship2_status: ship2Type,
              object_id_1: ship1ObjId,
              object_id_2: ship2ObjId,
              bunkering_id: bunkeringId,
            },
          };
          setSelectedShip((prevShips) => ({
            ...updateActiveStatus(prevShips, shipId),
            ...updatedShipDetails,
          }));
        } else if (eventData.type === "AIS Detection") {
          const { timestamp, longitude, latitude, ship2Attri, ship1Attri } =
            eventData || {};
          if (!ship2Attri || !ship1Attri || !longitude || !latitude) return;
          const shipId: string = `${ship1Attri}_${ship2Attri}`;
          setSelectedShip((prevShips) => ({
            ...(prevShips as { [key: string]: ShipDetailsInterFace }),
            [shipId]: {} as ShipDetailsInterFace,
          }));

          setShipDetailTabValue(shipId);

          updateMapLocation(latitude, longitude);
          const dateSting = moment.utc(timestamp).format("YYYY-MM-DD");
          const startTimestamp =
            moment.utc(timestamp).valueOf() - 24 * 60 * 60 * 1000;
          const shipIds: string[] = [ship1Attri, ship2Attri];
          const shipInfo = await getShipInfo(shipIds, dateSting);
          setShipDetails((prev) => {
            const updatedDetails = { ...prev };
            shipIds.forEach((id) => {
              updatedDetails[id] = {
                ...(shipInfo[id] || {}),
              };
            });
            return updatedDetails;
          });

          const startDate = moment
            .utc(startTimestamp)
            .format("YYYY-MM-DD HH:mm:ss");
          const endDate = moment.utc(timestamp).format("YYYY-MM-DD HH:mm:ss");
          for (const id of shipIds) {
            const shipPathColor = getUniqueColor();
            await fetchAndSetShipPath([id], startDate, endDate, shipPathColor);
          }

          const updatedShipDetails = {
            [shipId]: {
              [ship1Attri]: {
                ...shipInfo?.[ship1Attri],
                type: "AIS",
              },
              [ship2Attri]: {
                ...shipInfo?.[ship2Attri],
                type: "AIS",
              },
              synmax_ship_id: shipId,
              synmax_ship_id_1: ship1Attri,
              synmax_ship_id_2: ship2Attri,
              active: true,
              isObservationShip: true,
              timestamp,
              longitude,
              latitude,
              type: "AISSTS",
              ship1_status: "AIS",
              ship2_status: "AIS",
            },
          };

          setSelectedShip((prevShips) => ({
            ...updateActiveStatus(prevShips, shipId),
            ...updatedShipDetails,
          }));
        }
      } else if (eventData.event === "AIS Dark") {
        const {
          latitude_t0,
          latitude_t1,
          longitude_t0,
          longitude_t1,
          synmax_ship_id,
        } = eventData;
        if (
          !latitude_t0 ||
          !latitude_t1 ||
          !longitude_t0 ||
          !longitude_t1 ||
          !synmax_ship_id
        ) {
          setDarkEventOnMap(null);
          return;
        }
        updateMapLocation(latitude_t0, longitude_t0);
        setDarkEventOnMap({
          latitude_t0,
          latitude_t1,
          longitude_t0,
          longitude_t1,
          synmax_ship_id,
        });
      }
    } catch (error) {
      console.error("Error handling view event click:", error);
    } finally {
      setShipDetailsTabLoading(false);
    }
  };

  return (
    <MapContext.Provider
      value={{
        drawRef,
        features,
        setFeatures,
        showTemporaryCircle,
        setShowTemporaryCircle,
        detections,
        showShips,
        setShowShips,
        setSelectedShipEstimatedLocationGEOJSON,
        selectedShipEstimatedLoactionLayer,
        handleEstmatedShipLayerSelect,
        setSelectedShipEstimatedLocationLayer,
        getUniqueColor,
        onShipClick,
        showSimilarShips,
        setShowSimilarShips,
      }}
    >
      <div className={styles.headerContainer}>
        <Box
          display="flex"
          alignItems="center"
          className={styles.subHeaderMain}
          key={selectedTabId}
        >
          <Box display="flex" alignItems="center" gap="15px">
            <span className={styles.subHeaderText}>Work</span>
            <EditOutlined fontSize="small" />
          </Box>
          <Box display="flex" alignItems="center" gap="24px">
            <Box display="flex" alignItems="center" gap="8px">
              <MainSearch
                searchIsExpanded={searchIsExpanded}
                handleSearchExpand={handleSearchExpand}
                date={date?.toString()}
              />
            </Box>
            <Divider
              className={styles.divider}
              orientation="vertical"
              flexItem
            />
            {searchIsExpanded ? null : (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  gap="8px"
                  sx={{ color: "#7d7d84", width: "90px" }}
                >
                  <LanguageOutlined fontSize="small" />
                  <span className={styles.subHeaderFilterText}>Travel to</span>
                </Box>
                <Divider
                  className={styles.divider}
                  orientation="vertical"
                  flexItem
                />
                <Box
                  display="flex"
                  alignItems="center"
                  gap="8px"
                  className={styles.datePickerContainer}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="date picker"
                      value={date}
                      onChange={(newValue) => {
                        dispatch(setChangeSaved(false));
                        setDate(newValue);
                      }}
                      onClose={() => setShowDatePicker(false)}
                      open={showDatePicker}
                      slots={{
                        openPickerIcon: (params) => (
                          <CalendarTodayOutlined
                            {...params}
                            fontSize="small"
                            className={styles.replayIcon}
                            onClick={() => {
                              menuRef?.current?.click();
                              setShowDatePicker(true);
                            }}
                          />
                        ),
                      }}
                      maxDate={dayjs()}
                    />
                  </LocalizationProvider>
                  <span className={styles.subHeaderFilterText}>
                    {!date?.isSame(dayjs(), "day") && date !== null
                      ? `${date?.format("YYYY/MM/DD")}`
                      : "Live"}
                  </span>
                  {/* Can be added later */}
                  {/* <ReplayOutlined
                    fontSize="small"
                    onClick={() => setDate(null)}
                    className={styles.replayIcon} 
                  /> */}
                </Box>
                <Divider
                  className={styles.divider}
                  orientation="vertical"
                  flexItem
                />
                <Box display="flex" alignItems="center" gap="8px" width={280}>
                  <SignalCellularAltOutlined fontSize="small" />
                  {showGlobeView ? (
                    <span className={styles.subHeaderFilterText}>
                      Total ships in globe view: {shipCountsInCurrentView.total}
                    </span>
                  ) : (
                    <span className={styles.subHeaderFilterText}>
                      Ships in current view: {shipCountsInCurrentView.total}
                    </span>
                  )}
                  {shipCountsAreShown ? (
                    <ExpandLess
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setShipCountsAreShown(false);
                      }}
                    />
                  ) : (
                    <ExpandMore
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setShipCountsAreShown(true);
                      }}
                    />
                  )}
                </Box>
              </>
            )}
          </Box>
          <Box display="flex" alignItems="center" gap="24px">
            <Button
              className={styles.stateButton}
              onClick={() => {
                setShowGlobeView(!showGlobeView);
                dispatch(setChangeSaved(false));
                setShowDeckglPolygons(true);
              }}
              startIcon={<VisibilityOutlined />}
              disabled={true}
              classes={{
                disabled: styles.disabled,
              }}
            >
              Change View
            </Button>
            {!searchIsExpanded &&
              workStationMode !== "incognito" &&
              workStationMode !== "local" && (
                <Button
                  className={`${styles.stateButton} ${styles.savedButton}`}
                  classes={{
                    disabled: !saveFileLoading
                      ? styles.disabled
                      : `${styles.stateButton} ${styles.savedButton}`,
                  }}
                  disabled={changesSaved}
                  onClick={saveStateToFirebase}
                  startIcon={
                    loading || saveFileLoading ? (
                      <CircularProgress
                        classes={{ circle: styles.circularSpiner }}
                        size={20}
                      />
                    ) : (
                      <SaveOutlined />
                    )
                  }
                >
                  {loading || saveFileLoading ? "Saving" : "Saved"}
                </Button>
              )}
            {workStationMode === "local" && (
              <>
                <Button
                  className={`${styles.stateButton} ${styles.downloadButton}`}
                  onClick={downloadStateInJson}
                  startIcon={<FileDownload />}
                >
                  Download
                </Button>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="upload"
                  ref={uploadInputRef}
                  onChange={(e) => uploadMapState(e)}
                />
                <Button
                  className={`${styles.stateButton} ${styles.savedButton}`}
                  style={{ position: "relative", zIndex: 10001 }}
                  startIcon={<Publish />}
                  onClick={() =>
                    uploadInputRef.current && uploadInputRef.current?.click()
                  }
                >
                  Upload
                </Button>
              </>
            )}
            <ContentCopyOutlined fontSize="small" sx={{ color: "#7d7d84" }} />
            <ShareOutlined fontSize="small" sx={{ color: "#7d7d84" }} />
            <MoreHorizOutlined fontSize="small" sx={{ color: "#7d7d84" }} />
          </Box>
        </Box>
        <MainMapHeaderShipCounts
          shipsInCurrentViewCountIsShown={shipCountsAreShown}
          shipCountsInCurrentView={shipCountsInCurrentView}
          showGlobeView={showGlobeView}
        />
      </div>
      <Sidebar
        menuList={menuList}
        active={active}
        onClick={(index) => onSidebarClick(index)}
      >
        <Drawer
          collapsed={collapsed}
          setCollapsed={setSidebarCollapsed}
          width={collapsed ? "385px" : "29px"}
          left={"50px"}
        >
          {collapsed && menuList[active]?.component}
        </Drawer>
      </Sidebar>
      {detailsTabs.length > 0 && (
        <Drawer
          collapsed={shipCollapsed}
          setCollapsed={setShipCollapsed}
          width={shipCollapsed ? "500px" : "29px"}
          left={collapsed ? "435px" : "79px"}
          hideBackdrop={true}
        >
          <ShipDetails
            shipDetailTabValue={shipDetailTabValue}
            setShipDetailTabValue={setShipDetailTabValue}
            handleShipDetailsTabChange={handleShipDetailsTabChange}
            shipDetailsTabs={detailsTabs}
            setShipDetailsTabs={setDetailsTabs}
            handleShipDetailsTabClose={handleShipDetailsTabClose}
            bunkeringValue={bunkeringValue}
            setBunkeringValue={setBunkeringValue}
            getEventDetails={getEventDetails}
            shipPaths={shipPaths}
            setShipPaths={setShipPaths}
            pathRef={pathRef}
            handleViewPathAnalysis={handleViewPathAnalysis}
            handleDownloadXLS={handleDownloadXLS}
            handleSatelliteImageClick={handleSatelliteImageClick}
            setHoveredId={setHoveredId}
            hoveredId={hoveredId}
            handleViewEventClick={handleViewEventClick}
            shipDetailsTabLoading={shipDetailsTabLoading}
          />
        </Drawer>
      )}
      <FilterArea
        currentEventTypeFilterOptions={currentEventTypeFilterOptions}
        setCurrentEventTypeFilterOptions={setCurrentEventTypeFilterOptions}
        currentSelectedFilter={currentSelectedFilter}
        setCurrentSelectedFilter={setCurrentSelectedFilter}
        filterLayers={filterLayers}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        setMapStyle={setMapStyle}
        showGlobeView={showGlobeView}
        isSimilarShips={Object.keys(similarShips)?.length > 0}
      />
      <div
        id="map"
        style={{ width: "100%", height: "100%", position: "fixed" }}
      >
        {showGlobeView ? (
          <DeckglRender {...mapProps} setMapViewState={setMapViewState} />
        ) : (
          <>
            <RightNavigationBar
              currentSelectedFilter={currentSelectedFilter}
              setCurrentSelectedFilter={setCurrentSelectedFilter}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              selectedShape={selectedShape}
              setSelectedShape={setSelectedShape}
              setshowDropPinDialog={setshowDropPinDialog}
              mapRef={mapRef}
              setMapViewState={setMapViewState}
              setDateRange={setDateRange}
            />
            <MapboxRender {...mapProps} filteredDetection={filteredDetection} />
          </>
        )}
      </div>
      <Box
        display="flex"
        alignItems="flex-end"
        flexDirection="column"
        gap={1}
        className={styles.shipToolsModalMain}
      >
        <ShipTools
          shipDetailTabValue={shipDetailTabValue}
          shipDetailsTabs={detailsTabs}
          bunkeringValue={bunkeringValue}
          handleViewExtendedPath={handleViewExtendedPath}
          setSelectedShipEstimatedLocationLayer={
            setSelectedShipEstimatedLocationLayer
          }
          selectedShipEstimatedLoactionLayer={
            selectedShipEstimatedLoactionLayer
          }
          selectedPath={selectedPath}
          sliderValue={sliderValue}
          setSliderValue={setSliderValue}
          pathRef={pathRef}
          loading={extendedPathLoading}
        />

        {isOpenPolygonShipAnalysisModal && (
          <PolygonShipAnalysis
            setSelectedShape={setSelectedShape}
            dateRange={dateRange}
            setDateRange={setDateRange}
            handleSimilarShips={handleSimilarShips}
            showSimilarShipsButton={
              (Object.keys(selectedEvent).length > 0 ||
                Object.keys(selectedShip).length > 0) &&
              shipDetailTabValue &&
              selectedShip[shipDetailTabValue]?.type !== "unattributed" &&
              selectedShip[shipDetailTabValue]?.type !== "opticalSTS" &&
              selectedShip[shipDetailTabValue]?.type !== "AISSTS" &&
              !selectedShip[shipDetailTabValue]?.isObservationShip
                ? true
                : false
            }
            lastSelectedOption={lastSelectedOption}
            setLastSelectedOption={setLastSelectedOption}
          />
        )}
      </Box>
      {minimizePolygonModal && (
        <PolygonShipAnalysisMinimize
          setSelectedShape={setSelectedShape}
          setDateRange={setDateRange}
        />
      )}
      {showDropPinDialog && (
        <DropPin
          setshowDropPinDialog={setshowDropPinDialog}
          setMapLatLng={setMapLatLng}
          setDroppedPins={setDroppedPins}
          mapRef={mapRef}
          setMapViewState={setMapViewState}
        />
      )}
    </MapContext.Provider>
  );
};

export default MainMap;
