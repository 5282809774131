import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchFuturePath,
  fetchFuturePathPrediction,
  fetchFuturePathProgress,
} from "./FuturePathPrediction.services";
import { AlertColor } from "@mui/material";
import { setInterval } from "timers/promises";
import { FuturePathParams } from "./FuturePathPrediction";

interface FuturePathPrediction {
  success: boolean;
  task_id: string;
  version: string;
}

interface FuturePathProgress {
  message: string;
  progress: string | number;
  status: string;
  task_id?: string;
  timestamp?: number;
}

export const fetchFuturePathPredictionData = createAsyncThunk(
  "futurePathPrediction/fetchFuturePathPredictionData",
  async (shipData: any) => {
    try {
      const data = await fetchFuturePathPrediction(shipData);
      return data;
    } catch (error: any) {
      throw new Error(error?.response?.data?.error);
    }
  }
);

export const fetchFuturePathProgressData = createAsyncThunk(
  "futurePathPrediction/fetchFuturePathProgressData",
  async (shipData: any) => {
    try {
      const data = await fetchFuturePathProgress(shipData);
      return data;
    } catch (error: any) {
      throw new Error(error?.response?.data?.error);
    }
  }
);

export const fetchFuturePathData = createAsyncThunk(
  "futurePathPrediction/fetchFuturePathData",
  async (shipData: any) => {
    try {
      const data = await fetchFuturePath(shipData);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const futurePathPredictionSlice = createSlice({
  name: "futurePathPrediction",
  initialState: {
    loading: false,
    error: "",
    futurePathPredictionData: {} as FuturePathPrediction,
    futurePathProgressData: {} as FuturePathProgress,
    futurePathData: [] as any,
    pathCluster: [] as any,
    slider: 0,
    intervalId: "",
    layers: [] as any,
    futurePathParams: {} as FuturePathParams,
  },
  reducers: {
    setPathCluster: (state, action) => {
      state.pathCluster = action.payload;
    },
    setSliderForFuturePath: (state, action) => {
      state.slider = action.payload;
    },
    setFuturePathIntialValue: (state) => {
      state.futurePathPredictionData = {} as FuturePathPrediction;
      state.futurePathProgressData = {} as FuturePathProgress;
      state.futurePathData = [] as any;
      state.slider = 0;
      state.error = "";
      state.layers = [];
      state.intervalId = "";
    },
    setLayersFuturePath: (state, action) => {
      state.layers = action.payload;
    },
    setIntervalId: (state, action) => {
      state.intervalId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //GET FUTURE PATH PREDICTION
      .addCase(fetchFuturePathPredictionData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchFuturePathPredictionData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.futurePathPredictionData = action.payload;
        }
      )
      .addCase(fetchFuturePathPredictionData.rejected, (state, action: any) => {
        state.loading = false;
      })
      //GET FUTURE PATH PROGRESS
      .addCase(fetchFuturePathProgressData.pending, (state) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(
        fetchFuturePathProgressData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.futurePathProgressData = action.payload;
        }
      )
      .addCase(fetchFuturePathProgressData.rejected, (state, action: any) => {
        state.loading = false;
      })
      //GET FUTURE PATH DATA
      .addCase(fetchFuturePathData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchFuturePathData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.futurePathData = action.payload;
        }
      )
      .addCase(fetchFuturePathData.rejected, (state, action: any) => {
        state.loading = false;
      });
  },
});

export const {
  setPathCluster,
  setSliderForFuturePath,
  setFuturePathIntialValue,
  setLayersFuturePath,
  setIntervalId,
} = futurePathPredictionSlice.actions;

export const futurePathPredictionReducer = futurePathPredictionSlice.reducer;
