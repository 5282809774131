import { MainMapProvider } from "../../../context/useCurrentMapState";
import { GlobalFiltersProvider } from "../../../context/useGlobalFilter";
import { LayersProvider } from "../../../context/useLayers";
import { ShipDetailsProvider } from "../../../context/useShipDetails";
import { SatelliteTimelineProvider } from "../ShipDetails/hooks/useSatelliteImageTimeline";

interface IProviderLayers {
  children: React.ReactNode;
}

const MapProviderLayers = ({ children }: IProviderLayers) => {
  return (
    <GlobalFiltersProvider>
      <MainMapProvider>
        <SatelliteTimelineProvider>
          <ShipDetailsProvider>
            <LayersProvider>{children}</LayersProvider>
          </ShipDetailsProvider>
        </SatelliteTimelineProvider>
      </MainMapProvider>
    </GlobalFiltersProvider>
  );
};

export default MapProviderLayers;
