import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import moment from "moment";
import CustomSlider from "../../../../../components/ShipTools/CustomSlider/CustomSlider";
import { setSliderForFuturePath } from "../FuturePathPrediction.store";
import { RootState } from "../../../../../redux/store";
import styles from "./FuturePathPredictionSlider.module.scss";

const FuturePathPredictionSlider = ({ maxValue }: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();

  const labelForSlider = (slider: number) => {
    const formattedTime =
      moment()
        .startOf("day")
        .seconds(slider * 600)
        .format("H:mm:ss") + " (HH:MM:SS)";
    return formattedTime;
  };

  const updateFuturePathSlider = (newValue: number) => {
    dispatch(setSliderForFuturePath(newValue));
  };

  return (
    <Box className={styles.futurePathPredictionSlider}>
      <CustomSlider
        sliderTitle="Drag slider to view ship's predicted future path."
        minValue={1}
        maxValue={maxValue - 1}
        step={1}
        label={labelForSlider}
        updateSlider={updateFuturePathSlider}
        isFuturePath={true}
      />
      <Box
        display="flex"
        gap={2}
        className={styles.futurePathPredictionSliderOption}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Box
            display="flex"
            gap={1}
            className={`${styles.futurePathPredictionSliderBox}  ${styles.mostlikely}`}
          ></Box>
          <span className={styles.futurePathPredictionSliderBoxLabel}>
            Most likely
          </span>
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <Box
            className={`${styles.futurePathPredictionSliderBox}  ${styles.likely}`}
          ></Box>
          <span className={styles.futurePathPredictionSliderBoxLabel}>
            Likely
          </span>
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <Box
            className={`${styles.futurePathPredictionSliderBox}  ${styles.possible}`}
          ></Box>
          <span className={styles.futurePathPredictionSliderBoxLabel}>
            Possible
          </span>
        </Box>
      </Box>
    </Box>
  );
};

export default FuturePathPredictionSlider;
