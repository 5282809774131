const globalTerritories = {
  type: "FeatureCollection",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },

  features: [
    {
      type: "Feature",
      properties: { ISO_A3: "RUS", Country: "Conflict zone Japan/Russia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [150.3821985980004, 43.687438410000254],
            [151.149313409000229, 42.456810649000147],
            [151.023606414000255, 42.398761979000085],
            [150.808783414000345, 42.094291979000047],
            [150.802795414000229, 42.088501979000057],
            [150.80148941400023, 42.086638979000099],
            [150.328963414000327, 41.62514897900013],
            [149.763174413000229, 41.229104978],
            [149.757375413000204, 41.22624797800006],
            [149.756290413000329, 41.225480978],
            [149.705164413000148, 41.200268978000111],
            [149.660654413000316, 41.168713978000142],
            [149.590242033000095, 41.133540503],
            [149.055862412000238, 40.866598978000127],
            [149.033857412000202, 40.850674978000086],
            [149.033034412000347, 40.850258978000113],
            [149.031881412000189, 40.849424978],
            [149.027691412000195, 40.847307978],
            [149.023987412000224, 40.844625978000124],
            [148.389230412000302, 40.520399978000114],
            [147.859705876222392, 40.349891798000044],
            [147.58393338500025, 40.768112877000078],
            [146.851939156000071, 41.798640337000109],
            [146.785231465000265, 41.895375196000032],
            [146.274978399000247, 42.623940058000017],
            [145.954725630000212, 43.081212988000118],
            [145.941451393000307, 43.099241190000157],
            [145.918430759000216, 43.207353862000105],
            [145.917556789000173, 43.211458282500075],
            [145.904521171000169, 43.262109324],
            [145.903518330000111, 43.265857184500135],
            [145.903107797000303, 43.267391119500132],
            [145.900258037000071, 43.271185648000085],
            [145.891446176000272, 43.282918866000102],
            [145.883780756000192, 43.293125567000061],
            [145.856484225000258, 43.354933437000113],
            [145.855070470000214, 43.356845743000065],
            [145.851581847000148, 43.3632321340001],
            [145.847686181000199, 43.369801832000121],
            [145.846345683000322, 43.378114171000163],
            [145.845171131000143, 43.385397491999981],
            [145.845008081000287, 43.387510003999978],
            [145.844861430000151, 43.389346922000072],
            [145.844540149000295, 43.392100519999985],
            [145.843290282000225, 43.40258447299999],
            [145.842254031000152, 43.408985653000059],
            [145.841727873000309, 43.412235865000241],
            [145.841293081000231, 43.415294710000126],
            [145.840916114000265, 43.417252954500157],
            [145.838582514000223, 43.41929346400002],
            [145.828710260000321, 43.4230779130001],
            [145.824312631000225, 43.425070384000151],
            [145.814955333000285, 43.428812312],
            [145.792563389000293, 43.444101911000018],
            [145.781381961000079, 43.454523558],
            [145.780719735000162, 43.455113173000029],
            [145.778696134000143, 43.456914890000121],
            [145.766142627000022, 43.468844945000171],
            [145.757499685000312, 43.477058648000082],
            [145.721446850000348, 43.51231021000018],
            [145.720886778500244, 43.512857831000133],
            [145.705746628000156, 43.527447555000094],
            [145.689924252000196, 43.543051913000113],
            [145.68825879800022, 43.545728353000072],
            [145.661499214000173, 43.539378188],
            [145.656501951000223, 43.538280808000138],
            [145.65500658700023, 43.537955328999942],
            [145.625143076000199, 43.531569128000115],
            [145.586919813000122, 43.522947947000091],
            [145.556315316000337, 43.516238749000081],
            [145.539709706500275, 43.512671380500137],
            [145.534182178000265, 43.518385597000076],
            [145.53252494100002, 43.520099020000032],
            [145.516877817000136, 43.535902436000015],
            [145.491072430000145, 43.561965581999971],
            [145.475386361000119, 43.578211191000094],
            [145.4730050390001, 43.580629252000051],
            [145.472549011500064, 43.581116459500151],
            [145.459683855000321, 43.595865776000039],
            [145.457505934000324, 43.598579570000027],
            [145.44850866600018, 43.609790595000106],
            [145.442463409000311, 43.618049624000037],
            [145.428613614000255, 43.637488834000123],
            [145.426523764000308, 43.638253435000081],
            [145.418420797000181, 43.641150294000056],
            [145.397639463000246, 43.649660147000077],
            [145.372142307000217, 43.660737488000109],
            [145.368509404000065, 43.662459923000085],
            [145.361734865000244, 43.66558045000005],
            [145.357079693000173, 43.668236329000024],
            [145.354949006000197, 43.669451934000108],
            [145.348836294000193, 43.673019450000083],
            [145.332474437000201, 43.682568596000067],
            [145.327730732000191, 43.685645579000152],
            [145.320499689000144, 43.689664493],
            [145.298245160000192, 43.704260766000061],
            [145.295648444000108, 43.705916096000067],
            [145.294822468000348, 43.706512916000236],
            [145.293782878000115, 43.707245969000212],
            [145.292190254000161, 43.708365720000018],
            [145.284762766000199, 43.713481300500064],
            [145.278436539000154, 43.71947718600012],
            [145.263676841000233, 43.732127395000134],
            [145.262416683000197, 43.733261092000021],
            [145.261622167000269, 43.735187580000115],
            [145.261314937000179, 43.735932529000024],
            [145.259992807000231, 43.739758957999982],
            [145.256216197000185, 43.75068899300004],
            [145.255581351000274, 43.752278906000072],
            [145.255221543000175, 43.75318001200003],
            [145.253287884000315, 43.758485456000244],
            [145.252974755000196, 43.759618924999984],
            [145.251577205000217, 43.7646777920001],
            [145.250973329000203, 43.768057699999986],
            [145.242681892000292, 43.78115952200011],
            [145.242699678000122, 43.782298575000141],
            [145.243366028000196, 43.805638052000063],
            [145.243382994000171, 43.808657333999975],
            [145.244260120000149, 43.822409366000045],
            [145.24442854750032, 43.823533508500191],
            [145.244559209000272, 43.824405592],
            [145.245596079000279, 43.830069342500053],
            [145.245730380000282, 43.830853346000083],
            [145.246543302000191, 43.835740059000159],
            [145.250543040000025, 43.856971043000044],
            [145.251405551000062, 43.86154933500012],
            [145.252074868000136, 43.864904399000068],
            [145.253024590500218, 43.869664904500098],
            [145.255269593000207, 43.873331236000183],
            [145.257776474666912, 43.877425838666682],
            [145.258259433000148, 43.878249629666755],
            [145.258767242000204, 43.879078781000089],
            [145.25925427850018, 43.879884697500074],
            [145.266861774000176, 43.892888253000137],
            [145.269619442000135, 43.896846075000099],
            [145.27055517500014, 43.898231926999983],
            [145.275224760000128, 43.90514773700022],
            [145.27904788900014, 43.908844605000183],
            [145.281958479000082, 43.911659071000201],
            [145.28428905300018, 43.914013163000106],
            [145.285850264000146, 43.91559012700003],
            [145.290493190000234, 43.920330369000169],
            [145.312635568000246, 43.937340499000186],
            [145.317330827000092, 43.940947473000051],
            [145.320094305000111, 43.944428679499993],
            [145.32143722800015, 43.946268950999979],
            [145.322315547000244, 43.94705102100022],
            [145.326177775000247, 43.950490014000081],
            [145.344580002000043, 43.966875669999979],
            [145.345802721000268, 43.967908913000088],
            [145.353528777000179, 43.974437721000129],
            [145.354158929000278, 43.974958603000061],
            [145.355481802000213, 43.976052087000085],
            [145.360149910000246, 43.980241915000192],
            [145.361130149000132, 43.981104747000018],
            [145.366100234000214, 43.98546127100019],
            [145.366701767000336, 43.985988544000236],
            [145.367409178000344, 43.986593453000125],
            [145.370753634000152, 43.989552448999973],
            [145.380522232000317, 43.997412514000075],
            [145.386460047000242, 44.002415467999981],
            [145.394713111000215, 44.009369153000023],
            [145.399500511000156, 44.013340130000074],
            [145.400292347000345, 44.013996928999973],
            [145.401508299000056, 44.01501897899999],
            [145.40485897766689, 44.017835341000136],
            [145.40701412500016, 44.019532356000127],
            [145.412938111000017, 44.026222856],
            [145.414930718000164, 44.028427675000046],
            [145.433257623000145, 44.048706386999982],
            [145.434427505000144, 44.050000861],
            [145.434944469000243, 44.050682372000153],
            [145.438048468000147, 44.054774361999989],
            [145.438546814000347, 44.05569554799996],
            [145.439989830000172, 44.058362946999978],
            [145.441088923000279, 44.060498651000103],
            [145.442598518333398, 44.063260016666732],
            [145.449837421000211, 44.069653365000136],
            [145.471836493000183, 44.086893374000056],
            [145.484498622000274, 44.096816302000093],
            [145.485957343000337, 44.097751980999931],
            [145.495096416000365, 44.10361831649999],
            [145.496957206000161, 44.104838895000114],
            [145.497792070000287, 44.105578432000129],
            [145.499751463000166, 44.107314094],
            [145.501786016000324, 44.109526327000196],
            [145.509811211000056, 44.118252373000075],
            [145.519781064000313, 44.128862966000042],
            [145.526481010000225, 44.135993502000133],
            [145.527195888000279, 44.136744198000031],
            [145.531128691000163, 44.14082034],
            [145.533278786000324, 44.143048799000098],
            [145.53395993100014, 44.144528713000113],
            [145.534412205000194, 44.145511361999979],
            [145.536284022000132, 44.148617573000024],
            [145.544301810000206, 44.161922792999917],
            [145.549197876000107, 44.170318022000117],
            [145.556904201000293, 44.183531970000104],
            [145.557958260000191, 44.185361614000101],
            [145.559892031000231, 44.188718268],
            [145.570705930000145, 44.202387324000028],
            [145.573477046000107, 44.206309781000073],
            [145.576766038000301, 44.210965279],
            [145.57773617900034, 44.212338492000015],
            [145.580807526000171, 44.217205976000059],
            [145.587958068000148, 44.228538181000033],
            [145.593693893000136, 44.237628339000082],
            [145.602672899000027, 44.255325534],
            [145.603837126000144, 44.257374567],
            [145.611581605000083, 44.271689326500052],
            [145.611962832000131, 44.272388448000129],
            [145.617568054000344, 44.282622578000087],
            [145.619358362000298, 44.286060436000128],
            [145.621026187000325, 44.289263095000024],
            [145.621825168000328, 44.290797347000137],
            [145.624003443000106, 44.301976006000018],
            [145.624708427000201, 44.305593902999988],
            [145.626694020000059, 44.3157837440001],
            [145.629426025000271, 44.330200095],
            [145.631526321000138, 44.341283019000059],
            [145.632967534000272, 44.345303928000135],
            [145.633211641000116, 44.346051143000068],
            [145.634303770000201, 44.349394165000092],
            [145.636184931000344, 44.356357718999959],
            [145.637674137000204, 44.361870360000012],
            [145.641435204000345, 44.37579282400003],
            [145.647224217000144, 44.392371954000055],
            [145.649879358000135, 44.40048318700002],
            [145.66208647700023, 44.438147023000056],
            [145.664947304000179, 44.447820175],
            [145.659505075000311, 44.492588825000098],
            [145.654718099000206, 44.531967260000158],
            [145.650707799000202, 44.563991607000162],
            [145.648672470000292, 44.580244773000118],
            [145.648050716000171, 44.585378087000095],
            [145.647513851000213, 44.58981053600013],
            [145.639829806000193, 44.611947626000131],
            [145.63911400700033, 44.613985515000081],
            [145.6295601610002, 44.64118542600005],
            [145.618808703000127, 44.673403494000183],
            [145.616637926000067, 44.67990849500012],
            [145.607976085000246, 44.705021574999989],
            [145.580314235000174, 44.785220954000067],
            [145.533350182000163, 44.914893950000021],
            [145.47692656900017, 45.053671781000105],
            [145.330973560000302, 45.40772100200013],
            [145.299909395000327, 45.482864953999922],
            [145.257993944000305, 45.581587968000122],
            [145.229148123000186, 45.649528222000072],
            [145.340734849000341, 45.740463965000231],
            [145.39367377300016, 45.777185740000107],
            [145.522105857000156, 45.860933134000163],
            [145.581390847000421, 45.899591414000099],
            [145.603562886250188, 45.914237108000236],
            [145.613721952000219, 45.920947658000159],
            [145.627192471000399, 45.929820010000242],
            [145.650579080000171, 45.945223592000048],
            [145.670026750000261, 45.957979616000159],
            [145.745562693000238, 46.007524796000268],
            [145.833791782000162, 46.137472116000197],
            [145.966982885000334, 46.333641285000112],
            [145.970726774000241, 46.341421519000278],
            [145.975591775000339, 46.351565431000267],
            [145.982369275000337, 46.365623027000083],
            [145.997845005000158, 46.397722112000224],
            [146.014137154000196, 46.431514576000041],
            [146.072240472000203, 46.556360921000248],
            [146.257098161000158, 46.947247542000184],
            [146.265962910000212, 46.96575273100018],
            [146.275006529000279, 46.984631312000147],
            [146.285900028000356, 47.007371521000266],
            [146.438487791000171, 47.077141251000228],
            [146.459330951000425, 47.086657512000215],
            [146.468709920000265, 47.090939622000292],
            [147.551051266000172, 47.577876642000319],
            [147.85064704600029, 47.707370869000158],
            [147.932015710000201, 47.592156311000281],
            [148.030350824000266, 47.452917983000191],
            [148.152210255000227, 47.280370225000127],
            [148.559495328000139, 46.684363464000171],
            [148.667921607000267, 46.522507459000167],
            [148.753996155000436, 46.389723128000242],
            [148.794720773000222, 46.326765300000091],
            [148.809538734000284, 46.303857616000244],
            [148.898254685000325, 46.171494324000037],
            [148.963788273000318, 46.056324865000249],
            [149.001529500000288, 45.996258369000202],
            [149.018707538000172, 45.951509599000303],
            [149.063662400000226, 45.821667107000167],
            [149.08752560600044, 45.752878976000147],
            [149.103971352000258, 45.713984184000196],
            [149.108150122000211, 45.689626317000148],
            [149.109425831000294, 45.682190267000237],
            [149.112418914000273, 45.664743717000192],
            [149.116897354000457, 45.645344215000364],
            [149.119644533000269, 45.633444112000291],
            [149.124157443000286, 45.613895299000092],
            [149.126781430000165, 45.60409697200015],
            [149.127968763000354, 45.599663306000139],
            [149.134078293000243, 45.577297209000108],
            [149.152431248000312, 45.510109728000145],
            [149.155106904000149, 45.500635333000275],
            [149.156571113000268, 45.495450625000103],
            [149.159334494000177, 45.485751031500115],
            [149.159628925000447, 45.484972079500153],
            [149.159968474000266, 45.484077202000265],
            [149.160707722000325, 45.482129693000331],
            [149.180683858000179, 45.42863927000019],
            [149.186682634000249, 45.4125762500002],
            [149.195626262000246, 45.400220021000223],
            [149.211911962000158, 45.377720222000107],
            [149.219764768000232, 45.366761649000296],
            [149.233594062000407, 45.347462902000188],
            [149.244195806000448, 45.332953435000093],
            [149.254950910000161, 45.318234080000138],
            [149.275635869000212, 45.290989453000179],
            [149.298287623000192, 45.261995455000203],
            [149.334964835000278, 45.213706711000157],
            [149.339370948000123, 45.208470068000224],
            [149.455271949000235, 45.065030070000148],
            [149.493279384000402, 45.009024773000135],
            [149.524014507000288, 44.963735483000107],
            [149.528768769000408, 44.956687647000308],
            [149.554950061000341, 44.917875852000236],
            [149.562128916000461, 44.907233741000141],
            [149.748234471000274, 44.630414963000192],
            [150.3821985980004, 43.687438410000254],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "JPN", Country: "Conflict zone Japan/South Korea" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [134.221631595000161, 39.878541295000062],
            [134.234522342000247, 39.539344014000037],
            [134.250115181000211, 39.117170353000134],
            [134.267029206000103, 38.588847496000071],
            [134.267079470000255, 38.586874152000135],
            [134.27055264200024, 38.448177228],
            [133.02301329500014, 37.246072351000052],
            [132.708985391000141, 36.934698611],
            [132.619932271000181, 36.851501294000158],
            [132.583200262000133, 36.817042960000109],
            [132.562637267000042, 36.797661931000121],
            [132.440012291000102, 36.690323947000095],
            [132.434102450000154, 36.685125301000014],
            [132.401910069000195, 36.663838624000093],
            [132.365570963000238, 36.640833881000034],
            [132.271045495000209, 36.581814569000144],
            [132.17840682500011, 36.525015676000109],
            [131.932058635000175, 36.376129312000145],
            [131.872586185000188, 36.340332561000125],
            [131.53936404900017, 36.138438033000156],
            [131.265000000000242, 36.166666667000058],
            [131.133143444000126, 36.39673464],
            [131.238189432000155, 36.911468555000042],
            [131.24643019900023, 36.937434904000114],
            [131.296518189000182, 37.093873832999961],
            [131.355716787000262, 37.24683312300003],
            [131.389064105000131, 37.333062519000137],
            [131.401641937000051, 37.365221638],
            [131.443580430000139, 37.454407194000098],
            [131.471341984000134, 37.513671027999962],
            [131.475400843000131, 37.522449570000035],
            [131.476454180000161, 37.524693206000151],
            [131.573410622000239, 37.730323861999963],
            [131.87603382100022, 38.366325386000099],
            [132.472355450000094, 39.588135114999986],
            [132.81555409600017, 40.268984662999955],
            [133.110186505000257, 40.187170347000134],
            [133.640220926000183, 40.039988968000088],
            [134.221631595000161, 39.878541295000062],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "JPN", Country: "Joint regime Japan/Korea" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [126.245706739000155, 30.133139870000136],
            [126.091666667000169, 30.303333333000111],
            [126.013333333000247, 30.555],
            [125.925000242800166, 30.769999409600061],
            [126.858333333000047, 32.175000000000168],
            [127.218333333000174, 32.560000000000159],
            [127.463333333000065, 32.77000000000011],
            [127.60500000000016, 32.89],
            [127.685000000000144, 32.95],
            [128.30000000000021, 32.95],
            [128.30000000000021, 32.450000000000145],
            [127.933333333000206, 32.450000000000145],
            [127.833333333000184, 32.2],
            [128.233333333000047, 31.78333333300013],
            [128.83333333300007, 31.78333333300013],
            [129.066666667000078, 30.9],
            [129.150000000000176, 30.316666667],
            [128.633333333000252, 29.716666666999984],
            [128.000000000000199, 29.316666667000035],
            [127.633333333000195, 28.6],
            [126.245706739000155, 30.133139870000136],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ISO_A3: "CHN",
        Country: "Conflict zone China/Japan/Taiwan",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [122.900096165000065, 25.229875222000075],
            [122.894102414000059, 25.25509931200007],
            [122.838929477000221, 25.485668778000118],
            [122.799734683000139, 25.651933912000047],
            [122.788409264000194, 25.75857027500011],
            [122.788312920000095, 25.759470017000112],
            [122.788135074000053, 25.761118902000121],
            [122.726954591000236, 26.315013760000127],
            [122.69984426500011, 26.550941319000103],
            [122.646802295000072, 26.780702919000092],
            [122.607522531000171, 26.950310319],
            [122.749922781000095, 27.11427843100013],
            [122.838520429000113, 27.184339931000167],
            [122.84009794700026, 27.185372163000025],
            [122.997724824000187, 27.274376955999983],
            [123.068496092000061, 27.314263702000133],
            [123.33884243300011, 27.465351371999972],
            [123.365423965000247, 27.480140679],
            [123.67925072600022, 27.654624029],
            [123.761740917000196, 27.700330589000103],
            [124.202267406000118, 27.85846796],
            [124.212755313000144, 27.864655727000113],
            [124.567597132000145, 28.074009051000118],
            [124.904928185000045, 28.271609413000036],
            [125.042386499000173, 27.928859718000169],
            [125.388441459000177, 27.046621260000151],
            [125.576056065000245, 26.358042324000152],
            [125.700850998500158, 25.893782306000091],
            [125.706513531000127, 25.872687223000142],
            [125.708129638000088, 25.86655010500003],
            [125.055365051000223, 25.501147619000093],
            [124.97950804600012, 25.458107648],
            [124.767068938000222, 25.338122441000095],
            [124.6940016880001, 25.331464144000122],
            [124.567475900000176, 25.320212021000103],
            [124.552523575000151, 25.318864357999971],
            [124.352307158000116, 25.301259861000162],
            [124.216998118000248, 25.289719262],
            [124.192435161000077, 25.293577955000146],
            [124.007805700000148, 25.198941266000034],
            [123.927437568000158, 25.157467883],
            [123.868293730000261, 25.126791146000031],
            [123.818495662000061, 25.119938191000116],
            [123.605502912000105, 25.090846006999968],
            [123.509994772000113, 25.077635757000039],
            [123.417142185000188, 25.064261821000045],
            [123.377583502000192, 25.078284718000091],
            [123.295245987000072, 25.102627116000164],
            [123.1522723530002, 25.147528708999985],
            [123.0015130620001, 25.195714797999969],
            [122.995512579000177, 25.197670870000096],
            [122.953630593000156, 25.21134199000015],
            [122.900096165000065, 25.229875222000075],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CHN", Country: "Spratly Islands" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [112.239892644000406, 13.247067710000238],
            [112.314027605000206, 13.29789636400011],
            [112.353527605000068, 13.324905364000188],
            [112.644457605000184, 13.523662365000177],
            [112.681575605000035, 13.549240365000173],
            [112.948820605000037, 13.691903365000087],
            [113.648574606000011, 14.063590365000039],
            [113.713634606000028, 14.084056365000038],
            [114.359206607000033, 14.28570236500002],
            [114.799897607000048, 14.422377365000102],
            [115.480290608000018, 14.623198366000167],
            [115.6221496080002, 14.57296336600001],
            [115.819993608000232, 14.488655865000055],
            [115.821683608000285, 14.487911365000059],
            [116.013263608000216, 14.39172536500007],
            [116.198132608000265, 14.283887365000112],
            [116.375552608000248, 14.164831365000168],
            [116.406569608000154, 14.141046365000079],
            [116.482679608000126, 14.128830365000056],
            [116.574688785000376, 14.108131595000202],
            [116.599013179000394, 14.076759712000182],
            [116.759913764000316, 13.869241509000233],
            [116.97848854200015, 13.586538513000164],
            [116.980505068000411, 13.583928490000289],
            [117.179935270000442, 13.325559444000305],
            [117.314884130000337, 12.959360479000111],
            [117.500763700000221, 11.377754943000241],
            [117.502400402000262, 11.363367489000154],
            [117.494836782000448, 11.303485176000208],
            [117.475603276000129, 11.217709545000218],
            [117.354272570000262, 10.836284067000236],
            [117.31626688900019, 10.71610630500021],
            [117.306340172000205, 10.694111006000071],
            [117.256805893000433, 10.574528809000242],
            [117.234795633000203, 10.53442521900007],
            [117.08482484800038, 10.320299764000197],
            [116.894677298000374, 10.072902902000351],
            [116.789398581000341, 9.961212441000271],
            [116.774669236000136, 9.925819043000104],
            [116.76973656000041, 9.913930463000213],
            [116.737050505000184, 9.836924120000219],
            [116.633576537000408, 9.628048757000215],
            [116.599097360000314, 9.564712944000121],
            [116.527436477000265, 9.457085597000344],
            [116.511410625000167, 9.411984840000173],
            [116.501365412000268, 9.38363852800012],
            [116.48411472700036, 9.336349397000106],
            [116.464294967000342, 9.285214428000359],
            [116.445112489000394, 9.242569015000129],
            [116.322394994000206, 8.97005413800008],
            [116.299402597000267, 8.91913234600014],
            [116.279072849000386, 8.876518752000152],
            [116.279976217000353, 8.868918357000211],
            [116.280561868000405, 8.861691856000107],
            [116.28124211900024, 8.853000751000195],
            [116.269581095000149, 8.749785644000212],
            [116.229309894000266, 8.395797651000123],
            [116.221182880000356, 8.357519912000214],
            [116.215710485000272, 8.33153973900022],
            [116.207539572000428, 8.293066865000185],
            [116.188166311000373, 8.197670187000313],
            [116.117030054000224, 7.937156869000148],
            [116.041076143400232, 7.662437746000137],
            [116.018445001000146, 7.644721573000083],
            [115.984346228000163, 7.622426221000126],
            [115.49632751300021, 7.333529721000048],
            [115.371114114000193, 7.266393082000093],
            [115.189139156000124, 7.251657968000188],
            [115.156180087000394, 7.2489225650001],
            [115.14295088200015, 7.246806254000035],
            [115.121338691000119, 7.243369267000162],
            [114.994202119000192, 7.223256339000102],
            [114.861904420000201, 7.202059965999979],
            [114.627878438000153, 6.990169315000117],
            [114.422341872000288, 6.804082973000163],
            [113.986244883000154, 6.408249474000058],
            [113.950763192000409, 6.390923941000153],
            [113.942406720000349, 6.386883563000026],
            [113.920864526000173, 6.376434684000117],
            [113.817794942000432, 6.326482285000168],
            [113.774623754000345, 6.305559484000128],
            [113.762615948000189, 6.302530617000116],
            [112.928615982000451, 6.291019912000081],
            [112.827419938000162, 6.289596063000147],
            [112.497029644000236, 6.233079192000105],
            [112.476444746000197, 6.229557920000047],
            [112.035649273000331, 6.153496011000186],
            [112.196290367000074, 6.775656593000065],
            [112.475564055000063, 7.851237376000029],
            [112.54524776600033, 8.119923424000149],
            [112.514843105000182, 8.16045802800005],
            [112.245407260000206, 8.519978677000154],
            [112.16303055700007, 8.629054349000114],
            [112.079930878000027, 8.741426864000175],
            [112.058064859000183, 8.770720596000103],
            [111.992648262000216, 8.860325912000064],
            [111.91700101500021, 8.962725093000017],
            [111.911175952000235, 8.970657066000228],
            [110.964474469000152, 10.241265285999987],
            [111.178420759, 10.563672220000171],
            [111.247766536000199, 10.644575628000098],
            [111.398015721000093, 10.956631626000046],
            [111.559822535000194, 11.30336051400019],
            [111.79974442400021, 11.708622118999983],
            [111.914615199000139, 12.051334486000087],
            [112.000967731000173, 12.409324120000065],
            [112.061699051000119, 12.641480984],
            [112.120480094000072, 12.865602145000196],
            [112.173782020000317, 13.037041066000086],
            [112.239892644000406, 13.247067710000238],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "JAM", Country: "Joint regime Colombia/Jamaica" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.633333196714204, 14.49361115300006],
            [-79.933333, 15.502778000000106],
            [-80.065277999999864, 15.766667000000112],
            [-79.94444, 15.977778000000157],
            [-79.84222199999985, 16.070833000000121],
            [-79.769347762999985, 16.07083306900013],
            [-79.488888888999867, 16.070833333000152],
            [-79.488888888999867, 16.169444444],
            [-79.277777778, 16.169444444],
            [-79.277777778, 16.070833333000152],
            [-78.430555556, 16.070833333000152],
            [-78.430555556, 15.6],
            [-78.633333332999854, 15.6],
            [-78.633333196714204, 14.49361115300006],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ISO_A3: "STP",
        Country: "Joint regime Nigeria/Sao Tome and Principe",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.606793754000108, 2.710480842999985],
            [6.879166667000163, 2.349722222000139],
            [5.965, 1.67],
            [4.860555556000151, 1.15472222199999],
            [4.690833333000171, 1.220833333000115],
            [4.403888889000086, 1.358055556000082],
            [4.115277778000035, 1.5275],
            [3.839722222, 1.713888888999989],
            [3.575833333000105, 1.92166666700011],
            [3.894444444000072, 1.981388889000158],
            [4.253055556000135, 2.049722222000213],
            [4.415555556000129, 2.086111111000122],
            [4.799444444000187, 2.178888889000149],
            [5.100833333000196, 2.264722222000245],
            [5.286388889000079, 2.325000000000173],
            [5.449166667000213, 2.380277778000121],
            [5.605555556000184, 2.439166667000151],
            [5.756111111000166, 2.502222222000142],
            [5.882777778000104, 2.560277778000227],
            [5.983333333000189, 2.610555556000094],
            [6.265833333000131, 2.755000000000237],
            [6.444722222000166, 2.83833333300008],
            [6.490833333000154, 2.858055556000224],
            [6.529444444000148, 2.873055556000097],
            [6.63527777800013, 2.912777778000205],
            [6.949444444000164, 3.006666667000161],
            [7.018611111000155, 3.021944444000098],
            [7.029444444000148, 3.024166667000117],
            [7.051944444000185, 3.028888889000129],
            [7.121513794600162, 3.042152760000064],
            [7.431111111000064, 2.833333333],
            [7.606793754000108, 2.710480842999985],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ISO_A3: "AUS",
        Country: "Joint development area Australia/East Timor",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [128.201188889000377, -10.465252777999922],
            [127.967855556000359, -10.748586110999838],
            [127.784522222000192, -10.91525277799991],
            [127.531855153000265, -11.215317954999762],
            [127.517855556000313, -11.231919443999971],
            [126.951225000000363, -11.281927777999897],
            [126.801225000000386, -11.31526111099987],
            [126.53959385200028, -11.330651178999744],
            [126.536253280004416, -11.330847683066679],
            [126.535897726500252, -11.330868597999824],
            [126.535542172997793, -11.330889512958834],
            [126.531079382954545, -11.331152030027965],
            [126.001225000000204, -10.465261109999915],
            [126.000016259000176, -10.465833728999939],
            [126.00012814100478, -10.460105412999781],
            [126.004913030003991, -10.215119948999813],
            [126.006944536992023, -10.111111071065181],
            [126.006944613998485, -10.111111039995365],
            [127.81166666625677, -9.381388889488676],
            [127.933333336293686, -9.46666666418156],
            [127.933333478544796, -9.466666763936075],
            [127.933333621083648, -9.466666863880846],
            [127.936116667051124, -9.468616662927445],
            [127.979660680866687, -9.499080481726025],
            [127.979672558934908, -9.499131877685954],
            [127.984872192940315, -9.521630496013813],
            [127.994781141029534, -9.564506137993192],
            [128.202650561069163, -10.463949213983994],
            [128.202825516964026, -10.46470624100823],
            [128.202825528008134, -10.464706288998059],
            [128.202825730112863, -10.464707163973799],
            [128.201188889000377, -10.465252777999922],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ISO_A3: "AUS",
        Country: "Protected zone Australia/Papua New Guinea",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [142.851102800000177, -9.673499672285601],
            [142.596690001000155, -9.687151357999824],
            [142.437924875000107, -9.695670424999932],
            [142.403852188000172, -9.697475414999971],
            [142.384458300000205, -9.698502799999886],
            [142.351937521000224, -9.704187842999985],
            [142.251024088000037, -9.72177789200002],
            [142.059458300000159, -9.7551694],
            [142.05945833000024, -9.260826704999928],
            [142.108069440000151, -9.21239166999986],
            [142.143625000000185, -9.196002779999901],
            [142.172791670000294, -9.197947219999847],
            [142.216125000000119, -9.18794721999987],
            [142.236680560000224, -9.191280559999896],
            [142.275013890000224, -9.229891669999901],
            [142.345803465000103, -9.266258084999905],
            [142.49572563000018, -9.366199544999887],
            [142.52582500000014, -9.361833329999939],
            [142.558880560000119, -9.374333329999914],
            [142.592491670000157, -9.355444439999872],
            [142.696380560000165, -9.337666669999933],
            [142.732491670000087, -9.336277779999861],
            [142.806303205000148, -9.322351049999881],
            [142.807979668000115, -9.322578028999828],
            [142.809852619000111, -9.322987419999862],
            [142.811708602000266, -9.323467037999848],
            [142.813544949000089, -9.324016193999896],
            [142.815359020000159, -9.324634098999866],
            [142.817148209000123, -9.325319863999852],
            [142.81890994300025, -9.326072503999868],
            [142.820641689000098, -9.326890936999902],
            [142.822340959000115, -9.327773986999873],
            [142.824005309000341, -9.328720383999837],
            [142.825632348000198, -9.329728767999868],
            [142.82721973500017, -9.330797688999851],
            [142.828765189000165, -9.331925610999917],
            [142.830266488000206, -9.333110912999842],
            [142.831721473000158, -9.334351890999855],
            [142.833128053000195, -9.335646760999879],
            [142.83448420500028, -9.336993661999855],
            [142.835805273000204, -9.338322542999848],
            [142.837075708000071, -9.339699270999901],
            [142.838293753000045, -9.341121945999831],
            [142.839457723000208, -9.342588600999861],
            [142.840566011000107, -9.344097209999902],
            [142.841617083000216, -9.345645688999937],
            [142.842609488000306, -9.347231896999915],
            [142.843541852000129, -9.348853643999888],
            [142.844412886000356, -9.350508686999873],
            [142.84522138700018, -9.352194740999849],
            [142.845966235000361, -9.353909474999881],
            [142.846646401000129, -9.35565051999987],
            [142.847260942000247, -9.357415469999893],
            [142.847774794000344, -9.358751828999885],
            [142.848250131000128, -9.360102174999881],
            [142.848686563000371, -9.361465415999874],
            [142.849247474000293, -9.363097807999935],
            [142.849751633000182, -9.364748360999897],
            [142.850198442000277, -9.366415130999883],
            [142.850587375000231, -9.368096151999879],
            [142.850917972000133, -9.369789441999913],
            [142.851189842000252, -9.37149300499982],
            [142.85140266300013, -9.373204833999893],
            [142.851556185000305, -9.374922908999935],
            [142.851746978000193, -9.376643403],
            [142.851877974000189, -9.378369402999908],
            [142.851949017000123, -9.380098859999919],
            [142.851960021000281, -9.38182972099986],
            [142.851946034000292, -9.383700917999874],
            [142.8518618800002, -9.385570298999824],
            [142.85170767300022, -9.387435270999859],
            [142.851483627000306, -9.389293243999845],
            [142.851146416000148, -9.391362380999894],
            [142.851102780000161, -9.39294443999988],
            [142.851102800000177, -9.673499672285601],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "HUN", Country: "Hungary" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.151445082882475, 48.411918174886821],
            [22.171718224511551, 48.409054078563955],
            [22.214300136780508, 48.417773101909219],
            [22.270209107497379, 48.402218133591035],
            [22.270273312876014, 48.35999111110344],
            [22.347773228043053, 48.274991068954265],
            [22.380973276061468, 48.244854103640748],
            [22.454718098595322, 48.243609055739526],
            [22.494609252299341, 48.249700184794179],
            [22.574718118656165, 48.187209073133189],
            [22.597773213956771, 48.145827114126135],
            [22.605236125288968, 48.116482238665],
            [22.625718143945733, 48.100345063350417],
            [22.647036173627441, 48.094318139674201],
            [22.679791142586026, 48.094991206501177],
            [22.737773293240309, 48.114436048456085],
            [22.790273176288224, 48.116654067680557],
            [22.846545083413844, 48.083164173449504],
            [22.883227141659177, 48.040482181256394],
            [22.85916320048932, 47.9938912046875],
            [22.894800203043758, 47.954535989873918],
            [22.780000148089528, 47.842982078390591],
            [22.653609094477787, 47.776382157781413],
            [22.634163246694584, 47.771664152108869],
            [22.603609197877859, 47.767773105005958],
            [22.552218240623091, 47.767082100906336],
            [22.501800254691744, 47.790282203132236],
            [22.481109191369228, 47.795554084968202],
            [22.449854080169075, 47.794854028412985],
            [22.320973266030961, 47.746664119989916],
            [22.273745097181035, 47.7262451332452],
            [22.225273221534252, 47.679300105085261],
            [22.191391221871527, 47.628744991217346],
            [22.189836043551878, 47.603954003764144],
            [22.186391248975269, 47.598609031732323],
            [22.167909152421799, 47.588327118716293],
            [22.11800916810796, 47.578109075802828],
            [22.037773232098942, 47.533609048805843],
            [22.013882293411115, 47.510618158883673],
            [22.008327103247154, 47.478609179309657],
            [22.02027315932412, 47.450827025881026],
            [22.020554120719339, 47.425282170054047],
            [22.013473256535008, 47.390136011750982],
            [21.99853620212059, 47.373609077937587],
            [21.962500220973851, 47.368045002956009],
            [21.929445179880759, 47.358953990746684],
            [21.871663356712787, 47.296527084464131],
            [21.842291156247256, 47.229235992117154],
            [21.847218206585666, 47.198118176491377],
            [21.783473162206121, 47.114927116690367],
            [21.743191244174881, 47.098191138211305],
            [21.710000248611976, 47.079718094113346],
            [21.65250022502974, 47.02756421602929],
            [21.655500108184498, 47.020864225526353],
            [21.692218208613639, 47.006282228531219],
            [21.67082708873599, 46.961391101931056],
            [21.613327065153754, 46.884164269170284],
            [21.530209095548685, 46.827009077085606],
            [21.493400135288852, 46.754754054026293],
            [21.52572712427002, 46.726182157678139],
            [21.493891147178317, 46.6869451276999],
            [21.489409175899851, 46.684536001082407],
            [21.45353613895125, 46.684644965823892],
            [21.327563342308991, 46.620618121858925],
            [21.30805412733767, 46.589854022548153],
            [21.293609090640842, 46.532491126902201],
            [21.288891252606277, 46.460545060794814],
            [21.219163205723447, 46.408336029787833],
            [21.202773232208358, 46.396809068870581],
            [21.188327189683122, 46.371936106404021],
            [21.178609211114434, 46.326945067517769],
            [21.177363325022981, 46.297364157662557],
            [21.080827101862951, 46.24693611344712],
            [21.049373171918944, 46.240827047119737],
            [20.9897181610242, 46.254718040015163],
            [20.868609215170295, 46.276109159892812],
            [20.846382251905624, 46.279164195970608],
            [20.825000184483287, 46.275973205422801],
            [20.795900228233734, 46.265726999314623],
            [20.783054123391196, 46.259436045687707],
            [20.757354034717025, 46.242909111874397],
            [20.720273165517398, 46.189582102618587],
            [20.726954045281218, 46.175564207708859],
            [20.719654078147244, 46.166100200807151],
            [20.687663203484306, 46.146309186250008],
            [20.622082187028013, 46.130827140489586],
            [20.582773240320194, 46.155000046400843],
            [20.563327057260636, 46.164991107375826],
            [20.535273162530274, 46.173045110521471],
            [20.486945120342313, 46.181109171951206],
            [20.340273213750635, 46.159436084849759],
            [20.274300259500819, 46.140409164588377],
            [20.261027180509359, 46.1148540828395],
            [20.257773158049446, 46.118045073387393],
            [20.202773288553345, 46.150553949668009],
            [20.115273259955984, 46.16721817905578],
            [19.961109105370923, 46.170827091296999],
            [19.849582183615837, 46.152145002533203],
            [19.744718204351727, 46.162218038521459],
            [19.703718124854475, 46.176391166278577],
            [19.666527117446861, 46.181109171951206],
            [19.565273223890472, 46.172773201581848],
            [19.506318101912939, 46.139227148599289],
            [19.516318215343347, 46.118118163583247],
            [19.463891254853195, 46.076382152985232],
            [19.284027179232027, 45.988882124387757],
            [19.154091251119411, 45.988609041981576],
            [19.127500166135178, 46.020900156417085],
            [19.093054064387132, 46.022218126876069],
            [19.002636127648174, 45.959445047991395],
            [18.858891181653519, 45.911109126814509],
            [18.835827201535409, 45.911109126814509],
            [18.817018210757084, 45.912964209630488],
            [18.807500224398638, 45.90277315644515],
            [18.780000205831698, 45.894718147471096],
            [18.719445146171466, 45.911518163690616],
            [18.618473219838592, 45.851391084007716],
            [18.598473160615896, 45.827918067013499],
            [18.555554128200185, 45.800000126753034],
            [18.433745125791177, 45.750973201476526],
            [18.407500213409179, 45.748327034636574],
            [18.332636071522103, 45.754436100964043],
            [18.248054118704147, 45.767291090624084],
            [18.225827155439646, 45.779718100306624],
            [18.195554068018254, 45.783882229815831],
            [18.153327213168836, 45.78721805965084],
            [18.006382224170778, 45.786944977244744],
            [17.880836066401571, 45.783882229815831],
            [17.660973213439291, 45.838744971375618],
            [17.652691222526471, 45.853136028615708],
            [17.644854143035587, 45.885273251308504],
            [17.626109189997834, 45.905827019056758],
            [17.58256319122296, 45.937564257328845],
            [17.458609092147242, 45.95443602263974],
            [17.427573251534824, 45.940291225715598],
            [17.389445147349534, 45.938045043296242],
            [17.35485403867628, 45.951664127251846],
            [17.311391188381151, 45.975273098715959],
            [17.257427154482372, 46.020964194157543],
            [17.254718123368434, 46.053327057684427],
            [17.234718231783717, 46.090418152805952],
            [17.182773230727548, 46.154582124707318],
            [17.15270918797188, 46.180273160926092],
            [17.040273164995909, 46.214164213044214],
            [16.973054157016577, 46.233609054999121],
            [16.937773217709889, 46.249454037168121],
            [16.919718095305456, 46.259991095317517],
            [16.891391118168798, 46.283745073707038],
            [16.880582151079579, 46.304509059587232],
            [16.879027140398222, 46.308573109172372],
            [16.874300249908259, 46.345336136602455],
            [16.840554037077254, 46.369991169585759],
            [16.801391270761343, 46.391391174280912],
            [16.661109224254176, 46.465554086146469],
            [16.628609232790978, 46.475000155775362],
            [16.607873242467321, 46.476235983582896],
            [16.530973303931319, 46.509027162363381],
            [16.408609084254323, 46.658882180513942],
            [16.385554156591695, 46.701664252631232],
            [16.375327061222634, 46.722773237647274],
            [16.359300191754102, 46.720200161003149],
            [16.325273185166083, 46.755827105273895],
            [16.315482284039518, 46.794300208594748],
            [16.337218235414639, 46.809164172813311],
            [16.349027163555405, 46.842353994909828],
            [16.298191255930448, 46.869300137313544],
            [16.269718098401995, 46.874445117135096],
            [16.229445065188372, 46.876245047028064],
            [16.181036053815546, 46.864582131641001],
            [16.139791222744975, 46.859164237051417],
            [16.111800192288484, 46.869718059007155],
            [16.137918202656095, 46.878891213867931],
            [16.253609086282296, 46.958327013397749],
            [16.27666334339267, 46.988054103644757],
            [16.302773307133265, 47.011382113713566],
            [16.346945098800944, 47.009991220696904],
            [16.412500131357319, 47.007773201472432],
            [16.445000122820517, 47.004718165394351],
            [16.504863340190724, 47.006764187965288],
            [16.505554009014276, 47.115000206886307],
            [16.45930015259205, 47.147291153683838],
            [16.420900139467051, 47.210409064066013],
            [16.444718155596917, 47.244436070654146],
            [16.474300238918602, 47.259991206610593],
            [16.491391274817119, 47.281936035013572],
            [16.456391129267786, 47.368891072265043],
            [16.452009237913558, 47.412845102087616],
            [16.473891202042495, 47.418745123749247],
            [16.592500161448299, 47.425282170054047],
            [16.655200150137063, 47.458153982743212],
            [16.705273304570909, 47.521109116565455],
            [16.713891242163896, 47.543882077004454],
            [16.666391164373948, 47.614436076628593],
            [16.632573202451766, 47.634436135851402],
            [16.596663285129125, 47.628891171609141],
            [16.493054076773802, 47.646664159152053],
            [16.432636145050026, 47.66638208351317],
            [16.45055413951809, 47.69805411057844],
            [16.558609109330177, 47.756382098558717],
            [16.612218085809502, 47.759164219868396],
            [16.635554142505413, 47.760273145661628],
            [16.723473266262772, 47.726454010273059],
            [16.735836070566876, 47.702082117979657],
            [16.764163215341625, 47.685409171412644],
            [16.824445024957555, 47.683882156287993],
            [16.910827075306287, 47.690827066002313],
            [16.955273290484712, 47.694718113105338],
            [17.053891243123132, 47.70944511702568],
            [17.071663224837664, 47.72860916522346],
            [17.068609194588078, 47.768327148807359],
            [17.056800266447397, 47.794582119473276],
            [17.054445119286555, 47.84721812462918],
            [17.060554017975846, 47.876935994782784],
            [17.108054095765795, 47.971382106561052],
            [17.14694512078006, 48.00000010337682],
            [17.16638225138405, 48.0125002032553],
            [17.181109087666499, 48.020764256895191],
            [17.239191150606871, 48.025691139595594],
            [17.251654034835155, 48.024991083040376],
            [17.263191221674191, 48.007500062721149],
            [17.311945064544574, 47.998053993092171],
            [17.341945069559699, 47.99575399885434],
            [17.411882161108366, 47.944745088747354],
            [17.445209111141367, 47.913053950942796],
            [17.462082217556741, 47.892082093863038],
            [17.485863353312567, 47.87796411902913],
            [17.712218157940356, 47.770554052849249],
            [17.787282292037759, 47.746427079767543],
            [17.89694507852127, 47.747554110471683],
            [17.908882249781101, 47.750691121562838],
            [17.976109136749471, 47.763609143134815],
            [18.086109211017799, 47.75943612880819],
            [18.125409105270194, 47.756109183790599],
            [18.195827150424435, 47.74777321342124],
            [18.304718131261467, 47.737500185222629],
            [18.337500257586441, 47.740827130240334],
            [18.378609134187201, 47.74721816379143],
            [18.413327144874955, 47.753882112110531],
            [18.449300261747652, 47.76735400984596],
            [18.54083617673524, 47.767491137782329],
            [18.597218222068932, 47.76305409350492],
            [18.636945090470164, 47.759164219868396],
            [18.663054048382463, 47.759718096031818],
            [18.682918153135262, 47.766382211989097],
            [18.729654136629534, 47.795482168238848],
            [18.757782295022423, 47.814354023291017],
            [18.788054041339365, 47.817218119614054],
            [18.849718194430807, 47.817773169243864],
            [18.854718167326979, 47.83166416213929],
            [18.820000156639225, 47.855554094998539],
            [18.783500153331431, 47.872082202278321],
            [18.75965414164483, 47.914718094003931],
            [18.771663229633845, 47.965554001628888],
            [18.827363323323226, 48.036127111992229],
            [18.84533613543843, 48.049127108577679],
            [18.907282255753501, 48.057973201575621],
            [18.965673275645742, 48.059800121196801],
            [18.989800248727533, 48.067100088330776],
            [19.001273230188048, 48.068953997680282],
            [19.041709207600007, 48.070636078014232],
            [19.139454101201267, 48.061673141285596],
            [19.208336078775346, 48.059445063777432],
            [19.472427217432795, 48.089436016337046],
            [19.497291127443873, 48.135900090891582],
            [19.508609211333265, 48.173044997831582],
            [19.530218093056078, 48.208927087235494],
            [19.630554168212257, 48.233891077170725],
            [19.653327128651256, 48.232918105847503],
            [19.755000117367558, 48.209445088853201],
            [19.786836094459545, 48.196909114428976],
            [19.910627081698948, 48.130482196301827],
            [19.939863327694667, 48.136245090027259],
            [19.969027154046756, 48.153473253862174],
            [20.010136198285494, 48.173882182322998],
            [20.059327073588719, 48.176309078575215],
            [20.09332709044844, 48.198327164812142],
            [20.14055408583198, 48.226109150602881],
            [20.235554073773784, 48.274991068954179],
            [20.283018109379896, 48.255718056014715],
            [20.317218118450029, 48.269718181289718],
            [20.340827089914058, 48.286109160633302],
            [20.363054053178757, 48.304582204731261],
            [20.402500127822947, 48.361245043821995],
            [20.414163210847988, 48.399718147142764],
            [20.435836130311259, 48.433609031622822],
            [20.451109131406014, 48.448745072419356],
            [20.4994450525829, 48.483054046231004],
            [20.500827060782228, 48.504436113653256],
            [20.539718085796608, 48.536944989933957],
            [20.652918203068253, 48.561664228295783],
            [20.713336134792087, 48.569164187640112],
            [20.818054101302494, 48.576173135094976],
            [20.843473228581445, 48.56763599904879],
            [20.870000108187156, 48.550827098011965],
            [20.946391265198969, 48.524718140099665],
            [21.084445066559681, 48.513609100876025],
            [21.153327211771852, 48.511109114427953],
            [21.253891274695007, 48.522218153651593],
            [21.308709089253654, 48.548044976702087],
            [21.438191221127028, 48.575345003058871],
            [21.507218205626316, 48.548882161193589],
            [21.617291202452606, 48.492500115860096],
            [21.625409243338709, 48.458882146148071],
            [21.722354168098747, 48.35533613734286],
            [21.780973175758049, 48.340691108435877],
            [21.828127080945734, 48.343464177290045],
            [21.852500146705523, 48.362145092587568],
            [21.935836045793764, 48.379164211756731],
            [21.965000207421838, 48.381944991961845],
            [22.029445140718508, 48.387500182125891],
            [22.06486320796148, 48.385136150147716],
            [22.098609253154308, 48.377982195767558],
            [22.150554086572498, 48.408609167141933],
            [22.151445082882475, 48.411918174886821],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "TJK", Country: "Tajikistan" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.982036179338678, 40.244844999031145],
            [70.994982196467475, 40.228618137352257],
            [70.985672248946372, 40.189935989365651],
            [70.957354156627076, 40.174800116207265],
            [70.921654122160561, 40.168973184741489],
            [70.886109152903288, 40.171318105980461],
            [70.663382203617942, 40.100691183798446],
            [70.660263297437695, 40.044845077355447],
            [70.654300243863901, 40.007545105206347],
            [70.639709194413371, 39.985045059535381],
            [70.622482204044871, 39.970454010084936],
            [70.603045073440995, 39.958236045068062],
            [70.494282335722829, 39.908145120999265],
            [70.468036249874359, 39.927936135556322],
            [70.488591191088886, 39.969200077366381],
            [70.517691314976588, 39.98844509474894],
            [70.546509136364563, 40.002954001548133],
            [70.558245141947481, 40.028100046420789],
            [70.542209220023636, 40.046018040888939],
            [70.512354221933805, 40.056709158419139],
            [70.426654123229326, 40.070182229620954],
            [70.351518240040519, 40.082127112231618],
            [70.329572238170982, 40.090735997369094],
            [70.309563294130925, 40.115673165214091],
            [70.285682246088896, 40.132553982980582],
            [70.052472385748985, 40.207764129831602],
            [70.011936160774695, 40.217991057562685],
            [69.817763338202866, 40.170273217927658],
            [69.692472325566854, 40.132764201112721],
            [69.636936181904076, 40.118882093034799],
            [69.605818198640264, 40.111936009853807],
            [69.569709294935734, 40.109300068935923],
            [69.540745125517816, 40.128882206465207],
            [69.499291249781407, 40.072909030369615],
            [69.485463288798115, 40.037845014717888],
            [69.50554515539622, 39.962627156515751],
            [69.524045189222505, 39.934509056406952],
            [69.498318278458072, 39.920827108177193],
            [69.424845197225949, 39.90505404273766],
            [69.407482252387609, 39.937973129360941],
            [69.334818192452104, 39.992600171802636],
            [69.284991298334177, 39.887218023282671],
            [69.267763302137354, 39.838327052476089],
            [69.25721819736097, 39.798609068892077],
            [69.249500308533442, 39.757635979123236],
            [69.262491252663295, 39.733464246678366],
            [69.298736110837837, 39.704300085050093],
            [69.309982277997761, 39.686800179913561],
            [69.314145234040581, 39.659709030584438],
            [69.314145234040581, 39.630273127654547],
            [69.309700310774105, 39.599300151316001],
            [69.30150917969209, 39.560409126301735],
            [69.308591217342922, 39.534854044552858],
            [69.333254129315236, 39.518809070173504],
            [69.516372227396147, 39.538609137185958],
            [69.551227198382179, 39.550136098103209],
            [69.581236088214808, 39.573464108171933],
            [69.609145143657969, 39.582218167872881],
            [69.672209242221669, 39.586382129744109],
            [69.708036178702656, 39.587773190398948],
            [69.750272253645647, 39.581936033011189],
            [69.78192718162839, 39.572491136848683],
            [69.801091229826085, 39.56220905619459],
            [69.824436171339386, 39.545273085505173],
            [69.846645197331185, 39.537218076531119],
            [69.966091173590513, 39.554436014444249],
            [70.181363258170705, 39.584364102729907],
            [70.237491331837447, 39.577382145003256],
            [70.301927212678692, 39.551509053847056],
            [70.356091238787741, 39.582036112935455],
            [70.482072249694994, 39.60663599299572],
            [70.507491209335882, 39.607054082327224],
            [70.632836201428432, 39.579773166709757],
            [70.661372223230927, 39.549418104275176],
            [70.671645083791446, 39.518154108257519],
            [70.672672369847504, 39.507045069033879],
            [70.694700179092166, 39.489264202502042],
            [70.721100156683377, 39.425373145368795],
            [70.848600303726442, 39.410935987660977],
            [70.99414517961398, 39.400936041868576],
            [71.028036231732102, 39.410945040116374],
            [71.043591200050486, 39.425409187552518],
            [71.055818217522841, 39.453473140566771],
            [71.073872334098979, 39.489582211909365],
            [71.102618238757572, 39.512654071016598],
            [71.196091211574497, 39.533218064686523],
            [71.326382197106653, 39.56962704052556],
            [71.406372375713971, 39.606300046315468],
            [71.484554074887143, 39.617973187624401],
            [71.514709145111482, 39.604364161952603],
            [71.548727266882196, 39.572209169625168],
            [71.548809241895384, 39.54761800674423],
            [71.518463399554292, 39.508864109666135],
            [71.517491266421274, 39.488653999949193],
            [71.537900194882099, 39.464409177308383],
            [71.56387236013407, 39.454345026137545],
            [71.597072240514507, 39.449209098771391],
            [71.654436141988839, 39.450191122550208],
            [71.681091264713444, 39.453236100344313],
            [71.714572274127164, 39.461436116243746],
            [71.757763383120476, 39.45584505153407],
            [71.775263288257207, 39.417127029001719],
            [71.773182229330956, 39.391435992782945],
            [71.743872222587157, 39.360873226786978],
            [71.732827221103861, 39.333782077457968],
            [71.742754076700436, 39.308782212976993],
            [71.778518148907352, 39.27763606651844],
            [71.86130922428768, 39.288373116878276],
            [71.899427270188966, 39.306564193752635],
            [71.933872366108602, 39.326854099187969],
            [71.971382221113771, 39.347973142487817],
            [72.077618150293603, 39.371444986015646],
            [72.098318266071516, 39.348254103883122],
            [72.139709277533967, 39.274636015725591],
            [72.258882339025007, 39.195473130963975],
            [72.30636330607561, 39.257144995406364],
            [72.314136180188001, 39.286591124258152],
            [72.324991247744805, 39.316873096496877],
            [72.347763369993601, 39.338400170844423],
            [72.424491311875869, 39.358745061564903],
            [72.453327238174751, 39.352718137888772],
            [72.48441823171035, 39.349654049355379],
            [72.598600205122608, 39.365909074228981],
            [72.633182261340465, 39.390764099422526],
            [72.65734510896803, 39.401318089016442],
            [72.688872296745984, 39.397709176775024],
            [72.777482256964987, 39.37798219995851],
            [72.804982275532012, 39.371318084001231],
            [72.862354223633389, 39.36201802712587],
            [73.163591275059304, 39.354518067781626],
            [73.185527218644864, 39.358273160414839],
            [73.357554214775035, 39.404027120130181],
            [73.367763372871224, 39.437845082052561],
            [73.491363252717775, 39.469927151822318],
            [73.524709145851915, 39.473254096840023],
            [73.654163282168639, 39.460764222883327],
            [73.655682250666388, 39.454827153209592],
            [73.668872348816166, 39.365491152535455],
            [73.669009141476494, 39.341591161392316],
            [73.645536124482277, 39.292991210264645],
            [73.62817234145345, 39.261045094964786],
            [73.620045080473773, 39.235691178892651],
            [73.728382185147524, 39.076253969005322],
            [73.776100192420557, 39.04965399920367],
            [73.841863263814304, 38.996109060464775],
            [73.85234516904066, 38.972564126741204],
            [73.845745090823726, 38.947364102411896],
            [73.8192182112181, 38.93729106642364],
            [73.781100165316701, 38.936591177506401],
            [73.749845221754555, 38.9293731853857],
            [73.723309289693503, 38.912427156412477],
            [73.70818230135248, 38.886527075527951],
            [73.708600223045977, 38.858409143057131],
            [73.723454128980762, 38.838264244547119],
            [73.763045210550246, 38.761609057584408],
            [73.802182328242594, 38.649836043151439],
            [73.804145202333672, 38.634100193362016],
            [73.814009193656176, 38.612709073484368],
            [73.842209268778362, 38.591045038838516],
            [73.939418223489014, 38.539936048807462],
            [73.967345216205075, 38.53410006488609],
            [74.078745235945661, 38.545073149640004],
            [74.075891197906429, 38.567082183421334],
            [74.062136159481071, 38.594236029386323],
            [74.122754083415174, 38.666882152049013],
            [74.145191264812098, 38.676809175283566],
            [74.354709172846327, 38.674382111393342],
            [74.394991258515546, 38.659927016412709],
            [74.677763312470432, 38.563818102677786],
            [74.793172228872862, 38.523682197400248],
            [74.854427177450219, 38.472564154913599],
            [74.868172325229835, 38.420900115252536],
            [74.862218156473574, 38.385264118526592],
            [74.84123624111001, 38.361882129001046],
            [74.81915411713257, 38.343264245615771],
            [74.802900265725469, 38.324927155987709],
            [74.794291212949901, 38.300627012785796],
            [74.792482230601621, 38.195209157358178],
            [74.817691307386355, 38.073964257034291],
            [74.844436116475237, 38.051609050650725],
            [74.863872241250732, 38.043973136836584],
            [74.904854215837162, 38.025909129614732],
            [74.915963255060802, 38.007854007210099],
            [74.909991316669704, 37.915218051246583],
            [74.906163301478642, 37.841809175392982],
            [74.927063241828847, 37.820500198166584],
            [74.959854085333149, 37.806600152815761],
            [74.986500155602442, 37.78657310386474],
            [74.939418167144112, 37.686253960152968],
            [74.91442718748047, 37.670345107881516],
            [74.903600283118607, 37.652427113413282],
            [74.921100188255167, 37.604100077053815],
            [74.935536172496512, 37.578809025255836],
            [74.954282299000766, 37.559644977057971],
            [75.038245164448284, 37.522082148424559],
            [75.069436237907979, 37.505764259277001],
            [75.187482268695049, 37.406582204553288],
            [75.096645236796093, 37.321854071343822],
            [75.078872249253237, 37.312682089949433],
            [74.94525415106537, 37.247382202526509],
            [74.915736105484058, 37.237327103810983],
            [74.839427258761674, 37.319745016860836],
            [74.823045164235594, 37.331836079863294],
            [74.669572349026311, 37.392118057117344],
            [74.639436221903196, 37.386264135923241],
            [74.54676338556564, 37.381736064177048],
            [74.432209255651372, 37.394009182117045],
            [74.394991258515546, 37.401227006599683],
            [74.322491316244651, 37.406773144308218],
            [74.254154162378683, 37.409409085226216],
            [74.231227142558765, 37.39676414606042],
            [74.146100198394919, 37.336182096671862],
            [74.040263247807474, 37.311436036219803],
            [73.871927138931852, 37.267236081357524],
            [73.842209268778362, 37.250491050422866],
            [73.817063391543769, 37.228191164600318],
            [73.738591175605706, 37.221645065840207],
            [73.631300300089293, 37.242891178792448],
            [73.620600297741731, 37.263518036736443],
            [73.646654102730821, 37.300127004785921],
            [73.718736123308219, 37.308818032574735],
            [73.769854165794783, 37.343491116261347],
            [73.776318121903699, 37.432864164947588],
            [73.747209280836643, 37.438644995945751],
            [73.711654085657386, 37.437800100103303],
            [73.669700313214179, 37.435364151395689],
            [73.613036133018994, 37.437091158730865],
            [73.571382265072231, 37.448345037241737],
            [73.541236247303374, 37.460982097418409],
            [73.50512717596061, 37.472709050546015],
            [73.456372327261903, 37.476109085759461],
            [73.435527204558667, 37.475818066080549],
            [73.299282217908143, 37.460391173243053],
            [73.276927179162641, 37.453018115913139],
            [73.157763337765033, 37.402982177129488],
            [73.107754221071701, 37.362700091460269],
            [73.001391222239391, 37.304064152356446],
            [72.892491188946877, 37.250009090988996],
            [72.834227238707143, 37.23506415758537],
            [72.799772252141707, 37.229218115380306],
            [72.778045185583807, 37.210400072146669],
            [72.758882310852499, 37.180682201993065],
            [72.733872388087747, 37.135382206154858],
            [72.722209137424585, 37.112327110854082],
            [72.708036177305672, 37.090654023752919],
            [72.662463266699234, 37.025345083874399],
            [72.518036201422291, 37.001736112410285],
            [72.43136329903254, 37.002835985748121],
            [72.41024509392301, 37.002554018524407],
            [72.346636171651284, 36.989491157665199],
            [72.306354085982065, 36.978100151217845],
            [72.279972380939824, 36.96700016444963],
            [72.223854197918939, 36.942718126158795],
            [72.04774521493087, 36.830382183107005],
            [71.931363327205048, 36.750418156037497],
            [71.842754205176362, 36.692100058703048],
            [71.82026337959897, 36.684600099358804],
            [71.781663374263616, 36.678218118263132],
            [71.759154108499246, 36.674891173245513],
            [71.695254166548494, 36.67184502198505],
            [71.677200217610419, 36.676009151494256],
            [71.615300197762991, 36.697845015155664],
            [71.576572284584898, 36.731018073445753],
            [71.552918218481665, 36.76358210264938],
            [71.466382276390135, 36.92669108905217],
            [71.456936206761128, 36.94473598553482],
            [71.430818196393517, 37.06693608754712],
            [71.437500249623639, 37.166118142270946],
            [71.447763387176423, 37.20959105084988],
            [71.463491190338772, 37.23060917603523],
            [71.48276336508772, 37.259036065457977],
            [71.489291191299031, 37.278491133334867],
            [71.49188220521583, 37.309300159646781],
            [71.49331819287184, 37.370300130728666],
            [71.487200241727038, 37.418082175742214],
            [71.496182289194991, 37.563273167676769],
            [71.504391189911843, 37.609391069629183],
            [71.541927196455021, 37.726745090487938],
            [71.575963255498465, 37.797600168075107],
            [71.588591263219797, 37.815653949375175],
            [71.591091249667869, 37.836773160313001],
            [71.59360028857148, 37.874273124672541],
            [71.584854107859627, 37.911736041019864],
            [71.540263388669956, 37.944145005014278],
            [71.507191248494365, 37.947509165682206],
            [71.435809284472185, 37.925382114703595],
            [71.389709152154609, 37.907045025075448],
            [71.329018138024566, 37.890091117113215],
            [71.252145189216861, 37.927936080608319],
            [71.266100219852632, 37.982318036200539],
            [71.315263267237214, 38.085127108076662],
            [71.339427120693045, 38.131254062484388],
            [71.35581826767455, 38.155073084442662],
            [71.365818213466952, 38.195291132371537],
            [71.361927166363927, 38.247945074800256],
            [71.305954157906541, 38.297118012830552],
            [71.271518282080393, 38.302391068132906],
            [71.248182225384397, 38.304191165664051],
            [71.212491243373364, 38.319744960515848],
            [71.177763342039924, 38.347945035638091],
            [71.151272336979872, 38.381027066459509],
            [70.988172235394558, 38.466709060253109],
            [70.960127225481443, 38.471982115555463],
            [70.779436191290728, 38.441545078107524],
            [70.711382345752639, 38.409864166224949],
            [70.64749128861942, 38.354291142188046],
            [70.599154193975977, 38.310954020440946],
            [70.542627141717134, 38.252818145682099],
            [70.49025416068352, 38.161891092142767],
            [70.341936215941558, 38.018673200221102],
            [70.311236154371301, 38.00033611059304],
            [70.297209207006091, 37.998591165985133],
            [70.279982216637535, 37.9764361194499],
            [70.244563311204359, 37.943282171898929],
            [70.209427211184988, 37.928936041659981],
            [70.16253616248153, 37.928953978932881],
            [70.165545098091883, 37.871718152939323],
            [70.177754178291337, 37.852000060940142],
            [70.204845159982284, 37.834082234109971],
            [70.235745213762954, 37.822209100590769],
            [70.269154138809114, 37.771309155225467],
            [70.282209288317432, 37.728982220451812],
            [70.286091282965032, 37.699700041626542],
            [70.253600176319168, 37.615745055168063],
            [70.155818234705777, 37.536227112986879],
            [70.127909179262616, 37.528918093397479],
            [70.087063326784147, 37.532864125785451],
            [70.062454058992301, 37.541336050624622],
            [70.035545132238809, 37.541791187968343],
            [70.013882271059373, 37.54554510713497],
            [69.95123609418917, 37.572773216762286],
            [69.933027247679973, 37.602636093841213],
            [69.911936199936775, 37.613191089263424],
            [69.891654173490366, 37.614027100288453],
            [69.867482273407489, 37.608327070837092],
            [69.846100205985266, 37.60000015292313],
            [69.793045105669336, 37.582773162554687],
            [69.671891232814318, 37.57440014417331],
            [69.581100301382918, 37.578609033045481],
            [69.522209217145786, 37.582354067394689],
            [69.381509248945207, 37.453464200801349],
            [69.37664523051896, 37.419718155608635],
            [69.374418158839006, 37.388045122714871],
            [69.381363236191504, 37.344153957166256],
            [69.389982347250879, 37.324436032805252],
            [69.405818276964482, 37.276936122653396],
            [69.426091250955466, 37.207218134054287],
            [69.421245169802063, 37.196409166965068],
            [69.409991291291107, 37.175273192220899],
            [69.388600171413458, 37.158600078015795],
            [69.322418172497777, 37.119436138233212],
            [69.28665410029086, 37.104164142966837],
            [69.254427191233759, 37.095682159843776],
            [69.20499122908106, 37.116391160439221],
            [69.151045132455096, 37.150764171991213],
            [69.124363355278348, 37.164227017271131],
            [69.031518187011017, 37.253609118412797],
            [69.005818265974909, 37.300691106871128],
            [68.969218182742935, 37.322909185318494],
            [68.89110923848952, 37.338464153636878],
            [68.809418204084949, 37.32249109598699],
            [68.662209185136277, 37.272909121080474],
            [68.639436224697278, 37.243882087388599],
            [68.627345161694819, 37.206100155805544],
            [68.414991320532323, 37.119991187863022],
            [68.334427149193885, 37.108891201094892],
            [68.284145117732265, 37.030545048981011],
            [68.182482187299769, 37.017627027408921],
            [68.058009248415971, 36.932527072973457],
            [68.0013911686884, 36.93610011066913],
            [67.786936152031871, 37.09220903443439],
            [67.774227175125731, 37.115545091130301],
            [67.779872219292059, 37.185818129359134],
            [67.787418279104003, 37.190818102255363],
            [67.814009196450087, 37.218745094971339],
            [67.837072338377794, 37.259018128185161],
            [67.841372254718891, 37.281664186609746],
            [67.843736119059002, 37.321518124663655],
            [67.84081821091749, 37.352491101002087],
            [67.829572379033635, 37.39069112191676],
            [67.817491206676976, 37.416382158135335],
            [67.817491206676976, 37.446100195927031],
            [67.826382226676145, 37.482073145161635],
            [67.838318224469418, 37.505827123551157],
            [67.86095405697202, 37.543045120686983],
            [67.903045124989688, 37.599718018061523],
            [67.917209200291182, 37.61720903838075],
            [67.937191322241205, 37.636109056627831],
            [67.98122715943893, 37.673327053763657],
            [68.00179132074706, 37.68749112906518],
            [68.051927171816914, 37.738882086319947],
            [68.073045209288267, 37.765273179093853],
            [68.095409132851245, 37.802354048293282],
            [68.12302716861538, 37.858891158836101],
            [68.129972245967878, 37.884436014663081],
            [68.14255415322171, 37.916309039767086],
            [68.171100233307953, 37.934018157207575],
            [68.238391325654817, 37.942145082910983],
            [68.26846324739941, 37.952282156639782],
            [68.337818131952048, 38.046154108048924],
            [68.354982258046533, 38.085545029769989],
            [68.379982290165486, 38.160544958488984],
            [68.382972282674615, 38.19485409993861],
            [68.364291199739199, 38.237491165130692],
            [68.337063257749975, 38.267627124615714],
            [68.277482342879551, 38.303673164046174],
            [68.219845191360918, 38.330127121094208],
            [68.124909241159656, 38.427564231209928],
            [68.071236226939988, 38.543882081195321],
            [68.0741541350815, 38.664709059825597],
            [68.12004522273341, 38.7979821588779],
            [68.149709281068368, 38.806235986595809],
            [68.174282339038399, 38.820691081576442],
            [68.193645205980062, 38.851727089827122],
            [68.185118128217681, 38.898464246787782],
            [68.18026332988498, 38.904709099947084],
            [68.153872237111017, 38.931382159944505],
            [68.140127256969407, 38.950264240918656],
            [68.126363333726573, 38.980964134850907],
            [68.110536121192382, 38.998882129319057],
            [67.984418149986737, 39.008045058258048],
            [67.934709273065863, 38.996936019034408],
            [67.8608182701403, 38.977627131549397],
            [67.75082724832734, 38.99027324418158],
            [67.717345233085354, 38.99513608914144],
            [67.6999822882469, 39.006244960727102],
            [67.695263276745976, 39.015991102490588],
            [67.696863382066653, 39.128464173478775],
            [67.625954157384882, 39.168464124286217],
            [67.59554511549365, 39.173191182414243],
            [67.56666325636516, 39.171664167289507],
            [67.521854104778328, 39.167700197628633],
            [67.375882254741697, 39.216691080721503],
            [67.364700292960293, 39.271664128127341],
            [67.366000158508399, 39.288782153754298],
            [67.409145167034154, 39.296936069186117],
            [67.431509258235138, 39.367909163970168],
            [67.439972298257061, 39.437209063237773],
            [67.442472284705076, 39.462491062580355],
            [67.441954115449306, 39.483582110323582],
            [67.504436174654955, 39.52888210616176],
            [67.681927165842382, 39.615273208965974],
            [67.709718204088603, 39.625827030921826],
            [67.72970921085593, 39.629153975939417],
            [67.759154166241302, 39.63054503659437],
            [67.838036089607698, 39.619500202749251],
            [67.863036121726594, 39.611382161862977],
            [67.907763298300239, 39.600545031579244],
            [68.082491278917331, 39.567218081546244],
            [68.148882322498736, 39.556654033668607],
            [68.175263356988722, 39.553600171057113],
            [68.353318282623462, 39.536664200367696],
            [68.384718233110931, 39.535554101108076],
            [68.473309250228709, 39.537500043754747],
            [68.520272383299613, 39.547773239591336],
            [68.54026339006694, 39.554709096850345],
            [68.611782314387341, 39.630273127654547],
            [68.627618244101001, 39.650545095817066],
            [68.628582330606918, 39.771100165507718],
            [68.637209153017324, 39.839427093451903],
            [68.642672309884205, 39.858236084230029],
            [68.656100286446843, 39.862500126025409],
            [68.723045206191699, 39.868600139897396],
            [68.73553608597669, 39.849300137229804],
            [68.757554172213617, 39.829645077142672],
            [68.904672331331682, 39.89228220155745],
            [68.856654084286077, 39.954853947127233],
            [68.829436200580659, 39.977764203140609],
            [68.811645108126896, 40.000827009792332],
            [68.7845722313468, 40.067909057473557],
            [68.822963359654381, 40.078653986822232],
            [68.932754221618865, 40.075273229985882],
            [68.995045173431521, 40.070618088587295],
            [69.008736174116422, 40.103673129680473],
            [68.991091262054539, 40.140827089075785],
            [68.876227169359851, 40.147626991864897],
            [68.795372313618572, 40.128818168724749],
            [68.762209145974197, 40.122218090508014],
            [68.713882277252736, 40.116391159042124],
            [68.7023633629627, 40.117709129501222],
            [68.681363342688229, 40.1219361232843],
            [68.636654103387485, 40.139991078050755],
            [68.605672242231634, 40.165754030998968],
            [68.642000248885807, 40.184064130898818],
            [68.695536135169192, 40.196100040978152],
            [68.727909224617946, 40.202500126984646],
            [68.768327264757005, 40.208045091226822],
            [69.03581827099012, 40.238744985159073],
            [69.061645094040529, 40.234718151224328],
            [69.078936122149486, 40.230173148033813],
            [69.13414520394943, 40.208744980143948],
            [69.194154098797156, 40.195544991348342],
            [69.226927172666734, 40.190545018452198],
            [69.249145083475838, 40.191100068082093],
            [69.302482318653432, 40.199018116757841],
            [69.31943622661575, 40.209718119105375],
            [69.338736229283342, 40.333464179343721],
            [69.328327246614919, 40.374709178052385],
            [69.259709298991623, 40.502773091904587],
            [69.269991212007568, 40.595545002337914],
            [69.348872297183647, 40.763954033771384],
            [69.365263276527145, 40.779436079531976],
            [69.400609259402557, 40.799854060448311],
            [69.451509204767973, 40.811100059970144],
            [69.537200251016969, 40.780818087731333],
            [69.571518277284071, 40.75124505686513],
            [69.588327178320952, 40.724991092027537],
            [69.66900919921838, 40.655273103428513],
            [69.689700262540981, 40.642636043251727],
            [69.72678230520691, 40.63880903388916],
            [69.759636180623289, 40.651936100126917],
            [69.785954183201142, 40.679718085917656],
            [69.818109175528861, 40.712491159787149],
            [69.847209299416505, 40.722491105579465],
            [69.900272278721417, 40.733882112026706],
            [69.939972325032585, 40.740273145577802],
            [69.972627214066875, 40.746100077043579],
            [70.000000162981536, 40.754109153188281],
            [70.050263251341875, 40.769982130913903],
            [70.252900287402014, 40.858154052871768],
            [70.323472224299081, 40.899244992199712],
            [70.360818129277703, 40.934945026666199],
            [70.374418270132111, 40.961818078874145],
            [70.37573624059101, 41.009182202194083],
            [70.391163301066655, 41.036618015382587],
            [70.417627148760261, 41.047727054606227],
            [70.439009216182484, 41.049254069730964],
            [70.46081825775363, 41.043282131339978],
            [70.465145163822996, 41.038745007138388],
            [70.475118119887071, 41.034464201536636],
            [70.54860025357462, 40.977436079104464],
            [70.658036225757741, 40.870227010963319],
            [70.667209212980509, 40.82744510648412],
            [70.712491271545872, 40.770364178061683],
            [70.759718266929525, 40.76148221051811],
            [70.784154196963271, 40.749182102849872],
            [70.796800309595568, 40.725591068658574],
            [70.777482202016898, 40.706327108174818],
            [70.734009293438049, 40.675082222896478],
            [70.534991227902907, 40.55938211917676],
            [70.415263284420149, 40.491054017766189],
            [70.369982231683053, 40.471327040949589],
            [70.343091242202405, 40.445809174851021],
            [70.375536248380655, 40.376400143203753],
            [70.460963264678952, 40.35035404956561],
            [70.589427162951864, 40.255209054698511],
            [70.602909286609162, 40.233127098359134],
            [70.623591297476167, 40.211882158873195],
            [70.644991302171348, 40.205909047015624],
            [70.708454211689258, 40.203964110197361],
            [70.74888231011218, 40.209100037563516],
            [70.79304521696244, 40.219100150993896],
            [70.822491178176136, 40.228273138216693],
            [70.860672255989499, 40.241191159788784],
            [70.957763361141332, 40.244945078955411],
            [70.982036179338678, 40.244844999031145],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ABW", Country: "Aruba" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-69.504141999999888, 12.5807420000001],
            [-69.519138999999882, 12.311847000000114],
            [-69.54428449999989, 12.169680500000084],
            [-69.547519004999913, 12.151386284000068],
            [-69.739166564999891, 12.262777363000225],
            [-70.140277564999906, 12.365000363000263],
            [-70.164166564999846, 12.350000363000106],
            [-70.41666656599989, 12.350000363000106],
            [-70.41666656599989, 12.816666364000184],
            [-69.577222564999886, 15.413333366000245],
            [-69.341032887999916, 15.356442375000142],
            [-69.34082, 15.349717000000112],
            [-69.34081, 13.496881],
            [-69.38831, 13.306597],
            [-69.45414199999982, 12.962142],
            [-69.49581, 12.6388],
            [-69.499630999999852, 12.612164],
            [-69.500313999999889, 12.607417],
            [-69.504141999999888, 12.5807420000001],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "AFG", Country: "Afghanistan" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [74.915736105484058, 37.237327103810983],
            [74.808727197191246, 37.224227027301552],
            [74.773463356967255, 37.273064186289687],
            [74.760327238274016, 37.299100054005933],
            [74.739709265147411, 37.301400048243764],
            [74.669436226918407, 37.265973096183373],
            [74.607482227614327, 37.250954066755511],
            [74.527827157496944, 37.240800061582391],
            [74.390891334384861, 37.17000013691846],
            [74.394991258515546, 37.146464255650173],
            [74.528872380825732, 37.049236022562141],
            [74.565427201780466, 37.027818080594258],
            [74.571727207862892, 37.01902714051927],
            [74.554836164174532, 36.965054054165066],
            [74.530745233276519, 36.968254097168355],
            [74.507354191295661, 36.998736061617436],
            [74.476645077269751, 37.010473240666826],
            [74.416927202101107, 37.000044979620938],
            [74.205827126019045, 36.903327036799553],
            [74.122618128945277, 36.843582171902611],
            [74.06219131240411, 36.821344982716042],
            [74.03526327491133, 36.815373211963035],
            [73.957627237636331, 36.839809141996582],
            [73.927482225695854, 36.863418113460696],
            [73.835327223337885, 36.896118097134334],
            [73.668309252559339, 36.909636095337376],
            [73.643100175774549, 36.902664195894644],
            [73.636645104483108, 36.899635981906883],
            [73.539972256300956, 36.880453996436287],
            [73.484836097058775, 36.891836118066223],
            [73.385818159999786, 36.889191124692829],
            [73.05469124176679, 36.868773143776409],
            [72.93544509007981, 36.854818113140638],
            [72.918309294818044, 36.843500196889337],
            [72.894427240947749, 36.836553946070339],
            [72.865391322438455, 36.834200140013976],
            [72.809127126663924, 36.832954086284545],
            [72.767900232866083, 36.838773138761198],
            [72.745818276526819, 36.842373166185197],
            [72.646100282912499, 36.833491198641426],
            [72.578600313537919, 36.825427137211889],
            [72.552827134667808, 36.817591063549415],
            [72.535809188965146, 36.802654009134798],
            [72.497482266036144, 36.775027088553273],
            [72.470409221617928, 36.761700197743167],
            [72.40662712922628, 36.762400086660293],
            [72.330554149259825, 36.755718033430284],
            [72.226372289277919, 36.72948217350357],
            [72.174136100904633, 36.709091182315589],
            [72.176082211189254, 36.688127036586863],
            [72.145809291405925, 36.64773598617596],
            [71.973309220659218, 36.541927031145164],
            [71.745009143936926, 36.416118016891645],
            [71.709745136074929, 36.436945034683973],
            [71.644182224529544, 36.465973241842235],
            [71.61986330586447, 36.452709047668108],
            [71.590854209445439, 36.388644988052746],
            [71.581127178421212, 36.346445122931655],
            [71.464191246893876, 36.27206411394495],
            [71.246091275812518, 36.131382083017186],
            [71.189709230478826, 36.052109060047584],
            [71.187791283388862, 36.039445010142501],
            [71.222382224424138, 36.023673118169356],
            [71.331936213804255, 35.955554061424422],
            [71.382754184156369, 35.920826998281186],
            [71.442745141731194, 35.866936054578431],
            [71.545818243557932, 35.699709039134049],
            [71.528045088377013, 35.662764124404319],
            [71.568327174046232, 35.586936063649418],
            [71.604709160157, 35.52693605361911],
            [71.648318190843924, 35.428182146510792],
            [71.62442725215621, 35.395691207502992],
            [71.576718129700538, 35.358609164836977],
            [71.548045147599822, 35.317836067278293],
            [71.553872246703662, 35.289435999945781],
            [71.612763330940851, 35.230818165752979],
            [71.573882196572214, 35.120827143940048],
            [71.535400208434027, 35.084091106238176],
            [71.503054108713542, 34.984164235596197],
            [71.498900205126148, 34.966344979947635],
            [71.493800152305909, 34.95770005262635],
            [71.475818120097216, 34.94582708674524],
            [71.402209252033146, 34.915264153111295],
            [71.291654128135093, 34.829991196193802],
            [71.170254162602106, 34.723873116572875],
            [71.091936173682853, 34.668536126930249],
            [71.09290026018877, 34.643882099775155],
            [71.10359121008085, 34.616800170539634],
            [71.087418160220665, 34.561936087875367],
            [70.991745105452054, 34.555500127323327],
            [70.982272381370848, 34.537909027079834],
            [70.978318134717796, 34.507909022064624],
            [70.998600161164205, 34.465545039278837],
            [71.07749130462409, 34.390682070858261],
            [71.103527172340449, 34.381173136955212],
            [71.154918129595302, 34.355618055206534],
            [71.134709193344662, 34.163736006778763],
            [71.081172301232954, 34.056173222322613],
            [70.882345175452741, 33.965000076105426],
            [70.794145090300191, 33.953044967572865],
            [70.558318232143421, 33.944436082435388],
            [70.481791288299775, 33.943600071410359],
            [70.338318251244829, 33.963882097856683],
            [70.1997092326163, 33.992491209855032],
            [70.120818256794422, 34.01610906613648],
            [70.059145218885618, 34.035273114334345],
            [69.999927240423602, 34.051945055073062],
            [69.95166323597627, 34.044718010496936],
            [69.936918127144935, 34.041664147885442],
            [69.929982269885983, 34.041109098255632],
            [69.900954062727607, 34.029164215644855],
            [69.867200306183889, 33.935753939463879],
            [69.91665420560949, 33.852218048165398],
            [69.987063365946341, 33.753400103316537],
            [70.099436189372085, 33.726791081059574],
            [70.13338222677524, 33.733882171165689],
            [70.196636091627425, 33.64082712004226],
            [70.199291310922803, 33.606936067924224],
            [70.185527220041791, 33.573882200297604],
            [70.173309255024975, 33.546109099324283],
            [70.169700175145607, 33.519853961020189],
            [70.202627140757812, 33.477209016839296],
            [70.238591205175197, 33.465618018181786],
            [70.288036219783265, 33.420409049812164],
            [70.313863378109971, 33.383809134218282],
            [70.32477225748525, 33.332700144187029],
            [70.22790025528306, 33.251100137251299],
            [70.150545179403338, 33.205554048735124],
            [70.028872299102289, 33.135827007680618],
            [69.903736184037399, 33.095127000317873],
            [69.874282343834608, 33.089436023321909],
            [69.626372301664475, 33.08443605042585],
            [69.580827218976793, 33.086936036873922],
            [69.559982263911451, 33.079644954557367],
            [69.507072338158963, 33.036109181704362],
            [69.491782237981766, 32.996727144800715],
            [69.500827149723563, 32.945264103178303],
            [69.507491265680841, 32.915264098163178],
            [69.512700283242879, 32.872218163733194],
            [69.465818119356726, 32.836936050960119],
            [69.408045181006429, 32.790409112131599],
            [69.395409294296059, 32.766873230863425],
            [69.417345237881619, 32.740964097523587],
            [69.439145226997397, 32.715273228942905],
            [69.456100308426102, 32.674227048978125],
            [69.421372239454541, 32.616936070061527],
            [69.401236225761835, 32.591800083472762],
            [69.377763376405625, 32.568327066478545],
            [69.357209273381471, 32.558600035454347],
            [69.327209268366261, 32.548609142117343],
            [69.306927241919851, 32.540273171747984],
            [69.282772273281324, 32.520000197757085],
            [69.270536203353657, 32.500273220940571],
            [69.256518140805753, 32.470409170395257],
            [69.24789131839546, 32.441100169479668],
            [69.251027156020228, 32.411236118934468],
            [69.261382326870176, 32.383882113121146],
            [69.27777230038518, 32.355553962517902],
            [69.290672384684314, 32.321935992806075],
            [69.29068227533017, 32.290553979591422],
            [69.283054072866861, 32.217764191107975],
            [69.278318129921416, 32.197773184340676],
            [69.271100305438864, 32.170691087467063],
            [69.273036189801729, 32.139300189435104],
            [69.291645188369699, 32.04860011783434],
            [69.328245103963496, 31.940364098913321],
            [69.291309241689447, 31.924373103990533],
            [69.214563362534221, 31.858864171901857],
            [69.202909164326428, 31.83110917583933],
            [69.157482266473806, 31.769164228990846],
            [69.129154115870648, 31.734164251079378],
            [69.105754189072314, 31.711709132409638],
            [69.019091177328363, 31.65103605555241],
            [68.827763340340169, 31.605827087182988],
            [68.803736111906488, 31.614582152712259],
            [68.729991289372521, 31.701800046448014],
            [68.719709208718513, 31.721664151200898],
            [68.650545096282741, 31.782773087024466],
            [68.57610021719367, 31.823473094387211],
            [68.556372234548832, 31.827909132836041],
            [68.519154069774828, 31.824509097622482],
            [68.485354212763554, 31.812373107618882],
            [68.446300243550951, 31.78017318829022],
            [68.515827124757237, 31.768744966192756],
            [68.564636120550659, 31.760618040489149],
            [68.553872248100646, 31.736518057135768],
            [68.534572245432997, 31.726800078566967],
            [68.27138232900748, 31.773682074814943],
            [68.218872220037582, 31.816391056736407],
            [68.188000161813619, 31.829173123838487],
            [68.163627263692007, 31.82933606803671],
            [68.145827118782762, 31.80582700885877],
            [68.125327162853097, 31.767636040399609],
            [68.043872330480752, 31.686936082229252],
            [67.968736112015876, 31.633882155379936],
            [67.928318239514823, 31.641664249585759],
            [67.886172353850412, 31.639227127411644],
            [67.8613723139417, 31.628327132853869],
            [67.839709285124258, 31.607636069531353],
            [67.823045223374635, 31.580273178900612],
            [67.737409162410501, 31.531382208093916],
            [67.70832714343365, 31.524436124913009],
            [67.681654083436257, 31.523327031481685],
            [67.654982196905138, 31.531245080157518],
            [67.581372323012801, 31.52927315361093],
            [67.61415411406162, 31.429991186601129],
            [67.661091263232635, 31.401527081528073],
            [67.685809160489981, 31.403045044197412],
            [67.716518274515721, 31.412082076950313],
            [67.745263340983939, 31.41458206339847],
            [67.766518171115621, 31.410827138403349],
            [67.796854122811027, 31.38468213830744],
            [67.790554116728657, 31.34340914404207],
            [67.613036135812962, 31.271109026343524],
            [67.384718121817826, 31.215273145822309],
            [67.275409219287297, 31.214018039637281],
            [67.25648221131209, 31.222327020278314],
            [67.234291290231141, 31.218254085875955],
            [67.062763352617338, 31.241109021328313],
            [67.042136159397245, 31.264445078024309],
            [67.049909201147699, 31.302709136679411],
            [67.036372259843432, 31.318473149663546],
            [67.008045115068768, 31.322218016374663],
            [66.965545177813084, 31.320000164788198],
            [66.880809165614579, 31.292500146221172],
            [66.855818185951051, 31.280554090144093],
            [66.721582341497395, 31.207354091318066],
            [66.693863387618791, 31.126664191431715],
            [66.686782188158219, 31.091591123324505],
            [66.579018238025327, 30.986664112148262],
            [66.560954063165383, 30.975827149502337],
            [66.537491272092979, 30.970554094200068],
            [66.475818234184175, 30.961664247667201],
            [66.449845230741886, 30.959718137382637],
            [66.42096337161351, 30.951936043176701],
            [66.400263255835483, 30.943464118337445],
            [66.283182317382824, 30.570135993878907],
            [66.316445229675281, 30.51020907404434],
            [66.33957224170544, 30.494164099664985],
            [66.351791212550637, 30.442500060003923],
            [66.320891326408031, 30.227982179073635],
            [66.290545148790756, 30.186109040539137],
            [66.272491199852624, 30.149718001972914],
            [66.240672321843334, 30.065345093820909],
            [66.26123614787528, 30.040618144108137],
            [66.296518260648355, 30.020000170981476],
            [66.362063234920839, 29.959782063829991],
            [66.30247226176661, 29.893326982508043],
            [66.256654096672634, 29.851935971045677],
            [66.128036139019031, 29.820553957831024],
            [66.06805406626151, 29.805554039142564],
            [65.86249124307065, 29.753891172947974],
            [65.733872279588724, 29.721382129029294],
            [65.596936121200571, 29.68666411834154],
            [65.254991183422959, 29.598327072890655],
            [65.03414513321556, 29.541109016531934],
            [64.873309151322275, 29.569164252366946],
            [64.769472290476045, 29.578753987816768],
            [64.739972349805697, 29.58277311040068],
            [64.695527140455766, 29.586382190280077],
            [64.66053621499978, 29.587773083296824],
            [64.575000233959912, 29.5830540717959],
            [64.54942721493822, 29.580827000115917],
            [64.488172266361033, 29.574300179733015],
            [64.379700213045766, 29.553609116410499],
            [64.268600265439687, 29.521944968334239],
            [64.211382209081052, 29.498600194458916],
            [64.187200250714312, 29.48208214546311],
            [64.162754094758753, 29.457218067813969],
            [64.059145221679586, 29.414435995696678],
            [64.017073264401148, 29.430409053346651],
            [63.703045093417785, 29.484436119157479],
            [63.587209202866376, 29.503891187034284],
            [63.554709211403093, 29.501664115354217],
            [63.230436175415861, 29.473700074626137],
            [63.203327256452013, 29.470827093485681],
            [62.558600147213014, 29.412773193740108],
            [62.484436229519162, 29.406109077782716],
            [62.461382307684829, 29.411945061704088],
            [62.408045072507349, 29.427218062798758],
            [62.362500157457561, 29.440827088470471],
            [62.339709259745717, 29.447491204427749],
            [62.248327236500785, 29.473609047157467],
            [62.103609151544646, 29.514718091396119],
            [62.012218243482295, 29.540553966902209],
            [61.789436141274024, 29.603327045786799],
            [61.063045154996843, 29.807500149427213],
            [60.986382256683299, 29.828609134443255],
            [60.866300261609382, 29.863654039355666],
            [60.871654118458622, 29.866936057372229],
            [60.910818225879183, 29.907218143041447],
            [61.041936169980801, 30.04360914244559],
            [61.180273279669706, 30.185827073315622],
            [61.216663312407576, 30.22277316151154],
            [61.277491286835954, 30.284445025954014],
            [61.372218192371321, 30.381664206586649],
            [61.75832721046558, 30.790273102713698],
            [61.850127155404067, 31.023882109284088],
            [61.81110017591979, 31.177218131833044],
            [61.788327215480791, 31.217218082640571],
            [61.766800308771309, 31.256182197850805],
            [61.771100225112349, 31.31832713690973],
            [61.740827137690957, 31.353327114821113],
            [61.713609253985481, 31.383327119836324],
            [61.450518244017672, 31.418200195733178],
            [61.188600197583554, 31.452218149865715],
            [61.104163251691176, 31.463053939045167],
            [60.84880924129007, 31.496109147776352],
            [60.811382199488406, 31.659718030885827],
            [60.824163260762276, 31.774436110826699],
            [60.823463371845122, 31.948464202526694],
            [60.806936102755657, 31.996664169233568],
            [60.81443622973805, 32.019436123844358],
            [60.836654140547267, 32.09027309652042],
            [60.855000282630812, 32.190544966298077],
            [60.858327227648516, 32.225964207007522],
            [60.851800239627522, 32.261109024206021],
            [60.832773319366225, 32.321935992806075],
            [60.726382324977266, 32.623882153242434],
            [60.659709146534084, 32.818600134798444],
            [60.595827141856233, 33.01360913603348],
            [60.587909260818407, 33.043136066432155],
            [60.582500251046127, 33.066100134264175],
            [60.58055414076145, 33.088600179935057],
            [60.583263339513479, 33.149573161288615],
            [60.63138233703549, 33.207500159019887],
            [60.723873286073712, 33.308882128057164],
            [60.756945090973119, 33.344991199399757],
            [60.803318138058927, 33.390827134128656],
            [60.852700288393095, 33.484227016749557],
            [60.877563360213827, 33.500753950562952],
            [60.929827208867749, 33.504953954617903],
            [60.938882178893465, 33.517036132802858],
            [60.915545116369145, 33.547909029217138],
            [60.894018209659606, 33.555827077892971],
            [60.855827241200444, 33.558882113970967],
            [60.787773228024349, 33.561654009358747],
            [60.708973279671198, 33.560482219291558],
            [60.666100180085181, 33.56750005156384],
            [60.597209150055647, 33.597491171761632],
            [60.57054514251368, 33.611109082250849],
            [60.546109212479934, 33.625264105096889],
            [60.530827158929668, 33.639991109017231],
            [60.520263278690123, 33.660691057157166],
            [60.513327253793108, 33.689709038393644],
            [60.508691223133752, 33.739954021843047],
            [60.540554189953951, 33.776935984584711],
            [60.551654176722167, 33.79721801103112],
            [60.553591234551419, 33.821873211652601],
            [60.529163351144774, 33.961382111408696],
            [60.519991202112323, 34.010554043610611],
            [60.509182235023189, 34.060109028788602],
            [60.504163319025821, 34.091664212123348],
            [60.504436233793939, 34.122218093301896],
            [60.510973280098739, 34.14554509754241],
            [60.526100268439706, 34.169445088685634],
            [60.546109212479934, 34.19638217863384],
            [60.675127154554247, 34.314018166716309],
            [60.739300178911179, 34.320000163391299],
            [60.766663237179927, 34.322218014977679],
            [60.836100264383816, 34.322627051853786],
            [60.878873284045739, 34.319718028529607],
            [60.883609226991183, 34.355273223708863],
            [60.798254127422325, 34.463318135236946],
            [60.76777333643966, 34.477982107245268],
            [60.723873286073712, 34.527909081287433],
            [60.739991183010915, 34.544718149962435],
            [60.779163337058463, 34.556100103954279],
            [60.832909273836009, 34.561791080950158],
            [60.853191300282418, 34.567627064871331],
            [60.976654052192629, 34.650754086931912],
            [61.051100272386265, 34.789436028118374],
            [61.077491197521994, 34.879436043163807],
            [61.115545205682935, 35.016936135999188],
            [61.131663270258315, 35.085818113573268],
            [61.136036109157118, 35.13207314346208],
            [61.11638222253643, 35.163873246008151],
            [61.099782198527208, 35.176591107731795],
            [61.096236150559861, 35.236236060342563],
            [61.105827227114133, 35.281800086131554],
            [61.18360022468741, 35.348600166589151],
            [61.276518147874441, 35.517635996743834],
            [61.287909154321682, 35.555618088175223],
            [61.276554190058249, 35.607245079824068],
            [61.29638225262741, 35.628536119777536],
            [61.375054125499645, 35.636100116862409],
            [61.429345221261286, 35.554773192332775],
            [61.44680019939679, 35.551653950876457],
            [61.503327251655634, 35.509435980844373],
            [61.528463238244598, 35.483736059808294],
            [61.57666337258965, 35.450827031468904],
            [61.595827253149338, 35.444154030694179],
            [61.798327161273193, 35.411654039230896],
            [61.817500261926369, 35.418882089635517],
            [61.965691304653859, 35.459164175304736],
            [61.991663302267767, 35.458882208081022],
            [62.04270020793146, 35.441236122552581],
            [62.072491168280834, 35.419991183066728],
            [62.127773340276605, 35.374709124501365],
            [62.132563262678701, 35.368054060999569],
            [62.16360916157501, 35.335818099486985],
            [62.269991271146608, 35.256245004382876],
            [62.273809228053693, 35.201444959458954],
            [62.312118213709851, 35.145991126085633],
            [62.339018255645925, 35.169991197153038],
            [62.361109264440785, 35.198600141513211],
            [62.387218222352914, 35.227209085873582],
            [62.410545226593484, 35.250544974931401],
            [62.427218173160526, 35.265000069912148],
            [62.469536223116762, 35.287564153323373],
            [62.513054058696838, 35.2737450771575],
            [62.527909138098181, 35.258609036361051],
            [62.549573340382011, 35.236664207958071],
            [62.629991163690676, 35.225818025218743],
            [62.727073216386913, 35.257636065037715],
            [62.836936162718843, 35.324164236555518],
            [62.883191192607654, 35.357491186588234],
            [62.903318153844879, 35.374164133155361],
            [62.922491254498055, 35.38416424658574],
            [62.986109229225093, 35.415827053557521],
            [63.023045091499313, 35.427773109634686],
            [63.050545110066338, 35.429991128859044],
            [63.086800194162748, 35.432209148083516],
            [63.107354129548895, 35.456936097796458],
            [63.12442722817471, 35.53971812072119],
            [63.099300294041342, 35.55638218247087],
            [63.105336102534778, 35.633736084884148],
            [63.137218180094209, 35.652764178612003],
            [63.184718257884043, 35.67360913367726],
            [63.158327165110251, 35.764164198352589],
            [63.104854143100823, 35.831936076666963],
            [63.123109257715697, 35.86279120344652],
            [63.204436182245246, 35.86166417274238],
            [63.240273344648102, 35.856653973924338],
            [63.272763277827522, 35.852773152743296],
            [63.295827257945604, 35.851664226950064],
            [63.317218210185189, 35.852218103113486],
            [63.342491324710437, 35.857218076009545],
            [63.522773322025017, 35.902909171451128],
            [63.550136212655758, 35.917909090139588],
            [63.589991324176054, 35.958254040082849],
            [63.613054130827692, 35.967491065045991],
            [63.633327104818676, 35.969436001864366],
            [63.655545183265957, 35.969154034640653],
            [63.698045120521641, 35.970827062519206],
            [63.768882260835795, 35.978736058739528],
            [63.801382252299192, 35.987636131194208],
            [63.888973308365308, 36.03162704139082],
            [63.927209203825527, 36.038327031893758],
            [63.977773202510747, 36.039164216385259],
            [64.094982216444379, 36.123318021587735],
            [64.164572297200635, 36.164718085505498],
            [64.353318172727455, 36.230818109407991],
            [64.427127200639831, 36.246036125217799],
            [64.466382335529062, 36.251382103077916],
            [64.503600165026938, 36.280545091239716],
            [64.523882191473234, 36.299436057031286],
            [64.581372324409784, 36.36943601285391],
            [64.601382274278393, 36.396945083876446],
            [64.615263376527906, 36.423609091418555],
            [64.625954158781866, 36.45360004397817],
            [64.63304508125006, 36.486382170303145],
            [64.630391203059247, 36.532082150562061],
            [64.614572372428114, 36.567354037413239],
            [64.609845146662082, 36.59999115681272],
            [64.6169271843128, 36.636382195379028],
            [64.626091286718321, 36.663882213946053],
            [64.637354217684702, 36.68901803289684],
            [64.652072336787654, 36.710136070368279],
            [64.693591256092901, 36.76832709805025],
            [64.715545136951363, 36.802773199798267],
            [64.74432725143177, 36.850626993903106],
            [64.776382331473229, 36.893882140636961],
            [64.792900212831029, 36.919436048919337],
            [64.800045114755875, 36.955409165792119],
            [64.790263266084736, 37.007218044740412],
            [64.78553620795671, 37.028045062532826],
            [64.777627211736245, 37.062636003568102],
            [64.780682247814156, 37.102491115088398],
            [64.793727171400661, 37.12151803534978],
            [64.823045224771619, 37.138600186430992],
            [64.844436177011204, 37.148045082593583],
            [65.043591202844453, 37.235545111190945],
            [65.066791305070552, 37.242909116065263],
            [65.093318184676235, 37.246100106613071],
            [65.362200251564133, 37.248600093061143],
            [65.39138218282713, 37.248600093061143],
            [65.437254159739638, 37.244373099278064],
            [65.467754061461648, 37.244991180819923],
            [65.524154211706076, 37.247909088961521],
            [65.563045069082335, 37.261318122422878],
            [65.648527238303672, 37.352282223974427],
            [65.649918131320447, 37.396173221884865],
            [65.644291192065026, 37.42749119735906],
            [65.645691305175461, 37.45388212249479],
            [65.66665410979968, 37.494991166733726],
            [65.681091267507412, 37.516935995136706],
            [65.697545111124867, 37.532564221289007],
            [65.771927125939868, 37.537500156444722],
            [66.008045171413784, 37.455273183149714],
            [66.105254126124436, 37.421382131031592],
            [66.186372341264388, 37.371936110595087],
            [66.287482233723864, 37.326518097559713],
            [66.317627245664283, 37.326109060683606],
            [66.352345088714031, 37.336653997822012],
            [66.413882339791002, 37.343736035472844],
            [66.440845078363139, 37.318882183745572],
            [66.454500204502409, 37.321518124663655],
            [66.489836129094016, 37.344153957166256],
            [66.512418149778142, 37.361664255862863],
            [66.537736191304447, 37.366382093897428],
            [66.580272338381945, 37.368191076245793],
            [66.623454059643763, 37.360545104147846],
            [66.72110021442532, 37.355554016069007],
            [66.836382228813449, 37.363609025043061],
            [66.858036205175523, 37.365827044267618],
            [66.926927235205, 37.378873141320483],
            [66.970263351123691, 37.382491106017213],
            [67.012909133494958, 37.379709152345512],
            [67.092082244178556, 37.343273186778177],
            [67.20027233027011, 37.246653982776493],
            [67.216236167826509, 37.226382182251953],
            [67.225818191925413, 37.197491103030004],
            [67.246091165916397, 37.184991170789587],
            [67.26679128169431, 37.185827014176553],
            [67.292391290444328, 37.194409077223767],
            [67.30494520214134, 37.20328215995012],
            [67.380809137442014, 37.212491189356641],
            [67.400691179467827, 37.219018009739543],
            [67.456372330055814, 37.25054502987949],
            [67.526382344162442, 37.268600152283952],
            [67.649427174379156, 37.246100106613071],
            [67.725818163752905, 37.228873116244628],
            [67.749563257324922, 37.222627089618854],
            [67.773182287072899, 37.205200107040085],
            [67.779872219292059, 37.185818129359134],
            [67.774227175125731, 37.115545091130301],
            [67.786936152031871, 37.09220903443439],
            [68.0013911686884, 36.93610011066913],
            [68.058009248415971, 36.932527072973457],
            [68.182482187299769, 37.017627027408921],
            [68.284145117732265, 37.030545048981011],
            [68.334427149193885, 37.108891201094892],
            [68.414991320532323, 37.119991187863022],
            [68.627345161694819, 37.206100155805544],
            [68.639436224697278, 37.243882087388599],
            [68.662209185136277, 37.272909121080474],
            [68.809418204084949, 37.32249109598699],
            [68.89110923848952, 37.338464153636878],
            [68.969218182742935, 37.322909185318494],
            [69.005818265974909, 37.300691106871128],
            [69.031518187011017, 37.253609118412797],
            [69.124363355278348, 37.164227017271131],
            [69.151045132455096, 37.150764171991213],
            [69.20499122908106, 37.116391160439221],
            [69.254427191233759, 37.095682159843776],
            [69.28665410029086, 37.104164142966837],
            [69.322418172497777, 37.119436138233212],
            [69.388600171413458, 37.158600078015795],
            [69.409991291291107, 37.175273192220899],
            [69.421245169802063, 37.196409166965068],
            [69.426091250955466, 37.207218134054287],
            [69.405818276964482, 37.276936122653396],
            [69.389982347250879, 37.324436032805252],
            [69.381363236191504, 37.344153957166256],
            [69.374418158839006, 37.388045122714871],
            [69.37664523051896, 37.419718155608635],
            [69.381509248945207, 37.453464200801349],
            [69.522209217145786, 37.582354067394689],
            [69.581100301382918, 37.578609033045481],
            [69.671891232814318, 37.57440014417331],
            [69.793045105669336, 37.582773162554687],
            [69.846100205985266, 37.60000015292313],
            [69.867482273407489, 37.608327070837092],
            [69.891654173490366, 37.614027100288453],
            [69.911936199936775, 37.613191089263424],
            [69.933027247679973, 37.602636093841213],
            [69.95123609418917, 37.572773216762286],
            [70.013882271059373, 37.54554510713497],
            [70.035545132238809, 37.541791187968343],
            [70.062454058992301, 37.541336050624622],
            [70.087063326784147, 37.532864125785451],
            [70.127909179262616, 37.528918093397479],
            [70.155818234705777, 37.536227112986879],
            [70.253600176319168, 37.615745055168063],
            [70.286091282965032, 37.699700041626542],
            [70.282209288317432, 37.728982220451812],
            [70.269154138809114, 37.771309155225467],
            [70.235745213762954, 37.822209100590769],
            [70.204845159982284, 37.834082234109971],
            [70.177754178291337, 37.852000060940142],
            [70.165545098091883, 37.871718152939323],
            [70.16253616248153, 37.928953978932881],
            [70.209427211184988, 37.928936041659981],
            [70.244563311204359, 37.943282171898929],
            [70.279982216637535, 37.9764361194499],
            [70.297209207006091, 37.998591165985133],
            [70.311236154371301, 38.00033611059304],
            [70.341936215941558, 38.018673200221102],
            [70.49025416068352, 38.161891092142767],
            [70.542627141717134, 38.252818145682099],
            [70.599154193975977, 38.310954020440946],
            [70.64749128861942, 38.354291142188046],
            [70.711382345752639, 38.409864166224949],
            [70.779436191290728, 38.441545078107524],
            [70.960127225481443, 38.471982115555463],
            [70.988172235394558, 38.466709060253109],
            [71.151272336979872, 38.381027066459509],
            [71.177763342039924, 38.347945035638091],
            [71.212491243373364, 38.319744960515848],
            [71.248182225384397, 38.304191165664051],
            [71.271518282080393, 38.302391068132906],
            [71.305954157906541, 38.297118012830552],
            [71.361927166363927, 38.247945074800256],
            [71.365818213466952, 38.195291132371537],
            [71.35581826767455, 38.155073084442662],
            [71.339427120693045, 38.131254062484388],
            [71.315263267237214, 38.085127108076662],
            [71.266100219852632, 37.982318036200539],
            [71.252145189216861, 37.927936080608319],
            [71.329018138024566, 37.890091117113215],
            [71.389709152154609, 37.907045025075448],
            [71.435809284472185, 37.925382114703595],
            [71.507191248494365, 37.947509165682206],
            [71.540263388669956, 37.944145005014278],
            [71.584854107859627, 37.911736041019864],
            [71.59360028857148, 37.874273124672541],
            [71.591091249667869, 37.836773160313001],
            [71.588591263219797, 37.815653949375175],
            [71.575963255498465, 37.797600168075107],
            [71.541927196455021, 37.726745090487938],
            [71.504391189911843, 37.609391069629183],
            [71.496182289194991, 37.563273167676769],
            [71.487200241727038, 37.418082175742214],
            [71.49331819287184, 37.370300130728666],
            [71.49188220521583, 37.309300159646781],
            [71.489291191299031, 37.278491133334867],
            [71.48276336508772, 37.259036065457977],
            [71.463491190338772, 37.23060917603523],
            [71.447763387176423, 37.20959105084988],
            [71.437500249623639, 37.166118142270946],
            [71.430818196393517, 37.06693608754712],
            [71.456936206761128, 36.94473598553482],
            [71.466382276390135, 36.92669108905217],
            [71.552918218481665, 36.76358210264938],
            [71.576572284584898, 36.731018073445753],
            [71.615300197762991, 36.697845015155664],
            [71.677200217610419, 36.676009151494256],
            [71.695254166548494, 36.67184502198505],
            [71.759154108499246, 36.674891173245513],
            [71.781663374263616, 36.678218118263132],
            [71.82026337959897, 36.684600099358804],
            [71.842754205176362, 36.692100058703048],
            [71.931363327205048, 36.750418156037497],
            [72.04774521493087, 36.830382183107005],
            [72.223854197918939, 36.942718126158795],
            [72.279972380939824, 36.96700016444963],
            [72.306354085982065, 36.978100151217845],
            [72.346636171651284, 36.989491157665199],
            [72.41024509392301, 37.002554018524407],
            [72.43136329903254, 37.002835985748121],
            [72.518036201422291, 37.001736112410285],
            [72.662463266699234, 37.025345083874399],
            [72.708036177305672, 37.090654023752919],
            [72.722209137424585, 37.112327110854082],
            [72.733872388087747, 37.135382206154858],
            [72.758882310852499, 37.180682201993065],
            [72.778045185583807, 37.210400072146669],
            [72.799772252141707, 37.229218115380306],
            [72.834227238707143, 37.23506415758537],
            [72.892491188946877, 37.250009090988996],
            [73.001391222239391, 37.304064152356446],
            [73.107754221071701, 37.362700091460269],
            [73.157763337765033, 37.402982177129488],
            [73.276927179162641, 37.453018115913139],
            [73.299282217908143, 37.460391173243053],
            [73.435527204558667, 37.475818066080549],
            [73.456372327261903, 37.476109085759461],
            [73.50512717596061, 37.472709050546015],
            [73.541236247303374, 37.460982097418409],
            [73.571382265072231, 37.448345037241737],
            [73.613036133018994, 37.437091158730865],
            [73.669700313214179, 37.435364151395689],
            [73.711654085657386, 37.437800100103303],
            [73.747209280836643, 37.438644995945751],
            [73.776318121903699, 37.432864164947588],
            [73.769854165794783, 37.343491116261347],
            [73.718736123308219, 37.308818032574735],
            [73.646654102730821, 37.300127004785921],
            [73.620600297741731, 37.263518036736443],
            [73.631300300089293, 37.242891178792448],
            [73.738591175605706, 37.221645065840207],
            [73.817063391543769, 37.228191164600318],
            [73.842209268778362, 37.250491050422866],
            [73.871927138931852, 37.267236081357524],
            [74.040263247807474, 37.311436036219803],
            [74.146100198394919, 37.336182096671862],
            [74.231227142558765, 37.39676414606042],
            [74.254154162378683, 37.409409085226216],
            [74.322491316244651, 37.406773144308218],
            [74.394991258515546, 37.401227006599683],
            [74.432209255651372, 37.394009182117045],
            [74.54676338556564, 37.381736064177048],
            [74.639436221903196, 37.386264135923241],
            [74.669572349026311, 37.392118057117344],
            [74.823045164235594, 37.331836079863294],
            [74.839427258761674, 37.319745016860836],
            [74.915736105484058, 37.237327103810983],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "AGO", Country: "Angola" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.092636145487688, -4.64610893396798],
            [13.07763605916108, -4.669863918185797],
            [13.042218159556285, -4.668473025168964],
            [12.856663274577329, -4.715135918467183],
            [12.820554035596558, -4.737499842030275],
            [12.712218104389251, -4.878054970943595],
            [12.70950924091369, -4.90742683613297],
            [12.60694508824912, -4.998054991004267],
            [12.572218192743776, -5.021808969393703],
            [12.532218074298385, -5.11583581837408],
            [12.535273278014358, -5.27360888520036],
            [12.526663219410295, -5.724164010057848],
            [12.485000131370157, -5.725281988306392],
            [12.367218130533956, -5.730555043608661],
            [12.274582174570412, -5.736663942298208],
            [12.229863212261989, -5.755072948655766],
            [12.214554467072617, -5.76855471171217],
            [12.205879212999946, -5.777164940999853],
            [12.20330071400025, -5.779654966999829],
            [12.178155287000266, -5.7955223539999],
            [12.169070120000185, -5.800873558999967],
            [12.164045117000171, -5.80368806499996],
            [12.156052467000222, -5.808513664999907],
            [12.14344223900008, -5.817015645999959],
            [12.012975231000098, -5.905957977999947],
            [11.784922735000123, -6.039465467999889],
            [11.965465487000245, -6.00887082499986],
            [11.982141735000141, -6.006370500999765],
            [12.060889316000157, -5.994208746999902],
            [12.071143063000079, -5.992763707999984],
            [12.108787388000223, -5.991141035999817],
            [12.139218644000152, -5.989873573999944],
            [12.157411010000089, -5.990331542999911],
            [12.170167935000165, -5.990495304999968],
            [12.171770992000233, -5.990530851999949],
            [12.187781628000153, -5.991611514999931],
            [12.213863773000185, -5.993631136999795],
            [12.222539645000211, -5.993559350999874],
            [12.22496915000022, -5.993580342999962],
            [12.228123855500257, -5.993668861499941],
            [12.244415437999976, -5.995422389999931],
            [12.266146672000218, -6.001549039],
            [12.26983007400014, -6.00253354299997],
            [12.27665337500008, -6.003913812999883],
            [12.326820493000127, -6.022462464999933],
            [12.363246876000176, -6.039461443999926],
            [12.414243812000166, -6.044318294999869],
            [12.48952500300021, -6.034604591999937],
            [12.557520917000147, -6.022462464999933],
            [12.644944236000185, -6.007891911999877],
            [12.705654874000231, -5.995749783999941],
            [12.771222363000049, -5.969037102999948],
            [12.819790873000159, -5.932610720999946],
            [12.851360405000094, -5.898612762999903],
            [12.887786788000142, -5.869471656999906],
            [12.941212149000023, -5.854901103999964],
            [13.001922787000069, -5.859757954999907],
            [13.04806287200023, -5.864614805999963],
            [13.091774531000226, -5.879185358999905],
            [13.113630361000048, -5.879185358999905],
            [13.145199893000211, -5.871900082999844],
            [13.172582026642317, -5.863854754875604],
            [13.178882100244493, -5.856326985808209],
            [13.245000202541632, -5.861391020486906],
            [13.405273255816212, -5.854445943134408],
            [13.458609149889242, -5.848472998914929],
            [13.513336104617224, -5.854163975910865],
            [13.541036115394604, -5.864790888062487],
            [13.759718125834297, -5.862499946280082],
            [13.938609230132045, -5.840835911634201],
            [13.97333612563699, -5.836390988367953],
            [13.997500146731113, -5.848608953384655],
            [14.080273284838711, -5.866390993382993],
            [14.242773242154669, -5.885555041580915],
            [14.34166327137109, -5.893335794682287],
            [14.596391153603236, -5.908054919613733],
            [14.712427204003291, -5.880417940748373],
            [14.758473189226038, -5.878017866586333],
            [14.851109145189582, -5.87249989207254],
            [15.076391233846039, -5.862781913503682],
            [15.686109145080138, -5.857081884052377],
            [15.812636153161804, -5.869717938400868],
            [15.92833608924343, -5.861391020486906],
            [16.10007323916102, -5.859372993472789],
            [16.143054130022165, -5.856390879952755],
            [16.215827154699241, -5.852500000487737],
            [16.319718162640129, -5.85221786562613],
            [16.368327166223082, -5.853055050117632],
            [16.514163229427936, -5.875835889545669],
            [16.579718094346219, -5.900835921664623],
            [16.596109241327611, -5.921663945285445],
            [16.605273176095068, -5.981663955315867],
            [16.606109187120126, -6.027217922820967],
            [16.685273245348128, -6.136872997953617],
            [16.71569117205695, -6.168472940651441],
            [16.725227095688126, -6.197445994886664],
            [16.693609215717402, -6.354999958763415],
            [16.688473288351531, -6.404999855363371],
            [16.708054084776336, -6.467226937073463],
            [16.78277321973809, -6.681664016457034],
            [16.834163338802455, -6.792226851706118],
            [16.87097330489047, -6.857573007234862],
            [16.903118071296319, -6.863890950589905],
            [16.930000175959492, -6.892217927726733],
            [16.969791249739217, -7.017917809600704],
            [16.957918116220014, -7.067635906615067],
            [16.935000148855806, -7.112499875848755],
            [16.94311818974171, -7.19902693312298],
            [16.994582237192333, -7.297363924366266],
            [17.064718147485166, -7.339163972704739],
            [17.095263311484359, -7.379017910758535],
            [17.105554109317609, -7.422226957024861],
            [17.151109250289494, -7.45278201166991],
            [17.186663272002193, -7.513054936468222],
            [17.283609202590526, -7.679445940887604],
            [17.30944507809653, -7.756108839201204],
            [17.390691201079278, -7.845835939478548],
            [17.419718234771153, -7.854999874245834],
            [17.438327065700832, -7.876663908891715],
            [17.505273158912075, -8.003335923898646],
            [17.525554011891984, -8.053608902904841],
            [17.550200160057898, -8.080681947323001],
            [17.555273223149982, -8.083055031756658],
            [17.631109162894063, -8.098054950445203],
            [17.660273156884159, -8.092781895142593],
            [17.756663199652508, -8.066664052413159],
            [17.889718201583264, -8.042363909211304],
            [17.92221819304666, -8.04666399319018],
            [17.952773247691482, -8.061526951580618],
            [17.976945147774416, -8.083055031756658],
            [18.004863255673172, -8.107081924914098],
            [18.041382202082275, -8.107781981469429],
            [18.115418211933388, -8.107108914642424],
            [18.109427162802831, -8.081364066605261],
            [18.112573226349582, -8.0286179232412],
            [18.196109117648064, -7.99666392895233],
            [18.329718163380619, -7.997499939977388],
            [18.403327199082582, -8.00916402883081],
            [18.525554123185202, -7.98902684167183],
            [18.519163257272112, -7.95763594363973],
            [18.526945183839928, -7.937082008253526],
            [18.76611809775369, -7.937963952108191],
            [18.773054122650876, -7.972363953388424],
            [18.791763201142828, -7.998972975645472],
            [19.00166332396293, -7.999446050262122],
            [19.372773261559018, -7.993335810468068],
            [19.358891153481011, -7.935281910722665],
            [19.35236332726987, -7.914308880176463],
            [19.349300244564915, -7.89319101034306],
            [19.36124512717538, -7.812917858683761],
            [19.373473150476173, -7.790690895418891],
            [19.387500265479389, -7.763190876851922],
            [19.401391258374701, -7.692472927201095],
            [19.381382146696581, -7.61971783979709],
            [19.372500179152723, -7.60110884122895],
            [19.37501810287381, -7.579255040294811],
            [19.399300141164645, -7.572782031730355],
            [19.468191171194064, -7.568955022367788],
            [19.530618077476618, -7.477499908926973],
            [19.528263265591931, -7.434999971671573],
            [19.502218177782254, -7.369717853883174],
            [19.490273295171619, -7.316255057795729],
            [19.490136167235221, -7.286946056880254],
            [19.498891232764663, -7.179445969060112],
            [19.502918234337415, -7.152364039824647],
            [19.538945163028529, -6.996608832374307],
            [19.962773248431972, -6.99555505950417],
            [20.311391186984167, -6.994717875012611],
            [20.317909122549679, -6.971390870772154],
            [20.317500253311664, -6.951108844325574],
            [20.331527200676874, -6.914726858214976],
            [20.629745090570509, -6.913881962372614],
            [20.615973288338694, -6.972781931426937],
            [20.587773213216394, -7.030835831172681],
            [20.546182209543645, -7.115417951628444],
            [20.53347323263722, -7.180555062491408],
            [20.530900155993379, -7.249654969548601],
            [20.548718238175354, -7.283617938396162],
            [21.20804511404441, -7.283055009777343],
            [21.603054036955143, -7.282217825285898],
            [21.777854101939738, -7.281255079884374],
            [21.812773278304235, -7.311946088999377],
            [21.840000214464965, -7.367781969520422],
            [21.859718138826167, -7.476946032763635],
            [21.851109253688776, -7.591663945066614],
            [21.833054131284143, -7.635554942976967],
            [21.825973267099926, -7.645417928471261],
            [21.816945119164359, -7.654446076406714],
            [21.773745125353514, -7.773472957505874],
            [21.763336142685034, -7.835555032290927],
            [21.749027060457991, -7.935690947598573],
            [21.755063204227724, -8.003755019058474],
            [21.773327203660017, -8.023472943419733],
            [21.803891143122371, -8.053472948434887],
            [21.83610916736194, -8.13167292015703],
            [21.858891180256421, -8.200135802571253],
            [21.879027193949128, -8.283608829595778],
            [21.909254013265127, -8.332135858165486],
            [21.903609136736577, -8.343545975351958],
            [21.913854169378567, -8.389899911698535],
            [21.936527217531477, -8.420972967961347],
            [21.940000175302998, -8.496108851150041],
            [21.935000202406627, -8.546390882611661],
            [21.924445039346438, -8.584163929377212],
            [21.89027319347116, -8.732226896623899],
            [21.875827150945952, -8.801108874197894],
            [21.852773229111619, -8.933890961360817],
            [21.835282208792421, -9.071108919334392],
            [21.841391275119918, -9.15416402466559],
            [21.825273210544594, -9.302226824274044],
            [21.807218088140132, -9.346390904590692],
            [21.791527165351852, -9.411390887517257],
            [21.850273242663718, -9.620281996830386],
            [21.859445056419872, -9.642363953169593],
            [21.874718225152776, -9.664999953310485],
            [22.023191235103923, -9.849717821436059],
            [22.061245075626687, -9.882845952725006],
            [22.132082215940954, -9.901945963182186],
            [22.158327128322924, -9.924308880916954],
            [22.175418164221639, -9.962499849376115],
            [22.200836118034147, -10.083335880461675],
            [22.207218099129705, -10.154163968320347],
            [22.228191129675963, -10.202217922273803],
            [22.268891137038509, -10.27778195307792],
            [22.313818138184331, -10.374999960244168],
            [22.304445158751378, -10.422782005257602],
            [22.272500216917877, -10.513054935071352],
            [22.303336065320082, -10.583055058532068],
            [22.308054070992796, -10.612781981141097],
            [22.306109134174449, -10.675281977619477],
            [22.315136108643514, -10.727499893443849],
            [22.252218190471467, -10.789999889922342],
            [22.208882242190953, -10.809163938120037],
            [22.190136115686698, -10.825699924388744],
            [22.166454054026531, -10.859926923187231],
            [22.19444508448305, -10.967226851159069],
            [22.217500179783826, -11.018890890820103],
            [22.244027059389452, -11.068335905428057],
            [22.258891191246164, -11.183755047752697],
            [22.25389121835002, -11.209726877728542],
            [22.298882257236301, -11.237217843840142],
            [22.480763354043063, -11.130626857240884],
            [22.499927066964858, -11.093782022435335],
            [22.504582208363445, -11.064726825548746],
            [22.518609155728484, -11.044164005345181],
            [22.567500126535265, -11.033608842285048],
            [22.598191135650382, -11.041254982020916],
            [22.623473302630941, -11.061108860852016],
            [22.646663346573177, -11.072782002161034],
            [22.666109194356352, -11.081108920074826],
            [22.716663302395887, -11.093335937547181],
            [22.775073265389494, -11.091455038469348],
            [22.806945117027055, -11.074717886523729],
            [22.836245065487049, -11.055972933485862],
            [22.856945181264791, -11.049445945464981],
            [22.906800238577489, -11.064926817759158],
            [22.941245166859062, -11.093054976151791],
            [23.016945152133246, -11.096108838763485],
            [23.088054033748904, -11.096390973625148],
            [23.217009111549373, -11.076599959068119],
            [23.241945105927869, -11.061663910481741],
            [23.293891280450623, -11.026946067432277],
            [23.368054024678031, -10.982781987115516],
            [23.397500153529904, -10.970281887237036],
            [23.421945136018962, -10.963190964768842],
            [23.456109102905202, -10.959163963195977],
            [23.476391129351612, -10.9583358311599],
            [23.496663265152222, -10.958890880789625],
            [23.520000160038421, -10.962226878262811],
            [23.543054081872754, -10.966946057401856],
            [23.585827101534733, -10.979999865805723],
            [23.610273257490064, -10.990281946459675],
            [23.665136166688114, -11.010835881845992],
            [23.858609100342846, -11.027154944459994],
            [23.886109118910042, -11.014717876493478],
            [23.95402717761624, -10.933755061838838],
            [23.986209159672171, -10.870463981336627],
            [23.988054016566252, -10.919999855775416],
            [24.007782166849267, -11.122735798293263],
            [24.027000194503671, -11.249999910942279],
            [24.084445065162697, -11.40278188968972],
            [24.056245157678518, -11.426390861153834],
            [24.031945182114725, -11.452499986704197],
            [23.967363288519834, -11.66215502267471],
            [23.989718159627103, -11.696945955920341],
            [23.998473225156374, -11.715690908958095],
            [24.012354159768108, -11.82416396810163],
            [23.984163304739241, -11.882226920302685],
            [23.984791276927069, -12.211808886137987],
            [24.00389128738442, -12.229308958912725],
            [24.031109171089668, -12.257635936049383],
            [24.039163341873518, -12.277081951470848],
            [24.042773259943203, -12.297499932387097],
            [24.050691140980973, -12.392426830132933],
            [23.985273236360854, -12.491946005003285],
            [23.971382243465541, -12.512499940389432],
            [23.88700916767533, -12.763608852096468],
            [23.891945102831244, -12.825417844475169],
            [23.911945162054053, -12.852363986878856],
            [23.94639126380207, -12.880281927139492],
            [23.961663259068359, -12.89639093925922],
            [23.994582178053577, -12.933472981925149],
            [24.006245093440612, -12.953054951816341],
            [24.019445082236302, -12.999446103813227],
            [23.880000220220552, -13.006946063157272],
            [23.584718175741557, -13.006391013527548],
            [23.423054061812252, -13.005555002502604],
            [23.282500106365376, -13.005281920096309],
            [22.995827114661608, -13.004717818010931],
            [22.500000157160628, -13.004990900417283],
            [22.001527200458042, -13.004582031179211],
            [21.996391273092001, -13.315554998446657],
            [21.996663349669802, -13.452782008875829],
            [21.998254067258927, -13.697782021893374],
            [21.997773281291416, -13.727217924823265],
            [21.998609124678296, -13.916108974913413],
            [21.999445135703297, -13.967499932168266],
            [21.999445135703297, -14.006672924406189],
            [21.999718218109763, -14.452499985307213],
            [21.999163336117874, -14.744164065089649],
            [21.999163336117874, -14.847226940994403],
            [21.999163336117874, -14.898608845793774],
            [21.999163336117874, -14.949999970686633],
            [22.000000185333249, -15.451108868307358],
            [22.000000185333249, -15.605554990115877],
            [21.999163336117874, -15.999999978579581],
            [21.999718218109763, -16.122781952311982],
            [22.000145192258628, -16.171664038301287],
            [22.014445054391956, -16.183335838505741],
            [22.067218187484286, -16.234099997039323],
            [22.111945028781747, -16.322499906764165],
            [22.12389125249689, -16.348755045068174],
            [22.131527166311002, -16.382081995100975],
            [22.135063323632664, -16.48589991284598],
            [22.188327133338248, -16.541117879463229],
            [22.217773262189951, -16.561672988315934],
            [22.293327067072255, -16.612226928717291],
            [22.493891258471308, -16.780890937646063],
            [22.730554127653022, -17.041390933697016],
            [22.762500242953024, -17.069999878057274],
            [23.065000111914799, -17.331946087686049],
            [23.201663355534833, -17.479726920071073],
            [23.454445127482302, -17.615835952251587],
            [23.476109162128012, -17.625835898043988],
            [23.289163216494273, -17.661108958361467],
            [23.284718125589677, -17.662499851378413],
            [22.996391216746986, -17.71694601234924],
            [22.910000113942885, -17.73333581822618],
            [22.881109202358914, -17.739446058020121],
            [22.564163290872045, -17.798891018420647],
            [22.290273204460732, -17.850000008451786],
            [21.755409209191782, -17.947346091098836],
            [21.379354116346292, -18.014890987474644],
            [21.263327118402117, -17.956391002840746],
            [21.236873161354254, -17.938890930066009],
            [21.165863353834141, -17.931464060917619],
            [21.138609092668901, -17.934446006799647],
            [21.021382141462482, -17.953335799124773],
            [20.953327122457893, -17.968263968721942],
            [20.889718200186451, -17.994446016830011],
            [20.854163340283264, -18.016391012871054],
            [20.781582261189499, -18.010199971530312],
            [20.751945192582752, -17.996108986424645],
            [20.638473166371455, -17.97847295918001],
            [20.595554133955716, -17.981390867321693],
            [20.553682168887917, -17.983055010382657],
            [20.492554122325032, -17.941890980858943],
            [20.445200224927078, -17.901872925140538],
            [20.321318210218749, -17.857226885389821],
            [20.270836186546831, -17.86125506042913],
            [20.244718176179219, -17.880135800298717],
            [20.128891170445229, -17.894446055992148],
            [20.098891165429905, -17.896390992810382],
            [20.01694515353023, -17.890835802646336],
            [19.971318095829076, -17.880135800298717],
            [19.94624514115219, -17.863335951717318],
            [19.920554104933615, -17.857226885389821],
            [19.818609207277404, -17.858055017425954],
            [19.797918143954973, -17.863755046877202],
            [19.701382256071099, -17.870554949666314],
            [19.559163319372715, -17.867499913588347],
            [19.445827079993251, -17.862781907915803],
            [19.413327088529684, -17.861117932492846],
            [19.337218234017826, -17.843890942124233],
            [19.298745130696886, -17.824999976332833],
            [19.245554075911286, -17.806255023294966],
            [19.175282211148726, -17.801108870006999],
            [19.133609232462561, -17.804372950750746],
            [19.116800163787673, -17.819445959635175],
            [19.055827182433802, -17.827781930004505],
            [19.02583606223601, -17.829999949228977],
            [18.983891174610449, -17.825972947656169],
            [18.919445067847278, -17.816390923557265],
            [18.804300181395377, -17.768891013405437],
            [18.753682203253561, -17.745282041941323],
            [18.64194506336645, -17.64805498231965],
            [18.578327088639469, -17.572226921564663],
            [18.513336158168215, -17.472782010356639],
            [18.451536050607046, -17.389835869767182],
            [18.39000014063447, -17.38694595718232],
            [18.188891125527277, -17.387217866121944],
            [18.117218141826186, -17.38694595718232],
            [18.000545066783332, -17.386172978069339],
            [17.733891245041775, -17.38889089400061],
            [17.561663250872755, -17.390835830818844],
            [17.510554093203552, -17.39194609771647],
            [17.278827159177524, -17.392226891473683],
            [16.606109187120126, -17.390835830818844],
            [16.003327133144978, -17.386673042414088],
            [15.956663234018151, -17.386673042414088],
            [15.583054148164308, -17.389163976406792],
            [15.496945180221815, -17.389445943630392],
            [14.993891178144906, -17.389726905025611],
            [14.634718076532295, -17.388335844370772],
            [14.448054098122213, -17.387500000983721],
            [14.36166333059407, -17.387217866121944],
            [14.218054003793043, -17.38694595718232],
            [14.194718114735281, -17.411390939671492],
            [14.171391278132887, -17.421117970695661],
            [14.082918110573814, -17.429999938239348],
            [14.051109123210438, -17.419445948645603],
            [14.016663189100598, -17.410899927782111],
            [13.993218167662917, -17.423946024835004],
            [13.9288912515633, -17.40110885901737],
            [13.841391222965854, -17.323608943850388],
            [13.697773178985699, -17.241391023010863],
            [13.660691136319656, -17.218890977339981],
            [13.528018181536595, -17.087573041027952],
            [13.494300131900332, -17.025555003983413],
            [13.472082221091057, -17.010835879052024],
            [13.376036171630176, -16.970908850802346],
            [13.346309081383197, -16.970672984046246],
            [13.317782279674248, -16.978055093831586],
            [13.260827079800038, -16.98486404907618],
            [13.208891131199039, -16.973608997098665],
            [13.170900154950402, -16.956317968989879],
            [13.149163197746645, -16.954173039961177],
            [13.018473233622331, -16.978055093831586],
            [12.985836114222764, -16.99110890223551],
            [12.92333611774427, -17.016390901578063],
            [12.879791124797691, -17.040135827512017],
            [12.851391225103157, -17.072499864505289],
            [12.817918094678589, -17.107081920723203],
            [12.604445101800877, -17.225835887054416],
            [12.557218106417338, -17.243335792190976],
            [12.478891232680837, -17.24167299023452],
            [12.434163217916876, -17.212781911012542],
            [12.292218201815047, -17.229726934157327],
            [12.23805400806782, -17.215281897460613],
            [12.205827099010776, -17.194726956246086],
            [12.087773189234952, -17.136526876108633],
            [12.048054032184439, -17.143890880982951],
            [11.976109139543382, -17.164172907429474],
            [11.933054152658144, -17.180700008880819],
            [11.902218136617819, -17.205835827831606],
            [11.884582277011361, -17.224445999865964],
            [11.811663239580525, -17.270281934594777],
            [11.752782053360249, -17.254835373506694],
            [11.751325052000198, -17.249775872999976],
            [8.263853331000178, -17.249945732000015],
            [8.247254603000101, -17.131918772999938],
            [8.24712653000023, -17.130059996999933],
            [8.246523457000137, -17.127702244999909],
            [8.245675667000171, -17.124360081499901],
            [8.243327866000186, -17.115175037999904],
            [8.243119382000145, -17.112148919999981],
            [8.24259221400024, -17.109893844499879],
            [8.241864910000061, -17.106858302999967],
            [8.201878687000146, -16.527470272999892],
            [8.202811114000156, -16.519246638999959],
            [8.202170654000099, -16.50998482499989],
            [8.267282868000166, -15.932507870999927],
            [8.268035097000109, -15.93000921099997],
            [8.268134543000201, -15.929122402999951],
            [8.274100475000182, -15.90930244499998],
            [8.270326916000101, -15.855360390999977],
            [8.270604908000223, -15.852878585999974],
            [8.270491946000107, -15.851264154999967],
            [8.334823069000095, -15.273866132999899],
            [8.335333590000175, -15.272159618999922],
            [8.335696967000104, -15.268880654999975],
            [8.336329032000094, -15.266767741999956],
            [8.336558761000219, -15.264694549999831],
            [8.337364973000092, -15.261999344999936],
            [8.337518940000166, -15.260609723999892],
            [8.338272538000183, -15.258090293999885],
            [8.33836862600009, -15.257222990999878],
            [8.339306525000154, -15.254029415999867],
            [8.347006243000067, -15.2282239459999],
            [8.347102523000046, -15.227354243999969],
            [8.347734913000124, -15.225239157999852],
            [8.347964166000224, -15.223168110999836],
            [8.514258848000168, -14.665001120999932],
            [8.514793820000023, -14.663940809999986],
            [8.515187851000235, -14.662613591999843],
            [8.515583572000196, -14.661829255999862],
            [8.516264667500195, -14.659737490499936],
            [8.516947967000192, -14.657638243999941],
            [8.779615228000068, -14.135409391999872],
            [8.780763857000181, -14.133871958999862],
            [8.783803375499986, -14.127826748999894],
            [8.78459213400015, -14.126270138],
            [8.786038734999977, -14.124333683999964],
            [8.786363638500177, -14.12372029449989],
            [8.788039496000067, -14.120412410999961],
            [8.789695380500177, -14.11815523849998],
            [8.791005994999921, -14.116360238999903],
            [8.79320784000015, -14.112035723499901],
            [8.794019435000052, -14.110484285999917],
            [8.848733434000167, -14.037198799999942],
            [8.898959059000163, -13.936849719999884],
            [8.90280116100007, -13.923972855999807],
            [8.903475800000024, -13.921888790999958],
            [8.903890348000232, -13.92105995199995],
            [8.904406313000209, -13.919301606999852],
            [8.905206683000131, -13.917675144499867],
            [8.915782999000214, -13.896499572999858],
            [8.917112567000061, -13.891966852999815],
            [8.917663300000072, -13.890865370999961],
            [8.918040388000179, -13.889579749999939],
            [8.918807658500157, -13.887970495499872],
            [8.93036358, -13.864779873999851],
            [8.960527812000237, -13.58011403699993],
            [8.980707707000022, -13.511089843999969],
            [8.982455968000181, -13.494523024999978],
            [8.983676397000181, -13.490346257999875],
            [8.984287884000082, -13.484550076999938],
            [8.984949962000144, -13.482284001999915],
            [8.985142089000135, -13.480462649999936],
            [8.987256280000167, -13.473225958999819],
            [8.987807270000133, -13.468001417999972],
            [8.990927020000129, -13.457321210999964],
            [8.99129292900011, -13.453850497999952],
            [9.15391998900003, -12.895293409000033],
            [9.160316214000204, -12.882394659999932],
            [9.164078884000105, -12.846211664999885],
            [9.165721324000117, -12.840549929999952],
            [9.167808549000085, -12.820467014999963],
            [9.168618793500173, -12.817480050499967],
            [9.330188492000048, -12.258499419999964],
            [9.587559928000076, -11.735384470999861],
            [9.93262252400001, -11.263451889999942],
            [9.933661760500115, -11.262386363999894],
            [9.958986160000165, -11.237977071999978],
            [9.999608875000206, -11.154660869999873],
            [10.100319360000015, -11.016349033999973],
            [10.094331367000109, -10.993602046999854],
            [10.010776813000177, -10.813444422999908],
            [9.904155014999958, -10.407488648999959],
            [9.8162490630001, -10.217423364999945],
            [9.815619569000035, -10.215264745499837],
            [9.814824682500159, -10.21268901499991],
            [9.66751248900016, -9.650152951999928],
            [9.667212432000099, -9.646355935999907],
            [9.666671075000181, -9.644285612999965],
            [9.620892734000108, -9.065436784999918],
            [9.621715451000227, -9.057049640999821],
            [9.621060344000114, -9.048773493999931],
            [9.67759066900021, -8.470650060499906],
            [9.678503991000127, -8.461750688999928],
            [9.6789387500001, -8.460200315999884],
            [9.680242413000087, -8.446802157999969],
            [9.681172132500194, -8.443481079499918],
            [9.681974686000103, -8.44041921749988],
            [9.682673091000083, -8.437734017999958],
            [9.682841564000114, -8.436002105999933],
            [9.693048153, -8.39959215499988],
            [9.690735453000173, -8.394569381999872],
            [9.69023965000008, -8.392672765999976],
            [9.689168308999967, -8.390345948999936],
            [9.688827539000215, -8.389042361999884],
            [9.688287035999963, -8.387868442999945],
            [9.68269834400013, -8.366488459999914],
            [9.658092054000065, -8.331030565999981],
            [9.65671480400016, -8.328038393999918],
            [9.646583133000036, -8.313436186999922],
            [9.613921039000104, -8.242456583999854],
            [9.594572300000152, -8.214501900999949],
            [9.592928222000182, -8.21205032249992],
            [9.591135223500089, -8.209338175999903],
            [9.589610545500079, -8.206981912499884],
            [9.587966459500137, -8.204530339499911],
            [9.586312346000057, -8.202120143999821],
            [9.544420622000132, -8.111070758999972],
            [9.543612467000173, -8.109389222499885],
            [9.542794639500244, -8.107653246999917],
            [9.542066507500181, -8.106211102499913],
            [9.541133664000142, -8.104337704499926],
            [9.531998572000134, -8.084492904999877],
            [9.531222832000083, -8.083373608999977],
            [9.476869927, -7.965111204999971],
            [9.417325470000094, -7.879116454499808],
            [9.366895718000194, -7.80625059699986],
            [9.366184899500126, -7.804834232999895],
            [9.365245823500061, -7.802943660999972],
            [9.364462317500227, -7.801287998499902],
            [9.121821591000241, -7.272130084999958],
            [9.121481605000156, -7.270821595999962],
            [9.12015121200011, -7.267916922999973],
            [9.119821454999908, -7.266647779999971],
            [9.119268349000151, -7.265440158999866],
            [9.118628630000103, -7.263214861499932],
            [9.059279310000079, -7.034890994999898],
            [9.24352385200018, -6.909542827999815],
            [11.297896618000152, -5.511879385999919],
            [11.902198530000135, -5.100751130999981],
            [11.995858405000149, -5.038551150999922],
            [12.00465206500013, -5.033045049999885],
            [12.008083939000073, -5.030161294999971],
            [12.026127241072629, -5.014999832420756],
            [12.166391250288541, -4.895835837475488],
            [12.179163259106929, -4.854726793236637],
            [12.186391141873344, -4.827217889852079],
            [12.215063285783685, -4.759926797505102],
            [12.266109243902577, -4.787499906268181],
            [12.295000155486775, -4.789446016552915],
            [12.331454058326926, -4.787081984574741],
            [12.381245077899166, -4.712364023079374],
            [12.395627082683973, -4.665072989955377],
            [12.388473295941765, -4.634099845978596],
            [12.400836100245954, -4.609445986461935],
            [12.462773168105343, -4.592226875082417],
            [12.644300213321287, -4.557154980441624],
            [12.661245068827895, -4.528890867578923],
            [12.691527208704827, -4.479581975078702],
            [12.742218109404575, -4.419164043354954],
            [12.765273204705352, -4.393890928829705],
            [12.779045174575288, -4.388990868219537],
            [12.869718088809634, -4.399581905825585],
            [12.897082152906762, -4.417363945823922],
            [12.901245108949411, -4.437081870185011],
            [12.904300145027378, -4.457781985962868],
            [12.91389122158165, -4.475835934900999],
            [13.018745142561954, -4.585135784976217],
            [13.038054030047135, -4.604446013565678],
            [13.055136181128375, -4.618608915400841],
            [13.077773187097478, -4.629708902169057],
            [13.091391265224672, -4.633054957925992],
            [13.092636145487688, -4.64610893396798],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "AIA", Country: "Anguilla" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.683204556999954, 20.870558371000143],
            [-61.269419556999907, 19.902014370000032],
            [-61.848919558, 18.93084337000009],
            [-62.010081558000024, 18.658892369000043],
            [-62.213512485000024, 18.314452969999977],
            [-62.227084755999982, 18.310010094000077],
            [-62.233055555999897, 18.308055555000109],
            [-62.42274566599994, 18.245960587000084],
            [-62.496111110999976, 18.221944443000027],
            [-62.739444443999872, 18.155833332000086],
            [-62.876388888999855, 18.190277777000134],
            [-62.936066546999967, 18.182473776000037],
            [-62.948611110999877, 18.18083333200012],
            [-63.090255825999947, 18.13588112300009],
            [-63.209444443999928, 18.098055555000045],
            [-63.47388888899988, 18.034166666000132],
            [-63.615833332999955, 17.959722221000092],
            [-63.657983726999959, 17.950580837000089],
            [-63.753506078999891, 17.947573936000069],
            [-63.891192389399919, 17.94877690140008],
            [-63.898055547999945, 17.964166664000018],
            [-63.889888559999918, 18.002733369000097],
            [-63.838227558999932, 18.223101369000105],
            [-63.840430558999941, 18.243084369000073],
            [-63.869540558999859, 18.500064369000043],
            [-63.854095558999944, 18.618957369000086],
            [-63.843264558999948, 18.698114369000066],
            [-63.841840558999905, 18.708278369000013],
            [-63.840692558999962, 18.716743369000099],
            [-63.822960558999881, 18.856316370000044],
            [-63.810405558999889, 18.952519370000161],
            [-63.8014745589999, 19.01781737000006],
            [-63.801163558999946, 19.020197370000162],
            [-63.792008558999981, 19.085312370000096],
            [-63.748117559, 19.371185370000077],
            [-63.731807558999918, 19.473460370000154],
            [-63.727229558999937, 19.500825370000015],
            [-63.605972558999952, 20.205282371000081],
            [-63.569164558999915, 20.415280371000037],
            [-63.421091558999933, 21.248315372000079],
            [-63.299536558999876, 21.925858372000121],
            [-62.802976557999955, 21.888605372000129],
            [-62.199812557999877, 21.73607537200003],
            [-61.636047557000012, 21.486799372000135],
            [-61.236910557000044, 21.220685372000062],
            [-61.137769556999842, 21.176266372],
            [-60.683204556999954, 20.870558371000143],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ALB", Country: "Albania" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.071418136591092, 42.5609091892495],
            [20.102082155977712, 42.533473208422905],
            [20.161663238486199, 42.511318161887786],
            [20.222500265369945, 42.428327094297103],
            [20.239582248813065, 42.360554042516171],
            [20.249509104409555, 42.327073200740628],
            [20.355673284497982, 42.311845126647015],
            [20.386945159504563, 42.30416411819364],
            [20.457536207140834, 42.27390913568324],
            [20.508891122211878, 42.230273115267906],
            [20.525136088801474, 42.213054003888473],
            [20.59527317256061, 42.043609136857626],
            [20.621736182063955, 41.95652702995379],
            [20.60332717570634, 41.934718156020708],
            [20.583400206679471, 41.918818020928498],
            [20.589645059838688, 41.88219111560629],
            [20.568954164154235, 41.874544975870279],
            [20.533609187107231, 41.776936036738917],
            [20.517773257393628, 41.744027008399527],
            [20.533882269513413, 41.634718105868998],
            [20.466109217732651, 41.5555449951854],
            [20.458818135416095, 41.520827152135709],
            [20.470691268935298, 41.499300077788078],
            [20.508609154988164, 41.459718048674077],
            [20.561873299969932, 41.408673096383367],
            [20.550000166450616, 41.37305403110166],
            [20.524163285116316, 41.350273191673736],
            [20.498854128407345, 41.341800093367979],
            [20.502500256298873, 41.304444968295869],
            [20.519163312220172, 41.246382183732877],
            [20.578327143587387, 41.162773202238341],
            [20.600609092137006, 41.093182115653619],
            [20.662500227167129, 41.089164166536293],
            [20.740809163630814, 40.909536125309202],
            [20.807218144485034, 40.900554077841278],
            [20.821109137380461, 40.908882169221712],
            [20.837427194165997, 40.927427130049139],
            [20.944163355328783, 40.919164249875635],
            [20.963054153482176, 40.911382155669699],
            [20.980536121346006, 40.897209027912666],
            [20.983491245137799, 40.855891106646155],
            [20.98389122955831, 40.793053990021107],
            [21.026782266417371, 40.709427071253671],
            [21.051454063207103, 40.674445030615217],
            [21.053327083295841, 40.620691214848748],
            [21.042082257240452, 40.564027034653535],
            [20.949582255746662, 40.469644960615724],
            [20.920136126894988, 40.463600099666664],
            [20.833336154852731, 40.46221809146725],
            [20.791927206117464, 40.431545019625318],
            [20.787218085262339, 40.394718122092584],
            [20.758609140902138, 40.311944983985185],
            [20.736800266968885, 40.307218093495138],
            [20.714300221298004, 40.270100176283663],
            [20.721245131012438, 40.225136127125538],
            [20.667082278369776, 40.096245087065526],
            [20.621391182928278, 40.085273175778283],
            [20.60471823636135, 40.075273229985967],
            [20.583336168939041, 40.070544998391441],
            [20.555827097916506, 40.066382209986799],
            [20.479582288934466, 40.065554077950779],
            [20.461573266997561, 40.074164136554643],
            [20.450000205612866, 40.072491108676289],
            [20.430273228796352, 40.063054091502707],
            [20.315418188557089, 39.991800202961414],
            [20.337218177672753, 39.923191140155538],
            [20.369718169136036, 39.891391205247388],
            [20.411318225264239, 39.844644995831217],
            [20.413327199822959, 39.820135975601644],
            [20.404582192577493, 39.801800059440055],
            [20.378609189135204, 39.788054073469951],
            [20.306127184137296, 39.812118014639807],
            [20.294445158010944, 39.807491204073912],
            [20.298054070252164, 39.771945061350166],
            [20.31809117748719, 39.734091213037729],
            [20.220000111283781, 39.647354105269372],
            [20.196245127066049, 39.645000131575003],
            [20.166663211382257, 39.650273186877442],
            [20.141391270323595, 39.656109170798615],
            [20.097009092885685, 39.67597310791335],
            [20.02458224081073, 39.696664171236151],
            [20.010027065953182, 39.691200176089396],
            [20.008265519000247, 39.690585389000077],
            [20.007195519, 39.68868738899998],
            [20.007040519000157, 39.687237389000089],
            [20.005848519000097, 39.684767389000186],
            [20.005184519000153, 39.683926389000234],
            [20.004749519000114, 39.681396388999971],
            [20.002042519000156, 39.675319389000222],
            [19.996732519000091, 39.665634389000203],
            [19.991238519000092, 39.638635389000243],
            [19.985000519000153, 39.643324389000057],
            [19.969227519000185, 39.654792389],
            [19.96477151900018, 39.65821838900014],
            [19.957914519000099, 39.663986389000073],
            [19.952166519000031, 39.66892538900008],
            [19.940394519000165, 39.678506389000134],
            [19.928245519000114, 39.687203389000118],
            [19.928749519000149, 39.687860389000107],
            [19.929998519000208, 39.689402389000165],
            [19.931642519000064, 39.691177389000117],
            [19.938523519000171, 39.701170889000096],
            [19.939344185666727, 39.702223389000054],
            [19.945886019000142, 39.709995389000071],
            [19.952350519000163, 39.718090389000068],
            [19.952858019000161, 39.718751388999962],
            [19.954727519000187, 39.72073238900019],
            [19.955674519000127, 39.721824389000105],
            [19.957206519000152, 39.723751389000043],
            [19.957817519000059, 39.724720389000097],
            [19.95879851900014, 39.726231389000063],
            [19.959905519000273, 39.727480389000121],
            [19.959960519000106, 39.731887389000164],
            [19.959884519000127, 39.73606938900015],
            [19.958492519000231, 39.742614389000053],
            [19.958156519000227, 39.743555389000122],
            [19.959068519000198, 39.747258389000265],
            [19.960436519000041, 39.752049389000192],
            [19.960468019000075, 39.75565738900012],
            [19.960622519000083, 39.756664389000107],
            [19.963164519000088, 39.759057389000233],
            [19.96370818566669, 39.759619389000093],
            [19.965990519000229, 39.761551389000076],
            [19.9668575190002, 39.762565389000045],
            [19.967577519000088, 39.763345389000094],
            [19.968851519000168, 39.764817389000044],
            [19.970865519000171, 39.768859889000126],
            [19.97136951900012, 39.770114389000085],
            [19.971978519000061, 39.775037389000104],
            [19.972137519000171, 39.776749389000187],
            [19.973430519000118, 39.779935389000187],
            [19.973933019000128, 39.780986389000091],
            [19.975692519000148, 39.782281389000119],
            [19.976820519000142, 39.783465389000227],
            [19.977416519000144, 39.784074389000168],
            [19.978126519000114, 39.784843389000102],
            [19.978058519000058, 39.787239389000021],
            [19.97763151900017, 39.791836389000068],
            [19.977564519000225, 39.793369389000063],
            [19.977042019000066, 39.798998389000062],
            [19.97582551900004, 39.802423389000111],
            [19.971938519000219, 39.816402389000146],
            [19.971188519000066, 39.8189533890001],
            [19.96566951900013, 39.826461389000215],
            [19.949065519000158, 39.83683238899998],
            [19.94498151900018, 39.837421389000227],
            [19.94090651900018, 39.837660389000106],
            [19.933782519000118, 39.8402463890002],
            [19.928423519000091, 39.842028389000106],
            [19.925835519000117, 39.842705389000074],
            [19.924036519000197, 39.8439443890001],
            [19.913700519000145, 39.851472389000079],
            [19.906658519000132, 39.854781389000053],
            [19.897670519000116, 39.857298389000121],
            [19.881708519000142, 39.862096389000087],
            [19.871285519000082, 39.866684389000099],
            [19.860004518000153, 39.871987389000097],
            [19.859197518000087, 39.872353389000196],
            [19.856569518000242, 39.873448389000018],
            [19.848700518000101, 39.876689389000134],
            [19.837031518000231, 39.881974389000064],
            [19.829133518000106, 39.885433389000113],
            [19.826246518000232, 39.886656389000038],
            [19.754038518000044, 39.916239389000097],
            [19.750207517999968, 39.919401389000072],
            [19.749134518, 39.928737389000091],
            [19.732786518000239, 39.941067389000111],
            [19.718330518000101, 39.951764389000132],
            [19.717222517999971, 39.952608388999963],
            [19.714300518000243, 39.954870389000078],
            [19.705026518000125, 39.961951389000106],
            [19.68442251800019, 39.975233389000223],
            [19.678710518000173, 39.979005389000221],
            [19.660424518000013, 39.990784389000126],
            [19.651723518000153, 39.996305389000057],
            [19.649397518000171, 39.997477389000224],
            [19.645234518000137, 39.999770389000233],
            [19.623222517999977, 40.011841389000139],
            [19.575800518000165, 40.021204389000175],
            [19.565376518, 40.02348838900015],
            [19.526073518000203, 40.031289389000065],
            [19.520370518000135, 40.03199338900005],
            [19.50694951800017, 40.033589389000113],
            [19.469245518000093, 40.035191389000161],
            [19.465591518000167, 40.035332389000089],
            [19.448798518000103, 40.0344103890002],
            [19.443751518000113, 40.033992389000062],
            [19.428556518000107, 40.036872388999981],
            [19.427450518000171, 40.037071389000104],
            [19.423413518000189, 40.037825388999963],
            [19.406650518000106, 40.04042038900009],
            [19.398414518000152, 40.042266389000062],
            [19.39107951800014, 40.043943389000091],
            [19.381376518000138, 40.046126389000236],
            [19.353443518000063, 40.05220138899999],
            [19.30919051799998, 40.058817389000112],
            [19.275288518000139, 40.063602389000053],
            [19.254351518000107, 40.066411389000123],
            [19.188281518000196, 40.072577889000101],
            [19.184585518000091, 40.072922389000084],
            [19.149105018000142, 40.075713389000171],
            [19.114508518000122, 40.078527389000229],
            [19.091022518000017, 40.079623389000133],
            [19.070212518000119, 40.080385389000114],
            [19.033238518000189, 40.080622389000197],
            [19.015480518000118, 40.080628389000154],
            [19.014154518, 40.080623389000081],
            [19.008973518000232, 40.080607389],
            [19.004026518000103, 40.080582389000071],
            [18.986794151000083, 40.080423554000092],
            [18.97722222200008, 40.131944444000041],
            [18.951388889000015, 40.203611110000011],
            [18.880000000000109, 40.313888888000108],
            [18.859722222000187, 40.358333332000129],
            [18.851388889000162, 40.38805555499998],
            [18.795833333000161, 40.512222221000016],
            [18.75972222200005, 40.593888887999967],
            [18.745277778000144, 40.646111110000149],
            [18.739722222000097, 40.669444444000121],
            [18.711111111000065, 40.733055555000064],
            [18.671111111000158, 40.847222221000138],
            [18.659444444000144, 40.884999999000016],
            [18.658611111000113, 40.917499999000128],
            [18.595, 41.108055555000178],
            [18.576944444000048, 41.133611110000075],
            [18.542777778000072, 41.19361111000002],
            [18.461944444000153, 41.277499999000085],
            [19.154606755000145, 41.613509818000168],
            [19.286488496000118, 41.670539761000029],
            [19.347611113000141, 41.694427068000024],
            [19.396433112000153, 41.715037360000011],
            [19.413294628000131, 41.734529705999975],
            [19.409657402000107, 41.745884413000041],
            [19.374238905000112, 41.83949145800004],
            [19.368341902000168, 41.847946213000156],
            [19.36777300591524, 41.849000064999231],
            [19.34332713270723, 41.912500077573256],
            [19.34694509740396, 41.955554058630369],
            [19.376391226255635, 42.07280917303153],
            [19.399100148954375, 42.104091106321917],
            [19.378191156148688, 42.123464199185449],
            [19.33221809348359, 42.152500117694743],
            [19.288536140238818, 42.18290915958606],
            [19.519718082918899, 42.448609120743541],
            [19.608536081975245, 42.542845014389556],
            [19.611182248815027, 42.571036037056317],
            [19.633327069428361, 42.603336036309358],
            [19.651527198758401, 42.623191088606845],
            [19.717845152143866, 42.660345048002128],
            [19.754582195674146, 42.640973128605182],
            [19.7647911861323, 42.587873101288139],
            [19.746809153923692, 42.545000169340071],
            [19.769445154064385, 42.500964164504225],
            [19.793954174294072, 42.484091058088765],
            [19.822500254380202, 42.471936124983941],
            [19.927354175360676, 42.515554040488198],
            [20.056109093312614, 42.562909111352909],
            [20.071418136591092, 42.5609091892495],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "AND", Country: "Andorra" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.723609227717446, 42.509436089343396],
            [1.718891222044903, 42.503054108247895],
            [1.657782118583356, 42.469582151289572],
            [1.537500131299083, 42.436664238132849],
            [1.512218131956416, 42.436382103271157],
            [1.451527117826373, 42.446245088765366],
            [1.424163221367337, 42.493327077223611],
            [1.421391158341265, 42.54582712790959],
            [1.434445134383253, 42.576382182554696],
            [1.445836140830409, 42.601944975654519],
            [1.486527095737756, 42.650418024767689],
            [1.559718209746364, 42.655964162476337],
            [1.698336113192283, 42.626109164386421],
            [1.738609146406105, 42.616391185817733],
            [1.781718112748166, 42.569964159275301],
            [1.771391105092903, 42.571109127252257],
            [1.741873227149739, 42.560627054387894],
            [1.724100239606997, 42.521382145420532],
            [1.723609227717446, 42.509436089343396],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ANT", Country: "Netherlands Antilles" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.769858395999961, 17.642872968000049],
            [-62.791781549999854, 17.603416094000139],
            [-62.820495828999896, 17.554153010000121],
            [-62.825994809999969, 17.544941184000081],
            [-62.855389652000014, 17.49479998300005],
            [-62.868379419, 17.477485838000021],
            [-62.870277667000039, 17.47469012100008],
            [-62.873358277000023, 17.470296823],
            [-62.874074894999978, 17.469341135000079],
            [-62.884349954999976, 17.455863059000094],
            [-62.886488837999934, 17.452629624999972],
            [-62.894936243999979, 17.440964003000133],
            [-62.898416640999898, 17.436417022000043],
            [-62.900701079000015, 17.433939077],
            [-62.902282627000034, 17.432115770000152],
            [-62.903984251999958, 17.430254772999987],
            [-62.913963900999846, 17.419015612999985],
            [-62.920498697999903, 17.411927855],
            [-62.922634573999972, 17.409713447000016],
            [-62.924941997000019, 17.407428439000043],
            [-62.930275085999853, 17.40195904100004],
            [-62.939288668000017, 17.391235035],
            [-62.946482036999953, 17.383661568],
            [-62.960989758999943, 17.369978527000015],
            [-62.963500751999959, 17.367652297000106],
            [-62.994871874000012, 17.33571707],
            [-63.112053920999983, 17.211559063999985],
            [-63.211909047999939, 17.103511683000065],
            [-63.62902627879987, 16.680056111800113],
            [-63.630249825499817, 16.68045568400008],
            [-63.63789652, 16.681776071999977],
            [-63.19861, 17.416861],
            [-63.15158, 17.495556],
            [-63.12764, 17.537361],
            [-63.120194, 17.552583],
            [-63.11106, 17.571583],
            [-63.106471999999883, 17.57994400000014],
            [-63.09661, 17.598333],
            [-63.06775, 17.650194],
            [-63.04797, 17.685],
            [-63.026028, 17.725194],
            [-63.020592054999952, 17.735150815000097],
            [-63.017807529999942, 17.731768435000092],
            [-62.994003293999896, 17.725184042000123],
            [-62.952830345999928, 17.713984403000083],
            [-62.936876206999983, 17.707939814000085],
            [-62.896598448999924, 17.693013226000076],
            [-62.88463044, 17.688475704000084],
            [-62.861022744999985, 17.677684332000169],
            [-62.848245700999911, 17.672362068000055],
            [-62.812524710999924, 17.657482527000113],
            [-62.804626965999915, 17.654970127000084],
            [-62.800612881999967, 17.653570709000107],
            [-62.799499664999956, 17.653186031000175],
            [-62.774281781999832, 17.644411993000105],
            [-62.769858395999961, 17.642872968000049],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ARE", Country: "United Arab Emirates" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [56.840910195000021, 25.65674402600014],
            [56.841363908000091, 25.653453827000035],
            [56.841765403000096, 25.650542297],
            [56.843130037000122, 25.640646362000027],
            [56.858077853000196, 25.596920420000131],
            [56.867847436000119, 25.568342051000045],
            [56.900932433000122, 25.473887239000177],
            [56.910160578000188, 25.447541681],
            [56.915974036000165, 25.435709696999979],
            [56.930620443000095, 25.405900232000093],
            [56.931046239000153, 25.405055685000022],
            [56.972040766000106, 25.33918751100002],
            [57.019133856000082, 25.263520436000064],
            [57.034288561000125, 25.240346869999982],
            [57.04673490400009, 25.220941253000106],
            [57.080325950000059, 25.169266947999958],
            [57.087242679000184, 25.158574342000051],
            [57.0918103220001, 25.151513200000053],
            [57.093797130000013, 25.149092492000065],
            [57.095907775000029, 25.146520902],
            [57.108153818000034, 25.132334926999974],
            [57.110249183000093, 25.130189859499993],
            [57.120137668000126, 25.120127010000019],
            [57.125662434091026, 25.114528148000048],
            [57.034107306000152, 25.089745453999953],
            [56.833937628000086, 25.055259353000011],
            [56.724244612000149, 25.03876925400003],
            [56.68471924200017, 25.033344574],
            [56.674841969000141, 25.031988963000103],
            [56.614322938000129, 25.020431804000069],
            [56.520329386000157, 25.002481951],
            [56.440907386000191, 24.989943951],
            [56.380311386000045, 24.981982951000063],
            [56.373527294735794, 24.979381517154209],
            [56.352145157039587, 24.976527041213544],
            [56.339009205984581, 24.96944500356274],
            [56.33464508426502, 24.961809089748598],
            [56.339163265365215, 24.926109055281913],
            [56.340136236688551, 24.91402704473505],
            [56.332145097816948, 24.895136078943565],
            [56.303327108790739, 24.882500024594989],
            [56.287082309839235, 24.870136046824413],
            [56.280473346804882, 24.857273178175447],
            [56.269436224310795, 24.84950902124244],
            [56.226109160847471, 24.833609053788408],
            [56.188763255868849, 24.825000168650931],
            [56.179718176488876, 24.781664220370303],
            [56.143745059616009, 24.741109052294789],
            [56.104163365778078, 24.734718018743692],
            [56.076945146796646, 24.738054016216793],
            [56.064436162100748, 24.744236005102053],
            [55.981136137558252, 24.871735984507026],
            [55.99874517507439, 24.890273233983706],
            [56.028609225619761, 24.881245086048054],
            [56.050827136429035, 24.86610904525169],
            [56.03471812430908, 24.946391081728436],
            [56.022773241698502, 24.96582703886601],
            [56.0001362357294, 24.977082090843354],
            [55.976573197094837, 24.981991203908834],
            [55.94527332653152, 24.982500153071115],
            [55.922218231230744, 24.976527041213544],
            [55.888745100806233, 24.959582185706623],
            [55.866391235527118, 24.942218067401896],
            [55.845554159450813, 24.920273071360825],
            [55.814027139310866, 24.885827137250786],
            [55.80380926403538, 24.860064184302743],
            [55.813891184840969, 24.729718045847548],
            [55.820000251168523, 24.701664151116987],
            [55.843609222632466, 24.649718144232494],
            [55.812491239368711, 24.59610916775317],
            [55.781382308560268, 24.565418158638337],
            [55.797773287903766, 24.476944991079378],
            [55.820000251168523, 24.416664187291914],
            [55.813609217617426, 24.316945020211293],
            [55.778954071203628, 24.243645109099091],
            [55.862500188424036, 24.214026983593769],
            [55.892082271745636, 24.227427132237707],
            [55.918609151351433, 24.236664157200849],
            [55.948609156366643, 24.232573117887583],
            [56.023673290463961, 24.083191174353672],
            [56.007082318910221, 24.068609009720447],
            [55.875273203070805, 24.035000092464017],
            [55.801382200145071, 24.020000173775557],
            [55.680273254291166, 24.031109045361106],
            [55.637909271505492, 24.027909169995795],
            [55.510273337630593, 23.972773178391819],
            [55.538191277891173, 23.905000126611057],
            [55.556382187127525, 23.885764161683724],
            [55.562563337822525, 23.76853603701106],
            [55.48583623413046, 23.641664197431837],
            [55.421945176997127, 23.507218134845957],
            [55.361382238347886, 23.326109011323481],
            [55.343891218028745, 23.281800091719518],
            [55.323609191582165, 23.24389109048407],
            [55.296391307876917, 23.201382100772875],
            [55.267491176199343, 23.157773070085952],
            [55.242073222386949, 23.116809032772508],
            [55.214163328753415, 23.014027118262973],
            [55.212218224297061, 22.918327073766065],
            [55.213336202545776, 22.894164226138528],
            [55.217218197193318, 22.851664121244838],
            [55.218609257848158, 22.820554016970007],
            [55.217773246823043, 22.800553957747198],
            [55.199163242426863, 22.699718153522312],
            [55.141654166389031, 22.633327109940907],
            [52.58332715746181, 22.938891067436188],
            [51.583327213806825, 24.11666413714029],
            [51.58422716417985, 24.26046415582492],
            [51.577125614000153, 24.272546800999962],
            [51.570542842000037, 24.289003730000147],
            [51.562314377000092, 24.300523580000103],
            [51.554085913000137, 24.315334816000174],
            [51.544211755000077, 24.331791745000075],
            [51.535983291000065, 24.348248674000146],
            [51.524463441000165, 24.366351296000047],
            [51.523659435000098, 24.369969321],
            [51.521172055000164, 24.381162532000118],
            [51.519278606000086, 24.388736327999979],
            [51.51788066900022, 24.39432807499999],
            [51.51788066900022, 24.407493618999965],
            [51.511297897000219, 24.420659162000121],
            [51.508006512000094, 24.433824705],
            [51.509652204000105, 24.446990248000148],
            [51.519526362000164, 24.470029949000079],
            [51.537628984000065, 24.509526579000166],
            [51.568897149000037, 24.547377515999969],
            [51.593582543000053, 24.58029137400014],
            [51.610039472000125, 24.626370774999984],
            [51.612386956000108, 24.669799242000053],
            [52.272222000000198, 24.811111],
            [52.341796712000104, 24.86960792100011],
            [52.380600087000204, 24.902232964000163],
            [52.378918191000054, 24.902536354000134],
            [52.375838603000176, 24.904195822000034],
            [52.371340531000129, 24.9068276470001],
            [52.366351365000156, 24.909652596000157],
            [52.363512715000212, 24.911259888],
            [52.363047852000108, 24.911638641750059],
            [52.362694359000074, 24.912065107],
            [52.357744067000027, 24.915063346000025],
            [52.356943423000104, 24.916149417999989],
            [52.355894536000136, 24.916823437],
            [52.352091061000095, 24.920623746],
            [52.3485841090002, 24.925640128000126],
            [52.339490996000023, 24.938369090000023],
            [52.339345553000129, 24.93903045200004],
            [52.338660594000117, 24.940100002500017],
            [52.338408572000191, 24.940827578500077],
            [52.33778846200002, 24.941668588000184],
            [52.333584402000184, 24.9607889440001],
            [52.333583468000171, 24.962704606500083],
            [52.331981925000179, 24.96998641],
            [52.336168473000185, 24.989109390000053],
            [52.344830732000133, 25.00207280700009],
            [52.345881394000145, 25.003210614500048],
            [52.346800202000196, 25.004134749999977],
            [52.349313907000095, 25.006624944000052],
            [52.367198481000088, 25.01746062399998],
            [52.388300477000058, 25.02126646000012],
            [52.389198483000115, 25.02126646000012],
            [52.390724270000163, 25.020991463000101],
            [52.391699566000199, 25.021167278],
            [52.412800642000064, 25.017362739000063],
            [52.430685971500026, 25.006528182000054],
            [52.431413394000145, 25.005611231500055],
            [52.431994463333496, 25.005176017333312],
            [52.432606994000054, 25.004695858000147],
            [52.433804735000223, 25.003493901999988],
            [52.443828877000072, 24.989112519000045],
            [52.444068196500183, 24.98801988700005],
            [52.445580685750116, 24.985576832250089],
            [52.446211810000051, 24.984567585999955],
            [52.447138252666804, 24.983159034333354],
            [52.448831785500062, 24.980690116500071],
            [52.449151578000198, 24.98021725],
            [52.449750685000112, 24.979387376999981],
            [52.450541154000149, 24.978250167333371],
            [52.452028338500128, 24.976091269499975],
            [52.453075472000052, 24.971923196000134],
            [52.454694071000205, 24.964529592000062],
            [52.614167000000208, 25.098611000000133],
            [53.034721886000085, 25.530555883200108],
            [53.054141312000155, 25.516309042000088],
            [53.06586324100013, 25.507709261000045],
            [53.077739705000141, 25.498996106000064],
            [53.122499873000066, 25.483675295000111],
            [53.179355724000203, 25.47295308700005],
            [53.184699727000151, 25.472078333000169],
            [53.189180887000106, 25.471344816000041],
            [53.290432838000157, 25.470376297000016],
            [53.304003625000092, 25.470246487000125],
            [53.511652084000076, 25.478071300000053],
            [53.579643514000082, 25.494427327000139],
            [53.608771922000102, 25.505458304999976],
            [53.624497776000084, 25.511413712999953],
            [53.767027444000149, 25.560335850000016],
            [53.843831936000157, 25.589248618000042],
            [53.933843173000156, 25.623133017000029],
            [54.07600363300017, 25.635887607000015],
            [54.076652667000218, 25.63594583800004],
            [54.087777692500168, 25.636943972500077],
            [54.438332692500211, 25.665277975500103],
            [54.506943919000122, 25.693055967500115],
            [54.508148792000185, 25.693012239],
            [54.514747591000202, 25.692772553000069],
            [54.519764033000143, 25.692376945000021],
            [54.5532610180002, 25.689735297000126],
            [54.57758421500003, 25.69164592700001],
            [54.591775591000129, 25.692760685000152],
            [54.603023792000187, 25.695469969999962],
            [54.629126818000174, 25.701757246000156],
            [54.630226025000155, 25.702218196],
            [54.631625802000173, 25.702555127],
            [54.666684399000047, 25.717250211000035],
            [54.676446767000158, 25.723393173000105],
            [54.6983884630001, 25.737199966],
            [54.725799929250087, 25.761830471499977],
            [54.727024582000098, 25.762954515000118],
            [54.747221850000159, 25.788888807500157],
            [54.751944000000123, 25.791667000000118],
            [54.767203212000112, 25.795839268],
            [54.771148228000044, 25.796917938999982],
            [54.772628252000089, 25.797529680000068],
            [54.786401564333431, 25.803222628],
            [54.79415964200021, 25.806429287000029],
            [54.886823259000181, 25.844462176999983],
            [54.913342352000058, 25.861663181000083],
            [54.935239674000144, 25.876322609000013],
            [54.936316411500087, 25.877043443500028],
            [55.07524884700004, 25.970626668000122],
            [55.078742527000173, 25.972978081999983],
            [55.100963250000206, 25.987933698000106],
            [55.106543091000134, 25.989194702500114],
            [55.2262895530001, 26.015865321000135],
            [55.28429662850013, 26.02878455550011],
            [55.294952483500111, 26.031695989500136],
            [55.35153408350007, 26.047043627500003],
            [55.408977657000122, 26.062625074999957],
            [55.423228664000106, 26.066765509999968],
            [55.425297294000217, 26.067366522999961],
            [55.460192198000158, 26.084592040000032],
            [55.486572418000065, 26.09761436899997],
            [55.49934018400009, 26.103527118000088],
            [55.536724428000099, 26.120839753000084],
            [55.546844619000154, 26.124612149000015],
            [55.552760739000092, 26.126817438000145],
            [55.557322280000136, 26.128113307000078],
            [55.569975186000107, 26.131707856500086],
            [55.57143513000014, 26.132766201000024],
            [55.587579127000168, 26.144469336000057],
            [55.611562023000175, 26.16185505900016],
            [55.615414877000177, 26.165320571500146],
            [55.617374676000139, 26.167116875000133],
            [55.627920024000133, 26.176808172999969],
            [55.65876582100006, 26.200029293999975],
            [55.682623779000011, 26.217989879000029],
            [55.683920239000173, 26.218965856500105],
            [55.717547588000087, 26.240926574000056],
            [55.719928700000224, 26.242481586000011],
            [55.722563572000155, 26.243956112999982],
            [55.757958236000064, 26.263763673000099],
            [55.816444882000127, 26.228058169000164],
            [55.850564041000069, 26.207228768000149],
            [55.900765839500053, 26.176581079],
            [55.93669777900007, 26.154644994000094],
            [55.952823918000121, 26.144410762],
            [55.960880231000118, 26.139297933],
            [55.999248416000029, 26.114948093000024],
            [56.009441455000086, 26.109668462000101],
            [56.015572262, 26.106492922000072],
            [56.023791428000067, 26.101785153000108],
            [56.043659752000138, 26.090404986000053],
            [56.061278142000134, 26.079078878000146],
            [56.076384386000115, 26.069367952],
            [56.079936272765309, 26.065564100303376],
            [56.153182244427654, 26.083882065763405],
            [56.185554160410135, 26.014718120965611],
            [56.165818131137968, 25.90805404417047],
            [56.139163343689603, 25.832500071650074],
            [56.140827151474497, 25.734991044805],
            [56.147218185025594, 25.666109067230806],
            [56.1589733013478, 25.662500154989502],
            [56.183327256368187, 25.654991143189861],
            [56.238036106185149, 25.626109116423279],
            [56.269718288363691, 25.636017992021365],
            [56.280459542000102, 25.640293253000024],
            [56.300219904000215, 25.640281437000127],
            [56.303782286000086, 25.642664194],
            [56.305776628000075, 25.644160217999982],
            [56.311097094000189, 25.647765967999973],
            [56.314217028000172, 25.648702933999957],
            [56.314908164000059, 25.648909891000116],
            [56.320088661000142, 25.650431519000094],
            [56.326390651000139, 25.652506564000092],
            [56.331097496000012, 25.654056378000163],
            [56.33420277500008, 25.655155972],
            [56.337398632000173, 25.656287639000126],
            [56.367745972000165, 25.673183624000117],
            [56.36915163, 25.673372196666733],
            [56.370883873500162, 25.673687323250064],
            [56.379388775000194, 25.675276515],
            [56.380454582000056, 25.675396645000163],
            [56.38514038800011, 25.675790447999972],
            [56.389900035000011, 25.676114449000139],
            [56.395559697000209, 25.676418112000036],
            [56.41911340700014, 25.678680160000155],
            [56.450786476000218, 25.681325979000135],
            [56.461081030000202, 25.682185937999961],
            [56.470283330000171, 25.683074995000069],
            [56.501131042000083, 25.686055268],
            [56.516011758000133, 25.687113222999969],
            [56.52903933900015, 25.687374176999967],
            [56.571833351000038, 25.684448088999986],
            [56.673237233000151, 25.677827711000035],
            [56.777176069000035, 25.67104183299999],
            [56.83432255000011, 25.658221867000023],
            [56.838958296000072, 25.657181906000133],
            [56.840272212000087, 25.656887148999985],
            [56.840910195000021, 25.65674402600014],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ARG", Country: "Argentina" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.643772856568205, -22.238899974931144],
            [-62.617781915853016, -22.275555043448151],
            [-62.521390867256372, -22.370835825147054],
            [-62.392363872726662, -22.456808838619679],
            [-62.363472961142861, -22.46847292747313],
            [-62.341390837165306, -22.47028190982158],
            [-62.315835755416629, -22.480835899415396],
            [-62.285281874237995, -22.49583581810387],
            [-62.250417850796538, -22.525281946955658],
            [-62.240281950534325, -22.542782019730382],
            [-62.237646009616242, -22.570417825129326],
            [-62.15472685875514, -22.756108999854348],
            [-62.101954899129225, -22.816390977108284],
            [-62.042226798038627, -22.87639098713862],
            [-62.025699864225317, -22.899026819641321],
            [-62.004654916949818, -22.950072945398517],
            [-62.006954911187535, -22.975282022183322],
            [-61.990490841648295, -23.005490904226193],
            [-61.91889094814286, -23.068335899840264],
            [-61.88902689759766, -23.074308844059757],
            [-61.855281858233241, -23.089999934486116],
            [-61.757635871089747, -23.171526851225991],
            [-61.746254922926312, -23.188054958505703],
            [-61.744163973354233, -23.234235892370151],
            [-61.671735780174913, -23.286317853724739],
            [-61.63263587813276, -23.283473035778982],
            [-61.594173000733974, -23.297090946268113],
            [-61.450835750510748, -23.418055052834774],
            [-61.364172906404889, -23.456108893357552],
            [-61.294726826745489, -23.489172986906141],
            [-61.182499848435384, -23.554726845996058],
            [-61.111326761440978, -23.608399860215727],
            [-61.034726895039483, -23.754173059146495],
            [-61.024726781609075, -23.786108948524472],
            [-61.010699834243752, -23.810835898237229],
            [-60.986390973862598, -23.821663976065651],
            [-60.875281973801123, -23.849726923251524],
            [-60.76083580279996, -23.872499883690608],
            [-60.732226858439589, -23.872217916466994],
            [-60.613890981440079, -23.894446053198152],
            [-60.592017902128475, -23.918963958245158],
            [-60.577426852677917, -23.946317964058494],
            [-60.456390829381803, -23.988890991510033],
            [-60.279726796763924, -24.03847296641645],
            [-60.162781980419169, -24.041673009419654],
            [-60.139454976178769, -24.037781962316728],
            [-60.115835778792757, -24.032217887335264],
            [-60.087781884062281, -24.024172936644931],
            [-60.066672899046353, -24.016391010077072],
            [-60.038063954686066, -24.00972689411978],
            [-59.982781950328189, -24.042781935212886],
            [-59.670281967936091, -24.233608869665886],
            [-59.533617886125739, -24.315699888490997],
            [-59.470835754785639, -24.354726867975273],
            [-59.383199939356359, -24.43513580646642],
            [-59.356945974518851, -24.471390890562816],
            [-59.338890852114218, -24.488890963337454],
            [-59.289726798901427, -24.515281888473353],
            [-59.101954900526323, -24.608335933768373],
            [-59.058617778779109, -24.618617846784304],
            [-58.98972691638761, -24.654726918126983],
            [-58.938608873901046, -24.685555055178213],
            [-58.922499861781262, -24.697781905012405],
            [-58.881390985180502, -24.729999929252074],
            [-58.807917903948265, -24.781526840976753],
            [-58.669726807013092, -24.822226848339483],
            [-58.604446030329484, -24.828608997073161],
            [-58.575835744864634, -24.826390977848703],
            [-58.5326459769758, -24.833199933093212],
            [-58.449308904421173, -24.859581973411608],
            [-58.352226851724822, -24.967917904618815],
            [-58.281672852100684, -24.978617906966434],
            [-58.181808845732547, -24.975554991899443],
            [-58.129173008214821, -25.008054983362726],
            [-58.059717876100024, -25.034446076136618],
            [-57.935563952452043, -25.077499889555639],
            [-57.908054881429507, -25.078054939185449],
            [-57.879172854662954, -25.08486406206805],
            [-57.858063869646998, -25.097499948778449],
            [-57.761108886603154, -25.171526906173995],
            [-57.749726764973218, -25.200282030926061],
            [-57.737290870473174, -25.233263981823313],
            [-57.706390984330682, -25.271390912542032],
            [-57.672672934694475, -25.29429999508902],
            [-57.668617937564932, -25.306117975685197],
            [-57.63208189207333, -25.386390959706532],
            [-57.588199946618289, -25.415417825760315],
            [-57.556326921514369, -25.439999936185757],
            [-57.553890972806641, -25.468335798139833],
            [-57.576663933245726, -25.549445966652684],
            [-57.74889092158628, -25.735135800273142],
            [-57.792917873966616, -25.766673046334986],
            [-57.810981881188667, -25.773135828977459],
            [-57.805554934143402, -25.833055037460909],
            [-57.853890855320373, -25.904446053938571],
            [-57.900772851568263, -25.955072916897677],
            [-57.890908860245844, -25.973890960131399],
            [-57.860073011843696, -25.997917853289024],
            [-58.002917909072806, -26.104173060846151],
            [-58.048335754470088, -26.119163927079214],
            [-58.144726803066732, -26.206946090538366],
            [-58.131390859801115, -26.2572269485335],
            [-58.161945914446136, -26.300555017825204],
            [-58.209935830659049, -26.42034599322011],
            [-58.217226912975519, -26.541390901333543],
            [-58.182917771525894, -26.571108939125054],
            [-58.168608856936942, -26.600000018347018],
            [-58.181672891262735, -26.656108981274429],
            [-58.31667299765013, -26.872782017155316],
            [-58.352763964081817, -26.885390914137389],
            [-58.394445995223293, -26.908055077472881],
            [-58.471163878821869, -26.987264062702124],
            [-58.578608813718915, -27.122499868207413],
            [-58.605835749879844, -27.125000022293577],
            [-58.628063886611017, -27.131108920982882],
            [-58.652090947406634, -27.158890906773621],
            [-58.653517882607218, -27.193045988842542],
            [-58.614863897815411, -27.227226887173302],
            [-58.598963930361265, -27.249235920954817],
            [-58.604617859345126, -27.316917945266908],
            [-58.560981838929877, -27.301109005281546],
            [-58.529163966748882, -27.293335795893029],
            [-58.505563880102187, -27.288608905403066],
            [-58.47972683112971, -27.28610891895498],
            [-58.289726855246329, -27.273890953938121],
            [-58.156390891920154, -27.268890981041963],
            [-58.011117924972353, -27.270282041696802],
            [-57.935835861391666, -27.275555096999241],
            [-57.791390858841964, -27.292226870099782],
            [-57.729590918918689, -27.307081949501125],
            [-57.703899882699915, -27.319446094909694],
            [-57.682499878004847, -27.334999889761676],
            [-57.644863959175609, -27.360699978435846],
            [-57.616954903732278, -27.371664010734179],
            [-57.413063934953669, -27.417781912686493],
            [-57.344926773297857, -27.426117883055852],
            [-57.32361779607146, -27.425554954437018],
            [-57.296954961995937, -27.43111785595201],
            [-57.269172976205198, -27.44221784272024],
            [-57.237363988841707, -27.459581961025052],
            [-57.207226855890212, -27.47194610643372],
            [-57.164455009694706, -27.484172956267912],
            [-57.1216729375775, -27.490282022595395],
            [-57.077781939666977, -27.492226959413657],
            [-57.044235886684419, -27.476808951393579],
            [-56.998608828983379, -27.450835947951205],
            [-56.964726829320739, -27.434173059667984],
            [-56.916181863478016, -27.419308927811244],
            [-56.880699926132678, -27.419582010217539],
            [-56.862499964440929, -27.428199947810413],
            [-56.835835789260841, -27.449446060762838],
            [-56.81333574358996, -27.470281963372585],
            [-56.792646021371894, -27.48972697296557],
            [-56.766535889993321, -27.501946111448831],
            [-56.731390905156644, -27.505281941283954],
            [-56.706272855840496, -27.483435851700662],
            [-56.69667289446889, -27.4741639580201],
            [-56.673055038187357, -27.461390943373416],
            [-56.644445926189007, -27.453890984029186],
            [-56.606945961829553, -27.448335961503119],
            [-56.578199889532982, -27.448617928726733],
            [-56.549999814410739, -27.452499923374248],
            [-56.520281944257221, -27.461945993003226],
            [-56.504172932137323, -27.475281936268743],
            [-56.493890851483229, -27.502364033142356],
            [-56.456390887123888, -27.547226828909743],
            [-56.418617840358138, -27.574717962659449],
            [-56.398055020154686, -27.584445999512056],
            [-56.365626777782779, -27.584726960907261],
            [-56.345554969468594, -27.570281924210434],
            [-56.274999964015961, -27.468335853087837],
            [-56.268890897688578, -27.448608876271322],
            [-56.271390884136565, -27.424999904807208],
            [-56.268126803392931, -27.389382012991973],
            [-56.144026859201489, -27.311464008493502],
            [-56.098617898621796, -27.304726969978347],
            [-56.064446052746348, -27.302782033160085],
            [-56.035699812811799, -27.305135839216376],
            [-55.965272882840054, -27.331955079605677],
            [-55.923199919733463, -27.32652695909411],
            [-55.892508910618545, -27.332635857783501],
            [-55.867572916240022, -27.358408869015449],
            [-55.859026895376417, -27.39152694202059],
            [-55.844726865605082, -27.406254951769341],
            [-55.757781886637446, -27.441946101418509],
            [-55.731672928725146, -27.436673046116155],
            [-55.599999934993633, -27.34208192741248],
            [-55.584590979429066, -27.316808980525323],
            [-55.568481967309197, -27.255835831533574],
            [-55.570281897202136, -27.235417850617253],
            [-55.581117854019482, -27.214999869701032],
            [-55.573335759813546, -27.166673000979557],
            [-55.547499884307655, -27.112226840008816],
            [-55.417645931208199, -26.986391003664963],
            [-55.400835856704902, -26.974026858256309],
            [-55.37944591029364, -26.966390944442168],
            [-55.271117858075456, -26.93749986522019],
            [-55.251108914035143, -26.936108972203343],
            [-55.12999980054326, -26.872708926959376],
            [-55.055626838183656, -26.803264020766548],
            [-55.034445936438146, -26.794999967126657],
            [-54.992508927801367, -26.793890873695332],
            [-54.963335881355789, -26.783199923803295],
            [-54.949308933990665, -26.768335959584647],
            [-54.940417913991496, -26.740835941017508],
            [-54.92889095307433, -26.688263973602048],
            [-54.907781968058202, -26.667781954945283],
            [-54.87659994705399, -26.65528202270481],
            [-54.842226767863821, -26.657499874291275],
            [-54.693199881749393, -26.428054997230092],
            [-54.65875495346782, -26.285555099136644],
            [-54.667499960713286, -26.238881979916343],
            [-54.640972913469483, -26.214308921946298],
            [-54.639590905270154, -26.187782042340586],
            [-54.660972972692292, -26.157917991795287],
            [-54.660354891150433, -25.976254992109659],
            [-54.604445920433477, -25.943608987892659],
            [-54.61167296500949, -25.781946047429741],
            [-54.623335880396553, -25.648608910637108],
            [-54.598917887635736, -25.573226934770432],
            [-54.546390847221318, -25.570282036900508],
            [-54.46889093205445, -25.620555015906646],
            [-54.45250883752837, -25.633054948147119],
            [-54.384035896830284, -25.580699904386435],
            [-54.313335884452528, -25.555281950573956],
            [-54.278681911505117, -25.55274608958014],
            [-54.177508819495614, -25.561946066531249],
            [-54.156108814800547, -25.535554973757357],
            [-54.107572901413221, -25.496391033974859],
            [-53.888617808567432, -25.633755004702337],
            [-53.861663954812883, -25.685281916427016],
            [-53.825281968702171, -25.801390889384649],
            [-53.825690837940101, -25.822782009262383],
            [-53.834726864864678, -25.881390958637866],
            [-53.826881906384699, -25.951390914460603],
            [-53.738617783491662, -26.08778191386466],
            [-53.664308858872403, -26.193335891400167],
            [-53.654172958610275, -26.217635866964002],
            [-53.650008829101068, -26.249999903957388],
            [-53.650281911507165, -26.263055053465763],
            [-53.653890823748583, -26.277499922524427],
            [-53.66889091007522, -26.30999991398771],
            [-53.690972866414512, -26.334863991636851],
            [-53.711735846466496, -26.364099902356401],
            [-53.709445910512557, -26.511108929094817],
            [-53.725835884027475, -26.656672915721657],
            [-53.735835829819877, -26.695835849675746],
            [-53.717090876782208, -26.782646047639972],
            [-53.69861783268405, -26.882917917417629],
            [-53.705281948641442, -26.911663989714199],
            [-53.734172860225328, -26.978055033295604],
            [-53.745563866672569, -27.002781983008376],
            [-53.807781895927263, -27.129173036620116],
            [-53.838755039903987, -27.171117924245991],
            [-53.857781960165369, -27.149172928204919],
            [-53.899726847791158, -27.155281994532402],
            [-54.005835874956489, -27.191108931013289],
            [-54.026390816171102, -27.224999983131426],
            [-54.048617779435688, -27.250281982474093],
            [-54.070835857883054, -27.268054970016934],
            [-54.091946016365483, -27.283335850100713],
            [-54.113754890298651, -27.290835809444943],
            [-54.145554992844808, -27.289999966057991],
            [-54.234172999690912, -27.381390874120285],
            [-54.28028184918773, -27.424517945373339],
            [-54.358063899216489, -27.456946020107068],
            [-54.450835809649902, -27.469173037579438],
            [-54.529726785471723, -27.490835898758817],
            [-54.626945966104358, -27.53083584956633],
            [-54.686117844098675, -27.55528200552196],
            [-54.771255014184334, -27.565135938560573],
            [-54.789517840150154, -27.553890944867035],
            [-54.792499953670273, -27.533055042257274],
            [-54.81360893868623, -27.53555502870536],
            [-54.898608813197427, -27.67028205268646],
            [-54.901672901730905, -27.711108962063605],
            [-54.908199889751813, -27.730281895078704],
            [-55.021663869336152, -27.833890935796035],
            [-55.063754937353735, -27.845699863936716],
            [-55.179726782374985, -27.856255026997005],
            [-55.321254882611669, -27.922081968493117],
            [-55.374172854991286, -27.973608880217796],
            [-55.379581864763452, -27.993472984970694],
            [-55.380281921318755, -28.025835848497678],
            [-55.447781890693221, -28.086673043019516],
            [-55.582226779812714, -28.133054974922629],
            [-55.626299832660777, -28.141017950599604],
            [-55.633890819473777, -28.158817927870771],
            [-55.678608943592025, -28.195554971401123],
            [-55.70944596546056, -28.208617832260444],
            [-55.748335817008467, -28.217082045748668],
            [-55.770281986516011, -28.235626838938103],
            [-55.767508917661729, -28.256255037986577],
            [-55.717781935829919, -28.28083580730754],
            [-55.680835847633716, -28.302426919395629],
            [-55.667635858838196, -28.335626967414044],
            [-55.697535783929055, -28.407708987991327],
            [-55.720626753775576, -28.387635838572677],
            [-55.73291780898839, -28.363191023721527],
            [-55.833063950217934, -28.353054955821221],
            [-55.869790935464493, -28.356317863098482],
            [-55.893063960248355, -28.369726896559925],
            [-55.978890960967163, -28.488335855965744],
            [-56.01111787002435, -28.561108880642749],
            [-56.066117907158514, -28.626108863569229],
            [-56.170008915099373, -28.728335896087046],
            [-56.27556389846319, -28.776672990730404],
            [-56.29541777729429, -28.797435803144296],
            [-56.30167285637549, -28.831946110270763],
            [-56.413063991298657, -29.046390901005296],
            [-56.427390843160225, -29.06957306595848],
            [-56.515008888954782, -29.092782053001997],
            [-56.534172937152448, -29.103335874957736],
            [-56.571672901512073, -29.124726827197293],
            [-56.59222683689822, -29.140691000029861],
            [-56.624999910767599, -29.174446097678086],
            [-56.644173011420889, -29.200699894877602],
            [-56.654863961312998, -29.230835854362624],
            [-56.655208792810583, -29.263263929096453],
            [-56.695835877615366, -29.34326399834967],
            [-56.81333574358996, -29.4672268146047],
            [-56.913063963126064, -29.558890972711282],
            [-57.045008865797485, -29.706108876477359],
            [-57.076254924542127, -29.737917863840934],
            [-57.103890897579163, -29.762154975130798],
            [-57.144726859411804, -29.776526921631699],
            [-57.194726756011647, -29.777499892954935],
            [-57.227781964742832, -29.780555096670994],
            [-57.257499834896237, -29.791390885850348],
            [-57.279917905553958, -29.805699968077192],
            [-57.297426863146001, -29.825835814131821],
            [-57.318681860915746, -29.875282002206404],
            [-57.318926780127242, -29.910835856281196],
            [-57.325490983798346, -29.978272961381791],
            [-57.344526788877062, -29.999999860301699],
            [-57.486946053061985, -30.132226897834627],
            [-57.533617831177736, -30.163335828642971],
            [-57.553890972806641, -30.172781898272049],
            [-57.582508801984432, -30.180835901417694],
            [-57.60799984599285, -30.18492694073106],
            [-57.629445951155446, -30.237782048836607],
            [-57.624999854422612, -30.278890925437366],
            [-57.631390887973708, -30.298755030190264],
            [-57.66889085233305, -30.354726865181291],
            [-57.746117852731999, -30.41749994406598],
            [-57.796954933823343, -30.44833596010622],
            [-57.868617859240828, -30.499445955965768],
            [-57.882781934542351, -30.515135872925654],
            [-57.889454935317048, -30.534999977678552],
            [-57.889863972193154, -30.598335817543997],
            [-57.871535767382426, -30.6215268673146],
            [-57.845835846346318, -30.642646078252525],
            [-57.813890904512846, -30.713754959868396],
            [-57.806399829985935, -30.748217993060848],
            [-57.802917819759131, -30.765417993701064],
            [-57.799326844790528, -30.791763991835722],
            [-57.797781892393061, -30.805699911732191],
            [-57.796946049005925, -30.883472909305453],
            [-57.853617772914077, -31.065555004151079],
            [-57.920835775065115, -31.270972988054538],
            [-58.004445930025952, -31.417708932386745],
            [-58.038754903837599, -31.43347294537088],
            [-58.06805501993577, -31.449717911960576],
            [-58.0788909767532, -31.475899960068702],
            [-58.065281951081488, -31.494163959500924],
            [-58.035690982942384, -31.50726403601044],
            [-58.012917854865208, -31.52360891488631],
            [-57.989863933030904, -31.548335864599068],
            [-57.977781922484041, -31.580899893802808],
            [-57.981535841650754, -31.619446087319517],
            [-58.040208828766595, -31.789172921573893],
            [-58.06360892320285, -31.812499925814393],
            [-58.097917897014582, -31.827081922809441],
            [-58.139026773615257, -31.834308967385553],
            [-58.162226875841441, -31.841117922630076],
            [-58.18459096704251, -31.860281970827856],
            [-58.200145935360894, -31.894790936849844],
            [-58.160426778310494, -31.951108944443007],
            [-58.145972856796249, -31.993055005535183],
            [-58.151117836617715, -32.066390958831107],
            [-58.161235799607113, -32.10106404251772],
            [-58.17833588796114, -32.126255014391532],
            [-58.182290972804594, -32.156846111220275],
            [-58.162781925471165, -32.196673059545859],
            [-58.139999912576769, -32.221672924026748],
            [-58.101581962178926, -32.254645990106582],
            [-58.097554960606118, -32.294590955629147],
            [-58.101599899451855, -32.325626963879643],
            [-58.135281906904225, -32.354173043965957],
            [-58.150281993230863, -32.369726838817854],
            [-58.176946000772773, -32.404026927812183],
            [-58.1992460541166, -32.450308947214538],
            [-58.199664270999961, -32.451854511000022],
            [-58.204403286999934, -32.469341318999952],
            [-58.206512531000016, -32.487902697999985],
            [-58.202323568999986, -32.517491223999968],
            [-58.200233528999888, -32.527418915],
            [-58.184335553999972, -32.602932526999965],
            [-58.177774048999936, -32.631367335999968],
            [-58.170845157000031, -32.661392534999948],
            [-58.161851324999901, -32.728846274000048],
            [-58.152857520999959, -32.805293613999979],
            [-58.130372554000019, -32.863753527999961],
            [-58.107888478666638, -32.904225712000027],
            [-58.103391417999944, -32.967182819999962],
            [-58.116882165999897, -33.025642726999934],
            [-58.130914985999965, -33.060724778999983],
            [-58.13486970699995, -33.070611690333351],
            [-58.145552409999937, -33.078089693000038],
            [-58.179838553999957, -33.102090528],
            [-58.229305553999865, -33.106587528000034],
            [-58.296758554, -33.102090528],
            [-58.35521855399989, -33.097593527999976],
            [-58.395690553999856, -33.115581528],
            [-58.395690553999856, -33.151556528000029],
            [-58.391193554000012, -33.254985528],
            [-58.404684554, -33.317942528],
            [-58.426724238999867, -33.351001442],
            [-58.440660114999901, -33.371905255999948],
            [-58.444863745999896, -33.401330669999936],
            [-58.449653554, -33.434862527999968],
            [-58.463144554, -33.479831527999963],
            [-58.476581450999845, -33.50939210199995],
            [-58.485629275, -33.529297314999965],
            [-58.485629275, -33.655210961999956],
            [-58.476635442999964, -33.736155449000023],
            [-58.471396509000016, -33.759730649999938],
            [-58.458647553999867, -33.817099528999975],
            [-58.449557952999896, -33.846641869999956],
            [-58.440660114999901, -33.87555984399998],
            [-58.428783305999985, -33.901264386000037],
            [-58.421666553666654, -33.916666661999983],
            [-58.419085523999968, -33.922603294999931],
            [-58.405, -33.955],
            [-58.376666666999938, -34.0],
            [-58.344999999999857, -34.038333333000026],
            [-58.337814633999983, -34.078366088999971],
            [-58.333333333000013, -34.103333332999966],
            [-58.323333332999852, -34.123333333],
            [-58.319735320999968, -34.137725381999942],
            [-58.316666667, -34.15],
            [-58.294150637999962, -34.166082877999941],
            [-58.293333333000021, -34.166666667000015],
            [-58.29062869599997, -34.168830375999988],
            [-58.251666666999967, -34.2],
            [-58.20833339099994, -34.221666637999974],
            [-58.166666666999987, -34.253333332999972],
            [-58.151692639999851, -34.261652237000021],
            [-58.091666666999885, -34.295],
            [-58.064999999999884, -34.333333333000041],
            [-58.02, -34.361666667],
            [-58.015938206000015, -34.369113289000012],
            [-58.01, -34.38],
            [-57.939999999999827, -34.44333333299997],
            [-57.934999999999832, -34.55],
            [-57.945034466999914, -34.62024126700004],
            [-57.95166666700004, -34.666666667000015],
            [-57.786039934, -34.712857388000018],
            [-57.533333332999916, -34.783333332999973],
            [-57.360616080999932, -34.855298854999987],
            [-57.333333400499981, -34.866666638499936],
            [-57.253449206999846, -34.942556587],
            [-56.99999988499988, -35.18333332849997],
            [-56.824585851999956, -35.176110397999963],
            [-56.716666667, -35.171666666999982],
            [-56.209947899999918, -35.446884506499913],
            [-55.962571004999916, -35.581244113999958],
            [-55.866666666999834, -35.633333332999953],
            [-55.66134813, -35.743688445000032],
            [-54.892662928, -36.156843256999963],
            [-54.415796900499942, -36.405341335499941],
            [-54.069623204999971, -36.585734765000055],
            [-53.872030357000028, -36.688701720999987],
            [-53.613186953999872, -36.914136291],
            [-53.160775767000018, -37.356023940999975],
            [-52.931050641999946, -37.58040542599997],
            [-52.835574823999963, -37.637974273999944],
            [-52.829100816999954, -37.641877891999982],
            [-52.676008685, -37.734187526],
            [-52.668898873999865, -37.739076273000023],
            [-52.646188850999863, -37.754691818000026],
            [-52.631031449000034, -37.765114135999951],
            [-52.639747252999882, -37.784285525],
            [-52.674142382999946, -37.859941472000017],
            [-52.728642721999847, -37.979820859999961],
            [-52.865280548999976, -38.280370532999981],
            [-53.058270579, -38.556199419999984],
            [-53.098304668999987, -38.613417616999982],
            [-53.112340003999975, -38.63336989699998],
            [-53.164525904333317, -38.707449173],
            [-53.176738996999916, -38.724761672],
            [-53.192795547999907, -38.747508394000022],
            [-53.223556758000029, -38.791042204999926],
            [-53.225382968999867, -38.792812789999957],
            [-53.22754004, -38.795863111000017],
            [-53.229671110999931, -38.800709659],
            [-53.230230708999869, -38.801982258999971],
            [-53.230594450499893, -38.80249637049991],
            [-53.231178741499946, -38.803736865499971],
            [-53.231734964999902, -38.804523252999971],
            [-53.232008772999819, -38.805145256999921],
            [-53.233876049999964, -38.807861569999972],
            [-53.234531628999832, -38.808788315],
            [-53.234908715999865, -38.809645613999962],
            [-53.236388086999966, -38.811736584999949],
            [-53.23836355, -38.814528532999944],
            [-53.239586549999842, -38.817308533000016],
            [-53.242175893999956, -38.820967766],
            [-53.244666761999895, -38.824487516],
            [-53.245117081499899, -38.825510551],
            [-53.245909611999963, -38.826741652999985],
            [-53.247075701, -38.829270747999985],
            [-53.247369050666606, -38.829685282999918],
            [-53.249795634999884, -38.835163168],
            [-53.251762075333289, -38.837950694999989],
            [-53.25349754999985, -38.841891533],
            [-53.254519175999917, -38.843334810999963],
            [-53.255760684999871, -38.845088077999989],
            [-53.256827409000039, -38.847510299000035],
            [-53.25812355, -38.850452533000023],
            [-53.258505248499915, -38.850992017499934],
            [-53.25894532449999, -38.851940934499936],
            [-53.26341055, -38.858336532999985],
            [-53.264124053999979, -38.859350281000019],
            [-53.264609360499861, -38.860451566],
            [-53.267707503499878, -38.86502029699993],
            [-53.268453907000037, -38.866714355999946],
            [-53.31774375299986, -38.936127769],
            [-53.366585550000025, -39.004909532999946],
            [-53.583545549999855, -39.308349534],
            [-53.59230765800001, -39.32054118],
            [-53.604583031999852, -39.33762012699998],
            [-53.649740380999987, -39.438177219000011],
            [-53.655525549999822, -39.451059534],
            [-53.67877955, -39.502724533999981],
            [-53.684124549999922, -39.510126533999937],
            [-53.686044184999957, -39.51438730699995],
            [-53.689580960999933, -39.522236179999936],
            [-53.689957892999843, -39.522814741999937],
            [-53.690478549999852, -39.523968534],
            [-54.060331549999887, -40.031798535000021],
            [-54.474149550999982, -40.416042534999988],
            [-54.505764051, -40.445142046999919],
            [-54.544908918000033, -40.481172315],
            [-54.572230991, -40.498874781000012],
            [-54.634887686499894, -40.556287158499956],
            [-54.636836282999866, -40.557597462749953],
            [-54.637660810999961, -40.558307382],
            [-54.641650820249993, -40.560888581249984],
            [-54.646182985999872, -40.563824313999945],
            [-54.649926922999953, -40.566242573000025],
            [-54.651010628, -40.567235117999985],
            [-54.65172755099988, -40.567891534999944],
            [-54.654354551000011, -40.569587535000025],
            [-54.663673550999846, -40.578120535000039],
            [-54.664604946, -40.578721903999963],
            [-54.667667834999861, -40.580705915499948],
            [-54.673321551000015, -40.584361535000028],
            [-54.683666407666578, -40.591069798333294],
            [-54.751828271, -40.634878980999915],
            [-54.949332550999884, -40.761731534999967],
            [-54.958306550999879, -40.767469535],
            [-55.064419762, -40.835128693999948],
            [-55.118974140999853, -40.869913097999969],
            [-55.148953404999929, -40.888940727000033],
            [-55.236966550999909, -40.944801534999982],
            [-55.269743928999873, -40.965553601999986],
            [-55.270980649999984, -40.966336578000025],
            [-55.272924050999933, -40.967207035000015],
            [-55.275839001999969, -40.968501161999981],
            [-55.27675030249992, -40.968898387999985],
            [-55.293403550999926, -40.979430534999935],
            [-55.294725550999885, -40.980006534999987],
            [-55.296274236499926, -40.980985854999929],
            [-55.304619008499884, -40.98466136049997],
            [-55.30778847, -40.986047443999951],
            [-55.309765703999886, -40.987297047999959],
            [-55.31243278599996, -40.988457921],
            [-55.315254551999828, -40.989685534999964],
            [-55.316947551999931, -40.990755534999948],
            [-55.320182551999892, -40.992163535],
            [-55.320988528999976, -40.992515502400025],
            [-55.34059634, -41.001046017999968],
            [-55.361308346999863, -41.010052402999989],
            [-55.426641552000035, -41.051232534999926],
            [-55.429174273999877, -41.052827353999987],
            [-55.430681442999884, -41.053480565000029],
            [-55.437285551999906, -41.056342534999956],
            [-55.439804973, -41.057928446],
            [-55.443487784999888, -41.060246269999922],
            [-55.444272947999934, -41.060586434],
            [-55.445504764999981, -41.061361567],
            [-55.446536552, -41.06201053499997],
            [-55.902274398499998, -41.25792047900002],
            [-56.118195551999889, -41.34968553600001],
            [-56.121211617666631, -41.350498697666637],
            [-56.121865533499943, -41.350686247999946],
            [-56.126659493999966, -41.352687431999968],
            [-56.131657542999875, -41.354803167999961],
            [-56.13319300899991, -41.355209903999949],
            [-56.135205552, -41.355742535999966],
            [-56.152561551999952, -41.363085536000014],
            [-56.235744551999858, -41.398217535999933],
            [-56.272574369000012, -41.413737989999973],
            [-56.283162001999926, -41.418199656000013],
            [-56.31579429999988, -41.431932219],
            [-56.316827840999963, -41.432203764],
            [-56.318316551999942, -41.432594536],
            [-56.320745808999931, -41.43361648],
            [-56.323998611999968, -41.43452468800001],
            [-56.325099180999985, -41.434828449],
            [-56.326822904999972, -41.435281161999981],
            [-56.340203943, -41.44090508299999],
            [-56.347907551999867, -41.444142535999987],
            [-56.35011082199992, -41.444720944999972],
            [-56.35323628499998, -41.445963611000025],
            [-56.361370332999911, -41.449358047000032],
            [-56.367266853499956, -41.450922385499936],
            [-56.369154615999975, -41.451467496999953],
            [-56.369914551999983, -41.451666536],
            [-56.374885918999894, -41.453753572000011],
            [-56.377039920499897, -41.454637309500001],
            [-56.378728884999987, -41.455325669499928],
            [-56.381491635999879, -41.456091558000011],
            [-56.390527452000015, -41.459858611333289],
            [-56.391223866999894, -41.460134616999966],
            [-56.392545518499958, -41.460675269999939],
            [-56.393330091, -41.460990585],
            [-56.394510552999975, -41.461485536000012],
            [-56.396461440999929, -41.461996632999927],
            [-56.399687885999981, -41.463293306000011],
            [-56.403835552999965, -41.465032536000038],
            [-56.406562552999901, -41.46574653600004],
            [-56.412045656, -41.468045292],
            [-56.41746984, -41.470292355999931],
            [-56.419805562, -41.470903295999946],
            [-56.421204050999876, -41.471489413999947],
            [-56.42261455299996, -41.472060535999958],
            [-56.424573552999959, -41.472861535999968],
            [-56.427560052999894, -41.473661536],
            [-56.430679552999891, -41.474513535999961],
            [-56.431975912999945, -41.474870109999983],
            [-56.433216293499953, -41.475225837499977],
            [-56.434027235999935, -41.475544476],
            [-56.434626874999907, -41.475777401],
            [-56.43597955300001, -41.476343535999945],
            [-56.438454552999985, -41.476990536000017],
            [-56.441389052999938, -41.478185035999985],
            [-56.445307553, -41.479791535999951],
            [-56.447531552999976, -41.480372535999976],
            [-56.448523569666548, -41.480770646333298],
            [-56.449691252999969, -41.481238188],
            [-56.450600531333208, -41.481602589000047],
            [-56.452550673999951, -41.482405494999966],
            [-56.455057027999914, -41.483091947],
            [-56.455687552999962, -41.483355535999962],
            [-56.458361507999882, -41.484099515499921],
            [-56.460968062499944, -41.485171268],
            [-56.4629305549999, -41.485973335000025],
            [-56.4660442433333, -41.486809939666628],
            [-56.468123618999982, -41.487654434000035],
            [-56.470368035249891, -41.488244222499929],
            [-56.471228445999913, -41.488604242999926],
            [-56.473853491999961, -41.489289218000025],
            [-56.520623882999956, -41.508841247999953],
            [-56.538168593999984, -41.516166261000023],
            [-56.548859552999829, -41.520629536],
            [-56.551863427333274, -41.5214369396666],
            [-56.554167030999963, -41.522052336999963],
            [-56.555072604, -41.52243016499996],
            [-56.556490318999977, -41.522798711999982],
            [-56.558140602333253, -41.523247363666641],
            [-56.560785039999871, -41.523946178999978],
            [-56.564338595, -41.525428400999957],
            [-56.565655052999858, -41.525946035999979],
            [-56.568464717999888, -41.527086800999953],
            [-56.56959549100003, -41.527558382999985],
            [-56.570795227, -41.527870068000013],
            [-56.571820186999872, -41.528140910499999],
            [-56.572700611999892, -41.528508049000038],
            [-56.580639905, -41.530570108999953],
            [-56.582178431000017, -41.531191883499929],
            [-56.583838887999917, -41.531864326999923],
            [-56.586555552999982, -41.532616536],
            [-56.588126928999969, -41.533271936999952],
            [-56.589330004999852, -41.53377333899995],
            [-56.591602053, -41.534363097999986],
            [-56.607008295666589, -41.540769399999988],
            [-56.608985553, -41.541582536000035],
            [-56.619818784499955, -41.544403201499961],
            [-56.621447108999888, -41.545081104999952],
            [-56.629287659, -41.547113029999949],
            [-56.632280552999902, -41.547888535999988],
            [-56.633941552999943, -41.548579535999963],
            [-56.636122553, -41.549144535999986],
            [-56.636856347499844, -41.549450175500013],
            [-56.649904921000029, -41.552859227999946],
            [-56.658940474666622, -41.556600122333364],
            [-56.660099310999925, -41.557065299999977],
            [-56.660921360999907, -41.557407089],
            [-56.666119859, -41.558752153000015],
            [-56.669220634999846, -41.560041054999985],
            [-56.67236142, -41.560853407999957],
            [-56.674493552999962, -41.561404536],
            [-56.680124034999977, -41.563744387999975],
            [-56.683601131999893, -41.565189167999975],
            [-56.684524362999895, -41.565572708],
            [-56.685795552999934, -41.566100535999965],
            [-56.68796555299997, -41.566661536000026],
            [-56.688901173000033, -41.567050376999958],
            [-56.691353754, -41.567683968999972],
            [-56.709353552999914, -41.572333536000016],
            [-56.714937552999913, -41.574651535999962],
            [-56.740766549999876, -41.585366543000035],
            [-56.761719553, -41.59076653599999],
            [-56.769885891749936, -41.594141622749973],
            [-56.770749406, -41.594477950999945],
            [-56.771915843999864, -41.594961140999956],
            [-56.773226366499983, -41.595298526499931],
            [-56.777849315, -41.597186047],
            [-56.791571538999932, -41.602868163000039],
            [-56.793441340999919, -41.603349188999957],
            [-56.794575552999987, -41.603662036],
            [-56.799959552999951, -41.605068536000019],
            [-56.805066552999989, -41.60718153599997],
            [-56.806940201999936, -41.607663685999981],
            [-56.808051235333181, -41.607968140999944],
            [-56.859195553, -41.621126535999949],
            [-57.1049935529999, -41.683898035999974],
            [-57.118237907, -41.689323811000023],
            [-57.125345159, -41.692235291000046],
            [-57.135519928999969, -41.694813618999945],
            [-57.156288553, -41.700117036],
            [-57.157670079000013, -41.700508157999934],
            [-57.159510445333297, -41.70100924],
            [-57.162677429499922, -41.701868098749998],
            [-57.164857047999931, -41.702754078999959],
            [-57.188597643000037, -41.708760465999987],
            [-57.189498966999906, -41.709006278499942],
            [-57.20014127699983, -41.711749491000035],
            [-57.211066553, -41.714510536000049],
            [-57.246894552999834, -41.729132536],
            [-57.249090577, -41.729686800999943],
            [-57.249787980999969, -41.729890425666667],
            [-57.26577205399991, -41.733952404],
            [-57.280560779999888, -41.737679291],
            [-57.289262275, -41.739870030999981],
            [-57.294134553, -41.741096535999944],
            [-57.296517859999909, -41.742067218999964],
            [-57.301053945999882, -41.743914292999932],
            [-57.318576568999845, -41.751046403000032],
            [-57.334630364999867, -41.755076793],
            [-57.382823553, -41.767175535999982],
            [-57.644466064999961, -41.832076699000012],
            [-57.866728622000039, -41.887209205],
            [-57.968219504999865, -41.912059525],
            [-57.974853553999878, -41.913679536000018],
            [-58.071442476999948, -42.091916394],
            [-58.456565731000012, -42.546251542999968],
            [-58.463540696, -42.554404215000034],
            [-58.489008554, -42.584171536999989],
            [-58.498862491999972, -42.592166999000028],
            [-58.506416008000031, -42.598295407000023],
            [-58.814469559999907, -42.955417331999882],
            [-59.030015341499933, -43.127054470999951],
            [-59.107076554999907, -43.188417536999964],
            [-59.111457555000015, -43.202645537],
            [-59.115192554999965, -43.214772536999931],
            [-59.118144276999942, -43.22435],
            [-59.123263797999869, -43.240960377],
            [-59.126854917, -43.297215032999958],
            [-59.127152753999951, -43.30187756],
            [-59.176832941999919, -43.46137258499995],
            [-59.303592555000023, -43.86832453800001],
            [-59.618052555999981, -44.40336153799997],
            [-60.064076556, -44.889665538999964],
            [-60.331228167999967, -45.08879534099998],
            [-60.567134476999968, -45.264635196999961],
            [-60.582179826999862, -45.275741466999989],
            [-60.632020665999988, -45.419656522999944],
            [-60.672506556999963, -45.536558538999927],
            [-60.679936557000019, -45.557906538999944],
            [-60.682221353999864, -45.561549988999957],
            [-60.688906993999979, -45.572210162000033],
            [-60.700084923, -45.692796976999986],
            [-60.702644652999851, -45.72034739],
            [-60.708004766999977, -45.735667655],
            [-60.721096556999981, -45.773085539999954],
            [-60.725731712999874, -45.822666867999928],
            [-60.730434017999926, -45.872961685999954],
            [-60.731761944000027, -45.887135995],
            [-60.733182556999878, -45.902294540000028],
            [-60.751724533999976, -45.955044475499932],
            [-60.76117355699995, -46.05526654],
            [-60.764329556999854, -46.088668539999944],
            [-60.782505556999979, -46.140128540000021],
            [-60.791769556999981, -46.23756154],
            [-60.794851399999885, -46.269909240999951],
            [-60.79534062699986, -46.27504371199997],
            [-60.797061078999832, -46.279891249999949],
            [-60.813145557000013, -46.325210539999972],
            [-60.814071203999958, -46.33486531299998],
            [-60.822219024999981, -46.419844961999971],
            [-60.826214034000031, -46.461420038999989],
            [-60.84364271000004, -46.510290514000033],
            [-60.846736180999926, -46.542284938999956],
            [-60.852521556999989, -46.60211754],
            [-60.854550808999932, -46.623061112999949],
            [-60.85694789899992, -46.647797523999941],
            [-60.865008910999876, -46.670291593000016],
            [-60.873995556999944, -46.695367540999939],
            [-60.877170436, -46.72793291499994],
            [-60.882674041999906, -46.784379094],
            [-60.886434992999966, -46.822870408999961],
            [-60.886561556999965, -46.824165541000013],
            [-60.887608558599965, -46.83436531],
            [-60.869214126666662, -46.877905809666586],
            [-60.800429206999894, -47.30679267299999],
            [-60.77662961599998, -47.455187750999983],
            [-60.789189804999864, -47.584928146999957],
            [-60.790995847, -47.603537364000033],
            [-60.786147254999833, -47.633332322999962],
            [-60.778887183999899, -47.677948413999985],
            [-60.778739764499903, -47.678853138499917],
            [-60.778554127999968, -47.679992661000014],
            [-60.775937981999959, -47.680108424999915],
            [-61.097703613, -47.894144621999956],
            [-61.219604020999924, -47.975231900999944],
            [-61.478960127999954, -48.147753728999916],
            [-61.527924189999908, -48.179587679999898],
            [-61.575090597000013, -48.209770807999959],
            [-62.28063334266659, -48.645667025333253],
            [-62.984687137999941, -49.080643343999967],
            [-63.01324611199982, -49.099761899],
            [-63.206292382999891, -49.22899503599993],
            [-64.17039006633334, -49.997722989333326],
            [-64.570665135499922, -50.316944510249961],
            [-64.607716539499933, -50.403875676499965],
            [-65.003932420999831, -51.365962804999931],
            [-64.946723078999952, -51.48061103899996],
            [-64.937773529999845, -51.517069676999952],
            [-64.935658712999924, -51.525685008999936],
            [-64.925980979999878, -51.565110118999968],
            [-64.911899903999824, -51.628037804999948],
            [-64.902811769999971, -51.673179042999983],
            [-64.763945686999904, -52.386938020999906],
            [-64.725858247999867, -52.430333673999932],
            [-64.713571430999963, -52.444332893999956],
            [-64.689222567999877, -52.47207523799996],
            [-64.577494532999907, -52.531204148999954],
            [-64.520120804499953, -52.561567572499982],
            [-64.132291489999943, -52.709964561499959],
            [-63.508785207999836, -52.939943381999932],
            [-63.492637669999965, -52.945935048],
            [-63.489186834999913, -52.947215506999981],
            [-63.325716742999958, -53.018867410999981],
            [-63.220977238999893, -53.064776633999969],
            [-63.216052739999952, -53.066952633999975],
            [-63.202128301999977, -53.073105456999961],
            [-63.172670794999988, -53.086121912999943],
            [-63.120517245999906, -53.109845486999966],
            [-63.108788518999887, -53.115180642999974],
            [-63.079175129999982, -53.129010924999967],
            [-63.012259829999834, -53.160262242999956],
            [-62.057689118999917, -53.548153380999977],
            [-62.041876896999895, -53.555030926999969],
            [-62.036858238999883, -53.557213798999925],
            [-62.033754146999911, -53.558600864999939],
            [-61.997112947999824, -53.574974014999924],
            [-61.768215469000012, -53.677890671999961],
            [-61.680070788999899, -53.717522194999937],
            [-61.611876053999964, -53.747659020999905],
            [-61.517477951999979, -53.789375718],
            [-61.106445677999886, -54.151140743],
            [-60.937502805999912, -54.299833742999951],
            [-60.397850252999888, -54.774802332999975],
            [-59.983315555999923, -55.117779574999936],
            [-59.505580912999932, -55.513047114999928],
            [-58.579838885999834, -56.245473435999941],
            [-58.668094554999897, -56.36840155],
            [-58.683073894999836, -56.381809732999969],
            [-58.690699520999914, -56.388635313999927],
            [-58.693009611999912, -56.391838065999977],
            [-58.706266819999826, -56.410207786999955],
            [-58.707996554999966, -56.411753549999958],
            [-58.708475869000011, -56.412417583],
            [-58.709266889999981, -56.413480293],
            [-59.268538554999878, -56.906069550000026],
            [-59.989403555999871, -57.333940549999973],
            [-60.232678583999927, -57.432133847],
            [-60.851084974999964, -57.681741219999971],
            [-61.057787060999971, -57.736559848000027],
            [-61.156747556999875, -57.762804550999924],
            [-61.198865556999976, -57.779333551000015],
            [-61.380748418499962, -57.827008523499948],
            [-62.1779305579999, -58.031844550999978],
            [-62.574562154999967, -58.088091692000013],
            [-63.233750294, -58.181572171],
            [-63.238012944, -58.181749234],
            [-63.24072255899992, -58.181861550999969],
            [-63.252132558999847, -58.18233455099994],
            [-63.270777558999868, -58.184895551],
            [-63.354136558999869, -58.188313551000014],
            [-63.371265558999966, -58.189009550999963],
            [-63.427649874999986, -58.196682365999941],
            [-63.476798437999832, -58.203370268999969],
            [-63.544295019999936, -58.20604234799999],
            [-63.564135496999825, -58.206820950999926],
            [-63.574295559, -58.207219551000044],
            [-63.59593751299991, -58.210132466999923],
            [-63.653822482999828, -58.217923290999984],
            [-63.682848297999897, -58.221817361000042],
            [-63.697905151000043, -58.222394109999975],
            [-63.712717595999948, -58.224376894999985],
            [-63.715240558999966, -58.224714551000034],
            [-64.616083559999851, -58.256194551000029],
            [-64.629122816999853, -58.256608108999963],
            [-64.640676924999894, -58.256974154999966],
            [-64.817994980999885, -58.279279493999972],
            [-64.889622560000021, -58.288289550999927],
            [-65.001076560999962, -58.291569550999981],
            [-65.04398812799991, -58.292804728999947],
            [-65.07410217599994, -58.293671411999981],
            [-65.18435466599999, -58.307232170999967],
            [-65.204402791999826, -58.309685677999937],
            [-65.231820560999978, -58.313040550999965],
            [-65.817248561000014, -58.383242551],
            [-66.917903561999964, -58.404597550999974],
            [-67.241583740999857, -58.378193658000043],
            [-67.266666562999916, -58.376147320499975],
            [-67.26667, -56.38],
            [-65.72667, -56.379999582999943],
            [-65.72666656099986, -55.38166633649989],
            [-66.078332562000014, -55.183332123999854],
            [-66.416666561999932, -55.121666123999901],
            [-66.529382561999881, -55.113833123999903],
            [-66.612379561999859, -55.101976123999933],
            [-66.683519561999873, -55.072335123999864],
            [-66.754659561999887, -55.036764123999859],
            [-66.831727561999941, -54.989338123999879],
            [-66.926581561999882, -54.971553123999875],
            [-67.06293356199987, -54.947839124000019],
            [-67.199285562999876, -54.930054124000016],
            [-67.288210562999893, -54.912269124000012],
            [-67.412705562999918, -54.906341123999894],
            [-67.495702562999895, -54.900413123999861],
            [-67.62019756299992, -54.900413123999861],
            [-67.756549563000021, -54.900413123999861],
            [-67.910686563000013, -54.888556123999891],
            [-68.070751563000044, -54.882628123999858],
            [-68.183389564, -54.870771123999887],
            [-68.307885563999832, -54.870771123999887],
            [-68.408667563999927, -54.894484124],
            [-68.539090563999878, -54.906341123999894],
            [-68.571190563999949, -54.907498123999879],
            [-68.601844563999975, -54.909811123999944],
            [-68.625557564000019, -54.910390123999889],
            [-68.640595564000023, -54.909811123999944],
            [-68.641752563999916, -54.899400123999889],
            [-68.642330563999963, -54.893038123999915],
            [-68.643108839201403, -54.888608993066704],
            [-68.637217870196508, -54.884999913254184],
            [-68.636127108593513, -54.804772868381072],
            [-68.635836095998087, -54.788336149259656],
            [-68.632126869831694, -54.546099953270769],
            [-68.630572864978632, -54.355282071273258],
            [-68.631672905954446, -54.334726962420646],
            [-68.629181971961771, -53.998063964762913],
            [-68.626954900281902, -53.840282013119115],
            [-68.624454913833631, -53.510281957952188],
            [-68.623335762118614, -53.338335931006952],
            [-68.623335762118614, -53.324717852879743],
            [-68.623899864203821, -53.276390984158276],
            [-68.623064020816969, -53.138891058960979],
            [-68.620564034368812, -52.795008895716144],
            [-68.619735902332792, -52.719172955972141],
            [-68.618617924084049, -52.650555008348931],
            [-68.617581920924266, -52.641508923070212],
            [-68.441754905042131, -52.37778206199809],
            [-68.43583577268322, -52.328754969081459],
            [-68.575563943027049, -52.305281952087249],
            [-68.64861792909926, -52.29722694311311],
            [-68.727508904921166, -52.28999989853699],
            [-68.828608906734843, -52.279726870338472],
            [-68.880008916445007, -52.255835931650743],
            [-68.935835912148804, -52.230563990591975],
            [-68.991390831274799, -52.206118002274515],
            [-69.032790895192647, -52.194726995827182],
            [-69.233608890620644, -52.143335870934415],
            [-69.287508886778909, -52.143890920564139],
            [-69.461399850542477, -52.143618005796021],
            [-69.765835771505152, -52.060564073931303],
            [-69.887790954306041, -52.026117972183364],
            [-69.94307295866372, -52.010835918633106],
            [-69.998335852282196, -51.996391049574441],
            [-70.066681890965782, -51.996391049574441],
            [-70.11029092165262, -51.996391049574441],
            [-70.434717849382565, -51.997509027823085],
            [-70.662226842080884, -51.997509027823085],
            [-71.233335863162353, -51.997509027823085],
            [-71.694455035897562, -51.996944925737779],
            [-71.910573022148526, -51.995835832306547],
            [-71.942363904601109, -51.91410003853899],
            [-71.942363904601109, -51.880981965533849],
            [-71.959590894969637, -51.854591040398034],
            [-72.087508963706313, -51.764455070882597],
            [-72.13556392348778, -51.740008914926975],
            [-72.16111783177027, -51.728335941256113],
            [-72.181672940622775, -51.719999970886754],
            [-72.208755037496388, -51.712226929136321],
            [-72.258335838936404, -51.706944821378464],
            [-72.289245951000993, -51.696255044952821],
            [-72.400554943272823, -51.513617900477385],
            [-72.36944601246438, -51.498890896556937],
            [-72.347581985608258, -51.484791026633935],
            [-72.309726796191285, -51.399173070580694],
            [-72.313335876070482, -51.359455086996789],
            [-72.311672906475934, -51.311390907121634],
            [-72.292508858278154, -51.284454990639816],
            [-72.262790988124635, -51.265144929688333],
            [-72.249626873874689, -51.237854961615447],
            [-72.290555036642559, -51.204381998828829],
            [-72.326672992802486, -51.191944930862398],
            [-72.35196404460055, -51.179999880613735],
            [-72.376045923043137, -51.158963985793548],
            [-72.395217850229926, -51.108126904702203],
            [-72.334108914406357, -51.034308991972402],
            [-72.281881946126475, -51.030491035065417],
            [-72.265835798280648, -51.010700020508281],
            [-72.242645921976532, -50.854590929104951],
            [-72.254172882893585, -50.831672961740551],
            [-72.280426847731292, -50.800699985402098],
            [-72.302781886476765, -50.780555086891979],
            [-72.334308906616627, -50.756117983391846],
            [-72.338617875413064, -50.731673000902703],
            [-72.30041785449859, -50.677435884597713],
            [-72.293890866477682, -50.652917979550708],
            [-72.370281855851317, -50.614444876229847],
            [-72.491255014873389, -50.603064095704489],
            [-72.518335770642523, -50.605835823454292],
            [-72.542917881068064, -50.613891000066424],
            [-72.564726922639124, -50.631390905203155],
            [-72.586946006914786, -50.659726934795223],
            [-72.606946066137596, -50.669173004424209],
            [-72.639454942418297, -50.671944899812097],
            [-72.671399884251684, -50.663890896666352],
            [-72.690981854143075, -50.652644897144505],
            [-72.70889984861131, -50.637499971530545],
            [-72.73709087127807, -50.620763993051476],
            [-72.766117904969946, -50.620699955311032],
            [-72.873063948988602, -50.654444827037373],
            [-72.906108931797803, -50.667499976545749],
            [-72.925845966898208, -50.679172950216611],
            [-72.957163942372404, -50.706317911364188],
            [-72.983899866643895, -50.729726890617954],
            [-73.018064001168312, -50.749455040900948],
            [-73.05222679458825, -50.761673005917821],
            [-73.0836178602583, -50.768617915632333],
            [-73.109172942006978, -50.772508962735252],
            [-73.137090882267529, -50.769863969361865],
            [-73.158482002145263, -50.758891052246128],
            [-73.176526898627912, -50.739726836410362],
            [-73.228899879661526, -50.598063955170247],
            [-73.249590942983957, -50.500282013556863],
            [-73.266363969475236, -50.355582033511681],
            [-73.277508883244508, -50.328472946909841],
            [-73.317508834052205, -50.267226883149895],
            [-73.332090998685345, -50.249591023543353],
            [-73.349026801736585, -50.236255080277836],
            [-73.436399928319531, -50.191390943405978],
            [-73.485290899126312, -50.166944955088432],
            [-73.522508896262138, -50.148408879078332],
            [-73.536255049870249, -50.120135881398227],
            [-73.525008882710239, -50.087644942390426],
            [-73.501672993652392, -50.056526959126586],
            [-73.474246065281221, -50.032017938896985],
            [-73.469999960758742, -50.00167293474621],
            [-73.490008904798856, -49.983755107916053],
            [-73.514726802056117, -49.968609008835699],
            [-73.554245966896161, -49.940664078846822],
            [-73.52902683182765, -49.852708912905726],
            [-73.501808948122203, -49.831117968455729],
            [-73.46611779847305, -49.809308926884569],
            [-73.446463911852391, -49.79090897298245],
            [-73.515426858611335, -49.605135823244169],
            [-73.568890995803343, -49.559172986500954],
            [-73.582299861626723, -49.540835896872807],
            [-73.540008969036876, -49.443199968013133],
            [-73.464735790273608, -49.311391019811708],
            [-73.423063985054114, -49.309717991933169],
            [-73.168064026244252, -49.30472690385443],
            [-73.136126795761612, -49.304173027691007],
            [-73.086735760610054, -49.267364067431274],
            [-73.167690863913691, -49.252635890044445],
            [-73.174726801096881, -49.242781957005747],
            [-73.176817918306938, -49.221808926459616],
            [-73.077373007098913, -49.067917854280836],
            [-73.006390859859351, -48.998335820151624],
            [-72.982508805989141, -48.978481941320609],
            [-72.951672957586879, -48.964726902895094],
            [-72.915846021105892, -48.954444822241086],
            [-72.895835903599391, -48.950699955529871],
            [-72.856535841708904, -48.952091016184795],
            [-72.810835861449902, -48.94583593710361],
            [-72.781399958520012, -48.93805501636416],
            [-72.755281948152515, -48.928063955389078],
            [-72.73695491680806, -48.91972697919141],
            [-72.708890963793806, -48.90361796707154],
            [-72.586946006914786, -48.824444856388027],
            [-72.561117842759813, -48.799444824269074],
            [-72.552081983473329, -48.776673037296462],
            [-72.552226822760645, -48.73111789632479],
            [-72.55611786986367, -48.694172981595159],
            [-72.561254970696126, -48.65167304433956],
            [-72.573626827455712, -48.587226937576418],
            [-72.579726841327783, -48.564726891905536],
            [-72.590563971611601, -48.533681998837537],
            [-72.603763960407207, -48.494035931983078],
            [-72.595563944507859, -48.473064074903434],
            [-72.57792691143473, -48.456808882391755],
            [-72.556817926418802, -48.44694489106935],
            [-72.490845977997282, -48.422782043441799],
            [-72.426117904010511, -48.405281970667076],
            [-72.291881891918791, -48.34778194708484],
            [-72.300281900028693, -48.190417917134639],
            [-72.321954987129942, -48.078335945749743],
            [-72.406955029279231, -48.008264073197637],
            [-72.450008842698253, -48.001390912574507],
            [-72.478335819834911, -47.988481943458012],
            [-72.515017878080329, -47.961108994543366],
            [-72.531399972606408, -47.941390902544185],
            [-72.535072922588171, -47.915490989297737],
            [-72.455835774164029, -47.763200022439563],
            [-72.428690980654636, -47.721044916681535],
            [-72.404172907969439, -47.691944960431883],
            [-72.374026890200724, -47.659726936192214],
            [-72.353055033120881, -47.63667301435791],
            [-72.339726801206297, -47.617499913704734],
            [-72.32959995339948, -47.595835879058882],
            [-72.308263986444871, -47.505491032515764],
            [-72.341672911491145, -47.48999993429976],
            [-72.359308938735666, -47.478126968418636],
            [-72.350146009796703, -47.449999983492233],
            [-72.33541783240986, -47.435417986497185],
            [-72.313608958476777, -47.42611792962191],
            [-72.293063907907964, -47.420008863294441],
            [-72.256390902118142, -47.41111801093335],
            [-72.229864022512515, -47.410699921601847],
            [-72.194245963059302, -47.418608917822183],
            [-72.15903576701561, -47.411108958477939],
            [-72.081399897378731, -47.352781976326071],
            [-72.017372885775501, -47.277499912745498],
            [-71.88278198390239, -47.232781956265342],
            [-71.86791785204565, -47.218617880963805],
            [-71.852363889555676, -47.172855036430946],
            [-71.862508842273286, -47.139726905141913],
            [-71.892781929694706, -47.115564057514462],
            [-71.976390911189327, -47.034444836546299],
            [-71.939246004249242, -46.816255011462459],
            [-71.917364040120333, -46.800144825876188],
            [-71.850146037969182, -46.796673041571168],
            [-71.813754999402988, -46.786044955953102],
            [-71.686399859285416, -46.70278197942271],
            [-71.672855038992054, -46.68396410382708],
            [-71.666399967700613, -46.65249994796109],
            [-71.670563929571642, -46.538335911821719],
            [-71.689863932239319, -46.490699879561888],
            [-71.725008917075996, -46.430555030244321],
            [-71.740846020256072, -46.271390902763017],
            [-71.746608913981504, -46.247018004641404],
            [-71.766954978168258, -46.221526960632985],
            [-71.800563895424801, -46.209726917309638],
            [-71.834872869236364, -46.200973025246853],
            [-71.901808904163772, -46.149099940920038],
            [-71.85111783582613, -46.132508969366285],
            [-71.824172866888915, -46.130564032548023],
            [-71.793063936080557, -46.125555007196454],
            [-71.76597295438944, -46.117090961346207],
            [-71.740699839864277, -46.096672980429886],
            [-71.720835902749371, -46.068190938084015],
            [-71.699717865277933, -46.044444838683603],
            [-71.665845923899099, -46.01249989685013],
            [-71.645008847822965, -45.999454973263823],
            [-71.638626866727378, -45.996944928531761],
            [-71.604445968396618, -45.976944869308952],
            [-71.629246008305131, -45.903544878272669],
            [-71.649735905950905, -45.883609024428395],
            [-71.694455035897562, -45.868899957780769],
            [-71.724455040912659, -45.861808867674554],
            [-71.753754989372652, -45.849099890768315],
            [-71.792226919227033, -45.729035832967227],
            [-71.781954896856831, -45.655008875571582],
            [-71.750490908629018, -45.55854490441719],
            [-71.712781899603726, -45.53889101779653],
            [-71.672499981572599, -45.527781978572889],
            [-71.649735905950905, -45.523890931469879],
            [-71.629308872579287, -45.521526899491704],
            [-71.606254950744983, -45.522635825284937],
            [-71.467508804179886, -45.477499947111269],
            [-71.298617813312546, -45.305072966560473],
            [-71.329445950363862, -45.231255053830665],
            [-71.498064026463084, -45.075564051758846],
            [-71.549035888557853, -44.999173062385111],
            [-71.582645979280755, -44.975417910529202],
            [-71.740846020256072, -44.937226942070041],
            [-71.80667296175227, -44.930835908519043],
            [-71.934446023563368, -44.922226855743403],
            [-72.066817900383597, -44.901673087995349],
            [-72.081808934254923, -44.787090962524275],
            [-72.075426785521159, -44.766391014384439],
            [-72.047372890790797, -44.754035921431182],
            [-72.019454950530047, -44.759173022263724],
            [-71.994172951187579, -44.769172968056125],
            [-71.974164007147351, -44.776117877770545],
            [-71.951535885995668, -44.783055076134033],
            [-71.841117890033729, -44.792364017826728],
            [-71.813481916996778, -44.78500001295231],
            [-71.795008872898734, -44.763200023836546],
            [-71.77132681123885, -44.75534483943477],
            [-71.714446042664861, -44.764163942704471],
            [-71.693617851405946, -44.77028206148735],
            [-71.673617792183251, -44.777226971201785],
            [-71.653626785415753, -44.782781993727838],
            [-71.629181970564787, -44.780008924873471],
            [-71.608472969969341, -44.77458197782839],
            [-71.555699836877153, -44.754309003837463],
            [-71.51833582698751, -44.743890968713458],
            [-71.491672992911901, -44.743755014243646],
            [-71.471399851282996, -44.747081959261266],
            [-71.44279090692271, -44.761391041488181],
            [-71.395846046400578, -44.786944949770572],
            [-71.369726862566495, -44.79639101939955],
            [-71.33973591000688, -44.80333592911397],
            [-71.30555501167612, -44.803609011520265],
            [-71.277926917628207, -44.798200001747915],
            [-71.225908826375985, -44.746808876855056],
            [-71.220417841590461, -44.717091006701551],
            [-71.227508931696661, -44.688608964355765],
            [-71.232017892703539, -44.663335849830517],
            [-71.189945935425186, -44.591881969078898],
            [-71.145008875995472, -44.586672951516988],
            [-71.121681871754959, -44.568754957048739],
            [-71.108754965365449, -44.535281994262206],
            [-71.161735802019024, -44.451255091074245],
            [-71.195699944333199, -44.430282060528114],
            [-71.401117928236545, -44.400418009982815],
            [-71.480563953688261, -44.402499907099383],
            [-71.54556393661494, -44.409864079611793],
            [-71.626399849254909, -44.410000034081705],
            [-71.778626778372654, -44.408055097263428],
            [-71.827708856572372, -44.400208965317063],
            [-71.853408945246542, -44.372435864343728],
            [-71.842854955652626, -44.352217875637848],
            [-71.797290929863635, -44.328408911963294],
            [-71.793899947105501, -44.215564019749294],
            [-71.800417882670985, -44.190144892470428],
            [-71.818208807486741, -44.162364080146162],
            [-71.779172943185131, -44.083891026017781],
            [-71.746672951721848, -44.058890993898828],
            [-71.655426882946784, -43.956391046612808],
            [-71.646672990884099, -43.938054962813055],
            [-71.648617927702276, -43.916254973697392],
            [-71.69833585707849, -43.866672998790889],
            [-71.756117847884354, -43.819455055862647],
            [-71.807645933075605, -43.763335867013453],
            [-71.807363965851891, -43.734309000959655],
            [-71.786254980836048, -43.725835902654012],
            [-71.766399928538448, -43.719999918732725],
            [-71.702926793098641, -43.689235819421953],
            [-71.690417808402657, -43.666254987783589],
            [-71.70174595057594, -43.601672926550535],
            [-71.848890931784354, -43.500554987463957],
            [-71.935564001812082, -43.453399908809772],
            [-71.929735896879833, -43.386944827487909],
            [-71.895708890291786, -43.325282015501017],
            [-71.795281955304944, -43.300835859545487],
            [-71.758899969194232, -43.307290930836842],
            [-71.74015501615645, -43.297644868997594],
            [-71.733335834989958, -43.278617948736112],
            [-71.73966400426707, -43.176108948994695],
            [-71.835564040974162, -43.132990930197238],
            [-71.868054979981935, -43.126672986841996],
            [-71.930845996139453, -43.122218005291842],
            [-71.958890838414504, -43.121117964316028],
            [-71.999308878553535, -43.121808968415735],
            [-72.052781900562962, -43.104726984972615],
            [-72.078335808845338, -43.088755100789115],
            [-72.118064018351134, -43.039172958244635],
            [-72.13667301691919, -43.00576403319846],
            [-72.1334729739159, -42.956108968096189],
            [-72.126399821082515, -42.936535883022316],
            [-72.113481967148601, -42.91430891975763],
            [-72.104590947149433, -42.881808928294348],
            [-72.101963891048854, -42.854726831420734],
            [-72.107226888067316, -42.76056402797056],
            [-72.110835800308621, -42.720555024707622],
            [-72.128345931367164, -42.668717982564544],
            [-72.13889992096108, -42.590699898141807],
            [-72.135008873858055, -42.569172991432239],
            [-72.12610880140349, -42.547499904330991],
            [-72.111399902393941, -42.526944795478371],
            [-72.044799981784763, -42.368964025090705],
            [-72.056672947665987, -42.34944491947347],
            [-72.081681864602359, -42.333335907353671],
            [-72.108899915945784, -42.315835834578948],
            [-72.130354905925969, -42.285426960325722],
            [-72.038335858037925, -42.149172921219971],
            [-72.017499955427979, -42.133891035307698],
            [-71.993763914311472, -42.124590978432522],
            [-71.961746049920123, -42.129173029635155],
            [-71.947917921298767, -42.1466729347718],
            [-71.937854943594317, -42.172226843054176],
            [-71.907226798753442, -42.176255018093485],
            [-71.777226832900396, -42.130417909898213],
            [-71.726535764562755, -42.095072932851181],
            [-71.731526852641508, -42.029444810098951],
            [-71.759763975775968, -41.999726939945432],
            [-71.763481852758872, -41.946108911010796],
            [-71.77750880012411, -41.887217994411699],
            [-71.7877819959607, -41.861255049253302],
            [-71.850281992439108, -41.766391015781338],
            [-71.914590971266051, -41.652091025172055],
            [-71.914517881070111, -41.62354494508574],
            [-71.892226880064982, -41.603890890827003],
            [-71.850008910032898, -41.54944489749424],
            [-71.867781897575838, -41.432782048373184],
            [-71.88250890149618, -41.406255001129381],
            [-71.893063896918306, -41.386944940177997],
            [-71.89903583530949, -41.356663973767567],
            [-71.883472988002097, -41.293890894882878],
            [-71.865981967682785, -41.265835826686029],
            [-71.861390864024656, -41.241672979058578],
            [-71.840426885934107, -41.058755040825915],
            [-71.859163959982766, -41.011281952764293],
            [-71.83806402742232, -40.955490999244418],
            [-71.850281992439108, -40.912508934916772],
            [-71.863199846373107, -40.890563938875616],
            [-71.914172881934348, -40.806117940527741],
            [-71.950145998807216, -40.72694600331063],
            [-71.930354984250073, -40.70131799900399],
            [-71.900008806632826, -40.681399914794454],
            [-71.879181956478476, -40.664999882995559],
            [-71.859308966908173, -40.643472976286091],
            [-71.839290970412549, -40.600763994364641],
            [-71.840699968340203, -40.573481905280687],
            [-71.837781892560628, -40.531672972124795],
            [-71.779872999740263, -40.408964088588306],
            [-71.758608949515207, -40.410417845879124],
            [-71.73583582143803, -40.427781964183936],
            [-71.715699975383501, -40.423618002312807],
            [-71.701673028018263, -40.404446075126103],
            [-71.663964018992885, -40.334517868394755],
            [-71.668617819287078, -40.296945987305847],
            [-71.702717916070867, -40.278955070279935],
            [-71.719590854848235, -40.302364049533608],
            [-71.791399960657515, -40.245835823808221],
            [-71.818063968199425, -40.20459099273755],
            [-71.791399960657515, -40.115008899385543],
            [-71.749999896739553, -40.089446106285834],
            [-71.666945964875026, -40.047499877555481],
            [-71.633345932435816, -39.950564005250925],
            [-71.669590958248506, -39.854446039060591],
            [-71.705008857853301, -39.72749993581904],
            [-71.709026806970627, -39.626673016411488],
            [-71.689863932239319, -39.57549093618448],
            [-71.682235897414301, -39.56721799772717],
            [-71.666735914380865, -39.566599916185311],
            [-71.625281871006365, -39.611808884554833],
            [-71.599164028276761, -39.621117993885505],
            [-71.507155038672607, -39.617646041942493],
            [-71.492645964235237, -39.599172997844448],
            [-71.48528195936089, -39.561526853093213],
            [-71.450290866266954, -39.435417934343164],
            [-71.403199825353113, -39.330146091669263],
            [-71.387790869788546, -39.240417985563539],
            [-71.402235906485288, -39.129717854740079],
            [-71.412226799822292, -39.066117984923935],
            [-71.422235798070005, -39.039590937680131],
            [-71.422499828020705, -39.013891016644038],
            [-71.418626885828786, -38.960554954932917],
            [-71.410426869929324, -38.935417962515658],
            [-71.393690891450177, -38.913535830748557],
            [-71.360835842567326, -38.888891023687137],
            [-71.334454975715516, -38.875835874178762],
            [-71.23889088568842, -38.831117917698791],
            [-71.097781880611819, -38.766108882316715],
            [-71.053335833071372, -38.748481907527591],
            [-71.026263962119742, -38.745417986632191],
            [-70.994590929226092, -38.748199940303977],
            [-70.946117880112809, -38.74917307926539],
            [-70.92348187997203, -38.740835935429544],
            [-70.861945969999539, -38.69013581463642],
            [-70.829726772293498, -38.588054962510398],
            [-70.825699938358753, -38.555899970182764],
            [-70.843972990246357, -38.521946053790586],
            [-70.88084598824662, -38.498890958489895],
            [-70.907226855098628, -38.48625507177951],
            [-70.950008927215833, -38.450835831070151],
            [-70.971535833925401, -38.415491021661211],
            [-71.00279094512544, -38.284999876280693],
            [-71.013199927793949, -38.227646033090238],
            [-71.009035798284742, -38.204173016096036],
            [-71.000564041083663, -38.154164067040696],
            [-70.998335795937209, -38.070208912944111],
            [-71.094999926940034, -37.882499878843049],
            [-71.123899890979345, -37.837781922362893],
            [-71.130281872075102, -37.814172950898779],
            [-71.137217896972032, -37.791117855598088],
            [-71.16083575325365, -37.745564055730981],
            [-71.179864014619511, -37.696464040258533],
            [-71.182372885885002, -37.658890985703152],
            [-71.1758460655021, -37.62639099423987],
            [-71.157373021404027, -37.608617839058937],
            [-71.123063879954401, -37.588190973325297],
            [-71.10869092762502, -37.458963986585317],
            [-71.118055022240725, -37.435009010157046],
            [-71.145426797688884, -37.405700009241556],
            [-71.166735774915281, -37.37923582627181],
            [-71.196326910692363, -37.302226922994222],
            [-71.183126921896672, -37.268608953282282],
            [-71.147917899319651, -37.235973007349187],
            [-71.130008957306813, -37.191946054968753],
            [-71.11279085175579, -37.114445972163693],
            [-71.148617788236692, -36.97528190390527],
            [-71.18611792023421, -36.84555502045842],
            [-71.136945988032295, -36.741673064973057],
            [-71.054799816284088, -36.547091037886943],
            [-71.03430891280999, -36.483890984853332],
            [-71.016481945810597, -36.475917950892537],
            [-70.981945990060211, -36.483609017629718],
            [-70.952781996070115, -36.486946020931313],
            [-70.706945972027626, -36.414517995389872],
            [-70.696399861422634, -36.390973061666202],
            [-70.718617939870001, -36.343999870311364],
            [-70.704726779336596, -36.270564004729437],
            [-70.588617806378863, -36.164726886503843],
            [-70.504517812995147, -36.1622909377962],
            [-70.467508860525072, -36.16417301034042],
            [-70.438554917028938, -36.151464033434266],
            [-70.424317919169653, -36.136035967130297],
            [-70.396117844047495, -36.075564055949812],
            [-70.407226883271022, -35.971117998379142],
            [-70.411399897597562, -35.893054986955264],
            [-70.402235795192297, -35.870835902679602],
            [-70.375072896771798, -35.83312689365431],
            [-70.343754921297602, -35.811355067733345],
            [-70.374454982867945, -35.735281920128884],
            [-70.408617943925975, -35.672226874020666],
            [-70.418481935248366, -35.634935954326977],
            [-70.404173020659528, -35.614446056681189],
            [-70.396117844047495, -35.515008856824096],
            [-70.421954893019887, -35.364446070767485],
            [-70.532926932783056, -35.307782058210265],
            [-70.55029088344989, -35.296673018986638],
            [-70.567154937409754, -35.267917894234571],
            [-70.564999950097331, -35.24194606425867],
            [-70.54049092986773, -35.210135903428707],
            [-70.516399831331654, -35.204999976062638],
            [-70.482226811990017, -35.203335833001518],
            [-70.45083591395786, -35.19833586010536],
            [-70.381117925358922, -35.16902685918987],
            [-70.362990886224935, -35.144790921366479],
            [-70.357790921118323, -35.10028200955216],
            [-70.361955050627444, -35.079173024536118],
            [-70.362926848484392, -35.042635805578129],
            [-70.361955050627444, -35.00444601058544],
            [-70.359754968675901, -34.996909003228993],
            [-70.297499891408989, -34.85361785347348],
            [-70.232508960937821, -34.661946023178032],
            [-70.14986389831131, -34.531735839192748],
            [-70.045490930936381, -34.400281948410793],
            [-70.052226795985234, -34.354999889845416],
            [-70.052435840650901, -34.296046108972448],
            [-70.047617922692183, -34.286008947529837],
            [-70.029590963482349, -34.276808970578728],
            [-69.986117887265436, -34.269726932927924],
            [-69.957363935979743, -34.270563949781348],
            [-69.934717877555158, -34.275835831617314],
            [-69.888064036712336, -34.278335818065401],
            [-69.817572901362155, -34.234726955016555],
            [-69.848346053128239, -34.097226862181188],
            [-69.884172989609226, -33.891946006214027],
            [-69.904172881193944, -33.827508951906282],
            [-69.905008892218973, -33.778472974174278],
            [-69.869726779446097, -33.555282002727893],
            [-69.809172893252253, -33.437917923585218],
            [-69.783481857033564, -33.412781936996353],
            [-69.774935836170073, -33.387155106156115],
            [-69.803617870726185, -33.278890924040297],
            [-69.875417924079954, -33.245146052313956],
            [-69.902508905770901, -33.237226830171636],
            [-69.927372983420128, -33.240835910051032],
            [-69.955417825695008, -33.265208975810722],
            [-69.975772942337358, -33.295772915273162],
            [-70.000417917036856, -33.298617900856897],
            [-70.095555032914945, -33.179446012832258],
            [-70.100699845098319, -33.153199926983675],
            [-70.090908943971755, -33.122499865413346],
            [-70.084655038356971, -33.076908849896128],
            [-70.099635846306228, -33.051846121141097],
            [-70.071672979044621, -33.005835842825803],
            [-70.021117865176791, -32.948891036511455],
            [-69.993335879386052, -32.876182049574979],
            [-70.045208963712867, -32.853963971127612],
            [-70.144863925415052, -32.759099937655833],
            [-70.170845981312937, -32.627782001343789],
            [-70.164246070733981, -32.581108882123473],
            [-70.152235809278494, -32.543063926418029],
            [-70.146535779827218, -32.506254966158295],
            [-70.154172867107832, -32.475835865983186],
            [-70.230835765421375, -32.341664059269974],
            [-70.244326773896006, -32.30983596116711],
            [-70.313272956848522, -32.284172920505057],
            [-70.320981960858603, -32.265281954713572],
            [-70.316954959285681, -32.228472994453853],
            [-70.348890848663672, -32.086946067683641],
            [-70.306955013493365, -32.047781960263066],
            [-70.264926809749767, -32.042435814764772],
            [-70.232373006468009, -31.965208982004],
            [-70.237508933834079, -31.938473057732523],
            [-70.284445915367087, -31.891946118904016],
            [-70.304035764247203, -31.882363927167134],
            [-70.339935790924045, -31.884373069363917],
            [-70.373335831152815, -31.886108961516342],
            [-70.411881857031631, -31.878890969395727],
            [-70.430281978571713, -31.868617941197115],
            [-70.446672957915126, -31.848890964380516],
            [-70.458890922932, -31.832499985037103],
            [-70.586735901472736, -31.563817910359504],
            [-70.579181962671754, -31.530281915660922],
            [-70.567508821362821, -31.469172979837367],
            [-70.560281944424787, -31.322426977221355],
            [-70.531254910732912, -31.18125494023262],
            [-70.520845928064489, -31.159172983893242],
            [-70.451381911132273, -31.094582037842869],
            [-70.430281978571713, -31.113608958104251],
            [-70.361955050627444, -31.093055022718033],
            [-70.295008957416314, -31.02972689420352],
            [-70.291399877536918, -30.995835842085398],
            [-70.327917818117527, -30.956873067979728],
            [-70.331681963206137, -30.931390908788728],
            [-70.327090859548008, -30.89125500351129],
            [-70.316399909655871, -30.824726831993502],
            [-70.269181966727729, -30.678335886797029],
            [-70.25249996770512, -30.637781892188102],
            [-70.211672890689982, -30.559445965996005],
            [-70.133899893116734, -30.439726907330581],
            [-70.051535791885527, -30.394999898395014],
            [-70.029726917952246, -30.397782019704792],
            [-69.998345910566201, -30.395146078786809],
            [-69.938472970188172, -30.372635807193944],
            [-69.89889982589159, -30.324999942572376],
            [-69.831681991378645, -30.190555053452883],
            [-69.829172952475062, -30.143964076883918],
            [-69.841672884715535, -30.120555097630145],
            [-69.863481926286795, -30.104026822712285],
            [-69.924945919529819, -29.9999998603016],
            [-69.918808857645615, -29.974064072509435],
            [-69.907508878667244, -29.91819097633811],
            [-69.905146020155456, -29.799582016932391],
            [-69.914454961847952, -29.720417958704203],
            [-69.917235909691328, -29.7133359210534],
            [-69.950008815922843, -29.694309000792003],
            [-69.962781998207504, -29.678199988672304],
            [-69.968617814490699, -29.655417975777624],
            [-69.963890924000651, -29.628608961310306],
            [-69.956954899103749, -29.600417938643645],
            [-69.959863922427843, -29.555699982163489],
            [-69.965008902249394, -29.535555083653279],
            [-70.003063916238744, -29.423891033961979],
            [-70.029026861397227, -29.374863941047394],
            [-70.034726890848503, -29.352081928152799],
            [-70.02897288194049, -29.29111783161656],
            [-69.960282011759432, -29.201117984209105],
            [-69.901599972188109, -29.13896399269467],
            [-69.838135889203699, -29.132708913613484],
            [-69.805981902704445, -29.113608903156162],
            [-69.79278191390884, -29.09569996114341],
            [-69.786945929987581, -29.070835883494354],
            [-69.788890866805843, -29.016946113257902],
            [-69.75750885359119, -28.886673064998647],
            [-69.723346060171338, -28.797708885550406],
            [-69.729446074043494, -28.764026878098036],
            [-69.739581974305622, -28.741526832427155],
            [-69.746399814367663, -28.719172967148069],
            [-69.745981892674138, -28.669864074647847],
            [-69.700981968970353, -28.590900008630101],
            [-69.669308936076703, -28.575626839897254],
            [-69.66222689842607, -28.541390956281546],
            [-69.655381900997639, -28.400935907292364],
            [-69.605281924473644, -28.368335835904915],
            [-69.531955023633117, -28.314026970508536],
            [-69.398899854064183, -28.180281970306268],
            [-69.350563932887212, -28.126108891741637],
            [-69.337781865785018, -28.103199976832741],
            [-69.326126829387078, -28.059173024452292],
            [-69.295981985084723, -27.996808982443795],
            [-69.25903589688852, -27.980146094160588],
            [-69.222090982158988, -27.976946051157285],
            [-69.193199902936925, -27.970417889669903],
            [-69.177781894916848, -27.951946019038331],
            [-69.164463888924161, -27.925555093902517],
            [-69.155290901701477, -27.90278196582544],
            [-69.120526790546108, -27.77426408809599],
            [-69.113063879213996, -27.733064016388383],
            [-69.073472965282576, -27.630726845662593],
            [-69.045355032811671, -27.601599899684544],
            [-68.903481933439309, -27.364172936207169],
            [-68.869517791125247, -27.278546095336608],
            [-68.878126843900787, -27.24007299201584],
            [-68.866954940403105, -27.209446020641352],
            [-68.847782007388105, -27.176108844686567],
            [-68.807435883978343, -27.118545956830346],
            [-68.782372987585433, -27.106108888863915],
            [-68.73889085891301, -27.103335820009548],
            [-68.694926770806433, -27.109654936831177],
            [-68.645846033711365, -27.1384729258573],
            [-68.411255003362243, -27.066526859749828],
            [-68.392226909634473, -27.058608978712073],
            [-68.353899819067323, -27.040281947367816],
            [-68.323335879604883, -27.023335918394508],
            [-68.287790910347695, -26.912781967962928],
            [-68.295835861037943, -26.891672982946801],
            [-68.424454992157848, -26.712499911425354],
            [-68.436399874768625, -26.69639089930547],
            [-68.450290867663853, -26.681390980616996],
            [-68.495564041411996, -26.633055059440125],
            [-68.541117841279004, -26.584446055856958],
            [-68.568208822970035, -26.545555030842664],
            [-68.578063929475121, -26.52541784368357],
            [-68.583617946172694, -26.505281997628941],
            [-68.585555004002032, -26.457226870209198],
            [-68.585281921595737, -26.43667293482298],
            [-68.581117792086616, -26.381945980095097],
            [-68.577226912621597, -26.350563966880458],
            [-68.564446018985905, -26.303955053038678],
            [-68.545290855605629, -26.281946019257163],
            [-68.519726889039447, -26.263335847222805],
            [-68.479735823049253, -26.23805502134671],
            [-68.419445966806194, -26.187782042340501],
            [-68.399863996915087, -26.159372922552492],
            [-68.454454997173059, -25.905554979731718],
            [-68.493346022187438, -25.742781940009166],
            [-68.50110883801608, -25.718335951691628],
            [-68.55334603221786, -25.617499979828665],
            [-68.596672928042977, -25.498055009397817],
            [-68.599726790654586, -25.473890988303893],
            [-68.597499886612695, -25.443335933658872],
            [-68.593064015801673, -25.423335874436063],
            [-68.572508906949054, -25.346108874037213],
            [-68.560008807070489, -25.315281910452484],
            [-68.539463924139852, -25.273890898990018],
            [-68.534708870455091, -25.269890887145607],
            [-68.530008802055363, -25.251946070587039],
            [-68.514872928897006, -25.198755015801225],
            [-68.496946049611438, -25.171108984480483],
            [-68.485899874661754, -25.162490879249432],
            [-68.420290862648727, -25.152781953136142],
            [-68.355626826402499, -25.121255100634258],
            [-68.500290931901958, -24.894999873016403],
            [-68.548335833399619, -24.869581919203924],
            [-68.570973007006899, -24.813335828340229],
            [-68.567426791401545, -24.781946103774743],
            [-68.540845932339295, -24.729446053088651],
            [-68.508054921196987, -24.670282054083359],
            [-68.463054997493117, -24.604726853888891],
            [-68.390699894509623, -24.503755095194194],
            [-68.365835816860567, -24.489926966572824],
            [-68.318972931351794, -24.496945972311593],
            [-68.277508829693488, -24.447226869468793],
            [-68.25361789100586, -24.398608981068307],
            [-68.189999916278708, -24.371390929724882],
            [-67.89917302098786, -24.25389089611221],
            [-67.639881863016313, -24.147590929192035],
            [-67.577326881252759, -24.121691015945601],
            [-67.335845926474349, -24.021664065379426],
            [-67.250917801054442, -23.733226850690514],
            [-67.190846041932645, -23.528617888083772],
            [-67.150845923487026, -23.391946094922488],
            [-67.13862678500368, -23.358335836561494],
            [-67.09360892402708, -23.241108885355118],
            [-67.047499906892085, -23.124172953827852],
            [-67.000835840127365, -23.002782040750262],
            [-67.104735900523622, -22.900555008232459],
            [-67.183626876345528, -22.821664032410652],
            [-67.124454998351212, -22.719582006818143],
            [-67.012645941734519, -22.634172927792761],
            [-67.01806400396228, -22.573890950538825],
            [-67.022508927228614, -22.523890886300805],
            [-66.926390961038294, -22.478054951572005],
            [-66.850281938888088, -22.45305508709113],
            [-66.774735845356702, -22.426945961540852],
            [-66.771390963066182, -22.374999954656275],
            [-66.736682004833938, -22.227499916028492],
            [-66.688899959820475, -22.196108850358442],
            [-66.616390965094183, -22.191946061953701],
            [-66.559717900081552, -22.178335862815516],
            [-66.495563986463907, -22.161108872447073],
            [-66.402235852934268, -22.134164071147836],
            [-66.345381906450598, -22.116626950360995],
            [-66.306946018779854, -22.076946014789215],
            [-66.291263980808992, -22.035281920920553],
            [-66.281955039116411, -21.978054979744513],
            [-66.263063905686835, -21.902226918989612],
            [-66.236254891219431, -21.796963961133116],
            [-66.223008801956183, -21.780517828866678],
            [-66.194735804276178, -21.788335797618345],
            [-66.077517905525212, -21.831946001771655],
            [-65.926117934977071, -21.933335849797956],
            [-65.867508817963511, -22.005554998311638],
            [-65.812790915690954, -22.071390992263233],
            [-65.750008951989031, -22.110072972611775],
            [-65.65749989803993, -22.108890956622687],
            [-65.598890948664433, -22.099999936623519],
            [-65.190199909885962, -22.094726881321066],
            [-64.988345911222694, -22.09083583421814],
            [-64.96945494543121, -22.10763585043766],
            [-64.715699866884393, -22.182090955448615],
            [-64.68357287011338, -22.175435891946819],
            [-64.59264598421214, -22.213199886256973],
            [-64.546954888770671, -22.260564009576996],
            [-64.540081895785704, -22.286599877293341],
            [-64.526117812694338, -22.408891006774397],
            [-64.461945961803963, -22.514445990138313],
            [-64.44389083939933, -22.580000016866208],
            [-64.455281845846685, -22.615555044407472],
            [-64.453135910989658, -22.643963996557403],
            [-64.416399873287702, -22.683608889945461],
            [-64.363063979214758, -22.736945957484977],
            [-64.352508816154455, -22.767226923895308],
            [-64.347781925664407, -22.814164073066408],
            [-64.348072945343517, -22.843054984650294],
            [-64.338272991761556, -22.869790908921772],
            [-64.316390859994357, -22.860555057425017],
            [-64.301390941305897, -22.832499989228083],
            [-64.283890868531159, -22.754446030259615],
            [-64.286117940211028, -22.727499887855998],
            [-64.279872919413833, -22.645555049422597],
            [-64.253346039808122, -22.56778205184942],
            [-64.244172884947261, -22.549726929444887],
            [-64.223072952386616, -22.512917969185168],
            [-64.199390890726647, -22.494999974716919],
            [-64.172645913999787, -22.470417864291392],
            [-64.126681903790086, -22.410281899791158],
            [-64.05862688478561, -22.249726879293178],
            [-63.9930549207848, -22.094446087563938],
            [-63.941117798717642, -22.000835819172607],
            [-63.733063873896128, -22.002781929457271],
            [-63.685835872683981, -22.003055011863552],
            [-63.593335871190476, -22.003335805620694],
            [-63.130281987559073, -22.00055502541548],
            [-62.88611781792838, -21.997781956561113],
            [-62.817190913353329, -21.997381972140502],
            [-62.800699854085678, -22.017017921488346],
            [-62.792781973047909, -22.058890892384667],
            [-62.793063940271423, -22.093755083464217],
            [-62.79236388371632, -22.115417944643582],
            [-62.765008872074588, -22.154726891351487],
            [-62.657226817030704, -22.236254981557749],
            [-62.643772856568205, -22.238899974931144],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ARM", Country: "Armenia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.540373259122788, 38.875591206424431],
            [46.51639129296629, 38.878036039949578],
            [46.451936133747807, 38.889991148482139],
            [46.37638216122744, 38.906936003988861],
            [46.351091277067354, 38.909982155249438],
            [46.294718116551252, 38.895818079947915],
            [46.268600273821647, 38.882345008746015],
            [46.245000187174895, 38.866791213894018],
            [46.195536229465603, 38.844144987831456],
            [46.178245201356646, 38.841145104676585],
            [46.123600221642022, 38.909982155249438],
            [46.107218127115942, 38.936100165617134],
            [45.968736178139835, 39.174573190613685],
            [45.969427182239457, 39.197764240384274],
            [45.986091243989137, 39.223045066260468],
            [46.005618228595409, 39.249027122158267],
            [45.98512715748322, 39.275400110021152],
            [45.855554165779182, 39.348036006761774],
            [45.828945143522134, 39.446300075447297],
            [45.819354066967747, 39.549164132608198],
            [45.791936191051974, 39.565536001212379],
            [45.738391252313164, 39.579154079339489],
            [45.626654112425939, 39.559991204608295],
            [45.599163313952346, 39.551654060772464],
            [45.558327184481669, 39.533873194240513],
            [45.528327179466515, 39.517209132491004],
            [45.50235417602417, 39.504436117844236],
            [45.464991171963021, 39.494018082720302],
            [45.326927144680411, 39.538953968683543],
            [45.266391195759496, 39.611109079456767],
            [45.075545150567251, 39.772282181496806],
            [45.047073334143278, 39.779154001015385],
            [45.021100163062926, 39.76985394414011],
            [44.998745124317423, 39.751918012398946],
            [44.960536218585361, 39.729427019183575],
            [44.926791179220942, 39.719844995084586],
            [44.889573182085115, 39.722909083617978],
            [44.858327123340388, 39.724991148372723],
            [44.794282174464314, 39.711227057491797],
            [44.778863328253834, 39.706382149804668],
            [44.746100145030169, 39.730554049887715],
            [44.641382178519763, 39.803744996258231],
            [44.592491207712982, 39.83180006445518],
            [44.560263292827671, 39.879436096714826],
            [44.551800252805748, 39.898744984199922],
            [44.487782293658, 39.962773169269539],
            [44.399154060890027, 40.003609131102095],
            [44.351663370831744, 40.022218129670065],
            [44.28263621869425, 40.046100183540474],
            [44.044291269178615, 40.010344996150863],
            [43.971373237576159, 40.010818070767314],
            [43.933882158034152, 40.013609076894497],
            [43.91249120579451, 40.01860016497325],
            [43.668400126359842, 40.103182117791121],
            [43.64881815646865, 40.124854031425897],
            [43.66852719601232, 40.146173234574164],
            [43.715373317714665, 40.157182193873538],
            [43.661654035389205, 40.274436134808226],
            [43.606936133116818, 40.368891131403913],
            [43.583054079246409, 40.451109052243524],
            [43.592763340635855, 40.501100064026033],
            [43.653045150251813, 40.531382203902851],
            [43.717936168436694, 40.620918029149252],
            [43.741382195702698, 40.666654051591863],
            [43.75040917017165, 40.745000203705942],
            [43.742491289133937, 40.770264265775594],
            [43.672491165673108, 40.92651802882817],
            [43.5952001275339, 40.993326994103015],
            [43.56597326926979, 40.996664165042588],
            [43.483873197989141, 41.022773122954888],
            [43.454163374462752, 41.094927060261639],
            [43.460773175687251, 41.112964245393258],
            [43.546391299378485, 41.134991216447688],
            [43.570136225312496, 41.13554509261111],
            [43.599709256178784, 41.129718161145334],
            [43.637500240217236, 41.123044992732545],
            [43.734427227704288, 41.113600096569954],
            [43.756245154092966, 41.112627125246618],
            [43.791373207485123, 41.118664107206556],
            [43.833609114789994, 41.145545038403426],
            [43.85651819733701, 41.160127035398475],
            [43.8924911465717, 41.16540914315631],
            [43.992763351625541, 41.164144984515801],
            [44.122763317478501, 41.181927024514138],
            [44.144709151709975, 41.184991113047616],
            [44.172700182166381, 41.196864246566818],
            [44.248873242057044, 41.223591118382885],
            [44.340545111514615, 41.212491131614641],
            [44.429991250396739, 41.192218157623742],
            [44.484154103039401, 41.187764181901898],
            [44.566236069409086, 41.185264195453712],
            [44.728600239893609, 41.212209164391041],
            [44.824718206083929, 41.212491131614641],
            [44.848873174722456, 41.213054060233489],
            [44.86970924497021, 41.216645035201978],
            [44.978600225807241, 41.270264237603087],
            [45.022945187595013, 41.297053973693025],
            [45.046945091024412, 41.223309151159171],
            [45.143600169571727, 41.201518046860926],
            [45.193045184179852, 41.173045056970452],
            [45.211373221352602, 41.159718166160346],
            [45.22109119992129, 41.139982136888349],
            [45.204709273033387, 41.12075405095031],
            [45.175963368374795, 41.115000042042212],
            [45.127900194327964, 41.118182147772686],
            [45.082073312054632, 41.113636138753677],
            [45.079991247300001, 41.080409101007021],
            [45.096373174188017, 41.059709152867086],
            [45.350963257931625, 41.000964249021806],
            [45.619436120305323, 40.86658222417627],
            [45.61804505965037, 40.8359641376192],
            [45.602700141826148, 40.800473147818465],
            [45.576936183049696, 40.777491142713629],
            [45.523318154114889, 40.763053985005897],
            [45.455545102334128, 40.742209029940668],
            [45.43638222760282, 40.733736099273003],
            [45.420400285135457, 40.719991119131478],
            [45.389154058752723, 40.663735975812287],
            [45.413600214708225, 40.608036049760969],
            [45.536382188440683, 40.456927098891967],
            [45.638036066417698, 40.391109042213188],
            [45.662763351406596, 40.375827156301014],
            [45.882209120865667, 40.267491057455729],
            [45.913345209040443, 40.264500059118276],
            [45.956027201233582, 40.27213614057051],
            [45.997727169647959, 40.230827104121417],
            [45.977409268655805, 40.118453945419404],
            [45.907973247280239, 40.023944969367065],
            [45.880545145442653, 40.014018113770518],
            [45.847773245039633, 40.016109063342682],
            [45.824436182515257, 40.019982173172778],
            [45.763609213915373, 40.024435981256445],
            [45.713609149677353, 40.026091071862183],
            [45.681927231966398, 40.02582704191137],
            [45.638036066417698, 40.021664253506643],
            [45.613600136384008, 40.014436035464044],
            [45.597554156176301, 39.997354052021009],
            [45.607763314272432, 39.971791091283123],
            [45.705273179308023, 39.961373223797281],
            [45.729709276979833, 39.958318020081222],
            [45.751100229219418, 39.953318047185164],
            [45.779300136703654, 39.940545032538481],
            [45.796936163948175, 39.917900147580283],
            [45.804718258154224, 39.892073156891698],
            [45.820263335826809, 39.860400123997934],
            [45.85068226836384, 39.829018110783394],
            [45.881382162296092, 39.808591077411663],
            [45.917627188108582, 39.791664159177643],
            [45.960273305755976, 39.778600124851963],
            [45.983873224764665, 39.777491199058815],
            [46.008663374027577, 39.770191064286749],
            [46.030536118063111, 39.745536031303445],
            [46.043318185165191, 39.723035985632563],
            [46.057491145284217, 39.699845103499868],
            [46.189709130361649, 39.607218032353856],
            [46.207627124829997, 39.597073247274224],
            [46.244227208061858, 39.594982130064167],
            [46.304573223056337, 39.616518089229132],
            [46.329427242421588, 39.625127142004587],
            [46.357218113029631, 39.626509150204029],
            [46.526236173549563, 39.578118076104218],
            [46.54569124142634, 39.549364124818439],
            [46.512973320479915, 39.475609076362687],
            [46.448600136274621, 39.457764172090393],
            [46.404291216670657, 39.451236010603012],
            [46.386027217238365, 39.438664161633156],
            [46.384154197149456, 39.405964177959433],
            [46.398182317981167, 39.384153962921872],
            [46.557491284749716, 39.269145030940066],
            [46.581382223437373, 39.253609173360886],
            [46.620536104936122, 39.229427047356054],
            [46.620473240662051, 39.221654005605629],
            [46.541654181569726, 39.189418044093131],
            [46.496091161608973, 39.203609109122979],
            [46.455818128395236, 39.217554081474859],
            [46.423145134449925, 39.208700109487907],
            [46.42442723036325, 39.165618132874059],
            [46.458318114843422, 39.142773088067528],
            [46.477482163041174, 39.136653963456254],
            [46.495063372638839, 39.126444972998101],
            [46.517773301165818, 39.073736045284235],
            [46.523600232631594, 39.044153961962635],
            [46.516100273287265, 39.02484507447754],
            [46.502209112753945, 38.999845042358587],
            [46.495536111979249, 38.957345105102988],
            [46.540373259122788, 38.875591206424431],
          ],
          [
            [45.241100143961518, 40.985127145841645],
            [45.202027231647691, 40.999436060430483],
            [45.206382300911827, 40.979427116390369],
            [45.229163307977927, 40.969154088191758],
            [45.241100143961518, 40.985127145841645],
          ],
          [
            [45.049709275061247, 41.038745007138388],
            [45.055263291758848, 41.059718037684419],
            [45.023882284372661, 41.087073216964328],
            [44.992636225628047, 41.085409073903108],
            [44.984154074866893, 41.057627088112454],
            [45.009991291477291, 41.033054030142509],
            [45.025827221190951, 41.030544991238841],
            [45.049709275061247, 41.038745007138388],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ARM", Country: "Armenia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [45.573054188402097, 40.632491090534117],
            [45.528882229096354, 40.606100165398217],
            [45.506100216201872, 40.612082162073207],
            [45.491936140900322, 40.636936013800366],
            [45.509436213675059, 40.661791206632003],
            [45.56391824919146, 40.664209050428823],
            [45.58790926780344, 40.651100089101888],
            [45.573054188402097, 40.632491090534117],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ASM", Country: "American Samoa" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-166.64305565500004, -17.557777664],
            [-166.701944655, -17.529166663999973],
            [-168.434722657000037, -16.938888663999933],
            [-169.305277657999966, -16.631944663999931],
            [-169.319999658000029, -16.626666664000041],
            [-169.933055658000029, -16.582777663999963],
            [-170.319166659000018, -16.654722663999976],
            [-171.208055658999967, -16.812777663999967],
            [-171.284166659999983, -16.825833664000015],
            [-171.628055659999973, -16.224722663],
            [-171.710277659999974, -16.079722662999984],
            [-171.76833366, -15.972222662999968],
            [-171.83972266, -15.846666663],
            [-171.84555566, -15.836666663000017],
            [-171.626944659999879, -15.238610661999985],
            [-171.526944660000026, -15.03277766199993],
            [-171.405833660000042, -14.779999662],
            [-171.246110658999982, -14.450555661999957],
            [-171.079999659, -14.104999660999965],
            [-171.05152765899993, -14.057638660999942],
            [-171.048055659, -14.051388661],
            [-170.992777659000041, -13.948333660999879],
            [-170.972222658999897, -13.908333660999887],
            [-170.965833659, -13.895277661],
            [-170.939999659, -13.844444660999855],
            [-170.738888659, -13.232222659999863],
            [-170.710833658999974, -13.151388659999967],
            [-170.512222659, -12.604999659999905],
            [-170.526388658999963, -12.60305566],
            [-170.607222658999945, -12.589166659999947],
            [-171.139999658999955, -12.496388659999894],
            [-171.290277659999958, -12.457499659999897],
            [-171.421666659999914, -12.392777659999865],
            [-171.62055566, -12.293333659999917],
            [-171.740277659999975, -12.233610658999979],
            [-171.74638866, -12.230277658999981],
            [-172.015277659999867, -12.090833658999856],
            [-172.381388660999903, -11.901666658999858],
            [-172.385833661, -11.89916665899986],
            [-172.804722660999971, -11.680277658999842],
            [-173.146110661, -11.448888658999934],
            [-173.263888660999982, -11.36888865899995],
            [-173.743610661999924, -11.041110657999909],
            [-173.746666662000024, -11.038055658],
            [-173.064722660999905, -10.770833657999916],
            [-172.183610660000028, -10.423888657999839],
            [-171.849444659999989, -10.297222657999953],
            [-171.258888659000036, -10.254722657999963],
            [-170.26944465899993, -10.171666657999893],
            [-169.780555658000026, -10.131110657],
            [-168.523610657, -10.023888656999972],
            [-168.517222656999934, -10.212222657999973],
            [-168.517222656999934, -10.213610657999979],
            [-168.494999657, -10.875277657999945],
            [-168.489166657, -11.044444657999989],
            [-168.466110656999973, -11.73138865899999],
            [-168.173333657000029, -12.031944658999905],
            [-167.422222656, -12.477777659999845],
            [-167.183610656000042, -12.689444659999978],
            [-166.872499655000041, -12.964166659999961],
            [-166.617222654999921, -13.190277659999964],
            [-166.567499654999949, -13.234166660000042],
            [-166.428333655000017, -13.356944659999925],
            [-166.155277654999935, -13.595555660999906],
            [-165.978888655, -13.748888661],
            [-165.622222653999927, -14.058333660999892],
            [-165.368610654, -15.00249966199992],
            [-165.308055654000043, -15.234444662],
            [-165.200833654000036, -15.64638866300001],
            [-165.276666653999939, -15.749444662999949],
            [-165.569999653999986, -16.144999663],
            [-165.691388653999951, -16.308333662999985],
            [-165.753055653999979, -16.391388662999972],
            [-166.027499654999986, -16.758333664],
            [-166.64305565500004, -17.557777664],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ATA", Country: "Antarctica" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.097603542999849, -57.160988549999928],
            [-45.03062254199989, -57.211297549999884],
            [-45.008576541999844, -57.214387549999934],
            [-44.669167541999968, -57.228349549999905],
            [-44.665214541999944, -57.228891549999894],
            [-44.6564065419999, -57.229240549999908],
            [-44.022774540999819, -57.314159549999843],
            [-43.811320540999816, -57.321237549999907],
            [-42.762099539999838, -57.451894550999874],
            [-42.526089539999873, -57.504350550999931],
            [-42.499983539999903, -57.507417550999854],
            [-41.494540538999871, -57.723878550999885],
            [-40.559652537999852, -58.023317550999842],
            [-40.532343537999907, -58.035731550999891],
            [-40.499683538, -58.045960550999986],
            [-39.658137536999874, -58.420840550999934],
            [-39.652436536999971, -58.424381550999904],
            [-39.65001353699995, -58.425440550999866],
            [-38.926298535999877, -58.867032551999912],
            [-38.343450535999978, -59.36430655199986],
            [-37.923043534999891, -59.904316552999873],
            [-37.916985534999924, -59.919019552999863],
            [-37.914638534999881, -59.92197755299992],
            [-37.675933534999871, -60.490045552999938],
            [-37.635841534999884, -61.070028553999933],
            [-37.806562535000012, -61.64454455399985],
            [-38.19438353599989, -62.195405554999908],
            [-38.797979535999929, -62.704172555],
            [-38.803801535999895, -62.707467554999866],
            [-38.805650535999916, -62.708995554999902],
            [-38.81834453599987, -62.716173554999891],
            [-38.847788535999882, -62.740474554999906],
            [-39.658324537, -63.188875555999871],
            [-39.66923153699986, -63.193089055999884],
            [-40.664700538000034, -63.564716555999922],
            [-41.812155538999946, -63.84478655599991],
            [-41.95005653899986, -63.864861556999941],
            [-42.01284853899989, -63.879690556999883],
            [-43.273252539999817, -64.0553215569999],
            [-44.592943541999944, -64.120741556999945],
            [-44.600047541999857, -64.120518556999883],
            [-44.605221541999953, -64.120751556999892],
            [-45.677139542999925, -64.083081556999858],
            [-45.701577542999985, -64.084002556999934],
            [-47.02169154399985, -64.026622556999882],
            [-47.190622544, -64.004191556999871],
            [-47.299767543999877, -64.342873556999891],
            [-47.724359543999952, -64.894967556999916],
            [-48.388519544999866, -65.405180557999927],
            [-49.282060546, -65.855077557999948],
            [-49.288846545999945, -65.85743155800003],
            [-49.29322054599993, -65.859583557999912],
            [-49.305311545999984, -65.863773557999849],
            [-49.346322545999982, -65.883911557999937],
            [-49.996392546999886, -66.105404558999851],
            [-50.418949546999897, -66.393283558999912],
            [-50.658567546999961, -66.503954559000022],
            [-50.778930546999959, -66.583546558999899],
            [-50.82294054700003, -66.603588558999874],
            [-50.844308546999912, -66.617623559],
            [-51.174562548000011, -66.766431558999898],
            [-50.746173547000012, -67.071626558999952],
            [-50.249613547, -67.619059559999982],
            [-50.242871546999936, -67.634415559999908],
            [-50.236323546999927, -67.641442560000016],
            [-49.977897546999856, -68.213172560999894],
            [-49.97796254699989, -68.218924560999852],
            [-49.975805546999965, -68.223566560999956],
            [-49.977738546999944, -68.398497560999886],
            [-49.809349545999851, -68.751377560999956],
            [-49.80938154599994, -68.766310560999884],
            [-49.786076546, -68.814158560999886],
            [-49.785944045999969, -68.833740560999885],
            [-49.78566054599986, -69.413986561999877],
            [-49.79398254599991, -69.430573561999879],
            [-49.79398054599983, -69.433027561999893],
            [-49.794983545999855, -69.435026561999905],
            [-49.794949545999884, -69.468953561999953],
            [-49.808815546, -69.496538561999898],
            [-50.103993546999931, -70.067526561999856],
            [-50.457483546999924, -70.388655562999887],
            [-50.390142547000011, -70.452245562999863],
            [-50.289799546999916, -70.649569562999858],
            [-50.282497546999878, -70.656332562999964],
            [-50.281688546999931, -70.65790356299992],
            [-50.213403546999871, -70.720890563000012],
            [-50.212942547000011, -70.721777562999861],
            [-50.140957546999971, -70.787704562999934],
            [-50.121078546999911, -70.825439562999932],
            [-50.094242046999796, -70.849981062999859],
            [-50.068282546999967, -70.873662562999954],
            [-50.059249546999894, -70.890624562999903],
            [-50.057918546999986, -70.891826562999881],
            [-50.045753546999833, -70.914615562999927],
            [-49.987313547000014, -70.96716656299995],
            [-49.96767754699988, -71.003509562999966],
            [-49.939385546999887, -71.028764562999953],
            [-49.936857546999875, -71.033414562999937],
            [-49.903742545999961, -71.062873562999954],
            [-49.883531545999858, -71.099775562999952],
            [-49.870175546000041, -71.1115935629999],
            [-49.861184545999976, -71.12792956299991],
            [-49.822989545999917, -71.161593562999855],
            [-49.808535545999916, -71.187645562999904],
            [-49.79483754599994, -71.199662562999862],
            [-49.756299545999866, -71.268620562999914],
            [-49.735201545999928, -71.286990563],
            [-49.730151545999888, -71.295958562999857],
            [-49.360276546, -71.611650563999888],
            [-49.133441545999943, -71.98541356399987],
            [-48.785343544999932, -72.26319356399992],
            [-48.761205044999969, -72.300122563999849],
            [-48.376600544999917, -72.869030565],
            [-48.353346545000022, -73.105772564999882],
            [-48.317221544999938, -73.156392564999891],
            [-48.317024544999981, -73.158304564999924],
            [-48.315547544999987, -73.160367564999902],
            [-48.270026544999951, -73.587626565999926],
            [-48.198812544999868, -73.603482565999926],
            [-48.19286854500001, -73.605358565999893],
            [-48.127457544999913, -73.619870565999889],
            [-48.110571544999857, -73.62517856599996],
            [-48.094509544999966, -73.628732565999854],
            [-48.052226544999883, -73.641991565999916],
            [-48.024098544999873, -73.64819356599989],
            [-47.88713554499995, -73.69088456599988],
            [-47.882344545000024, -73.691929565999914],
            [-47.820269544999974, -73.700771565999915],
            [-47.770447543999893, -73.711243565999865],
            [-47.766249543999919, -73.711839565999981],
            [-47.675570543999896, -73.730832565999918],
            [-46.72636654400003, -73.861619565999987],
            [-46.192850542999906, -73.967287565999953],
            [-46.084049542999878, -73.975485565999946],
            [-46.057239542999923, -73.978966565999841],
            [-45.887431542999877, -73.991610566],
            [-43.950647540999853, -74.228654565999904],
            [-43.949474540999972, -74.22886256599989],
            [-43.874553540999841, -74.237552566],
            [-43.800073540999961, -74.250705565999922],
            [-43.721310540999923, -74.259764565999845],
            [-43.624272541000011, -74.276772565999948],
            [-43.601630540999849, -74.279354565999895],
            [-43.231610539999849, -74.343428565999858],
            [-43.09621754, -74.338554565999914],
            [-43.088227539999878, -74.338642565999905],
            [-43.076419539999961, -74.338212566000038],
            [-42.93667904, -74.33967906599986],
            [-42.86301554, -74.34040956599992],
            [-42.848509539999867, -74.339866565999927],
            [-42.656817539999849, -74.341676565999848],
            [-42.646699539999872, -74.341288565999903],
            [-42.635987539999974, -74.341384566000031],
            [-42.608650539999928, -74.340331565999946],
            [-41.533031538999893, -74.347148565999902],
            [-40.456815037999888, -74.348704065999911],
            [-40.353911537999863, -74.348579565999913],
            [-40.309425537999857, -74.350621565999944],
            [-40.185693536999906, -74.356253565999907],
            [-39.277502536999918, -74.208919565999949],
            [-39.266111536999858, -74.207706565999857],
            [-39.245901536999867, -74.204352565999983],
            [-39.199884536999832, -74.199441565999905],
            [-38.724037535999969, -74.083743565999896],
            [-38.722617535999888, -74.083500565999856],
            [-38.706381535999981, -74.079505565999881],
            [-37.863313534999975, -73.932563565999857],
            [-37.861082535000037, -73.931993565999861],
            [-36.76338553399998, -73.519915565999852],
            [-36.751926533999892, -73.516798564999959],
            [-36.739890533999954, -73.512130565],
            [-35.298131532999946, -73.105074564999853],
            [-35.271140532999851, -73.099536564999852],
            [-35.267851532999856, -73.098572565000026],
            [-34.174058531999975, -72.8680525649999],
            [-33.862293531999967, -72.729106564999853],
            [-33.797598530999949, -72.70877256499989],
            [-33.772783530999845, -72.697558564999923],
            [-32.464250529999845, -72.270738563999899],
            [-32.130386529999868, -72.190865563999935],
            [-32.021057529999865, -72.153293563999924],
            [-32.01330353, -72.151412563999898],
            [-31.985530529999863, -72.14183056399996],
            [-30.530074527999886, -71.775597563999867],
            [-30.522817527999848, -71.774297563999852],
            [-30.489923527999906, -71.765719563999852],
            [-29.684570527999853, -71.61836556399993],
            [-29.416051526999922, -71.54531656399989],
            [-29.404221526999976, -71.543077563999901],
            [-29.341633526999857, -71.525905563999913],
            [-29.327542526999963, -71.523228563999922],
            [-29.323656526999855, -71.522160563999932],
            [-29.298989526999833, -71.517468563999927],
            [-29.286913526999882, -71.510573563999912],
            [-29.284035526999872, -71.509447564000013],
            [-29.266395526999872, -71.499363563999921],
            [-28.805968526999891, -71.316704562999888],
            [-28.279103525999915, -71.001107562999906],
            [-28.259925525999961, -70.99313056299988],
            [-28.255262525999882, -70.99028256299988],
            [-27.180945524999913, -70.529420562999888],
            [-25.930594523999901, -70.128689562000019],
            [-25.920757523999896, -70.126416561999889],
            [-25.858801523999773, -70.105904561999935],
            [-24.470041522999907, -69.773113561999907],
            [-24.421371522999948, -69.765036561999878],
            [-24.354420522999959, -69.748403561999879],
            [-22.86071452099992, -69.489726561999916],
            [-21.290098519999873, -69.311094561999838],
            [-21.286766519999873, -69.310914561999866],
            [-21.257345519999888, -69.307399562],
            [-20.45562251899986, -69.26206956199988],
            [-19.934317518999961, -69.156525560999881],
            [-19.928477518999927, -69.155770560999855],
            [-19.909975518999829, -69.151975560999915],
            [-19.7342155179999, -69.129106560999901],
            [-19.692094518, -69.120385560999878],
            [-19.392649517999899, -69.079386560999922],
            [-19.38239651799995, -69.076236560999874],
            [-18.377653516999828, -68.767536560999901],
            [-18.360189516999924, -68.763739560999966],
            [-18.332563516999869, -68.755029560999873],
            [-18.147788516999981, -68.714599560999915],
            [-17.466594515999873, -68.494273560999915],
            [-16.501712514999895, -68.269291560999903],
            [-15.909901514999831, -68.063129559999879],
            [-15.89975451499987, -68.060631559999877],
            [-15.868702514999853, -68.049641559999884],
            [-14.592634513999883, -67.725169559999912],
            [-14.574658514, -67.722043559999904],
            [-14.531477513999874, -67.710707559999875],
            [-14.419017512999972, -67.691042559999929],
            [-14.416177512999923, -67.690292559999875],
            [-14.121496512999897, -67.638212559999928],
            [-14.065302512999835, -67.623159560000019],
            [-14.060904512999912, -67.622373559999915],
            [-14.043883512999969, -67.617805559999965],
            [-12.680120512, -67.365254559999869],
            [-12.668073511999921, -67.363885559999943],
            [-12.665015511999854, -67.363298559999947],
            [-11.229572509999855, -67.192174559999899],
            [-9.751099508999886, -67.106060559999946],
            [-8.563962507999918, -67.107732559999903],
            [-7.851109506999819, -66.76527455899992],
            [-7.841268506999768, -66.761885558999893],
            [-7.831454506999819, -66.757074558999832],
            [-6.742596505999927, -66.37175255899993],
            [-6.725996505999831, -66.367568558999864],
            [-6.713655505999981, -66.363081558999966],
            [-5.505296504999933, -66.049400558999878],
            [-5.485734504999982, -66.045977558999894],
            [-5.479591504999917, -66.044335558999833],
            [-4.180567503999839, -65.80900555799991],
            [-2.818808502999843, -65.657156557999855],
            [-2.765560502999904, -65.654917557999966],
            [-2.756664502999797, -65.653874557999899],
            [-1.360514500999898, -65.588047557999928],
            [-1.32792150099985, -65.588713557999881],
            [-1.300508500999911, -65.587278557999895],
            [-1.166955500999961, -65.58990655799991],
            [-0.971724500999954, -65.579356557999901],
            [-0.893465500999952, -65.58073655799987],
            [-0.854303500999833, -65.578567557999904],
            [-0.729406500999943, -65.58065055799986],
            [-0.718649500999845, -65.5800435579999],
            [0.684546501000113, -65.596065557999879],
            [0.709354501000234, -65.598043557999929],
            [0.768191501000075, -65.598413557999905],
            [0.830145501000118, -65.60331055799989],
            [0.838869501000232, -65.603359557999866],
            [2.224512502000096, -65.705560557999846],
            [2.26592200200011, -65.711588557999889],
            [2.299584502000101, -65.713890557999846],
            [3.640290503000074, -65.900998557999927],
            [3.651955003000097, -65.90354905799984],
            [3.657464503000142, -65.904286557999924],
            [4.924539505000212, -66.173017558999902],
            [6.08876450600016, -66.517879558999851],
            [6.511097506000027, -66.689689558999902],
            [7.28656850700014, -66.663304558999954],
            [7.334770507000115, -66.664879558999871],
            [7.918890507000015, -66.64219855899988],
            [8.397710508000131, -66.655196558999847],
            [8.664972508000233, -66.643228559000022],
            [8.745751508000126, -66.633666558999863],
            [8.787056508, -66.631751558999866],
            [8.789013508000096, -66.631518558999943],
            [9.112152508000264, -66.616139058999849],
            [9.133185509000157, -66.611902558999873],
            [9.138470509000172, -66.610319559],
            [10.427050510000129, -66.340992558999915],
            [11.790670511000087, -66.153180558999935],
            [11.822643511000024, -66.151011558999883],
            [11.85479851100007, -66.146392558999892],
            [13.264259512000081, -66.043319558999883],
            [13.267588512, -66.043298558999822],
            [13.782017513000142, -66.002151558999884],
            [13.794589513000119, -66.002028558999911],
            [13.863885513000156, -65.996334557999887],
            [13.93029651300003, -65.995640557999835],
            [13.939392513, -65.994885557999893],
            [15.364116514000216, -65.97278655799991],
            [15.36706151400017, -65.972936557999887],
            [15.50425151400006, -65.97008755799996],
            [16.921918516000176, -66.034391558999872],
            [16.976522516000131, -66.040621559000016],
            [16.979570516000081, -66.040743558999907],
            [18.363226517000157, -66.190930559],
            [19.684154518000128, -66.424473558999892],
            [19.708747518000052, -66.430893558999941],
            [20.697734519, -66.519176558999874],
            [20.699876519000213, -66.51952455899989],
            [20.713507519000245, -66.5206915589999],
            [21.172611520000089, -66.594207558999955],
            [21.217339520000138, -66.594720558999853],
            [21.274659520000114, -66.599401558999915],
            [21.321040520000224, -66.599902558999901],
            [22.75914152100006, -66.70928055899995],
            [22.760132521000173, -66.709424558999871],
            [22.841241521000086, -66.715161559],
            [22.934867521000029, -66.728684558999888],
            [22.999046521000082, -66.733148559000028],
            [23.204841522, -66.762515558999894],
            [23.385613522000142, -66.774669559000017],
            [23.518026522000184, -66.774234558999893],
            [23.76752702200011, -66.737199558999919],
            [23.814171522, -66.730234558999882],
            [23.941484522000138, -66.720577558999864],
            [23.942697522000117, -66.720394558999914],
            [24.446112523000238, -66.681100558999887],
            [24.470588023000118, -66.67262055899991],
            [24.558034523000032, -66.642155558999875],
            [24.585852523000057, -66.635409558999925],
            [24.589661523000188, -66.634076558999851],
            [25.197637523000139, -66.484415558999927],
            [25.208836523000116, -66.48039355899985],
            [25.217146523000139, -66.478317558999862],
            [25.242032524, -66.469368558999889],
            [26.462118525000079, -66.1554515589999],
            [26.4689095250001, -66.154272558999878],
            [26.474122525000126, -66.152892558999909],
            [27.784498526000249, -65.917380557999849],
            [27.794054526000053, -65.91637055799994],
            [27.831178526000059, -65.909464557999897],
            [29.202517527000083, -65.756860557999914],
            [29.293257527000179, -65.752582557999887],
            [29.473443527000228, -65.711669558000011],
            [29.732946528000326, -65.652746557999833],
            [29.733855528000021, -65.65261655799992],
            [29.770197528000125, -65.644255557999912],
            [29.887640528000048, -65.627362557999916],
            [29.924027528000153, -65.6189315579999],
            [31.241286529000064, -65.420880557999894],
            [32.609137530000254, -65.307691557999902],
            [32.617880530000235, -65.307569557999926],
            [32.646579530000139, -65.305044557999906],
            [32.764792531000268, -65.303324557999886],
            [32.839438531000241, -65.296662557999866],
            [32.936659531000117, -65.295126557999964],
            [32.964171531000289, -65.292641557999886],
            [34.353448531999987, -65.263350557999914],
            [35.7389365330001, -65.321968557999909],
            [35.757966533000257, -65.324109557999947],
            [35.760869533000147, -65.32421755799993],
            [37.11503153500027, -65.469441557999914],
            [37.410290535000087, -65.52303355799998],
            [37.506468535000153, -65.503232557999894],
            [37.876089535000091, -65.388918557999901],
            [37.885468535000172, -65.386943557999913],
            [37.902464535000291, -65.381637557999909],
            [37.998860535000262, -65.361272557999882],
            [38.128674536000318, -65.320436557999898],
            [39.353307537000234, -65.051353557999931],
            [39.360026537000152, -65.050421557999897],
            [39.363272537000142, -65.049686557999934],
            [40.661154538000147, -64.862417556999844],
            [40.784386538000291, -64.853668556999907],
            [41.325845538000209, -64.660943556999854],
            [42.485507540000043, -64.365508556999913],
            [42.52568854, -64.35877455699989],
            [42.537567540000026, -64.35565955699991],
            [42.785399540000213, -64.313771556999825],
            [42.814371540000167, -64.306083556999937],
            [42.816808539999982, -64.305668556999876],
            [42.830936540000124, -64.301915556999944],
            [42.833719540000146, -64.301440556999893],
            [43.269616539999987, -64.132266556999895],
            [43.301524540000088, -64.123589556999875],
            [43.310943540000125, -64.11988955699988],
            [44.425210541000212, -63.808521555999867],
            [44.439801541000151, -63.805774055999819],
            [45.639382543000096, -63.573968555999919],
            [46.450875543000024, -63.480612555999919],
            [46.46279754300005, -63.476897555999933],
            [47.630477544, -63.227914555999931],
            [47.654437544000217, -63.224827555999951],
            [47.659456544000278, -63.223724555999908],
            [47.682425544000097, -63.220760555999888],
            [48.664166545000313, -62.999590555999966],
            [48.99249754600001, -62.953807555999852],
            [49.024383546000166, -62.946337555999868],
            [50.23468754700005, -62.769013554999923],
            [50.26299354700015, -62.767111555000021],
            [50.310075547000025, -62.759937554999922],
            [50.486074547000186, -62.747907554999941],
            [50.501797547000109, -62.745483554999929],
            [50.545216547000081, -62.742473554999926],
            [50.555239547000014, -62.740923554999839],
            [51.268130548000187, -62.689359554999868],
            [51.569244548000171, -62.640506554999853],
            [52.809339549000271, -62.538340554999841],
            [54.066549550000246, -62.525446554999952],
            [54.132317550000124, -62.529784554999878],
            [54.595075551000122, -62.521860554999897],
            [55.844153552000051, -62.593642554999917],
            [56.292595552000165, -62.654117554999914],
            [56.324774552000264, -62.655699554999892],
            [56.355116552000055, -62.659720554999879],
            [56.479342553000123, -62.665722554999903],
            [56.481804553000046, -62.666046554999888],
            [56.655105553000141, -62.674222554999936],
            [56.663099553000023, -62.675261554999928],
            [56.680054553000133, -62.676047554999947],
            [57.902761554000023, -62.828366555999892],
            [59.066967555000275, -63.065652555999932],
            [59.074271555000081, -63.067851555999887],
            [59.110601555000102, -63.075032555999854],
            [59.139517555000197, -63.083717555999918],
            [59.185831555000135, -63.092838555999911],
            [59.199753555000228, -63.097008555999878],
            [59.213802555000058, -63.099768555999894],
            [59.279340555, -63.119350555999901],
            [59.46315955500009, -63.155157555999885],
            [60.54983455600015, -63.469070555999899],
            [60.574297556000232, -63.478992555999902],
            [60.633529556000013, -63.48977355599984],
            [60.814618557000216, -63.540402555999897],
            [60.847926557000136, -63.54638955599993],
            [61.410513557000087, -63.700811555999884],
            [62.274608558000153, -63.707535555999961],
            [63.566840559000326, -63.808545555999899],
            [64.814880560000262, -63.996150556999886],
            [65.560368561000274, -64.169086556999858],
            [66.082317562000156, -64.240052556999871],
            [67.219126563, -64.296911556999902],
            [68.389662563999963, -64.437453556999913],
            [69.360826565000167, -64.397176557],
            [70.703044566000273, -64.4320325569999],
            [70.710376566, -64.432752556999887],
            [70.721427566000074, -64.432986556999964],
            [72.039127567000179, -64.555574556999858],
            [72.122024567000125, -64.569634556999944],
            [72.131129567000102, -64.570430556999895],
            [72.145096067000082, -64.572769556999901],
            [73.412785568000146, -64.779736556999893],
            [73.847545568999948, -64.886847556999882],
            [74.333762569000243, -64.460408556999852],
            [74.356979569000231, -64.446511556999923],
            [74.427814569000276, -64.382607556999915],
            [75.191881570000049, -63.911834556999928],
            [75.19802757, -63.909133557],
            [75.203279570000092, -63.905820556999885],
            [75.272006570000286, -63.875546556999929],
            [75.330777570000208, -63.838262555999862],
            [75.429685570000032, -63.79435655599989],
            [75.450289570000081, -63.781184555999914],
            [76.356360570999954, -63.368001555999875],
            [76.375362571000238, -63.361798555999918],
            [76.37790857100012, -63.36060955599995],
            [77.407251572000263, -63.016059555999881],
            [78.533687573000321, -62.748108554999959],
            [78.55617457300022, -62.744747554999869],
            [78.57382857300027, -62.740425554999845],
            [79.770133574000198, -62.554660554999863],
            [81.01054657500012, -62.455506554999843],
            [82.26869657700027, -62.445165554999896],
            [82.272662577000148, -62.445434554999856],
            [82.305834577000127, -62.445001554999912],
            [83.555400578000246, -62.523440554999887],
            [84.76987457900006, -62.689428554999893],
            [85.343359579000179, -62.815561555999913],
            [85.95018958, -62.742769554999903],
            [86.041960580000165, -62.739070554999913],
            [86.051722580000131, -62.737866554999947],
            [87.313130581000081, -62.6804985549999],
            [87.323572581000263, -62.680809554999875],
            [87.347216581000083, -62.679617554999837],
            [87.436965581000067, -62.682247554999925],
            [87.478695581000096, -62.680106554999881],
            [87.566421582000231, -62.682592554999964],
            [87.613712582000034, -62.6801185549999],
            [87.729858582000105, -62.683282554999856],
            [87.738123582000327, -62.68284155499996],
            [88.956000582999962, -62.709953554999942],
            [89.196140583000016, -62.651602554999918],
            [89.213500583000183, -62.64901255499985],
            [89.222555583000116, -62.6467975549999],
            [90.415823584000151, -62.462037554999853],
            [91.25164458500015, -62.397293554999955],
            [91.268086585000191, -62.392906554999882],
            [91.274960585000287, -62.390234554999871],
            [92.347796586000101, -62.096479554999917],
            [92.386970586000217, -62.089463554999924],
            [92.389242586000279, -62.088824554999903],
            [93.537832587, -61.876337554999871],
            [94.736708588000084, -61.750178554999835],
            [94.822859588000227, -61.747942554999923],
            [94.855473588000081, -61.744319554999919],
            [96.078162589000243, -61.706178553999905],
            [96.090505589000117, -61.706759553999923],
            [96.161592590000311, -61.704183553999862],
            [97.382866591000294, -61.755243554999872],
            [97.464565591000252, -61.765203554999914],
            [97.486504591000283, -61.766002554999858],
            [98.681372592000031, -61.905047554999918],
            [99.028618592000129, -61.974859554999917],
            [99.101852592000029, -61.982777554999934],
            [99.167438592000025, -61.995781554999866],
            [99.194524592000079, -61.9986835549999],
            [99.651778593000273, -62.088038554999883],
            [99.740428593000189, -62.066278554999876],
            [100.913115594000118, -61.884910554999905],
            [102.126209595000063, -61.791227554999942],
            [103.353651596000049, -61.787281554999851],
            [103.367274596000158, -61.788308554999915],
            [103.416825596000166, -61.787906554999871],
            [103.666301597, -61.80635455500002],
            [104.164362597000235, -61.758541554999901],
            [105.389069598000077, -61.735818554999888],
            [106.607440599, -61.80311055499984],
            [106.616366599000145, -61.804329554999896],
            [106.628941599000171, -61.804963554999951],
            [107.815121600000083, -61.960630554999888],
            [108.942822601000074, -62.201474554999884],
            [109.985698602000156, -62.522151554999908],
            [110.196228603000094, -62.61251455499989],
            [110.200885603000273, -62.612244554999933],
            [110.275335603000229, -62.601627554999972],
            [110.298134602999966, -62.60029155499992],
            [110.432185603000022, -62.581027554999849],
            [110.552267603000104, -62.573800554999892],
            [110.602922603000053, -62.566430554999918],
            [110.674255603000034, -62.562057554999939],
            [110.738073603000288, -62.552701554999871],
            [110.773396603000123, -62.550504554999897],
            [110.807911603000065, -62.545419554999896],
            [110.909510603000143, -62.53902055499988],
            [111.109137603000278, -62.509233554999902],
            [111.134180604000136, -62.507604554999851],
            [111.170998604000289, -62.502064554999862],
            [111.249992604000084, -62.49687455499987],
            [111.282895604, -62.491891554999867],
            [112.523246605000139, -62.40380155499993],
            [113.77614660600014, -62.405266554999905],
            [113.794888606000114, -62.406731554999887],
            [113.911883606000032, -62.406261554999901],
            [115.151479607000084, -62.495985554999855],
            [116.351343608000292, -62.673268554999872],
            [116.35978460800024, -62.675264554999913],
            [116.364156608000172, -62.675887554999946],
            [117.023131609000046, -62.829218555999866],
            [117.27085360900017, -62.754339554999838],
            [118.424880610000201, -62.516842555000018],
            [118.45168461, -62.513604554999894],
            [118.46208661, -62.511398554999886],
            [119.673695611000227, -62.358651554999881],
            [120.916862613, -62.294228554999897],
            [120.918251613000194, -62.294263554999951],
            [120.940836613000357, -62.292984554999911],
            [122.18918761400019, -62.317990554999902],
            [122.211895613999985, -62.320211555],
            [122.240760613999981, -62.32064755499983],
            [123.468439615000221, -62.434240554999946],
            [123.566311615000103, -62.451338554999857],
            [123.605374615000329, -62.454723554999894],
            [124.787673616000092, -62.65366955499988],
            [124.803088616000281, -62.657679554999937],
            [124.810708616000198, -62.658917554999874],
            [125.447315617000328, -62.821942555999854],
            [126.121395617000047, -62.868320555999901],
            [126.133370617000224, -62.87012155599988],
            [126.179425618000067, -62.8731585559999],
            [126.237260618000022, -62.881818555999935],
            [126.262628618000207, -62.883472555999923],
            [126.26598461800009, -62.883973555999894],
            [126.315072618000187, -62.887141555999918],
            [126.746186618000053, -62.798971555999927],
            [126.791958618000223, -62.793492555999833],
            [126.804911618000091, -62.790755555999944],
            [128.021282619000289, -62.638280554999881],
            [128.045108619000274, -62.637166554999901],
            [128.055087619000147, -62.635859554999939],
            [129.304848620000286, -62.571093554999912],
            [130.019667621000025, -62.586595554999938],
            [130.206399621000259, -62.51918455499991],
            [131.300720622000057, -62.242713554999895],
            [131.330033622000172, -62.238003554999921],
            [131.341969622000278, -62.234902554999941],
            [132.506715623000133, -62.040904554999898],
            [133.716548625000172, -61.933975554999876],
            [133.851318625000175, -61.932626555],
            [133.891635625000021, -61.92880855499984],
            [135.120269626000237, -61.909597554999884],
            [135.121218626000228, -61.909657554999875],
            [135.147775626000197, -61.90911155499991],
            [136.370232627000206, -61.979444554999944],
            [137.560604628000164, -62.13809955499984],
            [137.572486628000291, -62.140727554999856],
            [137.609654628000015, -62.145475554999862],
            [138.742474629000043, -62.388611554999876],
            [139.790778630000176, -62.711339554999867],
            [139.811622630000187, -62.720316554999933],
            [139.816365630000206, -62.721740554999897],
            [140.654000631, -63.075253555999844],
            [141.067082631000034, -63.082352555999869],
            [142.324373633000135, -63.197227555999916],
            [142.329643633000302, -63.198133555999917],
            [142.330670633000153, -63.198221556],
            [143.541193634000052, -63.399409555999867],
            [143.89813263400012, -63.489984555999889],
            [144.144241634000167, -63.488444555999834],
            [144.44508163500015, -63.50967055599989],
            [144.687820635000065, -63.487891555999909],
            [144.732715635000176, -63.487383555999976],
            [144.740729635000349, -63.486653555999887],
            [145.235745635000143, -63.480035555999869],
            [145.309167635000307, -63.450347555999912],
            [146.395046636000103, -63.137384555999901],
            [146.402850636000238, -63.135876555999914],
            [146.405228636000203, -63.135173555999927],
            [147.574533637000258, -62.902188555999864],
            [148.80074763900015, -62.754331554999887],
            [150.05743164000026, -62.694762554999897],
            [151.318317641, -62.724742554999949],
            [151.330257641000202, -62.725948554999903],
            [151.341378641000205, -62.726158554999955],
            [152.580385642000209, -62.844833555999912],
            [153.771069643000118, -63.049886555999912],
            [154.886845644000203, -63.336884555999895],
            [155.900825645000197, -63.699485555999857],
            [156.469696646000244, -63.978069556999905],
            [157.149032646, -63.700337555999923],
            [158.234260647000298, -63.378046555999937],
            [158.24142564700017, -63.376603556],
            [158.272770646999987, -63.367052555999869],
            [159.443906648000024, -63.123654555999941],
            [160.674809650000128, -62.964731555999897],
            [161.938824651000317, -62.893650555999884],
            [163.209533652000317, -62.911889555999878],
            [164.46061565300019, -63.019035555999913],
            [164.466243653000078, -63.019972555999907],
            [164.472254653000078, -63.020456555999893],
            [165.677470654000132, -63.214108555999907],
            [166.810135655000266, -63.490197555999842],
            [167.843395656000183, -63.842655555999897],
            [168.750123657000103, -64.263526556999921],
            [168.753710657000084, -64.265862556999849],
            [168.761784657000049, -64.269522556999902],
            [168.83827565700031, -64.319209557],
            [169.263287658000223, -64.508918556999987],
            [170.263312659000206, -64.810468556999922],
            [170.278824659000094, -64.817013556999839],
            [170.290851659000253, -64.820550556999834],
            [171.271766660000338, -65.224063557999926],
            [172.101394660000295, -65.688613557999901],
            [172.113082660000117, -65.698088557999867],
            [172.203109660000194, -65.747132557999876],
            [172.229315660000253, -65.768224557999943],
            [172.253529660000225, -65.781348557999848],
            [172.316171660000151, -65.831518557999942],
            [172.330603660000378, -65.839298557999911],
            [172.989682660999989, -66.352679558999938],
            [173.440859662000179, -66.903416558999865],
            [173.44379166200028, -66.911395558999857],
            [173.445273662000176, -66.913157558999984],
            [173.662138662000103, -67.486601559999855],
            [173.657884662000214, -67.546414559999874],
            [173.666340662000351, -67.568072559999877],
            [173.625062662000374, -68.147925559999948],
            [173.607035662000243, -68.180677560999939],
            [173.845424662, -68.205196560999966],
            [173.85674666200029, -68.207133560999921],
            [173.878868662000144, -68.209286560999885],
            [175.313471663000286, -68.44537356099984],
            [176.651571665000318, -68.758857560999871],
            [177.861859666000299, -69.1433415609999],
            [177.880835666000252, -69.151656560999896],
            [177.903439666000025, -69.158618560999869],
            [178.954878667000202, -69.60568056199989],
            [178.956916667000229, -69.606903561999914],
            [179.011993667000269, -69.629641061999877],
            [179.152304667000266, -69.687241561999897],
            [179.806003667, -70.066853561999864],
            [179.958432668000142, -70.126458561999897],
            [179.999999668000413, -70.149989561999888],
            [179.999999668000413, -70.499999562999903],
            [179.999999668000413, -71.499999563999879],
            [179.999999668000413, -72.499999564999854],
            [179.999999668000413, -73.499999564999925],
            [179.999999668000413, -74.4999995659999],
            [179.999999668000413, -75.49999956699989],
            [179.999999668000413, -76.49999956799995],
            [179.999999668000413, -77.499999568999925],
            [179.999999668000413, -78.49999957],
            [179.999999668000413, -79.499999570999975],
            [179.999999668000413, -80.49999957199995],
            [179.999999668000413, -81.49999957299994],
            [179.999999668000413, -82.499999574],
            [179.999999668000413, -83.49999957499989],
            [180.000000001638426, -84.302244879039833],
            [180.000000335276383, -85.302244837915367],
            [180.000000335276383, -86.302244949208372],
            [180.000000335276383, -87.302244892863371],
            [180.000000335276383, -88.302244836518383],
            [180.000000335276383, -89.302244947811374],
            [180.000000335276383, -90.0],
            [179.000000391621398, -90.0],
            [178.000000280328408, -90.0],
            [177.000000336673423, -90.0],
            [176.000000393018382, -90.0],
            [175.00000028172505, -90.0],
            [174.000000338070407, -90.0],
            [173.000000394415196, -90.0],
            [172.000000283122375, -90.0],
            [171.000000339467391, -90.0],
            [170.000000395812123, -90.0],
            [169.000000284519302, -90.0],
            [168.000000340864261, -90.0],
            [167.000000397209106, -90.0],
            [166.000000285916116, -90.0],
            [165.000000342261245, -90.0],
            [164.00000039860609, -90.0],
            [163.0000002873131, -90.0],
            [162.000000343658286, -90.0],
            [161.000000400003245, -90.0],
            [160.000000288710083, -90.0],
            [159.00000034505527, -90.0],
            [158.000000233762051, -90.0],
            [157.000000290107067, -90.0],
            [156.000000346452083, -90.0],
            [155.000000235159092, -90.0],
            [154.000000291504051, -90.0],
            [153.000000347849067, -90.0],
            [152.000000236556076, -90.0],
            [151.000000292901035, -90.0],
            [150.000000349246051, -90.0],
            [149.00000023795306, -90.0],
            [148.000000294298019, -90.0],
            [147.000000350643035, -90.0],
            [146.000000239350044, -90.0],
            [145.00000029569506, -90.0],
            [144.000000352040018, -90.0],
            [143.000000240747028, -90.0],
            [142.000000297092043, -90.0],
            [141.000000353437002, -90.0],
            [140.000000242143955, -90.0],
            [139.000000298489027, -90.0],
            [138.000000354833986, -90.0],
            [137.000000243540825, -90.0],
            [136.000000299886011, -90.0],
            [135.000000356231027, -90.0],
            [134.000000244937809, -90.0],
            [133.000000301282995, -90.0],
            [132.000000357628011, -90.0],
            [131.000000246334793, -90.0],
            [130.000000302679979, -90.0],
            [129.000000359024995, -90.0],
            [128.000000247731833, -90.0],
            [127.000000304076792, -90.0],
            [126.000000360421978, -90.0],
            [125.000000249128817, -90.0],
            [124.000000305473776, -90.0],
            [123.000000361818962, -90.0],
            [122.000000250525801, -90.0],
            [121.00000030687076, -90.0],
            [120.00000019557794, -90.0],
            [119.000000251922785, -90.0],
            [118.000000308267744, -90.0],
            [117.000000196974923, -90.0],
            [116.000000253319769, -90.0],
            [115.000000309664784, -90.0],
            [114.000000198371964, -90.0],
            [113.000000254716923, -90.0],
            [112.000000311061768, -90.0],
            [111.000000199768948, -90.0],
            [110.000000256113907, -90.0],
            [109.000000312458752, -90.0],
            [108.000000201165761, -90.0],
            [107.000000257510891, -90.0],
            [106.000000313855736, -90.0],
            [105.000000202562745, -90.0],
            [104.000000258907932, -90.0],
            [103.00000031525289, -90.0],
            [102.000000203959729, -90.0],
            [101.000000260304915, -90.0],
            [100.000000316649874, -90.0],
            [99.000000205356713, -90.0],
            [98.000000261701729, -90.0],
            [97.000000318046858, -90.0],
            [96.000000206753697, -90.0],
            [95.000000263098713, -90.0],
            [94.000000319443899, -90.0],
            [93.000000208150681, -90.0],
            [92.000000264495696, -90.0],
            [91.000000320840883, -90.0],
            [90.000000209547665, -90.0],
            [89.00000026589268, -90.0],
            [88.000000322237867, -90.0],
            [87.000000210944705, -90.0],
            [86.000000267289664, -90.0],
            [85.00000032363468, -90.0],
            [84.000000212341689, -90.0],
            [83.000000268686648, -90.0],
            [82.000000325031664, -90.0],
            [81.000000213738673, -90.0],
            [80.000000270083632, -90.0],
            [79.000000158790471, -90.0],
            [78.000000215135657, -90.0],
            [77.000000271480673, -90.0],
            [76.000000160187454, -90.0],
            [75.00000021653247, -90.0],
            [74.000000272877656, -90.0],
            [73.000000161584438, -90.0],
            [72.000000217929454, -90.0],
            [71.00000027427464, -90.0],
            [70.000000162981422, -90.0],
            [69.000000219326438, -90.0],
            [68.000000275671454, -90.0],
            [67.000000164378463, -90.0],
            [66.000000220723422, -90.0],
            [65.000000277068438, -90.0],
            [64.000000165775447, -90.0],
            [63.000000222120406, -90.0],
            [62.000000278465421, -90.0],
            [61.000000167172431, -90.0],
            [60.000000223517389, -90.0],
            [59.000000279862405, -90.0],
            [58.000000168569414, -90.0],
            [57.00000022491443, -90.0],
            [56.000000281259389, -90.0],
            [55.000000169966512, -90.0],
            [54.000000226311414, -90.0],
            [53.000000282656373, -90.0],
            [52.000000171363496, -90.0],
            [51.000000227708398, -90.0],
            [50.000000284053357, -90.0],
            [49.00000017276048, -90.0],
            [48.000000229105382, -90.0],
            [47.000000285450398, -90.0],
            [46.000000174157464, -90.0],
            [45.000000230502479, -90.0],
            [44.000000286847381, -90.0],
            [43.000000175554447, -90.0],
            [42.000000231899463, -90.0],
            [41.000000288244365, -90.0],
            [40.000000176951374, -90.0],
            [39.000000233296447, -90.0],
            [38.000000122003343, -90.0],
            [37.000000178348358, -90.0],
            [36.000000234693431, -90.0],
            [35.000000123400326, -90.0],
            [34.000000179745342, -90.0],
            [33.000000236090415, -90.0],
            [32.00000012479731, -90.0],
            [31.000000181142326, -90.0],
            [30.000000237487342, -90.0],
            [29.000000126194351, -90.0],
            [28.00000018253931, -90.0],
            [27.000000238884326, -90.0],
            [26.000000127591306, -90.0],
            [25.000000183936123, -90.0],
            [24.00000024028131, -90.0],
            [23.00000012898829, -90.0],
            [22.000000185333107, -90.0],
            [21.000000241678379, -90.0],
            [20.000000130385104, -90.0],
            [19.00000018673029, -90.0],
            [18.000000243075363, -90.0],
            [17.000000131782116, -90.0],
            [16.000000188127274, -90.0],
            [15.000000244472261, -90.0],
            [14.0000001331791, -90.0],
            [13.000000189524258, -90.0],
            [12.000000245869273, -90.0],
            [11.000000134576169, -90.0],
            [10.000000190921071, -90.0],
            [9.000000247266257, -90.0],
            [8.000000135973067, -90.0],
            [7.000000192318083, -90.0],
            [6.000000248663241, -90.0],
            [5.00000013737008, -90.0],
            [4.000000193715067, -90.0],
            [3.000000250060225, -90.0],
            [2.000000138767064, -90.0],
            [1.000000195112136, -90.0],
            [0.00000008381906, -90.0],
            [-0.999999859835953, -90.0],
            [-1.999999803490965, -90.0],
            [-2.999999914783871, -90.0],
            [-3.999999858438969, -90.0],
            [-4.999999802093953, -90.0],
            [-5.999999913386972, -90.0],
            [-6.999999857041871, -90.0],
            [-7.999999800696969, -90.0],
            [-8.99999991198996, -90.0],
            [-9.999999855644887, -90.0],
            [-10.999999799299985, -90.0],
            [-11.999999910592976, -90.0],
            [-12.999999854247903, -90.0],
            [-13.999999797903001, -90.0],
            [-14.999999909195992, -90.0],
            [-15.999999852851005, -90.0],
            [-16.999999796505904, -90.0],
            [-17.999999907799008, -90.0],
            [-18.999999851453993, -90.0],
            [-19.99999979510892, -90.0],
            [-20.99999990640211, -90.0],
            [-21.999999850057009, -90.0],
            [-22.999999793711936, -90.0],
            [-23.999999905005097, -90.0],
            [-24.999999848660025, -90.0],
            [-25.999999792314952, -90.0],
            [-26.999999903608114, -90.0],
            [-27.999999847263041, -90.0],
            [-28.999999790918054, -90.0],
            [-29.99999990221113, -90.0],
            [-30.999999845866142, -90.0],
            [-31.999999789521041, -90.0],
            [-32.999999900814146, -90.0],
            [-33.99999984446913, -90.0],
            [-34.999999788124057, -90.0],
            [-35.999999899417048, -90.0],
            [-36.999999843072146, -90.0],
            [-37.999999786727074, -90.0],
            [-38.999999898020064, -90.0],
            [-39.999999841675162, -90.0],
            [-40.999999952968153, -90.0],
            [-41.999999896623166, -90.0],
            [-42.999999840278178, -90.0],
            [-43.999999951571169, -90.0],
            [-44.999999895226182, -90.0],
            [-45.999999838881195, -90.0],
            [-46.999999950174185, -90.0],
            [-47.999999893829198, -90.0],
            [-48.999999837484097, -90.0],
            [-49.999999948777287, -90.0],
            [-50.999999892432186, -90.0],
            [-51.999999836087113, -90.0],
            [-52.999999947380275, -90.0],
            [-53.999999891035202, -90.0],
            [-54.999999834690215, -90.0],
            [-55.999999945983291, -90.0],
            [-56.999999889638218, -90.0],
            [-57.999999833293231, -90.0],
            [-58.999999944586307, -90.0],
            [-59.999999888241319, -90.0],
            [-60.999999831896218, -90.0],
            [-61.999999943189323, -90.0],
            [-62.999999886844307, -90.0],
            [-63.999999830499235, -90.0],
            [-64.999999941792424, -90.0],
            [-65.999999885447323, -90.0],
            [-66.999999829102251, -90.0],
            [-67.999999940395327, -90.0],
            [-68.999999884050339, -90.0],
            [-69.999999827705352, -90.0],
            [-70.999999938998343, -90.0],
            [-71.999999882653356, -90.0],
            [-72.999999826308368, -90.0],
            [-73.999999937601359, -90.0],
            [-74.999999881256457, -90.0],
            [-75.999999824911356, -90.0],
            [-76.999999936204375, -90.0],
            [-77.999999879859359, -90.0],
            [-78.999999823514372, -90.0],
            [-79.999999934807363, -90.0],
            [-80.999999878462376, -90.0],
            [-81.999999989755452, -90.0],
            [-82.999999933410379, -90.0],
            [-83.999999877065392, -90.0],
            [-84.999999988358468, -90.0],
            [-85.999999932013395, -90.0],
            [-86.999999875668408, -90.0],
            [-87.999999986961484, -90.0],
            [-88.999999930616497, -90.0],
            [-89.999999874271396, -90.0],
            [-90.9999999855645, -90.0],
            [-91.999999929219513, -90.0],
            [-92.999999872874412, -90.0],
            [-93.999999984167516, -90.0],
            [-94.9999999278225, -90.0],
            [-95.999999871477428, -90.0],
            [-96.999999982770419, -90.0],
            [-97.999999926425517, -90.0],
            [-98.999999870080529, -90.0],
            [-99.999999981373435, -90.0],
            [-100.999999925028533, -90.0],
            [-101.999999868683531, -90.0],
            [-102.999999979976536, -90.0],
            [-103.999999923631449, -90.0],
            [-104.999999867286547, -90.0],
            [-105.999999978579538, -90.0],
            [-106.999999922234451, -90.0],
            [-107.999999865889549, -90.0],
            [-108.999999977182554, -90.0],
            [-109.999999920837467, -90.0],
            [-110.999999864492565, -90.0],
            [-111.999999975785556, -90.0],
            [-112.999999919440569, -90.0],
            [-113.999999863095482, -90.0],
            [-114.999999974388572, -90.0],
            [-115.999999918043571, -90.0],
            [-116.999999861698498, -90.0],
            [-117.999999972991674, -90.0],
            [-118.999999916646587, -90.0],
            [-120.000000027939677, -90.0],
            [-120.999999971594676, -90.0],
            [-121.999999915249589, -90.0],
            [-123.000000026542594, -90.0],
            [-123.999999970197692, -90.0],
            [-124.999999913852605, -90.0],
            [-126.000000025145596, -90.0],
            [-126.999999968800694, -90.0],
            [-127.999999912455607, -90.0],
            [-129.000000023748697, -90.0],
            [-129.99999996740371, -90.0],
            [-130.999999911058694, -90.0],
            [-132.000000022351713, -90.0],
            [-132.999999966006612, -90.0],
            [-133.99999990966171, -90.0],
            [-135.000000020954701, -90.0],
            [-135.999999964609628, -90.0],
            [-136.999999908264726, -90.0],
            [-138.000000019557717, -90.0],
            [-138.999999963212815, -90.0],
            [-139.999999906867743, -90.0],
            [-141.000000018160733, -90.0],
            [-141.999999961815746, -90.0],
            [-142.999999905470645, -90.0],
            [-144.000000016763749, -90.0],
            [-144.999999960418847, -90.0],
            [-145.999999904073661, -90.0],
            [-147.000000015366851, -90.0],
            [-147.99999995902175, -90.0],
            [-148.999999902676848, -90.0],
            [-150.000000013969839, -90.0],
            [-150.999999957624766, -90.0],
            [-151.999999901279779, -90.0],
            [-153.000000012572855, -90.0],
            [-153.999999956227782, -90.0],
            [-154.999999899882766, -90.0],
            [-156.000000011175786, -90.0],
            [-156.999999954830798, -90.0],
            [-157.999999898485783, -90.0],
            [-159.000000009778773, -90.0],
            [-159.999999953433871, -90.0],
            [-161.000000064726891, -90.0],
            [-162.000000008381789, -90.0],
            [-162.999999952036887, -90.0],
            [-164.000000063329992, -90.0],
            [-165.000000006984806, -90.0],
            [-165.999999950639818, -90.0],
            [-167.000000061932894, -90.0],
            [-168.000000005587907, -90.0],
            [-168.999999949242834, -90.0],
            [-170.000000060535911, -90.0],
            [-171.000000004190923, -90.0],
            [-171.999999947845936, -90.0],
            [-173.000000059138927, -90.0],
            [-174.000000002793939, -90.0],
            [-174.999999946448838, -90.0],
            [-176.000000057741943, -90.0],
            [-177.000000001396927, -90.0],
            [-177.99999994505194, -90.0],
            [-179.000000056345016, -90.0],
            [-180.0, -90.0],
            [-180.0, -89.000000056344959],
            [-180.0, -87.999999945051869],
            [-180.0, -87.000000001396955],
            [-180.0, -86.000000057742042],
            [-180.0, -84.999999946448867],
            [-179.999999833999937, -84.305344867220299],
            [-179.99999966799993, -83.49999957499989],
            [-179.99999966799993, -82.499999574],
            [-179.99999966799993, -81.49999957299994],
            [-179.99999966799993, -80.49999957199995],
            [-179.99999966799993, -79.499999570999975],
            [-179.99999966799993, -78.49999957],
            [-179.99999966799993, -77.499999568999925],
            [-179.99999966799993, -76.49999956799995],
            [-179.99999966799993, -75.49999956699989],
            [-179.99999966799993, -74.4999995659999],
            [-179.99999966799993, -73.499999564999925],
            [-179.99999966799993, -72.499999564999854],
            [-179.99999966799993, -71.499999563999879],
            [-179.99999966799993, -70.499999562999903],
            [-179.999868167999864, -70.149989561999888],
            [-179.997273667999877, -70.151373562],
            [-179.994809667999931, -70.152757562000033],
            [-179.992345667999899, -70.154141561999879],
            [-179.989880667999955, -70.155524561999911],
            [-179.987415667999926, -70.156908561999927],
            [-179.984950667999897, -70.158292561999957],
            [-179.98248466799987, -70.159675561999904],
            [-179.980018668000014, -70.161059561999849],
            [-179.977552668000016, -70.162443561999879],
            [-179.975085667999906, -70.163826561999912],
            [-179.972619667999879, -70.165210561999928],
            [-179.970151667999858, -70.166593561999875],
            [-179.967684667999947, -70.167977561999891],
            [-179.965216667999925, -70.169360561999923],
            [-179.96274766800002, -70.170744561999953],
            [-179.960279667999913, -70.172127561999901],
            [-179.957810667999979, -70.173510561999848],
            [-179.955341667999903, -70.17489356199988],
            [-179.952871668, -70.176277562],
            [-179.950401667999898, -70.177660562000028],
            [-179.94793166799991, -70.17904356199989],
            [-179.94546066800001, -70.180426561999923],
            [-179.942989668, -70.181809561999856],
            [-179.940518667999925, -70.183192561999888],
            [-179.938046667999942, -70.184575561999921],
            [-179.935574667999958, -70.185958561999868],
            [-179.933102667999975, -70.187341561999901],
            [-179.930629668, -70.188724561999933],
            [-179.928156668000014, -70.190107561999966],
            [-179.925683668000033, -70.191490562],
            [-179.92320966799997, -70.192873562000031],
            [-179.920735667999907, -70.194255561999881],
            [-179.918261667999928, -70.195638561999914],
            [-179.915786667999953, -70.197021561999946],
            [-179.913311667999892, -70.198404561999894],
            [-179.910836668, -70.199786562000028],
            [-179.908360667999887, -70.201169561999876],
            [-179.905884667999914, -70.202551561999911],
            [-179.90340866799994, -70.203934561999944],
            [-179.900931667999913, -70.205316562],
            [-179.898454667999857, -70.206699562000026],
            [-179.895977667999915, -70.208081561999876],
            [-179.893499667999862, -70.209464561999908],
            [-179.891021667999922, -70.210846561999958],
            [-179.888543667999954, -70.212228561999908],
            [-179.886064667999932, -70.213610561999943],
            [-179.88358566799991, -70.21499356199989],
            [-179.881106667999859, -70.21637556199984],
            [-179.878626667999924, -70.217757561999875],
            [-179.876146667999905, -70.219139562],
            [-179.873665667999887, -70.220521562000044],
            [-179.871185667999953, -70.221903561999909],
            [-179.868704668000021, -70.223285561999859],
            [-179.866222668, -70.224667561999894],
            [-179.863740668, -70.226049561999929],
            [-179.861258667999977, -70.227431561999879],
            [-179.858776667999962, -70.228813561999914],
            [-179.85629366799995, -70.230195561999864],
            [-179.853810667999937, -70.231577561999899],
            [-179.851327666999936, -70.232958561999865],
            [-179.848843666999926, -70.2343405619999],
            [-179.846359666999916, -70.23572256199995],
            [-179.843874667000023, -70.237104561999899],
            [-179.841390666999928, -70.238485561999937],
            [-179.838904667, -70.239867561999887],
            [-179.836419666999944, -70.241248561999925],
            [-179.83393366699994, -70.24263056199996],
            [-179.831447666999964, -70.244011561999926],
            [-179.828961666999959, -70.245393561999876],
            [-179.8264746669999, -70.246774561999928],
            [-179.823987666999983, -70.248156561999963],
            [-179.821499666999927, -70.249537561999915],
            [-179.819011666999955, -70.250918561999953],
            [-179.816523666999956, -70.252299562],
            [-179.814034666999987, -70.253681561999855],
            [-179.811546666999931, -70.255062561999907],
            [-179.809056666999879, -70.256443561999959],
            [-179.806567666999911, -70.257824561999911],
            [-179.804077666999945, -70.259205561999948],
            [-179.801587666999893, -70.260586562],
            [-179.79909666699993, -70.261967561999867],
            [-179.796605666999881, -70.263348561999919],
            [-179.794114666999917, -70.264729561999957],
            [-179.791623666999953, -70.266110561999909],
            [-179.789131666999907, -70.267491561999947],
            [-179.786638666999863, -70.268872561999913],
            [-179.784146666999931, -70.270253561999866],
            [-179.781653666999887, -70.27163356199992],
            [-179.779160666999928, -70.273014561999872],
            [-179.776666666999915, -70.27439556199991],
            [-179.774172666999959, -70.275775561999879],
            [-179.771678667000032, -70.277156561999931],
            [-179.769183666999908, -70.278537561999883],
            [-179.766688666999983, -70.279917562000023],
            [-179.764193666999944, -70.28129856199989],
            [-179.761697666999936, -70.282678561999859],
            [-179.7592016669999, -70.284058561999899],
            [-179.756705666999892, -70.285439561999937],
            [-179.754208666999887, -70.286819561999906],
            [-179.751711666999967, -70.288200562000043],
            [-179.749214666999933, -70.289580561999912],
            [-179.74671666699993, -70.290960561999867],
            [-179.744218666999927, -70.292340561999836],
            [-179.741720666999925, -70.293720561999891],
            [-179.739221666999924, -70.295101561999928],
            [-179.736722666999924, -70.296481561999897],
            [-179.734223666999924, -70.297861562999941],
            [-179.731723666999926, -70.29924156299991],
            [-179.729223666999928, -70.300621562999879],
            [-179.726722666999933, -70.302001562999919],
            [-179.724222666999964, -70.303381562999874],
            [-179.721720666999886, -70.304761562999843],
            [-179.719219666999891, -70.3061405629999],
            [-179.716717667000012, -70.307520562999954],
            [-179.714215666999934, -70.308900562999924],
            [-179.711713666999856, -70.310280562999878],
            [-179.709210666999894, -70.311659562999935],
            [-179.706707666999904, -70.313039562999904],
            [-179.704203666999945, -70.314419562999859],
            [-179.701699666999957, -70.315798562999916],
            [-179.699195666999913, -70.31717856299997],
            [-179.696691667000039, -70.318557562999928],
            [-179.694186666999883, -70.319937562999897],
            [-179.691680666999929, -70.321316562999954],
            [-179.689175666999887, -70.322696562999909],
            [-179.686669667000018, -70.32407556299988],
            [-179.68416366699995, -70.325455562999849],
            [-179.681656666999913, -70.326834562999892],
            [-179.679149666999876, -70.328213562999963],
            [-179.676642666999925, -70.32959256299992],
            [-179.67413466699989, -70.330972562999889],
            [-179.671626666999856, -70.332351562999946],
            [-179.669118666999907, -70.333730563],
            [-179.666610666999873, -70.335109562999889],
            [-179.66410166700004, -70.336488562999932],
            [-179.661591666999925, -70.337867562999904],
            [-179.659082666999893, -70.339246562999875],
            [-179.656572666999864, -70.340625562999932],
            [-179.654061666999951, -70.342004562999904],
            [-179.651551666999922, -70.343383562999946],
            [-179.649040666999895, -70.34476156300002],
            [-179.646528666999899, -70.346140562999892],
            [-179.64401666699996, -70.347519562999864],
            [-179.641504666999879, -70.348898562999921],
            [-179.638992666999854, -70.350276562999895],
            [-179.636479666999946, -70.351655562999866],
            [-179.633966666999953, -70.353034562999923],
            [-179.631453666999846, -70.354412562999897],
            [-179.628939666999941, -70.355791562999954],
            [-179.62642566699995, -70.357169562999928],
            [-179.623910666999961, -70.358548562999985],
            [-179.621395666999945, -70.359926562999959],
            [-179.618880666999871, -70.361305563000016],
            [-179.616365666999883, -70.362683562999905],
            [-179.613849666999897, -70.364061562999865],
            [-179.611333666999911, -70.365440562999851],
            [-179.608816666999928, -70.366818562999896],
            [-179.60629966699986, -70.36819656299997],
            [-179.603782666999962, -70.369574562999944],
            [-179.601265667, -70.370952562999918],
            [-179.598747666999941, -70.372330562999892],
            [-179.596228666999878, -70.373708562999951],
            [-179.593710666999982, -70.375087562999923],
            [-179.591191666999947, -70.376465562999897],
            [-179.588671666999886, -70.377842562999874],
            [-179.586152666999908, -70.379220562999848],
            [-179.583632666999875, -70.380598562999921],
            [-179.5811126669999, -70.381976562999881],
            [-179.578591666999955, -70.38335456299987],
            [-179.576070666999897, -70.384732562999915],
            [-179.573548666999955, -70.386109562999906],
            [-179.571027666999925, -70.38748756299988],
            [-179.568505666999954, -70.388865562999939],
            [-179.565982666999929, -70.390242563000015],
            [-179.563460666999902, -70.39162056299989],
            [-179.560936666999879, -70.392998562999878],
            [-179.558413667000025, -70.39437556299994],
            [-179.555889666999974, -70.395753562999914],
            [-179.553365666999866, -70.397130562999891],
            [-179.550841666999929, -70.398507562999868],
            [-179.548316667, -70.399885562999856],
            [-179.545791666999889, -70.401262562999918],
            [-179.543265666999957, -70.402639562999894],
            [-179.540739666999968, -70.404017562999869],
            [-179.538213666999951, -70.40539456299993],
            [-179.535687666999934, -70.406771562999921],
            [-179.533160666999919, -70.408148562999898],
            [-179.53063266700002, -70.40952556299996],
            [-179.52810566699992, -70.410902562999951],
            [-179.525577666999908, -70.412279562999927],
            [-179.523049667, -70.413656562999904],
            [-179.520520666999914, -70.415033562999895],
            [-179.517991667000018, -70.416410562999872],
            [-179.515462666999923, -70.417787562999848],
            [-179.512932666999944, -70.419164562999924],
            [-179.510402666999852, -70.420541562999901],
            [-179.507872666999958, -70.421918562999878],
            [-179.505341666999982, -70.423294562999871],
            [-179.502810666999892, -70.424671562999848],
            [-179.500278666999918, -70.42604856299991],
            [-179.497747666999942, -70.427424562999903],
            [-179.495215666999883, -70.42880156299988],
            [-179.492682666999912, -70.430177562999958],
            [-179.49014966699994, -70.431554562999935],
            [-179.487616666999969, -70.432930563000014],
            [-179.485083667, -70.434307562999905],
            [-179.482549666999944, -70.435683562999884],
            [-179.48001566699989, -70.43706056299996],
            [-179.477480666999924, -70.438436562999954],
            [-179.474945666999957, -70.439812562999933],
            [-179.472410666999906, -70.441188562999912],
            [-179.46987566699994, -70.442565562999903],
            [-179.467339666999919, -70.443941562999882],
            [-179.464802666999873, -70.445317562999861],
            [-179.462266667000023, -70.446693562999855],
            [-179.459729666999891, -70.448069562999848],
            [-179.457191666999847, -70.449445562999927],
            [-179.454654666999915, -70.450821562999906],
            [-179.452116666999871, -70.452197562999885],
            [-179.449577666999943, -70.453573562999878],
            [-179.447039666999927, -70.454949562999872],
            [-179.444500666999886, -70.456325562999851],
            [-179.441960666999876, -70.457701562999915],
            [-179.439421666999948, -70.459076562999911],
            [-179.43688066699994, -70.460452562999905],
            [-179.434340666999901, -70.461828562999898],
            [-179.431799666999893, -70.463204562999877],
            [-179.429258666999885, -70.464579562999958],
            [-179.426717666999878, -70.465955562999937],
            [-179.424175666999957, -70.467330562999933],
            [-179.421633666999952, -70.468706562999927],
            [-179.419090666999864, -70.470081563],
            [-179.416547666999861, -70.471457562999902],
            [-179.414004666999858, -70.472832562999983],
            [-179.411460666999886, -70.474208562999877],
            [-179.408916666999886, -70.475583562999958],
            [-179.406372666999886, -70.476958562999954],
            [-179.403827667, -70.47833356299995],
            [-179.401282666999919, -70.479709562999929],
            [-179.398737666999921, -70.481084562999925],
            [-179.396191666999954, -70.482459563],
            [-179.393645666999873, -70.483834562999903],
            [-179.391099666999907, -70.485209562999898],
            [-179.388552667, -70.486584562999894],
            [-179.386005666999949, -70.48795956299989],
            [-179.383458666999957, -70.489334562999886],
            [-179.38091066699991, -70.490709562999868],
            [-179.378362666999948, -70.492084562999864],
            [-179.375813666999903, -70.493459562999945],
            [-179.373264667000029, -70.494834563000026],
            [-179.370715666999956, -70.496208563000025],
            [-179.368166666999912, -70.497583562999921],
            [-179.365616666999955, -70.498958562999917],
            [-179.363066666999913, -70.500332562999915],
            [-179.360515666999959, -70.501707562999911],
            [-179.357964666999919, -70.503082562999907],
            [-179.35541366699988, -70.504456562999906],
            [-179.352861667000013, -70.505831562999902],
            [-179.350309667, -70.5072055629999],
            [-179.347757666999883, -70.508580562999882],
            [-179.345204666999933, -70.50995456299988],
            [-179.342651666999984, -70.511328562999878],
            [-179.340098666999978, -70.512703562999874],
            [-179.337544666999946, -70.514077562999873],
            [-179.334990667, -70.515451562999871],
            [-179.332435666999913, -70.51682556299987],
            [-179.329881666999881, -70.518200562999851],
            [-179.327325666999883, -70.51957456299985],
            [-179.324770666999939, -70.520948562999848],
            [-179.32221466699994, -70.522322562999946],
            [-179.319658666999942, -70.523696562999945],
            [-179.317101667000031, -70.525070562999943],
            [-179.314544667, -70.526444562999941],
            [-179.311987666999926, -70.52781856299994],
            [-179.30943066699993, -70.529192562999938],
            [-179.306872666999936, -70.530565562999939],
            [-179.304313666999946, -70.531939562999938],
            [-179.301755667000037, -70.533313562999936],
            [-179.299196666999933, -70.534687562999935],
            [-179.296636666999973, -70.53606056299995],
            [-179.294076666999899, -70.537434563000033],
            [-179.291516666999911, -70.538808562999947],
            [-179.288956666999923, -70.540181563000033],
            [-179.286395666999937, -70.541555562999946],
            [-179.283834666999951, -70.542928562999947],
            [-179.281272666999911, -70.544302562999945],
            [-179.278711666999925, -70.545675562999946],
            [-179.276148666999859, -70.547048562999947],
            [-179.273586666999904, -70.548422562999946],
            [-179.271023666999923, -70.549795562999947],
            [-179.268460666999971, -70.551168562999962],
            [-179.265896666999907, -70.55254256299996],
            [-179.263332666999958, -70.553915562999876],
            [-179.260768666999979, -70.555288562999877],
            [-179.258203666999947, -70.556661562999892],
            [-179.255638666999914, -70.558034562999978],
            [-179.253073666999853, -70.559407562999894],
            [-179.250507666999908, -70.560780562999895],
            [-179.247941666999878, -70.562153562999896],
            [-179.245375666999934, -70.563526562999897],
            [-179.242808666999906, -70.564899562999912],
            [-179.240241666999964, -70.566272562999913],
            [-179.237673666999939, -70.567645562999928],
            [-179.235105666999914, -70.569017562999932],
            [-179.232537666999889, -70.570390562999847],
            [-179.229969666999949, -70.571763562999848],
            [-179.227400666999927, -70.573136562999849],
            [-179.224830666999907, -70.574508562999867],
            [-179.222261666999884, -70.575881562999868],
            [-179.219691666999978, -70.577253562999886],
            [-179.217120666999875, -70.578626562999887],
            [-179.214550666999855, -70.57999856299989],
            [-179.211979666999952, -70.58137156299999],
            [-179.209407666999937, -70.582743562999909],
            [-179.206836667000033, -70.584116562999924],
            [-179.204263666999935, -70.585488562999927],
            [-179.201691666999949, -70.586860562999931],
            [-179.199118666999851, -70.588232562999949],
            [-179.196545666999953, -70.589605562999864],
            [-179.193972666999855, -70.590977562999882],
            [-179.191398666999874, -70.592349562999885],
            [-179.188823666999895, -70.593721562999889],
            [-179.186249666999913, -70.595093562999907],
            [-179.183674666999934, -70.596465562999924],
            [-179.181099667000041, -70.597837562999928],
            [-179.178523666999865, -70.599209562999931],
            [-179.175947666999889, -70.600581562999849],
            [-179.173371666999913, -70.601953562999867],
            [-179.170794666999939, -70.603325562999885],
            [-179.168217667, -70.604696562999891],
            [-179.165639666999937, -70.606068562999909],
            [-179.163062666999878, -70.607440562999926],
            [-179.160483666999909, -70.60881256299993],
            [-179.157905666999852, -70.610183562999936],
            [-179.155326666999912, -70.611555562999868],
            [-179.152747666999943, -70.612926562999974],
            [-179.150167666999891, -70.614298562999892],
            [-179.147587666999954, -70.615669562999912],
            [-179.145007666999902, -70.61704156299993],
            [-179.142427666999879, -70.61841256299985],
            [-179.139846666999915, -70.619784562999854],
            [-179.137264666999897, -70.621155562999874],
            [-179.130694666999943, -70.626553562999931],
            [-179.121983666999938, -70.631177562999895],
            [-178.447803665999913, -71.168208562999894],
            [-178.041379665999926, -71.734264563999886],
            [-178.029933665999948, -71.800905563999891],
            [-178.017757665999852, -71.81725456399991],
            [-177.913799665999903, -72.39703656399989],
            [-178.147910665999973, -72.973522564999911],
            [-178.473022665999849, -73.279379564999857],
            [-178.363890665999975, -73.417474564999907],
            [-178.363729665999898, -73.418408564999922],
            [-178.359786665999877, -73.423354564999883],
            [-178.256021665999981, -74.003182565999936],
            [-178.260560665999947, -74.013259565999974],
            [-178.248556665999899, -74.077638565999862],
            [-178.489585665999925, -74.594852566999947],
            [-177.805119665999939, -74.650133566999898],
            [-177.797423665999929, -74.651153566999909],
            [-177.734946665999928, -74.656073566999936],
            [-177.634958664999914, -74.669253566999856],
            [-177.576358664999987, -74.673817566999929],
            [-177.367365664999937, -74.701073566999924],
            [-177.362604665000021, -74.701438566999926],
            [-177.33883866499994, -74.704518566999951],
            [-177.335543664999904, -74.704770566999926],
            [-177.307638664999928, -74.708382566999916],
            [-177.302587664999976, -74.708767566999882],
            [-176.305937664, -74.834398566999852],
            [-175.293231662999858, -74.955567566999918],
            [-174.765288662999893, -75.045093566999924],
            [-174.060296661999871, -75.052386566999928],
            [-174.052330661999889, -75.052801566999889],
            [-173.983473661999938, -75.053238566999894],
            [-173.324620660999926, -75.086373566999868],
            [-172.554366660999989, -75.087620566999931],
            [-172.530698661000031, -75.088690567],
            [-172.401880660999893, -75.088632566999905],
            [-172.358197660999906, -75.090575566999917],
            [-172.343871660999923, -75.090562567],
            [-172.05663766, -75.103101566999982],
            [-172.05359166, -75.103094566999943],
            [-172.024266659999967, -75.104352566999935],
            [-170.91402265900004, -75.099022567],
            [-170.538074658999903, -75.112950566999899],
            [-170.508791658999883, -75.112688566999907],
            [-170.340478658999899, -75.118694566999906],
            [-169.85111665799991, -75.113414566999921],
            [-169.54929065799999, -75.010877566999952],
            [-169.546379658, -75.010177566999943],
            [-169.536588657999914, -75.00682056699992],
            [-169.098088656999892, -74.896807566999897],
            [-167.936723655999884, -74.605439567],
            [-167.85516965599993, -74.590517565999917],
            [-167.848524656, -74.588775565999939],
            [-166.088409654999907, -74.251929565999859],
            [-166.055907655, -74.247562565999843],
            [-166.033971654999903, -74.243175565999849],
            [-164.149905652999848, -73.976982565999919],
            [-164.138340652999887, -73.975932565999898],
            [-164.116385652999924, -73.972677565999874],
            [-162.142299650999945, -73.781793565999919],
            [-162.131240651000013, -73.781239566],
            [-162.117567650999888, -73.779836565999915],
            [-161.098138650000038, -73.726105565999873],
            [-160.085581648999892, -73.667470565999878],
            [-160.06433264899988, -73.667246565999918],
            [-160.055832648999854, -73.666730565999856],
            [-159.393384647999937, -73.658625566],
            [-158.895017647999879, -73.567064565999914],
            [-158.87368364799994, -73.564462566000017],
            [-158.788022647999981, -73.548488565999932],
            [-158.703505647999918, -73.538100565999912],
            [-158.681812647999948, -73.534029565999873],
            [-156.830012646, -73.294060564999981],
            [-156.805673645999946, -73.292144564999901],
            [-156.773070645999951, -73.287702564999904],
            [-156.760431645999944, -73.286703564999925],
            [-156.737313645999933, -73.283547564999836],
            [-156.706365645999881, -73.281093564999907],
            [-156.656457645999978, -73.274256564999902],
            [-154.730721643999885, -73.110254564999906],
            [-154.704727643999945, -73.109280564999864],
            [-154.686339643999872, -73.107608564999907],
            [-154.608531643999868, -73.104665564999863],
            [-154.573830644, -73.101489564999881],
            [-152.921482642, -73.031367564999897],
            [-151.837643641, -72.850580564999944],
            [-150.010444639999946, -72.642203564999903],
            [-150.008637639999932, -72.642089564999878],
            [-149.955413639999904, -72.63569156499986],
            [-148.991993638999929, -72.572196564999928],
            [-147.657107637999871, -72.188350563999933],
            [-146.113768635999946, -71.855688563999834],
            [-144.455495634999892, -71.596643563999876],
            [-144.445875635, -71.595698564000031],
            [-144.444240635, -71.595431563999881],
            [-142.704431632999871, -71.414617563999911],
            [-140.914315630999852, -71.315331562999887],
            [-139.104287629999902, -71.299285562999884],
            [-138.531787628999894, -71.322701562999868],
            [-138.33424762899989, -71.31925856299992],
            [-138.292480628999982, -71.320808563],
            [-137.207603627999987, -71.143029562999885],
            [-135.511078625999971, -70.960034562999937],
            [-135.50204962600003, -70.959557562999919],
            [-135.481363625999904, -70.957205563],
            [-133.733620624999844, -70.855776562999949],
            [-133.129770623999946, -70.851660562999911],
            [-132.240125622999869, -70.587623563],
            [-132.235902622999873, -70.58673356299991],
            [-132.230453622999846, -70.58508056299992],
            [-130.779324621999876, -70.268435561999894],
            [-129.224709619999828, -70.027706561999921],
            [-127.598097618999915, -69.867491561999913],
            [-127.594926618999921, -69.8673615619999],
            [-127.574213619000034, -69.865206561999941],
            [-125.906209616999973, -69.788280561999926],
            [-125.900908616999857, -69.788331561999883],
            [-125.88938261699991, -69.787741561999908],
            [-124.209307615999933, -69.795601561999916],
            [-122.546508613999848, -69.888282561999944],
            [-122.373461613999851, -69.907971561999915],
            [-122.314578613999856, -69.910886561999973],
            [-122.306735614, -69.911770562000015],
            [-122.286453614, -69.912768561999897],
            [-120.666685611999867, -70.086159562000034],
            [-120.521235611999842, -70.110888561999928],
            [-120.311847611999895, -70.131796561999877],
            [-120.259823611999877, -70.140512561999927],
            [-120.237153611999901, -70.142750561999918],
            [-118.684681610999974, -70.39230256299993],
            [-118.47151161, -70.441625562999917],
            [-117.390732608999883, -70.531563563],
            [-117.370891608999855, -70.534434562999877],
            [-117.267758608999856, -70.542622562999853],
            [-117.164953608999909, -70.557373562999913],
            [-117.142122609000012, -70.559162562999873],
            [-117.045491608999839, -70.572938562999894],
            [-116.95775960899995, -70.57451556299992],
            [-116.948507608999961, -70.57522856299984],
            [-116.777829609000037, -70.578164562999945],
            [-116.766569608999902, -70.579020562999887],
            [-116.239115607999864, -70.38742056300002],
            [-114.893001606999917, -70.027543561999934],
            [-113.425327605999954, -69.740394561999892],
            [-111.868283603999828, -69.531581561999914],
            [-111.853773603999912, -69.530519561999881],
            [-111.841446603999955, -69.528792561999893],
            [-110.226219602999848, -69.402024561999923],
            [-108.582197600999962, -69.359893561999911],
            [-108.401285600999884, -69.365430562000014],
            [-107.872747599999869, -69.230589561999892],
            [-107.8466516, -69.22604656199988],
            [-107.8386756, -69.223976561999933],
            [-106.388437598999943, -68.961781560999853],
            [-106.12364959899989, -68.931459560999926],
            [-106.039800598999932, -68.915519560999897],
            [-106.00040559899999, -68.910939560999935],
            [-105.973200598999952, -68.905744560999892],
            [-104.452042596999959, -68.719842560999851],
            [-102.881066595999869, -68.617497560999851],
            [-102.85639359599999, -68.617354560999928],
            [-102.804347595999928, -68.613682560999862],
            [-102.703148595999892, -68.613023560999949],
            [-102.494805594999903, -68.598014560999871],
            [-102.259077594999908, -68.595941560999947],
            [-102.140266594999972, -68.587077560999901],
            [-101.122466593999903, -68.573518560999887],
            [-100.23558659299988, -68.498598560999838],
            [-100.227311592999911, -68.498400560999983],
            [-99.811937592999868, -68.488748560999937],
            [-99.786085592999939, -68.39911956100002],
            [-99.785345593000017, -68.398171560999913],
            [-99.784480592999842, -68.395160561000012],
            [-99.359837592999924, -67.836770559999877],
            [-99.309424591999885, -67.797039559999931],
            [-99.275327591999883, -67.75067256],
            [-99.272810591999985, -67.748677559999948],
            [-99.270466591999934, -67.745481560000016],
            [-98.62494059200003, -67.220513559999887],
            [-97.784501590999952, -66.742461558999906],
            [-96.779479589999937, -66.322712558999896],
            [-96.77594559, -66.321649558999866],
            [-96.768443589999947, -66.318435558999866],
            [-95.629196588999889, -65.966432557999894],
            [-94.384733587999875, -65.68993555799986],
            [-93.06373958699993, -65.494800557999895],
            [-93.043977586999972, -65.493314557999867],
            [-93.041682586999968, -65.492962557999959],
            [-91.672441584999973, -65.383025557999929],
            [-90.282361583999972, -65.360738557999923],
            [-88.898930582999867, -65.42655855799984],
            [-88.892320582999901, -65.427340557999884],
            [-88.862097082999867, -65.428628057999845],
            [-88.828835582999972, -65.430036557999941],
            [-87.479030580999904, -65.581983557999834],
            [-87.4717375809999, -65.583361557999893],
            [-87.446919580999918, -65.586021557999899],
            [-86.158500579999895, -65.82136155799995],
            [-84.960283578999849, -66.135477558999881],
            [-83.881348577999887, -66.521629558999877],
            [-83.870355577999902, -66.527077558999906],
            [-83.863660578, -66.529410558999928],
            [-82.933154576999925, -66.978907558999907],
            [-82.461077576999969, -67.297654559999927],
            [-81.711121575999925, -67.048327558999887],
            [-80.44958257499988, -66.744394558999886],
            [-80.443959574999866, -66.743490558999866],
            [-80.437660574999853, -66.741927558999862],
            [-79.087958573999856, -66.516948558999914],
            [-78.99246557399988, -66.507831558999897],
            [-78.913051572999962, -66.494027558999861],
            [-78.906802572999936, -66.493424558999891],
            [-78.896133572999929, -66.491565558999895],
            [-78.648525572999915, -66.467433558999915],
            [-78.389962572999877, -66.42151055899987],
            [-78.175626572999903, -66.399830558999909],
            [-77.537283571999922, -66.223110558999934],
            [-76.612153570999851, -65.843370557999862],
            [-76.60678757099987, -65.842346557999946],
            [-76.591587570999877, -65.837982557999922],
            [-76.427094570999856, -65.806403557999914],
            [-76.42314957099984, -65.804073557999942],
            [-76.39410057099991, -65.778194557999839],
            [-76.241976570999839, -65.687723557999888],
            [-75.943066570999861, -65.415139557999879],
            [-75.86950557099982, -65.370147557999928],
            [-75.868016571, -65.368766557999891],
            [-75.099889569999903, -64.886932556999909],
            [-75.08169657, -64.878790556999917],
            [-75.079829569999873, -64.877591556999917],
            [-74.154716568999874, -64.453620556999894],
            [-74.13024356899993, -64.445532056999895],
            [-74.092771568999922, -64.433140556999916],
            [-74.051967568999856, -64.413930556999873],
            [-74.021256568999888, -64.403754556999985],
            [-74.01940256899988, -64.402879556999892],
            [-73.52076556799986, -64.235613556999937],
            [-73.315746567999838, -64.136129556999862],
            [-73.200536567999961, -64.054754556999882],
            [-73.180986567999923, -64.045148556999919],
            [-72.815922568000019, -63.636145555999867],
            [-72.148761566999951, -63.138597555999901],
            [-71.324516565999971, -62.696077554999896],
            [-70.369380565999904, -62.31925155499988],
            [-69.800258564999893, -62.158680554999904],
            [-69.771373564999948, -62.118318554999888],
            [-69.189445564000039, -61.606312553999857],
            [-68.977639563999873, -61.476258553999862],
            [-68.928655563999854, -61.431669553999868],
            [-68.88598756399989, -61.405210553999915],
            [-68.876944563999871, -61.396941553999895],
            [-68.855264563999924, -61.383465553999905],
            [-68.837680564000038, -61.367350553999877],
            [-68.816771563999907, -61.3543255539999],
            [-68.780853563999898, -61.321306553999847],
            [-68.751872564, -61.303186553999922],
            [-68.714082563999881, -61.268299553999853],
            [-67.990218563000013, -60.80529855399989],
            [-67.134004562999962, -60.405056552999895],
            [-66.170280561999874, -60.077163552999849],
            [-66.149382561999857, -60.072334552999884],
            [-66.146796561999935, -60.071434552999939],
            [-66.107685561999887, -60.062382552999885],
            [-66.07417556199988, -60.050693552999867],
            [-65.029400060999848, -59.80193205299981],
            [-64.907752559999949, -59.772155552999983],
            [-64.883338559999913, -59.76863655299988],
            [-64.722377560000012, -59.728896552999899],
            [-64.543585559999826, -59.662649552999902],
            [-63.523302558999944, -59.401099551999891],
            [-63.506091558999913, -59.398378551999912],
            [-63.503978558999961, -59.397822551999916],
            [-63.343382558999878, -59.372285551999859],
            [-63.036551558999889, -59.290479551999923],
            [-61.961824557999847, -59.109605551999877],
            [-61.955947557999963, -59.109147551999911],
            [-61.95236755799985, -59.108525551999868],
            [-61.543615556999896, -58.992380551999901],
            [-61.48848955699998, -58.982544551999908],
            [-61.295693556999936, -58.926867551999884],
            [-61.120191556999828, -58.894978551999976],
            [-60.935556556999899, -58.840790551999966],
            [-60.920060556999942, -58.837928551999866],
            [-60.913269557, -58.835924551999874],
            [-60.012029555999874, -58.665083551999878],
            [-59.34637555499998, -58.364886550999906],
            [-58.407149553999943, -58.061057550999898],
            [-58.398953553999831, -58.059308550999866],
            [-58.389288553999876, -58.056114550999915],
            [-57.379605553000033, -57.834721550999923],
            [-56.323379551999892, -57.700994550999873],
            [-56.303349551999958, -57.70028755099991],
            [-56.296172551999859, -57.699341550999883],
            [-55.217688550999924, -57.65604955099991],
            [-54.673516550999892, -57.681758550999895],
            [-54.434062550999982, -57.669640550999915],
            [-53.355888549999918, -57.710667550999887],
            [-53.349806550000039, -57.711457550999882],
            [-53.345921549999929, -57.711587550999887],
            [-53.337293549999856, -57.712707550999831],
            [-53.332298549999848, -57.712873550999888],
            [-52.278015549, -57.844360550999873],
            [-52.063189547999968, -57.89213355099988],
            [-51.881648547999902, -57.913314550999942],
            [-51.056749547999971, -58.089313550999947],
            [-50.696685546999845, -57.928329550999905],
            [-49.763832546, -57.629293550999897],
            [-49.743988546, -57.625125550999911],
            [-49.743261545999871, -57.624887550999937],
            [-48.740359544999961, -57.408716549999916],
            [-48.330633544999955, -57.359868549999959],
            [-48.22073354499986, -57.335055549999858],
            [-47.175701543999821, -57.202534549999854],
            [-46.107577542999906, -57.160992549999904],
            [-46.103072542999826, -57.161225549999905],
            [-46.097603542999849, -57.160988549999928],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ISO_A3: "ATF",
        Country: "French Southern & Antarctic Lands",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [68.838696564000088, -45.118527689999951],
              [69.658189565000214, -45.169918690000031],
              [70.456150566000247, -45.315106689999936],
              [70.814902566000058, -45.427670690000014],
              [71.120145566000133, -45.480288689999952],
              [71.881188566999981, -45.710919690999958],
              [72.581136568000204, -46.025931691000039],
              [72.7151835680001, -46.111360690999959],
              [72.802997568000194, -46.136525690999974],
              [72.80657756800008, -46.138101690999974],
              [72.809339568000212, -46.138891690999969],
              [72.834908568000117, -46.15014469099998],
              [72.839853568000194, -46.151557690999937],
              [72.844767568000179, -46.153718690999938],
              [72.851089568000134, -46.155522690999987],
              [73.562646569000208, -46.464256690999946],
              [73.563314569000227, -46.464564691000021],
              [73.571095569000249, -46.469367690999945],
              [73.574800569000217, -46.470953691000034],
              [74.206958569000051, -46.85636869199999],
              [74.208473569000148, -46.857665692000012],
              [74.210289569000111, -46.858759691999978],
              [74.744840570000179, -47.311071191999986],
              [74.74658557000015, -47.312678191999964],
              [74.7480795700003, -47.313995692],
              [75.167663570000059, -47.820535692999954],
              [75.457412570000145, -48.367590692999954],
              [75.458872570000153, -48.373327692999965],
              [75.45982357000014, -48.375100692999979],
              [75.466328570000144, -48.400645692999944],
              [75.495687570000143, -48.455277693000028],
              [75.643903570000134, -49.027260693999892],
              [75.643858570000134, -49.034142693999968],
              [75.647716570000171, -49.048825694000016],
              [75.647709570000075, -49.049883693999959],
              [75.650688570000227, -49.061216693999938],
              [75.650681570000131, -49.062277693999988],
              [75.653661570000168, -49.073606694000034],
              [75.653694570000027, -49.075144193999961],
              [75.650316570000143, -49.655573693999884],
              [75.634967570000072, -49.710642693999944],
              [75.634929570000168, -49.717149693999943],
              [75.613032570000087, -49.795513694000022],
              [75.602087570000094, -49.826204694000012],
              [72.822653568000078, -50.906364694999979],
              [72.474598567000271, -51.052476695999985],
              [71.208209566000107, -51.408865695999964],
              [69.734042565000095, -51.971643695999944],
              [68.092098563000263, -52.707754697000041],
              [67.055709562000288, -53.235255697999946],
              [66.154039562000094, -53.070665696999981],
              [65.302530561000253, -52.799947697],
              [64.553746060000321, -52.439241196999944],
              [64.551296560000196, -52.438025697],
              [63.929405560000248, -52.000416696],
              [63.451900559000023, -51.501143696000042],
              [63.129901559000245, -50.957071696000014],
              [62.968306559000098, -50.385929695000016],
              [62.966432059000226, -49.805494694],
              [62.966470559000243, -49.803955694000024],
              [63.118652559000111, -49.232124693999879],
              [63.415341559000069, -48.685463692999974],
              [63.57441355900022, -48.498991692999937],
              [63.574347559000103, -48.495229692999942],
              [63.574806559000194, -48.493382693],
              [63.574765559000184, -48.491040692999945],
              [63.715157559000147, -47.918308693000029],
              [63.997053560000154, -47.369958691999955],
              [64.408254560000159, -46.861590691999936],
              [64.409489560000225, -46.860488191999934],
              [64.935501560000091, -46.40590169099994],
              [65.135916561000158, -46.229951691],
              [65.755915561000222, -45.840765690999945],
              [66.455563562000123, -45.528029689999983],
              [67.215605563000281, -45.299889689999986],
              [68.016029563000302, -45.162216690000037],
              [68.836345564000112, -45.118527689999951],
              [68.837424564, -45.11859969],
              [68.838696564000088, -45.118527689999951],
            ],
          ],
          [
            [
              [50.502807547000089, -42.616907687999912],
              [50.504986547000271, -42.617062688000033],
              [50.505923547000179, -42.617008687999942],
              [51.2916135480001, -42.670187687999942],
              [52.056298548000143, -42.817706687999987],
              [52.057530548000102, -42.817982688000029],
              [52.780715549000206, -43.055817687999934],
              [52.852254549, -43.090951687999905],
              [52.961269549000178, -43.096272688],
              [53.734978550000164, -43.231927688000042],
              [53.73778955000023, -43.232807688000037],
              [53.777765550000083, -43.239650687999983],
              [54.512841551000122, -43.466008188999979],
              [54.515177551000136, -43.466728688999979],
              [54.515929551000056, -43.467078689],
              [54.517584551000198, -43.467580688999988],
              [54.519223551000266, -43.468343188999938],
              [55.195851551000146, -43.779697688999988],
              [55.219029551000176, -43.794867688999886],
              [55.229513551000196, -43.799628688999974],
              [55.230248551000273, -43.80010968900001],
              [55.231083551000097, -43.800488689000019],
              [55.830992552000112, -44.188505688999953],
              [55.831566552000197, -44.189027689],
              [55.839408552000123, -44.194043688999955],
              [55.840673552000169, -44.19519268900001],
              [55.84287821866684, -44.196644688999982],
              [56.348487552000222, -44.651294689999986],
              [56.743074553000213, -45.159884689999956],
              [56.750985553000191, -45.176142689999949],
              [56.753612553000266, -45.179490690000023],
              [57.023641553000118, -45.728007691000016],
              [57.157439553000131, -46.300808691000043],
              [57.14678255299998, -46.881042691999937],
              [56.987332553000215, -47.451103691999947],
              [56.679321553000136, -47.993126692999965],
              [56.673340553000173, -47.999777692999942],
              [56.669240553, -48.006916693],
              [56.217730885333594, -48.503457692999973],
              [55.633934552000142, -48.937935693999975],
              [55.623876552000127, -48.94315719399998],
              [55.622974552000301, -48.943643693999945],
              [55.621274552000159, -48.944893693999973],
              [55.619914052000183, -48.945652693999989],
              [54.920535551000228, -49.302822693999985],
              [54.892291551000142, -49.312487693999969],
              [54.873057551000102, -49.322173694000014],
              [54.080213050000111, -49.589099693999913],
              [54.077697550000153, -49.589919694],
              [53.219049550000193, -49.75736269399988],
              [52.325233549000131, -49.819223693999959],
              [52.323889549000114, -49.819200693999903],
              [51.912773548000132, -49.798966694],
              [51.849721548000161, -49.802860693999946],
              [51.848740548000194, -49.802810693999959],
              [51.847906548000083, -49.802861693999944],
              [51.843126548000043, -49.802614693999942],
              [51.839057548000142, -49.802864694],
              [51.838318548000132, -49.80285119399997],
              [51.766016548000209, -49.799119693999955],
              [51.759911548000105, -49.799490693999985],
              [51.758999548000133, -49.799443693999933],
              [51.758206548000089, -49.799491693999983],
              [51.085967548000042, -49.762423693999949],
              [50.360903547000078, -49.800223693999897],
              [50.35935854700017, -49.800124693999976],
              [50.357384547000265, -49.800221694000015],
              [49.46369054600018, -49.739661694],
              [48.604754545000048, -49.573469693999982],
              [48.602727545000221, -49.572800693999966],
              [48.592534545000262, -49.57078469399994],
              [47.79920054500019, -49.304971694000017],
              [47.098521044000222, -48.949199193999959],
              [47.096722544000045, -48.948267693999981],
              [47.094635544000027, -48.946741694000025],
              [47.09059954400027, -48.944608693999953],
              [47.089248544000213, -48.943856693999976],
              [46.503811543000154, -48.510694692999948],
              [46.502822043000151, -48.509683692999943],
              [46.049186543000246, -48.013949692999972],
              [46.047818543000233, -48.011588693000022],
              [46.046795543000144, -48.010457692999978],
              [45.736447543000281, -47.468986691999874],
              [45.73438154300004, -47.461777692],
              [45.727885543000127, -47.450322691999986],
              [45.716255543000102, -47.409691691999967],
              [45.549061542000061, -47.112774691999959],
              [45.39004154200012, -46.542771690999984],
              [45.37895254200032, -45.96218369099995],
              [45.379016542000102, -45.960844690999977],
              [45.380401542000129, -45.9545431909998],
              [45.381050542000139, -45.951389690999974],
              [45.380974542000104, -45.947509690999937],
              [45.513337542000102, -45.374649690000012],
              [45.781154543000127, -44.82602469],
              [46.17310954300018, -44.317328688999964],
              [46.173812543000111, -44.316699688999961],
              [46.182206876333396, -44.305791022333267],
              [46.684373543000049, -43.851024688999985],
              [46.694169543000157, -43.844717688999879],
              [46.700389543000199, -43.839021688999935],
              [47.296478544000195, -43.450820688999954],
              [47.969389545000126, -43.139514687999906],
              [47.9710715450002, -43.139001688],
              [47.971796545000132, -43.138662687999954],
              [47.982251545000281, -43.135474687999931],
              [48.217623545000151, -43.024763688],
              [48.219336545000147, -43.024201188],
              [48.948264546000246, -42.796231687999978],
              [49.715999546000205, -42.659315687999936],
              [50.502807547000089, -42.616907687999912],
            ],
          ],
          [
            [
              [77.545371572000136, -34.477818680000041],
              [78.247102573000063, -34.526341679999916],
              [78.24835557300014, -34.526609679999979],
              [78.279232573000172, -34.528644679999957],
              [78.96221957400013, -34.672637680000037],
              [79.607933574000185, -34.908408680999969],
              [79.609273574000213, -34.90910218099998],
              [80.200116575000266, -35.230323681000016],
              [80.718804575000263, -35.628047680999941],
              [80.720640575000118, -35.630034681000041],
              [80.721736575000023, -35.630868681000038],
              [81.153071576000173, -36.094148681999968],
              [81.158046576000231, -36.101979681999921],
              [81.159160576000204, -36.103167682],
              [81.489587576000133, -36.61918518199991],
              [81.49049607600017, -36.62068868199998],
              [81.493134576000159, -36.624872681999932],
              [81.494025576000126, -36.626334681999936],
              [81.494733576000272, -36.628148682000017],
              [81.496735576000049, -36.631247682000037],
              [81.497934576000091, -36.634320682],
              [81.498623576000114, -36.635387681999987],
              [81.716528576000144, -37.189005683],
              [81.718942576000018, -37.203536682999982],
              [81.721682576000262, -37.210435682999957],
              [81.818249576000198, -37.785753683],
              [81.81818257600014, -37.787013682999941],
              [81.818392576000292, -37.788248683],
              [81.817964576000065, -37.796612182999922],
              [81.78738557600019, -38.376575683999988],
              [81.778371576000268, -38.408752684000035],
              [81.820576576000178, -38.654611683999917],
              [81.820490576000168, -38.656265684],
              [81.820772576000252, -38.657905683999957],
              [81.79032957600009, -39.237825684999905],
              [81.628999576000183, -39.804717684999957],
              [81.338646576000087, -40.340883685999984],
              [80.92539524166682, -40.829342352666572],
              [80.400318575000199, -41.253964686000018],
              [79.779184574000055, -41.600238687],
              [79.081529574000172, -41.856567686999938],
              [79.075774574000206, -41.857795687000021],
              [79.069367574000182, -41.860121687],
              [78.318445573000133, -42.017535686999963],
              [77.540259572000252, -42.070581687000015],
              [77.538947572000097, -42.070496687],
              [77.537631572000237, -42.070581687000015],
              [76.759467571000243, -42.017373686999974],
              [76.756586571000241, -42.016779686999939],
              [76.751608571000133, -42.016422686999988],
              [76.747362571000167, -42.015546686999983],
              [76.73588857100026, -42.014722687000017],
              [75.985117571000217, -41.857008687000018],
              [75.287599570000168, -41.600471686999981],
              [74.66665257, -41.25401268600001],
              [74.665223570000251, -41.25286568599995],
              [74.664131570000166, -41.252250686000025],
              [74.139308569000093, -40.827455686000064],
              [73.726352569000198, -40.33887468599994],
              [73.436302568000201, -39.802620685000022],
              [73.27528156800031, -39.235682684999972],
              [73.275173568000241, -39.233622684999943],
              [73.270544568000219, -39.21720168500002],
              [73.240442068000164, -38.637274183999978],
              [73.240396568000108, -38.636374683999975],
              [73.240754568000114, -38.634302683999977],
              [73.240691568000216, -38.633083683999928],
              [73.240981568000223, -38.631402684000022],
              [73.240893568000217, -38.629689684000027],
              [73.310609568000189, -38.22367668399994],
              [73.28628556800021, -37.762486682999963],
              [73.286495568000191, -37.761251682999955],
              [73.286428568000133, -37.759991682999953],
              [73.382936568000162, -37.184670682999979],
              [73.600916569000105, -36.630963182000016],
              [73.601867569000149, -36.628589681999927],
              [73.60307756900022, -36.626717681999935],
              [73.603612569000063, -36.625346681999929],
              [73.604505069000197, -36.623883681999928],
              [73.605134569000256, -36.622828682000033],
              [73.609309569000175, -36.612128681999934],
              [73.9396535690002, -36.096325681999986],
              [74.371259235666912, -35.632912680999937],
              [74.890258570000128, -35.235035681],
              [75.480967570000246, -34.913961680999989],
              [76.126768571000241, -34.678270680000026],
              [76.15179457100021, -34.673079679999944],
              [76.159169571000234, -34.670356679999884],
              [76.84210457200021, -34.526255679999906],
              [77.543843572000185, -34.477818680000041],
              [77.544606572000077, -34.477873679999959],
              [77.545371572000136, -34.477818680000041],
            ],
          ],
          [
            [
              [40.443733538000231, -25.523624671999926],
              [40.314679538000121, -25.699166005333325],
              [40.313225538000097, -25.69906867200001],
              [40.310160538000162, -25.699276672],
              [39.669594537000194, -25.640423672],
              [39.238654537000173, -25.528696672000024],
              [39.05150553600015, -25.479769671999975],
              [39.050423536000153, -25.479289672],
              [39.045583536000095, -25.478020671999957],
              [38.871208536000154, -25.400133672000038],
              [38.687608536000084, -24.982974670999965],
              [38.516895536000106, -24.59264067099997],
              [38.472959536000303, -24.492551670999944],
              [38.305321536000179, -24.05334667],
              [38.246494536000228, -23.898960669999894],
              [38.077033535000169, -23.450796669999988],
              [38.052203535000103, -23.38419167],
              [38.027276535000169, -23.314370670000017],
              [37.986952535000114, -23.201359669999988],
              [37.738181535000024, -22.424109669000018],
              [37.715080535000197, -22.329030669000019],
              [37.714199535000148, -22.325410669],
              [37.700126535000152, -22.265693669000029],
              [37.679506535000201, -22.158439669000018],
              [37.627467535000221, -21.886390667999933],
              [37.601611535000302, -21.765005668000015],
              [37.596867535000172, -21.710089668000023],
              [37.584502535000126, -21.564404667999952],
              [37.584389535000184, -21.562958667999965],
              [37.584061535000217, -21.555616667999914],
              [37.579967535000208, -21.453787667999961],
              [37.579160535000085, -21.434170667999922],
              [37.553113535000193, -20.64126866700002],
              [37.994500535000213, -20.129341666999949],
              [38.03301753500017, -20.086535666999964],
              [38.08497253500019, -20.02869666700002],
              [38.350791536000088, -19.733605665999846],
              [38.37218753600024, -19.715904665999972],
              [38.384377536000045, -19.705931665999913],
              [38.483350536000103, -19.62519766600002],
              [38.523868536000066, -19.59229166599988],
              [38.728566536000187, -19.436150666],
              [38.732686536000188, -19.433158666],
              [38.793961536000211, -19.421851665999952],
              [39.740286537000088, -19.244539666000037],
              [39.856328537000081, -19.222344666000026],
              [39.914574537000078, -19.212333666],
              [40.274644538000189, -19.150143665999963],
              [40.331478538000113, -19.142759665999961],
              [40.497481538000102, -19.121142665999969],
              [40.944181538000066, -19.078893666],
              [40.94332753800029, -18.997047666000014],
              [40.967307538000227, -18.72085866499998],
              [40.988147538000078, -18.467971665],
              [41.005876538000223, -18.250890664999986],
              [41.067158538000285, -17.837348664999922],
              [41.169983538, -17.403535663999946],
              [41.272450538000157, -17.044328664000034],
              [41.415582539000241, -16.539232663],
              [41.688471539000261, -16.19500866300001],
              [41.926492539000265, -15.892944663000037],
              [42.165039539000134, -15.67631766300002],
              [42.474731540000107, -15.400795662],
              [42.523264540000127, -15.358838662000039],
              [42.695813540000131, -15.645071662999953],
              [42.883194540000034, -15.954377662999889],
              [42.912264540000137, -16.001464662999979],
              [42.935664540000232, -16.038954663000027],
              [43.053172540000162, -16.226114662999976],
              [43.084042540000098, -16.275046662999955],
              [43.109759540000084, -16.315462663],
              [43.119425540000293, -16.33044866299997],
              [43.293894540000167, -16.599745663999954],
              [43.458518540000114, -16.852261663999968],
              [43.360153540000283, -17.061910664000038],
              [43.176090540000246, -17.453527664000035],
              [43.007495540000207, -17.812131665000038],
              [42.969903540000018, -17.892219665000027],
              [42.880722540000136, -17.956976664999956],
              [42.720393540000146, -18.076109664999876],
              [41.899144539000247, -18.683070664999946],
              [41.846212539000192, -18.722137664999934],
              [41.550914539000075, -18.939688666],
              [41.153012538000212, -19.205372666000017],
              [41.459020539000221, -19.582810665999887],
              [41.480040539000186, -19.608526665999818],
              [41.512171539000235, -19.793899665999959],
              [41.518446539000195, -19.83121866700003],
              [41.525735539000209, -19.876907666999955],
              [41.525961539000264, -19.878403666999759],
              [41.532611539000186, -19.922383666999906],
              [41.539056539000171, -19.967639667000014],
              [41.545070539000221, -20.012674666999885],
              [41.550651539000114, -20.057489667000027],
              [41.555801539000129, -20.102084666999986],
              [41.556723539000188, -20.110758667000027],
              [41.560648539000226, -20.146522667],
              [41.565093539000145, -20.190755667000019],
              [41.569104539000193, -20.234769666999966],
              [41.572680539000089, -20.278564666999898],
              [41.575822539000171, -20.322140666999928],
              [41.578529539000158, -20.365497666999943],
              [41.58080153900022, -20.408637666999937],
              [41.582638539000186, -20.451560667000024],
              [41.584039539000059, -20.494266667000034],
              [41.585006539000176, -20.536755666999966],
              [41.585536539000145, -20.579027666999934],
              [41.58563153900019, -20.621084666999934],
              [41.585289539000144, -20.662925666999968],
              [41.584512539000116, -20.704551667000032],
              [41.583298539000225, -20.745963666999955],
              [41.581647539000244, -20.787160666999966],
              [41.579560539000056, -20.828142666999952],
              [41.577035539000178, -20.868911666999963],
              [41.574073539000096, -20.909467667999934],
              [41.573705539000173, -20.913837667999871],
              [41.582923539000092, -20.953054668],
              [41.644602539000175, -21.227029668],
              [41.684532539000145, -21.402778668],
              [41.689700539000171, -21.425661668000032],
              [41.698333539000203, -21.466389668000033],
              [41.706368539000152, -21.506976667999936],
              [41.7138075390003, -21.547424667999962],
              [41.720649539000135, -21.587732667999944],
              [41.726893539000173, -21.627901668],
              [41.73254053900024, -21.667931667999881],
              [41.737587539000174, -21.707822668],
              [41.740207539000266, -21.731232668000018],
              [41.742048539000166, -21.747577667999963],
              [41.756185539000171, -21.891918668000017],
              [41.758073539000236, -21.910963667999908],
              [41.759738539000296, -21.927571667999871],
              [41.771642539000254, -22.044996668999943],
              [41.773836539000229, -22.066310669000018],
              [41.797457539000163, -22.291354668999929],
              [41.800676539000222, -22.322283669],
              [41.801749539000184, -22.334294668999974],
              [41.80415353900014, -22.359718669000017],
              [41.807115539000222, -22.397232668999948],
              [41.809498539000231, -22.43483466899994],
              [41.81130253900028, -22.472524668999938],
              [41.8125275390002, -22.510301668999944],
              [41.81317353899999, -22.548167668999952],
              [41.813239539000165, -22.586120668999968],
              [41.812725539000098, -22.624160668999934],
              [41.811927539000294, -22.651936669],
              [41.808144539000295, -22.757971669000028],
              [41.806838539000239, -22.787800669000035],
              [41.804565539000123, -22.826583668999945],
              [41.801712539000107, -22.865486668999949],
              [41.798278539000194, -22.904508668999938],
              [41.794263538999985, -22.943649669000024],
              [41.789666539000166, -22.982908668999983],
              [41.784487539000111, -23.022285670000031],
              [41.78098753900025, -23.046288669999967],
              [41.778756539000199, -23.061774670000034],
              [41.772487539000167, -23.10137067],
              [41.765731539000228, -23.140533670000025],
              [41.702524539000194, -23.479144669999926],
              [41.693233539000289, -23.530184669999983],
              [41.689125539000202, -23.552696669999932],
              [41.680542539000214, -23.596739669999977],
              [41.671370539000179, -23.64101467],
              [41.66161053899998, -23.685519669999962],
              [41.651259539000137, -23.730254669999965],
              [41.640319539000188, -23.775217670000018],
              [41.634930539000152, -23.796344669999939],
              [41.617553539000141, -23.830165669999928],
              [41.597530539000047, -23.868111669999962],
              [41.57697653900027, -23.906061669999985],
              [41.555890539000188, -23.94401667],
              [41.534272539000142, -23.981974669999943],
              [41.518733539000152, -24.00860867],
              [41.512148539000123, -24.01993667],
              [41.489553539000042, -24.057900669999924],
              [41.466426539000167, -24.095868671],
              [41.442767539000158, -24.133839670999976],
              [41.41857453900019, -24.171812670999941],
              [41.393847539000262, -24.209788671000013],
              [41.368587539000146, -24.247766671000022],
              [41.342792539000129, -24.285746671000027],
              [41.316463538000107, -24.323728670999969],
              [41.289598538000234, -24.361711670999966],
              [41.274978538000255, -24.381980670999951],
              [41.030644538000161, -24.719175670999959],
              [40.867218538000202, -24.943833671],
              [40.793901538000171, -25.044497670999988],
              [40.443733538000231, -25.523624671999926],
            ],
          ],
          [
            [
              [54.320833551000192, -18.483055665],
              [52.814444549000143, -18.683333664999964],
              [52.591944549000061, -17.810555665000038],
              [52.483611549000045, -17.372777663999898],
              [52.454722549000167, -16.021110662999945],
              [52.556111549000093, -15.284999661999962],
              [52.759166549000156, -14.025833660999865],
              [52.979999549000183, -13.523610661000021],
              [53.381666550000148, -12.718333659999814],
              [53.920012550000251, -12.599575660000028],
              [53.995045550000157, -12.59326365999982],
              [54.513881551000225, -12.78314866],
              [55.718248552000205, -13.225443659999939],
              [55.862099552000188, -13.279779660000031],
              [55.898254552000253, -13.29339466],
              [56.674347553000189, -13.583767660999797],
              [56.978165553000053, -13.696643660999825],
              [57.178143553000268, -13.770501660999912],
              [57.078749553000193, -15.755863663000014],
              [57.069852553000203, -15.818267663000029],
              [56.968298553000068, -16.532039663000035],
              [56.966933553000104, -16.541556662999938],
              [56.79948755300012, -17.360509663999949],
              [56.78662655300019, -17.370330663999937],
              [56.474932553000201, -17.593995663999976],
              [55.66540655200015, -18.170571665000011],
              [55.50555555200026, -18.286388665],
              [54.320833551000192, -18.483055665],
            ],
          ],
          [
            [
              [46.948779544000104, -12.73218866000002],
              [46.692948543000227, -12.797081659999947],
              [46.681304543000124, -12.778960659999939],
              [46.501612543000249, -12.49812466000003],
              [46.457721543000275, -12.429196659999945],
              [46.394625543000245, -12.330191659999912],
              [46.207624543000293, -12.033147658999894],
              [46.072267543000237, -11.814913658999899],
              [45.987157543000194, -11.677390658999968],
              [45.91725054300008, -11.562453658999971],
              [45.780151543000045, -11.336829658999932],
              [45.767499836800027, -11.139721854799973],
              [46.91111054400011, -10.650277657999936],
              [48.485277545000088, -11.020832657999961],
              [48.405465545000197, -11.181451658000015],
              [48.387437545000182, -11.225783658999944],
              [48.368867545000228, -11.269159658999968],
              [48.349755545000221, -11.311580658999901],
              [48.330101545000275, -11.353048659000024],
              [48.309905545000106, -11.393563658999938],
              [48.28916554500023, -11.433126658999811],
              [48.267884545000129, -11.471737658999984],
              [48.24605954499998, -11.509398659],
              [48.231542545000195, -11.533227658999863],
              [47.944711545000189, -11.996125658999915],
              [47.888620545000094, -12.096557658999814],
              [47.770655544000078, -12.30772966],
              [47.751740544000199, -12.340351659999897],
              [47.728363544000104, -12.37871766],
              [47.720482544000106, -12.391056659999833],
              [47.6905685440002, -12.411269660000016],
              [47.657973544000129, -12.43234766],
              [47.638247544000137, -12.44455066],
              [47.624919544000136, -12.452989659999787],
              [47.591469544000176, -12.473254659999895],
              [47.557439544000033, -12.492967659999977],
              [47.522829544000103, -12.512128659999917],
              [47.487639544000046, -12.530737659999886],
              [47.451869544000203, -12.548794659999885],
              [47.415518544000236, -12.566300659999911],
              [47.378586543999972, -12.583253659999912],
              [47.341073544000153, -12.599654659999885],
              [47.30897454400008, -12.613010659999986],
              [47.302991544000122, -12.615515659999858],
              [47.264393544000086, -12.630886659999874],
              [47.22521454400021, -12.645705659999862],
              [47.202124544000242, -12.653991659999804],
              [47.185511544000178, -12.660028659999796],
              [47.155241544000148, -12.670456660000013],
              [47.145337544000085, -12.67390466],
              [47.104670544000101, -12.687313659999973],
              [47.06342554400004, -12.700174659999959],
              [47.021603544000186, -12.712486659999911],
              [46.979201544000091, -12.724250659999825],
              [46.948779544000104, -12.73218866000002],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ATG", Country: "Antigua & Barbuda" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-58.372168553999899, 18.531820369000044],
            [-58.902937554999852, 18.26517336900001],
            [-60.193273555999866, 17.59830336800006],
            [-60.199581555999913, 17.595001368000041],
            [-60.207468555999924, 17.590861368000063],
            [-60.259265555999917, 17.563545368000078],
            [-60.304826555999853, 17.538473368000055],
            [-60.306709555999873, 17.537436368000144],
            [-60.386080555999968, 17.493637368000051],
            [-60.723150556999826, 17.215784368000087],
            [-60.902624556999854, 17.067760368000066],
            [-61.016781556999916, 16.973176368000068],
            [-61.145404556999893, 16.928161368000175],
            [-61.329867556999886, 16.868220368000138],
            [-61.412892556999878, 16.838951368000025],
            [-61.448386556999907, 16.825804368000078],
            [-61.461501556999963, 16.820585368000096],
            [-61.546769556999919, 16.786779368000097],
            [-61.595211556999942, 16.761797368000046],
            [-61.653913556999953, 16.732487368],
            [-61.687364556999967, 16.715113368000075],
            [-61.689869556999923, 16.713827368000082],
            [-61.708930557, 16.705516367000072],
            [-61.71519555699993, 16.703013367000082],
            [-61.740268557999968, 16.692581367000159],
            [-61.761484557999893, 16.692867367000119],
            [-61.805512557999918, 16.693520367000147],
            [-61.822849557999888, 16.693307367],
            [-61.827399557999968, 16.697603367000013],
            [-61.885905557999934, 16.752989368000115],
            [-61.92323255799991, 16.795188367999984],
            [-61.941618557999902, 16.817358367999972],
            [-61.957001557999945, 16.83181336799997],
            [-61.988206557999916, 16.860405368000116],
            [-61.990337558000022, 16.863030368000054],
            [-62.002839557999891, 16.878723368000166],
            [-62.020120558000031, 16.899979368000018],
            [-62.024328558000036, 16.905212368000022],
            [-62.054185557999944, 16.942747367999985],
            [-62.078791557999978, 16.974660368000016],
            [-62.084195558, 16.981209368],
            [-62.117972557999877, 17.013258368],
            [-62.119369558000017, 17.01463136800011],
            [-62.139103557999988, 17.034887368000099],
            [-62.203989557999989, 16.961982368000051],
            [-62.225369557999898, 16.938268368000124],
            [-62.270208557999979, 16.886273368],
            [-62.282340557999873, 16.871618368],
            [-62.288367557999834, 16.864627368000029],
            [-62.306080558000019, 16.844527368000158],
            [-62.312887557999943, 16.836627368000123],
            [-62.313511557999874, 16.835940368000124],
            [-62.328920557999936, 16.823314368000112],
            [-62.33358155799985, 16.819678368],
            [-62.340309558, 16.814559368000019],
            [-62.394938558, 16.783882368000135],
            [-62.406658558, 16.777151368],
            [-62.522579557999904, 16.722262368000074],
            [-62.753156557999858, 16.613675367],
            [-62.597907557999861, 16.840099367999969],
            [-62.539231557999955, 16.92377436800011],
            [-62.532460558, 16.932968368],
            [-62.528015557999908, 16.939022367999982],
            [-62.52551355799983, 16.942692368000152],
            [-62.523537558000044, 16.945025368],
            [-62.499852558000015, 16.973052368000012],
            [-62.479116557999902, 16.997224368000118],
            [-62.457352558000025, 17.022133367999984],
            [-62.418517557999962, 17.064154368000132],
            [-62.407128557999982, 17.076238368000148],
            [-62.389059557999929, 17.093797368000011],
            [-62.369208557999968, 17.112543368000033],
            [-62.351996557999939, 17.127542368],
            [-62.33369755800004, 17.143651368000164],
            [-62.276945557999881, 17.193356368000067],
            [-62.255771557999964, 17.210596368],
            [-62.223787558000026, 17.235461368000145],
            [-62.213569557999847, 17.243455368000141],
            [-62.212960557999907, 17.250016368000146],
            [-62.211452558000019, 17.265111368000063],
            [-62.204442557999897, 17.337844367999978],
            [-62.201163558000019, 17.37073636800001],
            [-62.201135557999919, 17.371688368],
            [-62.222760557999948, 17.405205368000153],
            [-62.233430557999952, 17.421814368],
            [-62.2384495579999, 17.429921368000024],
            [-62.312027557999841, 17.549196367999983],
            [-62.314161558000023, 17.553666368000094],
            [-62.32762955799987, 17.574063368000125],
            [-62.338183557999855, 17.595773367999968],
            [-62.348539558, 17.617277368000018],
            [-62.351975557999879, 17.62505136800003],
            [-62.368971557999885, 17.663898368000119],
            [-62.369600776999903, 17.665376846000143],
            [-62.36510849599992, 17.686143872000045],
            [-62.362958324999909, 17.696083733000123],
            [-62.348503746999967, 17.764104826000093],
            [-62.336323622999885, 17.821422682000062],
            [-62.328891637999845, 17.851726859000124],
            [-62.314034561, 17.909809665000054],
            [-62.313219783999898, 17.912907957000087],
            [-62.274254027999945, 18.060675882000069],
            [-62.259307357999859, 18.123325798000067],
            [-62.255590559000012, 18.138904997000068],
            [-62.251408086999987, 18.155419206000104],
            [-62.237315984999896, 18.211060925000041],
            [-62.221508085999972, 18.279723558000072],
            [-62.213512485000024, 18.314452969999977],
            [-62.010081558000024, 18.658892369000043],
            [-61.848919558, 18.93084337000009],
            [-61.269419556999907, 19.902014370000032],
            [-60.683204556999954, 20.870558371000143],
            [-60.630502555999982, 20.857740370999963],
            [-60.628706055999828, 20.856984371000109],
            [-60.068268556000021, 20.607327371000039],
            [-60.036562555999978, 20.586101371000083],
            [-60.017714556000044, 20.577658371000055],
            [-59.514574554999854, 20.239344371],
            [-59.083915554999891, 19.823261370000111],
            [-59.083404554999987, 19.822553369999966],
            [-59.081941554999986, 19.821134369999967],
            [-59.080918554999897, 19.819714370000085],
            [-59.078937555000039, 19.817793370000018],
            [-59.07747755500003, 19.81576637],
            [-59.072984554999977, 19.811410370000104],
            [-59.064849554999853, 19.800116370000168],
            [-59.053115555000034, 19.788736370000052],
            [-59.051637554999957, 19.786684370000089],
            [-59.040166555000042, 19.775556370000132],
            [-59.039256554999866, 19.774328870000161],
            [-59.038378054999839, 19.773233370000113],
            [-59.035282554999867, 19.769021370000146],
            [-59.034203555000033, 19.767974370000118],
            [-58.689218554999854, 19.28722437],
            [-58.688751554999953, 19.286235369999972],
            [-58.6882325549999, 19.28551037000004],
            [-58.685773555000026, 19.280300370000177],
            [-58.685318555000038, 19.279665370000131],
            [-58.684992554999866, 19.278974370000057],
            [-58.684331554999886, 19.278049370000176],
            [-58.434752553999971, 18.747799369],
            [-58.372168553999899, 18.531820369000044],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "AUS", Country: "Australia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [139.2011408380001, -10.831851914999916],
            [139.384452800000162, -11.148474999999891],
            [140.001119400000192, -10.981808299999869],
            [141.660231832000164, -9.974074698999942],
            [141.945360958000066, -9.799384311999944],
            [142.001125000000258, -9.765169399999877],
            [142.059458300000159, -9.7551694],
            [142.251024088000037, -9.72177789200002],
            [142.351937521000224, -9.704187842999985],
            [142.384458300000205, -9.698502799999886],
            [142.403852188000172, -9.697475414999971],
            [142.437924875000107, -9.695670424999932],
            [142.596690001000155, -9.687151357999824],
            [142.851102800000177, -9.673499672285601],
            [142.950799629000159, -9.667968625999904],
            [143.001102800000098, -9.665166699999887],
            [143.00860196700026, -9.654671932999918],
            [143.040918113000174, -9.609437766999918],
            [143.084436100000261, -9.548499999999834],
            [143.195723972000366, -9.548522180999896],
            [143.248464917000177, -9.548520251999904],
            [143.330714220000203, -9.548501319999858],
            [143.334436100000147, -9.548499999999834],
            [143.501102800000268, -9.3985],
            [143.801102800000166, -9.365166699999875],
            [144.251111100000259, -9.498519399999864],
            [144.252321268000202, -9.499378120999864],
            [144.656780978000114, -9.792372755999963],
            [144.734444400000115, -9.848519399999844],
            [146.501077800000161, -12.331819399999858],
            [147.142744400000225, -12.640152799999882],
            [147.66772785300023, -12.93819871999986],
            [148.08441110000021, -13.1734861],
            [152.11773330000014, -14.631808299999889],
            [154.251066700000109, -14.748474999999857],
            [156.61773330000014, -14.081808299999921],
            [157.038333333000224, -14.067777777999879],
            [157.716820647000333, -14.683294661999923],
            [158.760987648000111, -15.735239662999945],
            [158.380431648000098, -16.424406662999942],
            [158.274042647000186, -16.580795663999936],
            [157.642098647000154, -17.507739663999899],
            [157.366542647000159, -17.911073664999932],
            [156.945709646000154, -18.540240664999985],
            [156.62487564600022, -18.931629665999949],
            [156.255709646000099, -19.286629665999925],
            [156.826264646000112, -20.141073666999887],
            [157.052653646000095, -20.541074666999933],
            [157.076264646000055, -20.71440766699989],
            [157.107098646000139, -20.892463667999905],
            [157.171542646000063, -21.215796667999967],
            [157.243487646000091, -21.789130667999956],
            [157.217931646000039, -22.175241668999945],
            [157.312098647000113, -22.52718566899992],
            [157.801264647000124, -23.248297669999928],
            [158.547354648000208, -24.998366670999957],
            [158.595129148000154, -25.110256670999945],
            [158.586055648000098, -25.122876670999986],
            [158.576976648000112, -25.135378670999941],
            [158.567834648000172, -25.147842671000035],
            [158.558629648000164, -25.160267670999929],
            [158.549361648000087, -25.172655671999962],
            [158.540031648000223, -25.185004671999963],
            [158.530443648000215, -25.197574672000044],
            [158.520790648000258, -25.210104672000014],
            [158.511073648000178, -25.222592671999962],
            [158.501291648000205, -25.235040671999982],
            [158.491444648000169, -25.247446672],
            [158.481534648000178, -25.259810671999986],
            [158.471559648000067, -25.272132671999984],
            [158.461520648000061, -25.284412672000045],
            [158.451418648000157, -25.296650672000013],
            [158.44125264800013, -25.308844671999978],
            [158.431023648000206, -25.320996672000021],
            [158.420731648000157, -25.333104671999976],
            [158.410376648000096, -25.345168671999929],
            [158.399959648000191, -25.357189671999961],
            [158.389479648000105, -25.369165672],
            [158.378937648000118, -25.381097671999967],
            [158.368333647000185, -25.392985672000023],
            [158.35766764700017, -25.404827672],
            [158.346939647000255, -25.416624671999969],
            [158.33615064700021, -25.428376671999956],
            [158.325300647000148, -25.44008267199996],
            [158.314389647000183, -25.45174267199998],
            [158.303418647000086, -25.463356672],
            [158.292386647000086, -25.47492367199996],
            [158.281294647000237, -25.486444672000019],
            [158.270141647000088, -25.497917672000014],
            [158.258929647000201, -25.509343671999943],
            [158.247657647000125, -25.520722671999977],
            [158.236327647000138, -25.532053672000032],
            [158.224936647000192, -25.543336671999938],
            [158.213488647000219, -25.554571671999952],
            [158.20198064700017, -25.565757672],
            [158.19041464700004, -25.576895672],
            [158.17879064700017, -25.587983671999936],
            [158.167108647000106, -25.59902267199999],
            [158.155411647000136, -25.609977672000028],
            [158.143656647000086, -25.620883672],
            [158.131845647000176, -25.631739672000023],
            [158.119977647000184, -25.642545671999983],
            [158.108052647000108, -25.653301671999984],
            [158.09607264700017, -25.664007672000025],
            [158.084036647000204, -25.674662672000011],
            [158.071944647000265, -25.685266671999955],
            [158.059797647000181, -25.695819672000042],
            [158.047595647000179, -25.706321671999987],
            [158.035338647000145, -25.716772671999976],
            [158.023027647000191, -25.727171671999926],
            [158.010661647000205, -25.737517672000024],
            [157.998241647000185, -25.747812671999981],
            [157.985768647000242, -25.758054672],
            [157.973240647000154, -25.768243671999983],
            [157.96066064700014, -25.778380672000011],
            [157.948026647000262, -25.78846367200002],
            [157.935340647000174, -25.798494671999975],
            [157.922602647000161, -25.808470671999928],
            [157.909811647000168, -25.818393672000013],
            [157.89696864700025, -25.828262672],
            [157.884074647000176, -25.838076671999943],
            [157.871128647000177, -25.84783767200004],
            [157.858131647000192, -25.857542672000037],
            [157.845083647000109, -25.867193672],
            [157.831984647000041, -25.876789672000029],
            [157.818836647000097, -25.88632967199996],
            [157.805637647000111, -25.895814671999958],
            [157.792389647000078, -25.905243672000026],
            [157.779091647000229, -25.914616672],
            [157.765744647000162, -25.923933672000047],
            [157.752348647000218, -25.933194671999985],
            [157.738903647000058, -25.942398672],
            [157.725410647000245, -25.951546672],
            [157.711869647000213, -25.960636672],
            [157.698281647000186, -25.969670672],
            [157.684644647000113, -25.978646671999968],
            [157.670961647000155, -25.987564671999948],
            [157.657231647000259, -25.996425671999916],
            [157.643454647000254, -26.005228671999973],
            [157.629631647000252, -26.013973672000034],
            [157.615762647000253, -26.022659672],
            [157.602467647000111, -26.030908671999967],
            [157.589130647000189, -26.039103671999982],
            [157.575752647000201, -26.047245671999974],
            [157.562333647000145, -26.055332672000034],
            [157.548874647000133, -26.063366671999958],
            [157.535374647000111, -26.071345671999964],
            [157.52183364700025, -26.079270671999936],
            [157.508253647000203, -26.08714067199999],
            [157.494633647000256, -26.094955672000012],
            [157.480974647000068, -26.102715671999917],
            [157.467276647000091, -26.110421671999987],
            [157.453539647000099, -26.118071671999942],
            [157.439146647000172, -26.126006671999946],
            [157.424711647000237, -26.133880671999961],
            [157.410234647000181, -26.141693672],
            [157.395716647000228, -26.149445671999956],
            [157.381158647000149, -26.157137672000019],
            [157.366558647000062, -26.164766671999928],
            [157.35191964700013, -26.172335672000017],
            [157.337240647000073, -26.179841671999966],
            [157.322521647000173, -26.187286672000013],
            [157.307763647000257, -26.194669672],
            [157.292966646000224, -26.201990672],
            [157.278130646000051, -26.209248671999944],
            [157.263257646000199, -26.216444672],
            [157.248345646000217, -26.22357767199999],
            [157.233395646000218, -26.230648672000015],
            [157.218409646000197, -26.237655671999974],
            [157.2033856460001, -26.244599673000039],
            [157.188325646000152, -26.251480672999975],
            [157.17322864600024, -26.258298673000013],
            [157.158095646000191, -26.265052673],
            [157.142927646000174, -26.27174267300002],
            [157.127723646000192, -26.278368672999989],
            [157.131472646000162, -26.293308672999956],
            [157.135145646000211, -26.308264672999925],
            [157.138743646000108, -26.323234672999988],
            [157.142266646000195, -26.33821967300004],
            [157.145714646000187, -26.353218673000015],
            [157.149086646000143, -26.368231672999983],
            [157.152383646000175, -26.383258672999958],
            [157.155605646000055, -26.398298672999957],
            [157.158750646000186, -26.413351672999951],
            [157.161820646000109, -26.428417672999956],
            [157.164815646000108, -26.443495672999987],
            [157.167733646000244, -26.458585673000016],
            [157.170575646000174, -26.473687672999986],
            [157.173341646000182, -26.488800672999972],
            [157.176032646000152, -26.503925672999983],
            [157.178645646000092, -26.519060673000013],
            [157.181183646000108, -26.534206672999986],
            [157.18364464600009, -26.549363672999974],
            [157.197000646000248, -26.542621673],
            [157.210386646000103, -26.535928673],
            [157.223801646000169, -26.529282672999969],
            [157.237244646000107, -26.52268667300001],
            [157.2507166460002, -26.516138673000015],
            [157.264217646000048, -26.509638672999984],
            [157.279141646000227, -26.502320672999971],
            [157.294101646000144, -26.495062672999936],
            [157.309096647000132, -26.487863673],
            [157.324125647000216, -26.480725672999938],
            [157.33918964700004, -26.473647672999959],
            [157.354286647000237, -26.466630672999983],
            [157.369417647000176, -26.459673672999983],
            [157.384581647000147, -26.452776672999974],
            [157.399779647000145, -26.44594167299995],
            [157.415009647000119, -26.439166673],
            [157.43027264700018, -26.432453672999941],
            [157.445567647000217, -26.425800672999969],
            [157.460825647000121, -26.419231673000013],
            [157.476115647000114, -26.412723672999945],
            [157.491436647000256, -26.40627667300005],
            [157.506788647000207, -26.399890672999959],
            [157.522170647000081, -26.393566673000024],
            [157.537582647000107, -26.387303672999977],
            [157.553024647000058, -26.381101672999932],
            [157.56849664700016, -26.37496167300003],
            [157.583997647000189, -26.368883673000013],
            [157.599527647000144, -26.362867672999982],
            [157.615085647000257, -26.356913672999937],
            [157.630672647000125, -26.351020672999951],
            [157.646286647000096, -26.345191672999945],
            [157.661929647000221, -26.339423673],
            [157.677599647000221, -26.33371867299995],
            [157.693296647000039, -26.328076672999956],
            [157.709020647000131, -26.322496672999932],
            [157.724770647000156, -26.316979672999977],
            [157.74054764700017, -26.311525673],
            [157.756349647000235, -26.306134672999988],
            [157.772177647000063, -26.300806672999954],
            [157.78803064700017, -26.295542672999972],
            [157.803909647000211, -26.29034067299996],
            [157.819812647000191, -26.285202673000015],
            [157.835739647000224, -26.280128673000036],
            [157.851691647000081, -26.275117673000025],
            [157.867666647000163, -26.270170672999981],
            [157.883665647000186, -26.265286673],
            [157.89968764700015, -26.26046767299998],
            [157.915731647000172, -26.255711673],
            [157.931799647000133, -26.251020673000014],
            [157.947888647000042, -26.246393672999972],
            [157.964000647000177, -26.241830673],
            [157.979978647000138, -26.237311672000018],
            [157.995978647000157, -26.232855672000014],
            [158.011999647000067, -26.228462671999978],
            [158.028041647000265, -26.224132672],
            [158.044102647000187, -26.219866672],
            [158.060184647000057, -26.215664671999946],
            [158.07628664700016, -26.211525671999965],
            [158.092407647000158, -26.20745067199995],
            [158.108547647000108, -26.203439671999988],
            [158.124706647000124, -26.199492672],
            [158.140883647000152, -26.195608671999977],
            [158.157079647000131, -26.191789672000013],
            [158.173292647000181, -26.188034672],
            [158.189523647000129, -26.184343671999969],
            [158.205771647000148, -26.180717671999972],
            [158.222037647000235, -26.17715567200004],
            [158.238319647000111, -26.173657671999976],
            [158.254617647000117, -26.170224671999975],
            [158.270931647000253, -26.166855671999926],
            [158.287261647000122, -26.163551672000025],
            [158.303607647000177, -26.160312671999989],
            [158.319968647000138, -26.157137672000019],
            [158.336343647000177, -26.154027672],
            [158.352733647000122, -26.150982671999941],
            [158.369138647000256, -26.148002672000018],
            [158.385556648000062, -26.145087671999974],
            [158.401988648000184, -26.142236671999981],
            [158.41843364800016, -26.13945167199995],
            [158.434891648000161, -26.136731671999954],
            [158.451362648000242, -26.134076671999921],
            [158.467845648000235, -26.131487671999935],
            [158.484340648000199, -26.128962672000014],
            [158.500847648000246, -26.126503671999942],
            [158.517764648000167, -26.124050672000024],
            [158.534693648000058, -26.121665671999949],
            [158.551633648000148, -26.119349671999984],
            [158.568585648000152, -26.117102671999973],
            [158.585547648000244, -26.114923671999975],
            [158.60251964800014, -26.112813672000016],
            [158.619501648000238, -26.110772671999982],
            [158.636493648000084, -26.108800671999973],
            [158.653494648000134, -26.106896672],
            [158.670505648000045, -26.105062672000045],
            [158.687524648000164, -26.10329767199994],
            [158.704551648000262, -26.101600671999947],
            [158.72158764800011, -26.099973671999976],
            [158.738630648000168, -26.098415671999945],
            [158.755680648000208, -26.096926671999924],
            [158.772738648000114, -26.095506671999942],
            [158.789802648000233, -26.094155671999985],
            [158.80687364800022, -26.092873671999953],
            [158.823949648000081, -26.091661671999958],
            [158.841031648000097, -26.090518671999973],
            [158.858119648000155, -26.089444672000013],
            [158.8752126480002, -26.088439672],
            [158.892309648000122, -26.087504672],
            [158.909411648000201, -26.086638671999921],
            [158.92651664800016, -26.085841671999972],
            [158.943626648000162, -26.085114672000046],
            [158.960738648000103, -26.084456671999959],
            [158.977854648000147, -26.08386867199998],
            [158.994972648000129, -26.083349671999926],
            [159.01209364800016, -26.082899672],
            [159.02921664800013, -26.082519672],
            [159.046340648000154, -26.082208672000021],
            [159.063466648000059, -26.081966672],
            [159.080593648000246, -26.081795671999956],
            [159.097720648000148, -26.08169267199996],
            [159.114848648000162, -26.081659671999986],
            [159.13064264800019, -26.081687672],
            [159.146435648000221, -26.081773672000011],
            [159.162229648000249, -26.081919672000012],
            [159.178021648000112, -26.082124672],
            [159.193812648000147, -26.082388672],
            [159.209602648000185, -26.082711671999988],
            [159.225391648000169, -26.083093671999976],
            [159.241177648000217, -26.083534671999956],
            [159.256962648000041, -26.08403467200003],
            [159.272744648000156, -26.084593672000011],
            [159.288524648000163, -26.085211671999986],
            [159.304300648000122, -26.085888671999953],
            [159.321458648000117, -26.086686671999985],
            [159.338611648000239, -26.087555671999951],
            [159.355760648000199, -26.088493672000027],
            [159.37290564800017, -26.089501672000026],
            [159.390044648000213, -26.090579671999961],
            [159.407178648000155, -26.091726672],
            [159.424306648000169, -26.092943671999976],
            [159.441428648000198, -26.094230671999966],
            [159.458544649000174, -26.095587671999979],
            [159.475653649000122, -26.097012671999934],
            [159.492755649000202, -26.098508672],
            [159.509849649000131, -26.100073672],
            [159.526936649000191, -26.101708672],
            [159.544015649000045, -26.103412671999948],
            [159.561085649000148, -26.105185672],
            [159.578147649000101, -26.107028672],
            [159.595199649000193, -26.108940672000017],
            [159.612242649000194, -26.110921671999961],
            [159.62927564900022, -26.112972672000026],
            [159.646298649000158, -26.115092672000017],
            [159.663311649000235, -26.117282671999945],
            [159.680313649000112, -26.119540671999985],
            [159.697304649000245, -26.121868671999962],
            [159.714283649000237, -26.124264672000052],
            [159.731250649000259, -26.126730671999979],
            [159.748206649000139, -26.129264672000033],
            [159.765149649000108, -26.131868671999939],
            [159.782079649000167, -26.134540671999943],
            [159.798996649000202, -26.137281671999986],
            [159.815900649000042, -26.140091672000054],
            [159.832790649000259, -26.142970671999961],
            [159.849665649000173, -26.145917672],
            [159.866527649000119, -26.148933671999956],
            [159.88337364900022, -26.152018671999954],
            [159.900204649000244, -26.15517167199998],
            [159.917020649000193, -26.158392671999934],
            [159.933820649000069, -26.161682672000012],
            [159.950604649000212, -26.165040672000032],
            [159.967372649000168, -26.168466672],
            [159.984123649000054, -26.171960672],
            [160.000856649000156, -26.175522671999929],
            [160.01757364900007, -26.17915367199997],
            [160.034271649000203, -26.18285167199997],
            [160.050951649000154, -26.186617672],
            [160.067613649000151, -26.190451671999966],
            [160.084256649000082, -26.194353671999963],
            [160.100880649000118, -26.198322672],
            [160.117485649000201, -26.202359671999986],
            [160.13175364900016, -26.205877671999914],
            [160.146006649000157, -26.209446671999984],
            [160.160244649000191, -26.21306467200003],
            [160.174467649000263, -26.216731672000037],
            [160.188674649000092, -26.220449672],
            [160.202866649000072, -26.224216672000026],
            [160.219350649000177, -26.22865767199994],
            [160.235813649000221, -26.233165671999984],
            [160.252254649000207, -26.237739671999989],
            [160.268672649000251, -26.242381673000025],
            [160.285068649000181, -26.247089673],
            [160.30144064900017, -26.251864672999943],
            [160.317789649000105, -26.256705673000013],
            [160.334114649000099, -26.261613672999943],
            [160.350416649000152, -26.266588672999987],
            [160.366693649000155, -26.271628673],
            [160.382945649000163, -26.276735672999962],
            [160.399173649000119, -26.281907672999964],
            [160.415375649000254, -26.287146672999924],
            [160.43155164900017, -26.292450673000019],
            [160.447701649000152, -26.297820672999975],
            [160.463825649000199, -26.303256672999979],
            [160.479923649000085, -26.308757672999945],
            [160.495993649000212, -26.314323672999961],
            [160.512037649000121, -26.31995567300001],
            [160.528052650000149, -26.325652673000036],
            [160.544040650000142, -26.331415673],
            [160.560015650000224, -26.337225673000034],
            [160.57596165000021, -26.343100673000038],
            [160.591879650000266, -26.349039673],
            [160.60776765000017, -26.355044673],
            [160.623626650000261, -26.361113673],
            [160.639455650000144, -26.367247673],
            [160.65525465000016, -26.373444672999923],
            [160.671023650000137, -26.379706672999973],
            [160.68676165000025, -26.386032672999974],
            [160.7024686500001, -26.392422672999956],
            [160.718144650000198, -26.39887667299999],
            [160.73378865000015, -26.405394672999989],
            [160.74940065000024, -26.411975672999958],
            [160.764979650000242, -26.418619673],
            [160.780527650000153, -26.425327673],
            [160.796041650000205, -26.43209867299997],
            [160.811522650000171, -26.438932673],
            [160.826969650000166, -26.44582967299992],
            [160.842383650000187, -26.452789672999984],
            [160.857762650000069, -26.459811672999948],
            [160.873107650000151, -26.466896672999965],
            [160.888418650000204, -26.47404367300004],
            [160.903693650000179, -26.481252673000014],
            [160.918933650000184, -26.488523672999975],
            [160.934137650000167, -26.495856673],
            [160.949305650000071, -26.503251673],
            [160.964436650000124, -26.510708672999968],
            [160.979531650000155, -26.518226673000015],
            [160.994589650000108, -26.525805672999965],
            [161.009610650000042, -26.533445672999989],
            [161.024593650000185, -26.541147672999983],
            [161.039539650000137, -26.548909672999969],
            [161.054446650000131, -26.556732672999942],
            [161.069315650000107, -26.564615673],
            [161.084145650000124, -26.572559672999944],
            [161.098936650000184, -26.580563672999972],
            [161.113687650000173, -26.588627673],
            [161.128399650000205, -26.596751673],
            [161.14307165000011, -26.604935673],
            [161.157703650000173, -26.613179673],
            [161.172294650000055, -26.621481673],
            [161.186844650000097, -26.629843672999968],
            [161.201353650000186, -26.638264673000037],
            [161.215820650000211, -26.64674467299993],
            [161.230246650000112, -26.655283673],
            [161.244630650000175, -26.663880672999966],
            [161.258971650000063, -26.672536673000039],
            [161.273269650000117, -26.681249673000011],
            [161.287525650000163, -26.69002167299999],
            [161.301737650000206, -26.698851672999979],
            [161.315906650000244, -26.707738672999966],
            [161.32949765000015, -26.716302672999973],
            [161.343047650000045, -26.72491867299992],
            [161.356557650000099, -26.733587672999988],
            [161.370025650000144, -26.742308673000011],
            [161.383452650000237, -26.751082672999985],
            [161.396838650000092, -26.759909672999981],
            [161.410181650000169, -26.76878767300002],
            [161.423483650000236, -26.77771867300001],
            [161.436742650000127, -26.786700672999956],
            [161.449959650000181, -26.795735673000024],
            [161.463132650000063, -26.804820672999966],
            [161.476263650000107, -26.813957672999948],
            [161.489350650000148, -26.823145672999985],
            [161.502393650000187, -26.832385673000047],
            [161.51539365000022, -26.841675672999983],
            [161.528348650000197, -26.851015672999978],
            [161.541259650000057, -26.860406673000014],
            [161.5541266500002, -26.869848673],
            [161.567371650000183, -26.879633672999958],
            [161.580568650000174, -26.889472672999929],
            [161.593717650000116, -26.899363673000039],
            [161.606817651000171, -26.909308673],
            [161.619868651000075, -26.919305673],
            [161.632870651000104, -26.92935467300002],
            [161.645823651000256, -26.939456673],
            [161.65872665100008, -26.949610672999938],
            [161.671580651000198, -26.959816673],
            [161.684383651000104, -26.970074673],
            [161.697135651000139, -26.980383672999963],
            [161.709837651000129, -26.990743672999969],
            [161.722488651000077, -27.00115467300003],
            [161.735088651000154, -27.011617672999947],
            [161.747636651000192, -27.022130673],
            [161.760133651000189, -27.032693673000011],
            [161.77248665100015, -27.043225672999952],
            [161.784787651000187, -27.053806672999954],
            [161.797037651000238, -27.064436672999918],
            [161.809235651000193, -27.075115673000042],
            [161.821380651000169, -27.085842672999945],
            [161.833473651000219, -27.096618673],
            [161.845513651000061, -27.107442673000037],
            [161.857500651000208, -27.118315673000041],
            [161.869434651000262, -27.129235673000011],
            [161.881315651000165, -27.140203672999974],
            [161.89314165100015, -27.151218673],
            [161.904914651000098, -27.162280673],
            [161.916632651000128, -27.173389672999988],
            [161.928296651000181, -27.18454567299996],
            [161.939905651000259, -27.195747673],
            [161.95146065100019, -27.206996672999935],
            [161.962959651000205, -27.218291672999953],
            [161.974402651000133, -27.22963267300004],
            [161.985790651000201, -27.241018672999942],
            [161.997122651000069, -27.252450673],
            [162.008398651000192, -27.263926672999986],
            [162.019617651000232, -27.275448673000028],
            [162.030780651000242, -27.287015672999985],
            [162.041886651000056, -27.298626672999944],
            [162.052934651000243, -27.310281673],
            [162.06392665100023, -27.321980674000031],
            [162.074859651000139, -27.333723673999984],
            [162.082937651000151, -27.342478673999977],
            [162.085735651000192, -27.345510674000025],
            [162.096553651000164, -27.357340673999985],
            [162.107313651000169, -27.369213674000036],
            [162.118014651000209, -27.381129674],
            [162.128656651000114, -27.393088673999969],
            [162.139239651000111, -27.405089673999953],
            [162.14937665100004, -27.416683674000012],
            [162.159457651000167, -27.428315674000018],
            [162.169483651000149, -27.439986673999968],
            [162.179453651000046, -27.451696673999948],
            [162.189368651000194, -27.463444673999959],
            [162.199226651000146, -27.475230673999917],
            [162.209029651000066, -27.487054674],
            [162.21877665100024, -27.498915673999946],
            [162.228466651000161, -27.510814674000017],
            [162.238099651000226, -27.522750673999951],
            [162.247676651000205, -27.53472367400002],
            [162.25719565100016, -27.546733673999938],
            [162.266657651000202, -27.558779673999922],
            [162.27606265100016, -27.570861673999943],
            [162.285409651000208, -27.58297967399993],
            [162.294699651000229, -27.595134673999951],
            [162.303735651000039, -27.60705567399998],
            [162.312716651000159, -27.619010673999981],
            [162.321640651000195, -27.630999673999938],
            [162.331039651000168, -27.64371167399996],
            [162.340375651000187, -27.656461674000013],
            [162.349648651000138, -27.669248674000016],
            [162.358858651000133, -27.682071673999971],
            [162.368004651000234, -27.694931673999974],
            [162.377086651000099, -27.707827673999944],
            [162.386105651000179, -27.720760674000033],
            [162.395059651000196, -27.733728674],
            [162.403950651000088, -27.746732673999929],
            [162.412776651000087, -27.759770674],
            [162.421537651000136, -27.772844673999955],
            [162.430233651000236, -27.785953674000041],
            [162.438864651000159, -27.799096674000012],
            [162.447430651000246, -27.812273674000039],
            [162.4559316510001, -27.82548567399995],
            [162.464366651000176, -27.838730674],
            [162.472735651000193, -27.852008673999961],
            [162.481038651000205, -27.865320673999975],
            [162.489275651000213, -27.878664673999964],
            [162.497445651000049, -27.892041674000026],
            [162.505549651000166, -27.905451673999963],
            [162.513587651000165, -27.918892674],
            [162.521557651000222, -27.932366674],
            [162.529460651000164, -27.945871673999989],
            [162.537296651000048, -27.959407673999962],
            [162.545065651000101, -27.972975673999926],
            [162.552765651000101, -27.986573673999985],
            [162.560398651000156, -28.000202674000022],
            [162.567963651000099, -28.013860673999972],
            [162.5754606510001, -28.027549674],
            [162.582889651000158, -28.041268674000023],
            [162.59024865100011, -28.055016673999958],
            [162.597629651000119, -28.068935673999917],
            [162.604939651000194, -28.082884673999956],
            [162.612178651000164, -28.096862674],
            [162.619347651000197, -28.110869673999971],
            [162.626445651000125, -28.124905673999933],
            [162.633472651000062, -28.138969674],
            [162.640428651000235, -28.153061673999972],
            [162.647312651000249, -28.167182673999946],
            [162.654125651000214, -28.181329674000025],
            [162.660866651000191, -28.195504673999949],
            [162.667536651000233, -28.20970667399996],
            [162.674133652000108, -28.223934674],
            [162.680658652000176, -28.238189673999969],
            [162.687111652000198, -28.252470673999952],
            [162.69349265200006, -28.266777673999954],
            [162.69979965200011, -28.281109673999978],
            [162.706034652000227, -28.29546667399994],
            [162.712196652000245, -28.30984967400002],
            [162.718285652000105, -28.324255674000042],
            [162.724300652000153, -28.338687674],
            [162.730243652000098, -28.353142673999983],
            [162.736111652000233, -28.367621674],
            [162.741906652000097, -28.382123673999985],
            [162.747627652000205, -28.396649674999978],
            [162.753274652000101, -28.411197675000025],
            [162.758846652000244, -28.425768675000029],
            [162.764333652000204, -28.440303674999981],
            [162.769745652000182, -28.45485967499998],
            [162.775083652000063, -28.469437674999924],
            [162.780347652000131, -28.48403567500003],
            [162.785537652000045, -28.498655675],
            [162.790652652000205, -28.513296674999921],
            [162.79569365200021, -28.527957675],
            [162.800659652000064, -28.542637675000037],
            [162.805550652000164, -28.55733867500004],
            [162.810366652000226, -28.572058674999937],
            [162.815108652000191, -28.586797674999971],
            [162.819774652000234, -28.601555674999986],
            [162.824365652000239, -28.616331674999984],
            [162.828880652000151, -28.631126674999948],
            [162.833319652000256, -28.645938675],
            [162.837683652000209, -28.660768674999929],
            [162.841972652000237, -28.675616675000015],
            [162.846184652000062, -28.690480675],
            [162.850320652000136, -28.705361674999978],
            [162.85438065200006, -28.720259675000037],
            [162.858297652000061, -28.734911674999978],
            [162.862140652000249, -28.749578675000024],
            [162.865910652000167, -28.764261674999958],
            [162.86960565200016, -28.778958674999984],
            [162.873227652000111, -28.793669674999933],
            [162.876774652000137, -28.808395674999957],
            [162.880247652000236, -28.823134675],
            [162.883646652000181, -28.837887674999962],
            [162.886970652000144, -28.852653674999928],
            [162.890220652000124, -28.867432674999989],
            [162.893394652000183, -28.882223674999977],
            [162.896495652000254, -28.89702767499999],
            [162.899520652000177, -28.911843675],
            [162.901785652000086, -28.923164674999938],
            [162.904006652000106, -28.934493674999928],
            [162.90664465200021, -28.948209674999973],
            [162.909218652000135, -28.961934674999952],
            [162.911728652000221, -28.97566867499998],
            [162.91417365200013, -28.989412675000025],
            [162.916554652000201, -29.003164674999937],
            [162.918871652000263, -29.01692467499997],
            [162.921123652000261, -29.030693674999966],
            [162.923311652000194, -29.044470674999985],
            [162.92543365200018, -29.05825567499997],
            [162.927647652000047, -29.073076675000038],
            [162.929786652000104, -29.087905674999973],
            [162.931850652000236, -29.102743674999957],
            [162.93383965200016, -29.117588674999965],
            [162.935753652000159, -29.132441674999939],
            [162.937592652000177, -29.147301674999952],
            [162.9393566520001, -29.162169675000015],
            [162.941044652000159, -29.17704367499995],
            [162.942657652000122, -29.191923674999927],
            [162.944195652000104, -29.206810674999957],
            [162.945658652000162, -29.22170267499996],
            [162.947045652000185, -29.236600675],
            [162.948357652000112, -29.251503675000038],
            [162.949593652000232, -29.266411675000029],
            [162.950754652000199, -29.281324674999979],
            [162.951838652000191, -29.296241675],
            [162.952847652000088, -29.31116267499992],
            [162.953791652000206, -29.326116675],
            [162.954659652000174, -29.34107267499995],
            [162.955451652000164, -29.35603267499998],
            [162.956167652000175, -29.370995675],
            [162.956806652000097, -29.385960675],
            [162.957369652000153, -29.400928674999975],
            [162.95785565200012, -29.415898674999951],
            [162.958266652000106, -29.430870675],
            [162.958599652000231, -29.445843674999949],
            [162.958856652000264, -29.460817674999987],
            [162.959037652000205, -29.475791676000014],
            [162.959141652000113, -29.490767675999948],
            [162.959169652000043, -29.505743675999966],
            [162.959119652000169, -29.520718676],
            [162.9589946520002, -29.535693675999937],
            [162.958791652000201, -29.550668675999972],
            [162.958512652000223, -29.565641675999913],
            [162.958156652000156, -29.580614676000053],
            [162.957723652000112, -29.59558467599993],
            [162.957213652000206, -29.610553676],
            [162.956627652000208, -29.625520675999979],
            [162.955964652000063, -29.640484675999986],
            [162.955223652000228, -29.655445676000014],
            [162.954406652000131, -29.670403675999964],
            [162.953512652000228, -29.685358676000021],
            [162.952541652000178, -29.700309676000018],
            [162.951493652000153, -29.715256675999953],
            [162.950605652000121, -29.727202675999919],
            [162.949667652000159, -29.739145676],
            [162.951000652000204, -29.753710676000026],
            [162.952260652000206, -29.768279676000034],
            [162.953448652000162, -29.782853676],
            [162.954563652000132, -29.79743167599996],
            [162.955605652000116, -29.812014675999976],
            [162.956575652000168, -29.82660067599997],
            [162.957472652000234, -29.84119067599994],
            [162.9582966520002, -29.855783675999973],
            [162.959047652000237, -29.870379676],
            [162.959725652000117, -29.884977676],
            [162.960330652000181, -29.899578676],
            [162.9606606520002, -29.907922676000013],
            [162.961210652000233, -29.92321567599997],
            [162.961680652000069, -29.938510676],
            [162.962069652000167, -29.953806675999942],
            [162.962379652000237, -29.969104675999972],
            [162.962607652000116, -29.984403675999985],
            [162.962756652000081, -29.999703675999982],
            [162.962824652000194, -30.015003676],
            [162.96281265200011, -30.030303676],
            [162.962720652000115, -30.045603675999914],
            [162.962546652000043, -30.060902676000026],
            [162.962293652000227, -30.076201676000039],
            [162.961959652000047, -30.091498675999972],
            [162.961544652000185, -30.106794676],
            [162.961049652000071, -30.122088676000047],
            [162.960473652000104, -30.137379676000023],
            [162.959816652000114, -30.152669676],
            [162.959079652000156, -30.167955676],
            [162.958261652000061, -30.183238676000016],
            [162.957362652000171, -30.198518675999978],
            [162.956383652000198, -30.213794675999949],
            [162.955323652000146, -30.229066675999945],
            [162.954182652000242, -30.244334675999966],
            [162.952960652000201, -30.259596675999944],
            [162.951658652000134, -30.274854676000032],
            [162.950294652000167, -30.29000967599994],
            [162.948850652000061, -30.305159675999974],
            [162.947327652000155, -30.32030267600004],
            [162.945724652000223, -30.335440675999962],
            [162.944041652000152, -30.35057167600003],
            [162.942278652000113, -30.36569567600003],
            [162.940436652000102, -30.380812676],
            [162.938513652000239, -30.395921676000015],
            [162.936511652000178, -30.411023675999985],
            [162.934429652000205, -30.42611767599999],
            [162.932268652000204, -30.441202675999975],
            [162.930026652000066, -30.456278676000011],
            [162.927705652000128, -30.471345675999927],
            [162.925304652000165, -30.486403675999981],
            [162.922824652000116, -30.501452675999914],
            [162.920263652000159, -30.516490675999918],
            [162.917623652000117, -30.531518675999976],
            [162.914903652000049, -30.546535677000023],
            [162.907122652000254, -30.58732567700001],
            [162.913795652000118, -30.601715676999945],
            [162.920393652000058, -30.616131677],
            [162.926915652000133, -30.630573676999987],
            [162.933362652000113, -30.64504167699998],
            [162.939733652000115, -30.659534677000011],
            [162.946028652000138, -30.674052676999978],
            [162.952247652000182, -30.688595676999967],
            [162.958390652000077, -30.703163676999978],
            [162.96445765200022, -30.71775467700003],
            [162.970446652000163, -30.73237067700002],
            [162.976360652000182, -30.747008676999968],
            [162.982196652000169, -30.761671677000024],
            [162.987956652000236, -30.776356677000038],
            [162.993638652000158, -30.791063677000011],
            [162.999243652000104, -30.805793676999926],
            [163.00477065200019, -30.82054567699997],
            [163.010220652000186, -30.835319676999987],
            [163.015592652000151, -30.850114676999951],
            [163.0208866520002, -30.864930676999961],
            [163.026103652000216, -30.879767676999947],
            [163.031241652000148, -30.894624676999982],
            [163.036300652000051, -30.90950167699998],
            [163.041281652000151, -30.92439967699994],
            [163.046184652000221, -30.939315676999982],
            [163.051008652000206, -30.954251676999974],
            [163.055752652000052, -30.969206676999946],
            [163.060418652000209, -30.984180676999983],
            [163.065005652000224, -30.99917267699999],
            [163.0695126520001, -31.014181677],
            [163.073940652000175, -31.029209676999969],
            [163.078289652000223, -31.044254677000012],
            [163.082557652000133, -31.059315676999958],
            [163.086746652000244, -31.074394676999987],
            [163.090855652000158, -31.089489676999989],
            [163.094884652000161, -31.104600677],
            [163.098833652000081, -31.119726676999989],
            [163.102701652000093, -31.134868676999986],
            [163.106489652000079, -31.150025676999974],
            [163.110197652000096, -31.165197677000037],
            [163.113823652000093, -31.180384676999935],
            [163.117369652000122, -31.195585676999926],
            [163.120834652000127, -31.210799677000011],
            [163.124219652000164, -31.226027676999919],
            [163.127522652000067, -31.241268676999937],
            [163.13074365200012, -31.25652267699995],
            [163.133884652000205, -31.271789676999987],
            [163.136943652000099, -31.287068677000022],
            [163.1399206520002, -31.302358677],
            [163.142816652000107, -31.31766167699999],
            [163.145631652000048, -31.33297467700001],
            [163.148363652000143, -31.348299676999957],
            [163.151013652000216, -31.363634676999922],
            [163.15358265200021, -31.378980677000015],
            [163.15606865200013, -31.394335676999944],
            [163.158472652000256, -31.409700676999918],
            [163.160794652000192, -31.425074677000012],
            [163.163033652000166, -31.440458677000038],
            [163.165190652000121, -31.45585067699993],
            [163.167264652000114, -31.471250676999944],
            [163.169256652000087, -31.486658677],
            [163.171165652000042, -31.502074676999939],
            [163.172991652000206, -31.517498677],
            [163.17473565200018, -31.532928677],
            [163.176395652000139, -31.548365676999978],
            [163.177972652000136, -31.563809677],
            [163.179466652000116, -31.579258676999984],
            [163.180877652000078, -31.59471367700003],
            [163.182205652000249, -31.610174677000032],
            [163.183449652000064, -31.625640678000011],
            [163.184610652000202, -31.641110677999961],
            [163.185688652000152, -31.656585677999971],
            [163.186682652000144, -31.672064677999956],
            [163.18759265200012, -31.687546677999919],
            [163.188419652000249, -31.703032677999957],
            [163.189162652000078, -31.718521677999959],
            [163.189821652000234, -31.734013677999954],
            [163.190396652000203, -31.749507677999944],
            [163.190888652000154, -31.765003678],
            [163.191296652000261, -31.780501677999965],
            [163.191619652000242, -31.796001678],
            [163.191859652000204, -31.811501677999942],
            [163.192014652000154, -31.827002677999971],
            [163.192086652000086, -31.842504678],
            [163.192073652000232, -31.85800667800001],
            [163.191976652000193, -31.873507677999953],
            [163.191795652000252, -31.889008677999982],
            [163.191529652000185, -31.904508678000013],
            [163.191179652000159, -31.920006677999979],
            [163.190745652000061, -31.935504678000029],
            [163.190226652000177, -31.950999678],
            [163.189623652000108, -31.966492678],
            [163.188936652000194, -31.981982678],
            [163.188164652000097, -31.997469678000016],
            [163.187307652000158, -32.012953677999974],
            [163.186366652000203, -32.028434677999954],
            [163.185341652000119, -32.043911678000029],
            [163.184231652000193, -32.059383677999975],
            [163.183036652000197, -32.07485167800003],
            [163.181757652000243, -32.09031367800003],
            [163.180393652000163, -32.105771677999954],
            [163.178944652000183, -32.121223678000021],
            [163.177411652000188, -32.136669678000032],
            [163.175793652000067, -32.152109677999988],
            [163.174090652000103, -32.167542677999975],
            [163.172303652000124, -32.182968678000023],
            [163.170431652000133, -32.198387678000017],
            [163.168475652000126, -32.213798677999975],
            [163.166433652000109, -32.229202677999965],
            [163.164307652000133, -32.244597677999934],
            [163.162097652000085, -32.25998367800004],
            [163.159801652000084, -32.27536167800001],
            [163.157421652000068, -32.290729677999963],
            [163.154957652000206, -32.306087677999969],
            [163.152407652000164, -32.321436677999941],
            [163.149773652000107, -32.336774677999983],
            [163.147055652000091, -32.352102678],
            [163.144252652000176, -32.36741967799999],
            [163.141364652000135, -32.382724677999974],
            [163.138391652000251, -32.398018678000014],
            [163.13533565200018, -32.413300678000041],
            [163.132193652000097, -32.428570677999971],
            [163.128967652000171, -32.443827677999963],
            [163.125657652000115, -32.459071677999958],
            [163.122262652000046, -32.474301677999946],
            [163.118783652000133, -32.48951867800001],
            [163.11521965200015, -32.504721678],
            [163.111572652000092, -32.51991067799996],
            [163.107839652000138, -32.535084678000018],
            [163.104023652000052, -32.550243678],
            [163.100122652000238, -32.565387677999979],
            [163.096137652000124, -32.580515678000054],
            [163.092068652000222, -32.595628677999954],
            [163.087915652000248, -32.610724677999954],
            [163.083678652000259, -32.625803677999968],
            [163.079357652000141, -32.640865678000011],
            [163.074952652000178, -32.655910677999969],
            [163.070463652000086, -32.670938678000027],
            [163.065890652000263, -32.685947679000023],
            [163.061234652000138, -32.700938678999961],
            [163.056494652000168, -32.715911679],
            [163.051670652000183, -32.730864678999978],
            [163.04676265200024, -32.745799678999987],
            [163.041771652000108, -32.760713678999934],
            [163.036697652000129, -32.775608679000015],
            [163.031539652000134, -32.790482679000036],
            [163.026298652000179, -32.805336679],
            [163.020974652000206, -32.820169679],
            [163.015566652000217, -32.834981678999952],
            [163.010076652000095, -32.849771678999943],
            [163.004502652000127, -32.864539678999989],
            [162.99884665200014, -32.879285679],
            [162.993106652000193, -32.894008678999953],
            [162.987284652000227, -32.908708678999957],
            [162.98138065200024, -32.92338567900002],
            [162.975393652000122, -32.938039678999957],
            [162.969323652000156, -32.952669678999953],
            [162.963171652000057, -32.967274679000013],
            [162.95693665200011, -32.981855679000034],
            [162.95062065200014, -32.996411678999948],
            [162.944221652000209, -33.01094267900001],
            [162.937741652000142, -33.025447678999967],
            [162.93117865200017, -33.039927678999973],
            [162.924534652000119, -33.054380678999976],
            [162.917808652000218, -33.06880767899996],
            [162.911000652000126, -33.083207678999941],
            [162.904111652000239, -33.097580678999975],
            [162.897141652000158, -33.111925679000024],
            [162.890089652000114, -33.126243678999955],
            [162.882957652000215, -33.140532678999975],
            [162.875743652000182, -33.154793679],
            [162.86844965200018, -33.169026679],
            [162.861074652000156, -33.183229679],
            [162.853618652000165, -33.197403678999933],
            [162.846082652000149, -33.211547679000034],
            [162.838465652000167, -33.225662678999967],
            [162.830768652000216, -33.239746679],
            [162.822991652000127, -33.25379967899994],
            [162.815134652000182, -33.267821678999979],
            [162.807198652000096, -33.281812679000041],
            [162.799181652000158, -33.295772679000024],
            [162.791086652000246, -33.309699678999934],
            [162.782910652000197, -33.323594678999953],
            [162.774656652000175, -33.337457679],
            [162.766322652000184, -33.351287678999967],
            [162.757910652000163, -33.365084678999949],
            [162.749418652000173, -33.378847678999975],
            [162.74084865200021, -33.392576678999944],
            [162.732200652000103, -33.406272679000011],
            [162.723473652000195, -33.419933678999939],
            [162.714668652000256, -33.433559679],
            [162.705786652000057, -33.447150679],
            [162.696825652000058, -33.460706678999955],
            [162.687786652000199, -33.474226679000026],
            [162.678671652000077, -33.487710678999974],
            [162.669477651000165, -33.501158678999957],
            [162.660207651000206, -33.514570678999974],
            [162.650860651000158, -33.527944678999958],
            [162.64143565100008, -33.541282679],
            [162.631935651000077, -33.554581679],
            [162.622357651000101, -33.567843678999949],
            [162.612704651000143, -33.581067678999958],
            [162.60297465100021, -33.594253679000033],
            [162.593169651000125, -33.60740067899998],
            [162.583287651000234, -33.620508678999983],
            [162.573331651000245, -33.633576678999958],
            [162.563299651000222, -33.646606679],
            [162.55319265100016, -33.65959567900002],
            [162.543009651000062, -33.672544678999927],
            [162.532753651000206, -33.685452679],
            [162.522421651000144, -33.698320678999949],
            [162.512016651000209, -33.711147678999978],
            [162.501536651000123, -33.723932678999986],
            [162.490982651000166, -33.736675678999987],
            [162.480354651000169, -33.749377678999977],
            [162.469653651000186, -33.762037679999949],
            [162.458879651000217, -33.774653680000014],
            [162.448032651000148, -33.787227679999987],
            [162.437112651000263, -33.799758680000039],
            [162.426119651000107, -33.812246679999987],
            [162.415053651000193, -33.824690679999946],
            [162.403916651000117, -33.837090679999989],
            [162.392706651000111, -33.849445680000045],
            [162.381425651000114, -33.86175768],
            [162.370073651000183, -33.874023679999979],
            [162.358648651000152, -33.886244679999947],
            [162.347153651000127, -33.898420680000029],
            [162.335587651000168, -33.910550680000028],
            [162.323951651000101, -33.922634679999945],
            [162.3122446510001, -33.934672679999977],
            [162.300467651000162, -33.946663680000015],
            [162.28862065100023, -33.958608679999983],
            [162.276704651000188, -33.970506679999971],
            [162.264718651000095, -33.98235668],
            [162.252663651000177, -33.994158679999941],
            [162.240539651000148, -34.00591368],
            [162.22834665100018, -34.01762068],
            [162.216086651000154, -34.029278680000019],
            [162.203756651000134, -34.040887679999983],
            [162.191360651000167, -34.052448679999983],
            [162.178895651000147, -34.063959680000011],
            [162.166363651000182, -34.075421679999977],
            [162.153764651000216, -34.08683268],
            [162.141098651000249, -34.098194679999963],
            [162.128366651000107, -34.109506680000038],
            [162.115567651000248, -34.12076768],
            [162.102703651000098, -34.13197768],
            [162.089772651000175, -34.143136679999941],
            [162.076777651000185, -34.154244680000019],
            [162.063716651000078, -34.165300679999973],
            [162.050590651000192, -34.176304679999973],
            [162.037399651000243, -34.187256680000033],
            [162.024144651000057, -34.198156679999954],
            [162.01082565100026, -34.209003680000023],
            [161.997442651000171, -34.219798679999968],
            [161.98399665100024, -34.230539679999964],
            [161.97048665100013, -34.24122668],
            [161.956914651000176, -34.251860679999979],
            [161.943279651000097, -34.262441679999981],
            [161.929582651000118, -34.272967679999951],
            [161.915822651000241, -34.28343868],
            [161.902001651000234, -34.293855680000021],
            [161.888118651000042, -34.30421768],
            [161.874175651000229, -34.31452468],
            [161.860170651000175, -34.324776679999943],
            [161.846105651000101, -34.334972679999979],
            [161.831980651000237, -34.345112679999986],
            [161.817794651000128, -34.355197679999975],
            [161.803550651000108, -34.365224679999969],
            [161.789245651000186, -34.375196679999945],
            [161.77488265100024, -34.38511068],
            [161.760461651000043, -34.394968680000034],
            [161.745980651000224, -34.404768679999989],
            [161.731442651000208, -34.414511680000018],
            [161.716847651000165, -34.424196679999952],
            [161.702193651000044, -34.433823679999975],
            [161.687483651000178, -34.443392679999988],
            [161.672716651000229, -34.452902680000022],
            [161.657893651000251, -34.462354680000033],
            [161.643013651000189, -34.471747679999979],
            [161.628078651000266, -34.48108168],
            [161.613088651000254, -34.490356679999948],
            [161.59804265000011, -34.499571679999988],
            [161.582941650000151, -34.508727679999936],
            [161.567787650000042, -34.51782268],
            [161.552578650000186, -34.526858679999975],
            [161.537315650000238, -34.535833679999953],
            [161.52199965000014, -34.544747679999958],
            [161.506630650000119, -34.553601679999971],
            [161.491209650000229, -34.562394680000011],
            [161.475735650000189, -34.571125679999966],
            [161.460209650000053, -34.579795679999947],
            [161.444631650000048, -34.58840468],
            [161.429002650000172, -34.596950679999964],
            [161.413322650000083, -34.60543568],
            [161.397592650000121, -34.613857679999967],
            [161.381811650000174, -34.622217679999963],
            [161.365981650000123, -34.630515679999988],
            [161.350101650000084, -34.638749679999947],
            [161.33417265000017, -34.646921679999934],
            [161.318194650000208, -34.655029680000041],
            [161.302168650000255, -34.66307468],
            [161.286093650000254, -34.671055679999981],
            [161.269971650000258, -34.67897368],
            [161.25380265000004, -34.686826679999967],
            [161.237586650000168, -34.69461568],
            [161.221323650000073, -34.70234068],
            [161.205014650000152, -34.71000168],
            [161.188659650000233, -34.717597680000011],
            [161.172259650000257, -34.725128679999983],
            [161.155813650000113, -34.73259368],
            [161.139323650000136, -34.739994679999967],
            [161.122789650000101, -34.747329679999964],
            [161.10621165000012, -34.754598680000029],
            [161.089589650000249, -34.761802680000031],
            [161.07292465000026, -34.768940679999986],
            [161.056216650000039, -34.77601268],
            [161.039466650000207, -34.783017679999972],
            [161.022674650000141, -34.789956679999989],
            [161.005840650000124, -34.796828679999962],
            [160.988965650000097, -34.803634679999988],
            [160.972049650000059, -34.810372679999986],
            [160.955093650000236, -34.817044680000024],
            [160.938097650000174, -34.823648679999948],
            [160.921061650000212, -34.830184679999931],
            [160.903986650000178, -34.836653680999959],
            [160.886872650000186, -34.843055680999953],
            [160.869720650000232, -34.849388680999922],
            [160.852530650000148, -34.855654681000033],
            [160.835301650000162, -34.861851681000033],
            [160.818036650000096, -34.867980681],
            [160.80073465000018, -34.87404068099994],
            [160.783396650000128, -34.880032680999932],
            [160.766021650000113, -34.885955681],
            [160.748611650000129, -34.891809681000041],
            [160.731166650000119, -34.897594680999958],
            [160.713685650000201, -34.903310680999951],
            [160.696171650000252, -34.908956681],
            [160.678623650000219, -34.914533680999952],
            [160.661041650000158, -34.920040680999975],
            [160.643425650000182, -34.925478680999959],
            [160.625778650000058, -34.930846680999935],
            [160.608097650000133, -34.93614368099999],
            [160.590385650000115, -34.941371681],
            [160.57264265000012, -34.946528681000018],
            [160.554867650000261, -34.951615681],
            [160.537062650000195, -34.956632680999988],
            [160.519227649000214, -34.961577680999952],
            [160.501362649000185, -34.966452681],
            [160.483467649000119, -34.971257681000012],
            [160.465544649000236, -34.975990681000027],
            [160.4475926490002, -34.980652681000024],
            [160.429612649000234, -34.985243680999915],
            [160.411604649000225, -34.989762680999988],
            [160.39356964900017, -34.994211680999953],
            [160.37550764900007, -34.998587681],
            [160.357419649000207, -35.002892680999949],
            [160.339305649000181, -35.007125680999977],
            [160.321166649000105, -35.011287681],
            [160.303001649000095, -35.015376681000021],
            [160.284812649000145, -35.019394680999937],
            [160.266599649000256, -35.023339681000024],
            [160.248362649000256, -35.027212681000023],
            [160.230101649000204, -35.031013680999934],
            [160.211818649000207, -35.034741681000014],
            [160.193512649000155, -35.038397681],
            [160.175184649000101, -35.041980680999984],
            [160.156835649000215, -35.045490680999961],
            [160.13860064900021, -35.048904681],
            [160.120344649000145, -35.052245680999945],
            [160.102069649000185, -35.055515680999974],
            [160.083774649000162, -35.058712680999989],
            [160.065459649000189, -35.061838681],
            [160.047127649000146, -35.064891681],
            [160.028776649000207, -35.067872681000011],
            [160.0104076490002, -35.070782681],
            [159.992021649000179, -35.073618681],
            [159.973617649000147, -35.076383680999982],
            [159.955198649000152, -35.079075680999964],
            [159.936762649000201, -35.081694680999945],
            [159.918311649000174, -35.084241681],
            [159.89984464900013, -35.086715680999973],
            [159.881363649000178, -35.089117680999948],
            [159.862867649000151, -35.091446681],
            [159.8443586490001, -35.093702680999968],
            [159.82583564900014, -35.095885681000027],
            [159.8072996490001, -35.097995680999986],
            [159.788751649000204, -35.100032680999959],
            [159.770190649000114, -35.101997681],
            [159.751618649000108, -35.103888680999972],
            [159.733035649000129, -35.105706681000029],
            [159.714441649000179, -35.107451680999986],
            [159.695836649000086, -35.109123681000042],
            [159.677222649000242, -35.110722681],
            [159.658598649000197, -35.112247680999971],
            [159.639965649000175, -35.113699680999943],
            [159.621324649000059, -35.115078681],
            [159.602674649000079, -35.116383680999974],
            [159.584017649000117, -35.117615680999947],
            [159.565352649000062, -35.11877468099992],
            [159.546681649000192, -35.11985968099998],
            [159.528003649000112, -35.120870680999971],
            [159.509320649000216, -35.121809680999945],
            [159.490631649000221, -35.122673681000023],
            [159.471937649000239, -35.123464681000016],
            [159.453238649000099, -35.124182681],
            [159.43453564800015, -35.124826681],
            [159.415829648000255, -35.125396681],
            [159.397119648000199, -35.125893681],
            [159.378407648000206, -35.126316681],
            [159.359692648000106, -35.126665681],
            [159.340975648000068, -35.126941680999934],
            [159.322257648000146, -35.12714368099995],
            [159.303538648000227, -35.127271680999968],
            [159.284819648000195, -35.127326680999985],
            [159.266099648000107, -35.127308680999917],
            [159.247379648000191, -35.127215680999953],
            [159.228661648000099, -35.127049680999988],
            [159.209943648000177, -35.126809680999941],
            [159.191228648000134, -35.126496680999978],
            [159.172514648000089, -35.126109681000017],
            [159.153803648000206, -35.125648680999973],
            [159.135095648000203, -35.125114681000028],
            [159.116390648000191, -35.124506680999986],
            [159.097690648000167, -35.123824680999959],
            [159.078993648000193, -35.123069681000018],
            [159.060302648000203, -35.122241681],
            [159.041615648000089, -35.121338680999969],
            [159.022935648000185, -35.120363680999944],
            [159.004260648000098, -35.119314680999921],
            [158.985592648000221, -35.118191681],
            [158.966932648000096, -35.116995680999977],
            [158.948278648000127, -35.115725680999972],
            [158.929633648000134, -35.114383680999964],
            [158.910996648000065, -35.112966680999961],
            [158.892367648000203, -35.11147768099994],
            [158.873748648000202, -35.109914681000035],
            [158.855139648000232, -35.108278680999931],
            [158.836539648000127, -35.106569680999925],
            [158.817951648000218, -35.104786681000022],
            [158.799373648000056, -35.102931681000015],
            [158.780807648000092, -35.101002681000011],
            [158.762252648000157, -35.099001681],
            [158.743710648000132, -35.096926681],
            [158.725181648000188, -35.094779681],
            [158.706665648000097, -35.092558681],
            [158.688163648000199, -35.090265681],
            [158.66967464800021, -35.087899680999982],
            [158.651201648000125, -35.085460680999972],
            [158.632742648000232, -35.082949680999945],
            [158.61429864800013, -35.080365680999932],
            [158.595871648000212, -35.077708680999919],
            [158.577460648000198, -35.074979680999988],
            [158.559065648000256, -35.072178680999954],
            [158.540688648000042, -35.069304681],
            [158.52232864800024, -35.066358680999969],
            [158.503986648000165, -35.063340680999929],
            [158.485663648000155, -35.060250680999971],
            [158.467358648000101, -35.057087681000013],
            [158.449073648000223, -35.053853681000035],
            [158.43080864800018, -35.050547680999969],
            [158.412563648000145, -35.047169680999971],
            [158.394338648000058, -35.043719680999985],
            [158.375970647000116, -35.040166680999988],
            [158.357624647000165, -35.036540681],
            [158.339300647000158, -35.032841681],
            [158.320998647000096, -35.029070681000022],
            [158.302720647000257, -35.025226680999936],
            [158.284466647000073, -35.021309680999934],
            [158.266235647000116, -35.01732068099993],
            [158.248029647000095, -35.013259681],
            [158.229848647000182, -35.009125681],
            [158.211693647000203, -35.004920680999973],
            [158.193563647000161, -35.000642680999945],
            [158.175459647000167, -34.996293681],
            [158.157383647000216, -34.991871680999964],
            [158.139333647000143, -34.987378681],
            [158.121311647000169, -34.982813681000039],
            [158.103316647000185, -34.978177680999963],
            [158.085350647000126, -34.973470680999966],
            [158.067413647000166, -34.968691680999967],
            [158.049506647000186, -34.963841680999963],
            [158.031628647000133, -34.958920680999938],
            [158.013780647000232, -34.953928681],
            [157.995962647000084, -34.948865681000029],
            [157.978176647000197, -34.943732680999958],
            [157.960420647000177, -34.938528680999966],
            [157.942697647000074, -34.933253680999968],
            [157.925006647000117, -34.927909681000017],
            [157.907347647000137, -34.922494680999975],
            [157.889722647000241, -34.91700968100001],
            [157.872130647000205, -34.911454681000023],
            [157.854572647000083, -34.905830681],
            [157.837048647000159, -34.900136680999964],
            [157.819559647000148, -34.894372681],
            [157.802105647000161, -34.888539680999926],
            [157.78468764700014, -34.882637681],
            [157.767304647000202, -34.876666680999961],
            [157.749958647000113, -34.87062668099999],
            [157.732649647000045, -34.864517681],
            [157.715377647000224, -34.858339680999975],
            [157.69814264700014, -34.852093680999914],
            [157.680945647000129, -34.84577968100001],
            [157.663787647000134, -34.839396680999982],
            [157.646668647000155, -34.832945681000027],
            [157.629588647000247, -34.826427679999938],
            [157.612547647000071, -34.81984168],
            [157.595546647000248, -34.813187679999928],
            [157.578586647000208, -34.806466680000014],
            [157.561667647000178, -34.799677679999974],
            [157.544788647000104, -34.792822679999972],
            [157.527952647000205, -34.785900679999941],
            [157.511157647000203, -34.778910679999967],
            [157.49440564700015, -34.771855679999945],
            [157.477695647000104, -34.764733679999978],
            [157.461029647000117, -34.757544679999967],
            [157.444406647000079, -34.75029068],
            [157.427827647000214, -34.74297068],
            [157.411292647000238, -34.735584679999945],
            [157.394802647000262, -34.728132679999931],
            [157.378357647000115, -34.720615679999966],
            [157.361957647000139, -34.713033680000038],
            [157.345604647000215, -34.705386679999975],
            [157.329296647000234, -34.697674680000034],
            [157.313035647000191, -34.689897679999959],
            [157.296821646000154, -34.68205768],
            [157.280654646000158, -34.67415168],
            [157.264535646000155, -34.66618268000002],
            [157.248464646000144, -34.65814968],
            [157.232442646000237, -34.65005268],
            [157.216468646000152, -34.641892679999941],
            [157.200543646000057, -34.63366868],
            [157.184668646000233, -34.62538168],
            [157.168843646000056, -34.617031679999954],
            [157.153068646000094, -34.60861968],
            [157.137344646000173, -34.60014468],
            [157.12167064600024, -34.59160768000001],
            [157.106048646000232, -34.583008680000049],
            [157.09047864600015, -34.574347680000017],
            [157.074959646000167, -34.565624679999914],
            [157.059493646000107, -34.556839679999925],
            [157.044080646000253, -34.547994679999945],
            [157.028720646000153, -34.53908768],
            [157.013413646000203, -34.530120680000039],
            [156.998160646000173, -34.521091680000026],
            [156.982961646000177, -34.51200368],
            [156.9678176460001, -34.502854680000013],
            [156.952727646000227, -34.493645679999943],
            [156.937693646000213, -34.484377679999952],
            [156.922714646000117, -34.475049679999984],
            [156.907790646000223, -34.465661680000011],
            [156.892923646000241, -34.456215679999957],
            [156.878112646000119, -34.44670968],
            [156.863359646000077, -34.437145679999944],
            [156.848662646000122, -34.427523679999979],
            [156.834022646000193, -34.417842680000021],
            [156.819642646000176, -34.408515679999951],
            [156.805316646000136, -34.399132680000037],
            [156.791044646000188, -34.389695679999932],
            [156.776827646000157, -34.380202679999982],
            [156.762666646000156, -34.370654680000015],
            [156.748560646000186, -34.361051679999932],
            [156.734510646000132, -34.35139468],
            [156.720515646000166, -34.34168368],
            [156.706578646000168, -34.331917679999975],
            [156.692696646000144, -34.322098679999968],
            [156.678872646000144, -34.312225680000012],
            [156.665105646000058, -34.302299680000033],
            [156.651396646000222, -34.292320679999932],
            [156.637744646000243, -34.282287679999982],
            [156.624151646000172, -34.272202679999978],
            [156.610616646000182, -34.262065680000035],
            [156.597140646000099, -34.251875679999969],
            [156.583722646000211, -34.241633679999964],
            [156.570220646000195, -34.231228679999958],
            [156.556778646000254, -34.220770679999916],
            [156.543398646000043, -34.210260680000019],
            [156.530079646000246, -34.19969868],
            [156.516821646000182, -34.189083679999939],
            [156.503626646000185, -34.178417679999924],
            [156.490493646000147, -34.167700679999953],
            [156.477422646000178, -34.156931679999943],
            [156.464414646000108, -34.146111679999976],
            [156.451468646000166, -34.135240679999967],
            [156.438587646000116, -34.124319679999985],
            [156.425768646000137, -34.113348679999959],
            [156.413014646000107, -34.102326679999976],
            [156.400323646000146, -34.091255680000032],
            [156.387697646000134, -34.080134680000029],
            [156.375136646000186, -34.068964679999965],
            [156.36263964600019, -34.057745680000025],
            [156.350208646000141, -34.046478679999936],
            [156.337841646000101, -34.035161679999973],
            [156.325541646000062, -34.023797680000015],
            [156.313306646000257, -34.012384680000011],
            [156.301138646000112, -34.000924680000026],
            [156.289035646000144, -33.989416679999977],
            [156.277000646000175, -33.977861679999947],
            [156.265031646000097, -33.966260679999934],
            [156.25313064600013, -33.954611680000028],
            [156.241295646000111, -33.942916679999968],
            [156.229529646000202, -33.93117568],
            [156.217830645000078, -33.919388679999955],
            [156.206199645000169, -33.907555680000016],
            [156.194636645000088, -33.89567768],
            [156.183142645000231, -33.883754679999981],
            [156.1717176450002, -33.871786679999985],
            [156.160360645000225, -33.859774679999973],
            [156.153399645000064, -33.872907680000012],
            [156.146369645000135, -33.886016680000012],
            [156.139269645000041, -33.89909868],
            [156.132100645000179, -33.912155679999955],
            [156.124861645000038, -33.925185679999984],
            [156.117553645000129, -33.93818968],
            [156.110175645000112, -33.951165680000017],
            [156.102729645000153, -33.96411568],
            [156.095214645000198, -33.977038679999978],
            [156.087112645000133, -33.990810680000038],
            [156.078931645000097, -34.00455168000002],
            [156.070673645000198, -34.018259680000014],
            [156.062336645000158, -34.031936679999944],
            [156.053921645000145, -34.045579679999975],
            [156.045429645000155, -34.059190680000043],
            [156.036859645000192, -34.072768680000024],
            [156.028211645000084, -34.086312679999963],
            [156.019486645000171, -34.099822679999917],
            [156.010684645000111, -34.11329868],
            [156.001805645000246, -34.126740680000026],
            [155.992848645000237, -34.140147679999984],
            [155.983816645000132, -34.153519679999988],
            [155.974706645000168, -34.166856680000024],
            [155.965521645000223, -34.18015768],
            [155.956259645000188, -34.19342268000004],
            [155.946921645000117, -34.206651679999936],
            [155.937507645000125, -34.219844679999952],
            [155.928017645000153, -34.232999680000034],
            [155.918452645000201, -34.246118679999967],
            [155.908812645000154, -34.25920068],
            [155.899096645000242, -34.27224368],
            [155.889305645000064, -34.28524968],
            [155.87944064500013, -34.298217679999979],
            [155.869500645000159, -34.31114668],
            [155.859485645000092, -34.32403668],
            [155.849397645000209, -34.336887679999975],
            [155.839234645000175, -34.34969968],
            [155.828997645000214, -34.362470680000015],
            [155.818687645000153, -34.375202679999987],
            [155.808303645000109, -34.387894679999945],
            [155.797846645000078, -34.400545679999979],
            [155.787316645000118, -34.413156679999986],
            [155.776713645000171, -34.425725679999985],
            [155.766038645000123, -34.43825368],
            [155.755290645000144, -34.450739680000012],
            [155.744470645000177, -34.463183679999972],
            [155.733577645000224, -34.47558568],
            [155.722613645000166, -34.487945679999939],
            [155.711578645000174, -34.500261679999966],
            [155.700470645000252, -34.512535679999971],
            [155.689292645000165, -34.524765679999987],
            [155.678043645000145, -34.53695268],
            [155.666723645000189, -34.549094679999925],
            [155.655333645000127, -34.561193680000017],
            [155.643872645000243, -34.573247679999952],
            [155.632341645000253, -34.585256679999972],
            [155.620671645000101, -34.59729368],
            [155.60893164500024, -34.609283679999962],
            [155.59712064500016, -34.62122868],
            [155.585239645000257, -34.633126680000018],
            [155.573289645000131, -34.64497868],
            [155.561269645000181, -34.656783679999947],
            [155.549180645000177, -34.66854068],
            [155.537022645000178, -34.680250679999972],
            [155.524796645000066, -34.691912679999973],
            [155.512501645000185, -34.70352768],
            [155.500137645000251, -34.715093679999953],
            [155.487706645000145, -34.726610680000036],
            [155.475207645000154, -34.73807968],
            [155.462640645000164, -34.749499679999929],
            [155.450007645000113, -34.760869680000013],
            [155.43730664500012, -34.772190679999952],
            [155.424539645000237, -34.78346168000003],
            [155.411705645000183, -34.794681679999954],
            [155.398806645000064, -34.80585268],
            [155.385840645000172, -34.816972679999935],
            [155.372809645000217, -34.828041679999984],
            [155.359713645000141, -34.839058681],
            [155.346551645000119, -34.850025680999963],
            [155.333325645000201, -34.860939680999977],
            [155.320034645000163, -34.871802680999949],
            [155.306680645000114, -34.882613680999967],
            [155.293261645000058, -34.893371680999962],
            [155.279778645000107, -34.904077681],
            [155.266233645000199, -34.914729681000011],
            [155.252624645000111, -34.92532968099998],
            [155.238953645000066, -34.935875681000013],
            [155.225219645000124, -34.94636868100001],
            [155.211423645000224, -34.956806680999989],
            [155.197565645000083, -34.967191681000031],
            [155.187079645000125, -34.977168680999966],
            [155.176542645000126, -34.987110680999947],
            [155.165955645000139, -34.997016680999963],
            [155.155318644000232, -35.006887681],
            [155.144631644000157, -35.016723681],
            [155.132229644000205, -35.028037680999986],
            [155.119761644000135, -35.039302681],
            [155.107227644000119, -35.050520680999952],
            [155.094627644000212, -35.061689681000018],
            [155.081963644000183, -35.072810681000021],
            [155.06923464400009, -35.083882680999963],
            [155.063819644000233, -35.097150680999974],
            [155.05833664400015, -35.110399680999919],
            [155.052783644000243, -35.123629680999983],
            [155.047161644000226, -35.136840681],
            [155.041469644000159, -35.150030681000032],
            [155.035709644000264, -35.163201681],
            [155.029533644000054, -35.177589680999958],
            [155.023274644000168, -35.191953680999973],
            [155.016933644000261, -35.206293681000048],
            [155.010511644000104, -35.220609680999985],
            [155.0040066440001, -35.234901681],
            [154.997420644000186, -35.249167680999975],
            [154.990752644000196, -35.263408680999945],
            [154.984003644000182, -35.277624680999963],
            [154.977172644000206, -35.291814680999963],
            [154.970260644000206, -35.305978680999942],
            [154.963267644000183, -35.320115681],
            [154.956193644000251, -35.334226680999961],
            [154.949037644000242, -35.348309680999989],
            [154.941801644000151, -35.362365681],
            [154.934484644000207, -35.376394681],
            [154.92708664400007, -35.390394681000018],
            [154.919608644000192, -35.404366681000013],
            [154.912050644000118, -35.418310681],
            [154.904411644000191, -35.432225680999977],
            [154.896692644000126, -35.446110680999979],
            [154.888893644000092, -35.459966680999969],
            [154.881014644000203, -35.473792680999964],
            [154.879045644000172, -35.488532681],
            [154.876995644000118, -35.50326468099999],
            [154.874865644000096, -35.517989681000032],
            [154.872654644000107, -35.532706681000022],
            [154.870363644000264, -35.547415680999976],
            [154.867991644000114, -35.562115681],
            [154.865538644000111, -35.576806680999965],
            [154.863004644000142, -35.591489681],
            [154.860390644000205, -35.606161681],
            [154.857695644000074, -35.620824680999974],
            [154.854920644000146, -35.635477681],
            [154.852064644000194, -35.650120681],
            [154.849468644000154, -35.663268681000034],
            [154.846807644000165, -35.676408680999941],
            [154.84408264400011, -35.689538680999988],
            [154.841291644000108, -35.702660681],
            [154.838435644000157, -35.715772680999976],
            [154.835514644000142, -35.728875681000019],
            [154.832065644000153, -35.744014681000024],
            [154.828528644000158, -35.759141681000017],
            [154.824905644000211, -35.774253681],
            [154.821195644000198, -35.789352681],
            [154.817398644000178, -35.804437680999982],
            [154.813515644000091, -35.819507680999962],
            [154.809544644000226, -35.834561680999954],
            [154.805531644000183, -35.849446680999989],
            [154.801433644000241, -35.864316680999949],
            [154.797250644000172, -35.879169681],
            [154.792982644000091, -35.894007680999977],
            [154.788629644000167, -35.908829681999961],
            [154.784192644000115, -35.923634681999957],
            [154.779670644000163, -35.938423681999964],
            [154.775064644000082, -35.953194681999989],
            [154.770294644000131, -35.968196682000027],
            [154.765437644000059, -35.983179682000014],
            [154.760493644000263, -35.998144682],
            [154.755461644000121, -36.013089681999958],
            [154.750343644000139, -36.028016681999915],
            [154.745137644000152, -36.042922682000011],
            [154.739844644000158, -36.057809682000041],
            [154.734464644000099, -36.072675682000025],
            [154.728997644000202, -36.087520681999948],
            [154.723444644000182, -36.102345682],
            [154.717804644000097, -36.117148681999922],
            [154.712077644000175, -36.131929681999978],
            [154.706263644000074, -36.146688681999976],
            [154.700363644000134, -36.161425681999944],
            [154.694377644000184, -36.176140682000039],
            [154.688304644000056, -36.190831682000024],
            [154.682145644000258, -36.205499682000053],
            [154.675984644000238, -36.219999682000037],
            [154.669738644000091, -36.234476681999979],
            [154.6634086440001, -36.248929681999982],
            [154.656993644000096, -36.263358681999961],
            [154.650495644000245, -36.277763681999986],
            [154.644009644000135, -36.292084682],
            [154.637440644000179, -36.306380681999968],
            [154.630788644000205, -36.320651682000019],
            [154.624053644000099, -36.334897682000033],
            [154.617235644000147, -36.349118682000025],
            [154.61033564400023, -36.363313682],
            [154.603352644000239, -36.37748268199995],
            [154.596287644000171, -36.391624681999971],
            [154.589139644000198, -36.405740681999987],
            [154.581909644000262, -36.419829681999985],
            [154.574597644000193, -36.433890681999969],
            [154.567203644000102, -36.447924681999936],
            [154.559727644000219, -36.461930682],
            [154.552170644000142, -36.475908681999954],
            [154.544531644000045, -36.489857682],
            [154.536810644000155, -36.503778681999947],
            [154.529008644000243, -36.51766968199999],
            [154.521124644000196, -36.531531682000036],
            [154.513159644000183, -36.545363682],
            [154.505114644000145, -36.559165681999957],
            [154.496987644000257, -36.572937682000017],
            [154.488780644000116, -36.586678682],
            [154.480492644000122, -36.600388681999988],
            [154.472124644000161, -36.614066681999986],
            [154.463675644000176, -36.627714682],
            [154.455147644000164, -36.641329681999949],
            [154.446538644000071, -36.654912681999988],
            [154.437849644000124, -36.668463681999967],
            [154.429080644000038, -36.681981681999972],
            [154.420232644000151, -36.695466682],
            [154.411305644000237, -36.708917681999964],
            [154.402298644000183, -36.722335681999951],
            [154.393212644000158, -36.735719681999967],
            [154.384047644000162, -36.749069682000012],
            [154.374804644000193, -36.762384682],
            [154.365481644000198, -36.775664681999942],
            [154.356081644000113, -36.788909682],
            [154.346601644000174, -36.802119682000011],
            [154.337044644000258, -36.815293681999975],
            [154.327409644000198, -36.828431681999973],
            [154.317696644000165, -36.841533682000019],
            [154.307905644000158, -36.854598682000017],
            [154.298037644000175, -36.86762668199998],
            [154.295032644000258, -36.882152681999983],
            [154.291946644000092, -36.89666668199996],
            [154.288779644000243, -36.911170682000019],
            [154.285531644000258, -36.925662681999952],
            [154.28220264400025, -36.94014368199997],
            [154.27879364400016, -36.954611681999964],
            [154.275303644000161, -36.96906768199996],
            [154.271733644000193, -36.983511683000017],
            [154.268081644000091, -36.997941682999979],
            [154.264349644000191, -37.012359682999929],
            [154.260537644000095, -37.026762682999973],
            [154.256644644000204, -37.04115368299999],
            [154.252670644000119, -37.055529683000017],
            [154.248616644000236, -37.069890683000054],
            [154.24448164400016, -37.084237682999984],
            [154.240266644000116, -37.098569682999923],
            [154.235970644000105, -37.112886682999957],
            [154.231594644000239, -37.127188682999986],
            [154.227441644000152, -37.140520682999963],
            [154.223219644000238, -37.15383968299993],
            [154.224205644000193, -37.168925683],
            [154.225106644000135, -37.184014682999958],
            [154.225922644000235, -37.199106682999982],
            [154.226653644000152, -37.214201682999985],
            [154.227299644000112, -37.229299682999979],
            [154.227860644000174, -37.244398682999972],
            [154.228336644000052, -37.259499682999945],
            [154.228726644000147, -37.274602682999983],
            [154.229031644000173, -37.289706683000034],
            [154.229251644000243, -37.304811682999983],
            [154.229385644000246, -37.319917683000014],
            [154.229434644000122, -37.335023683000045],
            [154.229397644000159, -37.350129683],
            [154.229275644000182, -37.365234683000025],
            [154.229067644000139, -37.380339682999974],
            [154.228773644000142, -37.395443682999925],
            [154.228394644000133, -37.410545682999981],
            [154.227922644000074, -37.425885682999919],
            [154.227361644000183, -37.441223682999961],
            [154.226711644000062, -37.456558683000026],
            [154.225973644000106, -37.47189168300001],
            [154.225146644000262, -37.487221683000016],
            [154.224231644000128, -37.502548682999944],
            [154.223227644000104, -37.517871683],
            [154.222134644000249, -37.533190682999972],
            [154.220952644000164, -37.548505682999973],
            [154.219681644000076, -37.563816683],
            [154.218322644000153, -37.57912268299998],
            [154.216873644000174, -37.594422682999976],
            [154.215336644000189, -37.609717682999928],
            [154.213710644000201, -37.625006683],
            [154.211995644000154, -37.640289682999935],
            [154.210191644000105, -37.655566682999989],
            [154.208297644000055, -37.670835683],
            [154.206315644000114, -37.686098682999983],
            [154.20424464400017, -37.701353683],
            [154.202084644000166, -37.716600682999967],
            [154.19983564400016, -37.73183968299999],
            [154.197497644000151, -37.747070682999976],
            [154.195070644000253, -37.762292683000013],
            [154.192553644000185, -37.777504683000018],
            [154.189948644000111, -37.792708682999987],
            [154.187254644000149, -37.807901683000026],
            [154.184470644000186, -37.823085682999931],
            [154.181598644000047, -37.838258683],
            [154.178636644000193, -37.853420682999953],
            [154.175586644000163, -37.868572682999982],
            [154.172446644000075, -37.883712682999985],
            [154.169218644000154, -37.89884068299996],
            [154.165900644000232, -37.913956683000023],
            [154.162494644000077, -37.929060682999975],
            [154.158999644000261, -37.944151683000015],
            [154.155414644000217, -37.959229682999947],
            [154.151741644000055, -37.974294683000053],
            [154.147979644000173, -37.989345682999968],
            [154.144128644000119, -38.004382682999974],
            [154.140188644000176, -38.019404683],
            [154.136160644000171, -38.034413682999983],
            [154.132042644000222, -38.049406683],
            [154.127836644000041, -38.064383684],
            [154.123542644000196, -38.079346684000029],
            [154.11915864400018, -38.094292684000052],
            [154.114687644000099, -38.109222684],
            [154.111685644000232, -38.123698684000033],
            [154.108602644000115, -38.138163683999963],
            [154.105437644000091, -38.152617683999964],
            [154.102191644000101, -38.167061684000018],
            [154.098863644000147, -38.181492683999977],
            [154.095454644000171, -38.195912684],
            [154.091962644000176, -38.210319684000027],
            [154.088390644000043, -38.224715683999932],
            [154.084736644000117, -38.239097684000015],
            [154.081426643000185, -38.251848683999967],
            [154.078052643000177, -38.264588683999989],
            [154.074614643000103, -38.277318683999979],
            [154.071113643000245, -38.290037683999955],
            [154.066989643000085, -38.304713684000021],
            [154.062780643000252, -38.319375684],
            [154.058485643000068, -38.334021683999978],
            [154.054105643000213, -38.348652683999973],
            [154.049640643000174, -38.363267683999965],
            [154.045089643000125, -38.377865683999971],
            [154.040453643000234, -38.39244768399999],
            [154.035732643000159, -38.407013683999935],
            [154.030926643000129, -38.421561683999983],
            [154.026035643000199, -38.436092683999959],
            [154.021058643000146, -38.450605684000038],
            [154.01599764300019, -38.465100683999964],
            [154.010851643000109, -38.479577684],
            [154.005619643000188, -38.494035683999968],
            [154.00030364300008, -38.508474683999964],
            [153.994902643000131, -38.522894684],
            [153.989417643000166, -38.537295683999957],
            [153.983847643000246, -38.55167568399996],
            [153.978192643000085, -38.566036684],
            [153.972453643000136, -38.580376683999972],
            [153.966629643000175, -38.594695683999987],
            [153.960721643000198, -38.608994684000038],
            [153.954745643000109, -38.62323468400001],
            [153.948686643000173, -38.63745268400001],
            [153.942543643000107, -38.651649683999977],
            [153.936316643000197, -38.665824683999986],
            [153.930006643000098, -38.679976683999953],
            [153.923612643000155, -38.694105683999965],
            [153.91713564300008, -38.708212683999946],
            [153.91057464300016, -38.722295683999974],
            [153.903930643000223, -38.736354683999977],
            [153.897204643000151, -38.750390684000024],
            [153.890394643000064, -38.764401683999964],
            [153.883501643000187, -38.77838868399995],
            [153.876525643000065, -38.792350684000013],
            [153.869467643000149, -38.80628768400004],
            [153.862326643000102, -38.820198683999962],
            [153.855103643000206, -38.834084683999947],
            [153.847797643000121, -38.847944684],
            [153.842326643000234, -38.858209683999959],
            [153.83680964300018, -38.868459684000015],
            [153.831248643000237, -38.878695683999979],
            [153.829033643000201, -38.882748684000049],
            [153.821266643000143, -38.896846683999982],
            [153.813413643000189, -38.910915684000017],
            [153.80547464300011, -38.924956683999937],
            [153.797449643000135, -38.938967683999962],
            [153.789339643000204, -38.952949683999989],
            [153.781144643000204, -38.966901683999936],
            [153.77849464300013, -38.97135768399994],
            [153.772863643000079, -38.980823683999958],
            [153.764497643000112, -38.994714684],
            [153.756046643000133, -39.008575683999965],
            [153.74751064300014, -39.022404684000037],
            [153.738890643000133, -39.036202683999932],
            [153.730185643000169, -39.049969684000018],
            [153.721396643000134, -39.063703683999947],
            [153.712522643000142, -39.077405683999984],
            [153.703564643000135, -39.091074684000048],
            [153.694522643000113, -39.104710683999954],
            [153.685397643000243, -39.118313683999972],
            [153.676187643000191, -39.13188368499992],
            [153.666895643000231, -39.145418685],
            [153.657518643000202, -39.158920685000012],
            [153.648059643000153, -39.172387684999961],
            [153.638517643000142, -39.185819684999956],
            [153.628891643000117, -39.199216684999982],
            [153.619184643000125, -39.21257868500004],
            [153.609393643000232, -39.225904684999961],
            [153.599520643000261, -39.239193685000032],
            [153.589566643000097, -39.25244768499995],
            [153.579529643000257, -39.265664685],
            [153.569410643000111, -39.27884468500001],
            [153.559210643000114, -39.291987684999981],
            [153.548928643000153, -39.305093685],
            [153.538565643000169, -39.318160685],
            [153.528243643000195, -39.331040684999962],
            [153.51784264300025, -39.343881684999985],
            [153.507363643000161, -39.356685684999974],
            [153.496804643000047, -39.36945168499993],
            [153.486168643000184, -39.382179685000025],
            [153.475453643000122, -39.394868685],
            [153.464660643000258, -39.407518684999957],
            [153.453789643000079, -39.420128684999966],
            [153.442840643000096, -39.432700684999944],
            [153.431814643000138, -39.445231685],
            [153.420927643000113, -39.458121685],
            [153.409960643000176, -39.470971684999967],
            [153.398911643000162, -39.483780685000013],
            [153.38778364300012, -39.496549684999948],
            [153.376575643000109, -39.509276684999961],
            [153.365287643000244, -39.521962684999949],
            [153.353919643000125, -39.534607685000026],
            [153.34247264300015, -39.547210684999982],
            [153.330946643000146, -39.559770684999947],
            [153.319341643000058, -39.57228868499999],
            [153.307658643000167, -39.58476468500001],
            [153.295896643000248, -39.597196684999957],
            [153.284056643000184, -39.609585684999985],
            [153.27213764300015, -39.621930685],
            [153.260141643000139, -39.634232685000029],
            [153.248068643000153, -39.646489684999963],
            [153.235917643000249, -39.658702685],
            [153.223689643000142, -39.670869684999943],
            [153.21138464300023, -39.682992684999988],
            [153.199003643000168, -39.695070685000033],
            [153.186546643000185, -39.70710268500001],
            [153.174012643000168, -39.719088684999988],
            [153.161403643000227, -39.731028684999984],
            [153.148718643000137, -39.742921685000013],
            [153.135958643000237, -39.754768684999945],
            [153.123123643000184, -39.766568685],
            [153.110213643000208, -39.778320684999983],
            [153.09722864300025, -39.790025684999989],
            [153.084169643000138, -39.801682684999932],
            [153.071037643000096, -39.813291685],
            [153.057830643000244, -39.824852684999982],
            [153.044550643000235, -39.836363685000023],
            [153.031197643000183, -39.847827684999984],
            [153.017771643000088, -39.859240684999989],
            [153.004272642000132, -39.87060568499993],
            [152.990701642000118, -39.881919685],
            [152.97705864200023, -39.893184684999923],
            [152.96334464200018, -39.904399684999973],
            [152.949557642000144, -39.915563684999981],
            [152.935700642000057, -39.926676684999947],
            [152.921771642000152, -39.937738684999957],
            [152.90777264200014, -39.94874968500001],
            [152.893703642000133, -39.95970868500001],
            [152.87956364200025, -39.970616684999982],
            [152.865354642000199, -39.981471685],
            [152.851076642000208, -39.992274684999984],
            [152.836728642000168, -40.003025685000011],
            [152.82231164200013, -40.013723685000016],
            [152.825200642000169, -40.028480685000034],
            [152.82800564200025, -40.043247685],
            [152.830725642000147, -40.058024684999978],
            [152.833360642000201, -40.07280968500001],
            [152.833833642000201, -40.075558685],
            [152.835910642000186, -40.087603684999976],
            [152.838375642000216, -40.102406685],
            [152.840755642000232, -40.117217684999943],
            [152.843050642000122, -40.132036684999946],
            [152.845259642000116, -40.146863684999985],
            [152.847383642000153, -40.161697684999979],
            [152.849422642000121, -40.176538685000025],
            [152.851375642000249, -40.191386684999941],
            [152.853242642000083, -40.206240685999987],
            [152.855023642000191, -40.221100686000014],
            [152.856719642000172, -40.235967685999981],
            [152.858328642000089, -40.25083968600002],
            [152.859852642000163, -40.265716686000019],
            [152.861289642000116, -40.28059868599999],
            [152.862640642000173, -40.295485686000021],
            [152.863905642000219, -40.310377686000024],
            [152.865083642000144, -40.325272686],
            [152.866175642000172, -40.340172685999946],
            [152.86718164200019, -40.35507468599998],
            [152.868124642000254, -40.370386686],
            [152.868976642000206, -40.385700686],
            [152.869737642000104, -40.401017685999918],
            [152.87040664200012, -40.416337685999977],
            [152.870983642000198, -40.431659685999946],
            [152.871468642000167, -40.446982685999984],
            [152.871862642000252, -40.462307686000017],
            [152.872163642000118, -40.477634685999945],
            [152.872373642000156, -40.492961685999973],
            [152.872490642000258, -40.508289685999983],
            [152.872516642000193, -40.523616686000011],
            [152.872449642000248, -40.538944686000022],
            [152.872290642000138, -40.55427168599995],
            [152.872038642000263, -40.569598685999978],
            [152.871694642000222, -40.584924686],
            [152.871258642000186, -40.600248685999944],
            [152.870729642000043, -40.615570686],
            [152.870108642000247, -40.630890685999972],
            [152.869394642000231, -40.646208685999966],
            [152.868587642000165, -40.661523685999967],
            [152.867688642000218, -40.67683568599999],
            [152.866696642000221, -40.692144685999935],
            [152.865611642000232, -40.707449686],
            [152.864434642000191, -40.722751686],
            [152.863163642000103, -40.738047685999945],
            [152.861800642000134, -40.753339686],
            [152.860343642000174, -40.768627685999988],
            [152.858794642000106, -40.783909686000015],
            [152.857152642000216, -40.799185685999987],
            [152.855416642000108, -40.814455686],
            [152.853588642000119, -40.829719685999962],
            [152.851667642000081, -40.844976685999967],
            [152.849652642000052, -40.860227686],
            [152.847544642000258, -40.875470686],
            [152.845344642000128, -40.890706685999959],
            [152.843050642000122, -40.905933685999955],
            [152.84066264200024, -40.921153686000011],
            [152.838182642000135, -40.936364685999933],
            [152.835608642000153, -40.951566686],
            [152.832942642000177, -40.966759685999946],
            [152.830182642000153, -40.981942685999954],
            [152.827329642000194, -40.997115686000029],
            [152.824382642000074, -41.012278685999974],
            [152.821342642000246, -41.027431685999986],
            [152.818210642000196, -41.042573685999983],
            [152.814984642000155, -41.057703685999954],
            [152.811664642000181, -41.072822686],
            [152.808252642000213, -41.087930685999922],
            [152.804746642000197, -41.103025686000024],
            [152.801147642000132, -41.118108686000014],
            [152.797455642000187, -41.13317768600001],
            [152.793670642000194, -41.148234685999981],
            [152.789791642000154, -41.163277685999958],
            [152.785820642000232, -41.178307686],
            [152.786948642000112, -41.193466685999979],
            [152.787985642000109, -41.20862868599994],
            [152.788931642000222, -41.223794685999962],
            [152.789786642000166, -41.238963685999963],
            [152.79055064200017, -41.254134685999958],
            [152.791223642000233, -41.269308685999931],
            [152.791805642000242, -41.284484686999974],
            [152.792295642000198, -41.299662687],
            [152.7926946420001, -41.314842686999953],
            [152.793002642000175, -41.330022686999982],
            [152.793218642000198, -41.345204687],
            [152.793343642000224, -41.360385687],
            [152.793376642000197, -41.375568686999927],
            [152.793318642000116, -41.390750687000036],
            [152.793168642000154, -41.405931686999963],
            [152.792926642000197, -41.421112686999976],
            [152.792592642000187, -41.43629268699992],
            [152.79216764200018, -41.451471687000037],
            [152.791649642000124, -41.466648686999989],
            [152.791039642000129, -41.481823686999959],
            [152.790338642000194, -41.496996687000035],
            [152.789544642000152, -41.512166686999933],
            [152.788658642000115, -41.527333686999953],
            [152.787680642000197, -41.542497687],
            [152.786609642000116, -41.55765768699996],
            [152.785447642000264, -41.572814686999948],
            [152.784192642000249, -41.587966686999962],
            [152.782844642000185, -41.603114687],
            [152.781405642000237, -41.618257687],
            [152.779872642000129, -41.633395687],
            [152.778248642000136, -41.648527686999969],
            [152.781378642000135, -41.662685687],
            [152.784427642000168, -41.676853686999948],
            [152.787397642000229, -41.691030687000023],
            [152.790287642000152, -41.705216686999961],
            [152.793096642000052, -41.719412686999931],
            [152.795825642000153, -41.733616686999937],
            [152.79847464200023, -41.747829686999978],
            [152.801043642000167, -41.76205068699997],
            [152.80353064200014, -41.776279687],
            [152.805938642000257, -41.790516687],
            [152.808264642000069, -41.804761686999925],
            [152.810345642000101, -41.817940687000032],
            [152.812357642000137, -41.831125686999926],
            [152.814300642000063, -41.844315686999963],
            [152.81617464200022, -41.857512686999968],
            [152.817978642000099, -41.87071368700002],
            [152.819712642000042, -41.883920686999957],
            [152.821558642000099, -41.898597686999935],
            [152.823318642000146, -41.913280686999968],
            [152.82499364200018, -41.927968686999961],
            [152.826581642000207, -41.942661686999926],
            [152.828083642000053, -41.957360686999948],
            [152.829498642000232, -41.972063686999931],
            [152.830828642000057, -41.986771686999987],
            [152.832070642000161, -42.001483687000018],
            [152.833227642000139, -42.016199687000025],
            [152.834296642000112, -42.030919687],
            [152.835279642000188, -42.045642686999955],
            [152.836175642000086, -42.060367687],
            [152.836984642000203, -42.075096687000013],
            [152.837707642000083, -42.08982768700001],
            [152.838342642000129, -42.104561687],
            [152.838890642000166, -42.119296686999974],
            [152.839351642000139, -42.134033686999942],
            [152.839725642000161, -42.148771686999979],
            [152.840403642000211, -42.163786687000041],
            [152.84098964200021, -42.178803686999984],
            [152.841485642000208, -42.193822687],
            [152.841891642000149, -42.208843686999941],
            [152.842205642000209, -42.223865686999943],
            [152.842429642000155, -42.238887686999959],
            [152.842562642000161, -42.253910686999959],
            [152.842603642000171, -42.268934686999955],
            [152.842554642000124, -42.283957686999955],
            [152.84241464200025, -42.298980686999968],
            [152.842182642000154, -42.31400268699997],
            [152.841860642000171, -42.329023686999989],
            [152.841446642000193, -42.34404368700001],
            [152.841899642000186, -42.358343687999962],
            [152.842270642000159, -42.372644688],
            [152.842559642000168, -42.386945687999955],
            [152.842764642000162, -42.401248687999981],
            [152.842888642000133, -42.415551687999923],
            [152.842928642000146, -42.429854688000034],
            [152.842880642000097, -42.445353687999983],
            [152.842736642000176, -42.460851687999948],
            [152.842493642000051, -42.476349687999985],
            [152.842154642000224, -42.491846687999953],
            [152.841717642000134, -42.507342687999923],
            [152.84118264200012, -42.522836688],
            [152.840550642000125, -42.538327688],
            [152.839821642000203, -42.553817688],
            [152.838994642000245, -42.569303687999948],
            [152.838069642000193, -42.584787688],
            [152.837046642000104, -42.600267687999974],
            [152.83592664200026, -42.615744687999964],
            [152.834707642000097, -42.631216688],
            [152.833391642000123, -42.646684687999951],
            [152.831977642000169, -42.662147687999948],
            [152.830465642000121, -42.677606687999969],
            [152.82885564200015, -42.693059687999934],
            [152.827146642000145, -42.708506687999929],
            [152.8253406420001, -42.723947687999967],
            [152.823436642000132, -42.739381687999966],
            [152.821433642000244, -42.754809688],
            [152.819332642000205, -42.77023068799997],
            [152.817133642000073, -42.785643687999922],
            [152.814836642000188, -42.801048688],
            [152.812440642000155, -42.816446688000028],
            [152.809946642000142, -42.831835687999941],
            [152.807354642000092, -42.847215688],
            [152.804664642000176, -42.862586688],
            [152.801875642000169, -42.877947688],
            [152.798988642000126, -42.893299687999956],
            [152.796002642000161, -42.908641687999975],
            [152.79291864200016, -42.923972687999978],
            [152.789736642000236, -42.939292687999952],
            [152.786455642000163, -42.954601687999897],
            [152.783076642000111, -42.969899687999828],
            [152.779599642000193, -42.985185687999831],
            [152.776023642000069, -43.000458688],
            [152.772349642000194, -43.015719687999891],
            [152.768576642000227, -43.03096868800003],
            [152.764705642000109, -43.046203688],
            [152.760736642000239, -43.061424687999946],
            [152.756668642000108, -43.076632687999975],
            [152.752503642000107, -43.091826688000012],
            [152.748238642000189, -43.107005687999859],
            [152.743876642000117, -43.122169687999985],
            [152.739415642000068, -43.137318687999937],
            [152.734856642000153, -43.152451687999886],
            [152.730199642000201, -43.167568687999946],
            [152.725444642000156, -43.182670688],
            [152.720591642000073, -43.197754687999989],
            [152.71563964200007, -43.212822687999989],
            [152.710590642000199, -43.227873687999988],
            [152.705442642000122, -43.242905687999937],
            [152.70019764200012, -43.257920688],
            [152.694853642000254, -43.272917687999978],
            [152.689412642000121, -43.287895688],
            [152.683873642000123, -43.302855688000022],
            [152.678236642000257, -43.31779568799999],
            [152.672501642000128, -43.332715688],
            [152.66666964200013, -43.347616687999945],
            [152.660739642000209, -43.362496688000022],
            [152.654712642000135, -43.377355687999952],
            [152.648587642000081, -43.392194688],
            [152.642364642000217, -43.407011688000011],
            [152.636045642000255, -43.421807687999973],
            [152.629918642000206, -43.435920689],
            [152.623702642000154, -43.450012688999976],
            [152.6173976420001, -43.464084688999989],
            [152.61100464200004, -43.478134688999958],
            [152.60452364200026, -43.492164688999949],
            [152.597953642000135, -43.506172689],
            [152.591295642000176, -43.520158689],
            [152.589291642000177, -43.535529688999929],
            [152.587188642000257, -43.550892689],
            [152.584987642000243, -43.56624868900002],
            [152.582687642000195, -43.581597689],
            [152.580288642000113, -43.596937689000029],
            [152.57779064200011, -43.612269689000016],
            [152.575193642000073, -43.627593688999966],
            [152.572497642000116, -43.642907688999969],
            [152.569702642000124, -43.658212689000038],
            [152.566809642000209, -43.673508688999988],
            [152.563816642000205, -43.688793689],
            [152.560725642000165, -43.704068688999968],
            [152.557534642000206, -43.719333688999924],
            [152.554245642000211, -43.734586688999954],
            [152.550856642000184, -43.749828688999955],
            [152.547369642000064, -43.765059688999941],
            [152.543783642000193, -43.780277689],
            [152.54009764200012, -43.795483689000051],
            [152.536313642000124, -43.810677688999988],
            [152.532430642000264, -43.825857689000017],
            [152.5284486420002, -43.841024688999937],
            [152.524367642000158, -43.856178688999947],
            [152.520187642000252, -43.871317688999952],
            [152.515908642000142, -43.886442688999963],
            [152.511530642000167, -43.901552688999971],
            [152.507053642000216, -43.91664868899997],
            [152.502478642000113, -43.931728688999982],
            [152.497803642000207, -43.946792689],
            [152.493030642000264, -43.961840689000027],
            [152.488158642000116, -43.976872688999975],
            [152.483188642000215, -43.991887688999938],
            [152.478118642000112, -44.006885689],
            [152.472950642000256, -44.021866689],
            [152.467684642000194, -44.036829689000015],
            [152.46231964200004, -44.051774688999956],
            [152.458096642000129, -44.065348688999975],
            [152.453793642000079, -44.078908688999988],
            [152.449410642000231, -44.092455689],
            [152.444947642000187, -44.105989688999983],
            [152.440403642000177, -44.119508688999971],
            [152.435779642000199, -44.133014688999964],
            [152.430633642000231, -44.147800688999979],
            [152.425392642000105, -44.16256868899994],
            [152.420054642000224, -44.177318689],
            [152.414620642000187, -44.192050689],
            [152.409090642000052, -44.206764689000011],
            [152.403463642000162, -44.221460688999954],
            [152.397741642000113, -44.23613668900002],
            [152.391922642000196, -44.250793689000034],
            [152.386008642000178, -44.265431688999982],
            [152.37999764200012, -44.280049688999952],
            [152.373891642000189, -44.294646688999975],
            [152.367688642000218, -44.309223689000021],
            [152.361391642000143, -44.323779688999934],
            [152.354997642000086, -44.338314688999972],
            [152.348508642000098, -44.352827688999966],
            [152.341923642000239, -44.367318689],
            [152.335243642000165, -44.38178868899999],
            [152.32846764200022, -44.396234689000039],
            [152.32159664200006, -44.410658688999945],
            [152.314630642000196, -44.425059689],
            [152.307569642000118, -44.439437688999931],
            [152.300412642000168, -44.453791689],
            [152.293161642000115, -44.46812068899996],
            [152.285815642000244, -44.482426688999965],
            [152.278374642000102, -44.496706689999954],
            [152.270838642000143, -44.51096269],
            [152.263207642000253, -44.525192690000026],
            [152.2554836420002, -44.539397689999944],
            [152.247663642000049, -44.553576690000014],
            [152.239750642000246, -44.567728689999981],
            [152.231742642000171, -44.581854690000014],
            [152.22364064200022, -44.595953689999945],
            [152.215444642000108, -44.610025689999958],
            [152.207155642000117, -44.624069689999956],
            [152.198771642000253, -44.638085689999954],
            [152.190294642000168, -44.652073689999938],
            [152.181723642000208, -44.66603369],
            [152.173060642000195, -44.67996469],
            [152.164455642000263, -44.69362969],
            [152.155760642000104, -44.707265689999971],
            [152.146975642000115, -44.720872689999965],
            [152.138101642000123, -44.73445069],
            [152.129137642000074, -44.747999690000043],
            [152.120083642000196, -44.761519689999943],
            [152.110940642000088, -44.775008689999936],
            [152.101708642000148, -44.788468689999945],
            [152.092387642000205, -44.801897690000033],
            [152.082977642000202, -44.815295689999971],
            [152.073478642000197, -44.82866369],
            [152.063891642000129, -44.841999690000037],
            [152.054215642000116, -44.85530369],
            [152.044451642000041, -44.868576689999983],
            [152.034599642000245, -44.88181669],
            [152.024658642000162, -44.895024690000014],
            [152.014630642000185, -44.908199689999961],
            [152.004514642000203, -44.921341690000034],
            [151.994310642000215, -44.934450689999935],
            [151.984019642000163, -44.947525689999964],
            [151.97364164200016, -44.960566689999936],
            [151.962889642000135, -44.973954690000014],
            [151.952045642000058, -44.987305689999971],
            [151.941109642000157, -45.000620689999963],
            [151.930081641000044, -45.01389769],
            [151.918962641000206, -45.02713769],
            [151.907751641000203, -45.040340689999965],
            [151.896450641000143, -45.05350469],
            [151.885058641000199, -45.066630689999982],
            [151.873575641000258, -45.079718689999922],
            [151.862001641000262, -45.092766690000033],
            [151.850338641000263, -45.105776690000013],
            [151.838584641000097, -45.118746689999966],
            [151.826741641000154, -45.13167669],
            [151.814808641000099, -45.14456669],
            [151.802785641000156, -45.157415689999965],
            [151.790674641000152, -45.170224689999927],
            [151.778473641000204, -45.182992689999963],
            [151.766184641000194, -45.195718689999978],
            [151.753806641000125, -45.208403689999983],
            [151.741340641000107, -45.221046689999966],
            [151.728786641000198, -45.233646689999958],
            [151.716145641000111, -45.246204690000013],
            [151.703415641000191, -45.258719689999978],
            [151.690599641000205, -45.271192690000035],
            [151.677695641000099, -45.28362069],
            [151.664705641000097, -45.296005689999959],
            [151.651628641000258, -45.30834669],
            [151.63846464100007, -45.320643689999969],
            [151.62521564100021, -45.33289569],
            [151.611880641000226, -45.34510269],
            [151.598459641000176, -45.35726469],
            [151.584953641000112, -45.369381689999969],
            [151.571362641000206, -45.381452689999975],
            [151.557686641000174, -45.393477689999983],
            [151.54392664100024, -45.405455689999926],
            [151.53008164100018, -45.417387689999984],
            [151.516153641000159, -45.429272689999962],
            [151.502141641000236, -45.44111069],
            [151.488045641000127, -45.452900689999979],
            [151.473867641000226, -45.464643689999932],
            [151.459605641000195, -45.47633869],
            [151.445261641000258, -45.48798469],
            [151.430835641000186, -45.499582689999954],
            [151.416327641000152, -45.511131690000013],
            [151.401737641000096, -45.522631690000026],
            [151.387066641000075, -45.534082689999977],
            [151.372314641000202, -45.545482689999972],
            [151.357481641000192, -45.55683369],
            [151.342568641000156, -45.56813469],
            [151.327575641000152, -45.579384690999987],
            [151.312502641000179, -45.590583690999964],
            [151.297349641000181, -45.601732690999981],
            [151.282117641000099, -45.612829690999945],
            [151.266806641000159, -45.623874690999969],
            [151.251417641000245, -45.634868690999937],
            [151.23594964100019, -45.645810690999966],
            [151.220404641000158, -45.656699690999957],
            [151.204781641000096, -45.667536691],
            [151.189081641000115, -45.678319691000013],
            [151.173304641000158, -45.689050691],
            [151.157450641000224, -45.699727691000021],
            [151.141520641000142, -45.710351691000028],
            [151.125514641000251, -45.720920691000018],
            [151.109433641000038, -45.731436690999956],
            [151.093276641000244, -45.741897690999977],
            [151.077045641000126, -45.75230369099998],
            [151.060739641000197, -45.762655691000035],
            [151.04435964100017, -45.772951690999989],
            [151.02790564100016, -45.783192691000011],
            [151.011377641000166, -45.793377690999932],
            [150.99477764100007, -45.80350669100001],
            [150.978104641000158, -45.813579690999987],
            [150.961358641000146, -45.823595691000037],
            [150.9445416410002, -45.833555691],
            [150.927652641000265, -45.843458691000023],
            [150.910691641000227, -45.853304690999963],
            [150.893660641000253, -45.863092691],
            [150.876559641000171, -45.872823691],
            [150.859387640000165, -45.882496691000014],
            [150.842145640000155, -45.892111691000011],
            [150.824834640000205, -45.901667690999943],
            [150.807454640000145, -45.911165690999951],
            [150.790006640000144, -45.920604690999951],
            [150.772490640000257, -45.929984690999973],
            [150.754905640000203, -45.939305690999987],
            [150.737253640000205, -45.948566690999925],
            [150.719535640000146, -45.957768690999956],
            [150.701749640000145, -45.966909691],
            [150.683898640000194, -45.975991690999962],
            [150.665980640000186, -45.985012690999937],
            [150.647998640000225, -45.993972691000025],
            [150.629950640000146, -46.002872690999936],
            [150.611838640000173, -46.011710690999948],
            [150.593662640000133, -46.020487690999985],
            [150.575422640000141, -46.02920369099995],
            [150.561208640000103, -46.041114690999947],
            [150.546909640000223, -46.052977690999981],
            [150.532525640000159, -46.064791691000025],
            [150.518056640000083, -46.076558691000017],
            [150.503503640000162, -46.088275691000035],
            [150.488865640000228, -46.099943691],
            [150.474144640000162, -46.111563690999986],
            [150.459339640000081, -46.123132691000023],
            [150.44445164000004, -46.134652691],
            [150.429480640000094, -46.146121691000019],
            [150.414426640000187, -46.157540691],
            [150.399290640000146, -46.168909691],
            [150.384071640000258, -46.180226690999959],
            [150.368772640000174, -46.191492690999965],
            [150.353390640000129, -46.202707690999929],
            [150.337928640000229, -46.213870690999954],
            [150.322385640000192, -46.224980691000013],
            [150.30676164000019, -46.236039691000045],
            [150.291058640000216, -46.247044691000042],
            [150.275274640000163, -46.257997691],
            [150.259411640000195, -46.268897691],
            [150.243470640000083, -46.279743691],
            [150.227449640000174, -46.29053569099996],
            [150.211350640000234, -46.301274690999975],
            [150.195173640000149, -46.311958690999973],
            [150.178918640000262, -46.322588691000021],
            [150.162586640000228, -46.333163690999967],
            [150.146177640000104, -46.343683690999981],
            [150.129691640000118, -46.354148690999978],
            [150.113130640000151, -46.364557690999959],
            [150.096669640000158, -46.374804690999937],
            [150.080134640000125, -46.384995691],
            [150.063525640000165, -46.395131690999946],
            [150.046843640000105, -46.40521269099996],
            [150.03008964000017, -46.415237690999973],
            [150.013262640000193, -46.42520769100004],
            [149.996362640000058, -46.435120691],
            [149.979391640000159, -46.444977690999963],
            [149.962348640000101, -46.454778691],
            [149.945235640000163, -46.464522691000028],
            [149.92805064000018, -46.474208690999959],
            [149.910796640000143, -46.48383869099996],
            [149.893471640000172, -46.493410690999966],
            [149.876077640000204, -46.502924690999961],
            [149.858614640000184, -46.512380691000047],
            [149.841081640000169, -46.521779690999949],
            [149.823481640000097, -46.531118691000032],
            [149.805812640000198, -46.540399690999934],
            [149.788076640000241, -46.54962269100001],
            [149.77027363900018, -46.558785691000011],
            [149.752403639000164, -46.567889690999934],
            [149.734467639000087, -46.576933691000036],
            [149.716464639000179, -46.585917690999963],
            [149.698396639000208, -46.594842691],
            [149.680263639000174, -46.603706690999942],
            [149.66206563900019, -46.612510691],
            [149.643802639000256, -46.621253690999978],
            [149.625476639000254, -46.629936690999969],
            [149.607086639000187, -46.638557690999974],
            [149.588633639000165, -46.647117692],
            [149.570117639000074, -46.655616692000038],
            [149.551539639000197, -46.66405369200001],
            [149.532899639000135, -46.672428692],
            [149.514198639000114, -46.680741692000012],
            [149.495435639000192, -46.688992691999971],
            [149.476612639000194, -46.697180692000032],
            [149.457729639000178, -46.705306692000022],
            [149.438787639000196, -46.713368691999975],
            [149.419784639000142, -46.721368691999928],
            [149.400724639000174, -46.729304692000014],
            [149.381604639000187, -46.73717769199996],
            [149.362427639000117, -46.744986691999927],
            [149.343193639000134, -46.752731691999927],
            [149.323901639000127, -46.76041269199996],
            [149.304553639000204, -46.768029692000042],
            [149.285148639000198, -46.775581691999975],
            [149.26568863900016, -46.783069692000026],
            [149.246173639000205, -46.790492691999944],
            [149.226602639000106, -46.797850691999983],
            [149.206978639000198, -46.80514269199999],
            [149.187300639000142, -46.812370691999945],
            [149.167568639000166, -46.819531691999941],
            [149.147783639000153, -46.826627691999974],
            [149.127946639000101, -46.833658691999972],
            [149.10805763900018, -46.840622692000011],
            [149.088116639000219, -46.847520691999918],
            [149.068125639000215, -46.85435169199998],
            [149.048083639000225, -46.861116691999982],
            [149.027990639000137, -46.867814691999953],
            [149.008002639000125, -46.874488691999986],
            [148.987964639000126, -46.881096691999971],
            [148.967878639000247, -46.887637692000013],
            [148.947743639000151, -46.894112692],
            [148.927561639000061, -46.900520691999958],
            [148.907331639000148, -46.906861691999964],
            [148.887055639000124, -46.913135692000026],
            [148.866732639000162, -46.919342691999972],
            [148.846363639000145, -46.92548269199996],
            [148.825949639000129, -46.931554692],
            [148.805490639000112, -46.93755869200001],
            [148.784987639000207, -46.943495692],
            [148.764440639000128, -46.949364692000032],
            [148.743849639000103, -46.955165692000037],
            [148.723215639000188, -46.960897692000017],
            [148.702539638000161, -46.966561691999971],
            [148.68182163800023, -46.972157691999968],
            [148.661062638000175, -46.977684691999954],
            [148.640262638000223, -46.983142692],
            [148.619421638000205, -46.988531692000024],
            [148.598540638000117, -46.993851692000021],
            [148.577620638000241, -46.999102692],
            [148.556661638000179, -47.004284691999942],
            [148.535664638000156, -47.009396691999967],
            [148.514628638000175, -47.014439691999954],
            [148.493555638000174, -47.019412691999932],
            [148.472446638000207, -47.024315691999973],
            [148.451300638000106, -47.029148692],
            [148.430118638000152, -47.033911692],
            [148.408901638000231, -47.038604692],
            [148.387648638000172, -47.043227691999974],
            [148.366362638000197, -47.04777969200002],
            [148.345042638000194, -47.052261691999959],
            [148.323688638000107, -47.056672691999964],
            [148.302302638000157, -47.06101369199996],
            [148.28088363800012, -47.065282692000039],
            [148.259433638000104, -47.069481692000011],
            [148.237952638000166, -47.073608691999979],
            [148.21644063800025, -47.077664692000027],
            [148.194897638000185, -47.081650692000039],
            [148.173326638000191, -47.08556369199998],
            [148.151725638000102, -47.089406691999983],
            [148.130095638000199, -47.093176691999986],
            [148.108438638000194, -47.096875691999969],
            [148.08675363800026, -47.100502691999964],
            [148.069355638000133, -47.103435692],
            [148.051941638000159, -47.106321691999938],
            [148.029916638000174, -47.109903691999932],
            [148.007865638000254, -47.113411691999929],
            [147.985787638000062, -47.116845692000027],
            [147.963683638000106, -47.120205691999942],
            [147.941554638000213, -47.123491691999959],
            [147.91940163800021, -47.126702691999981],
            [147.897223638000156, -47.129839692],
            [147.875021638000163, -47.132901691999962],
            [147.852797638000226, -47.135889692],
            [147.830549638000178, -47.138802691999985],
            [147.80828063800007, -47.141641691999965],
            [147.785990638000186, -47.144404691999952],
            [147.763679638000241, -47.147093691999956],
            [147.741347638000235, -47.149707691999964],
            [147.718996638000107, -47.152246692],
            [147.696626638000254, -47.154710692000023],
            [147.674237638000164, -47.157099691999989],
            [147.651831638000175, -47.159412691999961],
            [147.62940663700013, -47.161651692000035],
            [147.60696663700017, -47.163814692000031],
            [147.584509637000139, -47.165901692000048],
            [147.562036637000205, -47.167914691999982],
            [147.539548637000195, -47.169851692000023],
            [147.517046637000163, -47.171712691999986],
            [147.494529637000056, -47.173498691999967],
            [147.472000637000207, -47.17520869199997],
            [147.449457637000165, -47.176842692],
            [147.426903637000095, -47.178401692000023],
            [147.404336637000114, -47.179884691999973],
            [147.381759637000101, -47.181291691999945],
            [147.35917163700006, -47.182623691999936],
            [147.336574637000155, -47.183879691999948],
            [147.313967637000161, -47.18505869199997],
            [147.29135163700019, -47.186162692000011],
            [147.268727637000126, -47.187190691999987],
            [147.246096637000079, -47.188142691999971],
            [147.223457637000166, -47.189018691999976],
            [147.200812637000041, -47.189818692],
            [147.178162637000042, -47.190542691999951],
            [147.155506637000059, -47.191190692],
            [147.132845637000202, -47.191762692],
            [147.110181637000181, -47.192258692],
            [147.087513637000114, -47.19267869200003],
            [147.064841637000228, -47.193021691999988],
            [147.042168637000174, -47.193289691999951],
            [147.019493637000238, -47.193480692000037],
            [146.996817637000191, -47.19359569199996],
            [146.974140637000261, -47.193635691999987],
            [146.951463637000217, -47.193598692000023],
            [146.928786637000115, -47.19348569200001],
            [146.906111637000237, -47.193295692],
            [146.883438637000182, -47.193030692000022],
            [146.860766637000239, -47.192689691999959],
            [146.838098637000058, -47.192271692],
            [146.815433637000211, -47.191778691999986],
            [146.792773637000181, -47.191208691999989],
            [146.770117637000197, -47.190562692],
            [146.747466637000201, -47.189840691999947],
            [146.724821637000247, -47.189043692],
            [146.702182637000163, -47.188169691999988],
            [146.679551637000117, -47.187219691999985],
            [146.656927637000223, -47.186193692],
            [146.634311637000195, -47.185091691999943],
            [146.611703637000261, -47.183914692],
            [146.589106637000185, -47.182660691999985],
            [146.566517637000089, -47.181331691999972],
            [146.543940636000258, -47.179926691999981],
            [146.521373636000163, -47.178445692000011],
            [146.498818636000038, -47.176888691999977],
            [146.476276636000165, -47.175256691999948],
            [146.453745636000207, -47.17354869199994],
            [146.431229636000097, -47.171765691999937],
            [146.408726636000182, -47.16990669200004],
            [146.386238636000172, -47.167971692],
            [146.363765636000124, -47.165961691999954],
            [146.341307636000096, -47.163875692000019],
            [146.318866636000081, -47.161714692],
            [146.296441636000196, -47.159478692],
            [146.274034636000039, -47.157167692000016],
            [146.25164563600012, -47.154780692000045],
            [146.229274636000099, -47.152319692],
            [146.206922636000144, -47.149782692000045],
            [146.184590636000081, -47.147170692000017],
            [146.162278636000138, -47.144483692],
            [146.139987636000257, -47.141722692],
            [146.117718636000149, -47.138885692000017],
            [146.095470636000158, -47.135974691999934],
            [146.073245636000166, -47.132989691999967],
            [146.051042636000176, -47.129928692],
            [146.028864636000236, -47.126794692000047],
            [146.006709636000238, -47.123585692],
            [145.984580636000061, -47.120301691999984],
            [145.962475636000107, -47.116944691999947],
            [145.940397636000085, -47.113512692000029],
            [145.918344636000171, -47.110006692000013],
            [145.896319636000186, -47.106426692000014],
            [145.874322636000187, -47.102772692000016],
            [145.852352636000234, -47.099045691999933],
            [145.830411636000264, -47.09524369199994],
            [145.808499636000107, -47.091369691999958],
            [145.786617636000216, -47.087420691999966],
            [145.764766636000246, -47.083399691999986],
            [145.742945636000087, -47.079304692],
            [145.721156636000075, -47.075135691999932],
            [145.699398636000154, -47.070894691999953],
            [145.677673636000094, -47.066580691999974],
            [145.655981636000234, -47.062193692],
            [145.634323636000232, -47.057733692],
            [145.612699636000201, -47.053201692000016],
            [145.591109636000141, -47.048596691999933],
            [145.569555636000104, -47.043918692000034],
            [145.548036636000148, -47.03916969200003],
            [145.526554636000157, -47.034347692000026],
            [145.505108636000188, -47.029453692000018],
            [145.48370063500019, -47.024487692],
            [145.4623306350002, -47.019450691999964],
            [145.44099863500017, -47.014340692000019],
            [145.419705635000099, -47.00916069199998],
            [145.398452635000098, -47.00390869200001],
            [145.378059635000142, -47.000846691999968],
            [145.357687635000133, -46.997722692000039],
            [145.337338635000179, -46.994534691999959],
            [145.317012635000168, -46.991283692],
            [145.296708635000101, -46.987969691999979],
            [145.276428635000258, -46.984591692],
            [145.256172635000183, -46.981151692000026],
            [145.235940635000105, -46.977648692],
            [145.215733635000191, -46.974082692],
            [145.195551635000101, -46.970453692000021],
            [145.175394635000117, -46.966762691999989],
            [145.155264635000236, -46.963008691999974],
            [145.133867635000257, -46.958944691999989],
            [145.112501635000143, -46.954810691999967],
            [145.091166635000064, -46.950605691999954],
            [145.069862635000135, -46.946329692],
            [145.048590635000181, -46.941983691999951],
            [145.027351635000201, -46.937567691999959],
            [145.006146635000192, -46.933080691999962],
            [144.984973635000102, -46.928524691999939],
            [144.963835635000038, -46.923897692],
            [144.942732635000226, -46.91920169199993],
            [144.921664635000099, -46.91443569200004],
            [144.900632635000107, -46.909599692000029],
            [144.879635635000255, -46.904694692],
            [144.858676635000194, -46.899719692000033],
            [144.837753635000155, -46.894676691999948],
            [144.816869635000245, -46.889563692000024],
            [144.796022635000185, -46.884381692],
            [144.775215635000251, -46.879130691999933],
            [144.754446635000107, -46.873810692000021],
            [144.733717635000147, -46.868422692],
            [144.713029635000197, -46.862965691999932],
            [144.692381635000089, -46.85744069199994],
            [144.671774635000105, -46.851847692],
            [144.651209635000185, -46.846186691999947],
            [144.630686635000217, -46.840456692000046],
            [144.610206635000083, -46.834659692000017],
            [144.589769635000124, -46.828795692000043],
            [144.569376635000168, -46.822862691999944],
            [144.549027635000101, -46.816863691999984],
            [144.52872263500015, -46.810796692],
            [144.508462635000257, -46.80466269199998],
            [144.48824863500019, -46.798462692],
            [144.468080635000177, -46.792194692],
            [144.44795963500016, -46.785860692000028],
            [144.42788463500014, -46.779459691999932],
            [144.40785763400018, -46.772992691999988],
            [144.387878634000259, -46.766459691999984],
            [144.367948634000157, -46.759860691999947],
            [144.348066634000105, -46.753195691999949],
            [144.328233634000213, -46.746465692],
            [144.308451634000249, -46.739669692],
            [144.288718634000105, -46.732808691999949],
            [144.269037634000171, -46.725881692000044],
            [144.24940663400011, -46.718890691999988],
            [144.229827634000259, -46.711834691999968],
            [144.210300634000163, -46.704713691999984],
            [144.190826634000047, -46.697527691999966],
            [144.171405634000138, -46.690278691999964],
            [144.152037634000152, -46.682964692000013],
            [144.1327236340002, -46.675586692],
            [144.113463634000112, -46.668144691999927],
            [144.094258634000113, -46.660639691999975],
            [144.074809634000104, -46.652953691999969],
            [144.055417634000179, -46.645201692],
            [144.036083634000164, -46.637385691],
            [144.016807634000173, -46.629503691000025],
            [143.99759063400009, -46.621557691],
            [143.978432634000256, -46.613547691],
            [143.959334634000157, -46.60547269099996],
            [143.940296634000134, -46.597334690999944],
            [143.921319634000071, -46.589131690999977],
            [143.902402634000197, -46.580865691000028],
            [143.883547634000053, -46.572536691000025],
            [143.864754634000207, -46.564143690999956],
            [143.84602363400009, -46.555687691000017],
            [143.82735663400004, -46.547169691],
            [143.808751634000117, -46.538588691000015],
            [143.790210634000204, -46.529944690999969],
            [143.771733634000128, -46.521239690999934],
            [143.753320634000119, -46.51247169100003],
            [143.73497363400017, -46.503642691000039],
            [143.716691634000171, -46.494751690999976],
            [143.698474634000121, -46.48579969099994],
            [143.680324634000186, -46.476786691],
            [143.662240634000142, -46.467713690999986],
            [143.644224634000039, -46.458578691],
            [143.626274634000225, -46.449384691],
            [143.608393634000123, -46.440129691000038],
            [143.59058063400019, -46.430814690999981],
            [143.572835634000199, -46.421439690999932],
            [143.555160634000259, -46.412005690999976],
            [143.537554634000145, -46.402512691000027],
            [143.520017634000141, -46.392960691],
            [143.502551634000127, -46.383349690999964],
            [143.485156634000163, -46.373679690999936],
            [143.466724634000201, -46.365116691000026],
            [143.448355634000194, -46.356491691000031],
            [143.430049634000142, -46.347805690999976],
            [143.411807634000155, -46.339058690999934],
            [143.393629634000064, -46.330249691],
            [143.375516634000093, -46.321380690999987],
            [143.357468634000185, -46.312450691],
            [143.339485633000237, -46.303460691000012],
            [143.321568633000226, -46.294409690999956],
            [143.303717633000105, -46.285299690999977],
            [143.285933633000099, -46.276129690999937],
            [143.268216633000208, -46.266900690999975],
            [143.250567633000088, -46.257611691000037],
            [143.232985633000254, -46.248263691],
            [143.215472633000132, -46.238857690999964],
            [143.19802763300018, -46.229392690999944],
            [143.180651633000167, -46.219868691],
            [143.163345633000205, -46.210287690999962],
            [143.146108633000068, -46.200648691000012],
            [143.128942633000207, -46.190951690999967],
            [143.11184663300017, -46.181196691000011],
            [143.09482163300018, -46.171385690999955],
            [143.077868633000179, -46.161516690999989],
            [143.060986633000113, -46.151591691],
            [143.044176633000205, -46.141610691],
            [143.027438633000173, -46.131572690999974],
            [143.010773633000184, -46.121479690999948],
            [142.994182633000122, -46.111329691],
            [142.97766463300016, -46.101125690999922],
            [142.961220633000067, -46.090865691000019],
            [142.944850633000186, -46.080550691],
            [142.928554633000175, -46.070180690999962],
            [142.912333633000259, -46.05975669099999],
            [142.896188633000151, -46.049278690999984],
            [142.880118633000194, -46.03874569099996],
            [142.864125633000157, -46.028159691],
            [142.848207633000158, -46.017520690999987],
            [142.832366633000248, -46.00682769099997],
            [142.816603633000142, -45.996082690999984],
            [142.800916633000071, -45.985284690999976],
            [142.785307633000258, -45.97443369099993],
            [142.769776633000248, -45.963530691000017],
            [142.754323633000098, -45.952576690999976],
            [142.73894963300009, -45.941569691],
            [142.723654633000052, -45.930512690999961],
            [142.708438633000156, -45.919403690999971],
            [142.693302633000116, -45.908244690999936],
            [142.678245633000159, -45.897034690999945],
            [142.663269633000056, -45.885773690999983],
            [142.648373633000261, -45.874463690999974],
            [142.633558633000149, -45.863103690999921],
            [142.61882463300006, -45.85169369099998],
            [142.60417163300022, -45.840234691],
            [142.589600633000117, -45.828726691000028],
            [142.575111633000262, -45.817170691000015],
            [142.56070563300014, -45.805565691000012],
            [142.546381633000152, -45.79391269099996],
            [142.532140633000182, -45.782211690999929],
            [142.517981633000176, -45.770463691],
            [142.503907633000182, -45.758668691000011],
            [142.489916633000206, -45.746825690999955],
            [142.476009633000189, -45.734936691],
            [142.462186633000243, -45.723000690999982],
            [142.448448633000254, -45.711018690999978],
            [142.434795633000107, -45.698990690999977],
            [142.421226633000146, -45.686917691],
            [142.407743633000194, -45.674798691],
            [142.394346633000083, -45.662635690999949],
            [142.381034633000155, -45.650426690999979],
            [142.367809633000235, -45.638174691000017],
            [142.354670633000154, -45.625877690999957],
            [142.341617633000084, -45.613536691],
            [142.328652633000189, -45.601152691000038],
            [142.315773633000134, -45.588724690999982],
            [142.302982633000141, -45.576253691000019],
            [142.290279633000154, -45.563740690000031],
            [142.277663633000174, -45.551185689999954],
            [142.265135632000039, -45.538587690000043],
            [142.252696632000124, -45.525947689999953],
            [142.240345632000157, -45.51326669],
            [142.228083632000249, -45.50054469],
            [142.21591063200006, -45.48778169],
            [142.203826632000215, -45.47497769],
            [142.191831632000202, -45.46213369],
            [142.179926632000075, -45.449249689999959],
            [142.168111632000119, -45.43632569],
            [142.15638663200005, -45.42336269],
            [142.144752632000149, -45.410359689999964],
            [142.133208632000134, -45.39731869],
            [142.121754632000119, -45.38423869],
            [142.110749632000221, -45.371537690000011],
            [142.099829632000052, -45.358800689999967],
            [142.088995632000177, -45.346027689999971],
            [142.078246632000202, -45.33322069],
            [142.067584632000177, -45.32037869],
            [142.057007632000165, -45.307502689999978],
            [142.046517632000047, -45.294591689999933],
            [142.036113632000053, -45.28164669],
            [142.025796632000123, -45.26866869],
            [142.015566632000201, -45.255657690000035],
            [142.005422632000233, -45.242613689999985],
            [141.995366632000156, -45.229535689999977],
            [141.985397632000144, -45.216426689999977],
            [141.975515632000253, -45.20328469],
            [141.965721632000196, -45.19011069000004],
            [141.956015632000202, -45.17690569],
            [141.946396632000216, -45.16366869],
            [141.936865632000064, -45.150400689999969],
            [141.927423632000142, -45.137102689999963],
            [141.918069632000169, -45.123773689999965],
            [141.908803632000144, -45.110414689999985],
            [141.899206632000215, -45.099194689999962],
            [141.889676632000061, -45.08794769],
            [141.880212632000195, -45.076672689999967],
            [141.870813632000107, -45.06537069],
            [141.857851632000205, -45.053205690000027],
            [141.844973632000205, -45.040996689999986],
            [141.832179632000162, -45.028744689999925],
            [141.819470632000133, -45.016449689999945],
            [141.806845632000119, -45.004112689999971],
            [141.794306632000115, -44.991733689999975],
            [141.78185163200024, -44.979311689999975],
            [141.769482632000091, -44.966848689999949],
            [141.757199632000123, -44.95434469],
            [141.745002632000109, -44.941799690000039],
            [141.732890632000164, -44.929213689999969],
            [141.720866632000053, -44.916586689999974],
            [141.708927632000126, -44.903919689999952],
            [141.697076632000204, -44.891213689999987],
            [141.685311632000236, -44.87846769],
            [141.673634632000045, -44.865681689999988],
            [141.662044632000146, -44.852857689999951],
            [141.650542632000139, -44.839993689999972],
            [141.639128632000137, -44.827092690000043],
            [141.627801632000143, -44.814152689999986],
            [141.616563632000151, -44.80117469],
            [141.605414632000048, -44.788159689999958],
            [141.594353632000121, -44.775106689999973],
            [141.5833806320002, -44.762017689999936],
            [141.572497632000164, -44.748891689999951],
            [141.561703632000132, -44.73572969],
            [141.550998632000102, -44.722530690000013],
            [141.540383632000129, -44.709296689999974],
            [141.529858632000042, -44.696026689999982],
            [141.519422632000243, -44.68272269],
            [141.509077632000213, -44.66938269],
            [141.498822632000184, -44.65600869],
            [141.488657632000155, -44.642600689999952],
            [141.478583632000181, -44.629157689999943],
            [141.468599632000206, -44.615681690000045],
            [141.458707632000113, -44.602172689999989],
            [141.448905632000248, -44.58863069],
            [141.439195632000207, -44.575055689999942],
            [141.42957663200005, -44.561447689999952],
            [141.420049632000115, -44.547808689999982],
            [141.410613632000178, -44.534136690000025],
            [141.401269632000123, -44.520434689999988],
            [141.392018632000116, -44.506700689999974],
            [141.382858632000108, -44.492935689000049],
            [141.373790632000151, -44.479139688999965],
            [141.364815632000074, -44.46531368899997],
            [141.355933632000216, -44.451458688999963],
            [141.347143632000183, -44.437573688999976],
            [141.338446632000199, -44.423658689],
            [141.329842632000094, -44.409714689],
            [141.320960632000066, -44.396461689],
            [141.312164632000218, -44.383179688999974],
            [141.30345463200004, -44.369868688999958],
            [141.294829632000159, -44.356530689000024],
            [141.286291632000172, -44.343163689],
            [141.277838632000197, -44.329769688999946],
            [141.269471632000176, -44.31634868899998],
            [141.261191632000219, -44.302900688999983],
            [141.252997632000103, -44.289426688999981],
            [141.244889632000167, -44.275924688999979],
            [141.236868632000238, -44.26239768899994],
            [141.228934632000147, -44.248844688999981],
            [141.221087632000064, -44.235265688999988],
            [141.213326632000161, -44.221661688999973],
            [141.20565363200015, -44.208032688999936],
            [141.198066632000149, -44.194378688999961],
            [141.190567631000164, -44.180700688999963],
            [141.183155631000176, -44.16699868900001],
            [141.175830631000196, -44.153272688999948],
            [141.168226631000067, -44.138828688999979],
            [141.160720631000203, -44.12435868899999],
            [141.15331063100021, -44.109863688999965],
            [141.145996631000259, -44.095342688999935],
            [141.13878063100006, -44.080797688999965],
            [141.131660631000074, -44.066228688999956],
            [141.124638631000124, -44.051634689000011],
            [141.1177136310001, -44.037017689000024],
            [141.110885631000116, -44.022377689],
            [141.104154631000171, -44.007713688999942],
            [141.097521631000149, -43.99302768900003],
            [141.090985631000166, -43.978317689],
            [141.084524631000107, -43.966939688999943],
            [141.078123631000182, -43.955544689],
            [141.071783631000159, -43.944131689],
            [141.065504631000152, -43.932701688999927],
            [141.05762963100014, -43.918311689],
            [141.049851631000053, -43.903895689000024],
            [141.042169631000121, -43.889452688999967],
            [141.034583631000118, -43.874984688999959],
            [141.027094631000153, -43.860489688999948],
            [141.019701631000231, -43.845969689],
            [141.012405631000178, -43.831424689000016],
            [141.00520663100022, -43.816853689000027],
            [140.998103631000134, -43.802259688999982],
            [140.991098631000256, -43.787640688999929],
            [140.984190631000132, -43.772997689],
            [140.977378631000164, -43.758331688999974],
            [140.970664631000176, -43.743642688999984],
            [140.96404763100017, -43.728930689000038],
            [140.957528631000088, -43.714195688999979],
            [140.951106631000044, -43.699439689000044],
            [140.944781631000097, -43.684660688999969],
            [140.938555631000185, -43.669860688999947],
            [140.932425631000143, -43.655039688999963],
            [140.926394631000193, -43.640197688999919],
            [140.920460631000225, -43.625334688999928],
            [140.914624631000066, -43.610451688999959],
            [140.908886631000115, -43.595549688999938],
            [140.903246631000201, -43.580627689000025],
            [140.897704631000039, -43.565686688999975],
            [140.892260631000255, -43.550725688999947],
            [140.886914631000167, -43.535747688999933],
            [140.881666631000172, -43.520750688999954],
            [140.876516631000214, -43.505736688999988],
            [140.871465631000177, -43.49070468900004],
            [140.866511631000179, -43.475655689],
            [140.861657631000099, -43.460589689],
            [140.856900631000059, -43.445506689000013],
            [140.852242631000109, -43.430408688999933],
            [140.847682631000197, -43.415293687999949],
            [140.843221631000148, -43.400164687999975],
            [140.838858631000193, -43.385019688],
            [140.834594631000158, -43.369859687999949],
            [140.83042863100016, -43.354685687999975],
            [140.826360631000199, -43.339496688],
            [140.822392631000156, -43.324294688000023],
            [140.818521631000152, -43.30907968799994],
            [140.814749631000069, -43.293850687999949],
            [140.811076631000191, -43.278609688000017],
            [140.807502631000062, -43.263355687999919],
            [140.803548631000098, -43.248728687999986],
            [140.799684631000247, -43.234089687999969],
            [140.795912631000164, -43.219437688000014],
            [140.792232631000132, -43.204772687999963],
            [140.788642631000101, -43.190096687999983],
            [140.785144631000236, -43.17540868799999],
            [140.781738631000138, -43.160708687999886],
            [140.778422631000041, -43.145997687999852],
            [140.775198631000109, -43.131276687999971],
            [140.772066631000115, -43.116544687999976],
            [140.769024631000235, -43.101801687999867],
            [140.766074631000123, -43.087049688],
            [140.763216631000176, -43.072287688],
            [140.760448631000116, -43.057516687999978],
            [140.757772631000222, -43.04273668799992],
            [140.755188631000038, -43.027947688000012],
            [140.752694631000082, -43.013150687999968],
            [140.750292631000121, -42.998344687999975],
            [140.747981631000044, -42.983531688000028],
            [140.74576263100019, -42.968710687999859],
            [140.738432631000165, -42.954268687999971],
            [140.731196631000188, -42.939800687999877],
            [140.724055631000141, -42.92530868800003],
            [140.717008631000255, -42.910791687999875],
            [140.710056631000128, -42.896250687999967],
            [140.703198631000163, -42.881685687999934],
            [140.696435631000242, -42.867096687999961],
            [140.689767631000251, -42.852485687999945],
            [140.683194631000134, -42.837850687999975],
            [140.676715631000178, -42.823193687999975],
            [140.67033263100015, -42.808513688000019],
            [140.662148631000065, -42.79434968799994],
            [140.6540576310002, -42.780156687999963],
            [140.646058631000159, -42.765936687999954],
            [140.638152631000054, -42.751688687999959],
            [140.630339631000112, -42.737412687999935],
            [140.622618631000051, -42.723110688],
            [140.614991631000208, -42.708781687999945],
            [140.607457631000187, -42.694426687999979],
            [140.600016631000102, -42.680044687999981],
            [140.592669631000177, -42.665637687999961],
            [140.585415631000188, -42.651205688000019],
            [140.578254631000192, -42.63674868799994],
            [140.571188631000069, -42.622266688000025],
            [140.564215631000167, -42.607759687999916],
            [140.557335631000143, -42.593229687999937],
            [140.550550631000164, -42.578675688],
            [140.543859631000061, -42.564097687999961],
            [140.537262631000118, -42.549496687999977],
            [140.530759631000109, -42.534873687999934],
            [140.524351631000144, -42.520227688000048],
            [140.518042631000213, -42.50557468800001],
            [140.511827631000216, -42.490900688000025],
            [140.505707631000206, -42.476204687999982],
            [140.499681631000072, -42.461487688],
            [140.49375063100004, -42.44674968799994],
            [140.487913631000168, -42.431990688000013],
            [140.482170631000173, -42.41721268799995],
            [140.476522631000222, -42.402414688],
            [140.470969631000202, -42.387596687999988],
            [140.465511631000169, -42.372760688000014],
            [140.460147631000069, -42.357904687999962],
            [140.454878631000128, -42.343031686999936],
            [140.449704631000117, -42.328139687000032],
            [140.444625631000093, -42.313229686999961],
            [140.439641631000171, -42.298302687],
            [140.434752631000123, -42.283357686999963],
            [140.42995963100023, -42.268396686999935],
            [140.4252606310001, -42.253418687000021],
            [140.420656631000185, -42.238425687000017],
            [140.416147631000143, -42.223415686999928],
            [140.411734631000144, -42.208390687000033],
            [140.407416631000189, -42.193350686999949],
            [140.403193631000164, -42.178294686999976],
            [140.390980631000076, -42.166127687000014],
            [140.378845631000189, -42.153918686999972],
            [140.366790631000157, -42.141666687],
            [140.354815631000207, -42.129372686999929],
            [140.342920631000112, -42.117036686999938],
            [140.331104631000159, -42.104659687000023],
            [140.319369631000171, -42.092241687],
            [140.307715631000207, -42.079782687000048],
            [140.296141631000097, -42.067283686999971],
            [140.284649631000235, -42.054743686999984],
            [140.273237631000114, -42.042164686999953],
            [140.261907631000241, -42.029544687000012],
            [140.250658631000221, -42.016885686999942],
            [140.23949163100022, -42.004187687000012],
            [140.228406631000183, -41.991450686999968],
            [140.217404631000164, -41.978675687],
            [140.206483631000111, -41.965861686999972],
            [140.195646631000074, -41.953010687],
            [140.184891631000056, -41.940120686999919],
            [140.17421963100017, -41.927193686999956],
            [140.163630631000132, -41.914229686999974],
            [140.153124631000111, -41.901229687000011],
            [140.142702631000049, -41.888191686999946],
            [140.132364631000172, -41.875118686999983],
            [140.122110630000208, -41.862008687],
            [140.111939630000137, -41.848863686999948],
            [140.101853630000136, -41.835683687000028],
            [140.091852630000091, -41.822467686999957],
            [140.081934630000234, -41.809217686999929],
            [140.072102630000103, -41.795932686999933],
            [140.062355630000212, -41.782613686999966],
            [140.052692630000053, -41.769260687000028],
            [140.043115630000244, -41.755873686999948],
            [140.033623630000108, -41.742454686999963],
            [140.024217630000095, -41.729001686999922],
            [140.014896630000152, -41.715515687],
            [140.005661630000219, -41.701997686999988],
            [139.996895630000125, -41.689065686999982],
            [139.988207630000176, -41.676104686999963],
            [139.9795976300002, -41.663115686999944],
            [139.971067630000249, -41.65009668700003],
            [139.962615630000101, -41.637049687],
            [139.954243630000093, -41.62397568699997],
            [139.945949630000229, -41.610872687000025],
            [139.937735630000049, -41.597742686999965],
            [139.929600630000181, -41.584584687],
            [139.921545630000224, -41.571400686999922],
            [139.914189630000152, -41.559222686999945],
            [139.906901630000135, -41.547021687000026],
            [139.89968063000012, -41.534798686999977],
            [139.892528630000157, -41.522553686999956],
            [139.89114763000012, -41.52215568699998],
            [139.875633630000181, -41.517685686999968],
            [139.858767630000187, -41.512762686999963],
            [139.841931630000175, -41.507786687],
            [139.825124630000261, -41.50275668699993],
            [139.8083476300001, -41.497672687],
            [139.79160063000009, -41.492534686999974],
            [139.774884630000173, -41.487343687],
            [139.756154630000225, -41.481563686999948],
            [139.737462630000124, -41.475715686999962],
            [139.718810630000149, -41.469801687000015],
            [139.700196630000136, -41.463820686999952],
            [139.681623630000132, -41.457772686999931],
            [139.663090630000141, -41.45165768699998],
            [139.644598630000161, -41.445476686999967],
            [139.626147630000133, -41.439229687],
            [139.607737630000173, -41.43291568699992],
            [139.589370630000161, -41.426535686999969],
            [139.571045630000157, -41.42009068699997],
            [139.552763630000157, -41.413579687000023],
            [139.534524630000163, -41.407002686999931],
            [139.516329630000172, -41.400359687],
            [139.49817863000024, -41.393652686999985],
            [139.480071630000253, -41.386879687000018],
            [139.462010630000151, -41.380041687000016],
            [139.443994630000049, -41.373139686999949],
            [139.426024630000171, -41.366172687000017],
            [139.408100630000121, -41.359140687000021],
            [139.390223630000065, -41.352044686999989],
            [139.372392630000178, -41.344884686999976],
            [139.354609630000056, -41.33766068700001],
            [139.336874630000096, -41.330372686999979],
            [139.31918763000013, -41.323021686999979],
            [139.30154963000021, -41.315606687000013],
            [139.283960630000166, -41.308128687],
            [139.266420630000113, -41.30058768699999],
            [139.248931630000158, -41.292983687000017],
            [139.231491630000193, -41.285316686999977],
            [139.214102630000099, -41.277587686999965],
            [139.196764630000217, -41.269796685999978],
            [139.179478630000204, -41.261942685999927],
            [139.162243630000063, -41.254026685999989],
            [139.145060630000131, -41.24604868599998],
            [139.127930630000122, -41.238009685999984],
            [139.110853630000094, -41.229909686000013],
            [139.093830630000156, -41.221747685999958],
            [139.076860630000084, -41.213524686000028],
            [139.059944630000047, -41.20524068600001],
            [139.043082629000111, -41.196896686],
            [139.026276629000193, -41.18849168600002],
            [139.00952462900014, -41.180026685999948],
            [138.99282962900017, -41.171501685999971],
            [138.976189629000061, -41.162916686000017],
            [138.959606629000206, -41.154271685999973],
            [138.943079629000096, -41.145567685999936],
            [138.926609629000183, -41.136803686],
            [138.91019762900018, -41.12798168599997],
            [138.893842629000204, -41.119100686000024],
            [138.877546629000193, -41.110160686],
            [138.866936629000207, -41.104281686000036],
            [138.861308629000206, -41.101162685999981],
            [138.845129629000127, -41.092105685999954],
            [138.829009629000126, -41.082991686000014],
            [138.812531629000176, -41.076950686000046],
            [138.796088629000195, -41.070855685999959],
            [138.779682629000064, -41.06470768600002],
            [138.763313629000237, -41.058504685999978],
            [138.7469826290002, -41.052248685999984],
            [138.730688629000184, -41.045938685999957],
            [138.714431629000131, -41.039575686],
            [138.698213629000207, -41.033158686],
            [138.682033629000131, -41.026689685999969],
            [138.66437862900014, -41.019916685999917],
            [138.646766629000155, -41.013081685999978],
            [138.629198629000172, -41.006182685999988],
            [138.611675629000075, -40.999222685999925],
            [138.594197629000149, -40.992199685999978],
            [138.576765629000221, -40.985115686000043],
            [138.559379629000119, -40.977968685999954],
            [138.542039629000243, -40.970760685999977],
            [138.524745629000194, -40.963490686000014],
            [138.507499629000193, -40.956159685999978],
            [138.490300629000188, -40.948767685999968],
            [138.473149629000233, -40.941314685999956],
            [138.456046629000099, -40.933800685999969],
            [138.438991629000185, -40.926225686],
            [138.421986629000145, -40.918590685999959],
            [138.405030629000152, -40.910894686],
            [138.388123629000148, -40.903138685999977],
            [138.371267629000187, -40.895322685999972],
            [138.354460629000101, -40.887447686000044],
            [138.337106629000147, -40.879432685999987],
            [138.319805629000115, -40.871355685999958],
            [138.302557629000063, -40.863214685999964],
            [138.2853646290001, -40.855010686],
            [138.268224629000173, -40.846743685999968],
            [138.251140629000162, -40.838414685999965],
            [138.234110629000185, -40.830022686],
            [138.217136629000066, -40.821568686000035],
            [138.200218629000204, -40.813052686],
            [138.183356629000201, -40.804475685999989],
            [138.166551629000168, -40.795836686],
            [138.149803629000104, -40.78713668599994],
            [138.133112629000181, -40.778374686],
            [138.116479629000111, -40.769552685999969],
            [138.099905629000119, -40.760669686000043],
            [138.083388629000211, -40.751726686000026],
            [138.066931629000152, -40.742722686000036],
            [138.050533629000057, -40.73365868599997],
            [138.034195629000152, -40.724535686],
            [138.017916629000212, -40.715351686000034],
            [138.001698629000117, -40.706109685999976],
            [137.985541629000153, -40.696807685999929],
            [137.969445628000102, -40.687446685999987],
            [137.953411628000111, -40.678027685999936],
            [137.937438628000194, -40.668549686],
            [137.921527628000177, -40.659013686000037],
            [137.905679628000058, -40.649419685999987],
            [137.889894628000235, -40.639767686000027],
            [137.874172628000139, -40.630057685999972],
            [137.858514628000165, -40.620290686],
            [137.84291962800026, -40.610466685999938],
            [137.827001628000204, -40.601138685999956],
            [137.81114362800011, -40.591753685999976],
            [137.795346628000203, -40.582310685999985],
            [137.779611628000254, -40.572810686],
            [137.763937628000036, -40.563252686],
            [137.748325628000231, -40.553638685999985],
            [137.732776628000153, -40.543968686000042],
            [137.717289628000202, -40.534241686],
            [137.701866628000147, -40.524458685999946],
            [137.68650662800016, -40.514619685999975],
            [137.671209628000128, -40.504724685999975],
            [137.655977628000159, -40.494774685999971],
            [137.640809628000142, -40.484769685999936],
            [137.625706628000188, -40.474709686000054],
            [137.610668628000184, -40.46459468599997],
            [137.595695628000129, -40.454424685999967],
            [137.580788628000136, -40.444200685999931],
            [137.565947628000259, -40.433923685999943],
            [137.551172628000103, -40.423591685999952],
            [137.536464628000232, -40.413206686],
            [137.521823628000192, -40.402768685999931],
            [137.507249628000153, -40.392277686000014],
            [137.492743628000113, -40.381733685999961],
            [137.478305628000186, -40.371136685999971],
            [137.4639356280002, -40.360487685999942],
            [137.4496336280001, -40.349786685999959],
            [137.435400628000167, -40.339033685999951],
            [137.421236628000173, -40.328229685999972],
            [137.407141628000232, -40.317373685999954],
            [137.393116628000115, -40.306466685999979],
            [137.379161628000105, -40.29550968599996],
            [137.3652776280002, -40.284501685999984],
            [137.351463628000118, -40.273442685999953],
            [137.337719628000087, -40.26233468599996],
            [137.324047628000045, -40.251176686],
            [137.310446628000165, -40.239968686],
            [137.296917628000159, -40.22871168599994],
            [137.283460628000142, -40.217405686],
            [137.270075628000228, -40.206050686],
            [137.256762628000132, -40.194647685000021],
            [137.243522628000136, -40.18319668499997],
            [137.230355628000126, -40.171697684999955],
            [137.217262628000213, -40.160150684999977],
            [137.204242628000117, -40.148555685],
            [137.191295628000177, -40.136914684999965],
            [137.178423628000161, -40.125225685000046],
            [137.165626628000126, -40.113490684999945],
            [137.152902628000191, -40.10170968499996],
            [137.140254628000235, -40.089882685],
            [137.127643628000243, -40.07822568499995],
            [137.115105628000123, -40.066522684999939],
            [137.102641628000043, -40.05477568500001],
            [137.090251628000175, -40.042983685],
            [137.077935628000176, -40.031146685],
            [137.065693628000218, -40.019266685],
            [137.053525628000187, -40.007341684999986],
            [137.041433628000135, -39.995373684999976],
            [137.02941562800018, -39.983361684999977],
            [137.017472628000206, -39.971307684999957],
            [137.005605628000097, -39.959209685000033],
            [136.993814628000251, -39.947069685],
            [136.982098628000159, -39.934887684999964],
            [136.9704596280001, -39.922663685],
            [136.958895628000192, -39.910397685000035],
            [136.947409628000202, -39.89808968499996],
            [136.935999628000189, -39.885741685000042],
            [136.92466662800021, -39.873351684999932],
            [136.91341062800015, -39.86092168499998],
            [136.902231628000237, -39.848450684999918],
            [136.891131627000192, -39.835940685000011],
            [136.880107627000058, -39.823389685],
            [136.869958627000159, -39.811936684999964],
            [136.859874627000096, -39.800449684999975],
            [136.849854627000155, -39.788930684999983],
            [136.83990162700016, -39.777377685000033],
            [136.830013627000113, -39.765792685],
            [136.82019062700013, -39.75417468500001],
            [136.810434627000092, -39.742524685000021],
            [136.800743627000173, -39.730842684999971],
            [136.794837627000192, -39.723667684999967],
            [136.788955627000149, -39.716480685000036],
            [136.769095627000155, -39.714759684999919],
            [136.749246627000076, -39.712963685],
            [136.729410627000192, -39.711093685],
            [136.709585627000166, -39.709148685],
            [136.689774627000105, -39.70713068500001],
            [136.669976627000182, -39.705037685000036],
            [136.650193627000164, -39.702871684999963],
            [136.630423627000113, -39.700630685],
            [136.610669627000192, -39.698316685000023],
            [136.590930627000176, -39.69592868499997],
            [136.571207627000121, -39.693465685000021],
            [136.551500627000252, -39.690930684999969],
            [136.531810627000112, -39.688320685000022],
            [136.512138627000098, -39.685637684999989],
            [136.492483627000098, -39.682881685000041],
            [136.472847627000164, -39.680050685000012],
            [136.453229627000184, -39.67714768499998],
            [136.43363162700021, -39.674170685000036],
            [136.414052627000075, -39.67112068500002],
            [136.394493627000116, -39.667997684999989],
            [136.374956627000103, -39.66480068499996],
            [136.355439627000152, -39.661531684999943],
            [136.335944627000146, -39.658189685],
            [136.316471627000084, -39.654773684999981],
            [136.297021627000078, -39.651285684999948],
            [136.277593627000186, -39.647725685],
            [136.258189627000178, -39.644091684999964],
            [136.238810627000106, -39.64038668500001],
            [136.219454627000147, -39.636607684999973],
            [136.200124627000179, -39.63275768499993],
            [136.180819627000204, -39.628834684999973],
            [136.16153962700011, -39.624839685],
            [136.142287627000229, -39.62077268500002],
            [136.123060627000228, -39.616633684999954],
            [136.103862627000098, -39.61242268499997],
            [136.08469162700024, -39.608139684999969],
            [136.065548627000254, -39.603785684999963],
            [136.046433627000141, -39.599359684999953],
            [136.027348627000066, -39.594862685000024],
            [136.008292627000145, -39.590294684999975],
            [135.989267627000203, -39.585654684999938],
            [135.970272627000185, -39.580943684999966],
            [135.951308627000259, -39.576162684999986],
            [135.932375627000198, -39.571309684999989],
            [135.913474627000056, -39.566386684999969],
            [135.894605627000061, -39.561392684999944],
            [135.875769627000153, -39.556327685],
            [135.856966627000105, -39.551192684999947],
            [135.838197627000142, -39.545987684999957],
            [135.819462626000046, -39.54071268499996],
            [135.800762626000193, -39.535367685000026],
            [135.782097626000194, -39.529952684999984],
            [135.763467626000164, -39.524467684999919],
            [135.744873626000214, -39.518912685000018],
            [135.726315626000115, -39.51328968499999],
            [135.707794626000037, -39.507595684999956],
            [135.689310626000207, -39.50183368499998],
            [135.670864626000053, -39.496002684999979],
            [135.652456626000259, -39.490101684999971],
            [135.634086626000254, -39.484132685],
            [135.615756626000206, -39.478094685000016],
            [135.597464626000175, -39.471988685],
            [135.579213626000154, -39.465814684999955],
            [135.56100262600026, -39.459571684999972],
            [135.542832626000205, -39.453260685000046],
            [135.524702626000163, -39.446882685],
            [135.506614626000129, -39.440436685],
            [135.488569626000213, -39.43392268499997],
            [135.470565626000194, -39.427341684999917],
            [135.452605626000178, -39.420692685],
            [135.434687626000112, -39.413977684999949],
            [135.416814626000104, -39.407194684999965],
            [135.3989846260001, -39.400345684999934],
            [135.381199626000097, -39.393429684999958],
            [135.363459626000093, -39.386447684999936],
            [135.34576462600026, -39.379399684999953],
            [135.328115626000141, -39.37228468499994],
            [135.31051262600019, -39.365104684999977],
            [135.292955626000122, -39.357858684999954],
            [135.275446626000218, -39.350546684999983],
            [135.257984626000194, -39.343169684999964],
            [135.240569626000166, -39.335727684999981],
            [135.223203626000185, -39.328220684999962],
            [135.205886626000193, -39.320648684999966],
            [135.188617626000251, -39.313011684999935],
            [135.171398626000183, -39.305310684999924],
            [135.154229626000159, -39.29754568499996],
            [135.137110626000123, -39.289715685000019],
            [135.120041626000187, -39.281822684999938],
            [135.103023626000237, -39.273864685],
            [135.086057626000155, -39.265844684999976],
            [135.069142626000172, -39.25776068499998],
            [135.052280626000169, -39.249612684999946],
            [135.035470626000262, -39.241402685000011],
            [135.018713626000164, -39.233129684999938],
            [135.002010626000157, -39.224794684999964],
            [134.985360626000244, -39.216396685000021],
            [134.968764626000137, -39.207936684999922],
            [134.952222626000122, -39.199415685000019],
            [134.935735626000138, -39.190831685000049],
            [134.91930462600024, -39.182186685],
            [134.902928626000204, -39.173480684999987],
            [134.886608626000196, -39.164712684999984],
            [134.870344626000104, -39.15588468499999],
            [134.854137626000096, -39.146995685],
            [134.837987626000171, -39.138045684999952],
            [134.821894626000102, -39.129035685],
            [134.805859626000057, -39.119965683999965],
            [134.78988262600015, -39.110835683999937],
            [134.773963626000096, -39.101646684000016],
            [134.758104626000119, -39.092397684],
            [134.742303625000119, -39.083089683999987],
            [134.726562625000128, -39.073723683999987],
            [134.710881625000212, -39.064297683999982],
            [134.695260625000202, -39.054813683999981],
            [134.679700625000095, -39.045271683999971],
            [134.664201625000061, -39.035670683999967],
            [134.6487636250001, -39.026012683999951],
            [134.633386625000099, -39.01629668399994],
            [134.618072625000167, -39.006523684],
            [134.60281962500008, -38.996693683999965],
            [134.587630625000173, -38.986805683999933],
            [134.57250362500011, -38.976861683999971],
            [134.557439625000171, -38.966861684],
            [134.542440625000125, -38.956805684],
            [134.527504625000205, -38.946692684],
            [134.513297625000206, -38.939162683999939],
            [134.499133625000155, -38.931585683999984],
            [134.485010625000172, -38.923963683999943],
            [134.470930625000136, -38.916294684],
            [134.456893625000163, -38.908580683999986],
            [134.442758625000096, -38.901385683999933],
            [134.428663625000155, -38.894145683999966],
            [134.412363625000097, -38.885694683999986],
            [134.396118625000241, -38.877182683999948],
            [134.379925625000141, -38.868611683999987],
            [134.36378862500024, -38.859979683999953],
            [134.347704625000205, -38.851287683999928],
            [134.331676625000256, -38.842536684],
            [134.315703625000168, -38.833725684],
            [134.29978662500011, -38.824855683999985],
            [134.283925625000137, -38.81592668399999],
            [134.26812062500008, -38.806938683999988],
            [134.252372625000049, -38.797891684],
            [134.236681625000102, -38.788786684],
            [134.221047625000182, -38.779623684],
            [134.205471625000172, -38.77040268399999],
            [134.189953625000129, -38.761122683999986],
            [134.174494625000222, -38.751786683999981],
            [134.159093625000111, -38.742392684000052],
            [134.143752625000189, -38.732941684000025],
            [134.128470625000233, -38.723433683999986],
            [134.113248625000125, -38.713868684000033],
            [134.098085625000152, -38.704247683999967],
            [134.082984625000194, -38.694570683999984],
            [134.0679426250002, -38.68483668399999],
            [134.05296262500022, -38.675047683999978],
            [134.038044625000197, -38.665202683999937],
            [134.023187625000247, -38.655302683999977],
            [134.008392625000198, -38.645347684],
            [133.993660625000103, -38.635337684],
            [133.978990625000193, -38.625272683999967],
            [133.964383625000238, -38.615153684],
            [133.949840625000235, -38.604980684000012],
            [133.935360625000129, -38.594753684],
            [133.920944625000089, -38.584472683999948],
            [133.906592625000172, -38.574137683999965],
            [133.892305625000148, -38.563750683999942],
            [133.878082625000076, -38.553309683999984],
            [133.863925625000235, -38.542816683999973],
            [133.84983362500023, -38.532270684000039],
            [133.83580662500006, -38.521672683999967],
            [133.821846625000177, -38.51102268399994],
            [133.80795262500024, -38.500320683999959],
            [133.794125625000248, -38.489567683999951],
            [133.780364625000203, -38.478763683999972],
            [133.766671625000214, -38.467907683999954],
            [133.753045625000055, -38.457001683999977],
            [133.739487625000123, -38.446044683999958],
            [133.726002625000177, -38.435045683999917],
            [133.712586625000171, -38.423996684000016],
            [133.699238625000049, -38.412897683999972],
            [133.685959625000095, -38.401749684000038],
            [133.672748624000093, -38.390552683999971],
            [133.659607624000188, -38.379305684000016],
            [133.64653662400022, -38.368010684000012],
            [133.633534624000191, -38.35666768399993],
            [133.620603624000097, -38.345275683999972],
            [133.60774262400011, -38.333836683999948],
            [133.594951624000117, -38.32234968399996],
            [133.582231624000229, -38.310814683999979],
            [133.569583624000103, -38.29923368399993],
            [133.557005624000254, -38.287604684],
            [133.544500624000221, -38.275929684],
            [133.532066624000123, -38.264208683999982],
            [133.519705624000181, -38.252441684],
            [133.507415624000174, -38.240627683999946],
            [133.495199624000094, -38.228769683999985],
            [133.483055624000173, -38.216865684000027],
            [133.470984624000181, -38.204916684],
            [133.458987624000173, -38.192923683999965],
            [133.447063624000151, -38.180885684000032],
            [133.435213624000227, -38.168803684000011],
            [133.423438624000113, -38.156677683999987],
            [133.411736624000099, -38.144507683999962],
            [133.400109624000066, -38.13229468399993],
            [133.388556624000188, -38.120039683999977],
            [133.377079624000231, -38.107740684000035],
            [133.365677624000256, -38.095399683999986],
            [133.354350624000148, -38.083015684000017],
            [133.343098624000078, -38.070590684000038],
            [133.331923624000211, -38.058123683999952],
            [133.320823624000155, -38.045615682999937],
            [133.309800624000189, -38.033066683],
            [133.298853624000202, -38.020476682999956],
            [133.287983624000191, -38.007845682999985],
            [133.277189624000158, -37.995174682999988],
            [133.266473624000156, -37.982463682999963],
            [133.255834624000073, -37.969713683],
            [133.247186624000136, -37.959252682999988],
            [133.238590624000068, -37.948766683000031],
            [133.230045624000041, -37.938253682999971],
            [133.221553624000222, -37.927715682999988],
            [133.203436624000204, -37.921986682999986],
            [133.185355624000209, -37.916189682999956],
            [133.167311624000178, -37.91032268299999],
            [133.149305624000107, -37.904386683000013],
            [133.13133662400017, -37.898382683],
            [133.113406624000135, -37.892309682999951],
            [133.095514624000174, -37.88616868299998],
            [133.077661624000058, -37.879959683000052],
            [133.059848624000125, -37.873681683000015],
            [133.042074624000151, -37.867336683000033],
            [133.024341624000186, -37.860922683000013],
            [133.006648624000064, -37.854442682999959],
            [132.98899762400012, -37.847893682999981],
            [132.97138762400013, -37.841278683000027],
            [132.953819624000147, -37.834595682999975],
            [132.936293624000172, -37.827845682999964],
            [132.918810624000201, -37.821029683],
            [132.90137162400012, -37.814145683],
            [132.883975624000158, -37.807196683000043],
            [132.8666226240002, -37.800179682999968],
            [132.849315624000127, -37.793097683000013],
            [132.832052624000227, -37.785949682999942],
            [132.814834624000156, -37.778735683],
            [132.797662624000083, -37.771455683000013],
            [132.780535624000237, -37.76411068299997],
            [132.763456624000042, -37.756700682999977],
            [132.746422624000189, -37.749224683000023],
            [132.729437624000212, -37.741684683000017],
            [132.712498624000119, -37.734079682999962],
            [132.695608624000187, -37.726409682999943],
            [132.678766624000133, -37.718675682999972],
            [132.66197362400024, -37.710876683000023],
            [132.645228624000225, -37.703014682999935],
            [132.628534624000139, -37.695088682999966],
            [132.611889624000156, -37.687098682999945],
            [132.595294623000115, -37.679044683000043],
            [132.578750623000104, -37.670928682999985],
            [132.562258623000133, -37.662748682999961],
            [132.54581662300015, -37.654506682999951],
            [132.529426623000205, -37.646201682999973],
            [132.513089623000184, -37.63783368299994],
            [132.496804623000202, -37.629403683000021],
            [132.480572623000199, -37.620911683000017],
            [132.46439362300012, -37.612358682999954],
            [132.448268623000246, -37.603742683],
            [132.432196623000124, -37.595065682999987],
            [132.416179623000204, -37.586327682999979],
            [132.400217623000145, -37.577528682999983],
            [132.384310623000175, -37.568668683],
            [132.368459623000064, -37.559748682999953],
            [132.352663623000041, -37.550767682999989],
            [132.336923623000217, -37.541725682999967],
            [132.32124062300025, -37.532624682999952],
            [132.305613623000255, -37.523463682999932],
            [132.290044623000114, -37.514243683000018],
            [132.274532623000056, -37.504963683000014],
            [132.25907962300019, -37.495625683000014],
            [132.243683623000123, -37.48622768300001],
            [132.228346623000192, -37.476771683000024],
            [132.213068623000112, -37.467256683000031],
            [132.197849623000167, -37.457683683000027],
            [132.182690623000184, -37.448052682999943],
            [132.167591623000106, -37.438363683000034],
            [132.152552623000105, -37.428617683000013],
            [132.13757462300012, -37.418814683],
            [132.122656623000097, -37.408953682999979],
            [132.107800623000145, -37.399036683000034],
            [132.093005623000153, -37.389062682999977],
            [132.078273623000172, -37.37903268299992],
            [132.063602623000151, -37.368945682999936],
            [132.048994623000198, -37.358803683000033],
            [132.034449623000143, -37.348605683000017],
            [132.019967623000156, -37.338352682999968],
            [132.005549623000178, -37.328043683],
            [131.991194623000212, -37.317680682999935],
            [131.976904623000195, -37.307262683],
            [131.962678623000244, -37.296790682999969],
            [131.948516623000074, -37.286263683],
            [131.934420623000193, -37.275683683],
            [131.920389623000204, -37.265049682999958],
            [131.906424623000163, -37.254361682999985],
            [131.892524623000185, -37.243620682999989],
            [131.878691623000151, -37.232827683000039],
            [131.864925623000232, -37.22198068299997],
            [131.851225623000261, -37.211082683000029],
            [131.837592623000177, -37.200131682999967],
            [131.824027623000262, -37.18912868300005],
            [131.810529623000235, -37.178073683],
            [131.797100623000148, -37.166968683],
            [131.783738623000175, -37.155811683000024],
            [131.770446623000197, -37.144603683000014],
            [131.757222623000217, -37.133345682999959],
            [131.74406762300012, -37.122036683000033],
            [131.730981623000076, -37.110677682999963],
            [131.71796562300014, -37.099269682999932],
            [131.705020623000195, -37.087811683000027],
            [131.692144623000189, -37.076303682999978],
            [131.679339623000118, -37.06474768299995],
            [131.666604623000154, -37.053142682999962],
            [131.653940623000125, -37.041488683],
            [131.641348623000198, -37.029786682999969],
            [131.628827623000262, -37.018037682999974],
            [131.616378623000145, -37.00623968299999],
            [131.60400162300013, -36.994395682999951],
            [131.591697623000215, -36.982503683],
            [131.579464623000064, -36.970564682],
            [131.567305623000124, -36.958579681999922],
            [131.555218623000229, -36.946548681999943],
            [131.543205623000148, -36.93447168199998],
            [131.531266622000231, -36.922347681999938],
            [131.51940062200012, -36.910179682],
            [131.507608622000049, -36.897965681999978],
            [131.495890622000189, -36.885707682000046],
            [131.484247622000254, -36.873404681999943],
            [131.472678622000132, -36.861057682000023],
            [131.461184622000104, -36.848665681999918],
            [131.449766622000112, -36.836230682],
            [131.438423622000215, -36.823752682],
            [131.427155622000129, -36.811230681999973],
            [131.415963622000191, -36.798666682000032],
            [131.404847622000176, -36.786059682],
            [131.393808622000194, -36.773409681999965],
            [131.382845622000133, -36.760718682],
            [131.371959622000219, -36.747985682000021],
            [131.361175622000104, -36.73525368199995],
            [131.350467622000252, -36.722481681999952],
            [131.339837622000204, -36.709668682000014],
            [131.329284622000131, -36.696814681999982],
            [131.318807622000037, -36.68392168199999],
            [131.308409622000255, -36.670988681999987],
            [131.298087622000111, -36.658015682000055],
            [131.287844622000165, -36.645004682],
            [131.277679622000136, -36.631953681999988],
            [131.267591622000083, -36.618864682000051],
            [131.257583622000226, -36.605737681999983],
            [131.247652622000118, -36.592572681999968],
            [131.237801622000205, -36.579369682],
            [131.228028622000096, -36.566129682],
            [131.218335622000126, -36.552852682000051],
            [131.208721622000184, -36.539538681999957],
            [131.199186622000212, -36.526188682],
            [131.18973162200021, -36.512802682],
            [131.180356622000176, -36.49937968200004],
            [131.171061622000167, -36.485922682000023],
            [131.16184662200024, -36.472429682000055],
            [131.152711622000226, -36.458901682000018],
            [131.14365762200012, -36.445338681999942],
            [131.134683622000097, -36.431742681999964],
            [131.12579162200015, -36.418111682000031],
            [131.116979622000059, -36.40444768199994],
            [131.108248622000104, -36.390749681999964],
            [131.09959962200017, -36.377018682000013],
            [131.091031622000202, -36.363254682],
            [131.082544622000256, -36.349458682],
            [131.074140622000101, -36.33563068199993],
            [131.065817622000139, -36.321770681999965],
            [131.057576622000198, -36.307879682],
            [131.049417622000163, -36.293956681999987],
            [131.041341622000203, -36.28000268199996],
            [131.033347622000207, -36.266018682000038],
            [131.02543562200006, -36.252004681999949],
            [131.017607622000043, -36.237960681999951],
            [131.009861622000045, -36.223886681999943],
            [131.002198622000066, -36.209782681999954],
            [130.994618622000218, -36.19565068199995],
            [130.987121622000046, -36.181489681999949],
            [130.979708622000231, -36.167300681999947],
            [130.972378622000207, -36.153082681999933],
            [130.9651316220002, -36.138837682],
            [130.957968622000209, -36.124565681999968],
            [130.95089062200023, -36.110265682],
            [130.943894622000101, -36.095939681999937],
            [130.936983622000099, -36.081586681999951],
            [130.930156622000112, -36.067207681999946],
            [130.923414622000138, -36.052802682],
            [130.916755622000125, -36.038372681999945],
            [130.910181622000181, -36.023916681999964],
            [130.903691622000196, -36.009436681999944],
            [130.897287622000107, -35.994931682],
            [130.890966622000093, -35.980402682],
            [130.884731622000146, -35.965849682],
            [130.878580622000214, -35.951272682000038],
            [130.872515622000122, -35.936672681999951],
            [130.86653462200016, -35.922049682],
            [130.860639622000093, -35.907404681999921],
            [130.854829622000153, -35.892736680999974],
            [130.849104622000112, -35.878047680999984],
            [130.843465622000195, -35.86333668099995],
            [130.837911622000121, -35.848603680999972],
            [130.832442622000116, -35.833850681000015],
            [130.827060622000232, -35.819075681000015],
            [130.821762622000193, -35.804281680999949],
            [130.811923622000137, -35.791137680999981],
            [130.802161622000227, -35.77795568099998],
            [130.792477622000177, -35.764736680999945],
            [130.782870622000047, -35.751480681000032],
            [130.773342622000229, -35.738187681],
            [130.763893622000097, -35.724858681],
            [130.75452262200011, -35.71149368100005],
            [130.745229622000096, -35.698092680999963],
            [130.736016622000221, -35.684655681],
            [130.726882622000147, -35.671183680999988],
            [130.717826622000103, -35.657676681],
            [130.708851622000196, -35.644135680999966],
            [130.699954622000206, -35.630559680999966],
            [130.691138622000125, -35.616950680999977],
            [130.682401622000185, -35.603307680999933],
            [130.673744622000214, -35.589630681000017],
            [130.665168622000039, -35.575920681000028],
            [130.656671622000175, -35.562178680999963],
            [130.648256622000218, -35.548403681000011],
            [130.639920622000176, -35.534595681],
            [130.631666622000154, -35.520757681],
            [130.6234926220001, -35.506886681],
            [130.61540062200018, -35.492984680999939],
            [130.607388622000173, -35.479052681000056],
            [130.599458622000071, -35.465089681],
            [130.591610622000161, -35.45109568099997],
            [130.583843622000103, -35.437072681000018],
            [130.576157622000181, -35.423018680999988],
            [130.568554622000221, -35.408936680999943],
            [130.56103262200017, -35.394825681],
            [130.553593622000193, -35.380684680999963],
            [130.546236622000123, -35.366516681],
            [130.538961622000187, -35.35231968100004],
            [130.531768622000214, -35.338095680999984],
            [130.524658622000089, -35.323843681],
            [130.517631622000152, -35.309564681],
            [130.510686622000179, -35.295258681],
            [130.503825622000051, -35.280926680999968],
            [130.497046622000227, -35.266567681000012],
            [130.490351622000134, -35.252183680999948],
            [130.483738622000175, -35.23777368099995],
            [130.477209622000061, -35.223338681000016],
            [130.470764622000075, -35.208878680999959],
            [130.464402622000108, -35.19439368099998],
            [130.458123621000169, -35.179884680999962],
            [130.441557621000101, -35.173479680999989],
            [130.425028621000109, -35.167010680999951],
            [130.408538621000133, -35.160478681000043],
            [130.392086621000118, -35.153882680999985],
            [130.375673621000118, -35.14722468099994],
            [130.359300621000244, -35.140503681000027],
            [130.342966621000215, -35.133720680999943],
            [130.326672621000199, -35.12687468099999],
            [130.310419621000136, -35.119966680999951],
            [130.294206621000086, -35.112995680999958],
            [130.278035621000214, -35.10596268099998],
            [130.261905621000182, -35.098868680999928],
            [130.2458176210001, -35.09171268099999],
            [130.229771621000083, -35.084494680999981],
            [130.213767621000187, -35.077215680999984],
            [130.197806621000183, -35.069875680999914],
            [130.181889621000124, -35.062474680999955],
            [130.166015621000128, -35.055011680999925],
            [130.15018462100025, -35.047489680999988],
            [130.134399621000085, -35.039905680999979],
            [130.118657621000096, -35.03226168099998],
            [130.102961621000105, -35.024557681],
            [130.087310621000171, -35.016793681000024],
            [130.07170462100018, -35.008969680999968],
            [130.056144621000129, -35.001086681],
            [130.040631621000188, -34.993142680999966],
            [130.0271126210001, -34.998088681000013],
            [130.011485621000048, -35.004287681],
            [129.995823621000199, -35.010428680999979],
            [129.980128621000205, -35.01651368099995],
            [129.964398621000129, -35.022540681],
            [129.948635621000193, -35.028510680999958],
            [129.931780621000115, -35.034824681000046],
            [129.914888621000188, -35.041073680999986],
            [129.897958621000072, -35.047255680999982],
            [129.880992621000161, -35.053372681000027],
            [129.86399062100017, -35.059423680999927],
            [129.846952621000156, -35.06540868099998],
            [129.829878621000177, -35.071326680999974],
            [129.812770621000169, -35.077178681000035],
            [129.796503621000085, -35.082818680999949],
            [129.780204621000195, -35.088398680999958],
            [129.763874621000156, -35.093917681],
            [129.747512621000197, -35.099376681000038],
            [129.731120621000144, -35.104774681],
            [129.714697621000056, -35.110111681],
            [129.698244621000214, -35.115388680999985],
            [129.681761621000106, -35.120603681],
            [129.665249621000186, -35.125758681000022],
            [129.648661621000116, -35.13122268099994],
            [129.632042621000124, -35.13662568099997],
            [129.615391621000157, -35.141965680999931],
            [129.598711621000092, -35.147243681],
            [129.582000621000049, -35.152458681000013],
            [129.565259621000195, -35.157610680999966],
            [129.553056621000195, -35.161674680999965],
            [129.540835621000127, -35.16570468099998],
            [129.528597621000159, -35.169700680999952],
            [129.511913621000218, -35.175220680999971],
            [129.495198621000185, -35.18067768100002],
            [129.478450621000178, -35.186070680999933],
            [129.46167262100019, -35.191401680999959],
            [129.44486362100011, -35.196668681],
            [129.428024621000219, -35.201872681],
            [129.411155621000177, -35.207013681000021],
            [129.394256621000096, -35.212090680999978],
            [129.377431620000124, -35.217269680999948],
            [129.36057662000016, -35.222384680999951],
            [129.343691620000101, -35.227436680999972],
            [129.326778620000113, -35.232424680999955],
            [129.309836620000141, -35.237349680999955],
            [129.292865620000185, -35.242210680999989],
            [129.275867620000184, -35.247007680999957],
            [129.2588406200002, -35.251740680999973],
            [129.241787620000167, -35.256409681000022],
            [129.22470762000026, -35.261014681],
            [129.20747462000017, -35.265589681],
            [129.190215620000259, -35.270099681000048],
            [129.172930620000244, -35.27454368099994],
            [129.155619620000181, -35.278922680999983],
            [129.138283620000124, -35.283235680999965],
            [129.12092262000013, -35.287482681],
            [129.103536620000256, -35.291663680999989],
            [129.086126620000215, -35.295779680999942],
            [129.068691620000124, -35.299828681000022],
            [129.051234620000088, -35.303812680999968],
            [129.034027620000217, -35.309764680999947],
            [129.016784620000095, -35.315648680999971],
            [129.001477620000145, -35.320801681],
            [128.999506620000119, -35.321465680999978],
            [128.982193620000174, -35.327215681000027],
            [128.964846620000259, -35.332897680999949],
            [128.947465620000202, -35.338511680999943],
            [128.930050620000173, -35.344058680999979],
            [128.912602620000172, -35.349537680999987],
            [128.895121620000197, -35.35494768099997],
            [128.877608620000132, -35.360290681],
            [128.860063620000261, -35.365564681],
            [128.842486620000074, -35.370770681],
            [128.824878620000135, -35.375907680999944],
            [128.807240620000044, -35.380975680999967],
            [128.789571620000203, -35.385975680999962],
            [128.77187262000021, -35.390906680999919],
            [128.754144620000062, -35.395767680999953],
            [128.736386620000161, -35.400560680999959],
            [128.71860062000016, -35.405283680999943],
            [128.700785620000062, -35.409937680999988],
            [128.682943620000145, -35.414521680999925],
            [128.665073620000129, -35.419036681000023],
            [128.647176620000181, -35.423481681000013],
            [128.629253620000128, -35.42785668099998],
            [128.611303620000143, -35.432162681],
            [128.593327620000224, -35.436397681000031],
            [128.575326620000141, -35.440562681000031],
            [128.557301620000175, -35.44465768100001],
            [128.539250620000161, -35.44868268099998],
            [128.521176620000148, -35.452636681000016],
            [128.503077620000198, -35.456520681000029],
            [128.485884620000235, -35.460141680999968],
            [128.468669620000156, -35.463698681000025],
            [128.451434620000242, -35.467192681000014],
            [128.434180620000149, -35.470622680999952],
            [128.416906620000219, -35.473988681],
            [128.399613620000167, -35.477291681000011],
            [128.38230162000005, -35.480529680999965],
            [128.364970620000037, -35.483703680999938],
            [128.347132620000167, -35.487935680999982],
            [128.32926962000019, -35.492098680999987],
            [128.311381619000116, -35.496192680999982],
            [128.293469619000149, -35.500217681000038],
            [128.275533619000186, -35.504173680999969],
            [128.257573619000169, -35.508059680999978],
            [128.239590619000154, -35.511876680999961],
            [128.221585619000138, -35.515623681000022],
            [128.203557619000179, -35.519301680999959],
            [128.185507619000219, -35.522908680999976],
            [128.167436619000256, -35.526446680999967],
            [128.149344619000232, -35.529914681000022],
            [128.131231619000204, -35.533312680999984],
            [128.113098619000169, -35.536641681],
            [128.094945619000185, -35.539898681000011],
            [128.076773619000136, -35.543086681],
            [128.058581619000194, -35.546204680999963],
            [128.040372619000124, -35.54925168099993],
            [128.022144619000215, -35.552227681000048],
            [128.003898619000182, -35.555133680999973],
            [127.985635619000078, -35.55796968099996],
            [127.967356619000128, -35.560734680999943],
            [127.949060619000221, -35.563428681],
            [127.930748619000127, -35.56605268099996],
            [127.91242061900013, -35.568604681],
            [127.894077619000228, -35.571086681000011],
            [127.87571961900008, -35.573497680999921],
            [127.857348619000192, -35.575837681],
            [127.838962619000171, -35.578105680999982],
            [127.820563619000183, -35.580303681000032],
            [127.802151619000171, -35.582430681],
            [127.783726619000134, -35.584485680999933],
            [127.765289619000185, -35.58646968100004],
            [127.746840619000153, -35.588382681000056],
            [127.728380619000148, -35.590223680999969],
            [127.709909619000229, -35.591993680999948],
            [127.691428619000106, -35.593692680999936],
            [127.672937619000237, -35.595319681],
            [127.654436619000222, -35.596875681000043],
            [127.635926619000173, -35.598359681],
            [127.62146061900026, -35.600258681],
            [127.606986619000196, -35.602114680999989],
            [127.592504619000096, -35.603925681],
            [127.583202619000161, -35.616788680999989],
            [127.573827619000184, -35.629615681000018],
            [127.564379619000164, -35.642408680999978],
            [127.5548586190001, -35.655165680999986],
            [127.545264619000164, -35.667886681000041],
            [127.535598619000183, -35.680572680999944],
            [127.525859619000045, -35.693221680999983],
            [127.516048619000259, -35.705834680999985],
            [127.5061656190002, -35.718410681000023],
            [127.496209619000211, -35.730949681000027],
            [127.486183619000116, -35.743451680999982],
            [127.475715619000226, -35.756373680999957],
            [127.465171619000188, -35.769256681],
            [127.454550619000173, -35.782097681000039],
            [127.44385361900018, -35.794897680999952],
            [127.433080619000094, -35.807655680999957],
            [127.422230619000203, -35.820372681000038],
            [127.411306619000158, -35.833046681000013],
            [127.400305619000136, -35.845678680999981],
            [127.389230619000131, -35.858268681000027],
            [127.378079619000147, -35.870814680999985],
            [127.366854619000065, -35.883317680999937],
            [127.355554619000173, -35.89577768099997],
            [127.344179619000187, -35.908193681999919],
            [127.332731619000043, -35.92056568199996],
            [127.321208619000259, -35.932892681999917],
            [127.309612619000205, -35.945174681999973],
            [127.297942619000167, -35.957412682000026],
            [127.286200619000141, -35.969604682],
            [127.274384619000188, -35.981751681999981],
            [127.262495619000134, -35.993852681999982],
            [127.250534619000092, -36.005907681999986],
            [127.238501618000129, -36.017915682000023],
            [127.226395618000225, -36.029877681999977],
            [127.21421861800016, -36.041792681999951],
            [127.201970618000104, -36.053660681999943],
            [127.189650618000172, -36.065480681999958],
            [127.177259618000249, -36.077252682],
            [127.164797618000222, -36.088976681999981],
            [127.1522656180002, -36.100652681999989],
            [127.139663618000128, -36.112279681999937],
            [127.129468618000175, -36.12350168200004],
            [127.119212618000262, -36.134687682],
            [127.108896618000159, -36.145837682],
            [127.098520618000151, -36.156952681999954],
            [127.088084618000067, -36.168030681999952],
            [127.077588618000192, -36.179072681999983],
            [127.067032618000127, -36.190077681999981],
            [127.056417618000154, -36.201046682000026],
            [127.045743618000103, -36.211978681999938],
            [127.034343618000179, -36.223665681999961],
            [127.02287661800014, -36.235310681999977],
            [127.011341618000102, -36.246912681999987],
            [126.999739618000177, -36.258471682],
            [126.988070618000137, -36.269987682],
            [126.976335618000036, -36.281459682],
            [126.964533618000161, -36.292888681999983],
            [126.952665618000168, -36.304273681999987],
            [126.940731618000228, -36.315614681999989],
            [126.928730618000174, -36.326911681999988],
            [126.91666561800011, -36.338162681999989],
            [126.904534618000213, -36.349369682],
            [126.892338618000082, -36.360531681999944],
            [126.880077618000172, -36.371648681999972],
            [126.867751618000199, -36.382719682],
            [126.855361618000103, -36.393744681999976],
            [126.842907618000112, -36.404722681999957],
            [126.834700618000085, -36.411909681999973],
            [126.821801618000137, -36.423134681999969],
            [126.808835618000245, -36.434309682],
            [126.795802618000124, -36.44543468199997],
            [126.782702618000172, -36.456509681999989],
            [126.76953661800016, -36.467535681999948],
            [126.7563046180002, -36.478510682000035],
            [126.74300661800018, -36.489434682],
            [126.729642618000156, -36.500308682],
            [126.716213618000239, -36.511130681999944],
            [126.702719618000089, -36.521901682000035],
            [126.689161618000156, -36.532621681999984],
            [126.675537618000163, -36.543289682],
            [126.661850618000045, -36.553904681999953],
            [126.648098618000205, -36.564467681999972],
            [126.634283618000069, -36.574978681999951],
            [126.620405618000206, -36.585436682],
            [126.606463618000106, -36.595841682],
            [126.592459618000049, -36.606192681999971],
            [126.578393618000092, -36.616490682],
            [126.56426461800018, -36.626734682],
            [126.550073618000084, -36.636924681999986],
            [126.535822618000253, -36.647060682000017],
            [126.523234618000203, -36.658269682000025],
            [126.510580618000205, -36.669429681999958],
            [126.497860618000146, -36.680542682],
            [126.485073618000143, -36.691607682],
            [126.472222618000188, -36.702625682],
            [126.459304618000175, -36.713593681999967],
            [126.44632261800021, -36.724514681999935],
            [126.433275618000181, -36.735385681999958],
            [126.420163618000203, -36.746207682],
            [126.406986618000161, -36.756980681999977],
            [126.393746618000165, -36.767703681999919],
            [126.380442618000103, -36.77837768199997],
            [126.367074618000146, -36.789001681999977],
            [126.35364261800018, -36.799574682000028],
            [126.3401486180002, -36.810097681999935],
            [126.326591618000094, -36.820568681999973],
            [126.312972618000259, -36.830989681999966],
            [126.299290618000185, -36.841359682],
            [126.285547618000038, -36.851678682],
            [126.271742618000104, -36.861944681999958],
            [126.257875618000156, -36.87215968199996],
            [126.243948618000246, -36.882321681999926],
            [126.229959618000095, -36.892432681999935],
            [126.215910618000208, -36.902489682],
            [126.201801618000246, -36.912494681999959],
            [126.187633618000206, -36.922446681999958],
            [126.173202618000261, -36.93250868199992],
            [126.158711617000193, -36.942516682000019],
            [126.144158617000102, -36.952469682000014],
            [126.129545617000105, -36.962365682000012],
            [126.114872617000088, -36.972206681999978],
            [126.100138617000113, -36.981991682999919],
            [126.085346617000113, -36.99172068300004],
            [126.070493617000153, -37.001393682999961],
            [126.055582617000169, -37.011008682999972],
            [126.040612617000221, -37.020567682999953],
            [126.025584617000135, -37.030068683000039],
            [126.010498617000081, -37.039513682999925],
            [125.995354617000118, -37.048899682999988],
            [125.980153617000127, -37.058228683000053],
            [125.96489561700011, -37.067499682999923],
            [125.949580617000123, -37.076711682999985],
            [125.934209617000107, -37.085865682999952],
            [125.918781617000178, -37.094961683],
            [125.903299617000101, -37.103997682999974],
            [125.887760617000055, -37.112975682999945],
            [125.872167617000201, -37.121893682999925],
            [125.856519617000203, -37.130751683],
            [125.840817617000226, -37.139550683],
            [125.825061617000159, -37.148289683],
            [125.809252617000226, -37.156968683000017],
            [125.793389617000258, -37.165586682999944],
            [125.777473617000084, -37.17414468299998],
            [125.761505617000154, -37.182642683000026],
            [125.745485617000242, -37.191078683000015],
            [125.729413617000233, -37.199453683],
            [125.713289617000243, -37.207767682999929],
            [125.697114617000153, -37.216019682999956],
            [125.680889617000076, -37.224209683000012],
            [125.664613617000242, -37.232338682999981],
            [125.648287617000136, -37.240404683],
            [125.631912617000097, -37.248408683000022],
            [125.615487617000184, -37.25635068299998],
            [125.599014617000108, -37.264229682999968],
            [125.582491617000159, -37.272045682999988],
            [125.565921617000157, -37.279798683000024],
            [125.549303617000163, -37.287488682999921],
            [125.532638617000174, -37.295115682999935],
            [125.515925617000249, -37.302677682999985],
            [125.499166617000043, -37.310176682999966],
            [125.482361617000123, -37.317612683],
            [125.465510617000206, -37.324983682999957],
            [125.448613617000234, -37.332289682999956],
            [125.43167261700026, -37.339531683],
            [125.414685617000117, -37.346709682999986],
            [125.397654617000143, -37.353822683000018],
            [125.380580617000163, -37.36086968299999],
            [125.363461617000127, -37.36785268300001],
            [125.346300617000139, -37.374769682999982],
            [125.32909661700026, -37.381621683],
            [125.312190617000141, -37.388285683],
            [125.295243617000182, -37.394885683000027],
            [125.278256617000153, -37.401421683],
            [125.261230617000052, -37.407895682999921],
            [125.244164617000109, -37.414304682999969],
            [125.227060617000149, -37.420650683000034],
            [125.209916617000232, -37.426931682999964],
            [125.192735617000125, -37.433149683000011],
            [125.175516617000227, -37.439302683],
            [125.158260617000138, -37.44539168300004],
            [125.140967617000257, -37.451415682999937],
            [125.123637617000242, -37.457375682999952],
            [125.106270617000092, -37.463270683],
            [125.088868616000155, -37.46909968300001],
            [125.071431616000069, -37.474864682999979],
            [125.053634616000153, -37.480671683000025],
            [125.035801616000157, -37.486410682999974],
            [125.017932616000138, -37.492081682999967],
            [125.000028616000151, -37.497684682999932],
            [124.982090616000193, -37.503218682999957],
            [124.964117616000152, -37.508685682999953],
            [124.946110616000198, -37.514082683000012],
            [124.928070616000042, -37.519412683000041],
            [124.909997616000197, -37.524672682999963],
            [124.891891616000095, -37.529864683000028],
            [124.873753616000187, -37.534986682999985],
            [124.855583616000132, -37.540040683],
            [124.837382616000156, -37.545024683],
            [124.819150616000201, -37.549939682999963],
            [124.800887616000097, -37.554785683],
            [124.782594616000239, -37.559560683000029],
            [124.764272616000227, -37.564267683000011],
            [124.745921616000118, -37.568903683],
            [124.727540616000084, -37.573469683000042],
            [124.709132616000062, -37.577966682999985],
            [124.690695616000113, -37.582392683],
            [124.672231616000232, -37.586748683],
            [124.653740616000078, -37.591034682999961],
            [124.635222616000163, -37.59524968300002],
            [124.616679616000141, -37.599394682999957],
            [124.59810961600013, -37.603468682999988],
            [124.579514616000239, -37.607471682999986],
            [124.560894616000184, -37.611404682999975],
            [124.542250616000132, -37.615265682999961],
            [124.523582616000255, -37.619056682999926],
            [124.504891616000094, -37.622775682999972],
            [124.486176616000222, -37.626423683000013],
            [124.467438616000237, -37.630000682999935],
            [124.448679616000135, -37.63350668299995],
            [124.429898616000145, -37.636940683000034],
            [124.411095616000097, -37.640302682999945],
            [124.392271616000102, -37.64359368300002],
            [124.373427616000214, -37.646812683],
            [124.354563616000206, -37.649960682999961],
            [124.335680616000133, -37.653035683000013],
            [124.316777616000167, -37.65603968299996],
            [124.297856616000246, -37.658970683],
            [124.278917616000143, -37.661830682999934],
            [124.259960616000143, -37.66461768299996],
            [124.240986616000185, -37.667332682999984],
            [124.221994616000217, -37.669975683],
            [124.202987616000115, -37.672546683],
            [124.18396361600017, -37.67504468300001],
            [124.16492461600015, -37.67747068300001],
            [124.145871616000107, -37.679823682999924],
            [124.126802616000106, -37.682103683],
            [124.107719616000082, -37.684311683],
            [124.088623616000149, -37.686447682999983],
            [124.069514616000191, -37.688509682999978],
            [124.050392616000153, -37.690499683000056],
            [124.03125761600009, -37.692416683000033],
            [124.01211161500018, -37.694260683000024],
            [123.992953615000175, -37.696031683],
            [123.973785615000139, -37.697729683],
            [123.954606615000245, -37.699354682999967],
            [123.935417615000091, -37.70090768300004],
            [123.916218615000133, -37.702386682999929],
            [123.897011615000253, -37.703792683],
            [123.87779461500017, -37.705124683],
            [123.858570615000218, -37.706384682999968],
            [123.839338615000116, -37.707570682999958],
            [123.82009961500026, -37.708683682999947],
            [123.800853615000136, -37.709723682999922],
            [123.781600615000258, -37.710690683],
            [123.762342615000165, -37.711583682999986],
            [123.743079615000084, -37.712403682999977],
            [123.723810615000133, -37.713150682999967],
            [123.704537615000191, -37.713823682999973],
            [123.685260615000203, -37.714423682999964],
            [123.665980615000223, -37.714949682999958],
            [123.646696615000195, -37.715402682999965],
            [123.627410615000059, -37.715782682999958],
            [123.608236615000152, -37.716103682999972],
            [123.589061615000247, -37.716351682999971],
            [123.569885615000175, -37.716527682999967],
            [123.550707615000107, -37.71663168299996],
            [123.531530615000207, -37.716661683000041],
            [123.51235261500014, -37.716620683000016],
            [123.493175615000183, -37.716505683],
            [123.473998615000113, -37.716318683],
            [123.454824615000092, -37.716059682999969],
            [123.435651615000182, -37.71572768299994],
            [123.416480615000154, -37.715322683000011],
            [123.397312615000118, -37.714845682999979],
            [123.378147615000245, -37.714295682999946],
            [123.358986615000191, -37.713673683],
            [123.339829615000241, -37.712978682999946],
            [123.320677615000108, -37.712210683],
            [123.301529615000135, -37.711371683000039],
            [123.282387615000204, -37.710458682999985],
            [123.263251615000144, -37.709474682999925],
            [123.244122615000066, -37.708417682999965],
            [123.2249996150002, -37.70728768299999],
            [123.205884615000201, -37.706085683000012],
            [123.186776615000241, -37.704811682999932],
            [123.167676615000147, -37.70346468299995],
            [123.148585615000258, -37.702046682999949],
            [123.129504615000229, -37.700555682999962],
            [123.110431615000067, -37.69899168299996],
            [123.091369615000161, -37.697356682999953],
            [123.07231761500023, -37.695648682999931],
            [123.053607615000175, -37.699734682999974],
            [123.034872615000182, -37.703748683],
            [123.016111615000142, -37.707690682999939],
            [122.997326615000162, -37.711561682999942],
            [122.978516615000188, -37.71535968299996],
            [122.959683615000159, -37.719086682999958],
            [122.940826614000258, -37.722741683000038],
            [122.921946614000177, -37.726323683000032],
            [122.903044614000038, -37.72983368300001],
            [122.884121614000179, -37.733271682999984],
            [122.865175614000151, -37.736636682999972],
            [122.846209614000117, -37.739929683000028],
            [122.827222614000135, -37.743149683],
            [122.808215614000204, -37.746296682999969],
            [122.789188614000096, -37.749371682999936],
            [122.770142614000207, -37.752373682999988],
            [122.751077614000252, -37.755302683000039],
            [122.731994614000229, -37.758158683],
            [122.712893614000251, -37.760940682999959],
            [122.693775614000089, -37.763650683],
            [122.674639614000199, -37.766287682999973],
            [122.655488614000234, -37.768850683000025],
            [122.636320614000198, -37.771340683],
            [122.617137614000086, -37.773757682999957],
            [122.597939614000239, -37.776100683000024],
            [122.578726614000203, -37.778370683],
            [122.559499614000089, -37.780567682999958],
            [122.540258614000123, -37.782689682999944],
            [122.521004614000077, -37.784738682999929],
            [122.501738614000118, -37.786714683],
            [122.482459614000135, -37.788615682999989],
            [122.463756614000118, -37.790392682999936],
            [122.445042614000073, -37.792099682999961],
            [122.426318614000166, -37.793737682999947],
            [122.407584614000172, -37.795306683],
            [122.388840614000202, -37.79680568299996],
            [122.370088614000139, -37.798234682999976],
            [122.351327614000212, -37.799594682999967],
            [122.332558614000135, -37.80088468299995],
            [122.313781614000135, -37.802105682999979],
            [122.294997614000152, -37.803256683],
            [122.276206614000131, -37.804337683000014],
            [122.257409614000068, -37.805348683],
            [122.238606614000133, -37.806290682999958],
            [122.219797614000214, -37.807162682999987],
            [122.20098361400008, -37.807964683],
            [122.182164614000129, -37.808697682999977],
            [122.163341614000132, -37.809359682999954],
            [122.144514614000144, -37.809952683],
            [122.125684614000107, -37.810475682999922],
            [122.106850614000251, -37.810928682999915],
            [122.088014614000173, -37.811311682999985],
            [122.069176614000156, -37.811624682999948],
            [122.050337614000256, -37.811867682999988],
            [122.03149661400019, -37.81204168299999],
            [122.012654614000127, -37.812145682999983],
            [121.993811614000066, -37.812178682999956],
            [121.974397614000196, -37.812143683],
            [121.954983614000156, -37.812033683000038],
            [121.93557061400017, -37.81184968299992],
            [121.916159614000179, -37.811591682999975],
            [121.896749614000129, -37.811259682999946],
            [121.877342614000128, -37.810853682999934],
            [121.857937613000189, -37.810372683000011],
            [121.83853661300023, -37.809817683],
            [121.81913961300009, -37.809188683000016],
            [121.799745613000169, -37.808485683000029],
            [121.780357613000234, -37.807708682999944],
            [121.760973613000175, -37.806857682999976],
            [121.741596613000155, -37.805932683],
            [121.722224613000122, -37.804932682999947],
            [121.702859613000129, -37.803859682999985],
            [121.683501613000175, -37.802711683000027],
            [121.664150613000203, -37.801490683],
            [121.644807613000154, -37.800195682999956],
            [121.625473613000139, -37.798825683000018],
            [121.606148613000101, -37.797382683],
            [121.586832613000155, -37.795865682999988],
            [121.567526613000069, -37.794274682999969],
            [121.548230613000186, -37.792610682999964],
            [121.528945613000161, -37.790871683000049],
            [121.509671613000052, -37.789059682999962],
            [121.490409613000139, -37.787174682999961],
            [121.471159613000083, -37.785214682999964],
            [121.451922613000107, -37.783182682999964],
            [121.432698613000156, -37.781075682999983],
            [121.413487613000115, -37.778896683],
            [121.394291613000149, -37.77664268300002],
            [121.375109613000205, -37.774316682999938],
            [121.355941613000169, -37.771916682999958],
            [121.336790613000204, -37.769443682999977],
            [121.317654613000144, -37.766897683],
            [121.298534613000157, -37.76427868299993],
            [121.279431613000185, -37.76158568299995],
            [121.260346613000166, -37.758820682999968],
            [121.241278613000162, -37.755982682999985],
            [121.222228613000112, -37.753070683],
            [121.203197613000128, -37.750087682999933],
            [121.184186613000207, -37.747030682999949],
            [121.165193613000184, -37.743901682999962],
            [121.146221613000108, -37.74069968299996],
            [121.128637613000109, -37.737667683000012],
            [121.111072613000118, -37.734573682999979],
            [121.093525613000253, -37.731416682999978],
            [121.075997613000112, -37.728198683],
            [121.058488613000037, -37.724917682999958],
            [121.040998613000198, -37.721574683000028],
            [121.023528613000138, -37.718169683000028],
            [121.006077613000144, -37.714702683000041],
            [120.99119461300009, -37.711698683],
            [120.976325613000228, -37.708649682999976],
            [120.957805613000147, -37.704797683000024],
            [120.93930961300012, -37.700876682999962],
            [120.920837613000202, -37.696885682999962],
            [120.902391613000219, -37.692824682999955],
            [120.883970613000173, -37.688693682999926],
            [120.865575613000061, -37.684493682999957],
            [120.847206613000054, -37.68022368299998],
            [120.831475613000094, -37.689158682999945],
            [120.815689613000103, -37.698033683],
            [120.799848613000194, -37.70684868299999],
            [120.783952612000149, -37.715603682999983],
            [120.768002612000117, -37.724298682999986],
            [120.751998612000222, -37.732932683],
            [120.735940612000178, -37.74150668299994],
            [120.719830612000038, -37.750018683],
            [120.703666612000148, -37.758469682999973],
            [120.687450612000106, -37.766859682999964],
            [120.671182612000194, -37.77518768299997],
            [120.654862612000187, -37.783454683],
            [120.638491612000195, -37.791659682999963],
            [120.622069612000161, -37.799801682999956],
            [120.605596612000198, -37.807881682999962],
            [120.589073612000249, -37.815899683],
            [120.572501612000138, -37.823854682999965],
            [120.5558786120001, -37.831746682999963],
            [120.53920761200007, -37.839575683],
            [120.522487612000162, -37.847340682999956],
            [120.505718612000038, -37.855043682999948],
            [120.488902612000089, -37.862681683],
            [120.472038612000091, -37.870256683000051],
            [120.4551266120001, -37.877767682999959],
            [120.438168612000055, -37.885213683],
            [120.421164612000183, -37.892595683],
            [120.404113612000145, -37.899913683000023],
            [120.387017612000051, -37.907166682999986],
            [120.369875612000243, -37.914354683],
            [120.352689612000148, -37.921477682999964],
            [120.335458612000053, -37.928535682999978],
            [120.31818361200024, -37.935527683000018],
            [120.300865612000138, -37.942454682999937],
            [120.283503612000146, -37.94931568299998],
            [120.26609861200015, -37.956110683],
            [120.248651612000145, -37.962839683000041],
            [120.23116261200019, -37.969502683000044],
            [120.213631612000171, -37.976099683],
            [120.196058612000201, -37.982629683000027],
            [120.178445612000047, -37.989092683],
            [120.160792612000108, -37.995489683000031],
            [120.143098612000102, -38.001818683000025],
            [120.129052612000095, -38.00678668300003],
            [120.114981612000093, -38.01171168299993],
            [120.10088661200021, -38.01659568299992],
            [120.086767612000045, -38.021435683],
            [120.070223612000149, -38.029766683],
            [120.053628612000097, -38.038034683],
            [120.036980612000121, -38.04623868299997],
            [120.020281612000161, -38.054379683999969],
            [120.003530612000105, -38.062456684],
            [119.986728612000178, -38.070468683999977],
            [119.969876612000093, -38.078417683999987],
            [119.952974612000133, -38.086301684000034],
            [119.93602261200013, -38.094121684000029],
            [119.919020612000082, -38.101876683999961],
            [119.901970612000042, -38.109566683999944],
            [119.884871612000239, -38.117191683999977],
            [119.86772461200016, -38.124751683999946],
            [119.850529612000145, -38.132245683999969],
            [119.833287612000191, -38.139673683999945],
            [119.815997612000132, -38.147036683999957],
            [119.798662612000129, -38.154332683999939],
            [119.781280612000074, -38.161562683999961],
            [119.763852612000136, -38.168726684000035],
            [119.746379612000197, -38.17582368399998],
            [119.728861612000145, -38.182854683999963],
            [119.711298611000217, -38.189818683999917],
            [119.693692611000102, -38.196714684000014],
            [119.676042611000099, -38.203543684],
            [119.65834861100015, -38.210305683999934],
            [119.640612611000194, -38.216999684000015],
            [119.622833611000175, -38.223626683999981],
            [119.605012611000035, -38.230185684],
            [119.587149611000228, -38.236675684],
            [119.569246611000068, -38.243097683999949],
            [119.551301611000127, -38.249451683999965],
            [119.533317611000172, -38.255737683999953],
            [119.515292611000206, -38.26195368399992],
            [119.497228611000224, -38.268101683999959],
            [119.479125611000114, -38.274180683999958],
            [119.460983611000159, -38.280190683999933],
            [119.44280361100013, -38.286130683999986],
            [119.424586611000194, -38.292001684000013],
            [119.406479611000094, -38.297757684000018],
            [119.388336611000142, -38.303444684000013],
            [119.370157611000224, -38.309062683999969],
            [119.351942611000169, -38.314612684],
            [119.333693611000143, -38.320092683999988],
            [119.315409611000149, -38.325503683999969],
            [119.297091611000184, -38.330845683999925],
            [119.278739611000248, -38.336118684000027],
            [119.260354611000167, -38.341321684000022],
            [119.241936611000114, -38.346454684],
            [119.223486611000141, -38.351518684000055],
            [119.205004611000192, -38.356512683999981],
            [119.186490611000096, -38.361436684],
            [119.167945611000192, -38.36628968399998],
            [119.149369611000196, -38.371073683999953],
            [119.130764611000103, -38.375786684],
            [119.112128611000145, -38.380428684000037],
            [119.093463611000146, -38.385000684000047],
            [119.074769611000164, -38.389502684000036],
            [119.056047611000082, -38.393932683999935],
            [119.037297611000241, -38.398292684],
            [119.018519611000244, -38.402580683999958],
            [118.999715611000141, -38.406798684],
            [118.98088361100011, -38.410944684000029],
            [118.962026611000198, -38.415019683999944],
            [118.943142611000241, -38.419022683999955],
            [118.924233611000119, -38.422954683999947],
            [118.905300611000172, -38.426814684000036],
            [118.886342611000117, -38.430603684],
            [118.86736061100018, -38.434320683999971],
            [118.84835561100013, -38.437965683999934],
            [118.829326611000141, -38.44153868399998],
            [118.810276611000091, -38.44503868399994],
            [118.791202611000102, -38.448467683999979],
            [118.772108611000164, -38.451824684],
            [118.752992611000167, -38.455108684000024],
            [118.733855611000109, -38.45831968399996],
            [118.714698611000102, -38.461459683999976],
            [118.6955226110002, -38.464525684],
            [118.676325611000067, -38.467520684],
            [118.658047611000057, -38.470301683999978],
            [118.639753610000213, -38.473016683999987],
            [118.621442610000116, -38.475666683999961],
            [118.603115610000117, -38.478249684],
            [118.584773610000212, -38.48076668399996],
            [118.566415610000064, -38.483218683999979],
            [118.548043610000065, -38.485603683999969],
            [118.529657610000157, -38.487922684],
            [118.511256610000174, -38.490175683999979],
            [118.492842610000167, -38.492362684000014],
            [118.474415610000079, -38.494482683999919],
            [118.455976610000135, -38.496536684000048],
            [118.437524610000111, -38.498523683999963],
            [118.41906061000023, -38.500444684000016],
            [118.400584610000152, -38.502299684000022],
            [118.381095610000131, -38.504271683999931],
            [118.361593610000256, -38.506169683999943],
            [118.342079610000184, -38.507994684000039],
            [118.322553610000199, -38.509744683999983],
            [118.303017610000182, -38.511420684],
            [118.283470610000137, -38.513022683999949],
            [118.26391361000006, -38.514549684],
            [118.244347610000233, -38.516002683999957],
            [118.22477161000009, -38.517381683999929],
            [118.205187610000195, -38.51868568399999],
            [118.185594610000152, -38.519915683999983],
            [118.16599461000024, -38.521071683999978],
            [118.146387610000119, -38.522152683999977],
            [118.126773610000242, -38.523159683999978],
            [118.107153610000097, -38.524091684],
            [118.087527610000194, -38.524948684000037],
            [118.067896610000133, -38.525731683999979],
            [118.048260610000085, -38.526439684000039],
            [118.028619610000163, -38.527073684],
            [118.008975610000192, -38.527632683999983],
            [117.989328610000115, -38.528117683999966],
            [117.969677610000105, -38.528527684000039],
            [117.950024610000156, -38.528862683999961],
            [117.930370610000153, -38.529122683999987],
            [117.910714610000156, -38.529308684],
            [117.891057610000161, -38.529419683999947],
            [117.871399610000168, -38.529456684],
            [117.852063610000101, -38.529421683999942],
            [117.83272861000026, -38.529314683999971],
            [117.813394610000188, -38.529135684],
            [117.794060610000173, -38.528883683999922],
            [117.774729610000151, -38.52855968400003],
            [117.755400610000123, -38.528163683999949],
            [117.736074610000145, -38.527694684000039],
            [117.716751610000159, -38.527154684000038],
            [117.697432610000163, -38.526541683999952],
            [117.678116610000217, -38.525856684000019],
            [117.658806610000084, -38.525099684000011],
            [117.639500610000169, -38.524270683999987],
            [117.620200610000126, -38.523369683999945],
            [117.600906610000067, -38.522395684],
            [117.58161861000022, -38.521349683999972],
            [117.562337609000252, -38.520232684],
            [117.543063609000143, -38.519042683999956],
            [117.523797609000184, -38.517780683999987],
            [117.504539609000147, -38.516447684000013],
            [117.485290609000259, -38.515041684000025],
            [117.466050609000121, -38.513564683999945],
            [117.44681960900013, -38.512014683999951],
            [117.427599609000055, -38.510393683999936],
            [117.408389609000182, -38.508700684000019],
            [117.389190609000053, -38.506935684],
            [117.370002609000068, -38.505099683999958],
            [117.350826609000165, -38.503191684000015],
            [117.331662609000176, -38.501211683999969],
            [117.312512609000038, -38.499160684],
            [117.293374609000097, -38.49703768400002],
            [117.274250609000234, -38.494842683999948],
            [117.255140609000108, -38.492576683999957],
            [117.236044609000174, -38.490239683999945],
            [117.2169646090002, -38.487831684000014],
            [117.197899609000189, -38.485351684],
            [117.178850609000136, -38.48280068400004],
            [117.159817609000157, -38.480178683999981],
            [117.140801609000079, -38.477484683999933],
            [117.121802609000184, -38.474720684000033],
            [117.102821609000245, -38.471885684000029],
            [117.083857609000034, -38.468979684000018],
            [117.064913609000229, -38.466002683999989],
            [117.04598760900015, -38.462954683999953],
            [117.027081609000135, -38.459836683999981],
            [117.008725609000209, -38.456738683999973],
            [116.990387609000123, -38.453574683999932],
            [116.972070609000156, -38.450343684000032],
            [116.95377260900014, -38.447046684],
            [116.934867609000122, -38.443566684],
            [116.915985609000103, -38.440015684],
            [116.897125609000256, -38.436393684],
            [116.878289609000063, -38.432701683999952],
            [116.859476609000097, -38.428938684],
            [116.840687609000071, -38.425104684000033],
            [116.821923609000152, -38.421199683999959],
            [116.803183609000172, -38.417224683999962],
            [116.784469609000126, -38.41318068399994],
            [116.765498609000048, -38.413388684],
            [116.74652760900014, -38.413527683999959],
            [116.727555609000177, -38.413596683999984],
            [116.708582609000104, -38.413596683999984],
            [116.689610609000198, -38.413525683999964],
            [116.670638609000122, -38.413385683999934],
            [116.651668609000041, -38.413175683999967],
            [116.632699609000127, -38.412895683999977],
            [116.613732609000209, -38.41254568399998],
            [116.594767609000172, -38.412126683999944],
            [116.575806609000125, -38.411637683999984],
            [116.556847609000243, -38.411078684],
            [116.537892609000238, -38.410449684000014],
            [116.518941609000223, -38.409751683999986],
            [116.499995608000091, -38.408983683999949],
            [116.481054608000164, -38.408146683999973],
            [116.462118608000225, -38.407238684],
            [116.443188608000099, -38.406262683999969],
            [116.424264608000072, -38.405215684000026],
            [116.40487560800014, -38.404072683999956],
            [116.385492608000078, -38.402855683999974],
            [116.366118608000221, -38.401565684],
            [116.34675260800023, -38.400203684000019],
            [116.327395608000216, -38.398767683999949],
            [116.30804760800018, -38.397259683999977],
            [116.288709608000119, -38.395677684],
            [116.269381608000259, -38.394023683999919],
            [116.250064608000258, -38.392295684000032],
            [116.230759608000227, -38.390495683999958],
            [116.211465608000168, -38.388623683999967],
            [116.192183608000192, -38.386677683999977],
            [116.172913608000073, -38.384659683999985],
            [116.153657608000145, -38.382568684],
            [116.134414608000185, -38.380405684],
            [116.115042608000152, -38.378154684],
            [116.095684608000198, -38.37582968400001],
            [116.07634260800026, -38.373430684000013],
            [116.057015608000114, -38.370958683999945],
            [116.037704608000155, -38.368413683999961],
            [116.018409608000098, -38.365795683999963],
            [115.999131608000113, -38.363103683999981],
            [115.979871608000195, -38.360338684],
            [115.960629608000232, -38.35750068399993],
            [115.94140560800011, -38.354589683999947],
            [115.922200608000111, -38.351605683999964],
            [115.903014608000177, -38.348548683999979],
            [115.883848608000136, -38.345418684],
            [115.864703608000042, -38.342216684],
            [115.845373608000187, -38.340843684],
            [115.826052608000197, -38.339397684],
            [115.806741608000067, -38.337878683999989],
            [115.787440608000196, -38.336286683999987],
            [115.76814960800013, -38.334622683999967],
            [115.748868608000151, -38.332885683999962],
            [115.7295996080002, -38.331075684000041],
            [115.710342608000161, -38.329193684000018],
            [115.691097608000149, -38.327238684],
            [115.671864608000163, -38.325211683999967],
            [115.652644608000088, -38.323112684000023],
            [115.633438608000091, -38.320939684],
            [115.614246608000116, -38.318695684000048],
            [115.595069608000045, -38.316378684],
            [115.575906608000167, -38.313989684000049],
            [115.556759608000192, -38.311528684],
            [115.537627608000179, -38.308995684000024],
            [115.518512608000179, -38.30639068399995],
            [115.49941460800008, -38.303712683999976],
            [115.480332608000055, -38.300963683999981],
            [115.461269608000094, -38.298142683999984],
            [115.442223608000205, -38.295249683999984],
            [115.423196607000108, -38.292284683999981],
            [115.404188607000179, -38.289248684000043],
            [115.385199607000203, -38.286140684000017],
            [115.366231607000174, -38.282960683999974],
            [115.347282607000153, -38.279709684000011],
            [115.32835460700008, -38.276387684000042],
            [115.309448607000064, -38.272994683999968],
            [115.290563607000166, -38.269529683999977],
            [115.27170160700021, -38.26599368399998],
            [115.252861607000142, -38.262386684000049],
            [115.234044607000186, -38.258708683999927],
            [115.215250607000115, -38.254959683999971],
            [115.196481607000038, -38.251140684],
            [115.177735607000187, -38.247250683999937],
            [115.1590156070001, -38.243289683999933],
            [115.14032060700012, -38.239258683999921],
            [115.121650607000248, -38.235156683999975],
            [115.10300660700014, -38.23098468400002],
            [115.084390607000131, -38.226742683999959],
            [115.065800607000057, -38.22243068399996],
            [115.047237607000142, -38.218047683999956],
            [115.028702607000099, -38.213595684000012],
            [115.010196607000097, -38.209073683999961],
            [114.991719607000135, -38.20448268399997],
            [114.97327060700016, -38.199820683999974],
            [114.954851607000109, -38.195090684000036],
            [114.936462607000152, -38.190290683999976],
            [114.91810460700006, -38.185421684],
            [114.899777607000175, -38.180482684],
            [114.881480607000157, -38.175475683999963],
            [114.863216607000055, -38.170399683999989],
            [114.8449846070001, -38.16525468399999],
            [114.826784607000121, -38.160040683999966],
            [114.80861760700023, -38.154758684000015],
            [114.790484607000195, -38.149407684000025],
            [114.772385607000075, -38.143989684],
            [114.754201607000169, -38.138467684],
            [114.736052607000175, -38.132876683999953],
            [114.717939607000204, -38.127216683999976],
            [114.699862607000199, -38.121488683999971],
            [114.68182160700016, -38.115691683999941],
            [114.663817607000141, -38.10982668399997],
            [114.645850607000199, -38.103893683999971],
            [114.627921607000161, -38.097891683999933],
            [114.610030607000198, -38.091822683999965],
            [114.592178607000136, -38.085685683999955],
            [114.574365607000146, -38.079480684],
            [114.556591607000229, -38.073208683999937],
            [114.53885760700021, -38.066869684],
            [114.521163607000091, -38.060462683999944],
            [114.503509607000098, -38.053988683999947],
            [114.485897607000169, -38.047448683],
            [114.468326607000137, -38.040841683],
            [114.450797607000169, -38.034167682999978],
            [114.433310607000209, -38.027427682999985],
            [114.415866607000254, -38.020620682999976],
            [114.398465607000077, -38.013748682999989],
            [114.381107607000132, -38.006809682999972],
            [114.36379360700019, -37.999805683],
            [114.346524606000258, -37.992735682999978],
            [114.329299606000205, -37.985600683],
            [114.312119606000095, -37.978400682999961],
            [114.294985606000211, -37.971134682999988],
            [114.277896606000155, -37.963804683000035],
            [114.260854606000095, -37.956409682999947],
            [114.243858606000259, -37.948949682999981],
            [114.226910606000132, -37.94142668299996],
            [114.21000960600017, -37.933838683],
            [114.193155606000033, -37.926185682999957],
            [114.176350606000113, -37.918470682999953],
            [114.159593606000072, -37.910690682999984],
            [114.142886606000189, -37.902847682999948],
            [114.126227606000185, -37.894941682999942],
            [114.109619606000109, -37.886972682999968],
            [114.093060606000137, -37.878939682999942],
            [114.076552606000092, -37.870845683000013],
            [114.060094606000092, -37.862687682999947],
            [114.043688606000075, -37.854468682999979],
            [114.027334606000153, -37.846186683000042],
            [114.011031606000216, -37.837842682999948],
            [113.994781606000203, -37.829437682999966],
            [113.978583606000058, -37.820970683],
            [113.962439606000117, -37.812442682999958],
            [113.946348606000043, -37.803852682999931],
            [113.930310606000063, -37.795202683000014],
            [113.914327606000114, -37.786491683000023],
            [113.8983986060002, -37.777720683000041],
            [113.882524606000146, -37.768888682999972],
            [113.866706606000179, -37.759996682999926],
            [113.850943606000243, -37.751044682999975],
            [113.835235606000225, -37.742033682999946],
            [113.81958460600012, -37.732962683],
            [113.803990606000212, -37.723832682999983],
            [113.78845260600022, -37.714643682999963],
            [113.772972606000081, -37.705396682999933],
            [113.757550606000194, -37.696089683000011],
            [113.742185606000049, -37.686724683],
            [113.726879606000153, -37.677302682999965],
            [113.711631606000111, -37.667821682999943],
            [113.696443606000201, -37.65828268300001],
            [113.681313606000145, -37.64868668299998],
            [113.666244606000163, -37.639033683000022],
            [113.651234606000259, -37.629323682999967],
            [113.636285606000257, -37.619556683],
            [113.621396606000218, -37.609732683000018],
            [113.606568606000195, -37.599852682999938],
            [113.591801606000246, -37.589916683000013],
            [113.577096606000083, -37.579925682999985],
            [113.562453606000162, -37.56987768300003],
            [113.547873606000138, -37.559774682999972],
            [113.533354606000188, -37.549616682999968],
            [113.51889960600019, -37.539403682999961],
            [113.504507606000089, -37.529136682999919],
            [113.490178606000114, -37.518813682999948],
            [113.475913606000205, -37.508437682999954],
            [113.461712606000191, -37.498007683000012],
            [113.447576606000126, -37.487523682999949],
            [113.433504606000128, -37.47698668299995],
            [113.419497606000078, -37.466395683000016],
            [113.405556606000147, -37.455752682999957],
            [113.391680606000108, -37.44505568299995],
            [113.377870606000187, -37.434306682999988],
            [113.364127606000153, -37.423505683],
            [113.350449606000126, -37.412652682999962],
            [113.333225606000184, -37.405599682999934],
            [113.316046606000242, -37.398480682999946],
            [113.298911606000189, -37.391295683],
            [113.281821606000136, -37.384044683000013],
            [113.264776605000151, -37.376728682999982],
            [113.24777860500015, -37.369347682999987],
            [113.230826605000146, -37.361900682999945],
            [113.213920605000141, -37.354389683000036],
            [113.197062605000184, -37.346813682999979],
            [113.180251605000223, -37.339172682999958],
            [113.16348860500014, -37.331468682999983],
            [113.146773605000106, -37.323699683000029],
            [113.130106605000066, -37.315866683000024],
            [113.113489605000069, -37.307969682999968],
            [113.096921605000119, -37.300008682999945],
            [113.080403605000157, -37.291984682999953],
            [113.063935605000239, -37.283897683],
            [113.047517605000081, -37.275747682999963],
            [113.031150605000249, -37.267534682999951],
            [113.014835605000115, -37.259259682999982],
            [112.998571605000194, -37.25092168299993],
            [112.982359605000141, -37.242521683],
            [112.966199605000185, -37.234058683000015],
            [112.950093605000035, -37.225534683000035],
            [112.934039605000152, -37.216949682999982],
            [112.918038605000248, -37.208301682999959],
            [112.902092605000149, -37.199593682999947],
            [112.886200605000198, -37.19082468299996],
            [112.870362605000167, -37.181993682999973],
            [112.854579605000168, -37.173103683],
            [112.838851605000201, -37.164151682999972],
            [112.823179605000149, -37.155140683000013],
            [112.807563605000183, -37.146069683],
            [112.792003605000247, -37.136938682999968],
            [112.776500605000052, -37.127747682999967],
            [112.761054605000055, -37.118497682999958],
            [112.745665605000141, -37.109188682999957],
            [112.730333605000141, -37.099820682999962],
            [112.715060605000161, -37.090394682999957],
            [112.699845605000149, -37.080909682999959],
            [112.684689605000159, -37.071365682999968],
            [112.66959260500019, -37.061764682999964],
            [112.654554605000186, -37.05210568300005],
            [112.639576605000258, -37.042389682999939],
            [112.624658605000064, -37.032615683000017],
            [112.609800605000117, -37.022785682999981],
            [112.595003605000244, -37.012897682999949],
            [112.580267605000103, -37.002953682999987],
            [112.565592605000035, -36.992952683000013],
            [112.550979605000208, -36.982896683000021],
            [112.536428605000168, -36.972783681999928],
            [112.521939605000199, -36.962615682],
            [112.50751260500013, -36.952392682000038],
            [112.493149605000127, -36.942113681999984],
            [112.478848605000081, -36.931779682],
            [112.464612605000099, -36.921391681999978],
            [112.450439605000071, -36.910948682000026],
            [112.436330605000109, -36.900451681999968],
            [112.42228660500021, -36.889900681999961],
            [112.408306605000149, -36.879296682000017],
            [112.394392605000149, -36.868638682000039],
            [112.380542605000215, -36.857926682000041],
            [112.366571605000132, -36.847858682000037],
            [112.352659605000127, -36.83773868199998],
            [112.338809605000193, -36.827565682],
            [112.325019605000165, -36.817341681999963],
            [112.31129060500021, -36.807065681999973],
            [112.297623605000041, -36.79673868199994],
            [112.284018605000114, -36.786360681999952],
            [112.270475605000257, -36.775931682],
            [112.256994605000131, -36.76545168199992],
            [112.243575605000075, -36.754921681999974],
            [112.230220605000142, -36.74434168199997],
            [112.216928605000106, -36.733711682],
            [112.203699604000036, -36.723031681999984],
            [112.190534604000192, -36.712302682],
            [112.177432604000131, -36.701524681999956],
            [112.164395604000248, -36.690696681999938],
            [112.1514236040002, -36.679820681999956],
            [112.138515604000219, -36.66889668200001],
            [112.12567260400013, -36.657923681999989],
            [112.112894604000161, -36.646902682],
            [112.100182604000139, -36.635834681999938],
            [112.087536604000178, -36.624718682],
            [112.074955604000166, -36.613555681999983],
            [112.062441604000099, -36.602345682],
            [112.04999360400015, -36.59108868200002],
            [112.037612604000088, -36.579784681999968],
            [112.024907604000219, -36.568320682],
            [112.012271604000176, -36.556807681999985],
            [111.999704604000073, -36.545246682],
            [111.987290604000037, -36.53372768200002],
            [111.974945604000169, -36.522161681999961],
            [111.962668604000015, -36.510548682000021],
            [111.950462604000023, -36.498889682],
            [111.938325604000084, -36.487183682],
            [111.926258604000139, -36.475432682],
            [111.914261604000131, -36.463635682000032],
            [111.902335604000228, -36.451792681999976],
            [111.890479604000149, -36.439905682],
            [111.878695604000171, -36.427973681999958],
            [111.866981604000131, -36.415996682],
            [111.855339604000193, -36.403975681999967],
            [111.843769604000187, -36.391909681999934],
            [111.832271604000226, -36.379801681999979],
            [111.820844604000143, -36.367648682000024],
            [111.809490604000217, -36.355453681999975],
            [111.79820960400005, -36.343214681999939],
            [111.787000604000042, -36.330933681999966],
            [111.775864604000134, -36.318610681999985],
            [111.764802604000039, -36.306244682],
            [111.753813604000101, -36.293837681999989],
            [111.742897604000206, -36.281388681999985],
            [111.73205560400018, -36.268898681999943],
            [111.72128860400008, -36.25636768199999],
            [111.710594604000136, -36.243795682000012],
            [111.699975604000116, -36.231182681999925],
            [111.689431604000134, -36.218530682],
            [111.678961604000193, -36.205838682000035],
            [111.668567604000117, -36.193106681999964],
            [111.658247604000138, -36.180335681999949],
            [111.648003604000138, -36.167525682],
            [111.637835604000117, -36.154676681999931],
            [111.627743604000074, -36.141789682],
            [111.617726604000183, -36.128864682000042],
            [111.60778660400004, -36.115901681999944],
            [111.597922604000104, -36.102900682],
            [111.588134604000089, -36.089862681999932],
            [111.578274604000143, -36.076781681999933],
            [111.568490604000118, -36.063663681999984],
            [111.558785604000121, -36.050508681999915],
            [111.549156604000103, -36.037316681999968],
            [111.539606604000056, -36.024087681999987],
            [111.530134604000153, -36.010822681999969],
            [111.520740604000054, -35.99752168199997],
            [111.511424604000098, -35.984184682000034],
            [111.50218760400017, -35.970813682000028],
            [111.493028604000159, -35.95740668199997],
            [111.483948604000176, -35.943964681999944],
            [111.474948604000161, -35.930488681999961],
            [111.46602660400012, -35.916978682],
            [111.457184604000219, -35.903433680999981],
            [111.448421604000174, -35.889856680999983],
            [111.439738604000098, -35.876245680999929],
            [111.431135604000161, -35.862601680999987],
            [111.422612604000193, -35.848925680999969],
            [111.41416960400008, -35.835216680999963],
            [111.405806604000105, -35.821475681],
            [111.397523604000156, -35.807703681000021],
            [111.389321604000173, -35.793899680999985],
            [111.381200604000156, -35.780064680999956],
            [111.373159604000165, -35.766198680999935],
            [111.365199604000139, -35.752302681000018],
            [111.357321604000077, -35.738375681],
            [111.349523604000211, -35.724419681],
            [111.341807604000024, -35.710433681000012],
            [111.334173604000142, -35.696418681000011],
            [111.326620604000055, -35.682374681],
            [111.319148604000162, -35.668301681],
            [111.311759604000116, -35.654200681],
            [111.304451604000207, -35.640071680999966],
            [111.297226604000144, -35.62591468100004],
            [111.290083604000216, -35.611730681],
            [111.283022604000138, -35.597519680999937],
            [111.276043604000193, -35.58328168099996],
            [111.269147604000096, -35.569017680999963],
            [111.262334604000131, -35.554727681000045],
            [111.255603604000186, -35.540410681000012],
            [111.248955604000145, -35.526069681000038],
            [111.242390604, -35.511702681000045],
            [111.23590860400023, -35.497310680999931],
            [111.229509604000128, -35.482893680999979],
            [111.223193604000159, -35.468453680999986],
            [111.21696160400009, -35.45398868099997],
            [111.210812604000154, -35.439500681],
            [111.204747604000062, -35.424989681],
            [111.198765604000101, -35.410455681000045],
            [111.192867604000156, -35.395898680999963],
            [111.187053604000226, -35.38131868100001],
            [111.181322604000144, -35.366717681000026],
            [111.17567560400019, -35.352094680999983],
            [111.170113604000136, -35.337450681],
            [111.164634604000213, -35.322784681000044],
            [111.159240604000132, -35.308098680999947],
            [111.153929604000183, -35.293392680999972],
            [111.148703604000133, -35.278665680999936],
            [111.143562604000209, -35.263919680999948],
            [111.138504604000133, -35.249153680999981],
            [111.133532604000067, -35.234368681000035],
            [111.1283056030002, -35.219493681000017],
            [111.123164603000106, -35.20459868099995],
            [111.118109603000022, -35.189684681],
            [111.113139603000178, -35.174750681],
            [111.108257603000112, -35.159797680999915],
            [111.103460603000116, -35.14482668100004],
            [111.098749603000073, -35.129836681000015],
            [111.094125603000208, -35.114828681],
            [111.089587603000069, -35.099803681000012],
            [111.085135603000111, -35.084760681000049],
            [111.080770603000104, -35.069701681],
            [111.076492603000162, -35.054624680999964],
            [111.072299603000062, -35.039532681000026],
            [111.068194603000137, -35.024423681000016],
            [111.064175603000223, -35.009299681],
            [111.060242603000148, -34.994159680999914],
            [111.056397603000079, -34.97900568099999],
            [111.052638603000077, -34.963836680999989],
            [111.048966603000196, -34.948652680999984],
            [111.045380603000154, -34.933455680999984],
            [111.041882603, -34.918244680999962],
            [111.038470603000206, -34.903019680999947],
            [111.035145603000188, -34.887782681],
            [111.031907603000064, -34.872532680999953],
            [111.028756603000119, -34.857269681],
            [111.025692603000181, -34.841995681],
            [111.022715603000137, -34.82670968],
            [111.019825603000214, -34.811411679999978],
            [111.017022603000015, -34.796103679999931],
            [111.014306603000165, -34.780784679999954],
            [111.011676603000154, -34.765455679999945],
            [111.009134603000149, -34.75011668],
            [111.006679603000151, -34.734767679999933],
            [111.004311603000161, -34.719408680000015],
            [111.002030603, -34.704041679999975],
            [110.999836603000091, -34.688665679999986],
            [110.997729603000124, -34.67328168],
            [110.995709603000051, -34.657889679999968],
            [110.993777603000154, -34.64248968000004],
            [110.991931603000097, -34.627082679999972],
            [110.990172603000104, -34.611668680000037],
            [110.988500603000062, -34.59624868],
            [110.986915603000199, -34.580821679999929],
            [110.985417603000229, -34.565388680000012],
            [110.984006603000097, -34.549949679999969],
            [110.982682603000086, -34.534506679999936],
            [110.981445603000139, -34.519057679999946],
            [110.980297603000196, -34.503640679999947],
            [110.979234603000151, -34.488218679999974],
            [110.978258603000228, -34.472793679999924],
            [110.977369603000199, -34.457364679999984],
            [110.976565603000125, -34.441932679999979],
            [110.975849603000114, -34.42649668],
            [110.975218603000059, -34.411058679999954],
            [110.974674603000068, -34.39561868],
            [110.974216603000201, -34.380176679999963],
            [110.973844603000231, -34.364733680000029],
            [110.973559603000098, -34.349287680000018],
            [110.973360603000145, -34.33384168],
            [110.973247603000146, -34.31839568],
            [110.971677603000074, -34.30327168],
            [110.970190603000134, -34.28814268],
            [110.968786603000154, -34.273007679999978],
            [110.96746660300019, -34.257866679999978],
            [110.966229603000073, -34.242721679999917],
            [110.965075603000145, -34.227572679999966],
            [110.964004603000177, -34.212418680000056],
            [110.963017603000054, -34.197260679999985],
            [110.962112603000122, -34.182098680000024],
            [110.961291603000149, -34.16693468],
            [110.960553603000079, -34.15176668],
            [110.959899603000082, -34.136596679999982],
            [110.959327603000162, -34.12142368],
            [110.958838603000032, -34.10624868],
            [110.958432603000091, -34.091072679999982],
            [110.958110603000108, -34.075894680000033],
            [110.957870603000146, -34.060715679999916],
            [110.95771360300003, -34.045535679999986],
            [110.957639603000217, -34.030355679999957],
            [110.957647603000083, -34.01517468000003],
            [110.957739603000135, -33.99999468],
            [110.957913603000151, -33.984814679999971],
            [110.958170603000013, -33.969635679999939],
            [110.958509603000181, -33.95445768],
            [110.958931603000082, -33.939280680000039],
            [110.959436603000228, -33.92410668],
            [110.960023603000224, -33.90893368],
            [110.960692603000126, -33.89376268],
            [110.961381603000206, -33.879790679999914],
            [110.962139603000224, -33.86582068],
            [110.96296760300018, -33.851853679999977],
            [110.96386560300013, -33.837889679999932],
            [110.964832603000019, -33.823928679999952],
            [110.965869603000129, -33.809971679999961],
            [110.966976603000063, -33.796017680000034],
            [110.967690603000136, -33.781441679999972],
            [110.968480603000131, -33.76686768],
            [110.969345603000221, -33.752296679],
            [110.970286603, -33.737728678999986],
            [110.971303603000052, -33.723164679000035],
            [110.972396603000135, -33.708604678999976],
            [110.973564603000142, -33.694048678999977],
            [110.974808603000071, -33.679496678999953],
            [110.976128603000035, -33.664949678999989],
            [110.977523603000151, -33.650406679],
            [110.978993603000191, -33.635869678999967],
            [110.98053960300004, -33.621337679],
            [110.981368603000163, -33.606077679000023],
            [110.982280603000135, -33.590820678999933],
            [110.983275603000124, -33.575566679],
            [110.98435360300013, -33.560316679000039],
            [110.985514603000098, -33.545071678999975],
            [110.986757603000086, -33.529830678999957],
            [110.988083603000206, -33.514594679000012],
            [110.989440603000133, -33.499772678999932],
            [110.990874603000208, -33.484954678999927],
            [110.992387603000083, -33.470142678999977],
            [110.993978603000159, -33.455336678999984],
            [110.995646603000154, -33.440535678999964],
            [110.99739360300012, -33.425742679],
            [110.999218603000116, -33.410954678999985],
            [111.001120603000032, -33.396174679000012],
            [111.003100603000149, -33.381400679],
            [111.005157603000185, -33.366634678999944],
            [111.007292603000081, -33.351876678999929],
            [111.009505603000122, -33.337126678999965],
            [111.011794603000084, -33.322385679000021],
            [111.014161603000076, -33.307651678999946],
            [111.016606603000156, -33.292927678999988],
            [111.019016603000154, -33.278832678999947],
            [111.021497603000086, -33.264746679000027],
            [111.024048603000125, -33.250669678999955],
            [111.026670603000156, -33.236601678999918],
            [111.029362603000067, -33.222542679000014],
            [111.032125603000139, -33.208493678999943],
            [111.034958603000149, -33.194454678999918],
            [111.037861603000096, -33.180425679],
            [111.04083460300015, -33.166406679000019],
            [111.043877603000141, -33.152398678999987],
            [111.046991603000123, -33.138401678999955],
            [111.050174603000158, -33.124415679000052],
            [111.052953603000134, -33.112449678999937],
            [111.055784603000149, -33.100491678999958],
            [111.058665603000151, -33.088542678999929],
            [111.061458603000148, -33.073393678999977],
            [111.06433160300017, -33.05825567899997],
            [111.067287603000153, -33.043128678999977],
            [111.070323603000162, -33.028013679],
            [111.073427603000056, -33.012957679000024],
            [111.076612603000143, -32.997913678999964],
            [111.079877603000028, -32.982881678999931],
            [111.083223603000107, -32.967862679],
            [111.086649603000154, -32.952856678999979],
            [111.09015560300017, -32.93786367899996],
            [111.093741603000041, -32.922883678999966],
            [111.097407603000221, -32.907918678999962],
            [111.101152603000202, -32.892966678999983],
            [111.104978603000148, -32.87802967899998],
            [111.108883603000123, -32.863107679],
            [111.112867603000069, -32.848200678999987],
            [111.116931603000154, -32.833308679],
            [111.121074603000096, -32.818432678999983],
            [111.125296603000123, -32.803571678999973],
            [111.129597603, -32.788727679000033],
            [111.133977604000194, -32.773900679],
            [111.138435604000193, -32.759090678999939],
            [111.142972604000164, -32.744296678999973],
            [111.147588604000163, -32.729520678999975],
            [111.152282604000078, -32.714762678999961],
            [111.157054604000024, -32.700022679000014],
            [111.161904604000114, -32.685301678999949],
            [111.16683260400012, -32.670598677999962],
            [111.171838604000158, -32.655914677999945],
            [111.176921604000171, -32.64124967799998],
            [111.182082604000158, -32.626604678],
            [111.187320604000121, -32.611978677999971],
            [111.192636604000228, -32.597373678000011],
            [111.198028604000143, -32.582789678],
            [111.203498604000202, -32.568225677999962],
            [111.20904460400007, -32.553682677999973],
            [111.214667604000084, -32.53916067800003],
            [111.220366604000077, -32.524660677999961],
            [111.22614260400016, -32.510182678000021],
            [111.231994604000221, -32.49572667800004],
            [111.23792160400015, -32.481293678],
            [111.243925604000168, -32.466883678],
            [111.250004604000168, -32.452495677999963],
            [111.256159604000089, -32.438131677999948],
            [111.262390604000217, -32.423791677999972],
            [111.268695604000101, -32.409474678000024],
            [111.275076604000134, -32.395182678000026],
            [111.281531604000151, -32.380914677999954],
            [111.288062604000089, -32.366671677999918],
            [111.294667604000068, -32.352454678],
            [111.301346604000088, -32.338261678],
            [111.308099604000148, -32.324095677999949],
            [111.314927604000133, -32.309954678000011],
            [111.321828604000217, -32.295839678],
            [111.328803604000171, -32.281751678],
            [111.335852604000166, -32.267690678],
            [111.342974604000034, -32.253656678000027],
            [111.350170604000169, -32.239649677999978],
            [111.357438604000066, -32.225670678000029],
            [111.36475960400017, -32.21175167799997],
            [111.372152604000092, -32.197860678000026],
            [111.379617604000174, -32.183997677999983],
            [111.387154604000131, -32.170163677999952],
            [111.394763604000076, -32.156358678],
            [111.402444604000181, -32.142582677999968],
            [111.410196604000163, -32.12883567800003],
            [111.418019604000136, -32.115119677999985],
            [111.425913604000101, -32.101432678000037],
            [111.433878604000114, -32.087775678],
            [111.441914604000118, -32.074149678000026],
            [111.450021604000227, -32.060553677999962],
            [111.458197604000105, -32.046989677999974],
            [111.466444604000088, -32.033456677999979],
            [111.474761604000065, -32.01995567799996],
            [111.483147604000095, -32.006485677999933],
            [111.491603604000119, -31.99304867799998],
            [111.500128604000082, -31.979643678],
            [111.508723604000153, -31.966271678],
            [111.501806604000109, -31.95258267799997],
            [111.494962604000222, -31.938867678],
            [111.488193604000145, -31.925125678000043],
            [111.481497604000168, -31.911358677999957],
            [111.474875604, -31.897565678000021],
            [111.468327604000166, -31.883746678],
            [111.461853604000083, -31.869903678000014],
            [111.455454604000153, -31.856034678000015],
            [111.449129604000149, -31.842141677999976],
            [111.442878604000128, -31.828224678],
            [111.436702604000089, -31.814282678],
            [111.430601604000145, -31.800317677999971],
            [111.424574604000071, -31.786329677999973],
            [111.418105604000147, -31.772224677999986],
            [111.411712604000087, -31.75809367799998],
            [111.405396604000174, -31.74393867799995],
            [111.399158604000178, -31.729758677999968],
            [111.39299660400016, -31.715553677999978],
            [111.386912604000173, -31.701325677999932],
            [111.380905604000162, -31.687072677999964],
            [111.37497560400007, -31.672797677999938],
            [111.36912360400018, -31.658498677999972],
            [111.363348604000208, -31.64417667799998],
            [111.357651604000097, -31.629832678000028],
            [111.352032604000129, -31.615466678000018],
            [111.346491604000079, -31.601078676999975],
            [111.341027604000061, -31.586668676999977],
            [111.335642604000014, -31.572237677000018],
            [111.330334604000114, -31.557784677000015],
            [111.325105604000015, -31.54331267699996],
            [111.31995460400006, -31.528818676999933],
            [111.314881604000078, -31.514305677000024],
            [111.309887604000124, -31.499772676999982],
            [111.304971604000087, -31.485220676999958],
            [111.300133604000081, -31.470648676999971],
            [111.295374604000045, -31.456057677],
            [111.290694604000151, -31.441448676999968],
            [111.286091604000063, -31.426821676999964],
            [111.272919604, -31.41625867700003],
            [111.259807604000031, -31.405641676999977],
            [111.246755604000128, -31.394970677],
            [111.233764604000186, -31.384247676999976],
            [111.220834604000089, -31.373471677000012],
            [111.207965604000066, -31.362643677],
            [111.195157604000173, -31.351762676999954],
            [111.182411604, -31.340829676999974],
            [111.169727604000201, -31.329844677000025],
            [111.157730604000079, -31.319365676999936],
            [111.1457896040001, -31.308838676999969],
            [111.133904604000037, -31.298266677],
            [111.122076603000068, -31.287648676999964],
            [111.110306603000168, -31.27698467700003],
            [111.098593603000126, -31.26627567700001],
            [111.086937603000109, -31.25552167699999],
            [111.075339603000231, -31.244721677],
            [111.063799603000035, -31.233877677],
            [111.059909603000136, -31.230241676999981],
            [111.047997603000141, -31.219041677],
            [111.036147603000046, -31.207793676999984],
            [111.024360603000076, -31.196499676999963],
            [111.01263560300012, -31.185157676999978],
            [111.000972603000122, -31.173769677],
            [110.989373603000075, -31.162334676999947],
            [110.97783760300004, -31.150853677],
            [110.96636460300013, -31.139326676999971],
            [110.954955603000172, -31.127753676999973],
            [110.94361060300011, -31.11613567699996],
            [110.932329603000113, -31.104472676999976],
            [110.921112603000068, -31.092764676999977],
            [110.909960603000087, -31.081011677],
            [110.898873603000226, -31.069214676999934],
            [110.887851603000144, -31.057373676999958],
            [110.876894603000125, -31.04548867699998],
            [110.866003603000166, -31.033559677],
            [110.855177603000044, -31.021587677000014],
            [110.844417603000153, -31.009572676999937],
            [110.833723603000152, -30.997513677000043],
            [110.824097603000183, -30.986570677000017],
            [110.814525603000021, -30.975591676999954],
            [110.805007603000121, -30.964578677],
            [110.795545603000079, -30.953531677],
            [110.786837603000066, -30.943446677000011],
            [110.778176603000048, -30.93333267700001],
            [110.769560603000087, -30.923190677],
            [110.760991603000122, -30.913019677],
            [110.752032603000117, -30.902326676999976],
            [110.743124603000155, -30.891602677000051],
            [110.734267603000177, -30.880848676999946],
            [110.725461603000127, -30.87006267699995],
            [110.716706603000063, -30.859246676999959],
            [110.708003603000094, -30.848400676999972],
            [110.700989603000181, -30.839615676999983],
            [110.694008603000071, -30.830809676999948],
            [110.687061603000103, -30.821984676999932],
            [110.67644260300014, -30.809544676999948],
            [110.665893603000228, -30.797061676999974],
            [110.655413603000142, -30.78453567699998],
            [110.64500460300016, -30.771967676999978],
            [110.634664603000118, -30.759356676999971],
            [110.624394603000184, -30.746704677000039],
            [110.614195603000013, -30.734009677],
            [110.604067603, -30.721274676999954],
            [110.5940106030001, -30.708497676999983],
            [110.584023603000134, -30.695680676999984],
            [110.574108603000099, -30.682822676999962],
            [110.564264603000225, -30.669923676999929],
            [110.554492603000114, -30.656985676999952],
            [110.544792603000104, -30.644007676999962],
            [110.535164603000084, -30.630990676999929],
            [110.525608603000165, -30.617934676999965],
            [110.516124603000065, -30.604839676999973],
            [110.506713603000065, -30.59170567699995],
            [110.498320603000167, -30.579878676999968],
            [110.489986603000119, -30.568020677],
            [110.481711603000036, -30.556131676999968],
            [110.473494603000148, -30.544212677000019],
            [110.465337603000165, -30.532263675999985],
            [110.457240603000088, -30.520285675999943],
            [110.449201603000091, -30.508277676],
            [110.441223603000168, -30.496240675999971],
            [110.433304603000153, -30.484173675999941],
            [110.424788603000053, -30.471100676],
            [110.416342603000118, -30.457993675999987],
            [110.407966603000119, -30.444852676],
            [110.39962660300003, -30.431626676],
            [110.391357603000102, -30.418367675999932],
            [110.383159603000166, -30.405075675999981],
            [110.375032603000108, -30.391750675999958],
            [110.366977603000095, -30.378394676000042],
            [110.358994603000127, -30.365005675999967],
            [110.351083603000149, -30.351586675999982],
            [110.343243603000104, -30.338134676000024],
            [110.33547660300016, -30.324652675999985],
            [110.327781603000204, -30.311139676000039],
            [110.320158603000067, -30.297596676000012],
            [110.312608603000143, -30.28402367599999],
            [110.305130603000208, -30.270419675999975],
            [110.297726603000086, -30.256787675999959],
            [110.290394603000124, -30.243124676000036],
            [110.283135603000034, -30.229433675999942],
            [110.275949603000157, -30.215714676],
            [110.268836603000153, -30.201965675999986],
            [110.261797603000133, -30.188189676000036],
            [110.254831603000099, -30.174385675999915],
            [110.247939603000219, -30.160554675999947],
            [110.241120603000098, -30.146695675999979],
            [110.235295603000139, -30.135282675999974],
            [110.229521603000165, -30.123851675999987],
            [110.223797603000122, -30.11240067599995],
            [110.218123603000066, -30.100931676000016],
            [110.212500603000223, -30.08944367600003],
            [110.205598603000084, -30.075176675999955],
            [110.198774603000146, -30.060882675999963],
            [110.192028603000125, -30.04656067599997],
            [110.185360603000134, -30.032210676000048],
            [110.178770603000231, -30.017834676000021],
            [110.172259603000185, -30.003430675999979],
            [110.165826603000113, -29.989000675999932],
            [110.159472603000182, -29.974545675999934],
            [110.153197603000223, -29.960063675999933],
            [110.147000603000123, -29.945556676],
            [110.140883603000162, -29.931024675999936],
            [110.134844603000118, -29.916466676000042],
            [110.128884603000103, -29.90188567600002],
            [110.123004603000112, -29.887279675999963],
            [110.117203603000036, -29.872650675999964],
            [110.111482603000155, -29.857997676000025],
            [110.105840603000075, -29.843320675999962],
            [110.100277603000137, -29.828621675999941],
            [110.094795603000222, -29.813900675999975],
            [110.089392603000221, -29.799156675999953],
            [110.084068603000134, -29.784390675999987],
            [110.078825603000126, -29.769603675999974],
            [110.073662603000145, -29.754794676],
            [110.068579603000131, -29.739965675999983],
            [110.063576603000087, -29.725115675999987],
            [110.058653603000067, -29.710244675999945],
            [110.053811602000081, -29.695354676000022],
            [110.049049602000167, -29.68044467600005],
            [110.044367602000108, -29.665515676000012],
            [110.039766602000014, -29.650567676],
            [110.035246602000115, -29.63560067600001],
            [110.030806602000013, -29.620615675999943],
            [110.026447602000218, -29.605612676],
            [110.022169602000105, -29.590591675999988],
            [110.017971602000131, -29.575554675999982],
            [110.013855602000177, -29.560499676],
            [110.009819602000022, -29.545427676000017],
            [110.005864602000173, -29.530339675999969],
            [110.001990602, -29.515235676000017],
            [109.998198602000031, -29.500116675999976],
            [109.994486602000137, -29.484981676000032],
            [109.99085660200015, -29.469831676],
            [109.987307602000129, -29.45466667499997],
            [109.983839602000074, -29.439488674999936],
            [109.980452602000156, -29.424295674999982],
            [109.977147602000088, -29.40908867499995],
            [109.973923602000042, -29.393868675],
            [109.97078160200013, -29.378635674999927],
            [109.967720602000071, -29.363390674999948],
            [109.964740602000148, -29.348132674999945],
            [109.961842602000189, -29.33286267499993],
            [109.95902660200008, -29.317580675],
            [109.95448260200007, -29.304122674999988],
            [109.95000360200018, -29.290647674999988],
            [109.945589602000183, -29.277155675],
            [109.941242602000074, -29.263647674999945],
            [109.936960602000141, -29.250123674999983],
            [109.932744602000099, -29.23658367500002],
            [109.928594602000118, -29.223028674999981],
            [109.92451060200014, -29.209457674999953],
            [109.920397602, -29.195567675],
            [109.916354602000155, -29.181661674999944],
            [109.912379602000073, -29.16774067499999],
            [109.908473602000157, -29.153804674999961],
            [109.904637602000122, -29.139853675000012],
            [109.900870602000197, -29.12588867499997],
            [109.897172602000211, -29.111908674999938],
            [109.893543602000051, -29.097915674999982],
            [109.889984602000169, -29.083908675000018],
            [109.886303602000083, -29.069317674999965],
            [109.88269760200015, -29.054712675],
            [109.879166602000197, -29.040093675000037],
            [109.875711602000166, -29.025460674999962],
            [109.872331602000173, -29.010813675],
            [109.869026602000105, -28.996153675],
            [109.865797602000185, -28.981481675],
            [109.862644602000074, -28.966795674999986],
            [109.859566602000228, -28.952097674999962],
            [109.85656360200008, -28.937388675],
            [109.853636602000194, -28.922666674999945],
            [109.850785602000116, -28.907933675000052],
            [109.848009602000189, -28.893189675000045],
            [109.845309602000185, -28.878434675],
            [109.842684602000219, -28.86366967500004],
            [109.840136602000172, -28.848893674999957],
            [109.837662602000108, -28.834107674999927],
            [109.835265602000192, -28.819311674999966],
            [109.832910602000226, -28.80436067499997],
            [109.830632602000122, -28.789400675000024],
            [109.828432602000163, -28.774430674999977],
            [109.82630960200018, -28.759452674999963],
            [109.824263602000173, -28.744465675],
            [109.822294602000085, -28.729470675000016],
            [109.820436602000171, -28.71476467500004],
            [109.818652602000185, -28.700049674999931],
            [109.81694360200018, -28.685328674999965],
            [109.815308602000158, -28.670601674999943],
            [109.813747602000063, -28.655867674999953],
            [109.812260602000123, -28.641127675],
            [109.810848602000107, -28.626381675],
            [109.809510602000131, -28.611630674999958],
            [109.808246602000196, -28.596874675],
            [109.807057602000128, -28.582113675000016],
            [109.805942602000158, -28.567347674999965],
            [109.805041602000216, -28.555337675000033],
            [109.80418960200015, -28.543324674999951],
            [109.803386602000131, -28.531308674999963],
            [109.802632602000102, -28.519289675],
            [109.801762602000082, -28.504360674999958],
            [109.8009676020001, -28.489427674999945],
            [109.800249602000036, -28.474491675000039],
            [109.799606602000125, -28.459552674999969],
            [109.799038602000024, -28.44461167499999],
            [109.798546602000073, -28.429668674999945],
            [109.7981306020001, -28.414723675],
            [109.797790602000219, -28.399777674999982],
            [109.79752560200015, -28.38482967399996],
            [109.797336602000229, -28.369880673999958],
            [109.797222602000119, -28.354931673999943],
            [109.797184602000101, -28.339981673999944],
            [109.797222602000119, -28.325031673999945],
            [109.797335602000061, -28.310082673999929],
            [109.797523602000155, -28.295133673999928],
            [109.79778760200017, -28.280185674],
            [109.798126602000053, -28.265238673999974],
            [109.798540602000031, -28.250293674000034],
            [109.799030602000158, -28.235349674],
            [109.799562602000123, -28.221187674],
            [109.800161602000031, -28.207026674],
            [109.800828602000166, -28.192868673999982],
            [109.80156360200013, -28.178713673999937],
            [109.802364602000154, -28.164560673999986],
            [109.803234602000174, -28.150411674],
            [109.804171602000082, -28.136265673999986],
            [109.805175602000105, -28.122122673999968],
            [109.806246602000073, -28.10798367400001],
            [109.807384602000099, -28.093849674000026],
            [109.808590602000123, -28.079718673999935],
            [109.809814602000159, -28.066105673999971],
            [109.811100602000153, -28.052496674],
            [109.812448602000046, -28.038892673999982],
            [109.813859602, -28.02529367399994],
            [109.815332602000097, -28.011699673999956],
            [109.816866602000033, -27.998110674000031],
            [109.818463602000094, -27.984527673999978],
            [109.820122602000225, -27.97095067399998],
            [109.821801602000136, -27.957680673999988],
            [109.823540602000065, -27.944415673999956],
            [109.825337602000133, -27.931157673999977],
            [109.827194602000219, -27.917905673999954],
            [109.829109602000045, -27.904660673999985],
            [109.831084602000118, -27.891422673999969],
            [109.833118602000098, -27.878190674],
            [109.83539060200016, -27.863825674],
            [109.837732602000216, -27.849468674000036],
            [109.840144602000038, -27.835121674],
            [109.84262560200014, -27.820783674000012],
            [109.845175602000182, -27.806454673999966],
            [109.847794602000164, -27.792135673999951],
            [109.850482602000199, -27.777827674000036],
            [109.853239602000116, -27.763529673999983],
            [109.856066602000084, -27.749241673999961],
            [109.858961602000164, -27.734965674000037],
            [109.861925602000184, -27.720700673999957],
            [109.854056602000043, -27.707010673999932],
            [109.846260602000172, -27.693288674000016],
            [109.83853760200023, -27.679534674000024],
            [109.830887602000161, -27.665748673999971],
            [109.823310602000134, -27.651930674000013],
            [109.815806602000151, -27.638081673999977],
            [109.808376602000209, -27.624202673999946],
            [109.801019602000139, -27.610291673999939],
            [109.793736602000166, -27.596350673999936],
            [109.78652760200012, -27.582379674000023],
            [109.779391602000061, -27.56837867400003],
            [109.772330602000153, -27.554348674000025],
            [109.765343602000172, -27.540288673999939],
            [109.758431602000229, -27.526200673999938],
            [109.751592602000159, -27.512083673999939],
            [109.744829602000124, -27.497938674000025],
            [109.738140602000186, -27.483765674],
            [109.731526602000173, -27.469565673999966],
            [109.7249866020002, -27.45533767400002],
            [109.718522602000149, -27.441082673999972],
            [109.71213360200008, -27.426800674],
            [109.705819602000162, -27.41249367399999],
            [109.699581602000222, -27.39815967399997],
            [109.69341760200021, -27.383799673999945],
            [109.687330602000173, -27.36941467399997],
            [109.681318602000118, -27.355004673999971],
            [109.675381602000044, -27.340569673999951],
            [109.67002760200009, -27.329885673999954],
            [109.664714602000146, -27.319185673999968],
            [109.659445602000091, -27.308467673],
            [109.651964602000163, -27.294846673],
            [109.64455460200017, -27.281195673000013],
            [109.637214602000114, -27.267513672999939],
            [109.629946602000047, -27.25380167299997],
            [109.622750602000082, -27.240060672999988],
            [109.615625602000051, -27.226289672999926],
            [109.60857260200001, -27.212489672999951],
            [109.601590602000073, -27.198660672999978],
            [109.594680602000068, -27.18480367299999],
            [109.587828602000144, -27.170962673],
            [109.581047602000041, -27.157093673],
            [109.574338602000154, -27.143196673],
            [109.567701602000142, -27.129272672999974],
            [109.561136602000175, -27.115320672999943],
            [109.554644602000195, -27.101343672999974],
            [109.548223602000149, -27.087338673],
            [109.54187560200009, -27.073307672999917],
            [109.535600602000187, -27.059251673],
            [109.529397602000159, -27.045169673000046],
            [109.523268602000172, -27.03106267299998],
            [109.517210602000063, -27.016929673],
            [109.511226602000107, -27.002772673000052],
            [109.505315602000138, -26.988591672999988],
            [109.499477602000098, -26.974386673],
            [109.493712602000215, -26.960156672999972],
            [109.488021602000089, -26.945904672999987],
            [109.482403602000119, -26.931628672999977],
            [109.476858602000078, -26.917329673000012],
            [109.471388602000189, -26.903008672999917],
            [109.465990602000119, -26.888664672999965],
            [109.460667602000143, -26.874299672999953],
            [109.455417602000153, -26.859911673],
            [109.450242602000088, -26.845503673],
            [109.44514060200018, -26.831073672999935],
            [109.440099602000174, -26.816649673000029],
            [109.43513360200015, -26.80220567299996],
            [109.430241602000052, -26.787741673000028],
            [109.425422602000111, -26.773256672999949],
            [109.420678602000095, -26.758752672999989],
            [109.416008602000119, -26.744229672999978],
            [109.411413602000181, -26.729687672999972],
            [109.406891602000115, -26.715126672999915],
            [109.402444602000145, -26.700547672999974],
            [109.398072602000155, -26.68595067300005],
            [109.39377460200015, -26.671335672999959],
            [109.389551602000068, -26.656702672999984],
            [109.385402602000141, -26.642053673000021],
            [109.381328602000139, -26.62738667299999],
            [109.377329602000174, -26.612703672999956],
            [109.373405602000133, -26.598004672999934],
            [109.369556602000074, -26.583289673000024],
            [109.365781602000112, -26.568558672999927],
            [109.362082602000129, -26.553812673000024],
            [109.358457602000129, -26.539051673000017],
            [109.354908602000108, -26.524275673000034],
            [109.351434602000182, -26.509485673000029],
            [109.348035602000067, -26.494681672999945],
            [109.344711602000103, -26.479863673000025],
            [109.341462602000121, -26.46503267300001],
            [109.338290602000114, -26.450187672999988],
            [109.336056602000127, -26.441583672999968],
            [109.333848602000131, -26.432974672999975],
            [109.330653602000012, -26.42039967300002],
            [109.327513602000153, -26.407813673000035],
            [109.3244276020001, -26.395215672999953],
            [109.321397602000133, -26.382607672999924],
            [109.317920602000214, -26.367865672999983],
            [109.314518602000106, -26.353108672999966],
            [109.31119160200015, -26.338338672999939],
            [109.307938602000121, -26.32355467299999],
            [109.30476160200007, -26.308757672999945],
            [109.301657602, -26.293948672999974],
            [109.298629602000034, -26.279125673],
            [109.295676602000214, -26.264291673],
            [109.292797602000206, -26.249444673],
            [109.289993602000123, -26.234586671999963],
            [109.287265602000076, -26.219717672],
            [109.284611602000126, -26.204836671999942],
            [109.281848602000224, -26.189599672],
            [109.279164602000179, -26.174350671999946],
            [109.276558602000051, -26.159090671999962],
            [109.274032602000119, -26.143820671999947],
            [109.271584602000161, -26.12853867199992],
            [109.269215602000173, -26.113247671999943],
            [109.266924602000159, -26.097946672000035],
            [109.264713602000171, -26.082636672],
            [109.262580602000099, -26.067316671999933],
            [109.260526602000056, -26.051988671999922],
            [109.258550602000156, -26.036651671999962],
            [109.256654602000054, -26.021306671999966],
            [109.254837602000151, -26.005954672],
            [109.253098602000165, -25.990594671999915],
            [109.251438602000036, -25.975226671999977],
            [109.249857602000048, -25.959852671999968],
            [109.248355602000203, -25.944472672],
            [109.246932602000157, -25.929085672],
            [109.24558860200014, -25.913693672],
            [109.24432260200004, -25.898295671999975],
            [109.243136602000135, -25.882891671999985],
            [109.242028602000033, -25.867483671999921],
            [109.240999602000073, -25.85207167199998],
            [109.240100602000012, -25.838877671999953],
            [109.239260602000144, -25.825680671999962],
            [109.238477602000017, -25.812480671999978],
            [109.237751602000088, -25.799278672000014],
            [109.237084602000124, -25.786072671999975],
            [109.236474602000129, -25.772865671999952],
            [109.235836602000091, -25.757671672000015],
            [109.235274602000032, -25.742474672],
            [109.234789602000063, -25.727276672],
            [109.23438060200013, -25.712075672000012],
            [109.234047602000118, -25.696873672000024],
            [109.233791602000082, -25.681670671999967],
            [109.233611602000025, -25.666465672],
            [109.233507602000117, -25.651261672000032],
            [109.233479602000017, -25.636055671999983],
            [109.233528602000064, -25.620850672000032],
            [109.233653602000032, -25.60564567199998],
            [109.23385460200015, -25.590441672000011],
            [109.234131602000133, -25.575238671999941],
            [109.234484602000208, -25.560036671999967],
            [109.234913602000091, -25.544835671999977],
            [109.235418602000124, -25.529636671999981],
            [109.235971602000149, -25.514563672],
            [109.236599602000155, -25.499492672000031],
            [109.237302602000142, -25.484424671999946],
            [109.238079602000226, -25.469358671999942],
            [109.238931602000122, -25.454296672],
            [109.239857602000171, -25.439237671999948],
            [109.240858602000031, -25.424183671999955],
            [109.241933602000159, -25.409132671999942],
            [109.243083602000098, -25.394086671999986],
            [109.244307602000191, -25.379045672],
            [109.245605602000211, -25.364009672],
            [109.246978602000155, -25.348979671999942],
            [109.248424602000142, -25.333954671999962],
            [109.249945602000054, -25.318935672000023],
            [109.251540602000119, -25.303923671999954],
            [109.253208602000115, -25.288917672000025],
            [109.254951602000091, -25.27391867200005],
            [109.256768602000221, -25.25892667200003],
            [109.258658602000224, -25.243942671999974],
            [109.260622602000097, -25.228966671999956],
            [109.262660602000125, -25.213998671999974],
            [109.264771602000081, -25.199038672000029],
            [109.266704602000146, -25.183704671999962],
            [109.26871360200019, -25.168378672000017],
            [109.270800602000151, -25.153061670999946],
            [109.272964602000144, -25.137753670999984],
            [109.27520560200017, -25.122454670999971],
            [109.27752360200023, -25.107164671],
            [109.279917602000154, -25.091884670999946],
            [109.282388602000225, -25.07661467100003],
            [109.284936602000101, -25.061355670999944],
            [109.287561602000068, -25.046107670999987],
            [109.29026260200007, -25.030869670999948],
            [109.293049602000082, -25.015583670999945],
            [109.295914602000181, -25.000308670999971],
            [109.298855602000145, -24.985045671],
            [109.301873602000029, -24.969795670999957],
            [109.304968602000059, -24.954557671000018],
            [109.308139602000125, -24.939333671],
            [109.31138760200011, -24.924122670999978],
            [109.314711602000074, -24.908925670999963],
            [109.318112602000184, -24.893742670999956],
            [109.321588602000105, -24.878574670999953],
            [109.325141602000116, -24.863420671000029],
            [109.328770602000162, -24.848281670999938],
            [109.332474602000133, -24.833158671],
            [109.336254602000139, -24.818050671],
            [109.340110602000067, -24.802958670999956],
            [109.344042602000087, -24.787883671],
            [109.348049602000145, -24.772824670999952],
            [109.352131602000128, -24.757782670999987],
            [109.356289602000146, -24.742757671],
            [109.360521602000205, -24.727750670999981],
            [109.364829602000128, -24.712761670999953],
            [109.369212602000147, -24.697791670999976],
            [109.373669602000149, -24.682838671],
            [109.378201602000132, -24.667905670999971],
            [109.382807602000213, -24.652990670999941],
            [109.387488602000161, -24.638096671000042],
            [109.39224360200015, -24.623221670999939],
            [109.397072602000122, -24.608366670999985],
            [109.401975602000135, -24.593531671],
            [109.406953602000186, -24.578717670999964],
            [109.412003602000055, -24.563924670999981],
            [109.417128602000076, -24.549153670999956],
            [109.422326602000027, -24.534403670999978],
            [109.427597602000134, -24.519675670999959],
            [109.432942602000168, -24.504970670999981],
            [109.43835960200002, -24.490287671000047],
            [109.443850602000026, -24.475626670999972],
            [109.449414602000132, -24.460989670999936],
            [109.455050602000171, -24.446376670999925],
            [109.460758602000197, -24.431786670999955],
            [109.466539602000154, -24.417221670999922],
            [109.472393602000096, -24.402680670999914],
            [109.478318602000087, -24.388163671000044],
            [109.484315602000123, -24.373672671],
            [109.489763602000068, -24.360672670999961],
            [109.49527060200009, -24.347693670999973],
            [109.500834602000197, -24.334735671000033],
            [109.506455602000216, -24.32179767099997],
            [109.512134602000089, -24.308881670999952],
            [109.517871602000042, -24.295987670999978],
            [109.523664602000082, -24.283114670999964],
            [109.524751602000066, -24.277773671],
            [109.526654602000036, -24.268521671],
            [109.528586602000104, -24.259273670999988],
            [109.530996602000215, -24.247822670999952],
            [109.533450602000045, -24.236379670999952],
            [109.535946602000223, -24.224944670999989],
            [109.538989602000044, -24.211224670999968],
            [109.542093602000165, -24.197516670999974],
            [109.545259602000129, -24.183819671],
            [109.548487602000051, -24.170135671000025],
            [109.551777602000101, -24.156463670999983],
            [109.555128602000167, -24.142804670999951],
            [109.558540602000136, -24.129158671000013],
            [109.562014602000062, -24.115525671],
            [109.565550602000172, -24.101905671],
            [109.569111602000106, -24.087455669999983],
            [109.572740602000152, -24.073019669999965],
            [109.576439602000079, -24.058598670000023],
            [109.580206602, -24.04419267],
            [109.584041602000156, -24.029802669999981],
            [109.587945602000133, -24.015427669999937],
            [109.591917602000223, -24.00106867],
            [109.595958602000024, -23.986725669999942],
            [109.600067602000166, -23.972399669999959],
            [109.604243602000025, -23.958089669999978],
            [109.608488602000165, -23.943796669999983],
            [109.612800602000078, -23.929521669999957],
            [109.617181602000159, -23.915263669999931],
            [109.621628602000072, -23.901022669999975],
            [109.626144602000153, -23.886800669999985],
            [109.63072660200001, -23.872596669999979],
            [109.635377602000091, -23.858411669999938],
            [109.640094602, -23.844245669999978],
            [109.644878602000148, -23.83009867],
            [109.649729602000065, -23.815970669999956],
            [109.654648602000208, -23.801862669999977],
            [109.659750602000173, -23.787338669999968],
            [109.664924602000184, -23.77283567],
            [109.670168602000018, -23.758354669999989],
            [109.675483602000128, -23.743895670000029],
            [109.680869602000172, -23.729458669999929],
            [109.686324602000155, -23.715044669999955],
            [109.69185060200013, -23.700652670000025],
            [109.697445602000158, -23.686283669999952],
            [109.703111602000178, -23.67193867],
            [109.708846602000136, -23.65761767],
            [109.714650602000148, -23.643319670000025],
            [109.720524602000154, -23.62904667],
            [109.726468602000153, -23.614798669999985],
            [109.732480602000038, -23.600574670000015],
            [109.73856260200003, -23.586375669999981],
            [109.744712602000135, -23.572202669999967],
            [109.750931602000179, -23.558055669999959],
            [109.757219602000163, -23.543933669999987],
            [109.763575602000145, -23.529838669999947],
            [109.77000060200001, -23.51577067],
            [109.776492602000161, -23.50172867],
            [109.783052602000083, -23.48771367],
            [109.789681602000229, -23.47372667],
            [109.796377602000035, -23.45976767],
            [109.803140602000127, -23.445835669999951],
            [109.809683602000149, -23.43222067],
            [109.816291602000064, -23.41863067],
            [109.822963602000101, -23.40506867],
            [109.829698602000093, -23.39153367],
            [109.836497602000094, -23.37802667],
            [109.843361602000215, -23.364546669999967],
            [109.850287602000122, -23.35109467],
            [109.857277602000096, -23.337671669999963],
            [109.864330602000138, -23.324276670000017],
            [109.871446602000134, -23.310909669999987],
            [109.878625602000199, -23.297572669999951],
            [109.885867602000161, -23.284264670000013],
            [109.889773602000133, -23.269735669999932],
            [109.893749602000213, -23.255222670000023],
            [109.897794602000062, -23.240725670000032],
            [109.901908602000191, -23.226245670000012],
            [109.906092602000086, -23.211782669999977],
            [109.910344602000094, -23.197336670000027],
            [109.914666602000096, -23.182908669999961],
            [109.919056602000154, -23.168497669999965],
            [109.923515602000151, -23.154105670000035],
            [109.92804260200009, -23.139731669999989],
            [109.932638602000139, -23.125376670000023],
            [109.937302602000187, -23.11104067],
            [109.942035602000118, -23.096723669999989],
            [109.946835602000164, -23.08242667],
            [109.951704602000149, -23.06814967],
            [109.956641602000076, -23.05389167],
            [109.961645602000061, -23.03965467],
            [109.966717602000045, -23.025438669999957],
            [109.971553602000228, -23.012073669],
            [109.976450602000142, -22.998727668999933],
            [109.981406602000135, -22.98540066899993],
            [109.986421602000206, -22.972092669],
            [109.991496602000012, -22.958803669000019],
            [109.993297602000069, -22.953371669],
            [109.996761602000078, -22.942988668999945],
            [110.000261602000108, -22.932616669],
            [110.003798602000103, -22.922253668999929],
            [110.007805602000104, -22.910641668999972],
            [110.01185860200016, -22.899041668999956],
            [110.015956602000216, -22.887455669000019],
            [110.020098602000161, -22.875883669],
            [110.019237602000061, -22.860893668999978],
            [110.018449602000061, -22.845899668999976],
            [110.017733602000163, -22.830902669],
            [110.017090602000138, -22.815902669000025],
            [110.016516602000166, -22.800830668999964],
            [110.016015602000124, -22.785754669000028],
            [110.015588602000122, -22.770677669],
            [110.015234602000163, -22.755599668999963],
            [110.014953602000077, -22.740519669000051],
            [110.014745602000204, -22.725437668999959],
            [110.014611602000201, -22.71035666899995],
            [110.014550602000128, -22.69527366899996],
            [110.014562602000154, -22.680191668999953],
            [110.014647602000167, -22.665108668999963],
            [110.014806602000164, -22.650027668999954],
            [110.015037602000092, -22.634946668999959],
            [110.015342602000118, -22.619866669000032],
            [110.01572060200013, -22.604787669000018],
            [110.016171602000128, -22.589710668999984],
            [110.016695602000169, -22.574636669000029],
            [110.017292602000083, -22.559563668999971],
            [110.017962602000097, -22.544493669],
            [110.018704602000213, -22.529426668999989],
            [110.019520602000199, -22.514362668999965],
            [110.020409602000228, -22.499302669],
            [110.021370602000076, -22.484246669000029],
            [110.022404602000194, -22.469194668999933],
            [110.023511602000127, -22.454146669],
            [110.02469060200022, -22.439103669000019],
            [110.025942602000072, -22.424065669000029],
            [110.027267602000194, -22.409032669],
            [110.028664602000077, -22.394005669],
            [110.03013360200012, -22.378984669],
            [110.031675602000149, -22.363970669000025],
            [110.03328960200011, -22.348961668999948],
            [110.034976602000171, -22.333960668999978],
            [110.03673560200005, -22.318966668999977],
            [110.038506602000126, -22.303977668999948],
            [110.04034960200002, -22.288996668999957],
            [110.042265602000185, -22.274023669],
            [110.044252602000171, -22.259058669000012],
            [110.046311602000202, -22.24410166899996],
            [110.048442602000108, -22.229153669000027],
            [110.05064560200006, -22.214214668999958],
            [110.052920602000171, -22.199284669000022],
            [110.055267602000214, -22.184364668999919],
            [110.057685602000078, -22.169454668999947],
            [110.060174603000149, -22.154554669],
            [110.06273560300022, -22.139665668999967],
            [110.06536860300011, -22.124786668999988],
            [110.068071603000107, -22.109919669],
            [110.070846603000206, -22.09506366899997],
            [110.073693603000123, -22.080218669000033],
            [110.076610603000091, -22.065386669000034],
            [110.079598603000051, -22.050566669000034],
            [110.082657603000115, -22.035759668999958],
            [110.085787603000171, -22.020964669],
            [110.088988603000161, -22.006183668999938],
            [110.092260603000085, -21.991415669],
            [110.095602603000231, -21.976661668999952],
            [110.099014603000086, -21.961921669],
            [110.102497603000216, -21.947196668999965],
            [110.106050603000227, -21.932485668000027],
            [110.109674603000059, -21.917789667999983],
            [110.113368603000168, -21.903109667999942],
            [110.116864603000209, -21.889090667999966],
            [110.120424603000089, -21.875085668],
            [110.124048603000091, -21.861095668000019],
            [110.127735603000161, -21.847119667999962],
            [110.131486603000013, -21.833158667999982],
            [110.135300603000104, -21.819212668],
            [110.139178603000147, -21.805282668000018],
            [110.143119603000088, -21.791366667999952],
            [110.147124603000094, -21.777467667999957],
            [110.151191603000058, -21.76358466799995],
            [110.155322603000087, -21.749717667999946],
            [110.159516603000185, -21.735867668000012],
            [110.163772603000069, -21.722034667999964],
            [110.168290603000145, -21.707586668000019],
            [110.172876603000162, -21.693158667999953],
            [110.177531603000119, -21.678748667999955],
            [110.182254603000189, -21.66435866800002],
            [110.18704560300003, -21.649989667999947],
            [110.191905603000151, -21.635639667999939],
            [110.196832603000217, -21.621309667999981],
            [110.201827603000169, -21.607000668],
            [110.206890603000119, -21.592713667999959],
            [110.212021603000125, -21.578446667999984],
            [110.217219603000132, -21.564201667999953],
            [110.222484603000026, -21.54997866799998],
            [110.227817603000204, -21.535777668000037],
            [110.233216603000102, -21.521598667999967],
            [110.238683603000169, -21.507442667999939],
            [110.244217603000124, -21.493308667999955],
            [110.249817603000082, -21.479198668],
            [110.255484603000099, -21.465112667999989],
            [110.261217603, -21.45104966800001],
            [110.267017603000085, -21.437010667999971],
            [110.272883603000167, -21.422996667999968],
            [110.278815603000083, -21.409006667999989],
            [110.284813603000231, -21.395041668000047],
            [110.290877603000041, -21.381102668000025],
            [110.297007603000083, -21.367187667999957],
            [110.303202603000187, -21.353298667999979],
            [110.309113603000156, -21.339136668],
            [110.315093603000122, -21.324998668000021],
            [110.321139603000148, -21.310886668],
            [110.327253603000116, -21.296800667999975],
            [110.333433603000145, -21.282739668],
            [110.339681603000173, -21.268705667999924],
            [110.345995603000091, -21.254697667999977],
            [110.352376603000124, -21.240716667999948],
            [110.358824603000102, -21.226762667999935],
            [110.365337603000143, -21.212835668000011],
            [110.371917603000185, -21.198936667999931],
            [110.378563603000231, -21.185064667999953],
            [110.385274603000113, -21.171221667999973],
            [110.392052603000053, -21.157406668],
            [110.398895603000113, -21.143620668000025],
            [110.405803603000123, -21.12986366799997],
            [110.41277660300014, -21.116135668],
            [110.41981560300016, -21.102437667999936],
            [110.426918603000075, -21.088769667999955],
            [110.434086603000111, -21.075130667999986],
            [110.441319603000039, -21.061522668],
            [110.448192603000194, -21.048580667999957],
            [110.455124603000144, -21.035664668000024],
            [110.46211360300012, -21.022777668],
            [110.46916060300012, -21.009918668],
            [110.476266603000084, -20.997087668],
            [110.483429603000076, -20.984285667999913],
            [110.490649603000151, -20.971511668],
            [110.497927603000079, -20.958767668],
            [110.505263603000088, -20.946052667999922],
            [110.510266603000133, -20.931533667999958],
            [110.515339603000115, -20.917035667999968],
            [110.52048160300015, -20.902559668],
            [110.525693603000178, -20.888104668000025],
            [110.530973603000149, -20.873672667999983],
            [110.536323603000056, -20.859263666999979],
            [110.541741603000077, -20.844876666999923],
            [110.547228603000036, -20.830513667],
            [110.552783603000108, -20.81617366699993],
            [110.55840760300012, -20.801857666999979],
            [110.564099603000187, -20.787565666999981],
            [110.569859603000083, -20.773297667],
            [110.575687603000091, -20.759054666999972],
            [110.581583603000155, -20.744836666999959],
            [110.58754660300022, -20.730642666999984],
            [110.59357760300017, -20.716475666999926],
            [110.59967660300012, -20.702333667],
            [110.605842603000127, -20.688217666999975],
            [110.612075603000022, -20.674128667],
            [110.618375603000089, -20.660065667000012],
            [110.624742603000215, -20.646029666999965],
            [110.630953603000222, -20.632306666999966],
            [110.637228603000182, -20.618609666999987],
            [110.64356660300021, -20.604939666999925],
            [110.64996860300019, -20.591295666999983],
            [110.656433603000067, -20.577677666999961],
            [110.662961603000014, -20.564087666999953],
            [110.669553603000196, -20.550524666999962],
            [110.676207603000165, -20.536988666999989],
            [110.682924603000203, -20.523480667000015],
            [110.689703603000027, -20.51000166700004],
            [110.696546603000087, -20.496549667],
            [110.703450603000221, -20.483126666999951],
            [110.710416603000084, -20.469732666999988],
            [110.717445603000016, -20.456367666999952],
            [110.724535603000078, -20.443031667],
            [110.731688603000208, -20.429725666999943],
            [110.738901603000073, -20.416448667],
            [110.746176603, -20.403202667000031],
            [110.753727603000044, -20.389517666999978],
            [110.761343603000029, -20.375864666999988],
            [110.769025603000131, -20.362244666999985],
            [110.776771603000128, -20.348658666999953],
            [110.784583603000016, -20.335104667],
            [110.792459603000083, -20.321585667],
            [110.800400603000156, -20.308099666999979],
            [110.808405603000011, -20.294648666999933],
            [110.81647460300016, -20.281231666999943],
            [110.824607603000032, -20.267849667],
            [110.832449603000072, -20.255071667000024],
            [110.840350603000189, -20.242326666999944],
            [110.84830860300022, -20.229613667000024],
            [110.856325603000101, -20.216933667],
            [110.864399603000123, -20.204286666999948],
            [110.872531603000169, -20.191673667000032],
            [110.880721603000069, -20.179093667000018],
            [110.888968603000222, -20.166546666999977],
            [110.897272603000175, -20.154034667],
            [110.905633603000098, -20.141556666999975],
            [110.914028603000048, -20.129063666999969],
            [110.922479603000198, -20.116604666999919],
            [110.930986603000207, -20.104180667000023],
            [110.939551603000183, -20.091792667],
            [110.948172603000017, -20.079439667000017],
            [110.956849603000052, -20.067121667],
            [110.965583603000169, -20.054839666999953],
            [110.974372603000148, -20.042594666999946],
            [110.983218603000211, -20.030385666999976],
            [110.992118603000023, -20.018212666999972],
            [111.001075603000032, -20.006076667000016],
            [111.010086603000076, -19.993977667],
            [111.019153603000206, -19.981916666999936],
            [111.028274603000028, -19.969892667000011],
            [111.037878603000166, -19.957347666999951],
            [111.047542603000153, -19.944843666999915],
            [111.057265603000047, -19.932381666999987],
            [111.06704760300002, -19.919961666999967],
            [111.076888603000071, -19.907583666999969],
            [111.086788603000201, -19.895248666999876],
            [111.096747603000068, -19.882956666999974],
            [111.106765603000127, -19.870707667],
            [111.116840603000156, -19.858501666999828],
            [111.12697460300015, -19.846339666999938],
            [111.137165604000103, -19.834221666999952],
            [111.14741460400009, -19.822147666999967],
            [111.157720604000161, -19.810118666999983],
            [111.168083604000145, -19.798132665999901],
            [111.178503604000213, -19.786192666],
            [111.188980604000136, -19.774297665999896],
            [111.199513604000089, -19.762447665999971],
            [111.210103604000125, -19.750643665999945],
            [111.220748604000079, -19.738885665999817],
            [111.231449604000062, -19.727172665999859],
            [111.242206604000131, -19.715506665999882],
            [111.253018604000061, -19.703887665999886],
            [111.263885604000194, -19.692314665999973],
            [111.274807604000074, -19.680789665999939],
            [111.285783604000159, -19.669310665999973],
            [111.296814604000161, -19.657880665999983],
            [111.307899604000141, -19.646496665999891],
            [111.319038604000212, -19.635161665999945],
            [111.330230604000036, -19.623874665999978],
            [111.341476604000178, -19.612636665999972],
            [111.352775604000072, -19.601446665999944],
            [111.364126604000177, -19.590305665999978],
            [111.375531604000145, -19.579213665999973],
            [111.386318604000138, -19.568791666],
            [111.397151604000129, -19.558413666],
            [111.408031604000172, -19.548079666],
            [111.418957604000212, -19.537790666000021],
            [111.429928604000139, -19.527545666000023],
            [111.440945604000063, -19.517345666000011],
            [111.452008604000156, -19.507190665999914],
            [111.463115604000137, -19.497080665999988],
            [111.474268604000116, -19.487015665999962],
            [111.4855996040001, -19.476528666],
            [111.496977604000136, -19.466089665999945],
            [111.508403604000165, -19.455698665999947],
            [111.519877604000129, -19.445355666000012],
            [111.531397604000205, -19.435059665999972],
            [111.542965604000159, -19.42481266599998],
            [111.554580604000165, -19.414614665999963],
            [111.566242604000166, -19.40446466600001],
            [111.577950604000165, -19.39436466599993],
            [111.589704604000218, -19.384312666000014],
            [111.601503604000214, -19.374310665999957],
            [111.613349604000149, -19.364357665999961],
            [111.625240604000197, -19.354454666],
            [111.637176604000189, -19.344601665999946],
            [111.649158604000121, -19.334798665999926],
            [111.661184604000056, -19.325045665999951],
            [111.67325460400005, -19.315343665999947],
            [111.685369604000158, -19.305691665999987],
            [111.697527604000157, -19.296091665999981],
            [111.709730604000157, -19.286541665999934],
            [111.721976604000162, -19.277042665999929],
            [111.734265604000171, -19.267595665999963],
            [111.746598604000184, -19.258199665999953],
            [111.758973604000204, -19.248855666],
            [111.771391604000229, -19.239563665999981],
            [111.783851604000091, -19.230323666],
            [111.796353604000132, -19.221136665999964],
            [111.808898604, -19.212001665999978],
            [111.821761604000159, -19.20271466600002],
            [111.834668604000143, -19.193482666],
            [111.847617604000135, -19.184306665999983],
            [111.860609604000132, -19.17518566599999],
            [111.873644604000134, -19.166120665999927],
            [111.886721604000087, -19.157111665999963],
            [111.899840604000104, -19.148158666000015],
            [111.913000604000075, -19.139261666],
            [111.926202604000224, -19.130420666],
            [111.939444604000215, -19.121637666],
            [111.952728604000214, -19.112910666],
            [111.966052604000225, -19.104240665999939],
            [111.979416604000022, -19.09562766599997],
            [111.99282160400017, -19.087071665999929],
            [112.006265604000163, -19.078573665999969],
            [112.019748604000114, -19.070133665999933],
            [112.033271604000191, -19.061751665999978],
            [112.04683360400017, -19.05342666599995],
            [112.0579666040002, -19.046636665999912],
            [112.069126604000218, -19.039885665999989],
            [112.080311604000116, -19.033173666000025],
            [112.091522604000176, -19.026501665999973],
            [112.102759604000113, -19.019868665999965],
            [112.109010604000133, -19.005759666],
            [112.115329604000095, -18.991677665999973],
            [112.121714604000118, -18.977622665999945],
            [112.128165604000145, -18.963595665999932],
            [112.134754604000165, -18.949437666],
            [112.141410604000129, -18.935308665999983],
            [112.148133604000037, -18.921208665999956],
            [112.154924604000172, -18.907138666000023],
            [112.161782604000138, -18.893097666],
            [112.168707604000161, -18.879087665999975],
            [112.175698604000189, -18.865106666000031],
            [112.182757604000102, -18.851157666],
            [112.189882604000246, -18.837238666000033],
            [112.197073604000053, -18.823350665999968],
            [112.204330604000091, -18.809494665999978],
            [112.21165360500018, -18.795670665999964],
            [112.219042605000112, -18.78187866599994],
            [112.226497605000219, -18.768118666],
            [112.234017605000162, -18.754391665999933],
            [112.241602605000168, -18.740697666000017],
            [112.249253605000177, -18.727036666],
            [112.256968605000196, -18.71340866499996],
            [112.264749605000162, -18.699814664999977],
            [112.272594605000194, -18.686255664999962],
            [112.280503605000177, -18.672729665],
            [112.288477605000111, -18.659239665000015],
            [112.296514605000112, -18.645783664999982],
            [112.304616605000177, -18.632362665000016],
            [112.312781605000197, -18.618977664999917],
            [112.321010605000112, -18.605628664999955],
            [112.329302605000152, -18.592314664999961],
            [112.33765760500026, -18.579037664999916],
            [112.346075605000095, -18.565797665],
            [112.354556605000226, -18.552593664999961],
            [112.363100605000255, -18.539427664999948],
            [112.371706605000185, -18.526297664999987],
            [112.380374605000185, -18.51320666499997],
            [112.389104605000142, -18.500152664999987],
            [112.39789660500017, -18.487137665000034],
            [112.406728605000154, -18.474186664999962],
            [112.415622605000209, -18.461274665],
            [112.424576605000226, -18.448400665],
            [112.433591605000146, -18.435565665000027],
            [112.442667605000139, -18.422770664999987],
            [112.451803605000038, -18.41001566499996],
            [112.460999605000183, -18.397299664999977],
            [112.470255605000176, -18.384624665],
            [112.479571605000132, -18.371988664999961],
            [112.488947605000163, -18.359394665000039],
            [112.498382605000103, -18.346841664999943],
            [112.507876605000234, -18.334328664999958],
            [112.51742960500016, -18.321858664999979],
            [112.527041605000107, -18.309429665000025],
            [112.536711605000136, -18.297041665],
            [112.546440605000072, -18.284697664999968],
            [112.556227605000259, -18.272394665000036],
            [112.56607260500013, -18.260134664999939],
            [112.575974605000084, -18.247918664999929],
            [112.585934605000119, -18.235744665000027],
            [112.595951605000181, -18.223614665000028],
            [112.606025605000156, -18.211528664999932],
            [112.616156605000043, -18.199486664999938],
            [112.626344605000185, -18.187488664999933],
            [112.636588605000128, -18.17553466499993],
            [112.646888605000214, -18.163625664999927],
            [112.657245605000156, -18.151762665],
            [112.667656605000133, -18.139943664999961],
            [112.678124605000193, -18.128170665000013],
            [112.68864760500017, -18.116442664999965],
            [112.699224605000126, -18.104761665],
            [112.709857605000167, -18.093125665000017],
            [112.720544605000129, -18.081536664999931],
            [112.731286605000122, -18.069994665],
            [112.742081605000152, -18.058499664999957],
            [112.752931605000214, -18.047050664999986],
            [112.763834605000199, -18.035649665],
            [112.774791605000047, -18.024296665],
            [112.785801605000103, -18.012990664999961],
            [112.796864605000195, -18.001733664999975],
            [112.807979605000156, -17.990524664999981],
            [112.819148605000038, -17.979363664999951],
            [112.830368605000245, -17.968251664999983],
            [112.841641605000206, -17.95718866499999],
            [112.852965605000037, -17.946175664999942],
            [112.864341605000192, -17.935210664999971],
            [112.875768605000104, -17.924296664999957],
            [112.887246605000229, -17.91343166499999],
            [112.898775605000168, -17.902616665],
            [112.910355605000206, -17.891852664999959],
            [112.921751605000139, -17.881240664999964],
            [112.93319660500012, -17.870676664999962],
            [112.944689605000093, -17.860161665],
            [112.956231605000113, -17.84969666500001],
            [112.967821605000125, -17.83928066499999],
            [112.979459605000244, -17.828914665000013],
            [112.99114460500013, -17.818599665],
            [113.002877605000236, -17.80833366499995],
            [113.014658605000108, -17.798118664999947],
            [113.026485605000204, -17.787954664999987],
            [113.038359605000238, -17.777840665],
            [113.0502796050001, -17.767778665000037],
            [113.062246605000183, -17.757766664999963],
            [113.074259605000094, -17.747807665],
            [113.086318605000173, -17.737898665],
            [113.098422605000252, -17.728042665000032],
            [113.110571605000047, -17.71823866499993],
            [113.12276660500018, -17.708486664999967],
            [113.135005605000146, -17.698786664999943],
            [113.147289605000168, -17.689139665000042],
            [113.159617605000136, -17.679545665],
            [113.171989605000164, -17.670004664999979],
            [113.184405605000194, -17.660516665],
            [113.196864605000229, -17.65108166399996],
            [113.209367605000097, -17.641700663999956],
            [113.221912605000142, -17.632373663999971],
            [113.234501605000133, -17.623099663999938],
            [113.247132605000189, -17.613880664],
            [113.259805605000253, -17.604715664000025],
            [113.272520605000153, -17.595605663999962],
            [113.285277606000164, -17.586549664000017],
            [113.298076606000194, -17.57754866400002],
            [113.310916606000234, -17.568602664000025],
            [113.323797606000113, -17.559711663999977],
            [113.336718606000176, -17.550876663999929],
            [113.349680606000192, -17.542096663999914],
            [113.36268260600005, -17.533372664],
            [113.375724606000091, -17.524704664000012],
            [113.387887606000078, -17.51669166399995],
            [113.400083606000209, -17.508727663999935],
            [113.413331606000071, -17.500137664],
            [113.426619606000116, -17.491604664],
            [113.43994660600012, -17.483129663999989],
            [113.453312606000082, -17.474712663999981],
            [113.46671760600023, -17.466353663999982],
            [113.480160606000169, -17.458051664],
            [113.493642606000122, -17.449808663999988],
            [113.507162606000207, -17.441624663999988],
            [113.520719606000142, -17.433498664],
            [113.534314606000208, -17.425430664],
            [113.547946606000238, -17.417422664],
            [113.56161460600012, -17.409472664],
            [113.575320606000133, -17.401582663999989],
            [113.589062606000169, -17.393752663999962],
            [113.602839606000117, -17.385980663999945],
            [113.616653606000199, -17.378269664],
            [113.630502606000135, -17.370617663999965],
            [113.64438660600004, -17.363026664],
            [113.658305606000255, -17.355494663999949],
            [113.672259606000097, -17.348023663999967],
            [113.686247606000194, -17.340612663999977],
            [113.700269606000148, -17.333262663999975],
            [113.714325606000244, -17.32597366399996],
            [113.728415606000254, -17.318744664],
            [113.742537606000127, -17.311577663999955],
            [113.756693606000255, -17.304471663999976],
            [113.770881606000245, -17.297426663999985],
            [113.785102606000152, -17.290442663999983],
            [113.799354606000151, -17.283520664000037],
            [113.81363960600018, -17.276660663999976],
            [113.827955606000131, -17.269862664],
            [113.842302606000175, -17.263126663999984],
            [113.856680606000083, -17.256452663999966],
            [113.871089606000197, -17.249840664],
            [113.885528606000179, -17.243291664000012],
            [113.899997606000085, -17.236804664],
            [113.914496606000085, -17.230380663999966],
            [113.92902460600024, -17.224019663999982],
            [113.943581606000151, -17.217721663999981],
            [113.958168606000214, -17.211486663999949],
            [113.972783606000149, -17.205314663999971],
            [113.987426606000241, -17.199205663999976],
            [114.002097606000149, -17.193160663999947],
            [114.016796606000156, -17.187178663999973],
            [114.031522606000209, -17.181260663999964],
            [114.046276606000248, -17.175406664000022],
            [114.061056606000221, -17.169616663999946],
            [114.075863606000183, -17.163889663999939],
            [114.090696606000193, -17.15822766399998],
            [114.10555460600014, -17.152629663999974],
            [114.120439606000247, -17.147096663999946],
            [114.135349606000233, -17.141627663999969],
            [114.150284606000156, -17.136222663999945],
            [114.165243606000189, -17.130883663999981],
            [114.180227606000159, -17.125608663999969],
            [114.195235606000182, -17.120398663999936],
            [114.210267606000201, -17.115253664000022],
            [114.225401606000247, -17.110143664],
            [114.240557606000067, -17.10509866400001],
            [114.255737606000167, -17.100120663999974],
            [114.270940606000153, -17.095207664],
            [114.286165606000083, -17.090361663999957],
            [114.301413606000239, -17.08558166399996],
            [114.316682606000171, -17.080868664],
            [114.331973606000048, -17.076221664],
            [114.347286606000154, -17.071641663999955],
            [114.362619607000141, -17.067127663999941],
            [114.377974607000084, -17.06268066399997],
            [114.393348607000149, -17.058300663999944],
            [114.408743607000048, -17.053987664000047],
            [114.42415760700024, -17.049741664],
            [114.439591607000153, -17.045562664],
            [114.45504460700019, -17.041451664000022],
            [114.470516607000121, -17.037406664],
            [114.486007607000062, -17.03342966400001],
            [114.50151560700013, -17.029520663999975],
            [114.517042607000093, -17.025677663999971],
            [114.532586607000127, -17.021903664],
            [114.548148607000115, -17.018196663999959],
            [114.56372660700012, -17.014557663999952],
            [114.579321607000139, -17.010986663999986],
            [114.594933607000115, -17.007483663999963],
            [114.610560607000167, -17.004048663999967],
            [114.626203607000122, -17.000681664],
            [114.641862607000093, -16.997381663999988],
            [114.657535607000142, -16.994150663999989],
            [114.673224607000094, -16.990988663999929],
            [114.688927607000124, -16.987893664],
            [114.704643607000179, -16.984867664],
            [114.720374607000139, -16.981910663999955],
            [114.736118607000122, -16.979021664000015],
            [114.751876607000185, -16.976200664000018],
            [114.767646607000103, -16.973449663999943],
            [114.783429607000102, -16.970765664],
            [114.799224607000127, -16.96815166399999],
            [114.815031607000066, -16.965605664],
            [114.830850607000201, -16.963128663999967],
            [114.846680607000195, -16.96072066399995],
            [114.862520607000107, -16.958381663999944],
            [114.878372607000216, -16.956111663999977],
            [114.894234607000186, -16.953910664000034],
            [114.91010560700019, -16.951778664000017],
            [114.92598760700011, -16.949715663999953],
            [114.941878607000177, -16.947721663999985],
            [114.957777607000111, -16.945796663999957],
            [114.973686607000133, -16.943941663999951],
            [114.989603607000134, -16.942155663999969],
            [115.00552860700023, -16.940438663999927],
            [115.021461607000134, -16.938790664],
            [115.037401607000248, -16.937212664],
            [115.053348607000231, -16.935703664000016],
            [115.069302607000083, -16.934264663999969],
            [115.085263607000144, -16.932894663999946],
            [115.10123060700019, -16.931594663999945],
            [115.117203607000107, -16.93036366399997],
            [115.133181607000239, -16.929202664],
            [115.149164607000188, -16.928110663999973],
            [115.165152607000181, -16.927088663999967],
            [115.181145607000161, -16.926135663999986],
            [115.19653260700025, -16.925281663999939],
            [115.211923607000159, -16.924491663999945],
            [115.227317607000117, -16.923766664],
            [115.242714607000181, -16.923105663999934],
            [115.258114607000181, -16.922508664],
            [115.273517607000116, -16.921976663999956],
            [115.288921607000105, -16.921509663999956],
            [115.304328607000258, -16.921106664],
            [115.319736607000237, -16.920767664000024],
            [115.335146607000098, -16.920493664],
            [115.350556607000073, -16.920284663999936],
            [115.365968607000099, -16.920139664000018],
            [115.381380607000239, -16.920059663999965],
            [115.396792607000094, -16.920043663999977],
            [115.41220460700012, -16.920091663999955],
            [115.42761660800025, -16.920205663999965],
            [115.443027608000108, -16.920382663999959],
            [115.458437608000082, -16.920625664],
            [115.473846608000116, -16.920931664],
            [115.477287608000182, -16.905891664],
            [115.48079960800024, -16.890865663999932],
            [115.484382608000118, -16.875855664000028],
            [115.488037608000042, -16.860861664000026],
            [115.49176260800013, -16.84588466400001],
            [115.495558608000152, -16.830923664],
            [115.499425608000109, -16.815978663999957],
            [115.503327608000205, -16.801133664000034],
            [115.507299608000125, -16.786305664],
            [115.511340608000154, -16.771495663999943],
            [115.515451608000063, -16.75670266399996],
            [115.519632608000137, -16.741928663999957],
            [115.52388260800015, -16.727172664000022],
            [115.528201608000103, -16.712434663999971],
            [115.532590608000163, -16.697716663999984],
            [115.537047608000165, -16.683017663999962],
            [115.541574608000104, -16.668338664],
            [115.546169608000156, -16.653679664],
            [115.550833608000147, -16.639040663999978],
            [115.555566608000134, -16.624422664],
            [115.560367608000178, -16.609824663999987],
            [115.56523760800016, -16.595248663999939],
            [115.570175608000255, -16.580693664000023],
            [115.575181608000122, -16.566160662999962],
            [115.580255608000101, -16.551649662999964],
            [115.585397608000136, -16.537160663],
            [115.59060760800017, -16.522694662999982],
            [115.595885608000259, -16.50825166300001],
            [115.601230608000179, -16.493832662999978],
            [115.606642608000158, -16.479436662999987],
            [115.612122608000078, -16.465063663000024],
            [115.617670608000111, -16.450715663000011],
            [115.623284608000091, -16.436391662999938],
            [115.628965608000186, -16.422092662999972],
            [115.634713608000169, -16.407818662999944],
            [115.640528608000096, -16.393570662999934],
            [115.646409608000141, -16.379347662999962],
            [115.652357608000244, -16.365150663],
            [115.658371608000181, -16.350979662999976],
            [115.664451608000178, -16.33683566299996],
            [115.670597608000236, -16.322717662999963],
            [115.676809608000127, -16.30862666299997],
            [115.683087608000079, -16.294563663],
            [115.689430608000151, -16.280528662999942],
            [115.695839608000114, -16.266520663],
            [115.698424608000124, -16.260900663000044],
            [115.703777608000195, -16.249104662999969],
            [115.709176608000149, -16.237327663000045],
            [115.714622608000099, -16.225570663],
            [115.720114608000102, -16.213833663],
            [115.725651608000106, -16.202116662999984],
            [115.732372608000134, -16.188034662999939],
            [115.73915860800011, -16.173983662999987],
            [115.746011608000202, -16.159961662999933],
            [115.752931608000068, -16.145970662999972],
            [115.759916608000168, -16.132010662999988],
            [115.766966608000217, -16.118081663],
            [115.774083608000211, -16.104184663],
            [115.781265608000155, -16.090318662999977],
            [115.788512608000104, -16.076485663000028],
            [115.795824608000231, -16.062683662999973],
            [115.803201608000251, -16.048914663],
            [115.810644608000217, -16.035178662999982],
            [115.818150608000252, -16.021476662999945],
            [115.82572160800018, -16.007806662999968],
            [115.833357608000114, -15.994171662999975],
            [115.841056608000059, -15.980569662999955],
            [115.848820608000125, -15.967002662999974],
            [115.856647608000259, -15.953469662999979],
            [115.864538608000174, -15.939972662999935],
            [115.872492608000044, -15.926509662999962],
            [115.880172608000095, -15.912997663000013],
            [115.887915608000213, -15.899518663000038],
            [115.895721608000173, -15.886074662999945],
            [115.903590608000144, -15.872665663],
            [115.911522608000183, -15.85929066300001],
            [115.91951660800018, -15.845950663],
            [115.927572608000247, -15.832646662999935],
            [115.935690608000101, -15.819378663],
            [115.943871608000137, -15.806145662999967],
            [115.952113608000133, -15.792949662999959],
            [115.960416608000202, -15.779790663],
            [115.968781608000171, -15.766667663],
            [115.977207608000214, -15.753581663000034],
            [115.985694608000045, -15.740533663000022],
            [115.994242608000064, -15.727522662999959],
            [116.002851608000157, -15.714549663000014],
            [116.011520608000211, -15.701615662999927],
            [116.020249608000114, -15.688718662999975],
            [116.029039608000147, -15.675861663000035],
            [116.037888608000202, -15.66304266299997],
            [116.046797608000048, -15.650263663],
            [116.055765608000257, -15.637523662999982],
            [116.064793608000201, -15.624822662999989],
            [116.073880608000223, -15.612162662999921],
            [116.08302660800021, -15.599542662999966],
            [116.092230608000165, -15.586963662999949],
            [116.101493608000084, -15.574424662999945],
            [116.110814608000197, -15.561926662999966],
            [116.120194608000105, -15.549470663],
            [116.12963160800021, -15.537055662999947],
            [116.139126608000169, -15.524682663],
            [116.148679608000094, -15.512352662999973],
            [116.158288608000049, -15.500063661999974],
            [116.167955608000199, -15.487817661999969],
            [116.177679608000147, -15.47561466199997],
            [116.187459608000125, -15.463454661999975],
            [116.197301608000174, -15.451308661999974],
            [116.20720060800025, -15.439206661999989],
            [116.21715560800024, -15.427148662],
            [116.227165608000149, -15.415134662],
            [116.237232608000255, -15.403164662],
            [116.247354608000109, -15.391239662],
            [116.257532608000162, -15.379360662],
            [116.267765608000133, -15.367525661999977],
            [116.278053608000135, -15.355735662000043],
            [116.288395608000172, -15.343992662],
            [116.298792608000127, -15.332294662000038],
            [116.309244608000114, -15.320642661999983],
            [116.319749608000137, -15.30903766199998],
            [116.330308608000252, -15.297478661999975],
            [116.340921608000059, -15.28596666199995],
            [116.351588608000071, -15.274501662],
            [116.362307608000123, -15.263084661999926],
            [116.373080608000095, -15.251714661999927],
            [116.383905608000219, -15.240392662],
            [116.394782608000099, -15.229118661999948],
            [116.40571260800013, -15.217892661999954],
            [116.416694608000086, -15.206715662000036],
            [116.427728608000081, -15.195586661999982],
            [116.438813608000117, -15.184506662],
            [116.449950608000194, -15.173476661999985],
            [116.459275608000183, -15.164259661999949],
            [116.468637608000137, -15.155076661999985],
            [116.478034608000058, -15.145927662],
            [116.489197608000069, -15.13512466200001],
            [116.500409608000126, -15.12437066199999],
            [116.51167160900016, -15.113665661999946],
            [116.522982609000252, -15.103009661999963],
            [116.534341609000165, -15.092403662000024],
            [116.54575060900018, -15.081848661999956],
            [116.55720660900019, -15.07134266199995],
            [116.568711609000076, -15.060886661999987],
            [116.580264609000125, -15.050482661999979],
            [116.591864609000055, -15.040128662000029],
            [116.603512609000148, -15.029825661999936],
            [116.615207609000123, -15.019573661999985],
            [116.626949609000093, -15.009372661999976],
            [116.63893260900025, -14.999049662000019],
            [116.650964609000226, -14.988778662],
            [116.663043609000198, -14.978561662],
            [116.675170609000105, -14.968399662000039],
            [116.687344609000121, -14.95829066200001],
            [116.699565609000189, -14.948236662000014],
            [116.711833609000138, -14.938237661999935],
            [116.724147609000084, -14.928292661999976],
            [116.736507609000199, -14.918403661999946],
            [116.748914609000195, -14.908569661999948],
            [116.761366609000191, -14.898790661999953],
            [116.773864609000185, -14.889067661999988],
            [116.786407609000236, -14.879399662000026],
            [116.798994609000061, -14.869788662],
            [116.811627609000112, -14.860233661999985],
            [116.824304609000052, -14.850735661999977],
            [116.83702460900011, -14.841293661999984],
            [116.849789609000169, -14.831908662],
            [116.862597609000119, -14.822580661999936],
            [116.875449609000185, -14.813309661999966],
            [116.888344609000143, -14.804096662],
            [116.901281609000222, -14.794940661999959],
            [116.914261609000192, -14.785842661999922],
            [116.927283609000114, -14.776802661999966],
            [116.940347609000099, -14.767821662000017],
            [116.953453609000036, -14.758897661999981],
            [116.966600609000153, -14.750033662000035],
            [116.979788609000053, -14.741227662],
            [116.993017609000191, -14.732480661999958],
            [117.006287609000111, -14.723792662],
            [117.019596609000047, -14.715163661999952],
            [117.032946609000106, -14.706594661999972],
            [117.046336609000178, -14.698085661999912],
            [117.059765609000095, -14.68963566199993],
            [117.073233609000198, -14.681245662000023],
            [117.086739609000034, -14.67291666200002],
            [117.10028560900011, -14.664647662],
            [117.113869609000147, -14.656438661999971],
            [117.127491609000145, -14.64829166200002],
            [117.141150609000107, -14.64020466199996],
            [117.154847609000086, -14.632178661999973],
            [117.168581609000199, -14.624213661999974],
            [117.182352609000162, -14.616310661999961],
            [117.195422609000133, -14.608881661999988],
            [117.208525609000077, -14.601508661999944],
            [117.221661609000165, -14.594190662000017],
            [117.234828609000175, -14.586927661999923],
            [117.248027609000161, -14.579721661999926],
            [117.260831609000235, -14.57040566199997],
            [117.273678609000143, -14.56114566199993],
            [117.286336609000131, -14.552084662000027],
            [117.299036609000126, -14.543079661999954],
            [117.311777609000075, -14.534130661999981],
            [117.324558609000206, -14.525236661999941],
            [117.337381609000232, -14.516399662000012],
            [117.350243609000159, -14.507618662],
            [117.363145609000156, -14.498894661999984],
            [117.376088609000107, -14.490227662],
            [117.389069609000245, -14.481616662],
            [117.402090609000169, -14.473063661999944],
            [117.415150609000108, -14.464567661999979],
            [117.428248609000178, -14.456128662000012],
            [117.441385609000093, -14.447747661999969],
            [117.45456060900014, -14.439424662000022],
            [117.467773609000147, -14.431158661999987],
            [117.481023609000175, -14.422951660999942],
            [117.494311609000221, -14.414802661],
            [117.507636609000173, -14.406712660999958],
            [117.520998609000145, -14.398680661000014],
            [117.53439660900014, -14.390707660999979],
            [117.547831609000042, -14.382793660999937],
            [117.561301609000253, -14.374938660999973],
            [117.574807609000146, -14.367143661000014],
            [117.588349610000165, -14.359406661000051],
            [117.601926610000163, -14.351730660999976],
            [117.615538610000129, -14.344113661],
            [117.629184610000067, -14.336555661],
            [117.642864610000146, -14.329058661],
            [117.656579610000193, -14.321621661],
            [117.670328610000212, -14.314245660999973],
            [117.684110610000204, -14.306929661000026],
            [117.697925610000169, -14.299673660999986],
            [117.711773610000051, -14.292478661000018],
            [117.725654610000248, -14.285345661000022],
            [117.73956761000025, -14.278272661000017],
            [117.753512610000115, -14.271260660999914],
            [117.767489610000183, -14.264310660999968],
            [117.781498610000227, -14.257421661],
            [117.79553861000025, -14.250594661],
            [117.809539610000257, -14.243858661],
            [117.82357161000013, -14.237184660999972],
            [117.83763361000004, -14.230571660999928],
            [117.851725610000102, -14.224019660999957],
            [117.865847610000145, -14.217529660999972],
            [117.879998610000058, -14.211101660999958],
            [117.894177610000128, -14.204735661],
            [117.908386610000179, -14.198430661000046],
            [117.922623610000159, -14.192189660999958],
            [117.936888610000238, -14.186009660999943],
            [117.951181610000077, -14.179892661],
            [117.965501610000075, -14.173837660999936],
            [117.979849610000173, -14.167845660999944],
            [117.994224610000202, -14.161916661],
            [118.008625610000166, -14.156050660999952],
            [118.023053610000062, -14.150247661000037],
            [118.037507610000063, -14.14450766100002],
            [118.051987610000168, -14.138830660999972],
            [118.066493610000208, -14.133217660999975],
            [118.081024610000185, -14.127667660999961],
            [118.095579610000101, -14.122180661],
            [118.110160610000122, -14.116758660999906],
            [118.124765610000082, -14.111399660999879],
            [118.139394610000096, -14.106104661],
            [118.154047610000106, -14.100874661],
            [118.168723610000114, -14.095707660999949],
            [118.183423610000119, -14.09060566099987],
            [118.198145610000182, -14.08556666100003],
            [118.212890610000187, -14.080593660999966],
            [118.227658610000134, -14.075684660999869],
            [118.242448610000139, -14.070839661],
            [118.257259610000091, -14.066060661],
            [118.272092610000101, -14.061345660999976],
            [118.286946610000228, -14.056694661],
            [118.287482610000183, -14.041816660999913],
            [118.288086610000136, -14.026940660999898],
            [118.288758610000144, -14.012066660999878],
            [118.289497610000097, -13.997196661000018],
            [118.290303610000166, -13.982329660999952],
            [118.291161610000103, -13.966993660999989],
            [118.292090610000145, -13.951661660999903],
            [118.293091610000175, -13.936332660999895],
            [118.29416361000014, -13.921009660999943],
            [118.295308610000035, -13.905690660999966],
            [118.296524610000091, -13.890377660999945],
            [118.297662610000117, -13.876639660999857],
            [118.298858610000053, -13.862906661000011],
            [118.30011261000007, -13.849177660999871],
            [118.301424610000169, -13.835454660999872],
            [118.302792610000125, -13.821736660999932],
            [118.30421961000016, -13.808023660999964],
            [118.305703610000108, -13.794317661000036],
            [118.30724461000014, -13.780616661],
            [118.30875561000019, -13.767522660999902],
            [118.31031861000011, -13.754434660999863],
            [118.311933610000239, -13.741352660999965],
            [118.313601610000234, -13.728276660999853],
            [118.315321610000098, -13.715206660999883],
            [118.31733761000018, -13.700258660999864],
            [118.319422610000203, -13.685318660999982],
            [118.321576610000164, -13.670388660999947],
            [118.323798610000182, -13.655468660999944],
            [118.326088610000198, -13.640557660999875],
            [118.328447610000154, -13.625657660999835],
            [118.330873610000168, -13.610767660999912],
            [118.333368610000178, -13.595887660999935],
            [118.335931610000245, -13.581019660999871],
            [118.338562610000139, -13.566163660999919],
            [118.341261610000089, -13.551318661],
            [118.344028610000152, -13.536485660999901],
            [118.346862610000159, -13.521664660999917],
            [118.347289610000217, -13.519485660999919],
            [118.349765610000105, -13.506856661000029],
            [118.352735610000167, -13.492060660999883],
            [118.35577361000017, -13.477278660999929],
            [118.358878610000062, -13.462509660999984],
            [118.362051610000123, -13.447754660999948],
            [118.365291610000241, -13.433014661],
            [118.368598610000134, -13.418287660999979],
            [118.37197361000014, -13.403575660999948],
            [118.375389610000155, -13.388472660999895],
            [118.378876610000106, -13.373384660999847],
            [118.382433610000163, -13.358312660999871],
            [118.386061610000212, -13.343256659999895],
            [118.389760610000138, -13.32821666],
            [118.393529610000229, -13.313194659999894],
            [118.397368610000086, -13.29818865999988],
            [118.401277610000221, -13.28320066000002],
            [118.40506461000021, -13.26895466],
            [118.451263889000103, -13.248613888999927],
            [119.401263889000035, -12.831947221999854],
            [119.851250000000135, -12.631938888999898],
            [120.267916667000094, -12.581938888999929],
            [121.334583333000154, -12.398605555999879],
            [121.934583333000177, -11.71527222199984],
            [122.001250000000113, -11.665272221999871],
            [122.034583333000143, -11.665272221999871],
            [122.451250000000215, -11.631827777999874],
            [122.717916667000253, -11.615272221999902],
            [123.234572222000253, -11.548602777999946],
            [123.279035499285868, -11.537486720714185],
            [123.301238889000075, -11.531936110999922],
            [123.347478696000138, -11.520376158999923],
            [123.367905556000238, -11.515269443999898],
            [123.707108217000183, -11.535222541999971],
            [123.934572222000128, -11.548602778000031],
            [123.955565399000108, -11.559099365999884],
            [123.967905556000261, -11.565269443999966],
            [124.434572222000128, -11.515269443999898],
            [124.451238889000052, -11.515269443999898],
            [124.567905556000113, -11.565269443999966],
            [124.951238889000109, -11.665269443999819],
            [125.082662363000196, -11.705267891999952],
            [125.334572222000162, -11.781936110999965],
            [125.417905556000079, -11.74860277799992],
            [125.574448211000259, -11.685985714999973],
            [125.75123888900012, -11.615269443999935],
            [125.788617856000116, -11.60031578049994],
            [126.001225000000176, -11.515261111000015],
            [126.201225000000164, -11.431927777999817],
            [126.346377760000195, -11.386567539999874],
            [126.467891667000202, -11.348594443999986],
            [126.497615628000204, -11.338686457499847],
            [126.5178916670001, -11.331927777999965],
            [126.51901300233348, -11.331861779666596],
            [126.531079383000105, -11.33115203],
            [126.535897726500224, -11.33086859799991],
            [126.539593852000195, -11.330651178999943],
            [126.801225000000187, -11.315261110999856],
            [126.951225000000164, -11.281927778],
            [127.517855556000114, -11.231919443999956],
            [127.531855153000066, -11.215317954999819],
            [127.784522222000163, -10.91525277800001],
            [127.967855556000103, -10.748586110999895],
            [128.201188889000122, -10.465252778],
            [128.202825730000228, -10.464707163999876],
            [128.226511082000258, -10.456812046999886],
            [128.23094552500018, -10.455333898999982],
            [128.263980260000125, -10.444322320999973],
            [128.301188889000088, -10.43191944399986],
            [129.017855556000171, -9.981919444000027],
            [129.160622401000154, -9.912997518499992],
            [129.501188889000133, -9.748586111],
            [130.101188889000156, -9.648586110999972],
            [130.4190486090001, -9.700129962499915],
            [130.717850000000254, -9.748583332999956],
            [130.91784989500016, -9.781916649499948],
            [131.027845369000062, -9.765250701999946],
            [131.467850000000197, -9.698583332999888],
            [131.473297967000207, -9.694951354999858],
            [131.51785, -9.66525],
            [131.658318901000115, -9.618427032999847],
            [131.717850000000254, -9.598583332999951],
            [131.867850000000175, -9.548583332999883],
            [131.90394503450014, -9.534145318999876],
            [131.951183333000159, -9.51525],
            [132.007369230000194, -9.485284104999891],
            [132.201172222000167, -9.381922221999872],
            [132.334505556000153, -9.331922221999903],
            [132.501172222000179, -9.265255556],
            [132.551172222000247, -9.231922221999866],
            [132.688303276500193, -9.147533880999873],
            [132.76783888900016, -9.098588888999885],
            [133.384511949000199, -8.881881074999853],
            [134.83450294400015, -9.415213954999857],
            [134.977238223000114, -9.382274874999922],
            [135.051171062000179, -9.365213362999924],
            [135.081104249000163, -9.350245677999965],
            [135.217839444000191, -9.281873090999866],
            [135.484496786000165, -9.131872750999918],
            [135.662486672000313, -9.196001178999865],
            [135.664468660000097, -9.196715274999974],
            [137.751150673000126, -9.948531236999884],
            [138.097169321500161, -10.096823052499914],
            [138.217817261000135, -10.148528652999914],
            [138.345798091500086, -10.224153872999864],
            [138.584484752000236, -10.365196332999915],
            [138.60749503700012, -10.380534935999918],
            [138.634484421000167, -10.398525996999908],
            [139.2011408380001, -10.831851914999916],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "AUS", Country: "Australia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [160.625110768428755, -51.176804623571407],
            [160.633584914000068, -51.183369891000041],
            [160.641389276000183, -51.189408668999917],
            [160.658756468000178, -51.202846872999977],
            [160.669336930000128, -51.211033711999946],
            [160.678552223000196, -51.218164224],
            [160.694518178000209, -51.230518193999963],
            [160.705440294000113, -51.238969394000044],
            [160.729836463000112, -51.257846406999988],
            [160.739441872000185, -51.265278778999971],
            [160.752319524000171, -51.275243114000013],
            [160.832256114000216, -51.337095612000027],
            [160.942384178000253, -51.422309351000017],
            [160.963020240000191, -51.438276906999924],
            [161.079038089000193, -51.526446822],
            [161.33114739800024, -51.718041966],
            [161.516408620000192, -51.858834665999979],
            [161.635761877000135, -51.949539386999987],
            [161.736760646000192, -52.02629527399997],
            [161.953098063000226, -52.190704905000032],
            [161.992023409000041, -52.219843823999945],
            [162.052063479000111, -52.264788901],
            [162.055697539000136, -52.267498567999937],
            [162.316523948000139, -52.461978728],
            [162.391802801000125, -52.518125550999983],
            [162.6084112180001, -52.679683198999975],
            [162.636161843000167, -52.700180643999964],
            [162.74521298900018, -52.780729095000034],
            [162.764835443000067, -52.795170282999948],
            [162.922706276000184, -52.907978683999978],
            [163.070900190000231, -53.013872332999959],
            [163.073463159000113, -53.015703731999963],
            [163.428386069000197, -53.269317939],
            [163.6471063070002, -53.425606974999972],
            [164.053718848000159, -53.716156596000012],
            [164.122632922000122, -53.76435868299999],
            [164.245011723000204, -53.849956781999985],
            [164.271373471000089, -53.900492868],
            [164.438960582000078, -54.221761275999924],
            [164.444850507000154, -54.233052398999973],
            [164.650285471000217, -54.618729378000019],
            [164.661485335000094, -54.639755641999976],
            [164.677839913000213, -54.670459198999943],
            [164.680538048000102, -54.675460830999981],
            [164.691234608666804, -54.695265005333319],
            [164.690952653000096, -54.69857069900003],
            [164.689256653000172, -54.713791698999984],
            [164.687439653000212, -54.729007698999965],
            [164.685500653000105, -54.74421869899998],
            [164.683439653000192, -54.759424699000029],
            [164.681256653000133, -54.774624699000022],
            [164.678951653000098, -54.789818698999959],
            [164.677068653000191, -54.801676699000012],
            [164.675111653000187, -54.813530699],
            [164.673079653000087, -54.825380698999929],
            [164.670489653000203, -54.840151698999954],
            [164.667783653000043, -54.854915698999932],
            [164.664961653000233, -54.869672699000041],
            [164.662023653000148, -54.884422699000012],
            [164.658968653000244, -54.899164699000039],
            [164.655798653000119, -54.913897699000024],
            [164.652511653000118, -54.928622698999973],
            [164.649107653000129, -54.943339698999971],
            [164.646941653000141, -54.956082698999978],
            [164.64469065300014, -54.96882069899992],
            [164.642351653000247, -54.981552699],
            [164.640140653000259, -54.994616699],
            [164.637838653000045, -55.007674698999956],
            [164.635445653000119, -55.020726699000036],
            [164.63301765300011, -55.035523698999988],
            [164.630472653000226, -55.050314698999983],
            [164.627811653000236, -55.06509769900002],
            [164.625032653000147, -55.079874699],
            [164.622137653000237, -55.09464369899996],
            [164.619125653000168, -55.109404699000045],
            [164.615995653000169, -55.124158699],
            [164.612748653000125, -55.138903699],
            [164.609385653000203, -55.153639698999967],
            [164.60590365300007, -55.168367698999987],
            [164.602766653000202, -55.182077698999976],
            [164.599527653000251, -55.195779699000013],
            [164.596186653000046, -55.209473699000014],
            [164.592744653000096, -55.223159698999972],
            [164.589200653000063, -55.236836699999984],
            [164.586630653000128, -55.251902699999988],
            [164.583938653000047, -55.266961699999953],
            [164.5811256530001, -55.282013699999958],
            [164.57818965300018, -55.297057700000011],
            [164.575131653000113, -55.312093699999934],
            [164.57195165300007, -55.3271207],
            [164.568649653000165, -55.342140700000023],
            [164.565225653000169, -55.357150700000012],
            [164.561678653000143, -55.372152699999965],
            [164.558009653000255, -55.387144699999979],
            [164.554217653000109, -55.402126699999968],
            [164.5503036530001, -55.417098699999926],
            [164.546266653000117, -55.432059700000039],
            [164.542107653000159, -55.447010700000035],
            [164.537825653000169, -55.461950699999917],
            [164.533420653000206, -55.476878700000057],
            [164.528893653000097, -55.4917947],
            [164.52424265300013, -55.5066997],
            [164.519469653000186, -55.521591699999917],
            [164.514573653000099, -55.5364707],
            [164.509554653000208, -55.551337699999969],
            [164.504412653000117, -55.566190699999943],
            [164.499147653000051, -55.5810297],
            [164.493759653000183, -55.595855699999952],
            [164.488248653000113, -55.6106667],
            [164.48261465300007, -55.625462699999964],
            [164.476857653000167, -55.640244700000011],
            [164.470976653000179, -55.655010699999977],
            [164.464973653000158, -55.669761700000031],
            [164.458847653000106, -55.684496700000011],
            [164.452597653000254, -55.6992147],
            [164.446225653000084, -55.7139167],
            [164.442556653000253, -55.728416699999983],
            [164.438772653000257, -55.742906699999942],
            [164.434872653000156, -55.757387700000038],
            [164.430857653000118, -55.771858699999932],
            [164.426725653000091, -55.786318699999981],
            [164.42247865300024, -55.8007687],
            [164.418114653000117, -55.8152077],
            [164.413635653000171, -55.829635699999983],
            [164.409040653000119, -55.84405170000003],
            [164.404328653000249, -55.858456699999969],
            [164.399500653000104, -55.872849699999982],
            [164.394293653000119, -55.888005699999979],
            [164.388956653000122, -55.9031487],
            [164.383489653000225, -55.918276700000035],
            [164.377894653000141, -55.933390700000018],
            [164.372170653000097, -55.948488700000013],
            [164.366316653000155, -55.963571699999918],
            [164.360334653000251, -55.9786397],
            [164.354222653000107, -55.9936907],
            [164.347981653000119, -56.008725699999943],
            [164.341611653000115, -56.023744699999966],
            [164.335111653000155, -56.038745699999929],
            [164.32848365300012, -56.0537297],
            [164.321725653000072, -56.068695699999985],
            [164.31483865300018, -56.0836437],
            [164.307821653000104, -56.098572700000041],
            [164.300676653000181, -56.11348370000001],
            [164.293401653000188, -56.128374699999931],
            [164.285997653000237, -56.143247699999954],
            [164.2784646530001, -56.158099700000022],
            [164.270802653000118, -56.172931699999943],
            [164.263010653000123, -56.187743699999984],
            [164.255090653000053, -56.202533699999982],
            [164.247041653000196, -56.2173037],
            [164.238862653000155, -56.2320517],
            [164.230555653000209, -56.246777699999939],
            [164.222119653000135, -56.261481699999926],
            [164.213553653000162, -56.276163700000048],
            [164.204859653000227, -56.290822700000042],
            [164.196037653000104, -56.3054577],
            [164.187085653000082, -56.320069701000023],
            [164.178005653000099, -56.334657701],
            [164.168796653000101, -56.34922070100005],
            [164.159459653000198, -56.363759700999971],
            [164.149993653000166, -56.378273700999962],
            [164.140399653000117, -56.392762701],
            [164.130677653000049, -56.407225700999952],
            [164.120826653000137, -56.421663700999957],
            [164.110848653000204, -56.436074700999953],
            [164.100741653000256, -56.450458700999931],
            [164.090506653000119, -56.464815700999985],
            [164.080144653000133, -56.479145700999936],
            [164.069653653000188, -56.493448700999963],
            [164.059035653000166, -56.507722700999985],
            [164.04829065300018, -56.521968701],
            [164.037417653000176, -56.536186701000013],
            [164.026416653000155, -56.550374700999939],
            [164.01528965300011, -56.564533700999952],
            [164.004034653000105, -56.578662700999971],
            [163.99265365300019, -56.592762701],
            [163.981144653000143, -56.606831701000019],
            [163.969509653000074, -56.620869700999968],
            [163.957747653000212, -56.634876700999925],
            [163.945859653000099, -56.648852700999988],
            [163.934213653000171, -56.662402700999969],
            [163.922448653000089, -56.675921700999965],
            [163.910563653000196, -56.689409700999974],
            [163.898560653000203, -56.702867700999981],
            [163.886439653000167, -56.716294701],
            [163.874199653000204, -56.729689701000041],
            [163.861840653000087, -56.743053701],
            [163.849364653000151, -56.756384700999988],
            [163.836769653000061, -56.769684700999981],
            [163.82405765300021, -56.782951701],
            [163.811227653000202, -56.796185700999949],
            [163.798279653000208, -56.809385700999925],
            [163.785214653000111, -56.822553701000011],
            [163.772032653000196, -56.835686701000043],
            [163.758733653000121, -56.848785700999926],
            [163.745317652000125, -56.861850701000016],
            [163.731785652000184, -56.874880700999967],
            [163.717888652000198, -56.888112701000018],
            [163.70387065200012, -56.901306700999946],
            [163.689732652000231, -56.914464701],
            [163.675474652000133, -56.927585700999927],
            [163.661096652000111, -56.940668701],
            [163.646599652000106, -56.953713701000034],
            [163.631982652000119, -56.966720701000028],
            [163.617246652000148, -56.979688701],
            [163.602391652000193, -56.992618701000019],
            [163.587417652000255, -57.005508700999933],
            [163.572325652000046, -57.018359700999987],
            [163.557115652000192, -57.031170700999937],
            [163.541786652000184, -57.043942700999942],
            [163.526340652000187, -57.056673701000015],
            [163.51077665200026, -57.069363700999986],
            [163.495095652000174, -57.08201270099994],
            [163.479297652000099, -57.094620700999968],
            [163.463382652000092, -57.107187700999972],
            [163.447351652000094, -57.119711700999979],
            [163.431203652000164, -57.13219370099997],
            [163.41494065200007, -57.144633701000039],
            [163.398561652000041, -57.157030700999925],
            [163.382066652000191, -57.169384700999984],
            [163.36545765200006, -57.181694700999955],
            [163.348733652000107, -57.193961701],
            [163.331894652000216, -57.206184700999977],
            [163.314941652000101, -57.218362700999947],
            [163.297874652000104, -57.230496700999922],
            [163.280694652000165, -57.242585701],
            [163.263400652000172, -57.254628700999987],
            [163.245994652000178, -57.266626701],
            [163.228474652000074, -57.278579701],
            [163.210843652000193, -57.29048570100003],
            [163.193099652000143, -57.302345700999979],
            [163.175244652000202, -57.314158700999954],
            [163.157277652000147, -57.325924701000034],
            [163.139200652000142, -57.337642700999957],
            [163.121012652000189, -57.34931470099999],
            [163.102713652000119, -57.360937700999962],
            [163.084305652000154, -57.372512700999955],
            [163.065787652000182, -57.384039701999981],
            [163.047159652000147, -57.395517701999943],
            [163.028423652000157, -57.406946702000027],
            [163.009783652000266, -57.418203702],
            [162.991038652000071, -57.42941170200001],
            [162.972186652000147, -57.440571701999957],
            [162.953230652000258, -57.451682702000021],
            [162.934169652000179, -57.462743701999941],
            [162.915004652000135, -57.473755701999984],
            [162.895735652000184, -57.484717701999976],
            [162.876362652000154, -57.495629702],
            [162.856885652000216, -57.506490702],
            [162.837306652000194, -57.517301701999926],
            [162.817625652000146, -57.528060702],
            [162.79784165200013, -57.538769702000018],
            [162.777956652000199, -57.549426702],
            [162.757969652000071, -57.560031701999939],
            [162.737882652000195, -57.570584702000019],
            [162.717694652000233, -57.581085701999967],
            [162.697406652000183, -57.591533701999978],
            [162.677019652000155, -57.601929701999943],
            [162.656532651000163, -57.612271701999973],
            [162.635947651000123, -57.622560701999973],
            [162.61526365100022, -57.632796701999936],
            [162.594481651000052, -57.642977701999968],
            [162.57360265100013, -57.653105701999962],
            [162.552625651000227, -57.663178702000032],
            [162.531553651000223, -57.673197701999982],
            [162.510383651000183, -57.683161702],
            [162.489119651000209, -57.693070702],
            [162.467582651000072, -57.703058701999957],
            [162.445948651000123, -57.712990702],
            [162.424216651000194, -57.722864702000024],
            [162.402389651000163, -57.732682702000041],
            [162.380466651000262, -57.742442701999963],
            [162.358448651000145, -57.752144701999974],
            [162.336335651000155, -57.76178970199998],
            [162.314128651000118, -57.771375701999986],
            [162.291827651000204, -57.780903701999989],
            [162.269433651000128, -57.790372702],
            [162.246946651000115, -57.799782702],
            [162.224367651000108, -57.809134701999923],
            [162.201697651000046, -57.818425701999942],
            [162.17893565100016, -57.827658701999965],
            [162.156083651000046, -57.836830702],
            [162.133140651000218, -57.845942701999952],
            [162.110108651000047, -57.854994702],
            [162.086987651000214, -57.863985701999987],
            [162.063777651000152, -57.872916701999976],
            [162.040479651000084, -57.881785701999988],
            [162.017094651000178, -57.890594702],
            [161.993621651000097, -57.899340701999968],
            [161.970063651000117, -57.908025702000032],
            [161.946418651000187, -57.916648702000032],
            [161.922688651000186, -57.925209701999961],
            [161.898874651000114, -57.933708702],
            [161.874975651000256, -57.942143701999981],
            [161.850993651000152, -57.950516701999987],
            [161.826928651000202, -57.958826702000025],
            [161.802780651000063, -57.967073702],
            [161.778550651000074, -57.975256702],
            [161.75423965100012, -57.983375701999954],
            [161.729847651000199, -57.991431701999929],
            [161.705375651000082, -57.999422701999947],
            [161.680824651000165, -58.007349702],
            [161.656193651000109, -58.015211701999917],
            [161.631485651000077, -58.023009701999953],
            [161.606698651000187, -58.030741701999943],
            [161.581834650000104, -58.038409701999974],
            [161.55689465000026, -58.046010701999961],
            [161.531878650000152, -58.053547701999989],
            [161.506786650000237, -58.061017701999972],
            [161.481619650000226, -58.068422702],
            [161.456379650000059, -58.07576070199999],
            [161.431065650000079, -58.083032702000025],
            [161.405678650000169, -58.090238702000022],
            [161.3802196500001, -58.097377701999974],
            [161.354688650000099, -58.104448702],
            [161.329086650000164, -58.111453701999963],
            [161.303414650000121, -58.118390702],
            [161.277672650000255, -58.125260701999984],
            [161.251861650000109, -58.132062702000034],
            [161.225982650000248, -58.138796701999965],
            [161.200034650000049, -58.145462702000039],
            [161.174020650000131, -58.152060702],
            [161.147939650000097, -58.158589702000022],
            [161.121793650000114, -58.165050702],
            [161.095581650000241, -58.171442701999979],
            [161.069305650000075, -58.177765701999917],
            [161.042965650000184, -58.184019702000015],
            [161.01656165000017, -58.190204702],
            [160.99009665000014, -58.196319702000046],
            [160.963568650000155, -58.20236570199998],
            [160.93697965000004, -58.208341702],
            [160.910330650000247, -58.21424770199998],
            [160.883621650000208, -58.220083701999947],
            [160.85685365000009, -58.225848702],
            [160.830027650000062, -58.231544702000015],
            [160.80314365000018, -58.237168702000034],
            [160.775837650000227, -58.242797702000018],
            [160.748475650000074, -58.248354702],
            [160.721054650000184, -58.253837701999977],
            [160.693578650000205, -58.259247702000053],
            [160.666046650000197, -58.264584702000036],
            [160.638459650000158, -58.269848701999926],
            [160.610818650000198, -58.275038702],
            [160.583123650000203, -58.280154702],
            [160.555375650000173, -58.285197702],
            [160.5275766500001, -58.290165702],
            [160.499725649000169, -58.295060702],
            [160.471824649000183, -58.299880701999932],
            [160.443873649000153, -58.30462670199995],
            [160.415874649000131, -58.309297701999988],
            [160.387826649000232, -58.313894702000027],
            [160.359730649000113, -58.318416701999986],
            [160.331588649000167, -58.322863701999964],
            [160.303400649000224, -58.327235701999946],
            [160.275167649000224, -58.331532701999947],
            [160.246889649000224, -58.335753701999963],
            [160.218568649000218, -58.33990070199998],
            [160.19020364900021, -58.343970702000028],
            [160.161797649000192, -58.347966701999987],
            [160.13335064900005, -58.351885701999976],
            [160.104861649000185, -58.355729701999969],
            [160.076334649000188, -58.359496702],
            [160.047767649000235, -58.363188701999938],
            [160.019162649000265, -58.366804701999989],
            [159.990519649000163, -58.370343701999971],
            [159.961840649000209, -58.373806701999975],
            [159.933125649000118, -58.377192702],
            [159.904375649000116, -58.380502701999959],
            [159.875591649000199, -58.383736702000022],
            [159.846773649000085, -58.38689270199994],
            [159.817923649000107, -58.389972701999959],
            [159.789041649000211, -58.392975702],
            [159.760128649000166, -58.395901701999989],
            [159.731184649000198, -58.398750702],
            [159.702211649000247, -58.401522702000022],
            [159.673209649000199, -58.404216702],
            [159.644180649000219, -58.406833701999986],
            [159.615123649000253, -58.409373702],
            [159.586040649000182, -58.411835701999962],
            [159.556932649000061, -58.41422070200003],
            [159.527799649000116, -58.416528701999951],
            [159.498643649000115, -58.418757702],
            [159.469463649000176, -58.420909701999967],
            [159.440261648000188, -58.422983701999975],
            [159.411038648000073, -58.424980702],
            [159.381794648000238, -58.426898701999988],
            [159.352530648000112, -58.428739701999987],
            [159.323247648000262, -58.430501702000029],
            [159.29394764800017, -58.432186702],
            [159.264629648000181, -58.433792702000012],
            [159.235294648000178, -58.435321701999953],
            [159.205944648000042, -58.436771702000023],
            [159.176579648000171, -58.438143701999948],
            [159.147200648000108, -58.439436702],
            [159.117808648000192, -58.440652701999973],
            [159.088403648000195, -58.441789702],
            [159.058987648000112, -58.442847701999959],
            [159.029560648000171, -58.443828701999948],
            [159.000123648000255, -58.444730701999973],
            [158.970677648000247, -58.445553702000034],
            [158.941223648000204, -58.446298702000036],
            [158.911761648000123, -58.446965701999979],
            [158.882293648000228, -58.44755270199996],
            [158.852819648000121, -58.448062701999973],
            [158.823340648000141, -58.448493702000015],
            [158.79385764800017, -58.448845702],
            [158.76437164800015, -58.449119702000033],
            [158.734882648000138, -58.449314702],
            [158.705392648000128, -58.449431702],
            [158.675900648000123, -58.449469702000037],
            [158.646409648000116, -58.44942970200001],
            [158.616919648000106, -58.449310701999934],
            [158.587430648000264, -58.449112701999987],
            [158.557944648000245, -58.448836701999973],
            [158.528461648000103, -58.448481702],
            [158.498982648000123, -58.448048701999966],
            [158.469508648000186, -58.447536701999972],
            [158.440040648000121, -58.446946702],
            [158.410579648000208, -58.446277701999982],
            [158.381125648000165, -58.445530702],
            [158.351679647000225, -58.444704701999953],
            [158.322243647000079, -58.443800702000026],
            [158.292816647000137, -58.442817701999964],
            [158.263400647000111, -58.441756702000013],
            [158.234239647000237, -58.440628702000033],
            [158.205091647000103, -58.439422701999987],
            [158.175955647000166, -58.438139701999972],
            [158.146834647000134, -58.436779701999974],
            [158.117726647000183, -58.435342702],
            [158.088635647000075, -58.433829701999962],
            [158.0595596470001, -58.43223870199995],
            [158.030500647000139, -58.430571701999959],
            [158.001460647000073, -58.428827701999985],
            [157.972437647000248, -58.427006702000043],
            [157.9434356470002, -58.425109702000022],
            [157.914452647000218, -58.423135702000032],
            [157.885490647000182, -58.421084701999973],
            [157.856550647000205, -58.418957701999929],
            [157.827633647000113, -58.416754702],
            [157.79873964700019, -58.414474702],
            [157.769869647000149, -58.412118702000015],
            [157.741024647000046, -58.409686702000052],
            [157.712205647000047, -58.407177702000027],
            [157.683412647000154, -58.404593702],
            [157.654646647000192, -58.401932702000018],
            [157.625909647000213, -58.399195702000043],
            [157.597200647000165, -58.396383701999987],
            [157.568521647000097, -58.393495701999953],
            [157.539873647000121, -58.390531702000025],
            [157.511256647000238, -58.387491702000027],
            [157.482670647000219, -58.384376701999955],
            [157.454118647000115, -58.381185701999982],
            [157.425598647000157, -58.377919702000028],
            [157.397114647000166, -58.374578702],
            [157.368664647000145, -58.371161701999974],
            [157.34025064700009, -58.367669701999972],
            [157.311873647000226, -58.364103701999973],
            [157.283533646000166, -58.360461701999988],
            [157.255231646000226, -58.356745702000012],
            [157.226968646000131, -58.352954701999963],
            [157.198745646000049, -58.34908870200001],
            [157.170562646000263, -58.345148701999975],
            [157.142420646000147, -58.341133702000036],
            [157.114321646000207, -58.337044702000021],
            [157.086264646000103, -58.332881702],
            [157.058250646000175, -58.328644702],
            [157.030280646000193, -58.324333702],
            [157.002356646000152, -58.319948702],
            [156.974477646000224, -58.315490702],
            [156.946645646000235, -58.31095870199993],
            [156.918859646000186, -58.306352702000034],
            [156.891122646000184, -58.30167370199996],
            [156.863433646000232, -58.29692170199997],
            [156.835794646000096, -58.292096701999988],
            [156.808204646000178, -58.287198702],
            [156.780666646000185, -58.282227702000014],
            [156.753179646000177, -58.277183701999938],
            [156.725744646000209, -58.272067701999944],
            [156.698362646000163, -58.266879702000033],
            [156.671034646000152, -58.261618702000028],
            [156.643761646000172, -58.256285702000021],
            [156.616542646000227, -58.250881702],
            [156.589380646000194, -58.245404701999981],
            [156.562274646000247, -58.239856701999948],
            [156.535225646000157, -58.234236702],
            [156.508234646000147, -58.228545701999948],
            [156.481302646000046, -58.222783701999973],
            [156.454430646000191, -58.216950701999984],
            [156.427617646000186, -58.211046701999983],
            [156.400865646000142, -58.205071701999969],
            [156.374175646000168, -58.199025702000029],
            [156.347547646000152, -58.192909701999987],
            [156.320981646000149, -58.186723701999924],
            [156.294479646000099, -58.180467702000016],
            [156.268042646000168, -58.174141702],
            [156.241669646000247, -58.167746701999967],
            [156.21536264500017, -58.161280702],
            [156.189121645000199, -58.154746702000018],
            [156.162946645000119, -58.148142702],
            [156.136840645000149, -58.141469701999974],
            [156.110773645000137, -58.134721702000036],
            [156.084776645000119, -58.127905701999985],
            [156.058849645000208, -58.121019702],
            [156.032991645000067, -58.114066701999981],
            [156.007205645000198, -58.107044701999932],
            [155.98149064500015, -58.099954701999948],
            [155.955848645000202, -58.092796702000015],
            [155.930278645000186, -58.085571702000053],
            [155.904782645000154, -58.078278701999963],
            [155.879360645000105, -58.070918702000021],
            [155.854014645000206, -58.063490701999953],
            [155.828742645000062, -58.055996702000023],
            [155.803547645000236, -58.048435701999971],
            [155.778428645000218, -58.040808701999957],
            [155.753386645000177, -58.033114702],
            [155.728423645000106, -58.025355702],
            [155.703538645000179, -58.017529701999948],
            [155.678732645000224, -58.009638701999947],
            [155.654006645000123, -58.001682701999982],
            [155.629361645000159, -57.993660701999971],
            [155.604796645000221, -57.985573702],
            [155.580313645000075, -57.977422701999984],
            [155.55591264500012, -57.969206702],
            [155.531594645000126, -57.960925701999969],
            [155.50735964500015, -57.95258070199997],
            [155.48320864500019, -57.944172702],
            [155.459142645000185, -57.93570070199997],
            [155.43516164500025, -57.927164701999978],
            [155.4112656450001, -57.918565702000016],
            [155.387456645000185, -57.909903701999987],
            [155.363733645000053, -57.901179701999986],
            [155.340097645000156, -57.892391701999927],
            [155.316550645000262, -57.883542701999971],
            [155.293091645000203, -57.874630701999955],
            [155.269721645000203, -57.86565770199995],
            [155.246440645000206, -57.856622701999974],
            [155.223250645000206, -57.847525702000013],
            [155.200150645000264, -57.838368701999975],
            [155.177142645000202, -57.82914970199996],
            [155.154225644000206, -57.819870701999953],
            [155.131400644000195, -57.810531701999956],
            [155.108668644000232, -57.801131701999978],
            [155.086029644000149, -57.791672702],
            [155.063484644000113, -57.782153702000031],
            [155.041034644000234, -57.772574701999979],
            [155.018678644000119, -57.762936701999934],
            [154.996417644000218, -57.753240701999978],
            [154.974253644000186, -57.74348470199994],
            [154.952184644000198, -57.733671701999981],
            [154.930213644000247, -57.723799701999937],
            [154.908338644000224, -57.713869701999975],
            [154.886562644000122, -57.703882702],
            [154.864883644000173, -57.693837701999954],
            [154.843304644000142, -57.683735701999971],
            [154.821823644000204, -57.673577701999982],
            [154.800443644000126, -57.663361701999982],
            [154.779162644000138, -57.653090702000057],
            [154.757982644000236, -57.642762702000027],
            [154.736903644000193, -57.63237970199998],
            [154.715926644000234, -57.621940702000011],
            [154.69505064400019, -57.611446702000016],
            [154.674277644000227, -57.600897702],
            [154.653607644000232, -57.590293701999961],
            [154.633040644000204, -57.579634702],
            [154.612577644000197, -57.568922702],
            [154.592218644000042, -57.558156701999962],
            [154.57196464400019, -57.547336702],
            [154.551815644000186, -57.536463702],
            [154.531771644000145, -57.525537701999966],
            [154.511833644000177, -57.514558701999988],
            [154.492001644000169, -57.503526701999974],
            [154.472276644000232, -57.492442702000012],
            [154.452657644000197, -57.481307702],
            [154.433147644000172, -57.47011970199997],
            [154.413744644000161, -57.458881702000056],
            [154.394449644000161, -57.447591702000011],
            [154.375264644000168, -57.436251701999922],
            [154.356187644000187, -57.424860701999961],
            [154.337219644000101, -57.413419701999949],
            [154.318362644000132, -57.401928701999971],
            [154.299614644000116, -57.390387701999948],
            [154.280977644000046, -57.378797700999954],
            [154.262451644000095, -57.367158700999987],
            [154.244036644000204, -57.355470700999966],
            [154.225733644000144, -57.343734700999974],
            [154.207541644000202, -57.331950701],
            [154.189462644000145, -57.32011870099997],
            [154.171496644000086, -57.308238700999958],
            [154.153642644000143, -57.296311700999972],
            [154.135902644000197, -57.284337701],
            [154.118275644000136, -57.272316700999959],
            [154.100763644000068, -57.260249701000021],
            [154.083365644000111, -57.248136701],
            [154.06608164300016, -57.23597770100001],
            [154.04891264300025, -57.223773701000013],
            [154.031859643000161, -57.21152470100003],
            [154.014921643000235, -57.199230700999962],
            [153.998099643000074, -57.186891700999986],
            [153.981393643000075, -57.174508701000015],
            [153.964803643000181, -57.162081701000048],
            [153.948330643000105, -57.149611700999984],
            [153.931975643000129, -57.137097701],
            [153.915736643000145, -57.124540700999937],
            [153.899615643000146, -57.111940701000044],
            [153.883612643000248, -57.09929970099995],
            [153.867727643000222, -57.086615701000035],
            [153.851961643000124, -57.073889701000013],
            [153.836313643000182, -57.061122700999981],
            [153.820847643000121, -57.048367700999961],
            [153.805499643000218, -57.035571701],
            [153.790269643000244, -57.022735700999952],
            [153.77515864300014, -57.009859701000046],
            [153.760166643000133, -56.996943701000028],
            [153.74529364300011, -56.98398970099997],
            [153.730539643000071, -56.970995700999985],
            [153.715906643000181, -56.957963701000047],
            [153.701392643000219, -56.944893701],
            [153.68699864300018, -56.931784701],
            [153.67272464300018, -56.918638700999949],
            [153.658571643000215, -56.905455700999951],
            [153.644539643000059, -56.892235701],
            [153.630627643000054, -56.87897870099993],
            [153.616837643000196, -56.865684700999985],
            [153.603168643000203, -56.852355701],
            [153.589621643000129, -56.838989700999953],
            [153.576195643000261, -56.825589700999949],
            [153.562891643000199, -56.812153700999978],
            [153.549710643000111, -56.798682700999962],
            [153.536650643000172, -56.785177700999967],
            [153.523713643000207, -56.771638701],
            [153.510899643000101, -56.758065700999985],
            [153.498208643000197, -56.744459700999982],
            [153.485639643000042, -56.730819701000016],
            [153.473194643000085, -56.717146700999976],
            [153.460872643000101, -56.70344170099996],
            [153.448673643000262, -56.689704701000046],
            [153.43659864300011, -56.675935700999972],
            [153.424647643000156, -56.662134701],
            [153.41281964300012, -56.648302701000041],
            [153.401116643000165, -56.634440701],
            [153.389537643000125, -56.620546700999974],
            [153.378082643000113, -56.606623700999947],
            [153.366752643000126, -56.59266970100002],
            [153.35554664300011, -56.578686701],
            [153.344464643000123, -56.56467370099999],
            [153.333508643000101, -56.550632700999969],
            [153.322676643000108, -56.536562701000037],
            [153.311970643000251, -56.522464700999919],
            [153.301389643000249, -56.508338700999971],
            [153.290932643000048, -56.494184700999931],
            [153.280602643000208, -56.480003701000051],
            [153.270396643000169, -56.465795700999976],
            [153.260316643000039, -56.451560700999984],
            [153.250362643000045, -56.437299700999965],
            [153.240534643000188, -56.423012700999934],
            [153.230831643000187, -56.408699700999968],
            [153.221254643000208, -56.394361700999973],
            [153.211803643000195, -56.379998701000048],
            [153.202479643000089, -56.365611701],
            [153.193280643000236, -56.351199701000013],
            [153.184207643000121, -56.336763701],
            [153.175261643000141, -56.322303700999946],
            [153.166441643000184, -56.307820701000033],
            [153.157747643000249, -56.2933147],
            [153.149180643000108, -56.278785700000014],
            [153.140749643000191, -56.264266699999965],
            [153.132445643000182, -56.249725699999964],
            [153.124266643000084, -56.235161699999928],
            [153.116213643000179, -56.2205777],
            [153.108287643000182, -56.205972699999954],
            [153.100486643000096, -56.191346699999933],
            [153.092812643000201, -56.17670070000004],
            [153.085264643000215, -56.1620347],
            [153.077842643000139, -56.147348699999988],
            [153.070546643000142, -56.13264370000001],
            [153.063377643000109, -56.117919699999966],
            [153.056334643000099, -56.103176699999949],
            [153.049417643000226, -56.088415700000041],
            [153.042626643000204, -56.073636699999973],
            [153.035962643000204, -56.058840700000012],
            [153.029424643000226, -56.044026699999982],
            [153.023012643000101, -56.029195699999967],
            [153.016727643000223, -56.014348699999964],
            [153.010568643000141, -55.999484699999968],
            [153.00453564200015, -55.984604699999984],
            [152.99862864200017, -55.9697097],
            [152.992848642000098, -55.954798700000033],
            [152.987194642000105, -55.939872699999974],
            [152.981666642000135, -55.9249327],
            [152.976264642000189, -55.909977700000027],
            [152.970989642000092, -55.895008699999963],
            [152.965839642000134, -55.880026699999974],
            [152.960816642000196, -55.865030699999984],
            [152.955919642000111, -55.850021699999985],
            [152.951148642000163, -55.834999699999976],
            [152.946502642000127, -55.819966699999945],
            [152.941983642000167, -55.8049207],
            [152.937590642000117, -55.789862700000029],
            [152.933322642000206, -55.774793700000039],
            [152.929180642000262, -55.759713700000027],
            [152.925164642000055, -55.7446227],
            [152.921274642000157, -55.729521700000021],
            [152.917509642000113, -55.714410700000016],
            [152.913870642000205, -55.699289699999987],
            [152.910356642000153, -55.684159700000016],
            [152.906967642000069, -55.6690207],
            [152.903704642000179, -55.653871699999968],
            [152.900566642000143, -55.638715699999977],
            [152.897554642000074, -55.623550699999946],
            [152.894666642000146, -55.6083787],
            [152.891904642000128, -55.593198700000023],
            [152.889266642000194, -55.578011699999955],
            [152.886753642000173, -55.5628187],
            [152.88436564200012, -55.547618700000015],
            [152.882102642000206, -55.532411699999976],
            [152.879963642000149, -55.517199699999964],
            [152.877923642000184, -55.501781699999967],
            [152.876009642000184, -55.4863577],
            [152.8742246420002, -55.4709287],
            [152.872566642000066, -55.455494699999988],
            [152.871035642000123, -55.440056700000021],
            [152.869631642000144, -55.424615699999983],
            [152.868355642000239, -55.409169699999971],
            [152.86720564200013, -55.393721699999979],
            [152.866183642000038, -55.3782697],
            [152.865287642000254, -55.362815699999956],
            [152.864517642000038, -55.347359700000013],
            [152.863875642000124, -55.331901699999989],
            [152.863358642000236, -55.316441699999977],
            [152.862968642000141, -55.300980699999968],
            [152.862704642000125, -55.285518699999969],
            [152.862565642000192, -55.270055699999979],
            [152.862553642000165, -55.2545937],
            [152.862666642000107, -55.239130699999983],
            [152.862905642000243, -55.223668698999987],
            [152.863269642000176, -55.208206698999987],
            [152.863758642000136, -55.192746698999976],
            [152.86437264200012, -55.177287699000047],
            [152.865111642000244, -55.161830699000014],
            [152.865975642000166, -55.146375698999975],
            [152.866963642000172, -55.13092269900001],
            [152.868076642000261, -55.115472698999938],
            [152.869313642000208, -55.100025699000021],
            [152.870673642000185, -55.084581699],
            [152.87215864200013, -55.069142698999954],
            [152.873767642000217, -55.053706698999974],
            [152.875499642000165, -55.038275698999961],
            [152.877354642000199, -55.022848699000015],
            [152.879333642000148, -55.007427698999948],
            [152.881434642000187, -54.992010698999941],
            [152.883659642000254, -54.976600698999981],
            [152.886005642000185, -54.961195698999987],
            [152.888475642000202, -54.945797698999954],
            [152.891066642000084, -54.930406698999967],
            [152.89378064200011, -54.915021699000036],
            [152.896615642000114, -54.899644698999964],
            [152.899572642000152, -54.884275698999929],
            [152.902651642000109, -54.868913698999947],
            [152.905850642000104, -54.853560699],
            [152.909171642000189, -54.838215699],
            [152.912613642000139, -54.822880698999931],
            [152.91617564200024, -54.807553699],
            [152.919857642000153, -54.792236699],
            [152.923660642000215, -54.776929698999957],
            [152.927583642000201, -54.761632699000018],
            [152.931625642000228, -54.746346699000014],
            [152.935787642000065, -54.731071699000033],
            [152.940069642000168, -54.715806699],
            [152.944469642000143, -54.700554698999959],
            [152.948988642000046, -54.685313698999948],
            [152.953626642000103, -54.670084698999958],
            [152.958383642000143, -54.654867698999979],
            [152.963257642000173, -54.63966369900001],
            [152.968250642000129, -54.624473698999957],
            [152.973360642000245, -54.609295699],
            [152.978588642000176, -54.594132698999964],
            [152.983933642000039, -54.578982698999937],
            [152.989394642000235, -54.563846699],
            [152.994973642000247, -54.548726699000049],
            [153.000668642000193, -54.533620699000018],
            [153.006480642000128, -54.518529698999984],
            [153.012408643000157, -54.503454699000031],
            [153.018451643000134, -54.48839469899999],
            [153.024610643000159, -54.47335169900002],
            [153.030595643000225, -54.459005698999988],
            [153.036685643000141, -54.444675698999944],
            [153.042880643000245, -54.430359699],
            [153.049180643000255, -54.416060698999949],
            [153.055583643000233, -54.401777698999979],
            [153.062090643000118, -54.387510699],
            [153.06870264300008, -54.373259699],
            [153.075416643000125, -54.359026699],
            [153.082234643000248, -54.344809698999988],
            [153.089155643000112, -54.33061069900004],
            [153.09617964300017, -54.316429698999983],
            [153.103305643000198, -54.302265699],
            [153.110534643000136, -54.288119699],
            [153.115141643000101, -54.273788698999958],
            [153.119852643000087, -54.259468699000038],
            [153.124667643000208, -54.245161698999944],
            [153.129585643000183, -54.230865698999963],
            [153.134607643000123, -54.216582699],
            [153.139732643000144, -54.202312698999947],
            [153.145173643000106, -54.187340698999989],
            [153.150727643000181, -54.172382699000032],
            [153.156395643000195, -54.157440697999981],
            [153.162175643000154, -54.142512698000026],
            [153.168068643000225, -54.127600697999966],
            [153.174073643000071, -54.112703698],
            [153.1801916430002, -54.097823698000013],
            [153.186420643000162, -54.082959698000025],
            [153.192761643000182, -54.068112697999929],
            [153.199213643000206, -54.053281698],
            [153.205776643000121, -54.038468697999967],
            [153.212450643000153, -54.023672698],
            [153.219235643000133, -54.008894697999935],
            [153.226129643000064, -53.994133698000027],
            [153.231655643000153, -53.980017698000019],
            [153.237282643000214, -53.965915698000011],
            [153.243010643000133, -53.951827697999924],
            [153.248837643000257, -53.937753697999923],
            [153.254763643000132, -53.923694698000013],
            [153.260789643000095, -53.909650698000014],
            [153.26691564300009, -53.895622698],
            [153.273140643000175, -53.881609698],
            [153.279463643000128, -53.867611697999976],
            [153.285885643000171, -53.853630697999947],
            [153.292406643000135, -53.839665698],
            [153.297846643000213, -53.82682269799998],
            [153.303369643000138, -53.813991697999974],
            [153.308975643000082, -53.801173697999985],
            [153.315580643000175, -53.786320697999926],
            [153.322296643000215, -53.771484697999938],
            [153.329123643000202, -53.75666769799993],
            [153.336060643000138, -53.741867698],
            [153.343107643000195, -53.727087697999934],
            [153.350264643000145, -53.712325698000029],
            [153.357531643000044, -53.69758369800001],
            [153.364907643000066, -53.682860697999956],
            [153.37239264300004, -53.668158697999957],
            [153.37998664300008, -53.653475698000022],
            [153.387688643000075, -53.638812698000052],
            [153.395499643000136, -53.624171697999948],
            [153.403418643000208, -53.609550697999985],
            [153.411444643000124, -53.594951697999981],
            [153.419578643000165, -53.580373698000031],
            [153.427819643000163, -53.56581769799994],
            [153.436167643000232, -53.551283697999985],
            [153.444622643000258, -53.536772697999979],
            [153.453183643000187, -53.522283698000017],
            [153.461851643000188, -53.507818698],
            [153.47062464300015, -53.49337669800002],
            [153.479503643000129, -53.478957697999988],
            [153.488487643000241, -53.464562697999988],
            [153.497576643000144, -53.450191698000019],
            [153.506770643000237, -53.435845697999987],
            [153.516068643000125, -53.421524697999978],
            [153.525470643000091, -53.407227698],
            [153.534977643000133, -53.392956697999963],
            [153.544586643000258, -53.378710698000035],
            [153.554299643000121, -53.364490697999948],
            [153.564116643000062, -53.350296697999966],
            [153.57403464300026, -53.336129698],
            [153.584056643000196, -53.32198869799997],
            [153.594179643000217, -53.307874698000035],
            [153.604404643000152, -53.293787698000031],
            [153.614731643000113, -53.279728698000028],
            [153.625159643000217, -53.265696697999957],
            [153.635688643000179, -53.251693697999968],
            [153.646317643000174, -53.237717698],
            [153.657047643000141, -53.223771697999936],
            [153.667877643000139, -53.209853697999968],
            [153.678807643000056, -53.195964698],
            [153.689836643000177, -53.182105698000029],
            [153.700964643000049, -53.168275697999967],
            [153.712191643000125, -53.154475698],
            [153.723516643000124, -53.140705698000012],
            [153.734940643000158, -53.126966697999933],
            [153.746462643000115, -53.113257698000027],
            [153.758081643000111, -53.099579698000021],
            [153.769797643000146, -53.085933698],
            [153.781610643000107, -53.072318696999965],
            [153.793520643000221, -53.058734697000013],
            [153.805527643000147, -53.045183697000034],
            [153.81762964300006, -53.031664696999947],
            [153.829827643000186, -53.018177696999935],
            [153.842120643000072, -53.004723696999989],
            [153.854508643000173, -52.991302697000023],
            [153.866991643000262, -52.977915697000022],
            [153.87956964300011, -52.964561697],
            [153.892240643000122, -52.95124069699996],
            [153.905005643000237, -52.937954696999967],
            [153.917864643000172, -52.924702697000036],
            [153.930816643000156, -52.911484697],
            [153.943860643000193, -52.898302697],
            [153.956997643000108, -52.885154696999962],
            [153.970226643000188, -52.872041696999986],
            [153.983547643000207, -52.858964696999969],
            [153.996959643000167, -52.845923697],
            [154.010463643000236, -52.83291869699999],
            [154.024057643000134, -52.819949697000027],
            [154.037741643000203, -52.807017696999928],
            [154.051516643000156, -52.794121696999966],
            [154.065381643000165, -52.781262696999953],
            [154.078169643000166, -52.768016696999986],
            [154.091049644000208, -52.75480469699999],
            [154.104022644000253, -52.741627696999963],
            [154.117087644000236, -52.728485696999982],
            [154.130243644000217, -52.71537869699997],
            [154.143491644000193, -52.702306697000012],
            [154.156830644000109, -52.689271697],
            [154.170260644000138, -52.676271696999969],
            [154.183780644000109, -52.663307696999972],
            [154.197390644000194, -52.650379696999941],
            [154.211090644000052, -52.637489697000028],
            [154.224879644000254, -52.624635696999988],
            [154.238758644000114, -52.61181869699999],
            [154.252725644000151, -52.59903969700003],
            [154.266780644000079, -52.58629769700002],
            [154.28092464400018, -52.573593697000042],
            [154.295155644000118, -52.560927697000018],
            [154.309474644000119, -52.548300696999931],
            [154.32388064400007, -52.535711696999968],
            [154.338372644000202, -52.523161697000035],
            [154.352951644000228, -52.510649696999955],
            [154.367616644000265, -52.498178696999986],
            [154.382366644000143, -52.485745696999963],
            [154.397202644000146, -52.473353696999958],
            [154.412123644000218, -52.461000696999982],
            [154.427128644000078, -52.448688697000023],
            [154.442218644000121, -52.436415697],
            [154.457391644000182, -52.424184697],
            [154.472649644000199, -52.411993697000014],
            [154.487989644000123, -52.399844696999956],
            [154.503412644000178, -52.387736697],
            [154.518918644000195, -52.375669696999971],
            [154.534507644000115, -52.363644697000048],
            [154.550177644000172, -52.351661696999955],
            [154.565928644000195, -52.339721696999952],
            [154.581761644000181, -52.327823697000056],
            [154.597674644000193, -52.315967697],
            [154.613668644000228, -52.30415469699993],
            [154.629742644000231, -52.29238469699996],
            [154.645896644000203, -52.280658697],
            [154.662129644000146, -52.26897569700003],
            [154.678441644000117, -52.257336696999978],
            [154.694832644000172, -52.245741697000014],
            [154.711301644000088, -52.23419069699996],
            [154.727848644000261, -52.222683697],
            [154.744472644000183, -52.21122169700002],
            [154.761174644000135, -52.199803696999957],
            [154.777953644000121, -52.18843169699997],
            [154.794808644000256, -52.177104696999976],
            [154.811739644000198, -52.165822696999975],
            [154.828746644000063, -52.154586696999964],
            [154.845829644000247, -52.143395697000031],
            [154.862986644000244, -52.132251696999987],
            [154.880218644000053, -52.121153697000025],
            [154.897525644000126, -52.110101696999955],
            [154.914905644000186, -52.099096696999958],
            [154.93236064400017, -52.088138697000034],
            [154.949887644000142, -52.077226697],
            [154.967487644000101, -52.066362696999946],
            [154.98516064400016, -52.055546696999954],
            [155.002904644000154, -52.044777697000036],
            [155.020721644000133, -52.034055697],
            [155.038609644000218, -52.023382696999946],
            [155.056567644000239, -52.012757697000033],
            [155.074597644000193, -52.002180696000011],
            [155.09269764400014, -51.99165269599996],
            [155.110866644000197, -51.981173696000049],
            [155.129106644000188, -51.970742695999938],
            [155.147418644000112, -51.96035469600001],
            [155.165799645000135, -51.950015695999966],
            [155.184248645000167, -51.939725695999975],
            [155.202766645000253, -51.929486696000026],
            [155.221351645000112, -51.919296695999961],
            [155.240004645000255, -51.909156696000039],
            [155.258724645000171, -51.899066695999984],
            [155.277510645000206, -51.889027695999978],
            [155.29636264500013, -51.879039696000021],
            [155.315281645000169, -51.869101696000023],
            [155.334265645000158, -51.859215695999971],
            [155.353313645000213, -51.849379695999978],
            [155.372427645000158, -51.839595695999932],
            [155.391605645000112, -51.829863696000011],
            [155.410847645000246, -51.820182695999961],
            [155.430152645000163, -51.81055369599995],
            [155.449521645000203, -51.800976695999978],
            [155.468952645000144, -51.79145169599996],
            [155.488446645000153, -51.781979695999972],
            [155.508001645000178, -51.772559696000023],
            [155.527619645000215, -51.763192696000019],
            [155.547297645000157, -51.753878695999958],
            [155.567037645000113, -51.74461769600002],
            [155.586837645000202, -51.735409696000026],
            [155.606697645000139, -51.726254695999977],
            [155.626617645000039, -51.717153695999954],
            [155.646596645000187, -51.708106695999959],
            [155.666634645000187, -51.699112696],
            [155.686731645000265, -51.690173695999967],
            [155.706886645000083, -51.68128869599996],
            [155.727099645000152, -51.672457695999988],
            [155.747369645000077, -51.663680696000036],
            [155.767696645000257, -51.654959695999928],
            [155.788080645000178, -51.646292695999932],
            [155.808520645000129, -51.637680695999954],
            [155.829016645000166, -51.629123696],
            [155.849567645000235, -51.620621695999979],
            [155.870173645000165, -51.612175695999973],
            [155.890834645000183, -51.603784696],
            [155.911550645000119, -51.595449695999932],
            [155.932319645000206, -51.587170695999973],
            [155.953142645000213, -51.578947695999943],
            [155.974017645000259, -51.570780696000021],
            [155.994946645000169, -51.562670695999927],
            [156.015927645000119, -51.554616696000025],
            [156.036960645000107, -51.54661869599996],
            [156.058044645000138, -51.538677696],
            [156.07917964500021, -51.530793695999947],
            [156.100366645000207, -51.522966696],
            [156.121602645000138, -51.515196695999968],
            [156.142889645000167, -51.507484695999942],
            [156.164225645000244, -51.499828696000016],
            [156.185611645000137, -51.492231696],
            [156.20704564500025, -51.484691696],
            [156.228527645000185, -51.477208696],
            [156.250058646000156, -51.469784696],
            [156.271636646000189, -51.462418696],
            [156.293261646000047, -51.455110696],
            [156.314933646000128, -51.447860696000014],
            [156.336652646000204, -51.440669696000022],
            [156.358416646000109, -51.433536696000026],
            [156.380227646000236, -51.426462695999945],
            [156.402082646000196, -51.419447696000034],
            [156.423982646000155, -51.412491696000032],
            [156.445927646000172, -51.405594696000023],
            [156.467915646000137, -51.398756696000014],
            [156.489948646000158, -51.391977696],
            [156.512023646000131, -51.38525869599998],
            [156.534142646000163, -51.378598696000047],
            [156.556302646000148, -51.37199869599992],
            [156.578505646000195, -51.365458695999969],
            [156.600749646000196, -51.358978696],
            [156.623035646000204, -51.352558696000031],
            [156.645361646000225, -51.346197695999962],
            [156.667728646000256, -51.339897695999973],
            [156.690135646000073, -51.33365869600005],
            [156.712582646000243, -51.32747869599995],
            [156.735067646000203, -51.321360696],
            [156.757592646000177, -51.315302695999968],
            [156.780155646000111, -51.309305696],
            [156.802756646000063, -51.303368696000021],
            [156.825394646000149, -51.297493695999933],
            [156.848070646000252, -51.291678696000012],
            [156.870783646000149, -51.285925695999978],
            [156.893532646000182, -51.280233696000025],
            [156.916317646000181, -51.274603696000042],
            [156.939137646000148, -51.269034695999963],
            [156.961993646000138, -51.263526696000042],
            [156.984884646000211, -51.258080696],
            [157.00780964600014, -51.252696696000044],
            [157.030768646000155, -51.247374695999973],
            [157.0537606460002, -51.242113695999969],
            [157.076786646000158, -51.236915696000032],
            [157.099844646000093, -51.231779695999982],
            [157.122935646000172, -51.226705696],
            [157.146058646000171, -51.221693696],
            [157.169212646000204, -51.216744695999964],
            [157.192398646000157, -51.211857696],
            [157.215614646000205, -51.207033696],
            [157.238861646000061, -51.20227169599999],
            [157.262137646000184, -51.197572695999952],
            [157.285443646000175, -51.192936695999968],
            [157.308779647000193, -51.188363695999961],
            [157.332142647000154, -51.183853696000014],
            [157.355535647000096, -51.179405695999961],
            [157.37895564700014, -51.175021695999959],
            [157.402402647000173, -51.170700696000019],
            [157.425877647000249, -51.166443695999952],
            [157.449378647000145, -51.162248695999956],
            [157.472906647000144, -51.158117696000019],
            [157.496460647000191, -51.154050695999956],
            [157.520039647000232, -51.150046695999954],
            [157.543643647000209, -51.146106696],
            [157.56727164700024, -51.142229695999944],
            [157.590924647000207, -51.138416696000021],
            [157.614601647000171, -51.13466769599998],
            [157.638146647000184, -51.130993696000019],
            [157.661714647000196, -51.127381696000029],
            [157.685304647000208, -51.123833696000013],
            [157.708916647000052, -51.120348695999965],
            [157.732550647000124, -51.116926695999979],
            [157.756205647000144, -51.113568695999959],
            [157.779881647000053, -51.110273696],
            [157.803577647000196, -51.107042696],
            [157.827293647000118, -51.103874695999984],
            [157.851029647000104, -51.100770696000019],
            [157.874784647000155, -51.097729695999938],
            [157.898558647000101, -51.094752696],
            [157.922350647000172, -51.091839696000029],
            [157.946160647000141, -51.088990695999939],
            [157.969988647000122, -51.086204696],
            [157.99383364700023, -51.083482696000019],
            [158.017695647000181, -51.080825696],
            [158.041573647000092, -51.078231695999953],
            [158.06546764700019, -51.075701695999967],
            [158.089377647000248, -51.073235696000033],
            [158.113302647000154, -51.07083469599997],
            [158.137359647000068, -51.068485696000032],
            [158.16143064700023, -51.066200695999974],
            [158.185515647000244, -51.063981696000049],
            [158.20961564700022, -51.061826696],
            [158.233727647000052, -51.059737696],
            [158.257853647000076, -51.05771269599996],
            [158.281990647000185, -51.055752695999971],
            [158.306140647000149, -51.05385769600003],
            [158.330302647000195, -51.052028696000043],
            [158.354475647000214, -51.050263695999931],
            [158.37865864800014, -51.048564695999957],
            [158.402853648000161, -51.04693069599994],
            [158.427057648000158, -51.045361695999972],
            [158.45127064800019, -51.043857695999961],
            [158.475493648000139, -51.042418696],
            [158.499725648000123, -51.041045696],
            [158.523965648000143, -51.039737696000046],
            [158.548213648000143, -51.038494696000051],
            [158.572469648000066, -51.037317696],
            [158.596732648000142, -51.036205696000017],
            [158.621001648000089, -51.035158695999982],
            [158.645277648000075, -51.034177696],
            [158.669559648000103, -51.033261695999968],
            [158.693847648000116, -51.032410695999985],
            [158.718139648000175, -51.031625695999963],
            [158.742436648000222, -51.030905695999984],
            [158.766738648000256, -51.030251695999965],
            [158.791043648000056, -51.029662695999988],
            [158.815352648000186, -51.029139695999973],
            [158.839664648000138, -51.028681696],
            [158.863978648000085, -51.028289695999987],
            [158.888295648000195, -51.027962696000017],
            [158.912614648000186, -51.027701696],
            [158.936934648000175, -51.027505695999956],
            [158.961255648000162, -51.027375696000036],
            [158.985577648000202, -51.027310695999986],
            [159.009899648000186, -51.027311695999977],
            [159.034220648000172, -51.027378696000014],
            [159.058542648000156, -51.027509696000017],
            [159.082862648000145, -51.027707695999972],
            [159.107180648000138, -51.027970695999969],
            [159.131497648000135, -51.028298696000022],
            [159.155812648000079, -51.028692695999936],
            [159.180123648000205, -51.029151695999985],
            [159.204432648000164, -51.029676696],
            [159.228737648000248, -51.030266695999963],
            [159.253039648000168, -51.030922695999969],
            [159.277336648000215, -51.031643695999939],
            [159.301628648000104, -51.032430695999956],
            [159.325916648000117, -51.033282696000015],
            [159.350197648000147, -51.034200696000035],
            [159.374473648000134, -51.035183695999919],
            [159.398742648000194, -51.036231695999945],
            [159.423005648000156, -51.037345695999925],
            [159.447261648000193, -51.038524695999953],
            [159.471509649000183, -51.039768695999946],
            [159.495748649000205, -51.041078695999978],
            [159.519980649000189, -51.042453695999967],
            [159.544203649000252, -51.043893696000012],
            [159.56841664900017, -51.045398696000014],
            [159.592620649000224, -51.046969695999969],
            [159.61681464900019, -51.048605695999974],
            [159.640997649000127, -51.050306695999936],
            [159.665170649000203, -51.052072696000039],
            [159.689331649000195, -51.053903696000013],
            [159.713481649000158, -51.055799695999951],
            [159.737618649000098, -51.057761695999929],
            [159.761744649000178, -51.059787695999958],
            [159.785856649000124, -51.061878695999951],
            [159.81016964900013, -51.064053695999966],
            [159.834468649000172, -51.066295695999933],
            [159.858752649000195, -51.068602696000035],
            [159.883022649000139, -51.070975696],
            [159.907277649000179, -51.073414696],
            [159.931516649000201, -51.075919695999971],
            [159.955739649000151, -51.078490695999982],
            [159.979945649000143, -51.081127695999939],
            [160.004135649000119, -51.083829695999952],
            [160.028307649000197, -51.086597696],
            [160.052462649000205, -51.089431696000013],
            [160.07659864900009, -51.092331695999974],
            [160.100716649000248, -51.095296695999984],
            [160.124815649000226, -51.098326695999951],
            [160.148895649000252, -51.101422696000057],
            [160.172954649000218, -51.104583696000027],
            [160.196994649000231, -51.107810695999959],
            [160.221012649000187, -51.111102695999932],
            [160.245010649000193, -51.114459696000047],
            [160.268986649000141, -51.117882696000031],
            [160.292941649000198, -51.121369695999974],
            [160.316873649000144, -51.124922695999963],
            [160.340782649000147, -51.128539696000011],
            [160.364668649000208, -51.132221695999924],
            [160.388530649000046, -51.135969695999975],
            [160.412369649000226, -51.139781695999986],
            [160.436183649000242, -51.14365869599996],
            [160.459972649000093, -51.147599696],
            [160.483736649000178, -51.151605695999983],
            [160.507475649000156, -51.155676695999929],
            [160.531187650000192, -51.159811696000027],
            [160.554873650000133, -51.164010696],
            [160.578531650000144, -51.168274696000019],
            [160.602163650000222, -51.172602696],
            [160.625110768428755, -51.176804623571407],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "AUT", Country: "Austria" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.946182278275188, 48.619064171953752],
            [16.943336119224966, 48.573327143682732],
            [16.933327120977168, 48.532773149073805],
            [16.907500130288582, 48.506944984918746],
            [16.865691197132605, 48.468745131642166],
            [16.855827205810101, 48.449436076519092],
            [16.844718166586489, 48.361973095933834],
            [16.923891277270059, 48.282082159060295],
            [16.949718100320581, 48.278045099203652],
            [16.952363261332039, 48.250273171696719],
            [16.975273182069429, 48.188327051381734],
            [16.998018146951722, 48.169700115540948],
            [17.033545178936265, 48.145264185507315],
            [17.0691451334786, 48.135264239714914],
            [17.081109126828551, 48.088336142999296],
            [17.116663316179341, 48.038609161167571],
            [17.16638225138405, 48.0125002032553],
            [17.14694512078006, 48.00000010337682],
            [17.108054095765795, 47.971382106561052],
            [17.060554017975846, 47.876935994782784],
            [17.054445119286555, 47.84721812462918],
            [17.056800266447397, 47.794582119473276],
            [17.068609194588078, 47.768327148807359],
            [17.071663224837664, 47.72860916522346],
            [17.053891243123132, 47.70944511702568],
            [16.955273290484712, 47.694718113105338],
            [16.910827075306287, 47.690827066002313],
            [16.824445024957555, 47.683882156287993],
            [16.764163215341625, 47.685409171412644],
            [16.735836070566876, 47.702082117979657],
            [16.723473266262772, 47.726454010273059],
            [16.635554142505413, 47.760273145661628],
            [16.612218085809502, 47.759164219868396],
            [16.558609109330177, 47.756382098558717],
            [16.45055413951809, 47.69805411057844],
            [16.432636145050026, 47.66638208351317],
            [16.493054076773802, 47.646664159152053],
            [16.596663285129125, 47.628891171609141],
            [16.632573202451766, 47.634436135851402],
            [16.666391164373948, 47.614436076628593],
            [16.713891242163896, 47.543882077004454],
            [16.705273304570909, 47.521109116565455],
            [16.655200150137063, 47.458153982743212],
            [16.592500161448299, 47.425282170054047],
            [16.473891202042495, 47.418745123749247],
            [16.452009237913558, 47.412845102087616],
            [16.456391129267786, 47.368891072265043],
            [16.491391274817119, 47.281936035013572],
            [16.474300238918602, 47.259991206610593],
            [16.444718155596917, 47.244436070654146],
            [16.420900139467051, 47.210409064066013],
            [16.45930015259205, 47.147291153683838],
            [16.505554009014276, 47.115000206886307],
            [16.504863340190724, 47.006764187965288],
            [16.445000122820517, 47.004718165394351],
            [16.412500131357319, 47.007773201472432],
            [16.346945098800944, 47.009991220696904],
            [16.302773307133265, 47.011382113713566],
            [16.27666334339267, 46.988054103644757],
            [16.253609086282296, 46.958327013397749],
            [16.137918202656095, 46.878891213867931],
            [16.111800192288484, 46.869718059007155],
            [16.009309129819883, 46.835491060208781],
            [15.988327214456319, 46.831591128288338],
            [15.987918177580212, 46.755136101174159],
            [15.8590271375204, 46.723191159340686],
            [15.832782225138317, 46.722218020379287],
            [15.806663208942297, 46.718609108138068],
            [15.602500163585603, 46.686664166304681],
            [15.406109154151238, 46.652500199418327],
            [15.139445106487841, 46.657218037452893],
            [15.102918113451722, 46.65666416128947],
            [15.074409249015616, 46.648573110131608],
            [15.055136068438259, 46.652082110086738],
            [14.867500124533052, 46.613327039542256],
            [14.833745026884742, 46.57069114781676],
            [14.825000187277368, 46.54138214690137],
            [14.813191259136687, 46.511382141886244],
            [14.545000196348354, 46.4074911339453],
            [14.478054103137225, 46.41944506901136],
            [14.153682160692199, 46.442636118782062],
            [14.128891173238998, 46.463054099698283],
            [14.115836191368714, 46.478882150422962],
            [14.082218221656859, 46.48916423107697],
            [13.936945087070967, 46.515827065152493],
            [13.845554011370496, 46.520000079479033],
            [13.776109105177682, 46.518327051600565],
            [13.718654008596474, 46.526609042513385],
            [13.611109161413253, 46.548045089392176],
            [13.555000198485857, 46.556245105291609],
            [13.428891112097716, 46.563045008080721],
            [13.257500134782219, 46.561944967104907],
            [12.825554008492787, 46.631245034010519],
            [12.778336065564559, 46.647918148215595],
            [12.757773245360994, 46.649718078108478],
            [12.74792719131122, 46.644491123273653],
            [12.735627083643095, 46.64131806999886],
            [12.692500180028219, 46.659718023900879],
            [12.590273147510317, 46.658882180513942],
            [12.554445037562942, 46.661664134185614],
            [12.486173262541797, 46.682364249963555],
            [12.440554083829767, 46.690827122347415],
            [12.404300173199857, 46.705553958629665],
            [12.383745064347238, 46.72110909458614],
            [12.301663265615531, 46.841109114646784],
            [12.232218191784625, 46.888882107204935],
            [12.18555412501982, 46.907918079921714],
            [12.160273299143626, 46.928054093614421],
            [12.136109110411724, 46.966945118628715],
            [12.133018199788097, 47.012564129702739],
            [12.194582272955273, 47.032982110619074],
            [12.223891273870777, 47.063044979908241],
            [12.230836183585211, 47.072218134769102],
            [12.227500186112081, 47.084164190846067],
            [12.18610917464963, 47.094582225970086],
            [12.14012705952905, 47.084809094478246],
            [12.07528214181167, 47.059436067666908],
            [11.761245086010916, 46.978191118150662],
            [11.731109126525894, 46.978882122250369],
            [11.710136095979777, 46.996382195025191],
            [11.621945063282595, 47.01277317436849],
            [11.483891261921883, 47.014164235023415],
            [11.177154005616927, 46.967353987866815],
            [11.118054044352164, 46.929991151443673],
            [11.100554139215433, 46.915409154448625],
            [11.09708218727252, 46.891591138318674],
            [11.071391151053746, 46.82346420258493],
            [11.016800150795689, 46.773327010410625],
            [10.887782208721461, 46.771109158824046],
            [10.834163341596309, 46.786945088537649],
            [10.791663236702732, 46.801382078607375],
            [10.763263337008198, 46.830553951586566],
            [10.680136147309639, 46.873818150775818],
            [10.64694515174665, 46.870418115562288],
            [10.599718156362997, 46.857773176396492],
            [10.563609085020317, 46.848882156397323],
            [10.497773258706786, 46.855273189948306],
            [10.476109224060934, 46.865691225072325],
            [10.471236153179206, 46.871354038873406],
            [10.488209171880726, 46.935991085391478],
            [10.430000206925939, 46.984164230008233],
            [10.390763344585594, 47.002564183910337],
            [10.350627104032014, 46.991245094192536],
            [10.323327077675316, 46.955554112181474],
            [10.313327131882915, 46.933609116140403],
            [10.227500131163993, 46.876936051127771],
            [10.160836172814385, 46.85500010754221],
            [10.141809084914911, 46.851345094833263],
            [10.131527171898966, 46.85040917152206],
            [10.109445047921611, 46.850273217052248],
            [10.05889127515826, 46.866391113989366],
            [9.935000207994591, 46.912773213530656],
            [9.877782151635955, 46.940409186567578],
            [9.875836041351221, 46.968182119902835],
            [9.891600221973448, 47.000336106402059],
            [9.872918133209708, 47.023045029100629],
            [9.719445150362361, 47.050282191183427],
            [9.63250017139481, 47.056664172279042],
            [9.598636109004985, 47.063836063932129],
            [9.606945089645933, 47.06805400525981],
            [9.633609097188042, 47.097145076692115],
            [9.633891232049649, 47.130554001738304],
            [9.622773140370612, 47.150554060961085],
            [9.566391262675097, 47.243891079308142],
            [9.533573261804463, 47.274545040410842],
            [9.540000169901191, 47.28416411252185],
            [9.610963206401436, 47.362227123945743],
            [9.637363183992647, 47.369582076364665],
            [9.670345134889914, 47.390691061380707],
            [9.649654071567397, 47.458609120086848],
            [9.596600144717968, 47.469436024448882],
            [9.559800236913645, 47.502091081121293],
            [9.566727209355236, 47.540454046234075],
            [9.577918223592235, 47.537418120895595],
            [9.6087731827337, 47.529518177130555],
            [9.651509154383461, 47.529518177130555],
            [9.692527171153529, 47.529518177130555],
            [9.732791151911755, 47.541118060605484],
            [9.746245112374368, 47.565827073045412],
            [9.775418158819946, 47.591245026857891],
            [9.810973186361025, 47.594164108466074],
            [9.955000099579308, 47.539718115133226],
            [10.043054004340206, 47.495282125876869],
            [10.231736177402666, 47.373745032407484],
            [10.211663195622094, 47.322491203089001],
            [10.173336105054943, 47.274718042892871],
            [10.228891191818832, 47.276036013351856],
            [10.274445159324017, 47.28889117064999],
            [10.33666318857891, 47.312700134324459],
            [10.429718072064333, 47.396109123608554],
            [10.473745024444696, 47.437636089540732],
            [10.46618220082641, 47.482909095650697],
            [10.436391240477036, 47.529164125539396],
            [10.433682209363099, 47.5804821602364],
            [10.481800201056899, 47.586518136368042],
            [10.569445068941491, 47.540273164763036],
            [10.690836149657173, 47.553891075252167],
            [10.909445069900841, 47.518054080487374],
            [10.976663239689941, 47.400273253117675],
            [11.008336104945528, 47.39582715638484],
            [11.028609078936455, 47.395273112583524],
            [11.10402709698684, 47.396527045301966],
            [11.296391272486574, 47.428891082295351],
            [11.457500169147977, 47.512218096566286],
            [11.505282214161525, 47.509164233954678],
            [11.554445093908043, 47.515545041583877],
            [11.579863215358586, 47.524091062447312],
            [11.683054166744142, 47.584718038836982],
            [11.920000176616043, 47.61416416768877],
            [12.006391111782193, 47.624445074876391],
            [12.060000255899581, 47.620554027773395],
            [12.111109245930749, 47.614718043852108],
            [12.141391218169559, 47.611109131610803],
            [12.201554004760027, 47.612991204155193],
            [12.207318071951761, 47.633373142887692],
            [12.201109093338204, 47.657491063514001],
            [12.455000126354918, 47.667773144168009],
            [12.51444508675533, 47.633253952224138],
            [12.566663337855886, 47.641936095195618],
            [12.576391207070401, 47.644164172703981],
            [12.603400213748159, 47.672291157630397],
            [12.665836172486223, 47.685000134536537],
            [12.736945054101994, 47.682709192754217],
            [12.827845117913, 47.621245031873102],
            [12.805973212067983, 47.614853998322118],
            [12.788191172069645, 47.583536022847895],
            [12.806663210339195, 47.554718033821885],
            [12.957873246960958, 47.477018126444463],
            [13.012500121764674, 47.469791081868451],
            [13.05333608359723, 47.497564182841685],
            [13.100136104832018, 47.642918118974464],
            [13.077500104691239, 47.687354108230906],
            [13.058336056493459, 47.706109119552579],
            [13.023054111358476, 47.724164241957212],
            [12.993745110443001, 47.714164128526633],
            [12.913954085855636, 47.725000085344064],
            [12.944163303174662, 47.788745129723694],
            [12.970554060672299, 47.810554003656776],
            [13.008891209523341, 47.854164207810172],
            [12.966663348845543, 47.906945052253306],
            [12.918891194477823, 47.957773248527516],
            [12.86534508227254, 47.994600146059966],
            [12.857045154086904, 48.011936101170065],
            [12.83750023220793, 48.030000108392031],
            [12.79916325099498, 48.054582218817458],
            [12.766245170200165, 48.082218024216431],
            [12.759718182179256, 48.121727130772555],
            [12.871245103934257, 48.202909048376782],
            [13.017773177067113, 48.259436100635796],
            [13.154445137866389, 48.292218059322593],
            [13.258054010945642, 48.2988821752799],
            [13.28527323575554, 48.305273208830883],
            [13.313054048079806, 48.315827030786622],
            [13.335000217587265, 48.328609097888815],
            [13.395000227617601, 48.366100177430937],
            [13.421873279825434, 48.391036004171369],
            [13.437918086566697, 48.429436017296283],
            [13.45750022409598, 48.5155540376944],
            [13.443227184052887, 48.560236119628726],
            [13.518609159919635, 48.58458219566019],
            [13.602773191043894, 48.568054088380379],
            [13.654718192100177, 48.551664114865289],
            [13.676945155364848, 48.532291189639849],
            [13.726000243836154, 48.515591085706419],
            [13.803745078214519, 48.582636085375427],
            [13.825418165315881, 48.631109134488597],
            [13.814718162968347, 48.738054005041064],
            [13.833609128759832, 48.773609032582144],
            [13.858054111248975, 48.770553996504262],
            [13.883609192997852, 48.763609086789742],
            [13.998609240162324, 48.714027111883425],
            [14.051391258071959, 48.669791114837139],
            [14.184163286950962, 48.596109156577242],
            [14.27832709622956, 48.58250013090553],
            [14.390273280782537, 48.592500076697931],
            [14.446391128527353, 48.618891169471723],
            [14.50555412170425, 48.628327013178833],
            [14.61194511609321, 48.628464141115202],
            [14.654445053348809, 48.608473134347818],
            [14.700282161544095, 48.581382152656872],
            [14.705418088910136, 48.585136071823499],
            [14.719445036275374, 48.604018152797678],
            [14.716663250241766, 48.654300184259299],
            [14.727218078025913, 48.693464124041796],
            [14.823745081092341, 48.785064244407934],
            [14.849445169766511, 48.786382214866919],
            [14.885554073471013, 48.782773134987522],
            [14.958891200233353, 48.794718017598186],
            [14.985000158145539, 48.939164193614474],
            [14.979582263555869, 48.979927065251175],
            [15.00416320051491, 49.011391221117165],
            [15.028609188832462, 49.018745000069615],
            [15.065554103562079, 49.01471816613487],
            [15.156663212038865, 48.999718079808119],
            [15.301391187640746, 48.987782082014959],
            [15.359027165692908, 48.984718161119659],
            [15.39250012847944, 48.973609121896018],
            [15.451663289294402, 48.951109076225137],
            [15.514445085358432, 48.919991092961297],
            [15.546663277236092, 48.912218051210758],
            [15.564436097140941, 48.909164188599348],
            [15.585000258448986, 48.899164242806947],
            [15.685973190610241, 48.864018084503797],
            [15.746391122334074, 48.858609074731518],
            [15.849427176148595, 48.85970006325185],
            [15.964163193362396, 48.804718131028594],
            [16.021663216944631, 48.777218112461455],
            [16.060554074320862, 48.760273256954704],
            [16.081109183173567, 48.754718066790574],
            [16.103336146438238, 48.75000006111803],
            [16.333054105905518, 48.733054032144807],
            [16.382154121377965, 48.736036145664841],
            [16.412982258429281, 48.751873248844916],
            [16.430827162701576, 48.782218085357712],
            [16.458263311166263, 48.811245119049588],
            [16.540554154563637, 48.812354044842735],
            [16.658818114833792, 48.785273121435594],
            [16.711663332293597, 48.7463820964213],
            [16.785554167581182, 48.719854043349116],
            [16.832918123263113, 48.716245131107897],
            [16.871936050291907, 48.725482156071124],
            [16.892500211600009, 48.725827155206687],
            [16.917918165412487, 48.703191155065909],
            [16.946182278275188, 48.619064171953752],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "AZE", Country: "Azerbaijan" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [45.07554515056728, 39.772282181496792],
              [45.266391195759468, 39.611109079456753],
              [45.326927144680496, 39.538953968683529],
              [45.464991171963106, 39.494018082720402],
              [45.502354176024255, 39.504436117844193],
              [45.52832717946643, 39.517209132491075],
              [45.558327184481755, 39.533873194240584],
              [45.599163313952374, 39.551654060772421],
              [45.626654112425911, 39.559991204608366],
              [45.738391252313249, 39.579154079339503],
              [45.791936191051946, 39.565536001212479],
              [45.819354066967719, 39.549164132608155],
              [45.828945143522219, 39.446300075447368],
              [45.855554165779267, 39.348036006761845],
              [45.985127157483248, 39.275400110021167],
              [46.005618228595495, 39.249027122158225],
              [45.986091243989165, 39.223045066260568],
              [45.969427182239428, 39.197764240384288],
              [45.968736178139807, 39.174573190613785],
              [46.107218127115971, 38.93610016561712],
              [46.12360022164205, 38.909982155249509],
              [46.178245201356731, 38.841145104676571],
              [46.074718135652745, 38.871936026077606],
              [45.950827236127054, 38.887218079627814],
              [45.841100244265022, 38.903600174153894],
              [45.60749123769466, 38.951382219167215],
              [45.53860020766524, 38.965545121002322],
              [45.518591263624955, 38.970545093898693],
              [45.477263284074695, 38.984964146695575],
              [45.433473204278641, 39.00318221329826],
              [45.42443617152577, 39.022764183189565],
              [45.421100174052469, 39.060818023712329],
              [45.345545195703892, 39.16470903165316],
              [45.298391290516065, 39.19762711244806],
              [45.271236103446597, 39.187200192506737],
              [45.225818258049344, 39.194982119074609],
              [45.143527079375872, 39.216854024919826],
              [45.141800239678673, 39.247073132884566],
              [45.13054518770133, 39.285553947556366],
              [45.115818183781016, 39.312200185463553],
              [45.063045050688686, 39.373045091336337],
              [45.002218249726923, 39.416691170035449],
              [44.974709178704416, 39.42053611667086],
              [44.954573332649659, 39.432354097266909],
              [44.927773202999788, 39.476645079598086],
              [44.908045052716773, 39.535827015876293],
              [44.903045079820629, 39.555545107875446],
              [44.888600210761865, 39.605818086881698],
              [44.868463358878842, 39.622345020694979],
              [44.81304506477494, 39.630809066545112],
              [44.816373183259202, 39.645818037689082],
              [44.799373174829213, 39.686445122493922],
              [44.778863328253863, 39.706382149804767],
              [44.794282174464342, 39.711227057491897],
              [44.858327123340473, 39.724991148372794],
              [44.889573182085144, 39.722909083618049],
              [44.926791179221027, 39.7198449950846],
              [44.960536218585446, 39.729427019183561],
              [44.998745124317452, 39.75191801239896],
              [45.021100163062897, 39.769853944140152],
              [45.047073334143306, 39.779154001015343],
              [45.07554515056728, 39.772282181496792],
            ],
          ],
          [
            [
              [45.241100143961603, 40.985127145841602],
              [45.229163307977956, 40.969154088191715],
              [45.206382300911912, 40.979427116390355],
              [45.202027231647776, 40.999436060430469],
              [45.241100143961603, 40.985127145841602],
            ],
          ],
          [
            [
              [45.049709275061275, 41.038745007138459],
              [45.02582722119098, 41.030544991238912],
              [45.009991291477263, 41.033054030142466],
              [44.984154074866979, 41.05762708811244],
              [44.992636225628132, 41.085409073903065],
              [45.023882284372803, 41.087073216964313],
              [45.055263291758877, 41.05971803768449],
              [45.049709275061275, 41.038745007138459],
            ],
          ],
          [
            [
              [51.276690679262742, 41.241743538779644],
              [51.325225830000136, 41.166864395000118],
              [51.366469460868529, 41.103807394973842],
              [51.412741538360478, 41.054360745226518],
              [51.533618169247916, 40.925191173551809],
              [51.587536102679167, 40.780744997535464],
              [51.638273271484366, 40.672036071635773],
              [51.671227226824897, 40.470073108230906],
              [51.677009231289446, 40.296009141985337],
              [51.67343619359383, 39.82550911082825],
              [51.668445105514991, 39.644382217671193],
              [51.652654102802671, 39.599082221832816],
              [51.635427112434058, 39.527054013074178],
              [51.631909227661595, 39.475773194027397],
              [51.653309232356662, 39.408173144728494],
              [51.493192062790342, 39.173058888588855],
              [51.478010178000147, 38.96946716299999],
              [51.403982162000091, 38.73102378800013],
              [51.314878464000174, 38.736446381],
              [51.292886572036139, 38.73781903726757],
              [51.292709195543637, 38.71485396335089],
              [51.097309262343401, 38.623318048363217],
              [50.907227143808598, 38.581364108282116],
              [50.712400197511101, 38.545091086912805],
              [50.513636103642909, 38.472300124962885],
              [50.307745212760949, 38.402582136363947],
              [50.198973254949294, 38.378436052542838],
              [49.969573304889366, 38.333909035817385],
              [49.714527245611748, 38.262809039018975],
              [48.888282311250634, 38.442409084689359],
              [48.871137372456076, 38.445347230426535],
              [48.841173333063978, 38.450482198574491],
              [48.783736173755585, 38.445536037496822],
              [48.74220920782335, 38.424164195996354],
              [48.662900142669912, 38.389153992163131],
              [48.623509220948847, 38.396500059764605],
              [48.599154092461902, 38.416935977953756],
              [48.565263375619963, 38.471382138924582],
              [48.432763255680698, 38.624573154548273],
              [48.398736081454501, 38.620536094691545],
              [48.36192712119481, 38.605818143226486],
              [48.331654201411538, 38.602836029706452],
              [48.243600129012691, 38.672073232338107],
              [48.246900251940133, 38.71540012816331],
              [48.184709212413566, 38.750545112999987],
              [48.149300197626133, 38.761109160877709],
              [48.123600276590025, 38.772218032463343],
              [48.103045167737491, 38.783600154093108],
              [48.024636151349569, 38.833391173665348],
              [48.013191165445676, 38.863045006078437],
              [48.012073187197018, 38.8970731861329],
              [48.076791202899813, 38.953882205615457],
              [48.144145159520747, 38.968318022218682],
              [48.185263256215023, 38.969144980788258],
              [48.224709163221007, 38.971373225934656],
              [48.263718205432554, 38.977600141821085],
              [48.283736201928207, 38.985818094993419],
              [48.308736066409153, 39.003744974279016],
              [48.325409180614201, 39.032209079352128],
              [48.326927143283513, 39.054436042616715],
              [48.293873275656892, 39.112491115828789],
              [48.272354080298271, 39.126518063193998],
              [48.229227176683423, 39.139918044199874],
              [48.133327139976075, 39.210964229179979],
              [48.121718204045806, 39.251800191012421],
              [48.123873191358143, 39.278182231330845],
              [48.148945140206592, 39.306791175691217],
              [48.198536167568477, 39.336100176606692],
              [48.245963322800549, 39.34248215770225],
              [48.278873189330227, 39.3502642519083],
              [48.357882182349215, 39.389909145296201],
              [48.34054505377253, 39.423454024812429],
              [48.321936222842851, 39.440818143117099],
              [48.283873329864377, 39.472754032495232],
              [48.250827173588789, 39.494709086820194],
              [48.212491198204248, 39.526373234896198],
              [48.159154130664888, 39.573318095418301],
              [48.082491232351288, 39.646236127020643],
              [48.063318131697997, 39.665554066961136],
              [48.038318099579044, 39.683327054504161],
              [48.014154078485092, 39.699154099400289],
              [47.984709123099719, 39.715582126755692],
              [47.812900224090868, 39.679164266099292],
              [47.793882188646819, 39.666800120690823],
              [47.769854122022764, 39.633736027142049],
              [47.542500194533602, 39.515264195672557],
              [47.460682258114559, 39.498191097046856],
              [47.391663320242287, 39.470545065726071],
              [47.337218165099955, 39.424300094121293],
              [47.318609166532042, 39.397491079653832],
              [47.286936133638392, 39.374435984353227],
              [47.199500142781403, 39.33999105607154],
              [47.164573255065875, 39.335273218037173],
              [47.099709226609377, 39.304436028530517],
              [47.04346330338376, 39.237900145661627],
              [47.033882285113123, 39.196727063682431],
              [46.952900192081131, 39.156373228921893],
              [46.908736111764597, 39.167909074656563],
              [46.874700220359045, 39.167500037780371],
              [46.848882282126056, 39.160609107522475],
              [46.758945131354494, 39.085964236223163],
              [46.745473233619009, 39.051036007403013],
              [46.6522091378298, 38.976927074994251],
              [46.572491203438176, 38.915827023988015],
              [46.540373259122873, 38.875591206424417],
              [46.495536111979334, 38.957345105102945],
              [46.502209112754031, 38.999845042358686],
              [46.51610027328735, 39.024845074477639],
              [46.523600232631566, 39.04415396196265],
              [46.517773301165903, 39.073736045284249],
              [46.495063372638867, 39.126444972998058],
              [46.477482163041202, 39.13665396345624],
              [46.458318114843394, 39.142773088067543],
              [46.424427230363278, 39.165618132874158],
              [46.423145134449953, 39.208700109487893],
              [46.455818128395322, 39.21755408147493],
              [46.496091161609058, 39.203609109122965],
              [46.541654181569754, 39.189418044093202],
              [46.620473240662022, 39.221654005605615],
              [46.62053610493615, 39.229427047356126],
              [46.581382223437345, 39.253609173360871],
              [46.557491284749744, 39.269145030940138],
              [46.398182317981252, 39.384153962921971],
              [46.384154197149542, 39.405964177959504],
              [46.38602721723845, 39.438664161633255],
              [46.404291216670742, 39.451236010603111],
              [46.448600136274706, 39.457764172090378],
              [46.512973320479887, 39.475609076362673],
              [46.545691241426255, 39.54936412481851],
              [46.526236173549592, 39.578118076104261],
              [46.357218113029546, 39.626509150204129],
              [46.329427242421559, 39.625127142004658],
              [46.304573223056309, 39.616518089229089],
              [46.244227208061886, 39.594982130064125],
              [46.207627124829969, 39.597073247274182],
              [46.189709130361734, 39.607218032353956],
              [46.057491145284303, 39.699845103499968],
              [46.043318185165219, 39.723035985632634],
              [46.030536118063196, 39.745536031303516],
              [46.008663374027662, 39.770191064286735],
              [45.983873224764693, 39.777491199058773],
              [45.960273305755948, 39.778600124851948],
              [45.917627188108554, 39.791664159177742],
              [45.881382162296063, 39.808591077411734],
              [45.850682268363812, 39.829018110783352],
              [45.820263335826894, 39.86040012399792],
              [45.80471825815431, 39.892073156891797],
              [45.796936163948317, 39.917900147580269],
              [45.779300136703682, 39.94054503253858],
              [45.751100229219503, 39.953318047185235],
              [45.729709276979918, 39.958318020081208],
              [45.705273179308051, 39.961373223797352],
              [45.607763314272461, 39.971791091283194],
              [45.597554156176272, 39.99735405202108],
              [45.613600136383923, 40.014436035464144],
              [45.638036066417669, 40.021664253506742],
              [45.681927231966483, 40.025827041911384],
              [45.713609149677495, 40.02609107186214],
              [45.763609213915402, 40.024435981256431],
              [45.824436182515342, 40.019982173172878],
              [45.847773245039718, 40.01610906334264],
              [45.880545145442738, 40.014018113770589],
              [45.907973247280324, 40.023944969367165],
              [45.977409268655833, 40.11845394541939],
              [45.997727169648044, 40.230827104121374],
              [45.95602720123361, 40.272136140570467],
              [45.913345209040529, 40.264500059118348],
              [45.882209120865809, 40.267491057455743],
              [45.662763351406682, 40.375827156301],
              [45.638036066417669, 40.391109042213259],
              [45.536382188440768, 40.456927098892038],
              [45.413600214708254, 40.608036049760983],
              [45.389154058752695, 40.663735975812358],
              [45.420400285135543, 40.719991119131464],
              [45.436382227602905, 40.733736099273074],
              [45.455545102334213, 40.742209029940739],
              [45.523318154114975, 40.763053985005911],
              [45.576936183049781, 40.777491142713643],
              [45.602700141826176, 40.800473147818479],
              [45.618045059650399, 40.835964137619214],
              [45.619436120305295, 40.866582224176341],
              [45.35096325793171, 41.000964249021763],
              [45.096373174188102, 41.059709152867185],
              [45.079991247300029, 41.080409101007092],
              [45.082073312054547, 41.113636138753748],
              [45.127900194328049, 41.118182147772757],
              [45.175963368374823, 41.115000042042197],
              [45.204709273033359, 41.120754050950268],
              [45.221091199921318, 41.139982136888307],
              [45.211373221352574, 41.15971816616036],
              [45.193045184179823, 41.173045056970466],
              [45.143600169571812, 41.201518046860883],
              [45.04694509102444, 41.223309151159185],
              [45.022945187594985, 41.297053973693039],
              [45.051382302939828, 41.316373087099976],
              [45.141373265529865, 41.37665405852556],
              [45.186100274465502, 41.404982209128775],
              [45.260963242886191, 41.449291128732739],
              [45.285400178748034, 41.457209177408458],
              [45.306654170689569, 41.461100056873477],
              [45.34159128432691, 41.46096410240375],
              [45.61110920239139, 41.381927113828169],
              [45.716927209877554, 41.348591111339772],
              [45.712973298500799, 41.311791203535506],
              [45.711173200969824, 41.287218145565419],
              [45.748045193141536, 41.260273176628118],
              [45.80457325122876, 41.230400073627379],
              [45.850818222833709, 41.212773098838227],
              [45.869709188625393, 41.206091213246225],
              [45.895545064131397, 41.197491212926138],
              [45.922491206534858, 41.189154069090421],
              [45.966100237221838, 41.179153955659729],
              [45.996936085624156, 41.173609159055843],
              [46.039573318454075, 41.170273161582713],
              [46.062200266139371, 41.171927078721808],
              [46.091518151872151, 41.1788731619028],
              [46.135609141993143, 41.202554050096296],
              [46.239991161823298, 41.204709037408634],
              [46.274009115955835, 41.178318112273075],
              [46.294154182104023, 41.162764149782845],
              [46.340827133686332, 41.129991075913495],
              [46.359709214660484, 41.116936094043069],
              [46.37831821322834, 41.104154026941046],
              [46.492763378400923, 41.051800156646721],
              [46.514709212632397, 41.04804506401365],
              [46.62249126767631, 41.100545114699628],
              [46.66665417452657, 41.150545011299585],
              [46.697200176716251, 41.211653947123125],
              [46.710818254843275, 41.25832706634344],
              [46.689636179631407, 41.317345052594931],
              [46.621091154566045, 41.365000195594007],
              [46.593318221230732, 41.379436012197232],
              [46.553036135561513, 41.386936139179795],
              [46.502636086902896, 41.396236028416808],
              [46.331936113687163, 41.483464148074631],
              [46.233327213504083, 41.598591097253404],
              [46.216100223135641, 41.624982190027424],
              [46.196091279095356, 41.677491125530764],
              [46.194427136034278, 41.698045060917025],
              [46.198045100731179, 41.724991203320769],
              [46.209709189584544, 41.746654064499978],
              [46.230463284819109, 41.762473230407124],
              [46.256382308804859, 41.764573232434714],
              [46.290818184630893, 41.757773162007595],
              [46.324782159306835, 41.756454018082138],
              [46.35054511225502, 41.773318072042002],
              [46.414709251794591, 41.831800119403226],
              [46.435827121628051, 41.86180012441821],
              [46.451754078810268, 41.897054073996628],
              [46.505273201287537, 41.893600059326616],
              [46.564845063702251, 41.881864221381818],
              [46.642209192037512, 41.817627159284314],
              [46.761727085026166, 41.860473101504056],
              [46.769436089036276, 41.830964108378225],
              [46.774363307012663, 41.795618125502699],
              [46.806927168578284, 41.769145057715548],
              [46.861663343399755, 41.734709181889514],
              [46.940536214310669, 41.68387310662655],
              [47.0213821852347, 41.61860004129386],
              [47.092354106552364, 41.569291148793582],
              [47.129573277154805, 41.576382071261605],
              [47.15859125839097, 41.562909167697967],
              [47.259091283573582, 41.420264262679211],
              [47.261036220392157, 41.374291200014],
              [47.274645078425692, 41.321100145228229],
              [47.371091279945233, 41.271936092015324],
              [47.576173317168696, 41.211309115625568],
              [47.599436116030688, 41.215273085286526],
              [47.630682174775188, 41.232064216688627],
              [47.651518245023169, 41.235545053448959],
              [47.721373193920499, 41.210536136512644],
              [47.760818262736393, 41.196582111705368],
              [47.796100207871291, 41.198864168670127],
              [47.859154080513008, 41.207764241124778],
              [47.915473261572743, 41.224991063855214],
              [47.921791204927899, 41.251518111099017],
              [47.908391223921853, 41.278118080900583],
              [47.95831819796399, 41.35581815591587],
              [48.070263376688672, 41.46401813265328],
              [48.10582712140922, 41.480264105071456],
              [48.149991201725925, 41.488318108217129],
              [48.186100273068519, 41.492209155319927],
              [48.228463250025811, 41.501518097012649],
              [48.24887318431513, 41.509164236748916],
              [48.378318100538365, 41.574718095838762],
              [48.399709220415957, 41.589145027624511],
              [48.419573325168983, 41.609009132377537],
              [48.43692721755184, 41.639153976680063],
              [48.53138221414747, 41.767209173352683],
              [48.583954181563286, 41.835773141519553],
              [48.592125232004491, 41.844659377925268],
              [48.593451970375384, 41.846417367860226],
              [48.598165512000151, 41.855411530000083],
              [48.603282928000056, 41.870283127],
              [48.609922409000092, 41.88702011099997],
              [48.630675315500014, 41.909028053500037],
              [48.656328201000292, 41.940992355000049],
              [48.658018112000093, 41.943004608000024],
              [48.661607742000228, 41.947107315000096],
              [48.83630561800004, 42.156867981000062],
              [48.87170600900015, 42.200027466000165],
              [48.907737732000101, 42.244548798000096],
              [48.949129105000111, 42.296270370000116],
              [49.040697098000066, 42.411912918000098],
              [49.488710403000255, 43.017263411999977],
              [49.505123138000158, 43.039531708000027],
              [49.595012673885265, 42.982921190571744],
              [49.716913223000233, 42.906150818000015],
              [49.749692917000289, 42.885585785000103],
              [49.760971069000163, 42.864555359],
              [49.831283569000107, 42.739389420000123],
              [49.833375931000205, 42.735876083],
              [49.864006042000142, 42.68464851400006],
              [49.86624145500025, 42.680896759],
              [49.888084412000183, 42.644065856999987],
              [49.897027969000249, 42.630115509],
              [49.966375351000067, 42.5228443150001],
              [50.005865097000083, 42.471904755000082],
              [50.043920517000174, 42.423004150000111],
              [50.124774788779519, 42.319519687878312],
              [50.125392242454041, 42.318774852301999],
              [50.377600161658279, 42.108118107894825],
              [50.5872733025397, 41.93740908222361],
              [50.645347092088286, 41.875866303694124],
              [50.65142822300021, 41.873001099000021],
              [50.657669067000199, 41.870042801000011],
              [50.800672531000203, 41.802659988000073],
              [50.841066360000127, 41.786848068000097],
              [50.851884842000118, 41.782581329000095],
              [50.966360092000116, 41.737079620000031],
              [50.988414764000083, 41.721603394000056],
              [51.037300110000103, 41.63642692600024],
              [51.04281425500028, 41.626865387],
              [51.15730094900016, 41.427421570000092],
              [51.262363434000065, 41.263847351000209],
              [51.276690679262742, 41.241743538779644],
            ],
            [
              [45.506100216201844, 40.612082162073193],
              [45.528882229096382, 40.606100165398288],
              [45.573054188402125, 40.632491090534188],
              [45.587909267803525, 40.651100089101874],
              [45.563918249191431, 40.664209050428838],
              [45.509436213675144, 40.661791206632017],
              [45.491936140900407, 40.636936013800437],
              [45.506100216201844, 40.612082162073193],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BDI", Country: "Burundi" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.573327110155191, -2.399163929184056],
            [30.522082165654211, -2.461599887922006],
            [30.479718182868424, -2.591390976747391],
            [30.416945103983693, -2.856046049852083],
            [30.485000122988112, -2.947217854964819],
            [30.494827066298484, -2.949035889768581],
            [30.559100170579683, -2.894999939140419],
            [30.612782237254777, -2.918890877828062],
            [30.652354040446966, -2.953955061117952],
            [30.661527195307855, -2.974163997368521],
            [30.748609134573684, -2.990554976711834],
            [30.843663269610005, -2.978790975572309],
            [30.837227141419788, -2.994726817572072],
            [30.833400132057221, -3.086255021208828],
            [30.853191146614193, -3.15749985729461],
            [30.833609176723002, -3.259026833257295],
            [30.803054122078066, -3.283608943682736],
            [30.766109207348421, -3.299099874260676],
            [30.738327221557682, -3.282781985113118],
            [30.653191224938411, -3.332154915353769],
            [30.635554024227389, -3.350555036893965],
            [30.625545193617626, -3.371726886183978],
            [30.632273179677298, -3.373626896001099],
            [30.649300177835443, -3.37854606735047],
            [30.666036156314618, -3.394655079470255],
            [30.663473305592476, -3.416808952539085],
            [30.625409239147729, -3.454517961564292],
            [30.591527071847025, -3.460763988190067],
            [30.534445137596293, -3.489164055522579],
            [30.502218228539306, -3.505555034866077],
            [30.448473297590141, -3.551045970459128],
            [30.396663245175404, -3.689163977198362],
            [30.391391195701345, -3.711108973239433],
            [30.403891127941819, -3.765972888265622],
            [30.400836091863852, -3.786108901958229],
            [30.372354049518066, -3.772846048888752],
            [30.343054101058073, -3.770973028799858],
            [30.31971821200014, -3.788755068798196],
            [30.226800121175046, -3.933608940586097],
            [30.224718224058478, -3.965281973479762],
            [30.174582205350561, -4.082081950537216],
            [30.026109195399528, -4.269445985502614],
            [29.935273169328951, -4.334164001205565],
            [29.766391230917037, -4.438055009146424],
            [29.672500168768494, -4.446390979515698],
            [29.430827100768653, -4.44805495493874],
            [29.423336193879805, -4.447499905308931],
            [29.407500264166202, -4.369163979116934],
            [29.397500150735738, -4.295835904810019],
            [29.394445114657827, -4.228890985065277],
            [29.393054054002988, -4.19528190017067],
            [29.38902722006813, -4.168054964009826],
            [29.378327217720624, -4.133608862261795],
            [29.346245147950839, -4.054863899193705],
            [29.336391214912254, -4.037082026833446],
            [29.313054152387934, -4.004999957063674],
            [29.294163354234371, -3.982499911392793],
            [29.275000144226993, -3.960282000583604],
            [29.258473210413598, -3.945835790420304],
            [29.2386091056608, -3.920835925939414],
            [29.232500206971309, -3.884999937002931],
            [29.223745141442038, -3.789654949925676],
            [29.229900140599028, -3.750963917121553],
            [29.232500206971309, -3.74333588229652],
            [29.236382201618909, -3.71971785837691],
            [29.240136120785536, -3.692917896364989],
            [29.24694507603013, -3.594445956480286],
            [29.241945103133872, -3.496108965236999],
            [29.237500179867567, -3.459164050507368],
            [29.231945157341585, -3.422781896758579],
            [29.225273162395268, -3.38778191884721],
            [29.213609241179796, -3.333608840282665],
            [29.208336185877471, -3.311664011879685],
            [29.214445084566847, -3.290345982197891],
            [29.236963235148636, -3.271864053282414],
            [29.217845119780378, -3.250072948984084],
            [29.212918237080117, -3.153681900387539],
            [29.240554042478976, -3.117781873710612],
            [29.23721821264391, -3.060282017766454],
            [29.215554177998087, -3.021663907520278],
            [29.185136083651258, -3.02131790255622],
            [29.148609258253231, -2.996108825771415],
            [28.990691184501628, -2.814308865787496],
            [28.985000207505692, -2.78430886077237],
            [29.000063325744378, -2.756664002918015],
            [29.024436056227984, -2.74471794684095],
            [29.041527092126586, -2.729445951574505],
            [29.053327135449848, -2.697081914581119],
            [29.047082282290575, -2.666526859936184],
            [29.062563322222758, -2.603263942628601],
            [29.094163264920581, -2.592217935317009],
            [29.140554081641113, -2.589163905067437],
            [29.279718149899537, -2.634445963632814],
            [29.328891255567925, -2.657081963773592],
            [29.325836051851951, -2.683326876155689],
            [29.354163364264792, -2.716946019334102],
            [29.351663210178543, -2.736946078556912],
            [29.337500140705373, -2.757081924611526],
            [29.358954124857092, -2.817781991196981],
            [29.380482205032962, -2.825490995207062],
            [29.443891135094333, -2.795835821689522],
            [29.464300231193221, -2.805972895418222],
            [29.535554119734542, -2.824445939516281],
            [29.570554097645896, -2.799999951198728],
            [29.597009228160147, -2.802363983176903],
            [29.615836156211259, -2.79389088487126],
            [29.630827190082385, -2.781108985407158],
            [29.695827173009008, -2.805626890454164],
            [29.739163288927699, -2.802917859340326],
            [29.821945144214538, -2.772781899855218],
            [29.856945122125893, -2.755555077124782],
            [29.876391137547188, -2.737499954720235],
            [29.906454174474419, -2.69368187936756],
            [29.921945105052345, -2.652781879794574],
            [29.920273250640292, -2.641946090615122],
            [29.916382203537381, -2.566390944628338],
            [29.935273169328894, -2.370835778580911],
            [29.946945137171411, -2.321108964387179],
            [29.952218192473794, -2.309446049000215],
            [30.004163361168054, -2.336108883075738],
            [30.09305411514427, -2.409164042614449],
            [30.112500130565678, -2.420417921125406],
            [30.158336065294378, -2.428335802163161],
            [30.285945177079014, -2.355990925101551],
            [30.356391217789991, -2.336390850299352],
            [30.393118203036437, -2.301564042508105],
            [30.442427095536658, -2.32604607300938],
            [30.465136185873263, -2.352363907949339],
            [30.50944510547734, -2.383473006395775],
            [30.561391279999924, -2.399863985739174],
            [30.573327110155191, -2.399163929184056],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BEL", Country: "Belgium" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [3.364722160400078, 51.373610748600029],
            [3.364906573767769, 51.372678041624638],
            [3.370863338858641, 51.373854255030167],
            [3.373609168275806, 51.310000097923862],
            [3.3801361562968, 51.275273202418688],
            [3.388327119740865, 51.268673124202039],
            [3.439863251559103, 51.244782185514211],
            [3.474718222545079, 51.242636083019207],
            [3.522982226992497, 51.25895397216685],
            [3.521736173262866, 51.283264173652483],
            [3.60000018272558, 51.304164114002774],
            [3.669163289332886, 51.292773107555419],
            [3.788745052423934, 51.262845019269676],
            [3.793818115516046, 51.230273111076926],
            [3.895418181674501, 51.205691168289476],
            [3.952500115925318, 51.214436007897049],
            [4.062218222969847, 51.252218107117997],
            [4.127782140343669, 51.278882114660107],
            [4.167218156703939, 51.297364211213662],
            [4.22166331184647, 51.33541805173634],
            [4.238899953133739, 51.350427189707588],
            [4.255836333775136, 51.336236338244319],
            [4.263563388235667, 51.317045319281377],
            [4.280645296138147, 51.301127283098864],
            [4.312009334109121, 51.286381896321231],
            [4.300081861440191, 51.267654301705605],
            [4.302372832331315, 51.263181845208194],
            [4.309681842125968, 51.262027332077864],
            [4.327699879322182, 51.290118303449091],
            [4.28676335552953, 51.312445325043498],
            [4.280181871790916, 51.344199828997105],
            [4.260981826600101, 51.355436299616045],
            [4.252363450425776, 51.3751451007697],
            [4.279673290419311, 51.376591133715621],
            [4.352918216246479, 51.361245042424926],
            [4.38805414862756, 51.357500175713895],
            [4.412918058638724, 51.358464262219812],
            [4.433473167491343, 51.370136062424095],
            [4.417782244703062, 51.398327085090941],
            [4.399027065743297, 51.413882221047388],
            [4.395691235908203, 51.451518139876654],
            [4.484300190298967, 51.480136136692423],
            [4.544336074874934, 51.483044992378709],
            [4.540418205681704, 51.454509138214107],
            [4.540345115485763, 51.431173249156373],
            [4.647636158640239, 51.423191162739997],
            [4.671145050180201, 51.432554083889229],
            [4.702082151973002, 51.466945032714236],
            [4.766182253772087, 51.499927151249565],
            [4.797636183716122, 51.501245121708379],
            [4.825836091200273, 51.492218147239484],
            [4.8454822664701, 51.475273124094585],
            [4.832782174381265, 51.429991065529109],
            [4.853054142543698, 51.414444982028144],
            [4.940482254411791, 51.402354086663863],
            [4.997082229228567, 51.436318061339747],
            [5.017154037542667, 51.470618150333976],
            [5.038473240691019, 51.486945091937045],
            [5.076809216075389, 51.469300179874992],
            [5.101245146109221, 51.434718123657248],
            [5.081073257870713, 51.401244993232538],
            [5.14194515347188, 51.319718076492748],
            [5.161527123363072, 51.315127140472597],
            [5.193473238662932, 51.319509199464889],
            [5.23333606153426, 51.309373131564584],
            [5.238973226711579, 51.262282090650942],
            [5.329718057675393, 51.262218052910399],
            [5.404373154896689, 51.266036009817569],
            [5.47423615042112, 51.28687325353188],
            [5.508336079567016, 51.294227032484329],
            [5.552918081577104, 51.269645089696965],
            [5.568336089597267, 51.220709191889043],
            [5.644718194153739, 51.203609103535015],
            [5.754163218792172, 51.189991193045799],
            [5.847136127264264, 51.153191117603583],
            [5.818054108287441, 51.115000149144421],
            [5.776945064048675, 51.025836145123819],
            [5.764163332222665, 50.99000015618752],
            [5.758054098257105, 50.960000151172309],
            [5.651391194928266, 50.875136063492832],
            [5.638818172492023, 50.848882098655238],
            [5.653609214152908, 50.823609151768252],
            [5.683609219168034, 50.811382134295897],
            [5.69393605918512, 50.808818110107367],
            [5.701945135329709, 50.805827111769915],
            [5.704236077112029, 50.782009095640049],
            [5.691909147353499, 50.760554105659949],
            [5.698609137856522, 50.757773157816658],
            [5.739863189020753, 50.759854049104732],
            [5.797500172901294, 50.769436073203607],
            [5.870554158973448, 50.760827020428152],
            [5.898918184122209, 50.753882110713548],
            [5.926391212961022, 50.756100129938105],
            [6.011800124348525, 50.757273261109773],
            [6.054718150935713, 50.723609190930219],
            [6.167218211651942, 50.656391188779182],
            [6.270418215493265, 50.619853969821278],
            [6.247773162896891, 50.589164134172833],
            [6.264718186041904, 50.497773226110539],
            [6.313473202378589, 50.497218176480729],
            [6.339163232768868, 50.486936095826636],
            [6.373400122213155, 50.443191110669702],
            [6.398200162121782, 50.323173153336327],
            [6.349718060553187, 50.312773223123216],
            [6.280836082979107, 50.283609061495056],
            [6.16666332966031, 50.224718144895959],
            [6.139718193085002, 50.154445106667126],
            [6.134409095598841, 50.127844969227382],
            [6.120000101085822, 50.131945060996244],
            [6.030554129841789, 50.163053991804603],
            [6.026254045862572, 50.181253953496352],
            [6.017918075493327, 50.181809170764254],
            [5.97305410625944, 50.170000074985396],
            [5.887709232612593, 50.097427042518845],
            [5.883054091214007, 50.077218106268262],
            [5.834445087630854, 49.98693612399903],
            [5.778609207109724, 49.957009041541866],
            [5.747773191069484, 49.907491104375808],
            [5.734445126792991, 49.835000046922417],
            [5.752636036029344, 49.792282180183648],
            [5.790282180780594, 49.780827135995949],
            [5.869445065542209, 49.719718032534203],
            [5.876154108500629, 49.709882204406611],
            [5.899163270971883, 49.662773226219954],
            [5.900282255048921, 49.640545089488782],
            [5.863054031991197, 49.571664117743197],
            [5.835973276222148, 49.549718115873631],
            [5.807882165841391, 49.545045037202144],
            [5.79221806514326, 49.54582706877072],
            [5.702218217735918, 49.543053999916438],
            [5.631945011868908, 49.535827122978404],
            [5.593336121716305, 49.526382226815812],
            [5.522500154868538, 49.510000132289818],
            [5.472782225492153, 49.50888215404099],
            [5.474927154520742, 49.535900213174259],
            [5.432500139822935, 49.603473105106659],
            [5.406809103604331, 49.617354039718194],
            [5.392309081622557, 49.622554004824607],
            [5.331109118330232, 49.656664159892486],
            [4.990554067741101, 49.803609148890345],
            [4.959163337347206, 49.802218088235421],
            [4.937291263863983, 49.791873143307541],
            [4.909718155101018, 49.789300066663415],
            [4.868473156392355, 49.802218088235421],
            [4.820836118304214, 49.992773113748697],
            [4.838891240708563, 50.06916427076051],
            [4.853336109767412, 50.095554022429937],
            [4.885900138971039, 50.134164253687189],
            [4.824727165407126, 50.167573178733178],
            [4.76305412749835, 50.135553973237464],
            [4.693745175775263, 50.084018176695466],
            [4.69340017663967, 50.056664170881945],
            [4.676663192332029, 49.999164147299709],
            [4.510554155136475, 49.947491222821313],
            [4.458127194646323, 49.939227001543529],
            [4.43000909453724, 49.945009173646255],
            [4.413336147970313, 49.949164250699866],
            [4.317218181779822, 49.969718018448006],
            [4.239582144504936, 49.963464112833307],
            [4.215263225839976, 49.959600055458523],
            [4.178618215606775, 49.978136131468617],
            [4.149236124495445, 49.978373171691189],
            [4.147218097481243, 50.009991219299806],
            [4.171391171030564, 50.137218116298683],
            [4.194445092864783, 50.237500211998039],
            [4.165000137479581, 50.283054011865332],
            [4.035754040000199, 50.355991154206961],
            [3.983891181595368, 50.345273214586413],
            [3.889718152223196, 50.34000015928406],
            [3.774718105058611, 50.353882099723975],
            [3.676945048262667, 50.39527311118654],
            [3.671109231979472, 50.4370731595249],
            [3.660400177176513, 50.455273121216678],
            [3.613963259988452, 50.492936029774441],
            [3.518054003187615, 50.518882211126581],
            [3.454582208852372, 50.510409112820739],
            [3.370000088396409, 50.495273239662467],
            [3.297082224432273, 50.524300105716165],
            [3.279309236889247, 50.540000080959842],
            [3.242636063461447, 50.664164230529806],
            [3.197782152511365, 50.727218103171637],
            [3.187218104633843, 50.746664118593017],
            [3.182873261291661, 50.757045105704819],
            [3.15857328572784, 50.784364242800891],
            [3.116391190241387, 50.79166420993478],
            [3.046245054026969, 50.778745014896217],
            [3.026991151826905, 50.769845110079629],
            [3.014309164648893, 50.769864220819031],
            [2.960554007777972, 50.757500075410363],
            [2.943054102641327, 50.741391063290578],
            [2.898054011299564, 50.703045029622203],
            [2.867500130120931, 50.713382095561158],
            [2.781945038341831, 50.755553965125713],
            [2.650554011833748, 50.816109192423838],
            [2.597782219846124, 50.925827131830474],
            [2.597500252622496, 50.995827087653296],
            [2.541663346038604, 51.091109178805382],
            [2.543611000000169, 51.093611],
            [2.542982666000142, 51.094330400000146],
            [2.390278, 51.26917],
            [2.23972762000011, 51.554717018000147],
            [2.238118570400133, 51.557769027400113],
            [2.253333020000127, 51.613056017000147],
            [2.48166700000013, 51.805000000000163],
            [2.539443826000081, 51.869999806000081],
            [3.081389, 51.551667],
            [3.212222, 51.484722000000104],
            [3.296389, 51.45],
            [3.353889000000152, 51.379444],
            [3.364722160400078, 51.373610748600029],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BEN", Country: "Benin" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [3.604454158447339, 11.693273089757653],
            [3.571245057973442, 11.650973144712481],
            [3.525282221230214, 11.563609070584931],
            [3.475000189768679, 11.429718057628776],
            [3.608863207168042, 11.250000162399402],
            [3.694718203443671, 11.13471798037331],
            [3.714163213036642, 11.12721802102898],
            [3.73577326822587, 11.120636047723039],
            [3.728745042393626, 11.077500091652666],
            [3.730554024741878, 11.040554003456649],
            [3.737636062392795, 11.020273150476626],
            [3.775282207144016, 10.898609154992926],
            [3.759445103964026, 10.847500164961758],
            [3.749100159035891, 10.829445042557225],
            [3.751245088064508, 10.798744980986982],
            [3.785554061876155, 10.756944932648338],
            [3.830836120441518, 10.711945008944568],
            [3.847500182191226, 10.69652700092449],
            [3.855000141535442, 10.58500007916949],
            [3.812782171503471, 10.449445090790462],
            [3.791527173733726, 10.404164205691472],
            [3.763891200696776, 10.412773090828949],
            [3.734445071844988, 10.429582159503866],
            [3.699373177204166, 10.448300122813407],
            [3.638609072878182, 10.413609101853979],
            [3.581945060321061, 10.275273165631575],
            [3.644163257213933, 10.199444937238582],
            [3.670345137683967, 10.186453993108728],
            [3.684163208021346, 10.160836047085994],
            [3.677918187224236, 10.111809121809316],
            [3.612009103076616, 9.954100092723465],
            [3.558891138486757, 9.880282179993657],
            [3.520973252433777, 9.845553943384019],
            [3.483545037165726, 9.855691184750711],
            [3.34958227511828, 9.811454014238038],
            [3.334027139161805, 9.783327029311636],
            [3.33097327655048, 9.762364224687573],
            [3.337218129709697, 9.735000160590431],
            [3.352782150483563, 9.703054045290486],
            [3.363327087621798, 9.681936007818948],
            [3.317218070486888, 9.63610912554573],
            [3.171391227375665, 9.496391181123883],
            [3.14180914405398, 9.435136064908619],
            [3.148609214481155, 9.412218097544041],
            [3.163191211476203, 9.377364132386475],
            [3.166391254479493, 9.302773072905609],
            [3.131391108930046, 9.194445020687425],
            [3.095000238001916, 9.090554012746651],
            [2.996245157427211, 9.063191122115825],
            [2.967500090958879, 9.081664166213869],
            [2.905282229342077, 9.078891097359502],
            [2.813609186418091, 9.065273186870385],
            [2.792082279708609, 9.050418107469142],
            [2.783054131773071, 9.016664183287389],
            [2.745282258473708, 8.795273141733873],
            [2.742954101041448, 8.770764121504286],
            [2.746945060430477, 8.719445080978886],
            [2.756391130059455, 8.581944988143505],
            [2.756391130059455, 8.555273101612485],
            [2.752963266927253, 8.535573114524283],
            [2.757427133294726, 8.530136109195212],
            [2.758336066877717, 8.491109129710935],
            [2.750000096508359, 8.457500044816598],
            [2.725418153720909, 8.432009000808094],
            [2.709927055504977, 8.342291120624253],
            [2.736045065872588, 8.245764117557812],
            [2.751736156298961, 8.209654040386823],
            [2.741391211370967, 8.156109101647942],
            [2.727500218475569, 8.108327056634309],
            [2.708891219907599, 8.00805401339035],
            [2.692500240564186, 7.906391082957768],
            [2.695282194235887, 7.882218009408334],
            [2.702927160505425, 7.865035946041047],
            [2.73937318435685, 7.814444957627572],
            [2.742918058857555, 7.789582053444903],
            [2.738609090061118, 7.716391107074386],
            [2.732954155248962, 7.658209131847912],
            [2.732218056510021, 7.65194500031123],
            [2.736663315052539, 7.596391087013728],
            [2.770000155731168, 7.254444975769744],
            [2.778609208506708, 7.134444955709],
            [2.74971812928473, 7.094164211144346],
            [2.727918140169066, 6.947009004014234],
            [2.75187328423533, 6.921245045237697],
            [2.762782163610439, 6.76500016700254],
            [2.761663179533485, 6.671391072077611],
            [2.75541815873612, 6.565136032158577],
            [2.727218083613849, 6.474718095419533],
            [2.719609159528034, 6.452235987021467],
            [2.719609159764019, 6.365509105087682],
            [2.727717583000242, 6.305287846000155],
            [2.771742261000099, 5.99259578200008],
            [2.783800186000178, 5.900319837000097],
            [2.841755003000173, 5.423339101000209],
            [2.667506767333407, 3.013626390000113],
            [2.60906350199997, 3.026096355],
            [2.607776501999979, 3.025753355000035],
            [2.606194502, 3.025616355000068],
            [2.603607502000102, 3.02492635500009],
            [2.59455800200007, 3.024137355000093],
            [2.590783502000136, 3.023802354999987],
            [2.588711502000024, 3.023250355000059],
            [2.551404502000111, 3.020026355000027],
            [2.547957002000146, 3.019147854999986],
            [2.544545002000177, 3.018333855000051],
            [2.541469002000127, 3.017570855000073],
            [2.538048002000153, 3.01669185500009],
            [2.534889502000112, 3.015878355000012],
            [2.52951000200008, 3.015349855000068],
            [2.528252502000129, 3.015014355000019],
            [2.505485502000113, 3.013045355000088],
            [2.489337502000097, 3.011647355000051],
            [2.474144502000058, 3.007592355000099],
            [2.42114550200003, 2.993468855000089],
            [2.417963502, 2.992643355],
            [2.41382250200013, 2.992284355000052],
            [2.412191501999985, 2.99184835500003],
            [2.410321502000073, 2.991686355000041],
            [2.229740502000027, 3.794886355000045],
            [2.022745502000021, 4.639812356000036],
            [1.931737502000146, 5.024058357000172],
            [1.759511502000123, 5.754271357000107],
            [1.709177502000045, 5.952697357000147],
            [1.638774502000075, 6.197633358000147],
            [1.635399748362829, 6.218718322112863],
            [1.629773150853936, 6.235135976991657],
            [1.652500178463583, 6.250282076072011],
            [1.683063279735705, 6.259591185402599],
            [1.742500193509017, 6.269718033209415],
            [1.797800135139624, 6.280253917892423],
            [1.795282211418822, 6.308335975817499],
            [1.771527059562914, 6.414864098142914],
            [1.749163303637999, 6.456664146481472],
            [1.693054005434533, 6.549164147974992],
            [1.664027139380636, 6.577008998039702],
            [1.655418086605181, 6.570673117411729],
            [1.647500205567326, 6.573053913196247],
            [1.616809196452493, 6.596244962966935],
            [1.57528223052023, 6.679717989991474],
            [1.590136136454987, 6.715691106864256],
            [1.607500087121821, 6.796664147440623],
            [1.604163251458203, 6.894309128755793],
            [1.577027175128052, 6.909273172898622],
            [1.562363203119844, 6.948191187641228],
            [1.553882225825163, 6.996564156830232],
            [1.642500232671267, 6.995553969856871],
            [1.645554095282677, 7.333891168859452],
            [1.643745112934312, 7.409673129146825],
            [1.644445169489529, 7.443054058636278],
            [1.650136146485409, 7.51389103131244],
            [1.655418086605181, 7.529773061493557],
            [1.650691196115218, 7.547782083430562],
            [1.641945015403365, 7.628191189559885],
            [1.639309074485396, 7.759864183291398],
            [1.638054135938347, 7.998609117227673],
            [1.630973271754016, 8.360282205288257],
            [1.61705411566399, 8.36710909780578],
            [1.624163310681013, 8.425282188214922],
            [1.625400144317155, 8.587282081186402],
            [1.626109085689592, 8.726664078927996],
            [1.626663297129085, 8.84694506038386],
            [1.619718219776587, 9.034236005153318],
            [1.605973239635063, 9.09916407135043],
            [1.568609229745533, 9.167918141081714],
            [1.54000011774707, 9.189653924818941],
            [1.510554156533459, 9.210836000030739],
            [1.49360913338856, 9.228336072805462],
            [1.452454156320272, 9.28041803415995],
            [1.431627138527745, 9.301245051952378],
            [1.418054155039755, 9.319027091950716],
            [1.406945115816228, 9.34471812816939],
            [1.399445156471899, 9.387918121980206],
            [1.398545107706326, 9.429900057618212],
            [1.389927170113452, 9.486245054939602],
            [1.364254071167494, 9.481344994329532],
            [1.361109181087414, 9.48555405083988],
            [1.3375002096233, 9.542500030620715],
            [1.368336058025363, 9.596664224367842],
            [1.370000201086583, 9.636391092769259],
            [1.358891161862857, 9.914718055819719],
            [1.355000114759946, 9.99528205951998],
            [1.307782171831718, 10.025273179717772],
            [1.187218217323732, 10.100000026030557],
            [1.17240018593472, 10.108600026350615],
            [1.066945114856793, 10.174445072757621],
            [0.999373228752773, 10.220073136287056],
            [0.776663210911948, 10.376664187124263],
            [0.787500173557873, 10.529309037935519],
            [0.798053995513612, 10.546664103784934],
            [0.805973217655747, 10.573609072722249],
            [0.807500232780683, 10.607217989978679],
            [0.804027107371184, 10.641109042096716],
            [0.800282240659868, 10.68110916054232],
            [0.800554149599776, 10.701664101756947],
            [0.802191135294407, 10.712445073289345],
            [0.80708214344898, 10.723327130574489],
            [0.879445125421597, 10.799718119948224],
            [0.893609200723205, 10.86694500691658],
            [0.890553997007231, 10.911109087233314],
            [0.917973214027484, 10.996400149061714],
            [0.950282265735837, 11.029444964232923],
            [0.985282243647191, 11.079445028470843],
            [1.083054126976776, 11.122500015356351],
            [1.14889112675678, 11.224445080650639],
            [1.192782124667218, 11.265273163494172],
            [1.264718132490884, 11.246109115296491],
            [1.343818152978457, 11.315000145325982],
            [1.328054139994322, 11.342918085586447],
            [1.390000092671215, 11.433473150261889],
            [1.416809107138619, 11.449718116851585],
            [1.428600098006569, 11.451464234925979],
            [1.435282151236692, 11.458891104074368],
            [1.482500094164919, 11.459718062643987],
            [1.543191108294963, 11.457773125825724],
            [1.570000122762281, 11.451109009868432],
            [1.586391102105779, 11.405627126730792],
            [1.61236326735775, 11.388473058920127],
            [1.736945170983063, 11.415835949550868],
            [1.803891264194277, 11.439445088652974],
            [1.874654140846189, 11.442773207137066],
            [1.897182182073692, 11.431500050248914],
            [1.989309188874927, 11.417082170918391],
            [2.009863291899336, 11.420553955223411],
            [2.020036072535589, 11.426073103203649],
            [2.026945107704279, 11.431109118283715],
            [2.052218054591549, 11.454718089747828],
            [2.193891161753442, 11.603609021392373],
            [2.262218089697626, 11.645973171816152],
            [2.308891208918027, 11.688327096318133],
            [2.340836150751414, 11.768609132794779],
            [2.392500190412477, 11.887218092200683],
            [2.397927137457572, 11.896154039200894],
            [2.401663286989447, 11.957218048023321],
            [2.403609229636118, 12.084444945022099],
            [2.388745097779378, 12.129718118770057],
            [2.376391178292607, 12.206664157773687],
            [2.38836321826966, 12.247318064668804],
            [2.535836099531053, 12.281109036862773],
            [2.611673212741437, 12.292500043310014],
            [2.665009106814381, 12.302773071508625],
            [2.719036172625323, 12.352491168523017],
            [2.780282236385261, 12.38193595627024],
            [2.838618103354463, 12.396654075373263],
            [2.864591106796752, 12.381800169438506],
            [3.010036070398286, 12.268582114894059],
            [3.162282277893325, 12.113291097242836],
            [3.255454005109272, 12.011354078575835],
            [3.261663318998984, 11.977773156876026],
            [3.27000012755866, 11.951944992720968],
            [3.298054022289023, 11.910273187501375],
            [3.320900240562139, 11.886182088965214],
            [3.356527184832686, 11.885691077075933],
            [3.375136183400656, 11.894718051544828],
            [3.471245097135579, 11.854718100737301],
            [3.507918102925487, 11.824164219558853],
            [3.546109239022655, 11.774444949077974],
            [3.557845076967567, 11.736600153220948],
            [3.604454158447339, 11.693273089757653],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BES", Country: "Bonaire, Sint-Eustasius, Saba" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-63.290176064999969, 17.873241763000081],
            [-63.284350917000012, 17.87193953200007],
            [-63.20385, 17.848431],
            [-63.19658, 17.846306],
            [-63.1911, 17.844867],
            [-63.18378, 17.842944],
            [-63.17731, 17.840667],
            [-63.16781, 17.8378060000001],
            [-63.15783, 17.834333],
            [-63.13811, 17.827667],
            [-63.13172, 17.824861],
            [-63.110196272999929, 17.815746009000136],
            [-63.102816314999927, 17.812076913000041],
            [-63.048822563000016, 17.769442586000039],
            [-63.020592054999952, 17.735150815000097],
            [-63.026028, 17.725194],
            [-63.04797, 17.685],
            [-63.06775, 17.650194],
            [-63.09661, 17.598333],
            [-63.106471999999883, 17.57994400000014],
            [-63.11106, 17.571583],
            [-63.120194, 17.552583],
            [-63.12764, 17.537361],
            [-63.15158, 17.495556],
            [-63.19861, 17.416861],
            [-63.63789652, 16.681776071999977],
            [-63.986933271999959, 16.739807270000014],
            [-64.018500022999916, 16.745055589000131],
            [-64.017803737999969, 16.750874159000077],
            [-64.017330214999959, 16.754830809000154],
            [-64.015814124999906, 16.767498889000095],
            [-64.014844776999922, 16.775596636000145],
            [-64.013824039000013, 16.784123695000105],
            [-64.011833763999931, 16.800748392000131],
            [-64.009843016999866, 16.817372978000023],
            [-64.007851986999981, 16.833997635000046],
            [-64.005860568499912, 16.850622273000042],
            [-64.003868888999932, 16.86724669900012],
            [-64.00187682, 16.883871192000115],
            [-64.000688388999947, 16.893787104000168],
            [-63.999884373999947, 16.900495568000096],
            [-63.99789155, 16.917119914000082],
            [-63.995898441999913, 16.933744321000077],
            [-63.995451708999923, 16.937469769000117],
            [-63.993904957000012, 16.950368604000047],
            [-63.992898536999945, 16.958759795000148],
            [-63.991911093999875, 16.966992763000107],
            [-63.990814188999906, 16.976137122000083],
            [-63.989916948, 16.983616976000079],
            [-63.988560591999857, 16.994922082000116],
            [-63.987922425, 17.000241152000044],
            [-63.985927522999958, 17.016865198000104],
            [-63.983932243999988, 17.033489294000063],
            [-63.982438316999918, 17.045934398000114],
            [-63.981936681999883, 17.050113257000092],
            [-63.979940741999854, 17.06673726700005],
            [-63.978921822999837, 17.075222080000074],
            [-63.977944423999958, 17.083361140000093],
            [-63.976872519999915, 17.09347326400011],
            [-63.975107540999915, 17.110121328000091],
            [-63.97334218499995, 17.126769338000074],
            [-63.971576547999945, 17.143417380000059],
            [-63.969810622999859, 17.160065277000044],
            [-63.969499681999878, 17.162996001000053],
            [-63.968044322999901, 17.176713204000109],
            [-63.966929453999853, 17.187219472000052],
            [-63.966277739999924, 17.19336107000008],
            [-63.964868645999871, 17.206269339000087],
            [-63.963610514999971, 17.21779255400007],
            [-63.963051545999974, 17.222912154000099],
            [-63.961234161999982, 17.239554905000077],
            [-63.959416399999952, 17.256197680000085],
            [-63.957598355999892, 17.272840296000069],
            [-63.956882407999984, 17.279392818000176],
            [-63.955779932999889, 17.289482932000155],
            [-63.954844704999971, 17.298041040000086],
            [-63.953961226999866, 17.306125588000143],
            [-63.952142237999908, 17.322768170000117],
            [-63.951236917999978, 17.331049519000075],
            [-63.95032287199993, 17.339410677000089],
            [-63.94954232099991, 17.346549185000086],
            [-63.948503126999981, 17.356053108000069],
            [-63.946683099999859, 17.372695550000103],
            [-63.945389995999989, 17.384517870000067],
            [-63.944862788999984, 17.389337913000176],
            [-63.944358779999931, 17.393944905000055],
            [-63.943042099999872, 17.405980284000137],
            [-63.941221127999938, 17.422622572000094],
            [-63.940113443999849, 17.432743803000065],
            [-63.939399778999956, 17.439264775000041],
            [-63.937578050999946, 17.455906982000087],
            [-63.935756040999905, 17.472549190000123],
            [-63.933933746999941, 17.489191309000049],
            [-63.93344103899986, 17.493690013000148],
            [-63.932111074999966, 17.505833336000094],
            [-63.930623889666577, 17.518998695666696],
            [-63.928736068999939, 17.535708493000172],
            [-63.926856175999916, 17.552344663000056],
            [-63.926065325999957, 17.55934221800014],
            [-63.92498, 17.568980736000114],
            [-63.923578996999936, 17.581339207000028],
            [-63.92309544699998, 17.585616891000015],
            [-63.921214514999974, 17.602252946000092],
            [-63.919333300999824, 17.618888898000037],
            [-63.918824133999976, 17.62347220700012],
            [-63.918308891999885, 17.628110212000067],
            [-63.917472046999933, 17.635642146000137],
            [-63.916460158999968, 17.644749545000067],
            [-63.914611142999917, 17.661388861000134],
            [-63.913200066999963, 17.676894717000025],
            [-63.912050167999951, 17.689527460000065],
            [-63.911682668999902, 17.693564783000156],
            [-63.911299538999913, 17.697773302000016],
            [-63.910165081999935, 17.7102348210001],
            [-63.908647211999835, 17.726904831000112],
            [-63.908098821999943, 17.732926388000081],
            [-63.907129058999914, 17.743574809000066],
            [-63.905614814666592, 17.760197614000091],
            [-63.904091807999862, 17.77691457600001],
            [-63.902572804999949, 17.7935843610001],
            [-63.901337791999907, 17.807135109000072],
            [-63.90105351899993, 17.810254199000141],
            [-63.900841374999942, 17.812581415000139],
            [-63.899533948999959, 17.82692390800004],
            [-63.898860465000013, 17.834310684000073],
            [-63.898325906999929, 17.840173720000067],
            [-63.898014095999969, 17.843593665000057],
            [-63.897214133999938, 17.852365951000067],
            [-63.896493959999958, 17.860263289000059],
            [-63.895164939999859, 17.874834473000064],
            [-63.894973539999938, 17.876932959000058],
            [-63.894631124999933, 17.880686185000101],
            [-63.893452742999955, 17.893602493000046],
            [-63.892566164999863, 17.903319133000124],
            [-63.891931756999867, 17.91027206800014],
            [-63.890762310999918, 17.923086437000094],
            [-63.89041048799993, 17.926941595000116],
            [-63.89021677249994, 17.929063850000134],
            [-63.888888934999898, 17.943611069000113],
            [-63.891192389399919, 17.94877690140008],
            [-63.753506078999891, 17.947573936000069],
            [-63.657983726999959, 17.950580837000089],
            [-63.36139501599996, 17.887225751000088],
            [-63.290176064999969, 17.873241763000081],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BFA", Country: "Burkina Faso" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.235045078045346, 14.915064114334697],
            [0.232363204297911, 14.880209143348722],
            [0.211945055743513, 14.8638910865631],
            [0.196391260891602, 14.842082212630018],
            [0.241391184595301, 14.752217984416305],
            [0.225282172475488, 14.700836079616934],
            [0.166663332454192, 14.523054068750426],
            [0.189445010072632, 14.464718034143161],
            [0.233027051031229, 14.425418139890766],
            [0.296391221729465, 14.367218059753284],
            [0.321109118986811, 14.362782188942433],
            [0.38471820889643, 14.283609078258863],
            [0.40874510205407, 14.245135974938094],
            [0.392363175166082, 14.18069104164131],
            [0.354827168622904, 14.130973112265011],
            [0.465000245373176, 13.913609072284416],
            [0.515554018136527, 13.85416411188389],
            [0.607573233662663, 13.698818108947819],
            [0.62500021624146, 13.684164195223318],
            [0.786391247764556, 13.668891194128548],
            [0.996663191810541, 13.565973157511024],
            [1.015836124825739, 13.523818051752997],
            [1.015282248662317, 13.487844934880215],
            [1.031663337359987, 13.462500071263676],
            [1.12389126227572, 13.413327133233381],
            [1.170836122797738, 13.396391162543878],
            [1.206527104808828, 13.387500142544795],
            [1.242954017920681, 13.38979108432703],
            [1.276518175814147, 13.348054067900605],
            [1.179445007935215, 13.313473185149149],
            [1.107782250155935, 13.335282059082317],
            [1.073336148407975, 13.350554054348677],
            [1.043891193022574, 13.363609036218975],
            [1.011663278137178, 13.372773138624353],
            [0.991663218914397, 13.371664212831107],
            [0.990836092706672, 13.336664234919752],
            [0.989245039841506, 13.090564180926378],
            [0.989163232466296, 13.047218006723767],
            [1.011391201559292, 13.023891170121345],
            [1.117218093863016, 13.011109103019251],
            [1.32229124626889, 12.842518016648356],
            [1.578336093131639, 12.630000057821178],
            [1.871663310146744, 12.608891072805136],
            [1.928054072659677, 12.700554057445316],
            [1.988891267181515, 12.731109112090337],
            [2.106391133155995, 12.714718132746938],
            [2.142291159832922, 12.694100159620263],
            [2.200836071467876, 12.630836068846207],
            [2.220836130690685, 12.59360901925497],
            [2.25610919100825, 12.481109126176548],
            [2.23555408215563, 12.418473175228243],
            [2.16166324686796, 12.415553925982167],
            [2.076945171942299, 12.383473197316874],
            [2.058336173374329, 12.35721805901288],
            [2.320873139540737, 12.000000120140612],
            [2.392091153536285, 11.904009055964693],
            [2.397927137457572, 11.896154039200894],
            [2.392500190412477, 11.887218092200683],
            [2.340836150751414, 11.768609132794779],
            [2.308891208918027, 11.688327096318133],
            [2.262218089697626, 11.645973171816152],
            [2.193891161753442, 11.603609021392373],
            [2.052218054591549, 11.454718089747828],
            [2.026945107704279, 11.431109118283715],
            [2.020036072535589, 11.426073103203649],
            [2.009863291899336, 11.420553955223411],
            [1.989309188874927, 11.417082170918391],
            [1.897182182073692, 11.431500050248914],
            [1.874654140846189, 11.442773207137066],
            [1.803891264194277, 11.439445088652974],
            [1.736945170983063, 11.415835949550868],
            [1.61236326735775, 11.388473058920127],
            [1.586391102105779, 11.405627126730792],
            [1.570000122762281, 11.451109009868432],
            [1.543191108294963, 11.457773125825724],
            [1.482500094164919, 11.459718062643987],
            [1.435282151236692, 11.458891104074368],
            [1.428600098006569, 11.451464234925979],
            [1.416809107138619, 11.449718116851585],
            [1.390000092671215, 11.433473150261889],
            [1.328054139994322, 11.342918085586447],
            [1.343818152978457, 11.315000145325982],
            [1.264718132490884, 11.246109115296491],
            [1.192782124667218, 11.265273163494172],
            [1.14889112675678, 11.224445080650639],
            [1.083054126976776, 11.122500015356351],
            [0.985282243647191, 11.079445028470843],
            [0.950282265735837, 11.029444964232923],
            [0.917973214027484, 10.996400149061714],
            [0.811945156046932, 10.99694497276964],
            [0.72444512744957, 10.99694497276964],
            [0.666109092842305, 10.996109129382688],
            [0.630836032524741, 10.983891164365815],
            [0.504163179327378, 10.936944962739403],
            [0.514863181674912, 10.975418066060172],
            [0.505554072344125, 11.004582060050353],
            [0.416945117953617, 11.024444991336765],
            [0.218891138924505, 11.064164148387064],
            [0.190000227340505, 11.07055400847176],
            [0.05028211528068, 11.098891043892323],
            [-0.089717796364795, 11.127218021028895],
            [-0.142217847050773, 11.137782068906603],
            [-0.14976390686283, 11.138536104918373],
            [-0.176945916022419, 11.144718093803618],
            [-0.28687290009492, 11.155691178557348],
            [-0.364163938234213, 11.084718083773382],
            [-0.618472892944339, 10.913891040905099],
            [-0.666108757566008, 10.956809067492301],
            [-0.678608857444573, 10.988609002400281],
            [-0.679836806263211, 10.997800094533972],
            [-0.784445975670195, 10.99805406620095],
            [-0.902217750584498, 11.006664124804914],
            [-0.986945883793965, 11.014445045544363],
            [-1.091945985166234, 11.005973120705193],
            [-1.3727818194821, 10.999718041624007],
            [-1.419672868185472, 11.026473076634687],
            [-1.560181896631207, 11.023564220948586],
            [-1.570836804339621, 11.000273091253817],
            [-1.728608865337605, 11.000273091253817],
            [-1.991390918353488, 11.000273091253817],
            [-2.299999853642589, 11.001109102278846],
            [-2.734999842543516, 11.001944945665798],
            [-2.834045942797417, 11.002009151044319],
            [-2.833608742726511, 10.957500071592023],
            [-2.836945913666113, 10.885827087890831],
            [-2.906945869488936, 10.737082168999819],
            [-2.927526794603381, 10.708144989310412],
            [-2.916108798427814, 10.655282169853834],
            [-2.916390933289506, 10.598054055211392],
            [-2.891945950800277, 10.519445046613015],
            [-2.833890877588317, 10.365553974434235],
            [-2.790554929307575, 10.270827068898839],
            [-2.771390881109909, 10.21694501001322],
            [-2.794445976410515, 10.163054066310366],
            [-2.795417774267349, 10.055000102326787],
            [-2.7772178125756, 10.008054068338268],
            [-2.763190865210362, 9.800554019680163],
            [-2.794163841548908, 9.732364219672363],
            [-2.785072829339384, 9.688191086900133],
            [-2.751526776356911, 9.656391151992054],
            [-2.744663841655665, 9.65303604377965],
            [-2.76139076767933, 9.627364118300278],
            [-2.769581898761459, 9.60083606522808],
            [-2.768954932402096, 9.569718081964226],
            [-2.745072878531772, 9.544100135941491],
            [-2.714036702643199, 9.521191053394418],
            [-2.689163907814816, 9.487500161124629],
            [-2.685563880390816, 9.481818068946168],
            [-2.731663845070415, 9.410836089344613],
            [-2.757017761142436, 9.395691163730817],
            [-2.779717799023672, 9.403609044768572],
            [-2.801390886124921, 9.423891071214996],
            [-2.850836738923363, 9.481391094797246],
            [-2.920972816854146, 9.576809004432448],
            [-2.937499918305548, 9.627773155176371],
            [-2.969163898743716, 9.69986422820908],
            [-2.986108754250466, 9.724444997530043],
            [-3.123890814309448, 9.836109047221427],
            [-3.208608889235308, 9.901391165009613],
            [-3.394163941852241, 9.920000163577569],
            [-3.63708189011524, 9.954445091859029],
            [-3.706390841838243, 9.943609135041683],
            [-3.75360878476647, 9.935000082266129],
            [-3.882217857602768, 9.897782085130302],
            [-3.890836801023966, 9.894236037162955],
            [-3.903472855372428, 9.882218064356422],
            [-3.936390768529236, 9.863054016158657],
            [-4.034163825325209, 9.806945053231345],
            [-4.124163840370727, 9.829309144432315],
            [-4.262008764703694, 9.742918041628187],
            [-4.279445973204446, 9.675827109129642],
            [-4.283054885445779, 9.651664093864014],
            [-4.309717887159366, 9.601109147634276],
            [-4.312454913829924, 9.599973064474725],
            [-4.330490925495155, 9.615282107753217],
            [-4.426108827340727, 9.657500077785301],
            [-4.514717781731406, 9.690273151654779],
            [-4.604999764000468, 9.720691078363416],
            [-4.655208872904154, 9.702918090820575],
            [-4.704445848674993, 9.698054072394228],
            [-4.775281815522845, 9.736664136013388],
            [-4.810208870876238, 9.777845096981608],
            [-4.785417883423037, 9.782917992435628],
            [-4.786390854746372, 9.828054038247288],
            [-4.830281852657009, 9.862500139995319],
            [-4.949717770632333, 9.948609107937841],
            [-4.990281823525095, 10.063891122325956],
            [-5.128190785598576, 10.303053977955742],
            [-5.243890889318266, 10.319718039705435],
            [-5.268608786575641, 10.319164163542098],
            [-5.307499811589906, 10.305273170646771],
            [-5.33833683345847, 10.296945079266436],
            [-5.371108901499554, 10.289444952284029],
            [-5.404099904852217, 10.297636083366157],
            [-5.470281903767955, 10.348054069297589],
            [-5.520836682359516, 10.427773177155601],
            [-5.519845941401542, 10.436273097551393],
            [-5.483608794577975, 10.52721808836354],
            [-5.473336772207773, 10.632500156959338],
            [-5.464308791910383, 10.699309122234183],
            [-5.456208855935103, 10.721653935057859],
            [-5.444726822019106, 10.763335966199335],
            [-5.411181942503106, 10.8416641810404],
            [-5.419163861281305, 10.869444993364667],
            [-5.443336767192648, 10.897218094337987],
            [-5.479446006173333, 11.002218028072079],
            [-5.486526870357665, 11.033473139272232],
            [-5.485626821592177, 11.077291046986829],
            [-5.463608902993258, 11.086944987815173],
            [-5.394163829162352, 11.094444947159403],
            [-5.362636809022376, 11.101391030340309],
            [-5.321945854115143, 11.120135983378177],
            [-5.299445976082268, 11.139445038501265],
            [-5.24784597416172, 11.252636103317386],
            [-5.208608776545219, 11.461664172928636],
            [-5.201663866830785, 11.538191116772282],
            [-5.223890830095485, 11.588817979731473],
            [-5.268608786575641, 11.686945088118605],
            [-5.287499920005189, 11.788053974749687],
            [-5.273054883308447, 11.843891028737289],
            [-5.168336749159891, 11.93555401337747],
            [-5.147081919028238, 11.952773124756973],
            [-5.117499835706525, 11.964445092599433],
            [-5.079717736485406, 11.975553964184996],
            [-4.942781913373324, 12.008609005278089],
            [-4.698608858925411, 12.062773199025315],
            [-4.633426821061391, 12.067218122291649],
            [-4.630417885451038, 12.093327080203849],
            [-4.624863868753465, 12.115409036543127],
            [-4.569445909925776, 12.201109135247634],
            [-4.482499757491752, 12.274718003311676],
            [-4.417499774565243, 12.300827128861954],
            [-4.436108773133128, 12.429300079590263],
            [-4.418608867996483, 12.472636027870891],
            [-4.397081793648937, 12.504864110394379],
            [-4.370626830772693, 12.528744990798302],
            [-4.428754994180679, 12.629027086497842],
            [-4.453263846772188, 12.638126983524685],
            [-4.474926707951568, 12.665273118138742],
            [-4.470281792474879, 12.713609039315614],
            [-4.468890899458103, 12.723891119969636],
            [-4.288890869367037, 12.715000099970467],
            [-4.263199833148377, 12.717782053642154],
            [-4.221108765130793, 12.735282126416877],
            [-4.20652676813566, 12.769164126079602],
            [-4.194445931055157, 12.828609086480114],
            [-4.207363952627162, 12.948473152070946],
            [-4.217636813187681, 12.969582137086903],
            [-4.255345989851065, 12.996454015828277],
            [-4.277217728058105, 13.021664098441477],
            [-4.335554936131842, 13.119509071966903],
            [-4.313608766624384, 13.164309003460332],
            [-4.236945868310755, 13.240836114942041],
            [-4.191945944607085, 13.275973220789709],
            [-4.15659979409358, 13.278191072376174],
            [-3.976108752113134, 13.476109096935389],
            [-3.96425489697134, 13.503827044985599],
            [-3.956254873282148, 13.50139109627797],
            [-3.903263978344768, 13.448473123898452],
            [-3.785726729081972, 13.359064200666452],
            [-3.616945876422619, 13.223609124573514],
            [-3.588608841002156, 13.202773221963767],
            [-3.538054900600713, 13.178335950825556],
            [-3.508336695171124, 13.178891168093458],
            [-3.448845969579168, 13.171353993098919],
            [-3.437672892615069, 13.166500032956478],
            [-3.42713667265599, 13.18417310821323],
            [-3.424445914091166, 13.21139115955674],
            [-3.432317862299357, 13.23538217816855],
            [-3.442699855239567, 13.261027113919695],
            [-3.426099831230317, 13.276109007621528],
            [-3.394163941852327, 13.276664224889416],
            [-3.373890800223336, 13.277218101052839],
            [-3.232217860699421, 13.288054057870283],
            [-3.233890888577861, 13.322500159618215],
            [-3.237217833595565, 13.359717989115964],
            [-3.260281813713675, 13.496391123381812],
            [-3.273336795583987, 13.550282067084666],
            [-3.257499860041975, 13.696664127463791],
            [-3.211108875683379, 13.708053960444559],
            [-3.099445999458482, 13.68610913204158],
            [-2.958317883642479, 13.629164158089139],
            [-2.942217756340114, 13.633054031725678],
            [-2.882217746309692, 13.664300090470419],
            [-2.904445883040836, 13.721664159582758],
            [-2.903754878941129, 13.822364176975825],
            [-2.839999776277693, 14.005554024148196],
            [-2.815554961426642, 14.05028220655025],
            [-2.646663970559189, 14.161945082775148],
            [-2.599717768932692, 14.211664185617863],
            [-2.465136757705295, 14.286109064706935],
            [-2.384163884766934, 14.265553955854315],
            [-2.289445864048844, 14.238336072148982],
            [-2.244999816508511, 14.21778213676275],
            [-2.107499891311136, 14.151391093181317],
            [-2.103717808949796, 14.152627088627057],
            [-2.006945886671872, 14.187773079292143],
            [-1.996663973655842, 14.308054060748091],
            [-1.992499844146636, 14.342500162496023],
            [-1.989445981535226, 14.376945090777483],
            [-1.984163873777277, 14.410273214276785],
            [-1.980836761121594, 14.47471814757354],
            [-1.907499801997346, 14.488609140468881],
            [-1.777781803367901, 14.481109013486545],
            [-1.678336724521785, 14.500554023079545],
            [-1.36184595037858, 14.701354081234641],
            [-1.318890875779147, 14.728609012952191],
            [-1.093890921984411, 14.784164099716165],
            [-1.074163945167896, 14.77694510176714],
            [-1.003026732719121, 14.840100060161532],
            [-0.725281809026797, 15.082773089213035],
            [-0.72314593245369, 15.08236421997502],
            [-0.443608790219429, 15.082773089213035],
            [-0.386663816267003, 15.005282058863386],
            [-0.246390822215346, 15.077500033910596],
            [0.00000008381906, 14.994373179488193],
            [0.235045078045346, 14.915064114334697],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BGD", Country: "Bangladesh" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [92.600818270913265, 21.982218019606407],
            [92.606091326215591, 21.965000081693276],
            [92.615263307609922, 21.921109083782838],
            [92.620463272716421, 21.879782110060816],
            [92.610809164250355, 21.852982148049009],
            [92.599154127852131, 21.795000165032704],
            [92.599018173382234, 21.656800183280197],
            [92.600263388921434, 21.634164183139418],
            [92.618045093643758, 21.469991197944708],
            [92.630809223472937, 21.415000045627764],
            [92.641372265522392, 21.372773190778375],
            [92.64803621384155, 21.349718095477769],
            [92.658727163733687, 21.319718090462558],
            [92.669345191067777, 21.296982178035776],
            [92.666091336246012, 21.293326997688766],
            [92.606718292574811, 21.252291043645769],
            [92.58638228667192, 21.262564239482444],
            [92.537491315865424, 21.378053957431845],
            [92.381654133401611, 21.472082147516588],
            [92.36011817423676, 21.47235405645641],
            [92.260818269954001, 21.414436111180621],
            [92.222209212163449, 21.358054065847043],
            [92.204991274250204, 21.300554042264693],
            [92.205827285275262, 21.218882118599581],
            [92.214854092106322, 21.136727062034041],
            [92.232900329693365, 21.11527307788235],
            [92.261956694101428, 21.054190606992151],
            [92.262443044272032, 21.050843339505136],
            [92.26124382000026, 21.049232483000068],
            [92.260560775971413, 21.047916839292398],
            [92.257055728281017, 21.033631488127739],
            [92.257547379000101, 21.032001495000074],
            [92.256551743000074, 21.029500961000124],
            [92.257608414000202, 21.025995255000126],
            [92.25841713000014, 21.024950026999974],
            [92.25785064700014, 21.023250579999953],
            [92.25785064700014, 21.02035141000006],
            [92.259057999000134, 21.018575668000054],
            [92.259286880000133, 21.017778397000072],
            [92.260000229000269, 21.017047882000114],
            [92.261171341000107, 21.014703751000056],
            [92.261520386000115, 21.012899398999977],
            [92.264240265000097, 21.009775162000025],
            [92.264335632000069, 21.008287430000209],
            [92.264152527000277, 21.007547379000172],
            [92.264158249000076, 21.00663757300002],
            [92.265143394500143, 21.003456116000038],
            [92.265609741500128, 21.002366066000107],
            [92.266448975000259, 20.998109818000088],
            [92.266250610000242, 20.997051239000072],
            [92.266213417000216, 20.994350433500031],
            [92.26634597800026, 20.991300583000069],
            [92.266506195500227, 20.98956871050008],
            [92.267066002000121, 20.987415313500165],
            [92.267644882000155, 20.985185623000106],
            [92.268083572000052, 20.984170913999989],
            [92.268701553500165, 20.980450630000192],
            [92.269102096500234, 20.97739028950015],
            [92.269199371000269, 20.975349426000193],
            [92.269430160500093, 20.97213268300014],
            [92.269954681000144, 20.969600677000145],
            [92.269618988000076, 20.967901230000152],
            [92.269512177000109, 20.965351105000096],
            [92.269865036000084, 20.962532043500104],
            [92.271202087000205, 20.959600448999979],
            [92.270664215000153, 20.957901001000053],
            [92.270223618000188, 20.956237793000113],
            [92.270271301000236, 20.955316544000183],
            [92.270681381000117, 20.953004837000137],
            [92.271684647000171, 20.949831009000079],
            [92.271847725000185, 20.946393967000162],
            [92.274147034000094, 20.94050216700002],
            [92.27511405900006, 20.939311981000131],
            [92.275588035500135, 20.938156128000074],
            [92.280660629000153, 20.93588447600014],
            [92.282068253000176, 20.935050964000098],
            [92.283422470000261, 20.934041977000092],
            [92.283945083500072, 20.933446884000077],
            [92.286764145000262, 20.930076599],
            [92.287601471000158, 20.928874969000063],
            [92.290309906000147, 20.926832198999975],
            [92.292395342386698, 20.924891796997699],
            [92.295717393983608, 20.918697034264696],
            [92.296899795500195, 20.912838936000085],
            [92.297523499000278, 20.910768509000107],
            [92.298099517500276, 20.908432007000059],
            [92.299062729000156, 20.907278061000156],
            [92.299707413000277, 20.906696320000052],
            [92.301961899000133, 20.904905319000122],
            [92.303146362000092, 20.904098510999958],
            [92.30429267900027, 20.901077271000091],
            [92.306009293000017, 20.89789962799999],
            [92.30634689300021, 20.896701813000092],
            [92.308648109500126, 20.891814232000073],
            [92.309469223000093, 20.890070915000067],
            [92.310243607000217, 20.888164520000075],
            [92.310850143000124, 20.885412216000091],
            [92.31089973400023, 20.88455009500008],
            [92.311302185000073, 20.882680893000142],
            [92.311481475666739, 20.879106522000072],
            [92.311817169000136, 20.878463745000076],
            [92.312280655000023, 20.876810074000119],
            [92.313415527500155, 20.87441062900011],
            [92.315956116000081, 20.869440079000142],
            [92.317792892000313, 20.867912292000042],
            [92.319683075000199, 20.86479568500009],
            [92.320205688500067, 20.863417625500077],
            [92.320539474000242, 20.862585067999973],
            [92.321075439000111, 20.861783981000045],
            [92.321979523000124, 20.860157013000176],
            [92.322479248000178, 20.859384537000111],
            [92.323507309000064, 20.856956482000029],
            [92.323854446000297, 20.855588913000119],
            [92.324842453000116, 20.853226662000154],
            [92.325328827000163, 20.852218628000244],
            [92.32645615142232, 20.850381362938364],
            [92.327049255000105, 20.84885856863535],
            [92.327049255000105, 20.847972870000135],
            [92.327657700000174, 20.844751358000039],
            [92.328920364500135, 20.841507912000068],
            [92.329807281000143, 20.839050293000113],
            [92.330366135000219, 20.837001801000085],
            [92.330639839000099, 20.835762977500124],
            [92.331085205000107, 20.834655762000139],
            [92.331665039000171, 20.832338332999981],
            [92.331974030000111, 20.829666138000121],
            [92.332803726000151, 20.82787132300011],
            [92.333614349000214, 20.825649261000038],
            [92.333841324000247, 20.824464798],
            [92.333900452000222, 20.823652267000185],
            [92.334384918000097, 20.822177887000052],
            [92.335113525000139, 20.818044662000105],
            [92.335838318000157, 20.816417694000123],
            [92.336851120000205, 20.813611984000161],
            [92.337257385000186, 20.812887192000048],
            [92.338011741500196, 20.809299469000123],
            [92.338314056000314, 20.808237076000054],
            [92.339681625500219, 20.804424285500104],
            [92.340187073000294, 20.80329513500017],
            [92.342382431000118, 20.800901413000176],
            [92.342838287000092, 20.799972534000133],
            [92.343559265000152, 20.798839569000094],
            [92.345191956000207, 20.794569016000068],
            [92.345867157000129, 20.79316711400007],
            [92.347012520000106, 20.789719581500052],
            [92.34709739700034, 20.787450790000051],
            [92.347650528000173, 20.782746315000097],
            [92.348871231000118, 20.776700974000036],
            [92.349637985000271, 20.774765015],
            [92.349426270000123, 20.771249771000114],
            [92.349615097000168, 20.769971848000168],
            [92.35029029800009, 20.767498016000118],
            [92.352823257000153, 20.762771606000101],
            [92.354402542000145, 20.760330836000051],
            [92.355440140000127, 20.75311660800007],
            [92.35546684300013, 20.752099991000065],
            [92.355854034000089, 20.749771118000112],
            [92.355775833000308, 20.746746063000202],
            [92.356267929000154, 20.745807648000081],
            [92.357048035000133, 20.745197296000114],
            [92.358783722000027, 20.74392318700005],
            [92.361295700000113, 20.741659164000041],
            [92.363794327000249, 20.738868713000173],
            [92.365233421500164, 20.736943245000091],
            [92.367164612000153, 20.734298706000132],
            [92.36792945900018, 20.732831955000094],
            [92.369715690500158, 20.728377342000115],
            [92.370462418000074, 20.725677490000109],
            [92.371051788000244, 20.721664429000086],
            [92.370260239000203, 20.720529556000045],
            [92.368666667000156, 20.704388889000128],
            [92.365342640000193, 20.701315349000012],
            [92.342217957000088, 20.679933259000094],
            [92.341388889000228, 20.679166667000104],
            [92.353194444000138, 20.664166667000117],
            [92.389413810000207, 20.626338085000114],
            [92.395083333000088, 20.6204166670001],
            [92.396695062000134, 20.61815770800014],
            [92.401722833000036, 20.611110911000011],
            [92.41625000000019, 20.590750000000114],
            [92.429444444000154, 20.554944443999986],
            [92.414555556000067, 20.436472222000205],
            [92.402527778000291, 20.379472222000047],
            [92.403652734000076, 20.375380860000107],
            [92.401055234000211, 20.374610720500201],
            [92.39938710200019, 20.37413427000017],
            [92.3965979540001, 20.373368810500025],
            [92.394919141500026, 20.372926718500125],
            [92.392112992500216, 20.372218738000115],
            [92.390424469500289, 20.371811262000151],
            [92.387602642000303, 20.371161092000094],
            [92.385905048000183, 20.370788382500123],
            [92.383068866000173, 20.370196329500075],
            [92.381363085500169, 20.369858584500022],
            [92.378449357000108, 20.369312803000028],
            [92.377803515000068, 20.369195270000077],
            [92.376380354000077, 20.368943826000233],
            [92.375228573500209, 20.368748784500113],
            [92.373771058000017, 20.368518261000091],
            [92.371464859000156, 20.36817436300008],
            [92.37007971500023, 20.367979519000116],
            [92.366156904000235, 20.367459768000188],
            [92.362402363000029, 20.367090526000013],
            [92.359132332000257, 20.366878963000076],
            [92.356072222000222, 20.366545254],
            [92.354391684000262, 20.366387381000081],
            [92.352333563000201, 20.366420750000017],
            [92.351472425000026, 20.366280228000122],
            [92.350698772000243, 20.366214619000104],
            [92.349378465000342, 20.36614091200012],
            [92.348005600000221, 20.366026342000069],
            [92.34681387200024, 20.365974351000105],
            [92.345625219000084, 20.365926626000075],
            [92.344318196000216, 20.36588232300015],
            [92.343399298000207, 20.365860457000096],
            [92.342422565000135, 20.365861067000068],
            [92.341273343500205, 20.365858831500091],
            [92.337927180000094, 20.365725712000071],
            [92.336050379000227, 20.365741293000113],
            [92.334145978500175, 20.365777927500076],
            [92.332762955000163, 20.365818716000106],
            [92.331894377000168, 20.365849731000097],
            [92.330318210000229, 20.36587508500007],
            [92.326605274000173, 20.365981476000073],
            [92.32474257200019, 20.366065618000121],
            [92.322133990500134, 20.366228114500117],
            [92.320396117500167, 20.366353317000105],
            [92.317502371000131, 20.366591717000048],
            [92.315767780000243, 20.366752592500077],
            [92.312880231500202, 20.367050380000151],
            [92.311149812000252, 20.367246844000064],
            [92.308269830000057, 20.36760387650007],
            [92.306544307500104, 20.367835846500171],
            [92.303816194, 20.368230109000066],
            [92.302953367000129, 20.368359709000131],
            [92.301515813000179, 20.368583236000049],
            [92.300578314000148, 20.368734563500098],
            [92.29951311600027, 20.368915819000122],
            [92.298089957000201, 20.369167263000165],
            [92.297236468000136, 20.36932258400013],
            [92.294530336500173, 20.3698305865001],
            [92.292824569500084, 20.370168328500071],
            [92.289988394000119, 20.37076038050013],
            [92.288290802000148, 20.371133089500049],
            [92.285468988500071, 20.371783256000057],
            [92.283780490500163, 20.372190726500079],
            [92.281049507000176, 20.372879024000227],
            [92.279794549000115, 20.373205979000105],
            [92.277722069000134, 20.373732106500071],
            [92.275949745500071, 20.374197729500125],
            [92.273145024000115, 20.374967281500133],
            [92.271476830000125, 20.375443746500096],
            [92.268705964000191, 20.376266329000103],
            [92.26704917150019, 20.376776948000142],
            [92.264434467000086, 20.377611446000103],
            [92.263606369000144, 20.377880883000088],
            [92.262227465000166, 20.378337522000137],
            [92.26132566350023, 20.378642047500065],
            [92.260316293000216, 20.378992583000056],
            [92.25896499700022, 20.379471516000066],
            [92.258155043000187, 20.379763313000055],
            [92.255583558500092, 20.380706001500116],
            [92.253965603000125, 20.381317580000157],
            [92.251281090500214, 20.382364456000161],
            [92.249677648500239, 20.383009121000114],
            [92.247017950000185, 20.384110891500129],
            [92.245429820500277, 20.384788320500135],
            [92.242796247500138, 20.385944436000088],
            [92.241224118500185, 20.386654334500051],
            [92.238617825000148, 20.387864290500161],
            [92.237062456000189, 20.388606316000065],
            [92.234484682500153, 20.389869538500093],
            [92.232946778500235, 20.390643356000012],
            [92.23052471000014, 20.391892929000036],
            [92.229756925000316, 20.392294617000047],
            [92.228479201000283, 20.392971776000039],
            [92.227641458500244, 20.393422202],
            [92.226717265000133, 20.393929544000045],
            [92.225477971000174, 20.394620266000189],
            [92.224735691000063, 20.3950390910001],
            [92.222376645500134, 20.396387844500097],
            [92.220895607000131, 20.397254714000113],
            [92.218444196000206, 20.398724671000068],
            [92.216983542500117, 20.39962174700004],
            [92.214566604000112, 20.401141743500077],
            [92.213127019500149, 20.402068606500123],
            [92.210745780000167, 20.403637871500095],
            [92.209327908000176, 20.404594108000126],
            [92.206983451500122, 20.4062119325],
            [92.20558799050005, 20.407197074500075],
            [92.203281438000062, 20.408862685000159],
            [92.201909080500144, 20.409876243500051],
            [92.19975311500005, 20.411503149999973],
            [92.199069282000238, 20.412025469000113],
            [92.197932545000214, 20.412903543000127],
            [92.19702213400015, 20.413617582000043],
            [92.196007986000069, 20.414430971000101],
            [92.194401647000262, 20.415741575000098],
            [92.193443207000229, 20.416536234000219],
            [92.190729922000145, 20.418821203000093],
            [92.190095884000101, 20.419364092000066],
            [92.188837155000243, 20.420459387000093],
            [92.188212458000152, 20.421011796000144],
            [92.185732934000072, 20.423239926000136],
            [92.185117721000125, 20.423801715000067],
            [92.183896936000195, 20.424934543000106],
            [92.183291369000102, 20.425505577000095],
            [92.180739411000076, 20.427952096000112],
            [92.179845743000129, 20.42882229100006],
            [92.178368747000121, 20.430283787000107],
            [92.177490023000274, 20.431167386000041],
            [92.176018644000095, 20.432670348999963],
            [92.175400404000101, 20.433309418000178],
            [92.174376488000092, 20.434380091000023],
            [92.173766040000061, 20.435025819000074],
            [92.171945569000144, 20.436973875000049],
            [92.171336485000182, 20.437633303000084],
            [92.170325983000083, 20.43874024100019],
            [92.169665084500167, 20.439473826500077],
            [92.1689822270001, 20.440246081000069],
            [92.168072101000092, 20.441289381000061],
            [92.167528322000152, 20.441919599000173],
            [92.165799993000149, 20.443946627000017],
            [92.16495849400016, 20.444952567000129],
            [92.16408333300015, 20.444222222],
            [92.002111111000232, 20.218416667000028],
            [91.95499073400012, 20.171419042000153],
            [91.842166667000043, 20.058888889000045],
            [89.708948885000069, 18.565193760000128],
            [89.949027404000134, 19.069922552000222],
            [89.903338228000138, 19.203326268000168],
            [89.709877263000209, 19.763436564000173],
            [89.647402808000237, 19.944313286],
            [89.537495121000148, 20.260892278000043],
            [89.455000456000079, 20.498510581000048],
            [89.392126295000168, 20.679114440000134],
            [89.316350798500167, 20.898180942500133],
            [89.245754459000096, 21.22932346400016],
            [89.242054972000147, 21.24630603700011],
            [89.238305814000086, 21.263100915000066],
            [89.234506988000106, 21.279769352000145],
            [89.230757661000183, 21.295795482000116],
            [89.194421825000148, 21.447716574000097],
            [89.18458166100018, 21.483218102000123],
            [89.171524793000202, 21.538902107],
            [89.169784614000122, 21.54597333800011],
            [89.158586308000309, 21.591986962000121],
            [89.156228142000117, 21.60225603200017],
            [89.127303838000188, 21.74045274550015],
            [89.121695411500042, 21.745547007500122],
            [89.10706203200013, 21.7588387280001],
            [89.083388693000273, 21.78251206700007],
            [89.070074260000155, 21.813582760000017],
            [89.056757818000136, 21.853530076000013],
            [89.047881195000315, 21.894957666000082],
            [89.055710717000096, 21.902786657000107],
            [89.062676906000064, 21.909752374000121],
            [89.070439522271357, 21.934335528283285],
            [89.071553556000168, 21.937863522500052],
            [89.074512069, 21.974852299000148],
            [89.077121887000118, 22.007469262000072],
            [89.077471557000166, 22.011840194000158],
            [89.074911075000131, 22.017388436000019],
            [89.06859499000015, 22.031074596000181],
            [89.062413221000241, 22.041671230000148],
            [89.058237088000141, 22.048829852000026],
            [89.049360464000216, 22.078419266000097],
            [89.049360464000216, 22.096174522000112],
            [89.05282873000013, 22.104844892000116],
            [89.055278548000132, 22.110969229000077],
            [89.06299915159579, 22.115473374096425],
            [89.065263272941564, 22.11527318917534],
            [89.094082267795983, 22.143327083905987],
            [89.073154164251008, 22.192209169895264],
            [89.057909158712874, 22.205544945522703],
            [89.017909207905433, 22.257218037639163],
            [89.007218258013467, 22.27639113829234],
            [88.993318212662558, 22.3244360397902],
            [88.988254202025956, 22.401236066040028],
            [88.929291201059442, 22.869436102959369],
            [88.863109202143875, 22.968254047808315],
            [88.863036111947821, 22.996664173424634],
            [88.874836155271197, 23.092218037529747],
            [88.896936216521368, 23.108882099279342],
            [88.91581829749552, 23.123327135975998],
            [88.982800265252394, 23.206135980991249],
            [88.827836141826111, 23.242700022039472],
            [88.804836199448431, 23.227009099251191],
            [88.727136124432974, 23.247082081031849],
            [88.712827209844392, 23.272291157816582],
            [88.712491263164139, 23.310973138165181],
            [88.739154097239719, 23.350273200055582],
            [88.78637237544379, 23.45082703705684],
            [88.786336165622146, 23.492845014878569],
            [88.75686338231813, 23.469364118895342],
            [88.585127238228864, 23.604027105136097],
            [88.56596335766929, 23.646664170327981],
            [88.576872237044569, 23.766527062452255],
            [88.601800184795906, 23.858491125055352],
            [88.627618290667243, 23.873754067866216],
            [88.687236253549685, 23.892509079187974],
            [88.7347091739733, 23.918127025210637],
            [88.754609153271844, 24.040745048916634],
            [88.725818153974132, 24.060273206989265],
            [88.700954076324905, 24.08242708005811],
            [88.707336225058583, 24.121709037037576],
            [88.700409252616993, 24.148464239686533],
            [88.715682253711833, 24.180064182384243],
            [88.740000166548413, 24.187027029371492],
            [88.751727119675905, 24.216436110211149],
            [88.742082231303158, 24.241673182552589],
            [88.732754178871261, 24.261109139689992],
            [88.721645139647507, 24.2805539816449],
            [88.701909278013574, 24.306182153589887],
            [88.668727167267974, 24.310000110496773],
            [88.652209285910175, 24.294164180783284],
            [88.507209233730322, 24.31819107394081],
            [88.462663273903871, 24.340900164277556],
            [88.365536126568401, 24.411945008152983],
            [88.284427131522108, 24.450826980711767],
            [88.234418182466612, 24.466935992831552],
            [88.130400272511366, 24.506527074401149],
            [88.112627284968511, 24.521664121026092],
            [88.043872377046824, 24.685200081577719],
            [88.136654178126065, 24.820000028116695],
            [88.16679131107756, 24.848327005253438],
            [88.162345214344924, 24.889718016715804],
            [88.141100274858871, 24.916418066441437],
            [88.220063335048422, 24.950982185386565],
            [88.264018203061113, 24.912909066486392],
            [88.276927172177778, 24.888044988837251],
            [88.305545168993461, 24.874164221863722],
            [88.338872286664383, 24.868744986169574],
            [88.397218211917533, 24.93971808095371],
            [88.438036236477359, 25.023053980041865],
            [88.461382351456933, 25.074582065233116],
            [88.458454217393495, 25.152845068867336],
            [88.454227223610275, 25.188400096408529],
            [88.483036160180944, 25.199718012659829],
            [88.620254118154577, 25.198054037236787],
            [88.646654095745959, 25.197491108618053],
            [88.704991303819639, 25.195273089393581],
            [88.888882213375695, 25.175827073972201],
            [88.933045120225955, 25.164436067524932],
            [88.946018294721142, 25.169582220812984],
            [89.008663298124787, 25.290273077335272],
            [88.99165423723943, 25.30853607093907],
            [88.933800329704042, 25.307218100480085],
            [88.874009196701621, 25.332564137563097],
            [88.842900265893121, 25.364718124062321],
            [88.821100276777457, 25.404436107646248],
            [88.837000244231405, 25.426591154181565],
            [88.839991242569084, 25.460964165733557],
            [88.832627237694595, 25.480964224956452],
            [88.799709156899866, 25.510554019628884],
            [88.76666333590029, 25.517773185216001],
            [88.715536240958016, 25.488609023588097],
            [88.651654236280393, 25.489435982157531],
            [88.55663614342771, 25.504718035707796],
            [88.453882224474711, 25.589718077857086],
            [88.447209223700185, 25.616382085399081],
            [88.44950033312054, 25.659300111986298],
            [88.273736181629914, 25.80624510098427],
            [88.254018257268996, 25.814864212043616],
            [88.224991223577007, 25.807218072307606],
            [88.204709197130711, 25.796109033084051],
            [88.163382223408661, 25.780064226342503],
            [88.144018183000668, 25.785691165598038],
            [88.110536167758454, 25.835553934261682],
            [88.095536249070079, 25.915273209757672],
            [88.15776333078017, 26.097500143890514],
            [88.182891270741862, 26.150554070740029],
            [88.286100327038724, 26.195827076849909],
            [88.348527233321278, 26.220136104869241],
            [88.356436229541458, 26.249436053329234],
            [88.356718196765001, 26.282500146877922],
            [88.396382200892532, 26.325273166539532],
            [88.473600316474034, 26.357218108373118],
            [88.523063268354889, 26.367318134089587],
            [88.514436110668356, 26.406382161585995],
            [88.481682315176243, 26.460253994549547],
            [88.445527143366093, 26.472500122761303],
            [88.371572270338078, 26.48554504634761],
            [88.356582242295104, 26.454509038097129],
            [88.335591274476229, 26.483000132898397],
            [88.374982363835471, 26.580554086744812],
            [88.413072246542157, 26.626136049806618],
            [88.419909197343401, 26.586873203566441],
            [88.430327232467135, 26.554300121907303],
            [88.572772312913713, 26.465273078185007],
            [88.60693611216206, 26.470827094882665],
            [88.680818230270376, 26.420273154481336],
            [88.694136236263176, 26.391936119060674],
            [88.703982290312723, 26.333264137773241],
            [88.735763282119507, 26.293509106177297],
            [88.857145142741587, 26.240135996453958],
            [89.020482284549558, 26.235173071569918],
            [89.044009281000314, 26.274609087930216],
            [88.969100212112011, 26.338791164742659],
            [88.923363351478969, 26.357609040338204],
            [88.910482210281117, 26.392317998570562],
            [88.924827167053792, 26.42488219541228],
            [88.9466911939098, 26.442682172683348],
            [89.070736152816352, 26.385326988388428],
            [89.09043613990454, 26.29822711184984],
            [89.120809307250028, 26.17840009427114],
            [89.134972376723368, 26.153709019104113],
            [89.253009187416666, 26.065882096281982],
            [89.319909180160352, 26.024827031499697],
            [89.342772329877874, 26.017027000020832],
            [89.504354133517694, 26.005635993573591],
            [89.548218141700062, 26.015627054548574],
            [89.602418209992919, 26.068964122088104],
            [89.595263417422274, 26.099318011056297],
            [89.577582295538519, 26.126382170656981],
            [89.562100249777899, 26.152673183506622],
            [89.601718153437645, 26.227473120015347],
            [89.634191322810665, 26.230164213856355],
            [89.649300206240895, 26.189464206493525],
            [89.685527127142421, 26.159991087913525],
            [89.733936138515247, 26.156318137931848],
            [89.778872359754558, 26.088891091115144],
            [89.829991240431724, 25.999164158475722],
            [89.849018160693078, 25.906164092637241],
            [89.83055416905043, 25.874436074458643],
            [89.818054236809957, 25.852500130872883],
            [89.808036186106818, 25.822427035661903],
            [89.846100252551395, 25.691936057919392],
            [89.863036223240925, 25.647082146969339],
            [89.863309305646993, 25.605000131407351],
            [89.859982360629516, 25.576664101815183],
            [89.851382360309373, 25.515545107707723],
            [89.827491253983368, 25.431109167643754],
            [89.816300239746653, 25.366044979338753],
            [89.833054155498445, 25.306664224316748],
            [89.850536123362446, 25.288953933409786],
            [89.884500265676337, 25.293535984612433],
            [89.911991231787937, 25.308882075903128],
            [89.966091220156756, 25.295273217869422],
            [90.039154091046299, 25.260827116121376],
            [90.169145172081954, 25.213609173193149],
            [90.383327273970139, 25.154164212792821],
            [90.412491267960519, 25.148882105034872],
            [90.447482361054284, 25.150136037753427],
            [90.467209170232906, 25.161109122507227],
            [90.529563321595589, 25.174164104377454],
            [90.590818270173003, 25.166382177809609],
            [90.84109133849276, 25.148464183341432],
            [90.869982250076731, 25.150553959446952],
            [90.967209309698291, 25.161382204913437],
            [90.999709301161573, 25.172773211360678],
            [91.037491232744713, 25.183609168178108],
            [91.072627165125908, 25.192909057415406],
            [91.105545245920808, 25.196382182824706],
            [91.171582237911053, 25.195482134059247],
            [91.261791297622352, 25.206036123653163],
            [91.311372266700374, 25.182773157152994],
            [91.482272399764412, 25.138882159242556],
            [91.702763392552441, 25.151591136148809],
            [91.749418239223843, 25.167218021196632],
            [91.933045118828971, 25.183327033316417],
            [92.03888223705448, 25.187491162825538],
            [92.093600306965044, 25.177491049395243],
            [92.115263335782487, 25.170827101075858],
            [92.193036165717587, 25.140000137491214],
            [92.332491253655206, 25.079436025375557],
            [92.408872352383213, 25.025553966490136],
            [92.412491322908494, 25.013609083879473],
            [92.411227164267956, 24.993609024656664],
            [92.421672356758137, 24.966773188099111],
            [92.445545190534943, 24.959436005314913],
            [92.488182255727025, 24.917764200095419],
            [92.491627217941726, 24.877509104154328],
            [92.474700299707735, 24.866682199792493],
            [92.436545205794204, 24.849282206941851],
            [92.389927239497155, 24.848909044611489],
            [92.342482314630303, 24.882500024594989],
            [92.284572248343437, 24.906382078465484],
            [92.248382207815865, 24.894582202780228],
            [92.248182215605567, 24.851664176192841],
            [92.260545187547876, 24.826518131320356],
            [92.262636137119813, 24.803745003243009],
            [92.256654140444908, 24.773891178619763],
            [92.248600304937128, 24.742491060494231],
            [92.188309275227908, 24.562426992662708],
            [92.173872285158126, 24.546109103515235],
            [92.14082713471069, 24.526664093922349],
            [92.134991318427495, 24.518327117724411],
            [92.130263422109152, 24.490691144687545],
            [92.137500189692986, 24.437218122678232],
            [92.132763408557224, 24.409718104111093],
            [92.117200225973676, 24.390000179750004],
            [91.980672266271227, 24.320064094029718],
            [91.946636207228011, 24.34944501167449],
            [91.921200316142546, 24.327464141087759],
            [91.931300174220979, 24.28221812470612],
            [91.906372394107649, 24.176936056110335],
            [91.882572315250769, 24.151553976843488],
            [91.751918225671915, 24.154445062894936],
            [91.59356328712542, 24.096664245555658],
            [91.568027148477853, 24.090273212004476],
            [91.546327239286342, 24.086936041064789],
            [91.520491196142274, 24.088327101719742],
            [91.478963392019608, 24.090827088167813],
            [91.42051822503268, 24.10943608673567],
            [91.381982257437954, 24.105136002756652],
            [91.268463292568669, 23.968882131288794],
            [91.237072394536739, 23.902082218469459],
            [91.239709173644968, 23.878053984207256],
            [91.251382314953759, 23.840036018230123],
            [91.214500264498241, 23.755900150300576],
            [91.192891215137337, 23.750409165515052],
            [91.166091253125586, 23.745200147953128],
            [91.156654235952061, 23.725691100619699],
            [91.161927123616152, 23.631527123703037],
            [91.20921815674032, 23.510691092617279],
            [91.254718144788939, 23.451664221548455],
            [91.282209278538772, 23.373327121889801],
            [91.29999131853711, 23.297218099739595],
            [91.313036242123388, 23.236664213545922],
            [91.316082225745873, 23.210000038365749],
            [91.319718295353624, 23.190273229187412],
            [91.333045186163616, 23.124582074523047],
            [91.344291185685478, 23.098191149387318],
            [91.356927240033855, 23.081382080712402],
            [91.374700227576767, 23.066945090642477],
            [91.399427177289539, 23.069791082054806],
            [91.404991252271117, 23.094436056754219],
            [91.39053615729037, 23.132773205605062],
            [91.381091261127978, 23.181109126781948],
            [91.379482270989769, 23.21930009524111],
            [91.402691258033286, 23.265900124265485],
            [91.426091184831563, 23.261945039422116],
            [91.447209222303144, 23.246936068278146],
            [91.474563395754416, 23.220273234202537],
            [91.492754137352762, 23.162491075758581],
            [91.548036141710526, 23.000691174997357],
            [91.561791347774118, 22.98540912144729],
            [91.611509277150361, 22.94458221206996],
            [91.724991194007458, 22.994718063139871],
            [91.778591285669421, 23.03694508562738],
            [91.818172308955155, 23.09027310071157],
            [91.80470024358155, 23.158882163517362],
            [91.795254173952486, 23.195000119677459],
            [91.780127185611576, 23.228609036933804],
            [91.768182303000941, 23.254791085041845],
            [91.770127239819118, 23.279164150801634],
            [91.783600311020933, 23.312500153290031],
            [91.797345123524536, 23.33666417438387],
            [91.844982329250769, 23.410273210086089],
            [91.88373622632875, 23.434718024937055],
            [91.922200277194321, 23.442145061723622],
            [91.96630920458793, 23.50557310252411],
            [91.963045123844239, 23.56277322160993],
            [91.957218192378406, 23.591664133193817],
            [91.943309262210079, 23.642218073595259],
            [91.938382211871868, 23.680409042054421],
            [91.95860020057782, 23.727773165374359],
            [91.977482281551914, 23.701382072600452],
            [92.032827150183806, 23.645973166228188],
            [92.067209214191308, 23.650409037039125],
            [92.109709319084942, 23.690827077178241],
            [92.174854141298709, 23.73681807711607],
            [92.20304516396547, 23.706664180358231],
            [92.268600196521874, 23.719436021538613],
            [92.278045092684437, 23.710827136401051],
            [92.289427214314202, 23.692773187462976],
            [92.299791269981569, 23.572918006689562],
            [92.323318266432494, 23.456109144814704],
            [92.358109199678012, 23.367418047772659],
            [92.370254242136951, 23.339718036995279],
            [92.387182333837444, 23.285682086367117],
            [92.365818203688008, 23.248327128932985],
            [92.34984514603812, 23.2288821193399],
            [92.352482260422704, 23.177773129308846],
            [92.375536182256809, 22.938882182618684],
            [92.477000293945451, 22.748191202635482],
            [92.510272258693391, 22.726454077793889],
            [92.53089123764849, 22.581664243746403],
            [92.52470924876306, 22.552218114894615],
            [92.568600246673583, 22.357773215744899],
            [92.578327277697753, 22.322218020565558],
            [92.596936108627659, 22.17555399296289],
            [92.595263416025347, 22.136109091785173],
            [92.584991226016967, 22.066109135962634],
            [92.600818270913265, 21.982218019606407],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BGR", Country: "Bulgaria" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.681436174907844, 44.224700042779133],
            [22.69166327027682, 44.215554045284662],
            [22.914582165145418, 44.11110916118038],
            [22.948327204509837, 44.104018071074066],
            [22.997445157255186, 44.100627088315932],
            [23.01277331127298, 44.096109074853743],
            [23.042982193315765, 44.072564141129973],
            [23.037636047817557, 44.050691061818455],
            [22.992236139693091, 44.013754026077933],
            [22.959427191278053, 44.012273111420612],
            [22.913400149156331, 44.000864167700556],
            [22.882218128152033, 43.984864120322257],
            [22.841963199849204, 43.896673255263167],
            [22.852545184999656, 43.860736012936215],
            [22.869300106580027, 43.845418084840318],
            [22.894445145624388, 43.836109143147624],
            [22.966945087895112, 43.816936042494447],
            [23.067527088091083, 43.802182216483672],
            [23.143191198819551, 43.809718050373718],
            [23.162109154339362, 43.81822702322502],
            [23.19860915764724, 43.83138208501957],
            [23.22450018607617, 43.838664114880643],
            [23.262073240631565, 43.847782116818493],
            [23.28360919979653, 43.850827094612484],
            [23.422682240586084, 43.855700165494326],
            [23.446663200914287, 43.851109061836084],
            [23.511518176915359, 43.836554054569362],
            [23.533700213179003, 43.822427027279971],
            [23.570273306682736, 43.806936096702131],
            [23.607082266942456, 43.797354072603142],
            [23.629718099445057, 43.795000098908773],
            [23.774163269633135, 43.788327098134076],
            [23.987500140402744, 43.74416419128373],
            [24.067982169089731, 43.720627136549169],
            [24.116082223510716, 43.699909083498326],
            [24.155618152157189, 43.689909137705925],
            [24.189236121869016, 43.684927102082767],
            [24.224163344860699, 43.689436063089474],
            [24.257218218315728, 43.694445088441128],
            [24.286391264761306, 43.697491072063613],
            [24.307782217000863, 43.697491072063613],
            [24.319709162338711, 43.696636117937359],
            [24.355273242335301, 43.696873158159732],
            [24.40528219139054, 43.719718035328199],
            [24.438609141423541, 43.736382097077794],
            [24.457773189621321, 43.744718067447153],
            [24.501800142001571, 43.761245001260463],
            [24.528609156469088, 43.761109046790565],
            [24.56250020858721, 43.759164109972389],
            [24.605827104412441, 43.751518137874442],
            [24.686927214641287, 43.718382127596371],
            [24.714163203257726, 43.714718062432027],
            [24.793609228709329, 43.71277312561368],
            [24.881382172075007, 43.712218075983955],
            [25.022754033636005, 43.710191164152334],
            [25.224718170507344, 43.687482073815701],
            [25.268609168417981, 43.675273161254424],
            [25.314445103146767, 43.652218065953534],
            [25.344163308576356, 43.633464228098447],
            [25.378791129985672, 43.62330904945874],
            [25.432063321594427, 43.627927142845195],
            [25.463854036409003, 43.639209184550865],
            [25.531936045141691, 43.64360012836066],
            [25.609163213178533, 43.66220912692863],
            [25.681336093586594, 43.690427139323603],
            [25.735309179940884, 43.694600153650228],
            [25.766527075490728, 43.703327055984786],
            [25.786945056407149, 43.713054087008899],
            [25.884563215631999, 43.793018114078379],
            [25.942773186415081, 43.848473120918115],
            [26.085282304602288, 43.949718129657171],
            [26.111663339092189, 43.968318075769744],
            [26.136382242177859, 43.982773170750377],
            [26.301936062105796, 44.030818072248039],
            [26.386663357124945, 44.043873221756527],
            [26.413036177349852, 44.043127064733781],
            [26.438418088978523, 44.04130902992982],
            [26.478609147179156, 44.049427070816009],
            [26.671100224693106, 44.071936001304309],
            [26.794718209450679, 44.095273231466692],
            [26.840827226585674, 44.115554084446629],
            [26.890209209281636, 44.131991164257656],
            [26.913400259052338, 44.136127130572149],
            [27.036427151996264, 44.147336082081878],
            [27.111109238945772, 44.140827031333899],
            [27.165836193673755, 44.129991074516354],
            [27.193600242191593, 44.123318073741743],
            [27.230273247981501, 44.120827139749068],
            [27.25054504850587, 44.124982216802792],
            [27.27270914749667, 44.132145056000567],
            [27.279300173258122, 44.103882116604169],
            [27.295273230907895, 44.084300146713062],
            [27.407500209218114, 44.021800150234569],
            [27.475827137162383, 44.019436118256394],
            [27.624791159002797, 44.02818213133007],
            [27.652427132039833, 44.041664254987367],
            [27.678745134617856, 44.029718031272225],
            [27.698354094237374, 43.993609127567808],
            [27.716800148607007, 43.969227009352537],
            [27.741800180726074, 43.959436108225972],
            [27.813191197203736, 43.965000183207437],
            [27.833327210896272, 43.969164145078665],
            [27.860409140131907, 43.980818175648309],
            [27.922218132510551, 44.003882155766419],
            [27.952218137525705, 43.974436026914631],
            [27.960273314137993, 43.944991071529145],
            [27.998054072254575, 43.859509069945986],
            [28.038463227576273, 43.835409086592406],
            [28.1330541786418, 43.801653988944096],
            [28.18194514944858, 43.785273235522666],
            [28.235554125927791, 43.770000066789734],
            [28.257773210203453, 43.764435991808355],
            [28.403891240631907, 43.75027308997322],
            [28.485000235678257, 43.74416419128373],
            [28.583243146419733, 43.747771689213991],
            [28.593660527000139, 43.742665393000024],
            [28.686211881000105, 43.733217774000011],
            [28.861578656000091, 43.715315895000018],
            [29.138088005000014, 43.680079121000048],
            [29.484910223000128, 43.704190902000036],
            [30.43400296300004, 43.627049161000016],
            [30.974197864000161, 43.520284825000132],
            [31.345261965166827, 43.446967096666796],
            [31.284952724000078, 43.417491622500037],
            [31.10916666700021, 43.331666665999975],
            [30.751666667000137, 42.945277776999987],
            [30.604999999000171, 42.825277776000107],
            [30.569444486500203, 42.800833361500153],
            [29.975000000000108, 42.557499999000029],
            [29.826666667000069, 42.489999999],
            [29.572222222000192, 42.439999999000136],
            [29.345833333000115, 42.241111110000062],
            [28.896717639500167, 42.134172989],
            [28.323888888500079, 41.997777627000133],
            [28.323852688000073, 41.987062262000038],
            [28.323833333000124, 41.981333361500177],
            [28.040333365000066, 41.981333332000148],
            [28.037722222000156, 41.980138887999985],
            [28.035553553000085, 41.978720132000021],
            [28.034749738666761, 41.978194444000039],
            [28.031463526000067, 41.978785391000073],
            [28.013226487860521, 41.982217792724171],
            [27.971100218499856, 41.98415395053523],
            [27.903609133942695, 41.994709113595533],
            [27.867082308544667, 42.005545070412879],
            [27.83250025232681, 42.001664249231837],
            [27.705827063853377, 41.977482123227006],
            [27.633327121582482, 41.95582714103675],
            [27.595554074816818, 41.93555399940756],
            [27.56958224484103, 41.909264160024506],
            [27.441109126474629, 41.976945010870011],
            [27.411527210791036, 41.99471816605093],
            [27.393463203569098, 42.009291110590581],
            [27.373054107470097, 42.039982119705414],
            [27.363263206343589, 42.062845101784959],
            [27.309163217974998, 42.091382129415777],
            [27.286245082972499, 42.100964153514568],
            [27.233054028186615, 42.109991127983548],
            [27.070273277113159, 42.089991068760938],
            [27.037218236020038, 42.083609087665238],
            [26.962218139663179, 42.003318166370988],
            [26.933054145673083, 42.006945015885137],
            [26.621382295316977, 41.9730539637671],
            [26.566954071618966, 41.934754030566339],
            [26.575291215454712, 41.898645126861751],
            [26.565827208552918, 41.871382148517185],
            [26.558327081570496, 41.851664224155982],
            [26.535727123613441, 41.828045026769971],
            [26.472773163257784, 41.824153979666974],
            [26.446663199517189, 41.825000048975895],
            [26.381318217455146, 41.822000165821024],
            [26.343954039927524, 41.782345046511082],
            [26.332636123676139, 41.745409184236948],
            [26.351173205514527, 41.7190821292035],
            [26.361100228749166, 41.711054109957686],
            [26.301936062105796, 41.707909052239515],
            [26.271109098520867, 41.712909025135659],
            [26.232863312414935, 41.739518047392735],
            [26.172500198337957, 41.747773216215208],
            [26.138609146219835, 41.739154105155862],
            [26.077636164866163, 41.714300085790612],
            [26.065963358833301, 41.691654027365914],
            [26.068600137941701, 41.660273187617761],
            [26.101100129404983, 41.628045105094188],
            [26.129782163961096, 41.630618014100222],
            [26.182773226536654, 41.540127154803386],
            [26.198054106620333, 41.439436022227753],
            [26.14000020687476, 41.354718114939985],
            [25.983473193777911, 41.320273186658611],
            [25.905554015812953, 41.311100199435643],
            [25.883882269816155, 41.309436056374594],
            [25.814163275388552, 41.336100063916618],
            [25.65304515863366, 41.317773200210539],
            [25.561245046057195, 41.319373137892953],
            [25.503609235643125, 41.289445049607295],
            [25.340409221771637, 41.249853968037897],
            [25.282354148559591, 41.243045012793104],
            [25.241663193652158, 41.253600175853492],
            [25.190827118389393, 41.301453969958416],
            [25.185836197948561, 41.305273100331974],
            [25.163054017415988, 41.317491065348733],
            [25.061191262411114, 41.362573131703769],
            [25.036509239699683, 41.370436027456591],
            [24.901391283753298, 41.41194505611621],
            [24.818473306358584, 41.403745040216492],
            [24.812500194500814, 41.378327086404184],
            [24.809445158423017, 41.352218128492083],
            [24.767773185565346, 41.370554044653673],
            [24.609300229821912, 41.432564202709173],
            [24.565973166358617, 41.496382169646481],
            [24.466109159990765, 41.551936082943982],
            [24.306245143592321, 41.547500044495237],
            [24.25819118963895, 41.569582168472422],
            [24.215273163051847, 41.554991119022148],
            [24.199991277139759, 41.543782167512248],
            [24.157218089839688, 41.541109178582147],
            [24.093191245874635, 41.548264138790799],
            [24.064027084246447, 41.52172703326319],
            [24.066391283862799, 41.485273130423039],
            [23.976663345394883, 41.440827082882592],
            [23.886391253771649, 41.451945006923651],
            [23.796391238726017, 41.436664126839858],
            [23.738327113058659, 41.408609058642909],
            [23.63347319207827, 41.377773210240761],
            [23.598054119006889, 41.380827072852256],
            [23.545000192157488, 41.394436098523968],
            [23.440554134586705, 41.409436017212542],
            [23.287145189479986, 41.40416413537649],
            [23.22986326301907, 41.376245021649638],
            [23.228327195438737, 41.354091148580792],
            [23.207918099339736, 41.333045027838821],
            [23.18513608644534, 41.323744970963631],
            [23.120000149048963, 41.320827062822033],
            [23.094445067300086, 41.320545095598234],
            [22.975554140670567, 41.335545014286893],
            [22.935600122692591, 41.342127155230813],
            [22.955900254049908, 41.355618163705557],
            [22.966663288309576, 41.374164130361365],
            [22.959163328965246, 41.476391162879182],
            [22.951663201983024, 41.598609034526405],
            [22.954236110988887, 41.63596415959843],
            [22.986945147117922, 41.665836089132753],
            [23.015000215314757, 41.695409119999042],
            [23.030973272964843, 41.72506412587849],
            [23.009236148123051, 41.766309124587238],
            [22.965900199842309, 41.775691156475759],
            [22.942500105405969, 41.807782111062963],
            [22.897636136172196, 41.88221810533453],
            [22.876945072849679, 41.93819111379203],
            [22.823054129146726, 42.027354111984224],
            [22.685836171172951, 42.065273171503563],
            [22.609445181799231, 42.103336064481752],
            [22.519445166753712, 42.149718164023042],
            [22.407773238073389, 42.279445047469906],
            [22.365273300817705, 42.323882210192821],
            [22.410827100684799, 42.335273216640175],
            [22.459445156723376, 42.363054028964356],
            [22.520836059770545, 42.400691121260181],
            [22.537218154296625, 42.425418070972839],
            [22.558473152066284, 42.483327131431295],
            [22.546945185320823, 42.512909047114803],
            [22.522500202831679, 42.531109176444645],
            [22.468191169797137, 42.566936112925632],
            [22.447218139251106, 42.599444989206333],
            [22.442909170454556, 42.820409056610842],
            [22.521245096646652, 42.876873244595785],
            [22.594854132348701, 42.894445066461898],
            [22.615000204325298, 42.894718148868193],
            [22.658609235012136, 42.88290905308925],
            [22.74159125014728, 42.892145072224068],
            [22.966391211731775, 43.101664153724798],
            [22.992082247950464, 43.137353962269387],
            [23.005000269522441, 43.192773094563464],
            [22.940554162759298, 43.21054507627791],
            [22.905973280007942, 43.229718009293194],
            [22.846945067834469, 43.286727020985964],
            [22.829509200438167, 43.331873125081543],
            [22.764309225301332, 43.386664117549955],
            [22.721382146258634, 43.40069106491508],
            [22.669163224605938, 43.415835990529061],
            [22.628054012729109, 43.430827024400102],
            [22.54166324520105, 43.475618071076113],
            [22.410418231446783, 43.717500183741819],
            [22.367218237635967, 43.826945040742245],
            [22.41860919489082, 44.008191124562856],
            [22.544854068110766, 44.060836014536264],
            [22.614854023933503, 44.119582091847931],
            [22.61916332800601, 44.170000077779477],
            [22.651800112129422, 44.213191186772789],
            [22.67639127501036, 44.224718147689927],
            [22.681436174907844, 44.224700042779133],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BHR", Country: "Bahrain" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [51.121801285000146, 27.043114553],
            [51.119722222000149, 27.033333333000101],
            [51.054444444000154, 26.732777778000028],
            [50.986666667000151, 26.436388889000014],
            [50.954444444000188, 26.333888889],
            [50.932777778000201, 26.299444444000031],
            [50.92277777800021, 26.265277778000055],
            [50.91750000000016, 26.183888888999988],
            [50.907500000000169, 26.077222222000088],
            [50.85, 26.011111111000147],
            [50.815833333000143, 25.895000000000138],
            [50.820000000000192, 25.873888888999986],
            [50.831388889000067, 25.861111111000142],
            [50.842222222000174, 25.811944444000105],
            [50.856388889000215, 25.7825],
            [50.861111111000042, 25.766666667000052],
            [50.864722222000211, 25.759722222000121],
            [50.868055556000144, 25.749444444000162],
            [50.866111111000095, 25.735277778000139],
            [50.852500000000219, 25.707500000000138],
            [50.861944444000159, 25.690833333000015],
            [50.841666667000112, 25.669444444000177],
            [50.835000000000178, 25.658611110999985],
            [50.840555556000112, 25.645277778000079],
            [50.839444444000122, 25.638611111000031],
            [50.828888889000183, 25.629166667],
            [50.798333333000102, 25.622500000000159],
            [50.780277778000112, 25.5925],
            [50.793611111000104, 25.575833333000119],
            [50.808888889000144, 25.563611111000128],
            [50.817777778000192, 25.562222222000045],
            [50.813333333000088, 25.548611111000056],
            [50.81500000000014, 25.544444444],
            [50.810000000000144, 25.535],
            [50.796111111000101, 25.545277778000141],
            [50.780000000000115, 25.548611111000056],
            [50.76972222200007, 25.546944444],
            [50.763611111000074, 25.558055555999985],
            [50.734722222000158, 25.5725],
            [50.693055556000019, 25.580555556000022],
            [50.689444444000202, 25.581388888999967],
            [50.58, 25.586111110999965],
            [50.5675, 25.576111111],
            [50.557395545000162, 25.568769795],
            [50.529167000000172, 25.593889],
            [50.433333000000147, 25.679167000000106],
            [50.41666700000016, 25.69833],
            [50.366111000000188, 25.818889000000141],
            [50.304167000000149, 25.87917],
            [50.293056, 25.950833],
            [50.269722000000144, 26.07972200000016],
            [50.311111, 26.171111000000124],
            [50.316667000000137, 26.252222],
            [50.341667000000172, 26.37333],
            [50.376667, 26.4075],
            [50.3875, 26.53],
            [50.556667000000203, 26.620833],
            [50.773333, 26.991667000000163],
            [50.900000000000119, 27.166667],
            [50.95, 27.108333],
            [51.098333000000167, 27.046111000000124],
            [51.121801285000146, 27.043114553],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BHS", Country: "The Bahamas" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.631393941499937, 25.062369649000075],
            [-71.180441567, 24.136298748000044],
            [-71.721508934000013, 23.208511935999965],
            [-71.917428761999901, 22.879306375000041],
            [-71.973095691999987, 22.785251239],
            [-72.064626203000017, 22.631522754999978],
            [-72.213881320000041, 22.391184468999981],
            [-72.230369374999839, 22.365603257000103],
            [-72.255487867, 22.326808699000011],
            [-72.256163168, 22.325760599999981],
            [-72.305124090999982, 22.249640263000103],
            [-72.333509839000016, 22.229618916000064],
            [-72.565005459999952, 22.066380632000019],
            [-72.578406760999883, 22.054671353000103],
            [-72.58702886399999, 22.046956669000124],
            [-72.590457896000032, 22.043828140000173],
            [-72.597783163000031, 22.037177849000059],
            [-72.622566494999887, 22.014911110000142],
            [-72.677121822999823, 21.966154543000115],
            [-72.772869265999987, 21.921739616000039],
            [-72.791720143999896, 21.913031267999983],
            [-72.813606889999903, 21.902789989000027],
            [-72.781462836999964, 21.802610659],
            [-72.778865885999835, 21.794113604000032],
            [-72.767369469999949, 21.767598469000049],
            [-72.754656578000038, 21.737754228000043],
            [-72.743465617000027, 21.713144854],
            [-72.738387113000016, 21.697207898000144],
            [-72.737183661000017, 21.69333216599999],
            [-72.716363726, 21.624438511000037],
            [-72.707783268999862, 21.595821966],
            [-72.679618852000033, 21.505640047],
            [-72.678674273999945, 21.502738661],
            [-72.675988413000027, 21.494542221000145],
            [-72.656654913999972, 21.43473509899998],
            [-72.646516489, 21.40414228500002],
            [-72.644219247, 21.397329821000099],
            [-72.634495396999853, 21.369893855000029],
            [-72.588690960999941, 21.242214804000085],
            [-72.56700580399999, 21.214639311000028],
            [-72.563574087, 21.191783549000135],
            [-72.559813285999866, 21.173021687000031],
            [-72.55544954999985, 21.153674003000148],
            [-72.552452631999955, 21.140240715000047],
            [-72.539310218999958, 21.083503402000147],
            [-72.533353156, 21.058776756000086],
            [-72.518330366999919, 20.995507367000059],
            [-72.497781720999967, 20.918285479000176],
            [-72.486596896999913, 20.878210506],
            [-72.482926849000023, 20.865144544000017],
            [-72.480552286999909, 20.856745559000089],
            [-72.473837426999978, 20.833441508000035],
            [-72.448975394, 20.747607032000118],
            [-72.439003354000022, 20.714709268999954],
            [-72.451548041999956, 20.710961684000139],
            [-72.460249819999888, 20.708392189999969],
            [-72.464385309999955, 20.707197933000018],
            [-72.472008592, 20.705007171000133],
            [-72.482014428999832, 20.701246848000025],
            [-72.487950786999932, 20.699519893000044],
            [-72.563927723999853, 20.676843055000134],
            [-72.632381680000037, 20.65047737899998],
            [-72.747395818999962, 20.606411317],
            [-72.811508467999829, 20.581931275],
            [-72.858945389000013, 20.564240581999982],
            [-72.877618744999978, 20.558096607000024],
            [-72.921560360999962, 20.543766532999982],
            [-72.928629569, 20.541588458999982],
            [-72.987014944999942, 20.524145042],
            [-72.99953117099983, 20.520416467000146],
            [-73.083514474, 20.499464887999963],
            [-73.119312102, 20.491730430000032],
            [-73.178495838, 20.479439439000132],
            [-73.203571373999978, 20.474200676000109],
            [-73.275190026999979, 20.4592977],
            [-73.335288171999963, 20.43400777100014],
            [-73.39507516999987, 20.407418513999957],
            [-73.396014834, 20.407219688000126],
            [-73.449980824000022, 20.391911494999974],
            [-73.489859882999866, 20.381117964999973],
            [-73.49427158099985, 20.379884563000061],
            [-73.524972041000012, 20.377554765000028],
            [-73.572218658, 20.368263439000074],
            [-73.596224163999977, 20.383328738000117],
            [-73.64845002699991, 20.417564909000149],
            [-73.72220533599986, 20.465572037000172],
            [-73.739509265000038, 20.476878031000112],
            [-73.77610756699994, 20.501203129000075],
            [-73.790164866999902, 20.510474497000175],
            [-73.837649530000022, 20.54214277200002],
            [-73.884779024999887, 20.574676583000084],
            [-73.894874860999892, 20.582551687000105],
            [-73.919671326999975, 20.601450667000066],
            [-73.95541115799989, 20.628191414000256],
            [-73.996648537999874, 20.659481929000108],
            [-74.015447780000045, 20.673483875000088],
            [-74.04194590899985, 20.694328538000221],
            [-74.045929974999893, 20.697493264000045],
            [-74.053303992999929, 20.703279008000223],
            [-74.069051555999948, 20.715693467000037],
            [-74.080407536999928, 20.724604302999964],
            [-74.115228301999849, 20.751791830000158],
            [-74.151769193999911, 20.780014935000239],
            [-74.199784101999853, 20.816850804000083],
            [-74.2083572089999, 20.831780519000191],
            [-74.210907527999979, 20.835527213999953],
            [-74.258933381999839, 20.905632111000031],
            [-74.288006004999971, 20.970517652999987],
            [-74.308067340999912, 21.015590843000084],
            [-74.355639044999975, 21.121904445000084],
            [-74.39025214000003, 21.200174739999952],
            [-74.405232028999848, 21.233775262000066],
            [-74.432427719999907, 21.253390922000207],
            [-74.475619330999933, 21.284714993000179],
            [-74.507563146999928, 21.307933125000133],
            [-74.547765086999846, 21.344447602000102],
            [-74.553790661999869, 21.35002878000013],
            [-74.605789021999897, 21.362567160000182],
            [-74.775777324000046, 21.403664914000146],
            [-74.805830305999905, 21.398518538000118],
            [-74.820345528999923, 21.396867691000224],
            [-74.822933989000035, 21.396573300000099],
            [-74.828356272999883, 21.395951339000064],
            [-74.892666438999953, 21.388574670000068],
            [-74.938813317999859, 21.383195554000068],
            [-74.974084331499967, 21.379084295500135],
            [-75.08091596, 21.417791432000129],
            [-75.108735224000043, 21.428619614000056],
            [-75.139314918999872, 21.440558074000208],
            [-75.184490468, 21.458304785000152],
            [-75.229295897999862, 21.475768353000021],
            [-75.367206135999822, 21.53115754800011],
            [-75.395572415999851, 21.544688905000086],
            [-75.428987636999921, 21.561219726000147],
            [-75.430888538, 21.562166977000075],
            [-75.445285907999931, 21.569617716000081],
            [-75.599078280999919, 21.57408978900007],
            [-75.672557186999825, 21.571250625000104],
            [-75.697852245000036, 21.570273245000052],
            [-75.736260397999843, 21.569756306000087],
            [-75.758444879, 21.570973325000054],
            [-75.765034637999975, 21.571326706000079],
            [-75.813558920999867, 21.573928861],
            [-75.884222974999943, 21.577761646000127],
            [-75.918171739999934, 21.578847699000207],
            [-75.930930825000019, 21.579304039000121],
            [-75.943095657999976, 21.583914514000114],
            [-76.037242200999913, 21.627619554000077],
            [-76.117961707999939, 21.676158176000101],
            [-76.152326002999928, 21.696850573000049],
            [-76.210274712999876, 21.735595964000069],
            [-76.271233762999856, 21.778512713000097],
            [-76.350592695999921, 21.835178589000137],
            [-76.355962803999887, 21.841309673999987],
            [-76.490630937999924, 21.995733358000095],
            [-76.501429007999945, 22.012397240000055],
            [-76.502108074, 22.013444151000087],
            [-76.50275620799988, 22.014440656000019],
            [-76.503418996999926, 22.015458990999988],
            [-76.504306723999832, 22.017714391000098],
            [-76.518609554000022, 22.05440866900004],
            [-76.542977859999837, 22.118282995],
            [-76.544718492999976, 22.120697871000161],
            [-76.552988172999875, 22.132077384000141],
            [-76.559963877, 22.140916746],
            [-76.618275434999958, 22.226196540000032],
            [-76.654456701999891, 22.278772424999985],
            [-76.791331212999864, 22.437957180000112],
            [-76.817845991999832, 22.540482484000094],
            [-76.824412722999853, 22.56074106100003],
            [-76.838804324999842, 22.601853087000123],
            [-76.875475947999888, 22.706898660000078],
            [-76.875824632999866, 22.707895894000089],
            [-76.876174727999881, 22.708894072000064],
            [-76.877138002999914, 22.711638339000032],
            [-76.878110710000016, 22.714382543999974],
            [-76.879066302999945, 22.717076307000198],
            [-76.880030973, 22.719770008000054],
            [-76.887946561999911, 22.741852350000173],
            [-76.905188014999879, 22.790061699000134],
            [-76.973406108999882, 22.802866452000089],
            [-77.303352210999918, 22.86417958400007],
            [-77.309785272999989, 22.86626568200019],
            [-77.606660793999936, 22.718533568999973],
            [-77.656593360999921, 22.694678127000046],
            [-77.660653593999967, 22.692738338000041],
            [-77.683286239999859, 22.684198206000062],
            [-77.90065033399992, 22.602178719000236],
            [-77.932550791999887, 22.600550532000071],
            [-77.948627946999892, 22.602018998000077],
            [-78.00018229399987, 22.606727903000049],
            [-78.087565576999964, 22.614142197000135],
            [-78.132839982999911, 22.617983639000087],
            [-78.135161864999901, 22.618194124000112],
            [-78.139186728999874, 22.618558990000096],
            [-78.152029159999927, 22.620537107000061],
            [-78.16499832, 22.622534744999982],
            [-78.174152654999887, 22.624117030000079],
            [-78.194402757000034, 22.627546033000087],
            [-78.215393537999887, 22.634148333000056],
            [-78.250713799999914, 22.662746820000123],
            [-78.25921719199988, 22.670553841000086],
            [-78.283548287999906, 22.69400163500022],
            [-78.30102022499986, 22.713194163000153],
            [-78.3384855219999, 22.754798532000095],
            [-78.342660086, 22.75939367900007],
            [-78.354381446999923, 22.773144444000067],
            [-78.406010858999906, 22.836375642000107],
            [-78.415501759999927, 22.848231829000113],
            [-78.430879209999858, 22.868283546000171],
            [-78.555413541999854, 23.300796304000116],
            [-78.562337449999973, 23.36340584400007],
            [-78.579976854999899, 23.377017533],
            [-78.656844037000042, 23.43774379],
            [-78.763567013999875, 23.369023856000112],
            [-78.810888610999854, 23.338553032999968],
            [-78.822202033999872, 23.331266017000118],
            [-78.844909794000046, 23.316639866999978],
            [-78.884585373999926, 23.291545549000048],
            [-78.895127916999854, 23.285543401000112],
            [-78.965499456999879, 23.245479029000208],
            [-79.047505254999919, 23.199152314000045],
            [-79.116144668999908, 23.160862528000067],
            [-79.157181791, 23.137970394000106],
            [-79.189098455999982, 23.124479653000222],
            [-79.331369720999987, 23.139539550000066],
            [-79.416710145999986, 23.148874860000134],
            [-79.516880650999894, 23.174249860000188],
            [-79.553409949999889, 23.18872471900012],
            [-79.573865677999891, 23.196389769],
            [-79.624661758, 23.221036679000221],
            [-79.702629381999969, 23.257290842000074],
            [-79.708334830999974, 23.25989130900011],
            [-79.74186445, 23.275231891000104],
            [-79.751427120999836, 23.279560538999974],
            [-79.800037769999875, 23.300867640000078],
            [-79.808118754999981, 23.307556141000148],
            [-79.818662070999977, 23.316043544000124],
            [-79.826223102999847, 23.322100185000053],
            [-79.845637416999921, 23.339288858000188],
            [-79.901972064, 23.379253703000117],
            [-79.905469401999824, 23.382121188000141],
            [-79.908617888999913, 23.384616675000117],
            [-79.922099316999919, 23.395364872000087],
            [-79.930780872999861, 23.402324700000051],
            [-79.940140211999875, 23.409810949500198],
            [-79.950576783, 23.419296018000054],
            [-79.984533665999834, 23.440538806],
            [-80.011900546999868, 23.463645116000109],
            [-80.049057675999933, 23.494117771000148],
            [-80.05883358299991, 23.491541427000143],
            [-80.192481936000036, 23.45642156500007],
            [-80.227996858999916, 23.446801325000067],
            [-80.25949078199983, 23.438359409999961],
            [-80.263843109999868, 23.437512826000088],
            [-80.277511707, 23.434854106000088],
            [-80.286243860999861, 23.433168206000133],
            [-80.294234894999903, 23.434203415000084],
            [-80.312926148999907, 23.436624798000153],
            [-80.481507074999968, 23.4575306280001],
            [-80.483994267999975, 23.457850464000089],
            [-80.48649672, 23.458180762000055],
            [-80.519897187999987, 23.462813512500091],
            [-80.55962256, 23.475992576000138],
            [-80.620500466999943, 23.498445686000039],
            [-80.691029809999861, 23.524544420000112],
            [-80.69408918299996, 23.525809905000116],
            [-80.698607201999948, 23.527626798000071],
            [-80.701485339999977, 23.530056904000233],
            [-80.729287139, 23.556912398000222],
            [-80.811827138000012, 23.636052885000069],
            [-80.872515654999916, 23.694749849000104],
            [-80.910520327999933, 23.71572947300011],
            [-81.034568759999956, 23.784259275000167],
            [-81.091518973999882, 23.828284360000126],
            [-81.207410301999914, 23.918161090000112],
            [-81.210877589999825, 23.920845137000143],
            [-81.215277779999838, 23.925000000000111],
            [-81.187777779999891, 23.999444439999976],
            [-81.151666669999884, 24.038333330000128],
            [-81.051944439999829, 24.123888890000089],
            [-81.032777779999918, 24.140000000000143],
            [-81.018888889999857, 24.14888889],
            [-80.997777779999893, 24.16277778000007],
            [-80.996666669999883, 24.163611110000105],
            [-80.781944439999933, 24.309166670000067],
            [-80.780555560000039, 24.310000000000102],
            [-80.763333329999881, 24.320555560000216],
            [-80.756111109999864, 24.32472222],
            [-80.664444439999841, 24.36805556],
            [-80.64916667, 24.375277780000019],
            [-80.602777779999855, 24.391111110000097],
            [-80.539722219999931, 24.41805556000007],
            [-80.491944439999884, 24.44111111000025],
            [-80.455833329999876, 24.455833330000146],
            [-80.41972222000004, 24.467777780000247],
            [-80.41, 24.471111110000081],
            [-80.351666669999986, 24.49333333],
            [-80.32916667, 24.50111111000011],
            [-80.322777779999882, 24.503333330000132],
            [-80.296666669999922, 24.515277780000119],
            [-80.282222219999824, 24.523611110000147],
            [-80.25472222, 24.536388890000083],
            [-80.222777779999831, 24.550833330000088],
            [-80.212222219999916, 24.554444440000097],
            [-80.064444439999903, 24.606944440000248],
            [-79.999722219999967, 24.641666670000106],
            [-79.882777779999913, 24.695833330000127],
            [-79.8475, 24.709444440000098],
            [-79.829166732999965, 24.716574046000076],
            [-79.827500001333306, 24.71722225300006],
            [-79.826482566999914, 24.722600079000145],
            [-79.82361111, 24.737777779999973],
            [-79.766111109999969, 24.923888889999986],
            [-79.746944439999851, 24.983611109999956],
            [-79.735370887999892, 25.007027377999961],
            [-79.734999999999843, 25.00777778000004],
            [-79.734619483999893, 25.008604988000101],
            [-79.71583333, 25.049444440000102],
            [-79.708333329999846, 25.064722220000121],
            [-79.695833329999886, 25.150555560000058],
            [-79.693333329999973, 25.163888890000138],
            [-79.691944439999901, 25.177222220000047],
            [-79.691944439999901, 25.265555560000152],
            [-79.690000020999946, 25.280833462500041],
            [-79.690332679999926, 25.282927704000102],
            [-79.70222221999984, 25.35777778000012],
            [-79.705555559999965, 25.372222219999955],
            [-79.70333332999985, 25.400833330000253],
            [-79.703055559999882, 25.46611111000017],
            [-79.70333332999985, 25.517222220000136],
            [-79.707499999999897, 25.618611110000259],
            [-79.707499999999897, 25.623055560000111],
            [-79.707499999999897, 25.676666670000074],
            [-79.713333329999926, 25.708333330000144],
            [-79.716388889999962, 25.727500000000262],
            [-79.712499999999892, 25.770833330000087],
            [-79.712222219999916, 25.773611110000061],
            [-79.706666669999976, 25.805277780000054],
            [-79.70638889, 25.80638889],
            [-79.704444439999861, 25.825555560000069],
            [-79.699722219999842, 25.864722219999962],
            [-79.696111109999947, 25.889722220000081],
            [-79.693888889999926, 25.90083333000014],
            [-79.685, 25.93805556000008],
            [-79.67722222, 25.963055560000029],
            [-79.668888889999891, 25.9875],
            [-79.6675, 25.991388890000067],
            [-79.63944444, 26.047500000000227],
            [-79.609722219999981, 26.116111110000062],
            [-79.602499999999878, 26.129444439999972],
            [-79.598055560000034, 26.135555560000071],
            [-79.55638889, 26.253333330000132],
            [-79.554722219999917, 26.25694444000014],
            [-79.531944439999904, 26.31555556],
            [-79.498333329999895, 26.388888890000132],
            [-79.498611109999956, 26.391111110000153],
            [-79.499444439999905, 26.425000000000139],
            [-79.498055560000012, 26.484444440000047],
            [-79.504166669999904, 26.519722220000133],
            [-79.510555559999887, 26.569444440000026],
            [-79.51277778, 26.580555560000093],
            [-79.51388889, 26.58888889],
            [-79.518611109999824, 26.608055560000125],
            [-79.525833329999926, 26.636666670000253],
            [-79.533611109999867, 26.68638889000006],
            [-79.538888889999839, 26.7275],
            [-79.539722219999874, 26.741388890000096],
            [-79.54472222000004, 26.7625],
            [-79.574166669999926, 26.899166670000071],
            [-79.577310103999963, 26.919599006000098],
            [-79.577500017499887, 26.920833503000111],
            [-79.588055559999873, 27.007500000000121],
            [-79.588611109999931, 27.099444440000113],
            [-79.582222219999949, 27.198055559999986],
            [-79.571666670000013, 27.270000000000138],
            [-79.52722222, 27.433333330000224],
            [-79.476666669999872, 27.88166667],
            [-79.233487615999962, 28.224756491000235],
            [-79.189994062499949, 28.286111471000098],
            [-76.627649163999877, 28.28611183200023],
            [-76.616862068999893, 28.28611183200023],
            [-76.612601842666578, 28.28632041266674],
            [-76.616271973000011, 28.294069290000138],
            [-76.627037047999863, 28.32160949700021],
            [-76.638107299999831, 28.349079132000114],
            [-76.649436950999871, 28.376449585000174],
            [-76.661041259999905, 28.403730392000071],
            [-76.672927855999831, 28.430919647000067],
            [-76.685089111000025, 28.458019257000089],
            [-76.697692870999873, 28.487930298000109],
            [-76.707366942999982, 28.51581001300012],
            [-76.717330933, 28.54359054600009],
            [-76.72125244099982, 28.556379318000054],
            [-76.730377196999854, 28.584400177000092],
            [-76.73979187, 28.612340927000091],
            [-76.740351252999915, 28.615944974500096],
            [-76.743766784999906, 28.637950897000152],
            [-76.745178222999897, 28.667070389000045],
            [-76.746887206999986, 28.696180344000155],
            [-76.748870849999975, 28.725280762000253],
            [-76.751136779999882, 28.754360199000118],
            [-76.753707885999887, 28.783420563000121],
            [-76.756561278999897, 28.812450409000249],
            [-76.7596969599999, 28.841480255000207],
            [-76.763122558999839, 28.870470047000211],
            [-76.766853332999972, 28.899429321],
            [-76.770843505999892, 28.928359985000085],
            [-76.775146483999947, 28.95727920500012],
            [-76.779716491999892, 28.986129761000072],
            [-76.784606933999953, 29.014970779000137],
            [-76.789756775, 29.043760300000187],
            [-76.792096749999899, 29.056092621000133],
            [-76.792689763999931, 29.059217972000098],
            [-76.79521179199989, 29.072509766000209],
            [-76.797258871666628, 29.082757413000152],
            [-76.799502325999867, 29.093988109500046],
            [-76.800949096999943, 29.101230621000255],
            [-76.801833778599928, 29.105435938600081],
            [-76.806318512999951, 29.126754039500113],
            [-76.80697631799984, 29.129880905000064],
            [-76.807680661499887, 29.13307299100002],
            [-76.813306513166651, 29.158565605166771],
            [-76.819892882999909, 29.187070847000129],
            [-76.826766967999873, 29.21557998700014],
            [-76.833946227999974, 29.244029999000091],
            [-76.841400145999955, 29.272449493000167],
            [-76.849151610999883, 29.300779343000116],
            [-76.857177733999862, 29.329069138000207],
            [-76.865509032999881, 29.357299805000011],
            [-76.874099730999973, 29.385450363000231],
            [-76.883003234999904, 29.413539886000095],
            [-76.892173767, 29.441560745000132],
            [-76.901611327999888, 29.469499588000147],
            [-76.911346435999889, 29.497379303000045],
            [-76.921371459999875, 29.525180817000162],
            [-76.931671142999875, 29.552890778000048],
            [-76.942260741999888, 29.580520630000066],
            [-76.953132628999867, 29.608079910000114],
            [-76.964256287000012, 29.635540009000138],
            [-76.975692748999904, 29.66292953500006],
            [-76.987396239999981, 29.69022941600015],
            [-76.999366760000015, 29.717439651000078],
            [-77.01164245599989, 29.744539261000213],
            [-77.024169921999828, 29.771560669000067],
            [-77.036987304999855, 29.798479080000206],
            [-77.050079345999961, 29.825290680000094],
            [-77.063430785999856, 29.852010727000177],
            [-77.077072143999857, 29.878620148000138],
            [-77.0909729, 29.905120850000074],
            [-77.105163573999874, 29.931520462000194],
            [-77.119613646999909, 29.95779037500013],
            [-77.134323119999891, 29.983980179000113],
            [-77.149322509999905, 30.010040283],
            [-77.164581298999877, 30.035980225000102],
            [-77.180099487, 30.061800003000187],
            [-77.195892333999836, 30.087509155],
            [-77.211936950999899, 30.113079071000115],
            [-77.22827148399989, 30.138530731000216],
            [-77.24485778799982, 30.163860321000257],
            [-77.245456662999914, 30.16475676300017],
            [-77.257072448999907, 30.182144165000139],
            [-77.23524384399991, 30.194060512000121],
            [-77.233596801999965, 30.194959641000011],
            [-77.211173244999856, 30.207433652000148],
            [-77.205337524000015, 30.210680007999983],
            [-77.177230834999875, 30.226589203000131],
            [-77.149276732999937, 30.242719650000097],
            [-77.121482849000017, 30.259059906000104],
            [-77.093841552999947, 30.275590897000185],
            [-77.066352843999965, 30.292329788000231],
            [-77.039031981999926, 30.309270859000137],
            [-77.011863707999908, 30.326419830000177],
            [-76.984863281, 30.343750000000213],
            [-76.967540610500009, 30.355081346000119],
            [-76.572421045999874, 30.199800237999952],
            [-76.51912081999987, 30.187802904],
            [-76.308468969999893, 30.104142013000072],
            [-76.300947980000046, 30.102434583000118],
            [-76.299762727999848, 30.102165430000142],
            [-76.296393728, 30.100823430000119],
            [-76.295428560999881, 30.100604571000034],
            [-76.294629681999965, 30.100423154000126],
            [-76.121218945999971, 30.030838782000032],
            [-75.68672572700001, 29.85648943000011],
            [-75.684818433000032, 29.855331946999982],
            [-75.683952716, 29.85486101700009],
            [-75.669766531666625, 29.846299322333451],
            [-75.66797830699997, 29.845576007000133],
            [-75.661284727, 29.842868430000109],
            [-75.659523909999876, 29.841798853999975],
            [-75.658808748999945, 29.841364318000089],
            [-75.656187982, 29.840304132000128],
            [-75.156999508000013, 29.535108669000024],
            [-75.109848726999871, 29.50628142900014],
            [-75.104062227000014, 29.50119946700012],
            [-75.099639819999936, 29.497315397000094],
            [-75.092567433, 29.492962152000061],
            [-75.061414726999885, 29.4737864290001],
            [-75.059260726999923, 29.471892428999965],
            [-75.056868091999945, 29.470419615000068],
            [-75.055179063666657, 29.469333088000027],
            [-75.053352726999861, 29.468155429000134],
            [-75.051973556999968, 29.46694300099999],
            [-75.048505980999948, 29.463893944000134],
            [-75.047541502499854, 29.463299636500111],
            [-75.043663085999896, 29.459950701000096],
            [-75.039776863999862, 29.456532936000016],
            [-75.037110039999931, 29.454847805000028],
            [-75.03653572699983, 29.45434242900005],
            [-75.021090727, 29.444824428999979],
            [-75.002580051, 29.428532882],
            [-74.998051322999913, 29.424547012],
            [-74.972733623999886, 29.408928973000044],
            [-74.969851386999892, 29.406390008000031],
            [-74.967673726999919, 29.404471429],
            [-74.950020050999854, 29.393574231000045],
            [-74.944416174499963, 29.390093590500143],
            [-74.943629927499927, 29.38943913450008],
            [-74.935690429999852, 29.382479044000135],
            [-74.743566626999893, 29.263575142000079],
            [-74.731829293999965, 29.253183903999954],
            [-74.661847439000042, 29.209735061999979],
            [-74.66022245299996, 29.208294067000125],
            [-74.637369222999922, 29.194027424999987],
            [-74.621461725999922, 29.184073429000094],
            [-74.613634407999939, 29.177126207666774],
            [-74.611226667999944, 29.175628438000089],
            [-74.608379725999839, 29.173857429000108],
            [-74.604722326999934, 29.170608715000057],
            [-74.562067929999955, 29.132720162999973],
            [-74.561379619499888, 29.132291346500054],
            [-74.097149726, 28.717496429],
            [-74.090542463333293, 28.709002164000012],
            [-74.089848398000015, 28.708378392],
            [-74.089248725999965, 28.707839429000089],
            [-74.086259991999839, 28.703996403000176],
            [-74.085610029499946, 28.703224358500009],
            [-74.080313725999986, 28.698463429000142],
            [-73.825544240999932, 28.369109928000128],
            [-73.708994982999911, 28.218440385000051],
            [-73.440759899999932, 27.68843310700008],
            [-73.440404135499932, 27.687172472000114],
            [-73.439928639999891, 27.686123467000115],
            [-73.420748981999964, 27.61815570200001],
            [-73.346071724999945, 27.352859427000013],
            [-73.320630897999933, 27.329461703000035],
            [-73.297508891999854, 27.308196371000108],
            [-73.276055637999917, 27.302726678999974],
            [-73.167552724999979, 27.275062426999952],
            [-72.651173880999977, 27.049329693000161],
            [-72.582072754, 27.019122366999952],
            [-72.418584288000034, 26.911590275000023],
            [-72.058436723999932, 26.674708427000141],
            [-71.712306784999981, 26.347078957000136],
            [-71.612950512, 26.253033136999974],
            [-71.589172724000036, 26.220568426000042],
            [-71.584359723999967, 26.215988425999981],
            [-71.23054472299998, 25.730557426],
            [-71.132933723, 25.524683426000124],
            [-70.751518722999975, 25.156017425000059],
            [-70.699361929999895, 25.115357052999954],
            [-70.631393941499937, 25.062369649000075],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BIH", Country: "Bosnia & Herzegovina" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.039718170314188, 44.861382201327885],
            [19.121109132584166, 44.901382152135298],
            [19.175345075422683, 44.922427099410996],
            [19.304863249479865, 44.909435987642965],
            [19.371391253359576, 44.889164187118539],
            [19.382200220448595, 44.864818111087075],
            [19.345218090068755, 44.76773605839081],
            [19.336109140586416, 44.747218165188414],
            [19.313609094915535, 44.704718060294638],
            [19.193982237185253, 44.560154034719361],
            [19.130827111152882, 44.521427127369677],
            [19.105554164265698, 44.376664115412453],
            [19.104445070834487, 44.355827039336233],
            [19.128991139076192, 44.315227111897755],
            [19.160909091181253, 44.286782117564016],
            [19.237436202663076, 44.265182120658594],
            [19.35795405670342, 44.209409104411449],
            [19.485836083256174, 44.120282148403064],
            [19.59694508331765, 44.060273253555422],
            [19.618563352772043, 44.052618061363901],
            [19.61978224913517, 44.019753960025739],
            [19.525791274700538, 43.960445121733031],
            [19.485273154637355, 43.958882232062635],
            [19.428527167066761, 43.96065399876089],
            [19.366127082874556, 43.970873215140941],
            [19.308927131426827, 43.997736041426819],
            [19.239454062039187, 44.010609135997569],
            [19.251218230816818, 43.962382179562283],
            [19.366945156626656, 43.859445032205528],
            [19.437500162079289, 43.800282206666637],
            [19.475273208844953, 43.772773135644101],
            [19.490136167235192, 43.758473105872767],
            [19.499582236864171, 43.737082153633011],
            [19.507636072371923, 43.709582135065986],
            [19.510554148151527, 43.685827150848141],
            [19.49389125986832, 43.588054094052083],
            [19.480554143136402, 43.571391205768876],
            [19.408609250495402, 43.584445014172772],
            [19.287500137003434, 43.544164269608032],
            [19.228809212614692, 43.513218115359834],
            [19.217782148404325, 43.519027109552681],
            [19.009163283307259, 43.555973197748798],
            [18.949854109738567, 43.506035997784849],
            [18.957145024417059, 43.455900146715109],
            [18.982773196361762, 43.45277319390776],
            [19.005000159626348, 43.447353958213412],
            [19.025273301255453, 43.432354039524952],
            [19.092918109917321, 43.305273155279977],
            [19.075554159250601, 43.255273091041872],
            [19.05957322261159, 43.235945092817659],
            [19.016663242651504, 43.239435987861796],
            [18.955054074845066, 43.293891201288133],
            [18.95812721583377, 43.320909092783225],
            [18.915573299121633, 43.35777320596587],
            [18.880827125239165, 43.35553607600211],
            [18.839336201490624, 43.348018011747072],
            [18.702773205432749, 43.257145105302371],
            [18.645836110469332, 43.147218121229983],
            [18.648263341997733, 43.127073222719943],
            [18.658118113226749, 43.08388211372646],
            [18.647045116186831, 43.043400203485064],
            [18.579718149294223, 43.029164211453988],
            [18.552218130727169, 43.022773177902891],
            [18.530000219917895, 43.014718168928752],
            [18.503054077514207, 42.995409113805763],
            [18.481736047832413, 42.966173203086029],
            [18.455409160437029, 42.828609072510389],
            [18.490836112497334, 42.766391210893502],
            [18.523818231032664, 42.727564223619751],
            [18.557845069982619, 42.713954024481367],
            [18.576382151821008, 42.663054079116066],
            [18.572082235480082, 42.641173120815537],
            [18.543327110728001, 42.615136079632705],
            [18.463891143560005, 42.569444984191122],
            [18.455554167362436, 42.565827019494506],
            [18.404718092099301, 42.610409189142658],
            [18.362500122067331, 42.626664214016344],
            [18.340973215357849, 42.629027072527933],
            [18.307082163239727, 42.618191115710587],
            [18.274654088505798, 42.617145054191312],
            [18.226945133688304, 42.645554006341328],
            [18.160554090106871, 42.69500019441584],
            [17.990554173446299, 42.764718015377056],
            [17.960000124629687, 42.779164225540185],
            [17.879718088152941, 42.821382195572269],
            [17.845345076600836, 42.860835981567291],
            [17.797500167313331, 42.90388208363548],
            [17.744445066997429, 42.920273230616871],
            [17.706527180944477, 42.927082185861579],
            [17.686109200028142, 42.920273230616871],
            [17.649814328190558, 42.889096573680703],
            [17.650314912234194, 42.888463695924358],
            [17.652763208000152, 42.885185598000149],
            [17.651557821468316, 42.882546601708682],
            [17.634881902055071, 42.88748138495437],
            [17.632740021000046, 42.888818741],
            [17.631195068000125, 42.889883041000061],
            [17.630233765000156, 42.891170502000094],
            [17.627426147500159, 42.893492698500083],
            [17.624267578000087, 42.894334793000056],
            [17.623142242000142, 42.894401550000097],
            [17.6201496125002, 42.895968437000064],
            [17.6167736055001, 42.897708893000171],
            [17.615585327000161, 42.898532866999957],
            [17.614135742000116, 42.899984360000062],
            [17.61307525600003, 42.901468277],
            [17.60916709900016, 42.901978493000044],
            [17.606513977000191, 42.902484894000011],
            [17.604482651000154, 42.902370453000046],
            [17.603116989000142, 42.902851105000124],
            [17.601133347000143, 42.903181076000166],
            [17.60048866250014, 42.903434753000099],
            [17.597312927000047, 42.905071259000053],
            [17.596378326000092, 42.905361175999971],
            [17.595029831000062, 42.905988693000026],
            [17.59141159100011, 42.906667709000075],
            [17.590507506999984, 42.906671524000032],
            [17.588879585500166, 42.907502174500067],
            [17.585626602, 42.909652710000145],
            [17.584884644000169, 42.910276413000147],
            [17.583486557000128, 42.910888671999984],
            [17.582496643000098, 42.911220550500147],
            [17.579799652000162, 42.912059784],
            [17.578510284000146, 42.912059784],
            [17.577421188000187, 42.912380219000042],
            [17.575654030000152, 42.913162231500039],
            [17.573622704000229, 42.913990021],
            [17.570014954000044, 42.915205002000093],
            [17.569237709000134, 42.915597915500129],
            [17.567258835000189, 42.916309356999975],
            [17.565724373000137, 42.917105674500093],
            [17.564823151000098, 42.917566298999972],
            [17.564043045000119, 42.91781234699998],
            [17.562091827000046, 42.918718338000176],
            [17.560003281000093, 42.91936492900011],
            [17.559087753, 42.919523239000156],
            [17.557849884000092, 42.919861793500061],
            [17.555767059500113, 42.920490265000055],
            [17.553750991500095, 42.921226501499987],
            [17.550303459000162, 42.922354698000035],
            [17.548608780000109, 42.923067093],
            [17.547771454000099, 42.923316956000164],
            [17.545043945000117, 42.925525665000023],
            [17.541364689400183, 42.92831441240007],
            [17.542920942500103, 42.930422104500124],
            [17.544999952500064, 42.932501114499999],
            [17.547519560500177, 42.935791291500081],
            [17.548801298500081, 42.937345780000058],
            [17.551355238500207, 42.938308991500051],
            [17.552432890500199, 42.938026703500057],
            [17.554810400500088, 42.937000550250119],
            [17.556112165500139, 42.936386384000073],
            [17.558013792500191, 42.935245789500087],
            [17.558948393000122, 42.935127534000102],
            [17.562553282500176, 42.935789383500023],
            [17.563323851500087, 42.935848511500026],
            [17.564546461500157, 42.937059678000097],
            [17.567636366250099, 42.937134064750111],
            [17.571870680250129, 42.936799325000052],
            [17.575281019500068, 42.935768403000012],
            [17.575786467500052, 42.936573304500044],
            [17.57857953300018, 42.938322521500098],
            [17.57976263550006, 42.938743625500038],
            [17.578618028670746, 42.943757840193811],
            [17.675300232939037, 42.96751816327334],
            [17.667636155930182, 43.022909132372703],
            [17.65444505195191, 43.047218160392035],
            [17.636663347229728, 43.070418094979956],
            [17.589445069025317, 43.10221802988822],
            [17.56528222139778, 43.115000096990215],
            [17.434718153459414, 43.179445030286885],
            [17.303745048645141, 43.275691071958292],
            [17.287636204163334, 43.302082164732084],
            [17.278191140362651, 43.329018081213817],
            [17.257845076175897, 43.420064157778526],
            [17.27069118101852, 43.445344983654806],
            [17.254718123368434, 43.464027072418517],
            [17.223327057698384, 43.478054019783755],
            [17.160273185056667, 43.497773117611345],
            [17.106663202749019, 43.51027321748991],
            [17.071391148259778, 43.526382229609695],
            [17.053054058631517, 43.536245047465812],
            [16.976391160318087, 43.586109157233821],
            [16.955827166648049, 43.603335979964257],
            [16.937500135303793, 43.621664184775099],
            [16.829718080259823, 43.725554019249373],
            [16.709582273367346, 43.833745111169449],
            [16.699027110307156, 43.853336133516152],
            [16.646391272789316, 43.91221816529783],
            [16.533473290379391, 44.015691083907186],
            [16.510418195078614, 44.022500206789786],
            [16.463609121388487, 44.026664168660815],
            [16.430000204131971, 44.036036142265644],
            [16.346800259513628, 44.087500189716351],
            [16.323054160113202, 44.120064218919893],
            [16.305827169744589, 44.152909041880761],
            [16.27263617418177, 44.183191181757579],
            [16.243673178230239, 44.197527086074743],
            [16.206800180230175, 44.196382118097873],
            [16.166109225322828, 44.192491070994762],
            [16.145482199740854, 44.198673227518285],
            [16.191945100828917, 44.313891204165841],
            [16.198054167156215, 44.353609187749854],
            [16.129863193682041, 44.492353993210187],
            [16.115409104529789, 44.509164235351776],
            [16.074163267630723, 44.530545129307527],
            [16.033336190615586, 44.574164218278256],
            [16.010554010083013, 44.616945116929159],
            [15.961663206914324, 44.693882103477208],
            [15.942218197321409, 44.707845013101988],
            [15.863191267029606, 44.712145097081006],
            [15.763745182355109, 44.77999107141973],
            [15.740591180596624, 44.812244970205043],
            [15.769163244582757, 44.922773104375054],
            [15.765000120902045, 45.056391202562921],
            [15.765136075371942, 45.077918109272289],
            [15.785763268591921, 45.168953959915299],
            [15.806800169240603, 45.191800178188132],
            [15.828745165281674, 45.208609079225155],
            [15.882500154514616, 45.222500072120383],
            [15.930827190874083, 45.228327003586159],
            [16.022427143602215, 45.214373146416975],
            [16.11277316361182, 45.126244977993665],
            [16.197218156131186, 45.044444978847778],
            [16.215000196129523, 45.031382117988457],
            [16.255973285898563, 45.011664193627254],
            [16.291763342005311, 44.999118160919338],
            [16.352663233163213, 45.007382214559144],
            [16.366400166677636, 45.042018082595447],
            [16.417800176387999, 45.124264166629857],
            [16.476391188490567, 45.18582706633056],
            [16.505409169727045, 45.21209108945196],
            [16.531091153490223, 45.225527112641643],
            [16.595918133934759, 45.230191138857649],
            [16.62966334093727, 45.220809106969099],
            [16.662773199677133, 45.21027322228629],
            [16.682782143717446, 45.20499111452834],
            [16.818473254204463, 45.187491209391695],
            [16.844427146907464, 45.196336128923249],
            [16.913927206023402, 45.265944985142696],
            [17.04257315923374, 45.211800069773048],
            [17.071109181036235, 45.188327052778746],
            [17.182773230727548, 45.148327101971219],
            [17.237082263761891, 45.148745023664631],
            [17.282500109159088, 45.176382170168054],
            [17.485900233686607, 45.117564176126919],
            [17.519554077944264, 45.10950916715278],
            [17.596382267388805, 45.108891085610921],
            [17.690000247131167, 45.12277319368873],
            [17.758054092669141, 45.086664122346136],
            [17.832773227630895, 45.055544965615908],
            [17.860345162927302, 45.049373202652461],
            [17.912500214477831, 45.068053950311892],
            [17.933054149864063, 45.080273256433131],
            [18.070273281304111, 45.104436104060582],
            [18.117982236121719, 45.084436044837886],
            [18.173054022347372, 45.07819119167867],
            [18.201527179875825, 45.08332711904454],
            [18.21527316584573, 45.098536082398951],
            [18.217845069023269, 45.125900146496278],
            [18.26082713335083, 45.137773112377303],
            [18.41916329643422, 45.108891085610921],
            [18.634163304436555, 45.08332711904454],
            [18.795000124520072, 44.993609071222821],
            [18.797354098214441, 44.945136022109452],
            [18.769791215373544, 44.942145023771999],
            [18.768054149754448, 44.915836073649459],
            [18.791109245055338, 44.897353977096103],
            [18.846736080910688, 44.862773094344547],
            [18.962773304777045, 44.853327024715568],
            [19.029718224521787, 44.86027310789656],
            [19.039718170314188, 44.861382201327885],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BLR", Country: "Belarus" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.168009229552098, 56.150154072349423],
            [28.168609206183049, 56.148609119951956],
            [28.187082250281122, 56.134018070501398],
            [28.309900266197275, 56.051364123057382],
            [28.348036081733284, 56.055527079100116],
            [28.378954072786911, 56.090409039814389],
            [28.528191177033563, 56.105136043734845],
            [28.620963255105011, 56.099300059813558],
            [28.650000179442685, 56.091245050839618],
            [28.700554119844043, 56.033600187970038],
            [28.707009191135484, 56.003809059982601],
            [28.725491287689039, 55.968564162859749],
            [28.74610926081553, 55.956109157620389],
            [28.843809227415733, 55.951164170009278],
            [28.865136141914917, 55.978318183612245],
            [29.00610919252162, 56.023882209401151],
            [29.027636099231273, 56.029436058460831],
            [29.059436201777345, 56.03277322940032],
            [29.139163356262458, 56.021936099116502],
            [29.172218229717345, 56.015545065565405],
            [29.410063282526409, 55.957909087513357],
            [29.455654130405577, 55.919400109646759],
            [29.410273165382506, 55.897491155789496],
            [29.359791141710588, 55.796800190851954],
            [29.382291187381469, 55.759991230592036],
            [29.484991294515766, 55.692209126355962],
            [29.524436195693482, 55.692973220651439],
            [29.656382271831291, 55.765273170711978],
            [29.906936133908374, 55.846100198534728],
            [29.937354060617139, 55.853053993066581],
            [29.972518156193104, 55.856373226733254],
            [30.24582720907452, 55.854436001266023],
            [30.466663200997999, 55.805545030459243],
            [30.491518226191744, 55.799018042438334],
            [30.610963364260698, 55.71776420810464],
            [30.655273289693156, 55.660544978279532],
            [30.757500154572796, 55.597000093748321],
            [30.788745039851136, 55.594709151966001],
            [30.845691187269921, 55.614782133746658],
            [30.885000133977741, 55.618464136183931],
            [30.914582217299454, 55.610691094433392],
            [30.926245132686489, 55.602573221185295],
            [30.930273307725912, 55.577773181276697],
            [30.9399912862946, 55.495827169376795],
            [30.936245078478834, 55.399364204050912],
            [30.913463233222473, 55.378600218170519],
            [30.889300217956844, 55.37569119484634],
            [30.857773197817011, 55.353609070868899],
            [30.83139115749853, 55.329991214587366],
            [30.815973317116431, 55.301727101724751],
            [30.917909162317216, 55.200964220057628],
            [30.944027172684827, 55.191654104898461],
            [30.978054179272846, 55.152218088538248],
            [31.027536074255096, 55.048500083079333],
            [30.940409207988097, 54.963882088077739],
            [30.908191183748357, 54.954018096755348],
            [30.864718107531445, 54.947209141510811],
            [30.838891284480809, 54.922491076615373],
            [30.794718151708679, 54.843609153248977],
            [30.782709231357586, 54.799091188979261],
            [30.838054099989307, 54.781245111240466],
            [30.876382196384981, 54.769154048238008],
            [30.919445062259314, 54.751391118979072],
            [31.008745188387678, 54.709164264129569],
            [31.103054172229662, 54.645545115936144],
            [31.138191278077358, 54.57638217696676],
            [31.111382263610011, 54.547773232606474],
            [31.087291165074049, 54.516173122270672],
            [31.092700174846271, 54.4926270150806],
            [31.130554023158794, 54.478600067715192],
            [31.198600157345851, 54.460936044914021],
            [31.225963215614598, 54.459300065047685],
            [31.312009151645185, 54.341309187183825],
            [31.310409213962572, 54.300409187610754],
            [31.328609175654236, 54.24325399552599],
            [31.52416333587334, 54.150827084228311],
            [31.544163227458142, 54.143327124884095],
            [31.675554086327992, 54.106654119094273],
            [31.726245154665634, 54.10638221015445],
            [31.755836122804823, 54.104854021563128],
            [31.845554170626627, 54.060964197119162],
            [31.865273268454217, 54.006100114454895],
            [31.857218091841986, 53.969709075888701],
            [31.828882229888137, 53.886382229255773],
            [31.805200168227969, 53.856518178710573],
            [31.7791632946834, 53.837353962874786],
            [31.764227078459129, 53.804345022249223],
            [31.890200210377344, 53.778118047140126],
            [31.93555418567226, 53.789164222089695],
            [31.971382295619634, 53.79833603584602],
            [31.993045156799127, 53.802491112899716],
            [32.119709125178957, 53.819991185674539],
            [32.329436077878853, 53.762218079686008],
            [32.4566633101538, 53.724573108401259],
            [32.484300121380983, 53.71221818308608],
            [32.505482196592851, 53.682773227700679],
            [32.420545186355611, 53.647564205123658],
            [32.424436065820629, 53.601245137494502],
            [32.443527191460447, 53.572845070161904],
            [32.473736073503431, 53.552773261847719],
            [32.620273199091713, 53.499854116001714],
            [32.641245056171414, 53.501664271816466],
            [32.665127110041652, 53.498464228813262],
            [32.740054116202685, 53.458809109503406],
            [32.73654511624764, 53.438364138858773],
            [32.724436115972338, 53.360273131878174],
            [32.611936055255939, 53.313045130666055],
            [32.446100268104459, 53.214991112474863],
            [32.421936079372443, 53.196100146683378],
            [32.298882196700305, 53.131936007143835],
            [32.223318165896131, 53.105553966825354],
            [32.142073216380055, 53.089573197824521],
            [32.112073211364731, 53.087209165846261],
            [31.958045178887545, 53.094436042784295],
            [31.91832719530376, 53.09832708988732],
            [31.79965403051952, 53.132145051809502],
            [31.789445040061224, 53.170273155994707],
            [31.776454095931371, 53.192700111469819],
            [31.623191163578355, 53.21776418132923],
            [31.425754092624601, 53.208809123589518],
            [31.400000192131898, 53.188600187339134],
            [31.383191291095073, 53.153464254957768],
            [31.374827157530916, 53.121936061351434],
            [31.386836077882009, 53.104018066883199],
            [31.31471818275898, 53.051382229365359],
            [31.266663222977314, 53.023318108712928],
            [31.390827204909129, 52.910273224288588],
            [31.415273193226682, 52.89305411290907],
            [31.438882164690853, 52.879436034781961],
            [31.484854053889393, 52.861245125545608],
            [31.50889117296893, 52.8575000911964],
            [31.538473256290587, 52.854718137524614],
            [31.579718087361329, 52.804573233999278],
            [31.587500181567208, 52.782218027615798],
            [31.593745202364602, 52.737145013716187],
            [31.555136144573879, 52.701454031705012],
            [31.505136080335916, 52.687836121215881],
            [31.587082259873654, 52.592209166914913],
            [31.608891133806736, 52.49138224750736],
            [31.628745180275956, 52.367218097937595],
            [31.605973225665167, 52.3318001983328],
            [31.593818124922365, 52.313118109568975],
            [31.701382250483135, 52.221100067509255],
            [31.765418146903727, 52.176382111029184],
            [31.786036120030218, 52.157554009511657],
            [31.786109210226215, 52.124164195204784],
            [31.783882306184154, 52.108045124801109],
            [31.734718085333299, 52.106100187982847],
            [31.622218192254849, 52.108891194110029],
            [31.523609124433932, 52.116391153454273],
            [31.50278227427961, 52.117491194430087],
            [31.42250023780278, 52.119991180878159],
            [31.37082714568632, 52.117354066493689],
            [31.345973293959247, 52.113744986614378],
            [31.323891169981692, 52.105000147007004],
            [31.296245138660964, 52.086936139785067],
            [31.132218166219758, 52.086382095983637],
            [31.106382290713753, 52.086382095983637],
            [30.959663277826138, 52.07934498533406],
            [30.935000198215761, 52.061800153196188],
            [30.930618139223355, 52.032218069874418],
            [30.95888225208617, 52.000827004204368],
            [30.896945184226496, 51.97776419755273],
            [30.825000123947433, 51.947491110131423],
            [30.746936106695131, 51.895273194307023],
            [30.662082244937579, 51.821654100321084],
            [30.66693620508002, 51.801109049752299],
            [30.625973173594986, 51.707909159341554],
            [30.565000192241286, 51.643327098108486],
            [30.553891153017759, 51.541936076615784],
            [30.586382259663566, 51.489154058706092],
            [30.643745155309517, 51.368882129705625],
            [30.642600187332647, 51.331200110408659],
            [30.615827215048938, 51.323882206001954],
            [30.567773261095653, 51.297773248089769],
            [30.551418156297899, 51.251845112425812],
            [30.464718096541759, 51.295000179235487],
            [30.350273266645246, 51.358327134283414],
            [30.347636152260719, 51.400064150709838],
            [30.20721815128374, 51.483735996478416],
            [30.179991215122897, 51.491518090684266],
            [30.152218114149576, 51.493327073032717],
            [30.043054050906363, 51.490273210421222],
            [30.012218202504215, 51.489154058706092],
            [29.983327123282123, 51.486382163318197],
            [29.91166335967452, 51.472764252829066],
            [29.881663354659423, 51.459718155776201],
            [29.834445076455012, 51.441936115777864],
            [29.762773266220307, 51.440818137529206],
            [29.738218145523064, 51.457145079132275],
            [29.718954185039223, 51.491027078795],
            [29.675827113786255, 51.499300184890288],
            [29.624027119655381, 51.490682079659237],
            [29.409718115752668, 51.402773181823861],
            [29.353836134763952, 51.375318090257863],
            [29.342218146377917, 51.373182213684757],
            [29.312009096696983, 51.387627082743407],
            [29.2495821904146, 51.502282130772343],
            [29.24151812898495, 51.540964111120772],
            [29.181382164484745, 51.618045098765904],
            [29.163191255248279, 51.627773135618597],
            [29.118054035970346, 51.636936064557389],
            [29.08395410682445, 51.63798212607675],
            [29.052500176880329, 51.631100080636301],
            [28.836945119248099, 51.550273220451444],
            [28.810554026474193, 51.534436117271568],
            [28.793054121337633, 51.516109085927226],
            [28.766036062204392, 51.481382190422053],
            [28.769445149873633, 51.448045014467354],
            [28.75715409466082, 51.415645102928224],
            [28.666527113255967, 51.446654121450493],
            [28.652918087584197, 51.464718128672345],
            [28.637218112340491, 51.510000187237821],
            [28.632773189074072, 51.543600052038855],
            [28.628263222238985, 51.564854043980105],
            [28.490554084737738, 51.581109068853792],
            [28.468191167003141, 51.577909193488566],
            [28.433327143561797, 51.566100097709736],
            [28.319445074645927, 51.58915401954404],
            [28.256736201139773, 51.659291103303062],
            [28.21569119464138, 51.655682191061643],
            [28.182009187188982, 51.637773249049005],
            [28.157500166959522, 51.609991095620259],
            [28.138191279474341, 51.583045120854635],
            [28.109163239954142, 51.569444980000256],
            [28.079027112831142, 51.564436122286779],
            [28.006109248866693, 51.562209050606796],
            [27.983745157665652, 51.565827015303526],
            [27.955273173603672, 51.575000170164301],
            [27.925618167724139, 51.59166423191401],
            [27.895827207374822, 51.613054010687264],
            [27.865973215113257, 51.621800191399117],
            [27.832082162995135, 51.609164137050627],
            [27.813891253758783, 51.592209055621922],
            [27.814227200439035, 51.568327001751598],
            [27.816800277083047, 51.533254101282395],
            [27.747909247053457, 51.466518058565313],
            [27.681663210397431, 51.493191118562819],
            [27.709754153140096, 51.550509087207629],
            [27.724473278071486, 51.58165406019971],
            [27.687218232923414, 51.601391095300201],
            [27.597218217877895, 51.615827079541461],
            [27.511973256517223, 51.628864124138929],
            [27.453191137021719, 51.602773103499644],
            [27.416663305795197, 51.597491163379786],
            [27.282563248173375, 51.598191219935003],
            [27.199373194200831, 51.675136085472147],
            [27.204582211762755, 51.717491183440515],
            [27.202563346558208, 51.751309145362697],
            [27.170000155544898, 51.764164135022654],
            [27.080827099068898, 51.77110015991984],
            [27.019300241551917, 51.768118046399636],
            [26.971109159662348, 51.752218078945688],
            [26.948054064361571, 51.748882081472388],
            [26.926527157652174, 51.750409096597238],
            [26.895000137512142, 51.75555407641879],
            [26.820827167362864, 51.772491220574594],
            [26.772500131003397, 51.78582716384021],
            [26.722500234403469, 51.80249122558989],
            [26.672500170165478, 51.814991157830278],
            [26.642354152396535, 51.820691187281668],
            [26.608054063402392, 51.821109108975079],
            [26.571109148672775, 51.818327155303407],
            [26.530827063003557, 51.813327014769058],
            [26.498600153946455, 51.811936121752396],
            [26.457218194939429, 51.812764253788416],
            [26.439991204571072, 51.823054045793455],
            [26.435545107838152, 51.856173124626807],
            [26.364163311454064, 51.858891208196226],
            [26.33860923553371, 51.857500147541387],
            [26.296663342079512, 51.853600215620972],
            [26.207773258655521, 51.858327106110821],
            [26.175409221662221, 51.862909157313638],
            [26.144436077685697, 51.878464125632021],
            [26.026945096528436, 51.910273112995597],
            [25.818327069621688, 51.935818136460554],
            [25.770418122593838, 51.938182168438814],
            [25.733600109878608, 51.927354090610308],
            [25.68596323942856, 51.922354117714164],
            [25.598327088723124, 51.918882165771052],
            [25.562773234648404, 51.919991091564285],
            [25.546673274984016, 51.922136020592987],
            [25.439991260916059, 51.922218163244267],
            [25.352082195442506, 51.928609196795335],
            [25.312218199104791, 51.939709183563579],
            [25.269582139741203, 51.95360906127631],
            [25.240963304735203, 51.959854082073591],
            [25.173054130846367, 51.959300205910168],
            [25.09360911122306, 51.94221805482907],
            [25.051936132537094, 51.932209056581073],
            [24.991936122506672, 51.911936082590145],
            [24.897500236650188, 51.900691088896608],
            [24.740554128393427, 51.889718171780956],
            [24.700273216190709, 51.893882133652184],
            [24.394227131623637, 51.88471803124672],
            [24.314582287428067, 51.824027017116677],
            [24.30610918912231, 51.803045101753227],
            [24.297354123593067, 51.764573171898846],
            [24.283400266423769, 51.741173245100484],
            [24.070554072267356, 51.624991181946896],
            [24.043054053700331, 51.610273230481866],
            [23.998191257932859, 51.592909112177139],
            [23.963463356599362, 51.585545107302707],
            [23.938400124930297, 51.596100102724947],
            [23.922909194352343, 51.619854081114354],
            [23.901591164670549, 51.634154110885802],
            [23.751318224826491, 51.651791143958818],
            [23.650000125891665, 51.6361731437283],
            [23.613291245556098, 51.606727014876626],
            [23.62777316262688, 51.579718175836845],
            [23.641073231346667, 51.523500080529956],
            [23.60463609231283, 51.527691199767403],
            [23.567173175965621, 51.539600207832237],
            [23.539163202407792, 51.592764272889823],
            [23.534991193909661, 51.653591073851587],
            [23.555482265021936, 51.66520017742009],
            [23.547773261011855, 51.686382084993895],
            [23.558054168199476, 51.752491161351699],
            [23.61248222425931, 51.91595403170767],
            [23.626800191303573, 51.952073161334155],
            [23.660827197891592, 52.006100059506807],
            [23.638609119444226, 52.079436012802645],
            [23.595000256395394, 52.111936004266042],
            [23.348327215499381, 52.206791152920388],
            [23.297773275098052, 52.21166422380206],
            [23.214718169767025, 52.223464267125493],
            [23.192773173725868, 52.233191130511713],
            [23.165400224811407, 52.282273208711345],
            [23.248382239946579, 52.374482190525825],
            [23.377491209489477, 52.498327157221965],
            [23.397218186306077, 52.514445054159083],
            [23.417218077890794, 52.525273131987589],
            [23.503609180695122, 52.567500154474985],
            [23.52305402265003, 52.573609053164475],
            [23.59721810798186, 52.596382181241452],
            [23.633609146548167, 52.605553994997862],
            [23.655273181194019, 52.610273174136964],
            [23.688327216458731, 52.617209199033979],
            [23.715827067387693, 52.615954092848952],
            [23.746800211364302, 52.614645007207272],
            [23.883882214868066, 52.678045052451338],
            [23.911800155128731, 52.693182099076196],
            [23.935273172122834, 52.717491127095442],
            [23.941100271226702, 52.74971803615243],
            [23.939718095389281, 52.770273145005049],
            [23.938663316690764, 52.774709183454078],
            [23.931382292658014, 52.858609184627525],
            [23.92749124555499, 52.948373165279079],
            [23.933191275006266, 53.012082167474901],
            [23.858609100342818, 53.195827064276997],
            [23.785918218317249, 53.314336111396713],
            [23.700554066292909, 53.453045042311416],
            [23.673536174797846, 53.493745049674246],
            [23.64027326250536, 53.559154069476833],
            [23.617218167204669, 53.614018152141099],
            [23.608609114429214, 53.637500053952621],
            [23.592773184715526, 53.685273214148751],
            [23.590445194921074, 53.694427090632232],
            [23.580554046232265, 53.731100096422139],
            [23.539718084399709, 53.8408270882842],
            [23.510009099063524, 53.899318020462701],
            [23.503891147918722, 53.921382207167241],
            [23.502782222125489, 53.942491192183169],
            [23.504036154844044, 53.947045080191103],
            [23.516663324375088, 53.946653980587925],
            [23.543609131502535, 53.943318150752802],
            [23.568609163621602, 53.939154021243681],
            [23.594300199840262, 53.930827103329719],
            [23.709436201474659, 53.923882193615313],
            [23.783882254030232, 53.935545109002376],
            [23.898882301194618, 53.940545081898435],
            [23.986109247385969, 53.938882112303858],
            [24.016109252401094, 53.937209084425419],
            [24.084927192234744, 53.937554083560983],
            [24.122636201259837, 53.951453961273828],
            [24.149582176025547, 53.965273205077764],
            [24.17555417363954, 53.967491224302236],
            [24.206873154942059, 53.962982095657352],
            [24.241800210295565, 53.945691067548481],
            [24.256245079354301, 53.929436042674794],
            [24.267354118577941, 53.907209079410208],
            [24.391663275073199, 53.89033614063284],
            [24.467491168190008, 53.922627087430371],
            [24.514445081167452, 53.950409073221024],
            [24.612218137963424, 53.992218174014894],
            [24.695618242430299, 53.996518090355934],
            [24.724018142124805, 53.970200087778082],
            [24.826736186531889, 53.984364163079604],
            [24.840409249944145, 54.011109139806464],
            [24.839445163438228, 54.038191069042099],
            [24.822500140293329, 54.064782154026247],
            [24.798400156939834, 54.101591114285881],
            [24.847773254818577, 54.143400215079922],
            [24.971518141590337, 54.157282155519866],
            [25.004027185509131, 54.142353985922668],
            [25.027082280809822, 54.131936118436741],
            [25.077218131879562, 54.136109132763366],
            [25.101391205429081, 54.144436050677328],
            [25.161945091622755, 54.172491118874163],
            [25.178882235778644, 54.186100144545875],
            [25.204445196516417, 54.215964195091189],
            [25.345827116361335, 54.252773155350908],
            [25.372500176358841, 54.254164216005748],
            [25.398745088740839, 54.258473184802298],
            [25.420691258248297, 54.270964232225353],
            [25.43832711785484, 54.288891111510921],
            [25.466936062215211, 54.304364272454208],
            [25.50110924919494, 54.303045128528638],
            [25.573054141835939, 54.24304511849833],
            [25.544582157773874, 54.200545013604639],
            [25.505409165536065, 54.185127005584562],
            [25.539927184013379, 54.145482112196405],
            [25.668673217147983, 54.136527054456892],
            [25.766109153797316, 54.153882120306307],
            [25.785000119588886, 54.164509200095878],
            [25.807218198036054, 54.246654030739634],
            [25.712700169528318, 54.331518118419112],
            [25.69151809431645, 54.327353988909906],
            [25.650273263245765, 54.315827027992654],
            [25.611109155825289, 54.311382104726405],
            [25.575409121358803, 54.312636037444847],
            [25.551663357234361, 54.326909077488054],
            [25.55513614736779, 54.362636101682867],
            [25.655754022109505, 54.523118031984993],
            [25.72471814233495, 54.548464236706181],
            [25.748882163428874, 54.564436120889681],
            [25.762636196025909, 54.582491075656137],
            [25.757700260870081, 54.614991067119419],
            [25.732082147209269, 54.654154001073536],
            [25.727082174313125, 54.674854116851449],
            [25.73693610735171, 54.788118104225347],
            [25.79208215723969, 54.873045056178782],
            [25.875136089104217, 54.941100075183343],
            [25.91194504936405, 54.953609059879327],
            [25.936663281897467, 54.955545111880085],
            [26.076382232147637, 54.961936145431181],
            [26.160827224667088, 54.977209146525936],
            [26.214718168370041, 55.025682195639106],
            [26.249445063875214, 55.078673258214664],
            [26.253054143754525, 55.118736073296233],
            [26.280136072990047, 55.145691100517169],
            [26.481663345066636, 55.154991157392445],
            [26.512082277603781, 55.150273151719901],
            [26.641663315935006, 55.190827146328942],
            [26.783745124696935, 55.254853990293995],
            [26.806800219997541, 55.269718122150735],
            [26.813054125612354, 55.290273231003354],
            [26.775409154327491, 55.311245088083112],
            [26.645827110168057, 55.330273181810966],
            [26.625273174781825, 55.330273181810966],
            [26.560827068018682, 55.313882202467468],
            [26.528882293823273, 55.317773249570493],
            [26.50583608334, 55.323882148259869],
            [26.464854108753656, 55.338745106650137],
            [26.52666326877042, 55.446936030932122],
            [26.627909115699708, 55.59840020685867],
            [26.622636060397355, 55.652354014835666],
            [26.613209101507579, 55.674836123233646],
            [26.624854079621912, 55.687909042376745],
            [26.642218197926638, 55.698744999194091],
            [26.66527329322733, 55.705827036845008],
            [26.692054144500048, 55.701735997531628],
            [26.738473292053357, 55.687764203089429],
            [26.765827130228701, 55.687491120683333],
            [26.85666332393734, 55.71388221345704],
            [26.883263293738906, 55.740482183258692],
            [26.904991198487124, 55.775545025444117],
            [26.985827111127293, 55.832354044926646],
            [27.152773165176455, 55.846518120228168],
            [27.21166324358515, 55.825827056905652],
            [27.310827193397898, 55.807491140743977],
            [27.531109141520091, 55.788891194631518],
            [27.559718085880348, 55.789164277037614],
            [27.600245090761149, 55.792518044145638],
            [27.62000023077249, 55.818327097561323],
            [27.632045193307221, 55.84600011861049],
            [27.646382271090971, 55.919573112128788],
            [27.712491179810598, 55.956100105164992],
            [27.796109213760445, 56.001109081324174],
            [27.8977733176595, 56.070273193759775],
            [27.922491214916846, 56.105127158917426],
            [28.121663339832679, 56.167491200926108],
            [28.149645150195596, 56.160964212905114],
            [28.168009229552098, 56.150154072349423],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BLZ", Country: "Belize" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.112897184999895, 17.856131799000067],
            [-86.162847142, 17.784386841],
            [-86.281714753999893, 17.597219234000065],
            [-86.30746999599998, 17.569575399000129],
            [-86.432585581999831, 17.441844271999955],
            [-86.546069344999978, 17.325475753999953],
            [-86.573253560999888, 17.297599874000113],
            [-86.580088113999977, 17.290858825000029],
            [-86.630980565999977, 17.241586901],
            [-86.661227239999988, 17.20121203799998],
            [-86.677007392999826, 17.180221896999981],
            [-86.677494524999901, 17.179573491000085],
            [-86.677980356999967, 17.178926032999968],
            [-86.775784649999963, 17.048399389000039],
            [-86.785169835999852, 17.036926698999977],
            [-86.858161707999983, 16.957176387000047],
            [-86.86771407299986, 16.946750076999976],
            [-86.911634312000047, 16.900443047000024],
            [-86.912778864999979, 16.899313765000116],
            [-86.964352152, 16.849528813],
            [-87.007952093999961, 16.807876400000154],
            [-87.011094037999953, 16.80488585199997],
            [-87.012702676999822, 16.803354726000023],
            [-87.029082356, 16.788159511999979],
            [-87.065487395, 16.754387034000118],
            [-87.094353900000016, 16.727708825999954],
            [-87.108329702999981, 16.714706232000069],
            [-87.121198869999944, 16.70830200400006],
            [-87.145387317999962, 16.696409308000071],
            [-87.210589286000015, 16.618034690000101],
            [-87.363167471999986, 16.45123231200013],
            [-87.418364835499972, 16.38960209650007],
            [-87.467536795999962, 16.379032520000081],
            [-87.604281325000017, 16.348899699000086],
            [-87.60775917, 16.348123215000143],
            [-87.675980926999983, 16.294924131000059],
            [-87.762769576999972, 16.24710287200007],
            [-87.802158548999955, 16.22487489600006],
            [-87.809068353000043, 16.220255674000143],
            [-87.845339205999949, 16.196255243000124],
            [-87.867404551999897, 16.185896845999977],
            [-87.869732954, 16.184122062000128],
            [-87.905452240000017, 16.158380758000035],
            [-87.925849680999988, 16.143847328000149],
            [-87.932034111, 16.140048934],
            [-87.933758599999976, 16.139007540000094],
            [-87.979210802999972, 16.09886200900003],
            [-88.025177404, 16.069827303000139],
            [-88.028058464999987, 16.068053487],
            [-88.049714660999939, 16.046842977000082],
            [-88.063191699000015, 16.031897826000019],
            [-88.075829075999962, 16.017149771000035],
            [-88.082727296, 16.00925120700002],
            [-88.101867838999965, 15.986105890000076],
            [-88.114011514999959, 15.971535841000062],
            [-88.120946501, 15.963146559000037],
            [-88.180880473799874, 15.923009318400105],
            [-88.18196213399986, 15.923149062000064],
            [-88.194432076999931, 15.924883188000209],
            [-88.22501949899987, 15.929742479000097],
            [-88.236149873000045, 15.931510713000122],
            [-88.239322813999934, 15.932109034000163],
            [-88.243948965999891, 15.932981386000122],
            [-88.246654031999896, 15.933565423000132],
            [-88.254211937999855, 15.935385493999988],
            [-88.258299415999858, 15.936536159999989],
            [-88.260270430999867, 15.937121461000189],
            [-88.277478702999929, 15.942503334000079],
            [-88.278512805999867, 15.942823291000067],
            [-88.280557133, 15.943455815000092],
            [-88.286866818999982, 15.945581317000105],
            [-88.28888894699989, 15.946262497000149],
            [-88.300184685999966, 15.950621732000158],
            [-88.301601102999825, 15.95116835300017],
            [-88.305451217999888, 15.952701855000029],
            [-88.314360645999955, 15.956250482000101],
            [-88.31549296399993, 15.956734384],
            [-88.319239778999986, 15.958432434000073],
            [-88.322950241999933, 15.960247447000157],
            [-88.327092538999921, 15.962273698000118],
            [-88.334358437999896, 15.965927782000122],
            [-88.351173289, 15.975250441],
            [-88.354975679999853, 15.977338574000029],
            [-88.355527540999873, 15.97771008500014],
            [-88.357753517999924, 15.979208653000128],
            [-88.358683348666602, 15.979836707000132],
            [-88.359169420999933, 15.980172050000178],
            [-88.371545521999849, 15.989518434000061],
            [-88.372190889999871, 15.990029324000076],
            [-88.37455852699992, 15.991903606000065],
            [-88.375481876999856, 15.99266384100018],
            [-88.381795353999905, 15.998071958000097],
            [-88.382783670499919, 15.998931823000092],
            [-88.383761321999941, 15.999829768000225],
            [-88.389685395000015, 16.005318492000143],
            [-88.396613866499905, 16.012448423500032],
            [-88.39740852099996, 16.013333284000183],
            [-88.398842892999909, 16.015091697000102],
            [-88.404233314999942, 16.022155077000107],
            [-88.406191193000041, 16.023576252000097],
            [-88.408171235999902, 16.025013516000101],
            [-88.412765901999933, 16.028744542000069],
            [-88.416968598000039, 16.032157275000102],
            [-88.440038182999956, 16.052166167000067],
            [-88.44062465499988, 16.052714494000227],
            [-88.445930398999934, 16.057675162000166],
            [-88.457398866499972, 16.068734811500107],
            [-88.470022499999914, 16.067847334000177],
            [-88.485047966000025, 16.067017313000164],
            [-88.487777779999931, 16.066887591999986],
            [-88.494356220999975, 16.066574986000049],
            [-88.501453815999866, 16.066320530000056],
            [-88.511293025999862, 16.066405062000214],
            [-88.526088041999913, 16.067030555000187],
            [-88.531602517999858, 16.067263692000111],
            [-88.537536787999869, 16.067616612000094],
            [-88.546280301999985, 16.068211919000234],
            [-88.56245350699993, 16.069813262000125],
            [-88.573845489999883, 16.070941207000104],
            [-88.575089707999894, 16.071086181000098],
            [-88.575762797499948, 16.071176552500177],
            [-88.581909188999873, 16.071947787999974],
            [-88.594247396333287, 16.07371317700013],
            [-88.599863820999985, 16.07202358900004],
            [-88.608927637999955, 16.069308010000057],
            [-88.612195434999961, 16.068328956000144],
            [-88.616899474999883, 16.06706],
            [-88.627816348999971, 16.064049918000208],
            [-88.630431224999967, 16.063342374000101],
            [-88.634764360999839, 16.0621698960001],
            [-88.637896093999871, 16.061322498000052],
            [-88.675046070666639, 16.052049814666688],
            [-88.709186933999945, 16.034935898000228],
            [-88.711021788999886, 16.032272004000092],
            [-88.711457679999967, 16.031639165000215],
            [-88.712899722999907, 16.029501388000071],
            [-88.713527567, 16.028570632000068],
            [-88.716808774999976, 16.023785712000105],
            [-88.726869673999829, 16.009114105999956],
            [-88.745378526999957, 15.981299497333353],
            [-88.745753778999898, 15.98012464500006],
            [-88.74842898799983, 15.971749008000117],
            [-88.768523703, 15.965405694000182],
            [-88.770382075000015, 15.96485584800017],
            [-88.773642152999912, 15.963891273000101],
            [-88.774970748999976, 15.963646715000024],
            [-88.776346300999847, 15.963393513500094],
            [-88.780355119999882, 15.96275367900013],
            [-88.792059761999866, 15.960907056000096],
            [-88.802998779999911, 15.959176654000103],
            [-88.814860268999894, 15.956827532000077],
            [-88.819432197999902, 15.955825550000114],
            [-88.8246063039999, 15.954544434000084],
            [-88.827302396999869, 15.953909522000131],
            [-88.830389965999984, 15.953116142000127],
            [-88.839830908999886, 15.949800429000149],
            [-88.846592649999906, 15.947425667000104],
            [-88.847409463, 15.9457647360001],
            [-88.848513427999933, 15.943519901999977],
            [-88.850112785499874, 15.940267723000105],
            [-88.853466134428459, 15.93343253442869],
            [-88.853928453499918, 15.93232613300016],
            [-88.879671352999907, 15.917607338000025],
            [-88.89997276099993, 15.905999778000094],
            [-88.901195139999913, 15.905274946000148],
            [-88.901909679999989, 15.904851247],
            [-88.910572902236709, 15.893609068004807],
            [-88.980835882418717, 15.898053991276029],
            [-89.047226926000036, 15.906391135111775],
            [-89.169726932508866, 15.910136001823005],
            [-89.216172902152522, 15.889853975376681],
            [-89.216399884091174, 15.915273102655547],
            [-89.204181919074216, 16.104164152745867],
            [-89.184172975034102, 16.414444942447048],
            [-89.145008867613427, 17.013882114131732],
            [-89.14343591965914, 17.341800104543822],
            [-89.142226913941727, 17.719445076788091],
            [-89.141955005002018, 17.818882109007021],
            [-89.143008945510218, 17.951073104356212],
            [-89.121617825632569, 17.970936035642737],
            [-89.072054961465369, 17.994973154722246],
            [-89.022572898845141, 18.004509078353507],
            [-88.996999879823449, 17.987400105182061],
            [-88.983417843880147, 17.965818045549483],
            [-88.872035929050384, 17.907254023175327],
            [-88.841399905220584, 17.903891035973729],
            [-88.774081990783259, 17.988836092837957],
            [-88.728054948661537, 18.054718019619187],
            [-88.704455029652991, 18.110000023976895],
            [-88.681117967128614, 18.185553996497376],
            [-88.600017856899655, 18.235836027958911],
            [-88.589072935340738, 18.290735985168723],
            [-88.516955040217709, 18.430273215757708],
            [-88.506390992339988, 18.447500038488144],
            [-88.473790920952524, 18.483709022116827],
            [-88.405446055735524, 18.489900063457569],
            [-88.371399938408103, 18.482082094705888],
            [-88.343899919841135, 18.481391090606266],
            [-88.299499647608712, 18.482926831387218],
            [-88.260123137999926, 18.471475172000041],
            [-88.221679055999886, 18.462932043000094],
            [-88.167367811, 18.449354232000118],
            [-88.153334019999932, 18.44584578400007],
            [-88.10421102499987, 18.426623742000103],
            [-88.100387363999971, 18.425189869000135],
            [-88.07003850699985, 18.413809048000118],
            [-88.048667686999977, 18.401787962000171],
            [-88.035865988999831, 18.394587006000151],
            [-88.010236599999956, 18.368957618000067],
            [-88.005279102000031, 18.359868871000188],
            [-87.997421905999886, 18.345464012000079],
            [-87.989897820999857, 18.332923871000062],
            [-87.984607211999901, 18.324106187999959],
            [-87.971792517000011, 18.287797887000053],
            [-87.970979004999833, 18.278035739000117],
            [-87.96986155299993, 18.264626321000051],
            [-87.969656734999944, 18.262168499000083],
            [-87.968482418999855, 18.255122604000107],
            [-87.966647924999961, 18.24411564200004],
            [-87.963249387999866, 18.223724416000223],
            [-87.961287414999987, 18.204104687000026],
            [-87.961113622499937, 18.202366768000203],
            [-87.946163128999927, 18.202366592000189],
            [-87.914126487999908, 18.193823489000053],
            [-87.913472581999883, 18.19367817200002],
            [-87.896711140999912, 18.189953407000118],
            [-87.894904351999912, 18.189551898000204],
            [-87.867938097999911, 18.185857924000103],
            [-87.866544204999855, 18.185730133000092],
            [-87.851854150999969, 18.184383363000038],
            [-87.837311908999851, 18.183050145000024],
            [-87.830198752, 18.183049868000111],
            [-87.826299073999934, 18.183063279000052],
            [-87.818606373, 18.183530064000038],
            [-87.810765714999889, 18.183998185000135],
            [-87.802939262999985, 18.183996752000112],
            [-87.793056839999963, 18.183994943000229],
            [-87.792274708999912, 18.18412650000009],
            [-87.767929840999898, 18.188221360999975],
            [-87.753055194000012, 18.19072330900012],
            [-87.731061686999965, 18.193059759000093],
            [-87.702124917999981, 18.196133815999971],
            [-87.67687824399988, 18.197856692000059],
            [-87.668889922999909, 18.198215505],
            [-87.666941990999931, 18.198303001000127],
            [-87.665509120999872, 18.198341403000128],
            [-87.655699785999843, 18.198604302000106],
            [-87.645894996999857, 18.19886708],
            [-87.629533664999883, 18.198665139999989],
            [-87.593904887999969, 18.197217218000091],
            [-87.540263693000014, 18.195640374000092],
            [-87.477143655999924, 18.193784887999968],
            [-87.463906968999936, 18.194113993000101],
            [-87.450748534999832, 18.194441153],
            [-87.436654979999901, 18.192761471000111],
            [-87.428417442999915, 18.191779715000237],
            [-87.424818932999983, 18.191340470000043],
            [-87.419696227999879, 18.190715176000069],
            [-87.41875506, 18.189845620000071],
            [-87.412387148999954, 18.183962240000099],
            [-87.403133271999906, 18.175260760000086],
            [-87.346499973999897, 18.122008109000106],
            [-87.156886992999944, 18.074988624],
            [-86.99407079, 18.041068582000108],
            [-86.112897184999895, 17.856131799000067],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BMU", Country: "Bermuda" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-64.67130056, 35.722388384999974],
            [-63.959640560000025, 35.669259385000103],
            [-63.272355558999976, 35.512762384999959],
            [-62.632567557999892, 35.258234385],
            [-62.06126255800001, 34.914280384000037],
            [-62.044298557999895, 34.899617384000166],
            [-62.036133557999875, 34.894661384000173],
            [-61.551356056999879, 34.472425384000175],
            [-61.550560556999869, 34.471651384000083],
            [-61.166912556999932, 33.98561138400008],
            [-60.894686556999972, 33.451214382999964],
            [-60.894345556999895, 33.449969382999981],
            [-60.893797556999942, 33.448887382999985],
            [-60.739457557000037, 32.883062383000109],
            [-60.705492556999957, 32.303132048666768],
            [-60.705560556999984, 32.302438382000091],
            [-60.705557223666631, 32.301684382000062],
            [-60.790656557, 31.725496381000099],
            [-60.79384055700001, 31.716701381000163],
            [-60.795460556999927, 31.705638381000071],
            [-60.994853556999857, 31.150745381],
            [-60.995537556999977, 31.149596380999967],
            [-60.995937556999849, 31.148475381000125],
            [-61.301912556999866, 30.63087438],
            [-61.704062556999901, 30.16553238000013],
            [-62.189715557999961, 29.765725379999978],
            [-62.312405557999853, 29.694678380000013],
            [-62.31319455799985, 29.694022380000106],
            [-62.866993559, 29.370469379000056],
            [-62.867859558999982, 29.370133378999967],
            [-62.868936558999906, 29.369499379000089],
            [-62.872782058999945, 29.367992379000086],
            [-62.876699558999888, 29.366447379000149],
            [-62.885788559, 29.362905379000111],
            [-62.886540558999855, 29.362463379000033],
            [-62.887397559, 29.362130379000021],
            [-62.889471558999901, 29.360909379000063],
            [-62.892099558999917, 29.359887378999971],
            [-62.898295559000019, 29.35623937900003],
            [-62.901177058999963, 29.355110379000038],
            [-62.901867558999982, 29.354833379000141],
            [-62.911039559000017, 29.349430379000111],
            [-62.912000559000035, 29.349056378999975],
            [-62.913012559, 29.348461379000142],
            [-63.519615559000044, 29.110479379000068],
            [-63.522276558999948, 29.109884379000036],
            [-63.526408558999975, 29.108249379000128],
            [-64.168999559999975, 28.962622379],
            [-64.829891059999881, 28.913416379000083],
            [-64.83269956, 28.913212379],
            [-64.84928855999982, 28.914473379000057],
            [-64.853949559999904, 28.914113379000028],
            [-64.855743559999951, 28.914249379],
            [-64.858783559999949, 28.914014379000108],
            [-65.519671560999939, 28.962421379000077],
            [-66.162485562, 29.107263379000173],
            [-66.769422562000045, 29.344533379000026],
            [-66.772544561999894, 29.346367379000171],
            [-66.774378061999982, 29.347088379000056],
            [-66.776330061999943, 29.347867879000134],
            [-66.780174561999985, 29.349369379000038],
            [-67.334221562999943, 29.672430380000051],
            [-67.819336562999979, 30.072299380000103],
            [-67.824046562999968, 30.077792380000105],
            [-67.826423563, 30.07973838],
            [-68.228159563999867, 30.545111380000023],
            [-68.533788563999963, 31.062738381000102],
            [-68.534186564000038, 31.063855381000153],
            [-68.534871563999957, 31.06500838099997],
            [-68.535749063999901, 31.067261881],
            [-68.539158564000019, 31.076487881000062],
            [-68.539932564000026, 31.078529381000138],
            [-68.542466563999881, 31.082790381000095],
            [-68.741688563999986, 31.637687381000134],
            [-68.826633564000019, 32.213580382000018],
            [-68.826641064000029, 32.214407882],
            [-68.826648563999925, 32.215237381999984],
            [-68.826732230666607, 32.216129715333423],
            [-68.826762563999949, 32.216852882000083],
            [-68.792758563999939, 32.796655382000054],
            [-68.789251564000011, 32.809588382000058],
            [-68.788698564000015, 32.818987383000135],
            [-68.634387564, 33.384800383000098],
            [-68.362195563999904, 33.91930638400008],
            [-68.361341564000043, 33.920903384000141],
            [-67.97791656299998, 34.406912384000165],
            [-67.493665563000036, 34.828864384000113],
            [-66.923007561999839, 35.172890385000059],
            [-66.908713561999946, 35.178637385000044],
            [-66.875051561999982, 35.198759385000059],
            [-66.235634561999973, 35.453140385000026],
            [-66.222483562000036, 35.456174385000153],
            [-66.075919562000024, 35.513803385000031],
            [-66.074835561999947, 35.51405138500003],
            [-66.07283456199994, 35.514836385000066],
            [-65.385178561, 35.67031538500008],
            [-64.67339456, 35.722388384999974],
            [-64.672357559999881, 35.722313385000163],
            [-64.67130056, 35.722388384999974],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BOL", Country: "Bolivia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-58.158890878368254, -20.168055068317969],
            [-58.137226843722488, -20.116391028656992],
            [-58.133055002862335, -20.082226894132575],
            [-58.131254905331303, -19.986117980397651],
            [-58.150972997330484, -19.831108929970227],
            [-58.232226831664178, -19.782499926387061],
            [-58.268054941611553, -19.764164010225386],
            [-58.520563966428824, -19.638617852456093],
            [-58.792617913125184, -19.501508859223904],
            [-59.095835775914935, -19.348890998141059],
            [-59.156390835575166, -19.345555000667858],
            [-59.428335817529955, -19.329999864711411],
            [-59.573617836933266, -19.32166406198013],
            [-59.981808811366875, -19.296808869148407],
            [-60.018526911795902, -19.302073039633427],
            [-60.057499911823555, -19.314726863616642],
            [-60.424446055186507, -19.410835944989657],
            [-60.516399891867621, -19.434717831221889],
            [-60.613335764172177, -19.459163987177519],
            [-60.796390830341124, -19.490281970441359],
            [-61.357226823223982, -19.583055054341173],
            [-61.668890962229256, -19.633335912336321],
            [-61.742499830293212, -19.645000001189771],
            [-61.774445945593158, -19.724726820398615],
            [-61.847499931665368, -19.910835916817149],
            [-61.913072901494587, -20.080008874908117],
            [-62.101954899129311, -20.337499935348731],
            [-62.198835786148834, -20.471390948304801],
            [-62.269445944524421, -20.562226806737272],
            [-62.26778196910135, -20.621946023010466],
            [-62.265281982653278, -20.742226836828337],
            [-62.264164004404648, -20.810835899634228],
            [-62.261090863415831, -21.000517866110258],
            [-62.2588998339196, -21.056946011911407],
            [-62.377781875731614, -21.42639096825603],
            [-62.470554959631414, -21.71417305339088],
            [-62.474808943142904, -21.728064046286292],
            [-62.486117806938807, -21.764999908560426],
            [-62.524445903334367, -21.883608867966245],
            [-62.601108801647911, -22.120555045476124],
            [-62.634172895196485, -22.221946066968911],
            [-62.643772856568205, -22.238899974931144],
            [-62.657226817030704, -22.236254981557749],
            [-62.765008872074588, -22.154726891351487],
            [-62.79236388371632, -22.115417944643582],
            [-62.793063940271423, -22.093755083464217],
            [-62.792781973047909, -22.058890892384667],
            [-62.800699854085678, -22.017017921488346],
            [-62.817190913353329, -21.997381972140502],
            [-62.88611781792838, -21.997781956561113],
            [-63.130281987559073, -22.00055502541548],
            [-63.593335871190476, -22.003335805620694],
            [-63.685835872683981, -22.003055011863552],
            [-63.733063873896128, -22.002781929457271],
            [-63.941117798717642, -22.000835819172607],
            [-63.9930549207848, -22.094446087563938],
            [-64.05862688478561, -22.249726879293178],
            [-64.126681903790086, -22.410281899791158],
            [-64.172645913999787, -22.470417864291392],
            [-64.199390890726647, -22.494999974716919],
            [-64.223072952386616, -22.512917969185168],
            [-64.244172884947261, -22.549726929444887],
            [-64.253346039808122, -22.56778205184942],
            [-64.279872919413833, -22.645555049422597],
            [-64.286117940211028, -22.727499887855998],
            [-64.283890868531159, -22.754446030259615],
            [-64.301390941305897, -22.832499989228083],
            [-64.316390859994357, -22.860555057425017],
            [-64.338272991761556, -22.869790908921772],
            [-64.348072945343517, -22.843054984650294],
            [-64.347781925664407, -22.814164073066408],
            [-64.352508816154455, -22.767226923895308],
            [-64.363063979214758, -22.736945957484977],
            [-64.416399873287702, -22.683608889945461],
            [-64.453135910989658, -22.643963996557403],
            [-64.455281845846685, -22.615555044407472],
            [-64.44389083939933, -22.580000016866208],
            [-64.461945961803963, -22.514445990138313],
            [-64.526117812694338, -22.408891006774397],
            [-64.540081895785704, -22.286599877293341],
            [-64.546954888770671, -22.260564009576996],
            [-64.59264598421214, -22.213199886256973],
            [-64.68357287011338, -22.175435891946819],
            [-64.715699866884393, -22.182090955448615],
            [-64.96945494543121, -22.10763585043766],
            [-64.988345911222694, -22.09083583421814],
            [-65.190199909885962, -22.094726881321066],
            [-65.598890948664433, -22.099999936623519],
            [-65.65749989803993, -22.108890956622687],
            [-65.750008951989031, -22.110072972611775],
            [-65.812790915690954, -22.071390992263233],
            [-65.867508817963511, -22.005554998311638],
            [-65.926117934977071, -21.933335849797956],
            [-66.077517905525212, -21.831946001771655],
            [-66.194735804276178, -21.788335797618345],
            [-66.223008801956183, -21.780517828866678],
            [-66.236254891219431, -21.796963961133116],
            [-66.263063905686835, -21.902226918989612],
            [-66.281955039116411, -21.978054979744513],
            [-66.291263980808992, -22.035281920920553],
            [-66.306946018779854, -22.076946014789215],
            [-66.345381906450598, -22.116626950360995],
            [-66.402235852934268, -22.134164071147836],
            [-66.495563986463907, -22.161108872447073],
            [-66.559717900081552, -22.178335862815516],
            [-66.616390965094183, -22.191946061953701],
            [-66.688899959820475, -22.196108850358442],
            [-66.736682004833938, -22.227499916028492],
            [-66.771390963066182, -22.374999954656275],
            [-66.774735845356702, -22.426945961540852],
            [-66.850281938888088, -22.45305508709113],
            [-66.926390961038294, -22.478054951572005],
            [-67.022508927228614, -22.523890886300805],
            [-67.01806400396228, -22.573890950538825],
            [-67.012645941734519, -22.634172927792761],
            [-67.124454998351212, -22.719582006818143],
            [-67.183626876345528, -22.821664032410652],
            [-67.335008909620825, -22.852782015674407],
            [-67.499999968567835, -22.885435898880417],
            [-67.579181964068766, -22.901108884395882],
            [-67.793335902762351, -22.878054962561578],
            [-67.876399892910683, -22.828055065961649],
            [-67.884172934661223, -22.712781936390954],
            [-67.849726832913177, -22.554864030277344],
            [-67.890563968212319, -22.426390911910957],
            [-67.93278193824429, -22.300281993160908],
            [-67.922917946921899, -22.273890900387016],
            [-67.922646037982076, -22.239173057337339],
            [-67.944454911915159, -22.104726827113382],
            [-67.972781889051817, -22.060281953039521],
            [-67.996945910145854, -22.046946009774004],
            [-68.085155047753915, -21.961673052856511],
            [-68.087508853810107, -21.91306404927343],
            [-68.086117793155267, -21.880281922948456],
            [-68.087508853810107, -21.842781958589001],
            [-68.090563889888102, -21.819999945694505],
            [-68.105146054521128, -21.745281984199238],
            [-68.121946070740734, -21.698064041270996],
            [-68.138764024233069, -21.666946058007156],
            [-68.170008909511409, -21.627781950586666],
            [-68.186117921631194, -21.609726828182133],
            [-68.188608855623784, -21.296946052032794],
            [-68.229808927331391, -21.23320000182477],
            [-68.424035896998021, -20.938890928668926],
            [-68.453608927864224, -20.939445978298821],
            [-68.478899979662287, -20.941673049978704],
            [-68.505699941674123, -20.937646048405867],
            [-68.531817784403728, -20.926390996428523],
            [-68.552572885466702, -20.904717909327175],
            [-68.557690875559928, -20.894454939412469],
            [-68.560008807070489, -20.891391018517169],
            [-68.565563997234534, -20.870282033501127],
            [-68.564999895149413, -20.73381794390113],
            [-68.528890991444712, -20.687646062492078],
            [-68.509172899445616, -20.671673004842191],
            [-68.472163946975456, -20.650690921840564],
            [-68.467017793687518, -20.630626824877311],
            [-68.492508837695937, -20.604726911630962],
            [-68.549454985114835, -20.567499862039639],
            [-68.570845937354392, -20.558890976902163],
            [-68.599726790654501, -20.549999956902994],
            [-68.62944600191247, -20.538608950455739],
            [-68.661955045831263, -20.523054987965764],
            [-68.690981911885046, -20.504999865561231],
            [-68.724726783611288, -20.470835898674892],
            [-68.739181878592035, -20.450281963288674],
            [-68.752226802178427, -20.424026824984765],
            [-68.754799878822354, -20.373545974779276],
            [-68.73500886426541, -20.352226939269087],
            [-68.703608913778027, -20.338054984978541],
            [-68.721117871370069, -20.237781941734411],
            [-68.766454915220493, -20.136464010437564],
            [-68.784655044550334, -20.114446091838644],
            [-68.77722683429738, -20.089163924857985],
            [-68.650554986928512, -20.056663933394702],
            [-68.610635837667786, -20.051735877227998],
            [-68.577781962251493, -20.053546033042835],
            [-68.523346027202564, -19.916390939343032],
            [-68.566955057889572, -19.833890883641828],
            [-68.626108830972896, -19.785835923860162],
            [-68.689726805699934, -19.742672978061563],
            [-68.687154902522479, -19.703682040761095],
            [-68.601963920618346, -19.607782004053846],
            [-68.572555007416668, -19.56653583187871],
            [-68.551390869477615, -19.539717932593973],
            [-68.44368190462967, -19.433645953440774],
            [-68.491672994308885, -19.369026844195602],
            [-68.551254915007803, -19.319308914819288],
            [-68.582645980677853, -19.303754952329299],
            [-68.608335843430041, -19.297226958479911],
            [-68.642235780365581, -19.282082032866043],
            [-68.658335907668061, -19.267363913763006],
            [-68.740846021653056, -19.177781988049077],
            [-68.782499889599734, -19.141945999112679],
            [-68.899873021198005, -19.064790915443297],
            [-68.965835917164014, -18.953054949022444],
            [-68.932781881899302, -18.882781910793511],
            [-68.951399932922754, -18.845835822597493],
            [-69.000563986135575, -18.743055081554346],
            [-69.024446040005955, -18.658608915568394],
            [-69.029172930495832, -18.611390972640251],
            [-69.032499875513622, -18.560000015385384],
            [-69.025554965799103, -18.507499964699292],
            [-69.023472901044556, -18.483472903903674],
            [-69.03014606945726, -18.459172928339839],
            [-69.039735804907082, -18.440972966648062],
            [-69.06028186130419, -18.414308959105966],
            [-69.074726898001018, -18.392226835128611],
            [-69.085554975829439, -18.285835840739665],
            [-69.090981922874619, -18.228126940129656],
            [-69.114446055051417, -18.20083579859029],
            [-69.135417912131061, -18.179591026742514],
            [-69.148899868150295, -18.150690895065054],
            [-69.139590926457686, -18.12514603923816],
            [-69.118681933651999, -18.108891014364573],
            [-69.082363985281745, -18.086946018323502],
            [-69.073199882876281, -18.068335846289159],
            [-69.071672867751516, -18.038890890903858],
            [-69.074935775028791, -18.037917919580522],
            [-69.133617814600115, -18.028890945111442],
            [-69.291254926956611, -17.979446098141338],
            [-69.30611788534685, -17.955699998740926],
            [-69.315290872569648, -17.926946047455331],
            [-69.321399938897116, -17.891946069543962],
            [-69.318690907783292, -17.82513593080256],
            [-69.327372883116595, -17.797499957765609],
            [-69.355563905783356, -17.745554956709427],
            [-69.376108956352169, -17.726945958141471],
            [-69.487226841231063, -17.632917935694707],
            [-69.49972677347165, -17.505282001819921],
            [-69.500563957963152, -17.426946075627924],
            [-69.501108949309071, -17.378890948208181],
            [-69.533617825589772, -17.347782017399737],
            [-69.590835881948408, -17.295281966713731],
            [-69.656190922294456, -17.287246068478893],
            [-69.618899834962662, -17.214726847830704],
            [-69.592790877050561, -17.184717957998174],
            [-69.531535760835112, -17.129863933617713],
            [-69.465381925114258, -17.097046100385171],
            [-69.406399813408314, -17.072226949737257],
            [-69.386945918998094, -17.052917894614083],
            [-69.320699882341899, -16.926254932062619],
            [-69.191955022673881, -16.778055004517711],
            [-69.107645984624213, -16.711599923195848],
            [-69.050708889660797, -16.683755073131223],
            [-69.012390851549242, -16.656981933209551],
            [-69.014326903550085, -16.629364065083422],
            [-69.032017916079667, -16.608608964020547],
            [-69.041399947968216, -16.547646040950696],
            [-69.034181955847487, -16.473054981469829],
            [-68.990846007566859, -16.419726966385809],
            [-68.83334602314676, -16.354026926904112],
            [-68.824245958481754, -16.326317863671235],
            [-68.838481950512829, -16.29839992341067],
            [-68.892899948288857, -16.283617934205282],
            [-68.932563952416132, -16.250555014123165],
            [-68.968199949142274, -16.20909996492027],
            [-69.044726892985807, -16.207781994461271],
            [-69.070699896428209, -16.227573009018315],
            [-69.140008848151211, -16.222226863520021],
            [-69.159726772512329, -16.211873033774637],
            [-69.213817876063587, -16.157217828138045],
            [-69.420981978041539, -15.621526874299462],
            [-69.332508810482665, -15.438890903290499],
            [-69.268335786125817, -15.326946059841973],
            [-69.195981856608711, -15.261663942053715],
            [-69.169872898696525, -15.263335796465782],
            [-69.143135800958646, -15.254445949933086],
            [-69.137090940009614, -15.227635929637373],
            [-69.170835811735941, -15.187781991583549],
            [-69.197781954139657, -15.158608945137971],
            [-69.295008846123324, -15.079446060376256],
            [-69.381390896471942, -14.964791012347433],
            [-69.364799924918287, -14.800626911970042],
            [-69.343481895236494, -14.779863931918058],
            [-69.310563982079771, -14.766946077984144],
            [-69.248899828988215, -14.687499884894351],
            [-69.248608809309218, -14.654164050044116],
            [-69.244735867117186, -14.616391003278395],
            [-69.235417872969009, -14.588955022451799],
            [-69.115572918117465, -14.484163965745466],
            [-68.984235871066204, -14.378126855309503],
            [-68.999799891839899, -14.336046013213817],
            [-69.002372968484025, -14.248755029282208],
            [-68.986399910834024, -14.231663993383592],
            [-68.958899892266999, -14.223890951633066],
            [-68.912781990314585, -14.216946041918632],
            [-68.857890917921907, -14.200135799777243],
            [-68.898608862557467, -14.031808910995025],
            [-68.937372985557346, -14.005417818221133],
            [-68.960972904566148, -13.987081902059558],
            [-68.975146032323181, -13.966255051905122],
            [-69.001681964384289, -13.839446076599955],
            [-69.014173011807372, -13.791108981956597],
            [-69.062790900207858, -13.707781967685762],
            [-69.023090853896775, -13.642046053658333],
            [-68.979172866257926, -13.566108860523769],
            [-68.96125503942784, -13.506808907048566],
            [-68.959454941896809, -13.452782008875815],
            [-68.960554982872537, -13.27250001156122],
            [-68.968335903612171, -13.166108849534112],
            [-68.968481916365789, -13.096663943341284],
            [-68.963345988999833, -13.026673039973957],
            [-68.97334593479215, -13.0019460902612],
            [-68.97334593479215, -12.865490885478621],
            [-68.922226886477176, -12.802782011972454],
            [-68.879445987826273, -12.757499953407077],
            [-68.815563983148422, -12.733054970917834],
            [-68.768346040220194, -12.644164049303711],
            [-68.743617917041036, -12.584726800254046],
            [-68.67390881325926, -12.501155034409805],
            [-68.677508840683146, -12.495835878639838],
            [-68.698626878154698, -12.454172958237663],
            [-68.736390872464739, -12.378055051270124],
            [-68.749454906790533, -12.350281950296804],
            [-68.835554989915721, -12.176946036163017],
            [-68.880281998851302, -12.086946021117484],
            [-68.925281922554973, -11.998335893260403],
            [-68.957226864388446, -11.944726916781178],
            [-68.987226869403656, -11.896108860742686],
            [-69.000835895075369, -11.875282010588251],
            [-69.039463895967259, -11.812782014109857],
            [-69.069455016165051, -11.764163958071364],
            [-69.180554963771215, -11.583608878350489],
            [-69.214735862101975, -11.528054965052903],
            [-69.248899828988215, -11.472499878288914],
            [-69.432508938958591, -11.173608921568515],
            [-69.500563957963152, -11.062499921506856],
            [-69.568435916201707, -10.951091016948936],
            [-69.567508877707809, -10.950555078058514],
            [-69.54055502395326, -10.952217880014999],
            [-69.486954932291354, -10.951108954221851],
            [-69.447645985583449, -10.947635828812267],
            [-69.412226912512267, -10.937781895773668],
            [-69.22278198625861, -10.950835871815556],
            [-69.076126843473276, -10.967226851159054],
            [-68.848890933181252, -11.016108937148331],
            [-68.761835816005629, -11.003317817590656],
            [-68.766708886887386, -11.137117970716133],
            [-68.700563935983837, -11.134726949009547],
            [-68.654726827788664, -11.124446041821926],
            [-68.620835775670429, -11.116390865209794],
            [-68.576454939337111, -11.103073026855185],
            [-68.55222688050273, -11.088891014280748],
            [-68.522572880451577, -11.059308930959148],
            [-68.40250882265039, -11.017781965026785],
            [-68.344035827744904, -11.006872918013499],
            [-68.313064024872858, -10.996663927555346],
            [-68.280908864907133, -10.979726951037534],
            [-68.230282001947927, -10.911664053044049],
            [-68.124599948931632, -10.759308880807339],
            [-68.1189639572207, -10.731945990176598],
            [-68.101963948790853, -10.705281982634588],
            [-68.082226913690334, -10.690281896308036],
            [-68.061108876218896, -10.67639090341271],
            [-68.017646025923767, -10.660281891292826],
            [-67.865846070955058, -10.656946061457802],
            [-67.836681909326785, -10.65930891996949],
            [-67.817508808673608, -10.665282031827061],
            [-67.787226836434883, -10.684163945163235],
            [-67.742372925484716, -10.712917896448729],
            [-67.703335887716719, -10.694726819574385],
            [-67.674999858124664, -10.619999973261685],
            [-67.609735845247286, -10.530135912686063],
            [-67.582508909086442, -10.504446049933861],
            [-67.529173015013413, -10.481108987409471],
            [-67.509172955790604, -10.47278190185753],
            [-67.449446028166477, -10.441663918593761],
            [-67.326672939251466, -10.358964038320195],
            [-67.323272904037822, -10.323473048519375],
            [-67.300290898933071, -10.316663925636774],
            [-67.225417872228604, -10.311946087602308],
            [-67.183208954652002, -10.323681925547135],
            [-67.076954920561377, -10.268754978608911],
            [-67.046399865916356, -10.24360893373624],
            [-66.970835835112155, -10.172226802075997],
            [-66.950008817319826, -10.151390899466151],
            [-66.925281867606969, -10.123190991982071],
            [-66.893755015105114, -10.09152684390574],
            [-66.760281923842541, -9.99083587896817],
            [-66.732226855645706, -9.97694605953923],
            [-66.69348184338503, -9.963191021113715],
            [-66.659235901485516, -9.948545992206718],
            [-66.642508807823788, -9.93013581238263],
            [-66.640254914053571, -9.918272904785312],
            [-66.630481950199851, -9.905763920089328],
            [-66.545699837533732, -9.887917842350546],
            [-66.47931784640781, -9.881808943661156],
            [-66.437090991558222, -9.888608846450268],
            [-66.383346060609085, -9.866664018047373],
            [-66.17499994264216, -9.797226823205392],
            [-66.0776459810061, -9.775555077208509],
            [-65.971955043172272, -9.779726918068746],
            [-65.843899846499397, -9.777781981250484],
            [-65.79223580683842, -9.774999859940706],
            [-65.745835770024399, -9.770554936674273],
            [-65.60598187113257, -9.834926947413265],
            [-65.569308865342748, -9.83513582444094],
            [-65.515699888863509, -9.773964024343414],
            [-65.512217878636704, -9.741390942684276],
            [-65.49250883909292, -9.719163979419591],
            [-65.442335772372871, -9.679191018340305],
            [-65.406881998222445, -9.686254951080201],
            [-65.376954915764998, -9.703335928694926],
            [-65.34194605303631, -9.762499927700219],
            [-65.301808806654407, -9.842154997817687],
            [-65.322717799460008, -9.903546068502948],
            [-65.336454900612608, -9.947082008994016],
            [-65.330417918652671, -10.043890979284072],
            [-65.313064026269842, -10.075281877315945],
            [-65.303754916938971, -10.10083578559842],
            [-65.288617870314113, -10.195417851846599],
            [-65.289172919944008, -10.217781943047569],
            [-65.311526785223123, -10.28875503783172],
            [-65.324726774018814, -10.306808986769767],
            [-65.347926876244827, -10.325554945635943],
            [-65.378199963666219, -10.354863946551433],
            [-65.388899966013753, -10.372499973795968],
            [-65.39580950409669, -10.393458922744799],
            [-65.391254945536531, -10.413755030788522],
            [-65.402363984760058, -10.442217962395176],
            [-65.441973003602385, -10.473481958412648],
            [-65.43806401922663, -10.618754925360463],
            [-65.390017944262382, -10.755835923035932],
            [-65.329190975662442, -10.851891024952394],
            [-65.315845979941514, -10.880835915992918],
            [-65.302655043601334, -10.985555055969698],
            [-65.343335772586585, -11.058054998240507],
            [-65.393617804048205, -11.149446073940979],
            [-65.401946063066646, -11.169726926921001],
            [-65.390708948362118, -11.274726860655008],
            [-65.348345971404711, -11.404726826508067],
            [-65.310281904959965, -11.494135917378046],
            [-65.289735848562856, -11.504999869752112],
            [-65.241535881855782, -11.505973008713525],
            [-65.222926883287926, -11.518755075815704],
            [-65.169455034744971, -11.614726861614244],
            [-65.188335774614671, -11.656390955482991],
            [-65.200835874493151, -11.685554949472987],
            [-65.209163965873387, -11.711672959840683],
            [-65.1917460357501, -11.752017909783959],
            [-65.149517839796232, -11.772154929304975],
            [-65.118381919259463, -11.698954930478948],
            [-65.06237286861824, -11.754864068834081],
            [-65.043335890073052, -11.809717925576464],
            [-65.035572906606319, -11.881673044139347],
            [-65.016955023221044, -11.969999863668249],
            [-65.00834597044539, -11.989446046727807],
            [-64.984308851366052, -12.011108907907087],
            [-64.896117818668785, -12.023335925379456],
            [-64.835846067336831, -12.023199970909559],
            [-64.809590929032851, -12.02860898068181],
            [-64.791117884934692, -12.041526834615723],
            [-64.763335899144039, -12.097781977935014],
            [-64.738617834248686, -12.150072983955255],
            [-64.617654901148512, -12.21097304275105],
            [-64.516535788595547, -12.242217928029405],
            [-64.496945939715232, -12.366255007946776],
            [-64.421399846183959, -12.441108923911955],
            [-64.399445965325555, -12.458090995068986],
            [-64.373063925007159, -12.468335860072699],
            [-64.293308942603517, -12.482072961225299],
            [-64.238890944827489, -12.479172990356616],
            [-64.176390948348995, -12.485554971452302],
            [-64.039517821872892, -12.517990925175141],
            [-64.020008942177441, -12.537081883176882],
            [-63.927963910389565, -12.547272936362219],
            [-63.898608809006532, -12.528608952509316],
            [-63.870563966731396, -12.493890941821562],
            [-63.832508952742245, -12.474163965004962],
            [-63.797499922375465, -12.45999988970344],
            [-63.67195493807256, -12.470555052763643],
            [-63.471390914311456, -12.570417885665293],
            [-63.464364029583777, -12.595917814490946],
            [-63.415555033790355, -12.653890912689832],
            [-63.394617877789955, -12.668363944943295],
            [-63.32487289946252, -12.70420898633509],
            [-63.247090849433846, -12.705835913746014],
            [-63.216108820639903, -12.680899919367505],
            [-63.163335855185693, -12.648890939793674],
            [-63.133345908454288, -12.635881890752913],
            [-63.067099871798177, -12.659872909364722],
            [-63.062299891112389, -12.697890875341855],
            [-63.049172992512609, -12.747500007614761],
            [-62.994172955378446, -12.839335827098893],
            [-62.973764026917621, -12.852899925769464],
            [-62.896663928533286, -12.900281986362216],
            [-62.843846036077935, -12.942335838729591],
            [-62.769726877747132, -13.005835796259646],
            [-62.68825494629229, -12.968599861851089],
            [-62.645863973778262, -13.007363984850969],
            [-62.645481926630396, -13.032364016969922],
            [-62.419217814195207, -13.132517869550497],
            [-62.384726785446134, -13.145835875543199],
            [-62.330835841743095, -13.147781985827848],
            [-62.268526785019645, -13.140890887931988],
            [-62.244590919330761, -13.126673000811905],
            [-62.213754903290521, -13.120972971360615],
            [-62.113672967439413, -13.153708829579898],
            [-62.109726767413378, -13.183335839902639],
            [-62.085555034968507, -13.27139091812991],
            [-62.002499929637395, -13.362217891745061],
            [-61.972946009510508, -13.375426932996163],
            [-61.944454914709127, -13.395835861456987],
            [-61.874181876480208, -13.453946087592072],
            [-61.868445972483102, -13.480882004073806],
            [-61.840308929272993, -13.538745969893029],
            [-61.810064005046286, -13.548663940672071],
            [-61.73018195299025, -13.536882002259716],
            [-61.616390911543164, -13.513054933674255],
            [-61.593526923635295, -13.507235881197488],
            [-61.577499886528869, -13.525972955246345],
            [-61.521135778467993, -13.550381895551766],
            [-61.492781979241187, -13.553335845877086],
            [-61.261645969390656, -13.523090921650478],
            [-61.038981884379268, -13.493117906363594],
            [-61.004173013860935, -13.540835913636599],
            [-60.957781861864248, -13.582781974728874],
            [-60.791599902110733, -13.679454990549104],
            [-60.770655034759301, -13.683299937184401],
            [-60.744290931713721, -13.682364013873197],
            [-60.67556401934894, -13.738608931270505],
            [-60.583335759156967, -13.768608936285716],
            [-60.476808810298223, -13.803472959727173],
            [-60.384499916197541, -13.987255072179579],
            [-60.400554948860702, -14.029726846240465],
            [-60.425835774736782, -14.067081971312589],
            [-60.464863927687446, -14.101808866817848],
            [-60.481999890587304, -14.167863963719014],
            [-60.450517797448498, -14.291126891056919],
            [-60.427472928069591, -14.324299949347107],
            [-60.34650893994862, -14.483417976360712],
            [-60.281108804963367, -14.6233359129929],
            [-60.27611788452262, -14.70666393309213],
            [-60.264454969135556, -14.913608932120425],
            [-60.259999819947325, -15.036663988258965],
            [-60.258899946609688, -15.093608962211391],
            [-60.292499811410721, -15.09444597906483],
            [-60.384726898136023, -15.092499868780166],
            [-60.571399929001785, -15.097500009314402],
            [-60.439726767632209, -15.241390968062788],
            [-60.227226913715924, -15.478608886874412],
            [-60.184308887128822, -15.980417841050269],
            [-60.160281993971182, -16.263054946363653],
            [-60.106673017491943, -16.265835894206944],
            [-60.005563963222784, -16.269999856078073],
            [-59.53278187510071, -16.28917295673125],
            [-59.489726888215216, -16.290835926325897],
            [-58.972781893242882, -16.310835817910629],
            [-58.541946033851076, -16.326664036273286],
            [-58.439173004158761, -16.329726951340277],
            [-58.41500881542683, -16.319999920316079],
            [-58.400699900837992, -16.305555051257329],
            [-58.388890972697226, -16.282917877650064],
            [-58.327508954467476, -16.279163958483437],
            [-58.325835758950944, -16.306663977050562],
            [-58.324446039400556, -16.396117827283618],
            [-58.346390867803535, -16.506108849096449],
            [-58.362090843047326, -16.525835825913049],
            [-58.397781992696395, -16.553055050722961],
            [-58.419446027342246, -16.573608986109178],
            [-58.431672877176538, -16.589999965452591],
            [-58.467917902988944, -16.644026863625342],
            [-58.475772919752728, -16.674635897727001],
            [-58.476390833656694, -16.721672959184104],
            [-58.463481864540114, -16.900690965496423],
            [-58.458754974050152, -16.924445949714254],
            [-58.445135890094548, -16.952781979306323],
            [-58.435146002586038, -16.970973056180767],
            [-58.424446000238504, -17.009726953258763],
            [-58.407364016795299, -17.112081893619376],
            [-58.406673012695563, -17.138335858456969],
            [-58.409445913911867, -17.190554947747756],
            [-58.403063932816167, -17.22639093668424],
            [-58.390417820183984, -17.259581932247158],
            [-58.371117817516307, -17.278890987370232],
            [-58.351108873476193, -17.287226957739591],
            [-58.321946052952484, -17.29291793473547],
            [-58.294446034385345, -17.299726889980079],
            [-58.24999981920692, -17.326117982753971],
            [-58.120563955439167, -17.412782000326303],
            [-58.108890981768411, -17.438890958238503],
            [-58.091663991399884, -17.455555019988196],
            [-58.016672947498421, -17.497635862083882],
            [-57.983555042131343, -17.500690898161778],
            [-57.901672900333836, -17.461946053539194],
            [-57.880835824257503, -17.476945972227753],
            [-57.788063913824175, -17.553608870541296],
            [-57.745508823645451, -17.593890956210515],
            [-57.765835777093116, -17.612781922002085],
            [-57.779481850776932, -17.637746079575408],
            [-57.759173002240374, -17.686946007333944],
            [-57.729454964448792, -17.720555092228551],
            [-57.71728192643306, -17.72758197695623],
            [-57.718172922743122, -17.738581883800208],
            [-57.721117820613046, -17.774999912094813],
            [-57.718890916571155, -17.844999867917466],
            [-57.581672958597295, -18.096108947262479],
            [-57.521117898937263, -18.203891002306349],
            [-57.55236395768199, -18.246808861255474],
            [-57.563335868969148, -18.274726969154116],
            [-57.626945964707261, -18.468055063521675],
            [-57.770281873825837, -18.90610891503411],
            [-57.723035767702982, -18.91360887437834],
            [-57.716664012529179, -18.934445950454659],
            [-57.704726841269547, -19.0436088402314],
            [-57.776672907377019, -19.047499887334411],
            [-57.78722689697085, -19.06805499618703],
            [-57.821399916312487, -19.137217935156329],
            [-57.854999948751697, -19.206390932409434],
            [-57.960554932115599, -19.421108973188254],
            [-58.087226779484382, -19.677226910246759],
            [-58.121117831602504, -19.741390882148309],
            [-58.103335791604167, -19.760282015577872],
            [-57.851872995944234, -19.975508837880795],
            [-57.900835883480397, -20.029026954529442],
            [-58.109726825155263, -20.149172987343803],
            [-58.158890878368254, -20.168055068317969],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ISO_A3: "BES",
        Country: "Bonaire, Saint Eustatius and Saba",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-67.989722562999987, 11.666666363000104],
            [-68.390654905999895, 11.666666363000104],
            [-68.424082999999825, 11.829444000000137],
            [-68.429888999999889, 11.857722],
            [-68.436722, 11.912083000000166],
            [-68.441331, 11.933044],
            [-68.44199, 11.93605],
            [-68.45117, 11.977778000000114],
            [-68.45372, 11.987861],
            [-68.46036, 12.024194000000136],
            [-68.486278, 12.073917],
            [-68.52017, 12.106056],
            [-68.60061, 12.18511100000012],
            [-68.60828, 12.189556],
            [-68.61236, 12.199667000000147],
            [-68.620349, 12.215508],
            [-68.623946333333208, 12.222657666666748],
            [-68.6265, 12.232],
            [-68.65283, 12.322722],
            [-68.670028, 12.369444000000101],
            [-68.68375, 12.415000000000191],
            [-68.710332999999878, 12.522861],
            [-68.72147, 12.5515],
            [-68.72112, 12.555336000000125],
            [-68.72084, 12.558489],
            [-68.714332999999897, 12.629917],
            [-68.708694, 12.696444000000156],
            [-68.670028, 13.252111],
            [-68.591499999999883, 14.373472000000106],
            [-68.591410218999897, 14.374787796000078],
            [-67.989722562999987, 12.450000364000118],
            [-67.989722562999987, 11.666666363000104],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BRA", Country: "Brazil" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-28.854863526999907, -17.136338663999879],
              [-28.249572525999895, -17.18512966399993],
              [-27.661518525999838, -17.331710664000013],
              [-27.107647524999777, -17.571853664],
              [-26.604059524999911, -17.898617664999904],
              [-26.165593523999746, -18.3025276649999],
              [-26.164218523999807, -18.304326664999962],
              [-26.15855452399984, -18.309522664999918],
              [-25.798366523999789, -18.778799665999941],
              [-25.527668523999864, -19.299713666],
              [-25.355276523999862, -19.856891666999786],
              [-25.353130523999937, -19.875219666999953],
              [-25.352875523999899, -19.876038666999932],
              [-25.284903523999901, -20.452888666999968],
              [-25.284991523999906, -20.454162666999878],
              [-25.28484552399982, -20.455387666999968],
              [-25.324526523999964, -21.034568667999963],
              [-25.47188752399984, -21.598468667999896],
              [-25.723633523999752, -22.12973666900001],
              [-26.073084523999796, -22.611825668999757],
              [-26.510241524999799, -23.029537670000025],
              [-27.021977524999784, -23.36955667],
              [-27.592509525999844, -23.620964169999979],
              [-27.59342252599987, -23.621343669999931],
              [-28.20437752599986, -23.77591867000001],
              [-28.836113526999895, -23.82864767],
              [-28.893103526999937, -23.824158670000031],
              [-29.302847526999926, -23.856962669999973],
              [-29.304244526999867, -23.856848670000034],
              [-29.306939526999827, -23.857061669999894],
              [-29.313022526999816, -23.856566669999893],
              [-29.32971502699985, -23.857840669999973],
              [-29.330492526999876, -23.85786167],
              [-29.962372527999946, -23.805198669999982],
              [-30.57347952799995, -23.650684670000032],
              [-31.144055528999843, -23.399365669999952],
              [-31.656171028999779, -23.05917867],
              [-31.660946528999943, -23.05592767],
              [-32.09821853, -22.6382456689999],
              [-32.100889529999961, -22.634575669000014],
              [-32.111146529999814, -22.624737669000012],
              [-32.112077529999738, -22.623485168999935],
              [-32.461617529999955, -22.141331668999896],
              [-32.713377529999775, -21.610062667999955],
              [-32.860743530999798, -21.046160668000027],
              [-32.900420530999895, -20.466979667000032],
              [-32.899798530999931, -20.461738666999906],
              [-32.900172530999896, -20.456287666999913],
              [-32.899829531000023, -20.453396667],
              [-32.900035531000015, -20.450390666999965],
              [-32.899791530999892, -20.448332666999818],
              [-32.899938530999805, -20.446193666999989],
              [-32.831973530999846, -19.869342666999955],
              [-32.659554530000037, -19.31216866599982],
              [-32.388823529999854, -18.791259665999974],
              [-32.02859852999984, -18.321988664999935],
              [-31.590054528999758, -17.918104664999817],
              [-31.585134528999902, -17.914912664999974],
              [-31.081438528999854, -17.588117663999924],
              [-30.527439027999947, -17.34797266399994],
              [-30.526548527999978, -17.34759766399992],
              [-29.938437527999838, -17.201027663999923],
              [-29.332849026999895, -17.15226816400002],
              [-29.332104526999842, -17.152246664000018],
              [-29.189552526999876, -17.164309663999973],
              [-28.85680752699983, -17.136338663999879],
              [-28.855818526999883, -17.136419664000016],
              [-28.854863526999907, -17.136338663999879],
            ],
          ],
          [
            [
              [-45.056552541999849, 2.240010143000291],
              [-44.748499541999763, 2.095168143000251],
              [-44.706482541999947, 2.083770143000208],
              [-44.649048541999946, 2.056774143000155],
              [-44.557620540999778, 2.031983143000275],
              [-44.362279540999907, 2.014378143000215],
              [-44.359812540999883, 2.013709143000312],
              [-44.358284540999762, 2.013572142999976],
              [-44.349330540999858, 2.01114614300036],
              [-44.348279540999897, 2.011051143000316],
              [-44.346140468999749, 2.010472135500208],
              [-44.334314540999856, 2.009407142999976],
              [-44.332862540999884, 2.009014143000343],
              [-44.328306764999979, 2.008604162999973],
              [-43.994268944999817, 1.918185558000289],
              [-43.767912769999725, 1.856894410666996],
              [-43.767285253999887, 1.856697498000244],
              [-43.765025540999886, 1.856086143000198],
              [-43.763256540999976, 1.855304643000352],
              [-43.761338257499801, 1.85451470150025],
              [-43.757990006999876, 1.85300501800026],
              [-43.757032424999863, 1.852746061000232],
              [-43.755860755666447, 1.852196222333532],
              [-43.755019540999854, 1.851968143000136],
              [-43.753194040999972, 1.851143143000286],
              [-43.227134539999781, 1.604352143000312],
              [-43.222602876499764, 1.602170222000325],
              [-43.178528436999954, 1.571232602000237],
              [-42.747317539999869, 1.268033143000309],
              [-42.746484539999869, 1.267197143000033],
              [-42.745302539999784, 1.266367143000309],
              [-42.742884539999864, 1.263940143000184],
              [-42.733307539999942, 1.257209143000352],
              [-42.73044353999984, 1.254373643000235],
              [-42.320246538999868, 0.842916142000206],
              [-42.319188538999981, 0.841401142000279],
              [-42.318230538999956, 0.840440141999977],
              [-42.308420538999883, 0.826392142000088],
              [-42.101776538999957, 0.770839142000113],
              [-42.100099538999928, 0.770121142000221],
              [-42.097981538999932, 0.769236142000352],
              [-42.096106038999835, 0.768464642000311],
              [-42.093870456999866, 0.767534041800218],
              [-42.087767568999936, 0.765875341500248],
              [-42.086394924499814, 0.765245316000232],
              [-42.085667538999985, 0.765021642000363],
              [-42.0827915389998, 0.764221142000224],
              [-42.078644538999868, 0.762282142000174],
              [-42.051821538999889, 0.755073642000241],
              [-42.050544546999845, 0.754728801000226],
              [-42.048688247666576, 0.754198704666976],
              [-42.042529642999966, 0.752428925000174],
              [-42.041160646999856, 0.751831817000323],
              [-41.917971538999836, 0.694312142000229],
              [-41.909050538999907, 0.691916142000252],
              [-41.907723499999975, 0.691300323333621],
              [-41.907039538999982, 0.691108142000189],
              [-41.905154038999797, 0.690246642000318],
              [-41.903215900999783, 0.689426894000349],
              [-41.901700538999819, 0.688763142000141],
              [-41.900898038999856, 0.688520642000299],
              [-41.898049538999885, 0.68772814200031],
              [-41.874546538999823, 0.676746142000241],
              [-41.872084538999786, 0.676085142000261],
              [-41.870274538999865, 0.675272642000209],
              [-41.868450038999754, 0.674525142000334],
              [-41.86640803899985, 0.673700642000142],
              [-41.851092538999865, 0.666603142000156],
              [-41.849412003499822, 0.666152597500343],
              [-41.848258137499897, 0.665667833500208],
              [-41.846988538999881, 0.66524664200017],
              [-41.844140538999795, 0.664454142000352],
              [-41.842764224999826, 0.663811749500269],
              [-41.841438971, 0.663416595000228],
              [-41.839143538999792, 0.662800142000265],
              [-41.837795538999899, 0.662170142000207],
              [-41.836969039, 0.661915642000281],
              [-41.83414653899996, 0.661126142000342],
              [-41.801910538999834, 0.646065142000282],
              [-41.796068038999863, 0.644469642000274],
              [-41.79322053899989, 0.64367814200034],
              [-41.791695538999761, 0.642965142000321],
              [-41.78821994399982, 0.642032461000326],
              [-41.786256872333297, 0.641156142],
              [-41.783226538999884, 0.640319142000237],
              [-41.781977539000025, 0.639736142000288],
              [-41.781215538999845, 0.63953114200018],
              [-41.779530038999837, 0.638802642000314],
              [-41.7772889862498, 0.637867242000368],
              [-41.774433538999858, 0.636552142000255],
              [-41.77323153899988, 0.636230142000272],
              [-41.740754538999909, 0.621059142000206],
              [-41.739312181999765, 0.620672339000237],
              [-41.738278911999885, 0.620395113000257],
              [-41.737342773999956, 0.619957846000204],
              [-41.73333753899982, 0.618086142000266],
              [-41.255491537999887, 0.576436142000261],
              [-40.989311538000038, 0.505209142000297],
              [-40.507087537999837, 0.548130142000332],
              [-40.50424953799984, 0.547886142000209],
              [-40.501088537999976, 0.548168142000236],
              [-39.923044536999726, 0.498579142000267],
              [-39.630803536999906, 0.473640142000249],
              [-39.629970536999906, 0.473418142000355],
              [-39.622825536999812, 0.472810142000299],
              [-39.575428536999823, 0.460213142000214],
              [-39.527061536999838, 0.456102142000248],
              [-39.509178536999855, 0.451351142000249],
              [-39.44822453699993, 0.446176142000354],
              [-38.887656535999895, 0.297505141999977],
              [-38.88656753600003, 0.297000142000115],
              [-38.850718535999761, 0.287507142000152],
              [-38.849011535999921, 0.286770142000307],
              [-38.847224535999771, 0.285996142000215],
              [-38.827789535999841, 0.280851142000301],
              [-38.782209888999773, 0.259739709000144],
              [-38.776422585499972, 0.257059156500361],
              [-38.771922413999903, 0.255868085000259],
              [-38.770244035999838, 0.25515614200026],
              [-38.763294535999933, 0.252003142000319],
              [-38.75895153599987, 0.250854142000264],
              [-38.232677535999869, 0.007379141000285],
              [-38.230146535999836, 0.005618141000241],
              [-38.228688535999936, 0.004944141000237],
              [-38.226536535999799, 0.003446141000097],
              [-38.220709535999845, 0.000753141000246],
              [-38.218874535999817, -0.000522858999773],
              [-38.212699535999946, -0.003376859000014],
              [-38.208343918999901, -0.006407117749973],
              [-38.207736535999913, -0.006710858999895],
              [-38.195805535999853, -0.014996858999893],
              [-38.152785004999885, -0.044910613999889],
              [-38.147184686999879, -0.048806775666606],
              [-37.968225534999817, -0.131454858999916],
              [-37.96555353499997, -0.133311859],
              [-37.964236535, -0.133919858999946],
              [-37.851839534999897, -0.212043858999891],
              [-37.68571542833314, -0.288706763333323],
              [-37.641922151999836, -0.308926391999989],
              [-37.594079534999764, -0.330981859000019],
              [-37.593106538999905, -0.331657208999843],
              [-37.591096386999766, -0.33263797699999],
              [-37.562585386000023, -0.352423082999849],
              [-37.348161534999861, -0.501221858999941],
              [-37.334776534999776, -0.507383858999901],
              [-37.327953901749936, -0.512103096749797],
              [-37.323788534999949, -0.51402085899997],
              [-37.052148534999958, -0.702304858999923],
              [-36.821154533999959, -0.808431858999938],
              [-36.819844731999865, -0.809329868333293],
              [-36.818171533999987, -0.810098859],
              [-36.341146533999904, -1.139892860000032],
              [-36.066263533999916, -1.411308859999792],
              [-35.983862533999854, -1.328131859999928],
              [-35.509015532999854, -0.991811859999757],
              [-34.983086532999891, -0.742477859000019],
              [-34.422028531999899, -0.587603858999955],
              [-33.842836531999808, -0.531784858999913],
              [-33.842050031999918, -0.531807858999969],
              [-33.262019530999908, -0.576605858999756],
              [-33.196941472999981, -0.593280743999912],
              [-33.18620446899979, -0.596031977],
              [-33.15375803299986, -0.587031263999847],
              [-32.999375530999885, -0.544205858999931],
              [-32.997533530999874, -0.544025858999873],
              [-32.989380530999881, -0.541763859000014],
              [-32.409694529999854, -0.485040859000037],
              [-32.408926529999974, -0.485062858999925],
              [-32.364375529999847, -0.488390858999935],
              [-32.44234753, -0.308207858999879],
              [-32.472842585999842, -0.223505245999888],
              [-32.513282187999891, -0.111179929999707],
              [-32.57170503399982, 0.089912825000283],
              [-32.597074070000019, 0.203407367000352],
              [-32.617385529999808, 0.294277142000055],
              [-32.639623830999938, 0.434682084000201],
              [-32.650144436999824, 0.501106573000243],
              [-32.669851428999834, 0.709584773000302],
              [-32.676429078999831, 0.918889000000263],
              [-32.669851428999834, 1.128193227],
              [-32.650144436999824, 1.336671427000283],
              [-32.645533442999977, 1.365784095000322],
              [-32.617385529999808, 1.543501143000185],
              [-32.600865972999884, 1.617406634000133],
              [-32.57170503399982, 1.747865175000186],
              [-32.513282187999891, 1.948957930000176],
              [-32.480811067999866, 2.039149935000296],
              [-32.44234753, 2.145985143000019],
              [-32.392950176999875, 2.260136894000198],
              [-32.359182137999767, 2.338170228000195],
              [-32.26411309599996, 2.524753728],
              [-32.21251432400004, 2.612002487000154],
              [-32.157515529999898, 2.704999144],
              [-32.03981153, 2.878196144000356],
              [-32.001178740999876, 2.928001550000317],
              [-31.911464100999979, 3.043660967000335],
              [-31.772980398000016, 3.200739894000094],
              [-31.716473376999886, 3.257246915000223],
              [-31.624906528999929, 3.348813145000292],
              [-31.467827528999806, 3.487297145000298],
              [-31.30236352899999, 3.615644145000203],
              [-31.172726112999896, 3.70374597100033],
              [-31.129166612999825, 3.733348977000276],
              [-31.015390633999857, 3.800635904000217],
              [-30.948920528999793, 3.83994614500034],
              [-30.902728784999965, 3.863482067000291],
              [-30.762337227999865, 3.935015138000267],
              [-30.570152473999826, 4.018180908000147],
              [-30.456179417999977, 4.059213732000273],
              [-30.373124527999835, 4.089115145000278],
              [-30.28717026299995, 4.114087327000391],
              [-30.17203217499997, 4.14753803400032],
              [-29.967667829999925, 4.193218877000334],
              [-29.87579369399981, 4.207770311000104],
              [-29.760838527999965, 4.2259771450004],
              [-29.552360527999895, 4.245684145000268],
              [-29.376158023999835, 4.251221806000217],
              [-29.343055999999763, 4.252262079000388],
              [-29.295441760000017, 4.250765741000407],
              [-29.133751526999845, 4.245684145000268],
              [-28.925273526999831, 4.2259771450004],
              [-28.806704275999834, 4.207197905000157],
              [-28.718444169999771, 4.193218877000334],
              [-28.514079824999897, 4.14753803400032],
              [-28.407495009999764, 4.116572283000323],
              [-28.312987525999858, 4.089115145000278],
              [-28.221215995999955, 4.056075568000324],
              [-28.11595952599987, 4.018180908000147],
              [-27.923774771999831, 3.935015138000267],
              [-27.807771457999877, 3.875908497000125],
              [-27.737191525999833, 3.83994614500034],
              [-27.639606439999852, 3.782234588000392],
              [-27.556945386999871, 3.733348977000276],
              [-27.527995502999772, 3.713674656000308],
              [-27.383748525999977, 3.615644145000203],
              [-27.218284524999774, 3.487297145000298],
              [-27.061205524999764, 3.348813145000292],
              [-26.961545549999869, 3.249153843000272],
              [-26.913131601999851, 3.200739894000094],
              [-26.774647898999888, 3.043660967000335],
              [-26.693590548999794, 2.939162461000137],
              [-26.646300524999958, 2.878196144000356],
              [-26.576567560999763, 2.775587588000235],
              [-26.52859602299992, 2.704999613000325],
              [-26.421998903999736, 2.524753728],
              [-26.326929861999929, 2.338170228000195],
              [-26.298350997999819, 2.2721283690002],
              [-26.243764523999801, 2.145985143000019],
              [-26.201064925999901, 2.02738397800033],
              [-26.172829811999804, 1.948957930000176],
              [-26.114406965999876, 1.747865175000186],
              [-26.088504816999887, 1.631985622000229],
              [-26.068726523999715, 1.543501143000185],
              [-26.03596754799986, 1.336671284000147],
              [-26.016260570999918, 1.128193227],
              [-26.009682920999865, 0.918889000000263],
              [-26.016260564999811, 0.709584772000028],
              [-26.035967562999872, 0.501106573000243],
              [-26.042939869999884, 0.457085161000293],
              [-26.068726523999715, 0.294277142000055],
              [-26.092296719999922, 0.18882837700022],
              [-26.114406965999876, 0.089912825000283],
              [-26.172829811999804, -0.111179929999707],
              [-26.206286612999747, -0.204109768999956],
              [-26.243764523999801, -0.308207858999879],
              [-26.293551056999945, -0.423258359999977],
              [-26.326929861999929, -0.500392227999953],
              [-26.421998903999736, -0.686975728],
              [-26.52859602299992, -0.867221612999913],
              [-26.646300529999962, -1.040418535999891],
              [-26.774647898999888, -1.205882966999923],
              [-26.913131601999851, -1.362961893999966],
              [-27.061205105999818, -1.511035397999763],
              [-27.080068570999742, -1.52766578],
              [-27.218284524999774, -1.649518859999887],
              [-27.324993127999903, -1.732291158999942],
              [-27.383748463999893, -1.77786647],
              [-27.491672413999822, -1.851211509999757],
              [-27.556945525999879, -1.895570860000021],
              [-27.737191525999833, -2.002167859999929],
              [-27.923774525999818, -2.097236861],
              [-28.11595952599987, -2.180402860999948],
              [-28.312987525999858, -2.2513368609998],
              [-28.514079526999865, -2.309759860999918],
              [-28.718444526999747, -2.35544086099992],
              [-28.74841502799984, -2.360187794999945],
              [-28.925273572999913, -2.388199437000026],
              [-29.133751772999915, -2.407906428999809],
              [-29.276881233999973, -2.412404452999908],
              [-29.343055526999819, -2.414483860999837],
              [-29.34728849933316, -2.414350998999851],
              [-29.325457980999943, -2.459123793999936],
              [-29.244599527, -2.624958860999925],
              [-29.085722526999916, -3.17976086199991],
              [-29.025964306999811, -3.75393300799999],
              [-29.026043864999906, -3.755032349999908],
              [-29.025929994999899, -3.756115555999827],
              [-29.057747644999949, -4.199850535],
              [-29.067206526999826, -4.331763863],
              [-29.208547526999894, -4.891890862999958],
              [-29.213689355999804, -4.903306267999881],
              [-29.215530559, -4.907393597999942],
              [-29.219735563000029, -4.924106057000017],
              [-29.222308526999825, -4.934331862999954],
              [-29.280253849999781, -5.063136131999897],
              [-29.459761417999914, -5.462113383499855],
              [-29.460669460999895, -5.464091621999785],
              [-29.787310636000029, -5.943334467999875],
              [-29.788684944999943, -5.944742028999826],
              [-29.794278772999974, -5.952953153999943],
              [-30.200552641999877, -6.369104818999915],
              [-30.674339908999855, -6.709365006999803],
              [-30.681086471999777, -6.712616229999867],
              [-30.692343348, -6.720698633999973],
              [-31.219549681499927, -6.974573346999875],
              [-31.226342235999766, -6.977801704999763],
              [-31.425476459333197, -7.034124724999856],
              [-31.416849528999819, -7.112332864999814],
              [-31.416863028999956, -7.113004364999938],
              [-31.419551166999895, -7.153460984999981],
              [-31.427562894999937, -7.272641452999835],
              [-31.426757088333204, -7.280167504000019],
              [-31.426827223999908, -7.281512300499855],
              [-31.42673251899987, -7.28289486],
              [-31.426784069999883, -7.283664289999876],
              [-31.427432737, -7.29334405499992],
              [-31.426618806999898, -7.300984199599839],
              [-31.426672528999973, -7.302131864999808],
              [-31.426606748499921, -7.30272051399993],
              [-31.427594934000012, -7.31774040799985],
              [-31.427512296999907, -7.318485989499834],
              [-31.442530528999839, -7.544030865999787],
              [-31.439756528999965, -7.568896865999818],
              [-31.439846783999883, -7.570260371999836],
              [-31.440018026999837, -7.572839928999826],
              [-31.439708698, -7.575611691999939],
              [-31.446893418999821, -7.684266677999858],
              [-31.454877528999816, -7.805007865999812],
              [-31.448701528999777, -7.86002586599983],
              [-31.449152528999946, -7.866893866],
              [-31.448914775999924, -7.869008411999914],
              [-31.4486158, -7.871669173999805],
              [-31.486173743999871, -8.448741796000036],
              [-31.487065803999883, -8.452569502499898],
              [-31.487141528999928, -8.453737865999869],
              [-31.491010992333315, -8.469451963999859],
              [-31.503812767999875, -8.52132823799991],
              [-31.504105622999816, -8.525881220999963],
              [-31.504498528999818, -8.531983866999781],
              [-31.504770816000018, -8.533087267999861],
              [-31.505465203333245, -8.536027943333238],
              [-31.643951528999935, -9.097729866999885],
              [-31.649821269333358, -9.110953194666592],
              [-31.663669397999854, -9.142094023000027],
              [-31.669821528999819, -9.155895866999799],
              [-31.731609813999853, -9.40712551599978],
              [-31.740999441999861, -9.44530333099982],
              [-31.74191924549973, -9.447491414999945],
              [-31.759702300999948, -9.487540064999962],
              [-31.764570529999958, -9.498469866999869],
              [-31.765327282999976, -9.501550638999788],
              [-31.767484994999961, -9.510332549999703],
              [-31.779577737000011, -9.537484500999881],
              [-31.806415570999945, -9.646518491333211],
              [-31.80719905099977, -9.649465609999766],
              [-31.808231999999862, -9.653672725000035],
              [-31.808728371999905, -9.654787225499831],
              [-31.809214529999906, -9.656584867999925],
              [-31.817815529999848, -9.675901867999869],
              [-31.82075543499991, -9.68787867799989],
              [-31.823825621999845, -9.700385262999873],
              [-31.844886364999809, -9.747690496499786],
              [-31.848315437999759, -9.761662494999825],
              [-31.851916805999849, -9.769752316999757],
              [-31.874205529999813, -9.81981886799997],
              [-31.875472798999851, -9.824985488999914],
              [-31.87590681499978, -9.826565507666487],
              [-31.876628915999845, -9.829397998999866],
              [-31.877719741999783, -9.833843872999921],
              [-31.879911344999925, -9.838767220999785],
              [-31.883112529999948, -9.845957867999857],
              [-31.883615529999815, -9.848008867999738],
              [-31.884793461999834, -9.850655292999932],
              [-31.893754868999849, -9.870787165999957],
              [-31.897496631999843, -9.88604039],
              [-31.899220529999923, -9.893066867999892],
              [-31.909849482999846, -9.91694770499987],
              [-31.939419783999881, -9.983384075],
              [-31.941145127999903, -9.990419865000035],
              [-31.941410529999757, -9.991501867999943],
              [-31.945013346999872, -9.999597263999874],
              [-31.967669954999849, -10.050504628999875],
              [-31.967925893999848, -10.051548530999924],
              [-31.968732570999805, -10.05336111299988],
              [-31.968897595999863, -10.054034121999962],
              [-31.985820479999859, -10.092092039999841],
              [-32.204636998999831, -10.583737009666663],
              [-32.205804151999928, -10.58626875799996],
              [-32.206427259999884, -10.587669260999917],
              [-32.253562635999913, -10.657096757999909],
              [-32.270643366999821, -10.695488058000024],
              [-32.271150230999922, -10.696234591999882],
              [-32.271527992666563, -10.6968735559999],
              [-32.272363502999838, -10.698190054999941],
              [-32.272615900999938, -10.698757301499768],
              [-32.273500529999865, -10.700172868999971],
              [-32.274093200999801, -10.701145298999961],
              [-32.28345697199984, -10.714936508],
              [-32.286465977999967, -10.721699612999771],
              [-32.287488768999879, -10.723955340999964],
              [-32.288319726999873, -10.72526358749991],
              [-32.289165863999983, -10.726594071999898],
              [-32.28940035449989, -10.72712094849976],
              [-32.290007821999836, -10.72803367199981],
              [-32.290731176999827, -10.72909902799978],
              [-32.291381884999851, -10.730561571999885],
              [-32.296072700999844, -10.73747015399988],
              [-32.298410649999823, -10.742724941],
              [-32.299301529999923, -10.744726868999805],
              [-32.303158530000019, -10.750406869000017],
              [-32.312308838000035, -10.770974070999841],
              [-32.330731881999895, -10.812382042999786],
              [-32.657337157999962, -11.293201151999881],
              [-32.676420295999833, -11.312733097999796],
              [-32.681508979999876, -11.320220910999694],
              [-32.688160529999891, -11.330007868999758],
              [-32.688799304999918, -11.3306619829998],
              [-32.690100125999891, -11.332022598999913],
              [-32.694598356666518, -11.336655643666518],
              [-32.697564114999921, -11.341019413999902],
              [-32.710208053499855, -11.35952918449982],
              [-32.7131621609999, -11.363692538999828],
              [-32.715929642999981, -11.367675517999771],
              [-32.741117443999826, -11.393450726499964],
              [-32.741512372999921, -11.394031733999839],
              [-32.749777530999893, -11.406190868999943],
              [-32.750216865999903, -11.406665405499893],
              [-32.750746935999985, -11.407445354999822],
              [-32.798746486499851, -11.456556808499954],
              [-32.803520444999833, -11.46357874399996],
              [-32.828700860999788, -11.489338904999897],
              [-32.866070250999826, -11.544298185999878],
              [-32.891848324999842, -11.570664262000037],
              [-32.944501992999903, -11.648084848999645],
              [-33.017114348333223, -11.722327641666368],
              [-33.048936339000022, -11.76909958799996],
              [-33.050728484999979, -11.770931524999924],
              [-33.054873264999856, -11.777023123999868],
              [-33.218604035999931, -11.944336009999859],
              [-33.225469209999972, -11.954419041999813],
              [-33.245224334999818, -11.983331683499841],
              [-33.246908462999897, -11.98509369549987],
              [-33.255518712999901, -11.993930096999804],
              [-33.260569530999931, -11.999088869999923],
              [-33.361607460999778, -12.147415778999914],
              [-33.461113644999898, -12.293493533999765],
              [-33.581340194999882, -12.416139584],
              [-33.687546530999839, -12.524482869999986],
              [-33.705762893999861, -12.551186266],
              [-33.743751960999759, -12.606873235999956],
              [-33.745616210499861, -12.608822989499856],
              [-34.155581394999842, -13.026400376999902],
              [-34.165145927999845, -13.033207317999711],
              [-34.174585846999889, -13.042811678999726],
              [-34.175309185999879, -13.043326432999891],
              [-34.177572733999853, -13.045629339999891],
              [-34.460264260000031, -13.24666504999982],
              [-34.62305793299987, -13.609105081999644],
              [-34.623929281666506, -13.610438121000016],
              [-34.628674912999827, -13.617457289999834],
              [-34.639082245999759, -13.632663495499855],
              [-34.639727872999799, -13.63391244349981],
              [-34.64023803099991, -13.634760219999919],
              [-34.641840000999821, -13.638325],
              [-34.665629509999917, -13.67303416999988],
              [-34.666069046999951, -13.674012099999914],
              [-34.666551531999914, -13.675084870999967],
              [-34.673421531999907, -13.685138870999651],
              [-34.673953439999821, -13.685946433999902],
              [-34.674491448999959, -13.686731306999832],
              [-34.67643202499994, -13.691048668999827],
              [-34.679386658, -13.695358926999859],
              [-34.681470516999923, -13.699994919999824],
              [-34.68232053700001, -13.701885867999977],
              [-34.682975865999879, -13.702841834999845],
              [-34.683283970499815, -13.703527187499844],
              [-34.687980017000029, -13.710400052000011],
              [-34.68824596699983, -13.710991578999938],
              [-34.688712314999862, -13.711683084999947],
              [-34.689402362999942, -13.712730363999697],
              [-34.690400531999842, -13.714807870999948],
              [-34.692279183499977, -13.718777873999898],
              [-34.692847867999831, -13.719607555999971],
              [-34.693219389999911, -13.720375470333238],
              [-34.693973745999813, -13.721507401999702],
              [-34.694253508999964, -13.722067760999948],
              [-34.695610531999876, -13.724050870999918],
              [-34.706018031999918, -13.74714537099976],
              [-34.707888531999856, -13.751248870999859],
              [-34.708534932999839, -13.752192000999912],
              [-34.709988780999822, -13.754312518999825],
              [-34.710867908000012, -13.756267998999874],
              [-34.732565195999882, -13.787937293499851],
              [-34.768209437499763, -13.839940033499886],
              [-34.772055567499763, -13.845630793499822],
              [-34.77305366149983, -13.847246603999906],
              [-34.773957763999817, -13.848643521999918],
              [-34.775193571999921, -13.851391268999862],
              [-34.776094531999775, -13.853393871000037],
              [-34.777959273999954, -13.856112952999865],
              [-34.778890540999839, -13.857470721000027],
              [-34.78003698, -13.86001969199981],
              [-34.780690757999935, -13.860972870999888],
              [-34.781029741999816, -13.861726556],
              [-34.781684782999832, -13.86268156899979],
              [-34.782139136499893, -13.863602493999963],
              [-34.782537531999878, -13.864182872000015],
              [-34.78594453199986, -13.871757871999989],
              [-34.963924532999897, -14.131098871999939],
              [-34.97034598599987, -14.145358877000035],
              [-34.983545959999844, -14.17467022400001],
              [-34.984108365999873, -14.1754891839999],
              [-34.984564151499967, -14.176458156999843],
              [-34.985357968999892, -14.178177558999948],
              [-34.986565472999871, -14.180040909000013],
              [-34.999693841999886, -14.199157031999903],
              [-35.002278787999899, -14.204896041999973],
              [-35.006786759999841, -14.211459579],
              [-35.007223462000013, -14.212429091999923],
              [-35.008112559999859, -14.213803588999895],
              [-35.132603253999832, -14.394944373999977],
              [-35.339235532999851, -14.695471871999928],
              [-35.340465081999923, -14.696715090999817],
              [-35.341087292666657, -14.697406944333238],
              [-35.342055193999784, -14.698439517999986],
              [-35.360859756999844, -14.717443519000028],
              [-35.387893769999948, -14.756710109999915],
              [-35.390963416999796, -14.759811463999938],
              [-35.394088097999827, -14.764248157999873],
              [-35.406754762999867, -14.78264326599998],
              [-35.410842494749886, -14.786778734249879],
              [-35.411736808999819, -14.788077402999932],
              [-35.422210269999823, -14.798657264999918],
              [-35.428291532999822, -14.804799871999933],
              [-35.429063324999902, -14.805921042999898],
              [-35.43063803199982, -14.808207435999975],
              [-35.432013257999927, -14.80959653299999],
              [-35.432528637666508, -14.810153794333246],
              [-35.543254331999776, -14.922008215999881],
              [-35.544666598, -14.924057611999899],
              [-35.479841808, -15.117791046999969],
              [-35.440223264999929, -15.39230371799988],
              [-35.397046532999923, -15.691471872999898],
              [-35.397156303499798, -15.695851563499843],
              [-35.396936949999827, -15.697359181000024],
              [-35.418358926999929, -16.276966153999979],
              [-35.46566527799996, -16.490276495999922],
              [-35.49351653299982, -16.615859874000023],
              [-35.464707532999853, -16.667307873999903],
              [-35.376024200999836, -16.925240992999875],
              [-35.27580100199998, -17.216738470999928],
              [-35.233793311999875, -17.49296371600002],
              [-35.188690532999829, -17.789543874999879],
              [-35.188712291999764, -17.790234136000038],
              [-35.188695481666457, -17.790891207333402],
              [-35.202346077999835, -18.224516913],
              [-35.206947673999821, -18.370197703000031],
              [-35.259213422999949, -18.609054526000023],
              [-35.331195532999914, -18.938014876000011],
              [-35.331859886999894, -18.939593119999927],
              [-35.333671871999854, -18.943896030999895],
              [-35.335880921999888, -18.953983873000027],
              [-35.563452871999971, -19.493201761999956],
              [-35.88841667399987, -19.987135630999944],
              [-36.301612900999771, -20.420302038999978],
              [-36.384683309999787, -20.481396069000027],
              [-36.39136397399983, -20.496949115],
              [-36.393036533999918, -20.500842877999958],
              [-36.393390981000039, -20.502419850999956],
              [-36.393822745999955, -20.504339996999931],
              [-36.394712554333296, -20.506562539999891],
              [-36.395355298999846, -20.508198309999841],
              [-36.395601926749805, -20.508786351499907],
              [-36.395733568999958, -20.509371773],
              [-36.396497919999888, -20.511175261999824],
              [-36.396658534000039, -20.511887877999982],
              [-36.398063322999917, -20.515158500999974],
              [-36.400680911999871, -20.521251106000022],
              [-36.401321936499755, -20.524100981999936],
              [-36.403042982499869, -20.528401125499954],
              [-36.403312659999926, -20.529600420999941],
              [-36.404178258499826, -20.531744903499884],
              [-36.404706742999906, -20.533104795999861],
              [-36.405113523666557, -20.534170236999955],
              [-36.405542557999865, -20.535341487999972],
              [-36.406128809999871, -20.537947888999952],
              [-36.406459986000016, -20.53871858899987],
              [-36.407979770999816, -20.545475154999906],
              [-36.410501841000041, -20.551344007000012],
              [-36.420833453999819, -20.597267153999894],
              [-36.422440383999913, -20.601005261999887],
              [-36.423616533999962, -20.603740877999883],
              [-36.424604533999911, -20.608131877999938],
              [-36.512576253999839, -20.812242926999886],
              [-36.656523545999846, -21.14622720899996],
              [-36.656960975999766, -21.146880812500001],
              [-36.657365480999829, -21.147731634000024],
              [-36.659675307999976, -21.151294107999888],
              [-36.660409642999866, -21.152993371999855],
              [-36.923253538999859, -21.544858475000012],
              [-36.930745537999883, -21.577691141],
              [-36.954950533999863, -21.683764878999966],
              [-37.093416231999868, -22.001234515999784],
              [-37.189416575999985, -22.221340553],
              [-37.447714583999868, -22.602066481999941],
              [-37.539586932999782, -22.998135773999877],
              [-37.540193911999893, -22.999511523999843],
              [-37.540528765999852, -23.000953073999881],
              [-37.541135688999759, -23.002328661999911],
              [-37.541470590999836, -23.00377037599992],
              [-37.542066886999805, -23.005121842999927],
              [-37.542310351999788, -23.006169218999901],
              [-37.543100534999837, -23.00811087999989],
              [-37.543331503999809, -23.009105744999943],
              [-37.544926618999966, -23.012720743999921],
              [-37.545233366999867, -23.014041098],
              [-37.782935706999808, -23.551078507],
              [-37.783447159999923, -23.551823491499874],
              [-37.783883351999862, -23.552696458999947],
              [-38.121798275999765, -24.043293167999934],
              [-38.129892976999827, -24.051420210999936],
              [-38.133035671999892, -24.054605530499828],
              [-38.133724843999801, -24.055602372999942],
              [-38.562437715999863, -24.484268391999933],
              [-38.563734061499872, -24.485175572999935],
              [-38.565432527999747, -24.486812864499825],
              [-38.56690507799982, -24.487843403999875],
              [-38.568427285999917, -24.489359215999968],
              [-38.791548933999934, -24.645194052999955],
              [-38.916385960333173, -24.823203521333141],
              [-38.917305513999906, -24.824450126999977],
              [-39.016317984999773, -24.922309363999887],
              [-39.113887327999862, -25.060835341999905],
              [-39.1154938009999, -25.062418208999873],
              [-39.115878002999807, -25.062963050499945],
              [-39.15225734299986, -25.098658875999945],
              [-39.550526934999937, -25.48941895199988],
              [-39.561134646999847, -25.496697307000034],
              [-39.56274107049984, -25.498218376499892],
              [-39.563547458333232, -25.498781201333315],
              [-39.564893731999916, -25.499716575999912],
              [-39.566390157499882, -25.501178101499931],
              [-40.079598536999868, -25.851638882999964],
              [-40.117258789999823, -25.868837769999971],
              [-40.655016828999919, -26.11442312399987],
              [-41.274508925999896, -26.281114666],
              [-41.917954517999988, -26.346200535000037],
              [-41.918793811499825, -26.346182504999831],
              [-42.370148316999803, -26.31945616299987],
              [-42.443862519999982, -26.338563099999874],
              [-42.479260862999922, -26.341951478],
              [-42.496485315499854, -26.346348996500012],
              [-42.503292405999844, -26.348051644999885],
              [-42.628552536999848, -26.35992474],
              [-42.714864539999837, -26.368105882999885],
              [-42.762969482999949, -26.41276044499989],
              [-42.851453976999778, -26.494897646999959],
              [-42.852313851999781, -26.49545656500004],
              [-42.854456042999914, -26.497442160999924],
              [-43.38046102099986, -26.837563600999943],
              [-43.967202268499875, -27.088765481499991],
              [-43.971279881999976, -27.090499504999968],
              [-44.18543857099985, -27.143292656999961],
              [-44.306343400999936, -27.219819895499882],
              [-44.308223683, -27.220974754999929],
              [-44.468008972999883, -27.288259025999935],
              [-44.589704541999822, -27.339503883999896],
              [-44.600699942999825, -27.45772653299997],
              [-44.605136515333243, -27.505428491999965],
              [-44.60439566999986, -27.512567239999896],
              [-44.604007642999818, -27.516306412999938],
              [-44.604145635999828, -27.517788235999888],
              [-44.603873851999879, -27.520406921000017],
              [-44.658064561999936, -28.09969282399976],
              [-44.678375722999988, -28.16786473499991],
              [-44.680498093999859, -28.190384947999917],
              [-44.683325541999977, -28.220383884999933],
              [-44.703876541999875, -28.28923788499992],
              [-44.717284046999964, -28.430905746],
              [-44.719786539999916, -28.457347180999932],
              [-44.871156626999777, -28.960984512999914],
              [-44.871481776999929, -28.964353161999838],
              [-44.871762517999855, -28.967260635999935],
              [-45.042367984999942, -29.529406269999924],
              [-45.296725017999847, -30.005057314999931],
              [-45.324399541999867, -30.056808886999988],
              [-45.342005937999943, -30.078583060999904],
              [-45.476051648999885, -30.244359444],
              [-45.479163611999809, -30.250119445999985],
              [-45.866799873999867, -30.725838346999922],
              [-45.882058866999955, -30.73888833299992],
              [-45.925278535999894, -30.791585269999928],
              [-46.253436364999914, -31.070258419999846],
              [-46.406759542999851, -31.200460887999895],
              [-46.40828999349992, -31.201365234999855],
              [-46.413832748999823, -31.205974002999881],
              [-46.57014642099989, -31.298197774333119],
              [-46.570419581999829, -31.298702204],
              [-46.571090727999774, -31.30076045200002],
              [-46.571875038999906, -31.30216459799982],
              [-46.573981162499877, -31.3085034],
              [-46.574371819999897, -31.309581843999922],
              [-46.574789542999838, -31.310861887999977],
              [-46.575751621599807, -31.312588270999981],
              [-46.578487542999824, -31.320961887999943],
              [-46.578722227749779, -31.321382447999838],
              [-46.579125512999951, -31.322586543999989],
              [-46.579550242999915, -31.323721622499818],
              [-46.580351758999882, -31.32601151599988],
              [-46.581057022999971, -31.32727358],
              [-46.583117520999849, -31.333588309999982],
              [-46.584075074333271, -31.335305581333216],
              [-46.585034156999882, -31.337027795999916],
              [-46.586523293666573, -31.339695438666695],
              [-46.587833486499903, -31.343709722500023],
              [-46.588793234249977, -31.345432333999952],
              [-46.59127147799984, -31.353013006999959],
              [-46.597034767000025, -31.370664244999773],
              [-46.600801025999885, -31.377398129999904],
              [-46.621675349999805, -31.441249750999873],
              [-46.62556554299988, -31.453148887999873],
              [-46.62620374200003, -31.454288646],
              [-46.627877491999868, -31.457277026999918],
              [-46.63490979199986, -31.478612353500012],
              [-46.638459471999852, -31.489303347999908],
              [-46.639411843999881, -31.4910059367499],
              [-46.640351981999856, -31.492692285999908],
              [-46.643114828999842, -31.501128391999771],
              [-46.644401657999822, -31.50342402800004],
              [-46.644744331999846, -31.504470165999919],
              [-46.645009542999844, -31.505278888000021],
              [-46.645733958999926, -31.506571664999967],
              [-46.647084736999943, -31.508981146999929],
              [-46.647828727999865, -31.51125211599998],
              [-46.648111273999803, -31.511755798499962],
              [-46.648437641999806, -31.512681138999881],
              [-46.648835310499976, -31.513804274499989],
              [-46.652428472999873, -31.524679288999948],
              [-46.653379718999872, -31.526378656999952],
              [-46.653615985999807, -31.526810192999903],
              [-46.657163403999959, -31.537496491999946],
              [-46.657956542999841, -31.539829887999929],
              [-46.658564323499832, -31.540913499499936],
              [-46.661438770999837, -31.549679624999897],
              [-46.666219755999919, -31.564259580999931],
              [-46.668171793999875, -31.567737941999951],
              [-46.670877484000016, -31.57598554499998],
              [-46.671875069666612, -31.577772881666647],
              [-46.672172542999874, -31.578306887999929],
              [-46.672669222499792, -31.579822215999911],
              [-46.673691593499882, -31.581848931499962],
              [-46.673988754999868, -31.582425221999983],
              [-46.67459543949974, -31.584273947499867],
              [-46.681228437999891, -31.596133361],
              [-46.710583697999937, -31.685473394999974],
              [-46.711245918000031, -31.686650863999887],
              [-46.711856543999829, -31.687735887999963],
              [-46.712597980499766, -31.689838855999881],
              [-46.713583835999941, -31.692462240999873],
              [-46.714307491999932, -31.693785223999896],
              [-46.714958481999815, -31.694979410999906],
              [-46.715288024999893, -31.695574830999931],
              [-46.718141319999802, -31.700661030999925],
              [-46.721315750999906, -31.706307779999975],
              [-46.721928753999919, -31.708170523999911],
              [-46.722879845999842, -31.709870731500018],
              [-46.72383875099996, -31.711594688499929],
              [-46.724797651999893, -31.713318645999891],
              [-46.726944572999969, -31.717143669999928],
              [-46.727627024999947, -31.719216821000032],
              [-46.728572525399784, -31.720902181199961],
              [-46.728826973999844, -31.721371159999933],
              [-46.72951694499983, -31.723466931999837],
              [-47.026768841499972, -32.248486883499993],
              [-47.028030015999832, -32.250695997000037],
              [-47.028898008999874, -32.252088941499949],
              [-47.029602347499804, -32.252950579500009],
              [-47.030363776499883, -32.253884306499913],
              [-47.030815092499836, -32.254574924999986],
              [-47.032123011999829, -32.256116355999893],
              [-47.042960873999846, -32.275137441000027],
              [-47.044191664499806, -32.277262690499981],
              [-47.046579460999851, -32.281417406999878],
              [-47.047985543999914, -32.283883889000037],
              [-47.049120314499703, -32.285220526499927],
              [-47.052979061333247, -32.291773836666607],
              [-47.057888534499739, -32.300186984999982],
              [-47.059045362999825, -32.301549072999762],
              [-47.05964661649989, -32.30258917749984],
              [-47.06056362299995, -32.304182935000028],
              [-47.069615181999893, -32.314837154999964],
              [-47.070685915999832, -32.31671402500001],
              [-47.072321695499966, -32.319571907999944],
              [-47.073962005999817, -32.321502171999953],
              [-47.074340063666511, -32.322102946666519],
              [-47.075630757999875, -32.323628927999891],
              [-47.076280393999809, -32.324726842999951],
              [-47.077182615999845, -32.326267381999969],
              [-47.08474234099981, -32.335161213999868],
              [-47.086928532999934, -32.338991312999781],
              [-47.091391964999843, -32.344241090999958],
              [-47.091747306499769, -32.34486304849986],
              [-47.094422877999818, -32.348056454999892],
              [-47.096603226499951, -32.350693937999893],
              [-47.100839582999868, -32.35574858699988],
              [-47.101235589499822, -32.35623781299995],
              [-47.101630376999935, -32.356929137999941],
              [-47.103242368, -32.358824363999986],
              [-47.104539870999872, -32.361096084999929],
              [-47.106102999999848, -32.362933692000013],
              [-47.106507623999846, -32.3636420549999],
              [-47.108418836999931, -32.366987828],
              [-47.114483803999917, -32.374116380999965],
              [-47.116227756999876, -32.377168502999893],
              [-47.117690864999986, -32.378887890999977],
              [-47.122045653999862, -32.386507969000036],
              [-47.125029538999854, -32.390013742],
              [-47.126919613999917, -32.393320273999962],
              [-47.129993242999944, -32.396958457666642],
              [-47.130949482599874, -32.398568918799981],
              [-47.131796654999846, -32.400035162],
              [-47.139262828999961, -32.40880388100004],
              [-47.142511552999906, -32.414484218999917],
              [-47.143899583499831, -32.416113900999903],
              [-47.144420976999811, -32.416926366999803],
              [-47.147440650999897, -32.420505157999912],
              [-47.150606949999826, -32.426039646999982],
              [-47.151264547999915, -32.427188884999964],
              [-47.152726664999932, -32.42890515800002],
              [-47.153200810999977, -32.429733821999946],
              [-47.154034133999915, -32.430711945000041],
              [-47.154706543999794, -32.431500888999949],
              [-47.15521126699997, -32.43238336099995],
              [-47.156137932999911, -32.434002748000012],
              [-47.157669416999966, -32.435800173999951],
              [-47.161927722999962, -32.443240499999888],
              [-47.163032088999955, -32.444536299499873],
              [-47.168520567999849, -32.4540394469999],
              [-47.174475626999822, -32.464440728999932],
              [-47.176328499499959, -32.466681548],
              [-47.177721736999899, -32.468383183000014],
              [-47.178394718666652, -32.469549625999889],
              [-47.181305060333329, -32.474617805333253],
              [-47.182232821999946, -32.476224613999989],
              [-47.184728276499868, -32.479225209499987],
              [-47.186155543999973, -32.481715888999872],
              [-47.188258289999965, -32.484181605999822],
              [-47.191224460999877, -32.487659121999911],
              [-47.195858437999959, -32.495745436999925],
              [-47.198458958999936, -32.498793398999908],
              [-47.19891419649997, -32.499354827999866],
              [-47.199762594999868, -32.500835029000029],
              [-47.210301975, -32.513184911999929],
              [-47.218264589999876, -32.527069260999951],
              [-47.222513317499818, -32.532045311999923],
              [-47.224380893999864, -32.535215802999971],
              [-47.227985687999876, -32.541498826999884],
              [-47.228493333999779, -32.542093215000023],
              [-47.22922954399985, -32.54295488899993],
              [-47.230659820999961, -32.545447687000035],
              [-47.23474102299997, -32.552559218999932],
              [-47.236067676999824, -32.554112196],
              [-47.247290633999853, -32.573660824],
              [-47.248737477499901, -32.575353780499881],
              [-47.249227545, -32.576206887999888],
              [-47.253756034999867, -32.58150501800003],
              [-47.255098453999835, -32.583842182999888],
              [-47.256665306999878, -32.585675077000019],
              [-47.258007352999982, -32.588011343999881],
              [-47.260994298999947, -32.59150504899992],
              [-47.261911264999981, -32.593101087999912],
              [-47.266485517999911, -32.598450511999943],
              [-47.268002633499975, -32.600310908999859],
              [-47.268837566, -32.60172965799984],
              [-47.269750017999911, -32.60328314000003],
              [-47.27100970399988, -32.604755901999852],
              [-47.271863027999871, -32.606172515999901],
              [-47.272655687999986, -32.607551674999968],
              [-47.277080363999971, -32.612724533999881],
              [-47.278522649666513, -32.615218127666537],
              [-47.279439183999841, -32.61679892099994],
              [-47.280517199999963, -32.618651155499947],
              [-47.282379300999935, -32.621882262999975],
              [-47.286758526999847, -32.627044418999844],
              [-47.287247543999854, -32.627893889],
              [-47.288878682499842, -32.629856040499874],
              [-47.289214441999832, -32.63044011],
              [-47.290658246999953, -32.632127232999892],
              [-47.29115086399986, -32.632983746999969],
              [-47.292571350333219, -32.634643615999892],
              [-47.295998543999872, -32.640600889000041],
              [-47.297586318499839, -32.642485983499796],
              [-47.297965509999983, -32.643145212999968],
              [-47.299520988999859, -32.644962305000035],
              [-47.300860912999838, -32.647291210499873],
              [-47.308089691999839, -32.655741908999971],
              [-47.315467264999825, -32.668559408499959],
              [-47.316906826999912, -32.670240128999978],
              [-47.317403622999905, -32.671103063999965],
              [-47.320368306999967, -32.674564104999888],
              [-47.320791058999873, -32.675298327999883],
              [-47.321307543999978, -32.676194888999987],
              [-47.324067479999883, -32.679416585999945],
              [-47.328838387999838, -32.684985194999854],
              [-47.330112759999849, -32.687197816999912],
              [-47.331517000999952, -32.688836555999956],
              [-47.334959792999882, -32.694813230999955],
              [-47.336361447999877, -32.696448681],
              [-47.339806789999841, -32.702428661999875],
              [-47.345518640999842, -32.70909171999989],
              [-47.35146269199987, -32.719404657999917],
              [-47.354395405666537, -32.722824682666669],
              [-47.359423952499867, -32.73142939749988],
              [-47.362019350999844, -32.734455233999881],
              [-47.365059893999842, -32.739727555999934],
              [-47.368012801999839, -32.743169479999892],
              [-47.368963416999975, -32.744817552999905],
              [-47.370267650999835, -32.746337626000013],
              [-47.374779235999824, -32.754158103999941],
              [-47.376556323999921, -32.756321072499986],
              [-47.37840193299985, -32.758563737999964],
              [-47.37933954399989, -32.75965588899993],
              [-47.381478015999903, -32.763362092999841],
              [-47.383498389999829, -32.766863083999908],
              [-47.384323451999876, -32.767824222999877],
              [-47.402889884999979, -32.799935050499869],
              [-47.403776238999853, -32.801454219999925],
              [-47.406712419999963, -32.804872094999951],
              [-47.407679525999868, -32.806546229],
              [-47.408957887999861, -32.808058249333385],
              [-47.409633614666575, -32.809215893333317],
              [-47.410203988000035, -32.810196042999905],
              [-47.412494961999869, -32.814161298999977],
              [-47.413514697999858, -32.815347796499964],
              [-47.416903067999897, -32.821113067999988],
              [-47.419279391999964, -32.825225177999982],
              [-47.420664546000012, -32.826836766999918],
              [-47.421661819999827, -32.82856199899993],
              [-47.427008543999818, -32.837810889000025],
              [-47.427995246999842, -32.838959065999973],
              [-47.428334387333166, -32.839353651333283],
              [-47.428984729333138, -32.840471263999859],
              [-47.431854520999934, -32.845426753999902],
              [-47.432938668999839, -32.846687586999963],
              [-47.438607690999959, -32.856490112000017],
              [-47.440116842999771, -32.85824478699999],
              [-47.440574774999902, -32.859036475000039],
              [-47.458458925999935, -32.879823810999937],
              [-47.459176662, -32.881063862999952],
              [-47.460414917999856, -32.882502678999913],
              [-47.463050613999883, -32.887055936999957],
              [-47.871367081999892, -33.358546003999948],
              [-47.873362717999896, -33.360155896999913],
              [-47.879448563499807, -33.367097346499918],
              [-47.909053920999895, -33.401008009999771],
              [-47.90997930799989, -33.40175372099992],
              [-47.91103954499988, -33.402607890000013],
              [-47.914405715999862, -33.406466210999952],
              [-47.919958777999852, -33.412830597000038],
              [-47.921960507999927, -33.414443167999877],
              [-47.923938750999923, -33.416709943999933],
              [-47.925771831999896, -33.418247087499992],
              [-47.927574796999863, -33.419759903999875],
              [-47.928928463999853, -33.421310776000041],
              [-47.930927470999961, -33.422920797499899],
              [-47.932908450000014, -33.425190131999898],
              [-47.935008994999976, -33.426881718999937],
              [-47.939853040999822, -33.432429993999904],
              [-47.9438038159999, -33.435610900999905],
              [-47.951804603999847, -33.444772091999937],
              [-47.964919690999977, -33.455326795999895],
              [-47.981653555999969, -33.474473769999975],
              [-47.989198536999936, -33.480541272999915],
              [-47.996572365999839, -33.488973654999938],
              [-48.003262879999852, -33.494351902999881],
              [-48.004557725999973, -33.495832169999971],
              [-48.006777472999943, -33.497616262999884],
              [-48.00734713733317, -33.498128673],
              [-48.011321584499797, -33.501484936999873],
              [-48.016604998999867, -33.505814879000013],
              [-48.017544289999876, -33.50688829],
              [-48.035421164499894, -33.521293286999878],
              [-48.039985439999839, -33.525003011000024],
              [-48.041006544999846, -33.525822889999915],
              [-48.043605217999925, -33.528764051],
              [-48.059354776999839, -33.541407766999839],
              [-48.060277026499989, -33.542460343000016],
              [-48.136953544999926, -33.60402389],
              [-48.139898296999775, -33.607378449],
              [-48.143492748999904, -33.611472511999921],
              [-48.150555508999844, -33.617128617999924],
              [-48.156443666999792, -33.623832662000041],
              [-48.159902699999861, -33.626601933999893],
              [-48.160400782499948, -33.627168913999867],
              [-48.163369640999974, -33.629558133999922],
              [-48.168390365999812, -33.635272525999881],
              [-48.187240824999776, -33.650354850999861],
              [-48.19442754499994, -33.656104889999824],
              [-48.203292729999816, -33.666180255000029],
              [-48.240021762999845, -33.707922339999925],
              [-48.255081976999861, -33.719949871999972],
              [-48.285751380999841, -33.754750586999933],
              [-48.297877651999897, -33.764422809999871],
              [-48.299566544999834, -33.765769889999945],
              [-48.303874802999871, -33.770653420999906],
              [-48.314619713999832, -33.782832672999973],
              [-48.318682718999924, -33.78607127399988],
              [-48.319618741999761, -33.787131870999758],
              [-48.322987434999845, -33.789816678999898],
              [-48.324118057999783, -33.790760298499947],
              [-48.324620626999888, -33.791329751000035],
              [-48.325446436999925, -33.792047842999864],
              [-48.335391480999874, -33.800104183499911],
              [-48.336221977499804, -33.800837757999886],
              [-48.336671643999921, -33.801326926999877],
              [-48.337609044999965, -33.802388567000037],
              [-48.343163991999859, -33.806813655999974],
              [-48.344588495000039, -33.808426645999816],
              [-48.346395749499777, -33.809934716999805],
              [-48.352456336999978, -33.814830058999974],
              [-48.353548752333211, -33.816064177666703],
              [-48.355388990999984, -33.817612170499899],
              [-48.359948903999964, -33.821311755999886],
              [-48.360595839000041, -33.822043960999906],
              [-48.49146796999986, -33.92556822399996],
              [-48.868916667666554, -34.224088622666557],
              [-48.882391876999861, -34.246106309000027],
              [-48.883151895999902, -34.248194631999979],
              [-48.884036600999821, -34.249729893],
              [-48.88493243049993, -34.251283395999792],
              [-48.885261657999934, -34.252148259999899],
              [-48.887073881499816, -34.256916856999794],
              [-48.887776535999819, -34.258157499666652],
              [-48.891897856999776, -34.265016664999962],
              [-48.894549890999826, -34.272298806000038],
              [-48.896250546000033, -34.276967890999913],
              [-48.896828487999869, -34.277920202999894],
              [-48.897177901999839, -34.278507326750002],
              [-48.898044986333105, -34.280078617666447],
              [-48.899972507999792, -34.283349632999887],
              [-48.900973520999912, -34.286097221999853],
              [-48.901464047999923, -34.286904870999933],
              [-48.901867683499859, -34.287852676],
              [-48.941619534999973, -34.353297508999901],
              [-48.95476690299995, -34.389288004999969],
              [-48.95567893024986, -34.390873914999872],
              [-48.956603966999808, -34.392545551000012],
              [-48.957599592999941, -34.39526945599988],
              [-48.958458692499846, -34.396706236999933],
              [-49.278123298999901, -34.91841603499995],
              [-49.278662615999849, -34.919008823999889],
              [-49.288767957999937, -34.935384412999895],
              [-49.289588992999825, -34.936286493000011],
              [-49.291549292999832, -34.939462151999862],
              [-49.29756903, -34.946137425999893],
              [-49.300476640999875, -34.950846234999915],
              [-49.30143254599983, -34.952393890999929],
              [-49.302053780999984, -34.953076420999878],
              [-49.302557345499963, -34.953629558999864],
              [-49.303366319999839, -34.954939489999845],
              [-49.30427798799991, -34.955940648000023],
              [-49.30588467799987, -34.958542042999966],
              [-49.306298545999937, -34.959211890999882],
              [-49.307010089000016, -34.959993399999973],
              [-49.30734939974991, -34.960383757749923],
              [-49.309202920999894, -34.963384460999976],
              [-49.309795742999881, -34.964035142500052],
              [-49.310298776499849, -34.964732200999947],
              [-49.310699580999824, -34.965172435999818],
              [-49.311168021999947, -34.965930719999903],
              [-49.315957150999907, -34.971188117999873],
              [-49.31695454599992, -34.97228289100002],
              [-49.3176843, -34.973464001999957],
              [-49.320899995999866, -34.978667806000033],
              [-49.322108843999843, -34.979994511000029],
              [-49.322864947999904, -34.981217902999902],
              [-49.324806353999747, -34.983351734499934],
              [-49.334644249999769, -34.994149254000035],
              [-49.335614938999868, -34.995719127999905],
              [-49.340575678999983, -35.001160623999908],
              [-49.342691002499919, -35.004453325999862],
              [-49.346313723000037, -35.010182623999981],
              [-49.347727608999946, -35.011733048],
              [-49.34823505799983, -35.012553228499883],
              [-49.35011915899986, -35.014699544499877],
              [-49.351011738749833, -35.015776840999933],
              [-49.364467241999876, -35.030630144999918],
              [-49.364958442999921, -35.031423672999949],
              [-49.365868359999979, -35.032420848999948],
              [-49.367745666666565, -35.034559368333248],
              [-49.796954262499753, -35.501810705499906],
              [-49.800910212999781, -35.506082442999912],
              [-49.801805494999826, -35.50676475399996],
              [-49.809860082999819, -35.515470209999819],
              [-49.818804712999963, -35.522284537999951],
              [-49.829912445999923, -35.534282104],
              [-49.835766545999888, -35.540604891999919],
              [-49.83746854199984, -35.541900888999919],
              [-49.841753545999836, -35.546526891999918],
              [-49.842726034, -35.547267519],
              [-49.84468988399982, -35.548762386999954],
              [-49.845750554999881, -35.549907395999924],
              [-49.850778466999941, -35.553734079999913],
              [-49.855492076999951, -35.558821212999874],
              [-49.856765372499922, -35.560177209999836],
              [-49.857562990999895, -35.560784191999971],
              [-49.86171153849989, -35.56525302599988],
              [-49.863688668999941, -35.567378596999902],
              [-49.890353919999768, -35.587656854999921],
              [-49.891726267999843, -35.589136357999905],
              [-49.911205424999878, -35.603940114999887],
              [-49.911716647499759, -35.60449088099989],
              [-49.921629844666541, -35.612067183666625],
              [-49.929936703999829, -35.618426678],
              [-49.931805630999918, -35.620439274999853],
              [-49.932579943999826, -35.621027251999919],
              [-49.934783595999818, -35.623400101000016],
              [-49.936388130999831, -35.62461839],
              [-49.93876947099983, -35.627182267999956],
              [-49.939541841999926, -35.627768652999976],
              [-49.941747445999965, -35.630143098999838],
              [-49.971702337999773, -35.652874098999803],
              [-49.976790251999887, -35.658346285],
              [-49.978123302999791, -35.659357283999896],
              [-49.978803376499741, -35.660088669499885],
              [-49.980069355999944, -35.661792133],
              [-50.014827281666498, -35.708561370666587],
              [-50.091538806964479, -35.856896100266795],
              [-50.139805083330316, -35.826192427801232],
              [-50.27054749462593, -35.742962422253015],
              [-50.401153973522582, -35.659731206074866],
              [-50.531624911453036, -35.576498825516296],
              [-50.661960911363735, -35.493265328570089],
              [-50.792162676783619, -35.410030673437802],
              [-50.922230570442366, -35.326794817841858],
              [-51.052165385515025, -35.243557810673678],
              [-51.181967355783456, -35.160319700980324],
              [-51.311637145055045, -35.077080358045123],
              [-51.441175407710489, -34.993839921521214],
              [-51.570582458784827, -34.910598261978691],
              [-51.69985904368383, -34.827355519291302],
              [-51.829005677280918, -34.744111563793126],
              [-51.958022758424534, -34.660866537622894],
              [-52.086910892641413, -34.577620311387932],
              [-52.215670568913481, -34.494372937150729],
              [-52.344302483714671, -34.411124374932456],
              [-52.472806892007668, -34.32787476994514],
              [-52.601184469135717, -34.244623902560249],
              [-52.729435778505206, -34.16137191500718],
              [-52.857561158665987, -34.078118952941622],
              [-52.866235013349296, -34.072477274160576],
              [-52.985561361228406, -33.994864615266636],
              [-53.113436711173449, -33.911609228802433],
              [-53.241187842021361, -33.828352755031801],
              [-53.359893507914876, -33.750915109238633],
              [-53.370570670999825, -33.742844227999967],
              [-53.374109608532763, -33.740571005893401],
              [-53.397635758753665, -33.741117849102068],
              [-53.521317781251412, -33.681735920613789],
              [-53.5344460209555, -33.656954991444309],
              [-53.528890830791568, -33.611117883249108],
              [-53.523055014508429, -33.574172968519349],
              [-53.513890912103022, -33.524726948082844],
              [-53.50833588957704, -33.486390972698416],
              [-53.505008944559336, -33.460282014786173],
              [-53.501399864680025, -33.428063990546548],
              [-53.501108845000886, -33.40805504650649],
              [-53.509172906430479, -33.321391028933988],
              [-53.515699894451245, -33.290417884957549],
              [-53.522226882472239, -33.25944608208539],
              [-53.52861791602345, -33.221117985689943],
              [-53.530281891446521, -33.199446072055082],
              [-53.524581861995216, -33.157081921631288],
              [-53.521046039949681, -33.141608928326093],
              [-53.515835848921199, -33.129308988295918],
              [-53.50028188643131, -33.099999987380556],
              [-53.480417781678398, -33.074864000791706],
              [-53.462226872442045, -33.059172910365419],
              [-53.435563870728515, -33.040281944573849],
              [-53.298617821694393, -32.946673017287083],
              [-53.245563894844963, -32.862226851301045],
              [-53.170799832882153, -32.802181914269568],
              [-53.098299890611258, -32.723435945373069],
              [-53.159172959678926, -32.661117836194023],
              [-53.191672951141982, -32.63402685450302],
              [-53.259999879086308, -32.600835858940002],
              [-53.349726811725589, -32.579172997760622],
              [-53.378199801616006, -32.574308979334376],
              [-53.404172972696244, -32.568055073719677],
              [-53.521117789041227, -32.474445978794733],
              [-53.537226801161012, -32.46083594729464],
              [-53.553335813280682, -32.44610894337427],
              [-53.567508941037829, -32.43111790950303],
              [-53.630763979356232, -32.357991000872914],
              [-53.643617795549972, -32.305626904656776],
              [-53.637226761998704, -32.271391021041154],
              [-53.6647267805659, -32.206946087744313],
              [-53.743054995406965, -32.096672931069861],
              [-53.875417819771769, -31.974446006967241],
              [-53.918545896853175, -31.942155060169796],
              [-54.071326869772406, -31.875699978847933],
              [-54.095835890001922, -31.88416402469818],
              [-54.164455011091604, -31.887217887309589],
              [-54.285835865885304, -31.804446090306669],
              [-54.351108931218164, -31.750146109727609],
              [-54.383199885805254, -31.715700007979763],
              [-54.414999820713319, -31.693608999184903],
              [-54.441672880710826, -31.681663948936148],
              [-54.463399947268726, -31.672608978910432],
              [-54.472226761889374, -31.57979096800949],
              [-54.594099802039011, -31.460908926197476],
              [-54.705008809890217, -31.440135887861686],
              [-54.72833581413056, -31.43860887273695],
              [-54.763054998284701, -31.441108859185022],
              [-54.807090835482541, -31.435690964595267],
              [-54.834035804419727, -31.426673042581683],
              [-54.865835739327849, -31.409726845970397],
              [-54.913608899523979, -31.374446074301886],
              [-54.942508863563319, -31.350835929371385],
              [-54.976954965311222, -31.318890987537998],
              [-55.063126797527957, -31.325282021088867],
              [-55.089581928042207, -31.32569089032711],
              [-55.22875488111805, -31.249726875102112],
              [-55.24736387968602, -31.23125500447054],
              [-55.330208934522886, -31.078399935527159],
              [-55.350699837996956, -31.045699951853521],
              [-55.421672932781121, -30.963055056865073],
              [-55.521117843989146, -30.888890971533215],
              [-55.584172890097364, -30.846255079807634],
              [-55.629026801047416, -30.850417868212276],
              [-55.640417807494771, -30.870699894658685],
              [-55.671954885918353, -30.949446031193361],
              [-55.759446029698552, -31.016946000567827],
              [-55.837917910360375, -31.072782048726992],
              [-55.890554921344631, -31.079999873209829],
              [-55.910563865384972, -31.082226944889726],
              [-55.986672887535178, -31.081673068726161],
              [-56.008917788072608, -31.079790996181998],
              [-56.012217911000164, -31.066391015176123],
              [-56.006672946757817, -30.963608933028411],
              [-56.002226850025068, -30.921872922430282],
              [-55.98500891211205, -30.890699953881494],
              [-55.976808896212674, -30.85104600803794],
              [-55.989726917784594, -30.812781949383009],
              [-56.00167297386173, -30.795835920409615],
              [-56.116390886164538, -30.675835900349057],
              [-56.235835856595372, -30.5583358667364],
              [-56.266945960870203, -30.535835821065518],
              [-56.315281882047088, -30.50833580249838],
              [-56.387508909549865, -30.45999988132138],
              [-56.511117841851615, -30.358890994690512],
              [-56.526946060214414, -30.345835845182137],
              [-56.548055045230399, -30.323064058209525],
              [-56.567781854408963, -30.299164067066386],
              [-56.639172870886455, -30.228472939505934],
              [-56.790281989393634, -30.115554957095924],
              [-56.811390974409619, -30.105281928897398],
              [-56.860555027622638, -30.096673043759836],
              [-56.933617898512239, -30.097500002329468],
              [-57.070972984422212, -30.108891008776709],
              [-57.103890897578935, -30.135417888382335],
              [-57.144172983248154, -30.184999863288624],
              [-57.147090891389837, -30.208054958589628],
              [-57.147917849959583, -30.238199970530047],
              [-57.193617830218386, -30.282635959786603],
              [-57.213890971847263, -30.292082029415496],
              [-57.233890863432123, -30.293335962134051],
              [-57.285281988324925, -30.293335962134051],
              [-57.303755032422941, -30.275417967665874],
              [-57.322226903054684, -30.266672960420522],
              [-57.35639086994081, -30.27222697711818],
              [-57.495417810262893, -30.279864064398794],
              [-57.524863939114766, -30.274581956640759],
              [-57.547363984785648, -30.266390993196808],
              [-57.566599949712838, -30.247435822026887],
              [-57.564308840292426, -30.215146048695829],
              [-57.576808940171134, -30.197082041473891],
              [-57.607999845992708, -30.184926940730975],
              [-57.582508801984318, -30.180835901417709],
              [-57.553890972806641, -30.172781898271978],
              [-57.533617831177594, -30.163335828642914],
              [-57.486946053061843, -30.132226897834641],
              [-57.344526788876919, -29.999999860301614],
              [-57.325490983798204, -29.97827296138172],
              [-57.318926780127128, -29.91083585628121],
              [-57.318681860915603, -29.875282002206404],
              [-57.297426863145859, -29.825835814131835],
              [-57.27991790555393, -29.805699968077192],
              [-57.257499834896237, -29.791390885850149],
              [-57.227781964742803, -29.780555096670923],
              [-57.194726756011505, -29.77749989295495],
              [-57.144726859411662, -29.776526921631614],
              [-57.103890897578935, -29.762154975130727],
              [-57.076254924542127, -29.737917863840948],
              [-57.045008865797342, -29.706108876477288],
              [-56.913063963125978, -29.558890972711197],
              [-56.813335743589846, -29.467226814604714],
              [-56.695835877615366, -29.343263998349585],
              [-56.65520879281047, -29.263263929096468],
              [-56.654863961312856, -29.230835854362624],
              [-56.644173011420776, -29.200699894877516],
              [-56.624999910767599, -29.174446097678015],
              [-56.592226836898192, -29.14069100002979],
              [-56.571672901511931, -29.124726827197094],
              [-56.534172937152448, -29.103335874957736],
              [-56.515008888954753, -29.092782053001997],
              [-56.42739084316014, -29.069573065958423],
              [-56.413063991298543, -29.046390901005225],
              [-56.301672856375376, -28.831946110270792],
              [-56.29541777729429, -28.79743580314431],
              [-56.27556389846319, -28.776672990730333],
              [-56.170008915099231, -28.72833589608706],
              [-56.0661179071584, -28.626108863569243],
              [-56.011117870024236, -28.561108880642678],
              [-55.978890960967078, -28.488335855965659],
              [-55.893063960248242, -28.369726896559939],
              [-55.86979093546438, -28.356317863098411],
              [-55.833063950217934, -28.353054955821221],
              [-55.732917808988361, -28.363191023721527],
              [-55.720626753775434, -28.387635838572692],
              [-55.697535783928913, -28.407708987991214],
              [-55.667635858838196, -28.335626967414044],
              [-55.680835847633716, -28.302426919395543],
              [-55.717781935829805, -28.280835807307483],
              [-55.767508917661587, -28.256255037986591],
              [-55.770281986515897, -28.235626838938117],
              [-55.748335817008353, -28.217082045748612],
              [-55.709445965460418, -28.208617832260359],
              [-55.678608943591996, -28.195554971401009],
              [-55.633890819473777, -28.1588179278707],
              [-55.626299832660777, -28.141017950599405],
              [-55.582226779812629, -28.133054974922516],
              [-55.447781890693136, -28.086673043019516],
              [-55.380281921318613, -28.025835848497593],
              [-55.379581864763452, -27.993472984970708],
              [-55.374172854991173, -27.973608880217796],
              [-55.321254882611584, -27.922081968493046],
              [-55.179726782374985, -27.856255026997019],
              [-55.063754937353622, -27.845699863936716],
              [-55.02166386933601, -27.833890935796035],
              [-54.9081998897517, -27.730281895078633],
              [-54.901672901730763, -27.71110896206352],
              [-54.898608813197427, -27.670282052686389],
              [-54.813608938686116, -27.535555028705289],
              [-54.792499953670131, -27.533055042257217],
              [-54.78951784015004, -27.553890944866964],
              [-54.771255014184192, -27.565135938560502],
              [-54.686117844098646, -27.555282005521974],
              [-54.626945966104245, -27.530835849566131],
              [-54.52972678547161, -27.490835898758746],
              [-54.450835809649902, -27.469173037579367],
              [-54.358063899216461, -27.456946020107011],
              [-54.280281849187588, -27.424517945373339],
              [-54.234172999690884, -27.381390874120086],
              [-54.145554992844666, -27.289999966057792],
              [-54.113754890298651, -27.290835809444957],
              [-54.091946016365341, -27.283335850100627],
              [-54.070835857882912, -27.268054970016863],
              [-54.048617779435688, -27.250281982474007],
              [-54.026390816171102, -27.22499998313134],
              [-54.00583587495646, -27.191108931013218],
              [-53.899726847791044, -27.155281994532402],
              [-53.857781960165255, -27.149172928204848],
              [-53.838755039903958, -27.171117924246005],
              [-53.807781895927178, -27.129173036620045],
              [-53.745563866672455, -27.00278198300839],
              [-53.734172860225215, -26.978055033295618],
              [-53.705281948641414, -26.911663989714214],
              [-53.698617832683908, -26.882917917417558],
              [-53.717090876782095, -26.782646047639901],
              [-53.735835829819791, -26.695835849675689],
              [-53.725835884027333, -26.656672915721572],
              [-53.709445910512557, -26.511108929094746],
              [-53.711735846466468, -26.364099902356429],
              [-53.690972866414484, -26.33486399163678],
              [-53.66889091007522, -26.309999913987724],
              [-53.653890823748554, -26.277499922524441],
              [-53.650281911507079, -26.263055053465791],
              [-53.650008829100955, -26.249999903957303],
              [-53.654172958610161, -26.217635866963917],
              [-53.664308858872403, -26.193335891400181],
              [-53.73861778349152, -26.08778191386466],
              [-53.826881906384585, -25.951390914460603],
              [-53.834726864864649, -25.881390958637894],
              [-53.825690837940101, -25.822782009262312],
              [-53.825281968702029, -25.801390889384663],
              [-53.861663954812741, -25.685281916427016],
              [-53.88861780856729, -25.633755004702266],
              [-54.107572901413221, -25.496391033974874],
              [-54.156108814800405, -25.535554973757371],
              [-54.177508819495586, -25.561946066531164],
              [-54.278681911505032, -25.552746089580069],
              [-54.313335884452499, -25.555281950573885],
              [-54.384035896830198, -25.580699904386449],
              [-54.452508837528342, -25.633054948147048],
              [-54.468890932054421, -25.620555015906575],
              [-54.546390847221176, -25.570282036900437],
              [-54.598917887635707, -25.573226934770446],
              [-54.598917887635707, -25.510455029352102],
              [-54.616455008422463, -25.445690913181522],
              [-54.574308787481868, -25.354446017872988],
              [-54.549590890224522, -25.332226933597326],
              [-54.52444601881831, -25.303199899905451],
              [-54.505699892314055, -25.280417887011026],
              [-54.427508805409218, -25.153055035542309],
              [-54.423546009214988, -25.133126893048995],
              [-54.455417860852435, -25.089726907027853],
              [-54.458335768994118, -25.066526972439817],
              [-54.45361793095941, -25.017226964757128],
              [-54.408054910998885, -24.838890910089106],
              [-54.396390822145349, -24.804445981807646],
              [-54.330554995831847, -24.679445988850659],
              [-54.325008858123283, -24.661946083714099],
              [-54.315563961960777, -24.627781949189682],
              [-54.311808869327592, -24.602781917070729],
              [-54.321535900351876, -24.554790995029521],
              [-54.334308914998473, -24.518473046659153],
              [-54.331390839218955, -24.492782010440465],
              [-54.32333583024473, -24.469726915139688],
              [-54.312499873427385, -24.450554987952899],
              [-54.293617792453233, -24.428890953307132],
              [-54.27597288039118, -24.413335817350685],
              [-54.249446000785554, -24.353546025452601],
              [-54.262990821078745, -24.293264048198637],
              [-54.280135836433999, -24.272635849150106],
              [-54.302645940388686, -24.257226893585425],
              [-54.316672887754009, -24.241672931095366],
              [-54.336672946976648, -24.148054951353117],
              [-54.325835816692916, -24.117499896708182],
              [-54.294308796552912, -24.085417826938397],
              [-54.269581846840197, -24.065691017759889],
              [-54.243899863076876, -24.053608839574849],
              [-54.288608934739671, -24.020281889541934],
              [-54.407226778962752, -23.916673016462767],
              [-54.53945498996228, -23.847500019209747],
              [-54.625699912374728, -23.804926824120116],
              [-54.881454913024754, -23.921108887273704],
              [-54.902572950496278, -23.948963963260212],
              [-54.924726823564981, -23.965282020045834],
              [-55.031390900360236, -23.99444601403593],
              [-55.150281994627619, -24.016391010077086],
              [-55.174999891885022, -24.020554971948116],
              [-55.196390844124664, -24.019308918218599],
              [-55.347499962631673, -23.992635858221092],
              [-55.37125494684966, -23.984446068243699],
              [-55.396664015844635, -23.971108951511724],
              [-55.412090908682217, -23.954308935292033],
              [-55.420281872126168, -23.924726851970433],
              [-55.420564006987831, -23.878335867611895],
              [-55.427781831470497, -23.802781895091414],
              [-55.433063939228191, -23.750555094449794],
              [-55.442154951437885, -23.702708844057724],
              [-55.462990854047632, -23.661390922790986],
              [-55.485008940284501, -23.638055033733281],
              [-55.505281914275542, -23.624172925655273],
              [-55.520835876765489, -23.60528195986376],
              [-55.535208829094813, -23.459172981890958],
              [-55.50242687040793, -23.379717903983703],
              [-55.543608837204488, -23.333055010685314],
              [-55.549655039258141, -23.307017969502681],
              [-55.549172912186009, -23.137500012276064],
              [-55.583890922873707, -23.085281928813572],
              [-55.606390968544588, -23.020281945887007],
              [-55.645008911152729, -22.857917943040491],
              [-55.648399893910948, -22.814926826257533],
              [-55.632854983876371, -22.777426861897993],
              [-55.610072970981889, -22.734099966072847],
              [-55.609172922216317, -22.638473011771794],
              [-55.626108892905734, -22.620908901256712],
              [-55.664172959350481, -22.607226953027066],
              [-55.689726867632885, -22.591117940907168],
              [-55.709445965460418, -22.573890950538839],
              [-55.727226831992311, -22.546254977501803],
              [-55.734308869643087, -22.526255085917001],
              [-55.739726764232671, -22.477782036803831],
              [-55.740835857664024, -22.453608963254396],
              [-55.741254952824022, -22.420146058751754],
              [-55.744308815435431, -22.39750000032717],
              [-55.849726838501056, -22.288890986713682],
              [-55.98889090675948, -22.273335850757121],
              [-56.046672897565429, -22.278335823653379],
              [-56.085563922579809, -22.292155067457315],
              [-56.168199932751008, -22.283891013817424],
              [-56.202917775800586, -22.274726911412017],
              [-56.309172983357655, -22.203335894934355],
              [-56.329445957348696, -22.186673006651347],
              [-56.360835849552359, -22.157709004871435],
              [-56.37083579534459, -22.126108894535719],
              [-56.396526831563278, -22.068681961149252],
              [-56.456945936753584, -22.075835915529467],
              [-56.495281912138068, -22.088308858041671],
              [-56.526117928178223, -22.115417944643525],
              [-56.54597298047571, -22.144445984163781],
              [-56.555972926268282, -22.166672947428481],
              [-56.573890920736346, -22.189446075505543],
              [-56.634172897990368, -22.251946071984037],
              [-56.677781928677291, -22.233681904913738],
              [-56.698126819397601, -22.21610890958118],
              [-56.758054912698412, -22.241390908923734],
              [-56.769526888330745, -22.24804597242553],
              [-56.808335770693645, -22.26472696561973],
              [-56.877499883129303, -22.274173035248737],
              [-56.988890850414577, -22.232782023786228],
              [-57.153335744549167, -22.203608977340764],
              [-57.197781959727479, -22.207226942037494],
              [-57.239581840428002, -22.212499997339933],
              [-57.331946055089702, -22.218055019865915],
              [-57.374590831632702, -22.21778193745962],
              [-57.609446059570701, -22.174999865342329],
              [-57.64000882556644, -22.121108921639575],
              [-57.767781887377623, -22.113055086131737],
              [-57.791672993703571, -22.129172983069111],
              [-57.824863989266476, -22.144026889003783],
              [-57.892226830704828, -22.129026970315408],
              [-57.928890951677204, -22.11778197662187],
              [-57.985108879346228, -22.091826910452312],
              [-57.987226818646434, -22.060835829202858],
              [-57.984026775643315, -22.025691012004359],
              [-57.96333587995889, -21.97222687481235],
              [-57.935672917193529, -21.771945991741347],
              [-57.926399850046494, -21.656663977353162],
              [-57.937781971676372, -21.636526957832132],
              [-57.954308905489711, -21.51389099685349],
              [-57.932499863918565, -21.461672913390942],
              [-57.915555008411729, -21.429172921927659],
              [-57.879172854662841, -21.380281951121049],
              [-57.852645975057214, -21.341664008512964],
              [-57.814590961067836, -20.978755092644633],
              [-57.856672976629966, -20.838609000607562],
              [-57.85958199995423, -20.734726877484036],
              [-57.908890892454394, -20.676391010514749],
              [-57.935908951587521, -20.671463960176368],
              [-57.967745934507718, -20.710135882241048],
              [-57.995972999358457, -20.618054975907398],
              [-58.008826815551799, -20.513472963866889],
              [-58.058890917530164, -20.401108857620386],
              [-58.097226892914762, -20.341390982451628],
              [-58.136946049965161, -20.278890985973135],
              [-58.155835842290173, -20.228617839329047],
              [-58.15889087836814, -20.168055068317983],
              [-58.109726825155121, -20.149172987343832],
              [-57.900835883480397, -20.029026954529456],
              [-57.851872995944234, -19.975508837880739],
              [-58.103335791604081, -19.7602820155779],
              [-58.121117831602419, -19.741390882148323],
              [-58.08722677948424, -19.677226910246702],
              [-57.960554932115372, -19.421108973188268],
              [-57.854999948751583, -19.206390932409363],
              [-57.821399916312487, -19.137217935156343],
              [-57.787226896970822, -19.068054996186959],
              [-57.776672907376792, -19.047499887334425],
              [-57.704726841269405, -19.043608840231343],
              [-57.716664012529179, -18.934445950454688],
              [-57.723035767702982, -18.913608874378269],
              [-57.770281873825695, -18.906108915033997],
              [-57.626945964707261, -18.468055063521604],
              [-57.563335868969148, -18.274726969154131],
              [-57.552363957681848, -18.246808861255488],
              [-57.521117898937234, -18.203891002306364],
              [-57.58167295859721, -18.096108947262337],
              [-57.718890916571013, -17.844999867917466],
              [-57.721117820613017, -17.774999912094756],
              [-57.718172922742895, -17.738581883800123],
              [-57.717281926432918, -17.727581976956174],
              [-57.729454964448678, -17.720555092228551],
              [-57.759173002240232, -17.686946007333972],
              [-57.779481850776847, -17.637746079575436],
              [-57.765835777093002, -17.612781922002114],
              [-57.745508823645366, -17.593890956210544],
              [-57.788063913824033, -17.553608870541325],
              [-57.880835824257417, -17.476945972227782],
              [-57.901672900333722, -17.461946053539123],
              [-57.983555042131343, -17.500690898161793],
              [-58.016672947498307, -17.497635862083825],
              [-58.091663991399741, -17.455555019988083],
              [-58.108890981768297, -17.438890958238517],
              [-58.120563955439081, -17.412782000326104],
              [-58.249999819206835, -17.326117982753999],
              [-58.29444603438526, -17.299726889980093],
              [-58.321946052952342, -17.292917934735385],
              [-58.351108873476164, -17.287226957739392],
              [-58.371117817516165, -17.278890987370175],
              [-58.390417820183984, -17.259581932247158],
              [-58.403063932816167, -17.226390936684254],
              [-58.409445913911838, -17.190554947747671],
              [-58.406673012695478, -17.138335858456912],
              [-58.40736401679527, -17.11208189361929],
              [-58.42444600023839, -17.009726953258678],
              [-58.435146002586009, -16.970973056180696],
              [-58.445135890094434, -16.952781979306337],
              [-58.458754974050009, -16.924445949714197],
              [-58.463481864540086, -16.900690965496437],
              [-58.476390833656467, -16.721672959183991],
              [-58.475772919752728, -16.674635897726944],
              [-58.467917902988802, -16.644026863625356],
              [-58.431672877176311, -16.589999965452478],
              [-58.419446027342246, -16.573608986109207],
              [-58.397781992696309, -16.55305505072289],
              [-58.362090843047099, -16.525835825913077],
              [-58.34639086780345, -16.506108849096393],
              [-58.32444603940047, -16.396117827283547],
              [-58.325835758950916, -16.306663977050505],
              [-58.327508954467362, -16.279163958483366],
              [-58.388890972697141, -16.282917877649993],
              [-58.400699900837992, -16.305555051257272],
              [-58.415008815426745, -16.319999920316093],
              [-58.439173004158761, -16.329726951340206],
              [-58.541946033850934, -16.326664036273201],
              [-58.972781893242654, -16.310835817910629],
              [-59.489726888215102, -16.290835926325826],
              [-59.532781875100568, -16.289172956731193],
              [-60.005563963222642, -16.269999856077959],
              [-60.106673017491801, -16.265835894206873],
              [-60.160281993970955, -16.263054946363582],
              [-60.18430888712868, -15.980417841050212],
              [-60.227226913715924, -15.478608886874326],
              [-60.43972676763218, -15.241390968062717],
              [-60.5713999290017, -15.097500009314331],
              [-60.384726898135796, -15.09249986878018],
              [-60.292499811410607, -15.094445979064744],
              [-60.258899946609574, -15.09360896221142],
              [-60.259999819947325, -15.036663988258852],
              [-60.264454969135556, -14.913608932120439],
              [-60.276117884522478, -14.706663933092159],
              [-60.281108804963367, -14.623335912992843],
              [-60.346508939948535, -14.483417976360727],
              [-60.427472928069449, -14.324299949347107],
              [-60.450517797448356, -14.291126891056933],
              [-60.481999890587076, -14.167863963718901],
              [-60.464863927687361, -14.101808866817862],
              [-60.42583577473664, -14.067081971312518],
              [-60.400554948860588, -14.029726846240408],
              [-60.384499916197399, -13.987255072179494],
              [-60.47680881029811, -13.803472959727117],
              [-60.583335759156739, -13.768608936285602],
              [-60.675564019348712, -13.738608931270448],
              [-60.744290931713635, -13.682364013873212],
              [-60.770655034759159, -13.683299937184415],
              [-60.79159990211059, -13.679454990549118],
              [-60.957781861864134, -13.582781974728789],
              [-61.004173013860793, -13.540835913636613],
              [-61.038981884379268, -13.493117906363523],
              [-61.261645969390543, -13.523090921650407],
              [-61.492781979241045, -13.553335845876973],
              [-61.521135778467851, -13.550381895551766],
              [-61.577499886528642, -13.525972955246345],
              [-61.593526923635295, -13.507235881197516],
              [-61.616390911543135, -13.513054933674198],
              [-61.730181952990222, -13.536882002259745],
              [-61.810064005046058, -13.5486639406721],
              [-61.840308929272965, -13.538745969892943],
              [-61.868445972482959, -13.480882004073749],
              [-61.874181876480066, -13.45394608759193],
              [-61.944454914709127, -13.395835861456931],
              [-61.972946009510395, -13.375426932996106],
              [-62.002499929637167, -13.36221789174499],
              [-62.085555034968365, -13.271390918129839],
              [-62.109726767413349, -13.183335839902554],
              [-62.1136729674393, -13.153708829579784],
              [-62.213754903290294, -13.120972971360629],
              [-62.244590919330619, -13.12667300081182],
              [-62.268526785019617, -13.140890887931903],
              [-62.330835841742953, -13.147781985827862],
              [-62.384726785446105, -13.145835875543128],
              [-62.419217814195065, -13.132517869550441],
              [-62.645481926630282, -13.03236401696995],
              [-62.645863973778035, -13.007363984850883],
              [-62.688254946292261, -12.968599861850976],
              [-62.769726877747019, -13.005835796259589],
              [-62.843846036077821, -12.942335838729605],
              [-62.896663928533258, -12.900281986362074],
              [-62.973764026917479, -12.85289992576935],
              [-62.994172955378303, -12.839335827098921],
              [-63.049172992512524, -12.74750000761469],
              [-63.062299891112275, -12.697890875341784],
              [-63.067099871798177, -12.659872909364651],
              [-63.133345908454146, -12.635881890752842],
              [-63.163335855185551, -12.648890939793603],
              [-63.216108820639761, -12.680899919367505],
              [-63.247090849433619, -12.705835913745943],
              [-63.324872899462491, -12.704208986334891],
              [-63.394617877789926, -12.668363944943223],
              [-63.415555033790326, -12.653890912689747],
              [-63.464364029583749, -12.595917814490747],
              [-63.471390914311314, -12.570417885665222],
              [-63.671954938072417, -12.470555052763586],
              [-63.797499922375323, -12.459999889703454],
              [-63.832508952742216, -12.474163965004891],
              [-63.870563966731254, -12.493890941821576],
              [-63.898608809006532, -12.52860895250933],
              [-63.927963910389337, -12.547272936362162],
              [-64.020008942177299, -12.537081883176825],
              [-64.039517821872778, -12.51799092517507],
              [-64.176390948348967, -12.485554971452189],
              [-64.23889094482746, -12.479172990356631],
              [-64.293308942603289, -12.482072961225242],
              [-64.373063925006932, -12.468335860072557],
              [-64.399445965325413, -12.458090995068915],
              [-64.421399846183931, -12.441108923911884],
              [-64.49694593971509, -12.366255007946791],
              [-64.51653578859532, -12.242217928029334],
              [-64.617654901148512, -12.210973042750993],
              [-64.738617834248601, -12.150072983955283],
              [-64.763335899143897, -12.097781977935028],
              [-64.791117884934465, -12.041526834615752],
              [-64.809590929032822, -12.028608980681838],
              [-64.835846067336718, -12.023199970909559],
              [-64.896117818668642, -12.023335925379399],
              [-64.984308851365824, -12.011108907907101],
              [-65.008345970445248, -11.989446046727835],
              [-65.01695502322093, -11.969999863668193],
              [-65.035572906606319, -11.881673044139234],
              [-65.043335890073024, -11.809717925576479],
              [-65.062372868618013, -11.754864068834024],
              [-65.118381919259321, -11.698954930478976],
              [-65.14951783979609, -11.772154929304861],
              [-65.191746035749873, -11.752017909783888],
              [-65.209163965873245, -11.711672959840712],
              [-65.200835874493123, -11.68555494947293],
              [-65.188335774614643, -11.656390955483005],
              [-65.169455034744828, -11.614726861614258],
              [-65.222926883287812, -11.518755075815648],
              [-65.241535881855782, -11.505973008713454],
              [-65.289735848562714, -11.504999869752055],
              [-65.310281904959822, -11.49413591737806],
              [-65.348345971404626, -11.404726826507954],
              [-65.390708948361976, -11.274726860654937],
              [-65.401946063066532, -11.16972692692093],
              [-65.39361780404812, -11.149446073940908],
              [-65.343335772586443, -11.058054998240436],
              [-65.302655043601305, -10.985555055969712],
              [-65.315845979941514, -10.880835915992861],
              [-65.3291909756623, -10.851891024952408],
              [-65.390017944262297, -10.755835923035875],
              [-65.438064019226545, -10.618754925360406],
              [-65.441973003602243, -10.473481958412663],
              [-65.402363984759916, -10.442217962394977],
              [-65.391254945536502, -10.413755030788536],
              [-65.395809504096576, -10.393458922744685],
              [-65.388899966013525, -10.372499973795982],
              [-65.378199963666077, -10.354863946551234],
              [-65.347926876244799, -10.325554945635872],
              [-65.3247267740187, -10.306808986769624],
              [-65.311526785223009, -10.28875503783172],
              [-65.289172919944008, -10.217781943047498],
              [-65.288617870314113, -10.195417851846514],
              [-65.303754916938829, -10.100835785598349],
              [-65.313064026269728, -10.075281877315888],
              [-65.330417918652529, -10.043890979284072],
              [-65.336454900612466, -9.947082008993959],
              [-65.322717799459781, -9.903546068502862],
              [-65.301808806654265, -9.842154997817715],
              [-65.341946053036168, -9.762499927700162],
              [-65.37695491576477, -9.703335928694855],
              [-65.406881998222332, -9.686254951080116],
              [-65.442335772372871, -9.679191018340305],
              [-65.492508839092807, -9.719163979419534],
              [-65.512217878636591, -9.741390942684291],
              [-65.515699888863367, -9.773964024343343],
              [-65.569308865342521, -9.835135824440954],
              [-65.605981871132428, -9.83492694741318],
              [-65.745835770024314, -9.770554936674216],
              [-65.792235806838335, -9.774999859940635],
              [-65.843899846499255, -9.777781981250484],
              [-65.971955043172045, -9.779726918068661],
              [-66.077645981006071, -9.775555077208537],
              [-66.174999942642046, -9.797226823205392],
              [-66.383346060608858, -9.866664018047288],
              [-66.43709099155808, -9.888608846450211],
              [-66.479317846407582, -9.881808943661099],
              [-66.545699837533618, -9.887917842350475],
              [-66.630481950199737, -9.905763920089271],
              [-66.640254914053571, -9.91827290478534],
              [-66.642508807823674, -9.930135812382559],
              [-66.659235901485374, -9.948545992206732],
              [-66.693481843384802, -9.963191021113744],
              [-66.732226855645592, -9.976946059539159],
              [-66.760281923842456, -9.990835878968085],
              [-66.893755015105, -10.09152684390574],
              [-66.925281867606827, -10.123190991982085],
              [-66.950008817319713, -10.151390899466094],
              [-66.970835835111927, -10.172226802076011],
              [-67.046399865916214, -10.243608933736255],
              [-67.07695492056115, -10.268754978608854],
              [-67.183208954652002, -10.323681925547078],
              [-67.225417872228491, -10.311946087602337],
              [-67.300290898932928, -10.316663925636703],
              [-67.323272904037822, -10.323473048519304],
              [-67.326672939251239, -10.35896403832021],
              [-67.449446028166392, -10.44166391859369],
              [-67.509172955790518, -10.472781901857445],
              [-67.529173015013271, -10.481108987409471],
              [-67.582508909086357, -10.50444604993379],
              [-67.609735845247144, -10.530135912686092],
              [-67.674999858124636, -10.619999973261542],
              [-67.703335887716719, -10.694726819574385],
              [-67.742372925484574, -10.712917896448744],
              [-67.787226836434854, -10.684163945163164],
              [-67.817508808673495, -10.665282031827076],
              [-67.836681909326558, -10.659308919969419],
              [-67.865846070954944, -10.656946061457688],
              [-68.017646025923625, -10.660281891292755],
              [-68.061108876218668, -10.676390903412596],
              [-68.082226913690192, -10.690281896307965],
              [-68.10196394879074, -10.705281982634517],
              [-68.118963957220558, -10.731945990176541],
              [-68.124599948931518, -10.759308880807282],
              [-68.230282001947785, -10.911664053043978],
              [-68.280908864906905, -10.979726951037549],
              [-68.313064024872745, -10.996663927555232],
              [-68.344035827744676, -11.006872918013528],
              [-68.402508822650248, -11.017781965026643],
              [-68.522572880451435, -11.059308930959162],
              [-68.552226880502587, -11.088891014280762],
              [-68.576454939336998, -11.103073026855213],
              [-68.620835775670287, -11.116390865209723],
              [-68.654726827788636, -11.124446041821727],
              [-68.700563935983837, -11.134726949009348],
              [-68.766708886887272, -11.137117970716019],
              [-68.761835816005487, -11.003317817590585],
              [-68.848890933181224, -11.01610893714826],
              [-69.076126843473048, -10.967226851159069],
              [-69.222781986258383, -10.95083587181557],
              [-69.41222691251204, -10.937781895773583],
              [-69.447645985583449, -10.947635828812281],
              [-69.486954932291212, -10.951108954221866],
              [-69.540555023953118, -10.952217880014928],
              [-69.567508877707667, -10.950555078058528],
              [-69.58611787627575, -10.950281995652063],
              [-69.666117945528754, -10.953335858263529],
              [-69.688608938744323, -10.958608913565797],
              [-69.737917831244658, -10.961599911903477],
              [-69.780208891472455, -10.924717861447846],
              [-69.925981922765175, -10.914026911555936],
              [-69.950008815922729, -10.919999855775302],
              [-69.969454998981973, -10.928608908550984],
              [-69.999999827705324, -10.946945998179046],
              [-70.051681972276981, -10.979172907236148],
              [-70.154172867107718, -11.029999930043687],
              [-70.174999884899933, -11.037499889387902],
              [-70.226526796624739, -11.052781942938168],
              [-70.32889984189643, -11.069172922281666],
              [-70.352781895766668, -11.066108833748274],
              [-70.402508877598166, -11.045555066000134],
              [-70.436673012122583, -11.026946067432277],
              [-70.456945986113681, -11.001664068089383],
              [-70.47111794040427, -10.981108959236906],
              [-70.499308963070973, -10.95347298620004],
              [-70.52639994476192, -10.935690946201703],
              [-70.581681949119911, -10.971108845806498],
              [-70.631390993678508, -11.00916402743394],
              [-70.631954928125822, -10.975282027771129],
              [-70.631954928125822, -10.913054946060925],
              [-70.630846002332589, -10.781663919552841],
              [-70.630281900247496, -10.608608966814472],
              [-70.62945494167775, -10.33860892167786],
              [-70.629181859271682, -10.186390877377448],
              [-70.62945494167775, -10.068891011402854],
              [-70.628617924824312, -9.948491006921699],
              [-70.62889083959243, -9.895835891026479],
              [-70.625563894574952, -9.825972895501991],
              [-70.612646040641039, -9.798755011796629],
              [-70.595290974791624, -9.781390893491562],
              [-70.590835825603392, -9.631599913081459],
              [-70.607590914821458, -9.575663952636262],
              [-70.57722679993168, -9.538608899698716],
              [-70.54529091055349, -9.500835852932994],
              [-70.514663939179002, -9.427999963981847],
              [-70.56806403863078, -9.433473011494641],
              [-70.588617806378807, -9.440835842902686],
              [-70.643335876289484, -9.490835907140593],
              [-70.779172999530203, -9.605554992909958],
              [-70.843608880371164, -9.64555494371757],
              [-70.881954914039625, -9.669999926206629],
              [-70.969317814700901, -9.763890988355172],
              [-70.985354910091019, -9.796735811315784],
              [-71.01396402208951, -9.820835794669449],
              [-71.138545925714766, -9.863817858997095],
              [-71.158899868890558, -9.889999907105221],
              [-71.180417890782905, -9.932226929592787],
              [-71.296390909270656, -9.995417930170731],
              [-71.368055008154386, -10.003890860838396],
              [-71.459163948993194, -10.004163943244578],
              [-71.641954985211441, -10.004446078106298],
              [-71.930281894054303, -10.004446078106298],
              [-71.979172864860686, -10.004446078106298],
              [-72.010281963307307, -10.004446078106298],
              [-72.052226850933096, -10.004717819408029],
              [-72.143890841401799, -10.004717819408029],
              [-72.185835896665367, -10.00249996782162],
              [-72.168335823890857, -9.954446013868278],
              [-72.150772886842049, -9.88701796122325],
              [-72.164735796466744, -9.834717902747343],
              [-72.180281879967708, -9.803399927273233],
              [-72.226526851572828, -9.777781981250484],
              [-72.260355039416993, -9.759163930227146],
              [-72.269308923690119, -9.730554985866775],
              [-72.257646008303027, -9.692572894435443],
              [-72.252508907470485, -9.656945950164641],
              [-72.301526780291681, -9.533191005108961],
              [-72.371672916506327, -9.492635837033561],
              [-72.402235850140187, -9.483608862564381],
              [-72.606946066137539, -9.446108898204955],
              [-72.730008833627153, -9.413055030578334],
              [-72.840835866465113, -9.411663969923666],
              [-72.861954909764734, -9.412226898542485],
              [-72.980835778110531, -9.410835837887475],
              [-73.200699972177119, -9.400763975365692],
              [-73.182926816996314, -9.364999903158662],
              [-73.158063912813532, -9.344445967772515],
              [-73.119445970205561, -9.313335863497684],
              [-73.023617850227652, -9.221108944410275],
              [-72.966399961507307, -9.143054985441893],
              [-72.955290922283552, -9.119164046754065],
              [-72.949172971138751, -9.090135839595803],
              [-72.948064045345518, -9.066526868131859],
              [-72.951126792774346, -9.045835804809258],
              [-72.962363907478903, -8.988472909163306],
              [-73.005763893499932, -8.930346086859799],
              [-73.058817820349361, -8.90242680549477],
              [-73.121945956653519, -8.807499907748991],
              [-73.132781913470865, -8.790555052242098],
              [-73.141954900693861, -8.76222690163911],
              [-73.15250889028755, -8.73499996547821],
              [-73.166672965589214, -8.714164062868349],
              [-73.1838999559576, -8.700135942036638],
              [-73.215563936395768, -8.686108827033422],
              [-73.240981890208388, -8.679582006650435],
              [-73.273335868917741, -8.674235861152226],
              [-73.345699856718738, -8.602364058707167],
              [-73.404726895425938, -8.450555051282834],
              [-73.478617898351558, -8.398608876760079],
              [-73.533199846154162, -8.352363905155187],
              [-73.541399862053481, -8.302781930248955],
              [-73.556673030786328, -8.24917295376963],
              [-73.595699842632655, -8.121391007141142],
              [-73.651673018727934, -8.016663988175139],
              [-73.709872931227494, -7.983890914305675],
              [-73.756326779860103, -7.967082013268737],
              [-73.771955006012377, -7.948054925369092],
              [-73.777017843182591, -7.872881994168267],
              [-73.725390851533746, -7.87958198467129],
              [-73.69819995755654, -7.846181944442606],
              [-73.693755034290405, -7.8033358345848],
              [-73.706664003406786, -7.776390865647556],
              [-73.724163908543517, -7.764445983036978],
              [-73.751954946789567, -7.756946023692649],
              [-73.821535807452392, -7.731664024349982],
              [-73.842226870774823, -7.717363994578648],
              [-73.851954907627544, -7.69958195458031],
              [-73.865563933299256, -7.675281979016404],
              [-73.932508853043885, -7.609164017841351],
              [-73.974163894457263, -7.574446007153597],
              [-74.004590873621396, -7.554373025372854],
              [-74.002154924913839, -7.530454929318807],
              [-73.987155006225066, -7.528835880896736],
              [-73.962435767863326, -7.527364018695266],
              [-73.930526868213576, -7.442990942905169],
              [-73.931117792389045, -7.359164031927378],
              [-73.738199908364152, -7.333954955142758],
              [-73.705835871370766, -7.309235884419081],
              [-73.719999946672203, -7.223054999746921],
              [-73.775563918253738, -7.137217940744222],
              [-73.795135829861181, -7.122626891293578],
              [-73.799726933519139, -7.08083589541053],
              [-73.754454933237866, -6.89416403801124],
              [-73.744173020221638, -6.876946100098223],
              [-73.676390915985507, -6.804445990189322],
              [-73.587508879188704, -6.734999910530178],
              [-73.361190954934841, -6.581990949843885],
              [-73.335008906826829, -6.574446063498499],
              [-73.298763881014111, -6.574446063498499],
              [-73.276399957451304, -6.574999939661836],
              [-73.216673029827177, -6.566663969292676],
              [-73.186117807544122, -6.547363966624971],
              [-73.160846034123438, -6.515554979261253],
              [-73.145008930943447, -6.489164054125695],
              [-73.123899945927405, -6.447217825395342],
              [-73.120008898824267, -6.418055004871633],
              [-73.118617838169428, -6.381526838369155],
              [-73.166399883182976, -6.240281878822543],
              [-73.218608914189986, -6.146255029842109],
              [-73.225572934643765, -6.121390952193053],
              [-73.229735890686527, -6.093608966402371],
              [-73.218063922844067, -6.045281930042847],
              [-73.205563990603594, -6.017499944252165],
              [-73.130281927022963, -5.876390939175394],
              [-73.082226799603347, -5.827217833507177],
              [-73.048064006183267, -5.791945946656028],
              [-72.988890954722706, -5.711663910179198],
              [-72.961955038240887, -5.64860886407098],
              [-72.942790990043193, -5.537499864009305],
              [-72.952563953896629, -5.510281980303887],
              [-72.964172889827353, -5.47722693921088],
              [-72.961399820972986, -5.452499989498108],
              [-72.918335781632038, -5.321664012620033],
              [-72.898617857270835, -5.172781965792808],
              [-72.851964016428042, -5.12471795355566],
              [-72.786663961366912, -5.088891017074843],
              [-72.75667300880724, -5.076945966826088],
              [-72.617508940548817, -5.020281954268967],
              [-72.476964037557309, -4.941108843585198],
              [-72.403335891116001, -4.874517807793609],
              [-72.390563882297727, -4.838608896299434],
              [-72.389590910974391, -4.805690983142711],
              [-72.375290881202829, -4.784446043656772],
              [-72.325290984602816, -4.7608358987261],
              [-72.300981956583655, -4.756990952090803],
              [-72.279172915012623, -4.763608967580467],
              [-72.253617833263547, -4.76166403076229],
              [-72.234999949878443, -4.753335939381941],
              [-72.129181942392051, -4.693608844119638],
              [-72.028064003305644, -4.612499849073288],
              [-71.902481970990436, -4.518190865231304],
              [-71.881972962605573, -4.510863908369174],
              [-71.747190953339611, -4.49035506762209],
              [-71.726681944954407, -4.493282028219028],
              [-71.650564037986783, -4.50889097599412],
              [-71.382235847262336, -4.43083584355918],
              [-71.285935826134391, -4.381035939169749],
              [-71.169445979495492, -4.357782025124948],
              [-71.065145934678526, -4.362917952491159],
              [-71.036655007515321, -4.381935820296974],
              [-70.95626400629709, -4.382217955158751],
              [-70.872554944878175, -4.280117824655463],
              [-70.868899932169541, -4.251108895874381],
              [-70.832226926379406, -4.187017846530807],
              [-70.810290982793845, -4.170554950457927],
              [-70.762435847584356, -4.147708899822987],
              [-70.62569984904485, -4.160555004665525],
              [-70.584163998295082, -4.19555498257688],
              [-70.499999967170822, -4.17971787939689],
              [-70.465290841300487, -4.154726899733248],
              [-70.429381929806198, -4.1320178093967],
              [-70.320145949833432, -4.139717928589278],
              [-70.299726795450624, -4.174164030337238],
              [-70.298335902433905, -4.199026934519907],
              [-70.308763995841502, -4.230281878082053],
              [-70.301390938511759, -4.249999970081149],
              [-70.255846023462198, -4.2913909815436],
              [-70.197826824795868, -4.332654923353573],
              [-70.016963961589454, -4.337946083566919],
              [-69.979590899244499, -4.312499966559642],
              [-69.960563978982805, -4.283190965644167],
              [-69.950290950784506, -4.262499902321736],
              [-69.956926903546787, -4.236872903843505],
              [-69.943335815148032, -4.212364051251882],
              [-69.881954970384527, -3.920281882137942],
              [-69.793063881132355, -3.419163932061792],
              [-69.635281929488656, -2.53444600279488],
              [-69.625835859859535, -2.482217861048525],
              [-69.607790963376885, -2.386390914537287],
              [-69.594181937705173, -2.311108850956714],
              [-69.522508954004024, -1.914164043683115],
              [-69.452635900195787, -1.526035825108181],
              [-69.391046010766843, -1.381526952455943],
              [-69.378545910888192, -1.33791792176919],
              [-69.390017886520184, -1.246108924375221],
              [-69.41195500357253, -1.131946061702251],
              [-69.431399845527437, -1.040972907695277],
              [-69.441972945860471, -1.01780884765293],
              [-69.434235778655591, -1.001182001553445],
              [-69.480846033601722, -0.95499989422251],
              [-69.517781895875942, -0.933472987513198],
              [-69.61264592934765, -0.755835815933835],
              [-69.615290922721215, -0.724308963431952],
              [-69.596317814278507, -0.700490947302001],
              [-69.582781878802734, -0.668054993579233],
              [-69.573546027305895, -0.634926862290285],
              [-69.606535857191943, -0.519863950851914],
              [-69.630417911062182, -0.494164029815749],
              [-69.688617823561856, -0.466808850535926],
              [-69.717090981090223, -0.460973034252731],
              [-69.73945490465303, -0.45569092649481],
              [-69.933481882109504, -0.304308893219513],
              [-70.02166402998904, -0.203608875826433],
              [-70.05806395337288, -0.157499858691608],
              [-70.057508903742985, -0.088054952498624],
              [-70.055526918912676, 0.000000125728775],
              [-70.052508930846614, 0.143609117253618],
              [-70.049454900597027, 0.306945085594975],
              [-70.048335916519989, 0.368335988642343],
              [-70.046399864519344, 0.511391104003565],
              [-70.044726836640677, 0.585000139705528],
              [-69.983472893891928, 0.597917993639442],
              [-69.922499912538058, 0.597782039169715],
              [-69.79570887450592, 0.601527073519037],
              [-69.749999841791407, 0.633891110512309],
              [-69.683481896195531, 0.677218006337569],
              [-69.459281911242044, 0.736600102464081],
              [-69.328899898241332, 0.65639115618319],
              [-69.28750888677888, 0.622918025758793],
              [-69.209781989673331, 0.616309062724326],
              [-69.127817872862636, 0.64402701077455],
              [-69.159308850818775, 0.752917991611753],
              [-69.140290983012676, 0.863473115509805],
              [-69.227917913624538, 0.999027098060537],
              [-69.264872886638102, 1.033891121501881],
              [-69.338481922340009, 1.072918100986271],
              [-69.376817897724607, 1.056527121642659],
              [-69.435290892630064, 1.03833604476813],
              [-69.613617894842662, 1.078054028352312],
              [-69.718335861353182, 1.072500011654768],
              [-69.842226928516936, 1.07221804443094],
              [-69.842226928516936, 1.698054020240193],
              [-69.846099870709054, 1.710454040194861],
              [-69.784946007884344, 1.707573180065367],
              [-69.700563879638707, 1.745282189090403],
              [-69.553899852035954, 1.78389107924329],
              [-69.459163894045275, 1.760282107779062],
              [-69.400281862263512, 1.744445004599072],
              [-69.372472886744617, 1.728054025255688],
              [-69.240008808989046, 1.728054025255688],
              [-69.103055048604233, 1.727500149092066],
              [-68.82889992641384, 1.726109088437511],
              [-68.153063886366567, 1.724164151619163],
              [-68.182645969688167, 1.76652696093845],
              [-68.211535875443531, 1.771873106436658],
              [-68.232790873213503, 1.775553935407345],
              [-68.257235855702561, 1.852782109272653],
              [-68.236955002722539, 1.909718030769625],
              [-68.226672922068531, 1.93360913709563],
              [-68.196399834647025, 1.977500135006039],
              [-68.174455006244216, 1.977500135006039],
              [-68.124026794390886, 1.963744928942447],
              [-68.104863919659294, 1.951809098787294],
              [-68.088481992771506, 1.927082149074408],
              [-68.075146049506088, 1.872082111940358],
              [-68.0655640254069, 1.853335985436104],
              [-68.035281885530082, 1.801391152017629],
              [-68.010146066579296, 1.768473071223127],
              [-67.990708935975363, 1.753745061474262],
              [-67.96778191615573, 1.745836065254025],
              [-67.941808912713441, 1.742500067780725],
              [-67.91473586829494, 1.745282189090403],
              [-67.872508845807545, 1.755135954491209],
              [-67.811672992390299, 1.784309000936787],
              [-67.771045907585517, 1.818818134596768],
              [-67.687226875596707, 1.919582189730193],
              [-67.676955020864796, 1.936944966930355],
              [-67.666399857804493, 1.973336005496776],
              [-67.585981866857935, 2.053053939888173],
              [-67.565846020803008, 2.06721801518961],
              [-67.505846010772984, 2.106945051229104],
              [-67.422572975958701, 2.142845077906031],
              [-67.339726915293227, 2.113053949918594],
              [-67.325426885522006, 2.088473180597646],
              [-67.319735908525956, 2.063054053318695],
              [-67.308899951708611, 2.032782139363746],
              [-67.264726818936253, 1.946945080361047],
              [-67.228199825900276, 1.891809088757043],
              [-67.20659982899474, 1.875836031106985],
              [-67.160846036917405, 1.818609089930931],
              [-67.113617868067195, 1.73500010843668],
              [-67.071181968552139, 1.620409098148002],
              [-67.056881938780577, 1.520136054904071],
              [-67.06360891901204, 1.438336055757873],
              [-67.073899884483467, 1.414444949432095],
              [-67.080417820048751, 1.385282128908273],
              [-67.087781992561077, 1.286391093863756],
              [-67.087781992561077, 1.266391034640947],
              [-67.084164027864574, 1.193891092370336],
              [-67.075290945138306, 1.17250014013058],
              [-67.032781955426913, 1.182500085922925],
              [-66.885290969254925, 1.221100091258279],
              [-66.870455000592983, 1.22092708877625],
              [-66.787226892779756, 1.149445044829974],
              [-66.33805494958807, 0.765836013183616],
              [-66.314726771881396, 0.75139114412508],
              [-66.286955012012413, 0.747782064245484],
              [-66.12709082797619, 0.734164153756467],
              [-65.979590956986272, 0.798745041523205],
              [-65.955145974497043, 0.818336063869822],
              [-65.929035843118584, 0.854027045880912],
              [-65.912781991711256, 0.871809085879249],
              [-65.870835762981073, 0.90833607891517],
              [-65.735290832885767, 0.982782131470628],
              [-65.657226815633578, 0.989717988729808],
              [-65.595426875710302, 0.990418045284969],
              [-65.559446047486801, 0.971108990161781],
              [-65.53973583447663, 0.950000005146023],
              [-65.522090922414463, 0.926945077483197],
              [-65.510135813881902, 0.901945045364073],
              [-65.506955049255907, 0.871809085879249],
              [-65.511117837660777, 0.838891172722356],
              [-65.52737286253415, 0.806664096027077],
              [-65.56750893544978, 0.764718034935186],
              [-65.582572891878783, 0.729791147219657],
              [-65.575563944424005, 0.696391106990802],
              [-65.558617915450611, 0.666109134752105],
              [-65.542363896405504, 0.651664098055335],
              [-65.521663948265484, 0.649164111607092],
              [-65.485772974044323, 0.661873088513346],
              [-65.436108856486385, 0.695282181197683],
              [-65.400281920005398, 0.753953994847109],
              [-65.398346035642419, 0.789027062954347],
              [-65.395008864703129, 0.818609146276117],
              [-65.385845935763939, 0.837082190374076],
              [-65.32167291140729, 0.911944991156588],
              [-65.291464029364192, 0.926600078347462],
              [-65.253064016239193, 0.915282162096162],
              [-65.2276460624268, 0.915000027234555],
              [-65.192508956579047, 0.926664116088091],
              [-65.173890905555595, 0.950000005146023],
              [-65.165845954865574, 0.969445014738938],
              [-65.160845981969317, 0.989026984630016],
              [-65.154446063600687, 1.035282182157005],
              [-65.153899898788382, 1.055553982681431],
              [-65.146526841458581, 1.097635998243618],
              [-65.136264039181867, 1.118473074319752],
              [-65.10389094973317, 1.14208204578398],
              [-65.006954909790409, 1.165836024173018],
              [-64.943072905112786, 1.210553980653174],
              [-64.81907287320746, 1.279482058695066],
              [-64.783063881788905, 1.260000168728027],
              [-64.757235885272053, 1.244309078301399],
              [-64.731746014730106, 1.246945019219595],
              [-64.697508957647926, 1.262782122399756],
              [-64.595290977585307, 1.330000124550793],
              [-64.578964035982551, 1.349791139107538],
              [-64.56319985536021, 1.391391027597933],
              [-64.549035780058603, 1.415135953531887],
              [-64.529726892573592, 1.433608997630017],
              [-64.3943088644927, 1.51180913699028],
              [-64.365773010328496, 1.507573090751521],
              [-64.264454911393443, 1.476109102523537],
              [-64.113208832588043, 1.582918018605994],
              [-64.077990925193546, 1.635418069291973],
              [-64.062781961839107, 1.681108997095578],
              [-64.049863940267414, 1.806809046607668],
              [-64.052372979170684, 1.840836053195517],
              [-64.05189085209895, 1.890482065842548],
              [-64.030146015906041, 1.924718117096461],
              [-64.002364030115473, 1.949864161969117],
              [-63.970555042751698, 1.967218054351974],
              [-63.936526862697576, 1.976809130906418],
              [-63.825008825759596, 1.977782102229298],
              [-63.739172940223398, 2.003054043288444],
              [-63.718608946553331, 2.023609152141148],
              [-63.607090909615749, 2.105973085734206],
              [-63.545417871707002, 2.13000014652971],
              [-63.48833576981815, 2.130554022693332],
              [-63.399446021670315, 2.149509026225246],
              [-63.380008891066382, 2.229164096342743],
              [-63.360763873683709, 2.259100063617382],
              [-63.346390921354555, 2.406109090355812],
              [-63.365417841616022, 2.420000083251125],
              [-63.581672955803299, 2.434718034716184],
              [-63.78219993155227, 2.427164095915316],
              [-63.821808950394598, 2.425836067172497],
              [-64.034035889542452, 2.471318117948215],
              [-64.04646390505377, 2.509445048666748],
              [-64.031254941699274, 2.549718081880656],
              [-64.01722682086762, 2.57389115543009],
              [-63.995417779296361, 2.623054035176494],
              [-63.987854955678074, 2.718609072748109],
              [-64.034726893642471, 2.832782161343061],
              [-64.088899972206889, 2.940835957688535],
              [-64.146117860927575, 3.039718107915746],
              [-64.158890875574116, 3.060835977749207],
              [-64.205290912388136, 3.193336097688416],
              [-64.226672979810417, 3.337500138842984],
              [-64.235699954279369, 3.432782094008303],
              [-64.224381870389891, 3.466945055066276],
              [-64.193481984247683, 3.501391156814293],
              [-64.184717866262702, 3.527782081950193],
              [-64.182372945024042, 3.549308988659675],
              [-64.190217903503765, 3.58965393860268],
              [-64.30806394208048, 3.718336101634975],
              [-64.36584593288643, 3.750553958236594],
              [-64.405290834064147, 3.767218019986274],
              [-64.44632678810683, 3.787009034543246],
              [-64.518063977186671, 3.854444966177425],
              [-64.626681875617578, 3.965553966238929],
              [-64.642090831182031, 3.984309145198495],
              [-64.660981964611892, 4.016664129736455],
              [-64.69445492739834, 4.081945074058183],
              [-64.704726782130251, 4.118609027392665],
              [-64.713135842695635, 4.144026981205116],
              [-64.733890943758809, 4.161245086756253],
              [-64.785908867372768, 4.185836081999014],
              [-64.801955015218596, 4.210835946479961],
              [-64.800008904933634, 4.26507306278495],
              [-64.781672988772186, 4.286391092466943],
              [-64.746263973984583, 4.287218051036291],
              [-64.724172965189837, 4.281391119570799],
              [-64.693072919198812, 4.267217991813652],
              [-64.674726777115211, 4.257500013244794],
              [-64.651954990142826, 4.239164097083233],
              [-64.642155036560553, 4.223773078791396],
              [-64.607790909826008, 4.145273202572639],
              [-64.592017844386248, 4.127773129798129],
              [-64.552926827161741, 4.122364120025793],
              [-64.467508863318812, 4.133891080942931],
              [-64.430281981365567, 4.140000147270371],
              [-64.402235797985952, 4.149164082037657],
              [-64.379863995434164, 4.153609005303963],
              [-64.335554908192023, 4.154164222571808],
              [-64.24889994307506, 4.148053982777867],
              [-64.221117789646541, 4.141109073063546],
              [-64.126390884110833, 4.109582052923599],
              [-64.10889097897433, 4.082782090911849],
              [-64.087781993958288, 4.032773141856239],
              [-64.064590944187557, 3.95528211150679],
              [-64.052708925850936, 3.908435989804445],
              [-64.017790922953054, 3.8861091142536],
              [-63.994164014215812, 3.882918123705906],
              [-63.956255012980478, 3.891245041619641],
              [-63.940563922553906, 3.90458215835173],
              [-63.909026844130267, 3.933053974775532],
              [-63.848063921060373, 3.958891023748038],
              [-63.644454919505279, 3.948609110732093],
              [-63.614172947266468, 3.944864076382771],
              [-63.589172915147628, 3.928335969102989],
              [-63.573617779191181, 3.909718085717429],
              [-63.547917858155074, 3.880691052025895],
              [-63.530135818156737, 3.867217980824137],
              [-63.491526928004021, 3.857782137116828],
              [-63.458199810333156, 3.866454054166525],
              [-63.445555038805367, 3.890282128580225],
              [-63.438754968378248, 3.915135980307468],
              [-63.433617867545706, 3.941664201017772],
              [-63.419099908291116, 3.967082154830223],
              [-63.38250887751434, 3.97083607399685],
              [-63.359454955680121, 3.965836101100706],
              [-63.335554964536982, 3.958054006894486],
              [-63.293963960864403, 3.93937309159719],
              [-63.242290868747716, 3.898127087060175],
              [-63.164172872039103, 3.795282140638335],
              [-62.990563875499049, 3.604309025793725],
              [-62.923617782287806, 3.567218098310377],
              [-62.903064014539666, 3.562918014331387],
              [-62.878054929965174, 3.560136060659488],
              [-62.781255012130657, 3.604309025793725],
              [-62.751399846402762, 3.638336032381574],
              [-62.734026843280617, 3.676527000840736],
              [-62.727226772853214, 3.705282125592987],
              [-62.727499855259623, 3.731109116281459],
              [-62.740145967891692, 3.790554076681985],
              [-62.758335871299948, 3.834717989360911],
              [-62.777226837091348, 3.882500034374004],
              [-62.780417827639155, 3.908744946756372],
              [-62.75778199513644, 4.02027320961588],
              [-62.728335866284567, 4.038609125777612],
              [-62.669863877207263, 4.046809141676988],
              [-62.604164005363771, 4.041944955612564],
              [-62.539935828083969, 4.112291084037508],
              [-62.440135859456291, 4.182673087008141],
              [-62.392226912428214, 4.179445048447917],
              [-62.32110881071867, 4.152500079510787],
              [-62.281390827134885, 4.136109100167403],
              [-62.262508913798683, 4.127218080168234],
              [-62.220835767474625, 4.112500128703175],
              [-62.180972944603695, 4.099582107131084],
              [-62.142646021674409, 4.093191073580158],
              [-62.104790832257549, 4.103891075927436],
              [-62.079308840704414, 4.124864106473865],
              [-62.053199882792342, 4.150136047532612],
              [-61.987499843310729, 4.169582062954021],
              [-61.952017905965192, 4.156809048307309],
              [-61.914863946570108, 4.146945056984919],
              [-61.84889082468203, 4.160553915018454],
              [-61.811526814792671, 4.184864116504059],
              [-61.790564010168339, 4.215691080088902],
              [-61.762917811209206, 4.243053970719757],
              [-61.699172934467867, 4.259027028369644],
              [-61.662781895901674, 4.255282161658499],
              [-61.625281931542304, 4.244235986708645],
              [-61.554381926954022, 4.248818037911462],
              [-61.524035749336633, 4.274864131549805],
              [-61.510699806071329, 4.303473075910006],
              [-61.507363976236036, 4.327364182235783],
              [-61.512781870825677, 4.361945064987083],
              [-61.507363976236036, 4.390554009347625],
              [-61.4922267619732, 4.406391112527444],
              [-61.31360890771964, 4.506664155771546],
              [-61.163890849867187, 4.494309062818388],
              [-61.052508935037793, 4.507782134020374],
              [-60.987090862779496, 4.519309094937341],
              [-60.968335851457823, 4.538336015198752],
              [-60.951663910719333, 4.556945013766779],
              [-60.929863921603499, 4.59187307494858],
              [-60.924581981483811, 4.637709009677394],
              [-60.886526799856256, 4.709718107696915],
              [-60.866808875495281, 4.713336072393645],
              [-60.83861785282852, 4.718473173226187],
              [-60.720281975828868, 4.776109151278263],
              [-60.675564019348712, 4.813891082861403],
              [-60.64930888104476, 4.842918116553278],
              [-60.592508914017515, 4.924718115699079],
              [-60.57854600439282, 4.952636055959999],
              [-60.582363961300189, 4.988473218362799],
              [-60.644172953678662, 5.158609089493268],
              [-60.655208902706477, 5.181318012191639],
              [-60.68514604344773, 5.196945064877468],
              [-60.710972866498309, 5.202918009097232],
              [-60.73037294908994, 5.204800081641451],
              [-60.689445959788543, 5.216109113075447],
              [-60.597426911900584, 5.211944983566298],
              [-60.573826825254002, 5.197153941905526],
              [-60.546390844427322, 5.191391048180094],
              [-60.317846016131625, 5.19763606897726],
              [-60.268408880512311, 5.235282046090504],
              [-60.249999874154923, 5.258473095861234],
              [-60.202364009533255, 5.272709087892281],
              [-60.114581846073975, 5.24569102875904],
              [-60.098335873655969, 5.217218038868566],
              [-60.081945900140965, 5.173053958551861],
              [-60.080873016531541, 5.16150906036205],
              [-60.013890881136319, 5.110000085909917],
              [-59.98306391755176, 5.022500057312641],
              [-60.005835872162265, 4.836945004695679],
              [-60.020563881911244, 4.734445057409573],
              [-60.025835763747125, 4.707218121248673],
              [-60.072090961274, 4.618473212388153],
              [-60.091526918411489, 4.600691172389816],
              [-60.123890955404875, 4.596664170817064],
              [-60.152226817358951, 4.573335993109993],
              [-60.148472898192324, 4.520000099037134],
              [-60.130699910649355, 4.509718018383069],
              [-60.045281946806369, 4.494027095594788],
              [-59.967781864001495, 4.501944976632558],
              [-59.941808860559206, 4.508054042959998],
              [-59.791117831383588, 4.456109041903744],
              [-59.717226828457797, 4.414164154277955],
              [-59.674445929806893, 4.385135947119579],
              [-59.675281940832008, 4.373336071434323],
              [-59.730835854129737, 4.293336002181093],
              [-59.731108936535804, 4.21916420549843],
              [-59.722226801354168, 4.191391104525053],
              [-59.708617775682455, 4.170691156385146],
              [-59.581673013545299, 3.998891142193429],
              [-59.568608811581555, 3.899445057519017],
              [-59.665554909807724, 3.711391024282307],
              [-59.786117858487387, 3.620553992383236],
              [-59.831945914227163, 3.524164117253122],
              [-59.814446009090432, 3.498744989974341],
              [-59.810835755744733, 3.359582095182191],
              [-59.83569983339379, 3.322082130822821],
              [-59.856117814310323, 3.297782155259029],
              [-59.869726839982036, 3.276391035381266],
              [-59.904726817893277, 3.20416417551678],
              [-59.949717856779671, 3.071945016972677],
              [-59.962781891105465, 3.025835999837568],
              [-59.978890903225135, 2.89916415246887],
              [-59.982781950328047, 2.860282179909916],
              [-59.988472927323926, 2.688191146039287],
              [-59.954173005967903, 2.616391092685603],
              [-59.925563893969468, 2.567782089102423],
              [-59.892781935282642, 2.454445011532698],
              [-59.888890888179503, 2.407082061679034],
              [-59.901672955281811, 2.384445055709762],
              [-59.893263894716483, 2.364100164989566],
              [-59.849726780759113, 2.33055394436883],
              [-59.793199896138276, 2.297782043966038],
              [-59.735490827890146, 2.284718009640244],
              [-59.727017897222652, 2.241244933423104],
              [-59.743617921231873, 2.072782090171188],
              [-59.756254981408517, 1.908891072199879],
              [-59.749026763366089, 1.861391162048221],
              [-59.726317840667491, 1.854164117472124],
              [-59.632472878986391, 1.842235998667775],
              [-59.653617906186241, 1.802218110587376],
              [-59.675281940832008, 1.767082178206181],
              [-59.64576389525098, 1.735136062906236],
              [-59.605417939479366, 1.719444972479948],
              [-59.50750892821344, 1.687500030646447],
              [-59.483617821887492, 1.650553942450529],
              [-59.414454882918278, 1.563336048714689],
              [-59.243963954368382, 1.386527009171516],
              [-59.104726795914019, 1.344718076015624],
              [-58.972781893242654, 1.319718043896501],
              [-58.957508892148098, 1.314444988594232],
              [-58.92486389375938, 1.299309115435676],
              [-58.897363875192298, 1.261835973166455],
              [-58.898408930883249, 1.231109089505878],
              [-58.869590941857041, 1.205000131593806],
              [-58.836399946293909, 1.190282180128747],
              [-58.810699857619738, 1.186873092459507],
              [-58.751945901319118, 1.209582182796396],
              [-58.722917861798805, 1.232364195690934],
              [-58.708890914433653, 1.262918076869482],
              [-58.692990946979705, 1.284718065985203],
              [-58.572499915029596, 1.275282054640115],
              [-58.519617817195808, 1.269618067372505],
              [-58.475145953393451, 1.314027066900678],
              [-58.467645994049178, 1.337636038364735],
              [-58.469308796005748, 1.35847311444104],
              [-58.502290914541106, 1.454673055644662],
              [-58.387290867376407, 1.477844994676047],
              [-58.297226814590601, 1.582782064136097],
              [-58.26833590300663, 1.570554040835304],
              [-58.127781947559754, 1.517782081209532],
              [-58.007154961139861, 1.515691131637595],
              [-57.982917849849912, 1.572364196650256],
              [-57.972499814726177, 1.613473073250816],
              [-57.876108933767455, 1.665000152613572],
              [-57.75944591700835, 1.717845034797335],
              [-57.722499828812374, 1.717500035661772],
              [-57.687499850900963, 1.707500089869086],
              [-57.653617851238153, 1.69666413305174],
              [-57.633335824791914, 1.692782138404425],
              [-57.559308867396396, 1.699717995663434],
              [-57.528063982118056, 1.71583606023853],
              [-57.512364006874463, 1.730835978927189],
              [-57.450908898448745, 1.811109130586544],
              [-57.428054968824711, 1.871244927448686],
              [-57.425490944636181, 1.901391112855663],
              [-57.331946055089702, 1.972218027247948],
              [-57.298190957441477, 1.981945058272117],
              [-57.276672935549414, 1.979509109564503],
              [-57.235345961827363, 1.949791071773006],
              [-57.199172852744368, 1.971109101454772],
              [-57.163063949039952, 1.99666418320362],
              [-57.100208895141975, 2.022782193571061],
              [-57.074235891699686, 2.015764193660971],
              [-57.06083574305552, 1.984164083325084],
              [-57.050835797263119, 1.952218135663315],
              [-57.003890936741129, 1.922918019565145],
              [-56.873890970888112, 1.891664081831493],
              [-56.770690967046846, 1.875626986441148],
              [-56.752090853296465, 1.896945016122743],
              [-56.735008869853175, 1.914100089761916],
              [-56.628199953770718, 1.939444953378597],
              [-56.594999905752388, 1.932218076440506],
              [-56.577917922309268, 1.92166408684659],
              [-56.525554999559517, 1.922500097871819],
              [-56.470635763972098, 1.944500079197724],
              [-56.426390882108649, 1.934164186725127],
              [-56.326955023355993, 1.925282051543491],
              [-56.201664010719981, 1.891664081831493],
              [-56.113335850086514, 1.863609013634516],
              [-56.068064017443078, 1.845554058868061],
              [-56.027499964550316, 1.836244949537388],
              [-55.995972944410369, 1.837636010192341],
              [-55.965835811458874, 1.845282149928266],
              [-55.942645935154758, 1.8561090542903],
              [-55.901735877297938, 1.901044940253428],
              [-55.903963954806045, 2.047782058052235],
              [-56.036390816911421, 2.208891122351645],
              [-56.115835836534643, 2.249164155565495],
              [-56.084445944331151, 2.357500086772632],
              [-56.000417867676674, 2.45083609929128],
              [-55.982499873208553, 2.522082108843563],
              [-55.962781948847464, 2.533054020130976],
              [-55.941808918301149, 2.530554033682904],
              [-55.917508942737356, 2.52069104818878],
              [-55.886390959473715, 2.497082076724553],
              [-55.864099958468501, 2.471454072417941],
              [-55.713681844061057, 2.400135978498042],
              [-55.503335803990808, 2.438891049042638],
              [-55.111946019243192, 2.527218036209604],
              [-55.091945960020382, 2.53471799555399],
              [-54.969445953511524, 2.550553925267536],
              [-54.869164025450061, 2.441945079292111],
              [-54.847499823166345, 2.436109095370909],
              [-54.803755005647474, 2.438054032189086],
              [-54.775381928043316, 2.456845085694681],
              [-54.753126801583676, 2.470627113848423],
              [-54.689863884276178, 2.451600025948665],
              [-54.690208883411913, 2.399509012138878],
              [-54.709099849203483, 2.387918013481283],
              [-54.687499852298004, 2.325553971472857],
              [-54.634726886843794, 2.320073212609032],
              [-54.603781906062068, 2.329191046908875],
              [-54.593617842604942, 2.329718100982006],
              [-54.573890865788428, 2.325282062533063],
              [-54.541326836584574, 2.315209026544608],
              [-54.527226799023538, 2.289309113298373],
              [-54.518617913886146, 2.257500125934598],
              [-54.464654885815662, 2.211735940297444],
              [-54.320554882401439, 2.161526999031707],
              [-54.109654966167966, 2.113473212716599],
              [-53.932635876130405, 2.228053997083066],
              [-53.928063883211621, 2.252782120262054],
              [-53.910281843213284, 2.277218050295971],
              [-53.799445925558075, 2.352364159406591],
              [-53.776808919588802, 2.364582124423521],
              [-53.746108858018431, 2.370973157974447],
              [-53.7215358000484, 2.341736073788582],
              [-53.701663983944684, 2.310282143844404],
              [-53.527781904998392, 2.249508987063109],
              [-53.458617792562734, 2.257500125934598],
              [-53.348335751070749, 2.322709153527001],
              [-53.340563882786739, 2.349653954826351],
              [-53.319863934646889, 2.347982100414299],
              [-53.296535756939818, 2.319009046179019],
              [-53.22610882696813, 2.264445035649317],
              [-53.239172861293923, 2.20833607272192],
              [-53.196108821952919, 2.213609128024359],
              [-53.111808836358762, 2.222500148023528],
              [-53.087364021507653, 2.219444944307554],
              [-53.069590866326735, 2.203053964963942],
              [-53.054863862406535, 2.186944952844158],
              [-52.994308802746218, 2.175691074333315],
              [-52.954726773632217, 2.176182086222582],
              [-52.89646399685887, 2.20680905759707],
              [-52.882781880991217, 2.224717999609823],
              [-52.789454920927994, 2.303336060663582],
              [-52.675626831468776, 2.374164148522141],
              [-52.594454972148412, 2.473891026953879],
              [-52.563754910578041, 2.522082108843563],
              [-52.542226830402171, 2.565554011593974],
              [-52.539863971890384, 2.586391087670506],
              [-52.552781993462304, 2.621245052828044],
              [-52.51972678473129, 2.697782054955553],
              [-52.464172871433789, 2.813335978283305],
              [-52.40861778466973, 2.922218074303032],
              [-52.379172996922421, 2.97416408118778],
              [-52.351954945578996, 3.040000075139346],
              [-52.344864023110631, 3.088473124252459],
              [-52.353054986554639, 3.131664065607765],
              [-52.344935772202064, 3.157400028827681],
              [-52.273608793464973, 3.241045052505967],
              [-52.249654990503416, 3.243745031164394],
              [-52.226745907956342, 3.25305397285706],
              [-52.165281914713205, 3.342217976877407],
              [-52.10028193178681, 3.448891106127917],
              [-51.995835874216141, 3.625836100141214],
              [-51.990008942750194, 3.657500080579553],
              [-51.981046006021643, 3.700000017835066],
              [-51.927081972122778, 3.776945051010273],
              [-51.906390908800347, 3.790836043905585],
              [-51.812326844169775, 3.876818109833721],
              [-51.793890848084004, 3.916664168898421],
              [-51.783617819885421, 3.961109042972453],
              [-51.771808891744513, 3.983473134173266],
              [-51.753890897276392, 4.000553944149999],
              [-51.728335815527544, 4.015835997700435],
              [-51.704172967900092, 4.026109025898847],
              [-51.683803917645776, 4.034395324117327],
              [-51.671251876999804, 4.046675673000323],
              [-51.661894384999869, 4.054696380000223],
              [-51.64317940299992, 4.076084932000299],
              [-51.640714673999923, 4.086929740000187],
              [-51.636495479999951, 4.105494190000229],
              [-51.628661232999775, 4.124296385000093],
              [-51.623127635999936, 4.137577018000172],
              [-51.609759790999931, 4.164312707000306],
              [-51.597728730999961, 4.188374828000178],
              [-51.603075868999895, 4.23382550000025],
              [-51.615106928999865, 4.275265819000197],
              [-51.619699615999821, 4.296917057000201],
              [-51.624464419999754, 4.319379706000404],
              [-51.631148342999836, 4.351462534000348],
              [-51.637832264999815, 4.390229283000281],
              [-51.641343915999926, 4.406616988000224],
              [-51.645852971999886, 4.427659249000158],
              [-51.648375412999883, 4.4469979670003],
              [-51.649863324999842, 4.458405292000293],
              [-51.645825754999862, 4.473209717000316],
              [-51.641842617999885, 4.487814550000166],
              [-51.633888888999934, 4.501388889000225],
              [-49.417829353999934, 7.006188542000302],
              [-49.36214204599986, 6.967247648000239],
              [-49.360947045999808, 6.966334148000328],
              [-49.356944369999951, 6.963405289500258],
              [-49.35582250899995, 6.962347054000304],
              [-49.355168545999845, 6.961889148000296],
              [-49.353244513666652, 6.960017102000279],
              [-49.349507366999944, 6.956329349000271],
              [-49.348076545999788, 6.954906148000191],
              [-49.3451225459998, 6.952839148000407],
              [-49.343173545999832, 6.950901148000241],
              [-49.340100546000031, 6.948751148000383],
              [-49.337594545999877, 6.94625814800014],
              [-49.334098546, 6.943812148000291],
              [-49.332892545999869, 6.942613148000191],
              [-49.332099851, 6.942039017000354],
              [-49.331512546, 6.941481148000321],
              [-49.330081198999949, 6.940469480666934],
              [-49.3293751409999, 6.939953397000295],
              [-49.324682545999849, 6.935374148000221],
              [-49.324052545999848, 6.934933148000312],
              [-49.321094545999813, 6.931991148000236],
              [-49.320039545999919, 6.93125314800028],
              [-49.319038545999945, 6.930257148000294],
              [-49.318018545999848, 6.929543148000221],
              [-49.31525720649995, 6.926797504500257],
              [-49.301931333666573, 6.917397911666967],
              [-49.299955545999921, 6.915993148000325],
              [-49.299101545999804, 6.915144148000252],
              [-49.28893454599995, 6.908030148000137],
              [-49.286113545999854, 6.905223148000232],
              [-49.285056973999929, 6.904484668500288],
              [-49.280434812999943, 6.899926138000183],
              [-49.269125545999884, 6.888674148000177],
              [-49.264858545999971, 6.885688148000384],
              [-49.264111045999869, 6.885022648000358],
              [-49.263067545999945, 6.884130148000281],
              [-49.261560545999941, 6.882699148000313],
              [-49.260846546000039, 6.882199148000154],
              [-49.260150545999863, 6.88150714800031],
              [-49.258825545999798, 6.880579148000265],
              [-49.255345545999887, 6.877117148000309],
              [-49.252792545999853, 6.875330148000273],
              [-49.246047545999829, 6.868620148000389],
              [-49.242776545999902, 6.866331148000313],
              [-49.236528545999931, 6.860114148000378],
              [-49.234590726999841, 6.858710631333565],
              [-49.233741033999877, 6.85809109800033],
              [-49.228259459999833, 6.8526366990003],
              [-49.223863545999905, 6.848262148000288],
              [-49.221916531999796, 6.846899831500309],
              [-49.034859900000015, 6.66067038000034],
              [-48.806299111999977, 6.433033332000264],
              [-48.805052544999796, 6.431266147000315],
              [-48.803293544999917, 6.42951314700025],
              [-48.802485394749908, 6.428368334250024],
              [-48.79362271399998, 6.419523821000269],
              [-48.787268966999818, 6.413190520000171],
              [-48.786599473999843, 6.412241672000164],
              [-48.785927544999765, 6.411289147000332],
              [-48.784271544999967, 6.409638147000294],
              [-48.781739617999904, 6.406050470000366],
              [-48.780490014999884, 6.404279411000289],
              [-48.772282923999853, 6.396097861000328],
              [-48.756195544999969, 6.380060147000336],
              [-48.749208527999883, 6.370156174000215],
              [-48.747233933499899, 6.367430981500263],
              [-48.744171544999858, 6.364377147000312],
              [-48.741346544999885, 6.360373147000132],
              [-48.740163544999803, 6.359193147000212],
              [-48.739476544999889, 6.358220147000338],
              [-48.738035545499883, 6.356737762000364],
              [-48.737558943999773, 6.356062253000346],
              [-48.734137927999939, 6.352651420000313],
              [-48.733483942000021, 6.351724289000231],
              [-48.733044193499779, 6.351237607000257],
              [-48.721663169999943, 6.339841694000199],
              [-48.714107544999877, 6.332308147000276],
              [-48.712994967999919, 6.330730741000139],
              [-48.707757773999845, 6.323305306000293],
              [-48.707099979999953, 6.322649395000269],
              [-48.702843710999787, 6.316614481000272],
              [-48.70010904499992, 6.312756647000299],
              [-48.36293054500004, 5.834211147000246],
              [-48.361871909999934, 5.831964937000407],
              [-48.360040685999763, 5.828079246000129],
              [-48.358662997999886, 5.826121916000375],
              [-48.355879859333243, 5.82212810100026],
              [-48.355216235999819, 5.821165449000318],
              [-48.344912544999943, 5.806525147000286],
              [-48.344556247749836, 5.805769984750384],
              [-48.342927544999839, 5.803453147000141],
              [-48.342225500999916, 5.801963609000325],
              [-48.340758887999812, 5.79885110000032],
              [-48.337389296999902, 5.794063208000296],
              [-48.331840206999857, 5.786177008750371],
              [-48.329917544999944, 5.783444147000353],
              [-48.328734545000032, 5.780933147000212],
              [-48.325730137999869, 5.776664577000361],
              [-48.323900759000026, 5.774065042000302],
              [-48.251506903, 5.620350471000336],
              [-48.200786986999958, 5.548229700000263],
              [-48.198084138499837, 5.542486414000223],
              [-48.195776544999831, 5.539108147000263],
              [-47.947278544999904, 5.010440146000235],
              [-47.946780231999867, 5.008610392000321],
              [-47.946554925999806, 5.007817763000219],
              [-47.946272544999886, 5.007215146000192],
              [-47.793464544999892, 4.444428146000405],
              [-47.774011543999904, 4.230529145000276],
              [-47.384945043999892, 3.840406145000372],
              [-47.382963543999892, 3.83841514500034],
              [-47.382015543999842, 3.837087645000281],
              [-47.381624543999862, 3.836556145000145],
              [-47.37492054400002, 3.829831145000298],
              [-47.374214555999856, 3.828822363000313],
              [-47.373053977999859, 3.827204110666912],
              [-47.038678268999888, 3.349122834500292],
              [-47.037760696999896, 3.347790404000307],
              [-46.888617818999933, 3.027976423000212],
              [-46.791439543999957, 2.819554144000222],
              [-46.788020869999855, 2.806821737000234],
              [-46.783860714999946, 2.791327262000266],
              [-46.78359710799981, 2.790761444000168],
              [-46.783371165499887, 2.79020004850031],
              [-46.782443979666539, 2.786830211666938],
              [-46.773522043999833, 2.753875144000347],
              [-46.756191543999819, 2.689531144000227],
              [-46.734608670499995, 2.687546236000117],
              [-46.724038543999882, 2.684727144000306],
              [-46.718266543999789, 2.684196144000225],
              [-46.715759498249838, 2.683531548000303],
              [-46.714267543999824, 2.683394144000317],
              [-46.711620543999857, 2.682672144000264],
              [-46.710238544, 2.682545144000244],
              [-46.149431542999878, 2.529738144000135],
              [-46.079150542999855, 2.496643144000302],
              [-46.070991694333259, 2.495886370000164],
              [-46.070159082999794, 2.495803476000276],
              [-46.062053542999877, 2.495061144000317],
              [-45.72068354299995, 2.402167144000146],
              [-45.621072541999865, 2.393087144000276],
              [-45.618440309499761, 2.39243928500025],
              [-45.617076020999946, 2.392315039999971],
              [-45.403911992999838, 2.334394462000034],
              [-45.056552541999849, 2.240010143000291],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BRB", Country: "Barbados" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-57.105762006, 10.69331238300002],
            [-57.286916251999855, 10.683777465000063],
            [-57.769490553999901, 10.956662362000102],
            [-58.671085554999934, 11.452186363000024],
            [-58.922482554999817, 11.589491363000093],
            [-59.087565554999912, 11.678553363000077],
            [-59.149277555000026, 11.711725363],
            [-59.353079555, 11.82748036300012],
            [-59.445723554999972, 11.879732363000173],
            [-59.639747556000032, 11.988688363000136],
            [-59.937607555999989, 12.155358363000076],
            [-59.946817555999871, 12.160484363],
            [-59.966602556, 12.170582363000108],
            [-60.288264555999973, 12.331427363000103],
            [-60.311695555999961, 12.411436363000035],
            [-60.315729555999951, 12.447000364000175],
            [-60.327398555999906, 12.550808364000062],
            [-60.347068555999897, 12.70384236400011],
            [-60.372177055999941, 12.959272864000141],
            [-60.38769855599989, 13.07943836400014],
            [-60.384472555999963, 13.128199364000182],
            [-60.383265555999913, 13.170555364000066],
            [-60.383276555999856, 13.17370036400014],
            [-60.385322555999949, 13.236625364000076],
            [-60.377770555999916, 13.258241364000156],
            [-60.362837555999818, 13.303169364000041],
            [-60.362062556000012, 13.305521364000143],
            [-60.339303555999948, 13.374765364000041],
            [-60.330213555999848, 13.401355364],
            [-60.296058555999849, 13.490067365000158],
            [-60.270353556000032, 13.554394365],
            [-60.249505555999889, 13.605874365000048],
            [-60.221693556, 13.669719365000077],
            [-60.212092555999988, 13.691920365000158],
            [-60.199603555999857, 13.722099365000062],
            [-60.137203556000031, 13.85908636500011],
            [-60.13679355599993, 13.859985365000057],
            [-60.136496055999913, 13.860634865000151],
            [-60.131072556, 13.872474365000173],
            [-60.102272555999861, 13.926330365000084],
            [-60.07774655599988, 13.970556365000064],
            [-60.008776556000015, 14.094721365000041],
            [-60.005500556000015, 14.100499365],
            [-59.999722555999966, 14.114166365000088],
            [-59.995236555999867, 14.11870736500002],
            [-59.985407556000013, 14.12856336500009],
            [-59.962610555999902, 14.1510063650001],
            [-59.866571555999968, 14.246950365000103],
            [-59.728672555999964, 14.385536365000107],
            [-59.661152555999877, 14.453362365000103],
            [-59.660557555999844, 14.453959365000102],
            [-59.595488556, 14.519196365000042],
            [-59.358898554999882, 14.751886366000107],
            [-59.225107554999965, 14.875281366000067],
            [-58.911964554999884, 15.146046366000107],
            [-58.86211055499993, 15.186530366000099],
            [-58.038043553999898, 15.852211367000095],
            [-57.769186553999987, 15.939990367000163],
            [-57.559410553999896, 15.996800367000034],
            [-57.375140553, 15.870812367000028],
            [-57.374481552999896, 15.870167366999951],
            [-57.370115552999977, 15.867167367000079],
            [-57.360983552999869, 15.858211366999953],
            [-57.356076553000037, 15.854839367000025],
            [-57.353753552999933, 15.852561367],
            [-57.352068552999867, 15.851403367000032],
            [-56.930350553000011, 15.436738366000029],
            [-56.929516552999928, 15.435561365999988],
            [-56.780725552999854, 15.288756366000015],
            [-56.776393553, 15.282634366000011],
            [-56.775755552999954, 15.282004366000123],
            [-56.437199552999971, 14.802465366000021],
            [-56.191139551999868, 14.273197365000087],
            [-56.044439551999972, 13.710535365],
            [-56.04434555200001, 13.709298365000052],
            [-56.043493551999859, 13.706023365000064],
            [-55.999892551999977, 13.126939030666747],
            [-56.000033551999934, 13.125298364000045],
            [-55.999942885333212, 13.123541030666701],
            [-56.059982551999923, 12.545764364000078],
            [-56.060706551999971, 12.543264364],
            [-56.061066552, 12.539780364000123],
            [-56.222264551999984, 11.981179363000024],
            [-56.479197552999892, 11.458151363000013],
            [-56.480220552999896, 11.456064363],
            [-56.480854552999858, 11.455198363000108],
            [-56.482232553000017, 11.452386363000144],
            [-56.484191052999961, 11.449660363],
            [-56.485316052999963, 11.447383363000085],
            [-56.487305052999886, 11.443342363000127],
            [-56.489350553, 11.439168363],
            [-56.499516552999864, 11.425276362999966],
            [-56.500439553000035, 11.423393363000045],
            [-56.501384052999839, 11.422053363000103],
            [-56.845894552999937, 10.950020362000146],
            [-56.84878055299987, 10.947197362000068],
            [-56.84926455299987, 10.94668636199998],
            [-56.855939552999985, 10.937518362],
            [-56.860915552999927, 10.932713862000114],
            [-56.864449553000043, 10.929298362000139],
            [-56.864953552999907, 10.928605362],
            [-57.105762006, 10.69331238300002],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BRN", Country: "Brunei" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [112.49702964400015, 6.233079192000091],
            [112.827419938000077, 6.289596063000147],
            [112.928615982000196, 6.291019912000095],
            [113.76261594800016, 6.302530617000102],
            [113.77462375400026, 6.305559484000156],
            [113.817794942000177, 6.326482285000168],
            [114.762803652000144, 5.349829065000122],
            [114.845367534000076, 5.265033727000116],
            [115.003800928000118, 5.204784408000052],
            [115.119836653000192, 5.119989071000134],
            [115.258186941000218, 5.088748683000063],
            [115.144382672000035, 4.99279606400016],
            [115.137688303000203, 4.979407327000018],
            [115.146614128000095, 4.966018589000143],
            [115.157771409000105, 4.961555677],
            [115.1488455840001, 4.945935482999971],
            [115.126531022000194, 4.916926551000159],
            [115.115373741000127, 4.890149076000142],
            [115.133590695871618, 4.857636912504489],
            [115.145781964183328, 4.903236289568369],
            [115.192200199861787, 4.849164143178712],
            [115.223036215901942, 4.805000062861978],
            [115.261382249570403, 4.683053932516486],
            [115.282491234586274, 4.611664089505396],
            [115.282491234586274, 4.598609107635099],
            [115.278318220259649, 4.582773177921595],
            [115.272772417827156, 4.57389104273966],
            [115.268600241691018, 4.564444973110682],
            [115.264427227364394, 4.541109084052849],
            [115.273182292893665, 4.45139103623093],
            [115.27805419030895, 4.438891103990542],
            [115.286372391043557, 4.426945047913478],
            [115.302754150293396, 4.410282159630171],
            [115.317900249373821, 4.396391166734844],
            [115.344709263841224, 4.368327046082499],
            [115.350809277713125, 4.360000128168551],
            [115.356654146451916, 4.349309010638436],
            [115.360263393969291, 4.332636064071508],
            [115.352072262887162, 4.31750002327496],
            [115.339854130232226, 4.31166420699185],
            [115.320545242747215, 4.315553912990211],
            [115.113600243718935, 4.374164203470258],
            [115.102345191741591, 4.380554063554868],
            [115.096363362704579, 4.391391026200608],
            [115.090272401288104, 4.413336022241765],
            [115.02942716013925, 4.645836103018738],
            [115.02665425892306, 4.656664180847159],
            [115.020536140140081, 4.724444943979037],
            [115.020827159819106, 4.753336023200916],
            [115.029127317428475, 4.820209328144202],
            [115.027757175914729, 4.83257050599066],
            [115.023782646240676, 4.868430509026652],
            [115.022900390378169, 4.876391005669944],
            [115.019406177041077, 4.891576871259758],
            [115.018436176831614, 4.895791000180779],
            [114.989145242108037, 4.842918116553022],
            [114.979427263539407, 4.831664070404074],
            [114.962200273170907, 4.821945086006792],
            [114.945827231100139, 4.817218027878752],
            [114.917482316690752, 4.811664178819171],
            [114.904427167182376, 4.811109129189362],
            [114.879154220295192, 4.810273118164332],
            [114.866654120416712, 4.809718068534607],
            [114.852618288234083, 4.80360900220704],
            [114.787072308133105, 4.749582104034289],
            [114.779854148374483, 4.735835950426193],
            [114.777772418895978, 4.720836031737718],
            [114.779982223855285, 4.709164231533336],
            [114.788463368787944, 4.697364188209988],
            [114.815536245568154, 4.580835952454265],
            [114.825818326222162, 4.495836077942883],
            [114.829163376150774, 4.473891081901812],
            [114.83471823103875, 4.446391063334772],
            [114.843663398132463, 4.435627023246894],
            [114.859009321785067, 4.432636024909357],
            [114.866927202822836, 4.355273070040596],
            [114.806363425983449, 4.14458219847269],
            [114.793054137170003, 4.126944997761669],
            [114.705409269285411, 4.050000132224611],
            [114.669982317225021, 4.029718105778102],
            [114.649991310457636, 4.02221797879578],
            [114.638036201925075, 4.01819114486095],
            [114.619491241097734, 4.021664102632357],
            [114.609427257564874, 4.030836084026745],
            [114.608454118603476, 4.05569110922039],
            [114.475536244608833, 4.200000157300465],
            [114.399991324544004, 4.256391087451306],
            [114.329991368721295, 4.326945087075629],
            [114.329163404323168, 4.340000068945841],
            [114.309009285719554, 4.433191074539167],
            [114.263891344818973, 4.497500053366025],
            [114.246091367547734, 4.521664074459864],
            [114.235809286893726, 4.531944981647484],
            [114.188309209103778, 4.566944959558938],
            [114.17998229118993, 4.572500149722899],
            [114.16499125731869, 4.577773205025352],
            [114.153318283647906, 4.580000109067228],
            [114.095082032643262, 4.590536306500056],
            [114.081198376731237, 4.592988573801634],
            [114.066672405746687, 4.595554717889343],
            [114.071036337000095, 4.703267693999976],
            [112.49702964400015, 6.233079192000091],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BTN", Country: "Bhutan" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [91.657763301210679, 27.764718022361919],
            [91.658872394641918, 27.763336014162391],
            [91.659145141772143, 27.741245005367702],
            [91.654436188554911, 27.71666423604664],
            [91.642209171082612, 27.697082098517356],
            [91.62040029714953, 27.680964201580153],
            [91.598945139531367, 27.66006409359197],
            [91.600254225173018, 27.615409169023863],
            [91.617482221369841, 27.560827053583111],
            [91.631363323619468, 27.536382071093968],
            [91.675818255977134, 27.487082063411165],
            [91.770827128736215, 27.431664104583589],
            [91.853591214388217, 27.436664245117825],
            [91.958318233354106, 27.45443605919418],
            [91.993663378039258, 27.475582092222439],
            [92.078045171008597, 27.368609058475258],
            [92.089154210232294, 27.349445010277591],
            [92.114218280091706, 27.293054080126481],
            [92.044154118890532, 27.188327061160592],
            [92.015272259761986, 27.168054087169665],
            [92.009991325470679, 27.101527089118449],
            [92.017772246210114, 27.079300125853663],
            [92.04539128780263, 27.05513610475974],
            [92.071372337871964, 27.037082155821693],
            [92.086654223784222, 27.014718064620709],
            [92.100818299085773, 26.979164210545903],
            [92.113172386210493, 26.930273072101144],
            [92.103045203127607, 26.895545003129584],
            [92.092063401194565, 26.878327065216567],
            [92.081509243962643, 26.869045113252199],
            [92.064427260519466, 26.859718066648597],
            [92.031236264956561, 26.851935972442661],
            [91.991782311323306, 26.853744954791026],
            [91.838245123097551, 26.863264114615859],
            [91.742336201573067, 26.819854070310882],
            [91.715545124378565, 26.800973162803203],
            [91.542827124148658, 26.805273079144243],
            [91.516936263357621, 26.839164131262365],
            [91.501272330297667, 26.867254068176649],
            [91.417272249199897, 26.871245027565848],
            [91.401091320350673, 26.848327060201441],
            [91.390127288052355, 26.821945019883046],
            [91.375536238601825, 26.80416415335101],
            [91.343036247138599, 26.786391165808269],
            [91.307345097489446, 26.778054021972522],
            [91.28485410427399, 26.779853951865377],
            [91.263045230340907, 26.795000050945632],
            [91.231227190521821, 26.806245044639169],
            [91.119709153584239, 26.809164126247353],
            [91.04970919776153, 26.78889115225634],
            [90.96662727034024, 26.78342698956105],
            [90.957491331129603, 26.784445055523506],
            [90.936918285004168, 26.784718137929801],
            [90.902482241540014, 26.784718137929801],
            [90.744291253020123, 26.777218010947379],
            [90.708600271009033, 26.772500172912842],
            [90.688591326968918, 26.773191177012549],
            [90.641372378212196, 26.782500118705158],
            [90.56414521017544, 26.805136118845937],
            [90.518872371703452, 26.824718088737328],
            [90.457136134244564, 26.857982174496087],
            [90.417209273632892, 26.894436077336351],
            [90.388736116104411, 26.903464225271804],
            [90.36290024059852, 26.899853971926021],
            [90.216936269550985, 26.850827046649428],
            [90.196091314485642, 26.834164158366306],
            [90.183172287085171, 26.813682139709542],
            [90.176163339630392, 26.77388218111237],
            [90.14901821084473, 26.75541802183163],
            [90.123600257032166, 26.748054016957312],
            [90.08193616316359, 26.739164170424615],
            [89.854109161057949, 26.709482174816841],
            [89.805818166882176, 26.707218055124756],
            [89.793172389526063, 26.706718158417871],
            [89.769854102464905, 26.703609142883337],
            [89.643054179615064, 26.715273231736788],
            [89.622827138453687, 26.725136049592905],
            [89.643872253367277, 26.752500113690033],
            [89.642691243206542, 26.76263601395236],
            [89.588736261763273, 26.792354051743942],
            [89.563182353480869, 26.802500177928138],
            [89.526236265284894, 26.811664112695425],
            [89.481791223572856, 26.800064229220325],
            [89.367200213284463, 26.859436099425082],
            [89.360809179733366, 26.860000033872311],
            [89.299982211133425, 26.850827046649428],
            [89.276172241630633, 26.831527043981836],
            [89.220827205360791, 26.814718142944926],
            [89.200000187568406, 26.813882131919897],
            [89.149427136427761, 26.818882104815955],
            [89.126082362552467, 26.823609162944095],
            [89.106309285268281, 26.832564220683608],
            [89.093318173500421, 26.858327005993758],
            [89.084572328064638, 26.885618147533123],
            [89.038182349534424, 26.9224911455333],
            [88.947754186873595, 26.969436006055233],
            [88.893872295626153, 26.97555395720012],
            [88.869418260681499, 27.067354069776698],
            [88.861718141488751, 27.093744994912512],
            [88.826100249673601, 27.121109059009555],
            [88.792482279961774, 27.142353998495494],
            [88.75310929551344, 27.148064253868753],
            [88.751936164341743, 27.148609077576779],
            [88.762209192540269, 27.184436014057681],
            [88.810745105927509, 27.254718104741997],
            [88.8427633055949, 27.265691189495826],
            [88.890963272301889, 27.276945068006782],
            [88.911236246292873, 27.294027051449902],
            [88.917463329817366, 27.317382218885101],
            [88.916927223288752, 27.320827013461823],
            [88.923600224063449, 27.345553963174481],
            [88.93552716940124, 27.368327091251658],
            [88.946636208624824, 27.385273120224966],
            [88.956236169996572, 27.430827087730151],
            [88.967491221973916, 27.46638211527123],
            [88.984982242293114, 27.488882160942111],
            [89.015536123471776, 27.509854018021869],
            [89.057618139033849, 27.523609056447285],
            [89.086382316241412, 27.53832700791223],
            [89.104982262353843, 27.550554025384614],
            [89.128582349000595, 27.571664183867043],
            [89.128036184188176, 27.623327050061604],
            [89.166227152647338, 27.675000142178064],
            [89.179563263550932, 27.696800131293841],
            [89.200272264146264, 27.738044962364498],
            [89.218454120927134, 27.778191093563819],
            [89.236991202765608, 27.807218127255609],
            [89.269145189264833, 27.827500153702019],
            [89.359982221163733, 27.871664234018837],
            [89.386936242556459, 27.915273097067583],
            [89.398036229324674, 27.933053963599534],
            [89.409427235771915, 27.950000160210735],
            [89.450427147631075, 28.00838212764755],
            [89.456100187354167, 28.017218162361772],
            [89.467754217923783, 28.03416419133508],
            [89.487491253024217, 28.057773162799108],
            [89.588191270417241, 28.141664111517272],
            [89.626509308528938, 28.159436093231804],
            [89.692754171718519, 28.173609053350745],
            [89.721236214064419, 28.174436011920264],
            [89.746372368291333, 28.175553990169007],
            [89.791854083790923, 28.194718038366673],
            [89.836654182922445, 28.2516641857856],
            [89.863036223240925, 28.271382110146718],
            [89.998191227199214, 28.323691053439873],
            [90.020682220414727, 28.325000139081439],
            [90.178591241710905, 28.300553983125823],
            [90.225536269870958, 28.290273075938288],
            [90.248872326566953, 28.28471805341222],
            [90.286518303680197, 28.275409111719526],
            [90.319427164381381, 28.264300072495885],
            [90.346509261255022, 28.259300099599741],
            [90.388972318136581, 28.242564121120679],
            [90.395400232061633, 28.200544969832478],
            [90.379291219941905, 28.169582219415915],
            [90.366382250825239, 28.15055395804994],
            [90.356645161517207, 28.132500176749986],
            [90.353318216499503, 28.098473170161853],
            [90.376500213814694, 28.079582204370283],
            [90.466382211663159, 28.071664155694535],
            [90.510554170968902, 28.079164115038864],
            [90.532482235565482, 28.080554002227316],
            [90.55615423894173, 28.075136107637647],
            [90.626654091471181, 28.075409022405765],
            [90.650545197796959, 28.078609065409054],
            [90.756654224962318, 28.064718072513628],
            [90.840272258912222, 28.048054010764034],
            [90.868872318455118, 28.050553997212106],
            [90.917900249560063, 28.046526995639184],
            [90.95138226480222, 28.032500048274059],
            [90.967754133406316, 28.019164105008443],
            [90.993454222080373, 27.99235408471263],
            [91.020400196845998, 27.976936076692553],
            [91.086927194897356, 27.969991166978147],
            [91.11234514870992, 27.971936103796494],
            [91.131645151377512, 27.983609077467278],
            [91.145545196728307, 28.002082121565323],
            [91.164991212149602, 28.026109014722962],
            [91.218872265206727, 28.074718018305944],
            [91.30137232090803, 28.081109051857126],
            [91.326100276449125, 28.063054097090671],
            [91.356936124851273, 28.041945112074544],
            [91.419436121329767, 28.010554046404494],
            [91.450000228430184, 27.997218103139076],
            [91.485263398102035, 27.989300054463243],
            [91.501118270916805, 27.989518151584392],
            [91.520536123143444, 27.991382119217704],
            [91.606936110765076, 27.969436117348337],
            [91.638736213311205, 27.957773201961359],
            [91.662763274106823, 27.945000187314676],
            [91.677063303878271, 27.92916408996301],
            [91.67394523588834, 27.897982068958711],
            [91.656718245519784, 27.869435988872297],
            [91.653045127900072, 27.836109038839581],
            [91.656645155323957, 27.777773171870294],
            [91.657763301210679, 27.764718022361919],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BVT", Country: "Bouvet I." },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [3.405371503000026, -51.068028695999971],
            [4.326438504000038, -51.114955695999967],
            [4.350076504000157, -51.118728695999948],
            [4.354412504000095, -51.118930695999964],
            [5.253374505000039, -51.258258696],
            [5.255644505000106, -51.258877695999963],
            [5.256501505000131, -51.259006695999979],
            [6.111173506000142, -51.487524696],
            [6.123552506000038, -51.492500695999965],
            [6.125472506000108, -51.493006696],
            [6.128585506000093, -51.493825696],
            [6.129468505999967, -51.494180695999972],
            [6.139726506000159, -51.496867695999981],
            [6.928687506000188, -51.808743696000029],
            [6.930362506000137, -51.809682696],
            [6.931109506000126, -51.809973696000014],
            [6.935525506000118, -51.812447695999985],
            [6.940147006000132, -51.814261695999974],
            [6.940926506000153, -51.814581695999983],
            [7.642852507000129, -52.20211869700001],
            [8.237214508000136, -52.655468697000011],
            [8.704940508000163, -53.162713697999934],
            [8.710680508, -53.172556697999973],
            [8.712866508000133, -53.174892697999987],
            [9.036883508000102, -53.722275698000047],
            [9.202147509000156, -54.294489699],
            [9.197159509000187, -54.874879699000012],
            [9.014714508000111, -55.445843700000019],
            [9.013387508000079, -55.4478637],
            [9.008527508000128, -55.4628667],
            [8.646552508000184, -56.006308699999977],
            [8.644779508000028, -56.007978699999953],
            [8.643589508000161, -56.0097397],
            [8.106736508000154, -56.507683700999955],
            [7.40696450700014, -56.942958700999938],
            [7.404539507000123, -56.944002700999988],
            [7.401042507000142, -56.946143701000032],
            [6.557907506000106, -57.303093700999966],
            [5.598913505000041, -57.568492701999944],
            [4.55825850400015, -57.732207702],
            [4.55040650400008, -57.732643702000011],
            [4.547421504000113, -57.73309970199999],
            [4.546482504000124, -57.733151702000029],
            [4.523881504000087, -57.736596702000057],
            [4.509701504000105, -57.737380702],
            [4.473264504000127, -57.742921701999983],
            [3.389851503000131, -57.797872701999978],
            [3.388943503000036, -57.797830701999963],
            [3.388030503000095, -57.797872701999978],
            [3.383944503000038, -57.797681701999984],
            [3.362630503, -57.798669702000019],
            [3.361732503000127, -57.798627702],
            [3.360821503000153, -57.798669702000019],
            [2.277499502000126, -57.743131702000042],
            [1.236471501000096, -57.579527701999936],
            [0.277093500000149, -57.314228700999969],
            [0.0000005, -57.196997701000029],
            [-0.566417500999819, -56.957363700999963],
            [-0.567038500999956, -56.957013700999958],
            [-1.267436500999906, -56.521762701000014],
            [-1.288067500999944, -56.502915700999978],
            [-1.291344500999912, -56.500845700999946],
            [-1.828027501999884, -56.002876699999959],
            [-2.189931501999922, -55.4594267],
            [-2.372474501999847, -54.888467698999946],
            [-2.372640501999911, -54.868760698999985],
            [-2.374103502, -54.86412069899994],
            [-2.37911950199998, -54.283730698999932],
            [-2.378802001999901, -54.282338199],
            [-2.213623501999905, -53.709821697999971],
            [-1.889732501999873, -53.162433698000036],
            [-1.888569501999825, -53.161190698000041],
            [-1.887938501999855, -53.160107697999962],
            [-1.88565450199988, -53.157664697999977],
            [-1.881858501999858, -53.151154698000013],
            [-1.414282500999889, -52.643901696999961],
            [-0.820095500999884, -52.190543697000017],
            [-0.819427000999923, -52.190154696999983],
            [-0.1176045, -51.802520695999924],
            [0.0000005, -51.756039695999966],
            [0.67132050100011, -51.490715695999981],
            [1.526254501000125, -51.262490696000029],
            [2.425432502000092, -51.123493696000033],
            [2.427529502000027, -51.123396696],
            [2.481216502000166, -51.114843696000023],
            [3.402296503000059, -51.068028695999971],
            [3.40383250300016, -51.068112696],
            [3.405371503000026, -51.068028695999971],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "BWA", Country: "Botswana" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.264427101635846, -17.802245958994959],
            [25.241800153950464, -17.855135935817856],
            [25.23791815930295, -17.903754997684729],
            [25.241391284712449, -17.924446061007259],
            [25.275554078132302, -18.005417928117325],
            [25.3174272166668, -18.074935924506093],
            [25.366391277669351, -18.110554989787715],
            [25.396527069516281, -18.127917934626055],
            [25.450000259163801, -18.220000014426148],
            [25.47944504691111, -18.274446007758897],
            [25.500827114333248, -18.328335945633441],
            [25.506382304497407, -18.35110890607244],
            [25.514445192460471, -18.377646011600035],
            [25.526391248537607, -18.39749989043105],
            [25.601663253834289, -18.479582024438827],
            [25.661663263864625, -18.535835826653468],
            [25.71889121086906, -18.591390913417442],
            [25.796663202613928, -18.711945983107995],
            [25.870282296599783, -18.844026840249128],
            [25.940554161362314, -18.893199945917516],
            [25.956109129680698, -18.91360887437834],
            [25.976663232704908, -18.952782034254412],
            [25.986945145720938, -18.988055094571976],
            [25.986673236781115, -18.998290907120378],
            [25.964163300464435, -19.061673015091429],
            [25.964373183320589, -19.104790866250909],
            [26.000000127591221, -19.160835791437776],
            [26.022218206038502, -19.187781933841478],
            [26.054718197501785, -19.264999881784917],
            [26.098609195412308, -19.375555005683069],
            [26.151800250198022, -19.506526937031055],
            [26.169373245530608, -19.529863999555374],
            [26.1966632136035, -19.547364072330097],
            [26.243473293122008, -19.572091022042855],
            [26.263609139176651, -19.576945988013705],
            [26.356663352109763, -19.616946106459309],
            [26.404445061847127, -19.67583584959192],
            [26.536663214562736, -19.763617845412995],
            [26.568673199965048, -19.785217842318403],
            [26.590136068934072, -19.801254937708734],
            [26.595691259098118, -19.827364063259012],
            [26.610900222452528, -19.85278201707149],
            [26.635273288212233, -19.865699871005404],
            [26.695836059223296, -19.877881961476618],
            [26.728536210534998, -19.928755084751785],
            [26.978954118142241, -20.011317837088967],
            [27.079163291284004, -20.026672980835073],
            [27.213609186231821, -20.087363994965116],
            [27.232845151158955, -20.109099946340436],
            [27.282500216261298, -20.221108995167384],
            [27.290554051768964, -20.241672988837422],
            [27.298882143149314, -20.311526931906442],
            [27.295827107071347, -20.385281980362279],
            [27.290836186630571, -20.454726886555108],
            [27.287454088689827, -20.494964045223185],
            [27.293891222708481, -20.489172988303139],
            [27.330973265374467, -20.471808869998313],
            [27.357427222422331, -20.465835925778734],
            [27.432500241337181, -20.472226959329831],
            [27.516391190055145, -20.476390921200959],
            [27.610691289079767, -20.471317858108861],
            [27.691873206683994, -20.489172988303139],
            [27.715582258072203, -20.510217935578638],
            [27.726391225161336, -20.548891031109846],
            [27.730827095972273, -20.748335903155819],
            [27.726945101324731, -20.79194610730913],
            [27.710000245817895, -20.84389094072732],
            [27.694027188167951, -20.864926835547422],
            [27.689718219371599, -20.934717914342386],
            [27.686736105851452, -21.071182003942482],
            [27.727354138200923, -21.131946108268366],
            [27.747500210177435, -21.151808871916799],
            [27.780000201640661, -21.169172990221611],
            [27.842982157552996, -21.224726903519212],
            [27.89971808683967, -21.308608967419843],
            [27.941945109327179, -21.383335813732543],
            [27.970409214400235, -21.440417915621268],
            [28.007773224289764, -21.545564029747155],
            [28.012773197185908, -21.561390907005247],
            [28.015827227435494, -21.566108912677876],
            [28.048745140592217, -21.576255038861987],
            [28.161945090225771, -21.593608931244916],
            [28.202773173069289, -21.596673019778308],
            [28.238327194782158, -21.595555041529664],
            [28.280482300540115, -21.587781999779139],
            [28.350554173092263, -21.602499951244084],
            [28.380836145331131, -21.615554933114382],
            [28.408054029036549, -21.630555019441033],
            [28.462500190007262, -21.654581912598573],
            [28.506454052191799, -21.660555024456144],
            [28.526391247140651, -21.648055092215756],
            [28.567982250813344, -21.632290911593458],
            [28.6462450868095, -21.649581939702415],
            [28.86347317232017, -21.751808972220232],
            [28.925136151945168, -21.769726966688467],
            [28.955273284896776, -21.771108974887909],
            [28.987082272260324, -21.770554931086409],
            [29.072518173375869, -21.809408908088656],
            [29.075009107368629, -21.833535881170377],
            [29.044863257237779, -21.877635923746752],
            [29.035827230313231, -21.903063935843036],
            [29.031945068027625, -21.936390885875937],
            [29.035000271743655, -21.965555047504111],
            [29.046109143329232, -21.993890909458187],
            [29.058609243207712, -22.017226966154098],
            [29.075827181120729, -22.03944605042976],
            [29.093891188342667, -22.053608952264895],
            [29.113609112703813, -22.062499972264064],
            [29.148336175847049, -22.074446028341129],
            [29.18139121694017, -22.080835888425739],
            [29.22055415089423, -22.079026906077374],
            [29.248745173560991, -22.070135886078205],
            [29.267773267288959, -22.077782025814244],
            [29.361945123194545, -22.176945975627149],
            [29.373618096865385, -22.192408910648368],
            [29.274163295011562, -22.194172965995577],
            [29.0716632192497, -22.220555006313973],
            [29.045000217536113, -22.224163918555291],
            [29.024800166102835, -22.227108984063292],
            [28.961109101179858, -22.326045951937445],
            [28.968054178532356, -22.368335838698982],
            [28.964718181059339, -22.38875499308169],
            [28.950554105757732, -22.413064021100936],
            [28.93430913916805, -22.439026966259419],
            [28.917845069628612, -22.45495509690825],
            [28.828891283740433, -22.490835845207712],
            [28.638327205771844, -22.561808939991849],
            [28.456109156456279, -22.573890950538825],
            [28.356527117311799, -22.576390936986897],
            [28.298336089629828, -22.609445978080004],
            [28.196663268551589, -22.675281972031598],
            [28.179636102755325, -22.68643593825638],
            [28.167773195157991, -22.703608949168256],
            [28.159718186183881, -22.723608840752988],
            [28.161945090225771, -22.744173002061089],
            [28.156600118193836, -22.771463975962376],
            [28.109718121945861, -22.806117948909687],
            [28.049445197147435, -22.863055043873104],
            [27.972218196748571, -22.952781976512441],
            [27.943882167156431, -23.031390985110633],
            [27.783882196288261, -23.170281970962861],
            [27.704582183590247, -23.200835852141395],
            [27.604027173122489, -23.219164056952138],
            [27.572773235388837, -23.266673019559477],
            [27.565200185848624, -23.310835926409737],
            [27.55721809943239, -23.348335890769178],
            [27.529091282144151, -23.379381957303565],
            [27.502082275466393, -23.383890918310357],
            [27.452773215328165, -23.385281978965281],
            [27.407218241994514, -23.387217863328061],
            [27.33971810498187, -23.399726848023946],
            [27.148054153675417, -23.524726840980847],
            [27.098609139067293, -23.58917294774399],
            [27.069718227483435, -23.636108923448617],
            [27.03055412006276, -23.653817873251015],
            [27.005209256446307, -23.643755063184642],
            [26.978336204238389, -23.691673062668087],
            [26.959191266779982, -23.75207305711902],
            [26.963318180638993, -23.759773008673605],
            [26.919718202407466, -23.927499920824772],
            [26.880554094986991, -24.087782026554649],
            [26.852363239958237, -24.253754941642399],
            [26.830409191461655, -24.275135835598164],
            [26.787773299736244, -24.295281907574761],
            [26.759163349547578, -24.304172927573845],
            [26.726318191310554, -24.310835870064736],
            [26.555000136552792, -24.436945962281257],
            [26.514100136979891, -24.480346115940421],
            [26.493609233505708, -24.511108874146728],
            [26.485000180730168, -24.530555057206101],
            [26.477918143079449, -24.554726957289049],
            [26.461391209266054, -24.58444600090904],
            [26.440282224250097, -24.606390996950111],
            [26.411045140064061, -24.628272961079134],
            [26.401145106557834, -24.631890925775849],
            [26.389963312414437, -24.627426891770213],
            [26.360000187773352, -24.618890929190599],
            [26.265273282237871, -24.637499927758469],
            [26.167827119666555, -24.663963943090209],
            [26.018891261020883, -24.710835881054308],
            [25.918327198097728, -24.738335899621433],
            [25.8713912223931, -24.744445971777211],
            [25.875273217040615, -24.813682000942464],
            [25.872218180962619, -24.90528195367051],
            [25.827773306888758, -25.041946035480848],
            [25.769445151270503, -25.208335866433728],
            [25.684718191527338, -25.435555012919423],
            [25.671391133079226, -25.470281908424582],
            [25.62221819504893, -25.55083585384105],
            [25.589973181080921, -25.614691036428738],
            [25.581700242623612, -25.625026928901306],
            [25.509718134332417, -25.677781957082686],
            [25.414163264399093, -25.733335870380188],
            [25.360836087505305, -25.754581983332528],
            [25.17500024113113, -25.761945988206946],
            [25.152218228236535, -25.762217897146755],
            [25.128336174366211, -25.75278205343966],
            [25.086663195680245, -25.735146026195025],
            [25.033054051562829, -25.728617864707644],
            [25.012354103422894, -25.734173054871704],
            [24.953609199577699, -25.771117969601335],
            [24.900691227198081, -25.808126922071409],
            [24.801663231855173, -25.832226905424889],
            [24.684573240947003, -25.828217841124882],
            [24.648891143753247, -25.822635828870602],
            [24.621945168987622, -25.812363974138378],
            [24.533054079735422, -25.77194610163734],
            [24.501345172296112, -25.761390938577136],
            [24.472218226318063, -25.746672987112177],
            [24.438818186089293, -25.739864031867569],
            [24.398745145085826, -25.760699934477415],
            [24.351663324265672, -25.75555495465585],
            [24.281391124227071, -25.720281894338285],
            [24.243054143014092, -25.676108929204148],
            [24.22832713909375, -25.649654972156284],
            [24.189236121869016, -25.623190956824629],
            [24.115273202213899, -25.634164041578359],
            [24.046391224639819, -25.651526818778706],
            [24.006800143070421, -25.657917852329788],
            [23.901945048623645, -25.624863984703083],
            [23.889300109457849, -25.605135834420082],
            [23.871663244022898, -25.583617980166025],
            [23.736245048303999, -25.463755088041665],
            [23.71374517027121, -25.454445978710908],
            [23.670273267520571, -25.43583580667655],
            [23.650273208297762, -25.423335874436162],
            [23.596109182188627, -25.389445995784413],
            [23.53082706440054, -25.337226906493541],
            [23.454373210752664, -25.277017851797467],
            [23.275273229427171, -25.265835890015978],
            [23.194445028138034, -25.264164035603912],
            [23.014827212832728, -25.2997269421341],
            [22.97250011042118, -25.337226906493541],
            [22.910000113942772, -25.396945955128658],
            [22.830000212327633, -25.530835794618241],
            [22.812218172329295, -25.568408849173622],
            [22.830000212327633, -25.614446117217241],
            [22.812845138688573, -25.678264084154648],
            [22.777218194417941, -25.711873001411163],
            [22.756391176625499, -25.736254951988272],
            [22.743327142299705, -25.79805505954954],
            [22.745000170178344, -25.848335917544688],
            [22.722909161383484, -25.973890960131399],
            [22.707145148399348, -26.003608997922981],
            [22.663609207908365, -26.015208881397911],
            [22.626391210772539, -26.093054969167014],
            [22.624809210362656, -26.111564055448881],
            [22.479163248722557, -26.202781961029146],
            [22.366391279066335, -26.286390942523596],
            [22.249445121617185, -26.341117897251564],
            [22.197082198867406, -26.394172997567466],
            [22.175554118691451, -26.431117912297097],
            [22.158327128323009, -26.492573020722702],
            [22.133891198289177, -26.539864053846799],
            [22.056736114619895, -26.62444600666457],
            [22.015827062591399, -26.644446065887365],
            [21.962218086112159, -26.661808843087712],
            [21.891663248297704, -26.66889088073853],
            [21.862636046967737, -26.662081925493922],
            [21.808327181571372, -26.666390894290444],
            [21.769754165964429, -26.683964057261022],
            [21.774027092577143, -26.734172998526702],
            [21.778745098249686, -26.767499948559617],
            [21.772636199560282, -26.789864039760587],
            [21.750418121113029, -26.818472984120859],
            [21.693054052000576, -26.853055040338717],
            [21.668327102287918, -26.863681952490396],
            [21.613054150385551, -26.860835961078251],
            [21.516663269427085, -26.852499990708893],
            [21.481391214937844, -26.842226962510296],
            [21.460273177466291, -26.829726862631816],
            [21.435691234678842, -26.823755091878724],
            [21.410554074623604, -26.821663974668667],
            [21.377636161466882, -26.821390892262457],
            [21.338891149206205, -26.82902697371469],
            [21.276945028891134, -26.845555080994473],
            [21.177773200089405, -26.862226854095013],
            [21.144163277004481, -26.866672950827834],
            [21.128891281738106, -26.866390983604219],
            [21.101109128309275, -26.863054986131118],
            [21.012500173918767, -26.847500017812735],
            [20.988327100369247, -26.837635858852153],
            [20.966245144029983, -26.817155013661875],
            [20.924163296105974, -26.798617931823372],
            [20.892082232164597, -26.795345972090715],
            [20.855827148068215, -26.804999912918973],
            [20.823473169358635, -26.817635799629358],
            [20.80180024989545, -26.841526905955163],
            [20.763882196204406, -26.861117928301866],
            [20.703891238629495, -26.875555086009598],
            [20.641109107289395, -26.825972943465104],
            [20.618891196480206, -26.767499948559617],
            [20.601945167506898, -26.536390928437299],
            [20.603336060523759, -26.516391036852582],
            [20.609718209257437, -26.454446090003984],
            [20.623245092277784, -26.438863964319211],
            [20.652218146513036, -26.425835804539247],
            [20.691800175627122, -26.388055046422579],
            [20.744445065600445, -26.320835870805141],
            [20.757218080247128, -26.304446064928129],
            [20.796391240123114, -26.249446027793965],
            [20.860282297256447, -26.141808979675488],
            [20.81027318056303, -25.88083590900797],
            [20.783882255427216, -25.824717893625163],
            [20.739436207886769, -25.763055081638257],
            [20.682154113787789, -25.685690953303023],
            [20.679163283088343, -25.636672912843849],
            [20.689091144513213, -25.591181977250713],
            [20.650000127288564, -25.479446010829946],
            [20.631945172522109, -25.436946073574262],
            [20.609718209257437, -25.397364044460261],
            [20.536391140778903, -25.298055087722119],
            [20.449718238389153, -25.153617964161256],
            [20.390000195582445, -25.03694606258469],
            [20.261945166547662, -24.931663993988991],
            [20.237082262364993, -24.91610885803253],
            [20.166109167580942, -24.886390987879025],
            [20.132218115462734, -24.884446051060763],
            [20.059163291200178, -24.826390977848703],
            [20.044445172097141, -24.812226902547081],
            [20.000945106151903, -24.76540894403955],
            [20.001663267617943, -24.765554956793252],
            [20.002218149609661, -24.724163945330801],
            [20.001945067203479, -24.572226862425694],
            [20.001109223816513, -24.406390907636236],
            [20.000554174186703, -24.282217873248968],
            [20.000000130385104, -23.95083580988269],
            [20.000536069275796, -23.864409000170824],
            [20.000273212791484, -23.757226921758004],
            [20.000000130385104, -23.647499929895858],
            [19.999445080755294, -23.454173008994786],
            [19.998900257047467, -23.308699882198539],
            [19.998891204591956, -23.122781893173013],
            [19.998609237368441, -22.970835925450487],
            [19.998282175505608, -22.959917825981705],
            [19.997773226343327, -22.943335906883362],
            [19.997500143937117, -22.722499914959741],
            [19.997591171405787, -22.499999947613077],
            [19.997218176713517, -22.266945990672596],
            [19.996109250920284, -22.087500004383031],
            [19.99666329472177, -22.004999948681828],
            [20.000473205001754, -22.001564038922538],
            [20.30832709845086, -22.001390868802432],
            [20.64971816006485, -21.99999997578557],
            [20.917773268383286, -21.998335832724536],
            [20.991945065066062, -21.996946113174161],
            [20.992773197102167, -21.969446094607036],
            [20.992773197102167, -21.941946076039997],
            [20.992773197102167, -21.597226895941731],
            [20.992500114695872, -21.322226877908975],
            [20.992500114695872, -21.018890997922142],
            [20.993027168769061, -20.992454978147109],
            [20.99280923928589, -20.824272928652306],
            [20.993327073265505, -20.179172992359014],
            [20.993073269236589, -19.998217928217528],
            [20.99352706547586, -19.171946004127889],
            [20.994163251928626, -18.695281921358301],
            [20.994163251928626, -18.475564075321415],
            [20.993609208127197, -18.338608973831953],
            [20.99329119871976, -18.318417974854299],
            [21.235554017428683, -18.311945972118338],
            [21.462500249146359, -18.304446012774108],
            [21.816945119164245, -18.238335930587908],
            [22.262218136263868, -18.153890938068344],
            [22.500000157160713, -18.111263931160252],
            [22.637218115134488, -18.086672935917392],
            [23.077773294199545, -18.004445962622384],
            [23.287109147296263, -17.99633580072522],
            [23.297109093088579, -17.995946042226507],
            [23.316391158483327, -18.015417873909641],
            [23.325836054645833, -18.044999957231326],
            [23.33000018415504, -18.076391022901376],
            [23.36305405178166, -18.127226930526334],
            [23.398054029693128, -18.176108848877618],
            [23.431109238424312, -18.193890888875956],
            [23.453473161987205, -18.203608867444657],
            [23.503054131065198, -18.248335876380224],
            [23.519718192814906, -18.266946048414667],
            [23.554163288734543, -18.326117926408884],
            [23.555063337499917, -18.361664069132743],
            [23.563891157949058, -18.437226926470458],
            [23.582427066320975, -18.475208850263868],
            [23.613254029905818, -18.485164036693121],
            [23.650554169692981, -18.469717865478245],
            [23.719163232498857, -18.425282043859781],
            [23.791663342407844, -18.354172994605833],
            [23.829854143228829, -18.315000002367924],
            [23.84402710334777, -18.289999970248871],
            [23.870418196121761, -18.263055001311656],
            [23.886936077479476, -18.264764071373918],
            [23.912218076822143, -18.236946043399456],
            [23.967218113956307, -18.184999868876787],
            [24.006663350410093, -18.167781930963756],
            [24.268891191986484, -18.01333580915508],
            [24.36305416307485, -17.949163958264606],
            [24.388336162417318, -17.945835839780429],
            [24.416945106777689, -17.953755061922749],
            [24.439791157412628, -17.978055037486584],
            [24.451945084688958, -17.999172907319945],
            [24.47360911933481, -18.032781992214453],
            [24.497909094898745, -18.059308871820178],
            [24.524163227374345, -18.059999875919885],
            [24.566318165494181, -18.05423580872808],
            [24.679163225346258, -17.944173037823859],
            [24.832773168491826, -17.837708953238973],
            [24.936109126802876, -17.813890937109107],
            [24.972773247775365, -17.821108929229823],
            [25.000000183936208, -17.826046037852009],
            [25.066391227517727, -17.832226853270782],
            [25.099091211191165, -17.827845967744878],
            [25.136391183340351, -17.796564034454491],
            [25.179373247667911, -17.782081949745532],
            [25.252382139101002, -17.795708912690159],
            [25.264427101635846, -17.802245958994959],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CAF", Country: "Central African Republic" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.866500215635426, 10.922444940757529],
            [22.880000108927561, 10.899718080786158],
            [22.90139122880521, 10.865553946261741],
            [22.944163242638695, 10.79805397688709],
            [23.009445192788888, 10.698609065679051],
            [23.060273221424836, 10.664445098792811],
            [23.30944507530242, 10.451664115843244],
            [23.323891285465749, 10.430000081197392],
            [23.53666321595972, 10.083609046687059],
            [23.669163335898929, 9.866945063261667],
            [23.693891123802103, 9.668054067379117],
            [23.660273154090078, 9.597773150161174],
            [23.649436191444352, 9.579164151593204],
            [23.626036097007898, 9.542845029756464],
            [23.64200915465787, 9.443127036142144],
            [23.664718077356525, 9.434718143214923],
            [23.649300236974454, 9.275973110893702],
            [23.564445034112481, 9.187218143749391],
            [23.539027080299917, 9.179444934360774],
            [23.499645043396157, 9.177218030318897],
            [23.478609148576055, 9.145273088485425],
            [23.473054126050101, 9.129718120167141],
            [23.44832717633733, 9.019582091428902],
            [23.457918085253539, 8.990836019132246],
            [23.48930026610617, 8.966245023889385],
            [23.509863253947799, 8.958326975213552],
            [23.532218125055294, 8.961109096523231],
            [23.561182294473213, 9.000553997701033],
            [23.581663307301483, 8.993745042456425],
            [23.58263611098684, 8.912218125716635],
            [23.562773179700315, 8.868609095029711],
            [23.536527093851817, 8.831600142559637],
            [23.508054103961456, 8.80805403536948],
            [23.497636068837551, 8.77180012473957],
            [23.529654100866765, 8.708336041755246],
            [23.557500124397961, 8.704444994652235],
            [23.739163291721667, 8.70527312668834],
            [23.763745066871138, 8.714582068380935],
            [23.781945028562802, 8.72333596044372],
            [23.823891257293241, 8.72778205717664],
            [23.925827102493855, 8.718054020323947],
            [24.015554035133192, 8.703891118488812],
            [24.051873156970032, 8.693744992304801],
            [24.08693616679335, 8.688327097715032],
            [24.141182167915844, 8.686736044849837],
            [24.174463352757215, 8.695891094799805],
            [24.201109255388218, 8.686945089515689],
            [24.268327089901277, 8.587500010669572],
            [24.241391173419458, 8.562218011326905],
            [24.201945098775354, 8.526109107622304],
            [24.163609123390785, 8.473054007306402],
            [24.140273234332966, 8.37582711532292],
            [24.153327210374869, 8.34916411360922],
            [24.162773280003847, 8.330553941574863],
            [24.184854062876639, 8.309864219356911],
            [24.210000107749408, 8.293053977215337],
            [24.230554043135527, 8.28444509207786],
            [24.253054088806408, 8.27832697329498],
            [24.336936152707153, 8.262782063260502],
            [24.479718185662392, 8.238053940081258],
            [24.627636145983587, 8.220273073549308],
            [24.660000182976972, 8.21860909812635],
            [24.721663330240119, 8.209718078127182],
            [24.804445185526873, 8.192782107437679],
            [24.843891260171063, 8.175553943602765],
            [24.87013617255306, 8.160973120074203],
            [24.938609113250948, 8.088609132273206],
            [24.947500133250117, 8.067082057925745],
            [24.943882168553415, 8.041391189345148],
            [24.970273261327293, 8.003336007717721],
            [25.017773171479149, 7.955282053764449],
            [25.053891127639162, 7.921109034422713],
            [25.084718091223891, 7.894864122040701],
            [25.111736150356961, 7.891244983877499],
            [25.139445045951874, 7.897782197820291],
            [25.186109112716679, 7.901944986225033],
            [25.225554013894396, 7.872773113245827],
            [25.255827101315788, 7.845691184010306],
            [25.287218166985838, 7.77889110355278],
            [25.299300177532814, 7.682917976649776],
            [25.293745155006832, 7.645418012290236],
            [25.267709119652409, 7.626736091164503],
            [25.243891271160521, 7.623336055950958],
            [25.194718165492134, 7.595973165320217],
            [25.182636154945271, 7.577918042915584],
            [25.200273188018286, 7.516391185398589],
            [25.221454089763711, 7.494309061421234],
            [25.267773157392867, 7.475282141159752],
            [25.335000211999386, 7.420554012965397],
            [25.346800255322734, 7.401391138234104],
            [25.352082195442506, 7.372009047122674],
            [25.369863229612349, 7.343891114651768],
            [25.455000232059945, 7.297782097516858],
            [25.623891222927369, 7.21110902748903],
            [25.729718115231094, 7.168054040603522],
            [25.850273184921662, 7.109445091228039],
            [25.984445159272752, 7.018335982751367],
            [26.047773287787351, 6.963891162885005],
            [26.052218211053599, 6.93278206443857],
            [26.064163261302355, 6.903053968363167],
            [26.097636056450909, 6.845691072717216],
            [26.119163298436519, 6.82610910282601],
            [26.270418094421245, 6.705973128295454],
            [26.315900145196963, 6.700135970907795],
            [26.360836198798381, 6.683335954688289],
            [26.404027140153715, 6.644027007980384],
            [26.406282207390376, 6.6352449527228],
            [26.366109254100735, 6.583335993850341],
            [26.313609203414643, 6.496391182520881],
            [26.298054067458281, 6.465553993014069],
            [26.30437318427991, 6.390000020493858],
            [26.37250012001374, 6.346391157444927],
            [26.45485416296134, 6.297782153861846],
            [26.525418220869284, 6.216245011200172],
            [26.528473256947166, 6.19236413079625],
            [26.518745052456495, 6.169444989965456],
            [26.500000267056805, 6.156526968393365],
            [26.463536138294558, 6.130973060110989],
            [26.442773158242687, 6.102218102996915],
            [26.442291198808704, 6.077427115543799],
            [26.565282217206999, 6.02833598488867],
            [26.612218192911513, 6.014444991993344],
            [26.668891257924145, 6.007218115055224],
            [26.697500202284516, 6.01750002807124],
            [26.73555404280728, 6.009164225340058],
            [26.812909118687031, 5.977636031733624],
            [26.824027210366154, 5.960418093820508],
            [26.828327126707194, 5.91444503115541],
            [26.852982159690498, 5.891391109321106],
            [26.923882164278695, 5.865554060348813],
            [26.98166331689427, 5.859444994021246],
            [27.110273227920771, 5.791109013621565],
            [27.142773219384054, 5.771944965423884],
            [27.201945097378342, 5.708609125558439],
            [27.240836122392551, 5.647782156958485],
            [27.27889113638193, 5.583891099825152],
            [27.279863269514834, 5.544582153117346],
            [27.257427093946404, 5.509509085010137],
            [27.236318108930362, 5.426453979679025],
            [27.254718230470672, 5.331391127463277],
            [27.275000256916968, 5.282500156656681],
            [27.297782269811563, 5.230973077293925],
            [27.320273263026962, 5.208336071324737],
            [27.365418193656041, 5.17055397210359],
            [27.400827208443474, 5.150345035853121],
            [27.459718125042599, 5.071600072784932],
            [27.458563266419816, 5.025827002330189],
            [27.45527320177618, 5.016391158623094],
            [27.424582192661347, 5.069718000240627],
            [27.40235405593026, 5.084582132097367],
            [27.313327179846027, 5.123609111581558],
            [27.142082215284262, 5.19500012805922],
            [27.090273168697962, 5.203473058726885],
            [27.066663359043531, 5.201109026748611],
            [27.027500257451351, 5.185836025653856],
            [27.008609124021717, 5.177782190146104],
            [26.941527076340577, 5.143609003166375],
            [26.90833608077773, 5.103054002729039],
            [26.884718224496197, 5.066109087999408],
            [26.869445055763265, 5.030973155618128],
            [26.793054066389544, 5.058053911387276],
            [26.758400261080311, 5.087635994708876],
            [26.715554151222477, 5.092500013135123],
            [26.680273211915988, 5.088053916402302],
            [26.62082707804899, 5.068609074447394],
            [26.520836169666552, 5.041945066905384],
            [26.494718159298856, 5.045553979146788],
            [26.462709179725124, 5.059164178284888],
            [26.446109155715874, 5.088191044338686],
            [26.393891239891389, 5.132782098804341],
            [26.19750023045691, 5.232782059642275],
            [26.127500274634286, 5.24916415416827],
            [26.10860914120471, 5.234164067841718],
            [26.089582220943242, 5.206664216912671],
            [26.065554154319216, 5.197500114507307],
            [26.028054022321697, 5.190691159262784],
            [25.911391173200627, 5.178609148715822],
            [25.891663358193711, 5.192500141611148],
            [25.815554168405498, 5.236391139521587],
            [25.715554039929572, 5.283054032820004],
            [25.635554138314433, 5.305273117095766],
            [25.584582276219578, 5.364864090249895],
            [25.540136061041238, 5.380691135146165],
            [25.361945180936516, 5.314718013258272],
            [25.339163335680098, 5.263336108458816],
            [25.312218199104791, 5.196109053852368],
            [25.310418101573759, 5.175282203698117],
            [25.328609178448204, 5.153191027265166],
            [25.348682160228861, 5.142845076508792],
            [25.343609097136863, 5.11166422897098],
            [25.316182168765579, 5.041454055016104],
            [25.290000120657538, 5.025000043760556],
            [25.22777320658534, 5.006945088994016],
            [25.181945150845451, 4.995836049770475],
            [25.089718231758127, 4.973609086505704],
            [24.995691215139772, 4.982636060974869],
            [24.974163302601909, 4.98750007940103],
            [24.950827078267821, 4.985836103978073],
            [24.818336178422186, 4.938891075817949],
            [24.777773298995839, 4.915282104353835],
            [24.734445062065959, 4.9108360076211],
            [24.642773192608445, 4.973054036875979],
            [24.605000145842894, 5.022009045423118],
            [24.525554120391092, 5.077218127222949],
            [24.476218238162659, 5.098427024525165],
            [24.459445044033288, 5.105418034707213],
            [24.387291274364628, 5.112154067393959],
            [24.357354133623375, 5.057009023334388],
            [24.397918186516307, 5.035282124414664],
            [24.367500259807571, 5.008891031640772],
            [24.239718145540991, 4.958891135040844],
            [24.191663353397416, 4.941944938429543],
            [24.063054112923027, 4.901664193864789],
            [23.888609105358029, 4.823891028653549],
            [23.826663320319113, 4.819373182829253],
            [23.579718202845243, 4.732364166121258],
            [23.439718123561676, 4.650482191961913],
            [23.429582223299462, 4.631809155653599],
            [23.424509160207464, 4.593745089208838],
            [23.382500234841132, 4.588053944574881],
            [23.325900260024355, 4.6000730908478],
            [23.211936048457261, 4.681945006723339],
            [23.098818241475044, 4.707500088472116],
            [23.01680014520781, 4.749582104034289],
            [22.997500142540133, 4.792773213027687],
            [22.970691128072815, 4.831182110970019],
            [22.89332716737556, 4.820136103658527],
            [22.847573207660218, 4.723126973519953],
            [22.780282282951219, 4.71242697117242],
            [22.75444506634085, 4.653891111992877],
            [22.725973249916876, 4.572782116946513],
            [22.683682189688938, 4.479864193759383],
            [22.661109221460293, 4.48278210190098],
            [22.628745184466908, 4.487218140349995],
            [22.599300229081422, 4.474309003595422],
            [22.588054061921582, 4.453335973049292],
            [22.587218218534559, 4.411664167829699],
            [22.570273195389632, 4.331945059971787],
            [22.538263209987321, 4.219235954589621],
            [22.497218203489012, 4.178891172284338],
            [22.453600120346692, 4.14458219847269],
            [22.421527103032417, 4.134444957105998],
            [22.386454034925123, 4.12826414168714],
            [22.346663296421326, 4.127500047391578],
            [22.285554025321716, 4.12944498420984],
            [22.251945108065172, 4.136527021860559],
            [22.213191210987276, 4.148609032407521],
            [22.193054023828097, 4.15555394212204],
            [22.150973181732411, 4.176318095640497],
            [22.11944515576414, 4.20402699123521],
            [22.097500159723069, 4.215836087013969],
            [22.03735413693903, 4.225273104187636],
            [21.986945035824817, 4.246391141658989],
            [21.830827059604076, 4.265273054995063],
            [21.736663250325591, 4.295691149341877],
            [21.65513616594771, 4.299164107113285],
            [21.630691183458566, 4.292500158793985],
            [21.597082266202051, 4.271945049941465],
            [21.576391202879535, 4.255282161658158],
            [21.548263212124823, 4.246244961267195],
            [21.513882153945616, 4.247218100228608],
            [21.208609216129531, 4.288891078914588],
            [21.163745079257694, 4.305553967197895],
            [21.103263277431381, 4.350282149599849],
            [21.077918078538772, 4.389236038888185],
            [21.034445169959838, 4.408891098975332],
            [20.969863276364862, 4.432500070439445],
            [20.873891155290067, 4.44528213754154],
            [20.835554174077203, 4.448891049782944],
            [20.715273192621339, 4.426664086518173],
            [20.599791186022884, 4.40798216539244],
            [20.571945162491687, 4.417500151750886],
            [20.476663207326482, 4.498891114020864],
            [20.452782159284482, 4.522382068287897],
            [20.453300160902245, 4.523791066215651],
            [20.457982292029072, 4.619309055775034],
            [20.339300242427299, 4.767500098502524],
            [20.178336185053212, 4.872218065013016],
            [20.002082195139849, 4.977217998747108],
            [19.978054128515822, 4.983744986768016],
            [19.951663203380008, 4.984445043323134],
            [19.927218220890779, 4.987218112177501],
            [19.901391230202194, 4.995135993215257],
            [19.880418199656077, 5.011664100495054],
            [19.85810909374004, 5.061853931021432],
            [19.841391220171772, 5.084509041901512],
            [19.812636095419805, 5.099864185647633],
            [19.760000257901794, 5.118336056279205],
            [19.700482207305527, 5.136664093451856],
            [19.649300127078334, 5.142782044596743],
            [19.557218214916418, 5.150553912880795],
            [19.415900165173895, 5.131035980729948],
            [19.22791821630463, 4.999864224809784],
            [19.207773150156413, 4.969164163239356],
            [19.185409226593606, 4.944236047849856],
            [19.106391181119221, 4.932500042266952],
            [19.085418150572991, 4.914718002268614],
            [19.064163320441423, 4.889653932409203],
            [19.028609131090462, 4.81500000865438],
            [18.987918176183314, 4.740553956098822],
            [18.955554139189843, 4.712218094144745],
            [18.83944516623211, 4.577782089842671],
            [18.822500143087296, 4.543745024970832],
            [18.803609177295812, 4.484717986263846],
            [18.793891198726925, 4.460282056230099],
            [18.771245140302426, 4.415282132526414],
            [18.751663338049127, 4.390554009347269],
            [18.733054171843264, 4.378891093960121],
            [18.707500263560888, 4.367218120289351],
            [18.628745074570816, 4.354236060976916],
            [18.591036065545609, 4.370073164156892],
            [18.567218217053721, 4.361809110517001],
            [18.544445088976659, 4.340000068945841],
            [18.53576328128122, 4.31166420699185],
            [18.543745032421441, 4.288336029284778],
            [18.567363223979129, 4.260973138654123],
            [18.592500216396388, 4.228745056130649],
            [18.630273263161939, 4.134718039512194],
            [18.64389117365107, 4.053891179327522],
            [18.647082164198963, 4.027500086553729],
            [18.648191257630202, 3.993053984805698],
            [18.643327071565864, 3.967218109299694],
            [18.610554165334463, 3.866945066055649],
            [18.595000202844375, 3.769164130270568],
            [18.590691234047938, 3.725836060978963],
            [18.592500216396388, 3.700836028859911],
            [18.598327147862079, 3.666109133354837],
            [18.618609174308602, 3.549444943129117],
            [18.624954107392057, 3.479444987306479],
            [18.600763264207899, 3.48152705206104],
            [18.581391177172662, 3.501109021952331],
            [18.568336195302464, 3.538891121173378],
            [18.53069122401763, 3.60118207298612],
            [18.47971818845636, 3.640836018829589],
            [18.45194508748304, 3.616245023586728],
            [18.262254068551584, 3.564336064714269],
            [18.250554105152418, 3.52652697576481],
            [18.235136097132255, 3.501244976422143],
            [18.21471811621592, 3.487918085612122],
            [18.193609131199878, 3.482500023384375],
            [18.169373193376487, 3.490073072924545],
            [18.162363240093327, 3.525282095501666],
            [18.147563313615109, 3.547709050976692],
            [18.110973288666941, 3.562218125413978],
            [18.06582718457139, 3.56694501590384],
            [18.043327138900509, 3.565836090110608],
            [17.909445178399835, 3.552782114068705],
            [17.85805405350709, 3.536664217131502],
            [17.840000104568929, 3.586109064101706],
            [17.82937319241725, 3.616045031376288],
            [17.763336200427005, 3.627218108340458],
            [17.681109227131998, 3.630000062012144],
            [17.642218202117704, 3.629445012382249],
            [17.615273233180403, 3.629718094788529],
            [17.563891160743026, 3.653335951069977],
            [17.521945099650765, 3.686109024939555],
            [17.491109251248616, 3.710073053823137],
            [17.463609232681563, 3.711109057058508],
            [17.415963309776004, 3.680573113152789],
            [17.348891152740606, 3.613164171246908],
            [17.109718071188837, 3.56750006553375],
            [16.957427104330662, 3.554864178823351],
            [16.876663276058025, 3.565836090110608],
            [16.699163232415032, 3.545282154724561],
            [16.664445054089299, 3.535417995763979],
            [16.643054101849657, 3.522782109053594],
            [16.586245082367128, 3.477917972181643],
            [16.573682285852669, 3.44965402695712],
            [16.56847326829066, 3.41028204833718],
            [16.558745063800075, 3.330973150821848],
            [16.527773260928029, 3.234164180531707],
            [16.505000132850938, 3.202500032455461],
            [16.492500200610465, 3.181109080215805],
            [16.482709131845809, 3.155000122303605],
            [16.466109107836559, 2.949718092870057],
            [16.469509143050203, 2.91097308060948],
            [16.495554063221959, 2.884309073067385],
            [16.501663297187434, 2.849445049625913],
            [16.480273183138081, 2.802218054242189],
            [16.441318120383357, 2.720009018219997],
            [16.404163322797558, 2.639718096925847],
            [16.305827169744674, 2.430000029043271],
            [16.270836076650738, 2.355553976487812],
            [16.207000172440416, 2.221264152577518],
            [16.192500150458727, 2.237500066711817],
            [16.179718083356562, 2.260000112382784],
            [16.133473279389818, 2.354864145854577],
            [16.111382270594959, 2.410554013621976],
            [16.106109215292605, 2.432500015491442],
            [16.094718208845364, 2.497782133279628],
            [16.090554079336044, 2.538053993026949],
            [16.070554020113264, 2.79083610025053],
            [16.086663199871225, 2.80374506936711],
            [16.112354068451822, 2.825627033496133],
            [16.111945031575715, 2.858609152031292],
            [16.103054011576631, 2.898336020432609],
            [16.073609223829322, 2.943335944136379],
            [15.940000178096852, 3.100553961332949],
            [15.906945137003845, 3.112500017410014],
            [15.865273164146174, 3.108336055538885],
            [15.8175632035001, 3.100418006863052],
            [15.767773189756326, 3.147500162959375],
            [15.590273146113418, 3.344718130963457],
            [15.295273236495945, 3.639718040580945],
            [15.267173241297854, 3.666818074727374],
            [15.26027325858459, 3.673891059922681],
            [15.224163349051594, 3.721945013875953],
            [15.137500169669465, 3.838335954057285],
            [15.122218116119313, 3.858891062909905],
            [15.076945110009348, 3.920554042534889],
            [15.06389113396753, 3.961391177834003],
            [15.043891242382728, 4.029164229614764],
            [15.14500012901388, 4.071809006157693],
            [15.103473163081617, 4.140418068963655],
            [15.093609171759113, 4.235000135211834],
            [15.093609171759113, 4.266809122575395],
            [15.086945055801834, 4.294718010380464],
            [15.01368219270185, 4.414718030441108],
            [14.930554164812889, 4.460553965169908],
            [14.892218189428405, 4.476664150756179],
            [14.804300239137433, 4.538126970532815],
            [14.737363198381701, 4.615345086114345],
            [14.723473211314769, 4.643744985808766],
            [14.720273168311479, 4.72333601818579],
            [14.720418175236802, 4.805282197723756],
            [14.710836151137983, 4.878054048934288],
            [14.691109174321383, 4.98139118071181],
            [14.672954139630662, 5.012054026631944],
            [14.675836173226628, 5.015553974131578],
            [14.686036111229299, 5.037500143639122],
            [14.687791114121097, 5.11907316084654],
            [14.672500175753328, 5.180000041732669],
            [14.651454055011442, 5.219509148288907],
            [14.624718130739893, 5.233609018211908],
            [14.605136160848673, 5.23791798700843],
            [14.585418068849492, 5.245273107065344],
            [14.553609249124008, 5.267782037553644],
            [14.5326360509398, 5.291244996264055],
            [14.551391229899536, 5.342500166687088],
            [14.565973226894585, 5.358891146030501],
            [14.591391180707063, 5.404718028303805],
            [14.617218171395649, 5.495836021597981],
            [14.622363318855292, 5.520691046791626],
            [14.626800195494525, 5.724718137678323],
            [14.623054155316822, 5.833891085738955],
            [14.617218171395649, 5.901245042359889],
            [14.604718071517169, 5.922218072905935],
            [14.580273256666004, 5.927218045802178],
            [14.556363207239059, 5.906635947221247],
            [14.492845144798025, 5.915973219746732],
            [14.424163327072449, 6.006945032649114],
            [14.418891277598306, 6.040973212703534],
            [14.42979127215628, 6.079682182780473],
            [14.449163191553254, 6.096109036669532],
            [14.580273256666004, 6.188891173024828],
            [14.742363338915936, 6.264718060313342],
            [14.805000128054644, 6.346664072213045],
            [14.796945119080419, 6.390000020493858],
            [14.826663324510093, 6.433609051180682],
            [14.938027134428893, 6.686491070690352],
            [14.961945062844848, 6.735973133310665],
            [14.996109197369265, 6.740835978270525],
            [15.052918216851793, 6.771318110357697],
            [15.129718075463643, 6.951109095782911],
            [15.136391243876318, 6.985282115124662],
            [15.142082220872283, 7.016527000403002],
            [15.210000111940275, 7.191109135904298],
            [15.231391231818009, 7.236109059607983],
            [15.256736095434547, 7.260091025764481],
            [15.261109101971613, 7.268609051071365],
            [15.299163277770475, 7.297500130293329],
            [15.374718088481103, 7.347500026893087],
            [15.445554055328842, 7.394718137459492],
            [15.499009140065453, 7.526609060674076],
            [15.518609214867638, 7.518335954578774],
            [15.542500153555295, 7.51389103131244],
            [15.565691203325883, 7.51263609276549],
            [15.616945032644367, 7.518609036984984],
            [15.642636068863055, 7.523053960251318],
            [15.690136146652975, 7.52291800578142],
            [15.713609163647192, 7.506808993661622],
            [15.72791807823603, 7.476944943116322],
            [15.782709238342449, 7.458536104396799],
            [15.913891220184667, 7.482782100503997],
            [15.974863195709929, 7.501318008876012],
            [16.01874514116497, 7.54007307942048],
            [16.034445116408733, 7.571391054894676],
            [16.079445040112432, 7.594444976728894],
            [16.113054125006926, 7.596109119790029],
            [16.148609152548119, 7.600836010279991],
            [16.217636137047521, 7.614373119222321],
            [16.40750015846109, 7.69110910773172],
            [16.404163322797558, 7.714718079195833],
            [16.410000144909162, 7.767218129881925],
            [16.430827162701576, 7.798053978284074],
            [16.503054022566118, 7.850554028970166],
            [16.533609244849202, 7.867773140349584],
            [16.567709173995098, 7.864945086210355],
            [16.563682172422176, 7.8302091382497],
            [16.568054173130662, 7.783191187531813],
            [16.781663288116334, 7.565836032368622],
            [16.817454014775421, 7.546300162945045],
            [16.831527062608274, 7.549309098555312],
            [16.860000220136726, 7.569164150852799],
            [16.866182209021986, 7.59423609970122],
            [16.86979112126329, 7.621244938740816],
            [16.913327061754359, 7.642500104148638],
            [16.984445163463818, 7.654444986759316],
            [17.058891216019276, 7.662773078139651],
            [17.13833606800452, 7.700554003894311],
            [17.218609219663932, 7.758609077106371],
            [17.237218218231817, 7.803053951180232],
            [17.255000258230154, 7.812500020809225],
            [17.369445088126753, 7.867218090719774],
            [17.450691211109387, 7.901600154727362],
            [17.51082717560962, 7.912218014423544],
            [17.587218164983454, 7.93374508877109],
            [17.62763620512257, 7.967154013817265],
            [17.648891202892315, 7.988473216965446],
            [17.718327056629732, 7.983609030901121],
            [17.883609135255853, 7.958336084013951],
            [18.032773149306593, 8.013473081446421],
            [18.240836126583531, 8.02555391852691],
            [18.408054089572431, 8.033054045509218],
            [18.561109150726253, 8.039717993828518],
            [18.588891136516906, 8.040282095913739],
            [18.618327207084889, 8.060273102681137],
            [18.635554029815324, 8.087218071618366],
            [18.634718186428302, 8.13527319903811],
            [18.645273181850513, 8.16528208887064],
            [18.665554034830535, 8.193891033230912],
            [18.686663187484584, 8.214164174859917],
            [18.704718142251039, 8.223609071022508],
            [18.759163297393457, 8.243473175775407],
            [18.802154078900344, 8.256109062485791],
            [18.840273298268215, 8.292218133828385],
            [18.904163349573082, 8.367218062547281],
            [18.922500103925159, 8.392426971693993],
            [18.931800160800435, 8.423891127559898],
            [18.987500254489703, 8.493609116159021],
            [19.058791191043127, 8.578382176369729],
            [19.088609141120799, 8.64110915478679],
            [19.125827138256625, 8.672782187680454],
            [19.115000233894705, 8.691391186248424],
            [19.030691195845037, 8.754864154050154],
            [18.987218119628011, 8.765273136718662],
            [18.925554134174632, 8.79416421594064],
            [18.87637314951732, 8.841427085869924],
            [18.869718086015439, 8.861245090155293],
            [18.892500266548012, 8.891391107924207],
            [18.929445181277657, 8.920553928447816],
            [18.988891147506564, 8.964164132601212],
            [19.023473203724421, 8.984444985581149],
            [19.086936113242331, 9.009445017700116],
            [19.109445043730631, 9.013609147209323],
            [19.193318222813872, 9.020991089356571],
            [19.411391204167018, 9.016109133657395],
            [19.610836076212991, 9.02666412907962],
            [19.830554089887954, 9.053054048387025],
            [19.905554018606836, 9.059164120542903],
            [19.933754093729107, 9.057518082392676],
            [19.963336177050792, 9.08000002315265],
            [20.064718146088069, 9.134864105816916],
            [20.186945070190689, 9.125827073064045],
            [20.270273257927926, 9.118327113719801],
            [20.325418134349405, 9.104718088048088],
            [20.371663273592304, 9.108327000289407],
            [20.422218219822042, 9.139718065959457],
            [20.459163302189751, 9.175554054895855],
            [20.487782137195836, 9.209718021782095],
            [20.499854089458921, 9.241664137082054],
            [20.498473254725951, 9.275853920230134],
            [20.800827110933966, 9.4205540679134],
            [20.81684509558508, 9.415664233225215],
            [20.827218203707844, 9.451391089782035],
            [20.837773199130055, 9.476391121900988],
            [20.875691252821014, 9.511245087058654],
            [20.894027168982774, 9.520136107057823],
            [20.974445159929417, 9.603336051676152],
            [21.048054027993373, 9.766391058622318],
            [21.225000195472944, 9.942500041610373],
            [21.265627112639834, 9.978054063323142],
            [21.292773247253791, 9.972082124932058],
            [21.339163225784006, 9.95847309926026],
            [21.363336131695348, 9.969027088854077],
            [21.408336055399133, 10.006944974907043],
            [21.43527331298543, 10.0418089983485],
            [21.454163272948591, 10.092773149092423],
            [21.454445072534128, 10.113745006172081],
            [21.473609120731822, 10.14778207104392],
            [21.5000002135057, 10.177773191241712],
            [21.520273187496713, 10.199444937238496],
            [21.537218210641527, 10.211664075721842],
            [21.568891243535205, 10.212918008440397],
            [21.608745181589001, 10.212364132276974],
            [21.638882146902517, 10.223054076340702],
            [21.666945094088391, 10.23583597580479],
            [21.718400256721679, 10.296600080130688],
            [21.747218078109626, 10.409444972344772],
            [21.737773181947148, 10.448535989569407],
            [21.719445144774483, 10.468336056581862],
            [21.704854095323952, 10.498053926735366],
            [21.701109228612722, 10.520691100342631],
            [21.702500121629583, 10.563336044523552],
            [21.719027223080957, 10.63812709621476],
            [21.785827135900405, 10.668335978257645],
            [21.819718188018527, 10.67819108476273],
            [21.895836094986151, 10.705000099230148],
            [21.957218113215902, 10.732217982935481],
            [22.006109251660689, 10.768891156363395],
            [22.244718231127138, 10.910827120009699],
            [22.327918175745481, 10.943473124226699],
            [22.364109222101433, 10.955045012144993],
            [22.413818099022251, 10.964164187549216],
            [22.432782155009676, 10.986945026977324],
            [22.463327151370891, 11.000836019872651],
            [22.67639127501036, 10.969444954202601],
            [22.825000239431375, 10.931109146456123],
            [22.866500215635426, 10.922444940757529],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CAN", Country: "Canada" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-61.504105722999896, 86.367213008000306],
            [-60.378146721999826, 86.334058008000284],
            [-60.196062386666483, 86.336203946166791],
            [-60.085073156999897, 86.300584389000221],
            [-58.921728369999926, 85.927234170000247],
            [-58.906458996999845, 85.914953265000236],
            [-58.883030997999981, 85.89611051300011],
            [-58.408690812999964, 85.290973942000107],
            [-58.3726741239999, 85.245025872000099],
            [-58.295118040999853, 85.146129566500235],
            [-57.921824380999965, 84.975567252000246],
            [-57.718282156999862, 84.870155293000209],
            [-57.53460865649987, 84.760590970500232],
            [-57.528656289999958, 84.756365294000148],
            [-57.187742802999878, 84.514345067000079],
            [-57.18472425199991, 84.511730263000288],
            [-57.088985817999912, 84.428797331000283],
            [-57.074605514999973, 84.41414072400022],
            [-57.03695467599988, 84.375766456000179],
            [-57.020378305999827, 84.361646013000211],
            [-56.998926250999943, 84.343372258000215],
            [-56.980840906999845, 84.324940994000201],
            [-56.977918528999965, 84.321962719000226],
            [-56.93772981699999, 84.271875847000103],
            [-56.89886577999988, 84.232279987000254],
            [-56.858611726999868, 84.181157549000034],
            [-56.840068040999824, 84.161413705000143],
            [-56.820648479999818, 84.140737299000193],
            [-56.806563389999837, 84.121823319000214],
            [-56.79004733499994, 84.093674429000146],
            [-56.758274475999826, 84.039522834000195],
            [-56.720785313999954, 83.940976866000312],
            [-56.719056547999969, 83.928256178000254],
            [-56.718005024999854, 83.920518807000178],
            [-56.716558855999835, 83.909877536000181],
            [-56.717621777999909, 83.823781920000158],
            [-56.721706457999971, 83.808195181000031],
            [-56.743157580999878, 83.726339790333498],
            [-56.747136001999934, 83.718054039000265],
            [-56.804106676999879, 83.599403298000198],
            [-56.838151551999943, 83.552787013000227],
            [-56.859942164999836, 83.522949997000126],
            [-56.926243476999957, 83.44942046600022],
            [-56.974921556999846, 83.40772883800031],
            [-56.983468367999819, 83.40150121500028],
            [-57.085804861999975, 83.326933855000163],
            [-57.097460486999978, 83.320081807000065],
            [-57.144022129999883, 83.29270939300028],
            [-57.187215253999909, 83.260541611000065],
            [-57.206536603999893, 83.24615216900014],
            [-57.258490532999929, 83.213466352000296],
            [-57.259606708999911, 83.212610191000294],
            [-57.260581390999903, 83.211862563000238],
            [-57.37348459299983, 83.137567377000096],
            [-57.39300461699986, 83.127146596000216],
            [-57.41168492599985, 83.117174098000191],
            [-57.454036278999865, 83.085527005000301],
            [-57.499870071999851, 83.051277655000149],
            [-57.534517316999825, 83.030729529000155],
            [-57.561445245999892, 83.008193099000152],
            [-57.577678416999845, 82.994607289000271],
            [-57.680646020999859, 82.927153722000185],
            [-57.693856756999935, 82.914480975000174],
            [-57.720398504999821, 82.895913824000161],
            [-57.742281268999818, 82.880605853000247],
            [-57.750968776999912, 82.863006547000282],
            [-57.756189585999778, 82.85243014100007],
            [-57.862475251999911, 82.773183296000127],
            [-57.866225492999831, 82.771058063000055],
            [-57.969839858499938, 82.714687948000233],
            [-58.010993330999952, 82.692298938000135],
            [-58.014740480999819, 82.690737754000281],
            [-58.208415919999823, 82.610046331000035],
            [-58.233755855999846, 82.601103349000311],
            [-58.24276529499997, 82.597923734000176],
            [-58.402228069999865, 82.555388418500002],
            [-58.70222322599983, 82.47536730600018],
            [-58.943509546999906, 82.426340236000158],
            [-59.07351856299988, 82.3999236490003],
            [-59.280033789999891, 82.36712165900019],
            [-59.330983294999839, 82.353353985500291],
            [-59.41835516499998, 82.329744191000202],
            [-59.595003690999818, 82.296644136000168],
            [-59.819955355999866, 82.262093535000247],
            [-59.901475700999981, 82.249572725000235],
            [-59.917591830999925, 82.244060178500263],
            [-59.995477269999867, 82.217419350000227],
            [-60.226839361999907, 82.180054641000311],
            [-60.983484895999879, 82.057857267000145],
            [-62.162638275999825, 81.867425368000283],
            [-64.179832114999897, 81.301307371000291],
            [-66.266666666999924, 80.834722222000153],
            [-66.434220916999891, 80.819210381500284],
            [-66.480308392999973, 80.817971955000075],
            [-66.483378577499877, 80.817646080500253],
            [-67.118462919999899, 80.750015780500178],
            [-68.07156373599986, 80.488499166000167],
            [-68.330701990999898, 80.417388737000181],
            [-68.333333332999928, 80.416666667000129],
            [-68.997520248999933, 80.000732218000223],
            [-69.02960959699999, 79.967049413000154],
            [-69.330975353999975, 79.650718768000104],
            [-72.99821800899997, 78.802093537000133],
            [-73.765024793999942, 78.417358478000097],
            [-74.765242707999846, 77.500656473000049],
            [-74.998683562999872, 76.685066348333578],
            [-74.272133080999879, 75.978633589000196],
            [-73.26618483999988, 75.000539750000257],
            [-73.034235415000012, 74.835531423000191],
            [-72.968535584999813, 74.791890256000215],
            [-72.882009379999943, 74.734415152000167],
            [-72.229464848999839, 74.58121340300022],
            [-71.750830427999915, 74.46884156200025],
            [-71.685909194999851, 74.455543634000207],
            [-71.603356381999845, 74.438634200000195],
            [-71.417198952000035, 74.400503255000103],
            [-70.5504823039999, 74.201878184000236],
            [-70.46364440099984, 74.183965798000145],
            [-70.382139091999989, 74.167153386000166],
            [-70.278535032999912, 74.139514717000282],
            [-70.246772495999892, 74.131041359000164],
            [-70.199907238999856, 74.118539017000273],
            [-70.166853943999797, 74.112646394000251],
            [-70.131010361999927, 74.106256329000246],
            [-70.10017897299997, 74.100759820000121],
            [-69.865166940999927, 74.035477714000194],
            [-69.857564925999839, 74.035217772000181],
            [-69.83266456199982, 74.034366332000275],
            [-69.670326313999965, 73.992140588000041],
            [-69.516536956999914, 73.952138492000302],
            [-69.247847321999984, 73.884406075000072],
            [-69.18263067599986, 73.867965987500213],
            [-69.103515406999861, 73.844533754000167],
            [-68.848725882999872, 73.769070595000301],
            [-68.799833499999863, 73.767403504000299],
            [-68.660140176999846, 73.731408209000165],
            [-68.617948702000035, 73.72053657500021],
            [-68.482873112999982, 73.685731148000286],
            [-68.199247248999825, 73.61961521000012],
            [-68.083129265999958, 73.601835830000255],
            [-68.05690735099995, 73.599182073000122],
            [-67.268903239999815, 73.519433062000246],
            [-67.248631850999971, 73.517381520000129],
            [-66.782819028999882, 73.462687071000232],
            [-66.415803416999779, 73.41959311700009],
            [-66.132461484999965, 73.301808234000248],
            [-65.599731802999884, 73.05750476500009],
            [-65.116602812999986, 72.835947582000131],
            [-65.000766491499917, 72.785666826500176],
            [-64.966045070999883, 72.752331830000287],
            [-64.935399426999822, 72.737072019000038],
            [-64.923058233999939, 72.730926797000166],
            [-64.899099288999821, 72.718996587000163],
            [-64.63381489499983, 72.601211811000269],
            [-64.43186643599995, 72.501761645000158],
            [-64.356419135999886, 72.467184422000116],
            [-64.21547260199992, 72.402589139000099],
            [-63.681569539999828, 72.183686919000138],
            [-63.674203820999935, 72.180337720000239],
            [-63.641549036999834, 72.165489525000112],
            [-63.500732038999956, 72.101459755000064],
            [-63.399094846999844, 72.046373022000239],
            [-63.348507765999955, 72.018955135000169],
            [-63.050724923999894, 71.869502121000096],
            [-62.881833236999967, 71.784497251000232],
            [-62.834472772999902, 71.749025230000086],
            [-62.816555894999908, 71.735605855000074],
            [-62.742414499999825, 71.680208776000143],
            [-62.549613176999969, 71.536151194000297],
            [-62.537973037999961, 71.525226577000296],
            [-62.53155805699987, 71.519205926000154],
            [-62.482391493999842, 71.484759691000306],
            [-62.462759449999965, 71.465373626000257],
            [-62.416282267999918, 71.419478776000176],
            [-62.363444609999988, 71.373242719000075],
            [-62.282951331999868, 71.302806369000223],
            [-62.148507881999819, 71.200578960000087],
            [-61.910560492999821, 71.015841859000147],
            [-61.87846215199977, 70.990921416000106],
            [-61.700178453999854, 70.85250588800011],
            [-61.683103947999939, 70.841848131000177],
            [-61.671077488000037, 70.834341320000135],
            [-61.61740027399992, 70.800836473000231],
            [-61.485186160999831, 70.70053926900016],
            [-61.467126270999984, 70.68683909400022],
            [-61.333235597999931, 70.585270058000106],
            [-61.282680077999828, 70.550546043000224],
            [-61.20315623699986, 70.322766791000106],
            [-61.186527916999978, 70.27513848100017],
            [-61.16659069099984, 70.218032508000192],
            [-61.133817934999911, 70.135806919000174],
            [-61.131874843999981, 70.118306253000299],
            [-61.10013325899979, 70.070622176000256],
            [-61.072264190999874, 70.02875562200029],
            [-61.065490444000034, 70.018579701000192],
            [-60.998272606999819, 69.91913096199994],
            [-60.985547099999849, 69.917952546000151],
            [-60.98327184999988, 69.917741855000202],
            [-60.979530985999986, 69.896982688000264],
            [-60.976078678999841, 69.877824814000178],
            [-60.965501147999817, 69.819126952000033],
            [-60.906134008999913, 69.647267595000187],
            [-60.896476334999932, 69.619310012000028],
            [-60.849965779499883, 69.484669055000097],
            [-60.53404950599986, 69.261916136000139],
            [-60.449688186999879, 69.202432875000284],
            [-60.428534946999946, 69.191275649000147],
            [-60.420878289999962, 69.187237163000134],
            [-60.383853943999839, 69.167708759000277],
            [-60.299687744999972, 69.102427814000237],
            [-60.177970385999913, 69.022023552000178],
            [-60.148298534999867, 69.0024228690001],
            [-60.141893392999918, 68.998727420000137],
            [-60.032741669999893, 68.935752558000161],
            [-59.233277669999836, 68.63351161550014],
            [-59.232983169999841, 68.628499149000163],
            [-59.232445365999922, 68.619344700000056],
            [-59.115170449999766, 68.582215766000047],
            [-59.082986810999955, 68.572026581000159],
            [-59.066607272999875, 68.566840898000123],
            [-59.017162584999937, 68.536005980000255],
            [-58.995961557999919, 68.528110887000253],
            [-58.699377191999872, 68.417665246000126],
            [-58.689695390999873, 68.405246186000227],
            [-58.648268414999848, 68.352106886000286],
            [-58.635944912999776, 68.339493985000217],
            [-58.609598114999869, 68.312528472000281],
            [-58.565214994999906, 68.267103082000176],
            [-58.507622847999869, 68.193422691000194],
            [-58.476696276999974, 68.153856849000135],
            [-58.448831303999953, 68.118207854000275],
            [-58.44275329099986, 68.111908379000283],
            [-58.442200573000036, 68.111335521000171],
            [-58.433553925999945, 68.102373818000217],
            [-58.423089450999839, 68.082848048000244],
            [-58.414944451999986, 68.067650211000171],
            [-58.382446406999861, 68.01903709700008],
            [-58.345388659999855, 67.971882992000246],
            [-58.317171449999876, 67.935978024000235],
            [-58.237928680999914, 67.839794123000274],
            [-58.223944885999856, 67.822820764000198],
            [-58.150788945999864, 67.734024982000221],
            [-58.098847779499863, 67.652077164000161],
            [-58.08938897400003, 67.642460064000261],
            [-58.03218273199991, 67.584296468000275],
            [-57.950517901999888, 67.502070629000059],
            [-57.932864492000022, 67.484679571000129],
            [-57.931907363499874, 67.483736667500125],
            [-57.926953299999866, 67.478782281000179],
            [-57.922053472999835, 67.473882131000266],
            [-57.915714404499852, 67.467533531000072],
            [-57.912412335999875, 67.46386429900025],
            [-57.900796840999988, 67.45095720900008],
            [-57.886795538999877, 67.409978162000243],
            [-57.88288313399994, 67.398527323000224],
            [-57.866913362, 67.35178695400009],
            [-57.728922003999799, 66.867065579000041],
            [-57.714998530999821, 66.818156683000211],
            [-57.698158469999896, 66.771983685000066],
            [-57.666671822000012, 66.68565187500019],
            [-57.650008806999892, 66.619260666000287],
            [-57.64889890399985, 66.600371157000097],
            [-57.632515842999851, 66.500923342000249],
            [-57.617243875999975, 66.401753340000084],
            [-57.617245911999873, 66.370608697000193],
            [-57.617248075999896, 66.33751149700015],
            [-57.617250376999948, 66.302305727000089],
            [-57.632257545999835, 66.202580498000259],
            [-57.634886148999954, 66.180249900000206],
            [-57.644821694999848, 66.09584511900016],
            [-57.650045837999983, 66.051464806000297],
            [-57.656261337999865, 66.010911993000036],
            [-57.665330645999973, 65.951739616000168],
            [-57.665859296999884, 65.913953382000102],
            [-57.666725860000042, 65.852014294000242],
            [-57.666907576999847, 65.841512899000236],
            [-57.667004656999836, 65.835902684000303],
            [-57.669943792999845, 65.798861133000116],
            [-57.684241106999934, 65.618673913000123],
            [-57.691742112999862, 65.593814957000177],
            [-57.699244834999888, 65.568950317000031],
            [-57.734257606999847, 65.384222804000217],
            [-57.73612565499991, 65.374664019000193],
            [-57.750652253999874, 65.300331534000293],
            [-57.749016518999895, 65.245074162000265],
            [-57.748711611999965, 65.234773971000038],
            [-57.746962723999843, 65.229280895000272],
            [-57.739089098999841, 65.204550656000208],
            [-57.732880497, 65.185050081000099],
            [-57.729029063999917, 65.172653267000044],
            [-57.717604921999964, 65.135881773000108],
            [-57.719434329999899, 65.131344182000248],
            [-57.723032429999932, 65.1224195960001],
            [-57.732052120999981, 65.100047511000071],
            [-57.799050248999947, 64.200577323000175],
            [-57.804317642999763, 64.157832657000029],
            [-57.810933373999944, 64.104146295000135],
            [-57.815447011499884, 64.067518330000098],
            [-57.87039752499993, 63.973167060000151],
            [-57.883233971999914, 63.951126629000214],
            [-57.887241393999943, 63.944529488000171],
            [-57.917407013999821, 63.894869924000204],
            [-57.933518303999847, 63.868347026000208],
            [-57.9490763579999, 63.833623984000269],
            [-57.962251029999948, 63.76178383000024],
            [-57.967415690499934, 63.733621457500249],
            [-58.015757143999906, 63.617229718000146],
            [-58.026470121999921, 63.594698409000102],
            [-58.031870688999845, 63.583340051000164],
            [-58.026794872999972, 63.57119194600017],
            [-57.98381895599988, 63.468336377000298],
            [-57.949655880999842, 63.368888848000154],
            [-57.681895880999889, 62.783870342000171],
            [-57.416358049, 62.184685385000193],
            [-57.36580666999987, 62.051348149000205],
            [-57.363229746, 62.048389319000194],
            [-57.350806863999878, 62.034125339000184],
            [-57.348863861999916, 62.001346714000135],
            [-57.21557004899978, 60.999932075000288],
            [-57.144361783999898, 60.625143288000231],
            [-57.106146654499867, 60.424005785500128],
            [-57.104048718999934, 60.412962984000274],
            [-57.066833248999842, 60.361481831000333],
            [-57.061489787999903, 60.354090019000125],
            [-57.007501486999899, 60.28539055300007],
            [-56.846585653000034, 60.183800071000178],
            [-56.450028548999967, 59.883088483000222],
            [-56.417845718999956, 59.858683983000219],
            [-56.39283003099996, 59.833250521000082],
            [-56.108633956999938, 59.54430706900007],
            [-55.926079373999926, 59.401329582000187],
            [-55.892500742999857, 59.362237382000046],
            [-55.615967746999843, 59.040297181000199],
            [-55.594268045999854, 58.97774593600019],
            [-55.576171432666513, 58.925580651333576],
            [-55.434872775999793, 58.80080767100003],
            [-55.397591305999981, 58.65153281600027],
            [-55.300724374333299, 58.263677551000171],
            [-55.023661644999891, 58.011363842000208],
            [-55.000744985999972, 57.966936537000151],
            [-54.932583001999888, 57.834793194000156],
            [-54.284259012999883, 57.740461158000187],
            [-53.373112949999893, 57.433555659000206],
            [-53.372073148999846, 57.43312631300023],
            [-53.241055185999983, 57.36535026800027],
            [-53.177562656999896, 57.342369686000268],
            [-53.174632268999915, 57.341038742500132],
            [-52.726302714999861, 57.095442981000303],
            [-52.667907948999925, 57.064286884000182],
            [-52.408721890999857, 56.925999499000227],
            [-51.327256589999934, 55.774088268000241],
            [-51.323381778999874, 55.751851119000037],
            [-51.316716879999888, 55.713600375000283],
            [-50.99789216399995, 55.443999083000165],
            [-50.707294984999976, 55.14637900300005],
            [-50.196367705999933, 54.416059918000144],
            [-50.147939188999828, 53.972911332000365],
            [-50.054786900999943, 53.120512454000277],
            [-50.070901394666578, 53.075691528666766],
            [-50.017168762999802, 53.00524670700014],
            [-49.949090035999859, 52.360083003000227],
            [-49.281394314999886, 51.967633136000302],
            [-49.038772711999911, 51.825027976000172],
            [-48.997497016999944, 51.77147809100012],
            [-48.995069181999838, 51.768328261000249],
            [-48.634824876999915, 51.416212301000087],
            [-48.633052786999883, 51.414148100500142],
            [-48.403461650999873, 51.037074806000135],
            [-48.226556471999857, 50.746495313000025],
            [-48.199993972999948, 50.7102747320003],
            [-48.154754710999839, 50.648585975000231],
            [-48.02100571099993, 49.634463974000198],
            [-48.02108590749981, 49.633715467000172],
            [-48.038894069999913, 49.530824940000059],
            [-48.070166710999899, 49.350240973000211],
            [-48.072623737999834, 49.345875867333405],
            [-48.032075903999896, 49.25177497000027],
            [-47.9885967109999, 49.150870973000224],
            [-47.984994468999901, 49.111310602000174],
            [-47.982546011999887, 49.084418007000238],
            [-47.93736871099992, 49.020468968000216],
            [-47.935421710999833, 49.000249973000166],
            [-47.924195710999868, 48.983991973000229],
            [-47.921120428999927, 48.975030490000279],
            [-47.919159029999946, 48.969313991000263],
            [-47.908138905999863, 48.750347692000048],
            [-47.881134863999932, 48.557814904000168],
            [-47.854251953999892, 48.519302212000241],
            [-47.85228083599992, 48.512675924000177],
            [-47.851279081999905, 48.511223473500195],
            [-47.84454671099985, 48.49217497300026],
            [-47.844331537999835, 48.488271292000093],
            [-47.844173993999902, 48.48540785400013],
            [-47.817367620000027, 48.394836836000195],
            [-47.772015710999881, 48.241603972000178],
            [-47.743430710999917, 48.116624972000295],
            [-47.746146710999938, 47.095676971000159],
            [-47.903260710999916, 46.628232971000102],
            [-47.920369711000035, 46.589787971000248],
            [-48.013103710999985, 46.447103971000217],
            [-48.076896710999847, 46.288311971000155],
            [-48.104820710999832, 46.241408971000169],
            [-48.256755710999926, 46.031772970000304],
            [-48.352953710999884, 45.792139970000164],
            [-48.376801710999928, 45.752610970000148],
            [-48.40942139699996, 45.672087923000163],
            [-48.428504892999968, 45.624980037000284],
            [-48.438931295999964, 45.608050528000263],
            [-48.482405710999899, 45.537461970000038],
            [-48.759037711999838, 45.15414797000011],
            [-49.024005711999962, 44.869453969000062],
            [-49.152393711999878, 44.744695969000134],
            [-49.842890712999861, 44.237455969000166],
            [-49.996119712999871, 44.107038969000087],
            [-50.016377712999827, 44.095122969000215],
            [-50.049293712999827, 44.082937969000284],
            [-50.08534871299986, 44.055807968000238],
            [-50.095785712999941, 44.052051968000342],
            [-50.105567712999857, 44.043872968000187],
            [-50.396809794999911, 43.895153659000187],
            [-50.465622415499865, 43.86002115000025],
            [-50.470622712999784, 43.857704968000292],
            [-50.548442712999929, 43.832515968000166],
            [-50.720500712999979, 43.749374968000154],
            [-50.725054712999849, 43.74753196800026],
            [-51.197314713999901, 43.604644968000287],
            [-51.396788713999911, 43.522354968000172],
            [-51.454975713999943, 43.506607968000196],
            [-51.540635848999898, 43.498867938333433],
            [-51.547941306999974, 43.496591746000149],
            [-51.706606713999861, 43.447155968000231],
            [-51.726726713999881, 43.445823968000127],
            [-51.764625713999806, 43.431224968000151],
            [-52.931878714999925, 43.288620968000316],
            [-53.303745715999924, 43.276185968000277],
            [-53.907190715999889, 43.282400968000275],
            [-53.922487715999864, 43.285530968000216],
            [-53.933885715999963, 43.284517968000273],
            [-53.993141715999855, 43.296609968000155],
            [-54.051479717, 43.292061968000269],
            [-54.078183716999831, 43.294139968000252],
            [-54.895185849999962, 43.465132265000136],
            [-55.145943416000023, 43.517613978000099],
            [-55.14969198499989, 43.517767811000283],
            [-55.203540717999829, 43.519977968000148],
            [-55.327054717999886, 43.041019968000057],
            [-55.328246717999889, 43.038365968000221],
            [-55.540445717999887, 42.647044967000284],
            [-55.550562718000037, 42.63696496700021],
            [-55.579110717999868, 42.603391967000277],
            [-55.736123717999931, 42.382754967000267],
            [-55.737195717999896, 42.381394967000233],
            [-55.813470717999905, 42.311377967000226],
            [-55.912366717999987, 42.181439967000131],
            [-55.913418717999832, 42.180275967000284],
            [-55.992302717999848, 42.11198196700019],
            [-56.018049717999872, 42.081017967000264],
            [-56.063485717999868, 42.024436967000156],
            [-56.065347671999916, 42.022587845000089],
            [-56.069198796999956, 42.019383995000226],
            [-56.074454532999937, 42.013036066000154],
            [-56.11039971799994, 41.969621967000307],
            [-56.112463717999788, 41.967689967000297],
            [-56.216358718999913, 41.884226966000256],
            [-56.230573718999892, 41.86764396600023],
            [-56.231566718999943, 41.866776966000259],
            [-56.32060071899997, 41.79752096600015],
            [-56.338152718999879, 41.77773196600026],
            [-56.340156718999822, 41.776095966000241],
            [-56.366019718999894, 41.756617966000306],
            [-56.373859718999938, 41.747837966000191],
            [-56.375891718999867, 41.746201966000172],
            [-56.378728718999923, 41.744786966000163],
            [-56.382620718999988, 41.740431966000244],
            [-56.384652718999916, 41.738799966000215],
            [-56.387632718999896, 41.73731496600027],
            [-56.391237718999889, 41.733344966000288],
            [-56.392219718999911, 41.732578966000119],
            [-56.39485171899986, 41.731287966000252],
            [-56.396919719000039, 41.728981966000219],
            [-56.402949718999935, 41.724174966000191],
            [-56.408235718999947, 41.721549966000282],
            [-56.410753718999842, 41.718742966000207],
            [-56.413118925999925, 41.716858020000188],
            [-56.413878204499838, 41.716286150500196],
            [-56.417382718999932, 41.714546966000285],
            [-56.418018718999974, 41.713837966000256],
            [-56.421033718999979, 41.711431966000305],
            [-56.426920719, 41.708506966000243],
            [-56.432449718999862, 41.702371966000271],
            [-56.43549271899991, 41.699970966000251],
            [-56.440033277999987, 41.697726792000196],
            [-56.440729092333186, 41.697372462666863],
            [-56.442778718999818, 41.695803966000256],
            [-56.452361718999839, 41.691161966000209],
            [-56.461795718999838, 41.680731966000167],
            [-56.462778718999857, 41.679961966000064],
            [-56.467443718999846, 41.677665966000177],
            [-56.479480718999923, 41.664557966000245],
            [-56.48150271899982, 41.663018966000095],
            [-56.497753718999888, 41.655138966000209],
            [-56.507078718999878, 41.644978966000338],
            [-56.50808971899977, 41.64420996600029],
            [-56.519497718999901, 41.638674966000281],
            [-56.521556718999818, 41.63695596600013],
            [-56.522754218999808, 41.636008466000078],
            [-56.56744171899993, 41.599559966000186],
            [-56.570786718999898, 41.596066966000194],
            [-56.572943718999795, 41.595066966000275],
            [-56.574756718999822, 41.593585966000092],
            [-56.578156718999821, 41.592008966000208],
            [-56.579712718999872, 41.590738966000288],
            [-56.591057718999934, 41.584737966000205],
            [-56.607632718999923, 41.570778966000262],
            [-56.612500718999911, 41.568516966000288],
            [-56.616989718999832, 41.564525966000133],
            [-56.618933218999814, 41.562667966000049],
            [-56.621628718999943, 41.560399966000148],
            [-56.624510718999886, 41.559059966000348],
            [-56.625597718999984, 41.557922966000035],
            [-56.626572718999853, 41.557469966000212],
            [-56.627612718999842, 41.556380966000177],
            [-56.629501718999791, 41.555502966000176],
            [-56.63663671899991, 41.549150966000241],
            [-56.639195136249839, 41.547957281000151],
            [-56.639593718999834, 41.54754096600027],
            [-56.640464718999908, 41.547135966000155],
            [-56.641579718999935, 41.54600196600029],
            [-56.700316718999829, 41.518647966000231],
            [-56.737215718999892, 41.495000966000191],
            [-56.753949718999877, 41.47789296600024],
            [-56.754949718999796, 41.477223966000111],
            [-56.777400718999957, 41.467037966000191],
            [-56.780898718999879, 41.463554966000174],
            [-56.782860719000041, 41.462310966000246],
            [-56.799657718999867, 41.454909966000173],
            [-56.89288771899993, 41.394873966000205],
            [-56.894887718999769, 41.393708966000077],
            [-56.898152719, 41.391612966000025],
            [-56.900105718999896, 41.390467966000358],
            [-57.148691102999862, 41.284967804000246],
            [-57.16273867599989, 41.27900600900017],
            [-57.557955629999867, 41.063786971500178],
            [-57.558879719999936, 41.063414966000153],
            [-57.613515719999896, 41.044443966000074],
            [-57.634610719999898, 41.033008966000182],
            [-57.635582719999945, 41.032628966000232],
            [-57.738322719999871, 40.997442966000165],
            [-57.755058719999965, 40.988680966000288],
            [-57.757964720000018, 40.987637966000193],
            [-57.774732719999804, 40.984936966000305],
            [-57.932666719999872, 40.930471966000141],
            [-58.094334719999893, 40.851304965500105],
            [-58.096368719999873, 40.85042996500033],
            [-58.098038719999977, 40.849691965000204],
            [-58.099261719999959, 40.849523965000287],
            [-58.103812719999837, 40.847286965000137],
            [-58.105888719999825, 40.847001965000175],
            [-58.108848719499861, 40.845630967500142],
            [-58.110512719999974, 40.8448979650002],
            [-58.112121719999834, 40.84467696500019],
            [-58.113404719999892, 40.844045965000191],
            [-58.115248719999784, 40.84379196500015],
            [-58.11614187366655, 40.843357143333492],
            [-58.116776719999962, 40.843221965000055],
            [-58.118449219999775, 40.842474965000235],
            [-58.120102719999863, 40.841756965000172],
            [-58.120808751499794, 40.841658967000228],
            [-58.121359988999927, 40.841387815000189],
            [-58.123943719999886, 40.84011696500022],
            [-58.126024719999862, 40.839830965000147],
            [-58.128739719999771, 40.83849496500028],
            [-58.13059571999986, 40.838239965000298],
            [-58.13185671999986, 40.837710465000214],
            [-58.133533719999889, 40.836975965000192],
            [-58.134279756499836, 40.836859256000196],
            [-58.137373719999971, 40.835336965000295],
            [-58.138943719999872, 40.835120965000158],
            [-58.140236719999848, 40.834483965000231],
            [-58.143458719999899, 40.834039965000272],
            [-58.144118062999894, 40.833762215333422],
            [-58.14757722, 40.833158465000167],
            [-58.152416719999792, 40.832306965000214],
            [-58.155866719999892, 40.831750965000253],
            [-58.156717219999791, 40.831423465000114],
            [-58.158393719999879, 40.83068896500032],
            [-58.159965719999889, 40.830471965000299],
            [-58.161519719999887, 40.82979996500012],
            [-58.163189719999934, 40.829070965000199],
            [-58.167809436999846, 40.828410609333503],
            [-58.169913719999954, 40.828044965000174],
            [-58.170821719999822, 40.827597965000166],
            [-58.175445219999858, 40.826852965000171],
            [-58.180253719999882, 40.826104465000242],
            [-58.189760719999867, 40.824758965000285],
            [-58.240389719999882, 40.817150965000167],
            [-58.289103503999854, 40.809587995500237],
            [-59.204663720999918, 40.627367965000246],
            [-59.20558872099997, 40.627273965000313],
            [-59.505349721999835, 40.612160965000214],
            [-59.664770721999787, 40.58504896500034],
            [-59.66762772199985, 40.584954965000179],
            [-59.687378721999835, 40.587623965000205],
            [-59.715903721999894, 40.582863965000172],
            [-59.719683722000013, 40.582769965000239],
            [-59.745657721999805, 40.586378965000222],
            [-59.78893272199997, 40.579330965000224],
            [-59.794617721999884, 40.579236965000291],
            [-59.808211721999953, 40.581179965000103],
            [-59.818028721999895, 40.579487965000283],
            [-59.820804541499967, 40.579392774000098],
            [-59.832577851999986, 40.580953467000199],
            [-59.838182721999885, 40.581702965000034],
            [-59.853125721999845, 40.579361965000146],
            [-59.854590721999841, 40.579580965000162],
            [-59.855981721999797, 40.579362965000257],
            [-59.858199721999824, 40.579693965000274],
            [-59.865550721999853, 40.578581465000241],
            [-59.876787721999847, 40.576857965000272],
            [-59.877723721999871, 40.576997965000146],
            [-59.884348422999949, 40.57595701200006],
            [-59.886648118999801, 40.576299706000157],
            [-59.888058721999926, 40.576509965000071],
            [-59.896640721999972, 40.57516096500018],
            [-59.897605721999923, 40.575304965000214],
            [-59.89851672199984, 40.575161965000291],
            [-59.9098627219999, 40.576849965000292],
            [-59.921059721999825, 40.575112965000301],
            [-59.922934721999866, 40.575112965000301],
            [-59.934228721999887, 40.576815965000151],
            [-59.941020721999905, 40.576868465000075],
            [-59.95241772199995, 40.575177965000194],
            [-59.958355509499825, 40.576057939500174],
            [-59.959341342999835, 40.575963408500115],
            [-59.960753499999953, 40.575941578000027],
            [-59.970127221999803, 40.575925965000067],
            [-59.973077686999886, 40.575973513500116],
            [-59.975724157999878, 40.575983931000223],
            [-59.984130721999918, 40.576006965000204],
            [-59.98929472199984, 40.575189965000277],
            [-59.990280721999966, 40.575335965000306],
            [-59.991198722000036, 40.575190965000161],
            [-59.99990572199988, 40.576477965000265],
            [-60.002861722000034, 40.576017965000233],
            [-60.013730721999877, 40.576018965000117],
            [-60.019528721999905, 40.57511596500018],
            [-60.021432721999872, 40.57511596500018],
            [-60.038115721999873, 40.577621965000219],
            [-60.040240721999965, 40.577621965000219],
            [-60.045179721999887, 40.578363965000165],
            [-60.105708721999861, 40.580328965000035],
            [-60.11529772199998, 40.57889296500025],
            [-60.122561147499823, 40.578982352500134],
            [-60.126345896999908, 40.57953067800014],
            [-60.138085721999857, 40.581363965000264],
            [-60.170161479999763, 40.582377483000073],
            [-60.17637948949988, 40.58257398950019],
            [-60.201077721999866, 40.579175965000218],
            [-60.219057721999832, 40.579835965000314],
            [-60.245731047999868, 40.58432098000003],
            [-60.248686585999849, 40.584817986000303],
            [-60.310733278999862, 40.58667019700016],
            [-60.403783721999844, 40.589447965000147],
            [-60.40661272199992, 40.58963896500012],
            [-60.55756372299993, 40.617203965000215],
            [-60.708576722999908, 40.623998965000226],
            [-60.70950172299996, 40.624092965000159],
            [-61.609715723999898, 40.798961965000103],
            [-61.663989723999919, 40.806528965000041],
            [-61.697496175999845, 40.81095837800018],
            [-61.699321577499859, 40.811217474500154],
            [-61.700342723999825, 40.811708965000207],
            [-61.702129723999803, 40.81194496500018],
            [-61.702745575499932, 40.812240270000189],
            [-61.703693572999839, 40.812392390000355],
            [-61.705943723999837, 40.812682965000306],
            [-61.709862723999834, 40.81456696500004],
            [-61.713720197499924, 40.815118452000092],
            [-61.714683723999826, 40.815581965000206],
            [-61.716483723999829, 40.815813965000189],
            [-61.717852723999954, 40.816470965000178],
            [-61.719345723999936, 40.816667965000306],
            [-61.720429723999871, 40.817188965000298],
            [-61.72220472399988, 40.817422965000276],
            [-61.72357272399978, 40.818080965000206],
            [-61.725111512666501, 40.818304773000023],
            [-61.725718723999819, 40.818590965000283],
            [-61.72890872399995, 40.8190119650003],
            [-61.729993723999883, 40.819532965000235],
            [-61.731767723999894, 40.819766965000213],
            [-61.733137723999846, 40.820424965000143],
            [-61.734943153999836, 40.820771810500219],
            [-61.735957593999842, 40.821174058000224],
            [-61.737487723999948, 40.821375965000129],
            [-61.738410631333295, 40.821818842333528],
            [-61.740375723999932, 40.822130965000269],
            [-61.742217527999912, 40.822992941000166],
            [-61.742864983999823, 40.823281089000204],
            [-61.74398272399992, 40.823737465000136],
            [-61.746043223999891, 40.82462446500017],
            [-61.747192723999945, 40.825153965000084],
            [-61.749006723999969, 40.825392965000162],
            [-61.750093723999896, 40.825914965000152],
            [-61.75186572399997, 40.826147965000246],
            [-61.756599723999784, 40.828419965000137],
            [-61.757635161999872, 40.828555892000281],
            [-61.758774541999969, 40.829102514000226],
            [-61.761185723999915, 40.830159965000234],
            [-61.764441723999823, 40.831621965000238],
            [-61.765307723999797, 40.831735965000235],
            [-61.778399724, 40.8380139650003],
            [-62.141805988999977, 40.961536482000213],
            [-62.241259730999843, 40.995352107500139],
            [-62.242021723999869, 40.995650966000255],
            [-62.300485723999827, 41.026958966000223],
            [-62.329054723999889, 41.01178496600005],
            [-62.585777723999968, 40.860280966000118],
            [-63.425704724999832, 40.463140965000264],
            [-63.57009972499992, 40.434742965000225],
            [-63.673056724999981, 40.379022965000274],
            [-63.902775725999902, 40.306619965000209],
            [-65.291417726999896, 40.050941965000334],
            [-65.666140726999856, 40.040199965000227],
            [-65.887732405333225, 40.040450957555663],
            [-65.878211109999882, 40.057775000000163],
            [-65.863769439999885, 40.084672220000243],
            [-65.849624999999833, 40.111661110000227],
            [-65.836112138999852, 40.138085172000274],
            [-65.835774866499889, 40.138744704500141],
            [-65.822222219999901, 40.165916670000172],
            [-65.808966669999847, 40.193175000000281],
            [-65.796011109999938, 40.220522220000078],
            [-65.783358329999942, 40.247952780000048],
            [-65.771005559999935, 40.27546389000031],
            [-65.758958329999984, 40.303055560000075],
            [-65.750004436999973, 40.324150580000037],
            [-65.747213890000012, 40.330725],
            [-65.742522330999918, 40.342108042000291],
            [-65.735777779999864, 40.358472220000294],
            [-65.724647219999781, 40.386291670000332],
            [-65.714262207999866, 40.413056532000269],
            [-65.71382, 40.414183330000242],
            [-65.713405867999768, 40.415297988000077],
            [-65.703311109999902, 40.44214444000022],
            [-65.699722220000012, 40.451388890000203],
            [-67.468055559999868, 42.518888890000085],
            [-67.529346030999875, 42.600980975000255],
            [-67.74305549699983, 42.887222396000254],
            [-67.279444439999907, 44.18666667000025],
            [-67.168447845999879, 44.226243323000233],
            [-67.143888889999857, 44.235000000000298],
            [-67.087875758999871, 44.30513150600018],
            [-67.042499991999875, 44.361944544500147],
            [-67.037777779999828, 44.424166670000147],
            [-67.061666650999825, 44.484166698000195],
            [-67.043888897999864, 44.5105556325002],
            [-67.047950069999899, 44.546678672000269],
            [-67.04916667, 44.557500000000175],
            [-67.01, 44.61611111000019],
            [-66.958055559999764, 44.653611110000213],
            [-66.940555559999837, 44.73222222000021],
            [-66.938055559999952, 44.744722220000085],
            [-66.903706685999907, 44.775953267000205],
            [-66.903065065666567, 44.77653629966693],
            [-66.90196430199984, 44.777490477000185],
            [-66.929992669999933, 44.812793082000155],
            [-66.933201872999859, 44.816450113000144],
            [-66.93759774799986, 44.821556817000271],
            [-66.939992356999937, 44.825586098000144],
            [-66.940498059999982, 44.826314484000193],
            [-66.942025872499926, 44.828536910500077],
            [-66.944132747999845, 44.829948426000158],
            [-66.948643715999879, 44.829948426000158],
            [-66.951995856999872, 44.830398567000145],
            [-66.956250427499867, 44.830495371500035],
            [-66.961502075999931, 44.832220426000163],
            [-66.963889106999829, 44.833767238000291],
            [-66.967232528999887, 44.833917692000284],
            [-66.968925117999845, 44.833690446000332],
            [-66.971837997499847, 44.833301551000204],
            [-66.972576161978282, 44.833387901673802],
            [-66.975878557710985, 44.843785764505185],
            [-66.977363885627938, 44.848462608189777],
            [-66.975045626999929, 44.851399897000192],
            [-66.97434277099984, 44.852256722500158],
            [-66.975592122999956, 44.856479848000333],
            [-66.979515348999939, 44.859510941500218],
            [-66.979518341500011, 44.86130230900028],
            [-66.980567262999841, 44.862100598000154],
            [-66.98044509149986, 44.864675519500167],
            [-66.980778512999962, 44.865897254000231],
            [-66.982142213499856, 44.866830307000214],
            [-66.982773028999873, 44.867900848000261],
            [-66.983644200999834, 44.869100567000146],
            [-66.984357387999864, 44.869733973000166],
            [-66.983619247999854, 44.87239775400019],
            [-66.983198106999964, 44.872864285000162],
            [-66.982250434999912, 44.87433556700023],
            [-66.981654090999939, 44.874950738000223],
            [-66.97999573199985, 44.877469926000288],
            [-66.980011809999894, 44.878099441500126],
            [-66.97999573199985, 44.878700254000307],
            [-66.97999573199985, 44.879350660000114],
            [-66.97942018, 44.889307118666864],
            [-66.974108778999835, 44.892511129000184],
            [-66.97364440399997, 44.893548973000293],
            [-66.972081309999908, 44.898779645000161],
            [-66.970496669999932, 44.904155098000217],
            [-66.969910387999846, 44.9081188950002],
            [-66.96989461499976, 44.908746597500112],
            [-66.973801279499924, 44.913676904500107],
            [-66.97606127899985, 44.915678379000212],
            [-66.979074450999917, 44.918915910000294],
            [-66.979419606999784, 44.919605254000146],
            [-66.984244458499802, 44.920799254000144],
            [-66.985700419499949, 44.921161585500045],
            [-66.987063798999884, 44.922123150333505],
            [-66.988878716000045, 44.922803551000186],
            [-66.991215825999973, 44.924671832000115],
            [-66.993078708499866, 44.925449973000127],
            [-66.994223208499932, 44.926368324500174],
            [-66.995384544999922, 44.927238145000274],
            [-66.99714007599988, 44.928015738000227],
            [-66.998689872999933, 44.929397504000349],
            [-66.999998403999939, 44.931862676000208],
            [-67.001416104499867, 44.933786839000163],
            [-67.001541762999921, 44.935731082000189],
            [-67.001947966666535, 44.936721764666743],
            [-67.003292528999907, 44.938501364000047],
            [-67.006557700999792, 44.942582348000201],
            [-67.008276887999841, 44.943812785000148],
            [-67.011528575999876, 44.947422723000216],
            [-67.01527648199982, 44.949740801000189],
            [-67.015723075999915, 44.950309871500224],
            [-67.018317887999842, 44.95342244200026],
            [-67.021329294999873, 44.953919910000195],
            [-67.021876472499912, 44.954107670000099],
            [-67.023122325999907, 44.954482239000072],
            [-67.023959419999983, 44.955098129000248],
            [-67.024517200999981, 44.956001285000184],
            [-67.025287559999867, 44.957351692000088],
            [-67.027043137999954, 44.961178910000228],
            [-67.030077685000037, 44.965691160000176],
            [-67.030746325999928, 44.96773255100004],
            [-67.031320356999913, 44.968749207000144],
            [-67.032883934999916, 44.972225473000208],
            [-67.033363762999954, 44.972862754000062],
            [-67.034301801999902, 44.974857613000211],
            [-67.036531778999802, 44.979599942000277],
            [-67.03575138049996, 44.983150481000337],
            [-67.035507904016356, 44.984124012022164],
            [-67.035038510611514, 44.985959055964884],
            [-67.034689200999935, 44.987324645000172],
            [-67.033750106999889, 44.993963801000234],
            [-67.033323411999902, 44.996009785500121],
            [-67.030929529, 45.006532239000308],
            [-67.030779950999829, 45.007633317000284],
            [-67.031274762999772, 45.017978895000056],
            [-67.031029482, 45.019986410000172],
            [-67.041461715999901, 45.02319898900015],
            [-67.043568872999856, 45.023810535000109],
            [-67.045501715999819, 45.025249020000217],
            [-67.046548325999822, 45.026000785000178],
            [-67.055609841999853, 45.033445442000186],
            [-67.056262684999922, 45.034026629000209],
            [-67.056926966499816, 45.034631965000187],
            [-67.058649512999921, 45.035837395000101],
            [-67.059370700999864, 45.036378035000268],
            [-67.062198731999956, 45.038985692000097],
            [-67.065249466999887, 45.041503723000176],
            [-67.066470262999985, 45.04244950400016],
            [-67.067482325999777, 45.043449957000234],
            [-67.070092419999867, 45.045333035000283],
            [-67.070903294999766, 45.045845192000286],
            [-67.073776847897136, 45.048178330336157],
            [-67.076480075999882, 45.050373176000278],
            [-67.078172294999831, 45.051460442],
            [-67.081299856999919, 45.053993434000176],
            [-67.082779434999907, 45.056398567000144],
            [-67.085969559999796, 45.058352285000126],
            [-67.087459091999847, 45.059091410000292],
            [-67.0881691539999, 45.05961384800014],
            [-67.091343997999957, 45.066148754000153],
            [-67.091820127999824, 45.067277827333442],
            [-67.092175637999958, 45.067998879000186],
            [-67.095432028999824, 45.074800989000266],
            [-67.09518363799998, 45.076962692000336],
            [-67.095635700499827, 45.081936035500178],
            [-67.097020111618576, 45.085303446236196],
            [-67.098638747999871, 45.08634802000023],
            [-67.1000792399999, 45.087630317000219],
            [-67.101138341999956, 45.0906210360003],
            [-67.102145450999899, 45.092045817000155],
            [-67.103300606999937, 45.093414176000294],
            [-67.104021887999977, 45.094552036000266],
            [-67.105224899925531, 45.098245391385888],
            [-67.105574256387058, 45.098829452865061],
            [-67.106132891567313, 45.099710588362655],
            [-67.10765595099997, 45.100928520000139],
            [-67.108184130783343, 45.102185148833826],
            [-67.109490434999884, 45.105293067000218],
            [-67.1119327319999, 45.108179098000164],
            [-67.112560856999892, 45.109381254000141],
            [-67.113141200499797, 45.110301535500241],
            [-67.114316481999822, 45.111906067000064],
            [-67.115533372999835, 45.113073661000044],
            [-67.116299419999848, 45.113355473000297],
            [-67.118193893938894, 45.114178778167968],
            [-67.119288857000015, 45.11449311400014],
            [-67.121289341499846, 45.115289606000147],
            [-67.122392739999896, 45.116081668500215],
            [-67.124715185000014, 45.118629192000071],
            [-67.125291622999839, 45.119367457000351],
            [-67.12741904499984, 45.121329114000162],
            [-67.128151685499915, 45.122167918500168],
            [-67.130546387999857, 45.127153489000079],
            [-67.131994333499904, 45.128414254500058],
            [-67.132735169999762, 45.1286522070003],
            [-67.137648700999819, 45.132982473000084],
            [-67.138755747999824, 45.134140207000144],
            [-67.139199887999894, 45.134816645000171],
            [-67.140895903999819, 45.136585817000139],
            [-67.142060356999821, 45.139043614000229],
            [-67.143314419999967, 45.14092831700026],
            [-67.143566012999827, 45.142186567000294],
            [-67.144597903999852, 45.144853598000282],
            [-67.145355294999831, 45.145933270000057],
            [-67.145513095119099, 45.147201860231519],
            [-67.146011619390833, 45.147967279171439],
            [-67.147909137999818, 45.152005676000158],
            [-67.148349981999843, 45.15377095700029],
            [-67.150047091999966, 45.156968192000249],
            [-67.150903833499854, 45.159949238500189],
            [-67.151644904000023, 45.161849129000245],
            [-67.152305559999888, 45.163799286000199],
            [-67.15455943499984, 45.163799286000199],
            [-67.156611841999847, 45.164150239000207],
            [-67.159325545356353, 45.164150239000207],
            [-67.160938091999867, 45.164150239000207],
            [-67.163351091999857, 45.164678457000207],
            [-67.165689841499784, 45.164347621500212],
            [-67.168695200999821, 45.164625864000186],
            [-67.169509887999823, 45.164391692000208],
            [-67.170526528999801, 45.164247817000273],
            [-67.171508794999852, 45.163749692000181],
            [-67.174931122999908, 45.163749692000181],
            [-67.177756325999894, 45.162167028000169],
            [-67.181420497999852, 45.164051051000172],
            [-67.182607333499959, 45.164300926000124],
            [-67.185281341749885, 45.164963488500121],
            [-67.18697166949994, 45.165536942000074],
            [-67.187691309999821, 45.166319473000272],
            [-67.189303614999915, 45.166838934000168],
            [-67.18989024799987, 45.167051317000244],
            [-67.192026779499884, 45.167026520000206],
            [-67.193842909999887, 45.167001723000226],
            [-67.215413502859803, 45.186288371480998],
            [-67.236117874576166, 45.193882075304771],
            [-67.264246032968856, 45.199991141632267],
            [-67.290073023657442, 45.180127036879355],
            [-67.305826810719736, 45.154918127732742],
            [-67.338681859602445, 45.150273212255968],
            [-67.405963899494054, 45.196382229390849],
            [-67.422499885762818, 45.215000112776409],
            [-67.455272959632339, 45.263054066729694],
            [-67.46430881891871, 45.283882090350517],
            [-67.450564006415163, 45.333054022552403],
            [-67.481835881421659, 45.495827062274827],
            [-67.421245953044206, 45.52499122390293],
            [-67.413126906329694, 45.585473193367307],
            [-67.458054913303954, 45.613609063111028],
            [-67.477781890120468, 45.608327122991284],
            [-67.507508812729611, 45.601382213276906],
            [-67.573899856310845, 45.611664126292681],
            [-67.660554989065758, 45.632073222391682],
            [-67.794508866295928, 45.69582715158873],
            [-67.804445947814258, 45.731373126674384],
            [-67.806381999815187, 45.784718073202953],
            [-67.786663907815921, 45.888327113920184],
            [-67.772508884969966, 45.957500111173374],
            [-67.779173000927074, 46.283336036831088],
            [-67.788890979496045, 46.787773220573911],
            [-67.79167293316749, 46.921382098668289],
            [-67.794999878185251, 47.069991063089276],
            [-67.866945944292638, 47.100545111905831],
            [-67.892226770168918, 47.114436104801143],
            [-67.950008928612704, 47.168609183365731],
            [-67.960972960911135, 47.19047321022191],
            [-68.18581784949663, 47.332773116105102],
            [-68.208617799664125, 47.341664136104271],
            [-68.244999953412957, 47.351935990836409],
            [-68.314854902310344, 47.365136147270107],
            [-68.343054977432416, 47.361800149796977],
            [-68.370408815607789, 47.349164263086493],
            [-68.56470887547016, 47.289718129219693],
            [-68.761945954213729, 47.232764270449934],
            [-68.787508914951388, 47.224709093837816],
            [-68.831681880085625, 47.208882216579582],
            [-68.89153587736223, 47.189018111826556],
            [-68.96195492834488, 47.218873109916444],
            [-69.032226793107327, 47.255553994695276],
            [-69.051108874081535, 47.281936035013644],
            [-69.05359092325682, 47.293782178804747],
            [-69.055126823198862, 47.302073222172851],
            [-69.055826879754079, 47.342073172980463],
            [-69.052490882281063, 47.380545102834731],
            [-69.044581886060541, 47.403118071063602],
            [-69.045626774113259, 47.431027126506649],
            [-69.128326822024974, 47.459164169716814],
            [-69.236063950067432, 47.467891072051486],
            [-69.305263937048949, 47.400273253117746],
            [-69.423617918959508, 47.283336148123908],
            [-69.653881875601201, 47.055264226806912],
            [-69.712508929887463, 46.9969360711886],
            [-69.84722690141308, 46.862209047207557],
            [-69.992763898311694, 46.715827154466524],
            [-70.009172982566156, 46.698045114468187],
            [-70.026945970108954, 46.587491164036408],
            [-70.038608885495989, 46.509991081231476],
            [-70.04415502320461, 46.474991103320008],
            [-70.047772987901453, 46.45388211830408],
            [-70.057081929593949, 46.431382240271205],
            [-70.078408844093133, 46.417564169933826],
            [-70.119154951923406, 46.39360919350554],
            [-70.200290936698138, 46.336382084691309],
            [-70.242490969457435, 46.279164195970793],
            [-70.287781912840046, 46.203045115536781],
            [-70.305554900383072, 46.07888213943346],
            [-70.304855011465975, 45.979991104388773],
            [-70.269445996678371, 45.973318103614076],
            [-70.244926918165106, 45.960409134497468],
            [-70.25805499023113, 45.909091099800378],
            [-70.393890940005463, 45.778045072428256],
            [-70.466663964682652, 45.711935996070338],
            [-70.555264034255742, 45.672764177298916],
            [-70.576955058630006, 45.660818121221951],
            [-70.631946043308631, 45.627773138412465],
            [-70.693054979132057, 45.571936084425033],
            [-70.720272862837589, 45.528327053738053],
            [-70.724999920965558, 45.49721812292978],
            [-70.712781955948799, 45.477764228519391],
            [-70.697081980704979, 45.463600153217783],
            [-70.876590831268686, 45.241027095675236],
            [-71.021117808831832, 45.326664162467637],
            [-71.085126883162161, 45.307709158935666],
            [-71.143199893647079, 45.252773159542073],
            [-71.179717834227688, 45.255827022153369],
            [-71.211673001983058, 45.266109102807491],
            [-71.236390899240405, 45.276518085475971],
            [-71.272508855400474, 45.296382190228883],
            [-71.295690852715666, 45.303745021636928],
            [-71.321108806528059, 45.296936066392277],
            [-71.42443588002169, 45.250000090687706],
            [-71.401390843004833, 45.214400136145343],
            [-71.434299871344138, 45.127773166585087],
            [-71.459163948993194, 45.102773134466076],
            [-71.488746032315021, 45.077973094557308],
            [-71.498054974007573, 45.049436066926603],
            [-71.494155042087129, 45.020544987704625],
            [-71.554717813098307, 45.019991111541344],
            [-71.892772877239338, 45.019154094687849],
            [-72.049990894435723, 45.019436061911449],
            [-72.271655018395563, 45.018773221006484],
            [-72.459163892648178, 45.017491125093102],
            [-72.510281935134742, 45.017209157869672],
            [-72.778881867160976, 45.020827122566402],
            [-72.956390963259366, 45.01832713611833],
            [-73.341646033055895, 45.011627145615307],
            [-73.352999823852826, 45.00941801120814],
            [-73.367964035633804, 45.010582089924412],
            [-73.622772887050758, 45.006664220731238],
            [-73.911645960705926, 45.000000104773903],
            [-74.249163912490019, 44.992218178206031],
            [-74.682017798896027, 45.006718032549713],
            [-74.751108821135745, 45.002218123998375],
            [-74.777781881133251, 45.00888223995554],
            [-74.820546015977641, 45.018464264054728],
            [-74.850281991042095, 45.016664166523583],
            [-74.996117886608886, 44.98360007297498],
            [-75.170545962729364, 44.898609083281258],
            [-75.278063987822406, 44.85721807181875],
            [-75.309717909976769, 44.841936018268484],
            [-75.39584598865855, 44.785827055341258],
            [-75.537217850219633, 44.691373232211959],
            [-75.5624998495623, 44.67388221189276],
            [-75.623054909222446, 44.631382106998899],
            [-75.682499869622859, 44.588044985251827],
            [-75.736117898557723, 44.546391117305006],
            [-75.806664019192908, 44.487073226557072],
            [-75.822635903376238, 44.472491061923961],
            [-75.831255014435669, 44.440545114261965],
            [-75.845272909345397, 44.418882085444636],
            [-75.872081923812857, 44.398045009368445],
            [-75.904445960806243, 44.384991200964464],
            [-75.966108940431354, 44.364153957250153],
            [-75.989972889390685, 44.357064208248573],
            [-76.019455060426196, 44.353327052888289],
            [-76.040835786743799, 44.351382116070113],
            [-76.061099875917364, 44.343182100170623],
            [-76.363372930578578, 44.150991094791095],
            [-76.410281916554879, 44.121100054517342],
            [-76.437354960973096, 44.102073134255988],
            [-76.531390862408898, 43.983045079690271],
            [-76.569454928853531, 43.93415410888349],
            [-76.583617830688752, 43.915827077539234],
            [-76.697490847149197, 43.768600121317661],
            [-76.809445916519451, 43.633327100162205],
            [-76.974163893060222, 43.634436025955381],
            [-77.288326845047209, 43.636654045179853],
            [-77.582781930956628, 43.638600155464644],
            [-77.729999834722719, 43.639164257549908],
            [-77.857772896534016, 43.639435998851525],
            [-77.887217851919331, 43.639435998851525],
            [-78.388063893055801, 43.638327073058406],
            [-78.663055026270968, 43.63750011448866],
            [-78.72471783825813, 43.629436053059237],
            [-78.938326785605568, 43.553882080538926],
            [-79.028063944166604, 43.521936132877101],
            [-79.09527289386233, 43.49777311761153],
            [-79.184717859277839, 43.465545035087928],
            [-79.132217808591747, 43.382491103223401],
            [-79.06679085151643, 43.279400064123934],
            [-79.053745927929981, 43.259582227476642],
            [-79.044863960386181, 43.163044998488317],
            [-79.054645976695326, 43.140545120455442],
            [-79.08111787101609, 43.085545083321165],
            [-79.042082006714566, 43.009718028394786],
            [-79.013755029577908, 42.982209125010229],
            [-78.9752728738016, 42.959709079339348],
            [-78.945199946228627, 42.954018102343355],
            [-78.920345926863263, 42.936245114800556],
            [-78.917781902674847, 42.90193614098888],
            [-78.93415494474533, 42.865964197582684],
            [-78.965835856628075, 42.833600160589185],
            [-78.986935789188522, 42.819991134917473],
            [-79.121108937006227, 42.769154053826071],
            [-79.154445945323005, 42.757218056032912],
            [-79.299435771580818, 42.702491101304929],
            [-79.566455044301676, 42.600709147846942],
            [-79.763426919627932, 42.524700205621002],
            [-79.776673008891123, 42.52027321962737],
            [-80.091535849795093, 42.39819113481218],
            [-80.51028198728892, 42.329164150312863],
            [-80.528545986721042, 42.326618063397177],
            [-80.869155016766911, 42.279164253712906],
            [-81.249164020989099, 42.224991175148205],
            [-81.424435819485723, 42.145000158350683],
            [-81.62360895022988, 42.052773239263161],
            [-81.822235916161674, 41.96027323776957],
            [-82.218063918653058, 41.774436050290831],
            [-82.238890936445387, 41.763882228335092],
            [-82.425282000087634, 41.675554067701626],
            [-82.462781964447117, 41.676100064876039],
            [-82.649990934203402, 41.681936048797411],
            [-82.696655000968178, 41.68387310662655],
            [-83.076390922784185, 41.867353973477975],
            [-83.117417824371557, 41.946191137481378],
            [-83.130826857833028, 41.970545092501879],
            [-83.150281925709805, 42.008327024084849],
            [-83.168608957054062, 42.046109123305939],
            [-83.137217891384012, 42.201382203684204],
            [-83.132491000894049, 42.220827045639112],
            [-83.123317846033274, 42.245827077758065],
            [-83.11291791582039, 42.265273093179417],
            [-83.084546011682448, 42.299354079223917],
            [-83.055663984915896, 42.31957324139637],
            [-83.027217817115854, 42.331936045700559],
            [-83.002226837452213, 42.339154037821345],
            [-82.975826859860945, 42.34470906034727],
            [-82.94055497300991, 42.357500179904946],
            [-82.841381970741622, 42.396945081082663],
            [-82.801246065464113, 42.418045013643109],
            [-82.769172880511803, 42.442909091292336],
            [-82.729999888273824, 42.483327131431452],
            [-82.704455032446845, 42.508326995912228],
            [-82.662654984108372, 42.545300073836643],
            [-82.618190999295109, 42.555753983506293],
            [-82.579035776692081, 42.563745122378009],
            [-82.535826898063817, 42.599436104389099],
            [-82.517363912249607, 42.627636011873278],
            [-82.484726792849983, 42.719153989588108],
            [-82.472917864709302, 42.76082713591228],
            [-82.474090995880999, 42.796382163453472],
            [-82.481526917484814, 42.826382168468399],
            [-82.463482021002164, 42.901382097187479],
            [-82.418782001794966, 43.018636038122224],
            [-82.404173015071535, 43.049164103038777],
            [-82.322235887989223, 43.210545076277981],
            [-82.252790981796352, 43.346382199518814],
            [-82.228881938197674, 43.39138212322257],
            [-82.146117852545672, 43.553045063685545],
            [-82.130281922832012, 43.585264261391444],
            [-82.214445953956272, 43.952218116105428],
            [-82.331681957618116, 44.460818088252893],
            [-82.430555055389902, 44.882764268750122],
            [-82.543064000923835, 45.355827150629409],
            [-82.629990874980479, 45.396100183843316],
            [-82.66500895780274, 45.411936113556806],
            [-82.954181935954352, 45.541936079409936],
            [-83.050826788579911, 45.585264148701469],
            [-83.112208974447753, 45.612773219724204],
            [-83.270846047855855, 45.683327051710137],
            [-83.500290924916897, 45.78500020806456],
            [-83.597782014489383, 45.827218178096643],
            [-83.52389989638101, 45.918054036529213],
            [-83.487781940221055, 45.961664240682524],
            [-83.447772936957961, 46.011936046222047],
            [-83.479017822236244, 46.040136121344403],
            [-83.572081925815212, 46.101936061267793],
            [-83.596117871428248, 46.114154026284552],
            [-83.619163914273543, 46.121100109465374],
            [-83.663054912184009, 46.126100082361518],
            [-83.838335930774065, 46.125545032731793],
            [-83.890545967609398, 46.094618156860861],
            [-83.923755068083437, 46.07056410633686],
            [-83.954317834079347, 46.070482131323729],
            [-84.076673001300776, 46.203045115536781],
            [-84.089717924887339, 46.220273111733661],
            [-84.102499991989475, 46.24027317095647],
            [-84.157646041877342, 46.394164243135435],
            [-84.160281982795198, 46.425000091537413],
            [-84.154445998874053, 46.445264180711035],
            [-84.144582007551605, 46.465691214082824],
            [-84.120063934866607, 46.50707317308985],
            [-84.125346042624415, 46.52943609082439],
            [-84.192764036985693, 46.546664254659277],
            [-84.408617825647866, 46.508609073032062],
            [-84.431381901269674, 46.501664163317344],
            [-84.458064013722549, 46.483744995383006],
            [-84.483955042151479, 46.460200061659236],
            [-84.521255014300664, 46.460273151855176],
            [-84.564999831819364, 46.466382218182446],
            [-84.775008919381094, 46.653045023126424],
            [-84.787781934027862, 46.689709144098742],
            [-84.806945982225386, 46.74832714592975],
            [-84.825564033248952, 46.806936095305218],
            [-84.832782025369625, 46.829164232036305],
            [-84.864581960277519, 46.905818077894423],
            [-84.917499932657222, 46.928600090788905],
            [-85.354446031842883, 47.111664209413277],
            [-85.46417302370503, 47.157209124462952],
            [-85.738890906875952, 47.270827163428009],
            [-85.839717826283561, 47.312209122435092],
            [-86.014726935933822, 47.383882106136355],
            [-86.051390889268134, 47.398882192462963],
            [-86.466663901352547, 47.567218133700521],
            [-86.568890933870421, 47.608327010301252],
            [-86.884445952340513, 47.734718063913022],
            [-87.201399910454597, 47.860273106499733],
            [-87.341672904506083, 47.915545052573634],
            [-87.444717843138108, 47.955827138242853],
            [-88.188326934966256, 48.244154047085715],
            [-88.368055056117612, 48.312209066090077],
            [-88.645555060598383, 48.26416416459233],
            [-88.691663910095315, 48.255554105988438],
            [-88.97416405511035, 48.139164171635343],
            [-89.32333586982611, 47.993044967740559],
            [-89.356654940869873, 47.979718076930681],
            [-89.44777293416405, 48.003327048394681],
            [-89.493126909458795, 48.003164271834635],
            [-89.572917934046188, 48.002253997147193],
            [-89.606382012015501, 48.011864184440753],
            [-89.755563963339114, 48.029573134243151],
            [-89.838899862427354, 48.011664192230455],
            [-89.862499949073992, 48.000827061946609],
            [-89.899790868767752, 47.990618071488257],
            [-89.994235807079633, 48.025273217902168],
            [-90.032772948140916, 48.069718091976029],
            [-90.065555074465749, 48.10645396203995],
            [-90.146517889120389, 48.121444995911133],
            [-90.279999865200352, 48.113054040256714],
            [-90.749864045180743, 48.092773187276805],
            [-90.77389999079378, 48.103745098564048],
            [-90.835799843003258, 48.204464226696473],
            [-90.835208918827789, 48.227073237108982],
            [-90.86860895905653, 48.237500157050306],
            [-90.898054920270283, 48.236654087741272],
            [-90.928326834225174, 48.228600084595655],
            [-90.969163969524118, 48.214718144155825],
            [-91.12609985185901, 48.154991216531698],
            [-91.149172884432687, 48.144154086247681],
            [-91.192490895440528, 48.115000150541562],
            [-91.240555075315683, 48.083600200054036],
            [-91.276390896614089, 48.072627115300293],
            [-91.318608866645945, 48.069436124752599],
            [-91.347226863461799, 48.068054116552958],
            [-91.386799840120318, 48.058882135158683],
            [-91.418335912715861, 48.041109147615828],
            [-91.462781960256109, 48.057773209365337],
            [-91.5736269303668, 48.093045096216599],
            [-91.645146022325264, 48.098344973609187],
            [-91.687499946827245, 48.144718020695052],
            [-91.740135784345057, 48.19137320264241],
            [-91.78389099542386, 48.194709032477476],
            [-91.85055495377344, 48.203882187338252],
            [-91.940281886412777, 48.230545021413832],
            [-91.963717855394862, 48.240536082388815],
            [-91.991799913319994, 48.261245082984203],
            [-92.008063990648907, 48.280964180811907],
            [-92.014164004521149, 48.304436024339566],
            [-92.039372913667705, 48.345336023912637],
            [-92.141681921198767, 48.357218042249315],
            [-92.162217919312127, 48.356654107802115],
            [-92.25723584452669, 48.346936129233256],
            [-92.276808929600463, 48.334991078984558],
            [-92.299790934705413, 48.299364134713926],
            [-92.286108986475767, 48.269991096057993],
            [-92.291408863868469, 48.248864173769221],
            [-92.331681897082149, 48.234164159577006],
            [-92.359854982476179, 48.231727037402891],
            [-92.426390865344729, 48.311664242382165],
            [-92.455272892111395, 48.394154072161484],
            [-92.58222687434187, 48.441373188556156],
            [-92.697772918680755, 48.485264186466623],
            [-92.715290928728393, 48.541382201849501],
            [-92.951317946733468, 48.622627151365805],
            [-93.2449998858919, 48.640544978195862],
            [-93.31569989826977, 48.629153971748622],
            [-93.408335854233371, 48.6086090888179],
            [-93.454063997687001, 48.584327050526895],
            [-93.464235940133108, 48.551727146777409],
            [-93.496254977990645, 48.538609132995134],
            [-93.660699872125349, 48.515136116001031],
            [-93.724163955109702, 48.513882183282306],
            [-93.785835819552062, 48.517082226285709],
            [-93.804790990722154, 48.531864215490998],
            [-93.81889086064507, 48.586244997616916],
            [-93.83395498471225, 48.616382130568411],
            [-93.85874597216548, 48.628609148040709],
            [-93.882917872248299, 48.630273123463553],
            [-94.063890873662615, 48.63804499174779],
            [-94.111935775160475, 48.64110908028124],
            [-94.134172964347044, 48.642773223342317],
            [-94.247890915598362, 48.661000174762307],
            [-94.267363920747982, 48.695891187932148],
            [-94.298473019194319, 48.707218156638987],
            [-94.400355052576572, 48.710827068880405],
            [-94.433317892734522, 48.701936048881237],
            [-94.461046066706615, 48.694991139166518],
            [-94.500835799381889, 48.696936075984866],
            [-94.523899947138091, 48.701936048881237],
            [-94.605835900754073, 48.724436094552118],
            [-94.640417956972101, 48.741100156301627],
            [-94.694991019957172, 48.778736075131064],
            [-94.708055054282909, 48.796800082353002],
            [-94.707035814853839, 48.858000045645156],
            [-94.700981901449609, 48.902491187824808],
            [-94.70583586159205, 48.933182196939754],
            [-94.718608876238704, 48.974709162871818],
            [-94.729999882685945, 48.996936126136575],
            [-94.745272883780615, 49.028600106574913],
            [-94.766955023337459, 49.075554019552385],
            [-94.798335863085526, 49.157491146634754],
            [-94.80569098314254, 49.186664193080162],
            [-94.817781878506878, 49.305545061425846],
            [-94.931964019557199, 49.358536124001148],
            [-94.962090926586939, 49.360973246175263],
            [-94.998608867167547, 49.357500120765962],
            [-95.025835803328448, 49.357500120765962],
            [-95.081863964708987, 49.359591070337842],
            [-95.120835791270054, 49.365000080110178],
            [-95.142499993554111, 49.371653970145587],
            [-95.152790959025538, 49.376654110679738],
            [-95.154172967224838, 49.366391140765131],
            [-95.154446049631133, 49.333327047216528],
            [-95.153963922559001, 49.173336128803783],
            [-95.154172967224838, 48.999436112584874],
            [-95.266555010744185, 48.999973224941755],
            [-97.21993592592861, 48.999718079808304],
            [-97.502790960725065, 48.999436112584874],
            [-97.6358359043723, 48.999436112584874],
            [-97.801935889112542, 49.000000047031904],
            [-97.969163910385134, 49.000273129438199],
            [-98.269163960536844, 49.000273129438199],
            [-98.502226802294842, 48.999436112584874],
            [-98.868608843572417, 49.000000047031904],
            [-99.335554947773403, 48.999436112584874],
            [-99.835555087238902, 49.000000047031904],
            [-100.002226885415439, 49.000000047031904],
            [-100.501954948303137, 48.999718079808304],
            [-101.069164037464191, 49.000000047031904],
            [-101.302226879222019, 49.000273129438199],
            [-101.367235914603953, 48.998791208952639],
            [-101.468890966047525, 48.999436112584874],
            [-102.168872922277899, 49.000000047031904],
            [-102.335563998831958, 48.999436112584874],
            [-102.535555035690152, 49.000273129438199],
            [-102.768335910224437, 48.999436112584874],
            [-103.035281925111406, 48.999436112584874],
            [-103.168326868758641, 48.999436112584874],
            [-103.268890931681881, 49.000000047031904],
            [-103.435545966051848, 49.000273129438199],
            [-103.535281896938955, 48.999436112584874],
            [-103.735281986252744, 48.999436112584874],
            [-104.033099891725726, 49.000254018698911],
            [-104.135272944786891, 48.999718079808304],
            [-104.335008836511975, 48.999436112584874],
            [-104.835008975977473, 48.999436112584874],
            [-105.001399980396741, 48.999436112584874],
            [-105.268335937000074, 49.000000047031904],
            [-105.70221794765223, 48.999436112584874],
            [-105.935545992827315, 48.999436112584874],
            [-106.03472687408464, 48.999436112584874],
            [-106.135272999734923, 48.999436112584874],
            [-106.26862690033397, 48.999436112584874],
            [-106.468064061029054, 48.999436112584874],
            [-106.73554601480663, 48.999436112584874],
            [-107.335281917521229, 49.000000047031904],
            [-107.434999911135549, 49.000000047031904],
            [-107.634746028782388, 48.999436112584874],
            [-107.735545958461671, 48.999436112584874],
            [-107.801099985189524, 48.999436112584874],
            [-108.168872919484102, 48.999436112584874],
            [-108.335008946407982, 48.999436112584874],
            [-108.534726900860164, 48.999436112584874],
            [-108.66777301797373, 48.999436112584874],
            [-108.834726951011874, 48.999436112584874],
            [-109.334726922839366, 48.999436112584874],
            [-109.634746083730306, 48.999436112584874],
            [-109.801935883524607, 48.999436112584874],
            [-109.967773011780537, 48.999718079808304],
            [-109.99965508933991, 49.000600191300919],
            [-110.101390942330283, 48.999436112584874],
            [-110.201108935944603, 48.999436112584874],
            [-110.301663946412305, 49.000000047031904],
            [-110.36776397031457, 49.000000047031904],
            [-110.501108986096312, 49.000000047031904],
            [-110.667772905283698, 49.000000047031904],
            [-110.768626814419576, 48.999436112584874],
            [-111.368326842588488, 48.999436112584874],
            [-111.801099927447581, 48.999436112584874],
            [-112.034726871290673, 48.999436112584874],
            [-112.168326864567632, 48.999436112584874],
            [-112.234735845421994, 49.000000047031904],
            [-112.335008888666039, 49.000000047031904],
            [-112.434999964686426, 49.000000047031904],
            [-112.53499992552446, 49.000000047031904],
            [-112.601672936329408, 49.000000047031904],
            [-112.934717969290318, 49.000000047031904],
            [-113.034435795266745, 49.000000047031904],
            [-113.234163975640627, 48.999436112584874],
            [-113.368326897536406, 48.999436112584874],
            [-113.567499860642613, 48.999436112584874],
            [-114.034435906559565, 48.999436112584874],
            [-114.059863918655935, 49.000600191300919],
            [-114.335008943614184, 48.999436112584874],
            [-114.467500011097854, 48.999436112584874],
            [-114.534726898066253, 49.000000047031904],
            [-114.633899900334484, 49.000000047031904],
            [-114.901108939343942, 48.999436112584874],
            [-115.034155056457564, 48.999436112584874],
            [-115.167499904601016, 48.999436112584874],
            [-115.3680550435447, 49.000000047031904],
            [-115.468064056838045, 49.000000047031904],
            [-115.567226833184463, 49.000000047031904],
            [-115.60139096770888, 48.999436112584874],
            [-115.734445969639751, 48.999436112584874],
            [-116.048335839220385, 48.999718079808304],
            [-117.001399974808862, 48.999718079808304],
            [-117.036617882203302, 49.003126999839367],
            [-117.06722691630506, 48.999718079808304],
            [-117.200846020321308, 48.999436112584874],
            [-117.234735898972872, 49.000000047031904],
            [-117.300554961480145, 49.000000047031904],
            [-117.567499970538677, 49.000000047031904],
            [-117.834445985425816, 49.000000047031904],
            [-117.867490968235018, 48.999436112584874],
            [-118.000835816378469, 48.999436112584874],
            [-118.134172953171287, 48.999436112584874],
            [-118.36805504214783, 48.999436112584874],
            [-118.767772918275739, 48.999436112584874],
            [-118.967499925183233, 48.999436112584874],
            [-119.134173064464278, 48.999436112584874],
            [-119.267226892928761, 48.999436112584874],
            [-119.46777297941702, 48.999436112584874],
            [-119.867491023183106, 48.999436112584874],
            [-119.934154981532515, 48.999436112584874],
            [-120.034154942370492, 48.999436112584874],
            [-120.534726895272286, 48.999436112584874],
            [-121.084973024015767, 48.999718079808304],
            [-122.100563978172772, 49.000000047031904],
            [-122.33389101751942, 49.000000047031904],
            [-122.433609011133797, 49.000000047031904],
            [-122.566673065520149, 49.000000047031904],
            [-122.699999976390814, 49.000000047031904],
            [-122.760299050964591, 48.999436647917719],
            [-122.767494192179242, 49.000306302000126],
            [-123.035041153506256, 49.000306190951164],
            [-123.034318620953115, 48.999436641056491],
            [-123.093745876522576, 48.999436910042618],
            [-123.094203663133328, 49.000306302000126],
            [-123.21303567199999, 49.000306302000126],
            [-123.226354767999908, 49.000306302000126],
            [-123.323904161999906, 49.000306302000126],
            [-123.009065328999952, 48.829741845000285],
            [-123.009065328999952, 48.766397781000308],
            [-123.272385079999879, 48.691447223000125],
            [-123.247341082, 48.617965705000188],
            [-123.218719369999917, 48.545960139000158],
            [-123.163861087999919, 48.457989457000224],
            [-123.118543378000027, 48.419900509000172],
            [-123.18535179599985, 48.351360020000186],
            [-123.255689080999787, 48.279199174000269],
            [-123.531173060999834, 48.221055966000222],
            [-123.793538754999929, 48.257701901000303],
            [-123.869381810999869, 48.271009918000175],
            [-124.006770510999843, 48.295117215000062],
            [-124.048730007999836, 48.306704149000268],
            [-124.583480647999863, 48.454373234000229],
            [-124.659187877999841, 48.475279459000262],
            [-124.665775458999917, 48.477098591000185],
            [-124.678631950999886, 48.480648856000187],
            [-124.723310678999894, 48.492986694000365],
            [-124.725870870499989, 48.493676682000057],
            [-124.751097047999849, 48.497541707000209],
            [-124.786944399999811, 48.503055560000178],
            [-124.803729748999785, 48.504037678000259],
            [-124.839166699999794, 48.506111110000177],
            [-124.914444399999866, 48.503888890000155],
            [-124.987222199999849, 48.499166670000079],
            [-124.990965568999968, 48.498230832000274],
            [-125.001666699999873, 48.495555560000071],
            [-125.096388899999852, 48.469166670000163],
            [-125.140277799999865, 48.45277778000019],
            [-125.153333299999929, 48.446388890000037],
            [-125.38, 48.337777780000295],
            [-125.499444399999845, 48.306111110000302],
            [-125.896666699999912, 48.184722220000253],
            [-126.682499999999891, 47.820833330000141],
            [-127.199444399999777, 47.613055560000248],
            [-127.689722199999949, 47.366666670000143],
            [-128.865555599999823, 46.701388890000203],
            [-129.127499999999827, 46.529722220000167],
            [-129.346597103999784, 46.546139081000263],
            [-129.364785523999956, 46.547501951000186],
            [-129.88711428799985, 46.809933337000246],
            [-130.277699332999873, 47.060543203000179],
            [-130.312815151999871, 47.085626424000168],
            [-130.377126641999837, 47.147382863000132],
            [-130.404480777999936, 47.165915998000344],
            [-130.931857576999789, 47.37619009100024],
            [-131.295876679999935, 47.501800465000144],
            [-132.018936036999776, 48.080784563000179],
            [-132.375457684999787, 48.205576604000214],
            [-132.945468103, 48.636588401000211],
            [-133.251650789999928, 48.868107973000178],
            [-133.336471866999943, 48.888426563000223],
            [-133.545618369999886, 48.938527337000266],
            [-133.558580025999902, 48.942849855000134],
            [-133.559611790999782, 48.943193973000177],
            [-133.594155366, 48.956920788000048],
            [-134.222687491999864, 49.206685494000283],
            [-134.55901894099992, 49.37789520400014],
            [-134.563581376999934, 49.380418867000117],
            [-134.578408791999834, 49.388620973000172],
            [-134.609189073999858, 49.412092987000165],
            [-134.631008529999974, 49.428732182000147],
            [-134.724090091999926, 49.478583645000299],
            [-134.809832791999895, 49.524504974000308],
            [-134.878431589999877, 49.567186088000142],
            [-134.883622371999934, 49.570415738000236],
            [-134.957612897999979, 49.62976510000027],
            [-135.033172979999961, 49.671969191000187],
            [-135.072973791999914, 49.694199974000242],
            [-135.105513627999926, 49.716522304000307],
            [-135.192731854999863, 49.776354270000297],
            [-135.353103759999783, 49.881362222000178],
            [-135.418802364999976, 49.934636780000289],
            [-135.49752279199987, 49.998470974000156],
            [-135.574997168999801, 50.043283694000365],
            [-135.622045226999774, 50.070497413000226],
            [-135.697852656999885, 50.127801610000233],
            [-135.760867792999818, 50.175435974000152],
            [-135.769583889999979, 50.182985390000226],
            [-135.804818934999787, 50.213504387000171],
            [-135.83252066499989, 50.2295822290003],
            [-135.849144893999778, 50.242484330000195],
            [-135.854215986499867, 50.24642007350019],
            [-135.875966792999918, 50.25797697400003],
            [-136.008564793, 50.34425897400024],
            [-136.014021698999954, 50.347652094000125],
            [-136.05465947499988, 50.372921403000248],
            [-136.343491871999845, 50.596296470000198],
            [-136.44858806499991, 50.652163614000187],
            [-136.528916029999777, 50.705789078000237],
            [-136.617690792999895, 50.765053975000285],
            [-136.835220172999868, 50.936883501000239],
            [-136.848449308999932, 50.947333417000152],
            [-136.887357307999821, 50.96960856200036],
            [-136.939571793999789, 50.999501975000214],
            [-136.953005676999851, 51.010223002000089],
            [-136.98685735, 51.037239002000035],
            [-137.016868328999919, 51.078879720000145],
            [-137.063826723999853, 51.115230752000343],
            [-137.166337793999901, 51.194585975000166],
            [-137.2088227939999, 51.259193975000073],
            [-137.227338522999787, 51.270198881000226],
            [-137.252148548999912, 51.284945215000164],
            [-137.381236652999917, 51.398479604000272],
            [-137.397327979999943, 51.421911575000195],
            [-137.412655843999858, 51.43184629800021],
            [-137.416439793999984, 51.434298975000218],
            [-137.419643504999897, 51.437762447000239],
            [-137.421656678666523, 51.439938882333536],
            [-137.439810668999826, 51.450824899000111],
            [-137.460971971999868, 51.482376582000086],
            [-137.513819708999904, 51.539234892000252],
            [-137.518666286999803, 51.547246882000195],
            [-137.543134030333306, 51.587695137666856],
            [-137.677966594999987, 51.672812043000249],
            [-137.879393924999931, 51.914510274000122],
            [-138.316497794999805, 52.439003976000151],
            [-138.446105794999937, 52.658285976000116],
            [-138.446548794999899, 52.659097976000169],
            [-138.491027319999944, 52.733418813000185],
            [-138.542281404, 52.819138368000097],
            [-138.722317469999894, 53.189009448000149],
            [-138.738940794999962, 53.223160977000248],
            [-138.745358868999858, 53.321759685000245],
            [-138.755283094333208, 53.474233527833633],
            [-135.765833332999875, 54.000277778000168],
            [-134.939999999999827, 54.125000000000171],
            [-134.417499999999791, 54.2125],
            [-134.396388888999923, 54.215833333000205],
            [-134.180277777999891, 54.261111111000218],
            [-133.848976679999851, 54.336476967000294],
            [-133.8225, 54.34250000100019],
            [-133.81103776849983, 54.345896217000131],
            [-133.739999999999782, 54.366944444000069],
            [-133.282777777999854, 54.501666667000279],
            [-133.233333332999848, 54.517222222000157],
            [-133.191111110999827, 54.5116666670001],
            [-133.128611110999884, 54.502777778000336],
            [-133.116666666999777, 54.500833333000287],
            [-132.941111110999884, 54.475555556000188],
            [-132.931666666999945, 54.473611111000139],
            [-132.845, 54.456388889000152],
            [-132.826388888999986, 54.451944444000048],
            [-132.736666666999781, 54.433333333000178],
            [-132.662777777999963, 54.415000000000191],
            [-132.637777777999929, 54.409444444000258],
            [-132.4475, 54.410833333000141],
            [-132.409722221999971, 54.411388889000079],
            [-132.408055555999908, 54.411388889000079],
            [-132.394166666999865, 54.414444444000196],
            [-132.048333332999846, 54.364166667000177],
            [-131.824444443999937, 54.444722222000223],
            [-131.755555555999933, 54.471666667000193],
            [-131.633611111, 54.508888889000332],
            [-131.563333332999832, 54.498055556000224],
            [-131.322777777999931, 54.614722222000182],
            [-131.277098479999836, 54.648302679000153],
            [-131.271388888999923, 54.652500001000362],
            [-131.267829168999867, 54.655063994000272],
            [-131.231666666999956, 54.681111111000291],
            [-131.216666666999856, 54.703055556000095],
            [-131.078611110999873, 54.771111111000266],
            [-131.051666666999893, 54.760833333000221],
            [-130.995555555999829, 54.736666667000293],
            [-130.981944443999851, 54.729444444000194],
            [-130.961388888999863, 54.71666666700014],
            [-130.952499999999873, 54.709444444000269],
            [-130.940164464999839, 54.707751331500106],
            [-130.938333332999946, 54.707500001000028],
            [-130.9232024289999, 54.701695062000169],
            [-130.894166666999809, 54.69055555600022],
            [-130.888333332999878, 54.689166667000336],
            [-130.82138888899982, 54.684722222000175],
            [-130.808766826666499, 54.684996605666754],
            [-130.764166666999927, 54.679444444000126],
            [-130.749722221999832, 54.678055556000174],
            [-130.745277777999945, 54.678333333000126],
            [-130.706111110999927, 54.667500000000189],
            [-130.69305555499983, 54.663333334000072],
            [-130.664748875999976, 54.656308319000289],
            [-130.654999967499862, 54.653888954000251],
            [-130.653139928999792, 54.657609031000106],
            [-130.649444442999823, 54.665000002000284],
            [-130.649444442999823, 54.685833335000211],
            [-130.640555555, 54.706111113000247],
            [-130.63967983, 54.707205769000211],
            [-130.634999998999831, 54.713055557000359],
            [-130.632404195999925, 54.716206916000033],
            [-130.631944442999952, 54.716666668000073],
            [-130.630071858999827, 54.71922019300024],
            [-130.628888887999835, 54.720833335000123],
            [-130.627499918666501, 54.723333622000155],
            [-130.627706935, 54.725057289000176],
            [-130.630733764333229, 54.750253452666811],
            [-130.630396620999875, 54.752276863000304],
            [-130.627803786999834, 54.767832983000289],
            [-130.586786787999984, 54.782480978000137],
            [-130.534049786999901, 54.800059981000061],
            [-130.487172787999839, 54.823497979000081],
            [-130.443225786999847, 54.849866981000162],
            [-130.393418787999849, 54.876234979000117],
            [-130.361190786999771, 54.893813981000164],
            [-130.331892787999891, 54.917251979000184],
            [-130.29966478699987, 54.943620981000038],
            [-130.267436786999895, 54.972918981000305],
            [-130.22934978699999, 55.013935979000166],
            [-130.194191785999976, 55.046164981000175],
            [-130.173682785999915, 55.090111981000348],
            [-130.138525786999878, 55.136987979000196],
            [-130.103367786999854, 55.183864979000134],
            [-130.056490785999813, 55.2219529810003],
            [-129.992034786999909, 55.262969979000161],
            [-129.968595786999856, 55.286408979000271],
            [-129.994964785999912, 55.318636982000044],
            [-130.024262785999838, 55.371372982000196],
            [-130.053560785999878, 55.418249982000304],
            [-130.082858785999974, 55.47098698200017],
            [-130.106297785999857, 55.520793982000214],
            [-130.12680578599992, 55.561810982000281],
            [-130.132665785999961, 55.629196982000167],
            [-130.138525785999889, 55.676073982000332],
            [-130.147314785999924, 55.717091482],
            [-130.147314785999924, 55.755178982000132],
            [-130.123875786999918, 55.793265979000125],
            [-130.091648785999837, 55.816704982000147],
            [-130.050630785999829, 55.846002982000073],
            [-130.012543831499897, 55.887020432500037],
            [-130.014285785999903, 55.904516982000075],
            [-130.015072322072001, 55.909182532093041],
            [-130.014735856141499, 55.90999108803004],
            [-130.014463947201705, 56.02485400725817],
            [-130.053890911106578, 56.075553960413231],
            [-130.088590984521431, 56.11804501285161],
            [-130.229154998252113, 56.09027325298274],
            [-130.365264030432627, 56.123882170239085],
            [-130.447508941000478, 56.206382225940558],
            [-130.461946098708324, 56.235264252707054],
            [-130.484709000863518, 56.239436093567178],
            [-130.532773013100552, 56.246382176748],
            [-130.560717943089514, 56.25000014144473],
            [-130.627200014139589, 56.258609194220469],
            [-130.720554969759462, 56.325554113965211],
            [-130.75586407226092, 56.353053964894059],
            [-130.774446081100564, 56.366100061947009],
            [-130.847226984766507, 56.374436032316453],
            [-130.920008894260832, 56.382491208928627],
            [-131.062772989943255, 56.400827125090188],
            [-131.125826862585086, 56.424163181786128],
            [-131.144473076803251, 56.43471817720831],
            [-131.164735824872196, 56.445263281984751],
            [-131.213318006365114, 56.469154053034401],
            [-131.290008900235421, 56.500545118704451],
            [-131.314455056190923, 56.509991188333515],
            [-131.548617938924679, 56.599436153749139],
            [-131.578891026346128, 56.603327033214157],
            [-131.611117935403172, 56.602218107420811],
            [-131.824372998797458, 56.598400150513953],
            [-131.858035895510625, 56.718882130008353],
            [-131.861726950403295, 56.795827163183617],
            [-132.103055019267401, 56.866663297669504],
            [-132.091944806577374, 56.893609104797008],
            [-132.061955027484089, 56.959718181154699],
            [-132.036681912958898, 57.013054075227899],
            [-132.027499873280647, 57.036382085296623],
            [-132.221099876587971, 57.068054112362177],
            [-132.324235842688665, 57.089154044922623],
            [-132.261399899530147, 57.168882205236002],
            [-132.226655066752073, 57.20470914171699],
            [-132.354155046157018, 57.354436084386691],
            [-132.37443589913704, 57.375127147709122],
            [-132.451109023372453, 57.435263279847391],
            [-132.471926988709299, 57.451100215389431],
            [-132.498199896648202, 57.470409102874385],
            [-132.619444796972004, 57.583327085284168],
            [-132.762844911469131, 57.720127121564417],
            [-132.786664101065355, 57.751936108928135],
            [-132.795973042757964, 57.771382124349373],
            [-132.815900011784947, 57.798045126063073],
            [-132.877344894288626, 57.85943602911027],
            [-132.933199885549129, 57.909436093348347],
            [-132.964717853233594, 57.933327032036004],
            [-132.994990940655043, 57.951663283473749],
            [-133.038609023797335, 57.982909174580413],
            [-133.055555052770728, 57.997073249882021],
            [-133.070826880399011, 58.012218175495889],
            [-133.091244861315317, 58.040554037449965],
            [-133.108399934954207, 58.085754121002083],
            [-133.136963952313522, 58.135827107797752],
            [-133.18986398742021, 58.180127142584581],
            [-133.211399946585175, 58.196382167457898],
            [-133.23360897257686, 58.211382086146727],
            [-133.30611796730318, 58.257218188513491],
            [-133.361118004437344, 58.280545025115941],
            [-133.430299886507953, 58.35999121820555],
            [-133.408908934268254, 58.400273136236763],
            [-133.38790891399384, 58.412063288914226],
            [-133.429990929556027, 58.459163214645571],
            [-133.558899906888655, 58.528045024581502],
            [-133.73580885871803, 58.644718099624527],
            [-133.808344843172563, 58.709991164957387],
            [-133.826935904467689, 58.726100177077171],
            [-134.087491053441738, 58.808327150372179],
            [-134.238326921904502, 58.854435999869111],
            [-134.327499978380501, 58.925473132393506],
            [-134.325564094017636, 58.97110019009483],
            [-134.382781982738209, 59.053600078157956],
            [-134.466735963368308, 59.129091186404082],
            [-134.532226958184253, 59.132209086756063],
            [-134.566408862343394, 59.130545111332992],
            [-134.650844802407505, 59.185544980829263],
            [-134.673481976014727, 59.207491150336523],
            [-134.688044862270374, 59.243327139273219],
            [-134.738890995817314, 59.250273222453984],
            [-134.951935841079546, 59.279991092607531],
            [-135.091673063878659, 59.426936081605504],
            [-135.079708902890673, 59.444709069148303],
            [-135.06332697600277, 59.458045012413777],
            [-135.028135890698479, 59.469300064391291],
            [-135.0177909457704, 59.498882147712891],
            [-135.015017876916033, 59.540554120570562],
            [-135.014464000752753, 59.567500095336186],
            [-135.097226912938311, 59.621373269404103],
            [-135.126508924125403, 59.622209112791325],
            [-135.154173060357095, 59.627209085687298],
            [-135.177491012142241, 59.636936116711695],
            [-135.336117859628303, 59.726653996895436],
            [-135.473600015190982, 59.801936060476066],
            [-135.506135881199782, 59.793882224968456],
            [-135.823335931991835, 59.70555406433499],
            [-135.949155004529302, 59.669153973313087],
            [-136.071381928631752, 59.657491225564399],
            [-136.120817890784451, 59.651654068176526],
            [-136.15999993547797, 59.646663147735694],
            [-136.20776404321856, 59.639436103159767],
            [-136.310544951899715, 59.612500186677948],
            [-136.345126840479566, 59.601663224032109],
            [-136.29833587170026, 59.583600222638609],
            [-136.239163993706029, 59.561382144191384],
            [-136.23389093840359, 59.525827116650191],
            [-136.296526889351981, 59.47360014837011],
            [-136.371644835267773, 59.452491163354352],
            [-136.463617950326409, 59.469709101267313],
            [-136.46417299995619, 59.41415401450331],
            [-136.462490919622326, 59.372218179332833],
            [-136.462626874092223, 59.295963144428924],
            [-136.486526865235362, 59.255827071513409],
            [-136.558344855861947, 59.186382165320765],
            [-136.583891052793206, 59.163318185202627],
            [-136.611390903722395, 59.164709078219232],
            [-136.719726834929475, 59.16526328965881],
            [-136.808899891405474, 59.16526328965881],
            [-136.888335858573384, 59.131936004349939],
            [-136.941955060974408, 59.109436126317064],
            [-136.969726988481341, 59.098327087093423],
            [-137.033081939086003, 59.077491184483563],
            [-137.251681974512337, 59.006100168005901],
            [-137.296108911313468, 58.989991155886116],
            [-137.31418197099083, 58.981100135886948],
            [-137.33890003588607, 58.965544999930557],
            [-137.392790979589108, 58.928327170432851],
            [-137.423891025579962, 58.912763149659156],
            [-137.445008895413423, 58.907491100184814],
            [-137.471799972608096, 58.906654083331489],
            [-137.497635848113987, 58.914436009899532],
            [-137.506317991085325, 58.937909194531812],
            [-137.497217926420433, 58.964154106913838],
            [-137.483735970401256, 58.990273123109716],
            [-137.499144925965822, 59.041382113140969],
            [-137.54529099111295, 59.143054096029061],
            [-137.566099903994484, 59.186936041484159],
            [-137.590817968889951, 59.238600081144966],
            [-137.910281971735742, 59.40804511581382],
            [-138.117764083121131, 59.516663181882905],
            [-138.303617866768207, 59.613054062841172],
            [-138.491091034113083, 59.708327133189357],
            [-138.538608881537755, 59.732209187059652],
            [-138.615817944663888, 59.77416329477893],
            [-138.650982040239853, 59.810127023919961],
            [-138.667008909708386, 59.838391136782718],
            [-138.67501798585289, 59.866936043402688],
            [-138.690273049674829, 59.906935994210073],
            [-138.971926957742994, 59.97860009309386],
            [-139.047791060681618, 59.99749122652355],
            [-139.116390903394006, 60.04138222443396],
            [-139.161408932008612, 60.070273136017931],
            [-139.186399911672254, 60.095554129532218],
            [-139.155273043590967, 60.154991210943535],
            [-139.129455105357806, 60.201100060440638],
            [-139.082217884052227, 60.287500215700277],
            [-139.066882018683543, 60.344718104420792],
            [-139.519472940189871, 60.34470905196531],
            [-139.676663967658044, 60.340545090094167],
            [-139.77166395559982, 60.292500188596421],
            [-139.866391028773307, 60.244436008721323],
            [-139.913055095538027, 60.220827037257152],
            [-139.979426860742194, 60.187763278984733],
            [-140.00558191912188, 60.193882235957972],
            [-140.450835825482386, 60.309718126509551],
            [-140.471926873225414, 60.283882251003547],
            [-140.497499892247106, 60.251245131603923],
            [-140.521390998572826, 60.222218097912048],
            [-140.94638198922604, 60.29777324389886],
            [-140.995544868972445, 60.307209087605941],
            [-141.000582057518898, 60.366663268099956],
            [-141.001126881226867, 60.399436006693293],
            [-141.000308975112603, 60.933054115870902],
            [-141.001681930856591, 60.966382239370091],
            [-141.000308975112603, 62.73304502904972],
            [-141.001399963632934, 63.100000224867983],
            [-141.00195501326283, 63.832773192240722],
            [-141.000308975112603, 63.966382237973107],
            [-141.000308975112603, 64.199709109681692],
            [-141.00195501326283, 65.132754075308128],
            [-141.000308975112603, 65.166091083624679],
            [-141.000308975112603, 65.232754036145991],
            [-141.00195501326283, 65.699418173123206],
            [-141.00195501326283, 66.099427068930254],
            [-141.001681930856591, 66.499418195102464],
            [-141.000854972287016, 66.666382186424414],
            [-141.000582057518898, 66.866091088421172],
            [-141.001681930856591, 67.066373144958447],
            [-141.002226922202567, 67.299145134675484],
            [-141.000582057518898, 67.532482232306108],
            [-141.000000018160677, 67.732754062921572],
            [-141.00195501326283, 67.865818117307867],
            [-141.00195501326283, 68.065809154166232],
            [-141.00195501326283, 68.232754034749007],
            [-141.001681930856591, 68.532763304994205],
            [-141.000854972287016, 68.965545107032597],
            [-141.000582057518898, 69.4324821587781],
            [-141.000854972287016, 69.532209204847788],
            [-141.002990906748948, 69.64236358292942],
            [-140.997992474999819, 69.646860700500042],
            [-140.997561119000011, 69.647656255666845],
            [-140.993015453999988, 69.651813870000183],
            [-140.983611110999959, 69.660277779000182],
            [-140.979813442999898, 69.664919373000146],
            [-140.976111110999796, 69.669444446000171],
            [-140.949999998999942, 69.691666668000153],
            [-140.829166665999935, 69.773611112000253],
            [-140.785277776999862, 69.798333335000052],
            [-140.710277776999988, 69.861111112000174],
            [-140.322777776999885, 70.157222224000293],
            [-140.30296661299991, 70.190874064000184],
            [-140.30249999799986, 70.191666667000163],
            [-140.278138032999948, 70.243374696000046],
            [-140.164166665999971, 70.485277779000171],
            [-140.16249999899992, 70.488611112000228],
            [-140.04638888799991, 70.625277779000044],
            [-139.875277776999894, 70.806944446000273],
            [-139.787777776999974, 70.967222224000182],
            [-139.739999998999963, 71.020833335000134],
            [-139.566111110999884, 71.1994444460002],
            [-139.362777776999792, 71.386111112000151],
            [-138.661254686999939, 72.007719189000056],
            [-138.438611110999943, 72.205000001000201],
            [-137.980018296999845, 72.538154668000118],
            [-137.650555555999858, 72.777500000000259],
            [-137.568888889, 72.94694444400011],
            [-137.429547793999859, 72.930036995000137],
            [-137.270195793999847, 72.947589995000158],
            [-137.315327793999927, 73.761201996000182],
            [-137.142078793999985, 73.882991996000044],
            [-137.193220793999956, 73.915663996000262],
            [-137.276922793999915, 75.212695998000186],
            [-136.891094793999798, 75.543956998000169],
            [-136.770244793999836, 75.780401998000173],
            [-137.000154793999968, 76.158167998000067],
            [-137.056070793999822, 76.314733999000055],
            [-136.971654793999846, 76.728006999000229],
            [-137.017769793999889, 77.336245999000198],
            [-135.879993792999869, 77.937851000000251],
            [-134.628488791999985, 78.534531001000289],
            [-134.201412790999882, 78.623095001000252],
            [-133.195663789999969, 79.044273001000136],
            [-133.17195179, 79.051817001000302],
            [-133.157284789999892, 79.05958500100013],
            [-132.984874789999878, 79.127363001000163],
            [-131.188120787999935, 79.656074002000196],
            [-129.834081786999832, 79.909981002000279],
            [-128.756222785999853, 80.231732002000172],
            [-127.60724378499998, 80.549968002000242],
            [-126.380609783999887, 80.864326003000258],
            [-125.702215782999872, 80.968738003000055],
            [-125.689036782999779, 80.972318003000169],
            [-125.400232782999922, 81.027729003000275],
            [-125.172129782999775, 81.054513003000181],
            [-124.567183781999972, 81.166750003000118],
            [-124.404640781999888, 81.183561003000193],
            [-124.188881781999953, 81.232884003000208],
            [-122.587875779999933, 81.464824003000274],
            [-122.380772779999859, 81.483937003000221],
            [-122.247002779999946, 81.510880003000182],
            [-121.2401977789998, 81.639759003000165],
            [-120.426703777999876, 81.743884004000108],
            [-119.620004777999782, 81.847637004000262],
            [-118.82036177699996, 81.951001004000204],
            [-118.792603776999954, 81.954678004000129],
            [-118.636309776999951, 81.980382004000205],
            [-117.858989775999873, 82.111879004000173],
            [-117.095542774999899, 82.242521004000196],
            [-116.851502774999972, 82.285413004000134],
            [-116.783823774999973, 82.404731004000269],
            [-115.00363377299999, 83.220477005000134],
            [-114.333900772999925, 83.397857005000162],
            [-114.308836772999882, 83.40972700500015],
            [-113.516791771999863, 83.61834700500026],
            [-113.506497771999932, 83.621052005000251],
            [-113.504535771999826, 83.621464005000234],
            [-112.462636770999836, 83.874904006000293],
            [-111.332276769999964, 84.126234006000175],
            [-110.102479768999899, 84.375168006000195],
            [-108.735569766999902, 84.523149006000153],
            [-107.73352276699984, 84.724116006000258],
            [-106.65314676599985, 84.923402007000163],
            [-105.800057764999906, 85.0240890070001],
            [-105.227141763999953, 85.092209007000179],
            [-104.669642763999775, 85.159584007000262],
            [-104.030522762999965, 85.237939007000193],
            [-102.532936761999849, 85.415594007000266],
            [-101.550576760999775, 85.508642007000162],
            [-100.527186759999836, 85.60033900700023],
            [-99.460801759, 85.69059800700029],
            [-98.349405757999875, 85.779328007000174],
            [-97.533878756999911, 85.81474100700018],
            [-96.993219756999849, 85.86379600700019],
            [-96.381560755999828, 85.898656007000227],
            [-95.162840754999934, 85.966045008000151],
            [-93.803113753999867, 86.040228008000213],
            [-93.434944752999968, 86.052694008000117],
            [-92.399061751999966, 86.094946008000193],
            [-91.340941750999946, 86.135911008000221],
            [-90.260592749999887, 86.17554600800031],
            [-89.158080748999851, 86.213809008000169],
            [-88.03354074799995, 86.2506580080003],
            [-86.88718274699994, 86.286050008000188],
            [-85.719291745999897, 86.31994400800022],
            [-84.530234744999859, 86.352297008000164],
            [-82.600422742999967, 86.385867008000275],
            [-80.656708740999932, 86.418166008000128],
            [-79.59017974, 86.426969008000185],
            [-78.51874173899995, 86.434540008000113],
            [-77.443102737999936, 86.440872008000156],
            [-76.363990736999824, 86.445957008000278],
            [-75.282143735999966, 86.449791008000261],
            [-74.19831473499994, 86.452368008000121],
            [-73.113264733999983, 86.453687008000202],
            [-72.027757732999987, 86.45374600800028],
            [-70.942561731999888, 86.452544008000302],
            [-69.858442730999911, 86.450082008000152],
            [-68.980388729999902, 86.430030008000188],
            [-68.287531729999898, 86.426825008000208],
            [-67.845417728999792, 86.426803008000149],
            [-67.575186728999881, 86.42298200800019],
            [-66.551421727999866, 86.416484008000225],
            [-65.531629726999824, 86.408856008000271],
            [-64.516419725999867, 86.400107008000191],
            [-63.506382724999867, 86.390244008000195],
            [-62.502094723999846, 86.379276008000261],
            [-61.504105722999896, 86.367213008000306],
          ],
          [
            [-56.403229913999894, 45.87948682200016],
            [-56.402111110999897, 45.38444444400028],
            [-56.400731257999951, 44.733153887000128],
            [-56.400027777999981, 44.401111111000262],
            [-56.398027777999971, 43.41791666700027],
            [-56.314665482999828, 43.417291618000149],
            [-56.236099071999831, 43.416702528000087],
            [-56.157222497333237, 43.4161111133335],
            [-56.155083332999936, 44.462500000000205],
            [-56.152833332999933, 45.508333333000166],
            [-56.150444443999959, 46.55477777800013],
            [-56.125402287999862, 46.560216487000275],
            [-56.058580160999952, 46.574729059000219],
            [-56.029194443999927, 46.581111111000155],
            [-55.953388888999882, 46.617138889000216],
            [-55.94494278499991, 46.622027505000233],
            [-55.903333468499909, 46.64611103250013],
            [-55.924444443999903, 46.698888889000216],
            [-55.938005343999919, 46.723155762000147],
            [-55.945872020999957, 46.737232974000165],
            [-55.9825, 46.802777778000291],
            [-56.09166666699997, 46.855555556000184],
            [-56.096203410999863, 46.858147981000172],
            [-56.096920245999797, 46.858557601000143],
            [-56.099444443999857, 46.860000000000184],
            [-56.129722221999884, 46.931111111000234],
            [-56.119499489999839, 47.001177031000168],
            [-56.112838525999848, 47.046830827000178],
            [-56.105, 47.100555556000131],
            [-56.254999999999825, 47.305277778000118],
            [-56.359702257999913, 47.331392610000137],
            [-56.430159967999884, 47.348966169000107],
            [-56.494444443999839, 47.365000000000293],
            [-56.50688457099983, 47.353730560000088],
            [-56.523136888999943, 47.339007678000314],
            [-56.631111111, 47.241194444000087],
            [-56.654672731999938, 47.222590972000035],
            [-56.661142773999927, 47.217482442000289],
            [-56.662527777999856, 47.216388889000143],
            [-56.730314126999986, 47.187818388000267],
            [-56.868416666999963, 47.129611111000145],
            [-57.006668576999857, 47.040817767000192],
            [-57.096777595999924, 46.982944391000103],
            [-56.986749999999802, 46.798472222000044],
            [-56.935917499999903, 46.689527110000199],
            [-56.89869444399983, 46.60975000000019],
            [-56.844475379999892, 46.560140052000236],
            [-56.83791661149985, 46.554138853500149],
            [-56.688138888999958, 46.457888889000117],
            [-56.648333871999853, 46.444740377500239],
            [-56.50666666699982, 46.397944444000302],
            [-56.458515779999971, 46.38372398700011],
            [-56.40433333299984, 46.367722222000282],
            [-56.403229913999894, 45.87948682200016],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CCK", Country: "Cocos Is." },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [96.910976590000161, -15.558800662999957],
            [96.895370590000226, -15.558839662999986],
            [96.879763590000124, -15.558810662999988],
            [96.864157590000133, -15.558711662999968],
            [96.848552590000196, -15.558542663000011],
            [96.832947590000089, -15.558304662999944],
            [96.817344590000147, -15.557997663000037],
            [96.801743590000029, -15.557620663000023],
            [96.786143590000137, -15.557174662999984],
            [96.770546590000066, -15.556659663],
            [96.755318590000087, -15.556296662999983],
            [96.74009259000016, -15.555868663000012],
            [96.724868590000113, -15.555373663],
            [96.709646590000176, -15.55481266299995],
            [96.694427590000117, -15.554185662999942],
            [96.679212590000049, -15.553492662999972],
            [96.663999590000145, -15.55273366299997],
            [96.648791590000172, -15.551909663],
            [96.633586590000135, -15.551018663],
            [96.618386590000142, -15.550061662999951],
            [96.603191590000193, -15.549038663000047],
            [96.588000590000064, -15.547949663],
            [96.57281559000009, -15.546794663],
            [96.557635590000046, -15.545573662999956],
            [96.542461590000158, -15.544287662999963],
            [96.526970590000161, -15.542906663000011],
            [96.511485590000035, -15.541456663000019],
            [96.496008590000116, -15.53993866299993],
            [96.48053859000018, -15.538351662999972],
            [96.465075590000112, -15.536696663],
            [96.449621590000135, -15.534972663],
            [96.434175590000137, -15.53317966299997],
            [96.418738590000061, -15.531318663],
            [96.403310590000132, -15.529389663],
            [96.387892590000121, -15.527391662999975],
            [96.372483590000087, -15.525325662999919],
            [96.357084590000142, -15.523191663],
            [96.341696590000225, -15.520988662999983],
            [96.326318590000056, -15.518717663000018],
            [96.310952590000198, -15.516379663000023],
            [96.295596590000088, -15.51397266299999],
            [96.280253590000171, -15.511497662999929],
            [96.264921590000171, -15.508953662999943],
            [96.249602590000023, -15.506343663],
            [96.23429559000013, -15.503664661999935],
            [96.219002590000088, -15.50091766200002],
            [96.203722590000126, -15.498103661999977],
            [96.188455590000018, -15.495220662],
            [96.173202590000159, -15.492271661999979],
            [96.157964590000148, -15.489253661999939],
            [96.142740590000102, -15.486168661999955],
            [96.127531590000075, -15.483016662000011],
            [96.112338590000064, -15.479796661999956],
            [96.097160589000197, -15.476509661999941],
            [96.081998589000051, -15.473155661999982],
            [96.066670589000211, -15.469697661999959],
            [96.051360589000097, -15.466170661999982],
            [96.036066589000058, -15.462574662],
            [96.020791589000083, -15.458910661999965],
            [96.005533589000066, -15.455178662],
            [95.990293589000061, -15.451377662],
            [95.975072589, -15.447508661999976],
            [95.959870589000133, -15.443571662000011],
            [95.94468758900021, -15.439565661999936],
            [95.929524589000067, -15.435491662],
            [95.914381589000101, -15.431350661999943],
            [95.899257589000086, -15.427140661999957],
            [95.884155589000017, -15.422863661999926],
            [95.869073589000124, -15.418518661999954],
            [95.854012589000178, -15.414105661999955],
            [95.838973589000176, -15.409625662],
            [95.823956589000119, -15.405078661999923],
            [95.80896158900012, -15.400463662],
            [95.793988589000179, -15.395781661999933],
            [95.77903858900018, -15.39103266199993],
            [95.764111589000123, -15.386216661999981],
            [95.749208589000119, -15.381333661999989],
            [95.734329589000112, -15.376383661999967],
            [95.71947358900016, -15.371366661999986],
            [95.704642589000144, -15.366283661999972],
            [95.689836589000066, -15.361134661999913],
            [95.675055589000209, -15.355918662],
            [95.660299589000175, -15.350635661999945],
            [95.645569589000075, -15.345287662000032],
            [95.630865589000081, -15.339873661999988],
            [95.61618758900002, -15.334393661999982],
            [95.601536589000176, -15.328847661999944],
            [95.586912589000036, -15.323235661999945],
            [95.572315589000112, -15.317558661999982],
            [95.557746589000118, -15.311815661999972],
            [95.543204589000112, -15.306007662000013],
            [95.528691589000033, -15.300134662],
            [95.514206589000224, -15.294196661999948],
            [95.499751589000226, -15.288193662000012],
            [95.485324589000157, -15.282126661999939],
            [95.47092758900007, -15.275993662],
            [95.456559589000079, -15.26979666199999],
            [95.442222589000181, -15.263535662000038],
            [95.427915589000094, -15.25721066200002],
            [95.413638589000101, -15.250820662000038],
            [95.399393589000141, -15.244367662],
            [95.390052589000078, -15.240094661999947],
            [95.378221589000219, -15.234653661999971],
            [95.366411589000137, -15.22916966199999],
            [95.354623589000113, -15.223640662000022],
            [95.342858589000031, -15.218067661999967],
            [95.328636589000126, -15.211266662],
            [95.314447589000025, -15.204401661999967],
            [95.300291589000182, -15.197472661999981],
            [95.286170589000022, -15.19047966200003],
            [95.272082589, -15.183422661999927],
            [95.258030589000072, -15.176301661999958],
            [95.244011589000166, -15.169117662],
            [95.230028589000227, -15.161869662],
            [95.216081589000197, -15.154559661999926],
            [95.202169589000192, -15.147185661999984],
            [95.188292589000213, -15.139749661999957],
            [95.174453589000137, -15.132249661999978],
            [95.160650589000085, -15.124688661999926],
            [95.146883589000169, -15.11706466199999],
            [95.133154589000043, -15.109377661999986],
            [95.119463589000105, -15.101629662],
            [95.105809589000131, -15.093819661999945],
            [95.092193589000118, -15.085947662],
            [95.07861658900012, -15.078014661999987],
            [95.06507758900014, -15.070019662],
            [95.051577589000118, -15.061963662000011],
            [95.038116589000225, -15.053847661999953],
            [95.024695588000071, -15.045669662],
            [95.011314588000147, -15.037431661999989],
            [94.997973588000121, -15.029132661999967],
            [94.984672588000223, -15.020773661999968],
            [94.971412588000163, -15.012355661999976],
            [94.958193588000114, -15.003876661999982],
            [94.945016588000186, -14.995337661999926],
            [94.931879588000157, -14.986739661999948],
            [94.918785588000077, -14.978082662],
            [94.905733588, -14.96936566200003],
            [94.892723588000109, -14.960590661999973],
            [94.879755588000222, -14.951756662000022],
            [94.866831588000167, -14.942863661999979],
            [94.853950588000117, -14.933912662000026],
            [94.841112588000072, -14.924903661999977],
            [94.82831858800003, -14.915836662],
            [94.815568588000161, -14.90671166199995],
            [94.802863588000179, -14.897528661999985],
            [94.790202588000199, -14.888288662],
            [94.777586588000219, -14.878991662000018],
            [94.765015588000068, -14.869637661999946],
            [94.752489588000088, -14.860226661999931],
            [94.740091588000126, -14.850826662000046],
            [94.727739588000219, -14.84137066199996],
            [94.715431588000143, -14.831859661999943],
            [94.703170588000063, -14.822292662],
            [94.69095458800021, -14.812670661999945],
            [94.678785588000011, -14.802994661999946],
            [94.666663588000148, -14.79326366200003],
            [94.654587588000169, -14.783478662],
            [94.642559588000182, -14.773639661999923],
            [94.630578588000077, -14.763745662],
            [94.618644588000137, -14.75379866199998],
            [94.606758588000076, -14.743798662],
            [94.594920588000065, -14.733744662],
            [94.583131588000043, -14.723637661999959],
            [94.571390588000071, -14.713477661999974],
            [94.559699588000086, -14.703265662000049],
            [94.548056588000037, -14.693000661999989],
            [94.536462588000148, -14.682683661999988],
            [94.524918588000077, -14.672315662000031],
            [94.513424588000106, -14.661894661999952],
            [94.501980588000123, -14.651422662],
            [94.490586588000184, -14.640898661999913],
            [94.479243588000173, -14.630324661999964],
            [94.467951588000147, -14.619699661999974],
            [94.456709588000166, -14.609023662000013],
            [94.445519588000167, -14.598296661999925],
            [94.434380588000096, -14.58752066199996],
            [94.423293588000064, -14.576693662000039],
            [94.412257588000074, -14.565817661999958],
            [94.401274588000121, -14.554892661999929],
            [94.390344588000204, -14.543917662000013],
            [94.379465588000045, -14.53289366199995],
            [94.368640588000204, -14.521821662],
            [94.357868588000116, -14.510700662],
            [94.348012588000159, -14.500437662000024],
            [94.338202588000087, -14.490134661999932],
            [94.328437588000185, -14.479791662],
            [94.318717588000112, -14.469407662000052],
            [94.309043588000037, -14.458984661999978],
            [94.299415588000016, -14.448520661999979],
            [94.288716588000199, -14.436987662],
            [94.278072588000128, -14.425404660999959],
            [94.267485588000085, -14.413774660999934],
            [94.256955588000125, -14.402095661000047],
            [94.246481588000194, -14.390367661],
            [94.236064588, -14.378593660999968],
            [94.225705588000068, -14.366771660999959],
            [94.215403588000157, -14.354901660999985],
            [94.205159588000157, -14.342985661000014],
            [94.194973588000067, -14.331022660999977],
            [94.184844588000061, -14.319013660999957],
            [94.174774588000076, -14.306957660999942],
            [94.16476358800017, -14.294856660999955],
            [94.154810588000174, -14.28270966099997],
            [94.144917588000084, -14.270517660999985],
            [94.135082588000074, -14.258279661000017],
            [94.12530758800014, -14.245997660999976],
            [94.115591588000115, -14.233671661],
            [94.105936588000105, -14.221300660999958],
            [94.096340588000061, -14.208885661000011],
            [94.086804588000206, -14.196427660999959],
            [94.07732958800014, -14.183925661],
            [94.06791458800015, -14.171380660999944],
            [94.05856158800006, -14.158792660999978],
            [94.04926858800016, -14.146162661],
            [94.040036588000049, -14.133489660999928],
            [94.030866588000066, -14.120774660999928],
            [94.021757588000099, -14.108018661],
            [94.012710588000033, -14.095220660999885],
            [94.003725588000094, -14.082381660999843],
            [93.994802588000226, -14.069501660999876],
            [93.985941588000088, -14.056580660999884],
            [93.977143588000075, -14.043619660999966],
            [93.968407588000076, -14.030618660999934],
            [93.9597355880002, -14.017577660999962],
            [93.951125587000121, -14.004497660999874],
            [93.942579587000097, -13.991378660999843],
            [93.934095587000144, -13.978219660999883],
            [93.92567658700014, -13.965023660999876],
            [93.917320587000205, -13.95178766099994],
            [93.909028587000165, -13.938514660999871],
            [93.900801587000075, -13.925203660999856],
            [93.892637587000223, -13.911855660999976],
            [93.884538587000151, -13.898469660999879],
            [93.876504587000028, -13.885047660999831],
            [93.868534587000141, -13.871588661],
            [93.860629587000147, -13.858093660999955],
            [93.852789587000103, -13.844562660999941],
            [93.845015587000177, -13.830995660999974],
            [93.837306587000143, -13.817393660999855],
            [93.829662587000058, -13.803756660999866],
            [93.822084587000091, -13.790084661],
            [93.814572587000185, -13.776378661],
            [93.807126587000226, -13.762638660999826],
            [93.79991358700002, -13.749188660999948],
            [93.792764587000107, -13.735707661],
            [93.785678587000092, -13.722194660999961],
            [93.778656587000143, -13.708649660999939],
            [93.771697587000148, -13.695074661000021],
            [93.764802587000219, -13.681467660999942],
            [93.757971587000185, -13.667830661000039],
            [93.751204587000103, -13.654162660999972],
            [93.744501587000144, -13.640465661],
            [93.73786258700008, -13.626737660999936],
            [93.73128858700008, -13.612981660999964],
            [93.724778587000202, -13.599195660999897],
            [93.718333587000217, -13.585380660999931],
            [93.711953587000181, -13.571536660999953],
            [93.705638587000095, -13.557665660999874],
            [93.699388587000072, -13.543765660999981],
            [93.69320358700017, -13.529837660999888],
            [93.68708358700016, -13.515882660999878],
            [93.681029587000154, -13.501900661000036],
            [93.675040587000154, -13.487891660999907],
            [93.669116587000104, -13.473856660999942],
            [93.663259587000169, -13.459794660999961],
            [93.657467587000184, -13.44570666099996],
            [93.651741587000089, -13.431593661000022],
            [93.646081587000111, -13.417454660999979],
            [93.640488587000078, -13.4032906609999],
            [93.634960587000108, -13.389101660999984],
            [93.629499587000083, -13.374888660999943],
            [93.624105587000173, -13.360650660999966],
            [93.618777587000153, -13.346389659999858],
            [93.613516587000134, -13.332104659999899],
            [93.608321587, -13.31779666],
            [93.603194587000161, -13.303464659999875],
            [93.598133587000092, -13.289110659999892],
            [93.593139587000081, -13.27473466000005],
            [93.588213587000183, -13.260335659999981],
            [93.583318587000093, -13.245886659999954],
            [93.578492587000113, -13.231416659999965],
            [93.573733587000078, -13.216924659999933],
            [93.569042587000155, -13.202411659999939],
            [93.564419587, -13.187878659999981],
            [93.559864587000078, -13.17332365999988],
            [93.555377587000152, -13.15874966],
            [93.55095858700011, -13.14415466],
            [93.546607587000182, -13.129540659999876],
            [93.542324587000081, -13.114907659999901],
            [93.538110587000034, -13.10025565999986],
            [93.533964587000099, -13.085584659999853],
            [93.529887587000161, -13.070894659999865],
            [93.525878587000108, -13.05618766],
            [93.521938587000164, -13.041462659999951],
            [93.518067587000161, -13.026719660000026],
            [93.514265587000097, -13.011959659999931],
            [93.510531587000202, -12.997182659999851],
            [93.506866587000019, -12.982389659999967],
            [93.503270587000117, -12.96758066],
            [93.499744587000151, -12.952754659999854],
            [93.496286587000014, -12.93791366],
            [93.492898587000099, -12.923057659999955],
            [93.489578587000011, -12.908185659999916],
            [93.486328587000145, -12.893299659999883],
            [93.483148587000102, -12.878399659999843],
            [93.480036587000171, -12.863484659999983],
            [93.476995587000118, -12.848556659999844],
            [93.474022587000064, -12.833614659999881],
            [93.471119587000118, -12.818659659999909],
            [93.468286587000108, -12.803692659999925],
            [93.465523587000092, -12.788711659999933],
            [93.46282958700013, -12.773719659999827],
            [93.460205587000104, -12.758715659999979],
            [93.457650587000074, -12.743699659999933],
            [93.455165587000096, -12.728671659999961],
            [93.452751587000108, -12.713633659999871],
            [93.45040658700006, -12.698585659999935],
            [93.44813158700012, -12.683525659999887],
            [93.445926587000173, -12.66845666],
            [93.443791587000106, -12.653377659999876],
            [93.441726587000147, -12.638289659999828],
            [93.439730587000014, -12.623192659999916],
            [93.437806587000154, -12.608086659999884],
            [93.435951587000062, -12.592971659999819],
            [93.434145587000017, -12.577692659999954],
            [93.432411587000075, -12.562405659999868],
            [93.430749587000065, -12.547111659999914],
            [93.429158587000217, -12.53180966],
            [93.427639587000129, -12.516500659999878],
            [93.426191587000147, -12.501185659999962],
            [93.424816587000151, -12.485863659999907],
            [93.423512587000147, -12.470535659999896],
            [93.422280587000188, -12.45520165999983],
            [93.421119587000049, -12.439862659999974],
            [93.420030587000184, -12.424518659999876],
            [93.419013587000137, -12.409169659999989],
            [93.418068587000022, -12.393816659999857],
            [93.41719558700018, -12.378458659999936],
            [93.416393587000158, -12.363097659999951],
            [93.415664587000066, -12.347732659999892],
            [93.415006587000079, -12.332364659999854],
            [93.414419587000083, -12.316993659999923],
            [93.413905587000187, -12.301620659999912],
            [93.413462587000055, -12.286244659999923],
            [93.413092587000079, -12.270867659000032],
            [93.412793587000039, -12.25548865899988],
            [93.412566587000157, -12.24010865899983],
            [93.41241058700021, -12.224727658999868],
            [93.412327587000192, -12.209346659],
            [93.412315587000165, -12.19396465899996],
            [93.412375587000071, -12.178582658999915],
            [93.412506587000081, -12.163201658999867],
            [93.41270958700008, -12.147820658999819],
            [93.412984587000011, -12.132441658999852],
            [93.413175587000097, -12.116911658999911],
            [93.41343958700017, -12.101383658999865],
            [93.413775587000174, -12.085855659],
            [93.414185587000105, -12.070329659000038],
            [93.414668587000079, -12.054806658999965],
            [93.415224587000097, -12.039284658999975],
            [93.415853587000157, -12.023766658999961],
            [93.416554587000093, -12.008250658999842],
            [93.417329587000182, -11.992738658999883],
            [93.416738587000197, -11.977365658999872],
            [93.416218587000088, -11.961990658999881],
            [93.415770587000139, -11.946613658999894],
            [93.415393587000125, -11.931234658999927],
            [93.415089587000097, -11.915853658999879],
            [93.414856587000173, -11.900471658999834],
            [93.414695587000011, -11.885088658999891],
            [93.414605587000125, -11.869705659000033],
            [93.414588587000054, -11.854321658999822],
            [93.414641587000148, -11.838938658999879],
            [93.414767587000114, -11.823554658999939],
            [93.414964587000071, -11.808172658999894],
            [93.415233587000131, -11.792790658999948],
            [93.415573587000182, -11.777410658999983],
            [93.415985587000165, -11.762032658999914],
            [93.41646958700008, -11.746655658999842],
            [93.417024587000157, -11.731281658999933],
            [93.417651587000165, -11.715910659],
            [93.418349587000108, -11.700541658999967],
            [93.41911958700021, -11.685176658999907],
            [93.419960587000077, -11.669815658999923],
            [93.420872587000218, -11.654457659],
            [93.421856587, -11.639104658999869],
            [93.422911587000186, -11.623755658999983],
            [93.424038587000069, -11.608411658999884],
            [93.425236587000171, -11.593073659000027],
            [93.426505587000094, -11.577740658999858],
            [93.427845587000121, -11.562412658999847],
            [93.429257587000137, -11.547092658999873],
            [93.430739587000204, -11.531777658999957],
            [93.432293587000089, -11.516470659],
            [93.433918587000079, -11.501169658999899],
            [93.43561458700006, -11.485876658999942],
            [93.437380587000092, -11.470591659000021],
            [93.439218587000113, -11.455314658999967],
            [93.441126587000127, -11.440046658999847],
            [93.443106587000017, -11.424786658999864],
            [93.445156587000071, -11.409535658999914],
            [93.447276587000061, -11.394294659],
            [93.449468587000155, -11.379063658999925],
            [93.451729587000187, -11.363841658999888],
            [93.453785587000112, -11.350393658999906],
            [93.455895587000072, -11.336953658999875],
            [93.45806158700006, -11.32352165899988],
            [93.46028258700008, -11.310098658999834],
            [93.46288758700021, -11.294753658999923],
            [93.465564587000046, -11.279421658999937],
            [93.468314587000094, -11.26410065899988],
            [93.471134587000194, -11.248793658999915],
            [93.474027587000108, -11.233498658999878],
            [93.476991587000128, -11.218217658999933],
            [93.480027587000137, -11.202950658],
            [93.483134587000023, -11.187697657999877],
            [93.486312587000072, -11.172458657999854],
            [93.489561587000111, -11.157233657999925],
            [93.492882587000196, -11.142024657999897],
            [93.496071587000159, -11.127691657999875],
            [93.499324587000189, -11.113371657999863],
            [93.502639587000118, -11.099066658000027],
            [93.506018587000113, -11.084775657999927],
            [93.509460587000063, -11.070499658],
            [93.512964587000084, -11.056237657999816],
            [93.516532587000171, -11.041991657999887],
            [93.520162587000215, -11.027761657999861],
            [93.523854587000159, -11.013546657999925],
            [93.527610587000169, -10.999347657999976],
            [93.531428587000136, -10.985165658000014],
            [93.535308587000173, -10.970999657999954],
            [93.539251587000109, -10.956850657999965],
            [93.543256587000116, -10.942718657999876],
            [93.547323587000079, -10.928604657999855],
            [93.55155758700019, -10.914143657999901],
            [93.555857587000077, -10.899700658000015],
            [93.560222587000027, -10.885277658],
            [93.564653587000095, -10.870874657999877],
            [93.569148587000228, -10.856490657999899],
            [93.573708587000141, -10.842126657999884],
            [93.578333587, -10.827782657999933],
            [93.583022587000102, -10.813459657999942],
            [93.58777658700015, -10.799157658],
            [93.592595587000091, -10.784876658000016],
            [93.5974775870001, -10.770616658],
            [93.602424587000229, -10.756379657999915],
            [93.607436587000137, -10.742163657999896],
            [93.612511587000114, -10.727969658],
            [93.617650587000156, -10.713798657999888],
            [93.622853587000151, -10.699650657999896],
            [93.628120587000097, -10.685526657999844],
            [93.633450587000226, -10.671424658000021],
            [93.638844587000136, -10.657346657999867],
            [93.644301587000172, -10.643293657999834],
            [93.649822587000102, -10.629263658000013],
            [93.655405587000104, -10.61525865799986],
            [93.661162587000121, -10.60086665799993],
            [93.666986587000082, -10.586500657999935],
            [93.672876587000161, -10.572161657999857],
            [93.678832587000016, -10.557849657999981],
            [93.684854587000103, -10.54356365799994],
            [93.690943587000135, -10.529306657999896],
            [93.697097587000115, -10.51507665799987],
            [93.703317587000157, -10.500875657999842],
            [93.709603587000146, -10.486702657999913],
            [93.715954587000198, -10.472557658],
            [93.722371587000083, -10.458442657999981],
            [93.728853587000145, -10.444356657999876],
            [93.735400587000214, -10.430299657999868],
            [93.742011587, -10.416273658000023],
            [93.748688587000203, -10.402277657999903],
            [93.755429587, -10.388311657999964],
            [93.762234587000222, -10.374376657999917],
            [93.769104587000101, -10.360472657999864],
            [93.77580358700007, -10.347059657999935],
            [93.782562587000115, -10.333676657999916],
            [93.789380587000068, -10.320322657999895],
            [93.796258587000096, -10.30699865799987],
            [93.803196587000144, -10.293705657999922],
            [93.810192587000159, -10.280443657999967],
            [93.81724858700008, -10.267211657999908],
            [93.824363587000022, -10.254011658000024],
            [93.831536587000215, -10.240842657999849],
            [93.83876958700003, -10.227705657999934],
            [93.846059587000042, -10.214599657999827],
            [93.853644587000218, -10.201098658],
            [93.861291587000125, -10.187632657999941],
            [93.869000587000158, -10.174200657999947],
            [93.876770587000095, -10.160804658],
            [93.884602587000103, -10.147443657999844],
            [93.892496587000011, -10.134117656999834],
            [93.900451587000163, -10.120828656999862],
            [93.908467587000104, -10.107575656999941],
            [93.916543587000064, -10.094358656999972],
            [93.924681587000151, -10.081178656999867],
            [93.932879587000144, -10.068036656999894],
            [93.941137587000156, -10.054931656999869],
            [93.949456587000128, -10.041863656999979],
            [93.957834588000111, -10.028833656999851],
            [93.966273588000064, -10.015842656999936],
            [93.974771588000209, -10.002889656999869],
            [93.983328588000148, -9.989974656999848],
            [93.991945588000107, -9.97709965699994],
            [94.0006215880002, -9.964263656999876],
            [94.009356588000145, -9.951466657000026],
            [94.018149588000171, -9.938709656999833],
            [94.027001588000218, -9.925993656999935],
            [94.035912588000059, -9.913316656999882],
            [94.044880588000098, -9.900681656999936],
            [94.053906588000103, -9.888086656999917],
            [94.062991588000074, -9.875532656999923],
            [94.072132588000187, -9.863020656999936],
            [94.081332588000151, -9.850549656999874],
            [94.090588588000202, -9.838120656999919],
            [94.099901588000108, -9.82573465699997],
            [94.109271588000155, -9.813390656999843],
            [94.118698588000171, -9.801088657],
            [94.12818158800016, -9.788830656999892],
            [94.137720588000121, -9.776614656999882],
            [94.147315588000225, -9.764442656999876],
            [94.156966588000131, -9.752314656999957],
            [94.166672588000012, -9.740230656999941],
            [94.176434588000092, -9.728190656999928],
            [94.186251588000033, -9.716195657],
            [94.196122588, -9.704244656999975],
            [94.20604958800007, -9.692338656999951],
            [94.216030588000166, -9.680477656999912],
            [94.226065588000012, -9.668662656999956],
            [94.236154588000062, -9.656893656999983],
            [94.246297588000147, -9.645169656999911],
            [94.256494588000095, -9.633492657],
            [94.266745588000134, -9.621861656999897],
            [94.277048588000213, -9.61027765699987],
            [94.287404588000157, -9.598740656999908],
            [94.297814588000136, -9.587250656999927],
            [94.308275588000157, -9.575807656999928],
            [94.318789588000215, -9.564412657],
            [94.329356588000024, -9.553065656999848],
            [94.33997458800016, -9.541766656999954],
            [94.350643588000167, -9.530516656999936],
            [94.3613645880001, -9.519314656999981],
            [94.372137588000072, -9.508161656999903],
            [94.382960588000202, -9.497057656999885],
            [94.393834588000033, -9.486002656999929],
            [94.404758588000078, -9.474997656999932],
            [94.415733588000165, -9.464041657],
            [94.426758588000126, -9.453136656999916],
            [94.437833588000132, -9.442280657],
            [94.448957588000127, -9.431475656999936],
            [94.460130588000169, -9.420721656999831],
            [94.471353588000085, -9.410018656999867],
            [94.482625588000161, -9.399365656999862],
            [94.493945588, -9.388765656999979],
            [94.505313588000121, -9.37821565699997],
            [94.516730588000172, -9.367718657],
            [94.528195588000045, -9.357272656999882],
            [94.539707588000084, -9.346879656999988],
            [94.551267588000172, -9.336538656999863],
            [94.562874588000142, -9.326249656999863],
            [94.574527588000109, -9.316014656999897],
            [94.586228588000125, -9.30583165699997],
            [94.597975588000139, -9.295702656999978],
            [94.609768588000151, -9.285627656999921],
            [94.62160758800016, -9.2756056569999],
            [94.633492588000223, -9.265637657],
            [94.645422588, -9.255723657000033],
            [94.657397588000066, -9.245863657],
            [94.669418588000013, -9.236058656999901],
            [94.681483588000134, -9.226308656999919],
            [94.693592588000087, -9.216612656999871],
            [94.705746588000039, -9.206972656999838],
            [94.717944588000165, -9.197387657],
            [94.730185588000182, -9.187858656999921],
            [94.742470588000145, -9.178384656999938],
            [94.75479858800017, -9.168967656999882],
            [94.767168588000146, -9.159605656999943],
            [94.779582588000181, -9.150300657],
            [94.792038588000167, -9.141051657],
            [94.804536588000047, -9.131859657],
            [94.817075588000222, -9.122724656999907],
            [94.82965758800006, -9.113646656999833],
            [94.842279588000082, -9.104625656999858],
            [94.854943588000225, -9.095662656999977],
            [94.867648588000094, -9.086756657],
            [94.880393588000089, -9.077909656999864],
            [94.893178588000097, -9.069119656999817],
            [94.906004588000229, -9.060387655999861],
            [94.918869588000092, -9.051714655999902],
            [94.931773588000198, -9.043100655999936],
            [94.944717588000145, -9.03454465599998],
            [94.957700588000108, -9.026047655999932],
            [94.970631588000202, -9.017662656],
            [94.983601588000084, -9.009335655999976],
            [94.996608588000157, -9.001067655999861],
            [95.00965358800002, -8.992858655999839],
            [95.022735588000188, -8.984708655999896],
            [95.035853589000027, -8.976617655999959],
            [95.049008589000124, -8.968585655999917],
            [95.06219958900013, -8.960613655999964],
            [95.075427589000043, -8.952701655999817],
            [95.088690589000095, -8.944848656000033],
            [95.101988589000172, -8.93705565599987],
            [95.115322589000158, -8.92932365599988],
            [95.128690589000172, -8.921650655999883],
            [95.142094589000152, -8.91403865599996],
            [95.155531589000105, -8.906487656000024],
            [95.169003589000141, -8.898997655999977],
            [95.182508589000037, -8.891567655999836],
            [95.196047589000074, -8.884198655999853],
            [95.209619589000027, -8.876891655999856],
            [95.223224589000125, -8.869645655999932],
            [95.236862589000083, -8.862461655999979],
            [95.250532589000073, -8.855338656000015],
            [95.264234589000097, -8.848277655999851],
            [95.277969589000037, -8.841278655999844],
            [95.291734589000129, -8.834341656],
            [95.305531589000026, -8.827467655999939],
            [95.319359589000015, -8.82065465599986],
            [95.333218589000154, -8.813905655999932],
            [95.347107589000217, -8.807218655999989],
            [95.36102658900009, -8.800593655999833],
            [95.374975589000229, -8.794032656000013],
            [95.388954589000178, -8.787534655999977],
            [95.402962589000055, -8.781099656],
            [95.416999589000085, -8.774727655999826],
            [95.431064589000044, -8.768419655999978],
            [95.445158589000101, -8.762174655999914],
            [95.459280589000088, -8.755993655999816],
            [95.47343058900006, -8.74987665599987],
            [95.487608589000132, -8.743823655999975],
            [95.501958589000225, -8.737766656000019],
            [95.516336589000076, -8.731775656000011],
            [95.530741589000087, -8.725849655999966],
            [95.545173589000143, -8.719990655999865],
            [95.55963158900002, -8.714196656],
            [95.57411658900017, -8.708468656],
            [95.588627589000026, -8.702806655999851],
            [95.603164589000045, -8.697211656000022],
            [95.617726589000171, -8.69168265599987],
            [95.63231458900006, -8.686220655999847],
            [95.646926589000174, -8.680824655999956],
            [95.661562589000056, -8.675495655999924],
            [95.676223589000216, -8.670233655999837],
            [95.690908589000202, -8.665038655999879],
            [95.705616589000016, -8.659909655999968],
            [95.720348589000167, -8.654848656],
            [95.735103589000033, -8.649855655999872],
            [95.749880589000014, -8.644929655999974],
            [95.764679589000053, -8.640070655999921],
            [95.779501589000034, -8.635279655999909],
            [95.79434558900013, -8.630555655999842],
            [95.809209589000062, -8.625900655999885],
            [95.824095589000166, -8.621312655999972],
            [95.83900258900016, -8.616792656],
            [95.853929589000046, -8.61234165599987],
            [95.868877589000164, -8.607957655999869],
            [95.883844589000063, -8.603642655999892],
            [95.898831589000082, -8.599395655999857],
            [95.91383758900011, -8.595217655999846],
            [95.92886258900009, -8.591107655999863],
            [95.943906589000079, -8.58706665599982],
            [95.958968589000193, -8.583093655999988],
            [95.973872589000194, -8.579232655999917],
            [95.988794589000094, -8.575437655999977],
            [96.003733589000177, -8.571710655999979],
            [96.018688589000163, -8.568050655999926],
            [96.033660589000107, -8.564458656],
            [96.048648589000067, -8.560933656000017],
            [96.0636525890001, -8.557476655999977],
            [96.078671589000038, -8.554087655999879],
            [96.093705589000052, -8.550766656],
            [96.108754590000075, -8.547512655999867],
            [96.123817590000186, -8.544327655999865],
            [96.138895590000203, -8.541209655999893],
            [96.153987590000071, -8.53816065599986],
            [96.16909259000002, -8.535178655999957],
            [96.184211590000046, -8.532265655999893],
            [96.199343590000097, -8.529420655999957],
            [96.21448759, -8.526643655999962],
            [96.229644590000163, -8.523935655999892],
            [96.244813590000064, -8.521295655999864],
            [96.259993590000107, -8.518724655999947],
            [96.275185590000063, -8.516221655999971],
            [96.290388590000106, -8.513786655999937],
            [96.30560259000012, -8.511421655999925],
            [96.32082759000005, -8.509124655999941],
            [96.336061590000014, -8.506895655999983],
            [96.351306590000178, -8.504736655999963],
            [96.366560590000091, -8.50264565599997],
            [96.38182459000015, -8.500623656],
            [96.397096590000132, -8.498670655999874],
            [96.412377590000091, -8.49678665599987],
            [96.427667590000084, -8.494971655999976],
            [96.44296459, -8.493225655999836],
            [96.458270590000069, -8.491548655999907],
            [96.47358259, -8.489940655999817],
            [96.488902590000151, -8.488402655999934],
            [96.504228590000167, -8.486932655999894],
            [96.519562590000163, -8.485531655999878],
            [96.534901590000089, -8.484200655999885],
            [96.550246590000114, -8.482938655999831],
            [96.56562259, -8.481742656],
            [96.581003590000051, -8.48061665599991],
            [96.596390590000141, -8.47955965599985],
            [96.61178159000022, -8.478572655999898],
            [96.62717659, -8.47765465599997],
            [96.642575590000121, -8.47680665599988],
            [96.657978590000226, -8.476027655999914],
            [96.673384590000211, -8.475319655999954],
            [96.688794590000072, -8.474679656000021],
            [96.704206590000098, -8.474110655999837],
            [96.719620590000062, -8.473610655999948],
            [96.735037590000132, -8.473180655999982],
            [96.750455590000144, -8.472819655999956],
            [96.765875590000206, -8.472528655999952],
            [96.781296590000039, -8.47230765599987],
            [96.796718590000097, -8.472156655999896],
            [96.812140590000155, -8.472074655999862],
            [96.82756359000021, -8.472062655999849],
            [96.842985590000097, -8.472120655999944],
            [96.858407590000155, -8.47224765599988],
            [96.873829590000156, -8.47244565599992],
            [96.889249590000219, -8.472711655999902],
            [96.904668590000114, -8.473048655999989],
            [96.920085590000127, -8.473454655999916],
            [96.935500590000146, -8.473930655999865],
            [96.95091359000017, -8.474476655999922],
            [96.966324590000141, -8.475091656],
            [96.981731590000123, -8.475776655999837],
            [96.997135590000113, -8.476530655999966],
            [97.012536590000224, -8.47735565599983],
            [97.02793259, -8.478248656],
            [97.043325590000137, -8.479212655999916],
            [97.058713590000053, -8.480245655999852],
            [97.074096590000096, -8.481347656],
            [97.089474590000151, -8.48251965599998],
            [97.104847590000162, -8.483760655999987],
            [97.120213590000134, -8.485071655999832],
            [97.135574590000118, -8.486452655999969],
            [97.150928590000063, -8.487902655999861],
            [97.166276590000138, -8.489421655999863],
            [97.181617591000162, -8.491009655999989],
            [97.196982591000136, -8.492663655999877],
            [97.212340591000128, -8.494387655999972],
            [97.2276895910002, -8.496180655999908],
            [97.243031591000118, -8.498043655999865],
            [97.258364591000117, -8.499975655999847],
            [97.273688591000081, -8.501976655999854],
            [97.28900259100007, -8.504047655999884],
            [97.304307591000139, -8.506187655999938],
            [97.319603591000174, -8.508397655999914],
            [97.334888591, -8.510676655999831],
            [97.350163591000154, -8.513024655999857],
            [97.365427591000213, -8.515441656],
            [97.380679591000074, -8.517927655999813],
            [97.395921591000075, -8.520483655999911],
            [97.411150591000165, -8.523107655999866],
            [97.426368591000113, -8.52580065599993],
            [97.441573591000093, -8.52856265600002],
            [97.456766591000047, -8.531393655999864],
            [97.47194559100015, -8.534293655999917],
            [97.487111591000172, -8.537262656],
            [97.502264591000113, -8.540299655999831],
            [97.517403591000203, -8.543405655999962],
            [97.532527591000218, -8.546579655999949],
            [97.54763759100004, -8.549822655999861],
            [97.562733591000125, -8.5531336559999],
            [97.577813591000137, -8.556512655999882],
            [97.592877591000075, -8.559960655999888],
            [97.607926591000108, -8.56347665600002],
            [97.622958591000128, -8.567060656],
            [97.637975591000071, -8.570712655999912],
            [97.65297459100006, -8.574432655999956],
            [97.667957591000032, -8.578220655999942],
            [97.682922591000221, -8.582076655999956],
            [97.697869591000227, -8.585999655999913],
            [97.712799591000106, -8.589990656],
            [97.727710591000147, -8.594049655999925],
            [97.742603591000062, -8.598175655999896],
            [97.757477591000082, -8.602369656],
            [97.772332591000037, -8.606630655999851],
            [97.787167591000212, -8.610958655999923],
            [97.801983591000209, -8.615353655999954],
            [97.816779591000085, -8.619816655999912],
            [97.831554591000014, -8.624345655999832],
            [97.846308591000053, -8.628941655999881],
            [97.861042591000029, -8.633604655999875],
            [97.87575459100006, -8.638333655999915],
            [97.890444591000033, -8.643130655999983],
            [97.90511359100006, -8.647992655999829],
            [97.919759591000144, -8.65292165599989],
            [97.93438359100017, -8.657917656],
            [97.948985591000138, -8.662978655999879],
            [97.96356359100011, -8.668105655999895],
            [97.978117591000029, -8.673299655999855],
            [97.992648591000176, -8.678558655999964],
            [98.007155591000043, -8.68388365600002],
            [98.021638591000197, -8.689274655999938],
            [98.036096591000188, -8.69473065599982],
            [98.050529591000128, -8.700252655999918],
            [98.064937591000131, -8.705839655999895],
            [98.079320591000197, -8.711491656],
            [98.093676591000104, -8.717208655999897],
            [98.108007591000131, -8.722990656000022],
            [98.122311591000226, -8.728837655999925],
            [98.136731591000085, -8.734801655999917],
            [98.151124591000013, -8.740831655999955],
            [98.165488591000013, -8.746926655999857],
            [98.179825591000196, -8.753087655999892],
            [98.19413359100011, -8.75931365599989],
            [98.208412591000098, -8.765605655999934],
            [98.222663591000099, -8.771962656000028],
            [98.236884591000177, -8.778384655999986],
            [98.251076592000146, -8.784871655999908],
            [98.265238592000145, -8.791423655999878],
            [98.279369592000165, -8.798039655999901],
            [98.293471592000088, -8.804720655999887],
            [98.307541592000092, -8.811465655999839],
            [98.321580592000117, -8.818275656000026],
            [98.335588592000164, -8.825149656],
            [98.349565592000062, -8.832087655999928],
            [98.363509592000042, -8.839088655999831],
            [98.377421592000047, -8.846154655999968],
            [98.391301592000076, -8.85328265599999],
            [98.405147592000191, -8.86047465599998],
            [98.418961592000102, -8.867730655999935],
            [98.432741592000099, -8.875048655999862],
            [98.446488592000122, -8.882430655999855],
            [98.46020059200012, -8.889874656],
            [98.473879592000031, -8.897381655999851],
            [98.487522592000147, -8.904950655999855],
            [98.501131592000121, -8.912581655999844],
            [98.51470559200007, -8.920275655999887],
            [98.528243592000109, -8.928031655999916],
            [98.541746592000123, -8.935848656000019],
            [98.555213592000172, -8.943727656],
            [98.568643592000086, -8.951667655999884],
            [98.582037592000034, -8.959669656000017],
            [98.595393592000136, -8.967732655999953],
            [98.6087135920001, -8.975856655999962],
            [98.621996592000102, -8.98404165599996],
            [98.635240592000088, -8.992286656000033],
            [98.64844759200011, -9.00059265599991],
            [98.661315592000136, -9.008755655999948],
            [98.674147592000139, -9.016975656],
            [98.686942592000179, -9.025253655999961],
            [98.69969959200003, -9.033588655999836],
            [98.712419592000145, -9.04197965599991],
            [98.725101592000016, -9.050427656],
            [98.737745592000152, -9.058932655999911],
            [98.750351592000101, -9.067493656999929],
            [98.762919592000031, -9.076110656999873],
            [98.775447592, -9.084783656999832],
            [98.787937592000134, -9.093513656999889],
            [98.800387592000135, -9.10229765699988],
            [98.812798592000121, -9.111138656999884],
            [98.825169592000151, -9.120033657],
            [98.837500592000168, -9.128984656999876],
            [98.849791592000173, -9.137990656999946],
            [98.862041592000168, -9.147050656999951],
            [98.874251592000206, -9.156165656999974],
            [98.886419592000067, -9.165335656999844],
            [98.898547592000142, -9.17455865699992],
            [98.910632592000155, -9.183836657000029],
            [98.922676592000158, -9.193168656999887],
            [98.934678592000211, -9.202553656999868],
            [98.946638592000085, -9.211992656999882],
            [98.958555592000067, -9.221484657000019],
            [98.970429592000102, -9.231029657],
            [98.982261592000128, -9.240627656999848],
            [98.994049592000096, -9.250278657],
            [99.005794592000115, -9.259981657],
            [99.017495592000131, -9.269737656999951],
            [99.029152592000088, -9.279544657000031],
            [99.040765592000213, -9.289404656999878],
            [99.052334592000165, -9.299316656999949],
            [99.063858592000116, -9.309279656999976],
            [99.075337592000068, -9.319293656999861],
            [99.086771592000133, -9.329358656999887],
            [99.098160592000085, -9.339475656999866],
            [99.109638592000152, -9.349756657],
            [99.121070592000109, -9.360089656999904],
            [99.13245459200013, -9.370474656999946],
            [99.143791592000156, -9.380910656999845],
            [99.155081592000016, -9.39139765699997],
            [99.166322592000171, -9.40193465699997],
            [99.17751659200016, -9.412523657],
            [99.188661592000045, -9.423162656999921],
            [99.199758592000165, -9.433851656999977],
            [99.210806592000182, -9.444590657],
            [99.221805592000038, -9.455379656999966],
            [99.232755592000018, -9.466217657],
            [99.243656592000121, -9.47710565699991],
            [99.254507592000067, -9.488042656999966],
            [99.265308592000139, -9.499028656999897],
            [99.276059592000166, -9.510062657],
            [99.286759592000152, -9.521145656999863],
            [99.297410592000091, -9.532276656999898],
            [99.308009592000161, -9.543455657],
            [99.31855759200019, -9.55468165699989],
            [99.329054593000166, -9.565956656999845],
            [99.339500593000167, -9.577277656999968],
            [99.349894593000073, -9.58864665699997],
            [99.36023659300011, -9.60006165699987],
            [99.370527593000162, -9.611523656999935],
            [99.380764593000123, -9.623031656999984],
            [99.390950593000213, -9.634585656999931],
            [99.401082593000211, -9.646185656999862],
            [99.411162593000228, -9.657831656999875],
            [99.421189593000037, -9.669522656999874],
            [99.431162593000096, -9.681258656999873],
            [99.441081593000121, -9.693039656999858],
            [99.450947593000109, -9.704865656999928],
            [99.460759593000063, -9.716735657],
            [99.470516593000099, -9.728650656999974],
            [99.480220593000098, -9.740608656999953],
            [99.489868593000068, -9.752610656999934],
            [99.499462593000118, -9.764655656999821],
            [99.509001593000136, -9.776744656999895],
            [99.518484593000068, -9.788875656999977],
            [99.527912593000138, -9.801049656999979],
            [99.537285593000121, -9.813266656999886],
            [99.546602593000074, -9.825525656999901],
            [99.555862593000114, -9.837825656999939],
            [99.565067593000123, -9.850168656999884],
            [99.574215593000218, -9.862551656999855],
            [99.583307593000114, -9.874976656999834],
            [99.5923415930001, -9.887442656999838],
            [99.601319593000227, -9.899948657000039],
            [99.610240593000213, -9.912495657],
            [99.619103593000062, -9.925082656999876],
            [99.627909593000112, -9.937709656999871],
            [99.636657593000137, -9.950375657],
            [99.645347593000082, -9.963081656999861],
            [99.653978593000119, -9.975826656999857],
            [99.662552593000072, -9.988609656999884],
            [99.671067593000117, -10.001432656999938],
            [99.679524593000139, -10.014292656999942],
            [99.687921593000084, -10.027191656999975],
            [99.696260593000119, -10.040127656999957],
            [99.704540593000075, -10.053101656999885],
            [99.712760593000127, -10.066112656999863],
            [99.720946593000093, -10.079199656999904],
            [99.729072593000097, -10.092323657],
            [99.737139593000194, -10.105484657000034],
            [99.745144593000219, -10.118681656999854],
            [99.753089593000055, -10.131915656999894],
            [99.7609745930001, -10.145184658],
            [99.768797593000073, -10.158488657999868],
            [99.776559593000144, -10.171828657999882],
            [99.784260593000141, -10.185203657999864],
            [99.791899593000181, -10.1986136579999],
            [99.799477593000148, -10.212057657999821],
            [99.806993593000101, -10.225535657999885],
            [99.814448593000094, -10.239047657999834],
            [99.821840593000189, -10.252593658000023],
            [99.829169593000159, -10.266172657999917],
            [99.836437593000056, -10.279784657999883],
            [99.843642593000055, -10.293429657999908],
            [99.850784593000157, -10.307106657999924],
            [99.857863593000133, -10.320816657999913],
            [99.864879593000211, -10.33455765799998],
            [99.87183259300005, -10.348330657999853],
            [99.878721593000108, -10.362134657999889],
            [99.885547593000098, -10.375969657999917],
            [99.89231059300019, -10.389835657999939],
            [99.899008593000161, -10.403732657999853],
            [99.905643593000178, -10.417658657999866],
            [99.912213593000132, -10.431615657999956],
            [99.918720593000018, -10.445601657999859],
            [99.925161593000126, -10.45961665799986],
            [99.931498593000157, -10.473562657999835],
            [99.937770593000067, -10.487536657999911],
            [99.943978593000082, -10.501539657999984],
            [99.9501235930002, -10.51556965799989],
            [99.956203593000197, -10.529627657999896],
            [99.962219593000071, -10.543712657999919],
            [99.968170593000224, -10.557823657999961],
            [99.97405759300014, -10.571962658],
            [99.97987959300022, -10.586126657999898],
            [99.985637593000121, -10.600317657999895],
            [99.991329593000074, -10.614533657999914],
            [99.996957593000076, -10.628775657999867],
            [100.002519593000017, -10.643042657999942],
            [100.008016593, -10.657334657999854],
            [100.013447593000109, -10.671650657999891],
            [100.01881359300009, -10.685991657999864],
            [100.024113593000124, -10.700355657999964],
            [100.02934759300004, -10.714743657999918],
            [100.034515593000066, -10.729155658],
            [100.039618593000142, -10.743589657999934],
            [100.044654593000161, -10.758047658],
            [100.049624593, -10.772527657999831],
            [100.054527593000131, -10.787029657999895],
            [100.05936459300014, -10.80155265799992],
            [100.064135593000088, -10.816098657999888],
            [100.068839593000092, -10.83066165799984],
            [100.073477593000149, -10.845245657999939],
            [100.078048593000091, -10.859850658],
            [100.082496593000229, -10.874258657999917],
            [100.086879593000134, -10.888685657999901],
            [100.091197593000203, -10.903132657999848],
            [100.095449593000097, -10.917598657999861],
            [100.099637593000153, -10.932083657999939],
            [100.103758593000151, -10.946586657999902],
            [100.107814593000199, -10.961107657999847],
            [100.111805593000014, -10.97564665799986],
            [100.115729593000225, -10.990202657999859],
            [100.119588593000145, -11.004775658000028],
            [100.123381593, -11.019366657999896],
            [100.127108593000088, -11.033973658000036],
            [100.130768593000226, -11.04859665799998],
            [100.13573059300009, -11.062556657999963],
            [100.140630593000225, -11.07653865799989],
            [100.145468593000061, -11.090540657999881],
            [100.150244593000167, -11.104564657999916],
            [100.154957593000205, -11.118608658000014],
            [100.159608593000172, -11.13267265799989],
            [100.164197593000182, -11.146756657999916],
            [100.168723593000124, -11.160861657999902],
            [100.173187593000165, -11.174984657999957],
            [100.177587593000084, -11.18912765799989],
            [100.181925593000102, -11.203289657999974],
            [100.186200593000223, -11.217470658999943],
            [100.190511593000139, -11.231991658999888],
            [100.194756593000108, -11.246532658999982],
            [100.198935593000016, -11.261092658999956],
            [100.20304759300015, -11.275670659],
            [100.207093593000167, -11.29026665899984],
            [100.211073593000123, -11.304879658999937],
            [100.214987593000075, -11.319511658999829],
            [100.218833593000141, -11.33415965899998],
            [100.222613593000091, -11.348825658999843],
            [100.225881593000196, -11.361226658999882],
            [100.229101593000081, -11.373640658999932],
            [100.232273593000087, -11.386066658999908],
            [100.235397593000101, -11.398503659],
            [100.238473593000066, -11.410952658999918],
            [100.242069593000139, -11.425743659],
            [100.245597593000213, -11.440550658999896],
            [100.249058593000228, -11.455372658999877],
            [100.252451593000188, -11.470209659000034],
            [100.255775593000152, -11.485061658999925],
            [100.259032593000171, -11.499928658999892],
            [100.262221593000135, -11.514810658999863],
            [100.265341593000102, -11.529705658999845],
            [100.268394593000124, -11.544615658999916],
            [100.271378593000094, -11.559537659],
            [100.274293593000067, -11.574473658999906],
            [100.277140593000155, -11.589422659],
            [100.279919593000074, -11.604383658999851],
            [100.282629593000109, -11.619356658999891],
            [100.285270593000149, -11.634342658999856],
            [100.287843593000133, -11.64933965900002],
            [100.290347593, -11.664347658999844],
            [100.292782593000226, -11.679367658999865],
            [100.29514859300005, -11.694397659],
            [100.297445593000049, -11.709437658999903],
            [100.299673593000165, -11.724488659],
            [100.301832593000114, -11.739548658999865],
            [100.303922593000124, -11.754618658999945],
            [100.30594359300008, -11.769696658999877],
            [100.307894593000043, -11.784784658999925],
            [100.309777593000064, -11.799881658999837],
            [100.31158959300015, -11.814985658999973],
            [100.313333593000124, -11.830098658999958],
            [100.315007593000161, -11.845218659],
            [100.31661159300009, -11.860345658999989],
            [100.31814659300008, -11.875479658999936],
            [100.31961259300013, -11.890620659000021],
            [100.321008593000016, -11.905768658999975],
            [100.322334593000136, -11.920921658999987],
            [100.323590593000205, -11.93608065899987],
            [100.324777593000107, -11.951245659],
            [100.325894593000072, -11.966415659],
            [100.3269415930001, -11.981589658999965],
            [100.327918593000192, -11.996769658999895],
            [100.328826593000116, -12.011952658999903],
            [100.32966459300016, -12.027139658999886],
            [100.330442593000129, -12.042380658999988],
            [100.331149593000106, -12.057624658999984],
            [100.331787593000143, -12.072871658999958],
            [100.332353593000079, -12.088120658999912],
            [100.332850593000074, -12.103372658999859],
            [100.333275593000138, -12.118626658999972],
            [100.333631593000206, -12.13388265899998],
            [100.333915593000171, -12.149139658999886],
            [100.334130593000197, -12.164397658999974],
            [100.33427359300012, -12.179656658999875],
            [100.33434659300022, -12.194916658999858],
            [100.334349593000212, -12.210176658999842],
            [100.334280593000159, -12.225435659000013],
            [100.334141593000055, -12.240694658999914],
            [100.333932593000071, -12.2559536589999],
            [100.333652593000096, -12.271210659],
            [100.333301593000073, -12.28646566],
            [100.332879593000172, -12.301719659999847],
            [100.33238759300005, -12.316971659999879],
            [100.331824593000164, -12.332221660000016],
            [100.331191593000113, -12.34746866],
            [100.330487593000129, -12.362711659999988],
            [100.32971259300021, -12.377952659999906],
            [100.328866593000129, -12.393189659999848],
            [100.327950593000111, -12.408422659999914],
            [100.326963593000158, -12.423650659999907],
            [100.325906593000099, -12.438874659999939],
            [100.324778593000104, -12.454093659999899],
            [100.323579593000119, -12.469307659999899],
            [100.322310593000083, -12.484515659999843],
            [100.320970593000226, -12.499718659999814],
            [100.319559593000037, -12.514914659999832],
            [100.318078593000138, -12.530104659999978],
            [100.316526593000077, -12.545287659999985],
            [100.31490459300008, -12.560463660000039],
            [100.313212593000145, -12.575631659999956],
            [100.311449593000106, -12.59079266],
            [100.309615593000075, -12.605944659999935],
            [100.307711593000164, -12.62108966],
            [100.305736593000091, -12.636224659999939],
            [100.303692593000193, -12.651351659999847],
            [100.301577593000076, -12.66646866],
            [100.299391593000024, -12.681575659999922],
            [100.297135593000149, -12.696673659999917],
            [100.294809593000167, -12.711760659999882],
            [100.292413593000077, -12.726837659999916],
            [100.289947593000221, -12.741903659999835],
            [100.287410593000089, -12.756957659999912],
            [100.284832593000118, -12.771854659999974],
            [100.282184593000153, -12.786739659999924],
            [100.279468593000132, -12.801612659999861],
            [100.276684593000169, -12.816472659999974],
            [100.27383159300021, -12.831321659999873],
            [100.270909593000198, -12.846156659999863],
            [100.267919593000073, -12.860978659999844],
            [100.264861593, -12.87578766],
            [100.26173459300017, -12.890582659999964],
            [100.258539593000052, -12.905364659999918],
            [100.255275593000164, -12.920130659999884],
            [100.251943593000163, -12.934883660000025],
            [100.248544593000048, -12.949620659999894],
            [100.245076593000164, -12.964342659999957],
            [100.241540593000167, -12.979048659999933],
            [100.237936593000228, -12.993739659999903],
            [100.234264593000063, -13.008413659999988],
            [100.230524593000126, -13.0230716599999],
            [100.22671659300002, -13.037712659999912],
            [100.222841593000027, -13.052336660000037],
            [100.218898593000205, -13.06694366],
            [100.214888593000211, -13.081532659999965],
            [100.210810593000048, -13.096104659999952],
            [100.206664593000113, -13.11065665999989],
            [100.202685593000211, -13.12440065999985],
            [100.198646593000177, -13.138126660000012],
            [100.194546593000126, -13.15183566],
            [100.19038659300017, -13.165527659999924],
            [100.186167593000079, -13.179201659999862],
            [100.181887593000141, -13.192857659999916],
            [100.177548593000125, -13.206496659999985],
            [100.173149593000147, -13.220116659999988],
            [100.16869059300015, -13.233717659999925],
            [100.164172593000075, -13.247300659999979],
            [100.159594593000094, -13.26086366],
            [100.154956593000207, -13.27440766],
            [100.149997593000165, -13.288707659999858],
            [100.144971593000065, -13.302985659999948],
            [100.139879593000188, -13.31724166],
            [100.13472159300008, -13.331474659999913],
            [100.129496593000141, -13.345684659999975],
            [100.124206593000139, -13.359870661],
            [100.11885059300019, -13.374033660999899],
            [100.113428593, -13.388172660999857],
            [100.107940593000222, -13.40228766099996],
            [100.102387593000145, -13.416377660999856],
            [100.096769593, -13.430443661],
            [100.091085593000088, -13.444483660999936],
            [100.085336593000108, -13.458499661000019],
            [100.079522593, -13.472488661],
            [100.07364259300013, -13.486452660999859],
            [100.067698593000188, -13.500389660999986],
            [100.061689593000125, -13.514300661],
            [100.055616593000167, -13.528184660999898],
            [100.049477593000091, -13.542041660999885],
            [100.043275593000118, -13.555870661000043],
            [100.037008593000024, -13.569672660999913],
            [100.030676593000209, -13.583446660999954],
            [100.024281593000097, -13.597192661],
            [100.017821593000093, -13.610909660999852],
            [100.011298593000134, -13.624597660999882],
            [100.00471159300011, -13.638257660999912],
            [99.998060593000019, -13.651887660999947],
            [99.991346593000145, -13.665487660999887],
            [99.984569593000202, -13.679058660999928],
            [99.978073593000062, -13.691927660999966],
            [99.971520593000122, -13.704768660999903],
            [99.964910593000155, -13.717582660999923],
            [99.957967593000063, -13.731030661],
            [99.950961593000017, -13.744446660999898],
            [99.94389359300007, -13.757832661],
            [99.936763593000052, -13.77118666099993],
            [99.929571593000134, -13.784509660999959],
            [99.92231659300009, -13.797799660999928],
            [99.915000593000144, -13.811057660999907],
            [99.907622593000127, -13.824283661],
            [99.90018359300015, -13.83747666099984],
            [99.89268259300016, -13.850636660999882],
            [99.885119593000042, -13.863763660999865],
            [99.877496593000188, -13.876856660999962],
            [99.869811593000151, -13.889915661],
            [99.862066593000151, -13.902940660999889],
            [99.854260593000078, -13.915931660999988],
            [99.846394593000099, -13.928887660999933],
            [99.838467593000047, -13.941808660999925],
            [99.830479593000206, -13.954694660999948],
            [99.822432593000173, -13.96754466099992],
            [99.81432559300012, -13.980359661000023],
            [99.806157593000222, -13.993137661],
            [99.797931593000129, -14.005880660999907],
            [99.789644593000133, -14.018586660999958],
            [99.781299593000227, -14.031255660999875],
            [99.772894593000132, -14.043887660999843],
            [99.764431593000126, -14.056481660999864],
            [99.755963593000189, -14.068964660999853],
            [99.747438593000226, -14.081410660999978],
            [99.738855593000068, -14.093817660999889],
            [99.73021459300017, -14.106187660999936],
            [99.721516593000189, -14.118519660999866],
            [99.712760593000127, -14.130813660999934],
            [99.703948593000206, -14.143067660999975],
            [99.695078593000204, -14.155283660999984],
            [99.686152593, -14.167460660999964],
            [99.677169593000059, -14.179598661000014],
            [99.668130593000086, -14.19169566099994],
            [99.659034593000143, -14.203753660999936],
            [99.649882593000171, -14.215771661],
            [99.640675593000168, -14.227748661000035],
            [99.631411593000138, -14.239685660999967],
            [99.62209259300019, -14.25158166099996],
            [99.612718593000153, -14.263436660999943],
            [99.603289593000085, -14.275250661],
            [99.593804593000158, -14.287022661000037],
            [99.58426559300014, -14.29875266099998],
            [99.574671593000204, -14.310440661],
            [99.56502359300012, -14.322086661000014],
            [99.555025593000124, -14.334058661],
            [99.544969593000047, -14.345985660999986],
            [99.534856593000228, -14.357866660999989],
            [99.524686593000212, -14.369701661],
            [99.514460593000109, -14.381489660999947],
            [99.504176593000153, -14.393232661],
            [99.493836593000225, -14.404927660999974],
            [99.483440593000154, -14.416576660999965],
            [99.472988593000167, -14.428177660999978],
            [99.462480593000095, -14.439731661999929],
            [99.451916593000163, -14.451236662],
            [99.441297593000201, -14.46269466199999],
            [99.430623593000149, -14.474104662000016],
            [99.419894593, -14.485465661999982],
            [99.409110593000179, -14.496777661999971],
            [99.398272593000144, -14.508040661999914],
            [99.387530593000207, -14.519108661999979],
            [99.376735593000177, -14.530128661999967],
            [99.365888593000108, -14.541099662],
            [99.354989593000113, -14.552022661999956],
            [99.344037593000138, -14.56289566199996],
            [99.333034593000122, -14.573720661999985],
            [99.321980593000177, -14.584495661999952],
            [99.310874592000147, -14.595221661999958],
            [99.299718592000119, -14.605897662],
            [99.288510592000108, -14.616522662],
            [99.277252592000224, -14.627098662000037],
            [99.265944592000011, -14.63762366200001],
            [99.254586592000038, -14.648097661999955],
            [99.243177592000194, -14.658521661999927],
            [99.231719592000019, -14.668893661999959],
            [99.220212592000138, -14.679214661999936],
            [99.208656592000096, -14.689483661999958],
            [99.197050592000124, -14.699700661999955],
            [99.18539659200016, -14.709866661999982],
            [99.173694592000146, -14.719979661999986],
            [99.161943592000142, -14.730039661999953],
            [99.150145592000086, -14.740047661999967],
            [99.138299592000209, -14.750002661999957],
            [99.126405592000111, -14.759904662],
            [99.114464592000132, -14.769753662],
            [99.103076592000065, -14.779076662],
            [99.091647592000157, -14.788350661999957],
            [99.080175592000131, -14.797575661999929],
            [99.068661592000041, -14.80675166199994],
            [99.057105592000113, -14.815879661999986],
            [99.045509592000059, -14.82495766199996],
            [99.033474592000204, -14.834294661999976],
            [99.021395592000061, -14.843578662000041],
            [99.009273592000028, -14.852809661999984],
            [98.997108592000046, -14.861987661999976],
            [98.984899592000176, -14.871110662000049],
            [98.972648592000127, -14.880181661999984],
            [98.96035459200013, -14.889197661999987],
            [98.948018592000068, -14.898159661999969],
            [98.935639592000058, -14.90706666200002],
            [98.923219592000152, -14.915919661999951],
            [98.910758592000121, -14.924717662000035],
            [98.89825559200014, -14.933461662000013],
            [98.885711592000092, -14.942149661999963],
            [98.873126592000204, -14.950782662],
            [98.860500592000022, -14.959359662],
            [98.847835592000166, -14.967881661999982],
            [98.835129592000186, -14.976347661999966],
            [98.822383592000136, -14.984757661999922],
            [98.809598592000185, -14.993111661999947],
            [98.796774592000162, -15.001409661999972],
            [98.783911592000067, -15.009650661999984],
            [98.77100959200007, -15.017834661999984],
            [98.758068592000114, -15.025962661999969],
            [98.745089592000028, -15.034032661999944],
            [98.731717592000024, -15.042270661999979],
            [98.71830459200001, -15.05044766200001],
            [98.704852592000151, -15.058563661999983],
            [98.691361592000163, -15.066618661999968],
            [98.677831592000217, -15.074613661999962],
            [98.664262592000085, -15.082546661999984],
            [98.650655592000106, -15.090417661999922],
            [98.637010592000166, -15.098227661999985],
            [98.623327592000095, -15.105975661999963],
            [98.60960759200006, -15.11366166199997],
            [98.595850592000119, -15.121286661999989],
            [98.582055592000103, -15.128847661999956],
            [98.568224592000121, -15.136347661999949],
            [98.554357592000116, -15.143783661999962],
            [98.540454592000145, -15.151157662],
            [98.526515592000038, -15.158468661999976],
            [98.512541592000076, -15.16571666199998],
            [98.498532592000203, -15.172901662000015],
            [98.48448859200019, -15.180022661999985],
            [98.470409592000152, -15.187080661999985],
            [98.456297592000197, -15.194074661999935],
            [98.442150592000218, -15.201004662000017],
            [98.42797059200015, -15.207870661999934],
            [98.41375759200011, -15.214672661999984],
            [98.399511592000096, -15.221409661999985],
            [98.385232592000165, -15.22808266200002],
            [98.370921592000201, -15.23469066199992],
            [98.356578592000147, -15.24123466199994],
            [98.342203592000118, -15.247712662000012],
            [98.327797592000167, -15.254126662000019],
            [98.315619592000218, -15.259522662],
            [98.303419592000097, -15.264872661999988],
            [98.291197592000032, -15.270175661999914],
            [98.276867592000173, -15.27634666199998],
            [98.262507592000048, -15.282452662],
            [98.248119592000108, -15.288494661999962],
            [98.233701591000141, -15.294471662000049],
            [98.219254591000123, -15.300384662],
            [98.204780591000059, -15.306232661999985],
            [98.190277591000182, -15.312016662],
            [98.175747591000032, -15.317734661999978],
            [98.161189591000124, -15.323388661999985],
            [98.146604591000113, -15.32897666199996],
            [98.131992591000113, -15.334499661999956],
            [98.117354591000066, -15.339957662],
            [98.102690591000197, -15.345349661999919],
            [98.088150591000186, -15.35062666200001],
            [98.073584591000071, -15.355838661999954],
            [98.058994591000015, -15.360986662000016],
            [98.044380591000191, -15.366069662000029],
            [98.029741591000089, -15.371088662],
            [98.015079591000102, -15.376041662],
            [98.000393591000176, -15.380930661999955],
            [97.985684591000137, -15.385753661999956],
            [97.970952591000156, -15.390512661999978],
            [97.956198591000117, -15.395205661999967],
            [97.941421591000022, -15.399832662],
            [97.926622591000097, -15.404395661999985],
            [97.911802591000168, -15.408891662000016],
            [97.896960591000067, -15.413322661999914],
            [97.88209859100013, -15.417687661999935],
            [97.86678459100014, -15.422114661999942],
            [97.851449591000147, -15.42647166199994],
            [97.836093591000036, -15.430757662],
            [97.820716591000149, -15.434974661999945],
            [97.805318591000201, -15.439121661999977],
            [97.78990059100002, -15.443197661999974],
            [97.77446259100023, -15.447204661999962],
            [97.759004591000036, -15.451139661999946],
            [97.743528591000114, -15.455005661999976],
            [97.728032591000073, -15.458800661999916],
            [97.71251859100019, -15.462524662000021],
            [97.696986591000183, -15.466177661999936],
            [97.681436591000107, -15.469760661999928],
            [97.665869591000188, -15.473272661999985],
            [97.650285591000141, -15.476712661999954],
            [97.634683591000027, -15.480082661999987],
            [97.619066591000063, -15.483381662000014],
            [97.603432591000143, -15.486608662000023],
            [97.590097591000102, -15.489320661999983],
            [97.576750591000206, -15.491980661999975],
            [97.562475591000094, -15.494775661999952],
            [97.54818859100007, -15.49751266200002],
            [97.533888591000192, -15.500189662],
            [97.519577591000058, -15.502807662],
            [97.505254591000124, -15.505365663],
            [97.490919591000221, -15.507864663],
            [97.476574591000173, -15.510303663],
            [97.462217591000041, -15.512683663],
            [97.447850591000048, -15.515003662999945],
            [97.433473591000194, -15.517264662999963],
            [97.418334591000104, -15.519581663000011],
            [97.403184591000155, -15.521831663000015],
            [97.388024591000118, -15.524016662999969],
            [97.372853591000109, -15.526134662999979],
            [97.357673591, -15.528186662999943],
            [97.342483591000047, -15.530171663000047],
            [97.327284591000222, -15.53209066300002],
            [97.312076591000192, -15.533943662999945],
            [97.296860591000183, -15.535729662999927],
            [97.281635591000082, -15.537449662999947],
            [97.266402591000116, -15.539103662999935],
            [97.251161591000113, -15.540689662999981],
            [97.235914591000125, -15.542210662999977],
            [97.220659591000214, -15.543663663000018],
            [97.20539759100015, -15.545050662999941],
            [97.190129591000215, -15.546370663],
            [97.174855591000124, -15.547624663000022],
            [97.159576590000171, -15.548810662999912],
            [97.144290590000168, -15.549931662999938],
            [97.129000590000118, -15.550984662999937],
            [97.11370559000008, -15.551970663],
            [97.098129590000127, -15.552912663000029],
            [97.08254959000007, -15.553784662999973],
            [97.066965590000081, -15.554586662999981],
            [97.051377590000158, -15.555320662999961],
            [97.035786590000129, -15.555984663000018],
            [97.020192590000221, -15.556579663000036],
            [97.00459559000015, -15.557104662999961],
            [96.988996590000198, -15.557560662999947],
            [96.973395590000138, -15.557947663],
            [96.957792590000196, -15.558264662999932],
            [96.942187590000088, -15.558512662999931],
            [96.926582590000095, -15.558691663],
            [96.910976590000161, -15.558800662999957],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CHE", Country: "Switzerland" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.566727209355236, 47.540454046234075],
            [9.559800236913645, 47.502091081121293],
            [9.596600144717968, 47.469436024448882],
            [9.649654071567397, 47.458609120086848],
            [9.670345134889914, 47.390691061380707],
            [9.637363183992647, 47.369582076364665],
            [9.610963206401436, 47.362227123945743],
            [9.540000169901191, 47.28416411252185],
            [9.533573261804463, 47.274545040410842],
            [9.496809228545885, 47.223327085638104],
            [9.485000132767027, 47.196244988764491],
            [9.487082197521573, 47.172636017300377],
            [9.502082283848239, 47.146809194249883],
            [9.51173605703849, 47.105973232417242],
            [9.474636077099746, 47.057454082836429],
            [9.485282267628719, 47.061100210727957],
            [9.506391252644676, 47.064718175424773],
            [9.541391230556115, 47.070000115544545],
            [9.598636109004985, 47.063836063932129],
            [9.63250017139481, 47.056664172279042],
            [9.719445150362361, 47.050282191183427],
            [9.872918133209708, 47.023045029100629],
            [9.891600221973448, 47.000336106402059],
            [9.875836041351221, 46.968182119902835],
            [9.877782151635955, 46.940409186567578],
            [9.935000207994591, 46.912773213530656],
            [10.05889127515826, 46.866391113989366],
            [10.109445047921611, 46.850273217052248],
            [10.131527171898966, 46.85040917152206],
            [10.141809084914911, 46.851345094833263],
            [10.160836172814385, 46.85500010754221],
            [10.227500131163993, 46.876936051127771],
            [10.313327131882915, 46.933609116140403],
            [10.323327077675316, 46.955554112181474],
            [10.350627104032014, 46.991245094192536],
            [10.390763344585594, 47.002564183910337],
            [10.430000206925939, 46.984164230008233],
            [10.488209171880726, 46.935991085391478],
            [10.471236153179206, 46.871354038873406],
            [10.447500112062585, 46.763053982211929],
            [10.470836168758581, 46.635827085213137],
            [10.486527091546861, 46.617354041115092],
            [10.485691248159839, 46.592636143857831],
            [10.47187317782226, 46.555764151686148],
            [10.457527047583312, 46.542500125149999],
            [10.449718131287142, 46.539164127676898],
            [10.337218070570628, 46.549435982408923],
            [10.303609153314198, 46.554864270558667],
            [10.246318174397715, 46.590409072177962],
            [10.253609089076207, 46.61596415392664],
            [10.241800160935526, 46.634445077013808],
            [10.181109146805483, 46.632218172971832],
            [10.119163194128475, 46.611382102723994],
            [10.051391148175952, 46.541518101371267],
            [10.044445064995131, 46.502636128812284],
            [10.04968224575174, 46.445591074935791],
            [10.090691210066325, 46.4237449853524],
            [10.153891263100036, 46.390273196032169],
            [10.173954018958739, 46.263118048124866],
            [10.136391190325327, 46.230691146857524],
            [10.071773254546628, 46.221800126858355],
            [9.995000218025012, 46.291664128211167],
            [9.982218150922847, 46.333045081389727],
            [9.990418166822366, 46.355200127924959],
            [9.946945090605283, 46.379582078502054],
            [9.908054065590989, 46.38319115838145],
            [9.852782287155094, 46.369718087179635],
            [9.813054077649298, 46.358327080732309],
            [9.770554140393614, 46.342209183795106],
            [9.708818070572676, 46.300273180986736],
            [9.624718077188874, 46.29193603715099],
            [9.581945057527065, 46.296945062502644],
            [9.544863182499142, 46.30624511937792],
            [9.521391171333221, 46.319718022941643],
            [9.457636068669899, 46.38624502099286],
            [9.450282289717364, 46.426664234598476],
            [9.455000127751902, 46.472500169327262],
            [9.366800210237415, 46.508054023402067],
            [9.293782266348813, 46.500827146463919],
            [9.250627199539139, 46.44242707411631],
            [9.273127077572042, 46.425764185832989],
            [9.297218176108203, 46.350964249324463],
            [9.292636124905471, 46.324853950307713],
            [9.276663234893476, 46.292500139236196],
            [9.241009133256625, 46.233627159910029],
            [9.176454061751883, 46.178600133047539],
            [9.144436197360648, 46.168044969987236],
            [9.132218064705597, 46.160273101703282],
            [9.083336146354412, 46.1211091619207],
            [9.035827183747159, 46.057218104787452],
            [9.016663303187471, 46.022500094099698],
            [8.997363300519879, 45.975136138417739],
            [9.022982252371008, 45.938953976879205],
            [9.073054065700404, 45.920000146813678],
            [9.084100240650059, 45.90229119701128],
            [9.036663194772188, 45.83777317351867],
            [8.998336104205123, 45.829436029682924],
            [8.951109108821385, 45.845273132862914],
            [8.936318067160585, 45.87187310266448],
            [8.926663288142009, 45.900836098615898],
            [8.897782267203752, 45.95277322068317],
            [8.819863256876886, 45.986382137939685],
            [8.820282184398707, 46.03388221572952],
            [8.851454147119199, 46.056736145353582],
            [8.844163232440621, 46.080753986055626],
            [8.822218068761572, 46.098609116249818],
            [8.74721814004269, 46.1211091619207],
            [8.728854060686217, 46.108591124769404],
            [8.719300199782026, 46.104018126022069],
            [8.696527071705049, 46.101109102697976],
            [8.615836165990203, 46.121245116390597],
            [8.460554033156484, 46.233327087775407],
            [8.445000238304488, 46.247218080670834],
            [8.434582203180668, 46.293191143336017],
            [8.448127191111837, 46.322982103685362],
            [8.465973268850718, 46.372635995321147],
            [8.460836168018176, 46.448327095777941],
            [8.441454022699162, 46.462082134203271],
            [8.365273251457666, 46.453609035897713],
            [8.357218074845434, 46.450000123656295],
            [8.303745052836007, 46.422909141965462],
            [8.141945152074896, 46.222909052651502],
            [8.158745168294388, 46.183818035426853],
            [8.14958223935551, 46.151245121405807],
            [8.009027110442247, 46.009300105304092],
            [7.973054161207443, 45.996936127533516],
            [7.950000239373139, 45.994164232145621],
            [7.906809130379742, 45.992636043554384],
            [7.879300226995383, 45.975136138417739],
            [7.866391257878803, 45.948327123950335],
            [7.855745067349829, 45.919053997580576],
            [7.783891202177585, 45.923609059055096],
            [7.743336034102072, 45.932218111830551],
            [7.709163182398413, 45.943327151054277],
            [7.650282156445144, 45.980554033007522],
            [7.536663279289769, 45.981664132267056],
            [7.458054103053399, 45.94110913182972],
            [7.429718073461231, 45.929445042976269],
            [7.386109210412485, 45.915409043155549],
            [7.338891267484257, 45.9205540229771],
            [7.296809084284007, 45.92319113736157],
            [7.230282253870769, 45.8980450924888],
            [7.191391228856475, 45.880691200105886],
            [7.103263228071341, 45.883536018051629],
            [7.070282283002598, 45.903053950202477],
            [7.038054032840932, 45.931936144607036],
            [7.030554073496717, 45.96193598198407],
            [7.007218184438869, 45.998054105782145],
            [6.933054099106926, 46.055273167969276],
            [6.873891105930028, 46.088053953189601],
            [6.783473169190984, 46.154718079177215],
            [6.800000103004379, 46.378327139955118],
            [6.807018102914668, 46.404227053201552],
            [6.798336127581337, 46.428609171416738],
            [6.737782241387578, 46.447491084752912],
            [6.702773211020713, 46.454718129328924],
            [6.633609098585026, 46.464164198958017],
            [6.515136093649119, 46.458464169506541],
            [6.49194504387853, 46.453045101450485],
            [6.319718223176068, 46.404164188927595],
            [6.295554034444052, 46.394164243135279],
            [6.244863301382395, 46.356382143914047],
            [6.230000175354149, 46.330200095806092],
            [6.243745155495759, 46.315509134069359],
            [6.271945062979938, 46.261936032135779],
            [6.245554137844124, 46.218327001448856],
            [6.187500238098551, 46.172082197482027],
            [6.133263289431454, 46.149782144021501],
            [5.996391168783845, 46.146945037426789],
            [5.967009077672515, 46.207291220059162],
            [5.981391250095186, 46.221936081328167],
            [6.009163177602034, 46.233891189860728],
            [6.030000253678367, 46.241391149205057],
            [6.074582255688625, 46.248400096659921],
            [6.115554004352987, 46.261527162897764],
            [6.106109108190481, 46.297491059676943],
            [6.117636069107647, 46.308745105825906],
            [6.153545148239886, 46.379264236732794],
            [6.111109081086653, 46.409718037987076],
            [6.071109130279126, 46.42638209973687],
            [6.086945059992615, 46.449436021571174],
            [6.135282154635973, 46.539718171478214],
            [6.128682244057302, 46.588054092655099],
            [6.26916327694633, 46.682782171657166],
            [6.358745035022082, 46.716727035593848],
            [6.435000237564168, 46.758045124498338],
            [6.456873149237708, 46.781454103752125],
            [6.446945120174746, 46.83943608676843],
            [6.46028223690675, 46.895827016919256],
            [6.528336082444639, 46.971664130129753],
            [6.566391264072081, 46.980000100499112],
            [6.632500172791879, 47.000000159721807],
            [6.678336107520607, 47.034164126608161],
            [6.699445092536536, 47.06389121685514],
            [6.793336154685079, 47.130827084144485],
            [6.849718200018685, 47.164991218668817],
            [6.838954159930722, 47.171518039051833],
            [6.971663324535768, 47.29193614844398],
            [7.000836035705191, 47.365000192800196],
            [6.980836144120474, 47.361664195326895],
            [6.931391129512292, 47.358891126472614],
            [6.88166330949025, 47.363673169885615],
            [6.99055412268919, 47.497218177877627],
            [7.025000224437207, 47.508045082239661],
            [7.146418127242953, 47.499053982316227],
            [7.182918130550831, 47.444018070636318],
            [7.247709236449538, 47.421664205357231],
            [7.275554086514262, 47.432491109719351],
            [7.308336045201145, 47.440273203925287],
            [7.340282160501005, 47.439018097740259],
            [7.355900160731522, 47.434018124844002],
            [7.358054142215451, 47.433609087967895],
            [7.385418206312693, 47.43332712074438],
            [7.415553998159709, 47.445273176821345],
            [7.433054070934446, 47.460545004449713],
            [7.452773168761922, 47.469991074078692],
            [7.496945128067779, 47.494991106197759],
            [7.502218183370132, 47.500545122895332],
            [7.502573240789587, 47.549927105591379],
            [7.521663192963018, 47.559718174356121],
            [7.546663225082085, 47.565553990639316],
            [7.554027062318426, 47.578882222553787],
            [7.577082157619202, 47.584309169598882],
            [7.588273171856002, 47.584482172080911],
            [7.640554119592537, 47.603882087034762],
            [7.675691225440204, 47.60500006528342],
            [7.66881806481706, 47.576036063503679],
            [7.621109109999452, 47.561100182555563],
            [7.65610908791092, 47.550554071950657],
            [7.697218132149658, 47.543327027374531],
            [7.769718074420467, 47.553327140805024],
            [7.917709124937716, 47.554227021932419],
            [8.033336138461493, 47.556109094476724],
            [8.101836068887707, 47.571073138619553],
            [8.195763340857951, 47.620344983107643],
            [8.240836187119584, 47.619436049524737],
            [8.289100191567002, 47.609091104596686],
            [8.390836044557375, 47.579991148347048],
            [8.435836135899223, 47.573609167251433],
            [8.471245150686684, 47.607354038977789],
            [8.523473292432868, 47.6327000760608],
            [8.56291819361067, 47.618400046289466],
            [8.569427076720672, 47.59853610917456],
            [8.57641808690272, 47.591373102338778],
            [8.593054153095693, 47.600553968550585],
            [8.624963220383336, 47.65620913523874],
            [8.610254153735895, 47.667736096155991],
            [8.580554053217213, 47.668609155193039],
            [8.540554102409601, 47.664300186396602],
            [8.522082231778029, 47.651664132048225],
            [8.485418110805625, 47.647082080845493],
            [8.419027067224221, 47.673882210495591],
            [8.406945056677245, 47.701800150756142],
            [8.477082140436266, 47.770618090589679],
            [8.56291819361067, 47.806664130020337],
            [8.611663319301641, 47.803882176348552],
            [8.661391139323769, 47.798045018960877],
            [8.685554154589312, 47.78666423843562],
            [8.736454099954614, 47.757073102658339],
            [8.732254095899862, 47.708291096593328],
            [8.720218185820414, 47.696627007739878],
            [8.753336091187577, 47.693609187312106],
            [8.800136112422194, 47.681727001337393],
            [8.774373159474152, 47.715973110875083],
            [8.805482257920488, 47.73603603437185],
            [8.835282270725372, 47.718045117345739],
            [8.855836038473512, 47.698882242614545],
            [8.883300182494906, 47.654145007757094],
            [8.898891192997098, 47.650827115194787],
            [8.925973289870598, 47.651800086518037],
            [8.947773278986375, 47.657491063514001],
            [8.979445138413553, 47.670136002679797],
            [9.000836090653195, 47.680827120209912],
            [9.033336082116392, 47.688327079554242],
            [9.08263608979928, 47.685418056230063],
            [9.26110021994819, 47.662845088001305],
            [9.478318079536905, 47.575854008566054],
            [9.566727209355236, 47.540454046234075],
          ],
          [
            [8.710254114573758, 47.696809062677218],
            [8.705482129444562, 47.711036002252897],
            [8.673954103476206, 47.702854091264342],
            [8.678591139963885, 47.693344989723315],
            [8.710254114573758, 47.696809062677218],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CHL", Country: "Chile (Peruvian point of view)" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-79.326877541999863, -29.645940861999975],
              [-79.994633209999961, -29.675322629999926],
              [-79.99640235499993, -29.675126967999802],
              [-80.000836496999909, -29.675310761999924],
              [-80.663795749999792, -29.60028398899982],
              [-80.665320061999978, -29.599864440999909],
              [-80.668916367999884, -29.599448245999838],
              [-80.801454970999828, -29.562887195999906],
              [-80.864361133999921, -29.555526304999887],
              [-81.499875717999714, -29.377755169999887],
              [-82.087483466999799, -29.104203779999864],
              [-82.107113880999975, -29.090703293999923],
              [-82.114837503999979, -29.087080362999927],
              [-82.115859624999757, -29.0863771409999],
              [-82.117872184999896, -29.085432973000025],
              [-82.638557519999722, -28.725085615000012],
              [-83.076391808999915, -28.289893202999849],
              [-83.418492681999737, -27.793924598999922],
              [-83.655501736999838, -27.252956148999885],
              [-83.658609742999715, -27.238981118999902],
              [-83.661869785999954, -27.231507863999923],
              [-83.788035454999829, -26.662453653999762],
              [-83.801276838999797, -26.082693901999846],
              [-83.801269692999824, -26.081923136999876],
              [-83.702957487999981, -25.509248225999841],
              [-83.497587565999709, -24.960593080999899],
              [-83.192572010999754, -24.452082743999881],
              [-83.185702440999989, -24.444233296999926],
              [-83.183415120999882, -24.440395927999873],
              [-82.788807395999868, -23.98676707899989],
              [-82.780912619999924, -23.98032549349989],
              [-82.308774174999769, -23.59452199899988],
              [-81.779995079999765, -23.291658551999831],
              [-81.766460907999942, -23.284345953999889],
              [-81.184812700999828, -23.06597348199989],
              [-80.56988817499996, -22.940800129999786],
              [-80.565497304999838, -22.940617143999816],
              [-80.564038922999714, -22.940316430999928],
              [-79.93500918299975, -22.912577990999978],
              [-79.309500525999908, -22.983130067999923],
              [-78.705091378999896, -23.150038760999905],
              [-78.702215662999834, -23.151360895000039],
              [-78.699234992000015, -23.152176167],
              [-78.55968489299994, -23.216225470999859],
              [-78.489611933999981, -23.235282206999841],
              [-77.922637728999803, -23.492976788999897],
              [-77.921156497999903, -23.493971148000014],
              [-77.919688569999835, -23.49463386799988],
              [-77.406886951999809, -23.83701500799981],
              [-77.406091985999922, -23.837601407999898],
              [-77.401373740999816, -23.842063651999922],
              [-77.400120077999929, -23.842895751000015],
              [-76.956950963999759, -24.259846992999826],
              [-76.596708899999953, -24.739648263999925],
              [-76.330852559999926, -25.268407218999926],
              [-76.168462928999872, -25.830625879999843],
              [-76.115907490999746, -26.409852752499887],
              [-76.115837429499777, -26.411418404499898],
              [-76.115812508499744, -26.41223434949984],
              [-76.115793626999903, -26.413239062499827],
              [-76.115715948999878, -26.414600882999821],
              [-76.176210431999834, -26.992868653999835],
              [-76.349686553999902, -27.552716622999924],
              [-76.632468077999874, -28.076715760999889],
              [-77.017191970999818, -28.548291608999875],
              [-77.492843885999832, -28.952302406999763],
              [-77.494255777999854, -28.953134795999802],
              [-77.495003608499871, -28.953726667499893],
              [-77.496879065999906, -28.955270347999829],
              [-78.04903420699992, -29.278552503999833],
              [-78.056468656, -29.281373955999868],
              [-78.660175288999767, -29.510486931999765],
              [-78.931913197999847, -29.56602981399999],
              [-79.310389706999899, -29.643389929999842],
              [-79.311152119999917, -29.643425492999881],
              [-79.315518318000045, -29.644305856999893],
              [-79.319741672999896, -29.64450263499981],
              [-79.326877541999863, -29.645940861999975],
            ],
          ],
          [
            [
              [-69.487226841231063, -17.632917935694707],
              [-69.376108956352141, -17.726945958141471],
              [-69.355563905783356, -17.745554956709441],
              [-69.327372883116595, -17.797499957765524],
              [-69.318690907783321, -17.82513593080256],
              [-69.321399938897059, -17.891946069543962],
              [-69.315290872569676, -17.926946047455317],
              [-69.30611788534685, -17.95569999874084],
              [-69.29125492695664, -17.979446098141352],
              [-69.133617814600086, -18.028890945111471],
              [-69.074935775028734, -18.037917919580536],
              [-69.071672867751488, -18.038890890903872],
              [-69.073199882876224, -18.068335846289187],
              [-69.082363985281688, -18.086946018323488],
              [-69.118681933651942, -18.108891014364588],
              [-69.139590926457629, -18.125146039238189],
              [-69.148899868150238, -18.150690895065054],
              [-69.135417912131004, -18.179591026742514],
              [-69.11444605505136, -18.200835798590276],
              [-69.090981922874562, -18.228126940129641],
              [-69.085554975829382, -18.285835840739651],
              [-69.074726898000961, -18.392226835128611],
              [-69.060281861304134, -18.414308959105881],
              [-69.039735804907025, -18.440972966648076],
              [-69.030146069457203, -18.459172928339854],
              [-69.023472901044499, -18.483472903903646],
              [-69.025554965799074, -18.507499964699207],
              [-69.032499875513565, -18.560000015385413],
              [-69.029172930495804, -18.611390972640265],
              [-69.024446040005955, -18.658608915568379],
              [-69.000563986135603, -18.74305508155436],
              [-68.951399932922698, -18.845835822597508],
              [-68.932781881899245, -18.882781910793426],
              [-68.965835917163986, -18.95305494902243],
              [-68.899873021197948, -19.064790915443211],
              [-68.782499889599706, -19.141945999112693],
              [-68.740846021653056, -19.177781988049105],
              [-68.658335907668004, -19.267363913763006],
              [-68.642235780365525, -19.282082032865958],
              [-68.608335843429984, -19.297226958479825],
              [-68.582645980677796, -19.303754952329314],
              [-68.551254915007746, -19.31930891481926],
              [-68.491672994308828, -19.369026844195616],
              [-68.44368190462967, -19.43364595344076],
              [-68.551390869477586, -19.539717932593987],
              [-68.572555007416668, -19.566535831878696],
              [-68.601963920618289, -19.607782004053831],
              [-68.687154902522423, -19.703682040761123],
              [-68.689726805699877, -19.742672978061591],
              [-68.626108830972839, -19.785835923860191],
              [-68.566955057889516, -19.833890883641857],
              [-68.523346027202592, -19.916390939343046],
              [-68.577781962251493, -20.053546033042835],
              [-68.610635837667814, -20.051735877227998],
              [-68.650554986928455, -20.056663933394702],
              [-68.777226834297323, -20.089163924857985],
              [-68.784655044550277, -20.114446091838659],
              [-68.766454915220436, -20.136464010437578],
              [-68.721117871370041, -20.237781941734397],
              [-68.703608913777998, -20.338054984978555],
              [-68.735008864265353, -20.352226939269087],
              [-68.754799878822325, -20.37354597477929],
              [-68.75222680217837, -20.424026824984765],
              [-68.739181878591978, -20.45028196328866],
              [-68.724726783611288, -20.470835898674807],
              [-68.69098191188499, -20.504999865561217],
              [-68.661955045831291, -20.523054987965793],
              [-68.629446001912413, -20.538608950455739],
              [-68.599726790654472, -20.54999995690298],
              [-68.570845937354363, -20.558890976902148],
              [-68.549454985114778, -20.567499862039654],
              [-68.49250883769588, -20.604726911630962],
              [-68.467017793687546, -20.630626824877311],
              [-68.4721639469754, -20.650690921840578],
              [-68.509172899445559, -20.671673004842205],
              [-68.528890991444712, -20.687646062492092],
              [-68.564999895149356, -20.733817943901158],
              [-68.565563997234506, -20.870282033501041],
              [-68.56000880707046, -20.89139101851714],
              [-68.557690875559899, -20.894454939412469],
              [-68.552572885466645, -20.904717909327189],
              [-68.5318177844037, -20.926390996428438],
              [-68.505699941674095, -20.937646048405895],
              [-68.478899979662287, -20.941673049978704],
              [-68.453608927864195, -20.939445978298806],
              [-68.424035896998021, -20.938890928668911],
              [-68.229808927331362, -21.233200001824741],
              [-68.188608855623727, -21.296946052032808],
              [-68.186117921631194, -21.609726828182147],
              [-68.170008909511353, -21.627781950586666],
              [-68.138764024233012, -21.66694605800717],
              [-68.121946070740705, -21.698064041270982],
              [-68.105146054521072, -21.745281984199153],
              [-68.090563889888131, -21.819999945694519],
              [-68.087508853810107, -21.842781958589001],
              [-68.086117793155211, -21.880281922948427],
              [-68.087508853810107, -21.91306404927343],
              [-68.085155047753858, -21.961673052856497],
              [-67.996945910145882, -22.04694600977399],
              [-67.97278188905176, -22.060281953039521],
              [-67.944454911915102, -22.104726827113382],
              [-67.92264603798202, -22.239173057337325],
              [-67.922917946921871, -22.273890900387016],
              [-67.932781938244261, -22.300281993160922],
              [-67.890563968212291, -22.426390911910971],
              [-67.849726832913177, -22.554864030277315],
              [-67.884172934661194, -22.712781936390968],
              [-67.876399892910683, -22.828055065961564],
              [-67.793335902762294, -22.878054962561578],
              [-67.579181964068709, -22.901108884395796],
              [-67.499999968567806, -22.885435898880417],
              [-67.335008909620797, -22.852782015674393],
              [-67.1836268763455, -22.821664032410638],
              [-67.104735900523622, -22.900555008232459],
              [-67.000835840127309, -23.002782040750276],
              [-67.047499906892028, -23.124172953827838],
              [-67.09360892402708, -23.241108885355118],
              [-67.138626785003623, -23.35833583656148],
              [-67.150845923486997, -23.391946094922503],
              [-67.190846041932673, -23.528617888083772],
              [-67.25091780105447, -23.733226850690528],
              [-67.335845926474292, -24.021664065379412],
              [-67.577326881252759, -24.121691015945601],
              [-67.639881863016285, -24.147590929191949],
              [-67.899173020987803, -24.253890896112239],
              [-68.18999991627868, -24.371390929724896],
              [-68.253617891005888, -24.398608981068321],
              [-68.277508829693488, -24.447226869468807],
              [-68.318972931351766, -24.496945972311607],
              [-68.36583581686051, -24.489926966572853],
              [-68.390699894509567, -24.503755095194208],
              [-68.463054997493089, -24.604726853888906],
              [-68.508054921196958, -24.670282054083373],
              [-68.540845932339266, -24.729446053088566],
              [-68.567426791401488, -24.781946103774715],
              [-68.570973007006842, -24.813335828340144],
              [-68.548335833399619, -24.869581919203938],
              [-68.500290931901986, -24.894999873016388],
              [-68.355626826402442, -25.121255100634244],
              [-68.420290862648699, -25.152781953136127],
              [-68.485899874661698, -25.162490879249447],
              [-68.496946049611381, -25.171108984480497],
              [-68.514872928897034, -25.198755015801225],
              [-68.530008802055363, -25.251946070587053],
              [-68.534708870455063, -25.269890887145607],
              [-68.539463924139795, -25.273890898990032],
              [-68.56000880707046, -25.315281910452484],
              [-68.572508906948997, -25.346108874037213],
              [-68.593064015801644, -25.423335874436077],
              [-68.597499886612638, -25.443335933658886],
              [-68.599726790654529, -25.473890988303879],
              [-68.596672928042949, -25.498055009397831],
              [-68.553346032217803, -25.617499979828665],
              [-68.501108838016023, -25.718335951691643],
              [-68.493346022187382, -25.742781940009195],
              [-68.454454997173002, -25.905554979731733],
              [-68.39986399691503, -26.159372922552507],
              [-68.419445966806165, -26.187782042340416],
              [-68.479735823049282, -26.238055021346725],
              [-68.519726889039475, -26.263335847222834],
              [-68.545290855605572, -26.281946019257077],
              [-68.564446018985848, -26.303955053038692],
              [-68.577226912621541, -26.350563966880429],
              [-68.581117792086559, -26.381945980095111],
              [-68.585281921595708, -26.43667293482298],
              [-68.585555004002003, -26.457226870209183],
              [-68.583617946172637, -26.505281997628856],
              [-68.578063929475093, -26.525417843683556],
              [-68.568208822970064, -26.54555503084265],
              [-68.541117841278947, -26.584446055856972],
              [-68.495564041411967, -26.633055059440096],
              [-68.450290867663796, -26.681390980616982],
              [-68.436399874768654, -26.69639089930547],
              [-68.424454992157791, -26.712499911425368],
              [-68.295835861037972, -26.891672982946773],
              [-68.287790910347667, -26.912781967962928],
              [-68.323335879604826, -27.02333591839448],
              [-68.353899819067351, -27.040281947367816],
              [-68.392226909634417, -27.058608978712073],
              [-68.411255003362214, -27.066526859749843],
              [-68.645846033711337, -27.138472925857286],
              [-68.694926770806376, -27.109654936831191],
              [-68.738890858912953, -27.103335820009534],
              [-68.782372987585404, -27.106108888863901],
              [-68.807435883978314, -27.11854595683036],
              [-68.847782007388048, -27.176108844686553],
              [-68.866954940403048, -27.209446020641337],
              [-68.878126843900759, -27.240072992015826],
              [-68.86951779112519, -27.278546095336594],
              [-68.903481933439252, -27.364172936207183],
              [-69.045355032811699, -27.601599899684459],
              [-69.073472965282519, -27.630726845662593],
              [-69.113063879213939, -27.733064016388369],
              [-69.120526790546137, -27.774264088096004],
              [-69.155290901701505, -27.902781965825454],
              [-69.164463888924104, -27.925555093902517],
              [-69.177781894916791, -27.951946019038303],
              [-69.193199902936897, -27.970417889669818],
              [-69.222090982158932, -27.976946051157199],
              [-69.25903589688852, -27.980146094160602],
              [-69.295981985084666, -27.996808982443781],
              [-69.326126829387022, -28.059173024452321],
              [-69.337781865785018, -28.103199976832741],
              [-69.350563932887155, -28.126108891741637],
              [-69.398899854064211, -28.180281970306282],
              [-69.531955023633088, -28.314026970508451],
              [-69.605281924473616, -28.368335835904929],
              [-69.655381900997611, -28.400935907292364],
              [-69.662226898426013, -28.541390956281532],
              [-69.669308936076732, -28.575626839897268],
              [-69.700981968970325, -28.590900008630115],
              [-69.745981892674081, -28.669864074647876],
              [-69.746399814367692, -28.71917296714804],
              [-69.739581974305565, -28.741526832427155],
              [-69.729446074043437, -28.764026878098036],
              [-69.723346060171309, -28.797708885550321],
              [-69.757508853591162, -28.886673064998661],
              [-69.788890866805787, -29.016946113257916],
              [-69.78694592998761, -29.07083588349434],
              [-69.792781913908811, -29.095699961143396],
              [-69.805981902704445, -29.113608903156148],
              [-69.838135889203727, -29.132708913613499],
              [-69.901599972188137, -29.138963992694698],
              [-69.960282011759375, -29.20111798420902],
              [-70.028972881940433, -29.291117831616589],
              [-70.034726890848447, -29.352081928152813],
              [-70.029026861397256, -29.374863941047408],
              [-70.003063916238716, -29.423891033961979],
              [-69.965008902249338, -29.535555083653264],
              [-69.959863922427871, -29.555699982163503],
              [-69.956954899103721, -29.600417938643659],
              [-69.963890924000623, -29.628608961310306],
              [-69.968617814490642, -29.655417975777539],
              [-69.962781998207447, -29.678199988672304],
              [-69.950008815922786, -29.694309000792032],
              [-69.917235909691328, -29.713335921053385],
              [-69.914454961847923, -29.720417958704218],
              [-69.905146020155485, -29.799582016932391],
              [-69.907508878667272, -29.91819097633811],
              [-69.918808857645615, -29.974064072509407],
              [-69.924945919529762, -29.999999860301614],
              [-69.863481926286738, -30.104026822712285],
              [-69.841672884715479, -30.120555097630131],
              [-69.829172952475005, -30.143964076883833],
              [-69.831681991378673, -30.190555053452897],
              [-69.898899825891533, -30.32499994257239],
              [-69.938472970188116, -30.372635807193944],
              [-69.998345910566172, -30.395146078786809],
              [-70.029726917952246, -30.397782019704778],
              [-70.051535791885499, -30.394999898394929],
              [-70.133899893116677, -30.439726907330567],
              [-70.211672890689954, -30.559445965996019],
              [-70.252499967705091, -30.637781892188116],
              [-70.269181966727672, -30.678335886797015],
              [-70.316399909655843, -30.824726831993473],
              [-70.32709085954798, -30.891255003511276],
              [-70.331681963206108, -30.931390908788728],
              [-70.327917818117498, -30.956873067979643],
              [-70.29139987753689, -30.995835842085398],
              [-70.295008957416258, -31.02972689420352],
              [-70.361955050627444, -31.093055022718033],
              [-70.430281978571713, -31.113608958104237],
              [-70.451381911132216, -31.094582037842883],
              [-70.520845928064432, -31.159172983893257],
              [-70.531254910732855, -31.181254940232634],
              [-70.56028194442473, -31.322426977221369],
              [-70.567508821362765, -31.469172979837367],
              [-70.579181962671726, -31.530281915660908],
              [-70.586735901472707, -31.563817910359489],
              [-70.458890922931971, -31.832499985037089],
              [-70.446672957915098, -31.84889096438053],
              [-70.430281978571713, -31.868617941197101],
              [-70.411881857031574, -31.878890969395741],
              [-70.373335831152758, -31.886108961516356],
              [-70.339935790924017, -31.884373069363903],
              [-70.304035764247203, -31.882363927167148],
              [-70.28444591536703, -31.891946118904002],
              [-70.237508933834022, -31.938473057732438],
              [-70.232373006468038, -31.965208982004015],
              [-70.264926809749795, -32.042435814764758],
              [-70.306955013493337, -32.04778196026308],
              [-70.3488908486637, -32.086946067683641],
              [-70.316954959285681, -32.228472994453853],
              [-70.320981960858546, -32.2652819547136],
              [-70.313272956848493, -32.284172920505057],
              [-70.244326773895978, -32.309835961167096],
              [-70.230835765421318, -32.341664059269988],
              [-70.154172867107775, -32.475835865983186],
              [-70.146535779827161, -32.506254966158281],
              [-70.152235809278466, -32.543063926418029],
              [-70.164246070733952, -32.581108882123488],
              [-70.17084598131288, -32.627782001343803],
              [-70.144863925414995, -32.759099937655833],
              [-70.04520896371281, -32.853963971127598],
              [-69.993335879385995, -32.876182049574993],
              [-70.021117865176734, -32.948891036511441],
              [-70.071672979044592, -33.005835842825775],
              [-70.099635846306171, -33.051846121141125],
              [-70.084655038356914, -33.076908849896142],
              [-70.090908943971726, -33.122499865413317],
              [-70.100699845098291, -33.153199926983689],
              [-70.095555032914888, -33.179446012832273],
              [-70.000417917036827, -33.298617900856868],
              [-69.975772942337301, -33.295772915273176],
              [-69.955417825694951, -33.265208975810708],
              [-69.927372983420071, -33.240835910051032],
              [-69.902508905770844, -33.237226830171551],
              [-69.875417924079898, -33.245146052313942],
              [-69.803617870726157, -33.278890924040297],
              [-69.774935836170016, -33.387155106156115],
              [-69.783481857033564, -33.412781936996339],
              [-69.809172893252196, -33.437917923585246],
              [-69.869726779446069, -33.555282002727893],
              [-69.905008892218916, -33.778472974174292],
              [-69.904172881193915, -33.827508951906282],
              [-69.884172989609169, -33.891946006214027],
              [-69.848346053128182, -34.097226862181174],
              [-69.817572901362098, -34.23472695501647],
              [-69.888064036712308, -34.278335818065386],
              [-69.934717877555101, -34.275835831617314],
              [-69.957363935979743, -34.270563949781376],
              [-69.98611788726538, -34.269726932927938],
              [-70.029590963482292, -34.276808970578713],
              [-70.047617922692154, -34.286008947529808],
              [-70.052435840650844, -34.296046108972462],
              [-70.052226795985177, -34.354999889845431],
              [-70.045490930936353, -34.400281948410793],
              [-70.149863898311253, -34.53173583919272],
              [-70.232508960937764, -34.661946023178018],
              [-70.297499891408961, -34.853617853473395],
              [-70.359754968675873, -34.996909003229007],
              [-70.361955050627444, -35.004446010585355],
              [-70.362926848484335, -35.042635805578129],
              [-70.361955050627444, -35.079173024536033],
              [-70.357790921118294, -35.100282009552188],
              [-70.362990886224907, -35.14479092136645],
              [-70.381117925358865, -35.169026859189842],
              [-70.450835913957803, -35.198335860105374],
              [-70.48222681198996, -35.203335833001518],
              [-70.516399831331626, -35.204999976062652],
              [-70.540490929867701, -35.210135903428693],
              [-70.564999950097274, -35.241946064258684],
              [-70.567154937409782, -35.267917894234586],
              [-70.550290883449861, -35.29667301898661],
              [-70.532926932783027, -35.30778205821025],
              [-70.421954893019915, -35.364446070767485],
              [-70.396117844047467, -35.515008856824068],
              [-70.404173020659471, -35.614446056681174],
              [-70.418481935248337, -35.634935954326977],
              [-70.408617943925947, -35.67222687402068],
              [-70.374454982867974, -35.735281920128898],
              [-70.343754921297545, -35.811355067733359],
              [-70.375072896771769, -35.83312689365431],
              [-70.402235795192269, -35.870835902679573],
              [-70.411399897597505, -35.893054986955178],
              [-70.407226883270994, -35.971117998379157],
              [-70.396117844047467, -36.075564055949826],
              [-70.424317919169596, -36.136035967130283],
              [-70.43855491702891, -36.151464033434252],
              [-70.467508860525015, -36.164173010340392],
              [-70.504517812995118, -36.162290937796229],
              [-70.588617806378807, -36.164726886503843],
              [-70.704726779336625, -36.270564004729351],
              [-70.718617939869944, -36.343999870311279],
              [-70.696399861422606, -36.390973061666216],
              [-70.706945972027597, -36.414517995389872],
              [-70.952781996070144, -36.486946020931327],
              [-70.98194599006024, -36.483609017629703],
              [-71.01648194581054, -36.475917950892551],
              [-71.034308912809934, -36.483890984853304],
              [-71.054799816284117, -36.547091037886958],
              [-71.136945988032267, -36.741673064973043],
              [-71.186117920234153, -36.845555020458335],
              [-71.14861778823672, -36.975281903905284],
              [-71.112790851755733, -37.114445972163708],
              [-71.130008957306757, -37.191946054968753],
              [-71.147917899319623, -37.235973007349173],
              [-71.183126921896644, -37.268608953282296],
              [-71.196326910692335, -37.302226922994237],
              [-71.16673577491531, -37.379235826271838],
              [-71.145426797688856, -37.405700009241571],
              [-71.118055022240696, -37.435009010157046],
              [-71.108690927625048, -37.458963986585331],
              [-71.123063879954429, -37.588190973325311],
              [-71.157373021403998, -37.608617839058923],
              [-71.175846065502128, -37.626390994239841],
              [-71.182372885884945, -37.658890985703067],
              [-71.179864014619454, -37.696464040258547],
              [-71.160835753253593, -37.745564055730995],
              [-71.137217896972061, -37.791117855598088],
              [-71.130281872075045, -37.814172950898694],
              [-71.123899890979374, -37.837781922362808],
              [-71.094999926939977, -37.882499878843078],
              [-70.99833579593718, -38.070208912944111],
              [-71.000564041083635, -38.154164067040711],
              [-71.009035798284685, -38.204173016096036],
              [-71.013199927793892, -38.227646033090252],
              [-71.002790945125412, -38.284999876280722],
              [-70.971535833925373, -38.415491021661182],
              [-70.950008927215777, -38.450835831070151],
              [-70.907226855098656, -38.48625507177951],
              [-70.880845988246563, -38.498890958489881],
              [-70.8439729902463, -38.5219460537906],
              [-70.825699938358696, -38.555899970182679],
              [-70.829726772293441, -38.588054962510398],
              [-70.86194596999951, -38.690135814636392],
              [-70.923481879971973, -38.740835935429573],
              [-70.946117880112809, -38.749173079265404],
              [-70.994590929226035, -38.748199940303948],
              [-71.026263962119685, -38.745417986632162],
              [-71.053335833071344, -38.748481907527605],
              [-71.097781880611763, -38.766108882316701],
              [-71.238890885688363, -38.831117917698805],
              [-71.33445497571546, -38.875835874178676],
              [-71.360835842567326, -38.888891023687165],
              [-71.393690891450149, -38.913535830748572],
              [-71.410426869929267, -38.935417962515658],
              [-71.418626885828758, -38.960554954932945],
              [-71.422499828020648, -39.013891016644038],
              [-71.422235798069948, -39.039590937680146],
              [-71.412226799822236, -39.066117984923949],
              [-71.402235906485259, -39.129717854740079],
              [-71.387790869788489, -39.240417985563511],
              [-71.403199825353113, -39.330146091669235],
              [-71.450290866266982, -39.43541793434315],
              [-71.485281959360861, -39.561526853093198],
              [-71.49264596423518, -39.599172997844448],
              [-71.507155038672636, -39.617646041942521],
              [-71.599164028276732, -39.621117993885491],
              [-71.625281871006337, -39.611808884554819],
              [-71.666735914380808, -39.566599916185282],
              [-71.68223589741433, -39.567217997727141],
              [-71.689863932239291, -39.575490936184451],
              [-71.709026806970655, -39.626673016411473],
              [-71.705008857853272, -39.727499935819026],
              [-71.669590958248477, -39.854446039060576],
              [-71.633345932435759, -39.950564005250953],
              [-71.666945964874969, -40.047499877555481],
              [-71.749999896739496, -40.089446106285834],
              [-71.791399960657486, -40.115008899385543],
              [-71.818063968199397, -40.204590992737565],
              [-71.791399960657486, -40.245835823808193],
              [-71.719590854848207, -40.302364049533594],
              [-71.702717916070867, -40.278955070279949],
              [-71.668617819287022, -40.296945987305833],
              [-71.663964018992829, -40.334517868394755],
              [-71.701673028018206, -40.404446075126089],
              [-71.715699975383473, -40.423618002312821],
              [-71.735835821438002, -40.427781964183964],
              [-71.758608949515178, -40.410417845879124],
              [-71.779872999740235, -40.40896408858822],
              [-71.837781892560656, -40.531672972124795],
              [-71.840699968340175, -40.573481905280687],
              [-71.839290970412492, -40.600763994364627],
              [-71.859308966908145, -40.643472976286091],
              [-71.879181956478476, -40.664999882995573],
              [-71.900008806632798, -40.68139991479444],
              [-71.930354984250073, -40.701317999004004],
              [-71.950145998807159, -40.726946003310616],
              [-71.914172881934292, -40.806117940527713],
              [-71.86319984637305, -40.89056393887563],
              [-71.850281992439136, -40.912508934916787],
              [-71.838064027422263, -40.955490999244432],
              [-71.859163959982709, -41.011281952764307],
              [-71.84042688593405, -41.058755040825929],
              [-71.8613908640246, -41.241672979058592],
              [-71.865981967682728, -41.265835826686043],
              [-71.88347298800204, -41.29389089488285],
              [-71.899035835309462, -41.356663973767581],
              [-71.893063896918306, -41.386944940178012],
              [-71.882508901496124, -41.406255001129296],
              [-71.86778189757581, -41.432782048373099],
              [-71.850008910032841, -41.549444897494254],
              [-71.892226880064925, -41.603890890827017],
              [-71.914517881070083, -41.623544945085769],
              [-71.91459097126608, -41.652091025172069],
              [-71.850281992439136, -41.766391015781323],
              [-71.787781995960643, -41.861255049253316],
              [-71.777508800124053, -41.887217994411685],
              [-71.7634818527589, -41.946108911010811],
              [-71.759763975775911, -41.999726939945447],
              [-71.731526852641537, -42.029444810098937],
              [-71.726535764562698, -42.095072932851167],
              [-71.77722683290034, -42.130417909898199],
              [-71.907226798753413, -42.1762550180934],
              [-71.937854943594289, -42.172226843054204],
              [-71.947917921298711, -42.1466729347718],
              [-71.961746049920123, -42.129173029635069],
              [-71.993763914311444, -42.124590978432536],
              [-72.017499955427922, -42.133891035307613],
              [-72.038335858037954, -42.149172921219986],
              [-72.130354905925913, -42.285426960325708],
              [-72.108899915945813, -42.315835834578934],
              [-72.081681864602388, -42.333335907353671],
              [-72.056672947666016, -42.349444919473456],
              [-72.044799981784706, -42.36896402509069],
              [-72.111399902393885, -42.526944795478357],
              [-72.126108801403461, -42.547499904331005],
              [-72.135008873857998, -42.569172991432254],
              [-72.138899920961023, -42.590699898141793],
              [-72.128345931367107, -42.668717982564544],
              [-72.110835800308621, -42.720555024707608],
              [-72.10722688806726, -42.760564027970474],
              [-72.101963891048797, -42.854726831420749],
              [-72.104590947149404, -42.881808928294333],
              [-72.113481967148573, -42.914308919757616],
              [-72.126399821082487, -42.936535883022316],
              [-72.133472973915843, -42.956108968096203],
              [-72.136673016919133, -43.005764033198432],
              [-72.118064018351106, -43.039172958244649],
              [-72.078335808845338, -43.088755100789115],
              [-72.052781900562934, -43.104726984972615],
              [-71.999308878553506, -43.121808968415735],
              [-71.958890838414447, -43.121117964315999],
              [-71.930845996139396, -43.12221800529187],
              [-71.868054979981878, -43.126672986841982],
              [-71.835564040974191, -43.132990930197252],
              [-71.739664004267013, -43.176108948994681],
              [-71.73333583498993, -43.278617948736098],
              [-71.740155016156393, -43.297644868997622],
              [-71.758899969194204, -43.307290930836814],
              [-71.795281955304972, -43.300835859545487],
              [-71.895708890291758, -43.325282015500932],
              [-71.929735896879777, -43.386944827487923],
              [-71.935564001812054, -43.453399908809786],
              [-71.848890931784297, -43.500554987463943],
              [-71.701745950575912, -43.601672926550521],
              [-71.690417808402628, -43.666254987783589],
              [-71.702926793098584, -43.689235819421867],
              [-71.766399928538419, -43.71999991873264],
              [-71.78625498083602, -43.725835902654012],
              [-71.807363965851835, -43.734309000959684],
              [-71.807645933075548, -43.763335867013438],
              [-71.756117847884298, -43.819455055862647],
              [-71.698335857078462, -43.866672998790875],
              [-71.648617927702219, -43.916254973697392],
              [-71.646672990884042, -43.938054962813055],
              [-71.655426882946756, -43.956391046612794],
              [-71.746672951721791, -44.058890993898842],
              [-71.779172943185131, -44.083891026017795],
              [-71.818208807486712, -44.162364080146176],
              [-71.800417882670956, -44.190144892470414],
              [-71.793899947105444, -44.215564019749308],
              [-71.797290929863607, -44.328408911963265],
              [-71.84285495565257, -44.352217875637848],
              [-71.853408945246485, -44.372435864343743],
              [-71.827708856572315, -44.400208965317063],
              [-71.778626778372598, -44.408055097263343],
              [-71.626399849254881, -44.410000034081691],
              [-71.545563936614883, -44.409864079611793],
              [-71.480563953688204, -44.402499907099354],
              [-71.401117928236545, -44.400418009982786],
              [-71.195699944333228, -44.4302820605281],
              [-71.161735802018995, -44.451255091074245],
              [-71.108754965365449, -44.53528199426222],
              [-71.121681871754902, -44.568754957048725],
              [-71.145008875995416, -44.58667295151696],
              [-71.189945935425158, -44.591881969078884],
              [-71.232017892703482, -44.663335849830503],
              [-71.227508931696605, -44.688608964355751],
              [-71.220417841590404, -44.717091006701537],
              [-71.225908826375928, -44.746808876855084],
              [-71.277926917628179, -44.798200001747887],
              [-71.305555011676063, -44.80360901152028],
              [-71.339735910006823, -44.803335929113985],
              [-71.369726862566495, -44.79639101939955],
              [-71.39584604640055, -44.786944949770543],
              [-71.442790906922653, -44.761391041488196],
              [-71.471399851282968, -44.747081959261266],
              [-71.491672992911845, -44.743755014243618],
              [-71.518335826987482, -44.743890968713458],
              [-71.555699836877125, -44.754309003837477],
              [-71.608472969969284, -44.774581977828404],
              [-71.62918197056473, -44.780008924873471],
              [-71.653626785415724, -44.782781993727838],
              [-71.673617792183222, -44.777226971201799],
              [-71.693617851405918, -44.770282061487364],
              [-71.714446042664804, -44.764163942704442],
              [-71.771326811238794, -44.75534483943477],
              [-71.795008872898677, -44.763200023836532],
              [-71.81348191699675, -44.78500001295231],
              [-71.841117890033672, -44.792364017826742],
              [-71.951535885995611, -44.783055076134019],
              [-71.974164007147294, -44.77611787777056],
              [-71.994172951187522, -44.769172968056125],
              [-72.019454950530019, -44.759173022263724],
              [-72.047372890790825, -44.754035921431182],
              [-72.075426785521131, -44.766391014384453],
              [-72.081808934254866, -44.787090962524303],
              [-72.066817900383569, -44.901673087995334],
              [-71.934446023563396, -44.922226855743418],
              [-71.806672961752213, -44.930835908519043],
              [-71.740846020256015, -44.937226942070026],
              [-71.582645979280755, -44.975417910529188],
              [-71.549035888557796, -44.999173062385125],
              [-71.498064026463112, -45.075564051758818],
              [-71.329445950363834, -45.23125505383058],
              [-71.298617813312489, -45.305072966560488],
              [-71.467508804179829, -45.477499947111255],
              [-71.606254950744926, -45.522635825284965],
              [-71.629308872579259, -45.521526899491676],
              [-71.649735905950877, -45.523890931469793],
              [-71.672499981572628, -45.527781978572875],
              [-71.712781899603669, -45.538891017796516],
              [-71.75049090862899, -45.558544904417204],
              [-71.781954896856803, -45.655008875571582],
              [-71.792226919227005, -45.729035832967213],
              [-71.753754989372624, -45.849099890768343],
              [-71.72445504091263, -45.86180886767454],
              [-71.69445503589759, -45.868899957780684],
              [-71.649735905950877, -45.883609024428381],
              [-71.629246008305074, -45.903544878272669],
              [-71.60444596839659, -45.976944869308966],
              [-71.63862686672735, -45.996944928531775],
              [-71.645008847822908, -45.999454973263823],
              [-71.665845923899042, -46.012499896850159],
              [-71.699717865277876, -46.044444838683603],
              [-71.720835902749343, -46.068190938084001],
              [-71.740699839864249, -46.096672980429901],
              [-71.765972954389383, -46.117090961346207],
              [-71.7930639360805, -46.125555007196454],
              [-71.824172866888944, -46.130564032548023],
              [-71.851117835826074, -46.132508969366256],
              [-71.901808904163715, -46.149099940920053],
              [-71.834872869236392, -46.200973025246867],
              [-71.80056389542483, -46.209726917309638],
              [-71.766954978168258, -46.221526960632957],
              [-71.746608913981447, -46.247018004641404],
              [-71.740846020256015, -46.271390902763017],
              [-71.725008917075968, -46.430555030244307],
              [-71.689863932239291, -46.490699879561873],
              [-71.670563929571586, -46.538335911821719],
              [-71.666399967700556, -46.652499947961076],
              [-71.672855038991997, -46.683964103827066],
              [-71.686399859285359, -46.702781979422696],
              [-71.813754999402931, -46.786044955953116],
              [-71.850146037969182, -46.796673041571182],
              [-71.917364040120276, -46.800144825876203],
              [-71.939246004249242, -46.816255011462374],
              [-71.976390911189355, -47.034444836546299],
              [-71.89278192969465, -47.115564057514462],
              [-71.862508842273257, -47.139726905141913],
              [-71.852363889555704, -47.172855036430974],
              [-71.867917852045593, -47.218617880963791],
              [-71.882781983902419, -47.232781956265342],
              [-72.017372885775444, -47.277499912745498],
              [-72.081399897378674, -47.352781976326071],
              [-72.159035767015553, -47.411108958477939],
              [-72.194245963059245, -47.418608917822155],
              [-72.229864022512459, -47.410699921601861],
              [-72.256390902118085, -47.411118010933365],
              [-72.293063907907936, -47.420008863294413],
              [-72.313608958476721, -47.42611792962191],
              [-72.335417832409803, -47.435417986497157],
              [-72.350146009796731, -47.449999983492205],
              [-72.359308938735637, -47.478126968418621],
              [-72.341672911491173, -47.48999993429976],
              [-72.308263986444842, -47.505491032515749],
              [-72.329599953399452, -47.595835879058882],
              [-72.339726801206268, -47.617499913704705],
              [-72.353055033120825, -47.636673014357882],
              [-72.374026890200753, -47.659726936192214],
              [-72.404172907969439, -47.691944960431897],
              [-72.428690980654665, -47.721044916681535],
              [-72.455835774163972, -47.763200022439548],
              [-72.535072922588142, -47.915490989297723],
              [-72.531399972606437, -47.941390902544185],
              [-72.515017878080357, -47.961108994543281],
              [-72.478335819834854, -47.988481943457998],
              [-72.450008842698196, -48.001390912574493],
              [-72.406955029279175, -48.008264073197608],
              [-72.321954987129914, -48.078335945749757],
              [-72.300281900028722, -48.190417917134653],
              [-72.291881891918763, -48.347781947084854],
              [-72.426117904010482, -48.40528197066709],
              [-72.490845977997253, -48.422782043441714],
              [-72.55681792641883, -48.446944891069336],
              [-72.577926911434702, -48.456808882391726],
              [-72.59556394450783, -48.473064074903448],
              [-72.603763960407207, -48.494035931983092],
              [-72.590563971611573, -48.533681998837551],
              [-72.579726841327727, -48.564726891905536],
              [-72.573626827455655, -48.587226937576418],
              [-72.561254970696154, -48.651673044339475],
              [-72.556117869863613, -48.694172981595159],
              [-72.552226822760645, -48.731117896324804],
              [-72.552081983473272, -48.776673037296462],
              [-72.561117842759813, -48.799444824269074],
              [-72.586946006914786, -48.824444856388027],
              [-72.708890963793749, -48.903617967071511],
              [-72.736954916808031, -48.91972697919141],
              [-72.755281948152458, -48.928063955389064],
              [-72.781399958519955, -48.93805501636416],
              [-72.810835861449902, -48.945835937103595],
              [-72.856535841708933, -48.952091016184795],
              [-72.895835903599334, -48.950699955529842],
              [-72.915846021105835, -48.954444822241101],
              [-72.951672957586823, -48.964726902895109],
              [-72.982508805989085, -48.978481941320581],
              [-73.006390859859323, -48.998335820151624],
              [-73.077373007098856, -49.067917854280836],
              [-73.176817918306881, -49.22180892645963],
              [-73.174726801096824, -49.242781957005718],
              [-73.167690863913663, -49.252635890044417],
              [-73.086735760610054, -49.267364067431288],
              [-73.136126795761584, -49.304173027690979],
              [-73.168064026244224, -49.30472690385443],
              [-73.423063985054057, -49.309717991933155],
              [-73.464735790273551, -49.311391019811708],
              [-73.540008969036819, -49.443199968013118],
              [-73.582299861626666, -49.540835896872807],
              [-73.568890995803315, -49.559172986500869],
              [-73.515426858611306, -49.605135823244154],
              [-73.446463911852334, -49.790908972982436],
              [-73.466117798473022, -49.809308926884569],
              [-73.501808948122175, -49.831117968455715],
              [-73.529026831827593, -49.852708912905712],
              [-73.554245966896133, -49.940664078846794],
              [-73.514726802056089, -49.968609008835699],
              [-73.490008904798799, -49.983755107916068],
              [-73.469999960758685, -50.001672934746182],
              [-73.47424606528125, -50.0320179388969],
              [-73.501672993652335, -50.056526959126586],
              [-73.525008882710267, -50.087644942390341],
              [-73.536255049870192, -50.120135881398198],
              [-73.522508896262138, -50.148408879078318],
              [-73.485290899126255, -50.166944955088411],
              [-73.436399928319474, -50.191390943405963],
              [-73.349026801736613, -50.236255080277829],
              [-73.332090998685317, -50.24959102354336],
              [-73.317508834052148, -50.267226883149874],
              [-73.277508883244479, -50.328472946909812],
              [-73.266363969475265, -50.355582033511666],
              [-73.2495909429839, -50.500282013556841],
              [-73.228899879661469, -50.598063955170232],
              [-73.176526898627912, -50.739726836410341],
              [-73.158482002145263, -50.758891052246099],
              [-73.137090882267501, -50.769863969361836],
              [-73.109172942006921, -50.77250896273523],
              [-73.083617860258244, -50.768617915632319],
              [-73.052226794588194, -50.761673005917828],
              [-73.018064001168341, -50.749455040900955],
              [-72.983899866643924, -50.72972689061794],
              [-72.957163942372404, -50.706317911364181],
              [-72.92584596689818, -50.679172950216582],
              [-72.906108931797746, -50.667499976545741],
              [-72.873063948988545, -50.654444827037366],
              [-72.766117904969974, -50.620699955311011],
              [-72.737090871278042, -50.620763993051469],
              [-72.708899848611281, -50.637499971530531],
              [-72.690981854143047, -50.652644897144512],
              [-72.671399884251628, -50.663890896666373],
              [-72.63945494241824, -50.671944899812104],
              [-72.606946066137539, -50.669173004424181],
              [-72.586946006914786, -50.659726934795231],
              [-72.564726922639068, -50.631390905203148],
              [-72.542917881068092, -50.613891000066417],
              [-72.518335770642466, -50.605835823454299],
              [-72.491255014873332, -50.603064095704497],
              [-72.37028185585126, -50.614444876229868],
              [-72.293890866477625, -50.652917979550693],
              [-72.300417854498619, -50.677435884597685],
              [-72.338617875413036, -50.731673000902674],
              [-72.33430890661657, -50.756117983391846],
              [-72.302781886476737, -50.780555086891979],
              [-72.280426847731235, -50.800699985402105],
              [-72.254172882893556, -50.831672961740537],
              [-72.242645921976475, -50.854590929104972],
              [-72.265835798280591, -51.010700020508295],
              [-72.281881946126418, -51.030491035065438],
              [-72.334108914406329, -51.034308991972409],
              [-72.395217850229869, -51.108126904702203],
              [-72.37604592304308, -51.158963985793548],
              [-72.351964044600493, -51.179999880613707],
              [-72.326672992802457, -51.191944930862405],
              [-72.290555036642502, -51.204381998828808],
              [-72.249626873874718, -51.237854961615426],
              [-72.262790988124607, -51.265144929688347],
              [-72.292508858278097, -51.284454990639802],
              [-72.311672906475906, -51.31139090712162],
              [-72.313335876070482, -51.359455086996775],
              [-72.309726796191228, -51.399173070580673],
              [-72.347581985608201, -51.484791026633957],
              [-72.369446012464323, -51.498890896556929],
              [-72.400554943272766, -51.513617900477357],
              [-72.289245951001021, -51.696255044952807],
              [-72.258335838936375, -51.706944821378443],
              [-72.20875503749636, -51.712226929136307],
              [-72.181672940622775, -51.719999970886761],
              [-72.161117831770298, -51.728335941256091],
              [-72.135563923487723, -51.740008914926989],
              [-72.087508963706341, -51.764455070882605],
              [-71.959590894969608, -51.85459104039802],
              [-71.942363904601052, -51.880981965533863],
              [-71.942363904601052, -51.914100038539004],
              [-71.910573022148526, -51.995835832306568],
              [-71.69445503589759, -51.996944925737751],
              [-71.233335863162324, -51.997509027823071],
              [-70.662226842080884, -51.997509027823071],
              [-70.434717849382537, -51.997509027823071],
              [-70.110290921652563, -51.996391049574356],
              [-70.066681890965754, -51.996391049574356],
              [-69.998335852282196, -51.996391049574356],
              [-69.94307295866372, -52.01083591863312],
              [-69.88779095430607, -52.026117972183386],
              [-69.765835771505181, -52.060564073931289],
              [-69.461399850542477, -52.143618005796043],
              [-69.287508886778937, -52.143890920564161],
              [-69.233608890620587, -52.143335870934436],
              [-69.032790895192591, -52.194726995827182],
              [-68.991390831274771, -52.206118002274536],
              [-68.935835912148832, -52.230563990591975],
              [-68.880008916444979, -52.255835931650722],
              [-68.828608906734871, -52.279726870338493],
              [-68.727508904921137, -52.289999898536962],
              [-68.648617929099203, -52.297226943113117],
              [-68.575563943027078, -52.305281952087228],
              [-68.435835772683163, -52.328754969081444],
              [-68.441754905042103, -52.377782061998062],
              [-68.617581920924295, -52.641508923070205],
              [-68.618617924083992, -52.650555008348931],
              [-68.619735902332764, -52.719172955972113],
              [-68.62056403436884, -52.795008895716137],
              [-68.623064020816912, -53.138891058960951],
              [-68.623899864203793, -53.276390984158297],
              [-68.623335762118586, -53.324717852879758],
              [-68.623335762118586, -53.338335931006952],
              [-68.624454913833631, -53.510281957952202],
              [-68.62695490028193, -53.840282013119122],
              [-68.629181971961714, -53.998063964762935],
              [-68.631672905954474, -54.334726962420632],
              [-68.630572864978603, -54.35528207127328],
              [-68.632126869831723, -54.546099953270755],
              [-68.635836095998116, -54.788336149259635],
              [-68.636127108593485, -54.804772868381065],
              [-68.63721787019648, -54.884999913254205],
              [-68.643108839201432, -54.888608993066725],
              [-68.642330563999906, -54.893038123999929],
              [-68.641752563999944, -54.899400123999897],
              [-68.640595563999966, -54.90981112399993],
              [-68.625557563999962, -54.910390123999889],
              [-68.601844563999919, -54.90981112399993],
              [-68.571190563999892, -54.907498123999858],
              [-68.539090563999878, -54.90634112399988],
              [-68.40866756399987, -54.89448412400003],
              [-68.307885563999832, -54.870771123999873],
              [-68.183389563999981, -54.870771123999873],
              [-68.070751562999988, -54.882628123999837],
              [-67.910686562999956, -54.888556123999876],
              [-67.756549562999965, -54.90041312399984],
              [-67.620197562999863, -54.90041312399984],
              [-67.495702562999838, -54.90041312399984],
              [-67.412705562999861, -54.90634112399988],
              [-67.288210562999836, -54.912269124000034],
              [-67.199285562999819, -54.930054124000037],
              [-67.062933561999841, -54.947839124000041],
              [-66.926581561999853, -54.971553123999854],
              [-66.831727561999969, -54.989338123999858],
              [-66.754659561999915, -55.036764123999831],
              [-66.683519561999901, -55.072335123999835],
              [-66.612379561999887, -55.101976123999918],
              [-66.52938256199991, -55.113833123999882],
              [-66.416666561999875, -55.121666123999887],
              [-66.078332561999957, -55.183332123999833],
              [-65.726666560999888, -55.381666336499904],
              [-65.72667, -56.379999582999915],
              [-67.26667, -56.38],
              [-67.266666562999944, -58.376147320499967],
              [-67.266666562999944, -59.778079127999888],
              [-67.323841562999917, -59.787756128000012],
              [-68.463867063999828, -59.863920127999847],
              [-68.46545856399996, -59.864013127999897],
              [-68.4670355639999, -59.863972127999887],
              [-68.481780563999962, -59.864895127999887],
              [-69.630801564999956, -59.830217127999845],
              [-70.743355565999877, -59.686183127999925],
              [-71.776457566999852, -59.438443127999903],
              [-72.692349567999941, -59.096561127999905],
              [-73.46050156799987, -58.673391126999888],
              [-74.058700568999825, -58.184309126999892],
              [-74.189616568999838, -58.016195126999946],
              [-74.586134568999967, -57.85290212599989],
              [-75.376790569999855, -57.714710125999886],
              [-76.322150570999781, -57.435040125999876],
              [-77.146755571999904, -57.06571512599993],
              [-77.824356571999942, -56.62032312499997],
              [-78.336441572999945, -56.114685124999767],
              [-78.672267152199936, -55.565996352399907],
              [-78.673668572999929, -55.561043123999923],
              [-78.68594657299991, -55.540698123999903],
              [-78.687253572999964, -55.535937123999872],
              [-78.699555572999827, -55.515529123999841],
              [-78.70028786599994, -55.51285807499994],
              [-78.700843498999916, -55.510831303333248],
              [-78.701713573, -55.509386123999889],
              [-78.718874572999823, -55.491747123999858],
              [-78.721873572999868, -55.489640124],
              [-78.739690572999905, -55.471306123999966],
              [-78.742763572999934, -55.469145123999908],
              [-78.760508572999811, -55.450865123999904],
              [-78.763654572999883, -55.448650123999869],
              [-78.781326572999774, -55.430424123999899],
              [-78.784547572999827, -55.428155123999886],
              [-78.802146572999902, -55.409983123999837],
              [-78.805440572999771, -55.407660123999847],
              [-78.814011726999922, -55.398800444000017],
              [-78.82296729799981, -55.389543068999899],
              [-78.824317888999872, -55.388589440999851],
              [-78.826334572999883, -55.387165123999864],
              [-78.835674238999843, -55.377500133999945],
              [-78.843789242999947, -55.3691021489999],
              [-78.845135008999932, -55.368150932],
              [-78.847229572999822, -55.366670123999825],
              [-78.858586000999878, -55.354904725999916],
              [-78.864612292999936, -55.348661223999898],
              [-78.865635371999872, -55.347937332999834],
              [-78.868125572999929, -55.346175123999899],
              [-78.885436572999879, -55.328220123999927],
              [-78.889022572999863, -55.325680123999916],
              [-78.906261572999881, -55.307779123999921],
              [-78.909920573000022, -55.305184123999879],
              [-78.92708757399987, -55.287338123999859],
              [-78.930819573999941, -55.284689123999897],
              [-78.947915574000035, -55.266897123999911],
              [-78.951719573999924, -55.264194123999914],
              [-78.959567573999834, -55.256019123999863],
              [-78.961344573999952, -55.254756123999925],
              [-79.443614573999866, -54.745455123999875],
              [-79.445696992999956, -54.74182156299986],
              [-79.447224399999868, -54.739156317999914],
              [-79.452972573999944, -54.733001123999884],
              [-79.459630573999789, -54.721373123999854],
              [-79.47032057399997, -54.709917122999911],
              [-79.47693457399987, -54.698351122999895],
              [-79.487670573999821, -54.686833122999872],
              [-79.494241573999943, -54.675329122999926],
              [-79.503613277999989, -54.665264666999917],
              [-79.505023406999953, -54.663750238999853],
              [-79.506405963999953, -54.661326545999884],
              [-79.511550573999841, -54.652307122999844],
              [-79.522378573999958, -54.640666122999903],
              [-79.528862573999902, -54.629285122999875],
              [-79.539736573999846, -54.617582122999977],
              [-79.546176573999787, -54.606263123000019],
              [-79.55531, -54.596421625999881],
              [-79.557096207999962, -54.594499361999908],
              [-79.559201146999953, -54.590795310999852],
              [-79.563493573999892, -54.583241122999823],
              [-79.574458573999948, -54.571415122999781],
              [-79.580774079666469, -54.560288699666501],
              [-79.591823573999932, -54.548332122999909],
              [-79.593692192999811, -54.545035988999928],
              [-79.598135096999897, -54.537198102999866],
              [-79.601408269999922, -54.533660472999827],
              [-79.609190573999854, -54.525248122999983],
              [-79.615459573999942, -54.514176122999913],
              [-79.626560573999939, -54.502165122999884],
              [-79.632786573999965, -54.491154122999887],
              [-79.638866357999916, -54.48456982599987],
              [-79.643933136999919, -54.479082110999855],
              [-79.648548043999938, -54.470909965999851],
              [-79.650116573999981, -54.468132122999862],
              [-79.661307573999977, -54.455998122999915],
              [-79.666660839999906, -54.446507648999898],
              [-79.667448444999764, -54.445111188999817],
              [-79.668423663999988, -54.444052720999935],
              [-79.678684573999874, -54.432915122999873],
              [-79.683080622999853, -54.425111936999826],
              [-79.684783101, -54.422089563999862],
              [-79.692143593999788, -54.414092334999907],
              [-79.69606451199985, -54.409832189999918],
              [-79.69676273399989, -54.408591090999892],
              [-79.702120573999821, -54.399067122999895],
              [-79.710237682999889, -54.390239067999858],
              [-79.713446396999984, -54.386749103999875],
              [-79.71493668699992, -54.384096755999792],
              [-79.71945957399987, -54.376046122999924],
              [-79.730830573999867, -54.363665122999919],
              [-79.736802573999853, -54.353024122999898],
              [-79.748217573999796, -54.340582122999876],
              [-79.754146573999947, -54.330003122999926],
              [-79.765606573999833, -54.317499122999891],
              [-79.771493573999919, -54.306982122999898],
              [-79.782998573999805, -54.294416122999849],
              [-79.788843573999827, -54.283960122999929],
              [-79.800392558999874, -54.271333135999896],
              [-79.804164573999913, -54.264577122999924],
              [-79.81168657399985, -54.256346122999844],
              [-79.959217573999979, -53.990375122999922],
              [-80.121904291999954, -53.809612210000012],
              [-80.129615576999925, -53.801044072000025],
              [-80.177268554999898, -53.712546052999869],
              [-80.42688457499986, -53.24897512199982],
              [-80.508811753000032, -52.879146863999893],
              [-80.554209460999971, -52.674216369999897],
              [-80.553850666999892, -52.667699718499875],
              [-80.554673574999867, -52.663945121999859],
              [-80.553432574999931, -52.646327121999889],
              [-80.556005574999801, -52.634590121999906],
              [-80.555886128999873, -52.632892218999928],
              [-80.554767163999884, -52.616992069999924],
              [-80.557343126999911, -52.605235574999824],
              [-80.556107550666468, -52.587656269999911],
              [-80.558686459999933, -52.575880663999897],
              [-80.558301587999892, -52.570400254999981],
              [-80.558002574999932, -52.566144121999855],
              [-80.561890574999836, -52.558699120999904],
              [-80.564432119999822, -52.54708331799992],
              [-80.56553547999988, -52.542040099999895],
              [-80.566442766999899, -52.540301214999943],
              [-80.571160574999851, -52.531258120999894],
              [-80.574790574999952, -52.514645120999887],
              [-80.580435575000024, -52.503817120999884],
              [-80.584050575, -52.487251120999929],
              [-80.589714574999846, -52.476376120999817],
              [-80.591366574999938, -52.468795120999914],
              [-80.593412328999818, -52.464866173999894],
              [-80.593971224999791, -52.463792454999862],
              [-80.717987188999928, -51.889029147000031],
              [-80.690797981999822, -51.500318216999915],
              [-80.687131574999853, -51.447902119999867],
              [-80.758600574999832, -51.111105119999877],
              [-80.758016574999829, -51.10264512],
              [-80.76145157499991, -51.086364119999928],
              [-80.760766574999934, -51.076437119999866],
              [-80.764809574999845, -51.057265119999897],
              [-80.764526410999849, -51.053154259999928],
              [-80.764127218999874, -51.047364238999819],
              [-80.765997466999977, -51.038488952999899],
              [-80.768172574999824, -51.028165119999869],
              [-80.767666216999942, -51.020811757999866],
              [-80.767492527499826, -51.018290312999795],
              [-80.77154057499996, -50.999065119999898],
              [-80.77086257499991, -50.989216119999924],
              [-80.773309591999919, -50.977590374999863],
              [-80.774914254999771, -50.969965572999854],
              [-80.774238130999777, -50.960142870999903],
              [-80.778292460999864, -50.940865654999925],
              [-80.778170435999812, -50.939092768499904],
              [-80.778977574999885, -50.934966119999899],
              [-80.752088131999869, -50.535503940999888],
              [-80.739982448999911, -50.355667134999919],
              [-80.739188120999813, -50.353368009999883],
              [-80.737789144999908, -50.332093358999884],
              [-80.736793294999813, -50.329208621999953],
              [-80.735534888999837, -50.310027349999928],
              [-80.735042573999863, -50.302525141999865],
              [-80.734047466999868, -50.29963970499989],
              [-80.73230137399986, -50.272957698999846],
              [-80.73130665233316, -50.270070398666519],
              [-80.729564574999984, -50.243389118999914],
              [-80.728570574999935, -50.240501118999873],
              [-80.727658984999891, -50.226496309999902],
              [-80.727011357000038, -50.216550930999915],
              [-80.729066293999892, -50.20675510399991],
              [-80.728663320999942, -50.200560369999891],
              [-80.732915396999886, -50.180280141999845],
              [-80.73343146000002, -50.177818666999826],
              [-80.733030477999876, -50.171653320999894],
              [-80.737801294999826, -50.148882377999939],
              [-80.737402291999842, -50.142746228999897],
              [-80.739353702999836, -50.133425422999892],
              [-80.742175574999862, -50.119945118999908],
              [-80.741778575000012, -50.113839118999863],
              [-80.746554574999948, -50.091009118999807],
              [-80.746159574999979, -50.084931118999862],
              [-80.75093857499985, -50.062073118999876],
              [-80.750711308999882, -50.058575069999904],
              [-80.750545581666529, -50.056024448999892],
              [-80.755326574999856, -50.033136118999892],
              [-80.75493557499999, -50.027117118999797],
              [-80.759719574999906, -50.004200118999904],
              [-80.759330574999922, -49.998210118999907],
              [-80.764117523999857, -49.975263360999918],
              [-80.7637303919999, -49.969302775999836],
              [-80.76440375999988, -49.966072407999889],
              [-80.768519574999857, -49.946326118999821],
              [-80.768338463999783, -49.943536073999894],
              [-80.768134547499926, -49.940395252999906],
              [-80.772926427999835, -49.917390304999913],
              [-80.772543224999879, -49.911487953999881],
              [-80.774421039999822, -49.902466358999845],
              [-80.77733757499999, -49.888453118999905],
              [-80.777127665999899, -49.885217429999898],
              [-80.77695653599983, -49.882580299499864],
              [-80.781753550999895, -49.859516234999944],
              [-80.781374327999856, -49.853672965999863],
              [-80.781992825999907, -49.850697197999864],
              [-80.78617357499985, -49.830580118999933],
              [-80.785796574999836, -49.824765118999892],
              [-80.790598574999876, -49.801643118999891],
              [-80.790223598499892, -49.795857465499864],
              [-80.79502845199994, -49.772706710999898],
              [-80.794935976999852, -49.771281005999981],
              [-80.794654574999868, -49.766950118999887],
              [-80.798563537999769, -49.748104492999914],
              [-80.79946246899982, -49.743770068999936],
              [-80.799274341999876, -49.740869431999897],
              [-80.79990305899986, -49.737836269999832],
              [-80.801706574999855, -49.729133118999869],
              [-80.801255574999857, -49.722180118999972],
              [-80.802816574999895, -49.714648118999889],
              [-80.80254775099985, -49.710496122999814],
              [-80.801906087999896, -49.700593101999857],
              [-80.805066100999966, -49.68533730199988],
              [-80.804157563666649, -49.671301581666626],
              [-80.807319574999951, -49.656026118999819],
              [-80.806732574999899, -49.646951118999937],
              [-80.808781574999898, -49.637052118999861],
              [-80.808696637999844, -49.635737820999907],
              [-80.808513558666561, -49.632905440666548],
              [-80.808690604999867, -49.632049821999829],
              [-80.809788091999906, -49.626745191999873],
              [-80.809029226, -49.615003406999847],
              [-80.812645107999913, -49.597519398999907],
              [-80.811888228000043, -49.585800060999929],
              [-80.814087774999905, -49.575158493999879],
              [-80.815506574999972, -49.568293118999918],
              [-80.814751574999889, -49.556596118999892],
              [-80.818372575000012, -49.539067118999924],
              [-80.817619574999867, -49.527393118999896],
              [-80.821243574999869, -49.50984111899993],
              [-80.820492575, -49.498189118999846],
              [-80.824118574999943, -49.480615118999879],
              [-80.823369575000015, -49.468986118999851],
              [-80.826998574999891, -49.451389118999884],
              [-80.826251574999844, -49.439782118999915],
              [-80.828633588, -49.428226863999839],
              [-80.829883260999964, -49.422163766999915],
              [-80.829138204999822, -49.410578883999904],
              [-80.832772204999856, -49.392937684999879],
              [-80.832403982999949, -49.387208232999967],
              [-80.832033574999855, -49.381451118999905],
              [-80.83525861999982, -49.365789166999889],
              [-80.835646481999845, -49.363905237999916],
              [-80.799214796249885, -48.784547487499879],
              [-80.798222995999936, -48.781497132999903],
              [-80.796139404999849, -48.77522602099981],
              [-80.795032560999914, -48.757183995999924],
              [-80.794897584999944, -48.754984282999828],
              [-80.791832244999796, -48.745748528999854],
              [-80.791095299999938, -48.733699215999877],
              [-80.79059457499983, -48.725516117999916],
              [-80.789278911999929, -48.721547235999822],
              [-80.787529556999914, -48.716270893999877],
              [-80.786296488999938, -48.696047956999848],
              [-80.783231329999865, -48.68679311499983],
              [-80.782002601999977, -48.666579523999985],
              [-80.778937556999949, -48.657315193999921],
              [-80.777713161999941, -48.637110945999837],
              [-80.774648225999783, -48.627837129999818],
              [-80.773428151999951, -48.607642222999914],
              [-80.770363329999839, -48.598358922999921],
              [-80.769147564000036, -48.578173355999979],
              [-80.767914748999942, -48.57443522199992],
              [-80.766082574999871, -48.568880118000038],
              [-80.76540050899979, -48.557516488999852],
              [-80.764871389999882, -48.54870434399993],
              [-80.763274321999916, -48.54385659799982],
              [-80.76180657499998, -48.539402117999884],
              [-80.760536744999797, -48.51904416699989],
              [-80.757535151999775, -48.509923450999906],
              [-80.756332245999943, -48.489765887999795],
              [-80.753861273999917, -48.48224962199987],
              [-80.753267574999825, -48.480444117999866],
              [-80.752712546999931, -48.471109639999895],
              [-80.752069257999835, -48.46029644399988],
              [-80.750132051999913, -48.454397577999885],
              [-80.749004574999788, -48.450965117999885],
              [-80.747810612999899, -48.430826753999838],
              [-80.74474654699992, -48.421486706999985],
              [-80.743556402999843, -48.40135712599988],
              [-80.740492429999847, -48.392007509999928],
              [-80.739306517999864, -48.371887252999898],
              [-80.737591348999956, -48.366647952999926],
              [-80.736242574999835, -48.36252811799983],
              [-80.735060574999977, -48.342417117999844],
              [-80.733756556, -48.338428428999862],
              [-80.73199726799993, -48.333048696999924],
              [-80.731274332999874, -48.320706959999882],
              [-80.730819574999828, -48.312947117999897],
              [-80.729637960999924, -48.309329363999893],
              [-80.727756204999935, -48.303569079999875],
              [-80.727119527999832, -48.292666070999871],
              [-80.726582574999895, -48.283476117999783],
              [-80.725226114999941, -48.279319053],
              [-80.723519474999989, -48.274089323999817],
              [-80.722350382999878, -48.254006337999954],
              [-80.721119060999968, -48.250229180999895],
              [-80.719286574999842, -48.244609116999925],
              [-80.718692055999895, -48.234356212999899],
              [-80.718122154999946, -48.224535753999817],
              [-80.716081447999898, -48.218269176999854],
              [-80.715058574999773, -48.215129116999833],
              [-80.714483468999788, -48.205181264999908],
              [-80.713898231999963, -48.19506503],
              [-80.710835195999834, -48.185649221999881],
              [-80.709756058999801, -48.166937202999918],
              [-80.709678574999828, -48.165594116999898],
              [-80.709343353999941, -48.164562516999865],
              [-80.70661565599994, -48.156168492333165],
              [-80.706359905999818, -48.151719429999844],
              [-80.705463264999878, -48.136123156999929],
              [-80.702400511999826, -48.126688459999912],
              [-80.701252202999797, -48.106652009999891],
              [-80.698189593999984, -48.097207872999888],
              [-80.697045408999827, -48.077180722999913],
              [-80.695512876999885, -48.07244992],
              [-80.693982574999779, -48.067727116999833],
              [-80.692842574999872, -48.047709116999897],
              [-80.690450335999856, -48.040315987999918],
              [-80.68978056099985, -48.038246283999911],
              [-80.688644593999811, -48.01823772899985],
              [-80.685582428999851, -48.008765283999821],
              [-80.684450554999898, -47.988766022999926],
              [-80.681388540999876, -47.97928414699976],
              [-80.68119487599995, -47.97585144999988],
              [-80.680260574999863, -47.959294116999899],
              [-80.67719857499992, -47.949802116999876],
              [-80.676750451999965, -47.941829272999939],
              [-80.676075170999866, -47.929822192999893],
              [-80.673013463999894, -47.920321462999823],
              [-80.67261319399995, -47.91318182099991],
              [-80.671893575000013, -47.900350116999846],
              [-80.670973228999912, -47.897490460999848],
              [-80.668832256999849, -47.89083991699988],
              [-80.667823026999827, -47.872781235999923],
              [-80.667716606, -47.870877663999863],
              [-80.664655258999915, -47.861358233999852],
              [-80.663815732999865, -47.846288534999928],
              [-80.663543574999949, -47.841405116999908],
              [-80.663191722999784, -47.840309788999832],
              [-80.660482462999823, -47.83187641599983],
              [-80.660159355999895, -47.826058165999882],
              [-80.659374574999788, -47.811932116999856],
              [-80.657778574999895, -47.806960116999903],
              [-80.657202574999872, -47.796564116999832],
              [-80.610374475999777, -47.648666850999916],
              [-80.477703277999865, -47.229652071999908],
              [-80.471145192999927, -47.218633181999905],
              [-80.469409574999929, -47.215717116999826],
              [-80.494945574999917, -47.093310115999941],
              [-80.488649527999826, -46.964671398999883],
              [-80.466578172999789, -46.513725364999914],
              [-80.466226604999861, -46.512578317666623],
              [-80.46594457499998, -46.50668411599986],
              [-80.465369334999821, -46.504806401999929],
              [-80.464468457999942, -46.501866781999922],
              [-80.46415092399991, -46.495209423999825],
              [-80.463287574999924, -46.477117115999874],
              [-80.462512261999905, -46.474583984999924],
              [-80.461880422000036, -46.472520348999865],
              [-80.461583590999851, -46.466279757999928],
              [-80.460757574999946, -46.448923115999889],
              [-80.391791384999863, -46.220608272999925],
              [-80.289330960999905, -45.881429251999862],
              [-80.288935306999974, -45.880120743999896],
              [-80.288441512999839, -45.879259601999877],
              [-80.280172753999977, -45.851514872999928],
              [-80.279662785333301, -45.850624543999913],
              [-80.277805916000034, -45.844385883999905],
              [-80.271413358999837, -45.822908826999878],
              [-80.270887576999769, -45.821990121999875],
              [-80.262636124333198, -45.794265945333223],
              [-80.262111574999949, -45.792521114999829],
              [-80.254936574999988, -45.779968114999917],
              [-80.250992663999853, -45.766676413999903],
              [-80.24496731999983, -45.756124914999873],
              [-80.24273439999979, -45.748590959999888],
              [-80.241672574999768, -45.745009114999846],
              [-80.177483291999863, -45.631214281999917],
              [-79.937630488999901, -45.206003820999911],
              [-79.935083790499903, -45.203073495499893],
              [-79.93384057399993, -45.200844114999882],
              [-79.92562663800004, -45.19138831399988],
              [-79.921908573999929, -45.187108372999887],
              [-79.916120661999855, -45.17671465299992],
              [-79.904151427999921, -45.16292260799986],
              [-79.899818235999817, -45.155131903999916],
              [-79.898401573999934, -45.152585114999852],
              [-79.896224752999899, -45.150074219999851],
              [-79.888614223999866, -45.141296273999956],
              [-79.893657189999772, -45.117912630999854],
              [-79.883195922999846, -44.797835368999927],
              [-79.874695573999873, -44.537765113999853],
              [-79.817865874999939, -44.332856333999985],
              [-79.716916144999857, -43.968867649999879],
              [-79.504710186999887, -43.570650322999882],
              [-79.448936573999902, -43.465988112999923],
              [-79.446623573999887, -43.360307112999976],
              [-79.297263573999828, -42.790379111999925],
              [-79.290876594999929, -42.778021318999834],
              [-79.283960440333146, -42.764556969666614],
              [-79.277840747999846, -42.752588742999933],
              [-79.270292299666494, -42.73789312033324],
              [-79.267912869999861, -42.733177441],
              [-79.264612792999969, -42.726740977666566],
              [-78.987665179999851, -42.183971577999898],
              [-78.630456251999874, -41.731103972999847],
              [-78.626169384999855, -41.713336394999885],
              [-78.62263873366652, -41.706177508333212],
              [-78.617382928499865, -41.684585778499923],
              [-78.613944202999903, -41.677607122999916],
              [-78.60880441199987, -41.656252050999854],
              [-78.607882276999874, -41.654378964999978],
              [-78.605251572999975, -41.649036110999873],
              [-78.601552932, -41.633647714],
              [-78.600125550999849, -41.627709649999929],
              [-78.596562207999852, -41.62046511099976],
              [-78.5914491, -41.599167097999896],
              [-78.587874740666564, -41.591893512333229],
              [-78.582774572999881, -41.570624110999859],
              [-78.579189572999837, -41.563322110999763],
              [-78.57652632699984, -41.552199417999873],
              [-78.5741034159999, -41.542081536999831],
              [-78.570507475999932, -41.534751018999827],
              [-78.565434172999801, -41.513538528999845],
              [-78.561827418999826, -41.50617936999987],
              [-78.556767326, -41.484995368999819],
              [-78.554896597666527, -41.481175176666511],
              [-78.553954057999931, -41.477225848999922],
              [-78.552282622999826, -41.470222418999924],
              [-78.550894316999802, -41.467385914999909],
              [-78.548886572999947, -41.463284110999894],
              [-78.547363628499966, -41.456897454499881],
              [-78.540269186999979, -41.442292957999882],
              [-78.538039199999844, -41.432930728999906],
              [-78.537024572999826, -41.428672110999912],
              [-78.529966572999854, -41.414229110999827],
              [-78.528816549999931, -41.409393389999877],
              [-78.526735375999863, -41.400643997999794],
              [-78.522188595999978, -41.391331102999914],
              [-78.519666573, -41.386166110999852],
              [-78.518433276, -41.38097269799988],
              [-78.516448091999962, -41.372615452999923],
              [-78.509369202999835, -41.358102473999907],
              [-78.507493463999879, -41.350195191000012],
              [-78.506162572999813, -41.344586110999899],
              [-78.501825068999835, -41.335684713999967],
              [-78.499073660333181, -41.330038360999879],
              [-78.498831735999886, -41.329017062999924],
              [-78.49588024399992, -41.31655789999985],
              [-78.488780569000028, -41.301974766999933],
              [-78.485599673999843, -41.288528892999921],
              [-78.478489647999879, -41.273910698999828],
              [-78.475321334999819, -41.260499731999914],
              [-78.470320105999917, -41.250207509999825],
              [-78.46820057299999, -41.245846110999935],
              [-78.466833730999838, -41.240051221999863],
              [-78.465045222999947, -41.232470415999785],
              [-78.457914575999951, -41.217782121999846],
              [-78.454771333999929, -41.204440947999899],
              [-78.44763042, -41.189717636999831],
              [-78.444909163999853, -41.1781517739999],
              [-78.444499575999941, -41.176411135999842],
              [-78.437348511999915, -41.161652996999919],
              [-78.434230211999875, -41.148381549999897],
              [-78.429075970999918, -41.1377343609999],
              [-78.427068572999815, -41.133588110999881],
              [-78.423962572999869, -41.12035111099982],
              [-78.421157455999946, -41.114550765999866],
              [-78.41679142199996, -41.105523289999894],
              [-78.415495829999827, -41.099994215999914],
              [-78.413697572999808, -41.092321110999819],
              [-78.411604887999772, -41.087989740999831],
              [-78.40651623299982, -41.077458223999827],
              [-78.403435115999912, -41.064291302999834],
              [-78.396243277999815, -41.04939301499985],
              [-78.393174492999833, -41.0362609149999],
              [-78.388147642333195, -41.025838370999793],
              [-78.386012300999823, -41.016691297999841],
              [-78.355514747999848, -40.952777588999822],
              [-78.350569572999916, -40.942414110999891],
              [-78.296365082999955, -40.594141092999905],
              [-78.267682567999884, -40.409851966999895],
              [-78.193209085999968, -40.218684813999857],
              [-78.13794457299997, -40.076826109999899],
              [-78.137612622999768, -40.074631150999892],
              [-78.137165124999967, -40.071675704999905],
              [-78.065922383999975, -39.88735305799986],
              [-78.047398088999842, -39.763542248999897],
              [-78.046878869999887, -39.762192169499883],
              [-78.044403654999769, -39.746126660999892],
              [-78.037373460000026, -39.727817712999865],
              [-78.021096572999852, -39.685428109999862],
              [-78.020907011999896, -39.68415123349979],
              [-78.015445765999914, -39.669775730999959],
              [-78.010980572999983, -39.658132108999837],
              [-78.005590732999849, -39.621810978999861],
              [-78.001906472999963, -39.596984748999944],
              [-77.973601578999819, -39.522971215666587],
              [-78.004229419000012, -39.422873675999881],
              [-78.14245925799986, -38.971112281999865],
              [-78.145387096499803, -38.932456798999851],
              [-78.14620757299997, -38.929754108999873],
              [-78.147493563999888, -38.912638253499836],
              [-78.158362572999863, -38.876828108999916],
              [-78.183472930999926, -38.540215880999881],
              [-78.201721205999945, -38.295587109999815],
              [-78.201598950499829, -38.294759614999862],
              [-78.201626572999828, -38.293891107999912],
              [-78.142041835999919, -37.886717831999761],
              [-78.116975455999977, -37.715427553999916],
              [-77.909617138999806, -37.157031582999878],
              [-77.82156917899988, -37.014875260999986],
              [-77.812575022999908, -36.95034048999986],
              [-77.806351571999869, -36.905688106999889],
              [-78.337310572999797, -37.008830106999824],
              [-78.343183572999862, -37.009117106999781],
              [-78.350824572999954, -37.010584106999829],
              [-79.075736573999961, -37.043931109999846],
              [-79.615533573999983, -36.99047210699996],
              [-80.230098574999772, -37.100844107999933],
              [-80.956773574999886, -37.124213106999889],
              [-80.958670574999815, -37.124002106999853],
              [-80.96509509499981, -37.124192097999924],
              [-81.033420202999821, -37.116431413999862],
              [-81.684379075999914, -37.042481106999844],
              [-81.698847575999821, -37.040787106999858],
              [-82.386047577, -36.856872106999901],
              [-82.387878576999924, -36.856109106999838],
              [-83.020322576999831, -36.576291107000031],
              [-83.577217077999876, -36.210008105999862],
              [-83.58608357799983, -36.204086105999863],
              [-84.049992577999774, -35.763779105999845],
              [-84.407775725999841, -35.263607430499917],
              [-84.408091095999964, -35.262903152999854],
              [-84.409128626999973, -35.261444310999877],
              [-84.409618578999869, -35.26075510499976],
              [-84.410088578999989, -35.25970510499991],
              [-84.410531980333246, -35.259055988999933],
              [-84.41091457899995, -35.258255104999876],
              [-84.411499793999837, -35.257433253999864],
              [-84.470653733999882, -35.124608837999915],
              [-84.653713068333218, -34.7134201876666],
              [-84.654039971999964, -34.712614154999983],
              [-84.655485578999901, -34.709349104999887],
              [-84.655707374666633, -34.708405367999887],
              [-84.656419493999806, -34.706681092999872],
              [-84.657123078999859, -34.703687604999914],
              [-84.658893553999974, -34.695847287999982],
              [-84.659671578999905, -34.692273104999856],
              [-84.66067357899999, -34.690011104999826],
              [-84.781969578999849, -34.119561104000013],
              [-84.781963528999768, -34.116309083999852],
              [-84.781960347999984, -34.114723704999847],
              [-84.783420987666545, -34.107708043333218],
              [-84.784243437999976, -34.103706778999879],
              [-84.78346733199993, -33.524173399999881],
              [-84.663028317999988, -32.953039909999859],
              [-84.656129201999988, -32.937094398999818],
              [-84.655389578999859, -32.935385102999987],
              [-84.654082578999805, -32.929123102999824],
              [-84.544586647999893, -32.673952049999855],
              [-84.419862581999837, -32.383293443999889],
              [-84.418865387666642, -32.381817500333227],
              [-84.288037528999837, -32.187311505999901],
              [-84.079384784999775, -31.877113884999915],
              [-84.075579934999951, -31.873200735499836],
              [-84.074795707999954, -31.872113371999887],
              [-84.074281441999858, -31.871343124999896],
              [-83.640929726999843, -31.422438444000022],
              [-83.126968886999805, -31.042011470999853],
              [-83.125693685999806, -31.041353677999894],
              [-83.125015255, -31.040847887999973],
              [-82.545586542999843, -30.739607155999977],
              [-81.917174750999948, -30.526000768999836],
              [-81.91437979799997, -30.525500191999924],
              [-81.913303271999894, -30.52513074299992],
              [-81.906531386499807, -30.523917569999867],
              [-81.905582469999842, -30.523604988999864],
              [-81.895859382999959, -30.520266928999888],
              [-81.236039802999812, -30.400163015999908],
              [-80.562866824999787, -30.377044830999921],
              [-80.561329872999806, -30.377220286999886],
              [-80.559008919999883, -30.377134649999903],
              [-80.095223815999873, -30.429248085333256],
              [-80.09371124199987, -30.428703679999899],
              [-80.091959469999836, -30.428073241],
              [-79.435443421999878, -30.299018435999869],
              [-78.763817341999868, -30.266827420999959],
              [-78.761598600999946, -30.267050832999871],
              [-78.758987285999979, -30.266918987999986],
              [-78.758079020999787, -30.267010415999891],
              [-78.75416, -30.266812319999872],
              [-78.752277519999836, -30.267001537999874],
              [-78.748356504999947, -30.266803272],
              [-78.080008973999952, -30.332329838999897],
              [-78.077405630999863, -30.332987375999949],
              [-78.075196708999954, -30.33319826799999],
              [-77.428454271999954, -30.494726994999894],
              [-77.42662399399984, -30.495473291999872],
              [-77.42538381799983, -30.495971639999937],
              [-77.420650686000045, -30.497140365999883],
              [-77.419739076999861, -30.4975164734999],
              [-77.364441921999912, -30.520721406999826],
              [-77.358331462999956, -30.522226849999868],
              [-76.750626679999812, -30.775129413999935],
              [-76.74884784499983, -30.775897301999976],
              [-76.197947901999839, -31.113286024999923],
              [-75.719704081000032, -31.525829821999821],
              [-75.719139136999956, -31.526521667999873],
              [-75.71766895899998, -31.52778183099997],
              [-75.714216706999764, -31.532009110999923],
              [-75.710588505999908, -31.53511848699992],
              [-75.699030241999822, -31.549266796999916],
              [-75.698364872999889, -31.549836787999823],
              [-75.524380941666607, -31.762179992666518],
              [-75.578966569999807, -31.222787101999927],
              [-75.578871060499864, -31.2213501949999],
              [-75.577972606499884, -31.212186458999838],
              [-75.578221222999957, -31.209716440999898],
              [-75.579092071999924, -31.201062034999893],
              [-75.579043676333242, -31.200229081666578],
              [-75.572476153999787, -31.136615478999943],
              [-75.572404606499788, -31.135926445499877],
              [-75.578887245999852, -31.07131227199983],
              [-75.600992081999891, -30.850985104999836],
              [-75.600880817999894, -30.849799377999886],
              [-75.600920089999931, -30.849184896999873],
              [-75.587666570999829, -30.721264121999866],
              [-75.589938074999793, -30.698554996999974],
              [-75.589841208666542, -30.697290355999939],
              [-75.589571752999859, -30.69402950899979],
              [-75.588130569999805, -30.680101100999821],
              [-75.591017028749889, -30.650608658499834],
              [-75.531546569999875, -30.071782100999826],
              [-75.520841605999976, -30.037321610999868],
              [-75.51785952900002, -30.027722094999945],
              [-75.509915571999898, -29.949789167999882],
              [-75.498704388999897, -29.913631173999818],
              [-75.496104455999841, -29.888058346999912],
              [-75.495299581999859, -29.880142218999879],
              [-75.494238193999934, -29.876716096999814],
              [-75.494146099499858, -29.875811153499853],
              [-75.492073569999889, -29.86895909999987],
              [-75.491735569999889, -29.865630099999976],
              [-75.489758569999822, -29.859247099999948],
              [-75.488065785999879, -29.842569450999861],
              [-75.486145267999916, -29.82365175599989],
              [-75.485472481999977, -29.821478114999877],
              [-75.485046015999842, -29.81986293366657],
              [-75.477231942999879, -29.794413458999884],
              [-75.471329569999966, -29.775335099999893],
              [-75.47089548599979, -29.771046659999911],
              [-75.469813147999957, -29.760359001999859],
              [-75.398660306999943, -29.529500162999966],
              [-75.377308569999968, -29.4602241],
              [-75.372482569999988, -29.411957099999881],
              [-75.40429857, -29.095738099999835],
              [-75.404120569999861, -29.093955099999846],
              [-75.404202520999945, -29.093138590999786],
              [-75.404092184999911, -29.0920345319999],
              [-75.403811570000016, -29.089229099999898],
              [-75.403954990499869, -29.087802516499835],
              [-75.403833347999864, -29.085455266999929],
              [-75.403894569999864, -29.084840099999894],
              [-75.346411569999987, -28.506373098999916],
              [-75.176709600999857, -27.945273199999917],
              [-74.901506126999777, -27.418174373999875],
              [-74.900929463999944, -27.417436357999861],
              [-74.900494150999805, -27.416598059999842],
              [-74.898868099999959, -27.414516930999866],
              [-74.898416521999764, -27.413647247999961],
              [-74.862668552999821, -27.367868780999856],
              [-74.823666700999979, -27.292608976999873],
              [-74.818793355999958, -27.286355924999839],
              [-74.814998375999835, -27.279025651999859],
              [-74.741804353999783, -27.030675892999852],
              [-74.74089515799983, -27.028912854999817],
              [-74.703757144999884, -26.902428739999891],
              [-74.696094597999775, -26.876332191999893],
              [-74.695755804999862, -26.875673599999857],
              [-74.695510506999767, -26.874836812999888],
              [-74.694989301999897, -26.873059135999881],
              [-74.687639178999973, -26.858770117999882],
              [-74.687171566999837, -26.857861072499816],
              [-74.670242549999955, -26.672208712999918],
              [-74.66692001624989, -26.660833808499888],
              [-74.666861344999973, -26.660189066999862],
              [-74.503347618999953, -26.0980952889999],
              [-74.434263728333235, -25.962169503333257],
              [-74.444903601666567, -25.861260408499845],
              [-74.444863953374863, -25.860299426499921],
              [-74.404397466999967, -25.400744059999909],
              [-74.393819568999845, -25.280739095999763],
              [-74.352679661000025, -25.136519437999937],
              [-74.249459414999819, -24.774672278999901],
              [-74.245625184, -24.730184857999859],
              [-74.238650568999788, -24.649264095999911],
              [-74.240295566999919, -24.633761105999781],
              [-74.239940485000034, -24.62963772199987],
              [-74.240118402999826, -24.627960347999874],
              [-74.240078764333191, -24.627302907333274],
              [-74.240056379666612, -24.626354594666566],
              [-74.228193007999892, -24.487918434999926],
              [-74.199583568999913, -24.154583094999907],
              [-74.258338568999989, -23.596488094999756],
              [-74.258181568999873, -23.594660094999824],
              [-74.258262568999896, -23.593888094999841],
              [-74.25801839059983, -23.59091843659985],
              [-74.258140216999891, -23.589687236999907],
              [-74.257941117999849, -23.587365563999924],
              [-74.258021196999891, -23.586599262999812],
              [-74.245291208999845, -23.437092974999928],
              [-74.208524163999982, -23.006668964999847],
              [-74.203721407999922, -22.989505488999953],
              [-74.202198568999904, -22.984064093999905],
              [-74.202038323999886, -22.982176871999911],
              [-74.201727195999808, -22.978517667999824],
              [-74.195760483999948, -22.957184655999924],
              [-74.193569568999862, -22.949352093999835],
              [-74.193284606999839, -22.945994768999867],
              [-74.192598120999889, -22.93791265699997],
              [-74.192334987999857, -22.936971959999767],
              [-74.186694568999826, -22.870885093999959],
              [-74.185732724999923, -22.867441984999914],
              [-74.183532491999927, -22.859566683999901],
              [-74.181336207999834, -22.833656644999849],
              [-74.174292569000045, -22.750566093999851],
              [-74.169373529999916, -22.732935403333158],
              [-74.169274911999935, -22.732057902999884],
              [-74.168532375000041, -22.723286420999841],
              [-74.156128094, -22.678803652999875],
              [-74.150435404999882, -22.611430479999967],
              [-74.149656291999861, -22.608633625999914],
              [-74.148768207999922, -22.598116070999936],
              [-74.14769156899979, -22.585370093999757],
              [-74.055731683999966, -22.253727071999833],
              [-73.991428521999921, -22.021825717999945],
              [-73.745249238999861, -21.514609722999921],
              [-73.736869074999845, -21.411032449999823],
              [-73.725920349999967, -21.370898490999764],
              [-73.775118390999978, -20.90586309299988],
              [-73.759800181999935, -20.716148925999903],
              [-73.728226568999958, -20.325118090999865],
              [-73.725385394999932, -20.314626601999919],
              [-73.722981527, -20.305750678999857],
              [-73.720805410999844, -20.278684919999876],
              [-73.715322439199952, -20.210491349799952],
              [-73.658278808999967, -20.24889028299998],
              [-73.538336670999968, -20.329628975999981],
              [-72.468660304999958, -21.049678437999944],
              [-70.400572015249907, -18.38400025149997],
              [-70.38708831699995, -18.365433206999967],
              [-70.402785496999854, -18.351028077000024],
              [-70.405490173012879, -18.348545724588064],
              [-70.390017830175452, -18.335282028814277],
              [-70.367090977993541, -18.322781928935797],
              [-70.327790916103254, -18.319446099100674],
              [-70.249173022687387, -18.327781901831941],
              [-70.200835760406108, -18.330281888280012],
              [-70.178073025889034, -18.32889099526318],
              [-70.132508832461838, -18.32125508144901],
              [-69.9908459512219, -18.270146091417871],
              [-69.958481914228344, -18.249445975639958],
              [-69.867782010265785, -18.157781985171255],
              [-69.844590960495168, -18.132364031358804],
              [-69.796672961011836, -18.039717849473391],
              [-69.786390880357885, -18.015281919439758],
              [-69.77444599774708, -17.985281914424547],
              [-69.769590864138195, -17.952573045933548],
              [-69.8124998382699, -17.801108870006942],
              [-69.82167299313079, -17.782499871438972],
              [-69.838063972474174, -17.767499952750484],
              [-69.849726887861323, -17.749235953318191],
              [-69.846117807981955, -17.708335953745177],
              [-69.834872981926367, -17.68139098480799],
              [-69.814726909949798, -17.652226823179717],
              [-69.77028186823793, -17.656946002318648],
              [-69.7341729645334, -17.659999864930228],
              [-69.683899817889198, -17.663608944809653],
              [-69.630008874186274, -17.617499927674658],
              [-69.499726773471593, -17.505282001819921],
              [-69.487226841231063, -17.632917935694707],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CHL", Country: "Chile" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.606387765000022, -23.119366093999901],
            [-105.544153763999887, -23.123550093999881],
            [-105.522716763999952, -23.121532093999889],
            [-104.893090763999865, -23.162218093999883],
            [-104.888753763999844, -23.16320509399992],
            [-104.887249763999918, -23.163298093999899],
            [-104.273814763000019, -23.301257093999894],
            [-103.693892763, -23.532600093999903],
            [-103.690084762999973, -23.534900093999866],
            [-103.688970762999901, -23.535341093999847],
            [-103.15909176199996, -23.853479094999855],
            [-102.694686761999904, -24.249370094999904],
            [-102.309633760999887, -24.711670095999963],
            [-102.015908760999935, -25.227025095999892],
            [-102.015481760999947, -25.228260095999843],
            [-102.013811760999886, -25.231173095999907],
            [-101.821101760999923, -25.78454809699987],
            [-101.736508760999868, -26.359616096999886],
            [-101.764158760999862, -26.939173097999898],
            [-101.904922760999966, -27.50562609799988],
            [-102.156186760999958, -28.041506098999861],
            [-102.159019260999926, -28.045483598999795],
            [-102.51465776199997, -28.534179098999871],
            [-102.964585761999899, -28.959758099999902],
            [-103.495516762999841, -29.308655099999925],
            [-104.090913762999861, -29.569441099999864],
            [-104.731612763999891, -29.733473099999955],
            [-105.396540763999852, -29.795266099999836],
            [-106.063575764999868, -29.752728099999899],
            [-106.084102764999912, -29.74816409999984],
            [-106.159395765, -29.743152100000017],
            [-106.73737976599989, -29.612915099999896],
            [-106.81806376599998, -29.683870099999879],
            [-107.365079765999965, -30.018438100999859],
            [-107.550862765999923, -30.093481100999952],
            [-107.554882766, -30.095914100999906],
            [-108.164591766999905, -30.339696100999902],
            [-108.166882766999834, -30.340215100999856],
            [-108.167662766999982, -30.340524100999929],
            [-108.81924776799994, -30.48615210099986],
            [-108.828810768, -30.486781100999863],
            [-108.849146767999855, -30.491270100999841],
            [-109.521081768000045, -30.533769100999933],
            [-109.521924767999977, -30.53369310099994],
            [-109.523144767999852, -30.533768100999936],
            [-110.192912768999875, -30.471866100999904],
            [-110.838181768999846, -30.307670100999843],
            [-111.437686769999857, -30.046683100999886],
            [-111.972103769999933, -29.697567099999858],
            [-112.42477877099995, -29.271773099999891],
            [-112.425615770999926, -29.270699099999945],
            [-112.783244771, -28.781860098999886],
            [-113.035615770999925, -28.245844098999854],
            [-113.176655771999847, -27.67931509799989],
            [-113.203784771999906, -27.099560764666563],
            [-113.117970772, -26.52433809699987],
            [-113.114634771999931, -26.514908096999889],
            [-113.112161772000036, -26.498198096999928],
            [-112.917695770999913, -25.944928096999845],
            [-112.913797770999977, -25.938181096999912],
            [-112.912427770999855, -25.93426009699985],
            [-112.911315770999863, -25.932335096999907],
            [-112.907076770999964, -25.92019509699989],
            [-112.897506770999954, -25.90362309699988],
            [-112.889037770999906, -25.879349096999874],
            [-112.59335577099985, -25.364070095999935],
            [-112.589191770999847, -25.359130095999845],
            [-112.575865770999826, -25.335765095999832],
            [-112.574179770999947, -25.333764095999911],
            [-112.569698771, -25.325904095999888],
            [-112.56369777099988, -25.318720595999892],
            [-112.176476770999884, -24.856233095999883],
            [-112.175495770999987, -24.855405096000027],
            [-112.174468770999923, -24.854172095999886],
            [-111.707702769999926, -24.458243094999872],
            [-111.706862769999887, -24.457745094999879],
            [-111.702733769999838, -24.454222094999977],
            [-111.170282769999986, -24.135994094999901],
            [-111.168326769999879, -24.135221094999892],
            [-110.585630768999948, -23.903757094999904],
            [-110.581741768999862, -23.902892094999928],
            [-109.965409768999976, -23.764746094999907],
            [-109.959365768999945, -23.764369094999893],
            [-109.957629769, -23.763975094999978],
            [-109.324844767999934, -23.723070094999883],
            [-109.32406776799985, -23.723051094999917],
            [-108.692943766999988, -23.780548094999887],
            [-108.677674766999871, -23.784435094999893],
            [-108.666651766999905, -23.785413094999896],
            [-108.054645767000011, -23.939625094999883],
            [-108.052857766999864, -23.940366094999902],
            [-108.050310766999885, -23.941434094999906],
            [-108.047795766999982, -23.942062094999898],
            [-108.035555766999863, -23.947342094999883],
            [-108.024323766999913, -23.950143094999902],
            [-108.015417767000031, -23.953983094999927],
            [-107.991937766999911, -23.959832094999911],
            [-107.937402766999881, -23.910332094999916],
            [-107.417387765999933, -23.577968094999861],
            [-106.844620765999878, -23.331154093999913],
            [-106.235533765000014, -23.176874093999899],
            [-106.234578264999911, -23.176784593999869],
            [-105.606387765000022, -23.119366093999901],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CHN", Country: "China" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [130.604372403691826, 42.421864144016482],
            [130.586491289597717, 42.42714507830793],
            [130.567472415963408, 42.443045045762076],
            [130.5224912677227, 42.537218075134049],
            [130.514163343980641, 42.568054091174375],
            [130.376618324144005, 42.61749105915537],
            [130.328445179527279, 42.641800087174616],
            [130.246782308317421, 42.714154016691722],
            [130.23621826043987, 42.786245089724602],
            [130.243291245635106, 42.811664217003383],
            [130.250272365171412, 42.831664108588186],
            [130.251418338976777, 42.88794506816923],
            [130.193163441192524, 42.906653979023176],
            [130.034145158826931, 42.958045103916035],
            [129.947554231450368, 42.995400061350168],
            [129.929218315288693, 43.006100063697701],
            [129.904591277862124, 43.004573216211028],
            [129.877672292824712, 42.991791149108948],
            [129.847045153812161, 42.942482088970749],
            [129.807463292336223, 42.853609104629172],
            [129.75330932451098, 42.713882107752099],
            [129.725509233809333, 42.547491103332746],
            [129.732882291139248, 42.522218156445476],
            [129.728291355119126, 42.475400197938043],
            [129.71662726626576, 42.452491115391055],
            [129.695527166067137, 42.435818168823928],
            [129.514709229861779, 42.386936082834652],
            [129.445118310915319, 42.432773191029852],
            [129.3492724213028, 42.44623603630977],
            [129.246063365006222, 42.375827043611039],
            [129.209409302317539, 42.26582713698069],
            [129.211209232210393, 42.221309172711059],
            [129.152463322536704, 42.168054080184717],
            [129.114409314375763, 42.139436083368949],
            [129.016691242864823, 42.094991209295173],
            [129.01410022894791, 42.09419107281569],
            [128.958082293489355, 42.078400070103342],
            [128.949263357857632, 42.052073182707872],
            [128.926927262213297, 42.027354111984224],
            [128.895536196543247, 42.022764181792482],
            [128.844972365496261, 42.029718143962398],
            [128.683091327912081, 42.021027116173585],
            [128.570254147049042, 42.000273188576998],
            [128.497054148222929, 41.995545124620563],
            [128.472882248140053, 41.997627021737046],
            [128.44670036766999, 42.012773120817585],
            [128.422482367119443, 42.028600165713655],
            [128.361354152918494, 42.037500070530157],
            [128.336091264315229, 42.039718089754714],
            [128.275527319837664, 42.038327029099875],
            [128.056636264732305, 42.001244986433946],
            [128.050109276711424, 41.98164507926974],
            [128.060791341786199, 41.910545082471188],
            [128.068845177293696, 41.884991174188897],
            [128.098436145432885, 41.803044994651017],
            [128.155672306702428, 41.714573168196708],
            [128.203854168498395, 41.676382199737546],
            [128.226627296575572, 41.663882099858981],
            [128.254272322067919, 41.650409028657279],
            [128.269436190783182, 41.636936125093357],
            [128.300245217095011, 41.582836136724765],
            [128.282200320612475, 41.530273221764716],
            [128.222472387159854, 41.441100165288887],
            [128.192672374354999, 41.407491080394195],
            [128.152909296131867, 41.381236109728277],
            [128.114336280525009, 41.371791213565686],
            [128.04412728003652, 41.389436125627739],
            [128.011654110663386, 41.415827050763639],
            [127.92802719189612, 41.447345018448104],
            [127.871091270399091, 41.424164194599385],
            [127.835272380545206, 41.409991066842267],
            [127.694154155375003, 41.413127072105212],
            [127.620818202079278, 41.418053954805416],
            [127.270827307783037, 41.47249106332076],
            [127.159418235586855, 41.529991086903109],
            [127.134991358008705, 41.593609061630062],
            [127.100809286211557, 41.621373110147985],
            [127.057036305498087, 41.643500161126482],
            [127.060809335403945, 41.691100151202491],
            [127.048027268301922, 41.708327141570933],
            [126.927618211365001, 41.796109137392008],
            [126.904709296456105, 41.792218090288983],
            [126.868863416873893, 41.761382074248942],
            [126.847900276973661, 41.73582699249998],
            [126.795536180757296, 41.708045006709241],
            [126.712772430381534, 41.691100151202491],
            [126.645536155681526, 41.66054509655747],
            [126.566082251240715, 41.611764263958761],
            [126.568045125331963, 41.589718014527236],
            [126.583182339594913, 41.570827048735552],
            [126.510272354619616, 41.389991175257549],
            [126.4840002848712, 41.366727035291191],
            [126.456100281883465, 41.357218101388057],
            [126.437763359893296, 41.3424910974678],
            [126.411100358179766, 41.319164260865293],
            [126.366927225407466, 41.275691184648181],
            [126.309982251455011, 41.214436068433017],
            [126.286227267237194, 41.17262713527704],
            [126.279163334497383, 41.152773088807848],
            [126.124691228788862, 41.034718173203643],
            [126.011791351289759, 40.896936113144463],
            [125.93254515041022, 40.877627058021389],
            [125.703109325804718, 40.859973093503939],
            [125.688582314094702, 40.852145066468452],
            [125.649845180823064, 40.802600139574182],
            [125.603863400978611, 40.755827108067777],
            [125.553036210532895, 40.741382071371035],
            [125.457491231245314, 40.70332705738177],
            [125.412691299751799, 40.653800067760315],
            [125.369136248521414, 40.639991217516339],
            [125.314991333151767, 40.649991163308655],
            [125.286100253929789, 40.647491176860669],
            [125.140545152120268, 40.585264262788471],
            [125.042763378144883, 40.537500155047653],
            [125.024709261568745, 40.49416420676711],
            [125.039982262663585, 40.460682191524981],
            [124.96220021263494, 40.459164228855641],
            [124.885127271616881, 40.470373180365456],
            [124.812472431774864, 40.396664232377347],
            [124.710991221003638, 40.307009048829386],
            [124.688718324909502, 40.296800058371318],
            [124.626927269803645, 40.283609122031024],
            [124.554700242301038, 40.242218110568658],
            [124.410809283552481, 40.130273099481954],
            [124.381363322338785, 40.102218031285204],
            [124.373600120183454, 40.093617845023303],
            [124.370194450000241, 40.081266965000154],
            [124.368820751500124, 40.0675331475001],
            [124.368459616000138, 40.063920540000012],
            [124.376265616000211, 40.03616654000011],
            [124.373663450000123, 40.012749965],
            [124.360887403993473, 39.99145712760496],
            [124.355449533000154, 39.982394252500072],
            [124.327696033000194, 39.944232752500042],
            [124.315204595415338, 39.929243369321725],
            [124.301676450000201, 39.913009965000171],
            [124.284696307000246, 39.893651015000145],
            [124.281777885000196, 39.890323794000082],
            [124.236101528000205, 39.844647392],
            [124.235275498000163, 39.842995359],
            [124.220876022000226, 39.814196407999972],
            [124.205650544000179, 39.768519971999964],
            [124.194231435000091, 39.692392580000032],
            [124.186618696000124, 39.585814230999986],
            [124.148555000000187, 39.365044793000081],
            [124.106684934000185, 39.110018029000045],
            [124.071747096000166, 38.946520818999986],
            [123.861888786000037, 38.545718821000023],
            [123.825871593000244, 38.476590175000169],
            [123.742038392000183, 38.395244135],
            [123.714893387000217, 38.368932246000142],
            [123.569518193000164, 38.227253603000136],
            [123.547708292000181, 38.20473401200006],
            [123.460927018000092, 38.130556968000022],
            [123.532801275000082, 37.96488066600007],
            [123.576543832000226, 37.863404544],
            [123.614370689000197, 37.774231594999989],
            [123.63812344700014, 37.723000267000018],
            [123.66243373100022, 37.670212303000127],
            [123.752446904000209, 37.401610901000126],
            [123.753996463000163, 37.396997090000113],
            [123.75541612900011, 37.392836411000118],
            [123.755999859000184, 37.391131944000037],
            [123.756569595000173, 37.389475277000102],
            [123.756984856000059, 37.38827197900001],
            [123.757409482000213, 37.387044757],
            [123.775090811000069, 37.33603196100016],
            [123.789633640000119, 37.29124089000014],
            [123.873433012000163, 36.987731903000039],
            [123.897481822000174, 36.928682268000031],
            [124.007629568000226, 36.65977213799998],
            [124.017495082000181, 36.63518751200003],
            [124.012276900000103, 36.60241794800011],
            [124.01210942100019, 36.60136708600011],
            [124.011941023000105, 36.60031621200001],
            [123.91351906800017, 35.98182161600009],
            [123.891183105000124, 35.835507737999976],
            [123.595783853000199, 35.598536902999982],
            [123.132844136000045, 35.2232763680001],
            [123.100334679000156, 35.169879227000067],
            [122.804078126000064, 34.694877995999988],
            [122.782247458000171, 34.659587687000041],
            [122.74728080800017, 34.618688680000091],
            [122.71425142700005, 34.565633189000081],
            [122.724498094000097, 34.484706662000079],
            [122.773714642000158, 34.160465704000146],
            [122.785841519000172, 34.102348581000101],
            [122.786069492000109, 34.101254571000098],
            [122.819443270000221, 33.940327346000132],
            [122.833599945000145, 33.904482426000115],
            [122.909256902000124, 33.704360842000014],
            [122.914602114000189, 33.696137603000054],
            [122.91549450100004, 33.694773003000037],
            [122.987724373000248, 33.584411270000018],
            [123.017699651000186, 33.53866895200018],
            [123.153975683000198, 33.327159631000129],
            [123.157067374000036, 33.322372868000016],
            [123.392051327000189, 33.049640084000046],
            [123.624663680000168, 32.797365277000083],
            [123.73333038000024, 32.68060918400009],
            [124.002029652000175, 32.474185776000027],
            [124.217299632000078, 32.307832214000072],
            [124.224756445000111, 32.301954714000132],
            [124.271671787000145, 32.255070556000035],
            [124.300957888000227, 32.224564201000035],
            [124.416815941000181, 32.11865630400014],
            [124.617354551000147, 31.907277769000061],
            [124.779953424000126, 31.739258933000073],
            [124.888352673000185, 31.614599796999968],
            [125.034691659000174, 31.462840848999988],
            [125.218970382000094, 31.294822013],
            [125.489968504000245, 31.072603553],
            [125.925000242800166, 30.769999409600061],
            [126.013333333000247, 30.555],
            [126.091666667000169, 30.303333333000111],
            [126.245706739000155, 30.133139870000136],
            [126.244728308000191, 30.131468955999978],
            [126.244094565000154, 30.130384663000072],
            [126.243144687000182, 30.128759152000143],
            [126.242080869000262, 30.126936284000024],
            [125.966239247000175, 29.651548278000021],
            [125.431288292000175, 28.716770702],
            [125.41372242800017, 28.703264888000135],
            [125.344124013000084, 28.649664241000139],
            [125.133020238000114, 28.488659590000069],
            [125.024977916000154, 28.341931638999981],
            [124.904928185000045, 28.271609413000036],
            [124.567597132000145, 28.074009051000118],
            [124.212755313000144, 27.864655727000113],
            [124.202267406000118, 27.85846796],
            [123.761740917000196, 27.700330589000103],
            [123.67925072600022, 27.654624029],
            [123.365423965000247, 27.480140679],
            [123.33884243300011, 27.465351371999972],
            [123.068496092000061, 27.314263702000133],
            [122.997724824000187, 27.274376955999983],
            [122.84009794700026, 27.185372163000025],
            [122.838520429000113, 27.184339931000167],
            [122.749922781000095, 27.11427843100013],
            [122.607522531000171, 26.950310319],
            [122.446578174000109, 26.896015637000133],
            [122.078487258000195, 26.745730407000153],
            [122.00994212700013, 26.717625099000045],
            [121.960284439000162, 26.683875384000103],
            [121.615304255000211, 26.450057121000114],
            [121.510718202000049, 26.378980928000132],
            [121.506824428000215, 26.372418656000022],
            [121.353969851000187, 26.11354234100007],
            [121.333497405000202, 26.078848950000136],
            [121.210198720000136, 25.984348273000094],
            [121.175478560000187, 25.95856751200013],
            [121.033788000000158, 25.812237379000081],
            [121.016721722000142, 25.795493576000084],
            [120.936665780000141, 25.72038757300011],
            [120.879883183000089, 25.685066792000114],
            [120.857361796000163, 25.672995144000069],
            [120.786041772000175, 25.635513381000052],
            [120.721973089000045, 25.602592263000091],
            [120.710279005000103, 25.583059350000084],
            [120.708211573000114, 25.579599318000149],
            [120.694090456000055, 25.557668417000102],
            [120.66575008300012, 25.513893100000118],
            [120.65276101900011, 25.494036880000166],
            [120.579562526000046, 25.382756279000048],
            [120.566275311000112, 25.36316937000008],
            [120.562413645000191, 25.357220888000043],
            [120.557658522000196, 25.346226329000032],
            [120.551338772000094, 25.331731358000113],
            [120.535350370000145, 25.295661667000061],
            [120.527219736000035, 25.277730970000064],
            [120.502714660000066, 25.224171651000134],
            [120.470400317000127, 25.168309374000145],
            [120.453214522000081, 25.13871323400015],
            [120.410089740000188, 25.079265933000087],
            [120.403013553000193, 25.069776029000039],
            [120.401667441000171, 25.067977836000111],
            [120.379395276000167, 25.038336314000091],
            [120.337671486000175, 25.0009546000001],
            [120.244883453000085, 24.92927279600012],
            [120.212112113000188, 24.903994062000024],
            [120.180823835000155, 24.879798225000101],
            [120.149882171000144, 24.844809890000178],
            [120.086084892000059, 24.774732934000085],
            [120.07776943550013, 24.766010090500046],
            [120.04661123200006, 24.715377821000132],
            [119.977441085000095, 24.611744498000107],
            [119.946071419000162, 24.564278827000052],
            [119.902470401000102, 24.509679992000073],
            [119.879123248000127, 24.484295055000061],
            [119.838021062000081, 24.445232462000106],
            [119.816607412000138, 24.424927529000044],
            [119.78911980500007, 24.403118541000069],
            [119.717972634000176, 24.397333346000053],
            [119.592591034000151, 24.38244524800011],
            [119.328765912000193, 24.350969334000084],
            [119.27800996900018, 24.327867097000066],
            [119.074531446000066, 24.132900608000071],
            [119.046781407000168, 24.10739078500005],
            [119.046125416000137, 24.106785277000043],
            [118.989452131000036, 24.029999189000065],
            [118.958040457000067, 23.99138374900015],
            [118.879340406000182, 23.906060716000169],
            [118.877882949000224, 23.904473564000128],
            [118.865273893000193, 23.891222906000124],
            [118.853414716000117, 23.883306742000073],
            [118.689564329000149, 23.685071751000109],
            [118.503761880000098, 23.462422298000078],
            [118.478804824000036, 23.40221293200004],
            [118.47818151400017, 23.400709632000101],
            [118.449930951000141, 23.332885665000092],
            [118.44602364000005, 23.3246759230001],
            [118.439459696000171, 23.310903071000055],
            [118.432601262000134, 23.29231713800003],
            [118.318334088000114, 22.955758444000011],
            [118.328066631000155, 22.571504669000134],
            [118.323469858000152, 22.479882768000081],
            [118.320570962000119, 22.413782509000058],
            [118.304119133000171, 22.04304957000015],
            [118.301129376000091, 21.73044683500008],
            [118.191846795000089, 21.752204945000116],
            [118.054835586000166, 21.779483811000048],
            [117.921690889000075, 21.80530246400015],
            [117.451784123000067, 21.896277038000093],
            [117.393139387000105, 21.89155960500004],
            [117.063510609000133, 21.865260152000147],
            [117.003735577000072, 21.860487248000126],
            [116.75042815000009, 21.840403423000069],
            [116.676961452000143, 21.834389108000082],
            [116.570073291000227, 21.807941624],
            [116.345475256000128, 21.752007277000118],
            [116.202871867000198, 21.716502858000112],
            [115.570996765000103, 21.396959933000133],
            [115.491423336000054, 21.356304977000022],
            [115.470459494000096, 21.32412952600005],
            [115.114111498000142, 20.764180354000146],
            [115.088111779000172, 20.708442767000079],
            [115.087245173000071, 20.706584854000013],
            [114.887922077000127, 20.279208633000152],
            [114.77880898100014, 20.039002396000072],
            [114.69775033300013, 19.859903555000088],
            [114.32454930600008, 19.02791994],
            [114.07325622800019, 19.118174266000139],
            [113.882374319000149, 19.100117237],
            [113.355687079000148, 18.944830196000154],
            [113.11241911100015, 18.874674977999987],
            [112.73852431300017, 18.755624822000087],
            [112.482228052000181, 18.651436095000136],
            [112.265488677000036, 18.562840003000062],
            [112.251948244000033, 18.55730763199999],
            [112.205000106000142, 18.537993226000097],
            [112.165135901000127, 18.52159314100011],
            [112.142750447000054, 18.512383793000097],
            [112.117616369000217, 18.50204366500013],
            [111.984023773000075, 18.391985908999956],
            [111.820900726000076, 18.256567451],
            [111.814820204000199, 18.25102494299999],
            [111.737469811000068, 18.180308071999988],
            [111.644731766000206, 18.09554159200006],
            [111.342344226000108, 17.818743254000069],
            [111.180256842000148, 17.668813609000111],
            [111.150333324000172, 17.653179520999984],
            [111.121532503000168, 17.638089269000105],
            [111.101360849000031, 17.627520287000024],
            [111.06532034000017, 17.608818661000029],
            [111.000707393000113, 17.575290633000051],
            [110.975028369000114, 17.556104829999967],
            [110.748142495000053, 17.397499465],
            [110.724159775000231, 17.379149659000163],
            [110.70068455200007, 17.361188153000128],
            [110.679606975000212, 17.346734970000014],
            [110.635856191000045, 17.316734458000028],
            [110.560897964000134, 17.26523384399998],
            [110.534024277000213, 17.246801735000176],
            [109.950928580000067, 16.701448136000167],
            [109.917355602000185, 16.71474051899996],
            [109.597126602000031, 16.826445519000103],
            [109.286839602000015, 16.959631519000069],
            [109.15142260200011, 17.036377519000027],
            [109.085024602000118, 17.067770519],
            [108.98524660200016, 17.102736519000146],
            [108.851065601000215, 17.158175519000011],
            [108.761516601000181, 17.195315519000061],
            [108.596171601000179, 17.281199519],
            [108.548738601000224, 17.305767518999986],
            [108.470994601000115, 17.338199519000156],
            [108.46291660100016, 17.341569519],
            [108.442952554000186, 17.366958825000111],
            [107.652500000000174, 18.070277779999955],
            [107.626111100000088, 18.118888890000036],
            [107.566666700000184, 18.230277780000037],
            [107.159444400000126, 18.714444440000037],
            [107.159444400000126, 19.215277780000051],
            [107.189722200000148, 19.267777780000117],
            [107.211944400000021, 19.423888890000015],
            [107.350000000000108, 19.479444440000023],
            [107.527777800000109, 19.659166669999976],
            [107.9297222000001, 19.959166669999988],
            [108.37916670000007, 20.401388890000149],
            [108.20861110000007, 21.20972222000016],
            [108.134722200000141, 21.275555560000029],
            [108.095472200000046, 21.452277780000102],
            [108.094111100000219, 21.456416670000166],
            [108.094416700000096, 21.457833329999957],
            [108.097638900000078, 21.46097222],
            [108.099361100000095, 21.463916670000046],
            [108.100444400000043, 21.467138890000015],
            [108.101194400000196, 21.47013889],
            [108.049705692000231, 21.526718250000087],
            [107.9946942830002, 21.543049762000081],
            [107.990018396823217, 21.542409344101102],
            [107.968882217901893, 21.535691069788228],
            [107.947482380844832, 21.544444961851113],
            [107.916654243793715, 21.588891177029538],
            [107.860818195634494, 21.647773208811131],
            [107.783054250516642, 21.66693608354241],
            [107.756100229123859, 21.656244966012295],
            [107.738309304308274, 21.644718005095157],
            [107.710118281641513, 21.627218099958426],
            [107.660263391966993, 21.61000016204558],
            [107.531791279428916, 21.58638213812587],
            [107.384718214950141, 21.598327020736534],
            [107.362727286079547, 21.605264219100022],
            [107.348327176383947, 21.662218077869781],
            [107.340400242890695, 21.681382126067561],
            [107.298591309734803, 21.729373215746861],
            [107.268600189537011, 21.724164198184852],
            [107.253391226182686, 21.710200115093684],
            [107.213609204858159, 21.711518085552669],
            [107.02401826585097, 21.820618111055538],
            [107.015827302406848, 21.849854021774988],
            [107.042491309948929, 21.889509141084929],
            [107.044009272618183, 21.921382166189048],
            [107.021100190071394, 21.939164206187385],
            [106.901654213812066, 21.970553930752871],
            [106.863309185972099, 21.979164156994912],
            [106.737491286901133, 21.99971809238103],
            [106.693309269311413, 22.030827023189389],
            [106.66859120441606, 22.174718149575952],
            [106.670054181800452, 22.204164110789563],
            [106.692200343518181, 22.216318038065978],
            [106.648736152118573, 22.328818098782492],
            [106.618600192633636, 22.328745008586552],
            [106.586654244971697, 22.335418009361163],
            [106.564982331337006, 22.349300117439256],
            [106.55712714693513, 22.41495405645334],
            [106.553309190028045, 22.431382083808771],
            [106.551709252345432, 22.456818142532242],
            [106.607127211173037, 22.604927042608395],
            [106.631436239192368, 22.615064116336995],
            [106.717482342860848, 22.630554041086626],
            [106.787491351139153, 22.763882125423763],
            [106.778318196278207, 22.803053944195383],
            [106.757491346123771, 22.821945077624932],
            [106.7029003458658, 22.866945001328716],
            [106.592482349904031, 22.913609068093436],
            [106.521963386635122, 22.932673204005127],
            [106.500263309805547, 22.905691187055666],
            [106.473600308092017, 22.89055397279283],
            [106.341236142622535, 22.853327090839585],
            [106.294291282100602, 22.857082183472599],
            [106.256136188187071, 22.87214496643513],
            [106.252063421422889, 22.90416417193093],
            [106.23749131507364, 22.938609100212474],
            [106.223591269722732, 22.955553955719225],
            [106.195609291721809, 22.973882160530039],
            [106.134718285381354, 22.988609164450494],
            [106.077491344205299, 22.990827016036789],
            [106.014845167335096, 22.982491045667516],
            [106.005691290851615, 22.952809050059656],
            [105.991363433161638, 22.938191178519062],
            [105.877063274914264, 22.912527132028615],
            [105.835818276205458, 22.956382087755415],
            [105.788882300500831, 23.004718008932301],
            [105.768600274054421, 23.020136016952264],
            [105.639263316744547, 23.062909036614158],
            [105.573909282226879, 23.063327125945776],
            [105.565691329054545, 23.096782151459465],
            [105.565263349077128, 23.110553953691422],
            [105.561372301974103, 23.134718142423438],
            [105.551300271814256, 23.162427038018151],
            [105.527400280671117, 23.183073174339526],
            [105.399154144243397, 23.280691165926285],
            [105.358727219286919, 23.324164242143397],
            [105.258045139166683, 23.30444497667763],
            [104.907491316251622, 23.180273115756833],
            [104.824536123206798, 23.099264200634636],
            [104.852209311893859, 22.962500038900217],
            [104.73192715697158, 22.818054030522021],
            [104.673454162066008, 22.813054057625763],
            [104.651300288997192, 22.826173077236419],
            [104.573609266437273, 22.830000086598986],
            [104.539427194640126, 22.809164183989239],
            [104.49429131646653, 22.770973215530077],
            [104.461109205720874, 22.741936123554396],
            [104.374700333281822, 22.687500188505439],
            [104.358991305582805, 22.687264154111475],
            [104.26518221844745, 22.73492700846144],
            [104.254582296024097, 22.77374494327988],
            [104.259154121304931, 22.827882147298695],
            [104.111300198724081, 22.798118009039669],
            [104.061509179151898, 22.740553947716975],
            [104.033872367924658, 22.686382210256809],
            [104.011372322253777, 22.573191145440674],
            [104.016518307903624, 22.552636036588055],
            [104.00858232195506, 22.523245060659306],
            [103.968663340332313, 22.503873141262247],
            [103.909418204504078, 22.537773078197787],
            [103.861100220600093, 22.577218147013681],
            [103.826945138531102, 22.607773201658617],
            [103.794145242571375, 22.651391117162845],
            [103.653809216607755, 22.782873171139514],
            [103.618100297323764, 22.779444972731156],
            [103.566382278206248, 22.698191138397576],
            [103.583736170588992, 22.648609163491258],
            [103.53728232195661, 22.587427137471664],
            [103.522418190099785, 22.584364222404744],
            [103.507627148438957, 22.591527061602434],
            [103.486927200299107, 22.610273188106603],
            [103.459854155880834, 22.63916409969049],
            [103.426609180861277, 22.704744948508718],
            [103.394991300890723, 22.771873096657473],
            [103.359700303300343, 22.792082200546119],
            [103.333672314573022, 22.794509096798251],
            [103.166927258562595, 22.542500136325842],
            [103.064709278500203, 22.440826979971362],
            [103.03040030468847, 22.436518011174925],
            [102.928936192999828, 22.482291081629668],
            [102.880600271822942, 22.554435966481108],
            [102.870009234217008, 22.592882080073466],
            [102.702472255992376, 22.684164191032337],
            [102.521382243209388, 22.765000103672421],
            [102.500963424102821, 22.77180017409971],
            [102.474436209220954, 22.771873096657473],
            [102.454082266045162, 22.753745051695077],
            [102.426091235588586, 22.68832697943698],
            [102.388891343363667, 22.609164094675364],
            [102.269709229417145, 22.491664228700884],
            [102.167482364537506, 22.423327074834717],
            [102.140745266799541, 22.396282193611412],
            [102.114845185915016, 22.432982189129532],
            [102.030963289652362, 22.454853927336686],
            [101.925263299363252, 22.437354022199955],
            [101.799991229828464, 22.489435983554515],
            [101.773518162041313, 22.499927108874388],
            [101.741509182467468, 22.497773127390374],
            [101.691363273113751, 22.472218045641412],
            [101.67095417701492, 22.443327134057526],
            [101.661927202545854, 22.411527031511369],
            [101.675472358115172, 22.388400187119387],
            [101.625945200855739, 22.28069105463338],
            [101.57443622640389, 22.209164083685906],
            [101.627127216844798, 21.97040909146564],
            [101.672763327001377, 21.933327048799626],
            [101.765263328494967, 21.834445066210421],
            [101.752345139284813, 21.732073194405288],
            [101.781663360293777, 21.653464185807096],
            [101.767491238365238, 21.496664090303952],
            [101.760536270366941, 21.453327136194915],
            [101.743309279998385, 21.357773104451823],
            [101.740954132837572, 21.313891158996782],
            [101.768663363708498, 21.29290907599524],
            [101.806509165393919, 21.285553955938141],
            [101.848454220657828, 21.253464174817438],
            [101.849518219449834, 21.239535966271902],
            [101.845263397748028, 21.216109049745214],
            [101.785954224179335, 21.144509156239891],
            [101.732563344821443, 21.139491078432926],
            [101.703327266463731, 21.175000173144539],
            [101.677063410980367, 21.196382072928699],
            [101.608872269868044, 21.234718048313184],
            [101.570545179300865, 21.247218148191664],
            [101.508609284907692, 21.246800058860245],
            [101.387491286598419, 21.223191087396131],
            [101.338172335814392, 21.188744985648086],
            [101.291927196571379, 21.176873193233362],
            [101.253600273642434, 21.210409020293852],
            [101.234636217654923, 21.252009076422141],
            [101.247209240091337, 21.333327116134271],
            [101.264845267335858, 21.374435992734945],
            [101.184709243612843, 21.558891172014327],
            [101.148236230033291, 21.572635984517873],
            [101.169427190062578, 21.603673166234827],
            [101.16706332572258, 21.634854013772653],
            [101.1205633766223, 21.770518134531343],
            [101.099009312546428, 21.769026993952238],
            [100.835818222654353, 21.646664115379807],
            [100.808318204087385, 21.608054051760732],
            [100.800191278383892, 21.588053992538036],
            [100.721236264821556, 21.511109127000779],
            [100.639709180443589, 21.476936107659029],
            [100.572209211069008, 21.452353997233601],
            [100.477272255039367, 21.462145065998143],
            [100.456372314689162, 21.494718147657281],
            [100.42650021751669, 21.529445043162454],
            [100.373172370070762, 21.5381910562363],
            [100.345472359293268, 21.524791075230453],
            [100.282763318149108, 21.476936107659029],
            [100.205682330504061, 21.435464127011628],
            [100.167482309589474, 21.461109062762873],
            [100.120263360832865, 21.514509162214338],
            [100.107609201573467, 21.585136084396439],
            [100.141372345848794, 21.634164183139418],
            [100.084154121852094, 21.686109016557523],
            [99.998454190785679, 21.699853996699133],
            [99.977627172993238, 21.725545032917907],
            [99.942200220932961, 21.821245077414815],
            [99.945954140099587, 21.856664150486083],
            [99.959154128895108, 21.89193603733726],
            [99.974700212396243, 21.917218036679841],
            [99.99039130282253, 21.964373115334112],
            [99.957409184287286, 22.05018217878019],
            [99.892209209150366, 22.060553945798304],
            [99.743936191409688, 22.07374504977669],
            [99.709154142981475, 22.039718043188557],
            [99.685527234244461, 22.075409025199647],
            [99.659845250481368, 22.087354075448403],
            [99.588045197127514, 22.108891040441662],
            [99.465545190618883, 22.135827124561573],
            [99.434709174578558, 22.121382087864831],
            [99.409572349799447, 22.106382169176172],
            [99.366782231055055, 22.093473200059591],
            [99.241100286453758, 22.112500120320973],
            [99.167845134704891, 22.15591804361496],
            [99.293245112082303, 22.404854030736757],
            [99.330827219093095, 22.436936100506429],
            [99.348372386507123, 22.454464168837873],
            [99.362491199531433, 22.469164183030003],
            [99.376372301780947, 22.487082177498152],
            [99.384572317680323, 22.506945108784564],
            [99.386172255362993, 22.590209091143535],
            [99.344572366872882, 22.709991181720923],
            [99.442472325683269, 22.869991152589179],
            [99.514427276608131, 22.9126360967701],
            [99.551372358975783, 22.917773197602642],
            [99.564845262539592, 22.936591073198272],
            [99.511300323800782, 23.082045089255317],
            [99.357763303212977, 23.134227130533972],
            [99.257909187490696, 23.117635991342226],
            [99.233772323763247, 23.106627032042766],
            [99.20166326426525, 23.100273214141779],
            [99.104145184964494, 23.103882126383198],
            [98.927472267529112, 23.189164135756002],
            [98.921100177079239, 23.284164123697693],
            [98.924700204503239, 23.327218104754891],
            [98.915327225070172, 23.412491061672384],
            [98.904163368199676, 23.443053995306244],
            [98.887418169626955, 23.473044947866029],
            [98.86261829735642, 23.485827014968223],
            [98.827900286668665, 23.480482210574493],
            [98.806645121260857, 23.541735985685179],
            [98.852763358489341, 23.637773150328727],
            [98.81717228876451, 23.760727120714805],
            [98.687482285691686, 23.816391172220264],
            [98.679909236151531, 23.970753977911315],
            [98.727482236499128, 23.98582698679563],
            [98.754854179585465, 23.998009077266758],
            [98.848754126551256, 24.062582086044515],
            [98.869691282551656, 24.078400078485188],
            [98.897472262514071, 24.101618117984017],
            [98.905118234611933, 24.12516422517426],
            [98.890727177371758, 24.160073175616773],
            [98.874972384481197, 24.146064165524351],
            [98.852027259750486, 24.130436107010226],
            [98.586536175620751, 24.095336049174691],
            [98.546991194518711, 24.124436005424243],
            [98.51408233381747, 24.131027031185781],
            [98.446591249260251, 24.134953952834437],
            [98.220782274168783, 24.12530906446149],
            [98.187727233075606, 24.119445084983496],
            [98.064482243010417, 24.089100080832807],
            [98.043636114478801, 24.082864112490924],
            [97.912491348286864, 24.026664122094658],
            [97.896791205405094, 24.013327005362768],
            [97.865263347074716, 23.980545046675857],
            [97.764709174797389, 23.922773114153799],
            [97.691927265303121, 23.883882089139618],
            [97.649982210039155, 23.851700107083573],
            [97.645536113306349, 23.869164137674559],
            [97.633872359729054, 23.888400102601807],
            [97.581372309042962, 23.921109138730756],
            [97.547618217223061, 23.929991106274613],
            [97.537209234554467, 23.94860915729798],
            [97.644145220289403, 24.053054041402177],
            [97.694700334157318, 24.093473087369588],
            [97.721227213763115, 24.114436059631842],
            [97.736372307014989, 24.135273135708061],
            [97.748454149923788, 24.161244965683963],
            [97.759300332663116, 24.260554090060182],
            [97.712772388006357, 24.375273175829463],
            [97.665118250835718, 24.44221809557429],
            [97.543045218475839, 24.476664197322336],
            [97.556636139236645, 24.536945001109885],
            [97.561791344980094, 24.565136023776645],
            [97.568191263348496, 24.666664173205717],
            [97.560327194129201, 24.716127125086629],
            [97.55255415237869, 24.739927036305701],
            [97.578600246016919, 24.761944954904536],
            [97.671372324088424, 24.822773096970863],
            [97.689418226399567, 24.834445064813522],
            [97.736645221783107, 24.918882178343878],
            [97.722763281343276, 24.981382174822286],
            [97.725818149783009, 25.064718073910527],
            [97.757636189602152, 25.091391133908033],
            [97.789427239692799, 25.137500151043028],
            [97.806091301442422, 25.170273224912521],
            [97.814700186579984, 25.208882115065293],
            [97.827354178201091, 25.250900092887022],
            [97.852200318577417, 25.266936014810781],
            [97.885609243623691, 25.247218090449664],
            [97.908945132681509, 25.211727100648929],
            [97.944972396648922, 25.216173197381679],
            [98.065818150742331, 25.310691058251408],
            [98.124691297706676, 25.390000123404818],
            [98.135545191796865, 25.41027309739583],
            [98.168045183260148, 25.550273176679283],
            [98.167354179160412, 25.571109079289116],
            [98.191082341288023, 25.615273159605849],
            [98.227200297448093, 25.593054075330187],
            [98.307591298666438, 25.552354067967443],
            [98.361372271799297, 25.569445103866073],
            [98.383045191262482, 25.591944981898862],
            [98.438582340753811, 25.691109099349873],
            [98.477909224734361, 25.764164091250493],
            [98.482563360304624, 25.796735999443243],
            [98.540782215905239, 25.844891206787153],
            [98.58331819534456, 25.823745006120802],
            [98.628591201454611, 25.803327025204496],
            [98.710400253056008, 25.855973088644291],
            [98.714291300159033, 25.892500081680495],
            [98.655254203168141, 25.953882099910246],
            [98.605809188560016, 25.989227076957192],
            [98.575272406463995, 26.083327016133595],
            [98.569445139722148, 26.125273077225856],
            [98.591091237095071, 26.137500094698041],
            [98.648663345044866, 26.147845039626006],
            [98.647354091765038, 26.120691193661216],
            [98.655336178181386, 26.100136084808511],
            [98.679018239841383, 26.106382111434286],
            [98.708600323163154, 26.132500121801783],
            [98.731091316378439, 26.184718037626183],
            [98.704154226430234, 26.24763612343618],
            [98.726654104463108, 26.362773130898987],
            [98.745818152660974, 26.426109138402509],
            [98.775545242907896, 26.605827033631883],
            [98.778454098593983, 26.639582131280108],
            [98.77777231458785, 26.791664221110622],
            [98.760545156581344, 26.894164168396642],
            [98.738591275722769, 27.005553962215245],
            [98.710818174749448, 27.132773147863091],
            [98.691227152402831, 27.208044985521795],
            [98.693454224082785, 27.228609146829811],
            [98.712209235404458, 27.25319108961726],
            [98.735745116672632, 27.321591107757385],
            [98.732100329885583, 27.363091083961436],
            [98.702482372018181, 27.37985405216871],
            [98.685809257813077, 27.46930019105092],
            [98.696991219594651, 27.529718122774668],
            [98.64610032668466, 27.605273101123558],
            [98.533327183561966, 27.650827068628743],
            [98.458882304473065, 27.672500155729907],
            [98.426091293330643, 27.632773119690498],
            [98.424282310982306, 27.590827058598236],
            [98.407000335328831, 27.556945058935597],
            [98.376791285647784, 27.533053952609791],
            [98.347354209251563, 27.528744983813439],
            [98.319918228424882, 27.540135990260595],
            [98.303172359300021, 27.573326985823698],
            [98.292072372531862, 27.632500037284402],
            [98.263318253608105, 27.696944970581157],
            [98.230272264970523, 27.751391131551898],
            [98.195818284233468, 27.840000085942407],
            [98.146236141688888, 28.008327142362603],
            [98.156236255119467, 28.121109170302631],
            [98.144991261425929, 28.148882103637888],
            [98.136218258623757, 28.156318025241674],
            [98.016345140577499, 28.215654020900601],
            [98.020336267604677, 28.269300045392058],
            [97.902072307334635, 28.378953947058349],
            [97.853318296826131, 28.372354036479393],
            [97.83700024004051, 28.35540901333458],
            [97.804636203047124, 28.345764124961718],
            [97.743382260298262, 28.420344958520715],
            [97.736645221783107, 28.456382113133841],
            [97.722072277243484, 28.50152704376292],
            [97.706100225421977, 28.519027116537742],
            [97.558872263372081, 28.546527135104682],
            [97.496372266893701, 28.416936038489652],
            [97.482754188766393, 28.337500071321827],
            [97.468882306610368, 28.297709165180166],
            [97.445536191630566, 28.292153975015921],
            [97.415818153839069, 28.295000134066143],
            [97.360118227787751, 28.244926979632382],
            [97.348872395903896, 28.222773106563537],
            [97.307136217667704, 28.234227144922826],
            [97.24802720394743, 28.286664163696869],
            [97.23790018850255, 28.309027081431552],
            [97.149154106175644, 28.373054093034611],
            [97.128182249096, 28.381936060578468],
            [97.098736120244126, 28.380273090983636],
            [97.05324518465099, 28.358054006708059],
            [97.013882258486632, 28.333882106625012],
            [96.984709212041054, 28.332500098425768],
            [96.814700242925056, 28.3666642329501],
            [96.778663423587915, 28.376800133212313],
            [96.757491239021732, 28.406109134127703],
            [96.744709171919652, 28.422909150347223],
            [96.675263259898344, 28.458327049952103],
            [96.653872307658702, 28.467491152357567],
            [96.598727263599216, 28.468464123680903],
            [96.451154134775578, 28.416109079920219],
            [96.401927217288716, 28.35110909699354],
            [96.361645131619497, 28.392773190862201],
            [96.340272284290762, 28.525000060757137],
            [96.395400229267722, 28.568464252156829],
            [96.430263414518862, 28.590691047783523],
            [96.61542719989464, 28.760727006627732],
            [96.613727182287761, 28.795691109993456],
            [96.601936191419924, 28.813891071685319],
            [96.53387228759803, 28.896382074931111],
            [96.484418220534536, 28.968327135210004],
            [96.495818279437259, 29.036109071808269],
            [96.470827132135554, 29.056664180660889],
            [96.347136224820389, 29.033264253862527],
            [96.318600203017724, 29.015691090892048],
            [96.297209250778252, 28.992773123527641],
            [96.282209164451587, 28.974991083529304],
            [96.268327224011756, 28.959436115210835],
            [96.25027226924513, 28.940827116643064],
            [96.223309195397093, 28.921935983213402],
            [96.175327158173303, 28.901382215465347],
            [96.149709212150384, 28.936800115070142],
            [96.148882253580751, 29.059718043272383],
            [96.218872318757832, 29.147218071869744],
            [96.258745199912795, 29.164509099978815],
            [96.287491272209451, 29.167218131092554],
            [96.317491277224661, 29.170827043333972],
            [96.348872284610735, 29.181382206394161],
            [96.391727279285959, 29.257564151102244],
            [96.358454141071633, 29.286245012191884],
            [96.341663344945601, 29.273891092705199],
            [96.29248236028829, 29.242354014281531],
            [96.261936190460602, 29.243745074936371],
            [96.233182239174909, 29.25597309823705],
            [96.213318302060173, 29.277218037722989],
            [96.186854119090441, 29.312909019733993],
            [96.180682356126994, 29.351454039784201],
            [96.147763269503713, 29.395545029904994],
            [96.083145166087121, 29.464436059934584],
            [96.052345192230433, 29.461245069386891],
            [96.014363268437108, 29.432909039794723],
            [95.948872273621333, 29.395826997128708],
            [95.928318170596953, 29.391382073862374],
            [95.789700267151005, 29.34277323791737],
            [95.624691270931095, 29.255000126913714],
            [95.541363418469956, 29.191109069780381],
            [95.525272343622959, 29.136109032646218],
            [95.461436271774659, 29.054091104017047],
            [95.439145103131551, 29.041382127110623],
            [95.387772250787606, 29.035273228421417],
            [95.354009274150343, 29.042218138135738],
            [95.129972401033143, 29.117500034078247],
            [94.982454089856361, 29.140054059205781],
            [94.864082338311192, 29.174991172843178],
            [94.792263341856113, 29.260800068651079],
            [94.697354213745115, 29.336527043653504],
            [94.647509214716393, 29.333464128586499],
            [94.593172353763322, 29.272400119764271],
            [94.58120031378624, 29.231527109919412],
            [94.538854100635291, 29.214754083428133],
            [94.481436219704307, 29.218564161346265],
            [94.427754153029213, 29.215509125268383],
            [94.308145232571832, 29.141236075194954],
            [94.234554134142542, 29.073482134153309],
            [94.191672317377225, 29.008927062648567],
            [94.187445155955885, 28.974827133502671],
            [94.152454230500012, 28.93070010119807],
            [94.09455422249718, 28.893554020791797],
            [94.021100252004345, 28.836027007481235],
            [94.009291323863494, 28.808809123775703],
            [93.985363337163705, 28.72706410991465],
            [93.961727208333201, 28.669200144095626],
            [93.892936258227877, 28.686736091415995],
            [93.858509267219148, 28.690382219307523],
            [93.731936158670038, 28.677600152205244],
            [93.689627161169227, 28.664226993289518],
            [93.64729134157821, 28.647464192720221],
            [93.595209212585559, 28.65360913359352],
            [93.544036184813962, 28.665227121979356],
            [93.525000212097069, 28.675591177646524],
            [93.502063301631466, 28.679164215342198],
            [93.440482297019827, 28.655691198347981],
            [93.390445184769618, 28.635554011188802],
            [93.351936206903105, 28.61876422089118],
            [93.32866334975725, 28.595018121490867],
            [93.320391249490427, 28.540473221700424],
            [93.281518161748778, 28.492964091455079],
            [93.215536155043566, 28.41831804668918],
            [93.238163270366925, 28.387444982636723],
            [93.23766337366024, 28.34308208357622],
            [93.222054090608964, 28.319300109630078],
            [93.126245249008633, 28.316536093231107],
            [93.045709240865023, 28.310264250343678],
            [92.929872344485176, 28.232564175328278],
            [92.887336197407677, 28.198718050211184],
            [92.852872326024823, 28.200091173593208],
            [92.830036166035683, 28.212764108315824],
            [92.752900193105603, 28.168918037406328],
            [92.710445182851146, 28.14190014591135],
            [92.687145168338958, 28.114764237219262],
            [92.675245212729436, 28.080709067436331],
            [92.686527254435163, 28.060164184505609],
            [92.720772358144444, 28.041144975595259],
            [92.719572404882371, 27.979027026264632],
            [92.699418286278757, 27.955544956814819],
            [92.643727245044971, 27.916245062562425],
            [92.544982222753987, 27.861936029528167],
            [92.452472330614711, 27.826391060270694],
            [92.341372383008576, 27.806391168685977],
            [92.331100193000367, 27.794718027377129],
            [92.31470032883945, 27.796109088031969],
            [92.265536107988481, 27.868400153275005],
            [92.25734514454453, 27.819718059496083],
            [92.190245159590489, 27.812500067375467],
            [92.146100190013129, 27.818326998841243],
            [92.11831820422239, 27.812500067375467],
            [92.074436258767349, 27.786664191869463],
            [92.046354200842103, 27.750273153303155],
            [92.014427196281446, 27.734718017346708],
            [91.978318292577029, 27.728609118657303],
            [91.884718250107511, 27.731109105105489],
            [91.824418167942582, 27.766391050240472],
            [91.657763301210679, 27.764718022361919],
            [91.656645155323957, 27.777773171870294],
            [91.653045127900072, 27.836109038839581],
            [91.656718245519784, 27.869435988872297],
            [91.67394523588834, 27.897982068958711],
            [91.677063303878271, 27.92916408996301],
            [91.662763274106823, 27.945000187314676],
            [91.638736213311205, 27.957773201961359],
            [91.606936110765076, 27.969436117348337],
            [91.520536123143444, 27.991382119217704],
            [91.501118270916805, 27.989518151584392],
            [91.485263398102035, 27.989300054463243],
            [91.450000228430184, 27.997218103139076],
            [91.419436121329767, 28.010554046404494],
            [91.356936124851273, 28.041945112074544],
            [91.326100276449125, 28.063054097090671],
            [91.30137232090803, 28.081109051857126],
            [91.218872265206727, 28.074718018305944],
            [91.164991212149602, 28.026109014722962],
            [91.145545196728307, 28.002082121565323],
            [91.131645151377512, 27.983609077467278],
            [91.11234514870992, 27.971936103796494],
            [91.086927194897356, 27.969991166978147],
            [91.020400196845998, 27.976936076692553],
            [90.993454222080373, 27.99235408471263],
            [90.967754133406316, 28.019164105008443],
            [90.95138226480222, 28.032500048274059],
            [90.917900249560063, 28.046526995639184],
            [90.868872318455118, 28.050553997212106],
            [90.840272258912222, 28.048054010764034],
            [90.756654224962318, 28.064718072513628],
            [90.650545197796959, 28.078609065409054],
            [90.626654091471181, 28.075409022405765],
            [90.55615423894173, 28.075136107637647],
            [90.532482235565482, 28.080554002227316],
            [90.510554170968902, 28.079164115038864],
            [90.466382211663159, 28.071664155694535],
            [90.376500213814694, 28.079582204370283],
            [90.353318216499503, 28.098473170161853],
            [90.356645161517207, 28.132500176749986],
            [90.366382250825239, 28.15055395804994],
            [90.379291219941905, 28.169582219415915],
            [90.395400232061633, 28.200544969832478],
            [90.388972318136581, 28.242564121120679],
            [90.346509261255022, 28.259300099599741],
            [90.319427164381381, 28.264300072495885],
            [90.286518303680197, 28.275409111719526],
            [90.248872326566953, 28.28471805341222],
            [90.225536269870958, 28.290273075938288],
            [90.178591241710905, 28.300553983125823],
            [90.020682220414727, 28.325000139081439],
            [89.998191227199214, 28.323691053439873],
            [89.863036223240925, 28.271382110146718],
            [89.836654182922445, 28.2516641857856],
            [89.791854083790923, 28.194718038366673],
            [89.746372368291333, 28.175553990169007],
            [89.721236214064419, 28.174436011920264],
            [89.692754171718519, 28.173609053350745],
            [89.626509308528938, 28.159436093231804],
            [89.588191270417241, 28.141664111517272],
            [89.487491253024217, 28.057773162799108],
            [89.467754217923783, 28.03416419133508],
            [89.456100187354167, 28.017218162361772],
            [89.450427147631075, 28.00838212764755],
            [89.409427235771915, 27.950000160210735],
            [89.398036229324674, 27.933053963599534],
            [89.386936242556459, 27.915273097067583],
            [89.359982221163733, 27.871664234018837],
            [89.269145189264833, 27.827500153702019],
            [89.236991202765608, 27.807218127255609],
            [89.218454120927134, 27.778191093563819],
            [89.200272264146264, 27.738044962364498],
            [89.179563263550932, 27.696800131293841],
            [89.166227152647338, 27.675000142178064],
            [89.128036184188176, 27.623327050061604],
            [89.128582349000595, 27.571664183867043],
            [89.104982262353843, 27.550554025384614],
            [89.086382316241412, 27.53832700791223],
            [89.057618139033849, 27.523609056447285],
            [89.015536123471776, 27.509854018021869],
            [88.984982242293114, 27.488882160942111],
            [88.967491221973916, 27.46638211527123],
            [88.956236169996572, 27.430827087730151],
            [88.946636208624824, 27.385273120224966],
            [88.93552716940124, 27.368327091251658],
            [88.923600224063449, 27.345553963174481],
            [88.916927223288752, 27.320827013461823],
            [88.895827123090129, 27.333327113340388],
            [88.823318296001986, 27.38666418087972],
            [88.799709156899809, 27.416382218671302],
            [88.781245165257161, 27.443609154832146],
            [88.764636256430578, 27.542427099681092],
            [88.801372294132449, 27.615827090717275],
            [88.813309297754103, 27.632218070060688],
            [88.83054517294002, 27.654582161261757],
            [88.854991328895579, 27.724027067454685],
            [88.881091234352283, 27.839582164248881],
            [88.877200187249429, 27.875553940017298],
            [88.873027172922804, 27.895545114422575],
            [88.835754190501945, 28.008054059956507],
            [88.773318231763909, 28.05110904684183],
            [88.74053610543902, 28.069718045409886],
            [88.624354209923496, 28.116800033868216],
            [88.553463257790696, 28.068473165146642],
            [88.509154170548669, 28.040554051419704],
            [88.390827178366379, 27.983609077467278],
            [88.331372327320224, 27.979718030364253],
            [88.138118161338838, 27.949373193851571],
            [88.122063296313598, 27.907009043427692],
            [88.132063409744063, 27.878464136807949],
            [88.142791240010212, 27.866054058569759],
            [88.091663306877791, 27.892218001766892],
            [88.054427204831057, 27.898609035317989],
            [88.027772249744515, 27.888609089525588],
            [88.003054184849105, 27.885827135853887],
            [87.976927122026069, 27.890000150180512],
            [87.872618192391627, 27.917218033885845],
            [87.849009220927513, 27.910691045864851],
            [87.825272341620661, 27.869718123734103],
            [87.804563341025329, 27.843882080589921],
            [87.780272250278898, 27.825000167253947],
            [87.751391229340726, 27.813053943538804],
            [87.72053627019929, 27.805000108031138],
            [87.660536260168868, 27.828882161901461],
            [87.626082279431984, 27.813609160806593],
            [87.590545189163635, 27.814854041069836],
            [87.536654077822703, 27.840273168348787],
            [87.408872298832108, 27.843053948553901],
            [87.32916341689608, 27.822218045944155],
            [87.30609122251289, 27.816391114478378],
            [87.192745092487513, 27.823054056969184],
            [87.141518252897384, 27.844027087515414],
            [87.097072372995143, 27.891245030443471],
            [87.08651821576322, 27.924300071536649],
            [87.032554181864413, 27.9632539608249],
            [86.94707234791926, 27.94763612823256],
            [86.903591225075132, 27.969718084571852],
            [86.759991286005885, 28.030553937989211],
            [86.75610023890286, 28.055973232906254],
            [86.75610023890286, 28.082218145288365],
            [86.728591335518416, 28.100554061450012],
            [86.695263379657121, 28.111664106502062],
            [86.58860030869036, 28.113053993690514],
            [86.569436260492608, 28.100418106980115],
            [86.541091346083107, 28.024164245542678],
            [86.537763395237022, 27.959718138779536],
            [86.517763336014212, 27.940273129186536],
            [86.451372292432808, 27.908882063516486],
            [86.35206333569468, 27.945000187314676],
            [86.223045225982247, 28.011391063258017],
            [86.196918163159268, 28.07208207738806],
            [86.214572295314696, 28.112353937135296],
            [86.197691309910198, 28.158054085032362],
            [86.096227198221612, 28.086735991112462],
            [86.103863279673789, 28.026945025747992],
            [86.123872391352023, 27.980827123795663],
            [86.135963286716418, 27.93533602056435],
            [86.089418242976961, 27.890827108750031],
            [86.069718255888716, 27.888044987440267],
            [86.005400224606461, 27.886245057547413],
            [85.976372352724326, 27.910135996235041],
            [85.951663340284398, 28.013054032852565],
            [85.887772283151065, 28.148609021231678],
            [85.869418262078483, 28.16733603699673],
            [85.847209236086627, 28.173609053350745],
            [85.820545228544546, 28.185273142204196],
            [85.780818192505222, 28.206109044813942],
            [85.742482217120738, 28.242636037850147],
            [85.721372393914379, 28.279164204352625],
            [85.583600224501225, 28.282500034187663],
            [85.458882198767839, 28.30166425002362],
            [85.436918259625571, 28.300136061432383],
            [85.413882275064083, 28.279718080515963],
            [85.39721821331446, 28.266391189705942],
            [85.376645167189025, 28.263609068396178],
            [85.283872250927203, 28.272218121171747],
            [85.23692722276715, 28.27860915472273],
            [85.139982298007197, 28.295273216472523],
            [85.110954090848878, 28.306664222919679],
            [85.103318177034708, 28.31639108630587],
            [85.11360025768883, 28.395827053473695],
            [85.135127164398313, 28.46527313313301],
            [85.181927185632986, 28.530000033653394],
            [85.19518232735166, 28.591036046919086],
            [85.106936141731552, 28.636664110448621],
            [85.072218298681861, 28.641382116121164],
            [85.05227238655371, 28.634226988274506],
            [85.03166329824441, 28.603882151761709],
            [84.947209253269449, 28.542082211838434],
            [84.846645190346322, 28.544445070350221],
            [84.823318186105809, 28.551935977238955],
            [84.703109289017476, 28.607218149234811],
            [84.708454093411234, 28.651527068838774],
            [84.636382298755819, 28.702773186806311],
            [84.570827266199416, 28.712491165375113],
            [84.486227208470751, 28.73401807208468],
            [84.459154164052649, 28.759164116957251],
            [84.430263252468734, 28.812500178668458],
            [84.405545187573324, 28.849164132002883],
            [84.388600164428595, 28.863327033838019],
            [84.326518257281606, 28.871800132143662],
            [84.244427238456495, 28.913609065299553],
            [84.23692727911228, 28.918882120601992],
            [84.232754097147534, 28.976109061778047],
            [84.23997225690627, 29.053891111806607],
            [84.162072357318692, 29.130000133956727],
            [84.159427196307121, 29.156664141498922],
            [84.180400226853266, 29.183327143212537],
            [84.180954103016603, 29.209718068348238],
            [84.116509169719933, 29.260973238771285],
            [83.976100221198436, 29.296945014539503],
            [83.912491298926824, 29.289718137601469],
            [83.750827184997405, 29.242773109441444],
            [83.720536160303283, 29.228882116546018],
            [83.695118206490719, 29.206382070875051],
            [83.688309251246181, 29.183882192842347],
            [83.672272323493843, 29.163327083989728],
            [83.636100220239115, 29.169435982679019],
            [83.628582323622055, 29.165827070437714],
            [83.605945150014776, 29.164718144644567],
            [83.547909187542047, 29.189027005025721],
            [83.414427211462083, 29.365554077345379],
            [83.380536159343961, 29.416382105981413],
            [83.377482296732438, 29.437636097922663],
            [83.37915415114449, 29.466109087813138],
            [83.354427201431719, 29.490554070302281],
            [83.257491329127191, 29.580000041546398],
            [83.191082348272943, 29.631664248845354],
            [83.164991327633601, 29.633053968395828],
            [83.120809310044052, 29.622082224746563],
            [83.059982341444055, 29.623327105009608],
            [83.018054217624723, 29.658327082920991],
            [82.998600323214333, 29.670000056591832],
            [82.908245083111211, 29.690618029718522],
            [82.872063256848861, 29.666935968058553],
            [82.776654232031063, 29.733882061269682],
            [82.652354127991231, 29.838327113012056],
            [82.598600312224761, 29.892218056714896],
            [82.420818133720076, 30.010273139957278],
            [82.224918136174978, 30.075000040477661],
            [82.175254186255273, 30.069235973285842],
            [82.151236177915081, 30.120409168695616],
            [82.111227174652157, 30.334445090192034],
            [81.951382268993058, 30.363882166588311],
            [81.853045110111822, 30.376664233690505],
            [81.808318268814247, 30.363745038652027],
            [81.728045117154892, 30.392491110948598],
            [81.654982246265291, 30.419164170946104],
            [81.630254123086019, 30.424718020005585],
            [81.560527249669605, 30.384436101974458],
            [81.550536188694622, 30.358327144062358],
            [81.545682228552067, 30.332082064042154],
            [81.49360026719765, 30.341527127842753],
            [81.466382215854168, 30.35943606985559],
            [81.446927147977277, 30.377218109853843],
            [81.426227199837427, 30.385000036421687],
            [81.408600225048275, 30.375553966792793],
            [81.390963359613323, 30.315273163005244],
            [81.392763289506291, 30.265554060162444],
            [81.380818239257536, 30.172500182505516],
            [81.298600318418011, 30.078054070727248],
            [81.278591206739719, 30.067218113909703],
            [81.247482275931389, 30.056382157092273],
            [81.253327144669953, 30.02874501058885],
            [81.232209274836606, 30.011944994369443],
            [81.199845237843221, 30.017773099301607],
            [81.160954212828841, 30.030418038467403],
            [81.119427246896606, 30.019444953713659],
            [81.096100242656092, 30.084718019046349],
            [81.073882331846818, 30.128609016956986],
            [81.044436202995115, 30.17610909474682],
            [81.025363349904097, 30.204354096870119],
            [81.030127120768185, 30.213327091882562],
            [80.993518152718735, 30.263953954841853],
            [80.947763354812963, 30.274853949399741],
            [80.902000175004019, 30.285900124349311],
            [80.808727194397335, 30.324164183004413],
            [80.790954206854423, 30.337082204576504],
            [80.773882281695109, 30.360000171940712],
            [80.758600228144843, 30.385618117963546],
            [80.611791193616881, 30.472082143325721],
            [80.585400268480981, 30.476245099368342],
            [80.521382309333404, 30.477218070691677],
            [80.402791287200245, 30.526373239087249],
            [80.316227181914002, 30.566109159944077],
            [80.289427219902251, 30.570826997978443],
            [80.232754154889534, 30.574164168918216],
            [80.207000254396888, 30.575518013922746],
            [80.224018200099664, 30.59777314038233],
            [80.254227249780541, 30.733745044626474],
            [80.188591248039273, 30.795136115311649],
            [79.96081822539017, 30.884444958619369],
            [79.949809266090796, 30.884900095963005],
            [79.929700242126415, 30.887909031573471],
            [79.898036261688077, 30.919164142773511],
            [79.886036226154459, 30.949782061692602],
            [79.863036116138716, 30.965827036071929],
            [79.745263335396118, 30.989300053066145],
            [79.660545092832336, 30.973609130277865],
            [79.632482313284385, 30.956518094379348],
            [79.609982267613503, 30.938464145441188],
            [79.554291226379604, 30.957082196464555],
            [79.419436126917645, 31.052218138876256],
            [79.393245193992271, 31.101064182681696],
            [79.339709307708887, 31.120553951637746],
            [79.314427140728213, 31.134582072469371],
            [79.241791243987421, 31.241664238596215],
            [79.217900305299764, 31.300973076888724],
            [79.220600283958191, 31.325973109007791],
            [79.164154200884155, 31.395273175913289],
            [79.080818134157909, 31.437291153735018],
            [79.044145128368001, 31.426935982885155],
            [79.018527182345252, 31.409791135167879],
            [78.877654211662644, 31.291627087184011],
            [78.817491257434114, 31.28790904256293],
            [78.768245229207849, 31.308953989838628],
            [78.712491323699993, 31.502709058355109],
            [78.721927167407017, 31.525273141766334],
            [78.772345153338534, 31.552845077063026],
            [78.842482237097585, 31.607500115061342],
            [78.764363402198256, 31.675064122176437],
            [78.715818268717697, 31.726382156873441],
            [78.692891248897723, 31.779718050946585],
            [78.73610029516405, 31.889718125214912],
            [78.745072284348083, 31.91036409389811],
            [78.75278229418646, 31.92138210565308],
            [78.761654203446511, 31.939991104220937],
            [78.77075410047334, 31.968473146566723],
            [78.731654198431301, 32.006526987089686],
            [78.687482239125558, 32.030682123366105],
            [78.656936236935934, 32.059718041875399],
            [78.597491276535521, 32.124853979271776],
            [78.579300199660992, 32.161936021937706],
            [78.561382205192928, 32.189718007728445],
            [78.538036257851019, 32.214436072623798],
            [78.475936245793406, 32.243045016984169],
            [78.486927267819937, 32.251591205485667],
            [78.472345103186825, 32.432353988767929],
            [78.416645177135621, 32.510273166732887],
            [78.406327221935868, 32.519244988278828],
            [78.39707225969974, 32.534573142296622],
            [78.405954227243484, 32.55610004900619],
            [78.499436252515835, 32.582635981067298],
            [78.536100205850261, 32.571935978719679],
            [78.572209277192854, 32.566664096883798],
            [78.638600320774373, 32.577773136107439],
            [78.66387226183295, 32.585545004391477],
            [78.682618220699311, 32.599018075593293],
            [78.705545240519115, 32.629227125274255],
            [78.746227142970866, 32.639018026400905],
            [78.76909129851677, 32.625753999864756],
            [78.775409241871927, 32.598327071493671],
            [78.765000259203504, 32.546109155669271],
            [78.774427218093109, 32.489991140286364],
            [78.785545142134225, 32.4717911785947],
            [78.802763247685249, 32.452764258333119],
            [78.922618260820599, 32.364854019393178],
            [78.971100194751187, 32.35082707202794],
            [79.078045232941548, 32.38138212667296],
            [79.122818174706737, 32.457835980320752],
            [79.139572258096706, 32.479854066557763],
            [79.172618246734402, 32.495273080406136],
            [79.208600248424574, 32.505827070000052],
            [79.230818159233792, 32.511935968689457],
            [79.253172359789033, 32.516000185912574],
            [79.30227237526131, 32.495618079541984],
            [79.452763244588624, 32.599018075593293],
            [79.476382274336544, 32.621654075734071],
            [79.490536123716169, 32.63777314613786],
            [79.50249123224873, 32.654709116827277],
            [79.51540925382065, 32.67458210639758],
            [79.530272379849151, 32.728045070123116],
            [79.528736144630756, 32.756664240405357],
            [79.448182199214301, 32.884300174280057],
            [79.415263280229084, 32.910273177722431],
            [79.398036122222578, 32.921664184169785],
            [79.373863383949384, 32.937209094204164],
            [79.356582246486226, 32.968400167664058],
            [79.366091180389191, 33.061100161367833],
            [79.375118154858257, 33.09943596911431],
            [79.274700272326896, 33.189427099342524],
            [79.251372262258002, 33.194291117768685],
            [79.223309147433866, 33.203609111916862],
            [79.188582251928864, 33.222209058029321],
            [79.183872292883422, 33.225818137908632],
            [79.148882205617696, 33.235545001294938],
            [79.101091275786843, 33.253327041293275],
            [78.994772365765442, 33.317700057860392],
            [78.946227232284713, 33.384854022271085],
            [78.936509253715911, 33.408000145040532],
            [78.899018174173904, 33.42762704193278],
            [78.854427119708163, 33.460409168257868],
            [78.834300158470938, 33.482209157373617],
            [78.813863402091584, 33.520409178288091],
            [78.806718164890555, 33.549018122648462],
            [78.805672271009456, 33.616382137553117],
            [78.810391282510381, 33.657773149015568],
            [78.814991270985928, 33.703609083744468],
            [78.817763334011801, 33.749718100879463],
            [78.816936207804275, 33.76999107487039],
            [78.811927182452536, 33.817218070253929],
            [78.807209176779992, 33.837209077021427],
            [78.763318178869469, 33.95860015773691],
            [78.746654117119789, 33.99638208931988],
            [78.732754071769051, 34.038609111807361],
            [78.735809275485025, 34.068327149599057],
            [78.751236168322492, 34.094153972649551],
            [78.774563340201013, 34.109718161061508],
            [78.795818170332751, 34.116391161836205],
            [78.81608225950626, 34.120273156483719],
            [78.854009198014722, 34.130409056745847],
            [78.898036150395143, 34.145964192702408],
            [78.950272338768542, 34.175827069781221],
            [78.978736108565414, 34.197491104427073],
            [79.001236154236295, 34.224573201300686],
            [79.012082336975624, 34.257909036150821],
            [79.01027234879885, 34.289436056290882],
            [79.002209293197637, 34.310545041306725],
            [78.985354124055078, 34.350009053223843],
            [78.951382270390155, 34.380818079535842],
            [78.709845156860041, 34.521382093266524],
            [78.653600239462918, 34.546244997449193],
            [78.625536118810402, 34.554991178161046],
            [78.605254092364049, 34.55943610142738],
            [78.582491190208856, 34.561936087875367],
            [78.487763278844994, 34.571664124728059],
            [78.456100304235207, 34.573882143952616],
            [78.400127128139644, 34.583882089744932],
            [78.365536187104368, 34.594153944476957],
            [78.337072249669319, 34.611800197643575],
            [78.304145116419107, 34.648745112373106],
            [78.291363384593097, 34.669991057687454],
            [78.278591208136646, 34.721927006288226],
            [78.230118159023647, 34.863882080673747],
            [78.212491184234437, 34.887773186999553],
            [78.195818237667396, 34.901927036379291],
            [78.181091233746969, 34.921382104256097],
            [78.172763310004626, 34.94582708674524],
            [78.168318219100371, 34.977209099959779],
            [78.118591237268475, 35.116245092737316],
            [78.089982292908275, 35.15554498698971],
            [78.053318171935786, 35.216100046649856],
            [78.023045084514393, 35.280691160338321],
            [78.024018223475792, 35.32430019102533],
            [78.034427206144215, 35.355273167363876],
            [78.070963251635732, 35.438600181634698],
            [78.085536196175468, 35.473809036573741],
            [78.07180026848917, 35.499026998175779],
            [78.041445206054647, 35.505618023937316],
            [77.989082283304953, 35.492909047030977],
            [77.949563286102745, 35.456445085906907],
            [77.912054101649886, 35.437254047981],
            [77.882345116313701, 35.442764143505727],
            [77.85554515430195, 35.461245066592809],
            [77.829991246019489, 35.490827149914409],
            [77.823927274331226, 35.501326992413695],
            [77.783527171464954, 35.503745003848408],
            [77.752772292247727, 35.487491152441393],
            [77.713391261172404, 35.463527123557711],
            [77.684700174160781, 35.462353992385957],
            [77.618863342018955, 35.473318024684275],
            [77.561372370892087, 35.480818151666696],
            [77.535536160109956, 35.483882072562082],
            [77.50721823542878, 35.484164207423689],
            [77.483736165979082, 35.480818151666696],
            [77.453045156864135, 35.472764148520852],
            [77.42164520637678, 35.472764148520852],
            [77.392909192363987, 35.481518040583822],
            [77.251391318049201, 35.533609054393807],
            [77.109418138752773, 35.556100047609092],
            [76.895263361868871, 35.612500197853606],
            [76.772763355360127, 35.657773203963572],
            [76.756663395695824, 35.669718086574235],
            [76.736645231562051, 35.696100126892631],
            [76.719145158787313, 35.71013612671338],
            [76.693318168098727, 35.723182223766159],
            [76.624418253251832, 35.749436020965774],
            [76.594145165830497, 35.75721811517171],
            [76.572072261946545, 35.788882095609964],
            [76.570691259575511, 35.821518041543058],
            [76.553036121591589, 35.906654038162159],
            [76.440536228513253, 35.859164186294194],
            [76.38499120003317, 35.841382146295857],
            [76.296936121805885, 35.822491180504372],
            [76.229427267613829, 35.813327078099107],
            [76.180609219364982, 35.814573131828539],
            [76.15637227571321, 35.830964111171951],
            [76.153591327869918, 35.868327115233271],
            [76.154991273342176, 35.898882169878291],
            [76.127209287551437, 35.972354077643942],
            [76.109009158221653, 35.991800093065237],
            [76.085309159288869, 36.001809091313234],
            [76.058727126760033, 36.004854069107139],
            [76.029163315987347, 36.002218128189256],
            [76.00776331129228, 36.001654026103935],
            [75.986509151712852, 36.006100122836855],
            [75.968036107614779, 36.014991142836024],
            [75.953182201679937, 36.028873083275855],
            [75.928872335470288, 36.070818138539622],
            [75.918727215114501, 36.109945030310101],
            [75.941645182478993, 36.129435972732622],
            [75.99096329507276, 36.159582158139429],
            [76.041654195772338, 36.237509047455418],
            [76.010691277717598, 36.442082135516429],
            [76.002063281840918, 36.464436000795601],
            [75.904018148467031, 36.622954051178354],
            [75.859836130877483, 36.663427076602332],
            [75.838600243846969, 36.678573175682786],
            [75.789709273040359, 36.709664169218215],
            [75.754082328769726, 36.729454010308885],
            [75.71249132509692, 36.746600199130526],
            [75.66470022762806, 36.758536029285708],
            [75.639163250790176, 36.763545054637362],
            [75.551791297673532, 36.768109168567079],
            [75.524145098714683, 36.732818003338608],
            [75.47276336155349, 36.725582073945176],
            [75.451791336835669, 36.731691140272645],
            [75.435254177100461, 36.743491183595992],
            [75.42539119160628, 36.764045118982224],
            [75.423036212083531, 36.801553968158899],
            [75.424427272738541, 36.833636037928656],
            [75.425809280937898, 36.858227033171701],
            [75.399018203743395, 36.911554042427227],
            [75.376363260501392, 36.924891159159316],
            [75.315809206669627, 36.940154101970094],
            [75.277209201334387, 36.946253948204074],
            [75.239154187344951, 36.952654034210568],
            [75.212072258109487, 36.958900060836456],
            [75.181782239243745, 36.971245095505807],
            [75.147863359206809, 36.997191109219685],
            [75.014163286005697, 36.992345028066254],
            [74.982618160954843, 36.986927133476669],
            [74.817491315175999, 37.021764167189716],
            [74.696509271336623, 37.060791146673907],
            [74.675672362898382, 37.060091090118888],
            [74.565427201780466, 37.027818080594258],
            [74.528872380825732, 37.049236022562141],
            [74.394991258515546, 37.146464255650173],
            [74.390891334384861, 37.17000013691846],
            [74.527827157496944, 37.240800061582391],
            [74.607482227614327, 37.250954066755511],
            [74.669436226918407, 37.265973096183373],
            [74.739709265147411, 37.301400048243764],
            [74.760327238274016, 37.299100054005933],
            [74.773463356967255, 37.273064186289687],
            [74.808727197191246, 37.224227027301552],
            [74.915736105484058, 37.237327103810983],
            [74.94525415106537, 37.247382202526509],
            [75.078872249253237, 37.312682089949433],
            [75.096645236796093, 37.321854071343822],
            [75.187482268695049, 37.406582204553288],
            [75.069436237907979, 37.505764259277001],
            [75.038245164448284, 37.522082148424559],
            [74.954282299000766, 37.559644977057971],
            [74.935536172496512, 37.578809025255836],
            [74.921100188255167, 37.604100077053815],
            [74.903600283118607, 37.652427113413282],
            [74.91442718748047, 37.670345107881516],
            [74.939418167144112, 37.686253960152968],
            [74.986500155602442, 37.78657310386474],
            [74.959854085333149, 37.806600152815761],
            [74.927063241828847, 37.820500198166584],
            [74.906163301478642, 37.841809175392982],
            [74.909991316669704, 37.915218051246583],
            [74.915963255060802, 38.007854007210099],
            [74.904854215837162, 38.025909129614732],
            [74.863872241250732, 38.043973136836584],
            [74.844436116475237, 38.051609050650725],
            [74.817691307386355, 38.073964257034291],
            [74.792482230601621, 38.195209157358178],
            [74.794291212949901, 38.300627012785796],
            [74.802900265725469, 38.324927155987709],
            [74.81915411713257, 38.343264245615771],
            [74.84123624111001, 38.361882129001046],
            [74.862218156473574, 38.385264118526592],
            [74.868172325229835, 38.420900115252536],
            [74.854427177450219, 38.472564154913599],
            [74.793172228872862, 38.523682197400248],
            [74.677763312470432, 38.563818102677786],
            [74.394991258515546, 38.659927016412709],
            [74.354709172846327, 38.674382111393342],
            [74.145191264812098, 38.676809175283566],
            [74.122754083415174, 38.666882152049013],
            [74.062136159481071, 38.594236029386323],
            [74.075891197906429, 38.567082183421334],
            [74.078745235945661, 38.545073149640004],
            [73.967345216205075, 38.53410006488609],
            [73.939418223489014, 38.539936048807462],
            [73.842209268778362, 38.591045038838516],
            [73.814009193656176, 38.612709073484368],
            [73.804145202333672, 38.634100193362016],
            [73.802182328242594, 38.649836043151439],
            [73.763045210550246, 38.761609057584408],
            [73.723454128980762, 38.838264244547119],
            [73.708600223045977, 38.858409143057131],
            [73.70818230135248, 38.886527075527951],
            [73.723309289693503, 38.912427156412477],
            [73.749845221754555, 38.9293731853857],
            [73.781100165316701, 38.936591177506401],
            [73.8192182112181, 38.93729106642364],
            [73.845745090823726, 38.947364102411896],
            [73.85234516904066, 38.972564126741204],
            [73.841863263814304, 38.996109060464775],
            [73.776100192420557, 39.04965399920367],
            [73.728382185147524, 39.076253969005322],
            [73.620045080473773, 39.235691178892651],
            [73.62817234145345, 39.261045094964786],
            [73.645536124482277, 39.292991210264645],
            [73.669009141476494, 39.341591161392316],
            [73.668872348816166, 39.365491152535455],
            [73.655682250666388, 39.454827153209592],
            [73.78580928617194, 39.467991099821475],
            [73.83999124955389, 39.473818031287252],
            [73.863727123032419, 39.485209037734407],
            [73.954709161856755, 39.599654035269182],
            [73.924700272024069, 39.692700201575192],
            [73.907409243915282, 39.728200076193346],
            [73.862345114832948, 39.745209137078589],
            [73.84290027287804, 39.768954063012629],
            [73.847827155578358, 39.816944985053752],
            [73.990963240124671, 40.042018029044428],
            [74.024700232861989, 40.067364233765531],
            [74.189282254932976, 40.111736017643565],
            [74.225963307349815, 40.117291207807611],
            [74.258045209481594, 40.111254058209582],
            [74.294845117285917, 40.092709097382155],
            [74.32221823383847, 40.092709097382155],
            [74.35429125115283, 40.097845024748139],
            [74.383609304523787, 40.10770918370882],
            [74.414982265282873, 40.122573147927383],
            [74.442200316626298, 40.139373164146988],
            [74.514709143714498, 40.188264134953769],
            [74.552200223256619, 40.22298214564141],
            [74.600400189963608, 40.261945087385172],
            [74.734282318102174, 40.340764146477611],
            [74.760545167757101, 40.344935987337848],
            [74.820954214663601, 40.332009080948339],
            [74.848872322562244, 40.324927043297535],
            [74.880891192781888, 40.327918041635073],
            [74.890754178275955, 40.349100116847055],
            [74.877200305527282, 40.365209128966754],
            [74.845127120574858, 40.392573193063967],
            [74.826100200313562, 40.407982148628648],
            [74.831936184234763, 40.46355399919905],
            [74.845018155833287, 40.503973212804667],
            [74.858800183987029, 40.517227013418832],
            [74.88233623289338, 40.519245040432949],
            [74.907909251915072, 40.50869121847721],
            [74.936100274581889, 40.491609067395913],
            [74.970963292194966, 40.473827027397576],
            [74.991091259260571, 40.465773191889909],
            [75.021100316731093, 40.456600204667225],
            [75.066091187979367, 40.447573230198245],
            [75.233936117327602, 40.450427100599299],
            [75.277482283740568, 40.482436080173116],
            [75.339709197812766, 40.52653612274942],
            [75.486372387225089, 40.608836018602275],
            [75.530818267127387, 40.62966420986119],
            [75.582836190741517, 40.644527000613365],
            [75.604291180721503, 40.62855411060157],
            [75.62414522719061, 40.594654006027937],
            [75.629836204186489, 40.569800154300665],
            [75.62893615542103, 40.53792712919666],
            [75.665818205876604, 40.373827066559713],
            [75.678863297101174, 40.334936041545447],
            [75.688172238793783, 40.312027126636437],
            [75.703736091929642, 40.298000179271313],
            [75.900127269002127, 40.301191169819006],
            [75.943027190678407, 40.314945034778034],
            [75.966791227351649, 40.345500089423055],
            [75.98346333572843, 40.366600189621678],
            [76.045254223196224, 40.388827152886378],
            [76.068054173363549, 40.395773236067257],
            [76.099427134122692, 40.398282107332776],
            [76.223600168510046, 40.403827071574923],
            [76.314991244210404, 40.384664196843644],
            [76.33440021161968, 40.353236083161377],
            [76.450263259537536, 40.404109038798552],
            [76.493463253348267, 40.436744984731646],
            [76.514154149032692, 40.458900198904942],
            [76.526372281687628, 40.498691105046717],
            [76.537491211557068, 40.519664135592947],
            [76.551091184773298, 40.539382227592043],
            [76.569709235796694, 40.562300194956535],
            [76.586654091303586, 40.577582080868623],
            [76.630127167520612, 40.602644977261534],
            [76.644363327189666, 40.619100161983468],
            [76.648463251320351, 40.704518125826368],
            [76.648391334590912, 40.741264221812131],
            [76.791027187154242, 40.950909031860775],
            [76.870672366625996, 41.01258206976955],
            [76.89721818933296, 41.021045109791302],
            [76.921372319781057, 41.027436143342484],
            [77.021445203176739, 41.049591189877603],
            [77.073318287503554, 41.046535986161629],
            [77.109145223984484, 41.027164234402662],
            [77.13998224585319, 41.015982104983195],
            [77.274436187427995, 41.006336043143861],
            [77.338045109699664, 41.027300188872672],
            [77.382063344900729, 41.025082169648115],
            [77.436372377935214, 41.012727076694958],
            [77.474709191510016, 40.99689114698127],
            [77.505827174773827, 40.986064242619236],
            [77.543591336722073, 40.986609066327262],
            [77.684709226616263, 41.001891119877413],
            [77.780272310814865, 41.040791197347303],
            [77.827763336149218, 41.062445006071172],
            [77.860600280121076, 41.069673224113771],
            [77.997754200354365, 41.060227154484792],
            [78.028736229148336, 41.056336107381767],
            [78.05498231499692, 41.0439731354396],
            [78.080827242958293, 41.040791197347303],
            [78.138736135778601, 41.069253961315766],
            [78.188872322124467, 41.101345083540949],
            [78.206372227261141, 41.116053982550497],
            [78.23664514704447, 41.145500111402285],
            [78.246227171143317, 41.165127008294618],
            [78.252209167818336, 41.175509168872892],
            [78.265272363953699, 41.198000162088377],
            [78.290536258385401, 41.22966414252663],
            [78.307482287358681, 41.242027114468712],
            [78.326382305605676, 41.253554075385964],
            [78.369827218627989, 41.274845115339346],
            [78.383318227102706, 41.293835993417005],
            [78.376645226328009, 41.318282149372735],
            [78.363600302741617, 41.353009044877794],
            [78.370536160000626, 41.372582129951681],
            [78.395536192119465, 41.392864156398005],
            [78.531100232954003, 41.463836077715669],
            [78.670818177375878, 41.53189109672023],
            [78.695527189815806, 41.543009188399267],
            [78.726927140303275, 41.554118059984816],
            [78.758318205973325, 41.563135981998485],
            [78.958600262510714, 41.641618088582263],
            [79.059418129462784, 41.682444997959422],
            [79.147218230194738, 41.71993607750143],
            [79.340818233502119, 41.792173163287927],
            [79.526654079876295, 41.831344982059463],
            [79.659009192890068, 41.883282104126621],
            [79.693736256033247, 41.884818171706868],
            [79.722209245923722, 41.881900095927179],
            [79.753318176732051, 41.882453972090602],
            [79.79164509966111, 41.894882155239699],
            [79.81575413547003, 41.92480907005897],
            [79.826236208334393, 41.965854076557363],
            [79.844427117570746, 41.992036124665432],
            [79.873172351677084, 42.013427076905074],
            [79.896382344548925, 42.019954064925983],
            [79.923600228254344, 42.024954037822241],
            [79.97720031991625, 42.034127025044924],
            [80.025272378780443, 42.036345044269481],
            [80.062191309610142, 42.034682074674734],
            [80.139709162049911, 42.025791222313742],
            [80.179700228040105, 42.026618013245283],
            [80.213872241553446, 42.030791195209801],
            [80.245136237571018, 42.03953603481726],
            [80.269436213134867, 42.054264212204018],
            [80.281582261422244, 42.083145065504098],
            [80.246091271621509, 42.175227145304277],
            [80.234027198347491, 42.196218113123152],
            [80.285127135923148, 42.216064112965242],
            [80.293872310806506, 42.252182236763232],
            [80.270518149199773, 42.317836008139423],
            [80.253600283421264, 42.334682124826443],
            [80.238309177415516, 42.363291069186729],
            [80.158036193394281, 42.632454097469903],
            [80.171918133834112, 42.660509165666852],
            [80.242482191742141, 42.78912712332037],
            [80.257954179218956, 42.815654002926081],
            [80.281791306088024, 42.829818078227603],
            [80.312482315202857, 42.827809103668997],
            [80.340818177157104, 42.822191216868973],
            [80.369009199823864, 42.824964118085163],
            [80.572254091503993, 42.885453966538464],
            [80.574500273923178, 42.912182179459009],
            [80.55075417452278, 42.930027083731304],
            [80.521654218273028, 42.93496419235349],
            [80.490127198133251, 42.939400063164427],
            [80.44227223056177, 42.956909188394562],
            [80.388045172540757, 42.994827074447628],
            [80.384045160696161, 43.029054073245916],
            [80.615263313197971, 43.132182160357686],
            [80.654845174673966, 43.142044978213605],
            [80.678036224444583, 43.141909191381885],
            [80.712345198256145, 43.12190913215899],
            [80.743591257000872, 43.107745056857468],
            [80.774563395149102, 43.109273245448591],
            [80.803600319486605, 43.124818155483169],
            [80.815954071335483, 43.148018090071176],
            [80.815263402511818, 43.168300116517699],
            [80.77777232296981, 43.27107314620973],
            [80.739700209897961, 43.465800180221336],
            [80.681654189141369, 43.553291156363187],
            [80.619136255389975, 43.665791217079615],
            [80.583882305811727, 43.736354101521172],
            [80.553709298314658, 43.813227050328962],
            [80.543318252918965, 43.820527017462936],
            [80.529436144841071, 43.841354035255264],
            [80.51776333880818, 43.864973232641276],
            [80.508882209454754, 43.894553974858397],
            [80.5008272004807, 43.915800087810723],
            [80.435809280281291, 44.033864223508616],
            [80.368872239525587, 44.113782150110396],
            [80.366372253077401, 44.155245078302315],
            [80.387072368855428, 44.198582200049415],
            [80.396100181514811, 44.231636067676035],
            [80.399991228617722, 44.26121815099772],
            [80.378309256699055, 44.357744986426169],
            [80.356645222053118, 44.424136030007688],
            [80.349436114749921, 44.447191125308379],
            [80.384991309929177, 44.635800208174814],
            [80.512554153608193, 44.706154047950619],
            [80.515891324547709, 44.734082214133068],
            [80.43637237653823, 44.76553614407716],
            [80.28193614537534, 44.819427087780113],
            [80.262209168558826, 44.824427060676257],
            [80.229572384435329, 44.823873184512834],
            [80.151091283679847, 44.808873098186197],
            [80.053318226883704, 44.795535981454393],
            [80.018609268651545, 44.794009133967634],
            [79.998745163898633, 44.799009106863693],
            [79.87407223280448, 44.86823608357345],
            [79.871054077100638, 44.902818139791393],
            [80.076663336035296, 45.024700064758164],
            [80.113172391798486, 45.037336119106627],
            [80.139709162049911, 45.041927055126692],
            [80.163036166290425, 45.04109121173984],
            [80.192763256537347, 45.038318142885473],
            [80.261382209989193, 45.03609107120549],
            [80.40069128517294, 45.050400153432335],
            [80.426027263972145, 45.060045041805282],
            [80.524991221574538, 45.108591181114306],
            [80.743600309456355, 45.149554044961448],
            [80.765827272721054, 45.151918076939708],
            [80.916927171134745, 45.150254101516666],
            [81.00387231774036, 45.168309056283121],
            [81.147763276488746, 45.206082103048757],
            [81.239291312487438, 45.234554087110823],
            [81.396663389064798, 45.270391081875616],
            [81.451509199179981, 45.278173176081552],
            [81.486509177091449, 45.280945071469333],
            [81.53137230813482, 45.289700136998604],
            [81.556363287798462, 45.295818088143406],
            [81.582763265389787, 45.307200209773427],
            [81.605672347936576, 45.31998210923733],
            [81.646382245945205, 45.34191805282309],
            [81.679282221829027, 45.349700147029026],
            [81.713882215319785, 45.351918166253384],
            [81.751727178814889, 45.345118095826294],
            [81.783182282225454, 45.321373169892368],
            [81.797763273392121, 45.305809149118389],
            [81.811509259362111, 45.272754108025296],
            [81.8076182122592, 45.219518126238441],
            [81.824154198527907, 45.196226996543672],
            [81.948027160780583, 45.157482151921087],
            [81.977200207226275, 45.153864187224272],
            [82.01304524861817, 45.159418036283952],
            [82.043454122871282, 45.168864105912931],
            [82.071927280399763, 45.181364205791496],
            [82.123027217975419, 45.198582143704428],
            [82.168318161358371, 45.21275409799496],
            [82.224427124285597, 45.227754016683534],
            [82.270827161099788, 45.235809193295765],
            [82.296936119011917, 45.237753962475935],
            [82.32193615113087, 45.234009095764819],
            [82.348172346333655, 45.219836135645878],
            [82.459163329198219, 45.138873153353117],
            [82.481654154775612, 45.127473094450565],
            [82.514991330730226, 45.119418085476426],
            [82.545254192229748, 45.122335993618023],
            [82.566372397339279, 45.133027111148138],
            [82.586654088509619, 45.15261813349484],
            [82.60372718713532, 45.179282141036751],
            [82.632345183951003, 45.233036124441483],
            [82.642491310135284, 45.26609116553449],
            [82.652482203472204, 45.308027000704968],
            [82.658600322255069, 45.345527132702486],
            [82.659845202518142, 45.385391129040002],
            [82.655545118539095, 45.410254033222671],
            [82.646236176846429, 45.435809114971548],
            [82.610545194835339, 45.455945128664254],
            [82.58915407495769, 45.463864183168383],
            [82.561654224028558, 45.46970016708967],
            [82.525545152685964, 45.47580906577916],
            [82.485809231829336, 45.477473208840095],
            [82.409845216604509, 45.484564131308318],
            [82.35359124675179, 45.499418037243274],
            [82.335127255109143, 45.510536128922226],
            [82.319718131906512, 45.529418042258399],
            [82.314927203675921, 45.54479112327742],
            [82.321791311843555, 45.583100108933465],
            [82.387072256165339, 45.715118101800741],
            [82.413936255917775, 45.761927007852776],
            [82.445818165839142, 45.797553952123408],
            [82.464427164406999, 45.819436083890523],
            [82.480818143750497, 45.84081815131286],
            [82.49386340261313, 45.86360016420717],
            [82.510818148765708, 45.901927087136229],
            [82.520263380204284, 45.932764109004964],
            [82.534145153006108, 45.983873099036018],
            [82.558872270357057, 46.074991092330194],
            [82.560118156448397, 46.108736131694613],
            [82.562482356064749, 46.137209121585173],
            [82.571382260881165, 46.167764176230193],
            [82.607209197362153, 46.230118159954785],
            [82.633736244606013, 46.264154051360336],
            [82.655545118539095, 46.286100053229617],
            [82.671918160609692, 46.301654015719677],
            [82.692618276387606, 46.326791175775028],
            [82.708882353716632, 46.354154066405769],
            [82.7575092945726, 46.474064232464244],
            [82.797482255651886, 46.586927061950959],
            [82.80748220144423, 46.622753998431946],
            [82.815809286996142, 46.641927099085123],
            [82.842482346993648, 46.698864194048539],
            [82.873872239197141, 46.76360014702432],
            [82.898736149208304, 46.805400195362878],
            [82.929291203853239, 46.842618024860727],
            [82.952836137576895, 46.868045031128517],
            [82.971372381225166, 46.893318145653765],
            [82.997900266659116, 46.930400188319695],
            [83.011236209924704, 46.953182201214176],
            [83.018872291376937, 46.975545118948773],
            [83.031663410934442, 47.084436099785975],
            [83.038382344538689, 47.164573129337313],
            [83.03442725969532, 47.200618162939449],
            [83.061936163079764, 47.219991088165003],
            [83.121372238662872, 47.224709093837632],
            [83.15512716867309, 47.224709093837632],
            [83.185718265501833, 47.216636147590677],
            [83.18470925199486, 47.211664170251041],
            [83.230127265030063, 47.18415409340021],
            [83.256527242621388, 47.176382225116058],
            [83.299145197074068, 47.172491178013061],
            [83.328327128336952, 47.167773172340517],
            [83.354982251061557, 47.160273212996273],
            [83.42498220688438, 47.135691102570846],
            [83.443172277930245, 47.124154083369788],
            [83.460127191720886, 47.110691070451779],
            [83.558727207086548, 47.063327114769933],
            [83.612063268797755, 47.0487451177748],
            [83.693591191365869, 47.036945074451538],
            [83.738591282707631, 47.029436062651811],
            [83.760272248798088, 47.025554068004098],
            [83.78110027241874, 47.020827009876072],
            [83.806091252082382, 47.011935989876989],
            [83.853736169159532, 46.991100087267242],
            [83.89999119904823, 46.977209094371815],
            [83.930263280641242, 46.973318047268904],
            [84.085818160605101, 46.971654071845947],
            [84.11110032758566, 46.971654071845947],
            [84.143182229717439, 46.980409137375105],
            [84.179491293270445, 46.998673136807426],
            [84.223600220664167, 47.003882154369322],
            [84.294709269917945, 47.007500119066052],
            [84.319445104448306, 47.006664108041022],
            [84.594436237663473, 46.990827004860947],
            [84.65235418293932, 46.994436084740343],
            [84.678036166702583, 46.993609126170824],
            [84.718318252371802, 46.978673245222595],
            [84.774291260829301, 46.935682128439552],
            [84.781927174643357, 46.914436015487226],
            [84.773882223953109, 46.889435983368159],
            [84.760272360091079, 46.866664196395661],
            [84.744418157828505, 46.833154017958734],
            [84.803172281767303, 46.827700081185426],
            [84.962836138317385, 46.864509041445061],
            [84.976654208654878, 46.875544990472847],
            [84.984563372513293, 46.896445098461115],
            [85.023318275419598, 46.917636058490515],
            [85.064018282782428, 46.922773159323057],
            [85.082345146488677, 46.931800133791953],
            [85.097763322146847, 46.944991070132332],
            [85.098036236914965, 46.948327067605348],
            [85.168591242367512, 46.981935984861778],
            [85.191927131425501, 47.000136114191818],
            [85.205263409967102, 47.017209045179342],
            [85.218982238570703, 47.039000149477786],
            [85.266927227782304, 47.052627112422314],
            [85.298600260676068, 47.050827014891368],
            [85.380536214291936, 47.051100097297464],
            [85.522563373045188, 47.059109173442167],
            [85.565545102096593, 47.136800196002056],
            [85.689491322183272, 47.217973228788864],
            [85.701382225337312, 47.262218110652483],
            [85.702900188006566, 47.375964225098429],
            [85.682209292322199, 47.40915404719486],
            [85.639436105022156, 47.45277313616559],
            [85.621645180206571, 47.473327071551907],
            [85.609145247966097, 47.499436029464007],
            [85.603872360301722, 47.522218042358602],
            [85.61249130372309, 47.561936025942416],
            [85.623309155629642, 47.597500105939091],
            [85.622209282291891, 47.621100192585786],
            [85.585682289255686, 47.76416419276454],
            [85.556363397694469, 47.861100065069095],
            [85.547200301117499, 47.890273111514674],
            [85.537072279844296, 47.937218139674812],
            [85.544845153956572, 47.969918123348336],
            [85.569609151681533, 47.996382138680005],
            [85.5797091773982, 48.016391082720318],
            [85.601382264499449, 48.070827017769076],
            [85.605818135310386, 48.091936002785118],
            [85.603736238193818, 48.124718129110008],
            [85.618182280718969, 48.165127116793641],
            [85.739154098636646, 48.35638219886205],
            [85.765963280741886, 48.393327113591781],
            [85.783054149002425, 48.40581816101475],
            [85.803591320582285, 48.413882222444485],
            [85.838882318172779, 48.420827132158806],
            [85.858872319111669, 48.423327118606878],
            [85.915263416900785, 48.42888214113286],
            [86.030536211195312, 48.437073104576896],
            [86.098036180569949, 48.434718125054232],
            [86.138882200686481, 48.431936003744354],
            [86.1616541552971, 48.42860017390943],
            [86.186100311252545, 48.426654063624582],
            [86.234909307046138, 48.426591199350625],
            [86.297072351015828, 48.455827110070274],
            [86.307482339512802, 48.474300154168233],
            [86.322482258201291, 48.487773225370134],
            [86.34414511938067, 48.495273184714264],
            [86.382345140295314, 48.4995821535109],
            [86.414991312150306, 48.497491203938736],
            [86.437763266761095, 48.493882124059425],
            [86.468045238999736, 48.494436000222763],
            [86.597909250382969, 48.542773094866121],
            [86.605127242503755, 48.570273113433345],
            [86.638045155660478, 48.608044986732509],
            [86.654436135003976, 48.623882089912584],
            [86.709991221767865, 48.668600046392655],
            [86.758745232276254, 48.701382172717629],
            [86.778882251797455, 48.721100097078633],
            [86.807754220280032, 48.823882179226345],
            [86.768736125613174, 48.885691171605046],
            [86.74637236968843, 48.910891195934354],
            [86.734418266984193, 48.941373160383435],
            [86.731027284226144, 48.972000131758008],
            [86.770827242823231, 49.023609186133925],
            [86.876018283950344, 49.110200113510516],
            [87.120818137119471, 49.131100053860607],
            [87.175400252560195, 49.13221803210935],
            [87.201382308457909, 49.123045044886553],
            [87.316372297338575, 49.09777310382789],
            [87.348209280258772, 49.092618065722419],
            [87.373872320920754, 49.084991204363874],
            [87.412200249678193, 49.07832708840651],
            [87.446636125504341, 49.077491077381481],
            [87.466936256861658, 49.079718149061435],
            [87.507345244545405, 49.099164164482815],
            [87.51929130062237, 49.117218113420776],
            [87.705263269104506, 49.163044995694165],
            [87.807482254995392, 49.171382139529825],
            [87.836109304266387, 49.173327076348201],
            [87.840700240286566, 49.172954081655902],
            [87.868045193644463, 49.11360903354138],
            [87.898882215512998, 49.005554063729491],
            [87.892909271293519, 48.983045133241106],
            [87.873036114085124, 48.954918148314889],
            [87.842209150500395, 48.945264207486431],
            [87.81219120821234, 48.939991152183993],
            [87.774845135595655, 48.919573171267857],
            [87.761100323092109, 48.881036030206459],
            [87.842482232906576, 48.794653979857841],
            [87.933872302778667, 48.761800104441377],
            [87.957491332526587, 48.766382155644109],
            [87.991582209217, 48.765754015818359],
            [88.039154203736274, 48.747218107446329],
            [88.059418292909896, 48.734991089974059],
            [88.086482284872574, 48.696554028836928],
            [88.032482208789958, 48.6469450642022],
            [87.97436326547566, 48.61076424376823],
            [87.967482225863506, 48.587773186207968],
            [87.991936260808046, 48.565409095006899],
            [88.013609180271288, 48.556382120537918],
            [88.047209212710442, 48.544718031684383],
            [88.191227241111307, 48.500691079304133],
            [88.212627245806431, 48.501664218265361],
            [88.241782354979108, 48.505554091901885],
            [88.320263288096527, 48.479991131164198],
            [88.516791257829283, 48.405818161014835],
            [88.591091297631039, 48.348327022250018],
            [88.592136185683756, 48.316727079552194],
            [88.583045173474289, 48.287218086426449],
            [88.59845412903897, 48.230264227656605],
            [88.611782360953583, 48.211936022845848],
            [88.659854084541536, 48.179991081012375],
            [88.86137230416287, 48.116382158740848],
            [88.937336151749577, 48.114164139516376],
            [88.956372292104533, 48.102773133069022],
            [89.019009248881247, 48.050691171714462],
            [89.039154147391258, 48.030545099737949],
            [89.06331816848521, 48.005827034842596],
            [89.085136262511895, 47.993736139478315],
            [89.224845154478231, 47.980818117906225],
            [89.293354137359955, 48.001173234548489],
            [89.389918188438486, 48.046736086871093],
            [89.548727258500236, 48.042082118938978],
            [89.592763263336082, 48.027282192460788],
            [89.60264519193143, 48.007773145127246],
            [89.60137231611165, 47.982573120797937],
            [89.670263346141127, 47.911427023531857],
            [89.785536140435767, 47.829264255615371],
            [89.93123624917061, 47.83528212683602],
            [89.962627147202653, 47.848209033225416],
            [89.968800251270494, 47.877936123472423],
            [89.991800193648174, 47.893682199183829],
            [90.070963413685917, 47.887909079536499],
            [90.090400209013836, 47.8629821376134],
            [90.081100319776652, 47.830827145285852],
            [90.087072258167751, 47.789582146577217],
            [90.10026336214591, 47.768600063575477],
            [90.143109304365595, 47.73220919264746],
            [90.268327226805667, 47.697073092628088],
            [90.35120027719924, 47.627973185570838],
            [90.399427233634555, 47.556654085822643],
            [90.424991200200822, 47.527773232522648],
            [90.465818277215959, 47.500273213955509],
            [90.493309243327559, 47.31707314086124],
            [90.544145150952517, 47.244436070654146],
            [90.581663387860971, 47.19610014947726],
            [90.711382224680705, 47.041935994892199],
            [90.769018202732894, 46.997491120818339],
            [90.810818251071424, 46.994154117516828],
            [90.83568232872048, 46.994854006433869],
            [90.857209235429963, 46.98860010081917],
            [90.890272323150128, 46.970544978414537],
            [90.91360033321908, 46.952218114708344],
            [90.950272333180493, 46.889991200636061],
            [91.029163309002485, 46.75193605817087],
            [91.043309279393014, 46.724845076480037],
            [91.036372248667618, 46.67110903034812],
            [91.020245131636869, 46.600109113473749],
            [91.07026330078574, 46.577354090307566],
            [91.061091319391352, 46.542773207556294],
            [91.053591192409016, 46.523609159358429],
            [90.960536141285587, 46.351109088611693],
            [90.931227140370112, 46.325264160650292],
            [90.921509161801424, 46.296936010047133],
            [90.961927201940426, 46.197773233700715],
            [90.975263312844021, 46.173327077745185],
            [90.989009298814068, 46.155818120153029],
            [91.016727246864349, 46.134227008065054],
            [91.02665410246081, 46.108045127594991],
            [91.026509263173494, 46.017354108449737],
            [91.003391303598761, 45.995900124298046],
            [90.965000175291237, 45.973045021207682],
            [90.945527170141617, 45.958600152148946],
            [90.874145206119437, 45.903045065385058],
            [90.856936153023838, 45.887218020488703],
            [90.719436227826435, 45.735544967534381],
            [90.681927211011526, 45.579718178630813],
            [90.68387231546788, 45.492073143108158],
            [90.718318249577834, 45.469982134313383],
            [90.753454181959086, 45.450264209952181],
            [90.777209166176846, 45.436382101874173],
            [90.807754162538032, 45.371100151724065],
            [90.896945156286762, 45.253053953299016],
            [91.011382274832414, 45.22360011309631],
            [91.038872402753753, 45.224153989259733],
            [91.137909282914194, 45.213464212833983],
            [91.164291323232561, 45.200827152657311],
            [91.190663305267066, 45.170964107940407],
            [91.230545238877625, 45.151100170825586],
            [91.250545130462314, 45.143609096298675],
            [91.285954145249804, 45.134018019744289],
            [91.379154203298611, 45.119018101055715],
            [91.401927163737724, 45.126100138706519],
            [91.443591257606244, 45.153118030201526],
            [91.471163360540828, 45.152427026101904],
            [91.501936177030956, 45.124435995645499],
            [91.530272374261216, 45.096936144716452],
            [91.560882246553064, 45.0772820904577],
            [91.867754116223381, 45.074718066269085],
            [92.013045188082202, 45.082218025613415],
            [92.038591217375455, 45.084164135898064],
            [92.067491349052858, 45.083054036638444],
            [92.092482328716386, 45.079436071941629],
            [92.310254232106644, 45.026382145092199],
            [92.492763301101235, 45.003327049791423],
            [92.653454108431077, 45.022073176295677],
            [92.720536156112217, 45.033882104436529],
            [92.756945131951312, 45.041382231418837],
            [92.788109215682795, 45.047636137033649],
            [92.882754146204917, 45.045827154685199],
            [92.928654118674189, 45.025691140992492],
            [92.990263286480598, 45.009718083342605],
            [93.08665416743915, 45.009991165748787],
            [93.148609172571554, 45.010827009135753],
            [93.172209259218306, 45.013882212851726],
            [93.179427251339035, 45.016391084117217],
            [93.233045112635608, 44.998045109671651],
            [93.260545131202747, 44.989991106526006],
            [93.292072318980757, 44.983600072974909],
            [93.317491278621645, 44.984991133629748],
            [93.370254185791936, 44.99082711755112],
            [93.530818258745541, 44.962209120735352],
            [93.554700312615836, 44.957218032656428],
            [93.574436174249882, 44.950826999105416],
            [93.655254149617093, 44.923045013314763],
            [93.674991352355534, 44.915827021194062],
            [93.71609134413896, 44.896664146462754],
            [93.735263271325749, 44.875827070386535],
            [93.755554182589464, 44.862500179576429],
            [93.835536146931787, 44.828609127458392],
            [93.860809261456978, 44.818327046804299],
            [93.919709230511472, 44.797773111418067],
            [93.970263338550893, 44.777491084971743],
            [94.085400178375579, 44.722627002307476],
            [94.110809247370668, 44.709991115596992],
            [94.246091320981549, 44.632209065568432],
            [94.31346338251339, 44.590409184867937],
            [94.330272283550329, 44.575000061665193],
            [94.34304513055892, 44.557209136849437],
            [94.441927280786246, 44.515545042980889],
            [94.529982359013474, 44.479991188906084],
            [94.603182357839529, 44.451800166239323],
            [94.656372406796919, 44.415545082142941],
            [94.684700222123837, 44.392491160308722],
            [94.702900183815672, 44.372353973149544],
            [94.717345220512499, 44.354991195949182],
            [95.028736277111392, 44.257082184683313],
            [95.179154223880772, 44.272764222654175],
            [95.202772247800368, 44.275554055314885],
            [95.285809248220488, 44.28777319379823],
            [95.41060924896712, 44.294164227349242],
            [95.419982228400187, 44.27575404752524],
            [95.397345222430971, 44.248191164684144],
            [95.375818148083425, 44.226236110359267],
            [95.350263401610704, 44.177773119529903],
            [95.346100277929935, 44.158044969246916],
            [95.341091252578252, 44.019364201526841],
            [95.358872286748209, 44.009718139687592],
            [95.384154118452813, 44.005554010178386],
            [95.426654223346446, 43.998882182870076],
            [95.477763381015706, 43.993609127567808],
            [95.533391222699464, 43.993118115678342],
            [95.627063349536371, 43.859027110512031],
            [95.638882335961029, 43.805827003270721],
            [95.645827245675463, 43.784718018254679],
            [95.656372350451846, 43.759164109972389],
            [95.665263370451015, 43.738600116302351],
            [95.674700219986477, 43.719436068104571],
            [95.720127285477275, 43.632218174368816],
            [95.746372365497365, 43.584991178985277],
            [95.778591227927365, 43.533882188954024],
            [95.807482307149343, 43.488600130388633],
            [95.834300206434051, 43.454018074170804],
            [95.850954209899868, 43.435127108379319],
            [95.865400252425019, 43.410827132815385],
            [95.869427253997941, 43.354718169888073],
            [95.869982303627722, 43.319436057115112],
            [95.879009278096788, 43.283882203040307],
            [95.917209299011489, 43.234018093272127],
            [95.940263388483771, 43.216664200889269],
            [96.12302726733563, 43.081664262140052],
            [96.356936178402378, 42.906936113884953],
            [96.38206328017381, 42.734991092768027],
            [96.398463311972733, 42.731754001752719],
            [96.568600188931526, 42.744154021706933],
            [96.725818206128082, 42.754436102360941],
            [96.761109203718576, 42.756100077783984],
            [96.846372269990212, 42.758609116687566],
            [96.959718232377469, 42.759991124887094],
            [96.998600204936366, 42.761382185541848],
            [97.165400246231712, 42.795827113823393],
            [97.386154095503997, 42.767345071477521],
            [97.449709205957191, 42.757773105662451],
            [97.491363409180025, 42.751936115912955],
            [97.533054157500857, 42.746100131991668],
            [97.602763261282547, 42.736654062362604],
            [97.755263272806445, 42.714435983915436],
            [97.826518167176147, 42.703991126701183],
            [98.074154121111661, 42.66693607376358],
            [98.199145229251172, 42.64832707519561],
            [98.221372360153936, 42.645827088747538],
            [98.319718236214641, 42.640827115851479],
            [98.361927153791129, 42.638600044171412],
            [98.488309322585422, 42.631664186912403],
            [98.769145156901317, 42.615545116508812],
            [99.119709205738246, 42.594153996631164],
            [99.240372401980068, 42.586791165223119],
            [99.302200337459993, 42.583600174675425],
            [99.510127192629028, 42.571944970639308],
            [99.98637234787671, 42.653600130498049],
            [100.031518284334169, 42.649027131750742],
            [100.133327227520482, 42.644436028092699],
            [100.254991223004282, 42.641109083074909],
            [100.312763323164319, 42.689573247370745],
            [100.696927236802225, 42.681664251150423],
            [100.842482338611688, 42.677073147492266],
            [100.972763265860095, 42.652491204704816],
            [101.219709221524198, 42.604718044508772],
            [101.411372334640333, 42.56750004737286],
            [101.595545211419932, 42.530545074359523],
            [101.814700296475991, 42.509718056566996],
            [101.992200340118956, 42.32277311676161],
            [102.03553612076152, 42.276100165179386],
            [102.077209267085692, 42.233327145517492],
            [102.44246327183086, 42.151100172222485],
            [102.471363403508207, 42.154709084463704],
            [102.515263286236205, 42.161664220100207],
            [102.550263431785623, 42.166664192996265],
            [102.712491312524065, 42.161373200421096],
            [102.88275425330707, 42.096382102311921],
            [103.143882221545624, 41.994154063965723],
            [103.352200343956014, 41.912491192755922],
            [103.416382253130308, 41.887218078230873],
            [103.849427246929139, 41.80249111848768],
            [104.069427227827731, 41.804291216018626],
            [104.526654179993358, 41.877209079982876],
            [104.525272339432007, 41.761382074248942],
            [104.523736271851845, 41.670682170286184],
            [104.684418194364326, 41.645827145092525],
            [104.847636145508631, 41.649718024557458],
            [104.930545238086012, 41.651927158964597],
            [105.006509253310838, 41.586654093631822],
            [105.222491285091991, 41.746800077253894],
            [105.350809170611086, 41.785273180574663],
            [105.474982372636447, 41.83430010585117],
            [105.588318276739784, 41.891382207739994],
            [105.855263285798429, 41.987500173930314],
            [106.122482215453573, 42.077491136520351],
            [106.496372262702636, 42.20166417090762],
            [106.781936160974936, 42.295545007134294],
            [106.854709185652013, 42.300273238728707],
            [106.986100212159926, 42.310818008228964],
            [107.017763354407776, 42.315827033580618],
            [107.262500343303003, 42.359991113897351],
            [107.277209242312466, 42.410545054298765],
            [107.475818270971416, 42.466244980350083],
            [107.496509334293904, 42.463045104984772],
            [107.522763299131526, 42.449709161719269],
            [107.54484525547079, 42.430827080745203],
            [107.563045217162681, 42.415691207586733],
            [107.907763391432496, 42.408327035074322],
            [108.224427168057758, 42.458882148942038],
            [108.249445137449612, 42.462345048429754],
            [108.306645256535461, 42.437073107370978],
            [108.527772268138193, 42.442218087192359],
            [108.67998226581139, 42.422218027969549],
            [108.847072320957494, 42.399991064704963],
            [109.000000312458752, 42.458327099312228],
            [109.098036225739037, 42.44943607931306],
            [109.310672369401374, 42.429991069720174],
            [109.320827212764783, 42.447773109718511],
            [109.393600237441916, 42.450545005106292],
            [109.514009294378667, 42.456518116963863],
            [109.544709188310918, 42.473873182813193],
            [109.689700188035118, 42.555827073702105],
            [109.743872260771269, 42.5758271329248],
            [109.934972277630521, 42.641109083074909],
            [110.000000256113736, 42.642464269184288],
            [110.106372307401472, 42.645764224473666],
            [110.133391204724973, 42.673809066748632],
            [110.361191217102402, 42.75030918850193],
            [110.440536156801414, 42.777773164885431],
            [110.44886340999139, 42.797773224108241],
            [110.471918170015982, 42.846100092829616],
            [110.515545137975749, 42.870545075318674],
            [110.564700306371407, 42.896664259152757],
            [110.623027288523161, 42.939991154978074],
            [110.639972311668004, 42.956654043261196],
            [110.673600339663807, 43.00332699484342],
            [110.689972375906024, 43.053600141487621],
            [110.742754226177595, 43.094153968458571],
            [110.871372351469432, 43.202218158364147],
            [110.989700349479932, 43.316936070666927],
            [111.076927295671084, 43.359164266620908],
            [111.225809174860188, 43.405264231300393],
            [111.326663419272137, 43.432209032599545],
            [111.502772402260206, 43.494436114309835],
            [111.638600305407437, 43.54332708511653],
            [111.757218317268752, 43.649718079505462],
            [111.783863381709438, 43.672491207582638],
            [111.897218228914113, 43.674164235461191],
            [111.95832716473771, 43.692218016761146],
            [111.964427178609782, 43.712209191166536],
            [111.976791324018308, 43.769573092640897],
            [111.95971822539255, 43.835264247305176],
            [111.879436188915776, 43.938882172839826],
            [111.808027235165326, 43.996382196422175],
            [111.717482228773832, 44.033882160781516],
            [111.676645261112895, 44.050136012188645],
            [111.556927208275766, 44.17470903099651],
            [111.524991318897804, 44.243327146257897],
            [111.429418176415396, 44.332835981775972],
            [111.421372387534831, 44.375273222395705],
            [111.429009307177381, 44.419018039914349],
            [111.438863407854086, 44.439154053607055],
            [111.4586002753164, 44.458600069028435],
            [111.475536246005817, 44.476382109026687],
            [111.496091354858407, 44.498882154697569],
            [111.515827216492454, 44.521382200368535],
            [111.555545200076352, 44.570273171175231],
            [111.568600349584727, 44.596936005250868],
            [111.571663432289739, 44.620544976714982],
            [111.570545118764898, 44.648609097367327],
            [111.56790029302951, 44.677082087257688],
            [111.630809326384082, 44.774164139954038],
            [111.765263435597006, 44.973045077552783],
            [111.784991250603895, 45.000545096119808],
            [111.873027218091892, 45.049436066926418],
            [111.980818157953166, 45.091664262880485],
            [112.051091363820063, 45.100545056957671],
            [112.083941299741952, 45.091032183560216],
            [112.097209265772477, 45.075273115898895],
            [112.230818311504976, 45.071382236433962],
            [112.364700272005535, 45.067773156554651],
            [112.427200268483915, 45.080554050190372],
            [112.500263307011636, 45.033609189668326],
            [112.556500345419948, 44.993745025692633],
            [112.592482347110064, 44.951100081511626],
            [112.607482265798609, 44.92638218425445],
            [112.853045207434974, 44.846100147777619],
            [113.143045144156218, 44.795127112216264],
            [113.242482344013382, 44.78860917665078],
            [113.511109265767885, 44.777218170203625],
            [113.551936175145016, 44.752491220490683],
            [113.638045143087567, 44.745273228370152],
            [113.722209174211827, 44.793054099917114],
            [113.744709219882708, 44.810273211296632],
            [113.817209329791609, 44.8616641685514],
            [113.916927155767809, 44.92276421955755],
            [113.969982256083739, 44.92401815227619],
            [113.990809273876181, 44.925000176054922],
            [114.022772320620419, 44.929436046865774],
            [114.075545118436679, 44.939154025434661],
            [114.131100205200568, 44.980273127957204],
            [114.14553618944197, 44.996100172853389],
            [114.243045216287243, 45.070827019166174],
            [114.337200308386485, 45.129436136179649],
            [114.358600313081553, 45.143054046668752],
            [114.45400933789935, 45.206109092777083],
            [114.541091277165179, 45.322773115364626],
            [114.543318181207184, 45.348882240914904],
            [114.545254233207942, 45.38943606788574],
            [114.743045188114678, 45.440545057916907],
            [114.844991259237332, 45.410273143962073],
            [114.938027199621473, 45.382764240577515],
            [114.96943620256431, 45.384991144619406],
            [115.174427212318875, 45.403882110410891],
            [115.294709199603147, 45.40054510710938],
            [115.392209341631002, 45.398882137514832],
            [115.474700344876879, 45.414153965143115],
            [115.701927202713506, 45.45860018032154],
            [115.800809185302711, 45.545544991650999],
            [115.937191299889406, 45.635827141558238],
            [116.031372375888594, 45.685545070934452],
            [116.088600322892972, 45.68318221242275],
            [116.112200241901775, 45.681109032485622],
            [116.149991225940227, 45.693318112685077],
            [116.189563364408428, 45.708745005522459],
            [116.21040027284667, 45.721927057045249],
            [116.280063443798809, 45.808945126208741],
            [116.268463392685732, 45.843182183291034],
            [116.239291352068591, 45.876309141113595],
            [116.236091309065301, 45.900964174096785],
            [116.264991273104641, 45.950682103473113],
            [116.294009254341091, 45.996109168963798],
            [116.359145191737497, 46.083744984393078],
            [116.384163328767357, 46.112500109145216],
            [116.419282329704203, 46.141382135911712],
            [116.459427287437023, 46.168054022442831],
            [116.540818249706973, 46.225264199812344],
            [116.569991296152608, 46.252564226169127],
            [116.585536206187129, 46.295827084253901],
            [116.671800239338808, 46.32723608719688],
            [116.758609263836519, 46.331109029388884],
            [116.777063364833367, 46.351109088611693],
            [116.819027195560381, 46.383044977989655],
            [116.841663363339222, 46.393609193505469],
            [116.90220920290588, 46.383882162481186],
            [117.078600320755669, 46.361935992973727],
            [117.370818276701442, 46.364436147059777],
            [117.382209283148683, 46.399018035639628],
            [117.375527229918561, 46.41888214039254],
            [117.392763440380492, 46.457500083000625],
            [117.443863377956319, 46.527773121229657],
            [117.424982302810491, 46.57069114781676],
            [117.601927296823675, 46.605827080198125],
            [117.632754260408404, 46.551100125470057],
            [117.716663314037334, 46.513882128334231],
            [117.845545134003686, 46.53645409073458],
            [117.862972284220547, 46.564091069599911],
            [117.876082251375891, 46.581664232570589],
            [117.908454167358201, 46.605136076098219],
            [117.933591327413552, 46.619436105869838],
            [118.117482236969551, 46.678045055245136],
            [118.290818318741316, 46.731100155561123],
            [118.31470925742903, 46.736382095680895],
            [118.409572285072528, 46.719573194643985],
            [118.43664516185251, 46.699291168197547],
            [118.456236184199241, 46.694291195301403],
            [118.774636188253083, 46.686654108020875],
            [118.787554209825061, 46.716727035593848],
            [118.841718235934223, 46.763253974422284],
            [118.866500338569978, 46.772009039951527],
            [118.886936256759242, 46.769718098169207],
            [118.971918193997539, 46.742218079602182],
            [119.019709291466569, 46.71720916266581],
            [119.03851828224478, 46.693182101870079],
            [119.070600352014566, 46.672664208667683],
            [119.091663404201057, 46.666664107081786],
            [119.300536240965101, 46.620964126822983],
            [119.363036237443481, 46.61443613297358],
            [119.395545281362303, 46.61777313627509],
            [119.417200263552644, 46.626936065214053],
            [119.441082317422882, 46.636527141768354],
            [119.462772335968651, 46.639164256152739],
            [119.535809222958363, 46.63471815941999],
            [119.65262730492671, 46.623045018110972],
            [119.679282260013309, 46.604164110603378],
            [119.706791331035816, 46.598945034757477],
            [119.751391270318919, 46.613609174404061],
            [119.854709291357068, 46.656654103005664],
            [119.898036187182385, 46.676945014269407],
            [119.931509317606952, 46.71512709791115],
            [119.922491227955305, 46.902218050470353],
            [119.871372347278367, 46.921800187999636],
            [119.798736282899569, 46.998054049437073],
            [119.78999127565416, 47.019027079983303],
            [119.789045126421087, 47.083045039131022],
            [119.775272318360834, 47.109991181534653],
            [119.727482226720298, 47.16651806615549],
            [119.656100262698118, 47.219436038535193],
            [119.625527270780282, 47.241382208042737],
            [119.49802729137528, 47.321382109657677],
            [119.43914525959363, 47.356945016187879],
            [119.40670025341538, 47.370573152598993],
            [119.338318172548071, 47.419718095072582],
            [119.340818326634206, 47.462209147510762],
            [119.241091280564575, 47.509436142894501],
            [119.149427290095929, 47.533882131212025],
            [119.131345177963141, 47.634154000989597],
            [119.124972416961015, 47.664982138040827],
            [119.055800257898255, 47.685818040650659],
            [118.972209213676734, 47.710544990363431],
            [118.819763349247324, 47.756382098558717],
            [118.767291294117939, 47.772773245540108],
            [118.667209190628768, 47.878891157522943],
            [118.608018201895248, 47.940844989188847],
            [118.539336216531382, 47.994754037802792],
            [118.394145224596826, 48.003882098024334],
            [118.230272311536538, 48.029991223574527],
            [118.055545169109877, 48.013054079418723],
            [117.879545150863379, 48.01837323518869],
            [117.838318257065652, 48.015273104471575],
            [117.804554106961945, 48.011245097070272],
            [117.662182284349257, 47.894009093408485],
            [117.641345208273066, 47.874154041110998],
            [117.616482304090397, 47.84998214102815],
            [117.382672299481413, 47.657418140956239],
            [117.352463417438429, 47.657409088500728],
            [117.327754237360438, 47.669153978901065],
            [117.113436181002413, 47.806582155006978],
            [116.874691247065954, 47.888045034006424],
            [116.792763340077073, 47.881936135316934],
            [116.726927178487443, 47.876100151395732],
            [116.592209206961769, 47.857218070421581],
            [116.507763376251972, 47.845273187811003],
            [116.368591261366447, 47.858891098300134],
            [116.262363378813831, 47.878182216150307],
            [116.115536239374791, 47.821936125286683],
            [116.097209208030534, 47.804436052511974],
            [116.039963323753256, 47.752218136687574],
            [115.966082379111384, 47.690127009447096],
            [115.923118252056582, 47.69186424270417],
            [115.594409177620349, 47.917491162858298],
            [115.549072301408074, 48.143527119888333],
            [115.621372419106734, 48.173044997831582],
            [115.835818215669491, 48.252491190921191],
            [115.834427155014652, 48.273882143160833],
            [115.831672358709255, 48.306100167400515],
            [115.828872300126676, 48.338327076457588],
            [115.82221824245326, 48.403045092160468],
            [115.811100318412201, 48.520545125773126],
            [115.942200325241089, 48.663882208358089],
            [115.968045253202462, 48.690545042433811],
            [116.084154226160223, 48.815827002614228],
            [116.059009187115834, 48.868045086076606],
            [116.129963338798774, 48.975545006258884],
            [116.279982306975882, 49.199154067036758],
            [116.349154130762514, 49.301654014322693],
            [116.431091257844884, 49.422491218874953],
            [116.500545216493293, 49.524709031299167],
            [116.570263372730409, 49.626654096593469],
            [116.646654194466151, 49.738045063878658],
            [116.711382268452809, 49.830464263825391],
            [116.720827164615542, 49.82416425774305],
            [116.752209177830053, 49.809436080356235],
            [116.923600322783642, 49.733045090982401],
            [116.961382254366612, 49.716382202699194],
            [116.983045115546048, 49.709436119518372],
            [117.169982344000601, 49.652491145565946],
            [117.209991347263468, 49.641936150143735],
            [117.234709244520815, 49.636109051039867],
            [117.259427309416338, 49.631100193326276],
            [117.287491262430507, 49.626654096593469],
            [117.318327278470832, 49.624164168429203],
            [117.354700212126289, 49.624718044592626],
            [117.434418314155579, 49.626654096593469],
            [117.494427209003476, 49.623045016714244],
            [117.525272277499056, 49.614300177106784],
            [117.573318184825297, 49.596100047776744],
            [117.738591210995907, 49.535000164408686],
            [117.794845180848739, 49.518182210916265],
            [117.874709295631845, 49.520573232622937],
            [118.049427217965075, 49.604291178858958],
            [118.172209191697419, 49.668882124909359],
            [118.20596328351732, 49.690964248886885],
            [118.3658091950048, 49.778327149547792],
            [118.480672281870881, 49.841727027153567],
            [118.49233620308641, 49.869991140016353],
            [118.526382320413774, 49.896664200013859],
            [118.574572396475077, 49.922636029989761],
            [118.640827150310287, 49.948873231020841],
            [118.674700265155678, 49.955273149389356],
            [118.715891284407775, 49.949364242910221],
            [118.870818192183862, 49.972491087302373],
            [118.92150926052156, 49.986309157639838],
            [118.956236156026733, 49.982909122426307],
            [119.000263443683224, 49.976936010568735],
            [119.0667912799249, 49.983045076896204],
            [119.099636270523746, 49.990445123954359],
            [119.135818264424273, 49.994991132973254],
            [119.174145187353332, 50.001391218979748],
            [119.216582260334889, 50.017082141768029],
            [119.321027312077177, 50.096309064270187],
            [119.346482313901873, 50.150191123155636],
            [119.345945201544879, 50.193264214952038],
            [119.329009230855462, 50.210409062669029],
            [119.330054118908237, 50.275273258763875],
            [119.359991259649433, 50.330273128259947],
            [119.347963396197002, 50.346173095713979],
            [119.289982251371185, 50.347773201034684],
            [119.253118305826519, 50.341235987091792],
            [119.19831826090271, 50.345273214586413],
            [119.176654226256943, 50.352773173930828],
            [119.138600218095888, 50.390273138290368],
            [119.166454120616066, 50.412554081011606],
            [119.204154244823968, 50.410818021221004],
            [119.238036244486608, 50.440964206628095],
            [119.255545202078821, 50.470545116483223],
            [119.263318243829161, 50.514164205454037],
            [119.294145207414005, 50.597491219724859],
            [119.36110035308073, 50.633327041023179],
            [119.458800319680762, 50.696236074377694],
            [119.506027315064415, 50.749018092287471],
            [119.516663447309583, 50.793609146753042],
            [119.519436180887936, 50.846382112207337],
            [119.52081818908718, 50.902354114836342],
            [119.58970921911677, 50.972764113363596],
            [119.643872407035673, 50.997918037225261],
            [119.665000335152712, 51.003654108860545],
            [119.688763365997687, 51.018436098065749],
            [119.709991374039049, 51.036664222952325],
            [119.747891322819186, 51.076518161006234],
            [119.78026340643973, 51.168736027638047],
            [119.766245176253761, 51.18860013239113],
            [119.776091230303422, 51.213609049327417],
            [119.815463376561382, 51.269154077807499],
            [119.864318305184185, 51.287182210483721],
            [119.956645136557739, 51.389991114721667],
            [119.971363423298982, 51.423609084433608],
            [119.975191270851866, 51.46672710323115],
            [119.983045281787071, 51.493045105809117],
            [120.064145224378223, 51.620827052437605],
            [120.10770027560838, 51.664218153641443],
            [120.161582334493829, 51.666654102349085],
            [120.197200226308979, 51.686100117770465],
            [120.222209310883585, 51.712491210544258],
            [120.31025416318883, 51.773045096738016],
            [120.491645253934706, 51.878600080101918],
            [120.55748225371471, 51.895273194307023],
            [120.621372305019662, 51.9036001122209],
            [120.652027271950686, 51.914345041569547],
            [120.710963283188988, 51.979991101594706],
            [120.776654102577396, 52.11500009279932],
            [120.781791371047888, 52.157627099707597],
            [120.751663290551704, 52.210545072087129],
            [120.721918263031824, 52.251664174609672],
            [120.652545273568563, 52.29867324051024],
            [120.625909261583132, 52.344227040377248],
            [120.663736287805278, 52.40527311192686],
            [120.683309205241216, 52.428464161697534],
            [120.712172288906601, 52.542664240020812],
            [120.465000357131913, 52.6308180570676],
            [120.440263349135449, 52.637218143074179],
            [120.289427313034452, 52.615545055972845],
            [120.254436219940516, 52.605136073304507],
            [120.210536169574567, 52.586382235449221],
            [120.185582237923228, 52.579818031778117],
            [120.161909228718656, 52.578873223649495],
            [120.08192726437656, 52.588045037405792],
            [120.040600290654453, 52.606827038455705],
            [120.02574521125311, 52.641473132414077],
            [120.031436188248989, 52.768191080250389],
            [120.116836214819074, 52.806854117497707],
            [120.14886329930377, 52.807209174917446],
            [120.168736288874101, 52.809718046182851],
            [120.26873624971202, 52.857082169502959],
            [120.330554126908027, 52.897773124410023],
            [120.350691314067234, 52.925282195432558],
            [120.469454165215694, 53.020073138708582],
            [120.563327290091394, 53.077218104871363],
            [120.693863362472968, 53.15915405848726],
            [120.830691226671917, 53.263745122983252],
            [120.866509278335428, 53.279782218373654],
            [121.066091278317771, 53.291382101848498],
            [121.107127232360682, 53.278535997006045],
            [121.129963392349993, 53.272491136057099],
            [121.180545160669766, 53.269991149609112],
            [121.211936226339816, 53.271518164733763],
            [121.271927183914755, 53.29110918708038],
            [121.313454149847217, 53.3120731651711],
            [121.333600221823616, 53.319164255277499],
            [121.414291295176525, 53.31570906714073],
            [121.424136175759571, 53.316109051561526],
            [121.473791240861914, 53.323818055571437],
            [121.523036263259684, 53.333054074706169],
            [121.552754133413345, 53.339991105431849],
            [121.581936232314348, 53.3477731996377],
            [121.636109310878822, 53.36610006334395],
            [121.777482345906407, 53.401100208893396],
            [121.829991281409917, 53.413045091503975],
            [121.855554242147576, 53.41582704517576],
            [121.953454200958078, 53.422218078726857],
            [121.983045169096982, 53.421109152933539],
            [122.026091271165257, 53.413973135826168],
            [122.061436248212118, 53.412909137034163],
            [122.246091252063678, 53.456936089414526],
            [122.481654247907812, 53.448873201451349],
            [122.612763307192296, 53.456654122190898],
            [122.716382238555155, 53.455554081215084],
            [122.765682246237958, 53.45235403821188],
            [122.814845125984533, 53.449718097293712],
            [122.840818297064715, 53.452218083741784],
            [122.876663338456552, 53.46193606231067],
            [122.923600319989674, 53.478600124060378],
            [123.119836264214911, 53.497073168158337],
            [123.153318279457011, 53.500545120101336],
            [123.175263443136174, 53.510273156954028],
            [123.193863389248548, 53.522764204376912],
            [123.225809336910487, 53.546109145890327],
            [123.254709300949827, 53.553745059704553],
            [123.283054215359215, 53.553600052779259],
            [123.314418291301109, 53.549436090908117],
            [123.339154125831129, 53.542773148417226],
            [123.374563308256739, 53.529164122745513],
            [123.442745229275687, 53.522491121970987],
            [123.487200329271531, 53.53943614511563],
            [123.535263335680128, 53.542218098787501],
            [123.61470919349361, 53.543609159442255],
            [123.866382374924086, 53.480545060878555],
            [123.889972403286919, 53.473045101534311],
            [123.910809311724989, 53.464718183620434],
            [123.981463391273536, 53.43126416393504],
            [124.005127180384562, 53.408464213767743],
            [124.021791242134128, 53.385827040160464],
            [124.104427252305328, 53.340827116456879],
            [124.133182377057295, 53.336236012798551],
            [124.159982339069387, 53.349718136456048],
            [124.19442726735079, 53.360827008041511],
            [124.229427245262144, 53.363054079721465],
            [124.269009274376316, 53.346800060676273],
            [124.29677231706566, 53.320000098664352],
            [124.322209213979448, 53.28791802889468],
            [124.338863385083158, 53.255553991901209],
            [124.402209283232509, 53.212209158803162],
            [124.493309339253841, 53.188327104932668],
            [124.515263387750309, 53.188882154562563],
            [124.545818274757352, 53.190409169687413],
            [124.614436222380647, 53.191654049950458],
            [124.643872292948544, 53.187764176314104],
            [124.691782245804887, 53.170545064934501],
            [124.818327191159341, 53.126936034247578],
            [124.879009320472079, 53.146727048804635],
            [124.919009271279521, 53.171936125589454],
            [124.963609210562566, 53.182773255873272],
            [124.988309338185132, 53.186654077054484],
            [125.061918206249089, 53.195545097053483],
            [125.13416333866266, 53.197491207338302],
            [125.164991308075884, 53.193045110605382],
            [125.281100281033531, 53.149718047142173],
            [125.356091324935022, 53.118327149110129],
            [125.398609199463436, 53.101664260826894],
            [125.485527188703003, 53.069164269363611],
            [125.568327316538586, 53.056936078424954],
            [125.620045168018095, 53.050273135934063],
            [125.727482391564394, 52.98151806037437],
            [125.722272368174089, 52.945691123893468],
            [125.688300346871102, 52.932073213404337],
            [125.660263383585033, 52.923600115098694],
            [125.64499122068051, 52.897564247382363],
            [125.65734514016728, 52.875200156181378],
            [125.722491303485668, 52.88471814253991],
            [125.744427247071229, 52.889991197842349],
            [125.85574512416045, 52.86283601077271],
            [125.875809221123717, 52.836109138956729],
            [125.972891273819954, 52.763954028183505],
            [126.010954166798314, 52.777973096559734],
            [126.064845278139245, 52.781591228894612],
            [126.096791225801127, 52.757218163134851],
            [126.037200252646727, 52.716100066440603],
            [125.972972410642996, 52.637491225480474],
            [125.976645192986751, 52.610827050300301],
            [125.994282393697887, 52.576100154795128],
            [126.033200240802131, 52.564309163927177],
            [126.060400354872826, 52.580553962878881],
            [126.086863364376171, 52.589153963198939],
            [126.179982285602051, 52.544027137480768],
            [126.201782274717715, 52.527773118435661],
            [126.2131912184378, 52.510000130892919],
            [126.209427240987424, 52.50139107811718],
            [126.196354154206148, 52.476445025454851],
            [126.209018204111402, 52.460691070754521],
            [126.238800279643186, 52.46339121705104],
            [126.275672271815068, 52.453464193816487],
            [126.333882242598321, 52.395827042297853],
            [126.347272332958397, 52.36915398230046],
            [126.348600361701273, 52.291664125417284],
            [126.327763453263032, 52.234709093181053],
            [126.307482265006939, 52.212554046645735],
            [126.318882323909833, 52.192218040742759],
            [126.351927306718977, 52.183045053520075],
            [126.391372375534871, 52.176382111029184],
            [126.469845262025075, 52.165691161137048],
            [126.490536157709499, 52.159718049279476],
            [126.55498226447267, 52.126936090592679],
            [126.534572330183352, 52.06944511946584],
            [126.509427291139076, 52.037353997240658],
            [126.476791345205953, 52.034027052222868],
            [126.454563376112816, 52.02069110895745],
            [126.441227265209278, 51.99436422156198],
            [126.471100368210017, 51.929991204994778],
            [126.609154169570701, 51.787218056856958],
            [126.718382270554486, 51.71009113638236],
            [126.726372403597765, 51.634091078973753],
            [126.794427254964063, 51.423118072544142],
            [126.868872301691312, 51.405264115816337],
            [126.893600257232464, 51.398609052314654],
            [126.913882283678987, 51.38138222958419],
            [126.890382276956501, 51.350345047867037],
            [126.855809273193898, 51.344035989329413],
            [126.819845208776627, 51.327353990306889],
            [126.807336224080842, 51.30457315087888],
            [126.817618304734737, 51.265554050383699],
            [126.85110031997678, 51.241245022364367],
            [126.893527167036581, 51.2411731056349],
            [126.900263367361362, 51.260618115227985],
            [126.893772421524176, 51.303154094667306],
            [126.937391342856728, 51.314045036769684],
            [126.968872430167238, 51.319227064603282],
            [126.975191379350775, 51.299718017269939],
            [126.932063302269484, 51.242218161325766],
            [126.906100357110944, 51.210691141185819],
            [126.89874523705393, 51.181100173046715],
            [126.912491223023864, 51.125264124887607],
            [126.93310031133322, 51.058253993935935],
            [127.046109321211901, 50.955354062229318],
            [127.106091226331301, 50.929436044071892],
            [127.133182375660311, 50.909573112785452],
            [127.150545152860701, 50.888464127769325],
            [127.193036205298853, 50.824718077561485],
            [127.291927240343767, 50.74138217847333],
            [127.279209210982032, 50.684709113460528],
            [127.309772312254012, 50.648182120424408],
            [127.358582313875701, 50.595864124675941],
            [127.366918284245145, 50.576109152302521],
            [127.361782356879047, 50.551245074653465],
            [127.333172406690295, 50.524573188122545],
            [127.290882352290879, 50.459227032593915],
            [127.334018308361209, 50.314718159941592],
            [127.351654167967666, 50.299436106391326],
            [127.375536221838018, 50.286800052043034],
            [127.396100215508199, 50.278327121375369],
            [127.434418253619612, 50.265827021496804],
            [127.469009194654944, 50.257218136359242],
            [127.543318286912296, 50.231100125991631],
            [127.575545195969283, 50.216100207303171],
            [127.586063311017455, 50.208564205775119],
            [127.587309197109022, 50.197609058294219],
            [127.585527204488784, 50.167709133203161],
            [127.574436270176051, 50.13693614907497],
            [127.550327234367074, 50.10818219778939],
            [127.503182381634787, 50.067218160475861],
            [127.489554245223786, 50.045418171360282],
            [127.485945165344532, 50.018882239298975],
            [127.506245129063615, 49.827491202760754],
            [127.515009247048368, 49.805882153399935],
            [127.53567231481415, 49.785827108892008],
            [127.563600313358535, 49.77735401058645],
            [127.59166342818267, 49.77249116562659],
            [127.628309276606188, 49.765000091099679],
            [127.671782352823101, 49.746036035112255],
            [127.678454180131297, 49.708182186799647],
            [127.688791246070565, 49.670409140034181],
            [127.71304528880458, 49.653600071359094],
            [127.818318304944881, 49.592627090005422],
            [127.838045281761566, 49.586653978147851],
            [128.102754166684889, 49.536664139831814],
            [128.186763300237857, 49.534164153383657],
            [128.214418216376117, 49.536945101227033],
            [128.244982323476648, 49.548327055218792],
            [128.397754243940284, 49.577073127515447],
            [128.513445127566484, 49.587491162639381],
            [128.65304522242937, 49.592218053129315],
            [128.711909316938119, 49.584436126561386],
            [128.790254127947634, 49.568191159971704],
            [128.80331832991132, 49.546935994564038],
            [128.875245285279647, 49.47776417077732],
            [129.002445192550141, 49.435964122438762],
            [129.024109227195794, 49.415273226754138],
            [129.03926337290315, 49.385136093802643],
            [129.052309302318037, 49.363964244512729],
            [129.076872302004261, 49.35279116754883],
            [129.111782258275269, 49.34675401795063],
            [129.136354142778913, 49.352391183127949],
            [129.165509251951704, 49.375927064396237],
            [129.184709342333093, 49.387518063053832],
            [129.390500320928851, 49.427864186463481],
            [129.421200214861102, 49.431345023223898],
            [129.490200209632263, 49.415800113189434],
            [129.511536176586873, 49.404582109224108],
            [129.523463457200791, 49.3912361076748],
            [129.530718329695475, 49.313864267988535],
            [129.552445228615369, 49.287764194893938],
            [129.579936194727026, 49.273600119592331],
            [129.605209309252274, 49.268327064289892],
            [129.653563335340124, 49.277364264680827],
            [129.686036169437017, 49.278900164623096],
            [129.725536223537659, 49.262173238599431],
            [129.73660033576013, 49.227654046655644],
            [129.732091374753367, 49.202309183038935],
            [129.746318314328931, 49.181554081976046],
            [129.773563355400682, 49.170164248995277],
            [129.912709318748369, 49.071718125372414],
            [129.907636255656115, 49.041727005174621],
            [130.110509326110417, 48.932209057978056],
            [130.200672285354102, 48.878736035968828],
            [130.224609324509601, 48.864645050862961],
            [130.264282381092215, 48.860273217792653],
            [130.352172335826452, 48.884991115049829],
            [130.380245173658153, 48.891382148601096],
            [130.418027272879357, 48.89610015427364],
            [130.451491350848499, 48.892354114095937],
            [130.472454155472718, 48.878326999092806],
            [130.484682346411404, 48.860973106709778],
            [130.509018196520998, 48.851518152263381],
            [130.538027292940029, 48.851664165017112],
            [130.564272372960232, 48.856382170689656],
            [130.627682308849813, 48.874644996655476],
            [130.642818182008199, 48.875409090951209],
            [130.674000370650589, 48.860409172262379],
            [130.6705362976966, 48.839709056484637],
            [130.633845186995956, 48.810545062494541],
            [130.577182347905108, 48.713327055328207],
            [130.528872410628054, 48.626100109137028],
            [130.523591308698684, 48.605754044950189],
            [130.609409256962209, 48.519991081971725],
            [130.737454227713016, 48.429436017296368],
            [130.823991343271047, 48.326800115540451],
            [130.831500355070744, 48.293891087201061],
            [130.753872364422904, 48.19249118089094],
            [130.678372371359075, 48.138953953503105],
            [130.659836295349152, 48.108391187507138],
            [130.679563439803843, 48.047636135636751],
            [130.758027273838735, 47.988044994844344],
            [130.798036277101545, 47.965000125465551],
            [130.853854220349916, 47.9311090733474],
            [130.883727323350655, 47.90623611088084],
            [130.91330035421683, 47.865273247033812],
            [130.929963410137958, 47.838600187036477],
            [130.939836286278052, 47.817491202020349],
            [130.946072422257913, 47.797773110021168],
            [130.935109228149912, 47.740827130240419],
            [130.939972408385785, 47.720273194854016],
            [130.954682313223799, 47.706109119552579],
            [130.992172386937426, 47.687209101305584],
            [131.030809272646849, 47.677491122736896],
            [131.085936211795484, 47.672182192888798],
            [131.159700312706633, 47.693045085226785],
            [131.197209329521655, 47.706382201958874],
            [131.224682358360411, 47.718045117345739],
            [131.268191309123125, 47.730264255828999],
            [131.416654260790466, 47.738600058560465],
            [131.446363413764601, 47.737773099990747],
            [131.486754128899094, 47.73193611024125],
            [131.534972368155053, 47.719227133334911],
            [131.547054211064022, 47.697491181959705],
            [131.618009200937166, 47.663882097065184],
            [131.815245273852241, 47.674709169065196],
            [131.914982378205792, 47.669991163392567],
            [132.121609200188601, 47.692764123831651],
            [132.210800361575394, 47.713464239609593],
            [132.255245235649426, 47.728045063138225],
            [132.289700222214634, 47.736382206973971],
            [132.341063351550844, 47.742491105663291],
            [132.365236257462271, 47.742073183969936],
            [132.389263318257832, 47.736245079037602],
            [132.418309295050989, 47.725264115294848],
            [132.466372301459586, 47.716936023914613],
            [132.52109137719853, 47.710273249061785],
            [132.542482329438286, 47.71388216130309],
            [132.577882291770237, 47.733254080700064],
            [132.662063421977081, 47.871936021886526],
            [132.863863441183724, 47.994436028395441],
            [133.082736223740113, 48.098882085966025],
            [133.194118306207855, 48.109436075559941],
            [133.272218198005902, 48.104164193723975],
            [133.428727273829821, 48.085409182402387],
            [133.449263439581301, 48.074300143178661],
            [133.484272302310018, 48.0688820809509],
            [133.515182246736487, 48.075900080861359],
            [133.540800360397242, 48.098873201148692],
            [133.555818216358688, 48.120827082007182],
            [133.573718273553965, 48.151800058345728],
            [133.596345221239432, 48.17401813679281],
            [133.751372376577848, 48.249718122066994],
            [133.884709345732375, 48.264164164592231],
            [134.010954218952435, 48.305691130524494],
            [134.039272311271674, 48.323054075362833],
            [134.066372345418102, 48.32943605645842],
            [134.206909201782395, 48.342491205966908],
            [134.415345173751774, 48.391118146822706],
            [134.432109315425549, 48.378745116596733],
            [134.463863317504178, 48.359718028697344],
            [134.482727293567365, 48.350273132534753],
            [134.643863347595271, 48.291664183159256],
            [134.717472383297405, 48.27128207678858],
            [134.740754125260395, 48.26712699973497],
            [134.716418275151028, 48.260409071959131],
            [134.680754282868151, 48.214727028973059],
            [134.686154240185004, 48.182836066596209],
            [134.681363311954811, 48.155264131299717],
            [134.661027306051636, 48.100618145756712],
            [134.636763372671538, 48.088045123320271],
            [134.601627272652166, 48.076245079996923],
            [134.58177238799297, 48.060409150283434],
            [134.566227310320215, 48.032082173146662],
            [134.55760032027186, 47.992073169883653],
            [134.593654238691414, 47.911091076851605],
            [134.684354142653973, 47.800900062828603],
            [134.714954124300306, 47.77650017497858],
            [134.768463356131377, 47.737282088101452],
            [134.760818222223719, 47.700273135631377],
            [134.554782324416323, 47.469145004769857],
            [134.485791382100814, 47.429573201577838],
            [134.459136259376152, 47.427491136823093],
            [134.417327326220089, 47.431309093730178],
            [134.387482386414177, 47.430827134296194],
            [134.342745151556727, 47.426245083093576],
            [134.314700309281733, 47.421109155727507],
            [134.295809343490106, 47.413609028745284],
            [134.182463381102849, 47.323327046476138],
            [134.14929132864134, 47.248600200163352],
            [134.19051822243901, 47.18110911560602],
            [134.222836158964839, 47.129982188302122],
            [134.215191360333193, 47.0996732263349],
            [134.187163449502663, 47.083918098168184],
            [134.137127175442828, 47.076691221230064],
            [134.113591294174768, 47.055827155425604],
            [134.065800364343801, 46.954164224993193],
            [134.039363338740344, 46.86049109232782],
            [134.043291266217608, 46.766827012117929],
            [134.019436202075411, 46.666664107081871],
            [134.005245137045478, 46.644154003127184],
            [133.997745177701432, 46.640164217204571],
            [133.997463378115782, 46.633609065988779],
            [133.970927278416497, 46.611318064983735],
            [133.90891829382744, 46.578118016965234],
            [133.847182391644623, 46.478882150422962],
            [133.850527273935228, 46.443227042957503],
            [133.902800342682639, 46.421218176814094],
            [133.929718321891556, 46.381882240377948],
            [133.901609274237984, 46.253627051494831],
            [133.830245247488762, 46.194991112391037],
            [133.744691329176106, 46.155818120152944],
            [133.723827263371476, 46.069027032928304],
            [133.660236278372821, 45.935164183166947],
            [133.579500278018685, 45.87129106330643],
            [133.550600313979288, 45.884645111482754],
            [133.521091320853515, 45.883418168492526],
            [133.476054181499677, 45.823464258929917],
            [133.474945255706331, 45.797736007061033],
            [133.487536215415588, 45.678253988617982],
            [133.470245187306801, 45.624573095409275],
            [133.417209197730102, 45.589718124423328],
            [133.382172339444793, 45.569991147606814],
            [133.268309213630261, 45.525553984883786],
            [133.244109317990507, 45.516109088721194],
            [133.216627236696269, 45.511109115824851],
            [133.192245286119174, 45.503818033508296],
            [133.149136152139107, 45.450827138571],
            [133.130236301530118, 45.414573227941091],
            [133.101900271937865, 45.294444964761468],
            [133.099200293279608, 45.228882220854146],
            [133.113863427097527, 45.197209187960667],
            [133.126209299957139, 45.164718081314803],
            [133.117463454521356, 45.124573123581783],
            [133.074672329948811, 45.088882141570693],
            [133.025272409979749, 45.056936026270748],
            [132.952609355872738, 45.02458221519943],
            [132.931454270389139, 45.021409161924353],
            [132.871291316160551, 45.038182188415632],
            [132.799409287793509, 45.064991202883064],
            [132.568027185265152, 45.116391212593228],
            [132.364136216486287, 45.161100116617902],
            [132.299136233559722, 45.175273244374907],
            [132.196927305953039, 45.197491155184096],
            [132.122745283348223, 45.213609052121214],
            [131.992427308087798, 45.241436132551186],
            [131.961363304280525, 45.255964150089667],
            [131.923718332995662, 45.286109162030201],
            [131.910382389730358, 45.308609040063075],
            [131.89580022509719, 45.337564157025511],
            [131.868436160999892, 45.345818152381511],
            [131.836154266657871, 45.334300076281679],
            [131.703309315221048, 45.216100153752151],
            [131.652754201353247, 45.133736052520575],
            [131.646382278541608, 45.108054068757312],
            [131.628018199184993, 45.09151808248869],
            [131.469118269292636, 44.973873209588888],
            [131.341082350997141, 44.970953960342811],
            [131.314409290999635, 44.955127083084633],
            [131.286163450685848, 44.931800078844034],
            [131.212463387515044, 44.910545081074375],
            [131.17712729528543, 44.911864224999746],
            [131.149272386936985, 44.928054038666417],
            [131.12454526958615, 44.927353982111114],
            [131.092191290876514, 44.918600090048514],
            [131.067745134920955, 44.909718122504657],
            [130.952991348072629, 44.836491133950588],
            [131.000672307333474, 44.781936008237992],
            [131.019836187892935, 44.773044988238823],
            [131.055591375282603, 44.753464191814189],
            [131.095518235894275, 44.691373232211888],
            [131.128572438797079, 44.585818081209808],
            [131.255554248946197, 44.184718029244422],
            [131.284972382241421, 44.093609088405557],
            [131.298036248929037, 44.051936109719762],
            [131.262754136156019, 44.037354112724628],
            [131.244418219994401, 44.021382228541043],
            [131.231082276728813, 43.973873098295698],
            [131.199127276611506, 43.818882152779096],
            [131.19122716520863, 43.536245047465897],
            [131.223018215299334, 43.511664110506743],
            [131.235509262722445, 43.505554038350979],
            [131.272763302041966, 43.481936014431355],
            [131.283054267513393, 43.473418156762634],
            [131.301009309993759, 43.457145026978154],
            [131.310391341882195, 43.389582193329531],
            [131.264163301721823, 43.270273177368594],
            [131.191927221763564, 43.185818126565223],
            [131.106200300968993, 43.038891074840095],
            [131.10302724769403, 43.01555401231569],
            [131.103991334199947, 42.982209125010129],
            [131.114963413125366, 42.958609038363434],
            [131.133372419483038, 42.939709187754445],
            [131.128491301974094, 42.916445047788002],
            [131.021363370655848, 42.857082230038927],
            [130.887191228666637, 42.851800122280977],
            [130.865509256747799, 42.857773234138378],
            [130.841645140150462, 42.870273166378936],
            [130.818572442853025, 42.876100097844827],
            [130.795536290653587, 42.871936135973598],
            [130.638027253777892, 42.831664108588271],
            [130.532745185182108, 42.789991129902305],
            [130.475527296461536, 42.76527323264493],
            [130.43273617188882, 42.744854078262151],
            [130.411118237710497, 42.703118067664022],
            [130.471145237469074, 42.679164264702237],
            [130.497327285577313, 42.684854068231729],
            [130.536091240939157, 42.68200019783076],
            [130.58496343628272, 42.645000130177991],
            [130.605227190180273, 42.619709078379927],
            [130.6187093138374, 42.58540915702379],
            [130.607463314315538, 42.56360011545253],
            [130.580263367882907, 42.481100059751313],
            [130.604372403691826, 42.421864144016482],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CIV", Country: "Cote d'Ivoire" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-6.212499767303143, 10.569444943213],
            [-6.183054979555891, 10.463608998453992],
            [-6.181663918900995, 10.434718086870021],
            [-6.180281910701638, 10.403053938793846],
            [-6.17999977583986, 10.364718131047255],
            [-6.197163901934402, 10.236364203344522],
            [-6.188336752037685, 10.231664134944822],
            [-6.145554847558401, 10.211945037117118],
            [-6.111390880672104, 10.1977730828267],
            [-6.090281895656005, 10.19083605210119],
            [-6.004163875258087, 10.191245088977212],
            [-5.977363913246165, 10.216527088319765],
            [-5.96402679651419, 10.237636073335921],
            [-5.937499916908564, 10.284444979387956],
            [-5.88221774491268, 10.369718103943626],
            [-5.797499837624969, 10.421245015668205],
            [-5.782308811543487, 10.42642704350186],
            [-5.74639084759383, 10.423335965240085],
            [-5.726108821147307, 10.422782089076861],
            [-5.701808845583571, 10.430000081197477],
            [-5.669236769752786, 10.445973138847364],
            [-5.565690928585639, 10.457218132540902],
            [-5.519845941401513, 10.436273097551464],
            [-5.520836682359516, 10.4277731771557],
            [-5.470281903767955, 10.348054069297575],
            [-5.40409990485216, 10.297636083366172],
            [-5.371108901499554, 10.2894449522841],
            [-5.33833683345847, 10.296945079266493],
            [-5.307499811589878, 10.305273170646785],
            [-5.268608786575612, 10.319164163542098],
            [-5.243890889318266, 10.319718039705492],
            [-5.128190785598576, 10.303053977955813],
            [-4.990281823525038, 10.063891122326027],
            [-4.949717770632333, 9.948609107937898],
            [-4.830281852656981, 9.862500139995404],
            [-4.786390854746344, 9.828054038247387],
            [-4.785417883423008, 9.782917992435728],
            [-4.810208870876238, 9.777845096981594],
            [-4.775281815522817, 9.73666413601336],
            [-4.704445848674936, 9.6980540723942],
            [-4.655208872904097, 9.702918090820674],
            [-4.604999764000468, 9.720691078363473],
            [-4.514717781731406, 9.690273151654878],
            [-4.426108827340727, 9.657500077785357],
            [-4.330490925495155, 9.615282107753274],
            [-4.312454913829924, 9.599973064474739],
            [-4.309717887159366, 9.601109147634361],
            [-4.283054885445722, 9.651664093863985],
            [-4.279445973204417, 9.675827109129614],
            [-4.262008764703694, 9.742918041628286],
            [-4.124163840370727, 9.829309144432386],
            [-4.034163825325209, 9.806945053231402],
            [-3.936390768529236, 9.863054016158628],
            [-3.903472855372399, 9.882218064356437],
            [-3.890836801023966, 9.894236037163012],
            [-3.882217857602768, 9.897782085130302],
            [-3.75360878476647, 9.935000082266185],
            [-3.706390841838243, 9.943609135041754],
            [-3.637081890115212, 9.9544450918591],
            [-3.394163941852241, 9.920000163577583],
            [-3.208608889235279, 9.90139116500967],
            [-3.123890814309448, 9.836109047221498],
            [-2.986108754250438, 9.724444997530099],
            [-2.969163898743716, 9.699864228209151],
            [-2.937499918305548, 9.627773155176385],
            [-2.920972816854146, 9.576809004432505],
            [-2.850836738923306, 9.481391094797345],
            [-2.801390886124921, 9.423891071214996],
            [-2.779717799023672, 9.403609044768587],
            [-2.757017761142436, 9.395691163730817],
            [-2.731663845070386, 9.410836089344684],
            [-2.685563880390816, 9.481818068946154],
            [-2.674717865289438, 9.465973086777353],
            [-2.667781840392536, 9.382773142158726],
            [-2.687081843060128, 9.225273157738741],
            [-2.713754903057634, 9.199718075989836],
            [-2.760836723877901, 9.121664117021453],
            [-2.767499834006799, 9.063609043809436],
            [-2.729581947953818, 9.03291803469449],
            [-2.659899833900454, 9.014545070520626],
            [-2.616663965543978, 8.914445029758497],
            [-2.598190921445905, 8.8166640939736],
            [-2.617799881065423, 8.784136106953611],
            [-2.611390910241539, 8.780136095109185],
            [-2.584990932650328, 8.781245020902304],
            [-2.580526731006671, 8.768173107587529],
            [-2.57083674799452, 8.713336014651475],
            [-2.559445909185342, 8.64471806702818],
            [-2.495554852052067, 8.249444946528513],
            [-2.487781810301499, 8.197773195516675],
            [-2.560281920210343, 8.164164110621982],
            [-2.600281871017899, 8.121944967123625],
            [-2.660281881048206, 8.023053932078881],
            [-2.775136753649292, 7.94291807599393],
            [-2.784999906781593, 7.853609065048204],
            [-2.83971780905398, 7.756664140288365],
            [-2.854990810148763, 7.741982063368994],
            [-2.923608757772058, 7.59555407016029],
            [-2.948608789891068, 7.431945019412638],
            [-2.973336745432334, 7.262218017520354],
            [-3.023890853471642, 7.073054052661803],
            [-3.096354921196735, 7.05061804473155],
            [-3.118890841413361, 7.001945003407968],
            [-3.224445992415269, 6.818618028299284],
            [-3.208890856458822, 6.753336078149061],
            [-3.206663952416932, 6.695282178403488],
            [-3.241945897551886, 6.642773075262028],
            [-3.248890807266321, 6.613745035741829],
            [-3.231945951759599, 6.542773114424165],
            [-3.207217828580326, 6.446944994446255],
            [-3.169163820419499, 6.292773128510362],
            [-3.167217877772885, 6.269582078739688],
            [-3.168036789715359, 6.256308999748228],
            [-3.147081864080121, 6.247918044093979],
            [-3.094717767863926, 6.145273089882664],
            [-3.08860886917455, 6.111664172625979],
            [-3.07028183783018, 5.991944946322462],
            [-3.006390780696847, 5.85778219206486],
            [-3.013608772817577, 5.707500032127314],
            [-2.960281931200029, 5.627217995650653],
            [-2.930972930284554, 5.612009032296157],
            [-2.899999786307887, 5.616944967451843],
            [-2.879999894723198, 5.624445094434236],
            [-2.839999776277523, 5.629445067330437],
            [-2.797499839022009, 5.615554074435067],
            [-2.764445971395276, 5.579164209335374],
            [-2.741663958500794, 5.446391174628047],
            [-2.768890894661695, 5.350554002194656],
            [-2.783608846126754, 5.280282137432266],
            [-2.762636821408989, 5.204164230464585],
            [-2.744163944948866, 5.173053958551861],
            [-2.729308865547637, 5.141391151579967],
            [-2.734445966380179, 5.112782039581816],
            [-2.753608841111543, 5.100835983504624],
            [-2.928126938489527, 5.100218107067178],
            [-2.975554738647304, 5.083054244253276],
            [-3.041663738507168, 5.107218157119291],
            [-3.102272829087497, 5.109545117377593],
            [-3.102854570152829, 5.090381701005583],
            [-3.103045859244332, 5.085017991063978],
            [-3.103940776612831, 5.085230471131979],
            [-3.167588726999895, 4.79499813800021],
            [-3.335079334999875, 4.071877875000325],
            [-3.412733756999842, 3.694130067000117],
            [-3.659801898999888, 2.458616979000169],
            [-3.722407837999867, 2.146261110000239],
            [-3.789113593333127, 1.824909233666858],
            [-3.914193133999902, 1.848820651000153],
            [-3.989916535999896, 1.864535271000079],
            [-4.085364578999872, 1.880719238000211],
            [-4.089563593999856, 1.881093032000251],
            [-4.090740997999831, 1.881410388000234],
            [-4.096526363999885, 1.881925354000202],
            [-4.097854312999971, 1.882283276000123],
            [-4.100875618999851, 1.881478489000244],
            [-4.103760959999931, 1.881230418000257],
            [-4.105861477999838, 1.880670886000303],
            [-4.114504180999859, 1.87992773100018],
            [-4.117809319999935, 1.879047262000256],
            [-4.120253877999971, 1.878837035000174],
            [-4.122795193999821, 1.878160024000238],
            [-4.176322585999969, 1.869353339000327],
            [-4.177579347999881, 1.869018437000307],
            [-4.178982980999706, 1.868897621000258],
            [-4.181562020999934, 1.868210347000229],
            [-4.184117864999962, 1.867990340000233],
            [-4.186547962999953, 1.867342739000208],
            [-4.248674503999894, 1.861992125000143],
            [-4.250301129999855, 1.861558500000058],
            [-4.828886716999818, 1.811491825000303],
            [-4.833104600999889, 1.811863443000107],
            [-4.8381343974998, 1.811475284500261],
            [-4.857765837999864, 1.809817844000236],
            [-4.863355492999972, 1.810310119000178],
            [-4.878710970999862, 1.808974946000319],
            [-4.880829829999925, 1.809161501000176],
            [-4.956221398999787, 1.802601640000205],
            [-5.059441981999839, 1.75450371900024],
            [-5.071070154999973, 1.751390980000338],
            [-5.09654072699982, 1.739556789000233],
            [-5.098654239499808, 1.738673645000119],
            [-5.100552199499816, 1.737895981000122],
            [-5.10231555199988, 1.737116604000164],
            [-5.105152265999891, 1.736328374500204],
            [-5.10815960799988, 1.735464827000214],
            [-5.109091401999876, 1.735185718000309],
            [-5.110286729999928, 1.73462853000018],
            [-5.113174607499786, 1.733830685000157],
            [-5.114028330999815, 1.733577492000279],
            [-5.115276449999953, 1.732995687000198],
            [-5.140117626499944, 1.726328942000237],
            [-5.1420725159997, 1.725790579000204],
            [-5.14637678049985, 1.723821614000087],
            [-5.148164988999895, 1.72302560400027],
            [-5.151028155499944, 1.72222931250019],
            [-5.154091154999833, 1.721366152000144],
            [-5.160086066999838, 1.71973424700019],
            [-5.176914833999945, 1.715213926000217],
            [-5.185070617999827, 1.711411301000226],
            [-5.189874841999824, 1.710124446000066],
            [-5.205002548999914, 1.703070773000263],
            [-5.207916928499742, 1.702270690500256],
            [-5.211493563999909, 1.701293184000178],
            [-5.21329993299986, 1.700515546000133],
            [-5.214982353999915, 1.699795701000198],
            [-5.241777103999823, 1.692616966000116],
            [-5.304898165499878, 1.663212470500127],
            [-5.306998188499904, 1.662320706000173],
            [-5.309036164499844, 1.661487914500071],
            [-5.31071776899995, 1.660768381000082],
            [-5.313557304499852, 1.659979086000249],
            [-5.316590665499803, 1.659119879500167],
            [-5.393889308999917, 1.638378892000219],
            [-5.49744805399996, 1.590107252000166],
            [-5.499178554999844, 1.589354754000283],
            [-5.504509603999907, 1.587924478000218],
            [-5.569000917999858, 1.557819714000345],
            [-5.601042413999949, 1.549219620000201],
            [-5.883344971999804, 1.417380581500197],
            [-5.885383633499885, 1.416497781500141],
            [-5.887186625999902, 1.415696100000275],
            [-5.890695370999879, 1.414752903000135],
            [-5.892182951999814, 1.414064735000238],
            [-5.90813033399985, 1.406618708000281],
            [-5.962959759999819, 1.39187652700025],
            [-6.256440406999786, 1.254657956000187],
            [-6.258271237999793, 1.253830804000359],
            [-6.259341849999771, 1.25354249600008],
            [-6.262259604999826, 1.252177358000324],
            [-6.268091823499844, 1.250574220500198],
            [-6.271233722999796, 1.249694662500303],
            [-6.274141699499864, 1.248888799500321],
            [-6.274903519999896, 1.248661822000201],
            [-6.276230447999978, 1.248040961000072],
            [-6.290647119999903, 1.244158219000155],
            [-6.332099377999896, 1.224760949000199],
            [-6.892703027999858, 1.073551236000185],
            [-7.470945945999858, 1.021654462000186],
            [-7.471707672499861, 1.021676825000213],
            [-7.540061348999927, 1.027420395250203],
            [-7.540034416999788, 2.044021665000344],
            [-7.540017033999845, 3.061315299000171],
            [-7.540009070999815, 4.078498284000148],
            [-7.539900772999829, 4.093270466000092],
            [-7.545818372999861, 4.163815907000242],
            [-7.541526526999917, 4.297144638000248],
            [-7.538009670999827, 4.324868229000231],
            [-7.53451978899983, 4.343974196000033],
            [-7.53310121685945, 4.350759405943791],
            [-7.532596517999878, 4.353148427000349],
            [-7.531275179938291, 4.351245405768509],
            [-7.525399894606039, 4.352809113184378],
            [-7.556045927522803, 4.378091125118999],
            [-7.559999838899842, 4.454445066480673],
            [-7.554445989840133, 4.537218036949923],
            [-7.551108818900673, 4.605973112509616],
            [-7.557781819675313, 4.744164209444762],
            [-7.561390899554624, 4.781527045867904],
            [-7.587426767270813, 4.810818109510421],
            [-7.590836693130257, 4.821945086006849],
            [-7.588336706682185, 4.905836034725098],
            [-7.56055488852968, 5.051391136534448],
            [-7.503681831306608, 5.095418088914982],
            [-7.368399757695556, 5.325691098012101],
            [-7.384181875590627, 5.368382142660778],
            [-7.425972871473789, 5.395973188696416],
            [-7.436390906597694, 5.433891074749454],
            [-7.416390847374942, 5.485836075805537],
            [-7.391390815255932, 5.540835945301637],
            [-7.372499849464475, 5.569445057300186],
            [-7.399763833637451, 5.631427052160916],
            [-7.405554890557482, 5.646391096303716],
            [-7.431663848469668, 5.715000159109536],
            [-7.436108771736087, 5.743609103469907],
            [-7.433054909124678, 5.789445038198664],
            [-7.427781853822182, 5.811391040068145],
            [-7.425690904250075, 5.845554001126061],
            [-7.447845950785506, 5.860073133847095],
            [-7.498054892051186, 5.843335981901646],
            [-7.563608751140976, 5.883053965485431],
            [-7.654999826841561, 5.938054002619708],
            [-7.687572908500613, 5.910764202184794],
            [-7.757636734425716, 5.946664228861721],
            [-7.783472945207791, 5.97805395342715],
            [-7.779717852574663, 6.014309037523617],
            [-7.78916392220367, 6.070553954920911],
            [-7.82569980005718, 6.206109110937916],
            [-7.875836824593421, 6.24528210317601],
            [-7.897781820634464, 6.261944991459075],
            [-7.999445924533518, 6.300554049249911],
            [-8.067499770071606, 6.29360913953542],
            [-8.122217839982056, 6.277782094639235],
            [-8.143608792221812, 6.275554017130787],
            [-8.16972680258931, 6.275000140967336],
            [-8.374445903404137, 6.360554059280162],
            [-8.475281875267115, 6.435835955222672],
            [-8.544099815100594, 6.492153962815735],
            [-8.606381882095889, 6.507818063513866],
            [-8.602917809141957, 6.522982099867022],
            [-8.578399904094908, 6.529309095677661],
            [-8.56701778246503, 6.552118098300468],
            [-8.54013668363018, 6.561945041610954],
            [-8.528472930052828, 6.595864089285726],
            [-8.504345956970951, 6.605345027631927],
            [-8.482881914535483, 6.620208991850689],
            [-8.457781802492377, 6.63645395844037],
            [-8.44680888537664, 6.656127123438239],
            [-8.435072879793722, 6.652418131272782],
            [-8.418054934091117, 6.667218057751086],
            [-8.378608859446956, 6.715553978927971],
            [-8.364163822750186, 6.739445085253749],
            [-8.339726719249995, 6.784445008957334],
            [-8.327499869415647, 6.810553966869804],
            [-8.308890870847733, 6.858054044659468],
            [-8.290554954686172, 7.022782079484273],
            [-8.28666390758309, 7.178473081555978],
            [-8.323336745734991, 7.203054018515161],
            [-8.350554964716423, 7.22944494365089],
            [-8.391945976178874, 7.315273117836057],
            [-8.403054847764395, 7.377218064684826],
            [-8.409999757478772, 7.420282104025773],
            [-8.409717790255229, 7.453535963862748],
            [-8.415836747228525, 7.496391126175979],
            [-8.436117767846554, 7.531426978633078],
            [-8.469745963480364, 7.561327071361916],
            [-8.420281838132951, 7.604718004927577],
            [-8.398199881793573, 7.618191076129392],
            [-8.285554981789858, 7.582500094118302],
            [-8.218746016515013, 7.545000129758932],
            [-8.186463954534872, 7.573054024489409],
            [-8.186808786032543, 7.600973138216432],
            [-8.184445927520756, 7.621527073602692],
            [-8.094445912475237, 7.782500015794199],
            [-8.080426844099009, 7.805400045885733],
            [-8.111454973360424, 7.841182055365607],
            [-8.119026849434363, 7.864791026829607],
            [-8.0766638724769, 7.949164102619648],
            [-8.055972809154355, 7.976664121186673],
            [-8.050417786628486, 8.018891143674239],
            [-8.053390847693038, 8.03281801111541],
            [-8.018890934126432, 8.030282150121479],
            [-7.946836741467791, 8.018509096526373],
            [-7.951254842643891, 8.036245036057096],
            [-7.999445924533518, 8.15389108242357],
            [-8.020554909549503, 8.179718073112042],
            [-8.063054846805187, 8.163745015462155],
            [-8.109999874965183, 8.174445017809603],
            [-8.174717890668148, 8.197773195516675],
            [-8.228890801594616, 8.240273132772188],
            [-8.231946005310533, 8.274444978647637],
            [-8.234726785515988, 8.31500014672298],
            [-8.241017739142819, 8.446545064973577],
            [-8.188399838897737, 8.49916413868516],
            [-7.948890810665773, 8.501664125133232],
            [-7.817081862464306, 8.486109156814848],
            [-7.784163949307469, 8.454582136674901],
            [-7.760836777428892, 8.415691111660635],
            [-7.748926763535565, 8.382782083321217],
            [-7.697781898958738, 8.370836027244081],
            [-7.653372899430565, 8.383836023829417],
            [-7.665554989901693, 8.415835950947837],
            [-7.674863931594359, 8.437636107701792],
            [-7.6752818532878, 8.547500059862045],
            [-7.673472870939406, 8.615273111642978],
            [-7.750836831636605, 8.717636098630692],
            [-7.79236396520696, 8.755553984683559],
            [-7.883890827739151, 8.775418089436471],
            [-7.910554835281118, 8.767218073536924],
            [-7.940763884962109, 8.780136095109185],
            [-7.956254983178212, 8.804444955490169],
            [-7.956808859341606, 8.826109157774169],
            [-7.936663960831424, 8.933054028326353],
            [-7.928054908055856, 8.972082181277017],
            [-7.914163915160543, 9.002918029679392],
            [-7.898890914065873, 9.01889108732928],
            [-7.810554874443312, 9.069164233973481],
            [-7.736490869035606, 9.07245396334099],
            [-7.736108821887626, 9.091664112006185],
            [-7.797226810166819, 9.125827073064272],
            [-7.9031908304068, 9.179582062297129],
            [-7.919999899081802, 9.204027044786415],
            [-7.917217777772066, 9.235000021124904],
            [-7.877226711781873, 9.351527083414055],
            [-7.961945960174091, 9.389718051873217],
            [-8.019308855820043, 9.388054076450373],
            [-8.055836687046394, 9.403335962362462],
            [-8.064517824189466, 9.41880912330555],
            [-8.109163863940182, 9.464164104428903],
            [-8.13194587683455, 9.496945057287405],
            [-8.143608792221812, 9.533891145483324],
            [-8.145281820100195, 9.557217982085717],
            [-8.147499839324666, 9.620553989589268],
            [-8.114726765455146, 9.805553992576506],
            [-8.111181890954299, 9.809000128257651],
            [-8.104236813601801, 9.814864107735673],
            [-8.104163891043925, 9.864164115418475],
            [-8.129999766549929, 9.886109111459405],
            [-8.158199841672172, 9.927573213117739],
            [-8.14763679962283, 9.983891053072796],
            [-8.101045990692057, 10.053609041671905],
            [-8.065555000891322, 10.061945012041349],
            [-8.041945861789145, 10.073326966033108],
            [-8.019163848894664, 10.089164236851275],
            [-8.004999773593113, 10.105691170664613],
            [-7.973981870253454, 10.165609038043385],
            [-7.963754942522542, 10.159864081590968],
            [-7.93944591450321, 10.157082127919296],
            [-7.88916388304159, 10.173891028956348],
            [-7.82645484189743, 10.202364186484829],
            [-7.787781914004313, 10.245282045433839],
            [-7.756599892999986, 10.296109068241492],
            [-7.75471782045571, 10.330827078929133],
            [-7.731945865845034, 10.377218063287842],
            [-7.703190908731131, 10.403327021199971],
            [-7.63799093359421, 10.446664142947157],
            [-7.451663907692591, 10.397218122510651],
            [-7.359726834817764, 10.350826970513936],
            [-7.348199873900626, 10.327082044580038],
            [-7.269717767316763, 10.254444974372859],
            [-7.183890934235933, 10.236664107840966],
            [-7.128054886076654, 10.223054076340759],
            [-7.078608865640206, 10.203335984341606],
            [-7.061317837531249, 10.189864086606235],
            [-7.039581886156043, 10.156244943427737],
            [-7.019445872463336, 10.14215395832224],
            [-6.988054974431293, 10.147500103820448],
            [-6.968608791371821, 10.15597320212612],
            [-6.948336823209274, 10.177500108835602],
            [-6.945417741601318, 10.208191117950491],
            [-6.955417855031612, 10.225826977557176],
            [-6.981045859338394, 10.247845063794045],
            [-6.96049091812381, 10.333536110043099],
            [-6.940836696226881, 10.353745046293568],
            [-6.719717731251251, 10.350554055745818],
            [-6.656408881114203, 10.354064229167477],
            [-6.650836759505751, 10.36097309669816],
            [-6.63194596135213, 10.433609161076845],
            [-6.650281877513919, 10.452773209274653],
            [-6.66923671340777, 10.460691090312366],
            [-6.684999888201673, 10.491664234288919],
            [-6.676663917832286, 10.598326969979496],
            [-6.669163958488014, 10.628191188162873],
            [-6.645599914025013, 10.663991134915534],
            [-6.621599842957721, 10.660900056653929],
            [-6.601808828400749, 10.637291085189702],
            [-6.589308896160162, 10.610000111288514],
            [-6.533054926307443, 10.576109059170392],
            [-6.421908878233864, 10.551664076681163],
            [-6.388054874127818, 10.594126965924715],
            [-6.417081907819636, 10.623127009888265],
            [-6.413545918136265, 10.687982153527457],
            [-6.243399821083869, 10.735254075912337],
            [-6.224408775368033, 10.722909041242985],
            [-6.189099840504866, 10.63652699089414],
            [-6.212499767303143, 10.569444943213],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CMR", Country: "Cameroon" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.90234503723525, 12.375973070334624],
            [14.908336086365807, 12.320553938040575],
            [14.902218135220835, 12.228336071408705],
            [14.898891190203187, 12.200282176678286],
            [14.894336128728526, 12.155809139409598],
            [14.95152719535912, 12.097636049000471],
            [14.97340010703266, 12.091391028203077],
            [15.042600261652126, 12.078891095962661],
            [15.081945082905577, 11.754718139899524],
            [15.062500240950669, 11.690000124196558],
            [15.090763347984989, 11.588191181010473],
            [15.1093002621854, 11.496527022903706],
            [15.067636168316767, 11.436664140809853],
            [15.050973280033531, 11.394864092471096],
            [15.018263238076202, 11.202154079645481],
            [15.01916328684166, 11.13694505205325],
            [15.026063269555038, 11.079409153925269],
            [15.075973312152485, 10.903609127889183],
            [15.077218192415728, 10.880136110894853],
            [15.066391120415517, 10.845000010875708],
            [15.058054144217976, 10.825282086514505],
            [15.058191272154147, 10.801945023990072],
            [15.092218111104216, 10.731664106772257],
            [15.115136078468595, 10.704027127906954],
            [15.136663320454034, 10.660554051689871],
            [15.150000101910166, 10.615553960348109],
            [15.190282187579385, 10.502773105874553],
            [15.276945031685329, 10.390554006553259],
            [15.373054113058174, 10.248609158089664],
            [15.499027077338383, 10.101391086685567],
            [15.54194510392557, 10.072500175101595],
            [15.589163214491805, 10.049445079800819],
            [15.630282149376399, 10.027218116536233],
            [15.653473199147072, 10.011809160971552],
            [15.675300177991119, 9.988018134569984],
            [15.591663200939877, 9.967218106505811],
            [15.53500019421125, 9.953609080834099],
            [15.422218166271193, 9.926945073292075],
            [15.284163191443952, 9.973609140056908],
            [15.240000116955684, 9.987500132952221],
            [15.159445165710736, 9.989164108375235],
            [15.123609176774266, 9.984164135479091],
            [15.101245085573282, 9.975826991643373],
            [15.083473271496928, 9.955418063182549],
            [15.053054171321776, 9.94833602553166],
            [15.017500149608907, 9.949718033731074],
            [14.92666328534807, 9.970064097917941],
            [14.783473221345218, 9.923191154125448],
            [14.652773198936927, 9.959164103360138],
            [14.486109112111478, 9.993891166503317],
            [14.450554084570285, 9.998053954907959],
            [14.194773267658263, 9.981754003033302],
            [14.180554039433503, 9.935827040835761],
            [14.140973183785889, 9.860000153547333],
            [14.123336150713158, 9.831664123955079],
            [14.108609146792787, 9.811391149964152],
            [14.077500215984344, 9.78360899653552],
            [14.059445093579711, 9.7741641003729],
            [14.034163261875108, 9.758191042722842],
            [14.010000246609707, 9.730000020056195],
            [13.957636150393398, 9.646454070473681],
            [13.969445078534363, 9.629718091994732],
            [14.007845091659192, 9.617500126977745],
            [14.328609127691095, 9.258336077820843],
            [14.3525002340171, 9.23000004822859],
            [14.34805413728418, 9.196945007135469],
            [14.559445065407317, 9.007500080882039],
            [14.621109218498759, 8.960554046893606],
            [14.832773229028021, 8.813609057895633],
            [14.855836035679602, 8.817218137775001],
            [14.876109177308706, 8.809309141554593],
            [14.922782296529022, 8.775273082511035],
            [15.074163323975768, 8.643609141235061],
            [15.207218158268688, 8.477364149569382],
            [15.225200190477381, 8.436200120045726],
            [15.264718181851038, 8.339164167816875],
            [15.343609157672915, 8.171945031361531],
            [15.361945073834534, 8.142500075976329],
            [15.376527070829638, 8.112082149267678],
            [15.380273278645234, 8.077773175456002],
            [15.389163292815908, 8.03958203935872],
            [15.433336090312196, 7.911664138260278],
            [15.444718211942075, 7.882218009408405],
            [15.477918092322227, 7.810273116767519],
            [15.502291158082016, 7.774926966253986],
            [15.524027109457336, 7.773891130656807],
            [15.579982180641991, 7.76040900699951],
            [15.581945054733183, 7.73610903143566],
            [15.584300201894052, 7.690135968770448],
            [15.572773240976687, 7.630000171908307],
            [15.547009114562172, 7.582009082229035],
            [15.501945153118129, 7.529444993802599],
            [15.499009140065539, 7.526609060674048],
            [15.445554055328955, 7.394718137459563],
            [15.374718088481188, 7.347500026893158],
            [15.29916327777056, 7.297500130293429],
            [15.261109101971613, 7.268609051071451],
            [15.25673609543469, 7.260091025764495],
            [15.231391231818122, 7.236109059608054],
            [15.210000111940417, 7.191109135904298],
            [15.142082220872396, 7.016527000403073],
            [15.13639124387646, 6.985282115124733],
            [15.129718075463757, 6.951109095783011],
            [15.052918216851879, 6.771318110357754],
            [14.996109197369378, 6.740835978270525],
            [14.961945062844961, 6.735973133310722],
            [14.938027134428978, 6.686491070690408],
            [14.826663324510207, 6.433609051180667],
            [14.796945119080533, 6.390000020493858],
            [14.805000128054644, 6.346664072213116],
            [14.742363338915936, 6.264718060313442],
            [14.580273256666146, 6.188891173024842],
            [14.449163191553339, 6.096109036669588],
            [14.429791272156365, 6.079682182780573],
            [14.41889127759822, 6.040973212703591],
            [14.424163327072563, 6.006945032649128],
            [14.492845144798139, 5.915973219746832],
            [14.556363207239201, 5.906635947221332],
            [14.580273256666146, 5.927218045802192],
            [14.604718071517198, 5.922218072905991],
            [14.617218171395564, 5.90124504235996],
            [14.623054155316936, 5.833891085739026],
            [14.626800195494525, 5.724718137678394],
            [14.622363318855321, 5.520691046791626],
            [14.617218171395564, 5.495836021598052],
            [14.591391180707092, 5.404718028303876],
            [14.565973226894585, 5.358891146030601],
            [14.55139122989965, 5.342500166687159],
            [14.532636050939914, 5.291244996264027],
            [14.553609249124122, 5.267782037553729],
            [14.585418068849492, 5.245273107065316],
            [14.605136160848701, 5.23791798700853],
            [14.624718130740007, 5.233609018212007],
            [14.65145405501147, 5.219509148288978],
            [14.672500175753441, 5.180000041732683],
            [14.687791114121012, 5.119073160846597],
            [14.686036111229384, 5.037500143639136],
            [14.675836173226742, 5.015553974131649],
            [14.672954139630576, 5.012054026632029],
            [14.691109174321298, 4.98139118071191],
            [14.710836151137983, 4.878054048934303],
            [14.72041817523683, 4.805282197723841],
            [14.720273168311394, 4.723336018185762],
            [14.723473211314797, 4.64374498580878],
            [14.737363198381615, 4.615345086114417],
            [14.804300239137547, 4.538126970532915],
            [14.89221818942832, 4.476664150756278],
            [14.930554164812918, 4.460553965169993],
            [15.013682192701879, 4.414718030441179],
            [15.086945055801948, 4.294718010380564],
            [15.093609171759113, 4.266809122575467],
            [15.093609171759113, 4.235000135211919],
            [15.10347316308173, 4.140418068963754],
            [15.145000129013795, 4.071809006157764],
            [15.043891242382813, 4.029164229614821],
            [15.063891133967616, 3.961391177834059],
            [15.076945110009433, 3.920554042534945],
            [15.122218116119313, 3.858891062910004],
            [15.137500169669579, 3.8383359540573],
            [15.224163349051707, 3.721945013876052],
            [15.260273258584618, 3.673891059922767],
            [15.267173241297996, 3.66681807472736],
            [15.29527323649603, 3.639718040581045],
            [15.590273146113418, 3.344718130963429],
            [15.76777318975644, 3.147500162959432],
            [15.817563203500185, 3.100418006863151],
            [15.865273164146174, 3.108336055538871],
            [15.906945137003845, 3.112500017410014],
            [15.940000178096966, 3.100553961333048],
            [16.073609223829351, 2.943335944136436],
            [16.103054011576717, 2.89833602043268],
            [16.111945031575715, 2.858609152031363],
            [16.112354068451907, 2.825627033496232],
            [16.086663199871339, 2.80374506936721],
            [16.070554020113377, 2.790836100250601],
            [16.090554079335959, 2.538053993026949],
            [16.09471820884545, 2.497782133279713],
            [16.10610921529252, 2.432500015491541],
            [16.111382270594959, 2.410554013622061],
            [16.133473279389818, 2.354864145854663],
            [16.179718083356477, 2.260000112382841],
            [16.192500150458841, 2.237500066711789],
            [16.20700017244053, 2.221264152577589],
            [16.203536099486541, 2.213545090283901],
            [16.158127138906764, 2.186391076680763],
            [16.112118201695921, 2.200209147018313],
            [16.083473215151912, 2.152500024562585],
            [16.083882252027934, 2.044718137156735],
            [16.095273258475174, 1.950136070908741],
            [16.129163304765029, 1.84500001506666],
            [16.142773168627059, 1.799164080337846],
            [16.161036162230857, 1.724718027782217],
            [16.072218163174398, 1.654164195796227],
            [16.04513606630087, 1.674309094306295],
            [16.030000193142484, 1.710000076317328],
            [16.026109146039516, 1.730554011703589],
            [15.978336153481507, 1.771109012140926],
            [15.880554044229996, 1.820282117809313],
            [15.75742707136186, 1.908609104976279],
            [15.687500205735091, 1.933544931716824],
            [15.646109194272753, 1.93139111787076],
            [15.519163258669096, 1.961108988024307],
            [15.49152711799394, 1.97471801369619],
            [15.448327124183322, 1.9688910822303],
            [15.294718186866135, 1.97666412398064],
            [15.239163267740139, 2.02971805083024],
            [15.211109205371713, 2.036109084381167],
            [15.14889117611682, 2.038745025299136],
            [15.123054127144542, 2.01221797805556],
            [15.093263334433061, 1.980482080887725],
            [15.029163232634005, 1.986945031168261],
            [14.887218216532347, 2.016664074788252],
            [14.856663329525304, 2.082782035963476],
            [14.759163187497563, 2.072500122947588],
            [14.714163263793807, 2.124164162608508],
            [14.562636055955011, 2.168473082212472],
            [14.463054016810702, 2.143053954933691],
            [14.298336040269874, 2.152218057338985],
            [14.118609092585075, 2.158054041260186],
            [13.750554023429004, 2.160554027708429],
            [13.661818167023796, 2.16051798552445],
            [13.306073263819769, 2.164100075675719],
            [13.293891173348413, 2.163609063786055],
            [13.296109192573056, 2.171109023130498],
            [13.294445049511864, 2.225973105794822],
            [13.25458222664065, 2.26610901107216],
            [13.171391166839726, 2.282918079747276],
            [13.12166334681757, 2.275209075737166],
            [13.000000189524371, 2.256109065279873],
            [12.864163233921545, 2.240282188021695],
            [12.751945140428745, 2.233053969979267],
            [12.631109109343072, 2.251391059607329],
            [12.595836049025309, 2.258891018951545],
            [12.523609189160993, 2.283336001440773],
            [12.501800147589734, 2.292636058315964],
            [12.481800256004931, 2.300136017660179],
            [12.335654062381877, 2.31790012038573],
            [12.288891256797456, 2.30166420625153],
            [12.229163323344835, 2.283682174042838],
            [11.756391125868504, 2.282364203583825],
            [11.70000019571745, 2.307845021670289],
            [11.579718208433007, 2.315282116740548],
            [11.36734508889333, 2.298873200124319],
            [11.350554125129236, 2.23639114091884],
            [11.339763262950839, 2.168609036682199],
            [11.225827214578715, 2.168335954276131],
            [11.082500190277472, 2.168053987052474],
            [10.653327132842321, 2.16750011088925],
            [10.564718178451756, 2.16750011088925],
            [10.537218159884503, 2.16750011088925],
            [10.448609205494108, 2.16750011088925],
            [10.421391154150513, 2.16750011088925],
            [10.408663234142921, 2.167844942386807],
            [10.33278219975989, 2.16750011088925],
            [10.305554090132659, 2.16750011088925],
            [10.181073272259937, 2.167864220764102],
            [10.073891193846919, 2.16778207811285],
            [10.020973221467386, 2.168191114988872],
            [9.982636072616344, 2.173336094810452],
            [9.935273290400914, 2.191391049577078],
            [9.896663226781925, 2.209718080921334],
            [9.833127059429899, 2.259026973421442],
            [9.825836144751463, 2.278609110950754],
            [9.811570455766002, 2.343580116684564],
            [9.812500000000114, 2.337222222000264],
            [9.808347795598991, 2.341576972974281],
            [9.801111111000239, 2.349166667000247],
            [9.800707327000225, 2.349974234000172],
            [9.800000000000239, 2.351388889000248],
            [9.7975, 2.354722222000134],
            [9.789166667000131, 2.377777778000279],
            [9.785312185000237, 2.38186445700012],
            [9.766111111000328, 2.402222222000262],
            [9.70333333300016, 2.440555556000106],
            [9.655223739000348, 2.45952835300028],
            [9.64416666700015, 2.463888889000089],
            [9.124919620000128, 2.606323084000167],
            [9.101388889000191, 2.612777779000169],
            [9.111666667000236, 2.629722222000169],
            [9.15583333300026, 2.709444444000098],
            [9.1688888890003, 2.731944444000135],
            [9.214009800000326, 2.81900126100021],
            [9.216111111000203, 2.823055556000213],
            [9.227381007000247, 2.848791288000086],
            [9.234722222000244, 2.865555556000174],
            [9.252777778000052, 2.899166667000202],
            [9.286944444000142, 2.981666667000297],
            [9.30805555600017, 3.034722222000312],
            [9.275, 3.165555556000186],
            [9.272777778000204, 3.182222222000235],
            [9.266388889000154, 3.222777778000079],
            [9.257777778000218, 3.303055556000174],
            [9.257350334000023, 3.309467215000154],
            [9.25722222200028, 3.31138888900017],
            [9.258338624000089, 3.338601172000267],
            [9.25944444400011, 3.365555556000118],
            [9.26333334450004, 3.41666679850016],
            [9.264166667000154, 3.42555555600012],
            [9.2788888890002, 3.545555556000238],
            [9.265617240000154, 3.569914912000058],
            [9.256944444000226, 3.585833333000267],
            [9.241932853000264, 3.615351924000095],
            [9.223888889000136, 3.650833333000321],
            [9.215714882000157, 3.66426205800019],
            [9.212138354000217, 3.670094352000149],
            [9.210000000000207, 3.672500000000241],
            [9.180000000000291, 3.706388889000152],
            [9.168208055999969, 3.721632134000231],
            [9.157222222000144, 3.735833333000301],
            [9.134722222000107, 3.761111111000105],
            [9.101944444000196, 3.800833333000298],
            [9.091666667000084, 3.810555556000111],
            [9.053611111000123, 3.851666667000245],
            [9.052222222000239, 3.852777778000245],
            [8.993611111000291, 3.904444444000148],
            [8.973611111000309, 3.911388889000193],
            [8.924999669000215, 3.913333630000068],
            [8.888055556000097, 3.946388889000275],
            [8.831388889000266, 3.962777778000145],
            [8.828012894000267, 3.964766378000206],
            [8.81111111100023, 3.974722222000253],
            [8.800876287000222, 3.982832837000103],
            [8.796388889000355, 3.986388889000182],
            [8.743172326000149, 4.031546212000023],
            [8.680833333000123, 4.084444444000269],
            [8.602777778000359, 4.122222222000175],
            [8.575000000000273, 4.120555556000284],
            [8.553888889000291, 4.111111111000184],
            [8.505555556000218, 4.103333333000023],
            [8.491666667000231, 4.111111111000184],
            [8.45555552500025, 4.097499996000295],
            [8.454757441000083, 4.097353680000083],
            [8.438888889000225, 4.09444444400026],
            [8.39666666700009, 4.091666667000197],
            [8.351388889000134, 4.075000000000102],
            [8.324795280000217, 4.060916824000117],
            [8.333673636000128, 4.125112003000254],
            [8.335105574000124, 4.135465669000212],
            [8.355555966000225, 4.283332753500133],
            [8.371999753500234, 4.283333],
            [8.371944, 4.312778],
            [8.371389000000192, 4.325556000000233],
            [8.378056000000242, 4.333333],
            [8.395000000000323, 4.391111000000137],
            [8.402778000000296, 4.442222000000299],
            [8.40700000000021, 4.522222000000284],
            [8.406667039500178, 4.524999672000092],
            [8.410555832000114, 4.523889048000171],
            [8.413055555999961, 4.533333333000314],
            [8.418888889000243, 4.550000000000239],
            [8.426388889000123, 4.566666667000334],
            [8.427504421000265, 4.5695351775002],
            [8.428333333000296, 4.571666667000329],
            [8.431299424000088, 4.57759884900014],
            [8.434166667000284, 4.583333333000155],
            [8.43749883300012, 4.589391818000252],
            [8.443333333000226, 4.6],
            [8.448138010000321, 4.608735776000287],
            [8.452500000000271, 4.616666667000231],
            [8.464444444000208, 4.633333333000223],
            [8.467607724000288, 4.636605691000284],
            [8.48055555600007, 4.650000000000148],
            [8.492855615000224, 4.660542908000309],
            [8.500000000000227, 4.6666666670003],
            [8.506892141000208, 4.67105257500026],
            [8.512222398500114, 4.674444476500184],
            [8.517487516000131, 4.679319042000259],
            [8.522955507999939, 4.684381507000353],
            [8.526495453000223, 4.689208628000131],
            [8.531365508000192, 4.695849507000275],
            [8.539011508000272, 4.708082507000256],
            [8.541947127000242, 4.715422514000352],
            [8.545127508000235, 4.723374507000244],
            [8.547180276000233, 4.733636558000114],
            [8.547421508000127, 4.734842507000167],
            [8.547574969000152, 4.736147074000201],
            [8.548950508000189, 4.747840507000205],
            [8.552008508000029, 4.758544507000181],
            [8.554302508000319, 4.769248507000327],
            [8.555330220000087, 4.772331195000277],
            [8.558890508000331, 4.783010507000199],
            [8.56424250800012, 4.792950507000342],
            [8.568403343000227, 4.798671996000223],
            [8.570358508000083, 4.801360507000197],
            [8.571183729000268, 4.802323145000287],
            [8.574946508000266, 4.806712507000213],
            [8.578667268000288, 4.809193374000188],
            [8.581827463666855, 4.811300356000118],
            [8.587179402000231, 4.81130028050012],
            [8.591736123361557, 4.810936212138529],
            [8.602845054222428, 4.810418125089711],
            [8.632918149433522, 4.825000122084873],
            [8.635063246100003, 4.846109107100972],
            [8.620554004024825, 4.872082110543204],
            [8.620836138886432, 4.892364136989613],
            [8.681663275124208, 4.972218025851134],
            [8.71694505262127, 5.018609010209445],
            [8.812773172599179, 5.155691181351472],
            [8.824718222847821, 5.188609094508195],
            [8.840900157525425, 5.302573138437481],
            [8.832082227721969, 5.340135967070808],
            [8.837218155088408, 5.379717996184922],
            [8.862291109764953, 5.5038181080144],
            [8.884163183248347, 5.538744995729758],
            [8.907082156441049, 5.560836004524617],
            [8.92000017801314, 5.603744978656323],
            [8.90486329902609, 5.633191107508196],
            [8.880282194428958, 5.65721800066575],
            [8.85082718076012, 5.722500118453922],
            [8.864445091249138, 5.837573088176214],
            [8.934509084812362, 5.902918070238513],
            [8.960000128820752, 5.907364166971263],
            [9.071663340321834, 6.013609148606463],
            [9.223327173182497, 6.150554024173971],
            [9.3330541650447, 6.279717979001873],
            [9.369582163909115, 6.326527052692086],
            [9.409582282354904, 6.326664180628484],
            [9.432082160387608, 6.327218056791935],
            [9.455554003915438, 6.369164117883997],
            [9.468527178410454, 6.404300050265363],
            [9.52527316598065, 6.42971800407787],
            [9.594445157405744, 6.497773190720409],
            [9.637782279152759, 6.523335983820118],
            [9.655554093229114, 6.52583597026819],
            [9.708845060301201, 6.521253919065543],
            [9.744163215257913, 6.639444956777936],
            [9.786391243573803, 6.777782066466614],
            [9.795554004874589, 6.801664120336966],
            [9.879863210562348, 6.780553961854537],
            [10.095000178862676, 6.962782069453851],
            [10.166109228116909, 7.020136080282498],
            [10.178191238663999, 7.00278218789947],
            [10.187773262762789, 6.978336031943911],
            [10.188891241011447, 6.942364088537545],
            [10.193054029416089, 6.922636105892707],
            [10.210136180497273, 6.895418054549111],
            [10.2306911217118, 6.881245094430142],
            [10.269445018790066, 6.874718106409148],
            [10.513336106014208, 6.878053936244271],
            [10.560000172779041, 7.021664101235615],
            [10.620345181945197, 7.057082168478587],
            [10.73444518034421, 7.001391127244517],
            [10.821391165140085, 6.970273143980762],
            [10.842363189857792, 6.959653943180115],
            [10.863745089642066, 6.925835981257933],
            [10.880418203847057, 6.872364132715006],
            [10.881945051333901, 6.83360906217041],
            [10.903054036349829, 6.806945054628386],
            [10.940973263507374, 6.779027114367864],
            [10.962918091910353, 6.777218132019414],
            [11.014727138496539, 6.77785398319611],
            [11.043609165263206, 6.753336078149061],
            [11.076873251021993, 6.709026990907148],
            [11.082500190277472, 6.677782105628808],
            [11.075836074320137, 6.650000119838126],
            [11.073327203054703, 6.596244962967091],
            [11.088336174198673, 6.525000126881309],
            [11.09374501633306, 6.503336092235486],
            [11.112363234994405, 6.453336027997352],
            [11.127845113117019, 6.437918019977189],
            [11.1881911281111, 6.433891186042445],
            [11.202218075476537, 6.436945048653968],
            [11.270000179712781, 6.433191129487341],
            [11.340273217941785, 6.440836095756993],
            [11.380763342448404, 6.454653998456365],
            [11.409654086394198, 6.484309004335898],
            [11.414582142560903, 6.512918116334276],
            [11.438054153726682, 6.590000109807704],
            [11.464027157169085, 6.60166419866124],
            [11.512154033680304, 6.60340009081375],
            [11.557773212392249, 6.658336090207399],
            [11.580973146980057, 6.720073165856604],
            [11.588609228432176, 6.759444976838381],
            [11.586873168641887, 6.786182074576516],
            [11.55923618977647, 6.8195821148052],
            [11.583609087898253, 6.885836030450321],
            [11.641945122505604, 6.946527044580364],
            [11.701391256372403, 6.976109127901964],
            [11.760691209847494, 7.002082131344309],
            [11.864163290266561, 7.084718141515339],
            [11.882500212256616, 7.132218051667223],
            [11.788327182884643, 7.25284503808723],
            [11.754963184839596, 7.268300094119525],
            [11.799436054470107, 7.2966641192682],
            [11.846391140914022, 7.38139107901128],
            [11.864300250564952, 7.405000050475508],
            [11.892218190825474, 7.425282076921917],
            [11.913745097534957, 7.445282136144726],
            [12.042291138459234, 7.577364166752432],
            [12.035136178250525, 7.636526992291351],
            [12.026245158251356, 7.65500003638931],
            [12.025973249311562, 7.67680907796057],
            [12.031527098371157, 7.709582151830034],
            [12.04360910891802, 7.739718111314858],
            [12.053609222348541, 7.757218016451588],
            [12.063891135364599, 7.774718089226326],
            [12.076663311820994, 7.796391176327688],
            [12.096109159604225, 7.826109046481008],
            [12.112918228279113, 7.844027040949243],
            [12.141109083307981, 7.877773086142156],
            [12.168891236736613, 7.926664224586943],
            [12.209445063707676, 8.003336007717905],
            [12.20166330477781, 8.042500115138466],
            [12.19958224585173, 8.083953990874818],
            [12.204027169117978, 8.117364089387422],
            [12.21263605425554, 8.136809098980336],
            [12.229163323344835, 8.158327120872514],
            [12.245527145321944, 8.177173159663027],
            [12.255554080842558, 8.197773195516675],
            [12.25666334191186, 8.217782139556789],
            [12.253745098494107, 8.24194498718424],
            [12.240973257313897, 8.276527043402211],
            [12.237082210211099, 8.319864165149397],
            [12.242773187206865, 8.377500143201473],
            [12.250554107946414, 8.400973160195576],
            [12.269300234450839, 8.423891127560182],
            [12.290836193615633, 8.433191184435316],
            [12.314163197856033, 8.427918129133047],
            [12.339027107867082, 8.422082145211732],
            [12.370973223167084, 8.446945049394458],
            [12.420418070137089, 8.504164111581304],
            [12.416109101340737, 8.546945010232321],
            [12.402845074804588, 8.563127112548045],
            [12.404300173199942, 8.600273192954319],
            [12.476663322810566, 8.627918050808773],
            [12.498054107412145, 8.627364174645322],
            [12.546391202055304, 8.613053918951834],
            [12.613609204206341, 8.631664090986362],
            [12.681109173580978, 8.656800077575269],
            [12.797218146538796, 8.771391087863719],
            [12.815000186536963, 8.814717983688922],
            [12.825418221661153, 8.853336093935127],
            [12.851245044711504, 9.017918116005831],
            [12.848054054163583, 9.038327044466655],
            [12.838054108371466, 9.06860901670558],
            [12.897782209461695, 9.348053958004584],
            [12.973745051220192, 9.456527017148062],
            [13.023891128212028, 9.496109046262177],
            [13.060973170877844, 9.510273121563785],
            [13.096391238120816, 9.511944975975837],
            [13.15028218182357, 9.515209056719698],
            [13.191245045670826, 9.52889117258718],
            [13.218054060138229, 9.551109083396454],
            [13.241809211993939, 9.585000135514576],
            [13.259718154006691, 9.751664222340025],
            [13.233891163318219, 9.864653953841241],
            [13.228191133866915, 9.906244957514161],
            [13.243891276748684, 10.031664213268925],
            [13.25305403804947, 10.071809003363796],
            [13.270763323128051, 10.086873127430863],
            [13.32639116481181, 10.101664169091691],
            [13.395691231717592, 10.110191079215781],
            [13.404163324194769, 10.115418034050606],
            [13.45430018109289, 10.158745097513986],
            [13.465554059603733, 10.179027123960452],
            [13.468682185877697, 10.207082192157372],
            [13.459027071582739, 10.238609044659142],
            [13.519445170944664, 10.502218056244658],
            [13.545273167461687, 10.595836035986906],
            [13.558191189033778, 10.635827101977213],
            [13.571391177829071, 10.668054011034314],
            [13.583336060439933, 10.69110910633492],
            [13.598891196396494, 10.710836083151605],
            [13.617773277370645, 10.733609043590604],
            [13.649163337212201, 10.771945018975146],
            [13.676945155365047, 10.807500046516338],
            [13.706391116578573, 10.846664153936729],
            [13.715418091047638, 10.869027071671212],
            [13.718336166827214, 10.881544941184643],
            [13.770900255253878, 10.939308994717749],
            [13.774718212160906, 10.975827102936364],
            [13.807218203624302, 11.055827004551588],
            [13.88666322324724, 11.170553969309765],
            [13.984718079629147, 11.267500067536105],
            [14.013745113320795, 11.282009141973504],
            [14.087918083470186, 11.257918043437314],
            [14.114718213120057, 11.247500175951302],
            [14.154373164791934, 11.234864121603039],
            [14.188609216045847, 11.2441641784784],
            [14.224582165280538, 11.267218100312505],
            [14.329718221122619, 11.338891084013767],
            [14.373891186256458, 11.378054017967884],
            [14.40569112116475, 11.400135974307261],
            [14.436527137205076, 11.405282127595115],
            [14.462082218953924, 11.414164095138972],
            [14.614718184947662, 11.503609060554595],
            [14.644509145296922, 11.572354077830482],
            [14.638054074005595, 11.650836016775997],
            [14.593745154401631, 11.690273206602853],
            [14.588609227035363, 11.755000107123124],
            [14.61221819849959, 11.825282197807667],
            [14.630836081884809, 11.891391106527408],
            [14.637218230618544, 11.979926970722033],
            [14.619654120103405, 12.028609064501154],
            [14.649654125118502, 12.121035975798634],
            [14.645282292048194, 12.188327068145611],
            [14.552082233999272, 12.248891180261239],
            [14.519718197006, 12.315553965144375],
            [14.490836170239504, 12.335835991590841],
            [14.469191246332969, 12.340900169865392],
            [14.430636167998557, 12.348164094815843],
            [14.392500184824712, 12.352773135746816],
            [14.322082139670272, 12.355973178749821],
            [14.239027201977251, 12.354164196401541],
            [14.174445140744183, 12.396664133657225],
            [14.184163286950877, 12.415000049819014],
            [14.197363275746795, 12.475000059849265],
            [14.199163205639707, 12.501391152623114],
            [14.173054080089457, 12.623609024270252],
            [14.100827052586737, 12.957217991678306],
            [14.076945166354449, 13.068336044195291],
            [14.074718094674495, 13.081664108471898],
            [14.159718136823869, 13.08305399566035],
            [14.435973261041624, 13.085000105944971],
            [14.457082246057382, 13.065973185683617],
            [14.472773168845663, 13.040273097009504],
            [14.486945123136309, 13.019717988156799],
            [14.500873164043924, 13.00131803425478],
            [14.525554013289195, 12.975000031676643],
            [14.539718088590803, 12.853054068969357],
            [14.546245076611626, 12.771664112527844],
            [14.581527189384644, 12.738054021804828],
            [14.624200129122414, 12.734600174772822],
            [14.708745033927926, 12.714718132747123],
            [14.822427110633441, 12.633773087727263],
            [14.90234503723525, 12.375973070334624],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "COD", Country: "Congo, DRC" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.45527320177618, 5.016391158623094],
            [27.524436140745394, 4.943609081490493],
            [27.660209226245826, 4.896182093896599],
            [27.76889116241702, 4.787773072493451],
            [27.782909224964925, 4.757500152710122],
            [27.777709259858426, 4.697400062755634],
            [27.771663225442893, 4.66610907700975],
            [27.789445097803224, 4.600135955121857],
            [27.865273158558153, 4.557082141702736],
            [27.929718091854824, 4.551109029845165],
            [28.028054077269786, 4.502218059038569],
            [28.084445175058789, 4.432782037662975],
            [28.201109197646332, 4.347845027425734],
            [28.363054105332793, 4.29000000470792],
            [28.477218141472321, 4.330835966540377],
            [28.488327180695961, 4.350000014738242],
            [28.505836138288004, 4.373053936572546],
            [28.584718229292406, 4.401945015794425],
            [28.63347324562929, 4.416809147651165],
            [28.652500165890757, 4.425418032788642],
            [28.672773307519691, 4.455835959497279],
            [28.687773226208236, 4.484582199431927],
            [28.693400165463657, 4.510973124567741],
            [28.712500175921008, 4.537500171811629],
            [28.782500131743717, 4.55736410892635],
            [28.803609116759873, 4.519100050271433],
            [28.802909227842719, 4.497082131672414],
            [28.827427132889653, 4.48270901170504],
            [28.890273301970268, 4.477782129004822],
            [28.977218113299756, 4.48381810513645],
            [29.011454164553612, 4.496982051748333],
            [29.131382267884732, 4.412218043993036],
            [29.159445047432683, 4.386109086080836],
            [29.210273243706723, 4.342082133700487],
            [29.24360924619495, 4.346109135273224],
            [29.325000208465013, 4.390000133183847],
            [29.432782263508699, 4.525553948096487],
            [29.464163270894943, 4.591944991677806],
            [29.466527135235225, 4.6347180113397],
            [29.467773188964742, 4.663891057785193],
            [29.494236198467917, 4.683536059588533],
            [29.614718177962715, 4.656109131217434],
            [29.643327122322972, 4.643609031338869],
            [29.69860912668068, 4.617500073426584],
            [29.813536083649325, 4.559027078521098],
            [29.806391181724535, 4.496108992711001],
            [29.800000148173382, 4.468891109005654],
            [29.792500188829223, 4.433053946602783],
            [29.799163298958121, 4.375345045992773],
            [29.838745160434115, 4.346873061930893],
            [29.959445069411828, 4.29139106536276],
            [29.987782272470469, 4.233891041780424],
            [30.061800177410618, 4.131391094494589],
            [30.079718171878682, 4.122364120025424],
            [30.11950924565852, 4.116809097499541],
            [30.148054152278434, 4.099582107130928],
            [30.166663318484467, 4.039718051570418],
            [30.207773200913635, 3.961664092602035],
            [30.24305414022021, 3.955282111506534],
            [30.275209132547758, 3.959309113079357],
            [30.336109191343638, 3.940282192817875],
            [30.361382305868716, 3.925282106491323],
            [30.434718091526662, 3.885836031847134],
            [30.494445186788681, 3.865073051795065],
            [30.527218093020252, 3.873053964744955],
            [30.551036109150147, 3.8638181132483],
            [30.581663248162528, 3.700135972304693],
            [30.581391171584727, 3.668891087026438],
            [30.575136092503641, 3.641664150865594],
            [30.562009193903776, 3.613264083532997],
            [30.59187324444909, 3.604927107335243],
            [30.63110910096114, 3.611109096220574],
            [30.735209153567752, 3.637782156217995],
            [30.786391233794831, 3.660000067027269],
            [30.847218202394771, 3.528335958113175],
            [30.858818085869672, 3.493391133124831],
            [30.905973164523886, 3.52471799341636],
            [30.924791207757579, 3.510764136247062],
            [30.934445148585866, 3.413891128216576],
            [30.882773229935964, 3.324718071740662],
            [30.859718134635187, 3.290282195914514],
            [30.828054154197019, 3.240282131676508],
            [30.782218219468206, 3.130000090184652],
            [30.763473266430452, 3.051736080722023],
            [30.772363280601127, 3.028745023161775],
            [30.80624511262576, 3.013609150003305],
            [30.852354129760755, 2.962844991469723],
            [30.879445111451702, 2.885900125932565],
            [30.876527203310189, 2.840000153463322],
            [30.851663293299197, 2.785482075763213],
            [30.81721819737956, 2.739164181600358],
            [30.786945109958168, 2.674718074837301],
            [30.755973307086123, 2.601109039135167],
            [30.730973274967056, 2.473335977323998],
            [30.729718168782114, 2.448053977981417],
            [30.886245181878991, 2.336809023449945],
            [30.914718171769351, 2.342500168083902],
            [30.926936136786225, 2.360836084245662],
            [30.939718203888305, 2.400282158889766],
            [30.964027231907636, 2.411945074276815],
            [30.984863302155389, 2.409309133358832],
            [31.003054043753735, 2.396944987950178],
            [31.069163287749603, 2.325282062532793],
            [31.202500256904187, 2.229164096342458],
            [31.299200262452672, 2.162854021946004],
            [31.302773300148345, 2.121391093754085],
            [31.209718249024917, 1.987500080798014],
            [31.036663296286321, 1.765553989614773],
            [30.848327128187947, 1.618891135478506],
            [30.695482285166321, 1.500000041210996],
            [30.671391186630359, 1.473336033668986],
            [30.548882295304082, 1.321664154181065],
            [30.483891197195049, 1.24124498976802],
            [30.456109211404311, 1.212782058161466],
            [30.437636167306238, 1.202153972543385],
            [30.370827202031393, 1.198336015636229],
            [30.27458216618848, 1.167082077902563],
            [30.234373170715202, 1.130836046261663],
            [30.213745139304734, 1.065000052310069],
            [30.219309214286199, 1.043609100070412],
            [30.217363271639528, 0.994445046857521],
            [30.155554111622763, 0.909100005572583],
            [30.131182219329474, 0.895209012677256],
            [30.086391172653379, 0.893335992588362],
            [30.036109141191929, 0.874718109203073],
            [30.01582711474552, 0.866664106057328],
            [29.990836135081878, 0.850553920471057],
            [29.986727158495768, 0.846709141473752],
            [29.967109146420768, 0.835909059201967],
            [29.957500132593651, 0.818336063869467],
            [29.94750018680125, 0.787782182690933],
            [29.938745121271978, 0.624582168819458],
            [29.955827104715098, 0.551664137217145],
            [29.959863326381509, 0.483745072682495],
            [29.912445055966856, 0.429044940044761],
            [29.85832713032525, 0.366664134229922],
            [29.839445049351156, 0.277500130209418],
            [29.806945057887873, 0.162782050268447],
            [29.780482216022705, 0.173473167798647],
            [29.731945129169077, 0.104718092238969],
            [29.718609185903489, 0.077082119202032],
            [29.718191264210049, 0.042845062119838],
            [29.733327137368434, -0.003164042728983],
            [29.709000172076372, -0.074445926826883],
            [29.687763279217535, -0.202781917256985],
            [29.670827140889941, -0.304717930095677],
            [29.648609230080837, -0.43971786884498],
            [29.652773191951979, -0.476663957041097],
            [29.666663346656975, -0.580554964981957],
            [29.63471823718541, -0.641390986037308],
            [29.619300229165361, -0.743473011629803],
            [29.63305409412439, -0.787781931233766],
            [29.618891192289169, -0.891599848978686],
            [29.584445090541323, -0.895690888292052],
            [29.575136148848543, -0.916391004070064],
            [29.574300137823599, -1.18749997499981],
            [29.599027087536257, -1.214373027207571],
            [29.606109125187146, -1.320690931400662],
            [29.596945190419802, -1.38583592125255],
            [29.573327166500263, -1.393473008533164],
            [29.538327188588909, -1.413608854587793],
            [29.495282259987192, -1.449581971460489],
            [29.470000260644696, -1.482499884617283],
            [29.456391234972926, -1.504163919263135],
            [29.442773324483795, -1.504163919263135],
            [29.362009160935003, -1.510899951949796],
            [29.270836182355794, -1.626391011003747],
            [29.251391172762879, -1.654717820502327],
            [29.143473163249183, -1.815135880702101],
            [29.129927169489548, -1.851045965662834],
            [29.124300230234013, -1.916045948589328],
            [29.12805414940064, -1.948890939188274],
            [29.135273314987813, -1.976390957755399],
            [29.148209106194571, -2.01436399673139],
            [29.167500224044801, -2.068608824387312],
            [29.170554086656324, -2.093335774100069],
            [29.166391130613505, -2.133190885620365],
            [29.156391184821103, -2.160835911112713],
            [29.143891252580687, -2.189164061715857],
            [29.130491271574755, -2.212946035662],
            [29.118327118376413, -2.241108895134047],
            [29.097773182990267, -2.26854604942713],
            [28.985000207505692, -2.334446081119168],
            [28.89347317733538, -2.366390855314563],
            [28.867427083697152, -2.398681969750086],
            [28.85444519202278, -2.52610885895912],
            [28.902218184580846, -2.65999987191519],
            [28.923473182350591, -2.6799999311379],
            [28.942918191943562, -2.691254983115343],
            [28.975900142840743, -2.694235923168975],
            [29.017118151821165, -2.724026883518434],
            [29.024436056227984, -2.74471794684095],
            [29.000063325744378, -2.756664002918015],
            [28.985000207505692, -2.78430886077237],
            [28.990691184501628, -2.814308865787496],
            [29.148609258253231, -2.996108825771415],
            [29.185136083651258, -3.02131790255622],
            [29.215554177998087, -3.021663907520278],
            [29.23721821264391, -3.060282017766454],
            [29.240554042478976, -3.117781873710612],
            [29.212918237080117, -3.153681900387539],
            [29.217845119780378, -3.250072948984084],
            [29.236963235148636, -3.271864053282414],
            [29.214445084566847, -3.290345982197891],
            [29.208336185877471, -3.311664011879685],
            [29.213609241179796, -3.333608840282665],
            [29.225273162395268, -3.38778191884721],
            [29.231945157341585, -3.422781896758579],
            [29.237500179867567, -3.459164050507368],
            [29.241945103133872, -3.496108965236999],
            [29.24694507603013, -3.594445956480286],
            [29.240136120785536, -3.692917896364989],
            [29.236382201618909, -3.71971785837691],
            [29.232500206971309, -3.74333588229652],
            [29.229900140599028, -3.750963917121553],
            [29.223745141442038, -3.789654949925676],
            [29.232500206971309, -3.884999937002931],
            [29.2386091056608, -3.920835925939414],
            [29.258473210413598, -3.945835790420304],
            [29.275000144226993, -3.960282000583604],
            [29.294163354234371, -3.982499911392793],
            [29.313054152387934, -4.004999957063674],
            [29.336391214912254, -4.037082026833446],
            [29.346245147950839, -4.054863899193705],
            [29.378327217720624, -4.133608862261795],
            [29.38902722006813, -4.168054964009826],
            [29.393054054002988, -4.19528190017067],
            [29.394445114657827, -4.228890985065277],
            [29.397500150735738, -4.295835904810019],
            [29.407500264166202, -4.369163979116934],
            [29.423336193879805, -4.447499905308931],
            [29.426936053665628, -4.455835875678289],
            [29.430273224605315, -4.501108881788255],
            [29.429718174975477, -4.545072969894548],
            [29.420273278812971, -4.578335882187105],
            [29.41110917640745, -4.603335914305973],
            [29.397782285597515, -4.638054930822122],
            [29.37180022969963, -4.70166402073194],
            [29.358745080191255, -4.729726800279721],
            [29.347773168904013, -4.763335885174328],
            [29.341391187808398, -4.797781986922274],
            [29.340827085723191, -4.850281869970274],
            [29.341945063971849, -4.883890954864796],
            [29.34790912337391, -4.930417893693217],
            [29.351391133600742, -4.951390924239448],
            [29.363054048987777, -4.995281922149886],
            [29.365273241678807, -4.999999927822429],
            [29.3797181107374, -5.041108972061366],
            [29.403327082201514, -5.101390949315302],
            [29.414163206657037, -5.12528188800303],
            [29.43000014219902, -5.147499966450297],
            [29.454300117762756, -5.176663960440393],
            [29.476109159334015, -5.236663970470715],
            [29.487218198557656, -5.293608944423056],
            [29.513609123693556, -5.385555069753423],
            [29.527218149365268, -5.427499957379212],
            [29.534445193941366, -5.448055066231831],
            [29.543891263570373, -5.466390982393506],
            [29.562218127276623, -5.496390987408617],
            [29.573054084093968, -5.52055500850264],
            [29.597500240049584, -5.579163957878123],
            [29.626109184409955, -5.685554952266969],
            [29.631663201107614, -5.721945990833177],
            [29.630000231512867, -5.754863903990071],
            [29.608745066105058, -5.80721794192236],
            [29.592082177821936, -5.82847293969202],
            [29.570000221482559, -5.851945956686322],
            [29.538327188588909, -5.889999964847277],
            [29.516391245003234, -5.92305500594037],
            [29.504718103694387, -5.945835845368393],
            [29.497773193979953, -5.979726897486515],
            [29.495000125125586, -6.008890891476696],
            [29.498336122598772, -6.056390969266445],
            [29.540836059854399, -6.263055006899521],
            [29.550282297121356, -6.295281915956608],
            [29.567218100172767, -6.33125503282939],
            [29.61305403490158, -6.39972680006089],
            [29.703891234438544, -6.586390946109248],
            [29.720418168251939, -6.611808899921726],
            [29.739163288927699, -6.634582027998718],
            [29.76139125802078, -6.653890915483814],
            [29.885418112016254, -6.750835840243766],
            [29.914718228114424, -6.766108841338436],
            [29.950000173249322, -6.778890908440616],
            [29.984163301945244, -6.792781901335943],
            [30.002500223935414, -6.802217912681215],
            [30.037154029244647, -6.822635893597536],
            [30.064854040022027, -6.845763911456089],
            [30.070554069473388, -6.851391018349602],
            [30.141663286365514, -6.919163902492372],
            [30.172082218902545, -6.94416393461124],
            [30.18832718549217, -6.962499850773],
            [30.226663328514718, -7.014999901459092],
            [30.246663220099521, -7.043608845819364],
            [30.315000206327596, -7.143754987049093],
            [30.338054128161815, -7.187499972205828],
            [30.354445107505313, -7.220281930892639],
            [30.37916334003873, -7.279726891293151],
            [30.409445144639477, -7.364308844110923],
            [30.420827098631236, -7.405281933879863],
            [30.4261092063891, -7.427781979550744],
            [30.42944503622428, -7.45597300221759],
            [30.431945190310358, -7.484999868271473],
            [30.439718232060812, -7.529999959613235],
            [30.448609252059981, -7.559717829766655],
            [30.45582707654259, -7.58028199107477],
            [30.471391264954462, -7.613472986637774],
            [30.509718187883522, -7.679445940887589],
            [30.520554144700981, -7.696390964032503],
            [30.577500124481872, -7.778055008708677],
            [30.617500242927321, -7.833890889229792],
            [30.650554110553941, -7.880417828058313],
            [30.668891200182173, -7.914999884276156],
            [30.69694509491265, -7.970281888633849],
            [30.707218123111232, -7.994999953529373],
            [30.759445091391143, -8.144999978605142],
            [30.77123608225898, -8.192246084728083],
            [30.763054171270511, -8.191664045370047],
            [30.735273191308096, -8.19555492483488],
            [30.50610910800421, -8.231108946547664],
            [30.436663363620909, -8.241946076831582],
            [30.256109122090407, -8.270555021191853],
            [30.082500125550467, -8.297499990129168],
            [29.922773237088478, -8.32222693984184],
            [29.589445063437466, -8.373608844641211],
            [29.575154086121444, -8.37376390985041],
            [29.568609160827606, -8.37666404835727],
            [29.395000164287723, -8.403335934888204],
            [29.235273275825676, -8.427781923205842],
            [29.200554091671535, -8.433054978508196],
            [28.991945117220183, -8.46417296177205],
            [28.922500211027199, -8.474726951365867],
            [28.901663302589128, -8.478608946013381],
            [28.8997451878611, -8.480408875906335],
            [28.930691174471207, -8.529446027104726],
            [28.953609141835642, -8.596945996479377],
            [28.960554051550133, -8.635555054270142],
            [28.961945112204972, -8.664717874793752],
            [28.952773298448591, -8.686672929118714],
            [28.92582715604496, -8.741946048658988],
            [28.868327132462724, -8.826946090808363],
            [28.843609235205378, -8.857499971986996],
            [28.805273259820893, -8.902782030552373],
            [28.728609188040849, -8.991946034572877],
            [28.705136171046689, -9.017082021161741],
            [28.622500160875546, -9.093890932228902],
            [28.603882277490271, -9.108608883693847],
            [28.516945177511644, -9.168335811317974],
            [28.473891196454616, -9.191526861088576],
            [28.442773213190748, -9.205554981920287],
            [28.415691283955169, -9.217635819000861],
            [28.397636161550707, -9.227499977961443],
            [28.377145090438461, -9.250417945325751],
            [28.372218207738257, -9.27917290243974],
            [28.390273162504599, -9.302781873903854],
            [28.42889127275086, -9.32291788759656],
            [28.487354041734562, -9.336946008428185],
            [28.518954152070279, -9.359026958939154],
            [28.537773201132467, -9.470835847917769],
            [28.669718103803717, -9.748608934804807],
            [28.695554146947785, -9.795764013459078],
            [28.660827083804548, -9.852499942745666],
            [28.631109213651001, -9.92583589604159],
            [28.625827105893251, -9.947781897911057],
            [28.622218193651833, -9.986390955701822],
            [28.623054037038997, -10.14749985236314],
            [28.595000142308407, -10.246108920184227],
            [28.63347324562929, -10.519235918128274],
            [28.66332707025262, -10.556946100619868],
            [28.698745137495592, -10.651872998365704],
            [28.661391185889926, -10.710763914964801],
            [28.625409184199754, -10.714999961203375],
            [28.576382258923246, -10.790282024783963],
            [28.553609130845956, -10.832781962039562],
            [28.549027079643309, -10.85555492247866],
            [28.530282294243563, -10.955835844711714],
            [28.485554111841594, -11.16249988234479],
            [28.471109242782831, -11.223054942005021],
            [28.465000176455447, -11.244582016352567],
            [28.410554183122628, -11.369446054839472],
            [28.366700233224407, -11.434364062752778],
            [28.358054132436536, -11.475835875762115],
            [28.357427166077258, -11.524446052811669],
            [28.365409252493549, -11.555554983620027],
            [28.379163285090755, -11.57666396863597],
            [28.39527330303892, -11.594999884797645],
            [28.406945103243316, -11.618890991123465],
            [28.425827184217468, -11.662499854172296],
            [28.444863324572424, -11.724164007263752],
            [28.448609197111949, -11.776390975543549],
            [28.500273236772927, -11.870554952460211],
            [28.632218139444348, -11.956391005634615],
            [28.676936095924333, -11.981108902891975],
            [28.697636211702417, -11.988190940542779],
            [28.835554058593146, -12.0969459669099],
            [28.919718089717406, -12.181526913899276],
            [28.935418232599346, -12.200555007627145],
            [29.00082708476387, -12.305282026593119],
            [29.02500015831319, -12.355764050264909],
            [29.027082223067822, -12.376945957838814],
            [29.057082228082976, -12.387226865026335],
            [29.103882249317763, -12.391108859673849],
            [29.148882173021462, -12.376254953739092],
            [29.176800113281928, -12.369026903334571],
            [29.261036061135741, -12.365417823455175],
            [29.40000013718381, -12.43389093179124],
            [29.470554136808033, -12.457499903255268],
            [29.48166334366968, -12.459446013540102],
            [29.517082249103026, -12.442499984566794],
            [29.527636071058765, -12.425281879015685],
            [29.523682159681726, -12.395663921148355],
            [29.489582230535831, -12.393545981847964],
            [29.446945165343919, -12.338754989379652],
            [29.473536082690003, -12.249099973469768],
            [29.568882243233901, -12.190835855591956],
            [29.770273225564466, -12.154164023268521],
            [29.805045048070866, -12.155245959333442],
            [29.806391181724535, -12.163890886654642],
            [29.806663258302336, -12.225555039746098],
            [29.805827079639215, -12.472499989582005],
            [29.80555416487104, -12.541108884749804],
            [29.804445071439801, -12.780835842464995],
            [29.803054178422968, -13.171390957291962],
            [29.802218167398024, -13.417782030964446],
            [29.801663285406192, -13.449726805159841],
            [29.726663356687226, -13.458055064178168],
            [29.654582174300316, -13.434717834015871],
            [29.634027065447725, -13.415554959284577],
            [29.621109211513698, -13.382226835785275],
            [29.650973262059011, -13.316391009471758],
            [29.681945064931, -13.300208907155934],
            [29.683473253522294, -13.266872904667707],
            [29.594718118739792, -13.22347291864655],
            [29.570418143175999, -13.222781914546829],
            [29.545136143833389, -13.234726964795485],
            [29.518609096589699, -13.26028187890627],
            [29.485554055496522, -13.289717949474252],
            [29.464300231193334, -13.303335859963283],
            [29.308327094259909, -13.373055022028879],
            [29.197391264318441, -13.43381795288839],
            [29.098054144385486, -13.389164034148763],
            [29.065845172601229, -13.387435853347185],
            [29.040282211863456, -13.396526865556709],
            [29.018745079232104, -13.398472975841358],
            [28.970418210510644, -13.360135826990415],
            [28.957982148372622, -13.331108960936703],
            [28.954445152860643, -13.274726915603011],
            [28.950554105757732, -13.250835809277291],
            [28.920209101606787, -13.154235883652902],
            [28.825000236637521, -13.026673039973957],
            [28.742982308008237, -12.908681994472019],
            [28.673536060710859, -12.840626807829565],
            [28.637145189782558, -12.84000000910828],
            [28.617500187979289, -12.863608980572394],
            [28.59742720619866, -12.892017932722325],
            [28.572709141303307, -12.891808888056659],
            [28.49791808961217, -12.754999966958906],
            [28.495073271666428, -12.728190952491673],
            [28.515691244793089, -12.707155057671486],
            [28.530554035545293, -12.689163973007396],
            [28.530554035545293, -12.656808988469507],
            [28.446245165133718, -12.525835883654949],
            [28.346527171519455, -12.440417919812234],
            [28.31944507464587, -12.433055088404203],
            [28.229445059600408, -12.424446035628648],
            [28.163609233286792, -12.429446008524806],
            [28.049163229923835, -12.367782023071427],
            [27.96395414310868, -12.311599969948176],
            [27.935836210637859, -12.290835816429805],
            [27.91444509076004, -12.277499873164288],
            [27.885554179176239, -12.264717973700286],
            [27.860282238117378, -12.254717860269793],
            [27.838191229322689, -12.259790923361805],
            [27.82194508926645, -12.283335857085476],
            [27.797427184219515, -12.297781899610712],
            [27.677009242465346, -12.302226822877145],
            [27.655418130377342, -12.290281940266382],
            [27.640691126456915, -12.26263590894564],
            [27.623327175790081, -12.245282016562697],
            [27.600063203461701, -12.233363956042268],
            [27.586936137223887, -12.226390883133135],
            [27.568473319047797, -12.213473029199221],
            [27.541382169718787, -12.18166404183556],
            [27.535827147192634, -12.173335782817233],
            [27.530273298133153, -12.161390900206655],
            [27.494027098854161, -12.058890952920635],
            [27.486945061203443, -12.020835938931285],
            [27.486663261617906, -12.000282003545152],
            [27.480836162514066, -11.971526878793085],
            [27.453891193576823, -11.936945996041629],
            [27.418891215665468, -11.90944597747459],
            [27.374718082893224, -11.884163978131923],
            [27.352773254490245, -11.87139096348524],
            [27.263882165237845, -11.81333589027318],
            [27.244718117040151, -11.797364006089779],
            [27.231109259006558, -11.775835925913739],
            [27.228609104920366, -11.74360884921866],
            [27.229163316359887, -11.71701793187259],
            [27.228327137696766, -11.682217946171576],
            [27.218891126351508, -11.604999998228138],
            [27.208973323210529, -11.576490966153941],
            [27.033118144251404, -11.596945995082478],
            [26.996800195880923, -11.759726913793955],
            [26.994445048720166, -11.835690929018753],
            [26.98471818533406, -11.877226947406612],
            [26.975827165334891, -11.895835945974483],
            [26.959863327778493, -11.914581904840659],
            [26.902218129632843, -11.961108843669081],
            [26.873954016770142, -11.971735923458837],
            [26.750827211540042, -11.984446073831563],
            [26.678054019225044, -11.989172964321526],
            [26.598609167239715, -11.971390924323188],
            [26.477500221385924, -11.927226844006455],
            [26.457636116632926, -11.915555043801973],
            [26.436663253724873, -11.910417942969531],
            [26.198054106620333, -11.902226811887488],
            [26.071945187870199, -11.904726798335574],
            [26.043891125501659, -11.904999880741855],
            [26.00471813326385, -11.902499894293683],
            [25.983473193777911, -11.895690939049075],
            [25.842500143171122, -11.802226851049639],
            [25.692218150871554, -11.76749995554438],
            [25.498336180340658, -11.70999993196223],
            [25.363609156359672, -11.642990974476945],
            [25.326109192000217, -11.554446057826695],
            [25.316109246207816, -11.516108908975838],
            [25.291600225978129, -11.365626924466014],
            [25.322500112120821, -11.327217858885689],
            [25.345000157791702, -11.2780549791391],
            [25.344445108161892, -11.20528195446218],
            [25.320209170338501, -11.194445997644749],
            [25.284445098131471, -11.207499973686552],
            [25.255273225152365, -11.227155033773698],
            [25.18930027090255, -11.25236394292051],
            [25.126945113711372, -11.262364056350819],
            [25.066391227517727, -11.261108950165877],
            [25.029445139321609, -11.2619459670194],
            [24.980273207119609, -11.264999997268802],
            [24.739718117368398, -11.317081958623291],
            [24.692773256846465, -11.339999925987783],
            [24.602918081088148, -11.404790864248511],
            [24.584163237404567, -11.437782035239152],
            [24.526391137244616, -11.461391006703366],
            [24.44916329865535, -11.46263588696641],
            [24.37527330155811, -11.404726826508067],
            [24.365973244682834, -11.349863917310188],
            [24.410000197063169, -11.280073006153302],
            [24.416527185084163, -11.216599870713495],
            [24.398609190615929, -11.11180898164524],
            [24.376800149044868, -11.088608879419141],
            [24.318473166893, -11.051317959725452],
            [24.185554119431856, -11.029582008350232],
            [24.142218171151228, -10.979445989642315],
            [24.131945142952617, -10.916664025940207],
            [24.092218106913123, -10.893608930639601],
            [24.058473235186881, -10.878608844312865],
            [23.994718132523445, -10.869163948150458],
            [23.986209159672057, -10.870463981336627],
            [23.954027177616211, -10.933755061838824],
            [23.886109118910042, -11.014717876493492],
            [23.858609100342818, -11.027154944460008],
            [23.665136166688029, -11.010835881845978],
            [23.610273257490149, -10.99028194645966],
            [23.585827101534619, -10.979999865805738],
            [23.543054081872725, -10.966946057401827],
            [23.520000160038421, -10.962226878262896],
            [23.496663265152193, -10.95889088078961],
            [23.476391129351498, -10.958335831159886],
            [23.456109102905089, -10.959163963195991],
            [23.421945136018934, -10.963190964768813],
            [23.397500153529791, -10.970281887237036],
            [23.368054024678003, -10.982781987115516],
            [23.293891280450538, -11.026946067432249],
            [23.241945105927869, -11.061663910481826],
            [23.21700911154926, -11.076599959068119],
            [23.088054033748904, -11.096390973625162],
            [23.016945152133133, -11.09610883876347],
            [22.94124516685892, -11.093054976151876],
            [22.906800238577461, -11.064926817759172],
            [22.856945181264763, -11.049445945464953],
            [22.836245065486935, -11.055972933485947],
            [22.806945117026942, -11.074717886523814],
            [22.775073265389409, -11.091455038469363],
            [22.716663302395773, -11.093335937547181],
            [22.666109194356352, -11.081108920074811],
            [22.646663346573149, -11.072782002161048],
            [22.623473302630941, -11.061108860852102],
            [22.598191135650296, -11.041254982021002],
            [22.567500126535265, -11.033608842285062],
            [22.518609155728569, -11.044164005345181],
            [22.504582208363445, -11.064726825548732],
            [22.499927066964858, -11.093782022435406],
            [22.480763354043148, -11.13062685724087],
            [22.298882257236272, -11.237217843840156],
            [22.25389121835002, -11.209726877728528],
            [22.258891191246079, -11.183755047752712],
            [22.244027059389339, -11.068335905428128],
            [22.217500179783713, -11.018890890820117],
            [22.194445084483021, -10.967226851159054],
            [22.166454054026531, -10.859926923187231],
            [22.190136115686585, -10.825699924388758],
            [22.208882242190839, -10.809163938120037],
            [22.252218190471467, -10.789999889922356],
            [22.315136108643401, -10.727499893443863],
            [22.306109134174307, -10.675281977619562],
            [22.308054070992682, -10.612781981141069],
            [22.30333606531994, -10.583055058532054],
            [22.272500216917791, -10.513054935071338],
            [22.304445158751264, -10.422782005257687],
            [22.313818138184303, -10.374999960244139],
            [22.26889113703848, -10.277781953077891],
            [22.228191129675849, -10.202217922273789],
            [22.207218099129705, -10.154163968320432],
            [22.200836118034118, -10.083335880461689],
            [22.175418164221639, -9.962499849376101],
            [22.158327128323009, -9.924308880916939],
            [22.132082215940812, -9.901945963182257],
            [22.061245075626573, -9.88284595272502],
            [22.023191235103894, -9.849717821436073],
            [21.874718225152691, -9.664999953310456],
            [21.859445056419844, -9.642363953169578],
            [21.850273242663633, -9.620281996830386],
            [21.791527165351766, -9.411390887517257],
            [21.807218088140047, -9.346390904590777],
            [21.825273210544594, -9.302226824274044],
            [21.841391275119776, -9.154164024665604],
            [21.835282208792393, -9.071108919334407],
            [21.852773229111619, -8.933890961360817],
            [21.875827150945923, -8.801108874197894],
            [21.89027319347116, -8.732226896623899],
            [21.924445039346324, -8.584163929377198],
            [21.935000202406627, -8.546390882611647],
            [21.940000175302885, -8.496108851150026],
            [21.936527217531477, -8.420972967961319],
            [21.913854169378567, -8.389899911698521],
            [21.903609136736577, -8.343545975352029],
            [21.909254013265013, -8.332135858165572],
            [21.879027193949128, -8.283608829595764],
            [21.858891180256421, -8.200135802571225],
            [21.836109167362025, -8.131672920157044],
            [21.803891143122343, -8.053472948434859],
            [21.773327203659903, -8.023472943419748],
            [21.755063204227696, -8.003755019058545],
            [21.749027060457991, -7.935690947598587],
            [21.763336142684921, -7.835555032290927],
            [21.773745125353429, -7.773472957505945],
            [21.816945119164245, -7.654446076406714],
            [21.825973267099812, -7.645417928471247],
            [21.83305413128403, -7.635554942977052],
            [21.851109253688662, -7.591663945066614],
            [21.859718138826139, -7.476946032763635],
            [21.840000214464936, -7.367781969520507],
            [21.812773278304093, -7.311946088999392],
            [21.777854101939624, -7.281255079884374],
            [21.603054036955143, -7.282217825285912],
            [21.208045114044324, -7.283055009777328],
            [20.548718238175354, -7.283617938396162],
            [20.530900155993379, -7.249654969548573],
            [20.53347323263722, -7.180555062491408],
            [20.546182209543645, -7.115417951628444],
            [20.587773213216366, -7.030835831172681],
            [20.615973288338608, -6.972781931427008],
            [20.629745090570367, -6.913881962372599],
            [20.331527200676874, -6.914726858214948],
            [20.317500253311636, -6.95110884432566],
            [20.317909122549565, -6.971390870772169],
            [20.311391186984167, -6.994717875012583],
            [19.962773248431859, -6.995555059504184],
            [19.538945163028529, -6.996608832374392],
            [19.502918234337386, -7.152364039824647],
            [19.498891232764549, -7.179445969060083],
            [19.490136167235192, -7.286946056880254],
            [19.49027329517159, -7.316255057795743],
            [19.502218177782254, -7.369717853883188],
            [19.528263265591903, -7.434999971671544],
            [19.530618077476589, -7.477499908927058],
            [19.468191171194036, -7.568955022367874],
            [19.399300141164645, -7.572782031730441],
            [19.375018102873725, -7.579255040294797],
            [19.372500179152809, -7.601108841229021],
            [19.381382146696495, -7.619717839797076],
            [19.401391258374701, -7.692472927201166],
            [19.38750026547936, -7.763190876851937],
            [19.373473150476059, -7.790690895418976],
            [19.36124512717538, -7.812917858683747],
            [19.349300244564802, -7.893191010343074],
            [19.352363327269785, -7.914308880176435],
            [19.358891153481011, -7.935281910722665],
            [19.372773261559018, -7.993335810468153],
            [19.00166332396293, -7.999446050262108],
            [18.791763201142828, -7.998972975645557],
            [18.773054122650791, -7.972363953388495],
            [18.76611809775369, -7.937963952108177],
            [18.526945183840013, -7.937082008253611],
            [18.51916325727197, -7.957635943639744],
            [18.525554123185088, -7.989026841671802],
            [18.403327199082554, -8.00916402883081],
            [18.329718163380505, -7.997499939977359],
            [18.196109117648064, -7.99666392895233],
            [18.112573226349468, -8.028617923241214],
            [18.109427162802803, -8.081364066605275],
            [18.115418211933303, -8.107108914642509],
            [18.041382202082247, -8.107781981469401],
            [18.004863255673143, -8.107081924914098],
            [17.976945147774501, -8.083055031756643],
            [17.952773247691454, -8.061526951580603],
            [17.922218193046518, -8.046663993190251],
            [17.889718201583236, -8.042363909211318],
            [17.756663199652394, -8.066664052413145],
            [17.660273156884159, -8.092781895142664],
            [17.631109162894063, -8.098054950445203],
            [17.555273223150067, -8.083055031756643],
            [17.550200160057869, -8.080681947322972],
            [17.525554011891984, -8.053608902904855],
            [17.505273158912047, -8.003335923898732],
            [17.438327065700832, -7.876663908891686],
            [17.41971823477104, -7.854999874245834],
            [17.390691201079164, -7.845835939478548],
            [17.309445078096502, -7.756108839201218],
            [17.283609202590497, -7.679445940887589],
            [17.18666327200205, -7.513054936468237],
            [17.15110925028938, -7.452782011669882],
            [17.105554109317609, -7.422226957024861],
            [17.095263311484359, -7.379017910758549],
            [17.064718147485053, -7.339163972704739],
            [16.994582237192418, -7.297363924366266],
            [16.94311818974171, -7.19902693312298],
            [16.935000148855721, -7.112499875848769],
            [16.957918116220014, -7.067635906615081],
            [16.969791249739217, -7.017917809600689],
            [16.930000175959464, -6.892217927726747],
            [16.903118071296205, -6.863890950589905],
            [16.870973304890555, -6.857573007234848],
            [16.834163338802426, -6.792226851706133],
            [16.782773219737976, -6.681664016457049],
            [16.708054084776222, -6.467226937073534],
            [16.688473288351418, -6.404999855363343],
            [16.693609215717373, -6.354999958763415],
            [16.725227095688098, -6.197445994886678],
            [16.715691172056836, -6.168472940651441],
            [16.685273245348014, -6.136872997953631],
            [16.606109187120097, -6.027217922820952],
            [16.605273176094983, -5.981663955315852],
            [16.596109241327696, -5.921663945285445],
            [16.579718094346219, -5.900835921664708],
            [16.514163229427908, -5.875835889545655],
            [16.368327166223082, -5.853055050117646],
            [16.3197181626401, -5.85221786562613],
            [16.215827154699241, -5.852500000487737],
            [16.143054130022136, -5.856390879952755],
            [16.100073239160878, -5.859372993472789],
            [15.928336089243317, -5.861391020486906],
            [15.812636153161691, -5.869717938400854],
            [15.686109145080138, -5.857081884052391],
            [15.07639123384601, -5.862781913503667],
            [14.851109145189554, -5.872499892072554],
            [14.758473189226038, -5.878017866586305],
            [14.712427204003177, -5.880417940748387],
            [14.596391153603122, -5.908054919613733],
            [14.341663271371061, -5.893335794682301],
            [14.242773242154755, -5.885555041580929],
            [14.080273284838597, -5.866390993383064],
            [13.997500146731113, -5.848608953384726],
            [13.973336125637076, -5.836390988367953],
            [13.938609230132016, -5.840835911634201],
            [13.759718125834183, -5.862499946280053],
            [13.541036115394576, -5.864790888062473],
            [13.513336104617196, -5.854163975910879],
            [13.458609149889128, -5.848472998914914],
            [13.405273255816184, -5.854445943134493],
            [13.245000202541632, -5.861391020486906],
            [13.17888210024438, -5.856326985808209],
            [13.145199893000097, -5.871900082999915],
            [13.113630361000048, -5.87918535899999],
            [13.091774531000112, -5.87918535899999],
            [13.048062872000145, -5.864614805999935],
            [13.001922787000154, -5.859757954999978],
            [12.941212149000023, -5.854901103999936],
            [12.887786788000142, -5.869471656999892],
            [12.851360405000065, -5.898612762999988],
            [12.81979087300013, -5.932610720999961],
            [12.771222363000021, -5.969037102999948],
            [12.705654874000118, -5.995749783999926],
            [12.644944236000072, -6.007891911999863],
            [12.557520917000147, -6.022462464999919],
            [12.489525003000125, -6.034604591999937],
            [12.414243812000137, -6.04431829499994],
            [12.363246876000176, -6.039461443999897],
            [12.326820493000099, -6.022462464999919],
            [12.27665337500008, -6.003913812999969],
            [12.269830074000026, -6.002533542999984],
            [12.266146672000104, -6.001549038999968],
            [12.244415438000061, -5.995422389999931],
            [12.228123855500144, -5.993668861499927],
            [12.224969150000106, -5.993580342999934],
            [12.222539645000069, -5.993559350999888],
            [12.213863773000156, -5.993631136999866],
            [12.18778162800001, -5.991611514999917],
            [12.171770992000091, -5.990530851999949],
            [12.170167935000165, -5.990495304999939],
            [12.157411010000061, -5.990331542999883],
            [12.13921864400001, -5.989873573999915],
            [12.10878738800011, -5.991141035999902],
            [12.071143063000079, -5.992763707999956],
            [12.060889316000157, -5.994208746999874],
            [11.982141735000141, -6.006370500999836],
            [11.965465487000131, -6.008870824999946],
            [11.784922735000123, -6.03946546799996],
            [12.012975231000098, -5.905957977999961],
            [12.14344223900008, -5.81701564599993],
            [12.156052467000109, -5.808513665],
            [12.164045117000171, -5.80368806499996],
            [12.169070120000185, -5.800873558999967],
            [12.178155287000152, -5.795522353999871],
            [12.203300714000136, -5.779654966999914],
            [12.205879213000031, -5.777164940999938],
            [12.214554467072588, -5.768554711712255],
            [12.229863212261876, -5.755072948655737],
            [12.274582174570412, -5.736663942298222],
            [12.367218130533928, -5.730555043608732],
            [12.485000131370128, -5.725281988306463],
            [12.526663219410381, -5.724164010057819],
            [12.53527327801433, -5.273608885200346],
            [12.532218074298356, -5.115835818374052],
            [12.572218192743776, -5.021808969393689],
            [12.606945088249034, -4.998054991004253],
            [12.709509240913576, -4.907426836133055],
            [12.712218104389336, -4.878054970943609],
            [12.82055403559653, -4.737499842030246],
            [12.856663274577215, -4.715135918467269],
            [13.042218159556256, -4.668473025168936],
            [13.077636059161051, -4.669863918185783],
            [13.092636145487688, -4.646108933967952],
            [13.091391265224644, -4.633054957925964],
            [13.087782185345247, -4.619445932254251],
            [13.095209222131615, -4.579026886286826],
            [13.119445159954921, -4.573608824059065],
            [13.141945037987909, -4.585691002244019],
            [13.175691250818801, -4.630972893171403],
            [13.183191210163045, -4.657635894885019],
            [13.193054028018963, -4.681946096370737],
            [13.412082211060806, -4.882782029071464],
            [13.514791203012578, -4.793190883264131],
            [13.503536151035149, -4.775208851055453],
            [13.543473237568691, -4.753090852532353],
            [13.579027091643496, -4.779091013341045],
            [13.60034512132529, -4.781663922346979],
            [13.69958216133395, -4.725835920814887],
            [13.739445151843256, -4.562217817611725],
            [13.741109127266213, -4.536108859699624],
            [13.739582279779455, -4.510554951417234],
            [13.729027116719351, -4.476663899299112],
            [13.730273170448868, -4.448335916334045],
            [13.783054014892002, -4.423890933844902],
            [13.815273212598242, -4.42194599702664],
            [13.850136062573227, -4.439163934939572],
            [13.905473219853945, -4.489435908117301],
            [13.960345181507222, -4.494864028628953],
            [14.008336103548544, -4.453335889230203],
            [14.301391243985648, -4.308335837050507],
            [14.372218158378104, -4.283335804931454],
            [14.405836128089931, -4.282781928768031],
            [14.487082251072678, -4.429308828434486],
            [14.476663210120364, -4.456390925308099],
            [14.446391128527267, -4.488335867141572],
            [14.41721808208186, -4.513890948890349],
            [14.393473156147849, -4.524581898782472],
            [14.367327150223531, -4.555099905415361],
            [14.383609164825344, -4.624999948951825],
            [14.418891277598306, -4.756390975459723],
            [14.411663227193884, -4.827217889852164],
            [14.414463285776492, -4.873963931630243],
            [14.418891277598306, -4.887217899882401],
            [14.48826326123347, -4.840899838081739],
            [14.56277318389138, -4.861808830887426],
            [14.586245027419125, -4.884926790462089],
            [14.638745078105103, -4.907154927193247],
            [14.669300132750038, -4.907217959105296],
            [14.706109093009957, -4.89263579447217],
            [14.832009134732317, -4.819026926408213],
            [14.875273166283392, -4.737781976891853],
            [14.893054032815343, -4.699999877670805],
            [14.910827187996176, -4.669445996492172],
            [14.926663285348042, -4.649864026600881],
            [15.015418084854275, -4.559308961925623],
            [15.072500186742985, -4.51999984757974],
            [15.09250024596588, -4.502082020749668],
            [15.133609122566639, -4.450135846226914],
            [15.149436167462824, -4.423564039620146],
            [15.196391253906739, -4.353890978022079],
            [15.218054115086119, -4.334026873269181],
            [15.273054152220283, -4.306663982638526],
            [15.28430015174203, -4.295835904810019],
            [15.32805402171627, -4.277217853786652],
            [15.332618135646186, -4.27831789476248],
            [15.351391251878766, -4.281663950519388],
            [15.391245022294498, -4.290417842582357],
            [15.415836185175436, -4.295690897884711],
            [15.451391212716601, -4.272917937445712],
            [15.468054100999723, -4.25180895242967],
            [15.48583614099806, -4.217635933087934],
            [15.488054160222617, -4.177363905702521],
            [15.483118225066903, -4.153290912077352],
            [15.48583614099806, -4.140135850282789],
            [15.516109228419566, -4.096390865126054],
            [15.520691279622298, -4.07819090343429],
            [15.535273276617346, -4.058191011849388],
            [15.564445149596452, -4.039163923950014],
            [15.584445041181169, -4.031945931829384],
            [15.691945129001425, -3.995555060901168],
            [15.768054151151546, -3.986108823634112],
            [15.835827202932393, -3.971808961500756],
            [15.862218128068207, -3.960554915351722],
            [15.880282135290145, -3.951108845722743],
            [15.890500178203723, -3.943008909747547],
            [15.924445042140405, -3.884999937002931],
            [15.979163279689033, -3.766390977597212],
            [16.058336055096476, -3.606808928422467],
            [16.084300173721431, -3.587782008161085],
            [16.105827080430913, -3.560835865757284],
            [16.128609093325394, -3.521664046985862],
            [16.187500177562498, -3.41389087675941],
            [16.219163319810434, -3.348335844203021],
            [16.226945078740101, -3.328335784980311],
            [16.227082206676585, -3.302499909474221],
            [16.218609108370941, -3.283472989212925],
            [16.19694507372509, -3.263890851683556],
            [16.188609103355731, -3.133335836200686],
            [16.193336161483671, -3.096108954247441],
            [16.195827095476432, -3.000554922504335],
            [16.18752716729071, -2.942017889858306],
            [16.186109116907659, -2.935282024809638],
            [16.182218069804719, -2.911664000890113],
            [16.182636159136138, -2.878754972550723],
            [16.202773178657338, -2.753054923038704],
            [16.208054112948616, -2.723608961824908],
            [16.214027057168209, -2.702081887477362],
            [16.215691200229344, -2.527217952390444],
            [16.188054053725921, -2.394581877981409],
            [16.186109116907659, -2.359163978376529],
            [16.183891265321165, -2.306946062552129],
            [16.183891265321165, -2.245835785624095],
            [16.18721821033887, -2.214163926196733],
            [16.194027165583492, -2.180972930633729],
            [16.206945187155384, -2.158890974294536],
            [16.243891275351587, -2.113190994035648],
            [16.269718098401995, -2.090835787651997],
            [16.337500202638438, -2.048890900026208],
            [16.367500207653563, -2.023890867907241],
            [16.40750015846109, -1.98805504660875],
            [16.462218228371626, -1.937499932741105],
            [16.513609185626308, -1.882499895606941],
            [16.528436101833023, -1.862463961838401],
            [16.532773233824173, -1.853335901616759],
            [16.544163234443033, -1.830281979782455],
            [16.60360920067194, -1.724446035023419],
            [16.627500139359569, -1.686390853395991],
            [16.656800255457739, -1.639999869037467],
            [16.669445026985358, -1.598890992436708],
            [16.671363309351563, -1.591308890441042],
            [16.678054079761012, -1.57278186688643],
            [16.711109120854104, -1.48889091816838],
            [16.719718173629673, -1.469717817515104],
            [16.738609139421243, -1.433608913810502],
            [16.750554022031821, -1.411108868139621],
            [16.801527057593063, -1.324446024033676],
            [16.861945156954903, -1.254446068211038],
            [16.878609218704696, -1.236664028212786],
            [16.946391155302962, -1.173608982104483],
            [16.968054016482341, -1.153890890105203],
            [17.121945088661192, -1.068335798326103],
            [17.159163253435111, -1.054235928403102],
            [17.212500153336549, -1.044717942044571],
            [17.248891191902658, -1.039446060208604],
            [17.271245057181744, -1.034446087312446],
            [17.298609121279071, -1.020690881248854],
            [17.323054103768214, -1.004163947435543],
            [17.397773238729968, -0.941108901327325],
            [17.548891242054481, -0.779717869804315],
            [17.558300263671327, -0.763808849894687],
            [17.61860923065359, -0.695135916986445],
            [17.681800231231705, -0.603755067207956],
            [17.697500206475297, -0.574717807594283],
            [17.712218157940356, -0.544717802578987],
            [17.721945188964639, -0.509999959529409],
            [17.73610909662807, -0.449717814637296],
            [17.741391204385934, -0.413335828526584],
            [17.745827075196871, -0.376390913796953],
            [17.742363337518952, -0.339164031843708],
            [17.736663308067477, -0.316945953396441],
            [17.715273194018323, -0.259445929814106],
            [17.710000138715884, -0.226945938350909],
            [17.709163289500538, -0.204026797520029],
            [17.710000138715884, -0.174163920441302],
            [17.715691283349742, -0.142082018309608],
            [17.726109150835754, -0.114717954212296],
            [17.743054173980482, -0.064164013810952],
            [17.770273231152487, 0.018336041890251],
            [17.796527195989995, 0.119444928521332],
            [17.801945090579579, 0.143609117253348],
            [17.839718137345329, 0.218891180833936],
            [17.873054139833641, 0.257500070986524],
            [17.912500214477831, 0.301109101673447],
            [17.931109213045687, 0.323336064938218],
            [17.945827164510547, 0.342782080359513],
            [17.955973290694743, 0.369864177233126],
            [17.961109218060898, 0.399718001856542],
            [17.968327210181627, 0.453054063567564],
            [17.966527112650482, 0.47500006543703],
            [17.922154155306174, 0.557500121138332],
            [17.895000141703008, 0.600000058393931],
            [17.889582247113424, 0.687500086991292],
            [17.903745148948559, 0.742364169655559],
            [17.907618091140591, 0.796391067828495],
            [17.905827213703134, 0.828891059291692],
            [17.899300225682225, 0.863054020349537],
            [17.889300112251817, 0.899309104445933],
            [17.881591275879742, 0.933291184032825],
            [17.875554126281799, 0.970553940531715],
            [17.873609189463536, 0.992364155569447],
            [17.877354056174681, 1.029864119928803],
            [17.885000195910692, 1.053053996232919],
            [17.898891188806118, 1.080282105860235],
            [17.925554022881641, 1.115136071017986],
            [17.938745126859828, 1.139582059335439],
            [17.952500165285358, 1.173891033147086],
            [17.978882205603838, 1.245553958564471],
            [18.070273281304111, 1.528891120432974],
            [18.075691175893894, 1.563336048714518],
            [18.07860925167347, 1.668891032078434],
            [18.07777324064844, 1.728891042108756],
            [18.073054061509424, 1.756944936839218],
            [18.065282193225386, 1.8750000200816],
            [18.072500185346087, 2.03639105160471],
            [18.075000171794073, 2.066944932783343],
            [18.091873278209619, 2.222427057827403],
            [18.110554025868879, 2.280554047768831],
            [18.126800165924948, 2.301809045538576],
            [18.162500200391435, 2.327364127287439],
            [18.206945074465494, 2.388609017580819],
            [18.223745090684901, 2.415973081678047],
            [18.227218216094485, 2.457782182472101],
            [18.227363223019807, 2.490553915237115],
            [18.237082207416989, 2.521736103879306],
            [18.274582171776444, 2.549027077780593],
            [18.300554169390324, 2.563745029245538],
            [18.317218231140032, 2.577500067671053],
            [18.342782197706214, 2.612782180444029],
            [18.382773263696407, 2.701664217240918],
            [18.413327144874955, 2.776109096329989],
            [18.470000209887587, 2.915836093207247],
            [18.540845061552744, 3.076291033781146],
            [18.559163208079582, 3.089718004515518],
            [18.579718149294223, 3.103053947780936],
            [18.598054065455898, 3.118609083737311],
            [18.615827220636817, 3.138053925692219],
            [18.641800224079191, 3.207500005351704],
            [18.643609206427556, 3.318473050943282],
            [18.632354154450212, 3.45360911180039],
            [18.624954107392057, 3.479444987306479],
            [18.618609174308602, 3.549444943129117],
            [18.598327147862079, 3.666109133354837],
            [18.592500216396388, 3.700836028859911],
            [18.590691234047938, 3.725836060978963],
            [18.595000202844375, 3.769164130270568],
            [18.610554165334463, 3.866945066055649],
            [18.643327071565864, 3.967218109299694],
            [18.648191257630202, 3.993053984805698],
            [18.647082164198963, 4.027500086553729],
            [18.64389117365107, 4.053891179327522],
            [18.630273263161939, 4.134718039512194],
            [18.592500216396388, 4.228745056130649],
            [18.567363223979129, 4.260973138654123],
            [18.543745032421441, 4.288336029284778],
            [18.53576328128122, 4.31166420699185],
            [18.544445088976659, 4.340000068945841],
            [18.567218217053721, 4.361809110517001],
            [18.591036065545609, 4.370073164156892],
            [18.628745074570816, 4.354236060976916],
            [18.707500263560888, 4.367218120289351],
            [18.733054171843264, 4.378891093960121],
            [18.751663338049127, 4.390554009347269],
            [18.771245140302426, 4.415282132526414],
            [18.793891198726925, 4.460282056230099],
            [18.803609177295812, 4.484717986263846],
            [18.822500143087296, 4.543745024970832],
            [18.83944516623211, 4.577782089842671],
            [18.955554139189843, 4.712218094144745],
            [18.987918176183314, 4.740553956098822],
            [19.028609131090462, 4.81500000865438],
            [19.064163320441423, 4.889653932409203],
            [19.085418150572991, 4.914718002268614],
            [19.106391181119221, 4.932500042266952],
            [19.185409226593606, 4.944236047849856],
            [19.207773150156413, 4.969164163239356],
            [19.22791821630463, 4.999864224809784],
            [19.415900165173895, 5.131035980729948],
            [19.557218214916418, 5.150553912880795],
            [19.649300127078334, 5.142782044596743],
            [19.700482207305527, 5.136664093451856],
            [19.760000257901794, 5.118336056279205],
            [19.812636095419805, 5.099864185647633],
            [19.841391220171772, 5.084509041901512],
            [19.85810909374004, 5.061853931021432],
            [19.880418199656077, 5.011664100495054],
            [19.901391230202194, 4.995135993215257],
            [19.927218220890779, 4.987218112177501],
            [19.951663203380008, 4.984445043323134],
            [19.978054128515822, 4.983744986768016],
            [20.002082195139849, 4.977217998747108],
            [20.178336185053212, 4.872218065013016],
            [20.339300242427299, 4.767500098502524],
            [20.457982292029072, 4.619309055775034],
            [20.453300160902245, 4.523791066215651],
            [20.452782159284482, 4.522382068287897],
            [20.476663207326482, 4.498891114020864],
            [20.571945162491687, 4.417500151750886],
            [20.599791186022884, 4.40798216539244],
            [20.715273192621339, 4.426664086518173],
            [20.835554174077203, 4.448891049782944],
            [20.873891155290067, 4.44528213754154],
            [20.969863276364862, 4.432500070439445],
            [21.034445169959838, 4.408891098975332],
            [21.077918078538772, 4.389236038888185],
            [21.103263277431381, 4.350282149599849],
            [21.163745079257694, 4.305553967197895],
            [21.208609216129531, 4.288891078914588],
            [21.513882153945616, 4.247218100228608],
            [21.548263212124823, 4.246244961267195],
            [21.576391202879535, 4.255282161658158],
            [21.597082266202051, 4.271945049941465],
            [21.630691183458566, 4.292500158793985],
            [21.65513616594771, 4.299164107113285],
            [21.736663250325591, 4.295691149341877],
            [21.830827059604076, 4.265273054995063],
            [21.986945035824817, 4.246391141658989],
            [22.03735413693903, 4.225273104187636],
            [22.097500159723069, 4.215836087013969],
            [22.11944515576414, 4.20402699123521],
            [22.150973181732411, 4.176318095640497],
            [22.193054023828097, 4.15555394212204],
            [22.213191210987276, 4.148609032407521],
            [22.251945108065172, 4.136527021860559],
            [22.285554025321716, 4.12944498420984],
            [22.346663296421326, 4.127500047391578],
            [22.386454034925123, 4.12826414168714],
            [22.421527103032417, 4.134444957105998],
            [22.453600120346692, 4.14458219847269],
            [22.497218203489012, 4.178891172284338],
            [22.538263209987321, 4.219235954589621],
            [22.570273195389632, 4.331945059971787],
            [22.587218218534559, 4.411664167829699],
            [22.588054061921582, 4.453335973049292],
            [22.599300229081422, 4.474309003595422],
            [22.628745184466908, 4.487218140349995],
            [22.661109221460293, 4.48278210190098],
            [22.683682189688938, 4.479864193759383],
            [22.725973249916876, 4.572782116946513],
            [22.75444506634085, 4.653891111992877],
            [22.780282282951219, 4.71242697117242],
            [22.847573207660218, 4.723126973519953],
            [22.89332716737556, 4.820136103658527],
            [22.970691128072815, 4.831182110970019],
            [22.997500142540133, 4.792773213027687],
            [23.01680014520781, 4.749582104034289],
            [23.098818241475044, 4.707500088472116],
            [23.211936048457261, 4.681945006723339],
            [23.325900260024355, 4.6000730908478],
            [23.382500234841132, 4.588053944574881],
            [23.424509160207464, 4.593745089208838],
            [23.429582223299462, 4.631809155653599],
            [23.439718123561676, 4.650482191961913],
            [23.579718202845243, 4.732364166121258],
            [23.826663320319113, 4.819373182829253],
            [23.888609105358029, 4.823891028653549],
            [24.063054112923027, 4.901664193864789],
            [24.191663353397416, 4.941944938429543],
            [24.239718145540991, 4.958891135040844],
            [24.367500259807571, 5.008891031640772],
            [24.397918186516307, 5.035282124414664],
            [24.357354133623375, 5.057009023334388],
            [24.387291274364628, 5.112154067393959],
            [24.459445044033288, 5.105418034707213],
            [24.476218238162659, 5.098427024525165],
            [24.525554120391092, 5.077218127222949],
            [24.605000145842894, 5.022009045423118],
            [24.642773192608445, 4.973054036875979],
            [24.734445062065959, 4.9108360076211],
            [24.777773298995839, 4.915282104353835],
            [24.818336178422186, 4.938891075817949],
            [24.950827078267821, 4.985836103978073],
            [24.974163302601909, 4.98750007940103],
            [24.995691215139772, 4.982636060974869],
            [25.089718231758127, 4.973609086505704],
            [25.181945150845451, 4.995836049770475],
            [25.22777320658534, 5.006945088994016],
            [25.290000120657538, 5.025000043760556],
            [25.316182168765579, 5.041454055016104],
            [25.343609097136863, 5.11166422897098],
            [25.348682160228861, 5.142845076508792],
            [25.328609178448204, 5.153191027265166],
            [25.310418101573759, 5.175282203698117],
            [25.312218199104791, 5.196109053852368],
            [25.339163335680098, 5.263336108458816],
            [25.361945180936516, 5.314718013258272],
            [25.540136061041238, 5.380691135146165],
            [25.584582276219578, 5.364864090249895],
            [25.635554138314433, 5.305273117095766],
            [25.715554039929572, 5.283054032820004],
            [25.815554168405498, 5.236391139521587],
            [25.891663358193711, 5.192500141611148],
            [25.911391173200627, 5.178609148715822],
            [26.028054022321697, 5.190691159262784],
            [26.065554154319216, 5.197500114507307],
            [26.089582220943242, 5.206664216912671],
            [26.10860914120471, 5.234164067841718],
            [26.127500274634286, 5.24916415416827],
            [26.19750023045691, 5.232782059642275],
            [26.393891239891389, 5.132782098804341],
            [26.446109155715874, 5.088191044338686],
            [26.462709179725124, 5.059164178284888],
            [26.494718159298856, 5.045553979146788],
            [26.520836169666552, 5.041945066905384],
            [26.62082707804899, 5.068609074447394],
            [26.680273211915988, 5.088053916402302],
            [26.715554151222477, 5.092500013135123],
            [26.758400261080311, 5.087635994708876],
            [26.793054066389544, 5.058053911387276],
            [26.869445055763265, 5.030973155618128],
            [26.884718224496197, 5.066109087999408],
            [26.90833608077773, 5.103054002729039],
            [26.941527076340577, 5.143609003166375],
            [27.008609124021717, 5.177782190146104],
            [27.027500257451351, 5.185836025653856],
            [27.066663359043531, 5.201109026748611],
            [27.090273168697962, 5.203473058726885],
            [27.142082215284262, 5.19500012805922],
            [27.313327179846027, 5.123609111581558],
            [27.40235405593026, 5.084582132097367],
            [27.424582192661347, 5.069718000240627],
            [27.45527320177618, 5.016391158623094],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "COG", Country: "Congo" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.624954107392057, 3.479444987306479],
            [18.632354154450212, 3.45360911180039],
            [18.643609206427556, 3.318473050943282],
            [18.641800224079191, 3.207500005351704],
            [18.615827220636817, 3.138053925692219],
            [18.598054065455898, 3.118609083737311],
            [18.579718149294223, 3.103053947780936],
            [18.559163208079582, 3.089718004515518],
            [18.540845061552744, 3.076291033781146],
            [18.470000209887587, 2.915836093207247],
            [18.413327144874955, 2.776109096329989],
            [18.382773263696407, 2.701664217240918],
            [18.342782197706214, 2.612782180444029],
            [18.317218231140032, 2.577500067671053],
            [18.300554169390324, 2.563745029245538],
            [18.274582171776444, 2.549027077780593],
            [18.237082207416989, 2.521736103879306],
            [18.227363223019807, 2.490553915237115],
            [18.227218216094485, 2.457782182472101],
            [18.223745090684901, 2.415973081678047],
            [18.206945074465494, 2.388609017580819],
            [18.162500200391435, 2.327364127287439],
            [18.126800165924948, 2.301809045538576],
            [18.110554025868879, 2.280554047768831],
            [18.091873278209619, 2.222427057827403],
            [18.075000171794073, 2.066944932783343],
            [18.072500185346087, 2.03639105160471],
            [18.065282193225386, 1.8750000200816],
            [18.073054061509424, 1.756944936839218],
            [18.07777324064844, 1.728891042108756],
            [18.07860925167347, 1.668891032078434],
            [18.075691175893894, 1.563336048714518],
            [18.070273281304111, 1.528891120432974],
            [17.978882205603838, 1.245553958564471],
            [17.952500165285358, 1.173891033147086],
            [17.938745126859828, 1.139582059335439],
            [17.925554022881641, 1.115136071017986],
            [17.898891188806118, 1.080282105860235],
            [17.885000195910692, 1.053053996232919],
            [17.877354056174681, 1.029864119928803],
            [17.873609189463536, 0.992364155569447],
            [17.875554126281799, 0.970553940531715],
            [17.881591275879742, 0.933291184032825],
            [17.889300112251817, 0.899309104445933],
            [17.899300225682225, 0.863054020349537],
            [17.905827213703134, 0.828891059291692],
            [17.907618091140591, 0.796391067828495],
            [17.903745148948559, 0.742364169655559],
            [17.889582247113424, 0.687500086991292],
            [17.895000141703008, 0.600000058393931],
            [17.922154155306174, 0.557500121138332],
            [17.966527112650482, 0.47500006543703],
            [17.968327210181627, 0.453054063567564],
            [17.961109218060898, 0.399718001856542],
            [17.955973290694743, 0.369864177233126],
            [17.945827164510547, 0.342782080359513],
            [17.931109213045687, 0.323336064938218],
            [17.912500214477831, 0.301109101673447],
            [17.873054139833641, 0.257500070986524],
            [17.839718137345329, 0.218891180833936],
            [17.801945090579579, 0.143609117253348],
            [17.796527195989995, 0.119444928521332],
            [17.770273231152487, 0.018336041890251],
            [17.743054173980482, -0.064164013810952],
            [17.726109150835754, -0.114717954212296],
            [17.715691283349742, -0.142082018309608],
            [17.710000138715884, -0.174163920441302],
            [17.709163289500538, -0.204026797520029],
            [17.710000138715884, -0.226945938350909],
            [17.715273194018323, -0.259445929814106],
            [17.736663308067477, -0.316945953396441],
            [17.742363337518952, -0.339164031843708],
            [17.745827075196871, -0.376390913796953],
            [17.741391204385934, -0.413335828526584],
            [17.73610909662807, -0.449717814637296],
            [17.721945188964639, -0.509999959529409],
            [17.712218157940356, -0.544717802578987],
            [17.697500206475297, -0.574717807594283],
            [17.681800231231705, -0.603755067207956],
            [17.61860923065359, -0.695135916986445],
            [17.558300263671327, -0.763808849894687],
            [17.548891242054481, -0.779717869804315],
            [17.397773238729968, -0.941108901327325],
            [17.323054103768214, -1.004163947435543],
            [17.298609121279071, -1.020690881248854],
            [17.271245057181744, -1.034446087312446],
            [17.248891191902658, -1.039446060208604],
            [17.212500153336549, -1.044717942044571],
            [17.159163253435111, -1.054235928403102],
            [17.121945088661192, -1.068335798326103],
            [16.968054016482341, -1.153890890105203],
            [16.946391155302962, -1.173608982104483],
            [16.878609218704696, -1.236664028212786],
            [16.861945156954903, -1.254446068211038],
            [16.801527057593063, -1.324446024033676],
            [16.750554022031821, -1.411108868139621],
            [16.738609139421243, -1.433608913810502],
            [16.719718173629673, -1.469717817515104],
            [16.711109120854104, -1.48889091816838],
            [16.678054079761012, -1.57278186688643],
            [16.671363309351563, -1.591308890441042],
            [16.669445026985358, -1.598890992436708],
            [16.656800255457739, -1.639999869037467],
            [16.627500139359569, -1.686390853395991],
            [16.60360920067194, -1.724446035023419],
            [16.544163234443033, -1.830281979782455],
            [16.532773233824173, -1.853335901616759],
            [16.528436101833023, -1.862463961838401],
            [16.513609185626308, -1.882499895606941],
            [16.462218228371626, -1.937499932741105],
            [16.40750015846109, -1.98805504660875],
            [16.367500207653563, -2.023890867907241],
            [16.337500202638438, -2.048890900026208],
            [16.269718098401995, -2.090835787651997],
            [16.243891275351587, -2.113190994035648],
            [16.206945187155384, -2.158890974294536],
            [16.194027165583492, -2.180972930633729],
            [16.18721821033887, -2.214163926196733],
            [16.183891265321165, -2.245835785624095],
            [16.183891265321165, -2.306946062552129],
            [16.186109116907659, -2.359163978376529],
            [16.188054053725921, -2.394581877981409],
            [16.215691200229344, -2.527217952390444],
            [16.214027057168209, -2.702081887477362],
            [16.208054112948616, -2.723608961824908],
            [16.202773178657338, -2.753054923038704],
            [16.182636159136138, -2.878754972550723],
            [16.182218069804719, -2.911664000890113],
            [16.186109116907659, -2.935282024809638],
            [16.18752716729071, -2.942017889858306],
            [16.195827095476432, -3.000554922504335],
            [16.193336161483671, -3.096108954247441],
            [16.188609103355731, -3.133335836200686],
            [16.19694507372509, -3.263890851683556],
            [16.218609108370941, -3.283472989212925],
            [16.227082206676585, -3.302499909474221],
            [16.226945078740101, -3.328335784980311],
            [16.219163319810434, -3.348335844203021],
            [16.187500177562498, -3.41389087675941],
            [16.128609093325394, -3.521664046985862],
            [16.105827080430913, -3.560835865757284],
            [16.084300173721431, -3.587782008161085],
            [16.058336055096476, -3.606808928422467],
            [15.979163279689033, -3.766390977597212],
            [15.924445042140405, -3.884999937002931],
            [15.890500178203723, -3.943008909747547],
            [15.880282135290145, -3.951108845722743],
            [15.862218128068207, -3.960554915351722],
            [15.835827202932393, -3.971808961500756],
            [15.768054151151546, -3.986108823634112],
            [15.691945129001425, -3.995555060901168],
            [15.584445041181169, -4.031945931829384],
            [15.564445149596452, -4.039163923950014],
            [15.535273276617346, -4.058191011849388],
            [15.520691279622298, -4.07819090343429],
            [15.516109228419566, -4.096390865126054],
            [15.48583614099806, -4.140135850282789],
            [15.483118225066903, -4.153290912077352],
            [15.488054160222617, -4.177363905702521],
            [15.48583614099806, -4.217635933087934],
            [15.468054100999723, -4.25180895242967],
            [15.451391212716601, -4.272917937445712],
            [15.415836185175436, -4.295690897884711],
            [15.391245022294498, -4.290417842582357],
            [15.351391251878766, -4.281663950519388],
            [15.332618135646186, -4.27831789476248],
            [15.32805402171627, -4.277217853786652],
            [15.28430015174203, -4.295835904810019],
            [15.273054152220283, -4.306663982638526],
            [15.218054115086119, -4.334026873269181],
            [15.196391253906739, -4.353890978022079],
            [15.149436167462824, -4.423564039620146],
            [15.133609122566639, -4.450135846226914],
            [15.09250024596588, -4.502082020749668],
            [15.072500186742985, -4.51999984757974],
            [15.015418084854275, -4.559308961925623],
            [14.926663285348042, -4.649864026600881],
            [14.910827187996176, -4.669445996492172],
            [14.893054032815343, -4.699999877670805],
            [14.875273166283392, -4.737781976891853],
            [14.832009134732317, -4.819026926408213],
            [14.706109093009957, -4.89263579447217],
            [14.669300132750038, -4.907217959105296],
            [14.638745078105103, -4.907154927193247],
            [14.586245027419125, -4.884926790462089],
            [14.56277318389138, -4.861808830887426],
            [14.48826326123347, -4.840899838081739],
            [14.418891277598306, -4.887217899882401],
            [14.414463285776492, -4.873963931630243],
            [14.411663227193884, -4.827217889852164],
            [14.418891277598306, -4.756390975459723],
            [14.383609164825344, -4.624999948951825],
            [14.367327150223531, -4.555099905415361],
            [14.393473156147849, -4.524581898782472],
            [14.41721808208186, -4.513890948890349],
            [14.446391128527267, -4.488335867141572],
            [14.476663210120364, -4.456390925308099],
            [14.487082251072678, -4.429308828434486],
            [14.405836128089931, -4.282781928768031],
            [14.372218158378104, -4.283335804931454],
            [14.301391243985648, -4.308335837050507],
            [14.008336103548544, -4.453335889230203],
            [13.960345181507222, -4.494864028628953],
            [13.905473219853945, -4.489435908117301],
            [13.850136062573227, -4.439163934939572],
            [13.815273212598242, -4.42194599702664],
            [13.783054014892002, -4.423890933844902],
            [13.730273170448868, -4.448335916334045],
            [13.729027116719351, -4.476663899299112],
            [13.739582279779455, -4.510554951417234],
            [13.741109127266213, -4.536108859699624],
            [13.739445151843256, -4.562217817611725],
            [13.69958216133395, -4.725835920814887],
            [13.60034512132529, -4.781663922346979],
            [13.579027091643496, -4.779091013341045],
            [13.543473237568691, -4.753090852532353],
            [13.503536151035149, -4.775208851055453],
            [13.514791203012578, -4.793190883264131],
            [13.412082211060806, -4.882782029071464],
            [13.193054028018963, -4.681946096370737],
            [13.183191210163045, -4.657635894885019],
            [13.175691250818801, -4.630972893171403],
            [13.141945037987909, -4.585691002244019],
            [13.119445159954921, -4.573608824059065],
            [13.095209222131615, -4.579026886286826],
            [13.087782185345247, -4.619445932254251],
            [13.091391265224644, -4.633054957925964],
            [13.077773187097335, -4.629708902169057],
            [13.055136181128262, -4.618608915400827],
            [13.03805403004705, -4.604446013565692],
            [13.01874514256204, -4.585135784976217],
            [12.91389122158165, -4.475835934901085],
            [12.904300145027264, -4.457781985962939],
            [12.901245108949382, -4.437081870185011],
            [12.897082152906648, -4.417363945823908],
            [12.86971808880952, -4.399581905825571],
            [12.779045174575174, -4.388990868219622],
            [12.765273204705238, -4.393890928829691],
            [12.742218109404547, -4.41916404335494],
            [12.691527208704798, -4.479581975078702],
            [12.661245068827895, -4.528890867578909],
            [12.644300213321259, -4.557154980441609],
            [12.462773168105343, -4.592226875082432],
            [12.400836100245868, -4.609445986461935],
            [12.388473295941679, -4.634099845978668],
            [12.395627082683859, -4.665072989955377],
            [12.381245077899166, -4.712364023079374],
            [12.331454058326813, -4.787081984574741],
            [12.295000155486662, -4.789446016552915],
            [12.266109243902662, -4.787499906268167],
            [12.215063285783657, -4.759926797505187],
            [12.186391141873258, -4.827217889852164],
            [12.179163259106815, -4.854726793236622],
            [12.166391250288427, -4.89583583747546],
            [12.026127241072601, -5.014999832420841],
            [12.008083939000045, -5.030161294999942],
            [12.00465206500013, -5.033045049999885],
            [11.995858405000149, -5.038551151],
            [11.902198530000135, -5.100751131],
            [11.297896618000152, -5.511879385999933],
            [9.24352385200018, -6.9095428279999],
            [9.22429750900011, -6.882080653999893],
            [9.154509509000036, -6.782377653999916],
            [8.932196508000146, -6.464554653999954],
            [8.920344508000142, -6.447601653999868],
            [8.914302508, -6.434596653999947],
            [8.849142913000094, -6.369374161999986],
            [9.547256736000037, -5.685855564999898],
            [10.225266318000138, -5.037306164000029],
            [10.338930283000082, -4.92910403899991],
            [10.3863567410001, -4.883834958999955],
            [10.541247191000082, -4.739743618999981],
            [11.067024720000035, -4.04611201199998],
            [11.127892903000145, -3.960625508999982],
            [11.136600018, -3.936168755999958],
            [11.140663222201454, -3.925282022836114],
            [11.141945150677799, -3.918608854259546],
            [11.167773147194879, -3.841390906316107],
            [11.22687327609782, -3.693335818058515],
            [11.30221820395235, -3.633335808028093],
            [11.49208222536592, -3.509026819170913],
            [11.526663275755368, -3.50666396065931],
            [11.556109236968979, -3.514863976558757],
            [11.583745042368037, -3.534999990251379],
            [11.628336096833692, -3.598608912523019],
            [11.653745165828752, -3.638190941637021],
            [11.740000146525063, -3.68666399075029],
            [11.838927056115409, -3.706717861791645],
            [11.876109178705605, -3.705973045873279],
            [11.899163268177801, -3.67944599862966],
            [11.912082127940209, -3.660835826595132],
            [11.921873196704752, -3.627499991744898],
            [11.876391145929119, -3.599999973177859],
            [11.857082258444024, -3.591945970032214],
            [11.840136061832823, -3.568190985814383],
            [11.90305414764282, -3.40778197807002],
            [11.912773299678008, -3.389999938071767],
            [11.931663259641084, -3.371108972280197],
            [11.96624514822085, -3.347635787647903],
            [11.973609153095367, -3.32618197113419],
            [11.962291236843981, -3.297426846382123],
            [11.801109082348631, -3.214717913653246],
            [11.782500251418753, -3.205835778471496],
            [11.76333603558291, -3.197781942963829],
            [11.706391229268547, -3.177781883741034],
            [11.700691199817186, -3.091945998204707],
            [11.715273196812319, -3.055554959638499],
            [11.734300117073701, -3.043472949091537],
            [11.766109104437277, -3.045490976105654],
            [11.804863336791414, -3.015417880894589],
            [11.79083605415002, -2.992499913530196],
            [11.647191188079518, -2.823372888268779],
            [11.583054038268301, -2.844717907678799],
            [11.560309241024015, -2.862326945195107],
            [11.542773293703561, -2.831663931636896],
            [11.544291256372901, -2.81656393302417],
            [11.546109123538685, -2.79389088487126],
            [11.572773298718772, -2.761945943037873],
            [11.590000121449208, -2.743335938641422],
            [11.607500194223746, -2.722363913923687],
            [11.632500226342898, -2.667499998897597],
            [11.646318129042186, -2.614654949075756],
            [11.629718105033021, -2.550281932508554],
            [11.601663204474164, -2.451663979870133],
            [11.573473187635898, -2.336390850299352],
            [11.611527195796754, -2.332217835972727],
            [11.659163228056514, -2.353890923074076],
            [11.682500122942827, -2.379164037599324],
            [11.733327145750366, -2.418890906000655],
            [11.76333603558291, -2.402499926657242],
            [11.801663293788124, -2.372217954418332],
            [11.952218200855725, -2.332426880638579],
            [11.976245094013365, -2.341108855971896],
            [11.999718111007468, -2.366108888090949],
            [12.018327109575438, -2.388055057598493],
            [12.034718088918936, -2.406664056166363],
            [12.052500128917274, -2.416945969182294],
            [12.478054048189222, -2.327217863076569],
            [12.495554120963845, -2.223755002751204],
            [12.502218069283145, -2.133890942175583],
            [12.473054075292964, -2.074445981775071],
            [12.448054043174011, -2.024446085175143],
            [12.436945171588462, -1.901108894174811],
            [12.446182196551689, -1.881664052219904],
            [12.453800173093015, -1.878054972340593],
            [12.463609179130373, -1.900555018011474],
            [12.501109143489913, -1.921390920621135],
            [12.524509237926168, -1.890590946764803],
            [12.588473217617462, -1.825490883913972],
            [12.652500229220607, -1.823473024537847],
            [12.724791126825664, -1.859026878612724],
            [12.742654136008866, -1.88372683859717],
            [12.793336151891197, -1.899717833519972],
            [12.820000159433107, -1.910690918273701],
            [12.845273273958355, -1.954445961714327],
            [12.848473149323581, -1.978890944203471],
            [12.900836072073361, -2.142781962174567],
            [12.931800163594403, -2.190099985027061],
            [12.960209115744419, -2.183335789145602],
            [13.000973160847508, -2.23194596619507],
            [13.016663245445471, -2.27028194157964],
            [13.012500121764674, -2.315554947689606],
            [13.001509099738115, -2.367672951227817],
            [13.031245074802342, -2.333055020464329],
            [13.203054141449542, -2.360555039031368],
            [13.251663312670701, -2.370835778580911],
            [13.330000244691007, -2.402499926657242],
            [13.390554130884851, -2.428755064961237],
            [13.484654070060884, -2.434654918984691],
            [13.504927211690074, -2.399517813137123],
            [13.560000171382001, -2.349717908747451],
            [13.590273258803421, -2.325835854877226],
            [13.692500123683232, -2.220281877341719],
            [13.727218134370986, -2.184163921181707],
            [13.726945051964691, -2.154163916166496],
            [13.764445183962124, -2.090764038560522],
            [13.809445107665994, -2.144445937597709],
            [13.878891187325308, -2.314717930836181],
            [13.864445144800158, -2.423055035509861],
            [13.870554043489562, -2.470899944797367],
            [13.916391151684735, -2.490135909724515],
            [14.082218221656859, -2.496108853944008],
            [14.110836050834479, -2.4930549913325],
            [14.11860909258499, -2.465281890359265],
            [14.153891205358065, -2.395555016942737],
            [14.181109089063483, -2.371663910616917],
            [14.199163205639621, -2.35958190007004],
            [14.221391174732616, -2.292781987250606],
            [14.253609198972299, -2.126390982831253],
            [14.268536195102996, -2.036455005526165],
            [14.253818076000044, -2.018472973317571],
            [14.258891139092071, -1.973681926641561],
            [14.320000242553704, -1.937363978271208],
            [14.35777328931934, -1.928608912741936],
            [14.371354151796254, -1.928617965197347],
            [14.381663222178673, -1.925281967724146],
            [14.41597320181873, -1.905281908501436],
            [14.428054038899205, -1.884581960361501],
            [14.414300173940262, -1.855282011901508],
            [14.404445067435176, -1.597781899005398],
            [14.438054152329698, -1.568890987421412],
            [14.464718159871609, -1.550555071259836],
            [14.476945177343993, -1.433335831404392],
            [14.482500199869946, -1.259999917270619],
            [14.481945150240136, -1.212781974342477],
            [14.454445131673111, -1.127217830107696],
            [14.426391236942635, -1.046946019552934],
            [14.420273285797833, -0.995281979891871],
            [14.428745042998941, -0.92694599949219],
            [14.472918175771156, -0.735972884647396],
            [14.495000132110533, -0.700835778799828],
            [14.505973216864163, -0.676255009478766],
            [14.519582242535876, -0.613335917840459],
            [14.483609125663293, -0.548054973518575],
            [14.456663318535647, -0.518608844666886],
            [14.413336087434345, -0.476808963966405],
            [14.322773311408071, -0.436945973457185],
            [14.235282167628043, -0.438608943051833],
            [14.19250026314873, -0.444717841741138],
            [14.177082255128653, -0.421946054768625],
            [14.166109170374739, -0.374164009755077],
            [14.15166329548768, -0.332781883109945],
            [14.131945035850407, -0.281526880325174],
            [14.07013604347182, -0.251808842533578],
            [13.978473226469617, -0.237635882414637],
            [13.937500136700777, -0.236663916919611],
            [13.905209189903246, -0.264999946511864],
            [13.849027136779995, -0.202635904503268],
            [13.850973247064644, -0.175135885936228],
            [13.86208228628837, -0.145281893674735],
            [13.900054151797889, -0.096435849869181],
            [13.940282258010456, -0.038055055898852],
            [13.949100187813684, 0.030835974130738],
            [13.911391178788591, 0.112218051583298],
            [13.88582721222221, 0.190000101611957],
            [13.887782207324477, 0.218745000442055],
            [13.960418104065184, 0.348609011825289],
            [14.073891136104919, 0.522782110450493],
            [14.099163244801588, 0.54389109546662],
            [14.140973183785974, 0.551245042057147],
            [14.172218069064314, 0.545836032284797],
            [14.199445172863051, 0.53583608649248],
            [14.26930012176058, 0.545691025359574],
            [14.346800204565625, 0.621526965103399],
            [14.473609179870778, 0.830835996109869],
            [14.485691190417839, 0.917218046458672],
            [14.461945091017441, 0.932636054478749],
            [14.425554052451133, 0.960553994739399],
            [14.320554118717126, 1.106109096548821],
            [14.293336067373616, 1.195282153024735],
            [14.293127190345842, 1.254927105635588],
            [14.271109104108831, 1.330000124550423],
            [14.236109126197562, 1.363335959400658],
            [14.191527124187218, 1.390554010744083],
            [14.150273240661249, 1.395691111576625],
            [14.116109106136918, 1.390691138680467],
            [14.098336118593977, 1.381527036275187],
            [14.07694516635442, 1.375553924417616],
            [13.977500255146396, 1.41639105971656],
            [13.89777326829946, 1.440000031180674],
            [13.804445134769736, 1.435627024643793],
            [13.784445075546842, 1.394026968515576],
            [13.573054147423989, 1.305836103456485],
            [13.469163307121136, 1.290554049906234],
            [13.365000222602475, 1.271945051338363],
            [13.305273294978349, 1.234445086979008],
            [13.26471812690292, 1.224164179791387],
            [13.205000251734106, 1.221664193343216],
            [13.186782185131534, 1.222473214640019],
            [13.170554149986259, 1.247364114379309],
            [13.183645174040294, 1.283818017219573],
            [13.216873217615415, 1.294718011777363],
            [13.249100126672317, 1.329717989688817],
            [13.246391263196671, 1.395553983640326],
            [13.235836100136368, 1.427218131716572],
            [13.224718176095394, 1.448336001550032],
            [13.190282132631268, 1.510554030804911],
            [13.158745054207316, 1.538336016595565],
            [13.135136082743202, 1.577709001043814],
            [13.143191259355518, 1.706873123509922],
            [13.171663243417498, 1.76444506382154],
            [13.192636106325637, 1.808053926870372],
            [13.191391226062393, 1.841109135601556],
            [13.182154033461188, 1.872291156605854],
            [13.184163343296063, 1.929164213828827],
            [13.206945020914475, 1.977782102229227],
            [13.270282201884385, 2.037009133146654],
            [13.289718159021788, 2.072364168477492],
            [13.293891173348413, 2.163609063786069],
            [13.306073263819655, 2.16410007567562],
            [13.661818167023796, 2.16051798552445],
            [13.75055402342889, 2.160554027708358],
            [14.118609092585075, 2.158054041260186],
            [14.29833604026976, 2.152218057338914],
            [14.463054016810588, 2.143053954933634],
            [14.562636055954897, 2.1684730822125],
            [14.714163263793694, 2.124164162608452],
            [14.75916318749745, 2.072500122947474],
            [14.856663329525304, 2.082782035963405],
            [14.887218216532233, 2.016664074788196],
            [15.029163232633863, 1.98694503116829],
            [15.093263334433061, 1.980482080887654],
            [15.123054127144428, 2.01221797805546],
            [15.14889117611682, 2.038745025299164],
            [15.211109205371685, 2.036109084381096],
            [15.239163267740224, 2.029718050830184],
            [15.294718186866135, 1.976664123980669],
            [15.448327124183209, 1.968891082230229],
            [15.491527117994025, 1.974718013696105],
            [15.519163258669067, 1.961108988024208],
            [15.646109194272611, 1.931391117870703],
            [15.687500205734977, 1.933544931716725],
            [15.75742707136186, 1.908609104976208],
            [15.880554044229882, 1.820282117809214],
            [15.978336153481507, 1.771109012140826],
            [16.026109146039374, 1.730554011703489],
            [16.030000193142399, 1.710000076317272],
            [16.04513606630087, 1.674309094306182],
            [16.072218163174284, 1.654164195796156],
            [16.161036162230829, 1.724718027782217],
            [16.142773168626917, 1.799164080337775],
            [16.129163304764887, 1.845000015066574],
            [16.095273258475174, 1.950136070908655],
            [16.08388225202782, 2.044718137156735],
            [16.083473215151827, 2.152500024562528],
            [16.112118201695807, 2.200209147018214],
            [16.15812713890665, 2.18639107668065],
            [16.203536099486513, 2.213545090283816],
            [16.207000172440416, 2.221264152577518],
            [16.270836076650738, 2.355553976487812],
            [16.305827169744674, 2.430000029043271],
            [16.404163322797558, 2.639718096925847],
            [16.441318120383357, 2.720009018219997],
            [16.480273183138081, 2.802218054242189],
            [16.501663297187434, 2.849445049625913],
            [16.495554063221959, 2.884309073067385],
            [16.469509143050203, 2.91097308060948],
            [16.466109107836559, 2.949718092870057],
            [16.482709131845809, 3.155000122303605],
            [16.492500200610465, 3.181109080215805],
            [16.505000132850938, 3.202500032455461],
            [16.527773260928029, 3.234164180531707],
            [16.558745063800075, 3.330973150821848],
            [16.56847326829066, 3.41028204833718],
            [16.573682285852669, 3.44965402695712],
            [16.586245082367128, 3.477917972181643],
            [16.643054101849657, 3.522782109053594],
            [16.664445054089299, 3.535417995763979],
            [16.699163232415032, 3.545282154724561],
            [16.876663276058025, 3.565836090110608],
            [16.957427104330662, 3.554864178823351],
            [17.109718071188837, 3.56750006553375],
            [17.348891152740606, 3.613164171246908],
            [17.415963309776004, 3.680573113152789],
            [17.463609232681563, 3.711109057058508],
            [17.491109251248616, 3.710073053823137],
            [17.521945099650765, 3.686109024939555],
            [17.563891160743026, 3.653335951069977],
            [17.615273233180403, 3.629718094788529],
            [17.642218202117704, 3.629445012382249],
            [17.681109227131998, 3.630000062012144],
            [17.763336200427005, 3.627218108340458],
            [17.82937319241725, 3.616045031376288],
            [17.840000104568929, 3.586109064101706],
            [17.85805405350709, 3.536664217131502],
            [17.909445178399835, 3.552782114068705],
            [18.043327138900509, 3.565836090110608],
            [18.06582718457139, 3.56694501590384],
            [18.110973288666941, 3.562218125413978],
            [18.147563313615109, 3.547709050976692],
            [18.162363240093327, 3.525282095501666],
            [18.169373193376487, 3.490073072924545],
            [18.193609131199878, 3.482500023384375],
            [18.21471811621592, 3.487918085612122],
            [18.235136097132255, 3.501244976422143],
            [18.250554105152418, 3.52652697576481],
            [18.262254068551584, 3.564336064714269],
            [18.45194508748304, 3.616245023586728],
            [18.47971818845636, 3.640836018829589],
            [18.53069122401763, 3.60118207298612],
            [18.568336195302464, 3.538891121173378],
            [18.581391177172662, 3.501109021952331],
            [18.600763264207899, 3.48152705206104],
            [18.624954107392057, 3.479444987306479],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "COK", Country: "Cook Is." },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-155.490396645, -11.412670658999872],
            [-155.706648644999973, -11.631728658999933],
            [-155.710571644999931, -11.634554658999903],
            [-156.190063645000038, -11.976345658999946],
            [-156.723883646000019, -12.23076665899994],
            [-157.295590645999937, -12.389871659999855],
            [-157.88731964699997, -12.448600659999983],
            [-157.888469647, -12.448517659999951],
            [-157.889340646999926, -12.448602659999878],
            [-157.894188646999964, -12.448251659999968],
            [-157.923703647, -12.451150659999939],
            [-157.924679647, -12.451080659999917],
            [-157.925723646999984, -12.451182659999915],
            [-158.248927647000016, -12.427554659999899],
            [-158.346767646999922, -12.56757416],
            [-158.347634647, -12.568762659999962],
            [-158.763509648000024, -12.983773659999898],
            [-158.766713648, -12.98600966],
            [-158.769532648, -12.988817659999981],
            [-159.254250647999868, -13.32641866],
            [-159.255871647999982, -13.327172659999945],
            [-159.257259648, -13.328137659999939],
            [-159.621252649000013, -13.496799660999955],
            [-159.646759648999875, -13.83499266099993],
            [-159.647475649, -13.83774166100001],
            [-159.647676649, -13.840403661],
            [-159.648401648999936, -13.843186660999862],
            [-159.648607648999928, -13.845914660999895],
            [-159.79541864899997, -14.408575660999915],
            [-160.041658649000027, -14.937832662],
            [-160.380702148999859, -15.417588661999915],
            [-160.381463648999983, -15.418562661999957],
            [-160.548761650000017, -15.583234662999956],
            [-160.400173649000038, -15.544473663],
            [-159.80031164899998, -15.490874661999953],
            [-159.798187649, -15.49103966199992],
            [-159.796386649000027, -15.490875661999937],
            [-159.195867647999961, -15.53664766299994],
            [-158.612019648, -15.680461662999974],
            [-158.128055647, -15.868888662999936],
            [-157.868610646999969, -16.404999663000027],
            [-157.245833646, -17.318333664000022],
            [-156.041944644999973, -18.345555664999935],
            [-155.174444644999937, -18.919722665999927],
            [-154.805555644, -19.257222665999947],
            [-156.323055646000029, -21.405555668],
            [-156.142499644999987, -24.89444467099996],
            [-156.59878564600001, -25.058612670999921],
            [-157.215724646000012, -25.216224671999939],
            [-157.270706646, -25.221121672000024],
            [-157.272066645999985, -25.221465671999979],
            [-157.910776646999864, -25.276976671999932],
            [-157.912338646999956, -25.276861671999924],
            [-157.914874647, -25.27707667199995],
            [-158.554355647999984, -25.228842672],
            [-159.173662648, -25.078452671000051],
            [-159.177731148000021, -25.07672467099998],
            [-159.181807147999933, -25.074995170999969],
            [-159.183714647999921, -25.07421117100003],
            [-159.762824648999981, -24.826516670999965],
            [-159.766720148999894, -24.824039170999967],
            [-159.770900649000026, -24.821421337666663],
            [-159.772954648999871, -24.820504671],
            [-159.774456649, -24.819536671000023],
            [-159.776117648999957, -24.818797170999943],
            [-159.778032649000011, -24.817948670999982],
            [-160.163370649, -24.568668671000012],
            [-160.416557649000026, -24.546299670999986],
            [-160.419297648999986, -24.545603671000038],
            [-160.42064764899996, -24.545482670999974],
            [-160.44598364899997, -24.539043670999931],
            [-160.45031664900003, -24.538656670999984],
            [-161.064096649999982, -24.381290670999974],
            [-161.065676649999972, -24.38059267099996],
            [-161.066233149999903, -24.380431170999913],
            [-161.069184649999954, -24.379649671],
            [-161.073076649999933, -24.377964170999988],
            [-161.076825649999876, -24.376342671000032],
            [-161.090591649999965, -24.372781671],
            [-161.092401149999944, -24.372027171000013],
            [-161.664929651, -24.117815671000031],
            [-162.177568651, -23.775264669999984],
            [-162.182371150999984, -23.77072517],
            [-162.619612650999954, -23.35068667],
            [-162.968004651999934, -22.866845668999957],
            [-162.971468651999942, -22.859488668999987],
            [-162.972854651999967, -22.857554669000024],
            [-163.222641651999965, -22.325044669],
            [-163.367299652000014, -21.760467667999976],
            [-163.36781565199999, -21.752243667999963],
            [-163.368068651999863, -21.751255668000013],
            [-163.368148652, -21.749980668000035],
            [-163.37079965199996, -21.739601667999963],
            [-163.389912652, -21.435215667999955],
            [-163.745340651999925, -21.412266667999987],
            [-163.762663653, -21.408224667999946],
            [-163.765749653, -21.408019667999952],
            [-163.969198652999978, -21.360398668],
            [-164.370239652999885, -21.265774667999978],
            [-164.937427654000032, -21.026113667999979],
            [-165.44928565399988, -20.696738666999977],
            [-165.889862653999984, -20.288186666999948],
            [-166.245814655000032, -19.813430666999921],
            [-166.313312654999976, -19.694326666],
            [-166.390771655000037, -19.21686566599999],
            [-166.421834655, -19.03084866599994],
            [-166.570656654999965, -18.126797665000012],
            [-166.580600655000012, -18.059749665],
            [-166.580815655000038, -18.058297664999941],
            [-166.581053655, -18.05669666499999],
            [-166.58118065499994, -18.055845664999921],
            [-166.614892654999977, -17.829321664999938],
            [-166.64305565500004, -17.557777664],
            [-166.027499654999986, -16.758333664],
            [-165.753055653999979, -16.391388662999972],
            [-165.691388653999951, -16.308333662999985],
            [-165.569999653999986, -16.144999663],
            [-165.276666653999939, -15.749444662999949],
            [-165.200833654000036, -15.64638866300001],
            [-165.308055654000043, -15.234444662],
            [-165.368610654, -15.00249966199992],
            [-165.622222653999927, -14.058333660999892],
            [-165.978888655, -13.748888661],
            [-166.155277654999935, -13.595555660999906],
            [-166.428333655000017, -13.356944659999925],
            [-166.567499654999949, -13.234166660000042],
            [-166.617222654999921, -13.190277659999964],
            [-166.872499655000041, -12.964166659999961],
            [-167.183610656000042, -12.689444659999978],
            [-167.422222656, -12.477777659999845],
            [-168.173333657000029, -12.031944658999905],
            [-168.466110656999973, -11.73138865899999],
            [-168.489166657, -11.044444657999989],
            [-168.494999657, -10.875277657999945],
            [-168.517222656999934, -10.213610657999979],
            [-168.517222656999934, -10.212222657999973],
            [-168.523610657, -10.023888656999972],
            [-167.989493655999979, -8.311035655999888],
            [-167.98861465600001, -8.308218655999866],
            [-167.557752656000019, -8.020840655999976],
            [-167.538365656, -8.012283656000022],
            [-167.476322655999923, -7.970782654999823],
            [-166.941333654999966, -7.73380765499985],
            [-166.939156655000033, -7.733263654999959],
            [-166.938152154999983, -7.732860154999884],
            [-166.936388654999973, -7.732118655],
            [-166.93373565499985, -7.731456654999931],
            [-166.931427655000022, -7.730431654999848],
            [-166.363415654999926, -7.588013654999955],
            [-165.779183654, -7.544447654999956],
            [-165.777907653999932, -7.544572655],
            [-165.776207653999961, -7.544444654999978],
            [-165.193046654000028, -7.601070655],
            [-164.628234653000021, -7.756261654999989],
            [-164.098548652999966, -8.005445655999836],
            [-163.88531965300001, -8.155149655999864],
            [-163.677390651999985, -7.864232654999896],
            [-163.675533652000013, -7.862349654999974],
            [-163.25954665200004, -7.453380654999989],
            [-162.779255651999989, -7.121779655000012],
            [-162.248904650999975, -6.877219653999859],
            [-161.684298650999949, -6.726977654],
            [-161.102216650000031, -6.675515654000023],
            [-161.100178649999975, -6.675687653999958],
            [-161.098254650000058, -6.67551665400002],
            [-160.537745649999977, -6.722540653999957],
            [-160.240967649000027, -6.425174653999832],
            [-160.237320649, -6.422610653999968],
            [-160.234976649000032, -6.420260653999946],
            [-159.758129649000011, -6.08451765399991],
            [-159.261546648000035, -5.852911654],
            [-158.221936647, -6.515062653999863],
            [-158.034981646999967, -6.630342653999847],
            [-157.674209647, -6.852132654],
            [-157.590832647000013, -6.903028653999854],
            [-157.417587646999948, -7.008881654999939],
            [-157.400606646999961, -7.018936654999933],
            [-156.686227645999963, -7.441653654999939],
            [-156.600638645999936, -7.493728654999913],
            [-155.862275144999956, -7.939059154999939],
            [-154.926673644000061, -8.474729655999894],
            [-155.037844643999847, -9.050889655999939],
            [-155.141362643999912, -9.586983656999863],
            [-155.237507645000022, -10.085838656999883],
            [-155.237709644999939, -10.086885656999826],
            [-155.403552644999934, -10.941901657999864],
            [-155.490396645, -11.412670658999872],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "COL", Country: "Colombia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.633333196714204, 14.49361115300006],
            [-78.325, 14.25],
            [-77.66667, 14.083333],
            [-74.513888999999836, 14.736111000000122],
            [-73.458332999999897, 15.03333],
            [-71.675, 15.011111],
            [-71.577020962, 15.029897240000068],
            [-71.405277999999896, 15.062826779000105],
            [-71.405277893199894, 14.964444014400144],
            [-71.369362804499872, 14.064483032500064],
            [-71.364093717999879, 13.93245056000022],
            [-71.35353472949987, 13.816676921000152],
            [-71.319711167999913, 13.44581971500007],
            [-71.298768772999864, 13.235148660000192],
            [-71.288687430999914, 13.133734902000157],
            [-71.276319042999916, 13.009314489000175],
            [-71.273668705, 12.987943979000164],
            [-71.271273686, 12.968632190000037],
            [-71.269105318999891, 12.951147969000147],
            [-71.267295280999974, 12.938268551000135],
            [-71.265159781499904, 12.923073293500124],
            [-71.265078958999823, 12.922506232000245],
            [-71.259234914999979, 12.881503599999974],
            [-71.258803020999949, 12.878519646000171],
            [-71.257672764999882, 12.87071071900003],
            [-71.256065313000022, 12.859604848000131],
            [-71.252753192999876, 12.836027275000191],
            [-71.250427966999951, 12.819474981000226],
            [-71.248355045000039, 12.804718729],
            [-71.245369334999907, 12.79384358100009],
            [-71.236525441000026, 12.761630586000109],
            [-71.232440398999984, 12.746751229000083],
            [-71.231184229999968, 12.742222253000165],
            [-71.22844497799997, 12.732346184000235],
            [-71.225980054999866, 12.723459179000059],
            [-71.220180283999866, 12.707690565000092],
            [-71.212184465999911, 12.685951264000025],
            [-71.205001455999849, 12.666421851000194],
            [-71.195623411999918, 12.641908193000177],
            [-71.19404813899996, 12.637832388000177],
            [-71.188130554999901, 12.622521446000064],
            [-71.184043951999911, 12.611947917000151],
            [-71.182075841999875, 12.606971402000241],
            [-71.17981760699999, 12.601261284000117],
            [-71.172174615999893, 12.582160975000093],
            [-71.163220053, 12.559782969000025],
            [-71.161267295999892, 12.555312428999969],
            [-71.159035863999918, 12.550203905000231],
            [-71.158176434999859, 12.54823637400024],
            [-71.136082139999985, 12.499628987000037],
            [-71.134840539000038, 12.497203674000176],
            [-71.133954950999851, 12.495473790000048],
            [-71.127112860999858, 12.481949582000155],
            [-71.116121390999922, 12.463272853000177],
            [-71.099772332999891, 12.435496574000112],
            [-71.099239102999917, 12.434683432000156],
            [-71.080721360499894, 12.40748839800014],
            [-71.074422575999961, 12.378000798000159],
            [-71.061864765, 12.319223165000096],
            [-71.061544832499862, 12.318481935000136],
            [-71.060664333999966, 12.316472221000026],
            [-71.058368111999869, 12.311572732000101],
            [-71.049872282000024, 12.293743062000132],
            [-71.04742320399987, 12.289417783000104],
            [-71.045271414000013, 12.286012798000186],
            [-71.041800502999877, 12.281127276000078],
            [-71.014622570999848, 12.243694230000116],
            [-71.013964691999973, 12.242772076000193],
            [-71.010355339999933, 12.237712816000098],
            [-70.998307575999888, 12.225473183000176],
            [-70.99403457799994, 12.221132134000271],
            [-70.992295011999886, 12.219385557000265],
            [-70.990992020999897, 12.218151573000128],
            [-70.987823269999893, 12.215150640000246],
            [-70.984948358999986, 12.212490086000201],
            [-70.982967540999852, 12.21065696100014],
            [-70.979716903999929, 12.207750670000109],
            [-70.972319339999899, 12.201809748000073],
            [-70.955582184999912, 12.188196575],
            [-70.954119119499865, 12.187087022000128],
            [-70.939437995999867, 12.176074304500077],
            [-70.926167605000018, 12.166121562000058],
            [-70.923624215999894, 12.164240707000133],
            [-70.914904617999895, 12.157882730000139],
            [-70.910723962999839, 12.154834365000241],
            [-70.836442360999854, 12.099837146000155],
            [-70.823295155999972, 12.090103111000076],
            [-70.821905374999915, 12.089077047000089],
            [-70.814356063999952, 12.083503456000159],
            [-70.776322486999845, 12.058403079000172],
            [-70.775104945999828, 12.057601523000102],
            [-70.774382140999876, 12.05712691],
            [-70.76999426299983, 12.054245712000181],
            [-70.765094989999909, 12.051089860000189],
            [-70.694608899333247, 12.005727491000144],
            [-70.697748740999828, 11.988552103000231],
            [-70.698783687999935, 11.982890792000148],
            [-70.700874348999889, 11.971454568000169],
            [-70.702114332999912, 11.964946324000096],
            [-70.702353356999879, 11.963975614999981],
            [-70.707969489999954, 11.939892229000208],
            [-70.710485624999961, 11.929102410000155],
            [-70.725955935999849, 11.862761835000143],
            [-70.726722915999972, 11.859428857000239],
            [-70.726861572999894, 11.858826306500077],
            [-70.727233112999897, 11.85722686400004],
            [-70.728141639999848, 11.853162128000164],
            [-70.728283030999904, 11.852529546000127],
            [-70.729133074999908, 11.848625514000148],
            [-70.731174352999858, 11.839242625000153],
            [-70.742239174000019, 11.78838232300015],
            [-70.742631368999866, 11.786440898],
            [-70.746124343999867, 11.768951680000143],
            [-70.747105577999974, 11.764038674000062],
            [-70.749267743999894, 11.753212775000051],
            [-70.758309639, 11.705042694000142],
            [-70.807044613000016, 11.679830089000106],
            [-70.851750395999915, 11.656701950000055],
            [-70.866827541999868, 11.648901923000125],
            [-70.873388251999927, 11.645922286000228],
            [-70.885694191999931, 11.640333375000097],
            [-70.893195898999863, 11.636901895000037],
            [-70.933580136000046, 11.622823252000188],
            [-70.936406010999917, 11.621805141500104],
            [-70.948840645999979, 11.633227236000025],
            [-70.993728641999837, 11.674460233000246],
            [-71.029623935999837, 11.703766354999971],
            [-71.24506657500001, 11.822060082000178],
            [-71.260494652999853, 11.82864523800022],
            [-71.277804204999967, 11.835982982000019],
            [-71.296065792999883, 11.84313613900008],
            [-71.318632168, 11.851975502000215],
            [-71.324717886203672, 11.85305395783827],
            [-71.403335779823124, 11.812773213111697],
            [-71.468064021447788, 11.795553934094102],
            [-71.626399849254994, 11.755553983286489],
            [-71.721681972058377, 11.721945066030145],
            [-71.769726873556237, 11.700836081014018],
            [-71.968481914968805, 11.666244972340763],
            [-72.072726806862732, 11.495509124579243],
            [-72.20935501412734, 11.250000162399502],
            [-72.317646018333505, 11.164509108360662],
            [-72.349872927390493, 11.163473105125462],
            [-72.472090966675609, 11.134717980373225],
            [-72.491255014873389, 11.122773097762547],
            [-72.540008857743771, 11.031944950681009],
            [-72.633617785030623, 10.896391135768454],
            [-72.704726834284685, 10.770553958319965],
            [-72.810008902880384, 10.59055392822907],
            [-72.836672910422493, 10.554445024524469],
            [-72.903545913437682, 10.444444950256141],
            [-72.908535828050134, 10.426691073452602],
            [-72.91500883661439, 10.350554055745761],
            [-72.938899942940196, 10.11645403728609],
            [-72.956535802546654, 10.082082199200485],
            [-72.977508833092884, 10.001945002010885],
            [-72.986463890832397, 9.904926987055077],
            [-73.004726884436309, 9.768327110623162],
            [-73.081681975895435, 9.609444950365557],
            [-73.117508912376337, 9.561391164050278],
            [-73.245146019717708, 9.408473063194918],
            [-73.363890933593325, 9.225827033901894],
            [-73.378063893712351, 9.171391098853206],
            [-73.345563902249069, 9.166391125957048],
            [-73.221954969947092, 9.171109131629592],
            [-73.165635788887556, 9.195764164612982],
            [-73.143545953559169, 9.223891149539213],
            [-73.009999939738663, 9.302009146248039],
            [-72.981126797789699, 9.260827011813348],
            [-72.958345958361491, 9.201109136644718],
            [-72.96250891440431, 9.178054041344112],
            [-72.885563881229075, 9.119164130573324],
            [-72.779726930641658, 9.08027310555903],
            [-72.736954916808145, 8.917218098612793],
            [-72.664464026992846, 8.641109154786889],
            [-72.423064041399101, 8.389718108218162],
            [-72.405335813219324, 8.385926973401411],
            [-72.401735785795523, 8.384164091520589],
            [-72.388199850319666, 8.369444966589242],
            [-72.376108954955271, 8.336944975126045],
            [-72.336672938594887, 8.151944972138651],
            [-72.325008849741351, 8.089027053966817],
            [-72.328617929620748, 8.061809002623392],
            [-72.345835867533879, 8.047709132700206],
            [-72.389381866308753, 8.047564125774983],
            [-72.459726821267139, 7.920553984792903],
            [-72.47348185969247, 7.656245084290262],
            [-72.472446024095262, 7.497982179040989],
            [-72.419308948766002, 7.420554012965482],
            [-72.401672921521367, 7.40721806969988],
            [-72.397763937145641, 7.406426985675992],
            [-72.323899923948204, 7.393053994398443],
            [-72.262508853262943, 7.389445082157039],
            [-72.154726798219173, 7.325282116083812],
            [-72.164035907549845, 7.289444953681027],
            [-72.164599841997074, 7.262500152381875],
            [-72.145281902056581, 7.198609095248457],
            [-72.132926809103509, 7.17333598072338],
            [-72.113346012678676, 7.13833600281211],
            [-72.092508936602371, 7.103609107306937],
            [-72.06609990655565, 7.062418088054741],
            [-72.053799966525617, 7.062145005648631],
            [-71.992363968839214, 7.016245033179388],
            [-71.926799883827329, 7.00974503488672],
            [-71.876872909785334, 6.986526995387891],
            [-71.83278191966437, 6.987217999487513],
            [-71.804663987193464, 7.003127019396956],
            [-71.701954995241692, 7.046391050948216],
            [-71.645290982684571, 7.060553952783351],
            [-71.601390932318623, 7.057918011865368],
            [-71.553072948414666, 7.045000157931455],
            [-71.48806391303259, 7.028891145811485],
            [-71.463899891938667, 7.02333595564744],
            [-71.332508865430668, 7.022364157790676],
            [-71.26243581941219, 6.987427044153364],
            [-71.181263960091769, 6.963473073553402],
            [-71.025008855934715, 6.984444930633146],
            [-71.005281879118115, 6.992773189651572],
            [-70.970835777370183, 7.008336036958966],
            [-70.908199826421878, 7.048191148479347],
            [-70.886535791776026, 7.075073085504343],
            [-70.8575089257223, 7.082500122290995],
            [-70.819454917561274, 7.087500095187153],
            [-70.719726865663233, 7.098053917142892],
            [-70.552508902674248, 7.058336101197057],
            [-70.441681869836401, 7.011109105813148],
            [-70.386126783072399, 6.983054037616384],
            [-70.360835898912427, 6.963891162885091],
            [-70.34389992822301, 6.95250015643785],
            [-70.323626786593991, 6.945000029455443],
            [-70.294726822554708, 6.938473209072612],
            [-70.26229992128728, 6.945691033555065],
            [-70.243345923583746, 6.960835959169117],
            [-70.221817843407706, 6.974027063147318],
            [-70.158482003542275, 6.982500161452961],
            [-70.119172889196363, 6.975836045495768],
            [-69.724726894904478, 6.486664151496683],
            [-69.429454908709118, 6.118609082340413],
            [-69.404172909366451, 6.130836099812868],
            [-69.371254996209814, 6.145836018501342],
            [-69.316117831139167, 6.148609087355709],
            [-69.3007088755746, 6.122773211849704],
            [-69.270708870559474, 6.090973109303462],
            [-69.241746042246035, 6.084100116318595],
            [-69.19520887749573, 6.100418005466054],
            [-69.153335906599409, 6.141109128011294],
            [-69.11557291811755, 6.179164142000545],
            [-69.087926886796708, 6.203054074859878],
            [-69.056399866656761, 6.21610905673036],
            [-69.025835759556344, 6.214864176467131],
            [-68.827790832982714, 6.186391186576842],
            [-68.753617862833266, 6.169444989965541],
            [-68.712017806705148, 6.157009095465412],
            [-68.6861178934588, 6.141527049704806],
            [-68.638408938641192, 6.135482188755944],
            [-68.549999976460754, 6.164445017069212],
            [-68.476963927661359, 6.186391186576842],
            [-68.453899947543249, 6.190553974981498],
            [-68.41930883886991, 6.19180908116634],
            [-68.374308915166324, 6.179718018163967],
            [-68.319454890785863, 6.168053929310616],
            [-68.295290869691826, 6.175836023516453],
            [-68.229735837135536, 6.198891118817244],
            [-68.194172930605248, 6.210836001427907],
            [-68.155155003576652, 6.222773172687553],
            [-68.132235862745773, 6.22360901607442],
            [-68.103617865930005, 6.221527118957852],
            [-68.066117901570465, 6.213335987875993],
            [-68.035281885530225, 6.205554061308135],
            [-67.997926928096263, 6.207218036731192],
            [-67.957508887957147, 6.217782084608729],
            [-67.918126851053415, 6.238191180707716],
            [-67.831181872085864, 6.307573054988595],
            [-67.693346000208294, 6.293053922267418],
            [-67.635281874540738, 6.285136041229663],
            [-67.563617943294958, 6.262500041088884],
            [-67.534454955133185, 6.24305402566749],
            [-67.496263986674023, 6.205418106838053],
            [-67.472608914742295, 6.19445407453982],
            [-67.454455053518132, 6.19305396142957],
            [-67.489245986763763, 6.153745014721764],
            [-67.492526831313739, 6.121409140923092],
            [-67.48556398432649, 6.09778206454807],
            [-67.46958187422112, 6.069664132077165],
            [-67.434990933185844, 6.038645055271189],
            [-67.413981860456005, 5.995536088929128],
            [-67.426254978395889, 5.976727098150732],
            [-67.461726857457421, 5.955535970483524],
            [-67.478926858097537, 5.941600050586885],
            [-67.531872993671755, 5.891327071580847],
            [-67.597072968808675, 5.824691108787846],
            [-67.6199998209905, 5.79221810705279],
            [-67.642926840810304, 5.73139113845302],
            [-67.651673021522242, 5.683191171745875],
            [-67.648899952667875, 5.652218027769521],
            [-67.642226784255172, 5.612218076961909],
            [-67.633755027054093, 5.578264160569816],
            [-67.615908949315212, 5.548191065358751],
            [-67.649445949842288, 5.478335948823258],
            [-67.688335801390167, 5.448609026214427],
            [-67.747655033242665, 5.412209102830715],
            [-67.769172887496637, 5.406809145513861],
            [-67.803890898184477, 5.383264211790092],
            [-67.848635844392874, 5.30651799735891],
            [-67.829726773690481, 5.131391038149587],
            [-67.82028187752789, 5.093336024160337],
            [-67.802781972391244, 5.039445080457398],
            [-67.80158185149125, 4.973236091813419],
            [-67.813599824297853, 4.831153947775405],
            [-67.816655028013741, 4.801627017376745],
            [-67.824717915976805, 4.736664082462468],
            [-67.858754980848744, 4.561244930107563],
            [-67.784799940182552, 4.334754003371884],
            [-67.80126400972199, 4.300136072608311],
            [-67.805555041245611, 4.272782066794974],
            [-67.806254930162652, 4.231809144664126],
            [-67.786672960271375, 4.166527026875769],
            [-67.72917293668911, 4.087500096584137],
            [-67.70850886309492, 4.046953980963949],
            [-67.692781898123002, 3.96971809574795],
            [-67.68055504828871, 3.921391059388398],
            [-67.63514592007084, 3.797635946694641],
            [-67.615981871872975, 3.759027056541967],
            [-67.599872859753191, 3.740691140380207],
            [-67.576954892388784, 3.73236422246643],
            [-67.541954914477515, 3.736527010871086],
            [-67.511672942238789, 3.737982109266454],
            [-67.491045916656617, 3.724309045854298],
            [-67.437090935213234, 3.648335978174003],
            [-67.428345927967968, 3.608054060142777],
            [-67.395573021736368, 3.512500028399671],
            [-67.381117926755735, 3.48597314879386],
            [-67.362917797425894, 3.473053953755297],
            [-67.341535897641734, 3.466526965734488],
            [-67.306599957470894, 3.452782153231041],
            [-67.292854977329284, 3.396045050477795],
            [-67.34639991606798, 3.313682122712876],
            [-67.369735805125998, 3.304718012517952],
            [-67.435563920088583, 3.253891157348306],
            [-67.499999968567835, 3.190654056302563],
            [-67.65668187923572, 3.042782196448982],
            [-67.833072997085566, 2.876664106797918],
            [-67.823617875000991, 2.827082131891331],
            [-67.765835884195212, 2.832500026481185],
            [-67.63444603115363, 2.809444931180224],
            [-67.605835913327041, 2.793336086698602],
            [-67.58973578602459, 2.764164213719496],
            [-67.535281913702818, 2.680000014957159],
            [-67.48556398432649, 2.653336007415149],
            [-67.325208956038864, 2.47402698142389],
            [-67.2813999331415, 2.438891049042539],
            [-67.257926916147284, 2.42902705772012],
            [-67.219517850566973, 2.413127090266002],
            [-67.1925088438893, 2.392500064684015],
            [-67.174181980182937, 2.336527056226515],
            [-67.18459096285153, 2.31610907531001],
            [-67.207508930215852, 2.294864135824341],
            [-67.216945947389348, 2.275282165932865],
            [-67.211535764150597, 2.243609133039385],
            [-67.198626795034102, 2.207500061696777],
            [-67.175290905976169, 2.153336035587557],
            [-67.160572954511224, 2.132500132977711],
            [-67.113235820919613, 2.026653962296876],
            [-66.991672911188488, 1.69583600101565],
            [-66.926390961038209, 1.459718123179854],
            [-66.899035781758471, 1.284100152081265],
            [-66.89168200280605, 1.251391115952217],
            [-66.875072926341289, 1.225900071943983],
            [-66.870455000593012, 1.220927088775966],
            [-66.885290969254953, 1.221100091257995],
            [-67.03278195542714, 1.182500085922726],
            [-67.075290945138335, 1.172500140130325],
            [-67.084164027864574, 1.193891092370066],
            [-67.087781992561304, 1.266391034640876],
            [-67.087781992561304, 1.286391093863585],
            [-67.080417820048979, 1.385282128908202],
            [-67.073899884483495, 1.414444949431896],
            [-67.063608919012069, 1.438336055757802],
            [-67.05688193878072, 1.520136054903787],
            [-67.071181968552139, 1.620409098147931],
            [-67.113617868067308, 1.73500010843641],
            [-67.160846036917519, 1.81860908993076],
            [-67.206599828994968, 1.875836031106715],
            [-67.228199825900276, 1.891809088756787],
            [-67.264726818936396, 1.946945080360763],
            [-67.308899951708639, 2.032782139363476],
            [-67.319735908525985, 2.06305405331841],
            [-67.325426885522035, 2.088473180597362],
            [-67.339726915293454, 2.113053949918339],
            [-67.42257297595873, 2.14284507790596],
            [-67.505846010773013, 2.106945051229033],
            [-67.565846020803235, 2.067218015189539],
            [-67.585981866857963, 2.053053939888102],
            [-67.666399857804521, 1.973336005496577],
            [-67.676955020864824, 1.936944966930284],
            [-67.687226875596934, 1.919582189730022],
            [-67.771045907585631, 1.818818134596512],
            [-67.811672992390413, 1.784309000936531],
            [-67.872508845807772, 1.755135954490939],
            [-67.914735868295168, 1.745282189090332],
            [-67.941808912713469, 1.742500067780554],
            [-67.967781916155758, 1.745836065253755],
            [-67.990708935975476, 1.753745061474092],
            [-68.010146066579438, 1.768473071222843],
            [-68.035281885530225, 1.801391152017558],
            [-68.065564025407127, 1.853335985435834],
            [-68.075146049506117, 1.872082111940088],
            [-68.088481992771534, 1.927082149074266],
            [-68.104863919659437, 1.951809098787024],
            [-68.124026794390915, 1.963744928942191],
            [-68.174455006244244, 1.977500135005783],
            [-68.196399834647139, 1.977500135005783],
            [-68.226672922068531, 1.933609137095345],
            [-68.236955002722652, 1.90971803076944],
            [-68.257235855702675, 1.852782109272511],
            [-68.232790873213531, 1.775553935407174],
            [-68.211535875443673, 1.771873106436487],
            [-68.182645969688195, 1.766526960938194],
            [-68.153063886366596, 1.724164151618879],
            [-68.82889992641384, 1.726109088437241],
            [-69.103055048604261, 1.727500149091995],
            [-69.24000880898916, 1.728054025255418],
            [-69.372472886744646, 1.728054025255418],
            [-69.400281862263512, 1.744445004598816],
            [-69.459163894045304, 1.760282107778892],
            [-69.553899852036096, 1.783891079243006],
            [-69.700563879638935, 1.745282189090332],
            [-69.784946007884372, 1.707573180065125],
            [-69.846099870709054, 1.710454040194605],
            [-69.842226928516965, 1.698054020240122],
            [-69.842226928516965, 1.072218044430869],
            [-69.718335861353296, 1.072500011654483],
            [-69.613617894842804, 1.078054028352057],
            [-69.435290892630292, 1.038336044768059],
            [-69.37681789772472, 1.056527121642588],
            [-69.338481922340236, 1.072918100985987],
            [-69.264872886638102, 1.033891121501711],
            [-69.227917913624765, 0.999027098060253],
            [-69.140290983012818, 0.86347311550962],
            [-69.159308850818888, 0.752917991611469],
            [-69.127817872862664, 0.644027010774366],
            [-69.20978198967336, 0.616309062724156],
            [-69.287508886778909, 0.622918025758509],
            [-69.32889989824136, 0.656391156183119],
            [-69.459281911242186, 0.73660010246401],
            [-69.683481896195644, 0.677218006337284],
            [-69.749999841791549, 0.633891110512238],
            [-69.795708874505948, 0.601527073518767],
            [-69.922499912538285, 0.597782039169459],
            [-69.983472893891957, 0.597917993639371],
            [-70.04472683664082, 0.585000139705457],
            [-70.046399864519458, 0.511391104003323],
            [-70.048335916520216, 0.368335988642073],
            [-70.049454900597254, 0.306945085594805],
            [-70.052508930846841, 0.143609117253433],
            [-70.055526918912705, 0.000000125728576],
            [-70.057508903743013, -0.088054952498595],
            [-70.058063953372908, -0.157499858691608],
            [-70.021664029989182, -0.203608875826518],
            [-69.933481882109533, -0.304308893219584],
            [-69.739454904653144, -0.455690926494867],
            [-69.717090981090337, -0.460973034252731],
            [-69.688617823561884, -0.466808850535926],
            [-69.630417911062409, -0.494164029815735],
            [-69.606535857192085, -0.519863950851914],
            [-69.573546027305923, -0.634926862290285],
            [-69.582781878802763, -0.668054993579318],
            [-69.596317814278535, -0.700490947302072],
            [-69.615290922721442, -0.724308963432037],
            [-69.612645929347877, -0.755835815933906],
            [-69.517781895876084, -0.933472987513184],
            [-69.480846033601864, -0.954999894222652],
            [-69.434235778655619, -1.001182001553502],
            [-69.441972945860499, -1.017808847653001],
            [-69.431399845527466, -1.040972907695277],
            [-69.411955003572558, -1.131946061702223],
            [-69.390017886520411, -1.246108924375207],
            [-69.378545910888306, -1.33791792176919],
            [-69.391046010766871, -1.381526952456014],
            [-69.452635900195901, -1.526035825108266],
            [-69.522508954004138, -1.914164043683101],
            [-69.594181937705315, -2.3111088509567],
            [-69.607790963377028, -2.386390914537273],
            [-69.625835859859677, -2.482217861048596],
            [-69.635281929488656, -2.534446002794866],
            [-69.793063881132468, -3.419163932061764],
            [-69.881954970384754, -3.920281882137914],
            [-69.943335815148032, -4.212364051251882],
            [-69.956926903547014, -4.236872903843476],
            [-69.956681984335432, -4.209445975472192],
            [-69.988617873713508, -4.175973012685574],
            [-70.021117865176791, -4.153890888708219],
            [-70.040846015459778, -4.140554945442702],
            [-70.123072988754785, -4.072363971968329],
            [-70.13612679715871, -4.050835891792389],
            [-70.144308875785327, -4.031808971530992],
            [-70.152508891684789, -4.005835800450626],
            [-70.1626550178689, -3.974863997578566],
            [-70.181881930340637, -3.937426897493168],
            [-70.234454903584577, -3.871254956861321],
            [-70.323064025613263, -3.799164051466619],
            [-70.341673024181034, -3.78999994906124],
            [-70.362226791929174, -3.787781929836783],
            [-70.406535879171315, -3.802499881301728],
            [-70.423899829838064, -3.81610890697344],
            [-70.479446031784619, -3.849999959091576],
            [-70.500835810557874, -3.859308900784256],
            [-70.52799082998925, -3.859235810588402],
            [-70.607726869291696, -3.829481898251075],
            [-70.724163909940557, -3.779717868407232],
            [-70.672226787873313, -3.69778191479125],
            [-70.627499946575824, -3.629163967167955],
            [-70.565846019406251, -3.533608929596539],
            [-70.44723588653406, -3.349445943462655],
            [-70.412226856167194, -3.294717815268299],
            [-70.067508849535415, -2.755555077124782],
            [-70.098481993511939, -2.69833584729966],
            [-70.120681967048398, -2.701663965783752],
            [-70.194590907246777, -2.644017929447799],
            [-70.20886394729007, -2.609426820774459],
            [-70.218826845070168, -2.570346029471708],
            [-70.295390836926117, -2.501281996960188],
            [-70.33564593286701, -2.492399861778438],
            [-70.355808936287843, -2.498172981425654],
            [-70.37774605333999, -2.511673042355866],
            [-70.564454958751412, -2.493335785089641],
            [-70.582372953219647, -2.48389088892705],
            [-70.64972690984041, -2.401255046394013],
            [-70.657645964344738, -2.358191007053108],
            [-70.858964024117512, -2.225346055616313],
            [-70.976390967534229, -2.278335777087207],
            [-71.101117878084835, -2.311946035448202],
            [-71.188064030518859, -2.360555039031283],
            [-71.361681911876246, -2.34694601335957],
            [-71.412226799822292, -2.281255026333369],
            [-71.520145982802461, -2.234581907113053],
            [-71.576672867423184, -2.236663971867614],
            [-71.674317848738355, -2.224999883014178],
            [-71.68417295524344, -2.196108971430277],
            [-71.683208868737438, -2.166526888108592],
            [-71.693199929712421, -2.147917889540722],
            [-71.721955054464587, -2.159164056700646],
            [-71.808481944100635, -2.23083586693545],
            [-71.871399862272455, -2.32028200581756],
            [-71.889799816174673, -2.377782029399896],
            [-71.931399872302876, -2.379008972390125],
            [-71.982226895110415, -2.369446059030452],
            [-72.07000889093149, -2.407781866776929],
            [-72.227508875351589, -2.498890975253687],
            [-72.299454941459061, -2.491391015909457],
            [-72.327781918595718, -2.483055045540098],
            [-72.450835801267971, -2.441390951671451],
            [-72.590146049918189, -2.407217932329701],
            [-72.683335882044929, -2.439999891016598],
            [-72.814735793370346, -2.489999955254518],
            [-72.88195496898777, -2.506390934598016],
            [-72.902508904374002, -2.45360891668831],
            [-72.934581921688363, -2.399999940208986],
            [-72.975008846644727, -2.401663915632042],
            [-73.003063914841647, -2.410554935631211],
            [-73.073899881689499, -2.371390995848714],
            [-73.104735897729654, -2.34694601335957],
            [-73.115290893151865, -2.328754936485126],
            [-73.1317460778738, -2.246255048422],
            [-73.101255060969237, -2.23194596619507],
            [-73.077790928792524, -2.200281985756902],
            [-73.058208958901218, -2.145555031028934],
            [-73.090426815502724, -1.923608939845607],
            [-73.132781913471092, -1.849164060756536],
            [-73.197017802102096, -1.806455078835171],
            [-73.238890940636423, -1.845554980877225],
            [-73.296946013848668, -1.873546011333715],
            [-73.416399869096921, -1.816663901655247],
            [-73.490573006884176, -1.766945972278933],
            [-73.507508809935501, -1.74833580024459],
            [-73.486955042187446, -1.654446079200611],
            [-73.476963981212464, -1.636664039202273],
            [-73.447154915952098, -1.610346036624321],
            [-73.476963981212464, -1.552499840439935],
            [-73.520008909813981, -1.493608923840839],
            [-73.542781870253066, -1.468055015558534],
            [-73.54749987592561, -1.436663949888484],
            [-73.555699891825157, -1.375281931658634],
            [-73.653617787908445, -1.284446073226164],
            [-73.713055036958025, -1.2791639654683],
            [-73.752235908184929, -1.28971795506213],
            [-73.840981990511921, -1.265135844636589],
            [-73.868055034930023, -1.208608960015766],
            [-74.077790872447338, -1.072781895058839],
            [-74.215426919752616, -1.034864009005886],
            [-74.240699866639801, -1.012917839498428],
            [-74.255281863634821, -0.986664042298912],
            [-74.28279093465747, -0.930281996965306],
            [-74.264172883633989, -0.898890931295256],
            [-74.247790956746002, -0.853608872729879],
            [-74.290282009184381, -0.809717874819341],
            [-74.316117884690271, -0.781663980088879],
            [-74.338899897584866, -0.706390968963788],
            [-74.358063945782646, -0.633055015667949],
            [-74.370281910799321, -0.577554914188923],
            [-74.376108842265097, -0.568055032741384],
            [-74.393890882263435, -0.543335794379558],
            [-74.556946056847778, -0.41778192525932],
            [-74.60750888206644, -0.383608905917583],
            [-74.632917951061586, -0.406526873281976],
            [-74.708763949089416, -0.361663909876512],
            [-74.715835760818322, -0.333608841679577],
            [-74.716946027715949, -0.311664013276584],
            [-74.721117868576101, -0.28971784376904],
            [-74.733608915999156, -0.260972944938857],
            [-74.749172936772936, -0.235554991126378],
            [-74.772372871361057, -0.20791784462304],
            [-74.791945956434915, -0.200281930808814],
            [-74.812646072212857, -0.205690940581164],
            [-74.841126773454249, -0.225281962927866],
            [-74.860281936834525, -0.232499955048482],
            [-74.93806398686317, -0.198890870153974],
            [-74.960554980078655, -0.170555008199898],
            [-75.021391001134106, -0.123054930409964],
            [-75.137017847019791, -0.045345970577245],
            [-75.205008828283809, -0.03687303990958],
            [-75.216672917137146, -0.068335854671105],
            [-75.225908936271992, -0.095973001174443],
            [-75.259146032302709, -0.122481943507324],
            [-75.285845914390364, -0.119717927108454],
            [-75.3000088162255, -0.10221785433373],
            [-75.325563897974348, -0.0883359138939],
            [-75.349726913239891, -0.078335800463421],
            [-75.399035805739999, -0.072081894848708],
            [-75.434445993993876, -0.059446008138323],
            [-75.518890986513526, -0.008055050883556],
            [-75.57542692358976, 0.031809113092237],
            [-75.694035882995479, 0.043053939147597],
            [-75.727508845782097, 0.035553979803367],
            [-75.760846021736882, 0.046809031780711],
            [-75.912781931175516, 0.163336094070033],
            [-75.980281900550267, 0.256391145193376],
            [-76.024872955015923, 0.320126969479603],
            [-76.040117792915879, 0.337636094709637],
            [-76.145282011952759, 0.370835975090145],
            [-76.243054901110554, 0.395554039985498],
            [-76.400846072847941, 0.392318122436464],
            [-76.407790982562346, 0.271664146288316],
            [-76.448626776756925, 0.246945075564483],
            [-76.545154953289739, 0.219164095602139],
            [-76.566672975181973, 0.220553982790591],
            [-76.584872936873666, 0.230836063444684],
            [-76.611955033747364, 0.258891131641448],
            [-76.716663947802346, 0.283191107205468],
            [-76.726963965729169, 0.263053920046104],
            [-76.770281976736982, 0.24889101821114],
            [-76.811399905793337, 0.24139105886691],
            [-76.866599935137742, 0.239373199490601],
            [-77.035281881339444, 0.306245029039587],
            [-77.079608905854201, 0.359564159306288],
            [-77.191746030162221, 0.334918011140402],
            [-77.218899876127125, 0.336109079584986],
            [-77.311672960027039, 0.363054048522116],
            [-77.379455064263198, 0.384718083167968],
            [-77.422090955988807, 0.412782036182421],
            [-77.451117822042505, 0.541244928626739],
            [-77.446254977082731, 0.585136094175354],
            [-77.443481908228364, 0.621809099965176],
            [-77.463763934674773, 0.655553971691603],
            [-77.493335792074589, 0.665282176182288],
            [-77.513481864051187, 0.658473053299687],
            [-77.535845955252057, 0.655691099627816],
            [-77.565290910637458, 0.673891061319665],
            [-77.57999997728507, 0.686109026336453],
            [-77.624726818582474, 0.710835976049296],
            [-77.646954955313731, 0.728891098453929],
            [-77.659308874800416, 0.750553959633294],
            [-77.661664021961172, 0.781391149139921],
            [-77.656335813735694, 0.809400116869242],
            [-77.678346020983781, 0.837217977205725],
            [-77.698617821508122, 0.845282038635176],
            [-77.780008951416193, 0.827918087968357],
            [-77.814181970758028, 0.808335950439073],
            [-77.853899954341927, 0.813744960211423],
            [-77.883335857271817, 0.825836023213796],
            [-77.929735894085837, 0.875282043650301],
            [-77.999308875759652, 0.904309077342177],
            [-78.032091002084542, 0.900553984709063],
            [-78.070008888137608, 0.904445031812074],
            [-78.100008893152619, 0.915973166195712],
            [-78.175290956733306, 0.970553940531801],
            [-78.230008859005778, 1.01805401832182],
            [-78.248335890350035, 1.029864119928888],
            [-78.283617835485103, 1.038891094397954],
            [-78.310154941012684, 1.043891067294112],
            [-78.332226839068085, 1.052782087293281],
            [-78.353763971699522, 1.068745086659362],
            [-78.441535909236791, 1.154445017725877],
            [-78.455835771370147, 1.17471799171679],
            [-78.466535773717766, 1.192635986185039],
            [-78.540145982886287, 1.209445054859955],
            [-78.560290881396213, 1.19860909804261],
            [-78.591672894610952, 1.243053972116471],
            [-78.63195498028017, 1.262218020314251],
            [-78.656681929992942, 1.271945051338449],
            [-78.67750894778527, 1.285553909372169],
            [-78.809717880203635, 1.437782179797239],
            [-78.857284628999878, 1.449656710000099],
            [-83.45764790933336, 1.449656449666747],
            [-83.765266917499957, 1.449656710000099],
            [-84.153738649999923, 1.835443906000208],
            [-84.488672822000012, 2.309590522000263],
            [-84.736487429999983, 2.834447092000133],
            [-84.753967723999892, 2.959297386000245],
            [-84.766666744999895, 3.049999930000254],
            [-84.316666744999878, 3.533332931000018],
            [-84.316666744999878, 4.999999932000151],
            [-79.866666740999875, 4.999999932000151],
            [-79.233333739999921, 5.999999933000225],
            [-79.04999974, 6.266665933000141],
            [-78.783333739999989, 6.466665933000201],
            [-78.299999738999873, 6.733332934000117],
            [-77.889166738999847, 7.210832934000152],
            [-77.889727314911312, 7.228890500743731],
            [-77.807499917527821, 7.478682176373482],
            [-77.775699982619841, 7.475418095629664],
            [-77.748617885746143, 7.484309115628832],
            [-77.73222690640273, 7.505900060078915],
            [-77.720981912709192, 7.536453941257463],
            [-77.729381920818895, 7.568891068446518],
            [-77.754590997603714, 7.612218131909913],
            [-77.759172881168354, 7.633336001743359],
            [-77.759445963574649, 7.667218001405999],
            [-77.758899966400236, 7.693054044550166],
            [-77.744035834543496, 7.72000001931579],
            [-77.663890926003063, 7.679445018878454],
            [-77.624172942419136, 7.603335996728248],
            [-77.61029100197922, 7.562500034895422],
            [-77.600146049261497, 7.539236062567142],
            [-77.577435785458391, 7.526182086525338],
            [-77.330982015150028, 7.701809110079424],
            [-77.330081966384654, 7.726173123383532],
            [-77.369999942178907, 7.778473181859354],
            [-77.311399877620829, 7.886945067536558],
            [-77.295208890487601, 7.904864235471095],
            [-77.215564046292116, 7.937218046542597],
            [-77.198335882457201, 7.999444960614795],
            [-77.215426918355632, 8.087918128173484],
            [-77.243899908246078, 8.145273144830597],
            [-77.273608893582264, 8.198609038903641],
            [-77.296108939253145, 8.215826976816572],
            [-77.348890957162865, 8.267773151339156],
            [-77.362499982834578, 8.285000141707769],
            [-77.368055005360532, 8.337218057532155],
            [-77.36861793397938, 8.364164199935772],
            [-77.375008967530476, 8.398609128217331],
            [-77.405772899203157, 8.451318055931182],
            [-77.429099903443756, 8.472500131143065],
            [-77.468581852633491, 8.471700162301858],
            [-77.475773022663873, 8.521109134726231],
            [-77.452235800291334, 8.556944956024552],
            [-77.438608837346692, 8.566664108059726],
            [-77.371946052463471, 8.646109127683047],
            [-77.366672894842878, 8.674999838264071],
            [-77.364166737999824, 8.685276936000093],
            [-77.216666737999901, 9.149999936000071],
            [-77.049999737999855, 9.449999936000083],
            [-77.249999737999929, 10.466665937000116],
            [-77.566666737999981, 11.449999938000218],
            [-77.716666738999891, 11.999999939000276],
            [-77.816666738999942, 12.316665939000245],
            [-77.999999738999861, 12.499999939000176],
            [-78.999999740000021, 12.499999939000176],
            [-78.999999740000021, 11.833332938000126],
            [-79.999999741000011, 11.833332938000126],
            [-79.999999741000011, 10.999999938000201],
            [-81.249999741999829, 10.999999938000201],
            [-81.433332741999948, 10.816666938000012],
            [-82.233332742999863, 10.818405938000183],
            [-82.233332742999863, 10.983370938000107],
            [-82.233332999999874, 11.767564238],
            [-82.009270324999989, 11.915595652000107],
            [-81.999999999999886, 12.16713994600012],
            [-81.999999999999886, 14.98556],
            [-79.93333, 14.98556],
            [-79.933333, 15.502778000000106],
            [-78.633333196714204, 14.49361115300006],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "COM", Country: "Comoros" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [45.767499836800113, -11.139721854799944],
            [45.780151543000073, -11.336829658999918],
            [45.561614542000115, -11.567147658999843],
            [45.488097542000077, -11.644830658999979],
            [45.203120542000164, -11.945901658999873],
            [45.199972542000211, -11.949719658999939],
            [45.089090542000037, -12.06960965899988],
            [45.023712542000084, -12.150028658999815],
            [44.89398854200013, -12.309147659999908],
            [44.821752542000041, -12.425645659999958],
            [44.809701542, -12.444341660000021],
            [44.804683542000106, -12.452102659999923],
            [44.772196542000216, -12.50825466],
            [44.733484042000129, -12.577077659999873],
            [44.695137542000026, -12.644408659999854],
            [44.664722542000135, -12.697620659999842],
            [44.613321542000136, -12.789445659999913],
            [44.604555542000213, -12.801336659999947],
            [44.521983541000139, -12.91441366],
            [44.514153541000184, -12.922324659999873],
            [44.416578541000177, -13.021748659999858],
            [44.325558541000106, -13.105857660000012],
            [44.197166541000144, -13.224883659999875],
            [44.038236541000202, -13.540871660999983],
            [43.751453541000075, -14.213546661],
            [43.732922541000079, -14.25684666099994],
            [43.680286541000129, -14.375924660999928],
            [43.023561540000145, -14.427294661000019],
            [42.85978854000021, -14.215954660999941],
            [42.655227540000141, -13.953803660999853],
            [42.549482540000128, -13.797942661000036],
            [42.476116540000049, -13.689692660999953],
            [42.329913539000046, -13.475761660999908],
            [42.30333453900019, -13.410255660999852],
            [42.262442539000205, -13.309457659999964],
            [42.216696539000139, -13.171642659999975],
            [42.206332539000158, -13.120969659999915],
            [42.178898539000073, -12.907336659999928],
            [42.174908539000086, -12.896446659999938],
            [42.171831539000124, -12.888054659999966],
            [42.146796539000121, -12.820609659999874],
            [42.08046553900013, -12.642063659999891],
            [42.068698539000053, -12.594597659999977],
            [41.947579539000031, -12.152266658999892],
            [41.937344539000122, -12.11602965899985],
            [41.932439539000114, -11.921947658999969],
            [41.933415539000208, -11.885007658999939],
            [41.936118539000205, -11.731296658999838],
            [41.938704539000042, -11.577974658999949],
            [41.939973539000135, -11.488356658999876],
            [41.955135539000111, -11.437491658999832],
            [41.962998539000097, -11.393441658999848],
            [42.004767539000085, -11.157845657999843],
            [42.022776539000148, -11.049394657999926],
            [42.030976539000136, -10.999644657999852],
            [42.048052539000111, -10.894643657999836],
            [42.061276539000033, -10.810234658],
            [42.087118539000102, -10.642781657999834],
            [42.137632539000066, -10.416668657999935],
            [42.161802539000149, -10.321017657999846],
            [42.198876539000167, -10.173820657999855],
            [42.221581920000148, -10.091388889000015],
            [42.538980843000019, -9.063995185999829],
            [42.575823177000217, -8.956265079999966],
            [42.636769273000112, -8.787291589999938],
            [42.738473978000258, -8.510414871499975],
            [43.195446917000169, -8.013027249999908],
            [44.849999838000059, -10.449999872333308],
            [44.992973945000159, -10.550081761999948],
            [45.51666666700001, -10.916666666999845],
            [45.767499836800113, -11.139721854799944],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CPT", Country: "Clipperton Island" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-108.632453600999895, 6.999682358000129],
            [-109.215037601999882, 6.949535358000063],
            [-109.216027601999912, 6.949621358000073],
            [-109.217018601999925, 6.949535358000063],
            [-109.79959810199999, 6.999693358000059],
            [-109.800558601999981, 6.99978135800005],
            [-110.365827602999957, 7.148724359],
            [-110.897041602999963, 7.392030359000046],
            [-110.89783060299996, 7.392573359000025],
            [-110.899028602999962, 7.393121359000077],
            [-111.38037560399998, 7.723548359000091],
            [-111.383329937333329, 7.72640369233342],
            [-111.384364603999984, 7.727123359000117],
            [-111.386493603999924, 7.729208359000054],
            [-111.387561603999913, 7.729998359000049],
            [-111.388532603999963, 7.730774859000093],
            [-111.389385603999941, 7.731420359000111],
            [-111.390384603999905, 7.732124359000096],
            [-111.392338603999931, 7.73347435900007],
            [-111.809437603999967, 8.141166360000085],
            [-112.149682603999906, 8.613986360000055],
            [-112.150091603999911, 8.61483436000006],
            [-112.150698603999956, 8.615676360000094],
            [-112.151405604, 8.617143360000057],
            [-112.152714603999954, 8.618959360000119],
            [-112.405710605, 9.142777360000039],
            [-112.405962604999971, 9.14367436],
            [-112.407745604999974, 9.147356360000103],
            [-112.565567604999956, 9.706487361000086],
            [-112.623021104999893, 10.284399028666783],
            [-112.623032404999933, 10.285253762000139],
            [-112.623031604999952, 10.285972862000037],
            [-112.577878604999924, 10.864823362000024],
            [-112.430876605, 11.427019363000028],
            [-112.185926604, 11.955535363],
            [-112.184355603999947, 11.957778363000045],
            [-112.183949603999935, 11.958654363000051],
            [-111.848000604, 12.437239364000078],
            [-111.846928937333303, 12.438310364000046],
            [-111.846008603999934, 12.439556364000026],
            [-111.845038603999967, 12.440520364000122],
            [-111.844017603999959, 12.44197236400008],
            [-111.426791603999888, 12.855913364000131],
            [-111.425937603999927, 12.856713364000072],
            [-110.940323603, 13.192904364000043],
            [-110.401079602999943, 13.440915364],
            [-110.400175602999923, 13.441173364000122],
            [-109.823794101999937, 13.593254365000092],
            [-109.822961601999978, 13.593440365000035],
            [-109.228098602, 13.644664365000025],
            [-109.227345601999957, 13.644643365000064],
            [-108.632228600999923, 13.593472365000011],
            [-108.056033100999954, 13.441444364000077],
            [-108.055103600999971, 13.441181364000073],
            [-108.053130100999923, 13.440284864000063],
            [-108.039747600999945, 13.434155364000048],
            [-108.036932601, 13.433409364000056],
            [-108.03495910099987, 13.432512864000131],
            [-107.495690600000017, 13.184485364000054],
            [-107.0100276, 12.848225864],
            [-107.007094599999903, 12.846170364000017],
            [-106.590042598999929, 12.432331364000063],
            [-106.254100598999955, 11.953746363000036],
            [-106.253266098999916, 11.95206336300005],
            [-106.008159598999939, 11.423321363000127],
            [-105.861157598999924, 10.861126362000022],
            [-105.81599459899995, 10.282275362000078],
            [-105.816016098999953, 10.28152536200011],
            [-105.816045598999906, 10.280876362000058],
            [-105.816015798999928, 10.280266162000103],
            [-105.81611959899999, 10.278978362000046],
            [-105.816048798999901, 10.27748736200013],
            [-105.816070098999887, 10.276527862000066],
            [-105.873506598999924, 9.698690361000033],
            [-106.031321598999952, 9.139559360000064],
            [-106.031775598999943, 9.13862236000007],
            [-106.03235359899999, 9.136570360000107],
            [-106.032836598999978, 9.13557236000004],
            [-106.033368598999942, 9.133681360000082],
            [-106.286353598999966, 8.609861360000082],
            [-106.287307098999989, 8.608497360000115],
            [-106.627838098999959, 8.135328860000072],
            [-106.628602598999976, 8.134359360000062],
            [-106.630050598999972, 8.132946360000034],
            [-106.63060459899998, 8.132175360000105],
            [-107.047691599999922, 7.724480359000012],
            [-107.529048599999967, 7.394068359000087],
            [-107.530886099999947, 7.39319185900014],
            [-107.531940099999929, 7.392648859000062],
            [-108.063258600999973, 7.149291359000102],
            [-108.064168600999949, 7.14903485900011],
            [-108.629701100999881, 7.000051858000049],
            [-108.630513600999961, 6.99986735800006],
            [-108.631476600999875, 6.999779358000069],
            [-108.632453600999895, 6.999682358000129],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CPV", Country: "Cape Verde" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-19.999999518999914, 18.736666369000076],
            [-19.933333518999888, 18.581666369000075],
            [-19.896666518999893, 18.480000369000138],
            [-19.858333518, 18.40000036900004],
            [-19.816666518, 18.313333369000063],
            [-19.783333517999893, 18.223333369000116],
            [-19.736666517999822, 18.130000369000058],
            [-19.701666517999911, 18.041666369],
            [-19.633333517999887, 17.888333369],
            [-19.633333517999887, 17.735000368],
            [-19.633333517999887, 17.531666368000131],
            [-19.631666517999889, 17.446666367999953],
            [-19.613333517999848, 17.100000368000153],
            [-19.53499951799995, 17.000000368000016],
            [-19.553333517999988, 16.633333367],
            [-19.541666517999829, 16.475000367],
            [-19.541666517999829, 16.283333367],
            [-19.558332162428485, 16.066666665571447],
            [-19.594444443999976, 15.916666667],
            [-19.641666666999981, 15.666666667000143],
            [-19.747222221999863, 15.416666667],
            [-19.858333332999877, 15.166666667000058],
            [-19.861482785999868, 15.161108808],
            [-20.0, 14.916666667000115],
            [-20.036852875999983, 14.883290477000074],
            [-20.073611111, 14.850000000000122],
            [-20.073611111, 13.65],
            [-20.073611111, 13.593333],
            [-20.134121018999934, 13.476592864000025],
            [-20.138224519, 13.468407363999987],
            [-20.140906518999856, 13.464814364000063],
            [-20.14302251899997, 13.460590364000069],
            [-20.146285518999946, 13.45622036400016],
            [-20.147947518999871, 13.452902364000167],
            [-20.497653518999869, 12.983085364000146],
            [-20.924326519, 12.578756364000029],
            [-21.415123519999867, 12.251827363000075],
            [-21.416110519999904, 12.251391363000153],
            [-21.422797519999961, 12.246921363000141],
            [-21.423786519999879, 12.246484363000135],
            [-21.430472519999938, 12.242015363000021],
            [-21.431463519999852, 12.241577363000019],
            [-21.438146519999918, 12.237109363000087],
            [-21.439140520000024, 12.236670363],
            [-21.44582152000001, 12.232203363000153],
            [-21.44681752, 12.23176336300007],
            [-21.453495519999905, 12.227297363000034],
            [-21.454494519999969, 12.226856363000138],
            [-21.461170519999882, 12.222392363],
            [-21.462170520000029, 12.221949363000121],
            [-21.46884551999986, 12.217486363000063],
            [-21.46984752, 12.217042363000189],
            [-21.476519519999954, 12.212580363000129],
            [-21.477524519999974, 12.212135363000073],
            [-21.484194519999846, 12.20767436300001],
            [-21.485201519999947, 12.207228363000056],
            [-21.491869519999909, 12.20276836300016],
            [-21.49287852, 12.202321363000124],
            [-21.49954352, 12.197862363000041],
            [-21.500556519999975, 12.197414363000107],
            [-21.507218519999896, 12.192956363000107],
            [-21.508233519999948, 12.192507363000175],
            [-21.514893519999873, 12.188050363000173],
            [-21.51591052, 12.187600363000058],
            [-21.522568519999965, 12.183144363000054],
            [-21.523587519999893, 12.182693363000041],
            [-21.530243519999942, 12.178238363000119],
            [-21.531264519999866, 12.177786363000109],
            [-21.537918519999835, 12.173332363],
            [-21.538942519999921, 12.172879363000092],
            [-21.545593519999898, 12.168427363000148],
            [-21.546619519999894, 12.16797236300016],
            [-21.553268519999989, 12.163521363000029],
            [-21.554296519999866, 12.163065363000044],
            [-21.560943519999881, 12.158615363000095],
            [-21.561974519999922, 12.158158363000027],
            [-21.568618519999859, 12.153709363000061],
            [-21.569651519999894, 12.153251363000095],
            [-21.576293520000036, 12.148803363000127],
            [-21.577328519999867, 12.148344363000078],
            [-21.583969520000011, 12.143897363],
            [-21.585006519999922, 12.143437363000146],
            [-21.591644519999903, 12.138991363000073],
            [-21.592684519999978, 12.138530363000029],
            [-21.599319519999881, 12.134085363000139],
            [-21.60036152, 12.133623363000012],
            [-21.606994519999859, 12.12917936300002],
            [-21.60803952, 12.12871636300008],
            [-21.614670519999947, 12.124274362999984],
            [-21.615716519999978, 12.123809363000063],
            [-21.622345519999925, 12.119368363000049],
            [-21.623394520000033, 12.118902363000132],
            [-21.6300215199999, 12.114462363000115],
            [-21.63107252, 12.113995363000015],
            [-21.637696519999878, 12.109556363],
            [-21.638750519999974, 12.109088363000083],
            [-21.645372519999853, 12.104650363000147],
            [-21.646427520000032, 12.104181363000066],
            [-21.65304752000003, 12.099744363000028],
            [-21.65410552, 12.099275363000132],
            [-21.66072352, 12.094838363000093],
            [-21.661783519999858, 12.094368363000015],
            [-21.668398519999897, 12.089932363000159],
            [-21.669461519999913, 12.089461363],
            [-21.676074519999872, 12.085027363000123],
            [-21.677139519999884, 12.084554363000066],
            [-21.683750519999847, 12.080121363],
            [-21.684817519999854, 12.079647363000134],
            [-21.691425519999939, 12.075215363000069],
            [-21.692495519999909, 12.074740363000117],
            [-21.699101519999914, 12.070309363000135],
            [-21.700173519999879, 12.069833363],
            [-21.706777519999889, 12.065403363000016],
            [-21.707851519999849, 12.064926362999984],
            [-21.714453519999864, 12.060497363000081],
            [-21.715529519999905, 12.060019363000052],
            [-21.722129519999839, 12.055591363000048],
            [-21.72320752, 12.05511236300012],
            [-21.729805520000014, 12.050686363000111],
            [-21.730886520000013, 12.050205363000103],
            [-21.737481519999989, 12.045780363000077],
            [-21.738564519999983, 12.045298362999986],
            [-21.745157519999964, 12.040874363000142],
            [-21.746242519999839, 12.040391362999969],
            [-21.752833519999854, 12.035968363000023],
            [-21.753920519999895, 12.035484363000037],
            [-21.760509519999914, 12.031062363000089],
            [-21.761599519999862, 12.030577363000106],
            [-21.768185519999889, 12.026156363000155],
            [-21.769277519999832, 12.025671363000171],
            [-21.775861519999864, 12.021250363000036],
            [-21.776956519999885, 12.020764363000055],
            [-21.783537520000039, 12.016345363],
            [-21.78463452, 12.015857363000038],
            [-21.791213519999928, 12.011439363000065],
            [-21.794118519999842, 12.010147363000101],
            [-21.799025519999873, 12.006851363000152],
            [-22.338415520999831, 11.765963363000068],
            [-22.34702902099994, 11.763736863000133],
            [-22.355810020999854, 11.761434363000149],
            [-22.929149520999857, 11.614445363000044],
            [-22.934593520999897, 11.614004363000063],
            [-22.938089520999881, 11.613103363000121],
            [-22.94676602099986, 11.612337863000107],
            [-22.955791020999811, 11.611482863000063],
            [-22.964817020999931, 11.610627863000104],
            [-22.97384302099988, 11.609773363000102],
            [-22.982868520999887, 11.608917863000016],
            [-22.991894520999921, 11.608062863000072],
            [-23.000920520999955, 11.607208363000069],
            [-23.009946520999904, 11.606353363000025],
            [-23.018972020999882, 11.605498363000066],
            [-23.027998020999831, 11.604643863000064],
            [-23.037024020999866, 11.603788363000064],
            [-23.0460500209999, 11.602933863000061],
            [-23.055075521, 11.602078863000017],
            [-23.064101520999827, 11.6012243630001],
            [-23.073127520999861, 11.600369363000056],
            [-23.082153520999896, 11.599514863000152],
            [-23.091179521999862, 11.598659863000108],
            [-23.100205521999897, 11.597805363000191],
            [-23.109231021999875, 11.596950863000103],
            [-23.118257521999851, 11.596096363000186],
            [-23.127283521999885, 11.595241863000098],
            [-23.13630952199992, 11.594387363000095],
            [-23.145335521999868, 11.593532363000051],
            [-23.154361521999903, 11.592677863000048],
            [-23.163387521999937, 11.591823363000046],
            [-23.172413521999886, 11.590969363000085],
            [-23.18143952199992, 11.590114363000126],
            [-23.190466021999811, 11.589259863000123],
            [-23.199492021999845, 11.588405863000162],
            [-23.208518021999879, 11.587551363000159],
            [-23.217544021999913, 11.586696863000157],
            [-23.226570021999862, 11.58584286300011],
            [-23.235596521999867, 11.584988363000193],
            [-23.244622521999901, 11.584134363000047],
            [-23.253648521999935, 11.583279863000143],
            [-23.262674521999884, 11.582425863000083],
            [-23.271700521999918, 11.58157136300008],
            [-23.280727021999894, 11.580717363000119],
            [-23.289753021999928, 11.579862863000116],
            [-23.298779021999877, 11.579008863000155],
            [-23.307805521999853, 11.578154363000152],
            [-23.316831521999887, 11.577300863000062],
            [-23.325857521999836, 11.576446863000101],
            [-23.334884021999812, 11.575592363000098],
            [-23.343910021999847, 11.574738363000137],
            [-23.352936021999881, 11.573884863000032],
            [-23.361963021999912, 11.573030363000214],
            [-23.370989021999861, 11.572176363000068],
            [-23.38001502199981, 11.571322863000148],
            [-23.3890415219999, 11.570468863000102],
            [-23.398067521999934, 11.56961486300014],
            [-23.407093521999968, 11.568761363000135],
            [-23.416120521999886, 11.567906863000047],
            [-23.42514652199992, 11.567053363000028],
            [-23.434173021999925, 11.566199363000067],
            [-23.443199021999959, 11.565345863000147],
            [-23.452226021999877, 11.564491863000185],
            [-23.461252021999911, 11.563638363000095],
            [-23.47027802199986, 11.562784363000134],
            [-23.47930452199995, 11.561930863000129],
            [-23.488331021999926, 11.561076863000068],
            [-23.497357521999817, 11.560223863000104],
            [-23.506383521999851, 11.559370363000099],
            [-23.515410521999883, 11.558516863000179],
            [-23.524436521999831, 11.557662863000132],
            [-23.533463021999836, 11.556809363000113],
            [-23.542489521999897, 11.555955863000193],
            [-23.551516021999788, 11.555102863000144],
            [-23.560542521999878, 11.554248863000097],
            [-23.569569021999854, 11.553395363000078],
            [-23.578595521999858, 11.552542363000114],
            [-23.587621521999779, 11.551688863000024],
            [-23.596648521999896, 11.550835363000104],
            [-23.6056750219999, 11.549982363000126],
            [-23.614701521999876, 11.549128863000035],
            [-23.623728021999881, 11.548275363000116],
            [-23.632754521999857, 11.547422363000152],
            [-23.641781021999918, 11.546568863000047],
            [-23.650807521999837, 11.545715863000083],
            [-23.659834521999869, 11.544862363000163],
            [-23.668860521999903, 11.5440093630001],
            [-23.677887521999907, 11.543156363000051],
            [-23.686914021999911, 11.542302863000131],
            [-23.695940521999887, 11.541450363000109],
            [-23.704967021999892, 11.540596863000104],
            [-23.713994021999895, 11.539743863000055],
            [-23.723020021999929, 11.538890863000077],
            [-23.732047021999875, 11.538037363000157],
            [-23.74107352199988, 11.537184863000149],
            [-23.750100521999883, 11.536331863000086],
            [-23.759127521999915, 11.535478863000122],
            [-23.768153521999864, 11.534626363000015],
            [-23.777180521999895, 11.533772863000095],
            [-24.367618022999892, 11.482940863000124],
            [-24.376300522999856, 11.482241363000156],
            [-24.379718522999866, 11.482529363000097],
            [-24.385361522999943, 11.482036363000162],
            [-24.388781523000034, 11.482324363],
            [-24.394422522999946, 11.481830362999986],
            [-24.397844522999833, 11.482118363000012],
            [-24.403482522999951, 11.481624363],
            [-24.406906522999918, 11.481912363000021],
            [-24.412543522999954, 11.481418363],
            [-24.415969523, 11.481706363000029],
            [-24.421604522999843, 11.481212363000012],
            [-24.425032522999885, 11.481501363000035],
            [-24.430664522999848, 11.481007363000018],
            [-24.434095522999883, 11.481295363000044],
            [-24.439725522999851, 11.480801363000026],
            [-24.443158522999965, 11.481089363000052],
            [-24.448786522999853, 11.480595363000035],
            [-24.452221522999849, 11.480884363000143],
            [-24.457846522999859, 11.480390363000126],
            [-24.461284522999932, 11.480678363000152],
            [-24.466907522999861, 11.480184363000134],
            [-24.470347522999816, 11.480473363000158],
            [-24.475968522999864, 11.47997936300014],
            [-24.479410522999899, 11.480267363000166],
            [-24.485028522999869, 11.479773363000149],
            [-24.488473522999982, 11.480062363000073],
            [-24.494089522999957, 11.479567363000157],
            [-24.497536522999866, 11.479856363000081],
            [-24.50315052299996, 11.479362363000064],
            [-24.506599522999863, 11.479651363000087],
            [-24.512210522999965, 11.479156363000072],
            [-24.515662522999946, 11.479445363000096],
            [-24.521271522999967, 11.478951363000078],
            [-24.524725522999915, 11.479240363000102],
            [-24.530332522999856, 11.478745363000087],
            [-24.533788522999828, 11.47903436300011],
            [-24.539392522999862, 11.478540363000093],
            [-24.542850523, 11.478829363000017],
            [-24.548453522999864, 11.478335363],
            [-24.551913522999882, 11.478624363000023],
            [-24.557514522999867, 11.478129363],
            [-24.56097652299988, 11.478418363000031],
            [-24.566574522999872, 11.477924363000014],
            [-24.570039522999963, 11.478213363000123],
            [-24.575635522999875, 11.477719363000105],
            [-24.579102522999847, 11.478008363000129],
            [-24.584696522999877, 11.477513363000114],
            [-24.588165522999844, 11.477802363000137],
            [-24.593757522999965, 11.47730836300012],
            [-24.597228523000013, 11.477597363000044],
            [-24.60281752299997, 11.477103363000026],
            [-24.606291522999896, 11.47739236300005],
            [-24.611878522999973, 11.476898363000032],
            [-24.615354522999894, 11.477187363000141],
            [-24.620939522999976, 11.476692363000041],
            [-24.624417522999977, 11.476982363000147],
            [-24.629999522999981, 11.476487363000132],
            [-24.633480522999861, 11.476777363000053],
            [-24.63906052299987, 11.476282363000138],
            [-24.642543522999944, 11.476571363000062],
            [-24.648121522999872, 11.476077363000044],
            [-24.651606522999828, 11.476366363000068],
            [-24.657182522999875, 11.47587236300005],
            [-24.66066952299991, 11.476161363000159],
            [-24.66624252299988, 11.475667363000142],
            [-24.669732522999908, 11.47595636299998],
            [-24.675303522999883, 11.475462363000148],
            [-24.678795522999877, 11.475751362999986],
            [-24.684364522999886, 11.475257362999969],
            [-24.687858522999875, 11.475546363000078],
            [-24.693425522999974, 11.47505236300006],
            [-24.700904522999906, 11.475671363000117],
            [-24.702413522999876, 11.475537363000043],
            [-25.292454523999851, 11.523629363000154],
            [-25.300391523999849, 11.525668363000108],
            [-25.301276523999888, 11.525739363000028],
            [-25.309215523999853, 11.527779362999979],
            [-25.310098524000011, 11.527850363000084],
            [-25.88320652399986, 11.674237363000017],
            [-26.422589524999864, 11.91467136300011],
            [-26.912371524999969, 12.2420913630001],
            [-26.913824524999825, 12.24347736300011],
            [-26.919766524999858, 12.247436363000119],
            [-26.921221524999908, 12.248824363000125],
            [-26.92716152499986, 12.252781363000054],
            [-26.928617524999908, 12.254170363000142],
            [-26.934555524999865, 12.258126362999988],
            [-26.939449524999873, 12.262793363000043],
            [-26.941022524999823, 12.263840362999986],
            [-27.366753524999893, 12.668559364000131],
            [-27.715524525999882, 13.138714364000137],
            [-27.976606525999898, 13.660396365000068],
            [-28.141680525999874, 14.218095365000138],
            [-28.205117525999896, 14.795132366000175],
            [-28.185563525999896, 15.071703366000023],
            [-28.291282025999863, 15.21137786600012],
            [-28.296689526, 15.218563366000026],
            [-28.30001752599992, 15.225097366000014],
            [-28.301096526000038, 15.226520365999974],
            [-28.567197526999905, 15.747112367000156],
            [-28.568547526999879, 15.751564367],
            [-28.570632526999816, 15.755629367000083],
            [-28.571983526999873, 15.760084367],
            [-28.574067526999897, 15.764147367000092],
            [-28.575419526999866, 15.768603367000011],
            [-28.577503526999891, 15.772664367000019],
            [-28.578854526999947, 15.777122367000018],
            [-28.580938526999887, 15.781182367000028],
            [-28.582290526999941, 15.785641367000025],
            [-28.584373526999883, 15.789700366999952],
            [-28.58572652700002, 15.794160367000032],
            [-28.587808526999879, 15.798217367000163],
            [-28.589162526999814, 15.802680367000121],
            [-28.591243526999875, 15.806735367000087],
            [-28.592598526999893, 15.811199367000128],
            [-28.594679526999869, 15.815252367000099],
            [-28.596034526999887, 15.819718367000135],
            [-28.598114526999865, 15.823770367000023],
            [-28.59947052699988, 15.828237367000142],
            [-28.601549526999861, 15.832287367000035],
            [-28.602906526999874, 15.836756367000149],
            [-28.604985526999855, 15.840805366999959],
            [-28.606342526999953, 15.845275367000156],
            [-28.608420526999936, 15.84932236700017],
            [-28.610015526999916, 15.854573367000043],
            [-28.611710526999929, 15.857873367000067],
            [-28.781479526999874, 16.414765367000072],
            [-28.848747526999972, 16.991403367999951],
            [-28.848597526999896, 16.993674368000015],
            [-28.849406526999871, 17.000567368000034],
            [-28.811250526999856, 17.579601368000127],
            [-28.80944752699989, 17.58670136800005],
            [-28.809353526999843, 17.588138368000116],
            [-28.665935526999988, 18.151992368999984],
            [-28.420659525999923, 18.683383369000055],
            [-28.417021525999814, 18.688552369],
            [-28.415797525999892, 18.691197369],
            [-28.412160525999866, 18.696365369000134],
            [-28.410936525999858, 18.699011369000019],
            [-28.407300525999915, 18.704177369000078],
            [-28.406074525999827, 18.706825369000157],
            [-28.402439525999881, 18.711989369000023],
            [-28.401212525999881, 18.714639369000096],
            [-28.060633525999947, 19.197093370000132],
            [-27.634656525999844, 19.615532370000025],
            [-27.135920024999848, 19.956807871000038],
            [-27.128597524999918, 19.961768371000048],
            [-27.120874524999863, 19.965299371],
            [-27.120190524999941, 19.965765371000103],
            [-27.112469524999881, 19.969295371000143],
            [-27.111783524999964, 19.969763371000042],
            [-27.104063524999987, 19.973291371],
            [-27.103376524999987, 19.973760371000097],
            [-27.095658524999891, 19.977287370999974],
            [-27.094968524999928, 19.977758371000036],
            [-27.087253524999824, 19.981283371000117],
            [-27.086561524999951, 19.981755371000091],
            [-27.078848524999927, 19.985279370999976],
            [-27.078154524999974, 19.98575337100003],
            [-27.07044352500003, 19.989275371000033],
            [-27.069746524999914, 19.989750371000085],
            [-27.062038524999849, 19.993271371000091],
            [-27.061339524999937, 19.993747371000026],
            [-27.05363252499987, 19.997267371000149],
            [-27.05293252499996, 19.997745371000079],
            [-27.045227524999888, 20.001263371],
            [-27.0445245249999, 20.00174237100002],
            [-27.036822525, 20.005259371000065],
            [-27.036117524999923, 20.005740371000073],
            [-27.028416525000011, 20.009255371000123],
            [-27.027709524999949, 20.009737371000014],
            [-27.020011524999916, 20.013251370999981],
            [-27.019302524999887, 20.013734370999984],
            [-27.011605524999851, 20.017247371000039],
            [-27.010894524999912, 20.017732371],
            [-27.003200524999869, 20.021243371000097],
            [-27.002486524999853, 20.021729370999978],
            [-26.994794524999975, 20.02523937100004],
            [-26.994079524999876, 20.025726371000118],
            [-26.986389524999879, 20.029235371000013],
            [-26.985671524999901, 20.029724370999972],
            [-26.9779835249999, 20.033231371000156],
            [-26.977263524999842, 20.033721371000112],
            [-26.969578524999918, 20.037227371000014],
            [-26.968856524999865, 20.037718370999968],
            [-26.961172524999853, 20.041223370999987],
            [-26.96044852499989, 20.041716371000106],
            [-26.952766524999959, 20.04521937100013],
            [-26.952040524999916, 20.045713370999962],
            [-26.944360524999979, 20.049215370999988],
            [-26.943632524999856, 20.049710371000103],
            [-26.935955524999883, 20.053211370999961],
            [-26.935224524999882, 20.053708370999956],
            [-26.927549524999904, 20.057207371000104],
            [-26.926816524999822, 20.057705371000097],
            [-26.919143524999924, 20.061202370999965],
            [-26.918408524999961, 20.061702370999953],
            [-26.91073752499986, 20.065198371000022],
            [-26.910000524999987, 20.065699371000093],
            [-26.902331524999965, 20.06919437100008],
            [-26.901592524999927, 20.069697371000032],
            [-26.893925524999986, 20.073190371000138],
            [-26.893184524999953, 20.073694371000087],
            [-26.336647524999933, 20.326182371],
            [-25.740504523999903, 20.482380371000104],
            [-25.123816522999874, 20.537242371],
            [-25.119035522999894, 20.536876371000105],
            [-25.115222522999886, 20.537209371000031],
            [-24.497844522999969, 20.488924371000124],
            [-23.899668521999956, 20.339064371000049],
            [-23.89271652199983, 20.336015371000016],
            [-23.891007521999825, 20.335584371000081],
            [-23.884057521999978, 20.332535371000134],
            [-23.882346521999892, 20.332103371],
            [-23.875399521999924, 20.329056371000135],
            [-23.873684521999962, 20.328623371],
            [-23.866740521999873, 20.325577371000051],
            [-23.865023521999831, 20.32514337100001],
            [-23.858082521999904, 20.32209737099997],
            [-23.856362521999984, 20.321663371000128],
            [-23.849423521999967, 20.318618371000056],
            [-23.847701521999852, 20.318183371000046],
            [-23.840765521999913, 20.315138370999975],
            [-23.83904052199992, 20.31470337100005],
            [-23.4532415219999, 20.144821371],
            [-22.907087520999823, 20.187523371000097],
            [-22.291714520999875, 20.132698371000046],
            [-21.696814519999862, 19.97656137100013],
            [-21.1413915199999, 19.724151370000172],
            [-20.642950518999953, 19.383567369999966],
            [-20.641892518999896, 19.382533370000047],
            [-20.635453518999952, 19.37811737],
            [-20.634397518999975, 19.37708437000002],
            [-20.627956518999952, 19.372667370000144],
            [-20.62690251899997, 19.371636370000104],
            [-20.620459518999951, 19.367218370000032],
            [-20.61940751899985, 19.366188369999989],
            [-20.61296251899995, 19.361768370000036],
            [-20.187012518999865, 18.94380637],
            [-19.999999518999914, 18.736666369000076],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CRI", Country: "Costa Rica" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.433332741999948, 10.816666938000012],
            [-82.566666742999871, 9.571110936000068],
            [-82.563572978484018, 9.56287258070833],
            [-82.561399917085453, 9.535553947439894],
            [-82.610008920668633, 9.490418069266312],
            [-82.636263891334522, 9.488054037288137],
            [-82.682499978122109, 9.508744932972576],
            [-82.718899901505637, 9.546664160129922],
            [-82.748554907385198, 9.582982108500374],
            [-82.814182024309133, 9.606664170160343],
            [-82.837717905577307, 9.609718032771838],
            [-82.864455003315157, 9.585554011677914],
            [-82.934717815622207, 9.471664231411211],
            [-82.931117955836385, 9.199444993583668],
            [-82.930463999748696, 9.063127084375381],
            [-82.882235869847023, 9.067218123688647],
            [-82.743617798763097, 8.981036065550271],
            [-82.711881901595291, 8.925073115376577],
            [-82.719863988011554, 8.910827065061696],
            [-82.756326943307243, 8.881318071935951],
            [-82.813335787362121, 8.862500028702229],
            [-82.858481891457586, 8.842782104341126],
            [-82.879454922003731, 8.825836075367818],
            [-82.914463952370596, 8.762782202726086],
            [-82.915699947816222, 8.739654017229441],
            [-82.896663975099329, 8.710000017178302],
            [-82.87958199165621, 8.689445075963761],
            [-82.836745940082267, 8.636664231520626],
            [-82.825563978300778, 8.570000105533012],
            [-82.829926926553867, 8.4746539449891],
            [-82.876463923666279, 8.427500039801302],
            [-82.92229097357756, 8.416391168215753],
            [-82.952517960531452, 8.399444971604368],
            [-83.017790858226135, 8.340273093610136],
            [-83.030290958104615, 8.310554049990145],
            [-83.005008958762033, 8.29110904039716],
            [-82.972090877967247, 8.276391088932215],
            [-82.950008921627955, 8.270273137787413],
            [-82.933346033344662, 8.25750012314073],
            [-82.885981910024725, 8.101809121068897],
            [-82.886808868594244, 8.075273189007689],
            [-82.892781980451815, 8.035973127117387],
            [-82.898845786531382, 8.025672625773069],
            [-84.316666744999878, 4.999999932000151],
            [-84.316666744999878, 3.533332931000018],
            [-84.766666744999895, 3.049999930000254],
            [-85.36750624599992, 2.640560430000065],
            [-85.369272745999893, 2.639386930000143],
            [-85.375073746, 2.636699930000034],
            [-85.379223746, 2.633809930000012],
            [-85.384996745999899, 2.631135930000013],
            [-85.409141745999989, 2.614318930000081],
            [-85.936076745999969, 2.36992193],
            [-86.497300746999827, 2.220266930000037],
            [-86.953576747, 2.163840929000031],
            [-87.146598, 2.150738],
            [-89.42776, 3.421827000000107],
            [-90.30904, 4.572394000000116],
            [-90.390073749999942, 4.934717932000154],
            [-90.444054749999964, 5.512622932999989],
            [-90.444034749999901, 5.513484933000072],
            [-90.396578749999946, 6.092245933000129],
            [-90.248754749999932, 6.653744934000017],
            [-90.246794249999908, 6.657988434000117],
            [-90.246397749999971, 6.658848934],
            [-90.245798249999922, 6.661116934000063],
            [-90.244815749999987, 6.664842933999978],
            [-90.00079675, 7.192276934000134],
            [-89.999896249999921, 7.193618434000186],
            [-89.666720749999968, 7.671016935000097],
            [-89.659228749999954, 7.678517935000073],
            [-89.65873575, 7.679222935000141],
            [-89.246434748999945, 8.091622935000146],
            [-88.767387748999965, 8.426459935],
            [-88.75981974899986, 8.429982935000169],
            [-88.732265749, 8.449216934999981],
            [-88.717753749, 8.455960935000121],
            [-88.887485748999978, 8.781458936000064],
            [-88.902095749000011, 8.80257693600015],
            [-88.903933749000032, 8.810557436000096],
            [-88.904376748999908, 8.81197093600008],
            [-88.906214748999929, 8.815096436000047],
            [-88.907198749, 8.816628936000015],
            [-88.911193748999949, 8.834981436000078],
            [-88.911596748999983, 8.836421935999979],
            [-88.912325749000019, 8.837474935999978],
            [-88.912635748999975, 8.838932936000091],
            [-88.913369248999913, 8.840053936000018],
            [-88.914366748999953, 8.841556936000131],
            [-88.914881749000017, 8.843979936000053],
            [-88.915397749, 8.844845936000127],
            [-88.916121748999927, 8.848253936000106],
            [-88.917739748999963, 8.851299935999975],
            [-88.92147924899993, 8.857279436000098],
            [-88.922477748999853, 8.858781936000071],
            [-88.922987748999958, 8.861182936000134],
            [-88.939150748999964, 8.891613936000113],
            [-88.947746748999862, 8.9054222693334],
            [-88.949807748999945, 8.908737936000136],
            [-88.964640748999955, 8.955478936000105],
            [-88.965150248999919, 8.956275936000139],
            [-88.966148749000013, 8.957778936000068],
            [-88.967728749, 8.965204936000063],
            [-89.033257749, 9.171443936000045],
            [-89.037897748999967, 9.183851936000039],
            [-89.047329749000028, 9.198431936000162],
            [-89.070041749000012, 9.261815936000062],
            [-89.072793749, 9.277127936000085],
            [-89.084992749, 9.309715936],
            [-89.086299748999977, 9.313834936000021],
            [-89.094188748999898, 9.362870936000078],
            [-89.151078749000021, 9.541304936000117],
            [-89.152130749, 9.546292936],
            [-89.154984749, 9.572153936000035],
            [-89.189583748999951, 9.67959593600014],
            [-89.20488156600004, 9.75010738600011],
            [-88.400998114999879, 10.100185272000047],
            [-88.011206886999958, 10.269932873000087],
            [-87.442748449999954, 10.606426309000128],
            [-87.419530007999981, 10.619673580000111],
            [-87.371863561, 10.646869647000031],
            [-86.950068147999957, 10.854303851000054],
            [-86.891321003, 10.883187360500116],
            [-86.865998572999956, 10.895366687000063],
            [-86.860255145999901, 10.898129103000102],
            [-86.841709442999843, 10.907027471000049],
            [-86.750374101999967, 10.950850853000119],
            [-86.747724674999859, 10.9520883550001],
            [-86.685140730999905, 10.971852079000044],
            [-86.475894765999897, 11.04354370300014],
            [-86.42351900299991, 11.061395900000107],
            [-86.369167077999919, 11.073612709],
            [-86.293737118999871, 11.086473838000188],
            [-86.26363269299992, 11.089855663000137],
            [-86.216109473999978, 11.095194254999981],
            [-86.144370847, 11.103097158],
            [-86.128931549000015, 11.104487809000133],
            [-86.123671871999932, 11.105022323000128],
            [-86.117323655999854, 11.105667463000032],
            [-86.096085412999855, 11.101745568000169],
            [-86.075196493, 11.097888183000038],
            [-86.006533189499947, 11.097008485500126],
            [-85.995607327999977, 11.096938632000075],
            [-85.976118324000026, 11.096814031000065],
            [-85.960370623999921, 11.094294604000069],
            [-85.88651412400003, 11.060938093000061],
            [-85.8760067179999, 11.055153494000081],
            [-85.865508694000027, 11.050367656000049],
            [-85.848216751999956, 11.042996034000026],
            [-85.836107433999985, 11.047337511000066],
            [-85.816217493999886, 11.052821530000131],
            [-85.788536934999854, 11.060422369000023],
            [-85.787514354999985, 11.060476934],
            [-85.785793330999866, 11.060568770999964],
            [-85.770611264999843, 11.065623702999972],
            [-85.765261371999856, 11.065752611000065],
            [-85.763230281999938, 11.065801551000121],
            [-85.749656700999964, 11.066472303000111],
            [-85.744627577333205, 11.067575584666756],
            [-85.740075502, 11.068590657000144],
            [-85.737952623999945, 11.068942090000178],
            [-85.734296855499935, 11.069562229000056],
            [-85.729544066999836, 11.069979099000093],
            [-85.728668939999892, 11.069954785500073],
            [-85.725273386999987, 11.06938194933349],
            [-85.723902836, 11.068765203000112],
            [-85.722595832499991, 11.068108004500047],
            [-85.720474875999969, 11.066812407],
            [-85.711664308999985, 11.0661501700001],
            [-85.708052002999978, 11.066150197000013],
            [-85.705527930999949, 11.065868394000148],
            [-85.704464396999867, 11.06574965500009],
            [-85.70185677399985, 11.065749641],
            [-85.697753361499934, 11.064273528500038],
            [-85.696656808999933, 11.067400664],
            [-85.69613268899991, 11.068895586000153],
            [-85.694544035999911, 11.071304300000051],
            [-85.692930279999985, 11.073751075000175],
            [-85.692381905160119, 11.076064103998462],
            [-85.613546082421664, 11.212845029537633],
            [-85.56418186936034, 11.209718076730098],
            [-85.526945934951613, 11.16610904604336],
            [-85.420835901957787, 11.126391062459362],
            [-85.359590844026428, 11.122218048132737],
            [-85.266399838433102, 11.084445001367001],
            [-85.221572917211347, 11.065253963441094],
            [-85.173754997652168, 11.044864145719572],
            [-85.164717797261204, 11.042427023545457],
            [-85.161390852243585, 11.039445077663416],
            [-85.133617918908243, 11.027218060191046],
            [-85.087781984179543, 11.01000012227793],
            [-84.902790866009553, 10.940836009842329],
            [-84.674454914741801, 11.078053967816004],
            [-84.343063966558105, 10.953891159350704],
            [-84.273345977958996, 10.898609154992926],
            [-84.225045931327855, 10.811909095236771],
            [-84.193272986148003, 10.783644982373985],
            [-84.158054911115386, 10.784109004535125],
            [-84.115981948008709, 10.763745003075442],
            [-84.080290965997534, 10.766945046078646],
            [-84.040555045140906, 10.77805391766438],
            [-83.997363936147394, 10.766527124385306],
            [-83.958617918058337, 10.734445054615449],
            [-83.920626941809616, 10.709691115174365],
            [-83.870008963667829, 10.722500172004871],
            [-83.827517911229648, 10.746109143468985],
            [-83.772381919625474, 10.770254053823521],
            [-83.760846073891003, 10.77136415308324],
            [-83.75170896121395, 10.770482041590498],
            [-83.684172949655476, 10.787082065599748],
            [-83.66014605649795, 10.8019450239901],
            [-83.654726820803802, 10.824445069660982],
            [-83.662226780147932, 10.888609041562432],
            [-83.645799865462806, 10.924844989293092],
            [-83.639523897999936, 10.929710158000105],
            [-83.63751531600002, 10.93126720000015],
            [-83.129160153999976, 11.207619324],
            [-82.697938207999982, 11.458629113000015],
            [-82.233332999999874, 11.767564238],
            [-82.233332742999863, 10.983370938000107],
            [-82.233332742999863, 10.818405938000183],
            [-81.433332741999948, 10.816666938000012],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CUB", Country: "Cuba" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.215277779999838, 23.925000000000111],
            [-81.210877589999825, 23.920845137000143],
            [-81.207410301999914, 23.918161090000112],
            [-81.091518973999882, 23.828284360000126],
            [-81.034568759999956, 23.784259275000167],
            [-80.910520327999933, 23.71572947300011],
            [-80.872515654999916, 23.694749849000104],
            [-80.811827138000012, 23.636052885000069],
            [-80.729287139, 23.556912398000222],
            [-80.701485339999977, 23.530056904000233],
            [-80.698607201999948, 23.527626798000071],
            [-80.69408918299996, 23.525809905000116],
            [-80.691029809999861, 23.524544420000112],
            [-80.620500466999943, 23.498445686000039],
            [-80.55962256, 23.475992576000138],
            [-80.519897187999987, 23.462813512500091],
            [-80.48649672, 23.458180762000055],
            [-80.483994267999975, 23.457850464000089],
            [-80.481507074999968, 23.4575306280001],
            [-80.312926148999907, 23.436624798000153],
            [-80.294234894999903, 23.434203415000084],
            [-80.286243860999861, 23.433168206000133],
            [-80.277511707, 23.434854106000088],
            [-80.263843109999868, 23.437512826000088],
            [-80.25949078199983, 23.438359409999961],
            [-80.227996858999916, 23.446801325000067],
            [-80.192481936000036, 23.45642156500007],
            [-80.05883358299991, 23.491541427000143],
            [-80.049057675999933, 23.494117771000148],
            [-80.011900546999868, 23.463645116000109],
            [-79.984533665999834, 23.440538806],
            [-79.950576783, 23.419296018000054],
            [-79.940140211999875, 23.409810949500198],
            [-79.930780872999861, 23.402324700000051],
            [-79.922099316999919, 23.395364872000087],
            [-79.908617888999913, 23.384616675000117],
            [-79.905469401999824, 23.382121188000141],
            [-79.901972064, 23.379253703000117],
            [-79.845637416999921, 23.339288858000188],
            [-79.826223102999847, 23.322100185000053],
            [-79.818662070999977, 23.316043544000124],
            [-79.808118754999981, 23.307556141000148],
            [-79.800037769999875, 23.300867640000078],
            [-79.751427120999836, 23.279560538999974],
            [-79.74186445, 23.275231891000104],
            [-79.708334830999974, 23.25989130900011],
            [-79.702629381999969, 23.257290842000074],
            [-79.624661758, 23.221036679000221],
            [-79.573865677999891, 23.196389769],
            [-79.553409949999889, 23.18872471900012],
            [-79.516880650999894, 23.174249860000188],
            [-79.416710145999986, 23.148874860000134],
            [-79.331369720999987, 23.139539550000066],
            [-79.189098455999982, 23.124479653000222],
            [-79.157181791, 23.137970394000106],
            [-79.116144668999908, 23.160862528000067],
            [-79.047505254999919, 23.199152314000045],
            [-78.965499456999879, 23.245479029000208],
            [-78.895127916999854, 23.285543401000112],
            [-78.884585373999926, 23.291545549000048],
            [-78.844909794000046, 23.316639866999978],
            [-78.822202033999872, 23.331266017000118],
            [-78.810888610999854, 23.338553032999968],
            [-78.763567013999875, 23.369023856000112],
            [-78.656844037000042, 23.43774379],
            [-78.579976854999899, 23.377017533],
            [-78.562337449999973, 23.36340584400007],
            [-78.555413541999854, 23.300796304000116],
            [-78.430879209999858, 22.868283546000171],
            [-78.415501759999927, 22.848231829000113],
            [-78.406010858999906, 22.836375642000107],
            [-78.354381446999923, 22.773144444000067],
            [-78.342660086, 22.75939367900007],
            [-78.3384855219999, 22.754798532000095],
            [-78.30102022499986, 22.713194163000153],
            [-78.283548287999906, 22.69400163500022],
            [-78.25921719199988, 22.670553841000086],
            [-78.250713799999914, 22.662746820000123],
            [-78.215393537999887, 22.634148333000056],
            [-78.194402757000034, 22.627546033000087],
            [-78.174152654999887, 22.624117030000079],
            [-78.16499832, 22.622534744999982],
            [-78.152029159999927, 22.620537107000061],
            [-78.139186728999874, 22.618558990000096],
            [-78.135161864999901, 22.618194124000112],
            [-78.132839982999911, 22.617983639000087],
            [-78.087565576999964, 22.614142197000135],
            [-78.00018229399987, 22.606727903000049],
            [-77.948627946999892, 22.602018998000077],
            [-77.932550791999887, 22.600550532000071],
            [-77.90065033399992, 22.602178719000236],
            [-77.683286239999859, 22.684198206000062],
            [-77.660653593999967, 22.692738338000041],
            [-77.656593360999921, 22.694678127000046],
            [-77.606660793999936, 22.718533568999973],
            [-77.309785272999989, 22.86626568200019],
            [-77.303352210999918, 22.86417958400007],
            [-76.973406108999882, 22.802866452000089],
            [-76.905188014999879, 22.790061699000134],
            [-76.887946561999911, 22.741852350000173],
            [-76.880030973, 22.719770008000054],
            [-76.879066302999945, 22.717076307000198],
            [-76.878110710000016, 22.714382543999974],
            [-76.877138002999914, 22.711638339000032],
            [-76.876174727999881, 22.708894072000064],
            [-76.875824632999866, 22.707895894000089],
            [-76.875475947999888, 22.706898660000078],
            [-76.838804324999842, 22.601853087000123],
            [-76.824412722999853, 22.56074106100003],
            [-76.817845991999832, 22.540482484000094],
            [-76.791331212999864, 22.437957180000112],
            [-76.654456701999891, 22.278772424999985],
            [-76.618275434999958, 22.226196540000032],
            [-76.559963877, 22.140916746],
            [-76.552988172999875, 22.132077384000141],
            [-76.544718492999976, 22.120697871000161],
            [-76.542977859999837, 22.118282995],
            [-76.518609554000022, 22.05440866900004],
            [-76.504306723999832, 22.017714391000098],
            [-76.503418996999926, 22.015458990999988],
            [-76.50275620799988, 22.014440656000019],
            [-76.502108074, 22.013444151000087],
            [-76.501429007999945, 22.012397240000055],
            [-76.490630937999924, 21.995733358000095],
            [-76.355962803999887, 21.841309673999987],
            [-76.350592695999921, 21.835178589000137],
            [-76.271233762999856, 21.778512713000097],
            [-76.210274712999876, 21.735595964000069],
            [-76.152326002999928, 21.696850573000049],
            [-76.117961707999939, 21.676158176000101],
            [-76.037242200999913, 21.627619554000077],
            [-75.943095657999976, 21.583914514000114],
            [-75.930930825000019, 21.579304039000121],
            [-75.918171739999934, 21.578847699000207],
            [-75.884222974999943, 21.577761646000127],
            [-75.813558920999867, 21.573928861],
            [-75.765034637999975, 21.571326706000079],
            [-75.758444879, 21.570973325000054],
            [-75.736260397999843, 21.569756306000087],
            [-75.697852245000036, 21.570273245000052],
            [-75.672557186999825, 21.571250625000104],
            [-75.599078280999919, 21.57408978900007],
            [-75.445285907999931, 21.569617716000081],
            [-75.430888538, 21.562166977000075],
            [-75.428987636999921, 21.561219726000147],
            [-75.395572415999851, 21.544688905000086],
            [-75.367206135999822, 21.53115754800011],
            [-75.229295897999862, 21.475768353000021],
            [-75.184490468, 21.458304785000152],
            [-75.139314918999872, 21.440558074000208],
            [-75.108735224000043, 21.428619614000056],
            [-75.08091596, 21.417791432000129],
            [-74.974084331499967, 21.379084295500135],
            [-74.938813317999859, 21.383195554000068],
            [-74.892666438999953, 21.388574670000068],
            [-74.828356272999883, 21.395951339000064],
            [-74.822933989000035, 21.396573300000099],
            [-74.820345528999923, 21.396867691000224],
            [-74.805830305999905, 21.398518538000118],
            [-74.775777324000046, 21.403664914000146],
            [-74.605789021999897, 21.362567160000182],
            [-74.553790661999869, 21.35002878000013],
            [-74.547765086999846, 21.344447602000102],
            [-74.507563146999928, 21.307933125000133],
            [-74.475619330999933, 21.284714993000179],
            [-74.432427719999907, 21.253390922000207],
            [-74.405232028999848, 21.233775262000066],
            [-74.39025214000003, 21.200174739999952],
            [-74.355639044999975, 21.121904445000084],
            [-74.308067340999912, 21.015590843000084],
            [-74.288006004999971, 20.970517652999987],
            [-74.258933381999839, 20.905632111000031],
            [-74.210907527999979, 20.835527213999953],
            [-74.2083572089999, 20.831780519000191],
            [-74.199784101999853, 20.816850804000083],
            [-74.151769193999911, 20.780014935000239],
            [-74.115228301999849, 20.751791830000158],
            [-74.080407536999928, 20.724604302999964],
            [-74.069051555999948, 20.715693467000037],
            [-74.053303992999929, 20.703279008000223],
            [-74.045929974999893, 20.697493264000045],
            [-74.04194590899985, 20.694328538000221],
            [-74.015447780000045, 20.673483875000088],
            [-73.996648537999874, 20.659481929000108],
            [-73.95541115799989, 20.628191414000256],
            [-73.919671326999975, 20.601450667000066],
            [-73.894874860999892, 20.582551687000105],
            [-73.884779024999887, 20.574676583000084],
            [-73.837649530000022, 20.54214277200002],
            [-73.790164866999902, 20.510474497000175],
            [-73.77610756699994, 20.501203129000075],
            [-73.739509265000038, 20.476878031000112],
            [-73.72220533599986, 20.465572037000172],
            [-73.64845002699991, 20.417564909000149],
            [-73.596224163999977, 20.383328738000117],
            [-73.572218658, 20.368263439000074],
            [-73.622757568999873, 20.282808371000087],
            [-73.634654568999963, 20.26287637099999],
            [-73.666825569000025, 20.207686371000037],
            [-73.712654568999938, 20.127908371000117],
            [-73.731074568999901, 20.095328371000235],
            [-73.732179568999925, 20.093478371000103],
            [-73.734061568999863, 20.090295371000181],
            [-73.774704568999852, 20.02079637100023],
            [-73.778592568999841, 20.014011371000166],
            [-73.789844568999854, 19.994244371000136],
            [-73.790429568999855, 19.993191371000037],
            [-73.796226568999884, 19.982691371000087],
            [-73.819002568999935, 19.941179371000132],
            [-73.833182569, 19.920294370000036],
            [-73.836401568999889, 19.915613370000173],
            [-73.885108568999897, 19.844165370000056],
            [-73.890744568999935, 19.832297370000063],
            [-73.897214568999857, 19.818246370000125],
            [-73.938190568999971, 19.733911370000087],
            [-73.941979568999955, 19.726351370000017],
            [-73.943883568999922, 19.722596370000204],
            [-73.947496568999895, 19.715537370000206],
            [-73.950559568999836, 19.709728370000065],
            [-73.954416569000045, 19.702219369999952],
            [-73.975684569, 19.662533370000105],
            [-73.998975568999953, 19.618800370000045],
            [-74.009906568999867, 19.59859837000009],
            [-74.017168568999864, 19.584937370000077],
            [-74.027304568999909, 19.56577637000008],
            [-74.087443568999902, 19.450907370000081],
            [-74.088592568999843, 19.44869737000009],
            [-74.126883568999915, 19.374608370000161],
            [-74.178878568999949, 19.372037370000058],
            [-74.206994568999846, 19.370514370000066],
            [-74.286978568999956, 19.36708537000014],
            [-74.303930569000045, 19.366267370000131],
            [-74.322676568999981, 19.365241369999964],
            [-74.325135568999855, 19.365116370000109],
            [-74.379711568999824, 19.363702370000183],
            [-74.391087568999978, 19.362205369999955],
            [-74.442102568999985, 19.351914370000046],
            [-74.450086568999865, 19.350539369999964],
            [-74.505022568999948, 19.34088937],
            [-74.520061568999949, 19.338487370000038],
            [-74.555099568999907, 19.334276370000126],
            [-74.680320569999964, 19.28250737000026],
            [-74.737630569999908, 19.258584370000065],
            [-74.788478569999882, 19.237132370000054],
            [-74.826978569999937, 19.220080370000133],
            [-74.900586569999859, 19.188876370000244],
            [-74.905221569999952, 19.186984370000104],
            [-74.928377569999896, 19.177342370000176],
            [-75.021379570000022, 19.135197370000057],
            [-75.029154569999832, 19.131503370000146],
            [-75.158019569999851, 19.0655093700001],
            [-75.168613569999962, 19.060063370000066],
            [-75.222424570000044, 19.027105370000086],
            [-75.27997256999987, 18.991015370000056],
            [-75.292083569999903, 18.983016370000072],
            [-75.411085569999898, 18.904558370000146],
            [-75.460755569999947, 18.872098370000046],
            [-75.506388888999822, 18.832222222],
            [-75.528055556000027, 18.845833333000073],
            [-75.543333332999964, 18.861111111000199],
            [-75.569166666999848, 18.852777778],
            [-75.698611110999849, 18.918333333000263],
            [-75.721944444, 18.927777778000191],
            [-75.839999999999861, 18.974444443999957],
            [-75.850555555999875, 18.978611111],
            [-75.899166666999861, 18.997777778000099],
            [-75.934722221999834, 19.011111111000204],
            [-75.950833333, 19.016666666999967],
            [-75.964722221999949, 19.021944444000113],
            [-75.975277777999878, 19.024166667000046],
            [-75.981388888999874, 19.025833333000108],
            [-75.996111110999863, 19.028888888999958],
            [-76.020833333000013, 19.034166667],
            [-76.058611110999891, 19.042777778000087],
            [-76.095833332999916, 19.052222222000111],
            [-76.125277777999884, 19.060277778000156],
            [-76.133055555999931, 19.062222222000102],
            [-76.156944443999862, 19.068888889000036],
            [-76.17, 19.072222222000065],
            [-76.190277777999825, 19.077777778000197],
            [-76.208333333000013, 19.080277778000109],
            [-76.247499999999889, 19.086111111000022],
            [-76.28638888899988, 19.092500000000101],
            [-76.324722222, 19.099722222000096],
            [-76.336111110999866, 19.102222222000179],
            [-76.363055555999836, 19.107222222000175],
            [-76.374444443999892, 19.109444444],
            [-76.401388888999861, 19.114722222000054],
            [-76.412777777999935, 19.116944444000254],
            [-76.439999999999884, 19.12194444400005],
            [-76.446388888999849, 19.123333333000133],
            [-76.471944443999945, 19.128333333000128],
            [-76.47833333299991, 19.129444444000129],
            [-76.484444443999905, 19.130555556000232],
            [-76.514722221999904, 19.136111111000091],
            [-76.516666666999953, 19.136666667000227],
            [-76.588055555999944, 19.15],
            [-76.626111111, 19.156388889000098],
            [-76.64, 19.158333333000044],
            [-76.643611111000041, 19.158611111000127],
            [-76.683055556, 19.158611111000127],
            [-76.722222221999857, 19.158611111000127],
            [-76.764444443999878, 19.158611111000127],
            [-76.798055555999895, 19.15694444400016],
            [-76.838611110999949, 19.156111111000044],
            [-76.868055555999916, 19.156111111000044],
            [-76.877222221999858, 19.155833332999961],
            [-76.92527777799998, 19.156666667000181],
            [-76.932499999999891, 19.15694444400016],
            [-76.945833332999882, 19.157500000000127],
            [-76.99194444399987, 19.159722221999957],
            [-77.03, 19.1625],
            [-77.046944444000019, 19.16416666700006],
            [-77.067777777999822, 19.165833332999952],
            [-77.078333332999932, 19.166666667000143],
            [-77.093611110999973, 19.168055556000056],
            [-77.106111110999848, 19.167777778000172],
            [-77.11916666699986, 19.167777778000172],
            [-77.144722222000041, 19.166944443999952],
            [-77.157777777999968, 19.166944443999952],
            [-77.183333332999865, 19.166388889000089],
            [-77.193888888999879, 19.166388889000089],
            [-77.241666666999862, 19.165277778000259],
            [-77.252777777999825, 19.165],
            [-77.267499999999899, 19.164722222000123],
            [-77.27638888899989, 19.163888889],
            [-77.305833332999839, 19.163888889],
            [-77.344166666999968, 19.16416666700006],
            [-77.382222221999825, 19.165277778000259],
            [-77.408333332999973, 19.166666667000143],
            [-77.418055555999871, 19.166944443999952],
            [-77.419444443999851, 19.166944443999952],
            [-77.447777777999903, 19.16833333300012],
            [-77.460555555999861, 19.168611111000089],
            [-77.47527777799985, 19.169444444000121],
            [-77.486944443999874, 19.169722222000118],
            [-77.49625, 19.169722222000118],
            [-77.534583332999972, 19.169166667000056],
            [-77.567222221999941, 19.168611111000089],
            [-77.573055555999957, 19.16833333300012],
            [-77.611388888999898, 19.168055556000056],
            [-77.649444443999954, 19.168888889000172],
            [-77.6875, 19.170555556000139],
            [-77.725555555999875, 19.173333333000201],
            [-77.808315255000025, 19.182291659000143],
            [-77.829565574999862, 19.183269177000099],
            [-77.840217133999829, 19.183896060000095],
            [-77.848715708999947, 19.18445379700016],
            [-77.972058712999853, 19.193091304000106],
            [-78.021282335999899, 19.197507594000129],
            [-78.201857931999939, 19.21467705600007],
            [-78.238228791999859, 19.223929817000041],
            [-78.636796878999917, 19.343259115000066],
            [-78.723584578999919, 19.356544036000045],
            [-78.748087568999864, 19.482787953],
            [-78.799390690999928, 19.749311088999988],
            [-78.80263095, 19.766215480000071],
            [-78.986896212000033, 20.044270443000116],
            [-79.20731251499987, 20.189535790000079],
            [-79.406119561999901, 20.320718942000141],
            [-79.54664693499987, 20.394696468000106],
            [-79.594951799999905, 20.420658198000112],
            [-79.740902907999839, 20.459794572000064],
            [-79.755412103999873, 20.463756655999973],
            [-79.916498826999884, 20.495127124000149],
            [-79.945263972999868, 20.500855953000141],
            [-80.031629206999924, 20.51823995000008],
            [-80.113668481, 20.545745170000188],
            [-80.207483858999893, 20.5715508870001],
            [-80.221839729999942, 20.575992919000086],
            [-80.304026829999884, 20.601810719000113],
            [-80.323855366999851, 20.607416645000043],
            [-80.346019238999872, 20.613715262000099],
            [-80.429664949999903, 20.637853246000148],
            [-80.548756200999833, 20.672443807000022],
            [-80.723561484000015, 20.592135191999986],
            [-80.954579441999954, 20.490443239000101],
            [-81.042981192999832, 20.491100708000076],
            [-81.066250088999908, 20.49284603200006],
            [-81.13527662199985, 20.501120952000107],
            [-81.193187392999846, 20.508136400000055],
            [-81.387519496999914, 20.498632995999955],
            [-81.426909261999867, 20.496696978000088],
            [-81.518705376999947, 20.487474120000101],
            [-81.753032439999942, 20.451704759000123],
            [-81.790800049999888, 20.438527639000185],
            [-81.849533218999909, 20.418157515000146],
            [-82.015487303999947, 20.36168591400012],
            [-82.383435811999874, 20.236931943000073],
            [-82.453442932999849, 20.213212294000115],
            [-82.673137995999866, 20.078981520000113],
            [-82.686659190999961, 20.070716706000084],
            [-82.710704865999901, 20.055517120000033],
            [-82.934172734999976, 19.914189076999989],
            [-83.124403469999862, 19.793705964000011],
            [-83.31535143699989, 19.671406677000206],
            [-83.59722, 19.465833],
            [-83.930898217999896, 19.523001882000116],
            [-84.247615264999922, 19.581822440000096],
            [-84.64185, 19.5405],
            [-84.712916666999888, 19.654611111],
            [-84.965638888999933, 20.076972222000123],
            [-85.1234027779999, 20.296305556000064],
            [-85.53975, 20.82677777800005],
            [-85.855055555999854, 21.600027778000012],
            [-85.878722221999965, 21.692083332999957],
            [-86.009777777999972, 22.315500000000114],
            [-86.11527777799995, 22.759111111000053],
            [-86.376055555999926, 23.44841666700016],
            [-86.404083332999846, 23.508750000000148],
            [-86.937969443999862, 24.941341667000117],
            [-86.553333329999873, 25.2069444400001],
            [-85.999999999999886, 24.898611109999976],
            [-85.72, 24.7375],
            [-85.531944439999961, 24.648611110000033],
            [-85.10555556, 24.44305556000019],
            [-84.999999999999829, 24.391111110000182],
            [-84.768888889999914, 24.2775],
            [-84.644444439999887, 24.221666670000175],
            [-84.491111109999878, 24.159722220000205],
            [-83.999999999999858, 23.976666670000228],
            [-83.803333329999987, 23.929166670000072],
            [-83.693333329999973, 23.900555560000114],
            [-83.550555559999879, 23.873611110000056],
            [-83.430555559999902, 23.855555560000042],
            [-83.25, 23.830555560000121],
            [-83.0, 23.822777780000152],
            [-82.853333329999884, 23.825],
            [-82.815, 23.827777780000147],
            [-82.674899076999907, 23.85191501300018],
            [-82.666666669999898, 23.853333330000041],
            [-82.41666666999987, 23.853333330000041],
            [-82.386840863999964, 23.85028447],
            [-82.166666669999927, 23.827777780000147],
            [-82.003333329999919, 23.8175],
            [-81.833333329999959, 23.8175],
            [-81.666666669999927, 23.833333330000087],
            [-81.5, 23.84722222000012],
            [-81.32888889000003, 23.897222220000188],
            [-81.215277779999838, 23.925000000000111],
          ],
          [
            [-75.121399708394563, 19.887499740789238],
            [-75.085281892306057, 19.893036171848777],
            [-75.085554973035556, 19.917218149369816],
            [-75.087235879903034, 19.965554070546702],
            [-75.139735820018444, 19.962872936241453],
            [-75.164463728065073, 19.902499700133532],
            [-75.159727253678142, 19.890481713730154],
            [-75.134445756920996, 19.886945215974364],
            [-75.121399708394563, 19.887499740789238],
          ],
          [
            [-75.170290777616913, 19.931390740244566],
            [-75.159181820673609, 19.960690828965951],
            [-75.15918194601042, 19.9630540840988],
            [-75.193208952598525, 19.960973192810528],
            [-75.226963882608771, 19.924445026307936],
            [-75.223726841132219, 19.901554398984175],
            [-75.170290777616913, 19.931390740244566],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CW", Country: "Curaao" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.390654905999895, 11.666666363000104],
            [-68.599999563999859, 11.666666363000104],
            [-68.829166563999877, 11.741666363000093],
            [-68.95416656399982, 11.758333363000133],
            [-69.079166563999934, 11.879166363000024],
            [-69.547519004999913, 12.151386284000068],
            [-69.54428449999989, 12.169680500000084],
            [-69.519138999999882, 12.311847000000114],
            [-69.504141999999888, 12.5807420000001],
            [-69.500313999999889, 12.607417],
            [-69.499630999999852, 12.612164],
            [-69.49581, 12.6388],
            [-69.45414199999982, 12.962142],
            [-69.38831, 13.306597],
            [-69.34081, 13.496881],
            [-69.34082, 15.349717000000112],
            [-69.341032887999916, 15.356442375000142],
            [-68.862222563999978, 15.241111366000098],
            [-68.591410218999897, 14.374787796000078],
            [-68.591499999999883, 14.373472000000106],
            [-68.670028, 13.252111],
            [-68.708694, 12.696444000000156],
            [-68.714332999999897, 12.629917],
            [-68.72084, 12.558489],
            [-68.72112, 12.555336000000125],
            [-68.72147, 12.5515],
            [-68.710332999999878, 12.522861],
            [-68.68375, 12.415000000000191],
            [-68.670028, 12.369444000000101],
            [-68.65283, 12.322722],
            [-68.6265, 12.232],
            [-68.623946333333208, 12.222657666666748],
            [-68.620349, 12.215508],
            [-68.61236, 12.199667000000147],
            [-68.60828, 12.189556],
            [-68.60061, 12.18511100000012],
            [-68.52017, 12.106056],
            [-68.486278, 12.073917],
            [-68.46036, 12.024194000000136],
            [-68.45372, 11.987861],
            [-68.45117, 11.977778000000114],
            [-68.44199, 11.93605],
            [-68.441331, 11.933044],
            [-68.436722, 11.912083000000166],
            [-68.429888999999889, 11.857722],
            [-68.424082999999825, 11.829444000000137],
            [-68.390654905999895, 11.666666363000104],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CXR", Country: "Christmas I." },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [109.034971347333425, -11.118866059333243],
            [109.031952602000189, -11.134142658000016],
            [109.028864602000027, -11.149396657999858],
            [109.02570360200005, -11.164636657999964],
            [109.022470602000084, -11.179861657999894],
            [109.019166602000183, -11.195071658],
            [109.01579060200018, -11.210266658999942],
            [109.012342602000075, -11.225445658999973],
            [109.008822602000151, -11.24060765900002],
            [109.005231602000123, -11.255754658999976],
            [109.001568602000106, -11.270884658999861],
            [108.997833602000156, -11.285997659000031],
            [108.9940286020001, -11.301092658999949],
            [108.990151602000111, -11.31617065899988],
            [108.986202602000134, -11.331230658999928],
            [108.98218360100023, -11.346272659],
            [108.978092601000213, -11.361295658999907],
            [108.973931601000089, -11.37629965899994],
            [108.969698601000147, -11.391284659],
            [108.965394601000213, -11.406249659],
            [108.961020601000229, -11.421194658999937],
            [108.956575601000139, -11.436119658999914],
            [108.952080601000119, -11.450970658999893],
            [108.947516601000217, -11.465801658999908],
            [108.942881601000153, -11.480610658999879],
            [108.938176601000151, -11.495398658999889],
            [108.93340260100004, -11.510165658999853],
            [108.928558601000162, -11.52490965899986],
            [108.923644601, -11.539631659],
            [108.918661601000196, -11.554330658999845],
            [108.913608601000163, -11.569007658999908],
            [108.908487601000189, -11.583660659000032],
            [108.903295601000224, -11.598289658999946],
            [108.898035601000203, -11.612895658999904],
            [108.892706601000015, -11.627476658999925],
            [108.887308601000228, -11.642033658999921],
            [108.881842601000045, -11.65656565899998],
            [108.876306601000095, -11.671071658999836],
            [108.870702601000147, -11.685552658999939],
            [108.865030601000086, -11.70000865899992],
            [108.859289601000143, -11.714437658999984],
            [108.853480601000086, -11.728840658999843],
            [108.847603601000202, -11.74321665899987],
            [108.841657601000207, -11.75756565899988],
            [108.835644601000155, -11.77188665899989],
            [108.829564601000158, -11.786180658999882],
            [108.823415601000221, -11.800446658999959],
            [108.817199601000226, -11.814684659000022],
            [108.810916601000059, -11.828893659],
            [108.804565601000121, -11.843073658999884],
            [108.798147601000124, -11.857223658999956],
            [108.791662601000013, -11.871345658999928],
            [108.785111601000125, -11.885436658999907],
            [108.778933601000205, -11.899766658999951],
            [108.772688601000112, -11.914067658999898],
            [108.766374601000138, -11.928340658999929],
            [108.759992601000107, -11.942583658999965],
            [108.753543601000132, -11.956797659],
            [108.747026601000044, -11.970981658999861],
            [108.740441601000185, -11.985135659],
            [108.733789601000097, -11.999258658999963],
            [108.727070601000179, -12.013351659000023],
            [108.720283601000034, -12.027412658999921],
            [108.713430601000113, -12.04144365899991],
            [108.706510601000076, -12.055441658999925],
            [108.699523601000152, -12.069408658999862],
            [108.692469601000226, -12.083342658999911],
            [108.68534960100007, -12.097244658999884],
            [108.678163601000136, -12.111113658999884],
            [108.670911601000142, -12.12494965899991],
            [108.663593601000031, -12.138751658999965],
            [108.656209601000143, -12.152519658999864],
            [108.648759601000137, -12.166253658999977],
            [108.641244601000068, -12.179953658999935],
            [108.633663601000222, -12.193619658999921],
            [108.626017601000143, -12.207249658999956],
            [108.618307601000112, -12.220844659000022],
            [108.61053160100019, -12.234403658999952],
            [108.602690601000148, -12.247926659000015],
            [108.594785601000154, -12.261413659000027],
            [108.586816601000095, -12.274864658999988],
            [108.578782601000142, -12.28827866],
            [108.570684601000067, -12.301655659999881],
            [108.562523601000208, -12.31499466],
            [108.554297601000172, -12.328296659999978],
            [108.546071601000079, -12.341466659999867],
            [108.537782601000089, -12.354598659999908],
            [108.529431601000141, -12.367692659999818],
            [108.521018601000122, -12.380747659999884],
            [108.512543601000203, -12.393765659999815],
            [108.504006601000043, -12.406743659999989],
            [108.495407601000153, -12.419682659999864],
            [108.486747601000189, -12.432582659999895],
            [108.478026601000096, -12.445442659999898],
            [108.469243601000102, -12.458263659999872],
            [108.460400601000202, -12.47104366],
            [108.451496601000116, -12.483782659999946],
            [108.44253160100007, -12.496481659999858],
            [108.433506601000119, -12.509138659999948],
            [108.424421601000091, -12.521755659999826],
            [108.415276601000159, -12.534330659999966],
            [108.40607160100015, -12.546862659999917],
            [108.396806601000065, -12.559353659999942],
            [108.38748260100013, -12.571801659999963],
            [108.378099601000059, -12.584207659999976],
            [108.368657601000081, -12.596569659999986],
            [108.359155601000083, -12.608889659999889],
            [108.349596601000172, -12.621165659999889],
            [108.339977601000015, -12.633397659999986],
            [108.330301601000116, -12.645585659999895],
            [108.320566601000138, -12.657728659999904],
            [108.310774601000134, -12.669828659999908],
            [108.300924601000048, -12.681882659999928],
            [108.291017601000107, -12.693891659999949],
            [108.281052601000198, -12.705855659999983],
            [108.271031601000089, -12.717773659999935],
            [108.260952601000128, -12.729645659999903],
            [108.250817601000023, -12.741472659999971],
            [108.240626601000116, -12.753251659999876],
            [108.230378601000069, -12.764985659999979],
            [108.220075601000161, -12.77667166],
            [108.209715601000227, -12.78831065999988],
            [108.199301601000087, -12.799901659999961],
            [108.188831601000146, -12.811445659999876],
            [108.178305601000176, -12.822941659999913],
            [108.167725601000171, -12.834389659999971],
            [108.157091601000133, -12.845789659999966],
            [108.146402601000062, -12.857140659999899],
            [108.135659601000185, -12.868441659999874],
            [108.124861601000163, -12.87969466],
            [108.114010601000103, -12.89089766],
            [108.103106601000121, -12.902051659999984],
            [108.092148601000105, -12.913154659999918],
            [108.081142601000039, -12.924205659999814],
            [108.070083601000107, -12.935205659999838],
            [108.058972601000193, -12.94615465999982],
            [108.047808601000185, -12.957053659999843],
            [108.036593601000135, -12.967900659999913],
            [108.025325601000162, -12.978696660000026],
            [108.014006601000091, -12.989441659999912],
            [108.002635601000208, -13.000133659999861],
            [107.991214601000166, -13.010774659999939],
            [107.979741601000086, -13.021363659999977],
            [107.968218601000132, -13.031899659999894],
            [107.956644601000022, -13.042382659999959],
            [107.94502160100015, -13.052813659999885],
            [107.933347601000122, -13.063190659999975],
            [107.921624601000218, -13.073514659999844],
            [107.909852600000107, -13.083784659999864],
            [107.898030600000169, -13.094000659999864],
            [107.886159600000127, -13.104163659999827],
            [107.874240600000149, -13.114271659999858],
            [107.862273600000066, -13.124324659999957],
            [107.850257600000162, -13.134323659999851],
            [107.83819360000021, -13.144267659999898],
            [107.826082600000206, -13.154155660000029],
            [107.813924600000092, -13.163988660000044],
            [107.801718600000214, -13.173766659999856],
            [107.789466600000225, -13.183488659999924],
            [107.777167600000013, -13.193154659999976],
            [107.76482160000009, -13.202763659999931],
            [107.752430600000167, -13.212316659999871],
            [107.739993600000076, -13.221813659999881],
            [107.727510600000102, -13.231252659999896],
            [107.714982600000127, -13.240635659999981],
            [107.70241060000015, -13.249960659999886],
            [107.689792600000175, -13.259227659999965],
            [107.677130600000197, -13.268437659999947],
            [107.66442460000016, -13.277589659999833],
            [107.651674600000121, -13.28668366],
            [107.638881600000133, -13.295719659999875],
            [107.626044600000085, -13.304696659999848],
            [107.613164600000033, -13.313615659999911],
            [107.600241600000146, -13.322474659999898],
            [107.587276600000138, -13.331275659999875],
            [107.574268600000067, -13.340016659999961],
            [107.561219600000157, -13.348698659999954],
            [107.548128600000126, -13.357320660999861],
            [107.534996600000085, -13.365882660999873],
            [107.521822600000206, -13.374384660999894],
            [107.508608600000144, -13.382826660999925],
            [107.495353600000072, -13.391207660999882],
            [107.482058600000215, -13.399528661000033],
            [107.468723600000061, -13.407788660999927],
            [107.4553486, -13.415988660999915],
            [107.441934600000167, -13.424125660999934],
            [107.42848160000014, -13.432202660999963],
            [107.414989600000155, -13.44021766099992],
            [107.401459600000209, -13.448171660999989],
            [107.387891600000074, -13.456062660999905],
            [107.374284600000095, -13.463892660999832],
            [107.360640600000153, -13.471659660999975],
            [107.346959600000076, -13.479364660999948],
            [107.333240600000209, -13.487007660999865],
            [107.319485600000149, -13.4945866609999],
            [107.305694600000123, -13.502103661000049],
            [107.291866600000134, -13.509557661000045],
            [107.278003600000176, -13.516948660999887],
            [107.264104600000024, -13.524275660999947],
            [107.250170600000132, -13.53153966099994],
            [107.236201600000214, -13.53873966099988],
            [107.222197600000158, -13.54587566099994],
            [107.208159600000187, -13.552947660999862],
            [107.194087600000017, -13.559955660999904],
            [107.179981600000048, -13.566898660999897],
            [107.165842600000161, -13.573777660999923],
            [107.151670600000131, -13.580592660999983],
            [107.13774360000005, -13.587216660999871],
            [107.123785600000161, -13.593778660999959],
            [107.109796600000067, -13.600278660999891],
            [107.095776600000107, -13.606715660999939],
            [107.081725600000055, -13.613089661000018],
            [107.067392600000204, -13.619513660999871],
            [107.053028600000204, -13.625873660999858],
            [107.038633600000111, -13.632167660999883],
            [107.024209600000205, -13.638395660999961],
            [107.009754600000207, -13.644557661],
            [106.995269600000057, -13.650654660999976],
            [106.980756600000149, -13.656684660999829],
            [106.966213600000145, -13.662648661],
            [106.951642600000156, -13.668546660999951],
            [106.937043600000067, -13.674377660999951],
            [106.922415600000164, -13.680141661],
            [106.907760600000216, -13.685839660999832],
            [106.893077600000112, -13.691470660999897],
            [106.87836860000013, -13.697034660999918],
            [106.863631600000105, -13.702531660999909],
            [106.848869600000199, -13.707961660999857],
            [106.834080599000089, -13.713323660999961],
            [106.819266599000144, -13.718618660999837],
            [106.804426599000209, -13.723846660999953],
            [106.789561599000223, -13.729005661000045],
            [106.774671599000186, -13.734097661],
            [106.759757599000153, -13.739121661000027],
            [106.744818599000183, -13.74407766099992],
            [106.729856599000158, -13.748964660999889],
            [106.71487159900019, -13.753784661],
            [106.699862599000113, -13.758535660999897],
            [106.684830599000151, -13.763217660999956],
            [106.669776599000073, -13.767831660999889],
            [106.654700599000222, -13.772376660999981],
            [106.639602599000142, -13.776853660999947],
            [106.624483599000172, -13.781261660999888],
            [106.609342599000087, -13.785599660999907],
            [106.594181599000223, -13.789869660999983],
            [106.578999599000127, -13.794069661000037],
            [106.563797599000139, -13.798201660999879],
            [106.548575599000202, -13.802262660999887],
            [106.533334599000085, -13.806255660999867],
            [106.518073599000019, -13.810178661],
            [106.502794599000168, -13.814031660999859],
            [106.487496599000139, -13.817815660999869],
            [106.472180599000154, -13.821529660999857],
            [106.456847599000156, -13.825173661],
            [106.441496599000203, -13.828747660999866],
            [106.426127599000068, -13.832251660999887],
            [106.410742599000145, -13.835684660999988],
            [106.395341599000204, -13.839048660999879],
            [106.379923599000136, -13.842342660999932],
            [106.36449059900022, -13.845565660999881],
            [106.34904159900023, -13.848718660999907],
            [106.333577599000051, -13.851800660999913],
            [106.318098599000081, -13.854812660999912],
            [106.302605599000088, -13.857753660999975],
            [106.298019599000071, -13.858616660999971],
            [106.282465599000176, -13.861496660999876],
            [106.266897599000032, -13.864306660999944],
            [106.251316599000035, -13.867044660999909],
            [106.235721599000129, -13.869711660999954],
            [106.220114599000141, -13.872306661],
            [106.204494599000128, -13.874830661000018],
            [106.188862599000089, -13.877283661000035],
            [106.173218599000137, -13.87966466099995],
            [106.157563599000156, -13.881974660999944],
            [106.141897599000146, -13.884212660999935],
            [106.126916599000225, -13.886302660999931],
            [106.111924599000218, -13.888327660999877],
            [106.096924599, -13.890287660999959],
            [106.0819145990001, -13.892181661],
            [106.066896599000216, -13.894009660999899],
            [106.051870599000068, -13.895771661000026],
            [106.036835599000057, -13.897468661000019],
            [106.021793599000176, -13.899099660999966],
            [106.006744599000143, -13.900665660999948],
            [105.991687599000073, -13.902164660999986],
            [105.976623599000135, -13.903598660999975],
            [105.961553599000155, -13.904966660999918],
            [105.946477599000133, -13.906268660999913],
            [105.931395599000126, -13.907504660999862],
            [105.916307599000078, -13.908674660999949],
            [105.901214599000156, -13.90977866099999],
            [105.885635599000153, -13.910871660999916],
            [105.870052599000161, -13.911894661],
            [105.854463599000127, -13.912846660999904],
            [105.838870599000217, -13.913728660999965],
            [105.823273599000146, -13.914540661],
            [105.807672599000199, -13.915282660999935],
            [105.792068599000089, -13.915952660999949],
            [105.7764615990001, -13.916553661000023],
            [105.760851598000187, -13.917083660999907],
            [105.745238598000043, -13.917543660999868],
            [105.729624598000072, -13.917932661],
            [105.71400859800022, -13.918251660999914],
            [105.698391598000086, -13.918500660999911],
            [105.682772598000071, -13.918678660999987],
            [105.667153598000056, -13.918785660999959],
            [105.651534598000097, -13.918822660999908],
            [105.635914598000085, -13.918789660999934],
            [105.62029559800007, -13.918685660999856],
            [105.604676598000225, -13.918511661000025],
            [105.589059598000205, -13.918266661],
            [105.573443598000068, -13.917951660999876],
            [105.5578285980001, -13.917565660999912],
            [105.542216598000124, -13.917109660999841],
            [105.526606598000143, -13.916583660999933],
            [105.510999598000154, -13.915986661],
            [105.495394598000217, -13.915319660999884],
            [105.479793598000157, -13.914581660999929],
            [105.4641965980002, -13.913773660999865],
            [105.448603598, -13.912895660999965],
            [105.433014598000142, -13.911946660999959],
            [105.417430598000095, -13.910927660999931],
            [105.401851598000093, -13.909838660999981],
            [105.386277598000078, -13.908679661],
            [105.370709598000104, -13.90744966099993],
            [105.355147598000173, -13.906149661000015],
            [105.339591598000226, -13.904779661],
            [105.324043598000031, -13.903339660999862],
            [105.308501598000163, -13.901828660999897],
            [105.29296759800016, -13.900248660999907],
            [105.277440598000084, -13.898597661],
            [105.261922598000098, -13.896877660999877],
            [105.246412598000035, -13.895086660999837],
            [105.230910598000179, -13.893226661000043],
            [105.215418598000184, -13.891296660999956],
            [105.200062598000073, -13.889323660999864],
            [105.18471459800017, -13.887282661000015],
            [105.169377598000125, -13.885172660999871],
            [105.154050598000111, -13.88299466099987],
            [105.138734598000184, -13.880747660999944],
            [105.123428598000061, -13.878432660999977],
            [105.108134598000021, -13.876048660999899],
            [105.092851598000181, -13.87359666099988],
            [105.077580598000083, -13.871075660999921],
            [105.062321598000182, -13.868487660999932],
            [105.047074598000023, -13.865829660999921],
            [105.031840598000059, -13.863104660999966],
            [105.016620598000173, -13.860311660999884],
            [105.001412598000144, -13.857450661000044],
            [104.98621959800019, -13.854520661],
            [104.97103959800009, -13.851523660999916],
            [104.955874598000065, -13.848458660999981],
            [104.940724598000116, -13.845325660999919],
            [104.925589598000073, -13.842124661000014],
            [104.910469598000219, -13.83885666099998],
            [104.895365598000097, -13.835520661],
            [104.880277598000049, -13.832116661],
            [104.865205598000074, -13.828645660999953],
            [104.85015059800017, -13.825107660999961],
            [104.835111598000168, -13.821501660999942],
            [104.820090598000064, -13.817828660999879],
            [104.805087598000142, -13.814088660999872],
            [104.790102598000061, -13.810281661],
            [104.775134598000164, -13.806407660999923],
            [104.760186598000161, -13.802466660999983],
            [104.745256598000225, -13.798458660999827],
            [104.730345598000071, -13.794384660999896],
            [104.71545459800015, -13.790243661000019],
            [104.700583598000122, -13.786035661000014],
            [104.685731597000228, -13.781761660999962],
            [104.670698597000211, -13.777373660999984],
            [104.655685597000144, -13.77291766099998],
            [104.640694597000021, -13.768393660999848],
            [104.625725597000127, -13.76380166099996],
            [104.61077859700012, -13.759142660999942],
            [104.595854597000226, -13.754414660999984],
            [104.580952597000106, -13.749619661],
            [104.566074597000096, -13.744757660999966],
            [104.551219597000141, -13.739827660999907],
            [104.536389597000124, -13.73483066099999],
            [104.521582597000162, -13.729766660999857],
            [104.506800597000193, -13.724634661000053],
            [104.492043597000162, -13.719436660999932],
            [104.477310597000184, -13.714171660999867],
            [104.462604597000023, -13.708839660999942],
            [104.447923597000084, -13.703441660999886],
            [104.433268597000136, -13.69797666099997],
            [104.418640597000064, -13.692445660999923],
            [104.404038597000152, -13.686847660999931],
            [104.389463597000173, -13.681183660999977],
            [104.374916597000123, -13.67545466099989],
            [104.360396597, -13.669658660999943],
            [104.345905597000211, -13.663797660999947],
            [104.331441597000122, -13.657870660999905],
            [104.317006597000187, -13.651878661],
            [104.302601597000063, -13.645820660999959],
            [104.288224597000209, -13.639697660999957],
            [104.273877597000109, -13.633509661],
            [104.25956059700016, -13.62725666099989],
            [104.245720597000087, -13.621146660999898],
            [104.231910597000166, -13.614975660999917],
            [104.218128597000174, -13.608743660999963],
            [104.204375597000165, -13.602452661],
            [104.190652597000081, -13.59610066099988],
            [104.176958597000095, -13.589687660999957],
            [104.163294597000089, -13.58321566099994],
            [104.149660597000064, -13.576683660999848],
            [104.136057597000132, -13.57009166099985],
            [104.122485597000122, -13.563439660999961],
            [104.108943597000092, -13.55672866099998],
            [104.095433597000039, -13.549958660999906],
            [104.081955597000132, -13.54312866099994],
            [104.068508597000147, -13.536239660999883],
            [104.055093597000138, -13.529291661000016],
            [104.040935597000129, -13.522507661000034],
            [104.026809597000096, -13.515659660999901],
            [104.012716597000036, -13.508746660999904],
            [103.998657597000118, -13.501770660999938],
            [103.984632597000171, -13.494729661],
            [103.970640597000141, -13.487624660999842],
            [103.956683597000136, -13.480456660999977],
            [103.9427615970001, -13.473224660999875],
            [103.928874597000032, -13.465928660999893],
            [103.915022597000217, -13.458569660999856],
            [103.90120659700014, -13.451148660999834],
            [103.887425597000146, -13.443663660999945],
            [103.873681597000171, -13.436116660999886],
            [103.859973597000106, -13.428506660999957],
            [103.846302597000118, -13.420833660999961],
            [103.832669597000205, -13.413099660999976],
            [103.819072597000144, -13.405302660999936],
            [103.805513597000214, -13.397443660999912],
            [103.791992597000075, -13.389523661],
            [103.778510597000121, -13.381541661000014],
            [103.765066597000015, -13.373498660999957],
            [103.751661597000208, -13.365393660999828],
            [103.738295597000189, -13.357227660999897],
            [103.724968597000185, -13.349001659999885],
            [103.711681597000194, -13.34071465999989],
            [103.698434597000215, -13.332366659999906],
            [103.68522859700002, -13.323959659999929],
            [103.672062597000178, -13.315491659999878],
            [103.658936597000121, -13.306963660000022],
            [103.64575959700008, -13.298320659999874],
            [103.632624597000159, -13.289617659999919],
            [103.619531597000133, -13.280854659999889],
            [103.606480596000068, -13.272031659999953],
            [103.593473596000223, -13.263148659999942],
            [103.580508596000158, -13.254205660000025],
            [103.567587596000095, -13.24520366],
            [103.554709596000095, -13.236142659999842],
            [103.541876596000208, -13.227022659999847],
            [103.52908759600021, -13.217843659999943],
            [103.516342596000214, -13.208605659999861],
            [103.503642596000219, -13.199309659999869],
            [103.490987596000224, -13.189955659999967],
            [103.478377596000115, -13.180543659999969],
            [103.465813596, -13.171073659999877],
            [103.453295596000117, -13.161545659999874],
            [103.440823596000172, -13.151960659999958],
            [103.428398596000108, -13.142318659999844],
            [103.416019596000098, -13.132619659999904],
            [103.403687596000083, -13.122863659999865],
            [103.39140359600006, -13.113051659999897],
            [103.379165596000092, -13.103183660000013],
            [103.366976596000171, -13.093258659999933],
            [103.354835596000072, -13.08327866],
            [103.342742596000022, -13.07324266],
            [103.330697596000135, -13.063150659999948],
            [103.318702596000122, -13.053004659999971],
            [103.306755596000158, -13.042802659999978],
            [103.294858596000068, -13.032546660000051],
            [103.283010596000139, -13.02223566],
            [103.27121259600014, -13.011870659999943],
            [103.259464596000129, -13.001451659999844],
            [103.247767596000102, -12.990978659999897],
            [103.236120596, -12.980452660000012],
            [103.224524596000123, -12.96987266],
            [103.212979596000224, -12.959239659999881],
            [103.20148659600008, -12.948553659999988],
            [103.190112596000205, -12.93788366],
            [103.178790596000084, -12.927161659999967],
            [103.167519596000176, -12.916387659999899],
            [103.156299596000082, -12.905562659999873],
            [103.145132596000082, -12.894687659999974],
            [103.13401759600012, -12.88376066],
            [103.122954596000142, -12.872782659999871],
            [103.111944596000086, -12.861755660000014],
            [103.10098659600007, -12.850677660000017],
            [103.090082596000144, -12.839549659999875],
            [103.079231596000028, -12.828372659999857],
            [103.068434596000174, -12.817145659999966],
            [103.057690596000185, -12.805869659999928],
            [103.047000596000061, -12.794545659999827],
            [103.036365596000138, -12.783171659999851],
            [103.025783596000139, -12.771750659999896],
            [103.015257596000168, -12.760280659999879],
            [103.004785596000119, -12.748762659999983],
            [102.994369596000098, -12.737197659999836],
            [102.984008596000166, -12.725584659999981],
            [102.973702596000209, -12.713924659999876],
            [102.963452596000053, -12.702217659999974],
            [102.953258596000097, -12.690464659999904],
            [102.943121596000168, -12.678664659999939],
            [102.933039596000157, -12.666818659999905],
            [102.923015596000056, -12.654926659999973],
            [102.913047596000155, -12.642989659999955],
            [102.903136596000166, -12.631006659999855],
            [102.893282596000091, -12.618979660000036],
            [102.88348659600004, -12.606906659999865],
            [102.873747596000072, -12.594789659999876],
            [102.864066596000185, -12.582628659999884],
            [102.85444459600015, -12.570422659999906],
            [102.844879596000197, -12.55817366],
            [102.835373596000153, -12.545880660000023],
            [102.825925596000189, -12.533545659999845],
            [102.816537596000131, -12.521166659999849],
            [102.807207596000154, -12.508744659999849],
            [102.797937596000025, -12.496280659999925],
            [102.788726596000203, -12.48377466],
            [102.77957459600006, -12.471226659999871],
            [102.770483596000162, -12.458637659999908],
            [102.761451596000171, -12.446006660000023],
            [102.75248059600014, -12.433334659999943],
            [102.743738596000156, -12.420869659999937],
            [102.73505559600008, -12.408364659999904],
            [102.726430596000085, -12.395821660000024],
            [102.717864596000112, -12.383240660000013],
            [102.709356596000049, -12.370620659999972],
            [102.700907596000064, -12.357963659999882],
            [102.692517596000158, -12.345267659999863],
            [102.684187596000157, -12.332535659999877],
            [102.675916596000064, -12.31976565999986],
            [102.66770459600005, -12.306959659999976],
            [102.659552596000111, -12.294115659999875],
            [102.651460596000078, -12.281236658999987],
            [102.643428596000064, -12.268320658999968],
            [102.635456596000182, -12.255369658999896],
            [102.627545596000147, -12.242382658999958],
            [102.619694596000073, -12.229360658999866],
            [102.611903596000076, -12.216303658999905],
            [102.604174596000149, -12.203211658999891],
            [102.596505596000128, -12.190085658999905],
            [102.588898596000178, -12.176924658999866],
            [102.581351596000133, -12.163730658999853],
            [102.573867596000042, -12.150502658999955],
            [102.566443596000141, -12.137241658999812],
            [102.559081596000198, -12.123947658999867],
            [102.551782596000152, -12.110620658999864],
            [102.544544596000179, -12.097260658999971],
            [102.537368595, -12.083868659],
            [102.53025459500023, -12.070444658999961],
            [102.523203595000183, -12.056989658999839],
            [102.516277595000105, -12.043631659000027],
            [102.509411595000159, -12.030243658999851],
            [102.502608595000225, -12.016825658999863],
            [102.495866595000081, -12.003376658999983],
            [102.489186595000064, -11.989898659],
            [102.48256859500006, -11.976390658999847],
            [102.476012595000128, -11.962853658999876],
            [102.469518595000153, -11.94928865899989],
            [102.463087595000076, -11.935693659],
            [102.456718595000126, -11.922070658999843],
            [102.450412595000074, -11.908420658999844],
            [102.444168595000207, -11.894741658999934],
            [102.437987595000124, -11.88103565899992],
            [102.431870595000163, -11.867301659],
            [102.425815595000103, -11.853541658999859],
            [102.419823595000111, -11.839754658999894],
            [102.413895595000071, -11.825941658999909],
            [102.408030595000099, -11.812101658999907],
            [102.402228595000082, -11.798236658999883],
            [102.396491595000128, -11.78434565899984],
            [102.390816595000189, -11.770428658999961],
            [102.385206595000085, -11.756487658999859],
            [102.379660595000104, -11.74252165899992],
            [102.374177595000134, -11.728531658999856],
            [102.368611595000203, -11.71413365899987],
            [102.363113595000215, -11.699709658999964],
            [102.357682595000114, -11.685261658999835],
            [102.35232059500018, -11.670788658999868],
            [102.347026595000131, -11.656291658999862],
            [102.341799595000083, -11.641770658999917],
            [102.336642595000086, -11.627225659000032],
            [102.331552595000204, -11.612657659000021],
            [102.326531595000148, -11.598066658999969],
            [102.321579595000145, -11.583453658999957],
            [102.316695595000141, -11.568817658999819],
            [102.31188059500019, -11.554159658999822],
            [102.307134595, -11.539479658999866],
            [102.302457595000163, -11.524777658999867],
            [102.297849595000031, -11.510055658999988],
            [102.293310595000065, -11.495312658999879],
            [102.28884159500015, -11.480548659],
            [102.284441595000061, -11.465764658999959],
            [102.280110595000025, -11.450960658999961],
            [102.275848595000156, -11.436137658999982],
            [102.271656595000223, -11.421294658999955],
            [102.267534595000058, -11.406432658999947],
            [102.263481595000172, -11.391552658999871],
            [102.259499595000221, -11.376654658999911],
            [102.25558659500021, -11.361737658999886],
            [102.251743595000022, -11.346803658999875],
            [102.247970595000226, -11.331852658999878],
            [102.244267595000139, -11.316883658999814],
            [102.240634595000216, -11.301898658999846],
            [102.237071595000117, -11.286897658999976],
            [102.23357959500018, -11.271879659000035],
            [102.230157595000122, -11.256846659],
            [102.226805595000229, -11.241797658999886],
            [102.2235245950001, -11.226733658999962],
            [102.220313595000079, -11.211654659000033],
            [102.217173595000162, -11.196561658],
            [102.214104595000066, -11.181454657999893],
            [102.211105595000078, -11.166333657999957],
            [102.208177595000194, -11.151199657999825],
            [102.205319595000077, -11.136051657999872],
            [102.202533595000119, -11.120890657999908],
            [102.199817595000098, -11.105717657999932],
            [102.197172595000126, -11.09053265799993],
            [102.194599595000028, -11.075335658000014],
            [102.192096595000152, -11.060126657999987],
            [102.189664595000096, -11.044906657999846],
            [102.187304595000143, -11.02967565799986],
            [102.185014595000013, -11.014434657999942],
            [102.182796595000099, -10.99918265799991],
            [102.180649595000062, -10.983920657999846],
            [102.178573595000074, -10.968649657999933],
            [102.176568595000191, -10.953368657999988],
            [102.174635595000126, -10.938079657999822],
            [102.172773595000166, -10.922780658],
            [102.170982595000197, -10.907474657999842],
            [102.16926359500016, -10.892159657999926],
            [102.167615595000171, -10.876837657999872],
            [102.166072595000202, -10.861860657999856],
            [102.164597595000117, -10.846875657999888],
            [102.163191595000143, -10.831885657999862],
            [102.16185259500017, -10.816888657999883],
            [102.160582595000193, -10.801885657999847],
            [102.159380595000101, -10.786877658000023],
            [102.158247595000063, -10.771864657999956],
            [102.157182595000137, -10.756846658000015],
            [102.156185595000153, -10.741823657999831],
            [102.155256595000225, -10.726796657999941],
            [102.154396595000122, -10.71176565799999],
            [102.153604595000132, -10.696730657999879],
            [102.152881595000082, -10.681692657999875],
            [102.152226595000144, -10.666651657999893],
            [102.151639595000091, -10.651607657999932],
            [102.151121595000205, -10.636560657999894],
            [102.150657595000013, -10.621385657999838],
            [102.1502625950001, -10.606209657999969],
            [102.149937595000125, -10.591030657999937],
            [102.149681595000089, -10.575850657999908],
            [102.149495595000218, -10.560670657999978],
            [102.149379595000056, -10.545488657999869],
            [102.149332595000175, -10.530306657999859],
            [102.149354595000119, -10.515125657999931],
            [102.149446595000171, -10.499943658],
            [102.149607595000163, -10.484762657999894],
            [102.149838595000091, -10.469582657999865],
            [102.150138595000129, -10.454403657999833],
            [102.150508595000105, -10.439225657999884],
            [102.15094759500019, -10.42404965799993],
            [102.151455595000044, -10.408876657999855],
            [102.152033595000177, -10.393704657999962],
            [102.152680595000135, -10.378536657999859],
            [102.153396595000146, -10.363370657999837],
            [102.154182595000094, -10.348208657999976],
            [102.155037595000209, -10.333050658],
            [102.155961595000093, -10.317895657999898],
            [102.15695459500003, -10.302745657999964],
            [102.158017595000132, -10.287599657999905],
            [102.159149595000173, -10.272459657999903],
            [102.160349595000156, -10.257323657999876],
            [102.16161959500019, -10.242193658],
            [102.162958595000219, -10.227069657999891],
            [102.164366595000189, -10.211951657999933],
            [102.165843595000098, -10.196840657999843],
            [102.167389595000117, -10.181735657999894],
            [102.169003595000078, -10.1666376579999],
            [102.170687595000146, -10.151547657999956],
            [102.17244459500003, -10.136383657999829],
            [102.174271595000192, -10.121228656999918],
            [102.176167595000123, -10.106081656999962],
            [102.178133595000105, -10.090943656999855],
            [102.180168595000083, -10.075814656999967],
            [102.182272595000171, -10.060694656999928],
            [102.184446595000196, -10.045583656999838],
            [102.186689595000217, -10.030483656999948],
            [102.189000595000124, -10.015393656999905],
            [102.191381595000138, -10.000313656999893],
            [102.193831595000091, -9.985245657],
            [102.196350595000041, -9.97018765699984],
            [102.198938595000214, -9.955141656999984],
            [102.201594595000159, -9.940107656999956],
            [102.204320595000098, -9.925085656999855],
            [102.207114595000093, -9.910075656999865],
            [102.209977595000197, -9.895078656999971],
            [102.21290859500013, -9.880094657],
            [102.215907595000118, -9.865124656999939],
            [102.218976595000214, -9.850167656999886],
            [102.222112595000141, -9.835224657000026],
            [102.225317595000121, -9.820295656999889],
            [102.228590595000043, -9.805381656999941],
            [102.231931595000077, -9.790482656999984],
            [102.235340595000054, -9.775598656999932],
            [102.238817595000143, -9.76072965699997],
            [102.242363595000171, -9.745876656999911],
            [102.24597559500009, -9.731040656999838],
            [102.249656595000175, -9.716220656999937],
            [102.253404595000148, -9.701416657000024],
            [102.257220595000121, -9.68663065699991],
            [102.261103595000151, -9.671861656999965],
            [102.265054595000123, -9.657109656999907],
            [102.268832595000077, -9.642103656999893],
            [102.272680595000139, -9.627113656999867],
            [102.27659759500014, -9.612142657],
            [102.280583595000081, -9.597188656999833],
            [102.284638595000189, -9.58225365700001],
            [102.288762595, -9.567336656999984],
            [102.292956595000106, -9.552439656999923],
            [102.297218595000146, -9.537561656999927],
            [102.301548595000014, -9.522702657],
            [102.305948595000103, -9.50786365700003],
            [102.310416595000135, -9.493045656999939],
            [102.314952595000165, -9.478247656999898],
            [102.319557595000077, -9.463470657],
            [102.324230595000103, -9.448714656999883],
            [102.32897159500007, -9.433980657],
            [102.33378059500015, -9.419267656999878],
            [102.338657595000171, -9.404577656999891],
            [102.343601595000138, -9.389908656999964],
            [102.348614595000157, -9.375263656999877],
            [102.353694595, -9.360641657000016],
            [102.358841595000086, -9.346042656999927],
            [102.364056595000164, -9.331467656999862],
            [102.36933859500013, -9.316915656999925],
            [102.374687595000154, -9.302388656999838],
            [102.380103595000179, -9.287886656999959],
            [102.385586595000149, -9.273408656999933],
            [102.391136595000177, -9.258956656999828],
            [102.396752595000152, -9.244529656999845],
            [102.401986595000068, -9.231247656999926],
            [102.407278595000122, -9.217988657000035],
            [102.412625595000151, -9.204751657000017],
            [102.418029595000149, -9.191537656999941],
            [102.42348959500012, -9.178346656999906],
            [102.42900459500018, -9.165178657],
            [102.434576595000152, -9.152033656999862],
            [102.44020459500021, -9.138911656999852],
            [102.44588759500013, -9.125814656999864],
            [102.451626595000079, -9.112741656999916],
            [102.457420595000116, -9.099691656999823],
            [102.463750595000221, -9.08558765699992],
            [102.470144595000164, -9.071512656999928],
            [102.476602595000173, -9.057466656000031],
            [102.483125595000132, -9.043450655999948],
            [102.489713595000154, -9.029464655999945],
            [102.496365595000128, -9.015508655999852],
            [102.503080595000114, -9.00158265599984],
            [102.509860595000106, -8.987687655999821],
            [102.516704595000164, -8.97382365599988],
            [102.523611595000119, -8.959991655999929],
            [102.530582595000197, -8.946190655999956],
            [102.537616595000173, -8.932421655999974],
            [102.544713596000207, -8.918684655999968],
            [102.551873596000206, -8.90498065599985],
            [102.559096596000103, -8.891308656],
            [102.566382596000068, -8.877670656],
            [102.569175840000156, -8.872499279999872],
            [103.380709449000193, -8.807738119999897],
            [104.153209449000173, -8.744682549999894],
            [104.272931669000087, -8.734682547999924],
            [104.50213935300016, -8.733347617999868],
            [105.22682055900006, -8.729126996999881],
            [105.271742069000226, -8.727262327999881],
            [105.300431669000062, -8.726071436999931],
            [105.393487219000093, -8.748293659999902],
            [105.578209449000127, -8.792738117999832],
            [105.699042779000052, -8.820793672999912],
            [105.700431669000324, -8.821071452999888],
            [106.180709449000204, -8.931627031999867],
            [106.347931669000189, -8.978849259999862],
            [106.386264999000076, -8.989682591999866],
            [106.55876499900009, -9.038293709999849],
            [106.822931669000099, -9.132460396999875],
            [106.848764999000338, -9.151071509999923],
            [106.867924148000071, -9.164679879999852],
            [106.878487219000107, -9.172182623999888],
            [107.013251230000122, -9.268387560999884],
            [107.072653889000065, -9.310793757999932],
            [107.107667995000241, -9.33837599200001],
            [107.199598329000167, -9.410793775999878],
            [107.461154748000268, -9.616699927999861],
            [107.578209449000241, -9.708849388999852],
            [107.726264999000165, -9.826071630999877],
            [107.727799026000156, -9.827605657999882],
            [107.729876109000344, -9.829682740999871],
            [107.847540969000107, -9.944736990999914],
            [107.905153889000161, -10.001071662999905],
            [107.983764999000158, -10.076905006999851],
            [108.0501538890002, -10.140516138999899],
            [108.329598329000106, -10.411627299999893],
            [108.490153889000197, -10.572738440999885],
            [109.034971347333425, -11.118866059333243],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CYM", Country: "Cayman Is." },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.723584578999919, 19.356544036000045],
            [-78.907650409000013, 19.166097505000138],
            [-78.967646499999887, 19.111908576000147],
            [-79.025592414999977, 19.059853258000018],
            [-79.06896170499985, 19.020588426],
            [-79.074932114000035, 19.015109054000149],
            [-79.186181719999865, 18.911820583999983],
            [-79.203993015999913, 18.895188313000048],
            [-79.327384350999921, 18.780646850000082],
            [-79.426758774999882, 18.688045247000147],
            [-79.436857563000046, 18.67792263799997],
            [-79.454043374999856, 18.660352046000028],
            [-79.499117646999849, 18.614389028000133],
            [-79.503654756, 18.609841385999985],
            [-79.512093801999981, 18.601886816999965],
            [-80.591886328000015, 17.584084139000126],
            [-80.83306, 17.58417],
            [-82.35, 17.58417],
            [-83.59722, 19.465833],
            [-83.31535143699989, 19.671406677000206],
            [-83.124403469999862, 19.793705964000011],
            [-82.934172734999976, 19.914189076999989],
            [-82.710704865999901, 20.055517120000033],
            [-82.686659190999961, 20.070716706000084],
            [-82.673137995999866, 20.078981520000113],
            [-82.453442932999849, 20.213212294000115],
            [-82.383435811999874, 20.236931943000073],
            [-82.015487303999947, 20.36168591400012],
            [-81.849533218999909, 20.418157515000146],
            [-81.790800049999888, 20.438527639000185],
            [-81.753032439999942, 20.451704759000123],
            [-81.518705376999947, 20.487474120000101],
            [-81.426909261999867, 20.496696978000088],
            [-81.387519496999914, 20.498632995999955],
            [-81.193187392999846, 20.508136400000055],
            [-81.13527662199985, 20.501120952000107],
            [-81.066250088999908, 20.49284603200006],
            [-81.042981192999832, 20.491100708000076],
            [-80.954579441999954, 20.490443239000101],
            [-80.723561484000015, 20.592135191999986],
            [-80.548756200999833, 20.672443807000022],
            [-80.429664949999903, 20.637853246000148],
            [-80.346019238999872, 20.613715262000099],
            [-80.323855366999851, 20.607416645000043],
            [-80.304026829999884, 20.601810719000113],
            [-80.221839729999942, 20.575992919000086],
            [-80.207483858999893, 20.5715508870001],
            [-80.113668481, 20.545745170000188],
            [-80.031629206999924, 20.51823995000008],
            [-79.945263972999868, 20.500855953000141],
            [-79.916498826999884, 20.495127124000149],
            [-79.755412103999873, 20.463756655999973],
            [-79.740902907999839, 20.459794572000064],
            [-79.594951799999905, 20.420658198000112],
            [-79.54664693499987, 20.394696468000106],
            [-79.406119561999901, 20.320718942000141],
            [-79.20731251499987, 20.189535790000079],
            [-78.986896212000033, 20.044270443000116],
            [-78.80263095, 19.766215480000071],
            [-78.799390690999928, 19.749311088999988],
            [-78.748087568999864, 19.482787953],
            [-78.723584578999919, 19.356544036000045],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CYP", Country: "Cyprus" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.159257533000215, 36.014479385],
            [35.189261533000121, 35.851818385000016],
            [35.191951533000207, 35.837024385000049],
            [35.149392533000054, 35.584974385000137],
            [35.129107533000052, 35.465065385000131],
            [35.114661533000088, 35.296794385000041],
            [35.108645533000157, 35.263400385000139],
            [35.090167533000084, 35.152704385000092],
            [35.06844153300014, 35.116952385000033],
            [34.985688533000115, 35.005853385000037],
            [34.977785533, 34.992191385000027],
            [34.977816533000151, 34.990870385000065],
            [34.976469533000085, 34.958651384000021],
            [34.967585533000175, 34.854512384000017],
            [34.965692911000161, 34.832821350000032],
            [34.937556027000113, 34.764241450000114],
            [34.914158533000062, 34.70721238400013],
            [34.90604661800009, 34.687150944999971],
            [34.889286016000113, 34.645698903],
            [34.858769821000095, 34.597485490999972],
            [34.840282711000071, 34.568281261000081],
            [34.833100532000032, 34.556935384000113],
            [34.774683532000125, 34.464978384000048],
            [34.740618359000081, 34.411474141000028],
            [34.703588994000057, 34.360133467000068],
            [34.585009914000153, 34.254018275000149],
            [34.286152735000059, 33.986427566000103],
            [34.285473495000105, 33.98593470600018],
            [34.283033764000123, 33.98416429600006],
            [34.23403891950025, 33.95687760050005],
            [34.228671241000171, 33.952291938000016],
            [34.222623326000161, 33.94713820600002],
            [34.212225532000133, 33.938277384000159],
            [34.142507385000073, 33.879399504999967],
            [34.074072864000101, 33.8216053310001],
            [34.073465080500029, 33.821041616000159],
            [34.063677532000185, 33.812171383000148],
            [34.031760134000052, 33.783286357000108],
            [34.028669749500096, 33.780489553500004],
            [33.985652714000111, 33.746607077000149],
            [33.955501789000124, 33.72021717899996],
            [33.911898280000202, 33.682127878000145],
            [33.901892532000119, 33.673387383000104],
            [33.859632532, 33.623899383000136],
            [33.848377716000215, 33.612754131000074],
            [33.847623296000137, 33.613787357000021],
            [33.749291179000153, 33.530982881999975],
            [33.716666667000169, 33.5],
            [33.600000000000108, 33.407500000000127],
            [33.450000000000188, 33.282222221999987],
            [33.283333333000058, 33.156944444000132],
            [33.166666667000101, 33.056111111000163],
            [33.083333333000184, 32.993611111000135],
            [33.01666666700001, 32.934166667000042],
            [32.983333333000161, 32.90972222200007],
            [32.979298078000141, 32.904678153000063],
            [32.967655051800108, 32.89011422760008],
            [32.516666667000123, 33.011111111000091],
            [32.022222222000067, 33.12222222200019],
            [31.608333333000161, 33.191666666999978],
            [31.116666667000089, 33.355555556000112],
            [30.611111111000156, 33.511111110999977],
            [30.475000000000108, 33.566666667000177],
            [30.083333333000127, 33.750000000000128],
            [29.952283712000309, 33.811342376000212],
            [30.224294528000115, 34.425481384],
            [30.27107352800013, 34.472379384],
            [30.397378528000075, 34.599004384000054],
            [30.478648528000093, 34.687190384000033],
            [30.784057529000194, 35.019716385000081],
            [31.00558452900006, 35.265310385000063],
            [31.07405252900017, 35.34266338500008],
            [31.371011529000185, 35.682789385000021],
            [31.471074529000106, 35.68201438500013],
            [31.534384529000164, 35.681191384999963],
            [31.88336053000009, 35.662741385000103],
            [31.986997530000195, 35.656548385000079],
            [32.04210153, 35.652639385000029],
            [32.087475530000091, 35.649349385000036],
            [32.186708530000175, 35.639799384999989],
            [32.248600530000061, 35.633486384999983],
            [32.253312530000045, 35.633869385000068],
            [32.295580530000194, 35.636414385000151],
            [32.359200530000095, 35.636041385000098],
            [32.417833530000081, 35.631032384999983],
            [32.590314530000143, 35.675176385000114],
            [32.616654530000034, 35.681241385000121],
            [32.79084853100008, 35.705441385000128],
            [32.98504753100022, 35.731433385000159],
            [33.069549531000035, 35.718691385000156],
            [33.101725531000142, 35.71379938500003],
            [33.143725531000058, 35.715579385000055],
            [33.260925531000026, 35.719802384999966],
            [33.263032531000107, 35.720222384999985],
            [33.306255531000119, 35.72871838500005],
            [33.423665531000125, 35.718828385000123],
            [33.431052531, 35.718191385000168],
            [33.494301531000104, 35.730495385],
            [33.504815531000162, 35.731067385000173],
            [33.675870531000015, 35.764488385000092],
            [33.710983531000039, 35.770673385000165],
            [33.781615531000199, 35.774015385],
            [33.849076532000112, 35.798061384999968],
            [33.927593532000145, 35.841066385],
            [33.961204532000153, 35.859174385000173],
            [33.993544532000072, 35.873095385000127],
            [34.140630532000131, 35.906056385000099],
            [34.141450532000107, 35.906512384999971],
            [34.150431532000056, 35.911447385000102],
            [34.17510453200012, 35.924837385000089],
            [34.274956532000175, 35.978659385],
            [34.313400532000202, 35.998670385000068],
            [34.353824532000118, 36.012318385000171],
            [34.415067532000222, 36.046665385999958],
            [34.653565532000101, 36.179617386000118],
            [34.693808532000133, 36.19437838600011],
            [34.710235532000212, 36.199084386000109],
            [34.73997753200004, 36.207549386],
            [34.827918532000155, 36.191090386000113],
            [34.90145053300003, 36.162357386000124],
            [34.9456915330002, 36.141560386000023],
            [35.159257533000215, 36.014479385],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CZE", Country: "Czech Republic" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.828336184750555, 50.865827121800251],
            [14.966663236155568, 50.859164179309332],
            [15.003891123937194, 50.867500149678605],
            [15.019163286841547, 50.950554081543416],
            [15.016391223815674, 50.974091136277977],
            [15.176945070847268, 51.014718053444781],
            [15.239445067325761, 50.991945093005782],
            [15.27527317727305, 50.975000069860883],
            [15.290418102887031, 50.948953976222739],
            [15.272500108418768, 50.924509161371574],
            [15.282773304255471, 50.892082092466055],
            [15.311663210010948, 50.860345021831947],
            [15.362845122599964, 50.840618045015432],
            [15.379718229015424, 50.779445071451519],
            [15.488336127446246, 50.786664237038522],
            [15.606663287266514, 50.772773244143295],
            [15.787500166572926, 50.74416413214486],
            [15.946945087811372, 50.68943600395049],
            [16.024436118160935, 50.630000096005475],
            [16.071391204604851, 50.635554112703048],
            [16.118054097903183, 50.657800186707036],
            [16.137500113324563, 50.656109053917575],
            [16.237500241800404, 50.670554090614402],
            [16.332009217852828, 50.664027102593408],
            [16.370000194101635, 50.644164171306997],
            [16.430273286538153, 50.601527106115],
            [16.447363316608374, 50.578818183416459],
            [16.406663309245744, 50.523044999531137],
            [16.358918144606321, 50.497909180580351],
            [16.315000156967471, 50.504718135824973],
            [16.207736103541379, 50.439018096343275],
            [16.219027197702445, 50.410273197513092],
            [16.266109186160776, 50.389718088660473],
            [16.303054100890307, 50.378053999807022],
            [16.371945130919897, 50.361109144300102],
            [16.441391210579297, 50.316664270226326],
            [16.458609148492229, 50.303600068262355],
            [16.548054113907966, 50.227082176874134],
            [16.562218189209574, 50.208191211082649],
            [16.565973281842673, 50.170345074121158],
            [16.587773270958337, 50.140000069970299],
            [16.614436105033974, 50.119718043523861],
            [16.640000239238134, 50.10889113916194],
            [16.715345167092778, 50.098327091284219],
            [16.786663261012507, 50.140545061316303],
            [16.802936055520917, 50.169654070021338],
            [16.811245036161949, 50.179436086330682],
            [16.846945070628436, 50.201800177531638],
            [16.911391177391494, 50.222354112917785],
            [16.968609233750129, 50.222773208077783],
            [17.002218151006673, 50.216945103145619],
            [16.972773195621357, 50.310000154269048],
            [16.937500135303793, 50.340554035447482],
            [16.8687450597441, 50.411182131095885],
            [16.890973196475272, 50.438673097207513],
            [16.910827075306287, 50.440127022136508],
            [16.941391182406704, 50.43500014722575],
            [17.057782290226129, 50.41055399127022],
            [17.118054041558196, 50.39666417184138],
            [17.20527327639843, 50.36097318983029],
            [17.226945022395199, 50.345273214586499],
            [17.283609202590497, 50.32027318246746],
            [17.378327055670496, 50.279445099624027],
            [17.433054010398479, 50.270273118229639],
            [17.626800194097541, 50.265545054273204],
            [17.661109167909188, 50.272354009517713],
            [17.693473204902574, 50.300000208476533],
            [17.69700919458603, 50.32027318246746],
            [17.724445175412711, 50.319018076282518],
            [17.753054119772997, 50.297773136796579],
            [17.762918111095388, 50.233327030033522],
            [17.763682205391063, 50.209373227071737],
            [17.695273302433606, 50.179027049454575],
            [17.642773251747514, 50.171945011803771],
            [17.606318175440862, 50.162700107851421],
            [17.657773170436087, 50.10805395467041],
            [17.840136059038741, 49.989582123201004],
            [17.876245130381449, 49.978954037582852],
            [17.920691177921867, 49.97736415818413],
            [18.009445139237783, 50.011109197548549],
            [18.05316330230437, 50.055927066314794],
            [18.094163214163444, 50.038053998847701],
            [18.270273202980007, 49.957773135837343],
            [18.350273272233125, 49.938891222501255],
            [18.55270914261655, 49.922427152962015],
            [18.578745177970887, 49.912218162503834],
            [18.579345154601839, 49.814991102882104],
            [18.634163304436555, 49.737782207394261],
            [18.786945115546132, 49.681936100951233],
            [18.810827169416342, 49.673327048175693],
            [18.83944516623211, 49.594991121983597],
            [18.847773257612545, 49.554164212606551],
            [18.852218180878793, 49.52777311983256],
            [18.851245041917394, 49.517354078880246],
            [18.792773220478296, 49.513882126937247],
            [18.655554089038134, 49.504164148368446],
            [18.565900246594737, 49.493673190686778],
            [18.544373172247191, 49.461800165582744],
            [18.383191185389848, 49.333053964810162],
            [18.312500225467403, 49.305691074179421],
            [18.275691265207655, 49.300973236144969],
            [18.211318081002275, 49.286382186694411],
            [18.176245180533158, 49.244300171132252],
            [18.17416328341659, 49.210273164544205],
            [18.158118141399143, 49.164718023572547],
            [18.145282262478503, 49.14500009921143],
            [18.149445050883145, 49.121391127747401],
            [18.12194503231612, 49.082773185139231],
            [18.075282139017702, 49.041945102295685],
            [17.911109153822906, 48.984991075887777],
            [17.841945041387106, 48.928327063330627],
            [17.760273285360086, 48.878191212260802],
            [17.712218157940356, 48.856109088283361],
            [17.677500147252601, 48.851945126412303],
            [17.650273211091758, 48.851945126412303],
            [17.502782224919372, 48.838882097914833],
            [17.411245136465311, 48.824718022613311],
            [17.382218102773436, 48.825273239881213],
            [17.33082714551864, 48.850273104362159],
            [17.23832714402505, 48.872427145068983],
            [17.187909158093504, 48.869445031548864],
            [17.112218225275001, 48.831800060264115],
            [17.041527097714464, 48.768327092462371],
            [17.022500177453082, 48.743609195205025],
            [16.988327158111332, 48.681664248356427],
            [16.946182278275188, 48.619064171953752],
            [16.917918165412487, 48.703191155065909],
            [16.892500211600009, 48.725827155206687],
            [16.871936050291907, 48.725482156071124],
            [16.832918123263113, 48.716245131107897],
            [16.785554167581182, 48.719854043349116],
            [16.711663332293597, 48.7463820964213],
            [16.658818114833792, 48.785273121435594],
            [16.540554154563637, 48.812354044842735],
            [16.458263311166263, 48.811245119049588],
            [16.430827162701576, 48.782218085357712],
            [16.412982258429281, 48.751873248844916],
            [16.382154121377965, 48.736036145664841],
            [16.333054105905518, 48.733054032144807],
            [16.103336146438238, 48.75000006111803],
            [16.081109183173567, 48.754718066790574],
            [16.060554074320862, 48.760273256954704],
            [16.021663216944631, 48.777218112461455],
            [15.964163193362396, 48.804718131028594],
            [15.849427176148595, 48.85970006325185],
            [15.746391122334074, 48.858609074731518],
            [15.685973190610241, 48.864018084503797],
            [15.585000258448986, 48.899164242806947],
            [15.564436097140941, 48.909164188599348],
            [15.546663277236092, 48.912218051210758],
            [15.514445085358432, 48.919991092961297],
            [15.451663289294402, 48.951109076225137],
            [15.39250012847944, 48.973609121896018],
            [15.359027165692908, 48.984718161119659],
            [15.301391187640746, 48.987782082014959],
            [15.156663212038865, 48.999718079808119],
            [15.065554103562079, 49.01471816613487],
            [15.028609188832462, 49.018745000069615],
            [15.00416320051491, 49.011391221117165],
            [14.979582263555869, 48.979927065251175],
            [14.985000158145539, 48.939164193614474],
            [14.958891200233353, 48.794718017598186],
            [14.885554073471013, 48.782773134987522],
            [14.849445169766511, 48.786382214866919],
            [14.823745081092341, 48.785064244407934],
            [14.727218078025913, 48.693464124041796],
            [14.716663250241766, 48.654300184259299],
            [14.719445036275374, 48.604018152797678],
            [14.705418088910136, 48.585136071823499],
            [14.700282161544095, 48.581382152656872],
            [14.654445053348809, 48.608473134347818],
            [14.61194511609321, 48.628464141115202],
            [14.50555412170425, 48.628327013178833],
            [14.446391128527353, 48.618891169471723],
            [14.390273280782537, 48.592500076697931],
            [14.27832709622956, 48.58250013090553],
            [14.184163286950962, 48.596109156577242],
            [14.051391258071959, 48.669791114837139],
            [13.998609240162324, 48.714027111883425],
            [13.883609192997852, 48.763609086789742],
            [13.858054111248975, 48.770553996504262],
            [13.833609128759832, 48.773609032582144],
            [13.818263205107201, 48.779854053379538],
            [13.812009131854524, 48.800209170021887],
            [13.780136106750405, 48.834018079488672],
            [13.761663230290537, 48.845964135565723],
            [13.745627140728516, 48.870754117190629],
            [13.728127067953864, 48.881318165068151],
            [13.699445033397666, 48.879300138053949],
            [13.672218097236822, 48.889445090771673],
            [13.65194512324581, 48.904436124642913],
            [13.62610924773989, 48.94763611845373],
            [13.595345148429033, 48.957427019580379],
            [13.564827141796229, 48.973664274819143],
            [13.509718139920466, 48.972500196102871],
            [13.402545113962958, 49.006191088372574],
            [13.396109153410833, 49.049164267882801],
            [13.318473283773955, 49.100827134077278],
            [13.173054136434246, 49.167218177658796],
            [13.031109120332616, 49.28777324734925],
            [12.959027099755247, 49.334927152537048],
            [12.887200224311329, 49.34038209513885],
            [12.797218146538711, 49.339854035237451],
            [12.6744450576237, 49.425000090140443],
            [12.597773274492738, 49.527500205064442],
            [12.578609226294958, 49.560554072691062],
            [12.554718119969237, 49.616664209084959],
            [12.515482263457187, 49.677218095278604],
            [12.47610911137096, 49.690827120950317],
            [12.45555400251834, 49.695545126622946],
            [12.468473197556818, 49.783473135197639],
            [12.545973280361864, 49.909582221585865],
            [12.491391164921112, 49.975827084775389],
            [12.471109138474787, 49.985836083023301],
            [12.429445044606041, 49.99389109199744],
            [12.307500255365227, 50.047491183659261],
            [12.265554026634874, 50.066109067044621],
            [12.20614510841807, 50.106209097776329],
            [12.193818178659711, 50.136318067533111],
            [12.209163264122026, 50.163609041434412],
            [12.122082163046485, 50.308464254326779],
            [12.093700200624795, 50.322536128693159],
            [12.182636049240131, 50.314582205471581],
            [12.281109162591321, 50.218464239281346],
            [12.323054050217195, 50.206664195957998],
            [12.325554036665181, 50.2361091513432],
            [12.398609196203978, 50.309164143243819],
            [12.486109224801254, 50.373054026910765],
            [12.515418225716843, 50.392491157514755],
            [12.620973209080745, 50.411945051925059],
            [12.671391195012092, 50.411945051925059],
            [12.740418179511579, 50.431518136999031],
            [12.772218114419672, 50.445000093018152],
            [12.825136086799262, 50.4530450437084],
            [12.855836148369605, 50.442218139346565],
            [12.898336085625203, 50.425273116201552],
            [12.917218166599355, 50.414718120779341],
            [12.944718185166408, 50.410000115106797],
            [12.98555414699905, 50.418327033020645],
            [12.991945180550033, 50.421109154330523],
            [13.015273190618956, 50.43930006356679],
            [13.079445041509501, 50.498054019867581],
            [13.187218211735882, 50.502773199006683],
            [13.206109177527452, 50.520409058613254],
            [13.22322720315438, 50.551418077135409],
            [13.24958225374445, 50.589164134172833],
            [13.378336165867978, 50.629435993920069],
            [13.414863326542076, 50.611382212620086],
            [13.436663315657938, 50.605273146292618],
            [13.463400245757725, 50.601664234051398],
            [13.518473205449737, 50.641873229524677],
            [13.510827065713613, 50.650554031391621],
            [13.541109205590516, 50.689718138812097],
            [13.582218082191275, 50.709445115628696],
            [13.634718132877367, 50.72221813027538],
            [13.669445028382455, 50.725827042516798],
            [13.734163211723484, 50.730827015412856],
            [13.891109152342068, 50.768882197040185],
            [13.903609084582655, 50.78819108452528],
            [13.990691191486491, 50.814445049362789],
            [14.155554007314635, 50.843045108905656],
            [14.31139118977805, 50.88221810114355],
            [14.368327111275079, 50.935000119053356],
            [14.321245122816748, 50.946109158276883],
            [14.258054122238633, 50.995136083553589],
            [14.285554140805772, 51.037500066339263],
            [14.306245036490196, 51.052491100210418],
            [14.491663296446916, 51.03666422295224],
            [14.569163211614068, 51.005554118677495],
            [14.59721811217284, 50.982500196843191],
            [14.628336095436595, 50.923609112606002],
            [14.63139113151459, 50.881100122895006],
            [14.619236198409851, 50.858254072260067],
            [14.651663267315286, 50.847218123232281],
            [14.717782234318804, 50.829164174294235],
            [14.739718177904365, 50.823882234174462],
            [14.771527165268026, 50.818327044010303],
            [14.802918230938076, 50.819718104665142],
            [14.825000187277368, 50.85638222563766],
            [14.828336184750555, 50.865827121800251],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "DEU", Country: "Germany" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [8.710254114573786, 47.696809062677289],
              [8.678591139963885, 47.6933449897233],
              [8.673954103476319, 47.702854091264328],
              [8.70548212944459, 47.711036002252854],
              [8.710254114573786, 47.696809062677289],
            ],
          ],
          [
            [
              [4.750000000000114, 55.404166666000151],
              [5.15, 55.249999999000124],
              [5.403333333000035, 55.249999999000124],
              [5.750000000000114, 55.511194444000182],
              [7.552666667000267, 55.167611110000053],
              [7.906829495000181, 55.118597622000095],
              [8.301944444000128, 55.063916666000182],
              [8.338593570000228, 55.070200230000125],
              [8.373813570000266, 55.083747105000214],
              [8.397452783000119, 55.085323052000149],
              [8.41445044500017, 55.086456230000067],
              [8.449669445000097, 55.075619230000143],
              [8.471343570000101, 55.056655230000104],
              [8.501649445000226, 55.036279230000105],
              [8.514502570000275, 55.027637230000096],
              [8.610274499000241, 54.954522527000165],
              [8.664545163751711, 54.913090919717092],
              [8.692773152538336, 54.895827069073448],
              [8.719445039069171, 54.891109063400904],
              [8.75889111371356, 54.898053973115225],
              [8.785827197833555, 54.906109149727342],
              [8.83509116333255, 54.913791164009126],
              [8.92000017801297, 54.908054086545746],
              [8.941109163029068, 54.905545047642136],
              [9.168891238133597, 54.865000105488605],
              [9.228891248164018, 54.849582097468442],
              [9.230836184982195, 54.829300071022033],
              [9.248336090118926, 54.812636009272524],
              [9.350836037404861, 54.810554112155955],
              [9.377218077723057, 54.827636095598962],
              [9.414718209720775, 54.833327072594955],
              [9.445354065934623, 54.825400139076294],
              [9.444767731987383, 54.823671778004439],
              [9.44712832100015, 54.825561854000057],
              [9.449841696000192, 54.826368604000209],
              [9.455094821000102, 54.830402604000199],
              [9.46437432100015, 54.831689979000032],
              [9.46657569600012, 54.833433854000191],
              [9.475819133500181, 54.835184791500069],
              [9.48275544600034, 54.836766729000146],
              [9.484784071000263, 54.836385104000101],
              [9.492992446000244, 54.838216229000182],
              [9.494406821000098, 54.839098729],
              [9.498396321000143, 54.844133854000177],
              [9.499517571000354, 54.844884604000129],
              [9.501053633500135, 54.845282541500126],
              [9.514772696000193, 54.849875729000189],
              [9.515532946000064, 54.849976479000134],
              [9.521907570999986, 54.853863729000068],
              [9.525028946000248, 54.857610604000229],
              [9.525402821000171, 54.858454979000157],
              [9.53249169600025, 54.862762104000126],
              [9.536214446000088, 54.86373260400012],
              [9.538170571000194, 54.865005979000045],
              [9.5421871960001, 54.866282979000061],
              [9.546790071000373, 54.866279979000126],
              [9.558072321, 54.866460479000182],
              [9.56051744600029, 54.867653854000196],
              [9.561654571000304, 54.868929479000144],
              [9.572968446000175, 54.873507104000112],
              [9.573748571000351, 54.874397229000124],
              [9.576320321000139, 54.875449604000096],
              [9.578095571000119, 54.877252604000034],
              [9.580192196000326, 54.882492729000091],
              [9.592083321000359, 54.890749604000177],
              [9.593382946000304, 54.892705979000141],
              [9.597090446000323, 54.893411729000093],
              [9.602871446000279, 54.891195104000076],
              [9.607507571000269, 54.891174479000085],
              [9.609353196000086, 54.890439729000207],
              [9.613318571000207, 54.890472604000138],
              [9.614288237056599, 54.890083471015487],
              [9.621897630943181, 54.861977239984753],
              [9.619411571, 54.854007979000301],
              [9.626658946000248, 54.850588354000251],
              [9.627471821000086, 54.849936729000035],
              [9.640280196000333, 54.844337604000145],
              [9.643245321000109, 54.844059979000178],
              [9.650915321000184, 54.840732229000196],
              [9.653464446000157, 54.840475229000049],
              [9.654259446000253, 54.840630229000112],
              [9.664732072000049, 54.839510229000155],
              [9.66926594700027, 54.840388604000054],
              [9.676318697000113, 54.83966097900003],
              [9.677717947000076, 54.839899729000194],
              [9.683565322000106, 54.83929660400014],
              [9.684947947000182, 54.83876522900016],
              [9.692074884500073, 54.8379391040001],
              [9.697374822000143, 54.833524104000162],
              [9.703779947000328, 54.830544729000167],
              [9.710290072000305, 54.829702854],
              [9.71337719700017, 54.828269729],
              [9.714748322000048, 54.827097104000075],
              [9.719667322000078, 54.824924354000075],
              [9.729232822000142, 54.824138229000141],
              [9.730656072000158, 54.823526479000122],
              [9.740287321999972, 54.822796354000218],
              [9.741401072000315, 54.82305022900016],
              [9.75006657199998, 54.822288604000107],
              [9.753811697000174, 54.823049104000177],
              [9.759579822000035, 54.822621354000148],
              [9.779670384500264, 54.826493291500185],
              [9.795942947000185, 54.835970729000053],
              [9.800886447000323, 54.838265104000243],
              [9.810962197000151, 54.844299104000186],
              [9.81257244700015, 54.844006604000299],
              [9.836167072000251, 54.8348542290002],
              [9.84719407200032, 54.824231479000218],
              [9.851083134500243, 54.821877854000093],
              [9.852390697000317, 54.821061229000065],
              [9.857403072000352, 54.822378479000179],
              [9.864987947000259, 54.823710104000156],
              [9.893103447000044, 54.831207603999985],
              [9.899951947000034, 54.832034729000156],
              [9.900903322000147, 54.832011979000015],
              [9.911781072000281, 54.830145229000152],
              [9.914749822000033, 54.829144479000092],
              [9.924146072000156, 54.827453979000211],
              [9.930350197000053, 54.825273729000116],
              [9.936574947000111, 54.824152729000105],
              [9.942374822000147, 54.822129729000039],
              [9.951030447000335, 54.820552353999972],
              [9.954605947000232, 54.819329354000047],
              [9.959164822000048, 54.818482479000068],
              [9.961333947000355, 54.818384729000172],
              [9.97133494700023, 54.816505229000086],
              [9.975299572000097, 54.815193729000271],
              [9.981336197000132, 54.813924354000108],
              [10.000536572000271, 54.807297479000113],
              [10.005654196999956, 54.804981041500128],
              [10.016409572000214, 54.802118479000228],
              [10.035263697000289, 54.798614854000164],
              [10.047015822, 54.795560229000102],
              [10.052566322000246, 54.792143104000047],
              [10.0557080720003, 54.78993310400017],
              [10.061233822000304, 54.785538479000081],
              [10.067486072000179, 54.781050979000042],
              [10.071563947000129, 54.779816104000076],
              [10.076096697000196, 54.778925729000036],
              [10.160589747000301, 54.777359186000183],
              [10.218335659000218, 54.756669435666765],
              [10.268861111000206, 54.713805555000192],
              [10.30830555600005, 54.674888888000169],
              [10.355111111000213, 54.633305555000049],
              [10.374333333000322, 54.620944444000031],
              [10.454416667000089, 54.599111110000081],
              [10.532916667000336, 54.576944444000105],
              [10.613888889000293, 54.551666666000074],
              [10.660361111000327, 54.544388888000299],
              [10.733055556000238, 54.546999999000093],
              [10.800583333000247, 54.581194444000062],
              [10.873638889000119, 54.619499999000141],
              [10.904250000000275, 54.637388888000146],
              [11.005750000000319, 54.641194444000064],
              [11.075000000000102, 54.637861110000131],
              [11.157833333000326, 54.622138888000109],
              [11.208583333000092, 54.609166666000078],
              [11.260111111000072, 54.586444444000108],
              [11.321583333000262, 54.569888888000037],
              [11.384666667000147, 54.532499999000265],
              [11.443500000000199, 54.498083332000192],
              [11.513861111000153, 54.464833332000126],
              [11.581972222000275, 54.429916666000167],
              [11.63672222200006, 54.393333332000168],
              [11.672416667000164, 54.365749999000116],
              [11.670750000000226, 54.36483333200016],
              [11.940444444000207, 54.366805555000212],
              [12.112083333000157, 54.411083332000146],
              [12.44325, 54.687749999000175],
              [12.749972222000281, 54.76380555500009],
              [12.934, 54.83380555500014],
              [13.147958642000106, 55.008304681000197],
              [13.785666670000296, 55.021361109000281],
              [13.992836471111389, 54.962441875777813],
              [14.057500208000022, 54.908558709000147],
              [14.091796326000292, 54.87998037500023],
              [14.172777778000182, 54.812499999000124],
              [14.414166590000093, 54.812499999000124],
              [14.414166666500137, 54.658333568000103],
              [14.422743681000043, 54.653627322000091],
              [14.636722379600201, 54.536222170600183],
              [14.688589920999959, 54.519636768000055],
              [14.715146243000277, 54.511144997000088],
              [14.724682930000199, 54.508095502000117],
              [14.75, 54.499999999000067],
              [14.599677665500167, 54.383333234500071],
              [14.475184985000112, 54.275448150000102],
              [14.473029813000153, 54.273580482000114],
              [14.356609203000119, 54.172690636000084],
              [14.352222222000137, 54.168888887999969],
              [14.348425844000246, 54.167490340000029],
              [14.322594686000116, 54.157974401000047],
              [14.287376130000041, 54.145016508000026],
              [14.239372529000036, 54.127354638000099],
              [14.210894346000202, 54.127636388000155],
              [14.204560825999977, 54.127699011000061],
              [14.229578889000095, 54.040173932000016],
              [14.244122222000215, 53.989294444],
              [14.234770917000162, 53.954503273000057],
              [14.227994443999989, 53.929291666000097],
              [14.225554304328, 53.928600077354872],
              [14.215827099751266, 53.89999108728955],
              [14.218891042922792, 53.869018121261718],
              [14.21974283600008, 53.863177978000124],
              [14.219888347000165, 53.862180978000026],
              [14.274491347000207, 53.706904977000022],
              [14.275627236733442, 53.699064048058688],
              [14.309718161899809, 53.555544989597479],
              [14.413263332514589, 53.3389540963679],
              [14.412773158815639, 53.304445130346039],
              [14.39169116352781, 53.144164198082478],
              [14.380273167352243, 53.110136018028129],
              [14.347363300822565, 53.048882242917159],
              [14.293736051794212, 53.018773105522314],
              [14.225418176305539, 52.988882232886795],
              [14.200282189716688, 52.984718103377645],
              [14.168891124046638, 52.973327096930404],
              [14.14763612627695, 52.959236111824737],
              [14.149163309039807, 52.862782198954164],
              [14.205418117082729, 52.818609066182091],
              [14.355000220465001, 52.748327143135725],
              [14.383336082419078, 52.730827070360817],
              [14.448327180528338, 52.675282209518855],
              [14.478745107236932, 52.652500196624317],
              [14.514445141703334, 52.639164253358899],
              [14.554582220447344, 52.627218029643757],
              [14.595782292155036, 52.606827038455776],
              [14.639582262596718, 52.572982086805268],
              [14.633536060543236, 52.490554115471411],
              [14.585136101625892, 52.439853994678174],
              [14.563191273222913, 52.43319121982546],
              [14.543954134829391, 52.421873135936153],
              [14.534445033288364, 52.396245131629541],
              [14.57986321396163, 52.288327122115561],
              [14.598891140051308, 52.272773159625672],
              [14.655836114003932, 52.260273227385312],
              [14.692500234976251, 52.25443606999761],
              [14.707918075358407, 52.245618140194381],
              [14.760763292817956, 52.069864214625738],
              [14.717554078913679, 51.943109051139118],
              [14.689863288229958, 51.89694504871909],
              [14.645418078879914, 51.865273189291713],
              [14.610136133745016, 51.848191205848707],
              [14.600973204805882, 51.820064220922461],
              [14.667982162291253, 51.723882216991512],
              [14.698054084035846, 51.702218182345632],
              [14.722082150659844, 51.690964136196612],
              [14.756109157247863, 51.66673607736243],
              [14.759236110055156, 51.607491109172258],
              [14.715000113009125, 51.554718143717935],
              [14.739445095498183, 51.526527121051117],
              [14.83750011951787, 51.498600128335056],
              [14.921109101012291, 51.481945119040859],
              [14.953054042845793, 51.469991183974855],
              [14.975554088516674, 51.440827022346639],
              [14.981836157326143, 51.368045112852201],
              [14.971663209051826, 51.35721804085216],
              [14.983336182722667, 51.33360906938799],
              [15.002636185390372, 51.316800168351222],
              [15.033818206394585, 51.286664208866171],
              [15.032218101073795, 51.239164131076166],
              [14.99444505430813, 51.118609061385598],
              [14.978891259456248, 51.078327143354556],
              [14.965554142724329, 51.052218017804307],
              [14.931109214442813, 51.002491203610475],
              [14.896945079918396, 50.959445101542542],
              [14.826182203266569, 50.883054112168736],
              [14.828336184750583, 50.865827121800294],
              [14.825000187277453, 50.856382225637731],
              [14.802918230938076, 50.819718104665242],
              [14.771527165268026, 50.818327044010289],
              [14.739718177904479, 50.823882234174505],
              [14.717782234318804, 50.829164174294306],
              [14.651663267315314, 50.847218123232267],
              [14.619236198409851, 50.858254072260138],
              [14.631391131514704, 50.881100122895077],
              [14.628336095436509, 50.923609112606073],
              [14.597218112172925, 50.982500196843148],
              [14.569163211614182, 51.00555411867748],
              [14.49166329644703, 51.036664222952197],
              [14.306245036490111, 51.052491100210432],
              [14.285554140805857, 51.037500066339248],
              [14.258054122238661, 50.995136083553632],
              [14.321245122816777, 50.946109158276954],
              [14.368327111275107, 50.935000119053313],
              [14.311391189778192, 50.882218101143565],
              [14.15555400731472, 50.843045108905642],
              [13.990691191486519, 50.814445049362803],
              [13.90360908458257, 50.788191084525295],
              [13.89110915234221, 50.768882197040284],
              [13.734163211723569, 50.730827015412842],
              [13.669445028382484, 50.725827042516869],
              [13.634718132877481, 50.722218130275451],
              [13.582218082191275, 50.709445115628796],
              [13.541109205590544, 50.689718138812054],
              [13.510827065713613, 50.650554031391664],
              [13.518473205449766, 50.641873229524663],
              [13.463400245757725, 50.601664234051384],
              [13.436663315657938, 50.605273146292632],
              [13.414863326542218, 50.611382212620072],
              [13.378336165867893, 50.629435993920083],
              [13.24958225374445, 50.58916413417279],
              [13.223227203154408, 50.551418077135509],
              [13.206109177527537, 50.520409058613325],
              [13.18721821173591, 50.502773199006697],
              [13.07944504150953, 50.498054019867652],
              [13.015273190618984, 50.439300063566748],
              [12.991945180550033, 50.421109154330566],
              [12.985554146999164, 50.418327033020716],
              [12.944718185166494, 50.410000115106754],
              [12.917218166599469, 50.414718120779298],
              [12.898336085625317, 50.425273116201538],
              [12.855836148369633, 50.442218139346551],
              [12.825136086799375, 50.453045043708414],
              [12.772218114419672, 50.445000093018223],
              [12.740418179511607, 50.431518136999102],
              [12.67139119501212, 50.411945051925159],
              [12.62097320908066, 50.411945051925159],
              [12.515418225716928, 50.392491157514712],
              [12.486109224801282, 50.373054026910836],
              [12.398609196204006, 50.309164143243891],
              [12.325554036665096, 50.236109151343214],
              [12.323054050217195, 50.206664195957956],
              [12.281109162591235, 50.218464239281388],
              [12.182636049240273, 50.314582205471595],
              [12.09370020062488, 50.322536128693116],
              [12.1220821630464, 50.308464254326793],
              [12.209163264122026, 50.16360904143437],
              [12.193818178659797, 50.136318067533182],
              [12.206145108418184, 50.106209097776286],
              [12.265554026634902, 50.066109067044636],
              [12.307500255365312, 50.047491183659247],
              [12.429445044606155, 49.993891091997398],
              [12.471109138474787, 49.985836083023287],
              [12.491391164921026, 49.97582708477546],
              [12.545973280361977, 49.909582221585822],
              [12.468473197556818, 49.78347313519771],
              [12.455554002518454, 49.69554512662296],
              [12.476109111370988, 49.690827120950416],
              [12.515482263457216, 49.677218095278704],
              [12.554718119969266, 49.616664209085002],
              [12.578609226294873, 49.560554072691161],
              [12.597773274492852, 49.527500205064541],
              [12.674445057623814, 49.425000090140429],
              [12.797218146538796, 49.339854035237522],
              [12.887200224311357, 49.340382095138921],
              [12.95902709975536, 49.334927152537148],
              [13.031109120332644, 49.287773247349321],
              [13.173054136434246, 49.167218177658754],
              [13.31847328377404, 49.100827134077292],
              [13.396109153410919, 49.049164267882759],
              [13.402545113962958, 49.006191088372645],
              [13.509718139920494, 48.972500196102828],
              [13.564827141796343, 48.9736642748191],
              [13.595345148429033, 48.95742701958045],
              [13.626109247739919, 48.947636118453772],
              [13.651945123245923, 48.904436124642984],
              [13.672218097236851, 48.88944509077163],
              [13.699445033397751, 48.879300138053964],
              [13.728127067953892, 48.881318165068251],
              [13.745627140728629, 48.8707541171907],
              [13.761663230290651, 48.845964135565737],
              [13.780136106750433, 48.834018079488715],
              [13.812009131854609, 48.800209170021844],
              [13.818263205107314, 48.779854053379495],
              [13.833609128759861, 48.773609032582158],
              [13.814718162968347, 48.738054005041135],
              [13.825418165315966, 48.631109134488611],
              [13.803745078214661, 48.582636085375498],
              [13.726000243836154, 48.51559108570649],
              [13.676945155364876, 48.532291189639807],
              [13.65471819210029, 48.551664114865275],
              [13.602773191043809, 48.568054088380393],
              [13.518609159919663, 48.584582195660175],
              [13.443227184052887, 48.560236119628712],
              [13.457500224095895, 48.515554037694358],
              [13.43791808656681, 48.429436017296382],
              [13.421873279825434, 48.391036004171326],
              [13.395000227617629, 48.366100177431008],
              [13.335000217587378, 48.328609097888773],
              [13.313054048079891, 48.315827030786636],
              [13.28527323575554, 48.305273208830897],
              [13.258054010945671, 48.298882175279971],
              [13.154445137866389, 48.292218059322579],
              [13.017773177067227, 48.259436100635753],
              [12.871245103934257, 48.202909048376853],
              [12.759718182179256, 48.12172713077257],
              [12.766245170200079, 48.082218024216388],
              [12.79916325099498, 48.054582218817472],
              [12.837500232208015, 48.030000108392017],
              [12.857045154087018, 48.011936101170079],
              [12.865345082272654, 47.994600146060066],
              [12.918891194477851, 47.957773248527474],
              [12.966663348845657, 47.906945052253377],
              [13.00889120952337, 47.854164207810243],
              [12.970554060672328, 47.810554003656762],
              [12.944163303174776, 47.788745129723679],
              [12.913954085855664, 47.725000085344163],
              [12.993745110443001, 47.714164128526591],
              [13.023054111358476, 47.724164241957283],
              [13.058336056493374, 47.70610911955265],
              [13.077500104691353, 47.68735410823092],
              [13.100136104832131, 47.642918118974535],
              [13.05333608359723, 47.497564182841643],
              [13.012500121764788, 47.469791081868493],
              [12.957873246961071, 47.477018126444477],
              [12.806663210339195, 47.554718033821928],
              [12.78819117206973, 47.583536022847966],
              [12.805973212068068, 47.614853998322076],
              [12.827845117913114, 47.621245031873173],
              [12.736945054101909, 47.682709192754317],
              [12.665836172486252, 47.685000134536551],
              [12.603400213748159, 47.672291157630468],
              [12.576391207070401, 47.644164172704052],
              [12.566663337855914, 47.641936095195604],
              [12.514445086755359, 47.633253952224095],
              [12.455000126354832, 47.667773144168109],
              [12.201109093338232, 47.657491063513987],
              [12.207318071951761, 47.633373142887706],
              [12.201554004760055, 47.612991204155151],
              [12.141391218169588, 47.61110913161076],
              [12.111109245930663, 47.614718043852179],
              [12.060000255899581, 47.62055402777338],
              [12.006391111782307, 47.624445074876405],
              [11.920000176616156, 47.614164167688784],
              [11.683054166744171, 47.584718038837082],
              [11.579863215358614, 47.524091062447269],
              [11.554445093908157, 47.515545041583891],
              [11.505282214161525, 47.509164233954778],
              [11.457500169148091, 47.512218096566244],
              [11.296391272486687, 47.428891082295422],
              [11.104027096986954, 47.39652704530198],
              [11.02860907893654, 47.395273112583595],
              [11.008336104945613, 47.395827156384826],
              [10.976663239689969, 47.400273253117746],
              [10.909445069900926, 47.518054080487445],
              [10.690836149657173, 47.553891075252238],
              [10.569445068941604, 47.54027316476305],
              [10.481800201056899, 47.586518136367999],
              [10.433682209363212, 47.5804821602365],
              [10.436391240477121, 47.52916412553941],
              [10.466182200826438, 47.482909095650768],
              [10.473745024444781, 47.437636089540717],
              [10.429718072064418, 47.396109123608596],
              [10.336663188578939, 47.31270013432453],
              [10.274445159324046, 47.288891170649947],
              [10.228891191818832, 47.27603601335187],
              [10.173336105055057, 47.274718042892857],
              [10.211663195622123, 47.322491203088987],
              [10.231736177402695, 47.373745032407442],
              [10.04305400434032, 47.49528212587694],
              [9.955000099579422, 47.539718115133326],
              [9.810973186361025, 47.594164108466146],
              [9.775418158820059, 47.591245026857905],
              [9.746245112374481, 47.565827073045398],
              [9.732791151911783, 47.541118060605584],
              [9.692527171153529, 47.529518177130626],
              [9.651509154383575, 47.529518177130626],
              [9.608773182733728, 47.529518177130626],
              [9.577918223592235, 47.537418120895552],
              [9.566727209355349, 47.540454046234061],
              [9.478318079536905, 47.575854008566068],
              [9.261100219948105, 47.662845088001404],
              [9.082636089799394, 47.685418056230048],
              [9.033336082116421, 47.688327079554313],
              [9.000836090653195, 47.680827120209926],
              [8.979445138413666, 47.670136002679783],
              [8.94777327898646, 47.657491063513987],
              [8.92597328987074, 47.651800086518051],
              [8.898891192997212, 47.650827115194772],
              [8.88330018249502, 47.654145007757052],
              [8.855836038473626, 47.698882242614502],
              [8.835282270725372, 47.71804511734581],
              [8.80548225792063, 47.736036034371807],
              [8.77437315947418, 47.715973110875041],
              [8.800136112422194, 47.681727001337379],
              [8.753336091187691, 47.693609187312063],
              [8.720218185820443, 47.696627007739949],
              [8.732254095899862, 47.708291096593314],
              [8.736454099954642, 47.757073102658296],
              [8.685554154589397, 47.786664238435606],
              [8.661391139323769, 47.798045018960863],
              [8.61166331930167, 47.803882176348509],
              [8.562918193610756, 47.806664130020408],
              [8.47708214043638, 47.770618090589778],
              [8.406945056677273, 47.701800150756128],
              [8.419027067224306, 47.673882210495549],
              [8.485418110805654, 47.647082080845507],
              [8.522082231778143, 47.651664132048325],
              [8.540554102409601, 47.664300186396588],
              [8.580554053217213, 47.66860915519311],
              [8.610254153735923, 47.667736096155977],
              [8.624963220383449, 47.656209135238839],
              [8.593054153095807, 47.600553968550571],
              [8.57641808690272, 47.591373102338764],
              [8.569427076720785, 47.598536109174631],
              [8.562918193610756, 47.618400046289537],
              [8.523473292432868, 47.632700076060871],
              [8.471245150686684, 47.60735403897786],
              [8.435836135899251, 47.573609167251448],
              [8.390836044557489, 47.579991148347005],
              [8.289100191567115, 47.609091104596644],
              [8.240836187119669, 47.619436049524722],
              [8.19576334085798, 47.620344983107714],
              [8.101836068887792, 47.571073138619511],
              [8.033336138461607, 47.55610909447671],
              [7.917709124937744, 47.554227021932491],
              [7.769718074420382, 47.553327140805038],
              [7.697218132149771, 47.543327027374517],
              [7.656109087911034, 47.550554071950671],
              [7.621109109999566, 47.561100182555549],
              [7.66881806481706, 47.57603606350375],
              [7.675691225440289, 47.605000065283491],
              [7.64055411959265, 47.603882087034833],
              [7.588273171856144, 47.584482172080925],
              [7.588763177917087, 47.598918156322327],
              [7.564163297856794, 47.639164199807908],
              [7.527636137182697, 47.66242699866973],
              [7.512082174692637, 47.695136034798736],
              [7.526663333497481, 47.788053957985881],
              [7.557218220504296, 47.862782145403116],
              [7.584163189441426, 47.903327087556647],
              [7.623191174754311, 47.973053960973118],
              [7.619773202267766, 47.996527145605398],
              [7.583891112863625, 48.027218154720345],
              [7.570491131857807, 48.044109030770528],
              [7.569309115868691, 48.087218164750709],
              [7.578382190805371, 48.117200064855012],
              [7.607727066266534, 48.164182141027084],
              [7.693336137502058, 48.27638212960909],
              [7.74870916932872, 48.335791215463928],
              [7.743054066878585, 48.371109035144514],
              [7.736663200965666, 48.394027002508892],
              [7.745400161583916, 48.424944993562292],
              [7.802218065883778, 48.575827130130904],
              [7.880000115912537, 48.664164175581845],
              [7.961945121983945, 48.724164185612096],
              [8.0922181702432, 48.80360015277995],
              [8.105136191815063, 48.819445134948921],
              [8.111391270896263, 48.843609156042874],
              [8.121663293266579, 48.865000108282572],
              [8.137500228808562, 48.893191130949447],
              [8.198327197408503, 48.955418045021645],
              [8.226082193471029, 48.964418029762385],
              [8.155554010108688, 48.976391075567676],
              [8.045827185884662, 49.008191178113918],
              [7.935973292008043, 49.048882133021152],
              [7.821391166537069, 49.046391199028392],
              [7.73750021781899, 49.042773234331719],
              [7.668891155013, 49.042218017063817],
              [7.652091138793594, 49.044936100633208],
              [7.628054019714057, 49.067218049182941],
              [7.486945014637286, 49.164164147409281],
              [7.425554111590145, 49.176454029155707],
              [7.378609083430206, 49.172364163308828],
              [7.369018174513712, 49.168782240795736],
              [7.364027086435044, 49.151936124108545],
              [7.289718161815983, 49.111664264361252],
              [7.151663186988685, 49.121109160523872],
              [7.090554083527138, 49.127491141619259],
              [7.019445034273076, 49.187491151649851],
              [6.929863276197239, 49.220200187778744],
              [6.846073245593743, 49.212045098880537],
              [6.856354152781364, 49.181764132470107],
              [6.834100199788281, 49.155064250382281],
              [6.729236052885994, 49.167636099352308],
              [6.715073151051058, 49.187291159439326],
              [6.686945160296148, 49.24499117523203],
              [6.594718073570675, 49.36305396982533],
              [6.541245051561418, 49.423882111891771],
              [6.508336190860007, 49.442773245321234],
              [6.468336072414445, 49.461391128706623],
              [6.41374507215636, 49.475691158478128],
              [6.36217323343061, 49.459391206603357],
              [6.362782262516987, 49.489164229679943],
              [6.356491141251979, 49.52586422519812],
              [6.42083616226256, 49.665836141286775],
              [6.472500201923538, 49.692982108262754],
              [6.505836036773758, 49.706627008480268],
              [6.524027113648287, 49.807700188203626],
              [6.481945098086157, 49.81110005577932],
              [6.429718129806417, 49.808882204192855],
              [6.395554162920007, 49.817773224192024],
              [6.321109116192872, 49.848327105370572],
              [6.315936140814642, 49.855318115552564],
              [6.252782188248659, 49.882354111958591],
              [6.234163299035004, 49.89750021103896],
              [6.174163289004753, 49.951109187518114],
              [6.140554036472054, 49.993891091997398],
              [6.130554090679539, 50.013882098764725],
              [6.113054017905029, 50.057354001515364],
              [6.118336125662779, 50.090827131939989],
              [6.12916319766282, 50.119164167360509],
              [6.134409095598926, 50.12784496922734],
              [6.139718193085002, 50.154445106667083],
              [6.16666332966031, 50.224718144895917],
              [6.280836082979135, 50.283609061495042],
              [6.349718060553187, 50.312773223123315],
              [6.398200162121782, 50.323173153336313],
              [6.373400122213184, 50.443191110669773],
              [6.33916323276901, 50.486936095826707],
              [6.313473202378702, 50.497218176480771],
              [6.264718186041989, 50.497773226110496],
              [6.247773162897033, 50.58916413417279],
              [6.270418215493294, 50.619853969821236],
              [6.167218211652084, 50.656391188779196],
              [6.054718150935798, 50.723609190930233],
              [6.011800124348611, 50.757273261109844],
              [6.008054084170851, 50.802218031890504],
              [6.01666330458437, 50.841664274172672],
              [6.058163280788506, 50.850482203976071],
              [6.084445073544657, 50.872082200881323],
              [6.080836161303239, 50.914718092606904],
              [6.024927190586197, 50.977982183380959],
              [5.943745105344078, 50.984300126736116],
              [5.902009094745949, 50.973118164954712],
              [5.869445065542322, 51.01888218295386],
              [5.865000142275903, 51.045345024819255],
              [5.905554136884916, 51.063118180000231],
              [6.00305411127465, 51.084164133104252],
              [6.097500223052918, 51.13110010880888],
              [6.084445073544657, 51.174264228073866],
              [6.071663341718647, 51.21409117639945],
              [6.077636118300063, 51.241382150300637],
              [6.143054022920126, 51.295273261641569],
              [6.222500216009905, 51.36319115270976],
              [6.222082126678231, 51.467354069590385],
              [6.204863182937004, 51.513464260191824],
              [6.158609158876686, 51.558327055959182],
              [6.13402704845106, 51.570827155837776],
              [6.093609175950121, 51.605827133749187],
              [6.028891160247156, 51.706664111440375],
              [5.952054085985196, 51.747527063001201],
              [5.982745095099915, 51.767218165272197],
              [5.963609210096763, 51.806664239916415],
              [6.00305411127465, 51.829991076518809],
              [6.123609180965218, 51.888054028719921],
              [6.182218130340686, 51.895273194307038],
              [6.351109121207969, 51.848054077912479],
              [6.46278222335485, 51.853609100438348],
              [6.527500239057815, 51.874445003048208],
              [6.594718073570675, 51.896109037694032],
              [6.684654050875736, 51.911664173650422],
              [6.72250018783734, 51.897909135225007],
              [6.746391126525168, 51.906109151124383],
              [6.783054074030986, 51.92471814969241],
              [6.828954046500428, 51.975754049527723],
              [6.79750011655625, 52.008745052880215],
              [6.728609086526831, 52.035273105952456],
              [6.736391180732824, 52.07666411741485],
              [6.759427165294142, 52.11456423383305],
              [6.856036143373956, 52.120482192767497],
              [6.875073289557292, 52.142354098612714],
              [6.902636172398331, 52.17221814915797],
              [6.964718079545094, 52.190273103924426],
              [7.042218162350082, 52.231664115386934],
              [7.05309116717973, 52.237764129258721],
              [7.029100148567807, 52.27826414441131],
              [7.035136124699363, 52.305827027252292],
              [7.05811812980437, 52.337636014615896],
              [7.070418069834432, 52.355827091490426],
              [7.062982148230446, 52.390964197338064],
              [6.984027134668281, 52.457354067453082],
              [6.954236174318851, 52.437218053760375],
              [6.758891226403705, 52.460964153160603],
              [6.704027143739381, 52.48819108932156],
              [6.689582274680618, 52.550553957863656],
              [6.72250018783734, 52.55943609304552],
              [6.758127132107973, 52.56464511060733],
              [6.727773243139779, 52.618609144506195],
              [6.718918097686242, 52.626782170677416],
              [6.72083604477632, 52.629436048868058],
              [6.765763213560092, 52.651173173709878],
              [6.906218094911196, 52.648118137632025],
              [7.033745064044581, 52.633191141501356],
              [7.053473214327596, 52.649582120844741],
              [7.063609114589838, 52.721382174198425],
              [7.065000175244734, 52.760418038499949],
              [7.066245055507807, 52.792500108269792],
              [7.073336145614007, 52.819718159613217],
              [7.091063200327426, 52.836909107798022],
              [7.133054020782652, 52.888882104410925],
              [7.179718087547656, 52.934164162976288],
              [7.19618215708681, 52.96250019256837],
              [7.210973198747581, 53.008882124471597],
              [7.207218106114681, 53.17610913991598],
              [7.208363291398712, 53.242809558668625],
              [7.174640341000156, 53.287236977000077],
              [7.150679340000295, 53.291229977000057],
              [7.109147340000277, 53.296820977000095],
              [7.071609340000123, 53.30480797700011],
              [7.021291340000062, 53.323177977],
              [6.9949343400001, 53.342345977000036],
              [6.973979340000199, 53.377014977000101],
              [6.945117340000081, 53.441952976999971],
              [6.937902506000171, 53.499675553000088],
              [6.909041340000158, 53.528536977000101],
              [6.86574934000015, 53.521320977000073],
              [6.808026340000026, 53.514105976999986],
              [6.728658340000152, 53.514105976999986],
              [6.649289340000109, 53.535751977000245],
              [6.605997811000293, 53.593474240000205],
              [6.526629389000163, 53.615120172000104],
              [6.463114580000138, 53.646877576000122],
              [6.454476215000113, 53.651196759500067],
              [6.403968971000211, 53.687273358000084],
              [6.347138889000064, 53.725222218999988],
              [6.33286111100017, 53.750833329000045],
              [6.264250000000231, 53.814694439000164],
              [6.107833333000201, 53.999111109000012],
              [6.000000000000227, 54.186666668999976],
              [5.0, 54.619999999000129],
              [5.0, 54.999999999000124],
              [4.333333333000041, 55.333333329000027],
              [3.370277747600142, 55.764999925800055],
              [3.4, 55.834999999000161],
              [3.350000044000183, 55.919277769800146],
              [3.562700307000114, 55.88464176400015],
              [4.25, 55.772722221000151],
              [4.750000000000114, 55.404166666000151],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "DJI", Country: "Djibouti" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [43.273976540000177, 12.690687364000027],
            [43.27568254000019, 12.683602364],
            [43.28057054000007, 12.667344364000144],
            [43.283151540000091, 12.659479364000163],
            [43.283685540000221, 12.65782936400015],
            [43.284337540000166, 12.655729364000123],
            [43.284578540000126, 12.654926364000119],
            [43.287453540000143, 12.645866364000113],
            [43.288162540000116, 12.64391136400009],
            [43.294285540000118, 12.628774864000022],
            [43.294563540000098, 12.628169364000016],
            [43.295016540000091, 12.6272353640001],
            [43.303890540000083, 12.614776363999965],
            [43.306667540000177, 12.610952364000028],
            [43.326114540000191, 12.598547364000012],
            [43.335191540000011, 12.592162364],
            [43.350785540000203, 12.580947364000039],
            [43.363864540000094, 12.570783364],
            [43.381168540000175, 12.555753864000067],
            [43.387901540000115, 12.554530364000186],
            [43.389762540000078, 12.554194364000182],
            [43.396577540000152, 12.554497364000028],
            [43.428270540000113, 12.553681364000013],
            [43.432449540000022, 12.55370236400006],
            [43.497694541000072, 12.553333364],
            [43.50995254100016, 12.553300364000108],
            [43.523826541000147, 12.553152364000027],
            [43.527755541000062, 12.553033364000044],
            [43.531311541000122, 12.552868364000162],
            [43.539763041000157, 12.552455864000038],
            [43.574596541000147, 12.538260364000124],
            [43.65058054100021, 12.516144364000056],
            [43.656479541000152, 12.510924364000076],
            [43.694691541000083, 12.465711364000143],
            [43.718500041000112, 12.436072364000054],
            [43.725472541000073, 12.416892364],
            [43.76002954100008, 12.318659363000165],
            [43.776635541000161, 12.269806363000058],
            [43.796207541000143, 12.244338363000026],
            [43.802775541000102, 12.235728363000135],
            [43.810734041000188, 12.225923863000077],
            [43.827053541000083, 12.207484363],
            [43.845448541000025, 12.185886363000179],
            [43.861558541000164, 12.167898363000162],
            [43.909331541000171, 12.116963363000011],
            [43.920889541000093, 12.104892363],
            [43.947463541000133, 12.077921363000144],
            [43.950311541000161, 12.075012363000056],
            [43.96166454100009, 12.063156363000175],
            [43.985987541000128, 12.037670363000075],
            [43.988571541000084, 12.034967362999978],
            [44.01947354100011, 12.003063363000081],
            [44.085793541000186, 11.933322363000087],
            [44.143650541000198, 11.872747362999988],
            [44.146206541000112, 11.870122363000135],
            [44.138560541000146, 11.869546363000083],
            [44.032857541000141, 11.86158536300006],
            [43.803998541000141, 11.831413363000109],
            [43.798964541000174, 11.830725363000028],
            [43.732588541000069, 11.820200363000041],
            [43.635839541000081, 11.802591363000033],
            [43.621612541000133, 11.799905363000107],
            [43.576681541, 11.791368363000032],
            [43.565667541000124, 11.78915636300006],
            [43.469851540000178, 11.768846363000037],
            [43.457088540000115, 11.766087363000111],
            [43.444720540000134, 11.763504363],
            [43.41287054, 11.724772363000113],
            [43.370299540000161, 11.692342363000122],
            [43.312556540000088, 11.648557363000123],
            [43.282899540000102, 11.626393363000091],
            [43.294424540000165, 11.585308363000024],
            [43.295088540000194, 11.582913363000102],
            [43.30448754000011, 11.545578363000075],
            [43.271066040000136, 11.497217863000131],
            [43.269780540000085, 11.49562436300009],
            [43.264110540000189, 11.489013363000126],
            [43.257847540000142, 11.482899363000158],
            [43.249218334949973, 11.469536158249483],
            [43.225000129349127, 11.430826983421923],
            [43.20547331238086, 11.397827095251856],
            [43.186391239196524, 11.370282149683675],
            [43.106200230188364, 11.250000162399317],
            [43.064163309265524, 11.186944948653021],
            [42.975554187236838, 11.053609152964938],
            [42.953609191195682, 11.020273150476626],
            [42.944091204837235, 11.002435957555164],
            [42.890973240247462, 10.982082182017464],
            [42.810973170994231, 10.988336087632163],
            [42.792218159672558, 10.997773104805745],
            [42.769300192308236, 11.023191058618224],
            [42.759782205949733, 11.059100137750463],
            [42.748191207292194, 11.077918013346192],
            [42.640409152248338, 11.097082061543873],
            [42.608236222647889, 11.091573139485433],
            [42.501109129520017, 11.063609098757254],
            [42.358891198650184, 11.019164224683479],
            [42.30055415821451, 11.011391182932954],
            [42.171663285792675, 11.000282143709228],
            [42.125545048564192, 10.999444959217811],
            [42.080345132650081, 10.977427040618878],
            [42.019718156260552, 10.944718004489843],
            [41.991945055287232, 10.942218018041672],
            [41.940282189092642, 10.950000112247693],
            [41.918054052361498, 10.953609024489012],
            [41.881945148656968, 10.960836069065039],
            [41.83721813972133, 10.973054034081997],
            [41.808327228137529, 10.985282057382676],
            [41.789718229569559, 11.008054011993366],
            [41.800554186387018, 11.031109107294057],
            [41.802909165909767, 11.098973186543674],
            [41.806663252714401, 11.168335950085151],
            [41.811936140378833, 11.257918043437257],
            [41.793745063504417, 11.380973099575797],
            [41.759854179024245, 11.507918029350961],
            [41.798045147483407, 11.638336084535524],
            [41.829018123821953, 11.740973159757928],
            [41.891391218285975, 11.773609105690937],
            [41.94860927464461, 11.816664092576445],
            [42.025082239031605, 11.926364094710266],
            [42.058609181274761, 11.975827046591192],
            [42.127218244080694, 12.09027321759244],
            [42.168882170311321, 12.137218078114387],
            [42.255554066872634, 12.263336049319932],
            [42.292218187845066, 12.293891103964953],
            [42.308327199965021, 12.308335973023702],
            [42.325554190333463, 12.359164169297628],
            [42.339436130773294, 12.396945095052288],
            [42.366100138315318, 12.430273218551591],
            [42.399718108027315, 12.469718119729393],
            [42.456109205816318, 12.529164085958385],
            [42.48480917764536, 12.521845008085023],
            [42.501391264381795, 12.516664153717812],
            [42.535827140207772, 12.504164221477239],
            [42.588327190893864, 12.467773182911117],
            [42.620282191011057, 12.430827094715099],
            [42.6491632119494, 12.39860907047543],
            [42.698609232385792, 12.363891059787676],
            [42.749854176886942, 12.387635985721616],
            [42.819400168832459, 12.445591146647587],
            [42.808400261988396, 12.487918081421157],
            [42.810554075834517, 12.524927033891316],
            [42.929718252508025, 12.623891159131873],
            [43.121381929358705, 12.708327168399862],
            [43.129617540000226, 12.707352364000087],
            [43.16164554000008, 12.692873364000064],
            [43.1674690400001, 12.690749364000183],
            [43.177205540000131, 12.686560364000158],
            [43.189039540000152, 12.684970364000222],
            [43.199231540000113, 12.683403364000242],
            [43.208401540000153, 12.682154364000112],
            [43.21814754000016, 12.682592364000115],
            [43.224715540000119, 12.683083364000055],
            [43.244985540000215, 12.685318364000153],
            [43.247996540000116, 12.685700364000141],
            [43.256277540000184, 12.686878364],
            [43.262425540000123, 12.687879364000125],
            [43.264194540000261, 12.688192364000088],
            [43.273976540000177, 12.690687364000027],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "DMA", Country: "Dominica" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.813888559, 14.488611365000096],
            [-62.813888559, 15.065000366000092],
            [-61.748055557999976, 15.603333366000172],
            [-61.442499557, 15.787222367000098],
            [-61.323611556999907, 15.734166367000043],
            [-61.138333556999982, 15.701666367000129],
            [-61.061111556999975, 15.683889367000162],
            [-60.89944455700001, 15.665000367000104],
            [-57.948333553999959, 16.500833367000027],
            [-57.93216755399996, 16.453933367000147],
            [-57.918359553999949, 16.409955367000165],
            [-57.905730553999973, 16.365625367000106],
            [-57.894288553999957, 16.320973367000036],
            [-57.884042553999961, 16.27603236700007],
            [-57.874999553999942, 16.23083336700013],
            [-60.89944455700001, 15.164444366000041],
            [-60.999999556999853, 15.125000366000037],
            [-61.155555556999843, 15.080555366000155],
            [-61.283055556999898, 15.030833366000095],
            [-62.813888559, 14.488611365000096],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "DNK", Country: "Denmark" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [15.605336209000029, 54.960069011000087],
              [14.636722379600201, 54.536222170600183],
              [14.422743681000043, 54.653627322000091],
              [14.414166666500137, 54.658333568000103],
              [14.414166590000093, 54.812499999000124],
              [14.172777778000182, 54.812499999000124],
              [14.091796326000292, 54.87998037500023],
              [14.057500208000022, 54.908558709000147],
              [13.992836471111389, 54.962441875777813],
              [14.460000000000207, 55.312222221000354],
              [15.042888889000153, 55.691499999000087],
              [15.330921702000012, 55.625389990000144],
              [15.768662003000259, 55.52491874600031],
              [16.508250000000203, 55.355166666000059],
              [15.853153072000111, 55.068508888000167],
              [15.605336209000029, 54.960069011000087],
            ],
          ],
          [
            [
              [12.729444440000179, 55.540861109000161],
              [12.715277778000086, 55.489194444000304],
              [12.610583330000225, 55.431722219000221],
              [12.641972364000139, 55.337278303000119],
              [12.638888889000214, 55.308333332000188],
              [12.677222222000125, 55.249999999000295],
              [12.794888889000163, 55.166666666000083],
              [13.055555556000229, 55.064999999000179],
              [13.147958642000106, 55.008304681000197],
              [12.934, 54.83380555500014],
              [12.749972222000281, 54.76380555500009],
              [12.44325, 54.687749999000175],
              [12.112083333000157, 54.411083332000146],
              [11.940444444000207, 54.366805555000212],
              [11.670750000000226, 54.36483333200016],
              [11.672416667000164, 54.365749999000116],
              [11.63672222200006, 54.393333332000168],
              [11.581972222000275, 54.429916666000167],
              [11.513861111000153, 54.464833332000126],
              [11.443500000000199, 54.498083332000192],
              [11.384666667000147, 54.532499999000265],
              [11.321583333000262, 54.569888888000037],
              [11.260111111000072, 54.586444444000108],
              [11.208583333000092, 54.609166666000078],
              [11.157833333000326, 54.622138888000109],
              [11.075000000000102, 54.637861110000131],
              [11.005750000000319, 54.641194444000064],
              [10.904250000000275, 54.637388888000146],
              [10.873638889000119, 54.619499999000141],
              [10.800583333000247, 54.581194444000062],
              [10.733055556000238, 54.546999999000093],
              [10.660361111000327, 54.544388888000299],
              [10.613888889000293, 54.551666666000074],
              [10.532916667000336, 54.576944444000105],
              [10.454416667000089, 54.599111110000081],
              [10.374333333000322, 54.620944444000031],
              [10.355111111000213, 54.633305555000049],
              [10.30830555600005, 54.674888888000169],
              [10.268861111000206, 54.713805555000192],
              [10.218335659000218, 54.756669435666765],
              [10.160589747000301, 54.777359186000183],
              [10.076096697000196, 54.778925729000036],
              [10.071563947000129, 54.779816104000076],
              [10.067486072000179, 54.781050979000042],
              [10.061233822000304, 54.785538479000081],
              [10.0557080720003, 54.78993310400017],
              [10.052566322000246, 54.792143104000047],
              [10.047015822, 54.795560229000102],
              [10.035263697000289, 54.798614854000164],
              [10.016409572000214, 54.802118479000228],
              [10.005654196999956, 54.804981041500128],
              [10.000536572000271, 54.807297479000113],
              [9.981336197000132, 54.813924354000108],
              [9.975299572000097, 54.815193729000271],
              [9.97133494700023, 54.816505229000086],
              [9.961333947000355, 54.818384729000172],
              [9.959164822000048, 54.818482479000068],
              [9.954605947000232, 54.819329354000047],
              [9.951030447000335, 54.820552353999972],
              [9.942374822000147, 54.822129729000039],
              [9.936574947000111, 54.824152729000105],
              [9.930350197000053, 54.825273729000116],
              [9.924146072000156, 54.827453979000211],
              [9.914749822000033, 54.829144479000092],
              [9.911781072000281, 54.830145229000152],
              [9.900903322000147, 54.832011979000015],
              [9.899951947000034, 54.832034729000156],
              [9.893103447000044, 54.831207603999985],
              [9.864987947000259, 54.823710104000156],
              [9.857403072000352, 54.822378479000179],
              [9.852390697000317, 54.821061229000065],
              [9.851083134500243, 54.821877854000093],
              [9.84719407200032, 54.824231479000218],
              [9.836167072000251, 54.8348542290002],
              [9.81257244700015, 54.844006604000299],
              [9.810962197000151, 54.844299104000186],
              [9.800886447000323, 54.838265104000243],
              [9.795942947000185, 54.835970729000053],
              [9.779670384500264, 54.826493291500185],
              [9.759579822000035, 54.822621354000148],
              [9.753811697000174, 54.823049104000177],
              [9.75006657199998, 54.822288604000107],
              [9.741401072000315, 54.82305022900016],
              [9.740287321999972, 54.822796354000218],
              [9.730656072000158, 54.823526479000122],
              [9.729232822000142, 54.824138229000141],
              [9.719667322000078, 54.824924354000075],
              [9.714748322000048, 54.827097104000075],
              [9.71337719700017, 54.828269729],
              [9.710290072000305, 54.829702854],
              [9.703779947000328, 54.830544729000167],
              [9.697374822000143, 54.833524104000162],
              [9.692074884500073, 54.8379391040001],
              [9.684947947000182, 54.83876522900016],
              [9.683565322000106, 54.83929660400014],
              [9.677717947000076, 54.839899729000194],
              [9.676318697000113, 54.83966097900003],
              [9.66926594700027, 54.840388604000054],
              [9.664732072000049, 54.839510229000155],
              [9.654259446000253, 54.840630229000112],
              [9.653464446000157, 54.840475229000049],
              [9.650915321000184, 54.840732229000196],
              [9.643245321000109, 54.844059979000178],
              [9.640280196000333, 54.844337604000145],
              [9.627471821000086, 54.849936729000035],
              [9.626658946000248, 54.850588354000251],
              [9.619411571, 54.854007979000301],
              [9.621897630943181, 54.861977239984753],
              [9.614288237056599, 54.890083471015487],
              [9.613318571000207, 54.890472604000138],
              [9.609353196000086, 54.890439729000207],
              [9.607507571000269, 54.891174479000085],
              [9.602871446000279, 54.891195104000076],
              [9.597090446000323, 54.893411729000093],
              [9.593382946000304, 54.892705979000141],
              [9.592083321000359, 54.890749604000177],
              [9.580192196000326, 54.882492729000091],
              [9.578095571000119, 54.877252604000034],
              [9.576320321000139, 54.875449604000096],
              [9.573748571000351, 54.874397229000124],
              [9.572968446000175, 54.873507104000112],
              [9.561654571000304, 54.868929479000144],
              [9.56051744600029, 54.867653854000196],
              [9.558072321, 54.866460479000182],
              [9.546790071000373, 54.866279979000126],
              [9.5421871960001, 54.866282979000061],
              [9.538170571000194, 54.865005979000045],
              [9.536214446000088, 54.86373260400012],
              [9.53249169600025, 54.862762104000126],
              [9.525402821000171, 54.858454979000157],
              [9.525028946000248, 54.857610604000229],
              [9.521907570999986, 54.853863729000068],
              [9.515532946000064, 54.849976479000134],
              [9.514772696000193, 54.849875729000189],
              [9.501053633500135, 54.845282541500126],
              [9.499517571000354, 54.844884604000129],
              [9.498396321000143, 54.844133854000177],
              [9.494406821000098, 54.839098729],
              [9.492992446000244, 54.838216229000182],
              [9.484784071000263, 54.836385104000101],
              [9.48275544600034, 54.836766729000146],
              [9.475819133500181, 54.835184791500069],
              [9.46657569600012, 54.833433854000191],
              [9.46437432100015, 54.831689979000032],
              [9.455094821000102, 54.830402604000199],
              [9.449841696000192, 54.826368604000209],
              [9.44712832100015, 54.825561854000057],
              [9.444767731987383, 54.823671778004439],
              [9.445354065934623, 54.825400139076294],
              [9.414718209720775, 54.833327072594955],
              [9.377218077723057, 54.827636095598962],
              [9.350836037404861, 54.810554112155955],
              [9.248336090118926, 54.812636009272524],
              [9.230836184982195, 54.829300071022033],
              [9.228891248164018, 54.849582097468442],
              [9.168891238133597, 54.865000105488605],
              [8.941109163029068, 54.905545047642136],
              [8.92000017801297, 54.908054086545746],
              [8.83509116333255, 54.913791164009126],
              [8.785827197833555, 54.906109149727342],
              [8.75889111371356, 54.898053973115225],
              [8.719445039069171, 54.891109063400904],
              [8.692773152538336, 54.895827069073448],
              [8.664545163751711, 54.913090919717092],
              [8.610274499000241, 54.954522527000165],
              [8.514502570000275, 55.027637230000096],
              [8.501649445000226, 55.036279230000105],
              [8.471343570000101, 55.056655230000104],
              [8.449669445000097, 55.075619230000143],
              [8.41445044500017, 55.086456230000067],
              [8.397452783000119, 55.085323052000149],
              [8.373813570000266, 55.083747105000214],
              [8.338593570000228, 55.070200230000125],
              [8.301944444000128, 55.063916666000182],
              [7.906829495000181, 55.118597622000095],
              [7.552666667000267, 55.167611110000053],
              [5.750000000000114, 55.511194444000182],
              [5.403333333000035, 55.249999999000124],
              [5.15, 55.249999999000124],
              [4.750000000000114, 55.404166666000151],
              [4.25, 55.772722221000151],
              [3.562700307000114, 55.88464176400015],
              [3.350000044000183, 55.919277769800146],
              [3.250000000000227, 56.086666666000269],
              [5.033333333000201, 56.591666666000151],
              [6.936666667000111, 57.174999999000249],
              [7.983333333000246, 57.498333332000243],
              [8.458333333000212, 57.618333332000304],
              [8.888333333000276, 57.696666666000056],
              [9.383333333000166, 57.988333332000138],
              [10.030027778000203, 58.261444444000063],
              [10.542444444000239, 58.133361110000351],
              [11.048775470000123, 57.816834775000245],
              [11.399277778000169, 57.449999999000113],
              [12.147805556000264, 56.508972221000249],
              [12.108244352000213, 56.373893218000148],
              [12.087750000000256, 56.303916666000305],
              [12.236991577000083, 56.256501138000203],
              [12.358196406000104, 56.217993163000187],
              [12.363333333000185, 56.216361110000264],
              [12.416661589000284, 56.186921925000092],
              [12.464483260000122, 56.162157131000072],
              [12.500349513000231, 56.14080817100006],
              [12.531945975000269, 56.125436919000094],
              [12.557564727000226, 56.111773584000161],
              [12.572935979000192, 56.102380042000277],
              [12.586599313000022, 56.091278583000189],
              [12.603678482000191, 56.079323165000062],
              [12.620757650000201, 56.069075664000138],
              [12.631005151000181, 56.063951913000324],
              [12.642106610000212, 56.057120246000181],
              [12.649792236000224, 56.048580662000234],
              [12.654915986000162, 56.040041078000172],
              [12.656623903000138, 56.027231702000222],
              [12.659185778000278, 56.003320866000195],
              [12.655769945000202, 55.985387739000089],
              [12.652354111000193, 55.926464609000163],
              [12.659185778000278, 55.917925025000045],
              [12.666871404000233, 55.904261690000283],
              [12.671995155000275, 55.893160231000195],
              [12.685658489000105, 55.882912730000271],
              [12.693344115000286, 55.866687520000198],
              [12.70861111000022, 55.827194439000209],
              [12.846916667000301, 55.699166666000167],
              [12.895222221000154, 55.644166667000036],
              [12.881361110000171, 55.614166669000099],
              [12.729444440000179, 55.540861109000161],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "DOM", Country: "Dominican Republic" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-70.783618244398241, 19.84666375987284],
            [-70.709454788465507, 19.802218236102661],
            [-70.632509185144642, 19.759718183655835],
            [-70.55763617179241, 19.753054209496227],
            [-70.518336224666228, 19.757081713196797],
            [-70.488890746973482, 19.776391240758429],
            [-70.471254733351287, 19.782918234768843],
            [-70.454454809060479, 19.782500273922111],
            [-70.399445764265721, 19.744718224311598],
            [-70.38807272967847, 19.734718251415373],
            [-70.353618236395789, 19.692218198968547],
            [-70.320845785694303, 19.667218182909082],
            [-70.303345749306999, 19.655000200400607],
            [-70.290845783186768, 19.649163792259017],
            [-70.275281772799786, 19.645554165586091],
            [-70.185836203358804, 19.62916376264765],
            [-70.146954803812491, 19.622772745872126],
            [-70.124999692831068, 19.620827190729713],
            [-70.098209238052817, 19.624581737046313],
            [-70.086118206551561, 19.633891207892603],
            [-70.07291821215378, 19.655827179685488],
            [-70.067227223655806, 19.662500013891744],
            [-70.053336227208206, 19.67569123206188],
            [-70.03237274107704, 19.682500209684221],
            [-69.966945762539325, 19.678891253563521],
            [-69.949318188411382, 19.676800194958489],
            [-69.936399761444562, 19.671109206460542],
            [-69.898618211833906, 19.637218180401462],
            [-69.88848176170282, 19.626245221843604],
            [-69.882236164570998, 19.612218248161028],
            [-69.881390716649634, 19.600272723036667],
            [-69.892227195058467, 19.525272758677218],
            [-69.877090758479227, 19.442500023442577],
            [-69.864727185774854, 19.417500007383026],
            [-69.839172731633738, 19.374445180121299],
            [-69.824445813492588, 19.354445234328907],
            [-69.817781755513892, 19.346663773959179],
            [-69.755981785552251, 19.290836189374076],
            [-69.741390760826988, 19.286391227740822],
            [-69.728899737115455, 19.285827176698163],
            [-69.718609254379743, 19.288891220964913],
            [-69.702518216956264, 19.293609223801226],
            [-69.675563790078911, 19.300827219861546],
            [-69.537227235234553, 19.333327215593144],
            [-69.522518201910742, 19.335554167614063],
            [-69.445554800505732, 19.333327215593144],
            [-69.319172716108511, 19.316391230248442],
            [-69.307236214297802, 19.325554194717924],
            [-69.262218199990457, 19.35500025914375],
            [-69.235699702096241, 19.365272773243092],
            [-69.21973619949901, 19.360554183673614],
            [-69.157927203309697, 19.295000004128568],
            [-69.158399740617995, 19.280418255631119],
            [-69.165554804541301, 19.267499994845124],
            [-69.218336226762887, 19.186109263710094],
            [-69.230009213598322, 19.180272771749529],
            [-69.243054762124729, 19.180272771749529],
            [-69.370009253740108, 19.196663761421192],
            [-69.588345759391984, 19.22416377070472],
            [-69.615772723577635, 19.224372793037602],
            [-69.627090765522354, 19.212499976277982],
            [-69.636809254806707, 19.113190997908887],
            [-69.631672791123606, 19.101663767450376],
            [-69.620145810665122, 19.08847279928024],
            [-69.603899740637019, 19.088054167881282],
            [-69.578063802884088, 19.088891260127028],
            [-69.531954794316562, 19.094163787778214],
            [-69.515563720825782, 19.09805422459651],
            [-69.468899771262414, 19.106109229083572],
            [-69.442781766078582, 19.10694523459604],
            [-69.429736217552176, 19.107218191980124],
            [-69.415281756795054, 19.10582724547173],
            [-69.402236208268562, 19.103054211044579],
            [-69.391109220020439, 19.099163774226284],
            [-69.383054802266514, 19.093054154329437],
            [-69.378890737511909, 19.083609206248113],
            [-69.376954795330505, 19.065972776444781],
            [-69.366390771391679, 19.056663805598461],
            [-69.355836189861577, 19.052218257232042],
            [-69.253890740533421, 19.020827224397053],
            [-69.192227247806812, 19.01194515680622],
            [-69.178781709984207, 19.01212718427486],
            [-68.989454755456023, 19.018891282215648],
            [-68.963899714581572, 19.03082719729322],
            [-68.923899739177813, 19.029863740773479],
            [-68.907227182075246, 19.020554183193909],
            [-68.893618253058406, 19.012218198009279],
            [-68.773345788558117, 18.968891250096661],
            [-68.738890711456463, 18.957772788076184],
            [-68.72709077361381, 18.951245207332434],
            [-68.691536176024158, 18.923745198048877],
            [-68.656399709833465, 18.883054220595326],
            [-68.590563796676776, 18.821109246671071],
            [-68.567227181595626, 18.801391284490435],
            [-68.539736198539686, 18.778609194224046],
            [-68.435527189865695, 18.705154206063483],
            [-68.357790715085059, 18.654718244461307],
            [-68.343199690359796, 18.640691020778618],
            [-68.325563760556605, 18.616663740380901],
            [-68.322927203364401, 18.599027223844374],
            [-68.33555479104416, 18.56916378530498],
            [-68.451390736319837, 18.353890990100652],
            [-68.462781739543431, 18.348054161406779],
            [-68.476527229614305, 18.344581769254361],
            [-68.51889072100721, 18.344163724588569],
            [-68.531399713355114, 18.346945198510227],
            [-68.550845804884858, 18.357218212609538],
            [-68.569454804168771, 18.368054191018231],
            [-68.590763792781871, 18.377563744702925],
            [-68.607090763583443, 18.365272717096545],
            [-68.631390751365331, 18.25694519098748],
            [-68.636954788856059, 18.21639102750207],
            [-68.646045794960884, 18.205627173639002],
            [-68.698063756767908, 18.203327176520119],
            [-68.728618197357207, 18.207218200071551],
            [-68.755563768558972, 18.219718250010871],
            [-68.761672717903764, 18.236945161376113],
            [-68.771390707188061, 18.260827188311154],
            [-68.781681773742804, 18.277772786616893],
            [-68.820554733794523, 18.341663731364477],
            [-68.832509201327639, 18.35749969622124],
            [-68.839736223615631, 18.364436208669858],
            [-68.84722717706012, 18.371390776488099],
            [-68.863345796080949, 18.384163783811303],
            [-68.872790744162188, 18.389163770259486],
            [-68.895281740769889, 18.396381766319777],
            [-68.908890753605732, 18.39832723472891],
            [-68.914727245566382, 18.397499755444173],
            [-68.973072792012005, 18.404718251504434],
            [-69.063472791745056, 18.390972761433673],
            [-69.086118234224131, 18.395272719604165],
            [-69.087881761897791, 18.395645214036094],
            [-69.16167272339851, 18.409299693286741],
            [-69.194863721180013, 18.427499757951708],
            [-69.384445748774965, 18.426109227624281],
            [-69.46860926142287, 18.416109254728056],
            [-69.516681709950177, 18.40277278309533],
            [-69.547227208130693, 18.416945176421606],
            [-69.694036225394683, 18.456245207366791],
            [-69.746399689683756, 18.460281737295148],
            [-69.776036221072985, 18.460481733400243],
            [-69.884727215386306, 18.469027240917868],
            [-69.903336214670247, 18.463890777234852],
            [-69.912509208281676, 18.459163748170837],
            [-69.942509210789211, 18.439999724072024],
            [-69.959454809094922, 18.429163745663246],
            [-69.967218217009162, 18.423063738727294],
            [-70.001954777722688, 18.420690780329451],
            [-70.010681728890006, 18.416890770512325],
            [-70.061109248083426, 18.336109220101534],
            [-70.072509193715717, 18.322218223653849],
            [-70.154172716053807, 18.233054221643656],
            [-70.164736237078472, 18.229999706518825],
            [-70.172018252008996, 18.232372748735671],
            [-70.184172718561427, 18.227772754497991],
            [-70.229445805435404, 18.223609189743328],
            [-70.319454755366849, 18.230554228419564],
            [-70.383754802871607, 18.236390723294349],
            [-70.400009228575072, 18.234999776785983],
            [-70.41166374677411, 18.225827199355578],
            [-70.459727249978471, 18.204436223235732],
            [-70.475281734137724, 18.199718220399419],
            [-70.486681763589104, 18.197218227175412],
            [-70.50750918866612, 18.194718233951434],
            [-70.545836233949757, 18.206109237174942],
            [-70.556399757888641, 18.225827199355578],
            [-70.546954809807403, 18.23319120179282],
            [-70.554027215671709, 18.252081768507551],
            [-70.587027243575875, 18.274627171024534],
            [-70.572645744097656, 18.312081773522678],
            [-70.568618240396972, 18.365000009712517],
            [-70.590699718566754, 18.406109198012913],
            [-70.611118209024823, 18.419718210848757],
            [-70.622509212248502, 18.423609234400217],
            [-70.652236173552865, 18.431945219584904],
            [-70.664463769022348, 18.434999737623869],
            [-70.691945809669448, 18.432363767164887],
            [-70.712090758924489, 18.411245161695959],
            [-70.707781774526211, 18.396381766319777],
            [-70.708345741749895, 18.386390985832335],
            [-70.712781760974366, 18.376391012936111],
            [-70.722781733870505, 18.365000009712517],
            [-70.730009256158638, 18.358054218122092],
            [-70.738618198727323, 18.352499709773255],
            [-70.750427246616738, 18.346936172282469],
            [-70.866954780675542, 18.312218250757624],
            [-70.935836182729389, 18.253890759681738],
            [-70.988345737214274, 18.294027212320415],
            [-71.026118257682953, 18.305272795900422],
            [-71.0388907650063, 18.307781731533183],
            [-71.053345812496673, 18.309163735632879],
            [-71.068481749075858, 18.307218180490551],
            [-71.081254756399176, 18.299163762736711],
            [-71.099727191715033, 18.263336207762919],
            [-71.103054750957057, 18.245827228966945],
            [-71.099727191715033, 18.235836198479475],
            [-71.078899766637846, 18.189163725602597],
            [-71.074445778776891, 18.179718190788023],
            [-71.066390774289744, 18.167218224667792],
            [-71.055627170426789, 18.149027186230612],
            [-71.09000920243065, 18.077772741960004],
            [-71.184445815911033, 17.934718184279305],
            [-71.19361822278924, 17.922499951770831],
            [-71.207227235625083, 17.907772783629653],
            [-71.222499736172523, 17.894445167672401],
            [-71.248899724968112, 17.879718249531251],
            [-71.266954786170402, 17.846663731898886],
            [-71.396663759257308, 17.618327169031645],
            [-71.408336159859545, 17.609163788381281],
            [-71.423890727837801, 17.604163801933211],
            [-71.625563786433986, 17.836390967799602],
            [-71.65889076145038, 17.953054226179162],
            [-71.66307279484144, 17.963609223890316],
            [-71.675418228357074, 17.979027227900261],
            [-71.690845761509024, 17.992218196070368],
            [-71.73250922171016, 18.022499766008778],
            [-71.751954813239877, 18.032499738905003],
            [-71.767863796834206, 18.038499818171871],
            [-71.749172938170034, 18.092082197146723],
            [-71.753890943842578, 18.145554045689764],
            [-71.765835826453241, 18.180554023601033],
            [-71.769245919950606, 18.200482166094375],
            [-71.721390952379267, 18.284445031541892],
            [-71.715517920445961, 18.292527030244358],
            [-71.694735829654689, 18.322218078307614],
            [-71.716672946706638, 18.330273087281668],
            [-71.743054987025118, 18.340553994469289],
            [-71.778899860778949, 18.356664180055574],
            [-71.810564008855181, 18.375827054786853],
            [-71.899172963245888, 18.431391194006252],
            [-71.908817851618636, 18.441454004072611],
            [-71.906955057451825, 18.455973136793702],
            [-71.886535903069102, 18.477144986083701],
            [-71.89722685296104, 18.492773212236202],
            [-71.949173027483795, 18.547218032102379],
            [-71.996946020041861, 18.592500090667741],
            [-72.003063971186748, 18.600827008581703],
            [-71.979735793479762, 18.61944505960507],
            [-71.970563979723465, 18.624164238744001],
            [-71.924317834652157, 18.62729119155135],
            [-71.912226771649699, 18.612918071584076],
            [-71.901682002149357, 18.608609102787639],
            [-71.853345913334493, 18.616664111761779],
            [-71.831681878688642, 18.622500095682867],
            [-71.823626869714587, 18.628891129233963],
            [-71.78973581759638, 18.674445096739149],
            [-71.77528189608222, 18.696109131385001],
            [-71.766108908859337, 18.700836021875048],
            [-71.75611784788444, 18.70416414035914],
            [-71.736681890746951, 18.705273066152287],
            [-71.732226909196726, 18.707500137832241],
            [-71.725146045012394, 18.718744963887801],
            [-71.715835762215221, 18.749718107864325],
            [-71.709163934906996, 18.780282214964743],
            [-71.708617937732697, 18.790273108301662],
            [-71.716746036902578, 18.878264148788489],
            [-71.77750880012411, 18.955973108621194],
            [-71.749999896739638, 19.019718153000824],
            [-71.654446032634524, 19.119445031432477],
            [-71.639872920456895, 19.1369451042073],
            [-71.634590980337123, 19.161391092524738],
            [-71.629308872579173, 19.203745017026634],
            [-71.629181970564787, 19.219718074676607],
            [-71.66028184891772, 19.225826973366011],
            [-71.67222689916639, 19.228882177081985],
            [-71.687790919940255, 19.23555400439021],
            [-71.696117837854132, 19.241664244184065],
            [-71.71556402091349, 19.257773088665871],
            [-71.744735893892596, 19.285826983396433],
            [-71.723617856421242, 19.360000121183688],
            [-71.697646026445341, 19.402909095315394],
            [-71.691681967043195, 19.485835957527527],
            [-71.691955049449376, 19.498336057406092],
            [-71.704454981689878, 19.539445101644944],
            [-71.707781926707554, 19.549445047437345],
            [-71.717790924955466, 19.566664158816749],
            [-71.722226795766318, 19.576109054979256],
            [-71.726390925275524, 19.585553951141847],
            [-71.734999978051093, 19.610273189503673],
            [-71.73779098417819, 19.627218045010508],
            [-71.735290997730004, 19.654718063577647],
            [-71.733063926050136, 19.67915399361128],
            [-71.736535877993248, 19.686109129247697],
            [-71.748064012376773, 19.699164111117895],
            [-71.754181831347751, 19.705827159072513],
            [-71.719654813613374, 19.701318231301016],
            [-71.730836207770864, 19.734445210212314],
            [-71.744736230446335, 19.760272792289769],
            [-71.76221821437818, 19.76944519916789],
            [-71.775981759818592, 19.770554162064613],
            [-71.772299758600013, 19.784163761633707],
            [-71.665836216307582, 19.893745167368778],
            [-71.595290742723307, 19.906391223684935],
            [-71.583345801417977, 19.908054208482284],
            [-71.529445803338774, 19.908336192094097],
            [-71.484518216032598, 19.904027207695933],
            [-71.447509239797597, 19.89055417209498],
            [-71.431118250125849, 19.884163742052678],
            [-71.401945813136308, 19.86944517958689],
            [-71.366672782977531, 19.85250025183349],
            [-71.355836217835531, 19.848327244670216],
            [-71.260836223864771, 19.823054187407692],
            [-71.248899724968112, 19.82471825893812],
            [-71.232036197988123, 19.829563799514901],
            [-71.150281748648894, 19.849445233794583],
            [-71.094799750364814, 19.874091224058517],
            [-71.085836195267262, 19.894791031947435],
            [-71.063609213634891, 19.913891203357096],
            [-71.045290724190522, 19.923336235257423],
            [-71.029172775721918, 19.928336221705493],
            [-71.01139969813147, 19.93082718920185],
            [-70.99445477037807, 19.930691211966874],
            [-70.841945738388233, 19.903609246849115],
            [-70.830145800545665, 19.89708175283856],
            [-70.801118196966542, 19.864718234341879],
            [-70.783618244398241, 19.84666375987284],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "DOM", Country: "Dominican Republic" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.574445764389168, 18.129445201285066],
            [-68.568554779786012, 18.108054225165134],
            [-68.573336214759308, 18.101663795122917],
            [-68.590009188042842, 18.113609236428189],
            [-68.62056379918431, 18.121663740915224],
            [-68.640290703773616, 18.124999739651756],
            [-68.652236228897976, 18.123609209324329],
            [-68.662227175566443, 18.119718185772896],
            [-68.691263721554236, 18.111109243204211],
            [-68.701672712888438, 18.111945164897591],
            [-68.731945756599231, 18.119999753203686],
            [-68.755563768558972, 18.129854219723015],
            [-68.781536183546905, 18.169854195126774],
            [-68.784999720023905, 18.179718190788023],
            [-68.779999733575835, 18.197709233120108],
            [-68.609999691426481, 18.164718231443729],
            [-68.597781708918092, 18.161390758934985],
            [-68.587227211206994, 18.153890779262738],
            [-68.574445764389168, 18.129445201285066],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "DZA", Country: "Algeria" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.816666667000106, 38.001666666000048],
            [7.845657507000112, 37.938804387000161],
            [7.971244507000165, 37.699232387000151],
            [8.054290508000122, 37.5918703870001],
            [8.188369508000164, 37.494278387],
            [8.439858508000128, 37.309275387],
            [8.522390508000086, 37.285194386999976],
            [8.552171508000015, 37.209415387],
            [8.608522508000021, 37.061422386000046],
            [8.626400508000188, 37.013154386000039],
            [8.633054508000072, 36.972104386000026],
            [8.633162508000112, 36.969690385999954],
            [8.622027480180947, 36.94136378435492],
            [8.63221809287819, 36.881944969377216],
            [8.638054076799477, 36.831109061752258],
            [8.476391136336559, 36.743609033154897],
            [8.47569124741932, 36.720273144097078],
            [8.450000211200745, 36.660827010229994],
            [8.34971811550119, 36.604018158385642],
            [8.241109101887787, 36.5694361021677],
            [8.207636139101169, 36.556109043719701],
            [8.186245019223435, 36.527291054693578],
            [8.181663303296887, 36.505273136094743],
            [8.187291248380802, 36.495545099242136],
            [8.215273226381697, 36.483891068672406],
            [8.245836160015756, 36.481527036694231],
            [8.290691244432196, 36.481727028904587],
            [8.370273224353809, 36.452564208380792],
            [8.376391175498696, 36.420136133647063],
            [8.374445065213962, 36.37499120301807],
            [8.371663279180353, 36.357500182698757],
            [8.333891238243012, 36.291382221523619],
            [8.319300188792482, 36.204991118719406],
            [8.305273241427329, 36.097500083354461],
            [8.290836083719512, 36.000000108964784],
            [8.282973187966718, 35.965309088005355],
            [8.280282261763773, 35.961936042520037],
            [8.266663177808169, 35.921800137242698],
            [8.260554111480701, 35.85638206498443],
            [8.262500221765436, 35.816800035870514],
            [8.271109106902912, 35.767218060964112],
            [8.279163277686735, 35.746526997641595],
            [8.298609125469852, 35.725273173338323],
            [8.323891124812519, 35.704718064485704],
            [8.337918072177843, 35.682427063480574],
            [8.344863317168432, 35.644164178291859],
            [8.345273192234856, 35.623600184621822],
            [8.348891156931586, 35.584444962018765],
            [8.32221809693408, 35.457773114649697],
            [8.312082196671753, 35.435691158310519],
            [8.302773254979172, 35.398891082868019],
            [8.302082250879437, 35.373882165931647],
            [8.308336156494249, 35.339164155243893],
            [8.319300188792482, 35.312773230108277],
            [8.34055401309584, 35.290273184437197],
            [8.401109240394049, 35.192218160417625],
            [8.329509179250635, 35.108673216663718],
            [8.286109193229549, 34.965554063561839],
            [8.27528228886743, 34.843054057052925],
            [8.285482226870187, 34.750691183495789],
            [8.270154072852307, 34.74010903070716],
            [8.265273290619717, 34.73805395568094],
            [8.243609088335774, 34.718609113726032],
            [8.227082154522378, 34.693673119347508],
            [8.23833603303342, 34.670964196648768],
            [8.252709153000808, 34.655209068481966],
            [8.12083616705894, 34.537909027079834],
            [8.106554074560336, 34.532718114428732],
            [8.0702731742021, 34.521945021885259],
            [8.036109207315661, 34.509164128249651],
            [7.868891244326875, 34.436100083893436],
            [7.845136092470966, 34.417918059474587],
            [7.824236152120676, 34.380827131991154],
            [7.821945042700378, 34.338600109503673],
            [7.783745021785791, 34.250618121472442],
            [7.759309091751959, 34.233464221299855],
            [7.731109184267893, 34.22639106846627],
            [7.701663223054084, 34.223882197200865],
            [7.681663331469366, 34.219991150097954],
            [7.649163340006169, 34.208744982937944],
            [7.62597329606379, 34.194573196285475],
            [7.528891243367639, 34.104309151289058],
            [7.518054113083622, 34.083053985881421],
            [7.515263274594702, 34.071918124567546],
            [7.518191241020105, 34.004300137995799],
            [7.508609216921229, 33.967773144959594],
            [7.500282131369289, 33.947491118513184],
            [7.492218069939725, 33.919853972009761],
            [7.492500204801331, 33.887500160938359],
            [7.515000250472212, 33.810553954296736],
            [7.557363227429619, 33.676245019647141],
            [7.675554097503806, 33.507773123939714],
            [7.725027107668723, 33.419400036305106],
            [7.724718150716797, 33.399164110326311],
            [7.724445068310501, 33.373053978947624],
            [7.724163268724965, 33.318600106625965],
            [7.72639117859535, 33.286664217247889],
            [7.731945027654916, 33.248327068396932],
            [7.743054066878557, 33.229164193665738],
            [7.762573172495792, 33.211664120890916],
            [8.019445145566152, 33.113891064094943],
            [8.045418149008441, 33.107635985013857],
            [8.078400099905508, 33.110827143199643],
            [8.157773202799547, 33.028054005092244],
            [8.274163304790392, 32.876664260465915],
            [8.306109084814182, 32.834164155572225],
            [8.312218151141764, 32.7930539378671],
            [8.319445028079798, 32.738327150777209],
            [8.323327190365291, 32.71027308840867],
            [8.342782258242181, 32.57360917423641],
            [8.348609189707958, 32.533335973384496],
            [8.350982274141643, 32.531218034084119],
            [8.415282200512991, 32.491664168164917],
            [8.560000117830896, 32.404164139567555],
            [8.688327055805587, 32.326109174770593],
            [8.864445091249053, 32.218044984865216],
            [9.008336049997524, 32.129436030474508],
            [9.057218135986801, 32.095554030811783],
            [9.08256333487941, 32.000082141720029],
            [9.105836192025379, 31.912773220515604],
            [9.181391170373985, 31.624164176811135],
            [9.213336112207458, 31.5005540710426],
            [9.298609236763042, 31.170554015875766],
            [9.334163258475826, 31.032773129283257],
            [9.383609111274325, 30.840273166951803],
            [9.436391129183846, 30.633891096542342],
            [9.468054103793804, 30.510000029378674],
            [9.520554154479896, 30.303335991745499],
            [9.534163180151609, 30.248336122249512],
            [9.537109083849913, 30.234391149897547],
            [9.51139122554082, 30.22277316151154],
            [9.382773267887302, 30.162500069075108],
            [9.311391136227144, 30.12721812394004],
            [9.318891263209451, 30.100973211557928],
            [9.345554097284889, 30.076664183538796],
            [9.362500126258197, 30.063327066806792],
            [9.385827130498797, 30.042500049014379],
            [9.40221810984221, 30.021664146404518],
            [9.41360911628945, 30.003891158861776],
            [9.449163305640212, 29.96582709241703],
            [9.551391176348432, 29.827773123418226],
            [9.566663339252983, 29.806945099797403],
            [9.57889119491557, 29.789164233265453],
            [9.638054020454376, 29.691109041607788],
            [9.661109115755067, 29.649164153981999],
            [9.680827207754362, 29.611664189622658],
            [9.725000172888315, 29.521382207353511],
            [9.757218197128083, 29.454164205202559],
            [9.765553999859264, 29.430554060271859],
            [9.829718139398892, 29.198327061900997],
            [9.839718085191294, 29.160000138972023],
            [9.84721821217363, 29.118609127509572],
            [9.852218185069859, 29.089718048287509],
            [9.856109232172798, 29.06610907682348],
            [9.865273166940085, 28.993054084922946],
            [9.869445175438301, 28.950000103865747],
            [9.873191215615918, 28.849582221334472],
            [9.871663194662773, 28.806109145117276],
            [9.867500238620039, 28.768053963490118],
            [9.859445062007808, 28.708882085495716],
            [9.846836165025735, 28.630436021095747],
            [9.838054109768251, 28.571945088917161],
            [9.812218066624183, 28.40777310955086],
            [9.798336126184353, 28.325554015244862],
            [9.790282290676686, 28.270553978110698],
            [9.792218175039352, 28.249582121030855],
            [9.830554150424007, 28.159718060455234],
            [9.842500206501086, 28.131944959482084],
            [9.901391123099984, 27.993327056035966],
            [9.954163250363962, 27.868609030302778],
            [9.955836110604338, 27.846245106739886],
            [9.948327098804697, 27.824444949986045],
            [9.890273199059123, 27.648609049404186],
            [9.873536047113589, 27.608682188792514],
            [9.819163311614659, 27.537773131748807],
            [9.776391130143281, 27.439164231565897],
            [9.734445069050906, 27.316036085231318],
            [9.74360917145637, 27.254864117495814],
            [9.802773170461677, 27.097773169951822],
            [9.818054050545442, 27.056391043306675],
            [9.829163257407174, 27.005273168458203],
            [9.848327137966862, 26.908609037455292],
            [9.908327147997085, 26.874718152975149],
            [9.93055411126187, 26.859718066648597],
            [9.919718154444439, 26.804718029514433],
            [9.912773244730005, 26.7775001458091],
            [9.895836100574115, 26.69555396627122],
            [9.916945085590157, 26.67333605546203],
            [9.899163213229798, 26.610000047958508],
            [9.871663194662773, 26.51416421662968],
            [9.762782272109547, 26.468609075658108],
            [9.668891209961004, 26.429718050643814],
            [9.499445169463854, 26.357500075596633],
            [9.4422182282878, 26.270273129405368],
            [9.398191108269373, 26.199864136706637],
            [9.393473270234836, 26.176664202118715],
            [9.398336115194695, 26.15332713959431],
            [9.435273150935302, 26.10471813601113],
            [9.479445110241045, 26.048882087851936],
            [9.791663293047719, 25.644718114991164],
            [9.856109232172798, 25.560836051090618],
            [10.005554039980751, 25.365553967449287],
            [10.032082260690942, 25.329445063744672],
            [10.039163292513251, 25.279444999506765],
            [10.04527319703115, 25.087500086805207],
            [10.047782235934619, 25.005273113510114],
            [10.049718120297484, 24.936382083480524],
            [10.050836098546029, 24.867773188312825],
            [10.054445178425425, 24.838053977054656],
            [10.134773147731806, 24.795636014812416],
            [10.174163231262554, 24.775000104412911],
            [10.218336196396706, 24.751109165725282],
            [10.229163268396633, 24.709164110461316],
            [10.240000231042558, 24.660000057248325],
            [10.252218196059431, 24.605826978683879],
            [10.303336070907903, 24.566664212367769],
            [10.414582198905862, 24.483327139813142],
            [10.480000103525867, 24.478054084510788],
            [10.576663228700198, 24.515554048870129],
            [10.668054136762493, 24.550282117841689],
            [10.715273253157306, 24.567218088531192],
            [10.756945058376886, 24.550554026781597],
            [10.832500204363669, 24.520273228009259],
            [10.969691172609117, 24.472364113343119],
            [11.012891166419934, 24.457827043349411],
            [11.110836052231463, 24.429717995695725],
            [11.275273235015135, 24.383054096569097],
            [11.350000248965898, 24.36250016118278],
            [11.439445046743543, 24.336936026978506],
            [11.46955401650024, 24.327518120544326],
            [11.55889119064085, 24.302500151152444],
            [11.726018126160881, 24.000000114552748],
            [11.757218084438108, 23.941664247583375],
            [11.918891250822895, 23.647500181352925],
            [11.986473195210721, 23.522309081003201],
            [11.768327123661635, 23.398054071602658],
            [11.70750015506178, 23.364164192951009],
            [11.100554139215433, 23.01693597394906],
            [10.828336074854462, 22.860000091614197],
            [10.752782269972244, 22.816391060927273],
            [10.616945146731439, 22.737782219967173],
            [10.340245111091889, 22.576473163457237],
            [10.120282178205429, 22.447773230790446],
            [9.742863182071716, 22.22639107405432],
            [9.46987314442606, 22.06518209746865],
            [8.998609186611333, 21.785000051602296],
            [8.716109209234332, 21.61583597832869],
            [8.582500163501777, 21.535553941851944],
            [8.049445150581278, 21.213335980891046],
            [7.916663231056532, 21.132500068250877],
            [7.725000117940311, 21.015554078439621],
            [7.644027077363944, 20.965691142137999],
            [7.463773243244077, 20.856673091648489],
            [6.930273151263634, 20.408335986015558],
            [6.644718137808667, 20.164445066429252],
            [6.473891262578462, 20.017773159837574],
            [6.303336128649988, 19.871109132234821],
            [5.9816633266731, 19.593326992892386],
            [5.963609210096848, 19.57694506600437],
            [5.812500091589868, 19.44610908912621],
            [5.785282207884421, 19.440273105205009],
            [5.656945043987946, 19.416109084111085],
            [5.546945137357682, 19.396109024888275],
            [5.308609240297329, 19.350827133960891],
            [4.886945027023927, 19.269718138914541],
            [4.502218184767116, 19.195554053582683],
            [4.355836124388077, 19.167218023990628],
            [4.245282173956241, 19.146664088604396],
            [4.080836106355321, 19.116664083589185],
            [3.952782250786925, 19.093053938658684],
            [3.909100129904147, 19.084809163396173],
            [3.733336146051755, 19.051664100662705],
            [3.331945074407258, 18.976391089537529],
            [3.183891159616138, 19.078609069599835],
            [3.137363214959123, 19.117082172920689],
            [3.117009104145353, 19.145553989344776],
            [3.125836086403979, 19.180000091092623],
            [3.146391195256598, 19.20194508713378],
            [3.191109151736669, 19.256109113242829],
            [3.272573204202587, 19.37673609966275],
            [3.278054130704305, 19.405827003456992],
            [3.270418216890164, 19.425691108209961],
            [3.248400130653152, 19.445973134656398],
            [3.211527132653089, 19.556664213024348],
            [3.212500103976424, 19.584164231591487],
            [3.223054093570141, 19.623336050362909],
            [3.230836187776163, 19.737500086502465],
            [3.233054039362543, 19.817145098336127],
            [3.028609194420511, 19.917773198999626],
            [3.006109148749545, 19.927773144792027],
            [2.902218140808685, 19.966944963563535],
            [2.702636140826314, 20.002500158742819],
            [2.587782274053609, 20.00166414771779],
            [2.514863236623, 20.022218083103908],
            [2.421663178574164, 20.05305393150617],
            [2.405900171418438, 20.065554031384622],
            [2.386391124085009, 20.108891153131751],
            [2.376663254870493, 20.126945102069797],
            [2.355282193276651, 20.161109068956236],
            [2.344163263407097, 20.177773130705745],
            [2.320000248141667, 20.203053956582025],
            [2.275763245266972, 20.242009019336663],
            [2.227500246648049, 20.27416417930236],
            [2.203609140322243, 20.283054025835057],
            [2.173891102530746, 20.276527037814148],
            [2.129163255404762, 20.236382080081327],
            [2.090836164837611, 20.218745047008198],
            [1.900282145152829, 20.259718136777238],
            [1.810282130107282, 20.301109148239689],
            [1.789718136437358, 20.31290902392486],
            [1.715000174941991, 20.37000017826908],
            [1.668400145917616, 20.415618015876632],
            [1.671945020418491, 20.464718031349165],
            [1.661663275040553, 20.538191112581401],
            [1.62750014634463, 20.57110902573821],
            [1.577918171438114, 20.599864150490177],
            [1.518891132731142, 20.626664112502183],
            [1.478336132293691, 20.64139111642244],
            [1.351391202518641, 20.68194494339339],
            [1.176391145323805, 20.733745105162441],
            [1.163400201193951, 20.754854090178483],
            [1.160282133204021, 20.800135981105782],
            [1.170763200239975, 20.840554021244898],
            [1.181391118219977, 20.877500109440902],
            [1.186391258754213, 20.900554031275121],
            [1.19250015744359, 20.937500119471238],
            [1.197145072920307, 21.002482165124903],
            [1.194863183593469, 21.022773076388731],
            [1.185554074262711, 21.057782106755511],
            [1.170800248252107, 21.10085402508544],
            [0.768336125360008, 21.351664205762418],
            [0.401109188239928, 21.578327129151816],
            [0.267218175283858, 21.660827017214942],
            [0.149718141671286, 21.733054044717633],
            [0.01555404630912, 21.815273139023631],
            [-0.008054925154994, 21.830554019107396],
            [-0.107499836363132, 21.900553974930133],
            [-0.42333682205674, 22.119718112441788],
            [-0.773890812609892, 22.361109045580022],
            [-1.142781892791191, 22.612782059372179],
            [-1.412217835842398, 22.795553984851139],
            [-1.750554867206972, 23.023327007500072],
            [-2.089999818536398, 23.250000156811538],
            [-2.448336741485718, 23.487218075623062],
            [-2.824981920316219, 23.734718075088679],
            [-3.233772871380779, 24.000536053443156],
            [-3.462781889475536, 24.148891046197278],
            [-3.79221784255725, 24.360273089502996],
            [-4.123054914577494, 24.571109135634217],
            [-4.525281829608957, 24.825000168650931],
            [-4.80610877910749, 25.000273140613857],
            [-4.813054862288396, 25.005836042128848],
            [-4.883608861912535, 25.049718155222067],
            [-4.918890807047518, 25.070827140237995],
            [-5.289445862651775, 25.30000010835947],
            [-5.641663828627884, 25.514791071695967],
            [-5.821945993580641, 25.624718055768355],
            [-5.857781814878962, 25.645553958378187],
            [-6.286108803005277, 25.904444964291073],
            [-6.30416392540991, 25.914445077721652],
            [-6.662781809754335, 26.129164124328682],
            [-6.771108856144309, 26.192500131832205],
            [-6.986945881000082, 26.320827069806811],
            [-7.203890825820679, 26.448609016435299],
            [-7.258336819153527, 26.479718114881749],
            [-7.69360888463217, 26.734164197528273],
            [-7.711945974260317, 26.743891060914578],
            [-8.131108859981254, 26.986382202666633],
            [-8.204445986743679, 27.027500131722704],
            [-8.644163981317149, 27.278891178291346],
            [-8.666790929002531, 27.290454013754228],
            [-8.667217735513276, 27.499445035353446],
            [-8.667217735513276, 27.599718078597405],
            [-8.666663859349939, 27.666664171808733],
            [-8.667217735513276, 27.933327046005743],
            [-8.667217735513276, 28.709445014114536],
            [-8.628336768782788, 28.732773191821437],
            [-8.57847282665287, 28.759853947590486],
            [-8.555554859288378, 28.769718106551167],
            [-8.521108757540418, 28.779444969937188],
            [-8.495554849258042, 28.790554009160999],
            [-8.410554974746759, 28.849445093397989],
            [-8.396663981851418, 28.872918110392206],
            [-8.374999779567588, 28.903326984645432],
            [-8.359726778472719, 28.917636066872362],
            [-8.199999890010758, 29.026664175645678],
            [-8.172363916973808, 29.043473076682787],
            [-8.144999852876595, 29.056527052724689],
            [-7.986945992293073, 29.122773089380601],
            [-7.798054942202839, 29.256109052706861],
            [-7.690281939614465, 29.341109094856236],
            [-7.662917875517422, 29.369582084746597],
            [-7.642363940131105, 29.386109018559907],
            [-7.612917811279317, 29.393327010680636],
            [-7.58971787669131, 29.39555408236059],
            [-7.560554888529708, 29.393609145542328],
            [-7.539445903513581, 29.389718098439218],
            [-7.518336750859447, 29.387218111991146],
            [-7.487781863852518, 29.385273175172969],
            [-7.461945988346599, 29.388609172646071],
            [-7.436390906597751, 29.396109131990315],
            [-7.356390837344605, 29.46166416454669],
            [-7.336108810898196, 29.486382061804065],
            [-7.316808808230604, 29.517427122510142],
            [-7.296108860090669, 29.539854077985083],
            [-7.19499980582151, 29.597773196727232],
            [-7.1262549561838, 29.63582703725011],
            [-7.104999790776077, 29.640273133982831],
            [-6.840836735389047, 29.612218065785882],
            [-6.78528181626325, 29.603609013010427],
            [-6.732290921325784, 29.589236060681046],
            [-6.696945944278752, 29.575136023120066],
            [-6.635554873593492, 29.566109048650887],
            [-6.583399822043162, 29.568327067875359],
            [-6.554163911323514, 29.586936066443414],
            [-6.527781871005118, 29.6129181223411],
            [-6.511254937191808, 29.63389115288733],
            [-6.49736394429658, 29.669445006961936],
            [-6.481663969052704, 29.739582090721044],
            [-6.400281891600144, 29.804445113349331],
            [-6.224445991018285, 29.798882211834339],
            [-6.195136822464804, 29.808264243722775],
            [-6.161945994539877, 29.841391201545335],
            [-6.139308820932627, 29.848191104334447],
            [-6.111108745810355, 29.848891160889579],
            [-6.08499978789817, 29.847918021928351],
            [-6.063890802882213, 29.843745007601726],
            [-6.039026725233072, 29.833054057709589],
            [-5.992917875736254, 29.830554071261432],
            [-5.941254841903486, 29.840691144990316],
            [-5.898326757032493, 29.856673087457622],
            [-5.790554927910591, 29.876391179456803],
            [-5.733336703913977, 29.884718097370566],
            [-5.694446014175668, 29.889164194103486],
            [-5.627499920964539, 29.894164166999545],
            [-5.569481895764511, 29.895226992325163],
            [-5.530690783036221, 29.905973095140311],
            [-5.39083671650647, 29.976391140294552],
            [-5.347499762397433, 30.000273194164876],
            [-5.313608877917403, 30.025835987264671],
            [-5.27583666934197, 30.05527323129904],
            [-5.223054986708433, 30.107500031940944],
            [-5.170836735607878, 30.160273165033132],
            [-5.152136709571352, 30.180473216466382],
            [-5.139163870352291, 30.192364119620422],
            [-5.080972842670434, 30.262364243081137],
            [-5.048054929513626, 30.316664223660084],
            [-5.029163963722056, 30.359164160915682],
            [-5.010836764739707, 30.393891056420856],
            [-4.994445953034301, 30.423609094212352],
            [-4.969717829855057, 30.46332707779645],
            [-4.938190809715195, 30.495553986853352],
            [-4.915136720242799, 30.509854016624885],
            [-4.639163898524799, 30.600827002993739],
            [-4.467781806026693, 30.631664192500367],
            [-4.429999874443638, 30.637218041560047],
            [-4.399581947735015, 30.647218154990341],
            [-4.378890884412584, 30.660273136860738],
            [-4.359999918621099, 30.679164102652223],
            [-4.337363918480207, 30.693473184879153],
            [-4.261108883576298, 30.727773106235205],
            [-4.215136826739524, 30.742636064625643],
            [-4.164999802203283, 30.772500115170757],
            [-4.149446007351401, 30.786944984229606],
            [-4.132781945601693, 30.80444505700423],
            [-4.033336699117513, 30.901527109700581],
            [-4.008054867413108, 30.91332698538595],
            [-3.987499758560404, 30.918327125920186],
            [-3.925281896943602, 30.928609038936017],
            [-3.879999838378211, 30.934164229100261],
            [-3.840554937200608, 30.938053935098608],
            [-3.800281903986701, 30.941109138814582],
            [-3.75028183974888, 30.94097318434477],
            [-3.704717813959888, 30.941944982201719],
            [-3.646663914214116, 30.9611090303993],
            [-3.6222177582585, 30.973609130277865],
            [-3.602217866673868, 30.991391170276202],
            [-3.59284589306904, 31.02826416827638],
            [-3.600136807747617, 31.090827029028745],
            [-3.697781789062788, 31.150273162895829],
            [-3.781663852963334, 31.152636021407616],
            [-3.80152678424983, 31.147636048511274],
            [-3.823890875450815, 31.161591079147044],
            [-3.825554850873857, 31.182782206814423],
            [-3.803890816228005, 31.339164212986063],
            [-3.826599906564837, 31.452918038782954],
            [-3.842990885908137, 31.489791204421195],
            [-3.834999914674455, 31.650273134723236],
            [-3.828336804545557, 31.682773126186518],
            [-3.813472840326909, 31.698054006270397],
            [-3.791390883987617, 31.705553965614641],
            [-3.717917802755494, 31.716109128674944],
            [-3.685281856822201, 31.715826993813138],
            [-3.654308880483853, 31.70853607913476],
            [-3.630554902094332, 31.696391204313827],
            [-3.569163831409071, 31.682500043780337],
            [-3.547781931624911, 31.678053947047502],
            [-3.517081870054682, 31.673264192283582],
            [-3.481390888043506, 31.679445007702341],
            [-3.419999817358246, 31.707773158305585],
            [-3.386108765240209, 31.72610907446726],
            [-3.2874998650571, 31.744718073035116],
            [-3.234717847147408, 31.758054016300733],
            [-3.159717750790435, 31.778326990291546],
            [-3.096945845372318, 31.796664247557786],
            [-2.999445870982441, 31.83332702742571],
            [-2.97639077568175, 31.847500155182743],
            [-2.959445920175028, 31.868891107422385],
            [-2.949999850545936, 31.898609145214081],
            [-2.94639077066654, 31.939864202206635],
            [-2.9484728354212, 31.963054078510751],
            [-2.943472862524942, 32.011382120698514],
            [-2.934445888055961, 32.032909027408081],
            [-2.895490825301152, 32.068609061874568],
            [-2.85944595933708, 32.086527056342717],
            [-2.46389087161387, 32.143609158231627],
            [-2.417781854478761, 32.149164180757609],
            [-2.338190822101751, 32.157218016265162],
            [-2.302499840090661, 32.157218016265162],
            [-2.26416386470629, 32.15471802981709],
            [-2.166390807910318, 32.146109144679613],
            [-2.134999909878161, 32.143053940963554],
            [-2.102363963945066, 32.13610903124912],
            [-1.982781865577834, 32.124718024801879],
            [-1.931108773461375, 32.121109112560745],
            [-1.901663818075974, 32.119991134311917],
            [-1.659445926368306, 32.111109166768344],
            [-1.492499872319229, 32.107773169295044],
            [-1.47249981309642, 32.10944502370721],
            [-1.31221787500445, 32.100418049238229],
            [-1.286254929845967, 32.092491115744977],
            [-1.25916394815502, 32.085273123624162],
            [-1.238608839302401, 32.085826999787585],
            [-1.207781875717586, 32.091809164100752],
            [-1.180554939556743, 32.112218092561577],
            [-1.175972888354011, 32.138744972167203],
            [-1.206663897468928, 32.172773152221723],
            [-1.241663875380397, 32.167773179325465],
            [-1.27583672708397, 32.156245044941826],
            [-1.291872816645991, 32.172291192787654],
            [-1.257726787032567, 32.208018049344574],
            [-1.25805485472381, 32.240826997759612],
            [-1.250417767443082, 32.32347323385271],
            [-1.215972839161623, 32.382500104921704],
            [-1.19152685084407, 32.405691154692207],
            [-1.151108810704955, 32.410553999652066],
            [-1.117636686108654, 32.416935980747766],
            [-1.0638909169692, 32.450000074296256],
            [-1.040281945505086, 32.47193601788193],
            [-1.011808787976634, 32.505553987593757],
            [-1.266945874722978, 32.667218101523062],
            [-1.382781932912479, 32.724445042699116],
            [-1.542499768919157, 32.93943599824614],
            [-1.486181928963987, 32.981873238865759],
            [-1.477499785992478, 33.019573195435655],
            [-1.478263880288239, 33.050754042973367],
            [-1.490208762898817, 33.073473191594005],
            [-1.51721776957649, 33.088609064752276],
            [-1.548890802470055, 33.111382192829453],
            [-1.5805549505464, 33.145827121110997],
            [-1.646108809636218, 33.226391124811457],
            [-1.666663918488922, 33.258882063819144],
            [-1.667290884848285, 33.376518051901726],
            [-1.648890930945981, 33.412218086368213],
            [-1.629163954129467, 33.443191062706759],
            [-1.599863838031496, 33.516245048778885],
            [-1.600690796601015, 33.559718124995996],
            [-1.605554982665353, 33.589854084481104],
            [-1.654372863276109, 33.662773121911826],
            [-1.695136740741304, 33.678464212338085],
            [-1.728272918657353, 33.704491195237111],
            [-1.70833672953691, 33.890827105956149],
            [-1.687499821098669, 33.966382084304755],
            [-1.654445953472049, 34.08360903551123],
            [-1.65833666529889, 34.100000182492622],
            [-1.719163969175014, 34.266664101680092],
            [-1.750554867206972, 34.3494451187767],
            [-1.793336771686285, 34.378327145543167],
            [-1.732499912440431, 34.440273098220061],
            [-1.692581936646178, 34.489082094013483],
            [-1.73257283499828, 34.500754061856057],
            [-1.854963876765623, 34.61437310664941],
            [-1.819717806176186, 34.648327023041688],
            [-1.800836731030614, 34.66389121145356],
            [-1.757599856845559, 34.754645094872771],
            [-1.797845900331055, 34.770618152522744],
            [-1.856390811966094, 34.801382084195339],
            [-2.074999899847938, 34.943600182703264],
            [-2.180281800805545, 35.012218130326559],
            [-2.203263973548388, 35.037491077213716],
            [-2.209445771216821, 35.085827096396557],
            [-2.209358552999902, 35.086900565800065],
            [-2.20788693899982, 35.101349594000013],
            [-2.211009174999873, 35.122203036000073],
            [-2.212090248999971, 35.127688710000101],
            [-2.212631951499873, 35.133635246000139],
            [-2.21261494699985, 35.134905545000109],
            [-2.217342527999961, 35.150838413000074],
            [-2.218198575, 35.154114877],
            [-2.21948581700002, 35.160480853000124],
            [-2.222000289999869, 35.190397266999966],
            [-2.22200030599987, 35.200924444000051],
            [-2.218531032999977, 35.234826745],
            [-2.205292522, 35.312435315000116],
            [-2.197173535999866, 35.354079891000012],
            [-2.169510617999862, 35.411720570999975],
            [-2.16500930299992, 35.421128507000091],
            [-2.159012631999815, 35.434049824000098],
            [-2.061293646999957, 35.647157119000113],
            [-2.067130168999967, 35.73086259],
            [-2.074045802999819, 35.776252202000066],
            [-2.10138888900002, 35.905555559000035],
            [-2.069444443999856, 35.944444439000122],
            [-2.055555555999916, 35.961111109000043],
            [-1.858333332999905, 36.091666669000048],
            [-1.547222221999903, 36.287499999000076],
            [-1.366666666999834, 36.390277778999987],
            [-1.268055555999979, 36.448611109000055],
            [-1.208333332999814, 36.472222219000017],
            [-1.040277777999989, 36.568055559],
            [-1.002777778, 36.593055559000035],
            [-0.866666666999947, 36.704166669000145],
            [-0.720833332999888, 36.723611109000146],
            [-0.627777777999881, 36.747222219],
            [-0.479166666999959, 36.769444438999969],
            [-0.38055555599982, 36.823611109000169],
            [-0.333333332999814, 36.834722219000056],
            [0.006944444000169, 37.008333329000024],
            [0.298611111000071, 37.177777779000124],
            [0.6, 37.43888888900004],
            [0.78611111100011, 37.605555559000081],
            [1.494444444000067, 37.60138888900012],
            [1.533333332999973, 37.60694443900006],
            [1.638888889000043, 37.613888889],
            [1.870833333000178, 37.640277779000101],
            [2.186111111000031, 37.726388889000177],
            [2.643055555999979, 37.91666666899998],
            [2.768055556000149, 37.984722219000147],
            [2.919444444000135, 37.984722219000147],
            [3.147222222000124, 37.991666669000082],
            [3.24444444400018, 37.997222219000108],
            [3.376388889000168, 38.026388889000103],
            [4.13472222200005, 38.22083332900003],
            [4.241666667000175, 38.254166668999986],
            [4.340277778000143, 38.304166669000054],
            [4.418055556000041, 38.338888888999989],
            [4.570833333, 38.387499999000156],
            [4.738888889, 38.384722219000096],
            [5.3, 38.452777779000073],
            [5.83611111100015, 38.687499999000167],
            [6.055555556000115, 38.797222219000119],
            [6.455250727000077, 38.572124105000157],
            [6.821829373000071, 38.384150250000019],
            [6.848376564000148, 38.37637338200004],
            [7.392705815000028, 38.150596023000119],
            [7.816666667000106, 38.001666666000048],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ECU", Country: "Ecuador" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-78.591672894610895, 1.24305397211657],
              [-78.560290881396156, 1.198609098042709],
              [-78.540145982886315, 1.209445054860055],
              [-78.466535773717737, 1.19263598618511],
              [-78.455835771370175, 1.174717991716875],
              [-78.441535909236734, 1.154445017725948],
              [-78.353763971699493, 1.068745086659476],
              [-78.332226839068028, 1.052782087293394],
              [-78.310154941012684, 1.043891067294226],
              [-78.283617835485074, 1.038891094398025],
              [-78.248335890350063, 1.029864119928959],
              [-78.230008859005807, 1.018054018321891],
              [-78.175290956733249, 0.970553940531886],
              [-78.100008893152562, 0.915973166195784],
              [-78.070008888137636, 0.904445031812145],
              [-78.032091002084485, 0.900553984709177],
              [-77.999308875759596, 0.904309077342248],
              [-77.929735894085809, 0.875282043650373],
              [-77.883335857271788, 0.825836023213867],
              [-77.853899954341898, 0.813744960211523],
              [-77.814181970758, 0.80833595043913],
              [-77.780008951416221, 0.827918087968442],
              [-77.698617821508094, 0.845282038635276],
              [-77.678346020983724, 0.837217977205796],
              [-77.656335813735666, 0.809400116869313],
              [-77.661664021961144, 0.781391149140006],
              [-77.659308874800445, 0.750553959633407],
              [-77.646954955313674, 0.728891098454028],
              [-77.624726818582417, 0.710835976049395],
              [-77.57999997728507, 0.68610902633651],
              [-77.56529091063743, 0.67389106131975],
              [-77.535845955252057, 0.655691099627916],
              [-77.51348186405113, 0.658473053299758],
              [-77.493335792074618, 0.665282176182359],
              [-77.463763934674716, 0.655553971691688],
              [-77.443481908228307, 0.621809099965276],
              [-77.446254977082674, 0.585136094175425],
              [-77.451117822042534, 0.541244928626838],
              [-77.422090955988779, 0.412782036182477],
              [-77.379455064263141, 0.384718083168082],
              [-77.311672960027067, 0.363054048522201],
              [-77.218899876127068, 0.336109079585071],
              [-77.191746030162221, 0.334918011140473],
              [-77.079608905854172, 0.359564159306387],
              [-77.035281881339415, 0.306245029039701],
              [-76.866599935137742, 0.239373199490672],
              [-76.81139990579328, 0.241391058867009],
              [-76.770281976736953, 0.248891018211225],
              [-76.726963965729112, 0.263053920046161],
              [-76.716663947802317, 0.283191107205539],
              [-76.611955033747336, 0.258891131641519],
              [-76.584872936873694, 0.230836063444769],
              [-76.566672975181916, 0.220553982790648],
              [-76.545154953289739, 0.219164095602196],
              [-76.448626776756896, 0.246945075564554],
              [-76.407790982562346, 0.271664146288401],
              [-76.400846072847912, 0.392318122436563],
              [-76.243054901110497, 0.395554039985598],
              [-76.145282011952702, 0.370835975090245],
              [-76.040117792915851, 0.337636094709751],
              [-76.024872955015894, 0.320126969479702],
              [-75.98028190055021, 0.256391145193447],
              [-75.912781931175459, 0.163336094070132],
              [-75.760846021736882, 0.046809031780811],
              [-75.72750884578204, 0.035553979803467],
              [-75.694035882995422, 0.043053939147683],
              [-75.575426923589703, 0.031809113092322],
              [-75.518890986513497, -0.00805505088357],
              [-75.434445993993904, -0.059446008138309],
              [-75.39903580573997, -0.072081894848623],
              [-75.34972691323992, -0.078335800463435],
              [-75.325563897974291, -0.0883359138939],
              [-75.300008816225443, -0.10221785433373],
              [-75.285845914390393, -0.119717927108468],
              [-75.305281871527882, -0.134717845797013],
              [-75.34972691323992, -0.151255005532221],
              [-75.425008809182373, -0.164308981574209],
              [-75.479872891846526, -0.143755046187891],
              [-75.519026773345217, -0.116872941524718],
              [-75.615817806362429, -0.106517938312834],
              [-75.624499949333824, -0.172026870401623],
              [-75.571190877351057, -0.180135858832216],
              [-75.481117939747662, -0.232364000578571],
              [-75.464735845221696, -0.273608831649256],
              [-75.459163891251194, -0.294999951526904],
              [-75.44861794828438, -0.346317818585817],
              [-75.434445993993904, -0.384163955547308],
              [-75.413346061433288, -0.41958202279028],
              [-75.389454955107453, -0.446946086887408],
              [-75.363890988541357, -0.466664011248611],
              [-75.339799890005281, -0.464655036689919],
              [-75.262508851865903, -0.523054941399607],
              [-75.244381980370008, -0.560972995090594],
              [-75.249026895846725, -0.629581890258464],
              [-75.266964001054134, -0.646035901513926],
              [-75.277235855786444, -0.650282006036377],
              [-75.283426897127185, -0.656217902243668],
              [-75.292781939287408, -0.730554990057726],
              [-75.263335810435706, -0.814717847715599],
              [-75.216845919619175, -0.96936396173453],
              [-75.330426910572157, -0.979717959118034],
              [-75.359590904562424, -0.967781961324761],
              [-75.376955022867151, -0.950835932351538],
              [-75.402235848743203, -0.922781869982998],
              [-75.431946007545832, -1.038054999553594],
              [-75.444173025018131, -1.085555077343599],
              [-75.461672930154919, -1.153608922881517],
              [-75.483608873740309, -1.241945968332459],
              [-75.553617882018443, -1.513890950287248],
              [-75.563946063140179, -1.539954981198321],
              [-75.58818200096357, -1.555708935898622],
              [-75.627281903005553, -1.596372901077643],
              [-75.719726919214281, -1.708055055679836],
              [-75.781399957122972, -1.78194605860557],
              [-75.977508831696014, -2.016390908562755],
              [-76.074172962698754, -2.131108820865734],
              [-76.403899935459378, -2.380282015847911],
              [-76.66062690160436, -2.572135901080856],
              [-76.90444607209929, -2.656664042080081],
              [-77.043335884485032, -2.704717828395246],
              [-77.224164046612145, -2.767217824873683],
              [-77.439726815595293, -2.841390962661023],
              [-77.79444594148589, -2.963335919539929],
              [-77.810245996653805, -2.96815501096512],
              [-77.859590931337777, -2.985835797572804],
              [-77.925381998288231, -3.039963949136222],
              [-78.03445503406266, -3.156181886835554],
              [-78.06722693446585, -3.215282015738467],
              [-78.116390987678642, -3.27597302986851],
              [-78.141399904615071, -3.294163939104862],
              [-78.175846006363145, -3.314163998327672],
              [-78.20042677568415, -3.334308896837797],
              [-78.215708829234188, -3.36055498268621],
              [-78.203472926944528, -3.392426834323828],
              [-78.181190978394966, -3.413126950101855],
              [-78.17056389860511, -3.451946058386682],
              [-78.181399855422569, -3.472217858911222],
              [-78.246946003161611, -3.519863949454816],
              [-78.255846075616205, -3.516946041313247],
              [-78.252926826370071, -3.451808930450397],
              [-78.261399924675857, -3.424717948759451],
              [-78.279445994624894, -3.409999997294392],
              [-78.301399875483412, -3.40778197806992],
              [-78.329454943680162, -3.417217821777001],
              [-78.346117831963454, -3.434999861775339],
              [-78.352235783108426, -3.456664064059282],
              [-78.367917821079232, -3.518335928501699],
              [-78.37069994238891, -3.539717828285859],
              [-78.367090862509485, -3.572845959574806],
              [-78.372508924737247, -3.633890857658002],
              [-78.38862682167445, -3.711390940462877],
              [-78.399455067141048, -3.746108951150688],
              [-78.414173018605993, -3.776526877859453],
              [-78.420990858667835, -3.78585493029135],
              [-78.466672901653908, -3.832499886316839],
              [-78.531681937036069, -3.91278192279367],
              [-78.540846039441249, -3.931945970991364],
              [-78.553617880621459, -3.978608864289697],
              [-78.55542686296991, -4.010135884429758],
              [-78.555554938450825, -4.046946018155779],
              [-78.558063977354379, -4.073054976068136],
              [-78.563608941596442, -4.095555021739017],
              [-78.64153583091246, -4.278472959971623],
              [-78.659472936119982, -4.300672933507883],
              [-78.672072948284722, -4.307090956787363],
              [-78.672499922433644, -4.340281952350381],
              [-78.670145948739275, -4.365135804077568],
              [-78.6528460358129, -4.417635854763546],
              [-78.65028201162437, -4.473608863221045],
              [-78.666808945437595, -4.554864038659105],
              [-78.683617846474533, -4.57444600855041],
              [-78.709026915469849, -4.584790953478375],
              [-78.723482010450425, -4.588890877609231],
              [-78.767226827968955, -4.603890963935783],
              [-78.790846025355052, -4.614163992134422],
              [-78.823763938511888, -4.635973033705568],
              [-78.859308907769162, -4.661526941987972],
              [-78.876108923988738, -4.677217864776253],
              [-78.906108929003949, -4.716663939420414],
              [-78.918399984216762, -4.741046057635515],
              [-78.918481959229837, -4.78263588784182],
              [-78.928617859492192, -4.868335818908236],
              [-79.011399882417095, -4.972217942031705],
              [-79.036663944486747, -4.995555004556081],
              [-79.063208929003281, -5.00030888477437],
              [-79.100273034396537, -4.975055048626416],
              [-79.183626870757507, -4.96389085647985],
              [-79.203608825069409, -4.962499963462903],
              [-79.231917864933337, -4.963854981934105],
              [-79.285708896349945, -4.964582028217535],
              [-79.365573011133336, -4.886108974089268],
              [-79.445035800391565, -4.797399939774323],
              [-79.451672926620347, -4.766390921252139],
              [-79.51528201653025, -4.620417897749121],
              [-79.519726939796442, -4.581181873599064],
              [-79.539172955217737, -4.520835858604755],
              [-79.621390876057319, -4.44749990530886],
              [-79.643199917628579, -4.435417894761883],
              [-79.668063995277748, -4.434308968968821],
              [-79.705281992413575, -4.457081929407707],
              [-79.783763931359431, -4.481946007056877],
              [-79.815635782996878, -4.478817880783026],
              [-79.99779096803826, -4.373055026219788],
              [-80.025145979680076, -4.354999903815155],
              [-80.058763949392016, -4.319582004210361],
              [-80.07792682412321, -4.300135821150946],
              [-80.134599889135927, -4.284490998830222],
              [-80.174581902670752, -4.298545941752252],
              [-80.186535837736699, -4.321255032088914],
              [-80.226390949256995, -4.364445973444219],
              [-80.262217885737982, -4.397499841070953],
              [-80.334173004300794, -4.452499878205117],
              [-80.358617819151846, -4.465555027713378],
              [-80.390563934451791, -4.478608836117303],
              [-80.409726809183212, -4.471663926402698],
              [-80.463763933277733, -4.44180892831298],
              [-80.481254953597045, -4.416455012240931],
              [-80.479999847412103, -4.388473034239951],
              [-80.431117929060861, -4.315555002637552],
              [-80.39917298722753, -4.281808957444809],
              [-80.382926847171177, -4.263473041283021],
              [-80.340281902990171, -4.19951794640923],
              [-80.382926847171177, -4.196663908370112],
              [-80.4105639936746, -4.20986406480381],
              [-80.445563971585898, -4.221181981055111],
              [-80.464582007030117, -4.207499865187458],
              [-80.480763941707608, -4.174164030337238],
              [-80.483608927291414, -4.11444598753053],
              [-80.501217797169545, -4.055346026265568],
              [-80.501681986968777, -4.050282015628966],
              [-80.46542690287248, -3.985282032702571],
              [-80.420290857060706, -3.97805498812636],
              [-80.389172873796952, -3.979026953621315],
              [-80.345699965217932, -3.998263924376943],
              [-80.318964040946639, -4.011663905382875],
              [-80.292235828025923, -3.996108937064491],
              [-80.175290844043161, -3.90305505940745],
              [-80.153335789718199, -3.884226790251887],
              [-80.176673019880809, -3.859164061496926],
              [-80.211399915385755, -3.739164041436311],
              [-80.207781950689082, -3.717217871928653],
              [-80.206117807628004, -3.636663926512369],
              [-80.212781923585283, -3.595835843668567],
              [-80.234999834394557, -3.497217891030061],
              [-80.259172907943935, -3.417426866442781],
              [-80.340427075914533, -3.380518032595887],
              [-80.342643004355523, -3.392766663678799],
              [-84.469150578999916, -3.392766650999874],
              [-84.56389756599981, -3.392766650999874],
              [-84.56351013699981, -3.388530362999916],
              [-84.559623057999943, -3.346026984999924],
              [-84.558738578999908, -3.336356650999846],
              [-84.558865060999892, -3.334852890999855],
              [-84.55921626699984, -3.330673662999857],
              [-84.55450598599981, -3.279131730999836],
              [-84.554447600999822, -3.278492884499883],
              [-84.554924578999987, -3.272816650999914],
              [-84.55265727699998, -3.24798481699986],
              [-84.550159140999824, -3.220629403999908],
              [-84.550636253999812, -3.214959876999842],
              [-84.545873229, -3.162765609999781],
              [-84.546350109999878, -3.157102790999829],
              [-84.543425414999774, -3.125030292999838],
              [-84.541589578999947, -3.104901650999921],
              [-84.542037559999926, -3.099589475999835],
              [-84.537309118999985, -3.04703778299978],
              [-84.53778554299987, -3.041388247999862],
              [-84.533030578999956, -2.989173650999817],
              [-84.533239805, -2.986698469999908],
              [-84.533507122999822, -2.983530763999909],
              [-84.528755288999889, -2.931309636999913],
              [-84.52923127299988, -2.925673164999864],
              [-84.524482226999964, -2.873445444999845],
              [-84.524711349999905, -2.870734112999855],
              [-84.524957578999988, -2.867815650999887],
              [-84.520211578999863, -2.815581650999832],
              [-84.520343599999876, -2.814021807999893],
              [-84.520687285999941, -2.809957582999914],
              [-84.517966181999952, -2.779989588999854],
              [-84.515943578999838, -2.757716650999896],
              [-84.516114477999849, -2.755699972999821],
              [-84.516419148999887, -2.752099600999884],
              [-84.511678448, -2.69985239399989],
              [-84.512153572999864, -2.69424164999981],
              [-84.507415578999883, -2.641987650999852],
              [-84.507776951999858, -2.637724211999796],
              [-84.507890562999933, -2.6363834779998],
              [-84.506716768999979, -2.623428366999917],
              [-84.503155426999854, -2.584123297999895],
              [-84.503630151999857, -2.578524888999823],
              [-84.500292685999966, -2.541664669999761],
              [-84.498897578999902, -2.526258649999761],
              [-84.499074358999849, -2.524177479999878],
              [-84.499372289999826, -2.5206663969999],
              [-84.495581865999895, -2.478774067999893],
              [-84.494642578999844, -2.468393649999882],
              [-84.495116578999841, -2.462807649999945],
              [-84.492965800999912, -2.439015757999812],
              [-84.490390131000026, -2.410529062999899],
              [-84.490864266999836, -2.404949030999887],
              [-84.486140159999877, -2.352664160999836],
              [-84.486614104999887, -2.347090157999901],
              [-84.48346900499979, -2.312256854999816],
              [-84.481892578999862, -2.294799649999959],
              [-84.482150122999826, -2.291774314999884],
              [-84.482366508999974, -2.289231157999893],
              [-84.481978278999918, -2.284928344999855],
              [-84.477647578999836, -2.236933649999798],
              [-84.478047172999936, -2.232244739999885],
              [-84.478121477999878, -2.231372031999797],
              [-84.473766947999877, -2.183076435999908],
              [-84.473405596999896, -2.1790688184999],
              [-84.473878578999916, -2.17351264999985],
              [-84.469165578999821, -2.12120364999987],
              [-84.46935562199991, -2.118978457999901],
              [-84.469639109999832, -2.115653398999768],
              [-84.466228804000025, -2.077777476999813],
              [-84.464928579, -2.063338649999764],
              [-84.465401578999831, -2.057793649999894],
              [-84.464184731999865, -2.044267703999765],
              [-84.460694130999968, -2.005473108999922],
              [-84.460937879999847, -2.002617978999979],
              [-84.461166578999951, -1.999934649999886],
              [-84.458913919999986, -1.974876213999892],
              [-84.456462087999796, -1.947607661999768],
              [-84.456801485999847, -1.943634826999869],
              [-84.456934578999949, -1.942074649999881],
              [-84.452604290999943, -1.89387878299982],
              [-84.452232598666455, -1.889741938666589],
              [-84.452293820999842, -1.889026037999827],
              [-84.452705133999814, -1.884214619999909],
              [-84.448943688999805, -1.842323206999936],
              [-84.448005578999812, -1.831876650000027],
              [-84.448477578999871, -1.826354649999757],
              [-84.445554047999963, -1.793767493999894],
              [-84.443781311999885, -1.774010853999812],
              [-84.444253518999972, -1.768494473999908],
              [-84.439559502999884, -1.716145095999877],
              [-84.440031545999773, -1.710634289999916],
              [-84.439824559999977, -1.708324171999891],
              [-84.435340251999889, -1.65827926199978],
              [-84.435812147999854, -1.652773825999816],
              [-84.431123569499903, -1.60041349949978],
              [-84.431595302999824, -1.594913313999882],
              [-84.426909419999845, -1.54254736199988],
              [-84.427047511999945, -1.54093842799989],
              [-84.42738057899993, -1.537052648999918],
              [-84.422697578999873, -1.484681648999867],
              [-84.42291804499996, -1.482117320999805],
              [-84.42316929299983, -1.479191913999784],
              [-84.420712661999971, -1.451701055999877],
              [-84.418488578999927, -1.42681564899982],
              [-84.418637468999862, -1.425085434999858],
              [-84.418960126999821, -1.421331025999905],
              [-84.41428234499989, -1.368948936999914],
              [-84.41475352, -1.363470013999915],
              [-84.410078430999818, -1.311082641999917],
              [-84.410549470999797, -1.305608875999837],
              [-84.359162363, -0.727628712999888],
              [-84.351954219999868, -0.700871047999954],
              [-84.349332384999968, -0.671268459999908],
              [-84.198584166999865, -0.110911231999921],
              [-83.952986166999892, 0.414859700000193],
              [-83.951108197999872, 0.417538198000329],
              [-83.930537097999945, 0.461607921000223],
              [-83.927933577999909, 0.467185353000218],
              [-83.926050577999945, 0.469870352000271],
              [-83.910880641999768, 0.502374271000292],
              [-83.902882272999875, 0.519511366000245],
              [-83.900994695666668, 0.522204148666788],
              [-83.877831577999984, 0.571838352000327],
              [-83.875939577999873, 0.574537352000164],
              [-83.865051143999892, 0.597872936000215],
              [-83.852782155999989, 0.624166408000235],
              [-83.851035964499886, 0.626657832000149],
              [-83.8401662509998, 0.649847698000258],
              [-83.827733514999977, 0.6764951950002],
              [-83.82694530699996, 0.677619914000275],
              [-83.825832577999904, 0.679207353000209],
              [-83.802685577999796, 0.728824353000221],
              [-83.800780577999831, 0.73154335300012],
              [-83.77763857799988, 0.781155353000258],
              [-83.776329710999846, 0.783023986000217],
              [-83.775730340999814, 0.783879396000316],
              [-83.752593258999923, 0.833486619000269],
              [-83.751749239999981, 0.834691288000215],
              [-83.750680577999844, 0.836216353000168],
              [-83.727394695999919, 0.8860380925002],
              [-83.725632121999979, 0.888554114000328],
              [-83.706134772999917, 0.930366555000148],
              [-83.702504577999889, 0.938151353000194],
              [-83.700584577999962, 0.940892353000322],
              [-83.67746157799985, 0.990485353000281],
              [-83.67687787199992, 0.991318872000022],
              [-83.675538233999873, 0.993231411000295],
              [-83.652419484999825, 1.042820334000169],
              [-83.651460448999899, 1.044189613000242],
              [-83.650492577999842, 1.045571353000355],
              [-83.643161806999785, 1.061297591000141],
              [-83.627378405999934, 1.095155873000294],
              [-83.625539828999877, 1.097781096000176],
              [-83.621658717999935, 1.106042250000144],
              [-83.602338273999862, 1.147492255000031],
              [-83.600405523999825, 1.15025219200021],
              [-83.577299086999972, 1.199829482000325],
              [-83.57588841199987, 1.201844054000219],
              [-83.575363577999781, 1.202593353000282],
              [-83.552260577999903, 1.252167353000289],
              [-83.551281212999868, 1.253566663000242],
              [-83.550322468999809, 1.254935936000095],
              [-83.529338023999856, 1.299968794000222],
              [-83.527223577999962, 1.304506353000249],
              [-83.52528257799986, 1.307278353000072],
              [-83.518297255999926, 1.322270708000246],
              [-83.502187208999942, 1.356846226000187],
              [-83.500424981499862, 1.359363311500204],
              [-83.477151577999905, 1.409186353000166],
              [-83.475205577999816, 1.411966353000139],
              [-83.457647909333332, 1.449656449666747],
              [-78.857284628999821, 1.44965671000017],
              [-78.809717880203607, 1.437782179797296],
              [-78.677508947785213, 1.285553909372254],
              [-78.656681929992885, 1.271945051338548],
              [-78.631954980280113, 1.262218020314322],
              [-78.591672894610895, 1.24305397211657],
            ],
          ],
          [
            [
              [-89.041401582999924, -4.687401651999778],
              [-89.621250582999835, -4.738296651999917],
              [-89.690821583999934, -4.732243651999909],
              [-89.704007583999953, -4.733398652],
              [-89.782109583999841, -4.726600651999831],
              [-89.796918583999911, -4.727898651999851],
              [-89.875014583999928, -4.721097651999912],
              [-89.889827583999875, -4.722395651999932],
              [-89.967918583999847, -4.715592651999771],
              [-89.98273558399984, -4.71689065199979],
              [-90.060822583999823, -4.710085651999918],
              [-90.075643583999806, -4.711382651999884],
              [-90.153725583999915, -4.704576651999844],
              [-90.168550583999775, -4.705873651999866],
              [-90.246627583999953, -4.699064651999947],
              [-90.261456583999859, -4.70036165199997],
              [-90.339528583999936, -4.693550651999885],
              [-90.35436258399983, -4.694847651999908],
              [-90.427468583999882, -4.688467651999872],
              [-90.441363583999816, -4.689682651999874],
              [-91.021209584999895, -4.639011651999851],
              [-91.583289584999932, -4.488485651999895],
              [-91.587671584999953, -4.486442651999766],
              [-91.588723584999855, -4.486160651999967],
              [-91.657674584999768, -4.454020651999883],
              [-91.674234584999851, -4.449583651999887],
              [-91.743176584999901, -4.417444651999858],
              [-91.759742585000026, -4.413005651999924],
              [-91.828675585999861, -4.380866651999895],
              [-91.845246585999973, -4.376425651999966],
              [-91.914171585999838, -4.344288651999875],
              [-91.930746585999827, -4.339845651999781],
              [-91.999664585999938, -4.307709651999971],
              [-92.016243585999803, -4.303264651999882],
              [-92.050268585999788, -4.287397651999868],
              [-92.536525585999811, -4.157007651999834],
              [-93.063337586999921, -3.911179651999873],
              [-93.066137086999845, -3.909268651999753],
              [-93.111548586999845, -3.877492651999887],
              [-93.119130586999859, -3.873953651999898],
              [-93.594872586999941, -3.540488650999862],
              [-94.005138587999852, -3.129586650999897],
              [-94.039789587999849, -3.079982650999909],
              [-94.042252587999883, -3.077516650999883],
              [-94.374670587999901, -2.601728649999757],
              [-94.384354587999951, -2.580889649999847],
              [-94.398968588, -2.559976649999953],
              [-94.399632587999861, -2.558546649999812],
              [-94.400635588, -2.557111649999911],
              [-94.645257587999879, -2.030914649999886],
              [-94.668067587999872, -1.945332649999898],
              [-94.668458587999794, -1.944492649999859],
              [-94.692119587999798, -1.855736649999869],
              [-94.692522587999918, -1.854870649999896],
              [-94.716191587999958, -1.766110649999973],
              [-94.716591588000028, -1.765251649999868],
              [-94.740268587999822, -1.676485649999904],
              [-94.740665587999899, -1.675633649999781],
              [-94.76435158799984, -1.586861649999889],
              [-94.764743587999988, -1.58601764999986],
              [-94.788438587999906, -1.497238648999826],
              [-94.788827587999947, -1.496402648999776],
              [-94.796927087999791, -1.466166648999888],
              [-94.946779587999856, -0.905472648999876],
              [-94.949469587999943, -0.874471648999929],
              [-94.957340587999909, -0.845051648999913],
              [-94.961414587999911, -0.798143648999883],
              [-94.973321587999919, -0.753646648999791],
              [-94.977400587999853, -0.706727648999902],
              [-94.989309587999969, -0.662242648999893],
              [-94.99339358799989, -0.615311648999921],
              [-95.005302587999893, -0.570839648999765],
              [-95.009391587999914, -0.523897648999878],
              [-95.021301587999915, -0.479437648999863],
              [-95.025396587999808, -0.432482647999848],
              [-95.037306588999911, -0.388036647999911],
              [-95.041406588999848, -0.341069647999859],
              [-95.053317588999846, -0.296637647999887],
              [-95.057423588999939, -0.249656647999871],
              [-95.06933458899988, -0.20523864799992],
              [-95.073445588999846, -0.158244647999879],
              [-95.085357588999898, -0.113840647999837],
              [-95.089474588999849, -0.066832647999888],
              [-95.101386588999958, -0.022444647999919],
              [-95.10550858900001, 0.024578352000219],
              [-95.117421588999946, 0.068950352000172],
              [-95.121549588999869, 0.115988352000329],
              [-95.133462589000032, 0.160344352000209],
              [-95.13759658899977, 0.207398352000268],
              [-95.149508588999936, 0.251738352000245],
              [-95.153648588999829, 0.298807352000267],
              [-95.165561588999935, 0.343130352000287],
              [-95.169707588999927, 0.390215352000212],
              [-95.181619588999922, 0.434521352000218],
              [-95.185772588999896, 0.481623352000327],
              [-95.197684588999834, 0.52591035200021],
              [-95.201842588999853, 0.57303035200016],
              [-95.213754588999848, 0.617299353000249],
              [-95.217919588999848, 0.664437353000096],
              [-95.229831589000014, 0.7086873530003],
              [-95.234002588999886, 0.755843353000216],
              [-95.245913588999883, 0.800074353000298],
              [-95.250091588999851, 0.847248353000282],
              [-95.262002588999906, 0.891459353000243],
              [-95.266186588999858, 0.938653353000348],
              [-95.278096588999972, 0.982843353000192],
              [-95.282287588999793, 1.030057353000302],
              [-95.29419658899991, 1.074227353000254],
              [-95.34573058899997, 1.652181353000344],
              [-95.345259588999909, 1.657667353000193],
              [-95.345744588999878, 1.663085353000326],
              [-95.29631158899997, 2.241153354000119],
              [-95.147306588999925, 2.801839355000254],
              [-94.903107587999784, 3.328152355000213],
              [-94.570965587999808, 3.804110355000205],
              [-94.160820587999865, 4.215220356000202],
              [-93.685031586999912, 4.548927356000092],
              [-93.158031586999869, 4.795006356000158],
              [-92.595890585999825, 4.945894357000213],
              [-92.588752585999941, 4.946523357000217],
              [-92.587686586000018, 4.946809357000234],
              [-92.007617585999924, 4.997850357000289],
              [-91.427423584999872, 4.947459357000184],
              [-90.864914584999951, 4.797167356000273],
              [-90.30904, 4.572394000000202],
              [-89.427760999999862, 3.421827000000178],
              [-87.1466, 2.150738000000104],
              [-87.058066080999936, 1.999329354000224],
              [-87.056561580999926, 1.9971323540002],
              [-87.052432580999891, 1.988253354000221],
              [-87.004780580999864, 1.920099354000172],
              [-87.000649580999834, 1.911217354000144],
              [-86.952998580999974, 1.843068354000195],
              [-86.948864580999782, 1.834182354000177],
              [-86.90121458099992, 1.76604035400004],
              [-86.897077580999962, 1.757149354000205],
              [-86.84942858099987, 1.689014354000108],
              [-86.845288580999807, 1.680116354000177],
              [-86.797641580999937, 1.611989353000354],
              [-86.793497580999826, 1.603085353000267],
              [-86.745853580999835, 1.534968353000295],
              [-86.741704580999794, 1.526054353000291],
              [-86.694063580999966, 1.45794835300029],
              [-86.689910580999936, 1.449025353000252],
              [-86.642272580999872, 1.38093035300011],
              [-86.638113580999914, 1.371997353000324],
              [-86.590479580999954, 1.303915353000207],
              [-86.586314580999954, 1.294971353000221],
              [-86.538685580999868, 1.226902353000241],
              [-86.534514580999826, 1.217945353000232],
              [-86.486889580999843, 1.149890353000217],
              [-86.482711580999876, 1.140921353000294],
              [-86.435092579999832, 1.072882353000352],
              [-86.430907579999769, 1.063898353000241],
              [-86.383293579999986, 0.995875353000201],
              [-86.380857579999827, 0.990646353000272],
              [-86.353182579999896, 0.951112353000212],
              [-86.107979579999835, 0.425159352000094],
              [-85.957685579999975, -0.135280647999934],
              [-85.906798579999929, -0.713239648999888],
              [-85.956858579999846, -1.291231648999883],
              [-86.10640258, -1.851766649999831],
              [-86.351001579999831, -2.377867649999814],
              [-86.360011579999878, -2.390760649999891],
              [-86.36981758, -2.411861649999821],
              [-86.391339579999908, -2.44266064999988],
              [-86.414754579999794, -2.493052649999868],
              [-86.436280580999892, -2.523858649999795],
              [-86.459689580999907, -2.574245649999909],
              [-86.481217580999839, -2.605058649999762],
              [-86.504622581000035, -2.655441650999819],
              [-86.526152581000019, -2.68625965099983],
              [-86.549553580999941, -2.736638650999907],
              [-86.571085580999863, -2.767461650999905],
              [-86.594482580999966, -2.817838650999875],
              [-86.616015580999829, -2.848664650999922],
              [-86.639409580999768, -2.899040650999837],
              [-86.660942580999915, -2.929869650999876],
              [-86.684333580999862, -2.980244650999907],
              [-86.705867580999836, -3.011075650999828],
              [-86.729256580999959, -3.061450650999916],
              [-86.731609580999873, -3.064820650999877],
              [-86.734165580999786, -3.070325650999905],
              [-87.066470580999976, -3.546201650999876],
              [-87.476704580999865, -3.957193651999887],
              [-87.952497581999978, -4.290754651999862],
              [-88.479417581999826, -4.536673651999763],
              [-89.041401582999924, -4.687401651999778],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "EGY", Country: "Egypt" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.116666667000118, 33.355555556000184],
            [31.608333333000246, 33.19166666700005],
            [32.022222222000153, 33.122222222000289],
            [32.516666667000209, 33.011111111000162],
            [32.967655051800193, 32.890114227600066],
            [32.972222222000255, 32.88888888900027],
            [32.990981423000335, 32.884955567000247],
            [33.03425725000011, 32.829211892000217],
            [33.722787992000178, 31.785865658000205],
            [33.751243008000188, 31.746038122000186],
            [33.891322463000165, 31.585824570000284],
            [34.217421946391539, 31.323964900663782],
            [34.21865109700019, 31.322977886000217],
            [34.216725776095188, 31.323247702243862],
            [34.239163203013391, 31.294718165445772],
            [34.267582213447042, 31.216545015813665],
            [34.287500130018771, 31.162773095136288],
            [34.401382198934414, 30.859445094138664],
            [34.489436103695311, 30.691109152901106],
            [34.558882183354626, 30.486109090690945],
            [34.543327215036243, 30.434718133436093],
            [34.545273325321091, 30.406945032462943],
            [34.614436096652128, 30.365000144837154],
            [34.723345182399953, 30.089344997250237],
            [34.753609217366034, 29.991109091759654],
            [34.847773194282695, 29.740836023439613],
            [34.872773226401648, 29.650827123576903],
            [34.873891204650306, 29.630836116809576],
            [34.86639124530609, 29.606664216726472],
            [34.877782251753331, 29.532500131394613],
            [34.903974334732425, 29.486454541055821],
            [34.912667225000291, 29.469825016000129],
            [34.921211000000142, 29.453380000000152],
            [34.918790304000254, 29.445543166000107],
            [34.918094416000201, 29.443290276000198],
            [34.908578060000309, 29.431557001000272],
            [34.908511826000336, 29.428057214000148],
            [34.908484720000217, 29.426624922000144],
            [34.906773571000087, 29.420267938000109],
            [34.905933712000149, 29.418887224000287],
            [34.904016850500227, 29.415719219000096],
            [34.903423936000138, 29.414675879000299],
            [34.901919661000306, 29.411837706000199],
            [34.900957666000181, 29.409784497000032],
            [34.900013771000147, 29.40778173266682],
            [34.897497455000348, 29.402383604000079],
            [34.892955204000145, 29.392617490000248],
            [34.892260660500256, 29.391044097500242],
            [34.891882902000191, 29.390237686000205],
            [34.890326761000154, 29.386345074000189],
            [34.889798637000354, 29.385384621000185],
            [34.889090308000277, 29.384096446000285],
            [34.888734673000215, 29.383235715500234],
            [34.884496975800175, 29.375334759400232],
            [34.881891514000245, 29.368969478000281],
            [34.877683743000318, 29.361467470000036],
            [34.8745831800004, 29.35379706200024],
            [34.873912369000209, 29.352240460000075],
            [34.873485822000191, 29.35125067000007],
            [34.871268782000243, 29.345876814000235],
            [34.870356931000401, 29.343500900000322],
            [34.869213293000257, 29.340521046000276],
            [34.867826454000181, 29.337949412000171],
            [34.866920926000205, 29.336249750000206],
            [34.863905740000121, 29.330046804000176],
            [34.860655079000196, 29.325890555000115],
            [34.855515134000086, 29.318975379000278],
            [34.851532380000094, 29.310353619000125],
            [34.838897571000302, 29.281965624000236],
            [34.836096277000365, 29.276239058000158],
            [34.834687557000166, 29.273335352000288],
            [34.834621726000137, 29.271523929000239],
            [34.834100403000093, 29.270352046000141],
            [34.833321454000384, 29.267793525000116],
            [34.83094007800014, 29.259037248000311],
            [34.826817082000275, 29.246859792000294],
            [34.824228463000395, 29.238528632000168],
            [34.824108009000383, 29.234765494000214],
            [34.824346342000126, 29.227385652000123],
            [34.824149685000236, 29.224458228000231],
            [34.822453948000202, 29.21688300600033],
            [34.821965097000145, 29.214821203500208],
            [34.817295210000225, 29.195528073000219],
            [34.815135439000301, 29.188398525000082],
            [34.81231635700027, 29.175117981000085],
            [34.811164631000395, 29.172827736500096],
            [34.80631793900011, 29.162161125000239],
            [34.798502343000223, 29.144277546000183],
            [34.797478080000303, 29.140025819500067],
            [34.795695994000312, 29.134936278000168],
            [34.7926142620002, 29.128333989000225],
            [34.786359629000231, 29.115739767000207],
            [34.781271960000197, 29.104954095500091],
            [34.780933866000112, 29.103294017000167],
            [34.780510980000088, 29.101157425000338],
            [34.778374630000258, 29.093355338000208],
            [34.777837423000165, 29.092269246000228],
            [34.776788889000272, 29.090244416000189],
            [34.775660500000242, 29.086187324000093],
            [34.772915798000156, 29.077413770000135],
            [34.769554395000114, 29.06710224800014],
            [34.768909300000132, 29.065467901000204],
            [34.765900315000295, 29.058117069000218],
            [34.765618854000195, 29.056568571000355],
            [34.76444192050019, 29.048000625500208],
            [34.765296227000249, 29.039921075000109],
            [34.767161589000182, 29.024073801000213],
            [34.768633083000282, 29.010075782000285],
            [34.769493073000206, 29.005392278000215],
            [34.768213057000366, 28.99952464200021],
            [34.766980252000394, 28.993675549000216],
            [34.766680426000107, 28.991228626000179],
            [34.765961719000416, 28.986226053000223],
            [34.764978007000195, 28.977282179000042],
            [34.761843348000241, 28.957498828000155],
            [34.761252373500156, 28.956234785500158],
            [34.760470239000256, 28.954558905000169],
            [34.757090139000184, 28.948212305000141],
            [34.754638882000336, 28.943758315000082],
            [34.753840153000397, 28.942145638000113],
            [34.742338221000296, 28.920741068000325],
            [34.74145748050023, 28.919655077000243],
            [34.739693193500159, 28.91733953000022],
            [34.738851767000142, 28.912036664000254],
            [34.738662725000154, 28.909345561000237],
            [34.737646107000216, 28.898024957000246],
            [34.736638715000367, 28.883762661000219],
            [34.736623545000157, 28.881278922000149],
            [34.736376835000158, 28.87339633300013],
            [34.737468573000115, 28.86327239100018],
            [34.739996474000066, 28.848539855000297],
            [34.736880805000112, 28.832018203000246],
            [34.735547320000194, 28.825570361000189],
            [34.734931668000229, 28.822400667000295],
            [34.729663499000367, 28.813975528000299],
            [34.72390990800011, 28.805003809000254],
            [34.722274550000179, 28.798191236000093],
            [34.721368033000203, 28.78956820000019],
            [34.721110727000223, 28.786168530000282],
            [34.721020674000329, 28.784601488000135],
            [34.721268288000203, 28.779993145000105],
            [34.720422163000251, 28.769110115000103],
            [34.719327805000148, 28.764734012000133],
            [34.716245942500166, 28.749518225500196],
            [34.714519146000271, 28.74458843500031],
            [34.713791062000269, 28.73894384200014],
            [34.712639930000194, 28.731221839000284],
            [34.712441508000325, 28.730349200000319],
            [34.711750362000146, 28.727500621000047],
            [34.708007256000087, 28.716620977000332],
            [34.704952646000265, 28.709033710000028],
            [34.704573328000151, 28.7081275110001],
            [34.702725127000065, 28.703446031000169],
            [34.697763227000223, 28.69007054500014],
            [34.696661023000161, 28.687566036000135],
            [34.695518489000108, 28.68469465600009],
            [34.695192588000282, 28.683986332000075],
            [34.694108417000166, 28.681747251000161],
            [34.692428611000253, 28.677334364500211],
            [34.69131556900038, 28.67401862200029],
            [34.6902258450001, 28.670613924000122],
            [34.688354917000197, 28.664468408000232],
            [34.687801724500218, 28.662268097500203],
            [34.682254476000367, 28.637454537000167],
            [34.681989322000277, 28.635520352000128],
            [34.682026692000363, 28.634221723000223],
            [34.685011441000142, 28.617933714000173],
            [34.684987075000038, 28.607708533000107],
            [34.684175701000242, 28.604203252000275],
            [34.68084223700032, 28.588656951000132],
            [34.677558346000296, 28.577405345000159],
            [34.67512141300034, 28.568509224000138],
            [34.670508773000279, 28.553827818000229],
            [34.667953182000133, 28.544876511000098],
            [34.667235290000235, 28.544047345000024],
            [34.664078566000171, 28.540629669000225],
            [34.663493999000195, 28.539629786000205],
            [34.660349349000228, 28.533739648000108],
            [34.659182467000164, 28.531506320000233],
            [34.654991327000289, 28.523901015000206],
            [34.647619581000185, 28.511704711000277],
            [34.647219122000365, 28.509466857000177],
            [34.644990478000182, 28.500741888000277],
            [34.643241411000304, 28.492421949000288],
            [34.641843098000152, 28.4867019510001],
            [34.638464706000264, 28.475018424000154],
            [34.634800782000298, 28.46296663600009],
            [34.634440004000112, 28.461654220000185],
            [34.632247548000237, 28.457314220000114],
            [34.627882186000193, 28.447373278000214],
            [34.62398523800033, 28.438170202000265],
            [34.62191376200019, 28.433143711000127],
            [34.616771626000173, 28.421127520000198],
            [34.615452118000235, 28.41791185000028],
            [34.612326225000174, 28.412332344000276],
            [34.604189457000132, 28.399693857000216],
            [34.602673551000294, 28.397208640000201],
            [34.598534821000385, 28.390607916000135],
            [34.596944696000151, 28.388158852000174],
            [34.584733387000256, 28.372821423000062],
            [34.58398662600041, 28.372053653000137],
            [34.583509950000177, 28.370366610000076],
            [34.582276572000296, 28.366143105000333],
            [34.581841354000176, 28.364696938000293],
            [34.57628578400022, 28.347311046000243],
            [34.575047219000339, 28.343839515000184],
            [34.568586368000183, 28.333131288000288],
            [34.558188810000161, 28.31175085000018],
            [34.551301732000184, 28.296916710000289],
            [34.549505273000136, 28.293498542000236],
            [34.544531569000185, 28.284309787000154],
            [34.544807262000177, 28.274847659000272],
            [34.54420907500014, 28.263248545000295],
            [34.543501629000218, 28.260911477000036],
            [34.543685356000083, 28.249570226000174],
            [34.543856744000209, 28.244116589000271],
            [34.543368841000245, 28.239706053000191],
            [34.541162139000107, 28.222230520000267],
            [34.540099772000417, 28.215229087000182],
            [34.539858979000257, 28.209508366000136],
            [34.539770588000351, 28.208295052000324],
            [34.533823614000141, 28.188671262000184],
            [34.533280352000304, 28.187172407000276],
            [34.532217381000237, 28.183930018000126],
            [34.529739536000307, 28.17544748600028],
            [34.529273806000106, 28.173573502000352],
            [34.525683116000209, 28.161698326000248],
            [34.524903839000274, 28.159929142000237],
            [34.519230656500156, 28.14602558200005],
            [34.517548715000316, 28.142403081000282],
            [34.514771784000345, 28.135888830000169],
            [34.512170334000189, 28.129355081000199],
            [34.511230742000237, 28.121185747000141],
            [34.510937944000375, 28.1172142100001],
            [34.509130232000189, 28.108593850000204],
            [34.507606235000139, 28.098964406000164],
            [34.507316470000205, 28.09663372600005],
            [34.506865553000125, 28.093764484000303],
            [34.506133204000207, 28.08819384200018],
            [34.506024677000369, 28.081165146000217],
            [34.506837448000397, 28.078520331000163],
            [34.508802048000234, 28.072629830000096],
            [34.509287439000332, 28.070785419000174],
            [34.500843216000192, 28.060728324000195],
            [34.497200359000317, 28.056639708000262],
            [34.491793072000291, 28.049395409000169],
            [34.484635594500162, 28.0406599585001],
            [34.483835579000129, 28.038387920000218],
            [34.47890041300036, 28.025848482000072],
            [34.477104357000258, 28.022046108000268],
            [34.475214376000281, 28.015480301000309],
            [34.471216786000241, 28.005742418000239],
            [34.469876051000256, 28.003342645000203],
            [34.469211805000214, 27.995847791000187],
            [34.46875854300032, 27.987258040000086],
            [34.467602428000191, 27.982866147000266],
            [34.466427278500134, 27.972851345000095],
            [34.466038665000383, 27.971772111000178],
            [34.465117868000107, 27.968744602000243],
            [34.463445392500148, 27.963984154500224],
            [34.462680315000114, 27.954317435000121],
            [34.460322883000117, 27.947897494000188],
            [34.458205197000211, 27.943051184000126],
            [34.456377401000168, 27.937390876000165],
            [34.450494022000385, 27.916737826000201],
            [34.448199922000242, 27.908106733000068],
            [34.448954869000318, 27.898952842000199],
            [34.449695338000197, 27.884047738000106],
            [34.449863276000201, 27.881264741000336],
            [34.456246555000178, 27.848424926],
            [34.458048671000256, 27.823386828000082],
            [34.458177036000279, 27.818032198000083],
            [34.458672818000309, 27.81666356400018],
            [34.459909356000082, 27.813477000000262],
            [34.461563770000225, 27.809061752000162],
            [34.465920010000218, 27.796063469000217],
            [34.49523028800013, 27.710591107000027],
            [34.511240863000126, 27.690297090000172],
            [34.534659147000298, 27.65929352600017],
            [34.56711963000032, 27.617227063000257],
            [34.616670505000229, 27.551713262000078],
            [34.685470591000126, 27.415249318000065],
            [34.708009325000148, 27.370544075000339],
            [34.708691653000301, 27.369189569000355],
            [34.725541576000296, 27.335740330000192],
            [34.719420448000278, 27.227918231000274],
            [34.725837731000183, 27.201504081000223],
            [34.732778829000381, 27.188066589000186],
            [34.758963726000218, 27.137281711000242],
            [34.771053661000167, 27.110933670000179],
            [34.773633209000252, 27.105311966000102],
            [34.774942388000341, 27.102412480000169],
            [34.778182592000178, 27.095236282000087],
            [34.824221959000397, 27.045077123000112],
            [34.841205933000197, 27.040477800000303],
            [34.98042065400017, 27.00317624600018],
            [34.983069276000265, 27.001773350000121],
            [35.15454320300023, 26.84637961500016],
            [35.212205926000252, 26.794132503000299],
            [35.27807835300024, 26.736815155000215],
            [35.338447274000373, 26.680655294000189],
            [35.368752495000081, 26.652519863000293],
            [35.406735778000154, 26.616704072000289],
            [35.520907019000163, 26.303070413000114],
            [35.548753661000092, 26.226857113000051],
            [35.595848406000187, 26.096741450000138],
            [35.599790352000241, 26.083424632000174],
            [35.600117179000165, 26.0823205330002],
            [35.588247319, 25.960996174000229],
            [35.56999578100033, 25.7744433430002],
            [35.581929198000125, 25.756463780000161],
            [35.60713544700036, 25.643642543000112],
            [35.613741438000261, 25.61362641400018],
            [35.622022089000154, 25.576001009000208],
            [35.626292357000182, 25.557189956000116],
            [35.63047356400017, 25.538771226000108],
            [35.686553917000197, 25.414991073000351],
            [35.709957703000214, 25.362213547000124],
            [35.77869272900017, 25.203640604000213],
            [35.850846926000372, 25.121234751000202],
            [35.86264723800025, 25.107693845000142],
            [35.898120394000102, 25.067455538000161],
            [35.906961801333466, 25.057338604000108],
            [35.908467726000197, 25.054779819000203],
            [35.942307688000255, 24.997079548000102],
            [35.95201497100021, 24.980564719000142],
            [35.955471200000147, 24.97469946800004],
            [35.961128970000175, 24.965257905000158],
            [35.969865659000163, 24.955931093000117],
            [36.013108581000154, 24.909709800000144],
            [36.112353960000291, 24.803694939000195],
            [36.156022309000207, 24.709085327000025],
            [36.165893595000398, 24.688780131000044],
            [36.298642555000356, 24.555581182000083],
            [36.31744865300027, 24.536662695000189],
            [36.337683884000228, 24.48966855700013],
            [36.338926508000213, 24.486771247000036],
            [36.43201784900009, 24.269556223000052],
            [36.441805279000221, 24.246766139000158],
            [36.479441958000223, 24.213495568000099],
            [36.485524516000282, 24.207184376000157],
            [36.657084271000258, 24.031141086000218],
            [36.668842553000218, 24.01908579500008],
            [36.696648616000232, 23.990480910000031],
            [36.731401977000075, 23.955534871000225],
            [36.74354769800027, 23.943328416000213],
            [36.849893290000296, 23.844431750000069],
            [37.027567116000171, 23.678166996500124],
            [37.062187868000137, 23.645769367000128],
            [37.074153350000358, 23.627361612000186],
            [37.081316480000282, 23.616341818000194],
            [37.131155674000183, 23.512375329000065],
            [37.232964915000366, 23.299997307000069],
            [37.268141821000142, 23.209298839000041],
            [37.325393749000227, 23.054244305000168],
            [37.353682237000271, 23.021456591000174],
            [37.388719035000236, 22.980847260000189],
            [37.414735195000219, 22.950850456000182],
            [37.415499247000184, 22.949964322000028],
            [37.446077609000213, 22.914461809000215],
            [37.450360782500184, 22.909488899500161],
            [37.581179649000148, 22.794770295000092],
            [37.587552110000274, 22.789182114000198],
            [37.598582828000076, 22.779525128333376],
            [37.663162092000277, 22.722988372000145],
            [37.723871896000105, 22.669839185000171],
            [37.729027449000228, 22.665605065000136],
            [37.734702659000163, 22.66094416500016],
            [37.735572053000169, 22.660230154000146],
            [37.773574403000168, 22.562141725500055],
            [37.813646563000361, 22.458710878000147],
            [37.855779816000108, 22.349960113000122],
            [37.873275429000216, 22.307556344000204],
            [37.885757506000118, 22.277303783000036],
            [37.890766399000029, 22.249436656000285],
            [37.894450053000185, 22.228942535000215],
            [37.894082244000231, 22.186937304000026],
            [37.893894291000208, 22.16547235600018],
            [37.893432940000139, 22.112784311000041],
            [37.893373988000235, 22.082087905000037],
            [37.893349818000388, 22.069502439000189],
            [37.893308084000296, 22.047771328000124],
            [37.893304517000274, 22.044158205000201],
            [37.893171075000225, 21.989565583000115],
            [37.893037680000134, 21.935068674000206],
            [37.89306455600024, 21.933213308000177],
            [37.893100553000295, 21.930728289000172],
            [37.893138265500227, 21.928131459500094],
            [37.896542395000324, 21.921775175000107],
            [37.904135866000388, 21.907605854000053],
            [37.908917864000273, 21.898682705000112],
            [37.18828482400022, 21.954397618000257],
            [37.046776452000245, 21.967743072000076],
            [36.992889561000197, 21.971619657000133],
            [36.943754962000412, 21.974730937000345],
            [36.929331711000088, 21.982684580000182],
            [36.889992117000133, 21.998615880000102],
            [36.88846350637931, 22.000109070138592],
            [36.8825001447359, 22.001109153035941],
            [36.821936200258222, 22.001109153035941],
            [36.72805419056516, 21.99944500997492],
            [36.688327154525666, 21.998336084181744],
            [36.54582708879434, 21.998609166587869],
            [36.408609130820366, 21.998882081355987],
            [36.297773213165215, 21.999164216217764],
            [36.25638220170282, 21.999718092381215],
            [36.024991214357101, 21.998882081355987],
            [35.909436117562677, 21.998336084181744],
            [35.7811091795881, 21.996945023526848],
            [35.445000225731974, 21.997773155562868],
            [35.354436108601021, 21.999164216217764],
            [35.153609228355606, 22.000553935768266],
            [34.988609116953171, 22.001109153035941],
            [34.967636086406969, 22.001109153035941],
            [34.940282248231824, 22.001109153035941],
            [34.89249115076268, 22.000553935768266],
            [34.704163364567535, 21.997218105932973],
            [34.663891169544115, 21.99610901250179],
            [34.346245201501972, 21.995553962871895],
            [34.116663364142653, 21.99610901250179],
            [34.099282314393349, 21.996444959181872],
            [34.07443617401708, 21.996945023526848],
            [33.95499120358636, 21.998882081355987],
            [33.865000240996324, 21.99944500997492],
            [33.781391259501845, 21.999718092381215],
            [33.760273222030264, 21.999718092381215],
            [33.73971811317756, 21.999718092381215],
            [33.461800187003178, 22.00027314201094],
            [33.358054185987726, 22.00027314201094],
            [33.179591229305458, 21.999427072702076],
            [33.166936064217509, 21.998882081355987],
            [32.648045126598618, 22.001944996422992],
            [32.521663293080564, 22.002773128459182],
            [32.493891197935568, 22.003054089854288],
            [32.473054121859207, 22.00360913948424],
            [32.444445177498835, 22.004164189113965],
            [32.388054079710002, 22.004164189113965],
            [32.339718158533174, 22.004164189113965],
            [31.996663289133835, 22.002773128459182],
            [31.579163205369525, 21.99944500997492],
            [31.495300252208267, 21.998336084181744],
            [31.46027328456853, 21.998191077256365],
            [31.466109100851895, 22.030827023189488],
            [31.513818223307396, 22.159854017719397],
            [31.508191284051861, 22.189718068264597],
            [31.479027122423645, 22.221391101158247],
            [31.448327228491394, 22.23221800552011],
            [31.42166322094954, 22.227218032623966],
            [31.395418140929337, 22.21138210291042],
            [31.335000209205418, 22.106109086770118],
            [31.320000122878753, 22.080000128857876],
            [31.274300142620064, 21.998744953419816],
            [31.20750022980053, 21.998336084181744],
            [31.165273207312964, 21.998336084181744],
            [30.748336052167588, 21.999164216217764],
            [30.691109110991533, 21.99944500997492],
            [30.59889124435972, 22.00027314201094],
            [30.499163360099487, 22.000827018174334],
            [30.463054121118716, 22.000827018174334],
            [30.377500202806118, 22.000827018174334],
            [30.271663252218787, 22.000827018174334],
            [30.101109124118409, 22.000553935768266],
            [29.611945109108547, 21.998053949319967],
            [29.527218149365467, 21.997500073156743],
            [29.250000112108069, 21.995553962871895],
            [29.178054046000454, 21.995553962871895],
            [29.156391184821132, 21.995553962871895],
            [29.135273314988012, 21.995553962871895],
            [29.113609112704012, 21.995553962871895],
            [29.092782262549633, 21.995553962871895],
            [29.070827208224671, 21.995553962871895],
            [29.013336069459854, 21.99582704527819],
            [28.596663279541247, 21.99944500997492],
            [28.319163275060419, 22.002773128459182],
            [28.261109207676668, 22.00360913948424],
            [28.222773232292298, 22.003327004622633],
            [27.724163315291321, 21.99944500997492],
            [27.412218214890743, 21.994445037078776],
            [27.386663300780071, 21.99416424332162],
            [27.327218172741539, 21.99610901250179],
            [27.267773212340899, 21.998053949319967],
            [27.138054040245038, 22.000553935768266],
            [27.093054116541452, 22.00027314201094],
            [26.971663203463834, 21.998609166587869],
            [26.914445147105141, 21.996664229769692],
            [26.838054157731506, 21.995282053932272],
            [26.674718189389921, 21.99610901250179],
            [26.648609231477849, 21.996391147363397],
            [26.339718161327141, 21.999718092381215],
            [25.778609253675995, 22.002500046052887],
            [25.407218187046624, 21.999718092381215],
            [25.216391252593667, 22.001109153035941],
            [25.099163295558924, 22.000827018174334],
            [25.001418234319488, 21.999691102652889],
            [25.001945120754613, 22.046664126369649],
            [25.001409181864005, 22.286664166491107],
            [25.000273266342504, 23.58027312674659],
            [24.999445134306313, 24.023891053240447],
            [24.998054073651588, 24.343053978123464],
            [24.997218230264536, 24.786664193266517],
            [24.996945147858412, 24.99471811808786],
            [24.997500197488137, 25.442773088859212],
            [24.998336208513365, 25.970836007872549],
            [24.99916333472089, 26.483891129208473],
            [25.000000183936038, 26.567218143479295],
            [25.000273266342504, 26.719718155003306],
            [25.00055406009966, 26.941664246186633],
            [25.00015407567912, 26.999445063526082],
            [25.000000183936038, 27.080554058572318],
            [25.000827142505955, 27.427500142712461],
            [25.001663321168962, 27.607773087571616],
            [25.001227126926665, 27.666327051662165],
            [25.001945120754613, 27.760273099095627],
            [25.00221820316068, 27.898891170179866],
            [25.001945120754613, 28.00019116420367],
            [25.002500170384508, 28.125000049767664],
            [25.001945120754613, 28.2777731436978],
            [24.999445134306313, 28.956945013580423],
            [24.997773279894432, 29.248882175768983],
            [24.911663306123614, 29.432782137780237],
            [24.897218101788496, 29.462218040710127],
            [24.87840022619298, 29.508473238236888],
            [24.884718169548307, 29.585827140650338],
            [24.825000126741486, 29.816945045589819],
            [24.831800197168604, 29.837082065110963],
            [24.835827198741526, 29.858745093928349],
            [24.833054129887159, 29.881664234759228],
            [24.821109247276638, 29.916382077808919],
            [24.810273290459122, 29.945827033194291],
            [24.76916324039189, 30.034164246283069],
            [24.726109091696799, 30.119717996957888],
            [24.711527094701466, 30.138054080757627],
            [24.706800204211788, 30.159164239240056],
            [24.710000247215021, 30.188891161849085],
            [24.723191183555002, 30.225973204514901],
            [24.771945194063619, 30.303891209013557],
            [24.818609093190219, 30.363891051405801],
            [24.854445082126915, 30.3994450731185],
            [24.876391251634345, 30.427500141315647],
            [24.926182271206414, 30.507500042930644],
            [24.976945088635432, 30.634718055111932],
            [25.017500256711116, 30.789236093650061],
            [24.995554087203686, 30.83889115875246],
            [24.977009126376061, 30.872154071044747],
            [24.911663306123614, 31.015000141740302],
            [24.904445146364822, 31.034444983695209],
            [24.869163201229753, 31.159164182895097],
            [24.868118145538858, 31.370827019958028],
            [24.893609189547476, 31.402773135257803],
            [24.962773301982963, 31.445273240151437],
            [25.009163280513178, 31.481109061449786],
            [25.04763604855799, 31.522636027382248],
            [25.060827152536376, 31.567500164254056],
            [25.073191130306839, 31.585691073490466],
            [25.100273227180367, 31.611391162164523],
            [25.129718182565568, 31.634445083998855],
            [25.148054098727528, 31.646109172852391],
            [25.151663434622549, 31.646945146119663],
            [25.161288412000147, 31.64855929060019],
            [25.537632536000217, 31.848798237000153],
            [25.548522991000198, 31.91701152900032],
            [25.560876419000238, 31.978285747000086],
            [25.668098003000125, 32.201566929000137],
            [25.71304941600016, 32.293799556000181],
            [25.780590954000161, 32.429734776000146],
            [26.063185115000181, 32.991981087000056],
            [26.207209743400199, 33.264417686200261],
            [26.252170157000137, 33.26208459600025],
            [26.940077396000277, 33.275699685000291],
            [27.323792164000338, 33.313749807000193],
            [27.802591839000343, 33.392287073000318],
            [27.853551587000254, 33.404052401000342],
            [28.040581818000192, 33.409767627000178],
            [28.320854997000197, 33.417679426000291],
            [28.80181083000025, 33.471373332000269],
            [29.248916364000252, 33.726132078000148],
            [29.560419078000223, 33.76459665900029],
            [29.952283712000281, 33.811342376000312],
            [30.083333333000269, 33.750000000000227],
            [30.475000000000193, 33.56666666700022],
            [30.611111111000241, 33.511111111000048],
            [31.116666667000118, 33.355555556000184],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ERI", Country: "Eritrea" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [39.715429534000151, 18.073328403000048],
            [39.725473466000125, 18.04509555200012],
            [39.727942036000258, 18.038168173000017],
            [39.760858448000107, 17.950420275000042],
            [39.793661121000042, 17.877100164000112],
            [39.813226793000098, 17.834725215000091],
            [39.815593247000123, 17.829805749000101],
            [39.823586364000079, 17.813399954000076],
            [39.826706344000314, 17.807011801000087],
            [39.83537700500014, 17.784205005999965],
            [39.863008838000184, 17.712967898000088],
            [39.916516497000117, 17.583333886000119],
            [39.929946422000256, 17.554711269000038],
            [39.959283343000124, 17.500021505999982],
            [40.123594806000227, 17.4385013200001],
            [40.229725513000318, 17.363213726000126],
            [40.49376674900023, 17.180744335000142],
            [40.607262813000119, 17.102030548000172],
            [40.67533807300012, 17.054961381000041],
            [40.680071132000336, 17.042187031000012],
            [40.711996826000217, 16.95631557200015],
            [40.770785531000257, 16.797320782000028],
            [40.826308780000062, 16.647618695000247],
            [40.839147425000164, 16.615671744999972],
            [40.888699804000254, 16.530236221000209],
            [40.891980034000227, 16.524604426000067],
            [40.91360292400006, 16.471419233],
            [40.962699509000259, 16.427628309000127],
            [41.042087113000122, 16.331235697000054],
            [41.082032369571635, 16.290000000000148],
            [41.134494698000225, 16.23584211500004],
            [41.1446624506668, 16.225347456333409],
            [41.145991014000316, 16.224182552000158],
            [41.155945462000062, 16.215454304999966],
            [41.211717025000013, 16.16658091000015],
            [41.293938274000169, 16.09384820700005],
            [41.301679486000154, 16.086944411000118],
            [41.400100539000249, 15.923454367000019],
            [41.418242539000147, 15.896537367000107],
            [41.427362539000256, 15.878869367000178],
            [41.461623539000044, 15.812816367000082],
            [41.568864539000259, 15.721427367000118],
            [41.569441539000053, 15.651037367000143],
            [41.625683539000221, 15.250179365999983],
            [41.776120539000232, 15.061449366000076],
            [41.847236539000306, 14.999906366000189],
            [41.977159539000098, 14.763310366000141],
            [42.014084539000208, 14.720914366000144],
            [42.163154539000203, 14.595094366000168],
            [42.19187353900017, 14.584153366000137],
            [42.279400539000079, 14.448760365000012],
            [42.364192539000072, 14.353027365000159],
            [42.436675540000095, 14.255927365000062],
            [42.52283554000033, 14.14515136500016],
            [42.477704540000076, 14.06172736500001],
            [42.63087654000023, 13.658282365000133],
            [42.646680540000119, 13.603537365000122],
            [42.637541540000171, 13.595452365000057],
            [42.660390540000122, 13.561002365000078],
            [42.726608540000115, 13.457244364000061],
            [42.801827540000176, 13.443568364000043],
            [42.879780540000269, 13.402540364000174],
            [42.996027540000085, 13.241162364000033],
            [43.053467540000128, 13.178252364000102],
            [43.091912540000152, 13.11625336400013],
            [43.104068540000156, 13.106528364000084],
            [43.14540054, 13.070059364000116],
            [43.181870540000176, 13.011707364000102],
            [43.211046540000069, 12.970375364000205],
            [43.235359540000132, 12.909593364000131],
            [43.240907540000336, 12.88983336400014],
            [43.244117254000258, 12.882324720000142],
            [43.248386540000155, 12.87233736400006],
            [43.248884254000103, 12.871216277000116],
            [43.254119333500086, 12.859236590500075],
            [43.274743540000117, 12.836894364000088],
            [43.277589795000239, 12.833866544000117],
            [43.283506295000137, 12.827571908000209],
            [43.300733221000058, 12.799483695000106],
            [43.297807381000069, 12.787059647000106],
            [43.296037540000185, 12.779545364000185],
            [43.294450329000171, 12.772868581000111],
            [43.293738380000121, 12.769874138999967],
            [43.289958268000163, 12.753498379000121],
            [43.282865121000128, 12.725620455000097],
            [43.273976540000177, 12.690687364000027],
            [43.264194540000261, 12.688192364000088],
            [43.262425540000123, 12.687879364000125],
            [43.256277540000184, 12.686878364],
            [43.247996540000116, 12.685700364000141],
            [43.244985540000215, 12.685318364000153],
            [43.224715540000119, 12.683083364000055],
            [43.21814754000016, 12.682592364000115],
            [43.208401540000153, 12.682154364000112],
            [43.199231540000113, 12.683403364000242],
            [43.189039540000152, 12.684970364000222],
            [43.177205540000131, 12.686560364000158],
            [43.1674690400001, 12.690749364000183],
            [43.16164554000008, 12.692873364000064],
            [43.129617540000226, 12.707352364000087],
            [43.121381929358705, 12.708327168399862],
            [42.929718252508025, 12.623891159131873],
            [42.810554075834517, 12.524927033891316],
            [42.808400261988396, 12.487918081421157],
            [42.819400168832459, 12.445591146647587],
            [42.749854176886942, 12.387635985721616],
            [42.698609232385792, 12.363891059787676],
            [42.6491632119494, 12.39860907047543],
            [42.620282191011057, 12.430827094715099],
            [42.588327190893864, 12.467773182911117],
            [42.535827140207772, 12.504164221477239],
            [42.501391264381795, 12.516664153717812],
            [42.48480917764536, 12.521845008085023],
            [42.456109205816318, 12.529164085958385],
            [42.399718108027315, 12.469718119729393],
            [42.358054181796632, 12.511664180821654],
            [42.344718238531158, 12.526664099510214],
            [42.326109239963358, 12.549444938938237],
            [42.306518217616571, 12.5747180534634],
            [42.292218187845066, 12.598191070457702],
            [42.262773232459864, 12.650553993207311],
            [42.214445190271903, 12.725827004332487],
            [42.194300124123885, 12.746109030778911],
            [42.153054119586642, 12.778473067772282],
            [42.119027112998793, 12.801245022382886],
            [42.097773288695436, 12.811945024730505],
            [42.076945097436493, 12.81971806648103],
            [42.048191146150913, 12.830273061903256],
            [42.017218169812367, 12.840553969090777],
            [41.993054148718528, 12.850135993189667],
            [41.974300143225292, 12.863335981985273],
            [41.954163291342155, 12.88527309903742],
            [41.926800233073408, 12.926664110499786],
            [41.914718222526375, 12.948745061010754],
            [41.843863312577497, 13.098536041420857],
            [41.843054123642702, 13.105827123737427],
            [41.816527076398899, 13.157773130622004],
            [41.798609249568841, 13.18777313563713],
            [41.781391144017704, 13.211945035720078],
            [41.756945155700151, 13.236391191675793],
            [41.615554183399666, 13.353609090426659],
            [41.599163371694345, 13.365273179280109],
            [41.566936127361288, 13.38666413151968],
            [41.5497181894481, 13.39778205556081],
            [41.528045102346908, 13.41124506847882],
            [41.428054193964357, 13.472218049832478],
            [41.236527202956239, 13.595418112896411],
            [41.218609208488118, 13.612364141869634],
            [41.178327122818899, 13.681664208775317],
            [41.132500240545568, 13.766109033656718],
            [41.115554043934168, 13.796945049696944],
            [41.1004091183203, 13.822918053139247],
            [41.072909267391282, 13.866527083826156],
            [41.047218231172593, 13.900000046612789],
            [41.008045071296721, 13.944718003092859],
            [40.929163315568275, 14.032218031690306],
            [40.909436171113583, 14.052218090913115],
            [40.802700177588889, 14.153054062775979],
            [40.776936218812381, 14.164717983991423],
            [40.748891208899437, 14.172782045420988],
            [40.693600152086248, 14.182500023989761],
            [40.646936085321244, 14.189027012010669],
            [40.61416334672802, 14.195835967255292],
            [40.5925001502724, 14.203053959375907],
            [40.552773281871083, 14.220273070755511],
            [40.46889121797048, 14.261391167449673],
            [40.433609272835525, 14.282636106935612],
            [40.385554145415853, 14.325273172127581],
            [40.349718156479383, 14.359164224245731],
            [40.322773187542083, 14.387782053423322],
            [40.301382235302498, 14.405836002361482],
            [40.271109147881106, 14.424718083335634],
            [40.228054160995583, 14.44350913684103],
            [40.179427220139672, 14.457954005899765],
            [40.156663312156212, 14.464718034143161],
            [40.116863353558841, 14.470136096370922],
            [40.018882257925526, 14.458891102677384],
            [39.945791223841155, 14.431773131258112],
            [39.903745082824713, 14.432500177541655],
            [39.886800227317821, 14.443745003597016],
            [39.851663289108131, 14.479791043027646],
            [39.779927105856899, 14.513609004949856],
            [39.746382226340842, 14.502773215770489],
            [39.725963239596211, 14.495691178119685],
            [39.695136108373362, 14.49360911336511],
            [39.613882274039639, 14.510326986933379],
            [39.531391270793989, 14.56555400600611],
            [39.482627202001623, 14.511318063167508],
            [39.375973183490402, 14.483891134796337],
            [39.263054027613947, 14.473609054142244],
            [39.209718133540918, 14.485553936752908],
            [39.189582287486218, 14.544300014064774],
            [39.178609202732588, 14.56430912574298],
            [39.152218109958682, 14.600000107754155],
            [39.13527325445196, 14.617773095296982],
            [39.118745147172177, 14.629391083682904],
            [39.094027082276654, 14.643609138440993],
            [39.024018241636583, 14.655164094914753],
            [39.01242707534098, 14.588953932804387],
            [38.96555413154843, 14.546109164051202],
            [38.893054189277706, 14.50082710548584],
            [38.795554047249738, 14.46971800703939],
            [38.561391164515925, 14.426527065683999],
            [38.448954135711659, 14.418744971478063],
            [38.333463244295871, 14.52160014382163],
            [38.306936197052067, 14.594164123832783],
            [38.263336218820569, 14.677635977391034],
            [38.244854122267185, 14.687364181881705],
            [38.188918161821931, 14.69211806209988],
            [38.143745067998026, 14.683327122024892],
            [38.040554116612356, 14.721945064633061],
            [38.018327153347599, 14.735000046503288],
            [37.980345061916211, 14.775973136272299],
            [37.978327202540157, 14.801109122861178],
            [37.955827156869219, 14.837773076195489],
            [37.926518155953801, 14.87097312421399],
            [37.911382282795358, 14.883609010924303],
            [37.838336175712044, 14.715273069686774],
            [37.684436218715774, 14.360000067632669],
            [37.572209240405613, 14.102254029696766],
            [37.530754191202817, 14.114309050515416],
            [37.433054056964608, 14.268053942302473],
            [37.391245123808659, 14.356391155391449],
            [37.336936090774174, 14.426664193620283],
            [37.287845127757265, 14.451873102767095],
            [37.19958217833053, 14.452500069126287],
            [37.124718204081518, 14.425627016918426],
            [37.101391199841117, 14.393053935259374],
            [37.091663330626631, 14.371245061326206],
            [37.090827151963509, 14.346664124367166],
            [37.090545184739909, 14.312364203010915],
            [37.075273189473336, 14.287500125361859],
            [36.999791301320698, 14.261527121919656],
            [36.949891149368767, 14.294373118346911],
            [36.886109224615069, 14.320273199231352],
            [36.825000121153522, 14.328053952332894],
            [36.736382281945424, 14.327218108945871],
            [36.641245166067421, 14.316664119351955],
            [36.558463310780695, 14.288609051155092],
            [36.542818153183788, 14.262054008354582],
            [36.541663294561118, 14.278054055732881],
            [36.51554511655533, 14.450282049901816],
            [36.452218161507318, 15.068609013911512],
            [36.443282214507093, 15.14995404335194],
            [36.479718180074343, 15.180273063602911],
            [36.542773226182845, 15.234718051107265],
            [36.565554065610769, 15.302773070111627],
            [36.61054510449722, 15.437218126869013],
            [36.626109125270972, 15.489445095149094],
            [36.671663260414078, 15.659164218052538],
            [36.696245203201528, 15.748609015829985],
            [36.763609218106268, 15.808053976230681],
            [36.826109214584761, 15.97750001672766],
            [36.889445054450192, 16.14666409000138],
            [36.924718114767842, 16.227082080948023],
            [36.966873220525798, 16.259927071547068],
            [36.973882167980747, 16.285554070025213],
            [36.952836214876669, 16.433609158282806],
            [36.928054112240972, 16.459445033788811],
            [36.902773286364692, 16.493609000675136],
            [36.896663214209099, 16.514717985691178],
            [36.898327189632056, 16.536944948955764],
            [36.914027164875705, 16.64444503677602],
            [36.939300279400953, 16.677636032339038],
            [36.972636114251117, 16.700000123539994],
            [36.988882254307299, 16.725554031822398],
            [37.008954062621484, 16.786318136148381],
            [37.013054154390176, 17.022427129166843],
            [36.993809137007617, 17.064891191876526],
            [37.026109136260658, 17.077218121635084],
            [37.108327224738247, 17.047918005536914],
            [37.147773299382351, 17.017009066938996],
            [37.257354110852674, 17.026944974990855],
            [37.381382305952542, 17.043053987110838],
            [37.423291151394693, 17.034209067579113],
            [37.486109157280424, 17.110553956485305],
            [37.520573196301399, 17.195800091312478],
            [37.530691159290797, 17.249427005064703],
            [37.520273291804841, 17.277773092940762],
            [37.512073275905408, 17.32118213141716],
            [37.588745059036427, 17.350136074913095],
            [37.623054032847932, 17.357500079787613],
            [37.744718195969796, 17.381945062276841],
            [37.895554064432787, 17.441391196143726],
            [37.933882160828119, 17.458609134056772],
            [37.966527159216724, 17.499718010657531],
            [38.034718132691211, 17.54166423938797],
            [38.058745193486715, 17.552500028567223],
            [38.092254030819191, 17.543227129058451],
            [38.135554104553904, 17.49888216727048],
            [38.257500234899538, 17.532773219388801],
            [38.369163278762443, 17.664445039653842],
            [38.449163348015503, 17.851109018063923],
            [38.45860925000656, 17.871109077286732],
            [38.505827192934788, 17.913327047318987],
            [38.600691226203395, 17.994882127126843],
            [38.612376960000091, 18.000609863000065],
            [38.626987818000117, 18.003037920999972],
            [38.639525664000104, 18.004790306000118],
            [38.693502096, 18.019225005000109],
            [38.698671438000162, 18.020565282000248],
            [38.702489490000318, 18.02171961000009],
            [38.706168420000125, 18.022778042000013],
            [38.708830850000169, 18.023815822000259],
            [38.731307103000262, 18.03248409199999],
            [38.758415215000042, 18.043113167999977],
            [38.805653383000305, 18.064878427999972],
            [38.862684112000153, 18.091396588000109],
            [38.868781891000168, 18.090049095000083],
            [38.903975279000207, 18.08215506000009],
            [38.914303428000068, 18.079937992000069],
            [38.948616558000282, 18.072886774000082],
            [39.08465294700008, 18.045833958000216],
            [39.098842136000115, 18.042961009000081],
            [39.201318768000164, 18.039692502000108],
            [39.222837960000135, 18.039054994000125],
            [39.238761175000292, 18.039013792000091],
            [39.251175184000061, 18.039011845000189],
            [39.323881722000095, 18.04036355300012],
            [39.481296147000279, 18.050587659000072],
            [39.488183035000276, 18.051103599000029],
            [39.715429534000151, 18.073328403000048],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ESH", Country: "Western Sahara" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-8.666663859349939, 27.666664171808733],
            [-8.667217735513276, 27.599718078597405],
            [-8.667217735513276, 27.499445035353446],
            [-8.666790929002531, 27.290454013754228],
            [-8.667217735513276, 26.966391195899334],
            [-8.667217735513276, 26.833054059106686],
            [-8.667217735513276, 26.399718045628717],
            [-8.666663859349939, 26.133327080371416],
            [-8.666390776943643, 26.066391045444092],
            [-8.666945994211545, 26.000273084268869],
            [-9.399999922979532, 25.999444952232764],
            [-9.567217885968432, 26.000273084268869],
            [-9.633890896773551, 26.000273084268869],
            [-9.700554855123073, 26.000273084268869],
            [-9.899999894807138, 26.000000169500751],
            [-9.966663853156575, 25.999718034639145],
            [-10.000308812596813, 25.99796420521362],
            [-10.134117850539724, 25.998200071969805],
            [-10.500281794696008, 25.999444952232764],
            [-10.733336757464883, 25.999444952232764],
            [-11.266672899418893, 25.999444952232764],
            [-11.700554910071077, 26.000000169500751],
            [-12.000554960222786, 26.000000169500751],
            [-11.999999910592976, 25.733053986975662],
            [-11.999999910592976, 25.666109067230806],
            [-12.000836759808323, 25.632500149974291],
            [-11.999999910592976, 25.299718141135756],
            [-11.999999910592976, 25.232773221390929],
            [-12.000836759808323, 25.200000147521436],
            [-11.999999910592976, 24.866391180113382],
            [-11.999999910592976, 24.799718001670172],
            [-12.000836759808323, 24.766109084413742],
            [-11.999999910592976, 24.399718158318606],
            [-12.000836759808323, 24.333054032330992],
            [-12.000281877816604, 24.000000114552748],
            [-12.000836759808323, 23.799718058015273],
            [-12.000281877816604, 23.454436116936151],
            [-12.0988907779996, 23.429718052040911],
            [-12.231390897938809, 23.380553998827835],
            [-12.365836792886626, 23.318609051979237],
            [-12.571390898898159, 23.291382115818394],
            [-12.597426766614319, 23.276173152464182],
            [-12.63666396423082, 23.250827115380972],
            [-12.744999895438042, 23.182500187436787],
            [-12.998336717015263, 23.024718068154996],
            [-13.049445874684494, 22.962773121306313],
            [-13.105281922843687, 22.893053959240817],
            [-13.133890867203974, 22.809164183989239],
            [-13.150281846547557, 22.757500144328176],
            [-13.094163831164565, 22.570827113462499],
            [-13.079999923501106, 22.520273173061256],
            [-13.077799841549478, 22.496809040884258],
            [-13.066390897829393, 22.323891048444111],
            [-13.059163853253381, 22.204718154591163],
            [-13.051390811502756, 22.085827060323552],
            [-13.047781899261452, 22.033882059267384],
            [-13.031390919918039, 21.797773233886986],
            [-13.004999827144246, 21.423336015997123],
            [-12.999726771841694, 21.338054006624233],
            [-13.376945943403257, 21.340000116908882],
            [-13.908336813262906, 21.342500103356954],
            [-14.28528189658968, 21.342218136133354],
            [-14.30347297346421, 21.341664092331925],
            [-14.467226863499008, 21.340553993072305],
            [-14.6872268443976, 21.338609056253958],
            [-15.005281849315764, 21.336391037029486],
            [-15.225554912620652, 21.337500130460811],
            [-15.741999843248095, 21.338282162029387],
            [-15.892226850262631, 21.338609056253958],
            [-16.017781892849342, 21.33889119111565],
            [-16.14611788327926, 21.339718149685368],
            [-16.604726843644386, 21.339718149685368],
            [-16.953754992539189, 21.336664119435781],
            [-16.960554895328301, 21.300554042264693],
            [-16.99499982360976, 21.128053971517957],
            [-17.038063862950764, 21.004164245458853],
            [-17.049163849718894, 20.974445034200969],
            [-17.070281887190333, 20.920827005266048],
            [-17.075554942492886, 20.899027016150384],
            [-17.052327244273187, 20.764100336530149],
            [-17.053118515999927, 20.762480371],
            [-17.11908251599985, 20.174587371000015],
            [-17.135286515999979, 20.165782370999963],
            [-17.284614515999891, 20.10217337100012],
            [-17.383200515999903, 20.061889371000163],
            [-18.024224516999823, 19.797664370000049],
            [-18.771272516999943, 19.48682937],
            [-19.382438517999873, 19.227591369999956],
            [-19.469578518, 19.189234370000023],
            [-19.822064517999877, 19.063719370000157],
            [-20.038915518999943, 18.985636370000051],
            [-20.140259518999983, 19.112161370000095],
            [-20.140908518999851, 19.113394369999966],
            [-20.147352519000037, 19.12162937],
            [-20.421966518999966, 19.640692370000053],
            [-20.598484018999955, 20.196848871000029],
            [-20.600627518999858, 20.203693371000057],
            [-20.67272151899985, 20.780008371000051],
            [-20.672611518999901, 20.78179737100011],
            [-20.672801518999989, 20.783305371],
            [-20.63712051899995, 21.362682372000123],
            [-20.632497518999884, 21.380907372000124],
            [-20.628939518999914, 21.438727372000173],
            [-20.603488518999882, 21.538988372000134],
            [-20.599777518999872, 21.59919237199999],
            [-20.594463518999845, 21.620107372000163],
            [-20.587879518999983, 21.726898372000036],
            [-20.584100518999946, 21.741761372000028],
            [-20.577768518999875, 21.844468372000037],
            [-20.57724951900002, 21.84650937200017],
            [-20.576239518999841, 21.862894372000071],
            [-20.573866518999864, 21.872218372000063],
            [-20.57356251899995, 21.877147371999953],
            [-20.571980518999879, 21.883368372000163],
            [-20.571800518999908, 21.886282372000025],
            [-20.566097518999925, 21.908691372000092],
            [-20.56412751900001, 21.940629372000146],
            [-20.563459518999878, 21.943253372],
            [-20.56326251899992, 21.946444372000073],
            [-20.562411518999852, 21.949788371999986],
            [-20.562357518999931, 21.950662372],
            [-20.557566519, 21.96948237200003],
            [-20.542105518999904, 22.220100373000136],
            [-20.541544518999842, 22.222302372999977],
            [-20.541265519000035, 22.226816373000176],
            [-20.540692518999862, 22.229064373000099],
            [-20.540423519, 22.233431373000016],
            [-20.539862518999854, 22.235633373000056],
            [-20.539583518999962, 22.240148373000054],
            [-20.538958518999834, 22.242598373000092],
            [-20.534485518999844, 22.315131373000085],
            [-20.389993518999944, 22.879909373000018],
            [-20.385454518999893, 22.88961237300002],
            [-20.383392519000012, 22.897644373000063],
            [-20.382653518999973, 22.899224373000052],
            [-20.382438518999948, 22.900063373],
            [-20.381824518999849, 22.901376373000019],
            [-20.381488518999845, 22.90268237299999],
            [-20.31716151899991, 23.040048373000033],
            [-20.255893518999926, 23.277834374000051],
            [-20.004691518999834, 23.810519374],
            [-19.654067518, 24.294568375000026],
            [-19.652692518, 24.295886375000023],
            [-19.652099517999972, 24.296702375000038],
            [-19.57621651799991, 24.3693993750001],
            [-19.535084517999962, 24.455278374999963],
            [-19.49570550637489, 24.603629300750029],
            [-18.603069283999872, 24.980269315000029],
            [-18.546662667999954, 25.003854876],
            [-18.164227546999825, 25.177050864000094],
            [-17.845109931999872, 25.322374363000037],
            [-17.761696842999925, 25.361238771999965],
            [-17.660590284, 25.408923570000141],
            [-17.521046277999858, 25.47518705300017],
            [-17.228174904000014, 25.616446176],
            [-17.20054382399988, 25.632385877],
            [-17.129494215999983, 25.676605331000061],
            [-16.950919404999922, 25.872592125],
            [-16.943583259999912, 25.88054037800002],
            [-16.809385164999867, 26.034617187000023],
            [-16.743664535999812, 26.109992152000146],
            [-16.738160908999816, 26.116304259999978],
            [-16.66182805499983, 26.140232995],
            [-16.566939392999956, 26.169978585000123],
            [-16.486384415999964, 26.195277152000173],
            [-16.412024258999963, 26.223953342000172],
            [-16.379059540999862, 26.237538134000104],
            [-16.341713255999878, 26.253234011000174],
            [-16.303023718999867, 26.269665349999983],
            [-16.269745618999963, 26.283817252000134],
            [-16.254989536999915, 26.290143954000015],
            [-16.253439842999853, 26.290820117000109],
            [-16.227968714999918, 26.30195449600005],
            [-16.031126401999927, 26.387969911000127],
            [-15.857975898999882, 26.489476545000016],
            [-15.585835672, 26.638798682000171],
            [-15.568059712999855, 26.648540332999985],
            [-15.554405736999882, 26.656049610000125],
            [-15.492584913999906, 26.692323962000145],
            [-15.410191205999922, 26.73954144000011],
            [-15.377557660999969, 26.758319433000125],
            [-15.308733974999939, 26.798016578],
            [-15.285661054999821, 26.811398577000134],
            [-15.258177660999849, 26.827774152000032],
            [-15.251404952999906, 26.831913069],
            [-15.235286064999912, 26.841857387000104],
            [-15.206364901999905, 26.859950924000046],
            [-15.099435973999874, 26.927379364],
            [-15.084699364999864, 26.936793328000178],
            [-15.023696792999829, 26.975951196000025],
            [-14.913986505999901, 27.034536493000175],
            [-14.905778289999944, 27.040351106000045],
            [-14.71777236, 27.174029863000115],
            [-14.699106318999839, 27.187319919999979],
            [-14.531328533999982, 27.213981358],
            [-14.403123863999866, 27.223508878000146],
            [-14.375879237999925, 27.22928810700013],
            [-14.341963313999827, 27.237148772000111],
            [-14.332287806999972, 27.239502344000059],
            [-14.268328447999892, 27.279166197000038],
            [-14.228231540999872, 27.310597156000071],
            [-14.041181959999903, 27.463999139],
            [-14.034184361999849, 27.469759146999976],
            [-14.012427146999812, 27.488677126000127],
            [-13.98333308099987, 27.515638227000053],
            [-13.977616861999962, 27.520949833000074],
            [-13.92510099299983, 27.570482644000109],
            [-13.902322970999876, 27.596387153],
            [-13.901296307999843, 27.597575600000127],
            [-13.891436344999818, 27.611062257],
            [-13.871790004999895, 27.638374568000145],
            [-13.861707891999885, 27.652748830000021],
            [-13.852245657999902, 27.666303124000095],
            [-13.851430131428572, 27.666871818000075],
            [-13.228669357999877, 27.666596300000108],
            [-13.190355966999903, 27.666579373000118],
            [-13.174963691130813, 27.666954192396005],
            [-13.166945908297066, 27.66638220458502],
            [-13.067499823622541, 27.66610912217881],
            [-12.841099924355547, 27.666445068859062],
            [-10.299736714036214, 27.666573144339949],
            [-10.120508824867812, 27.664773214447095],
            [-10.067236800897149, 27.664236102090015],
            [-10.022517838588783, 27.665009081202996],
            [-9.999999855644887, 27.66638220458502],
            [-9.933608812063483, 27.666664171808733],
            [-9.833336774647819, 27.666664171808733],
            [-9.667217846806352, 27.666664171808733],
            [-9.333608879398128, 27.666664171808733],
            [-9.299999794503606, 27.66610912217881],
            [-8.666663859349939, 27.666664171808733],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ESP", Country: "Spain" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-2.914687194452199, 35.273643715456672],
              [-2.939236816286439, 35.264436135464905],
              [-2.965072859430336, 35.281318126697784],
              [-2.966181785223569, 35.316627061560951],
              [-2.946945987967126, 35.329164209225894],
              [-2.944243946999961, 35.330275878],
              [-2.931306610999854, 35.337603240000135],
              [-2.899640410999837, 35.346135105000087],
              [-2.894508236999798, 35.347399914000164],
              [-2.852111294999929, 35.358353886000032],
              [-2.821163974999877, 35.371064793000244],
              [-2.8075240739999, 35.374546365000185],
              [-2.757790080999882, 35.383733109000218],
              [-2.630584048999822, 35.400165761999972],
              [-2.634791936999818, 35.387844801000028],
              [-2.665330187999814, 35.370482114000083],
              [-2.669226683999852, 35.368332323000118],
              [-2.695808266999848, 35.354433917000165],
              [-2.728031949999831, 35.337323459],
              [-2.812204683999823, 35.302823057000069],
              [-2.840641171999835, 35.292959840000094],
              [-2.846041407999849, 35.291173501],
              [-2.862136215999897, 35.285753502000148],
              [-2.87743706099991, 35.282001114000025],
              [-2.896490606999862, 35.279768774000047],
              [-2.904253141999845, 35.278295471000092],
              [-2.914234994999845, 35.274145712000063],
              [-2.914687194452199, 35.273643715456672],
            ],
          ],
          [
            [
              [-1.776945959980935, 43.342500204871101],
              [-1.730372920684772, 43.298982201653018],
              [-1.702081818093689, 43.30597321183501],
              [-1.655554879265253, 43.309436111322668],
              [-1.503608743904749, 43.287773250143289],
              [-1.408890890824637, 43.273045072756474],
              [-1.385136744797137, 43.25256422756604],
              [-1.383890858705627, 43.209718117708292],
              [-1.423126715217563, 43.123264150630177],
              [-1.466736751732981, 43.092045081613662],
              [-1.439517862199068, 43.049373147704443],
              [-1.398054934007291, 43.038054057986642],
              [-1.348263914434995, 43.036173158908923],
              [-1.300281877211262, 43.071664148709658],
              [-1.20055483114146, 43.045827099737153],
              [-1.07278193696834, 43.006382198559493],
              [-0.993608826284742, 42.976109111138044],
              [-0.940281817029245, 42.954164115097058],
              [-0.818608769090019, 42.946109106122947],
              [-0.788754944466632, 42.954718158898345],
              [-0.754099798052835, 42.964373105555069],
              [-0.723472826678403, 42.929854081249289],
              [-0.723317761469218, 42.918945034236003],
              [-0.730626781058618, 42.901036092223251],
              [-0.715836745226113, 42.884582080967846],
              [-0.555836774357942, 42.780000068927222],
              [-0.39194592402481, 42.796391215908784],
              [-0.317845876433353, 42.832354106859498],
              [-0.308736759313035, 42.848382149794531],
              [-0.303190789242421, 42.841800176488562],
              [-0.262499834335131, 42.817500200924712],
              [-0.205554860382676, 42.79416414422883],
              [-0.179999778633885, 42.784436107376109],
              [-0.151945883903352, 42.776654013170287],
              [-0.123608848482888, 42.757500190894291],
              [-0.057917861456588, 42.699791122646332],
              [0.002782205128824, 42.686073132232991],
              [0.11194509490565, 42.709445063474561],
              [0.144163286783225, 42.722218078121273],
              [0.180900162675528, 42.734509133334143],
              [0.269163279740212, 42.707564164396842],
              [0.432782221133721, 42.686664224046581],
              [0.505418117874399, 42.691664196942895],
              [0.560000233315009, 42.695827152985714],
              [0.595554087389871, 42.695000194415798],
              [0.661663331385569, 42.691936105882519],
              [0.675509229641762, 42.688482091212336],
              [0.681527100862297, 42.70069117141179],
              [0.682636026655643, 42.721245106797994],
              [0.661809176501265, 42.751109157343251],
              [0.651945017540697, 42.785000041823366],
              [0.667782120720688, 42.839164235570593],
              [0.692782152839641, 42.853609104629129],
              [0.716109157080211, 42.858882159931625],
              [0.823609077262347, 42.833053995776652],
              [0.896945030558186, 42.813045051736481],
              [1.088336067096463, 42.784854029069834],
              [1.126663325301706, 42.764718015377127],
              [1.143818063664696, 42.733300127616587],
              [1.173891158875733, 42.709718145880856],
              [1.325000109744849, 42.717491187631367],
              [1.359163238440772, 42.715273168406895],
              [1.390282227533021, 42.688327026003151],
              [1.417218144014669, 42.650836114099263],
              [1.445836140830522, 42.601944975654476],
              [1.434445134383282, 42.576382182554767],
              [1.421391158341294, 42.545827127909604],
              [1.424163221367337, 42.493327077223569],
              [1.451527117826288, 42.446245088765409],
              [1.512218131956331, 42.436382103271171],
              [1.537500131299112, 42.436664238132948],
              [1.657782118583384, 42.469582151289671],
              [1.718891222044931, 42.503054108247966],
              [1.723609227717475, 42.509436089343353],
              [1.780000157868642, 42.488327104327425],
              [1.851391174346134, 42.469991188165693],
              [1.906391211480411, 42.44693609286486],
              [1.95916333874419, 42.410827021522266],
              [1.972782255061531, 42.379436123490393],
              [1.99624504613405, 42.36250015280109],
              [2.02055407415321, 42.352635993840352],
              [2.062218168021843, 42.356944962636874],
              [2.093609233691893, 42.364164128223933],
              [2.123336156300923, 42.395764238559877],
              [2.144582269253249, 42.419300119827938],
              [2.273182121996001, 42.435609124158248],
              [2.332218213158455, 42.418054066098591],
              [2.440836111589363, 42.378473210450977],
              [2.477218097700245, 42.348191070574046],
              [2.533891162712735, 42.332909184661958],
              [2.670418116586859, 42.349582131228999],
              [2.657082173321044, 42.381936109938465],
              [2.678336165262579, 42.40166426022148],
              [2.724718097165805, 42.419445126753317],
              [2.950000185822262, 42.480554062577085],
              [3.047982119645951, 42.465964186592771],
              [3.084027153248144, 42.4347181278481],
              [3.106109109587294, 42.429164111150556],
              [3.16471805896299, 42.434435992986494],
              [3.177654177403952, 42.436809022210127],
              [3.182937951999918, 42.437950030000025],
              [3.20327485200022, 42.443382891000056],
              [3.231773138000108, 42.452882320000072],
              [3.276103806000208, 42.468714701000124],
              [3.307768568000029, 42.487713558999985],
              [3.345766283000273, 42.506712415999971],
              [3.374264569000104, 42.522544797000137],
              [3.411666667000191, 42.544999999000026],
              [3.868055556000058, 42.759722219000025],
              [4.145833333000041, 42.723611108999989],
              [4.161688775000073, 42.715859561000059],
              [4.208333333000155, 42.693055559000129],
              [4.53561935200014, 42.465773597000123],
              [4.558333333000235, 42.449999999000113],
              [4.987500000000182, 41.947222219000025],
              [5.016666667000152, 41.883333329000095],
              [5.15576395000005, 41.708021371000086],
              [5.251388889000111, 41.587499999000045],
              [5.337500090000276, 41.497222176000093],
              [5.833842369000138, 41.258961330000147],
              [5.923028545000079, 41.163629692000086],
              [5.935000000000173, 41.150833332000218],
              [5.951666667000097, 41.101388888000088],
              [6.118888889000289, 40.585277777000044],
              [6.135833333, 40.518611110000052],
              [6.166944444000137, 40.450833332000059],
              [6.185833333000062, 40.351388888000088],
              [6.300000000000239, 40.018611110000165],
              [6.300000000000239, 39.618055555000012],
              [6.216666667000197, 39.335555555000042],
              [6.085555556000145, 38.916666665999969],
              [6.055555556000229, 38.797222219000105],
              [5.836111111000264, 38.687499999000238],
              [5.3, 38.452777779000144],
              [4.738888889000123, 38.384722219000082],
              [4.57083333300011, 38.387499999000227],
              [4.41805555600007, 38.338888888999975],
              [4.340277778000257, 38.304166669000153],
              [4.241666667000175, 38.254166669000085],
              [4.13472222200005, 38.220833329000016],
              [3.376388889000168, 38.026388889000089],
              [3.244444444000294, 37.997222219000207],
              [3.147222222000153, 37.99166666900004],
              [2.919444444000135, 37.984722219000105],
              [2.768055556000149, 37.984722219000105],
              [2.643055556000093, 37.916666669000051],
              [2.186111111000059, 37.726388889000248],
              [1.870833333000178, 37.640277779000201],
              [1.638888889000043, 37.613888889000066],
              [1.533333332999973, 37.606944439000131],
              [1.494444444000123, 37.601388889000191],
              [0.786111111000139, 37.605555559000152],
              [0.600000000000193, 37.438888889000111],
              [0.298611111000127, 37.17777777900011],
              [0.006944444000283, 37.008333329000095],
              [-0.333333332999814, 36.834722219000128],
              [-0.38055555599982, 36.82361110900024],
              [-0.479166666999902, 36.76944443900004],
              [-0.627777777999881, 36.747222219000093],
              [-0.72083333299986, 36.723611109000103],
              [-0.86666666699989, 36.704166669000131],
              [-1.002777777999938, 36.593055559000106],
              [-1.040277777999961, 36.568055559000015],
              [-1.208333332999814, 36.472222219],
              [-1.268055555999922, 36.448611109000069],
              [-1.366666666999834, 36.390277779000087],
              [-1.547222221999903, 36.287499999000147],
              [-1.858333332999905, 36.091666669000062],
              [-2.055555555999888, 35.961111109000058],
              [-2.069444443999828, 35.944444439000165],
              [-2.101388888999963, 35.905555559000049],
              [-2.370260653999821, 35.748967552000181],
              [-2.519330091999905, 35.735196518000123],
              [-2.638793482999915, 35.723975537000115],
              [-2.71423327999986, 35.716710641000134],
              [-2.751330929999824, 35.713431833000072],
              [-2.783724345999815, 35.710369059000129],
              [-2.878118516999905, 35.701629173000185],
              [-2.947442125999828, 35.694965424000088],
              [-2.980010967999817, 35.692336543000067],
              [-3.023079093999911, 35.688957158000164],
              [-3.040487500999859, 35.687591190000148],
              [-3.059625959999948, 35.686055128000191],
              [-3.097782855999867, 35.682992636000108],
              [-3.179698804999816, 35.678274824000027],
              [-3.191792514999861, 35.677588845000059],
              [-3.220219608999855, 35.675976405000029],
              [-3.358272958999919, 35.667644591000055],
              [-3.439327728999899, 35.662752763000128],
              [-3.452004235999766, 35.670921686000042],
              [-3.612015259999907, 35.774231205000149],
              [-3.642074349999973, 35.794125146000169],
              [-3.710016536999831, 35.839774795000039],
              [-3.85322495399987, 35.936280017000058],
              [-3.942430033999926, 35.996152117000122],
              [-3.949352322999857, 35.995366287000138],
              [-3.989476377999893, 35.994908977000136],
              [-4.500821254999892, 35.875919395000153],
              [-4.544082493999895, 35.861089097000217],
              [-4.780340632999923, 35.799091011000087],
              [-4.78850758499982, 35.797617814000091],
              [-5.010771406999936, 35.79557516400024],
              [-5.062781628999971, 35.79487506800001],
              [-5.076941510999859, 35.794587746000047],
              [-5.187669755999821, 35.792608598000072],
              [-5.196485484999812, 35.792451026000208],
              [-5.198340695999832, 35.793110245000094],
              [-5.203213544999869, 35.794841734000101],
              [-5.224330737999935, 35.801842966000095],
              [-5.236224965999838, 35.806489437000096],
              [-5.242799862999902, 35.809538343000099],
              [-5.246242035999842, 35.811097330000223],
              [-5.251533030999838, 35.813435479000134],
              [-5.255167673999949, 35.815359633000185],
              [-5.259847841999942, 35.817863842000179],
              [-5.272708787999818, 35.825158322000107],
              [-5.275628737999853, 35.826619159000131],
              [-5.28697548699995, 35.831871750000118],
              [-5.292306801999871, 35.835437755000214],
              [-5.307976784999823, 35.838529842000014],
              [-5.323396588999856, 35.841252992000079],
              [-5.341769735249898, 35.842009533250177],
              [-5.345836729401299, 35.841663797509852],
              [-5.354446013216318, 35.847218130217243],
              [-5.377217800188987, 35.871518105781036],
              [-5.392363899269299, 35.900554024290273],
              [-5.39549400995412, 35.916565064886868],
              [-5.394371142666557, 35.917934120666757],
              [-5.393917198499878, 35.918750575500269],
              [-5.392143913249811, 35.921852591750167],
              [-5.391924810499745, 35.923853669000209],
              [-5.391786886999853, 35.92513314300021],
              [-5.391742877714137, 35.926430369285697],
              [-5.391651901999978, 35.927061475000244],
              [-5.39104034699983, 35.930274315000133],
              [-5.387854732499818, 35.940261179500169],
              [-5.385674303999792, 35.947096791000092],
              [-5.384116194199862, 35.951963851400137],
              [-5.383691174999854, 35.953220354000223],
              [-5.378830216999916, 35.977483253500225],
              [-5.377108854999904, 35.986075229000051],
              [-5.375067965666517, 35.996262089666857],
              [-5.386388888999818, 35.99444444400001],
              [-5.413333332999855, 35.988611111000239],
              [-5.483333332999962, 35.971666667000136],
              [-5.582777777999922, 35.942222222000112],
              [-5.649444443999869, 35.936111111000116],
              [-5.888333332999878, 35.952222222000046],
              [-5.980555555999899, 35.958888889000036],
              [-6.053888888999836, 35.958888889000036],
              [-6.602679126999817, 35.887299889000133],
              [-6.623020884999846, 35.884768670000085],
              [-6.640614131999882, 35.882654615000206],
              [-6.671598468999832, 35.879247722000116],
              [-6.715045555999836, 35.874470484000085],
              [-6.754642107999928, 35.870496683000056],
              [-6.766298907999897, 35.869326839000223],
              [-6.78446175199997, 35.867671673000075],
              [-6.800162425999815, 35.866377491000094],
              [-6.892561568999895, 35.84481060700017],
              [-6.913913457999854, 35.840024368],
              [-6.954660721999858, 35.831249447000232],
              [-7.050054640999974, 35.811483259999989],
              [-7.067371850999848, 35.808284522],
              [-7.076003019999916, 35.807245553000087],
              [-7.25253679299982, 35.786673314000154],
              [-7.520946964999837, 35.760053222000124],
              [-7.548615374999827, 35.75740595100018],
              [-7.546606214999883, 35.758623647999968],
              [-7.533329999999864, 35.76667],
              [-7.531427592999876, 35.777653334000092],
              [-7.493573579999861, 35.999449025000104],
              [-7.35753603399985, 36.796525447000192],
              [-7.362671542999976, 36.895384004000107],
              [-7.366542881999862, 36.969907279000211],
              [-7.385854771999902, 37.077647299000034],
              [-7.396519182499958, 37.137143481000123],
              [-7.405979623499888, 37.1899227830001],
              [-7.407600767037877, 37.198967056974084],
              [-7.409445937830128, 37.201664117178439],
              [-7.431475612117765, 37.25192367034731],
              [-7.431854751575088, 37.253190727146375],
              [-7.434717878719255, 37.366945022516177],
              [-7.447217810959842, 37.418609062177325],
              [-7.461390938716704, 37.45721811996799],
              [-7.471390884509105, 37.481382141062056],
              [-7.484726827774693, 37.498882213836623],
              [-7.50305486494733, 37.522500070118156],
              [-7.512536809121968, 37.528545098705195],
              [-7.524445984824979, 37.55250007513348],
              [-7.446945902019934, 37.699436011675743],
              [-7.42305496333222, 37.743891111671587],
              [-7.386945891989626, 37.769718102360287],
              [-7.319999798778383, 37.815273243331774],
              [-7.281663823393842, 37.865554101327007],
              [-7.252781796627346, 37.9309731794134],
              [-7.251945953240465, 37.959444995837373],
              [-7.253545890922908, 37.983673222309903],
              [-7.210836741363494, 37.996936075379381],
              [-7.123608789343905, 38.009991057249863],
              [-7.004026858614793, 38.03110909472133],
              [-6.951663935865042, 38.140000075558135],
              [-6.9415268621363, 38.170409117449481],
              [-6.933745941396921, 38.209073160525236],
              [-6.947572896551776, 38.217427068167524],
              [-6.99333674691303, 38.209444981751176],
              [-7.01680875807881, 38.193809044247814],
              [-7.087781852862918, 38.177009028028237],
              [-7.102217837104263, 38.18916412877121],
              [-7.129999822894888, 38.225827076277255],
              [-7.144999909221553, 38.246109102723494],
              [-7.1697178064789, 38.284436025652553],
              [-7.192217852149781, 38.320273188055523],
              [-7.277636821821091, 38.41944501685731],
              [-7.296526781784166, 38.43138218811697],
              [-7.317081890636814, 38.444718131382558],
              [-7.267217780868748, 38.703327002433753],
              [-7.257154970802333, 38.723400151852502],
              [-7.207354898774668, 38.749264190553276],
              [-7.164163957419305, 38.781945063487569],
              [-7.084163888166188, 38.835544987511355],
              [-7.059999867072293, 38.860545019630308],
              [-6.955872824737355, 39.023191157338374],
              [-6.961108832027662, 39.056664120124992],
              [-6.982781919128854, 39.09138213081286],
              [-7.01319079338208, 39.111800111728996],
              [-7.053263834385518, 39.119918152615242],
              [-7.102781939189583, 39.104718074078278],
              [-7.124863895528847, 39.106382217139299],
              [-7.145836758436928, 39.113882176483742],
              [-7.242781850834945, 39.213054005285528],
              [-7.313608765227286, 39.344718114199736],
              [-7.322781920088175, 39.388327144886546],
              [-7.375690840012282, 39.493745000314107],
              [-7.502781950179156, 39.602773109087479],
              [-7.52499986098843, 39.636664161205601],
              [-7.532508872788128, 39.669418124335834],
              [-7.411108907255141, 39.64874499828619],
              [-7.332845903620921, 39.64527321398117],
              [-7.307217731676189, 39.656664220428411],
              [-7.263608868627387, 39.672082228448573],
              [-7.2055549688817, 39.66721804238415],
              [-7.157708886127864, 39.660345049399268],
              [-7.052872902420347, 39.663753969430161],
              [-7.017217794954888, 39.675000136590029],
              [-6.989717776387863, 39.724718065966499],
              [-6.870690895288533, 40.015973108872743],
              [-6.882499823429271, 40.044164131539446],
              [-6.928890807787923, 40.101664155121739],
              [-6.946945930192499, 40.113191116038934],
              [-6.982636744565411, 40.119164227896476],
              [-7.00889087704104, 40.132773085930182],
              [-7.02721774074729, 40.187773123064289],
              [-7.008336833239639, 40.226800102548452],
              [-6.9465268350325, 40.25898208460444],
              [-6.910281809220123, 40.256382185870052],
              [-6.878336699748502, 40.261800080459921],
              [-6.862299771996163, 40.271518059028608],
              [-6.831108866174532, 40.317218039287525],
              [-6.815554903684529, 40.387773212378136],
              [-6.796108888263177, 40.524436120722044],
              [-6.819445950787554, 40.733327062396882],
              [-6.801736833346979, 40.857009084894855],
              [-6.862917853538079, 40.952636039195909],
              [-6.891390843428439, 40.974718163173122],
              [-6.930554950849057, 41.015553957367786],
              [-6.924408836509429, 41.030964254037087],
              [-6.841663861596828, 41.030136122000897],
              [-6.805554957892127, 41.045553962382883],
              [-6.774445859445791, 41.070691122438234],
              [-6.75430883992459, 41.10749119788062],
              [-6.753336706791572, 41.136664244326198],
              [-6.688472845973081, 41.210273112390155],
              [-6.643890843962822, 41.244718040671728],
              [-6.618054968456818, 41.246382183732919],
              [-6.589163889234953, 41.241936087000056],
              [-6.558336758012103, 41.242218054223656],
              [-6.510281798230437, 41.265000067118137],
              [-6.438054938365781, 41.318882126003757],
              [-6.357781786706425, 41.38055399044606],
              [-6.295836672219764, 41.43610019239253],
              [-6.21229089027554, 41.536527127379372],
              [-6.190454858975954, 41.579636093721604],
              [-6.256108797990066, 41.640409082864778],
              [-6.303608875779958, 41.663327050229157],
              [-6.347217738828874, 41.672218070228325],
              [-6.429445885590212, 41.680000164434375],
              [-6.460972905730159, 41.670691055103646],
              [-6.501808867562829, 41.659436003126302],
              [-6.545626775277412, 41.687009111889154],
              [-6.547499795366207, 41.765000038945573],
              [-6.528054953411299, 41.839436033217396],
              [-6.548608888797617, 41.921944973735833],
              [-6.60229078783459, 41.949164198545759],
              [-6.697217853218433, 41.936664266305343],
              [-6.906108794893498, 41.94471810181301],
              [-6.940417768705117, 41.951109135364106],
              [-6.982845956869369, 41.973864158530262],
              [-7.0087549225712, 41.954582093135571],
              [-7.056108819969324, 41.951109135364106],
              [-7.075836802614276, 41.959164144338217],
              [-7.098890892086558, 41.983609126827446],
              [-7.131181838884004, 41.993809064829975],
              [-7.184999860029109, 41.977354047746132],
              [-7.178608826478069, 41.931591203213429],
              [-7.186945970313843, 41.898327117454471],
              [-7.200554995985556, 41.883609165989469],
              [-7.207346013957419, 41.883835980289916],
              [-7.268336764945786, 41.86360910676666],
              [-7.331108838002081, 41.844991055743264],
              [-7.418608866599357, 41.82277314493399],
              [-7.60916389211269, 41.835691166506081],
              [-7.598054852889049, 41.865273249827681],
              [-7.692781926062537, 41.90388213998034],
              [-7.75944588441223, 41.897500158884952],
              [-7.858054952233317, 41.860000194525412],
              [-7.876663950801117, 41.852636022012916],
              [-7.926663847401016, 41.877773182068267],
              [-8.035281913469987, 41.827218068200409],
              [-8.055063875571477, 41.819518116645838],
              [-8.067499770071606, 41.815273185589945],
              [-8.087781796517959, 41.809436028202072],
              [-8.136108832877369, 41.809164119262277],
              [-8.163808843654806, 41.817182080224484],
              [-8.16166391462616, 41.827500203062016],
              [-8.165490923988727, 41.858609133870459],
              [-8.193681946655488, 41.873400175531287],
              [-8.218336812000814, 41.907491219859764],
              [-8.208754955540087, 41.928745044163122],
              [-8.165281879323004, 41.974445024422039],
              [-8.134726824677955, 41.998882127922059],
              [-8.102226833214672, 42.010964138469092],
              [-8.084654843710553, 42.022982111275724],
              [-8.0837549625831, 42.060482075635093],
              [-8.197836688433199, 42.150673198073605],
              [-8.251945896895336, 42.138609124799416],
              [-8.310554846270861, 42.103609146887948],
              [-8.446108828821536, 42.084718013458485],
              [-8.473608847388618, 42.084718013458485],
              [-8.518054894928923, 42.083044985580102],
              [-8.613890893895643, 42.056382151504408],
              [-8.639999851808, 42.045273112280768],
              [-8.741181828634865, 41.967836061387743],
              [-8.745018112427715, 41.952474350576949],
              [-8.749484673999859, 41.945428967000169],
              [-8.750469639999835, 41.943787019000013],
              [-8.751427673999956, 41.942189967000047],
              [-8.75563867399984, 41.936358967000047],
              [-8.761145673999863, 41.932795967000118],
              [-8.76345683999989, 41.931585518000077],
              [-8.767948673999797, 41.929232967000189],
              [-8.776046673999815, 41.925021967000134],
              [-8.782524673999944, 41.92178296600008],
              [-8.789327673999821, 41.920162966000134],
              [-8.790621951999924, 41.91977456300009],
              [-8.795805673999837, 41.91821896600004],
              [-8.802107181999929, 41.91575367400003],
              [-8.80325667399984, 41.915303966000124],
              [-8.80401515799997, 41.915076351000039],
              [-8.809734673999913, 41.913359966000201],
              [-8.816537673999846, 41.908824966000111],
              [-8.823663673999818, 41.904613966000056],
              [-8.830790673999843, 41.900078966000024],
              [-8.832652096999823, 41.898683019000032],
              [-8.838564673999826, 41.894248966000077],
              [-8.845829436999907, 41.888867267000194],
              [-8.862170507999849, 41.879589576000058],
              [-8.867825204999917, 41.876379107000048],
              [-8.876279243999818, 41.871579305000125],
              [-9.190090806999933, 41.871579305000125],
              [-9.555880592999813, 41.810779492000108],
              [-9.68968, 41.788540000000125],
              [-10.893699999999853, 41.68129],
              [-12.097719999999867, 41.57387],
              [-12.351526902999865, 41.551185858000053],
              [-12.556189856999822, 41.532893896000019],
              [-13.288206511999761, 41.467469154000185],
              [-13.298854328999823, 41.466517909000061],
              [-13.301739999999825, 41.466260000000148],
              [-13.297839999999837, 41.43966],
              [-13.286604730999954, 41.374846420000097],
              [-13.286379999999895, 41.373550000000137],
              [-13.28598851799984, 41.371590511000079],
              [-13.285812287999931, 41.370708421000131],
              [-13.285500304999857, 41.369146848500179],
              [-13.296564678999914, 41.38319596600013],
              [-13.301620678999825, 41.393498966000095],
              [-13.32099567899985, 41.418419966000101],
              [-13.326112678999834, 41.428829966000251],
              [-13.345428678999838, 41.453642966000018],
              [-13.350606678999839, 41.464160966],
              [-13.369864678999932, 41.488865966000049],
              [-13.375103678999949, 41.499491966],
              [-13.39430267899985, 41.524088966000136],
              [-13.396505678999858, 41.528550966000012],
              [-13.404493678999813, 41.538775966000117],
              [-13.67578567899983, 42.082261966999965],
              [-13.678505678999841, 42.09315596700003],
              [-13.684793678999824, 42.10561696700006],
              [-13.685653678999813, 42.109057967000183],
              [-13.687634678999871, 42.112981967000167],
              [-13.831748678999816, 42.682974967],
              [-13.832105678999824, 42.707189967000033],
              [-13.834781678999946, 42.717645967000067],
              [-13.835223678999853, 42.74732396700017],
              [-13.838503678999814, 42.760125967000022],
              [-13.838949678999882, 42.789812966999989],
              [-13.842232678999835, 42.802604967000036],
              [-13.842311678999863, 42.807826967000096],
              [-13.842888678999884, 42.810075967000046],
              [-13.852086678999967, 43.389868968000087],
              [-13.850814678999939, 43.395487968],
              [-13.850857678999944, 43.398086968000086],
              [-13.720446678999906, 43.970211968000115],
              [-13.45049067899987, 44.516904969000109],
              [-13.438099678999833, 44.532466969000041],
              [-13.435088678999932, 44.538507969000023],
              [-13.415401678999842, 44.563211969],
              [-13.410554678999972, 44.572923969000101],
              [-13.39093067899995, 44.597518969000134],
              [-13.386022678999893, 44.607338969000125],
              [-13.374595678999867, 44.62164696900021],
              [-13.37170367899995, 44.62742896900005],
              [-12.965632677999849, 45.130970969000089],
              [-12.434442677999868, 45.574938970000119],
              [-12.422431677999839, 45.581930970000087],
              [-12.419209677999845, 45.584596970000177],
              [-12.398752677999823, 45.596497970000087],
              [-12.393205677999845, 45.60108297],
              [-11.75143867699984, 45.97024397000007],
              [-11.749883676999843, 45.970849970000131],
              [-11.740307676999976, 45.976297970000076],
              [-11.735029676999829, 45.978354970000055],
              [-11.701849676999814, 45.997214970000186],
              [-11.696714676999875, 45.999212969999974],
              [-11.663389676999827, 46.018131970000013],
              [-11.658398676999923, 46.020070970000063],
              [-11.624927676999903, 46.03904797000007],
              [-11.620080676999919, 46.040928970000095],
              [-11.586464676999867, 46.059962970000072],
              [-11.581760676999863, 46.061785970000074],
              [-11.547999676999893, 46.080877970000074],
              [-11.543439676999867, 46.082642970000052],
              [-11.509532676999982, 46.10179297000002],
              [-11.505116676999876, 46.103498969999976],
              [-11.471064676999845, 46.122706970000138],
              [-11.46679267699983, 46.12435497000024],
              [-11.432593676999829, 46.143620970000029],
              [-11.431133676999821, 46.144183970000086],
              [-11.419133676999877, 46.150937970000086],
              [-11.394324676999815, 46.160488970000017],
              [-11.39250567699986, 46.161511970000106],
              [-11.354444676999833, 46.176146970000048],
              [-11.351545676999933, 46.177774969999916],
              [-11.313605676999941, 46.192343970000138],
              [-11.310584676999838, 46.194037970000124],
              [-11.27276367699983, 46.208539970000118],
              [-11.269620676999978, 46.210299970000051],
              [-11.231920676999948, 46.224734971000089],
              [-11.228655676999892, 46.226561971000081],
              [-11.191075676999844, 46.240929971000014],
              [-11.187688676999869, 46.242822971000123],
              [-11.165778413999874, 46.251188024000101],
              [-8.518708572999856, 45.792703134000021],
              [-6.684444443999837, 45.474999999000147],
              [-5.666666666999845, 45.29871612300002],
              [-4.999999999999829, 45.183246334999978],
              [-3.172222221999846, 44.866666666000128],
              [-3.063888888999941, 44.756944443],
              [-2.949999999999818, 44.663888888000088],
              [-2.840833332999921, 44.595833332000154],
              [-2.708333332999871, 44.499999999000124],
              [-2.605277777999959, 44.411111110000149],
              [-2.516666666999811, 44.319444443],
              [-2.425, 44.216666666000208],
              [-2.341666666999913, 44.108333331999972],
              [-2.266666666999868, 43.999999999000181],
              [-2.190277777999881, 43.890277777],
              [-2.044444443999964, 43.799999999000136],
              [-1.924999999999898, 43.729166666000026],
              [-1.858333332999905, 43.661111110000093],
              [-1.802222221999841, 43.595277776999978],
              [-1.793926549999924, 43.581121554000106],
              [-1.785004993999905, 43.562003934000131],
              [-1.778632453999876, 43.544160821999981],
              [-1.772259913999903, 43.525043201000074],
              [-1.767161880999822, 43.50337656500011],
              [-1.763338356999895, 43.479160913000044],
              [-1.760789340999906, 43.453670752000107],
              [-1.759514832999969, 43.440925672000049],
              [-1.759514832999969, 43.432004116000087],
              [-1.758240324999974, 43.423082560000012],
              [-1.758294830499892, 43.411375798500103],
              [-1.759615504999886, 43.405652877500074],
              [-1.762063848999958, 43.395043384000132],
              [-1.767172287999756, 43.386101760500026],
              [-1.780872881814616, 43.359927019906024],
              [-1.776945959980935, 43.342500204871101],
            ],
            [
              [-5.331139504999953, 36.011662385000079],
              [-5.308594955999922, 36.009871962000148],
              [-5.2929985049999, 36.012500385000124],
              [-5.23738650499979, 36.022219385000085],
              [-5.199224504999904, 36.029211385000053],
              [-5.154386504999877, 36.037216386000068],
              [-5.102715504999821, 36.047353386000054],
              [-5.09641750499992, 36.048552386000097],
              [-5.08594950499986, 36.050489385999981],
              [-5.004708504999826, 36.065071386],
              [-4.892374504999907, 36.084418386000095],
              [-5.041955504999862, 36.119768386000203],
              [-5.102711504999832, 36.13611738600008],
              [-5.137289504999842, 36.140117386000099],
              [-5.141062504999979, 36.140383386000053],
              [-5.259245504999853, 36.153696386000092],
              [-5.333165504999897, 36.162423386000228],
              [-5.334508774380652, 36.162563683546807],
              [-5.355799858110458, 36.163309087789116],
              [-5.356681504999813, 36.162864385999967],
              [-5.36094450499985, 36.159484386000088],
              [-5.36608850499988, 36.155515386000104],
              [-5.372259504999818, 36.152699386],
              [-5.373405504999766, 36.152540386000169],
              [-5.374149504999821, 36.152374386000133],
              [-5.377449504999845, 36.151460386],
              [-5.383403504999876, 36.150094886000147],
              [-5.392023004999828, 36.147220886000014],
              [-5.39945350499994, 36.144112386000074],
              [-5.39876750499991, 36.142043386000125],
              [-5.398193504999881, 36.139993386000128],
              [-5.397686504999854, 36.137942386000248],
              [-5.39580350499989, 36.128345386000035],
              [-5.395633504999978, 36.126895386000172],
              [-5.395507004999899, 36.125414886000044],
              [-5.39535050499984, 36.119726386000082],
              [-5.394549504999873, 36.117244386000095],
              [-5.39153350499987, 36.109310386000175],
              [-5.391213504999826, 36.106194386000084],
              [-5.39052450499986, 36.100060386000052],
              [-5.385524504999921, 36.091522386000122],
              [-5.383856504999756, 36.089307386000144],
              [-5.374840504999838, 36.076240386000052],
              [-5.37438050499992, 36.075592386000096],
              [-5.366565504999812, 36.064692386000104],
              [-5.346497504999832, 36.034827385000028],
              [-5.331139504999953, 36.011662385000079],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ESP", Country: "Spain" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-13.851430131428572, 27.666871818000075],
            [-13.852245657999902, 27.666303124000095],
            [-13.861707891999885, 27.652748830000021],
            [-13.871790004999895, 27.638374568000145],
            [-13.891436344999818, 27.611062257],
            [-13.901296307999843, 27.597575600000127],
            [-13.902322970999876, 27.596387153],
            [-13.92510099299983, 27.570482644000109],
            [-13.977616861999962, 27.520949833000074],
            [-13.98333308099987, 27.515638227000053],
            [-14.012427146999812, 27.488677126000127],
            [-14.034184361999849, 27.469759146999976],
            [-14.041181959999903, 27.463999139],
            [-14.228231540999872, 27.310597156000071],
            [-14.268328447999892, 27.279166197000038],
            [-14.332287806999972, 27.239502344000059],
            [-14.341963313999827, 27.237148772000111],
            [-14.375879237999925, 27.22928810700013],
            [-14.403123863999866, 27.223508878000146],
            [-14.531328533999982, 27.213981358],
            [-14.699106318999839, 27.187319919999979],
            [-14.71777236, 27.174029863000115],
            [-14.905778289999944, 27.040351106000045],
            [-14.913986505999901, 27.034536493000175],
            [-15.023696792999829, 26.975951196000025],
            [-15.084699364999864, 26.936793328000178],
            [-15.099435973999874, 26.927379364],
            [-15.206364901999905, 26.859950924000046],
            [-15.235286064999912, 26.841857387000104],
            [-15.251404952999906, 26.831913069],
            [-15.258177660999849, 26.827774152000032],
            [-15.285661054999821, 26.811398577000134],
            [-15.308733974999939, 26.798016578],
            [-15.377557660999969, 26.758319433000125],
            [-15.410191205999922, 26.73954144000011],
            [-15.492584913999906, 26.692323962000145],
            [-15.554405736999882, 26.656049610000125],
            [-15.568059712999855, 26.648540332999985],
            [-15.585835672, 26.638798682000171],
            [-15.857975898999882, 26.489476545000016],
            [-16.031126401999927, 26.387969911000127],
            [-16.227968714999918, 26.30195449600005],
            [-16.253439842999853, 26.290820117000109],
            [-16.254989536999915, 26.290143954000015],
            [-16.269745618999963, 26.283817252000134],
            [-16.303023718999867, 26.269665349999983],
            [-16.341713255999878, 26.253234011000174],
            [-16.379059540999862, 26.237538134000104],
            [-16.412024258999963, 26.223953342000172],
            [-16.486384415999964, 26.195277152000173],
            [-16.566939392999956, 26.169978585000123],
            [-16.66182805499983, 26.140232995],
            [-16.738160908999816, 26.116304259999978],
            [-16.743664535999812, 26.109992152000146],
            [-16.809385164999867, 26.034617187000023],
            [-16.943583259999912, 25.88054037800002],
            [-16.950919404999922, 25.872592125],
            [-17.129494215999983, 25.676605331000061],
            [-17.20054382399988, 25.632385877],
            [-17.228174904000014, 25.616446176],
            [-17.521046277999858, 25.47518705300017],
            [-17.660590284, 25.408923570000141],
            [-17.761696842999925, 25.361238771999965],
            [-17.845109931999872, 25.322374363000037],
            [-18.164227546999825, 25.177050864000094],
            [-18.546662667999954, 25.003854876],
            [-18.603069283999872, 24.980269315000029],
            [-19.49570550637489, 24.603629300750029],
            [-19.978366518999849, 24.798800374999971],
            [-19.979155018999904, 24.799239375000141],
            [-19.979882518999887, 24.799639375000126],
            [-19.980969518999842, 24.800080375],
            [-20.511828518999977, 25.124346375000172],
            [-20.513806518999843, 25.126066375],
            [-20.519127518999937, 25.129343875000117],
            [-20.520702518999855, 25.130346375000116],
            [-20.984599519999875, 25.531556376000097],
            [-21.367567519999881, 25.998284376000143],
            [-21.368507520000037, 25.999976376000163],
            [-21.370641519999936, 26.002600376000103],
            [-21.371693519999837, 26.003913376000028],
            [-21.661769519999865, 26.52269337700001],
            [-21.84920752, 27.078414377000129],
            [-21.926955519999893, 27.654665378000047],
            [-21.890927519999906, 28.234320878000133],
            [-21.890379019999841, 28.242081878000036],
            [-21.890249519999855, 28.243544377999982],
            [-21.88747252, 28.25402137799999],
            [-21.886488519999887, 28.269843378000118],
            [-21.793972519999841, 28.617791379000138],
            [-21.805404519999911, 28.701315379000121],
            [-21.804949519999923, 28.70855937900005],
            [-21.80561551999989, 28.713675379000151],
            [-21.805714519999896, 28.714648379],
            [-21.80541351999986, 28.720218379000173],
            [-21.768977519999879, 29.299985379000148],
            [-21.768820519999849, 29.300687379000053],
            [-21.76857852, 29.301708379000061],
            [-21.76823252, 29.307203378999972],
            [-21.616060520000019, 29.872298380000032],
            [-21.35098251999986, 30.405471380000122],
            [-20.97954752000004, 30.889928381000161],
            [-20.975366519999852, 30.89370838100011],
            [-20.969588519999888, 30.901201381000035],
            [-20.960463519999934, 30.909449381000101],
            [-20.953943519999854, 30.917901380999965],
            [-20.951725519999911, 30.919905381000049],
            [-20.941433519999947, 30.933242381000085],
            [-20.748308970333198, 31.185581270000014],
            [-20.72046751899984, 31.136448145000102],
            [-20.701139518999895, 31.102340145000099],
            [-20.494289518999892, 31.09145014500011],
            [-18.934166784999945, 30.943212922000114],
            [-18.247031979999946, 30.877923856999971],
            [-18.20257951699989, 30.873700145000086],
            [-17.981632395999839, 30.626994785000065],
            [-17.758939516999959, 30.378340144000148],
            [-17.034773959999882, 29.560295191000122],
            [-16.877099515999959, 29.382180143000099],
            [-16.501499515000035, 29.333180143000092],
            [-16.333990224999923, 29.322441305999988],
            [-16.076909514999954, 29.305960143000036],
            [-15.115589513999907, 29.246080142999986],
            [-15.085312471999885, 29.283547531000139],
            [-14.741072257999861, 29.709539658000054],
            [-14.680109513999923, 29.784980143999988],
            [-13.87444951299986, 31.722860145000013],
            [-13.055199511999888, 32.265020146000111],
            [-12.987132159999817, 32.492554649000127],
            [-12.913034371999856, 32.740247602000053],
            [-12.908772424999825, 32.75449358300007],
            [-12.515678046999881, 32.010477846000114],
            [-11.996469311999846, 31.013150628000133],
            [-11.860662245000015, 30.644523495],
            [-11.753166798999843, 30.324310062000151],
            [-11.753226528999988, 30.323090359000119],
            [-11.767352523999847, 30.253890943000087],
            [-11.783387855999848, 30.219098337],
            [-11.794282071999817, 30.19617237400017],
            [-11.836998349999959, 30.108815639000156],
            [-11.900170647999914, 29.985668034000142],
            [-11.97294183599999, 29.743553525],
            [-11.993903601999847, 29.675916931000145],
            [-12.069174971999928, 29.436749151],
            [-12.081397780999964, 29.406796863000025],
            [-12.083990797999832, 29.400495489000107],
            [-12.127510202999872, 29.295532362000117],
            [-12.135148367999932, 29.279828762],
            [-12.168649098999879, 29.211223068999985],
            [-12.179687540999879, 29.18887084],
            [-12.204507509999843, 29.140050339000126],
            [-12.209258831999875, 29.130801132000087],
            [-12.22851361799988, 29.108652493000179],
            [-12.237088791999838, 29.099073540000134],
            [-12.265532132000033, 29.068666379999982],
            [-12.289051599999851, 29.044988563000018],
            [-12.305397917999841, 29.028640489999972],
            [-12.318533820999846, 29.015898015000019],
            [-12.31961964899989, 29.014852106999982],
            [-12.393674119999901, 28.944330609000176],
            [-12.400173404999862, 28.938317854999966],
            [-12.404165679999977, 28.934798094000072],
            [-12.431719471, 28.910914269],
            [-12.437995588999883, 28.905483966000034],
            [-12.484622664000028, 28.873487380000014],
            [-12.500168817999878, 28.863580243000101],
            [-12.509163835999914, 28.857878701000047],
            [-12.541904350000038, 28.83804435600014],
            [-12.542910407999869, 28.837455180000049],
            [-12.551260743999876, 28.832662275000146],
            [-12.56887408699987, 28.822694084000048],
            [-12.575847823999936, 28.817019810999952],
            [-12.610317798999858, 28.789115569999964],
            [-12.629379687999915, 28.773993607000037],
            [-12.636126717999957, 28.768664676000114],
            [-12.64425583799985, 28.762401210000021],
            [-12.686708867999869, 28.730021794000137],
            [-12.753624317999822, 28.687169113],
            [-12.771995464999918, 28.675470937000128],
            [-12.843497717, 28.636049368000116],
            [-12.896224990999826, 28.60939011100011],
            [-12.905938950999854, 28.604677757000118],
            [-12.967730422999892, 28.575751215000096],
            [-12.976734043999841, 28.571598840999982],
            [-13.164954366999865, 28.496881975000136],
            [-13.236589265999868, 28.412083992000177],
            [-13.249308984999914, 28.396977605000117],
            [-13.260396806000017, 28.379604558999986],
            [-13.276396314999886, 28.353912771000111],
            [-13.294332559999987, 28.3250314510001],
            [-13.295720592999885, 28.322790263000016],
            [-13.314685972999854, 28.295337441000171],
            [-13.386704610499862, 28.18646336750011],
            [-13.39230420899986, 28.176716303999953],
            [-13.399267910999896, 28.162404272999979],
            [-13.411370008999882, 28.140021735],
            [-13.452280073999873, 28.062614112],
            [-13.475780879999832, 28.038826394999987],
            [-13.492325321999971, 28.022044313000109],
            [-13.544680563999862, 27.965404117999967],
            [-13.549666464999916, 27.959850827000125],
            [-13.577867350999838, 27.928854256000065],
            [-13.596978758, 27.907497529000139],
            [-13.601977847999819, 27.901874850000141],
            [-13.605988648999954, 27.897320120000032],
            [-13.669748366999841, 27.825170199000141],
            [-13.684948187999908, 27.808180012999983],
            [-13.727651533999847, 27.761725992000137],
            [-13.851430131428572, 27.666871818000075],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "EST", Country: "Estonia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.372054146418606, 57.535636067739389],
            [27.310273317234561, 57.531382251866077],
            [27.077082232357867, 57.565200213788259],
            [27.045554038751419, 57.594018035176205],
            [27.025273185771397, 57.608044982541443],
            [26.996109191781301, 57.618327063195451],
            [26.96999118141369, 57.62331815127429],
            [26.902218129632843, 57.633463271630077],
            [26.878463313053004, 57.632073216803732],
            [26.697773284690612, 57.575554043533756],
            [26.624163243160183, 57.546245042618168],
            [26.606800130683666, 57.527009077691119],
            [26.53124515233506, 57.522636071154139],
            [26.511391273503961, 57.526100144108128],
            [26.306245030902232, 57.608191162933238],
            [26.280836129545264, 57.624300175053108],
            [26.20444514017143, 57.694991134975666],
            [26.035000273140668, 57.823327125405669],
            [25.946945194913411, 57.853882180050604],
            [25.883054137779965, 57.855827116868952],
            [25.836382192026235, 57.860545122541495],
            [25.801391266570363, 57.865827062661268],
            [25.620409212700565, 57.917353974385847],
            [25.452218110750266, 58.005127085389503],
            [25.429854019549282, 58.031863177299144],
            [25.297845079137517, 58.083253966915734],
            [25.267500242624635, 58.072282223266583],
            [25.295209138219548, 58.038391171148348],
            [25.295209138219548, 58.01090020503672],
            [25.2398540436659, 57.992627153149115],
            [25.219436062749594, 58.001518173148284],
            [25.209018195263667, 58.020273184469957],
            [25.201800203142938, 58.04458221248909],
            [25.186109112716679, 58.06485401301363],
            [25.166518090369976, 58.075136093667638],
            [25.131800247320314, 58.076382147397268],
            [25.091663336214481, 58.071663303534407],
            [25.065409203738795, 58.06319121105706],
            [25.038327106865182, 58.050273189484969],
            [25.015827061194301, 58.039436059201151],
            [24.723882187654908, 57.965000064929583],
            [24.549927186150882, 57.953400181454569],
            [24.443954113455447, 57.902836015131342],
            [24.430827214855697, 57.882627078880674],
            [24.411382205262612, 57.872491178618461],
            [24.373054108867166, 57.868882098739064],
            [24.324436052828673, 57.870273159393989],
            [24.314982022232442, 57.871827244831493],
            [24.322987650287928, 57.889596348652887],
            [24.261116523000112, 57.917216406],
            [24.209449523000046, 57.89916640600002],
            [23.601116522000041, 57.89916640600002],
            [23.648499522000122, 57.782900406000167],
            [23.58233352200017, 57.669550406],
            [23.406016522000129, 57.593833406],
            [23.180833522000171, 57.586383406000081],
            [22.999166521000149, 57.702216405999977],
            [22.907683521000138, 57.78051640599999],
            [22.70749952100013, 57.94083340600001],
            [22.583599521000025, 57.927400406000018],
            [22.143333521000102, 57.77916640600013],
            [21.91611652000006, 57.749450406000065],
            [21.842783520000154, 57.763050406],
            [20.396250144400142, 58.023999973000073],
            [20.373333330000207, 58.19999999900007],
            [20.380421750999972, 58.229391009000082],
            [20.397164703000101, 58.298812997000041],
            [20.441666670000075, 58.483333329000246],
            [20.461555045000125, 58.651910985000129],
            [20.475979811500139, 58.774178053500137],
            [20.476615360500148, 58.779565086000034],
            [20.481606388111175, 58.844569860222336],
            [21.186133363000152, 59.191483339000072],
            [21.776133333000189, 59.311483333000055],
            [22.164466667000113, 59.37983333300015],
            [22.36483092500012, 59.391792574000149],
            [22.751133333000041, 59.414849999000026],
            [22.987487504000171, 59.480453018000119],
            [23.15945, 59.528183333000058],
            [23.232303721000108, 59.527820876000177],
            [23.384085259000074, 59.527065743999955],
            [23.49445, 59.526516666000092],
            [23.59981326700003, 59.549982388],
            [23.906116667000049, 59.618199999000169],
            [24.406116667000134, 59.736549998999962],
            [24.8211, 59.84154999899998],
            [24.911678908, 59.859895095000056],
            [24.95276666700002, 59.868216666000066],
            [25.019995277000163, 59.874427555000054],
            [25.128155014000043, 59.884419851000118],
            [25.169433333000114, 59.888233333000059],
            [25.241512505666805, 59.885333595666808],
            [25.372905504000158, 59.88004767000011],
            [25.459433333000163, 59.87656666600013],
            [25.967783333000114, 59.861566665999973],
            [26.039141528000158, 59.907709085000178],
            [26.132766667000027, 59.968249999],
            [26.190758376000076, 59.980588661000141],
            [26.211099761500179, 59.984916615000131],
            [26.242097562000112, 59.987332329000154],
            [26.309019751500159, 59.992547626500155],
            [26.335783333000023, 59.99463333300001],
            [26.440000000000168, 59.93833332900023],
            [26.915000000000134, 59.631666669000182],
            [28.069420812910806, 59.570993287983512],
            [28.069582216834334, 59.570127151218443],
            [28.068360954259305, 59.560726087033188],
            [28.06751116899332, 59.554183299001025],
            [28.066245045864463, 59.544436115109008],
            [28.039436198949858, 59.502218145093011],
            [28.021945392526874, 59.483045275844333],
            [28.020698492944945, 59.48213928007597],
            [28.015827361904911, 59.47860007519283],
            [28.026391275313046, 59.474991209025163],
            [28.076109204689345, 59.453327174379311],
            [28.10943615472226, 59.436100184010868],
            [28.175554115897484, 59.393609131572518],
            [28.194091197735958, 59.367973248276869],
            [28.179318093347916, 59.328236153953569],
            [28.164300237386584, 59.30371808126857],
            [28.144000273667388, 59.290800059696565],
            [28.095491182370296, 59.283945003984414],
            [28.055418141367028, 59.279200176221551],
            [28.020618155665801, 59.278409092197663],
            [27.995836053030217, 59.28447323152399],
            [27.961309149735399, 59.286318088418099],
            [27.916491280969154, 59.273663258606476],
            [27.879845097269452, 59.228053970540188],
            [27.83721809036129, 59.150827137779331],
            [27.806945170577961, 59.093882163827075],
            [27.790136101902959, 59.060963244841886],
            [27.704991220466269, 58.985827026376924],
            [27.689436084509879, 58.979427108008323],
            [27.596945135471884, 58.931382206510634],
            [27.54194509833772, 58.904991113736756],
            [27.493609177160835, 58.881936018436065],
            [27.457218138594527, 58.852491230688841],
            [27.43943609859619, 58.83416319351609],
            [27.428327227010556, 58.815891147456782],
            [27.421663278691341, 58.784853965739799],
            [27.451109239905151, 58.686936069656511],
            [27.489300208364313, 58.573609050370393],
            [27.498327182833378, 58.554436117355479],
            [27.519445052666839, 58.504027016241182],
            [27.535273271029354, 58.450827076638049],
            [27.542500147967445, 58.413882161908603],
            [27.543609241398741, 58.393882102685794],
            [27.539854148765613, 58.364782146435985],
            [27.520418191628124, 58.344018160555592],
            [27.491245145182546, 58.324854112357812],
            [27.465900113927745, 58.296936004459283],
            [27.475754046966557, 58.213118145937059],
            [27.551527122436511, 58.134853968836453],
            [27.572918242314159, 58.122209197308635],
            [27.609718150118482, 58.107218163437508],
            [27.645554139054781, 58.095127100435136],
            [27.673609207251701, 58.075691143297533],
            [27.686663350931696, 57.965273147335765],
            [27.779027230317382, 57.900545073349107],
            [27.813263281571295, 57.886245043577574],
            [27.820200144658628, 57.867427167981859],
            [27.7852732569431, 57.848327157524707],
            [27.744018199950432, 57.837354072770793],
            [27.702218151612072, 57.831663263413006],
            [27.673609207251701, 57.830273208586377],
            [27.630000176564891, 57.828609065525526],
            [27.577218158655086, 57.822773249242331],
            [27.548609214294999, 57.81860911973304],
            [27.403327194891489, 57.681518063773851],
            [27.34843612249901, 57.588982187734402],
            [27.362500117876351, 57.545827120924727],
            [27.372054146418606, 57.535636067739389],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ETH", Country: "Ethiopia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.399718108027315, 12.469718119729393],
            [42.366100138315318, 12.430273218551591],
            [42.339436130773294, 12.396945095052288],
            [42.325554190333463, 12.359164169297628],
            [42.308327199965021, 12.308335973023702],
            [42.292218187845066, 12.293891103964953],
            [42.255554066872634, 12.263336049319932],
            [42.168882170311321, 12.137218078114387],
            [42.127218244080694, 12.09027321759244],
            [42.058609181274761, 11.975827046591192],
            [42.025082239031605, 11.926364094710266],
            [41.94860927464461, 11.816664092576445],
            [41.891391218285975, 11.773609105690937],
            [41.829018123821953, 11.740973159757928],
            [41.798045147483407, 11.638336084535524],
            [41.759854179024245, 11.507918029350961],
            [41.793745063504417, 11.380973099575797],
            [41.811936140378833, 11.257918043437257],
            [41.806663252714401, 11.168335950085151],
            [41.802909165909767, 11.098973186543674],
            [41.800554186387018, 11.031109107294057],
            [41.789718229569559, 11.008054011993366],
            [41.808327228137529, 10.985282057382676],
            [41.83721813972133, 10.973054034081997],
            [41.881945148656968, 10.960836069065039],
            [41.918054052361498, 10.953609024489012],
            [41.940282189092642, 10.950000112247693],
            [41.991945055287232, 10.942218018041672],
            [42.019718156260552, 10.944718004489843],
            [42.080345132650081, 10.977427040618878],
            [42.125545048564192, 10.999444959217811],
            [42.171663285792675, 11.000282143709228],
            [42.30055415821451, 11.011391182932954],
            [42.358891198650184, 11.019164224683479],
            [42.501109129520017, 11.063609098757254],
            [42.608236222647889, 11.091573139485433],
            [42.640409152248338, 11.097082061543873],
            [42.748191207292194, 11.077918013346192],
            [42.759782205949733, 11.059100137750463],
            [42.769300192308236, 11.023191058618224],
            [42.792218159672558, 10.997773104805745],
            [42.810973170994231, 10.988336087632163],
            [42.890973240247462, 10.982082182017464],
            [42.944091204837235, 11.002435957555164],
            [42.917500287491151, 10.955273167550047],
            [42.855000291012772, 10.892218121441829],
            [42.838609144031267, 10.873891090097487],
            [42.764163259113758, 10.780418117280632],
            [42.66479127046361, 10.632918078652764],
            [42.681109159611054, 10.582773175127613],
            [42.709445189203251, 10.536109108362709],
            [42.722218203849906, 10.517918031488364],
            [42.746663353977141, 10.495691068223678],
            [42.786109260983181, 10.455553989479768],
            [42.797082178098918, 10.422782089076676],
            [42.803327198896199, 10.380554060760886],
            [42.806109152567984, 10.35000001194426],
            [42.816936224567968, 10.313891108239659],
            [42.850691154578243, 10.219444996461391],
            [42.864163219951735, 10.200554030669906],
            [42.890973240247462, 10.172082046607756],
            [42.922773175155527, 10.14778207104392],
            [42.953254133776198, 10.13250001749384],
            [42.980554160132982, 10.117636053275092],
            [43.013054151596265, 10.081109060238973],
            [43.034718186242031, 10.025000097311576],
            [43.042773195216256, 9.993053982011716],
            [43.051663377025108, 9.968191077829047],
            [43.063045163378803, 9.948745062407752],
            [43.084300161148548, 9.924864182003816],
            [43.1102733322289, 9.908891124353843],
            [43.147845213317822, 9.902773173209042],
            [43.180409242521449, 9.902773173209042],
            [43.204091304181503, 9.892291100344679],
            [43.224854116595424, 9.86319114409504],
            [43.237773311633788, 9.799309139417204],
            [43.24638219677135, 9.750827037848438],
            [43.260554151061882, 9.684718129128726],
            [43.267218099381097, 9.664444987499721],
            [43.275273275993328, 9.645554021708236],
            [43.283336163956477, 9.634718064890805],
            [43.305273281008539, 9.628336083795119],
            [43.32847321559646, 9.619582191732334],
            [43.386663237450108, 9.556945067317542],
            [43.427773287517283, 9.48527308944476],
            [43.425136173132813, 9.460835985944627],
            [43.425827177232605, 9.434582188745111],
            [43.441663274584272, 9.41763599213381],
            [43.555273266922228, 9.348473220802589],
            [43.583054079246409, 9.336109075393921],
            [43.627218159563228, 9.354444991555596],
            [43.727773337668992, 9.262782174553493],
            [44.01055410880312, 9.007218113658325],
            [44.650473190729286, 8.795173061809706],
            [45.002218249726837, 8.67860911914633],
            [45.189991153930265, 8.616109122667837],
            [45.411663324517093, 8.541945037335893],
            [45.6161002904702, 8.473327089712598],
            [46.059163334972283, 8.323609031860357],
            [46.331663366557024, 8.232218123798063],
            [46.552773279077172, 8.15721802744109],
            [46.790836093731144, 8.076391167256418],
            [46.994718177692533, 8.006109076572088],
            [47.01193611560555, 8.00110910367593],
            [47.086936211962524, 8.001944947062881],
            [47.607500177460025, 8.003336007717721],
            [47.746382278494849, 8.003609090123916],
            [47.85055408019295, 8.003054040494106],
            [47.902500254715534, 8.003054040494106],
            [47.988245112783176, 8.004109154468779],
            [47.987218162003302, 8.00110910367593],
            [47.904045207113285, 7.920454072506814],
            [47.761836161060756, 7.786591055107465],
            [47.658054117861383, 7.688891088507347],
            [47.555545118119966, 7.592218072687018],
            [46.991391232674829, 7.056945040541947],
            [46.561391216670046, 6.619445065193034],
            [46.113982322997373, 6.1620270056344],
            [45.990273310771187, 6.0355539770093],
            [45.955827209023226, 5.999164111909565],
            [45.716663347564946, 5.73860913057365],
            [45.489400279906619, 5.489764170920708],
            [45.374463264654054, 5.363918108654886],
            [45.096109144237317, 5.060273104078121],
            [45.007836136526834, 4.962573137478003],
            [45.000000230502366, 4.954309083838112],
            [44.950827124834092, 4.902500037251741],
            [44.512773273321528, 4.930273138225076],
            [44.466900290580639, 4.933526993046925],
            [44.290273305974864, 4.944164131120488],
            [44.221663237340607, 4.948335971980541],
            [44.05902715791629, 4.958609000179152],
            [43.981945164442919, 4.963053923445486],
            [43.95985415564806, 4.951391175696514],
            [43.922218236818793, 4.934444979085214],
            [43.887218258907438, 4.92278206369825],
            [43.855827193237388, 4.913891043699081],
            [43.833054065160212, 4.910282131457677],
            [43.798054087248858, 4.905691027799634],
            [43.767491153614884, 4.90333604827677],
            [43.737354188301396, 4.900553926967092],
            [43.68639121102396, 4.891945041829615],
            [43.587218208755615, 4.860000099996142],
            [43.567773199162644, 4.852500140651813],
            [43.490554077752876, 4.822218000775095],
            [43.358891142305168, 4.765273194460647],
            [43.321663254523543, 4.748609132710953],
            [43.193600178861658, 4.687782164111098],
            [43.158191164074168, 4.666391044233364],
            [43.080827203377027, 4.604164130161152],
            [42.985691260965524, 4.52541799362649],
            [42.968882192290522, 4.504309008610534],
            [42.957218103436986, 4.481109074022527],
            [42.946518101089538, 4.446109096111158],
            [42.933609131972872, 4.388745026998819],
            [42.919991221483741, 4.364026962103381],
            [42.876663319830215, 4.318336034300074],
            [42.855691295112393, 4.304718123810858],
            [42.831663228488452, 4.29528211246577],
            [42.768609188208501, 4.278336083492462],
            [42.740827202417876, 4.272782066794889],
            [42.726527172646371, 4.271191181567687],
            [42.656109127492044, 4.264718005365253],
            [42.622291165569919, 4.260418089024313],
            [42.485836128425348, 4.228891068884266],
            [42.401382251088449, 4.207500116644695],
            [42.370273320280006, 4.202082054416948],
            [42.269854096644082, 4.203745024011596],
            [42.236109224917726, 4.20777319905099],
            [42.195827139248507, 4.208891177299634],
            [42.151663226569866, 4.203335987135489],
            [42.117773180280068, 4.193891090972897],
            [42.098327164858773, 4.186664214034863],
            [42.073882182369601, 4.176145093158468],
            [42.040836193732019, 4.142782100941744],
            [41.959436179006531, 4.088473067907387],
            [41.937845066918527, 4.052708995700357],
            [41.938745115683986, 4.022364159187575],
            [41.905163355793974, 3.980318018171047],
            [41.891945094449369, 3.969864108501483],
            [41.840136215501019, 3.948473156261926],
            [41.800827101155193, 3.960553993342501],
            [41.781109176793933, 3.981944945581972],
            [41.725827172436226, 4.000136022456502],
            [41.611382174901536, 3.98916411116916],
            [41.534854057591446, 3.976109129298862],
            [41.487636114663275, 3.961109042972311],
            [41.469027116095248, 3.949308999648963],
            [41.273891212845882, 3.941109151387494],
            [41.180973289658681, 3.941527073081019],
            [41.160554135276044, 3.945836041877456],
            [41.13513618146348, 3.958473102054327],
            [41.11471820054723, 3.975973174829051],
            [41.093609215531131, 3.996391155745286],
            [41.063609210516091, 4.03124495326486],
            [41.048191202495929, 4.060417999710353],
            [41.009436131951446, 4.108609081600093],
            [40.876936179650244, 4.216664219050074],
            [40.853609175409787, 4.234718000350043],
            [40.783763278967996, 4.287973092876385],
            [40.672218252301974, 4.228609101660652],
            [40.398891262147771, 4.123053950658743],
            [40.388609181493649, 4.098891103031207],
            [40.182500193490426, 4.022500113657472],
            [40.000000176951374, 3.928582061780617],
            [39.86666320779679, 3.869445052503636],
            [39.782773264907036, 3.678335983189029],
            [39.594991140610063, 3.500836107184213],
            [39.560273297560485, 3.406664083640365],
            [39.519027125385236, 3.409309077013845],
            [39.5020822698784, 3.42263596782395],
            [39.499582283430442, 3.45013598639099],
            [39.450827099455438, 3.462500131799644],
            [39.422491237501362, 3.460000145351572],
            [39.331382296662724, 3.469164080118858],
            [39.328645102353988, 3.499653923557048],
            [39.296136058435394, 3.476500089436556],
            [39.295000142913835, 3.475282198901738],
            [39.248891293416904, 3.471391151798727],
            [39.196936066438724, 3.478609143919442],
            [39.130000199149407, 3.510836052976529],
            [39.084718140584044, 3.534445024440558],
            [39.04610019797596, 3.528891175380977],
            [38.956382317792105, 3.518609094727054],
            [38.913600245674701, 3.513891089054511],
            [38.810000257412895, 3.543336044439727],
            [38.729436086074458, 3.567218098310136],
            [38.621936165892379, 3.601664200058067],
            [38.516936064520195, 3.626945025934262],
            [38.4480540869462, 3.599445007367223],
            [38.323882226025404, 3.608891076996201],
            [38.121109235495311, 3.611664145850483],
            [38.056109252568746, 3.651945058053215],
            [38.033609206897864, 3.69805407518821],
            [37.998054179356615, 3.728609129833146],
            [37.280545160623291, 4.19444496713632],
            [37.130273226607613, 4.286391092466602],
            [37.099163289970846, 4.331664098576482],
            [37.039718161932257, 4.375553923020533],
            [36.88777320003814, 4.434718089664003],
            [36.712154055473064, 4.43383597817126],
            [36.666382158484708, 4.433608996232593],
            [36.64750024514862, 4.446664145741067],
            [36.433609162939348, 4.441664172844909],
            [36.271663249424506, 4.439164186396738],
            [36.211163342687399, 4.448000053472882],
            [36.045300230531637, 4.447082067434494],
            [35.95110926388665, 4.521109024830139],
            [35.940554100826347, 4.622500046322827],
            [35.924718171112687, 4.640273201503845],
            [35.774436178813261, 4.798609029310882],
            [35.779991201339357, 5.082636021812718],
            [35.802773214233838, 5.105273195419983],
            [35.823609116843585, 5.136664093451856],
            [35.830691154494417, 5.168400158257569],
            [35.805136072745626, 5.206809056199901],
            [35.796245052746457, 5.235136033336644],
            [35.798745039194529, 5.265553960045381],
            [35.821663341835034, 5.328609006153599],
            [35.796945109301504, 5.331108992601671],
            [35.774991228443099, 5.337218058929153],
            [35.753745115490773, 5.346945089953351],
            [35.726245096923748, 5.364444995089983],
            [35.527918203126404, 5.426809037098579],
            [35.504718100900305, 5.431391088301211],
            [35.443709245000974, 5.425518056367906],
            [35.41909126002983, 5.398400084948562],
            [35.352845055735742, 5.350691130130954],
            [35.326245085934175, 5.357082163682051],
            [35.303054036163445, 5.377364190128461],
            [35.270418090230379, 5.438335998015731],
            [35.277082206187657, 5.458053922376749],
            [35.292700206418004, 5.487636005698434],
            [35.289991175304152, 5.507782077674946],
            [35.103882246523796, 5.632500103408233],
            [35.105409261648532, 5.688335983929349],
            [35.028327100536927, 5.814164108922341],
            [34.986382212911138, 5.878053992589201],
            [34.975827217489012, 5.964718010161633],
            [34.987827085384623, 6.013944927648481],
            [34.981382240014995, 6.061109058758163],
            [34.969718151161516, 6.086527012570642],
            [34.952218246024898, 6.103473209181942],
            [34.922427118037348, 6.132291030569974],
            [34.870554033710647, 6.237500008969832],
            [34.859300155199747, 6.267218046761428],
            [34.856382247058178, 6.291109153087149],
            [34.85000026596245, 6.36666413143594],
            [34.756991147668515, 6.613782083753691],
            [34.704718246559167, 6.677782105628708],
            [34.679163332448383, 6.700000016437883],
            [34.654718182321261, 6.716664078187492],
            [34.632082182180483, 6.729027050129673],
            [34.602773181264951, 6.734718027125552],
            [34.553609128052102, 6.735691166086966],
            [34.528818140598872, 6.747218127004118],
            [34.486973165259172, 6.877491175263373],
            [34.471800244088598, 6.914309020340511],
            [34.430954056334059, 6.935409120539134],
            [34.360963320604895, 6.959026976820667],
            [34.328745128727149, 6.958745009597052],
            [34.299573255748129, 6.964327021851346],
            [34.280554046837693, 6.975836045495669],
            [34.235691251070222, 7.01971799095071],
            [34.223609240523245, 7.042500171483283],
            [34.193882150276153, 7.090000081635139],
            [34.163327095631217, 7.171664126311228],
            [34.09916329136783, 7.19971802104169],
            [34.02708227661887, 7.248127032414601],
            [34.029745039627159, 7.280036099702244],
            [34.032773253614749, 7.304445040007664],
            [34.027709242978148, 7.378673163080052],
            [33.885554176382271, 7.525417992229592],
            [33.712491177016688, 7.658473161798611],
            [33.647218111683941, 7.685000041404336],
            [33.464445180376657, 7.744027080111323],
            [33.439163348672054, 7.744445001804849],
            [33.257500181348377, 7.763609050002529],
            [33.096663361264774, 7.781109122777252],
            [33.05221815191473, 7.790691146876227],
            [33.014973165050577, 7.846064178702591],
            [32.996827182815338, 7.887217982304492],
            [32.991800220190811, 7.926044969578427],
            [33.031945177923802, 7.997391059054948],
            [33.121382264350501, 8.100973110043782],
            [33.162773275812896, 8.115000057409105],
            [33.185918225115842, 8.140291109207084],
            [33.17805415589666, 8.222500145229361],
            [33.165836190879787, 8.27832697329498],
            [33.160827165528104, 8.356527112655243],
            [33.171245200652123, 8.399791144206503],
            [33.263536157479876, 8.46152704638925],
            [33.326736210513531, 8.453682087909357],
            [33.380827146426697, 8.437918074925221],
            [33.508609260693305, 8.466200125060737],
            [33.63437318030779, 8.467153985644771],
            [33.669991239761003, 8.437082063900192],
            [33.69048231087325, 8.395482175410066],
            [33.71527329832648, 8.375064194493746],
            [33.771327108330667, 8.367744948982377],
            [33.789718177415551, 8.384445052915794],
            [33.818745043469249, 8.411526982151329],
            [33.852491256300169, 8.425836064378259],
            [33.881527174809349, 8.430836037274418],
            [33.915273220002149, 8.430554070050803],
            [33.970754043103824, 8.437564190972139],
            [34.002218198969814, 8.460282166126191],
            [34.070827094137627, 8.523336038768022],
            [34.105545104825325, 8.557782140515968],
            [34.121663337038711, 8.579582129631717],
            [34.122800258388594, 8.584644966801847],
            [34.1236002272299, 8.634164077434292],
            [34.130000145598416, 8.805826963689611],
            [34.134991233677141, 8.955273112602043],
            [34.100554184384606, 9.385273128606912],
            [34.097773236541315, 9.419718056888371],
            [34.094991282869586, 9.453891076230107],
            [34.094163318471686, 9.45471803479974],
            [34.085827180464236, 9.553054020214532],
            [34.140000259028767, 9.75805408242465],
            [34.207218093541741, 9.905000077250918],
            [34.219718193420221, 9.973609140056809],
            [34.230554150237737, 10.027636038229559],
            [34.32305415173127, 10.117218131581666],
            [34.348327098618455, 10.23847309018926],
            [34.34194511752284, 10.280836067146652],
            [34.328045072172046, 10.35000001194426],
            [34.286109237001739, 10.554164230767341],
            [34.314718181361997, 10.586391139824329],
            [34.352773195351261, 10.631664145934309],
            [34.439718174318813, 10.784444951215377],
            [34.594445089884715, 10.887782082992814],
            [34.673882230519013, 10.834718097859579],
            [34.710827145248658, 10.802218106396296],
            [34.769863236411055, 10.749444973304008],
            [34.800554077887881, 10.723327130574489],
            [34.860618125658647, 10.732009105907807],
            [34.975273173687498, 10.864445020468594],
            [34.978609171160684, 10.915273216742619],
            [34.933327112595322, 10.956664228204986],
            [34.983600259239523, 11.110282050339478],
            [35.005554140097928, 11.174445016412619],
            [35.007500250382662, 11.198327070282943],
            [34.978882253566809, 11.223891036849224],
            [34.971109211816355, 11.231109028969755],
            [34.96416329627354, 11.250418084092928],
            [34.967082210243547, 11.274864240048558],
            [34.986382212911138, 11.318335975161006],
            [35.00443616184927, 11.348891029806026],
            [35.013327181848439, 11.367773110780192],
            [35.087491267180297, 11.535827084794107],
            [35.09520027119035, 11.576454001960897],
            [35.091663275678542, 11.598891183357907],
            [35.078191210305107, 11.623473126145271],
            [35.066800203857866, 11.646527047979575],
            [35.058327105552138, 11.730273157410309],
            [35.063191291616562, 11.759582158325799],
            [35.083609104894805, 11.80555404752441],
            [35.102354057932558, 11.834444959108396],
            [35.116245050827814, 11.850836106089787],
            [35.134436127702344, 11.863891087960084],
            [35.168327179820466, 11.878335957018834],
            [35.20166334994687, 11.886391133630966],
            [35.231382225928684, 11.898191176954413],
            [35.25360918919344, 11.917500064439409],
            [35.265436054606965, 11.933609076559293],
            [35.283882276614776, 11.983327005935607],
            [35.383609155046514, 12.165553940068463],
            [35.647354121101756, 12.591527122138402],
            [35.686245146116192, 12.602218072030524],
            [35.700136139011448, 12.62500008492502],
            [35.701082288244635, 12.666118013981276],
            [35.964718121920299, 12.715553976133975],
            [36.012636121403744, 12.720553949030133],
            [36.037218231829144, 12.715000099970553],
            [36.072291299936381, 12.724100164635473],
            [36.093400284952423, 12.706391047194998],
            [36.111936193324453, 12.694027069424422],
            [36.142545059788034, 12.714853919578673],
            [36.15916335634617, 12.88139110438982],
            [36.135345172578212, 12.925418056770255],
            [36.142082211093367, 12.952773068411986],
            [36.151109185562433, 12.971664201841548],
            [36.168882173105288, 12.990000118003309],
            [36.15735403872165, 13.033053931422245],
            [36.248600275134805, 13.368053959485323],
            [36.348882203196268, 13.502773104477498],
            [36.362218146461856, 13.519445045216116],
            [36.397500259234931, 13.567782139859474],
            [36.408745085290292, 13.595135978034634],
            [36.411382199674762, 13.615553958950954],
            [36.407354024635453, 13.635273224416622],
            [36.409027220151955, 13.657500020043315],
            [36.421036140503105, 13.686873226337241],
            [36.471109127298888, 13.804164215283961],
            [36.486936172195129, 13.839444986952557],
            [36.446391230041598, 13.956945020565215],
            [36.539445107698469, 14.246109113899408],
            [36.542818153183788, 14.262054008354582],
            [36.558463310780695, 14.288609051155092],
            [36.641245166067421, 14.316664119351955],
            [36.736382281945424, 14.327218108945871],
            [36.825000121153522, 14.328053952332894],
            [36.886109224615069, 14.320273199231352],
            [36.949891149368767, 14.294373118346911],
            [36.999791301320698, 14.261527121919656],
            [37.075273189473336, 14.287500125361859],
            [37.090545184739909, 14.312364203010915],
            [37.090827151963509, 14.346664124367166],
            [37.091663330626631, 14.371245061326206],
            [37.101391199841117, 14.393053935259374],
            [37.124718204081518, 14.425627016918426],
            [37.19958217833053, 14.452500069126287],
            [37.287845127757265, 14.451873102767095],
            [37.336936090774174, 14.426664193620283],
            [37.391245123808659, 14.356391155391449],
            [37.433054056964608, 14.268053942302473],
            [37.530754191202817, 14.114309050515416],
            [37.572209240405613, 14.102254029696766],
            [37.684436218715774, 14.360000067632669],
            [37.838336175712044, 14.715273069686774],
            [37.911382282795358, 14.883609010924303],
            [37.926518155953801, 14.87097312421399],
            [37.955827156869219, 14.837773076195489],
            [37.978327202540157, 14.801109122861178],
            [37.980345061916211, 14.775973136272299],
            [38.018327153347599, 14.735000046503288],
            [38.040554116612356, 14.721945064633061],
            [38.143745067998026, 14.683327122024892],
            [38.188918161821931, 14.69211806209988],
            [38.244854122267185, 14.687364181881705],
            [38.263336218820569, 14.677635977391034],
            [38.306936197052067, 14.594164123832783],
            [38.333463244295871, 14.52160014382163],
            [38.448954135711659, 14.418744971478063],
            [38.561391164515925, 14.426527065683999],
            [38.795554047249738, 14.46971800703939],
            [38.893054189277706, 14.50082710548584],
            [38.96555413154843, 14.546109164051202],
            [39.01242707534098, 14.588953932804387],
            [39.024018241636583, 14.655164094914753],
            [39.094027082276654, 14.643609138440993],
            [39.118745147172177, 14.629391083682904],
            [39.13527325445196, 14.617773095296982],
            [39.152218109958682, 14.600000107754155],
            [39.178609202732588, 14.56430912574298],
            [39.189582287486218, 14.544300014064774],
            [39.209718133540918, 14.485553936752908],
            [39.263054027613947, 14.473609054142244],
            [39.375973183490402, 14.483891134796337],
            [39.482627202001623, 14.511318063167508],
            [39.531391270793989, 14.56555400600611],
            [39.613882274039639, 14.510326986933379],
            [39.695136108373362, 14.49360911336511],
            [39.725963239596211, 14.495691178119685],
            [39.746382226340842, 14.502773215770489],
            [39.779927105856899, 14.513609004949856],
            [39.851663289108131, 14.479791043027646],
            [39.886800227317821, 14.443745003597016],
            [39.903745082824713, 14.432500177541655],
            [39.945791223841155, 14.431773131258112],
            [40.018882257925526, 14.458891102677384],
            [40.116863353558841, 14.470136096370922],
            [40.156663312156212, 14.464718034143161],
            [40.179427220139672, 14.457954005899765],
            [40.228054160995583, 14.44350913684103],
            [40.271109147881106, 14.424718083335634],
            [40.301382235302498, 14.405836002361482],
            [40.322773187542083, 14.387782053423322],
            [40.349718156479383, 14.359164224245731],
            [40.385554145415853, 14.325273172127581],
            [40.433609272835525, 14.282636106935612],
            [40.46889121797048, 14.261391167449673],
            [40.552773281871083, 14.220273070755511],
            [40.5925001502724, 14.203053959375907],
            [40.61416334672802, 14.195835967255292],
            [40.646936085321244, 14.189027012010669],
            [40.693600152086248, 14.182500023989761],
            [40.748891208899437, 14.172782045420988],
            [40.776936218812381, 14.164717983991423],
            [40.802700177588889, 14.153054062775979],
            [40.909436171113583, 14.052218090913115],
            [40.929163315568275, 14.032218031690306],
            [41.008045071296721, 13.944718003092859],
            [41.047218231172593, 13.900000046612789],
            [41.072909267391282, 13.866527083826156],
            [41.1004091183203, 13.822918053139247],
            [41.115554043934168, 13.796945049696944],
            [41.132500240545568, 13.766109033656718],
            [41.178327122818899, 13.681664208775317],
            [41.218609208488118, 13.612364141869634],
            [41.236527202956239, 13.595418112896411],
            [41.428054193964357, 13.472218049832478],
            [41.528045102346908, 13.41124506847882],
            [41.5497181894481, 13.39778205556081],
            [41.566936127361288, 13.38666413151968],
            [41.599163371694345, 13.365273179280109],
            [41.615554183399666, 13.353609090426659],
            [41.756945155700151, 13.236391191675793],
            [41.781391144017704, 13.211945035720078],
            [41.798609249568841, 13.18777313563713],
            [41.816527076398899, 13.157773130622004],
            [41.843054123642702, 13.105827123737427],
            [41.843863312577497, 13.098536041420857],
            [41.914718222526375, 12.948745061010754],
            [41.926800233073408, 12.926664110499786],
            [41.954163291342155, 12.88527309903742],
            [41.974300143225292, 12.863335981985273],
            [41.993054148718528, 12.850135993189667],
            [42.017218169812367, 12.840553969090777],
            [42.048191146150913, 12.830273061903256],
            [42.076945097436493, 12.81971806648103],
            [42.097773288695436, 12.811945024730505],
            [42.119027112998793, 12.801245022382886],
            [42.153054119586642, 12.778473067772282],
            [42.194300124123885, 12.746109030778911],
            [42.214445190271903, 12.725827004332487],
            [42.262773232459864, 12.650553993207311],
            [42.292218187845066, 12.598191070457702],
            [42.306518217616571, 12.5747180534634],
            [42.326109239963358, 12.549444938938237],
            [42.344718238531158, 12.526664099510214],
            [42.358054181796632, 12.511664180821654],
            [42.399718108027315, 12.469718119729393],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "FIN", Country: "Finland" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.957336071273943, 69.051618200429587],
            [28.9133272238046, 69.043318104605774],
            [28.877782254547213, 69.036100112485158],
            [28.855554117816013, 69.029709078934061],
            [28.746391228039187, 68.997209087470694],
            [28.45180018765987, 68.916518181756018],
            [28.435554047603688, 68.902636073678011],
            [28.439863351676109, 68.882354047231601],
            [28.470827107921224, 68.87443616619403],
            [28.509163250943715, 68.872773196599198],
            [28.56500013729331, 68.87443616619403],
            [28.705827175146311, 68.878327045658679],
            [28.737782175263675, 68.875827059210607],
            [28.792773159942527, 68.857773277910624],
            [28.817354096901369, 68.847000185367264],
            [28.799718237294911, 68.81013607218442],
            [28.76166322330559, 68.754854067826543],
            [28.738609133833307, 68.734163172142289],
            [28.655000152338829, 68.679991099406053],
            [28.518054103304991, 68.583882185671015],
            [28.459854023167424, 68.534854086928121],
            [28.560000164397167, 68.385818148358183],
            [28.663054155484502, 68.239436087979144],
            [28.695000270784448, 68.195409135598695],
            [28.823327208758855, 68.17248211577872],
            [29.160000264700585, 68.116654114246799],
            [29.356945150298401, 68.082491153188684],
            [29.383609157840482, 68.062482209148541],
            [29.539445166837453, 67.938891214119323],
            [29.691663211137865, 67.815273229361949],
            [29.727500205902658, 67.803327173284785],
            [29.902500263097664, 67.740827176806491],
            [30.0286091818476, 67.694718159671467],
            [29.977773274222756, 67.572491235568961],
            [29.95971815181818, 67.538609068268144],
            [29.932782235336305, 67.513882118555372],
            [29.900000276649479, 67.49249116631573],
            [29.881663354659423, 67.483600146316562],
            [29.745554154840903, 67.416382144165524],
            [29.525273212547091, 67.293882137656809],
            [29.156945060984611, 67.030818117417297],
            [29.13416321572808, 67.013609064321585],
            [29.090136095709596, 66.97290905695904],
            [29.081527210572204, 66.951100183025957],
            [29.075136177021108, 66.903600105235924],
            [29.076109148344443, 66.877491147323653],
            [29.093818098146954, 66.851518143881449],
            [29.128745153500546, 66.820400160617496],
            [29.21193621330147, 66.770682231241196],
            [29.252918187887616, 66.750827178943794],
            [29.273327116348554, 66.738327079065229],
            [29.324445158835175, 66.704573154883477],
            [29.354863253181833, 66.677909147341481],
            [29.385545042203177, 66.628309067524057],
            [29.414163206657037, 66.599991142842995],
            [29.554445085526169, 66.470273144213465],
            [29.567927209183409, 66.428573175799173],
            [29.57715417586283, 66.403045083778622],
            [29.590273195473486, 66.382763224970276],
            [29.631945168331157, 66.329436048076488],
            [29.776527131179165, 66.209718162877536],
            [29.799163298958121, 66.192491172509008],
            [29.826109106085852, 66.175554028353204],
            [29.850000212411459, 66.163609145742541],
            [29.876391137547301, 66.1522181392953],
            [29.90374514336068, 66.13388222313371],
            [29.918891242440935, 66.112782122935087],
            [29.948327145370882, 66.049709139553855],
            [30.032218094089018, 65.922209160148881],
            [30.093891131997708, 65.838054013841955],
            [30.102500184773334, 65.818600119431736],
            [30.13492708604079, 65.708882180025014],
            [30.120200249758312, 65.668118134921812],
            [30.076663303438949, 65.675418102055787],
            [30.030273324908734, 65.690545090396753],
            [29.992500278143069, 65.685273208560872],
            [29.81888222914759, 65.65331804080553],
            [29.834163276869475, 65.588336162789957],
            [29.798054037888818, 65.537491202709489],
            [29.770273225564466, 65.526654072425671],
            [29.746109204470685, 65.514436107408798],
            [29.738327110264692, 65.481109157375968],
            [29.739718170919531, 65.45555407562712],
            [29.745554154840903, 65.414718113794478],
            [29.7486091909187, 65.346100166171183],
            [29.706109253663186, 65.319718125852788],
            [29.671109108113711, 65.302900172360452],
            [29.61305403490158, 65.271236024284192],
            [29.602045075602035, 65.2444360622723],
            [29.63305409412439, 65.225963185812319],
            [29.658891143096838, 65.223327077256343],
            [29.737218184471516, 65.226654022273962],
            [29.787500215933079, 65.226382113334139],
            [29.82069121149604, 65.223463199364048],
            [29.848327184533019, 65.218045137136571],
            [29.870418193327708, 65.202000162757045],
            [29.86944505436648, 65.119991118945208],
            [29.836663263317661, 65.106936137074896],
            [29.806945057887873, 65.09915404286896],
            [29.773891190261367, 65.094709119602626],
            [29.726391280109482, 65.092500152833665],
            [29.674163306001134, 65.081800150486046],
            [29.645973289162924, 65.071936159163542],
            [29.621182301709752, 65.052354021634173],
            [29.608127152201263, 65.005963204913641],
            [29.6408271358749, 64.920963162764266],
            [29.652773191952036, 64.904709143719245],
            [29.671945119138769, 64.887209070944536],
            [29.714445056394283, 64.852218145488749],
            [29.733609104592148, 64.837209174344608],
            [29.755000224469853, 64.822491222879634],
            [29.774436181607285, 64.809709155777384],
            [29.808891168172607, 64.794018065351096],
            [29.843054129230637, 64.792500102681842],
            [29.891663300451825, 64.793045094027875],
            [29.926391201785208, 64.794709069450917],
            [29.950273255655674, 64.795545080475947],
            [30.048054023802621, 64.795545080475947],
            [30.081109232533692, 64.792636057151853],
            [30.121109183341133, 64.784154074028706],
            [30.142773217987127, 64.772073236948117],
            [30.206873319786069, 64.663318042942919],
            [30.176454051972968, 64.631382153565042],
            [30.141663286365514, 64.619427045032396],
            [30.072500179758237, 64.604427126343737],
            [30.026945038786465, 64.597627055916817],
            [29.976454130297242, 64.578945134790999],
            [29.986945087979024, 64.55305410636187],
            [30.010527069714755, 64.512263239168547],
            [30.048882155838527, 64.502491113505116],
            [30.068609132655325, 64.494718071754605],
            [30.087909135322803, 64.480691124389281],
            [30.085691283736338, 64.459991176249517],
            [30.061527095004379, 64.405345023068335],
            [30.129163354125097, 64.364218041556782],
            [30.163191198903377, 64.352763165007062],
            [30.196945123085214, 64.34887312373256],
            [30.321663316456608, 64.329436160766662],
            [30.359445080401457, 64.319718182197775],
            [30.389718167822792, 64.309436101543767],
            [30.563536209028626, 64.24089107647832],
            [30.577354111727999, 64.223736002839175],
            [30.612773184799352, 64.094154126317719],
            [30.610273198351393, 64.070400147928282],
            [30.595273279662734, 64.046936015751484],
            [30.55124515381587, 64.015273208779817],
            [30.528609153675092, 64.000000207684863],
            [30.480273232498206, 63.973327147687527],
            [30.398054138192236, 63.930545075570066],
            [30.263473294602903, 63.822627066056455],
            [30.210554148756927, 63.800827076940706],
            [30.135000176236616, 63.780000226786356],
            [30.11471814979032, 63.775273168658416],
            [30.063054110129173, 63.76666327769253],
            [30.033054105113962, 63.760827126133094],
            [30.008891257486511, 63.754582105335899],
            [29.996709167015268, 63.748027121758284],
            [29.998954175968123, 63.735336082124846],
            [30.206391192714108, 63.612782096159307],
            [30.245554126668281, 63.593263158180335],
            [30.308891140000043, 63.57249112567284],
            [30.35750014358328, 63.560000078249786],
            [30.395418197274267, 63.548045137355402],
            [30.4572181371976, 63.503609148098676],
            [30.478400212409525, 63.480973147957897],
            [30.648891140959478, 63.439718090965428],
            [30.714718082455676, 63.424436037415248],
            [30.912218185321336, 63.353609123022906],
            [31.186391244784403, 63.238327108634977],
            [31.219718194817204, 63.223054107540051],
            [31.242636162181697, 63.204509146712425],
            [31.383054163158675, 63.069163202999263],
            [31.519582290499187, 62.982636145725039],
            [31.581963263952133, 62.907900079319134],
            [31.521663349425438, 62.853336068789204],
            [31.501391213624657, 62.835545143973533],
            [31.474027149527643, 62.81263606142646],
            [31.430000197147109, 62.758891130477238],
            [31.321945059697015, 62.615273254135232],
            [31.310554053249774, 62.598053975117651],
            [31.294163241544567, 62.564163258275684],
            [31.280282306933003, 62.53610919590723],
            [31.25819113050008, 62.508254119920466],
            [31.08277331924981, 62.413053972130413],
            [30.96416335401554, 62.339163304480834],
            [30.834445187748173, 62.271382206072886],
            [30.79250013248415, 62.253882133298262],
            [30.704163254671442, 62.210545011551162],
            [30.632500161616036, 62.166109189932612],
            [30.396109201373889, 62.016663208658244],
            [30.300554163802389, 61.956109154826422],
            [30.203891206266036, 61.890545069814621],
            [30.155100147745515, 61.856345060744573],
            [30.142173241356119, 61.846953976400627],
            [29.998473222362492, 61.734436146049362],
            [29.801945084991729, 61.603745008458603],
            [29.683327073130584, 61.533882180572263],
            [29.652500277183748, 61.51777316845228],
            [29.497500111573657, 61.436663167577606],
            [29.355273295886519, 61.359718134402371],
            [29.334163305041983, 61.345273265343636],
            [29.301936060708812, 61.31832712293992],
            [29.285073180215164, 61.302863182090405],
            [29.274300255309782, 61.292082210557837],
            [29.24971814488444, 61.27583607050174],
            [29.213609241179739, 61.25332714001344],
            [29.165827196166475, 61.232082200527501],
            [29.0719451864733, 61.199436028672508],
            [28.970836132204255, 61.162773248804498],
            [28.81666326043964, 61.10513609728595],
            [28.720000135265337, 61.045273215191926],
            [28.67416336234632, 61.001663178676594],
            [28.645554082709879, 60.971109129860068],
            [28.619645117007821, 60.960000090636427],
            [28.577773319577972, 60.961663227868982],
            [28.529509147492575, 60.954582196046758],
            [28.46639123711023, 60.925282247586765],
            [28.388882269487823, 60.886663299150271],
            [28.341945120316808, 60.8582540117241],
            [28.329445188076448, 60.841518033245123],
            [28.298609172036123, 60.820545002698807],
            [28.269445178046027, 60.804163243448969],
            [28.246391256211695, 60.793327118993474],
            [28.093891244687683, 60.712218123947096],
            [27.884718168151238, 60.589163235446634],
            [27.807827388045922, 60.546399787706136],
            [27.790989595000127, 60.531278754000184],
            [27.774858608000017, 60.513681313000163],
            [27.766059887000125, 60.47408707200016],
            [27.754328260000051, 60.444758005000224],
            [27.736730820000133, 60.406630216999972],
            [27.700069486000046, 60.37143533600009],
            [27.651676524000067, 60.337706909000232],
            [27.606216470000135, 60.315710108000175],
            [27.553424148000175, 60.281981681000033],
            [27.511944444000022, 60.259722221000189],
            [27.463888889, 60.228333332000076],
            [27.188333333000145, 60.17666666600006],
            [26.965000000000117, 60.17666666600006],
            [26.915000000000134, 60.173333332000041],
            [26.798333333000159, 60.146666666000144],
            [26.640000000000157, 60.113333332000266],
            [26.543333333000135, 60.106666666000123],
            [26.346666667000079, 59.999999999000138],
            [26.335783333000023, 59.99463333300001],
            [26.309019751500159, 59.992547626500155],
            [26.242097562000112, 59.987332329000154],
            [26.211099761500179, 59.984916615000131],
            [26.190758376000076, 59.980588661000141],
            [26.132766667000027, 59.968249999],
            [26.039141528000158, 59.907709085000178],
            [25.967783333000114, 59.861566665999973],
            [25.459433333000163, 59.87656666600013],
            [25.372905504000158, 59.88004767000011],
            [25.241512505666805, 59.885333595666808],
            [25.169433333000114, 59.888233333000059],
            [25.128155014000043, 59.884419851000118],
            [25.019995277000163, 59.874427555000054],
            [24.95276666700002, 59.868216666000066],
            [24.911678908, 59.859895095000056],
            [24.8211, 59.84154999899998],
            [24.406116667000134, 59.736549998999962],
            [23.906116667000049, 59.618199999000169],
            [23.59981326700003, 59.549982388],
            [23.49445, 59.526516666000092],
            [23.384085259000074, 59.527065743999955],
            [23.232303721000108, 59.527820876000177],
            [23.15945, 59.528183333000058],
            [22.987487504000171, 59.480453018000119],
            [22.751133333000041, 59.414849999000026],
            [22.36483092500012, 59.391792574000149],
            [22.164466667000113, 59.37983333300015],
            [21.776133333000189, 59.311483333000055],
            [21.186133363000152, 59.191483339000072],
            [20.481606388111175, 58.844569860222336],
            [20.471266667000037, 58.862933332000111],
            [20.419455439000217, 58.957791118000202],
            [20.241062315000107, 59.284399413666748],
            [20.153333333, 59.445016666000058],
            [19.907773738000117, 59.616973693000219],
            [19.658283333000043, 59.791683332000119],
            [19.217784452000132, 60.098072932000079],
            [19.083200193500062, 60.191683197500097],
            [19.1027, 60.235249999000118],
            [19.134154253000162, 60.31237794500008],
            [19.145568873000116, 60.340367360000087],
            [19.158333330000119, 60.371666800000057],
            [19.158333330000119, 60.374999999000167],
            [19.18564895700024, 60.483074865000148],
            [19.235000000000184, 60.678333329000097],
            [19.524999993000108, 62.699999949500111],
            [19.64139705499997, 62.784249296000112],
            [20.400000000000205, 63.333333328999956],
            [20.569293744000078, 63.419882379000214],
            [20.63078660700009, 63.451319742000138],
            [20.696666670000013, 63.48499999900001],
            [20.939999920000105, 63.521666657000068],
            [21.500000000000227, 63.666666669000094],
            [23.005302947000189, 64.649590698000125],
            [24.148271610500046, 65.395919778000078],
            [24.142111175750159, 65.459162284250098],
            [24.138560621000124, 65.495611329000084],
            [24.136933330000033, 65.5123166690001],
            [24.137163477000172, 65.513643763000132],
            [24.137987969000164, 65.518398030000142],
            [24.1400000000001, 65.529999999000054],
            [24.14062888050006, 65.544295994000109],
            [24.141231103000109, 65.557985996000056],
            [24.142557144000136, 65.572572450000095],
            [24.14322607400004, 65.579930675000057],
            [24.143601600000125, 65.584061463000253],
            [24.144391765000137, 65.60460577000002],
            [24.146477658000151, 65.623378805000172],
            [24.147552428000068, 65.633051733999977],
            [24.148332805999985, 65.639684946000116],
            [24.150713091000114, 65.659917366000101],
            [24.151924083000125, 65.67566027000008],
            [24.152293422000128, 65.680461673000067],
            [24.152772832000096, 65.68717341300011],
            [24.153873753000056, 65.702586312000037],
            [24.156261399000073, 65.731238066000159],
            [24.157034416000101, 65.740514263],
            [24.157034416000101, 65.758688073000215],
            [24.157283567000093, 65.760044565000086],
            [24.157464510000153, 65.761029698000058],
            [24.15762457600016, 65.761901169000154],
            [24.164145907000091, 65.797406190000103],
            [24.166661271000095, 65.812006198000105],
            [24.16681749900016, 65.812913169000083],
            [24.167009158802216, 65.814027120842226],
            [24.164091250462832, 65.832636119252285],
            [24.031391138313268, 66.020263178340159],
            [23.967782216041627, 66.072491152448364],
            [23.948609115388535, 66.101382231670343],
            [23.935554133518139, 66.12846315507737],
            [23.919027199704828, 66.144163297959139],
            [23.886391253771649, 66.156663230199726],
            [23.856109113894831, 66.16027314826944],
            [23.814300180738769, 66.168182144489762],
            [23.754718092402044, 66.185000097982183],
            [23.726109148041786, 66.198254066234441],
            [23.684718136579335, 66.263318086901265],
            [23.662500225770145, 66.312209057708046],
            [23.63971821287555, 66.433318171200014],
            [23.656036102023194, 66.457209109887671],
            [23.725273304654735, 66.500827025392013],
            [23.807218143088136, 66.537763222942303],
            [23.82694511990465, 66.543882179915599],
            [23.87416323047097, 66.555345103092307],
            [23.889718198789353, 66.575827121748873],
            [23.902773180659636, 66.680263288673913],
            [23.900836122830299, 66.712491203559225],
            [23.892082230767613, 66.744782150356841],
            [23.94250021669896, 66.787909053971632],
            [24.000763328748377, 66.802282173939105],
            [23.94069123435051, 66.879773204288725],
            [23.866945070712177, 66.93110917625846],
            [23.787773301133143, 66.981382155264598],
            [23.751109180160825, 66.995973204715128],
            [23.731109120937845, 67.008327124201898],
            [23.681109224337916, 67.047482179166977],
            [23.573327169294316, 67.157009178818768],
            [23.590554159662673, 67.240682198053676],
            [23.610000175084053, 67.261373261376278],
            [23.630273316712959, 67.26749121252108],
            [23.683054161156264, 67.275545048028548],
            [23.708054025637239, 67.278045034476818],
            [23.743191131484991, 67.286318140572106],
            [23.780345090880189, 67.326309206562215],
            [23.765136127525977, 67.41687315605499],
            [23.735000168040955, 67.426100122734411],
            [23.581945106887105, 67.45000011387755],
            [23.542909242585409, 67.452627169978015],
            [23.519718192814992, 67.443318060647343],
            [23.493745189372703, 67.437218046775357],
            [23.469300206883474, 67.442909191409328],
            [23.44832717633733, 67.452500100325622],
            [23.430827103562507, 67.479782189409676],
            [23.471945032618862, 67.556382223449063],
            [23.507773310204328, 67.665545113225889],
            [23.491391215678249, 67.712491147214322],
            [23.470554139602001, 67.819854107098294],
            [23.488818139034123, 67.870963264767525],
            [23.511109140039338, 67.883609209761616],
            [23.545554068320911, 67.890000075674536],
            [23.602082294046284, 67.896382224408299],
            [23.655691270525523, 67.921173211861614],
            [23.659791194656123, 67.945891109118961],
            [23.638336204676108, 67.958054088850801],
            [23.531663243063662, 67.992491138143222],
            [23.394445117451795, 68.042500087198576],
            [23.372218154187124, 68.0601270619877],
            [23.353336073213143, 68.083600078982002],
            [23.055418087815895, 68.291100127640121],
            [22.96944507434327, 68.317763296991799],
            [22.936391206716479, 68.322218110903876],
            [22.905000141046429, 68.329991152654387],
            [22.863609129584148, 68.357500223676922],
            [22.826663209026123, 68.385963155283591],
            [22.666800198456258, 68.421654137294567],
            [22.581663196008662, 68.427218044637968],
            [22.500000157160798, 68.44012718139264],
            [22.430554077501398, 68.451663194765288],
            [22.37194512812593, 68.463609083204275],
            [22.159163306986216, 68.464991091403519],
            [22.048609188916373, 68.481518192855006],
            [22.028745084163376, 68.502345043009527],
            [22.000827143902825, 68.521518143662618],
            [21.95944518489577, 68.543882234863602],
            [21.933891276613565, 68.555263183027037],
            [21.889300222147824, 68.571245125494428],
            [21.864718111722198, 68.573609157472603],
            [21.81694511916433, 68.570400229651895],
            [21.765418207439666, 68.575682169771667],
            [21.726945104118812, 68.587354137614042],
            [21.706591160942935, 68.614991116479459],
            [21.642773194005542, 68.642491135046598],
            [21.623054096177952, 68.649427159943698],
            [21.593191219099111, 68.65734504098144],
            [21.550554153907228, 68.661654009777791],
            [21.494163223756118, 68.669854025677338],
            [21.45389119637079, 68.686727132092884],
            [21.420554020416006, 68.724154006256285],
            [21.212218128370864, 68.817909113934931],
            [21.081391203948272, 68.866654071988009],
            [21.05889115827739, 68.873318187945202],
            [21.024718138935555, 68.877491202271827],
            [20.990836139272915, 68.879436139090004],
            [20.952082242194933, 68.880682192819719],
            [20.900827071771971, 68.89207319926679],
            [20.885900243279281, 68.918191209634571],
            [20.930973257178891, 68.949227050247075],
            [20.930691122317285, 68.970691092682486],
            [20.908882248384202, 68.985544998617328],
            [20.875554124884815, 69.001245141499098],
            [20.846382251905709, 69.011936091391235],
            [20.791663343804828, 69.023327097838475],
            [20.744163266014993, 69.031100139588816],
            [20.650063326838591, 69.043791179222424],
            [20.604163354369348, 69.053054020447391],
            [20.580927209959526, 69.06030017576272],
            [20.609445126851227, 69.069444999790718],
            [20.675000159407602, 69.087218154971723],
            [20.697500205078484, 69.09277317749779],
            [20.731109122334828, 69.09610012251558],
            [20.773891194452233, 69.093600136067309],
            [20.829027186056294, 69.084991083291854],
            [20.935554134915151, 69.05860921061155],
            [20.980554058618822, 69.045273267345934],
            [21.033054109304999, 69.033327043630791],
            [21.063473209480037, 69.036800169040291],
            [21.079445093663423, 69.051518120505236],
            [21.109163299093098, 69.097418092974394],
            [21.072636138419, 69.122082178413265],
            [21.053609218157533, 69.140544996589355],
            [21.042218211710178, 69.16082702303585],
            [21.030273161461622, 69.210545120050156],
            [21.047082230136709, 69.223745108845762],
            [21.320827141984353, 69.326109101661885],
            [21.681945180414942, 69.284718090199348],
            [21.994600228016452, 69.078373235440168],
            [22.041382144340247, 69.052218177060453],
            [22.198991261139923, 68.937700089329908],
            [22.278891250468973, 68.881653990676469],
            [22.347636100106683, 68.843609202609102],
            [22.36680014830435, 68.79540906826405],
            [22.398327168444411, 68.711109082669893],
            [22.500000157160798, 68.728991202592411],
            [22.550000221398619, 68.737782142667314],
            [22.802773276166789, 68.691373221035789],
            [22.863609129584148, 68.693054127903437],
            [23.068191270100669, 68.693600125077936],
            [23.099163240610721, 68.67470915928628],
            [23.139445158641848, 68.649154077537403],
            [23.16471810552892, 68.637500214605851],
            [23.196391138422769, 68.629854074869726],
            [23.260554104495839, 68.636382236357193],
            [23.286663230045917, 68.644427019409548],
            [23.333054046766648, 68.659436158191497],
            [23.407773181728203, 68.680827110431082],
            [23.4672181421289, 68.691663234886576],
            [23.517500173590435, 68.698609150429419],
            [23.583054032680252, 68.705273266386698],
            [23.671945121932652, 68.711109082669893],
            [23.705063194937793, 68.716863259215884],
            [23.740836151962043, 68.742491095884347],
            [23.762500186607895, 68.759427066573934],
            [23.79610910386441, 68.789291117119078],
            [23.824509171196922, 68.826309122044719],
            [23.856391248756523, 68.832491110929979],
            [23.899436177358041, 68.833882171584818],
            [23.976391268817082, 68.832491110929979],
            [24.036945155010926, 68.825273118809449],
            [24.060827208881335, 68.818736072504649],
            [24.090418177020268, 68.802418183357005],
            [24.120554136505461, 68.783600140123283],
            [24.206109228284561, 68.749027136360851],
            [24.354100111163632, 68.710200149087015],
            [24.416945106777689, 68.701663180678892],
            [24.450273230276991, 68.698882232835686],
            [24.513336155374304, 68.695827029119712],
            [24.578891187930623, 68.691373221035789],
            [24.628891252168529, 68.687209091526668],
            [24.690282155215698, 68.676373134709323],
            [24.754445121288853, 68.660263284399022],
            [24.773327202263005, 68.652773215700606],
            [24.804718100294991, 68.636653977658838],
            [24.831663236870298, 68.617209135703931],
            [24.854473245321401, 68.593182242546376],
            [24.934918225996455, 68.580809212320403],
            [25.076382288492709, 68.621382150030556],
            [25.105073207866241, 68.637909083843866],
            [25.105000117670301, 68.669782108947871],
            [25.154718214684607, 68.767773262865106],
            [25.169854087843163, 68.789027087168463],
            [25.188327131941151, 68.803327116939883],
            [25.214445142308733, 68.817491192241405],
            [25.234163234308028, 68.825273118809449],
            [25.363891123583272, 68.87388212239243],
            [25.3847181413756, 68.880554117338832],
            [25.406945104640386, 68.886382222270996],
            [25.437773241691502, 68.891109112760873],
            [25.481800194071951, 68.891518149637065],
            [25.508054158909545, 68.888318106633861],
            [25.562773234648404, 68.879436139090004],
            [25.585554074076327, 68.878873210471269],
            [25.617773271782568, 68.8827632517458],
            [25.639863274748961, 68.894300103308765],
            [25.652773249693951, 68.906391166311138],
            [25.66139118728691, 68.911653995691594],
            [25.692218150871554, 68.933882132422852],
            [25.761109180901144, 68.989163298590213],
            [25.76832717302176, 69.045273267345934],
            [25.732218101679166, 69.109163151012879],
            [25.708054080585242, 69.209018104925335],
            [25.713336188343192, 69.255263244168319],
            [25.739436093799895, 69.318327175094041],
            [25.752363335465361, 69.339154025248192],
            [25.770554077063736, 69.353882202635205],
            [25.81166328894048, 69.368609206555575],
            [25.833745077641851, 69.385273268305255],
            [25.874163285418973, 69.486373270118932],
            [25.953327176009083, 69.58416325835951],
            [25.970691126675831, 69.618809184679534],
            [25.969445072946286, 69.642491246339674],
            [25.944509246205769, 69.665200169038243],
            [25.959300120228562, 69.685682187695008],
            [25.983327181024208, 69.704300071080283],
            [26.004445050857555, 69.711936152532516],
            [26.10055413223057, 69.726382195057752],
            [26.134445184348692, 69.73498219537781],
            [26.155827084132767, 69.742491207177537],
            [26.174163335570682, 69.75166318857201],
            [26.373745167914876, 69.852209146584173],
            [26.398609245564018, 69.868745132852879],
            [26.420136152273585, 69.895963184196304],
            [26.442918165168066, 69.920827094207468],
            [26.476800164830706, 69.936382230163915],
            [26.609163324471723, 69.950963221330582],
            [26.80658229051437, 69.952054042212907],
            [26.865209177162768, 69.939373228501367],
            [26.894300248594988, 69.925418197865596],
            [27.0458271211576, 69.907773118165565],
            [27.074854154849476, 69.909573215696511],
            [27.206109226887492, 69.933318141630522],
            [27.286245082972499, 69.948609079998036],
            [27.306036097529415, 69.964918084328175],
            [27.298263223417024, 69.986244998827473],
            [27.421391202113426, 70.01666326081245],
            [27.570409203410662, 70.066382196017074],
            [27.599445121919842, 70.072491094706365],
            [27.873054079297702, 70.088045057196254],
            [27.910691171593527, 70.08860915928166],
            [27.937745105272342, 70.083036031844799],
            [27.971145145501083, 70.045509077756932],
            [27.976245198321493, 70.011518113352722],
            [27.991391129763741, 69.997909087681009],
            [28.102082208131748, 69.934573247815464],
            [28.159163304192163, 69.913882184493033],
            [28.197218150543307, 69.906391109966123],
            [28.2266632735668, 69.902218095639498],
            [28.276109126365242, 69.896654020658119],
            [28.316800248910539, 69.889163281407193],
            [28.341945120316808, 69.877209178703211],
            [28.363818199628355, 69.841036069620188],
            [28.379718167082473, 69.827500134144245],
            [28.405000166425026, 69.821654091939067],
            [28.69471813592267, 69.768600165089637],
            [28.744163318168859, 69.75971819754615],
            [28.909718143925289, 69.733891206857479],
            [28.946109182491369, 69.728609099099529],
            [29.00082708476387, 69.721518176631307],
            [29.102782208341949, 69.705973266596729],
            [29.127500273237303, 69.685818142164905],
            [29.180000156285217, 69.630682150560929],
            [29.29847316122121, 69.485336093417075],
            [29.285554133820796, 69.463609194497266],
            [29.269163322115475, 69.451936053188419],
            [29.122218165479438, 69.370818173324537],
            [29.064163259905371, 69.343891141660222],
            [29.033336128682635, 69.330554024928219],
            [28.997082218052839, 69.315554106239674],
            [28.926800127368296, 69.286382233260568],
            [28.902773234210741, 69.274991226813327],
            [28.825418158331132, 69.236163233711167],
            [28.796663201217058, 69.188327041602903],
            [28.782918221075334, 69.155136046039885],
            [28.785063317742043, 69.124082100516475],
            [28.802218223742926, 69.105545018678001],
            [28.82750022308548, 69.093600136067309],
            [28.857773310506985, 69.082218182075749],
            [28.890554095727424, 69.071382225258034],
            [28.957336071273943, 69.051618200429587],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "FJI", Country: "Fiji" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-178.630648792999921, -23.610233408],
              [-179.071111110999936, -24.368888888999948],
              [-179.16083383299997, -24.358056095],
              [-179.476388832999959, -24.318889095000031],
              [-179.786388833999951, -24.254445095],
              [-179.999999833999965, -24.192501965499957],
              [-179.999999833999965, -23.500000093999972],
              [-179.999999833999965, -23.000000093999972],
              [-179.999999833999965, -22.000000092999983],
              [-179.999999833999965, -21.000000091999937],
              [-179.999999833999965, -20.000000090999947],
              [-179.999999833999965, -19.000000089999958],
              [-179.999999833999965, -18.000000088999968],
              [-179.999999833999965, -17.000000087999922],
              [-179.999999916999968, -16.965727455484284],
              [-179.999999916999968, -16.78736384955721],
              [-179.999999833999965, -16.000000086999933],
              [-179.999999833999965, -15.000000086999989],
              [-179.999999833999965, -14.000000085999886],
              [-179.999999630333292, -13.881135718166604],
              [-179.239568332999966, -14.804961575],
              [-178.516512777999907, -15.296350583],
              [-177.37623, -15.93662852],
              [-177.375554327999936, -15.971420117999912],
              [-177.375277777999969, -15.985555556],
              [-176.89666666699992, -16.8275],
              [-176.891772012999922, -16.832786226],
              [-176.889722221999961, -16.835],
              [-176.273333333000011, -17.474444444000028],
              [-176.275054563999873, -17.554158944999983],
              [-176.275088662999934, -17.555738140000017],
              [-176.275784561999956, -17.587966954999956],
              [-176.275819447999908, -17.589582610999969],
              [-176.277777777999944, -17.680277778],
              [-176.357499999999874, -17.984722221999959],
              [-176.427122832999856, -18.436127575],
              [-176.433320432999977, -18.476310227],
              [-176.450555555999955, -18.588055555999972],
              [-176.579444443999904, -18.856388889000016],
              [-176.592298252999939, -18.890231828999958],
              [-176.601388888999907, -18.914166666999961],
              [-176.624004244999981, -18.998438624000016],
              [-176.62777777799991, -19.0125],
              [-176.634684429999851, -19.052282314000024],
              [-176.669444443999964, -19.2525],
              [-176.666944443999967, -19.341944443999978],
              [-176.883333332999911, -20.435277777999943],
              [-177.024166666999889, -20.91],
              [-177.031944443999919, -20.961666666999974],
              [-177.044847128999919, -20.978490056],
              [-177.07892073099984, -21.022917514999961],
              [-177.1325, -21.092777778000027],
              [-177.189030048999911, -21.186714674999962],
              [-177.207804656999912, -21.217912743999989],
              [-177.300833333000014, -21.3725],
              [-177.767388734999969, -22.135250324999959],
              [-177.903333332999921, -22.3575],
              [-178.51936265, -23.418553348999978],
              [-178.630648792999921, -23.610233408],
            ],
          ],
          [
            [
              [179.999999918138428, -16.965727455484284],
              [179.999999501000275, -24.192501645],
              [179.903333333000091, -24.164722222000023],
              [179.610833333000187, -24.050555555999949],
              [179.331111111000041, -23.913055556000018],
              [179.066111111000282, -23.753333333],
              [178.817777778000277, -23.572222222],
              [178.588055556000199, -23.371944443999951],
              [178.378888889000109, -23.153333332999978],
              [178.191944444000342, -22.918888888999959],
              [178.062777626333514, -22.72305596599989],
              [178.031110500000068, -22.847223093999958],
              [177.972711347000086, -22.986330176000024],
              [177.916944444000023, -23.119166667],
              [177.837403169500135, -23.268266945499875],
              [177.777499499000214, -23.380556093999985],
              [177.741602975000205, -23.43494682499994],
              [177.613055556000177, -23.629722221999941],
              [177.309949633500196, -23.97470879499997],
              [177.214999499000214, -24.082778095000037],
              [177.032310278000068, -24.241915932999973],
              [176.984444444000019, -24.283611111000027],
              [176.7347222220003, -24.46444444399998],
              [176.468333333000146, -24.624444443999948],
              [176.186944444000233, -24.762222221999934],
              [175.893055556000263, -24.876388888999941],
              [175.713013011000271, -24.929497328999958],
              [175.588888497000312, -24.96611109600002],
              [175.554269304000059, -24.97329398100004],
              [175.276944444000151, -25.030833332999975],
              [174.95972222200021, -25.069722221999939],
              [174.6400000000001, -25.082777777999979],
              [174.320277778000218, -25.069722221999939],
              [174.275709444000199, -25.073020149999877],
              [172.764876111000149, -20.022462973000017],
              [173.560000000000286, -18.543333332999964],
              [173.581944444000214, -18.313611110999943],
              [173.495277778000172, -18.033888888999968],
              [173.458309235000343, -17.859221004],
              [173.434722495000301, -17.747778088999951],
              [173.412256553500129, -17.557276210999987],
              [173.400555556000086, -17.458055556000033],
              [173.392777778000323, -17.166388888999961],
              [173.411388889000023, -16.875],
              [173.456111111000041, -16.586388888999977],
              [173.5266666670002, -16.302777778],
              [173.536943827000187, -16.272991937000029],
              [173.622222496000063, -16.025834086999964],
              [173.741944496000201, -15.758056086999943],
              [173.799372701000038, -15.654907715000036],
              [173.885000000000105, -15.501111110999943],
              [173.989343302000066, -15.347138976999929],
              [174.050277496000263, -15.257223086999886],
              [174.236388496000131, -15.028056086999982],
              [174.366728386000204, -14.896051441999987],
              [174.453888889000154, -14.807777777999945],
              [174.338888889000259, -14.682222222],
              [174.15472222200026, -14.451944443999935],
              [173.992500000000291, -14.206666666999979],
              [173.853055556000186, -13.948333332999766],
              [173.759878285000013, -13.731813489999922],
              [173.7372224960003, -13.679167085000017],
              [173.716346921000081, -13.615327824000019],
              [173.646388889000036, -13.401388888999861],
              [173.580555556000263, -13.116666666999947],
              [173.540277778000245, -12.827222221999989],
              [173.525833333000151, -12.535833332999857],
              [173.57472222200056, -11.954444443999819],
              [173.6375, -11.669166666999956],
              [173.725000000000136, -11.390277777999813],
              [173.836388684000326, -11.119722720000027],
              [173.847860227000297, -11.097607374],
              [173.971111111000255, -10.859999999999843],
              [174.127777778000222, -10.612499999999784],
              [174.188020938000136, -10.53352498199996],
              [174.305555496000125, -10.379445081999961],
              [174.446674176000329, -10.224213960999862],
              [174.502777778000222, -10.162499999999852],
              [174.561943210000322, -10.107686090000016],
              [174.718055497000023, -9.963056081999866],
              [174.949999497000135, -9.78305608199986],
              [175.864444498000353, -9.819167081999865],
              [175.94444449800028, -9.821945081999957],
              [176.086666498000341, -9.826667081999858],
              [176.229999498000097, -9.831111081999836],
              [177.100833499000032, -10.088056082000037],
              [177.186110499000165, -10.114723081999955],
              [177.291666499000257, -10.268056081999987],
              [177.316944499000101, -10.305000082],
              [178.246388500000194, -11.563889082999879],
              [178.308055500000137, -11.645834082999954],
              [178.56333350000034, -11.98277808399996],
              [178.668888500000207, -12.12083408399991],
              [178.734444500000222, -12.206945083999983],
              [179.044444501000271, -12.609445083999958],
              [179.530153501000086, -13.234684084999969],
              [179.494320501000175, -13.328017084999942],
              [179.999999501000275, -13.881136084999923],
              [179.999999918138428, -16.154726879946054],
              [179.999999918138428, -16.172746181132595],
              [179.999999918138428, -16.787391011969191],
              [179.999999918138428, -16.965727455484284],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "FLK", Country: "Falkland Islands" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-59.402477217999945, -47.86349766099994],
            [-59.385427554999922, -47.866090692999933],
            [-59.376308554999923, -47.865312692999964],
            [-59.340722765999942, -47.862259839999979],
            [-59.278964837999922, -47.856962108999937],
            [-58.414079683999887, -47.880961712999976],
            [-57.565145706999942, -47.999196834999964],
            [-57.447389295999983, -48.030038947999913],
            [-57.396214635999968, -48.043393954999942],
            [-57.384149522999905, -48.045007000999931],
            [-57.372793095999896, -48.0451797099999],
            [-57.359863552999968, -48.045376692999923],
            [-57.230511552999928, -48.062558692999971],
            [-56.505796552999925, -48.155994692999911],
            [-55.687292552000031, -48.358094692999941],
            [-55.269939787999874, -48.516070343999843],
            [-54.925379698999961, -48.646492768999849],
            [-54.240459839999914, -49.013737084999946],
            [-54.187517955999908, -49.053455629000041],
            [-54.177509135999912, -49.058737941999937],
            [-54.159134549999976, -49.068435693999959],
            [-54.143501802999879, -49.08011976399996],
            [-54.086839949999899, -49.122470128999851],
            [-53.814584097999898, -49.323881291999953],
            [-53.677010549999977, -49.425656693999883],
            [-53.643099541999902, -49.450506698999959],
            [-53.587756549999881, -49.49098569399996],
            [-53.585325928999907, -49.492760607999912],
            [-53.569255901999952, -49.504496041999971],
            [-53.566372005999881, -49.507529829999896],
            [-53.559669339999971, -49.512421096999958],
            [-53.558132818999923, -49.513574530999904],
            [-53.551436039999885, -49.52061490799997],
            [-53.545375694999905, -49.526984024999877],
            [-53.509695802499834, -49.55298050749991],
            [-53.496568035999928, -49.566747427999928],
            [-53.461157802999878, -49.592491586999984],
            [-53.455811992999884, -49.59808578399992],
            [-53.441682549999939, -49.608344693999925],
            [-53.277419774, -49.777940123999883],
            [-52.963986760000012, -50.101548601999923],
            [-52.617420899999956, -50.63909353199989],
            [-52.582589993999932, -50.738243909],
            [-52.568990548999921, -50.776956694999967],
            [-52.564345073999903, -50.784028038999907],
            [-52.560319879999895, -50.790155529999936],
            [-52.505097688999967, -50.946055053999942],
            [-52.357466952999971, -51.356904292999957],
            [-52.337658949999877, -51.606383877000042],
            [-52.311437548999947, -51.936645695999971],
            [-52.429565548999904, -52.512046697000017],
            [-52.714454548999981, -53.065258696999877],
            [-53.12690942099988, -53.537162403999957],
            [-53.154655726499925, -53.56890788949994],
            [-53.163102036999931, -53.578454787],
            [-53.182966057999863, -53.593662126499908],
            [-53.202394547999916, -53.615492251999967],
            [-53.211415781999904, -53.622387744999969],
            [-53.220137545999876, -53.632217700999888],
            [-53.824488549999955, -54.087986697999952],
            [-54.000173717999928, -54.179425804999937],
            [-54.026168324999901, -54.192954558499935],
            [-54.029210762666594, -54.194531944999937],
            [-54.134142908999934, -54.379607723999982],
            [-54.285445525999876, -54.540636298999971],
            [-54.612322550999977, -54.888524698999959],
            [-54.980478974999983, -55.148765047999973],
            [-55.248800734999918, -55.338434340999868],
            [-55.691331552999941, -55.551961641999981],
            [-56.026290551999921, -55.713583699999958],
            [-56.920354552999981, -56.000394699999958],
            [-57.900068553999972, -56.1881867],
            [-58.266039939999928, -56.217695366999976],
            [-58.577958372333228, -56.242845550333243],
            [-58.579838885999834, -56.245473435999941],
            [-59.505580912999903, -55.513047114999949],
            [-59.983315555999923, -55.117779574999929],
            [-60.397850252999888, -54.774802332999968],
            [-60.937502805999884, -54.299833742999965],
            [-61.106445677999886, -54.151140742999985],
            [-61.517477951999922, -53.789375718000031],
            [-61.611876053999936, -53.74765902099989],
            [-61.680070788999842, -53.717522194999958],
            [-61.768215468999983, -53.677890671999933],
            [-61.997112947999767, -53.574974014999896],
            [-62.033754146999854, -53.55860086499996],
            [-62.036858238999855, -53.55721379899984],
            [-62.041876896999895, -53.555030926999962],
            [-62.057689118999917, -53.548153380999963],
            [-63.012259829999834, -53.160262242999977],
            [-63.079175129999953, -53.129010924999989],
            [-63.108788518999859, -53.115180642999974],
            [-63.120517245999906, -53.109845486999973],
            [-63.172670794999931, -53.086121912999943],
            [-63.202128301999949, -53.07310545699994],
            [-63.216052739999895, -53.066952633999961],
            [-63.220977238999865, -53.064776633999941],
            [-63.32571674299993, -53.018867410999974],
            [-63.489186834999941, -52.947215506999953],
            [-63.492637669999908, -52.945935047999967],
            [-63.508785207999836, -52.93994338199991],
            [-64.132291489999886, -52.70996456149993],
            [-64.520120804499925, -52.561567572499996],
            [-64.577494532999935, -52.531204148999961],
            [-64.689222567999877, -52.472075237999945],
            [-64.713571431, -52.444332893999956],
            [-64.725858247999838, -52.430333673999939],
            [-64.763945686999932, -52.386938020999821],
            [-64.902811769999971, -51.673179043],
            [-64.911899903999824, -51.628037804999963],
            [-64.925980979999849, -51.565110118999939],
            [-64.935658712999896, -51.525685008999915],
            [-64.937773529999788, -51.517069676999938],
            [-64.946723078999923, -51.480611038999939],
            [-65.003932420999831, -51.365962804999924],
            [-64.607716539499904, -50.403875676499979],
            [-64.570665135499894, -50.316944510249982],
            [-64.170390066333312, -49.99772298933334],
            [-63.206292382999891, -49.228995035999901],
            [-63.01324611199982, -49.099761898999986],
            [-62.984687137999913, -49.080643343999952],
            [-62.28063334266659, -48.645667025333239],
            [-61.575090596999985, -48.209770807999973],
            [-61.527924189999908, -48.179587679999884],
            [-61.478960127999926, -48.14775372899993],
            [-61.219604020999896, -47.975231900999916],
            [-61.097703612999965, -47.894144621999942],
            [-60.775937981999903, -47.680108424999901],
            [-60.511241555999902, -47.691519691999929],
            [-60.393455555999878, -47.710590691999926],
            [-60.37733348200004, -47.711248394999927],
            [-60.354921208999883, -47.712162860999911],
            [-60.343686890999948, -47.713971039999933],
            [-60.341992876999939, -47.714039691999972],
            [-60.340225555999893, -47.71411169199996],
            [-60.332622012999877, -47.715333881999925],
            [-60.323944594999887, -47.71672912199989],
            [-60.318969679999952, -47.716929860999983],
            [-60.214496313499922, -47.733663724999985],
            [-60.212152899999978, -47.734036874999958],
            [-60.120538511999939, -47.748625165999954],
            [-60.110102132999884, -47.750281440999913],
            [-60.101451555999915, -47.751654692999978],
            [-59.53496755499998, -47.83999469299988],
            [-59.506626985999958, -47.844331851999925],
            [-59.483339942999976, -47.847895735999941],
            [-59.477356949999944, -47.848809766999977],
            [-59.467026053999916, -47.851726159999942],
            [-59.4628289, -47.852910655999892],
            [-59.440722233999907, -47.856281883999941],
            [-59.429941338999924, -47.859320884999988],
            [-59.402477217999945, -47.86349766099994],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "FRA", Country: "France" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.807882165841391, 49.545045037202144],
            [5.810836116166712, 49.537636105326769],
            [5.838609217140061, 49.514164261798939],
            [5.855282163707159, 49.501109112290649],
            [5.979582267746935, 49.448464222317241],
            [6.092500250156775, 49.453609034500616],
            [6.120245020297205, 49.476000115429926],
            [6.160691223631147, 49.504300102838343],
            [6.234718181026693, 49.50805402200497],
            [6.247318193191347, 49.505973130716811],
            [6.302782252486651, 49.476664129801506],
            [6.328336160768941, 49.469164170457177],
            [6.362173233430525, 49.459391206603343],
            [6.413745072156246, 49.47569115847817],
            [6.468336072414417, 49.461391128706552],
            [6.508336190860007, 49.442773245321277],
            [6.541245051561333, 49.4238821118917],
            [6.594718073570647, 49.363053969825373],
            [6.686945160296148, 49.244991175231959],
            [6.715073151050944, 49.187291159439368],
            [6.729236052885994, 49.167636099352322],
            [6.834100199788168, 49.155064250382267],
            [6.856354152781279, 49.181764132470121],
            [6.846073245593658, 49.212045098880438],
            [6.92986327619721, 49.220200187778758],
            [7.019445034273076, 49.187491151649809],
            [7.09055408352711, 49.127491141619302],
            [7.151663186988571, 49.121109160523801],
            [7.28971816181587, 49.111664264361295],
            [7.364027086435044, 49.151936124108531],
            [7.369018174513684, 49.168782240795665],
            [7.378609083430092, 49.172364163308757],
            [7.425554111590117, 49.176454029155636],
            [7.486945014637286, 49.16416414740921],
            [7.628054019714057, 49.06721804918287],
            [7.652091138793509, 49.044936100633137],
            [7.668891155013, 49.042218017063803],
            [7.737500217818877, 49.042773234331705],
            [7.82139116653704, 49.046391199028434],
            [7.935973292008015, 49.048882133021195],
            [8.045827185884548, 49.008191178113961],
            [8.155554010108602, 48.976391075567719],
            [8.226082193470916, 48.964418029762427],
            [8.198327197408389, 48.955418045021574],
            [8.137500228808534, 48.893191130949376],
            [8.12166329326655, 48.865000108282615],
            [8.111391270896348, 48.843609156042859],
            [8.105136191815063, 48.819445134948936],
            [8.092218170243171, 48.803600152779936],
            [7.961945121983916, 48.724164185612025],
            [7.880000115912509, 48.664164175581803],
            [7.802218065883665, 48.575827130130918],
            [7.745400161583916, 48.424944993562306],
            [7.736663200965552, 48.394027002508906],
            [7.743054066878557, 48.371109035144414],
            [7.748709169328691, 48.335791215463885],
            [7.693336137502143, 48.276382129609019],
            [7.60772706626642, 48.164182141027126],
            [7.578382190805286, 48.11720006485497],
            [7.569309115868577, 48.087218164750752],
            [7.570491131857665, 48.044109030770429],
            [7.583891112863625, 48.027218154720245],
            [7.619773202267737, 47.996527145605413],
            [7.623191174754197, 47.973053960973104],
            [7.584163189441426, 47.903327087556576],
            [7.557218220504296, 47.862782145403045],
            [7.526663333497368, 47.788053957985895],
            [7.512082174692637, 47.69513603479875],
            [7.527636137182611, 47.662426998669716],
            [7.564163297856794, 47.639164199807837],
            [7.588763177916974, 47.598918156322341],
            [7.588273171856002, 47.584482172080911],
            [7.577082157619202, 47.584309169598882],
            [7.554027062318426, 47.578882222553787],
            [7.546663225082085, 47.565553990639316],
            [7.521663192963018, 47.559718174356121],
            [7.502573240789587, 47.549927105591379],
            [7.502218183370132, 47.500545122895332],
            [7.496945128067779, 47.494991106197759],
            [7.452773168761922, 47.469991074078692],
            [7.433054070934446, 47.460545004449713],
            [7.415553998159709, 47.445273176821345],
            [7.385418206312693, 47.43332712074438],
            [7.358054142215451, 47.433609087967895],
            [7.355900160731522, 47.434018124844002],
            [7.340282160501005, 47.439018097740259],
            [7.308336045201145, 47.440273203925287],
            [7.275554086514262, 47.432491109719351],
            [7.247709236449538, 47.421664205357231],
            [7.182918130550831, 47.444018070636318],
            [7.146418127242953, 47.499053982316227],
            [7.025000224437207, 47.508045082239661],
            [6.99055412268919, 47.497218177877627],
            [6.88166330949025, 47.363673169885615],
            [6.931391129512292, 47.358891126472614],
            [6.980836144120474, 47.361664195326895],
            [7.000836035705191, 47.365000192800196],
            [6.971663324535768, 47.29193614844398],
            [6.838954159930722, 47.171518039051833],
            [6.849718200018685, 47.164991218668817],
            [6.793336154685079, 47.130827084144485],
            [6.699445092536536, 47.06389121685514],
            [6.678336107520607, 47.034164126608161],
            [6.632500172791879, 47.000000159721807],
            [6.566391264072081, 46.980000100499112],
            [6.528336082444639, 46.971664130129753],
            [6.46028223690675, 46.895827016919256],
            [6.446945120174746, 46.83943608676843],
            [6.456873149237708, 46.781454103752125],
            [6.435000237564168, 46.758045124498338],
            [6.358745035022082, 46.716727035593848],
            [6.26916327694633, 46.682782171657166],
            [6.128682244057302, 46.588054092655099],
            [6.135282154635973, 46.539718171478214],
            [6.086945059992615, 46.449436021571174],
            [6.071109130279126, 46.42638209973687],
            [6.111109081086653, 46.409718037987076],
            [6.153545148239886, 46.379264236732794],
            [6.117636069107647, 46.308745105825906],
            [6.106109108190481, 46.297491059676943],
            [6.115554004352987, 46.261527162897764],
            [6.074582255688625, 46.248400096659921],
            [6.030000253678367, 46.241391149205057],
            [6.009163177602034, 46.233891189860728],
            [5.981391250095186, 46.221936081328167],
            [5.967009077672515, 46.207291220059162],
            [5.996391168783845, 46.146945037426789],
            [6.133263289431454, 46.149782144021501],
            [6.187500238098551, 46.172082197482027],
            [6.245554137844124, 46.218327001448856],
            [6.271945062979938, 46.261936032135779],
            [6.243745155495759, 46.315509134069359],
            [6.230000175354149, 46.330200095806092],
            [6.244863301382395, 46.356382143914047],
            [6.295554034444052, 46.394164243135279],
            [6.319718223176068, 46.404164188927595],
            [6.49194504387853, 46.453045101450485],
            [6.515136093649119, 46.458464169506541],
            [6.633609098585026, 46.464164198958017],
            [6.702773211020713, 46.454718129328924],
            [6.737782241387578, 46.447491084752912],
            [6.798336127581337, 46.428609171416738],
            [6.807018102914668, 46.404227053201552],
            [6.800000103004379, 46.378327139955118],
            [6.783473169190984, 46.154718079177215],
            [6.873891105930028, 46.088053953189601],
            [6.933054099106926, 46.055273167969276],
            [7.007218184438869, 45.998054105782145],
            [7.030554073496717, 45.96193598198407],
            [7.038054032840932, 45.931936144607036],
            [6.984509094102151, 45.875900104237488],
            [6.943891229390658, 45.858327108904902],
            [6.922218142289495, 45.852218042577519],
            [6.902082128596788, 45.849854010599259],
            [6.872363252614974, 45.855973135210533],
            [6.813336046269711, 45.834236010368741],
            [6.799345141088395, 45.788664105590826],
            [6.809863256136481, 45.729645113510841],
            [6.969445137673148, 45.596936116543858],
            [6.995418141115522, 45.525273191126573],
            [7.089163190510277, 45.476864179753747],
            [7.146945013678049, 45.430545112124506],
            [7.1586091025315, 45.377491185275076],
            [7.127782138946657, 45.259300147562882],
            [7.06471820802102, 45.223054115921897],
            [6.939445132658022, 45.179445085234974],
            [6.821663299459829, 45.153053992461182],
            [6.766391185747864, 45.168191206724018],
            [6.689927106178089, 45.148054019564924],
            [6.637782280549658, 45.12610919116203],
            [6.634118215385314, 45.124000136679157],
            [6.623963204383784, 45.115718145766252],
            [6.661663328591658, 45.039991170763912],
            [6.749027067443052, 44.918327007642134],
            [6.775282205747033, 44.905545108178032],
            [6.849309163142578, 44.870409175796951],
            [6.901663201074967, 44.860409062366372],
            [6.942363208437513, 44.87069114302038],
            [6.969163338087611, 44.864300109469283],
            [7.006391225869152, 44.850691083797571],
            [7.031663334566105, 44.831382196312674],
            [7.028336054272245, 44.744718011102165],
            [6.975418081892713, 44.688191126481328],
            [6.950836139105263, 44.664718109487112],
            [6.852782288552078, 44.540836094778953],
            [6.853609079483618, 44.517773120489139],
            [6.877918107502865, 44.375136094459307],
            [6.976391220854055, 44.284164113918948],
            [7.031391257988219, 44.246936058499116],
            [7.350836150094835, 44.131518089641204],
            [7.420973233853942, 44.12610907986884],
            [7.466391246889231, 44.13860917974732],
            [7.520282190591985, 44.156109084884051],
            [7.614718076448469, 44.180273105978088],
            [7.662218154238388, 44.170827036348996],
            [7.661663272246471, 44.133954038348932],
            [7.709045165201331, 44.091309094167926],
            [7.706245106618752, 44.07582704840722],
            [7.658609241997084, 44.006800063908017],
            [7.598336149560367, 43.964718048345745],
            [7.49621808178432, 43.874509156272538],
            [7.512500096386049, 43.821109056821072],
            [7.531936053761939, 43.78204502916229],
            [7.53170237933341, 43.773706735666721],
            [7.53364357200013, 43.760116320000108],
            [7.537345391000088, 43.745015452000146],
            [7.570561969000039, 43.70312652200009],
            [7.610526198000116, 43.653226904000164],
            [7.67057521400011, 43.434778151999964],
            [7.704580983000113, 43.37083479500005],
            [7.841164436000156, 43.128769095000152],
            [7.84230177500001, 43.127345599000037],
            [7.908836739000122, 43.045024147],
            [8.068641767000145, 43.11888940900009],
            [8.143830372000025, 43.147497357000034],
            [8.188585067000048, 43.164404938],
            [8.192643261000114, 43.165970748000163],
            [8.224454575999971, 43.178251713000023],
            [8.234031356000116, 43.181602315000063],
            [8.241857521000071, 43.184157594000013],
            [8.256885022000091, 43.189013509999981],
            [8.407837526000037, 43.237736826000102],
            [8.434019167000145, 43.246090301],
            [8.443020248999971, 43.248677886000038],
            [8.454122864000055, 43.25166362200018],
            [8.455183575000177, 43.252126663500079],
            [8.509403050000117, 43.293593067000032],
            [8.704223714000108, 43.439709762000163],
            [8.829598806000121, 43.520499940000079],
            [8.926492052000128, 43.583940124],
            [8.993516590000155, 43.630489361000102],
            [9.011022335000149, 43.631904738000046],
            [9.460368539000058, 43.34683866500005],
            [9.473398881000037, 43.326047538],
            [9.628899634999982, 43.170079002000065],
            [9.636672793000116, 43.043412601000071],
            [9.704995558000178, 42.869500107000036],
            [9.779962045000161, 42.785163495000049],
            [9.807725054000173, 42.557506822999969],
            [9.845445700000141, 42.459901595000119],
            [9.868335774000116, 42.175470885],
            [9.878803610000091, 42.161137931999988],
            [9.891839770000104, 42.144164907000018],
            [9.912286571999971, 42.11760413800009],
            [9.921034066500198, 42.106725334000046],
            [9.922419882000156, 42.105054658000014],
            [9.953858296000078, 42.067480901000025],
            [9.954565769999988, 42.066664493000175],
            [9.974316249000083, 42.044246791000106],
            [9.991088619000152, 42.023827069000149],
            [9.995139922000078, 42.018947616],
            [10.019970401000108, 41.986762835999954],
            [10.043833115000098, 41.952754803000047],
            [10.061866328000121, 41.925254817000123],
            [10.07832910200014, 41.898732727],
            [10.101141040000044, 41.862218828999957],
            [10.123405375000033, 41.818563588000146],
            [10.141880204000188, 41.782343438999973],
            [10.143011151000053, 41.780133906000046],
            [10.17964995000014, 41.746981830999985],
            [10.20160543200015, 41.726557393],
            [10.20732834800009, 41.721453150000102],
            [10.375856060000075, 41.577802656],
            [10.35783961600012, 41.575673666000156],
            [10.348334815999976, 41.574550490000021],
            [10.343209094000088, 41.573941802000022],
            [10.332185072000186, 41.572632679999984],
            [10.148199137000148, 41.546156993000054],
            [9.708586415000184, 41.44794886300015],
            [9.631666667000047, 41.434666666000012],
            [9.451000000000107, 41.407499999000137],
            [9.317500000000109, 41.337166665999987],
            [9.270833333000155, 41.292833332],
            [9.135861111000111, 41.319166666000044],
            [8.81366666700012, 41.258666666000025],
            [8.617272432000135, 41.291248383999957],
            [8.608064525999964, 41.300716024000124],
            [8.307175361000105, 41.481067177000071],
            [8.259431921000157, 41.495322621000057],
            [7.962233853999976, 41.584185866000027],
            [7.866915277000146, 41.615091755000051],
            [7.73685345200002, 41.643613012000017],
            [6.927467182000129, 41.818351307999976],
            [6.810236324000186, 41.753341845000065],
            [6.681212202000154, 41.685752923999971],
            [6.438065495000131, 41.557183824],
            [6.312880050000103, 41.483111188],
            [5.935000000000173, 41.150833332000147],
            [5.923028545000051, 41.163629692000129],
            [5.833842369000109, 41.258961330000076],
            [5.337500090000162, 41.497222176000022],
            [5.251388889000083, 41.587499998999974],
            [5.155763950000022, 41.708021371000129],
            [5.016666667000152, 41.883333329000024],
            [4.987500000000153, 41.947222218999954],
            [4.558333333000121, 42.449999999000013],
            [4.53561935200014, 42.465773597000137],
            [4.208333333000041, 42.69305555900003],
            [4.161688775000158, 42.715859560999988],
            [4.145833333000013, 42.723611109],
            [3.868055556000058, 42.759722219000011],
            [3.411666667000077, 42.544999999000069],
            [3.37426456899999, 42.52254479700018],
            [3.34576628300016, 42.506712415999985],
            [3.307768568, 42.487713559],
            [3.276103806000094, 42.468714701000025],
            [3.231773138000051, 42.452882320000114],
            [3.203274852000135, 42.443382890999985],
            [3.182937952, 42.437950030000067],
            [3.177654177403923, 42.436809022210113],
            [3.164718058962961, 42.434435992986423],
            [3.106109109587379, 42.429164111150456],
            [3.084027153248115, 42.434718127848114],
            [3.047982119645951, 42.465964186592757],
            [2.950000185822233, 42.480554062577013],
            [2.724718097165777, 42.419445126753359],
            [2.678336165262664, 42.401664260221409],
            [2.657082173321129, 42.381936109938508],
            [2.670418116586745, 42.349582131228928],
            [2.533891162712678, 42.332909184661915],
            [2.47721809770016, 42.348191070574089],
            [2.440836111589363, 42.378473210450906],
            [2.332218213158541, 42.41805406609852],
            [2.273182121995944, 42.435609124158177],
            [2.144582269253334, 42.419300119827952],
            [2.123336156300894, 42.395764238559778],
            [2.093609233691893, 42.364164128223976],
            [2.062218168021843, 42.356944962636859],
            [2.020554074153182, 42.352635993840337],
            [1.99624504613405, 42.362500152801019],
            [1.972782255061617, 42.379436123490436],
            [1.95916333874419, 42.410827021522309],
            [1.906391211480297, 42.446936092864902],
            [1.851391174346134, 42.469991188165679],
            [1.780000157868557, 42.488327104327354],
            [1.723609227717446, 42.509436089343396],
            [1.724100239606997, 42.521382145420532],
            [1.741873227149739, 42.560627054387894],
            [1.771391105092903, 42.571109127252257],
            [1.781718112748166, 42.569964159275301],
            [1.738609146406105, 42.616391185817733],
            [1.698336113192283, 42.626109164386421],
            [1.559718209746364, 42.655964162476337],
            [1.486527095737756, 42.650418024767689],
            [1.445836140830409, 42.601944975654519],
            [1.41721814401464, 42.650836114099192],
            [1.390282227532907, 42.688327026003137],
            [1.359163238440658, 42.715273168406938],
            [1.325000109744821, 42.71749118763141],
            [1.173891158875733, 42.709718145880785],
            [1.143818063664668, 42.733300127616573],
            [1.126663325301678, 42.764718015377056],
            [1.088336067096549, 42.784854029069763],
            [0.896945030558129, 42.813045051736523],
            [0.82360907726229, 42.833053995776638],
            [0.716109157080211, 42.858882159931611],
            [0.692782152839612, 42.853609104629172],
            [0.667782120720659, 42.839164235570607],
            [0.651945017540584, 42.78500004182338],
            [0.661809176501265, 42.751109157343237],
            [0.682636026655615, 42.721245106798037],
            [0.681527100862269, 42.700691171411805],
            [0.675509229641733, 42.68848209121235],
            [0.66166333138554, 42.691936105882448],
            [0.595554087389758, 42.69500019441584],
            [0.560000233314867, 42.695827152985643],
            [0.505418117874314, 42.691664196942824],
            [0.432782221133607, 42.686664224046567],
            [0.269163279740212, 42.707564164396857],
            [0.180900162675499, 42.734509133334072],
            [0.144163286783311, 42.722218078121259],
            [0.11194509490565, 42.709445063474575],
            [0.002782205128824, 42.686073132233034],
            [-0.057917861456616, 42.699791122646317],
            [-0.123608848482917, 42.757500190894334],
            [-0.15194588390338, 42.776654013170216],
            [-0.179999778633942, 42.784436107376152],
            [-0.205554860382705, 42.794164144228844],
            [-0.262499834335131, 42.817500200924755],
            [-0.303190789242478, 42.841800176488491],
            [-0.308736759313035, 42.848382149794432],
            [-0.317845876433353, 42.832354106859512],
            [-0.391945924024867, 42.796391215908713],
            [-0.555836774357971, 42.780000068927123],
            [-0.715836745226142, 42.884582080967888],
            [-0.730626781058618, 42.901036092223237],
            [-0.723317761469247, 42.918945034235989],
            [-0.72347282667846, 42.929854081249275],
            [-0.754099798052835, 42.964373105555083],
            [-0.788754944466632, 42.954718158898331],
            [-0.818608769090048, 42.946109106122961],
            [-0.940281817029245, 42.9541641150971],
            [-0.99360882628477, 42.976109111138086],
            [-1.072781936968369, 43.006382198559393],
            [-1.20055483114146, 43.045827099737195],
            [-1.30028187721129, 43.071664148709672],
            [-1.348263914434995, 43.036173158908852],
            [-1.398054934007348, 43.038054057986571],
            [-1.439517862199068, 43.049373147704458],
            [-1.466736751733009, 43.092045081613676],
            [-1.423126715217592, 43.123264150630106],
            [-1.383890858705627, 43.209718117708277],
            [-1.385136744797165, 43.252564227566026],
            [-1.408890890824694, 43.273045072756403],
            [-1.503608743904778, 43.287773250143331],
            [-1.655554879265281, 43.309436111322711],
            [-1.702081818093717, 43.305973211834996],
            [-1.730372920684829, 43.298982201653061],
            [-1.776945959980964, 43.342500204871115],
            [-1.780872881814616, 43.359927019906067],
            [-1.767172287999784, 43.38610176050004],
            [-1.762063848999986, 43.395043384000061],
            [-1.759615504999914, 43.40565287750006],
            [-1.758294830499921, 43.411375798500003],
            [-1.758240325, 43.423082560000026],
            [-1.759514833000026, 43.43200411600013],
            [-1.759514833000026, 43.440925672000034],
            [-1.760789340999963, 43.453670752000036],
            [-1.763338356999924, 43.47916091300003],
            [-1.767161880999822, 43.503376565000011],
            [-1.772259913999932, 43.525043201000116],
            [-1.778632453999876, 43.544160821999967],
            [-1.785004993999934, 43.562003934000174],
            [-1.793926549999924, 43.58112155400012],
            [-1.802222221999841, 43.595277777],
            [-1.858333332999905, 43.661111110000135],
            [-1.924999999999898, 43.729166666000012],
            [-2.044444444000021, 43.799999999000065],
            [-2.190277777999881, 43.890277777],
            [-2.266666666999868, 43.999999999000138],
            [-2.34166666699997, 44.108333331999987],
            [-2.425, 44.216666666000137],
            [-2.516666666999811, 44.319444443000052],
            [-2.605277777999987, 44.411111110000078],
            [-2.708333332999871, 44.499999999000025],
            [-2.840833332999921, 44.595833332000112],
            [-2.949999999999818, 44.663888887999988],
            [-3.063888889, 44.756944443],
            [-3.172222221999846, 44.866666666000029],
            [-4.999999999999829, 45.183246334999964],
            [-5.666666666999845, 45.298716123000034],
            [-6.684444443999865, 45.474999999000076],
            [-8.518708572999884, 45.792703134000064],
            [-8.637134375999835, 45.879131291000178],
            [-8.831112550999819, 46.038434495999965],
            [-9.011521210000012, 46.205587974000125],
            [-9.177608167999949, 46.380007247999984],
            [-9.269789339999818, 46.490513353000026],
            [-9.328651508999855, 46.561077184999974],
            [-9.463961508999972, 46.748154185000075],
            [-9.58288650899982, 46.940567185000063],
            [-9.676779015999898, 47.122081212000026],
            [-9.682064396666533, 47.132298983333342],
            [-9.684541508999928, 47.135671185000135],
            [-9.685969508999847, 47.137953185000029],
            [-9.741138377999846, 47.22575559600007],
            [-9.805275527999896, 47.32783159],
            [-9.855565682999895, 47.423337390000157],
            [-8.744695702999849, 47.932006640999973],
            [-7.602144014000032, 48.423383093000083],
            [-7.60064018699984, 48.424016437000105],
            [-6.969112592999892, 48.687024925000074],
            [-6.402510076999988, 48.911218074000217],
            [-6.343817828999875, 48.934151474000046],
            [-6.291766414, 48.954605464000096],
            [-6.070621518999928, 49.041706527000102],
            [-6.003998069999852, 49.068842899000046],
            [-5.848250159999878, 49.131332170999983],
            [-5.659433284999892, 49.202891334000142],
            [-5.448952391999853, 49.210493028000101],
            [-5.271167467999874, 49.216314428000189],
            [-5.211029557999893, 49.225709731000151],
            [-5.206017705999898, 49.226789617000136],
            [-5.133924774999912, 49.242202223000149],
            [-4.797073745999853, 49.324689905999989],
            [-4.628923669999836, 49.356580547000121],
            [-4.48380823999986, 49.415926328000111],
            [-4.33315680899986, 49.470791696000035],
            [-4.285992630999885, 49.48785484],
            [-4.233159236999853, 49.518109322000029],
            [-3.912329709999824, 49.540402479000093],
            [-3.79744311399989, 49.545113614999977],
            [-3.722486027999878, 49.55203450000009],
            [-3.439984776999893, 49.378361266000056],
            [-3.237402420999871, 49.262560743000094],
            [-3.233668866999864, 49.26118934000003],
            [-2.999517563999916, 49.197763126000112],
            [-2.987415709999851, 49.194455371000075],
            [-2.711401107999961, 49.115105329000116],
            [-2.702320266999919, 49.106020204000075],
            [-2.68090606699991, 49.084892566000079],
            [-2.662292500999939, 49.041464455000138],
            [-2.64287601699985, 48.996163022000175],
            [-2.642472104499859, 48.995213588000112],
            [-2.634639497999956, 48.976665515000136],
            [-2.625676842999923, 48.955638048000068],
            [-2.609497061999861, 48.917678334000144],
            [-2.605309762999866, 48.908723085000091],
            [-2.596492807999823, 48.889866529000173],
            [-2.578211956999894, 48.884399612000081],
            [-2.569902404999823, 48.882420848000052],
            [-2.516263045999921, 48.865790199000074],
            [-2.491168084999913, 48.861276027000073],
            [-2.396072897999829, 48.844239450000074],
            [-2.375103770999885, 48.837670606000145],
            [-2.328503645999945, 48.822869489000013],
            [-2.323498840999889, 48.821288403000025],
            [-2.318494033999968, 48.819798050000074],
            [-2.249526220999826, 48.799369719000126],
            [-2.186553746999863, 48.778404924000014],
            [-2.156006751999882, 48.783267184000053],
            [-2.147668196999888, 48.784560066000111],
            [-2.095001559, 48.786688616000092],
            [-2.07603782599989, 48.787372741000112],
            [-2.058532632999857, 48.788247446000113],
            [-2.035961179999845, 48.794262671000169],
            [-1.999867195999911, 48.805009803000146],
            [-1.986216942999818, 48.839511048000091],
            [-1.968216139999811, 48.884107277000155],
            [-1.955763642999955, 48.916606121000072],
            [-1.954284029999826, 48.920262762000092],
            [-1.970105758999921, 48.943068718000049],
            [-1.970076818999843, 48.950117777000131],
            [-1.954423301999896, 48.968778943000146],
            [-1.940912812999898, 48.985212400000151],
            [-1.919024173, 49.006768151000117],
            [-1.912533384999932, 49.013278049000078],
            [-1.911268520999897, 49.014525607000095],
            [-1.91016097099984, 49.015575842000018],
            [-1.90946952099992, 49.016225797000075],
            [-1.908764584999858, 49.016875739000128],
            [-1.883499649999919, 49.039959314000029],
            [-1.882777312999849, 49.04062547100007],
            [-1.866458881999961, 49.041217330000123],
            [-1.85562496499989, 49.077742984000153],
            [-1.8544990339999, 49.110394174000149],
            [-1.854481260499909, 49.111666175000053],
            [-1.854287570999816, 49.116918569000049],
            [-1.856744930999923, 49.123247868000064],
            [-1.856580080999976, 49.125593448000174],
            [-1.85653073799989, 49.126569074500097],
            [-1.856485341499905, 49.128316975500056],
            [-1.852766569999915, 49.206131265000167],
            [-1.849432317999913, 49.214629191000014],
            [-1.832781837999988, 49.219191497000125],
            [-1.818384923999815, 49.223085580000074],
            [-1.816787586999908, 49.260542730000097],
            [-1.818564223999829, 49.283580438000129],
            [-1.825188859999855, 49.293261824000112],
            [-1.843073709999857, 49.32031390900012],
            [-1.852800996999918, 49.326249054000115],
            [-1.869882512999908, 49.33637816800011],
            [-1.880350051999926, 49.342676283000046],
            [-1.886581899999868, 49.346425865000143],
            [-1.900508449999933, 49.354596777000026],
            [-1.913530688999856, 49.362237117000078],
            [-1.921997002999944, 49.366480985000109],
            [-1.925429520999899, 49.368233953000114],
            [-1.928672189999901, 49.369773399000039],
            [-1.931603016999901, 49.371604707000031],
            [-1.937347410999848, 49.375283581000147],
            [-1.964996254999846, 49.391439704000121],
            [-1.989047863999929, 49.405679413000044],
            [-2.00316004299998, 49.413287699000122],
            [-2.0731720039999, 49.433613097000077],
            [-2.091254045999989, 49.43618688100004],
            [-2.11042659099985, 49.474086965000012],
            [-2.133562717999951, 49.527640929000071],
            [-2.134827655999885, 49.530537424000087],
            [-2.138568161999842, 49.538618073000166],
            [-2.131424915999901, 49.545151437000087],
            [-2.122354309999821, 49.572787724000122],
            [-2.106364384999836, 49.611232315000123],
            [-2.104136766999915, 49.615517850000046],
            [-2.090993417999812, 49.640494769000028],
            [-2.085540692999842, 49.648379908000081],
            [-2.084949410999968, 49.649781100000027],
            [-2.072777396999953, 49.676566572000084],
            [-2.071531393999976, 49.678644962000092],
            [-2.061766507999891, 49.695196007000149],
            [-2.059030731499888, 49.705055196000117],
            [-2.058779354999842, 49.706004322000112],
            [-2.053156315, 49.727854836000162],
            [-2.049215243999868, 49.74260562400012],
            [-2.048627744999919, 49.754869453000069],
            [-2.048336847999934, 49.776350424000086],
            [-2.042821055999894, 49.856745008000118],
            [-2.040969946999951, 49.882150066000108],
            [-2.023477161799946, 50.149766386000138],
            [-1.875387309999894, 50.158517405000239],
            [-1.844582392999911, 50.160142154000127],
            [-1.835329675999844, 50.160667644999975],
            [-1.793056919999913, 50.161388292000012],
            [-1.75325131399984, 50.162117709000071],
            [-1.684494097999902, 50.165636527000245],
            [-1.679624834999885, 50.165877082000165],
            [-1.661159835000035, 50.16676089100001],
            [-1.613085953999814, 50.154274697000105],
            [-1.60367109399985, 50.151810176000126],
            [-1.450784135999896, 50.143723624000202],
            [-1.422207466999879, 50.142592381000114],
            [-1.309592134000013, 50.139073654000114],
            [-1.300991853999847, 50.138808830000144],
            [-1.226999232999873, 50.136225938000152],
            [-1.22623944099999, 50.136203840000078],
            [-1.210809148999857, 50.135935630000105],
            [-1.142362885999972, 50.136913774000206],
            [-1.037535318999915, 50.13638715900008],
            [-0.974917897499893, 50.135911665500103],
            [-0.858329771999905, 50.132031047999973],
            [-0.829620073999877, 50.131018650000229],
            [-0.592065607999842, 50.121842311000094],
            [-0.574279432999901, 50.121066397000206],
            [-0.550156027999947, 50.116342181000121],
            [-0.499999999999829, 50.124722221],
            [-0.258333332999911, 50.220277777000106],
            [0.037222222000082, 50.236666666000147],
            [0.603333333000108, 50.328055555000176],
            [0.777500000000117, 50.389444444000119],
            [1.123888889000085, 50.643888888000092],
            [1.257777778000133, 50.797222221000126],
            [1.282777778000082, 50.896388888000118],
            [1.356944444000135, 50.949999999000099],
            [1.548055556000236, 51.038611110000076],
            [1.725277778000191, 51.099444444000071],
            [1.955000000000126, 51.240833332000065],
            [2.038463131000128, 51.336649781500128],
            [2.121666667000227, 51.503888888000219],
            [2.237369453000127, 51.556710815000102],
            [2.238118570400133, 51.557769027400113],
            [2.23972762000011, 51.554717018000147],
            [2.390278, 51.26917],
            [2.542982666000142, 51.094330400000146],
            [2.543611000000169, 51.093611],
            [2.541663346038604, 51.091109178805382],
            [2.597500252622496, 50.995827087653296],
            [2.597782219846124, 50.925827131830474],
            [2.650554011833748, 50.816109192423838],
            [2.781945038341831, 50.755553965125713],
            [2.867500130120931, 50.713382095561158],
            [2.898054011299564, 50.703045029622203],
            [2.943054102641327, 50.741391063290578],
            [2.960554007777972, 50.757500075410363],
            [3.014309164648893, 50.769864220819031],
            [3.026991151826905, 50.769845110079629],
            [3.046245054026969, 50.778745014896217],
            [3.116391190241387, 50.79166420993478],
            [3.15857328572784, 50.784364242800891],
            [3.182873261291661, 50.757045105704819],
            [3.187218104633843, 50.746664118593017],
            [3.197782152511365, 50.727218103171637],
            [3.242636063461447, 50.664164230529806],
            [3.279309236889247, 50.540000080959842],
            [3.297082224432273, 50.524300105716165],
            [3.370000088396409, 50.495273239662467],
            [3.454582208852372, 50.510409112820739],
            [3.518054003187615, 50.518882211126581],
            [3.613963259988452, 50.492936029774441],
            [3.660400177176513, 50.455273121216678],
            [3.671109231979472, 50.4370731595249],
            [3.676945048262667, 50.39527311118654],
            [3.774718105058611, 50.353882099723975],
            [3.889718152223196, 50.34000015928406],
            [3.983891181595368, 50.345273214586413],
            [4.035754040000199, 50.355991154206961],
            [4.165000137479581, 50.283054011865332],
            [4.194445092864783, 50.237500211998039],
            [4.171391171030564, 50.137218116298683],
            [4.147218097481243, 50.009991219299806],
            [4.149236124495445, 49.978373171691189],
            [4.178618215606775, 49.978136131468617],
            [4.215263225839976, 49.959600055458523],
            [4.239582144504936, 49.963464112833307],
            [4.317218181779822, 49.969718018448006],
            [4.413336147970313, 49.949164250699866],
            [4.43000909453724, 49.945009173646255],
            [4.458127194646323, 49.939227001543529],
            [4.510554155136475, 49.947491222821313],
            [4.676663192332029, 49.999164147299709],
            [4.69340017663967, 50.056664170881945],
            [4.693745175775263, 50.084018176695466],
            [4.76305412749835, 50.135553973237464],
            [4.824727165407126, 50.167573178733178],
            [4.885900138971039, 50.134164253687189],
            [4.853336109767412, 50.095554022429937],
            [4.838891240708563, 50.06916427076051],
            [4.820836118304214, 49.992773113748697],
            [4.868473156392355, 49.802218088235421],
            [4.909718155101018, 49.789300066663415],
            [4.937291263863983, 49.791873143307541],
            [4.959163337347206, 49.802218088235421],
            [4.990554067741101, 49.803609148890345],
            [5.331109118330232, 49.656664159892486],
            [5.392309081622557, 49.622554004824607],
            [5.406809103604331, 49.617354039718194],
            [5.432500139822935, 49.603473105106659],
            [5.474927154520742, 49.535900213174259],
            [5.472782225492153, 49.50888215404099],
            [5.522500154868538, 49.510000132289818],
            [5.593336121716305, 49.526382226815812],
            [5.631945011868908, 49.535827122978404],
            [5.702218217735918, 49.543053999916438],
            [5.79221806514326, 49.54582706877072],
            [5.807882165841391, 49.545045037202144],
          ],
          [
            [7.75694444400014, 42.966388888000139],
            [7.528333333000035, 43.552499999000119],
            [7.453500000000162, 43.74319444400011],
            [7.439483492750099, 43.750413997500019],
            [7.439291213244985, 43.757518020820029],
            [7.428991194815779, 43.768300049183054],
            [7.390900138642706, 43.749373208845739],
            [7.391609801687054, 43.727545211170494],
            [7.419583333000162, 43.725805555],
            [7.496666667000142, 43.529444444000021],
            [7.724690725000158, 42.948578832000109],
            [7.75694444400014, 42.966388888000139],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "FRO", Country: "Faroe Is." },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.486727777999931, 64.433199999000223],
            [-0.488763888999813, 63.887480555000238],
            [-0.795599999999865, 63.677483333000239],
            [-3.055416666999861, 61.987216667000226],
            [-3.19548333299997, 61.86856666700011],
            [-3.798299999999813, 61.360183333000251],
            [-3.99365, 61.127516667000052],
            [-4.040416666999874, 61.07415],
            [-4.064316666999929, 61.04595000000019],
            [-4.17495, 60.916316667000203],
            [-4.233466666999902, 60.86348333300019],
            [-4.309016666999923, 60.795283333000057],
            [-4.737866666999878, 60.401283333000052],
            [-4.944533332999981, 60.351683333000054],
            [-5.403249999999844, 60.312566667000041],
            [-9.011, 59.940833333000128],
            [-10.34755, 60.015850000000185],
            [-10.846299999999815, 60.035616667000099],
            [-11.2743, 60.047216667000143],
            [-12.293699999999887, 60.121766667000017],
            [-13.269954065874884, 60.150284488750131],
            [-13.909696504999886, 60.826393094000068],
            [-13.299760563999939, 61.577798599000133],
            [-12.967583985999852, 61.925640564000091],
            [-12.027208718999958, 62.62596891400004],
            [-11.310116132999894, 63.068080035000222],
            [-11.063333380999779, 63.200581582000225],
            [-10.7651310359999, 63.353884954000179],
            [-10.740475376999939, 63.366475411000167],
            [-10.071307960999974, 63.772795301000073],
            [-9.872671470999819, 63.869899961000087],
            [-8.981929053999806, 64.278671858000095],
            [-7.888253434000035, 64.752489980000135],
            [-7.342066186999972, 64.9710991110001],
            [-6.717843286999909, 65.244289322000085],
            [-6.083711219999827, 65.505319199000056],
            [-5.557445790999907, 65.694080389000149],
            [-5.446967083, 65.691043362000116],
            [-4.08511980999981, 65.545928197000066],
            [-3.903684578999872, 65.510622973000139],
            [-3.843380623999906, 65.503790289000207],
            [-3.797693503999824, 65.498613412999973],
            [-3.762183334999889, 65.49163401400007],
            [-3.752116312999959, 65.489655275000231],
            [-3.616033534999929, 65.462322274000073],
            [-2.879652502999818, 65.314415413000134],
            [-2.544566501999896, 65.245192413000112],
            [-2.538038330999939, 65.243173334000133],
            [-2.535112286999947, 65.242268268000018],
            [-2.512481184999899, 65.237550115000161],
            [-2.495915501999889, 65.234096413000046],
            [-2.17666045799993, 65.132771167999962],
            [-1.396483009999855, 64.885157971000055],
            [-0.525798903999885, 64.453487263000127],
            [-0.486727777999931, 64.433199999000223],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "FSM", Country: "Micronesia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [141.214722632000104, 13.10888936400012],
            [141.321388632000122, 12.95944436400012],
            [141.359166632000125, 12.904722364000065],
            [141.363333632000121, 12.900000364000164],
            [142.257777632000085, 11.875833363000069],
            [143.052499633000053, 10.958333362000161],
            [143.448055634000156, 10.950833362],
            [144.941388635000095, 11.223055362000082],
            [144.957222635000193, 11.225555362000065],
            [145.377222635000095, 11.291944362000095],
            [145.876666636000181, 11.370277363000184],
            [146.426110636000232, 11.45416636300007],
            [146.921944637000109, 11.53000036300007],
            [147.517499637000043, 11.614722363000141],
            [147.744999638000166, 11.640277363000081],
            [147.86131563800015, 11.489085363000129],
            [147.867540638000179, 11.495321363000158],
            [148.349289638000215, 11.832906363],
            [148.387302638000136, 11.850679363000083],
            [148.407597638000169, 11.864871363000162],
            [148.942971639000262, 12.114570362999984],
            [149.515284639000157, 12.268609363000138],
            [150.106656640000239, 12.32212936300003],
            [150.109824640000141, 12.321871363000184],
            [150.112730640000251, 12.322131363000111],
            [150.70460164000022, 12.273418363000133],
            [151.278384641000144, 12.123996363000074],
            [151.513447641000113, 12.016848363000051],
            [151.612839641000193, 12.042937363000036],
            [151.637654641000182, 12.045061363000102],
            [151.677455641000137, 12.055496363],
            [152.26895914200017, 12.105517363000118],
            [152.269727642000163, 12.105539363000162],
            [152.280575642000116, 12.104590363000156],
            [152.281842642000157, 12.104696363000045],
            [152.287554642000174, 12.104196362999971],
            [152.29421914200023, 12.104712862999989],
            [152.294975642000196, 12.104734363],
            [152.886005642000185, 12.052517363],
            [153.458266643000144, 11.899735363000019],
            [153.46334164300012, 11.897385363000183],
            [153.467340643000142, 11.896314363000116],
            [154.002942643000125, 11.647763363000067],
            [154.004090643000069, 11.646964363000123],
            [154.004951643000169, 11.646563362999984],
            [154.197604644000222, 11.51230236300016],
            [154.308033644000176, 11.520984363000068],
            [154.31036664400014, 11.520767363000047],
            [154.313087644000206, 11.520980363000092],
            [154.90278064400016, 11.465634363],
            [155.473155645000105, 11.30990736199999],
            [156.006430645000108, 11.058719361999977],
            [156.486345146000161, 10.719731862000074],
            [156.487116646000146, 10.719119362000029],
            [156.49011764600013, 10.716091362000043],
            [156.491129646000189, 10.715375362000046],
            [156.500519646000129, 10.705898362],
            [156.54010864600005, 10.694934362000112],
            [157.071870646000178, 10.442488362000105],
            [157.072919646000202, 10.441744362000094],
            [157.07387764600017, 10.441288362000023],
            [157.188426646000067, 10.359962362],
            [157.228919646000264, 10.369981362000047],
            [157.472641647000074, 10.402441362000033],
            [157.76341164700014, 10.09301636100011],
            [158.009249647000075, 9.830861361000132],
            [158.029955647000094, 9.808736361000129],
            [158.38472064800024, 9.43152136099998],
            [159.082333648000059, 8.818391360000092],
            [159.395942648000158, 8.54807136],
            [159.776070649000161, 8.427232360000048],
            [159.874072649000169, 8.396074360000142],
            [159.920620649000085, 8.381231360000015],
            [160.165669649000193, 8.303852360000178],
            [160.335577649000157, 8.241017360000043],
            [160.480733649000143, 8.187163360000142],
            [160.501651649000195, 8.179295360000083],
            [160.631853650000181, 8.130150360000158],
            [160.824532650000066, 8.057070359000122],
            [160.978370650000187, 7.998193359000098],
            [161.0162796500002, 7.98361335900006],
            [161.545435650000087, 7.938331359000017],
            [161.695968651000129, 7.925551359000153],
            [161.796939651000145, 7.917332359000099],
            [162.621836651000166, 7.848877359000014],
            [162.70125265200025, 7.884104359000062],
            [162.8902806520002, 7.968169359000044],
            [162.918155652000252, 7.980536359000112],
            [163.002906652000235, 8.018139359000017],
            [163.017656652000113, 8.024743359000027],
            [163.073531652000185, 8.049668359000165],
            [164.37056965300016, 7.159509359000097],
            [164.669818653000192, 6.932751358000019],
            [164.855610654000174, 6.79211635800003],
            [165.515262654000054, 6.291839358000132],
            [165.523040654000198, 6.144390358000166],
            [165.553813654000152, 5.627906356999986],
            [165.5603856540001, 5.516786357000129],
            [165.572636654000149, 5.318188357000153],
            [165.572727654000147, 5.31671935700011],
            [165.572818654000201, 5.315261357],
            [165.590490654000092, 5.031539357],
            [165.590981654000217, 5.022362357000091],
            [165.595459654000166, 4.94126135700013],
            [165.642247654000045, 4.132965356000099],
            [165.656110654000173, 3.891460356000124],
            [165.667320654000179, 3.700731355000016],
            [165.667460654000109, 3.698360355000133],
            [165.671196654000056, 3.63543635500001],
            [165.674719654000143, 3.575840355000096],
            [165.635850654000109, 3.243221355000074],
            [165.632144654000086, 3.21136735500005],
            [165.577718654000165, 3.132736355],
            [165.577068654000215, 3.132095355000075],
            [165.575718654000156, 3.130194355000171],
            [165.57451965400017, 3.129012355000071],
            [165.572727654000147, 3.126487355000052],
            [165.159688654000121, 2.718594354000103],
            [165.157951654000186, 2.717393354000023],
            [165.153573654000155, 2.713103354000083],
            [165.150701654000187, 2.710301353999967],
            [165.146547654000216, 2.707429354000013],
            [165.142721654000098, 2.703646354],
            [164.665403653000197, 2.373203354],
            [164.138138653000084, 2.130191354000033],
            [163.576774652000182, 1.981949354],
            [163.574889652000167, 1.981791354000066],
            [163.571791652000257, 1.980971354000076],
            [162.993209652000104, 1.931985354000076],
            [162.989824652000067, 1.932290354000088],
            [162.988225652000125, 1.932154354000019],
            [162.409828651000254, 1.983923354000083],
            [162.407331651000135, 1.984596354000075],
            [162.405845651000192, 1.984728353999984],
            [162.403296651000147, 1.985416354000066],
            [162.401863651000241, 1.985543354000185],
            [162.394514651000151, 1.987525354000041],
            [162.35206465100012, 1.991304354000079],
            [161.791137651000184, 2.14229235400002],
            [161.788266651000157, 2.143085354],
            [161.787435651000152, 2.143474354000034],
            [161.767329651000153, 2.148875353999983],
            [161.240968650000042, 2.394476354000119],
            [160.764732150000071, 2.727560854],
            [160.763931650000103, 2.728177354000039],
            [160.760158650000136, 2.731943354000066],
            [160.758929650000056, 2.732801354],
            [160.613478650000189, 2.87790535500001],
            [160.118475649000203, 2.920367355000053],
            [159.556716649000208, 3.069289355000038],
            [159.353066648000237, 3.163330355000042],
            [159.017304648000135, 2.930352355000025],
            [158.489405648000201, 2.686369854000063],
            [158.487701648000183, 2.685638354000019],
            [158.464414648000144, 2.679467354000039],
            [158.450835648000151, 2.673182354000048],
            [158.07825864700024, 2.574348354000122],
            [157.923804647000139, 2.244885354000019],
            [157.934555647000167, 2.221761354000051],
            [158.084161647000172, 1.660657352999976],
            [158.085055647000132, 1.651042353000065],
            [158.135090647000169, 1.072151353000095],
            [158.134680647000181, 1.067519353],
            [158.135075647000264, 1.062952353000043],
            [158.134778647000218, 1.059599353000081],
            [158.135064647000064, 1.056293353000015],
            [158.08393864700011, 0.477876352000095],
            [158.083031647000183, 0.474499352],
            [158.082925647000053, 0.473298352000029],
            [158.080141647000261, 0.462939352],
            [158.079917647000201, 0.460396352000103],
            [157.929341647000143, -0.100379647999915],
            [157.683884647000042, -0.62653064899996],
            [157.683210647000038, -0.627493648999973],
            [157.682876647000199, -0.628208648999973],
            [157.349955647000257, -1.103796649],
            [157.274430646000127, -1.180216648999902],
            [156.824362646000196, -1.167572648999922],
            [155.847690645000256, -1.140973648999832],
            [155.637883645000187, -1.123180648999877],
            [153.920269643000182, -0.9852766489999],
            [153.598081643000199, -0.806203648999841],
            [152.915090642000251, -0.426841647999865],
            [152.680823642000149, -0.247478647999884],
            [151.853225641000193, 1.022120353000219],
            [151.480530641000286, 1.578431353000099],
            [151.486904641000109, 1.669674353000161],
            [151.635585641000063, 2.230784354],
            [151.778233641000071, 2.538953354000029],
            [151.326232641000132, 2.861018355000056],
            [150.917624641000174, 3.275453355000124],
            [150.66479464000011, 3.641454355000121],
            [150.434044640000053, 3.536609355000039],
            [149.871080640000201, 3.391086355000098],
            [149.291097639000299, 3.344735355000054],
            [149.290335639000119, 3.34471335500001],
            [148.711150638000078, 3.398932355000014],
            [148.149906638000147, 3.552162355000021],
            [147.623454637000151, 3.799838355000063],
            [147.281984637000193, 4.040107356000092],
            [147.025036637000113, 4.018469356000054],
            [147.024288637000126, 4.01844735600001],
            [146.444645636000104, 4.070304356000065],
            [146.365288636000201, 4.09165335600008],
            [146.170481636000119, 4.074890356000083],
            [146.169502636000203, 4.074976356000093],
            [146.168495636000188, 4.074889356],
            [145.631570636000248, 4.122077356],
            [145.608338136000185, 4.111284856000097],
            [145.606428636000118, 4.110415356],
            [145.04440863500011, 3.959795356000129],
            [144.977091635000221, 3.953841356000169],
            [144.766319635000144, 3.80610335500009],
            [144.239540634000122, 3.559552355000051],
            [143.678163634000185, 3.407520355000045],
            [143.099035633000227, 3.354549355000103],
            [143.097801633000103, 3.354651355000016],
            [143.097032633000168, 3.354580355000095],
            [143.095455633000057, 3.354711355],
            [143.094058633000117, 3.354582355],
            [143.09300463300022, 3.354670355000167],
            [143.092070633000191, 3.354584355000156],
            [142.512552633000126, 3.402227355000065],
            [141.950006632000139, 3.549026354999981],
            [141.920985632000196, 3.562303355000026],
            [141.908216632000091, 3.565626355000177],
            [141.379455632000173, 3.807093355000021],
            [140.90034763100013, 4.135997356000146],
            [140.485262631000211, 4.542445355999988],
            [140.484509131000124, 4.543397356000156],
            [140.299942631000107, 4.800672356000035],
            [139.877113630000139, 4.832923356000052],
            [139.31205163000007, 4.976438357000035],
            [138.780235629000259, 5.214660357000156],
            [138.777037629000148, 5.216822357000069],
            [138.77625962900018, 5.217170357],
            [138.771365129000088, 5.220460857000035],
            [138.766180629000161, 5.223946357000031],
            [138.660456629000095, 5.172093357000037],
            [138.650039629000077, 5.169302357000035],
            [138.643560629000177, 5.166283357],
            [138.080996629000111, 5.015081357000014],
            [137.500830628000159, 4.962775357000027],
            [137.499617628000152, 4.962877357000025],
            [137.498846628000052, 4.962807357000102],
            [136.97550962800014, 5.008350357000069],
            [136.642795627000083, 6.014132358000083],
            [136.355613627000054, 6.887707358000128],
            [136.233065627000116, 7.316405359000115],
            [136.186722627000194, 7.477977359000135],
            [136.182220627000191, 7.496708358999982],
            [136.168496627000223, 7.546022359000034],
            [136.148458627000224, 7.617674359000048],
            [136.11909362700024, 7.865587359000159],
            [136.084539627000112, 8.077761359000135],
            [136.018320127000237, 8.495242360000077],
            [136.018171627000044, 8.496171360000119],
            [136.003945627000093, 8.585375360000128],
            [135.928823627000128, 9.061811360000149],
            [135.726043626000234, 10.345491362000118],
            [135.501782626000107, 10.863790362],
            [135.363374626000251, 11.177716362000112],
            [135.29051962600019, 11.341208363],
            [135.27467762600017, 11.376603363000015],
            [135.543287626000193, 11.750277363000095],
            [135.543844626000094, 11.750833363],
            [135.547263626000102, 11.755727363000105],
            [135.962388627000138, 12.170190363000088],
            [136.445926627000091, 12.507216364000143],
            [136.447116627000042, 12.507769364000154],
            [136.449946627000173, 12.509737364],
            [136.987087628000126, 12.75877736400011],
            [137.561148628000211, 12.912007364000019],
            [137.906021628000104, 12.942718364000072],
            [138.41123062900013, 13.173982364000025],
            [138.414308629000089, 13.174792363999984],
            [138.425349629000124, 13.179834363999987],
            [138.451855629000164, 13.186801364000189],
            [138.471785629000095, 13.195898364000058],
            [138.475992629000103, 13.197004364000179],
            [138.48087162900012, 13.199230364000059],
            [138.492924629000214, 13.202397364],
            [138.508114629000175, 13.20932736400016],
            [138.53087362900024, 13.215304364000161],
            [138.54546562900012, 13.221959364000128],
            [139.121339630000222, 13.372551364000174],
            [139.715653630000162, 13.422360364000156],
            [139.717136630000113, 13.422230364000143],
            [139.718694630000158, 13.422359364000158],
            [140.31272563100012, 13.369817364],
            [140.887766631000233, 13.216598364000021],
            [140.893520631000257, 13.213941364000092],
            [140.915010631000115, 13.208193364000124],
            [141.214722632000104, 13.10888936400012],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "GAB", Country: "Gabon" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.293891173348413, 2.163609063786069],
            [13.289718159021788, 2.072364168477492],
            [13.270282201884385, 2.037009133146654],
            [13.206945020914475, 1.977782102229227],
            [13.184163343296063, 1.929164213828827],
            [13.182154033461188, 1.872291156605854],
            [13.191391226062393, 1.841109135601556],
            [13.192636106325637, 1.808053926870372],
            [13.171663243417498, 1.76444506382154],
            [13.143191259355518, 1.706873123509922],
            [13.135136082743202, 1.577709001043814],
            [13.158745054207316, 1.538336016595565],
            [13.190282132631268, 1.510554030804911],
            [13.224718176095394, 1.448336001550032],
            [13.235836100136368, 1.427218131716572],
            [13.246391263196671, 1.395553983640326],
            [13.249100126672317, 1.329717989688817],
            [13.216873217615415, 1.294718011777363],
            [13.183645174040294, 1.283818017219573],
            [13.170554149986259, 1.247364114379309],
            [13.186782185131534, 1.222473214640019],
            [13.205000251734106, 1.221664193343216],
            [13.26471812690292, 1.224164179791387],
            [13.305273294978349, 1.234445086979008],
            [13.365000222602475, 1.271945051338363],
            [13.469163307121136, 1.290554049906234],
            [13.573054147423989, 1.305836103456485],
            [13.784445075546842, 1.394026968515576],
            [13.804445134769736, 1.435627024643793],
            [13.89777326829946, 1.440000031180674],
            [13.977500255146396, 1.41639105971656],
            [14.07694516635442, 1.375553924417616],
            [14.098336118593977, 1.381527036275187],
            [14.116109106136918, 1.390691138680467],
            [14.150273240661249, 1.395691111576625],
            [14.191527124187218, 1.390554010744083],
            [14.236109126197562, 1.363335959400658],
            [14.271109104108831, 1.330000124550423],
            [14.293127190345842, 1.254927105635588],
            [14.293336067373616, 1.195282153024735],
            [14.320554118717126, 1.106109096548821],
            [14.425554052451133, 0.960553994739399],
            [14.461945091017441, 0.932636054478749],
            [14.485691190417839, 0.917218046458672],
            [14.473609179870778, 0.830835996109869],
            [14.346800204565625, 0.621526965103399],
            [14.26930012176058, 0.545691025359574],
            [14.199445172863051, 0.53583608649248],
            [14.172218069064314, 0.545836032284797],
            [14.140973183785974, 0.551245042057147],
            [14.099163244801588, 0.54389109546662],
            [14.073891136104919, 0.522782110450493],
            [13.960418104065184, 0.348609011825289],
            [13.887782207324477, 0.218745000442055],
            [13.88582721222221, 0.190000101611957],
            [13.911391178788591, 0.112218051583298],
            [13.949100187813684, 0.030835974130738],
            [13.940282258010456, -0.038055055898852],
            [13.900054151797889, -0.096435849869181],
            [13.86208228628837, -0.145281893674735],
            [13.850973247064644, -0.175135885936228],
            [13.849027136779995, -0.202635904503268],
            [13.905209189903246, -0.264999946511864],
            [13.937500136700777, -0.236663916919611],
            [13.978473226469617, -0.237635882414637],
            [14.07013604347182, -0.251808842533578],
            [14.131945035850407, -0.281526880325174],
            [14.15166329548768, -0.332781883109945],
            [14.166109170374739, -0.374164009755077],
            [14.177082255128653, -0.421946054768625],
            [14.19250026314873, -0.444717841741138],
            [14.235282167628043, -0.438608943051833],
            [14.322773311408071, -0.436945973457185],
            [14.413336087434345, -0.476808963966405],
            [14.456663318535647, -0.518608844666886],
            [14.483609125663293, -0.548054973518575],
            [14.519582242535876, -0.613335917840459],
            [14.505973216864163, -0.676255009478766],
            [14.495000132110533, -0.700835778799828],
            [14.472918175771156, -0.735972884647396],
            [14.428745042998941, -0.92694599949219],
            [14.420273285797833, -0.995281979891871],
            [14.426391236942635, -1.046946019552934],
            [14.454445131673111, -1.127217830107696],
            [14.481945150240136, -1.212781974342477],
            [14.482500199869946, -1.259999917270619],
            [14.476945177343993, -1.433335831404392],
            [14.464718159871609, -1.550555071259836],
            [14.438054152329698, -1.568890987421412],
            [14.404445067435176, -1.597781899005398],
            [14.414300173940262, -1.855282011901508],
            [14.428054038899205, -1.884581960361501],
            [14.41597320181873, -1.905281908501436],
            [14.381663222178673, -1.925281967724146],
            [14.371354151796254, -1.928617965197347],
            [14.35777328931934, -1.928608912741936],
            [14.320000242553704, -1.937363978271208],
            [14.258891139092071, -1.973681926641561],
            [14.253818076000044, -2.018472973317571],
            [14.268536195102996, -2.036455005526165],
            [14.253609198972299, -2.126390982831253],
            [14.221391174732616, -2.292781987250606],
            [14.199163205639621, -2.35958190007004],
            [14.181109089063483, -2.371663910616917],
            [14.153891205358065, -2.395555016942737],
            [14.11860909258499, -2.465281890359265],
            [14.110836050834479, -2.4930549913325],
            [14.082218221656859, -2.496108853944008],
            [13.916391151684735, -2.490135909724515],
            [13.870554043489562, -2.470899944797367],
            [13.864445144800158, -2.423055035509861],
            [13.878891187325308, -2.314717930836181],
            [13.809445107665994, -2.144445937597709],
            [13.764445183962124, -2.090764038560522],
            [13.726945051964691, -2.154163916166496],
            [13.727218134370986, -2.184163921181707],
            [13.692500123683232, -2.220281877341719],
            [13.590273258803421, -2.325835854877226],
            [13.560000171382001, -2.349717908747451],
            [13.504927211690074, -2.399517813137123],
            [13.484654070060884, -2.434654918984691],
            [13.390554130884851, -2.428755064961237],
            [13.330000244691007, -2.402499926657242],
            [13.251663312670701, -2.370835778580911],
            [13.203054141449542, -2.360555039031368],
            [13.031245074802342, -2.333055020464329],
            [13.001509099738115, -2.367672951227817],
            [13.012500121764674, -2.315554947689606],
            [13.016663245445471, -2.27028194157964],
            [13.000973160847508, -2.23194596619507],
            [12.960209115744419, -2.183335789145602],
            [12.931800163594403, -2.190099985027061],
            [12.900836072073361, -2.142781962174567],
            [12.848473149323581, -1.978890944203471],
            [12.845273273958355, -1.954445961714327],
            [12.820000159433107, -1.910690918273701],
            [12.793336151891197, -1.899717833519972],
            [12.742654136008866, -1.88372683859717],
            [12.724791126825664, -1.859026878612724],
            [12.652500229220607, -1.823473024537847],
            [12.588473217617462, -1.825490883913972],
            [12.524509237926168, -1.890590946764803],
            [12.501109143489913, -1.921390920621135],
            [12.463609179130373, -1.900555018011474],
            [12.453800173093015, -1.878054972340593],
            [12.446182196551689, -1.881664052219904],
            [12.436945171588462, -1.901108894174811],
            [12.448054043174011, -2.024446085175143],
            [12.473054075292964, -2.074445981775071],
            [12.502218069283145, -2.133890942175583],
            [12.495554120963845, -2.223755002751204],
            [12.478054048189222, -2.327217863076569],
            [12.052500128917274, -2.416945969182294],
            [12.034718088918936, -2.406664056166363],
            [12.018327109575438, -2.388055057598493],
            [11.999718111007468, -2.366108888090949],
            [11.976245094013365, -2.341108855971896],
            [11.952218200855725, -2.332426880638579],
            [11.801663293788124, -2.372217954418332],
            [11.76333603558291, -2.402499926657242],
            [11.733327145750366, -2.418890906000655],
            [11.682500122942827, -2.379164037599324],
            [11.659163228056514, -2.353890923074076],
            [11.611527195796754, -2.332217835972727],
            [11.573473187635898, -2.336390850299352],
            [11.601663204474164, -2.451663979870133],
            [11.629718105033021, -2.550281932508554],
            [11.646318129042186, -2.614654949075756],
            [11.632500226342898, -2.667499998897597],
            [11.607500194223746, -2.722363913923687],
            [11.590000121449208, -2.743335938641422],
            [11.572773298718772, -2.761945943037873],
            [11.546109123538685, -2.79389088487126],
            [11.544291256372901, -2.81656393302417],
            [11.542773293703561, -2.831663931636896],
            [11.560309241024015, -2.862326945195107],
            [11.583054038268301, -2.844717907678799],
            [11.647191188079518, -2.823372888268779],
            [11.79083605415002, -2.992499913530196],
            [11.804863336791414, -3.015417880894589],
            [11.766109104437277, -3.045490976105654],
            [11.734300117073701, -3.043472949091537],
            [11.715273196812319, -3.055554959638499],
            [11.700691199817186, -3.091945998204707],
            [11.706391229268547, -3.177781883741034],
            [11.76333603558291, -3.197781942963829],
            [11.782500251418753, -3.205835778471496],
            [11.801109082348631, -3.214717913653246],
            [11.962291236843981, -3.297426846382123],
            [11.973609153095367, -3.32618197113419],
            [11.96624514822085, -3.347635787647903],
            [11.931663259641084, -3.371108972280197],
            [11.912773299678008, -3.389999938071767],
            [11.90305414764282, -3.40778197807002],
            [11.840136061832823, -3.568190985814383],
            [11.857082258444024, -3.591945970032214],
            [11.876391145929119, -3.599999973177859],
            [11.921873196704752, -3.627499991744898],
            [11.912082127940209, -3.660835826595132],
            [11.899163268177801, -3.67944599862966],
            [11.876109178705605, -3.705973045873279],
            [11.838927056115409, -3.706717861791645],
            [11.740000146525063, -3.68666399075029],
            [11.653745165828752, -3.638190941637021],
            [11.628336096833692, -3.598608912523019],
            [11.583745042368037, -3.534999990251379],
            [11.556109236968979, -3.514863976558757],
            [11.526663275755368, -3.50666396065931],
            [11.49208222536592, -3.509026819170913],
            [11.30221820395235, -3.633335808028093],
            [11.22687327609782, -3.693335818058515],
            [11.167773147194879, -3.841390906316107],
            [11.141945150677799, -3.918608854259546],
            [11.140663222201454, -3.925282022836114],
            [11.136600018, -3.936168755999958],
            [11.127892903000145, -3.960625508999982],
            [11.067024720000035, -4.04611201199998],
            [10.541247191000082, -4.739743618999981],
            [10.3863567410001, -4.883834958999955],
            [10.338930283000082, -4.92910403899991],
            [10.225266318000138, -5.037306164000029],
            [9.547256736000037, -5.685855564999898],
            [8.849142913000094, -6.369374161999986],
            [8.492903090000112, -6.009532618],
            [8.404986102, -5.901718303],
            [8.090048271000114, -5.586748358000023],
            [7.73386489100011, -5.231397702999985],
            [7.323066103500139, -4.819754267499931],
            [7.321852951000153, -4.818522647999941],
            [7.299698780000142, -4.786995440999945],
            [7.035, -4.492222221999853],
            [7.133611111000135, -4.08694444399984],
            [7.140833333000046, -4.053333333],
            [7.222777778000079, -3.679722221999882],
            [7.28166666700011, -3.271666667],
            [7.373055556000168, -2.384444444],
            [7.378055556000049, -2.309444444],
            [7.363333333000156, -2.074166666999844],
            [7.307777778000144, -1.646944443999843],
            [7.304722222, -1.624166666999827],
            [7.288333332999969, -1.53861111099998],
            [7.280277778000027, -1.505555555999919],
            [7.275, -1.484444443999962],
            [7.473611111000054, -0.880833332999842],
            [7.628333333000171, -0.429166666999947],
            [7.689166667000137, -0.293888889000016],
            [7.841111111000089, -0.001388888999898],
            [8.187500000000171, 0.566666667000064],
            [8.195, 0.623611111000059],
            [9.070555556000102, 0.687777778000154],
            [9.080833333000157, 0.689166667000052],
            [9.251666667000137, 0.710277778],
            [9.269444444000072, 0.707777778000107],
            [9.333333333000098, 0.70305555600001],
            [9.352777778000188, 0.70416666700001],
            [9.375555556000108, 0.712222222000136],
            [9.38472222200005, 0.721666667000065],
            [9.391111111000129, 0.726111111000094],
            [9.412222222000082, 0.729444444000023],
            [9.412361111000024, 0.738055556000106],
            [9.421666667000181, 0.74027777800012],
            [9.440277778000166, 0.749166667],
            [9.470000000000113, 0.765555556000038],
            [9.492777778000118, 0.784166667],
            [9.479444444000023, 0.869722222000036],
            [9.479444444000023, 0.871111111000019],
            [9.473888889000079, 0.88333333300001],
            [9.47250000000011, 0.884722222],
            [9.525277778000145, 0.952222222000017],
            [9.529722221999975, 0.966111110999975],
            [9.538055556000074, 0.972777778000022],
            [9.54833333300013, 0.986944444000045],
            [9.548611111000014, 0.994722222],
            [9.549166667000151, 0.997777778000142],
            [9.548055556000151, 1.010277778000017],
            [9.548888889000182, 1.025],
            [9.560555556000025, 1.022222222000124],
            [9.569444444000084, 1.022222222000124],
            [9.580000495000149, 1.019723275500084],
            [9.597561343000081, 1.034650928000147],
            [9.627697343000079, 1.047207928000034],
            [9.647788343, 1.054741928000169],
            [9.665368343000011, 1.069809927999984],
            [9.708061343000168, 1.074832928],
            [9.733174343000144, 1.074832928],
            [9.765822343000139, 1.067298928000056],
            [9.788424343000088, 1.057252928000096],
            [9.789065884000138, 1.052761632000099],
            [9.790935343, 1.039673928000084],
            [9.792942343000163, 1.029580928000044],
            [9.796481207499994, 1.019850272500022],
            [9.801758866000085, 1.007693369000151],
            [9.803972389080656, 1.002610197259372],
            [9.832463212696553, 1.002053970943351],
            [9.871463202452503, 0.976164115980893],
            [9.892100118680304, 0.951853914495075],
            [9.951509204535171, 0.930154005303493],
            [9.975454122679452, 0.945945008016125],
            [9.99220921189783, 1.001882141927794],
            [10.001109116714417, 1.002218088607961],
            [10.028609135281528, 1.002500055831575],
            [10.226663281948646, 1.002500055831575],
            [10.253891223937899, 1.002500055831575],
            [10.281391242505009, 1.002500055831575],
            [10.308609126210371, 1.002500055831575],
            [10.415045047600529, 1.002500055831575],
            [10.49305407956777, 1.002218088607961],
            [10.520282189195086, 1.002218088607961],
            [10.773054070496585, 1.002500055831575],
            [10.909718152307022, 1.002782190693267],
            [10.969845064351858, 1.003591044351992],
            [11.244445097963904, 1.002218088607961],
            [11.353891128430831, 1.001945006201765],
            [11.350000248965898, 1.448609083956228],
            [11.348609188310974, 1.605554018746602],
            [11.347773177285944, 1.694164146603683],
            [11.346691241220924, 1.740700137887515],
            [11.345836119456692, 1.830553972541253],
            [11.345000108431663, 1.919164100398334],
            [11.344445058801767, 1.9872181135744],
            [11.345000108431663, 2.007782107244438],
            [11.343609215414716, 2.164445074811098],
            [11.339763262950925, 2.168609036682227],
            [11.350554125129236, 2.236391140918755],
            [11.36734508889333, 2.298873200124248],
            [11.579718208433093, 2.315282116740477],
            [11.700000195717536, 2.307845021670204],
            [11.756391125868475, 2.282364203583768],
            [12.229163323344721, 2.283682174042752],
            [12.288891256797342, 2.301664206251445],
            [12.335654062381849, 2.317900120385644],
            [12.481800256004902, 2.300136017660208],
            [12.50180014758962, 2.292636058315892],
            [12.523609189160879, 2.283336001440702],
            [12.595836049025394, 2.258891018951473],
            [12.631109109342958, 2.251391059607229],
            [12.751945140428745, 2.233053969979167],
            [12.864163233921545, 2.240282188021595],
            [13.000000189524258, 2.256109065279773],
            [13.121663346817655, 2.275209075737109],
            [13.171391166839697, 2.282918079747191],
            [13.254582226640622, 2.266109011072089],
            [13.294445049511836, 2.22597310579475],
            [13.296109192573056, 2.171109023130484],
            [13.293891173348413, 2.163609063786069],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "GBR", Country: "United Kingdom" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.79, 61.356666666000251],
            [2.076666667000268, 59.896666666000272],
            [1.711666667000259, 59.289999999000088],
            [1.483333333000303, 58.429999999000302],
            [1.965000000000202, 57.904999999000154],
            [2.613333332999957, 56.594999999000152],
            [3.250000000000227, 56.086666666000269],
            [3.350000044000183, 55.919277769800146],
            [3.4, 55.834999999000161],
            [3.370277747600142, 55.764999925800055],
            [2.89833333300021, 54.621666666000294],
            [2.763333333000332, 54.379999999000177],
            [2.866666667000231, 53.963333332000104],
            [2.95666666700015, 53.668333332000259],
            [2.988333332999957, 53.584999999000274],
            [3.016666667000209, 53.469999999000152],
            [3.056666667000115, 53.301666666000187],
            [3.175000000000239, 52.883333332000291],
            [3.205000000000155, 52.783333332000211],
            [3.183333322500005, 52.621666649000076],
            [3.058333333000178, 52.416666666000253],
            [3.024339698000119, 52.382219782000107],
            [2.933333333000292, 52.289999999000088],
            [2.840000000000146, 52.206666666000046],
            [2.715000000000259, 52.099999999000147],
            [2.703333333000103, 52.088333332000218],
            [2.674544739000225, 52.042484830500143],
            [2.65833333300003, 52.016666666000162],
            [2.650134385000229, 52.008036194000169],
            [2.626666667000222, 51.983333332000257],
            [2.539443826000081, 51.869999806000067],
            [2.48166700000013, 51.805000000000234],
            [2.253333020000184, 51.613056017000133],
            [2.238118570400275, 51.557769027400184],
            [2.237369453000156, 51.556710815000145],
            [2.121666667000341, 51.50388888800029],
            [2.038463131000128, 51.336649781500114],
            [1.955000000000211, 51.240833332000136],
            [1.725277778000304, 51.099444444000142],
            [1.548055556000349, 51.03861111000009],
            [1.356944444000135, 50.94999999900017],
            [1.282777778000195, 50.896388888000217],
            [1.257777778000047, 50.797222221000197],
            [1.123888889000227, 50.643888888000163],
            [0.777500000000202, 50.389444444000162],
            [0.603333333000194, 50.328055555000276],
            [0.037222222000082, 50.23666666600019],
            [-0.258333332999882, 50.220277777000149],
            [-0.499999999999829, 50.12472222100007],
            [-0.55015602799989, 50.116342181000221],
            [-0.574279432999901, 50.121066397000277],
            [-0.592065607999814, 50.121842311000194],
            [-0.829620073999877, 50.131018650000328],
            [-0.858329771999877, 50.132031048000044],
            [-0.974917897499893, 50.135911665500089],
            [-1.037535318999915, 50.136387159000151],
            [-1.142362885999944, 50.136913774000277],
            [-1.210809148999829, 50.135935630000176],
            [-1.226239440999962, 50.136203840000178],
            [-1.226999232999844, 50.136225938000223],
            [-1.300991853999847, 50.138808830000244],
            [-1.309592133999956, 50.139073654000128],
            [-1.422207466999851, 50.142592381000213],
            [-1.450784135999868, 50.143723624000245],
            [-1.603671093999822, 50.151810176000197],
            [-1.613085953999814, 50.154274697000119],
            [-1.661159834999978, 50.166760891000081],
            [-1.679624834999856, 50.165877082000236],
            [-1.684494097999902, 50.165636527000288],
            [-1.75325131399984, 50.162117709000142],
            [-1.793056919999913, 50.161388292000083],
            [-1.835329675999844, 50.160667645000046],
            [-1.844582392999882, 50.160142154000198],
            [-1.875387309999894, 50.158517405000339],
            [-2.023477161799917, 50.149766386000181],
            [-2.056533072999969, 50.151863695000088],
            [-2.10715027, 50.15441735200028],
            [-2.134633587999815, 50.15028879100015],
            [-2.14349516499982, 50.148919388000309],
            [-2.148783909999906, 50.148115620000226],
            [-2.250490767999906, 50.137077547000217],
            [-2.329844002999891, 50.128443233000098],
            [-2.397755761999974, 50.121895029000086],
            [-2.447233222999841, 50.117289186000107],
            [-2.458509772999946, 50.116851049000218],
            [-2.862297206999813, 50.099815552000109],
            [-2.879693976999874, 50.086837276000097],
            [-2.908857854999894, 50.064080895000075],
            [-2.962487500999771, 50.022639336000168],
            [-3.10477643299987, 49.87509727000014],
            [-3.11593667499983, 49.863430527000219],
            [-3.346309418999851, 49.743162565000148],
            [-3.395828897999934, 49.718316353000148],
            [-3.722486027999878, 49.552034500000104],
            [-3.797443113999861, 49.545113615000048],
            [-3.912329709999824, 49.540402479000193],
            [-4.233159236999825, 49.5181093220001],
            [-4.285992630999829, 49.487854840000068],
            [-4.333156808999831, 49.470791696000106],
            [-4.48380823999986, 49.415926328000182],
            [-4.628923669999836, 49.356580547000192],
            [-4.797073745999853, 49.32468990600006],
            [-5.133924774999912, 49.24220222300022],
            [-5.206017705999898, 49.226789617000236],
            [-5.211029557999893, 49.225709731000222],
            [-5.271167467999874, 49.21631442800026],
            [-5.448952391999853, 49.210493028000144],
            [-5.659433284999864, 49.202891334000185],
            [-5.84825015999985, 49.131332171000054],
            [-6.003998069999852, 49.068842899000117],
            [-6.070621518999872, 49.041706527000144],
            [-6.291766413999937, 48.954605464000167],
            [-6.343817828999875, 48.934151474000146],
            [-6.402510076999931, 48.91121807400026],
            [-6.969112592999863, 48.687024925000173],
            [-7.60064018699984, 48.424016437000148],
            [-7.602144013999975, 48.423383093000155],
            [-8.74469570299982, 47.932006641000044],
            [-9.855565682999838, 47.423337390000256],
            [-9.972990096999922, 47.493910885000162],
            [-9.977235353999902, 47.496462335500098],
            [-9.979397454999969, 47.497761840000237],
            [-10.197975954999833, 47.645432840000069],
            [-10.403701954999917, 47.801683090000154],
            [-10.595755965999956, 47.965975351000282],
            [-10.67108626799984, 48.038836177000178],
            [-10.773343672999829, 48.137741382000229],
            [-10.800046937999866, 48.16712240600026],
            [-10.818945454999778, 48.187916090000158],
            [-10.61100759999988, 48.388526988000194],
            [-10.30536280299998, 48.683402176000186],
            [-8.952984917999913, 49.845291151000197],
            [-8.952063096499899, 49.846059539500231],
            [-8.951509453999961, 49.846521342000301],
            [-8.806739092999862, 49.966485891000161],
            [-8.661194735999914, 50.087091950000229],
            [-8.50239000299996, 50.20119664300023],
            [-8.43921377599986, 50.240790375000074],
            [-8.430892510999854, 50.245973453000317],
            [-8.375639115999888, 50.280389209000191],
            [-8.315932202999875, 50.317579092000187],
            [-8.229312202999949, 50.365297842000359],
            [-8.167953702999966, 50.398915342000237],
            [-8.049966952999853, 50.467816592000133],
            [-7.977727702999914, 50.505895592000286],
            [-7.971243842999911, 50.50928440100023],
            [-7.967177216999914, 50.511409899000284],
            [-7.730370283999946, 50.635181449000299],
            [-7.373044254999854, 50.822062196000331],
            [-7.28312944299995, 50.853037887000255],
            [-7.26408227, 50.85959964600022],
            [-7.222463681999955, 50.874449642000172],
            [-6.926966943999901, 50.979199386000232],
            [-6.918213443999832, 50.982302387000175],
            [-6.907678720999854, 50.986036809000268],
            [-6.889831201999812, 50.992363593000221],
            [-6.814601701999834, 51.015887343000259],
            [-6.776386201999856, 51.026422593000291],
            [-6.742269201999932, 51.035948843000142],
            [-6.699999951999871, 51.050000093000278],
            [-6.591666700999781, 51.075000093000256],
            [-6.523428996999883, 51.191405440000153],
            [-6.449999999999875, 51.316666669000085],
            [-6.433400501999813, 51.333266167000261],
            [-6.416666700999883, 51.35000009300029],
            [-6.133333450999942, 51.641666594000299],
            [-5.924999948999812, 51.900000093000301],
            [-5.865027951999934, 51.993706338000209],
            [-5.791666700999826, 52.108333344000187],
            [-5.616674575999809, 52.308324344000198],
            [-5.408893699999965, 52.464163094000128],
            [-5.383333449999839, 52.483333344000187],
            [-5.4249999769998, 52.566666631000203],
            [-5.42729615799982, 52.589628258000346],
            [-5.433333449999793, 52.650000094000234],
            [-5.416666699999894, 52.758333345000153],
            [-5.408333449999816, 52.808333345000221],
            [-5.372129107999967, 52.906601942000179],
            [-5.35, 52.966666669000176],
            [-5.295704323999871, 53.129553706000195],
            [-5.291666698999791, 53.141666595000288],
            [-5.305035482999756, 53.185115314000143],
            [-5.324999999999875, 53.249999999000295],
            [-5.321133107499804, 53.271267903000194],
            [-5.308333449999907, 53.341666595000163],
            [-5.314308952999795, 53.479106066500208],
            [-5.316666521999821, 53.533329694000088],
            [-5.294140008999761, 53.595907388000228],
            [-5.265748031999919, 53.674773994000077],
            [-5.241686700499884, 53.741668848500069],
            [-5.262304098999891, 53.743959717000337],
            [-5.349911456999848, 53.7536938665001],
            [-5.54166666999987, 53.774999999000158],
            [-5.614509632499789, 53.783569761500132],
            [-5.683333450999953, 53.791666596000084],
            [-5.813769254999841, 53.824275644000068],
            [-5.85, 53.833333329000141],
            [-5.858079207999936, 53.838719470000171],
            [-5.863957200999891, 53.842638096000258],
            [-5.866355605999843, 53.844237069000314],
            [-5.874999999999829, 53.849999999000147],
            [-5.915806557999872, 53.871988867000198],
            [-5.941284700999915, 53.885717846000261],
            [-5.963625004999813, 53.903231596000182],
            [-5.981993420999856, 53.91763152700014],
            [-6.025201432999893, 53.951504475000263],
            [-6.053138748499805, 53.97667599350001],
            [-6.083524950999958, 54.004053846000147],
            [-6.100934950999829, 54.017243346000214],
            [-6.108789672499881, 54.023192072500251],
            [-6.123558837999781, 54.034452226000155],
            [-6.127955968999856, 54.03772575100021],
            [-6.139645365999968, 54.041651682000236],
            [-6.140594898999836, 54.041970582000147],
            [-6.15058664199978, 54.045288307000305],
            [-6.151462261999825, 54.045581915000071],
            [-6.15769095099995, 54.047670346000302],
            [-6.159619360999784, 54.048692255000219],
            [-6.16045229599996, 54.049133580000046],
            [-6.163081352999882, 54.050498223000261],
            [-6.167523950999964, 54.052804096000159],
            [-6.175409450999894, 54.05691709600012],
            [-6.188603715999932, 54.063804606500184],
            [-6.199476450999896, 54.069460346000028],
            [-6.203236700999867, 54.071491096000216],
            [-6.205846940999834, 54.072624865000307],
            [-6.208134153999936, 54.073618301000067],
            [-6.208952986999918, 54.073965168000086],
            [-6.214266450999901, 54.076215846000139],
            [-6.223396586999854, 54.080102227000282],
            [-6.224318986999947, 54.08049484500026],
            [-6.236454192999929, 54.085791339000082],
            [-6.247394217999897, 54.090803770333537],
            [-6.251149693999821, 54.092530338000358],
            [-6.262970762333111, 54.097966013999951],
            [-6.264471896475243, 54.098666907684731],
            [-6.266972381707717, 54.099829358349325],
            [-6.271526806010229, 54.104018178176375],
            [-6.288608789453349, 54.108609114196497],
            [-6.338890820914855, 54.115554023910818],
            [-6.3536087723798, 54.114236053451862],
            [-6.365208823492878, 54.104018178176375],
            [-6.447499834528458, 54.058327082734934],
            [-6.567499854589016, 54.050827123390548],
            [-6.605836835801881, 54.044444974656983],
            [-6.608054855026523, 54.042218070614979],
            [-6.619999905275108, 54.037218097718835],
            [-6.62875497080438, 54.042218070614979],
            [-6.655554932816301, 54.065273165915585],
            [-6.734726870033398, 54.183609042915407],
            [-6.851108757759334, 54.28971807008071],
            [-6.873054927266878, 54.342082166296791],
            [-6.917217834117025, 54.373600133981256],
            [-6.926945870969519, 54.379164208962834],
            [-6.97444594875958, 54.401664254633715],
            [-7.029445985893744, 54.417218049485598],
            [-7.058336729839652, 54.410000057364982],
            [-7.160836677125474, 54.33666427170715],
            [-7.175554963866603, 54.289164193917259],
            [-7.143472894096874, 54.25651802206221],
            [-7.145836758436928, 54.22527313678404],
            [-7.283890895073796, 54.123464193597556],
            [-7.313336688649429, 54.112500161299351],
            [-7.330281879432505, 54.113054037462746],
            [-7.559445962736447, 54.126936145540753],
            [-7.609999903137805, 54.142491113859137],
            [-7.621808831278486, 54.147500139210592],
            [-7.624863867356453, 54.163673189071005],
            [-7.699717783321546, 54.20250017634487],
            [-7.747781795558694, 54.200273104665087],
            [-7.818055001425705, 54.19916417887174],
            [-7.851390836275925, 54.212218154913558],
            [-7.861945999336172, 54.218045086379448],
            [-7.868336697611142, 54.227635995295714],
            [-7.869445958680387, 54.253054116746227],
            [-7.875763902035715, 54.284991179590861],
            [-7.900281807082649, 54.294164166813459],
            [-7.941390851321387, 54.299436048649397],
            [-8.030281940573786, 54.357218039455176],
            [-8.045281859262332, 54.361382168964496],
            [-8.055836687046394, 54.366391194315952],
            [-8.065555000891436, 54.372773175411737],
            [-8.158054834746963, 54.440273144786204],
            [-8.171663860418676, 54.463882116250431],
            [-8.046946002323466, 54.507218064531116],
            [-7.950836753312387, 54.53221809665024],
            [-7.833608796277588, 54.553891183751261],
            [-7.752226718825057, 54.594445010722495],
            [-7.777499833350134, 54.626936117368189],
            [-7.852363975237324, 54.633882200549181],
            [-7.906390873410089, 54.664445134183211],
            [-7.913890832754362, 54.671109082502426],
            [-7.926390932632842, 54.700544985432316],
            [-7.843054865906595, 54.733609078980919],
            [-7.825763837797638, 54.734164128610644],
            [-7.739163857965707, 54.71054509886261],
            [-7.553336728770887, 54.762773240608965],
            [-7.482836708603315, 54.829936089836906],
            [-7.458054941243574, 54.857773228550684],
            [-7.443890865942137, 54.882500178263342],
            [-7.40639090158254, 54.953327092655798],
            [-7.326390832329537, 55.045273217985994],
            [-7.252497169471212, 55.070601965821254],
            [-7.175308860999962, 55.080651965000072],
            [-7.082862197999816, 55.105094346000158],
            [-7.008649427999842, 55.160271120000289],
            [-6.987708057999896, 55.180942080000136],
            [-6.976979477999805, 55.195017354000356],
            [-6.975105326999937, 55.196327687000121],
            [-6.970588701999816, 55.199485597000262],
            [-6.965423451999811, 55.201272347000156],
            [-6.96233041399995, 55.200881592000258],
            [-6.957973005999861, 55.200331182000184],
            [-6.953132289999814, 55.199015905000351],
            [-6.9515549004999, 55.198587325500057],
            [-6.932466663999719, 55.191497398000251],
            [-6.874759994999806, 55.203934413000297],
            [-6.8733537949999, 55.20423396700005],
            [-6.871066451999866, 55.204721347000259],
            [-6.860184330999914, 55.207610261000184],
            [-6.856008386999974, 55.208718912000165],
            [-6.834082550999938, 55.219149436000237],
            [-6.820995651999908, 55.226853213000197],
            [-6.813219744999913, 55.231397104000166],
            [-6.811107698999876, 55.232470781000302],
            [-6.8071865954999, 55.234486085500123],
            [-6.8059083039999, 55.236139337000282],
            [-6.805546989999812, 55.236641811500135],
            [-6.788416950999931, 55.260241923000194],
            [-6.783219631666611, 55.267410286333529],
            [-6.78256120199984, 55.268471597000257],
            [-6.777084755999851, 55.286965211000279],
            [-6.773269242999845, 55.299850158000311],
            [-6.760107730999891, 55.3451158270002],
            [-6.735964041999978, 55.406577443000231],
            [-6.73460447299982, 55.409634581000034],
            [-6.733723451999936, 55.411615847000178],
            [-6.726916953999876, 55.427580783000053],
            [-6.720762789999867, 55.442015820000279],
            [-6.720046735999858, 55.456686203500112],
            [-6.727060821999885, 55.459022793000315],
            [-6.730000018999874, 55.459999084000174],
            [-6.795000552999852, 55.483333588000164],
            [-6.898333072999776, 55.581665039000143],
            [-6.955179728999838, 55.63405409700016],
            [-6.983333201999926, 55.659999847000222],
            [-7.01091057799988, 55.683104999000193],
            [-7.045000075999951, 55.711666107000099],
            [-7.138333320999834, 55.773334503000171],
            [-7.190038349999952, 55.802340063000258],
            [-7.206666951999921, 55.81166834700025],
            [-7.237581802999841, 55.826523113000235],
            [-7.335000037999976, 55.873332977000359],
            [-7.906022210499771, 55.886277552500189],
            [-7.99666595299982, 55.888332353000067],
            [-8.043332952999833, 55.893333348000056],
            [-8.244999919499833, 55.959999090500105],
            [-8.616666793999912, 56.081665039000029],
            [-8.761666250999838, 56.110000604000163],
            [-9.008333205999918, 56.150001526000153],
            [-9.270000454999945, 56.183334349000233],
            [-9.781666703999861, 56.238334598000051],
            [-10.260579968333161, 56.289586177333433],
            [-11.257799999999861, 56.3847],
            [-11.696399999999869, 56.353300000000331],
            [-11.71, 56.352800000000173],
            [-11.7336, 56.351700000000278],
            [-11.881699999999853, 56.346700000000283],
            [-12.308099999999854, 56.391100000000279],
            [-12.992799999999818, 56.45940000000013],
            [-13.585589700499838, 56.512717832000078],
            [-14.148428124666623, 56.563340931333528],
            [-14.237005391442722, 56.571308252302174],
            [-14.322255389999953, 56.578976436000175],
            [-14.330299958999888, 56.57970009800016],
            [-14.6191999589999, 57.000000099000317],
            [-14.889399958999832, 57.539400099000204],
            [-14.816099958999814, 58.500000090000071],
            [-14.585300000999837, 59.000000000000171],
            [-13.969399999999894, 59.681700000000149],
            [-13.595710536999945, 59.931858348000048],
            [-13.269954065874856, 60.150284488750231],
            [-12.293699999999887, 60.121766667000088],
            [-11.2743, 60.047216667000214],
            [-10.846299999999758, 60.03561666700017],
            [-10.347549999999899, 60.015850000000285],
            [-9.011, 59.940833333000228],
            [-5.403249999999844, 60.312566667000112],
            [-4.944533332999981, 60.351683333000153],
            [-4.737866666999878, 60.401283333000151],
            [-4.309016666999923, 60.795283333000157],
            [-4.233466666999846, 60.863483333000261],
            [-4.17495, 60.916316667000274],
            [-4.0643166669999, 61.045950000000289],
            [-4.040416666999818, 61.074150000000145],
            [-3.99365, 61.127516667000123],
            [-3.798299999999813, 61.360183333000293],
            [-3.19548333299997, 61.868566667000209],
            [-3.055416666999804, 61.987216667000325],
            [-0.795599999999865, 63.677483333000282],
            [-0.488763888999813, 63.887480555000309],
            [-0.429805555999906, 63.840802777000135],
            [-0.30231944399992, 63.736897221000163],
            [-0.183141666999916, 63.636299999000187],
            [0.470141667000235, 63.055752777000293],
            [0.558613889000242, 62.972516666000161],
            [0.641086111000163, 62.891524999000296],
            [0.791025000000161, 62.737863888000163],
            [0.858188889000303, 62.666108333000295],
            [0.912438889000271, 62.60576388800007],
            [0.963422222000247, 62.546469444000138],
            [1.001644444000135, 62.502730555000255],
            [1.03825, 62.45911666600017],
            [1.07385000000022, 62.415744444000154],
            [1.107836111000154, 62.372499999000297],
            [1.141933333000168, 62.327977777000285],
            [1.177961111000229, 62.278869444000236],
            [1.560000000000286, 61.736666666000247],
            [1.79, 61.356666666000251],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "GEO", Country: "Georgia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.451754078810296, 41.897054073996557],
            [46.435827121628023, 41.861800124418195],
            [46.414709251794562, 41.831800119403184],
            [46.350545112255048, 41.773318072042017],
            [46.324782159306807, 41.756454018082152],
            [46.290818184630922, 41.757773162007524],
            [46.256382308804774, 41.764573232434728],
            [46.230463284819024, 41.762473230407167],
            [46.209709189584458, 41.746654064499992],
            [46.198045100731093, 41.724991203320727],
            [46.194427136034193, 41.698045060916925],
            [46.196091279095327, 41.677491125530693],
            [46.216100223135555, 41.624982190027382],
            [46.233327213504054, 41.59859109725339],
            [46.331936113687078, 41.483464148074589],
            [46.502636086902868, 41.396236028416851],
            [46.553036135561484, 41.386936139179753],
            [46.593318221230703, 41.379436012197246],
            [46.62109115456596, 41.365000195593993],
            [46.689636179631378, 41.317345052594945],
            [46.71081825484319, 41.258327066343455],
            [46.697200176716166, 41.211653947123054],
            [46.666654174526542, 41.15054501129957],
            [46.622491267676281, 41.100545114699642],
            [46.514709212632425, 41.048045064013664],
            [46.492763378400952, 41.051800156646763],
            [46.378318213228368, 41.10415402694106],
            [46.359709214660398, 41.116936094043055],
            [46.340827133686247, 41.129991075913452],
            [46.294154182103938, 41.162764149782831],
            [46.274009115955806, 41.178318112273004],
            [46.239991161823269, 41.20470903740862],
            [46.135609141993058, 41.20255405009631],
            [46.091518151872179, 41.178873161902729],
            [46.062200266139342, 41.171927078721822],
            [46.039573318454046, 41.170273161582671],
            [45.996936085624071, 41.173609159055772],
            [45.966100237221752, 41.179153955659771],
            [45.922491206534829, 41.18915406909035],
            [45.895545064131312, 41.197491212926181],
            [45.869709188625308, 41.206091213246239],
            [45.850818222833738, 41.212773098838269],
            [45.804573251228732, 41.230400073627308],
            [45.748045193141508, 41.260273176628104],
            [45.711173200969739, 41.287218145565433],
            [45.712973298500771, 41.311791203535464],
            [45.716927209877582, 41.348591111339786],
            [45.611109202391361, 41.381927113828098],
            [45.341591284326881, 41.460964102403707],
            [45.306654170689484, 41.461100056873519],
            [45.285400178748063, 41.457209177408501],
            [45.260963242886106, 41.449291128732753],
            [45.186100274465417, 41.404982209128789],
            [45.141373265529779, 41.376654058525546],
            [45.051382302939743, 41.316373087100018],
            [45.022945187595013, 41.297053973693025],
            [44.978600225807241, 41.270264237603087],
            [44.86970924497021, 41.216645035201978],
            [44.848873174722456, 41.213054060233489],
            [44.824718206083929, 41.212491131614641],
            [44.728600239893609, 41.212209164391041],
            [44.566236069409086, 41.185264195453712],
            [44.484154103039401, 41.187764181901898],
            [44.429991250396739, 41.192218157623742],
            [44.340545111514615, 41.212491131614641],
            [44.248873242057044, 41.223591118382885],
            [44.172700182166381, 41.196864246566818],
            [44.144709151709975, 41.184991113047616],
            [44.122763317478501, 41.181927024514138],
            [43.992763351625541, 41.164144984515801],
            [43.8924911465717, 41.16540914315631],
            [43.85651819733701, 41.160127035398475],
            [43.833609114789994, 41.145545038403426],
            [43.791373207485123, 41.118664107206556],
            [43.756245154092966, 41.112627125246618],
            [43.734427227704288, 41.113600096569954],
            [43.637500240217236, 41.123044992732545],
            [43.599709256178784, 41.129718161145334],
            [43.570136225312496, 41.13554509261111],
            [43.546391299378485, 41.134991216447688],
            [43.460773175687251, 41.112964245393258],
            [43.474363258257739, 41.137982214785225],
            [43.455482183112082, 41.168045084074308],
            [43.429991306741641, 41.183464265560858],
            [43.378463221550675, 41.201664227252721],
            [43.349709270264981, 41.20193613619243],
            [43.23360012966927, 41.190273220805366],
            [43.126382176710621, 41.32000010425223],
            [43.039718159138289, 41.374164130361279],
            [43.016109187674175, 41.393327005092758],
            [42.89277333777838, 41.506945044057815],
            [42.831018157218267, 41.582418047393148],
            [42.681391294472775, 41.594718155061472],
            [42.651663366035365, 41.592764165787614],
            [42.60416328824553, 41.584718041630893],
            [42.590936142083621, 41.578753982228804],
            [42.557909264185213, 41.501800064236264],
            [42.48332708952168, 41.43832709643452],
            [42.472209165480791, 41.433327123538263],
            [42.455273194791317, 41.43470913173762],
            [42.366936149340432, 41.460273098303887],
            [42.311936112206268, 41.476664245285477],
            [42.28013617729826, 41.489573214401972],
            [42.17470926941516, 41.50750009368754],
            [42.097773288695436, 41.50750009368754],
            [41.979436070591333, 41.51804503082586],
            [41.958463375321372, 41.516800150562901],
            [41.910127118868246, 41.488254070476486],
            [41.883045189632725, 41.458318103201734],
            [41.830127217253192, 41.430127080534973],
            [41.759718224554348, 41.468600183855742],
            [41.724709194187511, 41.492491122543569],
            [41.56915414658576, 41.51804503082586],
            [41.531554552112283, 41.523873066036387],
            [41.533121056313149, 41.525921520074675],
            [41.275833538000114, 41.594722391],
            [40.692499538000135, 41.950000391000046],
            [40.433333538000198, 42.031111390999953],
            [39.826944537000116, 42.13916639100016],
            [39.003611536000079, 42.337500391000034],
            [38.973374679571549, 42.343123259285775],
            [39.16997768350015, 42.50840830900016],
            [39.670920241000175, 42.925424066000161],
            [39.699983638000134, 42.947894377000139],
            [39.700584624000129, 42.948359028000127],
            [39.943608480000222, 43.13549623800013],
            [39.945232750000201, 43.13675807400007],
            [39.952210340000335, 43.142178712000046],
            [39.96227832300022, 43.179499847000088],
            [39.966339649000105, 43.194554829000069],
            [39.975638806000092, 43.229025992000103],
            [39.982947116000076, 43.266261862000121],
            [39.985253825000115, 43.278014552000158],
            [39.990445292000032, 43.304465099000126],
            [39.993612713000147, 43.31899934000019],
            [39.995230023000062, 43.326420637000055],
            [39.999930800000215, 43.347990941000077],
            [40.001199732000231, 43.359882018000107],
            [40.002766805000221, 43.377831644000068],
            [40.002976785148149, 43.37926166885201],
            [40.009163273528344, 43.411936058608532],
            [40.021100277149998, 43.4441540828483],
            [40.081236074012139, 43.55097322485274],
            [40.098463232018759, 43.562354005377998],
            [40.126654087047399, 43.572218164338594],
            [40.169991208794585, 43.581244971169482],
            [40.211373335439561, 43.584718096578982],
            [40.253391145623283, 43.582518014627524],
            [40.29471811934522, 43.576100158986108],
            [40.32443615713683, 43.569709125435011],
            [40.352700269999417, 43.5594360972364],
            [40.488882224737807, 43.51777317683424],
            [40.518591210074106, 43.511654052222937],
            [40.543054129835951, 43.508609074428861],
            [40.577209211904773, 43.512291076865935],
            [40.608182188243319, 43.528600081196075],
            [40.643109243596854, 43.54388213474634],
            [40.680400163290557, 43.546244993258213],
            [40.695973236519734, 43.54301812816469],
            [40.718882319066807, 43.519573106727009],
            [40.743736170794165, 43.506936046550393],
            [40.810818218475191, 43.486927102510279],
            [40.834163327626612, 43.483327075086194],
            [40.863882203608426, 43.477209123941392],
            [40.890263238098413, 43.465400195800626],
            [40.961100210774561, 43.423609032279572],
            [41.014018183154093, 43.390682234305345],
            [41.0409633197294, 43.376100069672233],
            [41.068882265818416, 43.372909079124426],
            [41.12680021109432, 43.384154072817964],
            [41.16805409462026, 43.387218161351356],
            [41.193045074283845, 43.384718174903284],
            [41.213609235591861, 43.3788731385266],
            [41.435545100853318, 43.29610016805718],
            [41.564991190542941, 43.23220005846845],
            [41.597482297188918, 43.221509108576313],
            [42.031100277890374, 43.187482101988294],
            [42.111173269701482, 43.197282223208433],
            [42.169154079251115, 43.230954004738734],
            [42.189018184004027, 43.236382125250387],
            [42.270818183149999, 43.238045094845134],
            [42.363882286729023, 43.237491218681711],
            [42.379845118456927, 43.239018066168356],
            [42.424154038061062, 43.238464190005132],
            [42.460263277041719, 43.229982206881957],
            [42.483882306789639, 43.219427043821767],
            [42.532973269806774, 43.181927079462227],
            [42.619436121702364, 43.145409138881618],
            [42.645818162020674, 43.144718134781883],
            [42.669991235570109, 43.159291079321449],
            [42.694991267689062, 43.18026410986748],
            [42.769436146778133, 43.185818126565223],
            [42.85520028322307, 43.177764123419408],
            [42.948863357604495, 43.121653987025709],
            [43.010473195963328, 43.063664125020566],
            [43.139291145827173, 42.966791116989967],
            [43.183873315475552, 42.944427025789011],
            [43.207218256988853, 42.934018043120503],
            [43.377763332633521, 42.900536027878374],
            [43.392891159164918, 42.900127158640359],
            [43.447200192199347, 42.889154073886814],
            [43.532491254027804, 42.868318171276869],
            [43.560545148758223, 42.860827096749958],
            [43.597763313532113, 42.846518014523227],
            [43.621518130111866, 42.83318207125744],
            [43.641173190199112, 42.809991189125],
            [43.670827190250151, 42.791100055695452],
            [43.704718242368386, 42.780818142679422],
            [43.756382282029364, 42.775827054600668],
            [43.829154133239939, 42.7493642127355],
            [43.833945061470246, 42.729227025576321],
            [43.806100211405493, 42.70220913408123],
            [43.762773315580347, 42.673044972452956],
            [43.739663234994538, 42.649573128925326],
            [43.777282222379455, 42.604018155591646],
            [43.911936156164842, 42.583318039813733],
            [44.195200227837262, 42.627053972515043],
            [44.223182205838356, 42.638464257339692],
            [44.24110020030659, 42.655954104192418],
            [44.369427138281168, 42.708045118002403],
            [44.499718123813267, 42.75082702248163],
            [44.527209257563044, 42.756653953947406],
            [44.558045105965192, 42.759718042480799],
            [44.593882268367992, 42.758327149464137],
            [44.629436122442684, 42.752218083136469],
            [44.638263272339572, 42.748809163105605],
            [44.648882305502099, 42.748600118439938],
            [44.678182253962092, 42.741791163195145],
            [44.705273235653209, 42.727209166200183],
            [44.750963325266156, 42.692909077205869],
            [44.76554515462314, 42.67053610118738],
            [44.808327226740602, 42.665264219351585],
            [44.859573177069905, 42.746791136091488],
            [44.89374519058336, 42.761664152765533],
            [44.931100148017578, 42.761109103135638],
            [44.954991254343184, 42.75040910078819],
            [44.97429125701089, 42.736936029586403],
            [45.009991291477348, 42.714564227034387],
            [45.045827112775754, 42.696091182936428],
            [45.066163286316822, 42.693527158747813],
            [45.091473281216025, 42.697418038212746],
            [45.120273332969333, 42.706936024571277],
            [45.143045119941831, 42.708600167632326],
            [45.165118191463847, 42.703327112329859],
            [45.212209232377603, 42.67610017616893],
            [45.241936154986632, 42.650827061643881],
            [45.319427185336195, 42.578044984511266],
            [45.33360919791059, 42.558736097026284],
            [45.342209198230677, 42.540682148088123],
            [45.367636204498666, 42.527209076886308],
            [45.432209213276195, 42.53749115754043],
            [45.488182221733695, 42.547636110258125],
            [45.526927233994428, 42.550818048350536],
            [45.552773335422359, 42.550264172187113],
            [45.576100172024695, 42.546100042677992],
            [45.700536230534254, 42.516109090118178],
            [45.727627212225201, 42.504854038140749],
            [45.750827146813123, 42.487753949786722],
            [45.760273216442187, 42.477909069203534],
            [45.757627217240412, 42.465264130037738],
            [45.707500250987977, 42.356100066794411],
            [45.689709158534214, 42.319153978598493],
            [45.650682179050051, 42.251936144085434],
            [45.637973202143797, 42.220191194462316],
            [45.6551271023163, 42.19999114302918],
            [45.986927087376188, 42.028600165713655],
            [46.054154141982593, 42.024991085834344],
            [46.239227235165771, 42.000964192676705],
            [46.400118202344032, 41.938045101038412],
            [46.425827175835622, 41.922627093018249],
            [46.446382284688212, 41.904427131326472],
            [46.451754078810296, 41.897054073996557],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "GGY", Country: "Guernsy" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.70801403899992, 49.117599799000175],
            [-2.711401107999905, 49.115105329000187],
            [-2.987415709999823, 49.19445537100006],
            [-2.999517563999916, 49.197763126000098],
            [-3.233668866999835, 49.261189340000101],
            [-3.237402420999842, 49.262560743000165],
            [-3.439984776999893, 49.378361266000127],
            [-3.722486027999878, 49.552034500000104],
            [-3.395828897999934, 49.718316353000148],
            [-3.346309418999851, 49.743162565000148],
            [-3.11593667499983, 49.863430527000219],
            [-3.10477643299987, 49.87509727000014],
            [-2.962487500999771, 50.022639336000168],
            [-2.908857854999894, 50.064080895000075],
            [-2.879693976999874, 50.086837276000097],
            [-2.862297206999813, 50.099815552000109],
            [-2.458509772999946, 50.116851049000218],
            [-2.447233222999841, 50.117289186000107],
            [-2.397755761999974, 50.121895029000086],
            [-2.329844002999891, 50.128443233000098],
            [-2.250490767999906, 50.137077547000217],
            [-2.148783909999906, 50.148115620000226],
            [-2.14349516499982, 50.148919388000309],
            [-2.134633587999815, 50.15028879100015],
            [-2.10715027, 50.15441735200028],
            [-2.056533072999969, 50.151863695000088],
            [-2.023477161799917, 50.149766386000181],
            [-2.040969946999951, 49.882150066000179],
            [-2.042821055999866, 49.856745008000132],
            [-2.048336847999906, 49.776350424000157],
            [-2.048627744999919, 49.75486945300014],
            [-2.049215243999868, 49.742605624000191],
            [-2.053156314999967, 49.727854836000233],
            [-2.058779354999842, 49.706004322000126],
            [-2.059030731499888, 49.705055196000103],
            [-2.061766507999891, 49.695196007000106],
            [-2.071531393999919, 49.678644962000192],
            [-2.072777396999925, 49.676566572000183],
            [-2.084949410999911, 49.649781100000098],
            [-2.085540692999814, 49.648379908000152],
            [-2.090993417999812, 49.640494769000099],
            [-2.104136766999886, 49.61551785000006],
            [-2.106364384999836, 49.611232315000109],
            [-2.122354309999821, 49.572787724000193],
            [-2.131424915999901, 49.545151437000186],
            [-2.138568161999842, 49.538618073000123],
            [-2.134827655999857, 49.530537424000102],
            [-2.133562717999951, 49.527640929000142],
            [-2.11042659099985, 49.474086965000026],
            [-2.09125404599996, 49.43618688100014],
            [-2.095824964999906, 49.430798168000194],
            [-2.096331724999914, 49.430198410000173],
            [-2.117160340999931, 49.405401800000163],
            [-2.152999984999951, 49.388617643000089],
            [-2.156005966999885, 49.38723909800018],
            [-2.169737434999888, 49.381009427000151],
            [-2.171969862999958, 49.379916622000053],
            [-2.192125675999876, 49.370157559000177],
            [-2.226501550999842, 49.359574255000098],
            [-2.23766818099989, 49.3561620320001],
            [-2.264708542999927, 49.344622299000036],
            [-2.310937404999891, 49.327727793000179],
            [-2.336400135999952, 49.318615049000073],
            [-2.367996087999927, 49.307853276000174],
            [-2.422026799999912, 49.289105041000141],
            [-2.466787281999871, 49.273896199000148],
            [-2.491957197999909, 49.264571914000044],
            [-2.513224104999892, 49.248908222000068],
            [-2.536307657999885, 49.232386917000042],
            [-2.573775986999919, 49.203257632000145],
            [-2.577988497999968, 49.200840817000085],
            [-2.609021421999898, 49.181336011000099],
            [-2.609980103999931, 49.180734097000027],
            [-2.611024744999952, 49.180080407000105],
            [-2.653824574999874, 49.153331685000069],
            [-2.70801403899992, 49.117599799000175],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "GHA", Country: "Ghana" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.14976390686283, 11.138536104918373],
            [-0.144863846252662, 11.124300112887298],
            [-0.099163865993773, 11.09319118207894],
            [-0.077217864124293, 11.091664166954288],
            [-0.043508866943597, 11.102044986427998],
            [-0.005136681737326, 11.09883605860729],
            [0.031318059293255, 11.077153919050531],
            [0.034718094506815, 11.021391128725355],
            [0.032218108058629, 10.990000063055305],
            [-0.020281942627463, 10.858609036547321],
            [-0.059445882409761, 10.782218047173586],
            [-0.082054892822327, 10.702600025068065],
            [-0.083608897675305, 10.698609065678966],
            [-0.078754937532949, 10.650836073121098],
            [-0.063754851206397, 10.631245050774396],
            [-0.00221776776732, 10.608327083409904],
            [0.037845047314249, 10.590418141397251],
            [0.120600248148833, 10.521673124121378],
            [0.228891252354799, 10.409444972344772],
            [0.285136169752121, 10.41437319614964],
            [0.303336131443871, 10.389445080760055],
            [0.3665271320221, 10.254164180615533],
            [0.357782124776719, 10.19694495079051],
            [0.349718063346984, 10.115273194763304],
            [0.355136125574745, 10.093473205647641],
            [0.372500243879671, 10.082773203300022],
            [0.395691126012167, 10.067564072307619],
            [0.40022707674737, 10.0304910820971],
            [0.356182187094021, 10.030282205069426],
            [0.324445116459913, 9.762218044295778],
            [0.259309179063536, 9.664927114571768],
            [0.223191222903523, 9.550136112072934],
            [0.217218111045952, 9.460282109781204],
            [0.22930917404841, 9.431018035866856],
            [0.248609176716002, 9.422782145421763],
            [0.28208213950262, 9.420000024111985],
            [0.336318082341137, 9.444926966035098],
            [0.355282138328562, 9.485135961508462],
            [0.422991152368866, 9.496182136458046],
            [0.462782226148818, 9.488336004511666],
            [0.501245103547774, 9.472500074798077],
            [0.55062708624385, 9.408891152526436],
            [0.552782241194336, 9.367364186594074],
            [0.527500241851584, 9.20639107676449],
            [0.519445065239552, 9.188053987136243],
            [0.501109149077877, 9.157500105957794],
            [0.455282266804574, 9.060273213974227],
            [0.448682188587725, 9.032217978139201],
            [0.513473294486545, 8.919864097814667],
            [0.520553991032784, 8.88874494108434],
            [0.512782122748746, 8.844445073935688],
            [0.511173132610537, 8.842491084662015],
            [0.488682139395252, 8.79582701789721],
            [0.382736056428058, 8.760753949789901],
            [0.388473133891551, 8.738473174706755],
            [0.412363234388977, 8.690554001756823],
            [0.470073140827196, 8.595554013815132],
            [0.538336031031122, 8.555554063007605],
            [0.632218208362161, 8.49347316168911],
            [0.649863288062278, 8.473054007306402],
            [0.698891219167194, 8.388609014786837],
            [0.726245057342425, 8.323744986330354],
            [0.728609089320599, 8.295209132165837],
            [0.704445068226676, 8.273053917992627],
            [0.666945103867334, 8.253745030507531],
            [0.638336159507048, 8.237500063917835],
            [0.611663267147634, 8.216109111678179],
            [0.597500197674492, 8.094718030962596],
            [0.622927203942282, 7.896727083845519],
            [0.59319122887797, 7.701873147819782],
            [0.521245162770583, 7.585418002259914],
            [0.514163292757758, 7.557782196861055],
            [0.512782122748746, 7.531109136863549],
            [0.510554045240383, 7.460553963773009],
            [0.536527048682757, 7.415973135229152],
            [0.568263281126463, 7.386664134313747],
            [0.598336041061344, 7.387636099808589],
            [0.642636075848088, 7.397845090266841],
            [0.652954031047727, 7.369645015144499],
            [0.652218099946964, 7.282773126372888],
            [0.640000134930006, 7.205554004962963],
            [0.608745023729767, 7.017218004502638],
            [0.589309234230456, 6.993609033038609],
            [0.525000087765505, 6.947782150765121],
            [0.569027040145954, 6.814718096378769],
            [0.643818091837176, 6.733536011136465],
            [0.65069125246012, 6.708191147519926],
            [0.647218127050621, 6.676664127380064],
            [0.643336132403107, 6.646809129290077],
            [0.659718059291208, 6.610418090723869],
            [0.690836042554963, 6.589164098782518],
            [0.728363331918899, 6.584200168070083],
            [0.743891142870865, 6.557217983482744],
            [0.724673282854639, 6.535644976305562],
            [0.722782157854823, 6.524445077251343],
            [0.72736320322926, 6.501527109886851],
            [0.758891229197417, 6.447782178937715],
            [0.788336184582903, 6.415553928776063],
            [0.904718072308754, 6.329444960833541],
            [1.001527042598894, 6.325282172428984],
            [1.062218056728938, 6.219445054203376],
            [1.099973166221758, 6.160345092938528],
            [1.115000242276636, 6.160836104827894],
            [1.201109210219158, 6.160000093802864],
            [1.202782238097711, 6.117773071315384],
            [1.198890939545862, 6.100545115029192],
            [1.210001501000022, 6.088486358000097],
            [1.219945501000069, 6.064225358000016],
            [1.280774501000138, 5.902975357000173],
            [1.322501501000119, 5.795625357000134],
            [2.099101502000138, 2.911652355000172],
            [1.887680502000109, 2.603202354000089],
            [1.718781502000127, 2.558699354000012],
            [1.492422501000107, 2.499094854000106],
            [1.489318501000156, 2.498313354000103],
            [1.488299501000029, 2.498227354000093],
            [1.485218501000105, 2.497414354000057],
            [1.469718501000159, 2.496114354000056],
            [1.468598501000031, 2.495818354000079],
            [1.466094501000157, 2.495608354000112],
            [1.46360050100003, 2.494950354000025],
            [1.459706501000085, 2.494624354000052],
            [1.457843501000127, 2.494132354000016],
            [1.445090501000095, 2.493062354000131],
            [1.442834501000135, 2.492466354000015],
            [1.436619501000052, 2.49194535400008],
            [1.435420501000152, 2.491628354000142],
            [1.416450001000129, 2.490011354000103],
            [1.414884501000074, 2.489855353999985],
            [1.412162501000012, 2.48913635400001],
            [1.410618501000158, 2.489006354],
            [1.407843501000144, 2.488310354000049],
            [1.401212501000117, 2.486625354],
            [1.397892001000116, 2.48580635400009],
            [1.394770501000011, 2.485010354000039],
            [1.393300501000169, 2.48488735399998],
            [1.390562001000148, 2.484169854000044],
            [1.387297501000148, 2.483312353999963],
            [1.383394501000055, 2.482985354],
            [1.381463501000155, 2.482475353999988],
            [1.378623501000106, 2.482236354000023],
            [1.376465501000155, 2.481666354000112],
            [1.373748500999966, 2.481438354000076],
            [1.371513501000152, 2.480847354000019],
            [1.10065050100016, 2.458041354000073],
            [0.939420501, 2.383439354000046],
            [0.872500501000104, 2.336846354000059],
            [0.870485501000189, 2.335913354000141],
            [0.857524501000171, 2.326888354],
            [0.33030900000017, 2.08253735400001],
            [0.328685500000034, 2.081853353999989],
            [0.0000005, 1.99430435400005],
            [-0.175359499999871, 1.947595354000057],
            [-0.246365499999911, 1.914568354000053],
            [-0.307228499999951, 1.87202235400008],
            [-0.833703500999889, 1.626819853000057],
            [-0.835542501, 1.625989353],
            [-0.836928500999818, 1.625618353000036],
            [-0.839528501000018, 1.624406353000026],
            [-1.400285500999871, 1.47399335300004],
            [-1.424103501, 1.471896353000091],
            [-1.475039500999856, 1.458209353000044],
            [-1.508691500999987, 1.455243353000043],
            [-1.509918500999987, 1.454914353000092],
            [-2.088186501999928, 1.40370835300007],
            [-2.088921001999864, 1.403729853000073],
            [-2.667530501999892, 1.453326352999966],
            [-3.228575502999973, 1.602255353],
            [-3.651457502999847, 1.797943353999983],
            [-3.65364750299986, 1.798127354000016],
            [-3.658859502999888, 1.799504354000092],
            [-3.661610502999878, 1.79973535400002],
            [-3.664523502999856, 1.800505354000137],
            [-3.665592502999914, 1.800595354000123],
            [-3.668550502999807, 1.80137285400005],
            [-3.671592502999971, 1.802173354000033],
            [-3.672552502999906, 1.802253354000172],
            [-3.789113593333155, 1.824909233666801],
            [-3.722407837999896, 2.146261110000125],
            [-3.659801898999888, 2.458616979000098],
            [-3.41273375699987, 3.694130067000145],
            [-3.335079334999875, 4.071877875000226],
            [-3.167588726999895, 4.794998138000125],
            [-3.103940776612859, 5.085230471131922],
            [-3.103045859244389, 5.085017991063893],
            [-3.102854570152886, 5.090381701005597],
            [-3.102272829087525, 5.109545117377493],
            [-3.041663738507197, 5.107218157119235],
            [-2.975554738647304, 5.083054244253219],
            [-2.928126938489527, 5.100218107067093],
            [-2.753608841111571, 5.100835983504567],
            [-2.734445966380179, 5.112782039581717],
            [-2.729308865547637, 5.141391151579896],
            [-2.744163944948895, 5.173053958551762],
            [-2.762636821409046, 5.204164230464585],
            [-2.783608846126782, 5.280282137432209],
            [-2.768890894661723, 5.350554002194656],
            [-2.741663958500794, 5.446391174627948],
            [-2.764445971395304, 5.579164209335289],
            [-2.797499839022009, 5.615554074435011],
            [-2.839999776277523, 5.629445067330337],
            [-2.879999894723227, 5.624445094434179],
            [-2.899999786307944, 5.616944967451772],
            [-2.930972930284554, 5.612009032296143],
            [-2.960281931200058, 5.627217995650554],
            [-3.013608772817577, 5.707500032127214],
            [-3.006390780696876, 5.857782192064761],
            [-3.070281837830208, 5.991944946322462],
            [-3.08860886917455, 6.111664172625979],
            [-3.094717767863955, 6.145273089882593],
            [-3.147081864080121, 6.247918044093922],
            [-3.168036789715359, 6.256308999748143],
            [-3.167217877772941, 6.269582078739603],
            [-3.169163820419499, 6.292773128510291],
            [-3.207217828580355, 6.446944994446199],
            [-3.231945951759599, 6.542773114424079],
            [-3.248890807266349, 6.613745035741744],
            [-3.241945897551915, 6.642773075261928],
            [-3.206663952416932, 6.695282178403431],
            [-3.208890856458822, 6.753336078149005],
            [-3.224445992415269, 6.818618028299198],
            [-3.118890841413389, 7.001945003407869],
            [-3.096354921196763, 7.050618044731479],
            [-3.023890853471698, 7.073054052661831],
            [-2.973336745432363, 7.262218017520269],
            [-2.948608789891097, 7.431945019412638],
            [-2.923608757772058, 7.595554070160304],
            [-2.854990810148763, 7.741982063368894],
            [-2.839717809054008, 7.756664140288279],
            [-2.784999906781621, 7.853609065048147],
            [-2.775136753649349, 7.942918075993873],
            [-2.660281881048235, 8.023053932078824],
            [-2.600281871017927, 8.121944967123525],
            [-2.5602819202104, 8.164164110621996],
            [-2.487781810301499, 8.197773195516604],
            [-2.495554852052123, 8.249444946528499],
            [-2.559445909185371, 8.644718067028109],
            [-2.57083674799452, 8.713336014651404],
            [-2.580526731006699, 8.768173107587444],
            [-2.584990932650328, 8.781245020902247],
            [-2.611390910241539, 8.7801360951091],
            [-2.617799881065451, 8.784136106953525],
            [-2.598190921445934, 8.816664093973529],
            [-2.616663965544006, 8.914445029758511],
            [-2.659899833900454, 9.014545070520612],
            [-2.729581947953847, 9.032918034694418],
            [-2.767499834006799, 9.063609043809336],
            [-2.760836723877901, 9.121664117021396],
            [-2.713754903057662, 9.199718075989779],
            [-2.687081843060156, 9.225273157738641],
            [-2.667781840392564, 9.38277314215874],
            [-2.674717865289495, 9.465973086777254],
            [-2.685563880390816, 9.481818068946168],
            [-2.689163907814816, 9.487500161124629],
            [-2.714036702643199, 9.521191053394418],
            [-2.745072878531772, 9.544100135941491],
            [-2.768954932402096, 9.569718081964226],
            [-2.769581898761459, 9.60083606522808],
            [-2.76139076767933, 9.627364118300278],
            [-2.744663841655665, 9.65303604377965],
            [-2.751526776356911, 9.656391151992054],
            [-2.785072829339384, 9.688191086900133],
            [-2.794163841548908, 9.732364219672363],
            [-2.763190865210362, 9.800554019680163],
            [-2.7772178125756, 10.008054068338268],
            [-2.795417774267349, 10.055000102326787],
            [-2.794445976410515, 10.163054066310366],
            [-2.771390881109909, 10.21694501001322],
            [-2.790554929307575, 10.270827068898839],
            [-2.833890877588317, 10.365553974434235],
            [-2.891945950800277, 10.519445046613015],
            [-2.916390933289506, 10.598054055211392],
            [-2.916108798427814, 10.655282169853834],
            [-2.927526794603381, 10.708144989310412],
            [-2.906945869488936, 10.737082168999819],
            [-2.836945913666113, 10.885827087890831],
            [-2.833608742726511, 10.957500071592023],
            [-2.834045942797417, 11.002009151044319],
            [-2.734999842543516, 11.001944945665798],
            [-2.299999853642589, 11.001109102278846],
            [-1.991390918353488, 11.000273091253817],
            [-1.728608865337605, 11.000273091253817],
            [-1.570836804339621, 11.000273091253817],
            [-1.560181896631207, 11.023564220948586],
            [-1.419672868185472, 11.026473076634687],
            [-1.3727818194821, 10.999718041624007],
            [-1.091945985166234, 11.005973120705193],
            [-0.986945883793965, 11.014445045544363],
            [-0.902217750584498, 11.006664124804914],
            [-0.784445975670195, 10.99805406620095],
            [-0.679836806263211, 10.997800094533972],
            [-0.678608857444573, 10.988609002400281],
            [-0.666108757566008, 10.956809067492301],
            [-0.618472892944339, 10.913891040905099],
            [-0.364163938234213, 11.084718083773382],
            [-0.28687290009492, 11.155691178557348],
            [-0.176945916022419, 11.144718093803618],
            [-0.14976390686283, 11.138536104918373],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "GIB", Country: "Gibraltar" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-5.2929985049999, 36.012500385000138],
            [-5.308594955999922, 36.009871962000076],
            [-5.33113950500001, 36.011662385000065],
            [-5.346497504999832, 36.034827385000071],
            [-5.366565504999812, 36.064692386],
            [-5.37438050499992, 36.075592386000025],
            [-5.374840504999895, 36.076240386000094],
            [-5.383856504999812, 36.089307386000073],
            [-5.385524504999978, 36.091522386000022],
            [-5.39052450499986, 36.100060386000095],
            [-5.391213504999854, 36.106194386000126],
            [-5.39153350499987, 36.109310386000104],
            [-5.394549504999929, 36.11724438600011],
            [-5.39535050499984, 36.119726386000124],
            [-5.395507004999928, 36.125414886000058],
            [-5.395633505, 36.126895386000072],
            [-5.395803504999947, 36.128345385999964],
            [-5.397686504999854, 36.137942386000176],
            [-5.398193504999881, 36.139993386000143],
            [-5.398767504999967, 36.142043386000026],
            [-5.399453504999968, 36.14411238600006],
            [-5.392023004999857, 36.147220886000056],
            [-5.383403504999904, 36.150094886000076],
            [-5.377449504999845, 36.151460385999982],
            [-5.374149504999821, 36.152374386000034],
            [-5.373405504999823, 36.152540386000098],
            [-5.372259504999846, 36.152699386],
            [-5.36608850499988, 36.155515386000033],
            [-5.360944504999878, 36.159484385999988],
            [-5.356681504999841, 36.162864385999953],
            [-5.355799858110487, 36.163309087789045],
            [-5.334508774380652, 36.162563683546708],
            [-5.333165504999954, 36.162423386000157],
            [-5.259245504999853, 36.153696386],
            [-5.141062505000036, 36.140383385999982],
            [-5.13728950499987, 36.140117386],
            [-5.10271150499986, 36.136117386000066],
            [-5.041955504999891, 36.119768386000132],
            [-4.892374504999907, 36.084418386000024],
            [-5.004708504999854, 36.065071386000014],
            [-5.08594950499986, 36.050489386],
            [-5.09641750499992, 36.048552386000139],
            [-5.10271550499985, 36.04735338600004],
            [-5.154386504999877, 36.037216386000111],
            [-5.199224504999961, 36.029211385000096],
            [-5.237386504999847, 36.022219385000128],
            [-5.2929985049999, 36.012500385000138],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "GIN", Country: "Guinea" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-11.373054841352229, 12.407773172880709],
            [-11.383054954782722, 12.38332701692508],
            [-11.450699763444703, 12.244436031072951],
            [-11.481454977937972, 12.227636014853346],
            [-11.49471783100762, 12.206109108143878],
            [-11.494163954844197, 12.176382185534962],
            [-11.459445944156442, 12.136109152321239],
            [-11.449390845441116, 12.130235952749757],
            [-11.413336759383469, 12.12000014020127],
            [-11.374999778170491, 12.104791176847044],
            [-11.340054953182261, 12.046800141375428],
            [-11.319581819343, 12.025691156359386],
            [-11.243054875499183, 11.995209024272228],
            [-11.177426752747039, 12.016944975647448],
            [-11.1704729582151, 12.027182129300314],
            [-11.150690828475462, 12.044027072521061],
            [-11.101108853569059, 12.117500153753284],
            [-11.045690894741455, 12.204582093019042],
            [-10.958199918599519, 12.226108999728595],
            [-10.928890917684015, 12.224445024305552],
            [-10.909726869486235, 12.215827086712579],
            [-10.888545967740754, 12.197636009838234],
            [-10.814717829089062, 12.112773095625244],
            [-10.743336703257285, 11.963264082438755],
            [-10.727990779604681, 11.919164207500543],
            [-10.684999830459617, 11.898054049018114],
            [-10.652745931674303, 11.892608997062041],
            [-10.562781791174501, 11.994445097614559],
            [-10.452917839014162, 12.11708206442168],
            [-10.357226846972651, 12.166382072104568],
            [-10.330908844394799, 12.195764163215813],
            [-10.327090887487628, 12.223536090722661],
            [-10.098754936219677, 12.179027011270364],
            [-9.911945950884103, 12.093327080203849],
            [-9.86521784637884, 12.054809049881854],
            [-9.757917750768939, 12.027773221113947],
            [-9.701945915777912, 12.029164114130708],
            [-9.667217846806352, 12.081800119286697],
            [-9.670281935339744, 12.111109120202102],
            [-9.664026856258573, 12.131664229054806],
            [-9.644581846665574, 12.153745011927697],
            [-9.6283367124378, 12.165553940068463],
            [-9.580281920294226, 12.188053985739344],
            [-9.39805498616127, 12.254445029320763],
            [-9.37055496759433, 12.250273188460611],
            [-9.3458367350608, 12.252773174908782],
            [-9.31847283860165, 12.268054054992561],
            [-9.301990831789482, 12.312809059484664],
            [-9.294999821607462, 12.362218031909137],
            [-9.3458367350608, 12.40638211222587],
            [-9.400281890203161, 12.452144956758815],
            [-9.35986385006413, 12.488473131050966],
            [-9.311672935812652, 12.504700160367932],
            [-9.253054933981673, 12.499718124744504],
            [-9.153054973143725, 12.485135960111378],
            [-9.084726871733068, 12.437500095489625],
            [-9.056181797475148, 12.435000109041638],
            [-8.979999852767179, 12.392773086554158],
            [-8.948336710519328, 12.354718072564808],
            [-8.943608814200871, 12.334718013342098],
            [-8.95583683750155, 12.315553965144233],
            [-8.977499866319079, 12.27847309594479],
            [-8.980445937655389, 12.22961799968374],
            [-8.96208185829903, 12.195691073019873],
            [-8.936808743773781, 12.191244976287138],
            [-8.796899859597062, 12.00634505322418],
            [-8.779726848685186, 11.925836034808768],
            [-8.792781830555469, 11.85666421102205],
            [-8.831663970752345, 11.661664094604433],
            [-8.773890864763899, 11.63986410548867],
            [-8.737017866763836, 11.638608999303642],
            [-8.705972806057844, 11.655973117608553],
            [-8.694726806535812, 11.632218133390637],
            [-8.689417876687713, 11.602473105870814],
            [-8.672499843271225, 11.556664160870426],
            [-8.656663913557537, 11.520418129229526],
            [-8.638054914989652, 11.497218027003342],
            [-8.604717739034868, 11.475136070664064],
            [-8.57778182255305, 11.470000143297909],
            [-8.547781817537839, 11.480000089090311],
            [-8.533881939825108, 11.493909019258552],
            [-8.516526873975778, 11.434718030524934],
            [-8.495972938589631, 11.420553955223411],
            [-8.46062678807624, 11.419791034394237],
            [-8.39055491552395, 11.392773142899145],
            [-8.362845852291144, 11.375118004915308],
            [-8.356108813776103, 11.314164134300952],
            [-8.368963971074038, 11.287427036563003],
            [-8.393890912997136, 11.279718032552921],
            [-8.431390877356591, 11.280973138737949],
            [-8.451663851347519, 11.291945050025291],
            [-8.477426804295646, 11.288400175524416],
            [-8.609999846792732, 11.127218021028895],
            [-8.679790925587696, 11.002500162933785],
            [-8.671526871947805, 10.958918121975088],
            [-8.583608754018826, 10.972773072686692],
            [-8.511108811748016, 11.005554025545194],
            [-8.458054884898701, 11.050836084110571],
            [-8.346072825799865, 11.053264153829275],
            [-8.289717770194585, 11.007773218236139],
            [-8.28749991860812, 10.849444934141857],
            [-8.321390803088264, 10.760827094933845],
            [-8.281945901910547, 10.549445051628226],
            [-8.269999845833496, 10.502500023468201],
            [-8.218054844777214, 10.425691112400855],
            [-8.186526818808858, 10.416527009995491],
            [-8.14110880577357, 10.423891182507987],
            [-7.976454866973313, 10.328818104370455],
            [-7.942017817680778, 10.242982051196051],
            [-7.973981870253454, 10.165609038043399],
            [-8.004999773593141, 10.105691170664514],
            [-8.019163848894664, 10.089164236851204],
            [-8.041945861789145, 10.073326966033051],
            [-8.065555000891351, 10.061945012041292],
            [-8.101045990692086, 10.053609041671848],
            [-8.147636799622887, 9.983891053072824],
            [-8.1581998416722, 9.927573213117739],
            [-8.129999766549929, 9.886109111459433],
            [-8.104163891043953, 9.864164115418376],
            [-8.104236813601801, 9.814864107735573],
            [-8.111181890954299, 9.80900012825758],
            [-8.114726765455174, 9.805553992576407],
            [-8.147499839324666, 9.620553989589183],
            [-8.145281820100195, 9.557217982085746],
            [-8.143608792221841, 9.533891145483324],
            [-8.131945876834607, 9.496945057287306],
            [-8.109163863940182, 9.464164104428804],
            [-8.064517824189494, 9.418809123305479],
            [-8.055836687046394, 9.403335962362377],
            [-8.019308855820071, 9.388054076450288],
            [-7.96194596017412, 9.38971805187316],
            [-7.877226711781873, 9.351527083413998],
            [-7.917217777772066, 9.235000021124847],
            [-7.91999989908183, 9.204027044786315],
            [-7.903190830406828, 9.179582062297072],
            [-7.797226810166819, 9.125827073064215],
            [-7.736108821887683, 9.091664112006185],
            [-7.736490869035634, 9.072453963340891],
            [-7.810554874443312, 9.069164233973396],
            [-7.898890914065902, 9.01889108732918],
            [-7.914163915160572, 9.002918029679293],
            [-7.928054908055884, 8.97208218127696],
            [-7.936663960831453, 8.933054028326382],
            [-7.956808859341663, 8.826109157774113],
            [-7.956254983178241, 8.804444955490169],
            [-7.940763884962138, 8.7801360951091],
            [-7.910554835281175, 8.767218073536924],
            [-7.883890827739151, 8.775418089436386],
            [-7.79236396520696, 8.755553984683488],
            [-7.750836831636605, 8.717636098630607],
            [-7.673472870939463, 8.615273111642892],
            [-7.675281853287828, 8.54750005986196],
            [-7.674863931594388, 8.437636107701692],
            [-7.665554989901693, 8.415835950947766],
            [-7.653372899430565, 8.383836023829346],
            [-7.697781898958794, 8.370836027244081],
            [-7.748926763535593, 8.382782083321146],
            [-7.760836777428921, 8.415691111660536],
            [-7.784163949307498, 8.454582136674915],
            [-7.817081862464306, 8.486109156814777],
            [-7.94889081066583, 8.501664125133246],
            [-8.188399838897737, 8.499164138685074],
            [-8.241017739142848, 8.446545064973506],
            [-8.234726785516017, 8.315000146722966],
            [-8.231946005310533, 8.274444978647551],
            [-8.228890801594645, 8.240273132772202],
            [-8.174717890668177, 8.197773195516604],
            [-8.109999874965212, 8.174445017809617],
            [-8.063054846805187, 8.163745015462084],
            [-8.020554909549503, 8.179718073111971],
            [-7.999445924533546, 8.153891082423485],
            [-7.951254842643891, 8.03624503605711],
            [-7.946836741467791, 8.018509096526302],
            [-8.01889093412646, 8.030282150121423],
            [-8.053390847693038, 8.032818011115324],
            [-8.050417786628515, 8.018891143674182],
            [-8.055972809154383, 7.976664121186602],
            [-8.0766638724769, 7.949164102619648],
            [-8.119026849434391, 7.86479102682955],
            [-8.111454973360424, 7.841182055365522],
            [-8.080426844099037, 7.805400045885676],
            [-8.094445912475237, 7.782500015794099],
            [-8.184445927520784, 7.621527073602593],
            [-8.186808786032572, 7.600973138216375],
            [-8.186463954534901, 7.573054024489338],
            [-8.218746016515013, 7.545000129758876],
            [-8.285554981789858, 7.582500094118316],
            [-8.398199881793573, 7.618191076129392],
            [-8.420281838132951, 7.604718004927591],
            [-8.469745963480364, 7.561327071361916],
            [-8.486945964120565, 7.57139105489459],
            [-8.550808858059099, 7.615773064694494],
            [-8.555626776017931, 7.650345062628631],
            [-8.551690801913679, 7.691744958908501],
            [-8.573426753288999, 7.692491115931247],
            [-8.660554960660647, 7.69500015483473],
            [-8.674999829719212, 7.681108994301411],
            [-8.707572911378435, 7.646600028279323],
            [-8.722990919398512, 7.56548214841564],
            [-8.71909081984009, 7.560809069744238],
            [-8.712226711672457, 7.55763601646926],
            [-8.708472960144007, 7.515554000907088],
            [-8.788754996620639, 7.410418112703084],
            [-8.834445924424045, 7.309445012903822],
            [-8.844445870216447, 7.27173600387863],
            [-8.911390789961388, 7.253609132382621],
            [-8.972499893422849, 7.251664195564445],
            [-9.036946000185992, 7.228609100263753],
            [-9.099236784360642, 7.20326423664703],
            [-9.109972828891898, 7.193926964121644],
            [-9.098399767507232, 7.230900042046073],
            [-9.206663949622964, 7.349164169954307],
            [-9.241108877904509, 7.382218037580927],
            [-9.324445950459335, 7.426809092046582],
            [-9.422499801012435, 7.425000109698132],
            [-9.461045994529144, 7.387844976836263],
            [-9.485163915155454, 7.361991164057443],
            [-9.487363829469103, 7.370000072564054],
            [-9.457781913785396, 7.441317998845776],
            [-9.420899863329822, 7.476600111618836],
            [-9.384163825627866, 7.560000048447435],
            [-9.366945887714934, 7.611109038478588],
            [-9.377781844532279, 7.641809100049016],
            [-9.376254997045521, 7.683053931119588],
            [-9.358726761076184, 7.7373270896083],
            [-9.353854863660814, 7.742364110516675],
            [-9.359999804533942, 7.750282159192423],
            [-9.410281835995562, 7.837782187789969],
            [-9.437226804932777, 7.901109142837996],
            [-9.431463911207544, 7.955345085676498],
            [-9.417363873646281, 7.974582056432041],
            [-9.407499882323862, 8.018818053478327],
            [-9.462781886681654, 8.165553997810449],
            [-9.483654837303618, 8.346926983645361],
            [-9.639163952075904, 8.471109070488225],
            [-9.669863846008155, 8.490691040379431],
            [-9.792845979588918, 8.508400157819906],
            [-9.845836706888406, 8.494718041952254],
            [-9.876390923343024, 8.499444932442302],
            [-9.896172885444571, 8.502844967655761],
            [-9.965554927363428, 8.475000117591151],
            [-10.169726857537341, 8.512782049174206],
            [-10.214790818981555, 8.479235996191647],
            [-10.266654850852888, 8.488373108868785],
            [-10.323336800683023, 8.509164084477391],
            [-10.390417842535754, 8.49083604730474],
            [-10.42278187952914, 8.435836010170661],
            [-10.435836693761246, 8.404027022807],
            [-10.492636828426555, 8.348191142285799],
            [-10.529163821462561, 8.321809101967403],
            [-10.554581942913046, 8.308745067641695],
            [-10.695836793105457, 8.298608999741489],
            [-10.693890850458786, 8.361945007244827],
            [-10.619863893063325, 8.543744967228847],
            [-10.576390816846214, 8.599445060918143],
            [-10.536808787732127, 8.619927079574907],
            [-10.508055004084611, 8.717918065854136],
            [-10.583054932803492, 8.824445014712978],
            [-10.593190833065819, 8.866944951968492],
            [-10.601108881741567, 8.953609137179086],
            [-10.595836832267594, 8.98569103931068],
            [-10.577317855339999, 9.04120002560704],
            [-10.571526798419967, 9.059536109406793],
            [-10.611108827533968, 9.073191067908226],
            [-10.678608796908605, 9.077773119110773],
            [-10.721463959221865, 9.077564074445021],
            [-10.739654868458132, 9.091391029599905],
            [-10.723608888250368, 9.159445042775971],
            [-10.689308799256139, 9.206109109540876],
            [-10.67118192776033, 9.227709106446284],
            [-10.657290934864818, 9.302636112607402],
            [-10.699717781924562, 9.346109021186336],
            [-10.750136773684403, 9.389027047773538],
            [-10.83860876777689, 9.479718066918608],
            [-10.909172825684834, 9.60305391681446],
            [-10.979999907715182, 9.727500033607939],
            [-10.997217845628199, 9.75166422234004],
            [-11.023890905625706, 9.781945021112293],
            [-11.051672891416359, 9.809445039679332],
            [-11.104581811340466, 9.853609119996065],
            [-11.17423676802764, 9.975626999432933],
            [-11.214445931138897, 9.997500078744537],
            [-11.458890894526633, 9.997500078744537],
            [-11.481745997617082, 9.997473089016125],
            [-11.569446018424884, 9.9966642353575],
            [-11.576736765465284, 9.997473089016125],
            [-11.894726726814611, 9.996109018089697],
            [-11.900554999384866, 9.972500046625484],
            [-11.897499795668892, 9.935345081401792],
            [-12.105836693542415, 9.880553921295302],
            [-12.109136816469885, 9.880400029552575],
            [-12.134445973178856, 9.875000072235807],
            [-12.159726799054965, 9.87971807790835],
            [-12.219863937021671, 9.911944986965437],
            [-12.230763931579645, 9.935209126931795],
            [-12.456108884509945, 9.888336015501324],
            [-12.492946007964377, 9.863909137922988],
            [-12.585972895893093, 9.648473103316135],
            [-12.584090823348987, 9.63187307930697],
            [-12.616663905008124, 9.606109120530434],
            [-12.631108774066689, 9.572773118042122],
            [-12.664999826184896, 9.476109154677474],
            [-12.679581823179944, 9.419718056888371],
            [-12.797781913347649, 9.29805406140467],
            [-12.818054887338661, 9.286664228423902],
            [-12.856045863587468, 9.284445035732958],
            [-12.927226829570884, 9.289027086935505],
            [-12.948681819550984, 9.277291081352601],
            [-12.960836752655808, 9.234444971494952],
            [-12.976390882783875, 9.169444988568372],
            [-13.004445950980823, 9.10819104581941],
            [-13.031390919918039, 9.086391056703746],
            [-13.093190859841229, 9.047218064465753],
            [-13.113472886287639, 9.043891119448148],
            [-13.140836782746788, 9.053891065240549],
            [-13.182845875751099, 9.086182179676072],
            [-13.263681956029444, 9.062636072485915],
            [-13.290836807822643, 9.042773141199504],
            [-13.295764185385423, 9.032041052674515],
            [-13.297615135999877, 9.030799449750049],
            [-13.31148707899996, 9.025413967000162],
            [-13.314002594999891, 9.023943273000157],
            [-13.338507164999868, 9.013744884000019],
            [-13.355817593999973, 9.00456487300012],
            [-13.361009370999909, 9.003710032000086],
            [-13.385503620999856, 8.998501578000173],
            [-13.396353745999818, 8.996767841000164],
            [-13.426998134999963, 8.99047142000002],
            [-13.507585759999813, 8.973275097000055],
            [-13.526008345999884, 8.969494398000052],
            [-13.562370478, 8.96237552400008],
            [-13.782554941, 8.923482943000138],
            [-13.839185905999898, 8.897636752000082],
            [-13.996110064999925, 8.730401945000125],
            [-14.433433031, 8.493706298000077],
            [-14.434936727999883, 8.492891771000188],
            [-14.437230966999891, 8.491650067000123],
            [-14.712664564999869, 8.342682887000038],
            [-14.78053582299998, 8.307726204000062],
            [-15.282124984, 8.05151124300005],
            [-15.302818258999849, 8.040901861000108],
            [-15.397658742999937, 7.991710640000079],
            [-15.78018521899989, 7.829225841000124],
            [-16.559955290999937, 7.495131604],
            [-16.561360835999864, 7.494529396000175],
            [-17.067013201, 7.723735215],
            [-17.549802143999869, 8.052676307000084],
            [-17.968531413999983, 8.459049264000029],
            [-18.256016222999989, 8.855488047000023],
            [-15.570833, 10.666667000000189],
            [-15.341666999999887, 10.666667000000189],
            [-15.149999810999873, 10.833332558000123],
            [-15.141433567999968, 10.842254059000126],
            [-15.136967408999908, 10.846905105000104],
            [-15.126693257999904, 10.859747788000178],
            [-15.113850490999909, 10.87344675100006],
            [-15.100151513999947, 10.890570513000156],
            [-15.087308843999864, 10.904269309000014],
            [-15.073609513999855, 10.91368751300017],
            [-15.070703963999875, 10.915544693000086],
            [-15.057296687999894, 10.924114101],
            [-15.049315940999918, 10.930030530000053],
            [-15.043643513999911, 10.93423551299999],
            [-15.016846206576361, 10.956453844233053],
            [-15.009163843963279, 10.960282192901701],
            [-14.961945901035051, 11.016391155829112],
            [-14.924999812839019, 11.075835948591447],
            [-14.86805500652477, 11.176664209103478],
            [-14.847781864895666, 11.231109028969755],
            [-14.84013673098795, 11.26083595157867],
            [-14.828336687664603, 11.30291813477902],
            [-14.805699849333593, 11.352082187991911],
            [-14.781390821314261, 11.389445024414968],
            [-14.705281799164055, 11.493054065132213],
            [-14.678126779732764, 11.512636035023505],
            [-14.637217895342246, 11.516391127656519],
            [-14.597917833451874, 11.515273149407875],
            [-14.573054929269119, 11.509164083080492],
            [-14.535281882503568, 11.510000094105521],
            [-14.510554932790711, 11.514445017371955],
            [-14.431317784366769, 11.559118046850884],
            [-14.401945919177223, 11.575000077032001],
            [-14.309163950460174, 11.628891188372933],
            [-14.279445912668479, 11.67500003786985],
            [-14.142081941941001, 11.661109044974523],
            [-14.104026760313673, 11.640764154254143],
            [-14.079445990992696, 11.640836070983696],
            [-14.074608794656598, 11.642944957828405],
            [-14.001663940964136, 11.640973198919994],
            [-13.772917779353605, 11.686800081193297],
            [-13.708190878833221, 11.717636097233438],
            [-13.703890794854203, 11.780835982629071],
            [-13.714072963222122, 12.016182054818358],
            [-13.744026867769804, 12.029300068600705],
            [-13.784717822676953, 12.039582149254713],
            [-13.876799902477046, 12.117364199283287],
            [-13.926381877383449, 12.148609084561542],
            [-13.963981921667255, 12.153491040260803],
            [-13.96350884705052, 12.199035955310492],
            [-13.859446010094103, 12.278609050414602],
            [-13.800136836525411, 12.280000111069626],
            [-13.807081913878022, 12.255553955113996],
            [-13.731672948283034, 12.258053941561982],
            [-13.703608827630575, 12.280553987232864],
            [-13.695590866668567, 12.292964233109146],
            [-13.669308906274352, 12.318609001222214],
            [-13.643890784823867, 12.452500014178284],
            [-13.645836727470453, 12.476944996667427],
            [-13.655417745741033, 12.495553995235298],
            [-13.687226733104495, 12.526526971573745],
            [-13.712981807063812, 12.565764169190174],
            [-13.720836823827426, 12.598327024927514],
            [-13.720836823827426, 12.618327084150408],
            [-13.718336837379439, 12.642773072467861],
            [-13.713136704634849, 12.67721800074932],
            [-13.627781940342174, 12.677500135611112],
            [-13.356663916957018, 12.665273118138742],
            [-13.310226832130695, 12.649727034637692],
            [-13.206390809474783, 12.652218136268445],
            [-13.056317861841052, 12.634100149589855],
            [-13.042426868945739, 12.590000107013566],
            [-13.055554941011962, 12.555827087671915],
            [-13.068754929807568, 12.53208216173789],
            [-13.063890911381236, 12.502918000109631],
            [-13.045699834506877, 12.479027061421988],
            [-12.96159984112299, 12.473682089390081],
            [-12.946390877768664, 12.523191141738721],
            [-12.927017784905132, 12.542218062000202],
            [-12.896254859060832, 12.545000015671803],
            [-12.845836705491308, 12.512218056984992],
            [-12.786945956530303, 12.465000114056849],
            [-12.634026849846464, 12.436382117240981],
            [-12.587781878241458, 12.37194506293342],
            [-12.582781905345314, 12.363609092563976],
            [-12.502499868868568, 12.395136112704023],
            [-12.479172864628055, 12.395136112704023],
            [-12.445836694501764, 12.38721806402809],
            [-12.394654949550727, 12.367353959275107],
            [-12.373608828808841, 12.334164137178675],
            [-12.345399868869066, 12.301744947262264],
            [-12.196108785165762, 12.345553970159528],
            [-12.170281794477177, 12.353891113995275],
            [-12.149581846337242, 12.364864198749004],
            [-12.128054939627873, 12.382773140761657],
            [-12.033890795073034, 12.400554007293607],
            [-11.895281944082598, 12.409164233535549],
            [-11.841672967603273, 12.386391105458472],
            [-11.757499884023503, 12.37971810468386],
            [-11.736390899007574, 12.380553948070897],
            [-11.69360882689017, 12.390554061501291],
            [-11.675554877952038, 12.400554007293607],
            [-11.645554872936913, 12.421527037839738],
            [-11.519726747943992, 12.434445059411829],
            [-11.476526754133175, 12.435827067611172],
            [-11.427226746450486, 12.42638217144858],
            [-11.373054841352229, 12.407773172880709],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "GMB", Country: "The Gambia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-20.181979052999964, 13.0575],
            [-20.160961518999926, 13.333956364000073],
            [-20.160285518999956, 13.336549364000035],
            [-20.160033518999882, 13.33986636400013],
            [-20.158280518999874, 13.346587364000143],
            [-20.158159518999895, 13.348187364000097],
            [-20.157799518999866, 13.349566364000154],
            [-20.157265518999907, 13.356596364000154],
            [-20.156475518999912, 13.359626364000121],
            [-20.15632551899995, 13.361606364000167],
            [-20.155433518999956, 13.365025364000175],
            [-20.155373518999966, 13.365817364000065],
            [-20.154512518999951, 13.369119364000071],
            [-20.15445051899988, 13.369928364000131],
            [-20.154023519, 13.371565364000034],
            [-20.152695518999877, 13.389044364000128],
            [-20.148054518999942, 13.406839364000064],
            [-20.147943519, 13.408298364000174],
            [-20.136697518999853, 13.451413364000061],
            [-20.136544518999813, 13.453430364000155],
            [-20.136262518999843, 13.454508364],
            [-20.073611111, 13.593333],
            [-19.957158996999908, 13.593333],
            [-16.648912873999876, 13.593333],
            [-16.581511320999937, 13.592400500000053],
            [-16.572779028999861, 13.592279689],
            [-16.565672874215863, 13.590000050834306],
            [-16.170836786365101, 13.59250003711665],
            [-16.08859992242418, 13.592218069893036],
            [-15.929726814622057, 13.592782171978342],
            [-15.645836782418542, 13.591109144099889],
            [-15.574717842518766, 13.590553926832087],
            [-15.488199837700137, 13.590836061693693],
            [-15.484081808658544, 13.64079103129248],
            [-15.474308844804824, 13.669026980960467],
            [-15.455555006949538, 13.708327042850854],
            [-15.435554947726729, 13.730273212358313],
            [-15.420281946631974, 13.743327020762223],
            [-15.364445898472781, 13.781391087206885],
            [-15.336672965137524, 13.792918048124037],
            [-15.30049080359899, 13.790418061675865],
            [-15.271526801819249, 13.770553956922967],
            [-15.251390788126542, 13.744718081417062],
            [-15.216663892621483, 13.763891182070239],
            [-15.164445976796998, 13.795000112878597],
            [-15.07028183224233, 13.826391178548647],
            [-14.911663869573516, 13.802364117753015],
            [-14.88305492521323, 13.793054002593848],
            [-14.860281797136054, 13.78083603757716],
            [-14.856336770576604, 13.778609133535284],
            [-14.828890899104096, 13.756664137494212],
            [-14.816108832002101, 13.741109001537666],
            [-14.802081884636777, 13.709309066629686],
            [-14.79360878633122, 13.678609005059172],
            [-14.791945984374564, 13.65332700571669],
            [-14.716526792857593, 13.61333610736466],
            [-14.697081950902685, 13.632364201092528],
            [-14.668336716796432, 13.65332700571669],
            [-14.646108747703352, 13.663054036740888],
            [-14.625699819242527, 13.667918055167135],
            [-14.598890804775209, 13.670273202328076],
            [-14.562363979377068, 13.662082071245862],
            [-14.53569980419698, 13.650691064798707],
            [-14.50458182093314, 13.62805405882952],
            [-14.483336713809109, 13.593745085017872],
            [-14.477781858921219, 13.570836002470799],
            [-14.47278188602516, 13.534444963904591],
            [-14.446390793251169, 13.526391128397023],
            [-14.429581892214344, 13.50471804129576],
            [-14.398336839297912, 13.483327089056019],
            [-14.369863849407437, 13.466809040060213],
            [-14.334863871496083, 13.454026972958033],
            [-14.237081929882692, 13.481526991525072],
            [-14.222917854581169, 13.498473188136373],
            [-14.198336749984037, 13.516245002212912],
            [-14.176108780890956, 13.526109161173409],
            [-14.082363899134293, 13.560553921816876],
            [-13.980417828011696, 13.58277317373053],
            [-13.919163885262833, 13.568191176735581],
            [-13.887017777752618, 13.544373160605616],
            [-13.868499806653517, 13.508036101496046],
            [-13.845554849560699, 13.497082127481534],
            [-13.827499894794244, 13.480554020201737],
            [-13.803754968860233, 13.44319118377868],
            [-13.798608815572265, 13.413054050827085],
            [-13.803472833998626, 13.384444938828821],
            [-13.824026769384744, 13.356109076874745],
            [-13.849999772827147, 13.333053981574054],
            [-13.858336749024801, 13.327773214920668],
            [-13.873054868127838, 13.32083601655718],
            [-13.992781805782272, 13.30250010039542],
            [-14.048890936347675, 13.294445091421281],
            [-14.09555500311248, 13.285000027620697],
            [-14.129999763755961, 13.260554039303244],
            [-14.151672850857409, 13.246391137468109],
            [-14.188336804191636, 13.23305402073602],
            [-14.214726723499126, 13.230554034288033],
            [-14.351108838085963, 13.237782084692554],
            [-14.388754982837099, 13.248744943524301],
            [-14.52110875474645, 13.31389110684276],
            [-14.547781814743871, 13.335936015169821],
            [-14.647781943219883, 13.343891111857786],
            [-14.686808755065982, 13.350482137619224],
            [-14.714726862964625, 13.360554000141093],
            [-14.735417758649078, 13.375553918829652],
            [-14.755281863402047, 13.404444998051531],
            [-14.770417736560432, 13.424445057274426],
            [-14.833336828198838, 13.435273135102847],
            [-14.925281947700711, 13.460553960979027],
            [-14.954726735447935, 13.471944967426182],
            [-14.972499890629052, 13.482500130486486],
            [-14.989726880997381, 13.493053952442224],
            [-15.045281800123291, 13.528053930353593],
            [-15.082081875565677, 13.561391106308292],
            [-15.111663958887476, 13.595836034589937],
            [-15.147917869517386, 13.586244958035465],
            [-15.175054951675946, 13.552800158443645],
            [-15.201526845996625, 13.525136022211996],
            [-15.204172845198315, 13.487773185788839],
            [-15.204726721361737, 13.461945021633781],
            [-15.210836793517529, 13.434164209309515],
            [-15.224308858891021, 13.413191178763384],
            [-15.247217773799917, 13.393891176095792],
            [-15.264445937634918, 13.383054045811974],
            [-15.290417767610734, 13.372773138624439],
            [-15.33416392623414, 13.365836107898943],
            [-15.360281936601638, 13.366391157528668],
            [-15.41805487495202, 13.375273125072425],
            [-15.806526751558039, 13.339445015125051],
            [-15.809446000804201, 13.245282044036699],
            [-15.809717742106017, 13.159718067440181],
            [-15.862499927653715, 13.159718067440181],
            [-15.895563853564227, 13.160554078465211],
            [-15.998890927057857, 13.163054064913382],
            [-16.618336707506415, 13.165000175198131],
            [-16.700345918956344, 13.157218080992195],
            [-16.750891670820977, 13.060018367553283],
            [-16.754999999999882, 13.055833],
            [-16.755711879999893, 13.056901140000136],
            [-16.756110979999875, 13.057499970000023],
            [-16.757104433999842, 13.0575],
            [-20.121698394999868, 13.0575],
            [-20.181979052999964, 13.0575],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "GNB", Country: "Guinea-Bissau" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.516945966341837, 12.679717987197478],
            [-14.393054899178026, 12.678891028627959],
            [-14.341754969392014, 12.678891028627959],
            [-14.136946014575017, 12.677364181141115],
            [-13.998608904886112, 12.67610907495623],
            [-13.857499899809341, 12.676391042179716],
            [-13.713136704634792, 12.677218000749406],
            [-13.718336837379411, 12.642773072467833],
            [-13.720836823827369, 12.618327084150508],
            [-13.720836823827369, 12.598327024927528],
            [-13.712981807063784, 12.565764169190174],
            [-13.687226733104467, 12.526526971573844],
            [-13.655417745740976, 12.495553995235298],
            [-13.645836727470453, 12.476944996667442],
            [-13.643890784823839, 12.452500014178383],
            [-13.669308906274296, 12.318609001222228],
            [-13.695590866668567, 12.292964233109217],
            [-13.703608827630546, 12.280553987232963],
            [-13.731672948283006, 12.258053941562082],
            [-13.807081913877994, 12.25555395511401],
            [-13.800136836525382, 12.280000111069683],
            [-13.859446010094075, 12.278609050414616],
            [-13.963508847050491, 12.199035955310592],
            [-13.963981921667198, 12.15349104026086],
            [-13.926381877383449, 12.148609084561599],
            [-13.876799902477046, 12.117364199283259],
            [-13.784717822676953, 12.039582149254727],
            [-13.744026867769776, 12.029300068600776],
            [-13.714072963222122, 12.01618205481833],
            [-13.703890794854203, 11.780835982629128],
            [-13.708190878833193, 11.717636097233537],
            [-13.772917779353577, 11.686800081193383],
            [-14.001663940964079, 11.640973198920051],
            [-14.074608794656569, 11.642944957828377],
            [-14.079445990992667, 11.64083607098371],
            [-14.104026760313673, 11.640764154254214],
            [-14.142081941941001, 11.66110904497458],
            [-14.27944591266845, 11.67500003786995],
            [-14.309163950460118, 11.628891188373018],
            [-14.401945919177194, 11.575000077032087],
            [-14.43131778436674, 11.559118046850983],
            [-14.510554932790683, 11.514445017371941],
            [-14.535281882503511, 11.510000094105521],
            [-14.573054929269063, 11.509164083080464],
            [-14.597917833451845, 11.51527314940796],
            [-14.637217895342246, 11.516391127656618],
            [-14.678126779732736, 11.51263603502349],
            [-14.705281799164027, 11.493054065132185],
            [-14.781390821314233, 11.389445024414954],
            [-14.805699849333564, 11.352082187991982],
            [-14.828336687664603, 11.302918134779077],
            [-14.840136730987922, 11.26083595157877],
            [-14.847781864895637, 11.23110902896974],
            [-14.868055006524742, 11.176664209103535],
            [-14.924999812839019, 11.075835948591532],
            [-14.961945901034994, 11.016391155829183],
            [-15.009163843963279, 10.960282192901786],
            [-15.016846206576361, 10.956453844233124],
            [-15.043643513999882, 10.934235512999976],
            [-15.049315940999918, 10.930030530000124],
            [-15.057296687999894, 10.9241141010001],
            [-15.070703963999847, 10.915544693000186],
            [-15.073609513999827, 10.913687513000184],
            [-15.087308843999836, 10.904269309000028],
            [-15.10015151399989, 10.890570513000227],
            [-15.11385049099988, 10.873446751000074],
            [-15.126693257999875, 10.859747788000163],
            [-15.136967408999908, 10.846905105000076],
            [-15.141433567999911, 10.842254059000197],
            [-15.149999810999873, 10.833332558000222],
            [-15.341666999999859, 10.666667000000245],
            [-15.57083, 10.666667000000245],
            [-18.256016222999961, 8.855488047000108],
            [-18.566627516999858, 8.645977511000069],
            [-18.569028516999822, 8.647610511000153],
            [-18.573758516999931, 8.652213511000184],
            [-18.578757516999929, 8.655613511000126],
            [-18.583490516999859, 8.660217511000212],
            [-18.588486516999922, 8.663616511000043],
            [-18.593221516999847, 8.668222511000124],
            [-18.598215516999971, 8.671618511000077],
            [-18.602953516999889, 8.676227511000093],
            [-18.607944516999908, 8.679621511000221],
            [-18.612685516999932, 8.684232511000175],
            [-18.617673516999844, 8.687623511000083],
            [-18.622416516999976, 8.692237511000087],
            [-18.627401516999896, 8.695626511000057],
            [-18.632148516999905, 8.700241511000115],
            [-18.637130516999832, 8.70362851100009],
            [-19.056224517999965, 9.110238511000205],
            [-19.063197017999926, 9.119796511000175],
            [-19.405732517999866, 9.591826512000182],
            [-19.660846517999914, 10.114874511999972],
            [-19.664082517999873, 10.126211512000054],
            [-19.664466517999927, 10.126998512000171],
            [-19.667703517999939, 10.138336512000024],
            [-19.668087517999879, 10.139121512000145],
            [-19.671324517999835, 10.150461512000106],
            [-19.671707517999948, 10.151245512000173],
            [-19.674945517999845, 10.162586512000246],
            [-19.67532751799979, 10.163368512000091],
            [-19.678566517999911, 10.174711512000215],
            [-19.678948517999856, 10.175492512000176],
            [-19.682187517999864, 10.186836512000127],
            [-19.682568517999925, 10.187615512000093],
            [-19.685809517999814, 10.198961512000096],
            [-19.686189517999821, 10.199739512000122],
            [-19.689430517999881, 10.211086512],
            [-19.689810517999831, 10.211862512000096],
            [-19.693052517999831, 10.223211512000148],
            [-19.693431517999898, 10.22398551200007],
            [-19.696674517999838, 10.235336513],
            [-19.69705251799985, 10.236109512999974],
            [-19.700296517999845, 10.247461513000076],
            [-19.700673518000031, 10.248232513000119],
            [-19.703918517999966, 10.259586513000215],
            [-19.70429451799987, 10.260355513000093],
            [-19.70754051799986, 10.271711513000184],
            [-19.707915517999822, 10.27247851300001],
            [-19.711162517999924, 10.283836513000097],
            [-19.71153751799983, 10.284601513000155],
            [-19.714784517999817, 10.295961513000066],
            [-19.715158517999953, 10.296725513000013],
            [-19.718406517999938, 10.308086512999978],
            [-19.718780517999903, 10.308848513000157],
            [-19.722029517999943, 10.320210513000177],
            [-19.722401517999913, 10.320971513000075],
            [-19.725651517999836, 10.332335513000146],
            [-19.726023517999977, 10.333094513000049],
            [-19.729274517999897, 10.344460513000058],
            [-19.729645517999927, 10.345217513000193],
            [-19.732896517999905, 10.356584513000087],
            [-19.733267517999877, 10.357340513000111],
            [-19.736519517999909, 10.368709513000226],
            [-19.736889517999884, 10.369463513000085],
            [-19.740142517999971, 10.380834513000195],
            [-19.740511517999948, 10.381586513000229],
            [-19.743765517999975, 10.392958513000053],
            [-19.744133517999956, 10.393709512999976],
            [-19.74738851799998, 10.405083512999965],
            [-19.747755517999792, 10.405832513000121],
            [-19.751011517999927, 10.417207513000164],
            [-19.751378517999854, 10.417954513000154],
            [-19.75463551799993, 10.429332513000134],
            [-19.755000517999861, 10.430077513000128],
            [-19.75825851799982, 10.441456513],
            [-19.758623517999865, 10.442200513000103],
            [-19.761882517999823, 10.45358051300019],
            [-19.762245517999929, 10.45432351300019],
            [-19.765505517999884, 10.465705513000159],
            [-19.765868517999934, 10.466445513000053],
            [-19.793657517999918, 10.563189513000111],
            [-19.653161798999975, 10.644304509000165],
            [-16.72887626209473, 12.332641550932181],
            [-16.718055555999911, 12.338888889000202],
            [-16.720089190500175, 12.324558334791277],
            [-16.71777276972864, 12.322427376363237],
            [-16.710281827008259, 12.336664123626633],
            [-16.692781921871699, 12.355826998358111],
            [-16.66333679884815, 12.360836023709624],
            [-16.548608828261649, 12.357218059013064],
            [-16.518608823246439, 12.347636034914103],
            [-16.381672832496349, 12.374718131787802],
            [-16.209163876932223, 12.4608359845476],
            [-16.102217832913311, 12.471391147607847],
            [-16.072363840651974, 12.472218106177365],
            [-16.039999803658588, 12.467218133281222],
            [-16.019172953504153, 12.460554017324],
            [-16.000554902480815, 12.451664170791332],
            [-15.962226806085255, 12.436109034834772],
            [-15.888472931095976, 12.44810907036856],
            [-15.809446000804144, 12.440553958101191],
            [-15.685281851234322, 12.430000136145452],
            [-15.614726845781831, 12.462773210014973],
            [-15.419399835139302, 12.551409154133864],
            [-15.361390862394956, 12.598053942521403],
            [-15.334445893457541, 12.615835982519741],
            [-15.218054953276294, 12.684718127731628],
            [-14.923336843244442, 12.682782075730984],
            [-14.874445872437718, 12.681945058877432],
            [-14.649308790706527, 12.680973093382534],
            [-14.516945966341837, 12.679717987197478],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "GNQ", Country: "Equatorial Guinea" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [6.210992340000303, -4.741263076999928],
              [5.630808339000168, -4.792011076999984],
              [5.630011339000134, -4.791941076999876],
              [5.6292143390001, -4.792011076999984],
              [5.627950339000165, -4.791900077000037],
              [5.626379839000265, -4.791984076999881],
              [5.61582733900002, -4.792851076999852],
              [5.61517333900008, -4.792832076999957],
              [5.034770339000261, -4.742105076999962],
              [4.472404338000217, -4.591436076999855],
              [3.945091337000065, -4.345481076999931],
              [3.468934337000121, -4.011800075999986],
              [3.058361337000179, -3.60057257599999],
              [3.057147337000288, -3.599341075999973],
              [2.72461933600016, -3.123241074999953],
              [2.480041336000113, -2.596643074999974],
              [2.47506433600023, -2.585890074999782],
              [2.325753336000218, -2.024990073999959],
              [2.324647336000055, -2.012086073999967],
              [2.319220336000058, -1.991713073999961],
              [2.269528336000064, -1.413440074],
              [2.320871336000039, -0.835314072999893],
              [2.321610836000161, -0.832312072999912],
              [2.472415336000097, -0.271458073000019],
              [2.718052336000198, 0.254400928000109],
              [3.05115633700018, 0.729706927999985],
              [3.052757337000116, 0.731305928000097],
              [3.054535337000175, 0.733843928000169],
              [3.209152778000032, 0.916527778000102],
              [3.617555556000127, 0.695916667000063],
              [4.492354369000054, 0.228681944000186],
              [5.323055556000043, -0.215],
              [5.893633505000082, -0.591128662000017],
              [6.191861111000208, -0.787722221999957],
              [7.275, -1.484444443999962],
              [7.280277778000141, -1.505555555999933],
              [7.288333333000082, -1.538611110999966],
              [7.304722222000123, -1.624166666999827],
              [7.307777778000172, -1.646944443999757],
              [7.363333333000071, -2.074166666999872],
              [7.378055556000049, -2.309444444],
              [7.373055556000281, -2.384444444],
              [7.281666667000025, -3.271666666999977],
              [7.222777778000193, -3.679722221999896],
              [7.14083333300016, -4.053333333000012],
              [7.133611111000164, -4.086944443999869],
              [7.035, -4.492222221999782],
              [6.773357340000189, -4.59059207699994],
              [6.210992340000303, -4.741263076999928],
            ],
          ],
          [
            [
              [8.602777778000359, 4.122222222000175],
              [8.680833333000123, 4.084444444000269],
              [8.743172326000149, 4.031546212000023],
              [8.796388889000355, 3.986388889000182],
              [8.800876287000222, 3.982832837000103],
              [8.81111111100023, 3.974722222000253],
              [8.828012894000267, 3.964766378000206],
              [8.831388889000266, 3.962777778000145],
              [8.888055556000097, 3.946388889000275],
              [8.924999669000215, 3.913333630000068],
              [8.973611111000309, 3.911388889000193],
              [8.993611111000291, 3.904444444000148],
              [9.052222222000239, 3.852777778000245],
              [9.053611111000123, 3.851666667000245],
              [9.091666667000084, 3.810555556000111],
              [9.101944444000196, 3.800833333000298],
              [9.134722222000107, 3.761111111000105],
              [9.157222222000144, 3.735833333000301],
              [9.168208055999969, 3.721632134000231],
              [9.180000000000291, 3.706388889000152],
              [9.210000000000207, 3.672500000000241],
              [9.212138354000217, 3.670094352000149],
              [9.215714882000157, 3.66426205800019],
              [9.223888889000136, 3.650833333000321],
              [9.241932853000264, 3.615351924000095],
              [9.256944444000226, 3.585833333000267],
              [9.265617240000154, 3.569914912000058],
              [9.2788888890002, 3.545555556000238],
              [9.264166667000154, 3.42555555600012],
              [9.26333334450004, 3.41666679850016],
              [9.25944444400011, 3.365555556000118],
              [9.258338624000089, 3.338601172000267],
              [9.25722222200028, 3.31138888900017],
              [9.257350334000023, 3.309467215000154],
              [9.257777778000218, 3.303055556000174],
              [9.266388889000154, 3.222777778000079],
              [9.272777778000204, 3.182222222000235],
              [9.275, 3.165555556000186],
              [9.30805555600017, 3.034722222000312],
              [9.286944444000142, 2.981666667000297],
              [9.252777778000052, 2.899166667000202],
              [9.234722222000244, 2.865555556000174],
              [9.227381007000247, 2.848791288000086],
              [9.216111111000203, 2.823055556000213],
              [9.214009800000326, 2.81900126100021],
              [9.1688888890003, 2.731944444000135],
              [9.15583333300026, 2.709444444000098],
              [9.111666667000236, 2.629722222000169],
              [9.101388889000191, 2.612777779000169],
              [9.124919620000128, 2.606323084000167],
              [9.64416666700015, 2.463888889000089],
              [9.655223739000348, 2.45952835300028],
              [9.70333333300016, 2.440555556000106],
              [9.766111111000328, 2.402222222000262],
              [9.785312185000237, 2.38186445700012],
              [9.789166667000131, 2.377777778000279],
              [9.7975, 2.354722222000134],
              [9.800000000000239, 2.351388889000248],
              [9.800707327000225, 2.349974234000172],
              [9.801111111000239, 2.349166667000247],
              [9.808347795598991, 2.341576972974281],
              [9.812500000000114, 2.337222222000264],
              [9.811570455766002, 2.343580116684564],
              [9.825836144751463, 2.278609110950754],
              [9.833127059429899, 2.259026973421442],
              [9.896663226781925, 2.209718080921334],
              [9.935273290400914, 2.191391049577078],
              [9.982636072616344, 2.173336094810452],
              [10.020973221467386, 2.168191114988872],
              [10.073891193846919, 2.16778207811285],
              [10.181073272259937, 2.167864220764102],
              [10.305554090132659, 2.16750011088925],
              [10.33278219975989, 2.16750011088925],
              [10.408663234142921, 2.167844942386807],
              [10.421391154150513, 2.16750011088925],
              [10.448609205494108, 2.16750011088925],
              [10.537218159884503, 2.16750011088925],
              [10.564718178451756, 2.16750011088925],
              [10.653327132842321, 2.16750011088925],
              [11.082500190277472, 2.168053987052474],
              [11.225827214578715, 2.168335954276131],
              [11.339763262950839, 2.168609036682199],
              [11.343609215414745, 2.164445074811113],
              [11.345000108431691, 2.007782107244452],
              [11.344445058801796, 1.987218113574386],
              [11.345000108431691, 1.919164100398348],
              [11.345836119456692, 1.830553972541225],
              [11.346691241221038, 1.740700137887586],
              [11.347773177286058, 1.694164146603669],
              [11.348609188311059, 1.605554018746602],
              [11.350000248966012, 1.448609083956285],
              [11.35389112843086, 1.001945006201765],
              [11.244445097963933, 1.00221808860806],
              [10.969845064351887, 1.003591044351992],
              [10.90971815230705, 1.002782190693267],
              [10.773054070496698, 1.002500055831661],
              [10.5202821891952, 1.00221808860806],
              [10.493054079567798, 1.00221808860806],
              [10.415045047600529, 1.002500055831661],
              [10.308609126210456, 1.002500055831661],
              [10.281391242505038, 1.002500055831661],
              [10.253891223938012, 1.002500055831661],
              [10.226663281948561, 1.002500055831661],
              [10.028609135281556, 1.002500055831661],
              [10.001109116714531, 1.00221808860806],
              [9.99220921189783, 1.001882141927808],
              [9.975454122679537, 0.94594500801611],
              [9.951509204535284, 0.930154005303564],
              [9.892100118680389, 0.951853914495075],
              [9.871463202452617, 0.976164115980907],
              [9.832463212696553, 1.002053970943336],
              [9.803972389080684, 1.002610197259344],
              [9.801758866000114, 1.007693369000151],
              [9.796481207499994, 1.019850272500037],
              [9.792942343000277, 1.02958092800003],
              [9.790935343000115, 1.03967392800007],
              [9.789065884000252, 1.052761632000113],
              [9.788424343000202, 1.057252928000082],
              [9.765822343000252, 1.067298928000127],
              [9.733174343000258, 1.074832927999978],
              [9.708061343000168, 1.074832927999978],
              [9.665368343000011, 1.069809928000041],
              [9.647788343000116, 1.054741928000169],
              [9.627697343000079, 1.047207928000091],
              [9.597561343000109, 1.034650928000133],
              [9.580000495000149, 1.019723275500098],
              [9.569444444000112, 1.022222222000096],
              [9.560555556000054, 1.022222222000096],
              [9.548888889000295, 1.025],
              [9.548055556000179, 1.010277777999988],
              [9.549166667000179, 0.997777778000113],
              [9.548611111000014, 0.994722222000064],
              [9.54833333300013, 0.98694444400013],
              [9.538055556000188, 0.972777778000022],
              [9.529722222000089, 0.966111111000032],
              [9.525277778000259, 0.952222222000103],
              [9.472500000000196, 0.884722222],
              [9.473888889000079, 0.88333333300011],
              [9.479444444000137, 0.871111111000118],
              [9.479444444000137, 0.869722222],
              [9.492777778000232, 0.784166667000022],
              [9.470000000000141, 0.765555556000095],
              [9.440277778000279, 0.749166667000054],
              [9.421666667000181, 0.74027777800012],
              [9.412361110999939, 0.73805555600012],
              [9.412222222000082, 0.72944444400008],
              [9.391111111000157, 0.72611111100008],
              [9.38472222200005, 0.721666667000079],
              [9.375555556000108, 0.71222222200015],
              [9.352777778000188, 0.704166666999981],
              [9.333333333000098, 0.703055555999981],
              [9.269444444000101, 0.707777778000093],
              [9.251666667000052, 0.710277777999977],
              [9.080833333000271, 0.689166667000052],
              [9.070555556000102, 0.687777778000168],
              [8.195, 0.623611111000059],
              [8.302777778000177, 1.004166667],
              [8.360763889000282, 1.192402778000087],
              [8.380000000000166, 1.296666667000125],
              [8.402222222000148, 1.403888889000086],
              [8.466111111000146, 1.645833332999985],
              [8.504166667000106, 1.819444444000055],
              [8.520833333000155, 1.9125],
              [8.550138889000152, 2.067111111000145],
              [8.49999170100017, 2.106896310000081],
              [8.365833333000126, 2.213333333000037],
              [8.218109026000036, 2.310879894000095],
              [8.044444443999907, 2.425555556000177],
              [7.889000000000294, 2.526472222000109],
              [7.703611111000271, 2.642777778000038],
              [7.606793754000137, 2.710480842999971],
              [7.431111111000064, 2.833333332999985],
              [7.121513794600162, 3.042152760000135],
              [7.142500000000155, 3.074722222000219],
              [7.376611111000216, 3.253333333000057],
              [7.594638889000294, 3.446250000000191],
              [7.819527778000236, 3.645111111000233],
              [7.819444444000283, 3.710277778000091],
              [8.055192092000311, 3.873851922000256],
              [8.067777778000163, 3.855611111000258],
              [8.08302777800003, 3.865222222000114],
              [8.069579813000132, 3.883834881000155],
              [8.27583333299998, 4.026944444000151],
              [8.324795280000217, 4.060916824000117],
              [8.351388889000134, 4.075000000000102],
              [8.39666666700009, 4.091666667000197],
              [8.438888889000225, 4.09444444400026],
              [8.454757441000083, 4.097353680000083],
              [8.45555552500025, 4.097499996000295],
              [8.491666667000231, 4.111111111000184],
              [8.505555556000218, 4.103333333000023],
              [8.553888889000291, 4.111111111000184],
              [8.575000000000273, 4.120555556000284],
              [8.602777778000359, 4.122222222000175],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "GP-MQ", Country: "Guadeloupe-Martinique" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-57.567327553999917, 16.883496368000181],
            [-57.525667553999938, 16.304314867000073],
            [-57.525659553999901, 16.303710367000122],
            [-57.559410553999896, 15.996800367000048],
            [-57.76918655399993, 15.939990367000121],
            [-58.038043553999898, 15.852211367000109],
            [-58.862110554999958, 15.186530366000113],
            [-58.911964554999884, 15.146046366000121],
            [-59.225107554999909, 14.875281366000138],
            [-59.358898554999882, 14.751886366000178],
            [-59.595488555999964, 14.519196365000084],
            [-59.660557555999844, 14.453959365000173],
            [-59.661152555999763, 14.453362365000203],
            [-59.728672556, 14.385536365000121],
            [-59.86657155599994, 14.246950365000203],
            [-59.962610555999902, 14.151006365000114],
            [-59.985407555999984, 14.128563365000161],
            [-59.995236555999838, 14.118707365000034],
            [-59.999722555999938, 14.114166365000187],
            [-60.061111555999901, 14.122222365000084],
            [-60.154166555999836, 14.133333365000112],
            [-60.176944555999967, 14.13555536500013],
            [-60.679722556999877, 14.204444365000029],
            [-60.736666556999921, 14.219166365000092],
            [-60.891944556999988, 14.243333365000069],
            [-61.003888556999925, 14.272500365000155],
            [-61.076388556999916, 14.252777365000043],
            [-61.188333556999851, 14.230277365000177],
            [-61.278055556999902, 14.207500365000101],
            [-61.399444556999867, 14.187777365000159],
            [-61.716944556999863, 14.17500036500013],
            [-61.77416655799982, 14.170833365000135],
            [-62.2277775579999, 14.154444365000188],
            [-62.777222557999949, 14.086389365000116],
            [-62.81388855900002, 14.080555365000066],
            [-62.81388855900002, 14.488611365000111],
            [-61.283055556999898, 15.030833366000138],
            [-61.15555555699973, 15.080555366000112],
            [-60.99999955699974, 15.125000366000108],
            [-60.899444557000038, 15.164444366000055],
            [-57.874999553999942, 16.230833367000116],
            [-57.884042553999961, 16.276032367000141],
            [-57.894288553999843, 16.320973367000136],
            [-57.905730554, 16.365625367000177],
            [-57.918359553999835, 16.409955367000236],
            [-57.932167553999932, 16.453933367000104],
            [-57.948333553999987, 16.500833367000041],
            [-60.899444557000038, 15.665000367000175],
            [-61.061111556999947, 15.683889367000234],
            [-61.13833355700001, 15.701666367000087],
            [-61.323611556999936, 15.734166367000114],
            [-61.442499557000019, 15.787222367000084],
            [-61.748055557999862, 15.603333366000186],
            [-62.81388855900002, 15.065000366000106],
            [-62.81388855900002, 15.841944367000224],
            [-62.6494445579998, 15.948333367000146],
            [-62.405277557999909, 16.128055367000172],
            [-62.052777557999946, 16.431111367000085],
            [-61.8511115579999, 16.657777367000108],
            [-61.822849557999859, 16.693307366999989],
            [-61.805512557999918, 16.69352036700019],
            [-61.761484557999779, 16.69286736700019],
            [-61.740268557999912, 16.692581367000173],
            [-61.715195556999902, 16.703013367000153],
            [-61.708930557000031, 16.705516367000143],
            [-61.689869556999895, 16.713827368000068],
            [-61.68736455699991, 16.715113368000061],
            [-61.653913556999839, 16.732487368000022],
            [-61.595211556999971, 16.761797368000032],
            [-61.546769556999948, 16.786779368000111],
            [-61.461501556999906, 16.820585368000138],
            [-61.448386556999878, 16.825804368000149],
            [-61.41289255699985, 16.838951368000096],
            [-61.329867556999773, 16.868220368000095],
            [-61.145404556999893, 16.928161368000247],
            [-61.016781556999888, 16.973176368000168],
            [-60.90262455699974, 17.067760368000165],
            [-60.723150556999826, 17.215784368000072],
            [-60.386080555999911, 17.493637368000122],
            [-60.30670955599976, 17.537436368000101],
            [-60.304826555999853, 17.538473368000155],
            [-60.259265555999889, 17.563545368000177],
            [-60.207468555999924, 17.590861368000049],
            [-60.199581555999885, 17.595001368000112],
            [-60.193273555999838, 17.598303368000131],
            [-58.902937554999824, 18.265173369000081],
            [-58.37216855399987, 18.531820369000059],
            [-58.311633553999911, 18.473741369000152],
            [-58.307789053999841, 18.468405369000038],
            [-57.965416553999944, 17.9876083690001],
            [-57.71764955399982, 17.457457368000121],
            [-57.716152553999962, 17.45171536800018],
            [-57.71381555399995, 17.446701368000106],
            [-57.567327553999917, 16.883496368000181],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "GRC", Country: "Greece" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.361100228749166, 41.711054109957686],
            [26.398327110702326, 41.690818016340799],
            [26.519445109011713, 41.633609180075737],
            [26.570273305285724, 41.611382216811066],
            [26.604445151161087, 41.546100099022979],
            [26.636382214005465, 41.413464192251851],
            [26.635763294273289, 41.364718060732386],
            [26.624718125152015, 41.343882158122639],
            [26.607500187238969, 41.330964136550548],
            [26.415273307313726, 41.259435992136687],
            [26.372845119149417, 41.254436019240345],
            [26.325000209861997, 41.23470904242383],
            [26.323891284068651, 41.093036102900001],
            [26.372982247085787, 41.027354000691119],
            [26.360409224649459, 40.953882092925383],
            [26.287491193046947, 40.901927033585295],
            [26.251109206936235, 40.888609027592608],
            [26.213609242576808, 40.877209136327949],
            [26.17332715690759, 40.818600186952651],
            [26.120200139862305, 40.747700182364454],
            [26.090545133982772, 40.736109183706859],
            [26.059509125732177, 40.734264159174771],
            [26.044718295867114, 40.735827162712738],
            [26.03829452400015, 40.733731390000216],
            [25.899544524000248, 40.730976390000151],
            [25.899438524000175, 40.729024390000134],
            [25.899774524000179, 40.72238839000012],
            [25.900679524, 40.715783390000226],
            [25.902151524000118, 40.709236390000129],
            [25.904181524000137, 40.70277839000012],
            [25.906418524000031, 40.697200390000077],
            [26.049914524000144, 40.371418390000102],
            [25.693720524000042, 40.233723389000076],
            [25.686498524000058, 40.230694389000107],
            [25.679175524000243, 40.227140389000041],
            [25.672172524000075, 40.22322538900012],
            [25.665519524000104, 40.218968389000153],
            [25.659244524000144, 40.214386389000097],
            [25.6533745240001, 40.209498388999961],
            [25.64793452400022, 40.204327389000227],
            [25.642948524000047, 40.198894389000102],
            [25.63843752400021, 40.193222389000127],
            [25.634420524, 40.187335389000097],
            [25.630914524000104, 40.181260389000172],
            [25.627935524000179, 40.175022389000063],
            [25.625494524000089, 40.168647388999986],
            [25.623602524000034, 40.16216438900004],
            [25.622267524000165, 40.155599389000074],
            [25.621495524000153, 40.148982389000039],
            [25.621289524000161, 40.142340389000097],
            [25.62164952400019, 40.135702389000116],
            [25.62257352400016, 40.129096389000111],
            [25.624059524000188, 40.122550389000097],
            [25.62609952400004, 40.116093389],
            [25.628683524000166, 40.109753388999977],
            [25.631802524000108, 40.103555389],
            [25.633361524000236, 40.10084638900021],
            [25.698335524000242, 39.992046389000095],
            [25.70040952400015, 39.988726389000206],
            [25.70454252400009, 39.982891389000045],
            [25.709161524000081, 39.977277389000236],
            [25.714247524000086, 39.971907389],
            [25.719778524000219, 39.966804389000103],
            [25.725730524000113, 39.96199038900015],
            [25.73207752400009, 39.95748538900007],
            [25.73879352400013, 39.953309389000069],
            [25.745849524000135, 39.949480389000158],
            [25.753214524000128, 39.946013389000072],
            [25.757572524000125, 39.94419338900012],
            [25.891612524000124, 39.890548389000102],
            [25.890615524000054, 39.886920389000224],
            [25.889261524000119, 39.880357389000224],
            [25.888467524000163, 39.873740389000019],
            [25.888236524000035, 39.867098389000077],
            [25.888570524000158, 39.860458389000101],
            [25.889467524000111, 39.853849389000217],
            [25.89092252400016, 39.847300389000125],
            [25.892636524000125, 39.841675389000116],
            [25.910906524000126, 39.788522389000136],
            [25.928242524000098, 39.71435238900024],
            [25.927582524000115, 39.480612389000228],
            [25.927584524, 39.479822389000233],
            [25.927913524000047, 39.473181389000089],
            [25.928801524000164, 39.466571389000208],
            [25.9302455240001, 39.460019389000067],
            [25.932238524000155, 39.453555389000073],
            [25.93477252400001, 39.447206389000215],
            [25.937836524000147, 39.440999389000197],
            [25.941416524000147, 39.43496038900021],
            [25.945497524000132, 39.4291163890001],
            [25.950061524000091, 39.423491389000091],
            [25.955090524000184, 39.418109389000037],
            [25.960560524000158, 39.412994389000133],
            [25.965531524000141, 39.408879389000177],
            [25.98173252400008, 39.396180389000179],
            [26.142754524000168, 39.405482389000113],
            [26.431800525000142, 39.434207389000065],
            [26.586197525000159, 39.220564388000142],
            [26.674167525000115, 39.121822388000098],
            [26.704688525000165, 39.032056388000044],
            [26.699302525000121, 38.979992388000099],
            [26.64656352500009, 38.909730388000256],
            [26.618532525, 38.809115388000052],
            [26.451229525000088, 38.77268638800011],
            [26.323875525000119, 38.75590538799996],
            [26.320017525000111, 38.755347388000146],
            [26.311752525000088, 38.753829388000142],
            [26.303631524000139, 38.751889388000208],
            [26.295691524000176, 38.749538388000204],
            [26.287964524000046, 38.746784388000052],
            [26.280485524000113, 38.743640388000145],
            [26.273285524000187, 38.740119387999968],
            [26.266395524000131, 38.736237388000106],
            [26.259845524000241, 38.732009388000236],
            [26.25366352400016, 38.727455388000109],
            [26.247875524000165, 38.72259438799999],
            [26.242506524000191, 38.717446388000198],
            [26.237579524000125, 38.712033388000137],
            [26.233115524000056, 38.706380388000113],
            [26.229133524000105, 38.700510388000097],
            [26.225651524000142, 38.694448388000168],
            [26.222682524000135, 38.688221388000088],
            [26.220239524000249, 38.681854387999962],
            [26.218334524000113, 38.675376388000259],
            [26.21697352400011, 38.668815388000056],
            [26.216163524000052, 38.662197387999967],
            [26.215910524000094, 38.656517388000211],
            [26.215779524000084, 38.651550388000103],
            [26.250277524000126, 38.607077388000121],
            [26.279799524000026, 38.573704388000166],
            [26.312452525, 38.536792387999967],
            [26.320970525000092, 38.498460388000225],
            [26.315291525000248, 38.453030388000101],
            [26.242887524, 38.445932388000259],
            [26.213073524, 38.383465388000204],
            [26.223011524000185, 38.311061388000226],
            [26.19461752400008, 38.241496387999959],
            [26.221701523999968, 38.167533387000233],
            [26.326705524999966, 38.10947838700001],
            [26.329229525000187, 38.108115387000211],
            [26.336379525000154, 38.10461138700019],
            [26.343804525000053, 38.101483387000016],
            [26.351471525000193, 38.098746387000219],
            [26.359348525000087, 38.096410387000205],
            [26.3632045250001, 38.095432387000116],
            [26.587574525000036, 38.04132438699996],
            [26.589414525000137, 38.040890387000118],
            [26.918741525000087, 37.96478438700008],
            [27.053961525000233, 37.913859387000045],
            [27.06818752500007, 37.886451387000179],
            [27.160491525000083, 37.790558387000047],
            [27.164928525000022, 37.724055387],
            [27.086703525000161, 37.699610387000163],
            [27.059247525000075, 37.691030387000083],
            [27.043803525000129, 37.667006387000256],
            [27.054099525000112, 37.564047387000258],
            [27.109011525000113, 37.457655387000088],
            [27.153627525000076, 37.311796387000228],
            [27.158775525000095, 37.262032386999962],
            [27.161710525000245, 37.165141387000048],
            [27.162985525000039, 37.060612386000102],
            [27.207601525000229, 36.968830386000107],
            [27.217799525000117, 36.94524738600019],
            [27.287272525000134, 36.938874386000109],
            [27.324877525000119, 36.921027386000119],
            [27.351647525000061, 36.918478386000245],
            [27.393714526000025, 36.905093385999962],
            [27.384790526000103, 36.856652386000107],
            [27.351010525000135, 36.807575386000039],
            [27.247118525000047, 36.725991386000075],
            [27.250233525000084, 36.702990386000167],
            [27.272613525000082, 36.670540386000013],
            [27.298108525000231, 36.633572386000068],
            [27.351478525000147, 36.586100386000098],
            [27.462141526000124, 36.542784386000093],
            [27.468649526000064, 36.542554386000162],
            [27.476886526000129, 36.542697386000086],
            [27.485093526000156, 36.543273386000138],
            [27.493237526000115, 36.544281386000236],
            [27.49973552600008, 36.545406386000067],
            [27.641670526000127, 36.574321386000108],
            [27.725615526000041, 36.644274386000134],
            [27.774830526000159, 36.693490386000164],
            [27.813951526000125, 36.708633386000045],
            [27.895497526000128, 36.69727638600007],
            [27.907992526000015, 36.640854386000129],
            [27.918692526000115, 36.592534386000168],
            [27.930916526000175, 36.537332386000102],
            [27.960676526000128, 36.470373386],
            [28.002097526000199, 36.469589386000123],
            [28.12542952600009, 36.525124386000229],
            [28.207325526000119, 36.562001386],
            [28.281937526000121, 36.574258386000039],
            [28.355671526, 36.573845386000144],
            [28.391167526000146, 36.56786738600006],
            [28.406088526000104, 36.565369385999972],
            [28.442382526000159, 36.560980386000082],
            [28.47759452700015, 36.52745938600016],
            [28.48698152700004, 36.518445386000039],
            [28.488698527000139, 36.516809386000219],
            [28.5069055270001, 36.499336386000081],
            [28.522017527000088, 36.484574386000091],
            [28.534910527000164, 36.472159385999973],
            [28.551841527000164, 36.441673385999977],
            [28.555510527, 36.436572386000151],
            [28.571636527000152, 36.403063385999971],
            [28.640628527000132, 36.258927386000025],
            [28.664124527000155, 36.210076386000182],
            [28.67196552700014, 36.19705838600008],
            [28.677484527000189, 36.188071386000061],
            [28.68563152700014, 36.115603386000132],
            [28.685906527000128, 36.11314138600018],
            [28.698852527000128, 36.056009386000099],
            [28.702965527000089, 36.038032386000211],
            [28.709797527000177, 36.015379385000116],
            [28.724215527000212, 35.957160385000108],
            [28.729932527000102, 35.944295385000217],
            [28.771194527000119, 35.84324938500005],
            [28.84943852700016, 35.424465385000119],
            [28.872636527000026, 35.299706385000022],
            [29.339309527000154, 36.055589386],
            [29.348302527000186, 36.070193386000071],
            [29.355762527000167, 36.082008386000112],
            [29.44705052700013, 36.153764386000105],
            [29.449758527000171, 36.155841386],
            [29.457538527000253, 36.16197238600013],
            [29.466128527000279, 36.169247386000066],
            [29.468661527000251, 36.171939386000233],
            [29.470050527000154, 36.17338038600009],
            [29.480108527000226, 36.185236385999971],
            [29.484807527000072, 36.186093386000195],
            [29.48994852700028, 36.185923386000141],
            [29.493699527000189, 36.185584386000173],
            [29.495066527000148, 36.185509386000192],
            [29.503322527000279, 36.184479386000234],
            [29.506201527000229, 36.184319385999956],
            [29.508418527000032, 36.184071386000042],
            [29.510743527000187, 36.183969386000129],
            [29.515318527, 36.184423386000034],
            [29.529581528000023, 36.172942386000088],
            [29.530695528000166, 36.172018386000204],
            [29.536117528000062, 36.167634386000017],
            [29.544669528000071, 36.160084386000065],
            [29.547388528000141, 36.161385386000248],
            [29.548700528000239, 36.162073386000159],
            [29.551621528000197, 36.16355238600012],
            [29.560953528000113, 36.16839738600008],
            [29.565995528000116, 36.172582386000144],
            [29.566900528000104, 36.173540386],
            [29.56863452800016, 36.175318386000114],
            [29.570295528000173, 36.175300386000131],
            [29.573410528000153, 36.175219386000194],
            [29.577009528000161, 36.175399386000166],
            [29.579738528000092, 36.175399386000166],
            [29.580696528000232, 36.175455386],
            [29.584056528000161, 36.175850386000079],
            [29.586244528000151, 36.175850386000079],
            [29.589196528000087, 36.176138386000204],
            [29.592900528000229, 36.176254386000025],
            [29.598119528000183, 36.176950386000058],
            [29.599035528000144, 36.177008386000253],
            [29.600254528000306, 36.177295386000011],
            [29.603494528000141, 36.177558386000086],
            [29.610115528000136, 36.175650386000157],
            [29.613789528000012, 36.174668386000079],
            [29.614124528000076, 36.172746386000043],
            [29.613318528000121, 36.170976386000135],
            [29.612176528000276, 36.166381386000083],
            [29.609964528000063, 36.158777386000096],
            [29.609103028000192, 36.156681386000159],
            [29.612774528000131, 36.153359386000076],
            [29.613533528000033, 36.151971886000027],
            [29.61565252800014, 36.147991386000101],
            [29.619357528000165, 36.140151386000142],
            [29.622952528000184, 36.13476838600009],
            [29.624550528000071, 36.132792386000105],
            [29.625219028000146, 36.131275386000098],
            [29.624338528000266, 36.128428386000067],
            [29.622360528000144, 36.121757386000112],
            [29.620994528000011, 36.115730385999981],
            [29.620672528000085, 36.114050386000073],
            [29.619695528000165, 36.10972738599996],
            [29.61885752800012, 36.105446386000139],
            [29.620006528000062, 36.085448385999982],
            [30.079112528000138, 35.461876385000139],
            [30.224294528000115, 34.425481384],
            [29.952283712000309, 33.811342376000212],
            [29.560419078000137, 33.76459665900019],
            [29.248916364000166, 33.726132078000049],
            [28.801810830000278, 33.471373332000198],
            [28.320854997000112, 33.417679426000191],
            [28.040581818000106, 33.409767627000079],
            [27.853551587000226, 33.404052401000243],
            [27.802591839000257, 33.392287073000219],
            [27.323792164000253, 33.313749807000093],
            [26.940077396000191, 33.275699685000248],
            [26.252170157000137, 33.262084596000207],
            [26.207209743400085, 33.264417686200161],
            [26.204763524000157, 33.265087383000051],
            [25.851637524000154, 33.327571383000091],
            [25.708281524000114, 33.35327738300019],
            [25.704531524000146, 33.353945383000209],
            [25.700331524000092, 33.354688383000038],
            [25.318942524000192, 33.42127438300021],
            [25.167100523000101, 33.456111383000135],
            [24.962957523000171, 33.466308383000253],
            [24.937563523000222, 33.467548383],
            [24.90523552300013, 33.4678943830002],
            [24.897710523000143, 33.468014383000181],
            [24.812323523000117, 33.469941383000091],
            [24.709839522999971, 33.455169383000182],
            [24.613586523000095, 33.4412543830001],
            [24.426853523000119, 33.451699383000133],
            [24.338800523000145, 33.480569383000173],
            [24.238759523, 33.513406383],
            [24.122833522000121, 33.55172538300009],
            [23.764194522000054, 33.669089383000014],
            [23.507861522000155, 33.75177238300013],
            [23.447236522000082, 33.77192438300024],
            [23.391450522000014, 33.791398383000185],
            [23.375984522000039, 33.796534383000235],
            [23.283653522000122, 33.834629383000092],
            [23.258510522000165, 33.846715382999989],
            [23.169759522000049, 33.889068384000169],
            [23.106959522000153, 33.918544384000072],
            [23.040734521000132, 33.955700384000224],
            [23.033762521000028, 33.95959138400012],
            [22.919439521000186, 34.025544384000128],
            [22.886032521000089, 34.044889384000058],
            [22.821845521, 34.081820384000039],
            [22.764764521000075, 34.114770384000181],
            [22.716742521000072, 34.147458384000103],
            [22.65119952100008, 34.175372384000113],
            [22.189609521000136, 34.360630384000061],
            [22.146065521000196, 34.377933384000144],
            [21.940269520000186, 34.459486384000215],
            [21.713349520000122, 34.577507384000128],
            [21.459722520000184, 34.709269383999967],
            [21.328930520000057, 34.777145384000093],
            [21.208797520000161, 34.792928384000092],
            [20.933132519000139, 34.832302383999988],
            [20.907402519000101, 34.832810384000098],
            [20.741246519000242, 34.83588938400014],
            [20.598834519000206, 34.838413384000077],
            [20.506520519000105, 34.839825384000093],
            [20.447291519000089, 34.846112384000079],
            [20.432004519000088, 34.847725384000128],
            [20.371027519000023, 34.854336384000206],
            [20.344807519000142, 34.856823384000094],
            [20.032847519000057, 34.886869384000093],
            [19.901802519000142, 34.899298384000147],
            [19.58020451800013, 34.933061384000027],
            [19.289639518000143, 34.964760385000105],
            [19.117937518000133, 34.990231385000115],
            [19.032440518000158, 34.993013385000083],
            [19.005842518000065, 34.993955385000234],
            [18.882163518000056, 34.998288385000095],
            [18.61739451699998, 35.009886385000144],
            [18.510448517000157, 35.014544385000065],
            [18.357739499000104, 35.02106467600008],
            [18.345000000000198, 35.569999999000245],
            [18.2616666670001, 36.149999999000116],
            [18.2616666670001, 36.183333332000259],
            [18.295, 36.401666666000224],
            [18.300000000000125, 36.441666666000131],
            [18.310000000000116, 36.749999999000138],
            [18.320000000000164, 36.906666666000106],
            [18.318333333000112, 36.991666666000086],
            [18.283333333000115, 37.354999999000071],
            [18.476666667000131, 37.866666666],
            [18.731666667000241, 38.499999999000167],
            [18.900000000000148, 39.033333332000197],
            [18.926666667000234, 39.288333332000136],
            [18.915, 39.816666666000074],
            [18.935000000000173, 39.873333332],
            [18.958333333000127, 39.961666666000085],
            [18.986794151000083, 40.080423554000092],
            [19.004026518000103, 40.080582389000071],
            [19.008973518000232, 40.080607389],
            [19.014154518, 40.080623389000081],
            [19.015480518000118, 40.080628389000154],
            [19.033238518000189, 40.080622389000197],
            [19.070212518000119, 40.080385389000114],
            [19.091022518000017, 40.079623389000133],
            [19.114508518000122, 40.078527389000229],
            [19.149105018000142, 40.075713389000171],
            [19.184585518000091, 40.072922389000084],
            [19.188281518000196, 40.072577889000101],
            [19.254351518000107, 40.066411389000123],
            [19.275288518000139, 40.063602389000053],
            [19.30919051799998, 40.058817389000112],
            [19.353443518000063, 40.05220138899999],
            [19.381376518000138, 40.046126389000236],
            [19.39107951800014, 40.043943389000091],
            [19.398414518000152, 40.042266389000062],
            [19.406650518000106, 40.04042038900009],
            [19.423413518000189, 40.037825388999963],
            [19.427450518000171, 40.037071389000104],
            [19.428556518000107, 40.036872388999981],
            [19.443751518000113, 40.033992389000062],
            [19.448798518000103, 40.0344103890002],
            [19.465591518000167, 40.035332389000089],
            [19.469245518000093, 40.035191389000161],
            [19.50694951800017, 40.033589389000113],
            [19.520370518000135, 40.03199338900005],
            [19.526073518000203, 40.031289389000065],
            [19.565376518, 40.02348838900015],
            [19.575800518000165, 40.021204389000175],
            [19.623222517999977, 40.011841389000139],
            [19.645234518000137, 39.999770389000233],
            [19.649397518000171, 39.997477389000224],
            [19.651723518000153, 39.996305389000057],
            [19.660424518000013, 39.990784389000126],
            [19.678710518000173, 39.979005389000221],
            [19.68442251800019, 39.975233389000223],
            [19.705026518000125, 39.961951389000106],
            [19.714300518000243, 39.954870389000078],
            [19.717222517999971, 39.952608388999963],
            [19.718330518000101, 39.951764389000132],
            [19.732786518000239, 39.941067389000111],
            [19.749134518, 39.928737389000091],
            [19.750207517999968, 39.919401389000072],
            [19.754038518000044, 39.916239389000097],
            [19.826246518000232, 39.886656389000038],
            [19.829133518000106, 39.885433389000113],
            [19.837031518000231, 39.881974389000064],
            [19.848700518000101, 39.876689389000134],
            [19.856569518000242, 39.873448389000018],
            [19.859197518000087, 39.872353389000196],
            [19.860004518000153, 39.871987389000097],
            [19.871285519000082, 39.866684389000099],
            [19.881708519000142, 39.862096389000087],
            [19.897670519000116, 39.857298389000121],
            [19.906658519000132, 39.854781389000053],
            [19.913700519000145, 39.851472389000079],
            [19.924036519000197, 39.8439443890001],
            [19.925835519000117, 39.842705389000074],
            [19.928423519000091, 39.842028389000106],
            [19.933782519000118, 39.8402463890002],
            [19.94090651900018, 39.837660389000106],
            [19.94498151900018, 39.837421389000227],
            [19.949065519000158, 39.83683238899998],
            [19.96566951900013, 39.826461389000215],
            [19.971188519000066, 39.8189533890001],
            [19.971938519000219, 39.816402389000146],
            [19.97582551900004, 39.802423389000111],
            [19.977042019000066, 39.798998389000062],
            [19.977564519000225, 39.793369389000063],
            [19.97763151900017, 39.791836389000068],
            [19.978058519000058, 39.787239389000021],
            [19.978126519000114, 39.784843389000102],
            [19.977416519000144, 39.784074389000168],
            [19.976820519000142, 39.783465389000227],
            [19.975692519000148, 39.782281389000119],
            [19.973933019000128, 39.780986389000091],
            [19.973430519000118, 39.779935389000187],
            [19.972137519000171, 39.776749389000187],
            [19.971978519000061, 39.775037389000104],
            [19.97136951900012, 39.770114389000085],
            [19.970865519000171, 39.768859889000126],
            [19.968851519000168, 39.764817389000044],
            [19.967577519000088, 39.763345389000094],
            [19.9668575190002, 39.762565389000045],
            [19.965990519000229, 39.761551389000076],
            [19.96370818566669, 39.759619389000093],
            [19.963164519000088, 39.759057389000233],
            [19.960622519000083, 39.756664389000107],
            [19.960468019000075, 39.75565738900012],
            [19.960436519000041, 39.752049389000192],
            [19.959068519000198, 39.747258389000265],
            [19.958156519000227, 39.743555389000122],
            [19.958492519000231, 39.742614389000053],
            [19.959884519000127, 39.73606938900015],
            [19.959960519000106, 39.731887389000164],
            [19.959905519000273, 39.727480389000121],
            [19.95879851900014, 39.726231389000063],
            [19.957817519000059, 39.724720389000097],
            [19.957206519000152, 39.723751389000043],
            [19.955674519000127, 39.721824389000105],
            [19.954727519000187, 39.72073238900019],
            [19.952858019000161, 39.718751388999962],
            [19.952350519000163, 39.718090389000068],
            [19.945886019000142, 39.709995389000071],
            [19.939344185666727, 39.702223389000054],
            [19.938523519000171, 39.701170889000096],
            [19.931642519000064, 39.691177389000117],
            [19.929998519000208, 39.689402389000165],
            [19.928749519000149, 39.687860389000107],
            [19.928245519000114, 39.687203389000118],
            [19.940394519000165, 39.678506389000134],
            [19.952166519000031, 39.66892538900008],
            [19.957914519000099, 39.663986389000073],
            [19.96477151900018, 39.65821838900014],
            [19.969227519000185, 39.654792389],
            [19.985000519000153, 39.643324389000057],
            [19.991238519000092, 39.638635389000243],
            [19.996732519000091, 39.665634389000203],
            [20.002042519000156, 39.675319389000222],
            [20.004749519000114, 39.681396388999971],
            [20.005184519000153, 39.683926389000234],
            [20.005848519000097, 39.684767389000186],
            [20.007040519000157, 39.687237389000089],
            [20.007195519, 39.68868738899998],
            [20.008265519000247, 39.690585389000077],
            [20.010027065953182, 39.691200176089396],
            [20.02458224081073, 39.696664171236151],
            [20.097009092885685, 39.67597310791335],
            [20.141391270323595, 39.656109170798615],
            [20.166663211382257, 39.650273186877442],
            [20.196245127066049, 39.645000131575003],
            [20.220000111283781, 39.647354105269372],
            [20.31809117748719, 39.734091213037729],
            [20.298054070252164, 39.771945061350166],
            [20.294445158010944, 39.807491204073912],
            [20.306127184137296, 39.812118014639807],
            [20.378609189135204, 39.788054073469951],
            [20.404582192577493, 39.801800059440055],
            [20.413327199822959, 39.820135975601644],
            [20.411318225264239, 39.844644995831217],
            [20.369718169136036, 39.891391205247388],
            [20.337218177672753, 39.923191140155538],
            [20.315418188557089, 39.991800202961414],
            [20.430273228796352, 40.063054091502707],
            [20.450000205612866, 40.072491108676289],
            [20.461573266997561, 40.074164136554643],
            [20.479582288934466, 40.065554077950779],
            [20.555827097916506, 40.066382209986799],
            [20.583336168939041, 40.070544998391441],
            [20.60471823636135, 40.075273229985967],
            [20.621391182928278, 40.085273175778283],
            [20.667082278369776, 40.096245087065526],
            [20.721245131012438, 40.225136127125538],
            [20.714300221298004, 40.270100176283663],
            [20.736800266968885, 40.307218093495138],
            [20.758609140902138, 40.311944983985185],
            [20.787218085262339, 40.394718122092584],
            [20.791927206117464, 40.431545019625318],
            [20.833336154852731, 40.46221809146725],
            [20.920136126894988, 40.463600099666664],
            [20.949582255746662, 40.469644960615724],
            [21.042082257240452, 40.564027034653535],
            [21.053327083295841, 40.620691214848748],
            [21.051454063207103, 40.674445030615217],
            [21.026782266417371, 40.709427071253671],
            [20.98389122955831, 40.793053990021107],
            [20.983491245137799, 40.855891106646155],
            [21.121554098953936, 40.85721812956065],
            [21.150836110141086, 40.85750009678425],
            [21.314718075656884, 40.86860913600789],
            [21.349863228131539, 40.873191187210537],
            [21.374582298855387, 40.883191133002853],
            [21.410136152930164, 40.913882142117856],
            [21.52840011320032, 40.907836107702437],
            [21.548336134682586, 40.891109181678772],
            [21.575763230691933, 40.873118097014768],
            [21.600000174343563, 40.872773097879033],
            [21.786245057593931, 40.930826997624607],
            [21.910827128857306, 41.065582184800505],
            [21.916109236615227, 41.095273232863761],
            [21.977082217968899, 41.131800058261902],
            [22.205554123706662, 41.16777317513457],
            [22.259582195345899, 41.170973218137775],
            [22.304718073519467, 41.153327132609363],
            [22.343327131310247, 41.134435999179871],
            [22.442500133578477, 41.121382190775975],
            [22.484718103610447, 41.121382190775975],
            [22.586736091462598, 41.119854002184738],
            [22.737745130045511, 41.156109086281148],
            [22.758054146220161, 41.227773185164821],
            [22.761545041264469, 41.251736040582088],
            [22.762036053153849, 41.3066359977921],
            [22.776800105086409, 41.328054107398074],
            [22.808473305618065, 41.347773205225636],
            [22.926391260924248, 41.343044973631137],
            [22.935600122692591, 41.342127155230813],
            [22.975554140670567, 41.335545014286893],
            [23.094445067300086, 41.320545095598234],
            [23.120000149048963, 41.320827062822033],
            [23.18513608644534, 41.323744970963631],
            [23.207918099339736, 41.333045027838821],
            [23.228327195438737, 41.354091148580792],
            [23.22986326301907, 41.376245021649638],
            [23.287145189479986, 41.40416413537649],
            [23.440554134586705, 41.409436017212542],
            [23.545000192157488, 41.394436098523968],
            [23.598054119006889, 41.380827072852256],
            [23.63347319207827, 41.377773210240761],
            [23.738327113058659, 41.408609058642909],
            [23.796391238726017, 41.436664126839858],
            [23.886391253771649, 41.451945006923651],
            [23.976663345394883, 41.440827082882592],
            [24.066391283862799, 41.485273130423039],
            [24.064027084246447, 41.52172703326319],
            [24.093191245874635, 41.548264138790799],
            [24.157218089839688, 41.541109178582147],
            [24.199991277139759, 41.543782167512248],
            [24.215273163051847, 41.554991119022148],
            [24.25819118963895, 41.569582168472422],
            [24.306245143592321, 41.547500044495237],
            [24.466109159990765, 41.551936082943982],
            [24.565973166358617, 41.496382169646481],
            [24.609300229821912, 41.432564202709173],
            [24.767773185565346, 41.370554044653673],
            [24.809445158423017, 41.352218128492083],
            [24.812500194500814, 41.378327086404184],
            [24.818473306358584, 41.403745040216492],
            [24.901391283753298, 41.41194505611621],
            [25.036509239699683, 41.370436027456591],
            [25.061191262411114, 41.362573131703769],
            [25.163054017415988, 41.317491065348733],
            [25.185836197948561, 41.305273100331974],
            [25.190827118389393, 41.301453969958416],
            [25.241663193652158, 41.253600175853492],
            [25.282354148559591, 41.243045012793104],
            [25.340409221771637, 41.249853968037897],
            [25.503609235643125, 41.289445049607295],
            [25.561245046057195, 41.319373137892953],
            [25.65304515863366, 41.317773200210539],
            [25.814163275388552, 41.336100063916618],
            [25.883882269816155, 41.309436056374594],
            [25.905554015812953, 41.311100199435643],
            [25.983473193777911, 41.320273186658611],
            [26.14000020687476, 41.354718114939985],
            [26.198054106620333, 41.439436022227753],
            [26.182773226536654, 41.540127154803386],
            [26.129782163961096, 41.630618014100222],
            [26.101100129404983, 41.628045105094188],
            [26.068600137941701, 41.660273187617761],
            [26.065963358833301, 41.691654027365914],
            [26.077636164866163, 41.714300085790612],
            [26.138609146219835, 41.739154105155862],
            [26.172500198337957, 41.747773216215208],
            [26.232863312414935, 41.739518047392735],
            [26.271109098520867, 41.712909025135659],
            [26.301936062105796, 41.707909052239515],
            [26.361100228749166, 41.711054109957686],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "GRD", Country: "Grenada" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.82328555700002, 12.0365853630001],
            [-60.843837557, 12.024282363000083],
            [-61.013151556999958, 11.855732363000016],
            [-61.045258556999954, 11.815223363],
            [-61.078844557000025, 11.773011363000037],
            [-61.079837556999848, 11.771728363000022],
            [-61.08430755699996, 11.765910363000032],
            [-61.085507556999943, 11.764319363000112],
            [-61.092200556999956, 11.756403363000075],
            [-61.095834556999989, 11.752132363000101],
            [-61.108128557000043, 11.737532363000014],
            [-61.131381556999855, 11.71262236300015],
            [-61.205726556999934, 11.638175363000158],
            [-61.220886557, 11.624805363000149],
            [-61.264899557000035, 11.584059363000065],
            [-61.370853557000032, 11.486129362999975],
            [-61.388930557000037, 11.469069363000187],
            [-61.422685556999966, 11.434472363000125],
            [-61.54593455700001, 11.399752363000104],
            [-61.612936556999955, 11.380232363000076],
            [-61.661975556999892, 11.370623363000121],
            [-61.689339556999869, 11.365203863000062],
            [-61.70209955699994, 11.363665363000038],
            [-61.72914855699986, 11.360453363000019],
            [-61.735294557, 11.360834363000109],
            [-61.981915557999969, 11.374253363000079],
            [-62.062880557999961, 11.378549363000175],
            [-62.188456557999984, 11.402983363000089],
            [-62.202119557999907, 11.406169363],
            [-62.214677557999863, 11.409446363000072],
            [-62.225851557999903, 11.414001363000011],
            [-62.291454557999913, 11.441311363000125],
            [-62.295227558, 11.443006363000038],
            [-62.367843057999949, 11.59580486300014],
            [-62.564114558, 11.993902363000061],
            [-62.567279557999854, 12.000326363000099],
            [-62.56799355799987, 12.00177436300018],
            [-62.56871055799985, 12.003222363000262],
            [-62.803720557999867, 12.476581364000069],
            [-62.917333558999957, 12.673663364000106],
            [-62.948834559, 12.726083364000118],
            [-63.04297855899992, 12.880107364000096],
            [-63.127153559000021, 13.01785636400021],
            [-63.247407558999981, 13.214086364000096],
            [-63.249883558999841, 13.231600364000059],
            [-63.27282155899988, 13.398903364000063],
            [-63.153148558999959, 13.327949364000077],
            [-62.548984557999916, 12.970167364000048],
            [-62.153484557999974, 12.810755364000045],
            [-61.560714556999983, 12.561087364000031],
            [-61.534270556999928, 12.552604364000075],
            [-61.506034556999936, 12.54376836400013],
            [-61.495607556999886, 12.545452364000113],
            [-61.493989556999963, 12.545770364000134],
            [-61.488417556999877, 12.546588364000044],
            [-61.471497556999964, 12.549385864000044],
            [-61.467429556999889, 12.550206364000175],
            [-61.462813556999976, 12.551427364000034],
            [-61.45959155699984, 12.551954364000025],
            [-61.441498556999932, 12.551600364000137],
            [-61.428365556999807, 12.551586864000086],
            [-61.420495556999953, 12.551425364000139],
            [-61.418111556999861, 12.554063364000086],
            [-61.415924556999926, 12.554540364000033],
            [-61.412197556999928, 12.555463364000019],
            [-61.400497556999881, 12.557875364000012],
            [-61.394075556999837, 12.559051364000055],
            [-61.389436556999982, 12.559261364000122],
            [-61.380010556999878, 12.560531364000127],
            [-61.345882556999896, 12.567288364],
            [-61.344920556999881, 12.567493364],
            [-61.335160556999966, 12.56336936400011],
            [-61.30721455699998, 12.554015364000108],
            [-61.286669556999868, 12.528483364000124],
            [-61.286144556999972, 12.527833364000074],
            [-61.285613556999891, 12.527183364000024],
            [-61.265596556999867, 12.502775364000129],
            [-61.252379556999898, 12.488332364000058],
            [-60.82328555700002, 12.0365853630001],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "GRL", Country: "Greenland" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.115824311666728, 83.740299743666768],
            [7.951875534000123, 83.616053252000015],
            [6.838756652000086, 82.980828083000191],
            [5.914688580000131, 82.343651297000079],
            [5.638307830000031, 82.276750347000146],
            [4.024805835000109, 81.823722526000168],
            [2.25074112100009, 81.276752034000239],
            [1.201904143, 80.948946030000101],
            [0.226748113000014, 80.618428373000214],
            [-1.248292950999939, 80.042091262000085],
            [-2.23070489499986, 79.225200137000158],
            [-2.447286842999858, 79.06484926200018],
            [-3.649999075999972, 78.458995636000239],
            [-3.654101642999876, 78.439434302000151],
            [-3.65548187899995, 78.432853239000181],
            [-3.656681647999903, 78.427132652],
            [-3.660286976999885, 78.394132486000174],
            [-3.660791120999761, 78.3895179740001],
            [-3.671264160999982, 78.293656532000099],
            [-3.698713918999829, 78.155865605000159],
            [-3.403372121999951, 77.770470075000119],
            [-3.242145096999877, 77.548247853000262],
            [-2.99936835099993, 77.166936424000014],
            [-2.858626638999908, 76.925937524],
            [-3.137919301999887, 76.772638639000164],
            [-3.21492234699997, 76.730373007000011],
            [-3.83792078599987, 76.481255171000228],
            [-3.926621691999912, 76.433206151000107],
            [-3.982565503999894, 76.402901851000237],
            [-3.990292909999965, 76.395811707000135],
            [-3.99219370399976, 76.394067753000101],
            [-4.03929327599991, 76.371337994000143],
            [-4.046055041999921, 76.348242836000068],
            [-4.046963511999934, 76.345139950000018],
            [-4.485439834999852, 76.096303747000164],
            [-4.356869819999815, 75.965842563000152],
            [-4.474405868999952, 75.408750652500188],
            [-4.621062503999894, 74.713635849000255],
            [-4.712506650999899, 74.614224909000015],
            [-4.737221218999935, 74.587357243000085],
            [-4.760405979999973, 74.563778994000089],
            [-4.776415142999866, 74.545707920999959],
            [-4.77935650399985, 74.542387849000235],
            [-5.007694443999924, 74.363027777000141],
            [-11.474638888999891, 72.822833333000247],
            [-12.767027777999857, 71.880777777000034],
            [-13.629555555999872, 69.909555555000154],
            [-13.266666666999981, 69.583333333000141],
            [-13.559999512999923, 69.356666844],
            [-15.354999513999815, 69.084999844000194],
            [-15.751666514999869, 69.049999844000084],
            [-17.336666516, 68.763332844000146],
            [-19.999999518999914, 68.408332844000071],
            [-21.749999519999847, 68.1366668430001],
            [-23.350166522, 67.816805843000068],
            [-24.441666522999867, 67.629999843000107],
            [-25.599999523999941, 67.381666843000076],
            [-26.556666524999912, 67.064999842000091],
            [-26.994999524999884, 66.954999842],
            [-27.764999525999855, 66.639999842000194],
            [-28.978333526999876, 66.211666842000042],
            [-29.856666527999863, 65.216666841000134],
            [-30.581666527999857, 63.923332839000139],
            [-30.863333528999817, 63.313332839000154],
            [-31.072090528999865, 63.220797839000042],
            [-31.204544008999989, 63.181038117000114],
            [-31.302566528999819, 63.151613839000035],
            [-31.355530587999908, 63.128584692000189],
            [-31.52492994, 63.05492918800023],
            [-32.57523928199987, 62.726705123999963],
            [-33.714212462333336, 62.47738885966676],
            [-33.810744437999887, 62.375582849000196],
            [-34.084776797000018, 62.086578775000149],
            [-34.760121663999882, 61.601855886000138],
            [-34.780873498999966, 61.591265656],
            [-34.821835531999881, 61.570361837000135],
            [-35.12931353299993, 61.342425837000221],
            [-35.20803734699993, 61.171868792000069],
            [-35.29304975999986, 60.987688176000105],
            [-35.742562773999907, 60.450670223000017],
            [-35.775498048999879, 60.424704275000082],
            [-35.790066532999845, 60.413218836000041],
            [-35.928770868999862, 60.242347277],
            [-35.983898322999892, 60.174435249000098],
            [-36.038300953999908, 60.130782655000118],
            [-36.057868603999964, 60.083918086999958],
            [-36.481848697000032, 59.544072318000204],
            [-36.483006039499912, 59.543109716500098],
            [-36.549652533999961, 59.373952835000054],
            [-36.627343533999863, 59.271979835000096],
            [-36.634228599000039, 59.254207454000166],
            [-36.635437665999888, 59.251086710000052],
            [-36.66361808, 59.213263964],
            [-37.039251533999931, 58.709102835000166],
            [-37.040198533999956, 58.708277835000118],
            [-37.045496534999984, 58.701036835000153],
            [-37.046181463999915, 58.700440178999983],
            [-37.046978660999883, 58.699745807000099],
            [-37.049561921999924, 58.696213523000026],
            [-37.098016464000011, 58.653251044000243],
            [-37.613228534999934, 58.196435834000155],
            [-37.655171695000035, 58.170299036000117],
            [-37.727769756999891, 58.125060260000183],
            [-37.745799541999986, 58.108681318000166],
            [-38.44517849399989, 57.663401136000047],
            [-39.261431773999902, 57.284486826000162],
            [-40.171739914999932, 56.981141701500164],
            [-40.172997159999852, 56.980742567999968],
            [-40.17622953699987, 56.979643833000111],
            [-40.496356797999965, 56.90874954700017],
            [-40.547882364999964, 56.897338945000186],
            [-40.745230115999874, 56.82890500600017],
            [-40.915158551999866, 56.789559419000128],
            [-41.717988538999975, 56.603670833000237],
            [-41.800251645999822, 56.592935806000085],
            [-41.870797844, 56.583730136000113],
            [-41.900920434999932, 56.576525228000136],
            [-41.925548539000033, 56.570634833000085],
            [-42.525623580999962, 56.488628341000165],
            [-42.944285615000013, 56.431414023000045],
            [-43.83663076799985, 56.390148121000024],
            [-43.987380541, 56.383176832000103],
            [-45.031021541999962, 56.427032832000194],
            [-45.914262542999978, 56.544414833000118],
            [-45.925990543000012, 56.544762833000121],
            [-46.523342896999907, 56.618659334000213],
            [-46.952741054999905, 56.671778893000194],
            [-47.269793696999898, 56.741313460000171],
            [-47.934370544999922, 56.887065833],
            [-48.847171544999895, 57.185583833000138],
            [-48.860657397999887, 57.191848570000033],
            [-48.890743398000012, 57.205825288000071],
            [-49.206125379999975, 57.305960145000071],
            [-49.34226266, 57.368151990000186],
            [-49.348856643999852, 57.368782291000215],
            [-49.963641093999854, 57.488060245999961],
            [-50.113075967999919, 57.501395309000102],
            [-50.973143630999857, 57.659612529],
            [-51.135512547999923, 57.689481834000233],
            [-52.095516011333245, 57.962424552333587],
            [-52.100205115999955, 57.963747196000085],
            [-52.122128548999967, 57.969838834000157],
            [-52.397691384999888, 58.080552203000209],
            [-52.995604092999969, 58.320777290000066],
            [-53.018640516999881, 58.333708238000085],
            [-53.037078188999885, 58.340961740000196],
            [-53.801226226000011, 58.761530835000016],
            [-54.432028256999956, 59.241639021000211],
            [-54.566471223999827, 59.392749754000207],
            [-54.764206162999898, 59.494538180000163],
            [-55.056611237999988, 59.704966838000146],
            [-55.423899551999909, 59.969285836000196],
            [-55.498225552, 60.047803836000142],
            [-55.693248551999858, 60.184109836000047],
            [-55.765538434499831, 60.259119710000149],
            [-55.793943935999863, 60.278689639000149],
            [-55.909028154999987, 60.357976960000045],
            [-56.156953138999938, 60.60987897],
            [-56.424085214999877, 60.788828699000149],
            [-56.465091526999913, 60.829364011000123],
            [-56.64468477499986, 60.879967220000268],
            [-56.769728395999891, 60.908823440000063],
            [-56.856297056999892, 60.918442181000032],
            [-56.971721937999973, 60.956917141000233],
            [-57.215570048999837, 60.999932075000189],
            [-57.348863861999916, 62.001346714000064],
            [-57.35080686399985, 62.034125339000141],
            [-57.363229746, 62.048389319000123],
            [-57.36580666999987, 62.051348149000162],
            [-57.416358049, 62.184685385000122],
            [-57.681895880999917, 62.7838703420001],
            [-57.949655880999842, 63.368888848000111],
            [-57.98381895599988, 63.468336377000256],
            [-58.026794873, 63.571191946000127],
            [-58.031870688999845, 63.583340051000121],
            [-58.026470121999949, 63.594698409000031],
            [-58.015757143999963, 63.617229718000075],
            [-57.967415690499934, 63.733621457500178],
            [-57.962251029999948, 63.761783830000169],
            [-57.949076357999957, 63.833623984000198],
            [-57.933518303999904, 63.868347026000109],
            [-57.917407013999821, 63.894869924000105],
            [-57.887241393999972, 63.944529488000072],
            [-57.883233971999942, 63.951126629000143],
            [-57.87039752499993, 63.97316706000008],
            [-57.815447011499941, 64.06751833],
            [-57.810933374, 64.104146295000064],
            [-57.80431764299982, 64.157832656999958],
            [-57.799050248999919, 64.200577323000104],
            [-57.732052121000038, 65.100047511],
            [-57.723032429999989, 65.122419596000029],
            [-57.719434329999899, 65.131344182000205],
            [-57.717604921999964, 65.135881773000037],
            [-57.729029063999917, 65.172653266999973],
            [-57.732880497, 65.185050081000028],
            [-57.739089098999898, 65.204550656000137],
            [-57.746962723999872, 65.22928089500023],
            [-57.748711612, 65.234773970999967],
            [-57.749016518999895, 65.245074162000193],
            [-57.750652253999874, 65.300331534000222],
            [-57.73612565499991, 65.37466401900015],
            [-57.734257606999904, 65.384222804000117],
            [-57.699244834999888, 65.56895031699996],
            [-57.691742112999862, 65.593814957000077],
            [-57.684241106999906, 65.61867391300008],
            [-57.669943792999902, 65.798861133000045],
            [-57.667004656999893, 65.835902684000231],
            [-57.666907576999847, 65.841512899000165],
            [-57.666725860000014, 65.852014294000142],
            [-57.665859296999884, 65.913953382000031],
            [-57.665330646000029, 65.951739616000097],
            [-57.656261337999865, 66.010911992999965],
            [-57.650045837999954, 66.051464806000226],
            [-57.644821694999877, 66.095845119000089],
            [-57.634886148999925, 66.180249900000106],
            [-57.632257545999835, 66.20258049800016],
            [-57.617250377, 66.302305727000018],
            [-57.617248075999896, 66.337511497000108],
            [-57.617245911999902, 66.370608697000151],
            [-57.617243876, 66.401753340000013],
            [-57.632515842999908, 66.500923342000206],
            [-57.648898903999907, 66.600371157000026],
            [-57.650008806999892, 66.619260666000187],
            [-57.666671821999984, 66.685651875000147],
            [-57.698158469999896, 66.771983685],
            [-57.714998530999878, 66.81815668300014],
            [-57.728922003999855, 66.86706557899997],
            [-57.866913361999963, 67.351786954000048],
            [-57.882883133999968, 67.398527323000152],
            [-57.886795538999849, 67.409978162000172],
            [-57.900796840999959, 67.450957209000066],
            [-57.912412335999846, 67.46386429900015],
            [-57.91571440449988, 67.467533531],
            [-57.922053472999863, 67.473882131000195],
            [-57.926953299999923, 67.478782281000079],
            [-57.931907363499874, 67.483736667500168],
            [-57.932864492, 67.484679571000058],
            [-57.950517901999859, 67.502070628999988],
            [-58.03218273199991, 67.584296468000204],
            [-58.089388974, 67.642460064000161],
            [-58.09884777949992, 67.65207716400009],
            [-58.150788945999864, 67.734024982000122],
            [-58.223944885999884, 67.822820764000099],
            [-58.237928680999971, 67.839794123000203],
            [-58.317171449999933, 67.935978024000136],
            [-58.345388659999855, 67.971882992000147],
            [-58.382446406999861, 68.019037097],
            [-58.414944451999986, 68.067650211000071],
            [-58.423089450999868, 68.082848048000173],
            [-58.433553925999917, 68.102373818000146],
            [-58.442200573, 68.1113355210001],
            [-58.442753290999889, 68.111908379000241],
            [-58.448831304, 68.118207854000175],
            [-58.476696276999945, 68.153856849000064],
            [-58.507622847999869, 68.193422691000123],
            [-58.565214994999906, 68.267103082000077],
            [-58.609598114999926, 68.312528472000182],
            [-58.635944912999832, 68.339493985000118],
            [-58.648268414999848, 68.352106886000215],
            [-58.689695390999873, 68.405246186000127],
            [-58.699377191999901, 68.417665246000055],
            [-58.995961557999919, 68.528110887000182],
            [-59.017162584999966, 68.536005980000155],
            [-59.066607272999903, 68.566840898000081],
            [-59.082986810999955, 68.57202658100006],
            [-59.115170449999823, 68.582215765999976],
            [-59.232445365999922, 68.619344700000099],
            [-59.232983169999841, 68.628499149000092],
            [-59.233277669999865, 68.633511615500097],
            [-60.03274167, 68.935752558000118],
            [-60.141893392999918, 68.998727420000094],
            [-60.148298534999839, 69.002422869000057],
            [-60.17797038599997, 69.022023552000107],
            [-60.299687745000028, 69.102427814000137],
            [-60.383853943999839, 69.167708759000234],
            [-60.420878290000019, 69.187237163000063],
            [-60.428534947, 69.191275649000076],
            [-60.449688186999879, 69.202432875000241],
            [-60.53404950599986, 69.261916136000067],
            [-60.849965779499939, 69.484669055000055],
            [-60.896476334999988, 69.619310011999957],
            [-60.90613400899997, 69.647267595000088],
            [-60.965501147999873, 69.819126951999962],
            [-60.976078678999841, 69.877824814000107],
            [-60.979530985999958, 69.896982688000193],
            [-60.983271849999937, 69.917741855000159],
            [-60.985547099999906, 69.91795254600008],
            [-60.998272606999876, 69.919130961999983],
            [-61.065490444, 70.018579701000149],
            [-61.072264190999931, 70.02875562200019],
            [-61.100133258999847, 70.070622176000185],
            [-61.131874843999981, 70.118306253000199],
            [-61.133817934999911, 70.135806919000132],
            [-61.16659069099984, 70.21803250800015],
            [-61.186527917000035, 70.275138481000099],
            [-61.203156236999888, 70.322766791000035],
            [-61.282680077999828, 70.550546043000125],
            [-61.333235597999931, 70.585270058000035],
            [-61.467126270999955, 70.686839094000149],
            [-61.485186160999859, 70.700539269000117],
            [-61.617400273999891, 70.800836473000132],
            [-61.671077488, 70.834341320000064],
            [-61.683103947999967, 70.841848131000077],
            [-61.700178453999882, 70.852505888000039],
            [-61.878462151999827, 70.990921416000063],
            [-61.910560492999821, 71.015841859000076],
            [-62.148507881999876, 71.200578960000016],
            [-62.282951331999868, 71.302806369000123],
            [-62.363444609999988, 71.373242719],
            [-62.416282267999918, 71.419478776000133],
            [-62.462759450000021, 71.465373626000158],
            [-62.482391493999899, 71.484759691000207],
            [-62.531558056999927, 71.519205926000055],
            [-62.537973038000018, 71.525226577000197],
            [-62.549613176999969, 71.536151194000226],
            [-62.742414499999853, 71.680208776000072],
            [-62.816555894999965, 71.735605855],
            [-62.834472772999874, 71.749025230000015],
            [-62.881833237000023, 71.78449725100019],
            [-63.050724923999951, 71.869502121000025],
            [-63.348507766000012, 72.01895513500007],
            [-63.399094846999873, 72.046373022000139],
            [-63.500732039000013, 72.101459755],
            [-63.641549036999862, 72.165489525000041],
            [-63.674203821, 72.180337720000139],
            [-63.681569539999884, 72.183686919000152],
            [-64.215472601999977, 72.402589139000028],
            [-64.356419135999886, 72.467184422000045],
            [-64.43186643599995, 72.501761645000059],
            [-64.633814894999858, 72.601211811000169],
            [-64.899099288999849, 72.718996587000092],
            [-64.923058234, 72.730926797000095],
            [-64.935399426999879, 72.737072018999967],
            [-64.96604507099994, 72.752331830000216],
            [-65.000766491499945, 72.785666826500162],
            [-65.116602812999986, 72.83594758200006],
            [-65.599731802999941, 73.057504765000047],
            [-66.132461485000022, 73.301808234000205],
            [-66.415803416999836, 73.419593117000048],
            [-66.782819028999938, 73.462687071000161],
            [-67.248631851000027, 73.517381520000058],
            [-67.268903239999872, 73.519433062000147],
            [-68.05690735099995, 73.59918207300008],
            [-68.083129266000014, 73.601835830000184],
            [-68.199247248999882, 73.619615210000077],
            [-68.482873113000011, 73.685731148000244],
            [-68.617948702, 73.720536575000139],
            [-68.660140176999846, 73.731408209000122],
            [-68.7998335, 73.767403504000228],
            [-68.848725882999901, 73.76907059500023],
            [-69.103515406999918, 73.844533754000096],
            [-69.182630675999889, 73.867965987500114],
            [-69.247847321999984, 73.884406075],
            [-69.516536956999914, 73.952138492000259],
            [-69.670326314000022, 73.99214058799997],
            [-69.832664561999849, 74.034366332000175],
            [-69.857564925999895, 74.035217772000109],
            [-69.865166940999984, 74.035477714000123],
            [-70.10017897299997, 74.10075982000005],
            [-70.131010361999955, 74.106256329000175],
            [-70.166853943999854, 74.112646394000151],
            [-70.199907238999913, 74.118539017000202],
            [-70.246772495999949, 74.131041359000093],
            [-70.278535032999883, 74.139514717000239],
            [-70.38213909199996, 74.167153386000095],
            [-70.463644400999868, 74.183965798000102],
            [-70.550482303999956, 74.201878184000165],
            [-71.417198952, 74.400503255000061],
            [-71.603356381999845, 74.438634200000124],
            [-71.685909194999851, 74.455543634000165],
            [-71.750830427999944, 74.468841562000151],
            [-72.229464848999868, 74.58121340300012],
            [-72.88200938, 74.734415152000096],
            [-72.96853558499987, 74.791890256000144],
            [-73.034235414999984, 74.83553142300012],
            [-73.26618483999988, 75.000539750000158],
            [-74.272133080999879, 75.978633589000125],
            [-74.998683562999844, 76.685066348333478],
            [-74.765242707999874, 77.500656473000092],
            [-73.765024793999913, 78.417358478000054],
            [-72.998218009, 78.802093537000061],
            [-69.330975353999975, 79.650718768000061],
            [-69.029609596999961, 79.967049413000083],
            [-68.99752024899999, 80.000732218000181],
            [-68.333333332999899, 80.416666667000058],
            [-68.330701990999955, 80.41738873700011],
            [-68.071563735999916, 80.488499166000068],
            [-67.118462919999928, 80.750015780500135],
            [-66.483378577499906, 80.817646080500182],
            [-66.480308392999945, 80.817971955],
            [-66.434220916999948, 80.819210381500213],
            [-66.266666666999924, 80.83472222200011],
            [-64.179832114999897, 81.301307371000249],
            [-62.162638275999825, 81.867425368000212],
            [-60.983484895999879, 82.057857267000074],
            [-60.226839361999964, 82.18005464100024],
            [-59.995477269999895, 82.217419350000128],
            [-59.917591830999982, 82.244060178500163],
            [-59.901475700999953, 82.249572725000164],
            [-59.819955355999923, 82.262093535000176],
            [-59.595003690999818, 82.296644136000126],
            [-59.418355165, 82.329744191000131],
            [-59.330983294999868, 82.353353985500192],
            [-59.280033789999948, 82.367121659000119],
            [-59.073518562999851, 82.399923649000257],
            [-58.943509546999934, 82.426340236000058],
            [-58.70222322599983, 82.475367306000109],
            [-58.402228069999865, 82.555388418500016],
            [-58.242765295000027, 82.597923734000133],
            [-58.233755855999846, 82.601103349000212],
            [-58.208415919999851, 82.610046330999964],
            [-58.014740480999848, 82.690737754000153],
            [-58.010993330999952, 82.692298938000178],
            [-57.969839858499967, 82.714687948000162],
            [-57.866225492999888, 82.771058062999984],
            [-57.862475251999911, 82.773183296000056],
            [-57.756189585999834, 82.852430141],
            [-57.750968776999883, 82.863006547000239],
            [-57.742281268999875, 82.880605853000148],
            [-57.720398504999821, 82.895913824000118],
            [-57.693856756999963, 82.91448097500016],
            [-57.680646020999887, 82.927153722000142],
            [-57.577678416999902, 82.994607289000228],
            [-57.561445245999948, 83.008193099000053],
            [-57.534517316999825, 83.030729529000084],
            [-57.499870071999879, 83.051277655000106],
            [-57.454036278999922, 83.085527005000259],
            [-57.411684925999879, 83.11717409800012],
            [-57.39300461699986, 83.127146596000145],
            [-57.37348459299983, 83.137567377000025],
            [-57.26058139099996, 83.211862563000167],
            [-57.259606708999968, 83.212610191000195],
            [-57.258490532999986, 83.213466352000253],
            [-57.206536603999893, 83.246152169000098],
            [-57.187215253999966, 83.260541611],
            [-57.144022129999911, 83.292709393000237],
            [-57.097460487000035, 83.320081807],
            [-57.085804862000032, 83.326933855000092],
            [-56.983468367999848, 83.401501215000181],
            [-56.974921556999846, 83.407728838000239],
            [-56.926243476999929, 83.44942046600012],
            [-56.859942164999865, 83.522949997000055],
            [-56.838151552, 83.552787013000184],
            [-56.804106676999879, 83.599403298000126],
            [-56.747136001999934, 83.718054039000222],
            [-56.743157580999906, 83.72633979033354],
            [-56.721706457999971, 83.808195180999959],
            [-56.717621777999966, 83.823781920000059],
            [-56.716558855999864, 83.90987753600011],
            [-56.718005024999883, 83.920518807000107],
            [-56.719056548000026, 83.928256178000211],
            [-56.720785313999983, 83.940976866000213],
            [-56.758274475999883, 84.039522834000124],
            [-56.79004733499994, 84.093674429000046],
            [-56.806563389999837, 84.121823319000114],
            [-56.820648479999875, 84.140737299000094],
            [-56.840068040999853, 84.161413705000072],
            [-56.858611726999925, 84.181157548999963],
            [-56.89886577999988, 84.232279987000155],
            [-56.937729816999962, 84.271875847000032],
            [-56.977918529000021, 84.321962719000183],
            [-56.980840906999873, 84.32494099400013],
            [-56.998926250999943, 84.343372258000144],
            [-57.020378305999884, 84.361646013000168],
            [-57.03695467599988, 84.375766456000107],
            [-57.07460551500003, 84.414140724000148],
            [-57.088985817999969, 84.428797331000212],
            [-57.18472425199991, 84.511730263000217],
            [-57.187742802999907, 84.514345067000122],
            [-57.52865629, 84.756365294000076],
            [-57.53460865649987, 84.760590970500104],
            [-57.718282156999862, 84.870155293000167],
            [-57.921824380999936, 84.975567252000147],
            [-58.295118040999881, 85.146129566500136],
            [-58.3726741239999, 85.245025872000028],
            [-58.408690813000021, 85.290973942000036],
            [-58.88303099800001, 85.896110513000067],
            [-58.906458996999902, 85.914953265000193],
            [-58.921728369999954, 85.927234170000148],
            [-60.085073156999897, 86.300584389000178],
            [-60.19606238666654, 86.336203946166719],
            [-59.478007220999928, 86.376007403],
            [-58.699028956999967, 86.432260672000112],
            [-58.316286553999902, 86.459899860000107],
            [-57.734059928999983, 86.499324490000177],
            [-57.099336380999915, 86.542303614000204],
            [-57.064756928999884, 86.543291873000172],
            [-57.056602552999834, 86.543524860000176],
            [-57.025279552999876, 86.545637860000213],
            [-56.435997015499908, 86.57116076150011],
            [-55.572327597999873, 86.608567664],
            [-54.06595416899998, 86.669275482000174],
            [-54.046418335999874, 86.669741628000182],
            [-54.029924550000032, 86.670134861000136],
            [-53.336233898499898, 86.693200250499999],
            [-52.918143424999926, 86.707101670000171],
            [-51.986426712999872, 86.736388117000118],
            [-51.781774547999902, 86.742820861000169],
            [-51.651165238999965, 86.746694627000053],
            [-50.62088677600002, 86.777251518000142],
            [-49.489580554999918, 86.808845255000037],
            [-49.435631545999968, 86.810351861],
            [-48.226248544999919, 86.842079861000073],
            [-46.839774914999964, 86.870433329500159],
            [-46.344543019999975, 86.880560713000065],
            [-45.928721423999832, 86.888926168000253],
            [-45.036481541999905, 86.906875861000103],
            [-44.494116945999934, 86.916951119000103],
            [-43.706847201999949, 86.931575435000056],
            [-42.534185057999906, 86.941494449000075],
            [-41.471787484999936, 86.949280992000141],
            [-41.354460538999888, 86.95014086100015],
            [-40.208641536999892, 86.967374861000039],
            [-39.667941289999874, 86.974855069000071],
            [-39.050288049999921, 86.983399563000049],
            [-37.880135445999883, 86.998194869000244],
            [-36.81682232899982, 87.00552040400008],
            [-36.098222187999909, 87.009760689000174],
            [-35.748708532999842, 87.011822861000084],
            [-34.680549531999844, 87.018396861000113],
            [-34.497418432999837, 87.019344050000242],
            [-33.608083978999957, 87.023943516000131],
            [-32.56564254099996, 87.021527829000163],
            [-32.501140387999897, 87.021317814000113],
            [-31.525239529000032, 87.018139861000165],
            [-30.987471508999988, 87.015882964000198],
            [-30.487540208999889, 87.013784415000217],
            [-29.99569410649994, 87.011254639000143],
            [-29.453198526999927, 87.00846386100018],
            [-28.422854525999895, 87.002184861000231],
            [-27.888710936999871, 86.998419315000049],
            [-27.397136006999858, 86.994953433000163],
            [-26.376650889999837, 86.986775572999974],
            [-25.361988734999954, 86.977659488000214],
            [-24.353717816, 86.967613693000075],
            [-23.352383614999837, 86.956647450000176],
            [-22.358507377000024, 86.944770725000083],
            [-22.038321799499926, 86.940621436500109],
            [-21.372584520000032, 86.931993861000208],
            [-20.395085518999906, 86.918328861000234],
            [-19.426450517999911, 86.903786861000142],
            [-19.221980367999976, 86.900503338000149],
            [-18.467094619000022, 86.888380511000136],
            [-17.407154858999832, 86.858214638999968],
            [-16.367612670999904, 86.827009864000203],
            [-15.348531532999885, 86.794796672000203],
            [-15.030183558, 86.784215558000099],
            [-14.349920512999887, 86.761604861000237],
            [-13.814984940999892, 86.742935345000049],
            [-13.371739900999955, 86.727465538000203],
            [-12.732899993999894, 86.701131088000082],
            [-11.984595510999924, 86.670283861000215],
            [-11.099197509999954, 86.633442861000205],
            [-9.722933508999915, 86.57564986000007],
            [-8.488513507999812, 86.497394860000227],
            [-7.308158506999916, 86.417618860000104],
            [-6.17927550599984, 86.336421860000016],
            [-5.099333504999976, 86.253896860000182],
            [-4.06588550399988, 86.170129860000074],
            [-3.076571502999968, 86.085201860000026],
            [-1.958930501999873, 85.992088860000237],
            [-1.528534126999972, 85.95395928900011],
            [-0.891912300999962, 85.897559664000156],
            [0.127217175000169, 85.801710589000024],
            [1.22824838950018, 85.631558943500167],
            [1.586094501000161, 85.576257860000126],
            [1.733147502000122, 85.526891859000031],
            [2.088512411000096, 85.486271900000261],
            [2.127277821000177, 85.481840770000261],
            [3.649759503000155, 85.011026859000182],
            [3.753314776000025, 84.983940724000021],
            [3.777958463000232, 84.977494765000202],
            [4.172696719000214, 84.849025629000167],
            [4.25586578900004, 84.823548129000145],
            [4.455265504000181, 84.762464859000019],
            [4.67149687100013, 84.699669925],
            [4.903816100000085, 84.632202549000098],
            [4.929708760000011, 84.613952494000088],
            [4.947002505000114, 84.601762859000203],
            [5.171334257000098, 84.530822363000013],
            [5.473536370000147, 84.435256517000127],
            [6.220270687000067, 84.113230625000242],
            [6.266362506000149, 84.053984858000163],
            [7.376519507000154, 83.889514858000169],
            [7.729199371000078, 83.818332970000242],
            [8.115824311666728, 83.740299743666768],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "GTM", Country: "Guatemala" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.141955005002018, 17.818882109007021],
            [-89.142226913941727, 17.719445076788091],
            [-89.14343591965914, 17.341800104543822],
            [-89.145008867613427, 17.013882114131732],
            [-89.184172975034102, 16.414444942447048],
            [-89.204181919074216, 16.104164152745867],
            [-89.216399884091174, 15.915273102655547],
            [-89.216172902152522, 15.889853975376681],
            [-89.169726932508866, 15.910136001823005],
            [-89.047226926000036, 15.906391135111775],
            [-88.980835882418717, 15.898053991276029],
            [-88.910572902236709, 15.893609068004807],
            [-88.901909679999989, 15.904851247],
            [-88.901195139999913, 15.905274946000148],
            [-88.89997276099993, 15.905999778000094],
            [-88.879671352999907, 15.917607338000025],
            [-88.853928453499918, 15.93232613300016],
            [-88.853466134428459, 15.93343253442869],
            [-88.850112785499874, 15.940267723000105],
            [-88.848513427999933, 15.943519901999977],
            [-88.847409463, 15.9457647360001],
            [-88.846592649999906, 15.947425667000104],
            [-88.839830908999886, 15.949800429000149],
            [-88.830389965999984, 15.953116142000127],
            [-88.827302396999869, 15.953909522000131],
            [-88.8246063039999, 15.954544434000084],
            [-88.819432197999902, 15.955825550000114],
            [-88.814860268999894, 15.956827532000077],
            [-88.802998779999911, 15.959176654000103],
            [-88.792059761999866, 15.960907056000096],
            [-88.780355119999882, 15.96275367900013],
            [-88.776346300999847, 15.963393513500094],
            [-88.774970748999976, 15.963646715000024],
            [-88.773642152999912, 15.963891273000101],
            [-88.770382075000015, 15.96485584800017],
            [-88.768523703, 15.965405694000182],
            [-88.74842898799983, 15.971749008000117],
            [-88.745753778999898, 15.98012464500006],
            [-88.745378526999957, 15.981299497333353],
            [-88.726869673999829, 16.009114105999956],
            [-88.716808774999976, 16.023785712000105],
            [-88.713527567, 16.028570632000068],
            [-88.712899722999907, 16.029501388000071],
            [-88.711457679999967, 16.031639165000215],
            [-88.711021788999886, 16.032272004000092],
            [-88.709186933999945, 16.034935898000228],
            [-88.675046070666639, 16.052049814666688],
            [-88.637896093999871, 16.061322498000052],
            [-88.634764360999839, 16.0621698960001],
            [-88.630431224999967, 16.063342374000101],
            [-88.627816348999971, 16.064049918000208],
            [-88.616899474999883, 16.06706],
            [-88.612195434999961, 16.068328956000144],
            [-88.608927637999955, 16.069308010000057],
            [-88.599863820999985, 16.07202358900004],
            [-88.594247396333287, 16.07371317700013],
            [-88.581909188999873, 16.071947787999974],
            [-88.575762797499948, 16.071176552500177],
            [-88.575089707999894, 16.071086181000098],
            [-88.573845489999883, 16.070941207000104],
            [-88.56245350699993, 16.069813262000125],
            [-88.546280301999985, 16.068211919000234],
            [-88.537536787999869, 16.067616612000094],
            [-88.531602517999858, 16.067263692000111],
            [-88.526088041999913, 16.067030555000187],
            [-88.511293025999862, 16.066405062000214],
            [-88.501453815999866, 16.066320530000056],
            [-88.494356220999975, 16.066574986000049],
            [-88.487777779999931, 16.066887591999986],
            [-88.485047966000025, 16.067017313000164],
            [-88.470022499999914, 16.067847334000177],
            [-88.457398866499972, 16.068734811500107],
            [-88.445930398999934, 16.057675162000166],
            [-88.44062465499988, 16.052714494000227],
            [-88.440038182999956, 16.052166167000067],
            [-88.416968598000039, 16.032157275000102],
            [-88.412765901999933, 16.028744542000069],
            [-88.408171235999902, 16.025013516000101],
            [-88.406191193000041, 16.023576252000097],
            [-88.404233314999942, 16.022155077000107],
            [-88.398842892999909, 16.015091697000102],
            [-88.39740852099996, 16.013333284000183],
            [-88.396613866499905, 16.012448423500032],
            [-88.389685395000015, 16.005318492000143],
            [-88.383761321999941, 15.999829768000225],
            [-88.382783670499919, 15.998931823000092],
            [-88.381795353999905, 15.998071958000097],
            [-88.375481876999856, 15.99266384100018],
            [-88.37455852699992, 15.991903606000065],
            [-88.372190889999871, 15.990029324000076],
            [-88.371545521999849, 15.989518434000061],
            [-88.359169420999933, 15.980172050000178],
            [-88.358683348666602, 15.979836707000132],
            [-88.357753517999924, 15.979208653000128],
            [-88.355527540999873, 15.97771008500014],
            [-88.354975679999853, 15.977338574000029],
            [-88.351173289, 15.975250441],
            [-88.334358437999896, 15.965927782000122],
            [-88.327092538999921, 15.962273698000118],
            [-88.322950241999933, 15.960247447000157],
            [-88.319239778999986, 15.958432434000073],
            [-88.31549296399993, 15.956734384],
            [-88.314360645999955, 15.956250482000101],
            [-88.305451217999888, 15.952701855000029],
            [-88.301601102999825, 15.95116835300017],
            [-88.300184685999966, 15.950621732000158],
            [-88.28888894699989, 15.946262497000149],
            [-88.286866818999982, 15.945581317000105],
            [-88.280557133, 15.943455815000092],
            [-88.278512805999867, 15.942823291000067],
            [-88.277478702999929, 15.942503334000079],
            [-88.260270430999867, 15.937121461000189],
            [-88.258299415999858, 15.936536159999989],
            [-88.254211937999855, 15.935385493999988],
            [-88.246654031999896, 15.933565423000132],
            [-88.243948965999891, 15.932981386000122],
            [-88.239322813999934, 15.932109034000163],
            [-88.236149873000045, 15.931510713000122],
            [-88.22501949899987, 15.929742479000097],
            [-88.194432076999931, 15.924883188000209],
            [-88.18196213399986, 15.923149062000064],
            [-88.180880473799874, 15.923009318400105],
            [-88.180588083499913, 15.917679738500098],
            [-88.180094751499951, 15.902938086000248],
            [-88.180022, 15.901564413],
            [-88.179575221999897, 15.893128428000111],
            [-88.179089958666651, 15.883089943666747],
            [-88.178892979999858, 15.879015113000165],
            [-88.176391195999884, 15.871428449000106],
            [-88.166512102999889, 15.841470089000168],
            [-88.164801970499923, 15.829456596000128],
            [-88.174387473000024, 15.811983289000054],
            [-88.185819190999979, 15.791215333000068],
            [-88.199277558999825, 15.766282798000205],
            [-88.208915747999981, 15.748426942000023],
            [-88.216736747999875, 15.734633942000087],
            [-88.214736075409974, 15.724463002189381],
            [-88.215603168954146, 15.724092154697544],
            [-88.216510694389569, 15.723713765491695],
            [-88.240008911900276, 15.71416408754844],
            [-88.315463977962821, 15.667336070757088],
            [-88.32779090772118, 15.635273111726619],
            [-88.486508950314175, 15.504618016319583],
            [-88.552508894292487, 15.4497180591096],
            [-88.668335900026591, 15.350000065495365],
            [-88.758117817950705, 15.295518029978794],
            [-88.837235775711349, 15.247217983347653],
            [-88.980563973479008, 15.135553933656254],
            [-89.142790848388955, 15.076944984280956],
            [-89.150372950384735, 15.07348208479327],
            [-89.15833592606171, 15.056945092696154],
            [-89.170835858302098, 15.030000123758825],
            [-89.177435936519032, 14.998535967892835],
            [-89.154873026574109, 14.980827018090523],
            [-89.175763914468803, 14.924100141259359],
            [-89.185991009837949, 14.90760908199168],
            [-89.206535892768585, 14.893609124354768],
            [-89.223064000048453, 14.871945089708916],
            [-89.221735803667599, 14.844445071141806],
            [-89.154873026574109, 14.733536063290572],
            [-89.131072947717058, 14.715073077476418],
            [-89.137790875492897, 14.684718015041753],
            [-89.197235835893309, 14.585000021427405],
            [-89.235835841228578, 14.58242711242157],
            [-89.350490889257401, 14.471526989387755],
            [-89.348308912216766, 14.431982175923778],
            [-89.435564021602744, 14.414864150296935],
            [-89.495008982003242, 14.421391138317929],
            [-89.548482004012584, 14.42083608868812],
            [-89.570972997227955, 14.414718137543318],
            [-89.583346027454127, 14.385827058321254],
            [-89.589172958919903, 14.3212449970883],
            [-89.565490897259849, 14.316454068857794],
            [-89.543335850724617, 14.304164187111567],
            [-89.518163989590022, 14.233853933232425],
            [-89.579454980351017, 14.207500056108657],
            [-89.60514601656979, 14.208191060208449],
            [-89.626955058140965, 14.209309038457107],
            [-89.652090877091837, 14.195554000031663],
            [-89.686399850903484, 14.170000091749287],
            [-89.710145950303797, 14.145000059630334],
            [-89.726817891042515, 14.117918130394813],
            [-89.746672943340002, 14.069582209217927],
            [-89.73972686015918, 14.041391186551166],
            [-89.818199914287561, 14.066454082943991],
            [-89.854445945928376, 14.059444967851064],
            [-89.889026828679732, 14.044164087767271],
            [-90.020564035579525, 13.938609104403454],
            [-90.086535816362954, 13.868473194110905],
            [-90.103899934667766, 13.823327090015255],
            [-90.108063896538795, 13.789027001021111],
            [-90.096390922933836, 13.745836059832953],
            [-90.104145839999859, 13.71916170500009],
            [-90.113304965999987, 13.664450478000134],
            [-90.129616567999847, 13.567014567000243],
            [-90.233492749999868, 13.356317113000046],
            [-90.347218890999898, 13.160594232000108],
            [-90.373995508999855, 13.110084665000073],
            [-90.461845553999922, 12.987571578000072],
            [-90.548260574999915, 12.867059734000193],
            [-90.68929454299996, 12.670377915000145],
            [-90.794052864999969, 12.514439810500107],
            [-90.838213692999972, 12.448704165500061],
            [-90.872091647000047, 12.35346957500019],
            [-90.914508319, 12.224730063000152],
            [-90.925114851999865, 12.19253800750009],
            [-91.049115235000016, 11.816182491000134],
            [-91.326634822999921, 10.922294124000146],
            [-91.424937626999849, 10.605661582000096],
            [-91.43853475100002, 10.61180793699998],
            [-91.483772750999975, 10.61544593700026],
            [-91.601816750999859, 10.62489993700018],
            [-91.656116751999889, 10.629229937000147],
            [-91.66058075199993, 10.63037593700011],
            [-91.680541751999954, 10.631963937000052],
            [-91.703230751999911, 10.637786937000101],
            [-91.757470751999875, 10.642089937000151],
            [-91.863701751999969, 10.66929393700002],
            [-91.868431752, 10.669666937000159],
            [-92.07534875199994, 10.722504937000195],
            [-92.288348752000047, 10.776696937000182],
            [-92.440304751999975, 10.815232938000193],
            [-92.453422751999938, 10.821094938000101],
            [-92.527644751999873, 10.839874938000179],
            [-92.882775752999919, 10.998078938000248],
            [-93.066073752999984, 11.07945293800023],
            [-93.069686753000042, 11.081873938000172],
            [-93.208042752999944, 11.143164938000098],
            [-93.212129752999886, 11.145899938000255],
            [-93.233005752999929, 11.159864938000212],
            [-93.24337875299986, 11.164453938000122],
            [-93.254798752999903, 11.172089938000255],
            [-93.267484252999964, 11.17773193800015],
            [-93.300662752999983, 11.192432938000167],
            [-93.444735753000032, 11.288642938000052],
            [-93.448088752999979, 11.290121938000212],
            [-93.451600752999866, 11.292464938000094],
            [-93.490403752999867, 11.309576938],
            [-93.542166752999947, 11.34408493800008],
            [-93.951395753999861, 11.616141938000183],
            [-93.98044475399999, 11.635401938000015],
            [-94.010945753999977, 11.664347938000134],
            [-94.073817753999975, 11.705991938000167],
            [-94.080879753999852, 11.710667938000171],
            [-94.099609753999971, 11.728423938000091],
            [-94.127603753999836, 11.746947938000133],
            [-94.146302753999976, 11.76466493800001],
            [-94.157769754000014, 11.772248938000104],
            [-94.193441753999934, 11.806027938000256],
            [-94.208686754000013, 11.820458938000215],
            [-94.247656753999962, 11.846208938000217],
            [-94.260724753999938, 11.858570938000227],
            [-94.330573753999886, 11.904684939000191],
            [-94.336173753999873, 11.909977939000086],
            [-94.384722753999966, 11.942001938999965],
            [-94.407855253999941, 11.963839939000124],
            [-94.560650753999965, 12.103073939000183],
            [-93.852878753999875, 12.863257939000135],
            [-93.140926752999889, 13.621364940000106],
            [-92.424459751999905, 14.377327941000132],
            [-92.409031751999891, 14.392852941000186],
            [-92.263161751999917, 14.537740941000166],
            [-92.248113751999881, 14.548499941000259],
            [-92.246845608008186, 14.550576465547138],
            [-92.237946067781053, 14.55199108080204],
            [-92.2166729651004, 14.558054046661695],
            [-92.187226836248527, 14.58833601890062],
            [-92.15334601005236, 14.683054039618682],
            [-92.152645953497057, 14.70333606606512],
            [-92.158335924664726, 14.72944502397722],
            [-92.169172887310538, 14.76860913139798],
            [-92.182917867452176, 14.81055401902357],
            [-92.183826801034968, 14.845273203177911],
            [-92.149735924344554, 14.934718000955527],
            [-92.093926865913602, 15.025800119704073],
            [-92.070690889141957, 15.077291156882822],
            [-92.211399909797933, 15.26221806967429],
            [-92.10793587600611, 15.437600174017078],
            [-91.828608951903902, 15.908891121560046],
            [-91.797499853457566, 15.961109037384446],
            [-91.729172925513382, 16.0750001587556],
            [-91.70418194584974, 16.075554034919023],
            [-91.540008960654916, 16.075836002142651],
            [-90.99154599799806, 16.075744974673867],
            [-90.804455045438857, 16.075836002142651],
            [-90.625835850080847, 16.075554034919023],
            [-90.453772979404903, 16.074882141558518],
            [-90.447617812609906, 16.078844937753004],
            [-90.44195499880874, 16.088336102021216],
            [-90.432091007486235, 16.132773097106067],
            [-90.430072980472119, 16.172364178675465],
            [-90.457326906361203, 16.194436076731037],
            [-90.458072895745943, 16.256664164269708],
            [-90.438608943051833, 16.307500071894665],
            [-90.406535925737472, 16.335000090461719],
            [-90.38444609040917, 16.376944978087508],
            [-90.393335936941867, 16.401109166819609],
            [-90.406399971267547, 16.416382167914193],
            [-90.486117905659086, 16.461109009211853],
            [-90.50500887145057, 16.468609136194246],
            [-90.545290957119789, 16.473326974228812],
            [-90.590708970155077, 16.47639106276192],
            [-90.609172961797725, 16.48916424504678],
            [-90.645290917957709, 16.520691097548749],
            [-90.643481935609259, 16.563609124135766],
            [-90.644181992164476, 16.595273104574105],
            [-90.658063932604307, 16.644164243018892],
            [-90.678890950396834, 16.675827049990758],
            [-90.714282027911395, 16.726464138871663],
            [-90.748608938995858, 16.756945097492448],
            [-90.804735839195985, 16.805418146605703],
            [-90.866672907055658, 16.817773071920882],
            [-90.91909986754581, 16.829854076639251],
            [-90.948899880350666, 16.889164088398346],
            [-90.986681979571898, 16.896109165750872],
            [-91.063608907836141, 16.924445027704948],
            [-91.184445944750308, 17.035554027766594],
            [-91.268481900393681, 17.134026973479607],
            [-91.332226944773311, 17.188054039290435],
            [-91.433999845775787, 17.23721809250334],
            [-91.436954969567495, 17.25750011894975],
            [-90.983899858262021, 17.256109058294825],
            [-90.983608838582995, 17.585000020030336],
            [-90.983064014874998, 17.817773183213873],
            [-90.982417937776518, 17.820654043343339],
            [-90.979172967771973, 17.82000008725565],
            [-90.921672944189822, 17.82000008725565],
            [-90.777790870258855, 17.820273169661775],
            [-90.590835872169464, 17.821109013048996],
            [-90.238345997253646, 17.821109013048996],
            [-89.999999874271396, 17.820973226217077],
            [-89.742235899062592, 17.820827045825368],
            [-89.430435805587507, 17.81971812003205],
            [-89.141955005002018, 17.818882109007021],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "GUF", Country: "French Guiana" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-49.417829353999963, 7.006188542000032],
            [-51.633888888999962, 4.501388889000069],
            [-51.641842618, 4.487814550000024],
            [-51.645825754999862, 4.473209717000074],
            [-51.649863324999984, 4.458405292000123],
            [-51.648375412999883, 4.446997967000144],
            [-51.645852972, 4.427659249000016],
            [-51.641343916000039, 4.406616988000067],
            [-51.637832264999901, 4.390229283000124],
            [-51.63114834299995, 4.35146253400012],
            [-51.624464419999896, 4.319379706000163],
            [-51.619699615999821, 4.296917057000016],
            [-51.615106928999978, 4.27526581900004],
            [-51.60307586899998, 4.233825500000066],
            [-51.597728730999961, 4.188374828],
            [-51.609759790999959, 4.164312707000036],
            [-51.623127635999964, 4.137577018000016],
            [-51.62866123299986, 4.124296385000022],
            [-51.636495479999979, 4.105494190000059],
            [-51.640714673999923, 4.086929740000045],
            [-51.643179403000033, 4.076084932000157],
            [-51.661894384999869, 4.054696380000067],
            [-51.671251876999889, 4.046675673000081],
            [-51.683803917645889, 4.034395324117128],
            [-51.704172967900092, 4.026109025898705],
            [-51.728335815527544, 4.015835997700194],
            [-51.753890897276506, 4.000553944149928],
            [-51.771808891744655, 3.983473134173195],
            [-51.783617819885421, 3.961109042972311],
            [-51.793890848084033, 3.916664168898265],
            [-51.812326844169775, 3.876818109833465],
            [-51.906390908800347, 3.790836043905443],
            [-51.927081972122863, 3.776945051010117],
            [-51.981046006021757, 3.700000017834881],
            [-51.990008942750194, 3.657500080579283],
            [-51.995835874216169, 3.62583610014093],
            [-52.100281931786839, 3.448891106127846],
            [-52.165281914713319, 3.342217976877208],
            [-52.226745907956342, 3.25305397285689],
            [-52.249654990503416, 3.24374503116411],
            [-52.273608793465115, 3.241045052505683],
            [-52.344935772202149, 3.15740002882751],
            [-52.353054986554724, 3.131664065607609],
            [-52.344864023110773, 3.088473124252289],
            [-52.351954945578996, 3.040000075139204],
            [-52.379172996922421, 2.97416408118751],
            [-52.40861778466973, 2.922218074302833],
            [-52.464172871433817, 2.813335978283234],
            [-52.519726784731319, 2.697782054955411],
            [-52.552781993462389, 2.621245052827788],
            [-52.539863971890497, 2.586391087670222],
            [-52.5422268304022, 2.565554011593989],
            [-52.563754910578126, 2.522082108843364],
            [-52.594454972148554, 2.473891026953808],
            [-52.67562683146889, 2.374164148522155],
            [-52.789454920928108, 2.303336060663327],
            [-52.882781880991331, 2.224717999609638],
            [-52.896463996858984, 2.206809057596885],
            [-52.954726773632331, 2.176182086222411],
            [-52.994308802746332, 2.17569107433313],
            [-53.054863862406563, 2.186944952843987],
            [-53.06959086632682, 2.203053964963786],
            [-53.087364021507739, 2.219444944307369],
            [-53.111808836358904, 2.222500148023343],
            [-53.196108821953061, 2.213609128024174],
            [-53.239172861294065, 2.208336072721636],
            [-53.226108826968272, 2.264445035649132],
            [-53.296535756939932, 2.319009046178877],
            [-53.319863934646918, 2.347982100414114],
            [-53.340563882786768, 2.349653954826181],
            [-53.348335751070891, 2.322709153526858],
            [-53.458617792562762, 2.257500125934612],
            [-53.527781904998534, 2.249508987062839],
            [-53.701663983944712, 2.310282143844233],
            [-53.721535800048542, 2.341736073788425],
            [-53.746108858018573, 2.370973157974277],
            [-53.776808919588916, 2.364582124423364],
            [-53.799445925558103, 2.352364159406406],
            [-53.910281843213284, 2.277218050295815],
            [-53.928063883211621, 2.252782120261983],
            [-53.932635876130547, 2.228053997082924],
            [-54.109654966167966, 2.113473212716329],
            [-54.320554882401552, 2.161526999031508],
            [-54.464654885815776, 2.211735940297288],
            [-54.518617913886175, 2.257500125934612],
            [-54.527226799023538, 2.289309113298188],
            [-54.541326836584716, 2.315209026544537],
            [-54.573890865788456, 2.325282062532793],
            [-54.593617842605056, 2.329718100981808],
            [-54.603781906062068, 2.329191046908718],
            [-54.499172904293175, 2.365000046116705],
            [-54.420563895694897, 2.434718034715814],
            [-54.365908857696382, 2.496600117290441],
            [-54.351955000526999, 2.523891091191729],
            [-54.321808815119994, 2.598473098217184],
            [-54.315135814345382, 2.629164107332116],
            [-54.256955012585308, 2.718891039971354],
            [-54.222917780075477, 2.75555398747737],
            [-54.204590916369227, 2.775000002898764],
            [-54.195281974676533, 2.796244942384618],
            [-54.160835872928487, 2.953609139973068],
            [-54.176117926478753, 3.04389112224203],
            [-54.195745996837672, 3.096200065535186],
            [-54.208472911016742, 3.124309113188772],
            [-54.206117931493964, 3.145282143734818],
            [-54.187226798064302, 3.19486411864122],
            [-54.118755030832801, 3.277782096035949],
            [-54.094454887630974, 3.29500003394898],
            [-54.071108940289264, 3.312782073947318],
            [-54.001108816828349, 3.448336056498036],
            [-53.986117782957194, 3.601527072121783],
            [-53.99778187181073, 3.626945025934262],
            [-54.12764605083197, 3.788609139863567],
            [-54.14639083623166, 3.79778212708635],
            [-54.188681896459684, 3.808744985918096],
            [-54.290281962618224, 3.933891159266878],
            [-54.360763877874916, 4.042427082684341],
            [-54.36500880893081, 4.163609118734172],
            [-54.393890835697476, 4.237217986798214],
            [-54.431399852512328, 4.3691642305741],
            [-54.440235887226549, 4.410644928400757],
            [-54.451808948611131, 4.512364185222765],
            [-54.442917928611962, 4.530691048929029],
            [-54.423263874353324, 4.564309018640955],
            [-54.422090910819634, 4.601527015776696],
            [-54.439726770426091, 4.669164113087731],
            [-54.473335855320784, 4.734582185345815],
            [-54.477781952053533, 4.754164155237021],
            [-54.476108924175065, 4.867773141746667],
            [-54.455281906382652, 5.004027013214341],
            [-54.448063914261922, 5.024164200373619],
            [-54.408617839617733, 5.080553957058157],
            [-54.378890917008817, 5.112218105134403],
            [-54.347781986200374, 5.148336061294415],
            [-54.317326843841528, 5.208627091003848],
            [-54.28348189219102, 5.254864183619645],
            [-54.244999904052747, 5.285000143104668],
            [-54.193335864391685, 5.315273062888082],
            [-54.170699864250906, 5.342218031825311],
            [-54.166525158018914, 5.347595021118053],
            [-54.145279159999973, 5.371880063000134],
            [-54.111121372999833, 5.406037849],
            [-54.086072329999922, 5.437918450000012],
            [-54.065577658, 5.46752186500008],
            [-54.0428058, 5.506234023000104],
            [-54.029142685000011, 5.542668995],
            [-54.029142685000011, 5.572272410000068],
            [-54.031419870999855, 5.606430197000023],
            [-54.026865499999872, 5.636033612000091],
            [-54.017756755999926, 5.665637027000159],
            [-54.001816455999972, 5.690686069999984],
            [-53.985876156000018, 5.718012300000112],
            [-53.967147940999951, 5.754324106000027],
            [-53.966950573999952, 5.755429728000024],
            [-53.965940807999885, 5.762064685000112],
            [-53.964399747999835, 5.768828809000141],
            [-53.963000400999874, 5.776375674000178],
            [-53.962598209999896, 5.778137479000023],
            [-53.960563760999975, 5.785661763000149],
            [-53.959302024999943, 5.791090979000032],
            [-53.955014643999846, 5.802970397],
            [-53.949745198999949, 5.814760057000171],
            [-53.94798618199988, 5.818550075000047],
            [-53.946533356000032, 5.821272979000014],
            [-53.946256283000025, 5.823590697000114],
            [-53.943781243999865, 5.830860932000093],
            [-53.942061493999859, 5.83552301600001],
            [-53.937733738999896, 5.845537091500034],
            [-53.936657974999974, 5.848213273],
            [-53.891852394000011, 5.946441748000098],
            [-53.886874509999984, 5.958465692000118],
            [-53.802065101999858, 6.158692586000129],
            [-53.72198905, 6.350381673000172],
            [-53.715060147999907, 6.367084492000075],
            [-53.473706027, 6.943567262],
            [-53.069731575999981, 7.910777699],
            [-52.6640418369999, 8.877153263000096],
            [-52.657069177000039, 8.873935614000047],
            [-52.624728237999932, 8.865320436000161],
            [-52.621769947999866, 8.864477731500116],
            [-52.618883580999977, 8.863687808000179],
            [-52.617334346999854, 8.862972583000058],
            [-52.613849867999846, 8.862047812000014],
            [-52.611978922999953, 8.861214337500016],
            [-52.610152590499865, 8.860464807500094],
            [-52.608036695499948, 8.859596873000086],
            [-52.605953812999957, 8.85870907550013],
            [-52.604016760499974, 8.857888606000145],
            [-52.51580794900002, 8.817197433999979],
            [-52.511271088999877, 8.815992344],
            [-52.215758288999979, 8.679360723000059],
            [-52.058831164999958, 8.637550085],
            [-51.821320076999854, 8.527474590000139],
            [-51.646652236999955, 8.480789743000017],
            [-51.638869167, 8.477176855000138],
            [-51.420415700000035, 8.418699237000027],
            [-50.889695857999982, 8.171704255000023],
            [-50.411050675999945, 7.836821069999985],
            [-50.304780859999937, 7.730512973],
            [-50.243015079, 7.701630314000155],
            [-49.765181786999989, 7.366330675000029],
            [-49.459504298999889, 7.035322232000141],
            [-49.417829353999963, 7.006188542000032],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "GUY", Country: "Guyana" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-55.775762189, 9.321539308000126],
            [-55.776305529999917, 9.320455675000176],
            [-55.911029619999965, 9.051763166],
            [-55.92077751100004, 9.023210691000116],
            [-56.01208957800003, 8.741473684000042],
            [-56.057614459999911, 8.591745570000157],
            [-56.076524948999975, 8.531445685],
            [-56.094946363999867, 8.47230335600004],
            [-56.116562058999932, 8.390340521000098],
            [-56.200689143999881, 7.937048324000088],
            [-56.200847772999964, 7.936204615000179],
            [-56.20297017199988, 7.924384240000037],
            [-56.360621192999929, 7.514205617000158],
            [-56.41068567100001, 7.389644164000146],
            [-56.528778984999974, 7.135106226000062],
            [-56.588848077999955, 7.006524660000139],
            [-56.620134699999937, 6.93611662100011],
            [-56.624341471, 6.925554491000071],
            [-56.643559214, 6.884989835000169],
            [-56.648997061999978, 6.873592557000038],
            [-56.67300162899997, 6.82363774900017],
            [-56.684506873999965, 6.799873494000153],
            [-56.719014287999983, 6.729137115000142],
            [-56.731309239000041, 6.703445783000106],
            [-56.734918893999918, 6.695997062000103],
            [-56.789784600999866, 6.581608577000168],
            [-56.805924922999964, 6.548807108000076],
            [-56.815221726999937, 6.529249813999982],
            [-56.830823409999937, 6.497650120000031],
            [-56.838218061999896, 6.480852529000103],
            [-56.853709001, 6.449664297],
            [-56.878891559999943, 6.40601806200003],
            [-56.949857044999987, 6.280828670000105],
            [-56.999049360999834, 6.195098241],
            [-57.010589384999861, 6.171288585000042],
            [-57.011300805999838, 6.169449863000054],
            [-57.014195327999886, 6.162358035000182],
            [-57.024987174999922, 6.135433740000096],
            [-57.032012868999914, 6.121082779],
            [-57.034454621999885, 6.115927840000083],
            [-57.035824999999846, 6.112930603000066],
            [-57.041763630999981, 6.099466784000015],
            [-57.047444976999941, 6.087074114000046],
            [-57.052952281999978, 6.076937895000171],
            [-57.055107532999955, 6.073396527000071],
            [-57.055710323, 6.072396476000108],
            [-57.056832084999911, 6.070511749000133],
            [-57.057889360499956, 6.068679460000041],
            [-57.059815692999962, 6.06530237400014],
            [-57.062277105999954, 6.060682738],
            [-57.063420344999855, 6.058432982000014],
            [-57.064932168999889, 6.055425373000034],
            [-57.065520676999824, 6.054561795999987],
            [-57.066321687999846, 6.052998921],
            [-57.070720618999928, 6.043342205],
            [-57.071537120999892, 6.041415616000023],
            [-57.072204590999974, 6.039185561000068],
            [-57.073482473000013, 6.034430773000125],
            [-57.085233360999894, 6.008073870000089],
            [-57.08680997899998, 6.003083225000069],
            [-57.087088611999832, 6.00209661600006],
            [-57.091359464999982, 5.987624916000115],
            [-57.092424965999953, 5.982623907000132],
            [-57.098654911999915, 5.951792094000027],
            [-57.107181576999949, 5.926212097000061],
            [-57.11911890899998, 5.881873435000088],
            [-57.129350907999878, 5.844356105999978],
            [-57.134466907999865, 5.81195477600005],
            [-57.142993572999984, 5.77614278],
            [-57.148109572999886, 5.736920118000114],
            [-57.151520239, 5.701108122000065],
            [-57.15834157199987, 5.65165346],
            [-57.166868236999989, 5.60049346600016],
            [-57.177100235999973, 5.56468147000011],
            [-57.192448234999858, 5.539101473000144],
            [-57.226554897999847, 5.504994809999971],
            [-57.248508856406005, 5.486109161563135],
            [-57.271599872457415, 5.38486414947279],
            [-57.299445895988612, 5.359718104600034],
            [-57.324445928107565, 5.303609141672624],
            [-57.282781834238818, 5.226391026091193],
            [-57.262226893024376, 5.221664135601145],
            [-57.235208833891221, 5.259944958062675],
            [-57.207508823113727, 5.223609072419407],
            [-57.19104592704096, 5.172009070498973],
            [-57.231526831453863, 5.14645398875011],
            [-57.250554925181916, 5.17263603685825],
            [-57.272090884346881, 5.177082133591071],
            [-57.292364025975786, 5.166245003307068],
            [-57.321945941659493, 5.075973079321813],
            [-57.327226875950856, 5.026109137191881],
            [-57.417090936526392, 4.989445016219292],
            [-57.473617821147315, 4.98916422246225],
            [-57.610981959512884, 4.992218085073659],
            [-57.634173009283387, 5.00028214650321],
            [-57.676255024845659, 5.011526972558755],
            [-57.841117840673775, 4.927782036594408],
            [-57.901390933110207, 4.855554003263407],
            [-57.9233357615133, 4.821945086006792],
            [-57.924863950104424, 4.796391177724502],
            [-57.909026846924348, 4.777782179156631],
            [-57.887781907438494, 4.768336109527652],
            [-57.8403549198446, 4.669026985151348],
            [-57.846945945605853, 4.628891079873924],
            [-57.902781993765046, 4.503609119693408],
            [-57.948617928493945, 4.349164171351205],
            [-57.949172978123755, 4.317218056051345],
            [-57.954517782517485, 4.288336029284778],
            [-58.00278195460308, 4.239717973246201],
            [-58.039172993169302, 4.211391163747621],
            [-58.061808825671903, 4.180136052547567],
            [-58.071399902226375, 4.155691070058239],
            [-58.04375504437192, 4.001527083111256],
            [-58.016399865092211, 3.963609029420297],
            [-57.968890902484958, 3.929164101138838],
            [-57.9504178583868, 3.913191043488951],
            [-57.931945987755142, 3.890691165456062],
            [-57.876672868214769, 3.809164081078094],
            [-57.86749988099217, 3.790836043905443],
            [-57.859454930301837, 3.771664116718654],
            [-57.849308804117641, 3.738891042849261],
            [-57.845972974282517, 3.692636012960548],
            [-57.838481899755607, 3.669309008720049],
            [-57.816672858184546, 3.650282088458567],
            [-57.760563895257235, 3.624164078090871],
            [-57.740835744974049, 3.613745037138557],
            [-57.668617937564932, 3.538336071543569],
            [-57.654445983274485, 3.518609094727054],
            [-57.638408887883969, 3.457009147014034],
            [-57.657781980747501, 3.413891128216576],
            [-57.642226844791139, 3.356391104634326],
            [-57.610417857427478, 3.360000016875645],
            [-57.530554916110731, 3.346391158841826],
            [-57.429454914296969, 3.347218117411543],
            [-57.304172954116552, 3.380417997791966],
            [-57.28354592853438, 3.318682095609034],
            [-57.292499812807705, 3.267364228550207],
            [-57.289999826359633, 3.181391047439419],
            [-57.283617845263848, 3.140973174938466],
            [-57.255008900903675, 3.103608997410845],
            [-57.22028183776041, 3.065000107258072],
            [-57.210281891968009, 3.035835945629898],
            [-57.207217803434617, 3.004236002932089],
            [-57.213335754579504, 2.878891178477616],
            [-57.200835822339116, 2.822845079824262],
            [-57.116881841709016, 2.757191140810278],
            [-56.944726770097844, 2.4830539558927],
            [-56.917781968798607, 2.428053918758621],
            [-56.853890911665275, 2.283336001440617],
            [-56.810564015840242, 2.193335986395084],
            [-56.774999935843567, 2.14083610334707],
            [-56.682090897473842, 2.026764100504678],
            [-56.64611778060106, 2.028054075407042],
            [-56.579726904657633, 2.027364077135729],
            [-56.555972926268311, 2.020553948424634],
            [-56.477363917669919, 1.962082126985536],
            [-56.470635763972183, 1.944500079197553],
            [-56.52555499955966, 1.922500097871534],
            [-56.577917922309268, 1.92166408684642],
            [-56.594999905752502, 1.932218076440236],
            [-56.628199953770803, 1.939444953378455],
            [-56.73500886985326, 1.914100089761732],
            [-56.752090853296579, 1.896945016122672],
            [-56.770690967046932, 1.875626986440878],
            [-56.873890970888255, 1.891664081831294],
            [-57.003890936741215, 1.92291801956496],
            [-57.050835797263261, 1.952218135663131],
            [-57.060835743055662, 1.984164083324913],
            [-57.074235891699715, 2.015764193660715],
            [-57.100208895142003, 2.022782193571075],
            [-57.163063949039952, 1.996664183203478],
            [-57.199172852744368, 1.971109101454616],
            [-57.235345961827505, 1.949791071772822],
            [-57.276672935549527, 1.979509109564319],
            [-57.298190957441562, 1.981945058272046],
            [-57.331946055089787, 1.972218027247763],
            [-57.425490944636294, 1.901391112855492],
            [-57.428054968824711, 1.871244927448501],
            [-57.450908898448773, 1.811109130586345],
            [-57.512364006874463, 1.730835978927018],
            [-57.528063982118056, 1.715836060238459],
            [-57.559308867396425, 1.699717995663264],
            [-57.633335824792056, 1.692782138404155],
            [-57.653617851238295, 1.696664133051669],
            [-57.687499850901105, 1.7075000898691],
            [-57.722499828812374, 1.717500035661601],
            [-57.759445917008492, 1.717845034797165],
            [-57.87610893376754, 1.665000152613501],
            [-57.972499814726177, 1.613473073250745],
            [-57.982917849849997, 1.572364196649986],
            [-58.007154961139975, 1.515691131637453],
            [-58.127781947559896, 1.517782081209333],
            [-58.268335903006772, 1.570554040835148],
            [-58.297226814590744, 1.582782064135912],
            [-58.387290867376635, 1.477844994675777],
            [-58.502290914541135, 1.454673055644477],
            [-58.46930879600589, 1.358473114440883],
            [-58.46764599404932, 1.337636038364565],
            [-58.475145953393564, 1.314027066900536],
            [-58.519617817195837, 1.269618067372221],
            [-58.572499915029624, 1.275282054639959],
            [-58.692990946979734, 1.284718065985047],
            [-58.708890914433766, 1.262918076869283],
            [-58.722917861798891, 1.232364195690749],
            [-58.75194590131926, 1.209582182796254],
            [-58.810699857619966, 1.186873092459521],
            [-58.836399946294051, 1.190282180128577],
            [-58.869590941857155, 1.205000131593522],
            [-58.898408930883278, 1.231109089505722],
            [-58.897363875192298, 1.261835973166285],
            [-58.924863893759522, 1.299309115435491],
            [-58.957508892148127, 1.314444988593962],
            [-58.972781893242882, 1.319718043896316],
            [-59.104726795914104, 1.344718076015369],
            [-59.243963954368382, 1.386527009171346],
            [-59.41445488291842, 1.563336048714518],
            [-59.483617821887634, 1.650553942450372],
            [-59.507508928213554, 1.687500030646305],
            [-59.605417939479509, 1.719444972479778],
            [-59.645763895251008, 1.735136062906051],
            [-59.67528194083215, 1.767082178205996],
            [-59.653617906186383, 1.802218110587177],
            [-59.632472878986533, 1.842235998667618],
            [-59.726317840667633, 1.854164117471854],
            [-59.749026763366203, 1.861391162048065],
            [-59.756254981408603, 1.908891072199737],
            [-59.743617921232016, 2.072782090171003],
            [-59.727017897222765, 2.241244933422948],
            [-59.735490827890231, 2.28471800963996],
            [-59.793199896138418, 2.297782043965753],
            [-59.849726780759255, 2.330553944368759],
            [-59.893263894716711, 2.36410016498931],
            [-59.901672955281924, 2.384445055709691],
            [-59.888890888179645, 2.407082061678878],
            [-59.892781935282756, 2.454445011532513],
            [-59.925563893969553, 2.567782089102266],
            [-59.954173005968016, 2.616391092685433],
            [-59.988472927324068, 2.688191146039102],
            [-59.982781950328189, 2.860282179909746],
            [-59.978890903225277, 2.899164152468714],
            [-59.962781891105493, 3.025835999837497],
            [-59.949717856779699, 3.071945016972492],
            [-59.904726817893419, 3.204164175516496],
            [-59.869726839982064, 3.27639103538111],
            [-59.856117814310352, 3.297782155258844],
            [-59.835699833393932, 3.322082130822679],
            [-59.810835755744876, 3.359582095182034],
            [-59.814446009090574, 3.498744989974156],
            [-59.831945914227305, 3.524164117252937],
            [-59.786117858487614, 3.62055399238308],
            [-59.665554909807952, 3.711391024282037],
            [-59.568608811581697, 3.899445057518847],
            [-59.581673013545384, 3.998891142193273],
            [-59.708617775682569, 4.170691156384976],
            [-59.722226801354282, 4.191391104524911],
            [-59.731108936536032, 4.219164205498146],
            [-59.730835854129737, 4.293336002180936],
            [-59.67528194083215, 4.373336071434153],
            [-59.674445929807121, 4.385135947119423],
            [-59.717226828457939, 4.414164154277685],
            [-59.791117831383673, 4.456109041903474],
            [-59.941808860559348, 4.508054042959841],
            [-59.967781864001637, 4.501944976632274],
            [-60.045281946806597, 4.494027095594518],
            [-60.130699910649582, 4.509718018382799],
            [-60.148472898192324, 4.520000099036906],
            [-60.152226817358951, 4.573335993109851],
            [-60.123890955404875, 4.596664170816922],
            [-60.091526918411603, 4.600691172389659],
            [-60.072090961274, 4.618473212387997],
            [-60.02583576374721, 4.707218121248502],
            [-60.020563881911244, 4.734445057409431],
            [-60.005835872162407, 4.836945004695451],
            [-59.983063917551902, 5.022500057312485],
            [-60.013890881136547, 5.110000085909846],
            [-60.080873016531683, 5.161509060361794],
            [-60.081945900141108, 5.173053958551677],
            [-60.098335873656197, 5.21721803886841],
            [-60.114581846074202, 5.24569102875887],
            [-60.202364009533255, 5.272709087892039],
            [-60.249999874155009, 5.258473095861049],
            [-60.268408880512538, 5.235282046090362],
            [-60.31784601613171, 5.197636068977118],
            [-60.546390844427435, 5.191391048179824],
            [-60.573826825254031, 5.197153941905256],
            [-60.597426911900811, 5.211944983566056],
            [-60.68944595978877, 5.216109113075262],
            [-60.730372949090082, 5.204800081641281],
            [-60.748890920189382, 5.222500146626345],
            [-60.781108944428865, 5.258335967924751],
            [-60.860281887474372, 5.348054015746669],
            [-61.116108804853951, 5.63471812263279],
            [-61.38972681468718, 5.940000112904272],
            [-61.373455026007264, 5.960973143450403],
            [-61.337890946010674, 5.972782071591084],
            [-61.323064029804129, 5.992500163590449],
            [-61.282817818680655, 6.056336067800757],
            [-61.270281844256345, 6.08639105810083],
            [-61.262781884912215, 6.107782177978379],
            [-61.160145983156184, 6.182500139473831],
            [-61.138617902980258, 6.299164162061373],
            [-61.14000879599709, 6.408609019061814],
            [-61.196526795800509, 6.53368210221447],
            [-61.204726811700056, 6.577918099260756],
            [-61.174446012927717, 6.658744959445343],
            [-61.125108957232698, 6.714773120825953],
            [-61.054726786624201, 6.728891095659947],
            [-61.035972948768915, 6.719718108437164],
            [-60.93965499036824, 6.724582126863424],
            [-60.893681927703142, 6.76500016700254],
            [-60.816117807157639, 6.788336056060459],
            [-60.719172882397686, 6.759027055144969],
            [-60.697781930158044, 6.766664142425498],
            [-60.676390977918572, 6.790000031483416],
            [-60.664999971471218, 6.807500104258139],
            [-60.637781920127708, 6.834927032629338],
            [-60.590835886139388, 6.850836052538952],
            [-60.526946002472528, 6.874718106409091],
            [-60.498890934275494, 6.887782140734885],
            [-60.463617873958015, 6.90666422170905],
            [-60.408335869600137, 6.947500015903515],
            [-60.291254931147478, 7.056600041406384],
            [-60.281390939825059, 7.090000081635225],
            [-60.278054942351872, 7.118744980465323],
            [-60.309035797679428, 7.143473103644553],
            [-60.336672944182851, 7.155282199423311],
            [-60.360490960312717, 7.176245004047473],
            [-60.4561179146136, 7.195836026394261],
            [-60.505763927260546, 7.172709014364102],
            [-60.514999946395363, 7.142982091755101],
            [-60.538063926513473, 7.124445009916599],
            [-60.617499893681384, 7.194444965739507],
            [-60.630835836946801, 7.221944984306546],
            [-60.63423587216036, 7.254309021299846],
            [-60.618617871930127, 7.284027059091429],
            [-60.595354905430071, 7.306109015430721],
            [-60.591390935769113, 7.336391155307624],
            [-60.609999934337054, 7.381944955174717],
            [-60.660835841962012, 7.447218020507492],
            [-60.688263943799598, 7.453473099588678],
            [-60.714026896747924, 7.500973177378526],
            [-60.716664011132195, 7.540000156862718],
            [-60.691108929383518, 7.567082086098239],
            [-60.662090948146954, 7.566527036468429],
            [-60.588754994851115, 7.63930911360093],
            [-60.574581867094082, 7.715209091085299],
            [-60.533335862557038, 7.803891135671833],
            [-60.513199848864332, 7.818191165443267],
            [-60.319726915209543, 7.877500171373867],
            [-60.257545933966782, 7.921573056583853],
            [-60.23722685950824, 7.94639103376538],
            [-60.12486392672821, 8.028326987381277],
            [-60.093608815528057, 8.041245008953268],
            [-60.037781987462353, 8.037009130352772],
            [-60.011181850022808, 8.059236093617443],
            [-59.987017828928884, 8.146982047254696],
            [-59.979726914250307, 8.174864112969615],
            [-59.946181867096243, 8.199991047102984],
            [-59.922226890668156, 8.211109138782021],
            [-59.888890888179645, 8.218609098126436],
            [-59.832855015448189, 8.231527119698342],
            [-59.803055002643418, 8.28333599864672],
            [-59.812090861929818, 8.306109126723797],
            [-59.886117819325364, 8.400836032259292],
            [-59.981117807267054, 8.518327013416368],
            [-59.990281909836114, 8.535273210013997],
            [-59.986117837999899, 8.5385189080001],
            [-59.963581512999866, 8.55901625000017],
            [-59.957293668, 8.567765228000113],
            [-59.932522943999913, 8.60223149],
            [-59.569407030999912, 9.063848620000044],
            [-59.492528731999926, 9.160634796000181],
            [-59.481781179999899, 9.180354989000065],
            [-59.477450546999876, 9.188301069000104],
            [-59.146968118999894, 9.792291618000078],
            [-59.088970199999864, 9.898548047000148],
            [-59.057041902, 9.957043023000082],
            [-59.055172204999934, 9.960426555000083],
            [-59.052745564999981, 9.964817966000169],
            [-59.044012250999913, 9.980555900000169],
            [-58.954945716, 10.142316940000029],
            [-58.902283962999974, 10.152885942000012],
            [-58.887168687999974, 10.15681417100005],
            [-58.829328111999985, 10.172124731000039],
            [-58.801063746999858, 10.179606387000106],
            [-58.753615970999846, 10.192231009000082],
            [-58.502693674999989, 10.258994927000131],
            [-58.374841132999876, 10.293556319000174],
            [-58.328847084999893, 10.307388296000042],
            [-58.26503543799987, 10.326578631000061],
            [-58.163994606999864, 10.36055775800007],
            [-58.129787935999957, 10.372061155],
            [-58.096607221999932, 10.383336056000033],
            [-57.994334470999974, 10.418088627000088],
            [-57.963534491999837, 10.428524052000029],
            [-57.936380256999911, 10.437724254],
            [-57.873431466999961, 10.459153167000053],
            [-57.814702555999929, 10.479145556000091],
            [-57.759170099999977, 10.498229830000128],
            [-57.309874260999862, 10.674757307000164],
            [-57.286916251999855, 10.683777465000063],
            [-57.105762006, 10.69331238300002],
            [-56.979511312, 10.605735924000115],
            [-56.978322579999968, 10.604556570000057],
            [-56.976484081, 10.603279063000045],
            [-56.973429504999956, 10.600248499000031],
            [-56.970492076, 10.598207303999985],
            [-56.96812789699996, 10.595861615000146],
            [-56.965475440999967, 10.594017608000073],
            [-56.962448634, 10.591913417000143],
            [-56.958595957999989, 10.588090639000129],
            [-56.957467936999819, 10.58730670700001],
            [-56.949517647999983, 10.579417708],
            [-56.946436046, 10.577275949000153],
            [-56.932185713999985, 10.563134015000131],
            [-56.931437885, 10.562614200000112],
            [-56.892949379999834, 10.524410814],
            [-56.889373796000029, 10.521924663],
            [-56.887917822999896, 10.520479228000127],
            [-56.886377802000027, 10.519408400000074],
            [-56.88211078400002, 10.515172132000131],
            [-56.871361749999977, 10.507697414000106],
            [-56.51303465899997, 10.151458665000135],
            [-56.510079192999854, 10.149397908000068],
            [-56.095939543999975, 9.736546083000164],
            [-56.095060332999878, 9.735291087000064],
            [-56.093961521000011, 9.734194141000074],
            [-56.092654730999982, 9.732328785],
            [-56.090948740999949, 9.73062565900014],
            [-56.090510124, 9.72999955100002],
            [-56.087963768999884, 9.7274574260001],
            [-56.084992755, 9.723216291000014],
            [-56.080994666999857, 9.719224668999971],
            [-56.080197142499912, 9.718174669000135],
            [-56.075243692000015, 9.711191663000122],
            [-56.074002372999871, 9.709952278000159],
            [-56.073212208999962, 9.70882420900017],
            [-56.071018490999904, 9.706633870000033],
            [-56.069110722999966, 9.703939079000094],
            [-56.067507831999961, 9.701679524000099],
            [-56.062039470999849, 9.696219305000042],
            [-56.035704887999884, 9.658616135000031],
            [-56.02917965, 9.65209908300011],
            [-56.026727635999976, 9.648597309000095],
            [-56.009197418000014, 9.631087378000117],
            [-56.008126652999863, 9.629557992000045],
            [-56.005208855999854, 9.626643352000031],
            [-56.004263268499926, 9.625304187000154],
            [-56.003202359999932, 9.62380029200007],
            [-55.991258652999988, 9.611868753000152],
            [-55.975528952999952, 9.5894925045001],
            [-55.963351516999921, 9.572189096000017],
            [-55.961388901999896, 9.570227991000024],
            [-55.938886976999981, 9.538074442000081],
            [-55.920397137999913, 9.519548733500116],
            [-55.919462369999934, 9.518552870000178],
            [-55.918524042999934, 9.51759928400007],
            [-55.775762189, 9.321539308000126],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "HMD", Country: "Heard I. & McDonald Is." },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.055709562000203, -53.235255697999953],
            [68.092098563000178, -52.707754697000034],
            [69.73404256500001, -51.971643695999973],
            [71.208209566000136, -51.40886569599995],
            [72.474598567000186, -51.052476695999985],
            [72.822653568000106, -50.906364694999972],
            [75.602087570000066, -49.826204694],
            [75.620332570000215, -49.83091769399995],
            [75.642420570000098, -49.836695693999928],
            [75.664472570000186, -49.842535693999963],
            [75.686488570000137, -49.848436693999972],
            [75.708466571000059, -49.854398693999983],
            [75.730408571000027, -49.860423693999962],
            [75.752311571000149, -49.866508694999922],
            [75.77417657100014, -49.872655694999963],
            [75.796002571000173, -49.878863694999893],
            [75.817790571000188, -49.885132694999896],
            [75.83953857100019, -49.891462694999973],
            [75.861246571000066, -49.897853695000023],
            [75.882914571000157, -49.904304695],
            [75.90454157100001, -49.910816694999937],
            [75.926128571000191, -49.917388694999971],
            [75.947673571000138, -49.924021694999979],
            [75.969176571000133, -49.930714694999978],
            [75.990637571000065, -49.937467694999967],
            [76.012056571000102, -49.944281695000043],
            [76.033432571000134, -49.951154694999929],
            [76.054764571000163, -49.958086694999977],
            [76.076052571000019, -49.965079695000028],
            [76.097297571000041, -49.972131694999973],
            [76.118497571000177, -49.979242695000011],
            [76.139652571000198, -49.986413694999953],
            [76.160761571000165, -49.993642695],
            [76.181825571000132, -50.000931695],
            [76.202843571000045, -50.008278694999859],
            [76.223814571000133, -50.015685694999959],
            [76.244739571000224, -50.02315069499997],
            [76.265616571000095, -50.03067369499999],
            [76.286445571000087, -50.038255695000018],
            [76.307227571000084, -50.045895694999942],
            [76.327960571000204, -50.053593694999961],
            [76.348644571000165, -50.061348695],
            [76.369279571000078, -50.069162694999932],
            [76.389865571000115, -50.077033694999969],
            [76.410400571000167, -50.084962695000016],
            [76.430885571000118, -50.092948694999976],
            [76.451320571000082, -50.100992694999931],
            [76.47170357100012, -50.109092695],
            [76.492034571000119, -50.117250694999981],
            [76.512314571000132, -50.125464694999977],
            [76.53254157100011, -50.133735694999984],
            [76.552716571000161, -50.142062695],
            [76.572838571000176, -50.150446695000028],
            [76.592906571000157, -50.158886694999985],
            [76.612920571000046, -50.167382694999951],
            [76.632880571000129, -50.175934695000024],
            [76.652785571000123, -50.184542694999934],
            [76.672636571000027, -50.193205694999953],
            [76.692431571000071, -50.201923695],
            [76.712170571000087, -50.210697694999965],
            [76.731853571000016, -50.219526694999949],
            [76.75148057100003, -50.228410695000051],
            [76.771050571000188, -50.237349695],
            [76.790562572000084, -50.24634269499996],
            [76.810017572000078, -50.255390694999946],
            [76.82941357200005, -50.264492694999966],
            [76.848752572000109, -50.273649695],
            [76.868031572000092, -50.282859694999985],
            [76.887251572000167, -50.292123695],
            [76.906412572000164, -50.301441694999937],
            [76.925513572000085, -50.31081269500001],
            [76.94455357200016, -50.320237695000017],
            [76.963533572000216, -50.329715694999969],
            [76.982452572000142, -50.33924569499996],
            [77.001309572000224, -50.348829694999978],
            [77.020104572000122, -50.358465694999943],
            [77.038837572000119, -50.368153694999954],
            [77.05750857200016, -50.377894695],
            [77.076116572000132, -50.387687694999983],
            [77.094660572000151, -50.397532694999924],
            [77.113140572000106, -50.407428695],
            [77.131557572000162, -50.41737669500003],
            [77.149909572000212, -50.427376695],
            [77.168196572000085, -50.43742769499994],
            [77.186419572, -50.44752869500001],
            [77.204575572000152, -50.457681694999948],
            [77.222666572000065, -50.467884695000023],
            [77.240690572000204, -50.478138695000055],
            [77.258648572000226, -50.488441694999963],
            [77.276539572000132, -50.498795695000013],
            [77.294362572000153, -50.509199695000021],
            [77.312118572000173, -50.519653694999988],
            [77.32980557200014, -50.530156695000016],
            [77.347424572000165, -50.540708695000014],
            [77.364974572000193, -50.551310694999977],
            [77.382455572000168, -50.561960695],
            [77.39986657200015, -50.572659695],
            [77.417207572000194, -50.583407694999948],
            [77.434478572000017, -50.594203694999976],
            [77.451679572000018, -50.605047694999975],
            [77.468808572000199, -50.615939694999945],
            [77.485866572000162, -50.626879694999985],
            [77.502852572000137, -50.637867695],
            [77.519766572000123, -50.64890269499999],
            [77.536608572000063, -50.659983694999966],
            [77.553376572000133, -50.671112695000012],
            [77.5700725720001, -50.682288694999947],
            [77.586694572000141, -50.693510695000043],
            [77.603242572000028, -50.704778694999952],
            [77.619715572000104, -50.716093695000019],
            [77.63499357200007, -50.726576695],
            [77.650207572000141, -50.737098695],
            [77.665356572000206, -50.747659695000024],
            [77.683041572, -50.758303695],
            [77.700656572000156, -50.768995694999944],
            [77.718201572000027, -50.779737694999938],
            [77.735675572000133, -50.790528695],
            [77.753077572000137, -50.801367695000025],
            [77.770409572000148, -50.812255695000019],
            [77.787668572000172, -50.823191695],
            [77.804856572000148, -50.834175694999942],
            [77.82197057200014, -50.845207694999949],
            [77.839012572000144, -50.856287694999935],
            [77.855980573000039, -50.867414695],
            [77.87287557300013, -50.878588695000026],
            [77.889696573000123, -50.889809694999954],
            [77.906442573000135, -50.901077694999955],
            [77.923114573000163, -50.91239269499993],
            [77.939710573000099, -50.923753694999988],
            [77.956231573000224, -50.935160695000029],
            [77.9726765730002, -50.946614696000047],
            [77.989045573000027, -50.958113695999977],
            [78.005338573000046, -50.969657695999985],
            [78.021553573000091, -50.981247695999976],
            [78.03769157300016, -50.992882695999967],
            [78.053752573000139, -51.004562695999944],
            [78.06973457300009, -51.016286696000016],
            [78.085639573000066, -51.028055695999988],
            [78.101464573000186, -51.039869696000039],
            [78.117211573000219, -51.05172669600001],
            [78.132878573000227, -51.063627695999983],
            [78.148465573000152, -51.075571695999955],
            [78.163972573000166, -51.087559696000021],
            [78.179399573000097, -51.09959169599999],
            [78.194745573, -51.111665695999974],
            [78.210010573000119, -51.123781695999973],
            [78.225193573000155, -51.13594069599997],
            [78.240295573000111, -51.148142695999979],
            [78.25531457300022, -51.160385696],
            [78.270251573000138, -51.172670695999948],
            [78.285105573000095, -51.184996696],
            [78.299875573000037, -51.197364695999973],
            [78.314562573000131, -51.209773695999964],
            [78.329166573000094, -51.222223695999972],
            [78.343684573000161, -51.234713696],
            [78.358119573000096, -51.24724469599996],
            [78.37246857300002, -51.259814696000042],
            [78.386732573000103, -51.272425695999956],
            [78.400911573000172, -51.285075696],
            [78.415003573000064, -51.297765695999963],
            [78.429009573000116, -51.310494695999964],
            [78.442929573000157, -51.323261696],
            [78.456761573000023, -51.33606869599997],
            [78.470507573000162, -51.348912695999985],
            [78.484164573000186, -51.361795695999938],
            [78.497734573000145, -51.374716696000014],
            [78.511216573000098, -51.387675696000038],
            [78.524608573000165, -51.400671696000011],
            [78.537912573000227, -51.413704696000032],
            [78.551127573000116, -51.426774696],
            [78.564252573, -51.439881696000015],
            [78.577287573000177, -51.453024695999986],
            [78.590232573000122, -51.466204696],
            [78.60308657300007, -51.479419695999972],
            [78.615849573000133, -51.492670696],
            [78.628521573000199, -51.505956696],
            [78.64110257300004, -51.519278696000029],
            [78.65359157300017, -51.532634695999946],
            [78.665987573000137, -51.546026696],
            [78.678291573000166, -51.559451695999947],
            [78.690502573000032, -51.572911695999949],
            [78.702620573000019, -51.586404695999924],
            [78.714645573000126, -51.599932695999954],
            [78.726576573000074, -51.613492695999966],
            [78.73841257300009, -51.627086695999949],
            [78.750155573000114, -51.640712696],
            [78.76180257300021, -51.654371695999949],
            [78.773355573000089, -51.668063695999955],
            [78.784812573000096, -51.681786695999953],
            [78.796174573000059, -51.695541696000028],
            [78.807439573000153, -51.709328696],
            [78.818609573000032, -51.723145695999953],
            [78.829682573000156, -51.736994695999982],
            [78.84065857300007, -51.750874696],
            [78.851537573000115, -51.764784695999936],
            [78.862318573000124, -51.778724696000033],
            [78.873002573000036, -51.792694695999955],
            [78.883588573000083, -51.806693695999961],
            [78.894075573000151, -51.820722695999969],
            [78.90446457300007, -51.834780695999974],
            [78.914753573000127, -51.848866695999988],
            [78.924944574000193, -51.862982696],
            [78.935035574000068, -51.877125696000014],
            [78.945026574000195, -51.891296696000047],
            [78.95491757400012, -51.905495696],
            [78.964708574000127, -51.919722695999951],
            [78.974398574000105, -51.933975696000012],
            [78.98398757400011, -51.948255696],
            [78.993475574000087, -51.962562696],
            [79.00286157400015, -51.976895696000028],
            [79.012146574000127, -51.991254695999977],
            [79.02132857400008, -52.005639695999953],
            [79.030408574000063, -52.020049696999948],
            [79.039385574000136, -52.034484696999975],
            [79.048260574000125, -52.048945697000015],
            [79.057031574000092, -52.063429697000011],
            [79.065699574000092, -52.077938697000029],
            [79.074263574000184, -52.092471696999986],
            [79.082723574000198, -52.107027696999985],
            [79.09107957400002, -52.121607696999931],
            [79.099330574000163, -52.136210697],
            [79.107444574000141, -52.150771696999968],
            [79.115453574000213, -52.165353696999986],
            [79.123359574000148, -52.179958696999954],
            [79.131159574000066, -52.194585696999965],
            [79.138855574000019, -52.209232696999941],
            [79.146445574000069, -52.223902696999957],
            [79.153930574000157, -52.238592696999945],
            [79.161310574000055, -52.253302696999981],
            [79.168583574000166, -52.268033696999986],
            [79.175750574000148, -52.282784696999954],
            [79.182811574000226, -52.297554696999981],
            [79.189765574000063, -52.312344696999979],
            [79.19661257400017, -52.327153697000043],
            [79.203352574000149, -52.341981696999987],
            [79.209985574000171, -52.356827697],
            [79.216510574000125, -52.371691696999989],
            [79.222927574000124, -52.386574696999958],
            [79.229236574000225, -52.401474697],
            [79.235436574000147, -52.416391696999931],
            [79.241528574000228, -52.431325697000034],
            [79.247512574000012, -52.44627669700003],
            [79.253386574000189, -52.461243697000015],
            [79.259150574000074, -52.476227697],
            [79.264806574000062, -52.491226696999959],
            [79.270351574000216, -52.506241697000014],
            [79.275787574000134, -52.521271696999975],
            [79.281112574000105, -52.536315697000028],
            [79.286327574000012, -52.551375696999983],
            [79.291432574000083, -52.566448696999949],
            [79.29642557400021, -52.581536697],
            [79.301308574000103, -52.59663769699997],
            [79.306079574000051, -52.611752697000036],
            [79.310738574000169, -52.626879696999943],
            [79.315286574000226, -52.642020697000028],
            [79.319723574000164, -52.657172696999957],
            [79.324047574000105, -52.672337696999982],
            [79.328258574000102, -52.687514697000026],
            [79.332357574000213, -52.702702697],
            [79.336344574000151, -52.717901697],
            [79.34021757400015, -52.733111697000027],
            [79.343978574000204, -52.748332696999981],
            [79.347625574000148, -52.76356369699996],
            [79.351159574000093, -52.77880469699997],
            [79.354578574000101, -52.794054697000014],
            [79.357884574000167, -52.809314697],
            [79.361076574000123, -52.824582697000025],
            [79.364154574000082, -52.839859697],
            [79.367117574000105, -52.855145697],
            [79.369966574000188, -52.870439696999952],
            [79.372700574000163, -52.885740696999953],
            [79.375319574000144, -52.901049697],
            [79.377822574000135, -52.916364697],
            [79.380211574000015, -52.931687697000044],
            [79.382484574000131, -52.947016697000052],
            [79.384641574000085, -52.962351697000024],
            [79.386682574000105, -52.977692696999959],
            [79.388607574000133, -52.993038697000038],
            [79.390417574000168, -53.008389697],
            [79.392109574000159, -53.023745697000024],
            [79.3936865740001, -53.039106697000015],
            [79.395146574000108, -53.054471696999983],
            [79.396488574000188, -53.069840697000018],
            [79.397714574000048, -53.085212697000031],
            [79.398823574000147, -53.100587698000027],
            [79.399815574000144, -53.115965698],
            [79.400689574000154, -53.131346697999959],
            [79.401446574000175, -53.146729698],
            [79.40208557400004, -53.162114698000018],
            [79.402606574000146, -53.177501697999944],
            [79.403009574000095, -53.192888697999955],
            [79.403294574000057, -53.208277697999954],
            [79.403461574000204, -53.223666697999953],
            [79.40351057400008, -53.239056697999942],
            [79.403440574000143, -53.254445698000033],
            [79.403251574000052, -53.26983469799994],
            [79.402944574000145, -53.285223698000031],
            [79.402517574000143, -53.30061069799995],
            [79.401972574000098, -53.315996697999971],
            [79.401308574000126, -53.331380698],
            [79.400524574000116, -53.346762697999949],
            [79.399621574000065, -53.362142698],
            [79.398599574000087, -53.377519697999986],
            [79.397457574000072, -53.392893697999988],
            [79.396196574000129, -53.408263698000013],
            [79.394815574000148, -53.423630697999968],
            [79.393314574000129, -53.438993697999948],
            [79.391693574000129, -53.454352698000044],
            [79.389952574000148, -53.469705698],
            [79.388091574000072, -53.485054697999971],
            [79.38610957400013, -53.500398697999977],
            [79.384007574000208, -53.515735698000022],
            [79.381785574000133, -53.531067698000015],
            [79.379443574000135, -53.546392698000048],
            [79.3769805740001, -53.561711697999939],
            [79.374396574000087, -53.577023697999962],
            [79.371691574000096, -53.592327698000034],
            [79.368866574000123, -53.607623697999976],
            [79.365920574000171, -53.622912697999958],
            [79.36285357400007, -53.638192698],
            [79.359665574000161, -53.653463698],
            [79.356356574000216, -53.668726697999972],
            [79.352926574, -53.683979698],
            [79.349375574000106, -53.699222698],
            [79.345702574000114, -53.714455697999966],
            [79.341908574000087, -53.729678698],
            [79.33799357400008, -53.744890698],
            [79.333957574000095, -53.760091697999989],
            [79.329799574000134, -53.775281697999958],
            [79.32551957400014, -53.790459698],
            [79.321119574000051, -53.805625698000014],
            [79.316596574000101, -53.820778698000026],
            [79.311953574000057, -53.835919697999934],
            [79.307187574000153, -53.851047698],
            [79.3023005740001, -53.866161698],
            [79.297292574000181, -53.881262697999965],
            [79.292161574000176, -53.89634869799994],
            [79.286909574000134, -53.911420698],
            [79.281536574000171, -53.926477697999978],
            [79.276041574000175, -53.941519698000043],
            [79.270424574000032, -53.956546698000018],
            [79.264685574000197, -53.971557698],
            [79.258824574000101, -53.986552698],
            [79.252842574000141, -54.00153069800001],
            [79.246739574000088, -54.016492698000029],
            [79.240513574000175, -54.031436697999979],
            [79.234166574000113, -54.046363697999944],
            [79.227697574000075, -54.061273698000015],
            [79.221107574000172, -54.076164698000021],
            [79.214394574000124, -54.091036698000053],
            [79.207561574000152, -54.105890697999925],
            [79.20060557400015, -54.120725698],
            [79.193528574000169, -54.135540697999943],
            [79.186330574000152, -54.150335698],
            [79.179010574000046, -54.165110699],
            [79.171569574000131, -54.17986569899994],
            [79.164006574000183, -54.194599699000015],
            [79.156321574000089, -54.209312698999945],
            [79.148516574000183, -54.224003699000015],
            [79.140641574000171, -54.238585698999941],
            [79.132647574, -54.253146699],
            [79.124533574000026, -54.267684699000021],
            [79.116299574000124, -54.282200699],
            [79.107946574000181, -54.296693698999931],
            [79.099473574000086, -54.311162699000015],
            [79.090880574000181, -54.325608698999972],
            [79.082168574, -54.340031698999979],
            [79.073337574000021, -54.354429698999965],
            [79.064386574000167, -54.368802699],
            [79.055317574000043, -54.383151698999932],
            [79.046128574000107, -54.397474699000014],
            [79.036820574000075, -54.411772698999982],
            [79.027393574000172, -54.426044698999931],
            [79.017847574000115, -54.440290698999952],
            [79.008183574000128, -54.454509698999956],
            [78.998399574000103, -54.468702698999941],
            [78.988498574000147, -54.482867699],
            [78.978477574000209, -54.49700569899997],
            [78.968339574000169, -54.511115699000015],
            [78.958082574000144, -54.52519769899996],
            [78.947707574000134, -54.53925169899999],
            [78.937214574000137, -54.553276699000023],
            [78.926603574000154, -54.567271698999967],
            [78.915875573000079, -54.581237699],
            [78.905029573, -54.595174699000026],
            [78.89406557300012, -54.609080698999982],
            [78.88298457300013, -54.622956699000028],
            [78.871785573000153, -54.636801699],
            [78.860470573000185, -54.650615698999971],
            [78.849037573000118, -54.66439869899996],
            [78.837488573000059, -54.678149698999967],
            [78.825822573000067, -54.69186869899999],
            [78.814040573000085, -54.705555698999945],
            [78.802141573000114, -54.719209699],
            [78.790126573000208, -54.732830699],
            [78.77799557300014, -54.746418698999932],
            [78.765748573000138, -54.759972698999981],
            [78.753386573000199, -54.773492698999974],
            [78.740908573000098, -54.786978698999988],
            [78.728315573000174, -54.800429698999956],
            [78.715665573000052, -54.813789699000026],
            [78.702902573000159, -54.827114698999956],
            [78.690025573000156, -54.840404699000011],
            [78.677034573000157, -54.853659698999927],
            [78.663930573000044, -54.866877698999971],
            [78.650713573000161, -54.880060698999969],
            [78.637383573000164, -54.893206699000011],
            [78.623940573000169, -54.906315699000011],
            [78.610384573000118, -54.919387698999969],
            [78.596716573000066, -54.932422698999972],
            [78.582935573000071, -54.945419698999942],
            [78.569043573000187, -54.958378698999965],
            [78.55503957300013, -54.971298698999959],
            [78.540923573000128, -54.984180699],
            [78.526696573000066, -54.997023698999939],
            [78.512358573000171, -55.009827699000013],
            [78.497909573000157, -55.022592698999972],
            [78.483350573000081, -55.035316699],
            [78.468680573000057, -55.048000699000013],
            [78.453900573000197, -55.060644699],
            [78.439010573000161, -55.073247698999964],
            [78.424011573000115, -55.085809699],
            [78.408902573, -55.098330699000023],
            [78.393684573000115, -55.110809698999944],
            [78.378357573000102, -55.123246698999942],
            [78.362922573000134, -55.135641699000018],
            [78.347378573000157, -55.147993699],
            [78.331727573000165, -55.160303698999968],
            [78.315967573000108, -55.172569698999943],
            [78.300100573000094, -55.184792699],
            [78.284126573, -55.196971698999967],
            [78.268045573000137, -55.209106699000024],
            [78.251857573000194, -55.221197699],
            [78.235563573000178, -55.233243699999967],
            [78.219163573000202, -55.245244699999951],
            [78.202657573000153, -55.257200699999942],
            [78.186046573000027, -55.269110699999949],
            [78.169329573000113, -55.280975699999956],
            [78.152508573000119, -55.2927937],
            [78.135582573000221, -55.304565699999941],
            [78.11855257300013, -55.3162917],
            [78.101419573000186, -55.3279697],
            [78.084181573000052, -55.3396017],
            [78.066841573000175, -55.35118570000003],
            [78.049397573000221, -55.3627217],
            [78.03185257300018, -55.374209699999987],
            [78.014203573000174, -55.385648699999926],
            [77.996453573000139, -55.397039699999972],
            [77.978602573000074, -55.408381699999964],
            [77.960649573000211, -55.419674699999987],
            [77.942596573000145, -55.430917699999959],
            [77.924442573000164, -55.442111699999963],
            [77.906188573000094, -55.4532557],
            [77.887834573000163, -55.4643487],
            [77.86938157300014, -55.475390699999949],
            [77.850829573000084, -55.486382700000028],
            [77.832178572000117, -55.497323699999967],
            [77.813429572000103, -55.508212699999959],
            [77.794582572000223, -55.5190507],
            [77.77563857200019, -55.5298357],
            [77.756596572000177, -55.540569699999956],
            [77.737458572000122, -55.551250699999969],
            [77.718146572000165, -55.561931699999981],
            [77.698736572000172, -55.572559699999957],
            [77.679231572000077, -55.5831337],
            [77.659629572000171, -55.593653700000011],
            [77.639931572000165, -55.6041197],
            [77.620139572000113, -55.614530699999968],
            [77.600252572000187, -55.6248867],
            [77.580270572000103, -55.6351887],
            [77.560195572000083, -55.6454347],
            [77.540026572000073, -55.655624699999976],
            [77.519764572000128, -55.665759700000017],
            [77.499409572000019, -55.67583870000005],
            [77.478963572000026, -55.685860699999978],
            [77.458424572000155, -55.695826699999984],
            [77.437795572000113, -55.705735699999984],
            [77.417075572000186, -55.715586699999974],
            [77.396264572000092, -55.725381699999957],
            [77.375363572000111, -55.7351177],
            [77.354374572000125, -55.7447967],
            [77.333295572000083, -55.754417699999969],
            [77.312127572000037, -55.763980699999941],
            [77.290872572000154, -55.7734837],
            [77.269529572000096, -55.782928699999978],
            [77.248099572000143, -55.792314699999956],
            [77.226583572000067, -55.801641699999941],
            [77.204980572000096, -55.81090870000002],
            [77.18329257200017, -55.820115700000017],
            [77.16151957200006, -55.82926370000002],
            [77.139661572000051, -55.838350700000042],
            [77.117720572000081, -55.847376699999984],
            [77.095694572000099, -55.856342700000027],
            [77.073586572000153, -55.8652477],
            [77.051395572000189, -55.874090699999989],
            [77.029122572000034, -55.8828737],
            [77.006767572000086, -55.891593700000023],
            [76.984331572000116, -55.900252699999974],
            [76.961815572000063, -55.908848699999957],
            [76.939219572000098, -55.917383699999959],
            [76.916544572000049, -55.925854699999988],
            [76.893789572000145, -55.934263700000045],
            [76.870956572000097, -55.942609699999949],
            [76.848046572000129, -55.950892699999969],
            [76.825058572000131, -55.9591117],
            [76.801993572000157, -55.967267700000015],
            [76.778852572000034, -55.975359699999949],
            [76.755636571000167, -55.9833877],
            [76.732344571000141, -55.991350699999934],
            [76.708978571000131, -55.999249699999979],
            [76.685538571000137, -56.007084699999965],
            [76.662024571000217, -56.0148537],
            [76.638438571000194, -56.022558699999983],
            [76.614779571000014, -56.0301977],
            [76.59104957100007, -56.037771699999986],
            [76.567247571000138, -56.0452797],
            [76.543375571000098, -56.052721699999985],
            [76.519433571000121, -56.060097700000014],
            [76.495422571000205, -56.0674077],
            [76.471342571000179, -56.074650699999943],
            [76.44719457100021, -56.081827700000019],
            [76.422978571000129, -56.088936699999984],
            [76.398695571000161, -56.095979699999987],
            [76.374345571000191, -56.1029557],
            [76.349930571000158, -56.109863699999984],
            [76.325450571000175, -56.116704699999978],
            [76.301619571000145, -56.124195700000016],
            [76.277718571000179, -56.131619699999924],
            [76.253746571000221, -56.138978699999967],
            [76.22970557100021, -56.146271699999964],
            [76.205596571000086, -56.15349770000001],
            [76.181418571000137, -56.160657699999931],
            [76.157173571000129, -56.167750699999985],
            [76.132861571000177, -56.17477670000001],
            [76.108483571000107, -56.1817357],
            [76.08403857100015, -56.188626700000036],
            [76.05952957100007, -56.195451700000035],
            [76.034955571000211, -56.20220770000001],
            [76.010318571000113, -56.208896700000032],
            [75.985616571000122, -56.215517700000028],
            [75.960853571000172, -56.2220697],
            [75.936027571000153, -56.228554700000025],
            [75.911139571000064, -56.234970700000019],
            [75.886191571000069, -56.2413177],
            [75.861183571000169, -56.247595700000034],
            [75.836115571000192, -56.253804699999954],
            [75.810988571000138, -56.259944699999942],
            [75.785803571, -56.2660157],
            [75.760560571000184, -56.272016699999938],
            [75.735260571000111, -56.277948700000039],
            [75.709903571000126, -56.2838107],
            [75.68449157000012, -56.2896027],
            [75.659024570000071, -56.295323700000047],
            [75.633502570000104, -56.300975699999981],
            [75.607927570000101, -56.306556699999987],
            [75.582298570000177, -56.312066700999985],
            [75.5566175700001, -56.317506700999964],
            [75.5308835700001, -56.322875701000022],
            [75.505099570000226, -56.328173700999976],
            [75.479264570000197, -56.333399701000012],
            [75.453379570000124, -56.338555701000026],
            [75.427445570000174, -56.343639701000036],
            [75.401463570000175, -56.348651700999952],
            [75.381289570000121, -56.352504700999987],
            [75.36108657000014, -56.356314701],
            [75.335994570000054, -56.360983700999967],
            [75.310860570000131, -56.365585700999979],
            [75.285683570000202, -56.370121700999945],
            [75.26046557000015, -56.37459070099996],
            [75.235206570000202, -56.378992700999945],
            [75.209906570000129, -56.383327700999978],
            [75.184566570000101, -56.387595700999974],
            [75.15918657, -56.391796701000018],
            [75.133768570000115, -56.39592970099995],
            [75.108312570000095, -56.399995701000016],
            [75.082819570000225, -56.403993700999969],
            [75.057289570000222, -56.407923700999973],
            [75.031722570000085, -56.411786701000032],
            [75.006120570000149, -56.415581700999972],
            [74.980483570000075, -56.41930870099997],
            [74.954811570000146, -56.422967700999941],
            [74.92910657000013, -56.42655970099996],
            [74.902256570000162, -56.430263701],
            [74.875370570000115, -56.433892700999976],
            [74.848449570000213, -56.437447700999954],
            [74.821494570000169, -56.440927701000035],
            [74.794506570000152, -56.444333700999941],
            [74.767484570000221, -56.447664700999944],
            [74.742471570000106, -56.450682701],
            [74.717430570000062, -56.453636700999979],
            [74.69236457000008, -56.456526701],
            [74.667271570000167, -56.459352700999965],
            [74.642153570000147, -56.462113700999971],
            [74.617011569000084, -56.464811701],
            [74.591845569000185, -56.467443701],
            [74.566655569000062, -56.470012701000016],
            [74.541443569000165, -56.472516700999989],
            [74.516208569000042, -56.474955701],
            [74.488684569000071, -56.477560700999973],
            [74.461136569000161, -56.480088700999978],
            [74.433563569000142, -56.482539701],
            [74.405966569000185, -56.484913701000053],
            [74.378346569, -56.487210701000038],
            [74.350704569000214, -56.489430700999954],
            [74.323041569000026, -56.491572701],
            [74.295356569000063, -56.493638700999959],
            [74.267652569000035, -56.495626700999956],
            [74.239929569000111, -56.497537700999978],
            [74.212187569000122, -56.499371701000022],
            [74.184427569000178, -56.501127701],
            [74.156651569000047, -56.502806700999933],
            [74.12885856900013, -56.50440770099997],
            [74.101050569000023, -56.505931700999945],
            [74.073228569, -56.507377700999953],
            [74.045391569000145, -56.508745700999988],
            [74.017542569000199, -56.510036700999954],
            [73.989680569000171, -56.511249700999954],
            [73.961806569000174, -56.512384700999988],
            [73.933922569000202, -56.513442701000038],
            [73.906028569000085, -56.514422700999944],
            [73.878484569000221, -56.51548370099998],
            [73.850931569000153, -56.516468700999944],
            [73.823368569000166, -56.517378701000013],
            [73.795797569000143, -56.518211701000013],
            [73.768219569000138, -56.518968700999942],
            [73.740633569000096, -56.519649700999977],
            [73.713041569000012, -56.520255700999932],
            [73.685443569000057, -56.520784701],
            [73.657841569000112, -56.521237701],
            [73.630234569000123, -56.521614701000026],
            [73.602625569000139, -56.521915700999969],
            [73.575012569000165, -56.522140701000026],
            [73.547398568000148, -56.522289701],
            [73.519783568, -56.522361701000015],
            [73.492167568000156, -56.52235870100003],
            [73.464321568000202, -56.522309700999976],
            [73.436475568000134, -56.522182700999949],
            [73.408630568000177, -56.52197770100004],
            [73.380789568000097, -56.521696700999968],
            [73.352950568000068, -56.521336700999939],
            [73.325115568000143, -56.520900701],
            [73.297285568000149, -56.520386700999936],
            [73.269460568000142, -56.519795700999978],
            [73.241642568000117, -56.519126700999955],
            [73.213830568000191, -56.518380700999963],
            [73.186026568000074, -56.517557700999987],
            [73.158231568000161, -56.516657701000042],
            [73.130445568000169, -56.515679700999947],
            [73.102668568000155, -56.514625700999957],
            [73.074903568000224, -56.513493701],
            [73.047149568000151, -56.51228470099997],
            [73.019407568000162, -56.51099870099997],
            [72.991679568000023, -56.509634701],
            [72.963964568000137, -56.50819470100005],
            [72.936263568000101, -56.506677700999944],
            [72.908578568000138, -56.505082700999957],
            [72.880909568000078, -56.503411700999983],
            [72.853257568000089, -56.501663701000041],
            [72.825622568, -56.49983970100002],
            [72.79800556800015, -56.497937700999948],
            [72.770408568000079, -56.495959700999975],
            [72.742830568000187, -56.493904701000034],
            [72.715273568000072, -56.491772700999931],
            [72.687737568000074, -56.489564700999942],
            [72.660223568000134, -56.48727970099997],
            [72.632732568000137, -56.484918701000019],
            [72.605264568000194, -56.482481701],
            [72.577821568000076, -56.479967701],
            [72.55040256800001, -56.477378701000013],
            [72.523010568000046, -56.474712701000051],
            [72.495644568000017, -56.471969701000027],
            [72.468305567000158, -56.469151701],
            [72.440994567000217, -56.466257701000011],
            [72.413711567000206, -56.463288700999939],
            [72.386458567000119, -56.460242700999977],
            [72.359236567000181, -56.457121700999934],
            [72.332044567000054, -56.453924701],
            [72.304884567000187, -56.450651701],
            [72.277756567000125, -56.447304701],
            [72.250661567000208, -56.443880700999941],
            [72.223600567000091, -56.440382700999969],
            [72.196573567000115, -56.436808701000018],
            [72.169582567000106, -56.433160700999984],
            [72.142627567000062, -56.429436700999972],
            [72.115708567000155, -56.425638700999961],
            [72.088827567000095, -56.421765700999963],
            [72.061984567000167, -56.417817700999976],
            [72.035180567000083, -56.413795701],
            [72.008415567000128, -56.409699701000015],
            [71.98169156700007, -56.405528700999966],
            [71.955007567000081, -56.401283701],
            [71.928366567000154, -56.396963700999969],
            [71.901766567000067, -56.392570701000025],
            [71.875210567000096, -56.388103701],
            [71.848698567000184, -56.383563700999964],
            [71.822230567000105, -56.378948700999942],
            [71.795807567000026, -56.374261701],
            [71.769430567000114, -56.369499701],
            [71.7448955670001, -56.365002700999966],
            [71.720400567000098, -56.360441700999978],
            [71.695948567000215, -56.355816701000016],
            [71.671537567000058, -56.351129701],
            [71.647169567000077, -56.346378701],
            [71.622844567000044, -56.341564701000038],
            [71.598563567000184, -56.33668770100001],
            [71.574326567000213, -56.331748701],
            [71.550135567000069, -56.326746700999941],
            [71.525988567000098, -56.321681701],
            [71.501887567000182, -56.31655470099998],
            [71.481459567000087, -56.314221700999944],
            [71.46104956700006, -56.311845701],
            [71.440655567000164, -56.309427700999947],
            [71.414119567000142, -56.306230700000015],
            [71.387613566000056, -56.3029607],
            [71.361138566000108, -56.2996177],
            [71.334696566000133, -56.296203700000028],
            [71.308286566000135, -56.2927167],
            [71.281910566000221, -56.28915770000004],
            [71.255569566000162, -56.2855267],
            [71.229262566000074, -56.281823700000025],
            [71.202991566000122, -56.278048699999964],
            [71.176756566000137, -56.274202699999975],
            [71.150558566000115, -56.2702837],
            [71.12439856600011, -56.266294699999989],
            [71.098276566000067, -56.262232699999984],
            [71.072193566000038, -56.258100699999957],
            [71.046150566000136, -56.25389670000002],
            [71.020147566000077, -56.249621699999977],
            [70.9941845660002, -56.2452757],
            [70.968264566000158, -56.2408587],
            [70.94238556600007, -56.236370699999952],
            [70.916550566000211, -56.231812700000027],
            [70.89075856600013, -56.2271837],
            [70.865010566000109, -56.2224837],
            [70.839307566000087, -56.2177147],
            [70.813650566000064, -56.212874700000015],
            [70.788039566000094, -56.207964699999927],
            [70.762474566000122, -56.2029847],
            [70.736957566000029, -56.197934699999955],
            [70.711488566000099, -56.192815699999976],
            [70.686068566000046, -56.187626699999981],
            [70.660697566000152, -56.182368700000055],
            [70.635377566000017, -56.177040700000013],
            [70.610106566000042, -56.171643699999954],
            [70.584887566000162, -56.166178699999946],
            [70.559720566000095, -56.160643700000016],
            [70.534606566000122, -56.155040699999958],
            [70.50954456600013, -56.149368699999968],
            [70.484537566000057, -56.143628699999951],
            [70.45958356600002, -56.1378197],
            [70.43468556600007, -56.1319437],
            [70.409842566000151, -56.125998699999982],
            [70.385056566000145, -56.119986700000013],
            [70.360902566000135, -56.114060699999968],
            [70.336802566000216, -56.108069700000051],
            [70.312758565000166, -56.1020147],
            [70.287712565000135, -56.0956257],
            [70.26272756500012, -56.0891667],
            [70.237805565000059, -56.082638699999976],
            [70.212945565000126, -56.076041700000012],
            [70.188148565000034, -56.069374700000033],
            [70.163415565000065, -56.062638700000022],
            [70.138747565000159, -56.055834699999984],
            [70.114144565000203, -56.048961700000014],
            [70.089607565000193, -56.042019700000012],
            [70.065136565000131, -56.035009699999982],
            [70.040733565000124, -56.027931700000011],
            [70.016397565000062, -56.0207867],
            [69.992129565000113, -56.013572699999969],
            [69.967930565000103, -56.0062917],
            [69.943800565000203, -55.9989437],
            [69.919741565000123, -55.9915287],
            [69.895752565000095, -55.9840467],
            [69.871835565000225, -55.976497700000053],
            [69.847989565000177, -55.968881699999976],
            [69.824216565000171, -55.961200699999935],
            [69.800515565000154, -55.9534527],
            [69.776889565000118, -55.945638700000011],
            [69.753336565000183, -55.937759700000022],
            [69.729858565000114, -55.9298147],
            [69.706455565000084, -55.9218047],
            [69.683129565000087, -55.913728699999965],
            [69.659878565000071, -55.905588699999967],
            [69.636705565000142, -55.8973837],
            [69.613609565000075, -55.889114699999986],
            [69.590592565000151, -55.8807817],
            [69.567652565000145, -55.872384699999969],
            [69.544793565000163, -55.863923700000051],
            [69.52201256500021, -55.8553987],
            [69.499313565000165, -55.846810699999963],
            [69.476694565000145, -55.838159699999956],
            [69.454156565000147, -55.8294457],
            [69.431700565000114, -55.820669699999939],
            [69.409327565000098, -55.8118307],
            [69.387036565000045, -55.802929699999929],
            [69.364829565000178, -55.793966699999956],
            [69.342706565000157, -55.7849417],
            [69.320668565000148, -55.7758557],
            [69.298714565000097, -55.766707699999976],
            [69.276846565000113, -55.757499699999983],
            [69.255064564000151, -55.7482307],
            [69.233369564000128, -55.738900699999952],
            [69.211761564, -55.7295107],
            [69.19024056400022, -55.720060699999962],
            [69.168807564000105, -55.710550699999942],
            [69.147462564000222, -55.7009817],
            [69.126207564000168, -55.6913527],
            [69.105041564000118, -55.681665699999982],
            [69.083965564000124, -55.671918699999978],
            [69.06297956400013, -55.662113699999971],
            [69.042085564000018, -55.652250699999961],
            [69.021281564000134, -55.642329699999948],
            [69.000570564000071, -55.632350700000025],
            [68.979951564000061, -55.6223137],
            [68.959424564000216, -55.612219699999976],
            [68.938991564000133, -55.602069700000023],
            [68.918651564000101, -55.591861699999981],
            [68.898405564000058, -55.58159770000001],
            [68.878254564000116, -55.571277699999932],
            [68.858198564000162, -55.560901699999931],
            [68.838237564000138, -55.5504697],
            [68.8183725640001, -55.539981699999977],
            [68.79860356400016, -55.5294397],
            [68.778931564000146, -55.518842699999929],
            [68.759357564000112, -55.5081907],
            [68.739879564000177, -55.497484699999966],
            [68.720500564000162, -55.4867237],
            [68.701218564000129, -55.4759097],
            [68.682036564000072, -55.465042699999955],
            [68.662953564000162, -55.4541217],
            [68.643969564000059, -55.443147699999976],
            [68.625085564000159, -55.432121700000017],
            [68.606302564, -55.421042700000022],
            [68.587619564000221, -55.4099117],
            [68.569038564000181, -55.398728699999921],
            [68.550558564000056, -55.387494699999984],
            [68.532179564000018, -55.3762087],
            [68.513904564000057, -55.364871699999988],
            [68.495730564000183, -55.3534847],
            [68.477660564000217, -55.342046699999969],
            [68.459693564000105, -55.330558699999976],
            [68.44182956400013, -55.319021700000015],
            [68.424070564000061, -55.307433699999919],
            [68.406415564000071, -55.29579770000003],
            [68.388865564000213, -55.2841117],
            [68.371420564000033, -55.272377699999986],
            [68.354080564000157, -55.2605957],
            [68.336847564000067, -55.248764699999967],
            [68.319719564000167, -55.236886699999943],
            [68.302698564000053, -55.224960698999951],
            [68.285783564000013, -55.212987698999974],
            [68.268976564000099, -55.200967698999925],
            [68.252276564000084, -55.188900698999987],
            [68.235683564000198, -55.176787698999973],
            [68.219199564000093, -55.164628698999977],
            [68.202823564000056, -55.15242469899998],
            [68.186556564000142, -55.140174699],
            [68.170398563000134, -55.127879698999941],
            [68.15434856300007, -55.115539698999974],
            [68.138409563000124, -55.103154699000015],
            [68.12257956300013, -55.090726698999958],
            [68.106859563000199, -55.078253699],
            [68.091250563000102, -55.065737698999953],
            [68.075752563000066, -55.053178698999986],
            [68.060364563000149, -55.040576699000013],
            [68.04508756300018, -55.027931699000035],
            [68.029922563000156, -55.015244698999958],
            [68.014869563000133, -55.002515698999957],
            [67.999928563000168, -54.989744699000035],
            [67.985099563000148, -54.976932699],
            [67.97038356300007, -54.964078698999963],
            [67.955779563000164, -54.951184698999988],
            [67.941288563000143, -54.938250699],
            [67.926911563000118, -54.925275698999975],
            [67.912647563000206, -54.912260698999937],
            [67.898496563000066, -54.899206698999961],
            [67.88446056300009, -54.886113698999949],
            [67.870538563000224, -54.872981699],
            [67.856730563000127, -54.859810699000022],
            [67.843037563000138, -54.846601699000011],
            [67.829459563000142, -54.833354699000047],
            [67.815996563000084, -54.820070699000048],
            [67.80264856300019, -54.806748699000011],
            [67.789415563000063, -54.793390699000014],
            [67.776299563000094, -54.779994698999978],
            [67.763298563000063, -54.766563698999981],
            [67.750413563000137, -54.753095699000035],
            [67.737644563000089, -54.739592698999942],
            [67.724992563000086, -54.726053698999976],
            [67.712456563000131, -54.712480698999954],
            [67.700090563000145, -54.698932698999961],
            [67.687839563000097, -54.685351698999987],
            [67.67570456300021, -54.67173569899996],
            [67.663686563000084, -54.65808769899995],
            [67.65178456300012, -54.644405698999968],
            [67.639999563000146, -54.630690699],
            [67.62833156300016, -54.616943698999975],
            [67.616779563000051, -54.603164698999962],
            [67.605345563000157, -54.589353698999965],
            [67.594028563000194, -54.575510698999985],
            [67.582828563000049, -54.561637699],
            [67.571746563000175, -54.547732698999965],
            [67.56078156300012, -54.533797698999919],
            [67.549934563000221, -54.51983269899997],
            [67.539205563000138, -54.505837699000026],
            [67.528594563000155, -54.491812699],
            [67.518102563000213, -54.47775869899997],
            [67.507727563000145, -54.463676699000018],
            [67.497471563000119, -54.449564698999986],
            [67.487334563000076, -54.435425698999943],
            [67.477315563000133, -54.42125769899998],
            [67.467415563000174, -54.407062698999923],
            [67.457634563000198, -54.392840699000025],
            [67.447971563000038, -54.378591699000026],
            [67.438428563000031, -54.364315699],
            [67.429004563000177, -54.35001369899998],
            [67.41969956300008, -54.335685699000017],
            [67.410514563000135, -54.321332699000024],
            [67.401447563000175, -54.306953699000019],
            [67.392501563000081, -54.292549698999984],
            [67.383674563000085, -54.278121699],
            [67.374966563000072, -54.263668699],
            [67.366379563000038, -54.249192698999963],
            [67.357911563000158, -54.234692698999979],
            [67.349563563000089, -54.220168698999963],
            [67.341335563000172, -54.205622698999989],
            [67.333226563000181, -54.19105369899998],
            [67.325238563000056, -54.17646269900002],
            [67.317370563000082, -54.161849699],
            [67.30962256300009, -54.147214697999956],
            [67.301995563000077, -54.132558698000032],
            [67.29448756300016, -54.117880697999979],
            [67.287100563000109, -54.103183697999945],
            [67.279833563000039, -54.088465697999958],
            [67.272686563000121, -54.073727698],
            [67.265660563000012, -54.058969697999984],
            [67.258754563000167, -54.044192698],
            [67.251969563000074, -54.029397698000025],
            [67.245304563000019, -54.014582698],
            [67.23876056300017, -53.999749698],
            [67.232336563000132, -53.984899697999921],
            [67.226033563000072, -53.97003069799996],
            [67.219699563000205, -53.955331697999945],
            [67.213482563000213, -53.940614697999948],
            [67.207384563000147, -53.925880697999965],
            [67.201404563000011, -53.911130697999987],
            [67.195541563000091, -53.89636369800003],
            [67.189797563000212, -53.881581697999984],
            [67.184170563000208, -53.866783697999942],
            [67.178662563000017, -53.851969698],
            [67.1732715630001, -53.837141697999968],
            [67.167999563000166, -53.82229869799994],
            [67.16284456300005, -53.807441698],
            [67.157808563000088, -53.792570697999956],
            [67.152889563000059, -53.777685698],
            [67.148088563000016, -53.762786697999957],
            [67.14340556300013, -53.74787569799998],
            [67.138840563000173, -53.732951698],
            [67.134393563000032, -53.718015698],
            [67.13006356300005, -53.703067697999984],
            [67.125851563000168, -53.688106697999963],
            [67.121757563000159, -53.673135698],
            [67.117781563000079, -53.658152698000016],
            [67.113922563000102, -53.643159698000012],
            [67.110181563000168, -53.628155697999979],
            [67.106558562000174, -53.613141697999922],
            [67.103052562000158, -53.598118698],
            [67.099663562000075, -53.583085697999977],
            [67.096392562000148, -53.568042698],
            [67.093238562000039, -53.552991698],
            [67.090202562000201, -53.537932697999949],
            [67.087283562000181, -53.522864698000042],
            [67.084480562000152, -53.507788698],
            [67.081796562000108, -53.492705698],
            [67.079228562000168, -53.477614697999975],
            [67.07677756200016, -53.462517697999978],
            [67.074443562000141, -53.447413697999934],
            [67.072225562000114, -53.432303697999927],
            [67.070125562000186, -53.417187697999957],
            [67.068141562000136, -53.402065697999937],
            [67.066274562000132, -53.386938697999945],
            [67.064523562000119, -53.371806697999979],
            [67.062888562000097, -53.356669697999962],
            [67.061370562, -53.341528697999962],
            [67.059955562000169, -53.326301697999959],
            [67.05865756200015, -53.311071697999971],
            [67.057477562000116, -53.295837698],
            [67.056413562000017, -53.280600697999972],
            [67.055467562000075, -53.265360697999952],
            [67.054638562000065, -53.250118697999952],
            [67.055709562000203, -53.235255697999953],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "HND", Country: "Honduras" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.59722, 19.465833],
            [-82.35, 17.58417],
            [-80.83306, 17.58417],
            [-80.591886328000015, 17.584084139000126],
            [-79.87283085099989, 16.26121428600014],
            [-79.872263207, 16.26016997300006],
            [-79.871781234599865, 16.25928327260003],
            [-79.873871428000029, 16.258191422000138],
            [-82.366535013290729, 15.269266820551664],
            [-82.365518740499908, 15.268749840500092],
            [-82.364657534999935, 15.26836694699999],
            [-82.36379834549993, 15.267979550000021],
            [-82.362655481999923, 15.267457029500164],
            [-82.361515923499837, 15.266927338500025],
            [-82.360663345000034, 15.266525601000055],
            [-82.359812882499966, 15.266119403500156],
            [-82.358964128999844, 15.26570964800014],
            [-82.358117531499914, 15.265295454500105],
            [-82.356991644499942, 15.264737294000142],
            [-82.355869286499882, 15.264172070000015],
            [-82.354750501999916, 15.26359980550005],
            [-82.353913671000043, 15.263166219000013],
            [-82.353079122499963, 15.262728257000092],
            [-82.352246415000025, 15.262286802999981],
            [-82.351416030999985, 15.26184099450002],
            [-82.350311980999919, 15.261240795500086],
            [-82.349211723999929, 15.260633671500116],
            [-82.348115302999901, 15.260019646000089],
            [-82.347295408999912, 15.259554828000077],
            [-82.346477959999959, 15.25908572200008],
            [-82.345391318999845, 15.258454551000071],
            [-82.344308665499852, 15.257816564500089],
            [-82.343230041999902, 15.257171788500031],
            [-82.342423615999877, 15.256683976999952],
            [-82.341619755499835, 15.256191949000097],
            [-82.340817962999893, 15.255696559000043],
            [-82.340018775499885, 15.255196977000182],
            [-82.338956695499945, 15.254525298500099],
            [-82.33816278300003, 15.254017375000117],
            [-82.337371541499891, 15.253505302000107],
            [-82.336320143, 15.252817024000095],
            [-82.335273089999959, 15.25212215350011],
            [-82.334490537999955, 15.251596895000134],
            [-82.333710747999987, 15.251067546500153],
            [-82.332674738499946, 15.250356316000065],
            [-82.331643218499948, 15.249638589000099],
            [-82.33061622799994, 15.24891439550008],
            [-82.329593808499936, 15.248183763500023],
            [-82.328575999499918, 15.247446722000191],
            [-82.327562841500026, 15.24670330000005],
            [-82.326805901999961, 15.246141761999965],
            [-82.326051912499963, 15.245576268000079],
            [-82.325300298999821, 15.245007621],
            [-82.324551672999888, 15.244435045500111],
            [-82.323557517500006, 15.243666397500178],
            [-82.322814927999985, 15.243086015000173],
            [-82.322075387999888, 15.242501751500114],
            [-82.32109342849995, 15.24171758300001],
            [-82.32036004699998, 15.241125607000058],
            [-82.319629775500005, 15.240529800000061],
            [-82.318660254499889, 15.239730304000076],
            [-82.317695775499942, 15.238924732500081],
            [-82.316975588999895, 15.238316774000097],
            [-82.31625859549996, 15.237705052500061],
            [-82.31530688849989, 15.236884430500012],
            [-82.31436035699997, 15.236057845000047],
            [-82.313419037999893, 15.235225329000102],
            [-82.312482967999983, 15.234386915000087],
            [-82.311784216999882, 15.233754435999984],
            [-82.311088786499937, 15.233118307000112],
            [-82.310396026999967, 15.232479271000088],
            [-82.309706622999954, 15.231836616500075],
            [-82.309019916999972, 15.231191079999988],
            [-82.308336600499956, 15.230541956000096],
            [-82.307656008999913, 15.229889976000081],
            [-82.306978840499966, 15.229234441],
            [-82.306304423999904, 15.228576075999982],
            [-82.305633463999982, 15.227914188000099],
            [-82.304743484499937, 15.227027000500115],
            [-82.303859096999986, 15.226134238500052],
            [-82.303199320000033, 15.225461202],
            [-82.30254307699991, 15.22478472050004],
            [-82.301889672999948, 15.224105496],
            [-82.301239833999915, 15.223422859000024],
            [-82.300378160999827, 15.222508154500147],
            [-82.299522251999974, 15.221588053500042],
            [-82.298883942000032, 15.220894625000099],
            [-82.298249270999918, 15.220197864500122],
            [-82.297617523999861, 15.219498451000035],
            [-82.2969894485, 15.21879573950001],
            [-82.29615693249994, 15.21785442000008],
            [-82.29533034699989, 15.216907888500131],
            [-82.294509725499978, 15.215956182500079],
            [-82.293695099499814, 15.214999338500135],
            [-82.292886501499964, 15.214037395000091],
            [-82.292283837999975, 15.213312771],
            [-82.291684976999903, 15.212585001500045],
            [-82.29108917, 15.21185473000007],
            [-82.290497194999944, 15.211121349000038],
            [-82.289908295999879, 15.210385494],
            [-82.2893232589999, 15.209646566500098],
            [-82.288548379999895, 15.208657260500033],
            [-82.287779731999905, 15.207663105000066],
            [-82.287207156999955, 15.206914478000144],
            [-82.286638509999875, 15.206162864500058],
            [-82.286073015999989, 15.205408876],
            [-82.285511477499938, 15.204651936000161],
            [-82.284953114999979, 15.203892651000032],
            [-82.28439873499994, 15.203130452500162],
            [-82.283664894499907, 15.202110333500045],
            [-82.282937478499861, 15.201085623000054],
            [-82.282216514999845, 15.200056362500092],
            [-82.281679841000027, 15.199281596000091],
            [-82.281147230499982, 15.198504029500086],
            [-82.280442522999977, 15.197463572000103],
            [-82.27974436699995, 15.196418707000092],
            [-82.279052788999905, 15.195369476000039],
            [-82.278538231999875, 15.194579846000167],
            [-82.278027817, 15.193787534000066],
            [-82.277352805999868, 15.192727569500065],
            [-82.276850715999871, 15.19192995500002],
            [-82.276352808999974, 15.191129722999975],
            [-82.27569452949993, 15.190059286500102],
            [-82.275042988999957, 15.188984735500085],
            [-82.274398212499875, 15.187906112000078],
            [-82.273760226499832, 15.186823458500143],
            [-82.27312905499997, 15.185736817500114],
            [-82.272504723999901, 15.184646232500128],
            [-82.271887257499941, 15.18355174600002],
            [-82.271276679999914, 15.182453402000093],
            [-82.270673015499938, 15.181351243000123],
            [-82.270224601, 15.180522263000015],
            [-82.269780532999931, 15.179690947000054],
            [-82.269194254999917, 15.178579442000128],
            [-82.268758916999957, 15.177743520999954],
            [-82.268327961499864, 15.176905332500112],
            [-82.267759213999938, 15.175784756000084],
            [-82.267337059999903, 15.174942100000166],
            [-82.266919323499934, 15.174097245500107],
            [-82.266368247499997, 15.172967874000065],
            [-82.265824278999986, 15.171835061500005],
            [-82.265420755999884, 15.170983327000087],
            [-82.265021697999913, 15.170129491000054],
            [-82.264495590499962, 15.16898827450008],
            [-82.26397666299988, 15.167843774500071],
            [-82.263591967000025, 15.166983372000161],
            [-82.263211780499944, 15.166120968000129],
            [-82.262835210999924, 15.16525697800013],
            [-82.262463168999972, 15.164391029000072],
            [-82.261973167499974, 15.163233850000054],
            [-82.261490445999868, 15.162073615000068],
            [-82.261015023499908, 15.16091037000011],
            [-82.260546919499916, 15.159744161000063],
            [-82.26008615249998, 15.158575033500099],
            [-82.259632739999802, 15.157403033999984],
            [-82.259297287000038, 15.156522265000135],
            [-82.25896645100002, 15.155639751500061],
            [-82.258531503499853, 15.154460775],
            [-82.258103972499924, 15.153279088500071],
            [-82.257787967999946, 15.152391156000093],
            [-82.257476617999941, 15.151501581000034],
            [-82.257067700499903, 15.150313325000113],
            [-82.256765683, 15.149420538000058],
            [-82.256468343499876, 15.148526182500078],
            [-82.256174753000039, 15.147630589000116],
            [-82.255885855999935, 15.146733470500081],
            [-82.255506928499983, 15.145535314000099],
            [-82.255135536999944, 15.144334800000067],
            [-82.254861710999933, 15.143432966999981],
            [-82.25459261149993, 15.14252971300003],
            [-82.254240122499965, 15.141323514000064],
            [-82.253895219499952, 15.140115123500138],
            [-82.253641289999848, 15.139207488000139],
            [-82.253392116999919, 15.138298534500095],
            [-82.253066237499922, 15.137084876],
            [-82.252747989000028, 15.135869193500085],
            [-82.252514080000026, 15.134956193000093],
            [-82.252284954499942, 15.134041981000124],
            [-82.252059661, 15.133126817000075],
            [-82.251839161499902, 15.132210485500153],
            [-82.251551563999953, 15.130987189000152],
            [-82.251271658999912, 15.129762110499982],
            [-82.251066542999979, 15.12884221400013],
            [-82.250866246499896, 15.127921255500084],
            [-82.25066981, 15.126999466000072],
            [-82.250478201999982, 15.126076661000084],
            [-82.250290461, 15.125153061000063],
            [-82.250107558999957, 15.124228491000125],
            [-82.249928531999871, 15.123303163000131],
            [-82.249754351499888, 15.122376910000142],
            [-82.249528580999907, 15.121140708999988],
            [-82.249310582499959, 15.119903114000167],
            [-82.249151944999937, 15.118974073000047],
            [-82.248998174499945, 15.118044214000108],
            [-82.248799642499961, 15.116803347500152],
            [-82.248608910499883, 15.115561257500062],
            [-82.248425986499939, 15.114317994500126],
            [-82.248293676999879, 15.113384839999966],
            [-82.24816625599999, 15.11245100550012],
            [-82.248042747, 15.111516646000027],
            [-82.24792413249989, 15.110581652000107],
            [-82.247772509999834, 15.109334184000019],
            [-82.247628727999938, 15.10808578800011],
            [-82.247525794999945, 15.107148937999952],
            [-82.247427768999898, 15.10621156250015],
            [-82.247333669999932, 15.105273784000062],
            [-82.247244482999946, 15.104335525500019],
            [-82.247132118999957, 15.10308391100007],
            [-82.247052761999981, 15.102144769999981],
            [-82.246978322999951, 15.101205226500085],
            [-82.246885632499954, 15.099952001500142],
            [-82.24680081749986, 15.098698218500047],
            [-82.246723881999941, 15.097443927500095],
            [-82.246654828999908, 15.096189177500108],
            [-82.246593660999906, 15.094934019000121],
            [-82.246540380999932, 15.093678500500104],
            [-82.246494989999917, 15.092422672000069],
            [-82.246465879, 15.091480634000121],
            [-82.246441700499986, 15.090538456500113],
            [-82.246421469999973, 15.08959618599998],
            [-82.246406171999837, 15.08865382250012],
            [-82.246392354499989, 15.087397250000109],
            [-82.246386926999946, 15.086454778000032],
            [-82.246386433499936, 15.085512290500091],
            [-82.246392354499989, 15.084255656000053],
            [-82.246406171999837, 15.08299908350007],
            [-82.246427884500008, 15.081742623000153],
            [-82.246457490499978, 15.080486323000017],
            [-82.246494989999917, 15.079230234500059],
            [-82.246528047, 15.078288327000067],
            [-82.246566034999944, 15.077346605000045],
            [-82.246623258999961, 15.076091260500121],
            [-82.24668837, 15.074836300000086],
            [-82.246761364999941, 15.073581773500067],
            [-82.246821035999886, 15.07264117600009],
            [-82.246885632499954, 15.0717009050001],
            [-82.246978322999951, 15.070447679500106],
            [-82.247078886499963, 15.06919506150011],
            [-82.247187317999902, 15.067943099500084],
            [-82.24730361349998, 15.066691844500113],
            [-82.247395747999917, 15.065753871000098],
            [-82.247492792499912, 15.064816392500106],
            [-82.247593762999969, 15.063879329000073],
            [-82.247699638499881, 15.062942807499965],
            [-82.247847341499863, 15.061694869500101],
            [-82.247963017999979, 15.060759508000032],
            [-82.248083590499959, 15.059824764500107],
            [-82.248208078, 15.058890534000014],
            [-82.248337454499904, 15.057956968500108],
            [-82.24851647199992, 15.056713136500107],
            [-82.248703300999921, 15.055470454000115],
            [-82.248848300999953, 15.05453918700006],
            [-82.248998174499945, 15.053608692000083],
            [-82.249204499999934, 15.052369097500062],
            [-82.249418609499912, 15.051130823500088],
            [-82.249584052999978, 15.050202970000115],
            [-82.249754351499888, 15.049275996000048],
            [-82.249987884499944, 15.048041238000039],
            [-82.250167880999868, 15.047116098000046],
            [-82.250352718999977, 15.046191913000129],
            [-82.25060561849989, 15.04496097600007],
            [-82.250866246499896, 15.043731651500011],
            [-82.251134593999922, 15.042503989000082],
            [-82.251340673000016, 15.041584307000122],
            [-82.251551563999953, 15.040665717000124],
            [-82.251839161499902, 15.039442421000089],
            [-82.252059661, 15.038526090000019],
            [-82.252284954499942, 15.037610925999971],
            [-82.252591730999967, 15.036392298000138],
            [-82.252906158499911, 15.035175622000096],
            [-82.253228223999884, 15.033960945499999],
            [-82.253557914999874, 15.032748316500133],
            [-82.253809942999965, 15.031840151000083],
            [-82.254066721999976, 15.030933317],
            [-82.254415419499907, 15.029726016500078],
            [-82.254681680999965, 15.028821921000102],
            [-82.254952671499865, 15.027919232500139],
            [-82.255320289999958, 15.026717558000072],
            [-82.255695451, 15.025518216500089],
            [-82.255981528999854, 15.024620195000082],
            [-82.256272303999936, 15.023723683500108],
            [-82.256566831999976, 15.022828398000158],
            [-82.256866043999963, 15.021934667000195],
            [-82.257168995999876, 15.021042196999971],
            [-82.257476617999941, 15.020151326000061],
            [-82.257892992999928, 15.018965663000031],
            [-82.258316809999911, 15.017782639000117],
            [-82.258748050999884, 15.016602302000152],
            [-82.259186700499981, 15.015424697500109],
            [-82.259520307999821, 15.014543228000136],
            [-82.25985852549988, 15.013663516500046],
            [-82.260315617999908, 15.012492947500093],
            [-82.260663028999971, 15.011616826000122],
            [-82.261015023499908, 15.010742536000166],
            [-82.261490445999868, 15.009579291000094],
            [-82.261851576, 15.008708735000013],
            [-82.262217259500005, 15.007840082000186],
            [-82.262586577999969, 15.006972968000113],
            [-82.262960432499938, 15.0061078],
            [-82.263337906999965, 15.005244205000068],
            [-82.263719898499886, 15.004382599000095],
            [-82.264235227999933, 15.003236474500071],
            [-82.264757747999937, 15.002093610500097],
            [-82.265287438499826, 15.000954052500063],
            [-82.265689176999985, 15.000101474000175],
            [-82.266095373499979, 14.999251011500107],
            [-82.266642898499981, 14.998119913500048],
            [-82.267057978999901, 14.997273751000066],
            [-82.267477483999897, 14.996429773500083],
            [-82.268042707499887, 14.995307415500022],
            [-82.268614972499904, 14.994188631000057],
            [-82.269194254999917, 14.993073464000148],
            [-82.269780532999931, 14.991961959000108],
            [-82.270373783, 14.990854160000126],
            [-82.270973982499953, 14.989750110000088],
            [-82.271581105999871, 14.988649853000069],
            [-82.272195130999961, 14.987553432000126],
            [-82.272659949999877, 14.986733538000053],
            [-82.27312905499997, 14.985916089000099],
            [-82.273760226499832, 14.98482944800007],
            [-82.274398212499875, 14.983746794500078],
            [-82.274880947999975, 14.982937320000033],
            [-82.27536791549997, 14.982130383500092],
            [-82.27602282849989, 14.981057884500089],
            [-82.276518219, 14.980256092000033],
            [-82.277017800999857, 14.979456904500054],
            [-82.277689478999918, 14.978394824500086],
            [-82.278367816999918, 14.977336986000111],
            [-82.278880718999886, 14.976546281000125],
            [-82.279397753999945, 14.97575827200005],
            [-82.27991808500002, 14.974972436000044],
            [-82.280442522999977, 14.974189334500025],
            [-82.281147230499982, 14.973148877000042],
            [-82.281679841000027, 14.972371311000032],
            [-82.282216514999845, 14.971596543500098],
            [-82.282756429999836, 14.970824032000152],
            [-82.283300381499856, 14.970054357000166],
            [-82.284031013499913, 14.969031937500162],
            [-82.284768055499967, 14.968014128500059],
            [-82.285511477499938, 14.967000970000029],
            [-82.286073015999989, 14.966244031000016],
            [-82.286638509999875, 14.965490041500018],
            [-82.287397753999898, 14.964488686000081],
            [-82.287971112999912, 14.963740660000113],
            [-82.288548379999895, 14.962995646500062],
            [-82.2893232589999, 14.962006339500093],
            [-82.290104338999953, 14.961021921500176],
            [-82.290891588999983, 14.960042430000087],
            [-82.291684976999903, 14.959067904500145],
            [-82.292484472999973, 14.95809838350003],
            [-82.293087894999985, 14.957374391000045],
            [-82.293695099499814, 14.956653568],
            [-82.294509725499978, 14.9556967245001],
            [-82.29533034699989, 14.954745017500059],
            [-82.29615693249994, 14.95379848600011],
            [-82.2969894485, 14.952857166500152],
            [-82.297617523999861, 14.95215445500007],
            [-82.298249270999918, 14.951455042000092],
            [-82.299096470000023, 14.950526915500106],
            [-82.299735506000047, 14.949834156000108],
            [-82.300378160999827, 14.949144752000095],
            [-82.301023697999966, 14.948458046000113],
            [-82.301672821499892, 14.947774729500182],
            [-82.30254307699991, 14.946868186000117],
            [-82.303199320000033, 14.946191703999986],
            [-82.303859096999986, 14.945518668500043],
            [-82.304743484499937, 14.944625906000127],
            [-82.305410273, 14.94395981600006],
            [-82.306080539499902, 14.943297226000126],
            [-82.306978840499966, 14.942418465000159],
            [-82.307882645499973, 14.941545366000057],
            [-82.30879191799994, 14.940677963000056],
            [-82.309706622999954, 14.939816290000081],
            [-82.310396026999967, 14.939173635000103],
            [-82.311088786499937, 14.938534599000079],
            [-82.312016913499917, 14.937687400000058],
            [-82.312716326000015, 14.937055653000172],
            [-82.313419037999893, 14.936427577500055],
            [-82.31436035699997, 14.935595061000114],
            [-82.31530688849989, 14.934768476000116],
            [-82.31625859549996, 14.933947854500119],
            [-82.317215438999938, 14.93313322850004],
            [-82.317936261999989, 14.932526024000126],
            [-82.318660254499889, 14.931922602000114],
            [-82.319629775500005, 14.931123106000129],
            [-82.32036004699998, 14.930527299000133],
            [-82.32109342849995, 14.929935323500118],
            [-82.321829282999857, 14.929346425000134],
            [-82.322568210499952, 14.928761388000126],
            [-82.323557517500006, 14.927986508500098],
            [-82.324551672999888, 14.927217860500079],
            [-82.325550638499891, 14.926455474500059],
            [-82.326554374499977, 14.925699379500045],
            [-82.327562841500026, 14.924949606500078],
            [-82.328575999499918, 14.924206184500107],
            [-82.329593808499936, 14.923469142500053],
            [-82.33061622799994, 14.92273851050011],
            [-82.331385902999926, 14.922194558999976],
            [-82.332158414499929, 14.921654644000071],
            [-82.332933181999977, 14.921117970000083],
            [-82.333710747999987, 14.920585359500123],
            [-82.33475120549997, 14.919880652000032],
            [-82.335534306999989, 14.91935621400016],
            [-82.336320143, 14.918835882500034],
            [-82.3371081519999, 14.918318848000112],
            [-82.337898856999942, 14.917805946000144],
            [-82.338956695499945, 14.917127608000143],
            [-82.340018775499885, 14.916455930000168],
            [-82.340817962999893, 14.915956348000137],
            [-82.341619755499835, 14.915460957000093],
            [-82.342692254499866, 14.914806044500139],
            [-82.343499190999978, 14.914319077000115],
            [-82.344308665499852, 14.913836341500016],
            [-82.345391318999845, 14.913198355500057],
            [-82.346477959999959, 14.91256718400011],
            [-82.347295408999912, 14.912098079000017],
            [-82.348115302999901, 14.911633260000102],
            [-82.349211723999929, 14.911019235000097],
            [-82.350311980999919, 14.910412110500104],
            [-82.351416030999985, 14.909811912000137],
            [-82.352246415000025, 14.909366104000114],
            [-82.353079122499963, 14.908924650000088],
            [-82.353913671000043, 14.908486688],
            [-82.354750501999916, 14.908053101000192],
            [-82.355869286499882, 14.907480836500142],
            [-82.356991644499942, 14.906915613000123],
            [-82.35783562200001, 14.906496108000042],
            [-82.358681784999845, 14.90608102700007],
            [-82.359812882499966, 14.90553350250012],
            [-82.360663345000034, 14.90512730600004],
            [-82.361515923499837, 14.904725567500051],
            [-82.362370176999946, 14.904327404000028],
            [-82.363226503499959, 14.903933719000051],
            [-82.364084470999842, 14.903543624000108],
            [-82.36494446999987, 14.903158027500027],
            [-82.366093809499972, 14.902649909500084],
            [-82.367246318999975, 14.902149023000092],
            [-82.368112658, 14.901777889000115],
            [-82.368980927499933, 14.901411296500115],
            [-82.369850724999822, 14.901048343000056],
            [-82.370722411499969, 14.900689950000057],
            [-82.371887143999913, 14.900218184500076],
            [-82.373054818499867, 14.899753747000048],
            [-82.37422538749982, 14.899296654500105],
            [-82.375105099, 14.898958436999962],
            [-82.375986568499968, 14.89862482900007],
            [-82.377164172999926, 14.89818618000011],
            [-82.378344509999863, 14.897754939000137],
            [-82.379231445999892, 14.89743614700005],
            [-82.380120038499911, 14.897122003000092],
            [-82.381307003499899, 14.896709354500089],
            [-82.382198836999919, 14.896404534000041],
            [-82.383092253999962, 14.89610438600009],
            [-82.384285554499883, 14.895710433],
            [-82.385182066000027, 14.895419657],
            [-82.386080087999886, 14.895133580000063],
            [-82.387279428999847, 14.894758419000098],
            [-82.388481103499998, 14.894390800500091],
            [-82.389383792000046, 14.894119810000106],
            [-82.390287887499937, 14.893853548500132],
            [-82.391495187999936, 14.893504851000031],
            [-82.392402021999942, 14.89324807200002],
            [-82.393310187499878, 14.892996044000014],
            [-82.394522816499943, 14.892666353000109],
            [-82.395737492999956, 14.892344287500137],
            [-82.396954168999912, 14.892029860000108],
            [-82.39817279699983, 14.891723083500167],
            [-82.399393327999832, 14.891423970000162],
            [-82.40061571449985, 14.891132530500059],
            [-82.401839907499891, 14.890848778500029],
            [-82.403065859999856, 14.890572723000062],
            [-82.404293522499955, 14.890304375500037],
            [-82.405522846999929, 14.890043747500144],
            [-82.406753783999989, 14.889790848000118],
            [-82.407986286499863, 14.889545688000013],
            [-82.409220304999963, 14.88930827750012],
            [-82.410455789999872, 14.889078624000078],
            [-82.411692694499862, 14.888856738500053],
            [-82.412930968499893, 14.888642629000074],
            [-82.414170562999942, 14.888436303500086],
            [-82.415101058, 14.88828643],
            [-82.416032324999975, 14.88814143000009],
            [-82.417275007499967, 14.887954601000061],
            [-82.418207741999936, 14.887819360999984],
            [-82.419141171499916, 14.887689006500082],
            [-82.420386635499938, 14.887521719500185],
            [-82.421321378999977, 14.887401147000119],
            [-82.422256740499961, 14.887285470500089],
            [-82.42350467849991, 14.887137767500022],
            [-82.424441200000018, 14.887031892000024],
            [-82.425378263499965, 14.886930921500138],
            [-82.426315742000043, 14.886833877000058],
            [-82.427253715499887, 14.886741742500121],
            [-82.428504970999882, 14.886625447000043],
            [-82.42975693249997, 14.886517015500104],
            [-82.431009550999818, 14.886416452000176],
            [-82.432262775999874, 14.886323761500094],
            [-82.433203046999949, 14.886259165000112],
            [-82.434143644500011, 14.886199493500115],
            [-82.435398170999861, 14.886126499000071],
            [-82.43665313149998, 14.886061388000101],
            [-82.437594595999855, 14.886017484000021],
            [-82.43853627750002, 14.885978510000072],
            [-82.439792105999885, 14.885933119000057],
            [-82.441048194499928, 14.885895619500118],
            [-82.442304493999927, 14.885866013000012],
            [-82.443560954499901, 14.885844301000091],
            [-82.444503373999964, 14.885832951000125],
            [-82.445445839999962, 14.885826535500073],
            [-82.446702486499873, 14.885824561500087],
            [-82.447959120999968, 14.885830483500129],
            [-82.449215693499895, 14.885844301000091],
            [-82.45015805699984, 14.88585959800001],
            [-82.451100327499859, 14.885879829500041],
            [-82.45204250499998, 14.885904008000139],
            [-82.452984542999928, 14.885933119000057],
            [-82.454240371499878, 14.885978510000072],
            [-82.455182053000044, 14.886017484000021],
            [-82.456123517499918, 14.886061388000101],
            [-82.457378477999953, 14.886126499000071],
            [-82.458633004499887, 14.886199493500115],
            [-82.45988704749999, 14.88628036900009],
            [-82.461140557999926, 14.886369122500113],
            [-82.462393485999939, 14.88646575000017],
            [-82.463645781999901, 14.886570248000098],
            [-82.464897396499879, 14.886682612000087],
            [-82.465835655, 14.886771799000073],
            [-82.466773433499924, 14.886865898000153],
            [-82.467710808999897, 14.886963924000085],
            [-82.468647658999885, 14.887066857000079],
            [-82.469896054999964, 14.88721063900006],
            [-82.471143522999881, 14.887362261500058],
            [-82.472078516999886, 14.88748087600014],
            [-82.473012876499979, 14.887604385000131],
            [-82.473946710999826, 14.887731806000019],
            [-82.474879865499901, 14.887864115500079],
            [-82.476123128999888, 14.888047039500051],
            [-82.477365218499926, 14.888237771500016],
            [-82.478606084999882, 14.888436303500086],
            [-82.479535944, 14.888590074000078],
            [-82.480464984999941, 14.888748711500099],
            [-82.481393353000016, 14.888911238000091],
            [-82.482320857999923, 14.889078624000078],
            [-82.483556343999936, 14.88930827750012],
            [-82.484790361999984, 14.889545688000013],
            [-82.485714932, 14.889728590000132],
            [-82.486638531999944, 14.889916331000123],
            [-82.487561337000017, 14.89010793900006],
            [-82.488483126499943, 14.890304375500037],
            [-82.48940408499999, 14.890504672000034],
            [-82.490323981499927, 14.890709788000052],
            [-82.495878267999984, 14.892129408000173],
            [-82.555507931999955, 14.943181727000095],
            [-82.566322648999915, 14.946065651000126],
            [-82.832928170499855, 14.953334452000078],
            [-82.833627830499921, 14.954157115000115],
            [-82.834436428499941, 14.955119058000136],
            [-82.835039090999942, 14.955843682000122],
            [-82.835637951999928, 14.956571451500011],
            [-82.836431340999923, 14.957545977000038],
            [-82.837022547999823, 14.958279978000022],
            [-82.837609903499981, 14.959017064000065],
            [-82.838387887499948, 14.960003931000088],
            [-82.839159654499952, 14.960995667500029],
            [-82.839925175999923, 14.961992233000117],
            [-82.840684419999945, 14.96299358850014],
            [-82.841437357499842, 14.963999695000027],
            [-82.84218395900001, 14.965010512000092],
            [-82.842739933999837, 14.965771548000092],
            [-82.84329191549989, 14.966535484000062],
            [-82.844022547499861, 14.967557904000117],
            [-82.844566499999985, 14.968327578000029],
            [-82.84510641499989, 14.969100090500106],
            [-82.845820896999953, 14.970133860000075],
            [-82.846528869999901, 14.9711720990001],
            [-82.847230305499977, 14.972214765500084],
            [-82.847925175999961, 14.973261818500035],
            [-82.848613453999974, 14.974313217500068],
            [-82.849125526999984, 14.975104459000121],
            [-82.84963345049988, 14.975898371500122],
            [-82.850138044, 14.976694404000042],
            [-82.850638462499973, 14.977493068500181],
            [-82.85130010149993, 14.978561431500125],
            [-82.851792128999904, 14.979365292000054],
            [-82.852279940999864, 14.980171717500113],
            [-82.852764370999978, 14.980980180000088],
            [-82.85324456049986, 14.981791167000139],
            [-82.853879142499892, 14.982875819500137],
            [-82.854506896499942, 14.983964438000086],
            [-82.85512779899986, 14.985056979000163],
            [-82.855741823499898, 14.986153399500054],
            [-82.856348947499868, 14.987253656500158],
            [-82.856949146499801, 14.988357707000162],
            [-82.857542396999975, 14.989465506000144],
            [-82.858128674999904, 14.990577011000184],
            [-82.858564012999864, 14.991412931999989],
            [-82.858994968499957, 14.992251120500114],
            [-82.859422409000018, 14.993091107000012],
            [-82.859845445999923, 14.993933320500119],
            [-82.860403606499887, 14.995059207500091],
            [-82.860954682499823, 14.996188579000076],
            [-82.861498650999863, 14.997321391500051],
            [-82.862035490999943, 14.998457599000048],
            [-82.862565181500031, 14.999597157500133],
            [-82.863087701999888, 15.000740021500107],
            [-82.863603030999968, 15.001886146000047],
            [-82.863985022999969, 15.002747752000104],
            [-82.864362496999917, 15.003611347000131],
            [-82.86485975999986, 15.00476542400007],
            [-82.86534976249996, 15.005922602999973],
            [-82.865832483999952, 15.007082838000045],
            [-82.866189964999876, 15.007954899000097],
            [-82.866542873999947, 15.00882882000009],
            [-82.867007311999913, 15.009996494500044],
            [-82.86746440399989, 15.011167063500082],
            [-82.867914131999896, 15.012340481000038],
            [-82.868246815999981, 15.013222300000095],
            [-82.868574878499885, 15.014105848500137],
            [-82.869006119999909, 15.01528618600004],
            [-82.86942993649987, 15.016469209000135],
            [-82.869743149999977, 15.017358129999977],
            [-82.870051703499968, 15.018248679500076],
            [-82.870456885999971, 15.019438214000147],
            [-82.870756097999845, 15.020331945000109],
            [-82.871050625999885, 15.02122723050006],
            [-82.871437073499919, 15.022422982500146],
            [-82.871816000999871, 15.023621139000099],
            [-82.872095488000014, 15.024521234000062],
            [-82.872370257499966, 15.025422779500175],
            [-82.872730318499919, 15.026626740000111],
            [-82.873082807499941, 15.027832939000078],
            [-82.873427710499868, 15.029041329500089],
            [-82.873765013999929, 15.030251863500055],
            [-82.874013234999865, 15.031161077000021],
            [-82.874256692499898, 15.032071577500076],
            [-82.87457494049994, 15.03328726000008],
            [-82.874885543999937, 15.034504917500144],
            [-82.875188490499909, 15.035724503500077],
            [-82.875483767999953, 15.036945968000126],
            [-82.875771365499901, 15.038169264000075],
            [-82.876051270999938, 15.039394343000097],
            [-82.876323473999889, 15.040621156000086],
            [-82.876587962999935, 15.041849655500172],
            [-82.876781502999961, 15.042772056999965],
            [-82.876970210499877, 15.04369546000008],
            [-82.877155048999953, 15.044619645000168],
            [-82.877335045, 15.045544785000075],
            [-82.877568578499933, 15.046779543000085],
            [-82.877794348499862, 15.048015744000153],
            [-82.878012347499862, 15.049253339000032],
            [-82.878170983999894, 15.050182380000095],
            [-82.878324754499886, 15.051112239000119],
            [-82.878474628999982, 15.052042734000096],
            [-82.878619628999814, 15.052974001000038],
            [-82.878806457999985, 15.054216683500144],
            [-82.878985475499917, 15.05546051550003],
            [-82.879156674, 15.056705447500107],
            [-82.879320046999936, 15.05795143100012],
            [-82.879437681999889, 15.058886548999951],
            [-82.879550419999902, 15.059822269000122],
            [-82.879694201999882, 15.061070665000116],
            [-82.879830137499908, 15.062319939500057],
            [-82.87995822049993, 15.063570043500093],
            [-82.880078446999903, 15.064820927500037],
            [-82.880163702999937, 15.065759551000113],
            [-82.880244043499971, 15.066698608500147],
            [-82.880344606499932, 15.067951226500028],
            [-82.880437297499952, 15.069204451500084],
            [-82.880522111999909, 15.070458234500094],
            [-82.880599047499857, 15.071712525500047],
            [-82.880668100999912, 15.072967276000057],
            [-82.880729268999914, 15.074222434500129],
            [-82.880770214999927, 15.075164032000018],
            [-82.880806230499928, 15.076105831500101],
            [-82.880847675999945, 15.077361796000147],
            [-82.880881228999868, 15.078617996500114],
            [-82.880906887999856, 15.079874383000018],
            [-82.880921199, 15.080816762000111],
            [-82.880930574999951, 15.08175920300009],
            [-82.880936496499885, 15.083015837500099],
            [-82.880936002999874, 15.083958325000069],
            [-82.880930574999951, 15.08490079700006],
            [-82.880921199, 15.085843238000152],
            [-82.880906887999856, 15.086785617000046],
            [-82.880881228999868, 15.088042003500121],
            [-82.880851844499972, 15.08900898000006],
            [-82.880795491499924, 15.090746666000044],
            [-83.087713056999974, 15.018563342000036],
            [-83.099496196999866, 15.01445278600012],
            [-83.131854982039727, 14.992982118916657],
            [-83.158890978485402, 14.987218051641449],
            [-83.229717892877773, 14.981664202581968],
            [-83.324172889473545, 15.004718124416272],
            [-83.358899952616696, 15.015000037432102],
            [-83.384172899503767, 15.022218029552803],
            [-83.485835829936264, 15.006109017433019],
            [-83.546399942052005, 14.948327026627155],
            [-83.566955050904511, 14.917917984735951],
            [-83.842372990630793, 14.785553986904617],
            [-83.864454946970255, 14.777773066165068],
            [-83.938063982672304, 14.753891179932836],
            [-83.97861780964314, 14.755282072949782],
            [-84.035281989838353, 14.754445056096174],
            [-84.196673021361647, 14.716391047935318],
            [-84.260281943633203, 14.681944946187471],
            [-84.480490969197632, 14.6188179833497],
            [-84.688608931759518, 14.678336033946138],
            [-84.797017953162651, 14.813891189963257],
            [-84.826390991818499, 14.829027063121728],
            [-84.910008858130368, 14.806664145387046],
            [-84.984364050855135, 14.741309105040997],
            [-84.995281982685839, 14.726109026504119],
            [-85.030008878191097, 14.670000063576694],
            [-85.018926828695697, 14.598236052406847],
            [-85.027454912286288, 14.580900097296634],
            [-85.061108924182037, 14.553336040989151],
            [-85.080354947392976, 14.544509058730526],
            [-85.146390933554841, 14.489164190098691],
            [-85.180555068079258, 14.426664193620283],
            [-85.179455027103529, 14.385273182157832],
            [-85.159664012546386, 14.359654062668525],
            [-85.159126900189506, 14.335653991601291],
            [-85.180281985673162, 14.314718009067306],
            [-85.217226900402693, 14.296454009634985],
            [-85.254181873416229, 14.298336082179205],
            [-85.276399951863397, 14.295827043275708],
            [-85.298335895449156, 14.289445062180036],
            [-85.3222268341367, 14.27624507338443],
            [-85.378199842594199, 14.235553950839105],
            [-85.409454953794523, 14.171244972012332],
            [-85.416535817978854, 14.145691063729956],
            [-85.436954972361576, 14.122500013959453],
            [-85.543964048292452, 14.045718092620334],
            [-85.557717913251395, 14.043536115579698],
            [-85.728899845901054, 13.962573133287023],
            [-85.748272938764586, 13.885064165664559],
            [-85.724726831574429, 13.864509056811954],
            [-85.728663979145068, 13.83309116905167],
            [-85.73639092042788, 13.828609030135027],
            [-85.794308865703641, 13.836527078811045],
            [-85.828899974376981, 13.859445046175267],
            [-85.968335783937135, 13.958891130849779],
            [-85.996126822183385, 13.98027319827203],
            [-86.020281958459805, 14.005344979482359],
            [-86.008608817150957, 14.055173214704908],
            [-86.070146068228013, 14.055973183546214],
            [-86.119664005393872, 14.030691184203548],
            [-86.132363929844615, 13.995209079220231],
            [-86.325573001186882, 13.763473092738735],
            [-86.401117921251597, 13.749164178149798],
            [-86.469999898825677, 13.764164096838357],
            [-86.566390947422406, 13.779164183165094],
            [-86.710772918060059, 13.774654048691829],
            [-86.758599890074834, 13.754009085836927],
            [-86.764208892057354, 13.710691074829029],
            [-86.750835900779805, 13.567782139859474],
            [-86.727054932662071, 13.465473132328313],
            [-86.710846008255999, 13.434164209309515],
            [-86.696117830869156, 13.353891057650188],
            [-86.696054966595199, 13.298818097958161],
            [-86.735772950179211, 13.263609075381041],
            [-86.745281884082146, 13.26082712170944],
            [-86.775699978428861, 13.283745089073747],
            [-86.817508911584838, 13.298400176264735],
            [-86.859999964022933, 13.28333605219774],
            [-86.90258187629189, 13.248609156692481],
            [-86.918063922052511, 13.195000012575164],
            [-86.913346084017945, 13.1633269796815],
            [-86.924726864543317, 13.082773201903123],
            [-86.955699840881749, 13.036664184768028],
            [-87.022781888562889, 12.988191135654858],
            [-87.048755059643355, 12.98517314758918],
            [-87.093063979247319, 12.998609003140785],
            [-87.301407467607135, 12.98665007665231],
            [-87.302464043333345, 12.986905317666768],
            [-87.329800404, 13.001325778],
            [-87.38980915599987, 12.99565337500006],
            [-87.403369377999979, 13.029105573999985],
            [-87.414346336999984, 13.040180731000106],
            [-87.424524376999955, 13.047666048000025],
            [-87.457612474999848, 13.101299808],
            [-87.460815708000041, 13.102487160000109],
            [-87.501425244000018, 13.124194165000077],
            [-87.560891667999869, 13.169294002000157],
            [-87.574779977999867, 13.17195373500013],
            [-87.650738521999955, 13.195444608000173],
            [-87.651841608000012, 13.215117637999981],
            [-87.677110155999969, 13.238001920000016],
            [-87.72810073699992, 13.299919920000036],
            [-87.768870786000036, 13.33036604900002],
            [-87.783372574, 13.334359442000121],
            [-87.81341743599998, 13.361669505000151],
            [-87.833253117999845, 13.379453219000141],
            [-87.828465193999989, 13.393132999000116],
            [-87.81752137, 13.404076823000111],
            [-87.815582023166201, 13.405382094914572],
            [-87.750290853088728, 13.443054055842296],
            [-87.713345938359083, 13.471109124039145],
            [-87.711090871122508, 13.48165406117765],
            [-87.715317864905757, 13.504754083479398],
            [-87.754864019474041, 13.514636012074718],
            [-87.783390988821026, 13.524164224355047],
            [-87.776945975813305, 13.572427055336078],
            [-87.725008853746147, 13.733327074969722],
            [-87.694672902050769, 13.819009068763322],
            [-87.707646076545871, 13.822500131445636],
            [-87.725708910301364, 13.834582141992783],
            [-87.750290853088728, 13.864164225314383],
            [-87.800845966956444, 13.896945010534708],
            [-87.897508924492968, 13.899091113029883],
            [-87.908335828854916, 13.895827032286164],
            [-87.940563911378291, 13.895000073716446],
            [-87.953335920196679, 13.896108999509678],
            [-88.005863966439478, 13.875044941494863],
            [-88.040435796735352, 13.931173182799583],
            [-88.06000888180921, 13.963891103746121],
            [-88.121945949668884, 13.990553937821559],
            [-88.161663933252782, 13.976945079787939],
            [-88.196882008285399, 13.987618092407232],
            [-88.234999886548621, 13.940273079826412],
            [-88.259608819064312, 13.905282154370624],
            [-88.319308924598289, 13.888744994635246],
            [-88.345226942755545, 13.874864227661831],
            [-88.352235890210324, 13.867773137555602],
            [-88.375972937155325, 13.882500141476143],
            [-88.403346053708049, 13.884026988962702],
            [-88.431673030844706, 13.87013599606739],
            [-88.467926941474701, 13.854717988047227],
            [-88.488890919565364, 13.865836079726449],
            [-88.499454967442887, 13.915835976326363],
            [-88.495008870710251, 13.970273084841622],
            [-88.506672959563701, 13.982364147843995],
            [-88.629981987369206, 14.014827091295146],
            [-88.661482017780855, 14.014082107738901],
            [-88.705699909916149, 14.044164087767271],
            [-88.719308935587861, 14.092364222112323],
            [-88.734172899806424, 14.115553930778546],
            [-88.752235901200066, 14.134445064208009],
            [-88.772435784995139, 14.133473098713168],
            [-88.806326837113176, 14.096182179019493],
            [-88.827572950065587, 14.100136090396475],
            [-88.831117824566377, 14.114164211228186],
            [-88.839446083584903, 14.13847307160934],
            [-88.860290871012069, 14.171109017542435],
            [-88.875145950413298, 14.187635951355745],
            [-88.897781950553991, 14.203609009005717],
            [-88.920208906029103, 14.205973208622069],
            [-88.956046068431988, 14.193891030436944],
            [-88.959472925735867, 14.216982167921444],
            [-88.96055502943878, 14.221945092805569],
            [-88.977782019807307, 14.253891040467337],
            [-89.024735932784751, 14.327636030639198],
            [-89.037226812569742, 14.334718068290087],
            [-89.119726868270845, 14.324373123362122],
            [-89.174454996465386, 14.354718127512811],
            [-89.21334602147968, 14.375827112528938],
            [-89.297791013999159, 14.40110911187152],
            [-89.315564001541986, 14.404091057753462],
            [-89.332164025551236, 14.410482091304644],
            [-89.348308912216766, 14.431982175923778],
            [-89.350490889257401, 14.471526989387755],
            [-89.235835841228578, 14.58242711242157],
            [-89.197235835893309, 14.585000021427405],
            [-89.137790875492897, 14.684718015041753],
            [-89.131072947717058, 14.715073077476418],
            [-89.154873026574109, 14.733536063290572],
            [-89.221735803667599, 14.844445071141806],
            [-89.223064000048453, 14.871945089708916],
            [-89.206535892768585, 14.893609124354768],
            [-89.185991009837949, 14.90760908199168],
            [-89.175763914468803, 14.924100141259359],
            [-89.154873026574109, 14.980827018090523],
            [-89.177435936519032, 14.998535967892835],
            [-89.170835858302098, 15.030000123758825],
            [-89.15833592606171, 15.056945092696154],
            [-89.150372950384735, 15.07348208479327],
            [-89.142790848388955, 15.076944984280956],
            [-88.980563973479008, 15.135553933656254],
            [-88.837235775711349, 15.247217983347653],
            [-88.758117817950705, 15.295518029978794],
            [-88.668335900026591, 15.350000065495365],
            [-88.552508894292487, 15.4497180591096],
            [-88.486508950314175, 15.504618016319583],
            [-88.32779090772118, 15.635273111726619],
            [-88.315463977962821, 15.667336070757088],
            [-88.240008911900276, 15.71416408754844],
            [-88.216510694389569, 15.723713765491695],
            [-88.215603168954146, 15.724092154697544],
            [-88.214736075409974, 15.724463002189381],
            [-88.216736747999875, 15.734633942000087],
            [-88.208915747999981, 15.748426942000023],
            [-88.199277558999825, 15.766282798000205],
            [-88.185819190999979, 15.791215333000068],
            [-88.174387473000024, 15.811983289000054],
            [-88.164801970499923, 15.829456596000128],
            [-88.166512102999889, 15.841470089000168],
            [-88.176391195999884, 15.871428449000106],
            [-88.178892979999858, 15.879015113000165],
            [-88.179089958666651, 15.883089943666747],
            [-88.179575221999897, 15.893128428000111],
            [-88.180022, 15.901564413],
            [-88.180094751499951, 15.902938086000248],
            [-88.180588083499913, 15.917679738500098],
            [-88.180880473799874, 15.923009318400105],
            [-88.120946501, 15.963146559000037],
            [-88.114011514999959, 15.971535841000062],
            [-88.101867838999965, 15.986105890000076],
            [-88.082727296, 16.00925120700002],
            [-88.075829075999962, 16.017149771000035],
            [-88.063191699000015, 16.031897826000019],
            [-88.049714660999939, 16.046842977000082],
            [-88.028058464999987, 16.068053487],
            [-88.025177404, 16.069827303000139],
            [-87.979210802999972, 16.09886200900003],
            [-87.933758599999976, 16.139007540000094],
            [-87.932034111, 16.140048934],
            [-87.925849680999988, 16.143847328000149],
            [-87.905452240000017, 16.158380758000035],
            [-87.869732954, 16.184122062000128],
            [-87.867404551999897, 16.185896845999977],
            [-87.845339205999949, 16.196255243000124],
            [-87.809068353000043, 16.220255674000143],
            [-87.802158548999955, 16.22487489600006],
            [-87.762769576999972, 16.24710287200007],
            [-87.675980926999983, 16.294924131000059],
            [-87.60775917, 16.348123215000143],
            [-87.604281325000017, 16.348899699000086],
            [-87.467536795999962, 16.379032520000081],
            [-87.418364835499972, 16.38960209650007],
            [-87.363167471999986, 16.45123231200013],
            [-87.210589286000015, 16.618034690000101],
            [-87.145387317999962, 16.696409308000071],
            [-87.121198869999944, 16.70830200400006],
            [-87.108329702999981, 16.714706232000069],
            [-87.094353900000016, 16.727708825999954],
            [-87.065487395, 16.754387034000118],
            [-87.029082356, 16.788159511999979],
            [-87.012702676999822, 16.803354726000023],
            [-87.011094037999953, 16.80488585199997],
            [-87.007952093999961, 16.807876400000154],
            [-86.964352152, 16.849528813],
            [-86.912778864999979, 16.899313765000116],
            [-86.911634312000047, 16.900443047000024],
            [-86.86771407299986, 16.946750076999976],
            [-86.858161707999983, 16.957176387000047],
            [-86.785169835999852, 17.036926698999977],
            [-86.775784649999963, 17.048399389000039],
            [-86.677980356999967, 17.178926032999968],
            [-86.677494524999901, 17.179573491000085],
            [-86.677007392999826, 17.180221896999981],
            [-86.661227239999988, 17.20121203799998],
            [-86.630980565999977, 17.241586901],
            [-86.580088113999977, 17.290858825000029],
            [-86.573253560999888, 17.297599874000113],
            [-86.546069344999978, 17.325475753999953],
            [-86.432585581999831, 17.441844271999955],
            [-86.30746999599998, 17.569575399000129],
            [-86.281714753999893, 17.597219234000065],
            [-86.162847142, 17.784386841],
            [-86.112897184999895, 17.856131799000067],
            [-85.902248305999905, 17.989591910999977],
            [-85.549591622999941, 18.213023437000246],
            [-85.522932500999843, 18.281906141000078],
            [-85.51761038199993, 18.295657605000045],
            [-85.417371310000021, 18.552791886000094],
            [-85.390661733999849, 18.612587242000259],
            [-85.332781455999879, 18.742165182000107],
            [-85.329981147999888, 18.748434143000125],
            [-85.278321416999916, 18.863725151000111],
            [-85.231726325999944, 18.967712920999972],
            [-85.202097316999868, 19.033940654000062],
            [-85.185345622499881, 19.071621024000152],
            [-85.183443322000016, 19.075899958000079],
            [-85.175726059999988, 19.093258756000168],
            [-85.174447814000018, 19.094406232000082],
            [-85.173798986999884, 19.094988681000117],
            [-85.04775409399997, 19.201640816000165],
            [-85.012060177999899, 19.231370692000112],
            [-84.984189785999888, 19.254608631000096],
            [-84.964087624999905, 19.271180997999963],
            [-84.884404820999976, 19.336765923000158],
            [-84.882878254999866, 19.338022402000107],
            [-84.8757155589999, 19.343889535000173],
            [-84.788831129999892, 19.415058627000107],
            [-84.763384475999942, 19.435826538000086],
            [-84.759585149, 19.438912172000144],
            [-84.741339354999866, 19.453730542000173],
            [-84.668826472999967, 19.516972519000134],
            [-84.64185, 19.5405],
            [-84.247615264999922, 19.581822440000096],
            [-83.930898217999896, 19.523001882000116],
            [-83.59722, 19.465833],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "HRV", Country: "Croatia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.607873242467321, 46.476235983582896],
            [16.628609232790978, 46.475000155775362],
            [16.661109224254176, 46.465554086146469],
            [16.801391270761343, 46.391391174280912],
            [16.840554037077254, 46.369991169585759],
            [16.874300249908259, 46.345336136602455],
            [16.879027140398222, 46.308573109172372],
            [16.880582151079579, 46.304509059587232],
            [16.891391118168798, 46.283745073707038],
            [16.919718095305456, 46.259991095317517],
            [16.937773217709889, 46.249454037168121],
            [16.973054157016577, 46.233609054999121],
            [17.040273164995909, 46.214164213044214],
            [17.15270918797188, 46.180273160926092],
            [17.182773230727548, 46.154582124707318],
            [17.234718231783717, 46.090418152805952],
            [17.254718123368434, 46.053327057684427],
            [17.257427154482372, 46.020964194157543],
            [17.311391188381151, 45.975273098715959],
            [17.35485403867628, 45.951664127251846],
            [17.389445147349534, 45.938045043296242],
            [17.427573251534824, 45.940291225715598],
            [17.458609092147242, 45.95443602263974],
            [17.58256319122296, 45.937564257328845],
            [17.626109189997834, 45.905827019056758],
            [17.644854143035587, 45.885273251308504],
            [17.652691222526471, 45.853136028615708],
            [17.660973213439291, 45.838744971375618],
            [17.880836066401571, 45.783882229815831],
            [18.006382224170778, 45.786944977244744],
            [18.153327213168836, 45.78721805965084],
            [18.195554068018254, 45.783882229815831],
            [18.225827155439646, 45.779718100306624],
            [18.248054118704147, 45.767291090624084],
            [18.332636071522103, 45.754436100964043],
            [18.407500213409179, 45.748327034636574],
            [18.433745125791177, 45.750973201476526],
            [18.555554128200185, 45.800000126753034],
            [18.598473160615896, 45.827918067013499],
            [18.618473219838592, 45.851391084007716],
            [18.719445146171466, 45.911518163690616],
            [18.780000205831698, 45.894718147471096],
            [18.807500224398638, 45.90277315644515],
            [18.817018210757084, 45.912964209630488],
            [18.83235407612591, 45.875509172272288],
            [18.889854099708145, 45.825764253167549],
            [18.916663281813641, 45.815691217179378],
            [18.956663232621167, 45.782500053978296],
            [18.966891166180545, 45.664073149510031],
            [18.936391264458564, 45.628054099807699],
            [18.910418093378183, 45.620173099143997],
            [18.902236182389629, 45.573136037686808],
            [18.929182157155253, 45.544845102733873],
            [18.948727079034427, 45.537936067565184],
            [18.969663229206418, 45.539991142591518],
            [19.007091276836405, 45.561136002153106],
            [19.097927135268975, 45.51886422030266],
            [19.096018072996458, 45.49800903931542],
            [19.056400169336712, 45.487018017288776],
            [19.025573205751783, 45.497073116004216],
            [19.001082290433203, 45.489691173856968],
            [18.981263280319524, 45.382218075765039],
            [19.112500247446604, 45.298044992185382],
            [19.138609205358819, 45.287500055047062],
            [19.190200154823941, 45.270336096590583],
            [19.260518120054087, 45.24742701404368],
            [19.285000150555362, 45.243409064926155],
            [19.320336075146884, 45.238364165028784],
            [19.350563229738754, 45.234582082667359],
            [19.388963242863753, 45.235744987917329],
            [19.409209227126354, 45.235273254405158],
            [19.425000229838815, 45.21791802091785],
            [19.418536106091864, 45.179991082409387],
            [19.352500119929914, 45.173336018907591],
            [19.248682202184995, 45.186664250822176],
            [19.212827102509209, 45.210000139879995],
            [19.187500176165514, 45.216945049594514],
            [19.167354104189002, 45.21430005622112],
            [19.10416327124895, 45.096664235776629],
            [19.115554110058014, 45.031945046607191],
            [19.04076322600497, 44.930764243246742],
            [19.009818077585066, 44.905482076266068],
            [19.028609131090462, 44.88027316711937],
            [19.039718170314188, 44.861382201327885],
            [19.029718224521787, 44.86027310789656],
            [18.962773304777045, 44.853327024715568],
            [18.846736080910688, 44.862773094344547],
            [18.791109245055338, 44.897353977096103],
            [18.768054149754448, 44.915836073649459],
            [18.769791215373544, 44.942145023771999],
            [18.797354098214441, 44.945136022109452],
            [18.795000124520072, 44.993609071222821],
            [18.634163304436555, 45.08332711904454],
            [18.41916329643422, 45.108891085610921],
            [18.26082713335083, 45.137773112377303],
            [18.217845069023269, 45.125900146496278],
            [18.21527316584573, 45.098536082398951],
            [18.201527179875825, 45.08332711904454],
            [18.173054022347372, 45.07819119167867],
            [18.117982236121719, 45.084436044837886],
            [18.070273281304111, 45.104436104060582],
            [17.933054149864063, 45.080273256433131],
            [17.912500214477831, 45.068053950311892],
            [17.860345162927302, 45.049373202652461],
            [17.832773227630895, 45.055544965615908],
            [17.758054092669141, 45.086664122346136],
            [17.690000247131167, 45.12277319368873],
            [17.596382267388805, 45.108891085610921],
            [17.519554077944264, 45.10950916715278],
            [17.485900233686607, 45.117564176126919],
            [17.282500109159088, 45.176382170168054],
            [17.237082263761891, 45.148745023664631],
            [17.182773230727548, 45.148327101971219],
            [17.071109181036235, 45.188327052778746],
            [17.04257315923374, 45.211800069773048],
            [16.913927206023402, 45.265944985142696],
            [16.844427146907464, 45.196336128923249],
            [16.818473254204463, 45.187491209391695],
            [16.682782143717446, 45.20499111452834],
            [16.662773199677133, 45.21027322228629],
            [16.62966334093727, 45.220809106969099],
            [16.595918133934759, 45.230191138857649],
            [16.531091153490223, 45.225527112641643],
            [16.505409169727045, 45.21209108945196],
            [16.476391188490567, 45.18582706633056],
            [16.417800176387999, 45.124264166629857],
            [16.366400166677636, 45.042018082595447],
            [16.352663233163213, 45.007382214559144],
            [16.291763342005311, 44.999118160919338],
            [16.255973285898563, 45.011664193627254],
            [16.215000196129523, 45.031382117988457],
            [16.197218156131186, 45.044444978847778],
            [16.11277316361182, 45.126244977993665],
            [16.022427143602215, 45.214373146416975],
            [15.930827190874083, 45.228327003586159],
            [15.882500154514616, 45.222500072120383],
            [15.828745165281674, 45.208609079225155],
            [15.806800169240603, 45.191800178188132],
            [15.785763268591921, 45.168953959915299],
            [15.765136075371942, 45.077918109272289],
            [15.765000120902045, 45.056391202562921],
            [15.769163244582757, 44.922773104375054],
            [15.740591180596624, 44.812244970205043],
            [15.763745182355109, 44.77999107141973],
            [15.863191267029606, 44.712145097081006],
            [15.942218197321409, 44.707845013101988],
            [15.961663206914324, 44.693882103477208],
            [16.010554010083013, 44.616945116929159],
            [16.033336190615586, 44.574164218278256],
            [16.074163267630723, 44.530545129307527],
            [16.115409104529789, 44.509164235351776],
            [16.129863193682041, 44.492353993210187],
            [16.198054167156215, 44.353609187749854],
            [16.191945100828917, 44.313891204165841],
            [16.145482199740854, 44.198673227518285],
            [16.166109225322828, 44.192491070994762],
            [16.206800180230175, 44.196382118097873],
            [16.243673178230239, 44.197527086074743],
            [16.27263617418177, 44.183191181757579],
            [16.305827169744589, 44.152909041880761],
            [16.323054160113202, 44.120064218919893],
            [16.346800259513628, 44.087500189716351],
            [16.430000204131971, 44.036036142265644],
            [16.463609121388487, 44.026664168660815],
            [16.510418195078614, 44.022500206789786],
            [16.533473290379391, 44.015691083907186],
            [16.646391272789316, 43.91221816529783],
            [16.699027110307156, 43.853336133516152],
            [16.709582273367346, 43.833745111169449],
            [16.829718080259823, 43.725554019249373],
            [16.937500135303793, 43.621664184775099],
            [16.955827166648049, 43.603335979964257],
            [16.976391160318087, 43.586109157233821],
            [17.053054058631517, 43.536245047465812],
            [17.071391148259778, 43.526382229609695],
            [17.106663202749019, 43.51027321748991],
            [17.160273185056667, 43.497773117611345],
            [17.223327057698384, 43.478054019783755],
            [17.254718123368434, 43.464027072418517],
            [17.27069118101852, 43.445344983654806],
            [17.257845076175897, 43.420064157778526],
            [17.278191140362651, 43.329018081213817],
            [17.287636204163334, 43.302082164732084],
            [17.303745048645141, 43.275691071958292],
            [17.434718153459414, 43.179445030286885],
            [17.56528222139778, 43.115000096990215],
            [17.589445069025317, 43.10221802988822],
            [17.636663347229728, 43.070418094979956],
            [17.65444505195191, 43.047218160392035],
            [17.667636155930182, 43.022909132372703],
            [17.675300232939037, 42.96751816327334],
            [17.578618028670746, 42.943757840193811],
            [17.57976263550006, 42.938743625500038],
            [17.57857953300018, 42.938322521500098],
            [17.575786467500052, 42.936573304500044],
            [17.575281019500068, 42.935768403000012],
            [17.571870680250129, 42.936799325000052],
            [17.567636366250099, 42.937134064750111],
            [17.564546461500157, 42.937059678000097],
            [17.563323851500087, 42.935848511500026],
            [17.562553282500176, 42.935789383500023],
            [17.558948393000122, 42.935127534000102],
            [17.558013792500191, 42.935245789500087],
            [17.556112165500139, 42.936386384000073],
            [17.554810400500088, 42.937000550250119],
            [17.552432890500199, 42.938026703500057],
            [17.551355238500207, 42.938308991500051],
            [17.548801298500081, 42.937345780000058],
            [17.547519560500177, 42.935791291500081],
            [17.544999952500064, 42.932501114499999],
            [17.542920942500103, 42.930422104500124],
            [17.541364689400183, 42.92831441240007],
            [17.545043945000117, 42.925525665000023],
            [17.547771454000099, 42.923316956000164],
            [17.548608780000109, 42.923067093],
            [17.550303459000162, 42.922354698000035],
            [17.553750991500095, 42.921226501499987],
            [17.555767059500113, 42.920490265000055],
            [17.557849884000092, 42.919861793500061],
            [17.559087753, 42.919523239000156],
            [17.560003281000093, 42.91936492900011],
            [17.562091827000046, 42.918718338000176],
            [17.564043045000119, 42.91781234699998],
            [17.564823151000098, 42.917566298999972],
            [17.565724373000137, 42.917105674500093],
            [17.567258835000189, 42.916309356999975],
            [17.569237709000134, 42.915597915500129],
            [17.570014954000044, 42.915205002000093],
            [17.573622704000229, 42.913990021],
            [17.575654030000152, 42.913162231500039],
            [17.577421188000187, 42.912380219000042],
            [17.578510284000146, 42.912059784],
            [17.579799652000162, 42.912059784],
            [17.582496643000098, 42.911220550500147],
            [17.583486557000128, 42.910888671999984],
            [17.584884644000169, 42.910276413000147],
            [17.585626602, 42.909652710000145],
            [17.588879585500166, 42.907502174500067],
            [17.590507506999984, 42.906671524000032],
            [17.59141159100011, 42.906667709000075],
            [17.595029831000062, 42.905988693000026],
            [17.596378326000092, 42.905361175999971],
            [17.597312927000047, 42.905071259000053],
            [17.60048866250014, 42.903434753000099],
            [17.601133347000143, 42.903181076000166],
            [17.603116989000142, 42.902851105000124],
            [17.604482651000154, 42.902370453000046],
            [17.606513977000191, 42.902484894000011],
            [17.60916709900016, 42.901978493000044],
            [17.61307525600003, 42.901468277],
            [17.614135742000116, 42.899984360000062],
            [17.615585327000161, 42.898532866999957],
            [17.6167736055001, 42.897708893000171],
            [17.6201496125002, 42.895968437000064],
            [17.623142242000142, 42.894401550000097],
            [17.624267578000087, 42.894334793000056],
            [17.627426147500159, 42.893492698500083],
            [17.630233765000156, 42.891170502000094],
            [17.631195068000125, 42.889883041000061],
            [17.632740021000046, 42.888818741],
            [17.634881902055071, 42.88748138495437],
            [17.651557821468316, 42.882546601708682],
            [17.652763208000152, 42.885185598000149],
            [17.650314912234194, 42.888463695924358],
            [17.649814328190558, 42.889096573680703],
            [17.686109200028142, 42.920273230616871],
            [17.706527180944477, 42.927082185861579],
            [17.744445066997429, 42.920273230616871],
            [17.797500167313331, 42.90388208363548],
            [17.845345076600836, 42.860835981567291],
            [17.879718088152941, 42.821382195572269],
            [17.960000124629687, 42.779164225540185],
            [17.990554173446299, 42.764718015377056],
            [18.160554090106871, 42.69500019441584],
            [18.226945133688304, 42.645554006341328],
            [18.274654088505798, 42.617145054191312],
            [18.307082163239727, 42.618191115710587],
            [18.340973215357849, 42.629027072527933],
            [18.362500122067331, 42.626664214016344],
            [18.404718092099301, 42.610409189142658],
            [18.455554167362436, 42.565827019494506],
            [18.453327095682482, 42.516800094217714],
            [18.457782244870714, 42.493609044447211],
            [18.472918118029185, 42.471527088107834],
            [18.498054104618035, 42.451109107191527],
            [18.503200684923257, 42.449436470162809],
            [18.504473681666838, 42.449386757999989],
            [18.506832610000117, 42.449293880000127],
            [18.510112500000162, 42.448697540000168],
            [18.511895230000135, 42.448373400000079],
            [18.516590590000135, 42.446547880000011],
            [18.520179510000077, 42.445151739999957],
            [18.522299450000162, 42.444092190000148],
            [18.52570237000009, 42.442390310000107],
            [18.531225220000067, 42.438708410000018],
            [18.533986650000116, 42.43456626000004],
            [18.535827520000112, 42.429043540000052],
            [18.535827600000118, 42.423520549999964],
            [18.533986520000099, 42.415236540000038],
            [18.533145110500158, 42.409632639500032],
            [18.53494814600009, 42.40820297],
            [18.535705931000166, 42.407608035],
            [18.538301520000175, 42.406116410000024],
            [18.541604323000058, 42.404573145000072],
            [18.543472300000161, 42.40355474],
            [18.544992942000135, 42.401657745000065],
            [18.546979647000114, 42.399547128000151],
            [18.549783788000127, 42.395627599000122],
            [18.550645649000131, 42.394520103000147],
            [18.552246744500138, 42.392083612500116],
            [18.551895598000158, 42.388226584000151],
            [18.551658028500185, 42.382183009999963],
            [18.550580843000091, 42.375870723000119],
            [18.548748728500158, 42.366348967500059],
            [18.546400397000156, 42.354813071000038],
            [18.545984140000087, 42.353744383000091],
            [18.543803121, 42.349361931000132],
            [18.521006671000038, 42.304323909000104],
            [18.490497025000138, 42.253486679000034],
            [18.354013041000144, 42.039415851000157],
            [18.278657022000175, 41.91945534500006],
            [18.20190726800007, 41.830220855],
            [18.19856132100017, 41.826416743000024],
            [18.020445856, 41.622009092000141],
            [17.997245842000154, 41.643067695000113],
            [17.625498526000143, 41.832173937999968],
            [17.616666667000089, 41.836666666999989],
            [17.311666667, 41.913333332999954],
            [17.21666666700014, 41.99166666699999],
            [17.12110355700014, 42.035908847000073],
            [16.946666667000159, 42.116666667000047],
            [16.782293972000133, 42.191760791000021],
            [16.751514397999983, 42.205822526000063],
            [16.708691091999981, 42.225386472000011],
            [16.660956751000157, 42.247194039000036],
            [16.623895009, 42.264125800000059],
            [16.619266291000088, 42.266240443000143],
            [16.618150949000096, 42.266098652000139],
            [16.616944444000183, 42.266666666000148],
            [16.59013368300009, 42.278638799000149],
            [16.582982365000163, 42.269452467000164],
            [16.571437557, 42.256512637000029],
            [16.565908557000029, 42.251004637000122],
            [16.554519557000162, 42.240760637000122],
            [16.542257556999971, 42.231089637000096],
            [16.539534100000139, 42.22920461599999],
            [16.529173557000092, 42.222033636999967],
            [16.515325557000153, 42.213631637000148],
            [16.500770557000038, 42.205919637000122],
            [16.485571557000043, 42.198929637000148],
            [16.469793557000116, 42.192690637000041],
            [16.453504556999974, 42.187231637000096],
            [16.436771557000156, 42.182572637000064],
            [16.429169715, 42.180867289],
            [16.41966755700011, 42.178735637000031],
            [16.40226455700008, 42.175736637000043],
            [16.384636557000107, 42.173587637000068],
            [16.372999257, 42.172743264],
            [16.366857556000156, 42.172297637000085],
            [16.349003556000127, 42.17187363699999],
            [16.331150556000182, 42.172315637000153],
            [16.326892556000132, 42.172596636999955],
            [16.321836556000022, 42.172721636999981],
            [16.30405955599997, 42.174027637000037],
            [16.28643455600016, 42.176192637000028],
            [16.281514572000134, 42.177045250000063],
            [16.269036556000117, 42.179207637],
            [16.259679307000113, 42.181315726999983],
            [16.251938556000113, 42.183059637000142],
            [16.248249556000047, 42.184090637000097],
            [16.237674556000115, 42.184860637000028],
            [16.220044556000147, 42.18701463699999],
            [16.202639556000037, 42.19001963700002],
            [16.185534556000079, 42.193861637000126],
            [16.183314617000178, 42.194480466000172],
            [16.172573555999975, 42.197474637000013],
            [16.166518556000113, 42.198833637000106],
            [16.164280055000063, 42.199457063],
            [16.149782556000019, 42.20349463700002],
            [16.133490556000112, 42.208956636999957],
            [16.117710556000162, 42.215197637000031],
            [16.102509556000086, 42.222189637],
            [16.098849891000157, 42.22412934000009],
            [16.087953556000087, 42.229904637000104],
            [16.077560070000061, 42.236211269],
            [16.074103556000154, 42.238308637],
            [16.06101955600019, 42.247365637000016],
            [16.05554355600006, 42.251684636999968],
            [16.049689556000118, 42.255735637000143],
            [16.03742355600005, 42.265406637000169],
            [16.02711618500004, 42.274676213],
            [16.026031556000191, 42.275651637000166],
            [16.015335788500153, 42.286696412000069],
            [16.006061556000049, 42.297688637000178],
            [16.000237556, 42.305508637000074],
            [15.999254556000125, 42.30689863700006],
            [15.996586556000182, 42.310775637000134],
            [15.989140556000137, 42.322858636999953],
            [15.982771556000017, 42.335277636999976],
            [15.98246948200017, 42.336006760000103],
            [15.97827728300004, 42.346125558000139],
            [15.977509556000115, 42.347978636999969],
            [15.973376556, 42.360907637000096],
            [15.970390556000126, 42.37400863699996],
            [15.968892674000188, 42.384857405],
            [15.968565556000129, 42.387226637000012],
            [15.96796485699997, 42.39940479],
            [15.967910556000106, 42.400505637000137],
            [15.968443654000168, 42.413899328500023],
            [15.968729814000113, 42.416186772000046],
            [15.969134592000131, 42.419422405],
            [15.969517237000161, 42.422481111000124],
            [15.969527503500188, 42.425242081000036],
            [15.970416270000129, 42.427427710000032],
            [15.971482510000158, 42.431821160000013],
            [15.97206690500019, 42.433484961000019],
            [15.746666667000113, 42.49166666700016],
            [15.734130212000139, 42.495645628000133],
            [15.5531521690001, 42.55308648499998],
            [15.363333333000071, 42.613333333000114],
            [15.158333333000115, 42.798333333000031],
            [15.074071790000119, 42.906532816000109],
            [15.011666667000128, 42.986666667000165],
            [14.989905489000108, 42.99910162500008],
            [14.965, 43.013333332999977],
            [14.908333332999973, 43.063333333000131],
            [14.798333333000159, 43.176666667000163],
            [14.790024805000144, 43.18716165000005],
            [14.766666666999981, 43.216666667000069],
            [14.58166666700015, 43.42],
            [14.533333333000144, 43.495000000000161],
            [14.506666667000161, 43.526666666999958],
            [14.440000000000168, 43.44],
            [14.408557931000189, 43.638581486000035],
            [14.408333333000087, 43.64],
            [14.391666667000152, 43.671666667000167],
            [14.356666667000155, 43.716666666999956],
            [14.171666667000125, 43.900000000000105],
            [14.083333333000127, 43.958333333000084],
            [14.015, 44.008333333000152],
            [13.666666667000158, 44.180000000000163],
            [13.631666667000047, 44.208333333000027],
            [13.471666667000079, 44.296666667000025],
            [13.195000000000135, 44.465],
            [13.183333333000178, 44.47666666700006],
            [13.135, 44.500000000000128],
            [13.12398694200013, 44.621143642999968],
            [13.113348271000064, 44.738169018000022],
            [13.106666667000127, 44.768333332999973],
            [13.07833333300016, 44.975000000000179],
            [13.008333333000053, 45.18500000000013],
            [13.019921908500066, 45.204866129000109],
            [13.063333333000116, 45.28],
            [13.101666667000131, 45.335000000000136],
            [13.19000000000014, 45.431666667000044],
            [13.203118648000043, 45.444785314000043],
            [13.201944444000162, 45.450555555000093],
            [13.235829225000089, 45.485967097000028],
            [13.25790906300017, 45.514954435000121],
            [13.265752933000101, 45.525507366000014],
            [13.278763075000086, 45.530726597000111],
            [13.32554478300014, 45.548631199999974],
            [13.344713422000041, 45.556523664000011],
            [13.36511104900012, 45.562817688000038],
            [13.372198923000127, 45.565045408999978],
            [13.38609981400009, 45.569848918000147],
            [13.418516378000135, 45.580815999000109],
            [13.424499992000136, 45.582253774999955],
            [13.451750585000042, 45.588082829000072],
            [13.463980999111214, 45.590793996555604],
            [13.508600213000108, 45.544618033000106],
            [13.523037963000093, 45.530843415],
            [13.526970012, 45.52682215500009],
            [13.532071196, 45.522952081000071],
            [13.535423714000103, 45.520699285],
            [13.538272190000157, 45.517395830000154],
            [13.539495389000109, 45.516097377000094],
            [13.540668612000104, 45.515080544000071],
            [13.541228947000121, 45.514511050000024],
            [13.544727276000117, 45.511719186000036],
            [13.546840899000188, 45.510271484000057],
            [13.54976288600011, 45.508753718000079],
            [13.552065292000179, 45.50732703400017],
            [13.554753517000023, 45.505901162000143],
            [13.557593818000129, 45.504829014000066],
            [13.560809531000189, 45.50343914],
            [13.565169229000134, 45.502087405000154],
            [13.568521989999965, 45.500807320000021],
            [13.570816950000051, 45.499516400000047],
            [13.571746760000167, 45.498993390000024],
            [13.57597901000014, 45.496373820000102],
            [13.578539260000042, 45.495038040000154],
            [13.580614860000111, 45.493954700000032],
            [13.584242720000162, 45.490729930000029],
            [13.586272810000082, 45.488699840000109],
            [13.587668780000115, 45.48730417],
            [13.590527010000187, 45.483366360000062],
            [13.591736068774964, 45.481700202811666],
            [13.601391182844367, 45.473191062133893],
            [13.640482200069101, 45.453745046712513],
            [13.673473203421764, 45.445691211204846],
            [13.703609162906872, 45.444164196080109],
            [13.906945082055756, 45.453327125019186],
            [13.952500223027329, 45.456664128320583],
            [13.988263289405864, 45.463400161007243],
            [14.13721809115296, 45.475273126888467],
            [14.392500184824513, 45.486109083705898],
            [14.413473215370658, 45.491664106232051],
            [14.496109225541659, 45.543682197484074],
            [14.535000250556038, 45.612218170094124],
            [14.570345059964978, 45.666382196203259],
            [14.609791134609083, 45.668436097763006],
            [14.609582257581508, 45.63304502024836],
            [14.620836136092464, 45.612082215624113],
            [14.723609165784666, 45.531391142271374],
            [14.826091175797785, 45.465445010111793],
            [14.863718209809718, 45.468654105570394],
            [14.912836162555067, 45.482491119009168],
            [14.979063256109868, 45.500473151217776],
            [15.092027171349343, 45.471518034255539],
            [15.156673270322756, 45.429291179405951],
            [15.174454136854507, 45.425818053996451],
            [15.277218114091312, 45.462773194648079],
            [15.303973149102177, 45.538627071664806],
            [15.294000193038102, 45.594536042381961],
            [15.307091217092022, 45.629864255622451],
            [15.352591205140669, 45.651754098740597],
            [15.356627091530811, 45.712009086266207],
            [15.288645162722389, 45.700544989623111],
            [15.294027182766428, 45.734300087271336],
            [15.31583605669951, 45.756664178472406],
            [15.333891179104029, 45.766391209496604],
            [15.499445166669773, 45.835827063234021],
            [15.611800220460879, 45.846736110247306],
            [15.629718214929227, 45.833191122315938],
            [15.65263618229352, 45.823391168733892],
            [15.696109090872454, 45.843054107810147],
            [15.724445120464708, 45.938609145381733],
            [15.711491224346986, 46.05319110321453],
            [15.663609099409257, 46.06798214487533],
            [15.632918090294424, 46.08458216888458],
            [15.606391210688713, 46.115827054162835],
            [15.600418098831142, 46.144300044053395],
            [15.611945059748109, 46.166936044194003],
            [15.659163337952606, 46.220900078092782],
            [15.685973190610241, 46.225964256367476],
            [15.797782247227019, 46.233327087775407],
            [16.01721812604012, 46.298045103478373],
            [16.080409126618434, 46.33603607972718],
            [16.075836127871099, 46.358191126262412],
            [16.075318126253507, 46.378418167423874],
            [16.137918202655982, 46.405964118820449],
            [16.266109186160776, 46.41944506901136],
            [16.252409133020308, 46.495564149445372],
            [16.2675002468157, 46.514445056952965],
            [16.292509163751987, 46.530818099023648],
            [16.335554092353703, 46.533882187557126],
            [16.384163263574862, 46.535827124375302],
            [16.463891256250179, 46.52096416598495],
            [16.495482146492492, 46.50569116489028],
            [16.517791252408529, 46.491645106785754],
            [16.564454145706861, 46.477091105347242],
            [16.591991212286104, 46.474009079541119],
            [16.607873242467321, 46.476235983582896],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "HTI", Country: "Haiti" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.82477756199998, 21.854251372],
            [-66.825125561999897, 21.853485371999952],
            [-66.832131561999972, 21.838091372],
            [-66.839134562000027, 21.822697372000121],
            [-66.846137562000024, 21.807302371999967],
            [-66.853138561999913, 21.791907372000011],
            [-66.86013756199992, 21.776512372000141],
            [-66.867135562000044, 21.761117371999987],
            [-66.874131561999974, 21.745721372000034],
            [-66.881126561999906, 21.730324372],
            [-66.888119562000043, 21.714928372000045],
            [-66.895111562000011, 21.699531372000095],
            [-66.902102561999982, 21.684134372000145],
            [-66.909091561999958, 21.668736372000112],
            [-66.916078561999939, 21.653338372000078],
            [-66.923064562000036, 21.637940372000045],
            [-66.930048561999854, 21.622541372000015],
            [-66.937032561999956, 21.607142371999984],
            [-66.944013561999896, 21.591743372000039],
            [-66.950993561999837, 21.576344372000122],
            [-66.957972561999981, 21.560944372000094],
            [-66.963373562000015, 21.549065371999987],
            [-66.970370562000028, 21.533673372],
            [-66.977366561999872, 21.518281372000118],
            [-66.984361562, 21.502889371999956],
            [-66.985424561999935, 21.500601372000105],
            [-66.992553561999955, 21.485263372000148],
            [-66.999681561999978, 21.469925372000105],
            [-67.006807562, 21.454587372000148],
            [-67.013932561999866, 21.439248372000023],
            [-67.021055562000015, 21.423908371999985],
            [-67.028177562, 21.408569372000031],
            [-67.035297561999982, 21.393229372000107],
            [-67.042416561999971, 21.377888371999987],
            [-67.043927562000022, 21.374489372000042],
            [-67.050789561999977, 21.359047372],
            [-67.057649561999938, 21.343604372000058],
            [-67.064507561999989, 21.328161372000139],
            [-67.071365561999983, 21.312717372000023],
            [-67.078221561999953, 21.29727437199999],
            [-67.085075562000043, 21.281830372000158],
            [-67.091928561999964, 21.266386372000042],
            [-67.098780561999973, 21.250941372000128],
            [-67.105630562000016, 21.235496372000014],
            [-67.112479562999852, 21.2200513720001],
            [-67.119326562999987, 21.204605371999989],
            [-67.126172562999955, 21.189160372000075],
            [-67.133016563000012, 21.173714372000163],
            [-67.139859562999987, 21.158267372000054],
            [-67.14670156299988, 21.14282037200006],
            [-67.153541562999976, 21.127373372000065],
            [-67.160380562999876, 21.111926371999957],
            [-67.167217562999895, 21.096479371999962],
            [-67.174053562999916, 21.08103137199997],
            [-67.180888562999854, 21.06558237199998],
            [-67.187721563, 21.050134371999988],
            [-67.194553562999943, 21.034685372],
            [-67.201383563, 21.019236372],
            [-67.208212562999989, 21.003786372000022],
            [-67.21504056299986, 20.988337371000128],
            [-67.221866562999963, 20.972887371000141],
            [-67.228690563000043, 20.957436370999986],
            [-67.235514562999867, 20.941986371],
            [-67.242335562999983, 20.926535371000014],
            [-67.249156563000014, 20.911083371000146],
            [-67.255975562999964, 20.895632371000076],
            [-67.262793563, 20.880180371],
            [-67.26960956299996, 20.86472837100014],
            [-67.276424563000035, 20.84927537099999],
            [-67.283238563, 20.833823371],
            [-67.290050563, 20.818370371000142],
            [-67.296861563, 20.802916371],
            [-67.297472563000014, 20.800833371000053],
            [-67.302205562999916, 20.784708371000093],
            [-67.306937562999849, 20.768584371000017],
            [-67.311669562999867, 20.752459371000057],
            [-67.316399563, 20.7363333710001],
            [-67.321128562999945, 20.720208371000027],
            [-67.325856562999888, 20.704083370999982],
            [-67.330584562999945, 20.687957371000024],
            [-67.335310562999894, 20.671831371000152],
            [-67.340035562999958, 20.65570537100011],
            [-67.344759563000025, 20.639579370999954],
            [-67.349482563, 20.623453371],
            [-67.354205563, 20.607326371000042],
            [-67.358926562999898, 20.591200371],
            [-67.363646563, 20.575073371000045],
            [-67.368365562999912, 20.55894637100009],
            [-67.37308456300002, 20.542819371000135],
            [-67.377801562999849, 20.526692371000095],
            [-67.38251756299988, 20.510564371000143],
            [-67.387232562999912, 20.494437371000103],
            [-67.391947562999945, 20.478309371000066],
            [-67.396660562999898, 20.462181371000113],
            [-67.401372562999967, 20.446053371000076],
            [-67.406084563, 20.429925371000039],
            [-67.410794563, 20.413797371],
            [-67.415503562999959, 20.397668371000051],
            [-67.42021256299995, 20.381540371000014],
            [-67.424919562999946, 20.365411370999979],
            [-67.429626562999943, 20.349282371000143],
            [-67.434331562999944, 20.333153371000108],
            [-67.439036562999945, 20.317023371],
            [-67.443739562999951, 20.300894370999956],
            [-67.448442562999986, 20.284764371],
            [-67.453143563, 20.268634371000175],
            [-67.457844563000037, 20.252505371000055],
            [-67.462544562999881, 20.236374371000025],
            [-67.467242562999843, 20.220244371000106],
            [-67.47194056299989, 20.204114371000074],
            [-67.476637562999855, 20.187983370999959],
            [-67.481332563000024, 20.171853371000125],
            [-67.486027563, 20.15572237100001],
            [-67.486720562999949, 20.153267371000098],
            [-67.491283563000025, 20.137102371000012],
            [-67.495845562999932, 20.120937371000124],
            [-67.500406563000013, 20.104773371000121],
            [-67.504966563000011, 20.088608371000035],
            [-67.509525563000011, 20.072443371000148],
            [-67.514083563000014, 20.056277371000064],
            [-67.517416563000012, 20.044888371],
            [-67.519958563000017, 20.035536371],
            [-67.524361562999985, 20.019333371000059],
            [-67.526027562999985, 20.014333371000063],
            [-67.526994562999874, 20.010126371000055],
            [-67.530749562999972, 19.993777371000036],
            [-67.532630563, 19.982077370999988],
            [-67.535288562999938, 19.965537370999968],
            [-67.537946562999934, 19.94899837100003],
            [-67.540602562999965, 19.93245837100001],
            [-67.543259562999879, 19.91591837],
            [-67.545915563, 19.899379370000062],
            [-67.548570562999942, 19.882839370000042],
            [-67.551224562999977, 19.866299370000021],
            [-67.55387956300001, 19.84975937],
            [-67.556532562999962, 19.833219369999981],
            [-67.559185563, 19.81667937],
            [-67.561838563000038, 19.800139370000025],
            [-67.564490563, 19.78359837],
            [-67.567141562999865, 19.767058369999987],
            [-67.569792563000021, 19.750518370000052],
            [-67.572442562999981, 19.733977370000034],
            [-67.57509256299997, 19.717437370000013],
            [-67.577741563000018, 19.700896370000109],
            [-67.580390563, 19.684355370000091],
            [-67.583038562999889, 19.667815370000071],
            [-67.58568556299997, 19.651274370000053],
            [-67.588332562999966, 19.634733370000035],
            [-67.590979562999962, 19.618192370000017],
            [-67.593625562999847, 19.60165137],
            [-67.596270562999848, 19.585110369999981],
            [-67.598915562999849, 19.568569370000077],
            [-67.601559562999967, 19.552027370000062],
            [-67.60420356299997, 19.535486370000044],
            [-67.606846562999976, 19.518945370000026],
            [-67.609489562999983, 19.50240337000001],
            [-67.612131562999821, 19.485862370000106],
            [-67.614773563000028, 19.469320370000091],
            [-67.617414562999869, 19.452779369999988],
            [-67.620055563, 19.436237370000057],
            [-67.622695562999922, 19.419695370000156],
            [-67.625334562999967, 19.40315337000014],
            [-67.627973563000012, 19.386611369999954],
            [-67.630611562999974, 19.370069370000024],
            [-67.633249562999822, 19.353527370000123],
            [-67.633884562999867, 19.348703370000024],
            [-67.636067562999926, 19.332101370000132],
            [-67.638249562999988, 19.315500370000123],
            [-67.640831562999836, 19.307928370000027],
            [-67.646259562999887, 19.292011370000054],
            [-67.651685562999972, 19.276094370000052],
            [-67.657110562999947, 19.26017637],
            [-67.662534562999923, 19.244258370000111],
            [-67.667957563000016, 19.228340370000026],
            [-67.673379562999912, 19.212422370000141],
            [-67.67880056300001, 19.196504370000056],
            [-67.68422056299994, 19.18058537],
            [-67.68963856299996, 19.16466637000012],
            [-67.693245562999977, 19.153559370000025],
            [-67.698434562999978, 19.137571370000032],
            [-67.703623562999979, 19.121584370000122],
            [-67.70881156299987, 19.105596370000015],
            [-67.713998562999876, 19.089608370000022],
            [-67.719183563, 19.073620370000114],
            [-67.724368563000013, 19.057632370000121],
            [-67.729552562999942, 19.041644370000014],
            [-67.734734562999989, 19.025655370000024],
            [-67.739916562999838, 19.009666370000033],
            [-67.741601562999989, 19.00436837],
            [-67.746693562999951, 18.98835337000007],
            [-67.751784562999831, 18.972338370000145],
            [-67.756874563, 18.956323370000135],
            [-67.761963562999966, 18.940308370000125],
            [-67.767051562999967, 18.92429337],
            [-67.772138562999942, 18.90827737],
            [-67.776891562999936, 18.893724369999987],
            [-67.78210756299984, 18.877746370000111],
            [-67.787322563000032, 18.861768370000036],
            [-67.79253656299997, 18.845790368999971],
            [-67.797749563, 18.82981236900001],
            [-67.802962562999937, 18.813834369000134],
            [-67.808173562999968, 18.797855368999976],
            [-67.813383562999832, 18.781877369000014],
            [-67.818592562999982, 18.765898369000141],
            [-67.823801562999932, 18.749919368999983],
            [-67.829008563, 18.733940369000024],
            [-67.834214562999989, 18.717960369000068],
            [-67.839420562999862, 18.701981369000109],
            [-67.844624562999854, 18.686001369000039],
            [-67.849827562999963, 18.670021369],
            [-67.855030562999957, 18.654041368999955],
            [-67.860231562999957, 18.638061369000084],
            [-67.865432562999871, 18.622081369000128],
            [-67.870631562999989, 18.606100369000089],
            [-67.875830563000022, 18.590119369000135],
            [-67.881027562999861, 18.57413936899998],
            [-67.890197563000015, 18.567571369000021],
            [-67.904224562999929, 18.557522368999983],
            [-67.918249562999961, 18.547472369000147],
            [-67.922302563000017, 18.544120369],
            [-67.935555563000037, 18.533157369000108],
            [-67.948805562999979, 18.522194369000019],
            [-67.949608562999884, 18.521534369000037],
            [-67.962895562999961, 18.510613369000154],
            [-67.976181562999955, 18.499690369000106],
            [-67.989465562999953, 18.488766369000146],
            [-68.002747562999986, 18.477842369000101],
            [-68.016027563, 18.466916368999975],
            [-68.016651563000039, 18.466381369000018],
            [-68.029692563, 18.455197369000146],
            [-68.042732562999873, 18.444012369],
            [-68.05576956299987, 18.432826369000125],
            [-68.06880556299987, 18.421639368999976],
            [-68.069919563000042, 18.420593369000031],
            [-68.082416562999924, 18.408861369],
            [-68.09105456299983, 18.400370369000015],
            [-68.103264562999982, 18.388366368999968],
            [-68.115472563000026, 18.376361369],
            [-68.117970562999943, 18.373265368999981],
            [-68.128668562999849, 18.36001236900016],
            [-68.139363562999961, 18.346759368999969],
            [-68.15005756299999, 18.333505368999951],
            [-68.160749563000024, 18.32025036900005],
            [-68.169379562999978, 18.309545369],
            [-68.180065562999857, 18.296286369000015],
            [-68.190749564000043, 18.283027368999953],
            [-68.194193563999988, 18.275071369000088],
            [-68.20088556399989, 18.259608369],
            [-68.20757556399991, 18.244145369],
            [-68.214265563999845, 18.228682369000111],
            [-68.220953563999984, 18.213218369000018],
            [-68.227640564000012, 18.197754369000037],
            [-68.234326563999872, 18.182290369000057],
            [-68.241010564000021, 18.16682536900008],
            [-68.247694564, 18.151361368999986],
            [-68.252811563999984, 18.137942369000015],
            [-68.258805563999971, 18.122222369000085],
            [-68.258438563999988, 18.117519368999965],
            [-68.257138563999973, 18.100833369000028],
            [-68.257815563999941, 18.08928636900005],
            [-68.258793563999944, 18.072579368999968],
            [-68.259771563999863, 18.055873369000082],
            [-68.260749563999866, 18.039166369],
            [-68.261009563999977, 18.03353336900004],
            [-68.261777563999942, 18.016816369000125],
            [-68.262546563999962, 18.000100369],
            [-68.263314564000012, 17.983383369],
            [-68.264083563999947, 17.966666369000095],
            [-68.264558564, 17.95509636900006],
            [-68.265244564, 17.938376369000068],
            [-68.265931564, 17.921656369000075],
            [-68.266617564, 17.904936369000083],
            [-68.267303563999917, 17.888216369000091],
            [-68.267989563999834, 17.871496369000099],
            [-68.268675564000034, 17.854775369000023],
            [-68.269361563999951, 17.838055369000031],
            [-68.269634563999972, 17.832262368999963],
            [-68.270422563999972, 17.815546369000046],
            [-68.271210563999887, 17.798830369000129],
            [-68.271998564, 17.782113369000015],
            [-68.272786564, 17.765397368],
            [-68.273573564, 17.748681368000078],
            [-68.274361563999832, 17.731965367999962],
            [-68.275148563999949, 17.715248368000047],
            [-68.275935563999951, 17.69853236800013],
            [-68.276722563999868, 17.681816368000014],
            [-68.277509563999985, 17.665099368000099],
            [-68.278296563999987, 17.648383367999983],
            [-68.279083564000018, 17.631666368000069],
            [-68.279630563999973, 17.623593368000016],
            [-68.280763564000011, 17.606894367999971],
            [-68.281895563999967, 17.590196368000036],
            [-68.28302656399984, 17.573498367999989],
            [-68.284158564, 17.556799368000057],
            [-68.285290564000036, 17.540101368],
            [-68.286421564, 17.523402368000077],
            [-68.287552563999867, 17.50670436800003],
            [-68.288683563999939, 17.490006368000095],
            [-68.289813563999985, 17.47330736800005],
            [-68.290944563999858, 17.456608368000119],
            [-68.292074564000018, 17.439910367999985],
            [-68.293204563999979, 17.423211368000139],
            [-68.29433356399997, 17.406513368],
            [-68.295463564000016, 17.389814368000074],
            [-68.296593564, 17.373115368000029],
            [-68.297722563999969, 17.356416368000097],
            [-68.298691563999938, 17.345294368000012],
            [-68.300143564, 17.328618368000107],
            [-68.301595563999967, 17.311942368000118],
            [-68.303047563999939, 17.295266368000128],
            [-68.304498564, 17.278590368000025],
            [-68.305950563999971, 17.261915368000032],
            [-68.307401564000031, 17.245239368000043],
            [-68.308852564, 17.228563368000053],
            [-68.310302563999983, 17.211887368000149],
            [-68.311753563999957, 17.195211368000159],
            [-68.313203563999849, 17.178534368000086],
            [-68.314652564000028, 17.161858367999983],
            [-68.316102564, 17.145182368],
            [-68.317551563999984, 17.128506368],
            [-68.319000563999879, 17.111830368000014],
            [-68.320449563999972, 17.095153368000027],
            [-68.321897564000039, 17.078477368000037],
            [-68.323346563999934, 17.061801368000133],
            [-68.324794563999944, 17.045124368000145],
            [-68.326241564000014, 17.028448368000156],
            [-68.327689564000025, 17.011771368000083],
            [-68.329136564, 16.99509536799998],
            [-68.330583563999824, 16.978418368],
            [-68.332030564, 16.961742368],
            [-68.333476564000023, 16.945065368000016],
            [-68.334922563999925, 16.928389368000026],
            [-68.33636856399994, 16.911712368000039],
            [-68.337814564000041, 16.895035367999967],
            [-68.339259563999946, 16.878358367999979],
            [-68.340704563999964, 16.86168236799999],
            [-68.342149563999982, 16.845005368],
            [-68.343594563999886, 16.828328368000015],
            [-68.345038563999907, 16.811651368000028],
            [-68.34648356400001, 16.794974368000041],
            [-68.347926564000034, 16.778297368000139],
            [-68.349370563999969, 16.761620368000067],
            [-68.350813564, 16.744943368000165],
            [-68.352257564000013, 16.728266368000092],
            [-68.353700563999951, 16.711589368000105],
            [-68.355142563999976, 16.694911367000131],
            [-68.356585564, 16.678234367000144],
            [-68.35802756399994, 16.661557367000157],
            [-68.359469563999966, 16.644880366999971],
            [-68.360910564, 16.6282023670001],
            [-68.362352563999934, 16.611525367000112],
            [-68.363793563999877, 16.594848367000125],
            [-68.36523456399982, 16.578170367000141],
            [-68.366674563999965, 16.561493367000068],
            [-68.368115564, 16.544815367000169],
            [-68.369555563999853, 16.528138367000096],
            [-68.370995564, 16.511460367000112],
            [-68.372434563999946, 16.494782367000127],
            [-68.373874563999891, 16.47810536700014],
            [-68.375313563999953, 16.46142736700007],
            [-68.376752563999901, 16.444749367],
            [-68.378191563999962, 16.428072367000013],
            [-68.379629563999913, 16.411394367000028],
            [-68.381067563999977, 16.394716367000044],
            [-68.382505563999928, 16.378038366999974],
            [-68.383943564, 16.361360366999989],
            [-68.385380563999973, 16.344682367000118],
            [-68.386818564, 16.328004367000133],
            [-68.388254564, 16.311326367000149],
            [-68.389691563999975, 16.294648367000079],
            [-68.391128563999928, 16.277970367000094],
            [-68.392564564, 16.261292367000024],
            [-68.394000563999981, 16.24461436700004],
            [-68.395436563999965, 16.22793636699997],
            [-68.396871564000037, 16.211257366999988],
            [-68.398307563999907, 16.194579367000117],
            [-68.399741563999896, 16.177901367000132],
            [-68.401176563999968, 16.16122236700015],
            [-68.40261156400004, 16.14454436700008],
            [-68.404045563999944, 16.12786636700001],
            [-68.405479564000018, 16.111187367000028],
            [-68.406913564, 16.094509367000043],
            [-68.408347564, 16.077830367000089],
            [-68.409780563999902, 16.061152367000105],
            [-68.411213563999894, 16.044473367000123],
            [-68.412646564, 16.027794367000055],
            [-68.414079564, 16.011116366999985],
            [-68.415511563999985, 15.994437367],
            [-68.416943563999979, 15.977758367000135],
            [-68.418375563999888, 15.961080367000065],
            [-68.419807564, 15.944401367000083],
            [-68.421238564, 15.927722367000015],
            [-68.422669563999818, 15.911043367000033],
            [-68.424100564000014, 15.89436436700008],
            [-68.425531564000011, 15.877685367000097],
            [-68.426962563999837, 15.86100636700003],
            [-68.428392564000035, 15.844327367000048],
            [-68.429822563999863, 15.827648366999981],
            [-68.431252563999976, 15.810969367000112],
            [-68.432681564, 15.79429036700013],
            [-68.434111564, 15.777611367000063],
            [-68.435363563999942, 15.762834367000082],
            [-68.436776563999985, 15.746153367000019],
            [-68.438190564, 15.729473367000068],
            [-68.439603564000038, 15.712792367],
            [-68.441016563999966, 15.696112367000026],
            [-68.442428564000011, 15.679431367000163],
            [-68.443841564000024, 15.662750367000015],
            [-68.445253563999984, 15.646070367000149],
            [-68.446665564, 15.629389366000098],
            [-68.448076563999962, 15.612708366000035],
            [-68.449488563999893, 15.59602836599997],
            [-68.45089956399994, 15.579347366000107],
            [-68.452310563999987, 15.562666365999959],
            [-68.453721564000034, 15.545985366000096],
            [-68.455131563999885, 15.529304366000034],
            [-68.45654156400002, 15.51262336600017],
            [-68.457951563999984, 15.495942366000023],
            [-68.459361563999948, 15.479261366000159],
            [-68.460771564, 15.462580366000012],
            [-68.462180563999851, 15.445899366000148],
            [-68.463589563999903, 15.429218366000086],
            [-68.46499856399987, 15.412537366000024],
            [-68.466407563999923, 15.395856366000075],
            [-68.467816563999975, 15.379175366000013],
            [-68.469224563999944, 15.362493366000066],
            [-68.470632563999914, 15.345812366],
            [-68.472040563999883, 15.329131366000141],
            [-68.473447563999855, 15.312449366],
            [-68.474855563999824, 15.295768366000132],
            [-68.476262564, 15.279087365999985],
            [-68.477669563999967, 15.262405366000124],
            [-68.479076564000025, 15.245724365999976],
            [-68.480482563999914, 15.229042366000115],
            [-68.481888564, 15.212361365999968],
            [-68.862222564, 15.241111366000027],
            [-69.341032887999916, 15.356442375000071],
            [-69.577222564999943, 15.413333366000174],
            [-69.697221564999836, 15.379167365999976],
            [-69.938332565, 15.317778366000127],
            [-70.135832564999987, 15.263889366000072],
            [-70.880555565999828, 15.03555636600008],
            [-71.405277893199894, 14.964444014400144],
            [-71.405277999999896, 15.062826779000105],
            [-71.577020962, 15.029897240000068],
            [-71.675, 15.011111],
            [-73.458332999999897, 15.03333],
            [-74.513888999999836, 14.736111000000122],
            [-74.670460453, 17.020387689000145],
            [-74.865955409, 17.346198521000034],
            [-74.942178444999854, 17.414794680000071],
            [-75.009984384999967, 17.475853965000098],
            [-75.183414373999966, 17.631093102000065],
            [-75.330771329999948, 17.762012107999951],
            [-75.398797008000031, 17.822165561000062],
            [-75.402107765999858, 17.82509853],
            [-75.483817582000029, 17.897197873],
            [-75.489924792999972, 17.91041674100002],
            [-75.49050091600003, 17.91166798899998],
            [-75.491467753999899, 17.913771224000115],
            [-75.492427824999936, 17.91587443],
            [-75.492975411999851, 17.917075984000078],
            [-75.556979434, 18.057862614],
            [-75.584141866999971, 18.115570716000022],
            [-75.684917191999943, 18.327471172000017],
            [-75.699147958999845, 18.370957114000149],
            [-75.707659725999974, 18.398407829],
            [-75.711695757999962, 18.414645793000076],
            [-75.712160499999953, 18.416494212000075],
            [-75.719675444999837, 18.446336701000021],
            [-75.720021962, 18.447721630000032],
            [-75.671547802, 18.633674097999986],
            [-75.602808935000041, 18.748027962],
            [-75.506388888999822, 18.832222222],
            [-75.460755569999947, 18.872098370000046],
            [-75.411085569999898, 18.904558370000146],
            [-75.292083569999903, 18.983016370000072],
            [-75.27997256999987, 18.991015370000056],
            [-75.222424570000044, 19.027105370000086],
            [-75.168613569999962, 19.060063370000066],
            [-75.158019569999851, 19.0655093700001],
            [-75.029154569999832, 19.131503370000146],
            [-75.021379570000022, 19.135197370000057],
            [-74.928377569999896, 19.177342370000176],
            [-74.905221569999952, 19.186984370000104],
            [-74.900586569999859, 19.188876370000244],
            [-74.826978569999937, 19.220080370000133],
            [-74.788478569999882, 19.237132370000054],
            [-74.737630569999908, 19.258584370000065],
            [-74.680320569999964, 19.28250737000026],
            [-74.555099568999907, 19.334276370000126],
            [-74.520061568999949, 19.338487370000038],
            [-74.505022568999948, 19.34088937],
            [-74.450086568999865, 19.350539369999964],
            [-74.442102568999985, 19.351914370000046],
            [-74.391087568999978, 19.362205369999955],
            [-74.379711568999824, 19.363702370000183],
            [-74.325135568999855, 19.365116370000109],
            [-74.322676568999981, 19.365241369999964],
            [-74.303930569000045, 19.366267370000131],
            [-74.286978568999956, 19.36708537000014],
            [-74.206994568999846, 19.370514370000066],
            [-74.178878568999949, 19.372037370000058],
            [-74.126883568999915, 19.374608370000161],
            [-74.088592568999843, 19.44869737000009],
            [-74.087443568999902, 19.450907370000081],
            [-74.027304568999909, 19.56577637000008],
            [-74.017168568999864, 19.584937370000077],
            [-74.009906568999867, 19.59859837000009],
            [-73.998975568999953, 19.618800370000045],
            [-73.975684569, 19.662533370000105],
            [-73.954416569000045, 19.702219369999952],
            [-73.950559568999836, 19.709728370000065],
            [-73.947496568999895, 19.715537370000206],
            [-73.943883568999922, 19.722596370000204],
            [-73.941979568999955, 19.726351370000017],
            [-73.938190568999971, 19.733911370000087],
            [-73.897214568999857, 19.818246370000125],
            [-73.890744568999935, 19.832297370000063],
            [-73.885108568999897, 19.844165370000056],
            [-73.836401568999889, 19.915613370000173],
            [-73.833182569, 19.920294370000036],
            [-73.819002568999935, 19.941179371000132],
            [-73.796226568999884, 19.982691371000087],
            [-73.790429568999855, 19.993191371000037],
            [-73.789844568999854, 19.994244371000136],
            [-73.778592568999841, 20.014011371000166],
            [-73.774704568999852, 20.02079637100023],
            [-73.734061568999863, 20.090295371000181],
            [-73.732179568999925, 20.093478371000103],
            [-73.731074568999901, 20.095328371000235],
            [-73.712654568999938, 20.127908371000117],
            [-73.666825569000025, 20.207686371000037],
            [-73.634654568999963, 20.26287637099999],
            [-73.622757568999873, 20.282808371000087],
            [-73.572218658, 20.368263439000074],
            [-73.524972041000012, 20.377554765000028],
            [-73.49427158099985, 20.379884563000061],
            [-73.489859882999866, 20.381117964999973],
            [-73.449980824000022, 20.391911494999974],
            [-73.396014834, 20.407219688000126],
            [-73.39507516999987, 20.407418513999957],
            [-73.335288171999963, 20.43400777100014],
            [-73.275190026999979, 20.4592977],
            [-73.203571373999978, 20.474200676000109],
            [-73.178495838, 20.479439439000132],
            [-73.119312102, 20.491730430000032],
            [-73.083514474, 20.499464887999963],
            [-72.99953117099983, 20.520416467000146],
            [-72.987014944999942, 20.524145042],
            [-72.928629569, 20.541588458999982],
            [-72.921560360999962, 20.543766532999982],
            [-72.877618744999978, 20.558096607000024],
            [-72.858945389000013, 20.564240581999982],
            [-72.811508467999829, 20.581931275],
            [-72.747395818999962, 20.606411317],
            [-72.632381680000037, 20.65047737899998],
            [-72.563927723999853, 20.676843055000134],
            [-72.487950786999932, 20.699519893000044],
            [-72.482014428999832, 20.701246848000025],
            [-72.472008592, 20.705007171000133],
            [-72.464385309999955, 20.707197933000018],
            [-72.460249819999888, 20.708392189999969],
            [-72.451548041999956, 20.710961684000139],
            [-72.439003354000022, 20.714709268999954],
            [-72.359135566999953, 20.687086371000063],
            [-72.325365566999835, 20.664265371000127],
            [-72.268004566999934, 20.626022371000019],
            [-72.241180566999873, 20.608186370999974],
            [-72.221686566999864, 20.595638371000021],
            [-72.147221567, 20.545278371],
            [-71.462221567, 20.557500370999989],
            [-70.326388564999974, 20.723333370999953],
            [-69.483332564999984, 21.191667372000026],
            [-67.667777562999959, 22.413056373000117],
            [-67.402957562999916, 22.283741373000069],
            [-67.402055562999976, 22.283309373000023],
            [-66.89537256199992, 21.942357372000131],
            [-66.82477756199998, 21.854251372],
          ],
          [
            [-70.783618244398241, 19.84666375987284],
            [-70.801118196966542, 19.864718234341879],
            [-70.830145800545665, 19.89708175283856],
            [-70.841945738388233, 19.903609246849115],
            [-70.99445477037807, 19.930691211966874],
            [-71.01139969813147, 19.93082718920185],
            [-71.029172775721918, 19.928336221705493],
            [-71.045290724190522, 19.923336235257423],
            [-71.063609213634891, 19.913891203357096],
            [-71.085836195267262, 19.894791031947435],
            [-71.094799750364814, 19.874091224058517],
            [-71.150281748648894, 19.849445233794583],
            [-71.232036197988123, 19.829563799514901],
            [-71.248899724968112, 19.82471825893812],
            [-71.260836223864771, 19.823054187407692],
            [-71.355836217835531, 19.848327244670216],
            [-71.366672782977531, 19.85250025183349],
            [-71.401945813136308, 19.86944517958689],
            [-71.431118250125849, 19.884163742052678],
            [-71.447509239797597, 19.89055417209498],
            [-71.484518216032598, 19.904027207695933],
            [-71.529445803338774, 19.908336192094097],
            [-71.583345801417977, 19.908054208482284],
            [-71.595290742723307, 19.906391223684935],
            [-71.665836216307582, 19.893745167368778],
            [-71.772299758600013, 19.784163761633707],
            [-71.775981759818592, 19.770554162064613],
            [-71.76221821437818, 19.76944519916789],
            [-71.744736230446335, 19.760272792289769],
            [-71.730836207770864, 19.734445210212314],
            [-71.719654813613374, 19.701318231301016],
            [-71.754181831347751, 19.705827159072513],
            [-71.748064012376773, 19.699164111117895],
            [-71.736535877993248, 19.686109129247697],
            [-71.733063926050136, 19.67915399361128],
            [-71.735290997730004, 19.654718063577647],
            [-71.73779098417819, 19.627218045010508],
            [-71.734999978051093, 19.610273189503673],
            [-71.726390925275524, 19.585553951141847],
            [-71.722226795766318, 19.576109054979256],
            [-71.717790924955466, 19.566664158816749],
            [-71.707781926707554, 19.549445047437345],
            [-71.704454981689878, 19.539445101644944],
            [-71.691955049449376, 19.498336057406092],
            [-71.691681967043195, 19.485835957527527],
            [-71.697646026445341, 19.402909095315394],
            [-71.723617856421242, 19.360000121183688],
            [-71.744735893892596, 19.285826983396433],
            [-71.71556402091349, 19.257773088665871],
            [-71.696117837854132, 19.241664244184065],
            [-71.687790919940255, 19.23555400439021],
            [-71.67222689916639, 19.228882177081985],
            [-71.66028184891772, 19.225826973366011],
            [-71.629181970564787, 19.219718074676607],
            [-71.629308872579173, 19.203745017026634],
            [-71.634590980337123, 19.161391092524738],
            [-71.639872920456895, 19.1369451042073],
            [-71.654446032634524, 19.119445031432477],
            [-71.749999896739638, 19.019718153000824],
            [-71.77750880012411, 18.955973108621194],
            [-71.716746036902578, 18.878264148788489],
            [-71.708617937732697, 18.790273108301662],
            [-71.709163934906996, 18.780282214964743],
            [-71.715835762215221, 18.749718107864325],
            [-71.725146045012394, 18.718744963887801],
            [-71.732226909196726, 18.707500137832241],
            [-71.736681890746951, 18.705273066152287],
            [-71.75611784788444, 18.70416414035914],
            [-71.766108908859337, 18.700836021875048],
            [-71.77528189608222, 18.696109131385001],
            [-71.78973581759638, 18.674445096739149],
            [-71.823626869714587, 18.628891129233963],
            [-71.831681878688642, 18.622500095682867],
            [-71.853345913334493, 18.616664111761779],
            [-71.901682002149357, 18.608609102787639],
            [-71.912226771649699, 18.612918071584076],
            [-71.924317834652157, 18.62729119155135],
            [-71.970563979723465, 18.624164238744001],
            [-71.979735793479762, 18.61944505960507],
            [-72.003063971186748, 18.600827008581703],
            [-71.996946020041861, 18.592500090667741],
            [-71.949173027483795, 18.547218032102379],
            [-71.89722685296104, 18.492773212236202],
            [-71.886535903069102, 18.477144986083701],
            [-71.906955057451825, 18.455973136793702],
            [-71.908817851618636, 18.441454004072611],
            [-71.899172963245888, 18.431391194006252],
            [-71.810564008855181, 18.375827054786853],
            [-71.778899860778949, 18.356664180055574],
            [-71.743054987025118, 18.340553994469289],
            [-71.716672946706638, 18.330273087281668],
            [-71.694735829654689, 18.322218078307614],
            [-71.715517920445961, 18.292527030244358],
            [-71.721390952379267, 18.284445031541892],
            [-71.769245919950606, 18.200482166094375],
            [-71.765835826453241, 18.180554023601033],
            [-71.753890943842578, 18.145554045689764],
            [-71.749172938170034, 18.092082197146723],
            [-71.767863796834206, 18.038499818171871],
            [-71.751954813239877, 18.032499738905003],
            [-71.73250922171016, 18.022499766008778],
            [-71.690845761509024, 17.992218196070368],
            [-71.675418228357074, 17.979027227900261],
            [-71.66307279484144, 17.963609223890316],
            [-71.65889076145038, 17.953054226179162],
            [-71.625563786433986, 17.836390967799602],
            [-71.423890727837801, 17.604163801933211],
            [-71.408336159859545, 17.609163788381281],
            [-71.396663759257308, 17.618327169031645],
            [-71.266954786170402, 17.846663731898886],
            [-71.248899724968112, 17.879718249531251],
            [-71.222499736172523, 17.894445167672401],
            [-71.207227235625083, 17.907772783629653],
            [-71.19361822278924, 17.922499951770831],
            [-71.184445815911033, 17.934718184279305],
            [-71.09000920243065, 18.077772741960004],
            [-71.055627170426789, 18.149027186230612],
            [-71.066390774289744, 18.167218224667792],
            [-71.074445778776891, 18.179718190788023],
            [-71.078899766637846, 18.189163725602597],
            [-71.099727191715033, 18.235836198479475],
            [-71.103054750957057, 18.245827228966945],
            [-71.099727191715033, 18.263336207762919],
            [-71.081254756399176, 18.299163762736711],
            [-71.068481749075858, 18.307218180490551],
            [-71.053345812496673, 18.309163735632879],
            [-71.0388907650063, 18.307781731533183],
            [-71.026118257682953, 18.305272795900422],
            [-70.988345737214274, 18.294027212320415],
            [-70.935836182729389, 18.253890759681738],
            [-70.866954780675542, 18.312218250757624],
            [-70.750427246616738, 18.346936172282469],
            [-70.738618198727323, 18.352499709773255],
            [-70.730009256158638, 18.358054218122092],
            [-70.722781733870505, 18.365000009712517],
            [-70.712781760974366, 18.376391012936111],
            [-70.708345741749895, 18.386390985832335],
            [-70.707781774526211, 18.396381766319777],
            [-70.712090758924489, 18.411245161695959],
            [-70.691945809669448, 18.432363767164887],
            [-70.664463769022348, 18.434999737623869],
            [-70.652236173552865, 18.431945219584904],
            [-70.622509212248502, 18.423609234400217],
            [-70.611118209024823, 18.419718210848757],
            [-70.590699718566754, 18.406109198012913],
            [-70.568618240396972, 18.365000009712517],
            [-70.572645744097656, 18.312081773522678],
            [-70.587027243575875, 18.274627171024534],
            [-70.554027215671709, 18.252081768507551],
            [-70.546954809807403, 18.23319120179282],
            [-70.556399757888641, 18.225827199355578],
            [-70.545836233949757, 18.206109237174942],
            [-70.50750918866612, 18.194718233951434],
            [-70.486681763589104, 18.197218227175412],
            [-70.475281734137724, 18.199718220399419],
            [-70.459727249978471, 18.204436223235732],
            [-70.41166374677411, 18.225827199355578],
            [-70.400009228575072, 18.234999776785983],
            [-70.383754802871607, 18.236390723294349],
            [-70.319454755366849, 18.230554228419564],
            [-70.229445805435404, 18.223609189743328],
            [-70.184172718561427, 18.227772754497991],
            [-70.172018252008996, 18.232372748735671],
            [-70.164736237078472, 18.229999706518825],
            [-70.154172716053807, 18.233054221643656],
            [-70.072509193715717, 18.322218223653849],
            [-70.061109248083426, 18.336109220101534],
            [-70.010681728890006, 18.416890770512325],
            [-70.001954777722688, 18.420690780329451],
            [-69.967218217009162, 18.423063738727294],
            [-69.959454809094922, 18.429163745663246],
            [-69.942509210789211, 18.439999724072024],
            [-69.912509208281676, 18.459163748170837],
            [-69.903336214670247, 18.463890777234852],
            [-69.884727215386306, 18.469027240917868],
            [-69.776036221072985, 18.460481733400243],
            [-69.746399689683756, 18.460281737295148],
            [-69.694036225394683, 18.456245207366791],
            [-69.547227208130693, 18.416945176421606],
            [-69.516681709950177, 18.40277278309533],
            [-69.46860926142287, 18.416109254728056],
            [-69.384445748774965, 18.426109227624281],
            [-69.194863721180013, 18.427499757951708],
            [-69.16167272339851, 18.409299693286741],
            [-69.087881761897791, 18.395645214036094],
            [-69.086118234224131, 18.395272719604165],
            [-69.063472791745056, 18.390972761433673],
            [-68.973072792012005, 18.404718251504434],
            [-68.914727245566382, 18.397499755444173],
            [-68.908890753605732, 18.39832723472891],
            [-68.895281740769889, 18.396381766319777],
            [-68.872790744162188, 18.389163770259486],
            [-68.863345796080949, 18.384163783811303],
            [-68.84722717706012, 18.371390776488099],
            [-68.839736223615631, 18.364436208669858],
            [-68.832509201327639, 18.35749969622124],
            [-68.820554733794523, 18.341663731364477],
            [-68.781681773742804, 18.277772786616893],
            [-68.771390707188061, 18.260827188311154],
            [-68.761672717903764, 18.236945161376113],
            [-68.755563768558972, 18.219718250010871],
            [-68.728618197357207, 18.207218200071551],
            [-68.698063756767908, 18.203327176520119],
            [-68.646045794960884, 18.205627173639002],
            [-68.636954788856059, 18.21639102750207],
            [-68.631390751365331, 18.25694519098748],
            [-68.607090763583443, 18.365272717096545],
            [-68.590763792781871, 18.377563744702925],
            [-68.569454804168771, 18.368054191018231],
            [-68.550845804884858, 18.357218212609538],
            [-68.531399713355114, 18.346945198510227],
            [-68.51889072100721, 18.344163724588569],
            [-68.476527229614305, 18.344581769254361],
            [-68.462781739543431, 18.348054161406779],
            [-68.451390736319837, 18.353890990100652],
            [-68.33555479104416, 18.56916378530498],
            [-68.322927203364401, 18.599027223844374],
            [-68.325563760556605, 18.616663740380901],
            [-68.343199690359796, 18.640691020778618],
            [-68.357790715085059, 18.654718244461307],
            [-68.435527189865695, 18.705154206063483],
            [-68.539736198539686, 18.778609194224046],
            [-68.567227181595626, 18.801391284490435],
            [-68.590563796676776, 18.821109246671071],
            [-68.656399709833465, 18.883054220595326],
            [-68.691536176024158, 18.923745198048877],
            [-68.72709077361381, 18.951245207332434],
            [-68.738890711456463, 18.957772788076184],
            [-68.773345788558117, 18.968891250096661],
            [-68.893618253058406, 19.012218198009279],
            [-68.907227182075246, 19.020554183193909],
            [-68.923899739177813, 19.029863740773479],
            [-68.963899714581572, 19.03082719729322],
            [-68.989454755456023, 19.018891282215648],
            [-69.178781709984207, 19.01212718427486],
            [-69.192227247806812, 19.01194515680622],
            [-69.253890740533421, 19.020827224397053],
            [-69.355836189861577, 19.052218257232042],
            [-69.366390771391679, 19.056663805598461],
            [-69.376954795330505, 19.065972776444781],
            [-69.378890737511909, 19.083609206248113],
            [-69.383054802266514, 19.093054154329437],
            [-69.391109220020439, 19.099163774226284],
            [-69.402236208268562, 19.103054211044579],
            [-69.415281756795054, 19.10582724547173],
            [-69.429736217552176, 19.107218191980124],
            [-69.442781766078582, 19.10694523459604],
            [-69.468899771262414, 19.106109229083572],
            [-69.515563720825782, 19.09805422459651],
            [-69.531954794316562, 19.094163787778214],
            [-69.578063802884088, 19.088891260127028],
            [-69.603899740637019, 19.088054167881282],
            [-69.620145810665122, 19.08847279928024],
            [-69.631672791123606, 19.101663767450376],
            [-69.636809254806707, 19.113190997908887],
            [-69.627090765522354, 19.212499976277982],
            [-69.615772723577635, 19.224372793037602],
            [-69.588345759391984, 19.22416377070472],
            [-69.370009253740108, 19.196663761421192],
            [-69.243054762124729, 19.180272771749529],
            [-69.230009213598322, 19.180272771749529],
            [-69.218336226762887, 19.186109263710094],
            [-69.165554804541301, 19.267499994845124],
            [-69.158399740617995, 19.280418255631119],
            [-69.157927203309697, 19.295000004128568],
            [-69.21973619949901, 19.360554183673614],
            [-69.235699702096241, 19.365272773243092],
            [-69.262218199990457, 19.35500025914375],
            [-69.307236214297802, 19.325554194717924],
            [-69.319172716108511, 19.316391230248442],
            [-69.445554800505732, 19.333327215593144],
            [-69.522518201910742, 19.335554167614063],
            [-69.537227235234553, 19.333327215593144],
            [-69.675563790078911, 19.300827219861546],
            [-69.702518216956264, 19.293609223801226],
            [-69.718609254379743, 19.288891220964913],
            [-69.728899737115455, 19.285827176698163],
            [-69.741390760826988, 19.286391227740822],
            [-69.755981785552251, 19.290836189374076],
            [-69.817781755513892, 19.346663773959179],
            [-69.824445813492588, 19.354445234328907],
            [-69.839172731633738, 19.374445180121299],
            [-69.864727185774854, 19.417500007383026],
            [-69.877090758479227, 19.442500023442577],
            [-69.892227195058467, 19.525272758677218],
            [-69.881390716649634, 19.600272723036667],
            [-69.882236164570998, 19.612218248161028],
            [-69.88848176170282, 19.626245221843604],
            [-69.898618211833906, 19.637218180401462],
            [-69.936399761444562, 19.671109206460542],
            [-69.949318188411382, 19.676800194958489],
            [-69.966945762539325, 19.678891253563521],
            [-70.03237274107704, 19.682500209684221],
            [-70.053336227208206, 19.67569123206188],
            [-70.067227223655806, 19.662500013891744],
            [-70.07291821215378, 19.655827179685488],
            [-70.086118206551561, 19.633891207892603],
            [-70.098209238052817, 19.624581737046313],
            [-70.124999692831068, 19.620827190729713],
            [-70.146954803812491, 19.622772745872126],
            [-70.185836203358804, 19.62916376264765],
            [-70.275281772799786, 19.645554165586091],
            [-70.290845783186768, 19.649163792259017],
            [-70.303345749306999, 19.655000200400607],
            [-70.320845785694303, 19.667218182909082],
            [-70.353618236395789, 19.692218198968547],
            [-70.38807272967847, 19.734718251415373],
            [-70.399445764265721, 19.744718224311598],
            [-70.454454809060479, 19.782500273922111],
            [-70.471254733351287, 19.782918234768843],
            [-70.488890746973482, 19.776391240758429],
            [-70.518336224666228, 19.757081713196797],
            [-70.55763617179241, 19.753054209496227],
            [-70.632509185144642, 19.759718183655835],
            [-70.709454788465507, 19.802218236102661],
            [-70.783618244398241, 19.84666375987284],
          ],
          [
            [-68.574445764389168, 18.129445201285066],
            [-68.587227211206994, 18.153890779262738],
            [-68.597781708918092, 18.161390758934985],
            [-68.609999691426481, 18.164718231443729],
            [-68.779999733575835, 18.197709233120108],
            [-68.784999720023905, 18.179718190788023],
            [-68.781536183546905, 18.169854195126774],
            [-68.755563768558972, 18.129854219723015],
            [-68.731945756599231, 18.119999753203686],
            [-68.701672712888438, 18.111945164897591],
            [-68.691263721554236, 18.111109243204211],
            [-68.662227175566443, 18.119718185772896],
            [-68.652236228897976, 18.123609209324329],
            [-68.640290703773616, 18.124999739651756],
            [-68.62056379918431, 18.121663740915224],
            [-68.590009188042842, 18.113609236428189],
            [-68.573336214759308, 18.101663795122917],
            [-68.568554779786012, 18.108054225165134],
            [-68.574445764389168, 18.129445201285066],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "IDN", Country: "Indonesia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [103.51296550900031, 1.151612445000239],
            [103.529175229000145, 1.13540272400013],
            [103.55927899500017, 1.163190816000167],
            [103.568541692000167, 1.172453513000164],
            [103.582435738000243, 1.186347559000296],
            [103.593231731000287, 1.199302751000346],
            [103.594014156000128, 1.200241634500173],
            [103.612539504000097, 1.211819976000129],
            [103.62874922400033, 1.207188628000267],
            [103.651905967000232, 1.195610256000066],
            [103.670555556000295, 1.179444444000126],
            [103.740638889000365, 1.130361111000127],
            [103.805055556000184, 1.171444444000088],
            [103.859861111000214, 1.195972222000194],
            [103.880583333000232, 1.207250000000215],
            [104.033388889000207, 1.2695],
            [104.06178031800016, 1.272027508000122],
            [104.084937061000119, 1.269711835000294],
            [104.117356501000188, 1.265080485000226],
            [104.152091615000245, 1.25813346200016],
            [104.184511056000247, 1.255817788000229],
            [104.219246170000247, 1.25813346200016],
            [104.251665610000373, 1.255817788000229],
            [104.284085050000272, 1.25813346200016],
            [104.310938908000139, 1.269322570000327],
            [104.311873142000138, 1.269711834000191],
            [104.316007657000227, 1.271641274000331],
            [104.346608256000195, 1.285921554000254],
            [104.381343371000298, 1.309078297000156],
            [104.40388569500027, 1.327112156000226],
            [104.404500115000303, 1.327603691000149],
            [104.427656857000159, 1.339182063000351],
            [104.457760623000354, 1.360023131000219],
            [104.491666667000345, 1.398333333000153],
            [104.523854565000249, 1.417646072000139],
            [104.883333333000166, 1.63333333300011],
            [105.086666633000164, 1.906666905000179],
            [105.020000000000209, 2.375000000000114],
            [104.9327992460004, 2.668965429000082],
            [104.877109991000225, 2.856701371000099],
            [104.858333333000246, 2.92000000000013],
            [104.846860460000357, 3.045972147000214],
            [104.775, 3.835],
            [104.86500000000018, 4.050000000000125],
            [105.480000000000189, 5.078333333000103],
            [105.785000000000196, 5.676666667000234],
            [105.820000000000277, 6.096666667000136],
            [106.020218130000103, 6.17219412500009],
            [106.179303829000219, 6.247045090000086],
            [106.56928512500042, 6.443327185000101],
            [106.582602138000311, 6.448331255000085],
            [106.594642599000281, 6.45285550900013],
            [106.629119080000123, 6.465786095000112],
            [106.723567737000394, 6.501209199000243],
            [107.006342185000278, 6.601782084000092],
            [107.198716144000286, 6.670225579000203],
            [107.362560600000393, 6.728518509000196],
            [107.670270600000038, 6.833649509000281],
            [107.745054869000228, 6.857707602000232],
            [107.815054223000402, 6.880226304000189],
            [108.61519701900022, 7.138346616000206],
            [109.126872349000337, 7.302351381000165],
            [109.165050026000188, 7.314501121000205],
            [109.315949775000092, 7.362523756000144],
            [109.424285533000159, 6.86899674900036],
            [109.643333436000205, 6.303332933000206],
            [109.810234727000193, 5.918805613000245],
            [109.983333333000189, 5.52],
            [110.033333333000257, 4.666666667000072],
            [109.908333333000201, 3.000000000000284],
            [109.646666551500232, 2.083333131000131],
            [109.62677009459793, 2.048001094780716],
            [109.648563264351196, 2.073409089412166],
            [109.630809271436306, 2.031664161114918],
            [109.547336244411724, 1.906944961915258],
            [109.563872398318381, 1.833609008619248],
            [109.583872289903184, 1.802500077810976],
            [109.593872403333648, 1.79528208569036],
            [109.603591220092881, 1.791109071363735],
            [109.61580918510947, 1.789164134545558],
            [109.667209194819804, 1.706391164075967],
            [109.668727157489002, 1.617009062934301],
            [109.698863284612173, 1.580282077687798],
            [109.71164518407636, 1.565836035162761],
            [109.721918212274943, 1.555553954508696],
            [109.739963276395599, 1.539164148631755],
            [109.824709179240017, 1.468609143179094],
            [109.931663437523866, 1.406944990087823],
            [109.97970917721193, 1.317218057448429],
            [109.985409206663235, 1.301882192079688],
            [109.996045171270396, 1.288644987633859],
            [110.071663349169285, 1.220000050282238],
            [110.083882320014595, 1.211664079912964],
            [110.101091205472017, 1.201391051714211],
            [110.11553624216873, 1.195282153024891],
            [110.137209329270263, 1.189718078043541],
            [110.15581816020017, 1.187782193680675],
            [110.169436238327194, 1.187782193680675],
            [110.184554174212906, 1.185691076470619],
            [110.198863424077729, 1.176109052371828],
            [110.332491245273445, 1.001945006201936],
            [110.459991224678333, 0.902782062217398],
            [110.522063408817473, 0.865282097858085],
            [110.544436217197813, 0.856664160264984],
            [110.555254236742485, 0.853891091410844],
            [110.57443622221308, 0.852782165617498],
            [110.598600243307033, 0.856109110635259],
            [110.701927149162486, 0.878053939038296],
            [110.807900221858063, 0.908473206851397],
            [110.812609175075124, 0.917500013682456],
            [110.813027264406799, 0.934164075431966],
            [110.819563304882934, 0.950000005145853],
            [110.885536259132948, 1.005553918443354],
            [110.906936263828015, 1.015135942542145],
            [110.941091345897121, 1.018609067951616],
            [110.99470920719358, 1.020000128606398],
            [111.006945109483354, 1.021109054399687],
            [111.02414511012347, 1.024718134278942],
            [111.195245235398033, 1.062364111392185],
            [111.211927234420386, 1.069718057982698],
            [111.258327271234407, 1.067218071534796],
            [111.311100236688674, 1.044444943457449],
            [111.394709218183152, 1.011664158237124],
            [111.406372301208194, 1.008891089382928],
            [111.419436167895981, 1.008054072529433],
            [111.565127224175399, 1.000282036607359],
            [111.63527235456138, 1.029445024768961],
            [111.655254141235332, 1.036944984113404],
            [111.669572275917687, 1.038473172704528],
            [111.681927201232838, 1.033608986640104],
            [111.700272337487945, 1.024718134278942],
            [111.724427306126358, 1.011664158237124],
            [111.73275422404032, 1.006109135711029],
            [111.797763427060374, 0.993891170694269],
            [111.809982230267622, 0.995282063711215],
            [111.827209220636064, 0.998609008728806],
            [111.845836156476764, 1.007500028727975],
            [111.860118248975482, 1.020418050300066],
            [111.873872281572403, 1.041109113622497],
            [111.879963410627056, 1.055553982681261],
            [111.891236232239237, 1.07569116984024],
            [111.945682225572057, 1.12736409431875],
            [111.954709200041123, 1.132218054461191],
            [111.965554209313837, 1.135000008132863],
            [111.98331831203933, 1.137782129442542],
            [112.04165417900856, 1.134164164746039],
            [112.055254152224961, 1.134164164746039],
            [112.074436137695557, 1.135554051934491],
            [112.11080923898885, 1.140554024830635],
            [112.132200191228662, 1.149791049793862],
            [112.212900317036997, 1.376109141685504],
            [112.214709299385277, 1.394164096451959],
            [112.209836228503491, 1.403191070921196],
            [112.191782279565359, 1.402782034045003],
            [112.181509251366947, 1.411391086820572],
            [112.184709294370123, 1.425282079715885],
            [112.201372350291194, 1.450136099081135],
            [112.309709287326996, 1.499444991581242],
            [112.472763288444753, 1.568054054387233],
            [112.499718315665859, 1.570554040835304],
            [112.680263337102815, 1.563053913852912],
            [112.884145253426027, 1.588053945972035],
            [112.976927222143445, 1.577500124016296],
            [112.999709235037926, 1.572782118343753],
            [113.03859120759671, 1.556945015163592],
            [113.057482341026343, 1.539791114990919],
            [113.126372365227439, 1.431664228449677],
            [113.340818161790253, 1.365282069685634],
            [113.359427160358109, 1.356245036932592],
            [113.374418194229406, 1.343891117445935],
            [113.456100343816502, 1.288336030681933],
            [113.586927268239151, 1.283609140192084],
            [113.616382281908386, 1.251391115952288],
            [113.629963312023278, 1.237782090280575],
            [113.642491239820515, 1.229444946444687],
            [113.659082379012261, 1.22583603420361],
            [113.79040920014188, 1.304718125208012],
            [113.842482276678879, 1.348609123118479],
            [113.865254231289555, 1.374164204867327],
            [113.876082309118033, 1.390554010744268],
            [113.888036244184036, 1.405973192230874],
            [113.921791341832204, 1.440836042205774],
            [113.930818316301384, 1.445282138938694],
            [113.968600247884297, 1.448609083956285],
            [113.987200193997069, 1.450835987998289],
            [114.004991286450718, 1.453609056852486],
            [114.033600230811089, 1.458891164610407],
            [114.084400263890188, 1.469991151378565],
            [114.231372410254494, 1.443609111060141],
            [114.290818208845508, 1.470000036196041],
            [114.389427276666424, 1.508336011580411],
            [114.401654126500716, 1.509718019780053],
            [114.41330933053689, 1.507782135417017],
            [114.424427254577836, 1.504718046883738],
            [114.443591302775644, 1.496664211376071],
            [114.452472264491007, 1.491945032237027],
            [114.475682257362564, 1.477500163178263],
            [114.492336260828552, 1.463336087876655],
            [114.511382291829136, 1.44805403432639],
            [114.533336172687655, 1.435553934448023],
            [114.54359126361328, 1.4322181046129],
            [114.559982242956721, 1.432917993530054],
            [114.576518229225485, 1.440554074982174],
            [114.588872316350376, 1.452082041727806],
            [114.674427240491468, 1.642500106942691],
            [114.710818279057662, 1.77055396251103],
            [114.783336158601287, 1.863891148496123],
            [114.844709292013818, 1.891664081831493],
            [114.856509335337023, 1.900691056300559],
            [114.861927229926778, 1.915282105750975],
            [114.872482225349017, 2.007782107244623],
            [114.872482225349017, 2.020282039485153],
            [114.862618234026513, 2.046454029309245],
            [114.848872415694473, 2.047218123604978],
            [114.839154101849772, 2.043891178587216],
            [114.800536159241801, 2.064718028741538],
            [114.776382364069491, 2.15013599258441],
            [114.778591330838594, 2.165554000604573],
            [114.791509184772508, 2.216809003389528],
            [114.804700288750723, 2.248891073159257],
            [114.813727263219789, 2.26069111648269],
            [114.87302721669505, 2.279444954337748],
            [114.904291212712565, 2.276664174132577],
            [114.92970028170771, 2.282218023192115],
            [114.939418260276398, 2.285554020665245],
            [114.949145123662447, 2.293191107945859],
            [115.001663279259446, 2.360553949384155],
            [115.090818230824425, 2.422500069699197],
            [115.187482361827279, 2.483053955892842],
            [115.203872335342453, 2.487218085401992],
            [115.215682269311515, 2.49278216038357],
            [115.230818310108248, 2.508053988012023],
            [115.236091365410516, 2.529653984917218],
            [115.19914527721437, 2.583053916730705],
            [115.185254116681108, 2.596945077264195],
            [115.169436291878242, 2.60513604070826],
            [115.133600302941886, 2.611527074259186],
            [115.123172377172295, 2.605553962401586],
            [115.107618247044229, 2.597217992032313],
            [115.092063278725846, 2.600553989505443],
            [115.082363405067781, 2.613609139013704],
            [115.118318249391677, 2.851109025049141],
            [115.139709201631149, 2.906109062183191],
            [115.152072341211579, 2.922082119833078],
            [115.24970927589959, 3.01083608114908],
            [115.301372309732187, 3.016391103675176],
            [115.305545156420862, 3.006664072650949],
            [115.310254109637867, 2.997782105107092],
            [115.31831817106729, 2.987782159314804],
            [115.378036213874339, 2.991808993249549],
            [115.487200277117495, 3.027500142898816],
            [115.495527195031173, 3.040000075139176],
            [115.498872412597848, 3.050282155793298],
            [115.528591288579833, 3.176109107319803],
            [115.513045205078924, 3.184026988357573],
            [115.509991342467288, 3.198336070584389],
            [115.511936279285806, 3.210000159437925],
            [115.529982349234729, 3.301664149906514],
            [115.534145137639598, 3.318336090645062],
            [115.538882254051259, 3.333609091740016],
            [115.553309185837179, 3.373891177409234],
            [115.558872422628383, 3.38916417850379],
            [115.570827195884704, 3.417782175319644],
            [115.582972405981877, 3.427982113322344],
            [115.596936153796833, 3.424718032578483],
            [115.613872292124256, 3.42041811623767],
            [115.627682315834704, 3.434309109132982],
            [115.626082378152205, 3.457782126127086],
            [115.601927241875842, 3.53916420357973],
            [115.578872314213072, 3.585554014471768],
            [115.574154140902351, 3.594718116877232],
            [115.571382245514428, 3.612782124099226],
            [115.571382245514428, 3.666109133354894],
            [115.576927209756718, 3.708609070610407],
            [115.585272400219651, 3.741664111703528],
            [115.616382336856191, 3.854164172419928],
            [115.6624913539913, 4.078335994178758],
            [115.661100293336517, 4.097782177238116],
            [115.659709232681621, 4.108600029144668],
            [115.683309319328146, 4.167364211367328],
            [115.690809278672532, 4.180273180483937],
            [115.76138238903593, 4.239717973246286],
            [115.770263350751293, 4.244718113780607],
            [115.850536167134464, 4.321664152784308],
            [115.85754511458947, 4.344100160714788],
            [115.872482336642179, 4.361109053961854],
            [115.888036131493891, 4.368127053872342],
            [115.976091209721176, 4.343053931557392],
            [115.986645199315149, 4.339718101722497],
            [116.004154156907134, 4.330000123153582],
            [116.030882369827623, 4.300764212434046],
            [116.047618180668792, 4.281109152346801],
            [116.061100304325919, 4.27791799416093],
            [116.074154112729957, 4.283609138794873],
            [116.140263356725654, 4.337218115274197],
            [116.153318170957959, 4.355000155272535],
            [116.314700317663778, 4.357773056488952],
            [116.425809317725111, 4.310000063930772],
            [116.441654132256303, 4.299445068508589],
            [116.454163284590209, 4.29458205591061],
            [116.465818320988205, 4.294027006280714],
            [116.527618260911595, 4.319309005623325],
            [116.5336002575865, 4.330835966540462],
            [116.641936188793579, 4.334718128826125],
            [116.688763367394813, 4.330753991527388],
            [116.839845160897426, 4.330418044847136],
            [116.876918318746107, 4.345273124248365],
            [116.889163441129199, 4.349309010638592],
            [116.910536288457934, 4.352218033962686],
            [116.924427281353246, 4.352218033962686],
            [116.936918328776528, 4.35082697330796],
            [117.0438724194222, 4.33694503286813],
            [117.176654171308996, 4.335553972213177],
            [117.191791217933883, 4.336244976312798],
            [117.202772349314614, 4.345973180803469],
            [117.211109325512211, 4.35444493800469],
            [117.22360020529743, 4.35874502198368],
            [117.239436135011147, 4.358335985107658],
            [117.251791227964304, 4.353891061841409],
            [117.401791253040074, 4.254582105102998],
            [117.410809342691664, 4.242500094556135],
            [117.420254238854227, 4.224718054557798],
            [117.424427253180852, 4.21471810876551],
            [117.439418287052035, 4.195836027791358],
            [117.452763282772935, 4.18860915085321],
            [117.475536243212105, 4.18360901031906],
            [117.497754154021209, 4.178891172284523],
            [117.52887230492297, 4.175273207587793],
            [117.592054253738866, 4.169818451330798],
            [117.597984443000314, 4.16966393100023],
            [117.679800443000318, 4.168384931000276],
            [117.686918436752762, 4.168336282546647],
            [117.726645154918856, 4.169718017423747],
            [117.83970931772069, 4.171945089103531],
            [117.879436186122007, 4.17305401489682],
            [117.903554440299445, 4.174045625052429],
            [117.910965522000083, 4.175734091000095],
            [117.943005025000161, 4.179733861000102],
            [117.951455534000274, 4.175258513000188],
            [117.980569382000226, 4.159157048000282],
            [117.981279339000253, 4.158722009000087],
            [117.988472936000335, 4.153625248000196],
            [118.001508006000222, 4.143250526000315],
            [118.052926485000171, 4.100755326000353],
            [118.055033538000316, 4.099751317000141],
            [118.061751160000313, 4.096484576000307],
            [118.083510674000195, 4.085693910000089],
            [118.08816358800027, 4.083181168000181],
            [118.106467867000333, 4.072014847000275],
            [118.122628960000156, 4.062021598000229],
            [118.12331484400022, 4.061510147000092],
            [118.141963607000207, 4.047374266000134],
            [118.228809549000175, 3.97986010000028],
            [118.232573079000304, 3.975065210000196],
            [118.289484199000412, 3.902079957000183],
            [118.293973305000236, 3.842413312000133],
            [118.301917694000394, 3.73411764400015],
            [118.627504208000346, 3.351708661000259],
            [118.748495742000273, 3.209302006000144],
            [119.004645705000144, 3.20003917400021],
            [119.46749077100003, 3.182881756000256],
            [119.947309413000312, 3.026916159000223],
            [120.264643702000399, 3.000332643000206],
            [120.480228210000291, 3.11645495700003],
            [121.138785297000254, 3.312258950000114],
            [121.374799636000262, 3.324840492000192],
            [122.638876892000326, 3.577233170000227],
            [122.652545697000392, 3.576025311000194],
            [122.733071603000212, 3.58519209800005],
            [122.883567848000325, 3.735909693000224],
            [123.169080665000251, 4.287784176000287],
            [124.032354192000412, 4.779354530000091],
            [124.133500092000304, 4.86112823700023],
            [124.13503525800013, 4.862369377000221],
            [124.849532764000173, 4.959697203000246],
            [125.056712212000321, 4.983259761000056],
            [125.385723383000254, 5.049731437000219],
            [125.58277032000035, 5.15938468200028],
            [125.879766658000335, 5.361882185000297],
            [126.189071668000338, 5.654907984000147],
            [126.602686942000219, 6.044375522000337],
            [127.12784625400036, 6.342833571000199],
            [128.985949543000288, 6.521357714000146],
            [129.489699530857251, 6.670852391428809],
            [129.578176077000307, 5.938661671000204],
            [129.557354879000286, 4.824408321000249],
            [129.58838459000026, 4.772711051000101],
            [129.626966891000166, 4.503683395000166],
            [129.766183207000381, 3.525020506000203],
            [129.871606190000421, 3.007800592000194],
            [129.946745909000356, 2.63548675200019],
            [130.052634576000173, 2.106233631000237],
            [130.121083310000245, 1.991735373000267],
            [130.121637871000416, 1.99080549700011],
            [131.315929649000282, 2.044195140000227],
            [132.24611926300031, 1.796852927000145],
            [132.800614750000364, 1.648712881000222],
            [132.926443674000211, 1.811272240000335],
            [133.850249374000214, 2.933278317000202],
            [133.880094103000147, 2.968832584000211],
            [134.694819377000158, 3.443163018000178],
            [135.567025824000325, 3.94785279700028],
            [135.705986825000309, 3.951047215000244],
            [135.745914479000248, 3.946430198428686],
            [135.886107627000229, 3.8744711450002],
            [136.065965627000196, 3.767340145000162],
            [136.07647862700037, 3.760145145000308],
            [136.082320627000399, 3.756665145000113],
            [136.255118627000229, 3.638404145000038],
            [136.420179627000266, 3.509479145000341],
            [136.576857627000322, 3.370396145000313],
            [136.72453962700024, 3.221697144000188],
            [136.862645627000234, 3.063967144000287],
            [136.990635628000291, 2.897822144000259],
            [137.108009628000218, 2.723915144000159],
            [137.117712628000277, 2.707394144000261],
            [137.122283628000332, 2.700622144000249],
            [137.131981628000176, 2.684110144000215],
            [137.13655862800033, 2.677328144000228],
            [137.146263628000156, 2.660804144000281],
            [137.150832628000444, 2.654034144000207],
            [137.160531628000399, 2.637521144000289],
            [137.165107628000214, 2.630740144000185],
            [137.174807628000281, 2.614225144000329],
            [137.179382628000326, 2.607446144000221],
            [137.18908062800017, 2.590934144000187],
            [137.193658628000208, 2.584151144000316],
            [137.203359628000157, 2.567634144000237],
            [137.207933628000433, 2.560857144000295],
            [137.217633628000272, 2.544343144000095],
            [137.222209628000314, 2.537562144000219],
            [137.231910628000264, 2.521046144000195],
            [137.236485628000196, 2.514267144000257],
            [137.246180628000161, 2.497760144000154],
            [137.250762628000302, 2.490972144000182],
            [137.260464628000136, 2.474454144000219],
            [137.265038628000184, 2.467677144000277],
            [137.274739628000134, 2.451161144000309],
            [137.279315628000404, 2.444381144000261],
            [137.289012628000364, 2.427872144000162],
            [137.293592628000397, 2.421086144000185],
            [137.303294628000231, 2.404568144000223],
            [137.30786962800039, 2.397790144000226],
            [137.31756662800035, 2.381280144000357],
            [137.322147628000153, 2.374494144000209],
            [137.331845628000337, 2.35798414400017],
            [137.336425628000256, 2.351198144000193],
            [137.346123628000441, 2.33468714400027],
            [137.350703628000133, 2.327902144000234],
            [137.360407628000303, 2.311382144000277],
            [137.364982628000234, 2.304605144000334],
            [137.374675628000318, 2.288102144000163],
            [137.379261628000336, 2.281309144000147],
            [137.38895962800018, 2.264797144000283],
            [137.393540628000324, 2.258012144000247],
            [137.403240628000162, 2.241498143000342],
            [137.406530628000155, 2.236625143000197],
            [137.416035628000259, 2.231748143000061],
            [137.595903628000315, 2.124656143000266],
            [137.611252628000216, 2.114155143000232],
            [137.618767628000455, 2.109681143000216],
            [137.63411362800025, 2.099184143000343],
            [137.641631628000141, 2.094707143000278],
            [137.656986628000254, 2.084203143000138],
            [137.664495628000168, 2.07973314300034],
            [137.67985262800039, 2.069228143000316],
            [137.687358628000425, 2.06475814300029],
            [137.702701628000341, 2.054264143000296],
            [137.710223628000449, 2.049785143000065],
            [137.725586628000428, 2.039277143000163],
            [137.733087628000249, 2.034811143000127],
            [137.734164628000144, 2.034073143000171],
            [137.741938628000185, 2.032323143000156],
            [137.751696628000332, 2.029469143000085],
            [137.768454628000313, 2.025696143000175],
            [137.77821762800022, 2.022840143000337],
            [137.794968628000447, 2.019069143000252],
            [137.804733628000349, 2.016213143000186],
            [137.821484628000235, 2.012442143000271],
            [137.831259628000453, 2.009583143000157],
            [137.847998628000255, 2.00581514300012],
            [137.857772628000134, 2.002956143000176],
            [137.874512628000389, 1.999188143000197],
            [137.884294628000248, 1.996327143000258],
            [137.901026628000182, 1.992561143000216],
            [137.910812628000144, 1.989698143000112],
            [137.927539628000432, 1.985934143000065],
            [137.937328628000159, 1.983070143000077],
            [137.954053628000224, 1.979306143000201],
            [137.963846628000283, 1.976442143000213],
            [137.980566629000236, 1.972679143000278],
            [137.990351629000315, 1.969818143000339],
            [138.007079629000259, 1.966053143000238],
            [138.016882629000293, 1.963187143000198],
            [138.033591629000284, 1.959426143000258],
            [138.043399629000191, 1.956559143000163],
            [138.060104629000193, 1.952799143000107],
            [138.069901629000242, 1.949935143000289],
            [138.086616629000332, 1.946173143000237],
            [138.096435629000439, 1.943303143000264],
            [138.11312862900013, 1.939546143000086],
            [138.122936629000151, 1.936679143000219],
            [138.139639629000158, 1.932920143000217],
            [138.149451629000282, 1.930051143000355],
            [138.166150629000299, 1.926294143000177],
            [138.175984629000254, 1.923419143000331],
            [138.192661629000213, 1.919667143000197],
            [138.202485629000194, 1.916795143000286],
            [138.219171629000243, 1.913041143000157],
            [138.229000629000438, 1.910168143000305],
            [138.245682629000157, 1.906415143000288],
            [138.255513629000347, 1.903541143000155],
            [138.272192629000301, 1.899788143000137],
            [138.282029629000135, 1.89691314300029],
            [138.298703629000215, 1.893162143000268],
            [138.308561629000337, 1.890281143000095],
            [138.325212629000134, 1.886535143000117],
            [138.335060629000168, 1.883658143000105],
            [138.351721629000167, 1.879909143000248],
            [138.361563629000216, 1.877033143000347],
            [138.378230629000313, 1.873284143000262],
            [138.388087629000211, 1.870404143000201],
            [138.404739629000233, 1.866658143000222],
            [138.414602629000456, 1.863775143000055],
            [138.431248629000152, 1.860032143000183],
            [138.441116629000248, 1.857148143000302],
            [138.457756629000187, 1.853406143000313],
            [138.467633629000261, 1.850520143000267],
            [138.484263629000225, 1.846780143000274],
            [138.494128629000215, 1.84389814300016],
            [138.510772629000144, 1.840155143000288],
            [138.520657629000198, 1.837266143000136],
            [138.537279629000295, 1.833529143000021],
            [138.547170629000334, 1.830639143000212],
            [138.563786629000447, 1.826903143000152],
            [138.573670629000389, 1.824015143000338],
            [138.590293629000371, 1.820278143000223],
            [138.594692629000178, 1.818992143000173],
            [138.599775629000078, 1.818163143000163],
            [138.626758629000278, 1.813825143000258],
            [138.653730129000337, 1.809489143000292],
            [138.680707129000098, 1.805153143000211],
            [138.707684629000141, 1.800816643000189],
            [138.734663129000182, 1.796479643000168],
            [138.7616456290001, 1.792141643000093],
            [138.788614129000166, 1.787806143000182],
            [138.81559062900044, 1.783469643000046],
            [138.842568629000425, 1.779132643000196],
            [138.869542629000307, 1.774796643000059],
            [138.896518129000242, 1.770459643000208],
            [138.923494629000061, 1.766122643000188],
            [138.950468629000284, 1.761786643000278],
            [138.977449629000375, 1.757448143000147],
            [139.004416129000333, 1.753112643000236],
            [139.031392129000324, 1.748775643000215],
            [139.058364130000314, 1.74443914300025],
            [139.085337630000254, 1.740103143000113],
            [139.112312130000305, 1.735766143000262],
            [139.139283130000308, 1.731430143000182],
            [139.166257130000076, 1.72709364300016],
            [139.193223630000261, 1.722758143000306],
            [139.220201130000191, 1.718421143000285],
            [139.247175630000243, 1.714084143000264],
            [139.273773630000278, 1.709831143000258],
            [139.274517630000219, 1.709664143000282],
            [139.300742630000173, 1.705495143000292],
            [139.301493630000209, 1.705327143000261],
            [139.327709630000413, 1.701159143000211],
            [139.531596630000195, 1.655409143000213],
            [139.732221630000254, 1.59689114300005],
            [139.928799630000185, 1.525837143000047],
            [139.932181630000173, 1.524367143000291],
            [139.954106630000297, 1.516443143000117],
            [140.145866631000445, 1.433135143000356],
            [140.33208363100016, 1.337885143000165],
            [140.356389131000128, 1.325450143000126],
            [140.380717131000097, 1.313003143000174],
            [140.405021631000409, 1.300568143000191],
            [140.429336131000127, 1.288128643000164],
            [140.453651131000015, 1.275689143000136],
            [140.477969631000292, 1.263247643000284],
            [140.502282631000185, 1.250807643000201],
            [140.526590131000262, 1.238371643000107],
            [140.550909631000195, 1.225930143000085],
            [140.575218631000325, 1.213493643000163],
            [140.599538631000314, 1.201051143000257],
            [140.623845631000108, 1.188615643000162],
            [140.648162631000218, 1.17617514300008],
            [140.672476131000167, 1.163736142000175],
            [140.696786131000067, 1.151298642000143],
            [140.721103131000177, 1.138859142000285],
            [140.745415131000186, 1.126420642000141],
            [140.769725131000314, 1.113983142000109],
            [140.780741631000183, 1.108347142000241],
            [140.794384631000298, 1.102198142000248],
            [140.801432586000374, 1.099386233000189],
            [140.801642611000261, 1.08917741900018],
            [140.801714135000225, 1.085700884000232],
            [140.803522061000308, 0.997822216000259],
            [140.810525880000455, 0.774113712000144],
            [140.892636162000258, 0.491368749000287],
            [141.004388605000145, 0.106551654000214],
            [141.457682443000181, -1.018989187999864],
            [141.402139160000161, -1.163106917499874],
            [141.401741712000216, -1.164138170999877],
            [141.275423711768099, -1.491894357098658],
            [141.116094331926661, -1.905304959656291],
            [141.025000000000318, -2.141666999999813],
            [141.000000000000227, -2.593610999999896],
            [141.002472349276104, -2.607081323345142],
            [141.006100367308932, -2.613890854780095],
            [141.006100367308932, -4.905554989510563],
            [141.006100367308932, -5.999999871477428],
            [141.006136241854733, -6.332926887241285],
            [140.98550921627259, -6.333054962722258],
            [140.967054277085595, -6.336872919629343],
            [140.932191259472575, -6.43444598421496],
            [140.928036182418737, -6.450835790091958],
            [140.947191345798956, -6.470691010027451],
            [140.951082392902208, -6.480555001350126],
            [140.947209283071857, -6.49999984330492],
            [140.893863330715021, -6.600972943104182],
            [140.863009209763845, -6.631390869812833],
            [140.858854132710235, -6.678335897972886],
            [140.859409349978137, -6.728608876979024],
            [140.871063380547866, -6.78639086778486],
            [140.875245279691626, -6.796108846353661],
            [140.902772287986977, -6.855835941615851],
            [140.949400312568173, -6.903608934173917],
            [140.981218184749082, -6.906526842315486],
            [140.994536190741655, -6.896664024459483],
            [141.006136241854733, -6.893281926518682],
            [141.006136241854733, -6.904781897707494],
            [141.00701818555001, -9.128463484662234],
            [141.012118999095264, -9.135112472927005],
            [141.019444000000362, -9.135555999999838],
            [141.016699218387998, -9.140007545412857],
            [140.866667000000234, -9.383332999999823],
            [140.825000000000273, -9.40833],
            [140.483333000000243, -9.866666999999836],
            [139.60000000000025, -10.4],
            [139.201140838000128, -10.831851914999902],
            [138.634484421000252, -10.398525996999922],
            [138.607495037000149, -10.380534935999833],
            [138.584484752000321, -10.365196332999915],
            [138.345798091500228, -10.224153872999864],
            [138.217817261000278, -10.148528652999914],
            [138.097169321500246, -10.096823052499929],
            [137.751150673000211, -9.948531236999884],
            [135.664468660000125, -9.196715274999974],
            [135.662486672000455, -9.196001178999779],
            [135.484496786000136, -9.131872750999946],
            [135.217839444000219, -9.281873090999852],
            [135.081104249000305, -9.350245677999965],
            [135.051171062000321, -9.365213362999896],
            [134.977238223000199, -9.382274874999837],
            [134.834502944000292, -9.415213954999842],
            [133.384511949000171, -8.881881074999853],
            [133.23452309700042, -8.898551548999762],
            [130.167875691000404, -9.415231546999905],
            [128.013381690000301, -9.415240115999893],
            [128.001189009500195, -9.415262179499905],
            [127.97799962500028, -9.432653564999896],
            [127.966580208666869, -9.441217795333216],
            [127.882604455000319, -9.072014940999907],
            [127.819356837000328, -8.793833788999905],
            [127.586464774000433, -8.474993475999895],
            [127.48665389900043, -8.341912287999889],
            [127.392388055000197, -8.231011318999833],
            [127.22880911700031, -8.228238787999885],
            [127.156589289000294, -8.217755265999756],
            [127.05691258600018, -8.20328606899983],
            [126.876698528000247, -8.122882866499822],
            [126.760252461000391, -8.158925693999834],
            [126.588355960000285, -8.194968506999885],
            [126.483785219000424, -8.196835845999829],
            [126.43309458500039, -8.19774103799989],
            [126.395090047000394, -8.196857209999848],
            [126.313876023000148, -8.194968506999885],
            [126.178022304000251, -8.194968506999885],
            [126.08098396000014, -8.211603662999892],
            [125.981173085000137, -8.231011318999833],
            [125.845319366000126, -8.247646475999829],
            [125.784323837000215, -8.161698191999903],
            [125.698375585000122, -8.103475193999827],
            [125.607806428000231, -8.03323791299988],
            [125.533872459000236, -7.992574224999885],
            [125.48326146300019, -7.981071726999915],
            [125.452545053000335, -7.974090724999883],
            [125.433571852000341, -7.975988039999834],
            [125.37861108400034, -7.981484099999818],
            [125.364506503000143, -7.992062547999922],
            [125.349037491000217, -8.003664318999881],
            [125.33920514900035, -8.035127803999842],
            [125.33055399000034, -8.062811506999878],
            [125.33055399000034, -8.125655381999877],
            [125.335152948000314, -8.187741243999824],
            [125.337947397000335, -8.22546625699988],
            [125.335739396000122, -8.244234245999849],
            [125.33055399000034, -8.288310162999835],
            [125.314775490000443, -8.392448236999883],
            [125.3120705150003, -8.410301063499958],
            [125.279535833000295, -8.424941826999884],
            [125.238136490000329, -8.443571537999901],
            [125.167795445000252, -8.473717693999845],
            [125.134628928000438, -8.487931912999898],
            [125.01633455300032, -8.535989006999785],
            [124.988030027000377, -8.554303706999917],
            [124.953490646000319, -8.576652725999907],
            [124.890646771000206, -8.628406506999909],
            [124.84628636500031, -8.676463600999909],
            [124.820409459000302, -8.709733881999853],
            [124.8351962710002, -8.757790975999853],
            [124.872163271000318, -8.81324147599986],
            [124.878827811000406, -8.821810171999914],
            [124.923917053000196, -8.879782069999919],
            [124.964807751333524, -8.932980020333218],
            [124.967853592749861, -8.936942649926209],
            [124.951900113563966, -8.950126872663247],
            [124.946300245180964, -9.008946043002652],
            [124.965300343352055, -9.03727302013931],
            [125.018500282955188, -9.034317896347488],
            [125.093300219463913, -9.011673011389405],
            [125.139900248488289, -9.024117958344846],
            [125.159700315500572, -9.068099983724153],
            [125.165900241658733, -9.109208860324827],
            [125.140000328412498, -9.150399879576923],
            [125.069100323824159, -9.167172906068117],
            [125.019700236217091, -9.167855025350491],
            [124.986800260333212, -9.194363967683387],
            [124.966300304403603, -9.22130893662063],
            [124.971000205165524, -9.274390859026767],
            [125.019900228427446, -9.336226841133737],
            [125.062300253396984, -9.367772972013029],
            [125.129418176415925, -9.435290890440342],
            [125.12469109106712, -9.437499926714679],
            [125.108036320419956, -9.448336077123372],
            [125.092271745331345, -9.459827455378615],
            [125.999344913857271, -10.466151765428435],
            [125.684572220000291, -10.615269439999793],
            [125.317905560000213, -11.015269439999827],
            [124.567905560000213, -11.11526943999985],
            [124.167916670000295, -11.415272219999906],
            [124.001250000000255, -11.431938889999969],
            [123.667916670000182, -11.465272219999804],
            [123.434583330000237, -11.381938889999901],
            [123.279035499285897, -11.537486720714185],
            [123.234572222000338, -11.548602777999861],
            [122.717916667000395, -11.61527222199993],
            [122.4512500000003, -11.631827777999888],
            [122.034583333000228, -11.665272221999885],
            [122.001250000000255, -11.665272221999885],
            [121.934583333000319, -11.71527222199984],
            [121.334583333000296, -12.398605555999893],
            [120.267916667000236, -12.581938888999957],
            [119.851250000000164, -12.631938888999912],
            [119.40126388900012, -12.831947221999769],
            [118.451263889000188, -13.248613888999841],
            [117.997633610000321, -12.923818870999867],
            [117.974741610000137, -12.902312870999822],
            [117.929894610000133, -12.872740870999905],
            [117.856284610000216, -12.803522870999814],
            [117.797468610000237, -12.764692869999919],
            [117.567037609000408, -12.663611869999784],
            [117.543246609000221, -12.647871869999904],
            [117.500463609000349, -12.629079869999885],
            [117.466763609000168, -12.606771869999818],
            [117.405788609000297, -12.579968869999902],
            [117.190274609000312, -12.437088869999911],
            [117.10148660900029, -12.445728869999868],
            [116.509144609000259, -12.401471869999909],
            [116.376168608000285, -12.368095869999877],
            [116.304526608000288, -12.362686869999891],
            [116.128596608000294, -12.31844386999984],
            [116.03283760800025, -12.311171869999896],
            [115.857639608000454, -12.267009869999868],
            [115.761168608000276, -12.25963886999989],
            [115.586694608000357, -12.215558869999825],
            [115.489518608000253, -12.20808886999987],
            [115.474609608000378, -12.204315869999789],
            [115.466252608000218, -12.203672869999878],
            [115.345584607000205, -12.173117869999828],
            [115.271994607000238, -12.180080869999813],
            [115.253823607000186, -12.178675869999893],
            [115.18478760700043, -12.185198869999851],
            [114.593744607000417, -12.139148869999872],
            [114.547210607000238, -12.127289869999913],
            [114.406043607000129, -12.116196869999953],
            [114.33853060600029, -12.098965869999915],
            [114.131641606000244, -12.08262786999984],
            [114.09175460600045, -12.072426869999902],
            [113.968333606000215, -12.062639869999884],
            [113.90171660600015, -12.04557986999987],
            [113.858320606000348, -12.042127869999774],
            [113.822153606000143, -12.03285886999987],
            [113.798518606000357, -12.03097586999985],
            [113.225048605000325, -11.883616869999798],
            [113.220239605000302, -11.881446869999763],
            [113.128257605000272, -11.857747869999912],
            [113.051925605000406, -11.851577869999915],
            [112.864089605000231, -11.803101869999921],
            [112.851983605000328, -11.802118869999845],
            [112.819833605000326, -11.805045869999901],
            [112.576598605000186, -11.7852338699999],
            [112.543896605000327, -11.788197869999919],
            [112.301218605000145, -11.768329869999832],
            [112.267966605000424, -11.771330869999815],
            [112.025843604000215, -11.751406869999869],
            [111.992044604000171, -11.754443869999875],
            [111.755657604000191, -11.734893869999837],
            [111.722056604000386, -11.737899869999865],
            [111.132873604000054, -11.688774868999815],
            [111.129148603000147, -11.687801868999884],
            [111.127050603000271, -11.687625868999874],
            [110.953977603000112, -11.642361868999899],
            [110.855985603000192, -11.634113868999918],
            [110.683517603000155, -11.588922868999873],
            [110.584941603000232, -11.580584868999892],
            [110.413069603000281, -11.535466868999833],
            [110.313915603000339, -11.527038868999909],
            [110.194318603000227, -11.495590868999898],
            [110.124778603000095, -11.489659868999809],
            [109.554189602000349, -11.339202868999905],
            [109.504008602000056, -11.316124868999907],
            [109.47655960200035, -11.308869868999864],
            [109.427016602000265, -11.286076868999828],
            [109.399829602000409, -11.278886868999905],
            [109.350563602000307, -11.256212868999853],
            [109.215323602000296, -11.220416868999905],
            [109.145569602000251, -11.188280868999925],
            [109.03497134733351, -11.118866059333243],
            [108.490153889000283, -10.572738440999899],
            [108.329598329000191, -10.411627299999907],
            [108.050153889000285, -10.140516138999885],
            [107.983764999000243, -10.076905006999766],
            [107.905153889000246, -10.001071662999834],
            [107.847540969000192, -9.944736990999843],
            [107.729876109000429, -9.829682740999885],
            [107.727799026000241, -9.827605657999868],
            [107.72626499900025, -9.826071630999877],
            [107.578209449000326, -9.708849388999852],
            [107.461154748000354, -9.616699927999832],
            [107.199598329000139, -9.410793775999878],
            [107.107667995000327, -9.33837599200001],
            [107.072653889000151, -9.310793757999932],
            [107.013251230000265, -9.268387560999884],
            [106.878487219000078, -9.172182623999902],
            [106.867924148000156, -9.164679879999881],
            [106.848764999000423, -9.151071509999952],
            [106.82293166900007, -9.132460396999875],
            [106.558764999000175, -9.038293709999834],
            [106.386264999000161, -8.989682591999895],
            [106.347931669000332, -8.978849259999777],
            [106.180709449000346, -8.931627031999881],
            [105.700431669000295, -8.821071452999888],
            [105.699042779000138, -8.820793672999912],
            [105.578209449000212, -8.792738117999761],
            [105.393487219000178, -8.748293659999888],
            [105.300431669000147, -8.726071436999916],
            [105.271742069000311, -8.727262327999881],
            [105.226820559000146, -8.72912699699981],
            [104.502139353000132, -8.733347617999868],
            [104.272931669000172, -8.73468254799991],
            [104.153209449000144, -8.744682549999823],
            [103.380709449000278, -8.807738119999897],
            [102.569175840000241, -8.872499279999886],
            [102.272300595000218, -8.879028866999874],
            [101.687446595000154, -8.823062866999919],
            [101.122171594000292, -8.667344866999827],
            [100.593903594000267, -8.416788865999877],
            [100.118805593000275, -8.079208865999817],
            [100.072021593000215, -8.031667865999907],
            [99.97092759300017, -7.959808865999833],
            [99.945578593000278, -7.934047865999901],
            [99.890752593000059, -7.895073865999905],
            [99.483312593000221, -7.480962865999913],
            [99.421099593000235, -7.390215864999959],
            [99.377899593000222, -7.34631086499985],
            [99.278228592000232, -7.200947864999819],
            [99.209080592000191, -7.130677864999882],
            [99.109349592000285, -6.985260864999816],
            [99.040262592000147, -6.915061864999871],
            [98.94045359200021, -6.76956986499988],
            [98.871444592000273, -6.699463864999984],
            [98.771540592000292, -6.5538738649999],
            [98.702629592000335, -6.48388186499983],
            [98.602609592000306, -6.338174863999882],
            [98.533814592000169, -6.268317863999869],
            [98.475783592000141, -6.183805863999851],
            [98.435971592000158, -6.143386863999922],
            [98.405856592000134, -6.099537863999842],
            [98.362086592000253, -6.055105863999984],
            [98.290360592000241, -5.950693863999845],
            [98.211117591000288, -5.870268863999854],
            [98.142823591000194, -5.821757863999892],
            [97.735383591000129, -5.408418863999827],
            [97.644667591000257, -5.27654986399989],
            [97.629364591000126, -5.261033862999909],
            [97.492406591000247, -5.062044862999869],
            [97.46955559100013, -5.0388878629999],
            [97.409273591000215, -4.951345862999915],
            [97.399318591000224, -4.941259862999914],
            [97.324380591000192, -4.832468862999917],
            [97.309233591000293, -4.817126862999828],
            [97.175302591000104, -4.622793862999856],
            [97.148510590000114, -4.595670862999839],
            [97.118975590000105, -4.552835862999871],
            [97.078576590000239, -4.511947862999762],
            [96.972687590000248, -4.436896862999902],
            [96.870383590000301, -4.333389862999866],
            [96.764770590000069, -4.258562862999781],
            [96.732200590000161, -4.225621862999844],
            [96.69869859000039, -4.201889862999849],
            [96.290438590000349, -3.789285861999872],
            [95.960899589000235, -3.313154861999919],
            [95.937720589000378, -3.262587861999862],
            [95.862775589000307, -3.154464861999884],
            [95.828538589000061, -3.079848860999846],
            [95.718928589000257, -2.921864860999904],
            [95.706307589000176, -2.894396860999848],
            [95.666256589000113, -2.836705860999871],
            [95.461630589000265, -2.392215860999784],
            [95.329262589000223, -2.259222860999898],
            [95.327535589000149, -2.256741860999796],
            [95.268749589000208, -2.197703860999923],
            [94.937262588000237, -1.722390860000019],
            [94.693837588000349, -1.198009859999786],
            [94.688909588000286, -1.179406859999858],
            [94.424231588000225, -0.80193385899986],
            [94.419950588000177, -0.792761858999825],
            [94.330000588000303, -0.664682858999868],
            [94.322646588000225, -0.648942858999931],
            [94.175088588000108, -0.43899885899981],
            [94.170462588000134, -0.432342858999846],
            [94.051771588000406, -0.179054858999905],
            [93.629042587000185, 0.115943142000333],
            [93.54897158700021, 0.195415142000286],
            [93.4971925870002, 0.231498142000135],
            [93.371736587000214, 0.35590414200027],
            [93.291571587000249, 0.411711142000286],
            [93.254554587000257, 0.448385142000348],
            [93.231136587000094, 0.464680142000134],
            [92.816660586000125, 0.874650142000291],
            [92.479570586000079, 1.348077143000182],
            [92.22985658600021, 1.870633143000134],
            [92.074914586000205, 2.4265791440003],
            [92.01935358600025, 2.999229144000083],
            [92.064888586000393, 3.571422145000156],
            [92.145421644000237, 3.878097956000204],
            [92.398611000000301, 4.027778000000183],
            [92.725278000000344, 4.308611000000269],
            [92.854722000000208, 4.459444000000246],
            [93.686667000000227, 5.422222000000147],
            [94.171667000000184, 6.000000000000227],
            [94.403333000000146, 6.27],
            [94.540000000000305, 6.500000000000284],
            [94.633333000000164, 6.641667000000211],
            [94.926944000000333, 7.04],
            [95.429167000000348, 7.668333000000132],
            [95.520000000000323, 7.768333],
            [95.53, 7.783333],
            [95.551667000000123, 7.768333],
            [96.608333000000357, 7.096667000000252],
            [97.9, 6.363333000000353],
            [98.024999986800026, 5.950000043200077],
            [98.291666628000314, 5.450000072500131],
            [98.520970848000275, 5.150949131000175],
            [98.691666667000163, 4.928333333000239],
            [99.726666667000245, 3.993333333000294],
            [99.823859940000204, 3.889319480000154],
            [99.916666667000129, 3.79],
            [100.972842366000179, 2.887250602000051],
            [101.201666667000381, 2.691666667000277],
            [101.388161444000218, 2.550169176000225],
            [101.775000000000318, 2.256666667000218],
            [102.223333383000181, 1.919999967500075],
            [102.583333333000212, 1.686666667000281],
            [103.065000000000111, 1.325000000000102],
            [103.379999694000105, 1.250000073000194],
            [103.386814115000163, 1.246670924000341],
            [103.424969610333562, 1.228029830333469],
            [103.429677781000237, 1.225204959000166],
            [103.448126628000352, 1.214135651000163],
            [103.480545868500144, 1.188663390500153],
            [103.489985074000401, 1.173560825000095],
            [103.492124440000282, 1.170137839000233],
            [103.51296550900031, 1.151612445000239],
          ],
          [
            [124.066600226083096, -9.401599986390693],
            [124.118800204634681, -9.432281943050157],
            [124.176100236006619, -9.418646095288182],
            [124.200100307073853, -9.399035794564099],
            [124.249400314756826, -9.404364002789578],
            [124.262300231417726, -9.427664017301765],
            [124.293400277408864, -9.46362690825265],
            [124.34040029085395, -9.461664034161458],
            [124.368500286051813, -9.428964050487934],
            [124.449300324146407, -9.282655080304721],
            [124.462809259426194, -9.184409025019761],
            [124.468812532171683, -9.184414237788303],
            [124.468968477500198, -9.183027424249872],
            [124.470412583000325, -9.163635882999984],
            [124.471268224000141, -9.160040766999884],
            [124.475501740000254, -9.142252944999825],
            [124.479375779000293, -9.120716349999896],
            [124.485499333000348, -9.086674225999843],
            [124.506074240000316, -8.968210882999813],
            [124.50129811800025, -8.957516293999845],
            [124.498325833000422, -8.950860819999832],
            [124.482497667500297, -8.915815860999828],
            [124.473059396000167, -8.894918694999888],
            [124.466792122000129, -8.881145398999934],
            [124.451693865000152, -8.847964663999903],
            [124.441008418000138, -8.823891569999887],
            [124.438675646000206, -8.818636100999925],
            [124.428574760000288, -8.819653118999895],
            [124.403296458000227, -8.822198288999857],
            [124.334945990000278, -8.837045350999801],
            [124.27670049000028, -8.859825725999826],
            [124.213865758000338, -8.877548311999817],
            [124.129023739000218, -8.901478069999882],
            [124.113759567000443, -8.904704421999838],
            [124.069529208000176, -8.914053288999924],
            [123.970426850000422, -8.931325546999858],
            [123.929083686500235, -8.938531125499878],
            [123.941526062000321, -8.966261932999771],
            [123.995122020000395, -9.085712913999885],
            [123.995943614000339, -9.087487569999837],
            [123.999561224000331, -9.109961355999815],
            [124.018718427000294, -9.228972226999872],
            [124.021840482500011, -9.245558538499836],
            [124.036857536000184, -9.277277250999816],
            [124.054699552000443, -9.314962788999821],
            [124.059440053000344, -9.327700616999834],
            [124.061282364000135, -9.332650944999898],
            [124.062076319098537, -9.334824838008444],
            [124.046100052102247, -9.339999536359755],
            [124.066600226083096, -9.401599986390693],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "IND", Country: "India" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [93.133333333000223, 13.816666667000106],
              [93.666666667000129, 13.566666667000163],
              [93.833333333000155, 13.800000000000182],
              [95.033333333000201, 13.800000000000182],
              [95.683333333000292, 12.900000000000148],
              [95.500000000000171, 12.31666666700022],
              [95.433333333000178, 11.716666667000197],
              [95.366666667000118, 10.733333333000076],
              [95.250000000000227, 10.466666667000084],
              [95.266666667000152, 10.300000000000125],
              [95.466666667000027, 9.883333333000053],
              [95.578333660000112, 9.644046919],
              [95.581644232000144, 9.636952837000138],
              [95.583333333000212, 9.63333333300011],
              [95.585194006000222, 9.633333244000028],
              [95.59027784320017, 9.633333],
              [95.608611111000187, 9.288333333000026],
              [95.627777778000222, 8.801111111000068],
              [95.628333333000057, 8.753055556000049],
              [95.653055556000027, 8.229722222000078],
              [95.654444444000205, 8.165],
              [95.696666667000216, 7.958333333000098],
              [95.546666667000238, 7.800000000000125],
              [95.53, 7.783333],
              [95.520000000000323, 7.768333],
              [95.429167000000348, 7.668333000000132],
              [94.926944000000333, 7.04],
              [94.633333000000164, 6.641667000000211],
              [94.540000000000305, 6.500000000000284],
              [94.403333000000146, 6.27],
              [94.171667000000184, 6.000000000000227],
              [93.686667000000227, 5.422222000000147],
              [92.854722000000208, 4.459444000000246],
              [92.725278000000344, 4.308611000000269],
              [92.398611000000301, 4.027778000000183],
              [92.145421644000237, 3.878097956000204],
              [91.675319585000068, 4.205491356000095],
              [91.674086585000225, 4.206695856000124],
              [91.261528585000178, 4.616059356000164],
              [90.925825584999984, 5.090372357000206],
              [90.873866585000201, 5.200225357000193],
              [90.774050585000225, 5.34088435700005],
              [90.772502085000156, 5.344106857000043],
              [90.523379584000111, 5.869115357000226],
              [90.420117584000252, 6.244069358000104],
              [90.417125584000132, 6.248263358000031],
              [90.416348584000332, 6.249871858000063],
              [90.389074584000184, 6.307099358000187],
              [90.204232584000181, 6.565822358000162],
              [90.203545583999983, 6.567106858000216],
              [90.199643584000114, 6.575124358000096],
              [90.199180584000146, 6.575771358000168],
              [90.198479084000212, 6.577095858000177],
              [90.197488584000155, 6.579014358000109],
              [90.196529584000189, 6.581011358000126],
              [90.194950584000082, 6.583218358000124],
              [90.194144917333574, 6.584859024666798],
              [90.192430584000135, 6.587298358],
              [90.19164358400019, 6.588930858000083],
              [90.190849584000262, 6.590483358000085],
              [89.939864584000134, 7.114857359000098],
              [89.939371584000014, 7.116628359000117],
              [89.93824258400025, 7.118983359000083],
              [89.911276584000177, 7.215853359000221],
              [89.693778584000199, 7.668390359000171],
              [89.667697584000166, 7.761570359000189],
              [89.565752583000176, 7.973002359000247],
              [89.564999583000201, 7.975685359000181],
              [89.56229058300022, 7.981297359000166],
              [89.404964583000151, 8.540662360000056],
              [89.404164583000238, 8.543698360000121],
              [89.403985583000178, 8.545513359999973],
              [89.403326583000194, 8.547848360000103],
              [89.403203583000106, 8.549092360000202],
              [89.402389583000286, 8.551977360000137],
              [89.349016583000207, 9.090518360000033],
              [89.213820583000086, 9.368404361000046],
              [89.212918083000204, 9.371544861000189],
              [89.141935583000247, 9.621056361000058],
              [89.049295583000173, 9.810724361000212],
              [88.889406583000152, 10.369294362000062],
              [88.829968583000039, 10.946643361999975],
              [88.830193583000209, 10.949635362000095],
              [88.829903583000203, 10.952439362000064],
              [88.846676583000146, 11.176094362000185],
              [88.808029583000064, 11.547763363],
              [88.851093583000193, 12.126510363000136],
              [88.996485583000151, 12.68909736400019],
              [88.997554583000124, 12.69142036400018],
              [88.998900583000136, 12.696620364000182],
              [89.242845583000189, 13.225864364000017],
              [89.2744235830001, 13.271065364000037],
              [89.285093583000275, 13.413184364000244],
              [89.296578583000212, 13.457384364000177],
              [89.30046258300024, 13.509123365000164],
              [89.302744583000219, 13.517904365000163],
              [89.302837583000269, 13.519145365000156],
              [89.316626583000073, 13.572192365000092],
              [89.322100583000179, 13.645119365000028],
              [89.32239558300023, 13.646252365000123],
              [89.322813583000197, 13.651825365000093],
              [89.323248583000122, 13.653495365000083],
              [89.323626583000305, 13.658532365000099],
              [89.470111583000289, 14.221228365],
              [89.559187583000153, 14.413309365000146],
              [89.587456583000233, 14.521561365000196],
              [89.589234583000064, 14.525391365000189],
              [89.591510583000286, 14.534104365000076],
              [89.59876658300027, 14.549838365000028],
              [89.64303358300009, 14.645248366000089],
              [89.657223083000304, 14.699287866000077],
              [89.657862583999986, 14.701513366000199],
              [89.904285584000093, 15.230750366000109],
              [89.905873084000177, 15.233067866000169],
              [89.912478584000326, 15.242506866000099],
              [90.233333332000143, 15.700000001000092],
              [90.258998647666829, 15.683219007],
              [92.4, 14.283333333000144],
              [92.833333333000212, 14.0],
              [92.900000000000205, 13.950000000000159],
              [93.133333333000223, 13.816666667000106],
            ],
          ],
          [
            [
              [78.070963251635703, 35.438600181634797],
              [78.034427206144301, 35.355273167363919],
              [78.024018223475764, 35.324300191025429],
              [78.023045084514365, 35.280691160338392],
              [78.053318171935757, 35.216100046649842],
              [78.089982292908303, 35.155544986989696],
              [78.118591237268447, 35.116245092737415],
              [78.168318219100456, 34.97720909995985],
              [78.172763310004711, 34.945827086745282],
              [78.18109123374694, 34.921382104256054],
              [78.195818237667481, 34.901927036379391],
              [78.212491184234523, 34.887773186999539],
              [78.230118159023618, 34.863882080673761],
              [78.278591208136731, 34.721927006288297],
              [78.291363384593069, 34.669991057687525],
              [78.304145116419136, 34.648745112373206],
              [78.337072249669291, 34.611800197643561],
              [78.365536187104453, 34.594153944476943],
              [78.400127128139729, 34.583882089744975],
              [78.456100304235235, 34.573882143952687],
              [78.48776327884508, 34.571664124728045],
              [78.582491190208941, 34.561936087875381],
              [78.605254092364135, 34.55943610142748],
              [78.625536118810373, 34.554991178161004],
              [78.653600239463003, 34.546244997449264],
              [78.709845156860126, 34.521382093266482],
              [78.951382270390241, 34.3808180795358],
              [78.985354124055164, 34.3500090532238],
              [79.002209293197723, 34.310545041306796],
              [79.010272348798878, 34.289436056290867],
              [79.012082336975595, 34.25790903615092],
              [79.001236154236324, 34.224573201300643],
              [78.978736108565442, 34.197491104427172],
              [78.950272338768627, 34.175827069781292],
              [78.898036150395228, 34.145964192702365],
              [78.854009198014694, 34.130409056745862],
              [78.816082259506345, 34.12027315648379],
              [78.79581817033278, 34.116391161836304],
              [78.774563340201098, 34.109718161061551],
              [78.751236168322521, 34.094153972649508],
              [78.735809275484996, 34.068327149599043],
              [78.732754071769023, 34.038609111807375],
              [78.746654117119874, 33.99638208931998],
              [78.763318178869554, 33.95860015773701],
              [78.80720917678002, 33.837209077021384],
              [78.811927182452564, 33.817218070253944],
              [78.81693620780436, 33.769991074870347],
              [78.817763334011886, 33.74971810087942],
              [78.814991270986013, 33.703609083744539],
              [78.810391282510409, 33.657773149015554],
              [78.805672271009541, 33.616382137553217],
              [78.806718164890526, 33.54901812264842],
              [78.813863402091556, 33.520409178288105],
              [78.834300158471024, 33.482209157373575],
              [78.854427119708248, 33.460409168257854],
              [78.899018174173989, 33.427627041932851],
              [78.936509253715997, 33.408000145040603],
              [78.946227232284741, 33.384854022271043],
              [78.994772365765414, 33.317700057860463],
              [79.101091275786928, 33.253327041293232],
              [79.148882205617724, 33.235545001294895],
              [79.183872292883507, 33.225818137908732],
              [79.188582251928892, 33.222209058029307],
              [79.223309147433838, 33.203609111916933],
              [79.251372262257973, 33.194291117768785],
              [79.274700272326868, 33.189427099342481],
              [79.375118154858285, 33.099435969114325],
              [79.366091180389162, 33.061100161367904],
              [79.356582246486312, 32.968400167664129],
              [79.373863383949413, 32.937209094204263],
              [79.398036122222663, 32.921664184169856],
              [79.415263280229112, 32.910273177722445],
              [79.448182199214273, 32.884300174280042],
              [79.528736144630727, 32.756664240405428],
              [79.530272379849237, 32.728045070123073],
              [79.515409253820621, 32.674582106397679],
              [79.502491232248758, 32.654709116827235],
              [79.490536123716254, 32.637773146137931],
              [79.476382274336629, 32.62165407573417],
              [79.452763244588596, 32.599018075593392],
              [79.302272375261396, 32.495618079541941],
              [79.253172359789119, 32.516000185912617],
              [79.230818159233763, 32.511935968689443],
              [79.208600248424659, 32.505827070000123],
              [79.172618246734487, 32.495273080406207],
              [79.139572258096678, 32.479854066557721],
              [79.122818174706708, 32.457835980320851],
              [79.078045232941633, 32.381382126673032],
              [78.971100194751273, 32.350827072028039],
              [78.922618260820684, 32.364854019393249],
              [78.802763247685277, 32.452764258333218],
              [78.78554514213431, 32.471791178594742],
              [78.774427218093194, 32.489991140286463],
              [78.765000259203589, 32.546109155669342],
              [78.775409241871955, 32.598327071493713],
              [78.769091298516798, 32.625753999864855],
              [78.746227142970838, 32.639018026401004],
              [78.705545240519086, 32.629227125274326],
              [78.682618220699396, 32.599018075593392],
              [78.663872261832921, 32.585545004391577],
              [78.638600320774344, 32.57777313610751],
              [78.572209277192826, 32.566664096883869],
              [78.536100205850232, 32.571935978719694],
              [78.49943625251592, 32.582635981067313],
              [78.405954227243569, 32.556100049006147],
              [78.397072259699712, 32.534573142296722],
              [78.406327221935953, 32.519244988278842],
              [78.416645177135706, 32.510273166732873],
              [78.47234510318691, 32.432353988767886],
              [78.486927267820022, 32.251591205485738],
              [78.475936245793491, 32.243045016984127],
              [78.538036257851104, 32.214436072623812],
              [78.561382205193013, 32.189718007728459],
              [78.579300199661077, 32.161936021937777],
              [78.597491276535607, 32.124853979271791],
              [78.656936236935962, 32.059718041875499],
              [78.687482239125643, 32.030682123366091],
              [78.731654198431386, 32.006526987089671],
              [78.770754100473368, 31.968473146566737],
              [78.761654203446483, 31.939991104220951],
              [78.752782294186488, 31.921382105653038],
              [78.745072284348055, 31.910364093898181],
              [78.736100295164135, 31.88971812521487],
              [78.692891248897809, 31.779718050946542],
              [78.715818268717726, 31.726382156873512],
              [78.764363402198228, 31.675064122176423],
              [78.842482237097556, 31.607500115061328],
              [78.77234515333862, 31.552845077063012],
              [78.721927167406989, 31.52527314176632],
              [78.712491323700078, 31.502709058355208],
              [78.768245229207935, 31.308953989838699],
              [78.817491257434085, 31.287909042563001],
              [78.87765421166273, 31.291627087183997],
              [79.018527182345338, 31.409791135167893],
              [79.044145128368029, 31.426935982885254],
              [79.080818134157994, 31.437291153735089],
              [79.164154200884127, 31.39527317591336],
              [79.220600283958277, 31.325973109007748],
              [79.21790030529985, 31.300973076888795],
              [79.241791243987507, 31.241664238596172],
              [79.314427140728242, 31.13458207246947],
              [79.339709307708972, 31.12055395163776],
              [79.393245193992357, 31.101064182681682],
              [79.419436126917731, 31.052218138876242],
              [79.554291226379632, 30.957082196464626],
              [79.609982267613475, 30.938464145441287],
              [79.632482313284356, 30.956518094379419],
              [79.660545092832365, 30.973609130277964],
              [79.745263335396089, 30.989300053066245],
              [79.863036116138801, 30.965827036071914],
              [79.886036226154431, 30.949782061692588],
              [79.898036261688048, 30.919164142773582],
              [79.929700242126387, 30.887909031573429],
              [79.949809266090824, 30.884900095963076],
              [79.960818225390199, 30.88444495861944],
              [80.188591248039359, 30.79513611531172],
              [80.254227249780627, 30.733745044626573],
              [80.224018200099692, 30.597773140382287],
              [80.207000254396917, 30.57551801392276],
              [80.232754154889619, 30.574164168918287],
              [80.289427219902279, 30.570826997978543],
              [80.316227181913973, 30.566109159944119],
              [80.40279128720033, 30.52637323908732],
              [80.521382309333376, 30.477218070691777],
              [80.585400268481067, 30.476245099368327],
              [80.611791193616966, 30.472082143325679],
              [80.758600228144815, 30.385618117963645],
              [80.77388228169508, 30.360000171940783],
              [80.790954206854508, 30.337082204576575],
              [80.808727194397363, 30.324164183004484],
              [80.902000175004048, 30.285900124349382],
              [80.947763354813048, 30.274853949399699],
              [80.993518152718707, 30.263953954841952],
              [81.03012712076827, 30.213327091882661],
              [81.025363349904126, 30.20435409687019],
              [80.943036129046845, 30.181109067643035],
              [80.874145099017426, 30.140973162365526],
              [80.744009178694398, 30.019582081650128],
              [80.67886335065208, 29.97027318914985],
              [80.659427225876698, 29.963327105968858],
              [80.625536173758576, 29.962909016637354],
              [80.595463246185489, 29.949644990101206],
              [80.574154101321199, 29.910000096713304],
              [80.537282276787323, 29.844645056367199],
              [80.482827230999192, 29.798264130292466],
              [80.443309239625705, 29.796527064673455],
              [80.415818273513992, 29.78569110785611],
              [80.375009301409648, 29.740200172262917],
              [80.384154125437817, 29.681245050285384],
              [80.396518270846343, 29.660827069369248],
              [80.416582200171376, 29.640000051576692],
              [80.408463321095155, 29.601382108968551],
              [80.346100284914883, 29.521391092170802],
              [80.31303619136628, 29.498600194459016],
              [80.290263398565457, 29.483053943319931],
              [80.238027210192058, 29.422982184198247],
              [80.285672294907158, 29.320209154506017],
              [80.306163366019405, 29.308882185799234],
              [80.301927152142639, 29.278609098377842],
              [80.287763412117215, 29.205553938839103],
              [80.249145134233061, 29.163609051213314],
              [80.196927218408632, 29.128327106078245],
              [80.069718258682713, 28.949436001780498],
              [80.057618143224829, 28.924300182829825],
              [80.052200248635131, 28.898327011749473],
              [80.061372230029633, 28.82992699360932],
              [80.15081820127358, 28.793609045238895],
              [80.250263280119839, 28.747500028103786],
              [80.31665415606318, 28.692909027845928],
              [80.387500181194753, 28.625000189233049],
              [80.472209203664818, 28.590000043683801],
              [80.510818261455711, 28.621109142130194],
              [80.505900263572812, 28.655273109016491],
              [80.519009224899719, 28.671382121136276],
              [80.541363257816897, 28.685273114031759],
              [80.584991231605045, 28.653609133593591],
              [80.70470928444206, 28.578609037236561],
              [80.930254229582829, 28.445544982850095],
              [81.03137233630747, 28.403045045594411],
              [81.094145247553911, 28.393327067025723],
              [81.139163276168688, 28.382500162663689],
              [81.166927157048519, 28.375273118087534],
              [81.188872320727512, 28.369164219398272],
              [81.19359133222855, 28.366664232950143],
              [81.222491296267833, 28.324718004219903],
              [81.298036216332832, 28.163882189964454],
              [81.374418153251156, 28.166109094006401],
              [81.478763292707129, 28.084373132600831],
              [81.607482336113435, 28.018891190240311],
              [81.726927138906376, 27.955827091676611],
              [81.780818250247307, 27.910827000334848],
              [81.901054137063909, 27.854927082073289],
              [81.936918289195035, 27.887500163732398],
              [81.969500255671676, 27.913054072014802],
              [81.999709305352667, 27.922218006782089],
              [82.04331816840147, 27.91860909454067],
              [82.066400253430402, 27.914153945352439],
              [82.148882204220683, 27.864164107036345],
              [82.278318235626784, 27.77805396562735],
              [82.304291239069016, 27.759164173302338],
              [82.406100182255102, 27.690827019436313],
              [82.42942718649573, 27.677500128626207],
              [82.45207223909199, 27.672218020868286],
              [82.478863316286436, 27.672773238136188],
              [82.568054142397045, 27.685273170376604],
              [82.598036210139526, 27.690273143272918],
              [82.617200258337107, 27.696944970581114],
              [82.657909150517355, 27.707636088111258],
              [82.701100259510667, 27.711109045882779],
              [82.717563323221725, 27.690409097742815],
              [82.732482272725406, 27.63943606218146],
              [82.746927141783999, 27.581664129659487],
              [82.743663396316492, 27.555900170882978],
              [82.766518164130787, 27.503473210392713],
              [82.968045100931306, 27.461109059968976],
              [83.028736115061349, 27.449582099051781],
              [83.057482354995898, 27.448326992866782],
              [83.13053617343013, 27.449436086298078],
              [83.176582326291282, 27.447009022407769],
              [83.251791299675801, 27.390000178352921],
              [83.261100241368524, 27.371244999393355],
              [83.27346338094884, 27.344718119787728],
              [83.309972269073967, 27.336245021482057],
              [83.342354075702303, 27.342491048107718],
              [83.358872292336287, 27.356109126234799],
              [83.376645112240908, 27.375273174432721],
              [83.394009230545919, 27.411173201109477],
              [83.41831825856508, 27.472773148822341],
              [83.540545182667699, 27.473045057762135],
              [83.607754132363254, 27.469164236581094],
              [83.633882201014671, 27.459717999314023],
              [83.748027126414797, 27.408609176920947],
              [83.777482307721982, 27.394718016387628],
              [83.801654207805143, 27.383054095172099],
              [83.826100196122695, 27.371109044923401],
              [83.858309167906782, 27.352227131587256],
              [83.872209213257747, 27.385273120225065],
              [83.871372364042259, 27.440273157359115],
              [83.92249124471914, 27.451773128547927],
              [83.935672290413493, 27.447218067073607],
              [84.019145149800067, 27.443053937564343],
              [84.051372394133296, 27.452982134265369],
              [84.147218283745644, 27.511391091430482],
              [84.29776330016773, 27.388326982836361],
              [84.329709247829499, 27.377773160880622],
              [84.394991197979664, 27.367500132682039],
              [84.499418144810932, 27.356382208641037],
              [84.598391322507098, 27.340000114114957],
              [84.638045100712475, 27.311109034893036],
              [84.653182314975311, 27.291527065001901],
              [84.680263406020515, 27.232218059071101],
              [84.68511820435333, 27.206245055628926],
              [84.679836264233529, 27.13569105600476],
              [84.660263346797763, 27.094164090072468],
              [84.646236231794489, 27.061382131385528],
              [84.655263373901562, 27.040345063099039],
              [84.70749118037179, 27.021109098171792],
              [84.753463404846457, 27.007364118030182],
              [84.781100216073924, 27.011391119603104],
              [84.802200316272547, 27.01527311425059],
              [84.854982334182125, 26.995544963967575],
              [84.967754136200369, 26.935827088798817],
              [85.185527213057071, 26.798054081195289],
              [85.201163318198212, 26.775064197101415],
              [85.221927136440627, 26.763053935645871],
              [85.310527206013887, 26.739436079364339],
              [85.328045216061469, 26.736109134346691],
              [85.337345105298652, 26.736936092916267],
              [85.453045209018228, 26.786945041971649],
              [85.508045246152506, 26.813882131919968],
              [85.540263270392074, 26.829718061633457],
              [85.568600305812652, 26.84513606965362],
              [85.63075412968891, 26.865973145729924],
              [85.710536101820765, 26.816109035961745],
              [85.730191329546017, 26.787082169908047],
              [85.726927248802383, 26.733609147898619],
              [85.724991196801511, 26.704991151082766],
              [85.72887235325851, 26.673609137868198],
              [85.75345412840818, 26.642773121828043],
              [85.86047225679431, 26.572845082734773],
              [85.950609232138049, 26.627709165398983],
              [85.965818195492545, 26.648054056119349],
              [86.032909295628997, 26.663191102744236],
              [86.063872381321687, 26.653954077781009],
              [86.089427127794409, 26.633053969792911],
              [86.104991316206281, 26.618609100733977],
              [86.138318266239196, 26.603473227575762],
              [86.183863348927048, 26.608053937673731],
              [86.204782232378477, 26.587636124395601],
              [86.334154226043722, 26.607773143916631],
              [86.475536145888668, 26.54610915846331],
              [86.659991325167937, 26.456664193047516],
              [86.733382263748922, 26.420200064285552],
              [86.820954209075637, 26.436736050554202],
              [86.891582304724238, 26.477054010769223],
              [86.900682201751067, 26.500409010566273],
              [86.948027214331717, 26.530927017199133],
              [86.965182287970833, 26.52582713201673],
              [87.004436249393507, 26.534445069609774],
              [87.084427266191256, 26.486382063201006],
              [87.169982357970525, 26.400000180490395],
              [87.269718288857575, 26.375273230777566],
              [87.368736225916678, 26.401527027977068],
              [87.399291280561727, 26.418191089726747],
              [87.455127161082828, 26.43360909774691],
              [87.485818170197547, 26.434018134622931],
              [87.530272264365294, 26.418327044196644],
              [87.553591221978536, 26.405827111956285],
              [87.643045239849641, 26.39249116869064],
              [87.650545199194028, 26.399718045628788],
              [87.686782346017537, 26.425273127377466],
              [87.791363352229723, 26.458882212272158],
              [87.894154151556563, 26.469300079758],
              [87.908036259634741, 26.446382112393621],
              [87.927200307832379, 26.423327017092788],
              [87.947472275994983, 26.402500166938466],
              [88.020191321215407, 26.368364195608876],
              [88.090682288927439, 26.433191176053413],
              [88.107418267406615, 26.488809127091315],
              [88.101927282620977, 26.530282113567125],
              [88.121372292214062, 26.566109050047942],
              [88.172763417106921, 26.665691089192308],
              [88.179700280194027, 26.685273226721733],
              [88.191782290741116, 26.725964181628967],
              [88.194554186129153, 26.760417994727845],
              [88.17428221796655, 26.876800050091788],
              [88.118591344370657, 26.984436092381941],
              [88.072972333296718, 27.030973089494182],
              [88.035263324271511, 27.049300120838609],
              [87.994982244430616, 27.112291129206426],
              [88.010818174144106, 27.151391198886529],
              [88.009991215574587, 27.194164218548337],
              [88.014018217147509, 27.22360917393371],
              [88.03470928046994, 27.283609016325954],
              [88.047345167180424, 27.496454037016008],
              [88.056927191279215, 27.533336087471469],
              [88.093382267585866, 27.587153940978624],
              [88.120672403296908, 27.611936043614435],
              [88.148882201426886, 27.680273197480574],
              [88.187336194008367, 27.782636016830168],
              [88.188309165331589, 27.849227052621814],
              [88.152482228850602, 27.864164107036345],
              [88.14279124001024, 27.866054058569716],
              [88.132063409744148, 27.87846413680802],
              [88.122063296313684, 27.907009043427763],
              [88.138118161338866, 27.949373193851557],
              [88.331372327320253, 27.979718030364268],
              [88.390827178366408, 27.983609077467236],
              [88.509154170548641, 28.04055405141969],
              [88.553463257790781, 28.068473165146713],
              [88.624354209923524, 28.116800033868287],
              [88.740536105439105, 28.069718045409957],
              [88.773318231763881, 28.05110904684193],
              [88.835754190501973, 28.008054059956464],
              [88.873027172922832, 27.895545114422589],
              [88.877200187249457, 27.875553940017255],
              [88.881091234352255, 27.839582164248895],
              [88.854991328895665, 27.724027067454642],
              [88.830545172940106, 27.654582161261828],
              [88.813309297754188, 27.632218070060674],
              [88.801372294132534, 27.615827090717289],
              [88.764636256430549, 27.542427099681163],
              [88.781245165257133, 27.443609154832245],
              [88.79970915689978, 27.416382218671288],
              [88.823318296002014, 27.386664180879791],
              [88.895827123090157, 27.333327113340431],
              [88.916927223288781, 27.320827013461894],
              [88.917463329817338, 27.3173822188852],
              [88.911236246292958, 27.294027051449973],
              [88.89096327230186, 27.276945068006739],
              [88.842763305594872, 27.265691189495897],
              [88.810745105927481, 27.254718104741983],
              [88.76220919254024, 27.184436014057667],
              [88.751936164341828, 27.14860907757685],
              [88.753109295513582, 27.148064253868768],
              [88.79248227996186, 27.14235399849548],
              [88.826100249673686, 27.121109059009655],
              [88.861718141488836, 27.09374499491247],
              [88.869418260681584, 27.067354069776798],
              [88.893872295626124, 26.975553957200134],
              [88.947754186873567, 26.969436006055332],
              [89.038182349534452, 26.922491145533343],
              [89.084572328064667, 26.885618147533194],
              [89.09331817350045, 26.858327005993829],
              [89.106309285268253, 26.832564220683594],
              [89.126082362552381, 26.823609162944138],
              [89.149427136427846, 26.818882104815941],
              [89.200000187568492, 26.813882131919968],
              [89.22082720536082, 26.814718142944969],
              [89.276172241630661, 26.831527043981907],
              [89.299982211133511, 26.850827046649442],
              [89.360809179733451, 26.860000033872382],
              [89.367200213284548, 26.859436099425125],
              [89.481791223572827, 26.800064229220425],
              [89.526236265284865, 26.811664112695496],
              [89.563182353481011, 26.802500177928209],
              [89.588736261763358, 26.792354051744041],
              [89.642691243206571, 26.762636013952317],
              [89.643872253367363, 26.752500113690132],
              [89.622827138453658, 26.725136049593004],
              [89.643054179615149, 26.715273231736887],
              [89.769854102464933, 26.703609142883352],
              [89.793172389526148, 26.706718158417971],
              [89.805818166882261, 26.707218055124713],
              [89.854109161058034, 26.709482174816799],
              [90.081936163163675, 26.739164170424715],
              [90.123600257032138, 26.748054016957383],
              [90.149018210844815, 26.755418021831701],
              [90.176163339630477, 26.773882181112356],
              [90.183172287085256, 26.813682139709613],
              [90.196091314485614, 26.834164158366377],
              [90.216936269550956, 26.850827046649442],
              [90.362900240598492, 26.89985397192612],
              [90.388736116104496, 26.903464225271819],
              [90.417209273632977, 26.894436077336422],
              [90.457136134244649, 26.857982174496101],
              [90.518872371703537, 26.824718088737313],
              [90.564145210175411, 26.805136118846008],
              [90.641372378212282, 26.782500118705229],
              [90.688591326969004, 26.773191177012563],
              [90.708600271009118, 26.772500172912942],
              [90.744291253020208, 26.777218010947479],
              [90.902482241540042, 26.784718137929872],
              [90.936918285004253, 26.784718137929872],
              [90.957491331129575, 26.784445055523577],
              [90.966627270340268, 26.783426989561008],
              [91.049709197761615, 26.788891152256326],
              [91.119709153584324, 26.809164126247424],
              [91.231227190521849, 26.806245044639184],
              [91.263045230341049, 26.795000050945646],
              [91.284854104274132, 26.779853951865448],
              [91.307345097489531, 26.77805402197248],
              [91.343036247138627, 26.786391165808254],
              [91.375536238601967, 26.80416415335111],
              [91.39012728805244, 26.821945019883117],
              [91.401091320350645, 26.848327060201541],
              [91.417272249199925, 26.871245027565919],
              [91.501272330297752, 26.867254068176635],
              [91.516936263357707, 26.839164131262351],
              [91.54282712414863, 26.805273079144229],
              [91.71554512437865, 26.800973162803189],
              [91.742336201573039, 26.819854070310896],
              [91.838245123097522, 26.863264114615845],
              [91.99178231132322, 26.853744954791011],
              [92.031236264956647, 26.851935972442732],
              [92.064427260519437, 26.859718066648611],
              [92.081509243962671, 26.869045113252241],
              [92.092063401194594, 26.878327065216638],
              [92.103045203127635, 26.895545003129598],
              [92.113172386210522, 26.930273072101215],
              [92.100818299085859, 26.979164210546003],
              [92.086654223784251, 27.014718064620695],
              [92.071372337871935, 27.037082155821679],
              [92.045391287802772, 27.055136104759811],
              [92.017772246210143, 27.079300125853763],
              [92.009991325470764, 27.10152708911852],
              [92.015272259762071, 27.168054087169764],
              [92.04415411889056, 27.188327061160692],
              [92.114218280091734, 27.293054080126581],
              [92.089154210232323, 27.349445010277577],
              [92.078045171008682, 27.368609058475329],
              [91.993663378039344, 27.47558209222251],
              [91.958318233354134, 27.454436059194279],
              [91.853591214388246, 27.436664245117868],
              [91.77082712873613, 27.431664104583547],
              [91.675818255977163, 27.487082063411151],
              [91.631363323619439, 27.536382071093954],
              [91.617482221369926, 27.560827053583182],
              [91.600254225173046, 27.615409169023906],
              [91.598945139531338, 27.660064093591927],
              [91.620400297149558, 27.680964201580252],
              [91.642209171082641, 27.697082098517342],
              [91.654436188554882, 27.716664236046654],
              [91.659145141772115, 27.741245005367773],
              [91.658872394642003, 27.763336014162462],
              [91.657763301210707, 27.764718022361876],
              [91.824418167942667, 27.766391050240429],
              [91.884718250107483, 27.731109105105531],
              [91.978318292577114, 27.728609118657289],
              [92.014427196281531, 27.734718017346779],
              [92.046354200842188, 27.750273153303169],
              [92.074436258767435, 27.786664191869534],
              [92.118318204222419, 27.812500067375538],
              [92.146100190013215, 27.818326998841201],
              [92.190245159590461, 27.812500067375538],
              [92.257345144544615, 27.819718059496154],
              [92.265536107988453, 27.868400153275104],
              [92.314700328839535, 27.796109088031926],
              [92.331100193000452, 27.794718027377201],
              [92.341372383008547, 27.806391168686048],
              [92.452472330614739, 27.82639106027068],
              [92.544982222754129, 27.861936029528238],
              [92.643727245044943, 27.916245062562496],
              [92.699418286278842, 27.955544956814833],
              [92.719572404882342, 27.979027026264589],
              [92.720772358144472, 28.041144975595216],
              [92.686527254435248, 28.060164184505595],
              [92.675245212729578, 28.080709067436317],
              [92.68714516833893, 28.114764237219333],
              [92.710445182851231, 28.141900145911336],
              [92.752900193105575, 28.168918037406399],
              [92.830036166035654, 28.212764108315866],
              [92.852872326024794, 28.200091173593307],
              [92.887336197407649, 28.198718050211198],
              [92.929872344485148, 28.232564175328321],
              [93.045709240864994, 28.310264250343664],
              [93.126245249008662, 28.316536093231207],
              [93.222054090609049, 28.319300109630035],
              [93.237663373660212, 28.343082083576292],
              [93.238163270367011, 28.387444982636737],
              [93.215536155043537, 28.418318046689137],
              [93.281518161748863, 28.492964091455178],
              [93.320391249490513, 28.540473221700495],
              [93.328663349757335, 28.595018121490909],
              [93.351936206903133, 28.618764220891194],
              [93.390445184769703, 28.635554011188788],
              [93.440482297019912, 28.655691198347995],
              [93.502063301631438, 28.679164215342269],
              [93.525000212097154, 28.675591177646538],
              [93.544036184814047, 28.665227121979342],
              [93.595209212585587, 28.653609133593591],
              [93.647291341578239, 28.647464192720292],
              [93.689627161169369, 28.664226993289617],
              [93.731936158670067, 28.677600152205343],
              [93.858509267219119, 28.69038221930748],
              [93.892936258227849, 28.68673609141598],
              [93.961727208333173, 28.669200144095612],
              [93.985363337163733, 28.727064109914636],
              [94.009291323863636, 28.808809123775688],
              [94.02110025200443, 28.836027007481277],
              [94.094554222497322, 28.893554020791782],
              [94.152454230500041, 28.930700101198056],
              [94.18744515595597, 28.974827133502686],
              [94.191672317377254, 29.008927062648638],
              [94.234554134142627, 29.07348213415338],
              [94.308145232571917, 29.141236075195025],
              [94.427754153029184, 29.215509125268454],
              [94.481436219704278, 29.218564161346251],
              [94.538854100635376, 29.214754083428204],
              [94.581200313786326, 29.231527109919512],
              [94.593172353763293, 29.272400119764256],
              [94.647509214716422, 29.333464128586513],
              [94.697354213745086, 29.336527043653518],
              [94.792263341856199, 29.260800068651179],
              [94.864082338311221, 29.174991172843249],
              [94.982454089856446, 29.140054059205738],
              [95.129972401033115, 29.117500034078262],
              [95.354009274150371, 29.042218138135752],
              [95.387772250787577, 29.035273228421374],
              [95.439145103131636, 29.041382127110694],
              [95.461436271774687, 29.054091104017118],
              [95.525272343622987, 29.136109032646289],
              [95.541363418469928, 29.191109069780339],
              [95.624691270931066, 29.255000126913785],
              [95.789700267150977, 29.342773237917356],
              [95.928318170597038, 29.39138207386236],
              [95.948872273621419, 29.395826997128779],
              [96.01436326843708, 29.432909039794822],
              [96.052345192230405, 29.461245069386848],
              [96.083145166087093, 29.464436059934599],
              [96.147763269503798, 29.395545029905009],
              [96.180682356126965, 29.351454039784187],
              [96.186854119090412, 29.312909019733979],
              [96.213318302060259, 29.27721803772306],
              [96.233182239174994, 29.255973098237007],
              [96.261936190460574, 29.243745074936442],
              [96.292482360288261, 29.242354014281489],
              [96.341663344945687, 29.273891092705298],
              [96.358454141071775, 29.286245012191955],
              [96.391727279286044, 29.257564151102315],
              [96.34887228461082, 29.181382206394176],
              [96.317491277224747, 29.170827043333929],
              [96.287491272209422, 29.167218131092568],
              [96.25874519991288, 29.164509099978773],
              [96.21887231875786, 29.147218071869759],
              [96.148882253580723, 29.059718043272483],
              [96.149709212150469, 28.936800115070241],
              [96.175327158173445, 28.901382215465446],
              [96.223309195397064, 28.921935983213473],
              [96.250272269245215, 28.940827116643106],
              [96.268327224011841, 28.959436115210849],
              [96.282209164451672, 28.974991083529403],
              [96.297209250778337, 28.992773123527741],
              [96.318600203017695, 29.015691090892119],
              [96.347136224820474, 29.033264253862626],
              [96.470827132135639, 29.05666418066096],
              [96.495818279437287, 29.036109071808255],
              [96.484418220534508, 28.968327135210018],
              [96.533872287598058, 28.896382074931068],
              [96.601936191419895, 28.813891071685305],
              [96.613727182287846, 28.795691109993413],
              [96.615427199894725, 28.760727006627803],
              [96.430263414518947, 28.590691047783594],
              [96.39540022926775, 28.568464252156787],
              [96.340272284290734, 28.525000060757236],
              [96.361645131619525, 28.392773190862272],
              [96.401927217288744, 28.351109096993639],
              [96.45115413477555, 28.416109079920204],
              [96.598727263599187, 28.468464123680974],
              [96.653872307658844, 28.467491152357525],
              [96.675263259898429, 28.458327049952118],
              [96.744709171919737, 28.422909150347323],
              [96.757491239021761, 28.406109134127689],
              [96.778663423588, 28.376800133212384],
              [96.814700242925142, 28.366664232950143],
              [96.984709212041025, 28.332500098425726],
              [97.013882258486603, 28.333882106625026],
              [97.053245184651075, 28.35805400670813],
              [97.098736120244098, 28.380273090983735],
              [97.128182249095971, 28.381936060578425],
              [97.149154106175729, 28.373054093034625],
              [97.237900188502522, 28.309027081431623],
              [97.248027203947458, 28.286664163696969],
              [97.307136217667676, 28.234227144922897],
              [97.348872395903982, 28.222773106563523],
              [97.334427191569091, 28.166109094006401],
              [97.316854196236591, 28.087500085408294],
              [97.359982273317939, 28.043609087497657],
              [97.380536208704143, 27.991382119217803],
              [97.361100251566597, 27.940827005349945],
              [97.120254142136275, 27.791944958522834],
              [97.046645106434312, 27.749718103673274],
              [97.00387225441051, 27.717773161839943],
              [96.932209161355047, 27.662218075075998],
              [96.890272320356075, 27.619718137820257],
              [96.886382279081545, 27.599854033067402],
              [96.9035913321772, 27.457427057531788],
              [96.968872276499042, 27.366109072027086],
              [97.001654235186038, 27.328609107667717],
              [97.031100196399734, 27.302773232161712],
              [97.044982304477571, 27.286945013799254],
              [97.159009212680814, 27.128053968724146],
              [97.135409293672126, 27.087218006891476],
              [97.059718193215417, 27.093000178994203],
              [96.998318237712738, 27.133882073656309],
              [96.960536138491591, 27.147218016921727],
              [96.905127232119526, 27.168882219205784],
              [96.882063419639394, 27.182773212101097],
              [96.86390922313899, 27.212427044514129],
              [96.855254237533984, 27.26277311371615],
              [96.821518250624877, 27.321527070016998],
              [96.784145188279922, 27.346945023829335],
              [96.763609190166733, 27.355826991373192],
              [96.725891296323994, 27.365691150333589],
              [96.621918145731911, 27.365827104803657],
              [96.496645238006806, 27.302218014893867],
              [96.426227192852366, 27.304645078784176],
              [96.228318220748747, 27.276109056981738],
              [96.191227293265399, 27.269864203822522],
              [96.154163355510377, 27.256109165396936],
              [96.098172409779863, 27.232082104601204],
              [96.029563346974101, 27.187491050135634],
              [96.011382328383547, 27.169027058492986],
              [95.993591235929898, 27.136664194965988],
              [95.981654232308244, 27.110273102192309],
              [95.96192725549156, 27.080273097176985],
              [95.936091212347549, 27.051664152816784],
              [95.912200273659892, 27.030827076740479],
              [95.873727170338952, 27.012918134727727],
              [95.828463384322561, 27.008191076599871],
              [95.806091246494589, 27.002636054073662],
              [95.671100192562619, 26.898327124439277],
              [95.60612719936438, 26.823082108871063],
              [95.546645190951722, 26.826382064160384],
              [95.525827225614876, 26.824718088737313],
              [95.507491309452917, 26.812909160596519],
              [95.493591264102292, 26.791382086249087],
              [95.477336239228805, 26.752353933298252],
              [95.445400182212666, 26.71235398249064],
              [95.421100206648816, 26.698609169987151],
              [95.38831824796199, 26.684718009453832],
              [95.261591247670026, 26.649853986012317],
              [95.253600276436487, 26.679436069334088],
              [95.238172377770525, 26.676245078786224],
              [95.144982210367402, 26.616173152026477],
              [95.06589124233534, 26.475482068643089],
              [95.066727253360398, 26.31166414086789],
              [95.080272408929659, 26.234300180170692],
              [95.113309177473752, 26.138327053267744],
              [95.175082295306765, 26.066109078220393],
              [95.164982269590382, 26.0368091297604],
              [95.134991317030597, 26.001945106318999],
              [95.117754100740171, 25.983327055295661],
              [95.092627166606803, 25.960554094856661],
              [95.067272244706317, 25.946173095900178],
              [95.028309302962555, 25.825191052060745],
              [94.996372407756269, 25.732218143588682],
              [94.8852722925119, 25.578609038633374],
              [94.793727157430908, 25.499854017281507],
              [94.768463262999148, 25.49151804691229],
              [94.740054143210955, 25.494782127655753],
              [94.687000216361525, 25.465900100889257],
              [94.669436273484735, 25.447491094531756],
              [94.661654179278685, 25.428045079110348],
              [94.628591259196583, 25.401873089286084],
              [94.582500179334488, 25.288882184318425],
              [94.576236215435813, 25.233609064778022],
              [94.578472339571249, 25.209164249926971],
              [94.594154209904048, 25.184164217808018],
              [94.616654087936752, 25.170544966214322],
              [94.663036187478156, 25.163882191361438],
              [94.686500319654954, 25.15527313858604],
              [94.721372389723513, 25.140000137491256],
              [94.734009282262093, 25.123053940879799],
              [94.735182245795897, 25.032009037781563],
              [94.668591210004024, 24.867773188312867],
              [94.618318230998, 24.760273100492611],
              [94.597763289783472, 24.7252731225812],
              [94.500536230161799, 24.603191205403959],
              [94.472209253025028, 24.581664131056527],
              [94.359145257861428, 24.371664095950166],
              [94.313872251751548, 24.28582703694741],
              [94.270263388702688, 24.179436042558507],
              [94.242482241102152, 24.080827142375597],
              [94.219982363069448, 24.010827018914654],
              [94.147763382193943, 23.85152710460153],
              [94.134763385608608, 23.843727073122864],
              [94.094291198374663, 23.886244947651221],
              [94.031518287128051, 23.919718078076016],
              [93.910191244153111, 23.95575405922267],
              [93.858600294687903, 23.942218123746898],
              [93.822627177815207, 23.935136086096179],
              [93.800536169020518, 23.948882072066056],
              [93.784291202430836, 23.976664225494744],
              [93.766145220195426, 24.000273196958915],
              [93.689682314092124, 24.015273115647403],
              [93.628582263086116, 24.016517995910476],
              [93.61027233082433, 23.993818125667417],
              [93.593463262149271, 23.971244989800596],
              [93.52916333577798, 23.964164125616321],
              [93.500118197175084, 23.962353969801427],
              [93.473591317569458, 23.970973080860972],
              [93.421472308202851, 24.043718109981228],
              [93.407572262852057, 24.074536021110418],
              [93.387818296307216, 24.087000078805318],
              [93.36737231983426, 24.091000090649914],
              [93.337518159934689, 24.071836042452162],
              [93.32790026129004, 24.035964178970005],
              [93.329227284204535, 23.980618136871726],
              [93.35109131106077, 23.953326995332361],
              [93.384154231142873, 23.848053979192059],
              [93.414700233332383, 23.704718070073625],
              [93.422763288933538, 23.662491047586059],
              [93.425118268456401, 23.602354077257473],
              [93.399427232237656, 23.423327018489829],
              [93.371918161215092, 23.357773159399983],
              [93.38783623358006, 23.231454022517539],
              [93.349018298761678, 23.071109052513691],
              [93.30546341516947, 23.017636030504264],
              [93.27470920650444, 23.004300087238846],
              [93.247354194862623, 23.006945080612127],
              [93.226382337782866, 23.017917997727864],
              [93.209918268243541, 23.051244947760779],
              [93.184700306641389, 23.059164169902999],
              [93.139572307456717, 23.047082159355966],
              [93.126091189627914, 23.014164246199243],
              [93.139991234978595, 22.953191097207593],
              [93.117763265885685, 22.870826995976188],
              [93.102200250940086, 22.801664224644981],
              [93.092754181311193, 22.714436104987328],
              [93.097209162861475, 22.665273225240753],
              [93.119282234383491, 22.530282171308954],
              [93.141791332509683, 22.465618135062812],
              [93.176227208335774, 22.441453946330739],
              [93.189418144675926, 22.384445102276004],
              [93.192745257331751, 22.357217998476926],
              [93.196927156475681, 22.316936080445885],
              [93.197891242981768, 22.26471799698345],
              [93.155582245480787, 22.187845048175689],
              [93.129291232631317, 22.186036065827238],
              [93.105809163181505, 22.20527320422093],
              [93.071372281527147, 22.211664237772027],
              [93.046991336778376, 22.204509109925198],
              [93.016100335453075, 22.115000106769287],
              [92.990818168472515, 22.048609063187826],
              [92.959718290119554, 22.024718124500168],
              [92.924427124891167, 22.005000032500789],
              [92.885127230638716, 22.013609085276528],
              [92.875809236490682, 22.034027066192664],
              [92.862072302975889, 22.052636064760748],
              [92.798727243017083, 22.109091200290152],
              [92.706327153809525, 22.154509045687462],
              [92.695818258854956, 22.094164204159313],
              [92.675891289828087, 22.025136046193495],
              [92.605818243809438, 21.983327113037603],
              [92.600818270913237, 21.982218019606478],
              [92.584991226017053, 22.066109135962733],
              [92.595263416025375, 22.136109091785272],
              [92.596936108627744, 22.175553992962989],
              [92.578327277697895, 22.322218020565629],
              [92.568600246673668, 22.357773215744999],
              [92.524709248763031, 22.552218114894629],
              [92.530891237648461, 22.581664243746502],
              [92.510272258693476, 22.72645407779396],
              [92.477000293945537, 22.748191202635553],
              [92.375536182256837, 22.938882182618784],
              [92.352482260422676, 23.177773129308946],
              [92.349845146038206, 23.228882119339971],
              [92.365818203688093, 23.248327128933056],
              [92.387182333837529, 23.285682086367217],
              [92.370254242137037, 23.33971803699535],
              [92.35810919967804, 23.36741804777273],
              [92.32331826643258, 23.456109144814775],
              [92.299791269981654, 23.572918006689576],
              [92.289427214314287, 23.69277318746299],
              [92.278045092684522, 23.710827136401122],
              [92.268600196521959, 23.719436021538684],
              [92.203045163965498, 23.706664180358302],
              [92.174854141298795, 23.736818077116141],
              [92.109709319085027, 23.690827077178312],
              [92.067209214191337, 23.650409037039196],
              [92.032827150183891, 23.645973166228259],
              [91.977482281551886, 23.701382072600552],
              [91.958600200577905, 23.727773165374458],
              [91.938382211871954, 23.680409042054521],
              [91.943309262210164, 23.642218073595359],
              [91.957218192378491, 23.591664133193831],
              [91.963045123844381, 23.56277322161003],
              [91.966309204588015, 23.505573102524124],
              [91.922200277194406, 23.442145061723693],
              [91.883736226328779, 23.434718024937069],
              [91.844982329250854, 23.410273210086189],
              [91.797345123524622, 23.336664174383884],
              [91.783600311021019, 23.312500153290102],
              [91.770127239819203, 23.279164150801705],
              [91.768182303001026, 23.254791085041916],
              [91.780127185611718, 23.228609036933904],
              [91.795254173952571, 23.195000119677559],
              [91.804700243581635, 23.158882163517433],
              [91.818172308955127, 23.09027310071167],
              [91.778591285669506, 23.036945085627451],
              [91.724991194007544, 22.994718063139885],
              [91.61150927715039, 22.944582212070031],
              [91.56179134777426, 22.98540912144739],
              [91.548036141710668, 23.000691174997428],
              [91.49275413735279, 23.16249107575868],
              [91.474563395754387, 23.22027323420258],
              [91.447209222303286, 23.24693606827816],
              [91.426091184831648, 23.26194503942213],
              [91.402691258033258, 23.265900124265556],
              [91.37948227098974, 23.219300095241181],
              [91.381091261128063, 23.181109126782019],
              [91.390536157290455, 23.132773205605133],
              [91.404991252271202, 23.094436056754262],
              [91.399427177289624, 23.069791082054905],
              [91.374700227576795, 23.066945090642548],
              [91.35692724003394, 23.081382080712501],
              [91.344291185685449, 23.098191149387389],
              [91.333045186163645, 23.124582074523119],
              [91.319718295353709, 23.190273229187483],
              [91.316082225745959, 23.21000003836582],
              [91.313036242123474, 23.236664213546021],
              [91.299991318537195, 23.297218099739666],
              [91.282209278538858, 23.373327121889872],
              [91.254718144788967, 23.451664221548526],
              [91.209218156740405, 23.510691092617378],
              [91.161927123616238, 23.631527123703052],
              [91.156654235952146, 23.725691100619713],
              [91.166091253125728, 23.745200147953199],
              [91.192891215137422, 23.750409165515066],
              [91.214500264498326, 23.755900150300647],
              [91.251382314953844, 23.840036018230194],
              [91.239709173644997, 23.878053984207327],
              [91.237072394536881, 23.902082218469559],
              [91.268463292568754, 23.968882131288865],
              [91.38198225743804, 24.105136002756751],
              [91.420518225032765, 24.109436086735741],
              [91.478963392019693, 24.090827088167885],
              [91.520491196142302, 24.088327101719813],
              [91.546327239286427, 24.08693604106486],
              [91.568027148477938, 24.09027321200449],
              [91.593563287125505, 24.096664245555758],
              [91.751918225672, 24.154445062895036],
              [91.882572315250854, 24.151553976843559],
              [91.906372394107734, 24.176936056110435],
              [91.931300174221064, 24.282218124706219],
              [91.921200316142631, 24.327464141087773],
              [91.946636207228153, 24.349445011674561],
              [91.980672266271313, 24.320064094029817],
              [92.117200225973761, 24.390000179750075],
              [92.13276340855731, 24.409718104111107],
              [92.137500189693014, 24.437218122678303],
              [92.130263422109238, 24.49069114468756],
              [92.134991318427581, 24.518327117724482],
              [92.140827134710776, 24.52666409392242],
              [92.173872285158268, 24.546109103515334],
              [92.188309275227994, 24.562426992662779],
              [92.248600304937156, 24.742491060494274],
              [92.256654140444994, 24.773891178619863],
              [92.262636137119898, 24.80374500324308],
              [92.260545187548018, 24.826518131320427],
              [92.248182215605652, 24.851664176192912],
              [92.248382207816007, 24.894582202780327],
              [92.284572248343522, 24.906382078465583],
              [92.342482314630388, 24.88250002459506],
              [92.389927239497297, 24.84890904461156],
              [92.436545205794289, 24.84928220694195],
              [92.47470029970782, 24.866682199792592],
              [92.491627217941812, 24.877509104154399],
              [92.488182255727111, 24.917764200095519],
              [92.445545190535029, 24.959436005315013],
              [92.421672356758222, 24.966773188099182],
              [92.411227164268098, 24.993609024656735],
              [92.412491322908579, 25.013609083879544],
              [92.408872352383185, 25.025553966490236],
              [92.332491253655178, 25.079436025375628],
              [92.193036165717672, 25.140000137491256],
              [92.115263335782572, 25.170827101075929],
              [92.093600306965016, 25.177491049395314],
              [92.038882237054565, 25.187491162825609],
              [91.933045118829057, 25.183327033316516],
              [91.749418239223928, 25.167218021196732],
              [91.70276339255247, 25.151591136148852],
              [91.482272399764497, 25.138882159242655],
              [91.311372266700459, 25.182773157153065],
              [91.261791297622324, 25.206036123653234],
              [91.171582237911139, 25.195482134059318],
              [91.105545245920894, 25.196382182824777],
              [91.072627165125994, 25.192909057415477],
              [91.037491232744799, 25.183609168178123],
              [90.999709301161658, 25.172773211360777],
              [90.967209309698319, 25.161382204913536],
              [90.869982250076816, 25.150553959446995],
              [90.841091338492845, 25.148464183341446],
              [90.590818270173088, 25.16638217780968],
              [90.529563321595674, 25.174164104377553],
              [90.467209170232877, 25.161109122507241],
              [90.447482361054369, 25.150136037753498],
              [90.412491267960604, 25.148882105034943],
              [90.383327273970167, 25.154164212792921],
              [90.16914517208204, 25.21360917319322],
              [90.039154091046385, 25.260827116121447],
              [89.966091220156841, 25.295273217869521],
              [89.911991231788022, 25.308882075903227],
              [89.884500265676309, 25.293535984612504],
              [89.850536123362417, 25.288953933409857],
              [89.83305415549853, 25.306664224316762],
              [89.816300239746738, 25.366044979338824],
              [89.827491253983453, 25.431109167643854],
              [89.851382360309458, 25.515545107707794],
              [89.859982360629488, 25.576664101815197],
              [89.863309305647135, 25.605000131407394],
              [89.863036223241011, 25.64708214696941],
              [89.84610025255148, 25.691936057919463],
              [89.808036186106904, 25.822427035661917],
              [89.818054236810042, 25.852500130872954],
              [89.830554169050458, 25.874436074458629],
              [89.849018160693106, 25.906164092637255],
              [89.829991240431696, 25.999164158475821],
              [89.778872359754644, 26.088891091115215],
              [89.733936138515332, 26.156318137931919],
              [89.685527127142507, 26.159991087913568],
              [89.649300206241037, 26.189464206493597],
              [89.63419132281075, 26.230164213856369],
              [89.60171815343773, 26.227473120015418],
              [89.562100249777984, 26.152673183506693],
              [89.577582295538605, 26.126382170657052],
              [89.595263417422359, 26.099318011056369],
              [89.602418209993004, 26.068964122088175],
              [89.548218141700147, 26.015627054548645],
              [89.504354133517666, 26.005635993573662],
              [89.34277232987796, 26.017027000020903],
              [89.31990918016038, 26.02482703149974],
              [89.253009187416751, 26.065882096282053],
              [89.134972376723454, 26.153709019104099],
              [89.120809307250113, 26.178400094271126],
              [89.090436139904625, 26.29822711184994],
              [89.070736152816437, 26.385326988388499],
              [88.946691193909885, 26.442682172683419],
              [88.924827167053877, 26.424882195412295],
              [88.910482210281202, 26.392317998570661],
              [88.923363351478997, 26.357609040338275],
              [88.969100212111982, 26.338791164742759],
              [89.044009281000285, 26.27460908793023],
              [89.02048228454953, 26.235173071569989],
              [88.857145142741672, 26.240135996454057],
              [88.735763282119592, 26.293509106177396],
              [88.703982290312808, 26.333264137773313],
              [88.694136236263262, 26.391936119060745],
              [88.680818230270461, 26.420273154481322],
              [88.606936112162032, 26.470827094882679],
              [88.572772312913798, 26.465273078185078],
              [88.430327232467221, 26.554300121907374],
              [88.419909197343372, 26.586873203566483],
              [88.413072246542129, 26.626136049806689],
              [88.374982363835556, 26.580554086744883],
              [88.335591274476315, 26.483000132898496],
              [88.35658224229519, 26.454509038097171],
              [88.37157227033822, 26.485545046347681],
              [88.445527143366235, 26.472500122761403],
              [88.481682315176272, 26.460253994549646],
              [88.514436110668441, 26.406382161586009],
              [88.523063268354974, 26.367318134089658],
              [88.473600316474119, 26.357218108373104],
              [88.396382200892617, 26.325273166539603],
              [88.356718196765087, 26.282500146878021],
              [88.356436229541487, 26.249436053329248],
              [88.34852723332142, 26.220136104869255],
              [88.286100327038866, 26.195827076849923],
              [88.182891270741948, 26.150554070740043],
              [88.157763330780256, 26.097500143890613],
              [88.095536249070051, 25.915273209757743],
              [88.110536167758539, 25.835553934261668],
              [88.14401818300064, 25.785691165598109],
              [88.163382223408746, 25.780064226342574],
              [88.204709197130683, 25.796109033084122],
              [88.224991223577092, 25.807218072307592],
              [88.254018257268967, 25.814864212043688],
              [88.273736181629999, 25.806245100984313],
              [88.449500333120682, 25.659300111986397],
              [88.44720922370027, 25.616382085399152],
              [88.453882224474796, 25.589718077857185],
              [88.556636143427681, 25.50471803570781],
              [88.651654236280365, 25.489435982157545],
              [88.715536240958102, 25.488609023588197],
              [88.766663335900375, 25.517773185216072],
              [88.799709156899951, 25.510554019628955],
              [88.83262723769468, 25.480964224956551],
              [88.83999124256917, 25.460964165733571],
              [88.83700024423149, 25.426591154181608],
              [88.821100276777543, 25.404436107646347],
              [88.842900265893093, 25.364718124062335],
              [88.874009196701707, 25.332564137563168],
              [88.933800329704127, 25.307218100480156],
              [88.991654237239516, 25.308536070939169],
              [89.008663298124816, 25.290273077335371],
              [88.946018294721227, 25.169582220813083],
              [88.933045120226041, 25.164436067525003],
              [88.88888221337578, 25.175827073972243],
              [88.704991303819725, 25.195273089393652],
              [88.646654095746044, 25.197491108618124],
              [88.620254118154662, 25.198054037236886],
              [88.483036160181086, 25.199718012659901],
              [88.45422722361036, 25.1884000964086],
              [88.458454217393637, 25.152845068867407],
              [88.461382351457019, 25.074582065233187],
              [88.438036236477444, 25.023053980041936],
              [88.397218211917675, 24.93971808095381],
              [88.338872286664468, 24.868744986169645],
              [88.305545168993547, 24.874164221863794],
              [88.276927172177864, 24.888044988837351],
              [88.264018203061141, 24.912909066486407],
              [88.220063335048394, 24.950982185386636],
              [88.1411002748589, 24.916418066441508],
              [88.162345214344896, 24.889718016715904],
              [88.166791311077645, 24.848327005253509],
              [88.13665417812615, 24.820000028116738],
              [88.04387237704691, 24.685200081577818],
              [88.112627284968596, 24.521664121026106],
              [88.130400272511451, 24.50652707440122],
              [88.234418182466698, 24.466935992831623],
              [88.284427131522136, 24.450826980711838],
              [88.365536126568486, 24.411945008153054],
              [88.4626632739039, 24.340900164277627],
              [88.507209233730293, 24.318191073940909],
              [88.65220928591026, 24.294164180783355],
              [88.668727167268116, 24.310000110496844],
              [88.701909278013602, 24.306182153589987],
              [88.721645139647592, 24.280553981644971],
              [88.732754178871232, 24.261109139690063],
              [88.742082231303243, 24.241673182552688],
              [88.751727119675991, 24.216436110211248],
              [88.740000166548498, 24.187027029371507],
              [88.715682253711861, 24.180064182384342],
              [88.700409252617078, 24.148464239686632],
              [88.707336225058611, 24.121709037037647],
              [88.70095407632499, 24.082427080058153],
              [88.725818153974217, 24.060273206989336],
              [88.754609153271929, 24.040745048916676],
              [88.734709173973386, 23.918127025210708],
              [88.687236253549713, 23.892509079188073],
              [88.627618290667328, 23.873754067866287],
              [88.601800184795991, 23.858491125055366],
              [88.576872237044654, 23.766527062452326],
              [88.565963357669375, 23.646664170328052],
              [88.585127238228949, 23.604027105136197],
              [88.756863382318215, 23.469364118895442],
              [88.786336165622231, 23.492845014878583],
              [88.786372375443875, 23.450827037056854],
              [88.739154097239862, 23.350273200055597],
              [88.712491263164111, 23.310973138165252],
              [88.712827209844363, 23.272291157816653],
              [88.727136124433059, 23.247082081031863],
              [88.804836199448516, 23.227009099251291],
              [88.827836141826197, 23.242700022039571],
              [88.982800265252479, 23.206135980991291],
              [88.915818297495605, 23.123327135976069],
              [88.896936216521453, 23.108882099279356],
              [88.874836155271282, 23.092218037529847],
              [88.863036111947849, 22.996664173424733],
              [88.86310920214396, 22.968254047808387],
              [88.929291201059414, 22.869436102959469],
              [88.988254202025985, 22.401236066040099],
              [88.993318212662587, 22.324436039790271],
              [89.007218258013552, 22.276391138292354],
              [89.017909207905518, 22.257218037639234],
              [89.05790915871296, 22.205544945522774],
              [89.073154164251093, 22.192209169895364],
              [89.094082267796011, 22.143327083906058],
              [89.06526327294165, 22.115273189175412],
              [89.062999151595761, 22.115473374096496],
              [89.055278548000217, 22.110969229000148],
              [89.052828730000215, 22.104844892000131],
              [89.049360464000301, 22.096174522000183],
              [89.049360464000301, 22.078419266000196],
              [89.058237088000226, 22.048829852000097],
              [89.062413221000384, 22.04167123000019],
              [89.068594990000179, 22.031074596000281],
              [89.074911075000216, 22.01738843600009],
              [89.077471557000194, 22.011840194000229],
              [89.077121887000203, 22.007469262000086],
              [89.074512069000093, 21.974852299000247],
              [89.071553556000197, 21.937863522500038],
              [89.070439522271442, 21.93433552828327],
              [89.062676906000036, 21.90975237400022],
              [89.055710717000181, 21.902786657000206],
              [89.0478811950004, 21.894957666000153],
              [89.056757818000222, 21.853530076000084],
              [89.070074260000183, 21.813582760000088],
              [89.083388693000359, 21.782512067000141],
              [89.107062032000215, 21.758838728000114],
              [89.121695411500014, 21.745547007500193],
              [89.12730383800033, 21.740452745500249],
              [89.156228142000202, 21.602256032000241],
              [89.158586308000281, 21.591986962000192],
              [89.16978461400015, 21.545973338000124],
              [89.171524793000287, 21.538902107000069],
              [89.184581661000266, 21.483218102000194],
              [89.194421825000177, 21.44771657400014],
              [89.230757661000268, 21.295795482000187],
              [89.234506988000078, 21.279769352000244],
              [89.238305814000171, 21.263100915000166],
              [89.242054972000233, 21.246306037000181],
              [89.245754459000182, 21.229323464000231],
              [89.316350798500082, 20.898180942500119],
              [89.392126295000196, 20.679114440000205],
              [89.455000456000107, 20.498510581000119],
              [89.537495121000177, 20.260892278000142],
              [89.647402808000379, 19.944313286000067],
              [89.709877263000237, 19.763436564000244],
              [89.903338228000223, 19.203326268000239],
              [89.949027404000162, 19.069922552000321],
              [89.708948885000154, 18.565193760000113],
              [89.610330036000164, 18.357862539000166],
              [89.436020317000214, 18.1381946730001],
              [89.426345310000215, 18.129589199000065],
              [89.423009170000228, 18.125367304000065],
              [89.070441315500091, 17.810639333500148],
              [89.065829267000197, 17.806427482000061],
              [89.064109980000126, 17.804238202000079],
              [89.053825389000167, 17.795029170000191],
              [89.027688609000108, 17.761721998000155],
              [89.026422241000319, 17.760576141000115],
              [88.583001809000109, 17.361802946000012],
              [88.075715683000226, 17.041048431999968],
              [88.063269932000139, 17.035851404000027],
              [88.062484603000257, 17.035351285000104],
              [88.061211708000201, 17.034754254],
              [88.060081417000163, 17.034216475],
              [88.059228804000043, 17.033675180000046],
              [87.503020321000236, 16.800232737999977],
              [87.460941972000228, 16.790344828000229],
              [87.369127819000141, 16.75162571750019],
              [87.353433298000198, 16.745093530500185],
              [87.351364530000069, 16.744272551000165],
              [87.346130345000176, 16.743038320000096],
              [87.337384274000101, 16.73934064700012],
              [87.336053635000042, 16.739027319000115],
              [87.334928867000258, 16.7385533100001],
              [87.333571112000072, 16.738232952000033],
              [87.332466430000181, 16.737765973000023],
              [87.331646957000146, 16.737572151000165],
              [87.330003993000105, 16.736876202000076],
              [87.322912938000172, 16.735205119000057],
              [87.321056068000189, 16.734419790000175],
              [87.316855262000104, 16.733428589000141],
              [87.315246444000195, 16.732748707000042],
              [87.310755408000034, 16.731688212000165],
              [87.309540258000254, 16.731175037000071],
              [87.305354517000183, 16.730186849000177],
              [87.303738668000165, 16.729503954000052],
              [87.302387943000042, 16.729185605000112],
              [87.30127522700019, 16.728714609000065],
              [87.299915463000104, 16.728394251],
              [87.298813794000182, 16.727927271999988],
              [87.298008380000169, 16.727737467000168],
              [87.296357382000167, 16.727039509000178],
              [87.293152297500114, 16.726274767500172],
              [87.289919596000232, 16.725493456000038],
              [87.289243229000135, 16.725323235000076],
              [87.205372745000147, 16.61544652300006],
              [87.201535481000292, 16.611914553000076],
              [87.200765216000036, 16.610904271000152],
              [87.16134312600019, 16.574610440000185],
              [87.092991418333554, 16.484950752000145],
              [87.069635424000126, 16.463391038000111],
              [87.010112135333429, 16.385203976666844],
              [86.847421574000066, 16.234760992000133],
              [86.778202188000193, 16.14337264400001],
              [86.775515299500228, 16.140795219500035],
              [86.341251177000061, 15.73677126900003],
              [86.338859539500106, 15.735185548000175],
              [85.840140224000208, 15.408756985000025],
              [85.838579609500357, 15.408051494500057],
              [85.837766162000207, 15.407669375000125],
              [85.836620807000202, 15.406972923000069],
              [85.834251765500198, 15.405482606000135],
              [85.529427271000259, 15.118393282000056],
              [85.525372083000093, 15.115716938000105],
              [85.432538107500193, 15.028035300500164],
              [85.43131341700024, 15.026899486000104],
              [85.429110078000122, 15.025391595000144],
              [85.344485397000199, 14.969371655000089],
              [85.318647885000019, 14.917214588000149],
              [85.316662470000153, 14.914532219000137],
              [85.312633393000112, 14.90639673700008],
              [85.311828983000169, 14.90531113600008],
              [85.306615888000238, 14.894779497000229],
              [84.957983212000158, 14.422261737000156],
              [84.955196400500199, 14.419557776000147],
              [84.528588227000171, 14.011830127000167],
              [84.527001502000246, 14.010766620000197],
              [84.521336490500119, 14.005414936500131],
              [84.520311145000107, 14.004511106000109],
              [84.028850093000244, 13.673876716000052],
              [84.027454679000243, 13.673184783000067],
              [84.025547595000205, 13.672247811500085],
              [83.751353929000231, 13.548995408000053],
              [83.767755948500167, 13.360773130500206],
              [83.768447379, 13.352519648000055],
              [83.768360009000276, 13.351582677000067],
              [83.769877441000233, 13.334190962000037],
              [83.769484776000184, 13.329934923000053],
              [83.769766972000241, 13.326690170000063],
              [83.769461678000198, 13.323399221000159],
              [83.769680606000179, 13.320888580000201],
              [83.769281916000239, 13.316576302999977],
              [83.76957013800029, 13.313287362000096],
              [83.768440851500259, 13.300369610500127],
              [83.715117341000109, 12.721542258000056],
              [83.714593119000085, 12.719652247000113],
              [83.714235603000191, 12.715752719000022],
              [83.713525594000146, 12.713190860999987],
              [83.71337596, 12.711562961000141],
              [83.712555984500057, 12.708470353000052],
              [83.675245344000245, 12.573557025000071],
              [83.67175555200015, 12.535495702000048],
              [83.671253424000156, 12.533682015000011],
              [83.670879841000101, 12.529605738000157],
              [83.634473533000175, 12.397885443000121],
              [83.618237718000159, 12.220225433000223],
              [83.617583947000185, 12.217853379000246],
              [83.61749055100006, 12.21683506300019],
              [83.463118246000249, 11.655926659000215],
              [83.462476526000273, 11.654584973000226],
              [83.462047708000114, 11.653239269000096],
              [83.461356780000187, 11.650948560000188],
              [83.46071606400011, 11.649604865000072],
              [83.460330931500152, 11.648352054500151],
              [83.458746215000076, 11.642752822500142],
              [83.457788154000127, 11.639327805500159],
              [83.454373180000175, 11.626929756000209],
              [83.452955169000177, 11.623956153000108],
              [83.452623765000112, 11.622752048999985],
              [83.449484459000246, 11.61616914800021],
              [83.449244442, 11.615294440000127],
              [83.446347162000279, 11.609217684000214],
              [83.445748625000249, 11.607038447000093],
              [83.444822700000259, 11.60509722],
              [83.443933430500294, 11.601941845499994],
              [83.443258068000148, 11.599568787000067],
              [83.441840058000025, 11.596595183999966],
              [83.441509658000086, 11.595390076000115],
              [83.440886014000171, 11.594084542000246],
              [83.44058975900009, 11.593000950000146],
              [83.438684684000123, 11.589008026000158],
              [83.43629405150034, 11.580361376999974],
              [83.435329463000244, 11.577018709000129],
              [83.433726670000226, 11.571283902000232],
              [83.432263468000087, 11.568215899000108],
              [83.431987297000092, 11.567205616000081],
              [83.431198956000117, 11.565552610000111],
              [83.366666667000175, 11.443333333000169],
              [82.406666667000081, 11.266666667000152],
              [81.933333333000178, 11.045],
              [81.041666667000072, 10.695000000000107],
              [80.766666667000209, 10.55],
              [80.158333333000087, 10.140000000000157],
              [80.083333333000098, 10.096666667000079],
              [80.050000000000296, 10.083333333000155],
              [79.583333333000155, 9.95],
              [79.376666667000222, 9.66916666700007],
              [79.5116666670001, 9.363333333000071],
              [79.518422287000021, 9.317602984000189],
              [79.533333333000087, 9.21666666699997],
              [79.533333333000087, 9.1],
              [79.52166666700009, 9.000000000000171],
              [79.488333333000014, 8.896666667000034],
              [79.303333333000296, 8.666666667000186],
              [79.216666667000254, 8.620000000000175],
              [79.078333333000273, 8.52],
              [78.923333333000301, 8.37],
              [78.895000000000152, 8.203333333000103],
              [78.7616666670001, 7.588333333000094],
              [78.646666667000147, 7.35],
              [78.203333333000216, 6.513333333000105],
              [77.845, 5.898333333000153],
              [77.176666667000234, 5.0],
              [77.02333300000015, 4.784],
              [76.946666667000216, 4.870833333000121],
              [76.925991384000099, 4.89117801499998],
              [76.72083333300003, 5.093055556000081],
              [76.634195322000096, 5.205214841000213],
              [76.613333333000185, 5.232222222000246],
              [76.600193843000113, 5.249897493000162],
              [75.692777778000107, 6.470555555999965],
              [75.641944444000217, 6.555833333000123],
              [75.429444444000069, 6.851666667000131],
              [75.271944444000212, 7.2575],
              [75.201666667000211, 7.400000000000148],
              [75.188333333000287, 7.421944444000076],
              [74.935833333000062, 7.858333333000189],
              [74.495833333000235, 7.808333333000121],
              [73.642777778000095, 7.697222222],
              [73.327222222000159, 7.650555556000086],
              [73.056388889000175, 7.681111111000064],
              [72.825000000000273, 7.705277778000038],
              [72.707222222000212, 7.715000000000146],
              [72.062500000000171, 7.818055556000104],
              [70.252222222000228, 8.093888889000141],
              [69.595833333000087, 7.957500000000209],
              [69.366477808000013, 8.349567697999987],
              [69.365728131000253, 8.351076091000209],
              [69.112090591000253, 8.874528739000084],
              [69.111131526000236, 8.877921117000085],
              [69.109537771000248, 8.881202023000128],
              [68.950999809999985, 9.440011530000106],
              [68.892700709999986, 10.01746706900019],
              [68.892713765500162, 10.018147452500159],
              [68.895854076000148, 10.059695051000233],
              [68.843978702500067, 10.241126546500084],
              [68.843054284000232, 10.244416993000243],
              [68.842873518000204, 10.246197540000082],
              [68.842210709000199, 10.248506326000154],
              [68.84177084400028, 10.252829650000081],
              [68.841352070000084, 10.254295865000188],
              [68.838427675000162, 10.282998520000149],
              [68.754699795000079, 10.574942923000208],
              [68.695217681000258, 11.152325151000014],
              [68.695229229500228, 11.153006036500187],
              [68.738588507000202, 11.73194787400007],
              [68.741734843000131, 11.744224408500145],
              [68.815971494000109, 12.031360933000087],
              [68.825418535000097, 12.157556813000156],
              [68.971137162000105, 12.720097133000138],
              [69.215393439000081, 13.249234877000049],
              [69.55130318900018, 13.728695063000089],
              [69.552569054000173, 13.729967958000145],
              [69.970313157000248, 14.144937300000038],
              [70.376136233000238, 14.426177333000112],
              [70.217098152000176, 14.751263205000214],
              [70.057263696000234, 15.311040816],
              [70.047918085000106, 15.405518257000153],
              [70.047322561000215, 15.407595060000119],
              [70.045080056000245, 15.430237028000136],
              [69.901042555000174, 15.931003520000047],
              [69.899565293000137, 15.945735963],
              [69.896393851000141, 15.956728555000041],
              [69.863891089000219, 16.280086102000098],
              [69.85515004000024, 16.310286105000102],
              [69.847189298000217, 16.389186523000092],
              [69.83202602900019, 16.441508288000136],
              [69.827449632000139, 16.486773143000164],
              [69.761300259000166, 16.714522456000054],
              [69.75663548700021, 16.760361745000182],
              [69.73951893900005, 16.819157952000182],
              [69.737622902000112, 16.837747744000012],
              [69.730392255000254, 16.86256794500008],
              [69.724440027000128, 16.874467380000056],
              [69.718314062000161, 16.895487474000049],
              [69.712217221000287, 16.907667097000171],
              [69.683849987000258, 17.004899213999977],
              [69.651224202500089, 17.070007173000135],
              [69.650410253000132, 17.071649133000108],
              [69.492111305000208, 17.611362702000122],
              [69.464221092000116, 17.623373610000044],
              [69.440125965, 17.62923143800009],
              [68.883786427000075, 17.867585699500125],
              [68.882388501500259, 17.868251020000173],
              [68.857696345000193, 17.878789186000063],
              [68.856421440500185, 17.879544888500106],
              [68.855486980000251, 17.880079655000145],
              [68.812494758000184, 17.898390264999989],
              [68.811710434000133, 17.898894401000121],
              [68.810821667000255, 17.89927401000017],
              [68.803354015000195, 17.9040833950001],
              [68.793635825000251, 17.908220932000233],
              [68.7904523320002, 17.910270619000073],
              [68.789481216000127, 17.910683369000139],
              [68.778346019000139, 17.917855769000084],
              [68.767983096000194, 17.922299604000159],
              [68.766468677000034, 17.922979486000088],
              [68.765672302000155, 17.923492661000182],
              [68.764795586000105, 17.923866244000124],
              [68.762625387000185, 17.925262161000092],
              [68.741011778000086, 17.934455125000056],
              [68.732904415000121, 17.939671234000059],
              [68.721886215000097, 17.944389230500065],
              [68.720368281000049, 17.945071121000012],
              [68.715855152000131, 17.947973422000075],
              [68.712959881000302, 17.949204641000108],
              [68.644592105000129, 17.993161953000026],
              [68.633901794000167, 17.9976981800001],
              [68.631453416500335, 17.999265322000099],
              [68.622107805000155, 18.005263244000048],
              [68.620653642000207, 18.005879858000071],
              [68.593879660500249, 18.023008457500111],
              [68.086248070500233, 18.347592201500049],
              [68.078903439999976, 18.352323254000055],
              [68.074342107000177, 18.356482885000048],
              [68.073125953000044, 18.357257166000068],
              [68.070884452000143, 18.359298820000106],
              [68.048147080000035, 18.373817858500104],
              [68.045829256000104, 18.375337801000114],
              [68.042145643000197, 18.378695031000063],
              [68.026864876, 18.388417238000102],
              [68.011236635000216, 18.402652574],
              [67.993664155000204, 18.413824928],
              [67.992300374000251, 18.415068198000199],
              [67.990381240000261, 18.416288369000142],
              [67.984621829000218, 18.421532597000123],
              [67.983714985000177, 18.422109040000123],
              [67.970508008000138, 18.434131999000044],
              [67.966302181000231, 18.436804326000072],
              [67.963753378000092, 18.439123154000129],
              [67.962027061500237, 18.440249428000016],
              [67.959744386000068, 18.441727192000087],
              [67.957200604000093, 18.444043007000118],
              [67.955570696000223, 18.445078396000099],
              [67.952367117999984, 18.447993752000059],
              [67.950418358000036, 18.449280205000036],
              [67.948118108000131, 18.4507896020001],
              [67.94674629400015, 18.452036889000226],
              [67.944834190000222, 18.453251035000108],
              [67.933986210500279, 18.463120366000226],
              [67.929720129000145, 18.466999307000094],
              [67.921564561000196, 18.472178258000213],
              [67.865557175000276, 18.523087034000127],
              [67.860079457500092, 18.526608460000034],
              [67.857767157000183, 18.528123381000057],
              [67.414280444000212, 18.929438851500151],
              [67.401752344000158, 18.940758329000232],
              [67.395632405000129, 18.948611615000175],
              [67.374695664000228, 18.967469544000096],
              [67.37099397500026, 18.972218672999986],
              [67.367169766000302, 18.975661265000099],
              [67.363272246000236, 18.980659449000086],
              [67.35874104100003, 18.984739744000137],
              [67.357565057000102, 18.986248137000132],
              [67.353096617000233, 18.990359061000106],
              [67.35122719400016, 18.992131072000063],
              [67.347450185000184, 18.99697058400011],
              [67.344600105000268, 18.999536459000069],
              [67.344027679000192, 19.000271575000113],
              [67.340794977000201, 19.003180906000068],
              [67.340120116000094, 19.004046575000018],
              [67.328277922999973, 19.014702741000065],
              [67.327639216000193, 19.015521210000031],
              [67.325372609000283, 19.017559851000044],
              [67.318781674000206, 19.026004644000068],
              [67.315325023000128, 19.029114827000058],
              [67.311031826000089, 19.034615140000142],
              [67.305298525000239, 19.039769989000092],
              [67.302311867000242, 19.04359520200012],
              [67.301074622999977, 19.044708922000098],
              [67.288491288000046, 19.060821214000043],
              [67.273486691000187, 19.074310388000072],
              [67.269920576000231, 19.078874734000067],
              [67.266762190000065, 19.081712763000155],
              [67.260337961000232, 19.089934611000103],
              [67.257617430000153, 19.092378971000187],
              [67.255656117000285, 19.094889612000202],
              [67.248796040000173, 19.101052735000053],
              [67.247911290000104, 19.102184531999967],
              [67.245989143000145, 19.10391185300017],
              [67.239210412000148, 19.112581600000112],
              [67.234246372000143, 19.117040499000041],
              [67.230799763000164, 19.121448181000176],
              [67.229218059, 19.122868199000095],
              [67.226193239000224, 19.12673559100017],
              [67.2250011860001, 19.127807133000033],
              [67.21764802000007, 19.13720898300005],
              [67.210750786000176, 19.143400224000118],
              [67.207555241500188, 19.147437335500115],
              [67.200846808000108, 19.155962469000087],
              [67.197785834000172, 19.158710115000247],
              [67.19625735600016, 19.160662390000198],
              [67.189868276000198, 19.166397699000186],
              [67.18645179500021, 19.170762196999988],
              [67.183947179000086, 19.173010728000122],
              [67.171781616000089, 19.188549589000218],
              [67.170263180000234, 19.189911361000043],
              [67.169251893000137, 19.191203839000082],
              [67.164761863000251, 19.195231912000224],
              [67.160712700000147, 19.200401824999972],
              [67.157233956000255, 19.203523054000186],
              [67.14599130400029, 19.217874884000139],
              [67.136350441000104, 19.226518520000184],
              [67.128007078000167, 19.237164643000085],
              [67.127095213000302, 19.237983112000165],
              [67.122542918000136, 19.243793741000218],
              [67.120417409500249, 19.245180117000132],
              [67.118151304000207, 19.246656876000031],
              [67.117300699000111, 19.247420111000054],
              [67.104068615000187, 19.255691168000226],
              [67.095872375500335, 19.262991610500194],
              [67.095028298000159, 19.263703126000223],
              [67.093334117000211, 19.264762617000201],
              [67.083333730000277, 19.273719581000023],
              [67.079214270000136, 19.27629349100016],
              [67.067695448000109, 19.286608209000065],
              [67.065889794999975, 19.287734985000043],
              [67.050282141500247, 19.301677078500006],
              [67.043890551000345, 19.30729137450021],
              [66.595750113000179, 19.706622434000224],
              [66.594635389000132, 19.708031406000089],
              [66.583244107000155, 19.718142261000082],
              [66.582717876000231, 19.718805070000087],
              [66.58113516800023, 19.720210025000085],
              [66.577853258000061, 19.724352583000041],
              [66.576513579000164, 19.72554162300014],
              [66.57576239600013, 19.726490646000059],
              [66.573609270000134, 19.728400742000019],
              [66.566062282000246, 19.737928123000245],
              [66.560561969000162, 19.742806801000143],
              [66.559605917000113, 19.744013918000121],
              [66.556855258000155, 19.746453257000042],
              [66.553427730000237, 19.750779594000164],
              [66.535535896000283, 19.766641826000239],
              [66.53503075500015, 19.767280533000246],
              [66.533526379000193, 19.768614186000036],
              [66.524021091000122, 19.780604004000111],
              [66.521999522333545, 19.782424386666776],
              [66.51885352133354, 19.786306173000071],
              [66.517549662000135, 19.787477136000234],
              [66.51150805400016, 19.795097435000173],
              [66.503413747000195, 19.802269835],
              [66.496082674000149, 19.811512008000079],
              [66.492474380000203, 19.814707552000129],
              [66.489071959000171, 19.818995727000129],
              [66.487449081000136, 19.820433823000087],
              [66.486798322000027, 19.821252292000054],
              [66.477943793000065, 19.829094531000067],
              [66.476760777999971, 19.830584848000058],
              [66.472435446000247, 19.834415082000248],
              [66.470814576000237, 19.836457740000128],
              [66.466221106000148, 19.840523975000053],
              [66.465664748000108, 19.841225949999966],
              [66.464121206000129, 19.842591739000056],
              [66.457109487000196, 19.851423171000135],
              [66.454480343000114, 19.853750033000154],
              [66.452179591000174, 19.856649322000123],
              [66.445767413, 19.862322367000104],
              [66.441677077000179, 19.867474203000029],
              [66.438242519000198, 19.870513083000105],
              [66.434775826000134, 19.874878586000136],
              [66.43401861600006, 19.875547421000135],
              [66.430581046000157, 19.879876771000056],
              [66.429000346000294, 19.881273692000093],
              [66.425786726000183, 19.885318837],
              [66.424777448000157, 19.886211620999973],
              [66.421313767000186, 19.890572103000011],
              [66.420658992000085, 19.89114955000008],
              [66.420130753000137, 19.891816377000112],
              [66.401701139000124, 19.908170192000057],
              [66.400648176000118, 19.909159887000158],
              [66.396210366000105, 19.91474154500014],
              [66.390923960000237, 19.919415355000126],
              [66.389650563000089, 19.921017144000245],
              [66.380210551000118, 19.929360507000013],
              [66.37879655800009, 19.931139045000066],
              [66.373007019000227, 19.936254728],
              [66.365612679000208, 19.945551131000059],
              [66.363058854000201, 19.947806691000039],
              [66.358577862000175, 19.953440570000112],
              [66.354235456000197, 19.957275826000057],
              [66.352806399000201, 19.959073445000172],
              [66.347924708000164, 19.9633847180001],
              [66.345997540000212, 19.965807989000155],
              [66.341710369000111, 19.96959403599999],
              [66.340716155000109, 19.970841323000229],
              [66.338003658000105, 19.97323747900009],
              [66.336072473000286, 19.975663763000114],
              [66.330896535000306, 19.980233130000045],
              [66.328195085000061, 19.983626513000104],
              [66.320878071000038, 19.990087899000173],
              [66.320092743000259, 19.991073075000202],
              [66.317973762000179, 19.992943001000015],
              [66.313347152000176, 19.998755637000102],
              [66.309646466000231, 20.002021480000224],
              [66.303897600000255, 20.009170279999978],
              [66.299529586000176, 20.01458573300016],
              [66.296983796000234, 20.016832255000224],
              [66.278561714000261, 20.039954257000034],
              [66.277779398000177, 20.04064317700022],
              [65.905919274000098, 20.505288571],
              [65.623431955000171, 21.022638352],
              [65.62203804700016, 21.026861250000024],
              [65.620708411000066, 21.029284521000022],
              [65.606407798000191, 21.059144081000056],
              [65.671860217000244, 21.108099578000122],
              [65.886056084000245, 21.268275482000092],
              [66.169028459000117, 21.478299667000101],
              [66.488581882000176, 21.719066152000153],
              [66.614311787000133, 21.813808718000132],
              [67.067582971000093, 22.15262277700009],
              [67.158143812000219, 22.219658909000032],
              [67.301673159000302, 22.421378896000192],
              [67.363521302000038, 22.520934863000093],
              [67.402207272000112, 22.582915567999976],
              [67.547214885000187, 22.886453108000069],
              [67.572841503000063, 22.941813753000076],
              [67.646472588000222, 23.094908644000043],
              [67.69267642200009, 23.185050710000041],
              [67.722288934000289, 23.244383190000121],
              [67.904551452000192, 23.434196712000073],
              [67.909621943000047, 23.439435919000232],
              [67.997204159000205, 23.52930884400007],
              [68.001836794000042, 23.534160407000115],
              [68.008318266000231, 23.541010441000026],
              [68.051354675000084, 23.588025715000242],
              [68.060860968000156, 23.598731089000125],
              [68.081746491000104, 23.620874946000129],
              [68.090891251000016, 23.631282056000032],
              [68.092197788500073, 23.631919759000084],
              [68.110937215000092, 23.640295258000037],
              [68.11428440200018, 23.641876962000026],
              [68.121522079000101, 23.645115690000125],
              [68.131472253000254, 23.649223099000039],
              [68.143794480000224, 23.655646324000088],
              [68.149911407, 23.65813185900015],
              [68.155474988000151, 23.660845360000224],
              [68.158713715000033, 23.662085617000059],
              [68.165932311000233, 23.666231187000051],
              [68.175427557000205, 23.672255722000216],
              [68.179108157000087, 23.674745274000145],
              [68.182541710000237, 23.676938571000164],
              [68.183958716000234, 23.677919729000166],
              [68.183804060000227, 23.681320142000231],
              [68.182702391000134, 23.686729067000215],
              [68.182298178000167, 23.692414163500075],
              [68.181351164000148, 23.700174556000036],
              [68.181084534000092, 23.70135907700012],
              [68.181033316500191, 23.703678407000154],
              [68.181017248000217, 23.704855898000119],
              [68.180737061000229, 23.706848844500087],
              [68.180620567000176, 23.709672312000066],
              [68.180508089999989, 23.710524926000062],
              [68.178901280000105, 23.718998340000041],
              [68.177194044000089, 23.724267172000054],
              [68.177420002000304, 23.727131312000097],
              [68.177753415000097, 23.727950785000132],
              [68.179051918500193, 23.732723012],
              [68.179872396000178, 23.734249482000166],
              [68.180238950000103, 23.735105109000017],
              [68.180673793000153, 23.7358994760001],
              [68.182424212000114, 23.738193197999976],
              [68.183775941500016, 23.74095942200006],
              [68.184520095000039, 23.74195012100023],
              [68.184848989000102, 23.742562717500164],
              [68.186033008000095, 23.744643537000172],
              [68.187903436000113, 23.747555881000096],
              [68.188247896, 23.748309074000133],
              [68.188644577000105, 23.748945270000206],
              [68.190392987000166, 23.751627137000128],
              [68.190808247500286, 23.752514899500113],
              [68.191105005000111, 23.753140552000048],
              [68.193772311000146, 23.756822156],
              [68.19463295900016, 23.757544217000145],
              [68.195389164000062, 23.758582618],
              [68.197253064000279, 23.761745021500133],
              [68.197800333516909, 23.766681535330747],
              [68.208463237369244, 23.850827048046426],
              [68.217763294244435, 23.872500135147732],
              [68.284709219817671, 23.939300047967095],
              [68.38248227661353, 23.966245016904622],
              [68.631927212897608, 23.969991057082211],
              [68.69359119835093, 23.970273191943818],
              [68.747209227285737, 23.969991057082211],
              [68.746627187927515, 24.000000114552847],
              [68.746091249037079, 24.042500051808361],
              [68.744709240837665, 24.295273106576474],
              [68.748363247718203, 24.317827131703837],
              [68.788745078035333, 24.333609081960901],
              [68.819154119926623, 24.335273225021922],
              [68.929700191369363, 24.324300140268349],
              [68.947900153061084, 24.311800040389812],
              [68.959163251665586, 24.294718056946579],
              [68.977409146186915, 24.263609126138306],
              [69.016309223656663, 24.262635987176736],
              [69.077282205010363, 24.294854011416476],
              [69.252491306871093, 24.279718138258147],
              [69.380263362853782, 24.285000078377891],
              [69.543591284568151, 24.283882100129063],
              [69.598600206519734, 24.281391166136473],
              [69.642763281007944, 24.2422180062606],
              [69.693391317433736, 24.19362710758827],
              [69.72360019947655, 24.171109124644715],
              [70.000000162981564, 24.169718063989762],
              [70.020263246326635, 24.170691202951048],
              [70.064091212325138, 24.193682092873189],
              [70.086654122270147, 24.232635982161639],
              [70.117118149446412, 24.303127117511792],
              [70.148882209808676, 24.312500096944916],
              [70.182209159841591, 24.314164240005994],
              [70.262836195453986, 24.336035978213204],
              [70.329709198469459, 24.369718153303495],
              [70.414700188163124, 24.402218144766834],
              [70.559982207566577, 24.43582706202335],
              [70.586382185157788, 24.426936042024181],
              [70.599018239506222, 24.408327043456154],
              [70.589909290023911, 24.389373213390684],
              [70.579163354846628, 24.326664172246524],
              [70.58415410764951, 24.281109031274866],
              [70.645118204185678, 24.244091193987458],
              [70.761936118515905, 24.23582714034751],
              [70.798454059096741, 24.24193603903683],
              [70.881227197204112, 24.273191150237153],
              [70.86498223061443, 24.304127078563567],
              [70.928036103256147, 24.361664150157821],
              [70.949291268664183, 24.371800050420063],
              [70.975818148269809, 24.372354094221635],
              [70.999154204965691, 24.362636115652776],
              [71.020682285141675, 24.362218026321273],
              [71.104982270735889, 24.419300128210068],
              [71.095682213860528, 24.440545067695894],
              [71.037691178388911, 24.457218014262935],
              [71.003318166836834, 24.465064146209215],
              [70.980127117066331, 24.557918031655902],
              [70.978945101077045, 24.604027048791011],
              [71.001018172599117, 24.644645081140311],
              [71.077427266883717, 24.68046413863226],
              [71.070827188666783, 24.70499109613479],
              [70.994136127158583, 24.855827132235731],
              [70.978318134717881, 24.875553941414239],
              [70.966382304562728, 24.891664127000524],
              [70.942200178557755, 24.93555395144449],
              [70.934709271669021, 24.954164123478847],
              [70.906372236248501, 25.035273118525367],
              [70.901382321636106, 25.055273177748006],
              [70.895536111792978, 25.080554003624286],
              [70.887772290135956, 25.121664221329524],
              [70.882482303389168, 25.14916423989655],
              [70.863454209661256, 25.178191105950418],
              [70.842482184943549, 25.198054037236886],
              [70.821109169976808, 25.214718098986395],
              [70.797482261239793, 25.232773221391028],
              [70.750409157598938, 25.277909099564681],
              [70.677691285844958, 25.403118137187334],
              [70.672345140346579, 25.441518150312334],
              [70.671918166197543, 25.546664096800043],
              [70.672900189976474, 25.57013610796588],
              [70.6770633136573, 25.615273159605863],
              [70.679009256303971, 25.644854069461132],
              [70.675672253002347, 25.68013601459603],
              [70.65990924584662, 25.703253974170934],
              [70.635682192840591, 25.712773133995768],
              [70.612345130316328, 25.714582116344047],
              [70.577627119628517, 25.704991039789775],
              [70.53720924712735, 25.685273115428572],
              [70.511100289215278, 25.681664203187324],
              [70.37887224585387, 25.675827045799679],
              [70.351382285570594, 25.682773128980671],
              [70.317763310030273, 25.692491107549188],
              [70.2848452292356, 25.705553968408537],
              [70.122891269093657, 25.910691158555096],
              [70.088527142358885, 25.983191100825707],
              [70.094700246426783, 26.086245091913099],
              [70.102763302027938, 26.106945040053006],
              [70.119136176460529, 26.133882130001325],
              [70.139436140179782, 26.160000140368936],
              [70.153872292059191, 26.185273087256178],
              [70.168591249352346, 26.224436021210238],
              [70.174282226348339, 26.247500168966383],
              [70.185527220041877, 26.367773103795287],
              [70.186918280696773, 26.391382075259344],
              [70.187272332287876, 26.523882195198553],
              [70.16797232962017, 26.556245058725551],
              [70.140400226685699, 26.575136024517008],
              [70.112763247820453, 26.588327128495223],
              [70.09248222720214, 26.593327101391537],
              [70.071382294641694, 26.596109055063096],
              [70.038882303178525, 26.596664104692991],
              [69.946091282005796, 26.591664131796847],
              [69.90026339390397, 26.588473141248926],
              [69.833118146672916, 26.586109109270751],
              [69.795536207300131, 26.596936013632785],
              [69.774154139877851, 26.613609127838004],
              [69.7487361860654, 26.646873213596734],
              [69.722763350261175, 26.665273167498924],
              [69.695254111600661, 26.678609110764398],
              [69.634154060594426, 26.702218082228399],
              [69.591663343432401, 26.71860906157201],
              [69.571927146522228, 26.72471812789928],
              [69.542209276368681, 26.734436106468138],
              [69.511309222588125, 26.748745021057005],
              [69.4983182784581, 26.773054049076336],
              [69.491091233881946, 26.791945014867849],
              [69.485818178579677, 26.817773179022822],
              [69.512082201700991, 26.999300056600703],
              [69.583536250090788, 27.177982116232613],
              [69.695809161230386, 27.282154085568607],
              [69.728591287555389, 27.297500176859444],
              [69.755827108533595, 27.309164098074859],
              [69.867482273407518, 27.408609176920947],
              [70.031100208972475, 27.590553976192041],
              [70.041236109234887, 27.608327131373017],
              [70.053863278765846, 27.641109090059842],
              [70.063036098350437, 27.673882163929534],
              [70.074436157253217, 27.708044957349273],
              [70.08137234978841, 27.728054069027394],
              [70.106927263899081, 27.789027050381208],
              [70.123872287043923, 27.822500180805832],
              [70.203536241978668, 27.885582216642376],
              [70.221927143425603, 27.906244949132201],
              [70.366509273911674, 28.018745009848431],
              [70.480672304222821, 28.045554024315834],
              [70.519718226808322, 28.037500188808337],
              [70.554427185040481, 28.022218135258129],
              [70.587354150652686, 28.003191047358541],
              [70.649991275067578, 27.94290907010469],
              [70.665682197855858, 27.921936039558489],
              [70.668872350213235, 27.898744989787986],
              [70.662063394968641, 27.870209135623384],
              [70.672063340760985, 27.841664229003641],
              [70.72720017055542, 27.74582705657042],
              [70.742482224105686, 27.728054069027394],
              [70.775127222494291, 27.714164249598753],
              [70.805818231609067, 27.708044957349273],
              [70.829436087890599, 27.706382155392703],
              [70.857209188863919, 27.7065181098626],
              [70.894436238455114, 27.712218139313904],
              [70.929982213540995, 27.723045043675938],
              [70.95776336114136, 27.733054041923708],
              [70.995254105407184, 27.749435968811667],
              [71.056636123637077, 27.77750008946407],
              [71.085818222538137, 27.792218040928958],
              [71.110536119795484, 27.803327080152599],
              [71.15554509595438, 27.821944963537931],
              [71.203600223374167, 27.839164242555512],
              [71.225818134183442, 27.845827017408283],
              [71.253054122799824, 27.850554075536309],
              [71.353863272572369, 27.86443601597631],
              [71.399427130723552, 27.868609030302764],
              [71.437763273745929, 27.870273173363955],
              [71.478036139321659, 27.870273173363955],
              [71.52415420891225, 27.86971812373406],
              [71.568882223676212, 27.871935975320525],
              [71.614700221132011, 27.876109157285157],
              [71.640827116317155, 27.880826995319694],
              [71.693591196953946, 27.890554026343921],
              [71.89694522101388, 27.96193599036593],
              [71.904163380772502, 28.010827128810831],
              [71.919009240080243, 28.119164233484469],
              [71.992200186450873, 28.215827023382701],
              [72.085263284201233, 28.286944957454068],
              [72.137500310764949, 28.325273221487635],
              [72.206645144823312, 28.402500054248321],
              [72.213882247683273, 28.42360903926442],
              [72.253327148860933, 28.551935977239054],
              [72.29026334641128, 28.672845098520668],
              [72.389709263447685, 28.785000160101333],
              [72.554982289618408, 28.859164245433192],
              [72.596645210020597, 28.881936032405861],
              [72.638318188706535, 28.904718045300342],
              [72.728863362736149, 28.953609016107123],
              [72.834436115734718, 28.993609134552742],
              [72.950263289107113, 29.04000011891145],
              [72.988872346897779, 29.141936131749958],
              [73.049991173367005, 29.208045040469869],
              [73.086382211933369, 29.245827139690846],
              [73.265272310402793, 29.553054066780874],
              [73.274018155838576, 29.573053958365506],
              [73.330272293329358, 29.741936064415427],
              [73.397491301308719, 29.942773170582768],
              [73.419436129711698, 29.952500033968818],
              [73.581100243641004, 30.01763597136528],
              [73.674700286110465, 30.040836073591322],
              [73.72942724083839, 30.053327121014547],
              [73.798872314669211, 30.070553943744812],
              [73.933400184630386, 30.136000179197708],
              [73.96762718342876, 30.208191164516563],
              [73.961927153977285, 30.244164113751253],
              [73.949572228662305, 30.286109169015049],
              [73.936091278471281, 30.311664250763897],
              [73.906791330011288, 30.340345111853765],
              [73.860109158335661, 30.361464155153669],
              [73.870409176262626, 30.387409163039251],
              [73.996091288501702, 30.505000056482402],
              [74.048872300583184, 30.59916420103724],
              [74.107345127850635, 30.645000135765883],
              [74.181091291489111, 30.691664202530831],
              [74.242482194536137, 30.764164144801668],
              [74.307482345100709, 30.848882219727273],
              [74.380263248766823, 30.892773217637682],
              [74.554991229383745, 30.995054061974145],
              [74.658291313149221, 31.041982158689734],
              [74.697591207401501, 31.059091131861123],
              [74.70657224904113, 31.093264151202845],
              [74.68431812840987, 31.112018156696138],
              [74.623745131476937, 31.123926997122965],
              [74.605618259980986, 31.105982180564581],
              [74.589572279773336, 31.079491175504756],
              [74.557282338804157, 31.076045039823612],
              [74.538991182005532, 31.118727032016466],
              [74.522663402212231, 31.175082087621831],
              [74.548372375703821, 31.354436040614416],
              [74.562427150987503, 31.378364194952383],
              [74.582545227407422, 31.397173185730423],
              [74.602672356282767, 31.415973124053323],
              [74.62687225192235, 31.419409033812485],
              [74.632800269140603, 31.462109130916701],
              [74.579991261502869, 31.578209051418867],
              [74.541418245895841, 31.649900140030923],
              [74.519136129708102, 31.678064172969471],
              [74.492827179585674, 31.701100157530789],
              [74.488727255454819, 31.721591061005029],
              [74.599282211715035, 31.869427046312978],
              [74.671791206441185, 31.918164125376848],
              [74.744372285534951, 31.942136033249483],
              [74.851172316800046, 31.998591168779114],
              [74.897436231506163, 32.047309137103866],
              [74.935736164706952, 32.061864144370475],
              [74.972072385626348, 32.053336060779884],
              [74.996354088641056, 32.036291125348782],
              [75.040736098440959, 32.041454042443149],
              [75.194027193988859, 32.074027124102486],
              [75.256527190467352, 32.092873162892829],
              [75.306863369023517, 32.133909116935627],
              [75.381291316668182, 32.214236080413457],
              [75.391300314915952, 32.241582207237911],
              [75.375072279770791, 32.26377312831886],
              [75.350827121853911, 32.275700073656708],
              [75.326536198745373, 32.30044496064221],
              [75.319309154169446, 32.344653967960085],
              [75.314709165693841, 32.351109039251639],
              [75.231654060362644, 32.398464110116095],
              [75.05762714212932, 32.475127008429695],
              [75.025054060470154, 32.478464179369269],
              [74.898327227816139, 32.45888220947802],
              [74.7383182044926, 32.466518123292133],
              [74.710682231455621, 32.480691083411102],
              [74.68580926898909, 32.504027140107155],
              [74.654154173368227, 32.584854000291955],
              [74.66914520723941, 32.704709181065198],
              [74.641927155895985, 32.777073168866252],
              [74.607627234539791, 32.761245118141574],
              [74.531663386952971, 32.75027320685416],
              [74.363382263362269, 32.775053968385635],
              [74.340409143074908, 32.794436113704592],
              [74.329663375535802, 32.815427081523637],
              [74.358736174419164, 32.851244965548972],
              [74.363591308028106, 32.872491078501469],
              [74.343036199175572, 32.984718056811573],
              [74.331791205482034, 33.002354084056037],
              [74.307900266794377, 33.01458210735683],
              [74.279982326533627, 33.021100042922285],
              [74.256100272663332, 33.022764185983362],
              [74.232482248743793, 33.02416413145562],
              [74.202063316206704, 33.031382123576407],
              [74.18094511109723, 33.042909084493544],
              [74.036227193779041, 33.166518016795635],
              [74.019718197238774, 33.184154044040042],
              [74.012627107132516, 33.207209139340819],
              [74.01138222686933, 33.235264207537625],
              [74.02331822466266, 33.272982101380308],
              [74.05303609481615, 33.289027075759861],
              [74.087000237130212, 33.302627048976035],
              [74.12164515762214, 33.33610017940066],
              [74.137772274652718, 33.369709096657175],
              [74.178036255411143, 33.485264193451428],
              [74.182345224207666, 33.50750004153349],
              [74.177063284087694, 33.532082151959116],
              [74.165682335924259, 33.550136100897078],
              [74.147900295925922, 33.566245113017033],
              [74.117754110519115, 33.587773193192845],
              [74.085818221141153, 33.6038822053128],
              [74.049009260881405, 33.619991049794407],
              [74.012700197328456, 33.641591214338007],
              [73.986927186096466, 33.679854099526608],
              [73.983318273855048, 33.70249110549571],
              [73.991218217620144, 33.745409132082898],
              [74.004854065382006, 33.77290915064998],
              [74.030818184006876, 33.812764094532156],
              [74.046100237557141, 33.828327109477755],
              [74.067063377457544, 33.838045088046442],
              [74.096372378372848, 33.839709063469456],
              [74.118591295010617, 33.839427096245856],
              [74.143045162317037, 33.841518045817907],
              [74.178318222634744, 33.852354002635252],
              [74.20916329113038, 33.867909138591642],
              [74.23580919376144, 33.8841641634653],
              [74.257491333318058, 33.901100134154831],
              [74.276927122817654, 33.921591205266907],
              [74.294563317700295, 33.973600076425441],
              [74.276863252715202, 34.006109120344149],
              [74.244427131354485, 34.019154043930655],
              [74.085818221141153, 34.027491187766543],
              [74.051654086616736, 34.02304509103368],
              [74.021936216463189, 34.014300083788214],
              [73.985263378311402, 34.011382175646588],
              [73.951382216839079, 34.024573111986797],
              [73.933727246493106, 34.041109098255617],
              [73.916227173718596, 34.063882226332623],
              [73.907900255804634, 34.09040910593842],
              [73.915536169618804, 34.119718106853895],
              [73.933172364501559, 34.136654077543199],
              [73.961109247863249, 34.15151804176196],
              [73.988036111889585, 34.161373148267046],
              [74.006518208443026, 34.171109064108748],
              [74.02158233251015, 34.201936027693421],
              [73.976372358312233, 34.298045109066436],
              [73.938172337397532, 34.312491151591644],
              [73.906100158273489, 34.310818123713091],
              [73.882754210931978, 34.310273132367172],
              [73.851372365355417, 34.317218042081493],
              [73.825000215682621, 34.33471811485623],
              [73.804563291665204, 34.357345062541697],
              [73.799909156095111, 34.397564116298781],
              [73.875263304043074, 34.511936023637588],
              [73.903591287008112, 34.538327116411324],
              [73.936572232076969, 34.632973220400117],
              [73.96234507567101, 34.664018113467932],
              [73.990263351207716, 34.675553959202603],
              [74.066372373357751, 34.689154100056783],
              [74.130536177621138, 34.694154072952983],
              [74.155400255270365, 34.700409152034126],
              [74.208882329735275, 34.735753961443208],
              [74.247482335070458, 34.754718017430605],
              [74.298600209919016, 34.772491172611467],
              [74.329709140727459, 34.780545008119304],
              [74.357209159294484, 34.783609096652583],
              [74.380818130758541, 34.782636125329361],
              [74.444700303074455, 34.769436136533614],
              [74.480400169903021, 34.758054014903735],
              [74.523600163713695, 34.742073245902816],
              [74.558318174401506, 34.730273202579383],
              [74.637209150223441, 34.706654005193514],
              [74.671918276093777, 34.698045120055951],
              [74.692745126248099, 34.693600196789532],
              [75.064145077694775, 34.634991079776057],
              [75.11053622969149, 34.627773087655441],
              [75.135545146627805, 34.625264216390065],
              [75.186509297371799, 34.623045023699092],
              [75.243000307446891, 34.626100059776888],
              [75.34873617228186, 34.570273231711269],
              [75.389154212420976, 34.555827021548055],
              [75.637209261516432, 34.502773094698455],
              [75.661372276781833, 34.500827152051784],
              [75.798872369617413, 34.50666414180165],
              [75.827491204623414, 34.518744978882069],
              [75.860882192396787, 34.554991178161004],
              [75.888882275308561, 34.579991210279957],
              [75.932209171133991, 34.606382135415856],
              [75.979145146838448, 34.62401816266032],
              [76.015000246514205, 34.630273241741691],
              [76.038318198299294, 34.629991106879913],
              [76.067218162338577, 34.624164175414194],
              [76.086109295768267, 34.617491174639326],
              [76.113872338457782, 34.604436025131065],
              [76.134991214119339, 34.592764224926668],
              [76.165118288787085, 34.586864203265009],
              [76.229218222948134, 34.611382108311886],
              [76.242763378517395, 34.63790915555586],
              [76.282209285523606, 34.687491130462092],
              [76.298727166881406, 34.702909138482255],
              [76.428036128634744, 34.75832709731003],
              [76.450000235415075, 34.76720906485366],
              [76.475882211388694, 34.769509059091433],
              [76.506518235218493, 34.760409162064548],
              [76.65693618198793, 34.745273121268042],
              [76.702345142567879, 34.749018155617307],
              [76.735118216437229, 34.739436131518517],
              [76.763600258783185, 34.710682180232766],
              [76.786927263023586, 34.683182161665741],
              [76.822209208158654, 34.663882158998035],
              [76.843318193174582, 34.658736005710182],
              [76.869982200716777, 34.658882186101891],
              [76.906791328614304, 34.67388210479038],
              [76.948309242091455, 34.790691134303302],
              [76.954018156360064, 34.856244993393318],
              [77.035400233812709, 34.991864187150782],
              [77.062754071987854, 35.020827015464192],
              [77.064282260579205, 35.045545080359545],
              [77.050127237733079, 35.088600067244954],
              [77.042482271463427, 35.099164115122676],
              [77.049427181177748, 35.10388212079522],
              [77.244136110278362, 35.204709040202772],
              [77.328045163907404, 35.247773247181669],
              [77.398036234912638, 35.283609068480018],
              [77.566082329937558, 35.368600058173854],
              [77.776382269540193, 35.475545096364215],
              [77.818054074759857, 35.4972180158274],
              [77.823927274331311, 35.501326992413738],
              [77.829991246019517, 35.49082714991448],
              [77.855545154302035, 35.46124506659288],
              [77.882345116313786, 35.442764143505713],
              [77.912054101649971, 35.437254047980957],
              [77.94956328610283, 35.456445085906921],
              [77.989082283304981, 35.492909047031048],
              [78.041445206054732, 35.505618023937302],
              [78.071800268489255, 35.49902699817585],
              [78.085536196175553, 35.473809036573698],
              [78.070963251635703, 35.438600181634797],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "IOT", Country: "British Indian Ocean Territory" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [75.053238570000218, -3.467495222999872],
            [75.152712570000091, -3.626368223999961],
            [75.256694570000064, -3.819298224],
            [75.265644570000205, -3.838848223999847],
            [75.266770570000205, -3.840938223999942],
            [75.358025570000024, -4.040285223999973],
            [75.436149570000026, -4.245199224000018],
            [75.500796570000176, -4.454803223999988],
            [75.551680570000173, -4.668200225],
            [75.588572570000139, -4.884475225],
            [75.602568070000103, -5.018573724999968],
            [75.639546570000135, -5.23505322499993],
            [75.662306570000197, -5.453263225],
            [75.670789570000153, -5.672487224999941],
            [75.670804570000058, -5.675227224999986],
            [75.670812570000209, -5.676856224999938],
            [75.664955570000103, -5.893410225999943],
            [75.644799570000117, -6.111833225999959],
            [75.640333570000081, -6.139961225999983],
            [75.663644570000059, -6.200845225999885],
            [75.687609570000092, -6.272713226],
            [75.729269571000117, -6.412466225999935],
            [75.730290571000211, -6.416179225999841],
            [75.781417571000162, -6.629427225999862],
            [75.818522571000102, -6.845530226999855],
            [75.841435571000119, -7.063561226999937],
            [75.850045571000095, -7.282586227000024],
            [75.850069571000205, -7.286592227000014],
            [75.850087571000103, -7.289930226999886],
            [75.847774571000201, -7.433527227],
            [75.842264571000129, -7.58578322699988],
            [75.822183571000124, -7.803964226999852],
            [75.787833571000164, -8.020325227999962],
            [75.739347571000138, -8.233940227999881],
            [75.676921570000019, -8.44389122799987],
            [75.60080657000006, -8.649281227999865],
            [75.511319570000154, -8.849228227999987],
            [75.454870570000111, -8.959680228999915],
            [75.407935570000092, -9.044547228999903],
            [75.292869570000136, -9.231066228999921],
            [75.16571057000013, -9.40965722899989],
            [75.026995570000196, -9.579553228999956],
            [74.936621570000199, -9.678959228999958],
            [74.830758570000086, -9.790917228999874],
            [74.828656570000163, -9.79304122899994],
            [74.82120157000017, -9.800544228999897],
            [74.661151570000044, -9.950885228999965],
            [74.491449569000139, -10.090470229999852],
            [74.312817569000146, -10.218699230000013],
            [74.126020569000133, -10.33501723],
            [73.931863569000171, -10.438927229999976],
            [73.888342569, -10.46002823],
            [73.72947256900008, -10.530791229999892],
            [73.523127568000206, -10.608588229999953],
            [73.5208505680001, -10.609362229999959],
            [73.518108568000144, -10.610295229999878],
            [73.434757568000094, -10.637447229999978],
            [73.304481568000114, -10.675285229999972],
            [73.089514568000169, -10.725622229999843],
            [72.871629568000088, -10.761867229999837],
            [72.651772568000155, -10.78386323],
            [72.483162568000097, -10.790999229999983],
            [72.480752568000099, -10.79104123],
            [72.428492567000177, -10.791551229999911],
            [72.20757056700009, -10.784809229999851],
            [71.987562567000197, -10.763703229999962],
            [71.769429567000117, -10.728321229999963],
            [71.554123567000175, -10.678810229999954],
            [71.342585566000054, -10.615379229999888],
            [71.135738566000128, -10.538298229999924],
            [70.93448556600012, -10.447895229999915],
            [70.739704566000142, -10.34455823],
            [70.55224256600016, -10.228732229999963],
            [70.372915566000216, -10.10091523],
            [70.202500565000122, -9.961656228999871],
            [70.079577565000051, -9.847439229],
            [70.041139565000066, -9.832980228999858],
            [69.884122565000069, -9.76321122899995],
            [69.838604565000111, -9.741186228999865],
            [69.644279565000119, -9.637274228999985],
            [69.457301565000222, -9.520879228999846],
            [69.278481565000135, -9.392504228999869],
            [69.108594564000015, -9.252707228999938],
            [69.107609564000057, -9.251799228999857],
            [69.094468564000152, -9.240984228999864],
            [69.040332564000011, -9.19229322899993],
            [69.039034564000161, -9.191099228999903],
            [68.932958564000188, -9.089166228999986],
            [68.783104564000183, -8.928375227999922],
            [68.644249564000148, -8.758115227999852],
            [68.516984564000069, -8.579124227999912],
            [68.401851564000168, -8.392179228],
            [68.299334564000077, -8.198089227999986],
            [68.265083564000207, -8.125497227999915],
            [68.209067564000208, -7.995961227999885],
            [68.133008563000061, -7.790133226999885],
            [68.070675563000094, -7.579759226999897],
            [68.057788563000173, -7.522731227],
            [67.997086563000124, -7.317609226999892],
            [67.94878956300002, -7.103581227],
            [67.947308563000064, -7.095793226999987],
            [67.913870563000131, -6.882753226999853],
            [67.898803563000143, -6.734255225999959],
            [67.893269563000018, -6.655022226000014],
            [67.887730563000019, -6.439342225999937],
            [67.887784563000224, -6.4310322259999],
            [67.887810563000158, -6.427378225999902],
            [67.896645563000021, -6.208156225999957],
            [67.919773563000064, -5.989987225999911],
            [67.957071563000142, -5.773805225999951],
            [68.008366563000067, -5.560536224999879],
            [68.073417563000049, -5.35108622499996],
            [68.118561563000213, -5.233368224999964],
            [68.126664563000162, -5.207261225],
            [68.205131564000141, -5.002525224999843],
            [68.296708564000056, -4.803373224999959],
            [68.400992564000063, -4.610646223999922],
            [68.493013564000108, -4.464184224],
            [68.502615564000195, -4.423984223999966],
            [68.507601564000169, -4.405951223999963],
            [68.556153564000141, -4.245890224],
            [68.570644564000105, -4.202920224000025],
            [68.572297064000139, -4.198137723999906],
            [68.650470564000074, -3.993206223999834],
            [68.741662564000166, -3.794063223999984],
            [68.747972564000207, -3.782357223999981],
            [68.751730564000098, -3.774148223999958],
            [68.855604564000117, -3.581422223],
            [68.971715564000107, -3.39592822299997],
            [69.099561564000197, -3.218447223],
            [69.238591564000075, -3.049730222999969],
            [69.243090564000084, -3.044941222999853],
            [69.249399564000129, -3.037285223000026],
            [69.399012565000163, -2.87803822299999],
            [69.473029565000189, -2.806448222999961],
            [69.475224565000104, -2.804390222999942],
            [69.560769565000129, -2.72687322299987],
            [69.651822565, -2.649989222999949],
            [69.73369856500014, -2.585192223000021],
            [69.911087565000145, -2.45819922199999],
            [70.096282565000109, -2.343086221999968],
            [70.207211565000165, -2.283790221999922],
            [70.27231956500006, -2.305772221999902],
            [70.889368566000059, -2.500734221999949],
            [71.835560567000101, -2.778922222999824],
            [71.908289567000139, -2.800104222999877],
            [71.942900567000123, -2.809228222999849],
            [72.466097567000162, -2.946586222999954],
            [72.599959568000116, -2.981813223],
            [73.159198568000221, -3.091170223],
            [74.262882569000141, -3.309776222999972],
            [74.590096569000053, -3.374554223000018],
            [75.036902570000194, -3.46451722300003],
            [75.053238570000218, -3.467495222999872],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "IRL", Country: "Ireland" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-10.818945454999835, 48.187916090000115],
            [-11.444596206, 48.284317590000214],
            [-11.458898705999985, 48.288306090000177],
            [-11.463222955999925, 48.288954840000017],
            [-12.270855956999981, 48.510035341000176],
            [-12.271952956999883, 48.510491341000076],
            [-12.289554206999867, 48.515220091000188],
            [-12.290682956999831, 48.515688841000056],
            [-12.308251956999896, 48.5204050910002],
            [-12.309412706999922, 48.520886841000092],
            [-12.326949206999899, 48.525590341000239],
            [-12.328141706999872, 48.526084841000127],
            [-12.345646206999959, 48.530775591000108],
            [-12.346870706999823, 48.53128334100019],
            [-12.364342706999878, 48.535961341],
            [-12.365598956999975, 48.536481841000082],
            [-12.383038706999855, 48.54114709100007],
            [-12.384326956999956, 48.541680591000173],
            [-12.401734206999862, 48.546333341000022],
            [-12.403054456999939, 48.546879341000263],
            [-12.420429206999842, 48.551519590999973],
            [-12.421781456999923, 48.55207859100004],
            [-12.439123957, 48.556706091000123],
            [-12.440507957000023, 48.557277841000158],
            [-12.457817956999975, 48.561892841000102],
            [-12.459234207, 48.562477340999962],
            [-12.476511706999844, 48.567079841000108],
            [-12.477959956999939, 48.567677091000135],
            [-12.495205206999799, 48.572266841000115],
            [-12.496685206999956, 48.572877091000166],
            [-12.513897956999926, 48.577454341000148],
            [-12.515410206999832, 48.578077341000196],
            [-12.532590456999969, 48.582641841000211],
            [-12.534134706999851, 48.583277841000083],
            [-12.551282206999872, 48.587829841000129],
            [-12.552858706999928, 48.58847834100014],
            [-12.569973706999917, 48.593017841000218],
            [-12.571582206999778, 48.593679091000197],
            [-12.588664956999764, 48.598206091000137],
            [-12.590305456999971, 48.59888009100014],
            [-12.607355456999812, 48.603394591000082],
            [-12.60902795699991, 48.604081341000082],
            [-12.626045706999832, 48.608583341000056],
            [-12.627750206999906, 48.609282841000223],
            [-12.644735206999911, 48.613772341000058],
            [-12.647011206999821, 48.614705341000175],
            [-12.663406206999923, 48.619035091000129],
            [-12.673772956999983, 48.623281841000122],
            [-12.681801956999948, 48.625400091000259],
            [-12.692204206999804, 48.629658340999967],
            [-12.700197206999974, 48.631765341000047],
            [-12.710634956999854, 48.636035091000181],
            [-12.718591956999887, 48.638130591000206],
            [-13.469662457999874, 48.940700841000066],
            [-14.139771957999812, 49.32038409100015],
            [-14.14481670799978, 49.324392091000121],
            [-14.156733458999895, 49.331050341000193],
            [-14.165745458999822, 49.338204591000064],
            [-14.173383208999837, 49.342468591000099],
            [-14.182430958999817, 49.349646091000139],
            [-14.190032958999893, 49.353887091000203],
            [-14.199116208999953, 49.361087591000185],
            [-14.206682459, 49.365305591000137],
            [-14.215801459, 49.372529091000231],
            [-14.223331709, 49.376724091000042],
            [-14.232486458999944, 49.383970841000135],
            [-14.239980708999894, 49.388142591000147],
            [-14.249171458999967, 49.395412591000209],
            [-14.256629708999867, 49.399561341000108],
            [-14.265856208999878, 49.406854341000113],
            [-14.273278459000011, 49.410980091000042],
            [-14.282540958999988, 49.418296090999988],
            [-14.28992720899987, 49.422399091000202],
            [-14.299225458999871, 49.42973809100009],
            [-14.306575708999901, 49.433818092000067],
            [-14.315909708999925, 49.441180092000096],
            [-14.323223958999904, 49.445237092000227],
            [-14.332593958999894, 49.452622092000198],
            [-14.339871959, 49.456656092000188],
            [-14.349277958999835, 49.4640640920001],
            [-14.356519958999797, 49.468075342000176],
            [-14.365961958999947, 49.47550634200023],
            [-14.373167708999944, 49.479494592000165],
            [-14.947181458999864, 49.925170842000185],
            [-14.958286458999964, 49.937543091999984],
            [-14.961089458999879, 49.939689842000163],
            [-15.417116459999875, 50.440944342000122],
            [-15.4250314599999, 50.454497592000095],
            [-15.428556709999896, 50.458319592000137],
            [-15.436493709999894, 50.471897842000075],
            [-15.43999821, 50.475694592000153],
            [-15.447957209999913, 50.489298092000197],
            [-15.451441209999871, 50.49306934200014],
            [-15.459422209999957, 50.50669809200015],
            [-15.462885459999882, 50.510444343000231],
            [-15.47088821, 50.524098093],
            [-15.474330959999918, 50.527819093000218],
            [-15.482355709999979, 50.541498093000129],
            [-15.485777709999866, 50.545193593],
            [-15.493824709999899, 50.558897843000054],
            [-15.497225709999839, 50.562568343],
            [-15.505294959999844, 50.576297592999978],
            [-15.508675209999922, 50.579942842999955],
            [-15.516766459999815, 50.593697343000073],
            [-15.520125959999859, 50.597317343000114],
            [-15.528239209999867, 50.611097093],
            [-15.531577959999908, 50.614691593000046],
            [-15.539713459999888, 50.628496593000264],
            [-15.543031209999953, 50.632066093000205],
            [-15.55118895999982, 50.645896093000161],
            [-15.554485959999852, 50.649440342999966],
            [-15.562665959999975, 50.663295593000086],
            [-15.565941709999834, 50.66681434300007],
            [-15.575067459999786, 50.682256843000047],
            [-15.576972959999864, 50.684301843000142],
            [-15.902228959999974, 51.227044093],
            [-16.078458959999892, 51.79676334400024],
            [-16.079037959999937, 51.8170029690002],
            [-16.07964595999988, 51.837326719000131],
            [-16.096100959999887, 52.417568094000174],
            [-15.991468709999822, 52.816191845000034],
            [-15.94567120999983, 52.990669095000086],
            [-15.944694459999937, 52.992367845000118],
            [-15.940560209999916, 53.007931595000187],
            [-15.865862709999988, 53.137255845000126],
            [-15.841880209999857, 53.178776845000115],
            [-15.90805096, 53.384073845000032],
            [-15.908309709999941, 53.393625095000189],
            [-15.911602209999955, 53.403778845000105],
            [-15.916756459999817, 53.591238845000177],
            [-15.998801709999839, 53.841042346000123],
            [-16.016936959999953, 54.421163596000085],
            [-15.98650495999982, 54.532318346],
            [-16.0063197099999, 54.537192846000082],
            [-15.990442209999969, 54.642620096000016],
            [-15.73204796, 55.198078097000106],
            [-15.310122459999889, 55.712276347000071],
            [-15.076229958999875, 55.921432598000209],
            [-14.819547708999835, 56.131420098000106],
            [-14.480814958999872, 56.379867598000018],
            [-14.148428124666651, 56.563340931333457],
            [-13.585589700499895, 56.512717832000092],
            [-12.992799999999818, 56.4594],
            [-12.308099999999854, 56.391100000000179],
            [-11.881699999999853, 56.346700000000212],
            [-11.7336, 56.351700000000179],
            [-11.71, 56.3528],
            [-11.696399999999869, 56.353300000000232],
            [-11.257799999999861, 56.3847],
            [-10.26057996833319, 56.289586177333476],
            [-9.781666703999861, 56.23833459799998],
            [-9.270000454999945, 56.183334349000191],
            [-9.008333205999918, 56.150001526000111],
            [-8.761666250999895, 56.11000060400012],
            [-8.616666793999912, 56.081665038999958],
            [-8.244999919499861, 55.959999090500034],
            [-8.043332952999833, 55.893333347999985],
            [-7.996665952999876, 55.888332353],
            [-7.906022210499799, 55.886277552500118],
            [-7.335000038, 55.87333297700026],
            [-7.23758180299987, 55.826523113000192],
            [-7.206666951999921, 55.81166834700015],
            [-7.190038349999952, 55.802340063000159],
            [-7.138333320999863, 55.7733345030001],
            [-7.045000075999951, 55.711666107000028],
            [-7.01091057799988, 55.68310499900015],
            [-6.983333201999926, 55.659999847000122],
            [-6.955179728999838, 55.634054097000089],
            [-6.898333072999776, 55.581665039000072],
            [-6.79500055299988, 55.483333588000065],
            [-6.730000018999903, 55.459999084000074],
            [-6.727060821999885, 55.459022793000216],
            [-6.720046735999858, 55.456686203500098],
            [-6.720762789999867, 55.442015820000208],
            [-6.726916953999876, 55.427580782999982],
            [-6.733723451999936, 55.411615847000135],
            [-6.734604472999848, 55.409634580999963],
            [-6.735964041999978, 55.40657744300016],
            [-6.760107730999948, 55.345115827000157],
            [-6.773269242999845, 55.299850158000211],
            [-6.77708475599988, 55.286965211000179],
            [-6.782561201999869, 55.268471597000186],
            [-6.78321963166664, 55.267410286333458],
            [-6.788416950999959, 55.260241923000123],
            [-6.80554698999984, 55.236641811500064],
            [-6.8059083039999, 55.236139337000182],
            [-6.807186595499928, 55.234486085500137],
            [-6.811107698999876, 55.232470781000231],
            [-6.813219744999913, 55.231397104000123],
            [-6.820995651999908, 55.226853213000126],
            [-6.834082550999938, 55.219149436000166],
            [-6.856008386999974, 55.208718912000094],
            [-6.860184330999914, 55.207610261000113],
            [-6.871066451999866, 55.204721347000159],
            [-6.873353794999957, 55.204233966999979],
            [-6.874759994999863, 55.203934413000255],
            [-6.932466663999776, 55.191497398000152],
            [-6.9515549004999, 55.1985873255001],
            [-6.953132289999814, 55.199015905000252],
            [-6.957973005999861, 55.200331182000113],
            [-6.962330413999979, 55.200881592000158],
            [-6.96542345199984, 55.201272347000085],
            [-6.970588701999816, 55.199485597000219],
            [-6.975105326999937, 55.19632768700005],
            [-6.976979477999862, 55.195017354000257],
            [-6.987708057999896, 55.180942080000065],
            [-7.008649427999842, 55.160271120000189],
            [-7.082862197999816, 55.105094346000087],
            [-7.175308861, 55.080651965],
            [-7.252497169471212, 55.070601965821183],
            [-7.326390832329594, 55.045273217985894],
            [-7.40639090158254, 54.953327092655698],
            [-7.443890865942166, 54.882500178263271],
            [-7.458054941243603, 54.857773228550585],
            [-7.482836708603315, 54.829936089836835],
            [-7.553336728770915, 54.762773240608894],
            [-7.739163857965735, 54.710545098862539],
            [-7.825763837797638, 54.734164128610544],
            [-7.843054865906595, 54.73360907898082],
            [-7.926390932632842, 54.700544985432217],
            [-7.913890832754362, 54.671109082502326],
            [-7.906390873410118, 54.664445134183111],
            [-7.852363975237381, 54.633882200549081],
            [-7.777499833350134, 54.626936117368089],
            [-7.752226718825057, 54.594445010722396],
            [-7.833608796277616, 54.55389118375119],
            [-7.950836753312387, 54.532218096650197],
            [-8.046946002323494, 54.507218064531045],
            [-8.171663860418676, 54.463882116250332],
            [-8.158054834746963, 54.440273144786133],
            [-8.065555000891436, 54.372773175411666],
            [-8.055836687046394, 54.366391194315881],
            [-8.04528185926236, 54.361382168964397],
            [-8.030281940573786, 54.357218039455105],
            [-7.941390851321415, 54.299436048649326],
            [-7.900281807082649, 54.29416416681336],
            [-7.875763902035743, 54.284991179590762],
            [-7.869445958680416, 54.253054116746128],
            [-7.868336697611198, 54.227635995295671],
            [-7.861945999336172, 54.218045086379348],
            [-7.851390836275954, 54.212218154913487],
            [-7.818055001425734, 54.199164178871669],
            [-7.747781795558723, 54.200273104665015],
            [-7.699717783321546, 54.202500176344799],
            [-7.624863867356453, 54.163673189070934],
            [-7.621808831278486, 54.147500139210521],
            [-7.609999903137805, 54.142491113859066],
            [-7.559445962736476, 54.126936145540682],
            [-7.330281879432505, 54.113054037462675],
            [-7.313336688649429, 54.112500161299252],
            [-7.283890895073796, 54.123464193597485],
            [-7.145836758436928, 54.225273136783969],
            [-7.143472894096931, 54.256518022062139],
            [-7.175554963866603, 54.289164193917216],
            [-7.160836677125502, 54.33666427170705],
            [-7.058336729839652, 54.410000057364911],
            [-7.029445985893744, 54.417218049485527],
            [-6.97444594875958, 54.401664254633616],
            [-6.926945870969575, 54.379164208962735],
            [-6.917217834117054, 54.373600133981185],
            [-6.873054927266907, 54.34208216629672],
            [-6.851108757759334, 54.289718070080639],
            [-6.734726870033398, 54.183609042915307],
            [-6.655554932816301, 54.065273165915514],
            [-6.62875497080438, 54.042218070614908],
            [-6.619999905275108, 54.037218097718764],
            [-6.608054855026552, 54.042218070614908],
            [-6.60583683580191, 54.044444974656884],
            [-6.567499854589016, 54.050827123390476],
            [-6.447499834528458, 54.058327082734891],
            [-6.365208823492935, 54.104018178176275],
            [-6.353608772379829, 54.114236053451762],
            [-6.338890820914884, 54.115554023910747],
            [-6.288608789453349, 54.108609114196426],
            [-6.271526806010229, 54.104018178176275],
            [-6.266972381707745, 54.099829358349282],
            [-6.264471896475271, 54.098666907684745],
            [-6.262970762333111, 54.097966013999965],
            [-6.251149693999849, 54.092530338000259],
            [-6.247394217999897, 54.090803770333466],
            [-6.236454192999958, 54.085791339000011],
            [-6.224318986999975, 54.080494845000189],
            [-6.223396586999854, 54.080102227000211],
            [-6.214266450999901, 54.076215846000068],
            [-6.208952986999918, 54.073965168000015],
            [-6.208134153999936, 54.073618301],
            [-6.205846940999862, 54.072624865000236],
            [-6.203236700999895, 54.071491096000116],
            [-6.199476450999896, 54.069460345999957],
            [-6.188603715999932, 54.063804606500113],
            [-6.175409450999894, 54.056917096000049],
            [-6.167523951, 54.052804096000088],
            [-6.163081352999939, 54.050498223000162],
            [-6.160452296000017, 54.049133579999975],
            [-6.159619360999841, 54.048692255000148],
            [-6.157690950999978, 54.047670346000231],
            [-6.151462261999825, 54.045581915],
            [-6.15058664199978, 54.045288307000234],
            [-6.140594898999865, 54.041970582000076],
            [-6.139645366, 54.041651682000165],
            [-6.127955968999856, 54.037725751000139],
            [-6.123558837999838, 54.034452226000084],
            [-6.10878967249991, 54.023192072500152],
            [-6.100934950999857, 54.017243346000143],
            [-6.083524950999958, 54.004053846000048],
            [-6.053138748499833, 53.976675993500052],
            [-6.025201432999893, 53.951504475000164],
            [-5.981993420999856, 53.917631527000069],
            [-5.963625004999813, 53.903231596000111],
            [-5.941284700999944, 53.885717846000162],
            [-5.915806557999872, 53.871988867000127],
            [-5.874999999999829, 53.849999999000104],
            [-5.866355605999843, 53.844237069000215],
            [-5.86395720099992, 53.842638096000158],
            [-5.858079207999936, 53.838719470000129],
            [-5.85, 53.83333332900007],
            [-5.813769254999841, 53.824275644],
            [-5.683333450999982, 53.791666596000013],
            [-5.614509632499789, 53.783569761500175],
            [-5.54166666999987, 53.774999999000087],
            [-5.349911456999877, 53.753693866500114],
            [-5.262304098999891, 53.743959717000237],
            [-5.241686700499884, 53.741668848500083],
            [-5.265748031999919, 53.674773994],
            [-5.294140008999761, 53.595907388000185],
            [-5.316666521999849, 53.533329694000102],
            [-5.314308952999824, 53.479106066500137],
            [-5.308333449999964, 53.341666595000092],
            [-5.321133107499804, 53.271267903000123],
            [-5.324999999999875, 53.249999999000252],
            [-5.305035482999756, 53.185115314000072],
            [-5.291666698999848, 53.141666595000189],
            [-5.295704323999871, 53.129553706000095],
            [-5.35, 52.966666669000077],
            [-5.372129108, 52.906601942000108],
            [-5.408333449999816, 52.80833334500015],
            [-5.416666699999894, 52.758333345000082],
            [-5.43333344999985, 52.650000094000134],
            [-5.42729615799982, 52.589628258000246],
            [-5.424999976999828, 52.566666631000132],
            [-5.383333449999867, 52.483333344000116],
            [-5.4088937, 52.464163094000057],
            [-5.616674575999809, 52.308324344000127],
            [-5.791666700999826, 52.108333344000087],
            [-5.865027951999963, 51.993706338000138],
            [-5.924999948999812, 51.900000093000202],
            [-6.133333451, 51.641666594000228],
            [-6.416666700999883, 51.350000093000247],
            [-6.43340050199987, 51.333266167000161],
            [-6.45, 51.316666669000014],
            [-6.523428996999883, 51.191405440000111],
            [-6.591666700999838, 51.075000093000185],
            [-6.699999951999871, 51.050000093000236],
            [-6.74226920199996, 51.035948843000071],
            [-6.776386201999912, 51.02642259300022],
            [-6.814601701999862, 51.015887343000188],
            [-6.88983120199984, 50.992363593000178],
            [-6.907678720999883, 50.986036809000197],
            [-6.918213443999861, 50.982302387000104],
            [-6.926966943999901, 50.979199386000133],
            [-7.222463682000011, 50.87444964200013],
            [-7.264082269999989, 50.859599646000149],
            [-7.283129442999979, 50.853037887000156],
            [-7.373044254999911, 50.822062196000232],
            [-7.730370283999946, 50.635181449000228],
            [-7.967177216999971, 50.511409899000185],
            [-7.971243842999911, 50.509284401000187],
            [-7.977727702999914, 50.505895592000186],
            [-8.049966952999853, 50.467816592000062],
            [-8.167953702999966, 50.398915342000194],
            [-8.229312202999978, 50.36529784200026],
            [-8.315932202999875, 50.317579092000116],
            [-8.375639115999917, 50.28038920900012],
            [-8.430892510999882, 50.245973453000218],
            [-8.43921377599986, 50.240790375],
            [-8.502390002999988, 50.201196643000131],
            [-8.661194735999914, 50.087091950000158],
            [-8.806739092999862, 49.966485891000119],
            [-8.951509453999961, 49.846521342000202],
            [-8.952063096499899, 49.84605953950016],
            [-8.952984917999913, 49.845291151000126],
            [-10.30536280299998, 48.683402176000087],
            [-10.61100759999988, 48.388526988000095],
            [-10.818945454999835, 48.187916090000115],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "IRN", Country: "Iran" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [45.002218249726923, 39.416691170035449],
            [45.063045050688686, 39.373045091336337],
            [45.115818183781016, 39.312200185463553],
            [45.13054518770133, 39.285553947556366],
            [45.141800239678673, 39.247073132884566],
            [45.143527079375872, 39.216854024919826],
            [45.225818258049344, 39.194982119074609],
            [45.271236103446597, 39.187200192506737],
            [45.298391290516065, 39.19762711244806],
            [45.345545195703892, 39.16470903165316],
            [45.421100174052469, 39.060818023712329],
            [45.42443617152577, 39.022764183189565],
            [45.433473204278641, 39.00318221329826],
            [45.477263284074695, 38.984964146695575],
            [45.518591263624955, 38.970545093898693],
            [45.53860020766524, 38.965545121002322],
            [45.60749123769466, 38.951382219167215],
            [45.841100244265022, 38.903600174153894],
            [45.950827236127054, 38.887218079627814],
            [46.074718135652745, 38.871936026077606],
            [46.178245201356731, 38.841145104676571],
            [46.195536229465631, 38.844144987831442],
            [46.245000187174924, 38.866791213894032],
            [46.268600273821619, 38.882345008746086],
            [46.294718116551223, 38.895818079947901],
            [46.351091277067439, 38.909982155249509],
            [46.376382161227525, 38.906936003988847],
            [46.451936133747836, 38.889991148482181],
            [46.516391292966262, 38.878036039949563],
            [46.540373259122873, 38.875591206424417],
            [46.572491203438176, 38.915827023988015],
            [46.6522091378298, 38.976927074994251],
            [46.745473233619009, 39.051036007403013],
            [46.758945131354494, 39.085964236223163],
            [46.848882282126056, 39.160609107522475],
            [46.874700220359045, 39.167500037780371],
            [46.908736111764597, 39.167909074656563],
            [46.952900192081131, 39.156373228921893],
            [47.033882285113123, 39.196727063682431],
            [47.04346330338376, 39.237900145661627],
            [47.099709226609377, 39.304436028530517],
            [47.164573255065875, 39.335273218037173],
            [47.199500142781403, 39.33999105607154],
            [47.286936133638392, 39.374435984353227],
            [47.318609166532042, 39.397491079653832],
            [47.337218165099955, 39.424300094121293],
            [47.391663320242287, 39.470545065726071],
            [47.460682258114559, 39.498191097046856],
            [47.542500194533602, 39.515264195672557],
            [47.769854122022764, 39.633736027142049],
            [47.793882188646819, 39.666800120690823],
            [47.812900224090868, 39.679164266099292],
            [47.984709123099719, 39.715582126755692],
            [48.014154078485092, 39.699154099400289],
            [48.038318099579044, 39.683327054504161],
            [48.063318131697997, 39.665554066961136],
            [48.082491232351288, 39.646236127020643],
            [48.159154130664888, 39.573318095418301],
            [48.212491198204248, 39.526373234896198],
            [48.250827173588789, 39.494709086820194],
            [48.283873329864377, 39.472754032495232],
            [48.321936222842851, 39.440818143117099],
            [48.34054505377253, 39.423454024812429],
            [48.357882182349215, 39.389909145296201],
            [48.278873189330227, 39.3502642519083],
            [48.245963322800549, 39.34248215770225],
            [48.198536167568477, 39.336100176606692],
            [48.148945140206592, 39.306791175691217],
            [48.123873191358143, 39.278182231330845],
            [48.121718204045806, 39.251800191012421],
            [48.133327139976075, 39.210964229179979],
            [48.229227176683423, 39.139918044199874],
            [48.272354080298271, 39.126518063193998],
            [48.293873275656892, 39.112491115828789],
            [48.326927143283513, 39.054436042616715],
            [48.325409180614201, 39.032209079352128],
            [48.308736066409153, 39.003744974279016],
            [48.283736201928207, 38.985818094993419],
            [48.263718205432554, 38.977600141821085],
            [48.224709163221007, 38.971373225934656],
            [48.185263256215023, 38.969144980788258],
            [48.144145159520747, 38.968318022218682],
            [48.076791202899813, 38.953882205615457],
            [48.012073187197018, 38.8970731861329],
            [48.013191165445676, 38.863045006078437],
            [48.024636151349569, 38.833391173665348],
            [48.103045167737491, 38.783600154093108],
            [48.123600276590025, 38.772218032463343],
            [48.149300197626133, 38.761109160877709],
            [48.184709212413566, 38.750545112999987],
            [48.246900251940133, 38.71540012816331],
            [48.243600129012691, 38.672073232338107],
            [48.331654201411538, 38.602836029706452],
            [48.36192712119481, 38.605818143226486],
            [48.398736081454501, 38.620536094691545],
            [48.432763255680698, 38.624573154548273],
            [48.565263375619963, 38.471382138924582],
            [48.599154092461902, 38.416935977953756],
            [48.623509220948847, 38.396500059764605],
            [48.662900142669912, 38.389153992163131],
            [48.74220920782335, 38.424164195996354],
            [48.783736173755585, 38.445536037496822],
            [48.841173333063978, 38.450482198574491],
            [48.871137372456076, 38.445347230426535],
            [48.888282311250634, 38.442409084689359],
            [49.714527245611748, 38.262809039018975],
            [49.969573304889366, 38.333909035817385],
            [50.198973254949294, 38.378436052542838],
            [50.307745212760949, 38.402582136363947],
            [50.513636103642909, 38.472300124962885],
            [50.712400197511101, 38.545091086912805],
            [50.907227143808598, 38.581364108282116],
            [51.097309262343401, 38.623318048363217],
            [51.292709195543637, 38.71485396335089],
            [51.292886572036139, 38.73781903726757],
            [51.314878464000174, 38.736446381],
            [51.403982162000091, 38.73102378800013],
            [51.433158875000174, 38.689397812000152],
            [51.442623138000158, 38.675939560000018],
            [51.508251190000124, 38.570652008000081],
            [51.51729965200002, 38.556043624999973],
            [51.604780197000196, 38.413887024000076],
            [51.627344130999973, 38.391536713000107],
            [51.659566879000181, 38.368946075000224],
            [51.693935394000164, 38.344964981000032],
            [51.862430573000211, 38.22701454200012],
            [51.902812958000027, 38.201423645000091],
            [51.909090042000173, 38.197785377500168],
            [51.969062805000192, 38.176753998000038],
            [51.998817444000167, 38.166482925000082],
            [52.003511429000127, 38.164907455000105],
            [52.035282135000045, 38.154401779000239],
            [52.09094810500028, 38.137704848999988],
            [52.21961402900007, 38.100036621000129],
            [52.237651825000228, 38.094718932999967],
            [52.290206909000119, 38.079502106000064],
            [52.331420898000204, 38.067680359000065],
            [52.358242035000103, 38.060144424000157],
            [52.379053116000136, 38.055639267],
            [52.463626862000183, 38.038208008000026],
            [52.472200394000083, 38.036623001000066],
            [52.480001450000202, 38.0352211],
            [52.481903076000151, 38.03361892700002],
            [52.485445023000182, 38.030694962000098],
            [52.517494202000307, 38.00246429400022],
            [52.551292419000163, 37.972999573000095],
            [52.591415405000134, 37.941230774000076],
            [52.596994400000142, 37.936838150000085],
            [52.636650085000269, 37.905099869000139],
            [52.658569336000141, 37.887458801000093],
            [52.717552185000272, 37.84519767800009],
            [52.726230621000184, 37.838867188],
            [52.755672455000251, 37.81752777100013],
            [52.780427933000226, 37.799928665000039],
            [52.786849976000042, 37.795392990000039],
            [52.836921692000146, 37.759357452000245],
            [52.867570877000219, 37.7379188540001],
            [52.887678146000098, 37.724163055000133],
            [52.897546768000211, 37.71727562],
            [52.902633667000202, 37.713289261000114],
            [52.922651291000193, 37.697946548000175],
            [52.956703186000254, 37.672836303999986],
            [52.999711990000264, 37.642356873000097],
            [53.012269974000162, 37.633321762000094],
            [53.025304794000022, 37.624492645000089],
            [53.080326080000248, 37.587553023999988],
            [53.12772369400011, 37.555469513000048],
            [53.183105469000225, 37.518768311000088],
            [53.190402985000219, 37.514410019000081],
            [53.200782776000096, 37.507247925000058],
            [53.207355499000073, 37.502763748000064],
            [53.230482101000121, 37.487510681],
            [53.248907089000113, 37.4755039220002],
            [53.264401618806005, 37.46358519896944],
            [53.527100288630436, 37.323436071753576],
            [53.677318243189347, 37.33534507981858],
            [53.707464820332063, 37.337392835999253],
            [53.74284362800023, 37.343729019000079],
            [53.84932319961348, 37.347028801356089],
            [53.905636318017969, 37.350853931785196],
            [54.201663285220093, 37.330273190192827],
            [54.356100186935123, 37.360827071371375],
            [54.405682329479532, 37.377909054814552],
            [54.424709249740886, 37.386109070714099],
            [54.482209273323235, 37.414154080627043],
            [54.51277321278576, 37.430545059970484],
            [54.545827248050387, 37.444154085642197],
            [54.580127169406524, 37.452909151171468],
            [54.60470927983215, 37.448327099968822],
            [54.630545155337984, 37.440273096823148],
            [54.668745176252628, 37.440127084069445],
            [54.756382332786472, 37.495545042897049],
            [54.780691193167627, 37.513882132525282],
            [54.808873331016969, 37.577909144128455],
            [54.793536124543778, 37.623945071067396],
            [54.781863318510887, 37.643045081524747],
            [54.809718226859502, 37.711109152984761],
            [54.833054115917435, 37.746382213302354],
            [54.863327203338827, 37.766664239748764],
            [54.890273345742457, 37.774991157662555],
            [54.919991215895948, 37.784164144885494],
            [54.954709226583645, 37.804991162677823],
            [55.037773216732148, 37.865827016095125],
            [55.054163357885216, 37.88721813597283],
            [55.070409162665271, 37.905818082085204],
            [55.140063281162099, 37.954918097557652],
            [55.179436097972143, 37.96470916632245],
            [55.229427109754795, 37.981935989052943],
            [55.376518111506243, 38.038464214778287],
            [55.394436105974535, 38.04860916749584],
            [55.414154197973858, 38.067773215693649],
            [55.437627214968018, 38.083327010545702],
            [55.469436202331565, 38.090827137527924],
            [55.556654096067405, 38.098327096872367],
            [55.670273308498963, 38.108891144749862],
            [55.691654202454515, 38.111382078742452],
            [55.733318128685198, 38.120682135617642],
            [55.763882235785559, 38.124164145844645],
            [55.806382173041243, 38.122491117966092],
            [55.834709150177957, 38.106654014786102],
            [55.868327119889841, 38.093882173605891],
            [55.987073207231902, 38.072491053728186],
            [56.043191222614837, 38.07500009263174],
            [56.077082274733129, 38.080409102404076],
            [56.10999130307232, 38.086653955563293],
            [56.168745091735218, 38.094718016992942],
            [56.219700190023559, 38.07120912545281],
            [56.331800266319306, 38.086936090424899],
            [56.3496451705916, 38.136027053441865],
            [56.326727203227165, 38.162700113439371],
            [56.32298216887807, 38.182836127132077],
            [56.367209281106739, 38.218600199339107],
            [56.385554082085832, 38.231373213985762],
            [56.419300294916752, 38.252073162125726],
            [56.44276325362722, 38.259991210801672],
            [56.471100289047627, 38.2630539582305],
            [56.542773272748889, 38.266109161946474],
            [56.569718241686189, 38.259018071840046],
            [56.695263225988924, 38.266936120515993],
            [56.755745195453301, 38.286264118740462],
            [56.773882292871008, 38.266936120515993],
            [56.796027113484342, 38.25281814568217],
            [56.839573279897252, 38.234045029449589],
            [56.866709188589368, 38.225609146794056],
            [56.942491316514861, 38.211382207218492],
            [57.062627123407253, 38.195409149568434],
            [57.136863293106899, 38.239782106913026],
            [57.149791205324618, 38.260091123087761],
            [57.158882217533971, 38.27027312381756],
            [57.182491188998142, 38.281800084734755],
            [57.208463354250227, 38.282636095759756],
            [57.241518227705171, 38.272354015105634],
            [57.372073243188197, 38.090545002666317],
            [57.370818137002971, 38.070000119735596],
            [57.351663308898878, 37.993254072942193],
            [57.378873313615316, 37.974709112114795],
            [57.454018249259434, 37.93846425394031],
            [57.52860025628496, 37.927218086780442],
            [57.614436141821216, 37.925273149962095],
            [57.69964506099825, 37.926445107667348],
            [58.174436123679016, 37.796391162357793],
            [58.197773186203221, 37.786245036173568],
            [58.224436187916922, 37.721236000791521],
            [58.220963230145628, 37.69880904531658],
            [58.233736077154106, 37.67867319926205],
            [58.407209286862383, 37.641109029523875],
            [58.470682254664098, 37.640273186136994],
            [58.492491296235357, 37.645545067972989],
            [58.646382200776145, 37.66166413837658],
            [58.674709177912746, 37.653118117512975],
            [58.719436186848384, 37.649718082299614],
            [58.806309249086439, 37.670964195251941],
            [58.933600183825661, 37.668600163273766],
            [58.980818126753718, 37.654709170378283],
            [59.052773245316814, 37.631382166137826],
            [59.081663318710298, 37.611935983078467],
            [59.133882240363164, 37.576664263865382],
            [59.202218220762859, 37.533054059712072],
            [59.238045157243846, 37.518053973385236],
            [59.259854198814935, 37.517145039802472],
            [59.293473341993206, 37.535973141320142],
            [59.315682200347169, 37.540827101462412],
            [59.343045090977853, 37.535544993704491],
            [59.375545082441022, 37.495964138057047],
            [59.375891255043257, 37.471309105073829],
            [59.370545109544878, 37.424709076049282],
            [59.371936170199831, 37.404153967196748],
            [59.387218223749869, 37.343609133458415],
            [59.393054207671241, 37.323054024605881],
            [59.479991307649811, 37.232764163347724],
            [59.546109268825035, 37.208327059847477],
            [59.715545083400343, 37.147218124023937],
            [59.813045225428141, 37.124709025897573],
            [60.015827100775709, 37.043818127972543],
            [60.063463300673448, 37.011664141473204],
            [60.076382328073805, 36.988882128578723],
            [60.091800168456018, 36.958609041157331],
            [60.106100198227523, 36.936936121694146],
            [60.141382311000427, 36.891664121412589],
            [60.157491155482205, 36.871100127742523],
            [60.331245158947581, 36.658045056558365],
            [60.364718121734199, 36.645544956679828],
            [60.502782316654589, 36.64610017394773],
            [60.640827233198081, 36.647218152196501],
            [60.666936191110381, 36.647491066964619],
            [60.796391165617422, 36.648882127619572],
            [60.934436082160744, 36.64971813864463],
            [61.072773191849421, 36.650545097214149],
            [61.153736174142068, 36.650409142744252],
            [61.166663248169471, 36.632209181052531],
            [61.189500246349098, 36.568953975095667],
            [61.162627194141237, 36.419709159498098],
            [61.184436235712496, 36.286664215850976],
            [61.220818221823208, 36.17888216080712],
            [61.221373271453103, 36.139991135792798],
            [61.216936227175722, 36.108609122578287],
            [61.201100297462006, 36.069164221400399],
            [61.21166333951146, 35.937491060030879],
            [61.225818194719409, 35.903882142774364],
            [61.237073246696752, 35.891382210533948],
            [61.262082331271358, 35.816518068646815],
            [61.230473336117939, 35.692282169985731],
            [61.233182199593898, 35.662626996468191],
            [61.253609232965516, 35.632764119389094],
            [61.276554190058278, 35.607245079824054],
            [61.28790915432171, 35.555618088175208],
            [61.276518147874413, 35.517635996743877],
            [61.183600224687495, 35.348600166589222],
            [61.105827227114219, 35.281800086131568],
            [61.096236150559946, 35.236236060342549],
            [61.09978219852718, 35.176591107731895],
            [61.116382222536458, 35.163873246008109],
            [61.136036109157203, 35.132073143462037],
            [61.1316632702584, 35.085818113573282],
            [61.115545205682906, 35.016936135999174],
            [61.077491197521965, 34.879436043163821],
            [61.051100272386236, 34.789436028118473],
            [60.9766540521926, 34.650754086931954],
            [60.853191300282447, 34.567627064871317],
            [60.832909273836037, 34.561791080950115],
            [60.779163337058492, 34.55610010395435],
            [60.739991183010886, 34.544718149962421],
            [60.723873286073797, 34.527909081287476],
            [60.767773336439745, 34.477982107245339],
            [60.798254127422354, 34.463318135236932],
            [60.883609226991268, 34.355273223708934],
            [60.878873284045881, 34.319718028529564],
            [60.836100264383902, 34.322627051853885],
            [60.766663237179955, 34.322218014977693],
            [60.739300178911265, 34.320000163391398],
            [60.675127154554218, 34.314018166716323],
            [60.546109212480019, 34.196382178633826],
            [60.526100268439734, 34.169445088685677],
            [60.510973280098824, 34.145545097542367],
            [60.504436233794024, 34.122218093301967],
            [60.504163319025906, 34.091664212123419],
            [60.509182235023218, 34.060109028788702],
            [60.519991202112408, 34.010554043610568],
            [60.529163351144859, 33.961382111408682],
            [60.553591234551448, 33.821873211652701],
            [60.551654176722252, 33.797218011031134],
            [60.54055418995398, 33.776935984584725],
            [60.508691223133724, 33.739954021843118],
            [60.513327253793193, 33.689709038393687],
            [60.520263278690209, 33.66069105715718],
            [60.530827158929753, 33.639991109017217],
            [60.546109212480019, 33.625264105096846],
            [60.570545142513765, 33.611109082250948],
            [60.597209150055562, 33.597491171761703],
            [60.666100180085152, 33.567500051563911],
            [60.708973279671227, 33.560482219291657],
            [60.787773228024378, 33.561654009358733],
            [60.855827241200416, 33.558882113971038],
            [60.894018209659578, 33.555827077893071],
            [60.915545116369231, 33.547909029217124],
            [60.938882178893437, 33.517036132802843],
            [60.929827208867778, 33.504953954617974],
            [60.877563360213912, 33.500753950563023],
            [60.85270028839318, 33.484227016749571],
            [60.803318138058955, 33.390827134128642],
            [60.756945090973034, 33.344991199399828],
            [60.723873286073797, 33.308882128057235],
            [60.631382337035518, 33.207500159019958],
            [60.583263339513508, 33.149573161288629],
            [60.580554140761592, 33.088600179935099],
            [60.582500251046213, 33.066100134264218],
            [60.587909260818378, 33.043136066432226],
            [60.595827141856319, 33.013609136033551],
            [60.659709146534055, 32.818600134798544],
            [60.726382324977351, 32.623882153242448],
            [60.832773319366311, 32.321935992806061],
            [60.851800239627494, 32.261109024206121],
            [60.858327227648488, 32.225964207007621],
            [60.855000282630897, 32.190544966298148],
            [60.836654140547353, 32.090273096520491],
            [60.814436229738078, 32.0194361238444],
            [60.806936102755685, 31.996664169233554],
            [60.823463371845151, 31.948464202526736],
            [60.824163260762248, 31.774436110826798],
            [60.811382199488378, 31.659718030885927],
            [60.848809241290155, 31.496109147776394],
            [61.104163251691205, 31.463053939045153],
            [61.188600197583639, 31.452218149865814],
            [61.450518244017587, 31.418200195733277],
            [61.71360925398551, 31.383327119836395],
            [61.740827137690928, 31.35332711482107],
            [61.771100225112377, 31.318327136909829],
            [61.766800308771337, 31.256182197850876],
            [61.788327215480763, 31.21721808264067],
            [61.811100175919762, 31.177218131833058],
            [61.850127155404152, 31.023882109284159],
            [61.758327210465666, 30.790273102713797],
            [61.372218192371236, 30.381664206586663],
            [61.277491286836039, 30.284445025954028],
            [61.216663312407604, 30.222773161511554],
            [61.180273279669677, 30.185827073315579],
            [61.041936169980829, 30.043609142445689],
            [60.910818225879211, 29.907218143041462],
            [60.871654118458594, 29.866936057372243],
            [60.86630026160941, 29.863654039355765],
            [61.041518248287446, 29.683464242976299],
            [61.282491259732183, 29.434164145979821],
            [61.314436201565456, 29.395826997128779],
            [61.353463348687853, 29.291527119949876],
            [61.360273309760771, 29.265827031275819],
            [61.428045188075203, 29.151382201379022],
            [61.511936136793281, 29.071109049719723],
            [61.547618233986839, 28.984536059616062],
            [61.582773277107322, 28.909436050973056],
            [61.637218264611874, 28.80638205988555],
            [61.651382339913312, 28.785273074869451],
            [61.821936132737335, 28.626109115026395],
            [61.905545114231757, 28.555000065772504],
            [61.925000182108647, 28.541245027346918],
            [61.945963322008879, 28.531800131184468],
            [62.208600200461433, 28.459435975745407],
            [62.31665416444514, 28.43888220799721],
            [62.399854109063369, 28.419436024937795],
            [62.489091203279827, 28.355618058000346],
            [62.530818161422246, 28.295000134066242],
            [62.592491199331221, 28.233609063381039],
            [62.632909239470337, 28.240273179338431],
            [62.686936137642988, 28.250273125130718],
            [62.782218260446484, 28.26374502286609],
            [62.788891261221181, 28.212491193547578],
            [62.783336238695142, 28.171936025472291],
            [62.769154058482684, 28.076109078960883],
            [62.759163332783714, 27.995273166320771],
            [62.765000154895404, 27.97277312064989],
            [62.772491229422087, 27.945273102082865],
            [62.802491234437412, 27.835000113046419],
            [62.815545210479399, 27.786944985626519],
            [62.821936244030326, 27.760273099095457],
            [62.823327137047045, 27.73971815788093],
            [62.839709231573124, 27.474436118417032],
            [62.828882327211147, 27.447218067073607],
            [62.800554176608046, 27.384444988188875],
            [62.780273323628137, 27.266800115289072],
            [62.814854206379607, 27.234991127925468],
            [62.935891235503902, 27.214718153934541],
            [62.99860027664829, 27.230273122252925],
            [63.039163323712785, 27.241382161476565],
            [63.201936195797259, 27.267636126314073],
            [63.284854173191889, 27.222218113278814],
            [63.330273192055643, 27.148882159982975],
            [63.328809208842756, 27.127982219632656],
            [63.276518202822615, 27.12055400937993],
            [63.252636148952149, 27.080554058572318],
            [63.253054070645817, 27.051391070410489],
            [63.258191171478359, 26.940409140001634],
            [63.216100271098696, 26.853882082727409],
            [63.185127127122144, 26.639164209586681],
            [63.16360927286803, 26.630553983344782],
            [63.059573258001933, 26.635553956240813],
            [63.015827099378726, 26.643609132853101],
            [62.97360024452928, 26.647773094724073],
            [62.829163288606424, 26.651109092197316],
            [62.770263319551816, 26.648745060219142],
            [62.745654051760084, 26.618882183140272],
            [62.703882334254331, 26.602218121390706],
            [62.600827169700494, 26.580273125349549],
            [62.546663311229452, 26.577218089271582],
            [62.508609135430504, 26.574435967961904],
            [62.437909290690641, 26.566527139379673],
            [62.315273329711999, 26.509027115797323],
            [62.272563341962325, 26.420409108951219],
            [62.288609154531798, 26.379018097488824],
            [62.274891164118685, 26.356600026831075],
            [62.229163355941012, 26.360136016514673],
            [62.18277320977279, 26.371109101268416],
            [62.13895412859182, 26.37954498392395],
            [62.103882233950856, 26.314445088711295],
            [62.081109273511856, 26.314164127315962],
            [62.060818194609965, 26.31166414086789],
            [61.87096339329014, 26.24401815873955],
            [61.854854213532178, 26.23055397235504],
            [61.844154211184559, 26.211664180030084],
            [61.832773263021295, 26.179436097506482],
            [61.816936159841134, 26.108609015476077],
            [61.794154146946653, 25.958327023176651],
            [61.781936181929723, 25.86999115119221],
            [61.779991245111546, 25.843891078097386],
            [61.761936122707084, 25.807809164121295],
            [61.718045124796447, 25.800691084286768],
            [61.687627198087796, 25.797500093738904],
            [61.665545074110298, 25.590554088882186],
            [61.659709257827274, 25.506382178769002],
            [61.651927163621394, 25.390273205811184],
            [61.649163314860516, 25.348054062312656],
            [61.643609130524794, 25.298335965298349],
            [61.611027304723507, 25.197645049700043],
            [61.591575916000181, 25.046085373000096],
            [61.591547681000236, 24.323491972000056],
            [61.41666671500019, 23.346666633400105],
            [60.965252787000225, 23.602879738000127],
            [60.866768463000085, 23.656144956000162],
            [60.81614438600019, 23.680092013000092],
            [60.788027814000145, 23.693392188000018],
            [60.763572607000242, 23.704499363000025],
            [60.755839583000238, 23.70801158300003],
            [60.456786739999984, 23.842635913000095],
            [60.450374911000154, 23.845508666],
            [60.421147409000014, 23.851518709],
            [60.374424890000171, 23.873222342000133],
            [60.280925012000182, 23.91665508300008],
            [60.265637959000173, 23.925784546000102],
            [60.233502407000287, 23.944975972000123],
            [60.211759636500176, 23.959369689000084],
            [60.114602219000062, 24.023687911000195],
            [60.098287750000168, 24.032451386000105],
            [59.961851024000055, 24.105739698999969],
            [59.905970665000268, 24.128708749500106],
            [59.901694203000147, 24.130481582333346],
            [59.895362881000096, 24.133780063000074],
            [59.602352631000031, 24.283773298000142],
            [59.532020392000163, 24.319776680000132],
            [59.503161396500218, 24.330581425000162],
            [59.358802605000164, 24.38462904000022],
            [59.348042943000223, 24.390378302000045],
            [59.346270207000089, 24.391313094000111],
            [59.32815551400023, 24.400865253000234],
            [59.324841125000205, 24.402612982000164],
            [59.285702535000155, 24.413914544000022],
            [59.249679908000246, 24.424316349000094],
            [59.144703330000141, 24.454629127000146],
            [59.099858583000014, 24.467093778000219],
            [59.089533555000173, 24.469963632000145],
            [58.767379009000081, 24.558896774000175],
            [58.63962895900022, 24.620851985000058],
            [58.499078446000055, 24.689015063000113],
            [58.478992318000223, 24.693902293000178],
            [58.431156645000101, 24.700678396],
            [58.400447597000152, 24.700525498000104],
            [58.387810278000188, 24.700462578000042],
            [58.378807181500008, 24.700661143500099],
            [58.28291619200013, 24.702776043000085],
            [58.237451654000154, 24.703605211000138],
            [58.211154371000248, 24.704084813000179],
            [58.150010812000176, 24.706566592000115],
            [58.121690204000288, 24.707544517000088],
            [58.099392178000073, 24.708314480000126],
            [58.096362279000118, 24.708428921000063],
            [58.084187365000162, 24.708888774000229],
            [57.725012851000201, 24.719247816000063],
            [57.716785620000053, 24.722169797000163],
            [57.710925357500287, 24.724236653999981],
            [57.706531944000233, 24.725786168000127],
            [57.697530301000199, 24.727312161000157],
            [57.693019153000137, 24.728883715],
            [57.668075673000089, 24.737609561000056],
            [57.581168411000021, 24.785205000000246],
            [57.531259896000165, 24.817100161000042],
            [57.511762715000145, 24.82956027299997],
            [57.484850756500066, 24.847638377000067],
            [57.341967632000205, 24.948524587000179],
            [57.29810687100013, 24.97959191800004],
            [57.288162108000222, 24.986635965000119],
            [57.286937955000099, 24.987501867],
            [57.280556548000277, 24.992015738000021],
            [57.279972395500238, 24.992533434749987],
            [57.278716289000158, 24.993651514000135],
            [57.272156118000169, 24.999132589000055],
            [57.222201401000177, 25.040973156000121],
            [57.195413012000188, 25.063410304000115],
            [57.193312953000202, 25.064945953000063],
            [57.186845164000204, 25.069675462000134],
            [57.180762363000042, 25.074134282000102],
            [57.17729303300024, 25.076677374000042],
            [57.173110944000143, 25.079685552000058],
            [57.163093226000115, 25.086891300000161],
            [57.162196338000172, 25.087543252999978],
            [57.141025269000238, 25.102968179000129],
            [57.125662434091112, 25.11452814800009],
            [57.120137668000098, 25.12012701000009],
            [57.110249183000064, 25.130189859500092],
            [57.108153818000119, 25.132334927000045],
            [57.095907775000057, 25.146520902000077],
            [57.093797130000098, 25.149092492000023],
            [57.091810322000185, 25.151513200000124],
            [57.087242679000212, 25.158574342000122],
            [57.080325950000145, 25.169266948000029],
            [57.046734904000118, 25.220941253000149],
            [57.034288561000153, 25.240346870000053],
            [57.019133856000053, 25.263520436000078],
            [56.972040766000191, 25.339187511000091],
            [56.931046239000125, 25.405055685000036],
            [56.930620443000066, 25.405900232000192],
            [56.915974036000136, 25.43570969700005],
            [56.910160578000216, 25.447541681000075],
            [56.900932433000207, 25.473887239000135],
            [56.867847436000204, 25.568342051000116],
            [56.858077853000225, 25.596920420000117],
            [56.843130037000094, 25.640646362000098],
            [56.841765403000181, 25.650542297000072],
            [56.841363908000062, 25.653453827000135],
            [56.840910195000106, 25.656744026000126],
            [56.849353434000136, 25.723632849000012],
            [56.855814890000261, 25.774821645000031],
            [56.83507847400017, 25.933455224000227],
            [56.830555557000082, 25.968055542000116],
            [56.832958151000298, 25.996886708000147],
            [56.837500000000148, 26.051388889000123],
            [56.800000000000296, 26.193055556000104],
            [56.798368199000208, 26.241193693000184],
            [56.79722222200013, 26.275],
            [56.799374263000118, 26.322344902],
            [56.801388889, 26.366666667000175],
            [56.795440915000142, 26.399805379999975],
            [56.7916666670003, 26.420833333000019],
            [56.793078946000151, 26.47732449800003],
            [56.795133647000284, 26.559512530000063],
            [56.795833332000143, 26.587499959500065],
            [56.793064217000278, 26.592392106000034],
            [56.784114487000181, 26.608203295000067],
            [56.754166667000277, 26.661111111000025],
            [56.742679333000183, 26.678725024000187],
            [56.733333333000132, 26.69305555599999],
            [56.714496330000202, 26.709799559000089],
            [56.683333360000177, 26.737499976000095],
            [56.650819574000252, 26.729371560000118],
            [56.549999906000153, 26.704166630000088],
            [56.455243041500239, 26.666856114500149],
            [56.327777778000154, 26.616666667000118],
            [56.318223937000027, 26.612392580000062],
            [56.274999776500295, 26.593055537499993],
            [56.27373563600014, 26.592950192000217],
            [56.241666667000175, 26.590277778000086],
            [56.211578891000045, 26.571242655000106],
            [56.182395586000098, 26.552779747000159],
            [56.173611290500219, 26.547222336000118],
            [56.158694377000245, 26.532983522000109],
            [56.143055556000093, 26.518055556000036],
            [56.141074028000276, 26.515443542000128],
            [56.123210446000201, 26.491896094000083],
            [56.112500000000239, 26.477777778000075],
            [56.089547505000155, 26.462212292500112],
            [55.928559036000195, 26.353036205000194],
            [55.870833333000292, 26.313888889000111],
            [55.795833333000076, 26.276388889000089],
            [55.757958236000036, 26.263763673000199],
            [55.722563572000183, 26.243956112999967],
            [55.71992870000031, 26.24248158600011],
            [55.717547588000116, 26.240926574000071],
            [55.683920239000145, 26.218965856500063],
            [55.682623778999982, 26.217989879000015],
            [55.658765821000031, 26.200029293999989],
            [55.627920024000218, 26.17680817300004],
            [55.617374676000225, 26.167116875000204],
            [55.615414877000205, 26.165320571500104],
            [55.611562023000261, 26.161855059000231],
            [55.587579127000254, 26.144469336000157],
            [55.571435130000225, 26.132766201000038],
            [55.569975186000079, 26.131707856500157],
            [55.557322280000108, 26.128113307000149],
            [55.552760739000121, 26.126817438000103],
            [55.546844619000183, 26.124612149000029],
            [55.53672442800007, 26.120839753000155],
            [55.499340184000062, 26.103527118000045],
            [55.486572418000094, 26.097614368999984],
            [55.460192198000129, 26.084592040000132],
            [55.425297294000245, 26.067366522999976],
            [55.423228664000135, 26.066765510000039],
            [55.408977657000207, 26.062625075000028],
            [55.351534083499985, 26.047043627499988],
            [55.294952483500197, 26.031695989500179],
            [55.284296628500215, 26.028784555500181],
            [55.226289553000072, 26.015865321000206],
            [55.106543091000219, 25.989194702500185],
            [55.100963250000291, 25.987933698000063],
            [55.078742527000202, 25.972978082000054],
            [55.075248847000125, 25.970626668000193],
            [54.936316411500172, 25.877043443499986],
            [54.935239674000172, 25.876322609],
            [54.913342352000086, 25.861663181000154],
            [54.886823259000266, 25.844462176999969],
            [54.794159642000295, 25.806429287000128],
            [54.786401564333403, 25.803222628000071],
            [54.772628252000118, 25.797529680000139],
            [54.771148228000015, 25.796917939000082],
            [54.76720321200014, 25.795839268000066],
            [54.751944000000208, 25.791667000000189],
            [54.747221850000301, 25.788888807500143],
            [54.727024582000183, 25.762954515000104],
            [54.725799929250115, 25.761830471500048],
            [54.698388463000185, 25.737199966],
            [54.676446767000186, 25.72339317300009],
            [54.666684399000133, 25.717250211000021],
            [54.631625802000258, 25.702555127000096],
            [54.630226025000184, 25.70221819599999],
            [54.629126818000259, 25.701757246000227],
            [54.603023792000158, 25.695469969999976],
            [54.591775591000214, 25.692760685000223],
            [54.577584215, 25.691645927000025],
            [54.553261018000228, 25.689735297000198],
            [54.519764033000229, 25.692376945000092],
            [54.514747591000287, 25.692772553000168],
            [54.50814879200027, 25.693012239000097],
            [54.50694391900015, 25.693055967500186],
            [54.438332692500239, 25.665277975500089],
            [54.087777692500254, 25.636943972500035],
            [54.076652667000303, 25.635945838000055],
            [54.076003633000255, 25.635887607000086],
            [53.933843173000184, 25.6231330170001],
            [53.843831936000242, 25.589248618000056],
            [53.767027444000178, 25.56033585],
            [53.624497776000055, 25.511413713000024],
            [53.608771922000074, 25.505458305000047],
            [53.579643514000168, 25.49442732700021],
            [53.511652084000048, 25.478071300000067],
            [53.304003625000178, 25.470246487000168],
            [53.290432838000243, 25.470376297000087],
            [53.189180887000191, 25.471344816000055],
            [53.184699727000122, 25.47207833300024],
            [53.179355724000175, 25.472953087000121],
            [53.122499873000152, 25.483675295000182],
            [53.077739705000226, 25.498996106000163],
            [53.065863241000216, 25.507709261000116],
            [53.054141312000183, 25.516309042000159],
            [53.034721886000113, 25.530555883200066],
            [52.708333000000152, 26.105556],
            [52.202778000000194, 26.556944000000158],
            [51.734722000000147, 26.938889],
            [51.383333000000221, 27.009722],
            [51.121801285000117, 27.043114553000066],
            [51.098333000000196, 27.04611100000011],
            [50.950000000000102, 27.10833300000013],
            [50.900000000000205, 27.166667],
            [50.758333333000053, 27.308333333000121],
            [50.616666667000118, 27.441666667000106],
            [50.402636694000307, 27.770943548000218],
            [50.402981618000069, 27.772464145000129],
            [50.40379305100015, 27.776041342000099],
            [50.394419809000169, 27.818112328000097],
            [50.386746456000083, 27.852553511999986],
            [50.386115404750228, 27.853531451250092],
            [50.385951897000155, 27.854254156000195],
            [50.383375318000219, 27.857676039000069],
            [50.36898414250021, 27.879722801750063],
            [50.31110503299999, 27.880327525999974],
            [50.281419149000158, 27.880488178000121],
            [50.176649093000208, 27.881205014999978],
            [50.133435945000201, 27.880934669],
            [50.042268065000087, 27.881189244000041],
            [50.040161062000095, 27.881184043000019],
            [49.983926163000262, 27.881290574500099],
            [49.964746423000122, 27.908354785000142],
            [49.947368676000167, 27.984812414000089],
            [49.947698481000032, 27.986291000000222],
            [49.947466432000112, 27.987311201000125],
            [49.947628187000248, 27.988036364500033],
            [49.947997088000136, 27.989690159000133],
            [49.947503223000183, 27.992086471000164],
            [49.947560471000315, 27.993549689500128],
            [49.947600988000204, 27.994585258000086],
            [49.964545259000204, 28.06382350399997],
            [50.003699824000023, 28.119451421000178],
            [50.004298511000115, 28.120052973000213],
            [50.004897205000248, 28.120654522],
            [50.009776026000111, 28.123740854000062],
            [50.01380628350006, 28.128884729000163],
            [50.014743085000077, 28.129933629000107],
            [50.078802740000015, 28.167756803000202],
            [49.936666667000281, 28.293333333000135],
            [49.848333333000198, 28.35],
            [49.796666667000238, 28.41166666700002],
            [49.790000000000305, 28.406666667000195],
            [49.700000000000159, 28.465],
            [49.661666667000191, 28.58],
            [49.60333333300008, 28.62],
            [49.558333333000178, 28.681666667000059],
            [49.571666667000045, 28.688333333000116],
            [49.555540989000235, 28.770971288],
            [49.539830464000204, 28.848867742000209],
            [49.534093686000176, 28.880738734000204],
            [49.529631747000167, 28.909422627000026],
            [49.524879616000192, 28.93500000000023],
            [49.506367308000222, 28.997325785000044],
            [49.420334369000159, 29.058225838999988],
            [49.218206594000151, 29.351229304000071],
            [49.207299540000065, 29.367257847000133],
            [49.146388866000166, 29.404191574000095],
            [48.932098543000166, 29.534228410000026],
            [48.837479904000077, 29.568220567000139],
            [48.763725919000194, 29.594982862000109],
            [48.749148822000166, 29.609146053000188],
            [48.720431310000095, 29.677989404000186],
            [48.656838385000242, 29.83153134],
            [48.63441706500015, 29.871250940000152],
            [48.624913408000111, 29.887685010000098],
            [48.615857415000164, 29.903547163999974],
            [48.612090635000243, 29.909263646000113],
            [48.61150325400024, 29.910517096000092],
            [48.605895445000158, 29.920691567000176],
            [48.597353413000178, 29.930847166000149],
            [48.54555410491929, 29.963026912166242],
            [48.473382293858492, 29.982900191042802],
            [48.450127206347531, 30.005409121530988],
            [48.411100226863198, 30.10360915247611],
            [48.410127087901742, 30.16999114360209],
            [48.411945122705731, 30.199673139209779],
            [48.289436231379455, 30.329718032063909],
            [48.16042717412256, 30.427082219621923],
            [48.141382316588363, 30.439991188738531],
            [48.107545076288716, 30.454318040600185],
            [48.067518135752948, 30.463791099957575],
            [48.032491168113154, 30.491382145993214],
            [48.033054096732087, 30.588053988347156],
            [48.034436104931558, 30.656936133559213],
            [48.036663344249462, 30.946382194117007],
            [48.036663344249462, 30.996109175948845],
            [47.923882322137786, 30.999436120966436],
            [47.843318150799348, 30.999718088190207],
            [47.693882227808956, 31.00110914884516],
            [47.694709186378475, 31.111382137881435],
            [47.695545197403533, 31.194164160806338],
            [47.695827164627133, 31.221664179373363],
            [47.696382214257028, 31.235427096787816],
            [47.696382214257028, 31.304445028831822],
            [47.697209172826547, 31.407773108153776],
            [47.716663234874943, 31.455553979700937],
            [47.748600130081229, 31.530282167118003],
            [47.801100180767435, 31.65304503011123],
            [47.833327089824536, 31.727773217528295],
            [47.864436188270929, 31.798609016738055],
            [47.849163354814209, 31.819718001753984],
            [47.786945157921309, 31.889164249051476],
            [47.737500143313127, 31.936664159203303],
            [47.633191213678913, 32.053182169037143],
            [47.602682259501364, 32.100591051720244],
            [47.529436160207808, 32.196935999849245],
            [47.416082318831684, 32.34945394864593],
            [47.437900245220249, 32.385553967533212],
            [47.361236173440147, 32.473809037970739],
            [47.270963243626511, 32.48860007963151],
            [47.24886335001429, 32.473500081018983],
            [47.198873176422012, 32.462209154495781],
            [47.151382318725837, 32.458464120146516],
            [47.13277332015781, 32.465827119192511],
            [47.071936125636057, 32.50443600934517],
            [47.054436220499326, 32.515827015792468],
            [46.956100235084534, 32.584718045822001],
            [46.933600189413653, 32.600545090718185],
            [46.903318217174899, 32.623044968751117],
            [46.882073277688903, 32.643191208365579],
            [46.857218252495329, 32.666936134299704],
            [46.840273229350487, 32.680273083393615],
            [46.747491260633126, 32.753053987059559],
            [46.717763332195773, 32.769436081585638],
            [46.695827220972149, 32.778882151214532],
            [46.653882165708353, 32.798882210437341],
            [46.557527159295546, 32.857873206960505],
            [46.493600227616469, 32.902491083516395],
            [46.45082720795466, 32.925273096411104],
            [46.414991219018191, 32.940409137207439],
            [46.373318240332196, 32.951382221961353],
            [46.304709177526263, 32.963609071795645],
            [46.27540922906627, 32.966800062343339],
            [46.245000187174924, 32.965273214856666],
            [46.203882258118654, 32.959164148529226],
            [46.178327176369976, 32.953326991141523],
            [46.15165411637247, 32.952635987041731],
            [46.106418158274721, 32.971064104138577],
            [46.097018189113271, 33.00297317142639],
            [46.113054111037115, 33.083054042226365],
            [46.145136180807015, 33.135827007680689],
            [46.188318237344788, 33.175409036794804],
            [46.199018239692407, 33.193464159199209],
            [46.177145160380917, 33.264782085480931],
            [46.126936219115123, 33.303600187937491],
            [46.049163221541903, 33.383600089552488],
            [46.04888226014674, 33.406373217629493],
            [46.044227118748154, 33.437354072957078],
            [46.029163329957271, 33.460544955089574],
            [46.006473182721834, 33.485782195069191],
            [45.951936161920401, 33.479709170925332],
            [45.930545209680645, 33.482764207003527],
            [45.874227202087582, 33.499791205161614],
            [45.890673334354119, 33.521618016367484],
            [45.944782207540072, 33.554364100508678],
            [45.898882235070801, 33.633045025836395],
            [45.815063370720225, 33.622418113684716],
            [45.786091154675262, 33.600491054916574],
            [45.751691153395029, 33.595027059859262],
            [45.752327172209732, 33.637282077903478],
            [45.696727158444617, 33.669436064402589],
            [45.647382223760474, 33.735064187154876],
            [45.641054054483561, 33.767391176136016],
            [45.550273181336081, 33.888600034276237],
            [45.500136156799783, 33.945891180830586],
            [45.4726002636869, 33.940535982877066],
            [45.446800262726754, 33.942491145617169],
            [45.423736114970495, 33.951100198392567],
            [45.403609153733441, 33.975200181746231],
            [45.467209191187578, 34.077082215128314],
            [45.547254187441922, 34.134191139107259],
            [45.566518147925819, 34.15130916473413],
            [45.584163227625766, 34.301273147626262],
            [45.573327103170243, 34.324026997326143],
            [45.549436164482586, 34.345545019218264],
            [45.519854081160986, 34.347354001566714],
            [45.49235406259379, 34.335127151732422],
            [45.460545075230357, 34.378464105841431],
            [45.438636121372838, 34.457836035268826],
            [45.562927172957188, 34.586109161424986],
            [45.648463321635177, 34.573473107076495],
            [45.714673316107451, 34.557609181806185],
            [45.729573322510021, 34.585618149535492],
            [45.708327209557694, 34.659154095041686],
            [45.684018181538363, 34.69930005860283],
            [45.659645115778574, 34.715335980526845],
            [45.651654144544921, 34.735200085279757],
            [45.691100219189252, 34.816518124991944],
            [45.712627125898734, 34.829582159317738],
            [45.738327214572791, 34.83097321997252],
            [45.765054086388773, 34.846273210795573],
            [45.79773613278951, 34.914036036654579],
            [45.848745210534673, 34.896936115938502],
            [45.867763245978722, 34.903600064257716],
            [45.892354073583306, 34.973044970450587],
            [45.880545145442738, 34.998882187061213],
            [45.877773250054872, 35.032491104317728],
            [45.949818222620024, 35.093273145916385],
            [45.992063350018384, 35.075236128422716],
            [46.035754188080489, 35.059227028589021],
            [46.16600913142895, 35.108518151454518],
            [46.186600282465292, 35.221718101088015],
            [46.160200137236075, 35.234153995588144],
            [46.133045117804613, 35.272491144439016],
            [46.148045204131108, 35.2974641868297],
            [46.064991272266525, 35.383327062094224],
            [46.051100279371212, 35.399164165274385],
            [45.99096330904257, 35.485200043020996],
            [45.97999123011715, 35.584718044424847],
            [46.034163302853415, 35.689018089241927],
            [46.072773198834398, 35.69665400305604],
            [46.110691252525442, 35.692627001483288],
            [46.151245079496277, 35.694845020707589],
            [46.229427113945746, 35.7122091390126],
            [46.268600273821619, 35.72804506872609],
            [46.334163353005124, 35.777491089162595],
            [46.345200140223142, 35.814191084680772],
            [46.323609195773201, 35.832636133222081],
            [46.290963359194279, 35.831518154973594],
            [46.258891180070236, 35.813045110875464],
            [46.20069126757096, 35.802773088505205],
            [46.172909114142158, 35.807353966241521],
            [46.147736079541176, 35.824036132902052],
            [46.135545104252685, 35.84735408468714],
            [46.089363332197991, 35.865482129649365],
            [45.88817318026787, 35.831982177134591],
            [45.867909258732425, 35.817773174831814],
            [45.757127152895521, 35.817345027216504],
            [45.739991189995862, 35.832218043890748],
            [45.732909152345144, 35.853464156842904],
            [45.718882204979764, 35.879573114755033],
            [45.682500218869052, 35.921936091712496],
            [45.663318233398456, 35.940545090280352],
            [45.624518235852918, 35.966282226966769],
            [45.520073184110629, 36.011173185928783],
            [45.51054513946832, 36.01777309650754],
            [45.472482246490017, 36.016800125184432],
            [45.413882181932024, 35.995418057762038],
            [45.35443621570306, 36.064435989806043],
            [45.343600258885544, 36.133882069465358],
            [45.319709152559881, 36.238318068752108],
            [45.279163372216033, 36.381100101707432],
            [45.243736084879572, 36.428318044635489],
            [45.216100279480656, 36.428882146720923],
            [45.193882201033375, 36.41943607709203],
            [45.165545165612798, 36.411373189128824],
            [45.139163292932381, 36.408873202680581],
            [45.106945101054635, 36.418182144373304],
            [45.086509182865484, 36.435200090076023],
            [45.083927221404338, 36.457644982823808],
            [45.055263291758877, 36.50610914711973],
            [45.013091254556571, 36.54285406963902],
            [45.015827107760458, 36.557773186780707],
            [45.038818165320833, 36.614227148843895],
            [45.062763251103263, 36.639636050200693],
            [45.065409250305038, 36.683045088677488],
            [45.025554138784855, 36.736382156216848],
            [44.939227241359163, 36.782073084020453],
            [44.897763307338835, 36.782344992960077],
            [44.870536203539928, 36.784573238106475],
            [44.853045183220502, 36.794582068716295],
            [44.844500168185391, 36.821927022074135],
            [44.882491144434425, 36.870682206049025],
            [44.905536181451225, 36.892553944256065],
            [44.909427228554193, 36.920536089895222],
            [44.903118170016569, 37.030473132251643],
            [44.856518140992193, 37.052627005320289],
            [44.817491161507803, 37.05581816350616],
            [44.784991170044634, 37.084718127545443],
            [44.76617329444889, 37.112282183852926],
            [44.787336091283521, 37.149709058016697],
            [44.792763373604828, 37.17499105735925],
            [44.820545191757333, 37.268744991571282],
            [44.818182165607482, 37.297418141310231],
            [44.801654058327642, 37.321664137417429],
            [44.648600170640407, 37.427773164582732],
            [44.612800223887746, 37.434227062407786],
            [44.588854132276992, 37.443091092678685],
            [44.584436198739013, 37.613882093363145],
            [44.598873188808568, 37.69081807408287],
            [44.617763316409935, 37.717973093514331],
            [44.576791232469191, 37.766800194218661],
            [44.293054086180177, 37.873318090622035],
            [44.223973289862215, 37.899153966127983],
            [44.238882181082147, 37.960273127873393],
            [44.323318121146087, 38.097209118623539],
            [44.399018106420101, 38.21130006456707],
            [44.473218233935768, 38.316745077361077],
            [44.482518123172952, 38.341300198058207],
            [44.436727115445478, 38.394809094613265],
            [44.318145145767915, 38.382054017239568],
            [44.305263334017809, 38.400536113792839],
            [44.304163293041995, 38.449436137055102],
            [44.311763332310477, 38.469927040529399],
            [44.321527243708658, 38.509373115173389],
            [44.31721810727413, 38.613045020164634],
            [44.306518104926681, 38.647282077246984],
            [44.261800148446525, 38.724291148162479],
            [44.30319115990892, 38.809982194411475],
            [44.300263361121665, 38.842627025162074],
            [44.283663337112387, 38.862491129914986],
            [44.250545096469182, 38.865544992526509],
            [44.209427167412912, 38.893464106253532],
            [44.17332714852563, 38.968036054995082],
            [44.162418101512344, 39.00520007267437],
            [44.198036160965671, 39.056673172580474],
            [44.216445167323229, 39.136518008986513],
            [44.07471824834272, 39.336100176606692],
            [44.0605451205858, 39.352482103494765],
            [44.034954164291094, 39.377445087601416],
            [44.036382272958349, 39.385473106847428],
            [44.076863345009428, 39.411653981488996],
            [44.200954069107127, 39.416936089246747],
            [44.230918199576763, 39.40845410612377],
            [44.304527235278726, 39.38729114165119],
            [44.340273202574906, 39.395544969369041],
            [44.401100171174846, 39.41651816755342],
            [44.425827120887504, 39.436444968942283],
            [44.477891144969362, 39.640653947128271],
            [44.471082189724768, 39.698864253187708],
            [44.608463259534744, 39.779154001015343],
            [44.736718113141706, 39.70180009860195],
            [44.81304506477494, 39.630809066545112],
            [44.868463358878842, 39.622345020694979],
            [44.888600210761865, 39.605818086881698],
            [44.903045079820629, 39.555545107875446],
            [44.908045052716773, 39.535827015876293],
            [44.927773202999788, 39.476645079598086],
            [44.954573332649659, 39.432354097266909],
            [44.974709178704416, 39.42053611667086],
            [45.002218249726923, 39.416691170035449],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "IRQ", Country: "Iraq" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [44.787336091283493, 37.149709058016626],
            [44.766173294448919, 37.11228218385294],
            [44.784991170044549, 37.084718127545372],
            [44.817491161507832, 37.055818163506174],
            [44.856518140992108, 37.05262700532019],
            [44.903118170016484, 37.030473132251544],
            [44.909427228554222, 36.920536089895265],
            [44.905536181451197, 36.892553944256079],
            [44.882491144434397, 36.87068220604904],
            [44.844500168185419, 36.821927022074178],
            [44.85304518322053, 36.794582068716252],
            [44.870536203539842, 36.784573238106518],
            [44.897763307338749, 36.782344992960063],
            [44.939227241359077, 36.782073084020354],
            [45.02555413878477, 36.736382156216777],
            [45.065409250305066, 36.683045088677446],
            [45.062763251103178, 36.639636050200679],
            [45.038818165320805, 36.614227148843796],
            [45.015827107760487, 36.557773186780636],
            [45.013091254556485, 36.542854069638949],
            [45.055263291758848, 36.506109147119773],
            [45.083927221404252, 36.457644982823822],
            [45.086509182865512, 36.435200090076066],
            [45.106945101054663, 36.41818214437329],
            [45.139163292932409, 36.408873202680596],
            [45.165545165612713, 36.411373189128867],
            [45.193882201033347, 36.41943607709193],
            [45.216100279480628, 36.428882146720824],
            [45.243736084879487, 36.428318044635503],
            [45.279163372215947, 36.381100101707361],
            [45.319709152559795, 36.238318068752122],
            [45.343600258885516, 36.133882069465258],
            [45.354436215703032, 36.064435989806057],
            [45.413882181931939, 35.995418057761967],
            [45.472482246489932, 36.016800125184389],
            [45.510545139468292, 36.017773096507526],
            [45.520073184110544, 36.011173185928769],
            [45.624518235852889, 35.966282226966669],
            [45.663318233398428, 35.940545090280366],
            [45.682500218869023, 35.92193609171251],
            [45.718882204979735, 35.879573114755019],
            [45.732909152345059, 35.853464156842833],
            [45.739991189995777, 35.832218043890677],
            [45.757127152895549, 35.817345027216433],
            [45.86790925873234, 35.817773174831828],
            [45.888173180267785, 35.831982177134606],
            [46.089363332197905, 35.865482129649351],
            [46.1355451042526, 35.847354084687041],
            [46.147736079541147, 35.824036132901952],
            [46.172909114142129, 35.807353966241536],
            [46.200691267570875, 35.802773088505191],
            [46.258891180070265, 35.813045110875393],
            [46.290963359194308, 35.831518154973551],
            [46.32360919577323, 35.832636133222096],
            [46.345200140223113, 35.814191084680758],
            [46.334163353005039, 35.777491089162609],
            [46.268600273821647, 35.728045068726018],
            [46.229427113945661, 35.712209139012529],
            [46.151245079496306, 35.694845020707604],
            [46.110691252525356, 35.692627001483245],
            [46.072773198834312, 35.696654003055968],
            [46.034163302853386, 35.689018089241827],
            [45.979991230117179, 35.584718044424861],
            [45.990963309042598, 35.485200043020896],
            [46.051100279371127, 35.399164165274314],
            [46.064991272266553, 35.383327062094239],
            [46.14804520413108, 35.2974641868296],
            [46.133045117804528, 35.272491144439059],
            [46.160200137235989, 35.234153995588102],
            [46.186600282465207, 35.221718101088058],
            [46.166009131428865, 35.108518151454419],
            [46.035754188080517, 35.059227028589035],
            [45.992063350018412, 35.075236128422731],
            [45.949818222619939, 35.09327314591637],
            [45.877773250054844, 35.032491104317657],
            [45.880545145442653, 34.998882187061142],
            [45.892354073583334, 34.973044970450573],
            [45.867763245978637, 34.903600064257759],
            [45.848745210534588, 34.896936115938544],
            [45.797736132789424, 34.91403603665448],
            [45.765054086388801, 34.846273210795616],
            [45.73832721457282, 34.830973219972535],
            [45.712627125898649, 34.829582159317695],
            [45.691100219189167, 34.816518124991902],
            [45.651654144544892, 34.735200085279686],
            [45.659645115778602, 34.715335980526888],
            [45.684018181538278, 34.699300058602844],
            [45.708327209557609, 34.659154095041615],
            [45.729573322509935, 34.585618149535421],
            [45.714673316107479, 34.5576091818062],
            [45.648463321635091, 34.573473107076424],
            [45.562927172957217, 34.586109161424886],
            [45.438636121372866, 34.457836035268755],
            [45.460545075230272, 34.378464105841473],
            [45.492354062593762, 34.335127151732351],
            [45.519854081160901, 34.347354001566643],
            [45.549436164482501, 34.345545019218193],
            [45.573327103170215, 34.324026997326044],
            [45.584163227625737, 34.301273147626247],
            [45.566518147925791, 34.151309164734116],
            [45.547254187441837, 34.134191139107187],
            [45.467209191187493, 34.077082215128215],
            [45.403609153733356, 33.97520018174616],
            [45.423736114970467, 33.951100198392581],
            [45.446800262726669, 33.942491145617126],
            [45.472600263686928, 33.940535982876966],
            [45.500136156799698, 33.945891180830571],
            [45.550273181335996, 33.888600034276195],
            [45.641054054483533, 33.76739117613603],
            [45.647382223760502, 33.735064187154777],
            [45.696727158444588, 33.669436064402632],
            [45.752327172209647, 33.637282077903492],
            [45.751691153394944, 33.595027059859191],
            [45.78609115467529, 33.600491054916588],
            [45.815063370720196, 33.622418113684645],
            [45.898882235070715, 33.633045025836324],
            [45.944782207540044, 33.554364100508579],
            [45.890673334354034, 33.521618016367412],
            [45.874227202087496, 33.499791205161515],
            [45.930545209680673, 33.482764207003427],
            [45.951936161920315, 33.479709170925346],
            [46.006473182721749, 33.485782195069206],
            [46.029163329957242, 33.460544955089588],
            [46.044227118748182, 33.437354072957064],
            [46.048882260146655, 33.406373217629508],
            [46.049163221541875, 33.38360008955253],
            [46.126936219115038, 33.303600187937391],
            [46.177145160380888, 33.264782085480974],
            [46.199018239692435, 33.193464159199252],
            [46.188318237344816, 33.175409036794704],
            [46.145136180806986, 33.135827007680618],
            [46.113054111037144, 33.083054042226408],
            [46.097018189113186, 33.002973171426405],
            [46.106418158274636, 32.971064104138563],
            [46.151654116372498, 32.95263598704166],
            [46.178327176369891, 32.953326991141566],
            [46.203882258118682, 32.959164148529126],
            [46.245000187174838, 32.965273214856708],
            [46.275409229066241, 32.966800062343268],
            [46.304709177526234, 32.963609071795574],
            [46.373318240332111, 32.951382221961282],
            [46.414991219018106, 32.940409137207453],
            [46.450827207954575, 32.925273096411004],
            [46.493600227616383, 32.902491083516409],
            [46.557527159295461, 32.857873206960519],
            [46.653882165708268, 32.798882210437355],
            [46.69582722097212, 32.778882151214546],
            [46.717763332195688, 32.769436081585567],
            [46.747491260633097, 32.753053987059488],
            [46.840273229350402, 32.680273083393544],
            [46.857218252495244, 32.666936134299632],
            [46.882073277688875, 32.643191208365621],
            [46.903318217174814, 32.623044968751017],
            [46.933600189413568, 32.600545090718228],
            [46.956100235084449, 32.584718045821958],
            [47.054436220499241, 32.515827015792368],
            [47.071936125635972, 32.504436009345213],
            [47.132773320157725, 32.46582711919244],
            [47.151382318725751, 32.458464120146417],
            [47.19887317642204, 32.46220915449571],
            [47.248863350014318, 32.473500081018884],
            [47.270963243626426, 32.488600079631439],
            [47.361236173440062, 32.473809037970639],
            [47.437900245220163, 32.385553967533113],
            [47.416082318831599, 32.349453948645916],
            [47.52943616020778, 32.196935999849174],
            [47.602682259501336, 32.100591051720258],
            [47.633191213678828, 32.053182169037072],
            [47.737500143313099, 31.936664159203346],
            [47.786945157921224, 31.88916424905149],
            [47.849163354814181, 31.819718001753998],
            [47.864436188270957, 31.798609016738069],
            [47.833327089824508, 31.727773217528281],
            [47.801100180767349, 31.65304503011113],
            [47.748600130081257, 31.530282167117988],
            [47.716663234874972, 31.455553979700838],
            [47.697209172826575, 31.407773108153762],
            [47.696382214256943, 31.304445028831836],
            [47.696382214256943, 31.235427096787831],
            [47.695827164627048, 31.221664179373406],
            [47.695545197403504, 31.194164160806267],
            [47.69470918637839, 31.111382137881364],
            [47.693882227808871, 31.001109148845089],
            [47.84331815079932, 30.999718088190164],
            [47.923882322137757, 30.99943612096645],
            [48.03666334424949, 30.996109175948746],
            [48.03666334424949, 30.946382194117021],
            [48.03443610493153, 30.656936133559228],
            [48.033054096732002, 30.588053988347056],
            [48.032491168113182, 30.4913821459932],
            [48.067518135752863, 30.463791099957504],
            [48.107545076288687, 30.454318040600199],
            [48.141382316588277, 30.439991188738432],
            [48.160427174122589, 30.427082219621852],
            [48.289436231379483, 30.329718032063894],
            [48.411945122705703, 30.199673139209708],
            [48.41012708790177, 30.169991143602019],
            [48.411100226863169, 30.103609152476011],
            [48.450127206347446, 30.005409121531031],
            [48.473382293858407, 29.982900191042731],
            [48.545554104919262, 29.963026912166143],
            [48.597353413000093, 29.930847166000078],
            [48.605895445000129, 29.920691567000105],
            [48.611503254000155, 29.910517096],
            [48.612090635000158, 29.909263646000014],
            [48.615857415000079, 29.903547163999988],
            [48.624913408000026, 29.887685010000112],
            [48.634417065000065, 29.871250940000053],
            [48.656838385000157, 29.831531339999984],
            [48.720431310000123, 29.677989404000115],
            [48.749148822000137, 29.609146053000146],
            [48.701961510000189, 29.645183905000138],
            [48.696114729000129, 29.649683978000056],
            [48.687359621000127, 29.656484804000101],
            [48.650776564000154, 29.68697804900016],
            [48.613664264000164, 29.717504435000123],
            [48.586971125000019, 29.739048750000038],
            [48.5735542580002, 29.750433002000065],
            [48.567340923000103, 29.755439221000032],
            [48.563732629000157, 29.758216994000108],
            [48.540121554000137, 29.776588862999986],
            [48.538788905000075, 29.777498720000139],
            [48.534424406, 29.780240340000134],
            [48.513075921000137, 29.794182433000046],
            [48.507507319000155, 29.798629281],
            [48.49671758700012, 29.807020848999954],
            [48.487977542000209, 29.814094831999952],
            [48.484999921000139, 29.816584384000052],
            [48.473728146000013, 29.823414332000013],
            [48.454950558000206, 29.833335383000033],
            [48.450164271000148, 29.83604085000006],
            [48.443298169000201, 29.839577840999979],
            [48.438304001000148, 29.842074423000142],
            [48.434973887000154, 29.843739480000053],
            [48.432894071000106, 29.844778885999986],
            [48.42956395700017, 29.846028181000165],
            [48.424154027000014, 29.848316882000162],
            [48.419368744000195, 29.850398705000018],
            [48.414121504000178, 29.852675355000102],
            [48.337912493000061, 29.907029735000108],
            [48.275071141000041, 29.95495486500009],
            [48.254922742000161, 29.965045635000124],
            [48.242962047000134, 29.976034210000094],
            [48.228148259000108, 29.984680859],
            [48.207332029000128, 29.994167066],
            [48.194722584000061, 30.004999981000068],
            [48.184445023000222, 30.014444009000144],
            [48.164384999000191, 30.02210849500004],
            [48.154834519000104, 30.024582983000059],
            [48.146406799000175, 30.025767002000052],
            [48.133583447, 30.026555343],
            [48.121345577000142, 30.027756434000096],
            [48.10864574900009, 30.028435311000138],
            [48.098250690000015, 30.029916590000127],
            [48.088955291000076, 30.030152590000014],
            [48.076114867000086, 30.029313032000104],
            [48.068132032000022, 30.027876945000017],
            [48.061490884000051, 30.025976891000013],
            [48.050000180000012, 30.021916682000082],
            [48.044166454000134, 30.019167028000126],
            [48.037333493000034, 30.01400113199999],
            [48.030666234000108, 30.007250520000113],
            [48.022418275000149, 29.999495651000089],
            [48.017570729000141, 29.996648584000056],
            [48.014634282000117, 29.996483886000121],
            [48.014838147, 29.993746282],
            [48.011688798000108, 29.989910022000018],
            [48.010509801000097, 29.990470398000028],
            [48.009404114000091, 29.990844985000123],
            [48.008652931000114, 29.990957462],
            [48.006608264000221, 29.991089020000047],
            [48.001315832000188, 29.991584117999977],
            [47.998677650000133, 29.991898450000051],
            [47.997718585000172, 29.991927573999973],
            [47.993845168000149, 29.992659677000162],
            [47.991378714000092, 29.99327227300013],
            [47.990576313000048, 29.993624766999986],
            [47.987673007000154, 29.993996341999988],
            [47.984803846, 29.994439219000142],
            [47.983788543000202, 29.994670198000065],
            [47.978279192000144, 29.995268735000124],
            [47.97630683200012, 29.995555953000078],
            [47.973321177000088, 29.996083187],
            [47.970833634000059, 29.996807256000139],
            [47.968719674000027, 29.997551410000071],
            [47.966692080000058, 29.998418084000107],
            [47.963970545000194, 29.999996774999985],
            [47.962247742500125, 30.000904622500101],
            [47.960711732000135, 30.00204345000013],
            [47.957754197000014, 30.00472782700011],
            [47.957416767000183, 30.005592492000048],
            [47.956144374000047, 30.007100885999975],
            [47.95531485800015, 30.008508852999952],
            [47.94347310091203, 30.017554411076205],
            [47.934991193723505, 30.017773099301607],
            [47.723463305302147, 30.084164142882926],
            [47.436663243945958, 30.083054043623306],
            [47.396663293138516, 30.082773082228087],
            [47.351100273177764, 30.081664156434854],
            [47.204300291105284, 30.033327061791496],
            [47.169991149655544, 30.015273112853535],
            [47.147763348200471, 29.996526986349281],
            [47.128873220599388, 29.974718112416198],
            [47.113600219504548, 29.946382082823945],
            [47.094436171306853, 29.907082188571636],
            [47.047763219724544, 29.794164206161796],
            [46.98137318197152, 29.646109117904203],
            [46.944291306943597, 29.578473194059555],
            [46.924018165314664, 29.554718042203646],
            [46.858891280373683, 29.475827066381825],
            [46.827763238825952, 29.433609096349855],
            [46.775554040180879, 29.356382095950906],
            [46.714909126518563, 29.266318043165015],
            [46.690382169015976, 29.230345093930325],
            [46.653836065240711, 29.188482181317895],
            [46.635273334778304, 29.178609137539794],
            [46.598882296212167, 29.156109091868913],
            [46.581654132377167, 29.143327024766833],
            [46.558182288849508, 29.121453945455315],
            [46.547354043382853, 29.105200094048215],
            [46.546945174144838, 29.104200132996553],
            [46.426673245144372, 29.061664153557146],
            [46.12215417570215, 29.086073093862566],
            [44.721663376806106, 29.198327061900997],
            [43.810382190473689, 29.868500120509097],
            [43.065000158480842, 30.416664184498032],
            [42.924700174700973, 30.516154022707113],
            [42.085000274048724, 31.111664105105078],
            [41.44000025004155, 31.37332700640583],
            [40.413327078750939, 31.94832707459021],
            [40.074609173705056, 32.005864146184578],
            [39.487582247896654, 32.105553976604298],
            [39.196745126683908, 32.154945011755657],
            [39.301109209241218, 32.236382074493363],
            [39.260000165002481, 32.355545077700484],
            [39.043654190984427, 32.304054040521649],
            [38.98601821293235, 32.477664210528005],
            [39.086009121314788, 32.501509048748019],
            [38.794700266589899, 33.377591103149129],
            [38.810273172181184, 33.382218081353003],
            [38.961391175505668, 33.455554034648841],
            [39.386109251390593, 33.658882074808716],
            [39.579136100157172, 33.750000068102892],
            [39.798045092535432, 33.853336026413942],
            [40.000000176951374, 33.947654062711408],
            [40.165545112061778, 34.024435984050442],
            [40.199436164179843, 34.040827131031918],
            [40.663600147070923, 34.260273235767173],
            [41.00387323043654, 34.419436022143927],
            [41.030536064512006, 34.473609100708373],
            [41.099436146997078, 34.608600154640371],
            [41.224845176829973, 34.78290904009738],
            [41.223054131754367, 34.916936007523262],
            [41.214718161385093, 35.069154051823674],
            [41.212491257343203, 35.112782193249714],
            [41.211245203613686, 35.188600195720809],
            [41.212409114691951, 35.200618168527328],
            [41.215273211014988, 35.22470909942551],
            [41.222491203135604, 35.253609063464793],
            [41.240963241405183, 35.306518151026992],
            [41.254436144969105, 35.343318058831315],
            [41.262218239175041, 35.367773099604264],
            [41.269163316527539, 35.398745070114316],
            [41.270818239495071, 35.44332707212466],
            [41.272491267373624, 35.468318051788216],
            [41.277773207493311, 35.49554498794906],
            [41.322491163973467, 35.550564135822427],
            [41.384018189128625, 35.630964189496254],
            [41.378327212132746, 35.836936049563221],
            [41.356936092255097, 35.876382124207325],
            [41.271100206718614, 36.027773209938019],
            [41.25499119459883, 36.054991093643551],
            [41.290273307371791, 36.355554072413995],
            [41.367209120453623, 36.472773144631347],
            [41.403045109390092, 36.52555398907468],
            [41.835273202903153, 36.598882231019573],
            [42.367291206759973, 37.055891086064022],
            [42.37410016200451, 37.063164231107677],
            [42.378327155787673, 37.072064135924265],
            [42.355609180633707, 37.106927153537228],
            [42.376936095132834, 37.117491201415035],
            [42.531663345974806, 37.147491206430161],
            [42.579509261090635, 37.153673195315307],
            [42.588045056032257, 37.175682229097006],
            [42.724709137842609, 37.351945103827788],
            [42.765727154612676, 37.371218116767054],
            [42.786800265082974, 37.3836731220063],
            [42.822836078591621, 37.367182230376713],
            [42.894436139735063, 37.335536019573382],
            [42.924163229982042, 37.327764151289344],
            [42.960618138650631, 37.323527099222261],
            [43.002763353762845, 37.339153984270197],
            [43.156936225527232, 37.373882220879736],
            [43.489991149134056, 37.251245086434622],
            [43.623045145236603, 37.230000146948683],
            [43.816373239604246, 37.221927033063636],
            [43.91298221768389, 37.226591059279727],
            [43.936936188283738, 37.251936090534443],
            [43.955818101619826, 37.279636101311823],
            [44.009018208861022, 37.317764205497028],
            [44.048045188345242, 37.322218013580695],
            [44.072945140539929, 37.31637314484199],
            [44.096100148126794, 37.317218040684438],
            [44.118882161021389, 37.315682140742283],
            [44.230554089701769, 37.276245118553689],
            [44.262145147582174, 37.23651808251428],
            [44.268327136467434, 37.195000169037414],
            [44.26260028492581, 37.163464264080133],
            [44.230263237660751, 37.154018026812977],
            [44.1959633163045, 37.096382216398908],
            [44.257073257956478, 36.985691138030845],
            [44.319163211730455, 36.971236043050212],
            [44.341100161144453, 37.006653942655092],
            [44.349709213919908, 37.038318090731352],
            [44.436382283947836, 37.063045040444209],
            [44.470273336065958, 37.080827080442546],
            [44.49055418904598, 37.091653984804381],
            [44.550545146620806, 37.132636127028817],
            [44.567627130063926, 37.149709058016626],
            [44.582763338498552, 37.163182129218441],
            [44.642218189544707, 37.188464128560909],
            [44.745827230262051, 37.174427122911979],
            [44.774091175486575, 37.16596425052812],
            [44.787336091283493, 37.149709058016626],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ISL", Country: "Iceland" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-6.60941867799977, 67.613321188000086],
            [-6.27801250599984, 67.081524842000121],
            [-6.271371505999866, 67.036939842000109],
            [-5.942548505999866, 66.736181842000079],
            [-5.6175215049999, 66.170955841],
            [-5.557445790999907, 65.694080389000149],
            [-6.083711219999827, 65.505319199000056],
            [-6.717843286999909, 65.244289322000085],
            [-7.342066186999972, 64.9710991110001],
            [-7.888253434000035, 64.752489980000135],
            [-8.981929053999806, 64.278671858000095],
            [-9.872671470999819, 63.869899961000087],
            [-10.071307960999974, 63.772795301000073],
            [-10.740475376999939, 63.366475411000167],
            [-10.7651310359999, 63.353884954000179],
            [-11.063333380999779, 63.200581582000225],
            [-11.310116132999894, 63.068080035000222],
            [-12.027208718999958, 62.62596891400004],
            [-12.967583985999852, 61.925640564000091],
            [-13.299760563999939, 61.577798599000133],
            [-13.909696504999886, 60.826393094000068],
            [-14.530969513999935, 60.760417836000173],
            [-14.751298513999814, 60.724076836000151],
            [-16.681603515999853, 60.36861183600017],
            [-17.602887515999782, 60.165570836000015],
            [-17.935072516999924, 60.093837835999963],
            [-17.95082151699998, 60.090421836000118],
            [-18.74442051699998, 60.057534836000045],
            [-18.944868517999964, 60.066570836000025],
            [-18.957248517999886, 60.066035836000182],
            [-19.285542517999886, 60.021735836000104],
            [-20.439698518999847, 59.963398836],
            [-21.597391519999974, 59.995870836000179],
            [-22.733347520999956, 60.1184258360001],
            [-23.822265521999896, 60.328324836000135],
            [-23.941504521999889, 60.363333836000081],
            [-24.01213952199987, 60.363666836000093],
            [-25.169329522999845, 60.464499836000229],
            [-26.28522052399984, 60.653355835999989],
            [-27.334302524999771, 60.925990837000214],
            [-28.2910195259999, 61.276176837000222],
            [-29.129868526999871, 61.695720837000053],
            [-29.825629527999922, 62.174498838000076],
            [-30.353788527999853, 62.700501838000108],
            [-30.670192528999905, 63.225757839000082],
            [-30.863333528999817, 63.313332839000154],
            [-30.581666527999857, 63.923332839000139],
            [-29.856666527999863, 65.216666841000134],
            [-28.978333526999876, 66.211666842000042],
            [-27.764999525999855, 66.639999842000194],
            [-26.994999524999884, 66.954999842],
            [-26.556666524999912, 67.064999842000091],
            [-25.599999523999941, 67.381666843000076],
            [-24.441666522999867, 67.629999843000107],
            [-23.350166522, 67.816805843000068],
            [-21.749999519999847, 68.1366668430001],
            [-19.999999518999914, 68.408332844000071],
            [-17.336666516, 68.763332844000146],
            [-15.751666514999869, 69.049999844000084],
            [-15.354999513999815, 69.084999844000194],
            [-13.559999512999923, 69.356666844],
            [-13.266666666999981, 69.583333333000141],
            [-12.156666999999857, 69.578333],
            [-10.664004950999868, 69.377679685000075],
            [-9.297728212999914, 69.059185454000087],
            [-8.150119689, 68.652962831000067],
            [-7.254785368999819, 68.175629398000225],
            [-6.62946972099985, 67.645496455000128],
            [-6.60941867799977, 67.613321188000086],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ISR", Country: "Israel" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [35.259718087882788, 31.787218010290815],
              [35.254436147763158, 31.786391051721239],
              [35.251663246546798, 31.788054021315816],
              [35.243609243401124, 31.803891124495806],
              [35.246382312255491, 31.808054080538625],
              [35.248891183520925, 31.80889109739212],
              [35.256382258047665, 31.808054080538625],
              [35.263609134985757, 31.803609157272376],
              [35.262500209192638, 31.789718164376893],
              [35.259718087882788, 31.787218010290815],
            ],
          ],
          [
            [
              [35.068249048500263, 33.094658155],
              [35.100626209333882, 33.093568893494648],
              [35.109445148038674, 33.095553974466782],
              [35.26500019564071, 33.098609178182755],
              [35.321936117137625, 33.088054015122566],
              [35.351936122152779, 33.062500106840162],
              [35.377782223580709, 33.062082185146664],
              [35.425554042672388, 33.068327038305881],
              [35.505273318168292, 33.098053960914854],
              [35.505827194331744, 33.114991105070828],
              [35.525691299084599, 33.12672711065369],
              [35.542773282527833, 33.196527074266044],
              [35.58554512872314, 33.270273237904576],
              [35.623636184896213, 33.245727002024751],
              [35.632491162711574, 33.24637307912343],
              [35.659573259585272, 33.254164225784791],
              [35.66961813237873, 33.251718051155137],
              [35.681109218750066, 33.244509111490004],
              [35.669018155747665, 33.212173237691331],
              [35.666936090993147, 33.205273087339833],
              [35.652500274389865, 33.124991050863173],
              [35.650027110031942, 33.091209131124629],
              [35.642491276142039, 33.050554050763196],
              [35.634991149159646, 33.000000110361668],
              [35.633745095430129, 32.935691131534895],
              [35.624582166491166, 32.905827080989695],
              [35.614463365311451, 32.894909149159105],
              [35.618609222271743, 32.891391096748293],
              [35.64159122737658, 32.856873078270951],
              [35.639209258125391, 32.815318116781896],
              [35.662845051679938, 32.797218067376264],
              [35.665482166064237, 32.761991107526342],
              [35.651936172304659, 32.72749119395985],
              [35.646663284640226, 32.702773129064497],
              [35.648891194510497, 32.685273223927766],
              [35.631936113081849, 32.687218160745942],
              [35.615782173960724, 32.679653996023092],
              [35.571936103051428, 32.645827149283605],
              [35.546945123387957, 32.405000150592571],
              [35.552563345463966, 32.394200068320856],
              [35.48110912943622, 32.412773192343082],
              [35.423882188260166, 32.485691056307417],
              [35.408191265471885, 32.504164100405376],
              [35.350554113953478, 32.523464103073081],
              [35.317363286028467, 32.516936109223764],
              [35.289027088798264, 32.516245105123971],
              [35.263463289870231, 32.521664173180113],
              [35.208054048221811, 32.546391122892771],
              [35.17944510386161, 32.527500157101315],
              [35.08180012254644, 32.471454058447875],
              [35.066382282164284, 32.447218120624484],
              [35.056663297767102, 32.42528217703898],
              [35.047218233966476, 32.394436102714849],
              [35.04582717331175, 32.383327063491208],
              [35.047773283596371, 32.36750018623303],
              [35.025554031682645, 32.239300150273039],
              [34.979163214962114, 32.15305405439409],
              [34.985836215736811, 32.132500119007886],
              [35.006100137272426, 32.054164192815904],
              [35.007500250382691, 32.028054061437331],
              [35.000863291791632, 31.985318089787654],
              [34.987500191159882, 31.968891068260405],
              [34.966109238920296, 31.861382095622673],
              [34.965554189290401, 31.8305539585715],
              [34.975136213389362, 31.823327081633408],
              [35.014163360511589, 31.820273219021885],
              [35.035827227519405, 31.822218155840233],
              [35.108282242789073, 31.833073223397093],
              [35.153054178725711, 31.813054053434939],
              [35.227618248478279, 31.775100125198094],
              [35.209718191283002, 31.750000180793052],
              [35.16290911759279, 31.744864253427011],
              [35.097500265428209, 31.70832703446905],
              [35.001663260632995, 31.646945016239044],
              [34.985545196057728, 31.633327105750027],
              [34.961363237690932, 31.603354090463199],
              [34.950273309206636, 31.586800166921591],
              [34.945827212473887, 31.559164193884612],
              [34.946382262103612, 31.502009169438054],
              [34.937218159698318, 31.481664111079681],
              [34.910554152156124, 31.44388217949637],
              [34.888191234421811, 31.412500166281973],
              [34.889854036378381, 31.380554050982028],
              [34.90819129364445, 31.360553991759218],
              [34.937563326472059, 31.350691173903101],
              [35.13860914202894, 31.361945052414001],
              [35.220409141174969, 31.376800131815401],
              [35.240554039685151, 31.384164136689719],
              [35.259163205891014, 31.403609146282804],
              [35.378609182150285, 31.484164097527753],
              [35.400673201216819, 31.493554008405226],
              [35.451109124421237, 31.499444977611347],
              [35.478200273750247, 31.497318153493666],
              [35.478609142988091, 31.449164119616285],
              [35.473118158202681, 31.410345011331287],
              [35.471000218902304, 31.40041815573494],
              [35.452500185076019, 31.351391062820255],
              [35.41138225601992, 31.225273091614611],
              [35.432782260714816, 31.200273227133835],
              [35.459509132530968, 31.156944990204124],
              [35.462218163644707, 31.121109168905662],
              [35.454991286706559, 31.099718049027899],
              [35.395136115963737, 30.946764241264873],
              [35.36813616174129, 30.928664191859298],
              [35.357909234010492, 30.921109079591758],
              [35.337773220317786, 30.886945112705519],
              [35.300000173552007, 30.791109113738798],
              [35.292500214207735, 30.759164171905354],
              [35.255554126011816, 30.681945050495699],
              [35.219718137075176, 30.621945040465107],
              [35.206109111403464, 30.59471810430432],
              [35.15860920125175, 30.444164203064986],
              [35.156091277530663, 30.41528217629866],
              [35.161382270106117, 30.31944500386544],
              [35.166936119165712, 30.151664112257663],
              [35.160209138934363, 30.116045046976012],
              [35.143882197331209, 30.094164256313491],
              [35.118745204914035, 30.070000067581418],
              [35.10367320185793, 30.048891082565433],
              [35.07305410947265, 29.953609127400114],
              [35.072218098447479, 29.920827001075338],
              [35.072773315715381, 29.891664180551572],
              [35.075836063144209, 29.867773241863745],
              [35.007218115520914, 29.640827010146268],
              [34.974627096588904, 29.577018095664243],
              [34.977491192912055, 29.570000095753983],
              [34.97998176437261, 29.545754419811146],
              [34.978172122000103, 29.542587985000068],
              [34.966514000000245, 29.519090000000119],
              [34.933619000000249, 29.466837000000169],
              [34.921211000000142, 29.453380000000152],
              [34.912667225000291, 29.469825016000129],
              [34.903974334732425, 29.486454541055821],
              [34.877782251753331, 29.532500131394613],
              [34.86639124530609, 29.606664216726472],
              [34.873891204650306, 29.630836116809576],
              [34.872773226401648, 29.650827123576903],
              [34.847773194282695, 29.740836023439613],
              [34.753609217366034, 29.991109091759654],
              [34.723345182399953, 30.089344997250237],
              [34.614436096652128, 30.365000144837154],
              [34.545273325321091, 30.406945032462943],
              [34.543327215036243, 30.434718133436093],
              [34.558882183354626, 30.486109090690945],
              [34.489436103695311, 30.691109152901106],
              [34.401382198934414, 30.859445094138664],
              [34.287500130018771, 31.162773095136288],
              [34.267582213447042, 31.216545015813665],
              [34.334163358593059, 31.259718019896184],
              [34.371245065982919, 31.300000105565402],
              [34.364582291130205, 31.354300086144519],
              [34.388882266693884, 31.394718126283635],
              [34.478609199333278, 31.471109115657271],
              [34.513327210020975, 31.498609134224523],
              [34.539991217562942, 31.514718146344308],
              [34.558891235809938, 31.540000145686861],
              [34.490545273577311, 31.596099993406312],
              [34.482636283453132, 31.583053958052972],
              [34.455827276219395, 31.549718040627909],
              [34.401109241264294, 31.489163597531103],
              [34.276109244785857, 31.37388159033685],
              [34.248054210687314, 31.349717995970934],
              [34.219553770857658, 31.325756952009499],
              [34.21865109700019, 31.322977886000217],
              [34.217421946391539, 31.323964900663782],
              [33.891322463000165, 31.585824570000284],
              [33.751243008000188, 31.746038122000186],
              [33.722787992000178, 31.785865658000205],
              [33.03425725000011, 32.829211892000217],
              [32.990981423000335, 32.884955567000247],
              [32.972222222000255, 32.88888888900027],
              [32.967655051800193, 32.890114227600066],
              [32.979298078000227, 32.904678153000134],
              [32.983333333000246, 32.909722222000141],
              [33.016666667000095, 32.934166667000056],
              [33.083333333000212, 32.993611111000121],
              [33.166666667000129, 33.056111111000234],
              [33.283333333000087, 33.156944444000089],
              [33.450000000000216, 33.282222222000087],
              [33.600000000000193, 33.407500000000198],
              [33.716666667000254, 33.5],
              [33.749291179000068, 33.530982881999989],
              [33.783646076000025, 33.514298949000022],
              [33.933659511000144, 33.447805298],
              [34.119903183500185, 33.390045136500191],
              [34.994837766000217, 33.118699739000249],
              [35.041390591000095, 33.103452253000057],
              [35.068249048500263, 33.094658155],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ITA", Country: "Italy" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.133018199788182, 47.012564129702753],
            [12.136109110411837, 46.966945118628814],
            [12.160273299143739, 46.928054093614492],
            [12.185554125019848, 46.907918079921785],
            [12.232218191784625, 46.888882107204893],
            [12.301663265615446, 46.84110911464677],
            [12.383745064347238, 46.721109094586097],
            [12.404300173199942, 46.705553958629764],
            [12.440554083829795, 46.6908271223474],
            [12.486173262541911, 46.682364249963541],
            [12.554445037562971, 46.661664134185628],
            [12.590273147510231, 46.658882180513899],
            [12.692500180028219, 46.65971802390095],
            [12.735627083643124, 46.641318069998931],
            [12.747927191311135, 46.64449112327361],
            [12.757773245361079, 46.649718078108435],
            [12.778336065564645, 46.647918148215695],
            [12.825554008492702, 46.631245034010476],
            [13.257500134782219, 46.561944967104921],
            [13.428891112097745, 46.563045008080735],
            [13.555000198485857, 46.556245105291623],
            [13.611109161413253, 46.548045089392247],
            [13.718654008596502, 46.526609042513371],
            [13.719163293034967, 46.51846417953692],
            [13.711109122251116, 46.492773143318175],
            [13.692009111793766, 46.451664266717444],
            [13.657773228178087, 46.449718156432766],
            [13.511391167799047, 46.384718173506371],
            [13.440836162346386, 46.346382198121603],
            [13.383473266700605, 46.29513608015418],
            [13.4188911663054, 46.213054113784551],
            [13.458954149024976, 46.223191187513294],
            [13.488473200434612, 46.231945079576235],
            [13.550554101753107, 46.218045034225383],
            [13.666036108351591, 46.175309062575536],
            [13.648191204079296, 46.145545091954602],
            [13.593609088638573, 46.105273232207196],
            [13.557363224635708, 46.087282147543021],
            [13.538891186366072, 46.079436015596684],
            [13.521945157392793, 46.063327003476729],
            [13.47971813490517, 46.012218013445647],
            [13.50986331448388, 45.986245010003472],
            [13.540282247021025, 45.968327015535067],
            [13.568954055655354, 45.979373190484694],
            [13.593336173870227, 45.99304508043042],
            [13.635100180025177, 45.98200913140272],
            [13.634718132877481, 45.947909034618704],
            [13.614100159750819, 45.905136014956952],
            [13.590691180496947, 45.888118069254176],
            [13.578327202726257, 45.850273105759072],
            [13.603745156538764, 45.808882094296678],
            [13.697500096579404, 45.784164197039331],
            [13.791527113197844, 45.752773131369281],
            [13.835282156638414, 45.717773153457983],
            [13.91833608850294, 45.636391076005509],
            [13.857218100224031, 45.587218137975128],
            [13.827773144838659, 45.584445069120761],
            [13.802218230727988, 45.586391179405609],
            [13.716945106086143, 45.596109157987087],
            [13.707250461000172, 45.601037297000062],
            [13.705299855000248, 45.601912631],
            [13.699108510000144, 45.605448530999979],
            [13.60660241100021, 45.647278352000058],
            [13.605328185000133, 45.646534897000095],
            [13.602608857, 45.644911181000055],
            [13.581249916000161, 45.63253421200011],
            [13.57878068600013, 45.632118707000132],
            [13.568782778000184, 45.628785022000045],
            [13.56614206100005, 45.627977732000033],
            [13.552216218000069, 45.623635559000036],
            [13.548684572000241, 45.62250080299998],
            [13.53505425100019, 45.619889764000163],
            [13.519830874000149, 45.615255800000114],
            [13.483341005000113, 45.599420219000024],
            [13.463980999111243, 45.59079399655559],
            [13.45175058500007, 45.58808282900003],
            [13.424499992000165, 45.582253775000027],
            [13.418516378000163, 45.580815999000095],
            [13.38609981400009, 45.569848918000105],
            [13.372198923000212, 45.565045408999936],
            [13.36511104900012, 45.562817688000109],
            [13.344713422000041, 45.556523664],
            [13.325544783000225, 45.548631199999932],
            [13.278763075000199, 45.530726597000069],
            [13.265752933000215, 45.525507366],
            [13.257909063000284, 45.514954435000163],
            [13.235829225000202, 45.485967097000014],
            [13.201944444000276, 45.450555555000165],
            [13.203118648000043, 45.444785314000114],
            [13.190000000000168, 45.431666667000115],
            [13.101666667000131, 45.335000000000207],
            [13.063333333000116, 45.28],
            [13.01992190850018, 45.204866129000152],
            [13.008333333000053, 45.185000000000173],
            [13.078333333000273, 44.975000000000136],
            [13.106666667000127, 44.76833333299993],
            [13.113348271000177, 44.738169018],
            [13.123986942000158, 44.621143642999925],
            [13.135000000000105, 44.500000000000171],
            [13.183333333000178, 44.476666667000018],
            [13.195000000000164, 44.465],
            [13.471666667000193, 44.296666667000125],
            [13.631666667000161, 44.208333333000041],
            [13.666666667000243, 44.180000000000234],
            [14.015, 44.00833333300011],
            [14.083333333000212, 43.958333333000041],
            [14.171666667000125, 43.900000000000148],
            [14.356666667000241, 43.716666667000027],
            [14.391666667000152, 43.671666667000125],
            [14.408333333000201, 43.64],
            [14.408557931000303, 43.638581486000135],
            [14.440000000000282, 43.44],
            [14.506666667000275, 43.526666666999972],
            [14.533333333000257, 43.495000000000232],
            [14.581666667000263, 43.42],
            [14.766666666999981, 43.21666666700014],
            [14.790024805000144, 43.187161650000064],
            [14.798333333000187, 43.176666667000234],
            [14.908333333000087, 43.063333333000173],
            [14.965, 43.013333332999935],
            [14.989905489000137, 42.999101625000151],
            [15.011666667000156, 42.986666667000122],
            [15.074071790000119, 42.906532816000151],
            [15.15833333300003, 42.79833333300013],
            [15.363333333000071, 42.613333333000185],
            [15.5531521690001, 42.553086485],
            [15.734130212000252, 42.495645628000204],
            [15.746666667000227, 42.491666667000231],
            [15.972066905000304, 42.433484960999976],
            [15.971482510000158, 42.431821160000084],
            [15.970416270000044, 42.427427710000018],
            [15.969527503500217, 42.425242081000079],
            [15.969517237000161, 42.422481111000081],
            [15.969134592000216, 42.419422404999978],
            [15.968729814000142, 42.41618677200006],
            [15.968443654000282, 42.413899328500008],
            [15.96791055600022, 42.400505637000094],
            [15.96796485699997, 42.399404790000062],
            [15.968565556000215, 42.387226637000083],
            [15.968892674000301, 42.384857405],
            [15.970390556000041, 42.374008636999974],
            [15.973376556, 42.360907637000082],
            [15.97750955600003, 42.34797863700004],
            [15.978277283000068, 42.346125558000097],
            [15.982469482000283, 42.336006760000089],
            [15.982771556000046, 42.335277637000047],
            [15.989140556000166, 42.322858636999968],
            [15.996586556000182, 42.31077563700012],
            [15.999254556000153, 42.306898637000131],
            [16.000237556000116, 42.305508637000059],
            [16.006061556000077, 42.297688637000135],
            [16.015335788500067, 42.286696412000083],
            [16.026031556000191, 42.275651637000237],
            [16.027116185000182, 42.274676213000021],
            [16.037423556000078, 42.26540663700024],
            [16.049689556000146, 42.2557356370001],
            [16.055543556000089, 42.251684636999983],
            [16.06101955600019, 42.24736563700003],
            [16.074103556000239, 42.238308637000102],
            [16.077560070000061, 42.23621126900008],
            [16.087953556000116, 42.229904637000175],
            [16.09884989100027, 42.224129340000047],
            [16.102509556000086, 42.222189637000099],
            [16.117710556000191, 42.215197637000131],
            [16.133490556000197, 42.208956636999915],
            [16.149782555999934, 42.203494637000119],
            [16.164280055000177, 42.199457062999954],
            [16.166518556000199, 42.198833637000064],
            [16.172573556000089, 42.197474637000084],
            [16.183314617000178, 42.194480466000243],
            [16.185534556000107, 42.193861637000111],
            [16.202639555999951, 42.190019637000034],
            [16.220044556000175, 42.187014637000061],
            [16.237674556000115, 42.184860636999986],
            [16.248249556000076, 42.184090637000054],
            [16.251938556000141, 42.183059637000213],
            [16.259679307000113, 42.181315726999969],
            [16.269036556000117, 42.17920763699999],
            [16.281514572000162, 42.177045250000049],
            [16.286434556000074, 42.176192637000099],
            [16.30405955599997, 42.174027637000052],
            [16.321836556000051, 42.172721637],
            [16.326892556000246, 42.172596636999913],
            [16.331150556000296, 42.172315637000111],
            [16.349003556000241, 42.171873636999976],
            [16.36685755600007, 42.172297637000042],
            [16.372999257000117, 42.172743264000076],
            [16.384636557000192, 42.173587637000026],
            [16.402264557000194, 42.175736637000057],
            [16.419667557000196, 42.178735637000045],
            [16.429169715, 42.180867289],
            [16.436771557000156, 42.182572637000078],
            [16.453504556999974, 42.187231637000082],
            [16.46979355700023, 42.192690637000055],
            [16.485571557000071, 42.198929637000219],
            [16.500770556999953, 42.205919637000193],
            [16.515325557000267, 42.213631637000219],
            [16.529173557000092, 42.222033636999981],
            [16.539534100000139, 42.229204616000061],
            [16.542257556999971, 42.231089637000139],
            [16.554519557000276, 42.240760637000108],
            [16.565908557000057, 42.251004637000165],
            [16.571437556999911, 42.256512637000128],
            [16.582982365000191, 42.269452467000121],
            [16.590133683000204, 42.278638799000134],
            [16.616944444000296, 42.266666666000106],
            [16.61815094900021, 42.26609865200021],
            [16.619266291000088, 42.266240443000129],
            [16.623895008999909, 42.264125800000045],
            [16.660956751000242, 42.247194039000021],
            [16.708691091999981, 42.225386471999968],
            [16.751514398000097, 42.205822526000077],
            [16.782293972000218, 42.191760791000036],
            [16.946666667000272, 42.116666667000061],
            [17.12110355700014, 42.03590884700003],
            [17.21666666700014, 41.991666666999947],
            [17.311666667000111, 41.913333333000026],
            [17.616666667000118, 41.836666666999974],
            [17.625498526000172, 41.832173937999926],
            [17.997245842000154, 41.643067695000184],
            [18.020445856000038, 41.622009092000098],
            [18.21666666700014, 41.499999999000011],
            [18.461944444000267, 41.277499999000042],
            [18.542777778000072, 41.19361111000012],
            [18.576944444000162, 41.133611110000061],
            [18.595, 41.108055555000249],
            [18.658611111000141, 40.917499999000199],
            [18.659444444000258, 40.884999999],
            [18.671111111000187, 40.847222221000095],
            [18.711111111000093, 40.733055555000078],
            [18.739722222000125, 40.669444444000078],
            [18.745277778000229, 40.64611111000022],
            [18.75972222200005, 40.593888887999981],
            [18.795833333000189, 40.512222221],
            [18.851388889000191, 40.388055555],
            [18.859722222000187, 40.3583333320002],
            [18.880000000000223, 40.313888888000065],
            [18.951388888999929, 40.203611110000111],
            [18.977222222000194, 40.131944444],
            [18.986794151000197, 40.080423554000049],
            [18.958333333000269, 39.961666666000156],
            [18.935000000000286, 39.873333332000072],
            [18.915000000000134, 39.816666666000174],
            [18.926666667000234, 39.288333332000207],
            [18.900000000000148, 39.033333332000268],
            [18.731666667000354, 38.499999999000238],
            [18.476666667000245, 37.866666666000071],
            [18.28333333300003, 37.354999999000086],
            [18.318333333000112, 36.991666666000185],
            [18.320000000000277, 36.906666666000206],
            [18.310000000000116, 36.749999999000238],
            [18.300000000000125, 36.441666666000231],
            [18.295000000000186, 36.401666666000324],
            [18.261666667000213, 36.183333332000359],
            [18.261666667000213, 36.149999999000215],
            [18.345000000000198, 35.569999999000288],
            [18.357739499000104, 35.021064676000094],
            [18.284758154000258, 34.957566911000072],
            [18.224331965000033, 34.905985442000201],
            [18.20564608799998, 34.890144188000079],
            [18.175934202000292, 34.864981784000179],
            [18.16605182700016, 34.856622292000225],
            [18.160111250000284, 34.851642644000151],
            [18.073737577000031, 34.780146593000097],
            [18.057794556000147, 34.766805283000053],
            [17.821204195000291, 34.881098437000105],
            [17.673670125000172, 34.952369870000041],
            [17.217497869000169, 35.167177449500116],
            [16.303425252000068, 35.597606359999986],
            [15.749363091000191, 35.855442881000045],
            [15.525269859000275, 35.958746876000077],
            [15.286986338000304, 36.060159930000054],
            [15.184572823000224, 36.10406470200013],
            [15.079958236000095, 36.149408451000113],
            [15.025804883000205, 36.172783203999984],
            [14.889609454000095, 36.231957539],
            [14.79927433600011, 36.271968926000056],
            [14.623120404000076, 36.350484355000049],
            [14.525321727000119, 36.380569898000033],
            [14.52272452200009, 36.381537152000135],
            [14.500979756000163, 36.389569521000112],
            [14.47246128800009, 36.400177159000066],
            [14.466138127000136, 36.402658350000081],
            [14.41980374800022, 36.423093153000082],
            [14.338821141000039, 36.441164910999987],
            [14.311280969000052, 36.447430140000108],
            [14.271697259000121, 36.456707825000137],
            [14.237896896000279, 36.464560596000126],
            [14.234996826000099, 36.465245070000094],
            [14.232496815000104, 36.46583874300012],
            [14.229389342500212, 36.46658587100012],
            [14.227097212000217, 36.467139205000251],
            [14.225397319000137, 36.467551331999971],
            [14.223697426000285, 36.467967499000224],
            [14.133592362000172, 36.490052285000161],
            [14.09685314900014, 36.499219463000202],
            [14.074912972000106, 36.504953272000193],
            [14.000840758500203, 36.521422191500164],
            [13.873190590000206, 36.551343866000082],
            [13.747095765000211, 36.53107398700007],
            [13.699784107000028, 36.522583395000083],
            [13.656032307000203, 36.514327266000066],
            [13.427024191000214, 36.469055812000136],
            [13.412759146999917, 36.465253686000096],
            [13.449657873000092, 36.309193926000034],
            [13.461492806000138, 36.258578032],
            [13.504239061000135, 36.075625441000057],
            [13.505903826000122, 36.068544050000071],
            [13.514282154000227, 36.032435330000112],
            [13.515278525000156, 36.028235606000067],
            [13.53034584000028, 35.952416815000106],
            [13.550677917999963, 35.854327651000176],
            [13.553824265000173, 35.839607506000164],
            [13.594696623000118, 35.645964207000134],
            [13.616659588000061, 35.541250933000128],
            [13.617677457000184, 35.505524398000091],
            [13.6168072130003, 35.492847207000182],
            [13.644508902000211, 35.325951171000156],
            [13.65119575000017, 35.285918059000039],
            [13.661269918000016, 35.225193438000133],
            [13.601666667000245, 35.249999999000011],
            [13.580132434000063, 35.270745170000225],
            [13.184722222000289, 35.651666666000096],
            [13.074314512000171, 35.72902591400009],
            [13.067745553000179, 35.722275131000117],
            [13.06711755300023, 35.721671631000049],
            [13.056187553000029, 35.711870631000068],
            [13.044498553000153, 35.702672630999984],
            [13.03209955300008, 35.694116631000099],
            [13.019043553000188, 35.686239631000092],
            [13.016680553000072, 35.684924631],
            [13.005304552999917, 35.679028631],
            [12.991106553000066, 35.67260563100001],
            [12.976425553000013, 35.666952631000186],
            [12.961325553000165, 35.662092631000235],
            [12.945870553000191, 35.658047631000215],
            [12.930125553000209, 35.654833631000201],
            [12.914158553000163, 35.6524646310001],
            [12.898036553000111, 35.650950631000114],
            [12.881828553000219, 35.650297631000058],
            [12.865602553000031, 35.650509631000034],
            [12.863356553000187, 35.650607631000128],
            [12.851249553000173, 35.650845631000095],
            [12.849482553000144, 35.650920631000076],
            [12.844258553000088, 35.651081631000068],
            [12.828083553000056, 35.652153631000147],
            [12.813066553000112, 35.653959631000191],
            [12.814791553000191, 35.652508631000217],
            [12.825301553000088, 35.642417631000171],
            [12.834977553000101, 35.631786631000182],
            [12.843778553000163, 35.620660631000135],
            [12.8516665530002, 35.60908763100025],
            [12.858607553000184, 35.597118631000228],
            [12.864573553000184, 35.58480363100017],
            [12.865771553000172, 35.582009631000119],
            [12.866127553000126, 35.581158631000051],
            [12.869895553000163, 35.571344631000215],
            [12.873839553000153, 35.558498631000077],
            [12.876744553000208, 35.545469631000174],
            [12.878600553000297, 35.532313631000193],
            [12.879398553000044, 35.519086631],
            [12.879338553000252, 35.51605663100014],
            [12.880083553000247, 35.503712630999985],
            [12.880097553000155, 35.497805631000119],
            [12.880145553000204, 35.49594863100009],
            [12.879883553000013, 35.482707631000039],
            [12.87856355300022, 35.469509631000108],
            [12.876191553000069, 35.45641063100004],
            [12.87277855300016, 35.443466631000206],
            [12.868339553000226, 35.430733631000237],
            [12.86289455300016, 35.418264631000056],
            [12.856465553000078, 35.406114631000037],
            [12.849083553000185, 35.394334631000049],
            [12.840778553000291, 35.382974631000138],
            [12.831585552999911, 35.372082631000069],
            [12.821546553000303, 35.361706631000061],
            [12.810702553000112, 35.35188963100012],
            [12.799101553000185, 35.342673631000196],
            [12.786792553000112, 35.334097631000077],
            [12.773827553000103, 35.326198631000068],
            [12.766185053000186, 35.322022130999983],
            [12.759592553000232, 35.318656631000238],
            [12.745486553000148, 35.312208630999976],
            [12.730898553000145, 35.306529631000103],
            [12.715890553000065, 35.301641631000166],
            [12.700527553000086, 35.297567631000049],
            [12.684873553000216, 35.294323631000054],
            [12.668995553000229, 35.291923631000088],
            [12.652960553000241, 35.29037763100024],
            [12.636838553000189, 35.289692631000037],
            [12.626949053000089, 35.289672630999974],
            [12.610514553000144, 35.28896163100012],
            [12.594372553000142, 35.289135631000136],
            [12.578278553000075, 35.290173631000073],
            [12.562301553000111, 35.292069631000061],
            [12.546508553000081, 35.294815631000063],
            [12.530966553000212, 35.29840063100005],
            [12.521162553000124, 35.301239631000101],
            [12.518819553000242, 35.301779631000215],
            [12.503594553000141, 35.306186631000173],
            [12.488751552999986, 35.311397631000204],
            [12.478142553000282, 35.31581463100008],
            [12.47301655300015, 35.317272631000094],
            [12.470464553000227, 35.317714631000115],
            [12.454915552999921, 35.321287631000132],
            [12.446714552999936, 35.323541631000126],
            [12.443379553000057, 35.324524631000088],
            [12.438736553000126, 35.3258256310001],
            [12.429461553000294, 35.328610631000117],
            [12.428264053000134, 35.329012631000012],
            [12.413130553000144, 35.334293631000151],
            [12.398723553000025, 35.34027763100022],
            [12.385769553000046, 35.346525631000077],
            [12.383514553000168, 35.347690631000148],
            [12.382566553000061, 35.348182631000043],
            [12.369229553000025, 35.355651631000171],
            [12.356514553000125, 35.363817631000131],
            [12.344475553000223, 35.37264663100018],
            [12.333164553000302, 35.382099631000244],
            [12.322629553000297, 35.392137631000139],
            [12.312916553000264, 35.402717631000144],
            [12.30406555299993, 35.413794631000087],
            [12.296116553000218, 35.425320631000204],
            [12.289102553000191, 35.437247631000105],
            [12.28305455300017, 35.449523631000091],
            [12.277998553000202, 35.462097631000063],
            [12.273956553000232, 35.474914631000217],
            [12.270947553000155, 35.487920631000065],
            [12.268982553000285, 35.501059631000032],
            [12.268073553000193, 35.514276631000087],
            [12.268222053000045, 35.527518131000249],
            [12.268469553000102, 35.531432631000087],
            [12.268533553000054, 35.532283630999984],
            [12.269493553000075, 35.541565631000083],
            [12.271756553000102, 35.554674631000069],
            [12.275063553000166, 35.567634631000203],
            [12.279402553000182, 35.580389631000173],
            [12.284754553000141, 35.592885631000115],
            [12.29109755300027, 35.605069631000106],
            [12.298405553000237, 35.616887631000054],
            [12.306646553000178, 35.628290631000141],
            [12.315786553000237, 35.63922763100004],
            [12.325785553000117, 35.649653631000092],
            [12.328939553000112, 35.652668631000154],
            [12.332172552999907, 35.655887631000041],
            [12.342990553000277, 35.665755631000081],
            [12.354578553000181, 35.675024631000156],
            [12.366888553000138, 35.683653631000197],
            [12.37986655300017, 35.69160663100007],
            [12.381819553000071, 35.69271263100012],
            [12.383048553000151, 35.693400631000202],
            [12.394685553000215, 35.699536631000058],
            [12.408830553000143, 35.706035631000248],
            [12.423468553000191, 35.711764631],
            [12.438537553000174, 35.716698631000213],
            [12.45397055300009, 35.720816631000048],
            [12.458746553000026, 35.721812631000034],
            [12.46003655300018, 35.722129630999973],
            [12.475769553000191, 35.7254116310001],
            [12.491734553000072, 35.727846631000091],
            [12.507860553000114, 35.729421631000037],
            [12.524079553000263, 35.730130631000065],
            [12.533998553000288, 35.73013663100005],
            [12.545567553000012, 35.729933631000108],
            [12.561761553000224, 35.728904631000091],
            [12.577836553000111, 35.727012631000207],
            [12.581094553000014, 35.726520631000028],
            [12.585626553000225, 35.725811631000113],
            [12.59530955300005, 35.726127631000168],
            [12.597228553000207, 35.726159131000088],
            [12.608209553000165, 35.726140631000021],
            [12.610905553000123, 35.726087631000041],
            [12.616214553000304, 35.725936631000025],
            [12.632406553000123, 35.724896631000092],
            [12.648479553000243, 35.722993631000236],
            [12.664365553000152, 35.720235631000094],
            [12.669811553000272, 35.719072631000131],
            [12.668619553000042, 35.720116631000224],
            [12.658076553000058, 35.730191631000139],
            [12.648361553000029, 35.740806631000225],
            [12.639515552999967, 35.751915631000145],
            [12.63791455300003, 35.754110631],
            [12.636082553000165, 35.756542631],
            [12.6281435530002, 35.768099630999984],
            [12.621056553000187, 35.780222131000073],
            [12.618178553000064, 35.785395631000029],
            [12.612152553000101, 35.797697631000233],
            [12.607124553000119, 35.810293631000093],
            [12.60311655300012, 35.82313063099997],
            [12.60014755300017, 35.83615463100017],
            [12.598229553000067, 35.849307631000102],
            [12.59737155300013, 35.862535631000185],
            [12.597342553000033, 35.863933631000236],
            [12.59728255300007, 35.867693631000122],
            [12.597516553000048, 35.879541631000052],
            [12.59878755300025, 35.892748631000131],
            [12.601117553000108, 35.905858631000228],
            [12.604496553000104, 35.918817631000081],
            [12.608912553000266, 35.931569631000229],
            [12.614345553000021, 35.944058631000246],
            [12.62077355300022, 35.956231631000094],
            [12.628169553000191, 35.968037631000072],
            [12.636501553000187, 35.979423630999975],
            [12.645735553000179, 35.990342631000146],
            [12.65582955300016, 36.000745631000086],
            [12.666743553000231, 36.010590631000014],
            [12.678429553000285, 36.019832631000156],
            [12.690837553000165, 36.028432631000101],
            [12.70391355300012, 36.036353631000054],
            [12.710674571000141, 36.039912982000089],
            [12.633611111000107, 36.135277777000113],
            [12.534444444000144, 36.250555555000119],
            [12.500555556000108, 36.351944443000036],
            [12.484444444000246, 36.383333332000063],
            [12.350555556000302, 36.583333332000109],
            [12.267305281500285, 36.695168985500004],
            [12.266851553000151, 36.693890631999977],
            [12.26143155300025, 36.681373632000088],
            [12.25937355300016, 36.677444132000232],
            [12.252902553000126, 36.66512563200007],
            [12.245505553000214, 36.653284632000179],
            [12.237165553000295, 36.64185863199998],
            [12.227918553000279, 36.630896632000201],
            [12.217804553000065, 36.620443632000047],
            [12.206867553000222, 36.610545632000139],
            [12.203975553000191, 36.608135632000028],
            [12.200066553000227, 36.60474463200012],
            [12.19565255300023, 36.601239632000045],
            [12.192591553000113, 36.598467632],
            [12.180881553000233, 36.589165632000061],
            [12.16844555300014, 36.580499632000112],
            [12.155336552999927, 36.572507632000054],
            [12.143634553000197, 36.566231632000154],
            [12.133910553000135, 36.561177632000124],
            [12.119627553000157, 36.55462863200006],
            [12.104846553000186, 36.54884663200005],
            [12.08963055300012, 36.543853632000094],
            [12.074044552000089, 36.539673632000074],
            [12.05815455200019, 36.536321632000238],
            [12.042028552000147, 36.533813632000204],
            [12.025735552000128, 36.532159632000116],
            [12.009343552000303, 36.531366632000243],
            [11.992922552000152, 36.531438632000118],
            [11.97654255200024, 36.532374631999971],
            [11.974550552000267, 36.532547632000046],
            [11.96485455200019, 36.533214632000181],
            [11.960188552000147, 36.533573632000241],
            [11.943917552000187, 36.535366632000091],
            [11.92782655200017, 36.538012632000175],
            [11.911983552000095, 36.541499632000182],
            [11.906025552000244, 36.543046632000085],
            [11.901636552000184, 36.544101632000206],
            [11.886106552000058, 36.548413632000063],
            [11.870956552000109, 36.55353363200004],
            [11.856252551999944, 36.559439632000249],
            [11.842056552000088, 36.566107632000012],
            [11.828428551999934, 36.573508632000085],
            [11.815426552000304, 36.581609631999982],
            [11.803106552000202, 36.590379632000122],
            [11.796169552000151, 36.596006632000126],
            [11.793940551999924, 36.597592632000101],
            [11.782352552000191, 36.60698963200025],
            [11.771548552000127, 36.616977632000101],
            [11.761575552000068, 36.627512632000162],
            [11.752474552000251, 36.638549632000093],
            [11.751872552000179, 36.639394632000062],
            [11.751089552000053, 36.64011863200011],
            [11.741108552000242, 36.65065163200012],
            [11.732002552000154, 36.661687632],
            [11.723807552000096, 36.673178632000088],
            [11.719739552000135, 36.679859632000046],
            [11.715602552000178, 36.684219632000179],
            [11.706487552000226, 36.695252632000233],
            [11.698284552000075, 36.706742632000214],
            [11.691030552000086, 36.718638632000193],
            [11.684755552000126, 36.730891632000237],
            [11.679487552000182, 36.743448632000025],
            [11.675249552000196, 36.756255632000091],
            [11.672059552000178, 36.76925863200006],
            [11.669932552000262, 36.782400632000076],
            [11.669360051999945, 36.787911632000089],
            [11.668297552000126, 36.80120663200023],
            [11.668320552000296, 36.814460632000134],
            [11.668410552000239, 36.816336632000116],
            [11.668536552000035, 36.825823632000095],
            [11.669637552000154, 36.839048632000072],
            [11.671813552000287, 36.852187632000096],
            [11.672592552000253, 36.855747632000089],
            [11.673361552000301, 36.859701632000053],
            [11.676603552000302, 36.872698632000208],
            [11.680897552000147, 36.88549663200024],
            [11.68622555200028, 36.89804263200017],
            [11.692565552000076, 36.910281632000135],
            [11.699891552000167, 36.922160631999986],
            [11.705292552000117, 36.929833632000168],
            [11.705781552000076, 36.930569132000073],
            [11.714064552000082, 36.942037632000051],
            [11.723266552000098, 36.953046632000223],
            [11.733348552000109, 36.963547632000086],
            [11.74426755200011, 36.973496632000092],
            [11.755978552000272, 36.982849632000011],
            [11.768428552000103, 36.991567632000169],
            [11.781566552000186, 36.999611632000097],
            [11.795335552000267, 37.006948632000217],
            [11.809675552000158, 37.013544632000048],
            [11.824525552000125, 37.019373632000111],
            [11.828721552000047, 37.020755632000032],
            [11.831978552000237, 37.022032632],
            [11.847275552000269, 37.027067632000069],
            [11.862953552000192, 37.031286632000047],
            [11.870361552000304, 37.032966632000011],
            [11.884868552000114, 37.035999632000141],
            [11.901101552000171, 37.038536632000046],
            [11.917508552000186, 37.040214632000016],
            [11.919270552000114, 37.04034263200009],
            [11.924863552000147, 37.040815631999976],
            [11.941372552000303, 37.041626632000089],
            [11.957913552000036, 37.041566632000126],
            [11.974413552000101, 37.040638632000082],
            [11.990801552000164, 37.038843632000237],
            [12.007007552000289, 37.036191632000168],
            [12.015770308000185, 37.034269681000239],
            [12.002500000000168, 37.05222222100025],
            [11.935555556, 37.134444443000064],
            [11.868611111000121, 37.233888887999967],
            [11.800555556000177, 37.334999999000104],
            [11.751388889000225, 37.367777777000185],
            [11.700000000000273, 37.401388888000213],
            [11.15, 37.683888888000013],
            [11.1675, 37.784444442999984],
            [10.868611111000178, 38.051388888000133],
            [10.685277778, 38.401666666000096],
            [9.900555556000143, 38.234722221000084],
            [9.667777778000186, 38.218055555000035],
            [9.552222222000125, 38.249999999000124],
            [8.802222222000296, 38.216944443000159],
            [8.534722222000084, 38.168055555000194],
            [8.367500000000121, 38.118888888000072],
            [8.151666667000143, 38.069166666000228],
            [7.81666666700022, 38.001666666000119],
            [7.392705814999943, 38.150596023000105],
            [6.848376564000148, 38.376373382000054],
            [6.821829373000185, 38.384150250000118],
            [6.455250727000077, 38.572124105000114],
            [6.055555556000229, 38.797222219000105],
            [6.085555556000145, 38.916666665999969],
            [6.216666667000197, 39.335555555000042],
            [6.300000000000239, 39.618055555000012],
            [6.300000000000239, 40.018611110000165],
            [6.185833333000062, 40.351388888000088],
            [6.166944444000137, 40.450833332000059],
            [6.135833333, 40.518611110000052],
            [6.118888889000289, 40.585277777000044],
            [5.951666667000097, 41.101388888000088],
            [5.935000000000173, 41.150833332000218],
            [6.312880050000103, 41.483111187999953],
            [6.43806549500016, 41.557183824],
            [6.681212202000154, 41.685752923999928],
            [6.8102363240003, 41.753341845000023],
            [6.927467182000214, 41.818351308000047],
            [7.736853451999934, 41.643613012000117],
            [7.86691527700026, 41.615091755000037],
            [7.962233853999976, 41.584185866000041],
            [8.259431921000271, 41.495322621000071],
            [8.307175361000134, 41.481067177000057],
            [8.608064526000078, 41.30071602400011],
            [8.61727243200022, 41.291248383999971],
            [8.81366666700012, 41.258666666000124],
            [9.135861111000111, 41.319166666000115],
            [9.270833333000155, 41.292833331999987],
            [9.317500000000223, 41.337166665999973],
            [9.451000000000136, 41.407499999000095],
            [9.631666667000047, 41.434666666],
            [9.708586415000298, 41.447948863000192],
            [10.148199137000063, 41.546156993000068],
            [10.332185072000186, 41.57263268],
            [10.343209094000201, 41.573941802000093],
            [10.34833481600009, 41.574550490000036],
            [10.357839616000149, 41.575673666000228],
            [10.375856060000103, 41.57780265599996],
            [10.20732834800009, 41.721453150000059],
            [10.201605432000179, 41.726557393000064],
            [10.179649950000226, 41.746981830999971],
            [10.143011151000167, 41.780133906],
            [10.141880204000302, 41.782343438999987],
            [10.123405375000175, 41.818563588000188],
            [10.101141039999959, 41.862218828999971],
            [10.078329102000055, 41.898732727000095],
            [10.061866328000235, 41.925254817000166],
            [10.043833115000211, 41.952754803000062],
            [10.019970401000194, 41.986762836000025],
            [9.995139922000192, 42.018947616000105],
            [9.991088619000266, 42.023827069000106],
            [9.974316249000196, 42.044246791000148],
            [9.954565769999988, 42.066664493000133],
            [9.953858296000078, 42.06748090100001],
            [9.922419882000156, 42.105054657999972],
            [9.921034066500113, 42.106725334000032],
            [9.912286571999971, 42.117604138000047],
            [9.891839770000217, 42.144164907000118],
            [9.878803610000205, 42.161137931999974],
            [9.868335774000229, 42.175470885000095],
            [9.845445700000255, 42.459901595000076],
            [9.807725054000286, 42.557506822999983],
            [9.779962045000275, 42.785163495000063],
            [9.704995558000292, 42.869500107000022],
            [9.63667279300023, 43.043412601000057],
            [9.628899635000096, 43.17007900200008],
            [9.473398881000037, 43.326047538000068],
            [9.460368539000171, 43.346838665],
            [9.011022335000064, 43.631904738000117],
            [8.993516590000183, 43.630489361000173],
            [8.926492052000128, 43.583940124000094],
            [8.829598806000206, 43.52049994000015],
            [8.704223714000193, 43.43970976200012],
            [8.50940305000023, 43.293593067000018],
            [8.455183575000206, 43.252126663500121],
            [8.454122864000055, 43.251663622000251],
            [8.443020249000085, 43.24867788600011],
            [8.434019167000145, 43.246090300999981],
            [8.407837526000037, 43.237736826000059],
            [8.25688502200012, 43.189013509999938],
            [8.241857521000099, 43.184157594],
            [8.234031356000116, 43.181602315000021],
            [8.224454575999971, 43.178251713000122],
            [8.192643261000228, 43.16597074800012],
            [8.18858506700019, 43.164404938000075],
            [8.143830372000025, 43.147497357000134],
            [8.068641767000145, 43.118889409000076],
            [7.908836739000151, 43.045024147],
            [7.842301775000124, 43.127345599000023],
            [7.84116443600027, 43.128769095000223],
            [7.704580983000199, 43.370834795000064],
            [7.670575214000223, 43.434778151999978],
            [7.61052619800023, 43.653226904000235],
            [7.570561969000153, 43.703126522000161],
            [7.537345391000201, 43.745015452000189],
            [7.533643572000244, 43.760116320000179],
            [7.531702379333524, 43.773706735666792],
            [7.531936053761967, 43.782045029162305],
            [7.512500096385963, 43.821109056821115],
            [7.49621808178432, 43.874509156272609],
            [7.598336149560396, 43.964718048345844],
            [7.658609241997112, 44.006800063907974],
            [7.706245106618837, 44.075827048407291],
            [7.70904516520136, 44.091309094167912],
            [7.661663272246585, 44.133954038348975],
            [7.662218154238474, 44.17082703634901],
            [7.614718076448469, 44.180273105978074],
            [7.520282190592013, 44.156109084884122],
            [7.466391246889259, 44.138609179747391],
            [7.420973233853942, 44.126109079868854],
            [7.350836150094835, 44.13151808964119],
            [7.031391257988219, 44.246936058499216],
            [6.976391220854168, 44.284164113918905],
            [6.877918107502978, 44.375136094459378],
            [6.853609079483647, 44.517773120489153],
            [6.852782288552078, 44.540836094778911],
            [6.950836139105263, 44.664718109487126],
            [6.975418081892713, 44.688191126481399],
            [7.028336054272245, 44.744718011102123],
            [7.03166333456619, 44.831382196312745],
            [7.006391225869265, 44.850691083797585],
            [6.969163338087526, 44.864300109469298],
            [6.942363208437655, 44.870691143020395],
            [6.901663201074882, 44.860409062366443],
            [6.849309163142607, 44.870409175796908],
            [6.775282205747146, 44.905545108178103],
            [6.749027067443137, 44.918327007642119],
            [6.661663328591771, 45.039991170763983],
            [6.62396320438387, 45.115718145766323],
            [6.634118215385399, 45.124000136679115],
            [6.637782280549743, 45.126109191162016],
            [6.689927106178004, 45.148054019564995],
            [6.766391185747977, 45.168191206724032],
            [6.821663299459829, 45.153053992461139],
            [6.939445132658022, 45.179445085235045],
            [7.064718208021134, 45.223054115921855],
            [7.127782138946657, 45.25930014756284],
            [7.1586091025315, 45.377491185275062],
            [7.146945013678135, 45.430545112124491],
            [7.089163190510362, 45.476864179753704],
            [6.995418141115522, 45.52527319112653],
            [6.969445137673176, 45.596936116543816],
            [6.809863256136566, 45.729645113510912],
            [6.799345141088509, 45.788664105590897],
            [6.81333604626974, 45.834236010368727],
            [6.872363252614889, 45.85597313521049],
            [6.902082128596874, 45.849854010599245],
            [6.92221814228941, 45.85221804257759],
            [6.943891229390772, 45.858327108904859],
            [6.984509094102066, 45.87590010423753],
            [7.038054032840932, 45.931936144606993],
            [7.070282283002712, 45.903053950202548],
            [7.103263228071341, 45.8835360180517],
            [7.191391228856503, 45.880691200105844],
            [7.230282253870769, 45.898045092488871],
            [7.296809084284007, 45.923191137361528],
            [7.338891267484371, 45.920554022977115],
            [7.386109210412599, 45.915409043155535],
            [7.429718073461231, 45.929445042976283],
            [7.458054103053485, 45.941109131829819],
            [7.536663279289769, 45.981664132267156],
            [7.650282156445144, 45.980554033007479],
            [7.709163182398413, 45.943327151054234],
            [7.743336034102185, 45.93221811183065],
            [7.783891202177699, 45.923609059055082],
            [7.855745067349858, 45.91905399758059],
            [7.866391257878831, 45.948327123950435],
            [7.879300226995383, 45.975136138417838],
            [7.90680913037977, 45.992636043554398],
            [7.950000239373253, 45.994164232145692],
            [7.973054161207358, 45.996936127533559],
            [8.009027110442275, 46.009300105304078],
            [8.149582239355425, 46.151245121405907],
            [8.158745168294388, 46.183818035426839],
            [8.141945152074982, 46.22290905265146],
            [8.303745052836007, 46.422909141965533],
            [8.357218074845434, 46.450000123656366],
            [8.365273251457779, 46.453609035897784],
            [8.441454022699247, 46.462082134203229],
            [8.460836168018204, 46.448327095777984],
            [8.465973268850746, 46.372635995321161],
            [8.448127191111951, 46.322982103685433],
            [8.434582203180696, 46.293191143336003],
            [8.445000238304488, 46.247218080670791],
            [8.460554033156598, 46.233327087775479],
            [8.615836165990288, 46.121245116390583],
            [8.696527071705134, 46.101109102698047],
            [8.71930019978214, 46.10401812602214],
            [8.728854060686331, 46.108591124769475],
            [8.747218140042719, 46.121109161920685],
            [8.822218068761572, 46.098609116249804],
            [8.844163232440735, 46.080753986055697],
            [8.851454147119114, 46.056736145353625],
            [8.820282184398707, 46.033882215729534],
            [8.819863256876886, 45.9863821379397],
            [8.897782267203866, 45.952773220683127],
            [8.926663288142038, 45.900836098615912],
            [8.936318067160585, 45.871873102664438],
            [8.951109108821413, 45.845273132862872],
            [8.998336104205123, 45.829436029682938],
            [9.036663194772188, 45.837773173518656],
            [9.084100240650059, 45.902291197011266],
            [9.073054065700433, 45.920000146813663],
            [9.022982252371037, 45.938953976879304],
            [8.997363300519908, 45.975136138417838],
            [9.016663303187556, 46.022500094099769],
            [9.035827183747188, 46.05721810478741],
            [9.083336146354497, 46.121109161920685],
            [9.132218064705626, 46.16027310170324],
            [9.144436197360562, 46.168044969987307],
            [9.176454061751997, 46.178600133047496],
            [9.241009133256739, 46.2336271599101],
            [9.276663234893476, 46.292500139236211],
            [9.292636124905584, 46.32485395030767],
            [9.297218176108231, 46.35096424932442],
            [9.273127077572042, 46.425764185832975],
            [9.250627199539167, 46.442427074116381],
            [9.293782266348842, 46.500827146464019],
            [9.366800210237443, 46.50805402340211],
            [9.455000127751816, 46.472500169327247],
            [9.45028228971745, 46.426664234598434],
            [9.457636068670013, 46.386245020992874],
            [9.521391171333335, 46.319718022941629],
            [9.544863182499171, 46.306245119377991],
            [9.58194505752698, 46.29694506250263],
            [9.624718077188788, 46.291936037150947],
            [9.708818070572704, 46.300273180986721],
            [9.770554140393642, 46.342209183795205],
            [9.813054077649326, 46.358327080732295],
            [9.852782287155094, 46.369718087179706],
            [9.908054065590989, 46.383191158381521],
            [9.946945090605311, 46.379582078502153],
            [9.990418166822394, 46.355200127925059],
            [9.982218150922847, 46.333045081389741],
            [9.995000218025041, 46.291664128211153],
            [10.071773254546713, 46.221800126858341],
            [10.136391190325412, 46.230691146857509],
            [10.173954018958739, 46.263118048124966],
            [10.153891263100149, 46.39027319603224],
            [10.090691210066325, 46.423744985352414],
            [10.049682245751853, 46.445591074935749],
            [10.044445064995045, 46.502636128812298],
            [10.051391148176037, 46.541518101371253],
            [10.119163194128475, 46.611382102724065],
            [10.181109146805568, 46.632218172971932],
            [10.241800160935611, 46.634445077013879],
            [10.253609089076235, 46.615964153926711],
            [10.2463181743978, 46.590409072178034],
            [10.303609153314312, 46.554864270558653],
            [10.337218070570657, 46.549435982409022],
            [10.449718131287057, 46.539164127676884],
            [10.457527047583426, 46.542500125150013],
            [10.471873177822374, 46.555764151686105],
            [10.485691248159924, 46.592636143857817],
            [10.486527091546975, 46.617354041115163],
            [10.470836168758694, 46.635827085213123],
            [10.447500112062585, 46.763053982211886],
            [10.471236153179234, 46.871354038873392],
            [10.476109224060963, 46.865691225072396],
            [10.497773258706786, 46.855273189948377],
            [10.563609085020232, 46.848882156397281],
            [10.599718156362997, 46.857773176396449],
            [10.646945151746763, 46.870418115562245],
            [10.680136147309724, 46.873818150775833],
            [10.763263337008198, 46.83055395158658],
            [10.791663236702732, 46.801382078607446],
            [10.834163341596422, 46.78694508853772],
            [10.887782208721376, 46.771109158824061],
            [11.016800150795802, 46.773327010410696],
            [11.07139115105366, 46.823464202585001],
            [11.097082187272633, 46.891591138318631],
            [11.100554139215433, 46.915409154448696],
            [11.118054044352164, 46.929991151443687],
            [11.177154005616956, 46.967353987866829],
            [11.483891261921997, 47.014164235023486],
            [11.621945063282624, 47.01277317436859],
            [11.710136095979863, 46.996382195025149],
            [11.731109126525894, 46.978882122250468],
            [11.761245086010945, 46.978191118150619],
            [12.07528214181167, 47.059436067666923],
            [12.14012705952905, 47.08480909447826],
            [12.186109174649744, 47.094582225970044],
            [12.227500186112081, 47.084164190846082],
            [12.230836183585211, 47.072218134769059],
            [12.22389127387089, 47.063044979908284],
            [12.194582272955188, 47.032982110619059],
            [12.133018199788182, 47.012564129702753],
          ],
          [
            [12.410082177664492, 43.912782099745016],
            [12.471527060168341, 43.898682229822043],
            [12.495000244800451, 43.914718151745888],
            [12.496391137817398, 43.923336089338761],
            [12.511109089282229, 43.941245031351514],
            [12.504654017990902, 43.986873094881105],
            [12.459654094286918, 43.983264182639743],
            [12.415800144388811, 43.957954020102534],
            [12.40694516657328, 43.951664239941977],
            [12.410082177664492, 43.912782099745016],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "JAM", Country: "Jamaica" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.506388888999822, 18.832222222],
            [-75.602808935000041, 18.748027962],
            [-75.671547802, 18.633674097999986],
            [-75.720021962, 18.447721630000032],
            [-75.719675444999837, 18.446336701000021],
            [-75.712160499999953, 18.416494212000075],
            [-75.711695757999962, 18.414645793000076],
            [-75.707659725999974, 18.398407829],
            [-75.699147958999845, 18.370957114000149],
            [-75.684917191999943, 18.327471172000017],
            [-75.584141866999971, 18.115570716000022],
            [-75.556979434, 18.057862614],
            [-75.492975411999851, 17.917075984000078],
            [-75.492427824999936, 17.91587443],
            [-75.491467753999899, 17.913771224000115],
            [-75.49050091600003, 17.91166798899998],
            [-75.489924792999972, 17.91041674100002],
            [-75.483817582000029, 17.897197873],
            [-75.402107765999858, 17.82509853],
            [-75.398797008000031, 17.822165561000062],
            [-75.330771329999948, 17.762012107999951],
            [-75.183414373999966, 17.631093102000065],
            [-75.009984384999967, 17.475853965000098],
            [-74.942178444999854, 17.414794680000071],
            [-74.865955409, 17.346198521000034],
            [-74.670460453, 17.020387689000145],
            [-74.513888999999836, 14.736111000000122],
            [-77.66667, 14.083333],
            [-78.325, 14.25],
            [-78.633333196714204, 14.49361115300006],
            [-78.633333332999854, 15.6],
            [-78.430555556, 15.6],
            [-78.430555556, 16.070833333000152],
            [-79.277777778, 16.070833333000152],
            [-79.277777778, 16.169444444],
            [-79.488888888999867, 16.169444444],
            [-79.488888888999867, 16.070833333000152],
            [-79.769347762999985, 16.07083306900013],
            [-79.870157744999972, 16.256296486000011],
            [-79.870527471999822, 16.256976685000083],
            [-79.871781234599865, 16.25928327260003],
            [-79.872263207, 16.26016997300006],
            [-79.87283085099989, 16.26121428600014],
            [-80.591886328000015, 17.584084139000126],
            [-79.512093801999981, 18.601886816999965],
            [-79.503654756, 18.609841385999985],
            [-79.499117646999849, 18.614389028000133],
            [-79.454043374999856, 18.660352046000028],
            [-79.436857563000046, 18.67792263799997],
            [-79.426758774999882, 18.688045247000147],
            [-79.327384350999921, 18.780646850000082],
            [-79.203993015999913, 18.895188313000048],
            [-79.186181719999865, 18.911820583999983],
            [-79.074932114000035, 19.015109054000149],
            [-79.06896170499985, 19.020588426],
            [-79.025592414999977, 19.059853258000018],
            [-78.967646499999887, 19.111908576000147],
            [-78.907650409000013, 19.166097505000138],
            [-78.723584578999919, 19.356544036000045],
            [-78.636796878999917, 19.343259115000066],
            [-78.238228791999859, 19.223929817000041],
            [-78.201857931999939, 19.21467705600007],
            [-78.021282335999899, 19.197507594000129],
            [-77.972058712999853, 19.193091304000106],
            [-77.848715708999947, 19.18445379700016],
            [-77.840217133999829, 19.183896060000095],
            [-77.829565574999862, 19.183269177000099],
            [-77.808315255000025, 19.182291659000143],
            [-77.725555555999875, 19.173333333000201],
            [-77.6875, 19.170555556000139],
            [-77.649444443999954, 19.168888889000172],
            [-77.611388888999898, 19.168055556000056],
            [-77.573055555999957, 19.16833333300012],
            [-77.567222221999941, 19.168611111000089],
            [-77.534583332999972, 19.169166667000056],
            [-77.49625, 19.169722222000118],
            [-77.486944443999874, 19.169722222000118],
            [-77.47527777799985, 19.169444444000121],
            [-77.460555555999861, 19.168611111000089],
            [-77.447777777999903, 19.16833333300012],
            [-77.419444443999851, 19.166944443999952],
            [-77.418055555999871, 19.166944443999952],
            [-77.408333332999973, 19.166666667000143],
            [-77.382222221999825, 19.165277778000259],
            [-77.344166666999968, 19.16416666700006],
            [-77.305833332999839, 19.163888889],
            [-77.27638888899989, 19.163888889],
            [-77.267499999999899, 19.164722222000123],
            [-77.252777777999825, 19.165],
            [-77.241666666999862, 19.165277778000259],
            [-77.193888888999879, 19.166388889000089],
            [-77.183333332999865, 19.166388889000089],
            [-77.157777777999968, 19.166944443999952],
            [-77.144722222000041, 19.166944443999952],
            [-77.11916666699986, 19.167777778000172],
            [-77.106111110999848, 19.167777778000172],
            [-77.093611110999973, 19.168055556000056],
            [-77.078333332999932, 19.166666667000143],
            [-77.067777777999822, 19.165833332999952],
            [-77.046944444000019, 19.16416666700006],
            [-77.03, 19.1625],
            [-76.99194444399987, 19.159722221999957],
            [-76.945833332999882, 19.157500000000127],
            [-76.932499999999891, 19.15694444400016],
            [-76.92527777799998, 19.156666667000181],
            [-76.877222221999858, 19.155833332999961],
            [-76.868055555999916, 19.156111111000044],
            [-76.838611110999949, 19.156111111000044],
            [-76.798055555999895, 19.15694444400016],
            [-76.764444443999878, 19.158611111000127],
            [-76.722222221999857, 19.158611111000127],
            [-76.683055556, 19.158611111000127],
            [-76.643611111000041, 19.158611111000127],
            [-76.64, 19.158333333000044],
            [-76.626111111, 19.156388889000098],
            [-76.588055555999944, 19.15],
            [-76.516666666999953, 19.136666667000227],
            [-76.514722221999904, 19.136111111000091],
            [-76.484444443999905, 19.130555556000232],
            [-76.47833333299991, 19.129444444000129],
            [-76.471944443999945, 19.128333333000128],
            [-76.446388888999849, 19.123333333000133],
            [-76.439999999999884, 19.12194444400005],
            [-76.412777777999935, 19.116944444000254],
            [-76.401388888999861, 19.114722222000054],
            [-76.374444443999892, 19.109444444],
            [-76.363055555999836, 19.107222222000175],
            [-76.336111110999866, 19.102222222000179],
            [-76.324722222, 19.099722222000096],
            [-76.28638888899988, 19.092500000000101],
            [-76.247499999999889, 19.086111111000022],
            [-76.208333333000013, 19.080277778000109],
            [-76.190277777999825, 19.077777778000197],
            [-76.17, 19.072222222000065],
            [-76.156944443999862, 19.068888889000036],
            [-76.133055555999931, 19.062222222000102],
            [-76.125277777999884, 19.060277778000156],
            [-76.095833332999916, 19.052222222000111],
            [-76.058611110999891, 19.042777778000087],
            [-76.020833333000013, 19.034166667],
            [-75.996111110999863, 19.028888888999958],
            [-75.981388888999874, 19.025833333000108],
            [-75.975277777999878, 19.024166667000046],
            [-75.964722221999949, 19.021944444000113],
            [-75.950833333, 19.016666666999967],
            [-75.934722221999834, 19.011111111000204],
            [-75.899166666999861, 18.997777778000099],
            [-75.850555555999875, 18.978611111],
            [-75.839999999999861, 18.974444443999957],
            [-75.721944444, 18.927777778000191],
            [-75.698611110999849, 18.918333333000263],
            [-75.569166666999848, 18.852777778],
            [-75.543333332999964, 18.861111111000199],
            [-75.528055556000027, 18.845833333000073],
            [-75.506388888999822, 18.832222222],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "JEY", Country: "Jersey" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.156006751999882, 48.783267184000124],
            [-2.186553746999834, 48.778404923999972],
            [-2.249526220999769, 48.799369719000083],
            [-2.318494033999912, 48.819798050000088],
            [-2.323498840999889, 48.821288402999983],
            [-2.328503645999888, 48.822869489000084],
            [-2.375103770999885, 48.837670606000188],
            [-2.396072897999773, 48.844239450000146],
            [-2.491168084999913, 48.861276027000144],
            [-2.516263045999892, 48.86579019900006],
            [-2.569902404999823, 48.882420848000038],
            [-2.578211956999894, 48.884399612000152],
            [-2.596492807999766, 48.88986652900013],
            [-2.605309762999866, 48.90872308500019],
            [-2.609497061999832, 48.917678334000129],
            [-2.625676842999894, 48.955638048000139],
            [-2.634639497999899, 48.976665515000093],
            [-2.642472104499859, 48.995213588000126],
            [-2.642876016999821, 48.996163022000133],
            [-2.662292500999911, 49.041464455000209],
            [-2.68090606699991, 49.084892566000178],
            [-2.702320266999891, 49.106020204000174],
            [-2.711401107999905, 49.115105329000187],
            [-2.70801403899992, 49.117599799000175],
            [-2.653824574999874, 49.153331685000069],
            [-2.611024744999952, 49.180080407000105],
            [-2.609980103999931, 49.180734097000027],
            [-2.609021421999898, 49.181336011000099],
            [-2.577988497999968, 49.200840817000085],
            [-2.573775986999919, 49.203257632000145],
            [-2.536307657999885, 49.232386917000042],
            [-2.513224104999892, 49.248908222000068],
            [-2.491957197999909, 49.264571914000044],
            [-2.466787281999871, 49.273896199000148],
            [-2.422026799999912, 49.289105041000141],
            [-2.367996087999927, 49.307853276000174],
            [-2.336400135999952, 49.318615049000073],
            [-2.310937404999891, 49.327727793000179],
            [-2.264708542999927, 49.344622299000036],
            [-2.23766818099989, 49.3561620320001],
            [-2.226501550999842, 49.359574255000098],
            [-2.192125675999876, 49.370157559000177],
            [-2.171969862999958, 49.379916622000053],
            [-2.169737434999888, 49.381009427000151],
            [-2.156005966999885, 49.38723909800018],
            [-2.152999984999951, 49.388617643000089],
            [-2.117160340999931, 49.405401800000163],
            [-2.096331724999914, 49.430198410000173],
            [-2.095824964999906, 49.430798168000194],
            [-2.09125404599996, 49.43618688100014],
            [-2.0731720039999, 49.433613097000148],
            [-2.003160042999923, 49.41328769900008],
            [-1.989047863999929, 49.405679413000144],
            [-1.964996254999846, 49.391439704000106],
            [-1.937347410999848, 49.375283581000133],
            [-1.931603016999873, 49.371604707000017],
            [-1.928672189999872, 49.369773399000053],
            [-1.925429520999899, 49.368233953000185],
            [-1.921997002999944, 49.366480985000123],
            [-1.913530688999856, 49.362237117000177],
            [-1.900508449999904, 49.35459677700004],
            [-1.88658189999984, 49.346425865000185],
            [-1.880350051999926, 49.342676283000117],
            [-1.86988251299988, 49.336378168000124],
            [-1.852800996999918, 49.326249054000129],
            [-1.843073709999828, 49.320313909000106],
            [-1.825188859999855, 49.293261824000126],
            [-1.818564223999829, 49.283580438000172],
            [-1.816787586999908, 49.260542730000054],
            [-1.818384923999815, 49.223085580000088],
            [-1.83278183799996, 49.219191497000168],
            [-1.849432317999913, 49.214629191000029],
            [-1.852766569999915, 49.206131265000124],
            [-1.856485341499877, 49.128316975500013],
            [-1.856530737999833, 49.126569074500082],
            [-1.856580080999947, 49.125593448000132],
            [-1.856744930999923, 49.123247868000078],
            [-1.854287570999816, 49.11691856900012],
            [-1.854481260499881, 49.111666175000039],
            [-1.8544990339999, 49.110394174000191],
            [-1.85562496499989, 49.07774298400011],
            [-1.866458881999904, 49.041217330000109],
            [-1.882777312999849, 49.040625471000055],
            [-1.883499649999919, 49.0399593140001],
            [-1.908764584999858, 49.01687573900017],
            [-1.909469520999892, 49.016225797000061],
            [-1.910160970999812, 49.015575842000032],
            [-1.911268520999897, 49.014525607000053],
            [-1.912533384999904, 49.013278049000064],
            [-1.919024172999968, 49.006768151000188],
            [-1.940912812999898, 48.985212400000194],
            [-1.954423301999896, 48.968778943000189],
            [-1.970076818999814, 48.950117777000173],
            [-1.970105758999921, 48.943068718000063],
            [-1.954284029999826, 48.920262762000107],
            [-1.955763642999955, 48.916606121000143],
            [-1.968216139999811, 48.884107277000226],
            [-1.986216942999818, 48.839511048000077],
            [-1.999867195999911, 48.805009803000132],
            [-2.035961179999845, 48.794262671000126],
            [-2.058532632999857, 48.788247446000071],
            [-2.07603782599989, 48.787372741000127],
            [-2.095001558999968, 48.786688616000049],
            [-2.147668196999859, 48.784560066000154],
            [-2.156006751999882, 48.783267184000124],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "JOR", Country: "Jordan" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.986018212932379, 32.477664210528076],
            [39.043654190984398, 32.304054040521635],
            [39.260000165002566, 32.355545077700583],
            [39.301109209241304, 32.236382074493406],
            [39.196745126683993, 32.154945011755672],
            [39.072218208343486, 32.057782157512634],
            [39.005000206192619, 32.00555401576645],
            [38.488327120160022, 31.879164135621011],
            [37.986663340547523, 31.754054004456123],
            [37.963882165843359, 31.748336037732031],
            [37.709500120937264, 31.684664251186405],
            [37.471936197161597, 31.625000187836235],
            [37.202200181975797, 31.556164143091621],
            [37.005273233650883, 31.505554043938844],
            [37.294991203148356, 31.217227135096152],
            [37.490000204383534, 31.023053977247969],
            [37.561091148726575, 30.951091147334239],
            [38.00139118265821, 30.504164213095578],
            [37.667500248026329, 30.336391200476612],
            [37.502782271485728, 30.002218130983124],
            [37.41010021505457, 29.985582064790265],
            [37.034436221812484, 29.918054099858978],
            [36.743609158883544, 29.864718038147771],
            [36.500545197866757, 29.502773208785641],
            [36.272727080578591, 29.336527043653518],
            [36.070000190516168, 29.188891050555924],
            [35.541109139466585, 29.272500032050345],
            [35.187218145612121, 29.326945019554671],
            [34.961390935623967, 29.360836115336383],
            [34.957744144500111, 29.360553311500041],
            [34.952259674000175, 29.360858005000182],
            [34.940743346000147, 29.361538413000119],
            [34.930893521000087, 29.364446786000201],
            [34.884496975800175, 29.375334759400232],
            [34.888734673000215, 29.383235715500234],
            [34.889090308000277, 29.384096446000285],
            [34.889798637000354, 29.385384621000185],
            [34.890326761000154, 29.386345074000189],
            [34.891882902000191, 29.390237686000205],
            [34.892260660500256, 29.391044097500242],
            [34.892955204000145, 29.392617490000248],
            [34.897497455000348, 29.402383604000079],
            [34.900013771000147, 29.40778173266682],
            [34.900957666000181, 29.409784497000032],
            [34.901919661000306, 29.411837706000199],
            [34.903423936000138, 29.414675879000299],
            [34.904016850500227, 29.415719219000096],
            [34.905933712000149, 29.418887224000287],
            [34.906773571000087, 29.420267938000109],
            [34.908484720000217, 29.426624922000144],
            [34.908511826000336, 29.428057214000148],
            [34.908578060000309, 29.431557001000272],
            [34.918094416000201, 29.443290276000198],
            [34.918790304000254, 29.445543166000107],
            [34.921211000000142, 29.453380000000152],
            [34.933619000000249, 29.466837000000169],
            [34.966514000000245, 29.519090000000119],
            [34.978172122000103, 29.542587985000068],
            [34.97998176437261, 29.545754419811146],
            [34.977491192912055, 29.570000095753983],
            [34.974627096588904, 29.577018095664243],
            [35.007218115520914, 29.640827010146268],
            [35.075836063144209, 29.867773241863745],
            [35.072773315715381, 29.891664180551572],
            [35.072218098447479, 29.920827001075338],
            [35.07305410947265, 29.953609127400114],
            [35.10367320185793, 30.048891082565433],
            [35.118745204914035, 30.070000067581418],
            [35.143882197331209, 30.094164256313491],
            [35.160209138934363, 30.116045046976012],
            [35.166936119165712, 30.151664112257663],
            [35.161382270106117, 30.31944500386544],
            [35.156091277530663, 30.41528217629866],
            [35.15860920125175, 30.444164203064986],
            [35.206109111403464, 30.59471810430432],
            [35.219718137075176, 30.621945040465107],
            [35.255554126011816, 30.681945050495699],
            [35.292500214207735, 30.759164171905354],
            [35.300000173552007, 30.791109113738798],
            [35.337773220317786, 30.886945112705519],
            [35.357909234010492, 30.921109079591758],
            [35.36813616174129, 30.928664191859298],
            [35.395136115963737, 30.946764241264873],
            [35.454991286706559, 31.099718049027899],
            [35.462218163644707, 31.121109168905662],
            [35.459509132530968, 31.156944990204124],
            [35.432782260714816, 31.200273227133835],
            [35.41138225601992, 31.225273091614611],
            [35.452500185076019, 31.351391062820255],
            [35.471000218902304, 31.40041815573494],
            [35.473118158202681, 31.410345011331287],
            [35.478609142988091, 31.449164119616285],
            [35.478200273750247, 31.497318153493666],
            [35.451109124421237, 31.499444977611347],
            [35.400673201216819, 31.493554008405226],
            [35.378609182150285, 31.484164097527753],
            [35.259163205891014, 31.403609146282804],
            [35.240554039685151, 31.384164136689719],
            [35.220409141174969, 31.376800131815401],
            [35.13860914202894, 31.361945052414001],
            [34.937563326472059, 31.350691173903101],
            [34.90819129364445, 31.360553991759218],
            [34.889854036378381, 31.380554050982028],
            [34.888191234421811, 31.412500166281973],
            [34.910554152156124, 31.44388217949637],
            [34.937218159698318, 31.481664111079681],
            [34.946382262103612, 31.502009169438054],
            [34.945827212473887, 31.559164193884612],
            [34.950273309206636, 31.586800166921591],
            [34.961363237690932, 31.603354090463199],
            [34.985545196057728, 31.633327105750027],
            [35.001663260632995, 31.646945016239044],
            [35.097500265428209, 31.70832703446905],
            [35.16290911759279, 31.744864253427011],
            [35.209718191283002, 31.750000180793052],
            [35.227618248478279, 31.775100125198094],
            [35.153054178725711, 31.813054053434939],
            [35.108282242789073, 31.833073223397093],
            [35.035827227519405, 31.822218155840233],
            [35.014163360511589, 31.820273219021885],
            [34.975136213389362, 31.823327081633408],
            [34.965554189290401, 31.8305539585715],
            [34.966109238920296, 31.861382095622673],
            [34.987500191159882, 31.968891068260405],
            [35.000863291791632, 31.985318089787654],
            [35.007500250382691, 32.028054061437331],
            [35.006100137272426, 32.054164192815904],
            [34.985836215736811, 32.132500119007886],
            [34.979163214962114, 32.15305405439409],
            [35.025554031682645, 32.239300150273039],
            [35.047773283596371, 32.36750018623303],
            [35.04582717331175, 32.383327063491208],
            [35.047218233966476, 32.394436102714849],
            [35.056663297767102, 32.42528217703898],
            [35.066382282164284, 32.447218120624484],
            [35.08180012254644, 32.471454058447875],
            [35.17944510386161, 32.527500157101315],
            [35.208054048221811, 32.546391122892771],
            [35.263463289870231, 32.521664173180113],
            [35.289027088798264, 32.516245105123971],
            [35.317363286028467, 32.516936109223764],
            [35.350554113953478, 32.523464103073081],
            [35.408191265471885, 32.504164100405376],
            [35.423882188260166, 32.485691056307417],
            [35.48110912943622, 32.412773192343082],
            [35.552563345463966, 32.394200068320856],
            [35.546945123387957, 32.405000150592571],
            [35.571936103051428, 32.645827149283605],
            [35.615782173960724, 32.679653996023092],
            [35.631936113081849, 32.687218160745942],
            [35.648891194510497, 32.685273223927766],
            [35.689991186293753, 32.711936058003289],
            [35.76331808713428, 32.748882146199435],
            [35.793609111828459, 32.744164140526891],
            [35.931245159133766, 32.720273201839063],
            [36.00332717971105, 32.663327054420336],
            [36.025836110199236, 32.613609125043808],
            [36.079991251491151, 32.543327034359663],
            [36.125827186219794, 32.52777323950761],
            [36.165600155088896, 32.51822708995428],
            [36.197491285103581, 32.53333597338451],
            [36.314436101448393, 32.459718052865014],
            [36.346663345781508, 32.433054045323047],
            [36.400273328089099, 32.381945055291965],
            [36.488609200073597, 32.375544969285215],
            [36.516109218640622, 32.363054089500281],
            [36.643054148415786, 32.349164102433463],
            [36.724163311100142, 32.336664170192876],
            [36.837773303438212, 32.313609074892099],
            [36.866391300254065, 32.341664143089247],
            [37.153273168985208, 32.500291158213372],
            [37.493891251486559, 32.687218160745942],
            [37.495663353460714, 32.688882136169013],
            [37.498600204703621, 32.689718147194014],
            [37.624991258315333, 32.758327042361998],
            [37.958882192947215, 32.937491061427863],
            [38.318927180130459, 33.128736085212438],
            [38.533563245895778, 33.241100191458997],
            [38.697491144241155, 33.32750017908063],
            [38.794700266589984, 33.377591103149143],
            [39.086009121314873, 32.501509048748119],
            [38.986018212932379, 32.477664210528076],
          ],
          [
            [35.251663246546798, 31.788054021315816],
            [35.254436147763158, 31.786391051721239],
            [35.259718087882788, 31.787218010290815],
            [35.262500209192638, 31.789718164376893],
            [35.263609134985757, 31.803609157272376],
            [35.256382258047665, 31.808054080538625],
            [35.248891183520925, 31.80889109739212],
            [35.246382312255491, 31.808054080538625],
            [35.243609243401124, 31.803891124495806],
            [35.251663246546798, 31.788054021315816],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "JPN", Country: "Japan" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [154.604179644000055, 20.998994947000142],
              [153.98484264300032, 20.949638947000096],
              [153.983376643000156, 20.949758947000078],
              [153.981909643000108, 20.949638947000096],
              [153.362575643000241, 20.999034947000098],
              [152.760788642000193, 21.14579094700008],
              [152.758778642000152, 21.146646947000022],
              [152.749042642000177, 21.148998947000109],
              [152.181907642000169, 21.388892947000045],
              [151.665777641000318, 21.715122948000044],
              [151.215773641000169, 22.118331948000161],
              [150.845420640000157, 22.58688894800008],
              [150.566277640000294, 23.107171949000019],
              [150.387574640000253, 23.663915949],
              [150.31587264000018, 24.240857616666744],
              [150.315859640000212, 24.241707950000091],
              [150.35471664000022, 24.821231951000129],
              [150.504526640000336, 25.385557951000067],
              [150.762168640000141, 25.917468951999979],
              [150.763133140000264, 25.918776452000145],
              [151.121981641000161, 26.401623952000023],
              [151.130984641000168, 26.410037952000039],
              [151.131936641000152, 26.411312952000173],
              [151.133216641000331, 26.412508952000167],
              [151.133919641000261, 26.413449952000065],
              [151.583887641000274, 26.831845951999981],
              [152.111410642000237, 27.172364953000113],
              [152.700019642000143, 27.423922952999988],
              [153.33080564300019, 27.578255953000223],
              [153.983128643000214, 27.630261953000172],
              [153.983893643000101, 27.630242453000164],
              [154.636467644000163, 27.578211953000107],
              [155.267239645000132, 27.423835953000093],
              [155.855825645000209, 27.172238953000146],
              [156.383318646000134, 26.83168495200016],
              [156.833250646000238, 26.413259951999976],
              [156.834230646000378, 26.411951952000095],
              [157.193117646000132, 25.929103952000048],
              [157.4507866470002, 25.397194951000131],
              [157.600613647000273, 24.832868951000023],
              [157.601015647000168, 24.826885951000236],
              [157.601394647000234, 24.825452951000102],
              [157.601472647000151, 24.824302951000163],
              [157.602284647000147, 24.821231951000129],
              [157.641166647000091, 24.241907950000098],
              [157.641010647000144, 24.24066195000006],
              [157.641094647000159, 24.239408950000097],
              [157.569393647000311, 23.662716949000071],
              [157.390692647000151, 23.10597294900009],
              [157.111552646000177, 22.585688948000097],
              [156.741203646000258, 22.117132948000233],
              [156.739914646000329, 22.115983948],
              [156.739194646000215, 22.115068948000157],
              [156.28920764600025, 21.711852948000057],
              [155.773094645000015, 21.385615947000133],
              [155.205976645000192, 21.145713947000104],
              [154.604179644000055, 20.998994947000142],
            ],
          ],
          [
            [
              [141.247930172000338, 45.821185518000163],
              [141.352515396000399, 45.809310679000248],
              [141.492395862000308, 45.846728106000057],
              [141.621211712000445, 45.785973725000247],
              [141.707403399000327, 45.767594281000243],
              [141.838487341000359, 45.739775192000195],
              [141.937492827000256, 45.721277649000115],
              [141.969370008000169, 45.715511486000253],
              [142.081992219000426, 45.694566723000207],
              [142.274918317000356, 45.65898096900014],
              [142.299111693000327, 45.654438501000186],
              [142.377175281000291, 45.639893873000233],
              [142.395655213000282, 45.640022085000169],
              [142.401280225000278, 45.640076412000155],
              [142.424017309000419, 45.640421068000251],
              [142.470666745000358, 45.643140558000198],
              [142.486036498000431, 45.644071238000265],
              [142.49440727900037, 45.644653524000148],
              [142.664465782000434, 45.656767062000199],
              [142.806149544000164, 45.66765338200014],
              [142.837273581000204, 45.64026431800022],
              [142.860857620000388, 45.619583777000173],
              [142.871296769000196, 45.611369655000203],
              [142.876647016000334, 45.607159772000102],
              [142.878289294000297, 45.605883347000088],
              [142.891213265000374, 45.595838469000341],
              [142.912975533000235, 45.579096683000273],
              [142.918545397000344, 45.574904742000285],
              [142.952274231000274, 45.552142332000187],
              [142.99366471500025, 45.526385351000101],
              [142.994763227500243, 45.525709386500068],
              [142.996500655000432, 45.524657820000243],
              [143.013446312000383, 45.514410220000173],
              [143.314930824000271, 45.372812566000334],
              [143.380012171000203, 45.344053447000135],
              [143.416495490000329, 45.329810067000039],
              [143.489771186000326, 45.301044000000161],
              [143.604372560000229, 45.256010127000195],
              [143.717462868000325, 45.229881484000259],
              [143.732262638000407, 45.226776760000234],
              [143.882618292000302, 45.199614037000288],
              [143.937334866333572, 45.192815952000103],
              [144.168144177000272, 45.177412281000215],
              [144.320310949000145, 45.173057376000088],
              [144.370605985000225, 45.181751609000116],
              [144.380270070000279, 45.183789999000169],
              [145.229148123000186, 45.649528222000072],
              [145.257993944000305, 45.581587968000122],
              [145.299909395000327, 45.482864953999922],
              [145.330973560000302, 45.40772100200013],
              [145.47692656900017, 45.053671781000105],
              [145.533350182000163, 44.914893950000021],
              [145.580314235000174, 44.785220954000067],
              [145.607976085000246, 44.705021574999989],
              [145.616637926000067, 44.67990849500012],
              [145.618808703000127, 44.673403494000183],
              [145.6295601610002, 44.64118542600005],
              [145.63911400700033, 44.613985515000081],
              [145.639829806000193, 44.611947626000131],
              [145.647513851000213, 44.58981053600013],
              [145.648050716000171, 44.585378087000095],
              [145.648672470000292, 44.580244773000118],
              [145.650707799000202, 44.563991607000162],
              [145.654718099000206, 44.531967260000158],
              [145.659505075000311, 44.492588825000098],
              [145.664947304000179, 44.447820175],
              [145.66208647700023, 44.438147023000056],
              [145.649879358000135, 44.40048318700002],
              [145.647224217000144, 44.392371954000055],
              [145.641435204000345, 44.37579282400003],
              [145.637674137000204, 44.361870360000012],
              [145.636184931000344, 44.356357718999959],
              [145.634303770000201, 44.349394165000092],
              [145.633211641000116, 44.346051143000068],
              [145.632967534000272, 44.345303928000135],
              [145.631526321000138, 44.341283019000059],
              [145.629426025000271, 44.330200095],
              [145.626694020000059, 44.3157837440001],
              [145.624708427000201, 44.305593902999988],
              [145.624003443000106, 44.301976006000018],
              [145.621825168000328, 44.290797347000137],
              [145.621026187000325, 44.289263095000024],
              [145.619358362000298, 44.286060436000128],
              [145.617568054000344, 44.282622578000087],
              [145.611962832000131, 44.272388448000129],
              [145.611581605000083, 44.271689326500052],
              [145.603837126000144, 44.257374567],
              [145.602672899000027, 44.255325534],
              [145.593693893000136, 44.237628339000082],
              [145.587958068000148, 44.228538181000033],
              [145.580807526000171, 44.217205976000059],
              [145.57773617900034, 44.212338492000015],
              [145.576766038000301, 44.210965279],
              [145.573477046000107, 44.206309781000073],
              [145.570705930000145, 44.202387324000028],
              [145.559892031000231, 44.188718268],
              [145.557958260000191, 44.185361614000101],
              [145.556904201000293, 44.183531970000104],
              [145.549197876000107, 44.170318022000117],
              [145.544301810000206, 44.161922792999917],
              [145.536284022000132, 44.148617573000024],
              [145.534412205000194, 44.145511361999979],
              [145.53395993100014, 44.144528713000113],
              [145.533278786000324, 44.143048799000098],
              [145.531128691000163, 44.14082034],
              [145.527195888000279, 44.136744198000031],
              [145.526481010000225, 44.135993502000133],
              [145.519781064000313, 44.128862966000042],
              [145.509811211000056, 44.118252373000075],
              [145.501786016000324, 44.109526327000196],
              [145.499751463000166, 44.107314094],
              [145.497792070000287, 44.105578432000129],
              [145.496957206000161, 44.104838895000114],
              [145.495096416000365, 44.10361831649999],
              [145.485957343000337, 44.097751980999931],
              [145.484498622000274, 44.096816302000093],
              [145.471836493000183, 44.086893374000056],
              [145.449837421000211, 44.069653365000136],
              [145.442598518333398, 44.063260016666732],
              [145.441088923000279, 44.060498651000103],
              [145.439989830000172, 44.058362946999978],
              [145.438546814000347, 44.05569554799996],
              [145.438048468000147, 44.054774361999989],
              [145.434944469000243, 44.050682372000153],
              [145.434427505000144, 44.050000861],
              [145.433257623000145, 44.048706386999982],
              [145.414930718000164, 44.028427675000046],
              [145.412938111000017, 44.026222856],
              [145.40701412500016, 44.019532356000127],
              [145.40485897766689, 44.017835341000136],
              [145.401508299000056, 44.01501897899999],
              [145.400292347000345, 44.013996928999973],
              [145.399500511000156, 44.013340130000074],
              [145.394713111000215, 44.009369153000023],
              [145.386460047000242, 44.002415467999981],
              [145.380522232000317, 43.997412514000075],
              [145.370753634000152, 43.989552448999973],
              [145.367409178000344, 43.986593453000125],
              [145.366701767000336, 43.985988544000236],
              [145.366100234000214, 43.98546127100019],
              [145.361130149000132, 43.981104747000018],
              [145.360149910000246, 43.980241915000192],
              [145.355481802000213, 43.976052087000085],
              [145.354158929000278, 43.974958603000061],
              [145.353528777000179, 43.974437721000129],
              [145.345802721000268, 43.967908913000088],
              [145.344580002000043, 43.966875669999979],
              [145.326177775000247, 43.950490014000081],
              [145.322315547000244, 43.94705102100022],
              [145.32143722800015, 43.946268950999979],
              [145.320094305000111, 43.944428679499993],
              [145.317330827000092, 43.940947473000051],
              [145.312635568000246, 43.937340499000186],
              [145.290493190000234, 43.920330369000169],
              [145.285850264000146, 43.91559012700003],
              [145.28428905300018, 43.914013163000106],
              [145.281958479000082, 43.911659071000201],
              [145.27904788900014, 43.908844605000183],
              [145.275224760000128, 43.90514773700022],
              [145.27055517500014, 43.898231926999983],
              [145.269619442000135, 43.896846075000099],
              [145.266861774000176, 43.892888253000137],
              [145.25925427850018, 43.879884697500074],
              [145.258767242000204, 43.879078781000089],
              [145.258259433000148, 43.878249629666755],
              [145.257776474666912, 43.877425838666682],
              [145.255269593000207, 43.873331236000183],
              [145.253024590500218, 43.869664904500098],
              [145.252074868000136, 43.864904399000068],
              [145.251405551000062, 43.86154933500012],
              [145.250543040000025, 43.856971043000044],
              [145.246543302000191, 43.835740059000159],
              [145.245730380000282, 43.830853346000083],
              [145.245596079000279, 43.830069342500053],
              [145.244559209000272, 43.824405592],
              [145.24442854750032, 43.823533508500191],
              [145.244260120000149, 43.822409366000045],
              [145.243382994000171, 43.808657333999975],
              [145.243366028000196, 43.805638052000063],
              [145.242699678000122, 43.782298575000141],
              [145.242681892000292, 43.78115952200011],
              [145.250973329000203, 43.768057699999986],
              [145.251577205000217, 43.7646777920001],
              [145.252974755000196, 43.759618924999984],
              [145.253287884000315, 43.758485456000244],
              [145.255221543000175, 43.75318001200003],
              [145.255581351000274, 43.752278906000072],
              [145.256216197000185, 43.75068899300004],
              [145.259992807000231, 43.739758957999982],
              [145.261314937000179, 43.735932529000024],
              [145.261622167000269, 43.735187580000115],
              [145.262416683000197, 43.733261092000021],
              [145.263676841000233, 43.732127395000134],
              [145.278436539000154, 43.71947718600012],
              [145.284762766000199, 43.713481300500064],
              [145.292190254000161, 43.708365720000018],
              [145.293782878000115, 43.707245969000212],
              [145.294822468000348, 43.706512916000236],
              [145.295648444000108, 43.705916096000067],
              [145.298245160000192, 43.704260766000061],
              [145.320499689000144, 43.689664493],
              [145.327730732000191, 43.685645579000152],
              [145.332474437000201, 43.682568596000067],
              [145.348836294000193, 43.673019450000083],
              [145.354949006000197, 43.669451934000108],
              [145.357079693000173, 43.668236329000024],
              [145.361734865000244, 43.66558045000005],
              [145.368509404000065, 43.662459923000085],
              [145.372142307000217, 43.660737488000109],
              [145.397639463000246, 43.649660147000077],
              [145.418420797000181, 43.641150294000056],
              [145.426523764000308, 43.638253435000081],
              [145.428613614000255, 43.637488834000123],
              [145.442463409000311, 43.618049624000037],
              [145.44850866600018, 43.609790595000106],
              [145.457505934000324, 43.598579570000027],
              [145.459683855000321, 43.595865776000039],
              [145.472549011500064, 43.581116459500151],
              [145.4730050390001, 43.580629252000051],
              [145.475386361000119, 43.578211191000094],
              [145.491072430000145, 43.561965581999971],
              [145.516877817000136, 43.535902436000015],
              [145.53252494100002, 43.520099020000032],
              [145.534182178000265, 43.518385597000076],
              [145.539709706500275, 43.512671380500137],
              [145.556315316000337, 43.516238749000081],
              [145.586919813000122, 43.522947947000091],
              [145.625143076000199, 43.531569128000115],
              [145.65500658700023, 43.537955328999942],
              [145.656501951000223, 43.538280808000138],
              [145.661499214000173, 43.539378188],
              [145.68825879800022, 43.545728353000072],
              [145.689924252000196, 43.543051913000113],
              [145.705746628000156, 43.527447555000094],
              [145.720886778500244, 43.512857831000133],
              [145.721446850000348, 43.51231021000018],
              [145.757499685000312, 43.477058648000082],
              [145.766142627000022, 43.468844945000171],
              [145.778696134000143, 43.456914890000121],
              [145.780719735000162, 43.455113173000029],
              [145.781381961000079, 43.454523558],
              [145.792563389000293, 43.444101911000018],
              [145.814955333000285, 43.428812312],
              [145.824312631000225, 43.425070384000151],
              [145.828710260000321, 43.4230779130001],
              [145.838582514000223, 43.41929346400002],
              [145.840916114000265, 43.417252954500157],
              [145.841293081000231, 43.415294710000126],
              [145.841727873000309, 43.412235865000241],
              [145.842254031000152, 43.408985653000059],
              [145.843290282000225, 43.40258447299999],
              [145.844540149000295, 43.392100519999985],
              [145.844861430000151, 43.389346922000072],
              [145.845008081000287, 43.387510003999978],
              [145.845171131000143, 43.385397491999981],
              [145.846345683000322, 43.378114171000163],
              [145.847686181000199, 43.369801832000121],
              [145.851581847000148, 43.3632321340001],
              [145.855070470000214, 43.356845743000065],
              [145.856484225000258, 43.354933437000113],
              [145.883780756000192, 43.293125567000061],
              [145.891446176000272, 43.282918866000102],
              [145.900258037000071, 43.271185648000085],
              [145.903107797000303, 43.267391119500132],
              [145.903518330000111, 43.265857184500135],
              [145.904521171000169, 43.262109324],
              [145.917556789000173, 43.211458282500075],
              [145.918430759000216, 43.207353862000105],
              [145.941451393000307, 43.099241190000157],
              [145.954725630000212, 43.081212988000118],
              [146.274978399000247, 42.623940058000017],
              [146.785231465000265, 41.895375196000032],
              [146.851939156000071, 41.798640337000109],
              [147.58393338500025, 40.768112877000078],
              [147.859705876222392, 40.349891798000044],
              [147.58948563700028, 40.214934965000111],
              [147.585408637000228, 40.213677965000159],
              [147.584653637000315, 40.213317965000044],
              [147.156648637000103, 40.080281964999983],
              [147.109645637000199, 40.057563965000213],
              [147.101963637000154, 40.055151965000107],
              [147.097072637000338, 40.052784965000058],
              [147.067908637000301, 40.043620965000116],
              [147.062083637000114, 40.040835965000099],
              [147.060359637000261, 40.04003696500024],
              [146.962164637000228, 40.00910096500013],
              [146.960919637000302, 40.008495965],
              [146.782781637000198, 39.952083964999986],
              [146.558162636000247, 39.726673964000099],
              [146.383891636000158, 39.602827964000085],
              [146.389576636000015, 39.512793964000196],
              [146.38866363600016, 39.507231964000027],
              [146.388894136000204, 39.502379964000056],
              [146.388853302666917, 39.501304297333377],
              [146.388623636000034, 39.499734964000027],
              [146.388693636000312, 39.49861596400018],
              [146.388632636000239, 39.497995463999985],
              [146.388264636000144, 39.495502964000025],
              [146.388382636000188, 39.493621964],
              [146.294783636000147, 38.917680964000226],
              [146.290160636000223, 38.906017964000114],
              [146.28058663600018, 38.846435964000023],
              [146.271162636000213, 38.822615964000192],
              [146.268108636000193, 38.803555964000054],
              [146.050618636000195, 38.248564963000206],
              [146.004446636000125, 38.177229963000229],
              [145.962887636000346, 38.06975896299997],
              [145.962156636000259, 38.068624963000104],
              [145.961796636000145, 38.067693963000124],
              [145.954446636000171, 38.056289963000154],
              [145.905971636000231, 37.930413963000092],
              [145.739547136000169, 37.670147463000148],
              [145.529808636000212, 37.113899962000119],
              [145.206078635000154, 36.594380962000059],
              [145.204173135000246, 36.592216462000067],
              [145.202996635000204, 36.5908299620001],
              [145.077873635000287, 36.45420496100013],
              [145.072014635000073, 36.41155996100008],
              [144.948318635000078, 36.069535961000213],
              [144.97696563500017, 35.716863961],
              [144.976890635000132, 35.716305961000046],
              [144.976900635000106, 35.71575096100014],
              [144.899887635000141, 35.138064960000122],
              [144.703617635000342, 34.579977960000178],
              [144.700294635000205, 34.574373960000116],
              [144.694671635000191, 34.558254960000113],
              [144.692029635000267, 34.553795960000116],
              [144.691423635000206, 34.552060960000119],
              [144.384184634000121, 34.029693959000099],
              [143.976612634000162, 33.558082959000217],
              [143.811306634000175, 33.421945959000084],
              [143.834125634000088, 33.017121958000018],
              [143.83375663400011, 33.014706958000033],
              [143.833872134000217, 33.011933458000101],
              [143.833865634000063, 33.0113259580001],
              [143.833740634000037, 33.010506958000178],
              [143.833789634000141, 33.009630958000173],
              [143.760684634000313, 32.528150957999969],
              [143.766256634000229, 32.516963958000019],
              [143.917619634000175, 31.950874957000167],
              [143.930777634000151, 31.714712957000131],
              [144.012457634000043, 31.548543957000049],
              [144.161296634000223, 30.982213956000123],
              [144.185649634000129, 30.527096956000207],
              [144.605104635000203, 30.261194956000111],
              [145.065968635000218, 29.839981955000098],
              [145.066778635000105, 29.838912955000126],
              [145.0689476350002, 29.836919955000042],
              [145.069839135000166, 29.835778455000082],
              [145.099029635000306, 29.797288955000113],
              [145.10936763500024, 29.787778955000192],
              [145.474944635000156, 29.3024724550001],
              [145.47588463500017, 29.301199955000016],
              [145.736314636000202, 28.767509954000104],
              [145.744536636000134, 28.736395954000045],
              [145.760447636000208, 28.703610954000055],
              [145.909093636000307, 28.138238954000087],
              [145.909158636000143, 28.137135953999973],
              [145.910845636000317, 28.130693954000037],
              [145.911977636000302, 28.111560954000026],
              [145.9397556360002, 28.005358953999973],
              [145.973929302666875, 27.425618286333417],
              [145.973766636000278, 27.424244953000141],
              [145.973831636000227, 27.422721286333399],
              [145.971567636000145, 27.406174953000232],
              [145.994403636000129, 27.019149953000181],
              [145.994294636000177, 27.018356953000136],
              [145.994347636000271, 27.017452953000088],
              [145.951149636000082, 26.702872952000121],
              [145.96337463600014, 26.497534952000194],
              [145.885334636000152, 25.921447952000051],
              [145.698690636000265, 25.366026951000151],
              [145.410369635000166, 24.847693951000224],
              [145.106073635000143, 24.47525295000014],
              [145.125937635000014, 24.180574950000107],
              [145.125781635000067, 24.179318950000095],
              [145.125864635000198, 24.178075950000107],
              [145.096111100000144, 23.893055560000107],
              [144.901388900000143, 23.742222220000087],
              [144.673055600000225, 23.564444440000159],
              [144.595777651000276, 23.503978440000139],
              [144.296388900000125, 23.269722220000062],
              [143.749166664500194, 22.83694441],
              [143.083888900000147, 22.303611110000247],
              [142.584166701000299, 21.899444439000035],
              [142.344166700000187, 21.703888890000087],
              [142.30138890000012, 21.668888890000176],
              [142.062500000000227, 21.472500000000139],
              [141.45916670000031, 20.97333333000006],
              [141.348333300000149, 20.8808333300002],
              [140.840897631000303, 20.928311947000111],
              [140.838018631000295, 20.929018946999975],
              [140.836993631000041, 20.929097947000116],
              [140.235685631000138, 21.075300947000017],
              [139.668916630000126, 21.314661947000047],
              [139.481831630000215, 21.43276494700018],
              [139.603867630000195, 20.968829947000074],
              [139.644525630000317, 20.389673946000073],
              [139.644426630000112, 20.388825946000111],
              [139.644486630000301, 20.387974946000213],
              [139.577512630000228, 19.810989946000177],
              [139.406069630000161, 19.253529945000196],
              [139.136271630000124, 18.732196945],
              [138.77690762900022, 18.26238294400008],
              [138.339134629000228, 17.857853944],
              [137.835884128000203, 17.530287444000123],
              [137.83415562800019, 17.529211944],
              [137.280757628000288, 17.288311943999986],
              [136.693047627000283, 17.140937943000097],
              [136.087967627000239, 17.091340943000148],
              [136.086987627000326, 17.091422943],
              [136.086008627000183, 17.091340943000148],
              [135.48092862600015, 17.14094194300003],
              [134.893219626000132, 17.288318944000196],
              [134.891444126000124, 17.289059444000202],
              [134.337850625000328, 17.530003943999986],
              [133.834850625000172, 17.857465944000069],
              [133.397081624000293, 18.261996944000089],
              [133.396127624000201, 18.263224444000059],
              [133.036700624000105, 18.733094945000232],
              [132.766900624000186, 19.254427945000089],
              [132.595456623000246, 19.811886946000016],
              [132.528496123000195, 20.389073446000168],
              [132.528463623000221, 20.389673946000073],
              [132.569121623000342, 20.968829947000074],
              [132.693950624000195, 21.44338094699998],
              [132.415773123000236, 21.325764947000152],
              [132.413945623000131, 21.325010947000123],
              [131.811602623000226, 21.17808194700018],
              [131.191672622000169, 21.12845494700008],
              [131.190198622000025, 21.128574947000061],
              [131.188741622000293, 21.12845494700008],
              [130.568763622000347, 21.177524947000109],
              [129.966280621000124, 21.323909947],
              [129.398427621000224, 21.563449948000027],
              [128.881538620000129, 21.889316948000101],
              [128.43074662000015, 22.292177948000017],
              [128.23347561900016, 22.541057948000173],
              [128.227996619000123, 22.534876948000147],
              [128.073984619000157, 22.38126894800024],
              [127.910279619000221, 22.236775948000059],
              [127.737521619000177, 22.101929948000191],
              [127.556373619000169, 21.977230948000113],
              [127.367526619000273, 21.863138948000028],
              [127.171692618000179, 21.760072948000072],
              [126.969604618000176, 21.668407948000038],
              [126.762015618000305, 21.588483948000089],
              [126.549690618000227, 21.520592947000068],
              [126.333411618000127, 21.464983947000235],
              [126.113971617000175, 21.421862947000022],
              [126.028942617000212, 21.410179947000245],
              [125.915964617000327, 21.330019947000153],
              [125.729483617000255, 21.213777947000153],
              [125.53720986400026, 21.109201391000113],
              [125.525075928000206, 21.116546850000248],
              [124.745787483000299, 21.554049003000102],
              [124.72959966000019, 21.563022217000253],
              [124.172272680000447, 21.871012556000096],
              [124.168431326000245, 21.873127209000188],
              [123.790108428000281, 22.080934406000097],
              [123.609937251000247, 22.17896031700019],
              [123.585420708000242, 22.201050767000083],
              [123.530918580000161, 22.24987834600006],
              [123.521648781000181, 22.258140235000042],
              [123.422551685000144, 22.346462449000057],
              [123.373233447000189, 22.390114640000093],
              [123.224417122000091, 22.527030533000129],
              [123.129913734000183, 22.659309463500108],
              [123.113334589000033, 22.682504164000136],
              [123.005113172000165, 22.83345732000015],
              [122.832888836000166, 23.075791852000179],
              [122.82125591400029, 23.115487821000045],
              [122.749825674000022, 23.292118354000195],
              [122.682416488000172, 23.379820183000163],
              [122.654908923000335, 23.419881493000162],
              [122.652484648000154, 23.423633782000024],
              [122.635313431000213, 23.449853064000138],
              [122.585221175000015, 23.53624394100018],
              [122.550704843000176, 23.594627834000164],
              [122.522594456000206, 23.649015599000109],
              [122.498255953000125, 23.697401350000121],
              [122.47994240100013, 23.741603642000086],
              [122.42056029000014, 23.886336468000195],
              [122.413092893000226, 23.927570783000192],
              [122.41004786200017, 23.944668826000111],
              [122.409449681000183, 23.949155698000084],
              [122.402802027000121, 23.998613588000069],
              [122.382001622000075, 24.150545904000239],
              [122.392998413000214, 24.413983732000247],
              [122.396162602000345, 24.443998283000042],
              [122.396276574000126, 24.44509918600005],
              [122.401274383000214, 24.493621700000119],
              [122.416187526000158, 24.577618974000245],
              [122.531616146000147, 24.814228339000067],
              [122.565646677000132, 24.860940060000132],
              [122.575746951000156, 24.875529499000152],
              [122.666591059000211, 25.008851976000187],
              [122.668825932000345, 25.012092216000042],
              [122.745906568000123, 25.123404420500037],
              [122.90009616500015, 25.229875222000146],
              [122.953630593000298, 25.211341990000221],
              [122.995512579000319, 25.197670870000081],
              [123.001513062000186, 25.19571479800004],
              [123.152272353000171, 25.147528709000085],
              [123.295245987000044, 25.102627116000235],
              [123.377583502000334, 25.078284718000191],
              [123.417142185000216, 25.064261821000116],
              [123.509994772000141, 25.077635757000053],
              [123.605502912000247, 25.090846007000039],
              [123.818495662000146, 25.119938191000188],
              [123.868293730000346, 25.12679114600013],
              [123.9274375680003, 25.157467882999981],
              [124.007805700000176, 25.19894126600002],
              [124.192435161000049, 25.293577955000217],
              [124.216998118000333, 25.289719262000062],
              [124.352307158000144, 25.301259861000233],
              [124.552523575000123, 25.318864357999985],
              [124.567475900000318, 25.320212021000202],
              [124.694001688000185, 25.331464144000108],
              [124.767068938000307, 25.338122441000053],
              [124.979508046000205, 25.458107648000066],
              [125.055365051000308, 25.501147619000051],
              [125.708129638000059, 25.866550105000044],
              [125.706513531000155, 25.872687223000128],
              [125.7008509985003, 25.893782306000162],
              [125.57605606500033, 26.358042324000223],
              [125.388441459000205, 27.046621260000222],
              [125.042386499000258, 27.92885971800024],
              [124.904928185000017, 28.271609413000022],
              [125.024977916000239, 28.341931639000052],
              [125.133020238000199, 28.488659590000168],
              [125.344124013000169, 28.64966424100021],
              [125.413722428000256, 28.703264888000206],
              [125.431288292000204, 28.716770702000019],
              [125.966239247000203, 29.651548278],
              [126.242080869000233, 30.126936284000124],
              [126.243144687000154, 30.1287591520001],
              [126.244094565000296, 30.130384663000029],
              [126.244728308000163, 30.131468956],
              [126.245706739000184, 30.133139870000207],
              [127.633333333000223, 28.6],
              [128.000000000000227, 29.31666666700005],
              [128.633333333000223, 29.71666666699997],
              [129.150000000000318, 30.316666667],
              [129.06666666700005, 30.9],
              [128.833333333000155, 31.783333333000201],
              [128.233333333000132, 31.783333333000201],
              [127.833333333000155, 32.200000000000102],
              [127.933333333000292, 32.450000000000216],
              [128.300000000000296, 32.450000000000216],
              [128.300000000000296, 32.950000000000102],
              [127.685000000000286, 32.950000000000102],
              [127.698333333000164, 32.958333333000098],
              [127.700222681000128, 32.96175215300002],
              [127.733333333000019, 33.02166666700009],
              [127.805000000000177, 33.145],
              [127.860000000000241, 33.228333333000023],
              [127.871666667000227, 33.27],
              [128.361666667000122, 33.751666667000165],
              [128.425000000000182, 33.790000000000134],
              [128.435000000000173, 33.840000000000146],
              [128.688333333000287, 34.136666667000156],
              [128.793333333000191, 34.21666666699997],
              [128.880000000000223, 34.3],
              [128.888333333000219, 34.308333333000178],
              [128.955000000000155, 34.408333333000144],
              [128.990000000000123, 34.46000000000015],
              [129.003333333000228, 34.486666667000236],
              [129.013333333000219, 34.535],
              [129.013333333000219, 34.543333333000135],
              [129.051666667000291, 34.671666667000181],
              [129.201666667000154, 34.828333333000046],
              [129.216666667000254, 34.843333333000146],
              [129.263333333000219, 34.873333333000119],
              [129.306666667000172, 34.9050000000002],
              [129.361666667000122, 34.95],
              [129.376666667000222, 34.96],
              [129.421666667000295, 34.976666667000131],
              [129.548333333000301, 35.02],
              [129.678333333000296, 35.068333333],
              [130.125000000000114, 35.113333333000071],
              [130.273333333000323, 35.116666667],
              [130.388333333000219, 35.303333333000012],
              [130.568333333000169, 35.561666667000054],
              [130.711666667000145, 35.705],
              [131.138333333000332, 36.063333333000173],
              [131.265000000000327, 36.166666667000072],
              [131.539364049000255, 36.138438033000114],
              [131.872586185000159, 36.340332561000082],
              [131.932058635000203, 36.376129312000103],
              [132.178406825000252, 36.52501567600018],
              [132.27104549500018, 36.58181456900013],
              [132.365570963000323, 36.640833881000049],
              [132.401910069000223, 36.663838624000164],
              [132.434102450000182, 36.685125301000085],
              [132.44001229100013, 36.690323947000195],
              [132.562637267000127, 36.797661931000107],
              [132.583200262000219, 36.817042960000094],
              [132.619932271000152, 36.851501294000116],
              [132.708985391000283, 36.934698611000101],
              [133.023013295000169, 37.246072351000066],
              [134.270552642000325, 38.448177228000077],
              [134.26707947000034, 38.586874152000121],
              [134.267029206000075, 38.588847496000142],
              [134.250115181000297, 39.117170353000233],
              [134.234522342000218, 39.539344014000108],
              [134.221631595000304, 39.878541295000161],
              [134.371778558000301, 39.949314843000025],
              [134.544693151000274, 40.028961147000246],
              [134.742584522000129, 40.120112022000285],
              [134.798156541000253, 40.139728558000172],
              [135.277507338000191, 40.308936060000292],
              [135.868849302000285, 40.513740302000315],
              [135.88935705300014, 40.520664472000135],
              [136.116102527000407, 41.029526174000182],
              [136.193610425000429, 41.16171228200011],
              [136.393461942000386, 41.472885590000146],
              [136.421534663000216, 41.51635034800023],
              [136.4968548960004, 41.629685995000159],
              [136.545370760000424, 41.687104872000077],
              [136.77841265300026, 41.962298587000305],
              [136.913846747000207, 42.201775455000131],
              [137.084319215000164, 42.503207773000156],
              [137.206615714000236, 42.670147323000037],
              [137.308271673000377, 42.808911722000062],
              [137.453377532000331, 42.997553560000199],
              [137.483993967000401, 43.035728326000253],
              [137.498443535000206, 43.053743409000219],
              [137.563765713000294, 43.13686876700018],
              [137.603801164000402, 43.187815647000264],
              [137.607152504000169, 43.191515707000292],
              [137.626787119000255, 43.21319338700016],
              [137.703080580000233, 43.272054370000205],
              [137.704020823000292, 43.272779774000185],
              [137.720784129000322, 43.285712791000094],
              [137.734529372000338, 43.295949562000033],
              [137.804202017000307, 43.347838269000135],
              [138.053052746000276, 43.599513585000352],
              [138.149950746000286, 43.697106186000326],
              [138.175223458000232, 43.743690187000254],
              [138.266325485000152, 43.871480579000263],
              [138.40189088600016, 44.05020701900014],
              [138.45513104000031, 44.108644567000056],
              [138.49786143100016, 44.152024248000089],
              [138.671608892000336, 44.295961538000142],
              [138.802778891000344, 44.398113009000156],
              [139.009737445000269, 44.549593732000233],
              [139.125362070000278, 44.619600813000204],
              [139.130837799000233, 44.663389466000126],
              [139.211680076000221, 45.088251442000171],
              [139.235846443000128, 45.215256403000296],
              [139.236968178000325, 45.220475269000246],
              [139.239754675000199, 45.233439426000302],
              [139.241936085000248, 45.23985900300022],
              [139.304009431000281, 45.397692242000062],
              [139.337194844000237, 45.464878105000025],
              [139.414242701000262, 45.621303252000075],
              [139.549088319000361, 45.873568215000319],
              [139.557041506500241, 45.888446764500088],
              [139.658895357000233, 46.012981197000272],
              [139.689295723000328, 46.048717540000098],
              [140.606722557000239, 45.920199340000238],
              [140.749177092000195, 45.899524837000172],
              [140.966463322000209, 45.866930890000276],
              [141.073275676000321, 45.85081369500017],
              [141.163740651000353, 45.835313519000181],
              [141.247930172000338, 45.821185518000163],
            ],
            [
              [136.341479627000126, 30.141420956000047],
              [135.671433626000294, 30.105644955],
              [135.67038762600032, 30.105744955000091],
              [135.668548626000188, 30.105641955000067],
              [135.529365626000185, 30.118536956000071],
              [135.361259626000106, 30.075485955000033],
              [135.103888626000298, 29.909772955000221],
              [135.095433626000272, 29.90608595499998],
              [135.08591462600009, 29.899932955000168],
              [134.768325626000319, 29.76058595500001],
              [134.674625625000203, 29.472812955],
              [134.671090625000261, 29.466425955000034],
              [134.658067625000285, 29.426153955000132],
              [134.62773062500014, 29.37127095500017],
              [134.594942625000158, 29.269547955000178],
              [134.592791625000132, 29.265646955000136],
              [134.587256625000123, 29.24845095500018],
              [134.586713625000129, 29.247464955],
              [134.585054625000225, 29.242310955000221],
              [134.298214625000128, 28.71866995400012],
              [133.961945625000226, 28.303125954000109],
              [134.224727625000128, 28.061438954000209],
              [134.225494625000124, 28.060421954000049],
              [134.226708625000242, 28.059299954000153],
              [134.589668625000172, 27.575833953000199],
              [134.849668626000039, 27.043440953000072],
              [135.000103626000168, 26.478821952000146],
              [135.037981626000146, 25.899421952000182],
              [135.037820626000212, 25.898184952000122],
              [135.037903626000116, 25.896923952000236],
              [134.963730626000142, 25.320374951000133],
              [134.824534626000172, 24.896428951000246],
              [134.856330626000272, 24.423995950000119],
              [134.85629312600031, 24.423394450000103],
              [134.784562626000138, 23.846484950000161],
              [134.665502625000158, 23.475912949000133],
              [134.841531626000233, 23.553199949000145],
              [135.452448626000205, 23.70686595],
              [136.083940627000288, 23.758658950000097],
              [136.085986627000125, 23.758494950000056],
              [136.088032627000075, 23.758658950000097],
              [136.719525627000166, 23.706869950000169],
              [137.330443628000125, 23.553207949000125],
              [137.90103662800027, 23.302686949000076],
              [137.903029128000071, 23.301378949000195],
              [137.982315629000141, 23.248858949],
              [137.905128628000284, 23.48879494900001],
              [137.871819628000139, 23.549983949000023],
              [137.690846628000145, 24.10621395000004],
              [137.689905628000247, 24.113616950000051],
              [137.689622628000279, 24.114482949999967],
              [137.615865628000279, 24.690962950000085],
              [137.61586662800039, 24.691568950000146],
              [137.617904628000247, 24.723941950000153],
              [137.616572628000199, 24.734276950000094],
              [137.616628628000171, 24.735147950000055],
              [137.616521628000214, 24.73597495000007],
              [137.616522628000098, 24.736580950000132],
              [137.616498628000272, 24.737379450000105],
              [137.632404628000245, 24.986046951000048],
              [137.58223362800004, 25.372445950999975],
              [137.582370628000149, 25.374601951000045],
              [137.582104628000025, 25.376641951000238],
              [137.601171628000202, 25.676443951000181],
              [137.39474062800025, 26.04419295200023],
              [137.206485628000337, 26.599529952000182],
              [137.127410628000348, 27.175591953000094],
              [137.127464628000325, 27.176496953000026],
              [137.127354628000148, 27.17728995300007],
              [137.161477628000284, 27.756818953000106],
              [137.211753628000224, 27.948814953000124],
              [137.071060628000282, 28.119217954000135],
              [136.778700627000148, 28.639587954000035],
              [136.5909946270001, 29.196392955000192],
              [136.515203627000233, 29.773224954999989],
              [136.515283627000258, 29.7743799550002],
              [136.515105627000139, 29.775722955000106],
              [136.520002627000281, 29.846261955000102],
              [136.512638627000229, 29.867736955000055],
              [136.474046627000121, 30.157695956],
              [136.469090627000242, 30.166241956000135],
              [136.370462627000165, 30.146302956000056],
              [136.363571627000169, 30.145954956000026],
              [136.341479627000126, 30.141420956000047],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "KAZ", Country: "Kazakhstan" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [69.185527108749056, 55.34388220748275],
            [69.232754104132596, 55.332500085852985],
            [69.35138234191578, 55.394436147884278],
            [69.466091201762993, 55.36471811009261],
            [69.672763286023269, 55.354436029438489],
            [69.846100205985238, 55.295000121493501],
            [69.880809164217794, 55.263327088599794],
            [69.949145144617262, 55.204164263060932],
            [70.048327199341088, 55.206935990810791],
            [70.169436145194879, 55.160545006452253],
            [70.238872334208565, 55.13888214527293],
            [70.263882256973318, 55.164436053555107],
            [70.288036219783294, 55.187209181632284],
            [70.421918180284138, 55.261382151781447],
            [70.469982192521172, 55.296664264554522],
            [70.517209187904768, 55.285273258107452],
            [70.551091187567408, 55.278045040064853],
            [70.587345098197375, 55.277491163901402],
            [70.619563290075121, 55.285273258107452],
            [70.649845094675698, 55.301173225561399],
            [70.674709172324924, 55.313045017976151],
            [70.802200266912337, 55.318054043327777],
            [70.840127205420856, 55.30374512873891],
            [70.809427143850428, 55.267700095136604],
            [70.808172372941556, 55.246800154786513],
            [70.830272266553777, 55.231654055706144],
            [70.993736142738044, 55.098673149799424],
            [71.001100315250369, 55.079436011405733],
            [71.000545097982467, 55.055545072718076],
            [70.996372251294019, 55.024164232969838],
            [70.988872291949576, 55.00471804991048],
            [70.976091230675934, 54.980818058767341],
            [70.969218237691052, 54.892009112166363],
            [71.013045197861061, 54.797491083658656],
            [71.10525417967574, 54.73610906542882],
            [71.227482277244633, 54.733882161386987],
            [71.278872228670991, 54.690273130700177],
            [71.285263262222088, 54.661373166660724],
            [71.289427224093231, 54.635827137367357],
            [71.26443624442976, 54.628600092791203],
            [71.229982263692705, 54.623045070265334],
            [71.198318283254537, 54.615545110921119],
            [71.165745201595428, 54.599573226737334],
            [71.172763369143695, 54.51193607020366],
            [71.195818129168288, 54.471373190777342],
            [71.221645119856817, 54.410273139771107],
            [71.213609221622107, 54.325827141423304],
            [71.124982329958698, 54.317500055871335],
            [71.086382324623344, 54.339154032233409],
            [71.062482333480204, 54.339718134318616],
            [70.99650921159224, 54.3322181749744],
            [70.997345222617412, 54.280545082857941],
            [71.075000202993408, 54.270827104289197],
            [71.079436241442522, 54.209991083233604],
            [71.066936141563986, 54.193600103890219],
            [71.114700249304633, 54.181382138873403],
            [71.185527163696861, 54.103327006438576],
            [71.257763243655063, 54.126100134515696],
            [71.279436163118248, 54.151927125204224],
            [71.336382310537147, 54.215000108585457],
            [71.484427172872756, 54.186382111769774],
            [71.49525407723479, 54.135827165539922],
            [71.501100287077975, 54.113327119869041],
            [71.653591246146334, 54.109991122395741],
            [71.716091242624998, 54.130818140188239],
            [71.749145110251447, 54.154435996469772],
            [71.735682264971615, 54.219436147034173],
            [71.767763328912963, 54.255273141798966],
            [71.889436209214182, 54.258045037186889],
            [71.958327239243772, 54.247773182454921],
            [72.035600172472073, 54.217491210215996],
            [72.122209204759145, 54.133045044230073],
            [72.193872297814607, 54.138254061792054],
            [72.21220921980489, 54.156100139530849],
            [72.203663366579462, 54.196173180534231],
            [72.169436200143082, 54.209164124664085],
            [72.148882264756651, 54.213053998300609],
            [72.095409242747451, 54.250591178310401],
            [72.049218250599097, 54.380064257728293],
            [72.168872265695825, 54.376653996592779],
            [72.196927166254625, 54.373882101205027],
            [72.344436089699798, 54.26360911216841],
            [72.360263302233989, 54.24415404429169],
            [72.343872322890775, 54.217764124984114],
            [72.321654076805316, 54.186100144545946],
            [72.366382259207285, 54.176382165977259],
            [72.508882324939009, 54.142773248720744],
            [72.486372388622272, 54.11360908709247],
            [72.436372324384195, 54.034436144047163],
            [72.403045206713443, 53.957218028465604],
            [72.394291314650502, 53.929436042674979],
            [72.413609254590995, 53.922764215366726],
            [72.448591295229448, 53.912764269574438],
            [72.482418141969163, 53.912209052306537],
            [72.519718281756354, 53.934436015571123],
            [72.535263359428882, 53.947209197855784],
            [72.550536192885659, 53.960273232181578],
            [72.689418126282305, 53.959300093220179],
            [72.727691237392889, 53.96450911078233],
            [72.717754155874559, 54.000827059152471],
            [72.702209245839981, 54.024164121676847],
            [72.664636191284671, 54.06290913393741],
            [72.598327122716455, 54.059718143389716],
            [72.564845107474525, 54.053882159468344],
            [72.532472353301728, 54.059418071255095],
            [72.546236108906612, 54.102773130275125],
            [72.566509250535546, 54.120409157519759],
            [72.607891209542402, 54.143464252820365],
            [72.729709264407006, 54.13360914631528],
            [72.865818128949456, 54.123045098437728],
            [72.975263321226066, 54.128327038557529],
            [72.99359119076064, 54.116100188723408],
            [72.999709141905441, 54.056245017980245],
            [73.069445235415742, 53.988882176541949],
            [73.290818172058152, 53.953882198630652],
            [73.391109152575154, 53.948600090872731],
            [73.494427173613246, 53.951382212182409],
            [73.545818130868099, 53.959436047690076],
            [73.544436122668685, 53.98777325074883],
            [73.541654168996843, 54.015273101677678],
            [73.611645240002417, 54.077491130932572],
            [73.680954191725164, 54.08638215093174],
            [73.763882227403769, 54.065545074855606],
            [73.737763378845841, 53.969982158294954],
            [73.711654085657415, 53.874991222808603],
            [73.65359130109448, 53.864154092524927],
            [73.508463341072002, 53.882491182152989],
            [73.443036216358394, 53.875827066195654],
            [73.356372366423955, 53.789718098253104],
            [73.3288723478571, 53.694154008226178],
            [73.260272337506649, 53.683327103864201],
            [73.238591203778128, 53.644436078849878],
            [73.25387225150007, 53.579991145553038],
            [73.275545170963255, 53.57360916445765],
            [73.381654198128558, 53.539164236176077],
            [73.410127188019032, 53.521245068241342],
            [73.370254139225835, 53.466936035206913],
            [73.43719117998171, 53.436100186804765],
            [73.581663339898114, 53.519718053116719],
            [73.598872225355535, 53.566518074351222],
            [73.676927190152412, 53.619436046730925],
            [73.775545142790804, 53.617491109912578],
            [73.917482279903595, 53.654164115702599],
            [74.08137229204641, 53.632209061377637],
            [74.246645150578956, 53.602218108817965],
            [74.266936229480848, 53.566100152657896],
            [74.283872367808328, 53.527491094867059],
            [74.376372369301976, 53.471654040879628],
            [74.429154219573377, 53.478600124060449],
            [74.483600212906254, 53.584436068819628],
            [74.438872365780298, 53.609991150568362],
            [74.466382274993293, 53.693318164839184],
            [74.60860020586324, 53.68888212639024],
            [74.646945233703093, 53.688327076760345],
            [74.674145180135724, 53.759854047707904],
            [74.789427194523626, 53.836382165018108],
            [74.81219127014549, 53.844436000525604],
            [74.947209146167438, 53.838600184242409],
            [75.033872325549567, 53.806654068942635],
            [75.190536131306544, 53.88332702553987],
            [75.369427235604462, 53.970827054137317],
            [75.457218283881048, 53.994154058377944],
            [75.441363243427872, 54.010827004944929],
            [75.384154071886655, 54.067218102734103],
            [75.501100229335975, 54.115273230153718],
            [75.573609224062125, 54.097218107749086],
            [75.618045213318794, 54.0986001159485],
            [75.691363396979625, 54.110273257257518],
            [75.88693616502394, 54.175000157777845],
            [76.207218241622058, 54.259164188901991],
            [76.185527217247909, 54.2955450015466],
            [76.238872331414598, 54.35889106733373],
            [76.353591249545786, 54.340827060111792],
            [76.487763391535054, 54.325554059017009],
            [76.659427115980691, 54.370127008571785],
            [76.669709196634813, 54.397209105445484],
            [76.673591191282298, 54.423745037506592],
            [76.699700316832605, 54.444291093903644],
            [76.811645160280932, 54.44777310413059],
            [76.865745148649694, 54.340273183948341],
            [76.859082206158803, 54.30118216672372],
            [76.80247234069634, 54.227482103553029],
            [76.783600318006165, 54.207773231647309],
            [76.724154184139138, 54.155264128505792],
            [76.699418181970827, 54.14582711133221],
            [76.659154201212573, 54.138045017126217],
            [76.636791283477919, 54.137354013026595],
            [76.614009270583381, 54.142353985922739],
            [76.563872246047254, 54.164709192306418],
            [76.542482299635992, 54.168882206633043],
            [76.521382199437369, 54.17082714345122],
            [76.48081814654455, 54.172764201280359],
            [76.443591264591305, 54.171664160304715],
            [76.402209305584279, 54.156554103407984],
            [76.426091191816397, 54.130545057782001],
            [76.448309270264019, 54.109991122395741],
            [76.471645159321781, 54.084164131707269],
            [76.50602722332934, 54.037836011622517],
            [76.522418202672611, 53.99554511903267],
            [76.542754208575843, 53.973318155768084],
            [76.781936174944917, 53.851936127507997],
            [76.914427242428644, 53.799436076822019],
            [77.035809270688731, 53.748045119567109],
            [77.270263340739632, 53.625827080282022],
            [77.405254227033254, 53.561664114208781],
            [77.523882297178488, 53.506382109851074],
            [77.546936219012764, 53.493882177610544],
            [77.754163352902765, 53.373318055464665],
            [77.80009132092863, 53.345945106550005],
            [77.864991223931099, 53.306100053313628],
            [77.890272385083222, 53.286664263814203],
            [77.914700268489923, 53.265000061530202],
            [77.934982294936333, 53.244991117490201],
            [77.982754114027841, 53.194709086028524],
            [78.046372256393113, 53.126100190860711],
            [78.061918172256014, 53.094436042784366],
            [78.073045148752499, 53.075273168053286],
            [78.296100165728944, 52.852491065844731],
            [78.410809193214334, 52.7466541152574],
            [78.46804518684587, 52.686654105226978],
            [78.629427165913569, 52.509164119867933],
            [78.750827131446499, 52.372491153240105],
            [78.923036182514181, 52.176382111029227],
            [79.247209306215325, 51.782773133590752],
            [79.336927186399294, 51.669154088797256],
            [79.36609118038939, 51.646100166962924],
            [79.494136151140196, 51.499436139360284],
            [79.541509159277609, 51.441664206838141],
            [79.680818234461242, 51.232764212707934],
            [79.736645230165209, 51.160818146600377],
            [79.751100325145785, 51.143609093504779],
            [79.960536258166542, 50.873600163550861],
            [79.973309272813253, 50.844709084328883],
            [79.988591326363689, 50.819573097739976],
            [80.016700206378914, 50.796282135683214],
            [80.035718241823133, 50.783364114111293],
            [80.059054130880895, 50.768364195422691],
            [80.081700189305479, 50.757664193075072],
            [80.103009166531763, 50.764473148319667],
            [80.089336103119649, 50.784764227221558],
            [80.067127244765857, 50.799473126231135],
            [80.091854194478685, 50.849482242924523],
            [80.284054084675745, 50.923054062976348],
            [80.344036157433152, 50.921373156108814],
            [80.377791255081547, 50.92273605356894],
            [80.404309249869641, 50.932718062088441],
            [80.427918221333869, 50.944645007426288],
            [80.464782334516713, 50.966573239660931],
            [80.482336219109925, 50.995591220897438],
            [80.487900294091446, 51.021282089478063],
            [80.487345244461551, 51.05100012726956],
            [80.476382217991443, 51.083573208928726],
            [80.436372376538372, 51.111382184447677],
            [80.443927153529387, 51.199145069529436],
            [80.488709147750001, 51.217109164465114],
            [80.583163306155484, 51.212309183779496],
            [80.608982250216968, 51.212554102990794],
            [80.638218160936503, 51.221145050855569],
            [80.635654136747974, 51.251782080513806],
            [80.658400275096824, 51.288082091611273],
            [80.685054224354872, 51.312636038842072],
            [80.705336250801452, 51.310245017135628],
            [80.759091240034309, 51.29535406318854],
            [80.819763311063411, 51.276009133519892],
            [80.872254141655731, 51.259036114818116],
            [80.942645197081617, 51.219264151777622],
            [81.040836175571201, 51.181436119727039],
            [81.094709182001338, 51.181753961496327],
            [81.134282326297921, 51.203191181841476],
            [81.179427256926942, 51.193118145853248],
            [81.17803619627216, 51.164436111297107],
            [81.165400309561846, 51.130691071932688],
            [81.153318131376807, 51.11277324510263],
            [81.137082217242437, 51.094854077167895],
            [81.108872251474452, 51.050282133441613],
            [81.095263393440746, 51.024327067272054],
            [81.081663252586338, 50.987527159467845],
            [81.074854129703738, 50.951418088125081],
            [81.1885911916944, 50.944991180028524],
            [81.228045145327428, 50.952218056966444],
            [81.250000199652391, 50.958600205700236],
            [81.270827217444889, 50.965000124068638],
            [81.297209257763086, 50.9697090772857],
            [81.327772359035293, 50.969435994879404],
            [81.420118133510016, 50.966664267129659],
            [81.453327233984055, 50.877773177877486],
            [81.468045185448887, 50.816100139968682],
            [81.439218143967366, 50.768745069104057],
            [81.463882229406067, 50.743191160821652],
            [81.53803625645412, 50.737773098594005],
            [81.582218274043839, 50.736109123170934],
            [81.595400325566743, 50.735727076023068],
            [81.638318184515583, 50.737500183825716],
            [81.67859121772949, 50.741100211249602],
            [81.731091268415469, 50.751800213597051],
            [81.815809175703066, 50.771936059651921],
            [81.915545106590287, 50.786945030795891],
            [82.014854230966534, 50.769853994897232],
            [82.071927280399848, 50.735545021085557],
            [82.101509196083441, 50.720127013065394],
            [82.12360020487813, 50.714436036069628],
            [82.159154226590999, 50.70999111280338],
            [82.193036226253639, 50.710273247664986],
            [82.215818239148291, 50.713882159906177],
            [82.246718292928904, 50.726654001086388],
            [82.280191255715522, 50.753327061083894],
            [82.356372362233174, 50.759718094634991],
            [82.447545173174319, 50.754991204145142],
            [82.480809258933277, 50.731800154374241],
            [82.498872260326721, 50.721100152026963],
            [82.519991303626455, 50.727209050716112],
            [82.546645085246666, 50.737209164146805],
            [82.672209180288689, 50.78582705254712],
            [82.702772281560726, 50.800273095072328],
            [82.721509187971662, 50.81388212074404],
            [82.723109293292282, 50.846518066677163],
            [82.725327144878747, 50.890127097364143],
            [82.761109154358451, 50.910818160686574],
            [82.789982296307585, 50.918054090080204],
            [82.838591299890936, 50.905264143989029],
            [82.864991277481977, 50.896945105064276],
            [82.92276337764207, 50.892218046936023],
            [83.014854174621519, 50.891454120278638],
            [83.056645170504737, 50.90707312633748],
            [83.093245086098477, 50.931727153492375],
            [83.103327174542414, 50.952491139372739],
            [83.138309215180698, 50.999509090090442],
            [83.250272331178337, 51.002773170834246],
            [83.468318155165207, 50.989300099632487],
            [83.806645128245805, 50.886518017484775],
            [83.839572261496187, 50.879154012610229],
            [83.977827228533613, 50.795064245148467],
            [83.978318240422936, 50.771382183488527],
            [83.966382242629948, 50.730618138385353],
            [83.974572367883468, 50.711518127927832],
            [84.0013912729965, 50.692491207666649],
            [84.041091319307668, 50.671382222650493],
            [84.106218204248478, 50.642964218045165],
            [84.126082309001561, 50.631382104205045],
            [84.146945201339577, 50.615827135886718],
            [84.16900922040611, 50.598045095888381],
            [84.22415409682759, 50.506935987411509],
            [84.207000196654917, 50.474227118920453],
            [84.198527265987423, 50.4495090540251],
            [84.26540915382003, 50.272773104677867],
            [84.377200273164021, 50.212700172089683],
            [84.521054183900162, 50.216782158947524],
            [84.69552718702198, 50.177218067106253],
            [84.810527234186281, 50.144153973557707],
            [84.982754222526864, 50.088327145492087],
            [85.011518232096421, 50.077636027961944],
            [85.05581826688308, 50.012700082775666],
            [84.999709303955626, 50.003054020936418],
            [84.979154195102922, 49.997773254283118],
            [84.974291182504999, 49.927773130822231],
            [84.989082224166168, 49.905336117063484],
            [85.051782212854846, 49.892354057751106],
            [85.080691229349441, 49.88332708328204],
            [85.197482321589746, 49.7333270582061],
            [85.215263355759589, 49.699645050753816],
            [85.212145120131481, 49.649991159117747],
            [85.259227276227989, 49.592909057229122],
            [85.295682352534641, 49.583045065906731],
            [85.324291296895012, 49.595544998147091],
            [85.355545234628494, 49.610273175533962],
            [85.420191333601963, 49.620818112672168],
            [85.465263341673221, 49.597627062901665],
            [85.50416325150502, 49.590273116311153],
            [85.528182265673536, 49.590964120410945],
            [85.553872296063957, 49.595127076453593],
            [85.657763304004675, 49.608327065249284],
            [85.746372258395468, 49.579718120888913],
            [85.806636130738298, 49.564154100115218],
            [85.959154079535153, 49.538327109426575],
            [86.184072393592714, 49.476654071517771],
            [86.229427207078061, 49.488882094818507],
            [86.2499912007479, 49.507773228248027],
            [86.276382293521806, 49.564436067338647],
            [86.287491332745617, 49.585818134761098],
            [86.338172342799339, 49.616582234071757],
            [86.373918142457569, 49.620618120461984],
            [86.413363378911242, 49.631000113402195],
            [86.445254173649914, 49.645827029608654],
            [86.506863341456437, 49.688536011530175],
            [86.533054106743805, 49.727482189467423],
            [86.623454106210033, 49.799018045232287],
            [86.661927209530802, 49.802491170641986],
            [86.78193611440895, 49.784091216739569],
            [86.794572336395618, 49.69818224100743],
            [86.747754210249866, 49.673882097805574],
            [86.694700283400437, 49.672909126482182],
            [86.640272394978638, 49.633045130144637],
            [86.615536225172264, 49.60596420673761],
            [86.620818165292235, 49.583045065906731],
            [86.649645206773585, 49.560273111295828],
            [86.683318161770558, 49.551936135098288],
            [86.714427260216951, 49.553745117446738],
            [86.743600306662358, 49.564164158399024],
            [86.849918210855549, 49.513264213033437],
            [86.841018138400898, 49.482627015737194],
            [86.839009163842263, 49.454718127931926],
            [86.926654199364975, 49.353054024033213],
            [87.040263353512671, 49.271936144169331],
            [87.11220019952674, 49.250545024291625],
            [87.168591297315572, 49.250000200583713],
            [87.210263270173243, 49.247073239986776],
            [87.243318143628358, 49.238327059274809],
            [87.265545106892944, 49.228044978620744],
            [87.305327128217414, 49.201664111768821],
            [87.313872310890702, 49.167218177658981],
            [87.324991240760141, 49.128327152644488],
            [87.340272288481742, 49.101109101301063],
            [87.3482092802588, 49.092618065722434],
            [87.316372297338603, 49.097773103827933],
            [87.20138230845788, 49.123045044886567],
            [87.175400252560223, 49.132218032109336],
            [87.120818137119443, 49.131100053860678],
            [86.876018283950316, 49.110200113510587],
            [86.770827242823316, 49.02360918613391],
            [86.731027284226229, 48.97200013175808],
            [86.734418266984278, 48.941373160383421],
            [86.746372369688459, 48.910891195934312],
            [86.768736125613259, 48.885691171605004],
            [86.807754220280117, 48.823882179226359],
            [86.778882251797427, 48.721100097078647],
            [86.758745232276283, 48.701382172717615],
            [86.709991221767893, 48.668600046392612],
            [86.654436135004062, 48.623882089912627],
            [86.638045155660507, 48.608044986732466],
            [86.605127242503784, 48.570273113433302],
            [86.597909250382941, 48.542773094866106],
            [86.468045238999707, 48.494436000222777],
            [86.437763266761181, 48.493882124059496],
            [86.414991312150278, 48.49749120393875],
            [86.382345140295399, 48.499582153510971],
            [86.344145119380755, 48.495273184714279],
            [86.322482258201376, 48.487773225370177],
            [86.307482339512887, 48.474300154168191],
            [86.297072351015913, 48.455827110070231],
            [86.234909307046166, 48.426591199350696],
            [86.18610031125263, 48.426654063624653],
            [86.161654155297072, 48.428600173909501],
            [86.138882200686567, 48.431936003744454],
            [86.098036180569977, 48.434718125054303],
            [86.030536211195283, 48.437073104576996],
            [85.915263416900871, 48.428882141132931],
            [85.858872319111697, 48.423327118606892],
            [85.838882318172864, 48.42082713215882],
            [85.803591320582314, 48.413882222444443],
            [85.783054149002396, 48.405818161014849],
            [85.765963280741857, 48.393327113591738],
            [85.739154098636675, 48.35638219886215],
            [85.618182280719054, 48.165127116793713],
            [85.603736238193846, 48.124718129110079],
            [85.605818135310415, 48.091936002785133],
            [85.601382264499478, 48.070827017769147],
            [85.579709177398286, 48.016391082720304],
            [85.569609151681561, 47.996382138680019],
            [85.544845153956544, 47.96991812334835],
            [85.537072279844381, 47.937218139674769],
            [85.547200301117584, 47.890273111514659],
            [85.556363397694554, 47.861100065069081],
            [85.585682289255658, 47.764164192764554],
            [85.622209282291976, 47.6211001925858],
            [85.623309155629613, 47.597500105939048],
            [85.612491303723118, 47.561936025942373],
            [85.60387236030175, 47.522218042358588],
            [85.609145247966183, 47.499436029464107],
            [85.621645180206599, 47.473327071551978],
            [85.639436105022241, 47.452773136165547],
            [85.682209292322227, 47.409154047194932],
            [85.702900188006652, 47.375964225098414],
            [85.701382225337284, 47.262218110652441],
            [85.689491322183301, 47.217973228788935],
            [85.565545102096678, 47.136800196002127],
            [85.522563373045216, 47.059109173442152],
            [85.380536214291965, 47.051100097297478],
            [85.298600260676039, 47.050827014891354],
            [85.266927227782389, 47.052627112422272],
            [85.218982238570732, 47.039000149477772],
            [85.205263409967074, 47.017209045179357],
            [85.19192713142553, 47.000136114191776],
            [85.168591242367597, 46.981935984861764],
            [85.098036236914936, 46.948327067605419],
            [85.097763322146818, 46.944991070132289],
            [85.082345146488706, 46.93180013379191],
            [85.064018282782399, 46.922773159323015],
            [85.023318275419683, 46.917636058490473],
            [84.984563372513264, 46.896445098461129],
            [84.97665420865485, 46.875544990472804],
            [84.962836138317471, 46.86450904144516],
            [84.803172281767274, 46.827700081185412],
            [84.744418157828591, 46.833154017958748],
            [84.760272360091108, 46.866664196395618],
            [84.773882223953137, 46.889435983368173],
            [84.781927174643442, 46.914436015487297],
            [84.77429126082933, 46.935682128439623],
            [84.71831825237183, 46.978673245222581],
            [84.678036166702611, 46.993609126170782],
            [84.652354182939291, 46.994436084740357],
            [84.594436237663444, 46.990827004860932],
            [84.319445104448391, 47.006664108041093],
            [84.29470926991803, 47.007500119066151],
            [84.223600220664139, 47.003882154369421],
            [84.17949129327053, 46.998673136807383],
            [84.143182229717411, 46.980409137375091],
            [84.111100327585746, 46.97165407184599],
            [84.085818160605186, 46.97165407184599],
            [83.930263280641327, 46.97331804726889],
            [83.899991199048259, 46.977209094371915],
            [83.853736169159617, 46.991100087267228],
            [83.806091252082354, 47.011935989877088],
            [83.781100272418712, 47.020827009876029],
            [83.76027224879806, 47.025554068004112],
            [83.738591282707716, 47.029436062651769],
            [83.693591191365954, 47.036945074451523],
            [83.612063268797783, 47.048745117774786],
            [83.558727207086577, 47.063327114769891],
            [83.460127191720971, 47.110691070451878],
            [83.44317227793033, 47.124154083369888],
            [83.424982206884465, 47.135691102570831],
            [83.354982251061585, 47.160273212996287],
            [83.328327128337037, 47.167773172340503],
            [83.299145197074154, 47.172491178013047],
            [83.25652724262136, 47.176382225116072],
            [83.230127265030148, 47.184154093400252],
            [83.184709251994889, 47.21166417025114],
            [83.185718265501919, 47.216636147590634],
            [83.155127168673118, 47.224709093837589],
            [83.121372238662843, 47.224709093837589],
            [83.061936163079849, 47.219991088165045],
            [83.034427259695292, 47.200618162939463],
            [83.038382344538775, 47.164573129337271],
            [83.031663410934414, 47.084436099786046],
            [83.018872291376908, 46.975545118948844],
            [83.011236209924789, 46.95318220121419],
            [82.997900266659201, 46.930400188319652],
            [82.971372381225251, 46.893318145653836],
            [82.95283613757698, 46.868045031128588],
            [82.929291203853211, 46.842618024860769],
            [82.898736149208389, 46.805400195362893],
            [82.873872239197169, 46.763600147024306],
            [82.842482346993734, 46.698864194048554],
            [82.815809286996227, 46.641927099085137],
            [82.807482201444316, 46.622753998432017],
            [82.797482255651971, 46.58692706195103],
            [82.757509294572685, 46.474064232464229],
            [82.708882353716717, 46.35415406640584],
            [82.692618276387691, 46.326791175775043],
            [82.671918160609778, 46.301654015719691],
            [82.65554511853918, 46.286100053229632],
            [82.633736244606098, 46.264154051360322],
            [82.607209197362124, 46.23011815995477],
            [82.571382260881137, 46.16776417623015],
            [82.562482356064834, 46.137209121585158],
            [82.560118156448482, 46.108736131694684],
            [82.558872270357142, 46.074991092330265],
            [82.534145153006136, 45.983873099036089],
            [82.520263380204256, 45.932764109005007],
            [82.510818148765679, 45.901927087136244],
            [82.493863402613215, 45.863600164207128],
            [82.480818143750582, 45.840818151312817],
            [82.46442716440697, 45.819436083890594],
            [82.445818165839114, 45.797553952123394],
            [82.413936255917804, 45.761927007852762],
            [82.387072256165425, 45.715118101800726],
            [82.321791311843526, 45.583100108933479],
            [82.314927203676007, 45.544791123277378],
            [82.319718131906484, 45.529418042258385],
            [82.335127255109171, 45.510536128922183],
            [82.353591246751819, 45.499418037243231],
            [82.409845216604594, 45.484564131308332],
            [82.485809231829421, 45.477473208840081],
            [82.52554515268605, 45.475809065779231],
            [82.561654224028644, 45.469700167089741],
            [82.589154074957719, 45.463864183168369],
            [82.610545194835424, 45.455945128664325],
            [82.646236176846514, 45.435809114971619],
            [82.65554511853918, 45.41025403322277],
            [82.659845202518227, 45.385391129039988],
            [82.658600322255154, 45.345527132702443],
            [82.652482203472175, 45.308027000704953],
            [82.64249131013537, 45.266091165534476],
            [82.632345183951031, 45.233036124441526],
            [82.603727187135348, 45.179282141036822],
            [82.58665408850959, 45.152618133494798],
            [82.566372397339364, 45.133027111148181],
            [82.54525419222972, 45.122335993618094],
            [82.514991330730311, 45.119418085476468],
            [82.481654154775583, 45.127473094450636],
            [82.459163329198191, 45.138873153353188],
            [82.348172346333627, 45.219836135645835],
            [82.321936151130956, 45.234009095764804],
            [82.296936119012003, 45.237753962475949],
            [82.270827161099874, 45.235809193295722],
            [82.224427124285683, 45.227754016683605],
            [82.168318161358457, 45.212754097994946],
            [82.123027217975391, 45.198582143704527],
            [82.071927280399848, 45.181364205791567],
            [82.043454122871367, 45.168864105912974],
            [82.013045248618255, 45.15941803628391],
            [81.977200207226247, 45.153864187224372],
            [81.948027160780669, 45.157482151921045],
            [81.824154198527992, 45.196226996543658],
            [81.807618212259229, 45.219518126238427],
            [81.811509259362197, 45.272754108025367],
            [81.797763273392093, 45.305809149118488],
            [81.783182282225482, 45.321373169892354],
            [81.751727178814974, 45.345118095826251],
            [81.71388221531987, 45.35191816625337],
            [81.679282221829112, 45.349700147029068],
            [81.646382245945233, 45.341918052823075],
            [81.605672347936661, 45.319982109237401],
            [81.582763265389758, 45.307200209773384],
            [81.556363287798547, 45.295818088143506],
            [81.531372308134905, 45.289700136998704],
            [81.486509177091534, 45.280945071469432],
            [81.451509199180066, 45.278173176081509],
            [81.396663389064884, 45.270391081875687],
            [81.23929131248741, 45.234554087110894],
            [81.147763276488774, 45.206082103048743],
            [81.003872317740445, 45.168309056283078],
            [80.916927171134716, 45.150254101516623],
            [80.76582727272114, 45.151918076939694],
            [80.743600309456383, 45.149554044961519],
            [80.524991221574624, 45.108591181114264],
            [80.426027263972173, 45.060045041805267],
            [80.400691285172968, 45.050400153432349],
            [80.261382209989279, 45.036091071205476],
            [80.192763256537319, 45.03831814288543],
            [80.16303616629051, 45.041091211739797],
            [80.13970916204994, 45.041927055126678],
            [80.113172391798457, 45.037336119106726],
            [80.076663336035381, 45.024700064758235],
            [79.871054077100609, 44.90281813979135],
            [79.874072232804508, 44.868236083573436],
            [79.998745163898718, 44.799009106863764],
            [80.01860926865163, 44.79400913396762],
            [80.053318226883789, 44.795535981454464],
            [80.151091283679818, 44.808873098186211],
            [80.229572384435414, 44.82387318451282],
            [80.262209168558854, 44.824427060676271],
            [80.281936145375369, 44.81942708778007],
            [80.436372376538202, 44.765536144077146],
            [80.515891324547795, 44.734082214133139],
            [80.512554153608221, 44.706154047950633],
            [80.384991309929148, 44.635800208174828],
            [80.349436114750006, 44.447191125308336],
            [80.356645222053089, 44.42413603000773],
            [80.37830925669914, 44.357744986426269],
            [80.399991228617807, 44.261218150997706],
            [80.396100181514782, 44.231636067676106],
            [80.387072368855399, 44.198582200049486],
            [80.366372253077486, 44.1552450783023],
            [80.368872239525558, 44.113782150110467],
            [80.435809280281319, 44.033864223508715],
            [80.500827200480671, 43.915800087810737],
            [80.508882209454839, 43.894553974858411],
            [80.517763338808209, 43.864973232641262],
            [80.529436144841043, 43.841354035255279],
            [80.54331825291905, 43.820527017462894],
            [80.553709298314743, 43.813227050329033],
            [80.583882305811699, 43.736354101521272],
            [80.619136255390003, 43.665791217079573],
            [80.681654189141454, 43.553291156363173],
            [80.739700209898047, 43.465800180221379],
            [80.777772322969781, 43.271073146209801],
            [80.815263402511846, 43.168300116517742],
            [80.815954071335455, 43.148018090071162],
            [80.803600319486577, 43.124818155483183],
            [80.774563395149073, 43.109273245448605],
            [80.743591257000844, 43.107745056857425],
            [80.71234519825623, 43.121909132159089],
            [80.678036224444554, 43.141909191381842],
            [80.654845174674051, 43.142044978213619],
            [80.615263313197943, 43.132182160357672],
            [80.384045160696246, 43.029054073245902],
            [80.388045172540842, 42.994827074447699],
            [80.442272230561855, 42.956909188394661],
            [80.49012719813328, 42.939400063164442],
            [80.521654218273056, 42.934964192353505],
            [80.550754174522865, 42.930027083731318],
            [80.574500273923263, 42.912182179458966],
            [80.572254091504021, 42.885453966538535],
            [80.36900919982395, 42.824964118085177],
            [80.340818177157132, 42.82219121686893],
            [80.312482315202885, 42.827809103668983],
            [80.28179130608811, 42.829818078227618],
            [80.257954179218927, 42.815654002926181],
            [80.242482191742113, 42.789127123320384],
            [80.171918133834197, 42.660509165666838],
            [80.158036193394366, 42.632454097469861],
            [80.238309177415488, 42.363291069186801],
            [80.253600283421292, 42.334682124826429],
            [80.270518149199802, 42.317836008139409],
            [80.293872310806478, 42.252182236763304],
            [80.285127135923233, 42.216064112965228],
            [80.234027198347462, 42.196218113123166],
            [80.214427123545363, 42.200791111870444],
            [80.192200160280606, 42.209118029784406],
            [80.172763364952687, 42.223700194417518],
            [80.155545091763599, 42.248564104428397],
            [80.115536256138682, 42.301482076808099],
            [79.956518141411152, 42.43272709056231],
            [79.919436098745109, 42.441336143337878],
            [79.713045143518343, 42.474400069248304],
            [79.683309168454002, 42.47015396472591],
            [79.611927204431652, 42.457736007498568],
            [79.578872330976765, 42.453427038702216],
            [79.541654166202818, 42.454954053826896],
            [79.510272320626257, 42.458845100930091],
            [79.469709273561932, 42.465509049249135],
            [79.443863339772122, 42.47217316520647],
            [79.42380930109266, 42.490645035837986],
            [79.406372260230114, 42.526073161364934],
            [79.388045228885687, 42.56634502111217],
            [79.360463402943537, 42.602945104344201],
            [79.324582319367892, 42.6144001485319],
            [79.296654153185386, 42.617318056673355],
            [79.264709211351885, 42.632591057768252],
            [79.233045230913717, 42.65884502260576],
            [79.215272243370691, 42.679118164234694],
            [79.19970922842549, 42.711345073291795],
            [79.19790024607704, 42.735091172692194],
            [79.202218267328931, 42.758153979343831],
            [79.20581812711481, 42.783709061092679],
            [79.175391315588683, 42.797600053987992],
            [79.13860029260178, 42.792736035561745],
            [79.109845167849699, 42.786627136872255],
            [79.05706331757824, 42.765091177707291],
            [79.031382339643301, 42.759964135158782],
            [78.670254075290529, 42.827182137309819],
            [78.536927164419808, 42.879400053134191],
            [78.516372390843401, 42.884682160891941],
            [78.452200204676728, 42.88746411456367],
            [78.180545236572527, 42.870791167996629],
            [78.152482289386569, 42.868845057711951],
            [78.120254206863365, 42.86218211522106],
            [78.097763381285972, 42.857318096794813],
            [77.992900240212009, 42.869809144217868],
            [77.965545228570193, 42.886073221546894],
            [77.944572365662168, 42.895091143560478],
            [77.722209190975605, 42.91495407484706],
            [77.668872291074251, 42.915227157253184],
            [77.535536160110041, 42.921336055942447],
            [77.407209222135407, 42.920791064596528],
            [77.26832712110064, 42.916344967863665],
            [77.208191324238271, 42.918153950212115],
            [77.15803619479135, 42.958845072757526],
            [77.138600237653748, 42.967591085831259],
            [76.980954072841882, 42.995791160953615],
            [76.848727202946918, 42.978353952452835],
            [76.819154172080687, 42.95245403920643],
            [76.733736208237929, 42.91870916747996],
            [76.616927178725007, 42.900227070926519],
            [76.424145081531947, 42.899400112357],
            [76.377472297587587, 42.910236069174516],
            [76.324909214989475, 42.923982222782456],
            [76.240263392069323, 42.93690007671637],
            [76.115536146242675, 42.941891164795152],
            [75.903172246796316, 42.946754009754954],
            [75.844709142536374, 42.945226994630275],
            [75.796372383169341, 42.938564219777561],
            [75.790600269350421, 42.935691070998928],
            [75.785536258713819, 42.919118036718032],
            [75.769854220742843, 42.885227152238031],
            [75.74915410496493, 42.852873173528565],
            [75.671354117663384, 42.807218120270647],
            [75.622063330074241, 42.817873195617096],
            [75.591936255406381, 42.828427017572835],
            [75.562345119629242, 42.834673211836503],
            [75.317763363581491, 42.853291095221891],
            [75.243318149216179, 42.855236032040239],
            [75.183872350625336, 42.868845057711951],
            [75.066936251459992, 42.915509124476785],
            [74.893327254919996, 42.979127099203936],
            [74.86470020564883, 42.986491104078254],
            [74.82776333754623, 42.992736124875648],
            [74.780818141748114, 42.990654060121074],
            [74.745472326510765, 42.996700094536436],
            [74.668591331076129, 43.044127082130331],
            [74.614009215635406, 43.088436001734465],
            [74.575482300495992, 43.126418093165739],
            [74.391663307669546, 43.195509115405684],
            [74.34610028770885, 43.205518113653511],
            [74.29359118456739, 43.21690006764544],
            [74.23442718556214, 43.209827082450033],
            [74.059418243549771, 43.188009156061469],
            [73.938582212464041, 43.148300057294762],
            [73.915191170483013, 43.125100122706954],
            [73.78970016563693, 43.104409059384352],
            [73.713045146312311, 43.084964217429444],
            [73.650545149833818, 43.067745106049813],
            [73.586382183760804, 43.040245087482788],
            [73.539982314584734, 42.93024501321463],
            [73.529854125673353, 42.831418015910231],
            [73.524291224158503, 42.797045004358267],
            [73.49484509530663, 42.748573128711485],
            [73.463591325211098, 42.714127026963467],
            [73.434145196359225, 42.62745395693571],
            [73.433591320195831, 42.584744975014189],
            [73.442472281911193, 42.551900152053406],
            [73.451100277788044, 42.53329115348555],
            [73.473309303779786, 42.503845024633677],
            [73.51331813940476, 42.453845128033777],
            [73.522518283993861, 42.44480004865386],
            [73.532827186738075, 42.430300194310178],
            [73.520545183980857, 42.409254073567979],
            [73.346509213291881, 42.429327055348779],
            [73.335263381408026, 42.453418153884741],
            [73.335682308930018, 42.503045055792313],
            [73.152627242760957, 42.560364197903596],
            [73.019991168352021, 42.55050909139851],
            [72.960127112791326, 42.539264265342922],
            [72.908600201066747, 42.54830012462952],
            [72.883882303809401, 42.55412705609524],
            [72.86442723593251, 42.561345048215856],
            [72.837136094393145, 42.574754081677384],
            [72.815191265990336, 42.603018026902021],
            [72.786027272000069, 42.642391178988419],
            [72.748745237123899, 42.658009179218766],
            [72.621372327371432, 42.685518082603267],
            [72.458036191391898, 42.716627013411539],
            [72.353318224881491, 42.753854063002962],
            [72.29693617954797, 42.773573160830495],
            [72.272491197058741, 42.774964221485391],
            [72.171645166911844, 42.773573160830495],
            [71.989154202828388, 42.809136067360669],
            [71.957491228218544, 42.817473211196386],
            [71.898036209534212, 42.827473156988731],
            [71.821927187384006, 42.826364231195555],
            [71.747209225888753, 42.823582109885876],
            [71.716518216773807, 42.818445009053335],
            [71.657763254644635, 42.792200096671138],
            [71.639572345408283, 42.781773176729985],
            [71.603463274065518, 42.765391082203905],
            [71.578045152615005, 42.764282156410786],
            [71.510272268472249, 42.789418142999466],
            [71.487763337983893, 42.793591157326091],
            [71.42470024524863, 42.804136094464525],
            [71.275272368885112, 42.759136003122592],
            [71.169772370806299, 42.688645035410559],
            [71.169009282339061, 42.63150006924775],
            [71.057063265424063, 42.588436029906859],
            [71.035536191076403, 42.577473171075098],
            [70.955609212019169, 42.427464261181854],
            [70.961300189015105, 42.398991103653373],
            [70.927754136032718, 42.357191055314843],
            [70.883882248861426, 42.323300170834671],
            [70.873172355868178, 42.309136095533233],
            [70.914291290752715, 42.264691221459202],
            [70.962491257459703, 42.25440914080508],
            [70.970809290556133, 42.254673170756064],
            [70.966936180726123, 42.250800060925883],
            [70.907763296903397, 42.217736135015286],
            [70.884145105345738, 42.205100080666966],
            [70.840891299716418, 42.194953954482799],
            [70.792763249738925, 42.203991154873847],
            [70.750682240005062, 42.198845001585823],
            [70.665536185101985, 42.096627021523489],
            [70.654436198333826, 42.073854061084319],
            [70.645536125879346, 42.04857323520821],
            [70.628209223224644, 42.024264207188878],
            [70.538318172920697, 42.040591148792032],
            [70.531100180800081, 42.067391110803783],
            [70.505554151506715, 42.091073172463894],
            [70.484836098455787, 42.100518068626343],
            [70.462209150770491, 42.096491067053591],
            [70.38790022615126, 42.06940913781807],
            [70.36317227061005, 42.057873124445393],
            [70.337772254070615, 42.042882090574267],
            [70.244136169417345, 41.955236049223117],
            [70.18275415118768, 41.87608221691687],
            [70.156645193275381, 41.844418068840525],
            [70.139991189809678, 41.82831810917628],
            [70.058027240636989, 41.780264155222994],
            [69.928382332203398, 41.711900179266706],
            [69.845854113307269, 41.706673224431881],
            [69.786372272532844, 41.703327001036769],
            [69.75860922984333, 41.699153986710144],
            [69.6372092643104, 41.670127120656446],
            [69.489972249805021, 41.575554106863592],
            [69.353872270080046, 41.484436113569416],
            [69.254991293319165, 41.470827087897703],
            [69.226654090260467, 41.459718048674063],
            [69.059627234664561, 41.376791186461901],
            [69.070745158705563, 41.357564106352186],
            [69.07304515294345, 41.271936092015153],
            [69.06456333745831, 41.222218162638853],
            [69.031663361574374, 41.199718116967972],
            [68.875263250492026, 41.118600069466254],
            [68.785818117438396, 41.08568215630936],
            [68.769018268856826, 41.070273200744737],
            [68.750691237512569, 41.039436011238081],
            [68.738872251087969, 41.001245042778919],
            [68.721718183277119, 40.977836063525217],
            [68.664427204360834, 40.951935982640634],
            [68.648745166390029, 40.946800055274593],
            [68.646100173016578, 40.946654042520834],
            [68.616654211802711, 40.936936063952146],
            [68.593600289968549, 40.91999120844531],
            [68.568054093037006, 40.861382091431835],
            [68.589154193235629, 40.738882084922921],
            [68.628582330607003, 40.718045008846786],
            [68.65192710448224, 40.671664250410061],
            [68.640745142700837, 40.614227091101725],
            [68.608036106571831, 40.599718016664497],
            [68.479709168597196, 40.594436076544753],
            [68.455263347917821, 40.597773247484326],
            [68.310545095323675, 40.656245068923454],
            [68.265472249061929, 40.677800138827706],
            [68.256945171299833, 40.680545044487246],
            [68.233318262562818, 40.691373122315781],
            [68.212772373803773, 40.70388210701168],
            [68.048036124713974, 40.810273101400583],
            [68.05386339145582, 40.840827150217137],
            [68.081100218262577, 40.857218129560522],
            [68.103318129071681, 40.879436040369797],
            [68.116091311356513, 40.907491108566774],
            [68.144845095003973, 40.983045081087084],
            [68.154163256790127, 41.03610906622032],
            [68.140272263894815, 41.05165397625484],
            [68.107345130644603, 41.064853965050588],
            [67.989427175338506, 41.130545119714952],
            [67.935527179180042, 41.183327137624531],
            [67.755336209334132, 41.192145067427759],
            [67.639982278216564, 41.18220915937593],
            [67.536100155093209, 41.167500092728346],
            [67.489700285917195, 41.158882155135473],
            [67.439418254455461, 41.154164149462758],
            [67.350536217658771, 41.149164176566615],
            [67.159145181120437, 41.145000047057465],
            [67.071945224657611, 41.143300197088593],
            [66.875809192718236, 41.149991135136304],
            [66.777772273609742, 41.161936017746996],
            [66.759491175094752, 41.164835988615607],
            [66.719982236176804, 41.175000052072619],
            [66.63610922473157, 41.460545007243752],
            [66.562482251756592, 41.716936026708481],
            [66.557618233330345, 41.739018150685865],
            [66.545536222783312, 41.836382170605873],
            [66.539154074049748, 41.896664147859724],
            [66.526382232869366, 42.003053968782353],
            [66.029163376523286, 42.003053968782353],
            [66.029163376523286, 42.046109123305769],
            [66.033054088350127, 42.26388220016247],
            [66.034427211732066, 42.336382142433308],
            [66.03665411577407, 42.42332712140086],
            [66.070782208114679, 42.423864233757911],
            [66.095827167234916, 42.604436077285072],
            [66.101091170081872, 42.671664137719915],
            [66.110536233882272, 42.80027321055627],
            [66.123872344785866, 42.996936128930372],
            [65.821927190177803, 42.877209191276052],
            [65.748027134796757, 42.98943600194815],
            [65.638045165439223, 43.153600102325527],
            [65.519018116702, 43.321109084993566],
            [65.478591191745409, 43.339427063882283],
            [65.268327126688575, 43.432491167461308],
            [65.21609127359136, 43.480818036182711],
            [65.02692714109503, 43.652491148359843],
            [64.931363386343946, 43.737773157732818],
            [64.843318198762574, 43.703327055984801],
            [64.585536118642722, 43.600273232535415],
            [64.458036139237834, 43.54804509078923],
            [64.021382233197755, 43.569164134088965],
            [63.997218212103633, 43.570544968821935],
            [63.767209232957327, 43.59027311910512],
            [63.537500158307466, 43.609991211104102],
            [63.422491226325633, 43.619436107266722],
            [63.211382265426153, 43.636382136240002],
            [62.826663302158352, 43.589153967389791],
            [62.615000297457584, 43.562491133314438],
            [62.488045141760438, 43.546391173649965],
            [62.38249116422503, 43.532773095522771],
            [62.044718234945663, 43.488327047982409],
            [62.025109275326088, 43.484791058298981],
            [61.956936239124616, 43.543609052340116],
            [61.814709255799357, 43.663044970315582],
            [61.614436084079216, 43.829991192002808],
            [61.311663300349267, 44.079718095510316],
            [61.166100151912758, 44.198327054916035],
            [61.149163343032967, 44.21110006956269],
            [59.652973337830588, 45.000000104773903],
            [58.57082722242734, 45.570591124237751],
            [58.565509240123788, 45.569954099594554],
            [58.560545141773275, 45.56860008695179],
            [58.273045191500103, 45.50943608794654],
            [58.008045119259691, 45.453609092242573],
            [57.779636077795942, 45.404473202224494],
            [57.589709192108501, 45.363609077197225],
            [57.392218141698208, 45.320545037856334],
            [57.063609147186128, 45.247773186645702],
            [56.779436141930603, 45.183600162288826],
            [56.757500198344928, 45.178327106986387],
            [56.750191178755585, 45.172964197681665],
            [56.714154191780608, 45.168600075962217],
            [56.648609217507953, 45.153882124497159],
            [56.38750019237068, 45.093882114466908],
            [56.257218259293893, 45.063609027045516],
            [55.99875405989178, 45.002054006333651],
            [55.99749124235592, 44.734164189146384],
            [55.998600168149096, 44.509436144291556],
            [55.998882303010873, 43.948600151408527],
            [55.998045118519371, 43.878873110354107],
            [55.998327253380978, 43.626654099387338],
            [55.99916326440615, 43.346936075682038],
            [55.999436179174268, 43.081382127278346],
            [55.998600168149096, 43.011664138679237],
            [55.998882303010873, 42.760273092110708],
            [55.999718146397697, 42.481100059751327],
            [56.000000281259474, 42.216382122372636],
            [55.99916326440615, 42.132764256060682],
            [55.99916326440615, 41.896100045774517],
            [55.999436179174268, 41.576382071261605],
            [56.000273196027592, 41.353882103915055],
            [56.000963361936954, 41.328454091818571],
            [55.933600185222701, 41.333600077468589],
            [55.894436077802084, 41.3315180127139],
            [55.865273257278488, 41.320973243213814],
            [55.833882191608438, 41.300000045029435],
            [55.805827123411348, 41.288744993052092],
            [55.652491268500626, 41.267209033887127],
            [55.528882168560415, 41.26388208886948],
            [55.454782288607134, 41.288673243960773],
            [55.436100199843338, 41.308318078125865],
            [55.414718132421115, 41.351654026406607],
            [55.407491255482967, 41.370691172589943],
            [55.392491169156358, 41.388882081826296],
            [55.355545080960383, 41.40832709141921],
            [55.316663276039435, 41.423882227375771],
            [55.28916325747241, 41.439709104633948],
            [55.239991157632289, 41.488745082365938],
            [55.22776330196973, 41.505553983403047],
            [55.207218251401002, 41.543609165030091],
            [55.193882308135414, 41.562764160772417],
            [55.146663359378863, 41.624991074844786],
            [55.125827121492819, 41.651936043781916],
            [55.04790928463234, 41.733600088458161],
            [55.001936221967355, 41.771382187679308],
            [54.980691282481303, 41.792500057512598],
            [54.962773288013238, 41.816454028112389],
            [54.958327191280318, 41.855273136397386],
            [54.962736240000936, 41.900618059236706],
            [54.934154117731111, 41.929991097892639],
            [54.761109223276321, 42.058882137952651],
            [54.732209259237038, 42.073327007011244],
            [54.566936233066315, 42.153318023808936],
            [54.315273277557992, 42.268327123428776],
            [54.248327184346749, 42.310545093460803],
            [54.225818253858336, 42.322491149537996],
            [54.198600202514911, 42.332500147785765],
            [54.173045120766233, 42.337209101002827],
            [54.055545087153519, 42.348600107450238],
            [54.02332723055207, 42.350409089798518],
            [53.995545077123268, 42.348600107450238],
            [53.935127145399349, 42.338600161657951],
            [53.903600292897352, 42.330273243743818],
            [53.877218252579155, 42.32485400804967],
            [53.771936183983371, 42.306654046357835],
            [53.663045203146339, 42.289164199505251],
            [53.64193621813024, 42.286945006814278],
            [53.598045220219774, 42.284164226608937],
            [53.540554081454786, 42.27943599501441],
            [53.446591270215094, 42.267909034097215],
            [53.398045130905928, 42.257218084205135],
            [53.340827242185355, 42.243327091309766],
            [53.316663221091403, 42.23582713196555],
            [53.006245135815675, 42.13569121665779],
            [52.957073203613959, 42.112353986495407],
            [52.923045191197446, 42.093600148640121],
            [52.807209133007916, 42.016109118290672],
            [52.491382205598029, 41.780818031386389],
            [52.440073254094671, 41.740936121668426],
            [52.441585270500127, 41.740112114500164],
            [52.262941360000241, 41.506967545000066],
            [52.256681442000115, 41.503664017000119],
            [52.213800430000049, 41.480810165000094],
            [52.090658188000191, 41.416442871000015],
            [51.955533981000258, 41.344839096000101],
            [51.412741538360478, 41.054360745226518],
            [51.366469460868529, 41.103807394973842],
            [51.325225830000136, 41.166864395000118],
            [51.276690679262742, 41.241743538779644],
            [51.262363434000065, 41.263847351000209],
            [51.15730094900016, 41.427421570000092],
            [51.04281425500028, 41.626865387],
            [51.037300110000103, 41.63642692600024],
            [50.988414764000083, 41.721603394000056],
            [50.966360092000116, 41.737079620000031],
            [50.851884842000118, 41.782581329000095],
            [50.841066360000127, 41.786848068000097],
            [50.800672531000203, 41.802659988000073],
            [50.657669067000199, 41.870042801000011],
            [50.65142822300021, 41.873001099000021],
            [50.645347092088286, 41.875866303694124],
            [50.5872733025397, 41.93740908222361],
            [50.377600161658279, 42.108118107894825],
            [50.125392242454041, 42.318774852301999],
            [50.124774788779519, 42.319519687878312],
            [50.043920517000174, 42.423004150000111],
            [50.005865097000083, 42.471904755000082],
            [49.966375351000067, 42.5228443150001],
            [49.897027969000249, 42.630115509],
            [49.888084412000183, 42.644065856999987],
            [49.86624145500025, 42.680896759],
            [49.864006042000142, 42.68464851400006],
            [49.833375931000205, 42.735876083],
            [49.831283569000107, 42.739389420000123],
            [49.760971069000163, 42.864555359],
            [49.749692917000289, 42.885585785000103],
            [49.716913223000233, 42.906150818000015],
            [49.595012673885265, 42.982921190571744],
            [49.505123138000158, 43.039531708000027],
            [49.451820374000192, 43.077178955000193],
            [49.421380997000028, 43.098587036000083],
            [49.375694275000171, 43.138917923000065],
            [49.34301948500007, 43.17209243800005],
            [49.340692520000232, 43.174373627000193],
            [49.154357910000158, 43.367874146000133],
            [49.151535034000176, 43.37081909200009],
            [49.101816177000217, 43.422838211000112],
            [49.059949875000257, 43.637098312000148],
            [48.951732635000212, 44.185350418000098],
            [48.927448273000152, 44.307006836000085],
            [48.91719946860178, 44.356076373148312],
            [48.756618202336426, 44.587609126285315],
            [48.686154056714429, 44.75434512984026],
            [48.866345194198516, 44.98827315164641],
            [49.088136220172601, 45.18951812122333],
            [49.346854055965366, 45.444036120599321],
            [49.448318167654122, 45.530382128764302],
            [49.562690209594962, 45.586192678623547],
            [49.477266312000097, 45.734657288000051],
            [49.468479156000114, 45.749830246000101],
            [49.347267150999983, 45.957458496000015],
            [49.331651688000193, 45.984823226999936],
            [49.26971435500019, 46.090023041000109],
            [49.246065140000155, 46.127456665000125],
            [49.24028396600005, 46.137073517000147],
            [49.227489471000013, 46.156858444000022],
            [49.225742340000181, 46.159740448000093],
            [49.216211319000195, 46.17432403600003],
            [49.222112656000206, 46.194286346000069],
            [49.230251312000178, 46.226852417000146],
            [49.235544205000167, 46.242229462000125],
            [49.230853567000224, 46.267356298999971],
            [49.23400368630729, 46.266902814757657],
            [49.230582306447673, 46.277164106229463],
            [49.216191249207384, 46.334736046541082],
            [49.222527129835385, 46.34630910792589],
            [49.174991177499919, 46.369709034724224],
            [48.914754037933335, 46.487800160150357],
            [48.883327097717427, 46.481654045810728],
            [48.840509151054505, 46.482118067971896],
            [48.785827123327863, 46.515264136533744],
            [48.764436171088107, 46.536100206781725],
            [48.742900211923143, 46.556664200451621],
            [48.723454196501564, 46.561791075362351],
            [48.673045095387636, 46.563736012180527],
            [48.619982283720901, 46.559436095839487],
            [48.576173260823566, 46.561027148704795],
            [48.49442724113419, 46.667209098427861],
            [48.498600255460815, 46.688327135899328],
            [48.504991289011741, 46.719700096658528],
            [48.515409156497753, 46.737354061176006],
            [48.544573318125799, 46.754154077395413],
            [48.596382197074036, 46.771509143244941],
            [48.636936191683048, 46.774991153471717],
            [48.66582710326702, 46.772754023507957],
            [48.691091165336786, 46.767773161350931],
            [48.710536174929871, 46.75971815237699],
            [48.726100195703566, 46.746382209111573],
            [48.757209126511839, 46.703327054587987],
            [48.941373286112366, 46.704164239079489],
            [49.027209171648394, 46.776091194447531],
            [48.981654198314914, 46.824154033218349],
            [48.721100222807365, 47.098327092681359],
            [48.624700289393445, 47.270818110972698],
            [48.572491258386378, 47.365545016508122],
            [48.457554075495864, 47.431864143360144],
            [48.379991296055096, 47.501654048688749],
            [48.316091186466167, 47.57249118900296],
            [48.264436199260501, 47.641100084170603],
            [48.240818175341019, 47.674709169065295],
            [48.223309217748749, 47.690264137383679],
            [48.204427136774768, 47.704991141303992],
            [48.143036233727798, 47.749718150239687],
            [48.102763368152068, 47.76874507050087],
            [48.064709192353121, 47.779709102799302],
            [48.037345128255652, 47.782627010940871],
            [47.918873296786273, 47.782491224109151],
            [47.795536105785857, 47.778600177005956],
            [47.738045134659217, 47.773036102024605],
            [47.691373188905573, 47.765827162359471],
            [47.663318120708425, 47.769436074600833],
            [47.633054085742515, 47.779764255722398],
            [47.599991165660413, 47.794153971858009],
            [47.521382324700312, 47.820827031855515],
            [47.485263362711692, 47.832764203115175],
            [47.444845154934796, 47.842209099277795],
            [47.419845122815616, 47.837627048074978],
            [47.410809263529245, 47.813191118041402],
            [47.413045052388384, 47.771100050023733],
            [47.396200276805985, 47.696864215600613],
            [47.255827202830119, 47.750827076032806],
            [47.189418221975814, 47.783873232308395],
            [47.144645112572562, 47.81214505652224],
            [47.119982200600305, 47.946091222401151],
            [47.122209272280259, 48.102218083439197],
            [47.130273333709738, 48.237209137371167],
            [47.134873322185342, 48.24810913192897],
            [47.12123613331886, 48.272073160812738],
            [46.979991173772191, 48.306100167400587],
            [46.819991202904077, 48.343609184215438],
            [46.659709264812193, 48.381100096119496],
            [46.499163296769552, 48.417500187141172],
            [46.527491279734591, 48.47082702875872],
            [46.556100224094962, 48.523882129074821],
            [46.606945184175402, 48.61720908913793],
            [46.63113619499768, 48.663791180889518],
            [46.778882158665169, 48.936653981244774],
            [46.809573335418236, 48.95207316273121],
            [46.84832723249616, 48.966936121121478],
            [46.881936149752676, 48.978327127568718],
            [46.914709223621969, 48.990273183645684],
            [46.943600135205941, 49.006100060904032],
            [46.961800264536009, 49.018745000069828],
            [46.995545136262422, 49.049436009184603],
            [47.016391264793867, 49.071382178692261],
            [47.04374510296941, 49.10360020293183],
            [47.059573321331868, 49.133600207946927],
            [47.064573294228182, 49.159164174513137],
            [47.059154058534034, 49.197353969505798],
            [47.039991183802613, 49.224709148785792],
            [47.020409213911307, 49.239718119929762],
            [46.946100289292019, 49.28220917236797],
            [46.923045193991413, 49.295273206693594],
            [46.899718189750843, 49.308045047873975],
            [46.874709272814528, 49.319853976014713],
            [46.838045151842209, 49.331935986561746],
            [46.80401814525402, 49.33846414804907],
            [46.802218215361052, 49.365000080110178],
            [46.861109131960347, 49.596100047776815],
            [46.931382170189181, 49.865827010507132],
            [46.949300164657416, 49.878745032079394],
            [46.981654143366711, 49.891664227117758],
            [47.009163214389446, 49.900827156056607],
            [47.045273291560534, 49.910545134625465],
            [47.089427145955483, 49.921664232132912],
            [47.11970911819401, 49.928600089391921],
            [47.150827101457764, 49.934436073313123],
            [47.194163217376854, 49.947209087959777],
            [47.26277328601094, 49.99749111942134],
            [47.302491269594952, 50.031936047703027],
            [47.345273341712073, 50.075827045613494],
            [47.355545196444382, 50.099018095383997],
            [47.341100159747441, 50.128882145929424],
            [47.323463294312489, 50.144854030112981],
            [47.305263332620825, 50.157353962353227],
            [47.282009250938017, 50.181245068679004],
            [47.319645169767455, 50.296109161373806],
            [47.362354151688805, 50.312627042731606],
            [47.410545065940482, 50.328609152836975],
            [47.435691278451145, 50.355545069318794],
            [47.446400165616069, 50.376953958831336],
            [47.445782251712217, 50.378345019486119],
            [47.485691175051045, 50.417627144103619],
            [47.52082710743241, 50.436382155425349],
            [47.553882316163481, 50.449718098690767],
            [47.576100226972756, 50.456382214648158],
            [47.599718250892295, 50.460818085459096],
            [47.627354056291097, 50.457354012505107],
            [47.760827147553698, 50.375264167146497],
            [47.939436116989839, 50.250964230744614],
            [48.012218194122283, 50.191373257590385],
            [48.110545127081764, 50.09860017369067],
            [48.129709175279515, 50.071100155123474],
            [48.134854155100868, 50.0429091324566],
            [48.139718173527172, 50.008609043462457],
            [48.165545164215814, 49.96610005375129],
            [48.248745108834044, 49.871382200671349],
            [48.376654125115351, 49.833327019043963],
            [48.446663301031549, 49.817353961394076],
            [48.469436093832542, 49.829509062136879],
            [48.491663224735305, 49.846800090245949],
            [48.615545071805514, 49.886654028299631],
            [48.653045203803003, 49.895273139359006],
            [48.688600231344026, 49.905264200333988],
            [48.744154144641698, 49.922491190702431],
            [48.790554181455718, 49.939436046209437],
            [48.833882250747422, 49.959164196492281],
            [48.865000234011177, 49.98110014007807],
            [48.914718163387477, 50.032773232194586],
            [48.88110019367565, 50.099227140049834],
            [48.80971822965347, 50.162764145592234],
            [48.748882208598047, 50.267773131781553],
            [48.729154058315032, 50.331382221691342],
            [48.722763360040119, 50.353053967688197],
            [48.705827221712639, 50.424709181754508],
            [48.690545168162373, 50.504854090294941],
            [48.697491251343195, 50.591936029560884],
            [48.739436138969154, 50.608327008904098],
            [48.820409179545436, 50.596936002456857],
            [48.85832723323648, 50.605273146292802],
            [48.994709180185396, 50.668882236202421],
            [49.027773273733828, 50.686518095809049],
            [49.089991302988494, 50.735545021085557],
            [49.127627221817932, 50.769718040427506],
            [49.143191242591627, 50.785000093977544],
            [49.165827242732405, 50.794436105322802],
            [49.219154084350123, 50.79804501756405],
            [49.301100263888202, 50.809991073641015],
            [49.323609194376218, 50.815545090338787],
            [49.407209123415157, 50.842764147510536],
            [49.425827174438723, 50.851391137559062],
            [49.439227155444769, 50.866454088159514],
            [49.440127204210057, 50.903882135789559],
            [49.421800172865801, 50.930273228563465],
            [49.390273320364031, 50.94887317467601],
            [49.365273288244907, 50.970818170716996],
            [49.419718108111226, 51.082073183532202],
            [49.474709260427801, 51.124018071157991],
            [49.537491224130122, 51.109991123792895],
            [49.584718219513661, 51.10832714836971],
            [49.802845180323629, 51.111382184447677],
            [49.829154130445886, 51.129991183015534],
            [49.863882199417731, 51.158045077746181],
            [49.939573299874212, 51.211036140321482],
            [49.968045116298015, 51.226100096750542],
            [50.018327147759749, 51.240682093745534],
            [50.074436110687145, 51.250545079239771],
            [50.170409237590263, 51.263191191871897],
            [50.200273288135463, 51.266391067237123],
            [50.264163339440415, 51.277773188867002],
            [50.368600176917482, 51.327418028047418],
            [50.35687322379016, 51.37012700996894],
            [50.384373242357242, 51.423600199616374],
            [50.475618137665776, 51.432282174949648],
            [50.5502732348873, 51.471653985931596],
            [50.552627208581498, 51.493191118562891],
            [50.551382328318425, 51.52860013335038],
            [50.557500279463227, 51.58027322546684],
            [50.600409253594933, 51.637773249049133],
            [50.621382284141134, 51.644854113233407],
            [50.713536113032546, 51.62044500528998],
            [50.689227085013385, 51.595127131401739],
            [50.68331817853425, 51.575544993872313],
            [50.71054511469498, 51.572082094384768],
            [50.77860013369957, 51.57582712873409],
            [50.812354057881464, 51.59430017283205],
            [50.820273280023514, 51.615127022986371],
            [50.816527239846096, 51.640409189966988],
            [50.794991280681131, 51.672909181430157],
            [50.775963354591454, 51.69443608813981],
            [50.759018163808264, 51.721445094817568],
            [50.760691191686817, 51.752427123611426],
            [50.773300256307152, 51.76918221282989],
            [50.811663221419849, 51.764718178824296],
            [50.841936141203064, 51.7599911206961],
            [50.863327093442649, 51.75139112037607],
            [50.890136107910052, 51.733182106228867],
            [50.913536202346393, 51.701591215986468],
            [50.944300134019102, 51.688191067342416],
            [51.176663254497953, 51.676800060895175],
            [51.204163273065149, 51.678045108796425],
            [51.275273328147364, 51.683873213728589],
            [51.384709132692478, 51.640544976798878],
            [51.402282295662985, 51.615409157848205],
            [51.389227146154667, 51.570754065641836],
            [51.301654194999458, 51.554991226124059],
            [51.257563372516643, 51.540964111120843],
            [51.299436175774986, 51.481236010030443],
            [51.425336217497261, 51.469018045013456],
            [51.52499117919956, 51.492764144414025],
            [51.649091291028867, 51.476591094553839],
            [51.677009231289617, 51.455682101748039],
            [51.711936119005145, 51.461936007362738],
            [51.801973182062625, 51.503082099613607],
            [51.796382284990898, 51.544853984757367],
            [51.779573216315782, 51.582354116755084],
            [51.786936215362005, 51.60374506899467],
            [51.871382213709808, 51.671800087999031],
            [51.893609176974564, 51.681935988261444],
            [51.917073309151533, 51.686518039464033],
            [51.95651821032925, 51.683744970609666],
            [52.005554188061069, 51.666100058547556],
            [52.025545194828396, 51.663327157331537],
            [52.089154117100179, 51.661936096676584],
            [52.109436143546418, 51.665127087224334],
            [52.138736092006582, 51.681664246959599],
            [52.165263306888392, 51.718318142010105],
            [52.313327112325112, 51.778882086487613],
            [52.341800269853763, 51.780754100748197],
            [52.365273286847867, 51.759164162126581],
            [52.474091177489129, 51.582627031523202],
            [52.482773320460637, 51.549300081490287],
            [52.489854184645083, 51.528744972637583],
            [52.507354089781586, 51.503882236093204],
            [52.530691152305849, 51.484154085810019],
            [52.55915408391246, 51.470827027361906],
            [52.607627133025574, 51.456382158303143],
            [52.664154185284474, 51.456654067242994],
            [52.698609171849853, 51.472627124892881],
            [52.768254070253192, 51.503264154551118],
            [52.841382319987645, 51.484718020257219],
            [52.884991183036504, 51.465000095896244],
            [52.986109122122969, 51.470827027361906],
            [53.048336203833344, 51.491664271076218],
            [53.147491268828844, 51.501109167238837],
            [53.203882198979727, 51.493191118562891],
            [53.294445142644349, 51.486382163318297],
            [53.325691201388963, 51.492218147239669],
            [53.35540923918046, 51.501109167238837],
            [53.423745219580155, 51.492636068933166],
            [53.638118261223326, 51.383018041812534],
            [53.612500147562514, 51.349718081507945],
            [53.611936213115087, 51.301245032394661],
            [53.675545135386756, 51.229291087298293],
            [53.751663377630621, 51.214154040673407],
            [53.884918204133726, 51.192700056521915],
            [53.91499129934482, 51.199718056432175],
            [53.950263353834032, 51.196100091735445],
            [54.135127234713195, 51.104018179573359],
            [54.143882300242467, 51.084436042043876],
            [54.168463237201593, 50.99874499579505],
            [54.204436186436283, 50.966936008431333],
            [54.30943612017046, 50.904991229220911],
            [54.374436103096684, 50.895273250651996],
            [54.425827227989714, 50.885827013385097],
            [54.501663335371632, 50.85922704358353],
            [54.504091237452258, 50.830273100087595],
            [54.468045198021798, 50.795545031115978],
            [54.441236183554395, 50.769364156474239],
            [54.398400299618459, 50.625891119419293],
            [54.411936235094402, 50.596654035233428],
            [54.418882318275223, 50.588044982457689],
            [54.451100174876785, 50.557636108204576],
            [54.498745091954049, 50.533464208121757],
            [54.523936231465882, 50.528836056451496],
            [54.606663269105809, 50.542773149814309],
            [54.686800298657261, 50.589853964806139],
            [54.701800217345749, 50.609644979363281],
            [54.693882168669973, 50.649718020366663],
            [54.665827100473052, 50.69610011990784],
            [54.658327141128609, 50.727909107271444],
            [54.671663252032374, 50.793609146753113],
            [54.672427178689986, 50.871209141844361],
            [54.634854124134506, 50.904573139889351],
            [54.580827225961684, 50.917773128685099],
            [54.548609201722059, 50.922218051951347],
            [54.554227088522111, 51.009854035018691],
            [54.647218269543316, 51.036945016709524],
            [54.674163238480276, 51.037218099115819],
            [54.713745099956384, 51.029845041786018],
            [54.828882275057083, 50.983327155412894],
            [54.987773320132419, 50.89860908048712],
            [55.074100217558112, 50.835064195956079],
            [55.090136139481956, 50.814027127669419],
            [55.298736229115889, 50.687073145438774],
            [55.376382324674523, 50.652345076467157],
            [55.411800224279489, 50.664718106693272],
            [55.452636186112159, 50.669300157895918],
            [55.490273278407869, 50.663882095668271],
            [55.509854074832504, 50.654991075669102],
            [55.525963254590636, 50.638327013919422],
            [55.537845105289193, 50.612836137549152],
            [55.655545131112092, 50.546944990674433],
            [55.675273281395107, 50.53750009451187],
            [55.692491219308067, 50.532491069160187],
            [55.756663237836619, 50.578045036665401],
            [55.779718165499389, 50.591382153397433],
            [55.839154073444433, 50.613882199068314],
            [55.86749127650296, 50.62193603457581],
            [55.911663235808703, 50.631936148006332],
            [55.931382333636407, 50.639718074574148],
            [56.00027319602782, 50.672218066037544],
            [56.035000259170943, 50.692218125260354],
            [56.118882323071659, 50.743391153031837],
            [56.126936158579156, 50.772353981345304],
            [56.134509208119539, 50.814991214175336],
            [56.162763262698149, 50.894718033384265],
            [56.176036174051774, 50.913882081581903],
            [56.203327147952962, 50.915691063930353],
            [56.234436078761235, 50.904709094359305],
            [56.260963293643215, 50.897636109163898],
            [56.327636136810156, 50.889436093264521],
            [56.356100241883098, 50.901654058281281],
            [56.451309274490654, 50.976936121861797],
            [56.446936100315668, 51.006800172406997],
            [56.441236238502597, 51.033818063902118],
            [56.478873330798137, 51.069164214415594],
            [56.501863382530018, 51.080827129802856],
            [56.529991205646695, 51.074718063475359],
            [56.553609229566405, 51.049436064132806],
            [56.573327153927437, 51.028600161522945],
            [56.590545091840568, 51.012218066996866],
            [56.62298221902978, 50.989227009436547],
            [56.728318267082045, 50.984191161994715],
            [56.71331818075555, 51.01874505501786],
            [56.702218193987278, 51.042909076111812],
            [56.706036150894477, 51.063253966832008],
            [56.753609151242131, 51.084164133104252],
            [56.780273326422105, 51.091800214556372],
            [56.806100149472627, 51.082009145791915],
            [56.835263305272406, 51.064436150459244],
            [56.863600173055147, 51.059154042701323],
            [57.050827247722111, 51.07027314020894],
            [57.099718218528722, 51.075827156906485],
            [57.127354191565701, 51.084709124450342],
            [57.208045097280547, 51.06554507625242],
            [57.266109222948103, 51.018054050918067],
            [57.340545217219756, 50.920545024072794],
            [57.355973283523724, 50.903745007853388],
            [57.384573343066734, 50.888609134694832],
            [57.427773336877408, 50.873045113921137],
            [57.463536235617937, 50.865273245636899],
            [57.510136097004306, 50.872627024589406],
            [57.528191219408768, 50.887082119570152],
            [57.539436213102306, 50.909154017625553],
            [57.561663344005069, 50.923882195012368],
            [57.612773339864816, 50.926654090400234],
            [57.644436146836483, 50.924018149482265],
            [57.69589130946963, 50.906582114447986],
            [57.736100304943079, 50.910409123810553],
            [57.753463249781589, 50.929573172008361],
            [57.759991243630907, 50.958882172923666],
            [57.760554172249613, 50.980964129263043],
            [57.751936234656569, 51.022764177601573],
            [57.749718215432097, 51.052773235072209],
            [57.755000155551897, 51.082491105225699],
            [57.792682174848778, 51.116318119603591],
            [57.8435361873847, 51.102144991846274],
            [58.15138220184474, 51.053882160865328],
            [58.18270017731902, 51.058182244844545],
            [58.211936088038556, 51.096727097256803],
            [58.220545140814124, 51.117627037607065],
            [58.314436202962497, 51.149991074600337],
            [58.337773265486874, 51.156100140927833],
            [58.37804512523428, 51.127773163791232],
            [58.572909287181915, 51.063464184964289],
            [58.601391161889865, 51.046664168744712],
            [58.615273269967702, 51.031382115194447],
            [58.625827091923441, 51.002845087563742],
            [58.611100255641077, 50.959018127393733],
            [58.570236130613807, 50.921764255712162],
            [58.596654045477862, 50.866100204206418],
            [58.665545075507453, 50.804991100744871],
            [58.898045156284525, 50.697773147786393],
            [58.923882205256973, 50.686654050278776],
            [58.949991163169102, 50.682218179467839],
            [59.095545091512122, 50.669718079589472],
            [59.193318148308208, 50.668882236202421],
            [59.242218171570471, 50.666100114892743],
            [59.357773268364667, 50.635273151307899],
            [59.462627189344971, 50.634854056147901],
            [59.488745199712753, 50.630409132881653],
            [59.58409119261853, 50.586445044775076],
            [59.577563366406991, 50.560545131528841],
            [59.541936086860574, 50.553045004546618],
            [59.49708217591035, 50.557836100415102],
            [59.467563292138664, 50.533536124851082],
            [59.529718121843587, 50.483327015947452],
            [59.54250018894561, 50.478327043051138],
            [59.600273294934254, 50.511382084144259],
            [59.625545068354825, 50.522764205774138],
            [59.659018198779449, 50.535545099409887],
            [59.698600227893735, 50.536109033857088],
            [59.720691236688253, 50.532627023630084],
            [59.755000210500043, 50.53388212981514],
            [59.814400243899229, 50.546273097313929],
            [59.895273204551813, 50.645273097100244],
            [59.939709193808142, 50.711936049621556],
            [59.93638224879038, 50.749645058646706],
            [59.946373309765363, 50.778464221139245],
            [59.968045223400225, 50.813609038337745],
            [59.98790916051513, 50.843045108905812],
            [60.019154213431477, 50.858327162456078],
            [60.052909143441696, 50.864164152205774],
            [60.11950923168888, 50.862282079661384],
            [60.170409177054296, 50.83554498192359],
            [60.16922716106518, 50.795127109422481],
            [60.176663250307001, 50.769018151510352],
            [60.237491224735436, 50.721654028190244],
            [60.270545092362227, 50.707773261216857],
            [60.318118260348001, 50.690964192541799],
            [60.357773212019879, 50.686100174115552],
            [60.698045121918653, 50.661664244081805],
            [60.729709269995169, 50.660273183426852],
            [60.769991188026211, 50.661236096466325],
            [60.929991158894495, 50.694991194114721],
            [61.04804506867049, 50.723664176215323],
            [61.091936234218906, 50.734991144922162],
            [61.145000219352141, 50.74499109071445],
            [61.1780452021614, 50.751109041859422],
            [61.277773254059639, 50.768882197040284],
            [61.303045195118386, 50.773044985445097],
            [61.339427181229325, 50.776654065324465],
            [61.38138229477687, 50.783609033322648],
            [61.403600205585974, 50.789991182056212],
            [61.422354211079437, 50.800618094207891],
            [61.461663325425036, 50.873045113921137],
            [61.467763339297221, 50.897773237100239],
            [61.497773234958345, 51.025273216505127],
            [61.541109183238859, 51.199436089208575],
            [61.550973342199427, 51.217909133306534],
            [61.567073301863729, 51.232354002365241],
            [61.592218173270112, 51.243882136748709],
            [61.621654076200002, 51.252773156747878],
            [61.661100150843993, 51.261936085687068],
            [61.685818215739516, 51.265827132789866],
            [61.671663360531397, 51.268327119238108],
            [61.647773260034114, 51.274709100333723],
            [61.603882262123648, 51.289300149784196],
            [61.575554111520432, 51.309435995838726],
            [61.490827151777353, 51.425000145088632],
            [61.348873250858219, 51.458045127897719],
            [61.210273284685002, 51.468600123319959],
            [61.176654141506674, 51.466236091341784],
            [61.14054507016408, 51.459718155776272],
            [61.024018175512765, 51.480409051460697],
            [60.975545126399481, 51.50027315621378],
            [60.937763362454689, 51.552700116703818],
            [60.931654128489015, 51.598045039543194],
            [60.942300151379811, 51.616664264033034],
            [60.922218117143927, 51.620545085214246],
            [60.855554158794348, 51.622218113092629],
            [60.536109266687674, 51.627491168395068],
            [60.377073214687357, 51.690436076295214],
            [60.401100275482918, 51.713053971525028],
            [60.459300187982365, 51.735827099602375],
            [60.499227216232043, 51.794227004311836],
            [60.485827235225997, 51.809154000442504],
            [60.462773313391665, 51.815827168855378],
            [60.224163328097006, 51.871936131782604],
            [60.137491263897573, 51.864854094131886],
            [60.114154201372969, 51.862209100758605],
            [60.087491199659667, 51.866935991248454],
            [60.052009262314414, 51.883191183760118],
            [60.003191214065396, 51.955553998094558],
            [60.00874506312482, 51.97888217580163],
            [60.025554131799879, 52.000827004204439],
            [60.223045182210342, 51.999164202247869],
            [60.396100302586717, 52.106100187982918],
            [60.460273326943764, 52.144718130591059],
            [60.490682201196933, 52.155545034952922],
            [60.53874520760553, 52.159573209992345],
            [60.60249125781354, 52.152764254747694],
            [60.641663244223309, 52.148327042832307],
            [60.68388222008349, 52.149018046931928],
            [60.721654093382824, 52.159991131685842],
            [60.747218227587041, 52.179164232338962],
            [60.777773282232204, 52.216236049082966],
            [60.810827149858653, 52.230264169914676],
            [60.849163292881201, 52.239436151309008],
            [60.902209173103643, 52.254718037221267],
            [60.934991299428816, 52.269718123547875],
            [61.060127246855359, 52.34082700516376],
            [61.046109184307625, 52.356654050059944],
            [60.978600162477449, 52.413044980211168],
            [60.970827120726938, 52.494991159748849],
            [60.835136177877985, 52.536936047374638],
            [60.835827181977834, 52.599164134913337],
            [60.825127179630385, 52.63638213204905],
            [60.765273349991503, 52.634164112824578],
            [60.739718268242996, 52.63638213204905],
            [60.69560917321121, 52.676591127522499],
            [60.697073324061876, 52.707636020590485],
            [60.706654174694222, 52.726382147094739],
            [60.731654206813346, 52.749718036152501],
            [60.883882309397563, 52.822773195691411],
            [61.012909136289295, 52.883745003578383],
            [61.08055411258897, 52.929718066243595],
            [61.099163278795174, 52.981654014844366],
            [61.170691255570887, 53.014436141169313],
            [61.20332720150418, 53.021936100513585],
            [61.23485405400595, 53.019153979203907],
            [61.254436191535433, 53.013473228129897],
            [61.27221823153377, 53.003327101945672],
            [61.300136171794122, 52.994991131576455],
            [61.355000254458332, 52.991936095498431],
            [61.384018235694668, 52.994573209882731],
            [61.403045155956249, 53.003745023639226],
            [61.426936094643906, 53.020545039858632],
            [61.448182207596233, 53.028327134064682],
            [61.472354107679337, 53.028327134064682],
            [61.495545157449897, 53.022073228449926],
            [61.695545079125566, 52.983045075499263],
            [61.864918197064981, 52.98499118578394],
            [61.902209284396804, 52.966664154439684],
            [61.954782257640773, 52.93957317274868],
            [62.098463333532891, 52.977073137108164],
            [62.114018134213268, 52.995964270537684],
            [62.132209211087627, 53.038891181942347],
            [62.144018139228592, 53.076100126622634],
            [62.108182317930073, 53.121936061351619],
            [62.077491308815183, 53.130273205187336],
            [62.046663339402016, 53.133327067798689],
            [61.997773206785723, 53.139718101349956],
            [61.856945163104058, 53.162491229426905],
            [61.821109174167759, 53.169582151895156],
            [61.781109223360147, 53.180827145588523],
            [61.753327237569692, 53.192218152035935],
            [61.731373189072883, 53.206653968639159],
            [61.606382248571549, 53.228600138146646],
            [61.582354181947721, 53.219018114047685],
            [61.559991264213011, 53.215544988638328],
            [61.538045094705581, 53.215544988638328],
            [61.509718117568809, 53.219709118147478],
            [61.479154178106455, 53.229718116395304],
            [61.459163338977135, 53.238600083939161],
            [61.435818229825543, 53.251664118264728],
            [61.4116632611873, 53.265682180812632],
            [61.381245166840699, 53.275544998668579],
            [61.352491215554721, 53.279718180633381],
            [61.321382284746448, 53.279991095401499],
            [61.274436083119895, 53.278873117152671],
            [61.235827192967179, 53.280964234362727],
            [61.212209169047696, 53.28860920063255],
            [61.184718202936324, 53.306654097115199],
            [61.167500265023023, 53.323609178543848],
            [61.153327137266274, 53.368327135023833],
            [61.245963260867597, 53.507918177431236],
            [61.286773238800379, 53.505218031134802],
            [61.311100204092554, 53.502964137364529],
            [61.348873250858219, 53.480545060878626],
            [61.377209280450245, 53.45610913084505],
            [61.40470913137932, 53.444709071942157],
            [61.438600183497442, 53.446100132597053],
            [61.574582313663655, 53.520273102746444],
            [61.538891164014387, 53.593318036363144],
            [61.469982196712181, 53.594709097018097],
            [61.419436135299861, 53.595127018711594],
            [61.38749119346619, 53.590545135146954],
            [61.365273282657085, 53.575827016043945],
            [61.324436147357972, 53.555827124459313],
            [61.282936171154006, 53.561153991580227],
            [61.228600148391251, 53.563327083803529],
            [61.182500183711682, 53.56944503494833],
            [61.103182233740768, 53.613536025069322],
            [61.07332723565105, 53.618882170567531],
            [61.026654116430905, 53.61999109636065],
            [60.950827229142305, 53.618327120937806],
            [60.905891175541001, 53.622073161115395],
            [60.911382327964589, 53.659991214806439],
            [60.946936182039281, 53.664154003211081],
            [60.980963356265306, 53.657764143126315],
            [61.007218159293132, 53.640545031746854],
            [61.050409268286614, 53.642218059625236],
            [61.223045125865383, 53.807073164102462],
            [61.226727128302343, 53.830554060085774],
            [61.122073199532394, 53.881800178053197],
            [61.098873264944245, 53.885827011987942],
            [61.036100186059798, 53.886382229255844],
            [60.99999128235504, 53.892627082415231],
            [60.993463288505723, 53.919300142412567],
            [61.009163263749485, 53.943454105222713],
            [61.035482272155946, 53.955545000587222],
            [61.090545173563953, 53.963609062016701],
            [61.113054104052367, 53.963609062016701],
            [61.21554516652094, 54.025827091271594],
            [61.326100290418992, 54.06916421301861],
            [61.420273319791363, 54.066664226570708],
            [61.455554091459589, 54.057636078634971],
            [61.486654137450728, 54.015545010617473],
            [61.588418153635871, 53.998418100173126],
            [61.642218237508018, 54.001936152583767],
            [61.789718276135716, 54.004164230092215],
            [61.851727260724942, 53.960682101419764],
            [62.025827101516029, 53.939709070873562],
            [62.02596339126228, 53.988044992050447],
            [62.024709290905548, 54.024991080246366],
            [62.05331823526592, 54.028045110496066],
            [62.09610030738304, 54.029436003513013],
            [62.358045175907478, 54.022764176204589],
            [62.404018238572689, 53.961382157974924],
            [62.425482281007987, 53.936582118066326],
            [62.533045065464421, 53.881653997661488],
            [62.571945142933998, 53.893609106193992],
            [62.568327178237496, 53.952773105199185],
            [62.577354152706164, 54.043745085739829],
            [62.641109255369713, 54.075136151409879],
            [62.678182245580217, 54.083882164483668],
            [62.729709157304967, 54.08638215093174],
            [62.897491222379074, 54.087209109501259],
            [62.919154083558453, 54.085964229238357],
            [63.109991243933564, 54.095545079870703],
            [63.145063306212421, 54.112982120733307],
            [63.149854066804778, 54.135827165539922],
            [63.138118228859753, 54.168082237791623],
            [63.163745227338097, 54.184718136346703],
            [63.19249129963481, 54.187764119969017],
            [63.215000230122882, 54.187491205200899],
            [63.3258272629609, 54.182491232304756],
            [63.349854156118454, 54.172082081998042],
            [63.376100241967151, 54.169154115572667],
            [63.409300289985424, 54.173600212305587],
            [63.54221816398001, 54.206654079932207],
            [63.686936081298199, 54.249164243109874],
            [63.717773270804798, 54.258054089642542],
            [63.75937332693286, 54.257773128247209],
            [63.806100257971821, 54.24415404429169],
            [63.832773317969327, 54.23165411205116],
            [63.89336324634678, 54.201291170627655],
            [63.977354107350948, 54.214164265198406],
            [64.018345134392803, 54.244091180017563],
            [63.990482179417199, 54.271382153918921],
            [63.978045111450569, 54.292636145860286],
            [63.99548231995152, 54.311173227698703],
            [64.085263232047396, 54.318327014440911],
            [64.240818279649034, 54.331109081543048],
            [64.294427256128358, 54.34707325437563],
            [64.319427120609305, 54.347218093662889],
            [64.341091322893362, 54.341936153543088],
            [64.362200307909291, 54.339427114639534],
            [64.382472276071894, 54.338882123293615],
            [64.411372240111007, 54.341664244603294],
            [64.538882277800099, 54.363882155412568],
            [64.731791277007574, 54.341382109741687],
            [64.7634633040729, 54.345964160944334],
            [64.823882241625142, 54.372218125781842],
            [64.884427243001483, 54.39943600948726],
            [64.915818141033355, 54.408045062262829],
            [64.966927131064608, 54.407209051237771],
            [64.994009227938136, 54.397909162000587],
            [65.014709176078043, 54.38555406904743],
            [65.035809276276666, 54.370545097903459],
            [65.060400271519484, 54.347500060886489],
            [65.099572257929253, 54.318736051317103],
            [65.119982192218515, 54.312773165381316],
            [65.224982293590529, 54.324227036102513],
            [65.23984508434279, 54.344573100289381],
            [65.230954064343621, 54.376518042122882],
            [65.215545108778997, 54.405409121345031],
            [65.200954059328524, 54.464573120350224],
            [65.220818164081265, 54.530136031895552],
            [65.346936135286853, 54.568054085586596],
            [65.472136120454024, 54.630682157545777],
            [65.501936133258823, 54.640544975401895],
            [65.523318200681047, 54.640544975401895],
            [65.556363351128482, 54.637218030384247],
            [65.656645111551768, 54.621100133446987],
            [65.684418212525316, 54.615273201981097],
            [65.725263394451247, 54.60277310210256],
            [65.752627123272418, 54.600964119754281],
            [65.777072273399654, 54.606800103675482],
            [65.794982221240673, 54.621245140372196],
            [65.815682337018586, 54.647773193444493],
            [65.847209189520754, 54.684854062643865],
            [65.971309133712111, 54.708809039072094],
            [65.980818235252912, 54.665127085827351],
            [65.98567236303353, 54.627773134221684],
            [66.09166337300195, 54.639164140668754],
            [66.1083182146578, 54.645000124590126],
            [66.22053614051265, 54.663882205564278],
            [66.271927265405679, 54.673609068950327],
            [66.322218181684548, 54.684154006088704],
            [66.40525417627623, 54.702218180948648],
            [66.437191239120693, 54.710273189922816],
            [66.469145233409677, 54.718600107836721],
            [66.495254191321749, 54.723045031103027],
            [66.52360916401517, 54.725264223793943],
            [66.583054124415867, 54.72720916061229],
            [66.625263377268425, 54.727764210242015],
            [66.692472326963923, 54.725545017551269],
            [66.72276335165833, 54.726936078206165],
            [66.750545169810835, 54.734291198263008],
            [66.781163256368018, 54.757427095110586],
            [66.8181723764759, 54.767773213505109],
            [66.876372288975347, 54.772218136771414],
            [66.950272344356392, 54.773045095341104],
            [67.031936221394517, 54.7761001314189],
            [67.249009241696285, 54.808045073252401],
            [67.264436134533582, 54.822918089926645],
            [67.287627184304256, 54.849854006408464],
            [67.30373619642404, 54.861800062485429],
            [67.327627135111697, 54.867909128812869],
            [67.36123622000639, 54.863609044833879],
            [67.391918176665797, 54.852627075262831],
            [67.56052720030948, 54.853882181447659],
            [67.581372323012772, 54.854436057611053],
            [67.683045144091182, 54.874164207894069],
            [67.758472382235084, 54.889854124853855],
            [67.791372358119133, 54.917491103719499],
            [67.808591301860588, 54.940827160415211],
            [67.834854151515401, 54.96352703065844],
            [67.898036099638034, 54.978327124774751],
            [67.943936239745256, 54.978327124774751],
            [68.026309225794193, 54.95138215583745],
            [68.05525411683476, 54.950827106207726],
            [68.20595419846569, 54.967700212623072],
            [68.328182296034754, 55.06672703449965],
            [68.324718223080595, 55.087209053156414],
            [68.293045190186945, 55.094991147362293],
            [68.247627177151685, 55.103054035325442],
            [68.216654200813309, 55.132491111721663],
            [68.199709177668296, 55.17832704645059],
            [68.220263280692507, 55.187209181632284],
            [68.267763358482341, 55.200000133551782],
            [68.304427144178703, 55.205273188854051],
            [68.476382223579606, 55.192491121752028],
            [68.500272324076946, 55.190545011467407],
            [68.62192726710532, 55.200964220057699],
            [68.640545150490482, 55.218118120230201],
            [68.723309236142427, 55.368327022333972],
            [68.736509224938118, 55.369764183456368],
            [68.770936215946847, 55.365545068662129],
            [68.854982229874111, 55.350544982335634],
            [68.886509250014058, 55.339291103824621],
            [68.907972286621089, 55.317909036402398],
            [68.93963609942125, 55.285691179800779],
            [68.974427200305058, 55.283882197452328],
            [69.003463286452416, 55.290973119920579],
            [69.007218211447366, 55.313882202467653],
            [68.997345167669266, 55.351936042990417],
            [68.968318133977391, 55.364154008007347],
            [68.942618212941284, 55.368600104740096],
            [68.926018188932062, 55.426173218518386],
            [68.947618185837598, 55.442627062135728],
            [69.069445125519564, 55.421664257511509],
            [69.169982198714308, 55.393464182389152],
            [69.185527108749056, 55.34388220748275],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "KEN", Country: "Kenya" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [41.905163355793974, 3.980318018171047],
            [41.896436118183175, 3.96425393305249],
            [41.797773238543613, 3.824718043568168],
            [41.336936200670294, 3.167500054544092],
            [41.28638226026888, 3.118336001331201],
            [40.991354187456778, 2.835535951819594],
            [40.988609114159118, 2.716664135929477],
            [40.988609114159118, 2.309300120065487],
            [40.988609114159118, 1.313953976704596],
            [40.988609114159118, 0.000000125728491],
            [40.998327092727919, -0.866108972608529],
            [41.153882307967734, -1.068055004568976],
            [41.381382248210656, -1.364717883781097],
            [41.555273211974253, -1.592217824023834],
            [41.558154227735855, -1.674872846622847],
            [41.560135078139098, -1.672981193209026],
            [41.579375539000097, -1.694221649999861],
            [41.585349539000191, -1.696264649999975],
            [41.591574539000106, -1.701264649999956],
            [41.608704539000115, -1.714766649999973],
            [41.609655539000101, -1.715538649999985],
            [41.940962539000083, -1.963611649999919],
            [42.0756595390001, -2.054833649999921],
            [42.106690539000084, -2.07586864999989],
            [43.191495540000147, -2.841504651000022],
            [44.278013541000206, -3.606232650999885],
            [44.247322082000011, -3.649524682999953],
            [44.084081541000131, -3.879784651999969],
            [44.08296956725016, -3.880915735499897],
            [44.067114541000109, -3.896878652],
            [44.040068541000011, -3.935709651999986],
            [44.03841354100021, -3.937372652],
            [44.036676541000105, -3.939866652000035],
            [44.035996620000077, -3.940549829],
            [44.035554198500193, -3.941027868499972],
            [44.004201301000109, -3.972554442999865],
            [43.849013541000119, -4.128434651999953],
            [43.841896541000125, -4.138650651999853],
            [43.833954445666819, -4.146635299999971],
            [43.831730541000155, -4.148872652],
            [43.812057541000087, -4.177107651999989],
            [43.401787540000015, -4.588954651999984],
            [43.401351776666701, -4.589281855666542],
            [43.348349046000209, -4.626546818999969],
            [43.319962111000137, -4.654994514999984],
            [43.318787470000217, -4.656140787999874],
            [43.317067908000155, -4.65786591799997],
            [43.297775244000178, -4.671420979999937],
            [43.292710238500121, -4.67642686949992],
            [43.288468942000151, -4.68060506],
            [43.288066424000164, -4.681008723499957],
            [43.192472232000142, -4.68101144799995],
            [39.605, -4.681111],
            [39.477778, -4.891999999999896],
            [39.349443898500255, -4.832221885999928],
            [39.203027195246676, -4.669617825753932],
            [39.161663341397258, -4.639445991477061],
            [39.037773280061998, -4.549999852594951],
            [38.721109168160666, -4.322499912352029],
            [38.624445204795933, -4.253335799916343],
            [38.48666331237493, -4.154999982139529],
            [38.417500205767652, -4.105835928926638],
            [37.851391157582356, -3.700554983645617],
            [37.78305417135428, -3.651108963209111],
            [37.764854042024325, -3.615282026728124],
            [37.756382284823161, -3.578608853300125],
            [37.750000136089483, -3.550690913039659],
            [37.737354191095363, -3.528754969454084],
            [37.686391213817927, -3.512499944580497],
            [37.626382151332024, -3.509517831060464],
            [37.600000278651777, -3.450281915325704],
            [37.600136065483611, -3.424717948759522],
            [37.610273306850303, -3.401526898988834],
            [37.690554169860576, -3.316663984775843],
            [37.719991246257024, -3.311945979103299],
            [37.712491286912694, -3.267499931562952],
            [37.698054129204962, -3.186664018922784],
            [37.675282174594287, -3.051390997767371],
            [37.602773179867967, -2.995835911003297],
            [37.522500195846732, -2.949163965249468],
            [37.501245198076987, -2.938054926025927],
            [37.425282188680484, -2.906945995217484],
            [37.278609108622419, -2.825555032947506],
            [37.222582288346274, -2.794454986956566],
            [37.195827085697516, -2.778890966182601],
            [36.685273291914285, -2.49694603843551],
            [36.325554025489367, -2.298335836310102],
            [36.153327204786905, -2.199999850895296],
            [36.033282257725119, -2.13308192087878],
            [35.880827173202334, -2.048335850396299],
            [35.678327097440359, -1.935835789679885],
            [35.268882190288224, -1.707217871188419],
            [34.717218178799754, -1.398890903122847],
            [34.500273233978959, -1.277781957268957],
            [34.409718169303716, -1.227217958583807],
            [34.075554152265653, -1.039163925346998],
            [34.018745132783039, -1.001108911357747],
            [33.948327087628883, -1.001108911357747],
            [33.921100151468039, -1.001945928211171],
            [33.923336107965241, -0.953054957404476],
            [33.918609217475279, -0.452781903170774],
            [33.951663252740076, -0.271808901756287],
            [33.976100188602089, -0.13416396963369],
            [33.966382210033402, -0.100835846134387],
            [33.950482242579284, -0.04813580323804],
            [33.936836168895439, 0.000000125728576],
            [33.923045088286216, 0.048609129311657],
            [33.912500151147896, 0.082500013791702],
            [33.907218211028038, 0.103053949177934],
            [33.981809102870812, 0.205073110496372],
            [34.053600271407248, 0.301664151303342],
            [34.106382289316883, 0.387782171701275],
            [34.163600178037399, 0.608473156699674],
            [34.213463281977198, 0.632917971550739],
            [34.248600220186717, 0.639444959571733],
            [34.269163208028345, 0.648473107507101],
            [34.410827095096948, 0.821944976110785],
            [34.442218160766998, 0.871945040348706],
            [34.480136214457929, 0.959164107550947],
            [34.485554109047627, 0.984027011733701],
            [34.483118160339899, 1.011664158237025],
            [34.487773301738486, 1.043191178376986],
            [34.504300235551881, 1.080835982023657],
            [34.520000210795644, 1.105836014142625],
            [34.575545071637663, 1.092682125814633],
            [34.597145068543142, 1.150482053893313],
            [34.69166326468897, 1.208127084400957],
            [34.739718224470636, 1.214164066360894],
            [34.764718088951412, 1.216945014204285],
            [34.801663338957297, 1.221944987100443],
            [34.820345092444853, 1.235973107932153],
            [34.829718239516041, 1.269164103495058],
            [34.82888222849104, 1.295000146639055],
            [34.822082158063864, 1.315691042323493],
            [34.803745068435688, 1.335000097446581],
            [34.792218107518551, 1.393609046822064],
            [34.795682180472539, 1.398554034433275],
            [34.853191256510257, 1.428053975103609],
            [34.863882206402394, 1.459445040773659],
            [34.874300241526242, 1.506109107538478],
            [34.885273326280043, 1.530836057251236],
            [34.900409199438485, 1.55305396806051],
            [34.918191239436766, 1.562918127021007],
            [34.94346334813369, 1.568891071240586],
            [34.959163323377396, 1.587218102584842],
            [34.999718156176726, 1.670282092733274],
            [35.009718101969071, 1.895282046528024],
            [34.994436216056869, 2.085000055187876],
            [34.94193616537089, 2.223891041040105],
            [34.919163204931777, 2.277782152381036],
            [34.887773312728228, 2.357218119548847],
            [34.881100144315354, 2.378335989382308],
            [34.880200263187959, 2.403400059241704],
            [34.909436173907494, 2.521109137520128],
            [34.843463219657764, 2.597782094117562],
            [34.792500242380214, 2.680418104288577],
            [34.789300199377038, 2.70069107827959],
            [34.781663279734488, 2.78958216753189],
            [34.755554154184125, 2.849927009059883],
            [34.712218205903497, 2.8780539939862],
            [34.685618236101931, 2.86389109215115],
            [34.665482222409224, 2.860973184009552],
            [34.59340020183194, 2.936736033557523],
            [34.582500207273966, 3.007218116452208],
            [34.572354081089856, 3.082918101726406],
            [34.561663298835896, 3.106809040414049],
            [34.545000242914597, 3.127500103736566],
            [34.508745158818186, 3.145282143734818],
            [34.453891134437839, 3.245554013512475],
            [34.403745057446116, 3.385282183856205],
            [34.403400225948559, 3.48416416644541],
            [34.435482295718231, 3.500073186355039],
            [34.453473212744342, 3.521109081175041],
            [34.46500017366148, 3.614444926055697],
            [34.463609113006527, 3.664654034959455],
            [34.381663268744859, 3.731664165911127],
            [34.361109165720535, 3.738054025995737],
            [34.222218179868406, 3.779164076062983],
            [34.184718215508866, 3.840000097118335],
            [34.128327117719863, 3.951809153735027],
            [34.088054084506126, 4.097218075152753],
            [34.063045167569754, 4.173609064526573],
            [34.049445194353467, 4.19055392003331],
            [34.026245092127368, 4.208053992808033],
            [33.996663344081838, 4.222782170194961],
            [34.096109261118187, 4.320554053524447],
            [34.374163309400586, 4.596664170816922],
            [34.388191262594233, 4.609682104675002],
            [34.407773232485425, 4.610553990245677],
            [34.482218111574497, 4.611109039875672],
            [34.713054049290434, 4.612218133306811],
            [34.773891243812358, 4.612500100530426],
            [34.821391153964129, 4.612218133306811],
            [34.950273309206608, 4.61278206775404],
            [35.146945112398299, 4.613891161185265],
            [35.508882230409512, 4.616735979131107],
            [35.546945123387871, 4.616664230039632],
            [35.580827123050511, 4.616945023796859],
            [35.608454043632065, 4.617744992638166],
            [35.641936058873995, 4.617218106202969],
            [35.675827110992287, 4.617500073426584],
            [35.696109137438697, 4.618053949590006],
            [35.699236090246046, 4.619182153760534],
            [35.709718163110409, 4.617773155832964],
            [35.899718138993791, 4.619718092651226],
            [35.940554100826347, 4.622500046322827],
            [35.95110926388665, 4.521109024830139],
            [36.045300230531637, 4.447082067434494],
            [36.211163342687399, 4.448000053472882],
            [36.271663249424506, 4.439164186396738],
            [36.433609162939348, 4.441664172844909],
            [36.64750024514862, 4.446664145741067],
            [36.666382158484708, 4.433608996232593],
            [36.712154055473064, 4.43383597817126],
            [36.88777320003814, 4.434718089664003],
            [37.039718161932257, 4.375553923020533],
            [37.099163289970846, 4.331664098576482],
            [37.130273226607613, 4.286391092466602],
            [37.280545160623291, 4.19444496713632],
            [37.998054179356615, 3.728609129833146],
            [38.033609206897864, 3.69805407518821],
            [38.056109252568746, 3.651945058053215],
            [38.121109235495311, 3.611664145850483],
            [38.323882226025404, 3.608891076996201],
            [38.4480540869462, 3.599445007367223],
            [38.516936064520195, 3.626945025934262],
            [38.621936165892379, 3.601664200058067],
            [38.729436086074458, 3.567218098310136],
            [38.810000257412895, 3.543336044439727],
            [38.913600245674701, 3.513891089054511],
            [38.956382317792105, 3.518609094727054],
            [39.04610019797596, 3.528891175380977],
            [39.084718140584044, 3.534445024440558],
            [39.130000199149407, 3.510836052976529],
            [39.196936066438724, 3.478609143919442],
            [39.248891293416904, 3.471391151798727],
            [39.295000142913835, 3.475282198901738],
            [39.296136058435394, 3.476500089436556],
            [39.328645102353988, 3.499653923557048],
            [39.331382296662724, 3.469164080118858],
            [39.422491237501362, 3.460000145351572],
            [39.450827099455438, 3.462500131799644],
            [39.499582283430442, 3.45013598639099],
            [39.5020822698784, 3.42263596782395],
            [39.519027125385236, 3.409309077013845],
            [39.560273297560485, 3.406664083640365],
            [39.594991140610063, 3.500836107184213],
            [39.782773264907036, 3.678335983189029],
            [39.86666320779679, 3.869445052503636],
            [40.000000176951374, 3.928582061780617],
            [40.182500193490426, 4.022500113657472],
            [40.388609181493649, 4.098891103031207],
            [40.398891262147771, 4.123053950658743],
            [40.672218252301974, 4.228609101660652],
            [40.783763278967996, 4.287973092876385],
            [40.853609175409787, 4.234718000350043],
            [40.876936179650244, 4.216664219050074],
            [41.009436131951446, 4.108609081600093],
            [41.048191202495929, 4.060417999710353],
            [41.063609210516091, 4.03124495326486],
            [41.093609215531131, 3.996391155745286],
            [41.11471820054723, 3.975973174829051],
            [41.13513618146348, 3.958473102054327],
            [41.160554135276044, 3.945836041877456],
            [41.180973289658681, 3.941527073081019],
            [41.273891212845882, 3.941109151387494],
            [41.469027116095248, 3.949308999648963],
            [41.487636114663275, 3.961109042972311],
            [41.534854057591446, 3.976109129298862],
            [41.611382174901536, 3.98916411116916],
            [41.725827172436226, 4.000136022456502],
            [41.781109176793933, 3.981944945581972],
            [41.800827101155193, 3.960553993342501],
            [41.840136215501019, 3.948473156261926],
            [41.891945094449369, 3.969864108501483],
            [41.905163355793974, 3.980318018171047],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "KGZ", Country: "Kyrgyzstan" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [80.234027198347491, 42.196218113123152],
            [80.246091271621509, 42.175227145304277],
            [80.281582261422244, 42.083145065504098],
            [80.269436213134867, 42.054264212204018],
            [80.245136237571018, 42.03953603481726],
            [80.213872241553446, 42.030791195209801],
            [80.179700228040105, 42.026618013245283],
            [80.139709162049911, 42.025791222313742],
            [80.062191309610142, 42.034682074674734],
            [80.025272378780443, 42.036345044269481],
            [79.97720031991625, 42.034127025044924],
            [79.923600228254344, 42.024954037822241],
            [79.896382344548925, 42.019954064925983],
            [79.873172351677084, 42.013427076905074],
            [79.844427117570746, 41.992036124665432],
            [79.826236208334393, 41.965854076557363],
            [79.81575413547003, 41.92480907005897],
            [79.79164509966111, 41.894882155239699],
            [79.753318176732051, 41.882453972090602],
            [79.722209245923722, 41.881900095927179],
            [79.693736256033247, 41.884818171706868],
            [79.659009192890068, 41.883282104126621],
            [79.526654079876295, 41.831344982059463],
            [79.340818233502119, 41.792173163287927],
            [79.147218230194738, 41.71993607750143],
            [79.059418129462784, 41.682444997959422],
            [78.958600262510714, 41.641618088582263],
            [78.758318205973325, 41.563135981998485],
            [78.726927140303275, 41.554118059984816],
            [78.695527189815806, 41.543009188399267],
            [78.670818177375878, 41.53189109672023],
            [78.531100232954003, 41.463836077715669],
            [78.395536192119465, 41.392864156398005],
            [78.370536160000626, 41.372582129951681],
            [78.363600302741617, 41.353009044877794],
            [78.376645226328009, 41.318282149372735],
            [78.383318227102706, 41.293835993417005],
            [78.369827218627989, 41.274845115339346],
            [78.326382305605676, 41.253554075385964],
            [78.307482287358681, 41.242027114468712],
            [78.290536258385401, 41.22966414252663],
            [78.265272363953699, 41.198000162088377],
            [78.252209167818336, 41.175509168872892],
            [78.246227171143317, 41.165127008294618],
            [78.23664514704447, 41.145500111402285],
            [78.206372227261141, 41.116053982550497],
            [78.188872322124467, 41.101345083540949],
            [78.138736135778601, 41.069253961315766],
            [78.080827242958293, 41.040791197347303],
            [78.05498231499692, 41.0439731354396],
            [78.028736229148336, 41.056336107381767],
            [77.997754200354365, 41.060227154484792],
            [77.860600280121076, 41.069673224113771],
            [77.827763336149218, 41.062445006071172],
            [77.780272310814865, 41.040791197347303],
            [77.684709226616263, 41.001891119877413],
            [77.543591336722073, 40.986609066327262],
            [77.505827174773827, 40.986064242619236],
            [77.474709191510016, 40.99689114698127],
            [77.436372377935214, 41.012727076694958],
            [77.382063344900729, 41.025082169648115],
            [77.338045109699664, 41.027300188872672],
            [77.274436187427995, 41.006336043143861],
            [77.13998224585319, 41.015982104983195],
            [77.109145223984484, 41.027164234402662],
            [77.073318287503554, 41.046535986161629],
            [77.021445203176739, 41.049591189877603],
            [76.921372319781057, 41.027436143342484],
            [76.89721818933296, 41.021045109791302],
            [76.870672366625996, 41.01258206976955],
            [76.791027187154242, 40.950909031860775],
            [76.648391334590912, 40.741264221812131],
            [76.648463251320351, 40.704518125826368],
            [76.644363327189666, 40.619100161983468],
            [76.630127167520612, 40.602644977261534],
            [76.586654091303586, 40.577582080868623],
            [76.569709235796694, 40.562300194956535],
            [76.551091184773298, 40.539382227592043],
            [76.537491211557068, 40.519664135592947],
            [76.526372281687628, 40.498691105046717],
            [76.514154149032692, 40.458900198904942],
            [76.493463253348267, 40.436744984731646],
            [76.450263259537536, 40.404109038798552],
            [76.33440021161968, 40.353236083161377],
            [76.314991244210404, 40.384664196843644],
            [76.223600168510046, 40.403827071574923],
            [76.099427134122692, 40.398282107332776],
            [76.068054173363549, 40.395773236067257],
            [76.045254223196224, 40.388827152886378],
            [75.98346333572843, 40.366600189621678],
            [75.966791227351649, 40.345500089423055],
            [75.943027190678407, 40.314945034778034],
            [75.900127269002127, 40.301191169819006],
            [75.703736091929642, 40.298000179271313],
            [75.688172238793783, 40.312027126636437],
            [75.678863297101174, 40.334936041545447],
            [75.665818205876604, 40.373827066559713],
            [75.62893615542103, 40.53792712919666],
            [75.629836204186489, 40.569800154300665],
            [75.62414522719061, 40.594654006027937],
            [75.604291180721503, 40.62855411060157],
            [75.582836190741517, 40.644527000613365],
            [75.530818267127387, 40.62966420986119],
            [75.486372387225089, 40.608836018602275],
            [75.339709197812766, 40.52653612274942],
            [75.277482283740568, 40.482436080173116],
            [75.233936117327602, 40.450427100599299],
            [75.066091187979367, 40.447573230198245],
            [75.021100316731093, 40.456600204667225],
            [74.991091259260571, 40.465773191889909],
            [74.970963292194966, 40.473827027397576],
            [74.936100274581889, 40.491609067395913],
            [74.907909251915072, 40.50869121847721],
            [74.88233623289338, 40.519245040432949],
            [74.858800183987029, 40.517227013418832],
            [74.845018155833287, 40.503973212804667],
            [74.831936184234763, 40.46355399919905],
            [74.826100200313562, 40.407982148628648],
            [74.845127120574858, 40.392573193063967],
            [74.877200305527282, 40.365209128966754],
            [74.890754178275955, 40.349100116847055],
            [74.880891192781888, 40.327918041635073],
            [74.848872322562244, 40.324927043297535],
            [74.820954214663601, 40.332009080948339],
            [74.760545167757101, 40.344935987337848],
            [74.734282318102174, 40.340764146477611],
            [74.600400189963608, 40.261945087385172],
            [74.552200223256619, 40.22298214564141],
            [74.514709143714498, 40.188264134953769],
            [74.442200316626298, 40.139373164146988],
            [74.414982265282873, 40.122573147927383],
            [74.383609304523787, 40.10770918370882],
            [74.35429125115283, 40.097845024748139],
            [74.32221823383847, 40.092709097382155],
            [74.294845117285917, 40.092709097382155],
            [74.258045209481594, 40.111254058209582],
            [74.225963307349815, 40.117291207807611],
            [74.189282254932976, 40.111736017643565],
            [74.024700232861989, 40.067364233765531],
            [73.990963240124671, 40.042018029044428],
            [73.847827155578358, 39.816944985053752],
            [73.84290027287804, 39.768954063012629],
            [73.862345114832948, 39.745209137078589],
            [73.907409243915282, 39.728200076193346],
            [73.924700272024069, 39.692700201575192],
            [73.954709161856755, 39.599654035269182],
            [73.863727123032419, 39.485209037734407],
            [73.83999124955389, 39.473818031287252],
            [73.78580928617194, 39.467991099821475],
            [73.655682250666388, 39.454827153209592],
            [73.654163282168639, 39.460764222883327],
            [73.524709145851915, 39.473254096840023],
            [73.491363252717775, 39.469927151822318],
            [73.367763372871224, 39.437845082052561],
            [73.357554214775035, 39.404027120130181],
            [73.185527218644864, 39.358273160414839],
            [73.163591275059304, 39.354518067781626],
            [72.862354223633389, 39.36201802712587],
            [72.804982275532012, 39.371318084001231],
            [72.777482256964987, 39.37798219995851],
            [72.688872296745984, 39.397709176775024],
            [72.65734510896803, 39.401318089016442],
            [72.633182261340465, 39.390764099422526],
            [72.598600205122608, 39.365909074228981],
            [72.48441823171035, 39.349654049355379],
            [72.453327238174751, 39.352718137888772],
            [72.424491311875869, 39.358745061564903],
            [72.347763369993601, 39.338400170844423],
            [72.324991247744805, 39.316873096496877],
            [72.314136180188001, 39.286591124258152],
            [72.30636330607561, 39.257144995406364],
            [72.258882339025007, 39.195473130963975],
            [72.139709277533967, 39.274636015725591],
            [72.098318266071516, 39.348254103883122],
            [72.077618150293603, 39.371444986015646],
            [71.971382221113771, 39.347973142487817],
            [71.933872366108602, 39.326854099187969],
            [71.899427270188966, 39.306564193752635],
            [71.86130922428768, 39.288373116878276],
            [71.778518148907352, 39.27763606651844],
            [71.742754076700436, 39.308782212976993],
            [71.732827221103861, 39.333782077457968],
            [71.743872222587157, 39.360873226786978],
            [71.773182229330956, 39.391435992782945],
            [71.775263288257207, 39.417127029001719],
            [71.757763383120476, 39.45584505153407],
            [71.714572274127164, 39.461436116243746],
            [71.681091264713444, 39.453236100344313],
            [71.654436141988839, 39.450191122550208],
            [71.597072240514507, 39.449209098771391],
            [71.56387236013407, 39.454345026137545],
            [71.537900194882099, 39.464409177308383],
            [71.517491266421274, 39.488653999949193],
            [71.518463399554292, 39.508864109666135],
            [71.548809241895384, 39.54761800674423],
            [71.548727266882196, 39.572209169625168],
            [71.514709145111482, 39.604364161952603],
            [71.484554074887143, 39.617973187624401],
            [71.406372375713971, 39.606300046315468],
            [71.326382197106653, 39.56962704052556],
            [71.196091211574497, 39.533218064686523],
            [71.102618238757572, 39.512654071016598],
            [71.073872334098979, 39.489582211909365],
            [71.055818217522841, 39.453473140566771],
            [71.043591200050486, 39.425409187552518],
            [71.028036231732102, 39.410945040116374],
            [70.99414517961398, 39.400936041868576],
            [70.848600303726442, 39.410935987660977],
            [70.721100156683377, 39.425373145368795],
            [70.694700179092166, 39.489264202502042],
            [70.672672369847504, 39.507045069033879],
            [70.671645083791446, 39.518154108257519],
            [70.661372223230927, 39.549418104275176],
            [70.632836201428432, 39.579773166709757],
            [70.507491209335882, 39.607054082327224],
            [70.482072249694994, 39.60663599299572],
            [70.356091238787741, 39.582036112935455],
            [70.301927212678692, 39.551509053847056],
            [70.237491331837447, 39.577382145003256],
            [70.181363258170705, 39.584364102729907],
            [69.966091173590513, 39.554436014444249],
            [69.846645197331185, 39.537218076531119],
            [69.824436171339386, 39.545273085505173],
            [69.801091229826085, 39.56220905619459],
            [69.78192718162839, 39.572491136848683],
            [69.750272253645647, 39.581936033011189],
            [69.708036178702656, 39.587773190398948],
            [69.672209242221669, 39.586382129744109],
            [69.609145143657969, 39.582218167872881],
            [69.581236088214808, 39.573464108171933],
            [69.551227198382179, 39.550136098103209],
            [69.516372227396147, 39.538609137185958],
            [69.333254129315236, 39.518809070173504],
            [69.308591217342922, 39.534854044552858],
            [69.30150917969209, 39.560409126301735],
            [69.309700310774105, 39.599300151316001],
            [69.314145234040581, 39.630273127654547],
            [69.314145234040581, 39.659709030584438],
            [69.309982277997761, 39.686800179913561],
            [69.298736110837837, 39.704300085050093],
            [69.262491252663295, 39.733464246678366],
            [69.249500308533442, 39.757635979123236],
            [69.25721819736097, 39.798609068892077],
            [69.267763302137354, 39.838327052476089],
            [69.284991298334177, 39.887218023282671],
            [69.334818192452104, 39.992600171802636],
            [69.407482252387609, 39.937973129360941],
            [69.424845197225949, 39.90505404273766],
            [69.498318278458072, 39.920827108177193],
            [69.524045189222505, 39.934509056406952],
            [69.50554515539622, 39.962627156515751],
            [69.485463288798115, 40.037845014717888],
            [69.499291249781407, 40.072909030369615],
            [69.540745125517816, 40.128882206465207],
            [69.569709294935734, 40.109300068935923],
            [69.605818198640264, 40.111936009853807],
            [69.636936181904076, 40.118882093034799],
            [69.692472325566854, 40.132764201112721],
            [69.817763338202866, 40.170273217927658],
            [70.011936160774695, 40.217991057562685],
            [70.052472385748985, 40.207764129831602],
            [70.285682246088896, 40.132553982980582],
            [70.309563294130925, 40.115673165214091],
            [70.329572238170982, 40.090735997369094],
            [70.351518240040519, 40.082127112231618],
            [70.426654123229326, 40.070182229620954],
            [70.512354221933805, 40.056709158419139],
            [70.542209220023636, 40.046018040888939],
            [70.558245141947481, 40.028100046420789],
            [70.546509136364563, 40.002954001548133],
            [70.517691314976588, 39.98844509474894],
            [70.488591191088886, 39.969200077366381],
            [70.468036249874359, 39.927936135556322],
            [70.494282335722829, 39.908145120999265],
            [70.603045073440995, 39.958236045068062],
            [70.622482204044871, 39.970454010084936],
            [70.639709194413371, 39.985045059535381],
            [70.654300243863901, 40.007545105206347],
            [70.660263297437695, 40.044845077355447],
            [70.663382203617942, 40.100691183798446],
            [70.886109152903288, 40.171318105980461],
            [70.921654122160561, 40.168973184741489],
            [70.957354156627076, 40.174800116207265],
            [70.985672248946372, 40.189935989365651],
            [70.994982196467475, 40.228618137352257],
            [70.982036179338678, 40.244844999031145],
            [70.99567236237678, 40.267645116836533],
            [71.067900228069817, 40.277573145899495],
            [71.0941451404519, 40.277991067592993],
            [71.13916333670457, 40.277436017963097],
            [71.246645151975855, 40.287164222453768],
            [71.299145202661833, 40.30993600942638],
            [71.389982234560904, 40.301882173918713],
            [71.47109122960714, 40.268764100913572],
            [71.484700255278966, 40.238609030689261],
            [71.505554095161557, 40.223818156666439],
            [71.524154208912165, 40.215482186297081],
            [71.71422710735331, 40.147918011544007],
            [71.782763247601423, 40.17438219451374],
            [71.807691195352817, 40.193745061455402],
            [71.903591232060108, 40.250491216663946],
            [72.043872272738753, 40.266327146377549],
            [72.012772226747728, 40.334373112926514],
            [72.112072298668551, 40.432300061465313],
            [72.179563383225769, 40.462027151712292],
            [72.206936164502252, 40.464945059853889],
            [72.227054073284052, 40.464545075433207],
            [72.258045154533505, 40.465491057028302],
            [72.283109224392916, 40.44722705759601],
            [72.345263383545415, 40.409373209283487],
            [72.393872387128425, 40.392709147533893],
            [72.420536227032443, 40.387854013924951],
            [72.432754192049316, 40.404644977689046],
            [72.446363385359092, 40.433818024134538],
            [72.453045103313144, 40.458682101783708],
            [72.434072330146336, 40.488053966973141],
            [72.399718261695483, 40.50716420335236],
            [72.379972341777687, 40.526191123613742],
            [72.373645178328928, 40.602782105197932],
            [72.404609269850056, 40.616436057870786],
            [72.591172330145781, 40.52145400720191],
            [72.629154086301099, 40.513409056511662],
            [72.653882209480315, 40.519373115913837],
            [72.673591249024042, 40.535627134958943],
            [72.710536163753744, 40.584936027459136],
            [72.805882324297471, 40.675073170440967],
            [72.851927136054059, 40.688827035399996],
            [72.878036093966244, 40.693264247315412],
            [72.943591294160711, 40.712718141725802],
            [73.10414514119222, 40.77507313127883],
            [73.147218232988649, 40.799664126521762],
            [73.167545186436172, 40.829109081907077],
            [73.152482235835549, 40.847164204311596],
            [73.049145104058056, 40.868273189327638],
            [72.899427213843893, 40.867718139697828],
            [72.72553625008041, 40.869100147897271],
            [72.702209245839896, 40.87049120855211],
            [72.659991275807812, 40.879109146145069],
            [72.621300243003788, 40.89480006893335],
            [72.605809144787855, 40.927854104197976],
            [72.579709239331038, 40.95382710764035],
            [72.503327134774651, 41.016745025812284],
            [72.395263280145258, 41.040218042806501],
            [72.319154090357046, 41.032153981376936],
            [72.195482293781055, 41.006591188277227],
            [72.215472294720058, 41.052336095537257],
            [72.181091236540823, 41.192718054330427],
            [72.150191182760153, 41.203973106307771],
            [72.111436112215785, 41.196473146963527],
            [71.909009294287756, 41.189453973586765],
            [71.888327115782687, 41.200636103006261],
            [71.889854130907423, 41.24606417432544],
            [71.896236112003095, 41.274809073155623],
            [71.89443618211007, 41.302445046192574],
            [71.888318230965268, 41.331053990552832],
            [71.866782271800304, 41.358000132956548],
            [71.68830925683406, 41.55626416247982],
            [71.645400282702354, 41.554873101824981],
            [71.619418226804584, 41.504118163384888],
            [71.625954099642883, 41.484245006176494],
            [71.650054082996377, 41.462864112220743],
            [71.659772229203242, 41.428764183074847],
            [71.651518233847156, 41.397309079664339],
            [71.618045103422531, 41.333564202922801],
            [71.598454081075857, 41.315782162924549],
            [71.57595420304304, 41.301754042092838],
            [71.545682289088035, 41.302373129463106],
            [71.502072252572788, 41.318145021436251],
            [71.447827257278703, 41.350364219142406],
            [71.434418223817346, 41.332591063961488],
            [71.439145114307308, 41.257454007306208],
            [71.442200150385275, 41.230227071145364],
            [71.442200150385275, 41.19578214286382],
            [71.440391168036825, 41.143144964241444],
            [71.423591319455312, 41.120845078418895],
            [71.397218163954307, 41.12550005217939],
            [71.245472356080228, 41.194600126874732],
            [71.208118236836441, 41.186682078198899],
            [71.156936156609419, 41.161054073892259],
            [70.988309195692779, 41.203564237069841],
            [70.813309138497885, 41.250500045136292],
            [70.792627127630766, 41.330927088538417],
            [70.793445201383037, 41.354953981695971],
            [70.771663317178167, 41.394818145671778],
            [70.711018235877674, 41.470264159278869],
            [70.669700314611163, 41.475854050522187],
            [70.634154171887332, 41.45911807204304],
            [70.601100304260711, 41.443982198884655],
            [70.531245187725318, 41.419118121235513],
            [70.488872320122113, 41.410782150866154],
            [70.474154201019161, 41.412173211521079],
            [70.434982214609562, 41.459254026512937],
            [70.413736101657236, 41.477727070610982],
            [70.369982231682997, 41.499264203242447],
            [70.347763315045398, 41.505518108857146],
            [70.323318164918163, 41.5107911641595],
            [70.292763277911234, 41.515236087425947],
            [70.26639129587673, 41.51468221126261],
            [70.206309143195057, 41.519118082073462],
            [70.180954221294627, 41.541209090868236],
            [70.184291224596137, 41.582609154786084],
            [70.204709205512387, 41.598718166905869],
            [70.251927148440615, 41.614964139324073],
            [70.285263318567019, 41.624964252754452],
            [70.308591328635913, 41.633300055485748],
            [70.474572290350665, 41.716491115286672],
            [70.491645221338359, 41.732045077776732],
            [70.50235410850334, 41.750664134628479],
            [70.516663358368334, 41.785518099786046],
            [70.530818213576339, 41.800100096781179],
            [70.700200216333059, 41.91010017104945],
            [70.75179116579821, 41.912318022635915],
            [70.785263290394511, 41.913573128820943],
            [70.846691241453783, 41.931173113881741],
            [70.851509159412529, 41.970582140513713],
            [70.859427208088363, 42.02704498739412],
            [70.8772092480867, 42.041773164781048],
            [70.912345180467895, 42.045653985962062],
            [70.945118254337359, 42.039545087272685],
            [70.980954075635765, 42.042609175806078],
            [71.222491189165709, 42.14190908008878],
            [71.263736187874457, 42.174545026021988],
            [71.275745108225493, 42.198153997486017],
            [71.275054104125758, 42.204191147084018],
            [71.26388220062816, 42.205653956830346],
            [71.231654118104615, 42.218291184645111],
            [71.172482240110384, 42.249691135132579],
            [71.122545207784498, 42.277600190575797],
            [71.064500192856173, 42.302600055056686],
            [71.033182217382063, 42.298991142815467],
            [70.980536153942268, 42.26204505461935],
            [70.970809290556105, 42.254673170755993],
            [70.962491257459618, 42.254409140805123],
            [70.914291290752629, 42.264691221459216],
            [70.873172355868036, 42.309136095533162],
            [70.883882248861397, 42.323300170834713],
            [70.927754136032632, 42.357191055314743],
            [70.96130018901502, 42.398991103653302],
            [70.955609212019141, 42.427464261181754],
            [71.035536191076432, 42.577473171075027],
            [71.057063265423977, 42.588436029906873],
            [71.169009282338976, 42.631500069247792],
            [71.169772370806214, 42.688645035410573],
            [71.275272368885084, 42.759136003122578],
            [71.424700245248545, 42.804136094464425],
            [71.487763337983921, 42.79359115732602],
            [71.510272268472164, 42.78941814299948],
            [71.578045152615033, 42.764282156410715],
            [71.60346327406549, 42.765391082203948],
            [71.639572345408254, 42.781773176729942],
            [71.65776325464455, 42.792200096671181],
            [71.716518216773721, 42.818445009053377],
            [71.747209225888668, 42.823582109885919],
            [71.82192718738392, 42.826364231195598],
            [71.898036209534126, 42.827473156988745],
            [71.957491228218458, 42.817473211196429],
            [71.989154202828303, 42.809136067360683],
            [72.171645166911873, 42.773573160830395],
            [72.272491197058656, 42.77496422148532],
            [72.296936179547885, 42.773573160830395],
            [72.353318224881406, 42.75385406300289],
            [72.458036191391869, 42.716627013411582],
            [72.621372327371404, 42.685518082603224],
            [72.748745237123813, 42.658009179218666],
            [72.786027272000098, 42.642391178988348],
            [72.815191265990251, 42.603018026901921],
            [72.837136094393173, 42.574754081677398],
            [72.864427235932538, 42.561345048215841],
            [72.883882303809429, 42.554127056095226],
            [72.908600201066776, 42.548300124629449],
            [72.960127112791355, 42.539264265342965],
            [73.019991168351879, 42.550509091398496],
            [73.152627242760985, 42.560364197903581],
            [73.335682308929933, 42.503045055792299],
            [73.335263381407998, 42.453418153884755],
            [73.346509213291853, 42.429327055348679],
            [73.520545183980772, 42.409254073568022],
            [73.532827186738103, 42.430300194310078],
            [73.522518283993833, 42.444800048653789],
            [73.513318139404674, 42.453845128033763],
            [73.4733093037797, 42.503845024633605],
            [73.451100277787958, 42.533291153485479],
            [73.442472281911165, 42.551900152053449],
            [73.433591320195802, 42.584744975014203],
            [73.43414519635914, 42.627453956935753],
            [73.463591325211013, 42.714127026963396],
            [73.494845095306601, 42.748573128711527],
            [73.524291224158475, 42.79704500435831],
            [73.529854125673381, 42.831418015910216],
            [73.539982314584762, 42.930245013214559],
            [73.586382183760776, 43.040245087482802],
            [73.650545149833846, 43.067745106049856],
            [73.713045146312339, 43.084964217429459],
            [73.789700165636845, 43.104409059384366],
            [73.915191170483041, 43.125100122706883],
            [73.93858221246407, 43.148300057294804],
            [74.059418243549743, 43.188009156061369],
            [74.234427185562055, 43.209827082449962],
            [74.293591184567362, 43.216900067645369],
            [74.346100287708765, 43.205518113653525],
            [74.391663307669461, 43.195509115405613],
            [74.575482300495906, 43.126418093165782],
            [74.614009215635321, 43.088436001734365],
            [74.668591331076044, 43.044127082130316],
            [74.745472326510736, 42.996700094536337],
            [74.780818141748085, 42.990654060121003],
            [74.827763337546202, 42.992736124875549],
            [74.864700205648745, 42.986491104078269],
            [74.89332725491991, 42.979127099203836],
            [75.066936251459907, 42.915509124476799],
            [75.183872350625251, 42.868845057711994],
            [75.243318149216151, 42.855236032040281],
            [75.317763363581406, 42.853291095221905],
            [75.562345119629271, 42.834673211836545],
            [75.591936255406353, 42.828427017572764],
            [75.622063330074155, 42.817873195617025],
            [75.671354117663356, 42.807218120270633],
            [75.749154104964845, 42.852873173528494],
            [75.769854220742872, 42.885227152238073],
            [75.785536258713734, 42.919118036718018],
            [75.790600269350335, 42.935691070998942],
            [75.796372383169256, 42.93856421977749],
            [75.844709142536345, 42.945226994630318],
            [75.903172246796174, 42.946754009754969],
            [76.11553614624259, 42.941891164795194],
            [76.240263392069352, 42.936900076716356],
            [76.324909214989447, 42.923982222782357],
            [76.377472297587616, 42.910236069174445],
            [76.424145081531861, 42.899400112356901],
            [76.616927178724922, 42.900227070926448],
            [76.733736208237843, 42.918709167480003],
            [76.819154172080658, 42.95245403920633],
            [76.848727202946833, 42.978353952452878],
            [76.980954072841797, 42.995791160953544],
            [77.138600237653776, 42.967591085831188],
            [77.158036194791265, 42.958845072757427],
            [77.208191324238356, 42.918153950212101],
            [77.268327121100612, 42.916344967863651],
            [77.407209222135435, 42.920791064596571],
            [77.535536160109956, 42.92133605594249],
            [77.668872291074166, 42.915227157253085],
            [77.722209190975633, 42.914954074846989],
            [77.944572365662083, 42.895091143560464],
            [77.965545228570221, 42.886073221546908],
            [77.992900240211924, 42.869809144217911],
            [78.09776338128583, 42.857318096794742],
            [78.120254206863223, 42.862182115221074],
            [78.152482289386654, 42.868845057711994],
            [78.180545236572556, 42.870791167996643],
            [78.4522002046767, 42.887464114563656],
            [78.516372390843316, 42.884682160891956],
            [78.53692716441978, 42.87940005313412],
            [78.670254075290558, 42.82718213730972],
            [79.031382339643216, 42.759964135158683],
            [79.057063317578098, 42.765091177707333],
            [79.10984516784967, 42.786627136872298],
            [79.138600292601751, 42.792736035561788],
            [79.175391315588598, 42.797600053988035],
            [79.205818127114725, 42.783709061092694],
            [79.202218267328902, 42.758153979343845],
            [79.197900246076955, 42.735091172692208],
            [79.199709228425348, 42.71134507329181],
            [79.21527224337072, 42.679118164234708],
            [79.233045230913632, 42.658845022605803],
            [79.2647092113518, 42.632591057768209],
            [79.296654153185301, 42.617318056673341],
            [79.324582319367806, 42.614400148531828],
            [79.360463402943452, 42.602945104344158],
            [79.388045228885659, 42.566345021112099],
            [79.406372260230029, 42.526073161364863],
            [79.423809301092689, 42.490645035838],
            [79.443863339772037, 42.472173165206513],
            [79.469709273561847, 42.465509049249121],
            [79.510272320626285, 42.45884510093002],
            [79.541654166202846, 42.45495405382691],
            [79.578872330976736, 42.453427038702259],
            [79.611927204431737, 42.45773600749861],
            [79.683309168453917, 42.470153964725924],
            [79.713045143518315, 42.47440006924829],
            [79.919436098745138, 42.441336143337864],
            [79.956518141411067, 42.432727090562324],
            [80.115536256138654, 42.301482076808142],
            [80.155545091763514, 42.248564104428439],
            [80.172763364952715, 42.22370019441756],
            [80.192200160280521, 42.209118029784335],
            [80.214427123545278, 42.200791111870487],
            [80.234027198347491, 42.196218113123152],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "KHM", Country: "Cambodia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [102.203237000000144, 8.781882000000166],
            [102.151417064000128, 8.86036466900002],
            [102.075291128000146, 8.977481495000106],
            [101.966958064000124, 9.141445051000119],
            [101.712228968000119, 9.475228004000073],
            [101.603895904000211, 9.624551957000094],
            [101.499032835000065, 9.755706095999983],
            [101.494340024000081, 9.79710893000005],
            [101.493290337000104, 9.806369901999986],
            [101.492351811000077, 9.820519433000015],
            [101.485755027000124, 9.91997480400002],
            [101.482744448000147, 9.964960854000097],
            [101.482463346000173, 9.969161258],
            [101.480212360000138, 10.009671512000025],
            [101.442648910000145, 10.233950138000125],
            [101.442492280000153, 10.234884722000089],
            [101.39296129900012, 10.529363438000061],
            [101.330389756000187, 10.655609620000121],
            [102.194482935000138, 11.110606641000146],
            [102.231875599000062, 11.130220556000125],
            [102.264566956000095, 11.145548712000064],
            [102.428374112000057, 11.218869201000103],
            [102.487612738000138, 11.245153947000119],
            [102.569627049000104, 11.280767095000144],
            [102.595503749000187, 11.292085578000055],
            [102.726855581000081, 11.349209346000052],
            [102.727696259000112, 11.350997491000129],
            [102.745624420000155, 11.388660640000126],
            [102.746027217000147, 11.38951114200006],
            [102.746993882000226, 11.391561785000093],
            [102.747965034000089, 11.393662497000093],
            [102.748723928000089, 11.395312914000044],
            [102.749471896000074, 11.39696332200009],
            [102.749855228000087, 11.397813804000023],
            [102.751156419000182, 11.400714880000166],
            [102.771409253000087, 11.447294410000097],
            [102.773266539000105, 11.485660723],
            [102.772528549000157, 11.495214252],
            [102.76903455600015, 11.54531242600001],
            [102.768309464500049, 11.561046373500076],
            [102.767061102000213, 11.568966739000075],
            [102.764137387000147, 11.593070255],
            [102.785108546000089, 11.600934440000103],
            [102.825740168000124, 11.611420019000107],
            [102.859818303000139, 11.619284204000024],
            [102.884721555000084, 11.625837692000118],
            [102.910935468500128, 11.632391058500062],
            [102.916091261811886, 11.635854035180145],
            [102.924054237300737, 11.652153987234911],
            [102.91539120506863, 11.745691165430472],
            [102.906909221945483, 11.767782174225161],
            [102.898418186367024, 11.778882160993476],
            [102.886918215178099, 11.786945048956454],
            [102.846427252481277, 11.839791104606689],
            [102.717272350108686, 12.166318034364124],
            [102.717209318196552, 12.195553945083674],
            [102.747754146919675, 12.373682128552232],
            [102.787782260922, 12.415853998116688],
            [102.779427179813439, 12.451944964548375],
            [102.633736123534021, 12.602909076130246],
            [102.57011814880687, 12.644791099481978],
            [102.522900205878642, 12.657773158794413],
            [102.50360020321105, 12.685482054389311],
            [102.50680024621434, 12.736945096011624],
            [102.521654152149182, 12.750800046723128],
            [102.523609314889342, 12.756664193839114],
            [102.533809252892098, 12.798400036799151],
            [102.492482279170105, 12.976664174737707],
            [102.417209268045013, 13.116945047778472],
            [102.395136196523055, 13.16166417772493],
            [102.346509255667144, 13.281736114515041],
            [102.350100230635547, 13.29897316316746],
            [102.353045128505386, 13.310273142145945],
            [102.356091279765934, 13.379164172175351],
            [102.359845198932561, 13.496944999545235],
            [102.377200264781976, 13.57389103854878],
            [102.396654159192309, 13.568609098429008],
            [102.439418294036869, 13.56221806487801],
            [102.465818271628081, 13.56194498247163],
            [102.541018192557232, 13.570554035247184],
            [102.563872289819273, 13.580553981039586],
            [102.599018280484444, 13.615136037257528],
            [102.566927158259062, 13.646664230863962],
            [102.5655362652424, 13.681182081703284],
            [102.629436207192981, 13.716944980443827],
            [102.722072330794646, 13.765000107863386],
            [102.762763285701823, 13.850827108582479],
            [102.775545185165896, 13.88860904016552],
            [102.78470928757136, 13.923473063607005],
            [102.79525422470968, 13.94139105807507],
            [102.809418300011231, 13.95555395991029],
            [102.858036188411717, 13.993053924269631],
            [102.902482235952135, 14.036664128423112],
            [102.939700233087962, 14.145691063729956],
            [102.935391264291439, 14.168609031094448],
            [102.940872358431164, 14.18353602722506],
            [102.962772259833088, 14.206391130315595],
            [102.984836278899564, 14.211245090458036],
            [103.023800226471707, 14.227982074765421],
            [103.036327148440506, 14.238154017211443],
            [103.063736139538861, 14.272427116477459],
            [103.094645245774956, 14.291126974876093],
            [103.143882221545624, 14.317217995515378],
            [103.183863396890075, 14.330553938780795],
            [103.244263391341036, 14.335291055192826],
            [103.273882355036761, 14.353473079611675],
            [103.328327174903023, 14.358400129950141],
            [103.379009190785354, 14.357426990988742],
            [103.471227225055344, 14.373609093304381],
            [103.513182338603116, 14.399444968810471],
            [103.564563405212056, 14.423053940274499],
            [103.659145136184151, 14.447773178636325],
            [103.693727192402008, 14.438535986035092],
            [103.722763278549309, 14.390273155053976],
            [103.922209324061583, 14.339164165022922],
            [104.036027187598989, 14.347844966889866],
            [104.045000182611517, 14.354473208301314],
            [104.056091284562314, 14.359445018002859],
            [104.123727208406791, 14.384582178058125],
            [104.283582339987646, 14.407773227828713],
            [104.423027202003283, 14.376664129382263],
            [104.458554233987826, 14.36616411924517],
            [104.475336145296438, 14.357145023765028],
            [104.553036220311839, 14.36277313648695],
            [104.583736281882096, 14.369373214703884],
            [104.605400316528033, 14.398891092647048],
            [104.644145161150533, 14.429582101761795],
            [104.809009318083127, 14.447636050699927],
            [104.889709276253285, 14.419445028033195],
            [104.91829123088533, 14.401245066341417],
            [104.970836208572479, 14.393327017665584],
            [105.003354137308776, 14.377326970287285],
            [105.001391263217585, 14.346382157143452],
            [104.998454244336671, 14.31735394998519],
            [105.009427161452408, 14.288891186016698],
            [105.041172278713617, 14.238473200085267],
            [105.082136148389083, 14.21895392682994],
            [105.121372340177089, 14.230827060349142],
            [105.148245224746773, 14.249927070806578],
            [105.16470024183073, 14.302500044050433],
            [105.193145236164384, 14.349653949238117],
            [105.210600214299774, 14.349645064420699],
            [105.209163388453504, 14.288682141350947],
            [105.28297241636588, 14.178264145389093],
            [105.374772361304366, 14.106527123947544],
            [105.439700259863372, 14.115273137021333],
            [105.495954229716091, 14.13513606830783],
            [105.559127293021419, 14.168327063870819],
            [105.733263343634491, 14.110927120212764],
            [105.784563273420559, 14.076318074266496],
            [105.793454125781665, 14.032918088245438],
            [105.808027237959294, 14.016945030595551],
            [105.88860029411515, 13.950553987014032],
            [105.910282266033818, 13.932845037211635],
            [105.947200191035137, 13.926664221792876],
            [106.056636163218144, 13.930000051627886],
            [106.111100261461814, 13.945827096524155],
            [106.174218171844103, 14.058609124464098],
            [106.167627146082651, 14.081391137358693],
            [106.119427179375776, 14.135273196244199],
            [106.034563426972369, 14.23687309476459],
            [105.996227283949821, 14.331664205678592],
            [106.000818219969943, 14.36721805975337],
            [106.099845209484471, 14.38138213505492],
            [106.14012729515369, 14.378473111730713],
            [106.169491281354141, 14.365826999098445],
            [106.221927294299775, 14.381664102278521],
            [106.239418314618973, 14.413609044111908],
            [106.247209293642328, 14.448191100329851],
            [106.251927299314872, 14.488882055237085],
            [106.290282217800694, 14.481364158620025],
            [106.305263361026022, 14.459718061246903],
            [106.322627144054678, 14.448473067553365],
            [106.408736279635349, 14.458054085823946],
            [106.432209296629566, 14.485553936752993],
            [106.431372279776127, 14.508053982423874],
            [106.474563388769553, 14.564164118817573],
            [106.534491314432302, 14.597391156564399],
            [106.60080926781788, 14.509445043078628],
            [106.628863330186419, 14.465973140328188],
            [106.703327152376716, 14.435000163989642],
            [106.752618275242213, 14.409418092512553],
            [106.765263382045958, 14.388891146854647],
            [106.803454182867057, 14.340973147371287],
            [106.850263424195276, 14.303891104705357],
            [107.118445266890006, 14.403891065543107],
            [107.169145220045067, 14.438891043454575],
            [107.234154255427057, 14.497218025606443],
            [107.253600270848352, 14.53110907772448],
            [107.347491332996896, 14.595273217264108],
            [107.473036149661624, 14.640000058561597],
            [107.546600258362588, 14.708618006184778],
            [107.553309301321036, 14.701800166122936],
            [107.558454113504609, 14.65708220964278],
            [107.533245204357883, 14.550135997985862],
            [107.50443626778727, 14.482218106917784],
            [107.480818243867731, 14.435554040153065],
            [107.45671826051418, 14.422218096887562],
            [107.432263387379209, 14.435036038535387],
            [107.409145260166468, 14.420273160069186],
            [107.375454200258702, 14.337354009208084],
            [107.389145200943659, 14.292427008062091],
            [107.384709330132722, 14.240553923735447],
            [107.360682269337104, 14.164717983991338],
            [107.344082245327769, 14.128400035621084],
            [107.370527149920207, 14.020417988366958],
            [107.383045187071588, 14.00458205865327],
            [107.473500339460855, 13.931709121690162],
            [107.470263416083441, 13.841391097237207],
            [107.464191230130183, 13.794364094064008],
            [107.588591246456104, 13.595273105971017],
            [107.62924515335115, 13.538036106511072],
            [107.636382343924964, 13.381664158623607],
            [107.606463308094732, 13.311335967471564],
            [107.483318230315746, 13.020553999181757],
            [107.492482332721096, 12.952500153643868],
            [107.509427188227932, 12.884164173244187],
            [107.51970926888194, 12.864164114021293],
            [107.563182345098966, 12.810827046481776],
            [107.593045222177778, 12.603882047453482],
            [107.595263409040427, 12.554445079472387],
            [107.549909266107505, 12.35930012376754],
            [107.534145253123285, 12.338609060444924],
            [107.438936220515842, 12.254544941606937],
            [107.423391310481264, 12.25349116873673],
            [107.397772358630021, 12.287773152819966],
            [107.378309244126143, 12.312773184939019],
            [107.353727301338864, 12.3318001052005],
            [107.296372284681837, 12.322773130731335],
            [107.238591299704382, 12.30360908253374],
            [107.189972405475402, 12.287218103190156],
            [107.162763406587459, 12.27374503198844],
            [107.110809185437716, 12.215827086712579],
            [107.081936211126646, 12.177773078551724],
            [107.052754112225642, 12.139444982156178],
            [106.974709205712685, 12.082500175841915],
            [106.955836177194016, 12.074718081635893],
            [106.907345190808002, 12.064653930465141],
            [106.85693625733191, 12.067218122291564],
            [106.789200253563337, 12.073464148917438],
            [106.747100300728249, 12.000000120140712],
            [106.726082343180991, 11.973818072032572],
            [106.698582324613852, 11.962773070549289],
            [106.628682281077232, 11.971527130250252],
            [106.445382295696874, 11.982082125672378],
            [106.419545246724482, 11.972636056043484],
            [106.421172341773314, 11.96535402618224],
            [106.461354179880431, 11.885000072976126],
            [106.448582338700163, 11.817218136377861],
            [106.437736155960835, 11.793608997275754],
            [106.448300203838443, 11.709718048557605],
            [106.45820928980018, 11.665864098659284],
            [106.417191273030113, 11.67610913130126],
            [106.303054226618968, 11.681736070556695],
            [106.26864517288314, 11.717773225169822],
            [106.083163378099954, 11.773054056061127],
            [106.043372304320172, 11.776245046608921],
            [106.022263319304244, 11.759854067265607],
            [106.014282238716277, 11.720554005375035],
            [105.961282291323386, 11.644791155827065],
            [105.926363282596895, 11.651944942569159],
            [105.898172259930135, 11.667364124055709],
            [105.875100233185009, 11.670417986667303],
            [105.85314517886016, 11.661945055999553],
            [105.816436130886501, 11.617454081458064],
            [105.819072407080654, 11.594009060020568],
            [105.878645107685799, 11.548953983393773],
            [105.900118202576806, 11.435344996884211],
            [105.883036219133686, 11.364164198538873],
            [105.871136263524164, 11.296735978255597],
            [105.929963310020895, 11.220273072152409],
            [105.948582366872557, 11.202500084609568],
            [106.126454231741519, 11.086773158799531],
            [106.155518313445526, 11.093891070996165],
            [106.189400313108166, 11.053682075522801],
            [106.212400255485846, 10.972500157918574],
            [106.209027210000585, 10.97009103130118],
            [106.18662724425397, 10.975000144366746],
            [106.152263285157204, 10.968709023101653],
            [106.145663374578447, 10.919445057602672],
            [106.176645235734298, 10.870553919157899],
            [106.203309243276323, 10.77055395832005],
            [106.082200297422418, 10.802773156026205],
            [105.959972367491588, 10.882500142873042],
            [105.858309269420914, 10.916664109759381],
            [105.781354177961958, 11.020973207031744],
            [105.746091343566121, 11.019300011515298],
            [105.722054224486584, 11.013053984889524],
            [105.694409198994236, 10.996109129382603],
            [105.635527167212473, 10.970000171470403],
            [105.588527153767501, 10.959709038360899],
            [105.554691254572475, 10.953053974859102],
            [105.527763384717758, 10.94721799093783],
            [105.524291265136668, 10.945764233647012],
            [105.430936141878618, 10.965764125231914],
            [105.372191238033253, 10.90055392417311],
            [105.361909325017308, 10.880691160524592],
            [105.300245171925866, 10.875554059692135],
            [105.233854128344518, 10.889718134993757],
            [105.206091253293181, 10.899444998379963],
            [105.191745123053948, 10.906117999154574],
            [105.100800299879978, 10.954300028588634],
            [105.06108231629608, 10.920273189638777],
            [105.049954166333038, 10.906936072906689],
            [105.038918217305337, 10.881700174031735],
            [105.04913626021883, 10.843891085082277],
            [105.06108231629608, 10.823609058635952],
            [105.09371826222926, 10.746527065162411],
            [105.094145236378182, 10.713191062674099],
            [105.059972384674353, 10.693473138312996],
            [104.982609262167699, 10.653473187505469],
            [104.954409187045343, 10.631245050774396],
            [104.939136185950503, 10.611945048106719],
            [104.929691289788167, 10.593609131944959],
            [104.918300283340756, 10.576945070195265],
            [104.894427281925999, 10.544164117336763],
            [104.877882243201697, 10.530418131366744],
            [104.850527231559994, 10.521391156897764],
            [104.80107232630607, 10.520000096242924],
            [104.770518277489515, 10.521945033061186],
            [104.733036250402819, 10.525000069139168],
            [104.715472307525744, 10.528482079365986],
            [104.701682232744901, 10.531182058024413],
            [104.669827144913796, 10.536944951749661],
            [104.594827216194886, 10.532709073149164],
            [104.566227156651877, 10.50180901936848],
            [104.548572353944195, 10.465209103774598],
            [104.487745217706248, 10.422773204259258],
            [104.445327628137505, 10.422736120056797],
            [104.434866116000165, 10.406499575000083],
            [104.220500502000135, 10.369494198000154],
            [104.201500140000093, 10.371214991999977],
            [104.181096939000014, 10.373171091000088],
            [104.161755041000077, 10.400602018000086],
            [104.153443690000103, 10.412806076000081],
            [104.151816102000083, 10.415259935000165],
            [104.1433931400002, 10.428662014000167],
            [104.133154881000138, 10.454998939000149],
            [104.130679786500224, 10.461552642000086],
            [104.102500377000155, 10.469979305],
            [104.089214114000043, 10.47308187500002],
            [104.086298323000193, 10.474253174000125],
            [104.085370846000188, 10.47451379200011],
            [104.074818624000187, 10.476868944000017],
            [104.072997606000143, 10.477904831000018],
            [104.061932035000183, 10.483989483000045],
            [104.056609547000079, 10.487183070000142],
            [104.051651165000152, 10.489628038000021],
            [104.032969729000143, 10.497512329000088],
            [104.031027379000108, 10.498288597000013],
            [104.026093563000103, 10.500370605000157],
            [104.024451468500132, 10.500901811000134],
            [104.018729024000123, 10.503107543000127],
            [104.016847109000111, 10.503755381000161],
            [104.011482955000105, 10.505649631000139],
            [103.99800134900002, 10.508397262000102],
            [103.990536895000133, 10.510050535000119],
            [103.986642507000141, 10.510797653000026],
            [103.985738679000207, 10.510849678000156],
            [103.983349300000128, 10.510849575000151],
            [103.977352762000038, 10.511091064000055],
            [103.957792254000225, 10.512498716],
            [103.951265703000132, 10.51249261800011],
            [103.946545416500129, 10.511839356000067],
            [103.945198915000077, 10.511421648000052],
            [103.91485720700021, 10.502983142000133],
            [103.905997338000049, 10.50090868000018],
            [103.897752817500219, 10.48924670100017],
            [103.897987408000148, 10.484624747000026],
            [103.898330050000055, 10.479429640000063],
            [103.899360286000132, 10.4634668140001],
            [103.897713548000155, 10.454418886000028],
            [103.89405157600018, 10.43713726300011],
            [103.885955304000078, 10.429117789000131],
            [103.880357485000019, 10.424545821000123],
            [103.874924640000103, 10.41988463700001],
            [103.856002813000174, 10.408396927000041],
            [103.853194558000183, 10.40680937600014],
            [103.849147182000053, 10.405257400000067],
            [103.843502045000065, 10.402910408000125],
            [103.83776460500016, 10.401152484000065],
            [103.828075855000094, 10.39118884700008],
            [103.821003723000075, 10.389759390000108],
            [103.819000240000122, 10.389195294000061],
            [103.8104972900002, 10.387399071000033],
            [103.803451070000193, 10.385167115000016],
            [103.801502238000154, 10.384101109000099],
            [103.789820344000105, 10.377311580000011],
            [103.785019784000184, 10.374398474000031],
            [103.783932854000142, 10.373747956000031],
            [103.773223878000209, 10.367530710000139],
            [103.730960903000124, 10.354437758000046],
            [103.725970013000079, 10.352437446000096],
            [103.723669036000189, 10.351540434000128],
            [103.658255053000204, 10.324289591000024],
            [103.603500228000115, 10.232065398000145],
            [103.558767364000033, 10.155601515000171],
            [103.567353556000086, 10.107604937000048],
            [103.578804868000162, 10.009146583000117],
            [103.57387674400016, 9.998675704],
            [103.571842666000151, 9.965125233000109],
            [103.562640371000185, 9.798844373000023],
            [103.471183415000127, 9.728987106],
            [103.432799121000045, 9.69981212200004],
            [103.402378793000167, 9.676898784000088],
            [103.391483501000181, 9.672103801],
            [103.3553136620001, 9.656470469000041],
            [103.183380778000156, 9.581432823000128],
            [103.160834082000093, 9.571773489000179],
            [103.155498603000154, 9.56963181399999],
            [103.070275443000099, 9.505845087000168],
            [102.976578144000172, 9.435130557000093],
            [102.92685870800014, 9.394925446],
            [102.797473052000129, 9.289605863000091],
            [102.795182484000151, 9.287741623000031],
            [102.792873185000218, 9.285872090000069],
            [102.605756774000128, 9.134379081000077],
            [102.203237000000144, 8.781882000000166],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "KIR", Country: "Kiribati" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-179.999217549999827, -3.739973735000035],
              [-179.999815217999981, -3.741535835999969],
              [-180.0, -1.608414708],
              [-179.999619265999939, -1.609819486],
              [-179.999197313999929, -1.611380274999874],
              [-179.998775357999904, -1.612941064999802],
              [-179.99835339899991, -1.614501853999968],
              [-179.997931435999988, -1.616062643999953],
              [-179.997509469999983, -1.617623432999949],
              [-179.997087499999964, -1.619184221999888],
              [-179.996665526999948, -1.620745009999951],
              [-179.996243549999946, -1.622305798999946],
              [-179.995821569999947, -1.623866586999782],
              [-179.995399586999923, -1.625427374999958],
              [-179.994977599999913, -1.626988162999794],
              [-179.994555608999832, -1.628548950999971],
              [-179.994133615999914, -1.630109738999863],
              [-179.993711618, -1.631670526999983],
              [-179.993289617999977, -1.633231313999943],
              [-179.992867613999948, -1.634792100999789],
              [-179.992445605999933, -1.636352887999863],
              [-179.992023594999921, -1.637913674999936],
              [-179.991601579999923, -1.639474461999953],
              [-179.991179561999985, -1.641035248000037],
              [-179.990757540999908, -1.642596034999883],
              [-179.990335515999959, -1.644156820999854],
              [-179.989913487999956, -1.645717606999767],
              [-179.989491455999939, -1.647278392999851],
              [-179.989069420999925, -1.648839178999879],
              [-179.988647381999925, -1.65039996400003],
              [-179.988225339999985, -1.651960749999944],
              [-179.987803293999974, -1.653521534999925],
              [-179.987381244999966, -1.655082319999849],
              [-179.986959192999933, -1.656643104999773],
              [-179.986537136999942, -1.658203888999935],
              [-179.986115076999909, -1.659764673999859],
              [-179.985693013999906, -1.661325458000022],
              [-179.985270947999965, -1.662886242999946],
              [-179.984848877999951, -1.664447026999767],
              [-179.984426804999856, -1.666007810999929],
              [-179.984004727999917, -1.667568593999931],
              [-179.983582647999981, -1.669129377999923],
              [-179.983160563999974, -1.670690160999925],
              [-179.982738476999941, -1.67225094500003],
              [-179.982316385999923, -1.673811727999919],
              [-179.981894291999907, -1.675372510999921],
              [-179.981472193999906, -1.676933292999934],
              [-179.981050092999965, -1.678494075999936],
              [-179.980627988999942, -1.680054858999824],
              [-179.980205880999932, -1.681615641],
              [-179.979783768999908, -1.68317642300002],
              [-179.979361653999973, -1.684737205000033],
              [-179.978939535999956, -1.686297986999818],
              [-179.978517413999924, -1.687858768000012],
              [-179.978095288999924, -1.689419550000025],
              [-179.977673159999966, -1.690980331],
              [-179.977251027999955, -1.692541111999901],
              [-179.976828891999929, -1.694101892999811],
              [-179.976406751999917, -1.695662674],
              [-179.975984609999983, -1.697223454999914],
              [-179.975562462999875, -1.698784235999824],
              [-179.97514031399993, -1.700345015999972],
              [-179.97471816, -1.701905795999892],
              [-179.974296003999967, -1.703466575999983],
              [-179.973873843999939, -1.705027355999903],
              [-179.973451679999926, -1.706588136],
              [-179.973029513, -1.708148915999914],
              [-179.972607341999975, -1.709709694999901],
              [-179.972185167999925, -1.711270474],
              [-179.971762989999917, -1.71283125299999],
              [-179.971340808999969, -1.714392031999807],
              [-179.970918624999939, -1.715952810999966],
              [-179.970496436999923, -1.717513589999953],
              [-179.970074244999978, -1.719074367999951],
              [-179.969652049999951, -1.720635146999882],
              [-179.969229851999927, -1.72219592499988],
              [-179.96880765, -1.723756702999879],
              [-179.968385443999949, -1.725317480999877],
              [-179.967963234999928, -1.726878258999875],
              [-179.967541022999825, -1.728439035999941],
              [-179.967118806999963, -1.729999813999939],
              [-179.966696586999859, -1.731560590999948],
              [-179.966274363999986, -1.733121367999786],
              [-179.965852137999974, -1.734682144999965],
              [-179.965429907999948, -1.736242922000031],
              [-179.965007673999907, -1.73780369799988],
              [-179.964585436999954, -1.739364474999945],
              [-179.96416319699992, -1.740925250999851],
              [-179.963740952999984, -1.742486026999927],
              [-179.963318704999949, -1.744046802999947],
              [-179.962896453999917, -1.745607579000023],
              [-179.962474199999974, -1.747168354999928],
              [-179.962051941999874, -1.748729130999948],
              [-179.96162968, -1.750289906000035],
              [-179.961207414999961, -1.751850680999951],
              [-179.960785146999939, -1.753411457000027],
              [-179.960362874999987, -1.75497223199983],
              [-179.959940598999964, -1.756533005999756],
              [-179.959518319999916, -1.758093780999843],
              [-179.959096037999984, -1.75965455599993],
              [-179.958673751999953, -1.761215330000027],
              [-179.958251461999907, -1.762776104000011],
              [-179.957829168999979, -1.764336878],
              [-179.95740687299994, -1.765897651999921],
              [-179.956984572999971, -1.767458425999905],
              [-179.95656226899996, -1.7690192],
              [-179.956139961999924, -1.770579972999826],
              [-179.955717650999958, -1.77214074699981],
              [-179.955295336999939, -1.773701519999975],
              [-179.95487301899999, -1.775262292999969],
              [-179.954450697999931, -1.776823065999906],
              [-179.954028373999904, -1.7783838389999],
              [-179.953606044999873, -1.779944610999962],
              [-179.953183713999834, -1.7815053839999],
              [-179.952761377999963, -1.783066155999961],
              [-179.952339039999913, -1.784626927999966],
              [-179.951916696999973, -1.786187699999971],
              [-179.951494351999855, -1.787748471999976],
              [-179.951072001999904, -1.78930924399998],
              [-179.950649649999946, -1.790870014999882],
              [-179.950227292999983, -1.792430786999944],
              [-179.949804932999939, -1.793991557999959],
              [-179.949382570000012, -1.795552328999861],
              [-179.948960202999956, -1.797113099999876],
              [-179.948537831999914, -1.798673870999949],
              [-179.948115457999961, -1.800234641999964],
              [-179.947693080999926, -1.801795413000036],
              [-179.947270699999962, -1.803356182999778],
              [-179.946848314999926, -1.804916953000031],
              [-179.946425926999979, -1.806477722999773],
              [-179.946003535999921, -1.808038493000026],
              [-179.945581139999973, -1.809599262999768],
              [-179.945158741999848, -1.811160033000021],
              [-179.944736339999963, -1.812720802999763],
              [-179.944313933999922, -1.814281572000027],
              [-179.943891523999895, -1.815842341],
              [-179.943469111999917, -1.817403110999919],
              [-179.943046694999964, -1.818963879999842],
              [-179.942624274999929, -1.820524648],
              [-179.942201851999954, -1.822085416999926],
              [-179.941779424999908, -1.823646185999905],
              [-179.94135699399996, -1.825206954],
              [-179.940934559999903, -1.826767722999989],
              [-179.940512122999877, -1.828328490999979],
              [-179.940089681999922, -1.829889258999913],
              [-179.939667236999952, -1.831450026999903],
              [-179.939244788999986, -1.833010793999904],
              [-179.938822336999948, -1.834571561999894],
              [-179.93839988199997, -1.836132329999884],
              [-179.937977422999921, -1.837693096999885],
              [-179.937554959999972, -1.839253863999886],
              [-179.937132494999929, -1.840814630999887],
              [-179.936710024999883, -1.842375397999888],
              [-179.936287551999925, -1.843936164999889],
              [-179.935865074999953, -1.845496931999946],
              [-179.935442594999984, -1.847057697999958],
              [-179.935020111999933, -1.848618463999969],
              [-179.934597623999963, -1.85017923099997],
              [-179.934175133999844, -1.851739996999981],
              [-179.933752638999948, -1.853300762999936],
              [-179.933330140999914, -1.854861528999947],
              [-179.932907639999939, -1.856422294000026],
              [-179.932485134999979, -1.857983060000038],
              [-179.932062625999919, -1.859543824999946],
              [-179.931640113999975, -1.861104590999958],
              [-179.931217598999922, -1.862665356000036],
              [-179.930795078999864, -1.864226120999945],
              [-179.93037255699997, -1.865786886000024],
              [-179.929950029999844, -1.867347649999829],
              [-179.929527500999967, -1.868908415000021],
              [-179.929104966999915, -1.870469178999826],
              [-179.928682429999924, -1.872029944000019],
              [-179.928259888999975, -1.873590708],
              [-179.927837344999915, -1.875151471999914],
              [-179.927414797999944, -1.876712236],
              [-179.926992245999969, -1.87827],
              [-179.926569691999845, -1.879833763999898],
              [-179.926147132999944, -1.881394527],
              [-179.925724570999904, -1.882955290999917],
              [-179.925302005999924, -1.884516053999903],
              [-179.924879436999959, -1.886076817],
              [-179.924456863999978, -1.887637579999989],
              [-179.924034287999916, -1.889198342999805],
              [-179.923611707999953, -1.890759105999962],
              [-179.923189124999823, -1.892319867999959],
              [-179.922766537999934, -1.893880630999945],
              [-179.922343946999945, -1.895441392999942],
              [-179.921921352999988, -1.897002154999939],
              [-179.92149875599992, -1.898562917999868],
              [-179.921076153999962, -1.900123680000036],
              [-179.920653549999969, -1.901684440999929],
              [-179.920230940999915, -1.903245202999926],
              [-179.919808328999949, -1.904805965000037],
              [-179.919385713999986, -1.906366725999931],
              [-179.918963094999924, -1.907927487999928],
              [-179.918540471999933, -1.909488248999764],
              [-179.918117845999888, -1.911049009999942],
              [-179.917695215999913, -1.912609771],
              [-179.917272582999914, -1.914170531999844],
              [-179.916849945999871, -1.915731292000032],
              [-179.916427304999985, -1.917292052999926],
              [-179.916004660999903, -1.918852813],
              [-179.915582012999948, -1.920413574],
              [-179.915159361999969, -1.921974333999913],
              [-179.914736706999975, -1.923535093999988],
              [-179.914314048999842, -1.925095853999892],
              [-179.91389138699995, -1.926656613999967],
              [-179.913468720999958, -1.928217373],
              [-179.913046052, -1.9297781329999],
              [-179.912623378999911, -1.931338891999985],
              [-179.912200701999865, -1.93289965199989],
              [-179.911778021999964, -1.934460410999975],
              [-179.911355338999982, -1.936021169999947],
              [-179.910932651999929, -1.937581928999975],
              [-179.91050996099986, -1.939142687999777],
              [-179.910087266999966, -1.940703445999873],
              [-179.909664568999972, -1.942264204999958],
              [-179.909241866999906, -1.94382496299994],
              [-179.908819161999929, -1.945385722000026],
              [-179.908396452999938, -1.946946479999951],
              [-179.907973740999978, -1.948507237999934],
              [-179.907551024999975, -1.95006799600003],
              [-179.907128305000015, -1.951628754000012],
              [-179.906705581999944, -1.953189510999835],
              [-179.906282855999962, -1.954750268999931],
              [-179.905860124999975, -1.956311025999923],
              [-179.905437390999907, -1.957871784000019],
              [-179.905014654000013, -1.959432541000012],
              [-179.904591912999848, -1.960993298],
              [-179.904169167999868, -1.962554054999828],
              [-179.903746419999948, -1.964114812],
              [-179.903323667999899, -1.965675568999984],
              [-179.902900911999978, -1.967236324999817],
              [-179.902478152999919, -1.96879708199998],
              [-179.90205539, -1.970357837999984],
              [-179.901632623999944, -1.971918593999987],
              [-179.901209853999973, -1.973479349999877],
              [-179.900787080999976, -1.975040105999881],
              [-179.900364302999975, -1.976600861999884],
              [-179.89994152299991, -1.978161617999945],
              [-179.899518737999927, -1.979722373999948],
              [-179.899095949999861, -1.981283128999962],
              [-179.898673158999941, -1.982843884999852],
              [-179.89825036299996, -1.984404639999866],
              [-179.897827564999972, -1.985965394999937],
              [-179.897404761999979, -1.987526149999951],
              [-179.896981955999905, -1.989086905000022],
              [-179.89655914599993, -1.990647659999922],
              [-179.89613633299993, -1.992208414999936],
              [-179.895713515999859, -1.993769169000018],
              [-179.895290695999961, -1.995329923999918],
              [-179.894867870999946, -1.996890678],
              [-179.89444504399998, -1.998451433000014],
              [-179.894022211999896, -2.000012186999811],
              [-179.893599376999987, -2.001572941],
              [-179.893176538999825, -2.003133694999974],
              [-179.892753695999914, -2.004694448],
              [-179.892330850999912, -2.006255201999977],
              [-179.891908000999848, -2.007815955999888],
              [-179.89148514799993, -2.00937670899998],
              [-179.89106229099994, -2.010937461999902],
              [-179.890639430999869, -2.012498215999869],
              [-179.890216566999953, -2.014058968999962],
              [-179.889793698999966, -2.01561972199994],
              [-179.889370827999954, -2.017180474999861],
              [-179.888947952999985, -2.018741226999964],
              [-179.88852507499999, -2.020301979999942],
              [-179.88810219299998, -2.021862732999864],
              [-179.887679306999985, -2.023423485000023],
              [-179.887256417, -2.024984236999842],
              [-179.886833523999911, -2.026544989999763],
              [-179.886410627999908, -2.028105741999923],
              [-179.885987727000014, -2.029666494000026],
              [-179.885564822999839, -2.031227246000014],
              [-179.885141915999924, -2.032787997000014],
              [-179.884719004999909, -2.034348748999832],
              [-179.884296089999935, -2.0359095],
              [-179.883873170999919, -2.037470252],
              [-179.883450248999935, -2.03903100299982],
              [-179.883027323999926, -2.04059175399999],
              [-179.882604393999941, -2.042152505999979],
              [-179.882181460999931, -2.043713256999808],
              [-179.881758524999924, -2.045274006999875],
              [-179.881335583999942, -2.046834757999875],
              [-179.880912639999934, -2.048395508999988],
              [-179.88048969299993, -2.049956258999941],
              [-179.880066740999922, -2.051517009999941],
              [-179.879643786999935, -2.053077759999951],
              [-179.879220827999916, -2.054638509999961],
              [-179.878797865999928, -2.056199260999847],
              [-179.878374899999841, -2.057760011000028],
              [-179.877951930999842, -2.059320759999935],
              [-179.877528957999914, -2.060881509999945],
              [-179.877105980999914, -2.062442260000012],
              [-179.87668, -2.064003010000022],
              [-179.876260015999918, -2.065563758999929],
              [-179.875837028999825, -2.067124507999836],
              [-179.875414036999985, -2.068685257999903],
              [-179.874991041999976, -2.07024600699998],
              [-179.874568043999972, -2.071806756],
              [-179.874145040999963, -2.073367504999908],
              [-179.873722034999957, -2.074928253999815],
              [-179.873299025999955, -2.07648900199996],
              [-179.872849749999915, -2.078341198999851],
              [-179.871863342999916, -2.082175129],
              [-179.819562341999983, -2.660107778],
              [-179.819705377999924, -2.661799372999951],
              [-179.819555854999976, -2.663444602000027],
              [-179.81957343249988, -2.664091956999926],
              [-179.819659957999903, -2.66555476100001],
              [-179.819549379, -2.666771422999943],
              [-179.868231768999834, -3.244848690000026],
              [-179.86864530099993, -3.246410409999896],
              [-179.869058830999876, -3.247972131999973],
              [-179.8694723589999, -3.249533854999981],
              [-179.869885883999984, -3.251095579000037],
              [-179.870299407999852, -3.252657304000024],
              [-179.87071292899995, -3.254219030999934],
              [-179.871126447999956, -3.255780758000014],
              [-179.871539964999982, -3.257342486999903],
              [-179.871953479999917, -3.258904216999895],
              [-179.872366992999929, -3.260465947999876],
              [-179.872780502999944, -3.26202768],
              [-179.873194011999971, -3.263589414000023],
              [-179.873607517999829, -3.265151148000029],
              [-179.874021021999937, -3.266712884000015],
              [-179.874434524999941, -3.268274620999989],
              [-179.874848023999959, -3.26983635900001],
              [-179.875261521999903, -3.271398097999793],
              [-179.87567501800001, -3.272959838999896],
              [-179.876088510999921, -3.274521579999771],
              [-179.876502002999956, -3.276083323000023],
              [-179.876915491999881, -3.277645066999924],
              [-179.877328978999969, -3.279206811999927],
              [-179.877742463999908, -3.28076855799992],
              [-179.878155946999925, -3.282330305999892],
              [-179.878569427999935, -3.283892053999978],
              [-179.878982905999948, -3.285453803999872],
              [-179.879396382999971, -3.287015554999869],
              [-179.879809856999913, -3.288577306999969],
              [-179.880223328999904, -3.290139060999934],
              [-179.880636798999944, -3.291700815000013],
              [-179.881050266999949, -3.293262571000014],
              [-179.881463732999975, -3.294824328],
              [-179.881877196999966, -3.296386085999984],
              [-179.882290659, -3.297947844999896],
              [-179.882704117999907, -3.299509604999969],
              [-179.883117574999943, -3.301071366999793],
              [-179.883531030999961, -3.302633128999958],
              [-179.883944483999954, -3.30419489299976],
              [-179.884357934999969, -3.305756657999837],
              [-179.884771382999986, -3.307318425],
              [-179.885184829999929, -3.308880192],
              [-179.885598274999921, -3.310441960999981],
              [-179.886011716999946, -3.312003730000015],
              [-179.886425157999952, -3.313565500999971],
              [-179.886838595999961, -3.315127272999803],
              [-179.887252031999907, -3.316689046999954],
              [-179.887665465999987, -3.318250820999936],
              [-179.888078897999918, -3.319812596999952],
              [-179.888492327999927, -3.321374373999845],
              [-179.888905755999957, -3.322936152000011],
              [-179.889319180999877, -3.324497930999826],
              [-179.889732604999978, -3.326059710999914],
              [-179.890146025999968, -3.327621492999981],
              [-179.89055944499998, -3.329183274999821],
              [-179.890972861999927, -3.33074505899998],
              [-179.891386276999924, -3.332306843999959],
              [-179.891799689999942, -3.333868630999973],
              [-179.892213100999953, -3.335430417999987],
              [-179.892626509999957, -3.336992207000037],
              [-179.893039915999964, -3.33855399600003],
              [-179.893453320999981, -3.340115786999945],
              [-179.893866723, -3.34167757900002],
              [-179.894280123999835, -3.343239372999847],
              [-179.894693521999926, -3.344801167000014],
              [-179.895106917999925, -3.346362962999933],
              [-179.895520311999945, -3.347924759999898],
              [-179.895933703999873, -3.34948655799991],
              [-179.896347093999879, -3.351048356999968],
              [-179.896760480999973, -3.352610157999891],
              [-179.897173866999964, -3.354171958999984],
              [-179.897587249999987, -3.355733761999886],
              [-179.898000631999906, -3.357295565999777],
              [-179.898414010999915, -3.358857370999885],
              [-179.898827387999916, -3.360419176999869],
              [-179.899240762999938, -3.361980984999775],
              [-179.899654135999924, -3.363542793999841],
              [-179.900067506999932, -3.36510460300002],
              [-179.900480875999875, -3.366666414],
              [-179.900894242999954, -3.368228227000031],
              [-179.901307606999978, -3.36979004],
              [-179.901720969999985, -3.371351855],
              [-179.902134329999967, -3.372913671],
              [-179.902547688999988, -3.374475486999984],
              [-179.902961044999984, -3.376037306],
              [-179.90337439899983, -3.377599124999904],
              [-179.90378775099984, -3.379160945999899],
              [-179.904201100999927, -3.380722766999895],
              [-179.904614448999922, -3.382284589999983],
              [-179.905027794999938, -3.383846413999947],
              [-179.905441138999862, -3.385408239999947],
              [-179.905854479999931, -3.38697006599989],
              [-179.906267819999954, -3.388531893999868],
              [-179.906681156999952, -3.390093721999961],
              [-179.90709449299996, -3.39165555299985],
              [-179.907507825999886, -3.393217384000025],
              [-179.90792115699989, -3.394779215999961],
              [-179.908334486999962, -3.396341049999933],
              [-179.908747813999895, -3.397902884999837],
              [-179.909161139, -3.399464720000026],
              [-179.909574462, -3.401026558000012],
              [-179.909987781999973, -3.402588395999828],
              [-179.910401100999906, -3.404150236000021],
              [-179.910814417999916, -3.405712075999929],
              [-179.911227731999986, -3.40727391799993],
              [-179.91164104500001, -3.408835760999921],
              [-179.912054354999924, -3.410397605999833],
              [-179.912467663999905, -3.411959450999916],
              [-179.912880969999918, -3.413521297999978],
              [-179.913294273999924, -3.415083145999915],
              [-179.913707576999911, -3.416644994999899],
              [-179.914120876999931, -3.418206844999986],
              [-179.914534174999915, -3.419768695999892],
              [-179.91494747099992, -3.42133054899989],
              [-179.915360764999917, -3.422892403],
              [-179.915774055999918, -3.424454257999969],
              [-179.91618734599993, -3.426016114],
              [-179.916600633999934, -3.427577971],
              [-179.917013918999942, -3.429139829999883],
              [-179.917427202999932, -3.430701689999978],
              [-179.917840483999925, -3.432263551],
              [-179.918253763999928, -3.433825412999909],
              [-179.91866704099985, -3.435387275999972],
              [-179.919080316999924, -3.436949140999957],
              [-179.919493589999917, -3.438511006999988],
              [-179.91990686099993, -3.440072873999895],
              [-179.920320129999851, -3.441634741999906],
              [-179.92073339699985, -3.443196610999792],
              [-179.921146661999927, -3.444758482],
              [-179.921559924999912, -3.446320352999805],
              [-179.921973185999917, -3.447882225999876],
              [-179.922386444999916, -3.44944409999988],
              [-179.922799701999907, -3.451005975999806],
              [-179.923212955999929, -3.452567851999959],
              [-179.923626208999906, -3.454129729999806],
              [-179.924039459999904, -3.455691608999985],
              [-179.924452707999905, -3.457253488999982],
              [-179.924865954999831, -3.458815369999968],
              [-179.925279198999988, -3.46037725299999],
              [-179.925692440999825, -3.461939135999899],
              [-179.926105681999985, -3.4635010209999],
              [-179.926518919999978, -3.46506290699989],
              [-179.926932156, -3.466624794999973],
              [-179.927345389999971, -3.468186682999885],
              [-179.92775862299996, -3.46974857299989],
              [-179.928171852999981, -3.471310464],
              [-179.928585080999966, -3.472872355999982],
              [-179.928998306999972, -3.474434248999899],
              [-179.929411530999943, -3.475996143999907],
              [-179.929824752999963, -3.477558038999973],
              [-179.930237971999958, -3.479119935999904],
              [-179.930651189999935, -3.480681834],
              [-179.931064405999933, -3.482243734],
              [-179.931477619999924, -3.483805633999907],
              [-179.931890830999919, -3.485367536000012],
              [-179.932304040999924, -3.486929438999937],
              [-179.932717248999921, -3.488491343000021],
              [-179.933130454, -3.490053247999924],
              [-179.93354365799982, -3.49161515499992],
              [-179.933956858999977, -3.493177062000029],
              [-179.934370058999974, -3.494738970999833],
              [-179.934783255999974, -3.496300880999854],
              [-179.935196450999882, -3.497862793000024],
              [-179.935609644999943, -3.499424705000024],
              [-179.93602283599995, -3.500986618999775],
              [-179.936436024999949, -3.502548534000027],
              [-179.936849212999931, -3.504110449999871],
              [-179.937262397999916, -3.505672366999875],
              [-179.937675580999922, -3.507234285999971],
              [-179.938088761999921, -3.508796205999943],
              [-179.938501940999913, -3.510358125999971],
              [-179.938915117999983, -3.511920048999968],
              [-179.93932829299996, -3.513481971999965],
              [-179.939741465999958, -3.515043896999885],
              [-179.940154636999949, -3.516605821999974],
              [-179.940567805999933, -3.518167748999986],
              [-179.940980972999938, -3.519729676999987],
              [-179.941394137999936, -3.52129160699991],
              [-179.941807300999841, -3.522853536999833],
              [-179.942220461999909, -3.524415469000019],
              [-179.942633621, -3.525977402000024],
              [-179.943046777999967, -3.527539335999847],
              [-179.943459931999968, -3.529101271999764],
              [-179.94387308499995, -3.530663208000021],
              [-179.944286235999954, -3.53222514600003],
              [-179.944699384999922, -3.533787085000029],
              [-179.945112530999921, -3.535349025999949],
              [-179.945525675999903, -3.536910966999869],
              [-179.945938818999906, -3.538472909999882],
              [-179.946351959999987, -3.540034853999884],
              [-179.946765097999958, -3.54159679899999],
              [-179.947178234999967, -3.543158744999914],
              [-179.947591368999866, -3.544720692],
              [-179.948004501999918, -3.546282641],
              [-179.948417632999906, -3.547844591],
              [-179.948830760999982, -3.549406541999929],
              [-179.949243887999984, -3.550968495],
              [-179.94965701199996, -3.552530448000027],
              [-179.950070134999947, -3.554092403000027],
              [-179.950483254999938, -3.555654358999959],
              [-179.950896373999939, -3.557216315999938],
              [-179.951309489999915, -3.558778274999952],
              [-179.951722604999986, -3.560340233999966],
              [-179.952135716999976, -3.561902194999959],
              [-179.952548827999976, -3.563464156999885],
              [-179.952961935999951, -3.56502612],
              [-179.953375042999937, -3.566588084999978],
              [-179.953788146999926, -3.568150049999929],
              [-179.954201249999983, -3.569712016999915],
              [-179.9546143499999, -3.571273984999834],
              [-179.955027447999953, -3.57283595499996],
              [-179.955440544999959, -3.574397925000028],
              [-179.955853638999855, -3.575959896999962],
              [-179.956266731999932, -3.577521869999885],
              [-179.956679821999984, -3.579083843999967],
              [-179.957092910999989, -3.580645819999972],
              [-179.95750599699997, -3.582207795999921],
              [-179.957919080999943, -3.583769773999904],
              [-179.958332163999927, -3.585331752999821],
              [-179.958745243999914, -3.586893733000011],
              [-179.959158323, -3.588455714999839],
              [-179.959571398999969, -3.590017696999951],
              [-179.959984473999953, -3.591579680999871],
              [-179.960397545999939, -3.593141666999884],
              [-179.960810616999908, -3.594703652999954],
              [-179.961223684999908, -3.596265639999899],
              [-179.961636751999976, -3.597827628999823],
              [-179.962049815999961, -3.599389619000021],
              [-179.962462878999929, -3.600951609999754],
              [-179.962875938999929, -3.602513603000034],
              [-179.963288997999911, -3.60407559600003],
              [-179.963702053999981, -3.605637590999947],
              [-179.964115108999948, -3.607199586999968],
              [-179.964528160999919, -3.608761584999968],
              [-179.964941212000014, -3.610323582999968],
              [-179.96535426, -3.611885583],
              [-179.965767306999965, -3.613447584000028],
              [-179.966180351999952, -3.615009585999758],
              [-179.966593393999915, -3.616571590000035],
              [-179.967006434999917, -3.618133594000028],
              [-179.967419473999968, -3.619695599999943],
              [-179.967832509999965, -3.621257606999961],
              [-179.968245544999945, -3.622819614999912],
              [-179.968658577999832, -3.624381624999899],
              [-179.969071607999979, -3.625943635999818],
              [-179.969484636999965, -3.62750564800001],
              [-179.969897663999944, -3.629067661000022],
              [-179.970310688999916, -3.630629674999852],
              [-179.970723710999977, -3.632191690999946],
              [-179.971136731999962, -3.633753707999972],
              [-179.971549750999941, -3.635315725999817],
              [-179.971962767999912, -3.636877745],
              [-179.972375782999819, -3.638439766000033],
              [-179.97278879599989, -3.640001787000017],
              [-179.973201806999867, -3.641563809999866],
              [-179.973614815999923, -3.643125833999875],
              [-179.974027823, -3.644687859999976],
              [-179.974440827999956, -3.646249886999897],
              [-179.974853830999933, -3.647811913999988],
              [-179.975266831999932, -3.649373943],
              [-179.975679830999979, -3.650935973999765],
              [-179.976092827999963, -3.652498004999927],
              [-179.976505823999929, -3.654060037999955],
              [-179.976918816999927, -3.655622071999971],
              [-179.977331807999974, -3.657184106999978],
              [-179.977744797999947, -3.65874614400002],
              [-179.978157784999922, -3.660308181],
              [-179.978570769999919, -3.661870220000026],
              [-179.978983753999984, -3.663432260000036],
              [-179.979396734999938, -3.664994301999798],
              [-179.979809714999845, -3.6665563439999],
              [-179.980222691999984, -3.668118387999925],
              [-179.980635667999962, -3.66968043299994],
              [-179.981048640999944, -3.671242478999943],
              [-179.981461612999908, -3.672804526999869],
              [-179.981874582999893, -3.674366575999954],
              [-179.982287549999938, -3.675928625999973],
              [-179.982700515999909, -3.677490676999923],
              [-179.983113479999986, -3.67905272899992],
              [-179.98352644199997, -3.680614782999839],
              [-179.983939401999919, -3.682176838000032],
              [-179.984352359999917, -3.683738893999873],
              [-179.984765315999965, -3.685300950999988],
              [-179.985178269999864, -3.686863009999911],
              [-179.985591221999925, -3.688425069999823],
              [-179.986004171999895, -3.689987130999839],
              [-179.986417119999942, -3.691549192999958],
              [-179.98683006599984, -3.693111255999952],
              [-179.987243010999975, -3.694673320999982],
              [-179.987655952999944, -3.696235386999831],
              [-179.988068892999848, -3.69779745400001],
              [-179.988481831999906, -3.699359522999941],
              [-179.988894767999881, -3.700921591999986],
              [-179.989307702999923, -3.702483662999896],
              [-179.989720634999912, -3.704045734999909],
              [-179.990133565999969, -3.705607809000014],
              [-179.990546494999933, -3.707169882999949],
              [-179.990959422, -3.708731959000033],
              [-179.991372345999963, -3.71029403599988],
              [-179.991785268999934, -3.711856114],
              [-179.992198189999982, -3.713418193999928],
              [-179.992611108999967, -3.714980275000016],
              [-179.993024025999944, -3.716542357000037],
              [-179.993436940999914, -3.718104439999877],
              [-179.993849854999951, -3.71966652399999],
              [-179.994262765999935, -3.721228610000026],
              [-179.994675674999911, -3.722790696999937],
              [-179.995088581999966, -3.72435278499978],
              [-179.995501487999917, -3.725914873999784],
              [-179.995914390999985, -3.727476965],
              [-179.99632729299995, -3.729039057000023],
              [-179.996740191999919, -3.730601149999927],
              [-179.997153089999898, -3.732163243999935],
              [-179.997565985999955, -3.733725339999978],
              [-179.99797888, -3.735287437000011],
              [-179.998391771999962, -3.736849535000033],
              [-179.998804661999941, -3.738411633999931],
              [-179.999217549999827, -3.739973735000035],
            ],
          ],
          [
            [
              [-171.719627659999929, 0.563258352000048],
              [-171.14152465899997, 0.513182352000115],
              [-171.135170658999954, 0.511485352000022],
              [-171.125546658999923, 0.510654352000017],
              [-170.564763658999937, 0.361037852000095],
              [-170.561936658999969, 0.360253352000029],
              [-170.035761657999984, 0.115788352000095],
              [-170.031092657999949, 0.112605852000058],
              [-170.030144657999926, 0.112021352],
              [-170.025767657999978, 0.109988352000016],
              [-170.022804657999956, 0.107920352000122],
              [-170.021775657999825, 0.107442352000021],
              [-169.992883657999982, 0.087273352000125],
              [-169.980841657999974, 0.081684352000082],
              [-169.977913157999893, 0.079653852000092],
              [-169.806523657999975, -0.039937647999977],
              [-169.424814657999974, -0.216913647999945],
              [-168.948546156999953, -0.548686148999877],
              [-168.947721656999931, -0.549314648999939],
              [-168.536161156999952, -0.95850964899995],
              [-168.534281656999951, -0.960427648999769],
              [-168.199721656999827, -1.434853648999933],
              [-168.198873656999922, -1.436167649000026],
              [-168.084163656999948, -1.679785649999872],
              [-167.825376655999918, -2.045725649999781],
              [-167.577471655999943, -2.570276649999926],
              [-167.424163655999962, -3.129764650999959],
              [-167.370184655999935, -3.707300652000015],
              [-167.370199655999926, -3.707897651999929],
              [-167.370198655999928, -3.708747651999943],
              [-167.370245655999923, -3.709778651999954],
              [-167.370172655999909, -3.710557652000034],
              [-167.417318655999907, -4.288692651999952],
              [-167.56438765599998, -4.84992465299996],
              [-167.80717565599997, -5.377216652999834],
              [-168.138567656999982, -5.854503653999927],
              [-168.140391656999896, -5.85641365399988],
              [-168.250594656999937, -5.967416653999805],
              [-168.320804656999911, -6.119749654000032],
              [-168.652597656999973, -6.597038654000016],
              [-168.654499156999918, -6.598991653999974],
              [-168.953453656999926, -6.887176653999916],
              [-169.700010657999911, -6.890861653999821],
              [-170.169527658, -6.892794654],
              [-170.208228658999957, -6.892937653999979],
              [-170.225573659, -6.893023653999819],
              [-170.591047658999969, -6.894678653999904],
              [-170.628736659, -6.890064653999957],
              [-170.878947658999977, -6.8124126539999],
              [-171.242996658999942, -6.698969653999768],
              [-171.567151659999979, -6.59939165399976],
              [-172.071273659999946, -6.559827653999946],
              [-172.194381659999891, -6.549570654000036],
              [-172.569820660999966, -6.518947653999874],
              [-172.907617660999961, -6.491141653999932],
              [-173.219807660999976, -6.46672765400001],
              [-173.330202660999959, -6.523456653999915],
              [-173.634542661999973, -6.679656653999814],
              [-174.499503662999984, -7.124143654999955],
              [-175.368385662999941, -7.571324654999785],
              [-175.761499663999928, -7.773391654999898],
              [-176.226049663999987, -7.561457654999799],
              [-176.703150664999953, -7.225850654999931],
              [-177.113599664999839, -6.812957653999888],
              [-177.115374664999962, -6.810401653999975],
              [-177.11658066499993, -6.8091876539998],
              [-177.120404664999938, -6.803681653999945],
              [-177.121567664999986, -6.802510653999889],
              [-177.453001664999903, -6.325030653999875],
              [-177.695580664999937, -5.797582653],
              [-177.842220665999946, -5.236243653000031],
              [-177.842949665999953, -5.227149652999969],
              [-177.843177665999974, -5.226277653000011],
              [-177.889728665999939, -4.648096652],
              [-177.889495665999959, -4.645645651999985],
              [-177.88970666599991, -4.643041651999908],
              [-177.835018665999911, -4.065521652000029],
              [-177.68094466499997, -3.506107650999979],
              [-177.487730664999958, -3.004788650999956],
              [-177.433333664999822, -2.979166650999957],
              [-177.278610664999832, -2.942499650999878],
              [-176.968888664999923, -2.748055650999959],
              [-176.966944664999914, -2.746944650999978],
              [-176.850833664999925, -2.67388865099997],
              [-176.649722664999871, -2.547499649999907],
              [-176.23277766399994, -2.285833649999972],
              [-175.758055663999983, -1.988055649999865],
              [-175.580833663999954, -1.876666649999947],
              [-174.579999662999967, -1.247777649],
              [-174.517222662999956, -1.210833649],
              [-174.294722661999941, -1.068333648999953],
              [-173.758333661999927, -0.731110648999959],
              [-173.457777661999927, -0.242222647999938],
              [-173.144485660999976, 0.252269352000098],
              [-172.863195660999935, 0.36186735199999],
              [-172.302688659999973, 0.512270352000144],
              [-171.724623659999935, 0.56319935199997],
              [-171.722440659999904, 0.563010352000163],
              [-171.719627659999929, 0.563258352000048],
            ],
          ],
          [
            [
              [179.999979394500201, -1.608336679999923],
              [180.000000000000227, -3.742788306999785],
              [179.996711789000187, -3.755374932],
              [179.996240789000353, -3.757156931999987],
              [179.995827914000188, -3.758719057],
              [179.995415039000022, -3.760281182000028],
              [179.995002164000084, -3.761843306999879],
              [179.994589414000188, -3.763405432000013],
              [179.994176539000023, -3.76496755699992],
              [179.993763664000085, -3.766529681999771],
              [179.993350789000147, -3.768091806999792],
              [179.992937914000322, -3.769653931999812],
              [179.992525039000043, -3.771216056999833],
              [179.992112289000147, -3.772778181999854],
              [179.991699414000209, -3.774340306999875],
              [179.991286539000043, -3.775902431999896],
              [179.990873664000219, -3.777464556999917],
              [179.990460914000209, -3.779026681999937],
              [179.990048039000044, -3.780588806999958],
              [179.989635164000106, -3.782150931999979],
              [179.98922241400021, -3.783713057],
              [179.988809539000044, -3.785275182000021],
              [179.988396664000106, -3.786837306999928],
              [179.987983914000097, -3.788399431999949],
              [179.987571039000272, -3.78996155699997],
              [179.987158164000107, -3.791523806999919],
              [179.986745414000097, -3.79308593199994],
              [179.986332539000273, -3.794648056999961],
              [179.985919789000036, -3.796210181999982],
              [179.985506914000098, -3.797772307],
              [179.985094164000088, -3.799334432000023],
              [179.984681289000264, -3.800896681999973],
              [179.984268539000027, -3.802458807],
              [179.983855664000089, -3.804020932000014],
              [179.983442914000193, -3.805583057000035],
              [179.892221433250143, -4.002669841499994],
              [179.751784009000176, -4.022263426999757],
              [179.434312922000117, -4.066556992999892],
              [178.750140095000233, -4.161063949999914],
              [178.532752402000142, -4.190958475],
              [178.119529917000136, -4.247783633999973],
              [177.57059610500005, -4.32249327299985],
              [177.564726664000318, -4.323271371000033],
              [177.557355064000262, -4.324248606999959],
              [177.31623846500031, -4.356314296999926],
              [177.00760492600034, -4.284845674],
              [176.844237355000018, -4.246814579999921],
              [176.727385880500151, -4.219612183999914],
              [176.578744497000116, -4.184850970999946],
              [176.395870431000276, -4.138888217999977],
              [176.26386651200005, -4.105480887999875],
              [176.145227879000117, -4.075456022000026],
              [176.057316419000244, -4.077992172999984],
              [175.98449018000008, -4.079991806999942],
              [175.313972847000173, -4.099108346999969],
              [175.158560869666871, -4.103543071666593],
              [175.132630394000216, -4.104254112999968],
              [175.117258210000045, -4.104675634999921],
              [175.037088980000249, -4.106873956000015],
              [174.673063835000107, -4.116855887999918],
              [174.587625876000288, -4.119186820999971],
              [174.549383951000209, -4.120230143999777],
              [174.527989501000206, -4.120920264999768],
              [174.408370699000216, -4.124778807999974],
              [174.306519907000052, -4.128064208999945],
              [173.084879375750006, -4.168747671749998],
              [172.93236078200016, -4.091144431999908],
              [172.853341907000186, -4.012060681999969],
              [172.679309907000061, -3.890130806999821],
              [172.674383407000164, -3.885199431999979],
              [172.668307157000072, -3.88094205699997],
              [172.530582532000153, -3.743065556999909],
              [172.257820781000078, -3.470002806999901],
              [172.257022656000231, -3.468950181999901],
              [172.253011156000298, -3.463299806999771],
              [172.251831156000208, -3.462118431999897],
              [172.207399156000179, -3.398543806999953],
              [172.095955156000173, -3.286965556999917],
              [171.99916240600021, -3.148471931000017],
              [171.688777281000057, -3.459203557000023],
              [171.213175280000314, -3.792485056999965],
              [170.68671940500019, -4.038109181999801],
              [170.125440279000259, -4.188578306999887],
              [169.546228091500211, -4.239274181999974],
              [169.54536527900018, -4.239285431999917],
              [168.966541528000221, -4.188715556999796],
              [168.405322403000014, -4.038390306999986],
              [168.393330403000277, -4.032796806999897],
              [168.389304028000083, -4.031718681999962],
              [168.259557528000187, -3.971203307],
              [167.862943652000126, -3.786254307000036],
              [167.815832191000254, -3.753244848999941],
              [167.880157648000136, -3.284414949],
              [167.926786650000167, -2.944563965999976],
              [167.930982613000197, -2.913982091000037],
              [167.932153602000312, -2.905455172999893],
              [167.948754847000146, -2.784568140999966],
              [167.96825918900015, -2.642541317999928],
              [168.018395536000185, -2.28383657299986],
              [168.052102948000311, -2.04267403699987],
              [168.076398302000143, -1.868040025000028],
              [168.090182874000334, -1.768957086999933],
              [168.090449604000128, -1.767030837],
              [168.090611785000192, -1.765859608999961],
              [168.093677613000182, -1.743621076999887],
              [168.103299330000027, -1.673828227999877],
              [168.109845552000252, -1.626642660999892],
              [168.134771542500175, -1.448113657999954],
              [168.153561678000301, -1.311648464000029],
              [168.155905767000149, -1.294624288000023],
              [168.174971741000149, -1.156155770999874],
              [168.202581652000276, -0.952723788999947],
              [168.203036799000188, -0.949588006999875],
              [168.212599359000251, -0.883705509999913],
              [168.256875250000348, -0.577820433999932],
              [168.266776491000201, -0.508587621],
              [168.267223138500185, -0.505506549999893],
              [168.267318330000194, -0.504851236999855],
              [168.328284785000164, -0.085753804999911],
              [168.383120476000045, 0.291199338000126],
              [168.387852059000352, 0.324049229000082],
              [168.392655824000144, 0.357400252000048],
              [168.39360081000018, 0.363907864999987],
              [168.394120190500189, 0.367484565000154],
              [168.394910380000169, 0.372926182000015],
              [168.424688469000102, 0.577270264000163],
              [168.487620440000342, 1.009123897],
              [168.501523122000322, 1.105660246000184],
              [168.511867241000232, 1.177486919000046],
              [168.531385607000288, 1.311914176000073],
              [168.548172999000144, 1.427532621000069],
              [168.598105120000241, 1.770280294999964],
              [168.66280115300026, 1.780169142999966],
              [169.045916707000288, 1.838340837000089],
              [169.360716411000226, 1.88613955400001],
              [169.421538869000074, 1.895274837000102],
              [169.494126126000197, 1.906177145000129],
              [169.534500086000321, 1.912214935],
              [169.554254598000171, 1.915169156000104],
              [169.563557362000211, 1.916560353000023],
              [169.626390284000195, 1.925986615000113],
              [169.692225613000119, 1.935863302000143],
              [169.764059298000234, 1.946501242000124],
              [170.055175505000193, 1.989613008000106],
              [170.087449499000172, 2.074227598],
              [170.107107330000304, 2.129302746000064],
              [170.152027158000124, 2.255154177000122],
              [170.182849706000297, 2.340845900000033],
              [170.203657353000153, 2.398694564999971],
              [170.280385402000206, 2.628774723500101],
              [170.392050919000212, 2.955290932000139],
              [170.498640888000182, 3.266965945999971],
              [170.499233678333439, 3.268701432666717],
              [170.581999524000253, 3.51070724400023],
              [170.5846871560002, 3.518551566000042],
              [170.587367708000329, 3.526375217000123],
              [170.738278197500335, 3.965773099000103],
              [170.817891771000177, 4.197579622000092],
              [170.93526223400022, 4.237874258000204],
              [171.132833875000188, 4.30080242400021],
              [171.26224948000015, 4.342022321000059],
              [171.426793289000102, 4.375314083999967],
              [172.091462192000222, 4.509114387000125],
              [172.478663626000298, 4.587059463000116],
              [172.495539890000032, 4.592543871000032],
              [172.496479453000234, 4.592849208000132],
              [172.551845840000169, 4.610842047000233],
              [172.682322646000216, 4.653622275000089],
              [172.712089018000142, 4.663381956000023],
              [172.865980932000184, 4.713719757000206],
              [172.998118413000043, 4.756941717000245],
              [173.481139061000135, 4.915372490500204],
              [173.608598515000153, 4.957179192000183],
              [175.484469322666882, 5.569358057333488],
              [175.557102159000237, 5.500411827000221],
              [175.890701535000289, 5.024199076],
              [176.136043410000184, 4.497459076000041],
              [176.285857410000062, 3.936234075000129],
              [176.333843285000171, 3.380031950000102],
              [176.418154035000157, 3.198910574000081],
              [176.41851528500024, 3.197557324000059],
              [176.423124910000297, 3.187654199000178],
              [176.423784285000124, 3.185184323999977],
              [176.426118285000257, 3.180170074000102],
              [176.426985285000228, 3.177137136500164],
              [176.430554160000042, 3.163983573999985],
              [176.449020535000045, 3.124310699000091],
              [176.598804660000042, 2.563302949000047],
              [176.614923035000061, 2.376901449],
              [176.809082536000346, 2.099320948000013],
              [176.809639036000021, 2.098125448000019],
              [176.814223786000071, 2.091458073000183],
              [176.815072661000045, 2.090131573000065],
              [176.865491911000163, 1.981826323],
              [176.995355536000261, 1.970407948],
              [177.001066661000323, 1.968875448000063],
              [177.022354411000265, 1.967003322999972],
              [177.583434411000212, 1.816427698000041],
              [178.109751662000122, 1.570771573000059],
              [178.585474662000138, 1.237416760500139],
              [178.586338662000117, 1.236768823000034],
              [178.589415287000179, 1.233690448000118],
              [178.590332412000294, 1.23304807300002],
              [178.593542662000345, 1.229835948000073],
              [178.594325912000158, 1.229287198000122],
              [179.00490916300032, 0.818524822000029],
              [179.006786413000214, 0.816596197000024],
              [179.339764538000054, 0.341128697000102],
              [179.347170538000341, 0.325252572000068],
              [179.37574191300007, 0.284475697000062],
              [179.377077913000335, 0.28161207200003],
              [179.379735663000133, 0.277819072000057],
              [179.625264663000166, -0.248071553999921],
              [179.625489413000082, -0.24890867900001],
              [179.626256163000107, -0.25054967899996],
              [179.628499663000298, -0.2589036789999],
              [179.629260413000225, -0.260531803999982],
              [179.779955788000279, -0.820917928999791],
              [179.810538288000089, -1.165822429999878],
              [179.975491539000018, -1.517732554999895],
              [179.975913414000047, -1.51929343],
              [179.976335164000261, -1.520854180000015],
              [179.976756914000021, -1.522414929999911],
              [179.977178664000121, -1.523975804999907],
              [179.977600414000335, -1.525536554999974],
              [179.978022164000208, -1.527097430000026],
              [179.978443914000309, -1.528658179999979],
              [179.978865664000068, -1.53021893],
              [179.979287539000211, -1.531779804999928],
              [179.979709289000084, -1.533340554999825],
              [179.980131039000185, -1.53490143],
              [179.980552789000058, -1.53646218],
              [179.980974664000087, -1.538022930000011],
              [179.981396414000187, -1.539583805],
              [179.981818164000288, -1.541144554999903],
              [179.982240039000089, -1.54270530499997],
              [179.98266178900019, -1.544266180000022],
              [179.983083664000333, -1.545826929999976],
              [179.983505414000092, -1.547387804999914],
              [179.983927289000235, -1.548948554999924],
              [179.984349039000108, -1.550509304999821],
              [179.984770914000137, -1.552070179999987],
              [179.985192789000166, -1.55363093],
              [179.985614539000039, -1.55519168],
              [179.986036414000068, -1.556752555],
              [179.986458289000211, -1.558313304999899],
              [179.986880039000084, -1.55987418],
              [179.987301914000113, -1.561434930000019],
              [179.987723789000142, -1.562995679999972],
              [179.988145664000172, -1.564556554999911],
              [179.988567414000045, -1.566117304999921],
              [179.988989289000074, -1.567678054999817],
              [179.989411164000217, -1.569238929999983],
              [179.989833039000018, -1.57079968],
              [179.990254914000047, -1.57236043],
              [179.990676789000076, -1.573921305],
              [179.991098664000219, -1.575482054999895],
              [179.991520539000021, -1.577042804999962],
              [179.99194241400005, -1.578603680000015],
              [179.992364289000079, -1.580164429999968],
              [179.992786164000222, -1.581725179999978],
              [179.993208039000024, -1.583286054999917],
              [179.993629914000053, -1.584846804999813],
              [179.994051914000124, -1.586407679999979],
              [179.994473789000153, -1.587968429999989],
              [179.994895664000182, -1.58952918],
              [179.995317539000098, -1.591090055],
              [179.995739539000056, -1.592650804999892],
              [179.996161414000085, -1.594211554999958],
              [179.996583289000228, -1.595772430000011],
              [179.997005289000185, -1.597333179999964],
              [179.997427164000328, -1.598893929999974],
              [179.997849039000016, -1.600454804999913],
              [179.998271039000088, -1.602015554999809],
              [179.998692914000117, -1.603576304999876],
              [179.999114914000188, -1.605137054999886],
              [179.999536789000217, -1.60669793],
              [179.999979394500201, -1.608336679999923],
            ],
          ],
          [
            [
              [-153.877818642999927, 2.311517354000046],
              [-153.829158642999971, 1.733367354000109],
              [-153.829460642999919, 1.730051354000011],
              [-153.828204642999907, 1.715189354000131],
              [-153.828511643000013, 1.711814354000126],
              [-153.828229642999929, 1.708037020666893],
              [-153.880511642999977, 1.129958353000063],
              [-153.881021642999912, 1.128079352999976],
              [-153.881514642999946, 1.122606353000151],
              [-154.033104642999945, 0.562706352000021],
              [-154.27940464399984, 0.037564352000174],
              [-154.281388643999861, 0.033342352000091],
              [-154.614939643999975, -0.441123647999859],
              [-154.840400643999857, -0.665850648999822],
              [-154.328386643999949, -0.710225649],
              [-154.322548643999909, -0.711784648999981],
              [-154.321403643999929, -0.711883649000015],
              [-153.760681642999941, -0.861352648999798],
              [-153.234300642999983, -1.105660648999958],
              [-153.232529642999907, -1.106844148999926],
              [-152.756140641999934, -1.438697649],
              [-152.344547641999924, -1.847863649999852],
              [-152.009942642, -2.322086650000017],
              [-151.762451640999899, -2.847055650999948],
              [-151.609616640999974, -3.40692865099993],
              [-151.556189640999946, -3.984797652],
              [-151.603977640999972, -4.563188651999894],
              [-151.604814640999933, -4.56636765199994],
              [-151.604962640999844, -4.56816065199979],
              [-151.75276464099997, -5.12954265299993],
              [-151.753495640999972, -5.131125652999913],
              [-151.753745640999966, -5.132076653000013],
              [-151.766061640999965, -5.158742652999877],
              [-151.769585640999964, -5.17213065299984],
              [-152.013203641999979, -5.699437652999961],
              [-152.013850641999966, -5.700366653],
              [-152.014189641999934, -5.701100652999969],
              [-152.346482641999984, -6.178238653999927],
              [-152.60471264200001, -6.437346653999953],
              [-152.68895464199997, -6.756087653999828],
              [-152.704499641999917, -6.789601653999966],
              [-152.373047641999904, -6.762111653999909],
              [-151.790943640999927, -6.814085653999939],
              [-151.567999640999943, -6.873728653999763],
              [-151.371581140999922, -6.782865653999863],
              [-151.369916640999946, -6.782157653999832],
              [-150.805600639999966, -6.631442653999898],
              [-150.223744639999978, -6.579481653999892],
              [-150.222885639999873, -6.579553653999881],
              [-150.221763639999921, -6.579452653999851],
              [-149.639632638999927, -6.627773654],
              [-149.074493638999883, -6.774928653999893],
              [-148.543126637999904, -7.016527654999834],
              [-148.061345637999949, -7.345371654999894],
              [-147.643372137999933, -7.751917154999887],
              [-147.641556637999912, -7.75375565499985],
              [-147.300341636999917, -8.225373655999874],
              [-147.299973636999937, -8.226132655999947],
              [-147.299326636999979, -8.227024655999855],
              [-147.298973636999989, -8.227752655999893],
              [-147.298311636999927, -8.228665655999919],
              [-147.044085637, -8.751528655999891],
              [-147.043787636999923, -8.752574655999979],
              [-147.043074636999904, -8.754037655999923],
              [-147.04214513699992, -8.7571536559999],
              [-146.882737636999849, -9.31582365700001],
              [-146.882271636999917, -9.320386656999972],
              [-146.881682636999955, -9.322447657],
              [-146.822515637, -9.899726656999803],
              [-146.822678637, -9.901902657],
              [-146.822471636999921, -9.903913656999919],
              [-146.822482136999895, -9.904505656999959],
              [-146.822789636999943, -9.909050656999966],
              [-146.822374636999967, -9.91307665699992],
              [-146.822386136999938, -9.913683656999865],
              [-146.822428636999916, -9.914703656999905],
              [-146.821227636999936, -9.926366656999903],
              [-146.821389636999925, -9.928537656999765],
              [-146.821183636999933, -9.930542656999819],
              [-146.864440636999944, -10.509227657999816],
              [-146.866053636999908, -10.51549165800003],
              [-146.866333636999968, -10.519250657999919],
              [-147.011302636999829, -11.081790657999875],
              [-147.065069636999823, -11.192069657999866],
              [-147.235360636999957, -11.33458965899996],
              [-147.34251263699997, -11.424338658999943],
              [-147.427149636999928, -11.495023658999969],
              [-147.487754636999938, -11.545567658999914],
              [-147.848408637999825, -11.849745658999836],
              [-147.979810637999975, -11.959874658999979],
              [-148.062897637999953, -11.994279658999858],
              [-148.155046637999902, -12.032448658999954],
              [-148.282541637999913, -12.071892658999957],
              [-149.398106638999906, -12.411796659999936],
              [-149.428047638999942, -12.437782659999925],
              [-149.997166639999961, -12.928852659999905],
              [-150.490112639999921, -13.350937659999829],
              [-150.50210464, -13.361184661000038],
              [-151.065675640999956, -13.838930660999893],
              [-151.826784640999961, -13.835607660999756],
              [-151.831780641, -13.835569660999909],
              [-152.803152641999958, -13.82607466099995],
              [-153.164586643, -13.613102661000028],
              [-153.400756642999966, -13.473636660999887],
              [-154.361832644, -12.901995659999955],
              [-154.382569643999915, -12.889645659999871],
              [-154.423412643999939, -12.86544065999982],
              [-154.524397643999919, -12.80559566],
              [-155.024568643999856, -12.507758659999922],
              [-155.15021364399982, -12.030458658999805],
              [-155.154571643999986, -12.014486658999886],
              [-155.155558644999843, -12.003339658999835],
              [-155.319238644999842, -11.773919658999887],
              [-155.490396644999947, -11.4126706589999],
              [-155.403552644999905, -10.941901657999892],
              [-155.23770964499991, -10.086885656999812],
              [-155.237507644999965, -10.085838656999897],
              [-155.141362643999884, -9.586983656999848],
              [-155.037844643999819, -9.050889655999868],
              [-154.926673644, -8.474729655999909],
              [-155.862275144999899, -7.939059154999939],
              [-156.600638645999908, -7.493728654999927],
              [-156.686227645999935, -7.441653654999925],
              [-157.400606646999904, -7.018936654999948],
              [-157.41758764699992, -7.008881654999925],
              [-157.590832646999957, -6.903028653999854],
              [-157.674209646999941, -6.852132654],
              [-158.03498164699991, -6.630342653999833],
              [-158.22193664699995, -6.515062653999848],
              [-159.261546647999978, -5.852911653999911],
              [-159.28198164799997, -5.595511653000017],
              [-159.226467647999925, -5.017789653000023],
              [-159.071543647999931, -4.458477652],
              [-158.822112647999973, -3.934425651999902],
              [-158.485844647999926, -3.461387651],
              [-158.174999646999851, -3.177777650999928],
              [-157.816944646999957, -2.223888649999765],
              [-157.41444464699984, -0.970833648999928],
              [-157.811944646999962, -0.428888647999941],
              [-157.828888646999928, -0.406388648000018],
              [-158.124166646999953, -0.004722647999984],
              [-158.301666646999905, 0.210000352000066],
              [-158.80666664799989, 0.782777352999972],
              [-158.984444647999823, 0.981389353000111],
              [-159.657499648999931, 1.72527735400007],
              [-159.883055648999942, 1.762777354000093],
              [-160.344999648999931, 1.838333354000099],
              [-161.373333649999921, 2.003611354000157],
              [-161.605555650999918, 2.041666354000085],
              [-161.692499650999906, 2.055555354000091],
              [-162.026388650999849, 2.028333353999983],
              [-162.366666650999917, 2.016666354000108],
              [-162.564943650999822, 2.154856354000117],
              [-162.565514650999972, 2.155423354000163],
              [-162.566050650999841, 2.155825354],
              [-162.567074150999872, 2.156605854000134],
              [-162.567944650999948, 2.157250353999984],
              [-162.570160650999981, 2.159449354],
              [-162.570945650999846, 2.159994354],
              [-162.983394651999816, 2.568842354000026],
              [-163.087222651999923, 2.663889354000105],
              [-162.215555650999846, 3.906944356000224],
              [-161.62166665099997, 4.743889356000238],
              [-161.471944649999983, 4.956944357],
              [-160.920277649999917, 5.736666357000047],
              [-160.694999649999914, 6.051389358000108],
              [-160.427777648999921, 6.42527735800013],
              [-160.354999648999836, 6.52694435799998],
              [-160.33083364899997, 6.561111358000119],
              [-159.909722648999974, 7.161944359000188],
              [-159.658333648999928, 7.518055359000186],
              [-159.37472264799996, 7.91777735900007],
              [-159.247246647999958, 7.854330359000244],
              [-158.716492647999985, 7.608877359000132],
              [-158.237345646999842, 7.27540835900021],
              [-158.236367646999923, 7.274435359],
              [-158.234334646999912, 7.273019359000102],
              [-157.885118646999985, 6.925293358000147],
              [-157.671720646999972, 6.826049358000205],
              [-157.670837646999928, 6.825459358000046],
              [-157.192847645999933, 6.491430358],
              [-157.190796979333243, 6.489409691333492],
              [-157.189839645999911, 6.488681358000122],
              [-157.182687645999948, 6.481537358000026],
              [-157.117757645999944, 6.436114358000111],
              [-157.116652646, 6.435010358000056],
              [-157.113713645999923, 6.432941358000107],
              [-157.111753645999926, 6.43155735800002],
              [-156.700125645999947, 6.019956358000115],
              [-156.699582645999868, 6.019179358000144],
              [-156.698113645999825, 6.017710358000102],
              [-156.693108645999956, 6.010552358000041],
              [-156.682144645999983, 5.999580358000117],
              [-156.349124645999922, 5.523029357000212],
              [-156.161913644999913, 5.119597357000146],
              [-155.749012644999937, 4.926208357000121],
              [-155.743208644999953, 4.922129357000131],
              [-155.638822644999948, 4.873202356000093],
              [-155.162905644999967, 4.53851335600018],
              [-155.162047144999889, 4.537695356000143],
              [-154.75169214399989, 4.125271356000212],
              [-154.750749643999967, 4.124177856000131],
              [-154.746902643999903, 4.120248356000104],
              [-154.745871310666587, 4.118805689333442],
              [-154.744872143999942, 4.117721356000118],
              [-154.743902643999945, 4.11673135600023],
              [-154.412147643999901, 3.639965355000072],
              [-154.411401643999909, 3.638350355000227],
              [-154.409152643999903, 3.635119355000086],
              [-154.375619643999954, 3.562574354999981],
              [-154.276535144, 3.42006385500008],
              [-154.275671643999971, 3.418732355000031],
              [-154.032069642999858, 2.891813355000011],
              [-154.031483642999945, 2.889595354999983],
              [-154.031070642999964, 2.888702355000021],
              [-154.030824642999875, 2.887769355000103],
              [-154.030085642999921, 2.886171355000045],
              [-154.029598642999957, 2.884329355000034],
              [-154.029086642999943, 2.883221355000046],
              [-154.028791642999977, 2.882106355000133],
              [-154.028086642999909, 2.880580355],
              [-154.027430642999974, 2.878098354999963],
              [-154.027103642999919, 2.877389355000105],
              [-153.878813642999916, 2.316346354000132],
              [-153.878682642999905, 2.314783354000099],
              [-153.877818642999927, 2.311517354000046],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "KNA", Country: "St. Kitts & Nevis" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.753156557999858, 16.613675367],
            [-62.885578558999839, 16.494843367000115],
            [-62.888084559, 16.492577367000123],
            [-63.045478559, 16.348982367000175],
            [-63.279581763999943, 16.507347508000166],
            [-63.28044552099982, 16.507921848000066],
            [-63.294035934999869, 16.516958330000179],
            [-63.380138498499889, 16.573943459500114],
            [-63.442405558999894, 16.615153367000076],
            [-63.477305905, 16.630949531000098],
            [-63.482422645999861, 16.63326538000014],
            [-63.510537803, 16.645827048000243],
            [-63.62902627879987, 16.680056111800113],
            [-63.211909047999939, 17.103511683000065],
            [-63.112053920999983, 17.211559063999985],
            [-62.994871874000012, 17.33571707],
            [-62.963500751999959, 17.367652297000106],
            [-62.960989758999943, 17.369978527000015],
            [-62.946482036999953, 17.383661568],
            [-62.939288668000017, 17.391235035],
            [-62.930275085999853, 17.40195904100004],
            [-62.924941997000019, 17.407428439000043],
            [-62.922634573999972, 17.409713447000016],
            [-62.920498697999903, 17.411927855],
            [-62.913963900999846, 17.419015612999985],
            [-62.903984251999958, 17.430254772999987],
            [-62.902282627000034, 17.432115770000152],
            [-62.900701079000015, 17.433939077],
            [-62.898416640999898, 17.436417022000043],
            [-62.894936243999979, 17.440964003000133],
            [-62.886488837999934, 17.452629624999972],
            [-62.884349954999976, 17.455863059000094],
            [-62.874074894999978, 17.469341135000079],
            [-62.873358277000023, 17.470296823],
            [-62.870277667000039, 17.47469012100008],
            [-62.868379419, 17.477485838000021],
            [-62.855389652000014, 17.49479998300005],
            [-62.825994809999969, 17.544941184000081],
            [-62.820495828999896, 17.554153010000121],
            [-62.791781549999854, 17.603416094000139],
            [-62.769858395999961, 17.642872968000049],
            [-62.711972149999923, 17.64150851600003],
            [-62.678343559999973, 17.640755744000089],
            [-62.677166531999831, 17.640789268000034],
            [-62.663223927999866, 17.641238663000095],
            [-62.564724602999831, 17.644762572000062],
            [-62.507053485999933, 17.650598518000137],
            [-62.463438669999988, 17.654909993000089],
            [-62.369600776999903, 17.665376846000143],
            [-62.368971557999885, 17.663898368000119],
            [-62.351975557999879, 17.62505136800003],
            [-62.348539558, 17.617277368000018],
            [-62.338183557999855, 17.595773367999968],
            [-62.32762955799987, 17.574063368000125],
            [-62.314161558000023, 17.553666368000094],
            [-62.312027557999841, 17.549196367999983],
            [-62.2384495579999, 17.429921368000024],
            [-62.233430557999952, 17.421814368],
            [-62.222760557999948, 17.405205368000153],
            [-62.201135557999919, 17.371688368],
            [-62.201163558000019, 17.37073636800001],
            [-62.204442557999897, 17.337844367999978],
            [-62.211452558000019, 17.265111368000063],
            [-62.212960557999907, 17.250016368000146],
            [-62.213569557999847, 17.243455368000141],
            [-62.223787558000026, 17.235461368000145],
            [-62.255771557999964, 17.210596368],
            [-62.276945557999881, 17.193356368000067],
            [-62.33369755800004, 17.143651368000164],
            [-62.351996557999939, 17.127542368],
            [-62.369208557999968, 17.112543368000033],
            [-62.389059557999929, 17.093797368000011],
            [-62.407128557999982, 17.076238368000148],
            [-62.418517557999962, 17.064154368000132],
            [-62.457352558000025, 17.022133367999984],
            [-62.479116557999902, 16.997224368000118],
            [-62.499852558000015, 16.973052368000012],
            [-62.523537558000044, 16.945025368],
            [-62.52551355799983, 16.942692368000152],
            [-62.528015557999908, 16.939022367999982],
            [-62.532460558, 16.932968368],
            [-62.539231557999955, 16.92377436800011],
            [-62.597907557999861, 16.840099367999969],
            [-62.753156557999858, 16.613675367],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "KOR", Country: "South Korea" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [131.265000000000242, 36.166666667000058],
            [131.13833333300019, 36.063333333000102],
            [130.711666667000173, 35.705000000000112],
            [130.568333333000197, 35.56166666700004],
            [130.388333333000247, 35.303333333],
            [130.273333333000181, 35.116666667000018],
            [130.125000000000142, 35.113333333],
            [129.678333333000211, 35.068333333000012],
            [129.548333333000159, 35.02000000000011],
            [129.421666667000153, 34.976666667000174],
            [129.376666667000251, 34.96],
            [129.361666667000094, 34.95],
            [129.306666667000144, 34.905000000000101],
            [129.263333333000134, 34.873333333000019],
            [129.216666667000169, 34.843333333000103],
            [129.201666667000126, 34.828333333000032],
            [129.051666667000205, 34.671666667000082],
            [129.01333333300019, 34.543333333000177],
            [129.01333333300019, 34.535],
            [129.003333333000199, 34.486666667000165],
            [128.99, 34.46],
            [128.955000000000183, 34.408333333000044],
            [128.88833333300019, 34.308333333000107],
            [128.880000000000194, 34.3],
            [128.793333333000163, 34.216666666999984],
            [128.688333333000145, 34.136666667000085],
            [128.435, 33.840000000000103],
            [128.425, 33.79],
            [128.361666667000037, 33.751666667000094],
            [127.871666667000198, 33.27],
            [127.860000000000156, 33.228333332999952],
            [127.805000000000206, 33.145],
            [127.733333333000047, 33.021666667000019],
            [127.700222681000156, 32.961752153000035],
            [127.698333333000136, 32.958333333000141],
            [127.685000000000144, 32.95],
            [127.60500000000016, 32.89],
            [127.463333333000065, 32.77000000000011],
            [127.218333333000174, 32.560000000000159],
            [126.858333333000047, 32.175000000000168],
            [125.925000242800166, 30.769999409600061],
            [125.489968504000245, 31.072603553],
            [125.218970382000094, 31.294822013],
            [125.034691659000174, 31.462840848999988],
            [124.888352673000185, 31.614599796999968],
            [124.779953424000126, 31.739258933000073],
            [124.617354551000147, 31.907277769000061],
            [124.416815941000181, 32.11865630400014],
            [124.300957888000227, 32.224564201000035],
            [124.271671787000145, 32.255070556000035],
            [124.224756445000111, 32.301954714000132],
            [124.217299632000078, 32.307832214000072],
            [124.002029652000175, 32.474185776000027],
            [123.73333038000024, 32.68060918400009],
            [123.624663680000168, 32.797365277000083],
            [123.392051327000189, 33.049640084000046],
            [123.157067374000036, 33.322372868000016],
            [123.153975683000198, 33.327159631000129],
            [123.017699651000186, 33.53866895200018],
            [122.987724373000248, 33.584411270000018],
            [122.91549450100004, 33.694773003000037],
            [122.914602114000189, 33.696137603000054],
            [122.909256902000124, 33.704360842000014],
            [122.833599945000145, 33.904482426000115],
            [122.819443270000221, 33.940327346000132],
            [122.786069492000109, 34.101254571000098],
            [122.785841519000172, 34.102348581000101],
            [122.773714642000158, 34.160465704000146],
            [122.724498094000097, 34.484706662000079],
            [122.71425142700005, 34.565633189000081],
            [122.74728080800017, 34.618688680000091],
            [122.782247458000171, 34.659587687000041],
            [122.804078126000064, 34.694877995999988],
            [123.100334679000156, 35.169879227000067],
            [123.132844136000045, 35.2232763680001],
            [123.595783853000199, 35.598536902999982],
            [123.891183105000124, 35.835507737999976],
            [123.91351906800017, 35.98182161600009],
            [124.011941023000105, 36.60031621200001],
            [124.01210942100019, 36.60136708600011],
            [124.012276900000103, 36.60241794800011],
            [124.017495082000181, 36.63518751200003],
            [124.007629568000226, 36.65977213799998],
            [123.897481822000174, 36.928682268000031],
            [123.873433012000163, 36.987731903000039],
            [123.789633640000119, 37.29124089000014],
            [123.775090811000069, 37.33603196100016],
            [123.757409482000213, 37.387044757],
            [123.756984856000059, 37.38827197900001],
            [123.756569595000173, 37.389475277000102],
            [123.755999859000184, 37.391131944000037],
            [123.75541612900011, 37.392836411000118],
            [123.753996463000163, 37.396997090000113],
            [123.752446904000209, 37.401610901000126],
            [123.66243373100022, 37.670212303000127],
            [123.63812344700014, 37.723000267000018],
            [123.614370689000197, 37.774231594999989],
            [123.576543832000226, 37.863404544],
            [123.532801275000082, 37.96488066600007],
            [123.460927018000092, 38.130556968000022],
            [123.547708292000181, 38.20473401200006],
            [124.095665597000249, 38.128234683000088],
            [124.105387709000155, 38.126830507000179],
            [124.413582018000199, 38.081759360000078],
            [124.587950474000223, 38.057925047000126],
            [124.596253200000177, 38.056466389000136],
            [124.620794652000114, 38.051658843000084],
            [124.654057840000206, 38.044812592000014],
            [124.791487730000227, 38.011976904000122],
            [124.854831346000225, 37.975837031000125],
            [124.86376875400012, 37.890931662000057],
            [124.86600310500026, 37.788151480000025],
            [124.864553114000188, 37.696196397000037],
            [124.87010252800016, 37.607405767000031],
            [124.88675077100018, 37.479769236999957],
            [124.908948429000162, 37.346583293],
            [124.931146086000041, 37.224496177000091],
            [124.947794329000232, 37.107958476000036],
            [125.362029204000152, 37.26531316],
            [125.460340683000112, 37.29510451700007],
            [125.519923397000099, 37.321916738000127],
            [125.546735619000145, 37.41724908200014],
            [125.566164470000246, 37.484502797000019],
            [125.585464383000186, 37.55131018900012],
            [125.606318334000122, 37.634725990000121],
            [125.609275088000146, 37.646553006],
            [125.618234876000116, 37.682392160999981],
            [125.662921912000144, 37.694308704],
            [125.716546355000247, 37.691329569000047],
            [125.796983020000226, 37.679413026000034],
            [125.860537916000141, 37.682392160999981],
            [125.912176268000138, 37.690336523000141],
            [125.94792589700026, 37.694308704],
            [125.983675526000212, 37.702253066000068],
            [126.019425154000061, 37.702253066000068],
            [126.071063507000218, 37.710197428000029],
            [126.10681313600017, 37.718141790000018],
            [126.138590583000195, 37.718141790000018],
            [126.162423669000219, 37.718141790000018],
            [126.178312393000084, 37.722113971000041],
            [126.18625675500013, 37.749919238000118],
            [126.18625675500013, 37.769780143],
            [126.18625675500013, 37.789641048000092],
            [126.194201117000119, 37.817446314000151],
            [126.233922927000179, 37.833335038000016],
            [126.265700374000204, 37.833335038000016],
            [126.289533460000058, 37.833335038000016],
            [126.329255270000118, 37.837307218999953],
            [126.349116175000205, 37.841279400000175],
            [126.392810166000203, 37.845251581000028],
            [126.420615432000176, 37.841279400000175],
            [126.45239288000019, 37.821418495],
            [126.480198147000152, 37.805529771000138],
            [126.508003414000228, 37.79361322900003],
            [126.53183649900015, 37.781696686000018],
            [126.567586128000102, 37.769780143],
            [126.627168843000078, 37.77772450500008],
            [126.662918471000097, 37.785668867000069],
            [126.688491230140443, 37.833909063995023],
            [126.736645264158284, 37.955127142223418],
            [126.773463444511577, 37.973600186321576],
            [126.807209322066313, 38.000544987620614],
            [126.824436144796692, 38.016109176032572],
            [126.935254125179227, 38.128600184293575],
            [126.955109345114721, 38.15444511225509],
            [126.969836181397085, 38.188600194323911],
            [126.986645250072087, 38.213745065730095],
            [127.005800245814356, 38.229235996308134],
            [127.050118217873916, 38.24999109737108],
            [127.068045264797462, 38.259991210801573],
            [127.100963345592248, 38.28416411671283],
            [127.130818176044073, 38.298191064078154],
            [127.197482302031773, 38.311936044219678],
            [127.251391350645434, 38.31888212740067],
            [127.366382345354538, 38.323327050666919],
            [127.450818285418592, 38.312491093849488],
            [127.537491355446349, 38.307491120953429],
            [127.669009283968734, 38.319718138425699],
            [127.885272277145219, 38.313609072098231],
            [127.97970028401258, 38.307209153729715],
            [128.010527247597309, 38.305545010668581],
            [128.054691327914128, 38.306654104099906],
            [128.079954216517393, 38.311936044219678],
            [128.13707236058994, 38.329300162524405],
            [128.158445207918618, 38.339573190723101],
            [128.176091293447058, 38.35000011066434],
            [128.200809190704405, 38.365545020699003],
            [128.224972373607926, 38.38166425874077],
            [128.243563434903052, 38.395273116774305],
            [128.260527233511112, 38.411664263755981],
            [128.279691281708807, 38.430273094685774],
            [128.297336193770917, 38.459436082847546],
            [128.307736291621922, 38.488600076837642],
            [128.313382341616744, 38.524645110439693],
            [128.306636250646108, 38.560409182646808],
            [128.30850021827942, 38.587564202078099],
            [128.363554234935151, 38.625245047954323],
            [128.379997441000256, 38.627186323000117],
            [128.387266733000075, 38.62804458],
            [128.52369635400018, 38.652521137000136],
            [129.462357642000114, 38.802669947000012],
            [130.321440648000049, 39.047058552000109],
            [130.402482482000181, 39.153060801000109],
            [130.406049175000049, 39.154322595000039],
            [130.776437843000082, 39.28955680300011],
            [132.81555409600017, 40.268984662999955],
            [132.472355450000094, 39.588135114999986],
            [131.87603382100022, 38.366325386000099],
            [131.573410622000239, 37.730323861999963],
            [131.476454180000161, 37.524693206000151],
            [131.475400843000131, 37.522449570000035],
            [131.471341984000134, 37.513671027999962],
            [131.443580430000139, 37.454407194000098],
            [131.401641937000051, 37.365221638],
            [131.389064105000131, 37.333062519000137],
            [131.355716787000262, 37.24683312300003],
            [131.296518189000182, 37.093873832999961],
            [131.24643019900023, 36.937434904000114],
            [131.238189432000155, 36.911468555000042],
            [131.133143444000126, 36.39673464],
            [131.265000000000242, 36.166666667000058],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "KWT", Country: "Kuwait" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [48.749148822000137, 29.609146053000146],
            [48.763725919000223, 29.594982862000037],
            [48.837479904000048, 29.56822056700004],
            [48.93209854300008, 29.534228410000011],
            [49.146388866000194, 29.404191574000023],
            [49.207299540000093, 29.367257847000175],
            [49.218206594000065, 29.351229304],
            [49.42033436900013, 29.058225838999974],
            [49.506367308000137, 28.997325785000086],
            [49.524879616000106, 28.935000000000159],
            [49.445000000000135, 28.935000000000159],
            [48.688333000000199, 28.696944000000101],
            [48.663889000000182, 28.665556],
            [48.589444000000157, 28.638889],
            [48.435977146965655, 28.535979466944738],
            [48.434012775347554, 28.537208511022627],
            [48.42527297799117, 28.542636430500934],
            [48.421212451943319, 28.543869519812844],
            [48.416590767490476, 28.54527319793165],
            [48.302500265705277, 28.544164108955002],
            [47.688882254912613, 28.538882168835229],
            [47.617773205658665, 28.614164232415632],
            [47.6027632286862, 28.634991082570068],
            [47.574991301179438, 28.703053980563553],
            [47.576654103136008, 28.733053985578564],
            [47.574845120787558, 28.770136028244579],
            [47.56470922052543, 28.79666424895477],
            [47.540545199431421, 28.850554019191335],
            [47.506945166992381, 28.923053961462145],
            [47.463391289228497, 28.984464142886608],
            [47.459991254014966, 28.999436066018447],
            [46.996663282148887, 29.053054094953282],
            [46.800282163360208, 29.075273179228944],
            [46.587773256988527, 29.098054018656953],
            [46.548609149567966, 29.103053991553011],
            [46.546945174144838, 29.104200132996553],
            [46.547354043382853, 29.105200094048215],
            [46.558182288849508, 29.121453945455315],
            [46.581654132377167, 29.143327024766833],
            [46.598882296212167, 29.156109091868913],
            [46.635273334778304, 29.178609137539794],
            [46.653836065240711, 29.188482181317895],
            [46.690382169015976, 29.230345093930325],
            [46.714909126518563, 29.266318043165015],
            [46.775554040180879, 29.356382095950906],
            [46.827763238825952, 29.433609096349855],
            [46.858891280373683, 29.475827066381825],
            [46.924018165314664, 29.554718042203646],
            [46.944291306943597, 29.578473194059555],
            [46.98137318197152, 29.646109117904203],
            [47.047763219724544, 29.794164206161796],
            [47.094436171306853, 29.907082188571636],
            [47.113600219504548, 29.946382082823945],
            [47.128873220599388, 29.974718112416198],
            [47.147763348200471, 29.996526986349281],
            [47.169991149655544, 30.015273112853535],
            [47.204300291105284, 30.033327061791496],
            [47.351100273177764, 30.081664156434854],
            [47.396663293138516, 30.082773082228087],
            [47.436663243945958, 30.083054043623306],
            [47.723463305302147, 30.084164142882926],
            [47.934991193723505, 30.017773099301607],
            [47.94347310091203, 30.017554411076205],
            [47.95531485800015, 30.008508852999952],
            [47.956144374000047, 30.007100885999975],
            [47.957416767000183, 30.005592492000048],
            [47.957754197000014, 30.00472782700011],
            [47.960711732000135, 30.00204345000013],
            [47.962247742500125, 30.000904622500101],
            [47.963970545000194, 29.999996774999985],
            [47.966692080000058, 29.998418084000107],
            [47.968719674000027, 29.997551410000071],
            [47.970833634000059, 29.996807256000139],
            [47.973321177000088, 29.996083187],
            [47.97630683200012, 29.995555953000078],
            [47.978279192000144, 29.995268735000124],
            [47.983788543000202, 29.994670198000065],
            [47.984803846, 29.994439219000142],
            [47.987673007000154, 29.993996341999988],
            [47.990576313000048, 29.993624766999986],
            [47.991378714000092, 29.99327227300013],
            [47.993845168000149, 29.992659677000162],
            [47.997718585000172, 29.991927573999973],
            [47.998677650000133, 29.991898450000051],
            [48.001315832000188, 29.991584117999977],
            [48.006608264000221, 29.991089020000047],
            [48.008652931000114, 29.990957462],
            [48.009404114000091, 29.990844985000123],
            [48.010509801000097, 29.990470398000028],
            [48.011688798000108, 29.989910022000018],
            [48.014838147, 29.993746282],
            [48.014634282000117, 29.996483886000121],
            [48.017570729000141, 29.996648584000056],
            [48.022418275000149, 29.999495651000089],
            [48.030666234000108, 30.007250520000113],
            [48.037333493000034, 30.01400113199999],
            [48.044166454000134, 30.019167028000126],
            [48.050000180000012, 30.021916682000082],
            [48.061490884000051, 30.025976891000013],
            [48.068132032000022, 30.027876945000017],
            [48.076114867000086, 30.029313032000104],
            [48.088955291000076, 30.030152590000014],
            [48.098250690000015, 30.029916590000127],
            [48.10864574900009, 30.028435311000138],
            [48.121345577000142, 30.027756434000096],
            [48.133583447, 30.026555343],
            [48.146406799000175, 30.025767002000052],
            [48.154834519000104, 30.024582983000059],
            [48.164384999000191, 30.02210849500004],
            [48.184445023000222, 30.014444009000144],
            [48.194722584000061, 30.004999981000068],
            [48.207332029000128, 29.994167066],
            [48.228148259000108, 29.984680859],
            [48.242962047000134, 29.976034210000094],
            [48.254922742000161, 29.965045635000124],
            [48.275071141000041, 29.95495486500009],
            [48.337912493000061, 29.907029735000108],
            [48.414121504000178, 29.852675355000102],
            [48.419368744000195, 29.850398705000018],
            [48.424154027000014, 29.848316882000162],
            [48.42956395700017, 29.846028181000165],
            [48.432894071000106, 29.844778885999986],
            [48.434973887000154, 29.843739480000053],
            [48.438304001000148, 29.842074423000142],
            [48.443298169000201, 29.839577840999979],
            [48.450164271000148, 29.83604085000006],
            [48.454950558000206, 29.833335383000033],
            [48.473728146000013, 29.823414332000013],
            [48.484999921000139, 29.816584384000052],
            [48.487977542000209, 29.814094831999952],
            [48.49671758700012, 29.807020848999954],
            [48.507507319000155, 29.798629281],
            [48.513075921000137, 29.794182433000046],
            [48.534424406, 29.780240340000134],
            [48.538788905000075, 29.777498720000139],
            [48.540121554000137, 29.776588862999986],
            [48.563732629000157, 29.758216994000108],
            [48.567340923000103, 29.755439221000032],
            [48.5735542580002, 29.750433002000065],
            [48.586971125000019, 29.739048750000038],
            [48.613664264000164, 29.717504435000123],
            [48.650776564000154, 29.68697804900016],
            [48.687359621000127, 29.656484804000101],
            [48.696114729000129, 29.649683978000056],
            [48.701961510000189, 29.645183905000138],
            [48.749148822000137, 29.609146053000146],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "LAO", Country: "Laos" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [102.140745266799541, 22.396282193611412],
            [102.153736210929281, 22.384718017044051],
            [102.180118251247706, 22.326109067668554],
            [102.184909179478183, 22.300409146632475],
            [102.238309278929677, 22.242491201356799],
            [102.277418233427312, 22.215900116372651],
            [102.304836276981092, 22.210136049180832],
            [102.422763284742558, 22.115691110868866],
            [102.448027179174261, 22.066936094532068],
            [102.501782336045352, 21.966800179224307],
            [102.526645240228021, 21.947773091324862],
            [102.555254184588222, 21.940554093375837],
            [102.585536156827033, 21.93680017420921],
            [102.604427290256609, 21.92847308865727],
            [102.647218247191319, 21.853054064778277],
            [102.661654231432721, 21.786809033950661],
            [102.660536253184006, 21.760554063284772],
            [102.654145219632909, 21.734853974610601],
            [102.675191340374994, 21.658536075432707],
            [102.754009226001045, 21.670691176175623],
            [102.861363301067485, 21.718327040797291],
            [102.970545133945535, 21.745136055264695],
            [102.987409187905513, 21.712700101541756],
            [102.990809223119044, 21.606944958329606],
            [102.98747238745554, 21.574300127578894],
            [102.969018286458805, 21.55555400107464],
            [102.944427291215931, 21.528609032137425],
            [102.890682360266709, 21.466109035659017],
            [102.876918269385868, 21.430136086424326],
            [102.90498222240015, 21.440273160153012],
            [102.904709307631919, 21.381936119717338],
            [102.888600295512134, 21.252218121087893],
            [102.919072369315501, 21.228882064391897],
            [102.914436171018139, 21.168054089963661],
            [102.937272331007335, 21.163327031835607],
            [103.042072272530987, 21.045973178614744],
            [103.088045167558022, 20.955554068409398],
            [103.117818190634665, 20.8936091215608],
            [103.177127196565237, 20.84388213972899],
            [103.189091357553224, 20.844200149136427],
            [103.218600183040991, 20.830273114057277],
            [103.335272252255521, 20.80555404333343],
            [103.360209252462454, 20.80213607084697],
            [103.42499130590582, 20.79389112794648],
            [103.605400205235043, 20.740273099011731],
            [103.637354199523799, 20.717909007810775],
            [103.67331826394107, 20.681518136882559],
            [103.687054191627311, 20.659582193296885],
            [103.738382284608036, 20.670964147288643],
            [103.771382340416181, 20.737218062933763],
            [103.778872409114712, 20.784445058317502],
            [103.856645239049868, 20.852500077321864],
            [104.103945246305244, 20.975900132596152],
            [104.194427220784661, 20.943327050937015],
            [104.230272262176442, 20.928882181878365],
            [104.306227224945843, 20.894300125660422],
            [104.330691318174303, 20.87708218774749],
            [104.34421820119465, 20.843154087617151],
            [104.40941817633157, 20.7783271071727],
            [104.5019272302807, 20.712218030814896],
            [104.588172320331097, 20.672636001700695],
            [104.614418238541617, 20.665136042356565],
            [104.641891267380316, 20.652353975254286],
            [104.629972368669627, 20.618609103528044],
            [104.60053613046361, 20.590273073935791],
            [104.516100190399612, 20.517218082035171],
            [104.454436204946234, 20.504445067388488],
            [104.381509288526416, 20.454582131086866],
            [104.393736138360708, 20.435273075963877],
            [104.420536268010693, 20.424164204378215],
            [104.520400274378659, 20.407082053297117],
            [104.552754253088239, 20.414227122859884],
            [104.615463294232399, 20.416036105208221],
            [104.709018242062683, 20.341527020740628],
            [104.715000238737588, 20.313054030850267],
            [104.84491822957736, 20.225064163829913],
            [104.939282366342383, 20.183464107701624],
            [104.984709264195061, 20.098327105254029],
            [104.977827218754584, 20.003954083671616],
            [104.962718167686461, 19.988409005999145],
            [104.899427254822086, 19.973327112297213],
            [104.842209198463451, 19.938882184015668],
            [104.789700262960054, 19.891935982389256],
            [104.790154226837245, 19.86399105240038],
            [104.82651827567517, 19.863882087658695],
            [104.84907230080276, 19.844164163297691],
            [104.832000208005383, 19.787427060544516],
            [104.770263299994241, 19.75694509609535],
            [104.717000328478861, 19.747982159366828],
            [104.679282266998285, 19.707909118363446],
            [104.667209308906678, 19.68721805504093],
            [104.654972400788637, 19.647909108332939],
            [104.644227136163892, 19.616664223054684],
            [104.568882375947425, 19.605554010364656],
            [104.519572309980703, 19.605409003439249],
            [104.449845268926111, 19.645273167415141],
            [104.42276333969059, 19.669853936736004],
            [104.246091260445581, 19.704435992953862],
            [104.220536178696847, 19.703191112690817],
            [104.158736238773571, 19.687491137447026],
            [104.149991231528105, 19.681673090798668],
            [104.141591223418374, 19.66263611225348],
            [104.087418312491849, 19.664300087676438],
            [104.05831818860409, 19.682773131774482],
            [104.038827246181683, 19.693735990606328],
            [104.021418200875615, 19.669118005635156],
            [104.038882231466602, 19.620273135295989],
            [104.050536262036275, 19.599444944037273],
            [104.078945214186177, 19.569164145264935],
            [104.101645252067414, 19.55860914984261],
            [104.105945168408454, 19.522636032969942],
            [104.103863271291885, 19.478609080589493],
            [104.041363274813449, 19.418464231271841],
            [103.971645118576276, 19.392082190953445],
            [103.877682307336357, 19.309509045056402],
            [104.006927231349351, 19.233400022906196],
            [104.061091257458401, 19.238609040468091],
            [104.174572336125237, 19.190545028231028],
            [104.226018278664952, 19.145064150921698],
            [104.220754108179989, 19.111326990546289],
            [104.278809181392063, 19.110900184035543],
            [104.530263427510846, 18.972218075210904],
            [104.549145173208927, 18.941664194032356],
            [104.573036279534648, 18.902982213683927],
            [104.672209281802878, 18.836936001599994],
            [104.74456337895802, 18.796245046692846],
            [104.935254191303073, 18.744164091166752],
            [105.029982270305055, 18.720827028642347],
            [105.105554180098267, 18.69666418101481],
            [105.146100295718185, 18.673327118490491],
            [105.193172393530773, 18.636727035258545],
            [105.194136144760506, 18.610000163442479],
            [105.150272304216372, 18.598744943827043],
            [105.11525422139411, 18.517636116418757],
            [105.103872267402295, 18.470273166565207],
            [105.108727233373116, 18.437982052129612],
            [105.183318292854011, 18.334445095779998],
            [105.267072281273869, 18.252500089708505],
            [105.308518278021239, 18.256145044133646],
            [105.32582724340304, 18.242773226322399],
            [105.321927143844533, 18.196682146460134],
            [105.332209224498655, 18.1861090461271],
            [105.35733615863208, 18.168191051658951],
            [105.399982276279474, 18.157564139507272],
            [105.504436212839181, 18.168327006128763],
            [105.582209210412344, 18.048609120929811],
            [105.627245176299851, 17.98750001746815],
            [105.681927204026664, 17.814445064729696],
            [105.754300244282973, 17.670409099055817],
            [105.795536190536239, 17.653327115612697],
            [105.832836162685425, 17.640200049374855],
            [105.8608092558691, 17.618609104924943],
            [105.892209206356455, 17.584436085583192],
            [105.931927189940353, 17.529164139509319],
            [106.021382381277959, 17.426109142593575],
            [106.039145142898946, 17.40666413300049],
            [106.090127230915783, 17.356800023232481],
            [106.151382347131033, 17.304718061877892],
            [106.211645213645653, 17.261664080820893],
            [106.248663386209245, 17.246453944000095],
            [106.340818220929094, 17.169164079327274],
            [106.420536155320605, 17.064718021756605],
            [106.43420033391547, 17.011364190410561],
            [106.441227218643149, 16.992491161891891],
            [106.520200337116393, 16.967354001836654],
            [106.561100336689407, 16.996936085158339],
            [106.555254126846222, 16.93804500092115],
            [106.555254126846222, 16.888744993238348],
            [106.560672356712047, 16.677426987673172],
            [106.575272290979939, 16.642218132734044],
            [106.684700216535958, 16.459300026863488],
            [106.702209174128001, 16.439718056972183],
            [106.756645109176958, 16.420344964108679],
            [106.782491210604832, 16.432500064851482],
            [106.813872385629082, 16.473045007005013],
            [106.829163323996653, 16.506664150183425],
            [106.84338221694523, 16.532354012935613],
            [106.875118281750844, 16.536873199864388],
            [106.895263347899146, 16.517773189407066],
            [106.983872302289654, 16.337218109686276],
            [106.98720025313574, 16.299718145326736],
            [107.010554247104579, 16.307364117424683],
            [107.03054525387185, 16.306382093646036],
            [107.093891319659207, 16.300818018664572],
            [107.147218161276811, 16.263335991577847],
            [107.14860922193165, 16.23308218253392],
            [107.152072289057372, 16.205827083178193],
            [107.165118218472259, 16.183744959200922],
            [107.191654150533367, 16.16082699183643],
            [107.337618289218909, 16.056109025325938],
            [107.362491251685384, 16.06277314128333],
            [107.382472367807026, 16.067500031773292],
            [107.460818184644864, 16.080482091085727],
            [107.47530026935371, 16.027335963301155],
            [107.475818270971416, 16.022500108069508],
            [107.463609190771962, 16.003891109501652],
            [107.401936152863158, 15.918473145658751],
            [107.2555452076667, 15.858473135628415],
            [107.229291242829078, 15.866391184304362],
            [107.203945205746066, 15.858953921595898],
            [107.176300347891726, 15.790345026428199],
            [107.18303621294038, 15.765418084504986],
            [107.263045167010915, 15.647782096422517],
            [107.361100191030602, 15.556391188360138],
            [107.420254131751989, 15.503336088044236],
            [107.517491249657468, 15.468327057677456],
            [107.596363282378064, 15.383891117613402],
            [107.684709212646368, 15.288609162448196],
            [107.695254149784688, 15.270827122449859],
            [107.684709212646368, 15.234718051107265],
            [107.681582259839018, 15.226482160661973],
            [107.670254117665735, 15.205273095721864],
            [107.640545132329606, 15.128053974312039],
            [107.615818182616835, 15.063609041015368],
            [107.58679131656308, 15.045554086248799],
            [107.473309232067919, 15.035827055224601],
            [107.46803617676548, 15.023191168514316],
            [107.479982232842616, 14.971454038657313],
            [107.540118197342764, 14.918891123697264],
            [107.551654210715384, 14.847500107219687],
            [107.54942713903543, 14.724445051081133],
            [107.546600258362588, 14.708618006184778],
            [107.473036149661624, 14.640000058561597],
            [107.347491332996896, 14.595273217264108],
            [107.253600270848352, 14.53110907772448],
            [107.234154255427057, 14.497218025606443],
            [107.169145220045067, 14.438891043454575],
            [107.118445266890006, 14.403891065543107],
            [106.850263424195276, 14.303891104705357],
            [106.803454182867057, 14.340973147371287],
            [106.765263382045958, 14.388891146854647],
            [106.752618275242213, 14.409418092512553],
            [106.703327152376716, 14.435000163989642],
            [106.628863330186419, 14.465973140328188],
            [106.60080926781788, 14.509445043078628],
            [106.534491314432302, 14.597391156564399],
            [106.474563388769553, 14.564164118817573],
            [106.431372279776127, 14.508053982423874],
            [106.432209296629566, 14.485553936752993],
            [106.408736279635349, 14.458054085823946],
            [106.322627144054678, 14.448473067553365],
            [106.305263361026022, 14.459718061246903],
            [106.290282217800694, 14.481364158620025],
            [106.251927299314872, 14.488882055237085],
            [106.247209293642328, 14.448191100329851],
            [106.239418314618973, 14.413609044111908],
            [106.221927294299775, 14.381664102278521],
            [106.169491281354141, 14.365826999098445],
            [106.14012729515369, 14.378473111730713],
            [106.099845209484471, 14.38138213505492],
            [106.000818219969943, 14.36721805975337],
            [105.996227283949821, 14.331664205678592],
            [106.034563426972369, 14.23687309476459],
            [106.119427179375776, 14.135273196244199],
            [106.167627146082651, 14.081391137358693],
            [106.174218171844103, 14.058609124464098],
            [106.111100261461814, 13.945827096524155],
            [106.056636163218144, 13.930000051627886],
            [105.947200191035137, 13.926664221792876],
            [105.910282266033818, 13.932845037211635],
            [105.88860029411515, 13.950553987014032],
            [105.808027237959294, 14.016945030595551],
            [105.793454125781665, 14.032918088245438],
            [105.784563273420559, 14.076318074266496],
            [105.733263343634491, 14.110927120212764],
            [105.559127293021419, 14.168327063870819],
            [105.495954229716091, 14.13513606830783],
            [105.439700259863372, 14.115273137021333],
            [105.374772361304366, 14.106527123947544],
            [105.28297241636588, 14.178264145389093],
            [105.209163388453504, 14.288682141350947],
            [105.210600214299774, 14.349645064420699],
            [105.225609185443858, 14.342353982104228],
            [105.2974912138109, 14.369444963795175],
            [105.370527262610295, 14.404718024112739],
            [105.444427150353334, 14.450553958841638],
            [105.493318288798122, 14.503891194019147],
            [105.515263284839165, 14.53110907772448],
            [105.535400304360309, 14.563891036411476],
            [105.53637226985515, 14.607636021568297],
            [105.528318266709476, 14.648054061707327],
            [105.515963341394325, 14.815553991919828],
            [105.545254237398893, 14.858609146443413],
            [105.566382333154166, 14.883053961294465],
            [105.5772092375162, 14.911664079121223],
            [105.5905542332371, 15.000553994907037],
            [105.524427219606451, 15.070273156972647],
            [105.471163409900981, 15.11020907003963],
            [105.474700237774726, 15.174718041076844],
            [105.537072326410367, 15.395273071605331],
            [105.565809178613421, 15.405836113654559],
            [105.583318303843583, 15.416944985240022],
            [105.599991250410682, 15.433053997359892],
            [105.600327197090934, 15.436682187978604],
            [105.606372393315922, 15.471109011349157],
            [105.631236135688908, 15.585691136820131],
            [105.639291312301083, 15.639444952586686],
            [105.635127182791933, 15.669164163844854],
            [105.603863354412482, 15.720064109210156],
            [105.557482260699686, 15.748882098236194],
            [105.507754105401347, 15.766527010298248],
            [105.48450019135683, 15.761873210004225],
            [105.442336200781398, 15.761317992736323],
            [105.401091202072706, 15.807218132843545],
            [105.389427280857234, 15.828054035453405],
            [105.345954204640265, 15.926035969277038],
            [105.371372326090778, 15.97332700240112],
            [105.385545118571684, 15.989927026410371],
            [105.429745241072141, 16.000464084559681],
            [105.422718188706341, 16.009373209469757],
            [105.308591200579002, 16.038609120189406],
            [105.276382228794859, 16.043891060309178],
            [105.245245134791702, 16.04639104675725],
            [105.22026337522172, 16.050273209042828],
            [105.18887230955167, 16.056382107732233],
            [105.080672332814373, 16.095000050340317],
            [105.05748228887208, 16.115000109563127],
            [105.044854113512685, 16.132909051575965],
            [105.028672346473144, 16.175553995756886],
            [105.024291293309176, 16.226664159254497],
            [105.012500302441225, 16.251664191373564],
            [104.992482305945572, 16.276945017249645],
            [104.978854169534571, 16.28759104014064],
            [104.96192725130058, 16.299718145326736],
            [104.909982250244383, 16.346245084155257],
            [104.749572404309703, 16.524445016715376],
            [104.742618274501723, 16.566245065053849],
            [104.753054246898529, 16.636382148812956],
            [104.760127232093765, 16.657773101052427],
            [104.767972358211836, 16.691382185947035],
            [104.768736284869391, 16.724718020797255],
            [104.760818236193558, 16.753327132795718],
            [104.745527297825873, 16.910273073414302],
            [104.742418282291368, 16.946718091437148],
            [104.739963390482473, 16.973191159224299],
            [104.741082374559511, 17.010691123583754],
            [104.746372361306413, 17.03763609252097],
            [104.77442726186527, 17.091109114530397],
            [104.797491241983352, 17.133327084562481],
            [104.802463386960909, 17.152773099983776],
            [104.809145104914961, 17.190273064343316],
            [104.8144181602174, 17.242491147805694],
            [104.81360025410325, 17.286109063310036],
            [104.809418187321143, 17.361382074435127],
            [104.805545245129139, 17.381936009821246],
            [104.793036260433126, 17.40819114812534],
            [104.718318298937874, 17.503327090536928],
            [104.692054108178439, 17.528535999683655],
            [104.641027260798666, 17.557709046129148],
            [104.461372397481313, 17.64930011403986],
            [104.41748223776122, 17.703882061842435],
            [104.401654187036542, 17.728117999665827],
            [104.335536225861318, 17.826382068351265],
            [104.285818296485019, 17.845553995538054],
            [104.26638233934753, 17.865000178597413],
            [104.257000307458981, 17.887636011100128],
            [104.239436196943785, 17.93721798600653],
            [104.215754135283845, 17.989018147775496],
            [104.195536146577894, 18.015000036035204],
            [104.101791264821117, 18.109436089529666],
            [104.091936158316145, 18.146382177725783],
            [104.071927214275803, 18.199718071798813],
            [104.04692718215685, 18.238327129589479],
            [103.97615424722116, 18.326873219706201],
            [103.940672309875907, 18.326664175040364],
            [103.842482337214705, 18.311109039084002],
            [103.75942723188362, 18.337773214264089],
            [103.619218275572479, 18.388400077223295],
            [103.50776327054686, 18.421664162982054],
            [103.417482294106122, 18.43443600416235],
            [103.397218204932614, 18.43499105379216],
            [103.371372271142803, 18.431109059144646],
            [103.301091353924761, 18.41902704859767],
            [103.276382341484833, 18.40221814756076],
            [103.242200269687686, 18.365409019662948],
            [103.241091343894453, 18.339026979344553],
            [103.256654191201818, 18.275000135379386],
            [103.140827185467714, 18.165273143517354],
            [103.120263359435882, 18.148745036237457],
            [103.069418231717322, 18.123027010290471],
            [103.068409218210178, 18.028826991190087],
            [103.047127230712221, 17.997009119009178],
            [103.015891230251356, 17.979991173306516],
            [102.97838221343639, 17.998809048902146],
            [102.946509188332385, 18.005973229204201],
            [102.858600290496923, 17.974718118004049],
            [102.841372294300101, 17.962491100531778],
            [102.730554146279559, 17.883053959897396],
            [102.683591348484669, 17.82000008725565],
            [102.610682369337866, 17.886727077517335],
            [102.612763428264003, 17.918609155076652],
            [102.604218245590943, 17.946800177743498],
            [102.544145145364581, 17.971664087754576],
            [102.523327180027621, 17.973327057349124],
            [102.472491272402664, 17.970553988494842],
            [102.423736256065894, 17.981936110124778],
            [102.299145132347036, 18.047635981968298],
            [102.25776334097813, 18.087773228350287],
            [102.213318299266206, 18.138327001113552],
            [102.179982296777865, 18.190964179735928],
            [102.157063323585106, 18.20422703280569],
            [102.108218285607933, 18.209445102823011],
            [102.089391357556821, 18.214982188076149],
            [102.070827285990077, 18.208327124574353],
            [102.047763305871939, 18.191664236291146],
            [101.999418164601508, 18.127491044296121],
            [101.910672249912608, 18.036800025150953],
            [101.885054136251796, 18.03139118301668],
            [101.850809200180578, 18.044164197663363],
            [101.784145241831055, 18.063536117060423],
            [101.761936215839199, 18.038327040275803],
            [101.74165418939279, 17.972773181185786],
            [101.689145253889279, 17.905827087974572],
            [101.63609132703985, 17.889582121384862],
            [101.577209295258143, 17.860064243441613],
            [101.559709222483519, 17.813836035643149],
            [101.581063294348979, 17.797354028830981],
            [101.497218278460451, 17.743464090956522],
            [101.355818253704712, 17.657217995077715],
            [101.309709236569716, 17.624718003614433],
            [101.227172300494402, 17.531353995539078],
            [101.15970921149389, 17.460754063085389],
            [101.075754225035354, 17.499853965127343],
            [101.033454112351961, 17.541244976589795],
            [100.987900312484925, 17.561527003036204],
            [100.963045119653117, 17.565554004609041],
            [100.941309168277911, 17.564344998891627],
            [100.92216339262913, 17.568954039822685],
            [100.911227188249399, 17.599445056727163],
            [100.924145209821432, 17.619164154554753],
            [100.953454210736908, 17.649718035733301],
            [100.988454188648376, 17.732073084509196],
            [100.984391312529709, 17.750144970720257],
            [100.980554244883336, 17.763053939836752],
            [100.989700242377779, 17.806109094360338],
            [101.028809196875358, 17.897982129494679],
            [101.063182376065441, 17.914582153503929],
            [101.087209269223052, 17.930136115993903],
            [101.113736148828792, 17.957082090759528],
            [101.143336169423293, 18.022500163017511],
            [101.170818250717531, 18.087218011082385],
            [101.176654234638789, 18.175273089309755],
            [101.154572278299412, 18.345000091202039],
            [101.095818154360558, 18.373954034698059],
            [101.086691267605403, 18.387654087838527],
            [101.059000309283391, 18.418609126904258],
            [101.057827178111751, 18.441591132009009],
            [101.106991231324599, 18.518745042211904],
            [101.147218164070779, 18.540554083783078],
            [101.180118307592778, 18.560136053674469],
            [101.241091288946421, 18.637635968841337],
            [101.267972387781327, 18.684582170467834],
            [101.236927159437215, 18.741382137494966],
            [101.249709226539409, 18.86888211689994],
            [101.253945105139991, 18.894791082601884],
            [101.29998237318361, 18.975826987452209],
            [101.318600256568828, 18.996382096304828],
            [101.339563396469231, 19.010691178531758],
            [101.353463274181962, 19.044373185984128],
            [101.305818189466748, 19.092773144901443],
            [101.289982259753145, 19.106109088167059],
            [101.202472340509956, 19.353882170038887],
            [101.201100222956342, 19.374582118178822],
            [101.215854216604981, 19.46149105496265],
            [101.264363307901903, 19.474373201988996],
            [101.285127293782267, 19.520553968215282],
            [101.279082265195228, 19.566245063656751],
            [101.259291250638313, 19.585691079078245],
            [101.225109178841052, 19.594382106866973],
            [101.086109228247238, 19.590273130280977],
            [101.063600297758938, 19.605273216607529],
            [101.032200179633406, 19.619164209502841],
            [100.958600196386868, 19.621382061089221],
            [100.912200327210854, 19.619718085666179],
            [100.883882234891502, 19.598327133426622],
            [100.867482370730755, 19.57750011563418],
            [100.852200317180433, 19.560000042859471],
            [100.813600311845249, 19.520553968215282],
            [100.773882328261237, 19.485753982514254],
            [100.755336193967338, 19.507909029049486],
            [100.700000210152922, 19.533609117723657],
            [100.640882311615229, 19.550618010970922],
            [100.613027235628664, 19.538191168926389],
            [100.5849542301589, 19.493973109153117],
            [100.578945243755669, 19.493464159990836],
            [100.516391267820637, 19.518891166258712],
            [100.490882286539403, 19.537773079594885],
            [100.445536190233582, 19.627773094640318],
            [100.407563318895626, 19.73855402701075],
            [100.404991248080051, 19.749027047419517],
            [100.411236268877389, 19.771800175496693],
            [100.446091239863364, 19.81500016930751],
            [100.486100243126401, 19.859435990925974],
            [100.506236256819108, 19.87853600138321],
            [100.550945160843781, 20.017636031901176],
            [100.570972377432867, 20.091664162763223],
            [100.5804633740631, 20.157773071482936],
            [100.574827214714077, 20.162464087427352],
            [100.521027130841816, 20.145827015405885],
            [100.45786328763009, 20.188082201088079],
            [100.418727175766236, 20.238191062429578],
            [100.387618244957793, 20.308473153114008],
            [100.379282274588462, 20.337082097474294],
            [100.35990918172493, 20.365545029080849],
            [100.343318210171248, 20.382218143285954],
            [100.321100299361973, 20.391527084978648],
            [100.287491214467565, 20.392773138708165],
            [100.260891244665885, 20.386109022750887],
            [100.227200184758004, 20.356664235003578],
            [100.165545251760165, 20.291935993378829],
            [100.1052632745062, 20.309436066153452],
            [100.091372281610717, 20.348609058391432],
            [100.11261822692515, 20.37569115526496],
            [100.125672370605031, 20.401245063547336],
            [100.141372345848794, 20.489436096244518],
            [100.149427187184784, 20.544718100602395],
            [100.172763411518844, 20.62790916040332],
            [100.209436249670688, 20.69305398261703],
            [100.258745142170795, 20.74902699107453],
            [100.290545244717038, 20.771664164681781],
            [100.371718277503788, 20.826182074744011],
            [100.441654195586153, 20.818327057980113],
            [100.462200251983262, 20.813054002677674],
            [100.496936199943804, 20.80582712573964],
            [100.5261001939339, 20.804582077838504],
            [100.560536237398054, 20.813891187169261],
            [100.604709202532177, 20.833882193936674],
            [100.617863426136381, 20.840453941320632],
            [100.64054519146859, 20.861736096456795],
            [100.642527176299069, 20.88489110404366],
            [100.610536133998011, 20.888609148664543],
            [100.575545208542138, 20.876664098415787],
            [100.543872343286438, 20.867773078416619],
            [100.521718302579615, 20.876664098415787],
            [100.520682299344259, 20.951936103712669],
            [100.54677231415522, 21.026109073861832],
            [100.570682363582279, 21.032082185719403],
            [100.602909272639266, 21.038609006102419],
            [100.624563416639404, 21.054718018222118],
            [100.671918152227732, 21.128053971518042],
            [100.699636267916134, 21.182636086958681],
            [100.703172257599505, 21.227500056192568],
            [100.727209209041035, 21.307809082397526],
            [100.774372334322294, 21.300973137424691],
            [100.81817230476409, 21.299582076769852],
            [100.83915422012754, 21.304300082442396],
            [100.862482230196548, 21.316664227851149],
            [101.001363325402934, 21.398327099060751],
            [101.100818294894765, 21.465409146741891],
            [101.127200335213189, 21.49082710055437],
            [101.162136275384086, 21.536391126343361],
            [101.148236230033291, 21.572635984517873],
            [101.184709243612843, 21.558891172014327],
            [101.264845267335858, 21.374435992734945],
            [101.247209240091337, 21.333327116134271],
            [101.234636217654923, 21.252009076422141],
            [101.253600273642434, 21.210409020293852],
            [101.291927196571379, 21.176873193233362],
            [101.338172335814392, 21.188744985648086],
            [101.387491286598419, 21.223191087396131],
            [101.508609284907692, 21.246800058860245],
            [101.570545179300865, 21.247218148191664],
            [101.608872269868044, 21.234718048313184],
            [101.677063410980367, 21.196382072928699],
            [101.703327266463731, 21.175000173144539],
            [101.732563344821443, 21.139491078432926],
            [101.785954224179335, 21.144509156239891],
            [101.845263397748028, 21.216109049745214],
            [101.849518219449834, 21.239535966271902],
            [101.848454220657828, 21.253464174817438],
            [101.806509165393919, 21.285553955938141],
            [101.768663363708498, 21.29290907599524],
            [101.740954132837572, 21.313891158996782],
            [101.743309279998385, 21.357773104451823],
            [101.760536270366941, 21.453327136194915],
            [101.767491238365238, 21.496664090303952],
            [101.781663360293777, 21.653464185807096],
            [101.752345139284813, 21.732073194405288],
            [101.765263328494967, 21.834445066210421],
            [101.672763327001377, 21.933327048799626],
            [101.627127216844798, 21.97040909146564],
            [101.57443622640389, 22.209164083685906],
            [101.625945200855739, 22.28069105463338],
            [101.675472358115172, 22.388400187119387],
            [101.661927202545854, 22.411527031511369],
            [101.67095417701492, 22.443327134057526],
            [101.691363273113751, 22.472218045641412],
            [101.741509182467468, 22.497773127390374],
            [101.773518162041313, 22.499927108874388],
            [101.799991229828464, 22.489435983554515],
            [101.925263299363252, 22.437354022199955],
            [102.030963289652362, 22.454853927336686],
            [102.114845185915016, 22.432982189129532],
            [102.140745266799541, 22.396282193611412],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "LBN", Country: "Lebanon" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.623636184896128, 33.24572700202468],
            [35.585545128723055, 33.270273237904476],
            [35.542773282527747, 33.196527074266058],
            [35.525691299084514, 33.126727110653675],
            [35.505827194331715, 33.114991105070871],
            [35.505273318168207, 33.098053960914868],
            [35.425554042672303, 33.068327038305867],
            [35.377782223580738, 33.06208218514665],
            [35.35193612215275, 33.062500106840091],
            [35.321936117137653, 33.088054015122466],
            [35.265000195640624, 33.09860917818277],
            [35.109445148038702, 33.095553974466796],
            [35.100626209333853, 33.093568893494577],
            [35.068249048500178, 33.094658155],
            [35.041390591000066, 33.103452253000043],
            [34.994837766000131, 33.118699739000149],
            [34.119903183500156, 33.39004513650012],
            [33.933659511000059, 33.447805297999977],
            [33.783646076, 33.514298949000036],
            [33.749291179000153, 33.530982881999975],
            [33.847623296000137, 33.613787357000021],
            [33.848377716000215, 33.612754131000074],
            [33.859632532, 33.623899383000136],
            [33.901892532000119, 33.673387383000104],
            [33.911898280000202, 33.682127878000145],
            [33.955501789000124, 33.72021717899996],
            [33.985652714000111, 33.746607077000149],
            [34.028669749500096, 33.780489553500004],
            [34.031760134000052, 33.783286357000108],
            [34.063677532000185, 33.812171383000148],
            [34.073465080500029, 33.821041616000159],
            [34.074072864000101, 33.8216053310001],
            [34.142507385000073, 33.879399504999967],
            [34.212225532000133, 33.938277384000159],
            [34.222623326000161, 33.94713820600002],
            [34.228671241000171, 33.952291938000016],
            [34.23403891950025, 33.95687760050005],
            [34.283033764000123, 33.98416429600006],
            [34.285473495000105, 33.98593470600018],
            [34.286152735000059, 33.986427566000103],
            [34.585009914000153, 34.254018275000149],
            [34.703588994000057, 34.360133467000068],
            [34.740618359000081, 34.411474141000028],
            [34.774683532000125, 34.464978384000048],
            [34.833100532000032, 34.556935384000113],
            [34.840282711000071, 34.568281261000081],
            [34.858769821000095, 34.597485490999972],
            [34.889286016000113, 34.645698903],
            [34.90604661800009, 34.687150944999971],
            [34.914158533000062, 34.70721238400013],
            [34.937556027000113, 34.764241450000114],
            [34.965692911000161, 34.832821350000032],
            [35.171294533000179, 34.795419384000056],
            [35.518702533000095, 34.731481384000162],
            [35.581287533000051, 34.714001383999985],
            [35.727045533000165, 34.669497384],
            [35.746290533, 34.663895384000128],
            [35.756041533000172, 34.661039383999977],
            [35.760618533000155, 34.658598384000172],
            [35.769393533000112, 34.65353638400002],
            [35.770347533000091, 34.652979384000119],
            [35.781486533000162, 34.645983384000104],
            [35.785380533000222, 34.643429384000072],
            [35.790424533000106, 34.640325384],
            [35.797465533000121, 34.635933384000154],
            [35.800221533000155, 34.63396138399996],
            [35.810028533000064, 34.626976384000145],
            [35.820826533000087, 34.618750384000137],
            [35.825280533000097, 34.615307384000104],
            [35.827549533000166, 34.613527384000079],
            [35.83556153300006, 34.615822384000168],
            [35.933510033000147, 34.642395884000095],
            [35.967733533000143, 34.647348384000153],
            [35.97277329876627, 34.647500064235984],
            [36.03082719827816, 34.63250014578351],
            [36.087491210835225, 34.633891206438349],
            [36.112973202388304, 34.638764109682015],
            [36.114463337138915, 34.64001804240057],
            [36.121936139116855, 34.644164234636847],
            [36.2985912869174, 34.641935989490506],
            [36.376391274218889, 34.639445055497831],
            [36.459300199158207, 34.630409028573453],
            [36.452773211137213, 34.594718046562363],
            [36.397500259234931, 34.554445013348456],
            [36.36416325091821, 34.538054034005043],
            [36.351227124435212, 34.500745009400461],
            [36.429991198242618, 34.501936077845144],
            [36.454436180731847, 34.49139114070671],
            [36.525836082026757, 34.431936122022407],
            [36.546663267457262, 34.344164184485138],
            [36.593609133807689, 34.228191165997416],
            [36.623745093292627, 34.204991063771317],
            [36.516936177210169, 34.107218006975344],
            [36.397500259234931, 34.033882221317398],
            [36.350273263851278, 33.997218100344995],
            [36.327918225105719, 33.974991137080409],
            [36.283463292747939, 33.910900087736721],
            [36.329445072592392, 33.878327006077583],
            [36.357773223195437, 33.879927111398175],
            [36.38874519370566, 33.854227022724004],
            [36.373954152044774, 33.831109063149356],
            [36.353336178918113, 33.826945101278127],
            [36.240827233384351, 33.856382177674604],
            [36.071036193751439, 33.827527140636263],
            [36.017218172606391, 33.769718160102272],
            [35.971109155471282, 33.718191080739516],
            [35.940200216873365, 33.644164123343771],
            [35.967218108368371, 33.640691165572449],
            [36.025554142975722, 33.619164258862881],
            [36.05874513853874, 33.58561803824243],
            [36.034436110519408, 33.553327091444899],
            [35.934163234913427, 33.466664247338954],
            [35.883327159650293, 33.429573152217628],
            [35.8520001317207, 33.417291149460212],
            [35.826382185697952, 33.403264202095087],
            [35.814718096844416, 33.371109042129362],
            [35.794163323267895, 33.350554100914835],
            [35.786663363923736, 33.347218103441733],
            [35.769436205917117, 33.341109037114151],
            [35.761718149451639, 33.337609089614531],
            [35.626663225417389, 33.276318098853423],
            [35.623636184896128, 33.24572700202468],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "LBR", Country: "Liberia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-8.469745963480364, 7.561327071361916],
            [-8.436117767846554, 7.531426978632979],
            [-8.415836747228525, 7.49639112617588],
            [-8.409717790255229, 7.453535963862649],
            [-8.409999757478772, 7.420282104025674],
            [-8.403054847764423, 7.377218064684769],
            [-8.391945976178874, 7.315273117836085],
            [-8.350554964716423, 7.22944494365079],
            [-8.32333674573502, 7.203054018515061],
            [-8.28666390758309, 7.178473081555921],
            [-8.290554954686201, 7.022782079484188],
            [-8.308890870847762, 6.858054044659397],
            [-8.327499869415647, 6.810553966869733],
            [-8.339726719250024, 6.784445008957363],
            [-8.364163822750243, 6.739445085253678],
            [-8.378608859446985, 6.715553978927872],
            [-8.418054934091174, 6.667218057750986],
            [-8.435072879793751, 6.652418131272768],
            [-8.446808885376669, 6.656127123438168],
            [-8.457781802492406, 6.636453958440299],
            [-8.48288191453554, 6.620208991850603],
            [-8.504345956970951, 6.605345027631856],
            [-8.528472930052828, 6.595864089285627],
            [-8.540136683630209, 6.561945041610869],
            [-8.567017782465086, 6.552118098300411],
            [-8.578399904094908, 6.52930909567759],
            [-8.602917809142014, 6.522982099866951],
            [-8.606381882095917, 6.507818063513767],
            [-8.544099815100594, 6.492153962815721],
            [-8.475281875267115, 6.435835955222572],
            [-8.374445903404165, 6.360554059280062],
            [-8.169726802589338, 6.275000140967265],
            [-8.143608792221841, 6.275554017130773],
            [-8.122217839982085, 6.277782094639136],
            [-8.067499770071635, 6.29360913953532],
            [-7.999445924533546, 6.300554049249826],
            [-7.897781820634492, 6.261944991458975],
            [-7.875836824593421, 6.245282103175938],
            [-7.82569980005718, 6.206109110937859],
            [-7.78916392220367, 6.07055395492084],
            [-7.779717852574692, 6.014309037523532],
            [-7.783472945207791, 5.978053953427136],
            [-7.757636734425716, 5.94666422886165],
            [-7.687572908500613, 5.910764202184723],
            [-7.654999826841589, 5.938054002619623],
            [-7.563608751141032, 5.88305396548536],
            [-7.498054892051215, 5.843335981901546],
            [-7.447845950785535, 5.860073133847081],
            [-7.425690904250132, 5.845554001126004],
            [-7.427781853822182, 5.811391040068074],
            [-7.433054909124735, 5.789445038198608],
            [-7.436108771736144, 5.743609103469808],
            [-7.431663848469697, 5.715000159109522],
            [-7.405554890557511, 5.646391096303645],
            [-7.399763833637451, 5.631427052160817],
            [-7.372499849464504, 5.569445057300101],
            [-7.391390815255988, 5.540835945301566],
            [-7.416390847374942, 5.485836075805565],
            [-7.436390906597751, 5.433891074749383],
            [-7.425972871473817, 5.395973188696416],
            [-7.384181875590684, 5.368382142660707],
            [-7.368399757695556, 5.325691098012001],
            [-7.503681831306636, 5.095418088914897],
            [-7.560554888529708, 5.051391136534448],
            [-7.588336706682185, 4.905836034725027],
            [-7.590836693130257, 4.821945086006878],
            [-7.587426767270813, 4.810818109510421],
            [-7.561390899554652, 4.781527045867847],
            [-7.557781819675341, 4.744164209444705],
            [-7.55110881890073, 4.605973112509517],
            [-7.554445989840133, 4.537218036949923],
            [-7.559999838899898, 4.454445066480602],
            [-7.556045927522831, 4.378091125118914],
            [-7.525399894606039, 4.352809113184279],
            [-7.531275179938319, 4.351245405768537],
            [-7.532596517999878, 4.353148427000249],
            [-7.533101216859478, 4.350759405943805],
            [-7.534519788999859, 4.343974196000033],
            [-7.538009670999855, 4.32486822900016],
            [-7.541526526999974, 4.297144638000191],
            [-7.545818372999889, 4.163815907000142],
            [-7.539900772999829, 4.093270466000121],
            [-7.540009070999815, 4.078498284000091],
            [-7.540017033999845, 3.061315299000086],
            [-7.540034416999788, 2.044021665000258],
            [-7.540061348999956, 1.02742039525009],
            [-7.607657506999857, 1.020989353000076],
            [-7.609750506999944, 1.021178353],
            [-7.611641506999888, 1.021018352999988],
            [-7.613563507000038, 1.021191353],
            [-7.615625506999834, 1.021016353000093],
            [-7.617547506999983, 1.021189353000011],
            [-7.619609506999979, 1.021014353000012],
            [-7.621187506999888, 1.021156353000038],
            [-7.623835506999939, 1.020973853000129],
            [-7.624596506999865, 1.020951353000044],
            [-8.202668507999931, 1.072859353000055],
            [-8.211562507999872, 1.075262353000014],
            [-8.314295507999816, 1.084438353000024],
            [-8.87476750799982, 1.235539353000092],
            [-9.181670508999872, 1.379045353000038],
            [-9.367403508999871, 1.428930353],
            [-9.368205008999951, 1.429263853000052],
            [-9.396046508999973, 1.442171853000033],
            [-9.399305508999987, 1.443640353000035],
            [-9.400302508999857, 1.443908353000012],
            [-9.403498508999888, 1.445400353],
            [-9.41028250899987, 1.447221353000032],
            [-9.936426508999972, 1.692649353999968],
            [-9.971353508999869, 1.717094354],
            [-10.079110509000031, 1.767288354000129],
            [-10.080122509, 1.76799635400009],
            [-10.100056508999899, 1.777279354000072],
            [-10.102924508999877, 1.779285354000137],
            [-10.111018508999905, 1.783054354000058],
            [-10.115305508999967, 1.786053354000032],
            [-10.116013508999913, 1.786382354000082],
            [-10.116797508999866, 1.786931354000032],
            [-10.121006508999869, 1.788891354000015],
            [-10.143542508999957, 1.804655354000047],
            [-10.264676509999873, 1.861037354000047],
            [-10.283556509999897, 1.87423735400003],
            [-10.385384509999824, 1.921607354000088],
            [-10.38706950999989, 1.922716854000129],
            [-10.404165509999814, 1.934595354000024],
            [-10.467184509999896, 1.963899354],
            [-10.550776509999821, 2.022294354000024],
            [-10.705578509999953, 2.094213354000019],
            [-10.706785509999889, 2.095056354000135],
            [-10.70787800999986, 2.095631854000047],
            [-10.713552509999886, 2.098335354000014],
            [-10.913935509999845, 2.238158354000049],
            [-10.924058509999867, 2.242855354000014],
            [-10.930284509999865, 2.247197354],
            [-10.955226509999818, 2.258826354000121],
            [-10.960975509999969, 2.261550354000079],
            [-10.961837509999867, 2.262151353999982],
            [-10.964962509999879, 2.263601354000144],
            [-10.966695509999823, 2.264752854000022],
            [-11.443428510999922, 2.596821354000056],
            [-11.479236510999897, 2.632431354000175],
            [-11.568249510999919, 2.694358354000045],
            [-11.916806510999976, 3.04054535500002],
            [-11.921871510999921, 3.044061355000053],
            [-11.924714510999877, 3.046831355],
            [-12.026391510999929, 3.147648355000143],
            [-12.044727510999962, 3.160371355],
            [-12.054400510999869, 3.169964355000047],
            [-12.059689510999874, 3.173634355000132],
            [-12.063229510999861, 3.177143355000027],
            [-12.157526511000015, 3.242550355000162],
            [-12.170075510999851, 3.254989354999964],
            [-12.361110511999868, 3.342968355000053],
            [-12.399740511999852, 3.369729355000132],
            [-12.420902511999941, 3.379468355],
            [-12.421943511999814, 3.38018935499997],
            [-12.423882512000034, 3.381081355000148],
            [-12.425291512, 3.382057354999986],
            [-12.42689351199985, 3.382794355000129],
            [-12.440581511999881, 3.392274355000069],
            [-12.457790511999917, 3.400192355000087],
            [-12.935773511999969, 3.730802355000051],
            [-12.946831511999903, 3.741722355000121],
            [-12.958707511999847, 3.749925355000187],
            [-13.024919511999883, 3.815294355],
            [-13.501639512999873, 4.144077356000068],
            [-13.502450512999815, 4.144693356000047],
            [-13.582982512999962, 4.223879356000012],
            [-13.171890512, 4.942629357000072],
            [-12.991530511999855, 5.258860357000117],
            [-12.990936511999905, 5.259702357000151],
            [-12.726760511999856, 5.632949357000072],
            [-12.684992511999866, 5.692024357000136],
            [-12.613663511999846, 5.785448357000078],
            [-12.598828511999955, 5.804473357],
            [-12.566714511999891, 5.845235357000078],
            [-12.541027511999857, 5.877687357000028],
            [-12.525007511999888, 5.897830357000089],
            [-12.434151511999886, 5.984165358000126],
            [-12.171388510999861, 6.23697835800003],
            [-12.07328251099986, 6.326292358000089],
            [-12.069385510999837, 6.329857358],
            [-11.800036510999888, 6.577828358000119],
            [-11.78729251099989, 6.589697358],
            [-11.698099510999896, 6.670174358000125],
            [-11.656362510999884, 6.699077358000153],
            [-11.63783451099988, 6.711731358000165],
            [-11.635964510999969, 6.712990358000056],
            [-11.573327510999974, 6.793871358000118],
            [-11.572185510999986, 6.795196358000055],
            [-11.547195510999984, 6.825170358000051],
            [-11.521111510999873, 6.854860358000096],
            [-11.499224511000023, 6.906736358000032],
            [-11.496002510999887, 6.918155358000092],
            [-11.496002510999887, 6.920765358000125],
            [-11.492327160150523, 6.927090722721374],
            [-11.465836810069561, 6.923609077215787],
            [-11.425554892038321, 6.939027085235949],
            [-11.379999918704925, 7.018891032381092],
            [-11.351390806706462, 7.078609075187799],
            [-11.34166394332027, 7.116664089177149],
            [-11.338608907242474, 7.150836102690491],
            [-11.306390883002706, 7.214864120122044],
            [-11.267499857988327, 7.235000133814751],
            [-11.23889091362804, 7.243891153813919],
            [-11.193681945258533, 7.256453950328364],
            [-11.141663854006481, 7.327500135308455],
            [-11.137781859358881, 7.343564220427012],
            [-11.131945875437793, 7.351391073996183],
            [-11.102781881447527, 7.384444941622803],
            [-10.920099809970935, 7.508227044044787],
            [-10.868545908517945, 7.527364102514241],
            [-10.850863948443873, 7.541882061768845],
            [-10.845554850957683, 7.547500116206947],
            [-10.829999882639413, 7.566391081998432],
            [-10.74749982693811, 7.669717987853986],
            [-10.724726866499026, 7.70194506454915],
            [-10.705836738897773, 7.724164148824912],
            [-10.686808812807982, 7.74305394114991],
            [-10.652226756590125, 7.760554013924548],
            [-10.607781882516264, 7.772500070001698],
            [-10.601945898594977, 7.858891172805912],
            [-10.602636735056706, 8.032918091039321],
            [-10.524163848566332, 8.117773126263472],
            [-10.502363859450753, 8.13389102320059],
            [-10.474026824030091, 8.144164219037279],
            [-10.438263925289561, 8.141244969791117],
            [-10.358054979008756, 8.145691066523938],
            [-10.33375500344502, 8.159582059419265],
            [-10.297572841906486, 8.199027128235059],
            [-10.264308756147528, 8.455136012838253],
            [-10.266654850852888, 8.488373108868785],
            [-10.214790818981555, 8.479235996191647],
            [-10.169726857537341, 8.512782049174206],
            [-9.965554927363428, 8.475000117591151],
            [-9.896172885444571, 8.502844967655761],
            [-9.876390923343024, 8.499444932442302],
            [-9.845836706888406, 8.494718041952254],
            [-9.792845979588918, 8.508400157819906],
            [-9.669863846008155, 8.490691040379431],
            [-9.639163952075904, 8.471109070488225],
            [-9.483654837303618, 8.346926983645361],
            [-9.462781886681654, 8.165553997810449],
            [-9.407499882323862, 8.018818053478327],
            [-9.417363873646281, 7.974582056432041],
            [-9.431463911207544, 7.955345085676498],
            [-9.437226804932777, 7.901109142837996],
            [-9.410281835995562, 7.837782187789969],
            [-9.359999804533942, 7.750282159192423],
            [-9.353854863660814, 7.742364110516675],
            [-9.358726761076184, 7.7373270896083],
            [-9.376254997045521, 7.683053931119588],
            [-9.377781844532279, 7.641809100049016],
            [-9.366945887714934, 7.611109038478588],
            [-9.384163825627866, 7.560000048447435],
            [-9.420899863329822, 7.476600111618836],
            [-9.457781913785396, 7.441317998845776],
            [-9.487363829469103, 7.370000072564054],
            [-9.485163915155454, 7.361991164057443],
            [-9.461045994529144, 7.387844976836263],
            [-9.422499801012435, 7.425000109698132],
            [-9.324445950459335, 7.426809092046582],
            [-9.241108877904509, 7.382218037580927],
            [-9.206663949622964, 7.349164169954307],
            [-9.098399767507232, 7.230900042046073],
            [-9.109972828891898, 7.193926964121644],
            [-9.099236784360642, 7.20326423664703],
            [-9.036946000185992, 7.228609100263753],
            [-8.972499893422849, 7.251664195564445],
            [-8.911390789961388, 7.253609132382621],
            [-8.844445870216447, 7.27173600387863],
            [-8.834445924424045, 7.309445012903822],
            [-8.788754996620639, 7.410418112703084],
            [-8.708472960144007, 7.515554000907088],
            [-8.712226711672457, 7.55763601646926],
            [-8.71909081984009, 7.560809069744238],
            [-8.722990919398512, 7.56548214841564],
            [-8.707572911378435, 7.646600028279323],
            [-8.674999829719212, 7.681108994301411],
            [-8.660554960660647, 7.69500015483473],
            [-8.573426753288999, 7.692491115931247],
            [-8.551690801913679, 7.691744958908501],
            [-8.555626776017931, 7.650345062628631],
            [-8.550808858059099, 7.615773064694494],
            [-8.486945964120565, 7.57139105489459],
            [-8.469745963480364, 7.561327071361916],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "LBY", Country: "Libya" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.661269918000016, 35.225193438000176],
            [13.833333513000099, 34.679444384000035],
            [13.875277513000185, 34.669444384000073],
            [13.935833513000034, 34.654444384],
            [14.070833513000082, 34.619722384000099],
            [14.087222513000142, 34.617222384000101],
            [14.260277513000176, 34.588889384000069],
            [14.398333513000125, 34.568611384000022],
            [14.524722514000189, 34.551944383999981],
            [14.623333514000137, 34.538333384000012],
            [14.818611514000111, 34.52222238399996],
            [15.166666514000013, 34.498055384000011],
            [17.82425751700012, 34.499913384000095],
            [17.839593517000083, 34.517395383999983],
            [17.883878517000142, 34.568085384000128],
            [17.90684051700012, 34.594463384000122],
            [17.934434517000113, 34.626114384000047],
            [17.960890517000081, 34.656211384000116],
            [18.027286517000164, 34.731723384],
            [18.057794556000118, 34.766805283000039],
            [18.073737577000117, 34.780146593],
            [18.16011125000017, 34.85164264400008],
            [18.16605182700016, 34.856622292000154],
            [18.175934202000178, 34.864981784000108],
            [18.20564608799998, 34.890144188000065],
            [18.224331965, 34.905985442000102],
            [18.284758154000144, 34.957566911],
            [18.357739499000104, 35.02106467600008],
            [18.510448517000157, 35.014544385000065],
            [18.61739451699998, 35.009886385000144],
            [18.882163518000056, 34.998288385000095],
            [19.005842518000065, 34.993955385000234],
            [19.032440518000158, 34.993013385000083],
            [19.117937518000133, 34.990231385000115],
            [19.289639518000143, 34.964760385000105],
            [19.58020451800013, 34.933061384000027],
            [19.901802519000142, 34.899298384000147],
            [20.032847519000057, 34.886869384000093],
            [20.344807519000142, 34.856823384000094],
            [20.371027519000023, 34.854336384000206],
            [20.432004519000088, 34.847725384000128],
            [20.447291519000089, 34.846112384000079],
            [20.506520519000105, 34.839825384000093],
            [20.598834519000206, 34.838413384000077],
            [20.741246519000242, 34.83588938400014],
            [20.907402519000101, 34.832810384000098],
            [20.933132519000139, 34.832302383999988],
            [21.208797520000161, 34.792928384000092],
            [21.328930520000057, 34.777145384000093],
            [21.459722520000184, 34.709269383999967],
            [21.713349520000122, 34.577507384000128],
            [21.940269520000186, 34.459486384000215],
            [22.146065521000196, 34.377933384000144],
            [22.189609521000136, 34.360630384000061],
            [22.65119952100008, 34.175372384000113],
            [22.716742521000072, 34.147458384000103],
            [22.764764521000075, 34.114770384000181],
            [22.821845521, 34.081820384000039],
            [22.886032521000089, 34.044889384000058],
            [22.919439521000186, 34.025544384000128],
            [23.033762521000028, 33.95959138400012],
            [23.040734521000132, 33.955700384000224],
            [23.106959522000153, 33.918544384000072],
            [23.169759522000049, 33.889068384000169],
            [23.258510522000165, 33.846715382999989],
            [23.283653522000122, 33.834629383000092],
            [23.375984522000039, 33.796534383000235],
            [23.391450522000014, 33.791398383000185],
            [23.447236522000082, 33.77192438300024],
            [23.507861522000155, 33.75177238300013],
            [23.764194522000054, 33.669089383000014],
            [24.122833522000121, 33.55172538300009],
            [24.238759523, 33.513406383],
            [24.338800523000145, 33.480569383000173],
            [24.426853523000119, 33.451699383000133],
            [24.613586523000095, 33.4412543830001],
            [24.709839522999971, 33.455169383000182],
            [24.812323523000117, 33.469941383000091],
            [24.897710523000143, 33.468014383000181],
            [24.90523552300013, 33.4678943830002],
            [24.937563523000222, 33.467548383],
            [24.962957523000171, 33.466308383000253],
            [25.167100523000101, 33.456111383000135],
            [25.318942524000192, 33.42127438300021],
            [25.700331524000092, 33.354688383000038],
            [25.704531524000146, 33.353945383000209],
            [25.708281524000114, 33.35327738300019],
            [25.851637524000154, 33.327571383000091],
            [26.204763524000157, 33.265087383000051],
            [26.207209743400085, 33.264417686200161],
            [26.063185115000181, 32.991981086999985],
            [25.780590954000161, 32.429734776000075],
            [25.71304941600016, 32.29379955600011],
            [25.668098003000011, 32.201566929000066],
            [25.560876419000124, 31.978285747000072],
            [25.548522991000112, 31.91701152900022],
            [25.537632536000103, 31.848798237000054],
            [25.161288412000147, 31.64855929060009],
            [25.151663434622463, 31.64694514611962],
            [25.148054098727414, 31.646109172852292],
            [25.129718182565654, 31.634445083998756],
            [25.100273227180253, 31.611391162164452],
            [25.073191130306839, 31.585691073490366],
            [25.060827152536262, 31.567500164254014],
            [25.047636048557962, 31.522636027382148],
            [25.009163280513263, 31.481109061449686],
            [24.962773301983049, 31.445273240151394],
            [24.893609189547362, 31.402773135257704],
            [24.868118145538944, 31.370827019957929],
            [24.86916320122964, 31.159164182894997],
            [24.904445146364708, 31.034444983695138],
            [24.911663306123501, 31.015000141740231],
            [24.977009126375975, 30.872154071044733],
            [24.995554087203573, 30.838891158752361],
            [25.017500256711116, 30.789236093650018],
            [24.976945088635517, 30.634718055111861],
            [24.9261822712065, 30.507500042930602],
            [24.87639125163426, 30.427500141315548],
            [24.854445082126801, 30.399445073118429],
            [24.818609093190304, 30.36389105140573],
            [24.771945194063477, 30.303891209013486],
            [24.723191183555087, 30.22597320451483],
            [24.710000247214992, 30.188891161848986],
            [24.706800204211703, 30.159164239239985],
            [24.711527094701552, 30.138054080757556],
            [24.726109091696685, 30.119717996957817],
            [24.76916324039189, 30.034164246282998],
            [24.810273290459122, 29.94582703319422],
            [24.821109247276553, 29.916382077808819],
            [24.833054129887046, 29.881664234759157],
            [24.835827198741413, 29.858745093928277],
            [24.831800197168491, 29.83708206511092],
            [24.825000126741486, 29.816945045589804],
            [24.884718169548194, 29.585827140650267],
            [24.878400226192866, 29.508473238236817],
            [24.897218101788582, 29.462218040710113],
            [24.911663306123501, 29.432782137780222],
            [24.997773279894432, 29.248882175768912],
            [24.999445134306399, 28.956945013580352],
            [25.001945120754471, 28.277773143697786],
            [25.002500170384394, 28.125000049767564],
            [25.001945120754471, 28.000191164203599],
            [25.002218203160766, 27.898891170179766],
            [25.001945120754471, 27.760273099095556],
            [25.001227126926523, 27.666327051662066],
            [25.001663321168849, 27.607773087571545],
            [25.000827142505841, 27.427500142712447],
            [25.000000183936123, 27.080554058572304],
            [25.00015407567912, 26.999445063526039],
            [25.000554060099631, 26.941664246186562],
            [25.000273266342504, 26.719718155003235],
            [25.000000183936123, 26.56721814347928],
            [24.999163334720862, 26.483891129208374],
            [24.998336208513251, 25.970836007872478],
            [24.997500197488222, 25.442773088859198],
            [24.996945147858412, 24.994718118087789],
            [24.997218230264423, 24.786664193266446],
            [24.998054073651559, 24.343053978123393],
            [24.999445134306399, 24.023891053240376],
            [25.000273266342504, 23.580273126746491],
            [25.001409181863977, 22.286664166491036],
            [25.001945120754471, 22.046664126369549],
            [25.001418234319459, 21.999691102652818],
            [25.001127214640263, 21.010300133876669],
            [25.000518185553915, 19.999113534574334],
            [24.982500111161585, 19.999444955026846],
            [24.763609223694232, 20.000273087062837],
            [24.634445101228209, 20.000000172294634],
            [24.577218160052155, 19.999164161269604],
            [24.455827079336473, 19.99694496857866],
            [24.154445188623498, 20.000554048458056],
            [24.043327136106427, 20.002218023881014],
            [24.005827171747086, 19.998817988667568],
            [24.000273155049427, 19.906391077369804],
            [24.000973211604531, 19.514164108130771],
            [24.002745145940878, 19.499064109517931],
            [23.456663314344667, 19.789445087558633],
            [23.020554064374437, 20.017773159837574],
            [22.321391187724572, 20.380000124061482],
            [21.781663341667439, 20.656109067887385],
            [21.381945130263318, 20.858745098119343],
            [20.959445073602865, 21.072500058220456],
            [20.408336111744205, 21.347500076253212],
            [19.873891211635168, 21.611664137468537],
            [19.298054126597265, 21.89389120007742],
            [18.999718219506576, 22.038282055532576],
            [18.768327064522765, 22.150273167086809],
            [18.363609215498542, 22.344444983830229],
            [18.036636200852882, 22.500000031432151],
            [17.687182251275743, 22.666254075553113],
            [17.656327124496102, 22.680918047561519],
            [16.980273154965602, 22.998336027836601],
            [16.346109087775915, 23.292218126843423],
            [16.000827146696821, 23.450553954650658],
            [15.335282239758868, 23.153327140991209],
            [15.132773279179617, 23.062218032514451],
            [14.997891189989303, 23.000591095073204],
            [14.957500139578514, 22.980553987838263],
            [14.86500013808498, 22.934444970703268],
            [14.81833607132009, 22.911109081645449],
            [14.495000132110533, 22.747218063674168],
            [14.418054093106889, 22.708053956253693],
            [14.35666319005972, 22.675827047196591],
            [14.235000200404414, 22.6141642352096],
            [13.909163268918206, 22.883327095854796],
            [13.612782189291721, 23.127500150302623],
            [13.595554025456806, 23.139445032913287],
            [13.563891218485026, 23.158882163517362],
            [13.543891159262216, 23.168609026903383],
            [13.467636124358307, 23.197500106125361],
            [13.414300230285164, 23.215418100593595],
            [13.390273169489632, 23.219718016934635],
            [13.345836174404695, 23.229164086563614],
            [13.109445046524627, 23.281109087619896],
            [12.887782263669465, 23.330000058426592],
            [12.59139112575906, 23.393891115559924],
            [12.473054075292964, 23.419164230085173],
            [12.398891163427606, 23.434164148773547],
            [12.339445029560608, 23.446664248652112],
            [12.280282204021717, 23.459164180892699],
            [12.013336189134776, 23.515827019983433],
            [11.986473195210721, 23.522309081003201],
            [11.918891250822895, 23.647500181352925],
            [11.757218084438108, 23.941664247583375],
            [11.726018126160881, 24.000000114552748],
            [11.55889119064085, 24.302500151152444],
            [11.46955401650024, 24.327518120544326],
            [11.439445046743543, 24.336936026978506],
            [11.350000248965898, 24.36250016118278],
            [11.275273235015135, 24.383054096569097],
            [11.110836052231463, 24.429717995695725],
            [11.012891166419934, 24.457827043349411],
            [10.969691172609117, 24.472364113343119],
            [10.832500204363669, 24.520273228009259],
            [10.756945058376886, 24.550554026781597],
            [10.715273253157306, 24.567218088531192],
            [10.668054136762493, 24.550282117841689],
            [10.576663228700198, 24.515554048870129],
            [10.480000103525867, 24.478054084510788],
            [10.414582198905862, 24.483327139813142],
            [10.303336070907903, 24.566664212367769],
            [10.252218196059431, 24.605826978683879],
            [10.240000231042558, 24.660000057248325],
            [10.229163268396633, 24.709164110461316],
            [10.218336196396706, 24.751109165725282],
            [10.174163231262554, 24.775000104412911],
            [10.134773147731806, 24.795636014812416],
            [10.054445178425425, 24.838053977054656],
            [10.050836098546029, 24.867773188312825],
            [10.049718120297484, 24.936382083480524],
            [10.047782235934619, 25.005273113510114],
            [10.04527319703115, 25.087500086805207],
            [10.039163292513251, 25.279444999506765],
            [10.032082260690942, 25.329445063744672],
            [10.005554039980751, 25.365553967449287],
            [9.856109232172798, 25.560836051090618],
            [9.791663293047719, 25.644718114991164],
            [9.479445110241045, 26.048882087851936],
            [9.435273150935302, 26.10471813601113],
            [9.398336115194695, 26.15332713959431],
            [9.393473270234836, 26.176664202118715],
            [9.398191108269373, 26.199864136706637],
            [9.4422182282878, 26.270273129405368],
            [9.499445169463854, 26.357500075596633],
            [9.668891209961004, 26.429718050643814],
            [9.762782272109547, 26.468609075658108],
            [9.871663194662773, 26.51416421662968],
            [9.899163213229798, 26.610000047958508],
            [9.916945085590157, 26.67333605546203],
            [9.895836100574115, 26.69555396627122],
            [9.912773244730005, 26.7775001458091],
            [9.919718154444439, 26.804718029514433],
            [9.93055411126187, 26.859718066648597],
            [9.908327147997085, 26.874718152975149],
            [9.848327137966862, 26.908609037455292],
            [9.829163257407174, 27.005273168458203],
            [9.818054050545442, 27.056391043306675],
            [9.802773170461677, 27.097773169951822],
            [9.74360917145637, 27.254864117495814],
            [9.734445069050906, 27.316036085231318],
            [9.776391130143281, 27.439164231565897],
            [9.819163311614659, 27.537773131748807],
            [9.873536047113589, 27.608682188792514],
            [9.890273199059123, 27.648609049404186],
            [9.948327098804697, 27.824444949986045],
            [9.955836110604338, 27.846245106739886],
            [9.954163250363962, 27.868609030302778],
            [9.901391123099984, 27.993327056035966],
            [9.842500206501086, 28.131944959482084],
            [9.830554150424007, 28.159718060455234],
            [9.792218175039352, 28.249582121030855],
            [9.790282290676686, 28.270553978110698],
            [9.798336126184353, 28.325554015244862],
            [9.812218066624183, 28.40777310955086],
            [9.838054109768251, 28.571945088917161],
            [9.846836165025735, 28.630436021095747],
            [9.859445062007808, 28.708882085495716],
            [9.867500238620039, 28.768053963490118],
            [9.871663194662773, 28.806109145117276],
            [9.873191215615918, 28.849582221334472],
            [9.869445175438301, 28.950000103865747],
            [9.865273166940085, 28.993054084922946],
            [9.856109232172798, 29.06610907682348],
            [9.852218185069859, 29.089718048287509],
            [9.84721821217363, 29.118609127509572],
            [9.839718085191294, 29.160000138972023],
            [9.829718139398892, 29.198327061900997],
            [9.765553999859264, 29.430554060271859],
            [9.757218197128083, 29.454164205202559],
            [9.725000172888315, 29.521382207353511],
            [9.680827207754362, 29.611664189622658],
            [9.661109115755067, 29.649164153981999],
            [9.638054020454376, 29.691109041607788],
            [9.57889119491557, 29.789164233265453],
            [9.566663339252983, 29.806945099797403],
            [9.551391176348432, 29.827773123418226],
            [9.449163305640212, 29.96582709241703],
            [9.41360911628945, 30.003891158861776],
            [9.40221810984221, 30.021664146404518],
            [9.385827130498797, 30.042500049014379],
            [9.362500126258197, 30.063327066806792],
            [9.345554097284889, 30.076664183538796],
            [9.318891263209451, 30.100973211557928],
            [9.311391136227144, 30.12721812394004],
            [9.382773267887302, 30.162500069075108],
            [9.51139122554082, 30.22277316151154],
            [9.537109083849913, 30.234391149897547],
            [9.637500144290954, 30.278053992403031],
            [9.735136073150727, 30.315953941183082],
            [9.840691224152721, 30.340273195124198],
            [9.86402711321054, 30.339445063088093],
            [9.89229122607324, 30.356318001865461],
            [9.982218150922733, 30.461391193433499],
            [10.083609172415606, 30.588053988347056],
            [10.211945162845524, 30.728473162790507],
            [10.223054034431271, 30.750553945663299],
            [10.277500195402013, 30.869718122337005],
            [10.290691131742108, 30.91041812969975],
            [10.264582173830007, 31.076809134119102],
            [10.220554047983086, 31.197909027517582],
            [10.169845042372629, 31.315318033661399],
            [10.123336040816838, 31.422500112074218],
            [10.136454054599284, 31.502364226857438],
            [10.2872181739707, 31.694164132633873],
            [10.314927069565499, 31.719300119222638],
            [10.349445088043012, 31.730273203976466],
            [10.387636056502174, 31.729718154346656],
            [10.416527135724039, 31.72569115277382],
            [10.444163276399166, 31.720554051941278],
            [10.469445108103656, 31.7212450560409],
            [10.498473147623827, 31.73708215922089],
            [10.530836178788917, 31.761391187240221],
            [10.616109135706409, 31.861244967686375],
            [10.710345029352425, 31.964444971527413],
            [10.814027160265624, 32.025000031187645],
            [10.830554094078934, 32.041382125713639],
            [10.859718088069116, 32.092500168200289],
            [10.878536131302639, 32.13228218952473],
            [11.029163290375948, 32.199718121158853],
            [11.144163337540448, 32.246382187923743],
            [11.181927164212595, 32.261664241473923],
            [11.325836060233883, 32.3194450588134],
            [11.414718097030772, 32.353053976069916],
            [11.483327159836563, 32.390000064265948],
            [11.515882136584793, 32.409400146857692],
            [11.550973141964931, 32.428326987195007],
            [11.567500243416333, 32.442218147728411],
            [11.581663312889532, 32.470827092088697],
            [11.580000175656807, 32.503054001145685],
            [11.558327088555558, 32.545900111003519],
            [11.536945021133306, 32.567500107908828],
            [11.516945129548589, 32.581245088050551],
            [11.494445083877622, 32.598882121123566],
            [11.472773170242846, 32.622500145042991],
            [11.466663265724947, 32.650973134933466],
            [11.47721809350918, 32.863882193363921],
            [11.483700154528947, 32.942909123655639],
            [11.494027162184182, 33.043473186578794],
            [11.504745101804446, 33.074809099325719],
            [11.516954014365922, 33.102473235557554],
            [11.522173257849801, 33.125918089357057],
            [11.525000138522643, 33.146664137964436],
            [11.526081874409812, 33.171136194453155],
            [11.53124651100012, 33.196851383000066],
            [11.536198158000104, 33.210703704000011],
            [11.537370535666781, 33.213983507333481],
            [11.539969511000123, 33.227321383000159],
            [11.566442511, 33.298784382999983],
            [11.58510751100016, 33.364755383000059],
            [11.612536926500155, 33.401243490000169],
            [12.000000308500148, 33.916668326000021],
            [12.125978794000019, 34.174961805000052],
            [13.580132434000149, 35.270745170000154],
            [13.601666667000131, 35.249999999],
            [13.661269918000016, 35.225193438000176],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "LCA", Country: "St. Lucia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-59.999722555999966, 14.114166365000088],
            [-60.005500556000015, 14.100499365],
            [-60.008776556000015, 14.094721365000041],
            [-60.07774655599988, 13.970556365000064],
            [-60.102272555999861, 13.926330365000084],
            [-60.131072556, 13.872474365000173],
            [-60.136496055999913, 13.860634865000151],
            [-60.13679355599993, 13.859985365000057],
            [-60.137203556000031, 13.85908636500011],
            [-60.199603555999857, 13.722099365000062],
            [-60.212092555999988, 13.691920365000158],
            [-60.221693556, 13.669719365000077],
            [-60.249505555999889, 13.605874365000048],
            [-60.270353556000032, 13.554394365],
            [-60.296058555999849, 13.490067365000158],
            [-60.330213555999848, 13.401355364],
            [-60.339303555999948, 13.374765364000041],
            [-60.362062556000012, 13.305521364000143],
            [-60.362837555999818, 13.303169364000041],
            [-60.377770555999916, 13.258241364000156],
            [-60.385322555999949, 13.236625364000076],
            [-60.388103555999919, 13.237647364000082],
            [-60.397209555999922, 13.241023364000171],
            [-60.403991555999909, 13.243542364000049],
            [-60.426310555999976, 13.251904364000126],
            [-60.448154555999906, 13.260312364000185],
            [-60.473036555999954, 13.269977364000155],
            [-60.503340555999898, 13.282152364000055],
            [-60.515593555999857, 13.287181364000133],
            [-60.52574555599989, 13.291355364000083],
            [-60.535835555999853, 13.295578364000079],
            [-60.543866555999983, 13.29894636400013],
            [-60.683074556999941, 13.358109364000015],
            [-60.691870556999959, 13.361830364000141],
            [-60.737750556999913, 13.382242364000078],
            [-60.765133556999956, 13.394669364000137],
            [-60.847878556999859, 13.432901364000131],
            [-60.877842556999923, 13.449265364000155],
            [-60.884835556999974, 13.453033364000078],
            [-60.895694556999871, 13.458778364000068],
            [-60.948501557000014, 13.486570365],
            [-60.971245557, 13.498471365000157],
            [-60.98883955699992, 13.507757365000117],
            [-61.011664556999932, 13.519039365000026],
            [-61.034311556999967, 13.531064365000134],
            [-61.065798556999908, 13.547668365000121],
            [-61.104796556999958, 13.56850536500005],
            [-61.113072556999839, 13.57091836500004],
            [-61.120452556999936, 13.573308365000173],
            [-61.15328355699998, 13.584652365000153],
            [-61.172500556999978, 13.590991365000079],
            [-61.175991557, 13.592221365000157],
            [-61.217622556999942, 13.605443365000085],
            [-61.24405455699997, 13.614142365000163],
            [-61.249875556999939, 13.615701365000092],
            [-61.254242556999856, 13.616964365000058],
            [-61.323334556999981, 13.636515365000079],
            [-61.341184556999849, 13.641501365000153],
            [-61.344734556999953, 13.642595365000076],
            [-61.366248556999949, 13.648573365000161],
            [-61.635129556999885, 13.722239365000092],
            [-61.674841556999951, 13.733261365000075],
            [-61.928937557999973, 13.803971365000137],
            [-61.972390557999944, 13.816010365000153],
            [-62.024250557999977, 13.828667365000058],
            [-62.123801558, 13.858442365000116],
            [-62.127269557999966, 13.859477365000131],
            [-62.357597557999924, 13.927927365000059],
            [-62.493224557999866, 13.96690936500012],
            [-62.813888559, 14.08055536500008],
            [-62.777222557999977, 14.086389365000073],
            [-62.227777557999929, 14.154444365000174],
            [-61.77416655799982, 14.170833365000121],
            [-61.716944556999863, 14.175000365000116],
            [-61.399444556999896, 14.187777365000088],
            [-61.278055556999902, 14.207500365000058],
            [-61.188333556999851, 14.230277365000163],
            [-61.076388556999945, 14.252777365000028],
            [-61.003888556999925, 14.272500365000084],
            [-60.891944557000016, 14.243333365000055],
            [-60.736666556999921, 14.219166365000021],
            [-60.679722556999877, 14.204444365000043],
            [-60.176944556, 14.135555365000116],
            [-60.154166555999865, 14.133333365000041],
            [-60.061111555999901, 14.12222236500007],
            [-59.999722555999966, 14.114166365000088],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "LIE", Country: "Liechtenstein" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.598636109004985, 47.063836063932129],
            [9.541391230556115, 47.070000115544545],
            [9.506391252644676, 47.064718175424773],
            [9.485282267628719, 47.061100210727957],
            [9.474636077099746, 47.057454082836429],
            [9.51173605703849, 47.105973232417242],
            [9.502082283848239, 47.146809194249883],
            [9.487082197521573, 47.172636017300377],
            [9.485000132767027, 47.196244988764491],
            [9.496809228545885, 47.223327085638104],
            [9.533573261804463, 47.274545040410842],
            [9.566391262675097, 47.243891079308142],
            [9.622773140370612, 47.150554060961085],
            [9.633891232049649, 47.130554001738304],
            [9.633609097188042, 47.097145076692115],
            [9.606945089645933, 47.06805400525981],
            [9.598636109004985, 47.063836063932129],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "LKA", Country: "Sri Lanka" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [77.023333, 4.784],
            [77.176666667000205, 5.0],
            [77.845000000000113, 5.898333333000139],
            [78.203333333000188, 6.513333333000034],
            [78.646666667000062, 7.35],
            [78.761666667000071, 7.588333333000108],
            [78.895, 8.203333333],
            [78.923333333000215, 8.37],
            [79.078333333000188, 8.52],
            [79.216666667000169, 8.620000000000161],
            [79.303333333000211, 8.666666667000115],
            [79.488333333000043, 8.896666667000019],
            [79.521666667, 9.0],
            [79.533333333000115, 9.100000000000108],
            [79.533333333000115, 9.216666666999984],
            [79.51842228700005, 9.317602984000132],
            [79.511666667000014, 9.363333333000057],
            [79.376666667000137, 9.669166667000084],
            [79.583333333000184, 9.95],
            [80.05000000000021, 10.083333333000155],
            [80.08333333300007, 10.096666667000065],
            [80.158333333, 10.14],
            [80.766666667000123, 10.55],
            [81.041666667000101, 10.695000000000121],
            [81.933333333000206, 11.045],
            [82.40666666700011, 11.266666667000095],
            [83.366666667000089, 11.443333333000098],
            [83.747109578000135, 10.948495362000088],
            [83.747595578000215, 10.947449362000143],
            [83.750376578000015, 10.943480362000102],
            [83.771706578000106, 10.897607862000044],
            [83.77218457800015, 10.896627362000103],
            [83.779214578000079, 10.886593362000156],
            [83.779955078000029, 10.885027362000073],
            [83.780715078000156, 10.883545862000062],
            [83.791773578000203, 10.859856362000016],
            [83.802407578000128, 10.849219862000041],
            [83.804450578000086, 10.847158362000187],
            [84.138907578000129, 10.368973362000133],
            [84.158214578000212, 10.327257362000083],
            [84.286861578000213, 10.142957361000171],
            [84.530777579000102, 9.614749361000108],
            [84.561970579000075, 9.495341360999987],
            [84.635431579000198, 9.389774361000036],
            [84.636187579000108, 9.388159861000119],
            [84.636944579000129, 9.386665861000054],
            [84.637868579000127, 9.384809361000094],
            [84.638670579000149, 9.383106861000016],
            [84.643605579000081, 9.372411361000133],
            [84.65772257900008, 9.352117361000012],
            [84.659052579000075, 9.349231360999966],
            [84.659426079000156, 9.348677861000169],
            [84.665160579000172, 9.340417361000064],
            [84.666434579000139, 9.337652361000082],
            [84.666912579000069, 9.336912861],
            [84.668671079000177, 9.334228861000142],
            [84.670196079000135, 9.331871361000125],
            [84.671799579000066, 9.329503361000135],
            [84.67259257900011, 9.327782361000118],
            [84.674306079000218, 9.325299861000062],
            [84.678383079000156, 9.31939186100017],
            [84.680035579000076, 9.316989360999983],
            [84.680836245666825, 9.315272694333387],
            [84.682514579000127, 9.312822361000087],
            [84.683257579000127, 9.311319861000015],
            [84.684892579000149, 9.307941861000117],
            [84.686491579000091, 9.30465986100009],
            [84.69332157900007, 9.289958361000132],
            [84.694862579000215, 9.287743361],
            [84.695750579000077, 9.285814361000178],
            [84.69742607900011, 9.283343361000178],
            [84.69902957900004, 9.280975361000102],
            [84.702382579000158, 9.273698361000186],
            [84.704864579000201, 9.270127361000036],
            [84.948051579000122, 8.741639360000079],
            [84.94868657900011, 8.740166360000146],
            [84.949149579000135, 8.738386360000035],
            [84.949469579000066, 8.737692360000082],
            [84.949827579000186, 8.736315360000106],
            [84.950351579000113, 8.735176360000096],
            [84.950978579000235, 8.733012860000144],
            [84.951496579000178, 8.731267360000047],
            [84.952008579000079, 8.730156360000052],
            [84.952471579000218, 8.728376360000127],
            [84.952796579000193, 8.727659360000217],
            [84.953580579000032, 8.725946359999966],
            [84.953913579000215, 8.724663360000037],
            [84.954462579000193, 8.72347036],
            [84.955103579000109, 8.721242860000089],
            [84.95555357900011, 8.719750360000148],
            [84.956916579000023, 8.716785360000046],
            [84.95721957900011, 8.715623360000095],
            [84.958588579000065, 8.712644360000169],
            [84.959073579000147, 8.710783360000107],
            [84.960153579000149, 8.708434360000084],
            [84.960455579000183, 8.70727336000003],
            [84.961825579000021, 8.70429436000002],
            [84.962469579000157, 8.702049860000116],
            [85.002363579000217, 8.548992360000085],
            [85.00886757900011, 8.534843360000011],
            [85.009198579000071, 8.533569360000115],
            [85.010539579000152, 8.53065236],
            [85.010991579000091, 8.528914360000073],
            [85.012104579000066, 8.526492360000049],
            [85.012970579000154, 8.52317786],
            [85.013620579000161, 8.520932360000089],
            [85.014095579000156, 8.519344360000147],
            [85.014645579000074, 8.51814836000004],
            [85.015289079000212, 8.51590536000009],
            [85.023723579000063, 8.483719360000123],
            [85.025172579000156, 8.480568360000177],
            [85.025478579000065, 8.479390360000139],
            [85.026844579000027, 8.476418360000082],
            [85.027495579000146, 8.474122860000136],
            [85.028063579000076, 8.47214836],
            [85.028403579000127, 8.471408360000154],
            [85.029910579000131, 8.465868360000073],
            [85.030687079000046, 8.463397860000029],
            [85.031213579000024, 8.461636360000128],
            [85.031726579000207, 8.460519360000077],
            [85.177886579000159, 7.898559359000146],
            [85.177966579000127, 7.897541359000115],
            [85.179437579000165, 7.891881359000052],
            [85.179625579000088, 7.889478359000179],
            [85.180299579000149, 7.886887359000028],
            [85.180380579000115, 7.88585735900007],
            [85.182718579000124, 7.87686435900001],
            [85.228063579000121, 7.298344359000097],
            [85.227945579000135, 7.297140359000039],
            [85.228010579000085, 7.295523859000141],
            [85.229495579000144, 7.275827359000075],
            [85.229060579000105, 7.271386359000061],
            [85.229426579000034, 7.266723358999982],
            [85.22239157900006, 7.194948359],
            [85.237464579000203, 7.003456358000108],
            [85.237305579000093, 7.001832358000115],
            [85.237439579000096, 7.000128358000083],
            [85.181210579000123, 6.422574358000062],
            [85.180553579000133, 6.420225358000039],
            [85.180381579000112, 6.418452357999982],
            [85.178818579000023, 6.412855358000158],
            [85.178631579000097, 6.410929358000047],
            [85.173437579000137, 6.39233835800006],
            [85.171692579000165, 6.374331358000163],
            [85.151614579000153, 6.302423358000112],
            [85.14852057900012, 6.270438358000078],
            [85.144476579000099, 6.255947358000128],
            [85.142496579000152, 6.235461358000109],
            [85.129525579000102, 6.188966358000059],
            [85.127034579000139, 6.16316335800002],
            [84.971432579000151, 5.603959357000136],
            [84.946945579000129, 5.552769357000017],
            [84.946037579000205, 5.54969635700013],
            [84.945355579000108, 5.547439357000087],
            [84.931059579000049, 5.517544357000048],
            [84.916538579000104, 5.465196357000139],
            [84.913215579000138, 5.458244357000112],
            [84.907302579, 5.436919357000036],
            [84.65726157900022, 4.912870357000102],
            [84.655798079000164, 4.910717357000124],
            [84.629200579000127, 4.873327356000075],
            [84.619578579000091, 4.853120356000048],
            [84.609166579000117, 4.838518356000066],
            [84.601087579000108, 4.821548356],
            [84.599405245666759, 4.819113356000045],
            [84.598494579000061, 4.81729585600003],
            [84.59615357900006, 4.812430356000164],
            [84.259316578000124, 4.339275356],
            [84.258378578000048, 4.338351356000118],
            [84.254312578000139, 4.332631356000149],
            [83.84055307800017, 3.92448435600005],
            [83.836880578, 3.920922356000034],
            [83.834383578000171, 3.919196356000043],
            [83.831093578000178, 3.915946356000077],
            [83.826995578000123, 3.913115356000048],
            [83.79375457800009, 3.880267356000019],
            [83.780790578000193, 3.871307356000031],
            [83.761649578000032, 3.85240635600006],
            [83.757915578000194, 3.848732356000099],
            [83.753070578000148, 3.845383356000113],
            [83.7413425780002, 3.833789356000139],
            [83.722599578000171, 3.820831355000109],
            [83.709074244666766, 3.807497688333413],
            [83.630275578000095, 3.752935355],
            [83.623194578000067, 3.745930355000127],
            [83.563503578000024, 3.704625355000161],
            [83.560040578000013, 3.701197355000119],
            [83.557865578000161, 3.699692355000025],
            [83.544263578000056, 3.686230355000134],
            [83.066853577000103, 3.355300355000068],
            [83.059726577000134, 3.352013355000153],
            [82.990405577000189, 3.303883355],
            [82.463123577000175, 3.060196355000144],
            [82.395567577000151, 3.042300355],
            [82.364388577000085, 3.027861355000013],
            [82.358893577000089, 3.026404355000167],
            [82.269012577000098, 2.984761355000032],
            [82.130950576000117, 2.948115354999985],
            [81.863318576000125, 2.823891355],
            [81.770291576000062, 2.799116355000024],
            [81.736379576000132, 2.783354354999986],
            [81.735053576000183, 2.783001355000181],
            [81.733888576000169, 2.7824593550001],
            [81.172835576000153, 2.632585354000128],
            [80.594373575000219, 2.581849354000099],
            [80.593569575000146, 2.581919354000021],
            [80.592779575000151, 2.581849354000099],
            [80.59192957500008, 2.581923354],
            [80.591094575000085, 2.581849354000099],
            [80.590421575000192, 2.581868854000106],
            [80.588943575000172, 2.581958354000051],
            [80.587709575000218, 2.581849354000099],
            [80.587110075000197, 2.581867354000082],
            [80.008398575000143, 2.631763354000057],
            [79.962316574000084, 2.644029354000025],
            [79.90223957400022, 2.649169354000051],
            [79.898972574000169, 2.650034354000042],
            [79.896183574000105, 2.650772354],
            [79.894777574000187, 2.650893354000061],
            [79.891788074000118, 2.651634854000037],
            [79.881256574000219, 2.654382354000077],
            [79.874077574000154, 2.654995354],
            [79.822986574000225, 2.668580354000127],
            [79.806920574000088, 2.66995035400015],
            [79.245636574000144, 2.818787355000026],
            [79.195699574000145, 2.841887355000054],
            [79.13274857400009, 2.85852835500009],
            [79.131820574000045, 2.858957355000058],
            [79.126977574000165, 2.860237355000095],
            [79.126223574000136, 2.860585355000111],
            [79.124494574000067, 2.861042355],
            [79.115293574000049, 2.865296355000169],
            [79.106274574000139, 2.867679355000163],
            [78.578874073000094, 3.111125855000111],
            [78.577316573000104, 3.111875355000038],
            [78.557248573000123, 3.125797354999975],
            [78.553297573000151, 3.127617355000112],
            [78.075684906333521, 3.458506355000154],
            [78.074350573000203, 3.459771355000015],
            [78.050086573000129, 3.476554355000161],
            [78.043590573000102, 3.482978355000114],
            [78.040900573000187, 3.484839355000076],
            [78.020845573000116, 3.504672354999983],
            [78.013543573000078, 3.509722355000022],
            [78.005568573000033, 3.517604355000074],
            [77.995511573000073, 3.527545354999972],
            [77.987694573000141, 3.532950354999983],
            [77.574320072000063, 3.941141356000074],
            [77.568250572000153, 3.947182356000042],
            [77.559928572000018, 3.95889935600016],
            [77.542655072000201, 3.97596235600011],
            [77.54073357200005, 3.977892356000012],
            [77.204136572000067, 4.451033356000082],
            [77.193343572000032, 4.4737263560001],
            [77.168078572000098, 4.509179356000104],
            [77.167343572000135, 4.510674856000207],
            [77.023333, 4.784],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "LTU", Country: "Lithuania" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.613209101507579, 55.674836123233646],
            [26.622636060397355, 55.652354014835666],
            [26.627909115699708, 55.59840020685867],
            [26.52666326877042, 55.446936030932122],
            [26.464854108753656, 55.338745106650137],
            [26.50583608334, 55.323882148259869],
            [26.528882293823273, 55.317773249570493],
            [26.560827068018682, 55.313882202467468],
            [26.625273174781825, 55.330273181810966],
            [26.645827110168057, 55.330273181810966],
            [26.775409154327491, 55.311245088083112],
            [26.813054125612354, 55.290273231003354],
            [26.806800219997541, 55.269718122150735],
            [26.783745124696935, 55.254853990293995],
            [26.641663315935006, 55.190827146328942],
            [26.512082277603781, 55.150273151719901],
            [26.481663345066636, 55.154991157392445],
            [26.280136072990047, 55.145691100517169],
            [26.253054143754525, 55.118736073296233],
            [26.249445063875214, 55.078673258214664],
            [26.214718168370041, 55.025682195639106],
            [26.160827224667088, 54.977209146525936],
            [26.076382232147637, 54.961936145431181],
            [25.936663281897467, 54.955545111880085],
            [25.91194504936405, 54.953609059879327],
            [25.875136089104217, 54.941100075183343],
            [25.79208215723969, 54.873045056178782],
            [25.73693610735171, 54.788118104225347],
            [25.727082174313125, 54.674854116851449],
            [25.732082147209269, 54.654154001073536],
            [25.757700260870081, 54.614991067119419],
            [25.762636196025909, 54.582491075656137],
            [25.748882163428874, 54.564436120889681],
            [25.72471814233495, 54.548464236706181],
            [25.655754022109505, 54.523118031984993],
            [25.55513614736779, 54.362636101682867],
            [25.551663357234361, 54.326909077488054],
            [25.575409121358803, 54.312636037444847],
            [25.611109155825289, 54.311382104726405],
            [25.650273263245765, 54.315827027992654],
            [25.69151809431645, 54.327353988909906],
            [25.712700169528318, 54.331518118419112],
            [25.807218198036054, 54.246654030739634],
            [25.785000119588886, 54.164509200095878],
            [25.766109153797316, 54.153882120306307],
            [25.668673217147983, 54.136527054456892],
            [25.539927184013379, 54.145482112196405],
            [25.505409165536065, 54.185127005584562],
            [25.544582157773874, 54.200545013604639],
            [25.573054141835939, 54.24304511849833],
            [25.50110924919494, 54.303045128528638],
            [25.466936062215211, 54.304364272454208],
            [25.43832711785484, 54.288891111510921],
            [25.420691258248297, 54.270964232225353],
            [25.398745088740839, 54.258473184802298],
            [25.372500176358841, 54.254164216005748],
            [25.345827116361335, 54.252773155350908],
            [25.204445196516417, 54.215964195091189],
            [25.178882235778644, 54.186100144545875],
            [25.161945091622755, 54.172491118874163],
            [25.101391205429081, 54.144436050677328],
            [25.077218131879562, 54.136109132763366],
            [25.027082280809822, 54.131936118436741],
            [25.004027185509131, 54.142353985922668],
            [24.971518141590337, 54.157282155519866],
            [24.847773254818577, 54.143400215079922],
            [24.798400156939834, 54.101591114285881],
            [24.822500140293329, 54.064782154026247],
            [24.839445163438228, 54.038191069042099],
            [24.840409249944145, 54.011109139806464],
            [24.826736186531889, 53.984364163079604],
            [24.724018142124805, 53.970200087778082],
            [24.695618242430299, 53.996518090355934],
            [24.612218137963424, 53.992218174014894],
            [24.514445081167452, 53.950409073221024],
            [24.467491168190008, 53.922627087430371],
            [24.391663275073199, 53.89033614063284],
            [24.267354118577941, 53.907209079410208],
            [24.256245079354301, 53.929436042674794],
            [24.241800210295565, 53.945691067548481],
            [24.206873154942059, 53.962982095657352],
            [24.17555417363954, 53.967491224302236],
            [24.149582176025547, 53.965273205077764],
            [24.122636201259837, 53.951453961273828],
            [24.084927192234744, 53.937554083560983],
            [24.016109252401094, 53.937209084425419],
            [23.986109247385969, 53.938882112303858],
            [23.898882301194618, 53.940545081898435],
            [23.783882254030232, 53.935545109002376],
            [23.709436201474659, 53.923882193615313],
            [23.594300199840262, 53.930827103329719],
            [23.568609163621602, 53.939154021243681],
            [23.543609131502535, 53.943318150752802],
            [23.516663324375088, 53.946653980587925],
            [23.504036154844044, 53.947045080191103],
            [23.508054103961456, 53.960544973483238],
            [23.517500173590435, 54.038745112843415],
            [23.494163278704207, 54.117209114516399],
            [23.484436080041746, 54.138327151987838],
            [23.457773245966308, 54.174164146752631],
            [23.356109142067339, 54.235409037046097],
            [23.333054046766648, 54.247218132824955],
            [23.204445141568328, 54.287218083632368],
            [23.115827134722338, 54.304436021545584],
            [22.995063355642088, 54.385754061257714],
            [22.863191207890537, 54.408318144668939],
            [22.835136139693702, 54.405127154121132],
            [22.812218172329295, 54.395964225182254],
            [22.785882232478428, 54.36383605494477],
            [22.776391235848394, 54.376936131454372],
            [22.736109150179175, 54.423464243749194],
            [22.710336138947156, 54.443918099211359],
            [22.698327218596205, 54.464573120350153],
            [22.691382141243707, 54.493609038859333],
            [22.694854093186535, 54.529436142978327],
            [22.720136092529287, 54.6931821540242],
            [22.745273252584639, 54.717209047181925],
            [22.813882147752253, 54.771664260607892],
            [22.840409194996141, 54.777627146543679],
            [22.86687321032781, 54.812144997383072],
            [22.865000190238987, 54.838600127897323],
            [22.851382279749771, 54.881664167238412],
            [22.838745051935007, 54.899300194483047],
            [22.719991253241972, 54.962627149530888],
            [22.68221820647625, 54.95832706555187],
            [22.645409246216502, 54.964473179891471],
            [22.60290914132284, 55.044854122825896],
            [22.512773171807396, 55.063327166923941],
            [22.279718209038606, 55.067218046388874],
            [22.258054174392754, 55.066382203001936],
            [22.19069116531648, 55.060964140774175],
            [22.138745158431732, 55.052700087134284],
            [22.122773274248232, 55.038891069252131],
            [22.10332709118893, 55.029436114805819],
            [22.059927105167588, 55.028527013584764],
            [21.722627082866865, 55.138736132519128],
            [21.647354071741688, 55.181800171859848],
            [21.564163347216919, 55.197209127424699],
            [21.43138226588249, 55.251936082152483],
            [21.370782279221118, 55.286773115865728],
            [21.327654034501592, 55.270627055733812],
            [21.300273206598121, 55.258891217788985],
            [21.274854079319255, 55.250618111693498],
            [21.263936327670564, 55.248982075697668],
            [21.258097520000035, 55.249876979000149],
            [20.992784520000157, 55.276157979000217],
            [20.984809392432396, 55.27655464759242],
            [20.942836292885829, 55.287200074176283],
            [20.935216519, 55.291175979000244],
            [20.791725904500225, 55.338502843500166],
            [20.658835947000199, 55.382333531500123],
            [20.653431221000119, 55.384121808666819],
            [19.924433333000081, 55.636249999000071],
            [19.700383381000165, 55.709620072000106],
            [19.084805680000159, 55.911204498],
            [19.081083184000107, 55.912423511000057],
            [19.076443765000107, 55.913942791],
            [19.046750000000117, 55.923666666000045],
            [19.02253781800016, 55.93159547599997],
            [19.06748334460022, 55.955000000400119],
            [19.88744423100016, 55.980206111000015],
            [20.301487331000146, 56.004730338000016],
            [20.360132715000191, 56.00638500500007],
            [20.365451991000128, 56.006445369],
            [20.376543919000113, 56.006437751000178],
            [20.475602125000023, 56.006369714000144],
            [20.562519836000149, 56.01427170500007],
            [20.574843054000041, 56.015144754000104],
            [21.024693964000164, 56.065542746000048],
            [21.031441494000148, 56.068484579000099],
            [21.051682018306167, 56.077309110971569],
            [21.058054141423952, 56.079436122698652],
            [21.099027063554814, 56.085409066918331],
            [21.12389114120387, 56.087491131672792],
            [21.146663263452638, 56.088045007836214],
            [21.207427200140359, 56.085682149324541],
            [21.226100236448758, 56.101654033507927],
            [21.234300252348305, 56.132073133683051],
            [21.241800211692549, 56.165264129246154],
            [21.258891247591066, 56.178600072511571],
            [21.359509122332781, 56.239373229292951],
            [21.388191156889093, 56.240273110420347],
            [21.421318114711653, 56.240964282158146],
            [21.447773245225903, 56.248882163196001],
            [21.566663333665019, 56.295273147554511],
            [21.73554510443887, 56.323327042285072],
            [21.905273279797711, 56.369154092196467],
            [22.043745170490041, 56.414436150761745],
            [22.067218187484144, 56.419445008475506],
            [22.146936121875768, 56.423882220390723],
            [22.309436079192011, 56.403882161167914],
            [22.489300154813179, 56.406382147616185],
            [22.519163199530084, 56.404436037331337],
            [22.553609133640037, 56.399718031658708],
            [22.586936083672754, 56.394718058762734],
            [22.624791273089841, 56.385545071539852],
            [22.648609121581728, 56.371236156950928],
            [22.666245148826363, 56.360545039420813],
            [22.691382141243622, 56.356653992317987],
            [22.832363238477399, 56.380827065867223],
            [22.853191262098136, 56.392218072314577],
            [22.886382257661239, 56.410273194719011],
            [22.930063205077516, 56.423045035899307],
            [22.951245112651435, 56.420136012575128],
            [22.989718215972289, 56.395827152193974],
            [23.003745163337413, 56.374718167177932],
            [23.015554091478094, 56.352491203913146],
            [23.034445057269664, 56.331382218897303],
            [23.060973277979855, 56.31652713949596],
            [23.105273312766599, 56.306173142112399],
            [23.138609147616819, 56.318191114918918],
            [23.174891221441442, 56.354854062424934],
            [23.296109132037003, 56.380818181050003],
            [23.326109137052129, 56.379018083518858],
            [23.36305405178166, 56.367073200908195],
            [23.448054093931034, 56.3441540600774],
            [23.525273215340974, 56.334164172568805],
            [23.553473290463216, 56.335964270099836],
            [23.573473182047934, 56.345273211792531],
            [23.599718094430045, 56.361518178382326],
            [23.733745061855927, 56.360691219812693],
            [23.873609186669569, 56.342764172888948],
            [23.948882197794546, 56.332218062284241],
            [23.999854059889515, 56.320409134143475],
            [24.069854015712139, 56.283609058701174],
            [24.141800249457702, 56.262773156091313],
            [24.169300268024756, 56.262218106461418],
            [24.265000144883572, 56.28943599016695],
            [24.334373301985011, 56.309709131795856],
            [24.371800176148611, 56.298600092572215],
            [24.420345141991334, 56.275891169873759],
            [24.44943604578549, 56.269718065805748],
            [24.476663317222489, 56.269436098582233],
            [24.560554098302362, 56.28943599016695],
            [24.582500267810019, 56.312491085467727],
            [24.602773241800833, 56.329164199672832],
            [24.653327182202361, 56.365827147178763],
            [24.894163233348792, 56.449854050366625],
            [24.918882304072525, 56.442627005790499],
            [24.942218193130344, 56.406936023779522],
            [24.956663229827086, 56.382491208928357],
            [24.970554055084335, 56.35360918216189],
            [24.981936176714356, 56.329164199672832],
            [24.996527226164801, 56.300273120450768],
            [25.07680021018615, 56.214853983141481],
            [25.101527159898808, 56.199154007897803],
            [25.139854082827867, 56.18749109251074],
            [25.160273237210674, 56.184436056432858],
            [25.183054076638683, 56.183327130639711],
            [25.262500269728577, 56.18082714419144],
            [25.327500252655057, 56.169154002882678],
            [25.429718232717363, 56.166382107494698],
            [25.582773293871213, 56.151382188806238],
            [25.708609130214967, 56.097036107759564],
            [25.891109146754104, 55.999436053445535],
            [25.937218163889213, 55.978736105305686],
            [26.005327162350227, 55.957436012896707],
            [26.05832710974309, 55.938044982760275],
            [26.196245124271883, 55.863745110596696],
            [26.216382143793084, 55.845827116128461],
            [26.239718200488994, 55.816936036906483],
            [26.266109125624808, 55.7734000964155],
            [26.313327068552951, 55.73971808896313],
            [26.33860923553371, 55.726382145697514],
            [26.366945097487786, 55.716518154375123],
            [26.50458215062136, 55.685127088705073],
            [26.531391165088678, 55.680827172364033],
            [26.583327113689563, 55.675545064606169],
            [26.613209101507579, 55.674836123233646],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "LUX", Country: "Luxembourg" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.134409095598841, 50.127844969227382],
            [6.129163197662791, 50.119164167360438],
            [6.118336125662665, 50.090827131939974],
            [6.113054017904915, 50.057354001515378],
            [6.130554090679539, 50.013882098764739],
            [6.14055403647194, 49.99389109199744],
            [6.174163289004639, 49.951109187518128],
            [6.234163299034861, 49.897500211038889],
            [6.252782188248545, 49.882354111958634],
            [6.315936140814642, 49.855318115552549],
            [6.321109116192844, 49.848327105370501],
            [6.395554162919979, 49.817773224191981],
            [6.429718129806332, 49.808882204192898],
            [6.481945098086129, 49.811100055779278],
            [6.524027113648287, 49.807700188203611],
            [6.505836036773758, 49.706627008480282],
            [6.472500201923623, 49.69298210826274],
            [6.42083616226256, 49.665836141286761],
            [6.356491141252064, 49.525864225198106],
            [6.362782262516987, 49.489164229679986],
            [6.362173233430525, 49.459391206603343],
            [6.328336160768941, 49.469164170457177],
            [6.302782252486651, 49.476664129801506],
            [6.247318193191347, 49.505973130716811],
            [6.234718181026693, 49.50805402200497],
            [6.160691223631147, 49.504300102838343],
            [6.120245020297205, 49.476000115429926],
            [6.092500250156775, 49.453609034500616],
            [5.979582267746935, 49.448464222317241],
            [5.855282163707159, 49.501109112290649],
            [5.838609217140061, 49.514164261798939],
            [5.810836116166712, 49.537636105326769],
            [5.807882165841391, 49.545045037202144],
            [5.835973276222148, 49.549718115873631],
            [5.863054031991197, 49.571664117743197],
            [5.900282255048921, 49.640545089488782],
            [5.899163270971883, 49.662773226219954],
            [5.876154108500629, 49.709882204406611],
            [5.869445065542209, 49.719718032534203],
            [5.790282180780594, 49.780827135995949],
            [5.752636036029344, 49.792282180183648],
            [5.734445126792991, 49.835000046922417],
            [5.747773191069484, 49.907491104375808],
            [5.778609207109724, 49.957009041541866],
            [5.834445087630854, 49.98693612399903],
            [5.883054091214007, 50.077218106268262],
            [5.887709232612593, 50.097427042518845],
            [5.97305410625944, 50.170000074985396],
            [6.017918075493327, 50.181809170764254],
            [6.026254045862572, 50.181253953496352],
            [6.030554129841789, 50.163053991804603],
            [6.120000101085822, 50.131945060996244],
            [6.134409095598841, 50.127844969227382],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "LVA", Country: "Latvia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.372054146418606, 57.535636067739389],
            [27.425000114354958, 57.531936128029415],
            [27.449436212026598, 57.531109169459867],
            [27.471663342929361, 57.531382251866162],
            [27.547218153640046, 57.536382224762221],
            [27.542773230373513, 57.493609205100526],
            [27.527773311685252, 57.444327134690454],
            [27.571391227189366, 57.418191187049942],
            [27.606382152645182, 57.410545047313818],
            [27.687218232923414, 57.379991166135298],
            [27.825273207750598, 57.323882203207958],
            [27.855345129495305, 57.305963202911343],
            [27.863882265541378, 57.262500184978208],
            [27.849373191104206, 57.17991815426366],
            [27.774718093882854, 57.057209103089249],
            [27.701663269620354, 56.914709204995532],
            [27.731109230834051, 56.869154064024045],
            [27.76250012886598, 56.873600160756794],
            [27.802500247311542, 56.878182211959412],
            [27.837773307629192, 56.875545097575142],
            [27.860554147057286, 56.868882155084052],
            [27.938818156519829, 56.822427132985084],
            [27.926727093517428, 56.794018180835153],
            [27.943609252388313, 56.722763286465479],
            [28.00944507870193, 56.648327124555848],
            [28.1141632128504, 56.51749114767766],
            [28.147773303573302, 56.471373245725246],
            [28.182773281484771, 56.406382147616185],
            [28.235963330442047, 56.276382181763026],
            [28.19708219607341, 56.181236013429654],
            [28.183463279755955, 56.161936010761977],
            [28.168009229552098, 56.150154072349423],
            [28.149645150195596, 56.160964212905114],
            [28.121663339832679, 56.167491200926108],
            [27.922491214916846, 56.105127158917426],
            [27.8977733176595, 56.070273193759775],
            [27.796109213760445, 56.001109081324174],
            [27.712491179810598, 55.956100105164992],
            [27.646382271090971, 55.919573112128788],
            [27.632045193307221, 55.84600011861049],
            [27.62000023077249, 55.818327097561323],
            [27.600245090761149, 55.792518044145638],
            [27.559718085880348, 55.789164277037614],
            [27.531109141520091, 55.788891194631518],
            [27.310827193397898, 55.807491140743977],
            [27.21166324358515, 55.825827056905652],
            [27.152773165176455, 55.846518120228168],
            [26.985827111127293, 55.832354044926646],
            [26.904991198487124, 55.775545025444117],
            [26.883263293738906, 55.740482183258692],
            [26.85666332393734, 55.71388221345704],
            [26.765827130228701, 55.687491120683333],
            [26.738473292053357, 55.687764203089429],
            [26.692054144500048, 55.701735997531628],
            [26.66527329322733, 55.705827036845008],
            [26.642218197926638, 55.698744999194091],
            [26.624854079621912, 55.687909042376745],
            [26.613209101507579, 55.674836123233646],
            [26.583327113689563, 55.675545064606169],
            [26.531391165088678, 55.680827172364033],
            [26.50458215062136, 55.685127088705073],
            [26.366945097487786, 55.716518154375123],
            [26.33860923553371, 55.726382145697514],
            [26.313327068552951, 55.73971808896313],
            [26.266109125624808, 55.7734000964155],
            [26.239718200488994, 55.816936036906483],
            [26.216382143793084, 55.845827116128461],
            [26.196245124271883, 55.863745110596696],
            [26.05832710974309, 55.938044982760275],
            [26.005327162350227, 55.957436012896707],
            [25.937218163889213, 55.978736105305686],
            [25.891109146754104, 55.999436053445535],
            [25.708609130214967, 56.097036107759564],
            [25.582773293871213, 56.151382188806238],
            [25.429718232717363, 56.166382107494698],
            [25.327500252655057, 56.169154002882678],
            [25.262500269728577, 56.18082714419144],
            [25.183054076638683, 56.183327130639711],
            [25.160273237210674, 56.184436056432858],
            [25.139854082827867, 56.18749109251074],
            [25.101527159898808, 56.199154007897803],
            [25.07680021018615, 56.214853983141481],
            [24.996527226164801, 56.300273120450768],
            [24.981936176714356, 56.329164199672832],
            [24.970554055084335, 56.35360918216189],
            [24.956663229827086, 56.382491208928357],
            [24.942218193130344, 56.406936023779522],
            [24.918882304072525, 56.442627005790499],
            [24.894163233348792, 56.449854050366625],
            [24.653327182202361, 56.365827147178763],
            [24.602773241800833, 56.329164199672832],
            [24.582500267810019, 56.312491085467727],
            [24.560554098302362, 56.28943599016695],
            [24.476663317222489, 56.269436098582233],
            [24.44943604578549, 56.269718065805748],
            [24.420345141991334, 56.275891169873759],
            [24.371800176148611, 56.298600092572215],
            [24.334373301985011, 56.309709131795856],
            [24.265000144883572, 56.28943599016695],
            [24.169300268024756, 56.262218106461418],
            [24.141800249457702, 56.262773156091313],
            [24.069854015712139, 56.283609058701174],
            [23.999854059889515, 56.320409134143475],
            [23.948882197794546, 56.332218062284241],
            [23.873609186669569, 56.342764172888948],
            [23.733745061855927, 56.360691219812693],
            [23.599718094430045, 56.361518178382326],
            [23.573473182047934, 56.345273211792531],
            [23.553473290463216, 56.335964270099836],
            [23.525273215340974, 56.334164172568805],
            [23.448054093931034, 56.3441540600774],
            [23.36305405178166, 56.367073200908195],
            [23.326109137052129, 56.379018083518858],
            [23.296109132037003, 56.380818181050003],
            [23.174891221441442, 56.354854062424934],
            [23.138609147616819, 56.318191114918918],
            [23.105273312766599, 56.306173142112399],
            [23.060973277979855, 56.31652713949596],
            [23.034445057269664, 56.331382218897303],
            [23.015554091478094, 56.352491203913146],
            [23.003745163337413, 56.374718167177932],
            [22.989718215972289, 56.395827152193974],
            [22.951245112651435, 56.420136012575128],
            [22.930063205077516, 56.423045035899307],
            [22.886382257661239, 56.410273194719011],
            [22.853191262098136, 56.392218072314577],
            [22.832363238477399, 56.380827065867223],
            [22.691382141243622, 56.356653992317987],
            [22.666245148826363, 56.360545039420813],
            [22.648609121581728, 56.371236156950928],
            [22.624791273089841, 56.385545071539852],
            [22.586936083672754, 56.394718058762734],
            [22.553609133640037, 56.399718031658708],
            [22.519163199530084, 56.404436037331337],
            [22.489300154813179, 56.406382147616185],
            [22.309436079192011, 56.403882161167914],
            [22.146936121875768, 56.423882220390723],
            [22.067218187484144, 56.419445008475506],
            [22.043745170490041, 56.414436150761745],
            [21.905273279797711, 56.369154092196467],
            [21.73554510443887, 56.323327042285072],
            [21.566663333665019, 56.295273147554511],
            [21.447773245225903, 56.248882163196001],
            [21.421318114711653, 56.240964282158146],
            [21.388191156889093, 56.240273110420347],
            [21.359509122332781, 56.239373229292951],
            [21.258891247591066, 56.178600072511571],
            [21.241800211692549, 56.165264129246154],
            [21.234300252348305, 56.132073133683051],
            [21.226100236448758, 56.101654033507927],
            [21.207427200140359, 56.085682149324541],
            [21.146663263452638, 56.088045007836214],
            [21.12389114120387, 56.087491131672792],
            [21.099027063554814, 56.085409066918331],
            [21.058054141423952, 56.079436122698652],
            [21.051682018306167, 56.077309110971569],
            [21.031441494000148, 56.068484579000099],
            [21.024693964000164, 56.065542746000048],
            [20.574843054000041, 56.015144754000104],
            [20.562519836000149, 56.01427170500007],
            [20.475602125000023, 56.006369714000144],
            [20.376543919000113, 56.006437751000178],
            [20.365451991000128, 56.006445369],
            [20.360132715000191, 56.00638500500007],
            [20.301487331000146, 56.004730338000016],
            [19.88744423100016, 55.980206111000015],
            [19.06748334460022, 55.955000000400119],
            [19.068684760000082, 55.957270653000094],
            [19.081266667000079, 55.98105],
            [19.090729346000103, 56.023649955000209],
            [19.093579714000128, 56.036482006000128],
            [19.09448340650016, 56.040550116500057],
            [19.226083333000133, 56.25],
            [19.237715717000157, 56.268599414500116],
            [19.351166667000058, 56.45],
            [19.391811806000163, 56.531290279000061],
            [19.397953551000114, 56.543573768999963],
            [19.417833333000146, 56.583333333000056],
            [19.431204363000148, 56.603440144000231],
            [19.44525896900015, 56.624574891000194],
            [19.468444207000118, 56.659439911000149],
            [19.528666667000095, 56.75],
            [19.663446597000132, 56.954928550000076],
            [19.671166667000023, 56.966666667000069],
            [19.695444189000142, 56.996234296000111],
            [19.775604986000133, 57.093862249000082],
            [19.892750000000149, 57.236533333000054],
            [20.036000000000143, 57.445283333000148],
            [20.058549675000108, 57.533770506000181],
            [20.066083333000023, 57.563333333000074],
            [20.094906952000144, 57.592230612000165],
            [20.235650000000192, 57.733333333000118],
            [20.334940664000158, 57.831795117000254],
            [20.362109418000216, 57.858737066000103],
            [20.415333311500092, 57.911516793500184],
            [20.410456307000118, 57.940263435000091],
            [20.396250144400142, 58.023999973000073],
            [21.842783520000154, 57.763050406],
            [21.91611652000006, 57.749450406000065],
            [22.143333521000102, 57.77916640600013],
            [22.583599521000025, 57.927400406000018],
            [22.70749952100013, 57.94083340600001],
            [22.907683521000138, 57.78051640599999],
            [22.999166521000149, 57.702216405999977],
            [23.180833522000171, 57.586383406000081],
            [23.406016522000129, 57.593833406],
            [23.58233352200017, 57.669550406],
            [23.648499522000122, 57.782900406000167],
            [23.601116522000041, 57.89916640600002],
            [24.209449523000046, 57.89916640600002],
            [24.261116523000112, 57.917216406],
            [24.322987650287928, 57.889596348652887],
            [24.314982022232442, 57.871827244831493],
            [24.324436052828673, 57.870273159393989],
            [24.373054108867166, 57.868882098739064],
            [24.411382205262612, 57.872491178618461],
            [24.430827214855697, 57.882627078880674],
            [24.443954113455447, 57.902836015131342],
            [24.549927186150882, 57.953400181454569],
            [24.723882187654908, 57.965000064929583],
            [25.015827061194301, 58.039436059201151],
            [25.038327106865182, 58.050273189484969],
            [25.065409203738795, 58.06319121105706],
            [25.091663336214481, 58.071663303534407],
            [25.131800247320314, 58.076382147397268],
            [25.166518090369976, 58.075136093667638],
            [25.186109112716679, 58.06485401301363],
            [25.201800203142938, 58.04458221248909],
            [25.209018195263667, 58.020273184469957],
            [25.219436062749594, 58.001518173148284],
            [25.2398540436659, 57.992627153149115],
            [25.295209138219548, 58.01090020503672],
            [25.295209138219548, 58.038391171148348],
            [25.267500242624635, 58.072282223266583],
            [25.297845079137517, 58.083253966915734],
            [25.429854019549282, 58.031863177299144],
            [25.452218110750266, 58.005127085389503],
            [25.620409212700565, 57.917353974385847],
            [25.801391266570363, 57.865827062661268],
            [25.836382192026235, 57.860545122541495],
            [25.883054137779965, 57.855827116868952],
            [25.946945194913411, 57.853882180050604],
            [26.035000273140668, 57.823327125405669],
            [26.20444514017143, 57.694991134975666],
            [26.280836129545264, 57.624300175053108],
            [26.306245030902232, 57.608191162933238],
            [26.511391273503961, 57.526100144108128],
            [26.53124515233506, 57.522636071154139],
            [26.606800130683666, 57.527009077691119],
            [26.624163243160183, 57.546245042618168],
            [26.697773284690612, 57.575554043533756],
            [26.878463313053004, 57.632073216803732],
            [26.902218129632843, 57.633463271630077],
            [26.96999118141369, 57.62331815127429],
            [26.996109191781301, 57.618327063195451],
            [27.025273185771397, 57.608044982541443],
            [27.045554038751419, 57.594018035176205],
            [27.077082232357867, 57.565200213788259],
            [27.310273317234561, 57.531382251866077],
            [27.372054146418606, 57.535636067739389],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MAF", Country: "Saint Martin" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.369600776999903, 17.665376846000143],
            [-62.463438669999988, 17.654909993000089],
            [-62.507053485999933, 17.650598518000137],
            [-62.564724602999831, 17.644762572000062],
            [-62.663223927999866, 17.641238663000095],
            [-62.677166531999831, 17.640789268000034],
            [-62.678343559999973, 17.640755744000089],
            [-62.711972149999923, 17.64150851600003],
            [-62.769858395999961, 17.642872968000049],
            [-62.774281781999832, 17.644411993000105],
            [-62.799499664999956, 17.653186031000175],
            [-62.800612881999967, 17.653570709000107],
            [-62.804626965999915, 17.654970127000084],
            [-62.812524710999924, 17.657482527000113],
            [-62.848245700999911, 17.672362068000055],
            [-62.861022744999985, 17.677684332000169],
            [-62.88463044, 17.688475704000084],
            [-62.896598448999924, 17.693013226000076],
            [-62.936876206999983, 17.707939814000085],
            [-62.952830345999928, 17.713984403000083],
            [-62.994003293999896, 17.725184042000123],
            [-63.017807529999942, 17.731768435000092],
            [-63.020592054999952, 17.735150815000097],
            [-63.048822563000016, 17.769442586000039],
            [-63.102816314999927, 17.812076913000041],
            [-63.044005947999921, 17.90608616100009],
            [-63.021983740999985, 17.940505995000066],
            [-63.013534285999953, 17.953090329000062],
            [-63.012567907999937, 17.954540217000059],
            [-63.011614359, 17.956040642000104],
            [-63.004042489999932, 17.968071893000101],
            [-63.000331595999938, 17.971780100000061],
            [-62.973877444999943, 17.990227224000066],
            [-62.955330317999938, 18.015434743000114],
            [-62.950697744999957, 18.021893626000107],
            [-62.950105720999915, 18.022693605000043],
            [-62.928999154999985, 18.051083279000139],
            [-62.975959454, 18.047290035000074],
            [-62.997360716999964, 18.056549506000053],
            [-63.01053105799997, 18.056549974000106],
            [-63.144970041999954, 18.055749902000073],
            [-63.148599696999895, 18.053168460000094],
            [-63.153877833, 18.047977445000114],
            [-63.162104802999977, 18.034969992000057],
            [-63.184446621999911, 18.003316112000064],
            [-63.234853241999957, 17.936250750000042],
            [-63.244301267999901, 17.92528743900003],
            [-63.248727136999918, 17.920329006000017],
            [-63.290176064999969, 17.873241763000081],
            [-63.36139501599996, 17.887225751000088],
            [-63.657983726999959, 17.950580837000089],
            [-63.615833332999955, 17.959722221000092],
            [-63.47388888899988, 18.034166666000132],
            [-63.209444443999928, 18.098055555000045],
            [-63.090255825999947, 18.13588112300009],
            [-62.948611110999877, 18.18083333200012],
            [-62.936066546999967, 18.182473776000037],
            [-62.876388888999855, 18.190277777000134],
            [-62.739444443999872, 18.155833332000086],
            [-62.496111110999976, 18.221944443000027],
            [-62.42274566599994, 18.245960587000084],
            [-62.233055555999897, 18.308055555000109],
            [-62.227084755999982, 18.310010094000077],
            [-62.213512485000024, 18.314452969999977],
            [-62.221508085999972, 18.279723558000072],
            [-62.237315984999896, 18.211060925000041],
            [-62.251408086999987, 18.155419206000104],
            [-62.255590559000012, 18.138904997000068],
            [-62.259307357999859, 18.123325798000067],
            [-62.274254027999945, 18.060675882000069],
            [-62.313219783999898, 17.912907957000087],
            [-62.314034561, 17.909809665000054],
            [-62.328891637999845, 17.851726859000124],
            [-62.336323622999885, 17.821422682000062],
            [-62.348503746999967, 17.764104826000093],
            [-62.362958324999909, 17.696083733000123],
            [-62.36510849599992, 17.686143872000045],
            [-62.369600776999903, 17.665376846000143],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MAR", Country: "Morocco" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.10138888900002, 35.905555559000035],
            [-2.074045802999819, 35.776252202000066],
            [-2.067130168999967, 35.73086259],
            [-2.061293646999957, 35.647157119000113],
            [-2.159012631999815, 35.434049824000098],
            [-2.16500930299992, 35.421128507000091],
            [-2.169510617999862, 35.411720570999975],
            [-2.197173535999866, 35.354079891000012],
            [-2.205292522, 35.312435315000116],
            [-2.218531032999977, 35.234826745],
            [-2.22200030599987, 35.200924444000051],
            [-2.222000289999869, 35.190397266999966],
            [-2.21948581700002, 35.160480853000124],
            [-2.218198575, 35.154114877],
            [-2.217342527999961, 35.150838413000074],
            [-2.21261494699985, 35.134905545000109],
            [-2.212631951499873, 35.133635246000139],
            [-2.212090248999971, 35.127688710000101],
            [-2.211009174999873, 35.122203036000073],
            [-2.20788693899982, 35.101349594000013],
            [-2.209358552999902, 35.086900565800065],
            [-2.209445771216821, 35.085827096396557],
            [-2.203263973548388, 35.037491077213716],
            [-2.180281800805545, 35.012218130326559],
            [-2.074999899847938, 34.943600182703264],
            [-1.856390811966094, 34.801382084195339],
            [-1.797845900331055, 34.770618152522744],
            [-1.757599856845559, 34.754645094872771],
            [-1.800836731030614, 34.66389121145356],
            [-1.819717806176186, 34.648327023041688],
            [-1.854963876765623, 34.61437310664941],
            [-1.73257283499828, 34.500754061856057],
            [-1.692581936646178, 34.489082094013483],
            [-1.732499912440431, 34.440273098220061],
            [-1.793336771686285, 34.378327145543167],
            [-1.750554867206972, 34.3494451187767],
            [-1.719163969175014, 34.266664101680092],
            [-1.65833666529889, 34.100000182492622],
            [-1.654445953472049, 34.08360903551123],
            [-1.687499821098669, 33.966382084304755],
            [-1.70833672953691, 33.890827105956149],
            [-1.728272918657353, 33.704491195237111],
            [-1.695136740741304, 33.678464212338085],
            [-1.654372863276109, 33.662773121911826],
            [-1.605554982665353, 33.589854084481104],
            [-1.600690796601015, 33.559718124995996],
            [-1.599863838031496, 33.516245048778885],
            [-1.629163954129467, 33.443191062706759],
            [-1.648890930945981, 33.412218086368213],
            [-1.667290884848285, 33.376518051901726],
            [-1.666663918488922, 33.258882063819144],
            [-1.646108809636218, 33.226391124811457],
            [-1.5805549505464, 33.145827121110997],
            [-1.548890802470055, 33.111382192829453],
            [-1.51721776957649, 33.088609064752276],
            [-1.490208762898817, 33.073473191594005],
            [-1.478263880288239, 33.050754042973367],
            [-1.477499785992478, 33.019573195435655],
            [-1.486181928963987, 32.981873238865759],
            [-1.542499768919157, 32.93943599824614],
            [-1.382781932912479, 32.724445042699116],
            [-1.266945874722978, 32.667218101523062],
            [-1.011808787976634, 32.505553987593757],
            [-1.040281945505086, 32.47193601788193],
            [-1.0638909169692, 32.450000074296256],
            [-1.117636686108654, 32.416935980747766],
            [-1.151108810704955, 32.410553999652066],
            [-1.19152685084407, 32.405691154692207],
            [-1.215972839161623, 32.382500104921704],
            [-1.250417767443082, 32.32347323385271],
            [-1.25805485472381, 32.240826997759612],
            [-1.257726787032567, 32.208018049344574],
            [-1.291872816645991, 32.172291192787654],
            [-1.27583672708397, 32.156245044941826],
            [-1.241663875380397, 32.167773179325465],
            [-1.206663897468928, 32.172773152221723],
            [-1.175972888354011, 32.138744972167203],
            [-1.180554939556743, 32.112218092561577],
            [-1.207781875717586, 32.091809164100752],
            [-1.238608839302401, 32.085826999787585],
            [-1.25916394815502, 32.085273123624162],
            [-1.286254929845967, 32.092491115744977],
            [-1.31221787500445, 32.100418049238229],
            [-1.47249981309642, 32.10944502370721],
            [-1.492499872319229, 32.107773169295044],
            [-1.659445926368306, 32.111109166768344],
            [-1.901663818075974, 32.119991134311917],
            [-1.931108773461375, 32.121109112560745],
            [-1.982781865577834, 32.124718024801879],
            [-2.102363963945066, 32.13610903124912],
            [-2.134999909878161, 32.143053940963554],
            [-2.166390807910318, 32.146109144679613],
            [-2.26416386470629, 32.15471802981709],
            [-2.302499840090661, 32.157218016265162],
            [-2.338190822101751, 32.157218016265162],
            [-2.417781854478761, 32.149164180757609],
            [-2.46389087161387, 32.143609158231627],
            [-2.85944595933708, 32.086527056342717],
            [-2.895490825301152, 32.068609061874568],
            [-2.934445888055961, 32.032909027408081],
            [-2.943472862524942, 32.011382120698514],
            [-2.9484728354212, 31.963054078510751],
            [-2.94639077066654, 31.939864202206635],
            [-2.949999850545936, 31.898609145214081],
            [-2.959445920175028, 31.868891107422385],
            [-2.97639077568175, 31.847500155182743],
            [-2.999445870982441, 31.83332702742571],
            [-3.096945845372318, 31.796664247557786],
            [-3.159717750790435, 31.778326990291546],
            [-3.234717847147408, 31.758054016300733],
            [-3.2874998650571, 31.744718073035116],
            [-3.386108765240209, 31.72610907446726],
            [-3.419999817358246, 31.707773158305585],
            [-3.481390888043506, 31.679445007702341],
            [-3.517081870054682, 31.673264192283582],
            [-3.547781931624911, 31.678053947047502],
            [-3.569163831409071, 31.682500043780337],
            [-3.630554902094332, 31.696391204313827],
            [-3.654308880483853, 31.70853607913476],
            [-3.685281856822201, 31.715826993813138],
            [-3.717917802755494, 31.716109128674944],
            [-3.791390883987617, 31.705553965614641],
            [-3.813472840326909, 31.698054006270397],
            [-3.828336804545557, 31.682773126186518],
            [-3.834999914674455, 31.650273134723236],
            [-3.842990885908137, 31.489791204421195],
            [-3.826599906564837, 31.452918038782954],
            [-3.803890816228005, 31.339164212986063],
            [-3.825554850873857, 31.182782206814423],
            [-3.823890875450815, 31.161591079147044],
            [-3.80152678424983, 31.147636048511274],
            [-3.781663852963334, 31.152636021407616],
            [-3.697781789062788, 31.150273162895829],
            [-3.600136807747617, 31.090827029028745],
            [-3.59284589306904, 31.02826416827638],
            [-3.602217866673868, 30.991391170276202],
            [-3.6222177582585, 30.973609130277865],
            [-3.646663914214116, 30.9611090303993],
            [-3.704717813959888, 30.941944982201719],
            [-3.75028183974888, 30.94097318434477],
            [-3.800281903986701, 30.941109138814582],
            [-3.840554937200608, 30.938053935098608],
            [-3.879999838378211, 30.934164229100261],
            [-3.925281896943602, 30.928609038936017],
            [-3.987499758560404, 30.918327125920186],
            [-4.008054867413108, 30.91332698538595],
            [-4.033336699117513, 30.901527109700581],
            [-4.132781945601693, 30.80444505700423],
            [-4.149446007351401, 30.786944984229606],
            [-4.164999802203283, 30.772500115170757],
            [-4.215136826739524, 30.742636064625643],
            [-4.261108883576298, 30.727773106235205],
            [-4.337363918480207, 30.693473184879153],
            [-4.359999918621099, 30.679164102652223],
            [-4.378890884412584, 30.660273136860738],
            [-4.399581947735015, 30.647218154990341],
            [-4.429999874443638, 30.637218041560047],
            [-4.467781806026693, 30.631664192500367],
            [-4.639163898524799, 30.600827002993739],
            [-4.915136720242799, 30.509854016624885],
            [-4.938190809715195, 30.495553986853352],
            [-4.969717829855057, 30.46332707779645],
            [-4.994445953034301, 30.423609094212352],
            [-5.010836764739707, 30.393891056420856],
            [-5.029163963722056, 30.359164160915682],
            [-5.048054929513626, 30.316664223660084],
            [-5.080972842670434, 30.262364243081137],
            [-5.139163870352291, 30.192364119620422],
            [-5.152136709571352, 30.180473216466382],
            [-5.170836735607878, 30.160273165033132],
            [-5.223054986708433, 30.107500031940944],
            [-5.27583666934197, 30.05527323129904],
            [-5.313608877917403, 30.025835987264671],
            [-5.347499762397433, 30.000273194164876],
            [-5.39083671650647, 29.976391140294552],
            [-5.530690783036221, 29.905973095140311],
            [-5.569481895764511, 29.895226992325163],
            [-5.627499920964539, 29.894164166999545],
            [-5.694446014175668, 29.889164194103486],
            [-5.733336703913977, 29.884718097370566],
            [-5.790554927910591, 29.876391179456803],
            [-5.898326757032493, 29.856673087457622],
            [-5.941254841903486, 29.840691144990316],
            [-5.992917875736254, 29.830554071261432],
            [-6.039026725233072, 29.833054057709589],
            [-6.063890802882213, 29.843745007601726],
            [-6.08499978789817, 29.847918021928351],
            [-6.111108745810355, 29.848891160889579],
            [-6.139308820932627, 29.848191104334447],
            [-6.161945994539877, 29.841391201545335],
            [-6.195136822464804, 29.808264243722775],
            [-6.224445991018285, 29.798882211834339],
            [-6.400281891600144, 29.804445113349331],
            [-6.481663969052704, 29.739582090721044],
            [-6.49736394429658, 29.669445006961936],
            [-6.511254937191808, 29.63389115288733],
            [-6.527781871005118, 29.6129181223411],
            [-6.554163911323514, 29.586936066443414],
            [-6.583399822043162, 29.568327067875359],
            [-6.635554873593492, 29.566109048650887],
            [-6.696945944278752, 29.575136023120066],
            [-6.732290921325784, 29.589236060681046],
            [-6.78528181626325, 29.603609013010427],
            [-6.840836735389047, 29.612218065785882],
            [-7.104999790776077, 29.640273133982831],
            [-7.1262549561838, 29.63582703725011],
            [-7.19499980582151, 29.597773196727232],
            [-7.296108860090669, 29.539854077985083],
            [-7.316808808230604, 29.517427122510142],
            [-7.336108810898196, 29.486382061804065],
            [-7.356390837344605, 29.46166416454669],
            [-7.436390906597751, 29.396109131990315],
            [-7.461945988346599, 29.388609172646071],
            [-7.487781863852518, 29.385273175172969],
            [-7.518336750859447, 29.387218111991146],
            [-7.539445903513581, 29.389718098439218],
            [-7.560554888529708, 29.393609145542328],
            [-7.58971787669131, 29.39555408236059],
            [-7.612917811279317, 29.393327010680636],
            [-7.642363940131105, 29.386109018559907],
            [-7.662917875517422, 29.369582084746597],
            [-7.690281939614465, 29.341109094856236],
            [-7.798054942202839, 29.256109052706861],
            [-7.986945992293073, 29.122773089380601],
            [-8.144999852876595, 29.056527052724689],
            [-8.172363916973808, 29.043473076682787],
            [-8.199999890010758, 29.026664175645678],
            [-8.359726778472719, 28.917636066872362],
            [-8.374999779567588, 28.903326984645432],
            [-8.396663981851418, 28.872918110392206],
            [-8.410554974746759, 28.849445093397989],
            [-8.495554849258042, 28.790554009160999],
            [-8.521108757540418, 28.779444969937188],
            [-8.555554859288378, 28.769718106551167],
            [-8.57847282665287, 28.759853947590486],
            [-8.628336768782788, 28.732773191821437],
            [-8.667217735513276, 28.709445014114536],
            [-8.667217735513276, 27.933327046005743],
            [-8.666663859349939, 27.666664171808733],
            [-9.299999794503606, 27.66610912217881],
            [-9.333608879398128, 27.666664171808733],
            [-9.667217846806352, 27.666664171808733],
            [-9.833336774647819, 27.666664171808733],
            [-9.933608812063483, 27.666664171808733],
            [-9.999999855644887, 27.66638220458502],
            [-10.022517838588783, 27.665009081202996],
            [-10.067236800897149, 27.664236102090015],
            [-10.120508824867812, 27.664773214447095],
            [-10.299736714036214, 27.666573144339949],
            [-12.841099924355547, 27.666445068859062],
            [-13.067499823622541, 27.66610912217881],
            [-13.166945908297066, 27.66638220458502],
            [-13.174963691130813, 27.666954192396005],
            [-13.190355966999903, 27.666579373000118],
            [-13.228669357999877, 27.666596300000108],
            [-13.851430131428572, 27.666871818000075],
            [-13.727651533999847, 27.761725992000137],
            [-13.684948187999908, 27.808180012999983],
            [-13.669748366999841, 27.825170199000141],
            [-13.605988648999954, 27.897320120000032],
            [-13.601977847999819, 27.901874850000141],
            [-13.596978758, 27.907497529000139],
            [-13.577867350999838, 27.928854256000065],
            [-13.549666464999916, 27.959850827000125],
            [-13.544680563999862, 27.965404117999967],
            [-13.492325321999971, 28.022044313000109],
            [-13.475780879999832, 28.038826394999987],
            [-13.452280073999873, 28.062614112],
            [-13.411370008999882, 28.140021735],
            [-13.399267910999896, 28.162404272999979],
            [-13.39230420899986, 28.176716303999953],
            [-13.386704610499862, 28.18646336750011],
            [-13.314685972999854, 28.295337441000171],
            [-13.295720592999885, 28.322790263000016],
            [-13.294332559999987, 28.3250314510001],
            [-13.276396314999886, 28.353912771000111],
            [-13.260396806000017, 28.379604558999986],
            [-13.249308984999914, 28.396977605000117],
            [-13.236589265999868, 28.412083992000177],
            [-13.164954366999865, 28.496881975000136],
            [-12.976734043999841, 28.571598840999982],
            [-12.967730422999892, 28.575751215000096],
            [-12.905938950999854, 28.604677757000118],
            [-12.896224990999826, 28.60939011100011],
            [-12.843497717, 28.636049368000116],
            [-12.771995464999918, 28.675470937000128],
            [-12.753624317999822, 28.687169113],
            [-12.686708867999869, 28.730021794000137],
            [-12.64425583799985, 28.762401210000021],
            [-12.636126717999957, 28.768664676000114],
            [-12.629379687999915, 28.773993607000037],
            [-12.610317798999858, 28.789115569999964],
            [-12.575847823999936, 28.817019810999952],
            [-12.56887408699987, 28.822694084000048],
            [-12.551260743999876, 28.832662275000146],
            [-12.542910407999869, 28.837455180000049],
            [-12.541904350000038, 28.83804435600014],
            [-12.509163835999914, 28.857878701000047],
            [-12.500168817999878, 28.863580243000101],
            [-12.484622664000028, 28.873487380000014],
            [-12.437995588999883, 28.905483966000034],
            [-12.431719471, 28.910914269],
            [-12.404165679999977, 28.934798094000072],
            [-12.400173404999862, 28.938317854999966],
            [-12.393674119999901, 28.944330609000176],
            [-12.31961964899989, 29.014852106999982],
            [-12.318533820999846, 29.015898015000019],
            [-12.305397917999841, 29.028640489999972],
            [-12.289051599999851, 29.044988563000018],
            [-12.265532132000033, 29.068666379999982],
            [-12.237088791999838, 29.099073540000134],
            [-12.22851361799988, 29.108652493000179],
            [-12.209258831999875, 29.130801132000087],
            [-12.204507509999843, 29.140050339000126],
            [-12.179687540999879, 29.18887084],
            [-12.168649098999879, 29.211223068999985],
            [-12.135148367999932, 29.279828762],
            [-12.127510202999872, 29.295532362000117],
            [-12.083990797999832, 29.400495489000107],
            [-12.081397780999964, 29.406796863000025],
            [-12.069174971999928, 29.436749151],
            [-11.993903601999847, 29.675916931000145],
            [-11.97294183599999, 29.743553525],
            [-11.900170647999914, 29.985668034000142],
            [-11.836998349999959, 30.108815639000156],
            [-11.794282071999817, 30.19617237400017],
            [-11.783387855999848, 30.219098337],
            [-11.767352523999847, 30.253890943000087],
            [-11.753226528999988, 30.323090359000119],
            [-11.753166798999843, 30.324310062000151],
            [-11.860662245000015, 30.644523495],
            [-11.996469311999846, 31.013150628000133],
            [-12.515678046999881, 32.010477846000114],
            [-12.908772424999825, 32.75449358300007],
            [-12.48362, 34.175680000000156],
            [-12.590107907999965, 34.395464826000037],
            [-12.625847835, 34.469229949000109],
            [-12.045882988999892, 34.937164987000116],
            [-11.66667, 34.91667],
            [-10.499999999999886, 35.01667],
            [-9.216669999999851, 35.116670000000127],
            [-8.883329999999859, 35.18333],
            [-8.349999999999852, 35.31667],
            [-8.08333, 35.43333000000014],
            [-7.558469882999873, 35.751433403000121],
            [-7.548615374999855, 35.757405951000109],
            [-7.520946964999837, 35.760053222000025],
            [-7.252536792999848, 35.786673314000083],
            [-7.076003019999916, 35.807245553000129],
            [-7.067371850999876, 35.808284522000022],
            [-7.050054641000031, 35.81148326],
            [-6.954660721999886, 35.831249447000161],
            [-6.913913457999882, 35.840024368000016],
            [-6.892561568999895, 35.844810607000099],
            [-6.800162425999844, 35.866377491000108],
            [-6.784461752, 35.867671673000061],
            [-6.766298907999897, 35.869326839000152],
            [-6.754642107999985, 35.870496683000042],
            [-6.715045555999865, 35.874470484000128],
            [-6.671598468999861, 35.879247722000159],
            [-6.640614131999939, 35.882654615000135],
            [-6.623020884999903, 35.884768669999985],
            [-6.602679126999817, 35.887299889000175],
            [-6.053888888999865, 35.958888889000022],
            [-5.980555555999899, 35.958888889000022],
            [-5.888333332999935, 35.952222222000088],
            [-5.649444443999869, 35.936111111000017],
            [-5.582777777999979, 35.942222222000012],
            [-5.48333333299999, 35.971666667000065],
            [-5.413333332999883, 35.988611111000168],
            [-5.386388888999818, 35.994444444],
            [-5.375067965666545, 35.996262089666786],
            [-5.377108854999904, 35.986075229000036],
            [-5.378830216999944, 35.977483253500154],
            [-5.383691174999882, 35.953220354000152],
            [-5.384116194199891, 35.951963851400151],
            [-5.385674303999849, 35.947096791000106],
            [-5.387854732499818, 35.940261179500069],
            [-5.39104034699983, 35.930274315000062],
            [-5.391651901999978, 35.927061475000144],
            [-5.391742877714137, 35.926430369285711],
            [-5.391786886999881, 35.92513314300011],
            [-5.391924810499773, 35.923853669000138],
            [-5.392143913249868, 35.921852591750095],
            [-5.393917198499878, 35.918750575500169],
            [-5.394371142666586, 35.917934120666743],
            [-5.395494009954177, 35.91656506488691],
            [-5.392363899269299, 35.900554024290173],
            [-5.377217800189044, 35.871518105781078],
            [-5.354446013216347, 35.847218130217144],
            [-5.345836729401327, 35.841663797509867],
            [-5.341769735249926, 35.842009533250078],
            [-5.323396588999913, 35.841252992000065],
            [-5.307976784999852, 35.838529842],
            [-5.292306801999871, 35.835437755000143],
            [-5.286975487, 35.831871750000047],
            [-5.275628737999881, 35.826619159000032],
            [-5.272708787999875, 35.825158322000036],
            [-5.259847841999971, 35.817863842000108],
            [-5.255167673999978, 35.815359633000114],
            [-5.251533030999894, 35.813435479000063],
            [-5.246242035999899, 35.811097330000152],
            [-5.242799862999931, 35.809538343000028],
            [-5.236224965999838, 35.806489437],
            [-5.224330738, 35.801842966000137],
            [-5.203213544999869, 35.79484173400003],
            [-5.198340695999832, 35.793110245000022],
            [-5.196485484999812, 35.792451026000109],
            [-5.187669755999849, 35.792608598000086],
            [-5.076941510999887, 35.794587745999976],
            [-5.062781629, 35.794875068000024],
            [-5.010771406999964, 35.795575164000141],
            [-4.788507584999849, 35.797617814000134],
            [-4.780340632999923, 35.799091010999987],
            [-4.544082493999952, 35.861089097000146],
            [-4.500821254999948, 35.875919395000111],
            [-3.989476377999893, 35.994908977000065],
            [-3.949352322999857, 35.995366287000067],
            [-3.942430033999983, 35.996152117000165],
            [-3.85322495399987, 35.936280017000044],
            [-3.710016536999859, 35.839774795000082],
            [-3.64207435000003, 35.79412514600007],
            [-3.612015259999907, 35.774231205000106],
            [-3.452004235999823, 35.670921685999971],
            [-3.439327728999899, 35.662752763000057],
            [-3.358272958999947, 35.667644590999984],
            [-3.220219608999855, 35.675976404999957],
            [-3.191792514999861, 35.677588845000045],
            [-3.179698804999845, 35.67827482400007],
            [-3.097782855999867, 35.682992636000151],
            [-3.059625959999977, 35.686055128000149],
            [-3.040487500999888, 35.687591190000049],
            [-3.023079093999968, 35.688957158000093],
            [-2.980010967999846, 35.69233654300011],
            [-2.947442125999828, 35.694965424000017],
            [-2.878118516999905, 35.701629173000086],
            [-2.783724345999815, 35.710369059000172],
            [-2.751330929999824, 35.713431833],
            [-2.714233279999888, 35.716710641000034],
            [-2.638793482999915, 35.723975537000015],
            [-2.519330091999933, 35.735196518000137],
            [-2.370260653999821, 35.74896755200011],
            [-2.10138888900002, 35.905555559000035],
          ],
          [
            [-2.914687194452199, 35.2736437154566],
            [-2.914234994999845, 35.274145712000049],
            [-2.904253141999845, 35.278295471000135],
            [-2.896490606999919, 35.27976877400009],
            [-2.877437060999938, 35.282001113999954],
            [-2.862136215999953, 35.285753502000105],
            [-2.846041407999905, 35.291173501000017],
            [-2.840641171999835, 35.292959840000023],
            [-2.812204683999852, 35.302823057000055],
            [-2.72803194999986, 35.337323459000018],
            [-2.695808266999876, 35.354433917000094],
            [-2.669226683999909, 35.368332323000132],
            [-2.665330187999814, 35.370482114000097],
            [-2.634791936999846, 35.387844801000071],
            [-2.63058404899985, 35.400165761999958],
            [-2.757790080999939, 35.383733109000119],
            [-2.807524073999929, 35.374546365000114],
            [-2.821163974999877, 35.371064793000173],
            [-2.852111294999958, 35.358353886000074],
            [-2.894508236999854, 35.347399914000121],
            [-2.899640410999837, 35.346135105000101],
            [-2.931306610999883, 35.337603240000178],
            [-2.94424394699999, 35.330275878],
            [-2.946945987967126, 35.329164209225908],
            [-2.966181785223597, 35.316627061560993],
            [-2.965072859430364, 35.281318126697684],
            [-2.939236816286467, 35.264436135464919],
            [-2.914687194452199, 35.2736437154566],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MCO", Country: "Monaco" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.75694444400014, 42.966388888000139],
            [7.724690725000158, 42.948578832000109],
            [7.496666667000142, 43.529444444000021],
            [7.419583333000162, 43.725805555],
            [7.391609801687054, 43.727545211170494],
            [7.390900138642706, 43.749373208845739],
            [7.428991194815779, 43.768300049183054],
            [7.439291213244985, 43.757518020820029],
            [7.439483492750099, 43.750413997500019],
            [7.453500000000162, 43.74319444400011],
            [7.528333333000035, 43.552499999000119],
            [7.75694444400014, 42.966388888000139],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MDA", Country: "Moldova" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.214836072877034, 45.448644993892188],
            [28.211309135648975, 45.450109144742967],
            [28.174436137648826, 45.47207325152344],
            [28.06916328914653, 45.583318038416635],
            [28.137273293436039, 45.62040008108265],
            [28.15930026449044, 45.627000159299413],
            [28.166800223834713, 45.668191178551623],
            [28.164509114414301, 45.720818131252017],
            [28.120273285006164, 45.921927146359124],
            [28.107218135497902, 45.966100111493276],
            [28.093445159799472, 45.991754099699833],
            [28.091382205784129, 46.075827103355309],
            [28.108327228929028, 46.102291118686978],
            [28.138882283574134, 46.190536130840783],
            [28.19360018584652, 46.330273186001946],
            [28.248427220498655, 46.42703605582436],
            [28.246800125449653, 46.6078001802111],
            [28.228745170683254, 46.672009079113693],
            [28.203882266500585, 46.69860016409784],
            [28.174436137648826, 46.739718093153996],
            [28.115754098077417, 46.84047309583211],
            [28.119718235376382, 46.854409183366656],
            [28.115827188273357, 46.887773181411589],
            [28.106382292110737, 46.920273172874985],
            [28.079373285433093, 46.982273105008503],
            [28.03388218220195, 47.015545069756385],
            [27.998718086625871, 47.027773093057064],
            [27.945382192552842, 47.043309118274308],
            [27.861382279093306, 47.102482169735012],
            [27.798054150578793, 47.152491118790337],
            [27.785409211412997, 47.203318141597876],
            [27.742218102419514, 47.262773160282208],
            [27.719282197782292, 47.285645027179058],
            [27.679436138717477, 47.293873206273233],
            [27.636309235102516, 47.305954043353708],
            [27.576100180406428, 47.366100066137832],
            [27.472918113838233, 47.485982236639572],
            [27.433327199906813, 47.565264144426592],
            [27.361191199872763, 47.616982163544193],
            [27.327773222371178, 47.636653987437782],
            [27.299436186950715, 47.658600156945326],
            [27.28027331221935, 47.693045085226785],
            [27.238327083489168, 47.785254067041379],
            [27.21110014732821, 47.845818179156922],
            [27.200827119129741, 47.902200056852436],
            [27.158600264280238, 47.985818090802482],
            [27.000554115047663, 48.155545092694936],
            [26.954718180318849, 48.185545097710062],
            [26.817218087483553, 48.246800046287419],
            [26.704163312413414, 48.263882197368517],
            [26.634991153350512, 48.257164269592678],
            [26.637827086478865, 48.273609060754737],
            [26.651663261727236, 48.300827112098247],
            [26.702073201031652, 48.339991219518637],
            [26.808327067484385, 48.333054021155235],
            [26.981109105454749, 48.357773259516975],
            [27.239436176920407, 48.368327081472714],
            [27.454163270154623, 48.439709045494979],
            [27.519518142862552, 48.465500161637735],
            [27.547218153639989, 48.468318157493243],
            [27.763191132965716, 48.44958208927288],
            [27.866245124053052, 48.400553990529815],
            [27.880273244884762, 48.381100096119482],
            [27.890973247232324, 48.364154067146174],
            [27.912773236347988, 48.348045055026304],
            [27.932218245941073, 48.337491065432559],
            [27.955827217405187, 48.327353991703873],
            [28.014718134004198, 48.316664215278237],
            [28.074927188700343, 48.311100140296773],
            [28.093682200022016, 48.291173171269904],
            [28.08610915048186, 48.24260020987056],
            [28.213054080257024, 48.206518128256178],
            [28.2358272083342, 48.206800095479807],
            [28.260000114245457, 48.220545075621416],
            [28.290554163061955, 48.236382178801577],
            [28.34700913095341, 48.242044992602658],
            [28.37048214794757, 48.221791129350933],
            [28.367500202065713, 48.190964165766204],
            [28.388609187081641, 48.163882236530682],
            [28.436663308672877, 48.145827114126135],
            [28.501945091185092, 48.134164198739185],
            [28.557700170159336, 48.161800171776022],
            [28.642491167642874, 48.153600155876674],
            [28.737500208040018, 48.138600069550009],
            [28.807363203564449, 48.125409133209914],
            [28.828327181655226, 48.109718042783541],
            [28.879809166378635, 48.01045401304647],
            [28.882218125358037, 48.0055539524365],
            [28.90040920223251, 47.988591159656735],
            [28.961654092526061, 47.946645098564474],
            [28.982627123071978, 47.936918067540276],
            [29.016527227645639, 47.932336016337629],
            [29.043045054795954, 47.935673187277231],
            [29.061173267396299, 47.951718161656586],
            [29.073391232413115, 47.976991108543743],
            [29.103463321795829, 47.986509094902189],
            [29.144854165620217, 47.983518096564652],
            [29.175554059552468, 47.954700107538613],
            [29.257500239090433, 47.7663731595337],
            [29.195409111849955, 47.609153968870729],
            [29.160127166715, 47.581664176225487],
            [29.135373227273902, 47.549082209749017],
            [29.142909228801983, 47.511791122417335],
            [29.190100182001885, 47.439536099357937],
            [29.223045084886991, 47.448873204245345],
            [29.245618220753755, 47.457564232034159],
            [29.29943607426074, 47.422209029065343],
            [29.358891260583135, 47.378318031154706],
            [29.380973216922399, 47.356109172801027],
            [29.380273160367182, 47.329082228850453],
            [29.41395416199137, 47.291100137418866],
            [29.478536055586261, 47.297209036108356],
            [29.490127221881806, 47.327636015272489],
            [29.501873285748644, 47.344500069232524],
            [29.567009223145106, 47.337700166443412],
            [29.590545104413167, 47.310264185616731],
            [29.599227079746669, 47.279091217067915],
            [29.572218240707087, 47.142773140221649],
            [29.592491214698015, 47.023182157036999],
            [29.574236100083141, 46.947418134022556],
            [29.73749126687764, 46.890264115404264],
            [29.867009105658639, 46.861309166080034],
            [29.94430014379796, 46.818254011556448],
            [29.971663202066765, 46.741927059923157],
            [29.965027081666193, 46.570127045731525],
            [29.916154048132313, 46.558564210268642],
            [29.899436174564272, 46.535200158016025],
            [29.92860922100968, 46.505273243196839],
            [30.006945147201833, 46.456645128874371],
            [30.035273297804878, 46.443044988019992],
            [30.065827178983426, 46.43526423491862],
            [30.096518188098372, 46.429573090284649],
            [30.116554121866812, 46.426464242388207],
            [30.128709222609729, 46.405091059783402],
            [30.098473183200355, 46.381235995641404],
            [30.048609241070466, 46.378873137129617],
            [29.996382272790612, 46.378873137129617],
            [29.966663229170621, 46.379718032972065],
            [29.923327113251815, 46.382000089936966],
            [29.866382306937481, 46.346373145666334],
            [29.825273262698687, 46.368600108931005],
            [29.734436063161667, 46.440954038448112],
            [29.662209203297124, 46.379436065748351],
            [29.633609143754114, 46.369709034724153],
            [29.602082291252344, 46.376791072374871],
            [29.494436190678329, 46.442282234828994],
            [29.499436163574529, 46.48480916181289],
            [29.350827199153485, 46.469982077968282],
            [29.305409186118226, 46.428245061541773],
            [29.307354122936573, 46.404227053201637],
            [29.238327138437086, 46.368318141707377],
            [29.217909157520836, 46.367273253654602],
            [29.202427111760329, 46.383109183368177],
            [29.219854094339127, 46.406800129845649],
            [29.228327192644741, 46.484709081888738],
            [29.2231912652787, 46.519500182772433],
            [29.203300170797576, 46.54496423705244],
            [29.173882205140416, 46.539718171478214],
            [29.038882266391312, 46.496653964499316],
            [28.994436051212773, 46.478327100793038],
            [28.976382269912818, 46.468182148075428],
            [28.933954081748567, 46.429991179616181],
            [28.950000229594394, 46.289426998247322],
            [29.021663322649687, 46.217627112531815],
            [29.040136199109753, 46.195536103737041],
            [28.968254170742711, 46.006100062300888],
            [28.896663329692984, 45.990536041527022],
            [28.829436107448345, 45.978327128965546],
            [28.801109130311687, 45.972482092588862],
            [28.763363240912469, 45.961864232892779],
            [28.75048226735251, 45.926373243092044],
            [28.766727066304298, 45.862836069911779],
            [28.679963304083657, 45.779673173305568],
            [28.597009116867213, 45.76804512663584],
            [28.524436084400492, 45.711100152683414],
            [28.490691212674022, 45.665754056377594],
            [28.524791141820032, 45.647000050884301],
            [28.530554035545293, 45.531664224677556],
            [28.515827199262986, 45.514918020276596],
            [28.483045072938097, 45.506518012166723],
            [28.399573219380073, 45.511800119924757],
            [28.366382223816942, 45.517773231782243],
            [28.345554032558056, 45.523036061162699],
            [28.316382159579035, 45.527491210350931],
            [28.288882308649903, 45.525953969304297],
            [28.266527102266451, 45.52012703783852],
            [28.246936079919664, 45.511109115824937],
            [28.232354082924502, 45.495400088125749],
            [28.215409227417666, 45.463453972825974],
            [28.214836072877034, 45.448644993892188],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MDG", Country: "Madagascar" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [53.381666550000062, -12.718333659999814],
            [52.979999549000098, -13.523610661],
            [52.759166549000184, -14.025833660999879],
            [52.556111549000121, -15.284999661999976],
            [52.454722549000081, -16.021110662999945],
            [52.483611549000017, -17.372777663999983],
            [52.591944549000033, -17.810555665000038],
            [52.814444549000058, -18.68333366499995],
            [52.471335549000202, -19.303577665999953],
            [52.405262549000128, -19.443656666000038],
            [52.28678154900004, -19.680534665999971],
            [52.278253549000198, -19.698663666000016],
            [52.266191549000126, -19.724534666],
            [52.223546549000133, -19.825480666999965],
            [52.220868549000073, -19.831878666999984],
            [52.219407549000067, -19.835391666999953],
            [52.028553548000019, -20.44966166699993],
            [51.97876754800015, -20.704339666999971],
            [51.955775548000105, -20.822340666999935],
            [51.922041548000124, -20.99554066799999],
            [51.886581548000066, -21.17649666799997],
            [51.864519548000118, -21.289047668000023],
            [51.860695548000166, -21.307940667999958],
            [51.856108548000151, -21.352589667999936],
            [51.840922548, -21.500851667999939],
            [51.839932548000064, -21.510622667999982],
            [51.837920548000199, -21.530806667999968],
            [51.837813548000071, -21.544704667999966],
            [51.836475548000209, -21.737062668000021],
            [51.836071548000092, -21.806570668],
            [51.835708548000156, -21.881962668000028],
            [51.835495548000125, -21.971136669000046],
            [51.835076548000103, -22.119954669000037],
            [51.822101548000063, -22.167715669000032],
            [51.817290548000102, -22.187384669000039],
            [51.816792548000109, -22.188481668999941],
            [51.816304548000147, -22.190211168999966],
            [51.815700548000081, -22.192415669000013],
            [51.815368548000123, -22.19314866900001],
            [51.81495654800014, -22.194829669],
            [51.814459548000144, -22.195925669],
            [51.814111548000056, -22.197345668999986],
            [51.81335404800015, -22.199030168999926],
            [51.799676548000122, -22.229196669000032],
            [51.740334548000163, -22.471330669000011],
            [51.73851454800004, -22.475332669000025],
            [51.738062548000102, -22.477175669000019],
            [51.730788548000106, -22.493170668999966],
            [51.721750548000131, -22.529989669000031],
            [51.721259548000177, -22.531068669000035],
            [51.720906548000102, -22.532505668999917],
            [51.720158048000059, -22.534174168999954],
            [51.679593548000099, -22.623310668999977],
            [51.661277548000129, -22.697776668999936],
            [51.660848548000075, -22.698718668999987],
            [51.660300548000095, -22.700690668999897],
            [51.659692548000152, -22.702907669000012],
            [51.65733554800002, -22.708079669000028],
            [51.651154048000052, -22.733139169],
            [51.649586048000145, -22.739197669],
            [51.648978548000088, -22.741412668999942],
            [51.648491548000123, -22.742480669000031],
            [51.648134548000058, -22.743929669000011],
            [51.647129548000208, -22.746134669000028],
            [51.646630548000161, -22.748159668999975],
            [51.631676548, -22.780949669],
            [51.630500048000073, -22.785475168999895],
            [51.629862048000149, -22.787758668999928],
            [51.62842204800009, -22.793537168999933],
            [51.627471048000103, -22.797132668999978],
            [51.62672804800016, -22.799647668999967],
            [51.626112548000123, -22.801882168999924],
            [51.625165548000126, -22.805469668999933],
            [51.624556548000015, -22.807695669],
            [51.624071548000217, -22.808759669000011],
            [51.623579048000209, -22.810507668999961],
            [51.622969548000157, -22.812727668999969],
            [51.622216548000182, -22.814398669000028],
            [51.581697048000109, -22.90329366899995],
            [51.581372548000076, -22.904128668999945],
            [51.580891548000096, -22.906077669],
            [51.580467548000087, -22.907006668999955],
            [51.580021048000191, -22.908759668999949],
            [51.578584548000066, -22.914522669],
            [51.578258548000093, -22.915236669000024],
            [51.577839548000071, -22.916936669],
            [51.577157548000201, -22.918428669],
            [51.576259548000081, -22.922067668999986],
            [51.575716548000088, -22.923254668999974],
            [51.575310048000091, -22.924710669],
            [51.574540048000159, -22.927389668999979],
            [51.572978548000123, -22.933219668999982],
            [51.572308048000167, -22.935575668999959],
            [51.564052548000205, -22.968901668999933],
            [51.562838548000201, -22.971556668999952],
            [51.56244954800016, -22.973133668999964],
            [51.561227548000119, -22.975806668999979],
            [51.560842548000068, -22.977366669],
            [51.560360548000148, -22.978421668999985],
            [51.559950048000218, -22.97999016899999],
            [51.559251048000107, -22.982529168999932],
            [51.5577955480002, -22.988224669],
            [51.557314548000107, -22.989277668999989],
            [51.556952548000169, -22.990741668999974],
            [51.555455048000084, -22.994065668999937],
            [51.434451548000112, -23.258315669999973],
            [51.433919548000148, -23.260457669999923],
            [51.433879548000135, -23.26121767],
            [51.424571548000102, -23.298729669999943],
            [51.423086548000157, -23.326425669999921],
            [51.422575548000083, -23.328482670000042],
            [51.422417548000141, -23.331437670000014],
            [51.421697548000083, -23.334336669999985],
            [51.421627548000146, -23.335653669999971],
            [51.421034548000222, -23.338040669999941],
            [51.420936548000014, -23.33986667],
            [51.42021154800014, -23.342786669999967],
            [51.420147548000187, -23.343982669999988],
            [51.419555548000147, -23.346369669999959],
            [51.419457548000111, -23.348195669999953],
            [51.419122548000104, -23.349545669999927],
            [51.418834548000092, -23.354906669999934],
            [51.412236548000038, -23.381481669999985],
            [51.412093548000115, -23.38414167],
            [51.411457548000072, -23.386704669999958],
            [51.411246048000152, -23.389478169999933],
            [51.410749548000098, -23.397565669999935],
            [51.397313548000142, -23.45165767],
            [51.397206548000071, -23.453641670000025],
            [51.396605548000167, -23.456063669999963],
            [51.396514548000113, -23.457755669999983],
            [51.396278548000197, -23.45870367],
            [51.395921548000075, -23.465363669999931],
            [51.395285548000203, -23.46792167000001],
            [51.395153548000195, -23.47037967],
            [51.394454548000198, -23.473413669999942],
            [51.31831654800007, -23.779542670000012],
            [51.317736548000056, -23.790269669999915],
            [51.313996548000063, -23.805273669999949],
            [51.312823548000068, -23.826960669999949],
            [51.312137548000265, -23.830004669999923],
            [51.308692548000209, -23.844112669999987],
            [51.308484548000166, -23.847949669999934],
            [51.307787548000107, -23.85097367000003],
            [51.304714548000078, -23.863656169999913],
            [51.3024545480001, -23.872949669999969],
            [51.301761548000087, -23.876116669999902],
            [51.160232548000096, -24.442029670999958],
            [51.159719548000027, -24.443135671],
            [51.159273048000074, -24.444699671],
            [51.158650548000111, -24.446961671000011],
            [51.157910048000218, -24.448597170999975],
            [51.130515548000204, -24.507675670999973],
            [51.1278100480001, -24.518427170999956],
            [51.090724548000111, -24.665868671000013],
            [51.090276548000162, -24.666832671],
            [51.089771048000074, -24.668632170999956],
            [51.089147548000113, -24.670900670999984],
            [51.088414548000088, -24.672521670999984],
            [51.085263548000086, -24.679341671000017],
            [51.064796548000032, -24.760602670999987],
            [51.064351548000076, -24.761558670999946],
            [51.063899048000195, -24.763246170999949],
            [51.038964548000166, -24.862036671000013],
            [51.038612548000089, -24.862790670999956],
            [51.035860548000215, -24.873701671],
            [51.03512254800026, -24.875326171000026],
            [51.033599048000127, -24.878672670999975],
            [50.997576547000079, -24.955980671],
            [50.994773547000165, -24.967074670999978],
            [50.98862354700006, -24.980256670999978],
            [50.966517547000109, -25.067665670999972],
            [50.965782547000146, -25.069285670999989],
            [50.962853547000094, -25.075601670999973],
            [50.96245804700024, -25.077021170999913],
            [50.961802547000076, -25.079465671000023],
            [50.959889547000074, -25.083561671],
            [50.959445547000115, -25.085316670999987],
            [50.959014547000066, -25.086239670999973],
            [50.957974547000077, -25.090348670999958],
            [50.956660547000155, -25.093162671],
            [50.954717547000115, -25.100642670999932],
            [50.95409054700022, -25.10292967099997],
            [50.953434547000114, -25.104333670999964],
            [50.952515547000218, -25.107963670999936],
            [50.867621547000027, -25.289432672],
            [50.866892547000106, -25.292301671999965],
            [50.866160047000136, -25.293914671999943],
            [50.865561547000112, -25.295239671999965],
            [50.863111547000159, -25.304882671999977],
            [50.861617547000179, -25.308071672000054],
            [50.859230547000124, -25.317465671999983],
            [50.825005547000131, -25.390468671999955],
            [50.78804554700011, -25.535586671999965],
            [50.78720554700007, -25.537375671999925],
            [50.785736547000198, -25.543137671999986],
            [50.781010547000079, -25.553242671999953],
            [50.780778547000153, -25.553780671999974],
            [50.780248547000184, -25.555856671999962],
            [50.779913547000064, -25.556571671999976],
            [50.777966547000091, -25.564204671999946],
            [50.777542547000195, -25.565107671999968],
            [50.777090547000199, -25.566807671999939],
            [50.76660354700013, -25.607846671999923],
            [50.766118547000161, -25.608877671999963],
            [50.765764547000089, -25.610265671999969],
            [50.765033547000058, -25.611871171999937],
            [50.763517047000136, -25.615199672],
            [50.763064547000141, -25.616214671999955],
            [50.762651547000104, -25.617832671999977],
            [50.761920547000074, -25.619438171999946],
            [50.760766547000088, -25.62194267199996],
            [50.760296547000195, -25.623783671999959],
            [50.759540047000229, -25.625395171999983],
            [50.759115547000164, -25.626299671999945],
            [50.758702547000013, -25.627918671999964],
            [50.758167547, -25.629056671999976],
            [50.755684547000072, -25.638782671999934],
            [50.73745554700011, -25.677529672],
            [50.691363547000179, -25.857768672],
            [50.684034547000039, -25.873309671999948],
            [50.683748547000192, -25.874427672],
            [50.670012547000084, -25.903546671999948],
            [50.66864354700013, -25.908887672],
            [50.65492054700016, -25.937964672000035],
            [50.653540547000176, -25.943347672],
            [50.639830547000116, -25.972383672000021],
            [50.63843854700022, -25.977807672000012],
            [50.624740547000073, -26.006802672],
            [50.623337547000148, -26.012267672],
            [50.609652547000024, -26.041221672],
            [50.608237547000186, -26.046728672],
            [50.597437547000169, -26.069569672000014],
            [50.596312547000167, -26.073948671999958],
            [50.342826547000101, -26.607721672999944],
            [49.987118547000165, -27.093240673000011],
            [49.539187546000079, -27.515105674000054],
            [49.012411546000209, -27.859753673999961],
            [49.006714546000097, -27.862246674],
            [49.005155546, -27.86326067399996],
            [48.978072546000107, -27.875106674],
            [48.970622546000158, -27.879952674000037],
            [48.94359354600013, -27.89176667400001],
            [48.93608854600015, -27.896643674000032],
            [48.909112546000159, -27.908425674000014],
            [48.901553546000088, -27.913334673999927],
            [48.874631546000188, -27.925084673999933],
            [48.867018546000196, -27.930025674],
            [48.84014954500006, -27.94174267399994],
            [48.832482545000204, -27.946716674],
            [48.805667545000091, -27.958401673999958],
            [48.797945545000204, -27.963406674],
            [48.771183545000071, -27.975059673999965],
            [48.763407545000149, -27.980097674],
            [48.736700545000105, -27.991717673999986],
            [48.728869545000094, -27.996787673999989],
            [48.702215545000087, -28.008375674],
            [48.694329545000215, -28.013477673999986],
            [48.667729545000071, -28.025033673999914],
            [48.659789545000166, -28.030167673999983],
            [48.633243545000113, -28.041691673999935],
            [48.625248545000119, -28.046856673999983],
            [48.62316104500016, -28.047821173999935],
            [48.031861545000055, -28.302802673999949],
            [48.013883545000084, -28.307316673999964],
            [47.997802545000098, -28.314200673999949],
            [47.978562545000074, -28.319026673999929],
            [47.961243545000087, -28.326435674000024],
            [47.94206154500003, -28.331240673999957],
            [47.924683545000136, -28.338669674000016],
            [47.9055605450001, -28.343455673999969],
            [47.888122545000016, -28.350903674],
            [47.869057545000061, -28.355668674],
            [47.85156154500001, -28.363136674],
            [47.832555545000133, -28.367882673999929],
            [47.814999545000063, -28.375369674],
            [47.796051545000211, -28.380095673999961],
            [47.77843654400013, -28.387602674],
            [47.759547544000185, -28.392308674999981],
            [47.741872544000131, -28.399834675],
            [47.723042544000094, -28.404520675000015],
            [47.705308544000133, -28.412066675],
            [47.686536544, -28.416733674999961],
            [47.668743544000137, -28.424297675],
            [47.650029544000091, -28.428944675],
            [47.632177544000143, -28.436529675],
            [47.613522544000176, -28.441156674999945],
            [47.595610544000152, -28.448759675],
            [47.577014544000093, -28.453367674999981],
            [47.559042544000164, -28.460990675000019],
            [47.540506544000124, -28.465578675000017],
            [47.522474544000175, -28.473220674999936],
            [47.503996544000216, -28.477788674999985],
            [47.485905544000133, -28.485450675000038],
            [47.467486544000081, -28.489998675000024],
            [47.449335544000149, -28.497679674999972],
            [47.430976544000174, -28.502208674999977],
            [47.412765544000109, -28.509908675],
            [47.394464544000044, -28.514417674999947],
            [47.376193544000131, -28.52213767500001],
            [47.357952544000142, -28.526626675],
            [47.339621544000096, -28.534365674999947],
            [47.321439544000015, -28.538835674999959],
            [47.303049544000061, -28.546593674999968],
            [47.28492554400006, -28.551043675000017],
            [47.266475544000201, -28.558820675],
            [47.248411544000106, -28.563251674999989],
            [47.229900544, -28.571047674999946],
            [47.211896544000155, -28.575459674999962],
            [47.193325544000146, -28.583274674999984],
            [47.175380544000205, -28.587666675000023],
            [47.156749544000121, -28.595501675],
            [47.138863544000202, -28.599873675],
            [47.120173544000096, -28.607727675000049],
            [47.102346544000085, -28.612080674999973],
            [47.083595544000076, -28.619952675],
            [47.065828544000141, -28.624286674999951],
            [47.04701754400017, -28.632178675000034],
            [47.029309544000142, -28.636492674999928],
            [47.010438544000152, -28.644403675],
            [46.992790544000201, -28.648698675],
            [46.97385854400008, -28.656627675000038],
            [46.956270544000205, -28.660903674999986],
            [46.937278544000179, -28.668852675],
            [46.919749544000211, -28.67310867499998],
            [46.900696544, -28.68107567499996],
            [46.883227544000221, -28.685312674999977],
            [46.864114544000103, -28.693299674999921],
            [46.846705544000116, -28.697517674999958],
            [46.827531544000209, -28.705522674999983],
            [46.816375544000067, -28.708222675],
            [46.804022544000105, -28.71337667499995],
            [46.165679543000039, -28.86628067499997],
            [45.505844542000062, -28.916743674999978],
            [45.49912554200003, -28.916210674999931],
            [45.491984542000097, -28.916741674999983],
            [45.473224542000054, -28.915252674999977],
            [45.453204542000123, -28.916735675000027],
            [45.434503542000101, -28.915246674999921],
            [45.414424542000091, -28.916728674999973],
            [45.395782542000148, -28.915239674999967],
            [45.37564454200006, -28.916721675000019],
            [45.357060542000028, -28.915232675000013],
            [45.3368645420002, -28.916713674999983],
            [45.318339542000075, -28.915224674999976],
            [45.298084542000055, -28.916704674999949],
            [45.279618542000122, -28.915216675000025],
            [45.259304542000024, -28.916695675],
            [45.240897542, -28.915207675],
            [45.220523542000166, -28.916686674999966],
            [45.202176542000217, -28.915198674999957],
            [45.181743542000135, -28.916676675000033],
            [45.163455542000094, -28.91518867500001],
            [45.142963542000103, -28.916665674999919],
            [45.128059542000159, -28.915449674999934],
            [45.111267542000093, -28.91665667499997],
            [44.451982541000035, -28.86135567499997],
            [43.815266541000113, -28.703792674999931],
            [43.810335541000057, -28.701689675000011],
            [43.808692541000113, -28.701279675],
            [43.781733541000079, -28.689780675],
            [43.772701541000089, -28.687521674999957],
            [43.745804541000126, -28.676039675000013],
            [43.736711541000062, -28.673762675],
            [43.709876541, -28.662298674999946],
            [43.700722541000033, -28.660004675000039],
            [43.673948541000215, -28.648557674999978],
            [43.664734541000172, -28.646245674999989],
            [43.63802254100014, -28.634815675],
            [43.628746541000197, -28.632485674999955],
            [43.602096541000179, -28.621073674999934],
            [43.59276054100016, -28.618725675],
            [43.566171541000102, -28.607331674999969],
            [43.55677454100001, -28.60496567499996],
            [43.530246541000025, -28.59358967499999],
            [43.520789541000028, -28.591205675000012],
            [43.494323541000114, -28.579846674999942],
            [43.484805540000053, -28.577444674999967],
            [43.458400540000213, -28.566103674999979],
            [43.448821540000182, -28.563683674999936],
            [43.422478540000128, -28.55235967499992],
            [43.412839540000192, -28.549922675],
            [43.386557540000041, -28.53861567499996],
            [43.376857540000202, -28.536160674999962],
            [43.350636540000124, -28.524871674999915],
            [43.340876540000096, -28.522398675000019],
            [43.314717540000203, -28.511127674999955],
            [43.304897540000098, -28.508636675],
            [43.278798540000167, -28.497382675],
            [43.268917540000103, -28.49487367499998],
            [43.242880540000129, -28.483637674999954],
            [43.23293954000016, -28.481110674999954],
            [43.206963540000203, -28.469892675000011],
            [43.196962540000158, -28.467347674999928],
            [43.171046540000162, -28.456146674999971],
            [43.160985540000212, -28.453583675],
            [43.135130540000063, -28.442400674999931],
            [43.125009540000093, -28.439819674999981],
            [43.099215540000188, -28.42865467499999],
            [43.089034540000142, -28.426055674999972],
            [43.06330154000014, -28.414907674999952],
            [43.053060540000189, -28.412290674999966],
            [43.027388540000089, -28.401161675000012],
            [43.017087540000176, -28.398525675000045],
            [42.991475540000039, -28.387413673999987],
            [42.981114540000107, -28.384760673999935],
            [42.955563540000156, -28.373666673999949],
            [42.945143540000146, -28.37099467400003],
            [42.919652540000158, -28.359918674000014],
            [42.909172540000014, -28.357228673999927],
            [42.887262540000194, -28.347701674],
            [42.87818654000003, -28.345369673999969],
            [42.288838539000182, -28.087366674],
            [41.762453539000063, -27.741127673999969],
            [41.315412538000174, -27.317920673999922],
            [41.312448538000154, -27.313958172999989],
            [41.292410538000098, -27.286646673],
            [41.289313538000073, -27.283698672999989],
            [41.269529538000057, -27.256630673],
            [41.266391538000136, -27.253641672999962],
            [41.246649538000185, -27.226615672999984],
            [41.243468538000087, -27.223585673000017],
            [41.223768538000087, -27.196600672999963],
            [41.220546538000036, -27.193528672999989],
            [41.200887538000217, -27.166585672999958],
            [41.197623538000158, -27.163472672999958],
            [41.178005538000122, -27.136571672999935],
            [41.17470053800011, -27.133417673],
            [41.155124538000081, -27.106556673],
            [41.151777538000175, -27.103361672999966],
            [41.137361538000135, -27.08357167299998],
            [41.134865538000014, -27.081187672999988],
            [40.781815538000188, -26.594303672999956],
            [40.778565538000208, -26.58739667299993],
            [40.777234538000044, -26.585552672999938],
            [40.764855538000148, -26.559238673],
            [40.759762538000103, -26.552180673],
            [40.747409538000198, -26.52590667299998],
            [40.742289538000165, -26.518808673000038],
            [40.729962538000024, -26.492575673000033],
            [40.724816538000113, -26.485437673],
            [40.712514538000192, -26.459244673000015],
            [40.707342538000177, -26.452066672999933],
            [40.69506553800008, -26.42591467299998],
            [40.689867538000072, -26.418695672999974],
            [40.677615538000197, -26.392583672999962],
            [40.672391538000142, -26.385325673000011],
            [40.660165538000086, -26.359253673000012],
            [40.654914538000213, -26.351955672999964],
            [40.642714538000206, -26.325923672999977],
            [40.637437538000114, -26.318585672999987],
            [40.625261538000046, -26.292593672999956],
            [40.619958538000134, -26.285216673000022],
            [40.607808538000057, -26.259264673],
            [40.60247953800004, -26.251847672999958],
            [40.590355538000182, -26.225935671999963],
            [40.584999538000119, -26.218478671999989],
            [40.572900538000027, -26.192606672000011],
            [40.567519538000141, -26.185110672],
            [40.55544453800016, -26.159278671999971],
            [40.550037538000055, -26.151741671999943],
            [40.537988538000121, -26.125949671999933],
            [40.532555538000082, -26.118374671999959],
            [40.520531538000142, -26.092621671999979],
            [40.515072538000112, -26.085006672],
            [40.503072538000112, -26.059294672000021],
            [40.497588538000144, -26.051639672],
            [40.485613538000194, -26.025966671999981],
            [40.480104538, -26.018272672000023],
            [40.468154538000164, -25.992639671999925],
            [40.462619538000041, -25.984906672000022],
            [40.450693538000138, -25.959312671999967],
            [40.445132538000195, -25.951539671999953],
            [40.434031538000141, -25.927702671999967],
            [40.428821538000108, -25.920417671999914],
            [40.314679538000092, -25.699166005333311],
            [40.443733538000146, -25.523624672000011],
            [40.793901538000085, -25.044497671],
            [40.867218538000117, -24.943833671],
            [41.030644538000132, -24.719175670999959],
            [41.27497853800017, -24.381980670999951],
            [41.289598538000206, -24.361711670999952],
            [41.316463538000022, -24.323728670999955],
            [41.342792539000044, -24.285746671000041],
            [41.368587539000117, -24.247766671000022],
            [41.393847539000177, -24.209788671],
            [41.418574539000105, -24.171812670999955],
            [41.44276753900013, -24.13383967099999],
            [41.466426539000139, -24.095868671],
            [41.489553539000013, -24.05790067],
            [41.512148539000094, -24.019936669999979],
            [41.518733539000124, -24.00860867],
            [41.534272539000114, -23.981974669999943],
            [41.555890539000103, -23.944016669999982],
            [41.576976539000185, -23.906061669999985],
            [41.597530539000076, -23.868111669999962],
            [41.617553539000056, -23.830165669999928],
            [41.634930539000067, -23.796344669999939],
            [41.64031953900016, -23.77521767],
            [41.651259539000108, -23.730254669999965],
            [41.661610539, -23.685519669999977],
            [41.671370539000094, -23.64101467],
            [41.680542539000129, -23.596739669999963],
            [41.689125539000116, -23.552696669999932],
            [41.693233539000204, -23.530184669999969],
            [41.702524539000109, -23.479144670000011],
            [41.7657315390002, -23.140533670000011],
            [41.772487539000196, -23.10137067],
            [41.778756539000113, -23.06177467000002],
            [41.780987539000222, -23.04628867],
            [41.784487539000082, -23.022285670000031],
            [41.789666539000137, -22.982908668999983],
            [41.794263539000013, -22.94364966900001],
            [41.798278539000222, -22.904508668999938],
            [41.801712539000192, -22.865486668999949],
            [41.804565539000095, -22.826583668999959],
            [41.806838539000154, -22.787800669000021],
            [41.80814453900021, -22.757971669000028],
            [41.811927539000209, -22.651936669],
            [41.81272553900007, -22.624160668999963],
            [41.813239539000136, -22.586120668999953],
            [41.813173539000019, -22.548167668999952],
            [41.812527539000115, -22.510301668999958],
            [41.811302539000195, -22.472524668999952],
            [41.809498539000145, -22.43483466899994],
            [41.807115539000137, -22.397232668999948],
            [41.804153539000112, -22.359718669000031],
            [41.801749539000156, -22.334294669],
            [41.800676539000193, -22.322283668999987],
            [41.797457539000135, -22.291354668999929],
            [41.773836539000143, -22.066310669],
            [41.771642539000169, -22.044996668999957],
            [41.75973853900021, -21.927571667999956],
            [41.758073539000208, -21.910963668],
            [41.756185539000143, -21.891918668],
            [41.742048539000081, -21.747577667999977],
            [41.740207539000181, -21.731232668000018],
            [41.737587539000202, -21.707822668],
            [41.732540539000212, -21.667931667999966],
            [41.726893539000088, -21.627901667999978],
            [41.720649539000107, -21.587732667999944],
            [41.713807539000214, -21.547424667999962],
            [41.706368539000124, -21.506976667999936],
            [41.698333539000117, -21.466389668000033],
            [41.689700539000086, -21.425661668000032],
            [41.68453253900006, -21.40277866800001],
            [41.644602539000147, -21.227029668],
            [41.58292353900012, -20.953054668],
            [41.573705539000088, -20.913837667999957],
            [41.574073539000068, -20.909467667999948],
            [41.577035539000093, -20.868911666999963],
            [41.579560539000028, -20.828142666999938],
            [41.581647539000215, -20.787160666999952],
            [41.58329853900014, -20.745963666999955],
            [41.584512539000144, -20.704551667],
            [41.585289539000115, -20.662925666999953],
            [41.585631539000161, -20.621084666999948],
            [41.585536539000117, -20.579027666999934],
            [41.585006539000148, -20.53675566699998],
            [41.584039539000088, -20.494266667],
            [41.582638539000158, -20.451560667000024],
            [41.580801539000134, -20.408637666999951],
            [41.578529539000073, -20.365497666999943],
            [41.575822539000086, -20.322140666999914],
            [41.572680539000118, -20.278564666999984],
            [41.569104539000165, -20.234769666999952],
            [41.565093539000117, -20.190755667000019],
            [41.560648539000141, -20.146522667],
            [41.556723539000103, -20.110758667000013],
            [41.5558015390001, -20.102084666999971],
            [41.550651539000143, -20.057489667000013],
            [41.545070539000136, -20.01267466699997],
            [41.539056539000086, -19.967639667000014],
            [41.532611539000158, -19.922383667],
            [41.525961539000178, -19.878403666999844],
            [41.525735539000124, -19.876907666999969],
            [41.518446539000109, -19.831218667],
            [41.51217153900015, -19.793899665999959],
            [41.4800405390001, -19.608526665999904],
            [41.459020539000136, -19.582810665999972],
            [41.153012538000127, -19.205372666000031],
            [41.550914539000047, -18.939688666],
            [41.846212539000106, -18.722137664999934],
            [41.899144539000218, -18.683070664999946],
            [42.720393540000117, -18.076109664999962],
            [42.880722540000164, -17.956976664999942],
            [42.969903540000047, -17.892219665000042],
            [43.007495540000178, -17.812131665000052],
            [43.17609054000016, -17.453527664000035],
            [43.360153540000198, -17.061910664000024],
            [43.458518540000028, -16.852261663999982],
            [43.293894540000082, -16.599745663999954],
            [43.119425540000208, -16.330448662999956],
            [43.109759540000056, -16.315462663],
            [43.084042540000127, -16.275046662999955],
            [43.053172540000133, -16.226114662999976],
            [42.935664540000204, -16.038954663000027],
            [42.912264540000052, -16.001464662999965],
            [42.883194540000062, -15.954377662999974],
            [42.695813540000046, -15.645071662999939],
            [42.523264540000099, -15.358838662000011],
            [42.572943540000068, -15.24873166199994],
            [42.841014540000145, -14.717577661999925],
            [43.023561540000145, -14.427294661000019],
            [43.680286541000129, -14.375924660999928],
            [44.136997541000056, -14.458816661999933],
            [44.628039542000039, -14.485496661999974],
            [45.096279542000133, -14.449377662000018],
            [45.57718554200008, -14.33751366099996],
            [45.805610543000142, -14.236835660999958],
            [46.332014543000156, -13.800468660999869],
            [46.536994543000077, -13.268637659999882],
            [46.586174543000055, -13.118860659999854],
            [46.692948543000142, -12.797081659999961],
            [46.948779544000075, -12.73218866],
            [46.979201544000119, -12.724250659999825],
            [47.021603544000158, -12.712486659999911],
            [47.063425544000012, -12.700174659999959],
            [47.104670544000072, -12.687313659999958],
            [47.145337544000114, -12.67390466],
            [47.15524154400012, -12.67045666],
            [47.185511544000207, -12.660028659999867],
            [47.202124544000213, -12.653991659999875],
            [47.225214544000124, -12.645705659999877],
            [47.264393544000058, -12.630886659999874],
            [47.30299154400015, -12.615515659999858],
            [47.308974544000108, -12.613010659999986],
            [47.341073544000068, -12.599654659999885],
            [47.378586544, -12.58325366],
            [47.41551854400015, -12.566300659999911],
            [47.451869544000118, -12.548794659999899],
            [47.487639544000075, -12.530737659999858],
            [47.522829544000075, -12.512128659999888],
            [47.557439544000061, -12.49296766],
            [47.591469544000091, -12.473254659999981],
            [47.624919544000107, -12.452989659999858],
            [47.638247544000109, -12.44455066],
            [47.657973544000043, -12.43234766],
            [47.690568544000172, -12.411269660000016],
            [47.720482544000021, -12.391056659999848],
            [47.728363544000075, -12.378717659999964],
            [47.751740544000114, -12.340351659999897],
            [47.770655544000107, -12.30772966],
            [47.888620545, -12.096557658999885],
            [47.944711545000104, -11.996125658999901],
            [48.23154254500011, -11.533227658999934],
            [48.246059545, -11.509398658999984],
            [48.267884545000044, -11.471737658999984],
            [48.289165545000202, -11.433126658999896],
            [48.309905545000021, -11.393563658999909],
            [48.33010154500019, -11.353048659000024],
            [48.349755545000136, -11.311580658999887],
            [48.368867545000143, -11.269159658999953],
            [48.387437545000097, -11.225783658999958],
            [48.405465545000112, -11.181451658],
            [48.485277545000116, -11.020832657999961],
            [49.433333546000114, -10.283333657999989],
            [50.199999547000203, -11.083333657999987],
            [50.483333547000058, -11.516666658999867],
            [53.381666550000062, -12.718333659999814],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MDV", Country: "Maldives" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [77.023333, 4.784],
            [77.037700572000205, 4.68222478400007],
            [77.058808572, 4.539141784000122],
            [77.058891572000022, 4.534102784000012],
            [77.059308572000162, 4.465592784000094],
            [77.051303572000137, 4.246823784000142],
            [77.029286572000188, 4.03069378400005],
            [77.052526572000176, 3.886066783000075],
            [77.073149572000062, 3.668062783000096],
            [77.07958057200014, 3.451374783000105],
            [77.079578572000145, 3.450565783000044],
            [77.079573572000101, 3.448888783000115],
            [77.079568572000227, 3.446585782999989],
            [77.079564572000066, 3.444917783000093],
            [77.079560572000076, 3.443298783000159],
            [77.071764572000035, 3.224403783000085],
            [77.04978457200005, 3.006459783],
            [77.01371657200005, 2.790392781999969],
            [76.96371057200011, 2.577122782000174],
            [76.900807572000218, 2.370272781999986],
            [76.907111572000161, 2.164306782000125],
            [76.907103572000125, 2.159301782000071],
            [76.907100572000076, 2.157860782000043],
            [76.899535572000133, 1.938803782000065],
            [76.877778572000096, 1.720689781000033],
            [76.846482572000156, 1.531967781],
            [76.845047572000084, 1.517560781000071],
            [76.809215572000227, 1.301297781000073],
            [76.773134572000146, 1.146585781000013],
            [76.783192572000218, 1.097755781000131],
            [76.818380572000052, 0.881354781],
            [76.819246572000026, 0.872382781000169],
            [76.820772572000152, 0.86300178099998],
            [76.841849572000143, 0.644738780000154],
            [76.848725572000177, 0.42824578000004],
            [76.848724572000179, 0.42490478],
            [76.84872157200013, 0.422203780000174],
            [76.841359572000016, 0.203012780000122],
            [76.840606572000155, 0.19539178],
            [76.840555572000113, 0.193874780000058],
            [76.818987572000168, -0.024375219999939],
            [76.78330157200017, -0.240752219999862],
            [76.733642571000161, -0.454331221],
            [76.731383571000123, -0.461807220999958],
            [76.729736571000018, -0.511184220999894],
            [76.708206571000034, -0.729474221],
            [76.67254657100014, -0.945890220999956],
            [76.622903571000194, -1.159501221],
            [76.559482571000075, -1.369395220999976],
            [76.482547571000197, -1.574670221999952],
            [76.392421571000028, -1.774440222],
            [76.289485571000085, -1.967848221999986],
            [76.274470571000194, -1.992094221999977],
            [76.204826571000154, -2.146390221999894],
            [76.101843571000046, -2.339781221999985],
            [75.986476571000054, -2.525968222999978],
            [75.85921757100013, -2.704145222999983],
            [75.720608571000156, -2.873546222999934],
            [75.571242570000123, -3.033434222999887],
            [75.5575855700001, -3.046849222999981],
            [75.54288657000015, -3.062583222999933],
            [75.510064570000139, -3.095027222999931],
            [75.507059570000109, -3.097956222999983],
            [75.380391570000057, -3.215191222999891],
            [75.211472570000097, -3.354019222999938],
            [75.053238570000218, -3.467495222999872],
            [75.036902570000194, -3.46451722300003],
            [74.590096569000053, -3.374554223000018],
            [74.262882569000141, -3.309776222999972],
            [73.159198568000221, -3.091170223],
            [72.599959568000116, -2.981813223],
            [72.466097567000162, -2.946586222999954],
            [71.942900567000123, -2.809228222999849],
            [71.908289567000139, -2.800104222999877],
            [71.835560567000101, -2.778922222999824],
            [70.889368566000059, -2.500734221999949],
            [70.27231956500006, -2.305772221999902],
            [70.207211565000165, -2.283790221999922],
            [70.205142565000102, -2.280107221999927],
            [70.13787956500019, -2.1562832219999],
            [70.093284565000062, -2.068134221999969],
            [70.00408856500016, -1.869032221999944],
            [69.927967565000046, -1.664499221999989],
            [69.865239565000167, -1.455401221999963],
            [69.816167565000143, -1.242618220999958],
            [69.78095756500008, -1.027046221000035],
            [69.7597635650001, -0.809596220999964],
            [69.752676565000087, -0.594963220999929],
            [69.752666565000169, -0.586658220999951],
            [69.752664565000174, -0.582881221],
            [69.754957565000183, -0.511846220999956],
            [69.746908565000155, -0.477135220999969],
            [69.744443565000125, -0.46895922099992],
            [69.695014065000208, -0.255858719999935],
            [69.693351565000143, -0.24862022],
            [69.687589565000138, -0.213641219999872],
            [69.676710565000093, -0.16387522],
            [69.676096565000108, -0.160150219999977],
            [69.675806565000102, -0.158902220000016],
            [69.640244065000132, 0.056838780000135],
            [69.6394465650001, 0.061710780000013],
            [69.617909565000133, 0.279266780000142],
            [69.610490565000219, 0.49780278],
            [69.617227565000036, 0.716393780999965],
            [69.638102565000082, 0.934114781000162],
            [69.643714565000067, 0.968802781000022],
            [69.643891565000075, 0.970644781000018],
            [69.678820565000166, 1.186578781000051],
            [69.679178565000115, 1.188138781],
            [69.679608565000166, 1.190798781000169],
            [69.726026565000069, 1.393379781000093],
            [69.709677565000021, 1.44715378100004],
            [69.659720565000072, 1.660149781000086],
            [69.623648565000082, 1.875984782000018],
            [69.613487565000099, 1.959741782],
            [69.611258565000156, 1.984590782000055],
            [69.59706756500006, 2.044958782],
            [69.560879565000192, 2.260810782],
            [69.538740565000154, 2.478597782000051],
            [69.537381565000175, 2.515871782],
            [69.529712565000153, 2.591145782000126],
            [69.497302565000126, 2.69708578200003],
            [69.446955565000138, 2.910110783000164],
            [69.410505565000136, 3.125994783000152],
            [69.408033065000183, 3.149970783000015],
            [69.385617565000217, 3.367942783000103],
            [69.384873565000049, 3.387732783000089],
            [69.382124565000055, 3.414467782999964],
            [69.380499565000065, 3.45766678300015],
            [69.376664565000198, 3.496854783000103],
            [69.368416565000217, 3.715722783000075],
            [69.368406565000015, 3.717949783000037],
            [69.368401565000141, 3.718758783000098],
            [69.370659565000011, 3.800469783000082],
            [69.370496565000195, 3.80477378300013],
            [69.370488565000159, 3.806402783000081],
            [69.370480565000122, 3.808060783000045],
            [69.374408565000209, 3.951068784],
            [69.373015565000088, 3.987831784000036],
            [69.373003565000062, 3.989978784000044],
            [69.372995565000025, 3.991656784000156],
            [69.378939565000138, 4.208504784000056],
            [69.381890565000134, 4.240438784000133],
            [69.382715565000098, 4.270949784000067],
            [69.402868565000091, 4.48911978400011],
            [69.412349565000028, 4.548907784],
            [69.41489956500007, 4.576528784000104],
            [69.449211565000184, 4.792944784000142],
            [69.454312565000095, 4.815492784000156],
            [69.458934565000078, 4.844643784],
            [69.490959565000111, 4.98620778400003],
            [69.474176565000079, 5.08451178500016],
            [69.451407565000153, 5.302501785000047],
            [69.450694565000134, 5.320794785000075],
            [69.413617565000067, 5.440590785000055],
            [69.362550565000078, 5.653732785000102],
            [69.325443565000086, 5.869766785000067],
            [69.302469565000166, 6.087773786000028],
            [69.29374956500007, 6.306828786000111],
            [69.293738565000041, 6.308511786000096],
            [69.29372856500018, 6.310119786],
            [69.299330565000076, 6.527608786],
            [69.300032565000066, 6.535318786000133],
            [69.302121565000078, 6.616802786000164],
            [69.322018565000036, 6.835158786000051],
            [69.356168565000218, 7.051760786000116],
            [69.404449565000078, 7.265679787000053],
            [69.466680565000075, 7.475995787000045],
            [69.489988565000061, 7.539161787000026],
            [69.518227565000217, 7.717845787000144],
            [69.571108565000173, 7.888616787000032],
            [69.595833333, 7.95750000000011],
            [70.252222222000142, 8.093888889000041],
            [72.0625, 7.818055556000033],
            [72.707222222000127, 7.715],
            [72.825000000000188, 7.705277778000053],
            [73.056388889000146, 7.68111111100005],
            [73.327222222000188, 7.650555556000072],
            [73.642777778000124, 7.697222222000022],
            [74.495833333000206, 7.808333333000036],
            [74.93583333300009, 7.858333333000189],
            [75.188333333000202, 7.421944444000061],
            [75.201666667000126, 7.4],
            [75.271944444000184, 7.2575],
            [75.429444444000097, 6.851666667000075],
            [75.641944444000131, 6.555833333000024],
            [75.692777778000135, 6.470555555999979],
            [76.600193843000142, 5.249897493000063],
            [76.613333333000156, 5.232222222000175],
            [76.634195322000124, 5.205214841000142],
            [76.720833333, 5.093055556000081],
            [76.925991384000071, 4.891178015],
            [76.94666666700013, 4.870833333000135],
            [77.023333, 4.784],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MEX", Country: "Mexico" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.445052779999969, 25.996744440000171],
            [-93.440259752999964, 25.920332952000095],
            [-93.440516753, 25.918366952000142],
            [-93.440385752999987, 25.916336952000037],
            [-93.440769752999898, 25.913386952000209],
            [-93.440599252999903, 25.910145452000066],
            [-93.440598752999847, 25.909542952000109],
            [-93.440894752999924, 25.907268952000081],
            [-93.440092086333294, 25.894631618666793],
            [-93.442602752999846, 25.875262952000085],
            [-93.441317753000021, 25.855363952000133],
            [-93.442608252999861, 25.845421952000166],
            [-93.44345775299999, 25.838859952000078],
            [-93.443021752999869, 25.832109952000067],
            [-93.517551753, 25.255628951000105],
            [-93.524049752999929, 25.204992951],
            [-93.525150752999906, 25.201643951000094],
            [-93.525865752999891, 25.199462951000029],
            [-93.52632875300003, 25.195848951000144],
            [-93.527040752999966, 25.193691951000218],
            [-93.527580752999967, 25.18984495100004],
            [-93.530050752999955, 25.170941951000103],
            [-93.530890752999824, 25.168393951000112],
            [-93.531201752999891, 25.165971951000088],
            [-93.532170752999946, 25.163033951000202],
            [-93.532328752999888, 25.161799951000049],
            [-93.532990753000036, 25.15979095100009],
            [-93.533511252999944, 25.155911451000165],
            [-93.536072752999956, 25.136092951000066],
            [-93.536913752999908, 25.133541951000197],
            [-93.537223752999893, 25.131122951000052],
            [-93.53801475299997, 25.128722951000086],
            [-93.538343752999822, 25.126149950999988],
            [-93.540282753000042, 25.12026795100023],
            [-93.540597752999901, 25.117805951000079],
            [-93.541310752999919, 25.115641951000086],
            [-93.541772752999861, 25.112034951000069],
            [-93.542692752999955, 25.109243951000067],
            [-93.542868752999965, 25.107861951000118],
            [-93.545810752999927, 25.098936951000084],
            [-93.54624375299997, 25.095545951],
            [-93.548411252999983, 25.088941951000081],
            [-93.552791252999924, 25.075614451000135],
            [-93.664601752999886, 24.734235950000013],
            [-93.270690752999968, 24.995760951000094],
            [-92.694393752999957, 25.2499459510001],
            [-92.693226752999948, 25.250244951000141],
            [-92.691343752999927, 25.251070951000102],
            [-92.073249752, 25.408471951000152],
            [-91.646309751999979, 25.445761951000193],
            [-91.623497251999936, 25.460420951000103],
            [-91.622563751999877, 25.461008950999982],
            [-91.090247219999867, 25.703625000000144],
            [-90.494722219999915, 25.781111109999969],
            [-88.38487222, 25.69903333],
            [-87.911948747999929, 25.448059951000076],
            [-87.910009748, 25.446785951000194],
            [-87.906872747999955, 25.445400951000167],
            [-87.388734747999877, 25.103001951],
            [-87.209824746999971, 24.932916951000109],
            [-87.09670374699985, 24.941632951000074],
            [-87.092286746999861, 24.941266950999974],
            [-87.087477746999866, 24.941630951000079],
            [-86.937969443999862, 24.941341667000117],
            [-86.404083332999846, 23.508750000000148],
            [-86.376055555999926, 23.44841666700016],
            [-86.11527777799995, 22.759111111000053],
            [-86.009777777999972, 22.315500000000114],
            [-85.878722221999965, 21.692083332999957],
            [-85.855055555999854, 21.600027778000012],
            [-85.53975, 20.82677777800005],
            [-85.1234027779999, 20.296305556000064],
            [-84.965638888999933, 20.076972222000123],
            [-84.712916666999888, 19.654611111],
            [-84.64185, 19.5405],
            [-84.668826472999967, 19.516972519000134],
            [-84.741339354999866, 19.453730542000173],
            [-84.759585149, 19.438912172000144],
            [-84.763384475999942, 19.435826538000086],
            [-84.788831129999892, 19.415058627000107],
            [-84.8757155589999, 19.343889535000173],
            [-84.882878254999866, 19.338022402000107],
            [-84.884404820999976, 19.336765923000158],
            [-84.964087624999905, 19.271180997999963],
            [-84.984189785999888, 19.254608631000096],
            [-85.012060177999899, 19.231370692000112],
            [-85.04775409399997, 19.201640816000165],
            [-85.173798986999884, 19.094988681000117],
            [-85.174447814000018, 19.094406232000082],
            [-85.175726059999988, 19.093258756000168],
            [-85.183443322000016, 19.075899958000079],
            [-85.185345622499881, 19.071621024000152],
            [-85.202097316999868, 19.033940654000062],
            [-85.231726325999944, 18.967712920999972],
            [-85.278321416999916, 18.863725151000111],
            [-85.329981147999888, 18.748434143000125],
            [-85.332781455999879, 18.742165182000107],
            [-85.390661733999849, 18.612587242000259],
            [-85.417371310000021, 18.552791886000094],
            [-85.51761038199993, 18.295657605000045],
            [-85.522932500999843, 18.281906141000078],
            [-85.549591622999941, 18.213023437000246],
            [-85.902248305999905, 17.989591910999977],
            [-86.112897184999895, 17.856131799000067],
            [-86.99407079, 18.041068582000108],
            [-87.156886992999944, 18.074988624],
            [-87.346499973999897, 18.122008109000106],
            [-87.403133271999906, 18.175260760000086],
            [-87.412387148999954, 18.183962240000099],
            [-87.41875506, 18.189845620000071],
            [-87.419696227999879, 18.190715176000069],
            [-87.424818932999983, 18.191340470000043],
            [-87.428417442999915, 18.191779715000237],
            [-87.436654979999901, 18.192761471000111],
            [-87.450748534999832, 18.194441153],
            [-87.463906968999936, 18.194113993000101],
            [-87.477143655999924, 18.193784887999968],
            [-87.540263693000014, 18.195640374000092],
            [-87.593904887999969, 18.197217218000091],
            [-87.629533664999883, 18.198665139999989],
            [-87.645894996999857, 18.19886708],
            [-87.655699785999843, 18.198604302000106],
            [-87.665509120999872, 18.198341403000128],
            [-87.666941990999931, 18.198303001000127],
            [-87.668889922999909, 18.198215505],
            [-87.67687824399988, 18.197856692000059],
            [-87.702124917999981, 18.196133815999971],
            [-87.731061686999965, 18.193059759000093],
            [-87.753055194000012, 18.19072330900012],
            [-87.767929840999898, 18.188221360999975],
            [-87.792274708999912, 18.18412650000009],
            [-87.793056839999963, 18.183994943000229],
            [-87.802939262999985, 18.183996752000112],
            [-87.810765714999889, 18.183998185000135],
            [-87.818606373, 18.183530064000038],
            [-87.826299073999934, 18.183063279000052],
            [-87.830198752, 18.183049868000111],
            [-87.837311908999851, 18.183050145000024],
            [-87.851854150999969, 18.184383363000038],
            [-87.866544204999855, 18.185730133000092],
            [-87.867938097999911, 18.185857924000103],
            [-87.894904351999912, 18.189551898000204],
            [-87.896711140999912, 18.189953407000118],
            [-87.913472581999883, 18.19367817200002],
            [-87.914126487999908, 18.193823489000053],
            [-87.946163128999927, 18.202366592000189],
            [-87.961113622499937, 18.202366768000203],
            [-87.961287414999987, 18.204104687000026],
            [-87.963249387999866, 18.223724416000223],
            [-87.966647924999961, 18.24411564200004],
            [-87.968482418999855, 18.255122604000107],
            [-87.969656734999944, 18.262168499000083],
            [-87.96986155299993, 18.264626321000051],
            [-87.970979004999833, 18.278035739000117],
            [-87.971792517000011, 18.287797887000053],
            [-87.984607211999901, 18.324106187999959],
            [-87.989897820999857, 18.332923871000062],
            [-87.997421905999886, 18.345464012000079],
            [-88.005279102000031, 18.359868871000188],
            [-88.010236599999956, 18.368957618000067],
            [-88.035865988999831, 18.394587006000151],
            [-88.048667686999977, 18.401787962000171],
            [-88.07003850699985, 18.413809048000118],
            [-88.100387363999971, 18.425189869000135],
            [-88.10421102499987, 18.426623742000103],
            [-88.153334019999932, 18.44584578400007],
            [-88.167367811, 18.449354232000118],
            [-88.221679055999886, 18.462932043000094],
            [-88.260123137999926, 18.471475172000041],
            [-88.299499647608712, 18.482926831387218],
            [-88.343899919841135, 18.481391090606266],
            [-88.371399938408103, 18.482082094705888],
            [-88.405446055735524, 18.489900063457569],
            [-88.473790920952524, 18.483709022116827],
            [-88.506390992339988, 18.447500038488144],
            [-88.516955040217709, 18.430273215757708],
            [-88.589072935340738, 18.290735985168723],
            [-88.600017856899655, 18.235836027958911],
            [-88.681117967128614, 18.185553996497376],
            [-88.704455029652991, 18.110000023976895],
            [-88.728054948661537, 18.054718019619187],
            [-88.774081990783259, 17.988836092837957],
            [-88.841399905220584, 17.903891035973729],
            [-88.872035929050384, 17.907254023175327],
            [-88.983417843880147, 17.965818045549483],
            [-88.996999879823449, 17.987400105182061],
            [-89.022572898845141, 18.004509078353507],
            [-89.072054961465369, 17.994973154722246],
            [-89.121617825632569, 17.970936035642737],
            [-89.143008945510218, 17.951073104356212],
            [-89.141955005002018, 17.818882109007021],
            [-89.430435805587507, 17.81971812003205],
            [-89.742235899062592, 17.820827045825368],
            [-89.999999874271396, 17.820973226217077],
            [-90.238345997253646, 17.821109013048996],
            [-90.590835872169464, 17.821109013048996],
            [-90.777790870258855, 17.820273169661775],
            [-90.921672944189822, 17.82000008725565],
            [-90.979172967771973, 17.82000008725565],
            [-90.982417937776518, 17.820654043343339],
            [-90.983064014874998, 17.817773183213873],
            [-90.983608838582995, 17.585000020030336],
            [-90.983899858262021, 17.256109058294825],
            [-91.436954969567495, 17.25750011894975],
            [-91.433999845775787, 17.23721809250334],
            [-91.332226944773311, 17.188054039290435],
            [-91.268481900393681, 17.134026973479607],
            [-91.184445944750308, 17.035554027766594],
            [-91.063608907836141, 16.924445027704948],
            [-90.986681979571898, 16.896109165750872],
            [-90.948899880350666, 16.889164088398346],
            [-90.91909986754581, 16.829854076639251],
            [-90.866672907055658, 16.817773071920882],
            [-90.804735839195985, 16.805418146605703],
            [-90.748608938995858, 16.756945097492448],
            [-90.714282027911395, 16.726464138871663],
            [-90.678890950396834, 16.675827049990758],
            [-90.658063932604307, 16.644164243018892],
            [-90.644181992164476, 16.595273104574105],
            [-90.643481935609259, 16.563609124135766],
            [-90.645290917957709, 16.520691097548749],
            [-90.609172961797725, 16.48916424504678],
            [-90.590708970155077, 16.47639106276192],
            [-90.545290957119789, 16.473326974228812],
            [-90.50500887145057, 16.468609136194246],
            [-90.486117905659086, 16.461109009211853],
            [-90.406399971267547, 16.416382167914193],
            [-90.393335936941867, 16.401109166819609],
            [-90.38444609040917, 16.376944978087508],
            [-90.406535925737472, 16.335000090461719],
            [-90.438608943051833, 16.307500071894665],
            [-90.458072895745943, 16.256664164269708],
            [-90.457326906361203, 16.194436076731037],
            [-90.430072980472119, 16.172364178675465],
            [-90.432091007486235, 16.132773097106067],
            [-90.44195499880874, 16.088336102021216],
            [-90.447617812609906, 16.078844937753004],
            [-90.453772979404903, 16.074882141558518],
            [-90.625835850080847, 16.075554034919023],
            [-90.804455045438857, 16.075836002142651],
            [-90.99154599799806, 16.075744974673867],
            [-91.540008960654916, 16.075836002142651],
            [-91.70418194584974, 16.075554034919023],
            [-91.729172925513382, 16.0750001587556],
            [-91.797499853457566, 15.961109037384446],
            [-91.828608951903902, 15.908891121560046],
            [-92.10793587600611, 15.437600174017078],
            [-92.211399909797933, 15.26221806967429],
            [-92.070690889141957, 15.077291156882822],
            [-92.093926865913602, 15.025800119704073],
            [-92.149735924344554, 14.934718000955527],
            [-92.183826801034968, 14.845273203177911],
            [-92.182917867452176, 14.81055401902357],
            [-92.169172887310538, 14.76860913139798],
            [-92.158335924664726, 14.72944502397722],
            [-92.152645953497057, 14.70333606606512],
            [-92.15334601005236, 14.683054039618682],
            [-92.187226836248527, 14.58833601890062],
            [-92.2166729651004, 14.558054046661695],
            [-92.237946067781053, 14.55199108080204],
            [-92.246845608008186, 14.550576465547138],
            [-92.248113751999881, 14.548499941000259],
            [-92.263161751999917, 14.537740941000166],
            [-92.409031751999891, 14.392852941000186],
            [-92.424459751999905, 14.377327941000132],
            [-93.140926752999889, 13.621364940000106],
            [-93.852878753999875, 12.863257939000135],
            [-94.560650753999965, 12.103073939000183],
            [-94.572372753999957, 12.114634939000183],
            [-94.575960754, 12.11719793900015],
            [-94.59408875400004, 12.135626939000147],
            [-94.596000753999874, 12.136991939000112],
            [-94.596598753999984, 12.13759993900014],
            [-94.600995754, 12.140738939000158],
            [-94.6039607539999, 12.143752939000137],
            [-94.605968753999946, 12.145186939000126],
            [-94.609792753999898, 12.149019439000156],
            [-94.611901753999973, 12.151108939000025],
            [-94.614151254, 12.15276143900023],
            [-94.614971753999953, 12.153393939000253],
            [-94.624613753999881, 12.163191939000043],
            [-94.625998753999909, 12.164180939000161],
            [-94.63188775399999, 12.170164939000202],
            [-94.635001754, 12.17238693900002],
            [-94.641532753999911, 12.179022939000191],
            [-94.645218753999984, 12.181724939000119],
            [-94.646991753999941, 12.183061939000069],
            [-94.667899753999905, 12.204264439000127],
            [-94.66991875399998, 12.206245439000256],
            [-94.675041753999921, 12.211413939000067],
            [-94.678026754, 12.213542939000192],
            [-94.679948753999952, 12.215495939000135],
            [-94.683025753999885, 12.217690939000022],
            [-94.686065753999969, 12.220776939000274],
            [-94.691012753999928, 12.224305939000232],
            [-94.695794253999964, 12.229091939000099],
            [-94.702335754000046, 12.235663939000133],
            [-94.704995754000038, 12.237560939000232],
            [-94.707597753999949, 12.24020293900017],
            [-94.709023753999986, 12.241219939000118],
            [-94.723993754000048, 12.256414939000223],
            [-94.72503275400004, 12.257154939000173],
            [-94.727291754, 12.25944893900008],
            [-94.730032754000035, 12.261402939000106],
            [-94.735473753999912, 12.26692493900012],
            [-94.73702475399989, 12.268029939000073],
            [-94.741533753999931, 12.272604939000061],
            [-94.747999753999892, 12.277214939000118],
            [-94.759976253999895, 12.289346939000097],
            [-94.762304753999928, 12.29168993899998],
            [-94.765039753999986, 12.293638939000132],
            [-94.767048753999859, 12.295677939000086],
            [-94.770008753999889, 12.297786939000147],
            [-94.771375753999848, 12.299172939000073],
            [-94.774000753999985, 12.301043939000152],
            [-94.779054753999901, 12.306170939000168],
            [-94.781021753999852, 12.307571939000269],
            [-94.800193753999849, 12.327015939000233],
            [-94.80319775399991, 12.329202439000127],
            [-94.804016753999889, 12.32983293900007],
            [-94.804832753999904, 12.330659939000114],
            [-94.806028753999925, 12.33151193900008],
            [-94.816066753999849, 12.341689939000048],
            [-94.818273253999905, 12.343334939000087],
            [-94.820040754000047, 12.344666939000163],
            [-94.830328753999851, 12.355095939000108],
            [-94.833046754999941, 12.357031939000066],
            [-94.833607755, 12.357599939000167],
            [-94.835027754999913, 12.358610939000144],
            [-94.846714754999851, 12.370456939000093],
            [-94.851051754999958, 12.373544939000155],
            [-94.857098754999868, 12.379672939000216],
            [-94.85904975499983, 12.381061939000219],
            [-94.860673754999823, 12.382706939000258],
            [-94.8642062549998, 12.385268939000142],
            [-94.865022755000041, 12.385897939000131],
            [-94.865811754999868, 12.386631939000111],
            [-94.870992754999918, 12.391802439000088],
            [-94.874396754999907, 12.395236939000128],
            [-94.875039754999989, 12.395694939],
            [-94.876186755000049, 12.39685693900023],
            [-94.87705375500002, 12.397472939000224],
            [-94.886796754999864, 12.407343939000185],
            [-94.889052754999938, 12.408948939000112],
            [-94.906839754999822, 12.42689443900025],
            [-94.915103754999876, 12.435191939000219],
            [-94.920234254999912, 12.438888939000137],
            [-94.921049754999984, 12.439516939000143],
            [-94.925851754999911, 12.444317439000145],
            [-94.935832754999922, 12.454286939000085],
            [-95.028860754999869, 12.548345939000157],
            [-95.105507754999906, 12.515056939000232],
            [-95.106312754999891, 12.514830939000092],
            [-95.682641755, 12.373888939000025],
            [-95.913405755999861, 12.357520939000025],
            [-95.939757755999949, 12.351002939000196],
            [-96.531904755999904, 12.307737939000148],
            [-96.53705275599998, 12.308235939000141],
            [-96.539778755999919, 12.308032939000228],
            [-96.593675755999925, 12.31323893900003],
            [-96.59712375600003, 12.313016939000036],
            [-96.597877755999889, 12.312995939000174],
            [-97.189033756999834, 12.369166939000124],
            [-97.191965256999907, 12.369952439000102],
            [-97.460300757000027, 12.442367939000135],
            [-97.471026756999862, 12.443356939000068],
            [-98.044571256999944, 12.596882939000139],
            [-98.047505756999925, 12.597756439000122],
            [-98.050259756999878, 12.598533939000149],
            [-98.051927256999903, 12.599244439000131],
            [-98.053038757999957, 12.599698939000262],
            [-98.054208757999959, 12.600010939000143],
            [-98.056134257999844, 12.600890439000167],
            [-98.164079757999957, 12.650542939000161],
            [-98.297133757999916, 12.662323939000146],
            [-98.298060757999878, 12.662411939000236],
            [-98.299477757999966, 12.662786939000185],
            [-98.303018757999951, 12.663103939000123],
            [-98.303983757999845, 12.663193939000109],
            [-98.307049757999948, 12.664004939000236],
            [-98.307947757999926, 12.664088439000125],
            [-98.308914757999901, 12.664177939000169],
            [-98.310846757999883, 12.664688939000172],
            [-98.312853757999846, 12.664865939000066],
            [-98.314786757999912, 12.665376939000254],
            [-98.316792758, 12.665553939000148],
            [-98.319860757999891, 12.666365939000187],
            [-98.320732757999934, 12.666442939000163],
            [-98.322720757999832, 12.666968939000171],
            [-98.32566375799999, 12.667227939000099],
            [-98.327652757999886, 12.667753939000107],
            [-98.330630257999871, 12.668022439000111],
            [-98.331556757999863, 12.668109939000075],
            [-98.335410758, 12.669128939000188],
            [-98.339464757999963, 12.669485939000054],
            [-98.343250757999954, 12.670460439000124],
            [-98.35256275799992, 12.672895939000114],
            [-98.354230757999915, 12.673041939000115],
            [-98.356224757999883, 12.673568939000205],
            [-98.359161757999885, 12.673826939000236],
            [-98.360677757999895, 12.67422793900019],
            [-98.374913757999906, 12.675477939000061],
            [-98.375831758, 12.675720939000087],
            [-98.380835757999876, 12.676159939000172],
            [-98.382392758, 12.676571939000155],
            [-98.406447758000013, 12.678680939000046],
            [-98.980992257999873, 12.82955993900012],
            [-98.981894757999868, 12.829812939000092],
            [-99.521884758999903, 13.074350940000116],
            [-99.624454758999946, 13.143890940000261],
            [-99.62601575899987, 13.144292940000128],
            [-99.626935758999878, 13.144706940000091],
            [-99.628003758999967, 13.144981940000108],
            [-99.628964758999899, 13.145414940000052],
            [-99.630264258999858, 13.145807440000112],
            [-99.632942758999974, 13.146554940000229],
            [-99.633804758999872, 13.146942940000187],
            [-99.641824758999974, 13.149005940000166],
            [-99.642683758999823, 13.149372440000121],
            [-99.645621258999967, 13.150644940000063],
            [-99.652651759, 13.153780940000189],
            [-99.653667758999859, 13.154041940000113],
            [-99.707691758999886, 13.178348940000092],
            [-99.721790759000015, 13.18196994000003],
            [-100.230132759999833, 13.409667940000162],
            [-100.23575475999985, 13.41109594000018],
            [-100.238443759999939, 13.41130694000023],
            [-100.242071259999875, 13.412172940000204],
            [-100.244330759999883, 13.412691940000258],
            [-100.247759759999923, 13.41356094000021],
            [-100.249260759999942, 13.41367894000021],
            [-100.255966259999894, 13.415349440000128],
            [-100.261624759999847, 13.41675494000016],
            [-100.264043759999879, 13.416943940000166],
            [-100.267666759999884, 13.417808440000115],
            [-100.270400759999887, 13.41844694000001],
            [-100.27386775999986, 13.418717940000221],
            [-100.851490759999905, 13.56429694000019],
            [-100.852492759999848, 13.564739940000067],
            [-100.85347776, 13.564986940000082],
            [-100.85524076, 13.565728940000014],
            [-100.85875976, 13.567247940000101],
            [-100.860372760000018, 13.567651940000133],
            [-100.861374759999876, 13.568094940000023],
            [-100.862359759999833, 13.568340940000127],
            [-100.867897759999948, 13.570719940000231],
            [-100.871340759999981, 13.572171940000018],
            [-100.87220676000004, 13.572388940000039],
            [-100.897201759999916, 13.583435940000129],
            [-100.898857759999885, 13.58385094000019],
            [-101.443199760999875, 13.82343594000011],
            [-101.453784760999838, 13.828142940000191],
            [-101.456029760999968, 13.829629940000117],
            [-101.459012261, 13.831003940000116],
            [-101.462664760999871, 13.832671940000111],
            [-101.463491761, 13.833219940000149],
            [-101.472566760999825, 13.837193940000162],
            [-101.51218676099991, 13.863418940000201],
            [-101.552871260999879, 13.881246940000224],
            [-101.553737760999979, 13.881654940000217],
            [-101.59324076099989, 13.907773940000212],
            [-101.616100760999871, 13.917762940000145],
            [-101.647224760999904, 13.938324940000072],
            [-101.648801760999902, 13.938712940000201],
            [-101.649898760999989, 13.939190940000131],
            [-101.650757760999923, 13.939402940000079],
            [-101.651560760999928, 13.939752940000105],
            [-101.653898260999881, 13.940358440000196],
            [-101.656684760999966, 13.941073940000152],
            [-101.659878760999916, 13.942467940000014],
            [-101.660626760999889, 13.942651940000147],
            [-101.661998760999907, 13.943250940000141],
            [-101.663840260999947, 13.943753440000066],
            [-101.665560760999838, 13.944226940000092],
            [-101.666933760999839, 13.944825940000086],
            [-101.668774760999938, 13.945328940000124],
            [-101.67049576099987, 13.94580194000001],
            [-101.671858760999839, 13.946396940000042],
            [-101.673443760999888, 13.946786940000166],
            [-101.678239760999872, 13.948878940000228],
            [-101.679372760999925, 13.949157940000234],
            [-101.682122760999903, 13.950357940000217],
            [-101.687255760999975, 13.95161794000019],
            [-101.691091260999883, 13.953271440000051],
            [-101.693093760999844, 13.954124940000142],
            [-101.694147760999925, 13.954382940000173],
            [-101.699784760999947, 13.956842940000158],
            [-101.703024760999881, 13.957637940000026],
            [-101.704085761, 13.958100940000136],
            [-101.705010760999897, 13.958327940000103],
            [-101.706964761, 13.959137273333496],
            [-101.709914760999936, 13.959902940000191],
            [-101.711698760999923, 13.960642440000115],
            [-101.713689761, 13.961440440000146],
            [-101.715514761, 13.962203940000151],
            [-101.719783760999988, 13.963251940000177],
            [-101.723682427666645, 13.964925273333492],
            [-101.727667760999864, 13.96591294000028],
            [-101.733566760999977, 13.968484940000096],
            [-101.735551760999911, 13.96897194000006],
            [-101.737222760999884, 13.969700940000152],
            [-101.811436760999953, 13.987885940000226],
            [-101.814379760999913, 13.989160940000104],
            [-101.81531276099993, 13.989541940000109],
            [-101.816517760999886, 13.990047940000053],
            [-101.817364760999979, 13.99025594000021],
            [-101.818222761000015, 13.99062894000015],
            [-101.820312760999926, 13.991140940000079],
            [-101.82389826099984, 13.992633440000091],
            [-101.826043260999867, 13.993461940000074],
            [-101.827100760999883, 13.993884940000086],
            [-101.829189760999967, 13.994395940000103],
            [-101.830977760999943, 13.995136440000167],
            [-101.835091427666626, 13.996866273333538],
            [-101.841043760999867, 13.998335940000132],
            [-101.841904760999881, 13.998709940000154],
            [-101.843991760999984, 13.99922094],
            [-101.844852761, 13.999595940000106],
            [-101.846939761000016, 14.00010594000004],
            [-101.848728260999849, 14.00084694000013],
            [-101.850724760999924, 14.001647940000154],
            [-101.853583760999868, 14.002862940000242],
            [-101.854824760999875, 14.003165940000073],
            [-101.863161761000015, 14.006794940000148],
            [-101.865687760999847, 14.00741194000004],
            [-101.866584760999899, 14.007789941000212],
            [-101.87139626099993, 14.009834441000194],
            [-101.875440260999952, 14.011540941000177],
            [-101.878626760999907, 14.012909941000103],
            [-101.886388760999893, 14.014807941000115],
            [-101.915180760999874, 14.027329941000048],
            [-101.919199760999874, 14.028364941000135],
            [-101.920906760999983, 14.028834941000227],
            [-101.92177576099995, 14.029211940999986],
            [-101.923854760999916, 14.029719941000096],
            [-101.925645760999956, 14.030461441000071],
            [-101.929831094333295, 14.032220941000148],
            [-101.930746760999867, 14.032484941000249],
            [-101.933696260999923, 14.033761941000122],
            [-101.943731761, 14.038118941000121],
            [-101.946890760999807, 14.038955441000226],
            [-101.952439760999837, 14.040375941],
            [-101.953792760999875, 14.040963941000129],
            [-101.955594260999987, 14.041436441000158],
            [-101.958365760999882, 14.042146941000155],
            [-101.966075760999829, 14.045463441000194],
            [-101.979103760999877, 14.05106694100003],
            [-101.980064761, 14.051335941000175],
            [-101.98186726099992, 14.052090441000146],
            [-101.982570761000034, 14.052366941000216],
            [-101.984005760999935, 14.052716941000213],
            [-101.984880760999857, 14.053095941000137],
            [-101.986953760999953, 14.053601941000252],
            [-101.990967760999979, 14.055344941000058],
            [-102.000760760999881, 14.057731941000199],
            [-102.004358261, 14.059228941000057],
            [-102.006501760999896, 14.060058441000123],
            [-102.008432760999895, 14.060861941000169],
            [-102.012584760999971, 14.061872941000175],
            [-102.014544760999868, 14.062723941000044],
            [-102.017517760999922, 14.063448941000175],
            [-102.019826761, 14.064450941000118],
            [-102.025398761, 14.065808941000043],
            [-102.02674676099987, 14.066393941000129],
            [-102.066115260999936, 14.076035941000143],
            [-102.067772760999858, 14.076495941000204],
            [-102.068655760999903, 14.07687894100026],
            [-102.070720760999961, 14.077380941000243],
            [-102.073944761, 14.078779941],
            [-102.074692760999895, 14.078961941000145],
            [-102.075929760999841, 14.079497941000241],
            [-102.080587760999947, 14.080631941000107],
            [-102.081796760999978, 14.081155941000119],
            [-102.086512760999824, 14.082302941000179],
            [-102.090111760999889, 14.083798441000098],
            [-102.092390760999891, 14.084721941000126],
            [-102.093403760999962, 14.084967941000045],
            [-102.095618760999912, 14.085927941000065],
            [-102.097344760999903, 14.086347941000199],
            [-102.098191760999896, 14.086714941000182],
            [-102.100322760999916, 14.087233941000122],
            [-102.102124760999885, 14.087985441000214],
            [-102.10794626099991, 14.090430441000208],
            [-102.121004761, 14.096041941000081],
            [-102.15846576099996, 14.105142941000096],
            [-102.172243760999891, 14.111110941000234],
            [-102.211672760999889, 14.120677941000096],
            [-102.758724762000014, 14.356410941000163],
            [-103.135504761999954, 14.600977940999982],
            [-103.180287761999921, 14.620051941000256],
            [-103.5020902629999, 14.827572941000156],
            [-103.504930762999919, 14.828716941000138],
            [-103.511403762999919, 14.83026244100013],
            [-103.518144762999896, 14.831869941000079],
            [-103.519181762999906, 14.832308941000164],
            [-103.521244762999885, 14.832821941000148],
            [-103.555555762999902, 14.84089494100003],
            [-103.556753762999904, 14.841400941000146],
            [-103.557724263000011, 14.841663941000135],
            [-103.558622762999917, 14.84193144100017],
            [-103.644168762999868, 14.861977941000063],
            [-103.656754762999938, 14.867292941000173],
            [-103.658958762999958, 14.867807941000152],
            [-103.66402976299986, 14.869948941000203],
            [-103.809585762999973, 14.903905941000161],
            [-103.810434762999961, 14.90426394100011],
            [-103.819440762999932, 14.906360941000244],
            [-103.823816762999982, 14.908203941000139],
            [-103.850930762999965, 14.914513941000152],
            [-103.858870763000027, 14.917855941000099],
            [-103.868661762999935, 14.920132941000205],
            [-103.873960762999971, 14.922361941000062],
            [-103.889493262999949, 14.926002441000108],
            [-103.89330176299984, 14.926917941000099],
            [-103.957110762999918, 14.953743941000127],
            [-103.958288763000041, 14.954016941000148],
            [-103.96059676299987, 14.954986941000115],
            [-103.963217762999932, 14.955594941000172],
            [-103.964299762999929, 14.95604994100016],
            [-103.966162762999886, 14.956481941000177],
            [-103.969908262999894, 14.958019441000133],
            [-103.973841262999883, 14.959598441000125],
            [-103.983258762999981, 14.963516941000151],
            [-103.985060262999824, 14.963967441000207],
            [-103.985979762999975, 14.964232441000021],
            [-103.988091762999929, 14.964789441000093],
            [-103.990806762999853, 14.965467941000114],
            [-103.991699762999929, 14.965842941000048],
            [-103.99484926299985, 14.966592941000116],
            [-103.996695762999948, 14.967040941000064],
            [-103.997628262999925, 14.967420941000043],
            [-103.998426763, 14.967745941000132],
            [-103.999640762999903, 14.968026941000105],
            [-104.000869762999983, 14.968542941000166],
            [-104.001623762999841, 14.968717941000065],
            [-104.008909762999878, 14.971776941000215],
            [-104.011583762999877, 14.972415441000194],
            [-104.013434762999907, 14.972862941000031],
            [-104.016364762999956, 14.974092941000194],
            [-104.221148762999988, 15.021413941000091],
            [-104.250000762999889, 15.033482941000202],
            [-104.264480762999895, 15.036816941000197],
            [-104.316814762999982, 15.05868794100023],
            [-104.320618762999871, 15.059561941000069],
            [-104.328782762999907, 15.062971941000129],
            [-104.345225762999988, 15.066750941000166],
            [-104.347978762999858, 15.067899941000192],
            [-104.373763762999829, 15.073821941000077],
            [-104.376333762999934, 15.074894941000039],
            [-104.380673762999848, 15.075890941000225],
            [-104.382216762999974, 15.07653494100002],
            [-104.387834262999888, 15.077876941000127],
            [-104.400350762999892, 15.080801941000132],
            [-104.403751762999832, 15.082219942000123],
            [-104.471262262999915, 15.097704442000108],
            [-104.477130763, 15.099056942000189],
            [-104.611484763999968, 15.154966942000229],
            [-104.614005763999842, 15.15554294200011],
            [-105.167188764, 15.384354942000201],
            [-105.672257765, 15.700776942000246],
            [-106.114353764999919, 16.095552941999969],
            [-106.117480764999911, 16.099511941999978],
            [-106.129335764999894, 16.110055942000216],
            [-106.17863076499998, 16.17243194300012],
            [-106.460679764999838, 16.285829943000167],
            [-106.569596764999858, 16.352794943000234],
            [-106.601826764999828, 16.365686943000227],
            [-107.112656765999986, 16.677954943000103],
            [-107.561015765999855, 17.068941943000212],
            [-107.561629765999854, 17.069699943000231],
            [-107.562161265999862, 17.070217443000061],
            [-107.563014765999881, 17.071014942999952],
            [-107.61435376599988, 17.134396943000056],
            [-107.637078765999888, 17.15410594300009],
            [-107.763967766999841, 17.310094944000113],
            [-107.924251766999873, 17.004058443000147],
            [-107.925130766999942, 17.002463943000109],
            [-108.285159766999911, 16.535804943000173],
            [-108.286140766999893, 16.534534943000082],
            [-108.723450266999905, 16.133786442000101],
            [-108.724392766999898, 16.13293594200006],
            [-108.725276766999855, 16.132340442000086],
            [-109.226734767999872, 15.809182942000078],
            [-109.777422767999909, 15.573186942000106],
            [-109.785570767999957, 15.571218942000186],
            [-109.802048767999906, 15.564120941999974],
            [-109.80480276799986, 15.563419442000011],
            [-110.388905768999962, 15.421193942000045],
            [-110.389815268999897, 15.421114442000132],
            [-110.990197769999853, 15.377020942000186],
            [-110.991662769999834, 15.377157941999982],
            [-110.992378769999831, 15.377141442000138],
            [-110.99336427, 15.377140442000226],
            [-110.994123769999888, 15.37711994200022],
            [-111.59349377, 15.43240594200013],
            [-112.174621770999877, 15.585484941999979],
            [-112.538712770999851, 15.750062942000213],
            [-112.9416787709999, 15.47824894200015],
            [-112.942549270999891, 15.477831942000094],
            [-113.487606772, 15.231045941999966],
            [-114.067643771999855, 15.077713941000056],
            [-114.069286271999857, 15.077506941000152],
            [-114.082267771999881, 15.076265941000159],
            [-114.108950771999901, 15.069162941000243],
            [-114.109907271999901, 15.069071941000189],
            [-114.112822771999987, 15.068791441000158],
            [-114.711135772999882, 15.013111941000105],
            [-114.714445772999923, 15.013357941000024],
            [-114.732748772999884, 15.011625941000077],
            [-115.332066273999928, 15.055305441000044],
            [-115.332962773999853, 15.055382941000161],
            [-115.915884773999835, 15.197143941999954],
            [-116.465602775, 15.432875942000109],
            [-116.966084774999899, 15.755710942],
            [-117.402565775999932, 16.156235943000141],
            [-117.761942775999856, 16.622731943000261],
            [-117.765963775999936, 16.630453943000234],
            [-117.767043775999937, 16.631849943000262],
            [-118.038270775999933, 17.150598943000162],
            [-118.212591775999925, 17.706277944000263],
            [-118.283944109333248, 18.282546277333452],
            [-118.28391677599997, 18.283356944000232],
            [-118.28397477599988, 18.284043610666785],
            [-118.28373377599992, 18.288404944000106],
            [-118.284116775999905, 18.291937444000084],
            [-118.284155442666574, 18.292986277333412],
            [-118.284160275999866, 18.294163444000148],
            [-118.283445776, 18.307808445000077],
            [-118.283456775999923, 18.30856761166676],
            [-118.283485575999904, 18.309434345000042],
            [-118.248747775999917, 18.888857945000026],
            [-118.10783177599987, 19.453490946000059],
            [-117.863968775999922, 19.986304946000203],
            [-117.523675776, 20.470770947000105],
            [-117.09669877500005, 20.89168694700011],
            [-116.595655774999912, 21.235780447000153],
            [-116.594819775000019, 21.236314946999968],
            [-116.035513774000037, 21.492409947000098],
            [-115.483162773999879, 21.639598948000142],
            [-115.783628773999908, 21.618498948000124],
            [-116.404898774999893, 21.673396948000075],
            [-117.007704775, 21.825345948000049],
            [-117.57492577599993, 22.070057947999985],
            [-118.090252775999943, 22.40058794800008],
            [-118.538584776999883, 22.807503949],
            [-118.906407777, 23.279109949000187],
            [-119.182179776999988, 23.801729950000194],
            [-119.356687776999905, 24.360049950000203],
            [-119.423402443666632, 24.937753284333482],
            [-119.423363776999963, 24.938722951000173],
            [-119.423466443666598, 24.939803284333451],
            [-119.378871776999915, 25.519257951000085],
            [-119.339059776999932, 25.663334950999953],
            [-119.515642276999898, 25.702855451000161],
            [-119.516744276999944, 25.703200451000086],
            [-119.51932577699985, 25.703836951000184],
            [-120.109679777999872, 25.937737952000091],
            [-120.648835778999924, 26.258132952000054],
            [-120.649604778999873, 26.25878495200017],
            [-120.650815778999885, 26.259499952000183],
            [-121.123147778999979, 26.657298952000119],
            [-121.514520778999966, 27.121154953000215],
            [-121.518852778999928, 27.12870195300026],
            [-121.525804778999955, 27.136891953000045],
            [-121.824094779999967, 27.653421953000205],
            [-122.019235779999988, 28.207624954000025],
            [-122.022698779999899, 28.231337954000068],
            [-122.09028778, 28.421589954000225],
            [-122.175670779999876, 28.997078954000258],
            [-122.175609779999888, 28.998261954000085],
            [-122.17574678, 28.999175954000222],
            [-122.174843779999833, 29.016824954000143],
            [-122.18349978, 29.074648955000171],
            [-122.183505779999948, 29.075899955000054],
            [-122.154024779999887, 29.655934955000262],
            [-122.008383780000031, 30.222209956000071],
            [-121.868226946399858, 30.54301972980015],
            [-121.866213742333287, 30.542000213333424],
            [-121.863398577749891, 30.542510141750157],
            [-118.604999999999848, 31.132777780000154],
            [-117.825277799999881, 32.626944440000031],
            [-117.463727800000015, 32.589475],
            [-117.365999887499896, 32.572480978999963],
            [-117.262433, 32.553344000000195],
            [-117.238191912499943, 32.527442005000225],
            [-117.12400007249984, 32.534219196000166],
            [-117.122504790156654, 32.534062959329461],
            [-117.122373100760015, 32.535327385356723],
            [-117.095291036957377, 32.53666425950685],
            [-116.914173028617668, 32.552218054358761],
            [-116.71305496105515, 32.56860014888484],
            [-116.508146093952064, 32.585000180683735],
            [-116.242635899082956, 32.60577321901944],
            [-115.907782051411658, 32.631945041205668],
            [-115.605563982035392, 32.654718001644667],
            [-115.404163947249359, 32.669718087971333],
            [-115.202499882512441, 32.684436039436363],
            [-115.025891002817687, 32.696991124599705],
            [-114.871345974551218, 32.707854071145547],
            [-114.729799937041719, 32.717709177650633],
            [-114.719090882238689, 32.7184539935689],
            [-114.721390876476505, 32.711109099433699],
            [-114.731390989906998, 32.685273223927695],
            [-114.791672967160935, 32.55778212934014],
            [-114.80982682838517, 32.506991148716324],
            [-114.795226894117121, 32.500500035240961],
            [-114.585535815962956, 32.437091105179846],
            [-114.343899963613325, 32.364945046862104],
            [-114.061317843584973, 32.279727075229388],
            [-113.858281996570511, 32.218300129998582],
            [-113.692008841710162, 32.167573187115195],
            [-113.344508881406682, 32.061082112802197],
            [-113.052890902091761, 31.971073212939146],
            [-112.867282037656267, 31.9134090716923],
            [-112.499999947613063, 31.79837314998214],
            [-112.299435923851959, 31.735545085812433],
            [-111.94638194685092, 31.62360912718141],
            [-111.417509000712258, 31.454164092512556],
            [-111.045835799221265, 31.333053973192094],
            [-110.311955079521738, 31.332773179434966],
            [-110.245564035940319, 31.332773179434966],
            [-109.879181994662773, 31.332773179434966],
            [-109.51221791402692, 31.333327055598389],
            [-109.058926935965374, 31.332873091721055],
            [-109.047363932864499, 31.332600176952937],
            [-108.832764076920753, 31.332600176952937],
            [-108.831935944884734, 31.332626999043086],
            [-108.816655064800955, 31.333173163855676],
            [-108.778635925357435, 31.332773179434966],
            [-108.208345983856489, 31.333053973192094],
            [-108.207781881771183, 31.366391149146992],
            [-108.208617892796212, 31.433327016436252],
            [-108.207781881771183, 31.532773101110834],
            [-108.208617892796212, 31.599445106087487],
            [-108.207781881771183, 31.699445066925321],
            [-108.208617892796212, 31.783336015643201],
            [-108.174999923084457, 31.783882180455791],
            [-107.908335875420974, 31.783054048419771],
            [-107.708072929622872, 31.783882180455791],
            [-107.608063916329527, 31.783336015643201],
            [-107.57500887523635, 31.783882180455791],
            [-107.507781988267894, 31.783336015643201],
            [-107.44139094468666, 31.783882180455791],
            [-107.374999901105156, 31.783336015643201],
            [-107.341135838715346, 31.783882180455791],
            [-107.274726857861097, 31.783336015643201],
            [-107.241954957458006, 31.783882180455791],
            [-107.174726897023163, 31.783336015643201],
            [-107.141681914213876, 31.783882180455791],
            [-107.075290870632458, 31.783336015643201],
            [-106.608335881614337, 31.783609098049496],
            [-106.53956404224823, 31.782054087368024],
            [-106.522882043225707, 31.780754054182026],
            [-106.496399922983144, 31.75708221844387],
            [-106.460008884416851, 31.750273095561269],
            [-106.439182034262501, 31.751664156216194],
            [-106.416535808199924, 31.754027014727896],
            [-106.395846085981873, 31.747500194345079],
            [-106.334308834904917, 31.687354003922778],
            [-106.303346084488354, 31.637500120076723],
            [-106.289172956731321, 31.597500169269111],
            [-106.272226927757927, 31.559300148354467],
            [-106.209872944033236, 31.472218041450517],
            [-106.143617854921729, 31.431945008236809],
            [-106.062926949206954, 31.402500052851408],
            [-106.011672952250493, 31.395273175913289],
            [-105.972781927236113, 31.369718094164611],
            [-105.858064014933234, 31.282500032790765],
            [-105.841945950357939, 31.269164257163325],
            [-105.827517845105717, 31.254444964593816],
            [-105.786954965679357, 31.208191108171675],
            [-105.771664027311601, 31.178264193352319],
            [-105.710008926675641, 31.14416409656836],
            [-105.600008852407385, 31.081527139791646],
            [-105.580699964922303, 31.05791800068944],
            [-105.541399903031902, 30.996391143172545],
            [-105.490708834694274, 30.94639107893444],
            [-105.393064021017096, 30.865836127689661],
            [-105.253063941733544, 30.797218012428218],
            [-105.222572924829151, 30.80471813941044],
            [-105.169446075421774, 30.778609013860333],
            [-105.124999860243349, 30.749718102276361],
            [-105.061955040057015, 30.69416418897886],
            [-104.990845990803066, 30.632218068663789],
            [-104.934726801953687, 30.60763612587634],
            [-104.896526948677277, 30.566245114413974],
            [-104.879726932457601, 30.52500011570514],
            [-104.865008980992641, 30.466391166329842],
            [-104.822235793692769, 30.386109129853011],
            [-104.776946023776546, 30.318053943210643],
            [-104.70556405975428, 30.233326983467364],
            [-104.682235882047394, 30.187500101194061],
            [-104.674999952653948, 30.167773124377376],
            [-104.6793089214503, 30.105418134824461],
            [-104.701326840049319, 30.058882143540544],
            [-104.695008896693992, 30.005000084654924],
            [-104.678064041187156, 29.940973240689758],
            [-104.541817881070415, 29.672918132371521],
            [-104.453335828694222, 29.595835971260087],
            [-104.419173035274369, 29.569445046124187],
            [-104.338054987772594, 29.525000172050298],
            [-104.316746010546126, 29.530900193711929],
            [-104.285135841926603, 29.525973143373548],
            [-104.25279091567252, 29.508327057845108],
            [-104.228346100821284, 29.494444949767299],
            [-104.20243579401496, 29.460973160446983],
            [-104.169726925523904, 29.422773139532509],
            [-104.080835836271618, 29.355273170157844],
            [-104.063608845903175, 29.342500155511161],
            [-104.045290867014245, 29.330827014202313],
            [-104.02361794755106, 29.321109035633427],
            [-103.975008943968078, 29.30582698208336],
            [-103.889173058431666, 29.285827090498529],
            [-103.735835862416209, 29.199164246392584],
            [-103.531399902291412, 29.126109086853901],
            [-103.484999865477391, 29.08889108971816],
            [-103.461945943643173, 29.073053986538],
            [-103.401117969214738, 29.036391206669961],
            [-103.374999958847141, 29.023609139567967],
            [-103.296254995778952, 28.99736422718577],
            [-103.290872975734999, 28.996518157876935],
            [-103.163691005737363, 28.98402711045388],
            [-103.051955039316695, 29.094164145020429],
            [-102.954999888634774, 29.183054060806327],
            [-102.895845947913372, 29.254164115888685],
            [-102.851117933149411, 29.352218134080047],
            [-102.804763996802834, 29.474082121773918],
            [-102.670290944488627, 29.742773081268851],
            [-102.560835861566389, 29.767773113387804],
            [-102.496254973799822, 29.781664106283316],
            [-102.355564058054725, 29.850553962846234],
            [-102.30180890118352, 29.887982178114484],
            [-102.26125507421267, 29.867918081151245],
            [-102.227781943788059, 29.84721813301131],
            [-102.121945999028839, 29.810554012038793],
            [-102.097500010711386, 29.802773091299272],
            [-102.072781945816033, 29.798191207734604],
            [-102.048200003028583, 29.798473174958232],
            [-101.99084599220005, 29.805827121548745],
            [-101.892226866095157, 29.806109088772374],
            [-101.825835822513653, 29.80471802811762],
            [-101.774735884937911, 29.796664192609782],
            [-101.745008962329081, 29.788891150859442],
            [-101.706682039400008, 29.778054020575425],
            [-101.628764034901351, 29.766391105188561],
            [-101.539173056732011, 29.771109110861104],
            [-101.456681885848127, 29.772218036654252],
            [-101.405008961369745, 29.772782138739629],
            [-101.369355027370787, 29.692473112534501],
            [-101.355835855701358, 29.660553986962853],
            [-101.310835931997588, 29.615273101863963],
            [-101.218608845272186, 29.540273173145081],
            [-101.139035917806154, 29.49069119823848],
            [-101.106673054279071, 29.48208214546311],
            [-101.042999926628909, 29.46150004688208],
            [-101.025281924371114, 29.437773225859146],
            [-100.936954937204035, 29.350000114855405],
            [-100.795708971829043, 29.258744993625029],
            [-100.66583590799047, 29.109027103410668],
            [-100.628208873978451, 28.995826986139136],
            [-100.634455068242318, 28.958609156641401],
            [-100.621946083546334, 28.933326989660642],
            [-100.590564070331766, 28.894718099508154],
            [-100.564454944781602, 28.86360916869971],
            [-100.527364017298254, 28.822773206867069],
            [-100.491817874574394, 28.725709091443619],
            [-100.49237292420429, 28.70416424746135],
            [-100.479863939508419, 28.675836096858106],
            [-100.443890990273701, 28.626391082250095],
            [-100.399445948561663, 28.571109077892217],
            [-100.359172915347955, 28.51832705998261],
            [-100.350845997434078, 28.494718088518411],
            [-100.329590999664333, 28.425418021612799],
            [-100.331264027542801, 28.400409104676513],
            [-100.281463955515136, 28.280554091541077],
            [-100.240281988718436, 28.242773165786431],
            [-100.186399929833016, 28.197500159676551],
            [-100.108473040517026, 28.165691172312975],
            [-100.078335907565616, 28.155618136324549],
            [-100.050845947282369, 28.116391164630173],
            [-99.962235819425217, 27.984718003260511],
            [-99.935008883264459, 27.961664249064185],
            [-99.880835804699814, 27.90389114307591],
            [-99.871945958167032, 27.855554048432381],
            [-99.860908835672944, 27.80513606250112],
            [-99.832926857671879, 27.774300046460795],
            [-99.806699882562668, 27.771745074727775],
            [-99.798199962166791, 27.766664132646653],
            [-99.782299994712758, 27.737636093126483],
            [-99.75889990027639, 27.727144967806694],
            [-99.741399995139659, 27.714718125762076],
            [-99.727435912048406, 27.6891642174797],
            [-99.713899976572634, 27.668936002851737],
            [-99.696399903797897, 27.65694501977363],
            [-99.653626884136116, 27.64139105728357],
            [-99.606181959269293, 27.641245044529839],
            [-99.530835857948176, 27.60006425119964],
            [-99.503899941466443, 27.568054098159408],
            [-99.473199879896015, 27.476664195925437],
            [-99.443346055272684, 27.258053934577035],
            [-99.458617882900967, 27.046944973677697],
            [-99.319245943443292, 26.869853966910895],
            [-99.271126945921083, 26.859927111314519],
            [-99.253346079389303, 26.830826987426704],
            [-99.239726827795721, 26.803609103721286],
            [-99.200564061479525, 26.714445099700768],
            [-99.132508874837072, 26.526945110265487],
            [-99.104735941501815, 26.435000158401763],
            [-98.97557299250218, 26.405827111956185],
            [-98.92529096104056, 26.391391127714755],
            [-98.784181955963959, 26.348891190459071],
            [-98.730008877399428, 26.314445088711224],
            [-98.695563949117883, 26.289718138998538],
            [-98.604735802036316, 26.25666410373374],
            [-98.57833582444502, 26.256109054104016],
            [-98.439872986208201, 26.223327095417048],
            [-98.388063939621929, 26.191664120807175],
            [-98.361390879624423, 26.15360910681791],
            [-98.305281916696998, 26.10916423274405],
            [-98.286117868499332, 26.097773226296809],
            [-98.20000890055681, 26.062500165979245],
            [-98.060145949209641, 26.038891194515216],
            [-98.033335928913743, 26.047773162058704],
            [-97.979735837251837, 26.05860911887622],
            [-97.847508967356902, 26.063609091772392],
            [-97.803346060506641, 26.058054069246325],
            [-97.678899943713162, 26.038054010023515],
            [-97.648055042855702, 26.029444957248145],
            [-97.614455010416577, 26.005000142396895],
            [-97.55944608846491, 25.951109031055964],
            [-97.514445997123147, 25.898327013146357],
            [-97.417226816490597, 25.843327143650285],
            [-97.364726933442512, 25.839854018240786],
            [-97.344735926675213, 25.85965408525324],
            [-97.346682036959862, 25.893053957843918],
            [-97.315290971289812, 25.92000010024752],
            [-97.265290907051792, 25.941109085263648],
            [-97.160846022947709, 25.967218043175947],
            [-97.140735825637535, 25.966427126842447],
            [-97.138617886108193, 25.959326984114213],
            [-97.138583410066076, 25.95652026926372],
            [-96.924269439999875, 25.97515833000007],
            [-96.8080555599999, 26.008611110000047],
            [-95.657222219999881, 26.008333330000156],
            [-93.445052779999969, 25.996744440000171],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MHL", Country: "Marshall Is." },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [175.484469322666911, 5.569358057333403],
            [173.608598515000182, 4.957179192000098],
            [173.481139061000164, 4.915372490500133],
            [172.998118413000071, 4.756941717000188],
            [172.865980932000156, 4.713719757000149],
            [172.712089018000171, 4.663381956000023],
            [172.682322646000245, 4.653622275000032],
            [172.551845840000141, 4.610842047000133],
            [172.496479453000262, 4.59284920800016],
            [172.49553989000006, 4.592543871000032],
            [172.478663626000269, 4.587059463000017],
            [172.091462192000193, 4.509114387000054],
            [171.42679328900013, 4.375314083999982],
            [171.262249480000122, 4.342022321000087],
            [171.132833875000216, 4.300802424000153],
            [170.935262234000248, 4.237874258000133],
            [170.817891771000149, 4.197579622000092],
            [170.738278197500193, 3.965773099000018],
            [170.587367708000244, 3.526375217000052],
            [170.584687156000228, 3.518551566000028],
            [170.581999524000167, 3.510707244000159],
            [170.499233678333411, 3.268701432666703],
            [170.498640888000097, 3.266965945999971],
            [170.392050919000241, 2.95529093200004],
            [170.280385402000178, 2.628774723500086],
            [170.203657353000182, 2.398694564999985],
            [170.182849706000212, 2.340845900000048],
            [170.152027158000152, 2.255154177000037],
            [170.107107330000218, 2.12930274600005],
            [170.087449499000087, 2.074227598],
            [170.055175505000221, 1.98961300800002],
            [169.764059298000262, 1.946501242000039],
            [169.692225613000147, 1.935863302000129],
            [169.626390284000109, 1.925986615000141],
            [169.563557362000239, 1.916560353000023],
            [169.554254598000142, 1.915169156000118],
            [169.534500086000236, 1.912214935000023],
            [169.494126126000225, 1.906177145000143],
            [169.421538869000102, 1.895274837000116],
            [169.360716411000141, 1.88613955400001],
            [169.045916707000202, 1.838340837],
            [168.662801153000174, 1.780169142999981],
            [168.598105120000156, 1.770280294999964],
            [168.413648657000095, 1.834182354],
            [168.019619656000174, 1.969924354000071],
            [167.934040656000235, 1.999422354000032],
            [167.733006656000072, 2.068776353999979],
            [167.619732656000139, 2.107783354000148],
            [166.937356655000173, 2.341831353999964],
            [166.681926655000183, 2.429900354000125],
            [166.148882655000222, 2.612866354000062],
            [165.954234655000135, 2.678330354000025],
            [165.932968655000224, 2.711731354000065],
            [165.774790154000215, 2.935439355000113],
            [165.757780654000186, 2.959494355000018],
            [165.632144654000086, 3.21136735500005],
            [165.635850654000109, 3.243221355000074],
            [165.674719654000143, 3.575840355000096],
            [165.671196654000056, 3.63543635500001],
            [165.667460654000109, 3.698360355000133],
            [165.667320654000179, 3.700731355000016],
            [165.656110654000173, 3.891460356000124],
            [165.642247654000045, 4.132965356000099],
            [165.595459654000166, 4.94126135700013],
            [165.590981654000217, 5.022362357000091],
            [165.590490654000092, 5.031539357],
            [165.572818654000201, 5.315261357],
            [165.572727654000147, 5.31671935700011],
            [165.572636654000149, 5.318188357000153],
            [165.5603856540001, 5.516786357000129],
            [165.553813654000152, 5.627906356999986],
            [165.523040654000198, 6.144390358000166],
            [165.515262654000054, 6.291839358000132],
            [164.855610654000174, 6.79211635800003],
            [164.669818653000192, 6.932751358000019],
            [164.37056965300016, 7.159509359000097],
            [163.073531652000185, 8.049668359000165],
            [163.017656652000113, 8.024743359000027],
            [163.002906652000235, 8.018139359000017],
            [162.918155652000252, 7.980536359000112],
            [162.8902806520002, 7.968169359000044],
            [162.70125265200025, 7.884104359000062],
            [162.621836651000166, 7.848877359000014],
            [161.796939651000145, 7.917332359000099],
            [161.695968651000129, 7.925551359000153],
            [161.545435650000087, 7.938331359000017],
            [161.0162796500002, 7.98361335900006],
            [160.978370650000187, 7.998193359000098],
            [160.824532650000066, 8.057070359000122],
            [160.631853650000181, 8.130150360000158],
            [160.501651649000195, 8.179295360000083],
            [160.480733649000143, 8.187163360000142],
            [160.335577649000157, 8.241017360000043],
            [160.165669649000193, 8.303852360000178],
            [159.920620649000085, 8.381231360000015],
            [159.874072649000169, 8.396074360000142],
            [159.776070649000161, 8.427232360000048],
            [159.395942648000158, 8.54807136],
            [159.082333648000059, 8.818391360000092],
            [158.38472064800024, 9.43152136099998],
            [158.029955647000094, 9.808736361000129],
            [158.009249647000075, 9.830861361000132],
            [157.76341164700014, 10.09301636100011],
            [157.472641647000074, 10.402441362000033],
            [157.607141647000134, 10.941486362000049],
            [157.847696647000106, 11.471479363000014],
            [158.179564647000262, 11.952525363000092],
            [158.180368647000165, 11.953603363000113],
            [158.593436648000107, 12.370433363000188],
            [158.89407564800004, 12.582536364000063],
            [158.901118648000221, 12.597879364000164],
            [158.940848648000184, 12.751997364000104],
            [159.184407648000189, 13.281428364000107],
            [159.519723649000156, 13.761327365000042],
            [159.520753649000113, 13.76235536500019],
            [159.522712649000113, 13.76515336500006],
            [159.939976649000215, 14.180644365000106],
            [160.426623649000163, 14.518760365000091],
            [160.430064649000229, 14.520355365000015],
            [160.438693649000101, 14.526336365000063],
            [160.445509649000229, 14.529495365000045],
            [160.454983649000127, 14.536020865000054],
            [160.456818649000155, 14.537251364999989],
            [160.99797665000014, 14.787315366000072],
            [161.004023650000221, 14.788935866000045],
            [161.012656650000082, 14.791247365999979],
            [161.033337650000163, 14.800774366],
            [161.612121651000081, 14.954859366000036],
            [162.210248651000114, 15.008134366000107],
            [162.212869651000261, 15.007922366000159],
            [162.215304651000196, 15.008136366000102],
            [162.81384565200014, 14.958886365999987],
            [162.823621652000128, 14.95636636600004],
            [162.84322165200004, 14.954731366000019],
            [163.423199652000136, 14.804460366000072],
            [163.74050665200005, 14.660650366000013],
            [164.107736653000217, 14.825286366000157],
            [164.120561653000237, 14.828568366000027],
            [164.1360436530002, 14.835493366000023],
            [164.137587653000168, 14.835887366000108],
            [164.142121653000146, 14.837915366000047],
            [164.722980653000121, 14.985798366000139],
            [165.322126654000073, 15.032620366000046],
            [165.322900654000108, 15.032641366000021],
            [165.426429654000202, 15.023094366000137],
            [165.431295654000195, 15.023467366],
            [165.433522654000143, 15.023262366],
            [165.446740154000253, 15.024237366000037],
            [165.447515654000114, 15.024258365999984],
            [165.532930654000239, 15.016271366000026],
            [165.541179654000103, 15.120066366000103],
            [165.691008654000171, 15.682491367000139],
            [165.858614654000206, 16.037315367],
            [166.2688256550002, 16.223459367000103],
            [166.657593655000113, 16.408204367000153],
            [166.688936655000049, 16.423045367],
            [167.713932656000253, 16.904914368000149],
            [169.006423657000056, 17.501498368000156],
            [169.839400658000187, 17.879699369000079],
            [170.222942659000154, 17.777392368000122],
            [170.22380365900014, 17.777025868000081],
            [170.348300659000103, 17.719924367999951],
            [170.772636659000199, 17.524405368000018],
            [171.265268660000203, 17.183711368000147],
            [171.269077160000194, 17.180002368000046],
            [171.442931660000198, 17.007900367999966],
            [171.690645660000229, 16.761884367999969],
            [172.02812166000021, 16.279793367000124],
            [172.03170366000009, 16.272008367],
            [172.033015660000132, 16.27012836699997],
            [172.101779660000176, 16.120550367000064],
            [172.276789660000219, 15.738983366999989],
            [172.420192661000186, 15.175220366],
            [172.452425661000149, 14.70297736600007],
            [172.765569661000228, 14.395662365000121],
            [173.104850661000143, 13.917506365000051],
            [173.352436661000212, 13.389186364000153],
            [173.501334662000062, 12.826963364000136],
            [173.538764662000148, 12.359142363000061],
            [173.829017662000155, 11.945808363000054],
            [173.830972162000222, 11.941609363000083],
            [174.076083662000173, 11.413201363000084],
            [174.223289662000099, 10.851072362000025],
            [174.268691662, 10.272368362000165],
            [174.268194662000127, 10.267373362000143],
            [174.268587662000101, 10.262374361000056],
            [174.260790662000176, 10.183915361000118],
            [174.421533662000144, 9.835553361000038],
            [174.568134663000052, 9.273475361000024],
            [174.576177663000266, 9.170706360000125],
            [174.85307666300011, 8.771707360000036],
            [174.853484663000103, 8.770819360000104],
            [174.854059663000072, 8.769991360000162],
            [175.096616663000049, 8.241382360000046],
            [175.24239066300018, 7.679194359000178],
            [175.242717663000377, 7.674989359000151],
            [175.244323663000017, 7.668794359000145],
            [175.24456766300014, 7.665639359000039],
            [175.246246663000335, 7.659164359000044],
            [175.283652663000368, 7.178116359000072],
            [175.404089663, 6.71353735800011],
            [175.404514663000356, 6.708090358],
            [175.405042662999989, 6.706055358000015],
            [175.407338663000104, 6.676639358],
            [175.412677663000039, 6.656039358000143],
            [175.413210663000115, 6.649215358000049],
            [175.41360866300019, 6.647678358000064],
            [175.414015663000072, 6.642465358000038],
            [175.467163663000036, 6.437413358000029],
            [175.512468663000135, 5.858481857000086],
            [175.512483663000097, 5.857838357000134],
            [175.484469322666911, 5.569358057333403],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MKD", Country: "Macedonia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.365273300817705, 42.323882210192821],
            [22.407773238073389, 42.279445047469906],
            [22.519445166753712, 42.149718164023042],
            [22.609445181799231, 42.103336064481752],
            [22.685836171172951, 42.065273171503563],
            [22.823054129146726, 42.027354111984224],
            [22.876945072849679, 41.93819111379203],
            [22.897636136172196, 41.88221810533453],
            [22.942500105405969, 41.807782111062963],
            [22.965900199842309, 41.775691156475759],
            [23.009236148123051, 41.766309124587238],
            [23.030973272964843, 41.72506412587849],
            [23.015000215314757, 41.695409119999042],
            [22.986945147117922, 41.665836089132753],
            [22.954236110988887, 41.63596415959843],
            [22.951663201983024, 41.598609034526405],
            [22.959163328965246, 41.476391162879182],
            [22.966663288309576, 41.374164130361365],
            [22.955900254049908, 41.355618163705557],
            [22.935600122692591, 41.342127155230813],
            [22.926391260924248, 41.343044973631137],
            [22.808473305618065, 41.347773205225636],
            [22.776800105086409, 41.328054107398074],
            [22.762036053153849, 41.3066359977921],
            [22.761545041264469, 41.251736040582088],
            [22.758054146220161, 41.227773185164821],
            [22.737745130045511, 41.156109086281148],
            [22.586736091462598, 41.119854002184738],
            [22.484718103610447, 41.121382190775975],
            [22.442500133578477, 41.121382190775975],
            [22.343327131310247, 41.134435999179871],
            [22.304718073519467, 41.153327132609363],
            [22.259582195345899, 41.170973218137775],
            [22.205554123706662, 41.16777317513457],
            [21.977082217968899, 41.131800058261902],
            [21.916109236615227, 41.095273232863761],
            [21.910827128857306, 41.065582184800505],
            [21.786245057593931, 40.930826997624607],
            [21.600000174343563, 40.872773097879033],
            [21.575763230691933, 40.873118097014768],
            [21.548336134682586, 40.891109181678772],
            [21.52840011320032, 40.907836107702437],
            [21.410136152930164, 40.913882142117856],
            [21.374582298855387, 40.883191133002853],
            [21.349863228131539, 40.873191187210537],
            [21.314718075656884, 40.86860913600789],
            [21.150836110141086, 40.85750009678425],
            [21.121554098953936, 40.85721812956065],
            [20.983491245137799, 40.855891106646155],
            [20.980536121346006, 40.897209027912666],
            [20.963054153482176, 40.911382155669699],
            [20.944163355328783, 40.919164249875635],
            [20.837427194165997, 40.927427130049139],
            [20.821109137380461, 40.908882169221712],
            [20.807218144485034, 40.900554077841278],
            [20.740809163630814, 40.909536125309202],
            [20.662500227167129, 41.089164166536293],
            [20.600609092137006, 41.093182115653619],
            [20.578327143587387, 41.162773202238341],
            [20.519163312220172, 41.246382183732877],
            [20.502500256298873, 41.304444968295869],
            [20.498854128407345, 41.341800093367979],
            [20.524163285116316, 41.350273191673736],
            [20.550000166450616, 41.37305403110166],
            [20.561873299969932, 41.408673096383367],
            [20.508609154988164, 41.459718048674077],
            [20.470691268935298, 41.499300077788078],
            [20.458818135416095, 41.520827152135709],
            [20.466109217732651, 41.5555449951854],
            [20.533882269513413, 41.634718105868998],
            [20.517773257393628, 41.744027008399527],
            [20.533609187107231, 41.776936036738917],
            [20.568954164154235, 41.874544975870279],
            [20.589645059838688, 41.88219111560629],
            [20.618954060754078, 41.861518157194595],
            [20.707782285732407, 41.856391114645945],
            [20.736945106256115, 41.868118067773437],
            [20.777563306243593, 41.937391144950723],
            [20.759854021165012, 41.985618101386009],
            [20.770836158374237, 42.046800127405433],
            [20.795409216344268, 42.083118075775886],
            [20.915000199528919, 42.11999107377595],
            [21.110836159333473, 42.200691199584284],
            [21.136454105356307, 42.200000195484577],
            [21.218191240228435, 42.147636099268496],
            [21.305554140889512, 42.149582209553145],
            [21.346245095796746, 42.194300166033301],
            [21.472636149408487, 42.265973149734478],
            [21.619718098704737, 42.254164221593712],
            [21.681391136613541, 42.239718011430497],
            [21.707773176932022, 42.232491134492463],
            [21.729854127442906, 42.235136127865857],
            [21.788954088707754, 42.268264259154805],
            [21.803191254205217, 42.298745050137498],
            [21.850691164357073, 42.330344992835293],
            [22.023609156797335, 42.316936127011942],
            [22.075209158717854, 42.300973127645861],
            [22.196182150101919, 42.31639113566591],
            [22.21687321342435, 42.336136049755353],
            [22.325345099101554, 42.358954104833558],
            [22.365273300817705, 42.323882210192821],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MLI", Country: "Mali" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.245282173956241, 19.146664088604396],
            [4.245282173956241, 19.072500170910544],
            [4.245836050119664, 18.866391182907265],
            [4.245554082896035, 18.660553936205744],
            [4.245000206732698, 18.645273223760043],
            [4.24666317632736, 17.998054007061896],
            [4.248609118973917, 17.648054060310344],
            [4.249718212405242, 17.476109039193418],
            [4.251109105422103, 17.292009084971667],
            [4.251391240283709, 16.994027061834046],
            [4.221109100406892, 16.915000131542357],
            [4.198891189597703, 16.820827102170185],
            [4.199582193697324, 16.746664190304713],
            [4.200836126415965, 16.393891174698794],
            [4.179582134474543, 16.384236060404064],
            [4.145282213118378, 16.369718101149473],
            [4.114163224026214, 16.348054066503622],
            [4.096391242311682, 16.330691121665282],
            [4.072082214292436, 16.298609051895411],
            [3.980836145517372, 16.070273100627574],
            [3.950282264338739, 15.941944989186581],
            [3.902218084463613, 15.748609015829985],
            [3.895000092342968, 15.727500030813943],
            [3.885000146550567, 15.709164114652296],
            [3.881945110472685, 15.706664128204196],
            [3.846391256397879, 15.674164136740913],
            [3.818054053339353, 15.664027063012227],
            [3.779309208716853, 15.661244941702435],
            [3.733263223493907, 15.654864134073236],
            [3.567218056400606, 15.512218055588008],
            [3.535554075962352, 15.429164123723368],
            [3.530836070289809, 15.398335986672137],
            [3.518100103655257, 15.359909151456904],
            [3.307054007029791, 15.392382153191861],
            [3.032500241523337, 15.433053997359892],
            [3.022782262954649, 15.414027077098424],
            [3.011809178200906, 15.341809102051215],
            [2.860000170776601, 15.344718125375508],
            [2.628054133800958, 15.350553941658703],
            [2.510554100188386, 15.345000092599022],
            [1.97416325743265, 15.318891134686922],
            [1.884718124378963, 15.315273169990107],
            [1.312500177504262, 15.286664225629821],
            [1.085554113424848, 15.079444970728858],
            [0.994445172586069, 14.99528211307107],
            [0.974718195769469, 14.978608998865994],
            [0.938891259288482, 14.977500073072733],
            [0.77833607115241, 14.968609053073564],
            [0.731109075768785, 14.960836011323053],
            [0.695000172064169, 14.942218127937778],
            [0.653891127825347, 14.955554071203281],
            [0.618609182690449, 14.967500127280331],
            [0.512218188301432, 15.000827077313247],
            [0.450554035210047, 14.978608998865994],
            [0.396663259145186, 14.960554044099425],
            [0.334163262666692, 14.975553962787998],
            [0.280836085772989, 14.988609112296373],
            [0.237773219898571, 15.000009003561033],
            [0.232218197372504, 14.950282189367314],
            [0.235045078045346, 14.915064114334697],
            [0.00000008381906, 14.994373179488193],
            [-0.246390822215346, 15.077500033910596],
            [-0.386663816267003, 15.005282058863386],
            [-0.443608790219429, 15.082773089213035],
            [-0.72314593245369, 15.08236421997502],
            [-0.725281809026797, 15.082773089213035],
            [-1.003026732719121, 14.840100060161532],
            [-1.074163945167896, 14.77694510176714],
            [-1.093890921984411, 14.784164099716165],
            [-1.318890875779147, 14.728609012952191],
            [-1.36184595037858, 14.701354081234641],
            [-1.678336724521785, 14.500554023079545],
            [-1.777781803367901, 14.481109013486545],
            [-1.907499801997346, 14.488609140468881],
            [-1.980836761121594, 14.47471814757354],
            [-1.984163873777277, 14.410273214276785],
            [-1.989445981535226, 14.376945090777483],
            [-1.992499844146636, 14.342500162496023],
            [-1.996663973655842, 14.308054060748091],
            [-2.006945886671872, 14.187773079292143],
            [-2.103717808949796, 14.152627088627057],
            [-2.107499891311136, 14.151391093181317],
            [-2.244999816508511, 14.21778213676275],
            [-2.289445864048844, 14.238336072148982],
            [-2.384163884766934, 14.265553955854315],
            [-2.465136757705295, 14.286109064706935],
            [-2.599717768932692, 14.211664185617863],
            [-2.646663970559189, 14.161945082775148],
            [-2.815554961426642, 14.05028220655025],
            [-2.839999776277693, 14.005554024148196],
            [-2.903754878941129, 13.822364176975825],
            [-2.904445883040836, 13.721664159582758],
            [-2.882217746309692, 13.664300090470419],
            [-2.942217756340114, 13.633054031725678],
            [-2.958317883642479, 13.629164158089139],
            [-3.099445999458482, 13.68610913204158],
            [-3.211108875683379, 13.708053960444559],
            [-3.257499860041975, 13.696664127463791],
            [-3.273336795583987, 13.550282067084666],
            [-3.260281813713675, 13.496391123381812],
            [-3.237217833595565, 13.359717989115964],
            [-3.233890888577861, 13.322500159618215],
            [-3.232217860699421, 13.288054057870283],
            [-3.373890800223336, 13.277218101052839],
            [-3.394163941852327, 13.276664224889416],
            [-3.426099831230317, 13.276109007621528],
            [-3.442699855239567, 13.261027113919695],
            [-3.432317862299357, 13.23538217816855],
            [-3.424445914091166, 13.21139115955674],
            [-3.42713667265599, 13.18417310821323],
            [-3.437672892615069, 13.166500032956478],
            [-3.448845969579168, 13.171353993098919],
            [-3.508336695171124, 13.178891168093458],
            [-3.538054900600713, 13.178335950825556],
            [-3.588608841002156, 13.202773221963767],
            [-3.616945876422619, 13.223609124573514],
            [-3.785726729081972, 13.359064200666452],
            [-3.903263978344768, 13.448473123898452],
            [-3.956254873282148, 13.50139109627797],
            [-3.96425489697134, 13.503827044985599],
            [-3.976108752113134, 13.476109096935389],
            [-4.15659979409358, 13.278191072376174],
            [-4.191945944607085, 13.275973220789709],
            [-4.236945868310755, 13.240836114942041],
            [-4.313608766624384, 13.164309003460332],
            [-4.335554936131842, 13.119509071966903],
            [-4.277217728058105, 13.021664098441477],
            [-4.255345989851065, 12.996454015828277],
            [-4.217636813187681, 12.969582137086903],
            [-4.207363952627162, 12.948473152070946],
            [-4.194445931055157, 12.828609086480114],
            [-4.20652676813566, 12.769164126079602],
            [-4.221108765130793, 12.735282126416877],
            [-4.263199833148377, 12.717782053642154],
            [-4.288890869367037, 12.715000099970467],
            [-4.468890899458103, 12.723891119969636],
            [-4.470281792474879, 12.713609039315614],
            [-4.474926707951568, 12.665273118138742],
            [-4.453263846772188, 12.638126983524685],
            [-4.428754994180679, 12.629027086497842],
            [-4.370626830772693, 12.528744990798302],
            [-4.397081793648937, 12.504864110394379],
            [-4.418608867996483, 12.472636027870891],
            [-4.436108773133128, 12.429300079590263],
            [-4.417499774565243, 12.300827128861954],
            [-4.482499757491752, 12.274718003311676],
            [-4.569445909925776, 12.201109135247634],
            [-4.624863868753465, 12.115409036543127],
            [-4.630417885451038, 12.093327080203849],
            [-4.633426821061391, 12.067218122291649],
            [-4.698608858925411, 12.062773199025315],
            [-4.942781913373324, 12.008609005278089],
            [-5.079717736485406, 11.975553964184996],
            [-5.117499835706525, 11.964445092599433],
            [-5.147081919028238, 11.952773124756973],
            [-5.168336749159891, 11.93555401337747],
            [-5.273054883308447, 11.843891028737289],
            [-5.287499920005189, 11.788053974749687],
            [-5.268608786575641, 11.686945088118605],
            [-5.223890830095485, 11.588817979731473],
            [-5.201663866830785, 11.538191116772282],
            [-5.208608776545219, 11.461664172928636],
            [-5.24784597416172, 11.252636103317386],
            [-5.299445976082268, 11.139445038501265],
            [-5.321945854115143, 11.120135983378177],
            [-5.362636809022376, 11.101391030340309],
            [-5.394163829162352, 11.094444947159403],
            [-5.463608902993258, 11.086944987815173],
            [-5.485626821592177, 11.077291046986829],
            [-5.486526870357665, 11.033473139272232],
            [-5.479446006173333, 11.002218028072079],
            [-5.443336767192648, 10.897218094337987],
            [-5.419163861281305, 10.869444993364667],
            [-5.411181942503106, 10.8416641810404],
            [-5.444726822019106, 10.763335966199335],
            [-5.456208855935103, 10.721653935057859],
            [-5.464308791910383, 10.699309122234183],
            [-5.473336772207773, 10.632500156959338],
            [-5.483608794577975, 10.52721808836354],
            [-5.519845941401542, 10.436273097551393],
            [-5.565690928585667, 10.457218132540902],
            [-5.669236769752786, 10.445973138847364],
            [-5.701808845583599, 10.430000081197392],
            [-5.726108821147363, 10.422782089076762],
            [-5.746390847593858, 10.423335965240099],
            [-5.782308811543516, 10.426427043501803],
            [-5.797499837625026, 10.42124501566812],
            [-5.882217744912708, 10.369718103943541],
            [-5.937499916908564, 10.284444979387871],
            [-5.96402679651419, 10.237636073335835],
            [-5.977363913246165, 10.216527088319793],
            [-6.004163875258087, 10.191245088977212],
            [-6.090281895656034, 10.190836052101119],
            [-6.111390880672161, 10.197773082826615],
            [-6.145554847558401, 10.211945037117061],
            [-6.188336752037713, 10.231664134944737],
            [-6.197163901934431, 10.236364203344465],
            [-6.17999977583986, 10.364718131047198],
            [-6.180281910701638, 10.403053938793775],
            [-6.181663918900995, 10.434718086870021],
            [-6.183054979555919, 10.463608998453907],
            [-6.212499767303143, 10.569444943212943],
            [-6.189099840504866, 10.636526990894168],
            [-6.22440877536809, 10.722909041242886],
            [-6.243399821083926, 10.735254075912252],
            [-6.413545918136293, 10.687982153527457],
            [-6.417081907819664, 10.623127009888208],
            [-6.388054874127874, 10.594126965924659],
            [-6.421908878233864, 10.551664076681092],
            [-6.533054926307472, 10.576109059170321],
            [-6.58930889616019, 10.610000111288457],
            [-6.601808828400777, 10.63729108518973],
            [-6.621599842957721, 10.660900056653858],
            [-6.645599914025041, 10.663991134915477],
            [-6.669163958488014, 10.628191188162802],
            [-6.676663917832343, 10.59832696997951],
            [-6.684999888201702, 10.491664234288848],
            [-6.669236713407798, 10.460691090312309],
            [-6.650281877513947, 10.452773209274554],
            [-6.631945961352187, 10.433609161076788],
            [-6.650836759505751, 10.360973096698075],
            [-6.656408881114231, 10.354064229167378],
            [-6.719717731251279, 10.350554055745761],
            [-6.94083669622691, 10.353745046293554],
            [-6.96049091812381, 10.333536110042999],
            [-6.981045859338451, 10.247845063793989],
            [-6.955417855031641, 10.225826977557077],
            [-6.945417741601318, 10.208191117950435],
            [-6.948336823209303, 10.177500108835602],
            [-6.968608791371821, 10.155973202126049],
            [-6.988054974431293, 10.147500103820391],
            [-7.019445872463365, 10.142153958322183],
            [-7.039581886156071, 10.156244943427765],
            [-7.061317837531277, 10.189864086606178],
            [-7.078608865640263, 10.203335984341592],
            [-7.128054886076683, 10.223054076340787],
            [-7.183890934235961, 10.236664107840895],
            [-7.269717767316791, 10.25444497437276],
            [-7.348199873900654, 10.327082044579953],
            [-7.35972683481782, 10.350826970513879],
            [-7.451663907692591, 10.39721812251058],
            [-7.63799093359421, 10.446664142947085],
            [-7.703190908731131, 10.403327021199971],
            [-7.731945865845034, 10.377218063287771],
            [-7.75471782045571, 10.330827078929161],
            [-7.756599893000015, 10.296109068241407],
            [-7.787781914004313, 10.245282045433783],
            [-7.82645484189743, 10.202364186484743],
            [-7.88916388304159, 10.173891028956291],
            [-7.939445914503239, 10.157082127919281],
            [-7.96375494252257, 10.159864081590968],
            [-7.973981870253454, 10.165609038043399],
            [-7.942017817680778, 10.242982051196051],
            [-7.976454866973313, 10.328818104370455],
            [-8.14110880577357, 10.423891182507987],
            [-8.186526818808858, 10.416527009995491],
            [-8.218054844777214, 10.425691112400855],
            [-8.269999845833496, 10.502500023468201],
            [-8.281945901910547, 10.549445051628226],
            [-8.321390803088264, 10.760827094933845],
            [-8.28749991860812, 10.849444934141857],
            [-8.289717770194585, 11.007773218236139],
            [-8.346072825799865, 11.053264153829275],
            [-8.458054884898701, 11.050836084110571],
            [-8.511108811748016, 11.005554025545194],
            [-8.583608754018826, 10.972773072686692],
            [-8.671526871947805, 10.958918121975088],
            [-8.679790925587696, 11.002500162933785],
            [-8.609999846792732, 11.127218021028895],
            [-8.477426804295646, 11.288400175524416],
            [-8.451663851347519, 11.291945050025291],
            [-8.431390877356591, 11.280973138737949],
            [-8.393890912997136, 11.279718032552921],
            [-8.368963971074038, 11.287427036563003],
            [-8.356108813776103, 11.314164134300952],
            [-8.362845852291144, 11.375118004915308],
            [-8.39055491552395, 11.392773142899145],
            [-8.46062678807624, 11.419791034394237],
            [-8.495972938589631, 11.420553955223411],
            [-8.516526873975778, 11.434718030524934],
            [-8.533881939825108, 11.493909019258552],
            [-8.547781817537839, 11.480000089090311],
            [-8.57778182255305, 11.470000143297909],
            [-8.604717739034868, 11.475136070664064],
            [-8.638054914989652, 11.497218027003342],
            [-8.656663913557537, 11.520418129229526],
            [-8.672499843271225, 11.556664160870426],
            [-8.689417876687713, 11.602473105870814],
            [-8.694726806535812, 11.632218133390637],
            [-8.705972806057844, 11.655973117608553],
            [-8.737017866763836, 11.638608999303642],
            [-8.773890864763899, 11.63986410548867],
            [-8.831663970752345, 11.661664094604433],
            [-8.792781830555469, 11.85666421102205],
            [-8.779726848685186, 11.925836034808768],
            [-8.796899859597062, 12.00634505322418],
            [-8.936808743773781, 12.191244976287138],
            [-8.96208185829903, 12.195691073019873],
            [-8.980445937655389, 12.22961799968374],
            [-8.977499866319079, 12.27847309594479],
            [-8.95583683750155, 12.315553965144233],
            [-8.943608814200871, 12.334718013342098],
            [-8.948336710519328, 12.354718072564808],
            [-8.979999852767179, 12.392773086554158],
            [-9.056181797475148, 12.435000109041638],
            [-9.084726871733068, 12.437500095489625],
            [-9.153054973143725, 12.485135960111378],
            [-9.253054933981673, 12.499718124744504],
            [-9.311672935812652, 12.504700160367932],
            [-9.35986385006413, 12.488473131050966],
            [-9.400281890203161, 12.452144956758815],
            [-9.3458367350608, 12.40638211222587],
            [-9.294999821607462, 12.362218031909137],
            [-9.301990831789482, 12.312809059484664],
            [-9.31847283860165, 12.268054054992561],
            [-9.3458367350608, 12.252773174908782],
            [-9.37055496759433, 12.250273188460611],
            [-9.39805498616127, 12.254445029320763],
            [-9.580281920294226, 12.188053985739344],
            [-9.6283367124378, 12.165553940068463],
            [-9.644581846665574, 12.153745011927697],
            [-9.664026856258573, 12.131664229054806],
            [-9.670281935339744, 12.111109120202102],
            [-9.667217846806352, 12.081800119286697],
            [-9.701945915777912, 12.029164114130708],
            [-9.757917750768939, 12.027773221113947],
            [-9.86521784637884, 12.054809049881854],
            [-9.911945950884103, 12.093327080203849],
            [-10.098754936219677, 12.179027011270364],
            [-10.327090887487628, 12.223536090722661],
            [-10.330908844394799, 12.195764163215813],
            [-10.357226846972651, 12.166382072104568],
            [-10.452917839014162, 12.11708206442168],
            [-10.562781791174501, 11.994445097614559],
            [-10.652745931674303, 11.892608997062041],
            [-10.684999830459617, 11.898054049018114],
            [-10.727990779604681, 11.919164207500543],
            [-10.743336703257285, 11.963264082438755],
            [-10.814717829089062, 12.112773095625244],
            [-10.888545967740754, 12.197636009838234],
            [-10.909726869486235, 12.215827086712579],
            [-10.928890917684015, 12.224445024305552],
            [-10.958199918599519, 12.226108999728595],
            [-11.045690894741455, 12.204582093019042],
            [-11.101108853569059, 12.117500153753284],
            [-11.150690828475462, 12.044027072521061],
            [-11.1704729582151, 12.027182129300314],
            [-11.177426752747039, 12.016944975647448],
            [-11.243054875499183, 11.995209024272228],
            [-11.319581819343, 12.025691156359386],
            [-11.340054953182261, 12.046800141375428],
            [-11.374999778170491, 12.104791176847044],
            [-11.413336759383469, 12.12000014020127],
            [-11.449390845441116, 12.130235952749757],
            [-11.459445944156442, 12.136109152321239],
            [-11.494163954844197, 12.176382185534962],
            [-11.49471783100762, 12.206109108143878],
            [-11.481454977937972, 12.227636014853346],
            [-11.450699763444703, 12.244436031072951],
            [-11.383054954782722, 12.38332701692508],
            [-11.373054841352229, 12.407773172880709],
            [-11.373608885153743, 12.447773123688322],
            [-11.408890830288726, 12.535000069879487],
            [-11.416946006900844, 12.569444998160947],
            [-11.424163831383481, 12.656109015733463],
            [-11.3949998373933, 12.831945083953315],
            [-11.369926715078492, 12.932218127197359],
            [-11.376108871601815, 12.982918080352505],
            [-11.463336823621376, 13.085836116970029],
            [-11.51513681775225, 13.112082202818613],
            [-11.52889085034937, 13.140136097549075],
            [-11.556181824250643, 13.212426995154033],
            [-11.539372923213733, 13.254718055381957],
            [-11.552226739407274, 13.282773123578906],
            [-11.608054908577458, 13.358327096099202],
            [-11.634581955821176, 13.39152697647954],
            [-11.716254885314868, 13.412500174663748],
            [-11.740972950210192, 13.409027049254263],
            [-11.758608809816735, 13.364718129650299],
            [-11.809517807637548, 13.309273181094383],
            [-11.887290805210824, 13.381600120883164],
            [-11.878199793001301, 13.420418055701603],
            [-11.96549077693291, 13.528127020549533],
            [-11.990836814016006, 13.556391133412134],
            [-12.034446012341022, 13.610835953278411],
            [-12.057217799313634, 13.664718012163931],
            [-12.064446017356232, 13.695554028204171],
            [-12.041390922055342, 13.728609069297178],
            [-12.009999856385292, 13.746664191701811],
            [-11.989445920999259, 13.761245015230372],
            [-11.967781886353407, 13.78166416961308],
            [-11.955699875806346, 13.800973224736168],
            [-11.947781827130513, 13.830273173196161],
            [-11.94249988701074, 13.85889117001193],
            [-11.941663875985626, 13.88360906726929],
            [-11.943336736226087, 13.906809001857297],
            [-11.958754911884228, 13.922773174689766],
            [-11.986663967327473, 13.943745031769524],
            [-12.007917791630746, 13.96624507744049],
            [-12.014026857958214, 14.018473219186674],
            [-12.004446007325726, 14.06180899982941],
            [-11.97999985137028, 14.151391093181431],
            [-11.982226755412057, 14.172782045420988],
            [-12.030345920572159, 14.277500179569458],
            [-12.150836784884177, 14.374164142934191],
            [-12.206399918275366, 14.395291065223134],
            [-12.22569992094293, 14.505282087035894],
            [-12.216945861241982, 14.53971813050002],
            [-12.178608880029117, 14.6075000670984],
            [-12.244163912585492, 14.750554008993348],
            [-12.244836811774235, 14.764382137614618],
            [-12.17499980014972, 14.768326996536175],
            [-12.09430889443496, 14.739582097705906],
            [-12.059963878439589, 14.725635951887554],
            [-11.974308874374316, 14.771527039539464],
            [-11.866945914490344, 14.840827106445076],
            [-11.841390832741666, 14.863054069709662],
            [-11.815281874829367, 14.925000022386669],
            [-11.796317818841942, 15.014791160404528],
            [-11.812217786295975, 15.050345014479305],
            [-11.840881883579385, 15.046900052264434],
            [-11.846672940499417, 15.097218125909777],
            [-11.832226730336203, 15.195273149929548],
            [-11.795281815606671, 15.321109153911479],
            [-11.754999897575431, 15.438609019885874],
            [-11.740626777608071, 15.47742712234249],
            [-11.716181795119013, 15.498682120112235],
            [-11.710836823087106, 15.525835966077224],
            [-11.711672834112136, 15.545000181912982],
            [-11.639863895941033, 15.525282089913802],
            [-11.599717764741712, 15.54555405807632],
            [-11.537781870348539, 15.596391139167665],
            [-11.498608878110645, 15.641800099747527],
            [-11.455281814647321, 15.643327114872378],
            [-11.418126849423544, 15.633473181833679],
            [-11.365136792676395, 15.575691191027815],
            [-11.337226731404854, 15.524445073060278],
            [-11.3022267534935, 15.475000058452181],
            [-11.286663906186106, 15.455554043030773],
            [-11.253890832316614, 15.431391195403236],
            [-11.236945976809778, 15.420000021317989],
            [-11.19999988861386, 15.394164145812098],
            [-11.172499870046636, 15.374718130390718],
            [-10.993054889585466, 15.242773227719297],
            [-10.984999880611326, 15.223053962253729],
            [-10.973890841387799, 15.198891114626278],
            [-10.914445880987188, 15.124718144477001],
            [-10.896563928702761, 15.110364135248844],
            [-10.848054837405869, 15.206109106746894],
            [-10.764308895613112, 15.311391175342592],
            [-10.73458180536602, 15.341391180357888],
            [-10.719445932207634, 15.374445047984509],
            [-10.716390896129667, 15.438900039564984],
            [-10.67506392240773, 15.432127126504199],
            [-10.649999852548348, 15.430273217154692],
            [-10.604999761206585, 15.428054024463748],
            [-10.567426874289282, 15.432291076530717],
            [-10.469999822457282, 15.437218126869098],
            [-10.310699908144215, 15.441244960803957],
            [-10.289726877598099, 15.437918015786252],
            [-10.212781844422864, 15.408053965240853],
            [-10.118054938887354, 15.372773193572343],
            [-10.086946008078996, 15.368609064063136],
            [-10.060554915305119, 15.366945088640179],
            [-10.032781814331798, 15.366945088640179],
            [-9.81139094041643, 15.374718130390718],
            [-9.77826398259387, 15.386245091307771],
            [-9.746255003019968, 15.405553978792867],
            [-9.69666397565814, 15.430000134748397],
            [-9.660836703900998, 15.43110906054163],
            [-9.409726786365752, 15.444309049337235],
            [-9.415281808891706, 15.478891105555093],
            [-9.442499860235216, 15.596945015331087],
            [-9.338054976131048, 15.704718017919461],
            [-9.311808890282464, 15.677500134214128],
            [-9.332499785966888, 15.563609012842861],
            [-9.333608879398128, 15.499718123347506],
            [-9.226390926439564, 15.498609029916295],
            [-9.184672853114364, 15.498218097951096],
            [-9.090554976665317, 15.498891164777987],
            [-9.036946000185992, 15.498336115148177],
            [-8.996390832110677, 15.498336115148177],
            [-8.585281949535471, 15.501109016364367],
            [-8.513890933057894, 15.501391151225974],
            [-8.505090772889304, 15.500827049140739],
            [-8.051108790728051, 15.501391151225974],
            [-8.001390861351808, 15.500827049140739],
            [-7.66221781896212, 15.503336088044236],
            [-7.501108754662624, 15.505000063467278],
            [-7.358336779991276, 15.505836074492308],
            [-7.018890822833555, 15.50721808269175],
            [-6.658608795427796, 15.505836074492308],
            [-6.42639084951233, 15.504445013837469],
            [-6.31916384409837, 15.503609002812439],
            [-6.307981882316881, 15.502782211880898],
            [-5.958890869148121, 15.504445013837469],
            [-5.583608755415923, 15.499445040941325],
            [-5.495054953948255, 15.498373163160196],
            [-5.493608740370291, 15.515554053061109],
            [-5.483890929439582, 15.567218092722257],
            [-5.420281839529935, 15.894445079034725],
            [-5.386108820188213, 16.06639110598006],
            [-5.3724997945165, 16.135273083554139],
            [-5.33499983015696, 16.328054007280812],
            [-5.601390795414261, 16.507773075976573],
            [-5.628054970594349, 16.748609127123089],
            [-5.694446014175668, 17.35027320284955],
            [-5.747781908248697, 17.831109126479291],
            [-5.782781886160166, 18.15250012887067],
            [-5.803336827374608, 18.341391178960905],
            [-5.837781923294244, 18.650000114250091],
            [-5.853890935414029, 18.787218072223752],
            [-5.884445990059049, 19.061664214093085],
            [-5.901108878342257, 19.21582702757361],
            [-5.918336706901101, 19.37000006697609],
            [-5.956663965106344, 19.712500054383398],
            [-5.974163870242876, 19.866391126562277],
            [-6.006663861706272, 20.149164186345459],
            [-6.055281917744736, 20.559445104522652],
            [-6.077499828553925, 20.747218008726165],
            [-6.107217866345536, 20.999445066319836],
            [-6.132226783281908, 21.221109022641471],
            [-6.153054974540709, 21.408882094482962],
            [-6.174445926780379, 21.596382083918272],
            [-6.208336811260409, 21.88582713864777],
            [-6.226108792974856, 22.03916416702522],
            [-6.298890870107357, 22.668609055075976],
            [-6.319726772717189, 22.855553994881362],
            [-6.325836677235003, 22.906391075972905],
            [-6.335954975500471, 22.99026408741814],
            [-6.366945889111832, 23.254717994845905],
            [-6.441799805077011, 23.878764099046279],
            [-6.475281820319026, 24.161391146075758],
            [-6.511108756800013, 24.458054025287893],
            [-6.523890823902207, 24.562773165264943],
            [-6.533336725893093, 24.632500038681385],
            [-6.577217833157817, 24.999164214820709],
            [-6.500281852438093, 25.00000005820776],
            [-6.366945889111832, 25.00000005820776],
            [-6.299717828676989, 24.999445008577837],
            [-6.133890926343042, 24.999445008577837],
            [-5.767217865386186, 25.00000005820776],
            [-4.966663967243647, 24.999445008577837],
            [-4.866663838767721, 24.999445008577837],
            [-4.80610877910749, 25.000273140613857],
            [-4.525281829608957, 24.825000168650931],
            [-4.123054914577494, 24.571109135634217],
            [-3.79221784255725, 24.360273089502996],
            [-3.462781889475536, 24.148891046197278],
            [-3.233772871380779, 24.000536053443156],
            [-2.824981920316219, 23.734718075088679],
            [-2.448336741485718, 23.487218075623062],
            [-2.089999818536398, 23.250000156811538],
            [-1.750554867206972, 23.023327007500072],
            [-1.412217835842398, 22.795553984851139],
            [-1.142781892791191, 22.612782059372179],
            [-0.773890812609892, 22.361109045580022],
            [-0.42333682205674, 22.119718112441788],
            [-0.107499836363132, 21.900553974930133],
            [-0.008054925154994, 21.830554019107396],
            [0.01555404630912, 21.815273139023631],
            [0.149718141671286, 21.733054044717633],
            [0.267218175283858, 21.660827017214942],
            [0.401109188239928, 21.578327129151816],
            [0.768336125360008, 21.351664205762418],
            [1.170800248252107, 21.10085402508544],
            [1.185554074262711, 21.057782106755511],
            [1.194863183593469, 21.022773076388731],
            [1.197145072920307, 21.002482165124903],
            [1.19250015744359, 20.937500119471238],
            [1.186391258754213, 20.900554031275121],
            [1.181391118219977, 20.877500109440902],
            [1.170763200239975, 20.840554021244898],
            [1.160282133204021, 20.800135981105782],
            [1.163400201193951, 20.754854090178483],
            [1.176391145323805, 20.733745105162441],
            [1.351391202518641, 20.68194494339339],
            [1.478336132293691, 20.64139111642244],
            [1.518891132731142, 20.626664112502183],
            [1.577918171438114, 20.599864150490177],
            [1.62750014634463, 20.57110902573821],
            [1.661663275040553, 20.538191112581401],
            [1.671945020418491, 20.464718031349165],
            [1.668400145917616, 20.415618015876632],
            [1.715000174941991, 20.37000017826908],
            [1.789718136437358, 20.31290902392486],
            [1.810282130107282, 20.301109148239689],
            [1.900282145152829, 20.259718136777238],
            [2.090836164837611, 20.218745047008198],
            [2.129163255404762, 20.236382080081327],
            [2.173891102530746, 20.276527037814148],
            [2.203609140322243, 20.283054025835057],
            [2.227500246648049, 20.27416417930236],
            [2.275763245266972, 20.242009019336663],
            [2.320000248141667, 20.203053956582025],
            [2.344163263407097, 20.177773130705745],
            [2.355282193276651, 20.161109068956236],
            [2.376663254870493, 20.126945102069797],
            [2.386391124085009, 20.108891153131751],
            [2.405900171418438, 20.065554031384622],
            [2.421663178574164, 20.05305393150617],
            [2.514863236623, 20.022218083103908],
            [2.587782274053609, 20.00166414771779],
            [2.702636140826314, 20.002500158742819],
            [2.902218140808685, 19.966944963563535],
            [3.006109148749545, 19.927773144792027],
            [3.028609194420511, 19.917773198999626],
            [3.233054039362543, 19.817145098336127],
            [3.230836187776163, 19.737500086502465],
            [3.223054093570141, 19.623336050362909],
            [3.212500103976424, 19.584164231591487],
            [3.211527132653089, 19.556664213024348],
            [3.248400130653152, 19.445973134656398],
            [3.270418216890164, 19.425691108209961],
            [3.278054130704305, 19.405827003456992],
            [3.272573204202587, 19.37673609966275],
            [3.191109151736669, 19.256109113242829],
            [3.146391195256598, 19.20194508713378],
            [3.125836086403979, 19.180000091092623],
            [3.117009104145353, 19.145553989344776],
            [3.137363214959123, 19.117082172920689],
            [3.183891159616138, 19.078609069599835],
            [3.331945074407258, 18.976391089537529],
            [3.733336146051755, 19.051664100662705],
            [3.909100129904147, 19.084809163396173],
            [3.952782250786925, 19.093053938658684],
            [4.080836106355321, 19.116664083589185],
            [4.245282173956241, 19.146664088604396],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MLT", Country: "Malta" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.057794556000118, 34.766805283000039],
            [18.027286517000164, 34.731723384],
            [17.960890517000081, 34.656211384000116],
            [17.934434517000113, 34.626114384000047],
            [17.90684051700012, 34.594463384000122],
            [17.883878517000142, 34.568085384000128],
            [17.839593517000083, 34.517395383999983],
            [17.82425751700012, 34.499913384000095],
            [15.166666514000013, 34.498055384000011],
            [14.818611514000111, 34.52222238399996],
            [14.623333514000137, 34.538333384000012],
            [14.524722514000189, 34.551944383999981],
            [14.398333513000125, 34.568611384000022],
            [14.260277513000176, 34.588889384000069],
            [14.087222513000142, 34.617222384000101],
            [14.070833513000082, 34.619722384000099],
            [13.935833513000034, 34.654444384],
            [13.875277513000185, 34.669444384000073],
            [13.833333513000099, 34.679444384000035],
            [13.661269918000016, 35.225193438000176],
            [13.651195750000028, 35.285918059000025],
            [13.644508902000098, 35.325951171000114],
            [13.616807213000186, 35.492847207000111],
            [13.617677457000156, 35.505524398],
            [13.616659588000033, 35.541250933000171],
            [13.594696623000118, 35.645964207000176],
            [13.553824265000145, 35.839607506000092],
            [13.550677918000048, 35.854327651000077],
            [13.530345840000166, 35.952416815],
            [13.515278525000127, 36.028235606],
            [13.514282154000114, 36.032435330000126],
            [13.505903826000122, 36.068544050000114],
            [13.504239061000135, 36.075625441000099],
            [13.461492806000109, 36.258578031999988],
            [13.449657873000092, 36.30919392600002],
            [13.412759147, 36.465253686000139],
            [13.4270241910001, 36.469055812000036],
            [13.656032307000089, 36.514327266000109],
            [13.699784107, 36.522583395000126],
            [13.747095765000125, 36.531073987],
            [13.873190590000092, 36.551343865999982],
            [14.00084075850009, 36.521422191500093],
            [14.074912972000106, 36.504953272000151],
            [14.096853149000111, 36.499219463000102],
            [14.13359236200003, 36.490052285000118],
            [14.223697426000172, 36.467967499000153],
            [14.225397319000137, 36.467551331999985],
            [14.227097212000103, 36.46713920500018],
            [14.229389342500184, 36.466585871000134],
            [14.232496815000104, 36.465838743000049],
            [14.234996826000099, 36.465245070000137],
            [14.237896896000166, 36.464560596000169],
            [14.271697259000121, 36.456707825000038],
            [14.311280969000137, 36.447430140000151],
            [14.338821141000011, 36.441164911],
            [14.419803748000135, 36.423093152999982],
            [14.466138127000022, 36.402658350000124],
            [14.472461287999977, 36.400177159],
            [14.500979756000163, 36.389569521000013],
            [14.522724522000061, 36.381537152000035],
            [14.525321727000119, 36.380569898000019],
            [14.623120404000076, 36.350484355000034],
            [14.79927433600011, 36.271968926000042],
            [14.889609454000095, 36.231957539],
            [15.02580488300012, 36.17278320399997],
            [15.079958236000095, 36.149408451000127],
            [15.18457282300011, 36.104064702000031],
            [15.28698633800019, 36.060159929999983],
            [15.525269859000161, 35.958746876],
            [15.749363091000191, 35.855442880999973],
            [16.303425252000068, 35.59760636],
            [17.217497869000169, 35.167177449500016],
            [17.673670125000172, 34.95236987],
            [17.821204195000178, 34.881098437],
            [18.057794556000118, 34.766805283000039],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MMR", Country: "Myanmar" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [101.148236230033291, 21.572635984517873],
            [101.162136275384086, 21.536391126343361],
            [101.127200335213189, 21.49082710055437],
            [101.100818294894765, 21.465409146741891],
            [101.001363325402934, 21.398327099060751],
            [100.862482230196548, 21.316664227851149],
            [100.83915422012754, 21.304300082442396],
            [100.81817230476409, 21.299582076769852],
            [100.774372334322294, 21.300973137424691],
            [100.727209209041035, 21.307809082397526],
            [100.703172257599505, 21.227500056192568],
            [100.699636267916134, 21.182636086958681],
            [100.671918152227732, 21.128053971518042],
            [100.624563416639404, 21.054718018222118],
            [100.602909272639266, 21.038609006102419],
            [100.570682363582279, 21.032082185719403],
            [100.54677231415522, 21.026109073861832],
            [100.520682299344259, 20.951936103712669],
            [100.521718302579615, 20.876664098415787],
            [100.543872343286438, 20.867773078416619],
            [100.575545208542138, 20.876664098415787],
            [100.610536133998011, 20.888609148664543],
            [100.642527176299069, 20.88489110404366],
            [100.64054519146859, 20.861736096456795],
            [100.617863426136381, 20.840453941320632],
            [100.604709202532177, 20.833882193936674],
            [100.560536237398054, 20.813891187169261],
            [100.5261001939339, 20.804582077838504],
            [100.496936199943804, 20.80582712573964],
            [100.462200251983262, 20.813054002677674],
            [100.441654195586153, 20.818327057980113],
            [100.371718277503788, 20.826182074744011],
            [100.290545244717038, 20.771664164681781],
            [100.258745142170795, 20.74902699107453],
            [100.209436249670688, 20.69305398261703],
            [100.172763411518844, 20.62790916040332],
            [100.149427187184784, 20.544718100602395],
            [100.141372345848794, 20.489436096244518],
            [100.125672370605031, 20.401245063547336],
            [100.11261822692515, 20.37569115526496],
            [100.091372281610717, 20.348609058391432],
            [100.084400214529893, 20.348636048119673],
            [100.019991323417031, 20.383327069079101],
            [99.986091218843484, 20.415964188478867],
            [99.980818163541045, 20.435554037358983],
            [99.962627254304692, 20.454582131086866],
            [99.873663410132536, 20.428882210050759],
            [99.851382299773292, 20.404718021318857],
            [99.834436270800069, 20.364164194347993],
            [99.81568226530672, 20.340409042492084],
            [99.788318201209592, 20.329718092599862],
            [99.669991209027472, 20.311382176438187],
            [99.61110029242829, 20.323327059048879],
            [99.528454223973284, 20.350136073516282],
            [99.502209311591201, 20.308891074807448],
            [99.554836264291765, 20.202845079554166],
            [99.539563263196925, 20.153745064081718],
            [99.515727142156067, 20.145618138378111],
            [99.487618262140842, 20.118609131700438],
            [99.45207228705496, 20.09693604459919],
            [99.331927260069079, 20.067082052337682],
            [99.299572275531119, 20.076800030906483],
            [99.27012715250774, 20.10152698061934],
            [99.243863297024319, 20.117218071045599],
            [99.19303627421678, 20.126800095144503],
            [99.164145194994802, 20.126664140674578],
            [99.079091341026952, 20.098473118007732],
            [99.041927155709601, 20.042773191956528],
            [99.022763275149913, 19.924300019382642],
            [99.03011822756892, 19.890827056596024],
            [99.038182288998456, 19.857218139339594],
            [99.032909233696017, 19.83582701946186],
            [99.000472274144869, 19.784436062206979],
            [98.945818241974763, 19.771109003758895],
            [98.891109224519624, 19.776109144293045],
            [98.838182367322673, 19.81500016930751],
            [98.64915418929607, 19.741935957313402],
            [98.520536231642524, 19.709718100711811],
            [98.476027152190142, 19.693673126332371],
            [98.391100200236707, 19.695827107816299],
            [98.337554088031339, 19.693673126332371],
            [98.242200216136581, 19.689991123895211],
            [98.215272346281864, 19.72638216246132],
            [98.177200233210016, 19.758054021888583],
            [98.139436238899776, 19.78305405400765],
            [98.074718223196982, 19.809300139856035],
            [98.04935424884107, 19.804791178849356],
            [98.028736275714408, 19.713953979312308],
            [98.039700308012726, 19.671936001490579],
            [98.035954100197017, 19.647773153863128],
            [97.964291342417738, 19.601527008791734],
            [97.907482322935294, 19.574717994324416],
            [97.869082309810352, 19.570173158771993],
            [97.866382331151755, 19.521664235112993],
            [97.792345147834482, 19.398882093742557],
            [97.802754130502905, 19.359853940791893],
            [97.83415424862838, 19.290000165360951],
            [97.841809273181894, 19.207100125239052],
            [97.84054511454147, 19.167773073620438],
            [97.831791222478586, 19.098673166563074],
            [97.784291312326758, 19.068191034476015],
            [97.747200217205233, 19.031527081141505],
            [97.679836202300663, 18.932145034207437],
            [97.719700198638208, 18.889436052286172],
            [97.746227245881897, 18.867500108700412],
            [97.781518243472391, 18.678464219322947],
            [97.780272357380937, 18.634991143106035],
            [97.778872411908679, 18.604718055684529],
            [97.775682259551189, 18.572354018691144],
            [97.751854185137432, 18.572500031445045],
            [97.652772377976049, 18.56221811842893],
            [97.626918229921074, 18.555273208714496],
            [97.551372304027865, 18.507082126824955],
            [97.506654179909532, 18.496109042071126],
            [97.446645117423799, 18.496309034281481],
            [97.368454198157025, 18.558191116856094],
            [97.362291320010996, 18.579653985825118],
            [97.354082251656138, 18.575209062558784],
            [97.34727229058322, 18.542218059206121],
            [97.367545096936141, 18.5218000782899],
            [97.393600243029795, 18.493464216335823],
            [97.437482356122899, 18.410553950291941],
            [97.445945228506758, 18.390826973475427],
            [97.450272302214017, 18.370273205727287],
            [97.45095408622015, 18.336873165498517],
            [97.497909172664237, 18.279164097250614],
            [97.519291240086403, 18.266391082603931],
            [97.538936241889729, 18.275482094813256],
            [97.554363302365203, 18.31958213738956],
            [97.586100205361248, 18.326664175040364],
            [97.618454184070828, 18.315000086186913],
            [97.643354136265685, 18.280482067709428],
            [97.623454156966972, 18.252773172114715],
            [97.6209541705189, 18.225482198213328],
            [97.652482364125405, 18.175000174541552],
            [97.687191322357677, 18.128609022544751],
            [97.751109201581158, 17.974854072473974],
            [97.743945188917081, 17.954854013251079],
            [97.706991221731954, 17.928191179175641],
            [97.687272291542541, 17.867709042073173],
            [97.778318200469272, 17.703327012212625],
            [97.793872330597225, 17.684164137481318],
            [97.850963317303382, 17.623473123351189],
            [97.923872296450298, 17.568964098106491],
            [97.968872387792061, 17.528327122655796],
            [98.053036251278314, 17.415827061939481],
            [98.118863360412519, 17.313882164283271],
            [98.179427137251963, 17.25777320135596],
            [98.205554200074999, 17.235827031848416],
            [98.272763317408618, 17.173882084999818],
            [98.304427130208779, 17.14082704390681],
            [98.374691283620479, 17.050553946454883],
            [98.400545264037277, 17.052773139145827],
            [98.42360019169999, 17.05332701530925],
            [98.46165419986076, 17.015827050949909],
            [98.511109272752861, 16.941109089454457],
            [98.543872288338349, 16.883882148278488],
            [98.549991245311645, 16.854717986650328],
            [98.506691339214655, 16.782109079637834],
            [98.480018279217319, 16.738954012828245],
            [98.52249122674462, 16.685273119619652],
            [98.563600270983358, 16.630827126286803],
            [98.662072378506224, 16.459854070664804],
            [98.66804515508764, 16.381936066166347],
            [98.691227152402831, 16.283054083577142],
            [98.714572261554252, 16.27555395659472],
            [98.788591339960789, 16.353054039399765],
            [98.827482364975168, 16.402500059836271],
            [98.864991214152013, 16.43860913117895],
            [98.921200257003505, 16.395345099627789],
            [98.915954191429449, 16.261391054759585],
            [98.857763331385485, 16.141035977279373],
            [98.8123362658948, 16.104853983378931],
            [98.789282344060581, 16.118400144776771],
            [98.698863401493128, 16.129300139334546],
            [98.679282269792253, 16.120827041028903],
            [98.631654116521446, 16.046109079533537],
            [98.61499122823821, 15.965827043056791],
            [98.565263408216168, 15.722773140324094],
            [98.568882211103215, 15.698326984368364],
            [98.575572310960439, 15.661882133983696],
            [98.570827147921392, 15.643891049319606],
            [98.565400200876326, 15.60763596522311],
            [98.571382365189294, 15.555273210111594],
            [98.576936214248946, 15.53305395819784],
            [98.59248229774991, 15.477500044900339],
            [98.59610026244664, 15.452364225949466],
            [98.593600275998568, 15.416944985240022],
            [98.589427261672114, 15.382773139364858],
            [98.581791347857887, 15.357909061715603],
            [98.562963413978451, 15.334582057475203],
            [98.552127289522929, 15.357082103146084],
            [98.50054522487514, 15.387500029854706],
            [98.423936138380242, 15.360482138359728],
            [98.420254135942997, 15.284164239181663],
            [98.397763310365605, 15.271109089673374],
            [98.23429121991623, 15.221182115631294],
            [98.205472392699818, 15.226782065158417],
            [98.203045161171502, 15.204444963685759],
            [98.201100224353326, 15.075000047462609],
            [98.216154122498352, 14.993844951948532],
            [98.234563296494144, 14.965827099401977],
            [98.252063369268768, 14.928326967404345],
            [98.255272297089476, 14.898745051720752],
            [98.247482323894445, 14.874026986825314],
            [98.262082258162394, 14.811800072753201],
            [98.32540921321052, 14.715000154918457],
            [98.392491260891546, 14.651291152722749],
            [98.41790921470411, 14.636391146320349],
            [98.443309231243603, 14.611527068671123],
            [98.468045233411857, 14.578327020652793],
            [98.51943619066671, 14.486664203650705],
            [98.540818258088876, 14.44694504660022],
            [98.570263381112255, 14.384164088726592],
            [98.614700208559185, 14.322153930671178],
            [98.759718198011711, 14.217500169539207],
            [98.807209223346064, 14.185000178075924],
            [98.886654242969456, 14.131382149141203],
            [98.93928236913635, 14.101109061719782],
            [98.973872304343132, 14.079164233316718],
            [99.003327150374417, 14.01082707945055],
            [99.036372300821625, 13.934718057300543],
            [99.12929122983715, 13.771664223820679],
            [99.173963421125762, 13.72778211072756],
            [99.170254093684122, 13.665273061793656],
            [99.169145167890889, 13.63471800714882],
            [99.176927262096882, 13.578053994591599],
            [99.188309216088811, 13.541664129491693],
            [99.210263264585222, 13.468327002729467],
            [99.216654130498142, 13.412082085332244],
            [99.213882235110333, 13.234717996159162],
            [99.210682359745221, 13.21139115955674],
            [99.20338222497324, 13.203582075622492],
            [99.15457239098933, 13.197918088354839],
            [99.124918223300284, 13.179235999591029],
            [99.112409238604386, 13.061108999619094],
            [99.239009169243701, 12.734718024331571],
            [99.305254200071573, 12.665273118138742],
            [99.436236189703294, 12.574436086239871],
            [99.428863300011557, 12.49888211371939],
            [99.447063429341398, 12.331391068324393],
            [99.455554129643787, 12.298609109637496],
            [99.499709325143186, 12.188327068145455],
            [99.570263324767353, 12.138745093239137],
            [99.56330919495943, 12.101664224039595],
            [99.556636194184563, 12.0158360498543],
            [99.584218187764947, 11.884445023346316],
            [99.628727267217442, 11.833054066091449],
            [99.657345264033182, 11.8264539878747],
            [99.638182221663925, 11.74180900314488],
            [99.560118204411452, 11.630482073600135],
            [99.465545190618883, 11.56832707625739],
            [99.358318185204809, 11.350000123237351],
            [99.324436185542169, 11.278891073983303],
            [99.265545101304923, 11.176664209103478],
            [99.25443622971946, 11.159445097723975],
            [99.21331830066319, 11.099164126298433],
            [99.167072323229974, 11.042427023545457],
            [99.144136250954574, 11.030273096269028],
            [99.128036123652208, 11.024164197579637],
            [99.110672340623552, 11.013335952113138],
            [99.048872400700276, 10.958053947755261],
            [98.935254194097041, 10.822217997981113],
            [98.897918179764218, 10.776527070177622],
            [98.868482276834328, 10.77336407518645],
            [98.82457233582258, 10.731945068167278],
            [98.784982260081478, 10.677500080663108],
            [98.775272328139778, 10.621735949233283],
            [98.797063264799959, 10.59500002496172],
            [98.824291206789354, 10.519864141772928],
            [98.796091299305118, 10.456600050999043],
            [98.771518241335144, 10.432636022115375],
            [98.759427178332686, 10.409718054750883],
            [98.748872350548396, 10.378054074312715],
            [98.742751530806999, 10.348602299841673],
            [98.737560426000215, 10.322287937000141],
            [98.726529426000099, 10.282574937000092],
            [98.711085426000096, 10.242862937000027],
            [98.689023426000148, 10.18329393700013],
            [98.664754426000201, 10.136961937000066],
            [98.638279426000082, 10.08401193700017],
            [98.621434729333458, 10.044795098666711],
            [98.612160930000215, 10.024819207000135],
            [98.605742584000126, 10.006990467000065],
            [98.599324238000094, 9.997006373000076],
            [98.587200694000018, 9.986309129000048],
            [98.577929749000219, 9.974898735000082],
            [98.564379907000074, 9.962062042000142],
            [98.552969513000022, 9.963488341000115],
            [98.543698568000167, 9.967054089],
            [98.534427624000188, 9.971332987000011],
            [98.532288175000105, 9.974898735000082],
            [98.52373038000016, 9.979890782000126],
            [98.513033136000132, 9.969906688000037],
            [98.508754238000137, 9.961348893000022],
            [98.5059016390002, 9.955643696000038],
            [98.498056994000109, 9.952077948000152],
            [98.489499199000164, 9.952077948000152],
            [98.472383608000229, 9.954930546000185],
            [98.455981167000147, 9.959922593000044],
            [98.43601297800015, 9.964914641000178],
            [98.421036837000116, 9.957069995000012],
            [98.406773845000117, 9.948512200000081],
            [98.400355498000096, 9.943520153000037],
            [98.390552933000123, 9.934002847000031],
            [98.36981972900017, 9.902903041000144],
            [98.342175457000195, 9.875258769000084],
            [98.321442253000129, 9.840703429000101],
            [98.307620117000198, 9.806148089000018],
            [98.293797981000154, 9.768137215000024],
            [98.276520311000041, 9.73358187600013],
            [98.262698175000111, 9.702482069999974],
            [98.248876039000066, 9.66447119599998],
            [98.238509437000147, 9.629915856000082],
            [98.224687301000102, 9.591904982000088],
            [98.20395409700015, 9.533160904000141],
            [98.165943223000141, 9.498605564000059],
            [98.121021282000157, 9.491694496000136],
            [98.062277204000026, 9.48823896200004],
            [98.010444194000087, 9.505516632000081],
            [97.979344388000101, 9.5158832340001],
            [97.938888889000083, 9.5375],
            [97.917543225000173, 9.548941276],
            [97.869444444000038, 9.574722222000091],
            [97.853333333000052, 9.581666667000022],
            [97.75805555600013, 9.594166667000081],
            [97.724722222000167, 9.600555556000145],
            [97.62666666700008, 9.623333333000161],
            [97.443333333000197, 9.676388889000179],
            [96.493055556000144, 9.758333333000024],
            [95.590277843200141, 9.633333],
            [95.585194006000137, 9.633333244000042],
            [95.583333333000184, 9.633333333000138],
            [95.581644232000059, 9.636952837000123],
            [95.578333660000141, 9.644046919],
            [95.466666667000055, 9.883333333],
            [95.266666667000067, 10.300000000000153],
            [95.250000000000142, 10.466666667],
            [95.366666667000089, 10.733333333000061],
            [95.433333333000093, 11.716666667000112],
            [95.5, 12.316666667000135],
            [95.683333333000149, 12.900000000000134],
            [95.033333333000229, 13.800000000000168],
            [93.833333333000184, 13.800000000000168],
            [93.666666667000158, 13.566666667000149],
            [93.133333333000138, 13.816666667],
            [92.900000000000119, 13.95],
            [92.833333333000127, 14.0],
            [92.400000000000119, 14.283333333000044],
            [90.2589986476668, 15.683219007000034],
            [90.280774308000019, 15.714179669000131],
            [90.315406934000094, 15.76342038000017],
            [90.31728681600012, 15.770610443999985],
            [90.347694764000067, 15.886912972000019],
            [90.375992335000063, 15.995143857000116],
            [90.376697688500059, 15.996673723000029],
            [90.376997534000083, 15.997360002000065],
            [90.377526582000115, 15.999366564],
            [90.624952451500207, 16.529174278500122],
            [90.747946354500101, 16.702666137000122],
            [90.747447378000146, 16.70736275299997],
            [90.747217732000166, 16.709524300000126],
            [90.747342677000091, 16.711155899000019],
            [90.746866794000169, 16.715634387000122],
            [90.746041303000169, 16.723402989000036],
            [90.746166277000185, 16.725035167000087],
            [90.745191007000159, 16.734211337000104],
            [90.744864684000135, 16.737281669000126],
            [90.744989687000185, 16.73891442700004],
            [90.7445819120002, 16.742750558],
            [90.576745180000074, 16.964670077000036],
            [90.418548359000084, 17.173843371999979],
            [90.266660273000099, 17.471146686999973],
            [90.151935996000162, 17.69570615800005],
            [90.151522204000031, 17.697078058000145],
            [90.151104254500154, 17.698299957500026],
            [90.147661553000177, 17.709549077000119],
            [90.147054730000065, 17.710732316000033],
            [90.146542673000141, 17.711730771000148],
            [90.145545598000155, 17.715035510000021],
            [90.143218316000144, 17.722749132],
            [90.142598518000028, 17.723957483999968],
            [90.14210092100015, 17.724927592000043],
            [90.138774944000119, 17.735949147999989],
            [90.137659035000155, 17.738124373000019],
            [90.135652960000101, 17.744770748999983],
            [90.134331435000178, 17.749149126000148],
            [90.133647717000173, 17.750481681000096],
            [90.133217014000167, 17.751321113000088],
            [90.129865101000092, 17.762393277666703],
            [90.128774858000128, 17.764517813000154],
            [90.12544400800013, 17.775548965999988],
            [90.124365723500119, 17.777649869500138],
            [90.12100009100007, 17.788748828000152],
            [90.119890142000173, 17.790911089000176],
            [90.119005742666815, 17.793838977666709],
            [89.969463263000108, 17.930907702],
            [89.867203974000091, 18.02457822],
            [89.610330036000192, 18.357862539000095],
            [89.708948885000069, 18.565193760000128],
            [91.842166667000043, 20.058888889000045],
            [91.95499073400012, 20.171419042000153],
            [92.002111111000232, 20.218416667000028],
            [92.16408333300015, 20.444222222],
            [92.16495849400016, 20.444952567000129],
            [92.165799993000149, 20.443946627000017],
            [92.167528322000152, 20.441919599000173],
            [92.168072101000092, 20.441289381000061],
            [92.1689822270001, 20.440246081000069],
            [92.169665084500167, 20.439473826500077],
            [92.170325983000083, 20.43874024100019],
            [92.171336485000182, 20.437633303000084],
            [92.171945569000144, 20.436973875000049],
            [92.173766040000061, 20.435025819000074],
            [92.174376488000092, 20.434380091000023],
            [92.175400404000101, 20.433309418000178],
            [92.176018644000095, 20.432670348999963],
            [92.177490023000274, 20.431167386000041],
            [92.178368747000121, 20.430283787000107],
            [92.179845743000129, 20.42882229100006],
            [92.180739411000076, 20.427952096000112],
            [92.183291369000102, 20.425505577000095],
            [92.183896936000195, 20.424934543000106],
            [92.185117721000125, 20.423801715000067],
            [92.185732934000072, 20.423239926000136],
            [92.188212458000152, 20.421011796000144],
            [92.188837155000243, 20.420459387000093],
            [92.190095884000101, 20.419364092000066],
            [92.190729922000145, 20.418821203000093],
            [92.193443207000229, 20.416536234000219],
            [92.194401647000262, 20.415741575000098],
            [92.196007986000069, 20.414430971000101],
            [92.19702213400015, 20.413617582000043],
            [92.197932545000214, 20.412903543000127],
            [92.199069282000238, 20.412025469000113],
            [92.19975311500005, 20.411503149999973],
            [92.201909080500144, 20.409876243500051],
            [92.203281438000062, 20.408862685000159],
            [92.20558799050005, 20.407197074500075],
            [92.206983451500122, 20.4062119325],
            [92.209327908000176, 20.404594108000126],
            [92.210745780000167, 20.403637871500095],
            [92.213127019500149, 20.402068606500123],
            [92.214566604000112, 20.401141743500077],
            [92.216983542500117, 20.39962174700004],
            [92.218444196000206, 20.398724671000068],
            [92.220895607000131, 20.397254714000113],
            [92.222376645500134, 20.396387844500097],
            [92.224735691000063, 20.3950390910001],
            [92.225477971000174, 20.394620266000189],
            [92.226717265000133, 20.393929544000045],
            [92.227641458500244, 20.393422202],
            [92.228479201000283, 20.392971776000039],
            [92.229756925000316, 20.392294617000047],
            [92.23052471000014, 20.391892929000036],
            [92.232946778500235, 20.390643356000012],
            [92.234484682500153, 20.389869538500093],
            [92.237062456000189, 20.388606316000065],
            [92.238617825000148, 20.387864290500161],
            [92.241224118500185, 20.386654334500051],
            [92.242796247500138, 20.385944436000088],
            [92.245429820500277, 20.384788320500135],
            [92.247017950000185, 20.384110891500129],
            [92.249677648500239, 20.383009121000114],
            [92.251281090500214, 20.382364456000161],
            [92.253965603000125, 20.381317580000157],
            [92.255583558500092, 20.380706001500116],
            [92.258155043000187, 20.379763313000055],
            [92.25896499700022, 20.379471516000066],
            [92.260316293000216, 20.378992583000056],
            [92.26132566350023, 20.378642047500065],
            [92.262227465000166, 20.378337522000137],
            [92.263606369000144, 20.377880883000088],
            [92.264434467000086, 20.377611446000103],
            [92.26704917150019, 20.376776948000142],
            [92.268705964000191, 20.376266329000103],
            [92.271476830000125, 20.375443746500096],
            [92.273145024000115, 20.374967281500133],
            [92.275949745500071, 20.374197729500125],
            [92.277722069000134, 20.373732106500071],
            [92.279794549000115, 20.373205979000105],
            [92.281049507000176, 20.372879024000227],
            [92.283780490500163, 20.372190726500079],
            [92.285468988500071, 20.371783256000057],
            [92.288290802000148, 20.371133089500049],
            [92.289988394000119, 20.37076038050013],
            [92.292824569500084, 20.370168328500071],
            [92.294530336500173, 20.3698305865001],
            [92.297236468000136, 20.36932258400013],
            [92.298089957000201, 20.369167263000165],
            [92.29951311600027, 20.368915819000122],
            [92.300578314000148, 20.368734563500098],
            [92.301515813000179, 20.368583236000049],
            [92.302953367000129, 20.368359709000131],
            [92.303816194, 20.368230109000066],
            [92.306544307500104, 20.367835846500171],
            [92.308269830000057, 20.36760387650007],
            [92.311149812000252, 20.367246844000064],
            [92.312880231500202, 20.367050380000151],
            [92.315767780000243, 20.366752592500077],
            [92.317502371000131, 20.366591717000048],
            [92.320396117500167, 20.366353317000105],
            [92.322133990500134, 20.366228114500117],
            [92.32474257200019, 20.366065618000121],
            [92.326605274000173, 20.365981476000073],
            [92.330318210000229, 20.36587508500007],
            [92.331894377000168, 20.365849731000097],
            [92.332762955000163, 20.365818716000106],
            [92.334145978500175, 20.365777927500076],
            [92.336050379000227, 20.365741293000113],
            [92.337927180000094, 20.365725712000071],
            [92.341273343500205, 20.365858831500091],
            [92.342422565000135, 20.365861067000068],
            [92.343399298000207, 20.365860457000096],
            [92.344318196000216, 20.36588232300015],
            [92.345625219000084, 20.365926626000075],
            [92.34681387200024, 20.365974351000105],
            [92.348005600000221, 20.366026342000069],
            [92.349378465000342, 20.36614091200012],
            [92.350698772000243, 20.366214619000104],
            [92.351472425000026, 20.366280228000122],
            [92.352333563000201, 20.366420750000017],
            [92.354391684000262, 20.366387381000081],
            [92.356072222000222, 20.366545254],
            [92.359132332000257, 20.366878963000076],
            [92.362402363000029, 20.367090526000013],
            [92.366156904000235, 20.367459768000188],
            [92.37007971500023, 20.367979519000116],
            [92.371464859000156, 20.36817436300008],
            [92.373771058000017, 20.368518261000091],
            [92.375228573500209, 20.368748784500113],
            [92.376380354000077, 20.368943826000233],
            [92.377803515000068, 20.369195270000077],
            [92.378449357000108, 20.369312803000028],
            [92.381363085500169, 20.369858584500022],
            [92.383068866000173, 20.370196329500075],
            [92.385905048000183, 20.370788382500123],
            [92.387602642000303, 20.371161092000094],
            [92.390424469500289, 20.371811262000151],
            [92.392112992500216, 20.372218738000115],
            [92.394919141500026, 20.372926718500125],
            [92.3965979540001, 20.373368810500025],
            [92.39938710200019, 20.37413427000017],
            [92.401055234000211, 20.374610720500201],
            [92.403652734000076, 20.375380860000107],
            [92.402527778000291, 20.379472222000047],
            [92.414555556000067, 20.436472222000205],
            [92.429444444000154, 20.554944443999986],
            [92.41625000000019, 20.590750000000114],
            [92.401722833000036, 20.611110911000011],
            [92.396695062000134, 20.61815770800014],
            [92.395083333000088, 20.6204166670001],
            [92.389413810000207, 20.626338085000114],
            [92.353194444000138, 20.664166667000117],
            [92.341388889000228, 20.679166667000104],
            [92.342217957000088, 20.679933259000094],
            [92.365342640000193, 20.701315349000012],
            [92.368666667000156, 20.704388889000128],
            [92.370260239000203, 20.720529556000045],
            [92.371051788000244, 20.721664429000086],
            [92.370462418000074, 20.725677490000109],
            [92.369715690500158, 20.728377342000115],
            [92.36792945900018, 20.732831955000094],
            [92.367164612000153, 20.734298706000132],
            [92.365233421500164, 20.736943245000091],
            [92.363794327000249, 20.738868713000173],
            [92.361295700000113, 20.741659164000041],
            [92.358783722000027, 20.74392318700005],
            [92.357048035000133, 20.745197296000114],
            [92.356267929000154, 20.745807648000081],
            [92.355775833000308, 20.746746063000202],
            [92.355854034000089, 20.749771118000112],
            [92.35546684300013, 20.752099991000065],
            [92.355440140000127, 20.75311660800007],
            [92.354402542000145, 20.760330836000051],
            [92.352823257000153, 20.762771606000101],
            [92.35029029800009, 20.767498016000118],
            [92.349615097000168, 20.769971848000168],
            [92.349426270000123, 20.771249771000114],
            [92.349637985000271, 20.774765015],
            [92.348871231000118, 20.776700974000036],
            [92.347650528000173, 20.782746315000097],
            [92.34709739700034, 20.787450790000051],
            [92.347012520000106, 20.789719581500052],
            [92.345867157000129, 20.79316711400007],
            [92.345191956000207, 20.794569016000068],
            [92.343559265000152, 20.798839569000094],
            [92.342838287000092, 20.799972534000133],
            [92.342382431000118, 20.800901413000176],
            [92.340187073000294, 20.80329513500017],
            [92.339681625500219, 20.804424285500104],
            [92.338314056000314, 20.808237076000054],
            [92.338011741500196, 20.809299469000123],
            [92.337257385000186, 20.812887192000048],
            [92.336851120000205, 20.813611984000161],
            [92.335838318000157, 20.816417694000123],
            [92.335113525000139, 20.818044662000105],
            [92.334384918000097, 20.822177887000052],
            [92.333900452000222, 20.823652267000185],
            [92.333841324000247, 20.824464798],
            [92.333614349000214, 20.825649261000038],
            [92.332803726000151, 20.82787132300011],
            [92.331974030000111, 20.829666138000121],
            [92.331665039000171, 20.832338332999981],
            [92.331085205000107, 20.834655762000139],
            [92.330639839000099, 20.835762977500124],
            [92.330366135000219, 20.837001801000085],
            [92.329807281000143, 20.839050293000113],
            [92.328920364500135, 20.841507912000068],
            [92.327657700000174, 20.844751358000039],
            [92.327049255000105, 20.847972870000135],
            [92.327049255000105, 20.84885856863535],
            [92.32645615142232, 20.850381362938364],
            [92.325328827000163, 20.852218628000244],
            [92.324842453000116, 20.853226662000154],
            [92.323854446000297, 20.855588913000119],
            [92.323507309000064, 20.856956482000029],
            [92.322479248000178, 20.859384537000111],
            [92.321979523000124, 20.860157013000176],
            [92.321075439000111, 20.861783981000045],
            [92.320539474000242, 20.862585067999973],
            [92.320205688500067, 20.863417625500077],
            [92.319683075000199, 20.86479568500009],
            [92.317792892000313, 20.867912292000042],
            [92.315956116000081, 20.869440079000142],
            [92.313415527500155, 20.87441062900011],
            [92.312280655000023, 20.876810074000119],
            [92.311817169000136, 20.878463745000076],
            [92.311481475666739, 20.879106522000072],
            [92.311302185000073, 20.882680893000142],
            [92.31089973400023, 20.88455009500008],
            [92.310850143000124, 20.885412216000091],
            [92.310243607000217, 20.888164520000075],
            [92.309469223000093, 20.890070915000067],
            [92.308648109500126, 20.891814232000073],
            [92.30634689300021, 20.896701813000092],
            [92.306009293000017, 20.89789962799999],
            [92.30429267900027, 20.901077271000091],
            [92.303146362000092, 20.904098510999958],
            [92.301961899000133, 20.904905319000122],
            [92.299707413000277, 20.906696320000052],
            [92.299062729000156, 20.907278061000156],
            [92.298099517500276, 20.908432007000059],
            [92.297523499000278, 20.910768509000107],
            [92.296899795500195, 20.912838936000085],
            [92.295717393983608, 20.918697034264696],
            [92.292395342386698, 20.924891796997699],
            [92.290309906000147, 20.926832198999975],
            [92.287601471000158, 20.928874969000063],
            [92.286764145000262, 20.930076599],
            [92.283945083500072, 20.933446884000077],
            [92.283422470000261, 20.934041977000092],
            [92.282068253000176, 20.935050964000098],
            [92.280660629000153, 20.93588447600014],
            [92.275588035500135, 20.938156128000074],
            [92.27511405900006, 20.939311981000131],
            [92.274147034000094, 20.94050216700002],
            [92.271847725000185, 20.946393967000162],
            [92.271684647000171, 20.949831009000079],
            [92.270681381000117, 20.953004837000137],
            [92.270271301000236, 20.955316544000183],
            [92.270223618000188, 20.956237793000113],
            [92.270664215000153, 20.957901001000053],
            [92.271202087000205, 20.959600448999979],
            [92.269865036000084, 20.962532043500104],
            [92.269512177000109, 20.965351105000096],
            [92.269618988000076, 20.967901230000152],
            [92.269954681000144, 20.969600677000145],
            [92.269430160500093, 20.97213268300014],
            [92.269199371000269, 20.975349426000193],
            [92.269102096500234, 20.97739028950015],
            [92.268701553500165, 20.980450630000192],
            [92.268083572000052, 20.984170913999989],
            [92.267644882000155, 20.985185623000106],
            [92.267066002000121, 20.987415313500165],
            [92.266506195500227, 20.98956871050008],
            [92.26634597800026, 20.991300583000069],
            [92.266213417000216, 20.994350433500031],
            [92.266250610000242, 20.997051239000072],
            [92.266448975000259, 20.998109818000088],
            [92.265609741500128, 21.002366066000107],
            [92.265143394500143, 21.003456116000038],
            [92.264158249000076, 21.00663757300002],
            [92.264152527000277, 21.007547379000172],
            [92.264335632000069, 21.008287430000209],
            [92.264240265000097, 21.009775162000025],
            [92.261520386000115, 21.012899398999977],
            [92.261171341000107, 21.014703751000056],
            [92.260000229000269, 21.017047882000114],
            [92.259286880000133, 21.017778397000072],
            [92.259057999000134, 21.018575668000054],
            [92.25785064700014, 21.02035141000006],
            [92.25785064700014, 21.023250579999953],
            [92.25841713000014, 21.024950026999974],
            [92.257608414000202, 21.025995255000126],
            [92.256551743000074, 21.029500961000124],
            [92.257547379000101, 21.032001495000074],
            [92.257055728281017, 21.033631488127739],
            [92.260560775971413, 21.047916839292398],
            [92.26124382000026, 21.049232483000068],
            [92.262443044272032, 21.050843339505136],
            [92.261956694101428, 21.054190606992151],
            [92.232900329693365, 21.11527307788235],
            [92.214854092106322, 21.136727062034041],
            [92.205827285275262, 21.218882118599581],
            [92.204991274250204, 21.300554042264693],
            [92.222209212163449, 21.358054065847043],
            [92.260818269954001, 21.414436111180621],
            [92.36011817423676, 21.47235405645641],
            [92.381654133401611, 21.472082147516588],
            [92.537491315865424, 21.378053957431845],
            [92.58638228667192, 21.262564239482444],
            [92.606718292574811, 21.252291043645769],
            [92.666091336246012, 21.293326997688766],
            [92.669345191067777, 21.296982178035776],
            [92.658727163733687, 21.319718090462558],
            [92.64803621384155, 21.349718095477769],
            [92.641372265522392, 21.372773190778375],
            [92.630809223472937, 21.415000045627764],
            [92.618045093643758, 21.469991197944708],
            [92.600263388921434, 21.634164183139418],
            [92.599018173382234, 21.656800183280197],
            [92.599154127852131, 21.795000165032704],
            [92.610809164250355, 21.852982148049009],
            [92.620463272716421, 21.879782110060816],
            [92.615263307609922, 21.921109083782838],
            [92.606091326215591, 21.965000081693276],
            [92.600818270913265, 21.982218019606407],
            [92.605818243809409, 21.983327113037532],
            [92.675891289828002, 22.025136046193509],
            [92.695818258854871, 22.094164204159298],
            [92.706327153809553, 22.154509045687391],
            [92.798727243017055, 22.109091200290194],
            [92.862072302975918, 22.052636064760648],
            [92.875809236490596, 22.034027066192593],
            [92.885127230638687, 22.013609085276457],
            [92.924427124891025, 22.005000032500803],
            [92.959718290119582, 22.024718124500097],
            [92.99081816847243, 22.048609063187726],
            [93.01610033545299, 22.115000106769244],
            [93.046991336778348, 22.204509109925212],
            [93.071372281527061, 22.211664237771956],
            [93.105809163181419, 22.205273204220973],
            [93.129291232631232, 22.186036065827167],
            [93.155582245480758, 22.187845048175618],
            [93.197891242981683, 22.264717996983407],
            [93.196927156475596, 22.316936080445785],
            [93.192745257331723, 22.357217998476912],
            [93.189418144675955, 22.384445102275933],
            [93.176227208335746, 22.44145394633064],
            [93.141791332509712, 22.465618135062741],
            [93.119282234383405, 22.530282171308968],
            [93.097209162861446, 22.665273225240767],
            [93.092754181311221, 22.714436104987257],
            [93.102200250940115, 22.801664224644909],
            [93.117763265885657, 22.87082699597623],
            [93.139991234978567, 22.953191097207522],
            [93.126091189627942, 23.014164246199172],
            [93.139572307456746, 23.04708215935598],
            [93.184700306641417, 23.059164169903042],
            [93.209918268243456, 23.051244947760708],
            [93.226382337782894, 23.017917997727906],
            [93.247354194862538, 23.006945080612169],
            [93.274709206504355, 23.004300087238775],
            [93.305463415169328, 23.017636030504192],
            [93.349018298761592, 23.07110905251362],
            [93.387836233580032, 23.23145402251744],
            [93.371918161215007, 23.357773159399912],
            [93.399427232237628, 23.423327018489729],
            [93.425118268456316, 23.602354077257374],
            [93.422763288933453, 23.662491047585988],
            [93.414700233332411, 23.704718070073582],
            [93.384154231142787, 23.848053979192045],
            [93.351091311060742, 23.953326995332347],
            [93.329227284204563, 23.980618136871712],
            [93.327900261290068, 24.035964178969934],
            [93.337518159934604, 24.071836042452063],
            [93.367372319834175, 24.091000090649842],
            [93.387818296307131, 24.087000078805247],
            [93.407572262851971, 24.074536021110404],
            [93.42147230820288, 24.043718109981157],
            [93.473591317569486, 23.970973080860873],
            [93.500118197175112, 23.962353969801413],
            [93.529163335777895, 23.96416412561625],
            [93.593463262149243, 23.971244989800581],
            [93.610272330824245, 23.993818125667318],
            [93.628582263086088, 24.016517995910462],
            [93.689682314092153, 24.015273115647418],
            [93.766145220195341, 24.000273196958844],
            [93.784291202430751, 23.97666422549473],
            [93.800536169020432, 23.948882072066098],
            [93.822627177815122, 23.93513608609608],
            [93.858600294687818, 23.942218123746798],
            [93.910191244153026, 23.955754059222656],
            [94.03151828712808, 23.919718078075917],
            [94.094291198374691, 23.886244947651207],
            [94.134763385608522, 23.843727073122793],
            [94.147763382193801, 23.851527104601544],
            [94.219982363069477, 24.010827018914583],
            [94.242482241102181, 24.080827142375497],
            [94.270263388702602, 24.179436042558407],
            [94.313872251751519, 24.285827036947339],
            [94.3591452578614, 24.371664095950152],
            [94.472209253025056, 24.581664131056428],
            [94.500536230161714, 24.603191205403888],
            [94.597763289783444, 24.7252731225811],
            [94.618318230997914, 24.760273100492569],
            [94.668591210003939, 24.867773188312825],
            [94.735182245795869, 25.032009037781492],
            [94.734009282262122, 25.123053940879814],
            [94.721372389723427, 25.140000137491214],
            [94.686500319654868, 25.155273138585969],
            [94.66303618747807, 25.163882191361424],
            [94.61665408793678, 25.170544966214251],
            [94.594154209903962, 25.184164217807947],
            [94.578472339571277, 25.2091642499269],
            [94.576236215435728, 25.233609064777951],
            [94.582500179334346, 25.288882184318325],
            [94.628591259196611, 25.401873089286127],
            [94.661654179278713, 25.428045079110277],
            [94.669436273484649, 25.447491094531657],
            [94.687000216361554, 25.465900100889272],
            [94.740054143210983, 25.494782127655739],
            [94.768463262999177, 25.491518046912191],
            [94.793727157430823, 25.49985401728155],
            [94.885272292511928, 25.57860903863336],
            [94.996372407756184, 25.732218143588611],
            [95.028309302962469, 25.825191052060788],
            [95.067272244706288, 25.946173095900164],
            [95.092627166606718, 25.960554094856562],
            [95.1177541007402, 25.983327055295561],
            [95.134991317030511, 26.001945106318928],
            [95.164982269590297, 26.036809129760385],
            [95.175082295306794, 26.066109078220379],
            [95.113309177473667, 26.138327053267673],
            [95.080272408929631, 26.234300180170678],
            [95.066727253360369, 26.311664140867819],
            [95.065891242335255, 26.475482068643075],
            [95.144982210367431, 26.616173152026434],
            [95.238172377770439, 26.676245078786124],
            [95.253600276436401, 26.679436069334017],
            [95.261591247669998, 26.649853986012332],
            [95.388318247961905, 26.684718009453789],
            [95.421100206648731, 26.698609169987193],
            [95.445400182212637, 26.712353982490626],
            [95.47733623922872, 26.752353933298238],
            [95.493591264102207, 26.791382086249016],
            [95.507491309452945, 26.812909160596561],
            [95.52582722561479, 26.824718088737328],
            [95.546645190951637, 26.826382064160285],
            [95.606127199364352, 26.823082108870992],
            [95.671100192562534, 26.898327124439263],
            [95.806091246494503, 27.002636054073648],
            [95.828463384322475, 27.0081910765998],
            [95.873727170338981, 27.012918134727656],
            [95.912200273659749, 27.030827076740408],
            [95.936091212347463, 27.051664152816826],
            [95.961927255491531, 27.080273097176914],
            [95.981654232308159, 27.11027310219221],
            [95.993591235929813, 27.136664194966002],
            [96.011382328383462, 27.169027058492915],
            [96.029563346974015, 27.187491050135563],
            [96.098172409779892, 27.232082104601218],
            [96.154163355510292, 27.25610916539695],
            [96.191227293265314, 27.269864203822451],
            [96.228318220748662, 27.276109056981667],
            [96.426227192852394, 27.304645078784077],
            [96.496645238006721, 27.302218014893768],
            [96.621918145731826, 27.365827104803586],
            [96.725891296324022, 27.365691150333575],
            [96.763609190166648, 27.355826991373092],
            [96.78414518827995, 27.346945023829321],
            [96.821518250624905, 27.321527070017041],
            [96.855254237533899, 27.262773113716051],
            [96.863909223139018, 27.212427044514172],
            [96.882063419639309, 27.182773212101111],
            [96.90512723211944, 27.168882219205685],
            [96.960536138491619, 27.147218016921741],
            [96.998318237712766, 27.133882073656238],
            [97.059718193215446, 27.093000178994245],
            [97.135409293672041, 27.087218006891518],
            [97.159009212680729, 27.128053968724075],
            [97.044982304477486, 27.286945013799183],
            [97.031100196399649, 27.302773232161755],
            [97.001654235185953, 27.328609107667759],
            [96.96887227649907, 27.366109072027101],
            [96.903591332177172, 27.457427057531717],
            [96.886382279081573, 27.599854033067302],
            [96.890272320356104, 27.6197181378203],
            [96.932209161354962, 27.662218075075899],
            [97.003872254410425, 27.717773161839872],
            [97.046645106434227, 27.74971810367326],
            [97.12025414213619, 27.791944958522848],
            [97.361100251566569, 27.940827005349874],
            [97.380536208704001, 27.991382119217704],
            [97.359982273317854, 28.043609087497586],
            [97.316854196236505, 28.087500085408223],
            [97.334427191569063, 28.166109094006416],
            [97.348872395903896, 28.222773106563537],
            [97.360118227787751, 28.244926979632382],
            [97.415818153839069, 28.295000134066143],
            [97.445536191630566, 28.292153975015921],
            [97.468882306610368, 28.297709165180166],
            [97.482754188766393, 28.337500071321827],
            [97.496372266893701, 28.416936038489652],
            [97.558872263372081, 28.546527135104682],
            [97.706100225421977, 28.519027116537742],
            [97.722072277243484, 28.50152704376292],
            [97.736645221783107, 28.456382113133841],
            [97.743382260298262, 28.420344958520715],
            [97.804636203047124, 28.345764124961718],
            [97.83700024004051, 28.35540901333458],
            [97.853318296826131, 28.372354036479393],
            [97.902072307334635, 28.378953947058349],
            [98.020336267604677, 28.269300045392058],
            [98.016345140577499, 28.215654020900601],
            [98.136218258623757, 28.156318025241674],
            [98.144991261425929, 28.148882103637888],
            [98.156236255119467, 28.121109170302631],
            [98.146236141688888, 28.008327142362603],
            [98.195818284233468, 27.840000085942407],
            [98.230272264970523, 27.751391131551898],
            [98.263318253608105, 27.696944970581157],
            [98.292072372531862, 27.632500037284402],
            [98.303172359300021, 27.573326985823698],
            [98.319918228424882, 27.540135990260595],
            [98.347354209251563, 27.528744983813439],
            [98.376791285647784, 27.533053952609791],
            [98.407000335328831, 27.556945058935597],
            [98.424282310982306, 27.590827058598236],
            [98.426091293330643, 27.632773119690498],
            [98.458882304473065, 27.672500155729907],
            [98.533327183561966, 27.650827068628743],
            [98.64610032668466, 27.605273101123558],
            [98.696991219594651, 27.529718122774668],
            [98.685809257813077, 27.46930019105092],
            [98.702482372018181, 27.37985405216871],
            [98.732100329885583, 27.363091083961436],
            [98.735745116672632, 27.321591107757385],
            [98.712209235404458, 27.25319108961726],
            [98.693454224082785, 27.228609146829811],
            [98.691227152402831, 27.208044985521795],
            [98.710818174749448, 27.132773147863091],
            [98.738591275722769, 27.005553962215245],
            [98.760545156581344, 26.894164168396642],
            [98.77777231458785, 26.791664221110622],
            [98.778454098593983, 26.639582131280108],
            [98.775545242907896, 26.605827033631883],
            [98.745818152660974, 26.426109138402509],
            [98.726654104463108, 26.362773130898987],
            [98.704154226430234, 26.24763612343618],
            [98.731091316378439, 26.184718037626183],
            [98.708600323163154, 26.132500121801783],
            [98.679018239841383, 26.106382111434286],
            [98.655336178181386, 26.100136084808511],
            [98.647354091765038, 26.120691193661216],
            [98.648663345044866, 26.147845039626006],
            [98.591091237095071, 26.137500094698041],
            [98.569445139722148, 26.125273077225856],
            [98.575272406463995, 26.083327016133595],
            [98.605809188560016, 25.989227076957192],
            [98.655254203168141, 25.953882099910246],
            [98.714291300159033, 25.892500081680495],
            [98.710400253056008, 25.855973088644291],
            [98.628591201454611, 25.803327025204496],
            [98.58331819534456, 25.823745006120802],
            [98.540782215905239, 25.844891206787153],
            [98.482563360304624, 25.796735999443243],
            [98.477909224734361, 25.764164091250493],
            [98.438582340753811, 25.691109099349873],
            [98.383045191262482, 25.591944981898862],
            [98.361372271799297, 25.569445103866073],
            [98.307591298666438, 25.552354067967443],
            [98.227200297448093, 25.593054075330187],
            [98.191082341288023, 25.615273159605849],
            [98.167354179160412, 25.571109079289116],
            [98.168045183260148, 25.550273176679283],
            [98.135545191796865, 25.41027309739583],
            [98.124691297706676, 25.390000123404818],
            [98.065818150742331, 25.310691058251408],
            [97.944972396648922, 25.216173197381679],
            [97.908945132681509, 25.211727100648929],
            [97.885609243623691, 25.247218090449664],
            [97.852200318577417, 25.266936014810781],
            [97.827354178201091, 25.250900092887022],
            [97.814700186579984, 25.208882115065293],
            [97.806091301442422, 25.170273224912521],
            [97.789427239692799, 25.137500151043028],
            [97.757636189602152, 25.091391133908033],
            [97.725818149783009, 25.064718073910527],
            [97.722763281343276, 24.981382174822286],
            [97.736645221783107, 24.918882178343878],
            [97.689418226399567, 24.834445064813522],
            [97.671372324088424, 24.822773096970863],
            [97.578600246016919, 24.761944954904536],
            [97.55255415237869, 24.739927036305701],
            [97.560327194129201, 24.716127125086629],
            [97.568191263348496, 24.666664173205717],
            [97.561791344980094, 24.565136023776645],
            [97.556636139236645, 24.536945001109885],
            [97.543045218475839, 24.476664197322336],
            [97.665118250835718, 24.44221809557429],
            [97.712772388006357, 24.375273175829463],
            [97.759300332663116, 24.260554090060182],
            [97.748454149923788, 24.161244965683963],
            [97.736372307014989, 24.135273135708061],
            [97.721227213763115, 24.114436059631842],
            [97.694700334157318, 24.093473087369588],
            [97.644145220289403, 24.053054041402177],
            [97.537209234554467, 23.94860915729798],
            [97.547618217223061, 23.929991106274613],
            [97.581372309042962, 23.921109138730756],
            [97.633872359729054, 23.888400102601807],
            [97.645536113306349, 23.869164137674559],
            [97.649982210039155, 23.851700107083573],
            [97.691927265303121, 23.883882089139618],
            [97.764709174797389, 23.922773114153799],
            [97.865263347074716, 23.980545046675857],
            [97.896791205405094, 24.013327005362768],
            [97.912491348286864, 24.026664122094658],
            [98.043636114478801, 24.082864112490924],
            [98.064482243010417, 24.089100080832807],
            [98.187727233075606, 24.119445084983496],
            [98.220782274168783, 24.12530906446149],
            [98.446591249260251, 24.134953952834437],
            [98.51408233381747, 24.131027031185781],
            [98.546991194518711, 24.124436005424243],
            [98.586536175620751, 24.095336049174691],
            [98.852027259750486, 24.130436107010226],
            [98.874972384481197, 24.146064165524351],
            [98.890727177371758, 24.160073175616773],
            [98.905118234611933, 24.12516422517426],
            [98.897472262514071, 24.101618117984017],
            [98.869691282551656, 24.078400078485188],
            [98.848754126551256, 24.062582086044515],
            [98.754854179585465, 23.998009077266758],
            [98.727482236499128, 23.98582698679563],
            [98.679909236151531, 23.970753977911315],
            [98.687482285691686, 23.816391172220264],
            [98.81717228876451, 23.760727120714805],
            [98.852763358489341, 23.637773150328727],
            [98.806645121260857, 23.541735985685179],
            [98.827900286668665, 23.480482210574493],
            [98.86261829735642, 23.485827014968223],
            [98.887418169626955, 23.473044947866029],
            [98.904163368199676, 23.443053995306244],
            [98.915327225070172, 23.412491061672384],
            [98.924700204503239, 23.327218104754891],
            [98.921100177079239, 23.284164123697693],
            [98.927472267529112, 23.189164135756002],
            [99.104145184964494, 23.103882126383198],
            [99.20166326426525, 23.100273214141779],
            [99.233772323763247, 23.106627032042766],
            [99.257909187490696, 23.117635991342226],
            [99.357763303212977, 23.134227130533972],
            [99.511300323800782, 23.082045089255317],
            [99.564845262539592, 22.936591073198272],
            [99.551372358975783, 22.917773197602642],
            [99.514427276608131, 22.9126360967701],
            [99.442472325683269, 22.869991152589179],
            [99.344572366872882, 22.709991181720923],
            [99.386172255362993, 22.590209091143535],
            [99.384572317680323, 22.506945108784564],
            [99.376372301780947, 22.487082177498152],
            [99.362491199531433, 22.469164183030003],
            [99.348372386507123, 22.454464168837873],
            [99.330827219093095, 22.436936100506429],
            [99.293245112082303, 22.404854030736757],
            [99.167845134704891, 22.15591804361496],
            [99.241100286453758, 22.112500120320973],
            [99.366782231055055, 22.093473200059591],
            [99.409572349799447, 22.106382169176172],
            [99.434709174578558, 22.121382087864831],
            [99.465545190618883, 22.135827124561573],
            [99.588045197127514, 22.108891040441662],
            [99.659845250481368, 22.087354075448403],
            [99.685527234244461, 22.075409025199647],
            [99.709154142981475, 22.039718043188557],
            [99.743936191409688, 22.07374504977669],
            [99.892209209150366, 22.060553945798304],
            [99.957409184287286, 22.05018217878019],
            [99.99039130282253, 21.964373115334112],
            [99.974700212396243, 21.917218036679841],
            [99.959154128895108, 21.89193603733726],
            [99.945954140099587, 21.856664150486083],
            [99.942200220932961, 21.821245077414815],
            [99.977627172993238, 21.725545032917907],
            [99.998454190785679, 21.699853996699133],
            [100.084154121852094, 21.686109016557523],
            [100.141372345848794, 21.634164183139418],
            [100.107609201573467, 21.585136084396439],
            [100.120263360832865, 21.514509162214338],
            [100.167482309589474, 21.461109062762873],
            [100.205682330504061, 21.435464127011628],
            [100.282763318149108, 21.476936107659029],
            [100.345472359293268, 21.524791075230453],
            [100.373172370070762, 21.5381910562363],
            [100.42650021751669, 21.529445043162454],
            [100.456372314689162, 21.494718147657281],
            [100.477272255039367, 21.462145065998143],
            [100.572209211069008, 21.452353997233601],
            [100.639709180443589, 21.476936107659029],
            [100.721236264821556, 21.511109127000779],
            [100.800191278383892, 21.588053992538036],
            [100.808318204087385, 21.608054051760732],
            [100.835818222654353, 21.646664115379807],
            [101.099009312546428, 21.769026993952238],
            [101.1205633766223, 21.770518134531343],
            [101.16706332572258, 21.634854013772653],
            [101.169427190062578, 21.603673166234827],
            [101.148236230033291, 21.572635984517873],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MNE", Country: "Montenegro" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.228809212614692, 43.513218115359834],
            [19.254718178316551, 43.460000070845794],
            [19.279445128029295, 43.449718157829679],
            [19.444163272208186, 43.373600083224161],
            [19.514863284586056, 43.319573185051311],
            [19.557636136609858, 43.268744988777385],
            [19.585827159276704, 43.236109042844177],
            [19.644863250439187, 43.183882074564295],
            [19.663745163775189, 43.176244987283681],
            [19.69250012088915, 43.170827092694097],
            [19.783609229366022, 43.148045079799502],
            [19.92027331117626, 43.106382159397342],
            [19.969718158146463, 43.090554108672663],
            [20.035827066866091, 43.037218046961627],
            [20.059718173191925, 43.005554066523374],
            [20.150273237867253, 42.971944981628852],
            [20.171245094947011, 42.969300155893464],
            [20.259718094867708, 42.941664182856513],
            [20.29221808633099, 42.929435991917771],
            [20.346109197671922, 42.888745037010537],
            [20.37902711082873, 42.841800176488491],
            [20.231945161532536, 42.761109103135638],
            [20.104654059155251, 42.769718155911292],
            [20.071663223440567, 42.773464196088909],
            [20.039445031562906, 42.766109076031896],
            [20.015000216711854, 42.752491165542679],
            [19.985554087860066, 42.72763614034902],
            [19.977500252352399, 42.707009114767047],
            [20.014027077750427, 42.682145037117991],
            [20.048609133968284, 42.677773204047398],
            [20.070000253846018, 42.673882156944487],
            [20.099927168665289, 42.657700054628734],
            [20.071418136591092, 42.5609091892495],
            [20.056109093312614, 42.562909111352909],
            [19.927354175360676, 42.515554040488198],
            [19.822500254380202, 42.471936124983941],
            [19.793954174294072, 42.484091058088765],
            [19.769445154064385, 42.500964164504225],
            [19.746809153923692, 42.545000169340071],
            [19.7647911861323, 42.587873101288139],
            [19.754582195674146, 42.640973128605182],
            [19.717845152143866, 42.660345048002128],
            [19.651527198758401, 42.623191088606845],
            [19.633327069428361, 42.603336036309358],
            [19.611182248815027, 42.571036037056317],
            [19.608536081975245, 42.542845014389556],
            [19.519718082918899, 42.448609120743541],
            [19.288536140238818, 42.18290915958606],
            [19.33221809348359, 42.152500117694743],
            [19.378191156148688, 42.123464199185449],
            [19.399100148954375, 42.104091106321917],
            [19.376391226255635, 42.07280917303153],
            [19.34694509740396, 41.955554058630369],
            [19.34332713270723, 41.912500077573256],
            [19.36777300591524, 41.849000064999231],
            [19.368341902000168, 41.847946213000156],
            [19.374238905000112, 41.83949145800004],
            [19.409657402000107, 41.745884413000041],
            [19.413294628000131, 41.734529705999975],
            [19.396433112000153, 41.715037360000011],
            [19.347611113000141, 41.694427068000024],
            [19.286488496000118, 41.670539761000029],
            [19.154606755000145, 41.613509818000168],
            [18.461944444000153, 41.277499999000085],
            [18.216666667000112, 41.499999999],
            [18.020445856, 41.622009092000141],
            [18.19856132100017, 41.826416743000024],
            [18.20190726800007, 41.830220855],
            [18.278657022000175, 41.91945534500006],
            [18.354013041000144, 42.039415851000157],
            [18.490497025000138, 42.253486679000034],
            [18.521006671000038, 42.304323909000104],
            [18.543803121, 42.349361931000132],
            [18.545984140000087, 42.353744383000091],
            [18.546400397000156, 42.354813071000038],
            [18.548748728500158, 42.366348967500059],
            [18.550580843000091, 42.375870723000119],
            [18.551658028500185, 42.382183009999963],
            [18.551895598000158, 42.388226584000151],
            [18.552246744500138, 42.392083612500116],
            [18.550645649000131, 42.394520103000147],
            [18.549783788000127, 42.395627599000122],
            [18.546979647000114, 42.399547128000151],
            [18.544992942000135, 42.401657745000065],
            [18.543472300000161, 42.40355474],
            [18.541604323000058, 42.404573145000072],
            [18.538301520000175, 42.406116410000024],
            [18.535705931000166, 42.407608035],
            [18.53494814600009, 42.40820297],
            [18.533145110500158, 42.409632639500032],
            [18.533986520000099, 42.415236540000038],
            [18.535827600000118, 42.423520549999964],
            [18.535827520000112, 42.429043540000052],
            [18.533986650000116, 42.43456626000004],
            [18.531225220000067, 42.438708410000018],
            [18.52570237000009, 42.442390310000107],
            [18.522299450000162, 42.444092190000148],
            [18.520179510000077, 42.445151739999957],
            [18.516590590000135, 42.446547880000011],
            [18.511895230000135, 42.448373400000079],
            [18.510112500000162, 42.448697540000168],
            [18.506832610000117, 42.449293880000127],
            [18.504473681666838, 42.449386757999989],
            [18.503200684923257, 42.449436470162809],
            [18.498054104618035, 42.451109107191527],
            [18.472918118029185, 42.471527088107834],
            [18.457782244870714, 42.493609044447211],
            [18.453327095682482, 42.516800094217714],
            [18.455554167362436, 42.565827019494506],
            [18.463891143560005, 42.569444984191122],
            [18.543327110728001, 42.615136079632705],
            [18.572082235480082, 42.641173120815537],
            [18.576382151821008, 42.663054079116066],
            [18.557845069982619, 42.713954024481367],
            [18.523818231032664, 42.727564223619751],
            [18.490836112497334, 42.766391210893502],
            [18.455409160437029, 42.828609072510389],
            [18.481736047832413, 42.966173203086029],
            [18.503054077514207, 42.995409113805763],
            [18.530000219917895, 43.014718168928752],
            [18.552218130727169, 43.022773177902891],
            [18.579718149294223, 43.029164211453988],
            [18.647045116186831, 43.043400203485064],
            [18.658118113226749, 43.08388211372646],
            [18.648263341997733, 43.127073222719943],
            [18.645836110469332, 43.147218121229983],
            [18.702773205432749, 43.257145105302371],
            [18.839336201490624, 43.348018011747072],
            [18.880827125239165, 43.35553607600211],
            [18.915573299121633, 43.35777320596587],
            [18.95812721583377, 43.320909092783225],
            [18.955054074845066, 43.293891201288133],
            [19.016663242651504, 43.239435987861796],
            [19.05957322261159, 43.235945092817659],
            [19.075554159250601, 43.255273091041872],
            [19.092918109917321, 43.305273155279977],
            [19.025273301255453, 43.432354039524952],
            [19.005000159626348, 43.447353958213412],
            [18.982773196361762, 43.45277319390776],
            [18.957145024417059, 43.455900146715109],
            [18.949854109738567, 43.506035997784849],
            [19.009163283307259, 43.555973197748798],
            [19.217782148404325, 43.519027109552681],
            [19.228809212614692, 43.513218115359834],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MNG", Country: "Mongolia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [116.711382268452809, 49.830464263825391],
            [116.646654194466151, 49.738045063878658],
            [116.570263372730409, 49.626654096593469],
            [116.500545216493293, 49.524709031299167],
            [116.431091257844884, 49.422491218874953],
            [116.349154130762514, 49.301654014322693],
            [116.279982306975882, 49.199154067036758],
            [116.129963338798774, 48.975545006258884],
            [116.059009187115834, 48.868045086076606],
            [116.084154226160223, 48.815827002614228],
            [115.968045253202462, 48.690545042433811],
            [115.942200325241089, 48.663882208358089],
            [115.811100318412201, 48.520545125773126],
            [115.82221824245326, 48.403045092160468],
            [115.828872300126676, 48.338327076457588],
            [115.831672358709255, 48.306100167400515],
            [115.834427155014652, 48.273882143160833],
            [115.835818215669491, 48.252491190921191],
            [115.621372419106734, 48.173044997831582],
            [115.549072301408074, 48.143527119888333],
            [115.594409177620349, 47.917491162858298],
            [115.923118252056582, 47.69186424270417],
            [115.966082379111384, 47.690127009447096],
            [116.039963323753256, 47.752218136687574],
            [116.097209208030534, 47.804436052511974],
            [116.115536239374791, 47.821936125286683],
            [116.262363378813831, 47.878182216150307],
            [116.368591261366447, 47.858891098300134],
            [116.507763376251972, 47.845273187811003],
            [116.592209206961769, 47.857218070421581],
            [116.726927178487443, 47.876100151395732],
            [116.792763340077073, 47.881936135316934],
            [116.874691247065954, 47.888045034006424],
            [117.113436181002413, 47.806582155006978],
            [117.327754237360438, 47.669153978901065],
            [117.352463417438429, 47.657409088500728],
            [117.382672299481413, 47.657418140956239],
            [117.616482304090397, 47.84998214102815],
            [117.641345208273066, 47.874154041110998],
            [117.662182284349257, 47.894009093408485],
            [117.804554106961945, 48.011245097070272],
            [117.838318257065652, 48.015273104471575],
            [117.879545150863379, 48.01837323518869],
            [118.055545169109877, 48.013054079418723],
            [118.230272311536538, 48.029991223574527],
            [118.394145224596826, 48.003882098024334],
            [118.539336216531382, 47.994754037802792],
            [118.608018201895248, 47.940844989188847],
            [118.667209190628768, 47.878891157522943],
            [118.767291294117939, 47.772773245540108],
            [118.819763349247324, 47.756382098558717],
            [118.972209213676734, 47.710544990363431],
            [119.055800257898255, 47.685818040650659],
            [119.124972416961015, 47.664982138040827],
            [119.131345177963141, 47.634154000989597],
            [119.149427290095929, 47.533882131212025],
            [119.241091280564575, 47.509436142894501],
            [119.340818326634206, 47.462209147510762],
            [119.338318172548071, 47.419718095072582],
            [119.40670025341538, 47.370573152598993],
            [119.43914525959363, 47.356945016187879],
            [119.49802729137528, 47.321382109657677],
            [119.625527270780282, 47.241382208042737],
            [119.656100262698118, 47.219436038535193],
            [119.727482226720298, 47.16651806615549],
            [119.775272318360834, 47.109991181534653],
            [119.789045126421087, 47.083045039131022],
            [119.78999127565416, 47.019027079983303],
            [119.798736282899569, 46.998054049437073],
            [119.871372347278367, 46.921800187999636],
            [119.922491227955305, 46.902218050470353],
            [119.931509317606952, 46.71512709791115],
            [119.898036187182385, 46.676945014269407],
            [119.854709291357068, 46.656654103005664],
            [119.751391270318919, 46.613609174404061],
            [119.706791331035816, 46.598945034757477],
            [119.679282260013309, 46.604164110603378],
            [119.65262730492671, 46.623045018110972],
            [119.535809222958363, 46.63471815941999],
            [119.462772335968651, 46.639164256152739],
            [119.441082317422882, 46.636527141768354],
            [119.417200263552644, 46.626936065214053],
            [119.395545281362303, 46.61777313627509],
            [119.363036237443481, 46.61443613297358],
            [119.300536240965101, 46.620964126822983],
            [119.091663404201057, 46.666664107081786],
            [119.070600352014566, 46.672664208667683],
            [119.03851828224478, 46.693182101870079],
            [119.019709291466569, 46.71720916266581],
            [118.971918193997539, 46.742218079602182],
            [118.886936256759242, 46.769718098169207],
            [118.866500338569978, 46.772009039951527],
            [118.841718235934223, 46.763253974422284],
            [118.787554209825061, 46.716727035593848],
            [118.774636188253083, 46.686654108020875],
            [118.456236184199241, 46.694291195301403],
            [118.43664516185251, 46.699291168197547],
            [118.409572285072528, 46.719573194643985],
            [118.31470925742903, 46.736382095680895],
            [118.290818318741316, 46.731100155561123],
            [118.117482236969551, 46.678045055245136],
            [117.933591327413552, 46.619436105869838],
            [117.908454167358201, 46.605136076098219],
            [117.876082251375891, 46.581664232570589],
            [117.862972284220547, 46.564091069599911],
            [117.845545134003686, 46.53645409073458],
            [117.716663314037334, 46.513882128334231],
            [117.632754260408404, 46.551100125470057],
            [117.601927296823675, 46.605827080198125],
            [117.424982302810491, 46.57069114781676],
            [117.443863377956319, 46.527773121229657],
            [117.392763440380492, 46.457500083000625],
            [117.375527229918561, 46.41888214039254],
            [117.382209283148683, 46.399018035639628],
            [117.370818276701442, 46.364436147059777],
            [117.078600320755669, 46.361935992973727],
            [116.90220920290588, 46.383882162481186],
            [116.841663363339222, 46.393609193505469],
            [116.819027195560381, 46.383044977989655],
            [116.777063364833367, 46.351109088611693],
            [116.758609263836519, 46.331109029388884],
            [116.671800239338808, 46.32723608719688],
            [116.585536206187129, 46.295827084253901],
            [116.569991296152608, 46.252564226169127],
            [116.540818249706973, 46.225264199812344],
            [116.459427287437023, 46.168054022442831],
            [116.419282329704203, 46.141382135911712],
            [116.384163328767357, 46.112500109145216],
            [116.359145191737497, 46.083744984393078],
            [116.294009254341091, 45.996109168963798],
            [116.264991273104641, 45.950682103473113],
            [116.236091309065301, 45.900964174096785],
            [116.239291352068591, 45.876309141113595],
            [116.268463392685732, 45.843182183291034],
            [116.280063443798809, 45.808945126208741],
            [116.21040027284667, 45.721927057045249],
            [116.189563364408428, 45.708745005522459],
            [116.149991225940227, 45.693318112685077],
            [116.112200241901775, 45.681109032485622],
            [116.088600322892972, 45.68318221242275],
            [116.031372375888594, 45.685545070934452],
            [115.937191299889406, 45.635827141558238],
            [115.800809185302711, 45.545544991650999],
            [115.701927202713506, 45.45860018032154],
            [115.474700344876879, 45.414153965143115],
            [115.392209341631002, 45.398882137514832],
            [115.294709199603147, 45.40054510710938],
            [115.174427212318875, 45.403882110410891],
            [114.96943620256431, 45.384991144619406],
            [114.938027199621473, 45.382764240577515],
            [114.844991259237332, 45.410273143962073],
            [114.743045188114678, 45.440545057916907],
            [114.545254233207942, 45.38943606788574],
            [114.543318181207184, 45.348882240914904],
            [114.541091277165179, 45.322773115364626],
            [114.45400933789935, 45.206109092777083],
            [114.358600313081553, 45.143054046668752],
            [114.337200308386485, 45.129436136179649],
            [114.243045216287243, 45.070827019166174],
            [114.14553618944197, 44.996100172853389],
            [114.131100205200568, 44.980273127957204],
            [114.075545118436679, 44.939154025434661],
            [114.022772320620419, 44.929436046865774],
            [113.990809273876181, 44.925000176054922],
            [113.969982256083739, 44.92401815227619],
            [113.916927155767809, 44.92276421955755],
            [113.817209329791609, 44.8616641685514],
            [113.744709219882708, 44.810273211296632],
            [113.722209174211827, 44.793054099917114],
            [113.638045143087567, 44.745273228370152],
            [113.551936175145016, 44.752491220490683],
            [113.511109265767885, 44.777218170203625],
            [113.242482344013382, 44.78860917665078],
            [113.143045144156218, 44.795127112216264],
            [112.853045207434974, 44.846100147777619],
            [112.607482265798609, 44.92638218425445],
            [112.592482347110064, 44.951100081511626],
            [112.556500345419948, 44.993745025692633],
            [112.500263307011636, 45.033609189668326],
            [112.427200268483915, 45.080554050190372],
            [112.364700272005535, 45.067773156554651],
            [112.230818311504976, 45.071382236433962],
            [112.097209265772477, 45.075273115898895],
            [112.083941299741952, 45.091032183560216],
            [112.051091363820063, 45.100545056957671],
            [111.980818157953166, 45.091664262880485],
            [111.873027218091892, 45.049436066926418],
            [111.784991250603895, 45.000545096119808],
            [111.765263435597006, 44.973045077552783],
            [111.630809326384082, 44.774164139954038],
            [111.56790029302951, 44.677082087257688],
            [111.570545118764898, 44.648609097367327],
            [111.571663432289739, 44.620544976714982],
            [111.568600349584727, 44.596936005250868],
            [111.555545200076352, 44.570273171175231],
            [111.515827216492454, 44.521382200368535],
            [111.496091354858407, 44.498882154697569],
            [111.475536246005817, 44.476382109026687],
            [111.4586002753164, 44.458600069028435],
            [111.438863407854086, 44.439154053607055],
            [111.429009307177381, 44.419018039914349],
            [111.421372387534831, 44.375273222395705],
            [111.429418176415396, 44.332835981775972],
            [111.524991318897804, 44.243327146257897],
            [111.556927208275766, 44.17470903099651],
            [111.676645261112895, 44.050136012188645],
            [111.717482228773832, 44.033882160781516],
            [111.808027235165326, 43.996382196422175],
            [111.879436188915776, 43.938882172839826],
            [111.95971822539255, 43.835264247305176],
            [111.976791324018308, 43.769573092640897],
            [111.964427178609782, 43.712209191166536],
            [111.95832716473771, 43.692218016761146],
            [111.897218228914113, 43.674164235461191],
            [111.783863381709438, 43.672491207582638],
            [111.757218317268752, 43.649718079505462],
            [111.638600305407437, 43.54332708511653],
            [111.502772402260206, 43.494436114309835],
            [111.326663419272137, 43.432209032599545],
            [111.225809174860188, 43.405264231300393],
            [111.076927295671084, 43.359164266620908],
            [110.989700349479932, 43.316936070666927],
            [110.871372351469432, 43.202218158364147],
            [110.742754226177595, 43.094153968458571],
            [110.689972375906024, 43.053600141487621],
            [110.673600339663807, 43.00332699484342],
            [110.639972311668004, 42.956654043261196],
            [110.623027288523161, 42.939991154978074],
            [110.564700306371407, 42.896664259152757],
            [110.515545137975749, 42.870545075318674],
            [110.471918170015982, 42.846100092829616],
            [110.44886340999139, 42.797773224108241],
            [110.440536156801414, 42.777773164885431],
            [110.361191217102402, 42.75030918850193],
            [110.133391204724973, 42.673809066748632],
            [110.106372307401472, 42.645764224473666],
            [110.000000256113736, 42.642464269184288],
            [109.934972277630521, 42.641109083074909],
            [109.743872260771269, 42.5758271329248],
            [109.689700188035118, 42.555827073702105],
            [109.544709188310918, 42.473873182813193],
            [109.514009294378667, 42.456518116963863],
            [109.393600237441916, 42.450545005106292],
            [109.320827212764783, 42.447773109718511],
            [109.310672369401374, 42.429991069720174],
            [109.098036225739037, 42.44943607931306],
            [109.000000312458752, 42.458327099312228],
            [108.847072320957494, 42.399991064704963],
            [108.67998226581139, 42.422218027969549],
            [108.527772268138193, 42.442218087192359],
            [108.306645256535461, 42.437073107370978],
            [108.249445137449612, 42.462345048429754],
            [108.224427168057758, 42.458882148942038],
            [107.907763391432496, 42.408327035074322],
            [107.563045217162681, 42.415691207586733],
            [107.54484525547079, 42.430827080745203],
            [107.522763299131526, 42.449709161719269],
            [107.496509334293904, 42.463045104984772],
            [107.475818270971416, 42.466244980350083],
            [107.277209242312466, 42.410545054298765],
            [107.262500343303003, 42.359991113897351],
            [107.017763354407776, 42.315827033580618],
            [106.986100212159926, 42.310818008228964],
            [106.854709185652013, 42.300273238728707],
            [106.781936160974936, 42.295545007134294],
            [106.496372262702636, 42.20166417090762],
            [106.122482215453573, 42.077491136520351],
            [105.855263285798429, 41.987500173930314],
            [105.588318276739784, 41.891382207739994],
            [105.474982372636447, 41.83430010585117],
            [105.350809170611086, 41.785273180574663],
            [105.222491285091991, 41.746800077253894],
            [105.006509253310838, 41.586654093631822],
            [104.930545238086012, 41.651927158964597],
            [104.847636145508631, 41.649718024557458],
            [104.684418194364326, 41.645827145092525],
            [104.523736271851845, 41.670682170286184],
            [104.525272339432007, 41.761382074248942],
            [104.526654179993358, 41.877209079982876],
            [104.069427227827731, 41.804291216018626],
            [103.849427246929139, 41.80249111848768],
            [103.416382253130308, 41.887218078230873],
            [103.352200343956014, 41.912491192755922],
            [103.143882221545624, 41.994154063965723],
            [102.88275425330707, 42.096382102311921],
            [102.712491312524065, 42.161373200421096],
            [102.550263431785623, 42.166664192996265],
            [102.515263286236205, 42.161664220100207],
            [102.471363403508207, 42.154709084463704],
            [102.44246327183086, 42.151100172222485],
            [102.077209267085692, 42.233327145517492],
            [102.03553612076152, 42.276100165179386],
            [101.992200340118956, 42.32277311676161],
            [101.814700296475991, 42.509718056566996],
            [101.595545211419932, 42.530545074359523],
            [101.411372334640333, 42.56750004737286],
            [101.219709221524198, 42.604718044508772],
            [100.972763265860095, 42.652491204704816],
            [100.842482338611688, 42.677073147492266],
            [100.696927236802225, 42.681664251150423],
            [100.312763323164319, 42.689573247370745],
            [100.254991223004282, 42.641109083074909],
            [100.133327227520482, 42.644436028092699],
            [100.031518284334169, 42.649027131750742],
            [99.98637234787671, 42.653600130498049],
            [99.510127192629028, 42.571944970639308],
            [99.302200337459993, 42.583600174675425],
            [99.240372401980068, 42.586791165223119],
            [99.119709205738246, 42.594153996631164],
            [98.769145156901317, 42.615545116508812],
            [98.488309322585422, 42.631664186912403],
            [98.361927153791129, 42.638600044171412],
            [98.319718236214641, 42.640827115851479],
            [98.221372360153936, 42.645827088747538],
            [98.199145229251172, 42.64832707519561],
            [98.074154121111661, 42.66693607376358],
            [97.826518167176147, 42.703991126701183],
            [97.755263272806445, 42.714435983915436],
            [97.602763261282547, 42.736654062362604],
            [97.533054157500857, 42.746100131991668],
            [97.491363409180025, 42.751936115912955],
            [97.449709205957191, 42.757773105662451],
            [97.386154095503997, 42.767345071477521],
            [97.165400246231712, 42.795827113823393],
            [96.998600204936366, 42.761382185541848],
            [96.959718232377469, 42.759991124887094],
            [96.846372269990212, 42.758609116687566],
            [96.761109203718576, 42.756100077783984],
            [96.725818206128082, 42.754436102360941],
            [96.568600188931526, 42.744154021706933],
            [96.398463311972733, 42.731754001752719],
            [96.38206328017381, 42.734991092768027],
            [96.356936178402378, 42.906936113884953],
            [96.12302726733563, 43.081664262140052],
            [95.940263388483771, 43.216664200889269],
            [95.917209299011489, 43.234018093272127],
            [95.879009278096788, 43.283882203040307],
            [95.869982303627722, 43.319436057115112],
            [95.869427253997941, 43.354718169888073],
            [95.865400252425019, 43.410827132815385],
            [95.850954209899868, 43.435127108379319],
            [95.834300206434051, 43.454018074170804],
            [95.807482307149343, 43.488600130388633],
            [95.778591227927365, 43.533882188954024],
            [95.746372365497365, 43.584991178985277],
            [95.720127285477275, 43.632218174368816],
            [95.674700219986477, 43.719436068104571],
            [95.665263370451015, 43.738600116302351],
            [95.656372350451846, 43.759164109972389],
            [95.645827245675463, 43.784718018254679],
            [95.638882335961029, 43.805827003270721],
            [95.627063349536371, 43.859027110512031],
            [95.533391222699464, 43.993118115678342],
            [95.477763381015706, 43.993609127567808],
            [95.426654223346446, 43.998882182870076],
            [95.384154118452813, 44.005554010178386],
            [95.358872286748209, 44.009718139687592],
            [95.341091252578252, 44.019364201526841],
            [95.346100277929935, 44.158044969246916],
            [95.350263401610704, 44.177773119529903],
            [95.375818148083425, 44.226236110359267],
            [95.397345222430971, 44.248191164684144],
            [95.419982228400187, 44.27575404752524],
            [95.41060924896712, 44.294164227349242],
            [95.285809248220488, 44.28777319379823],
            [95.202772247800368, 44.275554055314885],
            [95.179154223880772, 44.272764222654175],
            [95.028736277111392, 44.257082184683313],
            [94.717345220512499, 44.354991195949182],
            [94.702900183815672, 44.372353973149544],
            [94.684700222123837, 44.392491160308722],
            [94.656372406796919, 44.415545082142941],
            [94.603182357839529, 44.451800166239323],
            [94.529982359013474, 44.479991188906084],
            [94.441927280786246, 44.515545042980889],
            [94.34304513055892, 44.557209136849437],
            [94.330272283550329, 44.575000061665193],
            [94.31346338251339, 44.590409184867937],
            [94.246091320981549, 44.632209065568432],
            [94.110809247370668, 44.709991115596992],
            [94.085400178375579, 44.722627002307476],
            [93.970263338550893, 44.777491084971743],
            [93.919709230511472, 44.797773111418067],
            [93.860809261456978, 44.818327046804299],
            [93.835536146931787, 44.828609127458392],
            [93.755554182589464, 44.862500179576429],
            [93.735263271325749, 44.875827070386535],
            [93.71609134413896, 44.896664146462754],
            [93.674991352355534, 44.915827021194062],
            [93.655254149617093, 44.923045013314763],
            [93.574436174249882, 44.950826999105416],
            [93.554700312615836, 44.957218032656428],
            [93.530818258745541, 44.962209120735352],
            [93.370254185791936, 44.99082711755112],
            [93.317491278621645, 44.984991133629748],
            [93.292072318980757, 44.983600072974909],
            [93.260545131202747, 44.989991106526006],
            [93.233045112635608, 44.998045109671651],
            [93.179427251339035, 45.016391084117217],
            [93.172209259218306, 45.013882212851726],
            [93.148609172571554, 45.010827009135753],
            [93.08665416743915, 45.009991165748787],
            [92.990263286480598, 45.009718083342605],
            [92.928654118674189, 45.025691140992492],
            [92.882754146204917, 45.045827154685199],
            [92.788109215682795, 45.047636137033649],
            [92.756945131951312, 45.041382231418837],
            [92.720536156112217, 45.033882104436529],
            [92.653454108431077, 45.022073176295677],
            [92.492763301101235, 45.003327049791423],
            [92.310254232106644, 45.026382145092199],
            [92.092482328716386, 45.079436071941629],
            [92.067491349052858, 45.083054036638444],
            [92.038591217375455, 45.084164135898064],
            [92.013045188082202, 45.082218025613415],
            [91.867754116223381, 45.074718066269085],
            [91.560882246553064, 45.0772820904577],
            [91.530272374261216, 45.096936144716452],
            [91.501936177030956, 45.124435995645499],
            [91.471163360540828, 45.152427026101904],
            [91.443591257606244, 45.153118030201526],
            [91.401927163737724, 45.126100138706519],
            [91.379154203298611, 45.119018101055715],
            [91.285954145249804, 45.134018019744289],
            [91.250545130462314, 45.143609096298675],
            [91.230545238877625, 45.151100170825586],
            [91.190663305267066, 45.170964107940407],
            [91.164291323232561, 45.200827152657311],
            [91.137909282914194, 45.213464212833983],
            [91.038872402753753, 45.224153989259733],
            [91.011382274832414, 45.22360011309631],
            [90.896945156286762, 45.253053953299016],
            [90.807754162538032, 45.371100151724065],
            [90.777209166176846, 45.436382101874173],
            [90.753454181959086, 45.450264209952181],
            [90.718318249577834, 45.469982134313383],
            [90.68387231546788, 45.492073143108158],
            [90.681927211011526, 45.579718178630813],
            [90.719436227826435, 45.735544967534381],
            [90.856936153023838, 45.887218020488703],
            [90.874145206119437, 45.903045065385058],
            [90.945527170141617, 45.958600152148946],
            [90.965000175291237, 45.973045021207682],
            [91.003391303598761, 45.995900124298046],
            [91.026509263173494, 46.017354108449737],
            [91.02665410246081, 46.108045127594991],
            [91.016727246864349, 46.134227008065054],
            [90.989009298814068, 46.155818120153029],
            [90.975263312844021, 46.173327077745185],
            [90.961927201940426, 46.197773233700715],
            [90.921509161801424, 46.296936010047133],
            [90.931227140370112, 46.325264160650292],
            [90.960536141285587, 46.351109088611693],
            [91.053591192409016, 46.523609159358429],
            [91.061091319391352, 46.542773207556294],
            [91.07026330078574, 46.577354090307566],
            [91.020245131636869, 46.600109113473749],
            [91.036372248667618, 46.67110903034812],
            [91.043309279393014, 46.724845076480037],
            [91.029163309002485, 46.75193605817087],
            [90.950272333180493, 46.889991200636061],
            [90.91360033321908, 46.952218114708344],
            [90.890272323150128, 46.970544978414537],
            [90.857209235429963, 46.98860010081917],
            [90.83568232872048, 46.994854006433869],
            [90.810818251071424, 46.994154117516828],
            [90.769018202732894, 46.997491120818339],
            [90.711382224680705, 47.041935994892199],
            [90.581663387860971, 47.19610014947726],
            [90.544145150952517, 47.244436070654146],
            [90.493309243327559, 47.31707314086124],
            [90.465818277215959, 47.500273213955509],
            [90.424991200200822, 47.527773232522648],
            [90.399427233634555, 47.556654085822643],
            [90.35120027719924, 47.627973185570838],
            [90.268327226805667, 47.697073092628088],
            [90.143109304365595, 47.73220919264746],
            [90.10026336214591, 47.768600063575477],
            [90.087072258167751, 47.789582146577217],
            [90.081100319776652, 47.830827145285852],
            [90.090400209013836, 47.8629821376134],
            [90.070963413685917, 47.887909079536499],
            [89.991800193648174, 47.893682199183829],
            [89.968800251270494, 47.877936123472423],
            [89.962627147202653, 47.848209033225416],
            [89.93123624917061, 47.83528212683602],
            [89.785536140435767, 47.829264255615371],
            [89.670263346141127, 47.911427023531857],
            [89.60137231611165, 47.982573120797937],
            [89.60264519193143, 48.007773145127246],
            [89.592763263336082, 48.027282192460788],
            [89.548727258500236, 48.042082118938978],
            [89.389918188438486, 48.046736086871093],
            [89.293354137359955, 48.001173234548489],
            [89.224845154478231, 47.980818117906225],
            [89.085136262511895, 47.993736139478315],
            [89.06331816848521, 48.005827034842596],
            [89.039154147391258, 48.030545099737949],
            [89.019009248881247, 48.050691171714462],
            [88.956372292104533, 48.102773133069022],
            [88.937336151749577, 48.114164139516376],
            [88.86137230416287, 48.116382158740848],
            [88.659854084541536, 48.179991081012375],
            [88.611782360953583, 48.211936022845848],
            [88.59845412903897, 48.230264227656605],
            [88.583045173474289, 48.287218086426449],
            [88.592136185683756, 48.316727079552194],
            [88.591091297631039, 48.348327022250018],
            [88.516791257829283, 48.405818161014835],
            [88.320263288096527, 48.479991131164198],
            [88.241782354979108, 48.505554091901885],
            [88.212627245806431, 48.501664218265361],
            [88.191227241111307, 48.500691079304133],
            [88.047209212710442, 48.544718031684383],
            [88.013609180271288, 48.556382120537918],
            [87.991936260808046, 48.565409095006899],
            [87.967482225863506, 48.587773186207968],
            [87.97436326547566, 48.61076424376823],
            [88.032482208789958, 48.6469450642022],
            [88.086482284872574, 48.696554028836928],
            [88.059418292909896, 48.734991089974059],
            [88.039154203736274, 48.747218107446329],
            [87.991582209217, 48.765754015818359],
            [87.957491332526587, 48.766382155644109],
            [87.933872302778667, 48.761800104441377],
            [87.842482232906576, 48.794653979857841],
            [87.761100323092109, 48.881036030206459],
            [87.774845135595655, 48.919573171267857],
            [87.81219120821234, 48.939991152183993],
            [87.842209150500395, 48.945264207486431],
            [87.873036114085124, 48.954918148314889],
            [87.892909271293519, 48.983045133241106],
            [87.898882215512998, 49.005554063729491],
            [87.868045193644463, 49.11360903354138],
            [87.840700240286566, 49.172954081655902],
            [87.857482319233299, 49.167218177658881],
            [87.888045252867158, 49.162491119530657],
            [87.992282265772104, 49.179854064369195],
            [88.156645184893335, 49.268882113919787],
            [88.173382336839069, 49.287218030081462],
            [88.158872256573289, 49.310545034321947],
            [88.133527225318886, 49.369364201829583],
            [88.17942719778793, 49.43415396662401],
            [88.202063365566829, 49.452764138658367],
            [88.220827261705807, 49.461664211112847],
            [88.346372246008599, 49.482209094043569],
            [88.40776331669386, 49.483882121922122],
            [88.568882271639183, 49.475818060492585],
            [88.730818294508396, 49.450827080829029],
            [88.851654157956006, 49.441654093606232],
            [88.902827185727659, 49.449991069804],
            [88.877472263827173, 49.478254009200313],
            [88.88644509120158, 49.53971817008123],
            [88.924563304740985, 49.546591163066282],
            [88.950127271307252, 49.518609185065273],
            [88.987409306183423, 49.468882203233463],
            [89.008191229336745, 49.463054098301299],
            [89.150545114676476, 49.498327158618864],
            [89.188591243848322, 49.509582210596292],
            [89.208600187888607, 49.520691082181841],
            [89.236445205591309, 49.545964196707089],
            [89.233382290524304, 49.570200134530481],
            [89.201027138348337, 49.601382155534694],
            [89.204145206338268, 49.622491140550821],
            [89.223318139353552, 49.636936009609471],
            [89.252972307042597, 49.642982211662897],
            [89.285536168608331, 49.632491086343137],
            [89.319845142419894, 49.609718125904052],
            [89.373109287401547, 49.582073100411705],
            [89.403045254676414, 49.591654118682001],
            [89.442200309641493, 49.627491113446794],
            [89.456936198379282, 49.648464143993024],
            [89.564991335829262, 49.678182181784607],
            [89.589982315492904, 49.680827007520023],
            [89.626927230222435, 49.684300132929522],
            [89.722000308360094, 49.722418178830722],
            [89.735463321278047, 49.74575406788874],
            [89.721645250940611, 49.764300202182639],
            [89.69345422827368, 49.775554080693595],
            [89.659009299992107, 49.788182088414871],
            [89.637218195693862, 49.858327051162902],
            [89.642700295662138, 49.904827000263126],
            [89.650818168910092, 49.911936027642142],
            [89.673036247357373, 49.924709042288825],
            [89.70138233523349, 49.934991122942947],
            [89.726382199714266, 49.940544972002513],
            [89.762772400090256, 49.946654038329811],
            [89.807482309943254, 49.951244974350047],
            [89.838045243577341, 49.949718126863289],
            [89.871091232214866, 49.946654038329811],
            [89.900272325287546, 49.948045098984821],
            [89.981372267878413, 49.97110019428554],
            [90.005691186543487, 49.980909032685005],
            [90.021100309746288, 49.996382193628094],
            [90.022627157232904, 50.046109175459904],
            [90.031927214108265, 50.068191131799281],
            [90.04860032831337, 50.080964146445965],
            [90.071927164915763, 50.090544997078283],
            [90.103591312992108, 50.098873256096695],
            [90.179154170329724, 50.110545056301177],
            [90.219145236319861, 50.114718070627717],
            [90.244427235662414, 50.120273260791762],
            [90.27720919434941, 50.134164253687189],
            [90.30455414770725, 50.150545007108704],
            [90.338463304736393, 50.169854062231693],
            [90.378582278569269, 50.185818067426268],
            [90.509718159943958, 50.224436010034438],
            [90.530545177736229, 50.226382120319087],
            [90.557754176624229, 50.226100153095487],
            [90.61290022651221, 50.215691170427064],
            [90.643327205676457, 50.211936077793879],
            [90.673309273418653, 50.213882188078614],
            [90.69859127276132, 50.219436037138095],
            [90.723600189697578, 50.231100125991631],
            [90.893054109183964, 50.33915408997531],
            [90.926091213004014, 50.367491125395688],
            [90.95915413308623, 50.396100069756059],
            [90.979709241938878, 50.409991062651486],
            [91.002491254833359, 50.422491162529965],
            [91.039563406853546, 50.434154077916915],
            [91.101091270199021, 50.431664149752734],
            [91.128582236310507, 50.431109100122924],
            [91.159709272029914, 50.433873116521696],
            [91.351091256112653, 50.47610013900929],
            [91.458036126665007, 50.50777317190294],
            [91.615536111085106, 50.577773127725592],
            [91.634154162108558, 50.595545109440124],
            [91.653182255836413, 50.631100136981203],
            [91.668872340434376, 50.644154113023191],
            [91.69762712991033, 50.660545092366604],
            [91.723872377568483, 50.672218066037473],
            [91.779854103205395, 50.692345027274669],
            [91.819718267181116, 50.703327164483895],
            [91.884718250107511, 50.713054027870001],
            [91.905545100261833, 50.714718170931135],
            [91.952209167026666, 50.714436036069529],
            [92.007218256616426, 50.705273107130566],
            [92.038318302607451, 50.697909102256048],
            [92.08526333076739, 50.691654023175047],
            [92.111927170671407, 50.690545097381815],
            [92.162200317315495, 50.693045083829972],
            [92.190263264501397, 50.697209045701001],
            [92.22137236294779, 50.705554068525871],
            [92.264163319882613, 50.727073096246315],
            [92.295391273716376, 50.754991204144957],
            [92.317209200105054, 50.790691070973466],
            [92.322772269258024, 50.814991214175294],
            [92.44775415730399, 50.810818032210662],
            [92.47026342306836, 50.787082158732048],
            [92.640545139314639, 50.688327078157258],
            [92.66998221571086, 50.681382168442909],
            [92.698863404287096, 50.683054022854989],
            [92.736509213762162, 50.697345000170927],
            [92.762491269660046, 50.714300081599632],
            [92.779154157943111, 50.740618084177598],
            [92.789363316039413, 50.780618034985011],
            [92.812627288367793, 50.794582118076377],
            [92.933172299774441, 50.799582090972635],
            [92.96776340844778, 50.793054097123232],
            [92.994354158155971, 50.772491109281603],
            [92.998591210223054, 50.748609055411279],
            [92.973318263335756, 50.69207311833496],
            [92.970782234703989, 50.670882158305645],
            [93.026518202938888, 50.610827162990276],
            [93.088882244947428, 50.599718123766664],
            [93.112763292989513, 50.596100159069934],
            [93.142209254203209, 50.596936002456786],
            [93.341509286961781, 50.604745086391219],
            [93.384154231142787, 50.614164166291872],
            [93.409145210806315, 50.619709130534048],
            [93.450809304675062, 50.622073162512308],
            [93.480545112101396, 50.617209144085891],
            [93.578045254129194, 50.590273227604158],
            [93.735954107787364, 50.579300142850329],
            [93.895263409832097, 50.584164161276576],
            [93.98304523801491, 50.585264202252503],
            [94.218872263809743, 50.577773127725592],
            [94.244427177920414, 50.575554102672712],
            [94.283736124628234, 50.564854100325292],
            [94.301654119096469, 50.548745088205308],
            [94.352200180508788, 50.414991203185721],
            [94.356372356645096, 50.382491211722439],
            [94.354427252188799, 50.346654049319554],
            [94.357345160330368, 50.311382162468291],
            [94.373309165524944, 50.246382179541897],
            [94.386936128469557, 50.219573165074493],
            [94.399991277977932, 50.203609159879917],
            [94.430954196032502, 50.186654078451383],
            [94.504845198958122, 50.167009076648057],
            [94.52720929015922, 50.147636151422503],
            [94.573882241741359, 50.088600060260106],
            [94.629009180890108, 50.027218042030356],
            [94.664700330539205, 50.025964109311801],
            [94.716654216412707, 50.035418057929704],
            [94.781100323176048, 50.056518158128327],
            [94.819845167798434, 50.059991115899734],
            [94.980263395636399, 50.052073234861993],
            [94.999709243419574, 50.037500122684364],
            [95.012345130130058, 50.023036142886312],
            [95.064491296862997, 49.96436416159888],
            [95.097345172279375, 49.956518029652401],
            [95.155818167184833, 49.957218086207618],
            [95.204709305629621, 49.958045044777137],
            [95.225536155783942, 49.95943610543209],
            [95.264163318485629, 49.959718072655605],
            [95.349154140541401, 49.958600094407061],
            [95.414018168998012, 49.95693611898399],
            [95.438582342150795, 49.949854081333186],
            [95.491572398897773, 49.90770014904156],
            [95.524154197736237, 49.895336003632892],
            [95.74755421384836, 49.960545031225323],
            [95.767345228405446, 49.977764142604727],
            [95.785545190097281, 50.002082223079569],
            [95.804427271071432, 50.016527092138219],
            [95.825963397874403, 50.023464122863729],
            [95.86400919177018, 50.025127092458192],
            [95.887500313675304, 50.021100090885469],
            [95.91151815437749, 50.009991219299806],
            [95.924845212825602, 49.979573124953177],
            [95.935118241024128, 49.96180013741035],
            [95.956518245719252, 49.944991068735348],
            [95.986591340930346, 49.951800191617949],
            [96.015827251649995, 49.976936010568735],
            [96.034709164985856, 49.99138222073195],
            [96.068109205214711, 50.005764225516728],
            [96.106645172809607, 50.001936042687689],
            [96.211654158999039, 49.971927152855059],
            [96.274700320289838, 49.953609173966314],
            [96.296791329084698, 49.944709101511634],
            [96.309982265424907, 49.929164191477057],
            [96.334427247914135, 49.910827101848994],
            [96.358600321463399, 49.899991145031478],
            [96.383463393284131, 49.893191074604388],
            [96.406791235714962, 49.89513601142265],
            [96.445263333207407, 49.908600197807132],
            [96.487482309067929, 49.926245109868987],
            [96.518309272652544, 49.930309159454126],
            [96.543727226465052, 49.926653979107087],
            [96.568036254484383, 49.912082208034022],
            [96.576936159300857, 49.889718116833066],
            [96.590263385387146, 49.866800149468574],
            [96.614009317149538, 49.863609158920767],
            [96.654163327337898, 49.882209105033141],
            [96.697345216237721, 49.913182081371772],
            [96.717900325090312, 49.920545080417796],
            [96.741363283800723, 49.920827047641311],
            [96.801654145872163, 49.914991063720137],
            [97.007482340118116, 49.8892271049436],
            [97.148600230012249, 49.796936148115648],
            [97.169027263384038, 49.761991155489412],
            [97.172618238352442, 49.760418039896948],
            [97.204709192939646, 49.751664147834262],
            [97.277482217616722, 49.733609193067807],
            [97.307900311963436, 49.730964199694327],
            [97.348454138934216, 49.736518048753723],
            [97.484145249421289, 49.787500136770561],
            [97.56540930967688, 49.83027315643254],
            [97.596645142499625, 49.855200098355553],
            [97.606509301460136, 49.876518128037347],
            [97.719145149008483, 49.951664237147938],
            [97.7402633541179, 49.958600094407061],
            [97.769572355033489, 49.967209147182515],
            [97.789845161386296, 49.968318072975833],
            [97.812191315314493, 49.95943610543209],
            [97.89082714600292, 49.955554110784576],
            [98.076382366258002, 50.031664138762991],
            [98.101791267615084, 50.0456910861284],
            [98.119427294859662, 50.060818074469452],
            [98.134718233227289, 50.077773155898072],
            [98.199009274781332, 50.161800059086048],
            [98.293245168427489, 50.302836141604971],
            [98.328354111080301, 50.471173256308973],
            [98.325963424649984, 50.507500089496645],
            [98.318318290742383, 50.527073174570532],
            [98.304700212614961, 50.542218100184485],
            [98.282900223499297, 50.555827125856197],
            [98.251100288591232, 50.564991228261576],
            [98.223309250345068, 50.566382121278238],
            [98.192900208453779, 50.565964199584911],
            [98.166027156246031, 50.569718118751538],
            [98.065536183518731, 50.628600150533217],
            [98.051372275855357, 50.643327154453672],
            [97.967072290261029, 50.773044985445026],
            [97.968118184142185, 50.799436078218818],
            [98.009436273046816, 50.853291147376027],
            [97.948309232312312, 50.905682233320519],
            [97.898600187753431, 50.919991147909386],
            [97.868800342586752, 50.937764135452127],
            [97.830554221204494, 50.999300213062796],
            [97.850682355908219, 51.029300050439915],
            [97.87893624284888, 51.062218131234701],
            [97.90887237776181, 51.119436019955273],
            [97.956518300667284, 51.218318170182627],
            [97.954709318318834, 51.252627143994104],
            [97.997754246920607, 51.373600135378169],
            [98.058082324642072, 51.46274502865937],
            [98.085936227162307, 51.474209125302579],
            [98.110354219923096, 51.479518055150649],
            [98.137927161047941, 51.479518055150649],
            [98.172763356570869, 51.467491197526712],
            [98.203318243577854, 51.460964209505718],
            [98.235609190375243, 51.465500160240921],
            [98.25762727661234, 51.507773115557868],
            [98.259845128198634, 51.528327050943986],
            [98.248600302143274, 51.55582706951121],
            [98.247200189032839, 51.578473127935709],
            [98.312482306821011, 51.699436061035883],
            [98.338318182327015, 51.71957324819509],
            [98.369427280773522, 51.730818074250621],
            [98.393600186684665, 51.736936025395423],
            [98.509154110012759, 51.761109098944857],
            [98.599154125058163, 51.774991207022666],
            [98.631236194827892, 51.78249116636691],
            [98.668182283024095, 51.799854111205249],
            [98.688872340518031, 51.813327014769058],
            [98.708036221077833, 51.828045133872095],
            [98.726091343482352, 51.84331813496685],
            [98.765272382347376, 51.88443606402312],
            [98.80609124509752, 51.941654120381841],
            [98.871091228023914, 52.044018113197851],
            [98.873736221397365, 52.064718061337786],
            [98.873027280024843, 52.090264258269244],
            [98.879700280799653, 52.111664262964311],
            [98.899572264541433, 52.132218030712451],
            [98.925954137221794, 52.142773193772669],
            [98.941891320325993, 52.14068224420059],
            [99.020191204334282, 52.065273110967709],
            [99.140827243209714, 52.027218096978359],
            [99.293318202278243, 51.969718073396095],
            [99.317136218408081, 51.95102709981488],
            [99.344982241939221, 51.94221805482907],
            [99.570827259214553, 51.900545076142905],
            [99.615263416109116, 51.895964198406759],
            [99.697482342777079, 51.897354085595211],
            [99.728591273585351, 51.890545130350588],
            [99.757491237624748, 51.875545044023951],
            [99.810254144795152, 51.833882123621763],
            [99.854427277567396, 51.800545115305155],
            [99.88776328005568, 51.777354065534368],
            [99.919709227717505, 51.760554049314962],
            [99.946927279060986, 51.75139112037597],
            [99.978182222623133, 51.744709067145763],
            [100.021936260235378, 51.739573139779893],
            [100.252782256235122, 51.73165408527565],
            [100.333327149196151, 51.730544991844241],
            [100.426372309673894, 51.732209134905375],
            [100.493591317653198, 51.737209107801633],
            [100.525818226710413, 51.738045118826662],
            [100.557482374786645, 51.734991088577075],
            [100.694136230675014, 51.698873132417148],
            [100.784145130538064, 51.662209179082637],
            [100.892209320443413, 51.623600121291972],
            [100.913036170597792, 51.616664264032963],
            [101.038036163554665, 51.576100211140115],
            [101.123872384367274, 51.550827096614952],
            [101.209709275731797, 51.53416420833166],
            [101.244709253643265, 51.529164235435516],
            [101.287836157258113, 51.513327132255526],
            [101.315400213565709, 51.490409164891119],
            [101.347627290260704, 51.463744989711117],
            [101.385536123858259, 51.451936061570265],
            [101.408600271614347, 51.45208224196206],
            [101.447900333504919, 51.46499121107864],
            [101.508327150046085, 51.481654099361847],
            [101.533863288693652, 51.486109080912101],
            [101.575272405067011, 51.48777322397315],
            [101.601091349128495, 51.484164144093754],
            [101.740809293550313, 51.459164111974772],
            [101.776654167304144, 51.448600064097164],
            [101.867200179523962, 51.419445122562465],
            [101.992754216282407, 51.39554513141924],
            [102.023463330308203, 51.391654084316329],
            [102.048600322725378, 51.391109092970325],
            [102.082072279683501, 51.388882188928434],
            [102.223591327464788, 51.3265910694778],
            [102.22499127293716, 51.306382133227132],
            [102.195818226491468, 51.278464192966581],
            [102.157627258032306, 51.247700093655894],
            [102.168254170183985, 51.120427096189488],
            [102.186654124086175, 51.076945135155071],
            [102.21526340372256, 51.030964193501049],
            [102.22804513554857, 51.015545012014414],
            [102.254854150015859, 50.987000105394472],
            [102.262082368058401, 50.888600082239336],
            [102.232200212602351, 50.851100117879881],
            [102.235536210075367, 50.82054506323486],
            [102.276518184661626, 50.781109046874477],
            [102.339709185239968, 50.750691120165854],
            [102.355191231000362, 50.720273193457203],
            [102.342627261019544, 50.694854066178252],
            [102.30776340521615, 50.663609180900082],
            [102.294845216005996, 50.645409051570041],
            [102.296500306611648, 50.624154053800382],
            [102.312191229399929, 50.593609057439167],
            [102.332209225895696, 50.565827071648513],
            [102.353591293317749, 50.552773095606526],
            [102.373872313935891, 50.545545045202104],
            [102.381163396252447, 50.543664146124271],
            [102.453045256981312, 50.536664251124918],
            [102.486782249718686, 50.531664110590597],
            [102.540954154816887, 50.509027104621495],
            [102.595254135395834, 50.452773134768776],
            [102.606027227939222, 50.424153964486536],
            [102.626791213819587, 50.409154045798047],
            [102.672209226854847, 50.404991089755228],
            [102.748872292806453, 50.394436094333102],
            [102.779709314675102, 50.387218102212401],
            [102.818309320010457, 50.371382172498798],
            [102.849354213078271, 50.351109198507785],
            [102.891236236430103, 50.324300184040467],
            [102.91859124807192, 50.315273209571487],
            [102.941936189585277, 50.309991101813537],
            [102.966927169248748, 50.306382189572318],
            [103.002782268924392, 50.302773109692922],
            [103.043591241028849, 50.30360006826244],
            [103.091663299892986, 50.306654098511942],
            [103.157491247217393, 50.312764170667904],
            [103.198318156594638, 50.312491088261609],
            [103.223600323575198, 50.30860020879679],
            [103.254227294949629, 50.293045072840229],
            [103.26540925673109, 50.25804509492896],
            [103.280191245936493, 50.222000061326625],
            [103.316654201232183, 50.199436145553463],
            [103.337482224853062, 50.19638211530399],
            [103.531372409649038, 50.175554091683168],
            [103.607345142053219, 50.147500196952691],
            [103.648882333907551, 50.137354070768495],
            [103.706218239825063, 50.137527073250524],
            [103.734845121458164, 50.146764265851843],
            [103.753454120026134, 50.166153954883711],
            [103.771518294886079, 50.184853980920352],
            [103.803318229794144, 50.198464180058536],
            [103.825682320995298, 50.201245127901743],
            [103.856091362886417, 50.197764123503234],
            [103.910545235208104, 50.180036062961719],
            [103.935809297277984, 50.171636054851817],
            [104.030536202813465, 50.149244973922535],
            [104.063309276682929, 50.147091160076499],
            [104.093036199291845, 50.148664275668963],
            [104.117754096549135, 50.153500130900412],
            [104.254627223025324, 50.18871803829505],
            [104.271518266713684, 50.208373098382182],
            [104.293382293569692, 50.238209153370676],
            [104.392209290874035, 50.298618032639112],
            [104.418172403670525, 50.309173195699415],
            [104.450254138164127, 50.316036130400576],
            [104.496600195521609, 50.320436126665683],
            [104.564700309165374, 50.317664231277988],
            [104.620800219637175, 50.317982240685225],
            [104.651791300886515, 50.328573110653181],
            [104.676372405483647, 50.346609122318412],
            [104.709427278938705, 50.359118107014311],
            [104.966663361884031, 50.404836024546015],
            [104.998754148833228, 50.407845127794459],
            [105.024145112917353, 50.39666417184138],
            [105.05929127122053, 50.392082120638733],
            [105.099718196177065, 50.392218075108545],
            [105.132200250367504, 50.398327141436013],
            [105.154427213632033, 50.404164131185709],
            [105.180545223999701, 50.414709068324029],
            [105.209154168360072, 50.429991121874181],
            [105.248182321310736, 50.451936117915167],
            [105.283045171285806, 50.473045102931295],
            [105.312491300137509, 50.48151803359896],
            [105.335536169516303, 50.483609150809016],
            [105.361363327843009, 50.483045048723611],
            [105.522218252837405, 50.454436104363324],
            [105.728318188385259, 50.430827132899211],
            [105.807472355967633, 50.435964233731752],
            [105.989154131116521, 50.410545106452801],
            [106.025954206558794, 50.400544993022308],
            [106.051927210001026, 50.386936134988773],
            [106.069427282775933, 50.375264167146312],
            [106.074291301202067, 50.345818038294524],
            [106.244991274417856, 50.300000208476533],
            [106.274563299455764, 50.298809140032049],
            [106.298600250897124, 50.306382189572318],
            [106.335536280809521, 50.316100168141006],
            [106.435527189191845, 50.331664188914786],
            [106.486927198902123, 50.338327131405705],
            [106.546945146205275, 50.346100173156131],
            [106.599427259618466, 50.345545123526406],
            [106.627472269531523, 50.343044969440157],
            [106.663036181890078, 50.338600213811887],
            [106.737763363478876, 50.325554116759022],
            [106.760818291141533, 50.319991215244031],
            [106.788182355238888, 50.31054497797696],
            [106.825818274068041, 50.294153998633547],
            [107.007482279582149, 50.199154010691686],
            [107.051936206111606, 50.131382132377396],
            [107.063600294964971, 50.108609171938397],
            [107.08082728533347, 50.083191218125748],
            [107.176091303225888, 50.026936074806642],
            [107.254854203566964, 50.003191148872716],
            [107.462772341556587, 49.982345020341072],
            [107.551372411129961, 49.982764115500984],
            [107.737763307133861, 49.980273181508309],
            [107.753591357858539, 49.979545129396556],
            [107.81650927603053, 49.948882115838259],
            [107.90581828697637, 49.944709101511634],
            [107.984427295574534, 49.928936036072102],
            [107.975809190343483, 49.788891197425485],
            [107.948582254182583, 49.682209183357443],
            [108.041927151518706, 49.610827051697285],
            [108.071927156533917, 49.589991149087538],
            [108.113309283178893, 49.569445092690231],
            [108.137500294001114, 49.558045033787593],
            [108.16943618337919, 49.551382091296787],
            [108.298600305845213, 49.493327018084713],
            [108.363727190786079, 49.441800106360049],
            [108.527763383320661, 49.345754056899082],
            [108.563463417787233, 49.334854062341194],
            [108.601091289989512, 49.331664245259887],
            [108.650795221587515, 49.331700119805518],
            [108.711927207644891, 49.338045052889001],
            [108.959300305095979, 49.35651809698696],
            [108.98415415682328, 49.351936045784313],
            [109.026791222015135, 49.338327020112601],
            [109.075000241177719, 49.334436140647583],
            [109.153454184566698, 49.349018137642716],
            [109.197627149700935, 49.35985409446026],
            [109.313309316147723, 49.346382196724832],
            [109.344845221105032, 49.339153978682234],
            [109.496445183863472, 49.260409183252122],
            [109.517072377083622, 49.242218106377592],
            [109.553454195556441, 49.231518104030144],
            [109.581382361738889, 49.229991088905408],
            [109.611100231892323, 49.22971817413729],
            [109.801936218800705, 49.219153958621504],
            [110.08831819082522, 49.188327162674838],
            [110.170254144441316, 49.172491065323172],
            [110.208036243662349, 49.169154062021661],
            [110.245263293253771, 49.170554007493919],
            [110.268336158189328, 49.17500010422664],
            [110.294145211604899, 49.184991165201623],
            [110.324582249052895, 49.198318056011644],
            [110.35220028481703, 49.21360916201742],
            [110.481936220719405, 49.200000136345707],
            [110.630254165461196, 49.152491173738426],
            [110.66249130044028, 49.145827057781148],
            [110.700272393832819, 49.142218145539843],
            [110.737491229159076, 49.14360903855659],
            [110.788591334372967, 49.149436137660544],
            [110.928863322596072, 49.182209043891859],
            [110.955554152228416, 49.191100063891028],
            [110.98109129670442, 49.200827094915226],
            [111.00874520701413, 49.214300166117127],
            [111.033545246922841, 49.232136017934025],
            [111.067754140810365, 49.253327145601403],
            [111.128454207395777, 49.287500164943154],
            [111.173027156950496, 49.304709050400774],
            [111.326927281584943, 49.342491149621807],
            [111.452209241765303, 49.354436032232499],
            [111.477482356290437, 49.350000161421562],
            [111.506372262046028, 49.350127063435863],
            [111.5441451411738, 49.358191124865513],
            [111.570545118764898, 49.367491181740874],
            [111.593318246842188, 49.379154097127739],
            [111.645263415536448, 49.398327030143022],
            [111.691509225331657, 49.406936082918591],
            [111.742200293669185, 49.40526422850651],
            [111.799427234845353, 49.399991173204072],
            [111.840818246307691, 49.398045062919422],
            [111.868591347281011, 49.398882079772747],
            [111.921918188898672, 49.402218077245934],
            [111.963882354901642, 49.406936082918591],
            [112.131372394468514, 49.436936087933688],
            [112.170536166612948, 49.447491083355928],
            [112.416091229260388, 49.518327050203666],
            [112.491700354703795, 49.541382145504471],
            [112.53665417793988, 49.536382172608214],
            [112.590818204048929, 49.528882213263884],
            [112.654709261182319, 49.5180539677974],
            [112.687482335051726, 49.504853979001879],
            [112.710536256886115, 49.498882208248759],
            [112.735809203773186, 49.49790906928736],
            [112.774300244366799, 49.502353992553779],
            [112.803036258379763, 49.509718165066104],
            [112.831382346255594, 49.518327050203666],
            [113.09616331554642, 49.603673097317099],
            [113.098109258193091, 49.670200095368344],
            [113.186772359678315, 49.79430020719785],
            [113.211518252492255, 49.81568210698201],
            [113.239154225529234, 49.831382082225687],
            [113.35748222353979, 49.888600138584323],
            [113.460254247403526, 49.935891171708406],
            [113.484218276287237, 49.963600067303204],
            [113.556927263223798, 50.001936042687689],
            [113.586109194486852, 50.016391137668322],
            [113.709718294426892, 50.061664143778287],
            [113.785054169825997, 50.086800130367166],
            [113.829572301733862, 50.086245080737328],
            [113.87206335417207, 50.087073212773433],
            [113.896382272837087, 50.099718151939229],
            [113.932482291724369, 50.123882173032982],
            [113.965254192127276, 50.147354016560811],
            [113.989436150494072, 50.161936013555945],
            [114.011936196164953, 50.174164204494716],
            [114.176372373120302, 50.248327116360073],
            [114.195254118818269, 50.255827075704488],
            [114.237200347548566, 50.268054093176673],
            [114.32248235692154, 50.284300065594763],
            [114.349427158220664, 50.281245029516967],
            [114.377472335771785, 50.27138221166085],
            [114.396100277441093, 50.262773158885395],
            [114.421654185723384, 50.251936028601492],
            [114.443863379353246, 50.245273253748749],
            [114.499445120569447, 50.241382206645753],
            [114.555254179000229, 50.246100212318296],
            [114.579163390236971, 50.250000144238626],
            [114.622754148375094, 50.26082704860066],
            [114.665954142185711, 50.264436128479858],
            [114.748600210640717, 50.252773213092993],
            [114.854154188176125, 50.228327057137264],
            [114.880809310900901, 50.217764182726214],
            [115.010827214026762, 50.157491090289611],
            [115.034145165811907, 50.14513599733634],
            [115.052063327918034, 50.129854111424166],
            [115.094991245151022, 50.081109153371287],
            [115.213309184877716, 49.993327157550297],
            [115.260536180261482, 49.96610005375139],
            [115.38999132240653, 49.903882192134503],
            [115.414700334846515, 49.898327001970429],
            [115.522000262818295, 49.895273139358935],
            [115.555400303047151, 49.904300113828],
            [115.579436248660187, 49.907209137152179],
            [115.609418316402554, 49.904436068297926],
            [115.634718253018065, 49.89916418646196],
            [115.673863417337287, 49.888882105807838],
            [115.729982270910369, 49.880818044378387],
            [115.761791258273917, 49.886382119359766],
            [115.788036170656, 49.895545048298757],
            [115.818600277756417, 49.908882165030647],
            [115.887209340562407, 49.936936059761209],
            [116.049427163016986, 49.996100058766402],
            [116.069718241918991, 50.00277322717919],
            [116.097763419470112, 50.010409140993332],
            [116.150545269741627, 50.021100090885469],
            [116.216663398554857, 50.028327135461566],
            [116.246091254857646, 50.027491124436537],
            [116.290818263793227, 50.013882098764739],
            [116.352763378279946, 49.991100085870343],
            [116.424991243972869, 49.967491114406229],
            [116.452063282562705, 49.959854027125502],
            [116.499418185789153, 49.950827052656436],
            [116.616854181661353, 49.929018178723354],
            [116.70082727303074, 49.844300103797693],
            [116.711382268452809, 49.830464263825391],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MNP++", Country: "Northern Mariana Islands-Guam" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [149.205416639000191, 19.045161370000244],
            [149.207616639000094, 19.01605037000013],
            [149.204894639000145, 19.023622370000112],
            [149.212405639000053, 18.995325370000046],
            [149.219652639000174, 18.966964370000028],
            [149.226638639000157, 18.938544370000102],
            [149.233363639000231, 18.910069370000031],
            [149.239822639000181, 18.881539370000041],
            [149.246019639000224, 18.852952369000036],
            [149.251952639000137, 18.824319369000079],
            [149.25761963900004, 18.795636369000022],
            [149.263024639000037, 18.766905369000142],
            [149.268163639000136, 18.738133369000025],
            [149.273038639000106, 18.709316369000078],
            [149.277647639000065, 18.680464369000106],
            [149.281988639000019, 18.651569369000129],
            [149.286069639000175, 18.62264436900017],
            [149.289880639000216, 18.593683369000189],
            [149.293427639000015, 18.564691369000172],
            [149.296705639000038, 18.535672369000054],
            [149.299719639000045, 18.506627369000114],
            [149.300706639000168, 18.496195369000077],
            [149.302074639000011, 18.483005369000125],
            [149.304824639000117, 18.453936369000132],
            [149.307305639000106, 18.424844369000027],
            [149.309522639000193, 18.395733369000141],
            [149.311469639000165, 18.366602369000134],
            [149.313152639, 18.33745836900016],
            [149.314569639000183, 18.308302369000103],
            [149.315716639000016, 18.279136369000071],
            [149.31659963900006, 18.249958369000126],
            [149.317219639000086, 18.22077536900008],
            [149.317569639000226, 18.191589369000042],
            [149.317652639000016, 18.162400369000068],
            [149.317472639000016, 18.133211369000094],
            [149.317024639000124, 18.104025369000169],
            [149.316313639000214, 18.074844369000118],
            [149.315873639000074, 18.061737369000127],
            [149.319258639000168, 18.036072369000067],
            [149.322810639000068, 18.00708036900005],
            [149.326099639000063, 17.978061369000102],
            [149.329122639000047, 17.949016369000162],
            [149.331880639000019, 17.91994736900017],
            [149.334372639000208, 17.890855369000178],
            [149.336599639000042, 17.861741369000242],
            [149.338560639000093, 17.832614369000055],
            [149.34025563900002, 17.80346936900014],
            [149.341685639000048, 17.774311368000156],
            [149.342849639000065, 17.745141368000191],
            [149.343749639000066, 17.71596436800013],
            [149.344383639000171, 17.686780368000029],
            [149.344749639000042, 17.657591368000055],
            [149.344852639000123, 17.628402368000081],
            [149.344691639000075, 17.599211368000113],
            [149.344263639000019, 17.570025368000245],
            [149.343572639000058, 17.540841368000144],
            [149.342613639000092, 17.511666368000078],
            [149.34130427434161, 17.482493857839643],
            [149.342066639000109, 17.461341368000092],
            [149.34297463900009, 17.432164368000088],
            [149.343616639000174, 17.402980368000158],
            [149.34399463900013, 17.373791368000184],
            [149.344105639000077, 17.344600368000044],
            [149.343952639000236, 17.315408368000192],
            [149.343535639000152, 17.286219368000047],
            [149.342852639000171, 17.257036368000172],
            [149.341908639000053, 17.227858368000057],
            [149.340697639000041, 17.198689368000146],
            [149.344330639000077, 17.172511368000073],
            [149.354374639000071, 17.144964368000046],
            [149.364166639000132, 17.117330368000125],
            [149.373702639000044, 17.089616368000179],
            [149.382985639000026, 17.061825368000086],
            [149.392013639000083, 17.033955368000136],
            [149.400788639000098, 17.006008368000096],
            [149.409305639000081, 16.977991368000119],
            [149.417566639000142, 16.949902368000039],
            [149.425569639000059, 16.921744368000077],
            [149.433316639000168, 16.893522368000163],
            [149.44080263900014, 16.865233368000077],
            [149.448033639000187, 16.836883368000031],
            [149.455005639000206, 16.808472368000139],
            [149.461716639000201, 16.780005368000047],
            [149.468169639000166, 16.751480368000102],
            [149.47436063900011, 16.72290536800017],
            [149.480294639000135, 16.694277367000154],
            [149.485963639000033, 16.665600367000081],
            [149.491374639000128, 16.636877367000125],
            [149.496524639000199, 16.608108367000057],
            [149.501410639000028, 16.579297367000095],
            [149.506035639000061, 16.550447367000118],
            [149.510399639000184, 16.521558367000125],
            [149.514499639000178, 16.49263336700011],
            [149.518338639000149, 16.463677367000059],
            [149.521913639000218, 16.434686367000154],
            [149.52522763900015, 16.405669367000201],
            [149.528277639000066, 16.376625367000145],
            [149.531063639000195, 16.347555367000041],
            [149.533588639000186, 16.318461367000111],
            [149.535849639000048, 16.289350367000225],
            [149.53784763900012, 16.260219367000047],
            [149.539580639000064, 16.231072367000024],
            [149.541049639000107, 16.201914367000086],
            [149.542258639000124, 16.172741367000185],
            [149.543202639000015, 16.143561367000075],
            [149.543883639000114, 16.114375367000036],
            [149.544299639000201, 16.085180367000078],
            [149.544455639000148, 16.05598636700006],
            [149.54434763900008, 16.026791367000101],
            [149.543974639000112, 15.997600367000132],
            [149.543341639000118, 15.968411367000158],
            [149.542444639000223, 15.939227367000058],
            [149.541283639000085, 15.910055367000098],
            [149.539863639000032, 15.880891367000061],
            [149.538180639000188, 15.851741367000102],
            [149.536233639000216, 15.822608367000157],
            [149.534027639000101, 15.79348936700012],
            [149.531558639000082, 15.76439136700003],
            [149.528830639000148, 15.735316367000053],
            [149.525841639000078, 15.706264367000074],
            [149.522591639000211, 15.677239367000141],
            [149.519080639000094, 15.648241367000196],
            [149.515313639000169, 15.619275366000068],
            [149.511283639000112, 15.590341366000075],
            [149.506997639000019, 15.561441366000054],
            [149.502452639000126, 15.532580366000218],
            [149.497649639000201, 15.503758366000056],
            [149.492588639000019, 15.474975366000081],
            [149.487272639000139, 15.4462393660001],
            [149.481697639000231, 15.417547366000065],
            [149.475866639000174, 15.388902366000138],
            [149.46978363900007, 15.360311366000076],
            [149.463441639000052, 15.331769366000174],
            [149.456847639000216, 15.303280366000024],
            [149.449999639000112, 15.274852366000118],
            [149.442899639000075, 15.246480366000071],
            [149.43554763900022, 15.218169366000097],
            [149.42794163900021, 15.189922366000076],
            [149.420085639000149, 15.161739366000063],
            [149.411980639000149, 15.133622366000111],
            [149.4036246390001, 15.105577366000148],
            [149.395019639000111, 15.077602366000065],
            [149.38616663900018, 15.049700366000138],
            [149.377066639000077, 15.021875366000074],
            [149.367719639000143, 14.994127366000157],
            [149.358124639000039, 14.966458366000154],
            [149.348288639000202, 14.938872366000112],
            [149.33820563900008, 14.911369366000031],
            [149.327880639000227, 14.88395236600013],
            [149.317310639000084, 14.856622366000124],
            [149.306502639000087, 14.829383366000229],
            [149.295449639000026, 14.802239366000151],
            [149.284160639000106, 14.775186366000185],
            [149.272630639000226, 14.748227366000151],
            [149.260863639000036, 14.72136936600009],
            [149.248858639000105, 14.694611366000174],
            [149.236619639000196, 14.667955366000172],
            [149.224141639000095, 14.641402366000023],
            [149.21143363900012, 14.614958366000167],
            [149.198895639000057, 14.589447366000172],
            [149.194877639000197, 14.571216366000044],
            [149.188330639000128, 14.542722365000145],
            [149.181530639000016, 14.514286365000089],
            [149.174477639000202, 14.485905365000178],
            [149.167172639000228, 14.457586365000111],
            [149.159616639000205, 14.429330365000055],
            [149.151810639000018, 14.401141365000228],
            [149.143758639000112, 14.373016365000183],
            [149.135452639000164, 14.344964365000124],
            [149.126899639000158, 14.316980365000177],
            [149.118102639000199, 14.289069365000103],
            [149.117100639000114, 14.285985365000158],
            [149.109769639000092, 14.260366365000152],
            [149.101472639000235, 14.232308365000165],
            [149.092927639000209, 14.204325365000159],
            [149.08413563900001, 14.176414365000028],
            [149.075097639000205, 14.148577365000051],
            [149.065810639000233, 14.120819365000159],
            [149.056283639000185, 14.093141365000122],
            [149.046508639000081, 14.065544365000108],
            [149.036491639000019, 14.038030365000168],
            [149.026230639000119, 14.010602365000238],
            [149.015727639000033, 13.983261365000033],
            [149.004983639000102, 13.956011365000052],
            [148.993999639000094, 13.928852365000182],
            [148.982777639000119, 13.90178936500007],
            [148.971316639000065, 13.874822365000114],
            [148.959619639000152, 13.847950365000031],
            [148.947685639000042, 13.821180365000146],
            [148.935513639000078, 13.794511365000176],
            [148.923110639000129, 13.767947365000111],
            [148.910472639000204, 13.741489365000177],
            [148.89760263900007, 13.715139365000084],
            [148.884499639000182, 13.688897365000059],
            [148.87116663900008, 13.662766365000152],
            [148.857605639000212, 13.63675236500012],
            [148.843816639000124, 13.610852365000142],
            [148.829799639000043, 13.585072365000144],
            [148.815558639000074, 13.559408364999967],
            [148.801088639000227, 13.533869365000044],
            [148.786397639000029, 13.508450365000044],
            [148.771483639000166, 13.483158364000019],
            [148.756347639000069, 13.457994364],
            [148.740991639000185, 13.432958364000115],
            [148.725416639000059, 13.408052364000127],
            [148.709624638000037, 13.383280364000086],
            [148.693616638000094, 13.358641364000164],
            [148.677391638000017, 13.334141364000175],
            [148.660952638000026, 13.309777364000126],
            [148.644302638000113, 13.285555364000061],
            [148.627438638000172, 13.261475364000034],
            [148.618157638000184, 13.248460364000152],
            [148.607377638000116, 13.230864364000183],
            [148.591819638000175, 13.205955364000147],
            [148.576044638000212, 13.18118036400017],
            [148.560055638000108, 13.156539364000082],
            [148.543847638000216, 13.132036364000044],
            [148.529927638000117, 13.104161364000049],
            [148.520224638000172, 13.076552364000065],
            [148.510274638000055, 13.049025364000158],
            [148.500085638000087, 13.021586364000029],
            [148.489655638000045, 12.994233364000081],
            [148.478983638000045, 12.966969364000022],
            [148.468072638000081, 12.939800364],
            [148.456922638000151, 12.912722364000103],
            [148.445535638000138, 12.885741364000012],
            [148.433910638000043, 12.858858364000071],
            [148.422052638000082, 12.832072364000169],
            [148.409958638000148, 12.805391364000059],
            [148.39763063800001, 12.778814364000027],
            [148.385069638000118, 12.752339364000079],
            [148.372274638000135, 12.725975364000078],
            [148.359252638000044, 12.699719364000089],
            [148.34599963800008, 12.673577363999982],
            [148.332516638000016, 12.647547364000104],
            [148.318808638000178, 12.621633364000047],
            [148.304869638000127, 12.595836364000036],
            [148.290708638000069, 12.570158364000122],
            [148.276322638000124, 12.544602364000184],
            [148.261713638000174, 12.519169364000163],
            [148.246883638000099, 12.49386436399999],
            [148.231830638000133, 12.468683364000185],
            [148.216558638000038, 12.443630364000114],
            [148.201066638000043, 12.41871136400016],
            [148.185358638000139, 12.393922363000172],
            [148.169435638000209, 12.369269363000171],
            [148.153297638000026, 12.34475036300006],
            [148.136944638000045, 12.320372363000047],
            [148.120380638000142, 12.296133363000024],
            [148.103602638000211, 12.272036363000154],
            [148.08661663800001, 12.248080363000099],
            [148.073991638000052, 12.230596363000132],
            [148.062977638000234, 12.205619363000153],
            [148.050930638000182, 12.178930363000063],
            [148.038647638000157, 12.152341363000119],
            [148.026133638000147, 12.125861363000126],
            [148.013385638000045, 12.099486363000096],
            [148.000410638000062, 12.073222363000184],
            [147.987205638000091, 12.047072363000098],
            [147.973769638000022, 12.021033363000186],
            [147.960108638000065, 11.995108363000099],
            [147.946222638000108, 11.969302363000054],
            [147.932108638000045, 11.943616363000046],
            [147.925369242050124, 11.93135534173507],
            [147.917860706725264, 11.918000688422978],
            [147.910938518180387, 11.905846155674396],
            [147.903366892544568, 11.892518953611727],
            [147.896577638000053, 11.880291363000026],
            [147.881797638000194, 11.854972363000172],
            [147.866794638000101, 11.829783363000047],
            [147.851574638000102, 11.804722363000053],
            [147.836135638000087, 11.779791363000129],
            [147.820480638000049, 11.754991363000045],
            [147.804608638000104, 11.730327363000185],
            [147.788522638000131, 11.705800363000094],
            [147.77222263800013, 11.681411363000109],
            [147.755708638000101, 11.657161363000057],
            [147.744999638000195, 11.640277363000166],
            [147.517499637000014, 11.614722363000112],
            [146.921944637000138, 11.530000363000056],
            [146.426110636000203, 11.454166363000127],
            [145.876666636000209, 11.370277363000241],
            [145.377222635000066, 11.291944362000095],
            [144.957222635000335, 11.22555536200008],
            [144.941388635000067, 11.223055362000139],
            [143.448055634000184, 10.950833362000083],
            [143.052499633000025, 10.958333362000133],
            [142.257777632000057, 11.87583336300014],
            [141.363333632000149, 12.900000364000164],
            [141.35916663200021, 12.904722364000065],
            [141.321388632000094, 12.959444364000205],
            [141.214722632000189, 13.108889364000106],
            [141.212505632000102, 13.131652364000047],
            [141.209633632000077, 13.160719364000045],
            [141.207019632000197, 13.1898083640001],
            [141.204660632000014, 13.218919364000044],
            [141.202563632000079, 13.248050364000164],
            [141.200722632000179, 13.277197364000074],
            [141.199141632000078, 13.306358364000175],
            [141.197819632000119, 13.335530364000022],
            [141.196755631000087, 13.364714364000122],
            [141.195952631000068, 13.393905364000034],
            [141.195410631000072, 13.423102364000158],
            [141.195124631000226, 13.452302364000161],
            [141.195102631000168, 13.481502364000164],
            [141.195338631000141, 13.510702365000043],
            [141.195833631000028, 13.539900365000051],
            [141.196588631000168, 13.569094365000126],
            [141.197605632000204, 13.598277365000115],
            [141.198883632000047, 13.627452365000181],
            [141.200419632000148, 13.656614365000053],
            [141.202213632000053, 13.685764365000182],
            [141.204272632000084, 13.714894365000077],
            [141.206588632000035, 13.744008365000013],
            [141.209163632000127, 13.773100365000175],
            [141.212002632, 13.802169365000168],
            [141.215097632000152, 13.831211365000058],
            [141.218452632000094, 13.860227365000071],
            [141.222069632000057, 13.889214365000043],
            [141.225944632000051, 13.918166365000104],
            [141.230077632000189, 13.947089365000124],
            [141.234469632000014, 13.975972365000189],
            [141.239119632000097, 14.004816365000181],
            [141.24403063200009, 14.03361936500005],
            [141.249197632000232, 14.062380365000138],
            [141.254622632000178, 14.09109736500011],
            [141.260305632000154, 14.119766365000089],
            [141.266244632000053, 14.148383365000143],
            [141.272441632000096, 14.176950365000152],
            [141.27889463200006, 14.205464365000068],
            [141.285602632000177, 14.233922365000126],
            [141.292566632000103, 14.26231936500011],
            [141.299788632000059, 14.290658365000127],
            [141.30726063200018, 14.318933365000134],
            [141.314991632000215, 14.347141365000141],
            [141.322972632000074, 14.375286365000022],
            [141.331208632000084, 14.403358365000145],
            [141.339697632000139, 14.431361365000043],
            [141.348435632000133, 14.459291365000126],
            [141.357427632000054, 14.487144365000063],
            [141.366672632000018, 14.514919365000083],
            [141.376163632000043, 14.542616365000072],
            [141.385908632000223, 14.570230366000033],
            [141.39589963200001, 14.597758366000051],
            [141.406141632000072, 14.625202366000053],
            [141.416627632000086, 14.652555366000172],
            [141.427363632000038, 14.679819366000061],
            [141.438344632000053, 14.706991366000125],
            [141.449572632000013, 14.734069366000028],
            [141.461044632000153, 14.761047366000184],
            [141.472758632000136, 14.787927366000076],
            [141.484716632000072, 14.814708366000104],
            [141.496919632000072, 14.841386366000165],
            [141.509360632000039, 14.867955366000103],
            [141.522044632000075, 14.894422366000072],
            [141.534966632000078, 14.920775366000044],
            [141.54812763200016, 14.947019366000063],
            [141.561527632000207, 14.973150366000141],
            [141.575163632000113, 14.999166366000168],
            [141.589035632000218, 15.025064366000151],
            [141.603141632000074, 15.050841366000043],
            [141.61748363200013, 15.076497366000126],
            [141.632058632000167, 15.102033366000171],
            [141.646863632000077, 15.127441366000141],
            [141.6618996320002, 15.152722366000035],
            [141.67716663200008, 15.177872366000145],
            [141.69266063200007, 15.202894366000123],
            [141.708383632000164, 15.227780366000047],
            [141.724333632000025, 15.252533366000137],
            [141.740508632000228, 15.277147366000122],
            [141.756905632000098, 15.301622366000174],
            [141.773527632000196, 15.325958366000066],
            [141.790369632000193, 15.350150366000094],
            [141.807433632000198, 15.374200366000139],
            [141.824716632000218, 15.398100366000165],
            [141.842216632000031, 15.421852366000053],
            [141.859933632000093, 15.44545536600009],
            [141.877863632000071, 15.468905366000172],
            [141.896010632000184, 15.492202366000072],
            [141.914369632000103, 15.515341366000087],
            [141.932941632000166, 15.538325366000208],
            [141.951719632000049, 15.561150366000106],
            [141.970708632000083, 15.583811366000134],
            [141.989905632000045, 15.60631136600017],
            [142.009305632000064, 15.628647366000166],
            [142.02891063200002, 15.650814367000066],
            [142.048722632000022, 15.672814367000115],
            [142.068730632000097, 15.694644367000137],
            [142.088941632000115, 15.716305367000075],
            [142.112888632000022, 15.755464367000172],
            [142.127494632000207, 15.781005367000148],
            [142.142330632000153, 15.806419367000046],
            [142.157399632000079, 15.83170836700009],
            [142.172699632000217, 15.856869367000229],
            [142.188230632000227, 15.881897367000136],
            [142.1952606320001, 15.904005367000082],
            [142.190955632000055, 15.932902367000054],
            [142.186908632000041, 15.961833367000168],
            [142.183122632000163, 15.990800367000077],
            [142.179594632000089, 16.019794367000088],
            [142.176330632000031, 16.048816367000086],
            [142.173324632000231, 16.077866367000127],
            [142.170577632000118, 16.106941367000047],
            [142.16809463200002, 16.136036367000088],
            [142.165874632000055, 16.165152367000076],
            [142.163913632000117, 16.194286367000075],
            [142.162216632000082, 16.223433367000155],
            [142.160780632000069, 16.252594367000142],
            [142.159608632000072, 16.281766367000102],
            [142.158697632000212, 16.310950367000032],
            [142.158052632000135, 16.340136367000127],
            [142.157669632000079, 16.369327367000039],
            [142.157547632000046, 16.398522367000169],
            [142.157691632000137, 16.427716367000187],
            [142.158097632000135, 16.456908367000096],
            [142.158769632000144, 16.486094367000135],
            [142.159702632000062, 16.515272367000136],
            [142.16089963200011, 16.544444367000096],
            [142.162360632000059, 16.573602367000149],
            [142.164088632000016, 16.602750367000112],
            [142.16607763200011, 16.631880367000178],
            [142.16833063200022, 16.660991367000122],
            [142.170847632000118, 16.690083367000057],
            [142.173627632000034, 16.719155368000031],
            [142.176672632000077, 16.748200368000141],
            [142.179980632000138, 16.77721936800009],
            [142.183549632000108, 16.806208368000057],
            [142.187385632000087, 16.835166368000102],
            [142.191480632000207, 16.864091368000061],
            [142.195841632000224, 16.89298036800011],
            [142.200463632000037, 16.921830368000087],
            [142.205349632000207, 16.950641368000049],
            [142.21049763200017, 16.979411368000171],
            [142.215905632000158, 17.008136368000123],
            [142.221574632000056, 17.03681436800008],
            [142.227505632000202, 17.065444368000158],
            [142.233699632000025, 17.094022368000026],
            [142.240152632000104, 17.122544368000092],
            [142.2468636320001, 17.151014368000062],
            [142.253838632000111, 17.17942736800012],
            [142.261069632000158, 17.207777368000166],
            [142.268560632000117, 17.236069368000187],
            [142.276310633000207, 17.264294368000151],
            [142.284319633000109, 17.292452368000056],
            [142.292583633000163, 17.320544368000071],
            [142.301105633000134, 17.348564368000098],
            [142.299602633000063, 17.352161368000168],
            [142.293133633000025, 17.380683368000064],
            [142.286919633000139, 17.409258368000167],
            [142.280963633000056, 17.437886368000136],
            [142.275266633000228, 17.466558368000108],
            [142.269827632000215, 17.495280368000181],
            [142.264647632000219, 17.524044368000148],
            [142.259727632000136, 17.552850368000065],
            [142.255069632000186, 17.581697368000164],
            [142.25066963200004, 17.610580368000058],
            [142.246533632000023, 17.639500368000142],
            [142.242655632000151, 17.668452368000032],
            [142.239044632000173, 17.697439368000175],
            [142.232602632000066, 17.755489368000156],
            [142.227216632000022, 17.813641369000152],
            [142.224916632000031, 17.842747369000165],
            [142.222883632000048, 17.871872369000187],
            [142.221113632000083, 17.901014369000166],
            [142.219608632000131, 17.930166369000119],
            [142.218369632000076, 17.959333369000035],
            [142.217394632000037, 17.988505369000052],
            [142.216685632, 18.017689369000152],
            [142.21484163200023, 18.037139369000045],
            [142.208063632000062, 18.065600369000038],
            [142.20154463200015, 18.094114369000067],
            [142.195283632000042, 18.122683369000129],
            [142.189280632000077, 18.151302369000177],
            [142.18353563200003, 18.179969369000105],
            [142.178052632000117, 18.208683369000028],
            [142.172830632000228, 18.237441369000067],
            [142.167866632000027, 18.266244369000049],
            [142.163163632000078, 18.295083369000054],
            [142.158724632000144, 18.323964369000123],
            [142.153835632000096, 18.347294369000053],
            [142.143354632000069, 18.370525369000177],
            [142.131641632000196, 18.390083369000024],
            [142.116697632000182, 18.415547369000137],
            [142.101977632000171, 18.441130369000177],
            [142.087488632000031, 18.466836369000077],
            [142.07323363200021, 18.492658369000026],
            [142.059208632000036, 18.51859736900019],
            [142.04541663200007, 18.544652369000062],
            [142.03185863200008, 18.570819369000105],
            [142.031436632000123, 18.571651369000165],
            [142.017108632000145, 18.590316369000163],
            [141.999238632000015, 18.614014369000188],
            [141.981583632000138, 18.637858369000071],
            [141.964138632000186, 18.661844369000107],
            [141.94691063200014, 18.685975369000118],
            [141.929899632000229, 18.710247369000058],
            [141.913108632000217, 18.734655369000222],
            [141.896533632000114, 18.75920236900015],
            [141.880177632000027, 18.783883369000137],
            [141.864044632000173, 18.808697369000186],
            [141.848135632000094, 18.833644369000183],
            [141.832449632000021, 18.858719370000188],
            [141.816988632000061, 18.8839253700001],
            [141.801755632000209, 18.909252370000104],
            [141.786749632000124, 18.934708370000124],
            [141.771974632000138, 18.960283370000184],
            [141.757427632000145, 18.985977370000171],
            [141.743110632000025, 19.011794370000075],
            [141.729030632000217, 19.037725370000089],
            [141.71518063200017, 19.063769370000102],
            [141.701566632000095, 19.089927370000112],
            [141.68818863200022, 19.116197370000179],
            [141.675047632000087, 19.142575370000088],
            [141.662144632000036, 19.169061370000065],
            [141.649480632000063, 19.19565037000018],
            [141.637058632000048, 19.222341370000038],
            [141.624874632000115, 19.249136370000031],
            [141.612935632000017, 19.276027370000122],
            [141.601238632000218, 19.303016370000137],
            [141.58978563200003, 19.330100370000025],
            [141.578580632000012, 19.357277370000133],
            [141.567619632000174, 19.384547370000121],
            [141.556905632000053, 19.411902370000064],
            [141.546438632000218, 19.439347370000178],
            [141.53622263200009, 19.466875370000025],
            [141.526255632000016, 19.494486370000175],
            [141.51653863200022, 19.522177370000179],
            [141.507074632000126, 19.549947370000154],
            [141.497860632000197, 19.577794370000106],
            [141.488902632000077, 19.605716370000096],
            [141.480197632000113, 19.633711370000185],
            [141.471747632000188, 19.661775370000157],
            [141.463549632000081, 19.689908370000182],
            [141.455610632000116, 19.718105370000103],
            [141.447930632000066, 19.746369370000139],
            [141.440505632000168, 19.774694370000191],
            [141.433338632000073, 19.803077370000096],
            [141.426433632000226, 19.83151937000008],
            [141.419785632000071, 19.860016370000153],
            [141.413397632000056, 19.888569370000084],
            [141.407272632000058, 19.917169370000181],
            [141.401408632000198, 19.945822371000133],
            [141.39580563200002, 19.974519371000099],
            [141.390466632000084, 20.003264371000114],
            [141.385213632000045, 20.039866371000073],
            [141.380402632000141, 20.068694371000163],
            [141.375852632000033, 20.097558371000105],
            [141.371566632000167, 20.126458371000126],
            [141.367547632000196, 20.155394371000114],
            [141.364778632000025, 20.176760371000114],
            [141.363794632000122, 20.184358371000144],
            [141.360308632000169, 20.213355371000034],
            [141.357085632000121, 20.242377371000032],
            [141.354130632000079, 20.271427371000073],
            [141.351444632000039, 20.300500371000055],
            [141.34902463200001, 20.329591371000049],
            [141.3468726320001, 20.358702371000163],
            [141.344988632000195, 20.387830371000064],
            [141.343374632000064, 20.416972371000043],
            [141.342027632000168, 20.446125371000051],
            [141.34095263200004, 20.475289371000088],
            [141.340144632000147, 20.504461371000104],
            [141.339605632000143, 20.533639371000049],
            [141.339335632000029, 20.56281637100011],
            [141.339338632000135, 20.592000371000211],
            [141.339608632000022, 20.621177371000044],
            [141.340149632000021, 20.65035537100016],
            [141.340963632000125, 20.679525371000182],
            [141.342044632000011, 20.708689371000048],
            [141.343397632000233, 20.737841371000172],
            [141.345019632000117, 20.766980371000102],
            [141.34691363200011, 20.796108371000173],
            [141.349077632000103, 20.825216371000181],
            [141.351510632000213, 20.854308371000116],
            [141.348333300000149, 20.8808333300002],
            [141.45916670000031, 20.97333333000006],
            [142.062500000000227, 21.472500000000139],
            [142.30138890000012, 21.668888890000176],
            [142.344166700000187, 21.703888890000087],
            [142.584166701000299, 21.899444439000035],
            [143.083888900000147, 22.303611110000247],
            [143.749166664500194, 22.83694441],
            [144.296388900000125, 23.269722220000062],
            [144.595777651000276, 23.503978440000139],
            [144.673055600000225, 23.564444440000159],
            [144.901388900000143, 23.742222220000087],
            [145.096111100000144, 23.893055560000107],
            [145.099722635000148, 23.890277374000107],
            [145.125566635000041, 23.882080374000111],
            [145.157213635000176, 23.880127374000153],
            [145.188841635000017, 23.877914374000056],
            [145.220447635000141, 23.875439374000052],
            [145.252024635000112, 23.872708374000069],
            [145.283574635000036, 23.869714374000125],
            [145.315091635000158, 23.866464374000145],
            [145.346577635000017, 23.862952374000031],
            [145.37802763500008, 23.85918637400016],
            [145.409435635000136, 23.855158374000155],
            [145.440805635000061, 23.850872374000062],
            [145.472130635000212, 23.846330374000161],
            [145.503408636000131, 23.841530374000172],
            [145.534635636000189, 23.836475374000088],
            [145.56581363600003, 23.831161374000033],
            [145.596935636000012, 23.825594374000048],
            [145.628002636000019, 23.819769374000202],
            [145.659010636000176, 23.813689374000205],
            [145.689958636000028, 23.80735537400011],
            [145.720838636000167, 23.800769374000026],
            [145.751652636000131, 23.793927374000191],
            [145.782399636000036, 23.786833374000139],
            [145.813072636000015, 23.779486374000101],
            [145.843672636000065, 23.771889374000182],
            [145.874194636000198, 23.764039374000106],
            [145.904638636000072, 23.75593937400015],
            [145.935002636000036, 23.747589374000086],
            [145.965280636000102, 23.738991374000136],
            [145.995469636000053, 23.730144374000133],
            [146.025572636000106, 23.721047374000079],
            [146.055583636000051, 23.711705374000189],
            [146.085499636, 23.702119374000233],
            [146.115322636000201, 23.692286374000219],
            [146.14504463600008, 23.68220837400014],
            [146.174663636000105, 23.671886374000167],
            [146.204180636000046, 23.661319374000186],
            [146.233594636000134, 23.650514374000068],
            [146.262897636000162, 23.639466374000051],
            [146.29208863600013, 23.628177374000188],
            [146.321169636000036, 23.61665037400013],
            [146.35013363600001, 23.604883374000167],
            [146.378980636000165, 23.592880374000174],
            [146.407708636000166, 23.580641374000038],
            [146.436313636000136, 23.568164374000105],
            [146.464794636000079, 23.555455374000189],
            [146.493147636000231, 23.542511374000128],
            [146.521372636000024, 23.529336374000025],
            [146.549466636000147, 23.515927374000057],
            [146.577427637000028, 23.502291374000151],
            [146.605252637000149, 23.48842537400003],
            [146.632938637000052, 23.474330374000033],
            [146.660485637000079, 23.460008374000211],
            [146.687888637000128, 23.445461374000047],
            [146.715149637000081, 23.43068937400011],
            [146.742260637000072, 23.415694374000054],
            [146.769227637000199, 23.400477374000047],
            [146.796038637000038, 23.385039374000087],
            [146.822699637000142, 23.369380374000116],
            [146.849205637000182, 23.353505374000065],
            [146.875555637000048, 23.337414374000048],
            [146.901744637000093, 23.321102374000077],
            [146.927772637000203, 23.304580374000125],
            [146.953635637000161, 23.287844374000031],
            [146.979335637000077, 23.270897374000128],
            [147.004869637000184, 23.253736374000027],
            [147.030230637000159, 23.236369374000162],
            [147.05542263700022, 23.21879437400014],
            [147.080441637000035, 23.201014374000181],
            [147.10528363700007, 23.183027374000176],
            [147.129949637000095, 23.164836374000117],
            [147.15443563700012, 23.146444373000122],
            [147.17874163700003, 23.12785237300011],
            [147.202866637000056, 23.109064373000081],
            [147.226805637000211, 23.090075373000104],
            [147.250555637000048, 23.070891373000165],
            [147.274119637000013, 23.051514373000089],
            [147.297494637000113, 23.031941373000109],
            [147.320677637000017, 23.012180373000035],
            [147.343663637000191, 22.992227373000048],
            [147.366458637000051, 22.972089373000074],
            [147.389052637000077, 22.951764373000231],
            [147.411449637000146, 22.931252373000177],
            [147.433647637000036, 22.910558373000185],
            [147.455641637000099, 22.889683373000139],
            [147.477433637000217, 22.868627373000209],
            [147.499016637000068, 22.847394373000157],
            [147.520394637000095, 22.825983373000156],
            [147.541563637000195, 22.804400373000192],
            [147.562524637000024, 22.78264137300016],
            [147.58327263700005, 22.760714373000098],
            [147.585599637000087, 22.758209373000113],
            [147.586535637000111, 22.757219373000112],
            [147.607069637000109, 22.735119373000146],
            [147.627388637000195, 22.712855373000082],
            [147.647488638000027, 22.690422373000104],
            [147.667374638000069, 22.667827373000023],
            [147.687038638000104, 22.645066373000247],
            [147.706483638, 22.622150373000068],
            [147.72570263800003, 22.599072373000126],
            [147.744702638000035, 22.575836373000129],
            [147.763474638000162, 22.55244737300012],
            [147.782022638000058, 22.528902373000108],
            [147.800341638000191, 22.505208373000244],
            [147.818430638000109, 22.48136437300019],
            [147.836288638000156, 22.457372373000169],
            [147.853916638000101, 22.433236373000113],
            [147.871310638000068, 22.40895537300014],
            [147.888472638000053, 22.384533373000068],
            [147.905397638000068, 22.359969373000126],
            [147.922085638000112, 22.335269373000131],
            [147.938535638000189, 22.310430373000031],
            [147.954749638000067, 22.285461373000146],
            [147.970719638000105, 22.260355373000152],
            [147.986452638000173, 22.235122373000138],
            [148.00193863800007, 22.209761373000163],
            [148.017183638000233, 22.184275373000162],
            [148.032185638000101, 22.158661373000029],
            [148.046938638000142, 22.132927373000086],
            [148.061447638000118, 22.107072373000108],
            [148.075708638000037, 22.081100373000027],
            [148.089722638000126, 22.055011372000138],
            [148.103483638000057, 22.028808372000128],
            [148.116997638000043, 22.002494372000228],
            [148.130258638000214, 21.976069372000154],
            [148.141272638000032, 21.964489372000173],
            [148.159077638000099, 21.940502372000026],
            [148.17665563800017, 21.916372372000126],
            [148.19399963800015, 21.892097372000137],
            [148.21111063800015, 21.867680372000109],
            [148.227988638000056, 21.843125372000031],
            [148.244630638000217, 21.81843037200008],
            [148.261033638000072, 21.793600372000185],
            [148.277199638000184, 21.768633372000124],
            [148.293127638000215, 21.743536372000165],
            [148.308816638000167, 21.718311372000073],
            [148.324263638000161, 21.692955372000142],
            [148.33946663800009, 21.667472372000077],
            [148.354427638000175, 21.641866372000038],
            [148.369144638000193, 21.616136372000142],
            [148.383616638000149, 21.590289372000086],
            [148.397838638000053, 21.564322372000049],
            [148.411816638000118, 21.538239372000248],
            [148.425547638000126, 21.512041372000112],
            [148.439024638000205, 21.485730372000035],
            [148.452255638000111, 21.459311372000116],
            [148.4652336380002, 21.432783372000245],
            [148.477960638000013, 21.406150372000184],
            [148.490433638000127, 21.379414372000099],
            [148.5026526380002, 21.352575372000047],
            [148.514619638000227, 21.325636372000076],
            [148.526327638000112, 21.298600372000124],
            [148.538944638000089, 21.270552372000054],
            [148.553391638000107, 21.244708372000048],
            [148.567591638000181, 21.218744372000117],
            [148.581547638000075, 21.192664372000195],
            [148.595252638000034, 21.166469372000108],
            [148.608710638000161, 21.140164372000186],
            [148.621919638000236, 21.11375037200014],
            [148.634877638000148, 21.087225372000148],
            [148.64758563800001, 21.060594372000082],
            [148.66003863800006, 21.033861372000104],
            [148.67224163800006, 21.007025372000157],
            [148.684191638000129, 20.980089371000133],
            [148.695883638000169, 20.953055371000119],
            [148.707322638000164, 20.925927371000171],
            [148.718505639000227, 20.898705371000062],
            [148.729430639000157, 20.871391371000129],
            [148.740097639000055, 20.843989371000191],
            [148.750508639000145, 20.816497371000139],
            [148.760658639000098, 20.788925371000175],
            [148.770549639000137, 20.761266371000147],
            [148.780183639000029, 20.733527371000093],
            [148.789552639000021, 20.705711371000177],
            [148.798663639000097, 20.677819371000055],
            [148.807510639000043, 20.649852371000122],
            [148.816097639000077, 20.621811371000092],
            [148.824419639000098, 20.59370237100012],
            [148.832477639000103, 20.56552737100003],
            [148.840272639000091, 20.537283371000115],
            [148.847805639000057, 20.508980371000064],
            [148.855072639000014, 20.480614371000172],
            [148.862072639000189, 20.452186371000096],
            [148.868808639000235, 20.423705371000096],
            [148.875277639000046, 20.395166371000073],
            [148.881480639000188, 20.366577371000176],
            [148.8874196390002, 20.337939371000118],
            [148.893088639000098, 20.309252371000127],
            [148.898491639000213, 20.280519371000025],
            [148.903627639000092, 20.251741371000151],
            [148.908494639000082, 20.222922371000038],
            [148.913094639000064, 20.194066371000133],
            [148.917427639000039, 20.16517237100004],
            [148.923916639000026, 20.13493937100003],
            [148.936074639000026, 20.108111371000177],
            [148.947980639000093, 20.081183371000236],
            [148.959633639000117, 20.054158371000028],
            [148.971033639000098, 20.027036371000065],
            [148.982177639000042, 19.99982237100005],
            [148.993066639000176, 19.972516371000097],
            [149.003702639000039, 19.945119371000089],
            [149.014080639000213, 19.917636370000139],
            [149.02419963900013, 19.890069370000049],
            [149.03406363900001, 19.862416370000176],
            [149.043669639000086, 19.834686370000099],
            [149.053013639000142, 19.806875370000114],
            [149.062099639000166, 19.778986370000041],
            [149.070927639000161, 19.751025370000093],
            [149.079494639000018, 19.722991370000102],
            [149.087797639000087, 19.69488637000012],
            [149.095841639000014, 19.666714370000079],
            [149.10362263900015, 19.638477370000032],
            [149.111138639000046, 19.610175370000036],
            [149.11839463900003, 19.581814370000188],
            [149.125385639000115, 19.553391370000043],
            [149.132113639000067, 19.524914370000147],
            [149.138577639000232, 19.496377370000118],
            [149.144774639000161, 19.467791370000157],
            [149.150708639000072, 19.439155370000094],
            [149.156377639000198, 19.410472370000036],
            [149.161780639000199, 19.381739370000162],
            [149.166919639000071, 19.352964370000166],
            [149.171791639000162, 19.324150370000098],
            [149.176394639000137, 19.295291370000086],
            [149.180733639000209, 19.266400370000099],
            [149.18480563900016, 19.237472370000035],
            [149.188610639000217, 19.20851137000011],
            [149.192149639000036, 19.179519370000094],
            [149.195419639000079, 19.150500370000145],
            [149.198424639000223, 19.121455370000149],
            [149.199351639000156, 19.111606370000061],
            [149.200208639000039, 19.103322370000171],
            [149.202947639000172, 19.074252370000067],
            [149.205416639000191, 19.045161370000244],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MOZ", Country: "Mozambique" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [43.023561540000145, -14.427294661000019],
            [42.841014540000145, -14.717577661999925],
            [42.572943540000068, -15.24873166199994],
            [42.523264540000099, -15.358838662000011],
            [42.474731540000022, -15.400795662000021],
            [42.165039539000105, -15.67631766300002],
            [41.926492539000179, -15.892944663000023],
            [41.688471539000176, -16.19500866300001],
            [41.415582539000155, -16.539232663000021],
            [41.272450538000072, -17.044328664000034],
            [41.169983538000025, -17.403535663999932],
            [41.0671585380002, -17.837348665],
            [41.005876538000138, -18.250890664999986],
            [40.98814753800005, -18.467971665],
            [40.967307538000142, -18.72085866499998],
            [40.943327538000204, -18.997047666000014],
            [40.944181538000095, -19.078893665999985],
            [40.497481538000073, -19.121142665999983],
            [40.331478538000084, -19.142759665999975],
            [40.27464453800016, -19.150143665999948],
            [39.914574537000107, -19.212333666],
            [39.856328537000053, -19.222344666],
            [39.740286537, -19.244539666000023],
            [38.793961536000126, -19.421851665999952],
            [38.732686536000216, -19.433158665999983],
            [38.728566536000102, -19.436150666],
            [38.523868536000151, -19.592291665999895],
            [38.483350536000131, -19.62519766600002],
            [38.384377536000073, -19.705931665999898],
            [38.372187536000155, -19.715904665999958],
            [38.350791536, -19.733605665999931],
            [38.084972535000162, -20.028696667000034],
            [38.033017535000084, -20.086535666999964],
            [37.994500535000128, -20.129341666999963],
            [37.553113535000165, -20.641268667000034],
            [37.579160535000057, -21.434170668],
            [37.579967535000122, -21.453787667999975],
            [37.584061535000131, -21.555616668],
            [37.584389535000156, -21.562958667999965],
            [37.584502535000098, -21.564404667999966],
            [37.596867535000086, -21.710089668],
            [37.601611535000217, -21.765005668000029],
            [37.627467535000193, -21.886390667999947],
            [37.679506535000115, -22.158439669],
            [37.700126535000123, -22.265693669],
            [37.71419953500012, -22.325410669],
            [37.715080535000112, -22.329030669000019],
            [37.738181535000052, -22.424109669000018],
            [37.986952535000086, -23.20135967],
            [38.027276535000084, -23.31437067],
            [38.052203535000018, -23.38419167],
            [38.077033535000197, -23.450796669999988],
            [38.246494536000142, -23.89896067],
            [38.305321536000207, -24.053346669999968],
            [38.472959536000218, -24.492551670999958],
            [38.516895536000078, -24.592640670999984],
            [38.687608536000056, -24.98297467099998],
            [38.871208536000069, -25.40013367200001],
            [38.722373036000221, -25.710260172],
            [38.721579536000178, -25.711878171999928],
            [38.720816536000115, -25.71348867200004],
            [38.718395536000088, -25.716835671999945],
            [38.707836036000202, -25.739017172],
            [38.694304536000033, -25.76746367199992],
            [38.691151536000035, -25.774117671999988],
            [38.652479536000129, -25.827512672000012],
            [38.649544036000151, -25.833602171999914],
            [38.648819536000161, -25.835027672],
            [38.645477536000129, -25.839638672000035],
            [38.644826536000011, -25.841012672000033],
            [38.633944536000143, -25.856023671999935],
            [38.630100536000128, -25.864024171999944],
            [38.629377536000021, -25.865442671999986],
            [38.628356536000098, -25.866850671999956],
            [38.627822536000139, -25.867976671999941],
            [38.27383203600013, -26.353894672999985],
            [38.271233536000153, -26.357279672999937],
            [38.268200536000023, -26.36133367299999],
            [38.264007536000094, -26.365325672999973],
            [38.260441536000172, -26.370101172999952],
            [38.254963536000076, -26.377488672999945],
            [38.252602536000126, -26.379735672999971],
            [38.076874535000087, -26.619226673000014],
            [38.069904535, -26.625831672999936],
            [38.068238535, -26.628095673000033],
            [38.030207535000187, -26.664112672999977],
            [38.018241535000101, -26.680363672999988],
            [38.012192535000082, -26.686087673000017],
            [38.010403535000108, -26.688517673],
            [37.562835535000062, -27.109918673],
            [37.552806535000144, -27.116553172999957],
            [37.547973535000125, -27.119838672999933],
            [37.545374035000094, -27.121660672999951],
            [37.542901535000141, -27.123330672999927],
            [37.541796535000032, -27.124365672999943],
            [37.53688903500003, -27.127627672999935],
            [37.530013535000222, -27.13218267299996],
            [37.52840653500013, -27.133686672999971],
            [37.00191803400017, -27.477726673999939],
            [36.997577034000045, -27.480418173999965],
            [36.993584534, -27.482900673999936],
            [36.991131534000118, -27.484455673999989],
            [36.989760534000169, -27.485109673999915],
            [36.988577534000086, -27.485680674],
            [36.987780534000223, -27.48619867400005],
            [36.986414034000035, -27.486851673999894],
            [36.985226534000134, -27.487425673999965],
            [36.984390034000199, -27.487960674],
            [36.981892534000139, -27.489575674000037],
            [36.971621534000207, -27.494060673999968],
            [36.969186534000158, -27.495641673999941],
            [36.967622034000016, -27.496359673999933],
            [36.965730867333463, -27.497265007333297],
            [36.964211534000043, -27.49820567399999],
            [36.957070534, -27.501322673999965],
            [36.939660534000126, -27.512626673999932],
            [36.938726534000097, -27.513033673999942],
            [36.937932534000055, -27.513548673999921],
            [36.936369034000194, -27.514265674],
            [36.935528534000099, -27.514666673999969],
            [36.932024534000135, -27.51693617399998],
            [36.929516534000214, -27.518558674000019],
            [36.928583534000182, -27.518965673999944],
            [36.927788534000143, -27.519481674],
            [36.926218034000243, -27.520200173999953],
            [36.924500534000146, -27.521018173999948],
            [36.923696534000129, -27.521404673999953],
            [36.922684034000127, -27.522056173999957],
            [36.92007403400018, -27.523723673999982],
            [36.915838034000132, -27.526411673999903],
            [36.91338053400014, -27.527967673999953],
            [36.912025534000207, -27.528615673999923],
            [36.910826534000108, -27.529195673999951],
            [36.910029534000074, -27.52971267400001],
            [36.908669534000097, -27.530361673999963],
            [36.907474534000158, -27.530938674000012],
            [36.906677534000011, -27.531455673999986],
            [36.532966534000167, -27.702084673999934],
            [32.898472531000124, -26.84735567300001],
            [32.890427333485576, -26.847145819610773],
            [32.767773237719467, -26.851673032139274],
            [32.688891146714894, -26.853617968957636],
            [32.395000162890739, -26.859726867647026],
            [32.34832721130843, -26.860281917276836],
            [32.175000181992147, -26.851673032139274],
            [32.154163273554019, -26.846945974011319],
            [32.133400125863858, -26.839626896138043],
            [32.132500244736462, -26.79667299500511],
            [32.126109211185479, -26.499026918547756],
            [32.089163290627454, -26.42499996115221],
            [32.072218099844434, -26.39333581307595],
            [32.061100175803489, -26.261390910404629],
            [32.101936137636045, -26.155417837709123],
            [32.07471808629262, -26.037226967634922],
            [31.998609231780478, -25.990835815638221],
            [31.968509146841114, -25.957835927468238],
            [31.919445173552305, -25.814164071669424],
            [31.982363259362302, -25.671390923531604],
            [32.006100138669325, -25.615835836767616],
            [32.005000265331574, -25.536946034412196],
            [31.988191196656572, -25.511117870257223],
            [31.991109104798085, -25.421108970394272],
            [32.009991185772236, -25.346108874037313],
            [32.016663348356616, -25.110555098286824],
            [32.018609123365223, -25.034999952300041],
            [32.011382246427189, -24.924999878031699],
            [32.00249122642802, -24.800835896099926],
            [31.995554028064504, -24.704446020969783],
            [32.016109136917038, -24.459446007952138],
            [32.007500251779646, -24.432226950780318],
            [31.963891221092723, -24.306945996428212],
            [31.941800212297977, -24.267654986993222],
            [31.885827203840478, -24.171117925642974],
            [31.884163228417407, -24.14361790707585],
            [31.877218151064909, -23.964172926614694],
            [31.87740020600242, -23.950646043594233],
            [31.844718159601626, -23.907217894378363],
            [31.769718230882717, -23.856390871570824],
            [31.72000013386841, -23.748608984165031],
            [31.688609235836367, -23.680281888582769],
            [31.686109249388295, -23.639163959526599],
            [31.683609262940223, -23.613608877777736],
            [31.651109103838763, -23.573890894193823],
            [31.621527188155227, -23.540835853100731],
            [31.5508271757773, -23.476673054665667],
            [31.537500117329188, -23.417226920798754],
            [31.545827202881213, -23.306391003143389],
            [31.555691194203717, -23.228226905966935],
            [31.56166330023288, -23.186672950306345],
            [31.516109165089546, -23.050835827065626],
            [31.302918139435661, -22.424826848774174],
            [31.297500244845963, -22.414764038707801],
            [31.306000165241784, -22.409517973133674],
            [31.354718133566536, -22.37944605138901],
            [31.400409229007977, -22.34722685368294],
            [31.688609235836367, -22.054445969118333],
            [31.926391256733297, -21.811108925695436],
            [32.4041272172839, -21.318399868546408],
            [32.416109147906667, -21.307217906764919],
            [32.465409155589469, -21.324999946763256],
            [32.488873287766381, -21.344445962184636],
            [32.476227175134028, -21.321855056683077],
            [32.412218100803699, -21.217781993804692],
            [32.360554061142722, -21.135555020509685],
            [32.376109197099169, -21.106108891657897],
            [32.480827163609575, -20.992226822742055],
            [32.521382164046969, -20.914173031411664],
            [32.510273292461449, -20.859446076683781],
            [32.500827222832442, -20.819445958238177],
            [32.484163328720769, -20.670281944187352],
            [32.502218115849217, -20.59860896048616],
            [32.550827119432398, -20.554999929799237],
            [32.665836219052295, -20.557226833841113],
            [32.80250013322464, -20.362781934691299],
            [32.873891149702132, -20.274026967546988],
            [32.884445139296048, -20.23083585855359],
            [32.89249126345274, -20.166390925256835],
            [32.904163231295144, -20.12417295522485],
            [32.982773245721916, -20.022226884102253],
            [33.018882149426332, -19.943335908280346],
            [33.059436144035459, -19.780281907162589],
            [32.984718182540092, -19.687226856039075],
            [32.850554087177812, -19.617781949846247],
            [32.851800140907443, -19.499999949010061],
            [32.840209142249847, -19.475554966520917],
            [32.812218111793328, -19.474099868125634],
            [32.785409264963988, -19.467082035853352],
            [32.792845186567803, -19.36243581843425],
            [32.840273179990191, -19.302500013782449],
            [32.855000183910619, -19.274173036645692],
            [32.884854176172126, -19.105282045778353],
            [32.834018100909105, -19.023055072483345],
            [32.782700233850193, -19.022217887991843],
            [32.739718169522547, -19.025835852688559],
            [32.71450926037582, -19.017990894208666],
            [32.699436083853328, -18.947917848189988],
            [32.704163309619418, -18.833054928961886],
            [32.790273283390349, -18.788891016283145],
            [32.832218171016137, -18.778335853222927],
            [32.869163253383846, -18.787499955628306],
            [32.893054024433496, -18.791117920325021],
            [32.920273249243422, -18.776735915540328],
            [32.949718204628795, -18.690281948462143],
            [32.905827206718271, -18.613335909458513],
            [32.889445112192192, -18.571391021832724],
            [32.888327133943534, -18.530555060000083],
            [32.915273276347165, -18.514026952720386],
            [32.945554075119588, -18.507082043005866],
            [32.99624514345723, -18.481526961257089],
            [33.020554171476562, -18.459726804503262],
            [33.071591244778261, -18.349726897872998],
            [33.050282267551864, -18.328890995263251],
            [33.020000127675047, -18.301672943919741],
            [32.974054054738275, -18.250099931727448],
            [32.999300179535197, -18.187708899990625],
            [32.988054180013279, -18.122226957630176],
            [32.977218223195763, -18.102217845951884],
            [32.964573284029967, -18.07833579208156],
            [32.954718177524882, -18.041673012213622],
            [32.946109124749313, -17.975000001408603],
            [32.952354145546707, -17.880835856853949],
            [32.974991151515866, -17.817635803820323],
            [33.015000154778903, -17.745282041941309],
            [33.042354160592225, -17.629308855815395],
            [33.040827145467489, -17.599999854899906],
            [33.028473225980719, -17.578055026496926],
            [32.978263278886601, -17.557435879903863],
            [32.956527159873332, -17.505490878847596],
            [33.042027098729392, -17.35648193000597],
            [33.037491147994359, -17.340973062155214],
            [33.017491256409556, -17.324717869643635],
            [32.993818247204985, -17.308054981360414],
            [32.987809260801697, -17.264999994474906],
            [32.988609229643004, -17.252226812190145],
            [32.987218168988164, -17.227781997339079],
            [32.979854164113732, -17.182917860467228],
            [32.968327203196594, -17.147499960862334],
            [32.934718118302072, -17.08555501401375],
            [32.917500180389055, -17.054726876962533],
            [32.865000129702963, -16.918608959964587],
            [32.892218181046445, -16.896526835987217],
            [32.912773289899093, -16.874446053114326],
            [32.926663276966025, -16.859173052019571],
            [32.938609165405154, -16.842226855408171],
            [32.948327143973785, -16.822782013453264],
            [32.959163268429307, -16.796945970309281],
            [32.966945194997237, -16.775281935663429],
            [32.973609143316452, -16.752226840362738],
            [32.981136092389107, -16.709055009746621],
            [32.93048223970149, -16.70270890319668],
            [32.799436212329255, -16.718335788244516],
            [32.767463274939161, -16.718035883748072],
            [32.728054080669068, -16.693608838531759],
            [32.70736335262265, -16.611735916827897],
            [32.579991281060558, -16.545835885135858],
            [32.407218127907498, -16.478335915761207],
            [32.377782224977722, -16.469999945391933],
            [32.353609151428287, -16.463608911840851],
            [32.293054091768141, -16.448335910746081],
            [32.2547181163836, -16.440281907600351],
            [32.230554095289648, -16.439026801415324],
            [32.156109216200576, -16.441391001031576],
            [32.084854154192925, -16.448199956276184],
            [32.055554038094755, -16.44847303868238],
            [31.983054095824031, -16.435281934704193],
            [31.911182293378971, -16.412708966475449],
            [31.902982277479538, -16.365491023547207],
            [31.782218163123133, -16.260835921310786],
            [31.715136115441993, -16.210555063315638],
            [31.662218143062574, -16.198608839600496],
            [31.577082146443303, -16.192499940911105],
            [31.556245070367055, -16.194999927359177],
            [31.510827224969859, -16.186390874583623],
            [31.426245104513924, -16.162363981425997],
            [31.400836203157013, -16.14639092377611],
            [31.343745048812792, -16.093335823460208],
            [31.342082246856108, -16.072781888073976],
            [31.311945113904613, -16.032499970042835],
            [31.276663336407722, -16.018608977147508],
            [31.148882227969438, -15.994655006547731],
            [31.071109230396218, -16.015282032129818],
            [30.820827109620808, -16.004446075312373],
            [30.422363333849574, -16.00555500110562],
            [30.417291276585871, -15.642146020892511],
            [30.415754035539152, -15.631872992693999],
            [30.396663245175404, -15.593608934038897],
            [30.388054024761828, -15.478891021736104],
            [30.361109223462591, -15.337426959239863],
            [30.332773193870452, -15.312499849678673],
            [30.303336117474174, -15.291390864662716],
            [30.271527130110513, -15.257781947406201],
            [30.252500209849103, -15.216108968720221],
            [30.227773260136331, -15.139446070406677],
            [30.219163201532496, -15.096108948659477],
            [30.213018093021191, -14.981717930581439],
            [30.343609150687797, -14.967782010684971],
            [30.504718214987378, -14.884726905353858],
            [30.527773310288154, -14.875835885354689],
            [30.602500156600769, -14.849726927442489],
            [30.80805409497421, -14.778054949569707],
            [30.912218185321279, -14.750554931002668],
            [31.045827231053721, -14.716390964116329],
            [31.094445119454207, -14.7116729584437],
            [31.116391288961637, -14.708335787504112],
            [31.304718236966579, -14.665555056491371],
            [31.463336199635336, -14.625282023277563],
            [31.484718099419496, -14.619717948296085],
            [31.50374518731897, -14.611526817214056],
            [31.534927208323353, -14.588755030241444],
            [31.700836085670659, -14.502081960213616],
            [31.730827205868451, -14.494999922562897],
            [31.782500130346847, -14.48694608705523],
            [31.809718181690272, -14.480555053504148],
            [31.835554057196163, -14.472782011753623],
            [31.855554116418972, -14.465554967177496],
            [31.875000131840437, -14.457781925427071],
            [31.950836071584348, -14.423608906085235],
            [32.094718145515316, -14.37722680654403],
            [32.230273301532435, -14.341390985245724],
            [32.343327070774279, -14.317217911696289],
            [32.372773199625982, -14.308335944152617],
            [32.98389122397046, -14.096672939451693],
            [33.222227121030727, -14.012564061250472],
            [33.257773263754558, -14.036108994974143],
            [33.302354092298316, -14.142781956586703],
            [33.34638221814518, -14.2088908653065],
            [33.435000224991342, -14.321390926022914],
            [33.531945149751238, -14.431663915059374],
            [33.633327118788515, -14.539717879042968],
            [33.696391217352328, -14.533054936552148],
            [33.777218245175078, -14.517781935457393],
            [33.892491207107668, -14.489446073503316],
            [34.129436211151159, -14.443890932531644],
            [34.363745106638618, -14.385972987255883],
            [34.384027133085027, -14.393335818663829],
            [34.410827095096948, -14.429726857230122],
            [34.426936107216733, -14.461672972529982],
            [34.44610920786991, -14.496108848356045],
            [34.467009148220114, -14.526255033763036],
            [34.496109104469753, -14.550555009326871],
            [34.522218230020115, -14.571663994342828],
            [34.532218175812403, -14.592782031814366],
            [34.541109195811572, -14.615554992253365],
            [34.561945098421432, -14.766391028354249],
            [34.588882188369638, -14.96389096358196],
            [34.567218153723871, -15.061108970748109],
            [34.563609241482567, -15.117782035760825],
            [34.575409117167823, -15.211526917517489],
            [34.586791238797588, -15.223735830078866],
            [34.590273249024591, -15.233054997693429],
            [34.593327111636, -15.253055056916239],
            [34.589718199394753, -15.28278197952524],
            [34.566382310336763, -15.321946086945729],
            [34.549718248587141, -15.334026924026304],
            [34.528609263571212, -15.34249985469387],
            [34.434300279729115, -15.469581912405332],
            [34.426527070340541, -15.501808989100496],
            [34.435963249323805, -15.522081963091424],
            [34.443327086560231, -15.547499916903902],
            [34.438327113663973, -15.611664056443431],
            [34.425000222853868, -15.652426928080132],
            [34.37582711718548, -15.714173056184862],
            [34.346936205601679, -15.736664049400332],
            [34.324163245162509, -15.743608959114752],
            [34.300554106060389, -15.759446062294828],
            [34.280273253080367, -15.777500011232888],
            [34.266800181878551, -15.792917851614973],
            [34.251109259090384, -15.837781988486825],
            [34.254436204107975, -15.888890978518077],
            [34.262500265537625, -15.911526811020778],
            [34.286382151769857, -15.929726940350619],
            [34.315136103055437, -15.944726859039193],
            [34.331527082398935, -15.957917963017465],
            [34.420691254057402, -16.050135829649392],
            [34.428745089565012, -16.069026963078954],
            [34.426800152746836, -16.092090943197064],
            [34.407500150079244, -16.131946054717361],
            [34.409582214833762, -16.199172941685717],
            [34.437500155094341, -16.265282018043521],
            [34.450691259072556, -16.280699858425606],
            [34.467773242515676, -16.292499901748954],
            [34.498327123694281, -16.293473040710367],
            [34.525000183691731, -16.29472680579083],
            [34.548609155155845, -16.300973000054597],
            [34.57166324462824, -16.320490932205445],
            [34.594163290299178, -16.379446054182978],
            [34.660000122441005, -16.453608966048449],
            [34.719991247654008, -16.508054959381198],
            [34.767491157805779, -16.546390934765668],
            [34.840973291493498, -16.621526817954461],
            [34.998473275913597, -16.794308855924811],
            [35.022909205947343, -16.813054982429065],
            [35.04374510855709, -16.822081956898145],
            [35.076109145550475, -16.82389093924651],
            [35.124236189699587, -16.817990917584879],
            [35.145136130049849, -16.836108904263369],
            [35.143327147701513, -16.937573015952012],
            [35.1297181220298, -16.963608883668357],
            [35.100273166644371, -16.982781984321534],
            [35.068473231736249, -17.000554971864375],
            [35.05464510311495, -17.023963951118148],
            [35.087773234403898, -17.127081979946013],
            [35.127218135581614, -17.130554937717434],
            [35.271945105355115, -17.135281995845475],
            [35.290054039578223, -17.13426392988292],
            [35.304300257531082, -17.108263936712305],
            [35.308054176697709, -17.061390992919812],
            [35.296663337888475, -17.015281975784816],
            [35.282773183183537, -16.966391004978121],
            [35.289163210906139, -16.867781937157034],
            [35.302218192776451, -16.809172987781551],
            [35.278054171682612, -16.704726930210882],
            [35.255827208417912, -16.677226911643757],
            [35.223118172288792, -16.646046064105946],
            [35.190827225491347, -16.633890963363129],
            [35.172773276553272, -16.624172984794328],
            [35.156109214803593, -16.599164067857956],
            [35.13861819448428, -16.549746042978086],
            [35.193882261569314, -16.506672951181756],
            [35.242982277041762, -16.473264026135581],
            [35.25500024984828, -16.40055503919902],
            [35.274163292217594, -16.304445957826019],
            [35.291663364992388, -16.228335929847503],
            [35.41166321741494, -16.126255077721481],
            [35.442218104421983, -16.122781952311982],
            [35.486936060901968, -16.132781898104298],
            [35.540273296079562, -16.164446046180643],
            [35.709445080704114, -16.110554934839627],
            [35.774163264045086, -16.0705549840321],
            [35.793563346636944, -16.055181903013164],
            [35.81596331238373, -16.013608836613273],
            [35.831663287627435, -15.854999926399941],
            [35.862500141857964, -15.47639086764994],
            [35.863118223399823, -15.458355023622701],
            [35.864436193858836, -15.453608854755444],
            [35.867218147530508, -15.419446061335591],
            [35.85388220426492, -15.37249985970908],
            [35.840191203580019, -15.324117838064581],
            [35.802363339167442, -15.18666401333482],
            [35.868045106100141, -15.019726844103076],
            [35.920963246117736, -14.893891007759223],
            [35.881100255608516, -14.884999987760054],
            [35.878609153977749, -14.774026942168476],
            [35.877500228184516, -14.699773002834249],
            [35.876027192516432, -14.661117844575955],
            [35.82139126525712, -14.602781977606682],
            [35.544718219345924, -14.269446092604724],
            [35.530000267881036, -14.251108835338499],
            [35.505000235762083, -14.191664042576065],
            [35.490827108004993, -14.15583593262869],
            [35.205554061773711, -13.844446049496113],
            [35.171100248674833, -13.796108954852841],
            [35.095273193748227, -13.686108880584413],
            [34.899436060477086, -13.522499997474924],
            [34.865991260885295, -13.506308842703788],
            [34.830827165309216, -13.496946089192548],
            [34.658054179794334, -13.496390871924646],
            [34.581663358058705, -13.373608898192217],
            [34.565691138598964, -13.339581891604183],
            [34.563045139397246, -13.309308971820855],
            [34.563045139397246, -13.288890990904633],
            [34.556109114500174, -13.046108829473468],
            [34.549163366595423, -13.013335923242053],
            [34.541663239613086, -12.960555078798834],
            [34.539027131057054, -12.926526898744328],
            [34.534163280268757, -12.841663984531337],
            [34.532773225442241, -12.782781952749644],
            [34.530000156587931, -12.758891014061916],
            [34.523891257898555, -12.714726933745183],
            [34.507773193323288, -12.653890912689832],
            [34.49888217332412, -12.632499960450176],
            [34.476527134578618, -12.595417917784161],
            [34.465554049824817, -12.568890870540542],
            [34.446800211969645, -12.511391014596285],
            [34.424436120768661, -12.410281960327126],
            [34.407491265261825, -12.319446101894641],
            [34.373291256191663, -12.246417932084256],
            [34.369445136089865, -12.227217841702668],
            [34.370000185719704, -12.186108965101909],
            [34.375273241022143, -12.155835877680502],
            [34.384445054778524, -12.129864047704686],
            [34.410827095096948, -12.080835781323628],
            [34.471109239988863, -11.981317947557727],
            [34.5150002378995, -11.92333579690353],
            [34.552773284665051, -11.871808885178851],
            [34.608191243492712, -11.77680889723716],
            [34.616800128630217, -11.756526870790651],
            [34.629436182978594, -11.714172946288855],
            [34.633609197305219, -11.691945983024169],
            [34.63416324110662, -11.667226912300336],
            [34.631800214956769, -11.62583590083797],
            [34.626245192430787, -11.604308994128417],
            [34.624854131775948, -11.579372999749808],
            [34.703327185904328, -11.575835836599964],
            [34.890545040477917, -11.573890899781603],
            [34.957773268550937, -11.572500006764841],
            [34.966727152824063, -11.572108907161564],
            [35.011109162623967, -11.573335850151793],
            [35.238609102866718, -11.575835836599964],
            [35.437073292238409, -11.593755004534685],
            [35.573327163706182, -11.607081895344606],
            [35.648054177657031, -11.587082003759889],
            [35.672491281157164, -11.558335931463319],
            [35.733327134574523, -11.48333583510626],
            [35.828473302908009, -11.416873042433465],
            [35.938191242314673, -11.432781894704917],
            [36.168191169005553, -11.585835782392365],
            [36.177773193104457, -11.643608888380811],
            [36.17458220255665, -11.668817965165616],
            [36.187636178598467, -11.705417880759498],
            [36.26721815852008, -11.709446055798892],
            [36.293882166062104, -11.707499945514058],
            [36.342500222100767, -11.696108939066903],
            [36.375545204909969, -11.68389097405003],
            [36.517491226840065, -11.720346050356682],
            [36.557427139907162, -11.740417858670952],
            [36.677773164931949, -11.714726822452093],
            [36.725000160315602, -11.681946037231768],
            [36.746109145331644, -11.663890914827135],
            [36.766109204554454, -11.643608888380811],
            [36.785827128915543, -11.617499930468526],
            [36.826945057971813, -11.572190882174922],
            [36.997773274306468, -11.591390972556326],
            [37.118954136889812, -11.670072903712565],
            [37.190827112801372, -11.689999872739335],
            [37.267773319442966, -11.702781939841515],
            [37.39958226764449, -11.723190868302339],
            [37.473209240619354, -11.718708897023959],
            [37.597218157341928, -11.6397178412778],
            [37.693327238714943, -11.602781979003666],
            [37.791936138898024, -11.561108832679608],
            [37.816936171016977, -11.536254980952435],
            [37.822700238208796, -11.51007293284421],
            [37.810200138330316, -11.483817962178392],
            [37.819163242696931, -11.451108926049258],
            [37.865136137723965, -11.332781933867068],
            [37.900273243571604, -11.304446071912992],
            [37.934436204629634, -11.288054924931501],
            [37.962491272826497, -11.278917812254363],
            [38.03277319587275, -11.260835867759681],
            [38.096691242734408, -11.256326906752804],
            [38.173609118543169, -11.284999888853704],
            [38.333882171817805, -11.347917974663616],
            [38.357218228513631, -11.364308954007114],
            [38.377782222183754, -11.377226807941028],
            [38.416800149212435, -11.397226867163738],
            [38.491709218100738, -11.415308979296682],
            [38.533882261131481, -11.372499917451066],
            [38.571109143084726, -11.342781879659384],
            [38.635136154687956, -11.294445958482683],
            [38.655273174208986, -11.281108841750694],
            [38.67249127976018, -11.270835813551997],
            [38.905691249454293, -11.170135796158931],
            [38.9313911704904, -11.165555086060763],
            [39.136100213021479, -11.14236403629026],
            [39.182636204305396, -11.15944601973338],
            [39.20596320854591, -11.173190999874905],
            [39.233054190236857, -11.17416397119824],
            [39.262500151450553, -11.169445965525696],
            [39.292636110935661, -11.152363982082662],
            [39.315282169360245, -11.135281998639357],
            [39.391391191510451, -11.078055057463402],
            [39.503891252226794, -10.997635893050273],
            [39.522909120032836, -10.99028194645966],
            [39.663891223095135, -10.946108981325693],
            [39.691245061270394, -10.944235793598807],
            [39.766936161726989, -10.920555073043218],
            [39.795273197147623, -10.901808946538964],
            [39.81194513788617, -10.885554927493772],
            [39.839027234759754, -10.866108912072562],
            [39.868327183219748, -10.848608839297839],
            [39.898327188234958, -10.831663983790918],
            [39.942218186145425, -10.814999922041224],
            [39.962773294998129, -10.808891023351833],
            [40.019718101312463, -10.779163933104925],
            [40.158882169570887, -10.677499996843935],
            [40.346109244238079, -10.564372969768243],
            [40.381382304555558, -10.540282038870259],
            [40.427773288914267, -10.499446077037703],
            [40.43680914810048, -10.478172974178435],
            [40.438611109000163, -10.467777776999924],
            [40.492777778000089, -10.414722221999909],
            [40.66861111100016, -10.312777777999841],
            [41.033611111000113, -10.091388889000015],
            [42.221581920000148, -10.091388889000015],
            [42.198876539000167, -10.173820657999855],
            [42.161802539000149, -10.321017657999846],
            [42.137632539000066, -10.416668657999935],
            [42.087118539000102, -10.642781657999834],
            [42.061276539000033, -10.810234658],
            [42.048052539000111, -10.894643657999836],
            [42.030976539000136, -10.999644657999852],
            [42.022776539000148, -11.049394657999926],
            [42.004767539000085, -11.157845657999843],
            [41.962998539000097, -11.393441658999848],
            [41.955135539000111, -11.437491658999832],
            [41.939973539000135, -11.488356658999876],
            [41.938704539000042, -11.577974658999949],
            [41.936118539000205, -11.731296658999838],
            [41.933415539000208, -11.885007658999939],
            [41.932439539000114, -11.921947658999969],
            [41.937344539000122, -12.11602965899985],
            [41.947579539000031, -12.152266658999892],
            [42.068698539000053, -12.594597659999977],
            [42.08046553900013, -12.642063659999891],
            [42.146796539000121, -12.820609659999874],
            [42.171831539000124, -12.888054659999966],
            [42.174908539000086, -12.896446659999938],
            [42.178898539000073, -12.907336659999928],
            [42.206332539000158, -13.120969659999915],
            [42.216696539000139, -13.171642659999975],
            [42.262442539000205, -13.309457659999964],
            [42.30333453900019, -13.410255660999852],
            [42.329913539000046, -13.475761660999908],
            [42.476116540000049, -13.689692660999953],
            [42.549482540000128, -13.797942661000036],
            [42.655227540000141, -13.953803660999853],
            [42.85978854000021, -14.215954660999941],
            [43.023561540000145, -14.427294661000019],
          ],
          [
            [34.744127105553133, -12.085073001028775],
            [34.751173268658221, -12.039235892833403],
            [34.720827091040945, -12.038263927338562],
            [34.709018162900207, -12.085417832526261],
            [34.726100146343327, -12.09639091727999],
            [34.744127105553133, -12.085073001028775],
          ],
          [
            [34.628118212519695, -12.030763967994233],
            [34.616873218826157, -12.008263922323351],
            [34.618327143754954, -12.037226918274783],
            [34.628118212519695, -12.030763967994233],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MRT", Country: "Mauritania" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-6.662781809754335, 26.129164124328781],
            [-6.304163925409853, 25.914445077721723],
            [-6.286108803005277, 25.904444964291088],
            [-5.857781814878933, 25.645553958378287],
            [-5.821945993580641, 25.624718055768426],
            [-5.641663828627884, 25.514791071696038],
            [-5.289445862651746, 25.300000108359541],
            [-4.918890807047489, 25.070827140238066],
            [-4.883608861912535, 25.049718155222138],
            [-4.813054862288368, 25.005836042128863],
            [-4.80610877910749, 25.000273140613956],
            [-4.866663838767693, 24.999445008577936],
            [-4.966663967243619, 24.999445008577936],
            [-5.767217865386158, 25.000000058207831],
            [-6.133890926342985, 24.999445008577936],
            [-6.299717828676989, 24.999445008577936],
            [-6.366945889111832, 25.000000058207831],
            [-6.500281852438036, 25.000000058207831],
            [-6.577217833157817, 24.99916421482078],
            [-6.533336725893093, 24.632500038681485],
            [-6.52389082390215, 24.562773165265014],
            [-6.511108756800013, 24.458054025287993],
            [-6.475281820319026, 24.161391146075857],
            [-6.441799805076982, 23.878764099046293],
            [-6.366945889111832, 23.254717994845976],
            [-6.335954975500442, 22.990264087418097],
            [-6.325836677234975, 22.906391075972863],
            [-6.319726772717161, 22.855553994881461],
            [-6.298890870107357, 22.668609055076047],
            [-6.226108792974856, 22.039164167025206],
            [-6.208336811260381, 21.885827138647869],
            [-6.174445926780322, 21.596382083918343],
            [-6.15305497454068, 21.408882094483033],
            [-6.132226783281851, 21.221109022641485],
            [-6.107217866345479, 20.999445066319936],
            [-6.077499828553925, 20.747218008726179],
            [-6.055281917744708, 20.559445104522752],
            [-6.006663861706215, 20.149164186345558],
            [-5.974163870242876, 19.866391126562291],
            [-5.956663965106316, 19.712500054383497],
            [-5.918336706901073, 19.370000066976104],
            [-5.901108878342257, 19.21582702757371],
            [-5.884445990059021, 19.061664214093071],
            [-5.853890935414029, 18.787218072223766],
            [-5.837781923294187, 18.65000011425019],
            [-5.803336827374608, 18.341391178960862],
            [-5.782781886160137, 18.152500128870656],
            [-5.747781908248669, 17.831109126479362],
            [-5.694446014175639, 17.350273202849564],
            [-5.628054970594292, 16.748609127123075],
            [-5.601390795414204, 16.507773075976559],
            [-5.33499983015696, 16.328054007280798],
            [-5.372499794516443, 16.135273083554239],
            [-5.386108820188156, 16.066391105980131],
            [-5.420281839529935, 15.89444507903471],
            [-5.483890929439553, 15.567218092722271],
            [-5.493608740370291, 15.515554053061123],
            [-5.495054953948227, 15.498373163160295],
            [-5.583608755415867, 15.499445040941282],
            [-5.958890869148092, 15.504445013837483],
            [-6.307981882316881, 15.502782211880913],
            [-6.319163844098341, 15.503609002812425],
            [-6.426390849512302, 15.504445013837483],
            [-6.658608795427767, 15.505836074492379],
            [-7.018890822833498, 15.50721808269185],
            [-7.358336779991248, 15.505836074492379],
            [-7.501108754662596, 15.505000063467378],
            [-7.662217818962063, 15.503336088044307],
            [-8.001390861351808, 15.500827049140753],
            [-8.051108790727994, 15.50139115122596],
            [-8.505090772889275, 15.500827049140753],
            [-8.513890933057837, 15.50139115122596],
            [-8.585281949535442, 15.501109016364353],
            [-8.996390832110649, 15.498336115148163],
            [-9.036946000185992, 15.498336115148163],
            [-9.090554976665317, 15.498891164778058],
            [-9.184672853114364, 15.498218097951053],
            [-9.226390926439535, 15.498609029916281],
            [-9.333608879398128, 15.499718123347577],
            [-9.332499785966888, 15.563609012842846],
            [-9.311808890282407, 15.677500134214199],
            [-9.338054976130991, 15.704718017919447],
            [-9.442499860235216, 15.596945015331073],
            [-9.415281808891677, 15.478891105555078],
            [-9.409726786365695, 15.444309049337335],
            [-9.660836703900998, 15.431109060541644],
            [-9.696663975658112, 15.430000134748468],
            [-9.74625500301994, 15.405553978792966],
            [-9.778263982593842, 15.386245091307785],
            [-9.811390940416402, 15.374718130390761],
            [-10.03278181433177, 15.36694508864025],
            [-10.06055491530509, 15.36694508864025],
            [-10.086946008078996, 15.368609064063151],
            [-10.118054938887326, 15.372773193572414],
            [-10.212781844422807, 15.408053965240867],
            [-10.289726877598071, 15.437918015786238],
            [-10.310699908144215, 15.441244960803999],
            [-10.469999822457225, 15.437218126869084],
            [-10.567426874289254, 15.432291076530703],
            [-10.604999761206557, 15.428054024463847],
            [-10.649999852548319, 15.430273217154763],
            [-10.67506392240773, 15.432127126504156],
            [-10.716390896129667, 15.438900039564999],
            [-10.719445932207634, 15.374445047984523],
            [-10.73458180536602, 15.341391180357903],
            [-10.764308895613112, 15.311391175342578],
            [-10.848054837405812, 15.206109106746965],
            [-10.896563928702733, 15.110364135248858],
            [-10.914445880987159, 15.124718144477015],
            [-10.973890841387743, 15.198891114626349],
            [-10.984999880611326, 15.2230539622538],
            [-10.993054889585437, 15.242773227719283],
            [-11.172499870046636, 15.374718130390761],
            [-11.199999888613831, 15.394164145812169],
            [-11.23694597680975, 15.420000021318003],
            [-11.253890832316586, 15.431391195403251],
            [-11.28666390618605, 15.455554043030872],
            [-11.302226753493471, 15.475000058452224],
            [-11.337226731404826, 15.524445073060292],
            [-11.365136792676367, 15.575691191027886],
            [-11.418126849423516, 15.633473181833665],
            [-11.455281814647321, 15.643327114872477],
            [-11.498608878110588, 15.641800099747627],
            [-11.53778187034851, 15.596391139167679],
            [-11.599717764741683, 15.545554058076391],
            [-11.639863895941005, 15.525282089913787],
            [-11.711672834112107, 15.545000181912997],
            [-11.71083682308705, 15.525835966077182],
            [-11.716181795118985, 15.498682120112221],
            [-11.740626777608043, 15.477427122342533],
            [-11.754999897575431, 15.43860901988586],
            [-11.795281815606643, 15.321109153911436],
            [-11.832226730336174, 15.195273149929619],
            [-11.846672940499388, 15.097218125909762],
            [-11.840881883579357, 15.046900052264448],
            [-11.812217786295946, 15.050345014479319],
            [-11.796317818841942, 15.01479116040457],
            [-11.815281874829338, 14.925000022386712],
            [-11.841390832741638, 14.863054069709676],
            [-11.866945914490316, 14.84082710644509],
            [-11.974308874374287, 14.771527039539478],
            [-12.059963878439532, 14.725635951887568],
            [-12.09430889443496, 14.739582097705977],
            [-12.174999800149692, 14.768326996536246],
            [-12.244836811774178, 14.764382137614632],
            [-12.28360881376318, 14.785827069310983],
            [-12.395836797901779, 14.853053956279325],
            [-12.448054881364214, 14.9056270971613],
            [-12.451872838271242, 14.941182124702493],
            [-12.478754942934586, 15.007426987892075],
            [-12.629308844173863, 15.099864125111765],
            [-12.662781806960481, 15.104718085254035],
            [-12.68598190918641, 15.08790901657909],
            [-12.708054980708368, 15.097773175539658],
            [-12.78344600903057, 15.149653971217333],
            [-12.849726746765839, 15.208054043565141],
            [-12.885763901379107, 15.261700068056598],
            [-12.85007291936796, 15.265836034371091],
            [-12.841736781360623, 15.296453953289927],
            [-12.860836791817917, 15.324444983746389],
            [-12.879445958023894, 15.337217998393101],
            [-12.907217885530713, 15.351664208556485],
            [-12.929926808229311, 15.365482111255858],
            [-12.940136804515873, 15.392635957220875],
            [-12.963608815681653, 15.50038213771893],
            [-13.056599829064623, 15.479927108790662],
            [-13.09166384471655, 15.496664093098104],
            [-13.102690908926888, 15.521391042810762],
            [-13.184717890011484, 15.629445006794299],
            [-13.245836716480881, 15.680553996825722],
            [-13.324717801656959, 15.929445056946008],
            [-13.395417814034829, 16.055418021226217],
            [-13.5205549349281, 16.132218047476215],
            [-13.579863940858786, 16.135554044949345],
            [-13.639717770497214, 16.115835952950135],
            [-13.668190928025695, 16.098953961717314],
            [-13.710345866145701, 16.136245049048966],
            [-13.698890821958003, 16.166944942981218],
            [-13.703508747706394, 16.17874498630465],
            [-13.816108888347003, 16.135273083554239],
            [-13.969717825664134, 16.233891036192631],
            [-13.984236790747161, 16.299864158080425],
            [-14.029726720511917, 16.357773218538966],
            [-14.219163935414542, 16.543191143219701],
            [-14.34360887874152, 16.636109066406675],
            [-14.416108821012301, 16.650273141708112],
            [-14.453608785371785, 16.635136095083226],
            [-14.504999910264644, 16.626391087837931],
            [-14.639508837124481, 16.635344972111113],
            [-14.756390789195166, 16.644445036775949],
            [-14.854717889792653, 16.637217992199794],
            [-14.906945863900944, 16.635273223019794],
            [-14.951663988019106, 16.648400121619375],
            [-14.958090896115891, 16.672554084429521],
            [-14.96152680587511, 16.684718069989799],
            [-14.984517863435542, 16.690618091651459],
            [-15.003954994039361, 16.670064156265255],
            [-15.021663943841816, 16.638745007324644],
            [-15.088336787008757, 16.608053998209698],
            [-15.235972947744472, 16.559300155339315],
            [-15.354999828843745, 16.56166418731749],
            [-15.441390931648016, 16.579436001394015],
            [-15.509999826815772, 16.556664214421517],
            [-15.576390870397177, 16.513335977491636],
            [-15.67028193254572, 16.481109068434705],
            [-15.696317800262023, 16.480136097111313],
            [-15.74833672970442, 16.488891162640584],
            [-15.823054858837793, 16.501664177287239],
            [-15.888336808988015, 16.512364179634687],
            [-15.915699867256876, 16.509718012794906],
            [-15.985554983792213, 16.490000088433874],
            [-16.05659982766781, 16.48374500935256],
            [-16.099236725221715, 16.526664209406192],
            [-16.115417821708888, 16.54937313210479],
            [-16.145836754246034, 16.55193598282699],
            [-16.28528195153774, 16.517082185307402],
            [-16.30236393498086, 16.499027062902769],
            [-16.321808776935768, 16.456244990785478],
            [-16.345146007098151, 16.381109107596728],
            [-16.357499926584921, 16.322773072989492],
            [-16.367499872377266, 16.27374497424637],
            [-16.390036798422329, 16.220618124839007],
            [-16.50694590785929, 16.094164206953451],
            [-16.526602574713309, 16.062010511824951],
            [-16.527681898182948, 16.060244991640502],
            [-16.527793761364705, 16.062931289599703],
            [-19.558332162428485, 16.066666665571461],
            [-19.541666517999829, 16.283333367000068],
            [-19.541666517999829, 16.475000367000064],
            [-19.553333517999931, 16.633333366999977],
            [-19.534999517999893, 17.00000036800003],
            [-19.61333351799982, 17.10000036800011],
            [-19.631666517999861, 17.446666368000024],
            [-19.633333517999858, 17.531666368000174],
            [-19.633333517999858, 17.735000368000101],
            [-19.633333517999858, 17.888333369000065],
            [-19.701666517999854, 18.041666368999984],
            [-19.736666517999822, 18.130000369000129],
            [-19.783333517999893, 18.223333369000159],
            [-19.816666518000034, 18.313333369000134],
            [-19.858333517999938, 18.400000369000054],
            [-19.896666518999893, 18.480000369000209],
            [-19.933333518999859, 18.581666369000175],
            [-19.999999518999914, 18.736666369000147],
            [-20.013618518999976, 18.770461369000031],
            [-20.03776951899988, 18.98415237],
            [-20.038915518999886, 18.985636370000066],
            [-19.82206451799982, 19.063719370000229],
            [-19.469578517999935, 19.189234370000122],
            [-19.382438517999844, 19.227591369999971],
            [-18.771272516999886, 19.486829370000066],
            [-18.024224516999823, 19.797664370000064],
            [-17.383200515999874, 20.061889371000234],
            [-17.284614515999863, 20.102173371000163],
            [-17.135286515999951, 20.165782371000034],
            [-17.119082515999821, 20.174587371000086],
            [-17.05311851599987, 20.762480371000095],
            [-17.052327244273158, 20.764100336530134],
            [-17.075554942492829, 20.899027016150399],
            [-17.070281887190333, 20.920827005266119],
            [-17.049163849718866, 20.974445034200926],
            [-17.038063862950708, 21.004164245458924],
            [-16.99499982360976, 21.128053971517943],
            [-16.960554895328244, 21.300554042264764],
            [-16.953754992539132, 21.336664119435852],
            [-16.604726843644357, 21.339718149685325],
            [-16.146117883279203, 21.339718149685325],
            [-16.017781892849314, 21.338891191115636],
            [-15.892226850262603, 21.338609056254029],
            [-15.741999843248095, 21.338282162029429],
            [-15.225554912620623, 21.33750013046091],
            [-15.005281849315736, 21.336391037029557],
            [-14.687226844397571, 21.338609056254029],
            [-14.467226863498979, 21.340553993072376],
            [-14.303472973464181, 21.341664092331996],
            [-14.285281896589652, 21.342218136133454],
            [-13.908336813262849, 21.342500103357054],
            [-13.3769459434032, 21.340000116908982],
            [-12.999726771841665, 21.338054006624304],
            [-13.004999827144218, 21.423336015997108],
            [-13.03139091991801, 21.797773233886971],
            [-13.047781899261395, 22.033882059267455],
            [-13.051390811502756, 22.085827060323538],
            [-13.059163853253324, 22.204718154591205],
            [-13.066390897829365, 22.323891048444182],
            [-13.077799841549449, 22.49680904088433],
            [-13.079999923501077, 22.520273173061298],
            [-13.094163831164565, 22.570827113462485],
            [-13.1502818465475, 22.757500144328276],
            [-13.133890867203945, 22.809164183989253],
            [-13.105281922843687, 22.893053959240831],
            [-13.049445874684466, 22.962773121306384],
            [-12.998336717015206, 23.024718068154982],
            [-12.744999895438013, 23.182500187436801],
            [-12.63666396423082, 23.250827115380957],
            [-12.597426766614319, 23.276173152464139],
            [-12.57139089889813, 23.291382115818465],
            [-12.365836792886569, 23.318609051979251],
            [-12.231390897938809, 23.38055399882785],
            [-12.0988907779996, 23.429718052040869],
            [-12.000281877816576, 23.454436116936222],
            [-12.000836759808294, 23.799718058015344],
            [-12.000281877816576, 24.000000114552847],
            [-12.000836759808294, 24.333054032331006],
            [-11.999999910592976, 24.399718158318592],
            [-12.000836759808294, 24.766109084413813],
            [-11.999999910592976, 24.799718001670158],
            [-11.999999910592976, 24.866391180113453],
            [-12.000836759808294, 25.200000147521507],
            [-11.999999910592976, 25.232773221391028],
            [-11.999999910592976, 25.29971814113577],
            [-12.000836759808294, 25.632500149974305],
            [-11.999999910592976, 25.666109067230821],
            [-11.999999910592976, 25.733053986975733],
            [-12.000554960222757, 26.000000169500822],
            [-11.700554910071048, 26.000000169500822],
            [-11.266672899418836, 25.99944495223275],
            [-10.733336757464883, 25.99944495223275],
            [-10.500281794695979, 25.99944495223275],
            [-10.134117850539667, 25.998200071969904],
            [-10.000308812596813, 25.997964205213691],
            [-9.966663853156547, 25.999718034639216],
            [-9.899999894807081, 26.000000169500822],
            [-9.700554855123073, 26.00027308426894],
            [-9.633890896773494, 26.00027308426894],
            [-9.567217885968375, 26.00027308426894],
            [-9.399999922979532, 25.99944495223275],
            [-8.666945994211517, 26.00027308426894],
            [-8.666390776943615, 26.066391045444163],
            [-8.66666385934991, 26.13332708037143],
            [-8.667217735513248, 26.399718045628788],
            [-8.667217735513248, 26.833054059106757],
            [-8.667217735513248, 26.966391195899291],
            [-8.666790929002502, 27.290454013754243],
            [-8.644163981317092, 27.278891178291417],
            [-8.204445986743622, 27.027500131722718],
            [-8.131108859981225, 26.986382202666618],
            [-7.711945974260288, 26.743891060914564],
            [-7.693608884632113, 26.734164197528344],
            [-7.258336819153499, 26.479718114881848],
            [-7.203890825820679, 26.448609016435398],
            [-6.986945881000054, 26.32082706980691],
            [-6.771108856144281, 26.192500131832276],
            [-6.662781809754335, 26.129164124328781],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MSR", Country: "Montserrat" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-61.822849557999888, 16.693307367],
            [-61.8511115579999, 16.657777367000122],
            [-62.052777557999974, 16.431111367000014],
            [-62.405277557999966, 16.128055367000101],
            [-62.649444557999914, 15.948333367000075],
            [-62.813888559, 15.841944367000153],
            [-63.045478559, 16.348982367000175],
            [-62.888084559, 16.492577367000123],
            [-62.885578558999839, 16.494843367000115],
            [-62.753156557999858, 16.613675367],
            [-62.522579557999904, 16.722262368000074],
            [-62.406658558, 16.777151368],
            [-62.394938558, 16.783882368000135],
            [-62.340309558, 16.814559368000019],
            [-62.33358155799985, 16.819678368],
            [-62.328920557999936, 16.823314368000112],
            [-62.313511557999874, 16.835940368000124],
            [-62.312887557999943, 16.836627368000123],
            [-62.306080558000019, 16.844527368000158],
            [-62.288367557999834, 16.864627368000029],
            [-62.282340557999873, 16.871618368],
            [-62.270208557999979, 16.886273368],
            [-62.225369557999898, 16.938268368000124],
            [-62.203989557999989, 16.961982368000051],
            [-62.139103557999988, 17.034887368000099],
            [-62.119369558000017, 17.01463136800011],
            [-62.117972557999877, 17.013258368],
            [-62.084195558, 16.981209368],
            [-62.078791557999978, 16.974660368000016],
            [-62.054185557999944, 16.942747367999985],
            [-62.024328558000036, 16.905212368000022],
            [-62.020120558000031, 16.899979368000018],
            [-62.002839557999891, 16.878723368000166],
            [-61.990337558000022, 16.863030368000054],
            [-61.988206557999916, 16.860405368000116],
            [-61.957001557999945, 16.83181336799997],
            [-61.941618557999902, 16.817358367999972],
            [-61.92323255799991, 16.795188367999984],
            [-61.885905557999934, 16.752989368000115],
            [-61.827399557999968, 16.697603367000013],
            [-61.822849557999888, 16.693307367],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MUS", Country: "Mauritius" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [58.23972255400011, -23.801388669999938],
            [57.244444553000022, -22.00888866899993],
            [56.835833553000185, -21.305277667999974],
            [56.462222553000146, -20.598610666999932],
            [56.294166552000121, -20.082499666999979],
            [55.845833552000187, -19.013610666],
            [55.505555552000175, -18.28638866499999],
            [55.665406552000178, -18.170571664999983],
            [56.474932553000173, -17.593995663999976],
            [56.786626553000104, -17.370330663999937],
            [56.799487553000091, -17.360509663999935],
            [56.966933553000132, -16.541556662999923],
            [56.968298553000096, -16.532039663000035],
            [57.069852553000175, -15.818267663000015],
            [57.078749553000108, -15.755863663000014],
            [57.178143553000183, -13.770501660999912],
            [56.978165553000082, -13.696643660999896],
            [56.67434755300016, -13.583767660999868],
            [55.898254552000168, -13.293394659999976],
            [55.86209955200016, -13.279779660000031],
            [55.718248552000119, -13.225443659999939],
            [54.513881551000139, -12.78314866],
            [53.995045550000128, -12.59326365999982],
            [53.990340550000127, -12.588597659999849],
            [53.987596050000121, -12.584713159999964],
            [53.958071550000142, -12.54275666],
            [53.939475550000083, -12.52426966],
            [53.93616855000019, -12.519566659999896],
            [53.932907050000182, -12.516239659999869],
            [53.92276155000016, -12.506042159999808],
            [53.919530550000133, -12.502802659999816],
            [53.915291550000148, -12.496772659999962],
            [53.913460550000167, -12.494951660000012],
            [53.910719050000154, -12.491069659999894],
            [53.910186550000191, -12.490329659999858],
            [53.903792550000134, -12.483970659999869],
            [53.899791550000117, -12.47827866],
            [53.897721550000171, -12.476219659999899],
            [53.800555550000155, -12.297777659999909],
            [53.823611550000095, -11.73749965899998],
            [53.86611155000017, -10.604166657999954],
            [53.90472255, -9.637777656999987],
            [54.467777551000182, -8.966666655999859],
            [55.933333552000164, -8.815277656000035],
            [57.252499553000092, -8.672499656000014],
            [58.338611554000153, -8.558055655999922],
            [59.386944555000099, -8.436944655999937],
            [59.608998556000159, -8.775061655999934],
            [59.862292556000085, -9.298783656999902],
            [59.862829556000094, -9.300688656999867],
            [59.863118056000047, -9.301288656999958],
            [59.863928556000161, -9.30296665699997],
            [59.864327556000063, -9.304381656999894],
            [59.864849556000166, -9.305457657000019],
            [60.022957556000193, -9.864520656999971],
            [60.080669556, -10.442125657999938],
            [60.080442556000065, -10.445036657999921],
            [60.080732556000072, -10.447921657999885],
            [60.035774556, -11.026628657999908],
            [59.888918556000164, -11.588868659],
            [59.887861556000104, -11.591154658999869],
            [59.887357556000069, -11.593082658999876],
            [59.64247455600011, -12.12167965899998],
            [59.306513555000066, -12.600377659999936],
            [58.920316555000142, -12.983627659999897],
            [59.010710555000202, -12.9607856599999],
            [59.604178556000107, -12.913388159999968],
            [59.60537755600015, -12.913354660000039],
            [60.198108556000108, -12.96548866],
            [60.773250557000125, -13.115684659999886],
            [61.31419955700008, -13.359688160999909],
            [61.315619557000019, -13.360363660999937],
            [61.806173558000211, -13.690836660999935],
            [62.231982058000114, -14.098296160999894],
            [62.233238558000181, -14.099513661],
            [62.581366558000155, -14.571869661999955],
            [62.841086559000104, -15.095254661999931],
            [62.863542559000138, -15.172510662],
            [62.894785559000155, -15.235187661999916],
            [63.058050559, -15.793995663],
            [63.062185559000028, -15.833472662999981],
            [63.111489559000148, -16.001363662999964],
            [63.14815155900007, -16.348757662999972],
            [63.332052559000118, -16.33061266299994],
            [63.332677559000189, -16.330628662999928],
            [63.385356559000144, -16.334217662999961],
            [63.395821559000154, -16.333176662999989],
            [63.39644955900016, -16.333193162999933],
            [64.000359560000135, -16.373533662999947],
            [64.588610560000035, -16.512112662999911],
            [64.591686560000113, -16.51288166300003],
            [64.608915560000099, -16.520131663000015],
            [64.61293656, -16.521070663],
            [64.616215560000143, -16.522449662999961],
            [64.617068560000035, -16.522649663],
            [64.618614560000225, -16.523299662999946],
            [64.619628560000109, -16.523536663000016],
            [64.620904560000071, -16.524073662999953],
            [64.625330560000094, -16.525106662999988],
            [65.180666561000209, -16.757528664],
            [65.183569561000155, -16.759369664],
            [65.187182561000128, -16.760873664000016],
            [65.693673561000224, -17.080579663999984],
            [66.136566895333516, -17.478445663999949],
            [66.502558062000077, -17.942820664999914],
            [66.505040562000062, -17.945988665000016],
            [66.782481562000129, -18.46279866499998],
            [66.962720562000214, -19.017110665999937],
            [67.039483562000129, -19.592443665999951],
            [67.039350562000067, -19.594977666],
            [67.039573562000129, -19.59663766599995],
            [67.039221562000222, -19.603382665999902],
            [67.040889562000217, -19.615793665999973],
            [67.040881562000067, -19.617156165999916],
            [67.010870562000122, -20.19658666700002],
            [66.873685562000134, -20.761957666999919],
            [66.872730562000271, -20.765543166999933],
            [66.872132562000075, -20.76771366700001],
            [66.630690562000069, -21.302202167999965],
            [66.63002256200005, -21.303517667999927],
            [66.291072562000153, -21.790025667999927],
            [66.289681562000141, -21.791410667999955],
            [66.289090562000212, -21.792256667999965],
            [66.228545562000107, -21.852487668000023],
            [66.224705562000082, -21.857936334666675],
            [65.797129561000105, -22.28166566900002],
            [65.294433561000147, -22.628674668999963],
            [64.731915560000147, -22.888124669000035],
            [64.12737056000006, -23.051609669999976],
            [63.500239559000164, -23.113785670000027],
            [63.498853059000083, -23.113753669999952],
            [62.869137559000166, -23.072585669999953],
            [62.83159755900013, -23.06385267],
            [62.796804558000105, -23.061503669999979],
            [62.185783558000168, -22.917992668999972],
            [61.612563057000187, -22.676920669000026],
            [61.61104155700005, -22.676233668999942],
            [61.601572557000139, -22.670202669],
            [61.59756855700013, -22.668509668999988],
            [61.080884557000019, -22.337861669],
            [61.077489557000121, -22.334743669000019],
            [61.057316557000178, -22.321773668999981],
            [60.82724355700006, -22.109945668999941],
            [60.48905855600006, -22.558746668999959],
            [60.487782556000099, -22.559936168999968],
            [60.042432556000136, -22.970524668999929],
            [60.028567556000127, -22.979434668999957],
            [59.983850556000078, -23.020464669],
            [59.977184556000026, -23.02474367],
            [59.976295556000167, -23.025559669999936],
            [59.965339556000146, -23.032592669999914],
            [59.944426556000138, -23.051761669999948],
            [59.425635555000127, -23.383105669999978],
            [59.419912055000253, -23.38555667],
            [59.418232055000118, -23.386344670000014],
            [59.414393555000089, -23.388009670000017],
            [59.408801555000224, -23.39156367],
            [59.40163955500006, -23.39458667000001],
            [59.39693655500011, -23.397575669999966],
            [58.821295555000034, -23.639199670000025],
            [58.803567055000173, -23.643400169999936],
            [58.795084555000159, -23.645410669999933],
            [58.755046555000121, -23.662109669999964],
            [58.737454055000143, -23.666312169999955],
            [58.23972255400011, -23.801388669999938],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MWI", Country: "Malawi" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [34.744127105553162, -12.085073001028775],
              [34.726100146343356, -12.096390917280019],
              [34.709018162900293, -12.085417832526275],
              [34.720827091041087, -12.038263927338562],
              [34.751173268658249, -12.039235892833403],
              [34.744127105553162, -12.085073001028775],
            ],
          ],
          [
            [
              [34.62811821251978, -12.030763967994233],
              [34.618327143754982, -12.037226918274769],
              [34.616873218826242, -12.008263922323351],
              [34.62811821251978, -12.030763967994233],
            ],
          ],
          [
            [
              [33.128954140424241, -9.491872916204443],
              [33.199645100346572, -9.507635923360169],
              [33.254718227676619, -9.492226800157425],
              [33.311936116397305, -9.486045984738496],
              [33.398054136795338, -9.542845951765685],
              [33.41791824154825, -9.578472896036317],
              [33.429163235241617, -9.609655084678707],
              [33.493473219896828, -9.62208192672324],
              [33.531527228057826, -9.613890963279232],
              [33.549436170070521, -9.594999997487719],
              [33.578891183739756, -9.584717916833711],
              [33.762909162948233, -9.585135838527151],
              [33.871382222091711, -9.659726898007932],
              [33.900273301313689, -9.684164001508123],
              [33.918818094503138, -9.706735796270323],
              [33.944509130721883, -9.686254951080116],
              [33.958609168282919, -9.628608914744234],
              [33.96027331134411, -9.604446067116726],
              [33.95242717939766, -9.578399973478383],
              [33.949300226590367, -9.551808888494236],
              [33.957218107628137, -9.526672901905442],
              [34.002700158403854, -9.487781876891006],
              [34.041527145677719, -9.483755042956261],
              [34.07443617401708, -9.507781936113986],
              [34.136363351230841, -9.571172928902456],
              [34.176382245139621, -9.60360888262511],
              [34.205827200524993, -9.627499988950944],
              [34.329645177492694, -9.740208926695175],
              [34.3738912328227, -9.811673033368606],
              [34.426663360086707, -9.881391021967715],
              [34.482773328842427, -9.951390977790368],
              [34.500545142918781, -9.969026837396825],
              [34.524582261998319, -10.015554949691818],
              [34.534854116730401, -10.045973044038647],
              [34.57332722005134, -10.34667298310751],
              [34.569163258180083, -10.367781968123438],
              [34.565554178300829, -10.403055028440917],
              [34.575827206499241, -10.516672899768082],
              [34.580227202764718, -10.534463992221788],
              [34.580827179395612, -10.53889097821525],
              [34.585000193722237, -10.561672991109845],
              [34.621109265064831, -10.606945997219782],
              [34.651382184848217, -10.643890911949427],
              [34.65790917286904, -10.664172938395836],
              [34.66305415269062, -10.728890954098631],
              [34.66305415269062, -10.761108978338484],
              [34.64999129183127, -10.890281985621812],
              [34.641382239055758, -10.926673024188005],
              [34.627218163754151, -10.951664003851647],
              [34.599582190717172, -10.981526880930403],
              [34.593118234608312, -11.020972955574678],
              [34.616036201972747, -11.110763925954359],
              [34.638673207941792, -11.137017890791867],
              [34.708882208430396, -11.19902687538098],
              [34.802491135717332, -11.331808962543732],
              [34.863473169526287, -11.35722691635624],
              [34.890545040478003, -11.383055080511269],
              [34.957636140614454, -11.481945947917893],
              [34.964436211041743, -11.508890916855137],
              [34.966727152824149, -11.572108907161578],
              [34.957773268551023, -11.572500006764756],
              [34.890545040478003, -11.573890899781532],
              [34.703327185904357, -11.575835836599879],
              [34.624854131775976, -11.579372999749808],
              [34.626245192430872, -11.604308994128417],
              [34.631800214956741, -11.625835900837956],
              [34.634163241106648, -11.66722691230035],
              [34.633609197305191, -11.691945983024198],
              [34.629436182978566, -11.714172946288841],
              [34.616800128630302, -11.756526870790651],
              [34.60819124349274, -11.776808897237174],
              [34.552773284665136, -11.871808885178837],
              [34.515000237899471, -11.92333579690353],
              [34.471109239988891, -11.981317947557727],
              [34.410827095097034, -12.080835781323628],
              [34.38444505477861, -12.1298640477047],
              [34.375273241022171, -12.155835877680488],
              [34.370000185719732, -12.186108965101823],
              [34.369445136090008, -12.227217841702668],
              [34.373291256191692, -12.246417932084171],
              [34.40749126526191, -12.319446101894641],
              [34.424436120768746, -12.41028196032704],
              [34.44680021196973, -12.5113910145962],
              [34.465554049824846, -12.568890870540542],
              [34.476527134578703, -12.595417917784175],
              [34.498882173324205, -12.632499960450161],
              [34.507773193323374, -12.653890912689747],
              [34.52389125789847, -12.714726933745169],
              [34.53000015658796, -12.75889101406193],
              [34.532773225442327, -12.782781952749644],
              [34.534163280268785, -12.841663984531351],
              [34.539027131057026, -12.926526898744328],
              [34.541663239613001, -12.960555078798848],
              [34.549163366595451, -13.013335923241982],
              [34.556109114500259, -13.04610882947344],
              [34.563045139397275, -13.288890990904633],
              [34.563045139397275, -13.309308971820883],
              [34.56569113859905, -13.339581891604212],
              [34.58166335805879, -13.373608898192231],
              [34.658054179794476, -13.496390871924575],
              [34.830827165309245, -13.496946089192534],
              [34.86599126088538, -13.506308842703788],
              [34.899436060477171, -13.522499997474938],
              [35.095273193748255, -13.686108880584413],
              [35.171100248674861, -13.796108954852855],
              [35.205554061773739, -13.844446049496128],
              [35.490827108005078, -14.15583593262869],
              [35.505000235762168, -14.191664042576065],
              [35.530000267881121, -14.251108835338471],
              [35.544718219346009, -14.26944609260471],
              [35.821391265257148, -14.602781977606696],
              [35.876027192516517, -14.661117844575983],
              [35.877500228184545, -14.699773002834263],
              [35.878609153977834, -14.774026942168462],
              [35.881100255608601, -14.884999987760068],
              [35.920963246117822, -14.893891007759237],
              [35.868045106100169, -15.019726844102991],
              [35.802363339167414, -15.186664013334848],
              [35.840191203580048, -15.324117838064581],
              [35.853882204265005, -15.37249985970908],
              [35.867218147530593, -15.419446061335577],
              [35.864436193858921, -15.45360885475543],
              [35.863118223399908, -15.458355023622687],
              [35.86250014185805, -15.476390867649854],
              [35.831663287627407, -15.854999926399955],
              [35.815963312383815, -16.013608836613287],
              [35.793563346637029, -16.055181903013079],
              [35.774163264045114, -16.070554984032128],
              [35.709445080704143, -16.110554934839627],
              [35.54027329607959, -16.164446046180558],
              [35.486936060902053, -16.132781898104327],
              [35.442218104422068, -16.122781952311982],
              [35.411663217414855, -16.126255077721396],
              [35.291663364992417, -16.228335929847503],
              [35.274163292217679, -16.304445957826033],
              [35.255000249848365, -16.400555039199048],
              [35.24298227704179, -16.473264026135496],
              [35.193882261569343, -16.50667295118177],
              [35.138618194484309, -16.549746042978086],
              [35.156109214803678, -16.599164067857942],
              [35.172773276553187, -16.624172984794313],
              [35.190827225491375, -16.633890963363115],
              [35.223118172288878, -16.64604606410586],
              [35.255827208417941, -16.677226911643743],
              [35.278054171682697, -16.704726930210882],
              [35.302218192776422, -16.809172987781551],
              [35.289163210906167, -16.867781937157019],
              [35.282773183183565, -16.966391004978107],
              [35.29666333788856, -17.015281975784831],
              [35.308054176697794, -17.061390992919826],
              [35.304300257531168, -17.108263936712319],
              [35.290054039578308, -17.134263929882934],
              [35.271945105355201, -17.135281995845446],
              [35.1272181355817, -17.13055493771742],
              [35.087773234403983, -17.127081979946013],
              [35.054645103114979, -17.023963951118162],
              [35.068473231736334, -17.000554971864403],
              [35.100273166644399, -16.982781984321548],
              [35.129718122029772, -16.963608883668371],
              [35.143327147701484, -16.937573015952012],
              [35.145136130049934, -16.836108904263369],
              [35.124236189699673, -16.817990917584893],
              [35.076109145550447, -16.823890939246496],
              [35.043745108557175, -16.82208195689816],
              [35.022909205947315, -16.813054982429094],
              [34.998473275913625, -16.794308855924726],
              [34.840973291493583, -16.621526817954475],
              [34.767491157805864, -16.546390934765668],
              [34.719991247654036, -16.508054959381184],
              [34.66000012244109, -16.453608966048421],
              [34.594163290299207, -16.379446054182893],
              [34.571663244628326, -16.32049093220536],
              [34.548609155155816, -16.300973000054512],
              [34.525000183691816, -16.294726805790845],
              [34.49832712369431, -16.293473040710353],
              [34.467773242515761, -16.292499901748954],
              [34.450691259072528, -16.280699858425521],
              [34.437500155094369, -16.265282018043536],
              [34.40958221483379, -16.199172941685731],
              [34.407500150079215, -16.131946054717275],
              [34.426800152746921, -16.092090943196979],
              [34.428745089565098, -16.069026963078954],
              [34.420691254057431, -16.050135829649378],
              [34.331527082398964, -15.957917963017394],
              [34.315136103055522, -15.944726859039179],
              [34.286382151769942, -15.929726940350633],
              [34.262500265537597, -15.911526811020792],
              [34.254436204108003, -15.888890978518077],
              [34.251109259090356, -15.837781988486825],
              [34.266800181878637, -15.792917851614959],
              [34.280273253080452, -15.777500011232803],
              [34.300554106060417, -15.759446062294842],
              [34.324163245162595, -15.743608959114681],
              [34.346936205601764, -15.73666404940036],
              [34.375827117185565, -15.714173056184848],
              [34.425000222853953, -15.652426928080047],
              [34.438327113664059, -15.611664056443431],
              [34.443327086560203, -15.547499916903917],
              [34.435963249323891, -15.522081963091409],
              [34.426527070340626, -15.501808989100425],
              [34.434300279729143, -15.469581912405317],
              [34.528609263571184, -15.34249985469387],
              [34.549718248587169, -15.334026924026318],
              [34.566382310336849, -15.321946086945729],
              [34.589718199394781, -15.282781979525168],
              [34.593327111635972, -15.253055056916253],
              [34.590273249024676, -15.233054997693444],
              [34.586791238797673, -15.223735830078851],
              [34.575409117167908, -15.211526917517517],
              [34.563609241482652, -15.11778203576074],
              [34.5672181537239, -15.061108970748137],
              [34.588882188369723, -14.963890963581946],
              [34.561945098421461, -14.766391028354235],
              [34.5411091958116, -14.615554992253351],
              [34.532218175812432, -14.592782031814352],
              [34.522218230020144, -14.571663994342828],
              [34.496109104469838, -14.550555009326899],
              [34.4670091482202, -14.52625503376305],
              [34.446109207869938, -14.496108848355959],
              [34.426936107216818, -14.461672972529982],
              [34.410827095097034, -14.429726857230037],
              [34.384027133085112, -14.393335818663843],
              [34.363745106638703, -14.385972987255798],
              [34.129436211151187, -14.443890932531644],
              [33.892491207107696, -14.489446073503302],
              [33.77721824517505, -14.517781935457378],
              [33.6963912173523, -14.533054936552162],
              [33.6333271187886, -14.539717879042939],
              [33.531945149751323, -14.431663915059403],
              [33.435000224991256, -14.321390926022843],
              [33.346382218145152, -14.2088908653065],
              [33.302354092298344, -14.142781956586703],
              [33.257773263754586, -14.036108994974143],
              [33.222227121030699, -14.012564061250487],
              [33.204509118772989, -13.9971549380478],
              [33.189991159518115, -13.973608830857643],
              [33.166727187189849, -13.926946105197317],
              [33.078891211912151, -13.981108957839979],
              [32.990000122659751, -13.934717973481384],
              [32.899163258399085, -13.819999893540512],
              [32.830827110361213, -13.704164002989046],
              [32.780963335869387, -13.638754983186288],
              [32.754163206219289, -13.641945973734153],
              [32.710000131731022, -13.63139097831197],
              [32.681873314442782, -13.612846017484515],
              [32.695482172476318, -13.566045996249727],
              [32.753327195194231, -13.563891008937389],
              [32.813745126918207, -13.541255008796611],
              [32.83166328902422, -13.528608896164315],
              [32.89638231055568, -13.430281963204948],
              [32.918745060652213, -13.389308873435994],
              [32.97749113796408, -13.229172948097926],
              [32.986245197664857, -13.140554941251821],
              [32.986382157963249, -13.096390860935117],
              [32.992773191514118, -13.036946068172654],
              [33.002782189762115, -13.002226884018455],
              [33.010273264289026, -12.981108846546988],
              [33.020554171476476, -12.962781982840738],
              [33.028882262856712, -12.941108895739433],
              [33.034854033609804, -12.913755057564117],
              [33.026736160361907, -12.885764027107541],
              [32.972354037131311, -12.853126907708031],
              [32.962009259841466, -12.757990965296472],
              [33.046391220448982, -12.603891016089904],
              [33.06902722058976, -12.585282017522047],
              [33.143745182085013, -12.581255015949125],
              [33.16430012329954, -12.589308851456792],
              [33.238054165927053, -12.585835893685385],
              [33.364018245389786, -12.540417880650068],
              [33.479582227001515, -12.439581908787204],
              [33.476391236453651, -12.412781946775283],
              [33.50527326322009, -12.386108886777777],
              [33.528663299372852, -12.377808958592141],
              [33.541800256256408, -12.364172943192045],
              [33.545827090191153, -12.335763991042199],
              [33.479718181471412, -12.314445961360434],
              [33.43666336222401, -12.336390957401477],
              [33.410273275278598, -12.341945979927516],
              [33.389582211955997, -12.340135824112679],
              [33.368882263816033, -12.326255057139235],
              [33.355273238144321, -12.309717897403971],
              [33.271382289426299, -12.129926911978657],
              [33.279991174563861, -12.057226809859515],
              [33.312427128286629, -12.000135823153357],
              [33.332218142843715, -11.897226838991344],
              [33.330827082188819, -11.74471794264997],
              [33.324582229029431, -11.613055007202263],
              [33.308745125849441, -11.582499952557271],
              [33.2704091504649, -11.570763946974353],
              [33.254163345684844, -11.520281923302491],
              [33.249509210114752, -11.411045943329611],
              [33.293054035423154, -11.368054994184718],
              [33.33943613496416, -11.300281942403899],
              [33.391109227080847, -11.215554982660763],
              [33.400836090466839, -11.193890948014939],
              [33.410409229748325, -11.163190886444511],
              [33.384436058668143, -11.113055035374771],
              [33.34978225335874, -11.079999994281593],
              [33.250409258880268, -10.89208191551478],
              [33.262563353794576, -10.863682015820245],
              [33.34416336073042, -10.813890996248006],
              [33.396945043363871, -10.798608942697911],
              [33.417773234622643, -10.80305503943066],
              [33.460900138237719, -10.803608915594054],
              [33.510827112279856, -10.775555020863635],
              [33.541800256256408, -10.743608905563747],
              [33.547009106180212, -10.712917896448744],
              [33.604718174428228, -10.647781959052452],
              [33.637773215521349, -10.621108899054946],
              [33.68040910724693, -10.596255047327759],
              [33.695973295658803, -10.576664024980971],
              [33.702282186558477, -10.561855046047356],
              [33.687491144897649, -10.548608956784108],
              [33.646563317405935, -10.500745942585695],
              [33.567500177292345, -10.392499865380785],
              [33.544854118867704, -10.331872888991199],
              [33.545827090191153, -10.296390951645833],
              [33.550554148319179, -10.27110895230328],
              [33.560273300354368, -10.244864039921197],
              [33.557082309806674, -10.223755054905155],
              [33.533882207580461, -10.206108969376828],
              [33.461663226704957, -10.165281892361577],
              [33.325973289684327, -10.064026825338715],
              [33.330827082188819, -9.993608947822452],
              [33.359445079004502, -9.933055061628806],
              [33.322491279457381, -9.811108931283286],
              [33.302500272690224, -9.798890966266526],
              [33.238609215556778, -9.731390996891889],
              [33.224582268191568, -9.705282038979703],
              [33.228882184532608, -9.677635840020855],
              [33.234163286462035, -9.655555057147865],
              [33.229163313565891, -9.634163937270216],
              [33.206936182663128, -9.602499956832048],
              [33.134991290022242, -9.598055033565629],
              [33.109163293505276, -9.625000002502929],
              [33.072218211137624, -9.631673003277626],
              [33.018800174413172, -9.631326830675505],
              [33.000127138104858, -9.621846059967254],
              [32.94708226371074, -9.477364009405278],
              [32.943600253483908, -9.455835929229295],
              [32.939436123974644, -9.415835810783619],
              [32.940400210480561, -9.405081996617525],
              [32.943336055895145, -9.399863926600176],
              [32.967773327033342, -9.384163951356527],
              [33.001245116353459, -9.37667304446768],
              [33.019391266226705, -9.399754961858605],
              [33.031963282834738, -9.419046079708892],
              [33.128954140424241, -9.491872916204443],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MYS", Country: "Malaysia" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [105.820000000000277, 6.096666667000136],
              [105.785000000000196, 5.676666667000234],
              [105.480000000000189, 5.078333333000103],
              [104.86500000000018, 4.050000000000125],
              [104.775, 3.835],
              [104.846860460000357, 3.045972147000214],
              [104.858333333000246, 2.92000000000013],
              [104.877109991000225, 2.856701371000099],
              [104.9327992460004, 2.668965429000082],
              [105.020000000000209, 2.375000000000114],
              [105.086666633000164, 1.906666905000179],
              [104.883333333000166, 1.63333333300011],
              [104.523854565000249, 1.417646072000139],
              [104.491666667000345, 1.398333333000153],
              [104.457760623000354, 1.360023131000219],
              [104.427656857000159, 1.339182063000351],
              [104.404500115000303, 1.327603691000149],
              [104.40388569500027, 1.327112156000226],
              [104.381343371000298, 1.309078297000156],
              [104.346608256000195, 1.285921554000254],
              [104.316007657000227, 1.271641274000331],
              [104.311873142000138, 1.269711834000191],
              [104.310938908000139, 1.269322570000327],
              [104.284085050000272, 1.25813346200016],
              [104.251665610000373, 1.255817788000229],
              [104.219246170000247, 1.25813346200016],
              [104.184511056000247, 1.255817788000229],
              [104.152091615000245, 1.25813346200016],
              [104.117356501000188, 1.265080485000226],
              [104.084937061000119, 1.269711835000294],
              [104.06178031800016, 1.272027508000122],
              [104.058366282000293, 1.297088840000185],
              [104.060097829000142, 1.307478121000088],
              [104.056634735000102, 1.324793588999967],
              [104.051440095000203, 1.342109057000016],
              [104.049708548000183, 1.361156072000085],
              [104.0462454540002, 1.373276900000064],
              [104.039319267000224, 1.387129275],
              [104.022003799000174, 1.394055462000097],
              [103.988498887000162, 1.400268740000115],
              [103.9574369850003, 1.40687765500013],
              [103.932983998000225, 1.412825679000036],
              [103.907209229000216, 1.418773703000113],
              [103.893991398000196, 1.425382618000071],
              [103.879451785000015, 1.433313316000067],
              [103.86689484600015, 1.442565798000032],
              [103.851694340000108, 1.451818279000065],
              [103.83451116100025, 1.457766302999971],
              [103.806092825000292, 1.458427194000024],
              [103.786266079000256, 1.455122737000067],
              [103.758508635000055, 1.449174713],
              [103.725464059000103, 1.443226689000085],
              [103.701011072000256, 1.437278665],
              [103.687793242000112, 1.429347967000183],
              [103.671931845000188, 1.418112811000128],
              [103.66069668900019, 1.404234089000056],
              [103.651444208000157, 1.389694475000169],
              [103.64153083500014, 1.376476645000025],
              [103.635582811000234, 1.366563272],
              [103.630674218000252, 1.357692978999978],
              [103.618553390000102, 1.340377511000099],
              [103.606432563000055, 1.319598949000067],
              [103.596043282000153, 1.288431106000019],
              [103.596043282000153, 1.269384091000177],
              [103.599506375000033, 1.250337076000108],
              [103.602969469000243, 1.233021608000058],
              [103.612539504000097, 1.211819976000129],
              [103.594014156000128, 1.200241634500173],
              [103.593231731000287, 1.199302751000346],
              [103.582435738000243, 1.186347559000296],
              [103.568541692000167, 1.172453513000164],
              [103.55927899500017, 1.163190816000167],
              [103.529175229000145, 1.13540272400013],
              [103.51296550900031, 1.151612445000239],
              [103.492124440000282, 1.170137839000233],
              [103.489985074000401, 1.173560825000095],
              [103.480545868500144, 1.188663390500153],
              [103.448126628000352, 1.214135651000163],
              [103.429677781000237, 1.225204959000166],
              [103.424969610333562, 1.228029830333469],
              [103.386814115000163, 1.246670924000341],
              [103.379999694000105, 1.250000073000194],
              [103.065000000000111, 1.325000000000102],
              [102.583333333000212, 1.686666667000281],
              [102.223333383000181, 1.919999967500075],
              [101.775000000000318, 2.256666667000218],
              [101.388161444000218, 2.550169176000225],
              [101.201666667000381, 2.691666667000277],
              [100.972842366000179, 2.887250602000051],
              [99.916666667000129, 3.79],
              [99.823859940000204, 3.889319480000154],
              [99.726666667000245, 3.993333333000294],
              [98.691666667000163, 4.928333333000239],
              [98.520970848000275, 5.150949131000175],
              [98.291666628000314, 5.450000072500131],
              [98.024999986800026, 5.950000043200077],
              [99.111666685000273, 6.299999898000067],
              [99.321666740333455, 6.271666987000117],
              [99.458333000000266, 6.306667000000118],
              [99.511667000000159, 6.481667000000243],
              [99.556666697000082, 6.503332854],
              [99.653332427000237, 6.474999933000106],
              [99.679203427000289, 6.472081933000027],
              [99.710649427000135, 6.469222933000083],
              [99.739236427000208, 6.473510933000171],
              [99.753530427000214, 6.487804933000177],
              [99.76496542700005, 6.509245934000035],
              [99.784976427000231, 6.527826934000188],
              [99.803558427000098, 6.534973934],
              [99.82785742700014, 6.530685934000132],
              [99.853585427000098, 6.517821934000153],
              [99.886461427000313, 6.503527934000147],
              [99.917907427000159, 6.490663933000121],
              [99.957929427000067, 6.473510933000171],
              [99.980799427000193, 6.457787933000077],
              [99.99938142700023, 6.429200933000175],
              [100.015104427000153, 6.396325933000185],
              [100.047979427000087, 6.384890933000179],
              [100.073707427000045, 6.384890933000179],
              [100.09228942700031, 6.393466933000241],
              [100.112300427000264, 6.412048933000108],
              [100.127108811725805, 6.424945473060177],
              [100.141918175385285, 6.436664087258634],
              [100.168445222628918, 6.47083610077209],
              [100.192745198192767, 6.575282158342702],
              [100.184127260599894, 6.597500069151977],
              [100.174545236500876, 6.622364146801033],
              [100.194063336289901, 6.697427107431849],
              [100.203845184960869, 6.709364111053503],
              [100.21498221974133, 6.711109055661495],
              [100.26413621467043, 6.706664132395076],
              [100.300245118374846, 6.699791139410195],
              [100.330663380359624, 6.620418036516185],
              [100.335172341366501, 6.586109062704679],
              [100.374400318889343, 6.543891092672652],
              [100.379045234366174, 6.54076413986536],
              [100.387482290487981, 6.536108998466602],
              [100.436900315367666, 6.515554057252245],
              [100.656636266315559, 6.448682060065266],
              [100.681354163572905, 6.453336027997352],
              [100.71691824356958, 6.480000035539376],
              [100.75136333948933, 6.502773163616382],
              [100.828163365739158, 6.427773067259466],
              [100.854418168766983, 6.322500051119221],
              [100.847872405282828, 6.29696408010966],
              [100.84691820942254, 6.285000086759851],
              [100.853236152777868, 6.243891042520886],
              [100.95206331772016, 6.240827121625614],
              [101.009700301600674, 6.26944495080329],
              [101.112045183677424, 6.250691112948175],
              [101.114263370540073, 6.182464097290108],
              [101.099136214560986, 6.118891049564127],
              [101.106354206681431, 6.050273101940832],
              [101.119200311524082, 5.99027309191041],
              [101.095663424427471, 5.923953965058331],
              [101.069000255075821, 5.914582159091708],
              [101.042254104882545, 5.901809144444996],
              [100.991145114851292, 5.794309056624911],
              [100.99466333490011, 5.779618094888235],
              [101.057454183419509, 5.729164234411144],
              [101.142327155916121, 5.633473074731626],
              [101.164982266796414, 5.638336087329435],
              [101.189691279236058, 5.65110910197609],
              [101.22322727393481, 5.670973206729172],
              [101.25941815265287, 5.714582069778032],
              [101.311900266065891, 5.80721802574152],
              [101.452191197390448, 5.863054073900628],
              [101.506045260719247, 5.880573089776647],
              [101.571018253917487, 5.915209125451042],
              [101.606491306445434, 5.905273217399213],
              [101.659409278824967, 5.850627064218031],
              [101.658572261971585, 5.821735984996224],
              [101.692318307164328, 5.764309051609814],
              [101.699545184102476, 5.760973054136514],
              [101.746354090154512, 5.789164076803388],
              [101.771854186618214, 5.777500155587859],
              [101.800718276111866, 5.745973135448082],
              [101.826091302923203, 5.740973162551882],
              [101.94405418523047, 5.867082081302044],
              [101.943709186094907, 5.90333599193184],
              [101.940800330408592, 5.927500013025792],
              [101.943727291005871, 5.956527046717497],
              [101.952609258549558, 5.975553966979078],
              [101.983927234023668, 6.013682071164055],
              [102.01109130591044, 6.038327045863639],
              [102.090527273078465, 6.154164109881663],
              [102.095227173920136, 6.236136105840728],
              [102.093332714500207, 6.241666966500134],
              [102.166667000000189, 6.458333000000152],
              [102.16, 6.463333],
              [102.353333, 6.833333000000152],
              [102.566667000000109, 6.883333],
              [103.1, 7.050000000000239],
              [103.625385942000207, 7.320653046000189],
              [103.897807733000207, 7.134047697000028],
              [104.09123912300015, 6.995128357000056],
              [104.295646537000209, 6.854479957000024],
              [104.369012276000262, 6.803921995000167],
              [104.520179985000198, 6.699788537000018],
              [104.73167566900014, 6.567879807000111],
              [104.88296934300007, 6.473534466000046],
              [105.040402988000096, 6.375162515000113],
              [105.382154685000273, 6.256456121000099],
              [105.422285999, 6.242514713],
              [105.820000000000277, 6.096666667000136],
            ],
          ],
          [
            [
              [116.22913387500023, 7.64875937100021],
              [116.676519568000344, 7.592436663000342],
              [116.788267555000175, 7.594999672000199],
              [116.908813574000305, 7.588227807000237],
              [116.968492148000337, 7.582982161000189],
              [116.973929965000252, 7.582515718000025],
              [117.008006485000124, 7.579210764000152],
              [117.013508751000131, 7.579090126000096],
              [117.016507150000166, 7.579017804000159],
              [117.019505552000282, 7.578912705000278],
              [117.046353691000263, 7.577912017000244],
              [117.08093800900042, 7.602834833000202],
              [117.184285896000347, 7.558574900000167],
              [117.18765042400014, 7.55712034800024],
              [117.190881743000318, 7.555675766000093],
              [117.234027904000186, 7.536271513000145],
              [117.236856759000261, 7.535030734000088],
              [117.253095494000263, 7.520235676000198],
              [117.254224108000244, 7.519195715000194],
              [117.255538945000239, 7.517925680000189],
              [117.256452566000235, 7.517019246000132],
              [117.270387890000393, 7.503272016000096],
              [117.277013457000294, 7.497349856000199],
              [117.28443213200012, 7.481688077000285],
              [117.288921098000287, 7.472993900000063],
              [117.302983415000199, 7.444544773000132],
              [117.304003681000268, 7.444873599000175],
              [117.314648477000219, 7.447699079000188],
              [117.330618577000223, 7.452106331000323],
              [117.337495160000344, 7.453930330000162],
              [117.366496742000436, 7.462445754000214],
              [117.406362888000444, 7.474477840000134],
              [117.416613196000185, 7.47738129700025],
              [117.444236363000186, 7.472430393000081],
              [117.843878346000452, 7.396811920000175],
              [117.933452535000242, 7.379846677000216],
              [117.92984609500013, 7.21340719900013],
              [117.92643176900026, 7.072305689000132],
              [117.933736260000387, 7.021406342000148],
              [117.937814612500119, 6.99260203300031],
              [117.937691366000422, 6.985219930000312],
              [117.937148534000244, 6.952708113000313],
              [117.974971167000263, 6.875599069000202],
              [118.014101851000305, 6.795381225000142],
              [118.028750359000355, 6.765498313000137],
              [118.070753130000298, 6.678776698000092],
              [118.007501168000317, 6.603000408000355],
              [117.961675929000307, 6.548270718000083],
              [117.937608613000407, 6.519316354000352],
              [117.925974758000166, 6.492152986000292],
              [117.894998602000214, 6.420360842000321],
              [117.880203832000234, 6.383648347000189],
              [117.876720561000184, 6.375168140000085],
              [117.872860291000279, 6.364517764000141],
              [117.869745339000247, 6.355134542000201],
              [117.867722771000444, 6.348782149000158],
              [117.867430568000145, 6.343127127000344],
              [117.8673070990003, 6.339070994000167],
              [117.867500285000119, 6.307462748000034],
              [117.867500291000283, 6.304957122000246],
              [117.867525943000146, 6.302877158000058],
              [117.867570273000183, 6.299982276000208],
              [117.868578845000343, 6.270001822000097],
              [117.87175961600019, 6.252880436000112],
              [117.87953362300027, 6.215314620000186],
              [117.880586698000343, 6.211384448000217],
              [117.929796821000309, 6.211650580000025],
              [117.945509745000209, 6.212509677000185],
              [117.955222050000316, 6.213208708000195],
              [117.958779224000295, 6.21456103800017],
              [117.998458504000155, 6.210230032000084],
              [118.031578468000134, 6.207117009000171],
              [118.045292780000295, 6.205530644000305],
              [118.057498387000237, 6.204344334000211],
              [118.062500547000241, 6.203955303000271],
              [118.068795742000361, 6.203342343000145],
              [118.08291486200028, 6.202250540000136],
              [118.08534910300034, 6.186655050000127],
              [118.086561104000339, 6.186234958000171],
              [118.103584505000299, 6.180025532000116],
              [118.10800148300018, 6.17787289700027],
              [118.112336849000258, 6.176363643000286],
              [118.114875557000346, 6.175675964000334],
              [118.123129547000303, 6.172315353000158],
              [118.129668777000234, 6.168329026000094],
              [118.152036748000228, 6.155958151000164],
              [118.162033927000266, 6.149783126000216],
              [118.178246848000185, 6.132127915000183],
              [118.201216009000404, 6.106706928000165],
              [118.186930202000212, 6.075808160000122],
              [118.186039231000336, 6.073948092000023],
              [118.178849256000262, 6.059012501000154],
              [118.176243788000306, 6.053469439000196],
              [118.177655812000125, 6.043599247000316],
              [118.178655583000136, 6.042269353000222],
              [118.20095053200032, 6.013402999000107],
              [118.223141688000169, 5.985971735000078],
              [118.240446114000292, 5.971254497000245],
              [118.241266011000334, 5.970864434000021],
              [118.246564719000162, 5.968665495000096],
              [118.266026198000191, 5.960877326000229],
              [118.284977193000373, 5.955760067000256],
              [118.299242020000179, 5.952625749000049],
              [118.308003356000313, 5.950977132000162],
              [118.314854693000257, 5.949630582000339],
              [118.329306469000358, 5.951855587000125],
              [118.3484954930002, 5.954334077000226],
              [118.366755176000311, 5.956913058000339],
              [118.401438468000265, 5.961514206000174],
              [118.408161079000251, 5.959144540000239],
              [118.423057190000236, 5.954020632000095],
              [118.436208630000237, 5.949669350000136],
              [118.447003120000204, 5.946288034000133],
              [118.461069464000161, 5.941676739000286],
              [118.463561104000291, 5.940916774000186],
              [118.479510062000259, 5.937497602000235],
              [118.484800567000207, 5.936424828000327],
              [118.503534299000194, 5.935601129000077],
              [118.526804270000184, 5.934873049000259],
              [118.590091815000278, 5.936205683000196],
              [118.623434521500371, 5.938467404000221],
              [118.637780491000399, 5.941108794000058],
              [118.644679742000221, 5.942438168000137],
              [118.663224222000167, 5.945949393000092],
              [118.692266229000325, 5.951717847000111],
              [118.735151275000248, 5.962136206000139],
              [118.768883207000385, 5.972794532000023],
              [118.806401279000283, 5.980997725000293],
              [118.859626341000194, 5.998409987000173],
              [118.989542963000304, 6.042309198000225],
              [119.078341544000409, 6.072539653000149],
              [119.08291273000043, 6.050249681000082],
              [119.103239146000192, 6.0227802550001],
              [119.141717414000141, 5.972566737000136],
              [119.252226422000263, 5.830315367000139],
              [119.280223996000245, 5.79456244000022],
              [119.317279873000189, 5.747523331000252],
              [119.335429065000312, 5.724725346000355],
              [119.345943498000338, 5.713758804000122],
              [119.361600870000302, 5.697820417000059],
              [119.399676224000132, 5.649427196000204],
              [119.414796985000407, 5.630052289000162],
              [119.446132967000381, 5.591814365000232],
              [119.4486707570004, 5.588931790000174],
              [119.461522775000276, 5.574929293000082],
              [119.488955472000157, 5.546287345000223],
              [119.502154535000159, 5.53275215300016],
              [119.502911715000323, 5.531980649000275],
              [119.513341524000452, 5.521421098000246],
              [119.530564556500167, 5.504096854000181],
              [119.533376241000155, 5.501247036000279],
              [119.559159891000263, 5.476072087000148],
              [119.606910618000143, 5.428956284500146],
              [119.604006668000238, 5.42125749700017],
              [119.599067316000287, 5.406020355000294],
              [119.57912309000028, 5.351586893000047],
              [119.566959981000309, 5.31662767000023],
              [119.565645837000261, 5.312883377000162],
              [119.564381707000166, 5.309163881000245],
              [119.563614861000133, 5.307260902000053],
              [119.560146602000145, 5.298894222000229],
              [119.558425416000318, 5.294801007000217],
              [119.54635169500034, 5.253371467000193],
              [119.542817850000233, 5.242415457000334],
              [119.541916880000258, 5.239530979000222],
              [119.541258514000333, 5.237411242000235],
              [119.54068942500021, 5.235536561000288],
              [119.529045433000306, 5.197081808000064],
              [119.526788068000315, 5.191069271000231],
              [119.525414862000389, 5.186834122000221],
              [119.515976336000335, 5.158835003000206],
              [119.45573191300025, 5.121425457000214],
              [119.454541436000227, 5.120706014000291],
              [119.410161137000159, 5.09354979200026],
              [119.403094047000195, 5.088918472000046],
              [119.399120379000237, 5.087105319000045],
              [119.39749634000043, 5.086230451000347],
              [119.394972842000357, 5.084880215000169],
              [119.377903437000327, 5.07551925200022],
              [119.347479997000164, 5.057206718000089],
              [119.339706352000235, 5.051963812000224],
              [119.337027604000127, 5.050119781000149],
              [119.330860048000204, 5.045649954000282],
              [119.316472911000233, 5.035009814000261],
              [119.31306312400045, 5.032570528000235],
              [119.300443400000177, 5.023692901000288],
              [119.280573335000327, 5.006034124000166],
              [119.274191820000283, 5.000118541000177],
              [119.272701043000325, 4.998615910000296],
              [119.260044834000155, 4.985637616000247],
              [119.257808901000203, 4.983252385000185],
              [119.256622749000144, 4.98201885400016],
              [119.245476266000423, 4.969961320000323],
              [119.227292020000277, 4.949699771000098],
              [119.225268020000271, 4.947386659000244],
              [119.223138894000158, 4.944919846000118],
              [119.201393133000238, 4.919397395000203],
              [119.200164248000306, 4.917790062000165],
              [119.192310022000242, 4.915596120000203],
              [119.182819468000275, 4.912358314000357],
              [119.177441129000158, 4.911883982000177],
              [119.147503499000436, 4.907491775000324],
              [119.144240783000328, 4.907022219000169],
              [119.123873282000432, 4.903910731000281],
              [119.121388751000268, 4.903443424000102],
              [119.108397873000285, 4.899526363000291],
              [119.072524373000192, 4.888470507000136],
              [119.045577022000316, 4.889320267000301],
              [119.042777552000189, 4.889191157000084],
              [119.031498137000312, 4.888506604000213],
              [119.025895042000229, 4.888182678000248],
              [119.013565375000326, 4.886406231500132],
              [119.002236944000288, 4.883898377000207],
              [119.000106797000171, 4.883406220000268],
              [118.993916980000222, 4.881916319000197],
              [118.987509796000154, 4.879769776000273],
              [118.967622037000183, 4.868205803000137],
              [118.95149381900012, 4.858897523000223],
              [118.927044668000235, 4.844311452000284],
              [118.876092218000394, 4.813451429000168],
              [118.869710152000266, 4.806786017000206],
              [118.890391971000213, 4.743558641000163],
              [118.910977942000272, 4.714959734000217],
              [118.920200304000218, 4.701948527000241],
              [118.96077617400033, 4.662675891000106],
              [118.987285246000283, 4.628214097000125],
              [118.995237967000207, 4.612308654000117],
              [119.003190689000235, 4.599054118000197],
              [119.01379431800035, 4.583148675000189],
              [119.024397946000363, 4.563266871000224],
              [119.033676122000315, 4.534106892000239],
              [119.048256111000228, 4.479763295000055],
              [119.054883379000216, 4.43734878000032],
              [119.061510647000205, 4.393608812000025],
              [119.066812461000268, 4.363123379000172],
              [119.069463368000356, 4.343241575000263],
              [119.07211427600032, 4.329987039000287],
              [119.075254351000126, 4.314518392000195],
              [119.202150171000198, 3.851074050000307],
              [119.289776432000167, 3.546517330000199],
              [119.46749077100003, 3.182881756000256],
              [119.004645705000144, 3.20003917400021],
              [118.748495742000273, 3.209302006000144],
              [118.627504208000346, 3.351708661000259],
              [118.301917694000394, 3.73411764400015],
              [118.293973305000236, 3.842413312000133],
              [118.289484199000412, 3.902079957000183],
              [118.232573079000304, 3.975065210000196],
              [118.228809549000175, 3.97986010000028],
              [118.141963607000207, 4.047374266000134],
              [118.12331484400022, 4.061510147000092],
              [118.122628960000156, 4.062021598000229],
              [118.106467867000333, 4.072014847000275],
              [118.08816358800027, 4.083181168000181],
              [118.083510674000195, 4.085693910000089],
              [118.061751160000313, 4.096484576000307],
              [118.055033538000316, 4.099751317000141],
              [118.052926485000171, 4.100755326000353],
              [118.001508006000222, 4.143250526000315],
              [117.988472936000335, 4.153625248000196],
              [117.981279339000253, 4.158722009000087],
              [117.980569382000226, 4.159157048000282],
              [117.951455534000274, 4.175258513000188],
              [117.943005025000161, 4.179733861000102],
              [117.910965522000083, 4.175734091000095],
              [117.903554440299445, 4.174045625052429],
              [117.879436186122007, 4.17305401489682],
              [117.83970931772069, 4.171945089103531],
              [117.726645154918856, 4.169718017423747],
              [117.686918436752762, 4.168336282546647],
              [117.679800443000318, 4.168384931000276],
              [117.597984443000314, 4.16966393100023],
              [117.592054253738866, 4.169818451330798],
              [117.52887230492297, 4.175273207587793],
              [117.497754154021209, 4.178891172284523],
              [117.475536243212105, 4.18360901031906],
              [117.452763282772935, 4.18860915085321],
              [117.439418287052035, 4.195836027791358],
              [117.424427253180852, 4.21471810876551],
              [117.420254238854227, 4.224718054557798],
              [117.410809342691664, 4.242500094556135],
              [117.401791253040074, 4.254582105102998],
              [117.251791227964304, 4.353891061841409],
              [117.239436135011147, 4.358335985107658],
              [117.22360020529743, 4.35874502198368],
              [117.211109325512211, 4.35444493800469],
              [117.202772349314614, 4.345973180803469],
              [117.191791217933883, 4.336244976312798],
              [117.176654171308996, 4.335553972213177],
              [117.0438724194222, 4.33694503286813],
              [116.936918328776528, 4.35082697330796],
              [116.924427281353246, 4.352218033962686],
              [116.910536288457934, 4.352218033962686],
              [116.889163441129199, 4.349309010638592],
              [116.876918318746107, 4.345273124248365],
              [116.839845160897426, 4.330418044847136],
              [116.688763367394813, 4.330753991527388],
              [116.641936188793579, 4.334718128826125],
              [116.5336002575865, 4.330835966540462],
              [116.527618260911595, 4.319309005623325],
              [116.465818320988205, 4.294027006280714],
              [116.454163284590209, 4.29458205591061],
              [116.441654132256303, 4.299445068508589],
              [116.425809317725111, 4.310000063930772],
              [116.314700317663778, 4.357773056488952],
              [116.153318170957959, 4.355000155272535],
              [116.140263356725654, 4.337218115274197],
              [116.074154112729957, 4.283609138794873],
              [116.061100304325919, 4.27791799416093],
              [116.047618180668792, 4.281109152346801],
              [116.030882369827623, 4.300764212434046],
              [116.004154156907134, 4.330000123153582],
              [115.986645199315149, 4.339718101722497],
              [115.976091209721176, 4.343053931557392],
              [115.888036131493891, 4.368127053872342],
              [115.872482336642179, 4.361109053961854],
              [115.85754511458947, 4.344100160714788],
              [115.850536167134464, 4.321664152784308],
              [115.770263350751293, 4.244718113780607],
              [115.76138238903593, 4.239717973246286],
              [115.690809278672532, 4.180273180483937],
              [115.683309319328146, 4.167364211367328],
              [115.659709232681621, 4.108600029144668],
              [115.661100293336517, 4.097782177238116],
              [115.6624913539913, 4.078335994178758],
              [115.616382336856191, 3.854164172419928],
              [115.585272400219651, 3.741664111703528],
              [115.576927209756718, 3.708609070610407],
              [115.571382245514428, 3.666109133354894],
              [115.571382245514428, 3.612782124099226],
              [115.574154140902351, 3.594718116877232],
              [115.578872314213072, 3.585554014471768],
              [115.601927241875842, 3.53916420357973],
              [115.626082378152205, 3.457782126127086],
              [115.627682315834704, 3.434309109132982],
              [115.613872292124256, 3.42041811623767],
              [115.596936153796833, 3.424718032578483],
              [115.582972405981877, 3.427982113322344],
              [115.570827195884704, 3.417782175319644],
              [115.558872422628383, 3.38916417850379],
              [115.553309185837179, 3.373891177409234],
              [115.538882254051259, 3.333609091740016],
              [115.534145137639598, 3.318336090645062],
              [115.529982349234729, 3.301664149906514],
              [115.511936279285806, 3.210000159437925],
              [115.509991342467288, 3.198336070584389],
              [115.513045205078924, 3.184026988357573],
              [115.528591288579833, 3.176109107319803],
              [115.498872412597848, 3.050282155793298],
              [115.495527195031173, 3.040000075139176],
              [115.487200277117495, 3.027500142898816],
              [115.378036213874339, 2.991808993249549],
              [115.31831817106729, 2.987782159314804],
              [115.310254109637867, 2.997782105107092],
              [115.305545156420862, 3.006664072650949],
              [115.301372309732187, 3.016391103675176],
              [115.24970927589959, 3.01083608114908],
              [115.152072341211579, 2.922082119833078],
              [115.139709201631149, 2.906109062183191],
              [115.118318249391677, 2.851109025049141],
              [115.082363405067781, 2.613609139013704],
              [115.092063278725846, 2.600553989505443],
              [115.107618247044229, 2.597217992032313],
              [115.123172377172295, 2.605553962401586],
              [115.133600302941886, 2.611527074259186],
              [115.169436291878242, 2.60513604070826],
              [115.185254116681108, 2.596945077264195],
              [115.19914527721437, 2.583053916730705],
              [115.236091365410516, 2.529653984917218],
              [115.230818310108248, 2.508053988012023],
              [115.215682269311515, 2.49278216038357],
              [115.203872335342453, 2.487218085401992],
              [115.187482361827279, 2.483053955892842],
              [115.090818230824425, 2.422500069699197],
              [115.001663279259446, 2.360553949384155],
              [114.949145123662447, 2.293191107945859],
              [114.939418260276398, 2.285554020665245],
              [114.92970028170771, 2.282218023192115],
              [114.904291212712565, 2.276664174132577],
              [114.87302721669505, 2.279444954337748],
              [114.813727263219789, 2.26069111648269],
              [114.804700288750723, 2.248891073159257],
              [114.791509184772508, 2.216809003389528],
              [114.778591330838594, 2.165554000604573],
              [114.776382364069491, 2.15013599258441],
              [114.800536159241801, 2.064718028741538],
              [114.839154101849772, 2.043891178587216],
              [114.848872415694473, 2.047218123604978],
              [114.862618234026513, 2.046454029309245],
              [114.872482225349017, 2.020282039485153],
              [114.872482225349017, 2.007782107244623],
              [114.861927229926778, 1.915282105750975],
              [114.856509335337023, 1.900691056300559],
              [114.844709292013818, 1.891664081831493],
              [114.783336158601287, 1.863891148496123],
              [114.710818279057662, 1.77055396251103],
              [114.674427240491468, 1.642500106942691],
              [114.588872316350376, 1.452082041727806],
              [114.576518229225485, 1.440554074982174],
              [114.559982242956721, 1.432917993530054],
              [114.54359126361328, 1.4322181046129],
              [114.533336172687655, 1.435553934448023],
              [114.511382291829136, 1.44805403432639],
              [114.492336260828552, 1.463336087876655],
              [114.475682257362564, 1.477500163178263],
              [114.452472264491007, 1.491945032237027],
              [114.443591302775644, 1.496664211376071],
              [114.424427254577836, 1.504718046883738],
              [114.41330933053689, 1.507782135417017],
              [114.401654126500716, 1.509718019780053],
              [114.389427276666424, 1.508336011580411],
              [114.290818208845508, 1.470000036196041],
              [114.231372410254494, 1.443609111060141],
              [114.084400263890188, 1.469991151378565],
              [114.033600230811089, 1.458891164610407],
              [114.004991286450718, 1.453609056852486],
              [113.987200193997069, 1.450835987998289],
              [113.968600247884297, 1.448609083956285],
              [113.930818316301384, 1.445282138938694],
              [113.921791341832204, 1.440836042205774],
              [113.888036244184036, 1.405973192230874],
              [113.876082309118033, 1.390554010744268],
              [113.865254231289555, 1.374164204867327],
              [113.842482276678879, 1.348609123118479],
              [113.79040920014188, 1.304718125208012],
              [113.659082379012261, 1.22583603420361],
              [113.642491239820515, 1.229444946444687],
              [113.629963312023278, 1.237782090280575],
              [113.616382281908386, 1.251391115952288],
              [113.586927268239151, 1.283609140192084],
              [113.456100343816502, 1.288336030681933],
              [113.374418194229406, 1.343891117445935],
              [113.359427160358109, 1.356245036932592],
              [113.340818161790253, 1.365282069685634],
              [113.126372365227439, 1.431664228449677],
              [113.057482341026343, 1.539791114990919],
              [113.03859120759671, 1.556945015163592],
              [112.999709235037926, 1.572782118343753],
              [112.976927222143445, 1.577500124016296],
              [112.884145253426027, 1.588053945972035],
              [112.680263337102815, 1.563053913852912],
              [112.499718315665859, 1.570554040835304],
              [112.472763288444753, 1.568054054387233],
              [112.309709287326996, 1.499444991581242],
              [112.201372350291194, 1.450136099081135],
              [112.184709294370123, 1.425282079715885],
              [112.181509251366947, 1.411391086820572],
              [112.191782279565359, 1.402782034045003],
              [112.209836228503491, 1.403191070921196],
              [112.214709299385277, 1.394164096451959],
              [112.212900317036997, 1.376109141685504],
              [112.132200191228662, 1.149791049793862],
              [112.11080923898885, 1.140554024830635],
              [112.074436137695557, 1.135554051934491],
              [112.055254152224961, 1.134164164746039],
              [112.04165417900856, 1.134164164746039],
              [111.98331831203933, 1.137782129442542],
              [111.965554209313837, 1.135000008132863],
              [111.954709200041123, 1.132218054461191],
              [111.945682225572057, 1.12736409431875],
              [111.891236232239237, 1.07569116984024],
              [111.879963410627056, 1.055553982681261],
              [111.873872281572403, 1.041109113622497],
              [111.860118248975482, 1.020418050300066],
              [111.845836156476764, 1.007500028727975],
              [111.827209220636064, 0.998609008728806],
              [111.809982230267622, 0.995282063711215],
              [111.797763427060374, 0.993891170694269],
              [111.73275422404032, 1.006109135711029],
              [111.724427306126358, 1.011664158237124],
              [111.700272337487945, 1.024718134278942],
              [111.681927201232838, 1.033608986640104],
              [111.669572275917687, 1.038473172704528],
              [111.655254141235332, 1.036944984113404],
              [111.63527235456138, 1.029445024768961],
              [111.565127224175399, 1.000282036607359],
              [111.419436167895981, 1.008054072529433],
              [111.406372301208194, 1.008891089382928],
              [111.394709218183152, 1.011664158237124],
              [111.311100236688674, 1.044444943457449],
              [111.258327271234407, 1.067218071534796],
              [111.211927234420386, 1.069718057982698],
              [111.195245235398033, 1.062364111392185],
              [111.02414511012347, 1.024718134278942],
              [111.006945109483354, 1.021109054399687],
              [110.99470920719358, 1.020000128606398],
              [110.941091345897121, 1.018609067951616],
              [110.906936263828015, 1.015135942542145],
              [110.885536259132948, 1.005553918443354],
              [110.819563304882934, 0.950000005145853],
              [110.813027264406799, 0.934164075431966],
              [110.812609175075124, 0.917500013682456],
              [110.807900221858063, 0.908473206851397],
              [110.701927149162486, 0.878053939038296],
              [110.598600243307033, 0.856109110635259],
              [110.57443622221308, 0.852782165617498],
              [110.555254236742485, 0.853891091410844],
              [110.544436217197813, 0.856664160264984],
              [110.522063408817473, 0.865282097858085],
              [110.459991224678333, 0.902782062217398],
              [110.332491245273445, 1.001945006201936],
              [110.198863424077729, 1.176109052371828],
              [110.184554174212906, 1.185691076470619],
              [110.169436238327194, 1.187782193680675],
              [110.15581816020017, 1.187782193680675],
              [110.137209329270263, 1.189718078043541],
              [110.11553624216873, 1.195282153024891],
              [110.101091205472017, 1.201391051714211],
              [110.083882320014595, 1.211664079912964],
              [110.071663349169285, 1.220000050282238],
              [109.996045171270396, 1.288644987633859],
              [109.985409206663235, 1.301882192079688],
              [109.97970917721193, 1.317218057448429],
              [109.931663437523866, 1.406944990087823],
              [109.824709179240017, 1.468609143179094],
              [109.739963276395599, 1.539164148631755],
              [109.721918212274943, 1.555553954508696],
              [109.71164518407636, 1.565836035162761],
              [109.698863284612173, 1.580282077687798],
              [109.668727157489002, 1.617009062934301],
              [109.667209194819804, 1.706391164075967],
              [109.61580918510947, 1.789164134545558],
              [109.603591220092881, 1.791109071363735],
              [109.593872403333648, 1.79528208569036],
              [109.583872289903184, 1.802500077810976],
              [109.563872398318381, 1.833609008619248],
              [109.547336244411724, 1.906944961915258],
              [109.630809271436306, 2.031664161114918],
              [109.648563264351196, 2.073409089412166],
              [109.62677009459793, 2.048001094780716],
              [109.646666551500232, 2.083333131000131],
              [109.908333333000201, 3.000000000000284],
              [110.033333333000257, 4.666666667000072],
              [109.983333333000189, 5.52],
              [109.810234727000193, 5.918805613000245],
              [109.643333436000205, 6.303332933000206],
              [109.424285533000159, 6.86899674900036],
              [109.315949775000092, 7.362523756000144],
              [109.777529870000194, 6.902325130000236],
              [110.130265411000209, 6.548643367000125],
              [110.739910980000104, 5.935945308000043],
              [111.000039051000044, 5.673458560000086],
              [111.215098129000182, 5.740075318000152],
              [111.824140570000282, 6.04671135700022],
              [111.918753352000181, 6.094584536000241],
              [112.035649273000331, 6.153496011000186],
              [112.476444746000197, 6.229557920000047],
              [112.497029644000236, 6.233079192000105],
              [114.071036337000123, 4.703267694000033],
              [114.066672405746772, 4.595554717889343],
              [114.081198376731209, 4.592988573801733],
              [114.09508203264329, 4.590536306500155],
              [114.153318283647877, 4.580000109067214],
              [114.164991257318775, 4.577773205025437],
              [114.179982291189958, 4.572500149722998],
              [114.188309209103863, 4.566944959558953],
              [114.235809286893868, 4.531944981647484],
              [114.24609136754782, 4.521664074459864],
              [114.263891344818944, 4.497500053366082],
              [114.30900928571964, 4.433191074539138],
              [114.32916340432314, 4.340000068945926],
              [114.32999136872138, 4.326945087075615],
              [114.399991324544089, 4.256391087451277],
              [114.475536244608861, 4.200000157300451],
              [114.608454118603504, 4.055691109220447],
              [114.609427257564903, 4.03083608402676],
              [114.619491241097762, 4.021664102632428],
              [114.638036201925047, 4.018191144860964],
              [114.649991310457722, 4.022217978795879],
              [114.669982317225049, 4.029718105778102],
              [114.705409269285497, 4.050000132224682],
              [114.793054137169975, 4.126944997761768],
              [114.806363425983477, 4.144582198472676],
              [114.866927202822922, 4.355273070040653],
              [114.859009321785152, 4.432636024909414],
              [114.843663398132435, 4.43562702324698],
              [114.834718231038778, 4.446391063334829],
              [114.829163376150746, 4.473891081901911],
              [114.825818326222134, 4.495836077942897],
              [114.815536245568182, 4.580835952454265],
              [114.78846336878803, 4.697364188210088],
              [114.779982223855313, 4.70916423153335],
              [114.77777241889612, 4.72083603173769],
              [114.779854148374511, 4.735835950426178],
              [114.787072308133247, 4.749582104034289],
              [114.852618288234225, 4.803609002207111],
              [114.866654120416797, 4.809718068534607],
              [114.879154220295163, 4.810273118164332],
              [114.904427167182462, 4.811109129189333],
              [114.917482316690894, 4.811664178819228],
              [114.945827231100111, 4.817218027878823],
              [114.962200273170879, 4.821945086006849],
              [114.979427263539492, 4.831664070404088],
              [114.989145242108066, 4.842918116553108],
              [115.018436176831756, 4.89579100018085],
              [115.019406177041219, 4.891576871259815],
              [115.02290039037814, 4.876391005669916],
              [115.023782646240647, 4.868430509026723],
              [115.027757175914758, 4.832570505990759],
              [115.029127317428447, 4.820209328144301],
              [115.020827159819191, 4.753336023200916],
              [115.020536140140052, 4.724444943979108],
              [115.026654258923031, 4.656664180847145],
              [115.029427160139221, 4.645836103018837],
              [115.090272401288189, 4.413336022241765],
              [115.096363362704665, 4.391391026200608],
              [115.102345191741733, 4.380554063554939],
              [115.113600243718906, 4.374164203470343],
              [115.320545242747244, 4.315553912990197],
              [115.339854130232311, 4.31166420699185],
              [115.352072262887305, 4.317500023275045],
              [115.360263393969262, 4.332636064071608],
              [115.356654146451888, 4.349309010638422],
              [115.350809277713211, 4.360000128168565],
              [115.344709263841196, 4.36832704608247],
              [115.317900249373793, 4.396391166734929],
              [115.302754150293481, 4.410282159630242],
              [115.286372391043528, 4.426945047913478],
              [115.278054190308922, 4.438891103990613],
              [115.273182292893694, 4.45139103623103],
              [115.264427227364422, 4.541109084052948],
              [115.268600241691047, 4.564444973110653],
              [115.272772417827127, 4.573891042739717],
              [115.278318220259735, 4.582773177921581],
              [115.282491234586359, 4.59860910763507],
              [115.282491234586359, 4.611664089505382],
              [115.261382249570374, 4.683053932516543],
              [115.223036215902084, 4.805000062862064],
              [115.192200199861873, 4.849164143178768],
              [115.145781964183357, 4.903236289568383],
              [115.13359069587159, 4.857636912504574],
              [115.115373741000212, 4.890149076000228],
              [115.126531022000336, 4.916926551000245],
              [115.148845584000242, 4.945935483000028],
              [115.157771409000247, 4.961555676999978],
              [115.146614128000238, 4.966018589000157],
              [115.137688303000346, 4.97940732699999],
              [115.14438267200012, 4.992796064000174],
              [115.258186941000304, 5.088748683000119],
              [115.119836653000334, 5.119989071000191],
              [115.00380092800026, 5.204784408000023],
              [114.845367534000047, 5.265033727000173],
              [114.76280365200023, 5.349829065000108],
              [113.817794942000319, 6.326482285000168],
              [113.920864526000173, 6.376434684000117],
              [113.942406720000236, 6.386883563000026],
              [113.950763192000295, 6.390923941000153],
              [113.986244883000154, 6.408249474000058],
              [114.422341872000288, 6.804082973000163],
              [114.627878438000153, 6.990169315000117],
              [114.861904420000201, 7.202059965999979],
              [114.994202119000192, 7.223256339000102],
              [115.121338691000119, 7.243369267000162],
              [115.14295088200015, 7.246806254000035],
              [115.15618008700028, 7.2489225650001],
              [115.189139156000124, 7.251657968000188],
              [115.371114114000193, 7.266393082000093],
              [115.49632751300021, 7.333529721000048],
              [115.984346228000163, 7.622426221000126],
              [116.018445001000146, 7.644721573000083],
              [116.041076143400232, 7.662437746000137],
              [116.162668189000215, 7.653489755000294],
              [116.22913387500023, 7.64875937100021],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "MYT", Country: "Mayotte" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.692948543000142, -12.797081659999961],
            [46.586174543000055, -13.118860659999854],
            [46.536994543000077, -13.268637659999882],
            [46.332014543000156, -13.800468660999869],
            [45.805610543000142, -14.236835660999958],
            [45.57718554200008, -14.33751366099996],
            [45.096279542000133, -14.449377662000018],
            [44.628039542000039, -14.485496661999974],
            [44.136997541000056, -14.458816661999933],
            [43.680286541000129, -14.375924660999928],
            [43.732922541000079, -14.25684666099994],
            [43.751453541000075, -14.213546661],
            [44.038236541000202, -13.540871660999983],
            [44.197166541000144, -13.224883659999875],
            [44.325558541000106, -13.105857660000012],
            [44.416578541000177, -13.021748659999858],
            [44.514153541000184, -12.922324659999873],
            [44.521983541000139, -12.91441366],
            [44.604555542000213, -12.801336659999947],
            [44.613321542000136, -12.789445659999913],
            [44.664722542000135, -12.697620659999842],
            [44.695137542000026, -12.644408659999854],
            [44.733484042000129, -12.577077659999873],
            [44.772196542000216, -12.50825466],
            [44.804683542000106, -12.452102659999923],
            [44.809701542, -12.444341660000021],
            [44.821752542000041, -12.425645659999958],
            [44.89398854200013, -12.309147659999908],
            [45.023712542000084, -12.150028658999815],
            [45.089090542000037, -12.06960965899988],
            [45.199972542000211, -11.949719658999939],
            [45.203120542000164, -11.945901658999873],
            [45.488097542000077, -11.644830658999979],
            [45.561614542000115, -11.567147658999843],
            [45.780151543000073, -11.336829658999918],
            [45.917250543000051, -11.562453658999956],
            [45.987157543000109, -11.677390658999983],
            [46.072267543000208, -11.81491365899987],
            [46.207624543000207, -12.03314765899988],
            [46.394625543000217, -12.330191659999912],
            [46.45772154300019, -12.42919666],
            [46.501612543000164, -12.49812466],
            [46.681304543000095, -12.778960659999953],
            [46.692948543000142, -12.797081659999961],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "NAM", Country: "Namibia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [23.476109162127983, -17.625835898043988],
            [23.820827168759763, -17.560282038954085],
            [24.029027106335093, -17.519582031591341],
            [24.239091179181628, -17.478426886884975],
            [24.381245072311202, -17.473473014456346],
            [24.440136156548306, -17.482364034455514],
            [24.502500198556788, -17.508055070674189],
            [24.5422181821408, -17.524999926181025],
            [24.565418116728807, -17.533473024486668],
            [24.59027330956053, -17.533890946180193],
            [24.619163215316036, -17.505835877983344],
            [24.638191141405713, -17.496672949044367],
            [24.663891230079997, -17.493617912966471],
            [24.708609186560068, -17.498335918639015],
            [24.817773249803366, -17.515555030018518],
            [24.912500155338847, -17.540835855894613],
            [24.970000178920998, -17.559717936868779],
            [24.984300208692531, -17.583891010418199],
            [25.030000188951419, -17.609999968330399],
            [25.065282301724494, -17.624726804612678],
            [25.251945106668273, -17.783055088706945],
            [25.264427101635846, -17.802245958994959],
            [25.252382139101002, -17.795708912690159],
            [25.179373247667911, -17.782081949745532],
            [25.136391183340351, -17.796564034454491],
            [25.099091211191165, -17.827845967744878],
            [25.066391227517727, -17.832226853270782],
            [25.000000183936208, -17.826046037852009],
            [24.972773247775365, -17.821108929229823],
            [24.936109126802876, -17.813890937109107],
            [24.832773168491826, -17.837708953238973],
            [24.679163225346258, -17.944173037823859],
            [24.566318165494181, -18.05423580872808],
            [24.524163227374345, -18.059999875919885],
            [24.497909094898745, -18.059308871820178],
            [24.47360911933481, -18.032781992214453],
            [24.451945084688958, -17.999172907319945],
            [24.439791157412628, -17.978055037486584],
            [24.416945106777689, -17.953755061922749],
            [24.388336162417318, -17.945835839780429],
            [24.36305416307485, -17.949163958264606],
            [24.268891191986484, -18.01333580915508],
            [24.006663350410093, -18.167781930963756],
            [23.967218113956307, -18.184999868876787],
            [23.912218076822143, -18.236946043399456],
            [23.886936077479476, -18.264764071373918],
            [23.870418196121761, -18.263055001311656],
            [23.84402710334777, -18.289999970248871],
            [23.829854143228829, -18.315000002367924],
            [23.791663342407844, -18.354172994605833],
            [23.719163232498857, -18.425282043859781],
            [23.650554169692981, -18.469717865478245],
            [23.613254029905818, -18.485164036693121],
            [23.582427066320975, -18.475208850263868],
            [23.563891157949058, -18.437226926470458],
            [23.555063337499917, -18.361664069132743],
            [23.554163288734543, -18.326117926408884],
            [23.519718192814906, -18.266946048414667],
            [23.503054131065198, -18.248335876380224],
            [23.453473161987205, -18.203608867444657],
            [23.431109238424312, -18.193890888875956],
            [23.398054029693128, -18.176108848877618],
            [23.36305405178166, -18.127226930526334],
            [23.33000018415504, -18.076391022901376],
            [23.325836054645833, -18.044999957231326],
            [23.316391158483327, -18.015417873909641],
            [23.297109093088579, -17.995946042226507],
            [23.287109147296263, -17.99633580072522],
            [23.077773294199545, -18.004445962622384],
            [22.637218115134488, -18.086672935917392],
            [22.500000157160713, -18.111263931160252],
            [22.262218136263868, -18.153890938068344],
            [21.816945119164245, -18.238335930587908],
            [21.462500249146359, -18.304446012774108],
            [21.235554017428683, -18.311945972118338],
            [20.99329119871976, -18.318417974854299],
            [20.993609208127197, -18.338608973831953],
            [20.994163251928626, -18.475564075321415],
            [20.994163251928626, -18.695281921358301],
            [20.99352706547586, -19.171946004127889],
            [20.993073269236589, -19.998217928217528],
            [20.993327073265505, -20.179172992359014],
            [20.99280923928589, -20.824272928652306],
            [20.993027168769061, -20.992454978147109],
            [20.992500114695872, -21.018890997922142],
            [20.992500114695872, -21.322226877908975],
            [20.992773197102167, -21.597226895941731],
            [20.992773197102167, -21.941946076039997],
            [20.992773197102167, -21.969446094607036],
            [20.991945065066062, -21.996946113174161],
            [20.917773268383286, -21.998335832724536],
            [20.64971816006485, -21.99999997578557],
            [20.30832709845086, -22.001390868802432],
            [20.000473205001754, -22.001564038922538],
            [19.99666329472177, -22.004999948681828],
            [19.996109250920284, -22.087500004383031],
            [19.997218176713517, -22.266945990672596],
            [19.997591171405787, -22.499999947613077],
            [19.997500143937117, -22.722499914959741],
            [19.997773226343327, -22.943335906883362],
            [19.998282175505608, -22.959917825981705],
            [19.998609237368441, -22.970835925450487],
            [19.998891204591956, -23.122781893173013],
            [19.998900257047467, -23.308699882198539],
            [19.999445080755294, -23.454173008994786],
            [20.000000130385104, -23.647499929895858],
            [20.000273212791484, -23.757226921758004],
            [20.000536069275796, -23.864409000170824],
            [20.000000130385104, -23.95083580988269],
            [20.000554174186703, -24.282217873248968],
            [20.001109223816513, -24.406390907636236],
            [20.001945067203479, -24.572226862425694],
            [20.002218149609661, -24.724163945330801],
            [20.001663267617943, -24.765554956793252],
            [20.000945106151903, -24.76540894403955],
            [20.001663267617943, -24.793608851523715],
            [20.001663267617943, -24.862782016414911],
            [20.001391191040142, -25.083617840700526],
            [20.000554174186703, -25.221672983165718],
            [20.000273212791484, -25.414999904066804],
            [20.000436156989622, -25.616490966321692],
            [20.000000130385104, -25.870835795577577],
            [20.000000130385104, -26.409172916256026],
            [19.999445080755294, -27.0302820015755],
            [19.999163281169757, -27.320281938296731],
            [19.998763296749246, -27.36949997096626],
            [19.998336154962146, -27.485835925862659],
            [19.99805402010054, -27.582226974459189],
            [19.997773226343327, -27.871946117423377],
            [19.998336154962146, -28.064999955918168],
            [19.99805402010054, -28.354726977871366],
            [19.996945094307307, -28.415590994483438],
            [19.812218173726279, -28.492499985474865],
            [19.789718128055398, -28.495555021552846],
            [19.743609110920403, -28.485555075760431],
            [19.567454027464635, -28.528326921955852],
            [19.513336101823114, -28.594999932760871],
            [19.493891259868207, -28.633054946750136],
            [19.490691216865002, -28.669026890156431],
            [19.466391241301267, -28.699717899271363],
            [19.4477731902779, -28.710835823312408],
            [19.418327061426112, -28.71889099992454],
            [19.349027162158592, -28.735835855431361],
            [19.322500114914703, -28.727499885062016],
            [19.301382245081356, -28.725417987945534],
            [19.277218223987319, -28.731254977695116],
            [19.261245166337432, -28.744864003366828],
            [19.239791182185826, -28.802426891223135],
            [19.248054062359245, -28.831673027864568],
            [19.26000011843621, -28.855417953798593],
            [19.253891219747004, -28.899446079645415],
            [19.183891263924266, -28.937226837761997],
            [19.14582719747952, -28.954999992942916],
            [19.108327065482001, -28.961872985927968],
            [19.002500173178362, -28.927917896069403],
            [18.985691272141253, -28.90499992870491],
            [18.970418103408605, -28.877635864607768],
            [18.951527137617035, -28.866391038552322],
            [18.71916334658593, -28.836526820368931],
            [18.338336100973294, -28.884445993318778],
            [18.181318075987178, -28.908473054114395],
            [18.044445117149053, -28.868126930704832],
            [18.026109200987378, -28.84986393710092],
            [18.001391136092053, -28.826945969736528],
            [17.969927147864126, -28.804099919101589],
            [17.912500214477831, -28.779163924723065],
            [17.749718122299782, -28.746391018491579],
            [17.710827097285403, -28.756672931507595],
            [17.679300244783633, -28.769864035485881],
            [17.643400218106791, -28.773963959616566],
            [17.605273287387973, -28.753126883540247],
            [17.602218083672, -28.720690929817493],
            [17.589754025977271, -28.689545956825327],
            [17.497773199567717, -28.694726978830602],
            [17.461109246233406, -28.702781987804656],
            [17.42200917655336, -28.715281920045143],
            [17.400900191537318, -28.70839987460468],
            [17.361382200163575, -28.534999922730549],
            [17.385418145776725, -28.424235921804637],
            [17.396109095668834, -28.402226888023122],
            [17.399582221078333, -28.363191023721527],
            [17.398054032487096, -28.3422268779928],
            [17.343118200731453, -28.233055103398556],
            [17.309573153577389, -28.22103595712565],
            [17.278818106722014, -28.238263953322488],
            [17.218809211874287, -28.242073025412225],
            [17.198609160441123, -28.223608866131585],
            [17.184373168410048, -28.200499959012248],
            [17.188327079787115, -28.171654980257898],
            [17.189018083886737, -28.114108856208119],
            [17.171654133220102, -28.102264053521623],
            [17.154136123172549, -28.097499947381451],
            [17.134300181614265, -28.087081912257531],
            [17.120418073536371, -28.065417877611679],
            [17.107909088840472, -28.045835907720488],
            [17.075763316606327, -28.030345982970857],
            [16.911873304463541, -28.067499942366339],
            [16.886945021435963, -28.092364020015395],
            [16.8225002557773, -28.237781993888603],
            [16.801600147788918, -28.36278198684542],
            [16.77666331522019, -28.441672962667312],
            [16.719509128963921, -28.499026973495859],
            [16.700618163172237, -28.490346003990837],
            [16.677118156449893, -28.464308962808104],
            [16.625000152911582, -28.496108897716169],
            [16.489591198190794, -28.57818235284563],
            [16.483327871673993, -28.608338099629918],
            [16.478290581500204, -28.633443359499921],
            [16.473745041000114, -28.644821620499982],
            [16.466228183000169, -28.656411058999979],
            [16.465359099000096, -28.658028754999975],
            [16.443615624000131, -28.68882584399995],
            [16.418487004000127, -28.716725317999988],
            [16.3800175, -28.762002771000013],
            [16.31989171900014, -28.81722457099994],
            [16.318100043000129, -28.818306535000019],
            [16.311384746000158, -28.822450432999972],
            [16.226693264000119, -28.879209653],
            [16.220313321000077, -28.883520641],
            [16.202574059000142, -28.893230167000013],
            [16.164709076000037, -28.914504978000025],
            [16.133473422, -28.933275485999943],
            [16.074487789000159, -28.969870557000036],
            [15.995098170000119, -29.018263360999981],
            [15.917965031000108, -29.067275079999987],
            [15.848349139000163, -29.108119939],
            [15.551673620000059, -29.291295034],
            [15.34499754899997, -29.419589429999974],
            [14.963309240000171, -29.657962281000053],
            [13.849887243000012, -30.367257330000015],
            [13.770953886000086, -30.416887702999958],
            [13.649866990000135, -30.492720213000013],
            [13.585109825000131, -30.522717495999984],
            [13.530871543000131, -30.549781586],
            [13.343749512000045, -30.635803012999943],
            [13.338021939500123, -30.62863969799993],
            [12.902240239000093, -30.245377097999977],
            [12.900405525999986, -30.243057623],
            [12.898424574000131, -30.240552784999963],
            [12.89281196799999, -30.235591488999972],
            [12.884191052000176, -30.224687263999968],
            [12.857526095000139, -30.20110238499997],
            [12.856748227000082, -30.200117717999944],
            [12.855441541000147, -30.19896156800003],
            [12.849745722000137, -30.191750605999971],
            [12.840154836000181, -30.183262280000022],
            [12.838454268000078, -30.181108614],
            [12.835589117000154, -30.178572357999926],
            [12.832675861000155, -30.174882396999976],
            [12.827746991000112, -30.170518586999961],
            [12.8230012920001, -30.164506263999968],
            [12.814941528000077, -30.157368522999931],
            [12.808701682000105, -30.149460486999942],
            [12.804642683000168, -30.145864730999975],
            [12.804143766000095, -30.145232307999962],
            [12.802555575000127, -30.143825262999982],
            [12.782902776333373, -30.118905612333265],
            [12.779938185000162, -30.116277488],
            [12.779461060000017, -30.115672281000016],
            [12.778647924000154, -30.11495143599997],
            [12.402113750000126, -29.634825506],
            [12.401596157000114, -29.633821891],
            [12.401276689666815, -29.633202309999987],
            [12.40056530600009, -29.63229028500001],
            [12.398731799500126, -29.628734545499938],
            [12.391879911833456, -29.619945031666632],
            [12.335660575000048, -29.510966171333322],
            [12.262356959000158, -29.416713200999936],
            [12.250052964000133, -29.392737880999945],
            [12.247048505000151, -29.388869204999949],
            [12.115968638000112, -29.132754623999986],
            [12.103141523000147, -29.116161968999961],
            [12.095575235000098, -29.106374100000039],
            [12.094483878500171, -29.104236412499958],
            [12.091586911000149, -29.100487463999968],
            [12.069796409500071, -29.057882771999928],
            [12.064232752000123, -29.050677473],
            [12.063462072000135, -29.049165413000026],
            [12.063128774000091, -29.04851139],
            [12.047144395000117, -29.027803446],
            [12.039295688000152, -29.012394252000021],
            [12.037718171500131, -29.010349409999947],
            [12.034889806000109, -29.004929405333272],
            [12.034341379000125, -29.004218208999973],
            [12.028115876000157, -28.991990655999984],
            [12.020500804500216, -28.982065423499932],
            [12.020138235000104, -28.981352100000024],
            [12.019404962000181, -28.980401343000054],
            [12.018141711000112, -28.978763064000034],
            [11.749366235000082, -28.448073098999956],
            [11.748719996500142, -28.44582404549999],
            [11.748261993333443, -28.444332524666663],
            [11.74773000100015, -28.443276769000036],
            [11.745515235000141, -28.438881098999957],
            [11.724676235000118, -28.364818098999947],
            [11.721623775000154, -28.358754735],
            [11.717109936000156, -28.349788033999971],
            [11.663079235000112, -28.157278098999953],
            [11.655909366000174, -28.142995577000022],
            [11.65230187100002, -28.135808799000031],
            [11.632165235000144, -28.06387609899997],
            [11.615878519666779, -28.03126379599999],
            [11.615312793000129, -28.030044033500005],
            [11.595544235000119, -27.959591098999951],
            [11.592235234999976, -27.947861099],
            [11.583727235000083, -27.930862099000024],
            [11.578157896000164, -27.910905229],
            [11.57715780000018, -27.907321263000028],
            [11.574256142000081, -27.901520618999967],
            [11.573757235000102, -27.90052309899994],
            [11.567863235000146, -27.879390099000034],
            [11.562835235000136, -27.86923059899992],
            [11.56214523500006, -27.867747098999914],
            [11.560204737500243, -27.860892421999949],
            [11.559753639500116, -27.859832712999932],
            [11.559168735000185, -27.857965098999941],
            [11.557475304500088, -27.852154196000015],
            [11.556049235000074, -27.849300098999933],
            [11.477037930666768, -27.565180417],
            [11.431133155000026, -27.472931810000048],
            [11.275634235000155, -26.908995098],
            [11.275518235000135, -26.907425098000019],
            [11.25915223500013, -26.847794097999966],
            [11.237581978000094, -26.554249093333311],
            [11.23005937400012, -26.52671319400001],
            [11.168742235000082, -26.302266098],
            [11.142066736666806, -25.93559306],
            [11.055828033000182, -25.75907135],
            [10.903628234000138, -25.195106095999989],
            [10.868453234000128, -24.697802096000046],
            [10.861679234000178, -24.672512096],
            [10.86148005400014, -24.669693421499971],
            [10.851921234000059, -24.633989096],
            [10.85157324400015, -24.629526056499984],
            [10.851184197000094, -24.628072567999936],
            [10.85103623400002, -24.625970095999975],
            [10.850497234000102, -24.623956096000043],
            [10.850046234000104, -24.617559096000022],
            [10.849073234000144, -24.613923096],
            [10.84882909000001, -24.610467565],
            [10.848412234000079, -24.60890909599992],
            [10.84807423400008, -24.604108095999962],
            [10.847776234000122, -24.602995095999972],
            [10.847719876750062, -24.602386132500001],
            [10.846968234000144, -24.599575095999981],
            [10.846359234000118, -24.590932096000017],
            [10.845573734000141, -24.587866595999955],
            [10.844957234000105, -24.585430095999968],
            [10.804187234000096, -24.006158094999918],
            [10.806091234000149, -23.990480094999981],
            [10.804973234000101, -23.974576095],
            [10.805234234000125, -23.972423094999954],
            [10.805090234000119, -23.970378094999944],
            [10.805359234000065, -23.968166094999972],
            [10.805283234000171, -23.967083094999978],
            [10.805481234000126, -23.965452094999947],
            [10.805300234000157, -23.962884094999922],
            [10.805611234000139, -23.960320094999958],
            [10.805440234000116, -23.957886094999921],
            [10.829533234000053, -23.758977094999977],
            [10.805004480666781, -23.410969043333381],
            [10.801109759000099, -23.405613098999922],
            [10.588831964333394, -22.960615573666587],
            [10.437626004, -22.75109840600004],
            [10.391718350000161, -22.65410169899998],
            [10.383653943333343, -22.6370626213333],
            [10.283256896000182, -22.497350303999937],
            [10.277421717000095, -22.484978388],
            [10.273963570000063, -22.477646110666697],
            [10.271147696000128, -22.473721939999976],
            [10.27039096850018, -22.472128066],
            [10.26476995500019, -22.460217450999934],
            [10.263012874000111, -22.457768328999975],
            [10.184714739000157, -22.291084453999957],
            [10.012904333000137, -21.92527401539995],
            [10.012516524000119, -21.924414933999984],
            [10.012171233000146, -21.923677093000038],
            [10.011340233000141, -21.920456092999984],
            [10.008177233000083, -21.913698092999937],
            [10.006752936000112, -21.908181457000012],
            [10.000180480000125, -21.893971162499966],
            [9.999653701800128, -21.891984376199957],
            [9.999183662000064, -21.890374871],
            [9.998653807000096, -21.889191614500007],
            [9.99810923299998, -21.887077092999988],
            [9.997232328500047, -21.885204311999928],
            [9.99653523300006, -21.882795092999984],
            [9.951911233000089, -21.709841093000037],
            [9.949064016000051, -21.705837375999963],
            [9.937264604000148, -21.689244741000039],
            [9.689360233000031, -21.156426092999951],
            [9.683527233000149, -21.133560093000014],
            [9.67866423300012, -21.123069093],
            [9.676456802000075, -21.119944009999983],
            [9.672407159800116, -21.114092299399985],
            [9.670900856, -21.111902996],
            [9.669828616000018, -21.109589273000054],
            [9.668466028000182, -21.107659649],
            [9.659943021500141, -21.089423216499995],
            [9.657870279000207, -21.08639198399996],
            [9.6572241020001, -21.085093525666665],
            [9.656732302000108, -21.084105125999926],
            [9.655564198666752, -21.081588773],
            [9.654844718000049, -21.080066625499953],
            [9.654227777000074, -21.078763253999981],
            [9.653121076250102, -21.076386929499961],
            [9.651497723000148, -21.07405099],
            [9.650430568666707, -21.071747064999983],
            [9.649012184000213, -21.069701240999905],
            [9.648769452000181, -21.069177216999975],
            [9.64092217100017, -21.058057959999942],
            [9.640153881333418, -21.056479812833302],
            [9.639810003999969, -21.055825177000031],
            [9.63591340300016, -21.050216378999934],
            [9.634366233000122, -21.047937093],
            [9.633907996000147, -21.04694801499997],
            [9.633109905, -21.045224257999976],
            [9.631914447000128, -21.043444118499991],
            [9.63036602599999, -21.041163646],
            [9.629663770500116, -21.03968951950003],
            [9.628428622, -21.037148261999945],
            [9.62719124500012, -21.035394170499899],
            [9.626702473000108, -21.034438830000028],
            [9.625433340800157, -21.032545609199985],
            [9.62382644300007, -21.030243252999981],
            [9.616242663000094, -21.013857696999949],
            [9.614179579000194, -21.010932402499904],
            [9.613682624, -21.009892864999912],
            [9.613255683000119, -21.008970258999952],
            [9.609240972000123, -21.003277239999917],
            [9.608734909000077, -21.002183532000018],
            [9.60837208650014, -21.001668990499937],
            [9.606473839666734, -20.997570396333302],
            [9.605157595000122, -20.995703400999943],
            [9.604591449, -20.99447969799995],
            [9.603904862333366, -20.992995543333322],
            [9.602822912000136, -20.991460969999935],
            [9.530857300000122, -20.835879413499924],
            [9.530246943333481, -20.834931125666685],
            [9.509967552000091, -20.80604448899993],
            [9.508708898000123, -20.80425565699997],
            [9.356280883000125, -20.652934503999987],
            [9.355890679250109, -20.652378211750033],
            [9.350115194000153, -20.646632379],
            [9.345784281000107, -20.640457372999961],
            [9.345175829000112, -20.63985239],
            [9.341297184000098, -20.634321639999968],
            [9.340335609333437, -20.632950389000015],
            [9.298350825000114, -20.591184842999965],
            [9.29750685400009, -20.589980376999961],
            [9.284726835000043, -20.577260710000019],
            [9.280415889000153, -20.571106596499973],
            [9.279787470000116, -20.570480865000022],
            [9.277534459500089, -20.567358584500013],
            [8.937901715000123, -20.080795942499918],
            [8.936325888000027, -20.078462956999942],
            [8.935903915000125, -20.077536791999961],
            [8.935055133000048, -20.07567621099993],
            [8.934676993000124, -20.075132550999982],
            [8.845766283000131, -19.87969917800001],
            [8.821156867333428, -19.825605476333322],
            [8.759790515000049, -19.737082202],
            [8.759434983000034, -19.736298508999894],
            [8.758270294500079, -19.734617295999882],
            [8.516500232000169, -19.200093090999914],
            [8.509110099000026, -19.170046583499996],
            [8.50803834950014, -19.167668994999957],
            [8.507601232000155, -19.166074090999984],
            [8.507213209500151, -19.165214068999958],
            [8.480292232000124, -19.055918091000024],
            [8.479532847000087, -19.054232042999971],
            [8.472057037000155, -19.037633293999974],
            [8.333377232000117, -18.471771089999933],
            [8.327471232000022, -18.367155089999983],
            [8.310980555000185, -18.299679287999922],
            [8.30424620600013, -18.272123638],
            [8.30123806633344, -18.218832326666686],
            [8.298417352000143, -18.207281563999985],
            [8.283450232000149, -18.145991089999924],
            [8.283356232000187, -18.14431509],
            [8.28273723200013, -18.141780089999941],
            [8.282379232000068, -18.13543909000002],
            [8.28209423200002, -18.13427309],
            [8.281462732000193, -18.12363959000001],
            [8.281127232000131, -18.118262089999959],
            [8.280791963000183, -18.116890660999914],
            [8.280411232000034, -18.110565090000023],
            [8.280115232000156, -18.109353090000013],
            [8.280043232000054, -18.10807109],
            [8.279303232000018, -18.105041089999929],
            [8.279216232000124, -18.103501089999966],
            [8.278576732000147, -18.10072659],
            [8.277855232000121, -18.097616090000031],
            [8.277727232000188, -18.095346089999964],
            [8.277042732000126, -18.092331589999944],
            [8.276327232000085, -18.089191089999986],
            [8.243504232000078, -17.509288088999952],
            [8.244573232000164, -17.500867088999968],
            [8.243917232000143, -17.489311088999941],
            [8.244761232000087, -17.482658088999969],
            [8.244242232000119, -17.473530089000022],
            [8.245135232000109, -17.466497089000043],
            [8.244586232000159, -17.456849088999959],
            [8.247356232000129, -17.435004088999946],
            [8.246917232000129, -17.426900588999942],
            [8.246326232000172, -17.416115088999987],
            [8.246748232000073, -17.412784088999985],
            [8.246415232000146, -17.406924088999986],
            [8.250364232000038, -17.375748089],
            [8.249770232, -17.365325089000024],
            [8.250106232, -17.362672088999986],
            [8.249973232000116, -17.360332089],
            [8.258834732000139, -17.289977088999919],
            [8.263853331000149, -17.249945731999986],
            [11.751325052000055, -17.249775872999962],
            [11.752782053360249, -17.254835373506694],
            [11.811663239580525, -17.270281934594777],
            [11.884582277011248, -17.224445999865978],
            [11.90221813661779, -17.205835827831621],
            [11.93305415265803, -17.180700008880834],
            [11.976109139543354, -17.164172907429446],
            [12.048054032184439, -17.143890880983037],
            [12.087773189234838, -17.136526876108604],
            [12.205827099010747, -17.194726956246072],
            [12.23805400806782, -17.215281897460599],
            [12.292218201814961, -17.229726934157341],
            [12.434163217916762, -17.212781911012527],
            [12.478891232680724, -17.241672990234491],
            [12.557218106417309, -17.243335792191061],
            [12.604445101800962, -17.22583588705443],
            [12.817918094678561, -17.107081920723218],
            [12.851391225103157, -17.072499864505374],
            [12.879791124797777, -17.040135827511989],
            [12.923336117744242, -17.016390901578049],
            [12.98583611422265, -16.991108902235482],
            [13.018473233622245, -16.978055093831571],
            [13.149163197746617, -16.954173039961262],
            [13.170900154950317, -16.956317968989865],
            [13.208891131199039, -16.973608997098751],
            [13.260827079799924, -16.98486404907618],
            [13.317782279674134, -16.978055093831571],
            [13.346309081383168, -16.970672984046246],
            [13.376036171630062, -16.970908850802331],
            [13.472082221091028, -17.010835879051996],
            [13.494300131900303, -17.025555003983428],
            [13.52801818153651, -17.087573041027952],
            [13.660691136319656, -17.218890977339996],
            [13.697773178985585, -17.241391023010877],
            [13.841391222965854, -17.323608943850388],
            [13.9288912515633, -17.401108859017455],
            [13.993218167662889, -17.423946024834976],
            [14.016663189100484, -17.410899927782097],
            [14.051109123210352, -17.419445948645603],
            [14.082918110573814, -17.429999938239334],
            [14.171391278132774, -17.421117970695661],
            [14.194718114735196, -17.411390939671463],
            [14.218054003792929, -17.38694595718232],
            [14.361663330593956, -17.387217866122029],
            [14.4480540981221, -17.387500000983735],
            [14.634718076532295, -17.388335844370772],
            [14.993891178144793, -17.389726905025611],
            [15.496945180221786, -17.389445943630392],
            [15.583054148164308, -17.389163976406778],
            [15.956663234018151, -17.386673042414102],
            [16.003327133144893, -17.386673042414102],
            [16.606109187120097, -17.390835830818844],
            [17.278827159177524, -17.392226891473683],
            [17.510554093203524, -17.391946097716556],
            [17.561663250872755, -17.390835830818844],
            [17.73389124504169, -17.388890894000582],
            [18.000545066783218, -17.386172978069325],
            [18.117218141826157, -17.38694595718232],
            [18.188891125527249, -17.387217866122029],
            [18.390000140634442, -17.38694595718232],
            [18.451536050606933, -17.389835869767197],
            [18.5133361581683, -17.472782010356724],
            [18.578327088639384, -17.572226921564749],
            [18.641945063366421, -17.64805498231965],
            [18.753682203253561, -17.745282041941309],
            [18.804300181395348, -17.768891013405423],
            [18.919445067847249, -17.816390923557279],
            [18.983891174610307, -17.825972947656155],
            [19.025836062236095, -17.829999949228991],
            [19.055827182433887, -17.827781930004519],
            [19.116800163787644, -17.819445959635161],
            [19.133609232462646, -17.804372950750746],
            [19.175282211148726, -17.801108870007013],
            [19.245554075911173, -17.806255023294966],
            [19.298745130696972, -17.824999976332833],
            [19.337218234017826, -17.843890942124318],
            [19.413327088529769, -17.861117932492832],
            [19.445827079993165, -17.862781907915888],
            [19.559163319372686, -17.867499913588432],
            [19.701382256071014, -17.870554949666328],
            [19.79791814395486, -17.863755046877216],
            [19.818609207277376, -17.858055017425926],
            [19.920554104933501, -17.857226885389821],
            [19.946245141152161, -17.863335951717303],
            [19.971318095829076, -17.880135800298731],
            [20.016945153530116, -17.89083580264635],
            [20.098891165429905, -17.896390992810396],
            [20.128891170445115, -17.894446055992134],
            [20.244718176179219, -17.880135800298731],
            [20.270836186546831, -17.86125506042913],
            [20.32131821021872, -17.857226885389821],
            [20.445200224926964, -17.901872925140523],
            [20.492554122324918, -17.941890980858943],
            [20.553682168887889, -17.983055010382742],
            [20.595554133955716, -17.981390867321693],
            [20.638473166371313, -17.978472959180095],
            [20.751945192582752, -17.99610898642463],
            [20.781582261189385, -18.010199971530312],
            [20.854163340283236, -18.016391012871054],
            [20.889718200186337, -17.994446016829983],
            [20.953327122457978, -17.968263968721942],
            [21.021382141462453, -17.953335799124758],
            [21.138609092668815, -17.934446006799661],
            [21.165863353834141, -17.931464060917619],
            [21.236873161354254, -17.938890930066009],
            [21.263327118402003, -17.956391002840732],
            [21.379354116346377, -18.014890987474629],
            [21.755409209191754, -17.947346091098836],
            [22.290273204460732, -17.850000008451786],
            [22.564163290871932, -17.798891018420633],
            [22.8811092023588, -17.739446058020121],
            [22.910000113942772, -17.733335818226166],
            [22.996391216746986, -17.71694601234924],
            [23.284718125589762, -17.662499851378399],
            [23.289163216494188, -17.661108958361552],
            [23.476109162127983, -17.625835898043988],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "NCL", Country: "New Caledonia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [164.132440653000117, -15.829336662999879],
            [164.529258653000056, -16.349439662999828],
            [164.537417653000148, -16.360105662999842],
            [164.552565653000102, -16.380131662999929],
            [164.567585653000151, -16.400215662999869],
            [164.582478653000294, -16.420355662999953],
            [164.597244653000075, -16.440552662999949],
            [164.598216653000236, -16.441898662999961],
            [164.611902653000129, -16.460797662999937],
            [164.626433653000049, -16.481098662999841],
            [164.640837653000062, -16.501455662999945],
            [164.655113653000058, -16.521869662999961],
            [164.66926265300026, -16.542340662999948],
            [164.683282653000106, -16.56286766300002],
            [164.693522653000059, -16.578040662999967],
            [164.916328654000154, -16.909383663999847],
            [164.926688654000145, -16.924856663999947],
            [164.940154654000139, -16.945214663999934],
            [164.953506654000194, -16.965646663999848],
            [164.966745654000079, -16.986153664000028],
            [164.979870654000024, -17.006735663999962],
            [164.992880654000146, -17.027391663999936],
            [165.005777654000212, -17.048123663999888],
            [165.018560654000225, -17.068929663999938],
            [165.031229654000072, -17.089809663999858],
            [165.043784654000206, -17.110765663999928],
            [165.0562256540004, -17.131795663999924],
            [165.0685526540002, -17.15289966399996],
            [165.080765654000288, -17.174078664000035],
            [165.092864654000209, -17.19533166399998],
            [165.104848654000193, -17.216659663999963],
            [165.116718654000238, -17.238061663999929],
            [165.128474654000343, -17.259538663999933],
            [165.140116654000281, -17.281088663999981],
            [165.151644654000165, -17.302713663999839],
            [165.163057654000227, -17.324413663999962],
            [165.174356654000235, -17.346186663999958],
            [165.185540654000079, -17.368034663999936],
            [165.196610654000096, -17.389955663999956],
            [165.207566654000175, -17.411951663999957],
            [165.218407654000089, -17.434021663999943],
            [165.229134654000177, -17.456165663999911],
            [165.231062654000198, -17.46020166400001],
            [165.245545654000011, -17.474561663999907],
            [165.263130654000179, -17.492184663999922],
            [165.280603654000288, -17.509881663999863],
            [165.297963654000114, -17.527653663999956],
            [165.31521265400022, -17.545499664000033],
            [165.332347654000046, -17.56341966399998],
            [165.349371654000151, -17.581415663999962],
            [165.366282654000315, -17.599484663999931],
            [165.383080654000196, -17.617628664],
            [165.399765654000134, -17.635846663999928],
            [165.416339654000126, -17.654139664999946],
            [165.432799654000064, -17.672506664999958],
            [165.449147654000171, -17.690948664999951],
            [165.465382654000223, -17.709463664999873],
            [165.481504654000105, -17.728053664999891],
            [165.497513654000045, -17.746718664999946],
            [165.513410654000154, -17.765456664999931],
            [165.521939654000107, -17.775623664999955],
            [165.526257654000347, -17.781201665000026],
            [165.541251654000348, -17.800794664999955],
            [165.556125654000198, -17.820453664999945],
            [165.570878654000126, -17.840179664999937],
            [165.585511654000129, -17.859972664999816],
            [165.600023654000211, -17.879830664999929],
            [165.61441565400014, -17.89975666499987],
            [165.628686654000148, -17.919747664999875],
            [165.642837654000232, -17.939806664999935],
            [165.656867654000052, -17.959930664999945],
            [165.670776654000178, -17.980121664999956],
            [165.684564654000269, -18.000378664999971],
            [165.69823165400021, -18.02070166499982],
            [165.711778654000227, -18.041091664999954],
            [165.725204654000208, -18.061547664999978],
            [165.738508654000157, -18.082069664999892],
            [165.751692654000294, -18.102658664999979],
            [165.764755654000396, -18.123312664999958],
            [165.777696654000351, -18.144033665],
            [165.790517654000155, -18.164820664999922],
            [165.803216654000153, -18.185673664999968],
            [165.815794654000229, -18.206592664999903],
            [165.828251654000155, -18.227577664999842],
            [165.84058765400016, -18.248628664999956],
            [165.842782654000189, -18.252423664999981],
            [165.865284654000106, -18.262911664999933],
            [165.892543654000178, -18.275793664999981],
            [165.919630655000219, -18.288771665000013],
            [165.946544655000139, -18.301845664999917],
            [165.973286655000152, -18.31501666500003],
            [165.999855655000033, -18.328283664999958],
            [166.026251655000237, -18.341646664999814],
            [166.052474655000083, -18.35510666499988],
            [166.078524655000251, -18.368663664999929],
            [166.104401655000061, -18.382316664999905],
            [166.130105655000193, -18.396066665000035],
            [166.155636655000194, -18.409913664999976],
            [166.16251965500021, -18.41369866499997],
            [166.180998655000053, -18.423853664999967],
            [166.206189655000344, -18.437890664999941],
            [166.231206655000051, -18.452023664999842],
            [166.256049655000197, -18.466253664999954],
            [166.280719655000212, -18.480580664999934],
            [166.305215655000211, -18.49500466499984],
            [166.329537655000308, -18.509525664999956],
            [166.338538655000207, -18.514974664999954],
            [166.355695655000318, -18.523043664999875],
            [166.382849655000314, -18.536018664999972],
            [166.409806655000011, -18.549103664999961],
            [166.436566655000092, -18.562298664999958],
            [166.463127655000221, -18.575603664999903],
            [166.489491655000052, -18.589018665000026],
            [166.515658655000266, -18.602544664999982],
            [166.532477655000093, -18.611376664999909],
            [166.620638655000107, -18.657854664999888],
            [166.668999655000107, -18.683158664999951],
            [166.690170655000202, -18.694361664999974],
            [166.714828655000019, -18.707624664999855],
            [166.739304655000183, -18.721006664999948],
            [166.763596655000129, -18.734506666000016],
            [166.787705655000082, -18.748124666000024],
            [166.811631655000156, -18.761860665999905],
            [166.835374655000351, -18.77571566599994],
            [166.858934655000212, -18.789689665999902],
            [166.882310655000197, -18.803780665999966],
            [166.905503655000189, -18.817990665999957],
            [166.928513655000188, -18.832319665999819],
            [166.951338655000086, -18.846766665999951],
            [166.973980656000208, -18.861331665999955],
            [166.996439656000234, -18.876015665999887],
            [167.018713656000045, -18.890818665999916],
            [167.040804656000205, -18.905739665999931],
            [167.062710656000149, -18.920778665999933],
            [167.084432656000217, -18.935936665999975],
            [167.105970656000068, -18.951213665999944],
            [167.127324656000155, -18.9666086659999],
            [167.148493656000255, -18.982122665999952],
            [167.169478656000138, -18.997755665999875],
            [167.190278656000032, -19.013506665999898],
            [167.210894656000164, -19.029375665999908],
            [167.231325656000195, -19.045364665999898],
            [167.251572656000235, -19.061470665999821],
            [167.271633656000176, -19.077696665999952],
            [167.291509656000017, -19.094040665999955],
            [167.311201656000094, -19.110503665999886],
            [167.330707656000186, -19.127084665999917],
            [167.350028656000291, -19.14378366599999],
            [167.36916465600018, -19.160602665999875],
            [167.388115656000195, -19.177539665999973],
            [167.406880656000226, -19.194594666],
            [167.425460656000155, -19.211768665999955],
            [167.443854656000099, -19.229060665999953],
            [167.462062656000285, -19.246471665999934],
            [167.480085656000256, -19.264001665999956],
            [167.497922656000128, -19.281649666000021],
            [167.515573656000015, -19.299415665999959],
            [167.533039656000142, -19.317300665999937],
            [167.550318656000172, -19.335303665999959],
            [167.552676656000244, -19.337803665999957],
            [167.572065656000063, -19.350480665999839],
            [167.594348656000079, -19.36532266599994],
            [167.616421656000171, -19.380297665999876],
            [167.617368656000167, -19.380952665999928],
            [167.638308656000163, -19.395392666000021],
            [167.660180156000365, -19.410758665999936],
            [167.681480656000076, -19.425967665999963],
            [167.702764656000227, -19.441448665999872],
            [167.723836656000231, -19.457064665999894],
            [167.74469865600031, -19.472815665999917],
            [167.751654656000142, -19.47816766599982],
            [167.768127656000161, -19.494338665999976],
            [167.787394656000174, -19.513608665999982],
            [167.806393656000068, -19.532973665999975],
            [167.825125656000068, -19.552431665999961],
            [167.84359065600006, -19.57198266599994],
            [167.861786656000163, -19.591628665999849],
            [167.864116656000192, -19.59419366599991],
            [167.879901656000129, -19.611303666000026],
            [167.897774656000138, -19.631062665999934],
            [167.915377656000146, -19.650916665999944],
            [167.916202656000223, -19.651866665999933],
            [167.932745656000066, -19.670853665999914],
            [167.949845656000093, -19.690883666],
            [167.966549156000156, -19.710849165999889],
            [167.983900656000174, -19.73099666600001],
            [168.000841656000148, -19.751085665999938],
            [168.017506656000023, -19.77126966599991],
            [168.033896656000024, -19.791550665999921],
            [168.050011657000141, -19.811926666999909],
            [168.065850657000283, -19.832399666999834],
            [168.075352657000053, -19.844956667000019],
            [168.155605657000024, -19.95145866699994],
            [168.165728657000045, -19.965124666999941],
            [168.168863657000088, -19.969344666999973],
            [168.183534657000223, -19.989561666999919],
            [168.197948657000211, -20.009897666999962],
            [168.212106657000163, -20.030350666999936],
            [168.226008657000193, -20.050922666999952],
            [168.233523657000092, -20.062318667],
            [168.256816657000172, -20.097714666999877],
            [168.34148765700013, -20.169573666999952],
            [168.359932657000172, -20.185538666999946],
            [168.379108657000188, -20.202516666999941],
            [168.398026657000173, -20.219652666999821],
            [168.416688657000236, -20.236946666999984],
            [168.435092657000155, -20.254398666999862],
            [168.442127657000128, -20.261225666999962],
            [168.453269657000192, -20.271989666999957],
            [168.47120765700015, -20.289727666999909],
            [168.488887657000305, -20.307623666999859],
            [168.506310657000199, -20.325677666999923],
            [168.523475657000063, -20.343889666999814],
            [168.540381657000125, -20.362259666999819],
            [168.557029657000271, -20.380786666999938],
            [168.573419657000272, -20.399472666999941],
            [168.58488165700021, -20.412861666999959],
            [168.589563657000213, -20.418307666999965],
            [168.605480657000385, -20.437281666999866],
            [168.621138657000074, -20.456414666999933],
            [168.630377657000167, -20.467988666999929],
            [168.636666657000205, -20.475625666999974],
            [168.652130657000157, -20.494875666999974],
            [168.667334657000197, -20.51428466699997],
            [168.682279657000208, -20.533851666999965],
            [168.696964657000194, -20.553575666999905],
            [168.711389657000041, -20.573457666999957],
            [168.725554657000202, -20.593497666999951],
            [168.739460657000222, -20.613695666999831],
            [168.753105657000219, -20.63405066699994],
            [168.758853657000031, -20.642860666999866],
            [168.769076657000141, -20.652995666999971],
            [168.786725657000147, -20.670922666999843],
            [168.804107657000145, -20.689013666999926],
            [168.82122065700014, -20.70726766699994],
            [168.834647657000232, -20.721947666999881],
            [168.838072657000225, -20.725681666999947],
            [168.854678657000022, -20.744244666999919],
            [168.871016657000041, -20.762970666999877],
            [168.887086657000282, -20.781859666999935],
            [168.948354657000209, -20.855697666999959],
            [168.960091657000021, -20.869807666999918],
            [169.441972658000168, -21.444192667999914],
            [169.471476658000256, -21.479043667999974],
            [169.907704658000057, -21.612767667999947],
            [169.933342658000242, -21.620812667999814],
            [169.959004658000254, -21.629085667999959],
            [169.995513658000164, -21.641142667999873],
            [170.267115659000183, -21.476803667999889],
            [170.977719659000172, -21.041929667999966],
            [170.993860659000234, -21.032069667999963],
            [171.016881659000205, -21.018278667999823],
            [171.03814765900006, -21.005786667999871],
            [171.303965660000131, -20.851814666999871],
            [171.339709660000153, -20.830968666999979],
            [171.355032660000347, -20.822054666999975],
            [171.381511660000115, -20.806923666999921],
            [171.408253660000128, -20.791907666999975],
            [171.435258660000045, -20.777008666999905],
            [171.462525660000097, -20.762225666999882],
            [171.49005666000005, -20.747558666999851],
            [171.517849660000138, -20.733008666999979],
            [171.545904660000133, -20.718573666999873],
            [171.574221660000262, -20.70425466699993],
            [171.602800660000185, -20.690050666999923],
            [171.631641660000128, -20.675963666999849],
            [171.660744660000091, -20.661991666999938],
            [171.690108660000192, -20.648134666999965],
            [171.719733660000202, -20.63439366699987],
            [171.749620660000119, -20.620767666999939],
            [171.779767660000175, -20.607256666999888],
            [171.810175660000141, -20.593860666999944],
            [171.840843660000019, -20.580579666999938],
            [171.8662186600003, -20.569778667000037],
            [171.870572660000335, -20.566891666999879],
            [171.895016660000238, -20.550907666999933],
            [171.91965966000032, -20.535011666999935],
            [171.944501660000128, -20.519203666999886],
            [171.969542660000116, -20.503484666999952],
            [171.994782660000169, -20.487853666999911],
            [171.996400660000177, -20.486864666999963],
            [172.020191660000251, -20.472297666999964],
            [172.045796660000178, -20.456828666999968],
            [172.071600660000172, -20.44144766699992],
            [172.097602660000234, -20.426154666999878],
            [172.123802660000138, -20.410949666999954],
            [172.143535660000225, -20.399649666999949],
            [172.764876111000149, -20.022462973000017],
            [174.275709444000199, -25.073020149999877],
            [173.924212662000059, -25.268594672],
            [173.347865661000014, -25.524327672000027],
            [172.729271661000212, -25.68346067199991],
            [172.252276660000121, -25.726353671999902],
            [172.088259160000234, -25.74074517199989],
            [172.086459660000173, -25.740862671999935],
            [171.44451666000009, -25.694423671999914],
            [171.332721660000175, -25.667903671999966],
            [171.324447660000146, -25.668589671999882],
            [171.322833660000015, -25.668468672000017],
            [171.321365660000083, -25.66859067199988],
            [171.296303659999921, -25.666708671999857],
            [170.680119659000184, -25.619233671999893],
            [170.570216659000039, -25.592455671999915],
            [170.196042659000227, -25.756332671999871],
            [169.575150658000211, -25.913054671999816],
            [169.431684658000108, -25.930781671999966],
            [168.738487657000178, -25.84496467199989],
            [165.860269154000207, -26.201095671999951],
            [163.725153652000245, -26.441631672999961],
            [158.547354648000237, -24.998366670999985],
            [157.801264647000153, -23.248297669999943],
            [157.312098647000198, -22.527185668999834],
            [157.217931646000125, -22.175241668999945],
            [157.243487646000062, -21.789130667999871],
            [157.171542646000034, -21.215796667999939],
            [157.107098646000168, -20.892463667999891],
            [157.076264646000027, -20.714407666999875],
            [157.052653646000067, -20.541074666999918],
            [156.826264646000254, -20.141073666999887],
            [156.255709646000241, -19.286629665999953],
            [156.624875646000191, -18.931629665999935],
            [156.94570964600041, -18.540240665],
            [157.366542647000188, -17.911073664999947],
            [157.642098647000125, -17.507739663999928],
            [158.274042647000329, -16.580795663999936],
            [158.380431648000354, -16.424406662999957],
            [158.760987648000196, -15.735239662999959],
            [160.24833264900019, -16.126943662999906],
            [162.323888651000175, -15.204721661999884],
            [163.166666652000231, -14.834166661999973],
            [163.296547652000413, -14.935241661999953],
            [163.728057652000189, -15.353796662000036],
            [163.745879653000316, -15.372851661999846],
            [163.765830653000307, -15.39436866199992],
            [163.785629653000115, -15.415911661999814],
            [163.805278653000187, -15.437480661999928],
            [163.824775653000302, -15.459074661999864],
            [163.844120653000232, -15.480693661999908],
            [163.8633156530002, -15.502338661999829],
            [163.88235865300021, -15.524008662999847],
            [163.90124965300015, -15.545704662999981],
            [163.919988653000132, -15.567426662999935],
            [163.938576653000268, -15.58917366299994],
            [163.957013653000217, -15.610945662999939],
            [163.975297653000098, -15.632744662999812],
            [163.993430653000132, -15.654568662999964],
            [164.011410653000326, -15.676417662999938],
            [164.029239653000218, -15.698293662999959],
            [164.046916653000153, -15.720194662999916],
            [164.06444065300002, -15.742121662999978],
            [164.08181365300004, -15.764073662999976],
            [164.09903365300022, -15.786052662999964],
            [164.116101653000214, -15.808056662999888],
            [164.132440653000117, -15.829336662999879],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "NER", Country: "Niger" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.997891189989303, 23.000591095073204],
            [15.00694515418661, 22.959445002822221],
            [15.047782289485724, 22.751664160407003],
            [15.1544632977253, 22.202773217772787],
            [15.194718226028101, 21.998882081356001],
            [15.197500179699887, 21.820000029513679],
            [15.199445116518149, 21.655282052972765],
            [15.200000166147959, 21.614164123916609],
            [15.201391226802826, 21.517773075319994],
            [15.209582190246863, 21.491809124333187],
            [15.284445158667438, 21.445273133049184],
            [15.533336051149917, 21.089717996133473],
            [15.583054148164308, 21.018609114517616],
            [15.615691267563903, 20.951182067700799],
            [15.584445041181084, 20.930000160126909],
            [15.566109125019409, 20.905554004171378],
            [15.556663223028494, 20.87527320539904],
            [15.554163236580422, 20.852500077321864],
            [15.55721810502024, 20.815827071532041],
            [15.57750013146665, 20.765973187685816],
            [15.61236331671779, 20.731800168344094],
            [15.633054044764236, 20.716664127547617],
            [15.668336157537226, 20.700691069897644],
            [15.996663184825678, 20.353053981657794],
            [15.981945065722641, 20.32638209512676],
            [15.873336052109153, 20.13971811671648],
            [15.80416322249414, 20.020000063879436],
            [15.75389124931641, 19.93250003528199],
            [15.730273225396871, 19.755273074045292],
            [15.713054114017382, 19.618891127096546],
            [15.634163305833567, 19.01889119443122],
            [15.610836133954962, 18.841664233194521],
            [15.602500163585603, 18.782218099327523],
            [15.574445095388768, 18.289718086844331],
            [15.573054034733843, 18.24888212501169],
            [15.558982160367464, 17.999453952534154],
            [15.52360918776381, 17.360000066235685],
            [15.489718135645575, 16.920973075761836],
            [15.485136084442956, 16.898091150581237],
            [15.482500143524959, 16.894445022689737],
            [15.456945061776196, 16.867773136158618],
            [15.354163314904639, 16.761109059363477],
            [15.238609223938653, 16.641391174164525],
            [15.122218116119313, 16.521382101648371],
            [14.904445039262612, 16.2947180047925],
            [14.572218080053801, 15.947773094118745],
            [14.368891213360399, 15.733891064365039],
            [14.271663315548437, 15.596945015331087],
            [14.19555412576014, 15.489164133753704],
            [14.072218108226281, 15.313891161790664],
            [13.997218179507399, 15.205826971885202],
            [13.8591632046803, 15.037773165509364],
            [13.786391185831604, 14.888891118682054],
            [13.767218085178428, 14.848053983383025],
            [13.778609091625668, 14.802218048654325],
            [13.794163221753735, 14.732773142461397],
            [13.759163243842352, 14.705554085289563],
            [13.682918099584271, 14.608473206059713],
            [13.684445114709007, 14.579444998901366],
            [13.674373252187223, 14.552291152936462],
            [13.64778216720299, 14.53166412735429],
            [13.629718159981138, 14.521664181561974],
            [13.615182263453619, 14.51626422424512],
            [13.547500239141527, 14.511809075056888],
            [13.51721809926471, 14.496944943200148],
            [13.494718221231835, 14.484445010959675],
            [13.475827087802259, 14.468054031616262],
            [13.46500018344031, 14.450836093703245],
            [13.461945147362343, 14.430554067256807],
            [13.562227075423891, 13.993264142401884],
            [13.600554165990957, 13.828609030135112],
            [13.625118171505562, 13.718336041098667],
            [13.347773232233948, 13.720000016521709],
            [13.255273230740329, 13.60805399960681],
            [13.202218130424512, 13.527364099720259],
            [13.125827141050621, 13.518891169052779],
            [13.09389125167263, 13.523053957457435],
            [13.075418207574671, 13.5384731389439],
            [13.01610920164407, 13.533609120517724],
            [12.899718093824646, 13.484445067304748],
            [12.876109122360532, 13.473326975625625],
            [12.77041818452679, 13.377500029114401],
            [12.749718068748678, 13.349027039223941],
            [12.646663239471025, 13.286944964439044],
            [12.608054014042182, 13.272782062603824],
            [12.589163215888703, 13.263609075381041],
            [12.573054036130912, 13.244718109589641],
            [12.541627095915203, 13.189164196292054],
            [12.544027170077015, 13.152636029789463],
            [12.491945041084534, 13.097773120591683],
            [12.459163250035715, 13.066109140153429],
            [12.42957328772502, 13.073027060139623],
            [12.368609191188767, 13.075282127376212],
            [12.175282270287596, 13.122218103080826],
            [12.007773287619784, 13.17139104111105],
            [11.89055404776434, 13.23750011746894],
            [11.824582266980826, 13.270827067501841],
            [11.531109204850111, 13.35527306584963],
            [11.491663297843928, 13.365836107898943],
            [11.463336153069349, 13.373336067243272],
            [11.426663314917533, 13.378891089769326],
            [11.392500186221582, 13.381109108993797],
            [11.338609242518658, 13.379444965932663],
            [11.268473164587931, 13.376809025014595],
            [11.238609114042646, 13.375273125072511],
            [11.203745090601188, 13.371945006588334],
            [11.17110914466798, 13.366391157528753],
            [11.126945064351332, 13.361664099400798],
            [11.100827053983636, 13.36028209120137],
            [11.066663254735403, 13.359717989116064],
            [11.038327057505143, 13.36028209120137],
            [11.011391141023324, 13.361945060796018],
            [10.977500256543379, 13.364164085848799],
            [10.95750019732057, 13.365836107898943],
            [10.906663283867118, 13.372500056218243],
            [10.880973253476839, 13.379444965932663],
            [10.841109089501231, 13.386109081889956],
            [10.795000240004242, 13.389164117967752],
            [10.749718181438851, 13.388609068338027],
            [10.714582249057486, 13.385418077790234],
            [10.644663262419726, 13.374609110701115],
            [10.592218197018667, 13.357500137529584],
            [10.535273223066241, 13.340000064754946],
            [10.463891259044061, 13.318609112515304],
            [10.442500139166327, 13.314718065412293],
            [10.41194508452142, 13.309718092516135],
            [10.361663220697949, 13.304445037213767],
            [10.33750020543232, 13.300835957334286],
            [10.225000144715921, 13.280553930887876],
            [10.178745114827109, 13.270973080255558],
            [10.141527117691368, 13.256109116036896],
            [9.988054134844106, 13.17278210176606],
            [9.958609179458705, 13.153327033889269],
            [9.93389111456338, 13.135973141506256],
            [9.873054087679606, 13.075282127376212],
            [9.694163318657871, 12.871109023735798],
            [9.634927067647027, 12.80243609082747],
            [9.621945175972684, 12.804445065386275],
            [9.423891196943543, 12.816944997626663],
            [9.236663284085949, 12.829718012273347],
            [9.113054016507931, 12.841664235988489],
            [9.064854049800942, 12.844654060859554],
            [9.032500238729455, 12.846664208884732],
            [9.010982216837306, 12.846909128096229],
            [8.99180023136671, 12.846244946086742],
            [8.818609156520267, 12.89666410548466],
            [8.661809228655301, 12.94583603768649],
            [8.6483361574534, 12.971109152211739],
            [8.632500227739882, 13.000553939959048],
            [8.611109107862148, 13.025000095914677],
            [8.558745179284131, 13.063744940537262],
            [8.539445176616368, 13.075973131475919],
            [8.506945017514994, 13.0847181387213],
            [8.471109196216702, 13.083891180151767],
            [8.451663180795293, 13.079026994087428],
            [8.408609199738294, 13.0883361034182],
            [8.348327054846266, 13.140554019242586],
            [8.287218119022782, 13.198327125231032],
            [8.251509199738877, 13.217600138170397],
            [8.230973201625488, 13.221391105349142],
            [8.141391108273467, 13.280553930887876],
            [8.125482256002101, 13.298891188154187],
            [8.043609166660076, 13.320836016557095],
            [7.815000132985944, 13.352773079401629],
            [7.379718176861388, 13.099718057409945],
            [7.341945130095667, 13.106664140590851],
            [7.218891247423613, 13.125553932915949],
            [7.147500230945951, 13.042773083457519],
            [7.09055408352711, 12.995282058123081],
            [7.02806330714219, 12.995764185195128],
            [6.942218201512304, 12.996800020792421],
            [6.806391136555476, 13.108054027779303],
            [6.779163194566138, 13.158053924379146],
            [6.677918185827167, 13.345273120057314],
            [6.575282284071164, 13.45083598241024],
            [6.423054013849026, 13.605273219401496],
            [6.388609085567396, 13.625554072381433],
            [6.285000212488313, 13.683891112817207],
            [6.282773140808359, 13.683891112817207],
            [6.238054010861816, 13.683336063187298],
            [6.133336044351324, 13.660827132699097],
            [5.997500094577077, 13.70139118559193],
            [5.961945067035828, 13.715273126031761],
            [5.874718120844648, 13.74944497190711],
            [5.700282165735047, 13.817782125773178],
            [5.657218126394156, 13.836664206747344],
            [5.548363187740847, 13.891500126216883],
            [5.526391201971478, 13.886945064742392],
            [5.364163321233093, 13.846527024603276],
            [5.341945075147578, 13.794718145655068],
            [5.28916322487612, 13.752773090391287],
            [5.2150001453725, 13.746664191701811],
            [5.00000013737008, 13.733673079933865],
            [4.923609147996331, 13.7363911635032],
            [4.885554134006981, 13.78139108720697],
            [4.625982182278193, 13.721964231717365],
            [4.478891180526631, 13.688609118489751],
            [4.247773275587065, 13.481391037055261],
            [4.142782226670391, 13.473473156017505],
            [4.142500091808614, 13.401109000578515],
            [4.140918091398902, 13.273591083900627],
            [4.139718138136914, 13.177218140214904],
            [4.105000127449244, 12.996391151554391],
            [4.077218141658591, 12.951109092988929],
            [3.952782250786925, 12.748891152088774],
            [3.912218197894163, 12.717782053642154],
            [3.657782173531444, 12.528891171190097],
            [3.661109118549149, 12.445273137240235],
            [3.666118143900718, 12.249436003969109],
            [3.65639111287652, 12.215273210549341],
            [3.63694509745514, 12.107773122729085],
            [3.67107318979572, 12.00500009303687],
            [3.671663275781043, 11.975553964184996],
            [3.61694503823233, 11.919718083663881],
            [3.631663324973431, 11.825282197807496],
            [3.670554014711655, 11.807218022947552],
            [3.688336054709993, 11.749717999365217],
            [3.604454158447339, 11.693273089757653],
            [3.557845076967567, 11.736600153220948],
            [3.546109239022655, 11.774444949077974],
            [3.507918102925487, 11.824164219558853],
            [3.471245097135579, 11.854718100737301],
            [3.375136183400656, 11.894718051544828],
            [3.356527184832686, 11.885691077075933],
            [3.320900240562139, 11.886182088965214],
            [3.298054022289023, 11.910273187501375],
            [3.27000012755866, 11.951944992720968],
            [3.261663318998984, 11.977773156876026],
            [3.255454005109272, 12.011354078575835],
            [3.162282277893325, 12.113291097242836],
            [3.010036070398286, 12.268582114894059],
            [2.864591106796752, 12.381800169438506],
            [2.838618103354463, 12.396654075373263],
            [2.780282236385261, 12.38193595627024],
            [2.719036172625323, 12.352491168523017],
            [2.665009106814381, 12.302773071508625],
            [2.611673212741437, 12.292500043310014],
            [2.535836099531053, 12.281109036862773],
            [2.38836321826966, 12.247318064668804],
            [2.376391178292607, 12.206664157773687],
            [2.388745097779378, 12.129718118770057],
            [2.403609229636118, 12.084444945022099],
            [2.401663286989447, 11.957218048023321],
            [2.397927137457572, 11.896154039200894],
            [2.392091153536285, 11.904009055964693],
            [2.320873139540737, 12.000000120140612],
            [2.058336173374329, 12.35721805901288],
            [2.076945171942299, 12.383473197316874],
            [2.16166324686796, 12.415553925982167],
            [2.23555408215563, 12.418473175228243],
            [2.25610919100825, 12.481109126176548],
            [2.220836130690685, 12.59360901925497],
            [2.200836071467876, 12.630836068846207],
            [2.142291159832922, 12.694100159620263],
            [2.106391133155995, 12.714718132746938],
            [1.988891267181515, 12.731109112090337],
            [1.928054072659677, 12.700554057445316],
            [1.871663310146744, 12.608891072805136],
            [1.578336093131639, 12.630000057821178],
            [1.32229124626889, 12.842518016648356],
            [1.117218093863016, 13.011109103019251],
            [1.011391201559292, 13.023891170121345],
            [0.989163232466296, 13.047218006723767],
            [0.989245039841506, 13.090564180926378],
            [0.990836092706672, 13.336664234919752],
            [0.991663218914397, 13.371664212831107],
            [1.011663278137178, 13.372773138624353],
            [1.043891193022574, 13.363609036218975],
            [1.073336148407975, 13.350554054348677],
            [1.107782250155935, 13.335282059082317],
            [1.179445007935215, 13.313473185149149],
            [1.276518175814147, 13.348054067900605],
            [1.242954017920681, 13.38979108432703],
            [1.206527104808828, 13.387500142544795],
            [1.170836122797738, 13.396391162543878],
            [1.12389126227572, 13.413327133233381],
            [1.031663337359987, 13.462500071263676],
            [1.015282248662317, 13.487844934880215],
            [1.015836124825739, 13.523818051752997],
            [0.996663191810541, 13.565973157511024],
            [0.786391247764556, 13.668891194128548],
            [0.62500021624146, 13.684164195223318],
            [0.607573233662663, 13.698818108947819],
            [0.515554018136527, 13.85416411188389],
            [0.465000245373176, 13.913609072284416],
            [0.354827168622904, 14.130973112265011],
            [0.392363175166082, 14.18069104164131],
            [0.40874510205407, 14.245135974938094],
            [0.38471820889643, 14.283609078258863],
            [0.321109118986811, 14.362782188942433],
            [0.296391221729465, 14.367218059753284],
            [0.233027051031229, 14.425418139890766],
            [0.189445010072632, 14.464718034143161],
            [0.166663332454192, 14.523054068750426],
            [0.225282172475488, 14.700836079616934],
            [0.241391184595301, 14.752217984416305],
            [0.196391260891602, 14.842082212630018],
            [0.211945055743513, 14.8638910865631],
            [0.232363204297911, 14.880209143348722],
            [0.235045078045346, 14.915064114334697],
            [0.232218197372504, 14.950282189367314],
            [0.237773219898571, 15.000009003561033],
            [0.280836085772989, 14.988609112296373],
            [0.334163262666692, 14.975553962787998],
            [0.396663259145186, 14.960554044099425],
            [0.450554035210047, 14.978608998865994],
            [0.512218188301432, 15.000827077313247],
            [0.618609182690449, 14.967500127280331],
            [0.653891127825347, 14.955554071203281],
            [0.695000172064169, 14.942218127937778],
            [0.731109075768785, 14.960836011323053],
            [0.77833607115241, 14.968609053073564],
            [0.938891259288482, 14.977500073072733],
            [0.974718195769469, 14.978608998865994],
            [0.994445172586069, 14.99528211307107],
            [1.085554113424848, 15.079444970728858],
            [1.312500177504262, 15.286664225629821],
            [1.884718124378963, 15.315273169990107],
            [1.97416325743265, 15.318891134686922],
            [2.510554100188386, 15.345000092599022],
            [2.628054133800958, 15.350553941658703],
            [2.860000170776601, 15.344718125375508],
            [3.011809178200906, 15.341809102051215],
            [3.022782262954649, 15.414027077098424],
            [3.032500241523337, 15.433053997359892],
            [3.307054007029791, 15.392382153191861],
            [3.518100103655257, 15.359909151456904],
            [3.530836070289809, 15.398335986672137],
            [3.535554075962352, 15.429164123723368],
            [3.567218056400606, 15.512218055588008],
            [3.733263223493907, 15.654864134073236],
            [3.779309208716853, 15.661244941702435],
            [3.818054053339353, 15.664027063012227],
            [3.846391256397879, 15.674164136740913],
            [3.881945110472685, 15.706664128204196],
            [3.885000146550567, 15.709164114652296],
            [3.895000092342968, 15.727500030813943],
            [3.902218084463613, 15.748609015829985],
            [3.950282264338739, 15.941944989186581],
            [3.980836145517372, 16.070273100627574],
            [4.072082214292436, 16.298609051895411],
            [4.096391242311682, 16.330691121665282],
            [4.114163224026214, 16.348054066503622],
            [4.145282213118378, 16.369718101149473],
            [4.179582134474543, 16.384236060404064],
            [4.200836126415965, 16.393891174698794],
            [4.199582193697324, 16.746664190304713],
            [4.198891189597703, 16.820827102170185],
            [4.221109100406892, 16.915000131542357],
            [4.251391240283709, 16.994027061834046],
            [4.251109105422103, 17.292009084971667],
            [4.249718212405242, 17.476109039193418],
            [4.248609118973917, 17.648054060310344],
            [4.24666317632736, 17.998054007061896],
            [4.245000206732698, 18.645273223760043],
            [4.245554082896035, 18.660553936205744],
            [4.245836050119664, 18.866391182907265],
            [4.245282173956241, 19.072500170910544],
            [4.245282173956241, 19.146664088604396],
            [4.355836124388077, 19.167218023990628],
            [4.502218184767116, 19.195554053582683],
            [4.886945027023927, 19.269718138914541],
            [5.308609240297329, 19.350827133960891],
            [5.546945137357682, 19.396109024888275],
            [5.656945043987946, 19.416109084111085],
            [5.785282207884421, 19.440273105205009],
            [5.812500091589868, 19.44610908912621],
            [5.963609210096848, 19.57694506600437],
            [5.9816633266731, 19.593326992892386],
            [6.303336128649988, 19.871109132234821],
            [6.473891262578462, 20.017773159837574],
            [6.644718137808667, 20.164445066429252],
            [6.930273151263634, 20.408335986015558],
            [7.463773243244077, 20.856673091648489],
            [7.644027077363944, 20.965691142137999],
            [7.725000117940311, 21.015554078439621],
            [7.916663231056532, 21.132500068250877],
            [8.049445150581278, 21.213335980891046],
            [8.582500163501777, 21.535553941851944],
            [8.716109209234332, 21.61583597832869],
            [8.998609186611333, 21.785000051602296],
            [9.46987314442606, 22.06518209746865],
            [9.742863182071716, 22.22639107405432],
            [10.120282178205429, 22.447773230790446],
            [10.340245111091889, 22.576473163457237],
            [10.616945146731439, 22.737782219967173],
            [10.752782269972244, 22.816391060927273],
            [10.828336074854462, 22.860000091614197],
            [11.100554139215433, 23.01693597394906],
            [11.70750015506178, 23.364164192951009],
            [11.768327123661635, 23.398054071602658],
            [11.986473195210721, 23.522309081003201],
            [12.013336189134776, 23.515827019983433],
            [12.280282204021717, 23.459164180892699],
            [12.339445029560608, 23.446664248652112],
            [12.398891163427606, 23.434164148773547],
            [12.473054075292964, 23.419164230085173],
            [12.59139112575906, 23.393891115559924],
            [12.887782263669465, 23.330000058426592],
            [13.109445046524627, 23.281109087619896],
            [13.345836174404695, 23.229164086563614],
            [13.390273169489632, 23.219718016934635],
            [13.414300230285164, 23.215418100593595],
            [13.467636124358307, 23.197500106125361],
            [13.543891159262216, 23.168609026903383],
            [13.563891218485026, 23.158882163517362],
            [13.595554025456806, 23.139445032913287],
            [13.612782189291721, 23.127500150302623],
            [13.909163268918206, 22.883327095854796],
            [14.235000200404414, 22.6141642352096],
            [14.35666319005972, 22.675827047196591],
            [14.418054093106889, 22.708053956253693],
            [14.495000132110533, 22.747218063674168],
            [14.81833607132009, 22.911109081645449],
            [14.86500013808498, 22.934444970703268],
            [14.957500139578514, 22.980553987838263],
            [14.997891189989303, 23.000591095073204],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "NFK", Country: "Norfolk I." },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [169.431684658000137, -25.930781672],
            [169.439041658000207, -25.933579671999951],
            [169.454625658000083, -25.939580671999977],
            [169.470180658000203, -25.945644671999986],
            [169.485706658000169, -25.951771671999964],
            [169.501203658000151, -25.957960672000013],
            [169.516669658000211, -25.964212672000031],
            [169.532106658000174, -25.970525671999937],
            [169.547512658000159, -25.976901672000011],
            [169.562887658000164, -25.983339671999957],
            [169.578231658000078, -25.989839671999974],
            [169.593544658000184, -25.996401671999976],
            [169.608825658000143, -26.003024672000052],
            [169.624074658000183, -26.009709671999929],
            [169.639291658000076, -26.016455671999964],
            [169.654476658000164, -26.023262671999973],
            [169.669627658000167, -26.030130671999984],
            [169.684745658000139, -26.037060671999967],
            [169.699830658000195, -26.044050672000026],
            [169.714881658000223, -26.051101671999987],
            [169.729898658000167, -26.058213672000022],
            [169.744881658000139, -26.065384671999951],
            [169.759829658000086, -26.072617671999964],
            [169.774742658000122, -26.079909671999971],
            [169.789619658000134, -26.087261671999954],
            [169.804462658000176, -26.09467467199994],
            [169.819268658000141, -26.102146672],
            [169.834038658000196, -26.109677671999975],
            [169.848772658000172, -26.117268672000023],
            [169.863469658000184, -26.124918671999978],
            [169.878129658000177, -26.132628672000024],
            [169.892752658000205, -26.14039667199998],
            [169.907337658000216, -26.148223672000015],
            [169.921885658000093, -26.156109671999957],
            [169.936394658000182, -26.164054672],
            [169.950864658000256, -26.17205667199994],
            [169.965296658000142, -26.18011767199998],
            [169.97968965800024, -26.188236672000016],
            [169.994043658000209, -26.196413671999977],
            [170.008357658000165, -26.204648672000019],
            [170.022832658000226, -26.212831671999936],
            [170.037268658000158, -26.221073671999932],
            [170.051663658000081, -26.229373671999937],
            [170.066018658000218, -26.237731671999953],
            [170.080333658000171, -26.246147672999967],
            [170.094606658000117, -26.254621672999974],
            [170.108838658000224, -26.263153673],
            [170.123028658000209, -26.271743673000017],
            [170.137176658000129, -26.280390672999957],
            [170.151282658000099, -26.289094673],
            [170.165346658000175, -26.297855672999944],
            [170.179367658000132, -26.306674672999989],
            [170.193345659000187, -26.315548672999981],
            [170.207279659000136, -26.324480673000053],
            [170.221170659000194, -26.333468672999956],
            [170.235017659000135, -26.342512672999973],
            [170.248819659000191, -26.351612673],
            [170.26257865900007, -26.360767672999955],
            [170.276291659000123, -26.369979672999932],
            [170.289959659000175, -26.379246673000011],
            [170.30358265900017, -26.388568673000023],
            [170.317160659000166, -26.397945672999967],
            [170.330691659000166, -26.407377672999928],
            [170.34417765900011, -26.416864673],
            [170.357616659000229, -26.426405673000019],
            [170.371008659000182, -26.436001672999964],
            [170.384353659000254, -26.445651672999929],
            [170.397651659000104, -26.455355672999929],
            [170.410902659000129, -26.465113672999962],
            [170.424104659000164, -26.474924673000018],
            [170.437259659000262, -26.484788673000025],
            [170.450365659000198, -26.494706672999968],
            [170.463422659000145, -26.504677672999946],
            [170.476431659000042, -26.51470067299995],
            [170.489391659000063, -26.524776673],
            [170.50230165900021, -26.534905673],
            [170.515161659000199, -26.545086673000029],
            [170.527971659000144, -26.555318672999931],
            [170.540731659000159, -26.565603672999941],
            [170.553441659000185, -26.575939672999922],
            [170.56610065900017, -26.58632667300003],
            [170.578708659000171, -26.596765672999922],
            [170.591264659000245, -26.607254672999943],
            [170.603769659000108, -26.617794672999921],
            [170.616222659000101, -26.628385672999954],
            [170.628623659000056, -26.639026672999947],
            [170.640972659000141, -26.649717672999984],
            [170.65326865900019, -26.66045867299998],
            [170.665511659000202, -26.671249673000034],
            [170.677701659000121, -26.682089672999979],
            [170.689838659000173, -26.692979672999954],
            [170.701921659000192, -26.703917673],
            [170.713950659000176, -26.714904673000021],
            [170.725925659000126, -26.725940673000011],
            [170.737845659000158, -26.737025672999962],
            [170.749711659000099, -26.748157673],
            [170.761522659000235, -26.75933767299999],
            [170.773278659000226, -26.770566672999962],
            [170.784979659000243, -26.781841673],
            [170.796624659000173, -26.793164673000021],
            [170.808213659000245, -26.80453467300002],
            [170.819745659000233, -26.815950673],
            [170.831222659000133, -26.827414672999964],
            [170.842642659000177, -26.838923673000011],
            [170.854005659000137, -26.850479672999953],
            [170.865310659000187, -26.862080672999966],
            [170.876559659000208, -26.873727672999962],
            [170.887750659000204, -26.885420672999956],
            [170.898882659000066, -26.897158673000035],
            [170.909957659000185, -26.908941673],
            [170.920974659000109, -26.920768672999984],
            [170.931932659000182, -26.932640673000037],
            [170.94283165900012, -26.944556673],
            [170.95367165900015, -26.956516672999967],
            [170.964452659000102, -26.968520672999944],
            [170.975173659000205, -26.980568673],
            [170.98583565900023, -26.992658672999966],
            [170.996436659000238, -27.00479267299994],
            [171.006978659000112, -27.016968673000022],
            [171.017459659000252, -27.02918767300001],
            [171.027879659000149, -27.041449672999917],
            [171.038239659000254, -27.05375267300002],
            [171.048537659000232, -27.066097672999959],
            [171.058774659000079, -27.078484673],
            [171.06894965900014, -27.090912672999949],
            [171.079063659000298, -27.10338167299993],
            [171.089114659000046, -27.115891673000021],
            [171.099104659000119, -27.128441672999955],
            [171.109031659000067, -27.141032673],
            [171.118895659000174, -27.153663672999969],
            [171.128696659000212, -27.166333672999968],
            [171.138434659000183, -27.179043673],
            [171.148109659000255, -27.191793672999964],
            [171.157720659000205, -27.204581672999964],
            [171.167268659000257, -27.21740867299998],
            [171.176752659000186, -27.230274672999954],
            [171.186171659000053, -27.24317867299996],
            [171.195526659000137, -27.256119673],
            [171.204816659000159, -27.269099672999985],
            [171.214042659000057, -27.282116673],
            [171.223203659000234, -27.295170672999987],
            [171.232298659000122, -27.308261673],
            [171.241328659000118, -27.321389673999988],
            [171.250292659000166, -27.334553674],
            [171.259190659000268, -27.347753673999989],
            [171.268023660000068, -27.360989673999924],
            [171.276724660000042, -27.374160674],
            [171.28536166, -27.38736467400004],
            [171.293932660000252, -27.40060467399995],
            [171.302437660000152, -27.413877673999934],
            [171.310875660000221, -27.427185673999958],
            [171.319248660000227, -27.440527673999966],
            [171.32755466, -27.453901673999951],
            [171.335794660000062, -27.467310674],
            [171.343967660000175, -27.480751674000018],
            [171.352073660000116, -27.494224673999938],
            [171.360112660000055, -27.507730674000015],
            [171.368083660000167, -27.521269673999981],
            [171.375987660000106, -27.534839674000025],
            [171.383823660000104, -27.548440673999963],
            [171.391592660000043, -27.562073673999976],
            [171.399292660000157, -27.575737673999981],
            [171.406924660000271, -27.589432673999966],
            [171.41448866000016, -27.603157673999959],
            [171.421983660000166, -27.616913673999932],
            [171.429409660000175, -27.630698674],
            [171.436766660000131, -27.644513673999967],
            [171.444054660000205, -27.658358673999942],
            [171.451273660000169, -27.672231674],
            [171.458423660000193, -27.686134673999973],
            [171.465502660000112, -27.700065674000044],
            [171.472512660000092, -27.714024674000029],
            [171.479453660000132, -27.728011674000015],
            [171.486322660000013, -27.742026673999931],
            [171.493122660000068, -27.756068673999948],
            [171.499851660000303, -27.770138673999966],
            [171.506510660000259, -27.784234674],
            [171.513098660000168, -27.798357673999973],
            [171.519614660000201, -27.812506673999962],
            [171.526060660000127, -27.826681673999957],
            [171.532435660000175, -27.840882673999985],
            [171.538738660000178, -27.85510867399995],
            [171.544969660000191, -27.869360674000021],
            [171.5511296600001, -27.883636673999945],
            [171.557217660000191, -27.897937673999976],
            [171.563233660000066, -27.912262673999962],
            [171.569177660000179, -27.926611673999972],
            [171.575048660000078, -27.940984673999935],
            [171.580847660000103, -27.955380674000011],
            [171.586573660000141, -27.969799673999958],
            [171.592227660000077, -27.984242674000029],
            [171.597807660000086, -27.998706673999962],
            [171.603315660000163, -28.013193674000021],
            [171.608750660000254, -28.027702673999954],
            [171.614111660000134, -28.042232673999933],
            [171.619447660000191, -28.056891673999928],
            [171.624709660000207, -28.071571673999969],
            [171.629896660000185, -28.086272673999972],
            [171.635008660000011, -28.100994673999949],
            [171.640044660000143, -28.115736673999962],
            [171.645006660000178, -28.13049967399995],
            [171.649892660000177, -28.145281674],
            [171.654703660000308, -28.160083674],
            [171.659438660000234, -28.174905673999987],
            [171.664097660000238, -28.189745673999951],
            [171.66868166000009, -28.204604673999981],
            [171.673188660000136, -28.219481673999979],
            [171.677620660000201, -28.234377674000044],
            [171.68197566000012, -28.249290674],
            [171.686254660000174, -28.264220673999944],
            [171.690456660000194, -28.279168673999962],
            [171.694581660000068, -28.294132673999968],
            [171.698630660000248, -28.30911367399996],
            [171.70260266000011, -28.324111673999937],
            [171.706497660000167, -28.339124674],
            [171.710315660000191, -28.354152673999977],
            [171.714056660000125, -28.369196674000023],
            [171.717720660000083, -28.384255673999988],
            [171.721306660000124, -28.39932867499995],
            [171.724814660000135, -28.414416675000012],
            [171.728245660000113, -28.429518674999969],
            [171.731599660000171, -28.444633675000034],
            [171.734874660000088, -28.459762675000022],
            [171.738072660000199, -28.474904675],
            [171.741191660000169, -28.490058674999929],
            [171.744233660000106, -28.505225675000034],
            [171.747196660000128, -28.52040467499998],
            [171.75008166000012, -28.535595675000025],
            [171.752887660000141, -28.550798675],
            [171.755615660000245, -28.566011675],
            [171.758264660000208, -28.581236675000014],
            [171.76083566000014, -28.596471674999975],
            [171.763327660000101, -28.611716674999968],
            [171.765740660000262, -28.626971674999979],
            [171.76807466000011, -28.642235674999938],
            [171.770329660000158, -28.657509675000014],
            [171.772505660000064, -28.672792675000039],
            [171.774602660000056, -28.688083675],
            [171.776620660000191, -28.703383674999927],
            [171.77855866000013, -28.718690674999976],
            [171.780417660000154, -28.734006674999975],
            [171.782197660000151, -28.749328675000029],
            [171.783896660000238, -28.764658675000035],
            [171.785517660000238, -28.779994675],
            [171.787057660000272, -28.795337674999914],
            [171.78851866, -28.810685674999988],
            [171.789899660000088, -28.826039674999933],
            [171.791200660000044, -28.841399674999934],
            [171.792421660000201, -28.856764675],
            [171.793562660000106, -28.872134675000012],
            [171.794623660000155, -28.887507674999938],
            [171.795604660000123, -28.902885675],
            [171.796505660000236, -28.918267675000038],
            [171.797325660000212, -28.933653674999974],
            [171.798065660000162, -28.949041674999975],
            [171.798725660000088, -28.964432674999955],
            [171.799305660000101, -28.979826675000012],
            [171.799804660000149, -28.99522267499998],
            [171.800222660000117, -29.01061967499993],
            [171.800560660000173, -29.026018674999946],
            [171.800818660000203, -29.041419674999972],
            [171.800994660000214, -29.056820674999983],
            [171.801091660000253, -29.072221675],
            [171.801106660000102, -29.087623675],
            [171.801041660000209, -29.103025675000012],
            [171.80089566, -29.118426675000023],
            [171.800668660000241, -29.133826674999966],
            [171.800360660000166, -29.14922667499998],
            [171.799972660000236, -29.164624675000013],
            [171.799502660000059, -29.180020674999966],
            [171.798952660000197, -29.195414674999938],
            [171.798321660000198, -29.210805675000017],
            [171.79760966000012, -29.226194674999931],
            [171.796815660000135, -29.241580675000037],
            [171.795941660000125, -29.256962674999983],
            [171.794986660000149, -29.27234167499995],
            [171.794005660000181, -29.287786674999964],
            [171.792942660000136, -29.303226675000019],
            [171.791798660000183, -29.318662675],
            [171.790572660000151, -29.334093675],
            [171.789264660000271, -29.349520674999951],
            [171.787874660000199, -29.364940675000028],
            [171.786402660000107, -29.380355675000047],
            [171.784867660000117, -29.395593674999986],
            [171.783252660000215, -29.410824674999972],
            [171.781557660000118, -29.426049674999987],
            [171.779781660000111, -29.441267674999963],
            [171.777926660000247, -29.456477674999974],
            [171.775991660000017, -29.471680675999949],
            [171.773976660000102, -29.486875675999983],
            [171.771881660000162, -29.502061675999968],
            [171.769705660000199, -29.517239676],
            [171.767450660000321, -29.532408675999918],
            [171.765115660000248, -29.547568675999983],
            [171.762700660000093, -29.562718676],
            [171.760205660000253, -29.577859676],
            [171.75763066000016, -29.592989675999974],
            [171.754975660000042, -29.608108676000015],
            [171.75224066000024, -29.623217676000039],
            [171.749425660000185, -29.638314675999936],
            [171.746531660000215, -29.653400675999919],
            [171.743556660000053, -29.668475675999957],
            [171.740502660000146, -29.683537675999986],
            [171.737368660000044, -29.69858667599992],
            [171.734155660000255, -29.713623675999926],
            [171.730861660000215, -29.728647676000023],
            [171.727488660000205, -29.743657676000012],
            [171.724036660000053, -29.758654676],
            [171.720503660000276, -29.773636675999981],
            [171.716892660000127, -29.788604675999963],
            [171.713200660000183, -29.803558676000023],
            [171.709430660000152, -29.818496675999924],
            [171.705579660000041, -29.833419676],
            [171.701650660000183, -29.848326676],
            [171.69764166000013, -29.863218676],
            [171.693553660000106, -29.878093675999921],
            [171.689385660000227, -29.89295267599995],
            [171.68513966000026, -29.907793676],
            [171.680813660000098, -29.922618675999956],
            [171.676408660000135, -29.937424676000035],
            [171.671925660000085, -29.952213675999943],
            [171.667362660000236, -29.966984675999967],
            [171.66272066000019, -29.981736676000025],
            [171.658000660000113, -29.996469676000018],
            [171.653201660000065, -30.011184676000028],
            [171.648323660000216, -30.025878675999977],
            [171.643462660000154, -30.040385676000014],
            [171.638524660000172, -30.054872675999988],
            [171.633510660000098, -30.069339676],
            [171.628419660000219, -30.083786675999946],
            [171.623251660000136, -30.098213675999929],
            [171.618007660000245, -30.112619675999952],
            [171.612687660000262, -30.127004676000013],
            [171.607290660000245, -30.141368676000027],
            [171.601818660000134, -30.155710675999984],
            [171.596524660000313, -30.170532675999965],
            [171.591149660000013, -30.185331676],
            [171.585693660000146, -30.200109675999983],
            [171.580157660000026, -30.214864675999934],
            [171.574541660000051, -30.229596676000014],
            [171.568844660000167, -30.244306675999965],
            [171.5630676600002, -30.258992675999977],
            [171.557210660000322, -30.273654676000035],
            [171.551273660000248, -30.288292675999983],
            [171.545256660000149, -30.302906675999978],
            [171.539159660000195, -30.317495675999965],
            [171.532982660000158, -30.332060676],
            [171.526725660000267, -30.346598675999928],
            [171.520389660000177, -30.361111676],
            [171.51397466, -30.375599675999979],
            [171.507479660000143, -30.390059676000021],
            [171.500905660000143, -30.404494675999956],
            [171.494252660000171, -30.418901675999976],
            [171.487520660000229, -30.433281675999979],
            [171.480709660000144, -30.447633675999967],
            [171.47381966000026, -30.461957675999955],
            [171.466850660000233, -30.47625467599994],
            [171.459803660000176, -30.490521676],
            [171.452678660000203, -30.504760675999975],
            [171.445474660000144, -30.51896967600004],
            [171.438192660000169, -30.533149675999923],
            [171.430832660000277, -30.547299677],
            [171.423394660000184, -30.561419676999975],
            [171.415878660000232, -30.575509676999971],
            [171.408285660000075, -30.58956867699996],
            [171.400614660000116, -30.603595676999987],
            [171.392866660000124, -30.617591677],
            [171.385041660000155, -30.631556676999963],
            [171.377138660000099, -30.645488676999932],
            [171.36915966, -30.65938867700001],
            [171.361102660000228, -30.673255676999929],
            [171.353033660000136, -30.686983676999986],
            [171.344889660000121, -30.700677676999987],
            [171.336670660000237, -30.714339677],
            [171.328375660000262, -30.727967677000038],
            [171.320006660000246, -30.741561676999936],
            [171.311562660000021, -30.755122677000031],
            [171.303043660000213, -30.768648676999987],
            [171.294450660000138, -30.782139676999975],
            [171.285783660000135, -30.795596677],
            [171.277041660000151, -30.809017676999957],
            [171.268226660000181, -30.822403676999969],
            [171.259337659000181, -30.835753677000014],
            [171.250374659000016, -30.849067677],
            [171.241338659000263, -30.862345676999951],
            [171.232229659000069, -30.875586676999959],
            [171.223047659000116, -30.888790677],
            [171.213791659000236, -30.901956676999916],
            [171.204463659000254, -30.915086676999962],
            [171.201188452800153, -30.919787476999986],
            [170.766820556000113, -31.183300212999939],
            [170.583487222000059, -31.299966927],
            [170.5501538890002, -31.316633598999928],
            [168.6777900700001, -32.417455238999977],
            [168.66776949200019, -32.419110480999976],
            [168.665384242500153, -32.419504398999976],
            [168.647455657000052, -32.422390677999957],
            [168.629499657000196, -32.425205677999983],
            [168.611527657000266, -32.427946677999941],
            [168.593540657000148, -32.430615677999967],
            [168.575536657000129, -32.433210678],
            [168.557518657000145, -32.435731677999968],
            [168.539486657000197, -32.438180678],
            [168.521439657000116, -32.440555677999953],
            [168.503378657000184, -32.442856678000012],
            [168.485304657000171, -32.445084677999972],
            [168.467217657000134, -32.447239678000017],
            [168.449118657000241, -32.449319677999981],
            [168.431007657000265, -32.451326677999958],
            [168.412885657000203, -32.453260677999921],
            [168.394751657000171, -32.455119678],
            [168.376606657000053, -32.456905677999984],
            [168.358452657000072, -32.45861667799997],
            [168.340287657000232, -32.46025467799997],
            [168.322113657000131, -32.461818677999958],
            [168.303930657000052, -32.463308677999962],
            [168.285739657000107, -32.464723677999984],
            [168.267540657000126, -32.466065678],
            [168.249332657000224, -32.467333677999932],
            [168.231118657000167, -32.468526677999961],
            [168.212897657000241, -32.469645678],
            [168.19467065700016, -32.470691678000037],
            [168.17643665700021, -32.471661678],
            [168.158197657000215, -32.47255867799997],
            [168.139953657000177, -32.473380677999955],
            [168.121705657000149, -32.474129677999926],
            [168.103452657000133, -32.474802678000017],
            [168.085196657000239, -32.475402678],
            [168.066936657000184, -32.475927678000019],
            [168.048673657000251, -32.476378677999932],
            [168.030408656000219, -32.476754677999963],
            [168.012141656000125, -32.477056678],
            [167.993873656000147, -32.477284677999933],
            [167.975603656000231, -32.477438677999984],
            [167.957333656000088, -32.477517678000027],
            [167.939062656000175, -32.477521678],
            [167.920792656000202, -32.477451678],
            [167.902522656000116, -32.477307677999974],
            [167.884253656000141, -32.47708967799997],
            [167.865986656000047, -32.476796677999971],
            [167.847721656000118, -32.476429677999988],
            [167.829458656000071, -32.47598767800001],
            [167.81119865600013, -32.475471678000034],
            [167.792941656000067, -32.474881677999974],
            [167.774688656000222, -32.474216677999934],
            [167.756439656000197, -32.473477677999981],
            [167.738194656000161, -32.472664677999944],
            [167.719955656000167, -32.471777678],
            [167.701721656000046, -32.470815678],
            [167.683492656000254, -32.469779677999981],
            [167.665271656000215, -32.468669677999983],
            [167.647055656000106, -32.467485677999989],
            [167.62884765600009, -32.466226678],
            [167.610647656000111, -32.464894678],
            [167.592454656000115, -32.463487678000035],
            [167.574270656000209, -32.46200667799998],
            [167.55609565600011, -32.46045267800001],
            [167.5379306560001, -32.458823677999959],
            [167.519774656000124, -32.457121678],
            [167.501628656000179, -32.45534467799996],
            [167.483493656000093, -32.453494678000013],
            [167.465369656000263, -32.451570677999982],
            [167.447256656000121, -32.449572678000052],
            [167.429156656000174, -32.447500678000026],
            [167.411067656000256, -32.445355678000013],
            [167.392992656000132, -32.443136677999988],
            [167.374930656000146, -32.440844677999976],
            [167.356881656000127, -32.438478677999967],
            [167.33884765600024, -32.436039677999958],
            [167.320827656000148, -32.43352667799995],
            [167.302822656000245, -32.430940677999956],
            [167.284832656000134, -32.428281677999948],
            [167.26685865600021, -32.425548678000041],
            [167.248900656000188, -32.422742677999949],
            [167.230959656000181, -32.419864677999939],
            [167.21303565600013, -32.416912678000031],
            [167.195129656000148, -32.413887678000023],
            [167.177240656000237, -32.410790678000026],
            [167.15937065600022, -32.407619677999932],
            [167.141518656000102, -32.404376678],
            [167.123686656000103, -32.401061678],
            [167.105874656000168, -32.397673677999975],
            [167.088081656000128, -32.39421267799996],
            [167.070309656000148, -32.390679678000026],
            [167.052558656000116, -32.387074678],
            [167.034828656000201, -32.383396677999968],
            [167.017119656000119, -32.379646678000029],
            [166.999433656000207, -32.375825677999984],
            [166.98177065600018, -32.371931678000024],
            [166.964413655000072, -32.368030678000011],
            [166.947080655000065, -32.364060677999987],
            [166.929769655000115, -32.36002167800001],
            [166.912482655000105, -32.355912678000024],
            [166.895218655000207, -32.351734678000014],
            [166.877979655000189, -32.347487677999979],
            [166.860764655000168, -32.343170678000021],
            [166.843575655000194, -32.338785678000022],
            [166.826411655000157, -32.334332678],
            [166.809272655000115, -32.329809677999947],
            [166.792160655000117, -32.32521867799997],
            [166.775074655000111, -32.320559677999952],
            [166.75801565500015, -32.315831678],
            [166.740984655000176, -32.311034677999928],
            [166.723980655000133, -32.306170678],
            [166.707005655000188, -32.301238677999947],
            [166.690058655000058, -32.296237677999969],
            [166.673139655000142, -32.291169677999946],
            [166.656250655000264, -32.286033678],
            [166.639391655000139, -32.280830677999987],
            [166.62256265500011, -32.275559677999965],
            [166.605763655000061, -32.270221677999984],
            [166.588995655000105, -32.264816677999974],
            [166.572258655000127, -32.259343677999922],
            [166.555553655000125, -32.253804677999923],
            [166.538879655000216, -32.24819867799998],
            [166.522238655000166, -32.242525678],
            [166.505629655000149, -32.236785677999976],
            [166.48905465500016, -32.230979678],
            [166.472512655000145, -32.225107677999986],
            [166.4560046550001, -32.219168678000017],
            [166.439530655000141, -32.213164678],
            [166.423090655000209, -32.207093678000035],
            [166.406686655000073, -32.200957677999938],
            [166.390316655000248, -32.194755677999979],
            [166.373983655000217, -32.188488677999956],
            [166.357685655000154, -32.182155677999987],
            [166.341424655000225, -32.175758677999966],
            [166.325199655000148, -32.169295677999983],
            [166.309012655000203, -32.162767677999952],
            [166.292862655000107, -32.156174677999971],
            [166.276874655000114, -32.149570677999961],
            [166.260925655000136, -32.142902677999984],
            [166.245013655000236, -32.136171678000039],
            [166.229140655000066, -32.129376678000028],
            [166.213305655000198, -32.122519677999961],
            [166.197509655000175, -32.115599678],
            [166.181753655000108, -32.10861667799999],
            [166.166036655000227, -32.101571678],
            [166.15035965500013, -32.09446367799994],
            [166.134723655000101, -32.087293678],
            [166.119127655000199, -32.080061677999979],
            [166.103573655000247, -32.072767678],
            [166.088059655000194, -32.065411678000018],
            [166.07258865500026, -32.057994677999972],
            [166.057158655000165, -32.050516677999951],
            [166.041771655000133, -32.042976677999945],
            [166.026426655000051, -32.035375678000037],
            [166.011125655000143, -32.027713677999984],
            [165.995867655000239, -32.019991677999926],
            [165.980653655000225, -32.012208677999979],
            [165.965482655000159, -32.004365677999942],
            [165.950356655000149, -31.996461677999932],
            [165.93527565500014, -31.988498677999914],
            [165.920239655000131, -31.980475678],
            [165.905248655000122, -31.97239267800002],
            [165.890303654000178, -31.964249677999945],
            [165.87540465400005, -31.956048677999959],
            [165.860551654000261, -31.947787677999983],
            [165.845744654000185, -31.939467678000014],
            [165.830985654000159, -31.931089678000035],
            [165.816273654000128, -31.922652677999977],
            [165.801608654000262, -31.914157678],
            [165.786992654000102, -31.90560367800002],
            [165.772423654000107, -31.896992677999961],
            [165.757684654000144, -31.888192677999967],
            [165.742996654000223, -31.879333677999981],
            [165.728358654000118, -31.870414678000017],
            [165.713771654000226, -31.861437677999945],
            [165.699236654000089, -31.852401677999978],
            [165.684752654000107, -31.843307678],
            [165.670320654000221, -31.834154677999933],
            [165.667550654000109, -31.832387678000018],
            [165.655940654000148, -31.824943677999954],
            [165.64161265400017, -31.815674677999979],
            [165.627338654000056, -31.806348678],
            [165.613116654000265, -31.796964678],
            [165.598948654000111, -31.787523678],
            [165.584834654000105, -31.778025678],
            [165.570774654000189, -31.768470677999986],
            [165.556768654000138, -31.758859677999951],
            [165.542817654000174, -31.749191678],
            [165.528921654000186, -31.739467678000025],
            [165.515080654000172, -31.729687677999962],
            [165.501295654000131, -31.719851677999969],
            [165.487566654000062, -31.709960677999945],
            [165.473893654000193, -31.700014678],
            [165.460276654000182, -31.690013678000028],
            [165.446716654000085, -31.679956677999954],
            [165.433213654000184, -31.669846677999928],
            [165.419767654000253, -31.659681677999984],
            [165.406380654000174, -31.649462678],
            [165.39304965400018, -31.639189678],
            [165.379778654000205, -31.628862677999962],
            [165.366564654000143, -31.618482678],
            [165.353409654000217, -31.608049676999983],
            [165.340314654000139, -31.597563677000025],
            [165.327277654000255, -31.587024676999945],
            [165.314301654000047, -31.576433677000011],
            [165.301384654000145, -31.565790676999953],
            [165.288527654000205, -31.555094676999943],
            [165.275730654000171, -31.544347676999976],
            [165.262995654000207, -31.533549676999954],
            [165.250320654000149, -31.522699677],
            [165.237706654000164, -31.511799676999985],
            [165.225154654000136, -31.500847677000024],
            [165.212664654000122, -31.489845677],
            [165.200235654000068, -31.478793676999942],
            [165.187869654000252, -31.467691677],
            [165.175565654000167, -31.456539677],
            [165.163324654000206, -31.445338676999953],
            [165.151146654000144, -31.434087677000036],
            [165.139032654000147, -31.422788676999957],
            [165.126980654000107, -31.411439677],
            [165.114993654000131, -31.400043676999985],
            [165.103070654000106, -31.388598677],
            [165.091210654000093, -31.377105677000031],
            [165.079416654000084, -31.365564677],
            [165.067686654000141, -31.353977677],
            [165.056021654000148, -31.342341677000022],
            [165.044421654000217, -31.330659676999957],
            [165.032887654000234, -31.318931677],
            [165.021418654000144, -31.307156676999981],
            [165.010015654000171, -31.295335676999969],
            [164.998679654000142, -31.283468676999973],
            [164.987409654000231, -31.271555676999981],
            [164.976205654000211, -31.259598677],
            [164.965068654000191, -31.247595676999936],
            [164.953998654000173, -31.235547676999971],
            [164.942996654000154, -31.223455677],
            [164.93206165400025, -31.211319676999963],
            [164.921194654000118, -31.199139677],
            [164.910394654000044, -31.186915676999945],
            [164.899663654000079, -31.174647676999982],
            [164.889000654000114, -31.162337677000011],
            [164.878406654000202, -31.149984676999949],
            [164.867881654000115, -31.137588676999968],
            [164.857424654000084, -31.125150676999979],
            [164.847037654000161, -31.112670676999969],
            [164.836719654000063, -31.100148676999964],
            [164.826471654000187, -31.087584676999938],
            [164.816292653000147, -31.074980676999971],
            [164.806253653000141, -31.062423677],
            [164.796284653000129, -31.049827676999982],
            [164.786384653000169, -31.037190676999955],
            [164.77655365300015, -31.024514676999985],
            [164.766792653000067, -31.011799677],
            [164.757102653000146, -30.99904567699997],
            [164.747481653000165, -30.986252677],
            [164.737931653000118, -30.973421677],
            [164.728451653000178, -30.960552676999967],
            [164.719042653000173, -30.947645676999983],
            [164.709703653000162, -30.93470167699995],
            [164.700436653000196, -30.921719676999984],
            [164.691240653000108, -30.90870067699997],
            [164.682116653000236, -30.89564567699999],
            [164.673063653000185, -30.882553676999976],
            [164.664081653000068, -30.86942567700001],
            [164.655172653000108, -30.856261676999978],
            [164.646335653000193, -30.843062677],
            [164.637569653000156, -30.829828676999952],
            [164.628877653000046, -30.816558676999961],
            [164.620256653000212, -30.803254676999984],
            [164.611709653000133, -30.789916676999965],
            [164.603234653000214, -30.776544676999961],
            [164.594833653000109, -30.763137676999989],
            [164.586504653000105, -30.749698676999955],
            [164.578249653000086, -30.73622567699995],
            [164.570067653000166, -30.722719676999958],
            [164.561959653000059, -30.709180677],
            [164.553925653000107, -30.695610676999962],
            [164.545956653000218, -30.681996677000015],
            [164.538061653000256, -30.668351676999976],
            [164.530240653000163, -30.654674676999974],
            [164.522494653000166, -30.640965676999969],
            [164.514822653000266, -30.627226676999982],
            [164.507225653000233, -30.613457677],
            [164.499703653000125, -30.599657677000025],
            [164.492256653000169, -30.585827676999969],
            [164.484884653000137, -30.571967677],
            [164.477587653000256, -30.558078677000026],
            [164.470366653000184, -30.544160676999979],
            [164.463220653000263, -30.530213676],
            [164.456149653000153, -30.516238675999944],
            [164.449154653000136, -30.502235675999955],
            [164.442236653000151, -30.488203675999969],
            [164.435393653000261, -30.474144675999966],
            [164.42862665300018, -30.46005867599996],
            [164.421935653000247, -30.445945676000022],
            [164.41532165300012, -30.431805675999982],
            [164.408783653000256, -30.417639676],
            [164.402321653000087, -30.40344667599993],
            [164.395936653000177, -30.389228676000016],
            [164.38962865300013, -30.37498567599998],
            [164.383397653000173, -30.36071667600001],
            [164.377242653000195, -30.346423676000015],
            [164.371165653000077, -30.332105675999983],
            [164.365165653000219, -30.317763676000013],
            [164.359241653000169, -30.303397676000017],
            [164.353396653000146, -30.289007675999983],
            [164.347627653000046, -30.274594675999921],
            [164.34193665300009, -30.260158675999989],
            [164.336323653000164, -30.245700676000013],
            [164.330787653000158, -30.231219676],
            [164.325329653000125, -30.216716676000047],
            [164.319949653000123, -30.202191676000027],
            [164.314647653000208, -30.187645675999974],
            [164.309423653000152, -30.173078675999946],
            [164.304276653000244, -30.158490675999971],
            [164.299208653000136, -30.143882676000018],
            [164.294218653000172, -30.129253676000019],
            [164.289307653000179, -30.114605675999954],
            [164.284473653000163, -30.099937676000025],
            [164.279719653000114, -30.08525067599993],
            [164.274565653000167, -30.070566676],
            [164.26949065300019, -30.055862676],
            [164.264495653000182, -30.04113867599996],
            [164.259579653000202, -30.026394675999953],
            [164.254742653000193, -30.011630675999982],
            [164.249984653000155, -29.996846676000033],
            [164.245306653000085, -29.98204467599993],
            [164.240707653000158, -29.967223675999946],
            [164.236188653000255, -29.952383675999982],
            [164.231748653000153, -29.93752567599995],
            [164.22738865300019, -29.922650675999932],
            [164.223107653000085, -29.907757675999946],
            [164.218907653000116, -29.89284767599996],
            [164.214786653000118, -29.877920676],
            [164.210745653000259, -29.862977675999957],
            [164.206784653000256, -29.848018676],
            [164.20290365300005, -29.833042676],
            [164.19910265300021, -29.818051675999968],
            [164.195381653000112, -29.803045675999954],
            [164.191740653000096, -29.788025676000018],
            [164.18818065300016, -29.772989676],
            [164.184699653000195, -29.757939676],
            [164.181299653000252, -29.742876676000051],
            [164.177979653000165, -29.727799676000018],
            [164.174739653000216, -29.712708676],
            [164.171580653000234, -29.697605676000023],
            [164.16850165300005, -29.68248967599996],
            [164.165502653000232, -29.667361675999985],
            [164.162584653000209, -29.652221675999982],
            [164.159746653000212, -29.637069675999953],
            [164.156989653000181, -29.621906676],
            [164.154312653000119, -29.606732676000036],
            [164.15171665300025, -29.591547675999948],
            [164.149200653000179, -29.576352675999928],
            [164.146764653000247, -29.561147675999962],
            [164.144410653000165, -29.545932675999978],
            [164.142136653000222, -29.530708675999946],
            [164.139942653000134, -29.51547567599998],
            [164.137829653000182, -29.500233675999979],
            [164.135797653000196, -29.484983676000013],
            [164.133845653000236, -29.469724675999942],
            [164.131974653000071, -29.454458675],
            [164.1301846530001, -29.439185675],
            [164.12847465300004, -29.42390467499996],
            [164.126845653000061, -29.408617674999974],
            [164.125296653000163, -29.393323675000019],
            [164.123828653000118, -29.378023675],
            [164.122441653000266, -29.362717674999942],
            [164.121134653000041, -29.347406675],
            [164.119908653000124, -29.332090675],
            [164.118762653000175, -29.316769674999946],
            [164.117697653000079, -29.301443675000016],
            [164.116744653000097, -29.286631674999967],
            [164.115866653000097, -29.271816675000039],
            [164.115063653000249, -29.256997675000036],
            [164.114336653000152, -29.24217567499997],
            [164.113683653000209, -29.227351675],
            [164.113106653000074, -29.212524674999955],
            [164.112603653000207, -29.19769567500002],
            [164.112176653000148, -29.182864675],
            [164.111824653000241, -29.168032675],
            [164.111547653000144, -29.153198675],
            [164.1113456530002, -29.138364675],
            [164.111218653000179, -29.123528674999932],
            [164.11116665300014, -29.108693675000026],
            [164.111189653000196, -29.093857675000038],
            [164.111286653000121, -29.079021674999964],
            [164.111459653000139, -29.064186674999974],
            [164.111488653000066, -29.048659675000025],
            [164.111599653000184, -29.033131674999979],
            [164.111793653000149, -29.017605675000013],
            [164.11206865300008, -29.002079674999962],
            [164.112425653000145, -28.986554674999979],
            [164.11286465300023, -28.971031675],
            [164.113385653000165, -28.955510675],
            [164.113988653000234, -28.939992674999985],
            [164.114672653000156, -28.924475674999968],
            [164.115438653000211, -28.908962674999927],
            [164.116285653000119, -28.893452674999949],
            [164.117214653000161, -28.877946675000047],
            [164.118225653000223, -28.862443674999938],
            [164.119317653000081, -28.846945674999972],
            [164.120490653000132, -28.831451675],
            [164.12174465300015, -28.815963674999978],
            [164.12308065300013, -28.80047967500002],
            [164.124497653000134, -28.785001674999947],
            [164.12599565300016, -28.769529675],
            [164.127574653000153, -28.754063674999941],
            [164.129234653000111, -28.738604675],
            [164.130974653000209, -28.723152674999952],
            [164.132796653000042, -28.707707674999938],
            [164.134699653000126, -28.692269674999963],
            [164.136682653000236, -28.676840674999951],
            [164.138745653000143, -28.661418674999965],
            [164.140889653000073, -28.646005675000026],
            [164.14311465300014, -28.630601675000037],
            [164.145419653000175, -28.615206674999982],
            [164.147804653000236, -28.599821674999959],
            [164.150269653000265, -28.584446675000052],
            [164.152815653000147, -28.569080675],
            [164.155440653000113, -28.553726674999965],
            [164.158146653000159, -28.538382675000051],
            [164.160931653000119, -28.523049674999982],
            [164.163796653000219, -28.50772867500001],
            [164.166740653000176, -28.492418674999968],
            [164.169764653000101, -28.477121675000035],
            [164.172868653000052, -28.461836675000029],
            [164.176051653000144, -28.446564674999934],
            [164.17931365300015, -28.431305675000033],
            [164.182654653000185, -28.416060675000054],
            [164.186074653000247, -28.400828674999985],
            [164.189573653000053, -28.38561067400002],
            [164.193151653000228, -28.370407674000035],
            [164.196808653000204, -28.355218673999985],
            [164.200543653000153, -28.340045674],
            [164.204357653000244, -28.324887674000024],
            [164.208249653000138, -28.309744673999944],
            [164.212220653000173, -28.294618673999949],
            [164.216268653000071, -28.279508673999942],
            [164.220395653000224, -28.264414674],
            [164.22459965300007, -28.249338673999972],
            [164.22888265300017, -28.234278673999938],
            [164.233241653000135, -28.219237673999956],
            [164.237679653000242, -28.204213673999959],
            [164.242194653000155, -28.189207673999931],
            [164.246786653000157, -28.174220673999983],
            [164.251455653000193, -28.159252674],
            [164.256202653000202, -28.144303673999985],
            [164.261025653000132, -28.12937367399995],
            [164.265925653000096, -28.114464673999976],
            [164.270901653000152, -28.099574673999953],
            [164.275954653000241, -28.084705674],
            [164.281084653000249, -28.069856674],
            [164.286290653000123, -28.055029673999968],
            [164.291571653000204, -28.040223673999975],
            [164.296929653000149, -28.025439674000026],
            [164.302362653000074, -28.010676673999953],
            [164.30787265300026, -27.995936674],
            [164.313456653000145, -27.981219673999917],
            [164.319116653000123, -27.96652467399997],
            [164.324851653000252, -27.951853673999963],
            [164.330662653000132, -27.937205673999983],
            [164.336547653000054, -27.922581673999957],
            [164.34250765300024, -27.907981673999956],
            [164.348541653000183, -27.893405674],
            [164.354650653000164, -27.878854673999967],
            [164.360834653000239, -27.864329674000047],
            [164.367091653000244, -27.84982867399998],
            [164.373422653000119, -27.835354674000016],
            [164.37982865300026, -27.820905673999988],
            [164.386307653000159, -27.80648267399998],
            [164.392859653000215, -27.792086673999975],
            [164.399485653000085, -27.777717674],
            [164.406183653000227, -27.763375674000031],
            [164.412955653000125, -27.749061673999989],
            [164.419800653000181, -27.734774673999951],
            [164.426717653000225, -27.720515674000012],
            [164.433578653000183, -27.706389673999979],
            [164.440510653000075, -27.692291673999947],
            [164.447513653000243, -27.678221673999928],
            [164.454587653000232, -27.664179674],
            [164.461731653000157, -27.650166674],
            [164.468946653000188, -27.636182673999969],
            [164.476231653000156, -27.622228673999956],
            [164.483587653000114, -27.608303673999941],
            [164.491012653000183, -27.594408674],
            [164.498507653000132, -27.580543673999983],
            [164.506072653000075, -27.566708674000054],
            [164.513706653000128, -27.552904673999919],
            [164.52140965300012, -27.539131673999961],
            [164.529181653000052, -27.525389673999982],
            [164.537022653000264, -27.511679674],
            [164.544932653000131, -27.498001674],
            [164.552910653000168, -27.484355673999971],
            [164.560957653000202, -27.470741674000024],
            [164.569072653000177, -27.457160673999951],
            [164.577254653000097, -27.443611673999968],
            [164.585505653000126, -27.43009667400004],
            [164.593823653000101, -27.416615673999985],
            [164.602208653000133, -27.403167674],
            [164.610661653000108, -27.389753674],
            [164.619263653000218, -27.376243674000037],
            [164.627933653000099, -27.362768673999952],
            [164.636671653000207, -27.349329674],
            [164.645477653000142, -27.335925674],
            [164.654350653000137, -27.322558673999978],
            [164.663290653000246, -27.309227673],
            [164.672298653000126, -27.295932672999982],
            [164.681372653000125, -27.282675672999986],
            [164.690513653000181, -27.269454672999956],
            [164.699721653000182, -27.256271672999958],
            [164.70899565300013, -27.243126673],
            [164.718334653000198, -27.230019672999987],
            [164.727740653000154, -27.216950672999928],
            [164.73721165300006, -27.203919672999987],
            [164.746747653000199, -27.190928672999974],
            [164.756349653000115, -27.177975672999921],
            [164.76601665300015, -27.165062672999966],
            [164.775747653000082, -27.152188672999969],
            [164.785543653000133, -27.139354672999985],
            [164.79540365300025, -27.126560673000014],
            [164.805327653000148, -27.113807672999982],
            [164.815315653000113, -27.101094672999977],
            [164.825366654000135, -27.088422672999982],
            [164.835481654000233, -27.075792673],
            [164.836313654000122, -27.074758673],
            [164.845659654000173, -27.063202672999964],
            [164.855900654000123, -27.050655672999923],
            [164.8662046540002, -27.038149673],
            [164.876570654000176, -27.025686672999967],
            [164.886998654000223, -27.013265672999964],
            [164.897488654000227, -27.000887672999966],
            [164.908040654000132, -26.988551672999975],
            [164.918654654000107, -26.976259672999973],
            [164.929329654000156, -26.964011672999987],
            [164.940065654000108, -26.951806673],
            [164.950861654000136, -26.939645673000015],
            [164.96171965400012, -26.927528673000026],
            [164.972636654000127, -26.915456672999937],
            [164.983614654000206, -26.903428672999937],
            [164.994651654000194, -26.891445673000021],
            [165.005749654000141, -26.879508673],
            [165.016905654000112, -26.867616672999986],
            [165.028121654000159, -26.855770672999938],
            [165.039395654000117, -26.843969672999989],
            [165.050729654000151, -26.832215673],
            [165.062120654000211, -26.820507672999938],
            [165.073570654000179, -26.808846673000019],
            [165.08507765400023, -26.797232673],
            [165.096643654000189, -26.785664672999957],
            [165.108265654000178, -26.774145672999964],
            [165.119945654000134, -26.762672672999969],
            [165.131681654000232, -26.751248672999949],
            [165.143475654000241, -26.739871673],
            [165.155324654000168, -26.728543673],
            [165.167230654000178, -26.717264672999988],
            [165.179191654000164, -26.706033673000022],
            [165.191209654000119, -26.694851672999945],
            [165.203281654000108, -26.68371867299993],
            [165.215409654000126, -26.672635672999959],
            [165.227591654000179, -26.661602672999962],
            [165.239828654000149, -26.650618672999926],
            [165.25211965400004, -26.639684673000033],
            [165.26446465400025, -26.628801673],
            [165.276863654000209, -26.617968672999936],
            [165.289316654000203, -26.607186673],
            [165.301821654000065, -26.596455673000023],
            [165.314380654000189, -26.585775673000015],
            [165.326992654000122, -26.575147672999947],
            [165.339655654000154, -26.56457067299992],
            [165.352371654000223, -26.554045673000019],
            [165.36513965400016, -26.543572672999986],
            [165.37795965400008, -26.533152672999989],
            [165.3908296540001, -26.522784672999947],
            [165.40375165400016, -26.51246867299993],
            [165.416724654000205, -26.502206672999947],
            [165.429747654000181, -26.491996673],
            [165.442821654000142, -26.481840673],
            [165.455944654000149, -26.471738672999933],
            [165.4691176540002, -26.461689672999981],
            [165.482340654000126, -26.451694672999977],
            [165.495612654000098, -26.441753673],
            [165.508932654000233, -26.431867672999957],
            [165.522301654000188, -26.422035673000025],
            [165.535719654000246, -26.412257672999942],
            [165.549184654000129, -26.40253567299996],
            [165.562698654000229, -26.392868673],
            [165.576258654000156, -26.383256672999977],
            [165.589866654000076, -26.373700672999959],
            [165.603521654000048, -26.364199672999973],
            [165.617222654000244, -26.354755673],
            [165.630970654000095, -26.345366672999944],
            [165.644763654000116, -26.336034673],
            [165.658603654000132, -26.326758672999972],
            [165.667514654000144, -26.32083167299993],
            [165.672488654000205, -26.317539672999956],
            [165.686418654000107, -26.308376672999955],
            [165.700393654000237, -26.29927167299995],
            [165.714412654000142, -26.290223672999971],
            [165.728476654000104, -26.281232673],
            [165.74258465400024, -26.272299673],
            [165.756735654000209, -26.263423673000034],
            [165.770930654000182, -26.254606672999984],
            [165.785168654000159, -26.245847673000014],
            [165.799449654000256, -26.237145671999954],
            [165.813772654000246, -26.228503671999988],
            [165.828138654000242, -26.219919671999932],
            [165.842545654000247, -26.211394672000054],
            [165.856995654000201, -26.202927672],
            [165.860269154000122, -26.201095671999937],
            [168.73848765700015, -25.844964671999918],
            [169.431684658000137, -25.930781672],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "NGA", Country: "Nigeria" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.625118171505562, 13.718336041098667],
            [13.634718132877367, 13.703891172039917],
            [13.760018197968691, 13.527800126324777],
            [13.901082276044235, 13.328218126342506],
            [14.074718094674381, 13.081664108471799],
            [14.076945166354335, 13.06833604419522],
            [14.100827052586737, 12.957217991678235],
            [14.173054080089344, 12.623609024270181],
            [14.199163205639707, 12.501391152623057],
            [14.197363275746682, 12.475000059849165],
            [14.184163286950962, 12.415000049818929],
            [14.174445140744183, 12.396664133657168],
            [14.239027201977166, 12.35416419640147],
            [14.322082139670187, 12.35597317874975],
            [14.392500184824598, 12.352773135746716],
            [14.430636167998642, 12.348164094815758],
            [14.469191246332855, 12.340900169865336],
            [14.49083617023939, 12.335835991590741],
            [14.519718197005886, 12.315553965144318],
            [14.552082233999158, 12.248891180261182],
            [14.64528229204808, 12.18832706814554],
            [14.649654125118388, 12.121035975798563],
            [14.619654120103291, 12.028609064501069],
            [14.637218230618458, 11.979926970721962],
            [14.630836081884695, 11.891391106527308],
            [14.612218198499477, 11.825282197807581],
            [14.588609227035363, 11.755000107123067],
            [14.593745154401518, 11.690273206602782],
            [14.638054074005481, 11.650836016775926],
            [14.644509145297008, 11.572354077830397],
            [14.614718184947577, 11.503609060554524],
            [14.46208221895381, 11.414164095138887],
            [14.436527137204962, 11.405282127595044],
            [14.405691121164637, 11.400135974307176],
            [14.373891186256543, 11.378054017967813],
            [14.329718221122505, 11.33889108401371],
            [14.224582165280424, 11.267218100312434],
            [14.188609216045705, 11.244164178478314],
            [14.154373164791849, 11.234864121602953],
            [14.114718213120057, 11.247500175951245],
            [14.087918083470072, 11.257918043437257],
            [14.013745113320795, 11.282009141973418],
            [13.984718079629005, 11.267500067536048],
            [13.886663223247325, 11.170553969309708],
            [13.807218203624188, 11.055827004551503],
            [13.774718212160906, 10.975827102936279],
            [13.770900255253764, 10.939308994717663],
            [13.718336166827214, 10.881544941184544],
            [13.715418091047553, 10.869027071671141],
            [13.706391116578459, 10.846664153936644],
            [13.676945155364933, 10.807500046516253],
            [13.649163337212116, 10.771945018975075],
            [13.617773277370532, 10.733609043590505],
            [13.59889119639638, 10.710836083151506],
            [13.583336060439848, 10.691109106334821],
            [13.571391177829156, 10.668054011034215],
            [13.558191189033636, 10.635827101977128],
            [13.545273167461573, 10.595836035986835],
            [13.519445170944664, 10.502218056244587],
            [13.459027071582739, 10.238609044659071],
            [13.468682185877583, 10.207082192157287],
            [13.465554059603733, 10.179027123960353],
            [13.454300181092776, 10.158745097513929],
            [13.404163324194656, 10.115418034050535],
            [13.395691231717478, 10.11019107921571],
            [13.326391164811696, 10.101664169091592],
            [13.270763323127937, 10.086873127430792],
            [13.25305403804947, 10.071809003363711],
            [13.24389127674857, 10.031664213268868],
            [13.228191133866801, 9.906244957514062],
            [13.233891163318106, 9.86465395384117],
            [13.259718154006578, 9.751664222339954],
            [13.241809211993854, 9.585000135514491],
            [13.218054060138115, 9.551109083396355],
            [13.191245045670712, 9.52889117258708],
            [13.150282181823655, 9.515209056719613],
            [13.096391238120702, 9.51194497597578],
            [13.060973170877759, 9.510273121563714],
            [13.023891128211915, 9.496109046262106],
            [12.973745051220106, 9.456527017148005],
            [12.897782209461781, 9.348053958004513],
            [12.838054108371352, 9.068609016705494],
            [12.848054054163669, 9.038327044466584],
            [12.851245044711362, 9.01791811600576],
            [12.825418221661039, 8.853336093935042],
            [12.815000186536878, 8.814717983688865],
            [12.797218146538711, 8.771391087863648],
            [12.681109173580893, 8.65680007757517],
            [12.613609204206227, 8.631664090986305],
            [12.54639120205519, 8.613053918951778],
            [12.498054107412031, 8.627364174645265],
            [12.476663322810452, 8.627918050808688],
            [12.404300173199942, 8.600273192954248],
            [12.402845074804475, 8.56312711254796],
            [12.416109101340624, 8.546945010232236],
            [12.420418070136975, 8.504164111581233],
            [12.370973223166942, 8.446945049394387],
            [12.339027107867082, 8.422082145211633],
            [12.314163197855947, 8.427918129132991],
            [12.290836193615519, 8.433191184435259],
            [12.269300234450725, 8.423891127560083],
            [12.2505541079463, 8.400973160195505],
            [12.242773187206751, 8.377500143201374],
            [12.237082210210986, 8.319864165149312],
            [12.240973257313811, 8.276527043402112],
            [12.253745098494193, 8.241944987184183],
            [12.256663341911775, 8.217782139556732],
            [12.255554080842472, 8.197773195516604],
            [12.24552714532183, 8.177173159662928],
            [12.229163323344721, 8.158327120872414],
            [12.212636054255427, 8.136809098980265],
            [12.204027169117865, 8.117364089387365],
            [12.199582245851616, 8.083953990874718],
            [12.201663304777696, 8.042500115138381],
            [12.209445063707562, 8.003336007717806],
            [12.168891236736528, 7.926664224586858],
            [12.141109083307867, 7.877773086142085],
            [12.112918228279028, 7.844027040949172],
            [12.096109159604111, 7.826109046480937],
            [12.07666331182088, 7.796391176327603],
            [12.063891135364599, 7.774718089226255],
            [12.053609222348399, 7.757218016451532],
            [12.043609108918105, 7.739718111314801],
            [12.031527098371043, 7.709582151829949],
            [12.025973249311477, 7.676809077960471],
            [12.026245158251356, 7.655000036389211],
            [12.035136178250525, 7.636526992291252],
            [12.04229113845912, 7.577364166752346],
            [11.913745097534871, 7.445282136144641],
            [11.892218190825474, 7.425282076921832],
            [11.864300250564838, 7.405000050475422],
            [11.846391140913909, 7.381391079011209],
            [11.799436054469993, 7.296664119268129],
            [11.754963184839511, 7.268300094119425],
            [11.78832718288453, 7.252845038087145],
            [11.882500212256502, 7.132218051667124],
            [11.864163290266447, 7.084718141515282],
            [11.760691209847579, 7.002082131344253],
            [11.70139125637229, 6.976109127901879],
            [11.64194512250549, 6.946527044580279],
            [11.583609087898111, 6.885836030450236],
            [11.559236189776328, 6.819582114805115],
            [11.586873168641773, 6.786182074576445],
            [11.588609228432261, 6.75944497683831],
            [11.580973146980142, 6.720073165856547],
            [11.557773212392135, 6.658336090207314],
            [11.51215403368019, 6.603400090813679],
            [11.464027157168971, 6.601664198661169],
            [11.438054153726682, 6.590000109807633],
            [11.414582142560789, 6.512918116334191],
            [11.409654086394085, 6.484309004335827],
            [11.38076334244829, 6.45465399845628],
            [11.340273217941785, 6.440836095756893],
            [11.270000179712696, 6.433191129487255],
            [11.202218075476424, 6.436945048653882],
            [11.188191128111185, 6.433891186042388],
            [11.127845113116905, 6.437918019977133],
            [11.112363234994291, 6.453336027997295],
            [11.093745016332917, 6.503336092235386],
            [11.08833617419856, 6.525000126881238],
            [11.073327203054589, 6.59624496296702],
            [11.075836074319994, 6.650000119838055],
            [11.082500190277386, 6.677782105628708],
            [11.076873251021851, 6.709026990907049],
            [11.043609165263092, 6.753336078149005],
            [11.014727138496625, 6.77785398319601],
            [10.96291809191024, 6.777218132019328],
            [10.94097326350726, 6.779027114367778],
            [10.903054036349744, 6.806945054628329],
            [10.881945051333787, 6.833609062170339],
            [10.880418203846943, 6.872364132714907],
            [10.863745089641924, 6.925835981257848],
            [10.842363189857679, 6.95965394318003],
            [10.821391165139943, 6.970273143980691],
            [10.734445180344096, 7.001391127244446],
            [10.620345181945083, 7.057082168478516],
            [10.560000172778899, 7.021664101235544],
            [10.513336106014094, 6.878053936244214],
            [10.269445018789952, 6.874718106409091],
            [10.230691121711885, 6.881245094430085],
            [10.210136180497159, 6.895418054549026],
            [10.193054029415975, 6.922636105892636],
            [10.188891241011305, 6.94236408853746],
            [10.187773262762676, 6.97833603194384],
            [10.178191238663885, 7.002782187899385],
            [10.166109228116824, 7.020136080282398],
            [10.095000178862762, 6.96278206945378],
            [9.879863210562206, 6.780553961854437],
            [9.795554004874475, 6.801664120336866],
            [9.786391243573661, 6.777782066466557],
            [9.744163215257799, 6.639444956777837],
            [9.708845060301087, 6.521253919065458],
            [9.655554093229028, 6.525835970268105],
            [9.637782279152646, 6.523335983820019],
            [9.59444515740563, 6.497773190720309],
            [9.525273165980735, 6.42971800407777],
            [9.468527178410341, 6.404300050265292],
            [9.455554003915324, 6.369164117883926],
            [9.432082160387495, 6.327218056791835],
            [9.409582282354791, 6.326664180628413],
            [9.369582163909001, 6.326527052692029],
            [9.333054165044587, 6.279717979001816],
            [9.223327173182355, 6.150554024173886],
            [9.07166334032172, 6.013609148606406],
            [8.960000128820639, 5.907364166971178],
            [8.93450908481222, 5.902918070238442],
            [8.864445091249138, 5.837573088176114],
            [8.850827180760007, 5.722500118453851],
            [8.880282194429043, 5.657218000665679],
            [8.904863299026175, 5.633191107508125],
            [8.920000178013026, 5.603744978656252],
            [8.907082156440964, 5.560836004524546],
            [8.884163183248234, 5.538744995729687],
            [8.862291109765039, 5.503818108014343],
            [8.837218155088294, 5.379717996184837],
            [8.832082227722054, 5.340135967070751],
            [8.840900157525283, 5.302573138437424],
            [8.824718222847736, 5.188609094508138],
            [8.812773172599066, 5.155691181351415],
            [8.71694505262127, 5.018609010209389],
            [8.681663275124293, 4.972218025851035],
            [8.620836138886432, 4.892364136989528],
            [8.620554004024825, 4.872082110543104],
            [8.635063246100003, 4.846109107100901],
            [8.632918149433408, 4.825000122084774],
            [8.602845054222428, 4.81041812508964],
            [8.591736123361443, 4.810936212138458],
            [8.587179402000146, 4.811300280500134],
            [8.581827463666855, 4.811300356000103],
            [8.578667268000174, 4.809193374000117],
            [8.574946508000153, 4.806712507000114],
            [8.571183729000126, 4.802323145000202],
            [8.57035850799997, 4.801360507000126],
            [8.568403343000114, 4.798671996000166],
            [8.564242508000035, 4.792950507000256],
            [8.558890508000218, 4.7830105070001],
            [8.555330219999973, 4.77233119500022],
            [8.554302508000205, 4.769248507000242],
            [8.552008508000114, 4.75854450700011],
            [8.548950508000047, 4.747840507000149],
            [8.54757496900001, 4.736147074000115],
            [8.547421508000014, 4.734842507000096],
            [8.547180276000148, 4.733636558000057],
            [8.545127508000121, 4.723374507000159],
            [8.541947127000128, 4.715422514000252],
            [8.539011508000158, 4.708082507000185],
            [8.531365508000079, 4.69584950700019],
            [8.526495453000109, 4.68920862800006],
            [8.522955508000024, 4.684381507000253],
            [8.517487516000131, 4.679319042000159],
            [8.512222398500199, 4.674444476500113],
            [8.506892141000094, 4.671052575000175],
            [8.500000000000227, 4.6666666670002],
            [8.49285561500011, 4.660542908000224],
            [8.480555556000155, 4.65],
            [8.467607724000175, 4.636605691000199],
            [8.464444444000094, 4.633333333000152],
            [8.452500000000157, 4.616666667000132],
            [8.448138010000207, 4.608735776000202],
            [8.44333333300014, 4.6],
            [8.437498833, 4.58939181800018],
            [8.43416666700017, 4.583333333000098],
            [8.431299424, 4.577598849000083],
            [8.428333333000154, 4.571666667000244],
            [8.427504421000236, 4.569535177500143],
            [8.426388889000123, 4.566666667000248],
            [8.418888889000158, 4.550000000000139],
            [8.413055556000046, 4.533333333000215],
            [8.410555832000085, 4.523889048000115],
            [8.406667039500064, 4.524999672000078],
            [8.407000000000124, 4.522222000000212],
            [8.402778000000183, 4.442222000000214],
            [8.395000000000209, 4.391111],
            [8.378056000000242, 4.333333],
            [8.371389, 4.325556000000134],
            [8.371944, 4.312778],
            [8.37199975350012, 4.28333],
            [8.355555966000225, 4.283332753500076],
            [8.335105574000039, 4.135465669000112],
            [8.333673636000043, 4.125112003000183],
            [8.324795280000217, 4.060916824000046],
            [8.275833333000065, 4.026944444000065],
            [8.069579813000132, 3.883834881000141],
            [8.083027778000115, 3.865222222000142],
            [8.067777778000163, 3.855611111000186],
            [8.055192092000198, 3.873851922000156],
            [7.819444444000169, 3.710277778000119],
            [7.81952777800015, 3.645111111000162],
            [7.59463888900018, 3.44625],
            [7.376611111000102, 3.253333333000086],
            [7.1425, 3.074722222000133],
            [7.121513794600162, 3.042152760000064],
            [7.051944444000185, 3.028888889000129],
            [7.029444444000148, 3.024166667000117],
            [7.018611111000155, 3.021944444000098],
            [6.949444444000164, 3.006666667000161],
            [6.63527777800013, 2.912777778000205],
            [6.529444444000148, 2.873055556000097],
            [6.490833333000154, 2.858055556000224],
            [6.444722222000166, 2.83833333300008],
            [6.265833333000131, 2.755000000000237],
            [5.983333333000189, 2.610555556000094],
            [5.882777778000104, 2.560277778000227],
            [5.756111111000166, 2.502222222000142],
            [5.605555556000184, 2.439166667000151],
            [5.449166667000213, 2.380277778000121],
            [5.286388889000079, 2.325000000000173],
            [5.100833333000196, 2.264722222000245],
            [4.799444444000187, 2.178888889000149],
            [4.415555556000129, 2.086111111000122],
            [4.253055556000135, 2.049722222000213],
            [3.894444444000072, 1.981388889000158],
            [3.575833333000105, 1.92166666700011],
            [3.525112503000173, 1.977687505000148],
            [3.514781003000138, 1.987944005000131],
            [3.3391275030001, 2.163348505000229],
            [3.301800503000209, 2.200622505000183],
            [3.141528503000188, 2.360607505000175],
            [3.103564503000086, 2.398496505000125],
            [3.100660503000142, 2.401401505000052],
            [3.098908503000132, 2.403898505000072],
            [3.095594503000029, 2.407204505000223],
            [3.093484503000241, 2.410210505000251],
            [3.092289503000131, 2.411403505000195],
            [3.090408503000106, 2.414082505000167],
            [3.089755503000077, 2.414735505000195],
            [3.085831503000122, 2.42032550500015],
            [3.084766503000111, 2.421388505000181],
            [3.082962503000061, 2.423958505000186],
            [3.080601503000111, 2.426314505000079],
            [3.078350503000195, 2.429413005000129],
            [3.072303503000171, 2.437921505000148],
            [3.06805250300016, 2.442161505000144],
            [3.056435503000074, 2.458711505000096],
            [3.05386750300022, 2.461273505000165],
            [3.029615503000173, 2.495821504999981],
            [3.018676503000137, 2.511403505000146],
            [3.016803503000233, 2.514071505000089],
            [2.944346503000133, 2.617261505000187],
            [2.855373503000237, 2.743922506000104],
            [2.820753503000134, 2.793192506000167],
            [2.771661503000246, 2.863042506000198],
            [2.719865503000165, 2.936823506000152],
            [2.718412503000138, 2.938992506000119],
            [2.717685003000156, 2.940440506000201],
            [2.717308503000083, 2.94114050600021],
            [2.684922503000081, 2.987197506000157],
            [2.684342502000163, 2.988436506000184],
            [2.683959002000137, 2.989044506000127],
            [2.670541502000106, 3.00829750600009],
            [2.669972502000178, 3.009396006000202],
            [2.669393502000133, 3.010515506000203],
            [2.668244502000107, 3.012149506000213],
            [2.667506767333407, 3.013626390000113],
            [2.841755003000173, 5.423339101000209],
            [2.783800186000178, 5.900319837000097],
            [2.771742261000099, 5.99259578200008],
            [2.727717583000242, 6.305287846000155],
            [2.719609159764019, 6.365509105087682],
            [2.719609159528034, 6.452235987021467],
            [2.727218083613849, 6.474718095419533],
            [2.75541815873612, 6.565136032158577],
            [2.761663179533485, 6.671391072077611],
            [2.762782163610439, 6.76500016700254],
            [2.75187328423533, 6.921245045237697],
            [2.727918140169066, 6.947009004014234],
            [2.74971812928473, 7.094164211144346],
            [2.778609208506708, 7.134444955709],
            [2.770000155731168, 7.254444975769744],
            [2.736663315052539, 7.596391087013728],
            [2.732218056510021, 7.65194500031123],
            [2.732954155248962, 7.658209131847912],
            [2.738609090061118, 7.716391107074386],
            [2.742918058857555, 7.789582053444903],
            [2.73937318435685, 7.814444957627572],
            [2.702927160505425, 7.865035946041047],
            [2.695282194235887, 7.882218009408334],
            [2.692500240564186, 7.906391082957768],
            [2.708891219907599, 8.00805401339035],
            [2.727500218475569, 8.108327056634309],
            [2.741391211370967, 8.156109101647942],
            [2.751736156298961, 8.209654040386823],
            [2.736045065872588, 8.245764117557812],
            [2.709927055504977, 8.342291120624253],
            [2.725418153720909, 8.432009000808094],
            [2.750000096508359, 8.457500044816598],
            [2.758336066877717, 8.491109129710935],
            [2.757427133294726, 8.530136109195212],
            [2.752963266927253, 8.535573114524283],
            [2.756391130059455, 8.555273101612485],
            [2.756391130059455, 8.581944988143505],
            [2.746945060430477, 8.719445080978886],
            [2.742954101041448, 8.770764121504286],
            [2.745282258473708, 8.795273141733873],
            [2.783054131773071, 9.016664183287389],
            [2.792082279708609, 9.050418107469142],
            [2.813609186418091, 9.065273186870385],
            [2.905282229342077, 9.078891097359502],
            [2.967500090958879, 9.081664166213869],
            [2.996245157427211, 9.063191122115825],
            [3.095000238001916, 9.090554012746651],
            [3.131391108930046, 9.194445020687425],
            [3.166391254479493, 9.302773072905609],
            [3.163191211476203, 9.377364132386475],
            [3.148609214481155, 9.412218097544041],
            [3.14180914405398, 9.435136064908619],
            [3.171391227375665, 9.496391181123883],
            [3.317218070486888, 9.63610912554573],
            [3.363327087621798, 9.681936007818948],
            [3.352782150483563, 9.703054045290486],
            [3.337218129709697, 9.735000160590431],
            [3.33097327655048, 9.762364224687573],
            [3.334027139161805, 9.783327029311636],
            [3.34958227511828, 9.811454014238038],
            [3.483545037165726, 9.855691184750711],
            [3.520973252433777, 9.845553943384019],
            [3.558891138486757, 9.880282179993657],
            [3.612009103076616, 9.954100092723465],
            [3.677918187224236, 10.111809121809316],
            [3.684163208021346, 10.160836047085994],
            [3.670345137683967, 10.186453993108728],
            [3.644163257213933, 10.199444937238582],
            [3.581945060321061, 10.275273165631575],
            [3.638609072878182, 10.413609101853979],
            [3.699373177204166, 10.448300122813407],
            [3.734445071844988, 10.429582159503866],
            [3.763891200696776, 10.412773090828949],
            [3.791527173733726, 10.404164205691472],
            [3.812782171503471, 10.449445090790462],
            [3.855000141535442, 10.58500007916949],
            [3.847500182191226, 10.69652700092449],
            [3.830836120441518, 10.711945008944568],
            [3.785554061876155, 10.756944932648338],
            [3.751245088064508, 10.798744980986982],
            [3.749100159035891, 10.829445042557225],
            [3.759445103964026, 10.847500164961758],
            [3.775282207144016, 10.898609154992926],
            [3.737636062392795, 11.020273150476626],
            [3.730554024741878, 11.040554003456649],
            [3.728745042393626, 11.077500091652666],
            [3.73577326822587, 11.120636047723039],
            [3.714163213036642, 11.12721802102898],
            [3.694718203443671, 11.13471798037331],
            [3.608863207168042, 11.250000162399402],
            [3.475000189768679, 11.429718057628776],
            [3.525282221230214, 11.563609070584931],
            [3.571245057973442, 11.650973144712481],
            [3.604454158447339, 11.693273089757653],
            [3.688336054709993, 11.749717999365217],
            [3.670554014711655, 11.807218022947552],
            [3.631663324973431, 11.825282197807496],
            [3.61694503823233, 11.919718083663881],
            [3.671663275781043, 11.975553964184996],
            [3.67107318979572, 12.00500009303687],
            [3.63694509745514, 12.107773122729085],
            [3.65639111287652, 12.215273210549341],
            [3.666118143900718, 12.249436003969109],
            [3.661109118549149, 12.445273137240235],
            [3.657782173531444, 12.528891171190097],
            [3.912218197894163, 12.717782053642154],
            [3.952782250786925, 12.748891152088774],
            [4.077218141658591, 12.951109092988929],
            [4.105000127449244, 12.996391151554391],
            [4.139718138136914, 13.177218140214904],
            [4.140918091398902, 13.273591083900627],
            [4.142500091808614, 13.401109000578515],
            [4.142782226670391, 13.473473156017505],
            [4.247773275587065, 13.481391037055261],
            [4.478891180526631, 13.688609118489751],
            [4.625982182278193, 13.721964231717365],
            [4.885554134006981, 13.78139108720697],
            [4.923609147996331, 13.7363911635032],
            [5.00000013737008, 13.733673079933865],
            [5.2150001453725, 13.746664191701811],
            [5.28916322487612, 13.752773090391287],
            [5.341945075147578, 13.794718145655068],
            [5.364163321233093, 13.846527024603276],
            [5.526391201971478, 13.886945064742392],
            [5.548363187740847, 13.891500126216883],
            [5.657218126394156, 13.836664206747344],
            [5.700282165735047, 13.817782125773178],
            [5.874718120844648, 13.74944497190711],
            [5.961945067035828, 13.715273126031761],
            [5.997500094577077, 13.70139118559193],
            [6.133336044351324, 13.660827132699097],
            [6.238054010861816, 13.683336063187298],
            [6.282773140808359, 13.683891112817207],
            [6.285000212488313, 13.683891112817207],
            [6.388609085567396, 13.625554072381433],
            [6.423054013849026, 13.605273219401496],
            [6.575282284071164, 13.45083598241024],
            [6.677918185827167, 13.345273120057314],
            [6.779163194566138, 13.158053924379146],
            [6.806391136555476, 13.108054027779303],
            [6.942218201512304, 12.996800020792421],
            [7.02806330714219, 12.995764185195128],
            [7.09055408352711, 12.995282058123081],
            [7.147500230945951, 13.042773083457519],
            [7.218891247423613, 13.125553932915949],
            [7.341945130095667, 13.106664140590851],
            [7.379718176861388, 13.099718057409945],
            [7.815000132985944, 13.352773079401629],
            [8.043609166660076, 13.320836016557095],
            [8.125482256002101, 13.298891188154187],
            [8.141391108273467, 13.280553930887876],
            [8.230973201625488, 13.221391105349142],
            [8.251509199738877, 13.217600138170397],
            [8.287218119022782, 13.198327125231032],
            [8.348327054846266, 13.140554019242586],
            [8.408609199738294, 13.0883361034182],
            [8.451663180795293, 13.079026994087428],
            [8.471109196216702, 13.083891180151767],
            [8.506945017514994, 13.0847181387213],
            [8.539445176616368, 13.075973131475919],
            [8.558745179284131, 13.063744940537262],
            [8.611109107862148, 13.025000095914677],
            [8.632500227739882, 13.000553939959048],
            [8.6483361574534, 12.971109152211739],
            [8.661809228655301, 12.94583603768649],
            [8.818609156520267, 12.89666410548466],
            [8.99180023136671, 12.846244946086742],
            [9.010982216837306, 12.846909128096229],
            [9.032500238729455, 12.846664208884732],
            [9.064854049800942, 12.844654060859554],
            [9.113054016507931, 12.841664235988489],
            [9.236663284085949, 12.829718012273347],
            [9.423891196943543, 12.816944997626663],
            [9.621945175972684, 12.804445065386275],
            [9.634927067647027, 12.80243609082747],
            [9.694163318657871, 12.871109023735798],
            [9.873054087679606, 13.075282127376212],
            [9.93389111456338, 13.135973141506256],
            [9.958609179458705, 13.153327033889269],
            [9.988054134844106, 13.17278210176606],
            [10.141527117691368, 13.256109116036896],
            [10.178745114827109, 13.270973080255558],
            [10.225000144715921, 13.280553930887876],
            [10.33750020543232, 13.300835957334286],
            [10.361663220697949, 13.304445037213767],
            [10.41194508452142, 13.309718092516135],
            [10.442500139166327, 13.314718065412293],
            [10.463891259044061, 13.318609112515304],
            [10.535273223066241, 13.340000064754946],
            [10.592218197018667, 13.357500137529584],
            [10.644663262419726, 13.374609110701115],
            [10.714582249057486, 13.385418077790234],
            [10.749718181438851, 13.388609068338027],
            [10.795000240004242, 13.389164117967752],
            [10.841109089501231, 13.386109081889956],
            [10.880973253476839, 13.379444965932663],
            [10.906663283867118, 13.372500056218243],
            [10.95750019732057, 13.365836107898943],
            [10.977500256543379, 13.364164085848799],
            [11.011391141023324, 13.361945060796018],
            [11.038327057505143, 13.36028209120137],
            [11.066663254735403, 13.359717989116064],
            [11.100827053983636, 13.36028209120137],
            [11.126945064351332, 13.361664099400798],
            [11.17110914466798, 13.366391157528753],
            [11.203745090601188, 13.371945006588334],
            [11.238609114042646, 13.375273125072511],
            [11.268473164587931, 13.376809025014595],
            [11.338609242518658, 13.379444965932663],
            [11.392500186221582, 13.381109108993797],
            [11.426663314917533, 13.378891089769326],
            [11.463336153069349, 13.373336067243272],
            [11.491663297843928, 13.365836107898943],
            [11.531109204850111, 13.35527306584963],
            [11.824582266980826, 13.270827067501841],
            [11.89055404776434, 13.23750011746894],
            [12.007773287619784, 13.17139104111105],
            [12.175282270287596, 13.122218103080826],
            [12.368609191188767, 13.075282127376212],
            [12.42957328772502, 13.073027060139623],
            [12.459163250035715, 13.066109140153429],
            [12.491945041084534, 13.097773120591683],
            [12.544027170077015, 13.152636029789463],
            [12.541627095915203, 13.189164196292054],
            [12.573054036130912, 13.244718109589641],
            [12.589163215888703, 13.263609075381041],
            [12.608054014042182, 13.272782062603824],
            [12.646663239471025, 13.286944964439044],
            [12.749718068748678, 13.349027039223941],
            [12.77041818452679, 13.377500029114401],
            [12.876109122360532, 13.473326975625625],
            [12.899718093824646, 13.484445067304748],
            [13.01610920164407, 13.533609120517724],
            [13.075418207574671, 13.5384731389439],
            [13.09389125167263, 13.523053957457435],
            [13.125827141050621, 13.518891169052779],
            [13.202218130424512, 13.527364099720259],
            [13.255273230740329, 13.60805399960681],
            [13.347773232233948, 13.720000016521709],
            [13.625118171505562, 13.718336041098667],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "NIC", Country: "Nicaragua" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.769347762999985, 16.07083306900013],
            [-79.84222199999985, 16.070833000000121],
            [-79.94444, 15.977778000000157],
            [-80.065277999999864, 15.766667000000112],
            [-79.933333, 15.502778000000106],
            [-79.93333, 14.98556],
            [-81.999999999999886, 14.98556],
            [-81.999999999999886, 12.16713994600012],
            [-82.009270324999989, 11.915595652000107],
            [-82.233332999999874, 11.767564238],
            [-82.697938207999982, 11.458629113000015],
            [-83.129160153999976, 11.207619324],
            [-83.63751531600002, 10.93126720000015],
            [-83.639523897999936, 10.929710158000105],
            [-83.645799865462806, 10.924844989293092],
            [-83.662226780147932, 10.888609041562432],
            [-83.654726820803802, 10.824445069660982],
            [-83.66014605649795, 10.8019450239901],
            [-83.684172949655476, 10.787082065599748],
            [-83.75170896121395, 10.770482041590498],
            [-83.760846073891003, 10.77136415308324],
            [-83.772381919625474, 10.770254053823521],
            [-83.827517911229648, 10.746109143468985],
            [-83.870008963667829, 10.722500172004871],
            [-83.920626941809616, 10.709691115174365],
            [-83.958617918058337, 10.734445054615449],
            [-83.997363936147394, 10.766527124385306],
            [-84.040555045140906, 10.77805391766438],
            [-84.080290965997534, 10.766945046078646],
            [-84.115981948008709, 10.763745003075442],
            [-84.158054911115386, 10.784109004535125],
            [-84.193272986148003, 10.783644982373985],
            [-84.225045931327855, 10.811909095236771],
            [-84.273345977958996, 10.898609154992926],
            [-84.343063966558105, 10.953891159350704],
            [-84.674454914741801, 11.078053967816004],
            [-84.902790866009553, 10.940836009842329],
            [-85.087781984179543, 11.01000012227793],
            [-85.133617918908243, 11.027218060191046],
            [-85.161390852243585, 11.039445077663416],
            [-85.164717797261204, 11.042427023545457],
            [-85.173754997652168, 11.044864145719572],
            [-85.221572917211347, 11.065253963441094],
            [-85.266399838433102, 11.084445001367001],
            [-85.359590844026428, 11.122218048132737],
            [-85.420835901957787, 11.126391062459362],
            [-85.526945934951613, 11.16610904604336],
            [-85.56418186936034, 11.209718076730098],
            [-85.613546082421664, 11.212845029537633],
            [-85.692381905160119, 11.076064103998462],
            [-85.692930279999985, 11.073751075000175],
            [-85.694544035999911, 11.071304300000051],
            [-85.69613268899991, 11.068895586000153],
            [-85.696656808999933, 11.067400664],
            [-85.697753361499934, 11.064273528500038],
            [-85.70185677399985, 11.065749641],
            [-85.704464396999867, 11.06574965500009],
            [-85.705527930999949, 11.065868394000148],
            [-85.708052002999978, 11.066150197000013],
            [-85.711664308999985, 11.0661501700001],
            [-85.720474875999969, 11.066812407],
            [-85.722595832499991, 11.068108004500047],
            [-85.723902836, 11.068765203000112],
            [-85.725273386999987, 11.06938194933349],
            [-85.728668939999892, 11.069954785500073],
            [-85.729544066999836, 11.069979099000093],
            [-85.734296855499935, 11.069562229000056],
            [-85.737952623999945, 11.068942090000178],
            [-85.740075502, 11.068590657000144],
            [-85.744627577333205, 11.067575584666756],
            [-85.749656700999964, 11.066472303000111],
            [-85.763230281999938, 11.065801551000121],
            [-85.765261371999856, 11.065752611000065],
            [-85.770611264999843, 11.065623702999972],
            [-85.785793330999866, 11.060568770999964],
            [-85.787514354999985, 11.060476934],
            [-85.788536934999854, 11.060422369000023],
            [-85.816217493999886, 11.052821530000131],
            [-85.836107433999985, 11.047337511000066],
            [-85.848216751999956, 11.042996034000026],
            [-85.865508694000027, 11.050367656000049],
            [-85.8760067179999, 11.055153494000081],
            [-85.88651412400003, 11.060938093000061],
            [-85.960370623999921, 11.094294604000069],
            [-85.976118324000026, 11.096814031000065],
            [-85.995607327999977, 11.096938632000075],
            [-86.006533189499947, 11.097008485500126],
            [-86.075196493, 11.097888183000038],
            [-86.096085412999855, 11.101745568000169],
            [-86.117323655999854, 11.105667463000032],
            [-86.123671871999932, 11.105022323000128],
            [-86.128931549000015, 11.104487809000133],
            [-86.144370847, 11.103097158],
            [-86.216109473999978, 11.095194254999981],
            [-86.26363269299992, 11.089855663000137],
            [-86.293737118999871, 11.086473838000188],
            [-86.369167077999919, 11.073612709],
            [-86.42351900299991, 11.061395900000107],
            [-86.475894765999897, 11.04354370300014],
            [-86.685140730999905, 10.971852079000044],
            [-86.747724674999859, 10.9520883550001],
            [-86.750374101999967, 10.950850853000119],
            [-86.841709442999843, 10.907027471000049],
            [-86.860255145999901, 10.898129103000102],
            [-86.865998572999956, 10.895366687000063],
            [-86.891321003, 10.883187360500116],
            [-86.950068147999957, 10.854303851000054],
            [-87.371863561, 10.646869647000031],
            [-87.419530007999981, 10.619673580000111],
            [-87.442748449999954, 10.606426309000128],
            [-88.011206886999958, 10.269932873000087],
            [-88.400998114999879, 10.100185272000047],
            [-89.20488156600004, 9.75010738600011],
            [-89.20698502, 9.752141353000084],
            [-89.272696333, 9.842771228000032],
            [-89.346012582999947, 9.892266665000093],
            [-89.358785488999928, 9.904540103000144],
            [-89.422342707000041, 9.972926435000048],
            [-89.180494938000038, 10.405912243000159],
            [-88.581739198000037, 11.577041188000067],
            [-88.506397681999829, 11.786566325000081],
            [-88.422350317, 12.020577838000079],
            [-88.371979861, 12.167436397000131],
            [-88.337296563, 12.270263351000125],
            [-88.31112965399987, 12.323064560000049],
            [-88.223500880999978, 12.499450786000125],
            [-88.220494678999955, 12.505485756000127],
            [-88.217503743999828, 12.511433339000178],
            [-88.204258192999902, 12.537720167000103],
            [-88.141916917999936, 12.659665163999989],
            [-88.076922371000023, 12.764873988000147],
            [-88.070559526999887, 12.775246056000086],
            [-88.052636214999893, 12.797491125],
            [-88.000356194999938, 12.853070249],
            [-87.955633406999965, 12.900285735000153],
            [-87.943636442999946, 12.91107966],
            [-87.933440670999858, 12.920380210000104],
            [-87.922831981999877, 12.929585237000026],
            [-87.920408714000018, 12.931651429000013],
            [-87.914174836, 12.936736262000181],
            [-87.885382185999958, 12.961486826],
            [-87.858930588, 12.985690279000025],
            [-87.84797336400004, 12.995921670000143],
            [-87.837167486, 13.006186272000079],
            [-87.832701676999875, 13.010639775000143],
            [-87.82569106299988, 13.01844253800013],
            [-87.791354049999939, 13.056107120999982],
            [-87.745571255000016, 13.105321008000104],
            [-87.733327154999898, 13.118240169000117],
            [-87.723613053999969, 13.124477602000084],
            [-87.718126359999928, 13.131364135000112],
            [-87.650738521999955, 13.195444608000173],
            [-87.574779977999867, 13.17195373500013],
            [-87.560891667999869, 13.169294002000157],
            [-87.501425244000018, 13.124194165000077],
            [-87.460815708000041, 13.102487160000109],
            [-87.457612474999848, 13.101299808],
            [-87.424524376999955, 13.047666048000025],
            [-87.414346336999984, 13.040180731000106],
            [-87.403369377999979, 13.029105573999985],
            [-87.38980915599987, 12.99565337500006],
            [-87.329800404, 13.001325778],
            [-87.302464043333345, 12.986905317666768],
            [-87.301407467607135, 12.98665007665231],
            [-87.093063979247319, 12.998609003140785],
            [-87.048755059643355, 12.98517314758918],
            [-87.022781888562889, 12.988191135654858],
            [-86.955699840881749, 13.036664184768028],
            [-86.924726864543317, 13.082773201903123],
            [-86.913346084017945, 13.1633269796815],
            [-86.918063922052511, 13.195000012575164],
            [-86.90258187629189, 13.248609156692481],
            [-86.859999964022933, 13.28333605219774],
            [-86.817508911584838, 13.298400176264735],
            [-86.775699978428861, 13.283745089073747],
            [-86.745281884082146, 13.26082712170944],
            [-86.735772950179211, 13.263609075381041],
            [-86.696054966595199, 13.298818097958161],
            [-86.696117830869156, 13.353891057650188],
            [-86.710846008255999, 13.434164209309515],
            [-86.727054932662071, 13.465473132328313],
            [-86.750835900779805, 13.567782139859474],
            [-86.764208892057354, 13.710691074829029],
            [-86.758599890074834, 13.754009085836927],
            [-86.710772918060059, 13.774654048691829],
            [-86.566390947422406, 13.779164183165094],
            [-86.469999898825677, 13.764164096838357],
            [-86.401117921251597, 13.749164178149798],
            [-86.325573001186882, 13.763473092738735],
            [-86.132363929844615, 13.995209079220231],
            [-86.119664005393872, 14.030691184203548],
            [-86.070146068228013, 14.055973183546214],
            [-86.008608817150957, 14.055173214704908],
            [-86.020281958459805, 14.005344979482359],
            [-85.996126822183385, 13.98027319827203],
            [-85.968335783937135, 13.958891130849779],
            [-85.828899974376981, 13.859445046175267],
            [-85.794308865703641, 13.836527078811045],
            [-85.73639092042788, 13.828609030135027],
            [-85.728663979145068, 13.83309116905167],
            [-85.724726831574429, 13.864509056811954],
            [-85.748272938764586, 13.885064165664559],
            [-85.728899845901054, 13.962573133287023],
            [-85.557717913251395, 14.043536115579698],
            [-85.543964048292452, 14.045718092620334],
            [-85.436954972361576, 14.122500013959453],
            [-85.416535817978854, 14.145691063729956],
            [-85.409454953794523, 14.171244972012332],
            [-85.378199842594199, 14.235553950839105],
            [-85.3222268341367, 14.27624507338443],
            [-85.298335895449156, 14.289445062180036],
            [-85.276399951863397, 14.295827043275708],
            [-85.254181873416229, 14.298336082179205],
            [-85.217226900402693, 14.296454009634985],
            [-85.180281985673162, 14.314718009067306],
            [-85.159126900189506, 14.335653991601291],
            [-85.159664012546386, 14.359654062668525],
            [-85.179455027103529, 14.385273182157832],
            [-85.180555068079258, 14.426664193620283],
            [-85.146390933554841, 14.489164190098691],
            [-85.080354947392976, 14.544509058730526],
            [-85.061108924182037, 14.553336040989151],
            [-85.027454912286288, 14.580900097296634],
            [-85.018926828695697, 14.598236052406847],
            [-85.030008878191097, 14.670000063576694],
            [-84.995281982685839, 14.726109026504119],
            [-84.984364050855135, 14.741309105040997],
            [-84.910008858130368, 14.806664145387046],
            [-84.826390991818499, 14.829027063121728],
            [-84.797017953162651, 14.813891189963257],
            [-84.688608931759518, 14.678336033946138],
            [-84.480490969197632, 14.6188179833497],
            [-84.260281943633203, 14.681944946187471],
            [-84.196673021361647, 14.716391047935318],
            [-84.035281989838353, 14.754445056096174],
            [-83.97861780964314, 14.755282072949782],
            [-83.938063982672304, 14.753891179932836],
            [-83.864454946970255, 14.777773066165068],
            [-83.842372990630793, 14.785553986904617],
            [-83.566955050904511, 14.917917984735951],
            [-83.546399942052005, 14.948327026627155],
            [-83.485835829936264, 15.006109017433019],
            [-83.384172899503767, 15.022218029552803],
            [-83.358899952616696, 15.015000037432102],
            [-83.324172889473545, 15.004718124416272],
            [-83.229717892877773, 14.981664202581968],
            [-83.158890978485402, 14.987218051641449],
            [-83.131854982039727, 14.992982118916657],
            [-83.099496196999866, 15.01445278600012],
            [-83.087713056999974, 15.018563342000036],
            [-82.880795491499924, 15.090746666000044],
            [-82.880851844499972, 15.08900898000006],
            [-82.880881228999868, 15.088042003500121],
            [-82.880906887999856, 15.086785617000046],
            [-82.880921199, 15.085843238000152],
            [-82.880930574999951, 15.08490079700006],
            [-82.880936002999874, 15.083958325000069],
            [-82.880936496499885, 15.083015837500099],
            [-82.880930574999951, 15.08175920300009],
            [-82.880921199, 15.080816762000111],
            [-82.880906887999856, 15.079874383000018],
            [-82.880881228999868, 15.078617996500114],
            [-82.880847675999945, 15.077361796000147],
            [-82.880806230499928, 15.076105831500101],
            [-82.880770214999927, 15.075164032000018],
            [-82.880729268999914, 15.074222434500129],
            [-82.880668100999912, 15.072967276000057],
            [-82.880599047499857, 15.071712525500047],
            [-82.880522111999909, 15.070458234500094],
            [-82.880437297499952, 15.069204451500084],
            [-82.880344606499932, 15.067951226500028],
            [-82.880244043499971, 15.066698608500147],
            [-82.880163702999937, 15.065759551000113],
            [-82.880078446999903, 15.064820927500037],
            [-82.87995822049993, 15.063570043500093],
            [-82.879830137499908, 15.062319939500057],
            [-82.879694201999882, 15.061070665000116],
            [-82.879550419999902, 15.059822269000122],
            [-82.879437681999889, 15.058886548999951],
            [-82.879320046999936, 15.05795143100012],
            [-82.879156674, 15.056705447500107],
            [-82.878985475499917, 15.05546051550003],
            [-82.878806457999985, 15.054216683500144],
            [-82.878619628999814, 15.052974001000038],
            [-82.878474628999982, 15.052042734000096],
            [-82.878324754499886, 15.051112239000119],
            [-82.878170983999894, 15.050182380000095],
            [-82.878012347499862, 15.049253339000032],
            [-82.877794348499862, 15.048015744000153],
            [-82.877568578499933, 15.046779543000085],
            [-82.877335045, 15.045544785000075],
            [-82.877155048999953, 15.044619645000168],
            [-82.876970210499877, 15.04369546000008],
            [-82.876781502999961, 15.042772056999965],
            [-82.876587962999935, 15.041849655500172],
            [-82.876323473999889, 15.040621156000086],
            [-82.876051270999938, 15.039394343000097],
            [-82.875771365499901, 15.038169264000075],
            [-82.875483767999953, 15.036945968000126],
            [-82.875188490499909, 15.035724503500077],
            [-82.874885543999937, 15.034504917500144],
            [-82.87457494049994, 15.03328726000008],
            [-82.874256692499898, 15.032071577500076],
            [-82.874013234999865, 15.031161077000021],
            [-82.873765013999929, 15.030251863500055],
            [-82.873427710499868, 15.029041329500089],
            [-82.873082807499941, 15.027832939000078],
            [-82.872730318499919, 15.026626740000111],
            [-82.872370257499966, 15.025422779500175],
            [-82.872095488000014, 15.024521234000062],
            [-82.871816000999871, 15.023621139000099],
            [-82.871437073499919, 15.022422982500146],
            [-82.871050625999885, 15.02122723050006],
            [-82.870756097999845, 15.020331945000109],
            [-82.870456885999971, 15.019438214000147],
            [-82.870051703499968, 15.018248679500076],
            [-82.869743149999977, 15.017358129999977],
            [-82.86942993649987, 15.016469209000135],
            [-82.869006119999909, 15.01528618600004],
            [-82.868574878499885, 15.014105848500137],
            [-82.868246815999981, 15.013222300000095],
            [-82.867914131999896, 15.012340481000038],
            [-82.86746440399989, 15.011167063500082],
            [-82.867007311999913, 15.009996494500044],
            [-82.866542873999947, 15.00882882000009],
            [-82.866189964999876, 15.007954899000097],
            [-82.865832483999952, 15.007082838000045],
            [-82.86534976249996, 15.005922602999973],
            [-82.86485975999986, 15.00476542400007],
            [-82.864362496999917, 15.003611347000131],
            [-82.863985022999969, 15.002747752000104],
            [-82.863603030999968, 15.001886146000047],
            [-82.863087701999888, 15.000740021500107],
            [-82.862565181500031, 14.999597157500133],
            [-82.862035490999943, 14.998457599000048],
            [-82.861498650999863, 14.997321391500051],
            [-82.860954682499823, 14.996188579000076],
            [-82.860403606499887, 14.995059207500091],
            [-82.859845445999923, 14.993933320500119],
            [-82.859422409000018, 14.993091107000012],
            [-82.858994968499957, 14.992251120500114],
            [-82.858564012999864, 14.991412931999989],
            [-82.858128674999904, 14.990577011000184],
            [-82.857542396999975, 14.989465506000144],
            [-82.856949146499801, 14.988357707000162],
            [-82.856348947499868, 14.987253656500158],
            [-82.855741823499898, 14.986153399500054],
            [-82.85512779899986, 14.985056979000163],
            [-82.854506896499942, 14.983964438000086],
            [-82.853879142499892, 14.982875819500137],
            [-82.85324456049986, 14.981791167000139],
            [-82.852764370999978, 14.980980180000088],
            [-82.852279940999864, 14.980171717500113],
            [-82.851792128999904, 14.979365292000054],
            [-82.85130010149993, 14.978561431500125],
            [-82.850638462499973, 14.977493068500181],
            [-82.850138044, 14.976694404000042],
            [-82.84963345049988, 14.975898371500122],
            [-82.849125526999984, 14.975104459000121],
            [-82.848613453999974, 14.974313217500068],
            [-82.847925175999961, 14.973261818500035],
            [-82.847230305499977, 14.972214765500084],
            [-82.846528869999901, 14.9711720990001],
            [-82.845820896999953, 14.970133860000075],
            [-82.84510641499989, 14.969100090500106],
            [-82.844566499999985, 14.968327578000029],
            [-82.844022547499861, 14.967557904000117],
            [-82.84329191549989, 14.966535484000062],
            [-82.842739933999837, 14.965771548000092],
            [-82.84218395900001, 14.965010512000092],
            [-82.841437357499842, 14.963999695000027],
            [-82.840684419999945, 14.96299358850014],
            [-82.839925175999923, 14.961992233000117],
            [-82.839159654499952, 14.960995667500029],
            [-82.838387887499948, 14.960003931000088],
            [-82.837609903499981, 14.959017064000065],
            [-82.837022547999823, 14.958279978000022],
            [-82.836431340999923, 14.957545977000038],
            [-82.835637951999928, 14.956571451500011],
            [-82.835039090999942, 14.955843682000122],
            [-82.834436428499941, 14.955119058000136],
            [-82.833627830499921, 14.954157115000115],
            [-82.832928170499855, 14.953334452000078],
            [-82.566322648999915, 14.946065651000126],
            [-82.555507931999955, 14.943181727000095],
            [-82.495878267999984, 14.892129408000173],
            [-82.490323981499927, 14.890709788000052],
            [-82.48940408499999, 14.890504672000034],
            [-82.488483126499943, 14.890304375500037],
            [-82.487561337000017, 14.89010793900006],
            [-82.486638531999944, 14.889916331000123],
            [-82.485714932, 14.889728590000132],
            [-82.484790361999984, 14.889545688000013],
            [-82.483556343999936, 14.88930827750012],
            [-82.482320857999923, 14.889078624000078],
            [-82.481393353000016, 14.888911238000091],
            [-82.480464984999941, 14.888748711500099],
            [-82.479535944, 14.888590074000078],
            [-82.478606084999882, 14.888436303500086],
            [-82.477365218499926, 14.888237771500016],
            [-82.476123128999888, 14.888047039500051],
            [-82.474879865499901, 14.887864115500079],
            [-82.473946710999826, 14.887731806000019],
            [-82.473012876499979, 14.887604385000131],
            [-82.472078516999886, 14.88748087600014],
            [-82.471143522999881, 14.887362261500058],
            [-82.469896054999964, 14.88721063900006],
            [-82.468647658999885, 14.887066857000079],
            [-82.467710808999897, 14.886963924000085],
            [-82.466773433499924, 14.886865898000153],
            [-82.465835655, 14.886771799000073],
            [-82.464897396499879, 14.886682612000087],
            [-82.463645781999901, 14.886570248000098],
            [-82.462393485999939, 14.88646575000017],
            [-82.461140557999926, 14.886369122500113],
            [-82.45988704749999, 14.88628036900009],
            [-82.458633004499887, 14.886199493500115],
            [-82.457378477999953, 14.886126499000071],
            [-82.456123517499918, 14.886061388000101],
            [-82.455182053000044, 14.886017484000021],
            [-82.454240371499878, 14.885978510000072],
            [-82.452984542999928, 14.885933119000057],
            [-82.45204250499998, 14.885904008000139],
            [-82.451100327499859, 14.885879829500041],
            [-82.45015805699984, 14.88585959800001],
            [-82.449215693499895, 14.885844301000091],
            [-82.447959120999968, 14.885830483500129],
            [-82.446702486499873, 14.885824561500087],
            [-82.445445839999962, 14.885826535500073],
            [-82.444503373999964, 14.885832951000125],
            [-82.443560954499901, 14.885844301000091],
            [-82.442304493999927, 14.885866013000012],
            [-82.441048194499928, 14.885895619500118],
            [-82.439792105999885, 14.885933119000057],
            [-82.43853627750002, 14.885978510000072],
            [-82.437594595999855, 14.886017484000021],
            [-82.43665313149998, 14.886061388000101],
            [-82.435398170999861, 14.886126499000071],
            [-82.434143644500011, 14.886199493500115],
            [-82.433203046999949, 14.886259165000112],
            [-82.432262775999874, 14.886323761500094],
            [-82.431009550999818, 14.886416452000176],
            [-82.42975693249997, 14.886517015500104],
            [-82.428504970999882, 14.886625447000043],
            [-82.427253715499887, 14.886741742500121],
            [-82.426315742000043, 14.886833877000058],
            [-82.425378263499965, 14.886930921500138],
            [-82.424441200000018, 14.887031892000024],
            [-82.42350467849991, 14.887137767500022],
            [-82.422256740499961, 14.887285470500089],
            [-82.421321378999977, 14.887401147000119],
            [-82.420386635499938, 14.887521719500185],
            [-82.419141171499916, 14.887689006500082],
            [-82.418207741999936, 14.887819360999984],
            [-82.417275007499967, 14.887954601000061],
            [-82.416032324999975, 14.88814143000009],
            [-82.415101058, 14.88828643],
            [-82.414170562999942, 14.888436303500086],
            [-82.412930968499893, 14.888642629000074],
            [-82.411692694499862, 14.888856738500053],
            [-82.410455789999872, 14.889078624000078],
            [-82.409220304999963, 14.88930827750012],
            [-82.407986286499863, 14.889545688000013],
            [-82.406753783999989, 14.889790848000118],
            [-82.405522846999929, 14.890043747500144],
            [-82.404293522499955, 14.890304375500037],
            [-82.403065859999856, 14.890572723000062],
            [-82.401839907499891, 14.890848778500029],
            [-82.40061571449985, 14.891132530500059],
            [-82.399393327999832, 14.891423970000162],
            [-82.39817279699983, 14.891723083500167],
            [-82.396954168999912, 14.892029860000108],
            [-82.395737492999956, 14.892344287500137],
            [-82.394522816499943, 14.892666353000109],
            [-82.393310187499878, 14.892996044000014],
            [-82.392402021999942, 14.89324807200002],
            [-82.391495187999936, 14.893504851000031],
            [-82.390287887499937, 14.893853548500132],
            [-82.389383792000046, 14.894119810000106],
            [-82.388481103499998, 14.894390800500091],
            [-82.387279428999847, 14.894758419000098],
            [-82.386080087999886, 14.895133580000063],
            [-82.385182066000027, 14.895419657],
            [-82.384285554499883, 14.895710433],
            [-82.383092253999962, 14.89610438600009],
            [-82.382198836999919, 14.896404534000041],
            [-82.381307003499899, 14.896709354500089],
            [-82.380120038499911, 14.897122003000092],
            [-82.379231445999892, 14.89743614700005],
            [-82.378344509999863, 14.897754939000137],
            [-82.377164172999926, 14.89818618000011],
            [-82.375986568499968, 14.89862482900007],
            [-82.375105099, 14.898958436999962],
            [-82.37422538749982, 14.899296654500105],
            [-82.373054818499867, 14.899753747000048],
            [-82.371887143999913, 14.900218184500076],
            [-82.370722411499969, 14.900689950000057],
            [-82.369850724999822, 14.901048343000056],
            [-82.368980927499933, 14.901411296500115],
            [-82.368112658, 14.901777889000115],
            [-82.367246318999975, 14.902149023000092],
            [-82.366093809499972, 14.902649909500084],
            [-82.36494446999987, 14.903158027500027],
            [-82.364084470999842, 14.903543624000108],
            [-82.363226503499959, 14.903933719000051],
            [-82.362370176999946, 14.904327404000028],
            [-82.361515923499837, 14.904725567500051],
            [-82.360663345000034, 14.90512730600004],
            [-82.359812882499966, 14.90553350250012],
            [-82.358681784999845, 14.90608102700007],
            [-82.35783562200001, 14.906496108000042],
            [-82.356991644499942, 14.906915613000123],
            [-82.355869286499882, 14.907480836500142],
            [-82.354750501999916, 14.908053101000192],
            [-82.353913671000043, 14.908486688],
            [-82.353079122499963, 14.908924650000088],
            [-82.352246415000025, 14.909366104000114],
            [-82.351416030999985, 14.909811912000137],
            [-82.350311980999919, 14.910412110500104],
            [-82.349211723999929, 14.911019235000097],
            [-82.348115302999901, 14.911633260000102],
            [-82.347295408999912, 14.912098079000017],
            [-82.346477959999959, 14.91256718400011],
            [-82.345391318999845, 14.913198355500057],
            [-82.344308665499852, 14.913836341500016],
            [-82.343499190999978, 14.914319077000115],
            [-82.342692254499866, 14.914806044500139],
            [-82.341619755499835, 14.915460957000093],
            [-82.340817962999893, 14.915956348000137],
            [-82.340018775499885, 14.916455930000168],
            [-82.338956695499945, 14.917127608000143],
            [-82.337898856999942, 14.917805946000144],
            [-82.3371081519999, 14.918318848000112],
            [-82.336320143, 14.918835882500034],
            [-82.335534306999989, 14.91935621400016],
            [-82.33475120549997, 14.919880652000032],
            [-82.333710747999987, 14.920585359500123],
            [-82.332933181999977, 14.921117970000083],
            [-82.332158414499929, 14.921654644000071],
            [-82.331385902999926, 14.922194558999976],
            [-82.33061622799994, 14.92273851050011],
            [-82.329593808499936, 14.923469142500053],
            [-82.328575999499918, 14.924206184500107],
            [-82.327562841500026, 14.924949606500078],
            [-82.326554374499977, 14.925699379500045],
            [-82.325550638499891, 14.926455474500059],
            [-82.324551672999888, 14.927217860500079],
            [-82.323557517500006, 14.927986508500098],
            [-82.322568210499952, 14.928761388000126],
            [-82.321829282999857, 14.929346425000134],
            [-82.32109342849995, 14.929935323500118],
            [-82.32036004699998, 14.930527299000133],
            [-82.319629775500005, 14.931123106000129],
            [-82.318660254499889, 14.931922602000114],
            [-82.317936261999989, 14.932526024000126],
            [-82.317215438999938, 14.93313322850004],
            [-82.31625859549996, 14.933947854500119],
            [-82.31530688849989, 14.934768476000116],
            [-82.31436035699997, 14.935595061000114],
            [-82.313419037999893, 14.936427577500055],
            [-82.312716326000015, 14.937055653000172],
            [-82.312016913499917, 14.937687400000058],
            [-82.311088786499937, 14.938534599000079],
            [-82.310396026999967, 14.939173635000103],
            [-82.309706622999954, 14.939816290000081],
            [-82.30879191799994, 14.940677963000056],
            [-82.307882645499973, 14.941545366000057],
            [-82.306978840499966, 14.942418465000159],
            [-82.306080539499902, 14.943297226000126],
            [-82.305410273, 14.94395981600006],
            [-82.304743484499937, 14.944625906000127],
            [-82.303859096999986, 14.945518668500043],
            [-82.303199320000033, 14.946191703999986],
            [-82.30254307699991, 14.946868186000117],
            [-82.301672821499892, 14.947774729500182],
            [-82.301023697999966, 14.948458046000113],
            [-82.300378160999827, 14.949144752000095],
            [-82.299735506000047, 14.949834156000108],
            [-82.299096470000023, 14.950526915500106],
            [-82.298249270999918, 14.951455042000092],
            [-82.297617523999861, 14.95215445500007],
            [-82.2969894485, 14.952857166500152],
            [-82.29615693249994, 14.95379848600011],
            [-82.29533034699989, 14.954745017500059],
            [-82.294509725499978, 14.9556967245001],
            [-82.293695099499814, 14.956653568],
            [-82.293087894999985, 14.957374391000045],
            [-82.292484472999973, 14.95809838350003],
            [-82.291684976999903, 14.959067904500145],
            [-82.290891588999983, 14.960042430000087],
            [-82.290104338999953, 14.961021921500176],
            [-82.2893232589999, 14.962006339500093],
            [-82.288548379999895, 14.962995646500062],
            [-82.287971112999912, 14.963740660000113],
            [-82.287397753999898, 14.964488686000081],
            [-82.286638509999875, 14.965490041500018],
            [-82.286073015999989, 14.966244031000016],
            [-82.285511477499938, 14.967000970000029],
            [-82.284768055499967, 14.968014128500059],
            [-82.284031013499913, 14.969031937500162],
            [-82.283300381499856, 14.970054357000166],
            [-82.282756429999836, 14.970824032000152],
            [-82.282216514999845, 14.971596543500098],
            [-82.281679841000027, 14.972371311000032],
            [-82.281147230499982, 14.973148877000042],
            [-82.280442522999977, 14.974189334500025],
            [-82.27991808500002, 14.974972436000044],
            [-82.279397753999945, 14.97575827200005],
            [-82.278880718999886, 14.976546281000125],
            [-82.278367816999918, 14.977336986000111],
            [-82.277689478999918, 14.978394824500086],
            [-82.277017800999857, 14.979456904500054],
            [-82.276518219, 14.980256092000033],
            [-82.27602282849989, 14.981057884500089],
            [-82.27536791549997, 14.982130383500092],
            [-82.274880947999975, 14.982937320000033],
            [-82.274398212499875, 14.983746794500078],
            [-82.273760226499832, 14.98482944800007],
            [-82.27312905499997, 14.985916089000099],
            [-82.272659949999877, 14.986733538000053],
            [-82.272195130999961, 14.987553432000126],
            [-82.271581105999871, 14.988649853000069],
            [-82.270973982499953, 14.989750110000088],
            [-82.270373783, 14.990854160000126],
            [-82.269780532999931, 14.991961959000108],
            [-82.269194254999917, 14.993073464000148],
            [-82.268614972499904, 14.994188631000057],
            [-82.268042707499887, 14.995307415500022],
            [-82.267477483999897, 14.996429773500083],
            [-82.267057978999901, 14.997273751000066],
            [-82.266642898499981, 14.998119913500048],
            [-82.266095373499979, 14.999251011500107],
            [-82.265689176999985, 15.000101474000175],
            [-82.265287438499826, 15.000954052500063],
            [-82.264757747999937, 15.002093610500097],
            [-82.264235227999933, 15.003236474500071],
            [-82.263719898499886, 15.004382599000095],
            [-82.263337906999965, 15.005244205000068],
            [-82.262960432499938, 15.0061078],
            [-82.262586577999969, 15.006972968000113],
            [-82.262217259500005, 15.007840082000186],
            [-82.261851576, 15.008708735000013],
            [-82.261490445999868, 15.009579291000094],
            [-82.261015023499908, 15.010742536000166],
            [-82.260663028999971, 15.011616826000122],
            [-82.260315617999908, 15.012492947500093],
            [-82.25985852549988, 15.013663516500046],
            [-82.259520307999821, 15.014543228000136],
            [-82.259186700499981, 15.015424697500109],
            [-82.258748050999884, 15.016602302000152],
            [-82.258316809999911, 15.017782639000117],
            [-82.257892992999928, 15.018965663000031],
            [-82.257476617999941, 15.020151326000061],
            [-82.257168995999876, 15.021042196999971],
            [-82.256866043999963, 15.021934667000195],
            [-82.256566831999976, 15.022828398000158],
            [-82.256272303999936, 15.023723683500108],
            [-82.255981528999854, 15.024620195000082],
            [-82.255695451, 15.025518216500089],
            [-82.255320289999958, 15.026717558000072],
            [-82.254952671499865, 15.027919232500139],
            [-82.254681680999965, 15.028821921000102],
            [-82.254415419499907, 15.029726016500078],
            [-82.254066721999976, 15.030933317],
            [-82.253809942999965, 15.031840151000083],
            [-82.253557914999874, 15.032748316500133],
            [-82.253228223999884, 15.033960945499999],
            [-82.252906158499911, 15.035175622000096],
            [-82.252591730999967, 15.036392298000138],
            [-82.252284954499942, 15.037610925999971],
            [-82.252059661, 15.038526090000019],
            [-82.251839161499902, 15.039442421000089],
            [-82.251551563999953, 15.040665717000124],
            [-82.251340673000016, 15.041584307000122],
            [-82.251134593999922, 15.042503989000082],
            [-82.250866246499896, 15.043731651500011],
            [-82.25060561849989, 15.04496097600007],
            [-82.250352718999977, 15.046191913000129],
            [-82.250167880999868, 15.047116098000046],
            [-82.249987884499944, 15.048041238000039],
            [-82.249754351499888, 15.049275996000048],
            [-82.249584052999978, 15.050202970000115],
            [-82.249418609499912, 15.051130823500088],
            [-82.249204499999934, 15.052369097500062],
            [-82.248998174499945, 15.053608692000083],
            [-82.248848300999953, 15.05453918700006],
            [-82.248703300999921, 15.055470454000115],
            [-82.24851647199992, 15.056713136500107],
            [-82.248337454499904, 15.057956968500108],
            [-82.248208078, 15.058890534000014],
            [-82.248083590499959, 15.059824764500107],
            [-82.247963017999979, 15.060759508000032],
            [-82.247847341499863, 15.061694869500101],
            [-82.247699638499881, 15.062942807499965],
            [-82.247593762999969, 15.063879329000073],
            [-82.247492792499912, 15.064816392500106],
            [-82.247395747999917, 15.065753871000098],
            [-82.24730361349998, 15.066691844500113],
            [-82.247187317999902, 15.067943099500084],
            [-82.247078886499963, 15.06919506150011],
            [-82.246978322999951, 15.070447679500106],
            [-82.246885632499954, 15.0717009050001],
            [-82.246821035999886, 15.07264117600009],
            [-82.246761364999941, 15.073581773500067],
            [-82.24668837, 15.074836300000086],
            [-82.246623258999961, 15.076091260500121],
            [-82.246566034999944, 15.077346605000045],
            [-82.246528047, 15.078288327000067],
            [-82.246494989999917, 15.079230234500059],
            [-82.246457490499978, 15.080486323000017],
            [-82.246427884500008, 15.081742623000153],
            [-82.246406171999837, 15.08299908350007],
            [-82.246392354499989, 15.084255656000053],
            [-82.246386433499936, 15.085512290500091],
            [-82.246386926999946, 15.086454778000032],
            [-82.246392354499989, 15.087397250000109],
            [-82.246406171999837, 15.08865382250012],
            [-82.246421469999973, 15.08959618599998],
            [-82.246441700499986, 15.090538456500113],
            [-82.246465879, 15.091480634000121],
            [-82.246494989999917, 15.092422672000069],
            [-82.246540380999932, 15.093678500500104],
            [-82.246593660999906, 15.094934019000121],
            [-82.246654828999908, 15.096189177500108],
            [-82.246723881999941, 15.097443927500095],
            [-82.24680081749986, 15.098698218500047],
            [-82.246885632499954, 15.099952001500142],
            [-82.246978322999951, 15.101205226500085],
            [-82.247052761999981, 15.102144769999981],
            [-82.247132118999957, 15.10308391100007],
            [-82.247244482999946, 15.104335525500019],
            [-82.247333669999932, 15.105273784000062],
            [-82.247427768999898, 15.10621156250015],
            [-82.247525794999945, 15.107148937999952],
            [-82.247628727999938, 15.10808578800011],
            [-82.247772509999834, 15.109334184000019],
            [-82.24792413249989, 15.110581652000107],
            [-82.248042747, 15.111516646000027],
            [-82.24816625599999, 15.11245100550012],
            [-82.248293676999879, 15.113384839999966],
            [-82.248425986499939, 15.114317994500126],
            [-82.248608910499883, 15.115561257500062],
            [-82.248799642499961, 15.116803347500152],
            [-82.248998174499945, 15.118044214000108],
            [-82.249151944999937, 15.118974073000047],
            [-82.249310582499959, 15.119903114000167],
            [-82.249528580999907, 15.121140708999988],
            [-82.249754351499888, 15.122376910000142],
            [-82.249928531999871, 15.123303163000131],
            [-82.250107558999957, 15.124228491000125],
            [-82.250290461, 15.125153061000063],
            [-82.250478201999982, 15.126076661000084],
            [-82.25066981, 15.126999466000072],
            [-82.250866246499896, 15.127921255500084],
            [-82.251066542999979, 15.12884221400013],
            [-82.251271658999912, 15.129762110499982],
            [-82.251551563999953, 15.130987189000152],
            [-82.251839161499902, 15.132210485500153],
            [-82.252059661, 15.133126817000075],
            [-82.252284954499942, 15.134041981000124],
            [-82.252514080000026, 15.134956193000093],
            [-82.252747989000028, 15.135869193500085],
            [-82.253066237499922, 15.137084876],
            [-82.253392116999919, 15.138298534500095],
            [-82.253641289999848, 15.139207488000139],
            [-82.253895219499952, 15.140115123500138],
            [-82.254240122499965, 15.141323514000064],
            [-82.25459261149993, 15.14252971300003],
            [-82.254861710999933, 15.143432966999981],
            [-82.255135536999944, 15.144334800000067],
            [-82.255506928499983, 15.145535314000099],
            [-82.255885855999935, 15.146733470500081],
            [-82.256174753000039, 15.147630589000116],
            [-82.256468343499876, 15.148526182500078],
            [-82.256765683, 15.149420538000058],
            [-82.257067700499903, 15.150313325000113],
            [-82.257476617999941, 15.151501581000034],
            [-82.257787967999946, 15.152391156000093],
            [-82.258103972499924, 15.153279088500071],
            [-82.258531503499853, 15.154460775],
            [-82.25896645100002, 15.155639751500061],
            [-82.259297287000038, 15.156522265000135],
            [-82.259632739999802, 15.157403033999984],
            [-82.26008615249998, 15.158575033500099],
            [-82.260546919499916, 15.159744161000063],
            [-82.261015023499908, 15.16091037000011],
            [-82.261490445999868, 15.162073615000068],
            [-82.261973167499974, 15.163233850000054],
            [-82.262463168999972, 15.164391029000072],
            [-82.262835210999924, 15.16525697800013],
            [-82.263211780499944, 15.166120968000129],
            [-82.263591967000025, 15.166983372000161],
            [-82.26397666299988, 15.167843774500071],
            [-82.264495590499962, 15.16898827450008],
            [-82.265021697999913, 15.170129491000054],
            [-82.265420755999884, 15.170983327000087],
            [-82.265824278999986, 15.171835061500005],
            [-82.266368247499997, 15.172967874000065],
            [-82.266919323499934, 15.174097245500107],
            [-82.267337059999903, 15.174942100000166],
            [-82.267759213999938, 15.175784756000084],
            [-82.268327961499864, 15.176905332500112],
            [-82.268758916999957, 15.177743520999954],
            [-82.269194254999917, 15.178579442000128],
            [-82.269780532999931, 15.179690947000054],
            [-82.270224601, 15.180522263000015],
            [-82.270673015499938, 15.181351243000123],
            [-82.271276679999914, 15.182453402000093],
            [-82.271887257499941, 15.18355174600002],
            [-82.272504723999901, 15.184646232500128],
            [-82.27312905499997, 15.185736817500114],
            [-82.273760226499832, 15.186823458500143],
            [-82.274398212499875, 15.187906112000078],
            [-82.275042988999957, 15.188984735500085],
            [-82.27569452949993, 15.190059286500102],
            [-82.276352808999974, 15.191129722999975],
            [-82.276850715999871, 15.19192995500002],
            [-82.277352805999868, 15.192727569500065],
            [-82.278027817, 15.193787534000066],
            [-82.278538231999875, 15.194579846000167],
            [-82.279052788999905, 15.195369476000039],
            [-82.27974436699995, 15.196418707000092],
            [-82.280442522999977, 15.197463572000103],
            [-82.281147230499982, 15.198504029500086],
            [-82.281679841000027, 15.199281596000091],
            [-82.282216514999845, 15.200056362500092],
            [-82.282937478499861, 15.201085623000054],
            [-82.283664894499907, 15.202110333500045],
            [-82.28439873499994, 15.203130452500162],
            [-82.284953114999979, 15.203892651000032],
            [-82.285511477499938, 15.204651936000161],
            [-82.286073015999989, 15.205408876],
            [-82.286638509999875, 15.206162864500058],
            [-82.287207156999955, 15.206914478000144],
            [-82.287779731999905, 15.207663105000066],
            [-82.288548379999895, 15.208657260500033],
            [-82.2893232589999, 15.209646566500098],
            [-82.289908295999879, 15.210385494],
            [-82.290497194999944, 15.211121349000038],
            [-82.29108917, 15.21185473000007],
            [-82.291684976999903, 15.212585001500045],
            [-82.292283837999975, 15.213312771],
            [-82.292886501499964, 15.214037395000091],
            [-82.293695099499814, 15.214999338500135],
            [-82.294509725499978, 15.215956182500079],
            [-82.29533034699989, 15.216907888500131],
            [-82.29615693249994, 15.21785442000008],
            [-82.2969894485, 15.21879573950001],
            [-82.297617523999861, 15.219498451000035],
            [-82.298249270999918, 15.220197864500122],
            [-82.298883942000032, 15.220894625000099],
            [-82.299522251999974, 15.221588053500042],
            [-82.300378160999827, 15.222508154500147],
            [-82.301239833999915, 15.223422859000024],
            [-82.301889672999948, 15.224105496],
            [-82.30254307699991, 15.22478472050004],
            [-82.303199320000033, 15.225461202],
            [-82.303859096999986, 15.226134238500052],
            [-82.304743484499937, 15.227027000500115],
            [-82.305633463999982, 15.227914188000099],
            [-82.306304423999904, 15.228576075999982],
            [-82.306978840499966, 15.229234441],
            [-82.307656008999913, 15.229889976000081],
            [-82.308336600499956, 15.230541956000096],
            [-82.309019916999972, 15.231191079999988],
            [-82.309706622999954, 15.231836616500075],
            [-82.310396026999967, 15.232479271000088],
            [-82.311088786499937, 15.233118307000112],
            [-82.311784216999882, 15.233754435999984],
            [-82.312482967999983, 15.234386915000087],
            [-82.313419037999893, 15.235225329000102],
            [-82.31436035699997, 15.236057845000047],
            [-82.31530688849989, 15.236884430500012],
            [-82.31625859549996, 15.237705052500061],
            [-82.316975588999895, 15.238316774000097],
            [-82.317695775499942, 15.238924732500081],
            [-82.318660254499889, 15.239730304000076],
            [-82.319629775500005, 15.240529800000061],
            [-82.32036004699998, 15.241125607000058],
            [-82.32109342849995, 15.24171758300001],
            [-82.322075387999888, 15.242501751500114],
            [-82.322814927999985, 15.243086015000173],
            [-82.323557517500006, 15.243666397500178],
            [-82.324551672999888, 15.244435045500111],
            [-82.325300298999821, 15.245007621],
            [-82.326051912499963, 15.245576268000079],
            [-82.326805901999961, 15.246141761999965],
            [-82.327562841500026, 15.24670330000005],
            [-82.328575999499918, 15.247446722000191],
            [-82.329593808499936, 15.248183763500023],
            [-82.33061622799994, 15.24891439550008],
            [-82.331643218499948, 15.249638589000099],
            [-82.332674738499946, 15.250356316000065],
            [-82.333710747999987, 15.251067546500153],
            [-82.334490537999955, 15.251596895000134],
            [-82.335273089999959, 15.25212215350011],
            [-82.336320143, 15.252817024000095],
            [-82.337371541499891, 15.253505302000107],
            [-82.33816278300003, 15.254017375000117],
            [-82.338956695499945, 15.254525298500099],
            [-82.340018775499885, 15.255196977000182],
            [-82.340817962999893, 15.255696559000043],
            [-82.341619755499835, 15.256191949000097],
            [-82.342423615999877, 15.256683976999952],
            [-82.343230041999902, 15.257171788500031],
            [-82.344308665499852, 15.257816564500089],
            [-82.345391318999845, 15.258454551000071],
            [-82.346477959999959, 15.25908572200008],
            [-82.347295408999912, 15.259554828000077],
            [-82.348115302999901, 15.260019646000089],
            [-82.349211723999929, 15.260633671500116],
            [-82.350311980999919, 15.261240795500086],
            [-82.351416030999985, 15.26184099450002],
            [-82.352246415000025, 15.262286802999981],
            [-82.353079122499963, 15.262728257000092],
            [-82.353913671000043, 15.263166219000013],
            [-82.354750501999916, 15.26359980550005],
            [-82.355869286499882, 15.264172070000015],
            [-82.356991644499942, 15.264737294000142],
            [-82.358117531499914, 15.265295454500105],
            [-82.358964128999844, 15.26570964800014],
            [-82.359812882499966, 15.266119403500156],
            [-82.360663345000034, 15.266525601000055],
            [-82.361515923499837, 15.266927338500025],
            [-82.362655481999923, 15.267457029500164],
            [-82.36379834549993, 15.267979550000021],
            [-82.364657534999935, 15.26836694699999],
            [-82.365518740499908, 15.268749840500092],
            [-82.366535013290729, 15.269266820551664],
            [-79.873871428000029, 16.258191422000138],
            [-79.871781234599865, 16.25928327260003],
            [-79.870527471999822, 16.256976685000083],
            [-79.870157744999972, 16.256296486000011],
            [-79.769347762999985, 16.07083306900013],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "NIU", Country: "Niue" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-166.64305565500004, -17.557777664],
            [-166.614892654999977, -17.829321664999938],
            [-166.58118065499994, -18.055845664999921],
            [-166.581053655, -18.05669666499999],
            [-166.580815655000038, -18.058297664999941],
            [-166.580600655000012, -18.059749665],
            [-166.570656654999965, -18.126797665000012],
            [-166.421834655, -19.03084866599994],
            [-166.390771655000037, -19.21686566599999],
            [-166.313312654999976, -19.694326666],
            [-166.383524655000031, -19.942044666999962],
            [-166.463749654999987, -20.224143666999964],
            [-166.466927655000035, -20.235298666999938],
            [-166.467505654999968, -20.236462666999969],
            [-166.467891654999846, -20.237817667],
            [-166.688367655, -20.680745667000011],
            [-166.729997655000034, -20.764070666999956],
            [-166.731589654999965, -20.766192666999927],
            [-166.73391265500004, -20.770838667000035],
            [-166.744126654999974, -20.784449667],
            [-166.745720655000042, -20.787637666999984],
            [-166.856020655, -20.934430667999948],
            [-167.103598656000031, -21.262668667999932],
            [-167.107416656, -21.266204667999943],
            [-167.109573656000038, -21.269056668000019],
            [-167.11854465599987, -21.277365667999973],
            [-167.121546656000021, -21.281332668000019],
            [-167.137012656, -21.295652667999946],
            [-167.144027656, -21.304921668],
            [-167.149517656000029, -21.312174667999983],
            [-167.150413655999898, -21.313359667999975],
            [-167.153199155999971, -21.316021167999921],
            [-167.298172655999934, -21.450095667999946],
            [-167.596874656000011, -21.725051667999921],
            [-168.112205657, -22.05461066899997],
            [-168.683333657, -22.29442166900003],
            [-168.708751323666604, -22.300441002333315],
            [-168.715025656999956, -22.303028668999943],
            [-168.716955657000028, -22.30381566899996],
            [-168.851359656999961, -22.335413668999948],
            [-169.325767657999961, -22.446042668999951],
            [-169.887740657999984, -22.482159669000012],
            [-169.952523658000018, -22.486210668999988],
            [-169.954523658000028, -22.486011668999964],
            [-169.957621658000022, -22.486204669000031],
            [-170.581983658999945, -22.423030669000013],
            [-171.183720658999988, -22.258630668999942],
            [-171.18541915900002, -22.257907168999949],
            [-171.29051866, -22.209290668999955],
            [-171.408884659999984, -22.157162668999945],
            [-171.47620766, -22.030780669000023],
            [-171.524326660000042, -21.941586667999957],
            [-171.56484466, -21.866545668000029],
            [-171.87025166, -20.90118166799995],
            [-171.956874659999983, -20.365883667],
            [-172.00253766000003, -20.053361666999962],
            [-171.997829660000036, -19.916573666999952],
            [-171.986940659999959, -19.325884666],
            [-171.985187659999951, -19.305529666],
            [-171.983585660000017, -19.290672665999963],
            [-171.97354965999989, -19.201010665999974],
            [-171.956643659999969, -19.065413665999927],
            [-171.954159659999959, -19.045288666000019],
            [-171.944772659999899, -18.969934666000043],
            [-171.90055066, -18.618197664999954],
            [-171.86485666, -18.333824665],
            [-171.86182766, -18.305598664999948],
            [-171.848109660000034, -18.179555665],
            [-171.83307166000003, -18.039614664999974],
            [-171.803214659999924, -17.763481665000043],
            [-171.79615666, -17.697351664999957],
            [-171.78435066, -17.575956663999918],
            [-171.76605665999989, -17.405814663999934],
            [-171.711473659999967, -17.342977664],
            [-171.54353566, -17.148469663999947],
            [-171.54118666, -17.145749663999965],
            [-171.445075660000043, -17.034539664000022],
            [-171.284166659999983, -16.825833664000015],
            [-171.208055658999967, -16.812777663999967],
            [-170.319166659000018, -16.654722663999976],
            [-169.933055658000029, -16.582777663999963],
            [-169.319999658000029, -16.626666664000041],
            [-169.305277657999966, -16.631944663999931],
            [-168.434722657000037, -16.938888663999933],
            [-166.701944655, -17.529166663999973],
            [-166.64305565500004, -17.557777664],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "NLD", Country: "Netherlands" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.011800124348611, 50.757273261109844],
            [5.92639121296105, 50.756100129938091],
            [5.898918184122124, 50.753882110713619],
            [5.870554158973448, 50.76082702042811],
            [5.79750017290138, 50.769436073203678],
            [5.739863189020866, 50.759854049104717],
            [5.698609137856522, 50.757773157816644],
            [5.691909147353499, 50.760554105659992],
            [5.704236077112057, 50.782009095640035],
            [5.701945135329822, 50.805827111769872],
            [5.693936059185148, 50.808818110107438],
            [5.683609219168034, 50.811382134295968],
            [5.653609214152937, 50.82360915176821],
            [5.638818172492108, 50.848882098655338],
            [5.651391194928294, 50.875136063492846],
            [5.758054098257219, 50.960000151172267],
            [5.764163332222665, 50.990000156187591],
            [5.776945064048675, 51.02583614512389],
            [5.81805410828747, 51.115000149144407],
            [5.847136127264264, 51.153191117603569],
            [5.7541632187922, 51.189991193045785],
            [5.644718194153768, 51.203609103534973],
            [5.568336089597267, 51.220709191889],
            [5.552918081577104, 51.269645089696951],
            [5.508336079567016, 51.2942270324844],
            [5.474236150421234, 51.286873253531837],
            [5.404373154896689, 51.266036009817526],
            [5.329718057675393, 51.262218052910498],
            [5.238973226711607, 51.262282090650899],
            [5.23333606153426, 51.309373131564598],
            [5.193473238663046, 51.31950919946496],
            [5.161527123363101, 51.315127140472669],
            [5.141945153471795, 51.319718076492791],
            [5.081073257870742, 51.401244993232524],
            [5.10124514610925, 51.434718123657319],
            [5.076809216075503, 51.469300179875063],
            [5.038473240691133, 51.486945091937002],
            [5.017154037542696, 51.470618150334076],
            [4.997082229228567, 51.436318061339762],
            [4.940482254411904, 51.40235408666382],
            [4.853054142543726, 51.414444982028215],
            [4.832782174381293, 51.429991065529066],
            [4.845482266470015, 51.475273124094656],
            [4.825836091200301, 51.492218147239441],
            [4.797636183716122, 51.501245121708337],
            [4.766182253772115, 51.499927151249551],
            [4.702082151973002, 51.466945032714193],
            [4.671145050180314, 51.432554083889329],
            [4.647636158640353, 51.423191162739954],
            [4.540345115485763, 51.431173249156359],
            [4.540418205681704, 51.454509138214064],
            [4.544336074875048, 51.483044992378666],
            [4.484300190298995, 51.480136136692408],
            [4.395691235908203, 51.451518139876725],
            [4.399027065743326, 51.413882221047459],
            [4.417782244703062, 51.398327085090898],
            [4.433473167491343, 51.370136062424081],
            [4.412918058638809, 51.358464262219854],
            [4.388054148627589, 51.357500175713938],
            [4.352918216246564, 51.361245042424912],
            [4.279673290419339, 51.376591133715635],
            [4.252363450425776, 51.375145100769771],
            [4.26098182660013, 51.355436299616031],
            [4.280181871791001, 51.344199828997148],
            [4.286763355529445, 51.312445325043541],
            [4.327699879322267, 51.290118303449162],
            [4.309681842125997, 51.262027332077935],
            [4.3023728323314, 51.26318184520818],
            [4.300081861440219, 51.267654301705591],
            [4.312009334109121, 51.286381896321302],
            [4.280645296138061, 51.301127283098822],
            [4.263563388235696, 51.317045319281362],
            [4.255836333775221, 51.336236338244305],
            [4.238899953133853, 51.350427189707659],
            [4.221663311846385, 51.33541805173644],
            [4.167218156704052, 51.297364211213619],
            [4.127782140343697, 51.278882114660178],
            [4.062218222969875, 51.252218107117983],
            [3.952500115925318, 51.214436007897007],
            [3.895418181674529, 51.205691168289547],
            [3.793818115516046, 51.230273111076997],
            [3.788745052423963, 51.262845019269662],
            [3.669163289332971, 51.29277310755549],
            [3.60000018272558, 51.304164114002731],
            [3.521736173262866, 51.283264173652469],
            [3.52298222699261, 51.258953972166807],
            [3.474718222545164, 51.242636083019192],
            [3.439863251559132, 51.244782185514168],
            [3.388327119740893, 51.268673124201996],
            [3.380136156296828, 51.275273202418759],
            [3.373609168275834, 51.310000097923933],
            [3.370863338858669, 51.373854255030153],
            [3.364722160400106, 51.373610748600015],
            [3.353889000000265, 51.37944],
            [3.296389, 51.45],
            [3.212222, 51.484722000000147],
            [3.081389000000115, 51.551667],
            [2.539443826000081, 51.869999806000067],
            [2.626666667000222, 51.983333332000257],
            [2.650134385000229, 52.008036194000169],
            [2.65833333300003, 52.016666666000162],
            [2.674544739000225, 52.042484830500143],
            [2.703333333000103, 52.088333332000218],
            [2.715000000000259, 52.099999999000147],
            [2.840000000000146, 52.206666666000046],
            [2.933333333000292, 52.289999999000088],
            [3.024339698000119, 52.382219782000107],
            [3.058333333000178, 52.416666666000253],
            [3.183333322500005, 52.621666649000076],
            [3.205000000000155, 52.783333332000211],
            [3.175000000000239, 52.883333332000291],
            [3.056666667000115, 53.301666666000187],
            [3.016666667000209, 53.469999999000152],
            [2.988333332999957, 53.584999999000274],
            [2.95666666700015, 53.668333332000259],
            [2.866666667000231, 53.963333332000104],
            [2.763333333000332, 54.379999999000177],
            [2.89833333300021, 54.621666666000294],
            [3.370277747600142, 55.764999925800055],
            [4.333333333000041, 55.333333329000027],
            [5.0, 54.999999999000124],
            [5.0, 54.619999999000129],
            [6.000000000000227, 54.186666668999976],
            [6.107833333000201, 53.999111109000012],
            [6.264250000000231, 53.814694439000164],
            [6.33286111100017, 53.750833329000045],
            [6.347138889000064, 53.725222218999988],
            [6.403968971000211, 53.687273358000084],
            [6.454476215000113, 53.651196759500067],
            [6.463114580000138, 53.646877576000122],
            [6.526629389000163, 53.615120172000104],
            [6.605997811000293, 53.593474240000205],
            [6.649289340000109, 53.535751977000245],
            [6.728658340000152, 53.514105976999986],
            [6.808026340000026, 53.514105976999986],
            [6.86574934000015, 53.521320977000073],
            [6.909041340000158, 53.528536977000101],
            [6.937902506000171, 53.499675553000088],
            [6.945117340000081, 53.441952976999971],
            [6.973979340000199, 53.377014977000101],
            [6.9949343400001, 53.342345977000036],
            [7.021291340000062, 53.323177977],
            [7.071609340000123, 53.30480797700011],
            [7.109147340000277, 53.296820977000095],
            [7.150679340000295, 53.291229977000057],
            [7.174640341000156, 53.287236977000077],
            [7.208363291398712, 53.242809558668625],
            [7.207218106114681, 53.17610913991598],
            [7.210973198747581, 53.008882124471597],
            [7.19618215708681, 52.96250019256837],
            [7.179718087547656, 52.934164162976288],
            [7.133054020782652, 52.888882104410925],
            [7.091063200327426, 52.836909107798022],
            [7.073336145614007, 52.819718159613217],
            [7.066245055507807, 52.792500108269792],
            [7.065000175244734, 52.760418038499949],
            [7.063609114589838, 52.721382174198425],
            [7.053473214327596, 52.649582120844741],
            [7.033745064044581, 52.633191141501356],
            [6.906218094911196, 52.648118137632025],
            [6.765763213560092, 52.651173173709878],
            [6.72083604477632, 52.629436048868058],
            [6.718918097686242, 52.626782170677416],
            [6.727773243139779, 52.618609144506195],
            [6.758127132107973, 52.56464511060733],
            [6.72250018783734, 52.55943609304552],
            [6.689582274680618, 52.550553957863656],
            [6.704027143739381, 52.48819108932156],
            [6.758891226403705, 52.460964153160603],
            [6.954236174318851, 52.437218053760375],
            [6.984027134668281, 52.457354067453082],
            [7.062982148230446, 52.390964197338064],
            [7.070418069834432, 52.355827091490426],
            [7.05811812980437, 52.337636014615896],
            [7.035136124699363, 52.305827027252292],
            [7.029100148567807, 52.27826414441131],
            [7.05309116717973, 52.237764129258721],
            [7.042218162350082, 52.231664115386934],
            [6.964718079545094, 52.190273103924426],
            [6.902636172398331, 52.17221814915797],
            [6.875073289557292, 52.142354098612714],
            [6.856036143373956, 52.120482192767497],
            [6.759427165294142, 52.11456423383305],
            [6.736391180732824, 52.07666411741485],
            [6.728609086526831, 52.035273105952456],
            [6.79750011655625, 52.008745052880215],
            [6.828954046500428, 51.975754049527723],
            [6.783054074030986, 51.92471814969241],
            [6.746391126525168, 51.906109151124383],
            [6.72250018783734, 51.897909135225007],
            [6.684654050875736, 51.911664173650422],
            [6.594718073570675, 51.896109037694032],
            [6.527500239057815, 51.874445003048208],
            [6.46278222335485, 51.853609100438348],
            [6.351109121207969, 51.848054077912479],
            [6.182218130340686, 51.895273194307038],
            [6.123609180965218, 51.888054028719921],
            [6.00305411127465, 51.829991076518809],
            [5.963609210096763, 51.806664239916415],
            [5.982745095099915, 51.767218165272197],
            [5.952054085985196, 51.747527063001201],
            [6.028891160247156, 51.706664111440375],
            [6.093609175950121, 51.605827133749187],
            [6.13402704845106, 51.570827155837776],
            [6.158609158876686, 51.558327055959182],
            [6.204863182937004, 51.513464260191824],
            [6.222082126678231, 51.467354069590385],
            [6.222500216009905, 51.36319115270976],
            [6.143054022920126, 51.295273261641569],
            [6.077636118300063, 51.241382150300637],
            [6.071663341718647, 51.21409117639945],
            [6.084445073544657, 51.174264228073866],
            [6.097500223052918, 51.13110010880888],
            [6.00305411127465, 51.084164133104252],
            [5.905554136884916, 51.063118180000231],
            [5.865000142275903, 51.045345024819255],
            [5.869445065542322, 51.01888218295386],
            [5.902009094745949, 50.973118164954712],
            [5.943745105344078, 50.984300126736116],
            [6.024927190586197, 50.977982183380959],
            [6.080836161303239, 50.914718092606904],
            [6.084445073544657, 50.872082200881323],
            [6.058163280788506, 50.850482203976071],
            [6.01666330458437, 50.841664274172672],
            [6.008054084170851, 50.802218031890504],
            [6.011800124348611, 50.757273261109844],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "NOR", Country: "Norway" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.625306571000294, 84.163051352000195],
            [21.757867652500067, 84.13945035650022],
            [22.88097607300017, 84.113613852000128],
            [23.993920074000187, 84.085571852000214],
            [25.096031075000269, 84.055355352000277],
            [26.186685076000231, 84.022997351000242],
            [27.265304077000224, 83.988532351000174],
            [27.568306592000283, 83.978147906000231],
            [28.331354878000241, 83.951996641000164],
            [29.384352919000094, 83.913427291000176],
            [30.423858998000242, 83.872862800000178],
            [30.674065578000068, 83.862489716000084],
            [31.449480081000274, 83.830342351000183],
            [31.584291424000241, 83.819210680000253],
            [31.636704130000112, 83.814882777000037],
            [31.938807099500195, 83.802141699500169],
            [32.67912608200038, 83.77091885100026],
            [33.631821539000271, 83.728309055000238],
            [33.706738807000193, 83.724958327000252],
            [33.892759231000355, 83.716155277000041],
            [34.216792830000372, 83.76268550400016],
            [34.217595847333712, 83.762466538666843],
            [35.000080556000398, 83.351944444000196],
            [34.999187978000066, 80.305480129000273],
            [34.998888889000114, 79.284658333000209],
            [37.928517317000228, 78.640576650000298],
            [38.000000000000227, 78.62486111100003],
            [38.000000000000227, 78.622896405000176],
            [38.000000000000227, 75.800205556000037],
            [37.000000000000114, 75.194722222000166],
            [37.000000000000114, 74.932394768000051],
            [37.000000000000114, 73.686347222000279],
            [36.538457061000258, 73.366754043000185],
            [32.073329128000125, 70.274897766500089],
            [31.775991667000085, 70.197688889000176],
            [31.505397222000283, 70.120888889000241],
            [31.444800000000214, 70.099677778000228],
            [31.104363393500194, 69.979315370000165],
            [31.063023250000128, 69.952952889000187],
            [30.930712021000204, 69.867693006000309],
            [30.819463290375268, 69.796143345250186],
            [30.788002157152903, 69.790573206902991],
            [30.854836259964429, 69.792309114428974],
            [30.902773289158972, 69.731936044117219],
            [30.94333616858512, 69.690554085110364],
            [30.947218163232833, 69.654436128950238],
            [30.950827075474024, 69.608745033508853],
            [30.941245051375063, 69.57401813800368],
            [30.91236335988475, 69.550200121873843],
            [30.863891148962011, 69.536654128114264],
            [30.824718156724089, 69.531663207673375],
            [30.796945055750598, 69.530273152846974],
            [30.767218133141569, 69.530545061786768],
            [30.546109226449914, 69.54415408745848],
            [30.520273183305733, 69.547627045229774],
            [30.453327090094717, 69.567218067576562],
            [30.393054165296007, 69.590545071817019],
            [30.370554119625126, 69.603045004057549],
            [30.3405541146102, 69.617209079358986],
            [30.305273175303398, 69.630554075080113],
            [30.195973325228351, 69.665409213703981],
            [30.165000181251855, 69.668044986983944],
            [30.129445153710662, 69.666382185027373],
            [30.109582222424251, 69.66384515056717],
            [30.076663303439034, 69.646591170470458],
            [30.095136179898816, 69.628191216568212],
            [30.137282233201631, 69.628363213221974],
            [30.181527115065137, 69.57803608712112],
            [30.097773294283456, 69.503327178081349],
            [30.062773316372045, 69.487782100408594],
            [29.898609215994668, 69.432773178457012],
            [29.66500020942425, 69.373882261858114],
            [29.603336056332978, 69.360273236186401],
            [29.571945158300935, 69.353882202635305],
            [29.53832718858888, 69.347218086677913],
            [29.48360911867843, 69.340818168309511],
            [29.408336107553282, 69.333054011376476],
            [29.313882284424153, 69.316182246065409],
            [29.295554079613225, 69.295127072868127],
            [29.307918225021865, 69.264445116208492],
            [29.318554021991076, 69.243418106205809],
            [29.295691207549794, 69.188327041602975],
            [29.254445035374488, 69.119991228841286],
            [29.234863233121359, 69.104718060108553],
            [29.200418137201837, 69.083191153399071],
            [29.177773252243526, 69.070545040766604],
            [29.129718124823967, 69.048044995095722],
            [29.099163237816924, 69.036373194891496],
            [29.056527178453166, 69.028736107610712],
            [29.020000185417075, 69.031391159268196],
            [29.000054105650861, 69.036873259236302],
            [28.957336071274028, 69.05161820042963],
            [28.890554095727509, 69.071382225258105],
            [28.857773310507014, 69.082218182075792],
            [28.827500223085565, 69.09360013606738],
            [28.802218223743012, 69.105545018678072],
            [28.785063317742015, 69.124082100516489],
            [28.782918221075363, 69.155136046039956],
            [28.796663201217143, 69.188327041602975],
            [28.825418158331217, 69.236163233711238],
            [28.90277323421077, 69.27499122681337],
            [28.926800127368324, 69.286382233260611],
            [28.99708221805281, 69.315554106239745],
            [29.033336128682663, 69.330554024928233],
            [29.064163259905456, 69.343891141660322],
            [29.122218165479524, 69.370818173324608],
            [29.269163322115503, 69.45193605318849],
            [29.285554133820938, 69.463609194497337],
            [29.298473161221295, 69.485336093417175],
            [29.180000156285303, 69.630682150560972],
            [29.127500273237331, 69.685818142164976],
            [29.102782208341978, 69.7059732665968],
            [29.000827084763955, 69.721518176631378],
            [28.946109182491284, 69.728609099099629],
            [28.909718143925261, 69.73389120685755],
            [28.744163318168944, 69.759718197546192],
            [28.694718135922756, 69.768600165089708],
            [28.405000166424941, 69.821654091939138],
            [28.379718167082558, 69.827500134144316],
            [28.36381819962844, 69.841036069620259],
            [28.34194512031695, 69.87720917870331],
            [28.316800248910567, 69.889163281407264],
            [28.276109126365327, 69.896654020658218],
            [28.226663273566828, 69.902218095639569],
            [28.197218150543449, 69.906391109966194],
            [28.159163304192134, 69.913882184493104],
            [28.102082208131833, 69.934573247815536],
            [27.991391129763883, 69.99790908768108],
            [27.976245198321521, 70.011518113352793],
            [27.971145145501112, 70.045509077757004],
            [27.937745105272427, 70.08303603184487],
            [27.910691171593498, 70.088609159281759],
            [27.873054079297788, 70.088045057196325],
            [27.599445121919928, 70.072491094706436],
            [27.570409203410748, 70.066382196017116],
            [27.421391202113568, 70.016663260812493],
            [27.298263223417166, 69.986244998827544],
            [27.3060360975295, 69.964918084328247],
            [27.286245082972528, 69.948609079998107],
            [27.206109226887577, 69.933318141630537],
            [27.074854154849618, 69.909573215696582],
            [27.045827121157515, 69.907773118165665],
            [26.894300248594902, 69.925418197865611],
            [26.865209177162797, 69.939373228501381],
            [26.806582290514484, 69.952054042212978],
            [26.609163324471808, 69.950963221330653],
            [26.47680016483082, 69.936382230163986],
            [26.44291816516818, 69.920827094207425],
            [26.420136152273699, 69.895963184196376],
            [26.398609245564046, 69.868745132852951],
            [26.37374516791499, 69.85220914658413],
            [26.174163335570825, 69.751663188572081],
            [26.155827084132682, 69.742491207177579],
            [26.134445184348806, 69.734982195377881],
            [26.100554132230684, 69.726382195057795],
            [26.004445050857669, 69.711936152532587],
            [25.983327181024208, 69.704300071080297],
            [25.959300120228704, 69.685682187695079],
            [25.944509246205882, 69.665200169038314],
            [25.969445072946371, 69.642491246339773],
            [25.970691126675945, 69.618809184679606],
            [25.953327176009111, 69.584163258359581],
            [25.874163285418888, 69.486373270119032],
            [25.833745077641993, 69.385273268305355],
            [25.811663288940565, 69.368609206555618],
            [25.77055407706365, 69.353882202635305],
            [25.752363335465475, 69.339154025248263],
            [25.739436093799895, 69.318327175094112],
            [25.713336188343305, 69.255263244168361],
            [25.708054080585327, 69.209018104925406],
            [25.73221810167928, 69.109163151012979],
            [25.768327173021873, 69.045273267346033],
            [25.761109180901258, 68.989163298590313],
            [25.692218150871668, 68.933882132422923],
            [25.661391187286824, 68.911653995691665],
            [25.652773249693951, 68.906391166311209],
            [25.639863274749075, 68.894300103308808],
            [25.617773271782482, 68.882763251745871],
            [25.585554074076242, 68.87887321047134],
            [25.562773234648432, 68.879436139090103],
            [25.508054158909658, 68.888318106633903],
            [25.48180019407198, 68.891518149637136],
            [25.437773241691616, 68.891109112760944],
            [25.406945104640499, 68.886382222271095],
            [25.384718141375743, 68.880554117338875],
            [25.363891123583187, 68.873882122392502],
            [25.234163234308028, 68.825273118809491],
            [25.214445142308648, 68.817491192241505],
            [25.188327131941264, 68.803327116939897],
            [25.169854087843305, 68.789027087168506],
            [25.154718214684749, 68.767773262865148],
            [25.105000117670443, 68.66978210894797],
            [25.105073207866155, 68.637909083843965],
            [25.076382288492709, 68.621382150030627],
            [24.934918225996569, 68.580809212320446],
            [24.854473245321515, 68.593182242546447],
            [24.831663236870384, 68.617209135704002],
            [24.804718100295077, 68.636653977658909],
            [24.773327202263033, 68.652773215700677],
            [24.754445121288882, 68.660263284399093],
            [24.690282155215812, 68.676373134709365],
            [24.628891252168444, 68.687209091526711],
            [24.578891187930708, 68.69137322103586],
            [24.513336155374418, 68.695827029119812],
            [24.450273230276991, 68.698882232835786],
            [24.416945106777803, 68.701663180678963],
            [24.354100111163746, 68.710200149087086],
            [24.206109228284561, 68.749027136360894],
            [24.120554136505461, 68.783600140123383],
            [24.090418177020297, 68.802418183357076],
            [24.060827208881335, 68.818736072504691],
            [24.03694515501104, 68.825273118809491],
            [23.976391268817224, 68.832491110929936],
            [23.899436177358155, 68.833882171584889],
            [23.856391248756552, 68.832491110929936],
            [23.824509171197064, 68.826309122044734],
            [23.796109103864524, 68.789291117119149],
            [23.762500186608008, 68.759427066573949],
            [23.740836151962128, 68.742491095884418],
            [23.705063194937793, 68.716863259215984],
            [23.671945121932765, 68.71110908266985],
            [23.583054032680366, 68.705273266386655],
            [23.517500173590349, 68.69860915042949],
            [23.467218142129013, 68.691663234886676],
            [23.407773181728317, 68.680827110431153],
            [23.333054046766733, 68.659436158191568],
            [23.286663230046031, 68.644427019409591],
            [23.260554104495952, 68.636382236357292],
            [23.196391138422882, 68.629854074869797],
            [23.164718105528834, 68.637500214605893],
            [23.139445158641763, 68.649154077537503],
            [23.099163240610721, 68.674709159286351],
            [23.068191270100669, 68.693600125077978],
            [22.863609129584177, 68.693054127903395],
            [22.802773276166874, 68.69137322103586],
            [22.550000221398534, 68.737782142667356],
            [22.500000157160798, 68.728991202592454],
            [22.398327168444439, 68.71110908266985],
            [22.366800148304492, 68.795409068264121],
            [22.347636100106683, 68.843609202609116],
            [22.278891250468973, 68.881653990676568],
            [22.198991261139838, 68.937700089330008],
            [22.041382144340275, 69.052218177060524],
            [21.994600228016566, 69.078373235440267],
            [21.681945180414857, 69.284718090199419],
            [21.320827141984466, 69.326109101661984],
            [21.047082230136709, 69.223745108845776],
            [21.03027316146165, 69.210545120050256],
            [21.042218211710292, 69.160827023035949],
            [21.053609218157533, 69.140544996589313],
            [21.072636138419114, 69.122082178413336],
            [21.109163299093211, 69.097418092974465],
            [21.079445093663537, 69.051518120505193],
            [21.063473209480151, 69.036800169040362],
            [21.033054109304999, 69.033327043630891],
            [20.98055405861885, 69.045273267346033],
            [20.935554134915265, 69.058609210611621],
            [20.829027186056408, 69.084991083291811],
            [20.773891194452233, 69.09360013606738],
            [20.731109122334942, 69.096100122515622],
            [20.697500205078597, 69.092773177497861],
            [20.675000159407716, 69.087218154971822],
            [20.609445126851256, 69.06944499979079],
            [20.580927209959668, 69.060300175762791],
            [20.535273162530359, 69.056382138931667],
            [20.096945055145284, 69.04221806363006],
            [20.238891244713386, 68.968600143110564],
            [20.314718132001985, 68.928327109896884],
            [20.350273159542951, 68.786654002734849],
            [20.313891173432467, 68.754718113356887],
            [20.238327142627952, 68.69137322103586],
            [20.202500206147192, 68.662218111863297],
            [20.177218206804582, 68.646945110768513],
            [20.063054170665112, 68.583054053635237],
            [19.956391267336357, 68.543882234863645],
            [20.088054035146115, 68.501663259003294],
            [20.204236098299702, 68.47089111306542],
            [20.17152706217064, 68.445963165313913],
            [20.075836070129299, 68.414718112397736],
            [20.039163231977284, 68.39915409162387],
            [20.006663240514285, 68.381100142685739],
            [19.970418214701795, 68.355545060936834],
            [19.952363259935169, 68.342763161472817],
            [19.930827133132198, 68.337073190305375],
            [19.898327141669029, 68.341382159101727],
            [19.860554094903364, 68.349991211877295],
            [19.717218185784589, 68.372773224771777],
            [19.543891156468248, 68.399718193708907],
            [19.413327088529684, 68.41943611807028],
            [18.952218141716742, 68.487782156753781],
            [18.611945058351296, 68.475263281412083],
            [18.358054025334695, 68.539154003269118],
            [18.149436166065925, 68.515273122865324],
            [18.099536181752114, 68.508927183953347],
            [18.08624516548781, 68.499300065215436],
            [18.058054142820993, 68.439991226922814],
            [18.049582217981595, 68.400273243338802],
            [18.069445149268233, 68.354427082688233],
            [18.085827076156306, 68.31776329699187],
            [18.103882198560768, 68.280818046986099],
            [18.155282208270933, 68.166109187138716],
            [18.135554057987918, 68.150273257424999],
            [17.939718098183164, 67.997773245901158],
            [17.884163346695345, 67.945545104154974],
            [17.85805405350709, 67.948609192688252],
            [17.825554062043921, 67.955273141007467],
            [17.801936038124381, 67.964154102722659],
            [17.743327088748913, 67.984709211575364],
            [17.680273216106968, 68.004436020754042],
            [17.648891202892401, 68.014163219416275],
            [17.592500105103397, 68.02970913527929],
            [17.273609089159834, 68.090544988696593],
            [17.252582246795498, 68.074573272150985],
            [17.234545061663709, 68.062100162000945],
            [17.1883270797872, 68.030273237364554],
            [16.726945050567622, 67.899154119796265],
            [16.684718195718403, 67.832218084868998],
            [16.620282147239038, 67.732209071575653],
            [16.588054064715493, 67.682218059792888],
            [16.5736091956569, 67.661236144429552],
            [16.508327077868557, 67.609163235530559],
            [16.403327144134551, 67.530000183130653],
            [16.382500126342222, 67.515545088150134],
            [16.199163260587738, 67.500827136685075],
            [16.088054092888058, 67.405400174594547],
            [16.107636062779363, 67.385691135050877],
            [16.138609206755916, 67.367491173358815],
            [16.161663296228198, 67.355818032049967],
            [16.212636164151547, 67.33373607571059],
            [16.261945056651655, 67.305545053043943],
            [16.337500202638466, 67.260818044108248],
            [16.361382256508762, 67.237782227184937],
            [16.402500185565032, 67.170545114294612],
            [16.353882297164546, 67.017773193830976],
            [16.010000133919675, 66.890827090589312],
            [15.731109236421901, 66.685000069809746],
            [15.625827167826287, 66.605818074308729],
            [15.527773149634925, 66.558045081750777],
            [15.362782258326206, 66.480000175237706],
            [15.371391143463597, 66.461654033154161],
            [15.40055413162537, 66.406936130881718],
            [15.446854088515124, 66.321382212568892],
            [15.468054100999836, 66.28387319575404],
            [15.025273191359474, 66.149991235253594],
            [14.981391245904319, 66.149154050762036],
            [14.717782234318804, 66.140544997986524],
            [14.507082142657509, 66.123600142479688],
            [14.519163315014225, 66.078891238454844],
            [14.537636191474235, 66.016382189521039],
            [14.569445178837611, 65.949436096309796],
            [14.580273256666146, 65.93110906496554],
            [14.608891253481829, 65.876391162693096],
            [14.633191229045622, 65.821800162435011],
            [14.621391185722189, 65.797209167192079],
            [14.603891280585628, 65.773327113321898],
            [14.588054009767291, 65.756945018795818],
            [14.565282222794963, 65.736373146136771],
            [14.534718115694432, 65.70096329315902],
            [14.500554148808021, 65.585827123886418],
            [14.497218151335119, 65.516100082831827],
            [14.495282266972254, 65.446382094232717],
            [14.494027160787198, 65.367636125336219],
            [14.493054021825799, 65.313600174708029],
            [14.368891213360314, 65.246654081496843],
            [14.35610914625812, 65.208600073335901],
            [14.329163339130844, 65.149718041554308],
            [14.319445025285802, 65.129991232375573],
            [14.308836050407137, 65.115236065260433],
            [14.296436198090817, 65.102127103933697],
            [14.235282167628156, 65.048873184873912],
            [14.091391208879656, 64.949154017793205],
            [13.955827168045118, 64.835536146466097],
            [13.879163263903195, 64.77110009798696],
            [13.832782170190114, 64.733318166403819],
            [13.725573269687345, 64.652263150813894],
            [13.702218102251948, 64.634573144112835],
            [13.676945155365047, 64.607773182100914],
            [13.668263347669722, 64.580200073337949],
            [13.820273185494443, 64.52943608244243],
            [13.90083618336638, 64.507491086401274],
            [14.032500124642581, 64.488054123435575],
            [14.116391240998439, 64.470554050660837],
            [14.150554034418576, 64.33929109963367],
            [14.150691162354576, 64.179845004929007],
            [13.985000214490526, 64.015545117719512],
            [13.291391186900512, 64.086653999335567],
            [13.230000116215365, 64.093045032886664],
            [13.19360924528695, 64.090000222730623],
            [13.135554004436926, 64.083600136724044],
            [13.03221821376377, 64.07110908930099],
            [12.988336100670836, 64.064436088526293],
            [12.938054069209329, 64.053327049302652],
            [12.846945128370635, 64.025273154572176],
            [12.794445077684429, 64.007218032167771],
            [12.681945184606207, 63.967218081360159],
            [12.63389123065275, 63.94277326650888],
            [12.530836066098914, 63.872773143048164],
            [12.473054075292964, 63.833327068404174],
            [12.346663189319429, 63.728882184299891],
            [12.146663267643532, 63.589573109116202],
            [12.178609215305528, 63.512218033236309],
            [12.195000194648912, 63.485273231937185],
            [12.137218203842963, 63.436663222525681],
            [12.07833617206137, 63.388327133710845],
            [11.998891152438034, 63.323882200414175],
            [11.93639115595937, 63.272218160753027],
            [12.02749121198093, 63.182445127646133],
            [12.036945160598975, 63.173882175338179],
            [12.144163281195461, 63.045273270140001],
            [12.168609101875006, 63.015827141288298],
            [12.151109196738275, 62.999445046762219],
            [12.11333614997244, 62.967218137705117],
            [12.05833611283856, 62.918609134122164],
            [12.028891157453359, 62.892491123754382],
            [12.050282277330894, 62.838882147275228],
            [12.066663198390529, 62.80305403732757],
            [12.089445043647004, 62.749445060848416],
            [12.072773270546463, 62.715827091136589],
            [12.046663306805669, 62.665273150735061],
            [12.047500156021215, 62.590000139610083],
            [12.084718153156757, 62.529163280364287],
            [12.149163254091775, 62.460273256163191],
            [12.178336132899233, 62.430827127311318],
            [12.204863347780986, 62.39735399688692],
            [12.245345090384205, 62.337982126682164],
            [12.256391265333889, 62.327218086594087],
            [12.271391184022718, 62.308054038396506],
            [12.29374504930172, 62.267082122093939],
            [12.294618108338909, 62.258218091823096],
            [12.258609116920525, 62.142491166013144],
            [12.215000253871722, 62.006100166608917],
            [12.200282134768656, 61.963336031764641],
            [12.181663245555001, 61.912500124139683],
            [12.170000162529959, 61.878609072021504],
            [12.159445167107719, 61.844445105135094],
            [12.124445021558302, 61.728609046945564],
            [12.144445080781111, 61.71750017535993],
            [12.399718121997125, 61.576936161629249],
            [12.437500221218272, 61.569018112953529],
            [12.474863225279421, 61.569582215038736],
            [12.526527097302221, 61.566663301068843],
            [12.773891142298226, 61.41471817153672],
            [12.856109230775928, 61.362500088074171],
            [12.831109198656918, 61.312218056612437],
            [12.796663264547078, 61.244991169644265],
            [12.772218114419672, 61.200827089327333],
            [12.670273216763633, 61.087773152447653],
            [12.637827204756888, 61.057536107209899],
            [12.621945174575785, 61.053053968293511],
            [12.602218197759271, 61.049718138458445],
            [12.567218219847803, 61.048045110579892],
            [12.500273300103061, 61.050827064251564],
            [12.458054156604703, 61.05389115278507],
            [12.432500248322128, 61.054445028948294],
            [12.40777329860947, 61.05389115278507],
            [12.388054033143817, 61.050553981845269],
            [12.2941633062714, 61.029444996829341],
            [12.247773160103009, 61.018609207650002],
            [12.216218144406241, 60.99874510289709],
            [12.239582196659171, 60.97693606132583],
            [12.271945060185999, 60.946109097740987],
            [12.308609181158658, 60.887218181142089],
            [12.336109199725684, 60.835827056249229],
            [12.352363218770677, 60.802218138992714],
            [12.364718144085884, 60.779445010915538],
            [12.382500184084222, 60.755554072227881],
            [12.425000121339906, 60.710273187129019],
            [12.505836033979961, 60.63000020310767],
            [12.590973204065563, 60.521800058732254],
            [12.607218170655244, 60.468400126918823],
            [12.600136133004526, 60.43388210844131],
            [12.589718097880507, 60.399163259563352],
            [12.571945110337879, 60.378054106909019],
            [12.532773291566286, 60.339027127425027],
            [12.506109116386085, 60.202073199401866],
            [12.507845176176772, 60.174091221401056],
            [12.502782171368494, 60.144718182745123],
            [12.494509232911355, 60.113044982213296],
            [12.469445163051773, 60.070000221249643],
            [12.316945151527761, 59.972636033691799],
            [12.191945158571116, 59.910273165149704],
            [12.163054079348967, 59.896945100873097],
            [12.13486322432027, 59.887636159180602],
            [12.107218198827638, 59.885827009193974],
            [12.034163206927303, 59.886109144055752],
            [12.003191236417251, 59.889582101827273],
            [11.965482227391874, 59.896945100873097],
            [11.87582721148209, 59.869991079480485],
            [11.852773289647587, 59.861382194343093],
            [11.815963323559572, 59.846100140792828],
            [11.882082290563289, 59.80235398216962],
            [11.896391205152099, 59.784718122562992],
            [11.898845091132557, 59.77247316781785],
            [11.903191275579218, 59.729300163735388],
            [11.899100236265895, 59.699718080413788],
            [11.818054105493445, 59.648682180578476],
            [11.764654006042008, 59.638045042504814],
            [11.667009192364844, 59.593573178702627],
            [11.739163297309915, 59.429718035277006],
            [11.783054127582204, 59.324718101542999],
            [11.79083605415002, 59.303327149303414],
            [11.797918091800966, 59.266527073861027],
            [11.792654088953611, 59.237836154487582],
            [11.769545181834587, 59.217536023130151],
            [11.761945142566219, 59.206245096607006],
            [11.752636033235376, 59.181245064488223],
            [11.741873166613971, 59.119991121739247],
            [11.751800189848325, 59.095136096545673],
            [11.624027128037369, 58.906936050555089],
            [11.599963186867512, 58.899182119543866],
            [11.585836159578207, 58.896663189994683],
            [11.496663270740157, 58.884718139745814],
            [11.456945119518366, 58.883745000784586],
            [11.431245030844082, 58.891036083100971],
            [11.423473162560072, 58.919163235665565],
            [11.429173923022176, 58.987636896715742],
            [11.42427934400024, 58.994833982000102],
            [11.416270344000168, 59.007554982000158],
            [11.409203062000188, 59.01886182800007],
            [11.401665344000207, 59.030639982000253],
            [11.397971873000188, 59.036070907000123],
            [11.39365556200022, 59.042418328000338],
            [11.382348562000288, 59.059378828000149],
            [11.371984062000138, 59.073041828000157],
            [11.361619203000089, 59.085762155000054],
            [11.347956062000151, 59.098011328000041],
            [11.338443713000117, 59.100938185000189],
            [11.32958234400013, 59.103664982000112],
            [11.317804344000137, 59.10554998300023],
            [11.300843562000296, 59.106020828000169],
            [11.29485126100019, 59.105534933000229],
            [11.283411562000197, 59.1046073280001],
            [11.262682344000098, 59.101780982000207],
            [11.249019344000089, 59.099895982000191],
            [11.245534779000195, 59.099295116000235],
            [11.235356562000106, 59.09754032800015],
            [11.212271062000298, 59.093771328000059],
            [11.188714614000048, 59.089060014000154],
            [11.176533934000133, 59.086601328000313],
            [11.156052760000193, 59.084482585000046],
            [11.14192781200012, 59.079538854000191],
            [11.134159091000299, 59.074595122000233],
            [11.131334101000334, 59.05976392700029],
            [11.129215359000341, 59.04705147400017],
            [11.123565380000116, 59.029395290000139],
            [11.115796659000296, 59.015976590000093],
            [11.107321690000106, 59.007501621000245],
            [11.095315484999958, 58.996907910000289],
            [11.075685591000081, 58.982083021000165],
            [11.042075783000257, 58.972000079000054],
            [11.008465975000263, 58.972000079000054],
            [10.964773224000282, 58.958556155000053],
            [10.924441454000259, 58.94847321300017],
            [10.870665760000236, 58.93502928900017],
            [10.820251048000159, 58.924946347000059],
            [10.757805556000108, 58.913944444000208],
            [10.640277778000154, 58.892777777000106],
            [10.594444444000203, 58.761472221000247],
            [10.450858163000191, 58.681351949000202],
            [10.146361084500199, 58.511444387000097],
            [10.030027778000203, 58.261444444000063],
            [9.383333333000166, 57.988333332000138],
            [8.888333333000276, 57.696666666000056],
            [8.458333333000212, 57.618333332000304],
            [7.983333333000246, 57.498333332000243],
            [6.936666667000111, 57.174999999000249],
            [5.033333333000201, 56.591666666000151],
            [3.250000000000227, 56.086666666000269],
            [2.613333332999957, 56.594999999000152],
            [1.965000000000202, 57.904999999000154],
            [1.483333333000303, 58.429999999000302],
            [1.711666667000259, 59.289999999000088],
            [2.076666667000268, 59.896666666000272],
            [1.79, 61.356666666000251],
            [1.560000000000286, 61.736666666000247],
            [1.177961111000229, 62.278869444000236],
            [1.141933333000168, 62.327977777000285],
            [1.107836111000154, 62.372499999000297],
            [1.07385000000022, 62.415744444000154],
            [1.03825, 62.45911666600017],
            [1.001644444000135, 62.502730555000255],
            [0.963422222000247, 62.546469444000138],
            [0.912438889000271, 62.60576388800007],
            [0.858188889000303, 62.666108333000295],
            [0.791025000000161, 62.737863888000163],
            [0.641086111000163, 62.891524999000296],
            [0.558613889000242, 62.972516666000161],
            [0.470141667000235, 63.055752777000293],
            [-0.183141666999916, 63.636299999000187],
            [-0.30231944399992, 63.736897221000163],
            [-0.429805555999906, 63.840802777000135],
            [-0.488763888999813, 63.887480555000309],
            [-0.486727777999931, 64.433199999000294],
            [-0.103910214999928, 64.635831906000078],
            [-0.097494448999839, 64.63922783300012],
            [-0.057705448999911, 64.670061833000148],
            [0.80928555200029, 65.119322334000174],
            [0.812539380000203, 65.120484105000287],
            [0.816411823000237, 65.121866616000204],
            [0.94440476900013, 65.186315041000285],
            [1.086363052000081, 65.25779533400015],
            [1.389857553000127, 65.363967834000107],
            [1.534742081000218, 65.466805061000059],
            [1.717479706000233, 65.596509763000199],
            [1.729837766000287, 65.602490486000249],
            [1.748818052999979, 65.611675834000152],
            [2.085258267000256, 65.843771394000157],
            [2.304888529000038, 65.995284641000183],
            [2.311212869000201, 65.998247169000194],
            [2.313932710000245, 66.000091338000288],
            [3.265391292000231, 66.435446193000303],
            [3.27695044200027, 66.439079798500131],
            [3.305724331000079, 66.49419032600025],
            [3.33260779800014, 66.545679970000094],
            [3.343818619000217, 66.690802584000153],
            [3.347391054000127, 66.737046335000173],
            [3.371305598000163, 66.782168433000209],
            [3.395741672000327, 66.828274356000065],
            [3.34372362400029, 66.890164369000274],
            [3.277851987000133, 67.06297093100028],
            [3.124992554000244, 67.463980336000304],
            [3.164686465000159, 68.043756317000089],
            [3.183225273000176, 68.077894228000162],
            [3.184720651000077, 68.099311097000168],
            [3.430794258000333, 68.539239386000077],
            [3.50232305500009, 68.667117837000205],
            [3.556490103000328, 68.715169795000179],
            [4.107895645000212, 69.204325143000176],
            [4.647132303000149, 69.501812927000174],
            [5.083812516000194, 69.858551721000254],
            [5.153218556000297, 69.915251838000188],
            [5.226755602500248, 69.953680376500131],
            [5.235775056000023, 69.960861838000085],
            [5.897923160000232, 70.298897872000225],
            [5.944671236000318, 70.322763409000174],
            [6.156522109000093, 70.481554192000033],
            [6.273799504000237, 70.569458299000246],
            [6.939192088000084, 70.888580307000211],
            [6.957880305000117, 70.901770650000287],
            [7.043749558, 70.962377839000169],
            [8.085270559000264, 71.434955340000158],
            [8.789223282000137, 71.647709959000281],
            [8.971334824000166, 71.702749209000046],
            [8.972397778000186, 71.703197543000215],
            [8.978677060000336, 71.705845840000222],
            [9.287429977000272, 71.795149121000179],
            [9.933637803000295, 71.982057092000105],
            [10.120289405000278, 72.092134330000249],
            [10.135139061000245, 72.100891840000259],
            [10.136110246000214, 72.101277873000129],
            [10.139316706000102, 72.102552259000049],
            [10.262381883666706, 72.174036706000209],
            [9.894207078000136, 72.283088682000255],
            [9.813641586000244, 72.306951894000235],
            [9.791550967000262, 72.315941775000056],
            [9.749093060000291, 72.333220341000072],
            [9.556986059999929, 72.387735341000166],
            [8.861743623000223, 72.658045818000062],
            [8.373765780999918, 72.847771838000256],
            [8.364863048000245, 72.852682990000176],
            [8.350512059000266, 72.86059984100018],
            [8.282330559000229, 72.886132341000291],
            [7.322184558000231, 73.395242342000131],
            [7.01226819000027, 73.641434389000267],
            [6.633409174000349, 73.942393514000287],
            [6.535284866000325, 74.095297619000178],
            [6.398683307000226, 74.307687345500256],
            [6.232905824000113, 74.360628076000182],
            [6.210643415000106, 74.365605992000098],
            [6.19335505700019, 74.369471842000223],
            [5.822821337000221, 74.4844447750001],
            [5.350187335000271, 74.631098589000203],
            [5.269194287000346, 74.648296722000225],
            [5.165180556000223, 74.670383343000253],
            [4.913342663000151, 74.745294594000086],
            [4.78208285500034, 74.784339040000134],
            [4.538845859000219, 74.834187929000223],
            [4.366798555000173, 74.869447343000104],
            [4.305402555000057, 74.887138343000174],
            [3.999184555, 74.948597343000131],
            [3.516322038000283, 75.080037443000151],
            [2.447020181000255, 75.371112492000236],
            [1.673953272000233, 75.648903606000147],
            [1.119243052000115, 75.848231344000169],
            [1.048829351000222, 75.884508249000135],
            [1.036725031000231, 75.890744380000172],
            [0.879467318000252, 75.944033640000271],
            [0.651978552000173, 76.02112184400022],
            [0.544125552000082, 76.046373344000187],
            [0.505663441000138, 76.052637872000162],
            [-1.275323950999848, 76.449531839000258],
            [-2.842245362999961, 76.903618503000189],
            [-2.85512586699997, 76.921167823000189],
            [-2.858626638999908, 76.925937524000062],
            [-2.99936835099993, 77.166936424000085],
            [-3.242145096999877, 77.548247853000305],
            [-3.403372121999951, 77.770470075000162],
            [-3.698713918999829, 78.155865605000201],
            [-3.671264160999954, 78.293656532000171],
            [-3.660791120999761, 78.389517974000171],
            [-3.660286976999885, 78.394132486000217],
            [-3.656681647999903, 78.427132652000068],
            [-3.655481878999922, 78.432853239000281],
            [-3.654101642999876, 78.439434302000222],
            [-3.649999075999972, 78.458995636000282],
            [-2.447286842999858, 79.064849262000223],
            [-2.23070489499986, 79.225200137000229],
            [-1.248292950999939, 80.042091262000156],
            [0.226748112999928, 80.618428373000313],
            [1.201904142999922, 80.948946030000172],
            [2.250741121000203, 81.27675203400031],
            [4.024805835000222, 81.823722526000267],
            [5.638307830000144, 82.276750347000245],
            [5.914688580000245, 82.343651297000179],
            [6.8387566520002, 82.980828083000233],
            [7.951875534000237, 83.616053252000086],
            [8.115824311666813, 83.740299743666753],
            [8.856795323000085, 83.777494811000224],
            [8.989186087000235, 83.783783089000224],
            [9.831686061000141, 83.823799851000274],
            [10.683396259500114, 83.862134423500095],
            [10.820889412000213, 83.86832281500017],
            [11.824140268000235, 83.911024115000259],
            [12.748339313000088, 83.948138940000092],
            [12.841131563000204, 83.951865351000151],
            [12.988942423999958, 83.957451891000176],
            [13.871514145000106, 83.990808707000213],
            [14.415729154000246, 84.010111783000241],
            [14.914895065000337, 84.027816851000182],
            [15.970837566000284, 84.062853852000217],
            [16.689328318000207, 84.085074774500129],
            [17.038860961000239, 84.095884759000228],
            [18.118440223000221, 84.12687529800013],
            [19.20900661100012, 84.155793174000053],
            [19.413999587000148, 84.161173679000171],
            [19.420142470000087, 84.161077448000299],
            [19.42151856900017, 84.161055852000288],
            [19.422306327000342, 84.161075600000288],
            [19.426604749000205, 84.161183105000248],
            [19.784048090000169, 84.155372906000252],
            [20.05039107000016, 84.151043352000158],
            [20.612698071000125, 84.163068352000153],
            [20.625306571000294, 84.163051352000195],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "NPL", Country: "Nepal" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [88.142791240010212, 27.866054058569759],
            [88.152482228850687, 27.864164107036331],
            [88.188309165331617, 27.849227052621828],
            [88.187336194008282, 27.782636016830068],
            [88.148882201426801, 27.680273197480531],
            [88.120672403296822, 27.611936043614364],
            [88.093382267585838, 27.587153940978581],
            [88.056927191279129, 27.533336087471483],
            [88.047345167180339, 27.496454037015909],
            [88.034709280469912, 27.283609016325997],
            [88.014018217147424, 27.223609173933752],
            [88.009991215574559, 27.194164218548352],
            [88.010818174144134, 27.151391198886458],
            [87.994982244430531, 27.112291129206326],
            [88.035263324271426, 27.049300120838538],
            [88.072972333296633, 27.030973089494196],
            [88.118591344370685, 26.98443609238187],
            [88.174282217966464, 26.876800050091717],
            [88.194554186129011, 26.760417994727888],
            [88.191782290741088, 26.72596418162901],
            [88.179700280194112, 26.685273226721662],
            [88.172763417106836, 26.665691089192293],
            [88.121372292213977, 26.566109050047984],
            [88.101927282620949, 26.530282113567083],
            [88.107418267406473, 26.488809127091358],
            [88.090682288927411, 26.433191176053313],
            [88.020191321215322, 26.368364195608777],
            [87.947472275994897, 26.402500166938395],
            [87.927200307832351, 26.423327017092831],
            [87.908036259634599, 26.446382112393522],
            [87.894154151556592, 26.469300079757929],
            [87.791363352229638, 26.458882212272087],
            [87.686782346017452, 26.42527312737748],
            [87.650545199193942, 26.399718045628717],
            [87.643045239849613, 26.392491168690569],
            [87.553591221978508, 26.405827111956185],
            [87.530272264365209, 26.418327044196573],
            [87.485818170197632, 26.434018134622946],
            [87.455127161082743, 26.433609097746839],
            [87.399291280561698, 26.418191089726676],
            [87.368736225916706, 26.401527027977082],
            [87.26971828885749, 26.375273230777552],
            [87.16998235797044, 26.400000180490409],
            [87.084427266191227, 26.486382063201049],
            [87.004436249393535, 26.534445069609703],
            [86.965182287970748, 26.525827132016744],
            [86.948027214331688, 26.530927017199176],
            [86.900682201750982, 26.500409010566287],
            [86.891582304724096, 26.477054010769152],
            [86.820954209075609, 26.436736050554188],
            [86.733382263748837, 26.420200064285481],
            [86.659991325167908, 26.45666419304753],
            [86.47553614588864, 26.546109158463238],
            [86.334154226043751, 26.607773143916617],
            [86.204782232378449, 26.587636124395615],
            [86.183863348926963, 26.608053937673773],
            [86.138318266239168, 26.603473227575691],
            [86.104991316206252, 26.618609100733963],
            [86.08942712779438, 26.633053969792812],
            [86.063872381321715, 26.653954077780995],
            [86.032909295628968, 26.663191102744221],
            [85.96581819549246, 26.648054056119364],
            [85.950609232138021, 26.627709165398997],
            [85.860472256794282, 26.57284508273473],
            [85.753454128408094, 26.642773121828],
            [85.728872353258595, 26.67360913786824],
            [85.724991196801426, 26.70499115108278],
            [85.726927248802355, 26.733609147898548],
            [85.730191329545988, 26.787082169907976],
            [85.710536101820793, 26.816109035961674],
            [85.630754129688881, 26.865973145729882],
            [85.568600305812566, 26.845136069653549],
            [85.540263270392103, 26.829718061633386],
            [85.50804524615242, 26.813882131919897],
            [85.453045209018256, 26.786945041971691],
            [85.337345105298567, 26.736936092916167],
            [85.328045216061327, 26.73610913434662],
            [85.310527206013802, 26.739436079364324],
            [85.221927136440542, 26.763053935645772],
            [85.20116331819824, 26.775064197101457],
            [85.185527213056986, 26.798054081195218],
            [84.967754136200284, 26.935827088798803],
            [84.854982334182154, 26.995544963967532],
            [84.802200316272462, 27.015273114250519],
            [84.781100216073838, 27.011391119603005],
            [84.753463404846485, 27.007364118030083],
            [84.707491180371704, 27.021109098171806],
            [84.65526337390159, 27.04034506309894],
            [84.646236231794404, 27.061382131385429],
            [84.660263346797734, 27.094164090072425],
            [84.679836264233501, 27.135691056004688],
            [84.685118204353302, 27.206245055628827],
            [84.680263406020543, 27.232218059071116],
            [84.653182314975339, 27.29152706500183],
            [84.63804510071239, 27.311109034893022],
            [84.598391322507013, 27.340000114115],
            [84.49941814481096, 27.35638220864108],
            [84.394991197979579, 27.367500132682025],
            [84.329709247829413, 27.377773160880636],
            [84.297763300167588, 27.388326982836375],
            [84.14721828374573, 27.511391091430411],
            [84.051372394133324, 27.452982134265383],
            [84.019145149800039, 27.443053937564358],
            [83.935672290413521, 27.447218067073564],
            [83.922491244719112, 27.45177312854797],
            [83.87137236404223, 27.440273157359044],
            [83.872209213257605, 27.385273120224966],
            [83.858309167906754, 27.35222713158727],
            [83.82610019612261, 27.371109044923443],
            [83.801654207805058, 27.383054095172113],
            [83.777482307722067, 27.394718016387557],
            [83.748027126414826, 27.408609176920876],
            [83.633882201014643, 27.459717999313952],
            [83.607754132363169, 27.469164236581108],
            [83.540545182667614, 27.47304505776215],
            [83.418318258565051, 27.472773148822327],
            [83.394009230545834, 27.411173201109492],
            [83.376645112240936, 27.37527317443265],
            [83.358872292336144, 27.356109126234784],
            [83.342354075702218, 27.342491048107675],
            [83.309972269073995, 27.336245021481986],
            [83.273463380948755, 27.344718119787629],
            [83.261100241368496, 27.371244999393255],
            [83.25179129967583, 27.390000178352906],
            [83.176582326291197, 27.447009022407812],
            [83.130536173430158, 27.449436086298007],
            [83.057482354995983, 27.448326992866797],
            [83.028736115061264, 27.449582099051824],
            [82.968045100931221, 27.461109059968877],
            [82.766518164130702, 27.503473210392755],
            [82.743663396316521, 27.555900170882907],
            [82.746927141783971, 27.581664129659444],
            [82.732482272725321, 27.639436062181503],
            [82.71756332322164, 27.690409097742744],
            [82.701100259510696, 27.711109045882679],
            [82.65790915051727, 27.707636088111272],
            [82.617200258337135, 27.696944970581157],
            [82.598036210139441, 27.690273143272847],
            [82.568054142397074, 27.68527317037659],
            [82.478863316286407, 27.672773238136202],
            [82.452072239091905, 27.672218020868215],
            [82.429427186495644, 27.677500128626249],
            [82.40610018225513, 27.69082701943627],
            [82.304291239068931, 27.759164173302324],
            [82.278318235626642, 27.778053965627336],
            [82.148882204220712, 27.864164107036331],
            [82.066400253430373, 27.914153945352453],
            [82.043318168401498, 27.918609094540685],
            [81.999709305352525, 27.922218006782103],
            [81.969500255671591, 27.913054072014731],
            [81.936918289195006, 27.88750016373244],
            [81.901054137063937, 27.854927082073189],
            [81.780818250247222, 27.910827000334862],
            [81.726927138906291, 27.955827091676625],
            [81.607482336113407, 28.01889119024024],
            [81.478763292707214, 28.08437313260076],
            [81.374418153251071, 28.166109094006416],
            [81.298036216332747, 28.16388218996444],
            [81.222491296267805, 28.324718004219832],
            [81.193591332228522, 28.3666642329501],
            [81.188872320727484, 28.369164219398172],
            [81.166927157048434, 28.375273118087577],
            [81.139163276168603, 28.382500162663675],
            [81.094145247553939, 28.393327067025623],
            [81.031372336307385, 28.403045045594425],
            [80.930254229582744, 28.445544982850109],
            [80.704709284442089, 28.578609037236461],
            [80.58499123160496, 28.65360913359352],
            [80.541363257816812, 28.685273114031688],
            [80.519009224899747, 28.671382121136261],
            [80.505900263572784, 28.655273109016392],
            [80.510818261455682, 28.621109142130237],
            [80.472209203664903, 28.590000043683702],
            [80.387500181194667, 28.625000189233063],
            [80.316654156063095, 28.692909027845829],
            [80.250263280119754, 28.747500028103801],
            [80.150818201273552, 28.793609045238796],
            [80.061372230029548, 28.829926993609249],
            [80.05220024863516, 28.898327011749373],
            [80.057618143224801, 28.924300182829754],
            [80.069718258682684, 28.949436001780541],
            [80.196927218408661, 29.12832710607826],
            [80.249145134233032, 29.163609051213243],
            [80.287763412117187, 29.205553938839032],
            [80.301927152142667, 29.278609098377743],
            [80.306163366019319, 29.308882185799249],
            [80.28567229490713, 29.320209154505946],
            [80.238027210191973, 29.422982184198261],
            [80.290263398565372, 29.483053943319973],
            [80.313036191366251, 29.498600194458916],
            [80.346100284914797, 29.521391092170816],
            [80.40846332109507, 29.601382108968536],
            [80.416582200171462, 29.640000051576735],
            [80.396518270846315, 29.660827069369148],
            [80.384154125437732, 29.681245050285369],
            [80.375009301409676, 29.740200172262902],
            [80.415818273513906, 29.785691107856152],
            [80.44330923962562, 29.796527064673498],
            [80.482827230999163, 29.79826413029248],
            [80.537282276787352, 29.844645056367185],
            [80.574154101321113, 29.910000096713233],
            [80.595463246185517, 29.949644990101191],
            [80.625536173758491, 29.96290901663734],
            [80.659427225876613, 29.963327105968844],
            [80.678863350652165, 29.970273189149751],
            [80.74400917869437, 30.019582081650057],
            [80.87414509901734, 30.14097316236554],
            [80.943036129046817, 30.181109067642964],
            [81.025363349904097, 30.204354096870119],
            [81.044436202995115, 30.17610909474682],
            [81.073882331846818, 30.128609016956986],
            [81.096100242656092, 30.084718019046349],
            [81.119427246896606, 30.019444953713659],
            [81.160954212828841, 30.030418038467403],
            [81.199845237843221, 30.017773099301607],
            [81.232209274836606, 30.011944994369443],
            [81.253327144669953, 30.02874501058885],
            [81.247482275931389, 30.056382157092273],
            [81.278591206739719, 30.067218113909703],
            [81.298600318418011, 30.078054070727248],
            [81.380818239257536, 30.172500182505516],
            [81.392763289506291, 30.265554060162444],
            [81.390963359613323, 30.315273163005244],
            [81.408600225048275, 30.375553966792793],
            [81.426227199837427, 30.385000036421687],
            [81.446927147977277, 30.377218109853843],
            [81.466382215854168, 30.35943606985559],
            [81.49360026719765, 30.341527127842753],
            [81.545682228552067, 30.332082064042154],
            [81.550536188694622, 30.358327144062358],
            [81.560527249669605, 30.384436101974458],
            [81.630254123086019, 30.424718020005585],
            [81.654982246265291, 30.419164170946104],
            [81.728045117154892, 30.392491110948598],
            [81.808318268814247, 30.363745038652027],
            [81.853045110111822, 30.376664233690505],
            [81.951382268993058, 30.363882166588311],
            [82.111227174652157, 30.334445090192034],
            [82.151236177915081, 30.120409168695616],
            [82.175254186255273, 30.069235973285842],
            [82.224918136174978, 30.075000040477661],
            [82.420818133720076, 30.010273139957278],
            [82.598600312224761, 29.892218056714896],
            [82.652354127991231, 29.838327113012056],
            [82.776654232031063, 29.733882061269682],
            [82.872063256848861, 29.666935968058553],
            [82.908245083111211, 29.690618029718522],
            [82.998600323214333, 29.670000056591832],
            [83.018054217624723, 29.658327082920991],
            [83.059982341444055, 29.623327105009608],
            [83.120809310044052, 29.622082224746563],
            [83.164991327633601, 29.633053968395828],
            [83.191082348272943, 29.631664248845354],
            [83.257491329127191, 29.580000041546398],
            [83.354427201431719, 29.490554070302281],
            [83.37915415114449, 29.466109087813138],
            [83.377482296732438, 29.437636097922663],
            [83.380536159343961, 29.416382105981413],
            [83.414427211462083, 29.365554077345379],
            [83.547909187542047, 29.189027005025721],
            [83.605945150014776, 29.164718144644567],
            [83.628582323622055, 29.165827070437714],
            [83.636100220239115, 29.169435982679019],
            [83.672272323493843, 29.163327083989728],
            [83.688309251246181, 29.183882192842347],
            [83.695118206490719, 29.206382070875051],
            [83.720536160303283, 29.228882116546018],
            [83.750827184997405, 29.242773109441444],
            [83.912491298926824, 29.289718137601469],
            [83.976100221198436, 29.296945014539503],
            [84.116509169719933, 29.260973238771285],
            [84.180954103016603, 29.209718068348238],
            [84.180400226853266, 29.183327143212537],
            [84.159427196307121, 29.156664141498922],
            [84.162072357318692, 29.130000133956727],
            [84.23997225690627, 29.053891111806607],
            [84.232754097147534, 28.976109061778047],
            [84.23692727911228, 28.918882120601992],
            [84.244427238456495, 28.913609065299553],
            [84.326518257281606, 28.871800132143662],
            [84.388600164428595, 28.863327033838019],
            [84.405545187573324, 28.849164132002883],
            [84.430263252468734, 28.812500178668458],
            [84.459154164052649, 28.759164116957251],
            [84.486227208470751, 28.73401807208468],
            [84.570827266199416, 28.712491165375113],
            [84.636382298755819, 28.702773186806311],
            [84.708454093411234, 28.651527068838774],
            [84.703109289017476, 28.607218149234811],
            [84.823318186105809, 28.551935977238955],
            [84.846645190346322, 28.544445070350221],
            [84.947209253269449, 28.542082211838434],
            [85.03166329824441, 28.603882151761709],
            [85.05227238655371, 28.634226988274506],
            [85.072218298681861, 28.641382116121164],
            [85.106936141731552, 28.636664110448621],
            [85.19518232735166, 28.591036046919086],
            [85.181927185632986, 28.530000033653394],
            [85.135127164398313, 28.46527313313301],
            [85.11360025768883, 28.395827053473695],
            [85.103318177034708, 28.31639108630587],
            [85.110954090848878, 28.306664222919679],
            [85.139982298007197, 28.295273216472523],
            [85.23692722276715, 28.27860915472273],
            [85.283872250927203, 28.272218121171747],
            [85.376645167189025, 28.263609068396178],
            [85.39721821331446, 28.266391189705942],
            [85.413882275064083, 28.279718080515963],
            [85.436918259625571, 28.300136061432383],
            [85.458882198767839, 28.30166425002362],
            [85.583600224501225, 28.282500034187663],
            [85.721372393914379, 28.279164204352625],
            [85.742482217120738, 28.242636037850147],
            [85.780818192505222, 28.206109044813942],
            [85.820545228544546, 28.185273142204196],
            [85.847209236086627, 28.173609053350745],
            [85.869418262078483, 28.16733603699673],
            [85.887772283151065, 28.148609021231678],
            [85.951663340284398, 28.013054032852565],
            [85.976372352724326, 27.910135996235041],
            [86.005400224606461, 27.886245057547413],
            [86.069718255888716, 27.888044987440267],
            [86.089418242976961, 27.890827108750031],
            [86.135963286716418, 27.93533602056435],
            [86.123872391352023, 27.980827123795663],
            [86.103863279673789, 28.026945025747992],
            [86.096227198221612, 28.086735991112462],
            [86.197691309910198, 28.158054085032362],
            [86.214572295314696, 28.112353937135296],
            [86.196918163159268, 28.07208207738806],
            [86.223045225982247, 28.011391063258017],
            [86.35206333569468, 27.945000187314676],
            [86.451372292432808, 27.908882063516486],
            [86.517763336014212, 27.940273129186536],
            [86.537763395237022, 27.959718138779536],
            [86.541091346083107, 28.024164245542678],
            [86.569436260492608, 28.100418106980115],
            [86.58860030869036, 28.113053993690514],
            [86.695263379657121, 28.111664106502062],
            [86.728591335518416, 28.100554061450012],
            [86.75610023890286, 28.082218145288365],
            [86.75610023890286, 28.055973232906254],
            [86.759991286005885, 28.030553937989211],
            [86.903591225075132, 27.969718084571852],
            [86.94707234791926, 27.94763612823256],
            [87.032554181864413, 27.9632539608249],
            [87.08651821576322, 27.924300071536649],
            [87.097072372995143, 27.891245030443471],
            [87.141518252897384, 27.844027087515414],
            [87.192745092487513, 27.823054056969184],
            [87.30609122251289, 27.816391114478378],
            [87.32916341689608, 27.822218045944155],
            [87.408872298832108, 27.843053948553901],
            [87.536654077822703, 27.840273168348787],
            [87.590545189163635, 27.814854041069836],
            [87.626082279431984, 27.813609160806593],
            [87.660536260168868, 27.828882161901461],
            [87.72053627019929, 27.805000108031138],
            [87.751391229340726, 27.813053943538804],
            [87.780272250278898, 27.825000167253947],
            [87.804563341025329, 27.843882080589921],
            [87.825272341620661, 27.869718123734103],
            [87.849009220927513, 27.910691045864851],
            [87.872618192391627, 27.917218033885845],
            [87.976927122026069, 27.890000150180512],
            [88.003054184849105, 27.885827135853887],
            [88.027772249744515, 27.888609089525588],
            [88.054427204831057, 27.898609035317989],
            [88.091663306877791, 27.892218001766892],
            [88.142791240010212, 27.866054058569759],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "NRU", Country: "Nauru" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [165.954234655000135, 2.678330354000025],
            [166.148882655000222, 2.612866354000062],
            [166.681926655000183, 2.429900354000125],
            [166.937356655000173, 2.341831353999964],
            [167.619732656000139, 2.107783354000148],
            [167.733006656000072, 2.068776353999979],
            [167.934040656000235, 1.999422354000032],
            [168.019619656000174, 1.969924354000071],
            [168.413648657000095, 1.834182354],
            [168.598105120000156, 1.770280294999964],
            [168.548172999000116, 1.427532621000083],
            [168.531385607000146, 1.311914176000073],
            [168.51186724100026, 1.177486918999989],
            [168.501523122000236, 1.105660246000184],
            [168.4876204400002, 1.009123897000023],
            [168.424688469000131, 0.577270264000163],
            [168.394910380000084, 0.372926182000015],
            [168.394120190500217, 0.367484565000083],
            [168.393600810000095, 0.363907865],
            [168.392655824000059, 0.357400252000062],
            [168.38785205900021, 0.324049229000067],
            [168.383120476000073, 0.291199338000055],
            [168.328284785000193, -0.085753804999911],
            [168.267318330000222, -0.504851236999926],
            [168.267223138500214, -0.505506549999978],
            [168.266776491000229, -0.50858762099999],
            [168.256875250000263, -0.577820433999946],
            [168.212599359000166, -0.883705509999899],
            [168.203036799000216, -0.949588006999846],
            [168.202581652000191, -0.952723788999961],
            [168.174971741000064, -1.156155770999945],
            [168.155905767000121, -1.294624288],
            [168.153561678000159, -1.311648464000029],
            [168.134771542500147, -1.448113657999983],
            [168.109845552000166, -1.626642660999877],
            [168.103299330000056, -1.673828227999863],
            [168.09367761300021, -1.743621076999972],
            [168.090611785000107, -1.765859608999961],
            [168.090449604000099, -1.767030836999965],
            [168.090182874000249, -1.768957086999919],
            [168.076398302000115, -1.868040025000013],
            [168.052102948000226, -2.04267403699987],
            [168.018395536000213, -2.283836572999931],
            [167.968259189000065, -2.642541317999928],
            [167.948754847000174, -2.784568140999966],
            [167.93215360200017, -2.905455172999893],
            [167.930982613000168, -2.913982091000022],
            [167.926786650000196, -2.944563965999961],
            [167.880157648000164, -3.284414948999981],
            [167.815832191000169, -3.753244848999955],
            [167.788264656000052, -3.780195651999918],
            [167.771293656000154, -3.793206651999981],
            [167.758027656000223, -3.801083651999988],
            [167.744138656000104, -3.804583651999849],
            [167.730360656000158, -3.808055651999979],
            [167.716555656000224, -3.811416651999977],
            [167.702833656000195, -3.814722651999958],
            [167.689083656000122, -3.817944651999923],
            [167.675333656000106, -3.821138651999959],
            [167.661555656000161, -3.824277651999978],
            [167.647777656000159, -3.827333651999965],
            [167.633972656000111, -3.830333652000036],
            [167.62016665600018, -3.833277652],
            [167.606194656000099, -3.836166652000017],
            [167.590277656000097, -3.839472651999913],
            [167.57419465600006, -3.842666651999949],
            [167.558083656000264, -3.845805651999967],
            [167.541972656000127, -3.848833651999954],
            [167.525833656000174, -3.851805651999925],
            [167.509694656000107, -3.854694652000035],
            [167.493527656000225, -3.857499651999959],
            [167.477333656000127, -3.860222651999834],
            [167.461166656000074, -3.862860651999966],
            [167.44494465600016, -3.865444651999894],
            [167.428749656000178, -3.867916651999977],
            [167.412527656000094, -3.870333651999943],
            [167.39627765600008, -3.872638651999964],
            [167.380027656000067, -3.874888651999967],
            [167.363777656000053, -3.877055651999939],
            [167.347499656000224, -3.87913865199998],
            [167.331222656000108, -3.881138651999905],
            [167.314944656000108, -3.883055651999982],
            [167.298638656000179, -3.884916651999859],
            [167.282333656000134, -3.886666651999974],
            [167.265999656000105, -3.888360651999889],
            [167.249694656000173, -3.889944651999855],
            [167.233360656000144, -3.89147265199999],
            [167.217027656000113, -3.892888652],
            [167.200666656000152, -3.894249651999985],
            [167.184305656000191, -3.895527651999942],
            [167.16797265600016, -3.896722651999966],
            [167.151583656000213, -3.89783365199996],
            [167.135222656000252, -3.898860651999939],
            [167.118860656000123, -3.899833651999884],
            [167.103972656000195, -3.900638651999969],
            [167.089083656000099, -3.901360651999937],
            [167.074277656000191, -3.902027651999873],
            [167.059305656000134, -3.902638651999979],
            [167.044416656000209, -3.903166651999967],
            [167.029527656000113, -3.903638651999941],
            [167.014610656000087, -3.904027651999982],
            [166.999722656000046, -3.904360652],
            [166.984805656000191, -3.904638651999903],
            [166.969916656000265, -3.904833651999965],
            [166.95499965500008, -3.904944651999912],
            [166.940110655000154, -3.905027651999944],
            [166.925194655000126, -3.905027651999944],
            [166.910305655000258, -3.904944651999912],
            [166.896860655000097, -3.904833651999965],
            [166.883416655000161, -3.904666652],
            [166.869944655000069, -3.904444651999839],
            [166.856499655000249, -3.90416665199993],
            [166.843055655000143, -3.903833652],
            [166.829610655000153, -3.903444651999877],
            [166.814027655000103, -3.902916651999888],
            [166.798416655000182, -3.902333651999967],
            [166.782833655000132, -3.901666651999847],
            [166.767222655000154, -3.900944651999978],
            [166.751638655000107, -3.900110651999981],
            [166.736055655000115, -3.899222651999963],
            [166.720499655000168, -3.898277651999933],
            [166.704916655000176, -3.897249651999957],
            [166.689333655000127, -3.896138651999962],
            [166.673777655000237, -3.894972652000035],
            [166.658222655000117, -3.893722652],
            [166.642666655000227, -3.892388651999923],
            [166.627138655000095, -3.890999651999934],
            [166.611583655000146, -3.889527652],
            [166.596055655000185, -3.88797265200003],
            [166.580527655000225, -3.886360651999965],
            [166.564069655000225, -3.884569151999983],
            [166.553083655000052, -3.883333651999976],
            [166.542305655000092, -3.882083651999935],
            [166.531472655000101, -3.880777651999878],
            [166.516610655000107, -3.878944651999916],
            [166.50169465500008, -3.877055651999939],
            [166.486777655000225, -3.875083651999844],
            [166.471888655000129, -3.875888652000015],
            [166.45699965500026, -3.870944651999864],
            [166.442110655000164, -3.868749651999977],
            [166.427222655000065, -3.866527651999974],
            [166.412388655000228, -3.864222651999953],
            [166.397527655000232, -3.861833651999902],
            [166.382694655000051, -3.859388651999936],
            [166.367860655000214, -3.856888651999938],
            [166.353027655000147, -3.854305651999823],
            [166.338222655000237, -3.851666651999963],
            [166.323416655000159, -3.848944651999986],
            [166.310222655000217, -3.846472651999918],
            [166.297027655000221, -3.843944652],
            [166.28380565500018, -3.841360651999892],
            [166.27063865500017, -3.838722651999944],
            [166.257305655000124, -3.836027651999885],
            [166.242499655000216, -3.832916651999966],
            [166.227527655000102, -3.82972265199993],
            [166.212610655000077, -3.826472651999964],
            [166.19766665500012, -3.823138651999969],
            [166.182777655000194, -3.819749651999871],
            [166.167888655000155, -3.816277651999926],
            [166.152999655000059, -3.812749651999965],
            [166.138138655000063, -3.809138651999973],
            [166.123305655000223, -3.805472651999864],
            [166.108472655000043, -3.801722652],
            [166.093666655000135, -3.797916651999955],
            [166.078972655000172, -3.794055651999884],
            [166.063555655000101, -3.789944652],
            [166.048222655000103, -3.785777651999922],
            [166.032944655000136, -3.781555652],
            [166.01766665500017, -3.777222651999963],
            [166.002388655000203, -3.772833651999989],
            [165.987166655000266, -3.768388652],
            [165.971944655000044, -3.763860652],
            [165.956749655000209, -3.759249651999852],
            [165.941583655000187, -3.754555651999965],
            [165.926444655000097, -3.749805651999964],
            [165.911333655000163, -3.744999651999947],
            [165.896222655000173, -3.740110652],
            [165.881138654000182, -3.735138651999932],
            [165.866083654000107, -3.730083652],
            [165.851055654000135, -3.724972651999892],
            [165.836055654000262, -3.719805651999948],
            [165.820944654000158, -3.714499651999859],
            [165.805888654000199, -3.709138652000021],
            [165.790833654000124, -3.703694651999982],
            [165.775833654000138, -3.698194651999927],
            [165.760833654000095, -3.692610650999839],
            [165.745860654000154, -3.68694465099999],
            [165.730944654000126, -3.681222650999956],
            [165.716027654000101, -3.675416650999978],
            [165.701138654000175, -3.669555650999982],
            [165.686277654000065, -3.663610650999871],
            [165.671472654000155, -3.657583650999911],
            [165.656666654000247, -3.651499650999938],
            [165.641916654000198, -3.64533365099993],
            [165.627166654000206, -3.63911065099991],
            [165.612472654000072, -3.632805650999956],
            [165.597777654000225, -3.626444650999971],
            [165.58313865400018, -3.619999650999972],
            [165.568527654000178, -3.613472650999938],
            [165.553944654000162, -3.606888651],
            [165.539388654000078, -3.600249651000013],
            [165.52488865400008, -3.593527650999917],
            [165.510388654000195, -3.586722650999974],
            [165.495944654000056, -3.579860650999933],
            [165.481527654000189, -3.572916650999858],
            [165.467138654000138, -3.565916650999952],
            [165.453305654000104, -3.559138650999927],
            [165.439527654000159, -3.552277650999969],
            [165.425749654000157, -3.545360651],
            [165.411944654000109, -3.53838865099992],
            [165.39833365400014, -3.531333650999983],
            [165.384666654000085, -3.524222650999945],
            [165.371027654000187, -3.517055650999978],
            [165.357416654000218, -3.509805651],
            [165.343860654000167, -3.502527651],
            [165.330305654000227, -3.495166650999963],
            [165.316833654000135, -3.487722651],
            [165.303360654000102, -3.480249650999937],
            [165.289916654000109, -3.472694650999841],
            [165.276527654000205, -3.465083650999915],
            [165.26316665400023, -3.457416650999974],
            [165.249833654000184, -3.449666651],
            [165.236555654000227, -3.441777650999981],
            [165.223138654000223, -3.433999650999908],
            [165.210194654000105, -3.426166651],
            [165.197110654000227, -3.418249650999883],
            [165.184055654000161, -3.41027765099993],
            [165.171027654000198, -3.402249650999963],
            [165.158055654000265, -3.394166650999964],
            [165.145110654000149, -3.385999650999949],
            [165.13222265400023, -3.377805651000017],
            [165.119360654000076, -3.369527650999871],
            [165.106527654000075, -3.361194650999977],
            [165.093749654000106, -3.352805650999983],
            [165.080999654000067, -3.344360650999974],
            [165.068277654000184, -3.335860650999933],
            [165.055610654000162, -3.327305650999975],
            [165.042860654000123, -3.318666650999987],
            [165.03011065400014, -3.30980565099992],
            [165.017305654000182, -3.300888651000022],
            [165.004499654000114, -3.291888651],
            [164.991777654000231, -3.282833650999976],
            [164.97908365400022, -3.273749650999846],
            [164.966416654000199, -3.264583650999953],
            [164.953805654000263, -3.255305650999944],
            [164.941194654000157, -3.246055650999935],
            [164.928666654000182, -3.236694650999894],
            [164.916138654000207, -3.227277650999838],
            [164.903666654000148, -3.21780565099985],
            [164.89124965400012, -3.208249651000017],
            [164.878860654000192, -3.198666651],
            [164.86652765400018, -3.188999650999946],
            [164.854222654000097, -3.179277650999879],
            [164.841972654000216, -3.169527650999981],
            [164.829777654000083, -3.159694650999967],
            [164.817610653000116, -3.149805650999937],
            [164.805499653000169, -3.139860650999893],
            [164.79344465300008, -3.129860650999916],
            [164.781416653000093, -3.119805651],
            [164.769444653000193, -3.109694650999913],
            [164.757527653000153, -3.099499650999959],
            [164.7456386530001, -3.089277651],
            [164.733805653000246, -3.078999650999847],
            [164.72202765300014, -3.068666650999944],
            [164.710305653000233, -3.058277651000026],
            [164.698638653000188, -3.047805650999891],
            [164.686999653000129, -3.03730565099994],
            [164.675416653000099, -3.02674965099996],
            [164.651388653000112, -3.016138650999878],
            [164.652388653000259, -3.005472650999863],
            [164.643444653000159, -2.997110650999971],
            [164.634555653000263, -2.98869465099996],
            [164.625666653000195, -2.980277650999952],
            [164.614388653000191, -2.969444650999975],
            [164.603138653000173, -2.958583651],
            [164.59194465300007, -2.947666651],
            [164.580805653000169, -2.936694650999883],
            [164.569694653000198, -2.925638650999929],
            [164.558666653000074, -2.914583650999973],
            [164.547666653000221, -2.903444650999987],
            [164.536722653000226, -2.892249650999901],
            [164.525860653000251, -2.881027650999982],
            [164.515027653000089, -2.869722650999947],
            [164.504166653000112, -2.858333650999967],
            [164.493388653000153, -2.846916650999987],
            [164.482666653000109, -2.835416650999889],
            [164.471972653000165, -2.823888650999962],
            [164.461360653000185, -2.812305651000017],
            [164.450805653000117, -2.800666650999872],
            [164.440277653000152, -2.78897265099998],
            [164.429833653000202, -2.777249651],
            [164.419416653000184, -2.765472650999982],
            [164.409083653000124, -2.75363865099996],
            [164.399166653000151, -2.742194650999963],
            [164.38927765300005, -2.730694650999865],
            [164.379472653000136, -2.719166650999938],
            [164.369694653000039, -2.707583651],
            [164.359999653000074, -2.695944650999849],
            [164.350333653000206, -2.684277650999974],
            [164.340722653000086, -2.672583650999982],
            [164.331166653000167, -2.66083365099999],
            [164.321666653000165, -2.649027650999969],
            [164.312222653000191, -2.63716665100003],
            [164.302833653000135, -2.625305651],
            [164.293499653000111, -2.613360649999933],
            [164.284194653000185, -2.601388649999947],
            [164.274972653000162, -2.572722649999903],
            [164.265638653000252, -2.577333649999858],
            [164.256222653000265, -2.56461065],
            [164.246694653000162, -2.551860650000037],
            [164.237222653000259, -2.539055649999966],
            [164.227833653000147, -2.52622265],
            [164.218472653000191, -2.513333649999979],
            [164.209194653000083, -2.500388649999863],
            [164.199972653000174, -2.48741664999983],
            [164.190833653000112, -2.474388649999966],
            [164.181722653000151, -2.461333649999986],
            [164.172694653000207, -2.448222649999821],
            [164.163722653000121, -2.43505565],
            [164.154833653000225, -2.421860649999985],
            [164.145972653000086, -2.408638649999872],
            [164.137194653000137, -2.39536065],
            [164.128472653000046, -2.382027649999955],
            [164.119805653000213, -2.36866665],
            [164.111222653000169, -2.355249649999891],
            [164.102694653000214, -2.341805649999884],
            [164.094222653000173, -2.328333649999962],
            [164.085833653000265, -2.31480564999984],
            [164.077499653000103, -2.301222649999971],
            [164.069222653000253, -2.28761065],
            [164.060999653000209, -2.273972649999934],
            [164.052860653000124, -2.260277650000035],
            [164.044805653000054, -2.246555649999848],
            [164.036777653000144, -2.232805649999932],
            [164.028833653000135, -2.218999649999986],
            [164.0209446530001, -2.205166649999953],
            [164.013138653000198, -2.19127764999989],
            [164.005360653000054, -2.177360650000011],
            [163.997388653000172, -2.162888649999942],
            [163.989499653000081, -2.148360649999859],
            [163.981638653000147, -2.133833649999957],
            [163.973888653000103, -2.119249649999944],
            [163.966194653000144, -2.104610649999827],
            [163.958555653000104, -2.089944649999964],
            [163.950999653000196, -2.075249649999833],
            [163.943527653000132, -2.060499649999954],
            [163.9361106530001, -2.045722649999959],
            [163.928777653000253, -2.030916649999881],
            [163.921527653000254, -2.01608365],
            [163.914333653000114, -2.00119464999986],
            [163.907222653000161, -1.98624965],
            [163.900166653000127, -1.971305649999877],
            [163.893222653000151, -1.956305649999919],
            [163.886305653000164, -1.941277649999947],
            [163.879499653000067, -1.926222649999957],
            [163.872749653000056, -1.91113865],
            [163.866083653000061, -1.895999649999965],
            [163.859472653000097, -1.88086064999986],
            [163.852944653000151, -1.86566665],
            [163.846499653000166, -1.850444649999972],
            [163.840138653000196, -1.83516665],
            [163.833833653000141, -1.819888649999868],
            [163.827610653000107, -1.804555649999884],
            [163.8214726530002, -1.7892226499999],
            [163.815388653000156, -1.773833649999901],
            [163.809388653000184, -1.758416649999987],
            [163.803472653000171, -1.742999649999888],
            [163.797638653000178, -1.727527649999956],
            [163.791860653000214, -1.712027649999825],
            [163.786166653000265, -1.696499649999964],
            [163.780555653000107, -1.68094465],
            [163.774999653000208, -1.665360650000025],
            [163.769555653000253, -1.649749649999961],
            [163.764166653000217, -1.634110649999968],
            [163.758833653000039, -1.618444649999873],
            [163.753610653000266, -1.602749649999964],
            [163.748444653000121, -1.587055649999954],
            [163.743360652000121, -1.571305649999928],
            [163.739833652000044, -1.560249649999975],
            [163.736360652000116, -1.54916665],
            [163.732860652000141, -1.538083648999944],
            [163.728083652000151, -1.522388648999851],
            [163.72333365200015, -1.50666664900001],
            [163.718666652000167, -1.490916648999985],
            [163.714083652000141, -1.475166648999959],
            [163.709555652000205, -1.459388648999933],
            [163.705110652000059, -1.443583648999976],
            [163.700749652000155, -1.427749648999921],
            [163.696472652000153, -1.411888648999948],
            [163.692277652000058, -1.396027648999976],
            [163.688138652000163, -1.380138649],
            [163.684083652000112, -1.364222648999913],
            [163.680110652000195, -1.348305648999926],
            [163.676222652000121, -1.332360648999938],
            [163.672388652000137, -1.316388649000018],
            [163.668666652000212, -1.300388649],
            [163.664999652000205, -1.284388648999979],
            [163.661416652000213, -1.268360648999959],
            [163.657916652000068, -1.252333649000022],
            [163.654472652000123, -1.236249648999888],
            [163.651110652000142, -1.220194648999851],
            [163.647860652000162, -1.204083648999983],
            [163.644666652000211, -1.187999648999849],
            [163.641555652000221, -1.171860648999967],
            [163.638499652000206, -1.155722648999813],
            [163.635555652000249, -1.139583648999931],
            [163.632666652000154, -1.123416648999864],
            [163.629860652000133, -1.107222648999965],
            [163.627138652000241, -1.091027648999983],
            [163.624499652000139, -1.074833648999984],
            [163.62194465200011, -1.058610648999903],
            [163.619444652000112, -1.042360648999988],
            [163.617055652000232, -1.026138648999989],
            [163.614805652000229, -1.009860648999975],
            [163.613555652000201, -1.001583648999826],
            [163.612416652000121, -0.99327764899995],
            [163.610222652000203, -0.976972648999919],
            [163.608083652000147, -0.96063864899989],
            [163.606055652000208, -0.944277648999943],
            [163.604083652000128, -0.927944648999812],
            [163.602222652000165, -0.911583648999951],
            [163.600416652000121, -0.89519464899999],
            [163.598694652000205, -0.878833648999944],
            [163.597055652000194, -0.862444648999983],
            [163.595499652000143, -0.846055648999936],
            [163.593999652000122, -0.829638648999875],
            [163.592610652000218, -0.813222649],
            [163.591277652000059, -0.796805649000021],
            [163.590055652000189, -0.780388648999875],
            [163.588888652000179, -0.763972648999811],
            [163.587805652000185, -0.747527648999835],
            [163.586805652000152, -0.731083648999942],
            [163.585888652000136, -0.714638648999966],
            [163.585055652000136, -0.698194648999987],
            [163.584305652000154, -0.681749648999926],
            [163.583610652000203, -0.665277649000018],
            [163.583027652000197, -0.648833648999954],
            [163.582499652000109, -0.632360648999963],
            [163.58205565200015, -0.615888648999885],
            [163.581694652000209, -0.599444649],
            [163.581416652000115, -0.582972648999913],
            [163.58122265200015, -0.566499649000022],
            [163.581110652000206, -0.550027648999944],
            [163.581083652000103, -0.53355564899995],
            [163.581110652000206, -0.517083648999872],
            [163.581249652000253, -0.500610648999981],
            [163.581444652000215, -0.484138648999988],
            [163.581749652000241, -0.467666648],
            [163.582110652000182, -0.451222647999927],
            [163.582555652000138, -0.434749648000036],
            [163.583083652000113, -0.418277647999957],
            [163.583694652000219, -0.401833647999979],
            [163.584360652000186, -0.385360647999988],
            [163.585138652000154, -0.368916647999924],
            [163.585972652000152, -0.352472647999846],
            [163.586833652000252, -0.337027647999832],
            [163.587777652000199, -0.321583647999901],
            [163.588777652000232, -0.306138647999887],
            [163.589860652000226, -0.290722647999971],
            [163.590999652000136, -0.275277647999957],
            [163.592194652000245, -0.259860647999943],
            [163.593499652000247, -0.244444647999842],
            [163.594833652000119, -0.229055647999843],
            [163.596277652000055, -0.213638648000014],
            [163.597777652000246, -0.19824964799993],
            [163.599333652000126, -0.182860648000016],
            [163.600972652000195, -0.167472647999929],
            [163.602666652000124, -0.152110647999848],
            [163.604472652000169, -0.136499647999955],
            [163.606333652000131, -0.120888647999976],
            [163.608277652000226, -0.105277648],
            [163.610305652000221, -0.08969464800002],
            [163.612388652000192, -0.074138647999973],
            [163.614555652000178, -0.05855564799991],
            [163.616805652000181, -0.042999648000034],
            [163.619110652000103, -0.027472647999986],
            [163.621472652000222, -0.011916647999939],
            [163.62391665200019, 0.003583352],
            [163.626444652000117, 0.019055352000038],
            [163.629027652000133, 0.034472352000137],
            [163.631666652000177, 0.049916352000068],
            [163.634416652000169, 0.065333351999982],
            [163.637194652000147, 0.080722352000066],
            [163.640083652000186, 0.09611135199998],
            [163.643027652000143, 0.111472352000064],
            [163.646027652000129, 0.126833352000048],
            [163.649110652000132, 0.142194352000047],
            [163.652277652000151, 0.157527352000031],
            [163.655499652000202, 0.172833352],
            [163.658777652000168, 0.18813935200015],
            [163.662166652000252, 0.203416352000119],
            [163.665583652000208, 0.218694352000171],
            [163.669110652000171, 0.233944352000037],
            [163.672222652000158, 0.247222352000165],
            [163.675388652000123, 0.260500352000022],
            [163.678583652000071, 0.27375035200005],
            [163.681860652000211, 0.287000352000177],
            [163.685194652000206, 0.30022235200002],
            [163.688583652000119, 0.313444352000047],
            [163.692333652000258, 0.327972352000032],
            [163.696194652000059, 0.342472352000016],
            [163.700083652000131, 0.356944352],
            [163.704055652000164, 0.371416352000153],
            [163.70808365200017, 0.38586135200002],
            [163.71219465200025, 0.40030535199999],
            [163.716333652000145, 0.414694352000026],
            [163.720555652000058, 0.429083351999978],
            [163.724860652000103, 0.443472352000015],
            [163.729222652000232, 0.457889352000151],
            [163.733610652000124, 0.472139352000056],
            [163.738110652000131, 0.486472352000078],
            [163.743055652000095, 0.502055352000141],
            [163.74805565300008, 0.517611352000102],
            [163.753138653000093, 0.533139352000049],
            [163.758305653000235, 0.54863935200018],
            [163.763555653000111, 0.564111352000026],
            [163.768860653000132, 0.579583352000057],
            [163.774249653000226, 0.595000352000056],
            [163.779722653000107, 0.610416353000076],
            [163.785249653000079, 0.625777353000061],
            [163.79086065300018, 0.641139353000142],
            [163.796555653000127, 0.656444353000026],
            [163.802333653000261, 0.671750353],
            [163.808166653000143, 0.687000353000045],
            [163.814055653000167, 0.702250353000082],
            [163.820055653000196, 0.717444353000118],
            [163.826110653000256, 0.732611353000038],
            [163.832249653000162, 0.74777735300006],
            [163.838444653000096, 0.762889353000062],
            [163.844722653000218, 0.777972353000052],
            [163.851055653000259, 0.793027353000042],
            [163.85749965300019, 0.808055353000015],
            [163.863999653000207, 0.823027353000057],
            [163.870555653000253, 0.838000353000012],
            [163.877194653000203, 0.85291635300004],
            [163.883916653000057, 0.867805353000151],
            [163.890694653000224, 0.882666353000161],
            [163.897555653000182, 0.897500353000069],
            [163.90447265300017, 0.912305353000065],
            [163.911472653000175, 0.927055353000128],
            [163.918555653000197, 0.941777353],
            [163.92563865300022, 0.956333353],
            [163.932777653000102, 0.970861352999989],
            [163.939999653000172, 0.985361352999973],
            [163.947277653000157, 0.999805353000127],
            [163.954638653000103, 1.014222353],
            [163.962055653000249, 1.028611353000031],
            [163.969527653000142, 1.042944353000053],
            [163.977110653000153, 1.057277353000075],
            [163.984722653000148, 1.071527352999979],
            [163.992416653000106, 1.085777352999969],
            [164.000194653000193, 1.099972353000027],
            [164.008027653000255, 1.114139353],
            [164.015916653000119, 1.128250353000126],
            [164.023888653000228, 1.142333353000069],
            [164.031944653000124, 1.15638935300008],
            [164.040055653000223, 1.17038935300009],
            [164.048222653000067, 1.184361353000085],
            [164.05644465300017, 1.198277353000066],
            [164.064749653000064, 1.212166353000029],
            [164.073138653000257, 1.22600035300006],
            [164.081583653000081, 1.239805353],
            [164.090083653000107, 1.253583353000025],
            [164.098638653000165, 1.267305353000125],
            [164.107277653000239, 1.280972353000109],
            [164.115999653000159, 1.294611353],
            [164.12474965300018, 1.308222353000048],
            [164.133583653000159, 1.321750353000084],
            [164.142499653000158, 1.335277353000023],
            [164.151444653000141, 1.348750353000128],
            [164.160472653000085, 1.362166353000035],
            [164.169583653000046, 1.37552735300001],
            [164.178722653000165, 1.388861352999982],
            [164.187944653000187, 1.402166353000027],
            [164.197249653000114, 1.415389353000052],
            [164.206583653000138, 1.428583352999979],
            [164.215999653000125, 1.441750352999975],
            [164.225472653000196, 1.454861353000055],
            [164.235027653000117, 1.46791635300012],
            [164.244360653000143, 1.480611352999972],
            [164.253777653000128, 1.493250353000164],
            [164.263277653000188, 1.505861353000171],
            [164.27280565300012, 1.518416352999978],
            [164.28238865300014, 1.530944353000052],
            [164.292055653000176, 1.543416353000012],
            [164.301749653000257, 1.55583335300004],
            [164.31152765300024, 1.568222353000067],
            [164.321360653000085, 1.580527352999979],
            [164.331249653000185, 1.59283335300006],
            [164.341166653000215, 1.60505535300004],
            [164.351166653000206, 1.617250353000088],
            [164.361222653000112, 1.629389353000121],
            [164.371360653000039, 1.641500352999969],
            [164.381527653000177, 1.653527353000058],
            [164.390805653000172, 1.664444352999965],
            [164.400138653000198, 1.675277353000126],
            [164.409527653000083, 1.686139354000105],
            [164.418944653000125, 1.696916354000066],
            [164.428416653000198, 1.707639354],
            [164.437916653000201, 1.718361354000024],
            [164.445749653000206, 1.727111354000144],
            [164.45363865300024, 1.735833353999979],
            [164.461527653000161, 1.744527354000169],
            [164.469860653000211, 1.753639354000029],
            [164.47824965300012, 1.762750354],
            [164.486666653000128, 1.771805354000122],
            [164.495110653000239, 1.780833354000052],
            [164.503583653000163, 1.789833354000066],
            [164.513749653000133, 1.800694354000143],
            [164.523916653000157, 1.811472354],
            [164.534166653000199, 1.822222354000047],
            [164.544472653000099, 1.832944354000077],
            [164.554805653000102, 1.843583353999989],
            [164.565166653000261, 1.854194354000072],
            [164.575610653000041, 1.864777354000054],
            [164.586083653000088, 1.875277354000104],
            [164.596610653000226, 1.885750354000137],
            [164.607166653000121, 1.896166354000073],
            [164.617777653000104, 1.906555354],
            [164.628444653000116, 1.916889353999977],
            [164.639138653000117, 1.92716635400005],
            [164.648388653000239, 1.935972354],
            [164.657638653000134, 1.944722354000021],
            [164.666944653000172, 1.953444354000041],
            [164.676277653000199, 1.962139354000044],
            [164.688444653000062, 1.973361354000147],
            [164.700666653000127, 1.984527353999965],
            [164.712944653000108, 1.995639354000019],
            [164.72530565300022, 2.00669435400016],
            [164.737666653000048, 2.017666354],
            [164.750110653000178, 2.028611354],
            [164.762610653000166, 2.039472354000083],
            [164.775166653000127, 2.050277354000045],
            [164.787749653000134, 2.061000353999987],
            [164.800416653000156, 2.071666354],
            [164.813110653000109, 2.082277354000084],
            [164.825888654000181, 2.092833354000049],
            [164.838694654000136, 2.103333354000014],
            [164.851555654000236, 2.113750354000032],
            [164.864472654000252, 2.124083354000021],
            [164.877444654000186, 2.134389354],
            [164.890444654000049, 2.144611354000148],
            [164.903527654000214, 2.154777354],
            [164.916638654000195, 2.164861354],
            [164.929805654000205, 2.174889354000072],
            [164.943027654000133, 2.184861354000134],
            [164.956277654000161, 2.194750354000163],
            [164.969583654000218, 2.204583354],
            [164.982944654000192, 2.214361354000076],
            [164.996360654000199, 2.224055354000143],
            [165.009833654000118, 2.233694354000093],
            [165.02333365400014, 2.243250354000011],
            [165.036888654000251, 2.252750354000099],
            [165.050472654000117, 2.262166354000072],
            [165.064138654000118, 2.271527354000114],
            [165.077805654000116, 2.280833354000038],
            [165.091555654000189, 2.290055354000131],
            [165.105333654000134, 2.299194354],
            [165.119166654000168, 2.308277354000055],
            [165.133027654000131, 2.317305353999984],
            [165.146944654000123, 2.326250353999981],
            [165.160916654000204, 2.335139354000049],
            [165.1749166540001, 2.343944354],
            [165.188972654000139, 2.352666354000021],
            [165.203055654000167, 2.36133335400001],
            [165.21719465400011, 2.369944354],
            [165.231360654000099, 2.378472354000039],
            [165.245583654000058, 2.38691635400005],
            [165.259833654000232, 2.395305354000143],
            [165.274166654000084, 2.40361135400002],
            [165.288499654000105, 2.411861354000067],
            [165.302888654000213, 2.420027354000084],
            [165.317305654000194, 2.428111354000066],
            [165.331777654000263, 2.436139354000133],
            [165.346277654000147, 2.444111354],
            [165.360805654000131, 2.451972354],
            [165.375388654000147, 2.459777354],
            [165.389999654000263, 2.467527353999969],
            [165.404666654000124, 2.47519435400001],
            [165.419360654000258, 2.482777354000021],
            [165.434083654000204, 2.490277354],
            [165.448860654000185, 2.497722354000047],
            [165.463666654000264, 2.505111354000178],
            [165.478499654000103, 2.512389354],
            [165.493388654000199, 2.519611354000162],
            [165.508305654000225, 2.526750354000029],
            [165.523249654000182, 2.533833353999967],
            [165.538222654000123, 2.540833353999972],
            [165.553249654000211, 2.54775035400003],
            [165.56830565400017, 2.554583354000073],
            [165.582888654000186, 2.561139354000019],
            [165.597499654000131, 2.567611354000121],
            [165.612138654000233, 2.574027354000023],
            [165.626833654000194, 2.58036135399999],
            [165.641527654000157, 2.586639354000027],
            [165.65624965400005, 2.592833354000135],
            [165.671027654000198, 2.59897235400004],
            [165.685833654000106, 2.605000354],
            [165.700638654000187, 2.611000354000126],
            [165.715499654000183, 2.616916354000054],
            [165.730360654000123, 2.622750354000033],
            [165.745277654000148, 2.628500354],
            [165.76022265400016, 2.63422235400003],
            [165.775166654000117, 2.639833354000132],
            [165.788666654000139, 2.644833354000028],
            [165.80216665400016, 2.649777354000094],
            [165.815694654000112, 2.654639354000025],
            [165.829222654000233, 2.659472354000059],
            [165.842805654000102, 2.66425035400006],
            [165.856388654000142, 2.668944354000047],
            [165.869999654000111, 2.673583354000186],
            [165.88361065400025, 2.678166354000041],
            [165.905160655000117, 2.684763354000097],
            [165.928331655000164, 2.683935353999971],
            [165.954234655000135, 2.678330354000025],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "NZL", Country: "New Zealand" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [180.000000000000227, -33.934426229999929],
              [180.000000000000227, -36.885245901999951],
              [180.000000000000227, -39.836065574000031],
              [180.000000000000227, -42.786885246],
              [180.000000000000227, -45.737704917999963],
              [180.000000000000227, -48.688524589999986],
              [180.000000000000227, -51.639344262],
              [180.000000000000227, -52.956988545999934],
              [179.98313604700013, -52.95951453899994],
              [179.971003359000065, -52.961293718999968],
              [179.958347594000202, -52.963149078000015],
              [179.941014449000022, -52.965634944999977],
              [179.933527046000222, -52.966708538999967],
              [179.908676046000323, -52.970193539000036],
              [179.888194353000245, -52.973001313999987],
              [179.883794225000088, -52.973604443],
              [179.858882820000304, -52.976940309999975],
              [179.833942358000172, -52.980201400999874],
              [179.82395655900018, -52.981475668999963],
              [179.808973046000233, -52.983387538999978],
              [179.783977046000132, -52.986498538999967],
              [179.771796248000328, -52.987976843000013],
              [179.758953165000094, -52.989535156999978],
              [179.733903014000134, -52.992496294000034],
              [179.708827080000106, -52.995382241999948],
              [179.697906320000016, -52.996605088999956],
              [179.683726046000174, -52.998192538999945],
              [179.668047341000261, -52.999899812000024],
              [179.658600501000251, -53.000928260000023],
              [179.633451174000129, -53.003588184000023],
              [179.611385651000091, -53.005853633999948],
              [179.608279046000121, -53.006172538999976],
              [179.597220619000041, -53.007273772999952],
              [179.583083755000303, -53.00868150499997],
              [179.577611471000182, -53.009209546999955],
              [179.557867046000069, -53.011114538999948],
              [179.544312320000017, -53.012380963999931],
              [179.532629068000233, -53.013472343999979],
              [179.507370660000333, -53.015754171999959],
              [179.482092431000069, -53.017960189999883],
              [179.456795047000014, -53.020090338999978],
              [179.435222913000189, -53.02184078199997],
              [179.431479046000277, -53.022144538999953],
              [179.406145046000233, -53.024122539000018],
              [179.380795046000344, -53.026024538999934],
              [179.366894155000068, -53.027025678999962],
              [179.355427330000111, -53.027851145999989],
              [179.330044204000274, -53.029601141999933],
              [179.31775335400016, -53.030411142999981],
              [179.30464604600013, -53.03127453899998],
              [179.279233046000058, -53.032872538999982],
              [179.253807046000247, -53.03439353899995],
              [179.22836704600013, -53.03583853899994],
              [179.202915046000271, -53.037207539],
              [179.177451046000101, -53.038499539000043],
              [179.151976046000186, -53.039715539000042],
              [179.143205510000143, -53.040107864],
              [179.126490789000286, -53.040855163],
              [179.100995673000057, -53.04191811000004],
              [179.076966275000132, -53.042847508000023],
              [179.075491046000025, -53.042904539],
              [179.069826867000074, -53.043106576999946],
              [179.049978911000039, -53.043814452999982],
              [179.043130742000017, -53.044038075],
              [179.024459046000175, -53.044647538999982],
              [178.998931046000052, -53.045404539],
              [178.973398046000284, -53.046084538999935],
              [178.962978334000013, -53.046330957],
              [178.947858228000086, -53.046688274000019],
              [178.922313853000105, -53.047215183],
              [178.896765152000171, -53.047665444000017],
              [178.871212804000038, -53.048039043000017],
              [178.857581246000109, -53.04819742799998],
              [178.845657045000166, -53.048335538999936],
              [178.83097849000012, -53.048462468999958],
              [178.820099881000147, -53.048556217999931],
              [178.805458487000351, -53.048638456999981],
              [178.794541045000301, -53.048699538999983],
              [178.768980045000148, -53.048766538999985],
              [178.743420045000107, -53.048756538999953],
              [178.728355621000105, -53.048705852999944],
              [178.717860141000187, -53.048670331000039],
              [178.692301272000236, -53.048507135000023],
              [178.666744188000052, -53.048267254999985],
              [178.650547003000042, -53.048066613],
              [178.641190045000144, -53.047950539],
              [178.623091560000034, -53.047672177000038],
              [178.615638088000082, -53.047557470999948],
              [178.599499493000053, -53.047260642000026],
              [178.590090045000238, -53.047087539000017],
              [178.588207123000188, -53.047047289999966],
              [178.564547270000048, -53.046541054999977],
              [178.549624929000174, -53.046176928999955],
              [178.539009045000057, -53.045917539000015],
              [178.527541883000026, -53.045603597999957],
              [178.513477163000175, -53.045218115999944],
              [178.496599880000076, -53.044704785000022],
              [178.487952045000156, -53.04444153899999],
              [178.462433045000239, -53.043588539000041],
              [178.449186406000081, -53.043106135000016],
              [178.436922697000341, -53.042659293999975],
              [178.411420769000074, -53.041653265000036],
              [178.396817693000116, -53.041033155999969],
              [178.385928045000014, -53.040570538999987],
              [178.36044504500012, -53.039411539000014],
              [178.349691514000256, -53.038890157999958],
              [178.334973140000102, -53.038176292999935],
              [178.309512234000067, -53.036864442999956],
              [178.28406327100015, -53.035476220000021],
              [178.265744768000218, -53.034421487999964],
              [178.258627045000026, -53.034011538999948],
              [178.233204045000093, -53.03247053900003],
              [178.207795045000239, -53.030853538999963],
              [178.195571010000322, -53.03003860299998],
              [178.182400332000043, -53.029160370999932],
              [178.173211225000273, -53.028519685999981],
              [178.157021045000079, -53.027390538999953],
              [178.143001241000206, -53.02637068599995],
              [178.131658026000196, -53.025545269999952],
              [178.107533371000272, -53.023716224999987],
              [178.106311279000124, -53.023623563999934],
              [178.080982045000042, -53.021625539000013],
              [178.068340245000172, -53.020590160999973],
              [178.055671121000159, -53.019552219],
              [178.038042869000151, -53.018054017999987],
              [178.030379045000245, -53.017402538999931],
              [178.023147159000132, -53.016765878999934],
              [178.005105359000027, -53.015177209],
              [177.993711281000174, -53.014138911999964],
              [177.97985204500003, -53.012875539],
              [177.954620045000297, -53.010498538999968],
              [177.935532954000109, -53.008642219000023],
              [177.929408320000334, -53.008046395],
              [177.904219063000141, -53.005518161],
              [177.879052455000192, -53.002914390999933],
              [177.853909160000057, -53.000235153999938],
              [177.828869207000139, -52.997489222666616],
              [177.803695097500167, -52.994650551],
              [177.778625755000348, -52.991745358999935],
              [177.772111565000273, -52.990970116999961],
              [177.753582044000041, -52.988764538999988],
              [177.732289077000132, -52.986164306000035],
              [177.728565473000117, -52.985709518999954],
              [177.703575898000167, -52.982579043000044],
              [177.682113867000112, -52.979823039],
              [177.678614044000028, -52.979373538999937],
              [177.670319017000338, -52.978282306999972],
              [177.653681158000154, -52.976093398999978],
              [177.62877729600018, -52.972738401999948],
              [177.616086782000139, -52.97098861500001],
              [177.603903044000162, -52.969308539],
              [177.579059945500148, -52.965804521],
              [177.554248044000019, -52.962225538999974],
              [177.529467044000285, -52.958572538999988],
              [177.520522337000102, -52.957225483],
              [177.5047190800002, -52.954845295000041],
              [177.489429339000139, -52.952493466999954],
              [177.480004044000225, -52.951043539000011],
              [177.474154314000089, -52.950125111000034],
              [177.45532304100027, -52.947168044999955],
              [177.430676292000157, -52.943218389000037],
              [177.422598770000263, -52.941897870999981],
              [177.406065044000229, -52.939194538999971],
              [177.385689161000187, -52.935797866999962],
              [177.381488535000244, -52.935097540000015],
              [177.367612006000058, -52.932738968999956],
              [177.356949044000316, -52.930926539000041],
              [177.332446044000108, -52.926681538999958],
              [177.319630540000276, -52.924420164000026],
              [177.307980809000128, -52.922364048999896],
              [177.292208826000092, -52.919528677999949],
              [177.283554044000084, -52.917972539],
              [177.271347997000134, -52.915738333999968],
              [177.2591656620001, -52.913508223],
              [177.240918427000111, -52.910107673000027],
              [177.234817044000238, -52.908970539],
              [177.210508044000107, -52.904359539000041],
              [177.186241044000099, -52.899676538999984],
              [177.174001162000195, -52.897273728999949],
              [177.16201404100002, -52.894920405],
              [177.137829275500053, -52.890091583999947],
              [177.113687567000056, -52.885190379],
              [177.109414699000013, -52.884308526000041],
              [177.089589044000036, -52.880216538999946],
              [177.06553404400006, -52.875170538999953],
              [177.054820929000016, -52.872887459999959],
              [177.041523484000095, -52.870053072000019],
              [177.017558086000349, -52.864863229000036],
              [176.993638358000112, -52.859601575],
              [176.969764909000332, -52.854268248999972],
              [176.945938347000038, -52.848863390999952],
              [176.922159277000219, -52.843387144000019],
              [176.900898614000084, -52.838417126000024],
              [176.898428044000184, -52.837839539000015],
              [176.896122962000163, -52.837292711999964],
              [176.874746031000086, -52.83222106],
              [176.851113049000105, -52.826531526999986],
              [176.827529976000278, -52.820771169999944],
              [176.803997390000063, -52.814940172999968],
              [176.78717360800033, -52.810711931999947],
              [176.780516044000137, -52.809038538999971],
              [176.757086044000062, -52.803066539000042],
              [176.733708044000082, -52.797024539],
              [176.710384043000317, -52.790912538999976],
              [176.687113043000295, -52.784730539],
              [176.67486300100029, -52.781432449999954],
              [176.663895315000332, -52.778479218999962],
              [176.640732683000351, -52.772158052],
              [176.617625234000116, -52.765767506999964],
              [176.613163000000213, -52.764517058999957],
              [176.594574043000193, -52.759307538999963],
              [176.571578043000159, -52.752778538999962],
              [176.555428469000276, -52.748133899000038],
              [176.54863973800002, -52.74618129199996],
              [176.542911459000265, -52.744512359999987],
              [176.525759043000335, -52.739514538999913],
              [176.513851163000254, -52.73600108],
              [176.502935830000297, -52.732780041999945],
              [176.485432395000203, -52.72754905100004],
              [176.480171043000269, -52.72597653899993],
              [176.472061644000178, -52.723522467],
              [176.457466352000097, -52.719105401000036],
              [176.450281903000132, -52.716903819000038],
              [176.434821043000056, -52.712165538999955],
              [176.412236043000121, -52.705158538999967],
              [176.389711043000148, -52.698083538999981],
              [176.367249043000015, -52.690941539],
              [176.356090406000021, -52.687350511000034],
              [176.344847661000131, -52.683732135999946],
              [176.322509211000352, -52.676455525],
              [176.320115484000212, -52.675666388999957],
              [176.300234043000273, -52.669111539000028],
              [176.278022043000078, -52.661701539000035],
              [176.255874043000176, -52.654224538999983],
              [176.233601043000249, -52.646752539000033],
              [176.21139204300016, -52.639213539000025],
              [176.199504954000304, -52.635130925],
              [176.189249012000232, -52.631608190999941],
              [176.1783959400002, -52.627836474],
              [176.167172043000164, -52.623935538999945],
              [176.14516104300003, -52.61619653799994],
              [176.136072505000016, -52.612964358999932],
              [176.123217500000123, -52.608392053999978],
              [176.101341479000325, -52.600521098999934],
              [176.079533814000115, -52.592584451],
              [176.057794347000026, -52.584582055],
              [176.036124291000078, -52.576514381000038],
              [176.018233515000247, -52.569778257999985],
              [176.014524043000279, -52.568381537999983],
              [175.992994043000181, -52.56018353799999],
              [175.983444371000132, -52.556506958999989],
              [175.97153441900025, -52.551921273],
              [175.950146284000311, -52.543594252999981],
              [175.940316129000053, -52.539724552],
              [175.928830043000062, -52.535202538000021],
              [175.907586043000038, -52.526747538],
              [175.904420770000229, -52.525473924000039],
              [175.886414511000112, -52.518228265999937],
              [175.865316483000129, -52.50964535899999],
              [175.855528935000279, -52.505620180999983],
              [175.84429204300011, -52.500998537999976],
              [175.828233672000238, -52.49432300899997],
              [175.823342277000279, -52.492289509999978],
              [175.818805452000333, -52.490382974999932],
              [175.802467043000206, -52.483516538],
              [175.781667043000084, -52.47468153799997],
              [175.760943043000111, -52.465783538000039],
              [175.740295043000174, -52.456823538000037],
              [175.719724043000042, -52.447801537999965],
              [175.699230043000284, -52.438717537999935],
              [175.678814043000216, -52.429572538],
              [175.668493262000197, -52.424900557],
              [175.65847546700013, -52.420365610000033],
              [175.649001140000223, -52.416031457999964],
              [175.638216042000067, -52.411097538000035],
              [175.618035042000088, -52.401768537999885],
              [175.61414511400028, -52.399951497000018],
              [175.597934668000335, -52.392379291],
              [175.577913832000149, -52.382929403],
              [175.557973480000101, -52.373419359999957],
              [175.538114068000027, -52.363849406000043],
              [175.530760255000246, -52.360268944999973],
              [175.518336042000328, -52.354219537999938],
              [175.498640042000147, -52.344530538000015],
              [175.479026042000214, -52.334782538000013],
              [175.470373544000154, -52.330437904],
              [175.459494835000214, -52.324975402],
              [175.456191629000045, -52.323299711999937],
              [175.440047042000174, -52.315109537999945],
              [175.434683987000085, -52.31236112800002],
              [175.42068249600004, -52.305185372000039],
              [175.410645899000201, -52.299988919000043],
              [175.401402042000086, -52.295202537999955],
              [175.383267814000192, -52.285717871999907],
              [175.382206347000192, -52.28516267599997],
              [175.363095422000015, -52.275064714999985],
              [175.344069830000194, -52.26490935399994],
              [175.340832668000076, -52.263163851999934],
              [175.325130042000183, -52.254696538000019],
              [175.313937480000163, -52.248600408999948],
              [175.306276337000327, -52.24442746699998],
              [175.287509270000214, -52.234101460999966],
              [175.268829209000273, -52.223719095999968],
              [175.25778104300025, -52.217516329000034],
              [175.250237042000208, -52.213280537999935],
              [175.232828556000072, -52.203408359999969],
              [175.231731739000224, -52.202786343000014],
              [175.213315140000077, -52.192236484000034],
              [175.194987167000022, -52.181631327],
              [175.176748218000171, -52.17097113799997],
              [175.158598686000346, -52.160256188],
              [175.152284711000107, -52.156491014999972],
              [175.140539042000228, -52.149486537999962],
              [175.135663631000284, -52.146550167999976],
              [175.122569433000081, -52.138663083999973],
              [175.104690485000333, -52.127785474999939],
              [175.086902498000313, -52.116854193],
              [175.071512381000161, -52.107301224999958],
              [175.069206042000019, -52.105869537999979],
              [175.051601042000016, -52.094831537999937],
              [175.045673732000068, -52.091078105999941],
              [175.034088070000166, -52.083741063],
              [175.025805041000012, -52.078442699999982],
              [175.016668042000106, -52.072597537999968],
              [175.00448691500003, -52.064727747999939],
              [174.999340105000329, -52.061402347999945],
              [174.994012691000307, -52.057925570999942],
              [174.982106042000169, -52.050154538000015],
              [174.964965042000017, -52.03885553799995],
              [174.94791804200031, -52.027504538000017],
              [174.939939449000349, -52.022138882999968],
              [174.930965196000216, -52.016103096],
              [174.914107085000069, -52.00465038699997],
              [174.897343915000306, -51.993147089999979],
              [174.880676031000121, -51.981593490999956],
              [174.879531232000204, -51.98079192199998],
              [174.864104042000235, -51.969989538],
              [174.853554221000309, -51.962528405999933],
              [174.847627469000258, -51.958336541],
              [174.831247461000089, -51.946633768000027],
              [174.827990300000351, -51.944283035999945],
              [174.81496404200027, -51.934881538000013],
              [174.80456297600017, -51.927298900000039],
              [174.798777636000182, -51.923081081000021],
              [174.782688468000174, -51.911231750999946],
              [174.766696891000151, -51.899334153999973],
              [174.750803221000297, -51.887388586999975],
              [174.735007906000533, -51.87539544],
              [174.719311042000299, -51.863354537999953],
              [174.703713042000231, -51.851266537999948],
              [174.688214042000027, -51.839132538],
              [174.68130757300014, -51.833669699999973],
              [174.672814309000273, -51.826951548000011],
              [174.657514537000225, -51.814724389],
              [174.642380125000159, -51.802504093666641],
              [174.627215370000272, -51.790132719999974],
              [174.625492235000252, -51.788712295999972],
              [174.612217042000111, -51.777768537999975],
              [174.597319042000095, -51.765359537999984],
              [174.58252195300031, -51.752906459999963],
              [174.567827041000328, -51.740408538],
              [174.553233041000226, -51.727866537999944],
              [174.549113959000124, -51.724289199999987],
              [174.538741355000184, -51.715280754999981],
              [174.532071675000111, -51.709426939000025],
              [174.524352041000157, -51.702651537999941],
              [174.522320840000248, -51.700849941999934],
              [174.510065187000237, -51.689979260999962],
              [174.495881145000226, -51.677264116999936],
              [174.48180045700019, -51.664506697666639],
              [174.489195041000016, -51.679245538000032],
              [174.496485041000142, -51.69400653799994],
              [174.500723934000121, -51.702726207000012],
              [174.503669737000223, -51.708786593000013],
              [174.510748548000151, -51.723587184],
              [174.51374022400023, -51.729945730999987],
              [174.517721041000186, -51.73840753799999],
              [174.524588041000129, -51.753247538000039],
              [174.528465964000247, -51.761770248],
              [174.531348696000236, -51.768106301999978],
              [174.53313703400022, -51.772104996999985],
              [174.538002041000141, -51.782984538],
              [174.544549041000209, -51.797880537999959],
              [174.550989041000321, -51.812795537999989],
              [174.555302257000335, -51.822965272999966],
              [174.557321899000158, -51.827727727000024],
              [174.563546893000193, -51.84267796499995],
              [174.568816983000147, -51.855572826000014],
              [174.569664041000124, -51.857645537999986],
              [174.575673041000073, -51.872630537999953],
              [174.581574041000181, -51.887631537999937],
              [174.583722527000191, -51.893201354999974],
              [174.587366670000279, -51.902649332999943],
              [174.589501718000179, -51.908296683999936],
              [174.593050042000186, -51.917683538],
              [174.598625042000094, -51.932732537999982],
              [174.604090982500338, -51.947798390499941],
              [174.609447042000284, -51.962878538000041],
              [174.614694042000224, -51.977974537999955],
              [174.615588096000124, -51.980604142],
              [174.619830854000043, -51.993083977999902],
              [174.624857741000028, -52.008208187000037],
              [174.626398184000209, -52.012951149999878],
              [174.629774042000236, -52.023346537999885],
              [174.632411855000328, -52.031661109000026],
              [174.634580596000205, -52.038498190000041],
              [174.637802277000105, -52.048903232000043],
              [174.63927604200012, -52.053663538000023],
              [174.641116295000188, -52.059755439999947],
              [174.643860820000214, -52.068841560999942],
              [174.645482585000195, -52.074348548999978],
              [174.648334042000101, -52.084032538000031],
              [174.650623339000049, -52.092009660000031],
              [174.652696754000317, -52.09923580200001],
              [174.656947600000308, -52.114451215999964],
              [174.661086765000164, -52.129678405],
              [174.66366735000031, -52.139442958000018],
              [174.665114042000255, -52.144917538000016],
              [174.669029042000147, -52.160166537999942],
              [174.672832042000209, -52.175427537999951],
              [174.673860821000176, -52.179683968999939],
              [174.676522648000059, -52.190698610999959],
              [174.678549027000145, -52.199353578999933],
              [174.680100042000277, -52.205979537999895],
              [174.683565042000282, -52.221271537999939],
              [174.686917042000346, -52.236571537999964],
              [174.688981340000055, -52.246328997000035],
              [174.690156013000205, -52.251881935000029],
              [174.691297783000323, -52.257478446999983],
              [174.693281042000194, -52.26720053799994],
              [174.696001642000283, -52.281045610000035],
              [174.696292994000032, -52.282528400999979],
              [174.699190921000081, -52.297864216999926],
              [174.70197489900022, -52.313207993],
              [174.704644753000139, -52.328559408999979],
              [174.705781992000198, -52.335394146999931],
              [174.707200042000295, -52.343918538000025],
              [174.709641042000271, -52.359283538],
              [174.711161127000196, -52.369325772999957],
              [174.71196784100016, -52.37465627499995],
              [174.712647206000156, -52.379380279000031],
              [174.714179042000069, -52.390034537999952],
              [174.716276042000345, -52.40541953799999],
              [174.716596708000111, -52.407909639999957],
              [174.718257667000103, -52.420810285999949],
              [174.720123892000061, -52.436206143],
              [174.721874658000161, -52.451607053999908],
              [174.723509806000038, -52.467012692000026],
              [174.724533629000348, -52.477396657999975],
              [174.725029042000187, -52.48242253799998],
              [174.725336237000079, -52.485795118999988],
              [174.72643263100008, -52.497836844999938],
              [174.727720003000172, -52.51325470699993],
              [174.728204113000288, -52.519629317999886],
              [174.728891042000328, -52.528675538000016],
              [174.729328241000189, -52.535067772],
              [174.72994592200007, -52.544100367999931],
              [174.730363876000183, -52.550972532999936],
              [174.730884042000184, -52.559527538],
              [174.731175026000301, -52.564989699000023],
              [174.73170577000019, -52.574957091999977],
              [174.732410564000247, -52.590388780999945],
              [174.732737582000254, -52.598974261999956],
              [174.732998042000304, -52.605822537999984],
              [174.733469042000252, -52.621257538],
              [174.733605903000239, -52.627225293000038],
              [174.733822771000064, -52.636693209000043],
              [174.734059004000187, -52.652130038],
              [174.734177768000222, -52.667567326999972],
              [174.734178937000252, -52.683004744000016],
              [174.734152275000156, -52.686536107999984],
              [174.734062042000232, -52.698441539000044],
              [174.733855241000128, -52.712084179999977],
              [174.73382799400008, -52.71387863800004],
              [174.733475640000051, -52.729314451],
              [174.733133089000262, -52.740553324999951],
              [174.733005042000173, -52.744749539],
              [174.732866169000317, -52.748394460000043],
              [174.732416580000177, -52.760182148999945],
              [174.731709645000251, -52.775613366999949],
              [174.731194437000227, -52.785244612999975],
              [174.730884042000184, -52.791042538999932],
              [174.729940042000294, -52.80646853899998],
              [174.729145306000078, -52.818010917000038],
              [174.728877910000165, -52.821892503000015],
              [174.727696668000249, -52.83731292799996],
              [174.726396592000015, -52.852729818999933],
              [174.724977582000179, -52.868142842],
              [174.723439544000144, -52.883551661999945],
              [174.722517198000332, -52.892125415999942],
              [174.721782042000314, -52.898955538999985],
              [174.720220828000038, -52.912493084999937],
              [174.720006010000247, -52.914355349],
              [174.719708237000077, -52.916773472999978],
              [174.718110042000035, -52.929749539],
              [174.71609504200012, -52.94513853899997],
              [174.715175499000338, -52.951766654999972],
              [174.713960740000061, -52.960520962999965],
              [174.71170665600016, -52.975897512000017],
              [174.709332942000174, -52.991267506000042],
              [174.7088941720001, -52.993970966],
              [174.70683904200007, -53.006630539000014],
              [174.704226042000073, -53.021986538999954],
              [174.701493042000038, -53.037334539000028],
              [174.698640042000079, -53.052675538999949],
              [174.695667042000309, -53.06800753899995],
              [174.69257404200016, -53.083330538999974],
              [174.689361042000087, -53.098645539000017],
              [174.686028042000203, -53.113950538999973],
              [174.682574042000056, -53.129246539],
              [174.680038284000034, -53.140095011000028],
              [174.679000788000167, -53.144532657999946],
              [174.675306722000187, -53.159808259999977],
              [174.672676068000328, -53.170335880999971],
              [174.671492042000182, -53.175073539000039],
              [174.667557042000226, -53.190327538999952],
              [174.663728664000018, -53.204718650000018],
              [174.663502154000071, -53.20557004099993],
              [174.663101925000092, -53.207029872000021],
              [174.659326042000089, -53.220801539000036],
              [174.655675336000172, -53.233734521000031],
              [174.655030064000243, -53.236020318999977],
              [174.65300658200033, -53.242986973999962],
              [174.650613042000032, -53.251227538999956],
              [174.650084384000138, -53.252997964999963],
              [174.646075744000314, -53.266421366999964],
              [174.641417674000195, -53.281602581000016],
              [174.636638963000109, -53.296770470000013],
              [174.631739590000052, -53.311924693000037],
              [174.629762502000176, -53.317887486000018],
              [174.626719042000104, -53.327064538999934],
              [174.622717981000278, -53.338838864999957],
              [174.621578783000132, -53.342190780999942],
              [174.620057139000323, -53.346561020999957],
              [174.616317042000219, -53.357301538999934],
              [174.611514552000131, -53.370772959999954],
              [174.610935136000307, -53.372398127999986],
              [174.610127022000029, -53.374612772999967],
              [174.605432042000075, -53.387478539000028],
              [174.60244256600015, -53.395487867000043],
              [174.59980857700009, -53.402544014],
              [174.594064196000204, -53.417593059000012],
              [174.592697501000089, -53.421095983000043],
              [174.588199042000042, -53.432625539000014],
              [174.582213041000273, -53.44764153899996],
              [174.579865734000123, -53.45340755],
              [174.576106666000214, -53.462640520000036],
              [174.572251639000115, -53.471915918999969],
              [174.569880041000033, -53.477621538999983],
              [174.566591265000056, -53.485527660999935],
              [174.56368400600013, -53.492516122999973],
              [174.557367948000092, -53.507392515999982],
              [174.550932561000081, -53.522251026],
              [174.544377862000033, -53.537091318999956],
              [174.537703874000044, -53.551913060999937],
              [174.530910621000089, -53.566715918999932],
              [174.525982003000195, -53.577256678999959],
              [174.523998041000141, -53.581499538999935],
              [174.516966041000273, -53.596263539],
              [174.512899271000038, -53.604649617999939],
              [174.50981556000022, -53.611007843000039],
              [174.502545551000139, -53.625731822999967],
              [174.498285957000121, -53.63420789700001],
              [174.495156041000087, -53.640435538999952],
              [174.487648041000284, -53.655117538999946],
              [174.482352807000211, -53.665296996999984],
              [174.480021104000116, -53.669779108999933],
              [174.475261763000049, -53.678773996999951],
              [174.47227504100033, -53.684418538999978],
              [174.464409980000369, -53.699036647499895],
              [174.456426015000147, -53.713632416999985],
              [174.454284729000278, -53.717483623999954],
              [174.448323041000094, -53.728205539999976],
              [174.441434859000196, -53.740396641999951],
              [174.440101871000024, -53.742755751],
              [174.431761756000014, -53.757282758000031],
              [174.423303033000138, -53.77178621600001],
              [174.414725773000214, -53.786265792999984],
              [174.406030048000048, -53.800721156999941],
              [174.400862738000114, -53.809181284000033],
              [174.397216041000092, -53.815151539999931],
              [174.388283041000136, -53.829557539999939],
              [174.380412685000124, -53.842064014000016],
              [174.379232863000084, -53.843938653999942],
              [174.376353514000243, -53.848446732000014],
              [174.370064041000091, -53.858293540000034],
              [174.362290607000091, -53.870288082],
              [174.360777231000156, -53.872623177000037],
              [174.359144280000123, -53.875106621999976],
              [174.351372041000332, -53.88692654],
              [174.343249800000194, -53.899103931000013],
              [174.341849762000038, -53.901202898999941],
              [174.340949386000148, -53.902533763999941],
              [174.332209041000169, -53.91545254],
              [174.323838666000142, -53.927652973999955],
              [174.322451229000194, -53.929675180999936],
              [174.312575570000263, -53.943870207000032],
              [174.30561719800005, -53.95373505099991],
              [174.302582041000051, -53.958037540000021],
              [174.295739695000179, -53.967606671999931],
              [174.292472007000015, -53.972176383000033],
              [174.290240364000056, -53.975255241999946],
              [174.282244041000013, -53.986286539999981],
              [174.271899041000097, -54.000368539999954],
              [174.261438041000133, -54.01442054],
              [174.25085904100024, -54.028443539999955],
              [174.245884316000115, -54.034952777999933],
              [174.240163754000037, -54.042437218999964],
              [174.229351776000158, -54.056400260000032],
              [174.224049174000243, -54.063160494999977],
              [174.218423041000165, -54.070332539999924],
              [174.212488112000187, -54.077803327999959],
              [174.207378545000097, -54.084234604000017],
              [174.196217570000272, -54.098105253000028],
              [174.184940548000327, -54.111944453999968],
              [174.17354762500031, -54.125751879999939],
              [174.162038952000103, -54.139527208],
              [174.150414683000122, -54.153270112999962],
              [174.138674976000175, -54.166980270999943],
              [174.126819993000254, -54.180657359],
              [174.114849896000123, -54.194301054],
              [174.106427609000207, -54.203786096999977],
              [174.102765041000225, -54.207910539999943],
              [174.090565041000104, -54.221486539999944],
              [174.084507364000046, -54.22814831200003],
              [174.078250622000041, -54.235028555999975],
              [174.065821784000036, -54.248535456999946],
              [174.053278705000025, -54.262007357999948],
              [174.040621571000088, -54.275443937000034],
              [174.027850568000076, -54.288844876000041],
              [174.021462934000056, -54.295470621],
              [174.014966041000093, -54.302209539999978],
              [174.010509842000033, -54.306779217999974],
              [174.001967729000057, -54.315538554999989],
              [173.996059200000104, -54.321528496999974],
              [173.98885604100019, -54.328830540000013],
              [173.97563204100004, -54.342085540000028],
              [173.962294041000064, -54.355303539999966],
              [173.950309471000082, -54.367048417999968],
              [173.948844294000082, -54.36848422099996],
              [173.935281772000167, -54.381626769999968],
              [173.921607010000116, -54.394731141000022],
              [173.916165900000237, -54.399887738000018],
              [173.907820041000264, -54.40779654],
              [173.901628715000129, -54.413600296000027],
              [173.893921650000038, -54.420824090999986],
              [173.879911501000151, -54.433812042],
              [173.865790025000024, -54.446760550499903],
              [173.851557041000092, -54.459669539999936],
              [173.849019005000059, -54.461946747999946],
              [173.837213938000218, -54.472538051000015],
              [173.822759835000056, -54.485366400999965],
              [173.808195344000183, -54.498154072999967],
              [173.793520712000145, -54.510900758999981],
              [173.778736191000121, -54.523606148],
              [173.77269028700016, -54.528746688999888],
              [173.763842041000089, -54.536269539999978],
              [173.748838041000113, -54.54889154],
              [173.737800756000183, -54.558079539999937],
              [173.733725844000332, -54.561471465000011],
              [173.727032466000082, -54.566984439],
              [173.718504041000074, -54.574008539999966],
              [173.703175041000236, -54.586502539999969],
              [173.699105613000143, -54.589864312000032],
              [173.688211561000116, -54.598863202999951],
              [173.677165565000109, -54.607893368999953],
              [173.673143041000117, -54.611181539999961],
              [173.657969041000342, -54.62345854],
              [173.642690041000037, -54.635693539999977],
              [173.632265567000104, -54.643956271999969],
              [173.627306868000232, -54.64788644500004],
              [173.611818985000127, -54.660036504],
              [173.603990888000226, -54.666115006000041],
              [173.596227041000247, -54.672143540000036],
              [173.580531041000086, -54.684207539999989],
              [173.57715741300035, -54.686774435000018],
              [173.564731566000091, -54.696228264000013],
              [173.54882876600027, -54.708205088999932],
              [173.537557629000162, -54.716608045000044],
              [173.532823041000256, -54.720137539999939],
              [173.516714041000228, -54.73202654],
              [173.500503207000065, -54.743870413999957],
              [173.484190220000073, -54.755669317000013],
              [173.467775215000188, -54.767423189999931],
              [173.451258663000203, -54.779131625],
              [173.434640867000326, -54.790794342000027],
              [173.417922137000232, -54.802411063000022],
              [173.401102784000273, -54.813981507],
              [173.384183125000163, -54.825505398000018],
              [173.37928989500017, -54.82880510800004],
              [173.36716304000015, -54.836982540999941],
              [173.353260953000017, -54.846264674999986],
              [173.350044162000017, -54.84841241099997],
              [173.348984772000108, -54.849112732000037],
              [173.33282504000033, -54.859794541000042],
              [173.315508040000282, -54.871129540999959],
              [173.298091040000031, -54.882416540999941],
              [173.280795582500218, -54.89351529049992],
              [173.262799828000198, -54.905036097999982],
              [173.25015344600024, -54.913051190999965],
              [173.24492204000012, -54.916366540999945],
              [173.236286093000103, -54.921785503999956],
              [173.226944336000315, -54.927647097999966],
              [173.208866548000287, -54.938877109000032],
              [173.190689128000258, -54.950056480499939],
              [173.172412873500321, -54.96118464649993],
              [173.154037040000276, -54.972261540999966],
              [173.143719822000207, -54.978419589999987],
              [173.135563648000129, -54.983287378],
              [173.11699185000009, -54.994261115999961],
              [173.106640705000132, -55.000316544999976],
              [173.098322040000198, -55.005182541000011],
              [173.091247953000334, -55.009280128],
              [173.079555626000229, -55.016052071999979],
              [173.064271305000034, -55.024816308000027],
              [173.060692040000163, -55.026868541],
              [173.050782750000167, -55.032494257999986],
              [173.041731817000141, -55.037632516000031],
              [173.022675549000155, -55.048343120000027],
              [173.003523571000187, -55.059000285000025],
              [172.991899446000161, -55.065404092999984],
              [172.984276040000168, -55.069603541],
              [172.9737571200003, -55.075341034999951],
              [172.964934097000082, -55.080153227999972],
              [172.951797154000133, -55.087246796999978],
              [172.945497040000078, -55.09064854099995],
              [172.941574549000165, -55.092745556999944],
              [172.925966656000242, -55.101089216],
              [172.90634223200027, -55.111475190000021],
              [172.886624564000272, -55.121806136000032],
              [172.872608451000019, -55.129076262000012],
              [172.866814040000122, -55.132081541],
              [172.846911040000236, -55.142301540999938],
              [172.833051623000074, -55.149347351000017],
              [172.826916351000136, -55.15246619100003],
              [172.806829976000017, -55.162574419],
              [172.788752407000061, -55.171580208999956],
              [172.786652040000263, -55.172626540999943],
              [172.766384040000162, -55.182621540999946],
              [172.75777169700018, -55.186826181999947],
              [172.746026060000077, -55.192560147],
              [172.725577969000256, -55.202441556999986],
              [172.705040568000186, -55.21226563099998],
              [172.684414309000204, -55.222032118999948],
              [172.666562442000213, -55.230399022999961],
              [172.663700040000208, -55.231740541000022],
              [172.659507473000303, -55.233685571000024],
              [172.642897052000194, -55.241391339999893],
              [172.622007009500294, -55.250983560999941],
              [172.601029897000103, -55.260517246],
              [172.579966279000246, -55.269992094000031],
              [172.565089621000197, -55.27661514700003],
              [172.558817040000122, -55.279407540999955],
              [172.5426727790001, -55.286521019999952],
              [172.537581328000101, -55.288764367999931],
              [172.516260954000131, -55.298061312000016],
              [172.49485595800013, -55.307298477000018],
              [172.473366933000193, -55.316475582500004],
              [172.451794046000145, -55.325592531000041],
              [172.451016448000132, -55.325917709999942],
              [172.430138039000099, -55.334648540999979],
              [172.413419826000109, -55.341567165000015],
              [172.408399546000282, -55.343644618000042],
              [172.386578812000153, -55.352579354999989],
              [172.379005661000292, -55.355647548999968],
              [172.364676039000187, -55.361452540999949],
              [172.353862169000195, -55.365787828999942],
              [172.342692900000202, -55.370265055],
              [172.32062873700022, -55.379015563],
              [172.298484453000128, -55.387704206000024],
              [172.280062390000126, -55.394855022999934],
              [172.276261039000133, -55.396330541],
              [172.253958039000167, -55.404894540999919],
              [172.231576039000174, -55.41339654099994],
              [172.209116039000037, -55.421835541],
              [172.186579039000321, -55.430211540999949],
              [172.163965039000118, -55.438524541],
              [172.141275039000107, -55.446773541000027],
              [172.118508039000062, -55.454959540999937],
              [172.10440928200012, -55.459972898999979],
              [172.095666603000154, -55.46308156799995],
              [172.072749999000166, -55.471139467],
              [172.049759121000193, -55.479133113999978],
              [172.02669451900033, -55.487062301000037],
              [172.019718188000297, -55.489433553000026],
              [172.00355703900027, -55.494926541000041],
              [171.98034603900021, -55.502726540999959],
              [171.957064039000102, -55.510460541000043],
              [171.941633081000191, -55.515528428999971],
              [171.933709963000297, -55.518130332999874],
              [171.910285089000126, -55.525734148000026],
              [171.886789852000106, -55.533272286999932],
              [171.863224821000131, -55.540744552000035],
              [171.851571517000139, -55.544396314000039],
              [171.839591039000084, -55.548150541000041],
              [171.815888039000129, -55.55549054100004],
              [171.814631943000336, -55.555874909999943],
              [171.792116718000216, -55.562764155000025],
              [171.780570133000197, -55.566254914000012],
              [171.768278039000165, -55.569970540999975],
              [171.744373039000038, -55.577110540999968],
              [171.720401039000194, -55.584183541000016],
              [171.696364039000173, -55.591189540999949],
              [171.689855819000286, -55.59306318900002],
              [171.672261409000043, -55.598128077999945],
              [171.661201493000135, -55.601272459999961],
              [171.648094039000284, -55.604998540999986],
              [171.623863039000184, -55.611801541000034],
              [171.599569039000016, -55.618536541000026],
              [171.575212039000235, -55.625203541000019],
              [171.550793039000155, -55.631802540999956],
              [171.526312039000231, -55.638332540999954],
              [171.506835285000193, -55.643461052000021],
              [171.501770657000179, -55.644794527999977],
              [171.477168788000085, -55.651187322],
              [171.452507245000106, -55.657511140999986],
              [171.436161410000295, -55.661646879999978],
              [171.427787039000236, -55.663765541000032],
              [171.403008039000156, -55.669951540999932],
              [171.378147039000169, -55.676339541],
              [171.372476326000196, -55.677777186999947],
              [171.353226142000267, -55.682657241999948],
              [171.328245598000194, -55.688904494000042],
              [171.303206168000315, -55.695081178999942],
              [171.278108493000218, -55.70118713100004],
              [171.252953216000151, -55.707222184999978],
              [171.227740985000082, -55.713186180000037],
              [171.217021402000228, -55.715686051000034],
              [171.202472038000224, -55.719078540999988],
              [171.182717536000155, -55.723620110999946],
              [171.177148259000205, -55.724900348],
              [171.151769068000249, -55.730650204999961],
              [171.126335534000191, -55.736328371000013],
              [171.120581883000227, -55.737593978],
              [171.100848038000208, -55.741934540999978],
              [171.075308038000117, -55.747468540999989],
              [171.058657695000193, -55.75102267],
              [171.04971546600018, -55.752931192],
              [171.024071166000226, -55.758321076000016],
              [170.998375839000232, -55.763638519999972],
              [170.972630155000274, -55.768883380000034],
              [170.946834785000192, -55.774055514999986],
              [170.94179080400022, -55.775050725999961],
              [170.920990038000127, -55.779154540999969],
              [170.911700674000144, -55.780958096],
              [170.89509769100016, -55.784181047999937],
              [170.869157321000216, -55.789134172999979],
              [170.858713229000273, -55.791095342999938],
              [170.843170038000153, -55.794013541000027],
              [170.818207316000326, -55.798622737000017],
              [170.817136343000243, -55.798820465],
              [170.791057102000082, -55.803553370000031],
              [170.771191541000348, -55.80709638899998],
              [170.764933038000208, -55.808212540999932],
              [170.762800366000192, -55.808586296999977],
              [170.738764552000248, -55.81279805500003],
              [170.712552622000203, -55.817309583999986],
              [170.68629784600023, -55.821747072999983],
              [170.66000091900014, -55.826110401],
              [170.633662287500329, -55.8303994945],
              [170.607283399000153, -55.834614100999943],
              [170.580864205000125, -55.838754243000039],
              [170.564187002000267, -55.841316796999934],
              [170.554406038000138, -55.842819541999972],
              [170.527908038000191, -55.846810541999957],
              [170.507257388000198, -55.849858136999956],
              [170.501373318000105, -55.850726482000027],
              [170.474800939000147, -55.854567470999939],
              [170.448192033000169, -55.858333405],
              [170.421547310000165, -55.862024180999981],
              [170.405601101000116, -55.864185131999932],
              [170.394867038000257, -55.865639542],
              [170.368153038000059, -55.869179541999983],
              [170.341405038000289, -55.872644541999989],
              [170.314624038000034, -55.876033541999959],
              [170.301571320000221, -55.877646815],
              [170.287811424000211, -55.879347239999959],
              [170.278173884000239, -55.880509643999972],
              [170.260967037000228, -55.882584541999961],
              [170.234091037000098, -55.885746541999936],
              [170.220939722000253, -55.887255483999979],
              [170.207185910000135, -55.888833045999945],
              [170.180251069000292, -55.891843111999947],
              [170.153287601000216, -55.894777103999957],
              [170.138858606000213, -55.896304842],
              [170.126296037000202, -55.897634542],
              [170.101020918000131, -55.900237080000011],
              [170.099277693000204, -55.900416547999953],
              [170.096743193000293, -55.900670072999958],
              [170.072233037000274, -55.903121541999951],
              [170.045162037000182, -55.905750542000021],
              [170.033665357000132, -55.906833764999931],
              [170.018066336000288, -55.908303219999937],
              [169.99094641000022, -55.910779155],
              [169.963802970000074, -55.913178497999979],
              [169.936636749000172, -55.915501181999957],
              [169.909448484000336, -55.917747145000021],
              [169.882238912000162, -55.919916323999985],
              [169.863155349000294, -55.921382684999969],
              [169.855009037000173, -55.922008542000015],
              [169.850268378000237, -55.922359262999976],
              [169.827758800000311, -55.924024094999936],
              [169.80048988700014, -55.925962557499986],
              [169.773202325000284, -55.927824044000033],
              [169.74589730300022, -55.929608454],
              [169.739602422000303, -55.930001811000011],
              [169.718575037000164, -55.931315542],
              [169.691237037000178, -55.93294554199997],
              [169.663884037000344, -55.934498542000028],
              [169.636511980000137, -55.935974748333308],
              [169.609134037000331, -55.937372541999878],
              [169.596477304000132, -55.937983316999976],
              [169.581739007000238, -55.938694054],
              [169.562974160000294, -55.939545592],
              [169.554332037000336, -55.939937542],
              [169.544330726000339, -55.940363198999989],
              [169.526912436000089, -55.941104129999985],
              [169.499482421000266, -55.942193046999932],
              [169.4720422500003, -55.943204508999941],
              [169.444592672000226, -55.944138487999965],
              [169.440987483000185, -55.944250940000018],
              [169.417134037000324, -55.944994542000018],
              [169.389668037000206, -55.945773541999984],
              [169.373707690000174, -55.946181365999962],
              [169.362194982000233, -55.946475281999938],
              [169.334715266000103, -55.947099093999952],
              [169.307229891000134, -55.947645316999967],
              [169.29703237400031, -55.947819150000043],
              [169.279740037000238, -55.948113542000044],
              [169.252245037000307, -55.948504541999966],
              [169.235024828000178, -55.948701179000011],
              [169.224747312000204, -55.948818303999985],
              [169.215881052000213, -55.94889430500001],
              [169.197247036000135, -55.949053541999945],
              [169.182228880000253, -55.949140364000016],
              [169.169744392000212, -55.949212128],
              [169.143976270000167, -55.949287494000032],
              [169.142241036000314, -55.94929254199991],
              [169.138488377000272, -55.949292951000018],
              [169.114736856000263, -55.949295362000043],
              [169.087233235000213, -55.949220503],
              [169.085035651000112, -55.949208316999943],
              [169.05973103600013, -55.949067542000023],
              [169.032230036000215, -55.948837541999922],
              [169.021614580000232, -55.948719026],
              [169.004731979000212, -55.948530056999971],
              [168.98171791700031, -55.948207448],
              [168.977237036000133, -55.948144541999966],
              [168.949747036000076, -55.947681541999941],
              [168.92226103600035, -55.947140541999943],
              [168.894781036000154, -55.946522541999968],
              [168.86730703600017, -55.94582654200002],
              [168.839840156000264, -55.94505354849997],
              [168.812381036000033, -55.944202542000028],
              [168.805192899000303, -55.943959533],
              [168.784931219000327, -55.94327425299997],
              [168.757490347000044, -55.942268361999936],
              [168.749766260000229, -55.941963307000037],
              [168.730060036000168, -55.941184541999974],
              [168.712769314000298, -55.940453060000038],
              [168.702639657000304, -55.940024237999978],
              [168.675231334000159, -55.938786067000024],
              [168.647835356000201, -55.937470534],
              [168.643073400000162, -55.937228312999935],
              [168.620452036000188, -55.936077542000021],
              [168.598883787000318, -55.934919101999981],
              [168.593083419000322, -55.934607531999973],
              [168.565728950000334, -55.933060140999942],
              [168.539577529000212, -55.931506124999942],
              [168.538390036000123, -55.931435541999974],
              [168.511067036000213, -55.929733541999951],
              [168.483760036000149, -55.927954542],
              [168.456472036000264, -55.926098541999977],
              [168.443445017000158, -55.925175649000039],
              [168.429201367000132, -55.92416605],
              [168.401949998000305, -55.922156146999953],
              [168.374718406000284, -55.920069338999951],
              [168.356682111000168, -55.918635205000044],
              [168.347507036000138, -55.917905541999971],
              [168.340546689000234, -55.917332125999962],
              [168.320317509000347, -55.915665239999953],
              [168.293149679000294, -55.913348069999984],
              [168.266004576000228, -55.910954237],
              [168.252746696000145, -55.909746616],
              [168.238883036000061, -55.908483541999942],
              [168.211785036000265, -55.905936541999949],
              [168.192594588000134, -55.90407718299997],
              [168.184712972000284, -55.90331342799999],
              [168.168396807000249, -55.901684753999973],
              [168.157666036000137, -55.900613541999974],
              [168.130645842500257, -55.89783752],
              [168.103652303000217, -55.89498514],
              [168.081952213000136, -55.892628456999944],
              [168.076687035000191, -55.892056542000034],
              [168.049750035000216, -55.889051542],
              [168.028736994000155, -55.88664630300002],
              [168.022842258000196, -55.885971426999959],
              [168.016106168000306, -55.885180339999977],
              [167.995965035000154, -55.882814542],
              [167.969118035000179, -55.87958254199998],
              [167.956857470000273, -55.878070088000015],
              [167.942302424000218, -55.876274507999938],
              [167.934988745000169, -55.875350515999969],
              [167.915519035000216, -55.872890542],
              [167.89103783500002, -55.869724916],
              [167.888768941000194, -55.869431509000037],
              [167.88630626500003, -55.869105686000012],
              [167.862052035000147, -55.86589654200003],
              [167.835370035000324, -55.862286541999936],
              [167.824164425000049, -55.86073692299999],
              [167.808722681000177, -55.858601301000022],
              [167.795957370000224, -55.856797405],
              [167.782111035000071, -55.854840541999977],
              [167.772853486000145, -55.853504598000029],
              [167.755536147000271, -55.851005171999986],
              [167.731360888000268, -55.847442763999936],
              [167.728998035000018, -55.847094541999979],
              [167.726627141000336, -55.846738013],
              [167.70249835500033, -55.843109214000037],
              [167.676037005000126, -55.839049067000019],
              [167.649614957000267, -55.834914287999936],
              [167.64064005900002, -55.833482327999945],
              [167.623233035000226, -55.830704540999932],
              [167.604224942000201, -55.827613858999939],
              [167.596891580000261, -55.826421287000016],
              [167.570591648000345, -55.822063295999953],
              [167.544333818000268, -55.817631135999989],
              [167.532218670000248, -55.815548613999965],
              [167.521586157000229, -55.813720624999974],
              [167.519196741000201, -55.813309826000022],
              [167.518119035000268, -55.813124541000036],
              [167.49194703500018, -55.808544541],
              [167.465820035000206, -55.803890541000044],
              [167.453175373000136, -55.801598960000035],
              [167.439737383000192, -55.799163241999963],
              [167.413700446000234, -55.794362082999953],
              [167.388590333000138, -55.789652661],
              [167.387710035000282, -55.789487540999971],
              [167.361766035000187, -55.784539540999958],
              [167.335870035000198, -55.779518541000016],
              [167.310022035000088, -55.774424540999973],
              [167.284223035000309, -55.769257541],
              [167.282293022000204, -55.768864853000025],
              [167.276483240000175, -55.767682770000022],
              [167.258473744000298, -55.764018077999935],
              [167.2449067780002, -55.76121365299997],
              [167.232775035000145, -55.758705540999983],
              [167.2191574980003, -55.75584696300001],
              [167.207126782000159, -55.753321169],
              [167.181530438000209, -55.74786418399998],
              [167.155986407000341, -55.742335042999969],
              [167.138864335000335, -55.738572810999983],
              [167.130495035000195, -55.736733540999978],
              [167.105058035000184, -55.731060540999977],
              [167.091209712000023, -55.727926759999946],
              [167.079674816000193, -55.725316182999961],
              [167.069696867000175, -55.723024888],
              [167.054347034000216, -55.719499541],
              [167.038294646000168, -55.715760356999965],
              [167.029074089000119, -55.713612280999939],
              [167.003857788000346, -55.707653401999949],
              [166.978698395000265, -55.701623451999978],
              [166.965779013000315, -55.698483469],
              [166.953597034000154, -55.695522540999946],
              [166.928553034000174, -55.689350540999897],
              [166.903568034000159, -55.683108540999967],
              [166.891828653000289, -55.680135664000034],
              [166.878642798000214, -55.676796209999964],
              [166.853777595000338, -55.670413374999953],
              [166.828973143000212, -55.663960469000017],
              [166.817442241000208, -55.660920677000036],
              [166.80423003400017, -55.657437541000036],
              [166.798616774000038, -55.655938306999985],
              [166.779549013000178, -55.650845143000026],
              [166.754930590000299, -55.644183075],
              [166.730375231000266, -55.637451590999945],
              [166.705884034000292, -55.630650540999966],
              [166.689952550000157, -55.62617052500002],
              [166.681457365000256, -55.623781396000027],
              [166.673645256000214, -55.621556429999941],
              [166.657096034000119, -55.61684254100004],
              [166.639873791000241, -55.611875622000014],
              [166.632800034000184, -55.609835541],
              [166.617173916000297, -55.605272817999946],
              [166.60857015500028, -55.602760349],
              [166.584407496000267, -55.59561656599999],
              [166.569394503000126, -55.591123061999951],
              [166.560312034000162, -55.588404540999932],
              [166.53628503400023, -55.581124540999951],
              [166.513116765000291, -55.574019720999956],
              [166.512327122000215, -55.57377756249997],
              [166.488438034000183, -55.566362540999933],
              [166.481886656000256, -55.564304628999935],
              [166.464619405000235, -55.55888051300002],
              [166.440870996000172, -55.551331245999961],
              [166.417193700000212, -55.543715090999967],
              [166.393588101000319, -55.536032250999938],
              [166.384217600000284, -55.532946625000022],
              [166.370055034000302, -55.52828254100001],
              [166.355727376000232, -55.523509912],
              [166.346594311000104, -55.520467331999953],
              [166.326246138000215, -55.513609795000036],
              [166.323207034000234, -55.512585541000021],
              [166.321190419000345, -55.511898111999983],
              [166.299894237000217, -55.504638142999966],
              [166.288876998000205, -55.500839137999947],
              [166.276656034000325, -55.496624541000017],
              [166.262355041000205, -55.491637344999958],
              [166.253492442000265, -55.488546360999976],
              [166.234347162000176, -55.481793136999954],
              [166.230405034000114, -55.480402540999933],
              [166.207393235500149, -55.472193614499986],
              [166.184458878000328, -55.463920058999975],
              [166.173253949000127, -55.459832549999987],
              [166.161602034000225, -55.455581540999958],
              [166.149301948000129, -55.451044871999954],
              [166.138822413000184, -55.447179303000041],
              [166.124849275000315, -55.441967763999969],
              [166.121020371000213, -55.440539600000022],
              [166.116122034000227, -55.438712540999916],
              [166.093500034000158, -55.430181540999968],
              [166.070958034000228, -55.421587541],
              [166.048495034000211, -55.41292954100004],
              [166.038316814000268, -55.408963942000014],
              [166.026113837000139, -55.404209055999956],
              [166.003813440000044, -55.39542509499995],
              [165.981594723000285, -55.386578363999945],
              [165.959458207000125, -55.377669094999931],
              [165.937404415000287, -55.36869752299998],
              [165.932606518000171, -55.366724817000033],
              [165.915434033000338, -55.359663540999975],
              [165.914694131500255, -55.359358586999974],
              [165.913686188000156, -55.358960288999981],
              [165.892538371000228, -55.350603375],
              [165.881271718000335, -55.346103139000036],
              [165.870463033000192, -55.341785541000036],
              [165.848470033000126, -55.332906541],
              [165.826558033000083, -55.32396554099995],
              [165.822673489000181, -55.322363604999964],
              [165.804728663000105, -55.314963094999939],
              [165.782982360000346, -55.305899422999971],
              [165.763651342000344, -55.297756967999931],
              [165.761319033000177, -55.296774540999934],
              [165.7397410330002, -55.287589540999988],
              [165.7358974670002, -55.28593624399997],
              [165.718246208000323, -55.278343508999967],
              [165.696836729000211, -55.269037365000031],
              [165.67551268800014, -55.259671198999968],
              [165.654274572000304, -55.250245254999982],
              [165.649971720000281, -55.248315642000023],
              [165.633123033000174, -55.240759540999932],
              [165.612058033000039, -55.231214541000043],
              [165.596269176000078, -55.223986605999983],
              [165.591080580000153, -55.221611182999936],
              [165.576189902000351, -55.214723412999945],
              [165.575288413000067, -55.214306419000025],
              [165.570191033000015, -55.21194854099997],
              [165.549390033000236, -55.202227540999957],
              [165.528677033000235, -55.192447540999979],
              [165.508053905000224, -55.182610473499949],
              [165.487520157000176, -55.172715088999951],
              [165.467076686000041, -55.162762226999973],
              [165.446723817000134, -55.152752073999977],
              [165.436662795000075, -55.147753205000015],
              [165.426462033000348, -55.142684540999937],
              [165.406292033000227, -55.132560540999975],
              [165.391988313000297, -55.125308593],
              [165.386213292000207, -55.122380444000044],
              [165.368875648000085, -55.113500183999975],
              [165.3662270330002, -55.112143540999966],
              [165.356529957000134, -55.107126097],
              [165.346334033000289, -55.101850541000033],
              [165.330571833000135, -55.093612801],
              [165.326534140000263, -55.091502495000043],
              [165.316586737000307, -55.08625075599997],
              [165.306828033000215, -55.081098541000017],
              [165.287216033000163, -55.070639541],
              [165.283839910000211, -55.06882088399999],
              [165.267698019000136, -55.060125304999985],
              [165.248275293000347, -55.049556535999955],
              [165.228947918000244, -55.038933360000044],
              [165.20971630400004, -55.028256046999957],
              [165.198150618000227, -55.021769995999932],
              [165.190581033000171, -55.017524540999943],
              [165.181096486000229, -55.012152338999954],
              [165.171541987000296, -55.006740087999958],
              [165.161937088000286, -55.001244395000015],
              [165.152600033000112, -54.995901540999967],
              [165.133756033000282, -54.985010541],
              [165.115009033000206, -54.974066541000013],
              [165.100400117000163, -54.965452693999964],
              [165.096360178000282, -54.963070484999946],
              [165.089708072000349, -54.959108409999942],
              [165.077810033000219, -54.952021540999965],
              [165.068443365000348, -54.946386614999938],
              [165.05935894900017, -54.940921243999981],
              [165.052463414000158, -54.936730888999932],
              [165.041007033000028, -54.929768540999987],
              [165.027095048000319, -54.921229423999932],
              [165.022754918000146, -54.918565342999955],
              [165.015867159000209, -54.914294771000016],
              [165.004603033000308, -54.907310540999958],
              [164.986551033000183, -54.896004540999968],
              [164.968600033000143, -54.884648540999933],
              [164.95759809100025, -54.877618391999931],
              [164.950750352000227, -54.873242554],
              [164.933002062000241, -54.861786220999988],
              [164.915355626000178, -54.850280204],
              [164.89781121350029, -54.838724665499967],
              [164.891986530000167, -54.834849324],
              [164.880369715000256, -54.827120268999977],
              [164.878412835000177, -54.825805056000036],
              [164.863031032000322, -54.815466541000035],
              [164.845794296000236, -54.80376404499998],
              [164.842784602000279, -54.80173741599998],
              [164.828632032000201, -54.792207540999982],
              [164.814634223000326, -54.782686699999942],
              [164.811570032000191, -54.780602541],
              [164.794610032000264, -54.768949539999937],
              [164.777752032000194, -54.757250539999973],
              [164.769023177000349, -54.751130704],
              [164.760997032000319, -54.745503539999959],
              [164.744344032000186, -54.733711539999945],
              [164.73702325000022, -54.72847419499999],
              [164.72779403200002, -54.72187154],
              [164.726018227000282, -54.720588300000031],
              [164.711104456000271, -54.709809465499973],
              [164.709229797000262, -54.708441009000012],
              [164.707222374000025, -54.706975639],
              [164.705916877000277, -54.706022655999959],
              [164.704618899500247, -54.705075164],
              [164.702955363000228, -54.703860821999967],
              [164.702082784000027, -54.70322386],
              [164.700225696000189, -54.701868230999935],
              [164.69796323900016, -54.70021669099998],
              [164.694900252500361, -54.697980007000012],
              [164.692908411500298, -54.696511130999966],
              [164.691234608666946, -54.69526500533334],
              [164.68053804800013, -54.675460830999953],
              [164.677839913000298, -54.670459198999936],
              [164.661485335000179, -54.639755641999955],
              [164.650285471000188, -54.618729378000012],
              [164.444850507000183, -54.233052398999973],
              [164.43896058200005, -54.221761275999938],
              [164.271373471000175, -53.900492868000015],
              [164.245011723000175, -53.849956782],
              [164.122632922000207, -53.764358683000012],
              [164.05371884800013, -53.716156596000019],
              [163.647106307000286, -53.425606975],
              [163.428386069000226, -53.269317939000018],
              [163.073463159000198, -53.015703731999963],
              [163.070900190000316, -53.013872332999938],
              [162.922706276000213, -52.907978684],
              [162.764835443000038, -52.795170282999948],
              [162.745212989000265, -52.780729095000027],
              [162.636161843000309, -52.700180643999943],
              [162.608411218000242, -52.679683198999953],
              [162.391802801000267, -52.518125550999969],
              [162.316523948000167, -52.461978727999906],
              [162.055697539000221, -52.267498567999951],
              [162.052063479000253, -52.264788900999918],
              [161.992023409000126, -52.219843823999952],
              [161.953098063000311, -52.190704905000018],
              [161.736760646000221, -52.026295273999949],
              [161.635761877000277, -51.949539386999902],
              [161.516408620000334, -51.85883466599995],
              [161.331147398000326, -51.718041965999987],
              [161.079038089000164, -51.526446821999969],
              [160.963020240000333, -51.438276906999931],
              [160.942384178000339, -51.422309351000024],
              [160.832256114000302, -51.337095612000041],
              [160.7523195240002, -51.275243114000034],
              [160.739441872000157, -51.265278778999971],
              [160.729836463000083, -51.257846406999988],
              [160.705440294000141, -51.238969394000037],
              [160.69451817800018, -51.230518193999956],
              [160.678552223000338, -51.21816422400002],
              [160.669336930000213, -51.21103371199996],
              [160.65875646800032, -51.202846873],
              [160.641389276000268, -51.189408668999931],
              [160.633584914000039, -51.183369891000041],
              [160.62511076842884, -51.1768046235714],
              [160.624880583000277, -51.175434697999947],
              [160.624772410000219, -51.174519023499897],
              [160.624529608000216, -51.172463712999956],
              [160.624021032000087, -51.168158651],
              [160.623410028000194, -51.162986537],
              [160.622069783000029, -51.150875948000021],
              [160.621720028000141, -51.147715536999897],
              [160.62100546500028, -51.140798579000034],
              [160.620142028000146, -51.132440536999979],
              [160.618677028000207, -51.117160536999961],
              [160.617325028000096, -51.101876536999953],
              [160.617011377000154, -51.098012634999975],
              [160.616084028000273, -51.086588536999955],
              [160.614956028000165, -51.071297536999964],
              [160.614860299000156, -51.069854995000014],
              [160.613941028000227, -51.05600253700004],
              [160.613037028000235, -51.040705537],
              [160.612245028000075, -51.025405536999983],
              [160.611565028000314, -51.01010353700002],
              [160.610997028000156, -50.994800536999946],
              [160.610541041500255, -50.979494981999892],
              [160.610427915000287, -50.974476239999944],
              [160.610196236000206, -50.964188399999955],
              [160.609963038500155, -50.948880777499994],
              [160.609841266000331, -50.933573080000031],
              [160.609836120000068, -50.926059839999937],
              [160.609831028000144, -50.918264537000027],
              [160.609865669000101, -50.91296181599995],
              [160.60993148700004, -50.90295683],
              [160.610143270000208, -50.887649163999981],
              [160.610443052000164, -50.873431384000014],
              [160.610466028000133, -50.872342536999952],
              [160.610900028000174, -50.857036537],
              [160.611444028000051, -50.84173153699993],
              [160.612099028000216, -50.826429537000024],
              [160.612412564000124, -50.82015840899993],
              [160.612864298000318, -50.811128296999982],
              [160.613740089000203, -50.795829687000037],
              [160.613908500000207, -50.793112246999954],
              [160.614693028000147, -50.780458536999959],
              [160.615757028000189, -50.765089536999938],
              [160.616932028000292, -50.749723536999966],
              [160.617585681000264, -50.741909723],
              [160.618217483000308, -50.734362049999959],
              [160.619614226000181, -50.719003968000038],
              [160.620162804000074, -50.713416434999942],
              [160.621122028000173, -50.70365053699993],
              [160.62274002800018, -50.688300536999975],
              [160.624468028000138, -50.672956537],
              [160.626307029000145, -50.657616536999967],
              [160.627243269000132, -50.650246768999978],
              [160.62825537100025, -50.64228294000003],
              [160.63031407800031, -50.62695486399997],
              [160.630754455000215, -50.623843326000042],
              [160.632483029000127, -50.611632537],
              [160.634761029000288, -50.596317537],
              [160.635629434000293, -50.59074802799995],
              [160.637148473000138, -50.581008671999939],
              [160.639645516000201, -50.565707019],
              [160.641491289000214, -50.554875421999952],
              [160.642252029000133, -50.550412537],
              [160.644967029000213, -50.535126537],
              [160.645621179000159, -50.531587316000014],
              [160.647791172000211, -50.519847757999969],
              [160.650724026000262, -50.504577630000028],
              [160.653765424000198, -50.489316177000035],
              [160.656915198000206, -50.474063714999943],
              [160.660173179000026, -50.458820561000039],
              [160.663539195000226, -50.44358703],
              [160.667090836500165, -50.428033127999981],
              [160.67059502900031, -50.413150535999932],
              [160.674284029000319, -50.397947535999947],
              [160.677456563000305, -50.385250710999969],
              [160.678080126000282, -50.382755433],
              [160.678845053000259, -50.379780681],
              [160.681984029000205, -50.367574536],
              [160.684406155000147, -50.358412135000037],
              [160.685994217000257, -50.352405541],
              [160.686986793000301, -50.348751499],
              [160.690112029000261, -50.337248536],
              [160.692364622000014, -50.329169498],
              [160.694335446000196, -50.322102920999953],
              [160.696862066000335, -50.313273367999955],
              [160.698666029000151, -50.306970536],
              [160.701830448000237, -50.296184687999983],
              [160.703102320000085, -50.291850062999913],
              [160.704722918000243, -50.286460534999947],
              [160.707645029000219, -50.276743536000026],
              [160.710018170000126, -50.269036953000011],
              [160.712293317000217, -50.261649446999968],
              [160.717047379000292, -50.246569502999989],
              [160.720499713000095, -50.235866213],
              [160.721907029000135, -50.231503536],
              [160.726872029000106, -50.216451535999965],
              [160.728596698000274, -50.21133536799988],
              [160.731941451000125, -50.201414823],
              [160.737116106000144, -50.186392669999975],
              [160.742395405000252, -50.171385731000044],
              [160.747779144000219, -50.156394311999975],
              [160.753267116000302, -50.141418717000022],
              [160.758147316000304, -50.128363418999982],
              [160.758859029000178, -50.12645953599997],
              [160.764555029000348, -50.111516536000011],
              [160.766471910000178, -50.106582358999958],
              [160.770354342000132, -50.096589914999868],
              [160.77625715000022, -50.081680650999886],
              [160.782263133000015, -50.06678872699996],
              [160.78837205300033, -50.051914488999955],
              [160.794584029000134, -50.037058535999961],
              [160.799665439000137, -50.025116336999986],
              [160.80089801700035, -50.022219821999954],
              [160.802893648000349, -50.017441442999939],
              [160.807099029000256, -50.007372535999934],
              [160.809908194000059, -50.000762996999981],
              [160.813402309000253, -49.992542549],
              [160.816605824000135, -49.985135197000034],
              [160.819808029000228, -49.977731535999965],
              [160.82631502900017, -49.962939535999851],
              [160.827049203000342, -49.96129833599997],
              [160.832924214000172, -49.948165942],
              [160.835824677000232, -49.941788949999932],
              [160.839635029000192, -49.933412536],
              [160.844494255000086, -49.922900739999932],
              [160.84644645300034, -49.918678043],
              [160.853359184000169, -49.903963933999933],
              [160.856072918000223, -49.898278452],
              [160.860373029000129, -49.88927053599997],
              [160.867487029000245, -49.874596535999956],
              [160.873893548000183, -49.86158457],
              [160.874701063000089, -49.859944503],
              [160.882015477000209, -49.845313304],
              [160.885977707000166, -49.837505750999867],
              [160.889430029000209, -49.830703535999987],
              [160.896944029000338, -49.816115535999927],
              [160.904557029000216, -49.801549535999982],
              [160.90740075500014, -49.796186574999979],
              [160.91226911400031, -49.787006010999846],
              [160.920080323000093, -49.772485016000019],
              [160.927990169000168, -49.757986931],
              [160.935997942000199, -49.743511756000032],
              [160.942961175000164, -49.72881337799987],
              [160.944092134000243, -49.726463257999967],
              [160.950025029000244, -49.714135536000015],
              [160.955313140000158, -49.703314950999982],
              [160.957188372000132, -49.699478102],
              [160.963253881000242, -49.687255811],
              [160.964452029000199, -49.684841535999965],
              [160.97181502900014, -49.67022753599997],
              [160.979277029000286, -49.655634536000036],
              [160.98683902900018, -49.641062535999936],
              [160.994499029000167, -49.626514536],
              [161.002258029000132, -49.611987536000015],
              [161.010115029000303, -49.597484536000024],
              [161.010866594000163, -49.596116413],
              [161.018070505000196, -49.58300397499994],
              [161.026123838000331, -49.56854703099998],
              [161.030833791000191, -49.560206944999948],
              [161.034275029000128, -49.554113535999988],
              [161.036447500000236, -49.550318295999944],
              [161.04252321000024, -49.539704526999913],
              [161.045780514000285, -49.534089998999946],
              [161.050869029000182, -49.52531953600004],
              [161.059311029000241, -49.510959535999973],
              [161.061394689000167, -49.50746131],
              [161.067850308000061, -49.496623506999981],
              [161.076485817000162, -49.482313033999958],
              [161.085217477000242, -49.468027965999966],
              [161.094045033000157, -49.453768585000034],
              [161.098940131000177, -49.445960400999923],
              [161.102968029000181, -49.439535535999937],
              [161.105590976000258, -49.4354035],
              [161.111986807000221, -49.425328006999962],
              [161.121100509000257, -49.411147368999934],
              [161.130309075000241, -49.396993538999936],
              [161.139612245000308, -49.382866794],
              [161.144504487000063, -49.375526806999915],
              [161.149010029000351, -49.368767534999847],
              [161.153496344000018, -49.362115820999975],
              [161.158501341000147, -49.354695665999941],
              [161.166440494000199, -49.343063796999957],
              [161.168087029000162, -49.340651534999836],
              [161.177766029000168, -49.326636535000034],
              [161.184870656000157, -49.316466711000032],
              [161.187537907000319, -49.312649022000016],
              [161.197403140000233, -49.298690583999985],
              [161.201747557000317, -49.292613520000032],
              [161.207361029000026, -49.284761534999973],
              [161.217412029000343, -49.270860534999883],
              [161.223744854000188, -49.262199888],
              [161.227554190000205, -49.256990421000012],
              [161.230473832000257, -49.253042025999889],
              [161.237789029000169, -49.243149534999986],
              [161.242140214000187, -49.237329835999958],
              [161.248114958000201, -49.229338979999966],
              [161.252335257000141, -49.223756395],
              [161.258533029000205, -49.215558535000014],
              [161.26296376200014, -49.209760803],
              [161.269041214000254, -49.201808990000018],
              [161.274156016000347, -49.195188970999936],
              [161.279641029000231, -49.188090534999958],
              [161.290331029000072, -49.174402535000013],
              [161.30111102900014, -49.160746534999987],
              [161.30290987300009, -49.158491774999902],
              [161.311981288000197, -49.14712190900002],
              [161.322941245000152, -49.133529374999966],
              [161.324000671000249, -49.132229195999855],
              [161.333991029000117, -49.119969535],
              [161.345129029000304, -49.106441534999874],
              [161.35289248100014, -49.097109764999914],
              [161.356356427000037, -49.092946152999957],
              [161.36101555200014, -49.087403365999933],
              [161.367672029000346, -49.079484535],
              [161.371933791000174, -49.074466015999974],
              [161.379076446000226, -49.066055267999957],
              [161.387034658000175, -49.056779123999945],
              [161.390569029000176, -49.052659534999975],
              [161.393103100000189, -49.049735472999885],
              [161.402148356000225, -49.039298484999961],
              [161.411926511000161, -49.028128873999947],
              [161.413816029000031, -49.025970535],
              [161.417934114000332, -49.021313253000017],
              [161.425569830000313, -49.01267786599999],
              [161.428096890000234, -49.009848288],
              [161.437411029000259, -48.999419534999959],
              [161.442850275000183, -48.993388798999945],
              [161.449338524000268, -48.98619545899993],
              [161.461352341000179, -48.973006722000036],
              [161.470311282000239, -48.963267605999931],
              [161.473452029000327, -48.959853534999979],
              [161.477715547000116, -48.955263562000027],
              [161.4856374310001, -48.946735445999934],
              [161.496639419000189, -48.935005987999943],
              [161.4979080290002, -48.933653534999962],
              [161.510264029000268, -48.920607534999931],
              [161.515306171000134, -48.915334363],
              [161.522704228000066, -48.907597798999973],
              [161.535229068000035, -48.894624720999957],
              [161.544492403000277, -48.885120927],
              [161.547838029000332, -48.881688534999967],
              [161.560531029000231, -48.868789535000019],
              [161.573307029000148, -48.855927535000035],
              [161.583906043000184, -48.845357367999952],
              [161.586166556000194, -48.843103033000034],
              [161.588581209000267, -48.840717456],
              [161.599109029000033, -48.830316534999952],
              [161.603460908000329, -48.82605687299997],
              [161.612133971666822, -48.817567678666705],
              [161.607643029000201, -48.802413534999971],
              [161.605813418000139, -48.796082320999915],
              [161.603260464000073, -48.787246146999962],
              [161.598987074000206, -48.772064701499914],
              [161.594823029000167, -48.756870535000019],
              [161.592801018000273, -48.749289487999931],
              [161.590767270000242, -48.74166332599998],
              [161.588894955000114, -48.734442814000033],
              [161.586821029000134, -48.72644353499993],
              [161.584677686000305, -48.717937734999964],
              [161.582983164000325, -48.711212048999982],
              [161.579254482000124, -48.695968602999983],
              [161.577329155000257, -48.687854702999914],
              [161.575635029000296, -48.680713534999938],
              [161.572124029000179, -48.665447535],
              [161.568722029000241, -48.650171535000027],
              [161.567477371000109, -48.644394867000017],
              [161.565428482000129, -48.634883978],
              [161.562525611000183, -48.620939345999943],
              [161.56224402900034, -48.619586534999939],
              [161.56192948100022, -48.61802125600002],
              [161.559168389000206, -48.604279879999979],
              [161.556201470000133, -48.588963562999936],
              [161.553343259000258, -48.573638184],
              [161.551800596000248, -48.565034795000031],
              [161.550594029000308, -48.558304534999934],
              [161.547953029000269, -48.542961535000018],
              [161.547215721000157, -48.538493438999978],
              [161.54542049700035, -48.527611038000032],
              [161.543922225000188, -48.518117196],
              [161.542997029000162, -48.512252534999959],
              [161.542064259000085, -48.506064273999982],
              [161.540681466000137, -48.496887129999948],
              [161.539402522000273, -48.487977971000021],
              [161.538475029000324, -48.481514534999974],
              [161.537312640000323, -48.472997914000018],
              [161.536376252000309, -48.466135042999952],
              [161.534386198000107, -48.450749272999985],
              [161.532504441000128, -48.435357463999935],
              [161.531208101000175, -48.424102765999976],
              [161.530731029000322, -48.419959534999975],
              [161.529066029000205, -48.404557534999981],
              [161.528257766000024, -48.396564128000023],
              [161.527508362000162, -48.389149165],
              [161.52605919400014, -48.373736556999972],
              [161.524882941000328, -48.360215462999975],
              [161.524718029000155, -48.358319534999964],
              [161.523485029000085, -48.342898534999961],
              [161.522907284000212, -48.334984044],
              [161.522359328000334, -48.32747389299999],
              [161.521922989000132, -48.320858749000024],
              [161.521342029000266, -48.312045534],
              [161.520608990000255, -48.29961528900003],
              [161.52043202900029, -48.296614534000014],
              [161.520059957000171, -48.289463141],
              [161.519629407000139, -48.281180982999956],
              [161.519312153000328, -48.274132551000037],
              [161.518935029000204, -48.265744534000021],
              [161.518566411000279, -48.256066420999957],
              [161.518347287000211, -48.250306685999988],
              [161.517867328000307, -48.23486683],
              [161.517704365000213, -48.228114633000018],
              [161.517495029000088, -48.219425533999981],
              [161.517307098000288, -48.208515630999955],
              [161.517229182000165, -48.203983362000031],
              [161.517162822000046, -48.197512367],
              [161.517071029000135, -48.188540534000026],
              [161.517053729000168, -48.183402695999973],
              [161.517019459000153, -48.173097102000042],
              [161.517075026000271, -48.157653643999936],
              [161.517237406000163, -48.142210700999954],
              [161.517506511000192, -48.12676827599995],
              [161.517882236000219, -48.111326806999955],
              [161.518364476000215, -48.095886624999935],
              [161.51895312500028, -48.080448059999981],
              [161.519648077000056, -48.065011441999957],
              [161.52044922000016, -48.049577099000032],
              [161.521356443000172, -48.034145359999968],
              [161.521866321000289, -48.026380956000025],
              [161.522370029000172, -48.018716533999964],
              [161.523489029000075, -48.003290533999973],
              [161.524060570000188, -47.996089772],
              [161.524713437000173, -47.987869053],
              [161.525618436000173, -47.977380831999938],
              [161.526044029000218, -47.972450534000018],
              [161.52748002900023, -47.957037534],
              [161.529021029000205, -47.94162753400002],
              [161.529271858000129, -47.93928017099995],
              [161.530667384000253, -47.926223652999965],
              [161.532154598000034, -47.913148723999939],
              [161.532419029000152, -47.910824534000028],
              [161.532617807000292, -47.90917583800001],
              [161.534275521000154, -47.895430977999979],
              [161.53584942700013, -47.883083305000014],
              [161.536237029000176, -47.880043534000038],
              [161.537764694000202, -47.868669601999954],
              [161.538303043000269, -47.864661791],
              [161.53866153100023, -47.862122631999966],
              [161.540474029000166, -47.849286533999987],
              [161.54274902900022, -47.833918533999963],
              [161.543486907000215, -47.829154119],
              [161.545128301000318, -47.818557726],
              [161.546668720000298, -47.809034897999936],
              [161.547612029000106, -47.803204534000031],
              [161.548348169000292, -47.798837779999928],
              [161.550199489000306, -47.787858599999936],
              [161.552233794000131, -47.776265967999962],
              [161.552891029000079, -47.772521533999964],
              [161.55410759900019, -47.765848897999945],
              [161.55568614300023, -47.757192015999919],
              [161.557685954000249, -47.746622772000023],
              [161.558585029000142, -47.741871533999984],
              [161.56055125700027, -47.731843250999987],
              [161.561587075000148, -47.726560545000041],
              [161.56469251600015, -47.71125878],
              [161.567901066000218, -47.695966752999936],
              [161.568470543000217, -47.69333872499999],
              [161.571213029000319, -47.680684533999965],
              [161.574627029000339, -47.665413533999981],
              [161.578144029000214, -47.650152533999972],
              [161.580520957000203, -47.640136465999944],
              [161.581763205000129, -47.634902419000014],
              [161.584829594000098, -47.622347088000026],
              [161.58548502900021, -47.619663533999926],
              [161.589309029000219, -47.604436534],
              [161.593235029000198, -47.589221533999932],
              [161.595898405000298, -47.57916724],
              [161.597262227000215, -47.574019372],
              [161.6013914910003, -47.558829308999975],
              [161.605622214000277, -47.543652157],
              [161.609954223000159, -47.528488229999937],
              [161.614387341000111, -47.513337839999906],
              [161.618921389000093, -47.498201301999984],
              [161.62355618800018, -47.483078927999941],
              [161.625783054000181, -47.475974250999968],
              [161.628292029000249, -47.467970533999903],
              [161.630410062000237, -47.461358854999958],
              [161.633127309000088, -47.45287792],
              [161.638063262000173, -47.437799908999978],
              [161.643099230000303, -47.422737305999988],
              [161.646867972000223, -47.411695617999953],
              [161.648235029000261, -47.407690533999983],
              [161.648730385000022, -47.406268241999953],
              [161.653470452000306, -47.39265956600002],
              [161.658805325000344, -47.37764504699993],
              [161.664239449000206, -47.362647173000028],
              [161.666240276000281, -47.357229991999986],
              [161.669773029000339, -47.347666533999984],
              [161.675405029000331, -47.332702533999964],
              [161.678361880000125, -47.324989953],
              [161.681135366000262, -47.317756492999933],
              [161.686964527000214, -47.302828267],
              [161.690835869000239, -47.293090151],
              [161.692892029000177, -47.287918534000028],
              [161.694828795000035, -47.28313142799999],
              [161.698917426000207, -47.27302665000002],
              [161.70360456300034, -47.261642194999979],
              [161.705041030000075, -47.258153533999973],
              [161.711262030000171, -47.243300533000024],
              [161.715254202000096, -47.233926700999973],
              [161.717580150000344, -47.228465865999965],
              [161.720325737000053, -47.222125927000036],
              [161.72399603000008, -47.213651533000018],
              [161.726773113000263, -47.207342110999946],
              [161.730508463000206, -47.19885663499997],
              [161.735219026000209, -47.188326994000022],
              [161.737118030000261, -47.184082533000037],
              [161.743824015500309, -47.169328556999972],
              [161.750626447000172, -47.154595744999938],
              [161.75575102300013, -47.143667314999959],
              [161.757525030000124, -47.139884533000043],
              [161.764520030000199, -47.125193532999958],
              [161.771610030000261, -47.110525533000043],
              [161.778796030000308, -47.095879532999959],
              [161.786077030000342, -47.081255532999933],
              [161.791619897000032, -47.070282520000035],
              [161.793452909000166, -47.066654025],
              [161.795737696000174, -47.062183752999942],
              [161.800269030000209, -47.053318532999981],
              [161.804282112000351, -47.045567706999975],
              [161.807163397000181, -47.040003179],
              [161.814137233000224, -47.026706996999962],
              [161.820022966000124, -47.015627319999965],
              [161.821190030000025, -47.013430532999962],
              [161.82632889100023, -47.00387777100002],
              [161.828321135000238, -47.000174401000038],
              [161.835530154500134, -46.98693832049986],
              [161.831233166000175, -46.975875497999937],
              [161.829812030000198, -46.972216532999937],
              [161.824195030000169, -46.95747653299992],
              [161.818679030000226, -46.942718533],
              [161.813265030000139, -46.927943532999905],
              [161.807952030000138, -46.913150533000021],
              [161.802741030000334, -46.89834153299995],
              [161.797631002000344, -46.883516447999966],
              [161.792623030000186, -46.868674532999933],
              [161.791121537000208, -46.864128439999945],
              [161.787716478000107, -46.853817483],
              [161.785144048000234, -46.845854776999943],
              [161.782912030000148, -46.83894453299996],
              [161.778209030000198, -46.824056532999975],
              [161.774801757000091, -46.813023249000025],
              [161.7736070750002, -46.809154038999964],
              [161.769107302000293, -46.794236816000023],
              [161.7647092320002, -46.779305425999951],
              [161.762670361000346, -46.772212902000035],
              [161.760413030000223, -46.764359532999947],
              [161.756144030000087, -46.749823532999983],
              [161.755483371000196, -46.747520015000021],
              [161.751971252000203, -46.735273464999977],
              [161.749653987000215, -46.726995052999939],
              [161.747895030000223, -46.720710532999931],
              [161.743915030000267, -46.706135532999951],
              [161.742933343000118, -46.702448649999894],
              [161.740031286000317, -46.691548186000034],
              [161.737224077000064, -46.680726905999961],
              [161.736244030000307, -46.676948532999972],
              [161.73255303000019, -46.662337533000027],
              [161.73200067200014, -46.660090920999949],
              [161.728958494000153, -46.647715812999934],
              [161.725460259000215, -46.633082613999932],
              [161.722058362000325, -46.618438706999939],
              [161.720111039000244, -46.609805793000021],
              [161.718753030000244, -46.60378453300001],
              [161.715544030000274, -46.58911953300003],
              [161.712431030000289, -46.57444553299996],
              [161.709414030000062, -46.559761533000028],
              [161.706494030000044, -46.545068532999949],
              [161.704947118000291, -46.537018571999965],
              [161.703669414000274, -46.530367089000038],
              [161.700941512000213, -46.51565656899993],
              [161.699207645000342, -46.505959198000028],
              [161.69831002900014, -46.500937532999956],
              [161.696979120000265, -46.49320870400004],
              [161.695774306000203, -46.486210849000031],
              [161.693620280000346, -46.47319972799994],
              [161.693335029000082, -46.47147653299993],
              [161.690992029000199, -46.456734532999974],
              [161.690027042000224, -46.450402874999952],
              [161.688744418000169, -46.441984859999877],
              [161.687948342000141, -46.43672570800004],
              [161.686779029000149, -46.428997532999972],
              [161.685611873000198, -46.420982892999973],
              [161.684887267000136, -46.416005043000041],
              [161.683298157000308, -46.404639457999963],
              [161.683070029000191, -46.403007532999936],
              [161.681158416000244, -46.391816474],
              [161.680455995500324, -46.387704294499997],
              [161.677946029000282, -46.372392533000038],
              [161.67553902900022, -46.35707253299995],
              [161.674569054000131, -46.350619921000032],
              [161.673235514000339, -46.341745788999958],
              [161.67178101900015, -46.331606472999908],
              [161.671036029000334, -46.326411532999941],
              [161.670294062000153, -46.32098317599997],
              [161.668939507000232, -46.311069944],
              [161.667922557000111, -46.303237797999941],
              [161.666947029000198, -46.295721532999949],
              [161.665659028000164, -46.285258054],
              [161.665057228000137, -46.280367609999928],
              [161.663271125000165, -46.265007340000011],
              [161.66158831600012, -46.249641446999931],
              [161.660732213000074, -46.241310465999959],
              [161.660009029000321, -46.234270533000029],
              [161.659429791000321, -46.228240486],
              [161.658532379000349, -46.218894115000012],
              [161.657159143000172, -46.203513340999962],
              [161.656065470000044, -46.190265966999903],
              [161.65588902900015, -46.188128532999883],
              [161.654722029000141, -46.17273953199998],
              [161.653658029000212, -46.15734653200002],
              [161.6531813590002, -46.149717827000018],
              [161.652696396000238, -46.141950600999962],
              [161.652008108000246, -46.129604025999981],
              [161.651838029000146, -46.126551532],
              [161.651641914000152, -46.12255632900002],
              [161.651082261000283, -46.111150083999974],
              [161.650429269000256, -46.095746196],
              [161.650060877000328, -46.085434235000037],
              [161.649879029000203, -46.080340532000037],
              [161.649531485000239, -46.06838747300003],
              [161.649431074000177, -46.064932815999953],
              [161.649393995000196, -46.063278421999939],
              [161.649086029000159, -46.049523531999967],
              [161.648843029000318, -46.034114531999933],
              [161.648736819000192, -46.02250591],
              [161.648702117000198, -46.018703683000012],
              [161.648694057000284, -46.015470119999975],
              [161.648664029000201, -46.003292531999932],
              [161.648692220000157, -45.996397082999977],
              [161.648727434000193, -45.98788206200004],
              [161.648893226000268, -45.972471577999954],
              [161.649119050000309, -45.95947537099994],
              [161.649161029000311, -45.957061531999955],
              [161.649531029000173, -45.941652532000035],
              [161.649964934000309, -45.927458974999979],
              [161.650002067000287, -45.926245378000033],
              [161.650575195000215, -45.910839486000043],
              [161.650982814000145, -45.901533585000038],
              [161.651250029000153, -45.895435531999965],
              [161.651590745000135, -45.888673034999954],
              [161.652026146000139, -45.8800339309999],
              [161.652350701000273, -45.874339198999962],
              [161.652904029000183, -45.864634532000025],
              [161.653883029000212, -45.849238531999958],
              [161.654963029000214, -45.833846532],
              [161.656144015500331, -45.818456702],
              [161.657426177000275, -45.803071601999989],
              [161.658809220000194, -45.787690579999946],
              [161.659990006000044, -45.775454216000014],
              [161.660293029000172, -45.772314531999939],
              [161.660465460000296, -45.770641167999941],
              [161.66187745600007, -45.756942579999929],
              [161.663562420000176, -45.741576250000037],
              [161.664617897000198, -45.732495243999949],
              [161.665348029000256, -45.726215531999969],
              [161.666047422000133, -45.720518357999936],
              [161.667233448000161, -45.710860544999946],
              [161.66921929300031, -45.695511636],
              [161.670370751000178, -45.684742613999958],
              [161.67085202900023, -45.680242531999966],
              [161.672584029000177, -45.664978531999964],
              [161.673933246000161, -45.653729165000016],
              [161.674414201000218, -45.649720362999972],
              [161.674881000000255, -45.646030254000038],
              [161.676344029000234, -45.634468531999971],
              [161.678372029000343, -45.619222531999981],
              [161.679416743000274, -45.611733618000017],
              [161.680498387000057, -45.603982723],
              [161.682723497000325, -45.588750276999974],
              [161.684371822000344, -45.577948958000036],
              [161.685047029000202, -45.573525531999962],
              [161.687469029000226, -45.558307532000015],
              [161.689259095000125, -45.547498917999974],
              [161.689988216000188, -45.543097651999972],
              [161.691080305000241, -45.536755408999966],
              [161.692606029000331, -45.527896532],
              [161.69401236200008, -45.520026248000022],
              [161.695321223000292, -45.512702972],
              [161.698134286000254, -45.497518692999961],
              [161.701045155000315, -45.482343384999979],
              [161.703361131000293, -45.467051973999958],
              [161.705775995000181, -45.451767963999941],
              [161.705994054000257, -45.450442736000014],
              [161.708290030000171, -45.436491531999934],
              [161.710902030000284, -45.421223532],
              [161.711646740000219, -45.417030345],
              [161.713612452000348, -45.405964605999941],
              [161.71642144700013, -45.390714102999937],
              [161.717848715000287, -45.383231454999986],
              [161.719329030000154, -45.375472531999947],
              [161.722334030000184, -45.360240531999978],
              [161.723773867000318, -45.353177302],
              [161.725437039000212, -45.345018875999983],
              [161.728637970000136, -45.329806948],
              [161.731936528000347, -45.314605451000034],
              [161.733739591000244, -45.306540208],
              [161.73533303000022, -45.299414532000014],
              [161.738742565500161, -45.284597298999984],
              [161.742416498000125, -45.269066698999893],
              [161.745755567000174, -45.25534255499997],
              [161.746104030000197, -45.253910531999963],
              [161.74988903000019, -45.238765532],
              [161.753770030000169, -45.223633531999951],
              [161.75555161800014, -45.21686017899998],
              [161.757747521000169, -45.208513441999969],
              [161.760259380000207, -45.199199646999944],
              [161.761822030000189, -45.193406531999983],
              [161.765992078500176, -45.178313354499892],
              [161.770259030000261, -45.163233531999936],
              [161.774621030000162, -45.148167531999945],
              [161.775673558000221, -45.14461377299989],
              [161.779079300000262, -45.133115224],
              [161.783633106000138, -45.11807779399993],
              [161.788282340000023, -45.103055155999982],
              [161.790797823000048, -45.095098306999944],
              [161.793027030000189, -45.088047531000029],
              [161.794547728000225, -45.083336163000013],
              [161.797866415000158, -45.073055481999972],
              [161.800698735000196, -45.064459259999978],
              [161.802801030000182, -45.058079530999976],
              [161.805979865000154, -45.048622670999976],
              [161.807830149000324, -45.043118655999933],
              [161.811531093000099, -45.032324485],
              [161.812954030000128, -45.028174531],
              [161.818172030000255, -45.013247530999934],
              [161.823485030000256, -44.99833653099995],
              [161.828891030000023, -44.983443530999978],
              [161.830978668000284, -44.977797039999984],
              [161.834391241000276, -44.968567575999884],
              [161.839985176000141, -44.953709672999935],
              [161.842287765000151, -44.947701692999942],
              [161.845673030000171, -44.938869530999966],
              [161.84859622200031, -44.931373919],
              [161.851453289000347, -44.924048599],
              [161.857327093000322, -44.90924602900003],
              [161.860505114000148, -44.901371956999981],
              [161.863294030000134, -44.894462530999931],
              [161.867156476000332, -44.885050885],
              [161.869353314000136, -44.879698160000032],
              [161.875509943000225, -44.864928735999968],
              [161.881759105000128, -44.850179542999967],
              [161.883201160000226, -44.846830251000029],
              [161.888101030000257, -44.835450530999935],
              [161.894535030000043, -44.820741530999896],
              [161.901061030000164, -44.806054530999972],
              [161.907679030000281, -44.791387530999941],
              [161.912374810000188, -44.781137165999951],
              [161.9143880270002, -44.776742854999895],
              [161.919987789000061, -44.764702348999947],
              [161.92118903000005, -44.762119530999939],
              [161.923674622000021, -44.75685369699994],
              [161.928081084000269, -44.747518532000015],
              [161.935064256000032, -44.732939771000019],
              [161.942138245000137, -44.71838367],
              [161.946413776000327, -44.709710897999969],
              [161.949303030000237, -44.703850530999944],
              [161.956558030000338, -44.689340531000028],
              [161.96390303000021, -44.674854530999937],
              [161.966869762000329, -44.669083468999986],
              [161.971338169000319, -44.660391715],
              [161.977010977000191, -44.649507012000015],
              [161.978863030000184, -44.645953530999975],
              [161.980217552000227, -44.643389296999942],
              [161.986477520000335, -44.631539288999988],
              [161.994181286000185, -44.617149977999929],
              [162.001974153000219, -44.602785655],
              [162.007392066000307, -44.592928993999976],
              [162.009856030000037, -44.588446530999988],
              [162.014910129000327, -44.579370078999943],
              [162.017826308000167, -44.574133120999988],
              [162.025885150000249, -44.559845484],
              [162.034032203000152, -44.545583980999936],
              [162.042267239000125, -44.53134889699993],
              [162.050590031000183, -44.517140523000023],
              [162.059000355000308, -44.502959122999982],
              [162.067497976000027, -44.4888],
              [162.070505561000232, -44.483855854000012],
              [162.076083030000177, -44.474678530999938],
              [162.081534997000176, -44.465813661000027],
              [162.084754185000065, -44.460579690999964],
              [162.093512168500069, -44.446509299000013],
              [162.102357030000348, -44.432466530999932],
              [162.103691206000235, -44.430372935999969],
              [162.111287412000138, -44.418453280999984],
              [162.119684511000287, -44.405429379999987],
              [162.120304030000284, -44.404468530999964],
              [162.129406030000155, -44.390513530999954],
              [162.138594030000149, -44.376587530999984],
              [162.144590463000213, -44.367600641999957],
              [162.147866398000133, -44.362691341999948],
              [162.154351687000144, -44.353081557999985],
              [162.157224030000066, -44.348825530999932],
              [162.159041285000058, -44.346162584999945],
              [162.166666428000326, -44.334989613],
              [162.17619322600035, -44.321184519999974],
              [162.185804217000168, -44.307410306],
              [162.188375124000231, -44.303765914999985],
              [162.195499030000065, -44.29366753100004],
              [162.205278030000045, -44.279955530999985],
              [162.215140030000157, -44.266275530999962],
              [162.217562751000173, -44.262950713999942],
              [162.225085150000268, -44.252627668],
              [162.235113374000207, -44.239011914],
              [162.245224290000124, -44.225428659999977],
              [162.24881721900033, -44.22065241599995],
              [162.255418030000328, -44.211878531000025],
              [162.262541124000109, -44.202507243000014],
              [162.26569317700023, -44.198360721000029],
              [162.270309886000177, -44.192350327999918],
              [162.276051030000076, -44.184876530999986],
              [162.284562577000088, -44.173909117999983],
              [162.286489763000191, -44.17142598099997],
              [162.297010298000146, -44.158009220999986],
              [162.301443692000134, -44.152412880000043],
              [162.3076120300002, -44.144626530999972],
              [162.318295030000172, -44.131278531000021],
              [162.329058030000056, -44.117964530999927],
              [162.333256155000186, -44.112823249999963],
              [162.339901301000282, -44.104685727],
              [162.345106447000148, -44.098375082000018],
              [162.350825030000237, -44.091442530999984],
              [162.355716656000141, -44.085570178999944],
              [162.361828439000192, -44.078233759],
              [162.372911489000217, -44.065061126999979],
              [162.376030394000281, -44.061390555999935],
              [162.384074030000193, -44.051924531],
              [162.388505693000184, -44.046759578999968],
              [162.395315212000014, -44.038823855999965],
              [162.406635346000144, -44.025759728999958],
              [162.413883246000182, -44.017476128],
              [162.418034030000172, -44.01273253],
              [162.429510918500227, -43.999741648],
              [162.441066030000229, -43.986788530000013],
              [162.452454839000325, -43.974142572999938],
              [162.453499876000137, -43.972990705999962],
              [162.4644080300003, -43.960994529999937],
              [162.476195030000326, -43.948154529999954],
              [162.486807068000189, -43.93670258100002],
              [162.488058657000238, -43.935352008999928],
              [162.492045271000165, -43.931090525999934],
              [162.499999030000254, -43.922588529999985],
              [162.512016030000154, -43.90986353],
              [162.520353210000167, -43.901116799999954],
              [162.524108342000204, -43.897177414],
              [162.531425157000172, -43.889572985999962],
              [162.536277030000178, -43.88453053],
              [162.547878030000334, -43.872396529999946],
              [162.559548030000315, -43.860297530000025],
              [162.563643352000213, -43.856089525999948],
              [162.571287030000235, -43.848235529999954],
              [162.574232242000221, -43.845235943000034],
              [162.58309527900019, -43.836209466999946],
              [162.587310291000222, -43.831954519999897],
              [162.594972030000349, -43.82422053],
              [162.601128293000102, -43.818060144000015],
              [162.606916998000315, -43.812267882999947],
              [162.618930104000128, -43.800352585000041],
              [162.631271105000337, -43.788221091999901],
              [162.643160030000217, -43.776634530000024],
              [162.655323658666816, -43.764881173999981],
              [162.667658161500299, -43.753066400499904],
              [162.680008030000181, -43.741339529999948],
              [162.690408030000071, -43.73104153],
              [162.700859030000061, -43.720771529999979],
              [162.705114056000298, -43.71662145199997],
              [162.711360447000061, -43.710529416000043],
              [162.721912233000239, -43.700315689999968],
              [162.732513944000175, -43.690129989999889],
              [162.740792626000143, -43.680102923999982],
              [162.743009030000053, -43.677418530000011],
              [162.753578030000199, -43.664739529999963],
              [162.759834513000328, -43.657304187000022],
              [162.764219530000332, -43.652093312999966],
              [162.774933918000329, -43.639480872000036],
              [162.777399857000319, -43.636605294999981],
              [162.785721031000094, -43.62690253],
              [162.796580031000076, -43.61435753],
              [162.807511031000217, -43.601846529999932],
              [162.809363963000237, -43.599745371999973],
              [162.81851435800013, -43.589369535],
              [162.829064341000048, -43.576569567999968],
              [162.836054199000159, -43.568170144000021],
              [162.839689031000148, -43.563802530000032],
              [162.850387031000196, -43.55106953],
              [162.852185035000275, -43.548949683999979],
              [162.861158462000162, -43.538370623999981],
              [162.867403523000235, -43.531077595999989],
              [162.872004031000188, -43.525705530000039],
              [162.879253975000211, -43.517318091999982],
              [162.882921994000185, -43.513074792999987],
              [162.893913267000244, -43.500478613999974],
              [162.902818863000221, -43.49036770500004],
              [162.904977031000271, -43.48791753],
              [162.916114031000347, -43.475390530000027],
              [162.918828037000139, -43.472365846999935],
              [162.927322166000181, -43.462899785999923],
              [162.931273321000162, -43.458537032999971],
              [162.938603031000184, -43.450444530000027],
              [162.949955031000286, -43.43802453],
              [162.956700666000188, -43.430711394999946],
              [162.961378497000283, -43.425640323000039],
              [162.972873319000314, -43.413292532999947],
              [162.978342660000294, -43.407470585999988],
              [162.984439031000306, -43.400981529999967],
              [162.990312280000126, -43.394785746999958],
              [162.996075524000275, -43.38870634899996],
              [163.007782403000306, -43.376468403999979],
              [163.019559461000199, -43.364267520999988],
              [163.031406443000265, -43.352103921999969],
              [163.043323094000129, -43.339977826999927],
              [163.055309158000114, -43.327889459],
              [163.056285081000169, -43.326913965000017],
              [163.067365031000236, -43.315839529999948],
              [163.074289515000146, -43.308798051],
              [163.079439198000159, -43.303561838],
              [163.085224057000232, -43.297732684999971],
              [163.091585031000136, -43.291323529999943],
              [163.099071667000203, -43.28384669899998],
              [163.103801343000214, -43.279123547999973],
              [163.116088248000096, -43.266963310499932],
              [163.128446031000294, -43.254842529999962],
              [163.13469888800023, -43.248763264999923],
              [163.140873459000318, -43.242760679999947],
              [163.14701764300014, -43.236840599999937],
              [163.153371031000233, -43.230719529999931],
              [163.16593803100011, -43.218717529999935],
              [163.178574031000039, -43.20675653],
              [163.191279031000022, -43.194836530000032],
              [163.204053031000171, -43.182956529999842],
              [163.216894978000255, -43.171117581499914],
              [163.229805031000183, -43.159320529999945],
              [163.24278381950046, -43.147563727999852],
              [163.255829496000274, -43.135849429999894],
              [163.268108608000148, -43.124919264000027],
              [163.268943031000163, -43.124176529999943],
              [163.282123031000197, -43.112546530000031],
              [163.288186239000197, -43.107242195999902],
              [163.295370000000162, -43.100957967999989],
              [163.308683516000201, -43.089412207],
              [163.313177734000078, -43.085548360999837],
              [163.322063031000312, -43.077909529999943],
              [163.335509031000129, -43.066449529999943],
              [163.34902003100035, -43.055032529999949],
              [163.362597031000178, -43.043658530000016],
              [163.376238031000298, -43.032328530000029],
              [163.390001031000224, -43.020637529999817],
              [163.403832031000093, -43.00899153],
              [163.417732031000128, -42.997390529999905],
              [163.431699031000107, -42.985835529999974],
              [163.445733031000259, -42.974326529999985],
              [163.459835031000125, -42.962863529999936],
              [163.474004031000163, -42.951446528999952],
              [163.486282424000251, -42.941639341000041],
              [163.488239525000267, -42.940076221999959],
              [163.490543950000188, -42.938045010999929],
              [163.500927031000174, -42.928893528999879],
              [163.513676031000273, -42.917749528999934],
              [163.526487031000102, -42.906644528999948],
              [163.53935803100012, -42.895580529000028],
              [163.552291031000323, -42.884555528999897],
              [163.557883600000196, -42.879827262999981],
              [163.565284187000316, -42.873570726999958],
              [163.569456665000189, -42.870072344999869],
              [163.578338031000158, -42.86262652899984],
              [163.590851031000284, -42.851909528999954],
              [163.602338416000293, -42.842150279],
              [163.603420640000309, -42.841230885999948],
              [163.616047723000179, -42.83058991099989],
              [163.628731792500275, -42.819986734999929],
              [163.641472031000148, -42.80942252899996],
              [163.647423667000226, -42.804526243999874],
              [163.654268519000084, -42.798895698000024],
              [163.667121192000309, -42.788407764999931],
              [163.674245516000155, -42.782640719999961],
              [163.680030031000342, -42.777958528999989],
              [163.692994031000239, -42.76754852900001],
              [163.70160157100031, -42.76069105900001],
              [163.706013167000265, -42.757176744999981],
              [163.719088195000154, -42.746844716],
              [163.732192097000052, -42.735688443000015],
              [163.732763885500162, -42.73520164699994],
              [163.746507016000237, -42.72360366800001],
              [163.753234448000171, -42.717975996999883],
              [163.760318031000224, -42.712050528999953],
              [163.774195031000318, -42.700543529000015],
              [163.779616248000252, -42.696087276999947],
              [163.788139503000139, -42.689081367000028],
              [163.799656460000165, -42.679697261999934],
              [163.802150031000338, -42.677665528999967],
              [163.816227031000153, -42.666295528999967],
              [163.830370031000257, -42.654971529000022],
              [163.844578031000196, -42.643694529000015],
              [163.84940398100025, -42.639897133000034],
              [163.858851101000255, -42.632463981],
              [163.873189308000292, -42.621280422999973],
              [163.88759209900013, -42.610144004999938],
              [163.902059167000175, -42.599054939999974],
              [163.916590204000158, -42.588013439],
              [163.931184902000155, -42.57701971299997],
              [163.945842951000145, -42.56607397],
              [163.957464013000163, -42.557471271999987],
              [163.96056403200032, -42.555176529],
              [163.975348032000284, -42.544327528999929],
              [163.984311305000233, -42.537806425000042],
              [163.99019410800031, -42.533526723999955],
              [164.005102459000227, -42.522774989999959],
              [164.01374520700017, -42.516595966999944],
              [164.020073032000141, -42.512072529000022],
              [164.028952495000226, -42.505778749999934],
              [164.035104232000208, -42.501418773999944],
              [164.050197027000195, -42.490814697000019],
              [164.053762570000231, -42.488331310999968],
              [164.065351032000194, -42.48026052900002],
              [164.080565032000209, -42.469755529],
              [164.081409580000127, -42.469177438999964],
              [164.095839263000244, -42.459301001999961],
              [164.107089979000193, -42.451667346999955],
              [164.111174032000349, -42.448896529000024],
              [164.116691452000282, -42.445185273],
              [164.126567465000221, -42.438542641999959],
              [164.133336274000271, -42.434029554999938],
              [164.142021032000343, -42.428239529],
              [164.157532883500153, -42.417986632500003],
              [164.173103032000199, -42.407785529000023],
              [164.174333577000198, -42.406986292999989],
              [164.188732519000155, -42.397634837999931],
              [164.204419553000321, -42.387535875000026],
              [164.220164251000256, -42.377488489999962],
              [164.23596629300016, -42.367492874],
              [164.248943530000133, -42.359356123],
              [164.25182503200017, -42.357549528999982],
              [164.257266854000136, -42.354167366000013],
              [164.267741112000294, -42.347657699999957],
              [164.2837132410001, -42.337818516999903],
              [164.299741418000167, -42.328031851],
              [164.315825316000087, -42.318297885999982],
              [164.331964609000124, -42.308616807000021],
              [164.348158971000316, -42.298988795000014],
              [164.364408217000232, -42.289413979999949],
              [164.369882958000176, -42.284133857999961],
              [164.377082032000203, -42.277191528999936],
              [164.389826032000201, -42.265010529],
              [164.391682414000343, -42.263252072],
              [164.402641407000147, -42.252871908999964],
              [164.411977229000058, -42.244108429999983],
              [164.415527032000227, -42.240776529000016],
              [164.428483032000145, -42.228723528999978],
              [164.441509032000226, -42.216713528999946],
              [164.454604032000134, -42.204747529000031],
              [164.467769032000206, -42.19282452899995],
              [164.478815800000234, -42.182908064999936],
              [164.481002314000307, -42.180945367999982],
              [164.483171924000203, -42.179015086999982],
              [164.49430503200017, -42.169110529000022],
              [164.500641110000288, -42.163522742999874],
              [164.507675385000198, -42.157319727000015],
              [164.521114249000334, -42.14557354],
              [164.524786470000322, -42.142392127],
              [164.534621032000132, -42.13387252900003],
              [164.545314089000158, -42.124689607999983],
              [164.54819515700035, -42.122215564999976],
              [164.550615354000115, -42.120155540999974],
              [164.561837032000085, -42.110604528999943],
              [164.575545032000264, -42.099038528999984],
              [164.580769399000133, -42.094669024000041],
              [164.589320024000131, -42.087517972999933],
              [164.603161388000331, -42.076043500000026],
              [164.607241624000267, -42.072690565999949],
              [164.617069032000131, -42.064615528999923],
              [164.626002080000148, -42.057338287999983],
              [164.631041934000223, -42.053232965999939],
              [164.64508051200005, -42.041897343999949],
              [164.659184224000342, -42.030608443],
              [164.662076066000282, -42.028313924999964],
              [164.67335303200025, -42.019366529000024],
              [164.687585936000119, -42.008171598999979],
              [164.701883032000183, -41.99702452899993],
              [164.712451114000146, -41.988856175999956],
              [164.716244344000074, -41.985924368000042],
              [164.721825520000152, -41.98164816100001],
              [164.730669032000037, -41.974872528999981],
              [164.745157032000293, -41.963868528999967],
              [164.75036715400006, -41.959945631000039],
              [164.75970841300034, -41.952912402999971],
              [164.774322222000251, -41.94200499],
              [164.788144795000221, -41.931777794000027],
              [164.788998032000222, -41.931146528999989],
              [164.803737032000072, -41.92033652899994],
              [164.818537032000222, -41.909575529],
              [164.822758424000199, -41.906500632999951],
              [164.830949279000151, -41.900534949000019],
              [164.83932988100014, -41.894475638000017],
              [164.843405032000192, -41.891529528999968],
              [164.855904032000012, -41.882558527999947],
              [164.859561510000162, -41.879952334999984],
              [164.868446273000131, -41.873622011999942],
              [164.881031107000126, -41.864720943999941],
              [164.893658770000343, -41.855855021999957],
              [164.907348495000178, -41.844270946],
              [164.921104990000345, -41.83273261599993],
              [164.922980333000169, -41.831173462999971],
              [164.934928033000347, -41.821240528000033],
              [164.948817033000182, -41.809794527999941],
              [164.953692225000168, -41.805811643000027],
              [164.962771369000166, -41.798394955999981],
              [164.976791456000342, -41.787042249000024],
              [164.979095559000143, -41.785192820999953],
              [164.990877033000174, -41.775736527999982],
              [164.999970374000213, -41.768501056],
              [165.005026751000173, -41.764477922999902],
              [165.019241352000222, -41.753266737999979],
              [165.02847284700033, -41.746049303999939],
              [165.033520033000173, -41.74210352800003],
              [165.04786303300034, -41.730987528000014],
              [165.052913897000167, -41.727106994999986],
              [165.062269298000047, -41.719919691999962],
              [165.069670600000336, -41.714283185999989],
              [165.076739033000308, -41.708900527999958],
              [165.091272033000337, -41.697929528000031],
              [165.100531384000192, -41.690999764999958],
              [165.105867005000164, -41.687006892999989],
              [165.113772338000331, -41.681142536999971],
              [165.120525033000149, -41.676133528000037],
              [165.13524503300016, -41.665309528000023],
              [165.150026033000131, -41.654534527999942],
              [165.163548707000245, -41.644762635],
              [165.164869133000252, -41.643808496999952],
              [165.179773275000116, -41.633132666],
              [165.194738236000148, -41.622506753000039],
              [165.209763700000053, -41.61193096300002],
              [165.224849351000216, -41.601405497999963],
              [165.235609956000189, -41.593963255],
              [165.239995033000014, -41.590930527999944],
              [165.255200033000278, -41.580506528],
              [165.270464033000167, -41.570133527999985],
              [165.275057421000156, -41.567038993999972],
              [165.285787509000329, -41.559810909999953],
              [165.297211158000209, -41.552183055999933],
              [165.301169033000178, -41.549540527999966],
              [165.306096322000144, -41.546279070999958],
              [165.316609467000262, -41.539320761999932],
              [165.323296965000168, -41.534933393000017],
              [165.332108033000168, -41.529153528],
              [165.345669613000155, -41.520333923999885],
              [165.347663270000226, -41.519037474000015],
              [165.363276311000163, -41.508973888000014],
              [165.378946350000206, -41.498962595999956],
              [165.394673062000152, -41.489003790999959],
              [165.41045612500011, -41.479097662000015],
              [165.425009998000178, -41.470043912],
              [165.426295033000201, -41.469244527999933],
              [165.442190033000202, -41.459444527999892],
              [165.458140033000177, -41.449697527999945],
              [165.464337783000246, -41.44594364],
              [165.474145391000206, -41.44000367000001],
              [165.490205333000176, -41.430363734999958],
              [165.501760928000181, -41.423489508999978],
              [165.506320033000179, -41.420777527999974],
              [165.522488033000286, -41.411245528],
              [165.538710127000172, -41.40176747299995],
              [165.554985776000194, -41.392343747000041],
              [165.571314412000334, -41.382974593000029],
              [165.576995306000185, -41.37974443600001],
              [165.587696033000157, -41.373660527999959],
              [165.604130033000274, -41.364400528000033],
              [165.607941188000154, -41.362272430999894],
              [165.620615497000017, -41.355196005999971],
              [165.637153145000184, -41.346046687999944],
              [165.653742216000296, -41.336952765999968],
              [165.670382376000248, -41.327914412999974],
              [165.687073291000047, -41.318931798999984],
              [165.69353985000032, -41.315483743999948],
              [165.703815033000069, -41.310005528000033],
              [165.720606033000081, -41.301134528000034],
              [165.737447033000308, -41.292320527999948],
              [165.754338033000067, -41.283562527999948],
              [165.76340681000022, -41.27890366400004],
              [165.771277471000303, -41.274860748000037],
              [165.77925623200008, -41.270800713000028],
              [165.788266033000127, -41.266216527999973],
              [165.8053030330002, -41.257628527999941],
              [165.822388033000266, -41.249097528],
              [165.839520033000213, -41.240624527999955],
              [165.856700033000038, -41.232208527999944],
              [165.864245722000078, -41.228547157999969],
              [165.873927147000245, -41.223849768999969],
              [165.891200877000188, -41.215548991999981],
              [165.898992403000079, -41.211840878999965],
              [165.90852103300017, -41.207306527999947],
              [165.925887033000322, -41.199121527999978],
              [165.94329903300013, -41.190994527999976],
              [165.960756033000166, -41.182926528],
              [165.978258033000202, -41.174916527999983],
              [165.986281856000232, -41.171280268999965],
              [165.99580493700023, -41.166964912999958],
              [166.009134451000023, -41.160984438000014],
              [166.013396034000152, -41.159072528000024],
              [166.017554323000013, -41.157225290000042],
              [166.031031083000244, -41.151238860000035],
              [166.041013795000026, -41.146848800999976],
              [166.048710034000237, -41.143464527999981],
              [166.066432034000172, -41.135749528000019],
              [166.08419703400034, -41.128093527999965],
              [166.094927102000156, -41.123515758999929],
              [166.102004032000195, -41.12049685400001],
              [166.108841143000177, -41.117610002999982],
              [166.119854034000241, -41.112960528000031],
              [166.137745034000318, -41.105483527999979],
              [166.155678034000175, -41.098066527999947],
              [166.17365303400004, -41.090709528],
              [166.189351997000017, -41.084350641000015],
              [166.191667875000348, -41.083412639999949],
              [166.203302226000233, -41.078749829999936],
              [166.209723034000035, -41.076176528000019],
              [166.21695525400014, -41.073308577999967],
              [166.227819309000324, -41.06900046200002],
              [166.229813935000152, -41.068217901000025],
              [166.245955034000303, -41.061885528],
              [166.257271281000158, -41.057492894],
              [166.264129922000279, -41.054830777999939],
              [166.271313606000263, -41.052072499999952],
              [166.282344034000175, -41.047837527999945],
              [166.296995866000316, -41.042272352],
              [166.300596910000195, -41.040904652999984],
              [166.305345783000234, -41.039120664],
              [166.318888034000338, -41.034033528],
              [166.337217034000219, -41.027223527999979],
              [166.35272199800022, -41.021526190000031],
              [166.355584446000307, -41.020474419999971],
              [166.357396374000132, -41.019816058999936],
              [166.373989034000033, -41.013787528000023],
              [166.386023495000018, -41.009463449],
              [166.39243006200013, -41.007161721999978],
              [166.410907989000179, -41.000597986999935],
              [166.421730966000155, -40.996797166999954],
              [166.429422034000027, -40.994096528],
              [166.447972034000259, -40.987656527999945],
              [166.453854401000171, -40.985637925999981],
              [166.466557995000016, -40.981278705000022],
              [166.473847663000129, -40.978806372],
              [166.485179034000225, -40.974963527999989],
              [166.503835034000303, -40.968710527999974],
              [166.514454846000262, -40.965192750999961],
              [166.522524890000227, -40.962519828999973],
              [166.541249374000131, -40.956391922000023],
              [166.559857867000346, -40.950317979999966],
              [166.578499721000185, -40.944305950999933],
              [166.597174494000228, -40.938355977000015],
              [166.608092229000135, -40.93491980899995],
              [166.615882034000151, -40.932468527999958],
              [166.628320216000247, -40.928601467000021],
              [166.634621391000337, -40.926642630999936],
              [166.646585221000237, -40.922969524999985],
              [166.653393034000146, -40.920879528],
              [166.660633525000208, -40.918684237999969],
              [166.67219575300021, -40.915178804999982],
              [166.691029855000238, -40.909540726999978],
              [166.697795169000187, -40.90754129000004],
              [166.709895034000169, -40.903965528000015],
              [166.719289883000215, -40.901224390000039],
              [166.728790142000321, -40.898452759999941],
              [166.734765621000292, -40.89673209099999],
              [166.747716034000291, -40.893003528],
              [166.766671034000183, -40.887616527999967],
              [166.772621662000347, -40.885947694000038],
              [166.785655494000139, -40.882292805999953],
              [166.801868321000029, -40.877807311999959],
              [166.804669034000199, -40.877032527999916],
              [166.823712034000209, -40.871835527999963],
              [166.836700855000345, -40.868338693999931],
              [166.842782273000239, -40.866701618999912],
              [166.852685172000292, -40.864072651000015],
              [166.861881034000135, -40.861631527999975],
              [166.868055023000011, -40.860015238000017],
              [166.881007312000293, -40.856624755999974],
              [166.900160924000261, -40.851681783],
              [166.914688904000172, -40.847985934],
              [166.919341234000171, -40.846802439999863],
              [166.93316504700033, -40.840695614999959],
              [166.947015209500137, -40.83462545749984],
              [166.960892181000077, -40.828591783999968],
              [166.97479519000035, -40.822594580999919],
              [166.98934226900019, -40.815864780999959],
              [167.003920744000197, -40.80917595599999],
              [167.013462548000234, -40.804834201],
              [167.01853003400015, -40.802528527],
              [167.033171034000134, -40.795921527000019],
              [167.045645034000131, -40.78877052699994],
              [167.052153130000193, -40.785065222999947],
              [167.058149211000227, -40.781651636999982],
              [167.072329450000211, -40.771020754],
              [167.086568550000266, -40.760436992999985],
              [167.100866103000271, -40.749900639000032],
              [167.115221818000123, -40.739411888],
              [167.129635407000137, -40.728970932999971],
              [167.144106576000127, -40.718577966999987],
              [167.147697766000306, -40.716020910999987],
              [167.158635035000088, -40.708233526999948],
              [167.164157335000141, -40.704334786999937],
              [167.173220493000031, -40.697936769000023],
              [167.187862654000128, -40.687688916999946],
              [167.202561226000171, -40.677489815999934],
              [167.217315914000324, -40.667339653999989],
              [167.232126424000057, -40.657238616999962],
              [167.246992460000115, -40.647186891999979],
              [167.260445463000224, -40.638168888999985],
              [167.261914035000132, -40.637184526999988],
              [167.276890035000179, -40.62723252699999],
              [167.2919210350002, -40.617329527000038],
              [167.299101313000165, -40.612639617999946],
              [167.307006116000252, -40.607476781],
              [167.309986651000145, -40.601790677999986],
              [167.314182035000044, -40.593787526999961],
              [167.321434035000152, -40.58012152699996],
              [167.324728110000137, -40.573989187999985],
              [167.328762069000277, -40.566479760999925],
              [167.335421644000348, -40.554231566999931],
              [167.336166035000019, -40.552862527],
              [167.343646035000233, -40.539269526999988],
              [167.351298035000241, -40.525065526999981],
              [167.359032035000155, -40.510888526999963],
              [167.360148751000338, -40.508866693999948],
              [167.366848435000293, -40.496737666999934],
              [167.370202740000082, -40.490739489999953],
              [167.374747035000155, -40.482614526999953],
              [167.378864977000148, -40.475339099],
              [167.382726480000315, -40.468517818999942],
              [167.38720947600018, -40.460694027999978],
              [167.390788035000242, -40.454449526999895],
              [167.397160602000213, -40.443459940000025],
              [167.398930310000196, -40.440408288000015],
              [167.403143587000272, -40.433228991000021],
              [167.407154035000303, -40.426395526999954],
              [167.412336908000157, -40.417667527999981],
              [167.415458239000259, -40.412411313],
              [167.423843208000221, -40.398455732999935],
              [167.426321562000339, -40.394378517],
              [167.43230903500023, -40.384529526999984],
              [167.439071170000261, -40.37353126499994],
              [167.440854075000061, -40.370631772000024],
              [167.444729724000013, -40.364400590999935],
              [167.449480035000192, -40.356763527],
              [167.458185035000156, -40.342925526999977],
              [167.458807666000212, -40.341946783000026],
              [167.466969434000021, -40.32911797600002],
              [167.475833423000267, -40.315340381999931],
              [167.484776477000196, -40.301593411999988],
              [167.493798371000025, -40.287877340999955],
              [167.49834118900003, -40.28104607299997],
              [167.502899035000155, -40.274192527000025],
              [167.510444459000155, -40.262968513000033],
              [167.512077780000141, -40.260538974999974],
              [167.513097151000238, -40.259038970999939],
              [167.52133503500022, -40.246917526999937],
              [167.530670035000298, -40.233327527000029],
              [167.532982646000164, -40.229996207],
              [167.540082534000248, -40.219769923999934],
              [167.548917954000103, -40.207178040000031],
              [167.549573035000208, -40.206244526999967],
              [167.551660399000298, -40.203300791999936],
              [167.559140117000084, -40.192752687999985],
              [167.561831775000201, -40.188996370000041],
              [167.568785035000133, -40.17929352699997],
              [167.578506035000146, -40.165867526999932],
              [167.582566233000193, -40.160317443999929],
              [167.58830346200034, -40.152475361999961],
              [167.5981774950003, -40.139116919999935],
              [167.604675662000318, -40.130415119999896],
              [167.608128035000277, -40.125792526999874],
              [167.612365234000094, -40.120175331999974],
              [167.618153514000142, -40.112502615999972],
              [167.625514069000133, -40.102843994000011],
              [167.628255035000052, -40.099247526999989],
              [167.635831059000225, -40.089405469000042],
              [167.638431873000286, -40.086026829],
              [167.641126106000343, -40.082561707999901],
              [167.648684035000201, -40.07284152699998],
              [167.659011035000162, -40.059691527000041],
              [167.669412035000164, -40.046577527000011],
              [167.679888035000204, -40.033499527],
              [167.688189279000198, -40.023236671],
              [167.690437791000249, -40.02045699599995],
              [167.692382302000283, -40.018076520000022],
              [167.701062035000177, -40.007451527],
              [167.711759035000227, -39.994482526999946],
              [167.722530035000204, -39.981550526999968],
              [167.733374035000224, -39.96865552700001],
              [167.74429103500006, -39.955797526999959],
              [167.755280035000169, -39.94297852699998],
              [167.766342035000207, -39.930196526999964],
              [167.770872714000234, -39.925010702999941],
              [167.777475949000291, -39.917452964],
              [167.788366103000158, -39.90504345599993],
              [167.799325035000265, -39.892670527],
              [167.810351035000281, -39.880334526999945],
              [167.821445035000124, -39.868035526999904],
              [167.832607035000137, -39.855773527],
              [167.843836035000209, -39.843549526999936],
              [167.853912296000203, -39.832678476000012],
              [167.855131672000198, -39.831362988999956],
              [167.866494391000174, -39.81921474699999],
              [167.875414381000155, -39.809763486999941],
              [167.877924035000319, -39.807104526999922],
              [167.889419035000174, -39.79503352699993],
              [167.900981035000086, -39.783000526999956],
              [167.906707621000351, -39.777093184000023],
              [167.912608166000155, -39.771006676999946],
              [167.924301105000154, -39.759051995999982],
              [167.936059347000082, -39.747136692999959],
              [167.947882644000174, -39.735260985999957],
              [167.948936970000204, -39.734211289999962],
              [167.959771035000017, -39.723425525999971],
              [167.970584741000152, -39.71275296399989],
              [167.97172340200018, -39.711629239999979],
              [167.983740361000173, -39.69987363499996],
              [167.995821371000147, -39.688158498],
              [168.001424590000056, -39.682772284999942],
              [168.007966035000322, -39.676484525999911],
              [168.013356965000298, -39.671347068999978],
              [168.020174532000169, -39.664850489],
              [168.02695522800002, -39.658445084],
              [168.032446035000021, -39.653258525999945],
              [168.039910419000194, -39.646267965999982],
              [168.044780852000144, -39.641706926999916],
              [168.057178307000186, -39.630197345999932],
              [168.069638283000131, -39.618729513],
              [168.074636760000089, -39.614168683999935],
              [168.082161035000127, -39.607303525999953],
              [168.090110035000293, -39.593543526000019],
              [168.098136035000152, -39.579810526000017],
              [168.100804994000214, -39.575295829000027],
              [168.106238504000203, -39.566105562999894],
              [168.111604349000231, -39.557133023999938],
              [168.114418035000313, -39.552428525999971],
              [168.120828533000235, -39.54182977499994],
              [168.122673909000014, -39.53877885899999],
              [168.131006046000152, -39.525157910000019],
              [168.139414200000147, -39.511565578999978],
              [168.144722440000237, -39.503079211999932],
              [168.147898036000129, -39.498002526],
              [168.156458036000345, -39.484467526],
              [168.165093036000144, -39.470962526],
              [168.173803036000209, -39.457487526000023],
              [168.182588036000197, -39.444042525999919],
              [168.191448036000338, -39.430627526000023],
              [168.19588098200029, -39.423986045999982],
              [168.200382511000157, -39.417242314999953],
              [168.209391371000152, -39.403888233000032],
              [168.218474371000298, -39.390565085999981],
              [168.225317846000166, -39.380631274999985],
              [168.227631168000244, -39.377273327500006],
              [168.236862036000161, -39.36401252600001],
              [168.23818903200015, -39.362125719999938],
              [168.246166073000268, -39.350783790999969],
              [168.255543487000182, -39.337586916999896],
              [168.262374200000181, -39.328071611999974],
              [168.264994036000076, -39.324422526000035],
              [168.268075276000332, -39.320173241999953],
              [168.274517276000211, -39.311290009999937],
              [168.284113211000317, -39.298190478000038],
              [168.287196541000299, -39.29402337700003],
              [168.293782036000266, -39.28512352599995],
              [168.301727052000047, -39.274109977000023],
              [168.303507001000185, -39.27164266799997],
              [168.313308932000155, -39.258195736999937],
              [168.31614755600026, -39.254341482999962],
              [168.323187036000149, -39.244783526],
              [168.326800167000215, -39.239927540999986],
              [168.333141095000201, -39.231405751999944],
              [168.336744693000128, -39.226611906999949],
              [168.343171036000058, -39.21806352599998],
              [168.353276036000011, -39.20475652600004],
              [168.357886070000063, -39.19874627300004],
              [168.363456488000224, -39.191484471999956],
              [168.373711889000219, -39.178248759999974],
              [168.374870262000059, -39.176768619999962],
              [168.384042036000324, -39.165049525999962],
              [168.394447036000201, -39.151885525999944],
              [168.395286461000296, -39.150833961999979],
              [168.40492547600013, -39.138759461999939],
              [168.41547830900015, -39.12566984800003],
              [168.422738079000169, -39.116752795999957],
              [168.426105036000195, -39.112617525999951],
              [168.436805036000351, -39.099602526000012],
              [168.447578036000323, -39.086625525999978],
              [168.449590566000211, -39.084224442999982],
              [168.458424517000253, -39.073685750000038],
              [168.469343528000309, -39.060784604999924],
              [168.480335015000151, -39.047921925999958],
              [168.491398723000202, -39.035097966000023],
              [168.502534395000225, -39.022312979999981],
              [168.509132508000221, -39.014809176999961],
              [168.513742036000167, -39.009567525999955],
              [168.525021036000112, -38.996860525999921],
              [168.536371036000219, -38.984194525999953],
              [168.547792036000146, -38.971567526],
              [168.555496406000316, -38.963128990999962],
              [168.559283170000299, -38.958981488],
              [168.570845183000074, -38.946435630999986],
              [168.582477336000352, -38.933930500000031],
              [168.594179364000297, -38.921466344],
              [168.600248611000211, -38.915061299],
              [168.605951036000306, -38.909043526],
              [168.609544604000348, -38.905285771999957],
              [168.617791983000302, -38.896661935],
              [168.623779791000288, -38.890458091000028],
              [168.62970203600014, -38.884322525999949],
              [168.64168103600025, -38.872024526000018],
              [168.646265065000193, -38.86736097],
              [168.65372830400031, -38.859768743000018],
              [168.665159873000221, -38.84824516599997],
              [168.66584403600018, -38.847555525999951],
              [168.678028036000228, -38.835385526000039],
              [168.690279036000106, -38.82325752600002],
              [168.692656025000247, -38.820925687],
              [168.702597859000207, -38.811173023000038],
              [168.7149838710003, -38.799131975000023],
              [168.722160808000126, -38.792217550000032],
              [168.727437036000197, -38.787134525999988],
              [168.730208038000228, -38.784488582999984],
              [168.739956323000229, -38.775180995000028],
              [168.752542208000136, -38.763271532999966],
              [168.76519409950015, -38.751406463999956],
              [168.777911910000171, -38.739585834999957],
              [168.782519201000241, -38.735341657],
              [168.790695036000216, -38.727810525999928],
              [168.803544036000176, -38.716079525999987],
              [168.812327382000035, -38.707888213999979],
              [168.816199265000023, -38.704277404000038],
              [168.828919862000134, -38.69251982000003],
              [168.841705391000232, -38.680806944999972],
              [168.854555572000095, -38.669139005000034],
              [168.857430964000173, -38.666551223999875],
              [168.867470036000327, -38.657516525],
              [168.876921865000213, -38.649084960999971],
              [168.880448773000126, -38.645938840999975],
              [168.883383528000195, -38.643344014000036],
              [168.893491036000228, -38.634407525000029],
              [168.906597036000335, -38.622921525000038],
              [168.915846687000112, -38.614886291],
              [168.919766453000307, -38.611481261999984],
              [168.92428459000007, -38.607590917999971],
              [168.932999036000069, -38.600087525],
              [168.936630425000203, -38.596987978999948],
              [168.946293524000339, -38.588740591000033],
              [168.952824861000295, -38.583215025999891],
              [168.959651036000309, -38.577440524999929],
              [168.967068680000125, -38.571219632999941],
              [168.973070163000102, -38.566186821999963],
              [168.986551354000312, -38.554980573000023],
              [169.000094075000334, -38.543821703999981],
              [169.013698125500127, -38.532710455000029],
              [169.026963036000325, -38.521660525000016],
              [169.04028803600022, -38.510655524999947],
              [169.053672036000194, -38.499697524999931],
              [169.056195636000325, -38.497649059999958],
              [169.067115479000108, -38.488785551999968],
              [169.073527944000148, -38.483625469],
              [169.08061803600026, -38.477920524999945],
              [169.092011115000332, -38.468831099],
              [169.094178888000329, -38.467101723999974],
              [169.099355887000343, -38.463007256000026],
              [169.107798036000304, -38.456330524999942],
              [169.11479357300027, -38.450845283999968],
              [169.121475809000344, -38.445605831999956],
              [169.135211137000169, -38.43492886699994],
              [169.147256563000042, -38.425646143],
              [169.149004036000179, -38.424299525000038],
              [169.150673392000044, -38.423024064999936],
              [169.16285412200034, -38.413717901999973],
              [169.174367540000162, -38.404997332],
              [169.17676103600013, -38.403184525000029],
              [169.179871247000165, -38.400848971999949],
              [169.190724996000313, -38.392698880000012],
              [169.204745182000238, -38.382261840000012],
              [169.21882148900022, -38.371873376999929],
              [169.226834010000175, -38.366011064999952],
              [169.232954037000013, -38.361533525000027],
              [169.235475703000134, -38.359704351999937],
              [169.247141321000186, -38.351242953],
              [169.26138427300009, -38.341001378999977],
              [169.262530817000084, -38.340184068999974],
              [169.275682037000024, -38.33080952499995],
              [169.29003503700028, -38.320666525000036],
              [169.304442037000172, -38.310573524999938],
              [169.318903037000155, -38.300530524999942],
              [169.333418037000342, -38.290537524999934],
              [169.335982637000257, -38.288787124999942],
              [169.347986332000232, -38.280594872],
              [169.362608027000192, -38.270702698999969],
              [169.365862819000199, -38.268519879999985],
              [169.377283037000097, -38.260861525],
              [169.392010037000205, -38.251070524999989],
              [169.39928728100017, -38.246274830999937],
              [169.406789597000056, -38.241330878000042],
              [169.421621303000165, -38.231642444999977],
              [169.425203874000204, -38.229322732000014],
              [169.436505037000074, -38.222005524999929],
              [169.44536068900004, -38.216321541999946],
              [169.451439722000259, -38.212419926],
              [169.456060068000284, -38.209480597999971],
              [169.466426037000247, -38.202886525],
              [169.481463037000253, -38.193404524999949],
              [169.487223799000276, -38.189803809999944],
              [169.496550426000226, -38.183974690000014],
              [169.50860284300029, -38.176508599999977],
              [169.511688037000113, -38.174597524999967],
              [169.526876037000193, -38.165272525000034],
              [169.534086295000293, -38.160884762000023],
              [169.542113682000149, -38.155999933999965],
              [169.547066885000163, -38.153012653999951],
              [169.557401037000204, -38.146780525],
              [169.572736826500204, -38.13761365649998],
              [169.58812141400017, -38.128500338000038],
              [169.589059086000134, -38.127949876],
              [169.603555037000206, -38.119440524999959],
              [169.61723185700032, -38.111483214999964],
              [169.61903615700021, -38.110433524999905],
              [169.634565499000246, -38.101480498999933],
              [169.639971127000194, -38.098392272999945],
              [169.650143037000248, -38.092581524999957],
              [169.657504623000023, -38.087770886999976],
              [169.664474096000163, -38.083216942999968],
              [169.678854021000234, -38.073900644],
              [169.69328201400026, -38.064632657],
              [169.695907800000214, -38.062960314999941],
              [169.707758037000303, -38.055413524999892],
              [169.722281037000016, -38.046242525000025],
              [169.736852037000176, -38.037120525000034],
              [169.751469037000334, -38.028047525000034],
              [169.760297353000283, -38.022614483999917],
              [169.766133592000301, -38.019023015999949],
              [169.777025146000142, -38.012378281999929],
              [169.780844037000179, -38.010048525],
              [169.78269130300032, -38.008931178000012],
              [169.795601279000152, -38.001122927999916],
              [169.805193854000208, -37.995371240999987],
              [169.810404037000126, -37.992247524999982],
              [169.825253037000266, -37.983421524999983],
              [169.828043752000099, -37.978298059],
              [169.830518444000177, -37.973755426000025],
              [169.835821212000269, -37.964102434999973],
              [169.838436121000314, -37.959381662],
              [169.841161037000347, -37.95446252499994],
              [169.843082785000206, -37.950973922999935],
              [169.848677364000309, -37.940818616999934],
              [169.854224142000135, -37.930867203999981],
              [169.856268037000291, -37.927200524999989],
              [169.863932037000268, -37.91360952499997],
              [169.866751316000204, -37.908666587000027],
              [169.871669272000304, -37.900044958999956],
              [169.878934238000284, -37.887453431999973],
              [169.879480037000235, -37.886507524999956],
              [169.880459548000204, -37.884828913999968],
              [169.887364258000048, -37.872996612],
              [169.895321307000245, -37.859513427999957],
              [169.903351144000339, -37.846057908999967],
              [169.906305967000094, -37.841161086],
              [169.911454037000226, -37.832630525000013],
              [169.915525490000277, -37.825956012],
              [169.919628422000073, -37.819230870999945],
              [169.927875482000331, -37.805859855999927],
              [169.932668101000331, -37.798173340999938],
              [169.936195037000289, -37.792517525],
              [169.942403626000328, -37.782665922000035],
              [169.944585484000186, -37.779204083000025],
              [169.953048153000282, -37.765919698],
              [169.95631328800016, -37.760747787000014],
              [169.961257037000223, -37.75291752499993],
              [169.965340899000239, -37.74651539499996],
              [169.96953339200013, -37.739943514999936],
              [169.977878366000141, -37.726997413999982],
              [169.986291304000019, -37.714079658999935],
              [169.993844826000327, -37.702599653999982],
              [169.994772037000189, -37.701190525000015],
              [170.0004789850002, -37.69260473199995],
              [170.003320332000158, -37.688330113999939],
              [170.011936052000237, -37.675498782000034],
              [170.020618992000095, -37.662696714999981],
              [170.022397466000143, -37.660100628999942],
              [170.029369037000151, -37.649924524999903],
              [170.038186037000287, -37.63718152499996],
              [170.045134166000253, -37.627237636000018],
              [170.047069256000327, -37.62446837899995],
              [170.056019192000235, -37.611785643999951],
              [170.06503540000017, -37.599133306999931],
              [170.067893680000225, -37.595161072],
              [170.074118037000176, -37.586511524],
              [170.063885037000318, -37.574693523999883],
              [170.057094380000194, -37.566775989],
              [170.053718893000337, -37.562840114000039],
              [170.047674810000331, -37.555724387999931],
              [170.043620037000323, -37.550950524000029],
              [170.037221640000325, -37.543344653999895],
              [170.033589116000144, -37.539026528000022],
              [170.032802320000201, -37.538082115999941],
              [170.023626037000327, -37.527066523999963],
              [170.013730037000187, -37.515072523999947],
              [170.00390203700033, -37.50304352400002],
              [169.998699528000316, -37.496613415000013],
              [169.994142201000273, -37.490980440999977],
              [169.984450719000051, -37.478883033999921],
              [169.983377525000265, -37.477530115999969],
              [169.974828037000179, -37.46675152399996],
              [169.965273037000316, -37.454586523999922],
              [169.963039884000182, -37.451714923999987],
              [169.955787303000193, -37.442388385999948],
              [169.951042258000143, -37.436586611],
              [169.945199037000066, -37.429441523999969],
              [169.934689037000226, -37.416454524000017],
              [169.924258037000072, -37.403428524],
              [169.919417577000331, -37.397319033000031],
              [169.913906066000209, -37.390362452999966],
              [169.911669934000088, -37.387509707999982],
              [169.903634037000188, -37.377257523999958],
              [169.899756693000199, -37.372258021],
              [169.89344133200018, -37.364114268000037],
              [169.887495830000262, -37.356364354],
              [169.883329037000181, -37.350932523999973],
              [169.880022750000307, -37.34657598900003],
              [169.873296425000262, -37.337712109999984],
              [169.862669493000226, -37.325425908999932],
              [169.860568189000219, -37.322972146000041],
              [169.852115037000203, -37.313100523999935],
              [169.841633037000179, -37.300737523999942],
              [169.835672614000146, -37.293636547999938],
              [169.831223053000144, -37.288335359999962],
              [169.820886427000232, -37.275895539000032],
              [169.816776507000327, -37.270899059999977],
              [169.810623037000141, -37.263417524000033],
              [169.800433037000062, -37.250903524000023],
              [169.790316037000139, -37.238350523999941],
              [169.780272037000145, -37.225761523999893],
              [169.773300037000013, -37.217017523999914],
              [169.763376037000285, -37.204716524],
              [169.753523037000036, -37.192379523999989],
              [169.744692761000238, -37.181211238],
              [169.743741065000336, -37.180007498999942],
              [169.7325464500002, -37.167383746000041],
              [169.727953048000131, -37.162150566000037],
              [169.721429037000235, -37.154717523999977],
              [169.710388037000143, -37.142008523999948],
              [169.699425037000196, -37.129258524000022],
              [169.688539037000282, -37.116466524000032],
              [169.677731037000058, -37.103633524000031],
              [169.667001037000318, -37.090759523999964],
              [169.656349037000155, -37.077844524],
              [169.645776037000019, -37.064889523999966],
              [169.635281037000141, -37.051894523999977],
              [169.624865037000177, -37.038859523999974],
              [169.614528037000241, -37.02578452399996],
              [169.607498594000276, -37.016798650999931],
              [169.604270114000315, -37.012671348999959],
              [169.60161201800031, -37.009236574],
              [169.594092037000223, -36.999518524],
              [169.583993037000255, -36.986327523999933],
              [169.573974037000312, -36.973098523999965],
              [169.564035037000281, -36.959831524000037],
              [169.554176037000161, -36.946526523999978],
              [169.544397037000181, -36.933183523999958],
              [169.534699037000337, -36.919804524000028],
              [169.531200675000264, -36.914924712999948],
              [169.525081326000304, -36.906388401000015],
              [169.521302747000163, -36.90105826599995],
              [169.515545037000038, -36.892935524],
              [169.509386112000129, -36.884150459000011],
              [169.506089123000152, -36.879447210999956],
              [169.502263266000227, -36.873927624000032],
              [169.496715037000286, -36.865922523999927],
              [169.487422037000329, -36.852362524],
              [169.48117311600015, -36.843140414],
              [169.478210285000273, -36.83876757500002],
              [169.475428110000166, -36.834613983999986],
              [169.469081037000137, -36.825137524000013],
              [169.46003303700013, -36.81147252400001],
              [169.45106703700003, -36.797773524000036],
              [169.446326585000179, -36.790446206999945],
              [169.442183184000328, -36.784041091999939],
              [169.436069289000216, -36.774478069],
              [169.433382037000342, -36.770274524],
              [169.4246630370003, -36.756474523999941],
              [169.418883246000178, -36.752488610999933],
              [169.410045012000182, -36.746393159999968],
              [169.395489079000299, -36.736255351999944],
              [169.380995534000249, -36.726061554999944],
              [169.376558831000239, -36.722910373],
              [169.366565037000214, -36.715811523999889],
              [169.352197037000337, -36.705506523999986],
              [169.340938121000136, -36.697352565999893],
              [169.337892395000154, -36.695146636],
              [169.330216753000173, -36.689532895000013],
              [169.323652037000215, -36.684731524000028],
              [169.313959668000166, -36.677573514000017],
              [169.309474674000171, -36.674261194999929],
              [169.299873405000199, -36.66710095000002],
              [169.295362037000132, -36.663736524],
              [169.281314037000129, -36.653157524],
              [169.267331037000076, -36.642524523999953],
              [169.253413037000314, -36.631837524],
              [169.23956003700016, -36.621097524],
              [169.238097912000285, -36.619952915999932],
              [169.22577351800021, -36.610304286999906],
              [169.217584877000263, -36.603830983999956],
              [169.212053036000157, -36.599457523999945],
              [169.204967810000198, -36.593802314000015],
              [169.198398487000304, -36.588558495000029],
              [169.191483813000332, -36.58298521200004],
              [169.184811036000269, -36.577606523999975],
              [169.171290036000187, -36.566602523999961],
              [169.15783703600016, -36.555546524000022],
              [169.144451036000191, -36.544438523999929],
              [169.132245624000348, -36.534212515999968],
              [169.131132377000199, -36.533279735],
              [169.117882271000212, -36.522069325999951],
              [169.105724212000268, -36.511682524999969],
              [169.104700036000281, -36.51080752300004],
              [169.091587036000305, -36.49949552299995],
              [169.087473807000038, -36.495912687999976],
              [169.078543243000126, -36.488133513999969],
              [169.072505786000193, -36.482823069],
              [169.065568036000059, -36.476720522999983],
              [169.05266303600024, -36.465258522999932],
              [169.04103999400013, -36.454834368000036],
              [169.039826931000221, -36.45374640499989],
              [169.039098355000306, -36.453086566000025],
              [169.027061036000191, -36.44218452299998],
              [169.014366036000183, -36.430574522999962],
              [169.00174103600034, -36.41891552300001],
              [168.999327744000084, -36.416665038999952],
              [168.989186431000235, -36.407207448999941],
              [168.976703290000046, -36.395451397999977],
              [168.964291462000233, -36.383647370999938],
              [168.951951202000259, -36.371795643000041],
              [168.950088386000289, -36.369988898999964],
              [168.939683036000133, -36.359896522999975],
              [168.936616139000307, -36.356892738999932],
              [168.927486401000237, -36.34795018899996],
              [168.917782487000181, -36.338351020999937],
              [168.915362036000261, -36.335956523],
              [168.908372417000237, -36.328973863999977],
              [168.903310895000345, -36.323917259000041],
              [168.891332245000171, -36.311831190000021],
              [168.879426656000192, -36.299699093000015],
              [168.867594370000347, -36.287521249999884],
              [168.862620275000268, -36.282350639000015],
              [168.855836036000142, -36.275297522999949],
              [168.844151036000198, -36.263029523],
              [168.835262876000229, -36.25360398600003],
              [168.832539713000187, -36.250716078999915],
              [168.821003013000109, -36.238358090999952],
              [168.815990762000297, -36.232934754000041],
              [168.809541036000269, -36.225955522999982],
              [168.802697082000236, -36.218475733],
              [168.798153279000303, -36.213509438999949],
              [168.790864999000178, -36.205462529999977],
              [168.786841036000169, -36.201019523000014],
              [168.775603161500271, -36.188485663999984],
              [168.764441255000179, -36.175909093999962],
              [168.756095942000229, -36.16640969599996],
              [168.75335503600013, -36.163289522999946],
              [168.749389071000337, -36.158728897999936],
              [168.742344223000259, -36.150627338],
              [168.738666998000213, -36.146354908999967],
              [168.73141003600017, -36.137922522999929],
              [168.724565096000106, -36.129888118],
              [168.720551348000185, -36.125176420999935],
              [168.709769505000281, -36.112388261000035],
              [168.699064339000188, -36.099558692999892],
              [168.695892828000297, -36.095718042999977],
              [168.688436036000212, -36.086687523000023],
              [168.677885036000134, -36.073776523],
              [168.667411036000203, -36.060824523],
              [168.657015036000303, -36.047832523000011],
              [168.650633733000177, -36.039773493000041],
              [168.646695880000038, -36.034800123000025],
              [168.636455076000175, -36.021728350999979],
              [168.626292363000175, -36.008617259],
              [168.616207934000045, -35.995467147999989],
              [168.606201980000151, -35.982278321000024],
              [168.597273542000153, -35.970381959],
              [168.596274865000282, -35.969051300999865],
              [168.586426255000333, -35.955785727999967],
              [168.584060335000345, -35.95256401499995],
              [168.576657036000142, -35.942482522999967],
              [168.566967036000278, -35.929141522999942],
              [168.559620398000106, -35.918916159],
              [168.55735589800031, -35.915764056999933],
              [168.552522447000342, -35.908961186999932],
              [168.54782503600029, -35.902349523],
              [168.538373036000166, -35.888897523000026],
              [168.529002036000179, -35.875410523000028],
              [168.522776271000339, -35.866349260999982],
              [168.519710335000127, -35.86188681699997],
              [168.51049920000014, -35.848327590999929],
              [168.501368505000187, -35.834733017000019],
              [168.492318419000298, -35.82110340600002],
              [168.48334910400024, -35.807439066999962],
              [168.477376530000129, -35.798234149],
              [168.474461036000321, -35.79374052299994],
              [168.471914370000036, -35.789769886999977],
              [168.465653433000284, -35.780007455000032],
              [168.456927395000207, -35.766240806000042],
              [168.454090696000208, -35.761712354],
              [168.448283036000021, -35.752440522999962],
              [168.439720036000267, -35.738607522999985],
              [168.436604620000253, -35.733514578999973],
              [168.431238327000074, -35.724741250999955],
              [168.425777901000174, -35.715705874999969],
              [168.422839036000141, -35.710842523],
              [168.418196537000313, -35.703067257999976],
              [168.41452132700033, -35.696911686000021],
              [168.411072195000202, -35.691063779999979],
              [168.406286036000211, -35.68294852299988],
              [168.398133036000331, -35.668954523000025],
              [168.393842141000164, -35.661497689999976],
              [168.390062317000229, -35.654928863],
              [168.382074275000264, -35.640872262999949],
              [168.3741689430002, -35.62678503099994],
              [168.366346456000201, -35.612667484999974],
              [168.361872050000215, -35.604488436999929],
              [168.358607036000194, -35.598519523],
              [168.350951036000197, -35.584342522999975],
              [168.34337703600022, -35.570135522999976],
              [168.335879036000165, -35.555926523000039],
              [168.333637919000239, -35.551623796],
              [168.328463514000305, -35.541688296000018],
              [168.324548724000238, -35.534070805999932],
              [168.321132036000336, -35.527421522999987],
              [168.31388303600022, -35.513126522999983],
              [168.311249307000253, -35.507862005],
              [168.306718181000292, -35.498804372999984],
              [168.300251540000204, -35.485699845999989],
              [168.299637036000149, -35.484454522999897],
              [168.292640036000307, -35.470077522999986],
              [168.286403158000098, -35.457084178999935],
              [168.285726132000036, -35.455673632999947],
              [168.284743590000289, -35.453597568000021],
              [168.278897036000217, -35.441243521999979],
              [168.274741379000034, -35.432337753000013],
              [168.272151416000042, -35.426786986000025],
              [168.265490374000137, -35.412304786],
              [168.261721570000191, -35.403991090000034],
              [168.258914036000078, -35.397797521999962],
              [168.257347706000132, -35.39429167399993],
              [168.25242143700018, -35.383264253],
              [168.248426156000278, -35.374187346999975],
              [168.246014036000133, -35.368706522000025],
              [168.239691036000295, -35.354124522],
              [168.235565069000245, -35.344464642],
              [168.233452383000213, -35.339517998999952],
              [168.231055550000065, -35.333819393999931],
              [168.227299036000318, -35.32488752200004],
              [168.223351236000326, -35.315355300999954],
              [168.221230353000237, -35.310233991999951],
              [168.219864151000252, -35.306882847999987],
              [168.215247036000164, -35.295556522000027],
              [168.212473990000262, -35.288646706999941],
              [168.209348367000189, -35.280857160999972],
              [168.203660707500148, -35.266452819999927],
              [168.203076517000113, -35.264954284999931],
              [168.197807110000326, -35.251390124000011],
              [168.192164459000196, -35.236623598],
              [168.186607232000142, -35.221835503999898],
              [168.181135505000157, -35.207026171],
              [168.17574935100015, -35.192195927999933],
              [168.171500692000222, -35.18029214500001],
              [168.17044903600015, -35.177345521999939],
              [168.169454815000222, -35.174510422000026],
              [168.165234053000177, -35.162474029],
              [168.160105047000229, -35.147583031000011],
              [168.157068608000031, -35.138605499],
              [168.155062036000345, -35.132672522],
              [168.150105036000127, -35.117742521999958],
              [168.147102284000198, -35.108529029999943],
              [168.145233383000232, -35.102793808],
              [168.140448154000296, -35.087826423999957],
              [168.137558999000248, -35.078612838999959],
              [168.135749035000231, -35.072840521999979],
              [168.133591452000132, -35.065823347],
              [168.131136035000168, -35.057837180000035],
              [168.126609255000176, -35.04281598],
              [168.12563349200002, -35.039511416999972],
              [168.122169035000184, -35.027777521999951],
              [168.12072473600017, -35.022784658000035],
              [168.117814513000326, -35.01272208599994],
              [168.114814392000312, -35.002127109000014],
              [168.113547035000352, -34.997650522000015],
              [168.110437756000238, -34.986431985000024],
              [168.109365185000229, -34.982561741999973],
              [168.10527016500032, -34.967457481999986],
              [168.104279562000329, -34.963721004000035],
              [168.101262035000332, -34.952337521999937],
              [168.097340035000343, -34.937202522],
              [168.094745539000087, -34.926955749999877],
              [168.09350417800033, -34.92205233899989],
              [168.089755333000198, -34.906887612999981],
              [168.086093121000204, -34.891708601999937],
              [168.085662250000155, -34.889877774999889],
              [168.082518035000248, -34.876515521999977],
              [168.07902903500019, -34.861309521999942],
              [168.075627035000252, -34.846089521999886],
              [168.073561699000265, -34.836601803],
              [168.072311224000259, -34.830856375999986],
              [168.071328263000169, -34.826214839999977],
              [168.069083035000176, -34.815610521999929],
              [168.065941035000151, -34.800353522000023],
              [168.062886035000133, -34.785083522000036],
              [168.059918035000237, -34.769802521999964],
              [168.058465791000344, -34.762096354],
              [168.057036375000166, -34.754509696999946],
              [168.05451876300026, -34.740722185000038],
              [168.054242035000129, -34.739206522000018],
              [168.054034286000274, -34.73803168],
              [168.051534382000341, -34.723892044999957],
              [168.050073900000257, -34.715351840999972],
              [168.048914035000166, -34.708567521999896],
              [168.046380035000084, -34.693233522000014],
              [168.04542450100007, -34.687241809999932],
              [168.043933079000112, -34.677889295],
              [168.041573122000159, -34.66253604100001],
              [168.039300057000332, -34.647173682499954],
              [168.037114035000286, -34.631803522000041],
              [168.035015035000129, -34.616423522],
              [168.033966729000326, -34.608410461],
              [168.033002421000219, -34.601036921],
              [168.03107702650027, -34.585642403],
              [168.029238511000017, -34.57024037399998],
              [168.027873691000082, -34.55823413600001],
              [168.027487035000263, -34.554831522000029],
              [168.026743231000182, -34.547945188000028],
              [168.025822159000199, -34.539416066],
              [168.02424429000007, -34.52399433599993],
              [168.022753275000241, -34.508566668999947],
              [168.021349096000336, -34.493133396000019],
              [168.02111299600017, -34.490366466999944],
              [168.020032035000327, -34.477694521999979],
              [168.019195884000169, -34.467204932999891],
              [168.018801177000341, -34.462251374],
              [168.017657396000232, -34.446803289999934],
              [168.01660036900023, -34.431350936999934],
              [168.015630072000135, -34.415894647999949],
              [168.014746477000131, -34.400434756000038],
              [168.014371402000165, -34.393156941999962],
              [168.013950035000221, -34.384971521999944],
              [168.013476974000014, -34.374681263000014],
              [168.013239277000139, -34.369505495999988],
              [168.013016745000186, -34.36398608899998],
              [168.012616035000292, -34.354036521000012],
              [168.012357212000097, -34.346593674999951],
              [168.012078516000315, -34.33856582300001],
              [168.01169657600019, -34.325449243999969],
              [168.011628035000285, -34.323092521000035],
              [168.011264035000181, -34.307618520999938],
              [168.011180220000256, -34.302952607999885],
              [168.010986320000143, -34.292142609999928],
              [168.010795150000149, -34.276665882999964],
              [168.010690357000158, -34.261188547000017],
              [168.010677545000135, -34.25044852000002],
              [168.010672035000198, -34.245710521000035],
              [168.01074003500014, -34.230233520999946],
              [168.010894035000092, -34.21475652099997],
              [168.011140413500243, -34.198976740999967],
              [168.011460391000242, -34.183804360999943],
              [168.011725067000157, -34.173874492999971],
              [168.011873035000235, -34.16833052100003],
              [168.012083666000194, -34.161786584000026],
              [168.01237132000017, -34.152858035999941],
              [168.012664315000166, -34.145102406000035],
              [168.012956035000343, -34.137387520999937],
              [168.013457923000146, -34.125800653999875],
              [168.013626108000153, -34.121919881999929],
              [168.014382299000204, -34.106454695000025],
              [168.015224271000079, -34.090992542000038],
              [168.015963144000239, -34.078680142999929],
              [168.016152035000118, -34.075533521000011],
              [168.016331972000216, -34.07278828699998],
              [168.017165301000318, -34.060078654],
              [168.018264223000074, -34.044627577999961],
              [168.018682214000137, -34.039176378999969],
              [168.019449035000207, -34.029180520999887],
              [168.019957888000135, -34.022988480000038],
              [168.020718535000128, -34.013738806999982],
              [168.021655040000269, -34.003071452000029],
              [168.022074035000173, -33.998301520999973],
              [168.022786455000187, -33.990667256],
              [168.023514316000274, -33.982870067000022],
              [168.024954091000268, -33.968313314999932],
              [168.025040035000302, -33.967444521],
              [168.026651035000214, -33.952023520999944],
              [168.028347035000138, -33.936610520999977],
              [168.030128035000075, -33.921203520999939],
              [168.031994035000139, -33.905803521],
              [168.033296086000291, -33.895525335999935],
              [168.033944286000292, -33.890410253000027],
              [168.034591416000126, -33.885518601999934],
              [168.035980035000193, -33.875025521],
              [168.038100035000298, -33.859647520999943],
              [168.039659996000182, -33.848769555999979],
              [168.04030414000033, -33.844278871000029],
              [168.042593116000148, -33.828918510999983],
              [168.044966450000288, -33.813567077000016],
              [168.047424046000174, -33.79822489299994],
              [168.047967027000141, -33.794949484000028],
              [168.049966035000125, -33.782892520999951],
              [168.052592035000202, -33.767569520999984],
              [168.05406882900013, -33.759222286999886],
              [168.055301438000242, -33.752257074999932],
              [168.058081615666993, -33.737029009666685],
              [168.060972532000164, -33.721664039999951],
              [168.062311457000192, -33.714754877000018],
              [168.063934035000329, -33.706384520999961],
              [168.065891915000179, -33.696563604999938],
              [168.066978261000145, -33.691115754999942],
              [168.070106346000216, -33.675859198000012],
              [168.071818011000232, -33.667734033999977],
              [168.07331803500017, -33.660614520999957],
              [168.074372281000137, -33.655739516999972],
              [168.076612411000042, -33.645382856999902],
              [168.079990166000016, -33.630163713],
              [168.082362367000087, -33.619740614000023],
              [168.08345103500028, -33.614957520999951],
              [168.0869950350002, -33.599765521],
              [168.090621035000254, -33.584586520999949],
              [168.094330035000212, -33.569421521000038],
              [168.098122035000188, -33.554271520999976],
              [168.099364206000246, -33.549417017],
              [168.10032480700022, -33.545662910000033],
              [168.101995533000263, -33.539135340999962],
              [168.104670627000246, -33.528911205999975],
              [168.1059520350002, -33.524014521000026],
              [168.108745191000253, -33.513566128999969],
              [168.109990186000118, -33.508909315999972],
              [168.11079339600019, -33.505967828999871],
              [168.114111035000292, -33.493819521000034],
              [168.118234433000225, -33.479027521999967],
              [168.122597040500182, -33.463688484999921],
              [168.126963035000131, -33.448647520999941],
              [168.130890934000149, -33.435377283000037],
              [168.131410024000161, -33.433623661999945],
              [168.131880152000349, -33.432065773000033],
              [168.13593903500032, -33.418616520999933],
              [168.137487696000335, -33.413580098999944],
              [168.14054848700016, -33.403627765999943],
              [168.145239435000349, -33.38865643500003],
              [168.150011342000198, -33.373703264000028],
              [168.154864069000155, -33.358768564999934],
              [168.15979747300014, -33.343852649000041],
              [168.164811412000176, -33.32895582499998],
              [168.167819467000299, -33.320171136999932],
              [168.169906036000157, -33.314078520999942],
              [168.172469502000126, -33.306717099999958],
              [168.175080315000059, -33.299220695000031],
              [168.180334985000172, -33.284383006999974],
              [168.181633638000221, -33.280775885999958],
              [168.185670036000261, -33.269565519999958],
              [168.189718644000209, -33.258500271999935],
              [168.1910840190003, -33.254768922000039],
              [168.194249711000225, -33.24625507799999],
              [168.196578036000233, -33.239993519999985],
              [168.202152036000257, -33.225238519999948],
              [168.204475830000206, -33.219181114000037],
              [168.207804523000163, -33.21050563099999],
              [168.21093125300024, -33.202481013000011],
              [168.213537036000275, -33.195794519999936],
              [168.21934803600027, -33.181105520000017],
              [168.225238036000292, -33.166439519999983],
              [168.229521727000247, -33.155928399],
              [168.231206320000297, -33.151795365],
              [168.237253530000203, -33.137174726999945],
              [168.2433791200001, -33.122577466999928],
              [168.249582925000141, -33.10800388499996],
              [168.250310883000338, -33.106317838],
              [168.255865036000046, -33.093454520000023],
              [168.258071648000168, -33.088413908999939],
              [168.262224519000142, -33.078928958999938],
              [168.267192419000139, -33.06773846699997],
              [168.268662036000137, -33.064428519999922],
              [168.275177036000287, -33.049952519999977],
              [168.277082022000172, -33.045776402999934],
              [168.281769335000149, -33.035501657999873],
              [168.286334545000159, -33.025627836999945],
              [168.288439036000341, -33.021076519999951],
              [168.289761613000223, -33.018253348999963],
              [168.29518550000023, -33.006676997999932],
              [168.301611055000308, -32.993141764000029],
              [168.3020090360003, -32.99230352],
              [168.304578524000249, -32.986960845999931],
              [168.308909065000194, -32.97795660700001],
              [168.309807953000131, -32.976111529000036],
              [168.315886036000052, -32.963636519999966],
              [168.322939036000093, -32.949342520000016],
              [168.330068036000171, -32.935076519999939],
              [168.33727303600017, -32.920838519999961],
              [168.341761688000133, -32.912076399999989],
              [168.344553502000281, -32.906627287],
              [168.350253900000212, -32.895637258999955],
              [168.351910036000163, -32.892444520000026],
              [168.359342036000271, -32.878290519999894],
              [168.36684903600019, -32.864164520000031],
              [168.374431036000146, -32.850068519999979],
              [168.37742860200035, -32.844560452999986],
              [168.38208753400022, -32.836001003999911],
              [168.388764891000164, -32.823877258999971],
              [168.389819036000063, -32.82196352],
              [168.39762503600025, -32.80795552],
              [168.399348149000275, -32.804898962000038],
              [168.405505196000149, -32.79397776199994],
              [168.413459471000237, -32.780030493000027],
              [168.419077461000143, -32.770291869999937],
              [168.421488036000255, -32.766113519999976],
              [168.424424081000211, -32.761081178999973],
              [168.429589427000224, -32.752228383999977],
              [168.432491222000294, -32.747310848000041],
              [168.437765036000201, -32.738374519999979],
              [168.444155854000314, -32.727664011],
              [168.446013270000321, -32.724551403999953],
              [168.450810176000232, -32.716601825999987],
              [168.454335036000202, -32.710760519999951],
              [168.455860510000093, -32.708260043999985],
              [168.462729399000295, -32.697001813999975],
              [168.467197592000048, -32.689758320999942],
              [168.471197036000262, -32.68327552],
              [168.476058296000247, -32.675479513000028],
              [168.479736192000132, -32.66958186100004],
              [168.485693676000324, -32.660131756999988],
              [168.488348036000161, -32.65592152],
              [168.493163351000248, -32.648364734999916],
              [168.497032006000154, -32.642293777999953],
              [168.504510141000083, -32.630683497999883],
              [168.50578803600024, -32.628699519999941],
              [168.506324718000201, -32.627875070999949],
              [168.514615182000171, -32.615139788999954],
              [168.523514006000141, -32.601613771000018],
              [168.526505744000247, -32.597113950999926],
              [168.532484036000142, -32.588122520000013],
              [168.541525036000166, -32.57466552],
              [168.548016067000162, -32.565103502],
              [168.550636880000184, -32.561242915999969],
              [168.559819258000175, -32.547855941999956],
              [168.567272191000029, -32.537101485999983],
              [168.569072036000193, -32.534504519999984],
              [168.578395036000302, -32.52118852000001],
              [168.58778803600012, -32.507908519999944],
              [168.597072186000219, -32.494914438499848],
              [168.606782578000207, -32.481457776499951],
              [168.61995021000007, -32.468201278000038],
              [168.629545140000346, -32.45730534],
              [168.64157946000023, -32.44624677600001],
              [168.65312590200017, -32.43632659299999],
              [168.665485474000207, -32.425918533000015],
              [168.677790070000128, -32.417455239],
              [170.550153889000285, -31.316633598999942],
              [170.583487222000031, -31.299966927000014],
              [170.766820556000084, -31.183300212999939],
              [171.201188452800125, -30.919787477],
              [171.217817258000309, -30.910325166],
              [171.231689680000272, -30.903262843],
              [171.24833658600005, -30.89620051899999],
              [171.264479040000197, -30.88964264699996],
              [171.281125946000202, -30.883337],
              [171.297016175000181, -30.877283580000039],
              [171.313158629000213, -30.871482386000025],
              [171.332263096500242, -30.865385692999951],
              [171.349827038000058, -30.862199517999954],
              [171.362131047000162, -30.860016446999964],
              [171.367405807000296, -30.859080739999911],
              [171.373999797000238, -30.857937460000016],
              [171.385000039000147, -30.856030517999955],
              [171.397553600000151, -30.853903919999937],
              [171.402608997000129, -30.853047646999983],
              [171.420232876000227, -30.85013300199995],
              [171.433935078000104, -30.847921609999958],
              [171.437871039000129, -30.847286517999976],
              [171.442677792000154, -30.846529778999979],
              [171.455523494000204, -30.844507798999928],
              [171.465960633000236, -30.842906461000041],
              [171.469548387000032, -30.842356122999888],
              [171.473190039000315, -30.841797518000021],
              [171.490869039000245, -30.839155517999927],
              [171.508561039000142, -30.836581517999946],
              [171.526267039000231, -30.834075517999963],
              [171.527248588000134, -30.833940449000011],
              [171.535952585000132, -30.832742709999934],
              [171.543984128000034, -30.831637784],
              [171.56171364800025, -30.829268695999986],
              [171.565189248000024, -30.828817980999986],
              [171.579455039000095, -30.826968518],
              [171.580829214000346, -30.826795662999928],
              [171.590311805000056, -30.82560286],
              [171.597207342000161, -30.824735810999925],
              [171.604800755000042, -30.823810883999954],
              [171.614971039000238, -30.822572518000015],
              [171.624180537000143, -30.821486488],
              [171.632744955000021, -30.820476937999956],
              [171.646507561000135, -30.818908655999962],
              [171.650529039000162, -30.818450517999906],
              [171.668324039000254, -30.816492518000018],
              [171.686128039000209, -30.814603518000013],
              [171.703941001000203, -30.812782522999953],
              [171.721763124000177, -30.811030674],
              [171.739593861000344, -30.80934758799998],
              [171.742948124000122, -30.809044054000026],
              [171.757433039000034, -30.80773351800002],
              [171.765120626000112, -30.807067578999977],
              [171.775279689000172, -30.806187854000029],
              [171.785174422000068, -30.805369543999916],
              [171.793134039000051, -30.80471151799992],
              [171.809202467000318, -30.803444898],
              [171.810995693000223, -30.803303570999958],
              [171.828864152000278, -30.801964794999947],
              [171.846739121000155, -30.800694962999955],
              [171.856181347000188, -30.800060841999937],
              [171.864620039000101, -30.799494518000017],
              [171.882507039000188, -30.798362518000033],
              [171.900400039000033, -30.797299517999932],
              [171.914084300000127, -30.796539493999944],
              [171.918297223000081, -30.796305558000029],
              [171.93619958100021, -30.795380793],
              [171.943139376000204, -30.795049168999981],
              [171.95410603900018, -30.794525518],
              [171.972017160500286, -30.793738508999979],
              [171.989931936000289, -30.793021000999943],
              [172.007849997000221, -30.792372621000027],
              [172.015274396000336, -30.792132649],
              [172.025771039000119, -30.791793517999906],
              [172.043695039000227, -30.791283518],
              [172.061621039000215, -30.79084251799992],
              [172.079409919000113, -30.790473406666706],
              [172.097479356000122, -30.790167888999974],
              [172.112015051000185, -30.78997864400003],
              [172.115411039000151, -30.789934517999939],
              [172.133343039000238, -30.789770517999955],
              [172.151276039000209, -30.789675517999967],
              [172.169209039000179, -30.789649517999976],
              [172.187142039000037, -30.789692517999981],
              [172.190587938000135, -30.789714038999989],
              [172.205074930000251, -30.789804886999946],
              [172.223007005500108, -30.789986521499941],
              [172.2236619170003, -30.789995685],
              [172.240938039000298, -30.790237518000026],
              [172.258867039000279, -30.790557517999957],
              [172.276795039000035, -30.790946518],
              [172.279389888000082, -30.791012814999988],
              [172.294720894000079, -30.791404787999966],
              [172.303740261000144, -30.791670219000025],
              [172.312644039000247, -30.791932517999953],
              [172.320909991000235, -30.792207434999966],
              [172.330564477000053, -30.792528849],
              [172.348481716000265, -30.793194577],
              [172.355370598000036, -30.793477166999935],
              [172.366395039000167, -30.79392951799997],
              [172.384305039000196, -30.794733517999987],
              [172.402211039000235, -30.795606518],
              [172.420112039000287, -30.796548517999952],
              [172.424081277000255, -30.796772739999938],
              [172.438008584000158, -30.797559636999949],
              [172.455899678000264, -30.798639813999955],
              [172.473785208000209, -30.799789001000022],
              [172.484316367000019, -30.80050651],
              [172.491665040000271, -30.801007517999949],
              [172.50953804000028, -30.80229451799994],
              [172.527405040000076, -30.803650517999984],
              [172.545265040000118, -30.805075518000024],
              [172.563117040000179, -30.806569518],
              [172.570265230000018, -30.807195209999975],
              [172.58096224700023, -30.808131977999949],
              [172.598799111500171, -30.809763525499989],
              [172.61662780000006, -30.81146387900003],
              [172.634447755000224, -30.813232988000038],
              [172.652258703000257, -30.815070822999871],
              [172.663515630000234, -30.816276422999977],
              [172.670060040000067, -30.816977517999987],
              [172.687852122500203, -30.818952523499831],
              [172.705634040000064, -30.820996517999959],
              [172.713853428000107, -30.821973298999978],
              [172.723405562000039, -30.823108692999938],
              [172.741166327000201, -30.82528959900003],
              [172.758916026000065, -30.827538997000033],
              [172.776654316000077, -30.829856845],
              [172.784129252000071, -30.830863081000018],
              [172.794381040000303, -30.832243517999984],
              [172.8072119320002, -30.834021039],
              [172.812095299000021, -30.83469770499994],
              [172.829797307000263, -30.837220624],
              [172.847486536000247, -30.839811803999964],
              [172.855328069000052, -30.840991570999961],
              [172.865163040000198, -30.842471518000025],
              [172.87549270500017, -30.844066410999915],
              [172.88282529200012, -30.845198743999902],
              [172.893135449000056, -30.846831919999943],
              [172.900474040000063, -30.84799451799995],
              [172.918109040000218, -30.850858517999939],
              [172.927962704000038, -30.852497626999934],
              [172.935729043000038, -30.853789813999981],
              [172.948068259000138, -30.855892196999946],
              [172.9533340400003, -30.856789517999971],
              [172.956717822000201, -30.857379482],
              [172.970924640000248, -30.859856983999919],
              [172.983045998000193, -30.862019447999955],
              [172.988499040000193, -30.862992518],
              [173.006058040000198, -30.866195517999984],
              [173.023601040000244, -30.869466518000024],
              [173.033699192000313, -30.871389810999972],
              [173.04112700200028, -30.872804686999984],
              [173.058636268000214, -30.8762107],
              [173.063456205000307, -30.87716782800004],
              [173.076128040000071, -30.879684517999976],
              [173.093603040000062, -30.883225517999961],
              [173.111059040000214, -30.886833518],
              [173.128498040000181, -30.890509517999931],
              [173.135779196000044, -30.892073588000017],
              [173.145917675000192, -30.894251953000037],
              [173.163318600000139, -30.898061951999978],
              [173.171924471000352, -30.89998153800002],
              [173.180700040000147, -30.901939518],
              [173.198062040000195, -30.905883517999939],
              [173.215405040000178, -30.909894517999987],
              [173.223130992000279, -30.911713045],
              [173.232726505000073, -30.913971982000021],
              [173.250027845000204, -30.918116611999949],
              [173.267308258000298, -30.92232793200003],
              [173.284567405000075, -30.926605859999938],
              [173.294492168000147, -30.92910724],
              [173.301805040000318, -30.930950517999975],
              [173.319021040000166, -30.935361518000036],
              [173.336214040000186, -30.939838517999988],
              [173.337069677000045, -30.940064896999957],
              [173.353384630000164, -30.944381966000037],
              [173.370532417000163, -30.948991658999944],
              [173.378321292000237, -30.951118382],
              [173.387657040000136, -30.953667517999975],
              [173.404758040000047, -30.958409518],
              [173.41048727000009, -30.960022237999965],
              [173.421834777000299, -30.963216909000039],
              [173.433294735000118, -30.96649206099994],
              [173.438887040000168, -30.968090517999883],
              [173.455915040000036, -30.973029517999976],
              [173.461345327000231, -30.974627882999982],
              [173.472918624000044, -30.978034463000029],
              [173.489896460000296, -30.983104824999941],
              [173.506848684000261, -30.98824061199997],
              [173.523774966000133, -30.993441724000036],
              [173.52958092200015, -30.995250960999954],
              [173.540675041000327, -30.998708517999944],
              [173.557548041000246, -31.004039517999956],
              [173.560331924000252, -31.004931180999961],
              [173.574394837000227, -31.009435993000011],
              [173.587829154000246, -31.013798270999985],
              [173.591214041000057, -31.014897518],
              [173.598274233000211, -31.017220924000014],
              [173.608005633000289, -31.02042357199997],
              [173.617554185000017, -31.023608131999936],
              [173.62476904100015, -31.026014518000011],
              [173.628333243000156, -31.027218842000025],
              [173.641504718000078, -31.03166994299994],
              [173.658211547000178, -31.037389902000029],
              [173.667561321000335, -31.040632642999981],
              [173.674889041000029, -31.043174518],
              [173.685342129000333, -31.04684619],
              [173.691538131000016, -31.049022809],
              [173.705191989000014, -31.053880560000039],
              [173.708157041000163, -31.054935517999922],
              [173.724746041000117, -31.060912518],
              [173.734339082000133, -31.064411641],
              [173.741305409000347, -31.066952931999936],
              [173.754708989000051, -31.071903277999979],
              [173.757834041000137, -31.073057517999928],
              [173.774331041000323, -31.07922551799993],
              [173.790798041000016, -31.085457517999942],
              [173.807232041000134, -31.091752517999964],
              [173.82363504100033, -31.098110518],
              [173.840006041000038, -31.104532517999928],
              [173.846351637000225, -31.107050716999936],
              [173.856344552000223, -31.111016893],
              [173.871650479000323, -31.117163099999971],
              [173.87265004100027, -31.117564518],
              [173.875033323000139, -31.118532652999988],
              [173.888922305000165, -31.124174841999945],
              [173.905161134000082, -31.13084789100003],
              [173.91796268100029, -31.136168829999974],
              [173.921366041000169, -31.137583517999985],
              [173.937537041000041, -31.144381517999989],
              [173.941543232000072, -31.145192707000035],
              [173.955010117000143, -31.147919798999965],
              [173.96143226200013, -31.149246274999939],
              [173.972465041000191, -31.151525518999904],
              [173.98990204100005, -31.155197518999955],
              [174.007321041000068, -31.158936518999951],
              [174.024720041000137, -31.162742518999949],
              [174.03649262200031, -31.165365119999933],
              [174.042100730000016, -31.16661464699996],
              [174.059461569000177, -31.170553726999913],
              [174.068440308000277, -31.172627735999924],
              [174.076802041000178, -31.174559518999956],
              [174.094123041000103, -31.178631519000021],
              [174.102188441000067, -31.180560688000014],
              [174.111423480000099, -31.182769828999938],
              [174.12870279900028, -31.186974555999939],
              [174.145960971500131, -31.191245505999973],
              [174.163197041000103, -31.195582519000027],
              [174.168718078000325, -31.196994607999954],
              [174.180412122000121, -31.199985673000015],
              [174.197604572000159, -31.204454745999954],
              [174.214774472000045, -31.208989692999978],
              [174.218506872000262, -31.209991136999975],
              [174.231921041000078, -31.213590518999979],
              [174.23946504200012, -31.215646130999971],
              [174.249045289000264, -31.218256857000029],
              [174.266145540000025, -31.222988895000015],
              [174.28111428200009, -31.227194317],
              [174.283222041000158, -31.227786518999949],
              [174.300274041000193, -31.232649519],
              [174.304463331000306, -31.233861922999949],
              [174.317301690000022, -31.237577730999988],
              [174.325201488000289, -31.239897824000025],
              [174.334304041000337, -31.24257151899991],
              [174.341929502000198, -31.244843257000014],
              [174.351281972000265, -31.247629946000018],
              [174.368233976000283, -31.252753660999929],
              [174.37532085900034, -31.254926123000018],
              [174.385160041000177, -31.257942519000039],
              [174.392631584000128, -31.260264885999959],
              [174.40206006200026, -31.263195810000013],
              [174.407400801000108, -31.264879125999926],
              [174.418933041000173, -31.268514518999893],
              [174.429286317000106, -31.271822010999983],
              [174.43578005500035, -31.273896909999962],
              [174.44472023700007, -31.276792421000039],
              [174.452599041000212, -31.279344518999949],
              [174.469391041000335, -31.284856518999959],
              [174.486155041000075, -31.290432518999978],
              [174.501323348000142, -31.295544209000013],
              [174.502891251000165, -31.296072626000011],
              [174.504662376000169, -31.296677346999957],
              [174.519599041000333, -31.301777518999984],
              [174.536277041000062, -31.307545518999916],
              [174.538028693000228, -31.308159069999988],
              [174.552926644000195, -31.313377848000016],
              [174.569546604000266, -31.319273937],
              [174.586136770000167, -31.325233677999961],
              [174.602696816000048, -31.331256956],
              [174.619226229000219, -31.337343585999918],
              [174.62076898600003, -31.337918651],
              [174.635725250000291, -31.343493651000017],
              [174.652192991000106, -31.349706830999935],
              [174.658369341000025, -31.352065281999955],
              [174.668629042000134, -31.355983518999949],
              [174.685034042000325, -31.362322518999974],
              [174.701406042000144, -31.368724518999954],
              [174.708043084000224, -31.371349929999951],
              [174.717746573000227, -31.375188945],
              [174.728666695000129, -31.379559861999951],
              [174.734054042000139, -31.381716518999895],
              [174.743318218000127, -31.385467388],
              [174.750328425000134, -31.388305902999946],
              [174.766569492000031, -31.394957913999917],
              [174.772648409000226, -31.397476209999979],
              [174.782777042000021, -31.401672518999931],
              [174.798950042000229, -31.40844851899999],
              [174.815089042000295, -31.415286518999949],
              [174.828781666000282, -31.421152963999987],
              [174.831193898000038, -31.422186483999951],
              [174.83190431800017, -31.422494250999932],
              [174.84726304200035, -31.429148519000023],
              [174.863298042000338, -31.43617151899997],
              [174.874941326000226, -31.441327228000034],
              [174.879296421000049, -31.443255742000019],
              [174.884816200000159, -31.445726674000014],
              [174.895259042000134, -31.450401518999968],
              [174.911186042000054, -31.457608518999962],
              [174.927076042000181, -31.464876519000029],
              [174.934376956000278, -31.468251343999952],
              [174.942929118000052, -31.472204851000015],
              [174.949681224000187, -31.475359465],
              [174.958745042000146, -31.479594518999932],
              [174.974524042000098, -31.487044518999937],
              [174.989087834000202, -31.493992874999947],
              [174.990264592000017, -31.494554330999961],
              [175.005967303000148, -31.502124845000026],
              [175.021631601000195, -31.509755458999962],
              [175.025052271000277, -31.511439038],
              [175.037257042000306, -31.517446519000032],
              [175.051948873000242, -31.524751432999949],
              [175.052843714000346, -31.525196389999962],
              [175.059822221000104, -31.528701990999878],
              [175.068391042000144, -31.533006518999969],
              [175.071163642000329, -31.534413469999947],
              [175.083898438000119, -31.54087591900003],
              [175.099366003000341, -31.548804776999987],
              [175.114793290000193, -31.556792824999945],
              [175.130179991000091, -31.564839906999964],
              [175.145525797000118, -31.572945866999987],
              [175.156910172000153, -31.579019186999972],
              [175.160830042000043, -31.581110519],
              [175.171643163000113, -31.58693613],
              [175.176093377000029, -31.58933376799996],
              [175.191418449000253, -31.597493809999946],
              [175.206701913000074, -31.605712468999968],
              [175.221943550000219, -31.613989632000028],
              [175.229498832000104, -31.618132997000032],
              [175.237143042000184, -31.622325518999958],
              [175.246171857000149, -31.62732511300004],
              [175.252300118000221, -31.630718823999985],
              [175.260597278000091, -31.635358471000018],
              [175.267414042000155, -31.639170519],
              [175.282486042000187, -31.647680519],
              [175.295804222000129, -31.655272802999946],
              [175.297513619000028, -31.656247310000026],
              [175.298823755000285, -31.657001407999985],
              [175.312498042000243, -31.664872519000028],
              [175.32743804200004, -31.673554518999936],
              [175.342334042000289, -31.682293519000041],
              [175.346634417000018, -31.684840388],
              [175.357185657000286, -31.691089717999887],
              [175.371992251000052, -31.699942834000012],
              [175.386753676000126, -31.708852610999941],
              [175.401469632000044, -31.717818873999988],
              [175.416139819000023, -31.726841447000027],
              [175.418784617000142, -31.728483346999951],
              [175.430764042000192, -31.73592051899999],
              [175.436137200000303, -31.739287128],
              [175.445341695000138, -31.745054816999925],
              [175.457489008000039, -31.752737593999939],
              [175.45987304200014, -31.754245519],
              [175.474357042000179, -31.763491518999956],
              [175.478428604000328, -31.766114611999967],
              [175.488793797000199, -31.772792740999932],
              [175.499163154000144, -31.779535431999932],
              [175.50318304200016, -31.782149518999972],
              [175.517525042000216, -31.79156151899997],
              [175.52039695000019, -31.793463532999965],
              [175.531817941000213, -31.801027748000024],
              [175.538177456000312, -31.805278437999903],
              [175.546063042000128, -31.810549518999949],
              [175.554175562000069, -31.816021297999953],
              [175.56025902600004, -31.82012478300004],
              [175.574406186000147, -31.829754849000039],
              [175.578942301000211, -31.83287082399994],
              [175.588504042000068, -31.839439518999939],
              [175.602552042000298, -31.849177519000023],
              [175.60868338600028, -31.853465824000011],
              [175.616550648000043, -31.858968962999882],
              [175.630196225000304, -31.868600757999957],
              [175.644396042000039, -31.87871351900003],
              [175.657096356000125, -31.887840464999954],
              [175.658243498000161, -31.888664920999986],
              [175.666771210000206, -31.894849234],
              [175.672039043000268, -31.898669518999952],
              [175.685784043000126, -31.908727519000024],
              [175.69947700500029, -31.918837490999977],
              [175.713118039000278, -31.928999961999978],
              [175.72670692700035, -31.939214621000019],
              [175.730362890000265, -31.941987463999965],
              [175.740243043000305, -31.94948151899996],
              [175.751331665000293, -31.957966568000018],
              [175.753727020000156, -31.959799612999973],
              [175.767157662000159, -31.97016953900004],
              [175.780534992000298, -31.980590814000038],
              [175.792379754000194, -31.989900760999944],
              [175.793859043000111, -31.991063518999965],
              [175.79500441100015, -31.991971786999954],
              [175.807128596000325, -32.001586583999938],
              [175.818445349000058, -32.010641276999934],
              [175.820344043000205, -32.012160518999963],
              [175.833505043000173, -32.022785518999967],
              [175.846286035000048, -32.028466776000016],
              [175.847425889000192, -32.028973500999939],
              [175.858562205000226, -32.033959764999963],
              [175.863044043000173, -32.035966519],
              [175.878627043000051, -32.043017518999932],
              [175.890320749000352, -32.048363127999949],
              [175.894175592000352, -32.05012547299998],
              [175.909688865500357, -32.057291439999972],
              [175.925166240000181, -32.064514655000039],
              [175.927393539000036, -32.065564797999969],
              [175.940608043000225, -32.071795519000034],
              [175.944903811000245, -32.07384134900002],
              [175.956013544000257, -32.079132934999961],
              [175.971382715000118, -32.086527652000029],
              [175.978387375000239, -32.089931920999959],
              [175.986715043000231, -32.093979519000015],
              [175.995899413000075, -32.098487642],
              [176.002010646000258, -32.101487555999938],
              [176.01726882700018, -32.109052467999902],
              [176.03248943500023, -32.116673834999972],
              [176.041810364000298, -32.121387284999969],
              [176.047672043000262, -32.124351519000015],
              [176.062816910000151, -32.132085443],
              [176.077923043000169, -32.139875518999986],
              [176.092990043000327, -32.147721519000015],
              [176.105457354000123, -32.154275795999979],
              [176.108018800000082, -32.155622530000016],
              [176.123007934000043, -32.163579636],
              [176.126528097000119, -32.165466347999939],
              [176.137957043000142, -32.171592518999944],
              [176.152867043000128, -32.179660519000038],
              [176.167737043000329, -32.187783518999879],
              [176.182566043000179, -32.195961518999979],
              [176.190516209000066, -32.20038711899997],
              [176.197354233000056, -32.204193920000023],
              [176.212149655666849, -32.212502632333269],
              [176.228228043000172, -32.219631518999961],
              [176.244319043000132, -32.22684252],
              [176.260372043000075, -32.234114519999935],
              [176.276388043000225, -32.241447519999952],
              [176.287519051000118, -32.246598196999969],
              [176.292366744000219, -32.248841411999877],
              [176.294062072000202, -32.249634220000019],
              [176.308308043000352, -32.256296520000035],
              [176.324210043000335, -32.263811519999933],
              [176.332139562000179, -32.26759759600003],
              [176.34007474100008, -32.271386825000036],
              [176.356009012333402, -32.279075639999974],
              [176.371686854000131, -32.286718587999985],
              [176.385086221000279, -32.293318126999964],
              [176.387434043000326, -32.294474519999937],
              [176.403141043000346, -32.302290519999929],
              [176.417858633000264, -32.309687827999937],
              [176.418808885000317, -32.310165476999941],
              [176.42128351100007, -32.311421991999907],
              [176.434436043000119, -32.318100519999973],
              [176.450022043000104, -32.32609452],
              [176.457744588000082, -32.330094883000015],
              [176.465568009000094, -32.334147795999968],
              [176.471450191000258, -32.337225554999975],
              [176.481072043000268, -32.342260520000025],
              [176.496535043000108, -32.350431520000029],
              [176.511956043000168, -32.35866152],
              [176.516803526000217, -32.361274079],
              [176.527334407000126, -32.366949819999945],
              [176.535509161000277, -32.37139903100001],
              [176.542670043000157, -32.37529652],
              [176.545637739000199, -32.376927452000018],
              [176.557963827000094, -32.383701783999982],
              [176.573214009000139, -32.392164934999983],
              [176.588420763000158, -32.40068597699991],
              [176.603583780000235, -32.409264744999973],
              [176.61220323100008, -32.414188384999932],
              [176.61870304300021, -32.41790152],
              [176.630630233000147, -32.424779790999935],
              [176.633777378000218, -32.426594787],
              [176.648807348000332, -32.435345720999919],
              [176.663792200500097, -32.444153611499985],
              [176.678732074000209, -32.453018539499908],
              [176.693626043000222, -32.461940519999928],
              [176.708475043000135, -32.47091852],
              [176.710121920000233, -32.471923645999937],
              [176.723276726000108, -32.479952641999887],
              [176.729174723000256, -32.483586257999889],
              [176.738032044000079, -32.489043519999939],
              [176.748408241000334, -32.495495399999982],
              [176.752741272000321, -32.498189862],
              [176.759146702000066, -32.502210203999937],
              [176.767403044000162, -32.50739252],
              [176.782017044000213, -32.516650519999928],
              [176.792253172000187, -32.523194698999987],
              [176.796584273000349, -32.525963866000041],
              [176.811103058000185, -32.535332654999934],
              [176.825573572000167, -32.544756532999941],
              [176.831123407000291, -32.548404145999939],
              [176.839995044000261, -32.554235519999963],
              [176.846728680000183, -32.558701343999985],
              [176.854368600000043, -32.563768814999946],
              [176.868692282000211, -32.573356682999929],
              [176.882967044000225, -32.58299952],
              [176.888243076000094, -32.586595752],
              [176.897191707000275, -32.592695741999933],
              [176.903767848000143, -32.597219342999949],
              [176.911366044000033, -32.60244652],
              [176.921738712000092, -32.609646069999883],
              [176.925490722000063, -32.612250486999983],
              [176.929577872000323, -32.615113302999987],
              [176.939564044000235, -32.62210852000004],
              [176.953587044000187, -32.632019520000028],
              [176.955715409000163, -32.633537343999933],
              [176.967558810000014, -32.641983951999975],
              [176.980263637000235, -32.651126810999983],
              [176.981479044000139, -32.65200152],
              [176.995347044000027, -32.662071519999984],
              [177.009163044000275, -32.67219452],
              [177.022927044000312, -32.68236952],
              [177.030676195000297, -32.688149591999945],
              [177.036638456000219, -32.692596932999948],
              [177.050296718000027, -32.702876320999962],
              [177.063901756000064, -32.713207502999978],
              [177.068257507000112, -32.71654474799999],
              [177.077453044000094, -32.723590519999959],
              [177.090951044000235, -32.734024519999934],
              [177.092516733000252, -32.735245604],
              [177.104394684000226, -32.744509770000036],
              [177.117783904000021, -32.755045987999949],
              [177.132507764000025, -32.76403145],
              [177.134390575000111, -32.765191155000025],
              [177.147185044000139, -32.773072519999971],
              [177.156506242000205, -32.778866417000017],
              [177.161817002000134, -32.782167891999961],
              [177.176401889000317, -32.791318582000031],
              [177.190939895000042, -32.800524014999951],
              [177.205430727000021, -32.809784014],
              [177.217296714000099, -32.817436272999942],
              [177.219874044000221, -32.819098520000011],
              [177.222455693000029, -32.820778493000034],
              [177.23426970200012, -32.82846699299995],
              [177.247652807000122, -32.837256216999947],
              [177.248617044000184, -32.837889520000033],
              [177.262916044000235, -32.84736652],
              [177.277167044000066, -32.856896519999964],
              [177.282990530000347, -32.860825998999928],
              [177.291368747000206, -32.86647980500004],
              [177.305521213000134, -32.876116698999965],
              [177.319624186000283, -32.885806697999953],
              [177.324808106000205, -32.88940065],
              [177.333677044000183, -32.895549519999975],
              [177.347680044000072, -32.905345519999969],
              [177.361633044000314, -32.915193519999946],
              [177.369052738000278, -32.92047729],
              [177.375535402000196, -32.925093980999975],
              [177.389386612000294, -32.935046671999942],
              [177.403186615000209, -32.945051328000034],
              [177.41693513000007, -32.955107753999982],
              [177.430631874000028, -32.965215756000021],
              [177.443325348000144, -32.974666891999959],
              [177.44427704400016, -32.975375519999886],
              [177.445385722000083, -32.976208334000034],
              [177.457868925000298, -32.985585700999934],
              [177.471408857500251, -32.995847384499939],
              [177.484895044000041, -33.00615952000004],
              [177.498329044000343, -33.016522520000024],
              [177.511709044000327, -33.026935519999881],
              [177.525036044000103, -33.037399519999951],
              [177.538308044000132, -33.047912520000011],
              [177.5400981680001, -33.049342966999973],
              [177.551526706000118, -33.058475981000015],
              [177.564690217500129, -33.069088651999948],
              [177.565511529000332, -33.069756661999975],
              [177.577799251000101, -33.07975095],
              [177.584791631000257, -33.085488575999932],
              [177.590853044000255, -33.09046252],
              [177.603851044000066, -33.101222519999965],
              [177.616794044000244, -33.11203152],
              [177.629681044000108, -33.12288952],
              [177.634350805000281, -33.126858325],
              [177.64251165900032, -33.133794914000021],
              [177.655285868000192, -33.144748861000039],
              [177.665954792000321, -33.153978492999954],
              [177.668003044000329, -33.155750520000026],
              [177.680664044000196, -33.166800520000038],
              [177.693267044000208, -33.177897519999988],
              [177.704689283000221, -33.188043337999943],
              [177.705812848000051, -33.189041385999928],
              [177.718300798000314, -33.200232621999987],
              [177.730730701000311, -33.211470664999979],
              [177.736942106000129, -33.217136337999989],
              [177.743102044000352, -33.222755520000021],
              [177.755415044000188, -33.23408652],
              [177.767669044000172, -33.245463519999873],
              [177.774545392000277, -33.251904034999939],
              [177.779864591000319, -33.256886495],
              [177.785115945000143, -33.261849238000025],
              [177.792000044000133, -33.26835552],
              [177.80407604400034, -33.279869520000034],
              [177.81561904500029, -33.290913519999947],
              [177.820016741000245, -33.295156959],
              [177.827107708000312, -33.301999352],
              [177.833913435000341, -33.308622766999918],
              [177.838541045000284, -33.313126520999958],
              [177.843984418000105, -33.318469419],
              [177.849918320000256, -33.32429390599998],
              [177.854320874000223, -33.328652406999936],
              [177.861240045000045, -33.335502520999967],
              [177.869881684000347, -33.344131119999986],
              [177.872506221000208, -33.346751751],
              [177.875180718000024, -33.34944528099993],
              [177.883716045000256, -33.358041521000018],
              [177.889151629000025, -33.363562873999967],
              [177.894869628000038, -33.369371275],
              [177.905966592000027, -33.380741157999978],
              [177.917006769000182, -33.39215085],
              [177.924675982000281, -33.400145542999951],
              [177.927990045000342, -33.403600520999987],
              [177.931870028000048, -33.407680077999942],
              [177.938915885000142, -33.415088835],
              [177.946686866000164, -33.423331253999947],
              [177.949784045000342, -33.426616520999971],
              [177.963971045000221, -33.436024521000036],
              [177.978110045000221, -33.445484520999969],
              [177.992200045000345, -33.454996521],
              [178.006242045000135, -33.464560520999953],
              [178.020235045000049, -33.47417552099995],
              [178.034179045000201, -33.483842520999929],
              [178.035264338000047, -33.484601562000023],
              [178.048073917000238, -33.493561001999979],
              [178.061918916000309, -33.503330517],
              [178.075713992000033, -33.513150993999943],
              [178.086342054000056, -33.520783819999963],
              [178.089459045000126, -33.52302252100003],
              [178.103153045000113, -33.532944520999934],
              [178.116797045000112, -33.542916521],
              [178.118923166000059, -33.544484091999948],
              [178.130389580000042, -33.552938786000027],
              [178.141002534000108, -33.560833034000041],
              [178.143931045000045, -33.563011521000021],
              [178.148689565000268, -33.566581998],
              [178.157420712000203, -33.573133578999943],
              [178.170858660000249, -33.58330553299993],
              [178.184244501000308, -33.593526937000036],
              [178.19757796500005, -33.603797595999936],
              [178.210858914500363, -33.614117418999967],
              [178.224086687000295, -33.624485903999982],
              [178.230761544000075, -33.629763714999967],
              [178.237261045000196, -33.634903520999956],
              [178.243795908000266, -33.640115176],
              [178.250382681000019, -33.645368887000018],
              [178.263450238000246, -33.65588288899994],
              [178.276463813000078, -33.666444964],
              [178.289423142000032, -33.677054913],
              [178.302327961000287, -33.687712534000013],
              [178.315178007000327, -33.69841762599998],
              [178.32797301700009, -33.709169984000027],
              [178.340712728000199, -33.719969405999962],
              [178.353396881000094, -33.730815684],
              [178.364163089000044, -33.740102385],
              [178.366025045000129, -33.741708521000021],
              [178.370483606000334, -33.745587950999948],
              [178.378597469000169, -33.752647988000035],
              [178.382333627000207, -33.75592733000002],
              [178.391113045000111, -33.763633521000031],
              [178.403572877000215, -33.774665378499947],
              [178.415975179000156, -33.78574269],
              [178.417915852000078, -33.78749121200002],
              [178.428320045000191, -33.796865521000029],
              [178.434422288000178, -33.801990778],
              [178.441167684000106, -33.807656475999977],
              [178.453959071000213, -33.818494573999942],
              [178.458022948000121, -33.821968065000021],
              [178.466694045000168, -33.829379521000021],
              [178.467647577000207, -33.830201604000024],
              [178.47937360100002, -33.840311986999893],
              [178.491996218000168, -33.851290882999933],
              [178.497921783000265, -33.856490041999962],
              [178.504562045000284, -33.862316520999968],
              [178.507858998000074, -33.865234464999901],
              [178.517070850000096, -33.87338800699996],
              [178.529522343000281, -33.884505809999951],
              [178.530513725000219, -33.88539878499995],
              [178.541916045000107, -33.895669520999945],
              [178.554252218000215, -33.906878679499926],
              [178.566530045000263, -33.918133521000016],
              [178.57655109700022, -33.927400122999984],
              [178.578750136000167, -33.929433658999926],
              [178.590911149000135, -33.940778612499855],
              [178.603013045000125, -33.952168521000033],
              [178.607367220000015, -33.956302166999933],
              [178.615056706000132, -33.963602968000032],
              [178.624230796000234, -33.97239042800004],
              [178.627040045000058, -33.975081520999936],
              [178.631295918000205, -33.979193925999937],
              [178.638964755000075, -33.986604674000034],
              [178.648933214000067, -33.996323149999967],
              [178.650829045000137, -33.998171520999961],
              [178.662633045000121, -34.009782521000034],
              [178.674377045000028, -34.021436520999885],
              [178.68301235100023, -34.03008143400001],
              [178.686060599000029, -34.033133324],
              [178.697683051000126, -34.044873607999989],
              [178.70106770700022, -34.048323176999872],
              [178.709244045000105, -34.056656520999979],
              [178.72074404500006, -34.068482520999964],
              [178.731321626000067, -34.079456795999988],
              [178.732182825000223, -34.080350338000017],
              [178.733269059000236, -34.081487513999946],
              [178.743559045000211, -34.092260520999957],
              [178.753340559000208, -34.10259270499995],
              [178.754873905000068, -34.104212444000041],
              [178.756380203000049, -34.105818000999932],
              [178.766126045000078, -34.116206520999981],
              [178.776870129000145, -34.127761931999942],
              [178.777522623500204, -34.128465749499981],
              [178.788442045000352, -34.140317520999929],
              [178.799506045000044, -34.152434520999975],
              [178.802014448000023, -34.155206737999947],
              [178.810506750000172, -34.164592596000034],
              [178.819024209000304, -34.174092464000012],
              [178.821444045000135, -34.176791521000041],
              [178.824059445000216, -34.17973525900004],
              [178.832316906000301, -34.189029716],
              [178.839580113000125, -34.197280350000028],
              [178.84312604500019, -34.201308520999987],
              [178.846734548000086, -34.205445285999929],
              [178.853871079000328, -34.21362690199993],
              [178.864551585000299, -34.225984924999977],
              [178.873654675000125, -34.236616118000029],
              [178.875167045000239, -34.238382521],
              [178.893340046000276, -34.240826520999974],
              [178.895532734000199, -34.241129586],
              [178.911499904000038, -34.243336857000031],
              [178.929647089500122, -34.245913532500026],
              [178.947781046000273, -34.248556520999955],
              [178.965901046000113, -34.251265520999937],
              [178.971329962000027, -34.252097534000029],
              [178.984007949000215, -34.254040690999943],
              [179.002100141000028, -34.256881934000035],
              [179.012629455000251, -34.25857526599998],
              [179.020178046000183, -34.259789521000016],
              [179.038240046000055, -34.262762520999956],
              [179.05400153800008, -34.265416508999976],
              [179.056287759000156, -34.265801499999952],
              [179.059046746000263, -34.266276579000021],
              [179.074319046000255, -34.268906521000019],
              [179.09233504600013, -34.272077521000028],
              [179.105304599000078, -34.274409159000015],
              [179.110334877000128, -34.275313643],
              [179.128317793000292, -34.278615768],
              [179.146283754000251, -34.281983495999953],
              [179.164232426000126, -34.28541676399999],
              [179.175669855000137, -34.287648459999957],
              [179.182163046000255, -34.288915520999979],
              [179.187321485000325, -34.289941795999937],
              [179.200076559000195, -34.29247966500003],
              [179.206638095000017, -34.293810504000021],
              [179.217971046000116, -34.296109520999892],
              [179.228064914000015, -34.298195377000013],
              [179.235847515000273, -34.29980394200004],
              [179.253704715000254, -34.303563924999935],
              [179.263567842000157, -34.305678951999937],
              [179.271543046000261, -34.307389521],
              [179.289361046000181, -34.31127952099996],
              [179.307159046000038, -34.315234520999923],
              [179.315471693000063, -34.317114194999931],
              [179.324937084000112, -34.319254695000041],
              [179.328670922000015, -34.32003029699996],
              [179.340748046000044, -34.322539520999953],
              [179.356545046000065, -34.325874521],
              [179.358561742000347, -34.326307227000029],
              [179.372325676666833, -34.329260547000047],
              [179.362820592000048, -34.32511193299996],
              [179.355828711000072, -34.322060169],
              [179.349548344000254, -34.319286782999939],
              [179.339374046000103, -34.314793520999956],
              [179.32966866400011, -34.31045800499993],
              [179.322963430000073, -34.307462464999901],
              [179.312426565000351, -34.30270060099997],
              [179.306596046000323, -34.300065521000022],
              [179.298929699000041, -34.296561133999944],
              [179.290273820000039, -34.292604276999953],
              [179.286823350000191, -34.291008909000027],
              [179.273996046000207, -34.285077520999948],
              [179.257763046000036, -34.277486521000014],
              [179.241575046000207, -34.269831521000015],
              [179.240178014000207, -34.269163428999946],
              [179.225433588000101, -34.262112117999948],
              [179.210749581000073, -34.255011137999986],
              [179.209003757500113, -34.254165073999957],
              [179.193288898000333, -34.246481038999946],
              [179.186429473000089, -34.243089887],
              [179.17728704600006, -34.238569520999931],
              [179.163951738000151, -34.231904792999956],
              [179.161331742000129, -34.230595261999952],
              [179.145424400000024, -34.222557329999972],
              [179.129565031000084, -34.214456289999987],
              [179.113753994000263, -34.206292332999965],
              [179.108657887000049, -34.203632573],
              [179.097992046000172, -34.19806552099999],
              [179.088819904000275, -34.193227296000032],
              [179.08227835300022, -34.189776441],
              [179.072690666000312, -34.184664557999952],
              [179.066614046000041, -34.18142452099994],
              [179.062698581000063, -34.179314825],
              [179.051000326000121, -34.173011216999953],
              [179.035436303000097, -34.164535600000022],
              [179.0199227410003, -34.155998244999978],
              [179.00445999100009, -34.147399354999948],
              [178.989048400000229, -34.138739130999966],
              [178.975998930000173, -34.131329730999937],
              [178.97368804600012, -34.130017521000013],
              [178.962468649000016, -34.123581098999978],
              [178.958380081000314, -34.121235503999969],
              [178.943124043000125, -34.112392517999979],
              [178.927920046000054, -34.103488521000031],
              [178.920643735000112, -34.099183730999982],
              [178.912769907000097, -34.094525218999934],
              [178.89767249400029, -34.085501336],
              [178.888693849000219, -34.080079868000041],
              [178.882629046000147, -34.076417520999939],
              [178.871882397000036, -34.069862711000042],
              [178.867638656000054, -34.067274161],
              [178.852702902000033, -34.058071296999969],
              [178.83782170100028, -34.048809204999941],
              [178.822995384000023, -34.039488099999915],
              [178.808224279000228, -34.030108203999987],
              [178.800532194000198, -34.025174549999974],
              [178.793509045000178, -34.020669521],
              [178.778849045000129, -34.011172521],
              [178.764245045000052, -34.001617520999957],
              [178.761771966000197, -33.999983421999957],
              [178.74969850500031, -33.992005118],
              [178.735208340000213, -33.982334588999947],
              [178.720775325000091, -33.972606608999911],
              [178.712039978000121, -33.966660598999965],
              [178.706400045000123, -33.962821520999967],
              [178.703714145000163, -33.960975269999949],
              [178.692082018000065, -33.952979207999988],
              [178.677822355000103, -33.943080247000012],
              [178.667614264000122, -33.935924075999935],
              [178.663621045000014, -33.933124520999968],
              [178.649479045000021, -33.92311252099995],
              [178.640432570000144, -33.916646256999968],
              [178.635395067000104, -33.913045103],
              [178.621370974000342, -33.902921468499983],
              [178.607406378000292, -33.892742136000038],
              [178.596310511000297, -33.884574886],
              [178.593502045000264, -33.88250752099998],
              [178.584536855000124, -33.875844334999954],
              [178.579657470000029, -33.872217748999958],
              [178.565873686000032, -33.861873120999974],
              [178.563332860000287, -33.859947679000015],
              [178.552151045000301, -33.851473520999946],
              [178.542637859000138, -33.84419484],
              [178.538488954000286, -33.84102020499995],
              [178.53143741100007, -33.835572097000011],
              [178.524889045000236, -33.830512521],
              [178.511350045000199, -33.819950520999953],
              [178.506637813000225, -33.81623898700002],
              [178.497873362000064, -33.809335331999989],
              [178.484756061500207, -33.798902756999894],
              [178.482949561000282, -33.797454365999954],
              [178.471107369000151, -33.787944621999884],
              [178.468460437000317, -33.785798433999958],
              [178.457819045000178, -33.777169521000019],
              [178.447048505000112, -33.768352596999932],
              [178.444592912000189, -33.766342262999956],
              [178.436459072000048, -33.759618799],
              [178.431431045000181, -33.755462521000027],
              [178.429089574000159, -33.753508405],
              [178.418332255000337, -33.744530264],
              [178.405297802000177, -33.733546281999963],
              [178.404483601000038, -33.732853521000038],
              [178.392328045000227, -33.722510521],
              [178.379422045000183, -33.711423520999972],
              [178.375070420000043, -33.707649018000041],
              [178.366581260000203, -33.700285470999972],
              [178.361033293000105, -33.695426514999951],
              [178.353806045000056, -33.689096520999954],
              [178.341095045000088, -33.677856520999967],
              [178.328451045000065, -33.666566521],
              [178.315872045000219, -33.655226520999975],
              [178.306061026000179, -33.646296007999979],
              [178.303359354000236, -33.643836729999975],
              [178.290913223000075, -33.632397220999962],
              [178.281181334000053, -33.623365481],
              [178.278534045000129, -33.620908520999933],
              [178.273833016000026, -33.616503381999962],
              [178.266221303000066, -33.609370553999895],
              [178.262141045000135, -33.605509764000033],
              [178.253976045000286, -33.59778352099994],
              [178.241798045000223, -33.586148520999984],
              [178.237115234000157, -33.581630826000037],
              [178.229688124000063, -33.574465427],
              [178.217645997000091, -33.562734091000038],
              [178.205672087000039, -33.550955057999943],
              [178.199592149000068, -33.544915458999924],
              [178.193767045000186, -33.539128521],
              [178.181930045000286, -33.527254520999975],
              [178.171629182000174, -33.516820607999989],
              [178.170162112000298, -33.515334501000041],
              [178.1584635170002, -33.503367408000031],
              [178.149094953000258, -33.493689281],
              [178.146834045000219, -33.491353521],
              [178.135275045000071, -33.479294520999929],
              [178.132200438000154, -33.476055345999896],
              [178.123785271000315, -33.467189257999962],
              [178.115920215000074, -33.458820526999943],
              [178.112366045000272, -33.455038521],
              [178.101017045000049, -33.442842520999989],
              [178.092539032000332, -33.433641406999925],
              [178.089738261000321, -33.430601639000031],
              [178.081029808000039, -33.421055638999974],
              [178.078531045000318, -33.418316521],
              [178.077402094000036, -33.417066635999959],
              [178.06739401100026, -33.405986138999936],
              [178.056328868000151, -33.393612314499876],
              [178.045334870000033, -33.38119427099997],
              [178.044604710000158, -33.380361208999886],
              [178.034413045000178, -33.36873252099997],
              [178.02356304500006, -33.356228520999935],
              [178.012785045000214, -33.34368052100001],
              [178.002079045000073, -33.331090520999965],
              [177.991446045000089, -33.318457520999971],
              [177.980886045000148, -33.305783521000023],
              [177.974868245000152, -33.29848676599994],
              [177.970398365000165, -33.293066747999944],
              [177.959984303000056, -33.280308749999961],
              [177.958398763000332, -33.278346235999948],
              [177.949644045000127, -33.267509519999919],
              [177.939377045000128, -33.25466952],
              [177.933823897000025, -33.247652638999966],
              [177.929183390000162, -33.241788611999937],
              [177.919064178000326, -33.228867495000031],
              [177.909019175000026, -33.215906331999975],
              [177.904897166000183, -33.210531540999966],
              [177.899049045000083, -33.202905519999945],
              [177.889153045000171, -33.189865520000012],
              [177.886460315000136, -33.186279601000024],
              [177.879331358000286, -33.176785523999968],
              [177.869585119000135, -33.163667136000015],
              [177.859914042000241, -33.150510185999963],
              [177.852232992000154, -33.139947872999983],
              [177.850318045000222, -33.137314519999961],
              [177.840798045000156, -33.124081519999947],
              [177.831354045000353, -33.11081052000003],
              [177.821985045000019, -33.097502519999978],
              [177.819543073000091, -33.093995783000025],
              [177.812692500000026, -33.084157479999931],
              [177.803476212000078, -33.070775454],
              [177.794336192500396, -33.057356745999925],
              [177.785273053000196, -33.043902338999942],
              [177.776286424000091, -33.030411726000011],
              [177.767361448000088, -33.016885572000035],
              [177.758513516000221, -33.003323965],
              [177.751938265000035, -32.993130737],
              [177.74974304400007, -32.989727519999974],
              [177.74105004400019, -32.976096519999942],
              [177.738872592000348, -32.972642821000022],
              [177.732434097000237, -32.962430329000028],
              [177.723896282000055, -32.948730538],
              [177.71543638900016, -32.934996974],
              [177.714717851000273, -32.933816783999987],
              [177.707055044000072, -32.92122952],
              [177.698751044000119, -32.90742952],
              [177.69052604400008, -32.89359652],
              [177.68556023900021, -32.885145446999957],
              [177.682379094000339, -32.879731176],
              [177.674311081000042, -32.865833398],
              [177.672054970000204, -32.861899720999986],
              [177.66632204400014, -32.851903519999951],
              [177.658412044000215, -32.837942519999956],
              [177.653879964000112, -32.829845303999946],
              [177.650580500000274, -32.823949875999972],
              [177.647340083000131, -32.818087826999943],
              [177.642829044000223, -32.809926519999976],
              [177.63782967800023, -32.80076959400003],
              [177.635156051000081, -32.795872178000025],
              [177.630323122000163, -32.786907458999963],
              [177.627563044000226, -32.781787520000023],
              [177.620050044000322, -32.767673520000017],
              [177.61261604400022, -32.753528519999975],
              [177.609217772000079, -32.746979197999963],
              [177.605262237000034, -32.739355292000027],
              [177.597988514000122, -32.725152607000041],
              [177.590794934000201, -32.710921171999985],
              [177.589617944000338, -32.708561691999989],
              [177.58368204400017, -32.696661520000021],
              [177.576649044000192, -32.682373519999885],
              [177.572677403000284, -32.674196040999959],
              [177.569696257000032, -32.668057551999937],
              [177.562824447000253, -32.653714304999937],
              [177.556033368000271, -32.639343903999929],
              [177.549323134000019, -32.624946672000021],
              [177.548993070000279, -32.624228532000018],
              [177.542694044000086, -32.610522520000018],
              [177.536146044000247, -32.59607252],
              [177.529679044000204, -32.581597519999946],
              [177.523293044000297, -32.567095519999953],
              [177.520467797000038, -32.560586472000011],
              [177.516988404000017, -32.552569274999939],
              [177.510765475000198, -32.53801780699996],
              [177.505137685000136, -32.524660673999961],
              [177.504624044000138, -32.52344152],
              [177.504250910000223, -32.522542550000026],
              [177.498564430000101, -32.508841466999968],
              [177.49258650600018, -32.494217244000026],
              [177.486690436000117, -32.479569418999972],
              [177.480876312000305, -32.46489831599996],
              [177.475144222000154, -32.450204262],
              [177.469494252000345, -32.435487582],
              [177.463926487000208, -32.420748602],
              [177.459362516000169, -32.408467344999906],
              [177.458441044000097, -32.40598752],
              [177.454365588000201, -32.394837530999951],
              [177.453037899000037, -32.391205048999893],
              [177.447717234000265, -32.376401129999977],
              [177.442479093000202, -32.361576219000028],
              [177.441336819000185, -32.358287],
              [177.437324044000093, -32.346730519999937],
              [177.432251044000054, -32.331864519999954],
              [177.429640078000034, -32.324077133999936],
              [177.427260543000102, -32.316978812999935],
              [177.422353221000094, -32.302073213999904],
              [177.419476016000203, -32.293172266999989],
              [177.417529044000048, -32.28714852],
              [177.415697186000216, -32.281375577999938],
              [177.412787271000184, -32.272204292999959],
              [177.410497020000321, -32.264848222000012],
              [177.408129044000134, -32.25724152],
              [177.405686656000057, -32.249245579999922],
              [177.403553338000052, -32.242260604000023],
              [177.399401958000112, -32.228399847999981],
              [177.399061044000177, -32.227261520000013],
              [177.394652044000281, -32.212244518999967],
              [177.390326044000062, -32.197210518999952],
              [177.389714569000034, -32.195041460999903],
              [177.386083419000101, -32.182159476000038],
              [177.3819241650001, -32.167091588999938],
              [177.377848303000178, -32.152007322000031],
              [177.375787967000065, -32.144214627],
              [177.373856044000149, -32.136906518999979],
              [177.369947044000014, -32.121790518999944],
              [177.368779087000121, -32.11717150100003],
              [177.366121538000016, -32.10665955099995],
              [177.363586217000147, -32.09639689200003],
              [177.362380044000247, -32.091513518999989],
              [177.360910985000146, -32.085426098000028],
              [177.358721479000337, -32.076351876999979],
              [177.355833396000207, -32.064090730999965],
              [177.355147044000091, -32.061176519000014],
              [177.351656044000038, -32.045986518999939],
              [177.348249044000113, -32.030783518999954],
              [177.347399624000332, -32.026894943],
              [177.34492543600004, -32.015566490999959],
              [177.342991187000166, -32.006473389],
              [177.341686044000085, -32.000336518999973],
              [177.338853421000294, -31.986656279999977],
              [177.338529987000015, -31.985094095999955],
              [177.335458008000273, -31.969839136999951],
              [177.334417607000091, -31.964523471999939],
              [177.332470044000161, -31.954572518999953],
              [177.329566044000217, -31.939293518999989],
              [177.328171530000077, -31.931735162999985],
              [177.326745345000177, -31.924003207],
              [177.324909643000296, -31.913738423000041],
              [177.32400904400015, -31.908701519000033],
              [177.323372258000063, -31.905026495999948],
              [177.321356507000132, -31.893390039999929],
              [177.319012434000228, -31.87940643099995],
              [177.318788044000144, -31.878067518999956],
              [177.318560254000317, -31.876662098],
              [177.316303511000228, -31.862735278999935],
              [177.313903016000268, -31.847393325999917],
              [177.311586460000058, -31.832041587999981],
              [177.310020669000096, -31.821268344999964],
              [177.309354044000088, -31.816680518999988],
              [177.308673451000232, -31.811813125999947],
              [177.307205428000316, -31.801311636999969],
              [177.305768427000203, -31.790607889999933],
              [177.305141044000266, -31.785933518999968],
              [177.304640299000312, -31.782044596999981],
              [177.30316045800032, -31.770548091000023],
              [177.30143190900003, -31.756517451999969],
              [177.30126404400005, -31.755154518999973],
              [177.299452044000077, -31.739753518999976],
              [177.297993601000144, -31.726756594],
              [177.29772314600018, -31.724345619000019],
              [177.297380084000224, -31.721129746000031],
              [177.296079044000066, -31.708930518999978],
              [177.295196302000249, -31.700209978999908],
              [177.294518333000156, -31.693509759999927],
              [177.293507858000339, -31.682951163999917],
              [177.293042044000345, -31.678082518999986],
              [177.292744667000306, -31.674787894000019],
              [177.291649506000226, -31.662649642999952],
              [177.290341062000152, -31.647211320999986],
              [177.290263517000199, -31.646233313999971],
              [177.28911704400025, -31.631767519],
              [177.287976044000175, -31.616319518999887],
              [177.287920754000083, -31.615511253999955],
              [177.286919435000073, -31.600867301999983],
              [177.286375379000333, -31.592222009000011],
              [177.285947044000181, -31.585410519],
              [177.285058044000039, -31.569950518999974],
              [177.284253044000025, -31.554486518999965],
              [177.283532044000026, -31.539019519000021],
              [177.282895044000043, -31.523550518999969],
              [177.282420942000158, -31.510309902000017],
              [177.282341116000225, -31.508078500000011],
              [177.281871382000077, -31.492604519999929],
              [177.281485376000319, -31.47712880499995],
              [177.281183064000174, -31.461651686000039],
              [177.28096441200023, -31.446173496000029],
              [177.280829384000072, -31.430694565999943],
              [177.280789911000284, -31.418817145],
              [177.280778044000272, -31.415215518999958],
              [177.280810043000315, -31.399735667499954],
              [177.280926044000239, -31.384256519000019],
              [177.281125044000191, -31.368778518999932],
              [177.281325074000051, -31.357799547999946],
              [177.281407186000251, -31.35330043700003],
              [177.281718655000191, -31.340124331999959],
              [177.281773044000147, -31.337824519000037],
              [177.281845270000076, -31.335335208999936],
              [177.282222194000155, -31.322349213],
              [177.282353396000076, -31.318536362999964],
              [177.282755044000055, -31.306876518999957],
              [177.283153442000298, -31.296854378000035],
              [177.283370272000298, -31.291405631999964],
              [177.284069081000325, -31.275937533999979],
              [177.284383503000072, -31.26971870899996],
              [177.284851044000106, -31.260472519000018],
              [177.285716044000083, -31.245010519],
              [177.28666404400019, -31.229551518999983],
              [177.287695044000088, -31.214097518999949],
              [177.288809044000345, -31.198647518999962],
              [177.289878473000158, -31.18483614400003],
              [177.29000511300012, -31.183201416000031],
              [177.291284408000024, -31.167760547],
              [177.292646373000025, -31.152324883999938],
              [177.293827611000211, -31.139707509999937],
              [177.294091044000083, -31.13689451800002],
              [177.295618041500234, -31.121470502000022],
              [177.297228044000121, -31.106052517999956],
              [177.29801191800027, -31.098908182],
              [177.298919540000043, -31.090640843000017],
              [177.300058375000276, -31.080753055],
              [177.300694044000352, -31.075236518],
              [177.301725801000202, -31.066676719999975],
              [177.302550277000023, -31.05983857199999],
              [177.303813368000192, -31.049811411999883],
              [177.30448904400032, -31.044448517999967],
              [177.306510044000333, -31.029066518000036],
              [177.307871705000139, -31.019107347999977],
              [177.308612331000177, -31.013692200999969],
              [177.31019018700033, -31.002594234999947],
              [177.310797044000196, -30.998326518],
              [177.311252532000026, -30.99523961299991],
              [177.31306337400008, -30.982969777],
              [177.31541154200022, -30.967622086999938],
              [177.317841357000134, -30.952283844999954],
              [177.319144686000186, -30.944328811999981],
              [177.320353044000171, -30.936955517999934],
              [177.322946044000332, -30.921636518000014],
              [177.325620044000061, -30.90632951799995],
              [177.327186203000224, -30.897632932000022],
              [177.328375259000154, -30.891031828],
              [177.33050401800017, -30.879560425999955],
              [177.331212044000154, -30.875745518000031],
              [177.334130044000176, -30.860470517999886],
              [177.335352155000351, -30.854248674999951],
              [177.337128404000282, -30.845207856999934],
              [177.34020801600002, -30.829956831],
              [177.343368408000174, -30.81471815499998],
              [177.346609475000264, -30.799492150999981],
              [177.349853912500407, -30.784632738499965],
              [177.350326402000064, -30.782513075999987],
              [177.353333208000322, -30.769079437000016],
              [177.354225592000262, -30.765187977999972],
              [177.356816044000311, -30.753893518000041],
              [177.359084322000285, -30.744232006],
              [177.360378349000143, -30.738721246000011],
              [177.364021172000321, -30.723563393999939],
              [177.365622777000226, -30.717048600999988],
              [177.367744044000347, -30.708420517999969],
              [177.371547044000067, -30.693291518000024],
              [177.373356449000084, -30.686247331],
              [177.375429278000297, -30.678178626000033],
              [177.379391471000304, -30.663081022999961],
              [177.383433212000114, -30.647999275000018],
              [177.387554380000097, -30.632933695999952],
              [177.39047802600021, -30.62245910799993],
              [177.391755044000206, -30.617884517999983],
              [177.39301874600028, -30.613445171999956],
              [177.396034504000227, -30.602852309999946],
              [177.400393210000061, -30.587837130999958],
              [177.40117300500026, -30.585201678000033],
              [177.404831044000161, -30.572839518000023],
              [177.407366236000257, -30.56443004800002],
              [177.409347277000279, -30.557859372],
              [177.413942380000094, -30.542897418],
              [177.418616020000059, -30.52795383],
              [177.423368065000091, -30.513028921999933],
              [177.428198381000243, -30.498123002999932],
              [177.43143781300023, -30.488298274999977],
              [177.433107044000053, -30.483236517999956],
              [177.435972849000109, -30.474691407000023],
              [177.438093281000192, -30.468369378000034],
              [177.439418618000218, -30.464483871999903],
              [177.443158044000143, -30.453522517999943],
              [177.448300044000348, -30.438695518000031],
              [177.453166825000267, -30.424888743],
              [177.4535192190001, -30.423889116],
              [177.45881625800007, -30.409103644000027],
              [177.460591481000279, -30.404226764999976],
              [177.464191044000131, -30.394339518000038],
              [177.469642052000154, -30.379596493],
              [177.475170531000231, -30.364875379000011],
              [177.478708136000137, -30.355598597000039],
              [177.480776044000322, -30.350176518000012],
              [177.486458044000074, -30.335499517999949],
              [177.487393576000045, -30.333118606000028],
              [177.492216434000284, -30.320845765999934],
              [177.498051395000175, -30.306214796999939],
              [177.50060370600022, -30.299907561],
              [177.503963044000329, -30.291607517999978],
              [177.509950044000334, -30.277023517999964],
              [177.513969394000128, -30.267370581000023],
              [177.516013097000041, -30.26246283699993],
              [177.522152081000286, -30.247926841999913],
              [177.523916620000023, -30.243806522999989],
              [177.52836704400022, -30.233415517999958],
              [177.534657044000028, -30.218928517999984],
              [177.541022044000101, -30.204467517999944],
              [177.542668569000057, -30.200776388],
              [177.547462506000102, -30.190030831999877],
              [177.552275457000292, -30.179385755],
              [177.553978044000246, -30.17562051799996],
              [177.557297920000337, -30.168373716999952],
              [177.560568126000135, -30.161235800999975],
              [177.565704396000228, -30.150170591],
              [177.567233044000091, -30.14687751799994],
              [177.568172669000091, -30.144879194999874],
              [177.573972242000082, -30.132545926000034],
              [177.57734710200009, -30.125460570999962],
              [177.580786044000206, -30.118241518],
              [177.583914158000084, -30.111756370000023],
              [177.587673498000072, -30.103963577999878],
              [177.594635123000216, -30.089713463999942],
              [177.601670515000251, -30.075491113999931],
              [177.608779498000217, -30.061296822999964],
              [177.615961896000158, -30.047130880999987],
              [177.616457324000066, -30.046165554999959],
              [177.623218044000282, -30.032993516999966],
              [177.626218044000325, -30.017797516999963],
              [177.628420970000207, -30.00693457],
              [177.629297239000266, -30.002614241999936],
              [177.63245628000027, -29.98744301499994],
              [177.633940580000171, -29.980495129999952],
              [177.635695044000158, -29.972284517],
              [177.638484027000118, -29.959550378000017],
              [177.639012225000329, -29.957139042],
              [177.64142973100013, -29.946369175999962],
              [177.642409044000033, -29.942006516999982],
              [177.645885044000124, -29.926888517],
              [177.6489193970001, -29.913992947],
              [177.649439233000066, -29.911784025000031],
              [177.65007590100015, -29.909139827999979],
              [177.653073044000053, -29.896693517000017],
              [177.656785044000117, -29.881618517],
              [177.65875450600015, -29.873792117],
              [177.660575356000322, -29.866557695999973],
              [177.664444458000048, -29.851512315999969],
              [177.667222812000205, -29.840933775],
              [177.668392044000029, -29.836482517000036],
              [177.67241804400021, -29.821468516999971],
              [177.674777163000272, -29.812845055000025],
              [177.676521335000189, -29.80647074299992],
              [177.680703091000282, -29.791489448],
              [177.681188229000327, -29.789785106000011],
              [177.684963044000256, -29.776524516999928],
              [177.688548926000294, -29.764166164999949],
              [177.689300073000027, -29.761577568],
              [177.693715054000336, -29.746647604000032],
              [177.698207527000022, -29.731735368999978],
              [177.702777366000191, -29.716841173999967],
              [177.707424443000036, -29.701965326999982],
              [177.709247742000343, -29.696231195999985],
              [177.712149044000057, -29.687108516999956],
              [177.7169500440001, -29.672269516999961],
              [177.72182804400029, -29.657450516999972],
              [177.722703997000053, -29.654833798000027],
              [177.72678251300033, -29.64265156600004],
              [177.729566520000049, -29.634473502999981],
              [177.731814044000203, -29.627872516999957],
              [177.73389064100013, -29.621870839999985],
              [177.736921537000057, -29.613112755999907],
              [177.740224606000311, -29.603721737999933],
              [177.742106044000138, -29.598373517000027],
              [177.747366044000159, -29.583655516999954],
              [177.750440087000015, -29.575188650999962],
              [177.752701972000068, -29.568958998000028],
              [177.758114053500321, -29.5542835],
              [177.763602044000095, -29.53962951699998],
              [177.76470224500008, -29.536735732000011],
              [177.76916535800035, -29.524997742000039],
              [177.774662277000289, -29.510711028999935],
              [177.78023123100013, -29.496445725],
              [177.784157854000341, -29.486530688999949],
              [177.785872044000143, -29.482202517000019],
              [177.791585044000271, -29.467980516999944],
              [177.797369044000334, -29.453781516999982],
              [177.801439598000115, -29.443925981],
              [177.803224133000072, -29.439605580000034],
              [177.805073164000078, -29.435190029999887],
              [177.809151045000021, -29.42545251699994],
              [177.815149045000339, -29.411322517000031],
              [177.821218045000137, -29.39721551699995],
              [177.823735126000088, -29.391441275999966],
              [177.827357512000162, -29.383132542999931],
              [177.833568002500215, -29.369073621999974],
              [177.839849045000165, -29.355039516999966],
              [177.843031335000205, -29.348019538000017],
              [177.846200335000049, -29.341029356000035],
              [177.849876577000259, -29.333023239999989],
              [177.852622045000203, -29.327044516999933],
              [177.859114045000183, -29.313084516999936],
              [177.864828360000274, -29.300948673],
              [177.865675308000164, -29.299150077999968],
              [177.86646844400002, -29.297486555999967],
              [177.872307045000071, -29.285241516999974],
              [177.879008045000091, -29.271358516999953],
              [177.885778147000224, -29.257502303999956],
              [177.892618045000177, -29.243672517],
              [177.897126772000092, -29.234664198000019],
              [177.899526975000185, -29.229868883],
              [177.904299542000103, -29.220446630000026],
              [177.906505045000017, -29.216092516999936],
              [177.913552045000301, -29.202343517000031],
              [177.920395296000152, -29.189146573000016],
              [177.920667015000163, -29.188622583499978],
              [177.927851045000352, -29.17492951700001],
              [177.933649045000266, -29.160506516999988],
              [177.939520045000222, -29.146106517000021],
              [177.945464045000335, -29.131730517],
              [177.947613003000129, -29.126604730999986],
              [177.951481269000283, -29.117378310999982],
              [177.953633054000079, -29.112315941999952],
              [177.95757204500012, -29.103049516999931],
              [177.963735045000021, -29.088745517],
              [177.969971045000193, -29.074465517000021],
              [177.976279108000085, -29.060211386999981],
              [177.982660045000102, -29.04598151700003],
              [177.989113045000181, -29.031777517000023],
              [177.995638045000078, -29.017599516999951],
              [178.00223504500002, -29.003446516999986],
              [178.00468061000015, -28.998265644999947],
              [178.008903393000196, -28.989320515000031],
              [178.012712430000192, -28.981352510999955],
              [178.015644045000158, -28.975220516000036],
              [178.022455045000015, -28.961148515999923],
              [178.029337976000306, -28.947102648499992],
              [178.036292045000096, -28.933084515999951],
              [178.038807224000038, -28.928074927999944],
              [178.043316503000256, -28.919094317999964],
              [178.050411974000099, -28.905131941999969],
              [178.057578069000215, -28.891197721999959],
              [178.064740488000325, -28.877412971999945],
              [178.070569060000139, -28.866325136],
              [178.071972045000166, -28.863656515999935],
              [178.076580750000176, -28.85498962600002],
              [178.079272227000047, -28.849928355000031],
              [178.083030872000222, -28.842940841999905],
              [178.086641045000079, -28.836229516],
              [178.088685438000198, -28.832471703999943],
              [178.094078544000013, -28.822559053999896],
              [178.101584241000296, -28.808918321999954],
              [178.104506547000142, -28.80366657899998],
              [178.109158045000072, -28.79530751599998],
              [178.116800045000218, -28.781726515999935],
              [178.123465231000296, -28.770010347999971],
              [178.124509399000203, -28.768175024000016],
              [178.125430622000295, -28.766573480000034],
              [178.132287045000112, -28.75465451599996],
              [178.138619492000089, -28.743764063999947],
              [178.140131134000285, -28.741164579999975],
              [178.148043000000143, -28.727705599499927],
              [178.156022045000213, -28.714278516000036],
              [178.164068045000135, -28.700881516000038],
              [178.172180059000198, -28.687517488999958],
              [178.180359045000159, -28.674185515999909],
              [178.182737143000281, -28.670349121000015],
              [178.188604368000142, -28.660885005999944],
              [178.194567096000242, -28.651366733999964],
              [178.196916045000194, -28.647617515999968],
              [178.200447093000321, -28.642038738],
              [178.205293241000163, -28.634382744999982],
              [178.213736428000061, -28.621181087999958],
              [178.222245195000141, -28.608012759000019],
              [178.230819354000232, -28.594878015999939],
              [178.235291869000207, -28.588095802999987],
              [178.239459045000331, -28.581777515999931],
              [178.248163045000069, -28.56871051600001],
              [178.249738919000038, -28.566368360000013],
              [178.256932260000212, -28.555677874000025],
              [178.262233179000191, -28.54787823700002],
              [178.265766045000078, -28.542680516000019],
              [178.27466404500035, -28.529717515999948],
              [178.283627045000117, -28.516789516000017],
              [178.288988176000089, -28.509131520999972],
              [178.292653223000343, -28.503896755999961],
              [178.301743539000086, -28.491039906000026],
              [178.310897487000034, -28.478218932999937],
              [178.31645654700003, -28.470508310999946],
              [178.32011504500008, -28.465434516000016],
              [178.329396045000067, -28.452685516000031],
              [178.331376075000094, -28.446003413999961],
              [178.333847314000195, -28.437664214999984],
              [178.338376373000074, -28.422660848000021],
              [178.340968392000036, -28.414229057999933],
              [178.34298304500021, -28.407676516000038],
              [178.347667045000321, -28.392710516000022],
              [178.352428045000238, -28.377763516000016],
              [178.357266045000188, -28.362836515999959],
              [178.360050740000247, -28.354388361000019],
              [178.362180473000194, -28.347928627999977],
              [178.36717213400027, -28.333041304],
              [178.372240426000076, -28.318174416000033],
              [178.377385214000128, -28.303328277999981],
              [178.382606358000089, -28.288503198999933],
              [178.384581932000287, -28.282982369000024],
              [178.387904045000141, -28.273699515999965],
              [178.392156273000069, -28.262000941999986],
              [178.393277152000337, -28.258917465000025],
              [178.398726516000352, -28.244157428],
              [178.401951742000279, -28.23555449],
              [178.404252045000021, -28.22941951599995],
              [178.406602303000341, -28.223243792999938],
              [178.409852458000046, -28.214704561999952],
              [178.41291563700031, -28.206775980999964],
              [178.415529045000198, -28.200012515999958],
              [178.418884346000027, -28.191454193000027],
              [178.42128037000009, -28.185343357999955],
              [178.427107192000108, -28.170697897000025],
              [178.431679639000095, -28.159369847999926],
              [178.433009045000176, -28.156076515999928],
              [178.438986045000206, -28.141478516],
              [178.44056377000004, -28.137678782000023],
              [178.445037297000113, -28.126905748999945],
              [178.451163365000184, -28.112357460999988],
              [178.454176079000263, -28.105300865],
              [178.45736404500019, -28.097834516000034],
              [178.463639045000036, -28.083336516000031],
              [178.467897261000076, -28.073628749000022],
              [178.469987466000248, -28.06886412800003],
              [178.476410266000244, -28.054417868999963],
              [178.482316283000159, -28.04130870399996],
              [178.482907045000161, -28.039997515999914],
              [178.489477045000172, -28.025604515999987],
              [178.496121045000336, -28.011237516],
              [178.50283804500009, -27.996898515999931],
              [178.509628045000227, -27.982586516000026],
              [178.516491045000294, -27.968302516],
              [178.521175714000037, -27.958672429999979],
              [178.523426455000049, -27.954046144000017],
              [178.526501069000233, -27.947804335999933],
              [178.530435045000104, -27.939818516],
              [178.537516045000189, -27.92561951600004],
              [178.544669045000092, -27.911448515000018],
              [178.548043148000147, -27.904844614000012],
              [178.551893884000265, -27.897308006999936],
              [178.555904186000248, -27.889552672000036],
              [178.559191045000148, -27.883196514999952],
              [178.566560045000074, -27.869114514999978],
              [178.574000045000162, -27.85506351500004],
              [178.581512045000295, -27.84104251499997],
              [178.589095045000136, -27.827051514999937],
              [178.596749045000138, -27.813092514999937],
              [178.60318463700014, -27.801487785999882],
              [178.604473276000022, -27.799164286],
              [178.606315016000053, -27.79588105299996],
              [178.612269045000176, -27.78526751499993],
              [178.615056897000045, -27.7803528849999],
              [178.620134405000272, -27.771402936999948],
              [178.628070448000244, -27.757570324000028],
              [178.630402664000144, -27.753550265999934],
              [178.636077045000093, -27.743770515],
              [178.640539340000032, -27.736162234],
              [178.644152544000235, -27.730002647999981],
              [178.651711607000152, -27.717257239],
              [178.65229804500018, -27.716268514999967],
              [178.652912479000065, -27.715243687],
              [178.660513373000299, -27.702566940999986],
              [178.66566343400018, -27.694070393999937],
              [178.668798045000244, -27.688899514999946],
              [178.673263174000112, -27.681611407999981],
              [178.677151386000105, -27.675265468000021],
              [178.684634988000084, -27.663181742999939],
              [178.685574045000067, -27.661665514999981],
              [178.694065045000229, -27.64810051500001],
              [178.702625045000104, -27.63456951500001],
              [178.711253045000035, -27.621074515000032],
              [178.719949045000021, -27.607613515000025],
              [178.728713045000177, -27.594188514999928],
              [178.729295565000029, -27.593305336000014],
              [178.737544405000108, -27.580799845999934],
              [178.743666174000055, -27.571614258999944],
              [178.746444045000203, -27.567446514999972],
              [178.75541004500019, -27.554130515],
              [178.764444045000232, -27.540850514999988],
              [178.771637730000066, -27.530380936999961],
              [178.773543974000063, -27.527606916999957],
              [178.782711039000105, -27.514400914000021],
              [178.785560568000051, -27.510336998000014],
              [178.791945045000148, -27.50123251499997],
              [178.798663179000187, -27.491745925000032],
              [178.801244301000224, -27.488101486999938],
              [178.810610073000134, -27.475008602999935],
              [178.81606577700029, -27.467457139000032],
              [178.82004204500015, -27.461953514999948],
              [178.827222921000157, -27.45211185200003],
              [178.829538828000182, -27.448937841000031],
              [178.839101379000113, -27.435960497999986],
              [178.845155170000339, -27.427825049000035],
              [178.848729045000027, -27.423022515000014],
              [178.858422045000111, -27.410123515],
              [178.864755532000231, -27.401775802000031],
              [178.86817910700006, -27.397263812999938],
              [178.873218356000052, -27.390686553000023],
              [178.878001045000246, -27.384444514999927],
              [178.882874774000015, -27.378144064999958],
              [178.8878871200003, -27.371664786999986],
              [178.897837318000029, -27.358925691999957],
              [178.90785134500004, -27.346227222999971],
              [178.917928976000326, -27.333569641999929],
              [178.918894029000171, -27.332369019],
              [178.928070046000244, -27.320953514999871],
              [178.93827404600006, -27.308378515000015],
              [178.941436927000154, -27.304517255],
              [178.948541219000049, -27.295844805],
              [178.955610485000307, -27.287296173000016],
              [178.958871046000013, -27.283353514999931],
              [178.969263046000265, -27.270904514999927],
              [178.979718046000244, -27.258497514999931],
              [178.990234046000069, -27.24613351499994],
              [178.993995984000321, -27.241751343999965],
              [179.00081234400011, -27.23381182299994],
              [179.011452086000077, -27.221533774999955],
              [179.017158585000061, -27.215009469999984],
              [179.022153046000199, -27.209299514999941],
              [179.032915046000312, -27.197108514999968],
              [179.043738046000158, -27.184961515],
              [179.054622046000077, -27.17285851500003],
              [179.065565046000302, -27.160800515],
              [179.068569127000274, -27.157520704999968],
              [179.076569315000029, -27.148786368999936],
              [179.087633081000035, -27.136817590999954],
              [179.098756457000036, -27.124893977999875],
              [179.109939200000326, -27.113015773000029],
              [179.116722825000124, -27.105875709999964],
              [179.121181046000174, -27.101183514999946],
              [179.132482046000177, -27.089396514999919],
              [179.143841046000148, -27.077656514999944],
              [179.150930893000123, -27.070394667999949],
              [179.15525892400035, -27.065961860000016],
              [179.159035552000205, -27.06212873599992],
              [179.166735046000099, -27.054314514999987],
              [179.17826904600031, -27.042713514999946],
              [179.184201540000345, -27.036799956999971],
              [179.18985996500021, -27.03115996899993],
              [179.198041817000217, -27.02307818099996],
              [179.201509046000297, -27.019653515000016],
              [179.213214844000163, -27.008194705999983],
              [179.224977417000105, -26.996783916999959],
              [179.236796811000318, -26.985420953000016],
              [179.239481582000167, -26.982863016999943],
              [179.248673046000022, -26.974106515000017],
              [179.254213258000277, -26.968874634000031],
              [179.260604432000264, -26.962839992999932],
              [179.272592146000079, -26.95162245399996],
              [179.284635451000099, -26.940453848],
              [179.295559633000153, -26.930413801999975],
              [179.296734046000211, -26.929334514999937],
              [179.308888046000106, -26.918264514999976],
              [179.313719846000026, -26.91390251699994],
              [179.321096318000173, -26.907243883000035],
              [179.333359388000304, -26.896273261000033],
              [179.33782890000009, -26.892310593],
              [179.345677046000219, -26.885352515000022],
              [179.358048046000135, -26.874482514999897],
              [179.359678935000147, -26.873062294999897],
              [179.370473263000122, -26.863662608000027],
              [179.379330983000045, -26.856018382],
              [179.382952046000128, -26.852893515],
              [179.395484046000092, -26.842175513999962],
              [179.408069046000264, -26.83150851399995],
              [179.416262490000349, -26.824625425000022],
              [179.42070608400013, -26.820892765],
              [179.433396019000043, -26.810328719999973],
              [179.446138077000228, -26.79981649299998],
              [179.4589320460002, -26.789356513999962],
              [179.471778046000054, -26.778948513999978],
              [179.48467504600012, -26.768592514000034],
              [179.497623046000172, -26.758289513999955],
              [179.503004432000239, -26.75404584599994],
              [179.510621363000269, -26.748039566999978],
              [179.517243375000021, -26.742864964000034],
              [179.523671046000118, -26.737842513999965],
              [179.529593700000191, -26.73325595099999],
              [179.536770213000068, -26.72769873499999],
              [179.543389213000296, -26.722619624999936],
              [179.549920046000352, -26.717608514],
              [179.558833689000267, -26.710830255999952],
              [179.563118930000314, -26.707571766999934],
              [179.576367546000142, -26.697588991000032],
              [179.589381855500051, -26.687871938499995],
              [179.591543088000094, -26.686271014],
              [179.603011901000173, -26.67778586299994],
              [179.616407080000158, -26.667965910999953],
              [179.624938179000196, -26.661768958000039],
              [179.629851046000113, -26.65820051399993],
              [179.643342046000157, -26.648490513999946],
              [179.648402838000038, -26.644881163000036],
              [179.656881251000073, -26.638834873999883],
              [179.67046791100006, -26.62923478700003],
              [179.673676138000019, -26.626988803000017],
              [179.684102046000021, -26.619690514000013],
              [179.697782046000043, -26.610201514000039],
              [179.711510046000058, -26.600768513999981],
              [179.719178782000085, -26.595546891999959],
              [179.725283323000099, -26.591390895000018],
              [179.739102941000056, -26.582069988],
              [179.752968289000137, -26.572805484999947],
              [179.76687908100007, -26.563597571999878],
              [179.77339560900009, -26.55932458],
              [179.78083504600022, -26.554446513999949],
              [179.794836046000114, -26.545352514],
              [179.808881046000124, -26.536315514000023],
              [179.822971046000248, -26.527335513999958],
              [179.82974605600009, -26.523058528999968],
              [179.837104546000091, -26.518413307999936],
              [179.851281902000096, -26.509548789],
              [179.85467334700013, -26.50744853599997],
              [179.865503046000072, -26.500742513999967],
              [179.879640046000191, -26.492052514000022],
              [179.893820046000201, -26.483419514],
              [179.901093965000086, -26.479032963999941],
              [179.908041298000057, -26.474843786],
              [179.922304435000228, -26.466325902999984],
              [179.936608892000095, -26.457865757999969],
              [179.950954379000223, -26.449463518000016],
              [179.955527022000069, -26.44681133499995],
              [179.965341047000038, -26.441119514000036],
              [179.978720260000046, -26.433434766999937],
              [179.979767047000109, -26.432833514000038],
              [179.994004047000203, -26.424710514000026],
              [180.000000000000227, -26.42134454],
              [180.000000000000227, -28.032786884999894],
              [180.000000000000227, -30.983606556999973],
              [180.000000000000227, -33.934426229999929],
            ],
            [
              [177.638334105000013, -44.54298],
              [177.634622812000316, -44.555634044999977],
              [177.638796847000208, -44.554755952999983],
              [177.659468300000071, -44.550477016999935],
              [177.680164130000207, -44.546262536999961],
              [177.700883964000241, -44.542112583999938],
              [177.721627425000179, -44.538027235999948],
              [177.74239413800035, -44.534006568],
              [177.763183726000079, -44.530050649999964],
              [177.783995816000129, -44.526159555999982],
              [177.804830027000094, -44.522333352999965],
              [177.825685988000032, -44.518572112999948],
              [177.846563318000335, -44.514875902000028],
              [177.867461640000329, -44.511244786999953],
              [177.888380584000288, -44.507678833999989],
              [177.909319765000077, -44.50417810499988],
              [177.930278808000025, -44.50074266799993],
              [177.951257340000325, -44.49737258],
              [177.972254976000045, -44.494067904000019],
              [177.993271345000153, -44.490828698999962],
              [178.014306066000017, -44.487655022999945],
              [178.035358760000236, -44.484546934999969],
              [178.05642905100035, -44.481504488000041],
              [178.077516560000277, -44.478527739999947],
              [178.098620906000178, -44.475616741999943],
              [178.119741717000352, -44.472771545],
              [178.140878605000125, -44.469992204999983],
              [178.162031199000353, -44.467278769000018],
              [178.183199117000186, -44.464631286999975],
              [178.204381980000221, -44.462049804],
              [178.225579408000044, -44.459534368999982],
              [178.246791021000035, -44.457085027],
              [178.268016443000079, -44.454701822],
              [178.289255291000245, -44.452384797000036],
              [178.310507188000088, -44.450133991999962],
              [178.331771750000257, -44.447949448999964],
              [178.353048602000172, -44.445831207000026],
              [178.37433736100013, -44.443779302],
              [178.39563764900015, -44.44179377699993],
              [178.416949082000201, -44.43987466],
              [178.438271283000176, -44.438021990000038],
              [178.459603934000029, -44.436235995999937],
              [178.456793283000025, -44.420876318000012],
              [178.454083569000204, -44.405507164000028],
              [178.451474803000224, -44.390129057999957],
              [178.448966963000203, -44.374742338999965],
              [178.446560024000064, -44.359347343000024],
              [178.4442539610003, -44.343944408000027],
              [178.442048744000203, -44.328533873000026],
              [178.439944342000331, -44.313116073999936],
              [178.437940721000018, -44.29769134899999],
              [178.436037844000225, -44.282260039999983],
              [178.434235678000164, -44.26682248000003],
              [178.432534178000083, -44.25137900499999],
              [178.430933305000053, -44.235929957999986],
              [178.429433014000324, -44.220475670999974],
              [178.428033258000141, -44.205016485999977],
              [178.426733986000158, -44.189552734000017],
              [178.425535152000066, -44.174084752999931],
              [178.424436703000197, -44.158612881999943],
              [178.423438580000152, -44.143137455999977],
              [178.422540729000161, -44.127658814000029],
              [178.421743088000198, -44.112177283999984],
              [178.421455317000095, -44.106042145999879],
              [178.405100254000331, -44.114390026999956],
              [178.388690734000079, -44.122684414999924],
              [178.372226965000095, -44.130925193],
              [178.355709275000095, -44.139112182999895],
              [178.339137998000069, -44.147245207999958],
              [178.322513463000064, -44.155324095000026],
              [178.305836004000184, -44.163348671],
              [178.289105957000174, -44.171318762999874],
              [178.272323697000161, -44.179234056000041],
              [178.257274021000171, -44.187423404000015],
              [178.24217413100007, -44.195566551999946],
              [178.227024168000071, -44.203663415],
              [178.211824394000132, -44.21171383899997],
              [178.196575082000322, -44.219717670999955],
              [178.181276494000144, -44.227674760999946],
              [178.165928900000154, -44.235584956000025],
              [178.150532693000059, -44.243448051999962],
              [178.133665586000234, -44.252800282999885],
              [178.116733742000065, -44.262094196999954],
              [178.099737422000317, -44.271329642000012],
              [178.082676994000281, -44.280506395999907],
              [178.065552842000216, -44.289624236],
              [178.048365344000104, -44.298682945],
              [178.031114883000214, -44.307682301999932],
              [178.013801845000103, -44.316622093000035],
              [177.996426619000317, -44.325502100999984],
              [177.978989592000175, -44.33432211500002],
              [177.961491152000121, -44.34308191599996],
              [177.943931699000018, -44.351781295999956],
              [177.926311623000174, -44.360420045999945],
              [177.908631324000226, -44.368997957000033],
              [177.890891201000272, -44.377514817999952],
              [177.873091654000063, -44.385970426999961],
              [177.855233086000226, -44.394364578000022],
              [177.837315904000093, -44.402697065999973],
              [177.819340513000043, -44.410967693000032],
              [177.801307326000028, -44.419176255000025],
              [177.783216752000158, -44.427322554],
              [177.765069203000053, -44.435406391999948],
              [177.746865095000203, -44.44342757599992],
              [177.728604845000177, -44.451385907999963],
              [177.710288873000081, -44.45928119499996],
              [177.691917597000042, -44.467113244000018],
              [177.673491441000124, -44.474881869],
              [177.655010755000148, -44.482586602999959],
              [177.650990771000352, -44.49770584700002],
              [177.646871347000229, -44.512811293000027],
              [177.642652455000189, -44.527902789],
              [177.638334105000013, -44.54298],
            ],
            [
              [173.760986111000193, -50.698856620999948],
              [173.774489127000265, -50.711714266999934],
              [173.768079256000078, -50.697028628999988],
              [173.761779058000116, -50.682323986000029],
              [173.755588648000071, -50.667600831999948],
              [173.749508054000216, -50.652859489999969],
              [173.743537310000193, -50.63810028499995],
              [173.737676439000325, -50.623323544000016],
              [173.731925468000099, -50.608529588999943],
              [173.726284422000163, -50.59371875],
              [173.720753314000149, -50.578891347000024],
              [173.715332166000053, -50.564047708000032],
              [173.710020992000068, -50.549188157999936],
              [173.704819800000337, -50.534313022999925],
              [173.699728605000018, -50.519422623999958],
              [173.694747412000083, -50.504517291999946],
              [173.689876220000087, -50.489597350999986],
              [173.685115039000038, -50.47466312399996],
              [173.680463861000135, -50.459714939000037],
              [173.675922685000273, -50.44475312000003],
              [173.671491507000269, -50.429777992000027],
              [173.667170316000238, -50.414789879999944],
              [173.662959103000048, -50.39978911299994],
              [173.6588578530002, -50.384776011000042],
              [173.654866552000271, -50.369750903999943],
              [173.650985179000145, -50.354714115000036],
              [173.647213716000351, -50.339665965999984],
              [173.643552138000018, -50.324606785999947],
              [173.64000042000032, -50.309536901999934],
              [173.636558533000198, -50.294456631999935],
              [173.633226447000084, -50.279366307999965],
              [173.630004129000099, -50.264266248999945],
              [173.626891545000262, -50.249156782],
              [173.623888656000076, -50.234038231999989],
              [173.620995420000327, -50.218910922999896],
              [173.618211798000061, -50.20377517899999],
              [173.615537746000086, -50.188631322999925],
              [173.612973212000156, -50.173479680999947],
              [173.610518152000054, -50.158320574999948],
              [173.608172509000042, -50.143154331],
              [173.605936234000069, -50.12798127100001],
              [173.603809267000202, -50.112801718000014],
              [173.601791550000144, -50.097615994999956],
              [173.599883035000062, -50.082424245000027],
              [173.597815429000207, -50.067125295999915],
              [173.595858591000137, -50.05182012399996],
              [173.594012482000267, -50.036509241000033],
              [173.5922770330003, -50.021192976999885],
              [173.590652177000266, -50.005871661000015],
              [173.589137843000231, -49.990545624],
              [173.587733960000151, -49.975215194000043],
              [173.586440449000179, -49.959880702000021],
              [173.585257236000075, -49.944542474999935],
              [173.584184238000262, -49.929200842999933],
              [173.583221375000107, -49.913856133999957],
              [173.582368562000283, -49.898508676999938],
              [173.581625712000346, -49.8831588],
              [173.580992738000191, -49.867806830999939],
              [173.580469546000245, -49.852453096000033],
              [173.580056044000116, -49.837097922999874],
              [173.579752138000345, -49.821741639000038],
              [173.579557730000261, -49.806384573],
              [173.579472718000034, -49.791027050000025],
              [173.579497003000142, -49.775669392999987],
              [173.57963047800024, -49.76031193],
              [173.579873039000177, -49.744954990999986],
              [173.580224576000205, -49.729598892999945],
              [173.580684978000249, -49.714243965000037],
              [173.581254136000325, -49.698890533999929],
              [173.581931931000327, -49.683538921000036],
              [173.582718250000084, -49.66818945],
              [173.583612972000083, -49.652842446000022],
              [173.584615976000123, -49.637498229000016],
              [173.585727138000038, -49.622157125999877],
              [173.586946335000107, -49.606819456000039],
              [173.588273441000297, -49.591485541999987],
              [173.589708324000298, -49.576155707999987],
              [173.591250853000247, -49.560830272000032],
              [173.592900896000288, -49.545509556999889],
              [173.594658320000235, -49.530193882999924],
              [173.59652298400033, -49.514883571000041],
              [173.598494752000192, -49.499578938000013],
              [173.600573478000115, -49.484280305000027],
              [173.602759027000161, -49.468987993000042],
              [173.605051248000336, -49.453702316999966],
              [173.607449996000241, -49.438423593999914],
              [173.609955123000304, -49.423152148999975],
              [173.612566476000097, -49.407888290999836],
              [173.61528390400008, -49.392632339999921],
              [173.618107253000176, -49.377384614],
              [173.621036367000215, -49.362145423999948],
              [173.624071087000289, -49.346915090000039],
              [173.627211253000041, -49.331693925999957],
              [173.630456703000164, -49.316482245999907],
              [173.633807273000343, -49.301280361999943],
              [173.637262798000165, -49.28608859000002],
              [173.640823111000259, -49.270907239999929],
              [173.644488043000138, -49.255736629000012],
              [173.648257421000039, -49.240577066999947],
              [173.652131074000067, -49.225428861999887],
              [173.656108828000015, -49.210292330000016],
              [173.660190504000184, -49.195167776999938],
              [173.664375927000037, -49.180055517999961],
              [173.668664918000104, -49.164955857999985],
              [173.673057291000305, -49.149869107999962],
              [173.677552867000145, -49.134795573999952],
              [173.682151457000145, -49.11973556800001],
              [173.686852879000298, -49.104689395000037],
              [173.691656941000247, -49.089657359],
              [173.696563457000138, -49.074639771999898],
              [173.701572230000238, -49.059636933999968],
              [173.706683072000146, -49.04464915199992],
              [173.711895783000273, -49.029676731999928],
              [173.717210170000044, -49.014719977000027],
              [173.722626035000076, -48.99977918899998],
              [173.728143174000138, -48.98485467200004],
              [173.733761388000062, -48.969946727000035],
              [173.73948047600004, -48.955055655999956],
              [173.745300230000225, -48.940181761999952],
              [173.751220447000037, -48.925325341999951],
              [173.757240916000228, -48.91048669700001],
              [173.763361427000063, -48.895666128999949],
              [173.769581773000141, -48.880863931999954],
              [173.775901740000222, -48.866080406000037],
              [173.782321111000101, -48.851315847000023],
              [173.788839674000201, -48.83657055499998],
              [173.795457211000212, -48.821844819999967],
              [173.802173500000208, -48.80713894500002],
              [173.808988326000076, -48.792453219000038],
              [173.815901465000138, -48.777787937000028],
              [173.822912693000148, -48.763143394],
              [173.83002178900017, -48.748519883000029],
              [173.83722852100027, -48.733917693000024],
              [173.844532668000056, -48.719337118999931],
              [173.851933998000163, -48.704778448999889],
              [173.859432279000316, -48.690241974],
              [173.867027283000311, -48.675727983999934],
              [173.874718775000019, -48.661236766999934],
              [173.882506519000117, -48.646768612999949],
              [173.890390281000123, -48.632323805999988],
              [173.898369824000156, -48.61790263599994],
              [173.906444908000253, -48.603505385999938],
              [173.914615293000224, -48.589132345],
              [173.922880738000117, -48.574783792999938],
              [173.931241000000341, -48.560460016999969],
              [173.939695836000169, -48.546161298000015],
              [173.948244998000064, -48.531887920999964],
              [173.956888240000012, -48.517640165999978],
              [173.965625314000135, -48.503418313999987],
              [173.97445597300009, -48.489222643999938],
              [173.983379963000175, -48.475053438999964],
              [173.992397033000202, -48.460910973999944],
              [174.001506932000211, -48.446795530999964],
              [174.010709402000202, -48.432707381999933],
              [174.020004189000247, -48.418646808],
              [174.029391035000231, -48.404614082999956],
              [174.038869684000019, -48.390609484000038],
              [174.048439871000028, -48.376633280999954],
              [174.058101342000327, -48.362685748999922],
              [174.067853830000104, -48.34876716499997],
              [174.077697074000071, -48.334877796],
              [174.087630811000167, -48.32101791499997],
              [174.097654772000055, -48.307187791999979],
              [174.10776857600024, -48.293387477999943],
              [174.107180631000119, -48.290354799999932],
              [174.098047719000306, -48.296421737999935],
              [174.081718945000034, -48.307163415],
              [174.065309631000332, -48.317852646],
              [174.048820115000012, -48.328489168999965],
              [174.032250746000045, -48.33907273],
              [174.015601871000285, -48.349603067999965],
              [173.998873844000286, -48.360079932999952],
              [173.98206701800018, -48.370503067999948],
              [173.965181748000191, -48.380872221999937],
              [173.948218394000151, -48.391187148000029],
              [173.931177322000053, -48.40144758699995],
              [173.914058892000185, -48.411653297999976],
              [173.89686347400027, -48.421804031000022],
              [173.879591437000158, -48.4318995349999],
              [173.862243154000112, -48.441939569999988],
              [173.844819000000143, -48.45192388800001],
              [173.827319349000049, -48.461852249],
              [173.809744588000058, -48.471724409999979],
              [173.792095093000171, -48.481540127999949],
              [173.774371252000037, -48.491299167000022],
              [173.75657345500008, -48.50100128899993],
              [173.738702088000309, -48.510646251000026],
              [173.720757545000197, -48.520233825999981],
              [173.702740222000102, -48.529763772999956],
              [173.684650519000115, -48.539235860999952],
              [173.66648883400012, -48.548649858999966],
              [173.64825557000006, -48.558005535],
              [173.629951234000032, -48.567302568],
              [173.615822058000163, -48.579277979999972],
              [173.601604173000169, -48.591208615000028],
              [173.587297710000144, -48.603094303999967],
              [173.572902945000237, -48.614934772999959],
              [173.558420148000209, -48.626729741999952],
              [173.543849597000076, -48.638478928999973],
              [173.52919157600013, -48.650182064000035],
              [173.514446363000047, -48.661838871000043],
              [173.499614246000306, -48.67344907],
              [173.484695511000041, -48.685012392000033],
              [173.469690453000226, -48.696528562000026],
              [173.454599361000191, -48.707997308000017],
              [173.439422533000311, -48.71941836100001],
              [173.424160267000161, -48.73079144899998],
              [173.408812867000051, -48.742116301999943],
              [173.393380636000302, -48.753392654000038],
              [173.377863880000177, -48.764620237999964],
              [173.362262910000254, -48.775798784999949],
              [173.346578038000075, -48.786928032999924],
              [173.330809579000061, -48.798007712999947],
              [173.314957853000124, -48.809037565999972],
              [173.299023175000116, -48.820017327999949],
              [173.283005872000103, -48.830946735999987],
              [173.266906271000039, -48.841825533000019],
              [173.250724698000226, -48.85265345900001],
              [173.234461486000214, -48.863430253999979],
              [173.218116964000046, -48.874155658],
              [173.201691476000065, -48.884829421999939],
              [173.185185355000272, -48.895451285000036],
              [173.168598943000347, -48.906020997999974],
              [173.151932587000147, -48.916538300999946],
              [173.135186636000071, -48.927002946999949],
              [173.118361433000132, -48.937414684999965],
              [173.101457333000212, -48.947773264000034],
              [173.08447469400005, -48.958078434999948],
              [173.067413870000109, -48.96832995099993],
              [173.050275224000302, -48.978527566000025],
              [173.03305911200016, -48.988671032999946],
              [173.015765907000059, -48.998760111000024],
              [172.998395974000346, -49.008794556999931],
              [172.980949681000197, -49.018774122999901],
              [172.96342740300031, -49.028698574000032],
              [172.945829517000334, -49.038567672],
              [172.92815639700018, -49.048381173999985],
              [172.91040842700022, -49.058138843999984],
              [172.892585987000075, -49.067840446999867],
              [172.874689468000042, -49.077485748000015],
              [172.856719253000051, -49.087074514],
              [172.838675733000173, -49.096606511999937],
              [172.820559304000199, -49.106081512],
              [172.802370358000189, -49.115499281000041],
              [172.784109786000045, -49.124859827999956],
              [172.770843354000021, -49.132068849999939],
              [172.757536052000319, -49.139246702999912],
              [172.744188182000244, -49.146393413999988],
              [172.728187676000061, -49.154924560999973],
              [172.712129044000108, -49.163410628999941],
              [172.696012575000054, -49.171851646999983],
              [172.679838782000047, -49.180247468999937],
              [172.661801378000291, -49.190057277],
              [172.643688223000254, -49.199809577999986],
              [172.625499585000057, -49.209504207],
              [172.607235864000131, -49.219140921999951],
              [172.588897467000265, -49.228719484999942],
              [172.570484803000227, -49.238239657999941],
              [172.551998284000319, -49.247701207000034],
              [172.53343832500002, -49.257103898999958],
              [172.514805338000315, -49.266447493999976],
              [172.49609974700013, -49.275731765999979],
              [172.477321971000038, -49.284956480999938],
              [172.458472436000307, -49.29412141],
              [172.439551567000194, -49.303226326000015],
              [172.420559793000166, -49.312271003999967],
              [172.40149754700019, -49.32125521699993],
              [172.382365264000214, -49.330178740999941],
              [172.363163381000049, -49.339041351999924],
              [172.343892334000316, -49.34784283],
              [172.324552569000161, -49.356582954999965],
              [172.305144526000277, -49.365261511999961],
              [172.285668655000109, -49.373878280000042],
              [172.266125405000253, -49.382433043],
              [172.246515227000259, -49.390925589999938],
              [172.226838573000236, -49.399355706999984],
              [172.207095903000209, -49.407723183999963],
              [172.187287672000252, -49.416027806999978],
              [172.167414348000079, -49.424269372999959],
              [172.147476387000211, -49.432447669999931],
              [172.127474258000234, -49.440562497999963],
              [172.107408430000305, -49.448613651000016],
              [172.087279371000022, -49.456600923999872],
              [172.067087561000221, -49.464524119999965],
              [172.046833467000056, -49.472383036],
              [172.026517570000351, -49.480177476999927],
              [172.006140352000216, -49.48790724700001],
              [171.985702289000187, -49.495572146999962],
              [171.965203873000178, -49.503171989],
              [171.944645588000185, -49.510706577],
              [171.924027920000071, -49.518175721000034],
              [171.903351361000205, -49.525579236999818],
              [171.882616408000104, -49.532916931999978],
              [171.861823553000022, -49.540188623999882],
              [171.840973295000026, -49.54739412899994],
              [171.820066133000182, -49.554533262],
              [171.805413594000129, -49.559476719000031],
              [171.825960373000271, -49.566676869999981],
              [171.846835889000204, -49.574067648999971],
              [171.867664822000052, -49.581517534],
              [171.888446785000156, -49.589026385000011],
              [171.909181383000146, -49.596594074999985],
              [171.929868228000146, -49.604220460000022],
              [171.950506930000188, -49.61190540900003],
              [171.971097096000108, -49.619648779999977],
              [171.991638336000051, -49.627450433999968],
              [172.012130266000213, -49.635310232999984],
              [172.032572490000234, -49.643228033999947],
              [172.052964625000186, -49.651203693999946],
              [172.073306281000328, -49.659237069000028],
              [172.093597068000122, -49.667328013999963],
              [172.113836602000333, -49.675476384999968],
              [172.134024492000208, -49.683682033999958],
              [172.154160356000034, -49.691944810999985],
              [172.174243803000195, -49.700264568999842],
              [172.194274449000204, -49.708641156999988],
              [172.214251908000165, -49.717074421],
              [172.234175795000169, -49.725564213999974],
              [172.254045727000175, -49.734110375999933],
              [172.273861317000183, -49.742712756999936],
              [172.293622182000149, -49.7513712],
              [172.313327936000064, -49.760085546000028],
              [172.332978200000184, -49.768855638999923],
              [172.352572588000157, -49.77768131900001],
              [172.372110721000013, -49.786562428000025],
              [172.391592213000195, -49.795498802999816],
              [172.411016688000245, -49.804490281999961],
              [172.430383758000062, -49.813536701999965],
              [172.449693047000324, -49.822637895000014],
              [172.468944174000058, -49.83179370199997],
              [172.488136760000202, -49.84100395199988],
              [172.507270424000239, -49.850268475],
              [172.526344790000167, -49.859587108000028],
              [172.545359477000034, -49.868959679999932],
              [172.564314108000076, -49.878386015999865],
              [172.583208307000064, -49.887865948000012],
              [172.602041696000242, -49.897399303999919],
              [172.620813897000176, -49.906985904999885],
              [172.639524536000067, -49.91662557899997],
              [172.658173239000234, -49.926318149],
              [172.676759628000013, -49.936063437999962],
              [172.695283331000155, -49.945861267999987],
              [172.713743973000078, -49.955711459999918],
              [172.732141180000212, -49.965613830999985],
              [172.750474583000141, -49.97556820199992],
              [172.76874380300012, -49.98557439199999],
              [172.786948475000031, -49.995632214000011],
              [172.805088223000212, -50.005741482999895],
              [172.82316268000011, -50.015902016999974],
              [172.841171473000031, -50.026113625999983],
              [172.859114233000128, -50.036376122000036],
              [172.876990593000073, -50.04668932099986],
              [172.894800181000164, -50.057053028000041],
              [172.912542632000282, -50.067467055999884],
              [172.930217575000142, -50.077931208],
              [172.947824647000175, -50.088445296000032],
              [172.965363481000281, -50.099009123999963],
              [172.982833714000208, -50.109622496999975],
              [173.000234974000136, -50.120285219999971],
              [173.017566901000237, -50.130997092999962],
              [173.034829132000027, -50.141757921000021],
              [173.052021304000164, -50.152567504999979],
              [173.069143050000207, -50.163425641999936],
              [173.086194013000295, -50.17433213399994],
              [173.103173829000298, -50.185286775999941],
              [173.120082138000157, -50.196289368],
              [173.136918579000053, -50.207339701999956],
              [173.153682796000112, -50.218437576999975],
              [173.170374427000183, -50.229582783999945],
              [173.186993114000188, -50.240775117999988],
              [173.203538501000025, -50.252014368],
              [173.22001023200005, -50.263300324999989],
              [173.236407946000327, -50.274632781999969],
              [173.252731294000341, -50.286011525],
              [173.268979917000138, -50.297436343000015],
              [173.285153462000153, -50.308907022999961],
              [173.30125157700013, -50.320423349],
              [173.317273909000164, -50.33198510699998],
              [173.333220105000237, -50.343592078999961],
              [173.349089814000308, -50.355244054000025],
              [173.364882685000225, -50.366940805999945],
              [173.380598370000143, -50.378682119999951],
              [173.39623651900024, -50.390467776999969],
              [173.411796783000227, -50.402297552],
              [173.427278816000012, -50.41417122799993],
              [173.442682271000194, -50.42608857700003],
              [173.458006800000135, -50.43804937799996],
              [173.473252060000277, -50.450053408000031],
              [173.488417706000291, -50.462100434999968],
              [173.503503396000042, -50.474190237999949],
              [173.518508783000243, -50.486322582999946],
              [173.533433529000149, -50.498497249000025],
              [173.548277289000225, -50.510713998999961],
              [173.563039724000078, -50.522972608999964],
              [173.577720498000303, -50.535272840999937],
              [173.592319267000221, -50.547614466000027],
              [173.606835697000179, -50.55999725099997],
              [173.621269447000145, -50.572420957999952],
              [173.635620183000242, -50.584885357000019],
              [173.649887572000239, -50.597390206999933],
              [173.664071273000104, -50.609935270999983],
              [173.67817095900034, -50.622520311999949],
              [173.692186293000191, -50.63514509099997],
              [173.706116943000325, -50.647809368999958],
              [173.719962580000015, -50.660512903999972],
              [173.733722873000033, -50.673255453000024],
              [173.747397494000211, -50.686036770999976],
              [173.760986111000193, -50.698856620999948],
            ],
          ],
          [
            [
              [-177.864540286999954, -34.596823522000022],
              [-177.882685287000015, -34.600603522000029],
              [-177.900852286999907, -34.604311521999989],
              [-177.919041286999942, -34.60794652200002],
              [-177.937252286999922, -34.611509522],
              [-177.955484286999962, -34.614999521999948],
              [-177.973737286999977, -34.618417521999959],
              [-177.992010286999943, -34.621763522000037],
              [-178.010304286999968, -34.625035521999962],
              [-178.028617286999946, -34.628235521999954],
              [-178.046949286999904, -34.631362521999961],
              [-178.065300286999957, -34.634416521999981],
              [-178.083669286999964, -34.637397521999958],
              [-178.102055286999956, -34.640305521999892],
              [-178.120460286999986, -34.643140521999896],
              [-178.138881286999947, -34.645902521999972],
              [-178.157318286999981, -34.648590522],
              [-178.175772286999916, -34.651205522],
              [-178.194241286999869, -34.653747522],
              [-178.212725286999955, -34.656215522000025],
              [-178.231224286999975, -34.658609521999949],
              [-178.249737286999903, -34.660930521999944],
              [-178.268264286999937, -34.663178521999953],
              [-178.286804286999967, -34.66535152199998],
              [-178.305358286999933, -34.667451522000022],
              [-178.323923286999985, -34.669477521999966],
              [-178.342501286999948, -34.67143052199998],
              [-178.36109128699988, -34.673308521999957],
              [-178.379691286999986, -34.675112522],
              [-178.39830228699995, -34.676843521999956],
              [-178.416924286999915, -34.678499522000038],
              [-178.435555286999943, -34.680081522000023],
              [-178.454196286999974, -34.681589521999967],
              [-178.472845286999956, -34.683023521999985],
              [-178.491503286999915, -34.684383521999962],
              [-178.510169286999968, -34.685669521999955],
              [-178.528843287, -34.686880521999967],
              [-178.547523286999962, -34.688017522],
              [-178.566211286999987, -34.68908052200004],
              [-178.584904286999972, -34.690069521999874],
              [-178.603604286999854, -34.69098352200001],
              [-178.622308286999868, -34.691823521999879],
              [-178.641018286999923, -34.692588521999937],
              [-178.659731286999971, -34.693279522000012],
              [-178.678449287999825, -34.693895522],
              [-178.69717028799991, -34.694438521999984],
              [-178.715895287999956, -34.694905522],
              [-178.73462228799994, -34.695298521999973],
              [-178.753351287999891, -34.69561752200002],
              [-178.772082288, -34.69586152200003],
              [-178.790814287999922, -34.696031521999942],
              [-178.809547287999948, -34.696126521999929],
              [-178.828280287999945, -34.69614752199999],
              [-178.847013287999971, -34.696093521999956],
              [-178.865746287999912, -34.695965521999938],
              [-178.884477287999914, -34.695762522],
              [-178.903207287999948, -34.695485522000013],
              [-178.921936288000012, -34.695133521999935],
              [-178.94066228799997, -34.694707521999931],
              [-178.959385287999879, -34.694206521999945],
              [-178.978104287999969, -34.693631521999976],
              [-178.996821287999978, -34.692982522000023],
              [-179.015533287999943, -34.692258521999975],
              [-179.03424028799995, -34.691459521999946],
              [-179.052942287999969, -34.690586521999933],
              [-179.071639287999943, -34.689639522],
              [-179.090330287999961, -34.688618521999899],
              [-179.109014287999827, -34.687522522],
              [-179.127692287999906, -34.686352522],
              [-179.146362287999949, -34.685108522],
              [-179.165024287999955, -34.683789522000041],
              [-179.183679287999979, -34.682396521999976],
              [-179.202324287999915, -34.680929521999929],
              [-179.220961287999984, -34.679388521999954],
              [-179.239588287999936, -34.677773522],
              [-179.258205287999942, -34.67608452199994],
              [-179.276811287999919, -34.674321522000014],
              [-179.295407287999979, -34.672484522],
              [-179.313991287999926, -34.670573521999984],
              [-179.332564287999929, -34.668588521999936],
              [-179.351124287999937, -34.666529522000019],
              [-179.369672287999919, -34.664396521999947],
              [-179.388206287999907, -34.662190521999946],
              [-179.406727287999956, -34.659910522000018],
              [-179.425234287999928, -34.657556521999936],
              [-179.443727287999906, -34.655129522000038],
              [-179.462204287999953, -34.652628521999986],
              [-179.480666287999924, -34.650054521999948],
              [-179.499113287999904, -34.64740752199998],
              [-179.517543287999985, -34.644686521999972],
              [-179.535957287999821, -34.641892522000035],
              [-179.55435328799993, -34.639024521999943],
              [-179.572732287999941, -34.636084521999976],
              [-179.591093287999911, -34.633071521999966],
              [-179.609435288000014, -34.629984521999916],
              [-179.627759287999851, -34.626825521999933],
              [-179.646063287999965, -34.623593522000021],
              [-179.664348287999957, -34.620288521999953],
              [-179.68261328799997, -34.616910521999955],
              [-179.700856287999869, -34.613460521999968],
              [-179.719079287999961, -34.609937522],
              [-179.737280287999909, -34.606342521999977],
              [-179.755460288999842, -34.60267552199997],
              [-179.773617288999958, -34.598935521999977],
              [-179.757668288999923, -34.605701521999947],
              [-179.74175228799993, -34.61252352199989],
              [-179.725871287999979, -34.619401522000032],
              [-179.710024287999914, -34.626335521999977],
              [-179.694212287999903, -34.63332552199995],
              [-179.678435287999974, -34.640371522],
              [-179.662693287999986, -34.647473522000041],
              [-179.646986287999965, -34.654630521999877],
              [-179.631316288, -34.661843522000026],
              [-179.615681287999962, -34.66911152199998],
              [-179.600083288, -34.676435521999963],
              [-179.584522287999988, -34.68381352199998],
              [-179.568998287999904, -34.691246522000029],
              [-179.553511287999896, -34.698735522],
              [-179.538061287999909, -34.706277521999937],
              [-179.522650287999937, -34.713875521999967],
              [-179.507276287999957, -34.721527521999917],
              [-179.491941287999964, -34.729233521999959],
              [-179.476645287999929, -34.736993521999977],
              [-179.462081287999979, -34.743686521999962],
              [-179.447549287999948, -34.750426521999941],
              [-179.433048287999952, -34.757214522000027],
              [-179.418579287999961, -34.764050521999934],
              [-179.404142287999946, -34.77093352199995],
              [-179.389736287999852, -34.777863522000018],
              [-179.37536328799996, -34.784840521999968],
              [-179.358979287999972, -34.792398521999985],
              [-179.342634287999942, -34.800016522000021],
              [-179.326329287999982, -34.80769452200002],
              [-179.310064287999978, -34.815432522000037],
              [-179.293840288000013, -34.823230522000017],
              [-179.277657287999943, -34.831087522000018],
              [-179.261514287999915, -34.839005522000036],
              [-179.245414287999978, -34.846981521999965],
              [-179.229355287999965, -34.855017521999969],
              [-179.213339287999958, -34.863113522],
              [-179.197365287999872, -34.871267521999982],
              [-179.181434287999963, -34.879480522],
              [-179.165546287999973, -34.887751521999974],
              [-179.149702287999986, -34.896081521999974],
              [-179.133901287999919, -34.904469522],
              [-179.118145287999937, -34.912916521999989],
              [-179.102433287999929, -34.921420522],
              [-179.08676628799995, -34.92998352199993],
              [-179.071144287999942, -34.938603521999937],
              [-179.055567287999935, -34.947280521999971],
              [-179.040036287999982, -34.956015521999973],
              [-179.024551287999941, -34.964807521999944],
              [-179.009112287999983, -34.973656522],
              [-178.993720287999906, -34.982562522000023],
              [-178.978374287999912, -34.991525522000018],
              [-178.963077287999823, -35.00054352199993],
              [-178.947826287999959, -35.00961952199998],
              [-178.932624287999971, -35.018750522],
              [-178.917470287999976, -35.027937521999945],
              [-178.902364287999944, -35.037180522000028],
              [-178.88730728799996, -35.04647952199997],
              [-178.872299287999965, -35.055833521999887],
              [-178.857341287999986, -35.065242522],
              [-178.842432287999912, -35.07470752200004],
              [-178.827574287999909, -35.084226522],
              [-178.812765287999923, -35.093799521999983],
              [-178.798008288, -35.103428522],
              [-178.783301287999933, -35.113110521999943],
              [-178.768646287999928, -35.122847522000029],
              [-178.754042287999937, -35.132637522000039],
              [-178.739490287999928, -35.142482521999966],
              [-178.72499028799993, -35.152379521999933],
              [-178.710543288, -35.162331521999931],
              [-178.696149287999987, -35.172335521999969],
              [-178.681807287999931, -35.182392521999986],
              [-178.667519286999976, -35.192502521999984],
              [-178.653285286999903, -35.202664521999964],
              [-178.639104286999924, -35.21287952199998],
              [-178.624978286999976, -35.223146521999922],
              [-178.610906286999978, -35.233465522000017],
              [-178.596890286999979, -35.243836522000038],
              [-178.582928287000016, -35.25425852199993],
              [-178.569022286999967, -35.264731521999977],
              [-178.555171286999865, -35.27525652199995],
              [-178.541377286999932, -35.285832521999964],
              [-178.527639286999857, -35.296458521999966],
              [-178.513957286999954, -35.30713552200001],
              [-178.500332287, -35.317862521999984],
              [-178.486765286999912, -35.328639521999946],
              [-178.473255286999972, -35.339466521999896],
              [-178.459803286999914, -35.350343521999946],
              [-178.446408286999969, -35.361269521999986],
              [-178.433073286999928, -35.372244521999903],
              [-178.419795286999971, -35.38326852199998],
              [-178.406577286999919, -35.394341522],
              [-178.393418286999946, -35.405463522],
              [-178.380318286999824, -35.416633522000041],
              [-178.36727828699992, -35.427851521999912],
              [-178.354298286999864, -35.439118522],
              [-178.341378286999941, -35.450431522999963],
              [-178.328519286999949, -35.46179352300004],
              [-178.315721286999832, -35.473201523],
              [-178.302984286999958, -35.48465752300001],
              [-178.290308286999903, -35.496159522999903],
              [-178.277694286999917, -35.507708522999962],
              [-178.265142286999918, -35.519303523000019],
              [-178.252652286999961, -35.53094452299996],
              [-178.240225286999987, -35.542632523],
              [-178.227860286999913, -35.554364523],
              [-178.215559286999962, -35.566143522999937],
              [-178.203321286999909, -35.57796652299993],
              [-178.191146286999924, -35.589834522999979],
              [-178.179035287, -35.601748522999969],
              [-178.166989286999922, -35.613705522999965],
              [-178.155006286999935, -35.62570752299996],
              [-178.143089286999952, -35.637753523000015],
              [-178.131236286999979, -35.649842522999904],
              [-178.119449286999924, -35.661975522999967],
              [-178.107726286999934, -35.674151522999978],
              [-178.096070287, -35.686371522999934],
              [-178.084480286999906, -35.698633522999955],
              [-178.072956286999954, -35.710938523000038],
              [-178.061498286999949, -35.723285522999959],
              [-178.050107286999918, -35.735674523],
              [-178.038783286999973, -35.748104522999938],
              [-178.027526286999915, -35.760577523],
              [-178.016337286999971, -35.773090522999951],
              [-178.005216286999968, -35.785645523000028],
              [-177.994162286999909, -35.798241522999945],
              [-177.98317728699999, -35.810877522999988],
              [-177.972260286999898, -35.823553522999987],
              [-177.961412286999916, -35.836270522999939],
              [-177.95063328699996, -35.849026522999964],
              [-177.93992428699994, -35.861822523],
              [-177.929284286999945, -35.874657523],
              [-177.918713286999974, -35.887531523],
              [-177.908213286999938, -35.900444523000033],
              [-177.897783286999982, -35.91339552300002],
              [-177.887423286999905, -35.926385523000022],
              [-177.877134286999905, -35.939412522999987],
              [-177.866916286999839, -35.952478522999968],
              [-177.856769286999935, -35.965581523000026],
              [-177.846694287, -35.97872152299999],
              [-177.836690286999925, -35.991898523000032],
              [-177.826758286999905, -36.005111523000039],
              [-177.816899286999956, -36.018361522999953],
              [-177.807112286999853, -36.031647522999947],
              [-177.79739728699991, -36.044969522999907],
              [-177.787755286999896, -36.058327523],
              [-177.778186286999954, -36.071720522999954],
              [-177.768691286999939, -36.085148522999873],
              [-177.75926928699991, -36.098611522999931],
              [-177.74992128699995, -36.112108522999961],
              [-177.740646286999919, -36.125640523000015],
              [-177.731446286999898, -36.139205522999987],
              [-177.722321286999914, -36.152805522999984],
              [-177.713270286999943, -36.166437523000013],
              [-177.704294286999982, -36.180103523000014],
              [-177.695393286999973, -36.193802522999988],
              [-177.686567286999974, -36.207533522999938],
              [-177.677817286999954, -36.221297523000032],
              [-177.669142286999914, -36.235093522999932],
              [-177.660544286999937, -36.248920523000038],
              [-177.652022286999937, -36.262779523],
              [-177.643576286999945, -36.276669522999953],
              [-177.635206286999932, -36.290590523],
              [-177.626914286999948, -36.304542522999952],
              [-177.618698286999972, -36.318524522999951],
              [-177.61056028699997, -36.332536522999987],
              [-177.602499285999926, -36.346578523],
              [-177.594515285999961, -36.360650523],
              [-177.586610285999939, -36.374750522999932],
              [-177.578782285999921, -36.388880522999955],
              [-177.571033285999931, -36.403038522999964],
              [-177.563362285999915, -36.417224522999902],
              [-177.555770285999955, -36.431439522999938],
              [-177.548257285999966, -36.445681522999962],
              [-177.540822285999951, -36.459951523000029],
              [-177.533467285999961, -36.474248522999972],
              [-177.526191285999886, -36.488572522999959],
              [-177.518995285999921, -36.502922523],
              [-177.511879285999981, -36.517299524],
              [-177.504842285999956, -36.531702523999968],
              [-177.497886285999954, -36.546130524000034],
              [-177.491010285999977, -36.560584524000035],
              [-177.484215285999909, -36.575063523999972],
              [-177.477500285999952, -36.589567524000017],
              [-177.47086728599993, -36.604095524],
              [-177.464314285999961, -36.618648524000037],
              [-177.457842285999874, -36.633225523999954],
              [-177.451452285999977, -36.647825523999984],
              [-177.44514428599993, -36.662448524000013],
              [-177.438918285999932, -36.677095523999981],
              [-177.432773285999986, -36.691764524],
              [-177.426710285999974, -36.706456523999975],
              [-177.42073028599998, -36.721170524],
              [-177.414833285999947, -36.735905523999975],
              [-177.409018285999935, -36.750662524],
              [-177.403285285999885, -36.765441523999982],
              [-177.397636285999909, -36.780240524000021],
              [-177.392070285999921, -36.795060524],
              [-177.386587285999923, -36.809900523999943],
              [-177.381188285999968, -36.824760524],
              [-177.375872285999861, -36.839640523999947],
              [-177.37064028599994, -36.854539524000018],
              [-177.365492285999977, -36.86945752400004],
              [-177.360428285999916, -36.884394524000015],
              [-177.355449285999981, -36.899349524],
              [-177.350553285999979, -36.91432352399994],
              [-177.345743285999987, -36.929314523999949],
              [-177.341017285999953, -36.944323523999969],
              [-177.336375285999907, -36.959349523999947],
              [-177.331819285999899, -36.974392524],
              [-177.32734828599996, -36.989452523999944],
              [-177.322962285999978, -37.004527523999911],
              [-177.31866228599992, -37.01961952399995],
              [-177.314447285999961, -37.03472652399995],
              [-177.310318285999983, -37.049849523999967],
              [-177.306275285999959, -37.064987524],
              [-177.302317285999948, -37.080139524],
              [-177.298446285999859, -37.095306524000023],
              [-177.29466128599995, -37.110486523999953],
              [-177.290963285999936, -37.125681523999958],
              [-177.287350285999963, -37.140889523999931],
              [-177.283825285999967, -37.156110523999985],
              [-177.280386285999924, -37.171343523999951],
              [-177.277034285999946, -37.186590524],
              [-177.273769285999975, -37.201848524000013],
              [-177.270592285999925, -37.217118524],
              [-177.26750128599997, -37.232400523999956],
              [-177.264498285999935, -37.247692524],
              [-177.261582286, -37.262996523999902],
              [-177.258754285999856, -37.278310524],
              [-177.256014285999981, -37.293634524000026],
              [-177.253361285999944, -37.30896952400002],
              [-177.250796286, -37.324313523999933],
              [-177.248319285999941, -37.339666524],
              [-177.245931285999973, -37.355027524000036],
              [-177.243630285999927, -37.370398524],
              [-177.24141828599997, -37.385777523999934],
              [-177.239295285999987, -37.401163523999969],
              [-177.237260285999838, -37.416558524000038],
              [-177.235313285999922, -37.431959524000035],
              [-177.233455285999952, -37.447368524000012],
              [-177.231686285999871, -37.462783524000031],
              [-177.230006285999934, -37.478205523999975],
              [-177.228415285999944, -37.49363252400002],
              [-177.226913285999927, -37.509065523999936],
              [-177.225501285999968, -37.524504524],
              [-177.224177285999929, -37.539947524000013],
              [-177.222943285999975, -37.555395524],
              [-177.22179828599991, -37.570848523999985],
              [-177.22074328599993, -37.586304524000013],
              [-177.219777285999953, -37.601764525000021],
              [-177.218901285999948, -37.61722852500003],
              [-177.218115285999914, -37.632694524999977],
              [-177.217418285999969, -37.648163525000029],
              [-177.21681228599985, -37.663635524999961],
              [-177.216295285999962, -37.679108525],
              [-177.215869285999901, -37.694583524999985],
              [-177.215532285999984, -37.710060525000017],
              [-177.215285285999897, -37.72553852499999],
              [-177.21512928599995, -37.741016524999964],
              [-177.215063286, -37.756495525],
              [-177.21508828599994, -37.771973524999964],
              [-177.215202285999936, -37.787452524999935],
              [-177.21540728599993, -37.802930525000022],
              [-177.215703285999922, -37.818406524999943],
              [-177.216089285999971, -37.833882525000035],
              [-177.216566285999903, -37.849356524999962],
              [-177.217133285999921, -37.864828525],
              [-177.217791285999937, -37.880298524999944],
              [-177.218540285999921, -37.895765525],
              [-177.219379285999963, -37.911229524999953],
              [-177.220310286, -37.926689524999972],
              [-177.221331285999923, -37.942147524999939],
              [-177.222443285999987, -37.957600524999975],
              [-177.223646285999962, -37.973049524999965],
              [-177.224939285999909, -37.988493524999967],
              [-177.226324285999937, -38.003932525000039],
              [-177.227800285999933, -38.019366524999953],
              [-177.22936628599993, -38.034795524999879],
              [-177.231024285999979, -38.050217524999937],
              [-177.232773285999912, -38.065633524999953],
              [-177.234613285999927, -38.08104252499993],
              [-177.236544285999912, -38.096445525000036],
              [-177.238566285999894, -38.111840525],
              [-177.240679285999931, -38.127228524999964],
              [-177.24288428599985, -38.14260752499996],
              [-177.245179285999939, -38.157978524999976],
              [-177.247566285999909, -38.173341524999955],
              [-177.250044285999934, -38.188694524999903],
              [-177.252613285999956, -38.204039524999985],
              [-177.255273285999948, -38.21937352499998],
              [-177.258024285999937, -38.234698525],
              [-177.260866285999981, -38.250012524999931],
              [-177.263800285999906, -38.265316525],
              [-177.266825285999914, -38.280608524999934],
              [-177.269941285999977, -38.295890525],
              [-177.273148285999952, -38.311159525],
              [-177.276446285999981, -38.326417525000011],
              [-177.27983528599998, -38.341662525],
              [-177.283315285999976, -38.35689552499997],
              [-177.286887285999939, -38.372114525000029],
              [-177.290549285999958, -38.387321524999948],
              [-177.294303285999973, -38.402513525000018],
              [-177.298147285999903, -38.41769252499995],
              [-177.302083285999942, -38.432856524999977],
              [-177.306109286, -38.448005524999928],
              [-177.310226285999931, -38.463140524999972],
              [-177.314434285999937, -38.478259525],
              [-177.318733285999912, -38.49336352499995],
              [-177.323123285999969, -38.508450525],
              [-177.327604285999911, -38.523521524999978],
              [-177.332175285999938, -38.538576525],
              [-177.336837285999934, -38.553613525],
              [-177.34158928599993, -38.568633525],
              [-177.346433285999979, -38.583635525000034],
              [-177.351366285999916, -38.598620525],
              [-177.356390285999936, -38.613586525000017],
              [-177.361505285999868, -38.628533525000023],
              [-177.366710285999858, -38.643461524999964],
              [-177.372005285999904, -38.658370524999953],
              [-177.377391285999948, -38.673259525999981],
              [-177.382867285999964, -38.688129525999955],
              [-177.388432285999983, -38.702978526000038],
              [-177.394088285999914, -38.717806525999947],
              [-177.399834285999987, -38.732613525999966],
              [-177.405670285999946, -38.747399525999981],
              [-177.411596285999821, -38.762163525999938],
              [-177.417611285999925, -38.776905525999894],
              [-177.420038285999937, -38.79209752600002],
              [-177.422555285999834, -38.807280525999943],
              [-177.425163285999957, -38.822454526],
              [-177.427860285999913, -38.837618526000028],
              [-177.430647285999925, -38.852772525999967],
              [-177.433525285999934, -38.867915525999933],
              [-177.43575928599995, -38.883016526],
              [-177.438082285999968, -38.89810852599993],
              [-177.440494285999961, -38.913192525999932],
              [-177.442995285999956, -38.928267525999956],
              [-177.445585285999925, -38.94333352599989],
              [-177.448263285999985, -38.95839052599996],
              [-177.45103128599996, -38.973436526],
              [-177.453887285999912, -38.988473526],
              [-177.456832285999951, -39.003499525999985],
              [-177.459866285999908, -39.018515525999931],
              [-177.462989285999925, -39.033519525999964],
              [-177.466201285999944, -39.048512525999968],
              [-177.469502285999965, -39.063493525999945],
              [-177.472891285999964, -39.07846352599995],
              [-177.476369285999965, -39.093420525999989],
              [-177.479936285999969, -39.108364525999946],
              [-177.483592285999947, -39.123296525999933],
              [-177.487337285999928, -39.138214525999956],
              [-177.491170285999914, -39.153118526000014],
              [-177.495092285999959, -39.168009526],
              [-177.499103285999922, -39.182886526],
              [-177.50320228599989, -39.197747526],
              [-177.507390285999918, -39.21259552600003],
              [-177.511667285999948, -39.227426525999931],
              [-177.516032285999898, -39.242243526000038],
              [-177.520486285999908, -39.257043525999961],
              [-177.525028285999923, -39.271828525999979],
              [-177.529659285999941, -39.286596526000039],
              [-177.534378285999935, -39.301347526000029],
              [-177.539185285999963, -39.316081526],
              [-177.544081285999937, -39.33079852599991],
              [-177.549064285999947, -39.345497526000031],
              [-177.554137285999929, -39.36017852599997],
              [-177.559297285999946, -39.374841526000012],
              [-177.564545285999912, -39.38948552599993],
              [-177.56988228599991, -39.404110525999954],
              [-177.575306285999972, -39.418716526000026],
              [-177.580818285999982, -39.433302525999977],
              [-177.58641828599994, -39.447868526000036],
              [-177.592106285999932, -39.462414525999975],
              [-177.597882286000015, -39.476940526000021],
              [-177.603745286999981, -39.49144552599995],
              [-177.608941286999936, -39.505909525999982],
              [-177.614222286999905, -39.520355525999946],
              [-177.619589286999968, -39.534782526000015],
              [-177.625043286999954, -39.549190526000018],
              [-177.630582286999953, -39.563579525999955],
              [-177.636207286999962, -39.577948525999943],
              [-177.641917286999984, -39.592297526000038],
              [-177.647714286999928, -39.606625526000016],
              [-177.653596286999971, -39.620933525999931],
              [-177.659563286999941, -39.635220525999955],
              [-177.665616286999921, -39.649486526000032],
              [-177.671755286999939, -39.663730526],
              [-177.677979286999943, -39.677952525999956],
              [-177.684288286999958, -39.692152525999973],
              [-177.69068228699993, -39.706330525999988],
              [-177.697161286999972, -39.720485526],
              [-177.703725286999969, -39.734617526000022],
              [-177.710375286999948, -39.748726526999974],
              [-177.717108286999974, -39.762811527],
              [-177.723927286999924, -39.776872526999966],
              [-177.730831286999972, -39.79090952699994],
              [-177.737818286999897, -39.804921527000033],
              [-177.744891286999973, -39.818908526999962],
              [-177.75204728699984, -39.832871527],
              [-177.759288286999976, -39.846807526999953],
              [-177.766613286999956, -39.860719526999958],
              [-177.774023286999949, -39.874604527000031],
              [-177.78151628699996, -39.888463527],
              [-177.789093286999929, -39.902295526999978],
              [-177.796753286999916, -39.916100526999969],
              [-177.804497286999919, -39.929878527000028],
              [-177.812442286999953, -39.944110527000021],
              [-177.820476287, -39.958312526999975],
              [-177.828598286999835, -39.972486526999944],
              [-177.836809286999937, -39.986629527],
              [-177.841332286999972, -40.000492526999949],
              [-177.845151286999936, -40.015053526999907],
              [-177.849056286999911, -40.029600526999957],
              [-177.853046286999842, -40.044134526999983],
              [-177.857122286999925, -40.058653526999876],
              [-177.861284286999961, -40.073159526999973],
              [-177.865532286999979, -40.087650526999937],
              [-177.869866286999923, -40.102126527],
              [-177.874285286999907, -40.116587527000036],
              [-177.878790286999958, -40.131033527],
              [-177.883380286999966, -40.14546252699995],
              [-177.888056286999955, -40.159876526999938],
              [-177.892817286999986, -40.174273527000025],
              [-177.897664286999913, -40.188654526999983],
              [-177.902596286999966, -40.20301852700004],
              [-177.907614286999944, -40.21736552699997],
              [-177.912717286999907, -40.231694527],
              [-177.917905286999911, -40.246005526999966],
              [-177.923179286999925, -40.260298526999975],
              [-177.903508286999937, -40.256969526999967],
              [-177.883818286999968, -40.253706526999963],
              [-177.864111286999986, -40.25051052699996],
              [-177.844387286999961, -40.247380526999962],
              [-177.82464628699995, -40.244317527000021],
              [-177.804888286999955, -40.241320527000028],
              [-177.785114286999942, -40.238390526999979],
              [-177.765325286999968, -40.23552652699999],
              [-177.745520286999977, -40.232730527],
              [-177.72570028699991, -40.230000527000016],
              [-177.705865286999938, -40.227337527000032],
              [-177.686016286999944, -40.224740527],
              [-177.666153286999958, -40.222211527000013],
              [-177.646277286999947, -40.219749527000033],
              [-177.626387286999972, -40.217353526999943],
              [-177.606484286999944, -40.215025526999966],
              [-177.586569285999843, -40.212764526999933],
              [-177.566642285999961, -40.210570526999959],
              [-177.546703285999911, -40.208443526999929],
              [-177.526752285999919, -40.206384527000012],
              [-177.506791285999924, -40.204392527000039],
              [-177.486819285999957, -40.20246752700001],
              [-177.466836285999904, -40.200609526999983],
              [-177.446844285999987, -40.198819526999955],
              [-177.426842285999982, -40.197096527000042],
              [-177.406831285999914, -40.195441526999957],
              [-177.386811285999983, -40.193853526999931],
              [-177.366783285999929, -40.19233352699996],
              [-177.346747285999925, -40.19088052699999],
              [-177.326702286, -40.189495526999963],
              [-177.306651285999976, -40.188177527],
              [-177.286593285999942, -40.186927527000023],
              [-177.266527285999956, -40.18574452699994],
              [-177.246456285999983, -40.184630526999968],
              [-177.226379285999968, -40.183583527],
              [-177.206296285999969, -40.182603527000026],
              [-177.186208285999953, -40.181691526999941],
              [-177.166116285999919, -40.180847527000026],
              [-177.146019285999927, -40.180071526999939],
              [-177.125918285999973, -40.179363526999964],
              [-177.105813285999943, -40.178722527],
              [-177.085705285999865, -40.178149526999903],
              [-177.065594285999879, -40.177644527000041],
              [-177.045481285999898, -40.17720752699995],
              [-177.025365285999925, -40.176837526999975],
              [-177.005247285999985, -40.176536526999939],
              [-176.985128285999878, -40.176302527000018],
              [-176.965008285999943, -40.176136526999983],
              [-176.944888286, -40.176038526999946],
              [-176.924767286, -40.176007526999967],
              [-176.904645285999976, -40.176045526999985],
              [-176.884525285999956, -40.176150526999947],
              [-176.864405285999908, -40.176324526999963],
              [-176.844286285999971, -40.176565527000037],
              [-176.824169285999943, -40.176873526999941],
              [-176.80405328599997, -40.177250526999956],
              [-176.783940285999904, -40.177694526999915],
              [-176.763829285999918, -40.178207526999984],
              [-176.743722285999922, -40.17878752699994],
              [-176.723617285999893, -40.17943552700001],
              [-176.703517285999908, -40.180150527000023],
              [-176.683420285999944, -40.180934527000034],
              [-176.66332828599991, -40.181785526999931],
              [-176.643241285999949, -40.182704526999942],
              [-176.623158285999978, -40.183690526999897],
              [-176.603082285999875, -40.184745526999961],
              [-176.583011285999987, -40.185867526999971],
              [-176.562947285999968, -40.187056527000038],
              [-176.542889285999934, -40.188313526999934],
              [-176.522838284999921, -40.189638527],
              [-176.502795284999962, -40.19103152699995],
              [-176.482760284999955, -40.192491526999959],
              [-176.46273228499993, -40.194018527000026],
              [-176.442713284999826, -40.195613526999978],
              [-176.422703284999926, -40.197276526999929],
              [-176.402702284999975, -40.199006527],
              [-176.382711284999885, -40.200803526999891],
              [-176.362730285, -40.202668527000014],
              [-176.342759284999943, -40.204600527000025],
              [-176.322798284999919, -40.206599527000037],
              [-176.302849284999922, -40.208666527],
              [-176.282911284999955, -40.21080052699989],
              [-176.262985285000013, -40.213001527000017],
              [-176.243071284999957, -40.215269526999975],
              [-176.223170284999952, -40.217604526999935],
              [-176.203282284999858, -40.22000752699995],
              [-176.18340728499993, -40.222476526999969],
              [-176.163545284999941, -40.225012526999933],
              [-176.143697284999945, -40.227616527],
              [-176.123864284999883, -40.230286527000032],
              [-176.104046284999953, -40.233023527],
              [-176.084243284999957, -40.235827527000026],
              [-176.06445528499998, -40.238697527],
              [-176.044683284999962, -40.241635526999971],
              [-176.024927284999961, -40.24463852699995],
              [-176.00518728499992, -40.247709526999927],
              [-175.98546528499989, -40.250846526999965],
              [-175.965760284999902, -40.25404952699995],
              [-175.946072284999929, -40.257319526999879],
              [-175.926403284999907, -40.26065552700004],
              [-175.906752284999897, -40.264057526999977],
              [-175.887119284999841, -40.26752652700003],
              [-175.867506284999848, -40.271061527000029],
              [-175.847912284999978, -40.274662526999975],
              [-175.828338284999944, -40.278328526999985],
              [-175.808785284999942, -40.282061526999939],
              [-175.789251284999978, -40.285860526999954],
              [-175.769739284999986, -40.289724527000033],
              [-175.750248284999969, -40.293654526999944],
              [-175.730779284999983, -40.297650526999973],
              [-175.711331284999886, -40.301711527],
              [-175.691906284999817, -40.305838527],
              [-175.672504284999945, -40.310031526999978],
              [-175.653125284999959, -40.314288527000031],
              [-175.633769284999914, -40.318611526999973],
              [-175.61443728499998, -40.322999527000036],
              [-175.595130284999982, -40.327453526999989],
              [-175.575846284999955, -40.331971526999951],
              [-175.556588284999918, -40.336554526999976],
              [-175.537355284999848, -40.341202526999894],
              [-175.518148284999938, -40.345915526999931],
              [-175.498966284999909, -40.350693527000033],
              [-175.479811284999982, -40.355535527000029],
              [-175.460682284999962, -40.360442527000032],
              [-175.441581283999966, -40.36541352699993],
              [-175.422507283999977, -40.370448526999951],
              [-175.403461283999917, -40.375548526999978],
              [-175.384443283999929, -40.380712527000014],
              [-175.365453283999983, -40.385939526999948],
              [-175.346492283999936, -40.391231526999945],
              [-175.327561283999955, -40.396587527],
              [-175.308659283999987, -40.402006527000026],
              [-175.289787283999857, -40.407489527],
              [-175.270945283999964, -40.413036527000031],
              [-175.252133284, -40.418646526999964],
              [-175.233353283999975, -40.424319526999966],
              [-175.214604283999904, -40.430055526999979],
              [-175.195887283999951, -40.435855527],
              [-175.177202283999918, -40.441718526999978],
              [-175.158549283999946, -40.447643526999968],
              [-175.139929283999948, -40.453632526999968],
              [-175.121342283999951, -40.459683526999981],
              [-175.102788283999985, -40.465796526999952],
              [-175.08426928399993, -40.471972527],
              [-175.065783283999906, -40.478211526999985],
              [-175.047332283999964, -40.484511527],
              [-175.028916283999962, -40.490874527000017],
              [-175.010535283999872, -40.497299527],
              [-174.992189283999977, -40.503785527],
              [-174.973880283999961, -40.510334527],
              [-174.95560728399991, -40.516943526999967],
              [-174.937370283999911, -40.523615527],
              [-174.919170283999932, -40.530347526999947],
              [-174.899737283999826, -40.534702527000036],
              [-174.880328283999916, -40.539122527000018],
              [-174.860943283999944, -40.543607527],
              [-174.841583283999938, -40.548157526999887],
              [-174.822248283999926, -40.552773527],
              [-174.802939283999962, -40.55745352699995],
              [-174.783656283999903, -40.562198526999907],
              [-174.764399283999978, -40.567008526999984],
              [-174.745168283999959, -40.571883527000011],
              [-174.725965283999955, -40.576822526999933],
              [-174.706789283999967, -40.581826526999976],
              [-174.687640283999912, -40.586894527000027],
              [-174.668520283999925, -40.592027526999971],
              [-174.649428283999981, -40.59722352700004],
              [-174.63036428399991, -40.602484526999945],
              [-174.611330283999962, -40.607809526999972],
              [-174.592326283999824, -40.613198527],
              [-174.573351283999926, -40.618651527],
              [-174.554406283999981, -40.624168526999938],
              [-174.535492283999986, -40.62974852699989],
              [-174.516609283999827, -40.635391526999967],
              [-174.497757283999903, -40.641099527],
              [-174.478937283999898, -40.646869527000035],
              [-174.460149283999925, -40.652703526999915],
              [-174.441393283999986, -40.658599527000035],
              [-174.422670283999906, -40.664559527],
              [-174.403980283999971, -40.670582526999965],
              [-174.385323283999952, -40.676667527],
              [-174.366700282999972, -40.682815526999946],
              [-174.348112282999949, -40.689026526999953],
              [-174.329557282999929, -40.695299527000032],
              [-174.311038282999959, -40.70163452700001],
              [-174.29255428299993, -40.708032527],
              [-174.274105282999955, -40.714492527],
              [-174.255692282999945, -40.721013526999968],
              [-174.237316282999984, -40.727597526999944],
              [-174.218976282999819, -40.734242526999935],
              [-174.200673282999929, -40.740948527],
              [-174.182407282999918, -40.747716526999966],
              [-174.164179282999868, -40.754546526999945],
              [-174.145989282999921, -40.761437527],
              [-174.127837282999934, -40.768388527000013],
              [-174.109724282999963, -40.775401526999929],
              [-174.091651282999919, -40.782475527000031],
              [-174.07361628299995, -40.789609526999982],
              [-174.055621282999965, -40.796803526999895],
              [-174.037667282999934, -40.804058526999938],
              [-174.019752282999917, -40.811374527999988],
              [-174.001879282999909, -40.818749528000012],
              [-173.984047282999938, -40.826185527999939],
              [-173.96625628299995, -40.833680527999945],
              [-173.948507282999913, -40.841235528000027],
              [-173.930800282999968, -40.848850527999957],
              [-173.913136282999943, -40.856524528000023],
              [-173.895515282999924, -40.864257527999939],
              [-173.877936282999912, -40.872050527999988],
              [-173.8604022829999, -40.879901527999948],
              [-173.84291128299995, -40.88781252800004],
              [-173.825465282999943, -40.895781527999929],
              [-173.80806328299991, -40.903808527999956],
              [-173.790706283, -40.911894527999948],
              [-173.773394282999931, -40.920038528000021],
              [-173.756128282999981, -40.928241528],
              [-173.738908282999915, -40.936501527999951],
              [-173.721734282999932, -40.944819528000039],
              [-173.704606282999947, -40.95319452800004],
              [-173.687526283000011, -40.961627527999951],
              [-173.670493282999956, -40.970118527999944],
              [-173.653508282999979, -40.978665527999965],
              [-173.636570282999969, -40.987270527999897],
              [-173.619681282999949, -40.995931528000028],
              [-173.60284128299989, -41.004649527999959],
              [-173.586049282999966, -41.013423528000033],
              [-173.569307282999858, -41.022254528000019],
              [-173.552615282999938, -41.031141527999978],
              [-173.535972282999921, -41.040084527999909],
              [-173.519380282999975, -41.049083527999926],
              [-173.502839282999872, -41.058137527999975],
              [-173.486348282999984, -41.067247527999939],
              [-173.469909282999964, -41.076412527999935],
              [-173.45352128299993, -41.085632528000019],
              [-173.437186282999818, -41.094907527999965],
              [-173.420902282999918, -41.104237527999942],
              [-173.404672282999968, -41.113622528],
              [-173.388494282999972, -41.123061527999937],
              [-173.372370282999981, -41.132554527999957],
              [-173.35629928299997, -41.142102528],
              [-173.340282282999965, -41.151703528000041],
              [-173.324320282999906, -41.16135852799988],
              [-173.308412281999864, -41.17106652800004],
              [-173.292558281999931, -41.18082852799995],
              [-173.27676128199991, -41.190643527999953],
              [-173.261018281999952, -41.200510527999938],
              [-173.245332281999964, -41.210431527999958],
              [-173.22970228199992, -41.220404528000017],
              [-173.214128281999905, -41.230430527999943],
              [-173.198612281999971, -41.240507527999966],
              [-173.183152281999952, -41.25063752799997],
              [-173.167750281999929, -41.260818528000016],
              [-173.15240628199993, -41.271052527999984],
              [-173.135660281999918, -41.280036527999982],
              [-173.118964281999951, -41.28907752799995],
              [-173.102320281999937, -41.298174528],
              [-173.085728282, -41.307327527999973],
              [-173.069187281999888, -41.316535528000031],
              [-173.052699281999907, -41.325799527999948],
              [-173.036263281999965, -41.33511852800001],
              [-173.019880281999946, -41.344492527999932],
              [-173.003550281999907, -41.353921527999944],
              [-172.987274281999959, -41.36340552799993],
              [-172.97105228199996, -41.372943527999951],
              [-172.954884281999824, -41.382535527999948],
              [-172.93877028199995, -41.392182528000035],
              [-172.922712281999935, -41.401883528000042],
              [-172.906708281999869, -41.411637527999972],
              [-172.890760281999974, -41.421445528],
              [-172.874868281999909, -41.431307528],
              [-172.859032281999987, -41.441222527999969],
              [-172.843253281999949, -41.451189527999986],
              [-172.827531281999853, -41.461210527999981],
              [-172.811865281999928, -41.471283527999958],
              [-172.796257281999914, -41.481409527999972],
              [-172.780707281999923, -41.491587528000025],
              [-172.765216281999926, -41.501817527999947],
              [-172.749782281999927, -41.512099527999965],
              [-172.734407281999864, -41.522433527999965],
              [-172.719092281999934, -41.532818528],
              [-172.703836281999941, -41.543254527999977],
              [-172.688639281999912, -41.553741527999932],
              [-172.673503281999984, -41.56428052800004],
              [-172.658427281999934, -41.574869527999965],
              [-172.6434122819999, -41.585508528],
              [-172.628458281999912, -41.596198528],
              [-172.613565281999911, -41.606938527999944],
              [-172.598734281999981, -41.617727527999989],
              [-172.583965281999951, -41.628567528000019],
              [-172.569258281999822, -41.639455528000042],
              [-172.554613281999906, -41.650393528],
              [-172.54003228199997, -41.661380528],
              [-172.525514281999932, -41.672416527999985],
              [-172.511059281999934, -41.683500528000025],
              [-172.496668281999916, -41.694633527999883],
              [-172.482341281999936, -41.705814527999962],
              [-172.468079281999934, -41.717042528000036],
              [-172.453882281999967, -41.728319527999986],
              [-172.439749281999894, -41.739643527999988],
              [-172.42568228199994, -41.751014527999985],
              [-172.411681281999932, -41.762432528000033],
              [-172.397746281999986, -41.773898527999961],
              [-172.383877281999986, -41.785409527999946],
              [-172.370074281999933, -41.796967528000039],
              [-172.356339281999908, -41.808572528000013],
              [-172.342670281999943, -41.820222527999931],
              [-172.329070281999833, -41.83191852799996],
              [-172.31553628199984, -41.843659527999932],
              [-172.302072281999955, -41.855446527999959],
              [-172.288675281999843, -41.867278528000043],
              [-172.275347281999842, -41.879154527999958],
              [-172.262088281999951, -41.891076528999974],
              [-172.248899282, -41.903041529],
              [-172.235779281999953, -41.915050529000041],
              [-172.222729280999971, -41.927104528999962],
              [-172.209749280999972, -41.939201529],
              [-172.19683928099991, -41.951341528999933],
              [-172.184001280999951, -41.963525529000037],
              [-172.171233280999928, -41.975751528999979],
              [-172.158537281, -41.988020529000039],
              [-172.145912280999909, -42.000332529],
              [-172.133359280999969, -42.01268652899995],
              [-172.120878280999904, -42.025081529000033],
              [-172.10847028099991, -42.037519528999951],
              [-172.096135280999903, -42.049998528999936],
              [-172.083872280999913, -42.062518528999988],
              [-172.071683280999906, -42.075079528999936],
              [-172.059568280999912, -42.087681528999894],
              [-172.047526280999904, -42.100323528999979],
              [-172.03555928099982, -42.11300552900002],
              [-172.02366628099989, -42.125728528999957],
              [-172.011848280999942, -42.138490529000023],
              [-172.000105280999975, -42.151292528999932],
              [-171.988437280999932, -42.164133528999969],
              [-171.976844280999956, -42.177012529000024],
              [-171.965328280999955, -42.189931529000035],
              [-171.953887280999936, -42.202888528999949],
              [-171.942523280999922, -42.215883528999939],
              [-171.93123628099994, -42.228916528999946],
              [-171.920025280999937, -42.241987529000028],
              [-171.908892280999936, -42.255095528999959],
              [-171.897836280999968, -42.268241528999965],
              [-171.886858280999917, -42.281423529],
              [-171.875958280999981, -42.29464252899993],
              [-171.865136280999934, -42.307897528999945],
              [-171.854392280999946, -42.32118952899998],
              [-171.843727280999929, -42.334516528999984],
              [-171.833141280999968, -42.347879528999954],
              [-171.822635280999947, -42.361277528999949],
              [-171.812208280999982, -42.374711528999967],
              [-171.801861280999987, -42.388179529000013],
              [-171.791594280999874, -42.401681528999973],
              [-171.781407280999957, -42.415218528999958],
              [-171.771301280999836, -42.428789529000028],
              [-171.761275280999911, -42.442393529000014],
              [-171.751331280999949, -42.456031529000029],
              [-171.741468280999953, -42.46970252899996],
              [-171.731687280999978, -42.483406528999978],
              [-171.72198728099994, -42.49714352899997],
              [-171.712369280999951, -42.510912529],
              [-171.702834280999923, -42.524713528999939],
              [-171.693381280999972, -42.538546528999973],
              [-171.684011281000011, -42.552410528999928],
              [-171.674724280999953, -42.56630652900003],
              [-171.66552128099994, -42.580232528999943],
              [-171.656400280999861, -42.594189528999948],
              [-171.647364280999966, -42.608177528999931],
              [-171.638411280999918, -42.622194529000012],
              [-171.629543280999911, -42.636242529000015],
              [-171.62075928099992, -42.650319528999944],
              [-171.612060280999941, -42.664425529000027],
              [-171.603446280999975, -42.678561529000035],
              [-171.594917280999937, -42.692724528999918],
              [-171.586474280999909, -42.706917528999895],
              [-171.578116280999922, -42.721137528999975],
              [-171.569843280999919, -42.735386528999982],
              [-171.561657280999924, -42.74966152899998],
              [-171.553557280999939, -42.76396452900002],
              [-171.545544281, -42.77829552899999],
              [-171.537617280999939, -42.792651528999954],
              [-171.529778280999949, -42.80703452900002],
              [-171.522025280999912, -42.821443529000021],
              [-171.514360280999966, -42.835878528999956],
              [-171.506782280999914, -42.850339529],
              [-171.49929328099995, -42.864825528999802],
              [-171.491891280999909, -42.879335529],
              [-171.484577280999957, -42.893870529000026],
              [-171.47735228099998, -42.908430528999929],
              [-171.470216280999921, -42.923013528999945],
              [-171.463169280999949, -42.937621528999955],
              [-171.456210280999954, -42.952251528999852],
              [-171.44934128099996, -42.966905529999906],
              [-171.442561280999968, -42.981582529999969],
              [-171.435871280999947, -42.996281529999976],
              [-171.429271280999984, -43.011002530000042],
              [-171.422761280999936, -43.025746529999935],
              [-171.416341280999887, -43.040510529999949],
              [-171.410012280999894, -43.055297529999848],
              [-171.403773280999957, -43.070104530000037],
              [-171.397625280999932, -43.08493252999989],
              [-171.39156928099996, -43.099780529999975],
              [-171.385603280999902, -43.114649529999951],
              [-171.379729280999982, -43.12953752999988],
              [-171.373947280999943, -43.144445530000041],
              [-171.368256280999987, -43.159372529999985],
              [-171.362657280999912, -43.174317529999769],
              [-171.357151280999972, -43.189282529999957],
              [-171.351736280999859, -43.204264529999932],
              [-171.346415280999935, -43.219265529999973],
              [-171.341186280999921, -43.234283530000027],
              [-171.336050280999956, -43.249318530000039],
              [-171.331007280999899, -43.264371529999949],
              [-171.326057280999947, -43.279440529999931],
              [-171.321200280999903, -43.294526529999985],
              [-171.316437280999963, -43.309627529999887],
              [-171.311768280999928, -43.32474553000003],
              [-171.30719328099994, -43.339877529999967],
              [-171.302712281, -43.355025530000034],
              [-171.298325280999904, -43.37018853],
              [-171.294032280999971, -43.385365529999945],
              [-171.289834280999912, -43.400557529999958],
              [-171.285731280999983, -43.415762529999938],
              [-171.281723280999927, -43.43098153],
              [-171.277809280999975, -43.446213529999966],
              [-171.273991280999923, -43.461458529999959],
              [-171.270268281, -43.476716530000033],
              [-171.266640280999923, -43.491985529999965],
              [-171.263108280999973, -43.507267529999979],
              [-171.259672280999979, -43.522561529999962],
              [-171.256332280999942, -43.537865530000033],
              [-171.253088280999947, -43.553181530000018],
              [-171.249940280999908, -43.568507530000034],
              [-171.246888280999968, -43.583844530000022],
              [-171.243933280999983, -43.599191529999985],
              [-171.241075280999951, -43.614547529999925],
              [-171.238313280999961, -43.629913529999953],
              [-171.235648280999982, -43.645288529999959],
              [-171.233080280999957, -43.66067153],
              [-171.230609280999971, -43.676063529999965],
              [-171.228235280999911, -43.691463530000021],
              [-171.225959280999973, -43.706871529999887],
              [-171.223780280999819, -43.722286530000019],
              [-171.221699280999928, -43.737708529999964],
              [-171.219716280999904, -43.75313753],
              [-171.217830280999834, -43.768573529999969],
              [-171.216043280999855, -43.784014530000036],
              [-171.214353280999916, -43.799462530000028],
              [-171.212762280999982, -43.814915529999951],
              [-171.211269280999915, -43.83037253],
              [-171.209874280999941, -43.845835529999988],
              [-171.208578280999916, -43.861302529999989],
              [-171.207381281000011, -43.876774529999977],
              [-171.206282280999915, -43.892249530000015],
              [-171.205282280999967, -43.907727529999988],
              [-171.204381280999854, -43.923209529999951],
              [-171.203579280999918, -43.938694530000021],
              [-171.202877280999928, -43.954181530000028],
              [-171.202273280999947, -43.96967053000003],
              [-171.201769280999912, -43.985161530000028],
              [-171.201364280999968, -44.000653530000022],
              [-171.201058280999888, -44.016147530000012],
              [-171.200852280999896, -44.031641531],
              [-171.200746280999908, -44.047136530999978],
              [-171.200739280999954, -44.062631530999965],
              [-171.200833280999916, -44.078126531],
              [-171.201026281, -44.093620531],
              [-171.201319280999968, -44.109114530999989],
              [-171.201712280999914, -44.124606530999984],
              [-171.20220528099992, -44.140097530999981],
              [-171.202798280999957, -44.155586530999983],
              [-171.20349228099991, -44.171072530999936],
              [-171.204285280999983, -44.186556530999951],
              [-171.205180280999883, -44.202038531000028],
              [-171.20617428099996, -44.217516530999944],
              [-171.207269280999981, -44.232990531000041],
              [-171.208465280999974, -44.248461530999975],
              [-171.209761280999913, -44.263927531000036],
              [-171.211158280999967, -44.279389530999936],
              [-171.212655280999911, -44.294846530999962],
              [-171.214253280999969, -44.310298530999944],
              [-171.215952280999943, -44.325744530999941],
              [-171.217751280999948, -44.341185531],
              [-171.217219280999984, -44.356315530999893],
              [-171.216782280999979, -44.371447530999944],
              [-171.216441280999902, -44.38658153099999],
              [-171.216196280999981, -44.401715531000036],
              [-171.216046280999905, -44.416849530999968],
              [-171.215992280999984, -44.431984531000012],
              [-171.216034280999821, -44.447119530999942],
              [-171.216171280999959, -44.462254530999985],
              [-171.216405280999965, -44.477388531000031],
              [-171.216734280999987, -44.492521530999966],
              [-171.217160280999821, -44.507653531000017],
              [-171.217682280999924, -44.522783531000016],
              [-171.218300280999983, -44.537912530999961],
              [-171.219014280999914, -44.55303853099997],
              [-171.219824280999973, -44.568161530999987],
              [-171.220731280999956, -44.583282530999952],
              [-171.22173528099998, -44.598400530999982],
              [-171.222835280999959, -44.613514530999964],
              [-171.224031280999981, -44.628625530999955],
              [-171.225324280999928, -44.643731531000014],
              [-171.226714280999914, -44.658833530999971],
              [-171.228200280999943, -44.673930530999939],
              [-171.22978328099984, -44.689022530999978],
              [-171.231463280999918, -44.704109530999972],
              [-171.233240280999951, -44.719190530999981],
              [-171.235148280999908, -44.734607530999881],
              [-171.237157280999952, -44.750016531000028],
              [-171.239268280999909, -44.76541953100002],
              [-171.24148028099998, -44.780815530999973],
              [-171.243793280999938, -44.796203531],
              [-171.246208280999923, -44.81158353099994],
              [-171.248725280999906, -44.826955530999953],
              [-171.251343280999976, -44.84231953099993],
              [-171.254062280999904, -44.857673530999989],
              [-171.256883280999972, -44.873018530999957],
              [-171.259806280999982, -44.888354530999948],
              [-171.262830280999907, -44.903680530999964],
              [-171.265956280999916, -44.91899553099995],
              [-171.269184280999838, -44.934300530999963],
              [-171.272513280999931, -44.949594531],
              [-171.275943280999883, -44.964877531000013],
              [-171.279476280999972, -44.980148530999941],
              [-171.283110280999921, -44.995407531000012],
              [-171.286846280999953, -45.010654531],
              [-171.290683280999986, -45.025888530999964],
              [-171.29462228099996, -45.041109530999904],
              [-171.298663280999904, -45.056317530999934],
              [-171.302805280999877, -45.071511530999942],
              [-171.307049280999905, -45.086691530999985],
              [-171.31139528099996, -45.101857531],
              [-171.315842280999959, -45.11700953199994],
              [-171.320390280999931, -45.132145531999981],
              [-171.325041280999983, -45.147266532],
              [-171.329793280999979, -45.162372531999949],
              [-171.334646280999976, -45.177461532],
              [-171.339601280999972, -45.192534531999968],
              [-171.344657280999968, -45.20759053200004],
              [-171.349815281, -45.222630531999982],
              [-171.355074280999986, -45.237652532000027],
              [-171.360434280999925, -45.252656531999946],
              [-171.365896280999948, -45.267642532000025],
              [-171.371459280999971, -45.282610531999978],
              [-171.377123280999939, -45.297560531999977],
              [-171.382889280999962, -45.312490532000027],
              [-171.388755280999931, -45.32740153200001],
              [-171.394723280999983, -45.342292531999931],
              [-171.400791280999982, -45.357163531999959],
              [-171.406961280999951, -45.372014532000037],
              [-171.413231280999952, -45.386844531999941],
              [-171.419603280999951, -45.401653532000012],
              [-171.426075280999953, -45.416440532000024],
              [-171.432648280999985, -45.431206531999976],
              [-171.439321280999849, -45.445950531999927],
              [-171.44609528099997, -45.460671532],
              [-171.452970280999921, -45.475370532],
              [-171.459945280999904, -45.490046531999894],
              [-171.467020280999975, -45.50469853200002],
              [-171.474196280999905, -45.519327532000034],
              [-171.481471280999926, -45.533931532],
              [-171.488847280999863, -45.54851153200002],
              [-171.496323280999974, -45.563067531999934],
              [-171.503899280999946, -45.577597532000027],
              [-171.511574280999923, -45.592102531999956],
              [-171.519349280999933, -45.606581531999893],
              [-171.527224280999945, -45.621034531999896],
              [-171.535199280999962, -45.635461531999965],
              [-171.543272280999929, -45.649861531999932],
              [-171.551446280999983, -45.664234531999966],
              [-171.559718280999903, -45.678580532000012],
              [-171.568089280999914, -45.692898531999958],
              [-171.576559280999959, -45.707188531999975],
              [-171.585129280999922, -45.721450531999949],
              [-171.59379628099984, -45.735683532],
              [-171.602563280999931, -45.749887532],
              [-171.611428280999974, -45.764061531999971],
              [-171.620391280999939, -45.778206532000013],
              [-171.629452280999857, -45.792321532000017],
              [-171.638612280999951, -45.80640653200004],
              [-171.647869280999913, -45.82046053199997],
              [-171.65722528099991, -45.834483532000036],
              [-171.666678280999832, -45.848475532],
              [-171.676228280999879, -45.862435531999949],
              [-171.685876280999935, -45.876363531999971],
              [-171.695621280999973, -45.890259531999959],
              [-171.705463280999908, -45.904122532000031],
              [-171.715402280999911, -45.917952531999958],
              [-171.725438280999924, -45.931749531999969],
              [-171.735570280999923, -45.945513532],
              [-171.745799280999904, -45.959243531999959],
              [-171.756124280999956, -45.972938531999944],
              [-171.766545280999964, -45.986599531999957],
              [-171.777062280999985, -46.000225531999945],
              [-171.787675280999963, -46.013816532000021],
              [-171.798383280999985, -46.027372531999958],
              [-171.809187280999964, -46.04089253199993],
              [-171.820086280999902, -46.054375532],
              [-171.831080280999885, -46.06782253199998],
              [-171.842168280999914, -46.081233532],
              [-171.853352280999957, -46.094606532],
              [-171.864630280999904, -46.107942532000038],
              [-171.876002280999984, -46.121241531999942],
              [-171.88746828099994, -46.134501531999945],
              [-171.899028280999914, -46.147723531999929],
              [-171.910682280999964, -46.160906531999956],
              [-171.922429281, -46.174051531999964],
              [-171.934269280999985, -46.187156533],
              [-171.946202280999927, -46.200222532999931],
              [-171.958229280999859, -46.213247532999901],
              [-171.970347280999931, -46.226233533000027],
              [-171.982559280999965, -46.239178532999972],
              [-171.994862280999968, -46.252082532999964],
              [-172.007257280999966, -46.264946532999943],
              [-172.019744280999902, -46.277767532999917],
              [-172.032322280999864, -46.290548532999935],
              [-172.044992280999935, -46.303286533],
              [-172.057752280999949, -46.31598253300001],
              [-172.070604280999902, -46.328635532999954],
              [-172.08354628099994, -46.341245532999949],
              [-172.09657828099995, -46.353812532999939],
              [-172.109700280999959, -46.366336532999981],
              [-172.122912280999969, -46.378815532999965],
              [-172.136213280999954, -46.391251532999888],
              [-172.149604280999853, -46.403642532999982],
              [-172.163083280999984, -46.415989533000015],
              [-172.176652280999946, -46.428290532999938],
              [-172.190309280999912, -46.440546532999974],
              [-172.204054280999941, -46.45275753300001],
              [-172.217887280999975, -46.464922533],
              [-172.231807280999931, -46.477040533000036],
              [-172.24581528199991, -46.489112532999968],
              [-172.259910281999936, -46.501137532999962],
              [-172.274092281999827, -46.513116533000016],
              [-172.288361281999954, -46.525046532999966],
              [-172.302716281999864, -46.536930533000032],
              [-172.317156281999928, -46.54876553299988],
              [-172.331683281999943, -46.560552533000021],
              [-172.346295281999943, -46.572291533],
              [-172.360992281999899, -46.583981532999985],
              [-172.375773281999926, -46.595621533000042],
              [-172.390640281999907, -46.607213532999936],
              [-172.405590281999906, -46.618755532999955],
              [-172.420624281999864, -46.630247532999931],
              [-172.435742281999978, -46.641688533000035],
              [-172.450943281999969, -46.653080532999979],
              [-172.466227281999977, -46.664420533],
              [-172.481594281999946, -46.675710533000029],
              [-172.497043281999936, -46.68694853300002],
              [-172.512574281999946, -46.69813553299997],
              [-172.528186281999922, -46.709270533],
              [-172.543880281999947, -46.720353533000036],
              [-172.559655281999909, -46.731383532999985],
              [-172.575511281999923, -46.742361532999951],
              [-172.591446281999964, -46.753286533],
              [-172.607462281999972, -46.764157532999945],
              [-172.623558281999948, -46.774976532999972],
              [-172.639733281999952, -46.785740533000023],
              [-172.655986281999958, -46.796451533000038],
              [-172.672319281999904, -46.807107533000021],
              [-172.688729281999912, -46.81770953299997],
              [-172.70521828199989, -46.828256533],
              [-172.72178428199993, -46.838748532999944],
              [-172.738427281999947, -46.849185533000025],
              [-172.755147281999939, -46.859566532999963],
              [-172.77194328199991, -46.869892532999927],
              [-172.788816281999942, -46.880161533000035],
              [-172.805764281999956, -46.890374532999942],
              [-172.822788281999976, -46.900531532999878],
              [-172.839886281999981, -46.910631532999957],
              [-172.857059281999966, -46.920673532999956],
              [-172.874307281999876, -46.930659532999982],
              [-172.891628281999857, -46.940587532999984],
              [-172.90902228199991, -46.950457532999962],
              [-172.926490281999975, -46.960269532999973],
              [-172.944030281999886, -46.97002353299996],
              [-172.961643281999869, -46.979718532999925],
              [-172.979327281999957, -46.98935553299998],
              [-172.997083281999949, -46.998932532999959],
              [-173.01491028199996, -47.008451533000027],
              [-173.032807281999908, -47.017910532999963],
              [-173.050775281999876, -47.027309533],
              [-173.068813281999923, -47.036648532999948],
              [-173.086920281999852, -47.045927533],
              [-173.105096281999948, -47.05514653299997],
              [-173.123341281999927, -47.06430453300004],
              [-173.141654281999905, -47.073401532999981],
              [-173.160035281999939, -47.082437532999961],
              [-173.178483282, -47.091412533000039],
              [-173.19699828200001, -47.100325532999932],
              [-173.215579281999879, -47.109176532999982],
              [-173.234227281999949, -47.117966532999958],
              [-173.252940281999912, -47.126693533000036],
              [-173.271718281999938, -47.135358532999931],
              [-173.29056128199997, -47.143960532999984],
              [-173.309469282999913, -47.152500533000023],
              [-173.328440282999821, -47.160976532999939],
              [-173.347475282999937, -47.169389532999958],
              [-173.366573282999951, -47.177739533000022],
              [-173.385733282999951, -47.186024532999966],
              [-173.404956282999848, -47.194246532999898],
              [-173.424240282999961, -47.202404532999878],
              [-173.443585282999919, -47.210498532999964],
              [-173.462991282999923, -47.218527533000042],
              [-173.482458282999914, -47.226491532999944],
              [-173.501984282999985, -47.23439053300001],
              [-173.521569282999906, -47.242225532999953],
              [-173.54121428299996, -47.249993532999952],
              [-173.560917282999952, -47.257697533999931],
              [-173.580677282999858, -47.265334533999976],
              [-173.600496282999842, -47.272906533999958],
              [-173.620371282999912, -47.280412534],
              [-173.640303282999923, -47.287851533999969],
              [-173.660291282999964, -47.295224533999942],
              [-173.680334282999922, -47.302530534000027],
              [-173.700433283, -47.309770534],
              [-173.720586282999903, -47.31694253400002],
              [-173.740793282999931, -47.324047533999931],
              [-173.761054282999964, -47.331085533999953],
              [-173.781368282999978, -47.338055534000034],
              [-173.801735282999914, -47.344958533999943],
              [-173.822154283, -47.351792533999969],
              [-173.842624282999935, -47.358559533999937],
              [-173.863146282999907, -47.365257534000023],
              [-173.883718282999951, -47.371887533999939],
              [-173.904341282999923, -47.378448533999972],
              [-173.92501328299997, -47.384940533999952],
              [-173.945734282999979, -47.39136453399999],
              [-173.966504282999949, -47.397718533999978],
              [-173.987322282999912, -47.404003533999969],
              [-174.008188282999924, -47.410219533999964],
              [-174.029101282999932, -47.416365534000022],
              [-174.050060282999937, -47.422441534000029],
              [-174.071066283, -47.428447534000043],
              [-174.092117282999936, -47.434384534],
              [-174.113213282999908, -47.440250533999972],
              [-174.134354282999851, -47.446046534],
              [-174.155539282999968, -47.451771533999931],
              [-174.176767282999919, -47.457426534000035],
              [-174.19803828299996, -47.463010534000034],
              [-174.219352282999921, -47.468523533999985],
              [-174.240707282999978, -47.473965533999888],
              [-174.2621042829999, -47.479335534000029],
              [-174.283542282999974, -47.484635533999949],
              [-174.305019282999922, -47.489863534],
              [-174.326537282999908, -47.495019534],
              [-174.348094283, -47.500103534],
              [-174.369690282999926, -47.505116534000024],
              [-174.391324283999978, -47.510057534],
              [-174.412995283999976, -47.514925533999985],
              [-174.434042283999986, -47.520971534000012],
              [-174.455134283999939, -47.526946533999933],
              [-174.476272283999918, -47.532851534000031],
              [-174.497455283999955, -47.538686534000021],
              [-174.518683283999906, -47.544449533999966],
              [-174.539954283999947, -47.550143534000028],
              [-174.561269283999906, -47.555765533999931],
              [-174.582626283999957, -47.561316533999957],
              [-174.604026283999929, -47.566796534],
              [-174.625468283999936, -47.572205534000034],
              [-174.646951283999925, -47.577542534000031],
              [-174.668475283999953, -47.58280853399998],
              [-174.690038283999911, -47.588002533999941],
              [-174.711642283999964, -47.593124534000026],
              [-174.733285283999891, -47.598174533999952],
              [-174.75496628399992, -47.603152534],
              [-174.77668528399991, -47.608058534],
              [-174.798442283999918, -47.612891533999971],
              [-174.820235283999921, -47.617652533999888],
              [-174.842065283999943, -47.622340533999989],
              [-174.863931283999904, -47.62695653399993],
              [-174.885832283999946, -47.631498533999945],
              [-174.907768283999957, -47.635968534000028],
              [-174.92973828399991, -47.640364533999957],
              [-174.951741283999951, -47.644688533999954],
              [-174.973778283999962, -47.648937533999913],
              [-174.99584728399995, -47.65311453399994],
              [-175.017948283999942, -47.65721753400004],
              [-175.04008028399997, -47.661246533999986],
              [-175.062244283999917, -47.665202534],
              [-175.084437283999961, -47.669084534000035],
              [-175.106660283999986, -47.672891533999973],
              [-175.128913283999935, -47.676625534000038],
              [-175.151194283999985, -47.680285534],
              [-175.173502283999852, -47.683870534],
              [-175.195839283999902, -47.68738153399994],
              [-175.218202283999887, -47.690817534000018],
              [-175.240591283999976, -47.694179533999943],
              [-175.263006283999971, -47.697467533999941],
              [-175.285447283999929, -47.700680533999957],
              [-175.307912283999912, -47.703818534],
              [-175.33040028399995, -47.706881533999933],
              [-175.352913283999982, -47.709869533999949],
              [-175.375448283999987, -47.712782534000041],
              [-175.398005283999936, -47.715620533999925],
              [-175.420584283999943, -47.718383534],
              [-175.443184283999983, -47.721071534000032],
              [-175.465805284999902, -47.723683534000031],
              [-175.488446284999981, -47.726220534],
              [-175.511106284999926, -47.728682534000029],
              [-175.533785284999936, -47.73106853400003],
              [-175.55648228499993, -47.733379534],
              [-175.579196284999909, -47.735613533999924],
              [-175.601928284999957, -47.737773534000041],
              [-175.624676284999907, -47.739856533999955],
              [-175.647440284999874, -47.741864533999944],
              [-175.670219284999973, -47.743796534000012],
              [-175.693013284999921, -47.74565253399993],
              [-175.715821284999947, -47.747432534000041],
              [-175.738643284999966, -47.749136534],
              [-175.761477284999927, -47.750764534000041],
              [-175.784324284999911, -47.752316533999931],
              [-175.807182284999982, -47.753792534000013],
              [-175.830052284999908, -47.755192533999946],
              [-175.852932284999866, -47.756515533999902],
              [-175.87582328499991, -47.757762533999937],
              [-175.898722284999934, -47.758934533999934],
              [-175.921631284999961, -47.760028533999957],
              [-175.944548284999939, -47.761047534],
              [-175.967472284999985, -47.761989533999952],
              [-175.990403284999985, -47.762854533999985],
              [-175.989742284999835, -47.77792653399996],
              [-175.989182284999941, -47.79300053399993],
              [-175.988722285, -47.808075533999897],
              [-175.988364284999932, -47.82315253400003],
              [-175.988107284999984, -47.838229533999936],
              [-175.987950284999954, -47.853307533999953],
              [-175.987895284999951, -47.868385534000026],
              [-175.98794128499992, -47.883463534000043],
              [-175.988089284999916, -47.898541533999946],
              [-175.988338284999912, -47.913619533999963],
              [-175.988689284999907, -47.928695534000042],
              [-175.989141284999931, -47.943770533999952],
              [-175.989695284999925, -47.958843534000039],
              [-175.990351284999946, -47.973915534000014],
              [-175.991109284999965, -47.988984534],
              [-175.991969284999954, -48.004051533999984],
              [-175.992930285, -48.019115533999923],
              [-175.993994284999928, -48.034176534000039],
              [-175.995160284999855, -48.049234533999936],
              [-175.996428284999951, -48.064288534000013],
              [-175.997799284999985, -48.079337533999933],
              [-175.999272284999904, -48.094383533999974],
              [-176.000847284999935, -48.109423533999973],
              [-176.002525284999933, -48.124459533999982],
              [-176.004305284999958, -48.139490533999947],
              [-176.006188284999979, -48.154514534000043],
              [-176.008173285, -48.169533533999982],
              [-176.010261284999984, -48.184546533999935],
              [-176.01245228499991, -48.199552533999963],
              [-176.014746284999916, -48.214551534],
              [-176.01714228499992, -48.229543533999959],
              [-176.019642284999918, -48.244528533999983],
              [-176.022244284999914, -48.259505534000027],
              [-176.024949284999906, -48.274473533999981],
              [-176.027757284999979, -48.289434534],
              [-176.030668284999962, -48.304385534],
              [-176.033682284999941, -48.319327533999967],
              [-176.036871284999933, -48.334608535000029],
              [-176.040168284999964, -48.349878535000016],
              [-176.043573284999951, -48.365137535000031],
              [-176.047086284999921, -48.380386534999957],
              [-176.050706284999961, -48.395624535000024],
              [-176.054435284999926, -48.410850535],
              [-176.058272284999902, -48.426065535000021],
              [-176.062217284999974, -48.441267535],
              [-176.066269284999947, -48.45645753499997],
              [-176.070430284999986, -48.471633535000024],
              [-176.07469928499998, -48.486797534999937],
              [-176.079076284999928, -48.501947535],
              [-176.083561284999945, -48.517084534999981],
              [-176.088154284999916, -48.532206534999943],
              [-176.092855284999956, -48.547314534999941],
              [-176.09766528499992, -48.562407534999977],
              [-176.102582284999983, -48.577485535],
              [-176.10760728499983, -48.592547534999937],
              [-176.112740284999973, -48.607593534999978],
              [-176.117982284999925, -48.622623534999946],
              [-176.123331284999921, -48.63763753500001],
              [-176.128788284999956, -48.652634535],
              [-176.134353284999975, -48.667613534999987],
              [-176.140026284999891, -48.682575535000012],
              [-176.145807284999904, -48.697520534999967],
              [-176.151696284999929, -48.712446535000026],
              [-176.157692285, -48.72735353500002],
              [-176.163796284999847, -48.742242534999946],
              [-176.170008284999909, -48.757111534999979],
              [-176.176328284999954, -48.771961534999889],
              [-176.182755284999985, -48.78679153500002],
              [-176.18929028499997, -48.801601534999975],
              [-176.195932284999941, -48.816390534999982],
              [-176.202682284999952, -48.831158534999986],
              [-176.20953928499992, -48.845905534999986],
              [-176.216503284999959, -48.860631535000039],
              [-176.223575284999896, -48.875334535000036],
              [-176.217989284999987, -48.889038534999941],
              [-176.212489284999947, -48.902757534999978],
              [-176.207075284999974, -48.916491534999921],
              [-176.201746284999956, -48.930240534999939],
              [-176.196504284999918, -48.944004535000033],
              [-176.191347285, -48.957782534999978],
              [-176.186277284999818, -48.971573535],
              [-176.181293284999981, -48.985379534999936],
              [-176.17639628500001, -48.999198534999948],
              [-176.171586284999933, -49.013031535000039],
              [-176.166862284999951, -49.026877534999869],
              [-176.161798284999975, -49.041979534999939],
              [-176.156839284999847, -49.05709553500003],
              [-176.151982284999946, -49.072227534999968],
              [-176.147230284999893, -49.08737353500004],
              [-176.142582284999975, -49.102532535000023],
              [-176.138039284999962, -49.117706534999968],
              [-176.133599284999946, -49.132893534999937],
              [-176.129265284999832, -49.148094535000041],
              [-176.125035284999939, -49.163307534999944],
              [-176.120911284999977, -49.178532534999874],
              [-176.116892284999977, -49.193770535],
              [-176.11297828499994, -49.209020535000036],
              [-176.109169284999922, -49.224281534999932],
              [-176.105467284999946, -49.239554535000025],
              [-176.101871284999874, -49.254837535000036],
              [-176.098380284999934, -49.270131534999962],
              [-176.094996284999979, -49.285436534999974],
              [-176.091719284999925, -49.300750534999963],
              [-176.088548284999916, -49.316074534999927],
              [-176.085484284999978, -49.331407534999983],
              [-176.082527284999912, -49.346750535000012],
              [-176.079677284999917, -49.362101534999965],
              [-176.076934285, -49.377460534999955],
              [-176.07429928499991, -49.392827535000038],
              [-176.071771284999926, -49.408202536000033],
              [-176.069351284999982, -49.423585535999848],
              [-176.067039284999822, -49.438974535999876],
              [-176.064835284999958, -49.454371536],
              [-176.062740284999904, -49.469773535999934],
              [-176.060752284999836, -49.485182535999968],
              [-176.058874284999973, -49.500597535999987],
              [-176.057103284999897, -49.516017536],
              [-176.055442284999941, -49.531442536000043],
              [-176.053899284999915, -49.546592535999878],
              [-176.052461284999936, -49.561747535999984],
              [-176.051129284999945, -49.576905536000027],
              [-176.049902284999945, -49.592068536],
              [-176.048780284999935, -49.607233536000024],
              [-176.047764284999971, -49.622402536000038],
              [-176.04685428499991, -49.637574535999931],
              [-176.046050284999836, -49.65274853599999],
              [-176.045351284999981, -49.66792453599993],
              [-176.044759284999941, -49.683102535999978],
              [-176.044273284999889, -49.698282536000022],
              [-176.043893284999967, -49.713463535999949],
              [-176.043620284999946, -49.728645535999931],
              [-176.04345328499997, -49.743827535999969],
              [-176.043393284999979, -49.759010535999948],
              [-176.043439284999977, -49.774193535999984],
              [-176.043593284999929, -49.789375535999909],
              [-176.043853284999869, -49.804557536],
              [-176.044220284999938, -49.819738535999932],
              [-176.044695284999904, -49.834918535999975],
              [-176.045276284999915, -49.850096536000024],
              [-176.045965284999824, -49.86527253599985],
              [-176.046761284999945, -49.880445536000025],
              [-176.047665284999965, -49.895617536000032],
              [-176.048676284999942, -49.910785535999935],
              [-176.049795284999817, -49.925950535999959],
              [-176.051022284999902, -49.941112535999935],
              [-176.052356284999973, -49.956270535999977],
              [-176.053798284999914, -49.971423535999918],
              [-176.05534828499998, -49.986573535999923],
              [-176.057006284999943, -50.001717536],
              [-176.058773284999944, -50.016857536000032],
              [-176.060647284999931, -50.031991535999964],
              [-176.062629284999957, -50.047119535999968],
              [-176.064720284999879, -50.062241535999988],
              [-176.066919284999841, -50.077357536000022],
              [-176.069227284999926, -50.092466535999847],
              [-176.071643284999908, -50.107569536000028],
              [-176.074167284999845, -50.122663535999948],
              [-176.076799284999908, -50.137750536],
              [-176.079288284999961, -50.153080536],
              [-176.081889284999988, -50.168402535999974],
              [-176.084602284999931, -50.183716535999963],
              [-176.087426284999907, -50.19902253600003],
              [-176.090363284999825, -50.214319535999948],
              [-176.093412284999857, -50.229607535999946],
              [-176.09657328499992, -50.244886536000024],
              [-176.099846285000012, -50.260155536000013],
              [-176.103232284999933, -50.275414536000028],
              [-176.106729284999915, -50.290662535999957],
              [-176.110339284999924, -50.305900536000024],
              [-176.114061284999934, -50.321127536],
              [-176.117896284999915, -50.336343536000015],
              [-176.121843284999983, -50.351547535999941],
              [-176.12590228499991, -50.366739536000011],
              [-176.130074284999978, -50.381918535999944],
              [-176.134358284999877, -50.397085535999963],
              [-176.138754284999976, -50.412239535999959],
              [-176.143263284999847, -50.427380535999987],
              [-176.147885285, -50.442506535999939],
              [-176.152619284999986, -50.457619536000038],
              [-176.157465284999944, -50.472717535999948],
              [-176.162423284999932, -50.487801536999939],
              [-176.167495284999916, -50.502870537000035],
              [-176.172678284999932, -50.517923537],
              [-176.177913284999846, -50.532813537000038],
              [-176.183258284999937, -50.547688536999942],
              [-176.18871328499992, -50.562546536999946],
              [-176.194278284999939, -50.577388536999933],
              [-176.199953284999907, -50.592213536999964],
              [-176.205739284999936, -50.607022537000034],
              [-176.211634284999974, -50.621813537000037],
              [-176.217640284999987, -50.636586537000028],
              [-176.223756284999951, -50.651342536999948],
              [-176.229982284999949, -50.66607953700003],
              [-176.236318284999896, -50.680797536999933],
              [-176.242764284999964, -50.69549653699994],
              [-176.249319284999928, -50.710176537],
              [-176.255985284999923, -50.724837536999985],
              [-176.262760284999871, -50.739477536999914],
              [-176.269646284999936, -50.754098536999948],
              [-176.276641284999954, -50.76869753699998],
              [-176.28374628499995, -50.783276537],
              [-176.290960284999983, -50.797833536999974],
              [-176.298284284999966, -50.812369536999938],
              [-176.305717285000014, -50.826883537000015],
              [-176.313260284999984, -50.841375537000033],
              [-176.320913284999932, -50.855844536999939],
              [-176.32867428499992, -50.870291537000014],
              [-176.336545284999971, -50.884714536999979],
              [-176.344525284999946, -50.899113536999948],
              [-176.352615284999985, -50.913489536999975],
              [-176.360813284999921, -50.927841537],
              [-176.369120284999951, -50.942168537000043],
              [-176.377536284999962, -50.956470536999973],
              [-176.386061284999954, -50.970747537000022],
              [-176.394694284999957, -50.984999536999965],
              [-176.403436284999941, -50.999225537000029],
              [-176.412287284999906, -51.013425536999989],
              [-176.421246285, -51.027598537000017],
              [-176.430313284999869, -51.041744537],
              [-176.439488284999953, -51.055864537],
              [-176.448772284999933, -51.069956536999939],
              [-176.458163284999983, -51.084021537000012],
              [-176.467662284999989, -51.098057536999988],
              [-176.477269284999949, -51.112065537000035],
              [-176.486984284999977, -51.126044536999984],
              [-176.496806284999963, -51.139995537],
              [-176.506735284999934, -51.153916537000043],
              [-176.516771284999948, -51.167807536999987],
              [-176.526915284999944, -51.181669536999948],
              [-176.537166285999973, -51.195500536999987],
              [-176.547523285999915, -51.209301536999988],
              [-176.557987285999928, -51.223071536999953],
              [-176.568558285999984, -51.236809536999942],
              [-176.579133285999916, -51.250672536999957],
              [-176.589816285999916, -51.264503536999939],
              [-176.600607285999985, -51.278302536999945],
              [-176.611506285999923, -51.292068537000034],
              [-176.622513285999986, -51.305802537000034],
              [-176.63362728599995, -51.319502537],
              [-176.644848285999956, -51.333170537],
              [-176.656177285999945, -51.346803536999971],
              [-176.667612285999979, -51.360403537000025],
              [-176.679155285999911, -51.373968536999939],
              [-176.690804285999917, -51.38749953699994],
              [-176.702559285999968, -51.400994536999974],
              [-176.714421285999919, -51.414455536999981],
              [-176.726389285999915, -51.427879537000024],
              [-176.738463285999984, -51.441268536999985],
              [-176.750643285999871, -51.454621537000037],
              [-176.762928285999976, -51.467937537000012],
              [-176.775319285999984, -51.481216536999966],
              [-176.787814285999985, -51.494458536999957],
              [-176.800415285999975, -51.507663536999985],
              [-176.813120285999929, -51.520829537],
              [-176.825930285999817, -51.53395853699999],
              [-176.838845285999838, -51.547048536999966],
              [-176.85186328599994, -51.560100537999972],
              [-176.86498528599995, -51.573112538000032],
              [-176.878211285999868, -51.586085537999963],
              [-176.891540285999866, -51.599018538],
              [-176.904973285999915, -51.611912537999956],
              [-176.918509285999903, -51.62476553800002],
              [-176.932147285999974, -51.63757753799996],
              [-176.945887285999959, -51.650349537999944],
              [-176.959730285999939, -51.663079538000034],
              [-176.973676285999915, -51.675768538],
              [-176.987722285999922, -51.688415537999958],
              [-177.001871285999982, -51.701020538000023],
              [-177.016120285999904, -51.713582537999969],
              [-177.030471285999909, -51.726102538000021],
              [-177.044922285999832, -51.73857953800001],
              [-177.059474285999926, -51.751012537999941],
              [-177.074126285999967, -51.76340253799998],
              [-177.088878285999925, -51.775748537999959],
              [-177.103729285999918, -51.788049537999939],
              [-177.118680285999915, -51.800306538000029],
              [-177.133731285999914, -51.812519538],
              [-177.148880285999951, -51.824686538000037],
              [-177.164127285999854, -51.836808537999957],
              [-177.179473285999933, -51.848884537999936],
              [-177.194917285999935, -51.860914538000031],
              [-177.21045828599992, -51.872897538000018],
              [-177.226097285999913, -51.88483453799995],
              [-177.241833285999917, -51.896725538],
              [-177.257665285999906, -51.90856853799994],
              [-177.273594285999906, -51.920363537999947],
              [-177.289620285999945, -51.932111537999958],
              [-177.305741285999915, -51.943811537999977],
              [-177.321957285999957, -51.955462537999949],
              [-177.338269285999957, -51.967065537999986],
              [-177.354676285999972, -51.978619538000032],
              [-177.371177285999977, -51.990124537999975],
              [-177.387772285999915, -52.00157953799993],
              [-177.404461285999929, -52.012985537999953],
              [-177.421244285999961, -52.02434053799999],
              [-177.438120285999986, -52.03564653799998],
              [-177.455089285999975, -52.046900537999989],
              [-177.472150285999959, -52.058104537999952],
              [-177.489304285999964, -52.069257537999931],
              [-177.506549285999967, -52.080358538000041],
              [-177.523886285999936, -52.091408537999939],
              [-177.541314285999903, -52.102406537999968],
              [-177.558832285999841, -52.113351538000018],
              [-177.57644128599992, -52.124244537999971],
              [-177.594140285999913, -52.135084538],
              [-177.611929286999981, -52.145871538],
              [-177.629807286999949, -52.156605537999951],
              [-177.647774286999919, -52.167285537999931],
              [-177.66582928699998, -52.177912538000044],
              [-177.683972286999932, -52.188484538000012],
              [-177.702203286999975, -52.199002537999945],
              [-177.720522286999852, -52.209465538000018],
              [-177.73892728699991, -52.219874538],
              [-177.757419286999948, -52.230227537999951],
              [-177.775997286999939, -52.240525537999986],
              [-177.794660286999857, -52.250767537999934],
              [-177.813410286999925, -52.260953538000024],
              [-177.832244286999952, -52.271083537999971],
              [-177.851162286999909, -52.281157537999945],
              [-177.870165286999963, -52.291174538],
              [-177.889251286999979, -52.301134537999985],
              [-177.908420286999927, -52.311037538],
              [-177.927673286999976, -52.320882538000035],
              [-177.94700828699996, -52.330670538],
              [-177.96642428699991, -52.340400537999983],
              [-177.985923286999906, -52.350071538],
              [-178.005502286999928, -52.359685537999951],
              [-178.025162287, -52.369239537999931],
              [-178.044903286999926, -52.378735538],
              [-178.064723286999907, -52.388171538],
              [-178.084622286999945, -52.397548537999967],
              [-178.104601286999923, -52.406866537999974],
              [-178.124657286999906, -52.416123537999965],
              [-178.144792286999916, -52.425321538],
              [-178.165005286999985, -52.434458538],
              [-178.185294286999891, -52.443535538],
              [-178.205660286999915, -52.452551537999966],
              [-178.226102286999861, -52.461506537999981],
              [-178.246620286999928, -52.470399538000038],
              [-178.267213286999919, -52.479231538000022],
              [-178.287880286999922, -52.488002537999989],
              [-178.308622287, -52.496710538],
              [-178.329438286999959, -52.505357537999942],
              [-178.350326286999916, -52.513941537999983],
              [-178.37128828699997, -52.522462538000013],
              [-178.392322286999956, -52.53092153800003],
              [-178.413428286999931, -52.53931653799998],
              [-178.434604286999928, -52.547649537999973],
              [-178.455852286999942, -52.555918537999958],
              [-178.477170286999893, -52.564123537999933],
              [-178.498558286999923, -52.572264538000013],
              [-178.520078286999961, -52.580405538000036],
              [-178.541668286999936, -52.58848153799994],
              [-178.563327286999936, -52.59649253799995],
              [-178.58505528699996, -52.604438537999954],
              [-178.606851286999841, -52.612318537999954],
              [-178.628714286999838, -52.620133538],
              [-178.650644286999949, -52.627882539000041],
              [-178.672641286999948, -52.63556553899997],
              [-178.694704287999969, -52.643182538999952],
              [-178.716832287999949, -52.650733538999987],
              [-178.739024287999911, -52.658217538999963],
              [-178.761282287999904, -52.665634538999939],
              [-178.783603287999966, -52.672984539000026],
              [-178.805987287999983, -52.680267539],
              [-178.828433287999928, -52.687483539000027],
              [-178.850942288, -52.694631538999943],
              [-178.873513287999941, -52.701712538999971],
              [-178.896144287999817, -52.708724539],
              [-178.918836287999909, -52.715669538999975],
              [-178.941588287999934, -52.722545539],
              [-178.964399287999953, -52.729353538999931],
              [-178.987269287999965, -52.736092539000026],
              [-179.010197287999887, -52.742762538999955],
              [-179.033182287999978, -52.749364539],
              [-179.056225287999922, -52.755896539],
              [-179.079324287999953, -52.762359538999988],
              [-179.102479287999842, -52.768753538999931],
              [-179.125689287999904, -52.77507653899994],
              [-179.148954287999914, -52.781330538999953],
              [-179.172274287999926, -52.787514538999972],
              [-179.195646287999836, -52.79362853899994],
              [-179.219072287999921, -52.799672538999971],
              [-179.242550287999961, -52.805645539000011],
              [-179.266080287999955, -52.811547539],
              [-179.289661287999905, -52.817379539],
              [-179.313293287999983, -52.823140538999894],
              [-179.33697428799988, -52.828830538999966],
              [-179.360705287999963, -52.834448539],
              [-179.384485287999951, -52.839995539000029],
              [-179.408313287999903, -52.845471538999959],
              [-179.432188287999935, -52.850874539000017],
              [-179.45611128799996, -52.856206539000027],
              [-179.480080287999925, -52.861467538999932],
              [-179.504094287999919, -52.866655539000021],
              [-179.528154287999968, -52.871770539000011],
              [-179.552258287999962, -52.876814539],
              [-179.57640628799993, -52.881784539000023],
              [-179.600598287999986, -52.886683538999989],
              [-179.624832287999965, -52.891508538999972],
              [-179.649108287999951, -52.896261539000022],
              [-179.673425287999947, -52.900940539000032],
              [-179.697783287999897, -52.905547539],
              [-179.722102287999888, -52.910139539],
              [-179.746461287999978, -52.914658538999959],
              [-179.770859288999958, -52.919103538999934],
              [-179.795296288999936, -52.923476539000035],
              [-179.819771288999959, -52.927775538999981],
              [-179.844283288999947, -52.932001539],
              [-179.868832288999954, -52.936153538999974],
              [-179.893417288999927, -52.940231538999967],
              [-179.918038288999952, -52.944236538999974],
              [-179.942694288999945, -52.94816753899994],
              [-179.967384288999909, -52.952023538999981],
              [-179.992107288999932, -52.95580653899998],
              [-180.0, -52.956988496],
              [-180.0, -51.639344262],
              [-180.0, -48.688524589999986],
              [-180.0, -45.737704917999963],
              [-180.0, -42.786885246],
              [-180.0, -39.836065574000031],
              [-180.0, -36.885245901999951],
              [-180.0, -33.934426229999929],
              [-180.0, -30.983606556999973],
              [-180.0, -28.032786884999894],
              [-180.0, -26.421344352],
              [-179.991259288999942, -26.41643651399994],
              [-179.976713288999974, -26.408326514],
              [-179.962127288999966, -26.400275513999929],
              [-179.94750328899994, -26.392282513999987],
              [-179.932839288999986, -26.384349513999894],
              [-179.918138288999955, -26.376476513999933],
              [-179.903398288999853, -26.368661513999939],
              [-179.888621288999957, -26.360907513999962],
              [-179.87380628899993, -26.353212513999949],
              [-179.858953288999942, -26.345577513999899],
              [-179.844064288999931, -26.338002513999925],
              [-179.829138288999957, -26.330488514000024],
              [-179.814176288999931, -26.323034514000028],
              [-179.799178288999968, -26.315641513999935],
              [-179.784145288999923, -26.308308513999975],
              [-179.769075288999943, -26.301036513999975],
              [-179.738832287999912, -26.286675514000024],
              [-179.723659287999908, -26.279587514000013],
              [-179.708451287999964, -26.27256051400002],
              [-179.693209287999963, -26.265595513999983],
              [-179.677934287999904, -26.25869151400002],
              [-179.662625287999873, -26.251849514000014],
              [-179.647178287999935, -26.244987514],
              [-179.63169728799997, -26.238189514],
              [-179.61618328799986, -26.231454514],
              [-179.600637287999916, -26.224781514000028],
              [-179.585058287999914, -26.218173513999943],
              [-179.569447287999935, -26.21162851400004],
              [-179.55380528799995, -26.205146513999978],
              [-179.538131287999931, -26.198729513999922],
              [-179.522425288, -26.192375514],
              [-179.506689287999961, -26.186085514000013],
              [-179.490923287999948, -26.179860513999927],
              [-179.475126287999927, -26.173699513999964],
              [-179.459300287999923, -26.167602514],
              [-179.443443287999941, -26.161570513999948],
              [-179.427558287999915, -26.155603514],
              [-179.411644287999934, -26.149700513999903],
              [-179.395701287999913, -26.143863514000032],
              [-179.37973028799982, -26.138090513999941],
              [-179.363731287999968, -26.132383513999969],
              [-179.347704287999932, -26.126741514],
              [-179.331651287999989, -26.121164513999872],
              [-179.315570287999918, -26.115653513999973],
              [-179.299462287999944, -26.110207514000024],
              [-179.28332828799995, -26.104827513999965],
              [-179.267168287999908, -26.099513514000023],
              [-179.250983287999958, -26.094265514000028],
              [-179.234772287999903, -26.089083513999981],
              [-179.218536287999967, -26.083967514],
              [-179.202275287999953, -26.078917513999954],
              [-179.185990287999971, -26.07393451400003],
              [-179.169681287999964, -26.069017514],
              [-179.153348287999933, -26.064166513999965],
              [-179.136992287999959, -26.05938351399999],
              [-179.12061328799993, -26.054666513999962],
              [-179.104211287999959, -26.050015514],
              [-179.087786287999876, -26.04543251399997],
              [-179.071340287999988, -26.040916513999946],
              [-179.054871287999987, -26.03646751399998],
              [-179.03838128799984, -26.032084513999905],
              [-179.021870288, -26.02777051399994],
              [-179.005339288000016, -26.023522513999978],
              [-178.988787287999969, -26.019342513999902],
              [-178.97221428799989, -26.015230513999938],
              [-178.95562228799983, -26.011185513999976],
              [-178.939011287999961, -26.007208514000013],
              [-178.922380287999971, -26.003299514],
              [-178.905730287999916, -25.999457513999971],
              [-178.889062287999963, -25.99568351399995],
              [-178.872376287999941, -25.991978514000039],
              [-178.855672287999852, -25.988340514000015],
              [-178.838951287999947, -25.984771513999931],
              [-178.822212287999974, -25.981270514000016],
              [-178.805457287999928, -25.977837513999987],
              [-178.788685287999982, -25.974472514000013],
              [-178.771897287999963, -25.971176513999978],
              [-178.75509328799987, -25.96794951399994],
              [-178.738274287999928, -25.964790513999958],
              [-178.72144028799994, -25.961699514000031],
              [-178.704590287999935, -25.958677513999987],
              [-178.687727287999905, -25.955724514],
              [-178.670849286999982, -25.952840513999945],
              [-178.653957286999969, -25.950024514],
              [-178.63705228699996, -25.947278514],
              [-178.620134286999928, -25.944600514],
              [-178.603203286999985, -25.941992513999878],
              [-178.586259286999933, -25.939452514000038],
              [-178.56930328699994, -25.936982513999965],
              [-178.552336286999861, -25.934581514],
              [-178.535357286999925, -25.932249514000034],
              [-178.518367286999961, -25.929986514],
              [-178.501366286999911, -25.92779351400003],
              [-178.484354286999917, -25.925669513999935],
              [-178.467333286999974, -25.923614514],
              [-178.450302286999914, -25.921629514000017],
              [-178.433261286999851, -25.919713514000023],
              [-178.416211286999982, -25.917867514000022],
              [-178.399152286999936, -25.916090514000018],
              [-178.38208528699991, -25.914383513999894],
              [-178.365010286999933, -25.912745514],
              [-178.347927286999948, -25.91117751400003],
              [-178.33083728699998, -25.909679513999947],
              [-178.313740286999973, -25.908250514000031],
              [-178.296636286999984, -25.906892513999935],
              [-178.279525286999984, -25.905603513999949],
              [-178.262408286999914, -25.904383514000017],
              [-178.245286286999971, -25.903234513999962],
              [-178.228158286999957, -25.90215451399996],
              [-178.211025286999984, -25.90114551399995],
              [-178.193888286999965, -25.900205514],
              [-178.176746286999958, -25.899335514000029],
              [-178.160008286999982, -25.898164513999973],
              [-178.143264286999965, -25.897060514000032],
              [-178.126515286999961, -25.896023514000035],
              [-178.109761286999912, -25.895052513999985],
              [-178.093003286999902, -25.894149513999935],
              [-178.076240286999905, -25.893312513999945],
              [-178.059474286999915, -25.892542514000013],
              [-178.042705286999961, -25.891839514000026],
              [-178.025635286999943, -25.891169514000012],
              [-178.008563286999959, -25.890568513999938],
              [-177.991489286999922, -25.890037513999971],
              [-177.974411286999953, -25.889575513999944],
              [-177.957332286999929, -25.889182514000026],
              [-177.94025128699991, -25.888859513999932],
              [-177.923169286999979, -25.888604513999951],
              [-177.906085286999911, -25.888420514000018],
              [-177.889001286999985, -25.888304514000026],
              [-177.871916287, -25.888258514000029],
              [-177.854832286999823, -25.888281513999971],
              [-177.837747286999985, -25.888374513999963],
              [-177.820664286999914, -25.888536513999952],
              [-177.803581286999986, -25.888767513999881],
              [-177.78649928699997, -25.889068513999916],
              [-177.769419286999948, -25.889438513999949],
              [-177.752341286999922, -25.889877513999977],
              [-177.735266286999945, -25.890385514],
              [-177.718193286999963, -25.89096351400002],
              [-177.701123286999888, -25.891610513999979],
              [-177.684056286999947, -25.892327513999987],
              [-177.666993286999912, -25.893113513999936],
              [-177.649933286999868, -25.893967514],
              [-177.632878286999954, -25.894892513999935],
              [-177.615828286999914, -25.895885513999872],
              [-177.598783285999957, -25.896947513999976],
              [-177.581742285999951, -25.89807951399996],
              [-177.564708286000013, -25.89928051399994],
              [-177.547679285999919, -25.900550514000031],
              [-177.530657285999922, -25.901889513999947],
              [-177.513642285999936, -25.90329751400003],
              [-177.496633285999934, -25.904774513999939],
              [-177.479631285999943, -25.906321513999956],
              [-177.46263828599993, -25.907936513999971],
              [-177.445652285999955, -25.909620513999982],
              [-177.428674285999932, -25.91137351399999],
              [-177.411705285999943, -25.913195514],
              [-177.394745285999903, -25.915086514],
              [-177.37779528599998, -25.917045513999881],
              [-177.36085428599992, -25.919074513999988],
              [-177.343922285999952, -25.921171514000036],
              [-177.327001285999955, -25.923337514000025],
              [-177.31009128599996, -25.925571514000012],
              [-177.293192285999908, -25.927874514],
              [-177.276304285999942, -25.930246514000032],
              [-177.259427285999919, -25.932686513999954],
              [-177.242563285999921, -25.935194513999988],
              [-177.225710285999952, -25.937771513999962],
              [-177.208871285999976, -25.940417513999932],
              [-177.192044285999913, -25.943131513999958],
              [-177.175230285999959, -25.94591351400004],
              [-177.15843128599991, -25.94876351399995],
              [-177.141644285999973, -25.951681513999972],
              [-177.124873285999939, -25.954667513999937],
              [-177.108115285999986, -25.957722514000011],
              [-177.091373285999879, -25.960844513999973],
              [-177.074646285999933, -25.964035513999931],
              [-177.057934285999892, -25.967293513999948],
              [-177.041239285999922, -25.970619513999964],
              [-177.024559285999942, -25.97401251399998],
              [-177.007896285999948, -25.977474514],
              [-176.991250285999882, -25.981003514],
              [-176.974622285999942, -25.984599514000024],
              [-176.958010285999904, -25.988263514000039],
              [-176.941417285999961, -25.991994513999941],
              [-176.924842285999972, -25.995793514000013],
              [-176.908285285999881, -25.999658513999975],
              [-176.891747285999941, -26.003591513999879],
              [-176.875228285999953, -26.007591514000012],
              [-176.858729285999942, -26.011658513999976],
              [-176.842249285999912, -26.015792514],
              [-176.825790285999943, -26.019993514000021],
              [-176.809351285999924, -26.024260513999934],
              [-176.792933285999965, -26.028594513999963],
              [-176.776536285999981, -26.032995513999879],
              [-176.760160285999916, -26.037462513999969],
              [-176.743806285999852, -26.041996513999891],
              [-176.727475285999986, -26.046596513999987],
              [-176.711165285999897, -26.051262513999973],
              [-176.69487828599992, -26.055994513999906],
              [-176.678615285999911, -26.060792513999957],
              [-176.662375285999929, -26.065657513999952],
              [-176.646158285999917, -26.070587513999953],
              [-176.629965285999987, -26.075583513999959],
              [-176.613797285999937, -26.080644514000028],
              [-176.597654285999823, -26.08577151399993],
              [-176.58153528599982, -26.090964514],
              [-176.565442285999922, -26.096221514000035],
              [-176.54937428599996, -26.101544514000011],
              [-176.533332285999933, -26.106933513999934],
              [-176.517317284999905, -26.112386514000036],
              [-176.501327284999917, -26.117904513999974],
              [-176.485365284999943, -26.123487513999976],
              [-176.469430284999902, -26.129135513999984],
              [-176.453523284999932, -26.134847513999944],
              [-176.437643284999979, -26.140624514000024],
              [-176.421792284999952, -26.146465513999942],
              [-176.405969284999912, -26.15237051400004],
              [-176.390175284999913, -26.158340513999974],
              [-176.374410284999954, -26.164373513999976],
              [-176.358674284999864, -26.170470513999987],
              [-176.342968284999841, -26.176632514],
              [-176.32729228499997, -26.182856514000036],
              [-176.311646284999938, -26.189145513999904],
              [-176.29603128499997, -26.19549751400001],
              [-176.280447284999923, -26.201912514000014],
              [-176.264895284999938, -26.208390513999973],
              [-176.249373284999962, -26.214931513999943],
              [-176.233884284999931, -26.221535514000038],
              [-176.218427284999905, -26.228202514000031],
              [-176.203003284999937, -26.234932513999979],
              [-176.187611284999974, -26.241724514],
              [-176.172252284999956, -26.248578513999917],
              [-176.156927284999938, -26.255495513999961],
              [-176.141636284999947, -26.262473514000021],
              [-176.126379284999956, -26.269514514000036],
              [-176.111156284999907, -26.276616513999954],
              [-176.095968284999941, -26.283780513999943],
              [-176.080814284999946, -26.291006514],
              [-176.065696284999973, -26.298293514000022],
              [-176.050614284999966, -26.305642513999942],
              [-176.035567284999956, -26.313051513999937],
              [-176.020557284999938, -26.32052151399995],
              [-176.005583284999915, -26.328052513999978],
              [-175.990646284999912, -26.335644513999966],
              [-175.975746284999985, -26.343297513999971],
              [-175.960884284999963, -26.351009514],
              [-175.946059284999905, -26.35878251400004],
              [-175.931272284999977, -26.366615513999932],
              [-175.916524284999838, -26.374508514000013],
              [-175.901814284999972, -26.382460514],
              [-175.887143284999951, -26.390472513999896],
              [-175.872511284999973, -26.398544514000037],
              [-175.857919284999923, -26.406675514000028],
              [-175.843366284999917, -26.414865513999985],
              [-175.82885428499992, -26.423113514000022],
              [-175.814382284999937, -26.431421513999965],
              [-175.799951284999963, -26.439787513999931],
              [-175.785560284999946, -26.448212513999977],
              [-175.771211284999936, -26.456695513999932],
              [-175.756903284999936, -26.465236514000026],
              [-175.742638284999941, -26.473835513999973],
              [-175.728414284999985, -26.482491513999946],
              [-175.714233284999835, -26.491206514],
              [-175.700094284999949, -26.499977513999966],
              [-175.685999284999923, -26.508806513999957],
              [-175.671947284999959, -26.517692513999975],
              [-175.657938284999915, -26.526635513999963],
              [-175.643974284999956, -26.535635513999978],
              [-175.630053284999917, -26.544691514000021],
              [-175.616177284999964, -26.553803513999981],
              [-175.602346284999925, -26.562972514000023],
              [-175.588559284999945, -26.572196513999927],
              [-175.574818284999964, -26.581477513999914],
              [-175.561123284999979, -26.590813513999933],
              [-175.547473285, -26.600204513999984],
              [-175.533870284999978, -26.609651514],
              [-175.520313284999929, -26.619153514],
              [-175.506802285, -26.628709513999922],
              [-175.493339284999905, -26.638320513999872],
              [-175.479923285000012, -26.647986514000024],
              [-175.466554284999972, -26.657706513999983],
              [-175.453234283999933, -26.667480514000033],
              [-175.439961283999963, -26.67730851399989],
              [-175.426737283999927, -26.687190513999951],
              [-175.413516283999911, -26.697156513999971],
              [-175.40034528399994, -26.707176513999968],
              [-175.38722328399993, -26.717250513999943],
              [-175.374151283999964, -26.727376513999957],
              [-175.361129283999958, -26.737556514],
              [-175.348157283999939, -26.747789513999976],
              [-175.335236283999933, -26.758074513999929],
              [-175.322366283999912, -26.768412513999976],
              [-175.309547283999933, -26.778802514],
              [-175.296780284, -26.789243513999963],
              [-175.284064283999982, -26.799737513999958],
              [-175.271400283999952, -26.810282514],
              [-175.258788283999962, -26.820879513999955],
              [-175.24622928399998, -26.831527514000015],
              [-175.23372328399995, -26.842226514],
              [-175.221269283999987, -26.852976515000023],
              [-175.208869283999917, -26.86377651500004],
              [-175.196523283999909, -26.874626514999989],
              [-175.184230283999938, -26.885527514999978],
              [-175.171991283999972, -26.896477514999958],
              [-175.159807283999953, -26.907477514999925],
              [-175.14767728399994, -26.918526515],
              [-175.135602283999958, -26.929625514999941],
              [-175.12358328399992, -26.940773514999989],
              [-175.111618283999974, -26.951969514999973],
              [-175.099710283999883, -26.963214514999947],
              [-175.087857283999966, -26.974507514999971],
              [-175.076060283999908, -26.98584851499993],
              [-175.064320283999933, -26.997237514999938],
              [-175.052636283999959, -27.008674514999939],
              [-175.041010283999924, -27.020158515],
              [-175.029408283999913, -27.031601514999977],
              [-175.017864283999984, -27.043091514999958],
              [-175.006376283999913, -27.054628514999933],
              [-174.994946283999923, -27.066211514999964],
              [-174.983573283999931, -27.077840515],
              [-174.972258283999963, -27.089515515000016],
              [-174.961001283999821, -27.101236514999982],
              [-174.949803283999842, -27.113003514999889],
              [-174.938663283999944, -27.124815514999966],
              [-174.927581283999984, -27.136671514999932],
              [-174.916559283999902, -27.148573514999896],
              [-174.905596283999927, -27.160519515000033],
              [-174.894692283999916, -27.172509515],
              [-174.883848283999924, -27.184544515000027],
              [-174.873064283999923, -27.196622515],
              [-174.862340283999913, -27.208744515000035],
              [-174.85167628399995, -27.220909515000017],
              [-174.841074283999916, -27.233117515],
              [-174.830532283999958, -27.245368514999939],
              [-174.820051283999959, -27.257661514999938],
              [-174.809631283999948, -27.269997514999943],
              [-174.799273283999952, -27.282375515000012],
              [-174.788977284, -27.294795515000033],
              [-174.778743283999916, -27.307256514999892],
              [-174.768571283999933, -27.31975951499993],
              [-174.758462283999819, -27.332303514999978],
              [-174.748415283999947, -27.344887514999982],
              [-174.738431283999944, -27.357513515],
              [-174.728510283999952, -27.370178515000021],
              [-174.718653284, -27.382884515],
              [-174.708859283999914, -27.395629515],
              [-174.699130283999978, -27.408414515],
              [-174.689464283999911, -27.421238515000027],
              [-174.679862283999938, -27.434101515],
              [-174.670325283999972, -27.447003514999949],
              [-174.660852283999986, -27.459943514999907],
              [-174.651445283999891, -27.472922515],
              [-174.642102283999918, -27.485939514999927],
              [-174.632825283999978, -27.498993515],
              [-174.623614284000013, -27.512085515000024],
              [-174.614468284, -27.525213514999962],
              [-174.605388283999929, -27.538379514999974],
              [-174.596374284, -27.55158251499995],
              [-174.587426283999918, -27.564820514999951],
              [-174.578545283999915, -27.57809551500003],
              [-174.569731283999914, -27.59140651499996],
              [-174.560984283999971, -27.60475251500003],
              [-174.552304283999945, -27.618134514999952],
              [-174.543691283999976, -27.631550514999958],
              [-174.53514628399995, -27.645001514999933],
              [-174.526669283999951, -27.658487514999933],
              [-174.518260283999922, -27.672007515000018],
              [-174.509919283999977, -27.685561514999961],
              [-174.501646283999975, -27.69914851499999],
              [-174.493441283999942, -27.712769515],
              [-174.485306283999904, -27.726423514999965],
              [-174.477239283999978, -27.740109515000029],
              [-174.469242283999932, -27.75382951499995],
              [-174.461314283999883, -27.767580514999963],
              [-174.453455283999944, -27.781363514999953],
              [-174.445666283999941, -27.795178514999918],
              [-174.437947283999875, -27.809025514999973],
              [-174.430298284, -27.82290251500001],
              [-174.422719283999953, -27.836810515000025],
              [-174.415210283999926, -27.850749514999961],
              [-174.407772283999947, -27.864718515],
              [-174.40040528399993, -27.878717514999892],
              [-174.393109283999962, -27.892746515],
              [-174.385884283999985, -27.906804514999976],
              [-174.378730282999953, -27.920891515],
              [-174.371648282999928, -27.935007515999928],
              [-174.364637282999979, -27.949152516000026],
              [-174.35769928299996, -27.963324515999943],
              [-174.35083228299996, -27.977525515999957],
              [-174.344037282999949, -27.991753516000017],
              [-174.337315282999867, -28.006009515999949],
              [-174.330665282999945, -28.02029251600004],
              [-174.324088282999952, -28.034601516],
              [-174.317584282999945, -28.048937516000024],
              [-174.311152282999956, -28.063299515999972],
              [-174.304794282999893, -28.077687515999912],
              [-174.298509282999987, -28.092100515999959],
              [-174.292298282999894, -28.106539516],
              [-174.286160282999987, -28.121003515999917],
              [-174.280096282999892, -28.135491516],
              [-174.274106282999952, -28.150004515999967],
              [-174.268170282999961, -28.164491515999941],
              [-174.262308282999982, -28.179002516000025],
              [-174.256520282999986, -28.193537516],
              [-174.250806282999974, -28.20809451599996],
              [-174.245166282999975, -28.222675515999981],
              [-174.239600282999959, -28.237278516],
              [-174.234109282999867, -28.251903516000027],
              [-174.228693282999984, -28.266551516],
              [-174.22335228299994, -28.281220516000019],
              [-174.218085282999908, -28.295910516],
              [-174.212894282999912, -28.310622516000024],
              [-174.207777282999928, -28.325354516],
              [-174.202736282999922, -28.340107515999989],
              [-174.19777128299998, -28.35488051599998],
              [-174.192881282999963, -28.369673516000034],
              [-174.188067282999981, -28.384485515999984],
              [-174.183329282999836, -28.399317516],
              [-174.178666282999842, -28.414168516000018],
              [-174.174080282999824, -28.429037515999937],
              [-174.169570282999956, -28.443925515999979],
              [-174.165137282999979, -28.458830515999978],
              [-174.160780282999923, -28.473754515999985],
              [-174.156499282999903, -28.488694515999953],
              [-174.15229628299997, -28.503652515999988],
              [-174.148169282999987, -28.518627516000038],
              [-174.144119282999924, -28.533618515999933],
              [-174.140147282999976, -28.548625515999959],
              [-174.13625128299995, -28.563648515999944],
              [-174.132433282999983, -28.578687515999945],
              [-174.128660282999903, -28.593813515999955],
              [-174.124965282999966, -28.60895451600004],
              [-174.121349282999915, -28.62411051600003],
              [-174.117811282999924, -28.63928151600004],
              [-174.114352282999903, -28.654465516000016],
              [-174.110972282999938, -28.669664516000012],
              [-174.107670282999919, -28.684876516000031],
              [-174.104447282999956, -28.700101515999961],
              [-174.101304282999962, -28.715339515999972],
              [-174.098239282999941, -28.73059051599995],
              [-174.095254282999974, -28.745852516000014],
              [-174.09234828299995, -28.761127515999988],
              [-174.08952228299998, -28.776414515999988],
              [-174.08677528299998, -28.791711516000021],
              [-174.084108282999949, -28.807020515999966],
              [-174.081520282999946, -28.822339515999943],
              [-174.079012282999912, -28.837669515999949],
              [-174.076585282999844, -28.853008515999989],
              [-174.074237282999974, -28.868357515999946],
              [-174.071969282999987, -28.883716515999936],
              [-174.069781282999969, -28.899083516000019],
              [-174.067674283, -28.914459515999965],
              [-174.065647282999976, -28.929844516],
              [-174.063700282999974, -28.945236515999966],
              [-174.061834282999968, -28.96063751600002],
              [-174.060048282999986, -28.976044516],
              [-174.058343282999942, -28.99145951600002],
              [-174.056718282999952, -29.006880517000013],
              [-174.055175282999926, -29.022308516999942],
              [-174.053712282999925, -29.037742517000027],
              [-174.052330282999975, -29.053182517000039],
              [-174.051029282999906, -29.068627516999982],
              [-174.04980928299986, -29.084077516999969],
              [-174.048670282999922, -29.099531517],
              [-174.047612282999893, -29.114991517000021],
              [-174.046636282999941, -29.130454517000032],
              [-174.045740282999901, -29.145921516999977],
              [-174.044926282999938, -29.161392517000024],
              [-174.044194282999882, -29.176865516999953],
              [-174.043542282999937, -29.192342516999929],
              [-174.042973282999839, -29.207820517000016],
              [-174.042484282999879, -29.223301516999982],
              [-174.042078282999938, -29.238784516999942],
              [-174.041753282999963, -29.254268517000014],
              [-174.041509282999925, -29.269754517000024],
              [-174.041348282999934, -29.285240516999977],
              [-174.041268282999965, -29.30072651699993],
              [-174.04127028299996, -29.316213516999937],
              [-174.041353283, -29.33169951699989],
              [-174.041519282999957, -29.347185517000014],
              [-174.041766282999959, -29.36267051699997],
              [-174.042095282999924, -29.378154516999984],
              [-174.042506282999909, -29.393636516999948],
              [-174.043000282999941, -29.409117517000027],
              [-174.04357528299991, -29.424595516999943],
              [-174.044232282999985, -29.440071517000035],
              [-174.044971282999938, -29.455544516999964],
              [-174.045793282999909, -29.471013517000017],
              [-174.046696282999932, -29.486479516999964],
              [-174.047681282999918, -29.501941516999977],
              [-174.048749282999978, -29.517399517],
              [-174.049899282999917, -29.532853517000035],
              [-174.051131282999819, -29.548302517000025],
              [-174.052445282999912, -29.563745516999973],
              [-174.05384128299994, -29.579183516999933],
              [-174.055320282999929, -29.594615517000022],
              [-174.056880282999941, -29.610041516999956],
              [-174.058523282999914, -29.625460516999965],
              [-174.060248282999908, -29.640872516999934],
              [-174.062055282999864, -29.656277516999978],
              [-174.063945282999924, -29.67167551700004],
              [-174.065916282999922, -29.687065516999951],
              [-174.067970282999966, -29.702447517],
              [-174.070106282999944, -29.717820517],
              [-174.072324282999972, -29.733184517000041],
              [-174.074624282999935, -29.748539516999983],
              [-174.077006282999861, -29.763884517],
              [-174.07947128299989, -29.779220516999942],
              [-174.08201828299994, -29.794545516999904],
              [-174.084646282999955, -29.809860517],
              [-174.087357282999903, -29.825164516999962],
              [-174.090150282999986, -29.840457516999948],
              [-174.093025282999918, -29.855738516999963],
              [-174.095982282999984, -29.87100751700001],
              [-174.099021282999985, -29.88626551699997],
              [-174.102141282999952, -29.901509516999965],
              [-174.105344282999937, -29.916741516999934],
              [-174.108629282999942, -29.931960516999936],
              [-174.111995282999914, -29.947165516999974],
              [-174.115444282999846, -29.962356516999932],
              [-174.118974282999972, -29.977533516999983],
              [-174.122586282999947, -29.992696517000013],
              [-174.126279282999974, -30.007843516999969],
              [-174.130054282999964, -30.022976517000018],
              [-174.133911282999946, -30.038093517],
              [-174.137850282999835, -30.053194516999952],
              [-174.141870282999918, -30.068279516999951],
              [-174.145971282999938, -30.083347518000039],
              [-174.150154282999921, -30.098399517999951],
              [-174.154418282999927, -30.113433517999965],
              [-174.158763283000013, -30.128450518000022],
              [-174.163190282999921, -30.143449517999954],
              [-174.167698282999964, -30.158430517999875],
              [-174.172287282999974, -30.173392517999957],
              [-174.176957282999837, -30.188336518000028],
              [-174.181708282999921, -30.203260517999922],
              [-174.186540282999943, -30.218165518000035],
              [-174.191453282999845, -30.23305051799997],
              [-174.196447282999941, -30.247915517999957],
              [-174.20152128299992, -30.262760518],
              [-174.20667628299995, -30.277584518000026],
              [-174.211912282999918, -30.292386517999944],
              [-174.21722828299994, -30.307168518000026],
              [-174.222625282999928, -30.321927517999939],
              [-174.228102282999942, -30.336665517999961],
              [-174.233659282999923, -30.351380517999985],
              [-174.239296282999959, -30.366072517999953],
              [-174.245013282999935, -30.380741517999979],
              [-174.250810282999964, -30.39538751799995],
              [-174.256688282999932, -30.410009517999981],
              [-174.262645282999898, -30.424607518000016],
              [-174.268681282999978, -30.439180518],
              [-174.274798282999939, -30.453729517999932],
              [-174.28099328299993, -30.46825351800004],
              [-174.287268283, -30.482752518000041],
              [-174.293623282999903, -30.497225517999937],
              [-174.300056282999975, -30.511672517999955],
              [-174.306568282999933, -30.526144518000024],
              [-174.313159282999948, -30.54058951799999],
              [-174.319829282999905, -30.555007518000025],
              [-174.326578282999918, -30.56939751799996],
              [-174.3334072829999, -30.58376151799996],
              [-174.34031528299991, -30.598096517999977],
              [-174.347301282999837, -30.61240251800001],
              [-174.354367282999959, -30.626681518],
              [-174.36151128299997, -30.640930518],
              [-174.368733282999955, -30.655150517999971],
              [-174.37603428299991, -30.669340518000013],
              [-174.383414283999912, -30.683501518000014],
              [-174.390871283999985, -30.697631517999923],
              [-174.398407283999916, -30.711730518000024],
              [-174.406020283999936, -30.725799517999974],
              [-174.413711283999987, -30.739837518],
              [-174.421480283999927, -30.753843518],
              [-174.42932628399987, -30.767817517999958],
              [-174.43725028399993, -30.781760517999942],
              [-174.445250283999968, -30.795669517999954],
              [-174.453328283999923, -30.809546517999934],
              [-174.461483283999911, -30.823390518],
              [-174.469714283999906, -30.837201517999972],
              [-174.478022283999934, -30.850978518000034],
              [-174.486407283999966, -30.864721517999953],
              [-174.49486728399998, -30.878429517999962],
              [-174.50340428399997, -30.892103518],
              [-174.512017283999938, -30.905742518000011],
              [-174.520705283999973, -30.919346518],
              [-174.529470283999984, -30.932914517999961],
              [-174.538309283999922, -30.946447517999957],
              [-174.547224283999839, -30.959943517999932],
              [-174.556215283999819, -30.973403518],
              [-174.565280283999954, -30.986826517999873],
              [-174.57441928399993, -31.000211517999958],
              [-174.58363428399997, -31.01356051800002],
              [-174.592923283999909, -31.02687151799995],
              [-174.602286283999945, -31.040144518000034],
              [-174.611723283999964, -31.053378517999988],
              [-174.621234283999911, -31.066574517999982],
              [-174.630819284000012, -31.07973151799996],
              [-174.64047828399984, -31.09284951799998],
              [-174.650209283999942, -31.105927517999987],
              [-174.660014283999857, -31.118966517999979],
              [-174.669892283999957, -31.131964518000018],
              [-174.679843283999872, -31.144922517999987],
              [-174.689866283999976, -31.15784051899999],
              [-174.699961283999983, -31.170716518999882],
              [-174.704651283999908, -31.185274518999961],
              [-174.70941828399998, -31.199814518999972],
              [-174.714263283999941, -31.214335518999917],
              [-174.719186283999932, -31.228837518999967],
              [-174.724187283999925, -31.243319518999954],
              [-174.729265283999979, -31.257782518999932],
              [-174.734421283999978, -31.272224518999963],
              [-174.73965528399998, -31.28664651899993],
              [-174.74496528399996, -31.301047518999951],
              [-174.750353283999971, -31.315427518999968],
              [-174.755819283999898, -31.329786518999924],
              [-174.761361283999946, -31.34412451899999],
              [-174.766981283999911, -31.358439518999944],
              [-174.77267728399994, -31.372733518999951],
              [-174.778451283999914, -31.387003519000018],
              [-174.78430128399998, -31.401251518999914],
              [-174.790228283999937, -31.415476518999981],
              [-174.796232283999956, -31.429677519],
              [-174.802312283999953, -31.443855518999897],
              [-174.808469283999926, -31.458009519000029],
              [-174.814702283999964, -31.472138518999941],
              [-174.821011283999979, -31.486243519000027],
              [-174.827397283999915, -31.500323519],
              [-174.833859283999971, -31.514377518999936],
              [-174.840396283999951, -31.52840651899993],
              [-174.847010283999822, -31.542409518999932],
              [-174.85369928399993, -31.556387518999884],
              [-174.860463283999934, -31.57033751900002],
              [-174.865528283999964, -31.58444351899999],
              [-174.870667283999921, -31.59852951900001],
              [-174.87588128399986, -31.612596518999965],
              [-174.881168283999955, -31.626642519000029],
              [-174.886529283999977, -31.640668518999973],
              [-174.891964283999982, -31.654673519000028],
              [-174.897473284, -31.668657518999964],
              [-174.903056283999973, -31.682620519000011],
              [-174.908623283999987, -31.696373518999906],
              [-174.914262283999904, -31.710104519000026],
              [-174.919972283999954, -31.723813519000032],
              [-174.925754283999964, -31.737501518999977],
              [-174.931607283999853, -31.751167518999978],
              [-174.937531283999903, -31.764811518999977],
              [-174.943526283999887, -31.778433518999975],
              [-174.949593283999945, -31.792031519000034],
              [-174.955731283999967, -31.805607519000034],
              [-174.957903283999912, -31.820993518999956],
              [-174.960160283999954, -31.836371518999954],
              [-174.962501283999927, -31.851739518999977],
              [-174.964926283999858, -31.867098518999967],
              [-174.96743528399989, -31.882446518999984],
              [-174.970028283999937, -31.897785519000024],
              [-174.972706283999827, -31.913113519000035],
              [-174.975468283999987, -31.928431519000014],
              [-174.978314283999936, -31.943737518999967],
              [-174.981244283999985, -31.95903151899995],
              [-174.984258283999878, -31.974314518999961],
              [-174.987356283999958, -31.989585518999945],
              [-174.990538283999967, -32.004843518999962],
              [-174.993804283999935, -32.020088518999955],
              [-174.997155283999831, -32.035320518999981],
              [-175.000589283999915, -32.050539518999983],
              [-175.004107283999929, -32.065743519000023],
              [-175.007709283999986, -32.080934518999982],
              [-175.011395283999974, -32.096110519000035],
              [-175.015164283999894, -32.111271519000013],
              [-175.019018283999912, -32.126417518999972],
              [-175.022955283999977, -32.141547519000028],
              [-175.026976283999943, -32.156662519],
              [-175.031080283999955, -32.171760518999974],
              [-175.035268283999841, -32.186842518999981],
              [-175.039540283999969, -32.201907519000031],
              [-175.043895283999973, -32.216955518999953],
              [-175.048333283999909, -32.231985520000023],
              [-175.052855283999889, -32.246998519999977],
              [-175.057460283999973, -32.261992519999978],
              [-175.062149283999986, -32.276968519999912],
              [-175.066920283999934, -32.29192552],
              [-175.071775283999926, -32.306863520000036],
              [-175.076713283999936, -32.321781519999945],
              [-175.081734283999964, -32.336679520000018],
              [-175.08683728399987, -32.351558520000026],
              [-175.092024283999962, -32.36641652000003],
              [-175.097293283999903, -32.38125352000003],
              [-175.102645283999976, -32.396068520000028],
              [-175.108080283999982, -32.410863520000021],
              [-175.113597283999951, -32.425636520000012],
              [-175.11919728399991, -32.44038652],
              [-175.124879283999945, -32.455114519999938],
              [-175.130643283999916, -32.469819519999987],
              [-175.136490283999905, -32.484501520000038],
              [-175.142419283999914, -32.499160520000032],
              [-175.148429284, -32.513795519999974],
              [-175.15452228399991, -32.528406519999919],
              [-175.160697283999951, -32.542992519999984],
              [-175.166953283999931, -32.55755452],
              [-175.173291283999959, -32.572091519999901],
              [-175.179710283999952, -32.586602519999985],
              [-175.186211283999967, -32.601088519999962],
              [-175.192793283999919, -32.61554752],
              [-175.199457283999919, -32.62998052],
              [-175.206201283999974, -32.64438752],
              [-175.213027283999963, -32.658766519999915],
              [-175.219933283999922, -32.67311852],
              [-175.226921283999985, -32.687443519999988],
              [-175.233989283999932, -32.70174052],
              [-175.241137283999933, -32.71600852],
              [-175.248366283999957, -32.730247519999978],
              [-175.255675283999949, -32.744458519999966],
              [-175.263065284, -32.758640519999915],
              [-175.270534283999979, -32.772792519999882],
              [-175.278083283999848, -32.786914520000039],
              [-175.285713283999911, -32.801005519999933],
              [-175.293421283999891, -32.815067520000014],
              [-175.301210283999893, -32.82909752],
              [-175.309077283999954, -32.843097520000015],
              [-175.317024283999956, -32.857064519999938],
              [-175.325050283999957, -32.871001519999879],
              [-175.333155283999901, -32.884905520000018],
              [-175.341339283999986, -32.898776520000013],
              [-175.349602283999957, -32.912615520000031],
              [-175.357943283999958, -32.926421519999963],
              [-175.366362283999962, -32.94019352],
              [-175.374860283999908, -32.95393252],
              [-175.383436283999941, -32.967637520000039],
              [-175.392089283999923, -32.98130852],
              [-175.400820283999963, -32.99494452],
              [-175.409629283999919, -33.008545519999984],
              [-175.418516283999821, -33.022111519999953],
              [-175.427479283999958, -33.035641520000013],
              [-175.436520283999982, -33.049136519999934],
              [-175.445638284000012, -33.062594519999948],
              [-175.454832283999821, -33.076016519999939],
              [-175.464103284999879, -33.089401520000024],
              [-175.473450284999984, -33.102750519999972],
              [-175.482874284999838, -33.116061520000017],
              [-175.492373284999843, -33.129334519999986],
              [-175.50194928499991, -33.142569519999938],
              [-175.511600284999957, -33.155766519999929],
              [-175.521327284999984, -33.168925519999959],
              [-175.531129284999935, -33.182045519999917],
              [-175.541006284999952, -33.195125519999976],
              [-175.55095828499995, -33.208166519999963],
              [-175.56098528499993, -33.22116852],
              [-175.571086284999836, -33.23412952000001],
              [-175.58126228499998, -33.247050520000016],
              [-175.591511284999939, -33.259930519999955],
              [-175.601835284999908, -33.27277052],
              [-175.612233284999945, -33.285568520000027],
              [-175.622704284999912, -33.29832452100004],
              [-175.633248284999951, -33.311039520999941],
              [-175.643866284999916, -33.323712520999948],
              [-175.654556284999956, -33.336342521],
              [-175.665319284999924, -33.348930520999886],
              [-175.676155284999965, -33.361475520999932],
              [-175.687063284999937, -33.373976520999975],
              [-175.698043284999983, -33.386434520999956],
              [-175.709095284999961, -33.398848521],
              [-175.720218284999959, -33.411218520999967],
              [-175.731413284999945, -33.42354452099994],
              [-175.742679284999952, -33.43582552099997],
              [-175.754016284999977, -33.448061520999943],
              [-175.76542328499994, -33.460251521000032],
              [-175.77690128499998, -33.472397520999948],
              [-175.788450284999982, -33.48449652100004],
              [-175.800068284999981, -33.496549520999963],
              [-175.811756284999916, -33.508556521],
              [-175.823514284999931, -33.52051652099999],
              [-175.835317284999974, -33.532422521],
              [-175.847190284999925, -33.544281520999959],
              [-175.859131284999933, -33.556093521000037],
              [-175.871141284999936, -33.567857520999951],
              [-175.883218284999913, -33.579573520999986],
              [-175.895364284999829, -33.591241521000029],
              [-175.907577284999945, -33.602861521000023],
              [-175.919858285, -33.614432521000026],
              [-175.932206284999921, -33.625954520999983],
              [-175.944621284999954, -33.637427521],
              [-175.957102284999962, -33.648850520999929],
              [-175.969650284999972, -33.660224520999975],
              [-175.982264284999985, -33.671547521000036],
              [-175.994944284999917, -33.682821521],
              [-176.007690284999825, -33.694044520999967],
              [-176.02050028499994, -33.705216520999954],
              [-176.033376284999946, -33.716337520999957],
              [-176.046317284999901, -33.727407520999975],
              [-176.059322284999979, -33.738425521000011],
              [-176.072391284999981, -33.74939152099995],
              [-176.085524284999906, -33.760305520999964],
              [-176.098721285000011, -33.771167520999882],
              [-176.111982284999954, -33.781977520999988],
              [-176.125305284999826, -33.792733521],
              [-176.138691284999908, -33.803437521000035],
              [-176.152140284999973, -33.814087520999976],
              [-176.165651284999939, -33.824684521],
              [-176.179224284999947, -33.835226521000038],
              [-176.19285928499994, -33.845715520999988],
              [-176.206555284999951, -33.856150520999961],
              [-176.22031228499992, -33.866529521000018],
              [-176.234130284999964, -33.876854520999984],
              [-176.248008285, -33.887124521000032],
              [-176.261946284999908, -33.897339520999935],
              [-176.275945284999921, -33.90749852099998],
              [-176.290003284999926, -33.917602520999878],
              [-176.304120284999925, -33.927649521000035],
              [-176.318296284999946, -33.937641520999932],
              [-176.332531284999931, -33.947575520999976],
              [-176.34682428499994, -33.957453521],
              [-176.361175284999945, -33.967275520999976],
              [-176.37558428499986, -33.977039520999938],
              [-176.390050284999944, -33.986745520999932],
              [-176.404573284999969, -33.996394521000013],
              [-176.419153284999908, -34.0059855209999],
              [-176.433790284999958, -34.015519520999931],
              [-176.448482284999926, -34.024993520999942],
              [-176.463230284999923, -34.034410520999984],
              [-176.478034284999836, -34.04376752099995],
              [-176.492893284999923, -34.053066520999948],
              [-176.507806284999987, -34.062305520999928],
              [-176.522775284999881, -34.071485521],
              [-176.537797285999972, -34.080606521],
              [-176.552873285999908, -34.089667520999967],
              [-176.56800228599991, -34.098667520999982],
              [-176.583185285999917, -34.107608521000031],
              [-176.598420285999964, -34.116488520999951],
              [-176.613708285999934, -34.125307521000025],
              [-176.629048285999971, -34.134065520999968],
              [-176.64443928599988, -34.142763520999949],
              [-176.659882286, -34.151399520999973],
              [-176.67537628599996, -34.159973520999984],
              [-176.690921285999906, -34.168486520999977],
              [-176.706516285999982, -34.176937521000013],
              [-176.722161285999931, -34.185326521000036],
              [-176.737856285999868, -34.193652520999933],
              [-176.753600285999909, -34.20191652099993],
              [-176.769392285999942, -34.21011852099997],
              [-176.785234285999962, -34.218256521],
              [-176.801124285999919, -34.226332520999961],
              [-176.817061285999984, -34.234344521000025],
              [-176.833046285999984, -34.242293521000022],
              [-176.84907828599998, -34.250178520999953],
              [-176.86515728599997, -34.257999520999931],
              [-176.881283285999928, -34.265757520999955],
              [-176.89745428599997, -34.273450521000029],
              [-176.913671285999925, -34.28107852100004],
              [-176.929933285999937, -34.288643520999983],
              [-176.946240285999949, -34.296142521000036],
              [-176.96259228599996, -34.303576520999968],
              [-176.978988285999975, -34.310946520999948],
              [-176.995428285999964, -34.318250520999982],
              [-177.011911285999986, -34.325489520999952],
              [-177.028437286000013, -34.332662520999975],
              [-177.045005285999935, -34.339769520999937],
              [-177.061616285999975, -34.346810520999952],
              [-177.078269285999937, -34.353786521000018],
              [-177.094964285999907, -34.360695521000025],
              [-177.111699285999975, -34.367537521000031],
              [-177.128475285999968, -34.374313521999966],
              [-177.145292285999915, -34.381022521999967],
              [-177.162148285999962, -34.387664522000023],
              [-177.179044285999879, -34.394239522000021],
              [-177.195979285999982, -34.400747521999961],
              [-177.21295328599993, -34.407187522000015],
              [-177.229966285999922, -34.413560522000012],
              [-177.247016285999877, -34.419865521999952],
              [-177.264104285999935, -34.426103521999892],
              [-177.281229285999956, -34.432272521999948],
              [-177.298391285999912, -34.438373522],
              [-177.315589285999948, -34.444406521999952],
              [-177.332823285999922, -34.450370522000014],
              [-177.350093285999861, -34.456266521999964],
              [-177.36739828599994, -34.462093522000032],
              [-177.384737285999989, -34.467851521999933],
              [-177.402111285999979, -34.473540522],
              [-177.419519285999968, -34.479160522000029],
              [-177.436961285999928, -34.484711521999941],
              [-177.454435285999949, -34.490192522000029],
              [-177.47194328599997, -34.495604522],
              [-177.489482285999941, -34.500946521999879],
              [-177.507054285999914, -34.506218521999983],
              [-177.52465728599995, -34.511421521999978],
              [-177.542291285999823, -34.516553521999981],
              [-177.559956285999846, -34.521615521999934],
              [-177.577651285999934, -34.526607521999949],
              [-177.595376285999947, -34.531528521999974],
              [-177.613130286999962, -34.536379522],
              [-177.630913287, -34.541159521999987],
              [-177.648725286999905, -34.545869521999975],
              [-177.666565286999941, -34.550507522000032],
              [-177.684433286999962, -34.555075521999981],
              [-177.702328286999915, -34.559572522],
              [-177.720250287, -34.563997522000022],
              [-177.738198286999989, -34.568351521999944],
              [-177.756172287, -34.572633521999933],
              [-177.774172286999914, -34.576844521999931],
              [-177.792197287, -34.580984522],
              [-177.810247286999896, -34.585051521999958],
              [-177.828321286999909, -34.58904752199993],
              [-177.846419286999975, -34.592971521999971],
              [-177.864540286999954, -34.596823522000022],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "OMN", Country: "Oman" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [57.668075673000089, 24.737609561000056],
              [57.693019153000137, 24.728883715],
              [57.697530301000199, 24.727312161000157],
              [57.706531944000233, 24.725786168000127],
              [57.710925357500287, 24.724236653999981],
              [57.716785620000053, 24.722169797000163],
              [57.725012851000201, 24.719247816000063],
              [58.084187365000162, 24.708888774000229],
              [58.096362279000118, 24.708428921000063],
              [58.099392178000073, 24.708314480000126],
              [58.121690204000288, 24.707544517000088],
              [58.150010812000176, 24.706566592000115],
              [58.211154371000248, 24.704084813000179],
              [58.237451654000154, 24.703605211000138],
              [58.28291619200013, 24.702776043000085],
              [58.378807181500008, 24.700661143500099],
              [58.387810278000188, 24.700462578000042],
              [58.400447597000152, 24.700525498000104],
              [58.431156645000101, 24.700678396],
              [58.478992318000223, 24.693902293000178],
              [58.499078446000055, 24.689015063000113],
              [58.63962895900022, 24.620851985000058],
              [58.767379009000081, 24.558896774000175],
              [59.089533555000173, 24.469963632000145],
              [59.099858583000014, 24.467093778000219],
              [59.144703330000141, 24.454629127000146],
              [59.249679908000246, 24.424316349000094],
              [59.285702535000155, 24.413914544000022],
              [59.324841125000205, 24.402612982000164],
              [59.32815551400023, 24.400865253000234],
              [59.346270207000089, 24.391313094000111],
              [59.348042943000223, 24.390378302000045],
              [59.358802605000164, 24.38462904000022],
              [59.503161396500218, 24.330581425000162],
              [59.532020392000163, 24.319776680000132],
              [59.602352631000031, 24.283773298000142],
              [59.895362881000096, 24.133780063000074],
              [59.901694203000147, 24.130481582333346],
              [59.905970665000268, 24.128708749500106],
              [59.961851024000055, 24.105739698999969],
              [60.098287750000168, 24.032451386000105],
              [60.114602219000062, 24.023687911000195],
              [60.211759636500176, 23.959369689000084],
              [60.233502407000287, 23.944975972000123],
              [60.265637959000173, 23.925784546000102],
              [60.280925012000182, 23.91665508300008],
              [60.374424890000171, 23.873222342000133],
              [60.421147409000014, 23.851518709],
              [60.450374911000154, 23.845508666],
              [60.456786739999984, 23.842635913000095],
              [60.755839583000238, 23.70801158300003],
              [60.763572607000242, 23.704499363000025],
              [60.788027814000145, 23.693392188000018],
              [60.81614438600019, 23.680092013000092],
              [60.866768463000085, 23.656144956000162],
              [60.965252787000225, 23.602879738000127],
              [61.41666671500019, 23.346666633400105],
              [61.546666667000238, 23.256111111],
              [61.608929839000098, 23.213317228000108],
              [61.636388889000102, 23.194444444],
              [62.014166667000097, 22.943055556000218],
              [62.040158738000173, 22.92592111800019],
              [62.063888889000282, 22.910277778000136],
              [62.421388889000241, 22.676944444000014],
              [62.68835604100002, 22.456433293000089],
              [63.138427870000214, 22.084680228000025],
              [63.139722222000216, 22.083611110999982],
              [63.15134936700025, 22.0684113800001],
              [63.239166667000291, 21.9536111110001],
              [63.370277615000248, 21.790000048333411],
              [63.342939393000279, 21.604328948000159],
              [63.328119393000293, 21.560896948],
              [63.327703393000149, 21.557983948000185],
              [63.327168393000136, 21.556414948000054],
              [63.326809393000246, 21.553904948000195],
              [63.326129393000144, 21.551912948000222],
              [63.325931393000246, 21.55052694800014],
              [63.325423393000165, 21.549037948000034],
              [63.325035893000063, 21.546339448000197],
              [63.323967393000089, 21.538879948000101],
              [63.323268393000205, 21.536827948],
              [63.323092393000195, 21.535598948000086],
              [63.135528393000243, 20.982518947000074],
              [62.849752392000198, 20.467511947],
              [62.830400392, 20.443764946000101],
              [62.691333392000274, 20.191251946000023],
              [62.690671392000013, 20.190434946000096],
              [62.687042392000308, 20.183831946],
              [62.686197392000224, 20.182789946000128],
              [62.685412392000103, 20.181360946000041],
              [62.622601392000036, 20.103886946000102],
              [62.611909392000143, 20.08438894599999],
              [62.571740392000095, 20.034765946000164],
              [62.493757892000076, 19.892240446000073],
              [62.492922392000253, 19.890761946],
              [62.492048392000015, 19.88967894600006],
              [62.490371392000213, 19.886607946000026],
              [62.489052392000303, 19.884973946],
              [62.447908392000045, 19.809595946000172],
              [62.276149392000207, 19.283321945000125],
              [61.997579392000205, 18.766527945],
              [61.630395390999979, 18.30248794400012],
              [61.566977391000137, 18.245947944000193],
              [61.431727391000038, 18.073827944000129],
              [61.431285891000243, 18.073409944000218],
              [61.402009391000149, 18.047185944000091],
              [61.393951391000087, 18.036908944000061],
              [61.38488739100012, 18.028791944000147],
              [61.374237391000179, 18.01520394400012],
              [61.113087391000136, 17.78070594400009],
              [60.853893390000167, 17.285305944000129],
              [60.493573390000108, 16.818225276333351],
              [60.055999390000068, 16.41709694299999],
              [60.048090390000084, 16.412019943000018],
              [60.041086390000203, 16.405573943000093],
              [60.034205390000011, 16.401154943000222],
              [60.032999723333603, 16.400086943000133],
              [60.031391390000238, 16.399003943000082],
              [60.028024390000212, 16.395945276333464],
              [60.025019390000239, 16.393964943000242],
              [60.016224390000161, 16.385866943000224],
              [59.526717389000055, 16.07010894199999],
              [59.39592138900025, 15.811560942000085],
              [59.043072389000145, 15.34133494200006],
              [58.612719388000301, 14.936436941000125],
              [58.117822388000207, 14.608709940999972],
              [57.573023387000092, 14.36770094100018],
              [57.432575372000201, 14.335011927500204],
              [57.416508322000197, 14.340632110000058],
              [57.21117876500017, 14.41245571200011],
              [57.010730991000173, 14.482571683000231],
              [56.857626010000217, 14.535220504000165],
              [56.75098642900025, 14.571891082000093],
              [56.722924640000031, 14.574950607000062],
              [56.688996949000057, 14.578649682000105],
              [55.754288461000101, 14.678600820000099],
              [55.481746001000147, 14.700394489000075],
              [55.152825737000171, 14.719295489000046],
              [55.070281281000177, 14.730403735000039],
              [54.822048662000157, 14.763809116000118],
              [54.796978182000089, 14.767182923000064],
              [54.767408805000173, 14.771126770000023],
              [54.696629386000211, 14.780567049000126],
              [54.632529117000104, 14.79029725900017],
              [54.542574155500176, 14.803991339000049],
              [54.440800883000236, 14.794103865000181],
              [54.315427324000041, 14.77924769800012],
              [54.162469755000245, 14.761639582000157],
              [53.877762043000189, 15.086733210999967],
              [53.647531499000138, 15.349622060000115],
              [53.628001471000175, 15.437013278000052],
              [53.587726104000126, 15.617233882000107],
              [53.329676549000169, 16.174789867000243],
              [53.131193636000148, 16.603642907000108],
              [53.130337041000246, 16.605366719000244],
              [53.127463829000192, 16.611148764],
              [53.121894093000151, 16.625366042000053],
              [53.114827668607859, 16.643401881592695],
              [53.114436334823949, 16.642781833787637],
              [52.812282196099829, 17.285527023952],
              [52.782173226343161, 17.349727038037145],
              [51.999291229990888, 18.999345099085815],
              [55.000000169966484, 20.000000172294619],
              [55.422200154492316, 21.267509059455506],
              [55.666109178989643, 21.999718092381045],
              [55.199163242426835, 22.699718153522326],
              [55.217773246823015, 22.800553957747184],
              [55.218609257848243, 22.820554016969993],
              [55.21721819719329, 22.851664121244937],
              [55.213336202545804, 22.894164226138628],
              [55.212218224297146, 22.918327073766079],
              [55.2141633287535, 23.014027118263073],
              [55.242073222387035, 23.116809032772608],
              [55.267491176199371, 23.157773070085966],
              [55.296391307877002, 23.201382100772946],
              [55.32360919158225, 23.243891090484112],
              [55.34389121802883, 23.281800091719504],
              [55.361382238347915, 23.326109011323467],
              [55.421945176997212, 23.507218134846028],
              [55.485836234130488, 23.641664197431908],
              [55.562563337822553, 23.768536037011131],
              [55.55638218712761, 23.88576416168371],
              [55.538191277891258, 23.905000126611128],
              [55.510273337630679, 23.97277317839189],
              [55.637909271505464, 24.027909169995894],
              [55.680273254291251, 24.031109045361063],
              [55.801382200145042, 24.0200001737756],
              [55.875273203070833, 24.035000092464088],
              [56.007082318910193, 24.068609009720433],
              [56.023673290463989, 24.083191174353772],
              [55.948609156366672, 24.232573117887682],
              [55.918609151351518, 24.236664157200835],
              [55.892082271745721, 24.227427132237779],
              [55.862500188424121, 24.214026983593726],
              [55.778954071203657, 24.243645109099191],
              [55.813609217617511, 24.316945020211392],
              [55.820000251168608, 24.416664187291929],
              [55.797773287903851, 24.476944991079449],
              [55.781382308560239, 24.565418158638295],
              [55.812491239368683, 24.596109167753241],
              [55.843609222632438, 24.649718144232565],
              [55.820000251168608, 24.701664151116972],
              [55.813891184840941, 24.729718045847619],
              [55.803809264035351, 24.860064184302814],
              [55.814027139310838, 24.885827137250885],
              [55.845554159450842, 24.920273071360896],
              [55.866391235527146, 24.942218067401882],
              [55.888745100806318, 24.959582185706722],
              [55.922218231230772, 24.976527041213501],
              [55.945273326531549, 24.982500153071101],
              [55.976573197094808, 24.981991203908819],
              [56.000136235729428, 24.977082090843453],
              [56.022773241698474, 24.965827038866109],
              [56.034718124309165, 24.946391081728507],
              [56.05082713642912, 24.866109045251676],
              [56.028609225619846, 24.881245086048068],
              [55.998745175074475, 24.890273233983748],
              [55.981136137558337, 24.87173598450704],
              [56.064436162100833, 24.744236005102152],
              [56.076945146796731, 24.738054016216893],
              [56.104163365778163, 24.734718018743763],
              [56.143745059616094, 24.74110905229486],
              [56.179718176488961, 24.781664220370374],
              [56.188763255868821, 24.825000168650888],
              [56.2261091608475, 24.83360905378845],
              [56.26943622431088, 24.849509021242397],
              [56.280473346804854, 24.857273178175433],
              [56.28708230983932, 24.870136046824427],
              [56.303327108790825, 24.88250002459506],
              [56.332145097817033, 24.895136078943551],
              [56.340136236688579, 24.914027044735008],
              [56.339163265365301, 24.926109055281927],
              [56.334645084265105, 24.96180908974867],
              [56.33900920598461, 24.969445003562782],
              [56.352145157039672, 24.976527041213501],
              [56.373527294735823, 24.979381517154309],
              [56.380311386000074, 24.981982951000077],
              [56.44090738600022, 24.9899439510001],
              [56.520329386000128, 25.002481951000107],
              [56.614322938000157, 25.020431804000168],
              [56.674841969000227, 25.03198896300006],
              [56.684719242000256, 25.033344574000068],
              [56.724244612000177, 25.038769254000044],
              [56.833937628000058, 25.05525935300011],
              [57.034107306000124, 25.089745454000024],
              [57.125662434091112, 25.11452814800009],
              [57.141025269000238, 25.102968179000129],
              [57.162196338000172, 25.087543252999978],
              [57.163093226000115, 25.086891300000161],
              [57.173110944000143, 25.079685552000058],
              [57.17729303300024, 25.076677374000042],
              [57.180762363000042, 25.074134282000102],
              [57.186845164000204, 25.069675462000134],
              [57.193312953000202, 25.064945953000063],
              [57.195413012000188, 25.063410304000115],
              [57.222201401000177, 25.040973156000121],
              [57.272156118000169, 24.999132589000055],
              [57.278716289000158, 24.993651514000135],
              [57.279972395500238, 24.992533434749987],
              [57.280556548000277, 24.992015738000021],
              [57.286937955000099, 24.987501867],
              [57.288162108000222, 24.986635965000119],
              [57.29810687100013, 24.97959191800004],
              [57.341967632000205, 24.948524587000179],
              [57.484850756500066, 24.847638377000067],
              [57.511762715000145, 24.82956027299997],
              [57.531259896000165, 24.817100161000042],
              [57.581168411000021, 24.785205000000246],
              [57.668075673000089, 24.737609561000056],
            ],
          ],
          [
            [
              [56.800000000000296, 26.193055556000104],
              [56.837500000000148, 26.051388889000123],
              [56.832958151000298, 25.996886708000147],
              [56.830555557000082, 25.968055542000116],
              [56.83507847400017, 25.933455224000227],
              [56.855814890000261, 25.774821645000031],
              [56.849353434000136, 25.723632849000012],
              [56.840910195000106, 25.656744026000126],
              [56.840272212000173, 25.656887149],
              [56.838958296000158, 25.657181906000119],
              [56.834322550000138, 25.658221867000123],
              [56.777176069, 25.671041833],
              [56.673237233000123, 25.677827711000134],
              [56.571833351, 25.684448089000057],
              [56.529039339000235, 25.687374177000038],
              [56.516011758000104, 25.687113222999983],
              [56.501131042000111, 25.686055268000075],
              [56.4702833300002, 25.68307499500014],
              [56.461081030000287, 25.682185938000032],
              [56.450786476000303, 25.681325979000178],
              [56.419113407000225, 25.678680160000226],
              [56.395559697000238, 25.676418112000135],
              [56.389900034999982, 25.676114449000124],
              [56.385140388000082, 25.675790448000043],
              [56.380454582000198, 25.675396645000262],
              [56.379388775000166, 25.675276515000064],
              [56.370883873500134, 25.673687323250135],
              [56.369151630000033, 25.673372196666833],
              [56.36774597200025, 25.67318362400016],
              [56.337398632000202, 25.656287639000084],
              [56.334202775000165, 25.655155971999989],
              [56.331097496000041, 25.654056378000121],
              [56.326390651000224, 25.652506564000191],
              [56.32008866100017, 25.650431519000165],
              [56.31490816400003, 25.648909891000187],
              [56.314217028000257, 25.648702934000028],
              [56.311097094000218, 25.647765967999987],
              [56.30577662800016, 25.644160218000081],
              [56.303782286000171, 25.642664194000076],
              [56.300219904000301, 25.640281437000226],
              [56.280459542000131, 25.640293253000095],
              [56.26971828836372, 25.636017992021436],
              [56.238036106185177, 25.626109116423379],
              [56.183327256368159, 25.654991143189818],
              [56.158973301347885, 25.662500154989573],
              [56.147218185025565, 25.666109067230821],
              [56.140827151474468, 25.734991044805042],
              [56.139163343689631, 25.832500071650145],
              [56.165818131137939, 25.908054044170456],
              [56.185554160410163, 26.014718120965711],
              [56.153182244427683, 26.083882065763362],
              [56.079936272765337, 26.065564100303334],
              [56.0763843860002, 26.069367952000107],
              [56.061278142000162, 26.079078878000132],
              [56.043659752000224, 26.090404986000067],
              [56.023791428000152, 26.101785153000094],
              [56.015572262000092, 26.106492922000143],
              [56.009441455000058, 26.109668462000172],
              [55.999248416, 26.114948093000123],
              [55.960880231000203, 26.13929793300008],
              [55.952823918000206, 26.144410762000064],
              [55.936697779000042, 26.15464499400008],
              [55.900765839500195, 26.176581079000073],
              [55.850564041000098, 26.20722876800022],
              [55.816444882000098, 26.228058169000121],
              [55.757958236000036, 26.263763673000199],
              [55.795833333000076, 26.276388889000089],
              [55.870833333000292, 26.313888889000111],
              [55.928559036000195, 26.353036205000194],
              [56.089547505000155, 26.462212292500112],
              [56.112500000000239, 26.477777778000075],
              [56.123210446000201, 26.491896094000083],
              [56.141074028000276, 26.515443542000128],
              [56.143055556000093, 26.518055556000036],
              [56.158694377000245, 26.532983522000109],
              [56.173611290500219, 26.547222336000118],
              [56.182395586000098, 26.552779747000159],
              [56.211578891000045, 26.571242655000106],
              [56.241666667000175, 26.590277778000086],
              [56.27373563600014, 26.592950192000217],
              [56.274999776500295, 26.593055537499993],
              [56.318223937000027, 26.612392580000062],
              [56.327777778000154, 26.616666667000118],
              [56.455243041500239, 26.666856114500149],
              [56.549999906000153, 26.704166630000088],
              [56.650819574000252, 26.729371560000118],
              [56.683333360000177, 26.737499976000095],
              [56.714496330000202, 26.709799559000089],
              [56.733333333000132, 26.69305555599999],
              [56.742679333000183, 26.678725024000187],
              [56.754166667000277, 26.661111111000025],
              [56.784114487000181, 26.608203295000067],
              [56.793064217000278, 26.592392106000034],
              [56.795833332000143, 26.587499959500065],
              [56.795133647000284, 26.559512530000063],
              [56.793078946000151, 26.47732449800003],
              [56.7916666670003, 26.420833333000019],
              [56.795440915000142, 26.399805379999975],
              [56.801388889, 26.366666667000175],
              [56.799374263000118, 26.322344902],
              [56.79722222200013, 26.275],
              [56.798368199000208, 26.241193693000184],
              [56.800000000000296, 26.193055556000104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "PAK", Country: "Pakistan" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [77.823927274331226, 35.501326992413695],
            [77.818054074759772, 35.497218015827414],
            [77.776382269540164, 35.475545096364229],
            [77.566082329937473, 35.368600058173897],
            [77.398036234912666, 35.283609068479919],
            [77.328045163907319, 35.247773247181598],
            [77.244136110278276, 35.204709040202701],
            [77.049427181177776, 35.103882120795234],
            [77.042482271463342, 35.09916411512269],
            [77.050127237732994, 35.088600067244883],
            [77.064282260579176, 35.04554508035956],
            [77.062754071987882, 35.020827015464121],
            [77.035400233812624, 34.991864187150682],
            [76.954018156359979, 34.856244993393219],
            [76.948309242091369, 34.790691134303316],
            [76.906791328614332, 34.673882104790366],
            [76.869982200716692, 34.658882186101906],
            [76.843318193174611, 34.658736005710111],
            [76.822209208158569, 34.663882158997964],
            [76.7869272630235, 34.683182161665641],
            [76.763600258783157, 34.71068218023278],
            [76.735118216437201, 34.739436131518445],
            [76.702345142567793, 34.749018155617236],
            [76.656936181987845, 34.745273121267942],
            [76.506518235218465, 34.76040916206459],
            [76.475882211388665, 34.769509059091334],
            [76.450000235415047, 34.767209064853702],
            [76.428036128634773, 34.75832709730993],
            [76.298727166881434, 34.702909138482241],
            [76.282209285523521, 34.687491130462078],
            [76.242763378517424, 34.637909155555761],
            [76.229218222948163, 34.611382108311872],
            [76.165118288787056, 34.586864203265051],
            [76.134991214119367, 34.592764224926682],
            [76.113872338457696, 34.604436025131051],
            [76.086109295768182, 34.617491174639341],
            [76.067218162338605, 34.624164175414151],
            [76.038318198299208, 34.629991106879928],
            [76.01500024651412, 34.630273241741619],
            [75.97914514683842, 34.624018162660334],
            [75.932209171133906, 34.606382135415814],
            [75.888882275308589, 34.579991210279999],
            [75.860882192396758, 34.554991178161046],
            [75.827491204623328, 34.518744978881969],
            [75.798872369617328, 34.506664141801579],
            [75.661372276781862, 34.500827152051798],
            [75.637209261516404, 34.502773094698469],
            [75.389154212420891, 34.555827021547984],
            [75.348736172281775, 34.570273231711198],
            [75.243000307446806, 34.626100059776817],
            [75.186509297371714, 34.623045023699021],
            [75.135545146627777, 34.625264216389965],
            [75.110536229691405, 34.62777308765537],
            [75.064145077694803, 34.634991079775986],
            [74.692745126248013, 34.693600196789546],
            [74.671918276093749, 34.698045120055994],
            [74.637209150223413, 34.706654005193471],
            [74.558318174401421, 34.730273202579369],
            [74.523600163713667, 34.74207324590283],
            [74.480400169902936, 34.758054014903635],
            [74.44470030307437, 34.769436136533656],
            [74.380818130758456, 34.782636125329262],
            [74.357209159294399, 34.783609096652512],
            [74.329709140727374, 34.780545008119205],
            [74.298600209918931, 34.772491172611453],
            [74.247482335070373, 34.754718017430534],
            [74.208882329735189, 34.735753961443109],
            [74.15540025527028, 34.700409152034169],
            [74.13053617762111, 34.694154072952969],
            [74.066372373357666, 34.689154100056825],
            [73.990263351207631, 34.675553959202531],
            [73.962345075670925, 34.664018113467861],
            [73.936572232076884, 34.632973220400046],
            [73.903591287008027, 34.538327116411253],
            [73.875263304043045, 34.511936023637546],
            [73.799909156095026, 34.39756411629871],
            [73.804563291665119, 34.357345062541626],
            [73.82500021568265, 34.334718114856159],
            [73.851372365355331, 34.317218042081535],
            [73.882754210931893, 34.310273132367101],
            [73.906100158273517, 34.31081812371302],
            [73.93817233739756, 34.312491151591573],
            [73.976372358312204, 34.298045109066337],
            [74.021582332510064, 34.201936027693321],
            [74.006518208443055, 34.171109064108677],
            [73.9880361118895, 34.161373148266975],
            [73.961109247863277, 34.151518041761889],
            [73.933172364501473, 34.136654077543241],
            [73.915536169618775, 34.119718106853824],
            [73.907900255804549, 34.090409105938321],
            [73.916227173718511, 34.063882226332609],
            [73.933727246493135, 34.041109098255632],
            [73.951382216838994, 34.024573111986726],
            [73.985263378311373, 34.011382175646517],
            [74.021936216463217, 34.014300083788115],
            [74.051654086616651, 34.02304509103358],
            [74.085818221141068, 34.027491187766501],
            [74.244427131354399, 34.019154043930669],
            [74.276863252715231, 34.006109120344163],
            [74.294563317700209, 33.973600076425484],
            [74.276927122817682, 33.921591205266836],
            [74.257491333318086, 33.90110013415476],
            [74.235809193761412, 33.884164163465258],
            [74.209163291130409, 33.867909138591656],
            [74.178318222634658, 33.852354002635295],
            [74.143045162317009, 33.841518045817864],
            [74.118591295010532, 33.839427096245785],
            [74.096372378372877, 33.839709063469499],
            [74.067063377457458, 33.838045088046456],
            [74.046100237557056, 33.828327109477655],
            [74.03081818400679, 33.812764094532085],
            [74.00485406538192, 33.772909150649966],
            [73.991218217620059, 33.745409132082941],
            [73.983318273854962, 33.702491105495639],
            [73.986927186096437, 33.679854099526537],
            [74.012700197328371, 33.641591214338021],
            [74.04900926088132, 33.619991049794422],
            [74.085818221141068, 33.603882205312729],
            [74.11775411051903, 33.587773193192831],
            [74.14790029592595, 33.56624511301699],
            [74.165682335924288, 33.550136100897006],
            [74.177063284087723, 33.532082151959045],
            [74.18234522420758, 33.507500041533419],
            [74.178036255411058, 33.485264193451414],
            [74.137772274652747, 33.369709096657104],
            [74.121645157622055, 33.336100179400589],
            [74.087000237130127, 33.302627048976078],
            [74.053036094816065, 33.289027075759762],
            [74.023318224662574, 33.272982101380236],
            [74.011382226869301, 33.235264207537611],
            [74.012627107132431, 33.207209139340861],
            [74.019718197238745, 33.184154044040085],
            [74.036227193779069, 33.166518016795536],
            [74.180945111097145, 33.042909084493473],
            [74.202063316206676, 33.031382123576421],
            [74.232482248743707, 33.024164131455606],
            [74.256100272663247, 33.022764185983348],
            [74.279982326533656, 33.021100042922328],
            [74.307900266794292, 33.01458210735673],
            [74.331791205481949, 33.002354084056051],
            [74.343036199175486, 32.984718056811616],
            [74.363591308028077, 32.872491078501398],
            [74.358736174419079, 32.851244965548958],
            [74.32966337553583, 32.815427081523566],
            [74.340409143074822, 32.794436113704521],
            [74.36338226336224, 32.775053968385592],
            [74.531663386952999, 32.750273206854175],
            [74.607627234539706, 32.761245118141616],
            [74.641927155895957, 32.777073168866181],
            [74.669145207239382, 32.704709181065184],
            [74.654154173368141, 32.584854000291855],
            [74.685809268989004, 32.504027140107198],
            [74.710682231455536, 32.480691083411088],
            [74.738318204492572, 32.466518123292147],
            [74.898327227816168, 32.458882209478006],
            [75.025054060470069, 32.478464179369311],
            [75.057627142129292, 32.475127008429624],
            [75.231654060362615, 32.39846411011608],
            [75.314709165693813, 32.351109039251654],
            [75.31930915416936, 32.344653967960127],
            [75.326536198745401, 32.300444960642253],
            [75.350827121853825, 32.275700073656665],
            [75.375072279770706, 32.263773128318903],
            [75.391300314915981, 32.241582207237954],
            [75.381291316668154, 32.214236080413443],
            [75.306863369023432, 32.133909116935669],
            [75.256527190467267, 32.092873162892758],
            [75.194027193988774, 32.074027124102415],
            [75.040736098440931, 32.041454042443192],
            [74.996354088641027, 32.036291125348711],
            [74.972072385626262, 32.053336060779898],
            [74.935736164706924, 32.061864144370404],
            [74.897436231506191, 32.047309137103767],
            [74.851172316800074, 31.998591168779015],
            [74.744372285534922, 31.942136033249469],
            [74.6717912064411, 31.918164125376862],
            [74.59928221171495, 31.869427046312907],
            [74.488727255454791, 31.721591061004958],
            [74.492827179585589, 31.701100157530774],
            [74.519136129708016, 31.678064172969485],
            [74.541418245895755, 31.649900140030965],
            [74.579991261502784, 31.578209051418767],
            [74.632800269140631, 31.46210913091663],
            [74.626872251922265, 31.419409033812499],
            [74.602672356282795, 31.415973124053224],
            [74.582545227407394, 31.397173185730409],
            [74.562427150987531, 31.378364194952283],
            [74.548372375703735, 31.354436040614345],
            [74.522663402212146, 31.17508208762176],
            [74.538991182005446, 31.11872703201648],
            [74.557282338804129, 31.076045039823541],
            [74.58957227977325, 31.079491175504714],
            [74.605618259981014, 31.105982180564595],
            [74.623745131476852, 31.123926997122979],
            [74.684318128409899, 31.112018156696124],
            [74.706572249041045, 31.09326415120286],
            [74.69759120740153, 31.059091131861109],
            [74.658291313149135, 31.041982158689777],
            [74.554991229383717, 30.995054061974074],
            [74.380263248766795, 30.892773217637696],
            [74.307482345100681, 30.848882219727173],
            [74.242482194536109, 30.764164144801597],
            [74.181091291489025, 30.691664202530788],
            [74.107345127850607, 30.645000135765869],
            [74.048872300583099, 30.599164201037169],
            [73.996091288501731, 30.505000056482331],
            [73.870409176262598, 30.38740916303918],
            [73.860109158335689, 30.361464155153598],
            [73.906791330011316, 30.340345111853665],
            [73.936091278471309, 30.31166425076394],
            [73.94957222866222, 30.286109169015063],
            [73.961927153977314, 30.244164113751296],
            [73.967627183428675, 30.208191164516492],
            [73.933400184630301, 30.136000179197723],
            [73.798872314669239, 30.070553943744741],
            [73.729427240838419, 30.053327121014476],
            [73.674700286110436, 30.040836073591308],
            [73.581100243640918, 30.017635971365323],
            [73.419436129711613, 29.952500033968747],
            [73.397491301308634, 29.942773170582726],
            [73.330272293329273, 29.741936064415412],
            [73.27401815583849, 29.573053958365406],
            [73.265272310402707, 29.553054066780774],
            [73.086382211933284, 29.245827139690832],
            [73.049991173366976, 29.208045040469798],
            [72.988872346897693, 29.141936131749972],
            [72.950263289107028, 29.040000118911379],
            [72.834436115734746, 28.993609134552671],
            [72.728863362736178, 28.953609016107166],
            [72.638318188706563, 28.904718045300385],
            [72.596645210020569, 28.88193603240579],
            [72.554982289618323, 28.859164245433178],
            [72.3897092634476, 28.785000160101319],
            [72.290263346411251, 28.672845098520568],
            [72.253327148860961, 28.551935977238955],
            [72.213882247683244, 28.423609039264349],
            [72.206645144823227, 28.402500054248307],
            [72.137500310764921, 28.325273221487649],
            [72.085263284201147, 28.286944957454111],
            [71.992200186450788, 28.21582702338263],
            [71.919009240080271, 28.119164233484369],
            [71.904163380772417, 28.010827128810789],
            [71.896945221013794, 27.961935990365916],
            [71.693591196953918, 27.89055402634385],
            [71.640827116317126, 27.880826995319651],
            [71.614700221131926, 27.876109157285086],
            [71.568882223676127, 27.871935975320483],
            [71.524154208912165, 27.869718123734103],
            [71.478036139321688, 27.870273173363913],
            [71.437763273745958, 27.870273173363913],
            [71.399427130723467, 27.868609030302778],
            [71.353863272572397, 27.864436015976239],
            [71.253054122799739, 27.850554075536323],
            [71.225818134183413, 27.845827017408183],
            [71.203600223374139, 27.839164242555555],
            [71.155545095954409, 27.821944963537973],
            [71.110536119795398, 27.803327080152584],
            [71.085818222538052, 27.792218040928944],
            [71.056636123637048, 27.777500089463999],
            [70.995254105407213, 27.749435968811653],
            [70.957763361141332, 27.733054041923751],
            [70.92998221354091, 27.723045043675839],
            [70.894436238455143, 27.712218139313919],
            [70.857209188863891, 27.706518109862529],
            [70.829436087890571, 27.706382155392632],
            [70.805818231609038, 27.708044957349202],
            [70.775127222494206, 27.714164249598653],
            [70.742482224105601, 27.728054069027408],
            [70.727200170555449, 27.745827056570434],
            [70.672063340760957, 27.841664229003541],
            [70.662063394968555, 27.870209135623369],
            [70.668872350213263, 27.898744989787886],
            [70.665682197855773, 27.921936039558389],
            [70.649991275067492, 27.942909070104619],
            [70.587354150652715, 28.00319104735847],
            [70.554427185040396, 28.022218135258029],
            [70.519718226808237, 28.037500188808295],
            [70.480672304222736, 28.045554024315763],
            [70.366509273911589, 28.018745009848445],
            [70.221927143425575, 27.90624494913213],
            [70.203536241978696, 27.885582216642305],
            [70.123872287043895, 27.822500180805761],
            [70.106927263898996, 27.78902705038125],
            [70.081372349788381, 27.728054069027408],
            [70.074436157253132, 27.708044957349202],
            [70.063036098350466, 27.673882163929434],
            [70.05386327876576, 27.641109090059857],
            [70.041236109234802, 27.608327131373059],
            [70.03110020897239, 27.590553976192027],
            [69.867482273407489, 27.408609176920876],
            [69.755827108533623, 27.30916409807476],
            [69.728591287555361, 27.297500176859401],
            [69.695809161230301, 27.282154085568592],
            [69.583536250090702, 27.177982116232627],
            [69.51208220170102, 26.999300056600632],
            [69.485818178579592, 26.817773179022723],
            [69.491091233881974, 26.79194501486775],
            [69.498318278458072, 26.773054049076265],
            [69.511309222588039, 26.748745021056934],
            [69.542209276368709, 26.734436106468181],
            [69.571927146522142, 26.724718127899294],
            [69.591663343432316, 26.718609061571911],
            [69.634154060594341, 26.702218082228413],
            [69.695254111600576, 26.678609110764299],
            [69.72276335026109, 26.665273167498881],
            [69.748736186065315, 26.646873213596663],
            [69.774154139877879, 26.613609127837904],
            [69.795536207300103, 26.596936013632714],
            [69.833118146672831, 26.58610910927068],
            [69.900263393903998, 26.58847314124894],
            [69.94609128200571, 26.591664131796747],
            [70.038882303178497, 26.596664104692891],
            [70.071382294641609, 26.596109055063081],
            [70.092482227202169, 26.593327101391495],
            [70.112763247820368, 26.588327128495152],
            [70.140400226685614, 26.57513602451705],
            [70.167972329620198, 26.556245058725565],
            [70.18727233228779, 26.523882195198482],
            [70.186918280696801, 26.391382075259358],
            [70.185527220041791, 26.367773103795329],
            [70.174282226348254, 26.247500168966369],
            [70.168591249352374, 26.224436021210281],
            [70.153872292059106, 26.185273087256078],
            [70.139436140179697, 26.160000140368922],
            [70.119136176460444, 26.133882130001226],
            [70.102763302027853, 26.10694504005302],
            [70.094700246426811, 26.086245091913085],
            [70.088527142358799, 25.983191100825749],
            [70.122891269093572, 25.910691158555025],
            [70.284845229235572, 25.705553968408523],
            [70.317763310030301, 25.692491107549202],
            [70.351382285570622, 25.6827731289806],
            [70.378872245853842, 25.675827045799608],
            [70.511100289215193, 25.681664203187367],
            [70.537209247127379, 25.685273115428586],
            [70.577627119628431, 25.704991039789789],
            [70.612345130316243, 25.714582116343976],
            [70.635682192840562, 25.712773133995725],
            [70.659909245846535, 25.703253974170892],
            [70.675672253002375, 25.680136014596044],
            [70.679009256303885, 25.644854069461061],
            [70.677063313657214, 25.615273159605849],
            [70.672900189976389, 25.57013610796578],
            [70.671918166197571, 25.546664096799972],
            [70.672345140346494, 25.441518150312262],
            [70.677691285844872, 25.403118137187349],
            [70.750409157598853, 25.277909099564695],
            [70.797482261239765, 25.232773221390929],
            [70.821109169976722, 25.214718098986381],
            [70.842482184943464, 25.198054037236787],
            [70.863454209661285, 25.178191105950376],
            [70.882482303389139, 25.149164239896479],
            [70.887772290135928, 25.121664221329539],
            [70.895536111792893, 25.080554003624215],
            [70.901382321636135, 25.05527317774802],
            [70.906372236248473, 25.035273118525325],
            [70.934709271668936, 24.954164123478861],
            [70.942200178557783, 24.935553951444504],
            [70.9663823045627, 24.891664127000453],
            [70.978318134717796, 24.875553941414282],
            [70.994136127158555, 24.85582713223566],
            [71.070827188666811, 24.704991096134691],
            [71.077427266883745, 24.680464138632274],
            [71.001018172599032, 24.644645081140297],
            [70.978945101077073, 24.604027048790911],
            [70.980127117066246, 24.557918031655831],
            [71.003318166836749, 24.46506414620923],
            [71.037691178388826, 24.457218014262864],
            [71.095682213860556, 24.440545067695936],
            [71.104982270735803, 24.419300128209997],
            [71.020682285141646, 24.362218026321173],
            [70.999154204965606, 24.362636115652677],
            [70.975818148269724, 24.372354094221564],
            [70.949291268664098, 24.371800050419964],
            [70.928036103256176, 24.36166415015775],
            [70.864982230614345, 24.304127078563468],
            [70.881227197204026, 24.273191150237054],
            [70.798454059096713, 24.241936039036815],
            [70.761936118515933, 24.235827140347411],
            [70.64511820418565, 24.244091193987416],
            [70.584154107649425, 24.281109031274795],
            [70.579163354846656, 24.326664172246481],
            [70.589909290023826, 24.389373213390726],
            [70.59901823950625, 24.408327043456083],
            [70.58638218515776, 24.426936042024138],
            [70.559982207566549, 24.435827062023307],
            [70.414700188163152, 24.402218144766763],
            [70.329709198469374, 24.369718153303481],
            [70.2628361954539, 24.336035978213133],
            [70.182209159841506, 24.314164240005894],
            [70.14888220980859, 24.312500096944845],
            [70.117118149446327, 24.303127117511721],
            [70.086654122270062, 24.23263598216154],
            [70.064091212325053, 24.193682092873203],
            [70.020263246326664, 24.170691202951033],
            [70.000000162981536, 24.16971806398972],
            [69.723600199476522, 24.171109124644644],
            [69.693391317433651, 24.193627107588256],
            [69.642763281007973, 24.242218006260529],
            [69.598600206519649, 24.281391166136402],
            [69.543591284568066, 24.283882100129077],
            [69.380263362853697, 24.28500007837782],
            [69.252491306871008, 24.279718138258048],
            [69.077282205010334, 24.294854011416518],
            [69.016309223656691, 24.262635987176751],
            [68.977409146186886, 24.263609126138263],
            [68.959163251665501, 24.294718056946508],
            [68.947900153061056, 24.311800040389741],
            [68.929700191369278, 24.324300140268306],
            [68.819154119926537, 24.335273225021936],
            [68.788745078035248, 24.333609081960802],
            [68.748363247718117, 24.317827131703851],
            [68.74470924083758, 24.295273106576431],
            [68.746091249036994, 24.042500051808261],
            [68.74662718792743, 24.000000114552748],
            [68.747209227285651, 23.96999105708214],
            [68.693591198350902, 23.970273191943832],
            [68.631927212897523, 23.96999105708214],
            [68.382482276613558, 23.966245016904523],
            [68.284709219817586, 23.939300047967109],
            [68.217763294244349, 23.872500135147774],
            [68.208463237369159, 23.850827048046412],
            [68.197800333516938, 23.766681535330733],
            [68.197253064000137, 23.761745021500062],
            [68.19538916400009, 23.758582618],
            [68.194632959000131, 23.757544217000074],
            [68.19377231100006, 23.756822156000013],
            [68.191105005000082, 23.753140552000033],
            [68.190808247500144, 23.752514899500014],
            [68.190392987000138, 23.751627137000057],
            [68.188644577000133, 23.748945270000107],
            [68.188247896000036, 23.748309074000147],
            [68.187903436000141, 23.747555881],
            [68.186033008000067, 23.744643537000073],
            [68.184848989000074, 23.742562717500093],
            [68.184520095000067, 23.741950121000158],
            [68.183775941500045, 23.740959422000074],
            [68.182424212000029, 23.738193198],
            [68.180673793000125, 23.735899476],
            [68.180238950000131, 23.735105109],
            [68.179872396000093, 23.734249482000095],
            [68.179051918500164, 23.732723012],
            [68.177753415000012, 23.727950785000033],
            [68.177420002000218, 23.727131312],
            [68.177194044, 23.724267171999983],
            [68.178901280000133, 23.718998340000027],
            [68.180508090000018, 23.710524926],
            [68.180620567000091, 23.709672312000052],
            [68.180737061000201, 23.706848844500072],
            [68.181017248000131, 23.704855898000162],
            [68.181033316500162, 23.703678407000083],
            [68.181084534, 23.701359077000021],
            [68.18135116400012, 23.70017455600005],
            [68.182298178000138, 23.692414163500061],
            [68.182702391000106, 23.686729067000115],
            [68.183804060000142, 23.681320142000132],
            [68.183958716000149, 23.677919729000067],
            [68.182541710000152, 23.676938571000065],
            [68.179108157, 23.674745274000045],
            [68.17542755700012, 23.672255722000116],
            [68.165932311000148, 23.666231187000093],
            [68.158713715, 23.662085617000102],
            [68.155474988000179, 23.660845360000152],
            [68.149911407000019, 23.658131859000079],
            [68.143794480000139, 23.655646324000131],
            [68.131472253000226, 23.649223098999968],
            [68.12152207900013, 23.645115690000026],
            [68.114284402000095, 23.641876962000012],
            [68.110937215000064, 23.640295257999966],
            [68.092197788500101, 23.631919759000041],
            [68.090891251000045, 23.631282056000018],
            [68.081746491000132, 23.620874946000029],
            [68.060860968000185, 23.598731089000054],
            [68.051354675000113, 23.588025715000171],
            [68.008318266000146, 23.541010440999955],
            [68.00183679400007, 23.534160407000016],
            [67.997204159000177, 23.529308844000056],
            [67.909621943000076, 23.439435919000132],
            [67.904551452000106, 23.434196712000059],
            [67.722288934000204, 23.244383190000136],
            [67.692676422, 23.185050710000084],
            [67.646472588000137, 23.094908644000085],
            [67.572841503000092, 22.941813753000062],
            [67.547214885000159, 22.886453108000111],
            [67.40220727200014, 22.582915567999962],
            [67.363521302000066, 22.520934863],
            [67.301673159000217, 22.421378896000121],
            [67.158143812000134, 22.219658909000017],
            [67.067582971000121, 22.152622777000104],
            [66.614311787000162, 21.813808718000061],
            [66.488581882000091, 21.71906615200011],
            [66.169028459000145, 21.47829966700003],
            [65.88605608400016, 21.268275482000021],
            [65.671860217000159, 21.108099578000051],
            [65.606407798000163, 21.059144081000099],
            [65.100197231000124, 21.349636329000091],
            [64.658935950000085, 21.760398338],
            [64.654000029000173, 21.766929018999988],
            [64.651248367000136, 21.769477822000127],
            [64.641356440000123, 21.782563284000034],
            [64.635860144000191, 21.787653861000067],
            [64.625951145000187, 21.800756396000153],
            [64.620470917000176, 21.80583090399999],
            [64.61054584900009, 21.818950510999983],
            [64.609405014000032, 21.82000799300009],
            [64.59357291000012, 21.81869342100002],
            [64.592267376000024, 21.818797863999961],
            [64.58321299900004, 21.818044672000084],
            [64.580264502000063, 21.818278662999987],
            [64.55992730300008, 21.816587495000036],
            [64.556952695000092, 21.816823496000026],
            [64.536640603000166, 21.81513031900009],
            [64.533642897000192, 21.815367324000064],
            [64.513353903000137, 21.813673143000145],
            [64.510333099000121, 21.813912156000114],
            [64.490066198000108, 21.812217975],
            [64.48702330100005, 21.812456988000051],
            [64.46677949700009, 21.810759794999953],
            [64.463713502000161, 21.811002824999989],
            [64.443492797000062, 21.809304627000174],
            [64.440402700000192, 21.809546653000027],
            [64.420206097000147, 21.807848455000126],
            [64.417092901000188, 21.808092488999975],
            [64.396918392000117, 21.806393286999977],
            [64.393782099000219, 21.806638326],
            [64.373631692000089, 21.804937115000016],
            [64.370472301000149, 21.805183158000133],
            [64.350343987000059, 21.803480943000054],
            [64.347160494000178, 21.803728994999986],
            [64.327058291000213, 21.802026780000105],
            [64.32384969100022, 21.80227684],
            [64.303769582000115, 21.800570608000143],
            [64.300538889000137, 21.800822676000124],
            [64.280482882000086, 21.799116445000081],
            [64.277228086000179, 21.799368512999976],
            [64.257194173000158, 21.797662281000115],
            [64.25391728400021, 21.797915354000096],
            [64.233908477000142, 21.796208118000052],
            [64.230606481000137, 21.796462195000018],
            [64.210619768000043, 21.794753954000086],
            [64.207295679000168, 21.795009035000035],
            [64.187332063000014, 21.793298787000126],
            [64.183983872000084, 21.79355788499997],
            [64.164044358000154, 21.791846632000144],
            [64.160673069000126, 21.792103721],
            [64.140756654000057, 21.790392467999979],
            [64.137362267000213, 21.790652571000109],
            [64.117468949000141, 21.788939309000014],
            [64.114051464000084, 21.789200416000128],
            [64.094180240000043, 21.787487154000033],
            [64.090739657000114, 21.787749265000045],
            [64.070893540000185, 21.786032991000056],
            [64.067428855000145, 21.786297110000064],
            [64.047604831000086, 21.784580835999989],
            [64.044116044000162, 21.784844955],
            [64.024316122000158, 21.783128681],
            [64.020805241000204, 21.783394809],
            [64.001028417000128, 21.781676526000027],
            [63.99749343400012, 21.781942654000019],
            [63.977740713000031, 21.78022437099996],
            [63.974182632000151, 21.780492507000119],
            [63.954450999000215, 21.778773220000048],
            [63.95087082500018, 21.779042361000037],
            [63.931163295000175, 21.777320061],
            [63.927558014000141, 21.777591209999954],
            [63.907874586000077, 21.77586891],
            [63.904247211000069, 21.776142068000141],
            [63.88458688100016, 21.774418764],
            [63.883176905000113, 21.774523205999955],
            [63.875582717000128, 21.773856380000026],
            [63.868822062000135, 21.774362525000058],
            [63.861272062000097, 21.773699716000024],
            [63.850231265000076, 21.774524211000127],
            [63.837943183000135, 21.773442626000062],
            [63.826879288000072, 21.774268124999978],
            [63.814614303000013, 21.773186541000015],
            [63.803526307000055, 21.774010031000032],
            [63.791284420000153, 21.772929450999968],
            [63.780173326000153, 21.773753945999985],
            [63.76795453600019, 21.772673366],
            [63.756820344000147, 21.773498865],
            [63.744625657000114, 21.772417281000045],
            [63.733467363000074, 21.773241775000074],
            [63.721295774000083, 21.772163204],
            [63.710115386000126, 21.772986694000011],
            [63.69796589000012, 21.771906114000032],
            [63.686762405000223, 21.772731613000033],
            [63.674637011000158, 21.771652036999981],
            [63.663409423000218, 21.772475527000083],
            [63.651308132000196, 21.77139595200002],
            [63.640056442000144, 21.77222044600002],
            [63.627977244000164, 21.77114187500014],
            [63.616703461000128, 21.771966369000054],
            [63.604648365000031, 21.77088578899999],
            [63.593350479000122, 21.771711288],
            [63.581318482000171, 21.770632717000112],
            [63.569997498000049, 21.771456207000128],
            [63.557989603000095, 21.770378640000033],
            [63.546644517000033, 21.771202130000049],
            [63.534658715000063, 21.770124562999982],
            [63.523291535000141, 21.770949056999982],
            [63.511330840000113, 21.769870486000102],
            [63.370277615000219, 21.790000048333454],
            [63.239166667000205, 21.953611111],
            [63.151349367000165, 22.06841138],
            [63.139722222000188, 22.083611110999968],
            [63.138427870000129, 22.084680228000039],
            [62.688356041000048, 22.456433292999989],
            [62.421388889000156, 22.676944444000029],
            [62.063888889000197, 22.910277778000065],
            [62.040158738000144, 22.925921118000147],
            [62.014166667000126, 22.943055556000147],
            [61.636388889000131, 23.194444443999984],
            [61.608929839000126, 23.213317228],
            [61.54666666700021, 23.256111110999981],
            [61.416666715000162, 23.346666633400034],
            [61.591547681000208, 24.323491971999985],
            [61.591575916000153, 25.046085373],
            [61.611027304723535, 25.197645049699972],
            [61.643609130524709, 25.29833596529825],
            [61.649163314860431, 25.348054062312642],
            [61.651927163621309, 25.390273205811113],
            [61.659709257827245, 25.506382178768931],
            [61.66554507411027, 25.590554088882115],
            [61.687627198087711, 25.797500093738805],
            [61.718045124796362, 25.800691084286697],
            [61.761936122706999, 25.807809164121224],
            [61.779991245111518, 25.843891078097315],
            [61.781936181929694, 25.869991151192195],
            [61.794154146946568, 25.958327023176693],
            [61.816936159841163, 26.108609015476063],
            [61.83277326302121, 26.179436097506411],
            [61.844154211184588, 26.211664180029985],
            [61.854854213532093, 26.230553972355082],
            [61.870963393290054, 26.244018158739564],
            [62.06081819460988, 26.311664140867819],
            [62.081109273511885, 26.314164127316005],
            [62.103882233950884, 26.314445088711224],
            [62.138954128591791, 26.379544983923878],
            [62.182773209772762, 26.371109101268345],
            [62.229163355940983, 26.360136016514602],
            [62.2748911641186, 26.35660002683106],
            [62.288609154531827, 26.379018097488782],
            [62.27256334196224, 26.420409108951233],
            [62.315273329711914, 26.509027115797224],
            [62.437909290690612, 26.566527139379573],
            [62.508609135430476, 26.574435967961833],
            [62.546663311229423, 26.577218089271511],
            [62.600827169700409, 26.580273125349592],
            [62.703882334254303, 26.602218121390663],
            [62.745654051759999, 26.618882183140173],
            [62.770263319551844, 26.648745060219184],
            [62.829163288606338, 26.651109092197359],
            [62.973600244529308, 26.647773094724059],
            [63.015827099378697, 26.64360913285303],
            [63.059573258001905, 26.635553956240798],
            [63.163609272868001, 26.63055398334474],
            [63.185127127122058, 26.639164209586582],
            [63.216100271098668, 26.853882082727424],
            [63.258191171478273, 26.940409140001535],
            [63.253054070645732, 27.051391070410531],
            [63.252636148952121, 27.080554058572218],
            [63.27651820282253, 27.120554009379831],
            [63.328809208842785, 27.127982219632699],
            [63.330273192055557, 27.148882159982875],
            [63.284854173191803, 27.222218113278714],
            [63.201936195797174, 27.267636126314002],
            [63.0391633237127, 27.241382161476494],
            [62.998600276648261, 27.230273122252854],
            [62.935891235503931, 27.214718153934584],
            [62.814854206379579, 27.234991127925397],
            [62.780273323628052, 27.266800115288973],
            [62.800554176608074, 27.384444988188861],
            [62.828882327211119, 27.447218067073564],
            [62.839709231573153, 27.474436118417074],
            [62.823327137047073, 27.739718157880944],
            [62.821936244030297, 27.760273099095471],
            [62.815545210479314, 27.786944985626505],
            [62.802491234437326, 27.835000113046348],
            [62.772491229422116, 27.945273102082794],
            [62.765000154895375, 27.972773120649933],
            [62.759163332783686, 27.995273166320814],
            [62.769154058482599, 28.076109078960783],
            [62.783336238695114, 28.171936025472192],
            [62.788891261221096, 28.212491193547621],
            [62.782218260446399, 28.26374502286599],
            [62.686936137643016, 28.250273125130761],
            [62.632909239470251, 28.240273179338359],
            [62.592491199331135, 28.233609063380968],
            [62.530818161422275, 28.295000134066143],
            [62.489091203279742, 28.355618058000331],
            [62.399854109063284, 28.419436024937724],
            [62.316654164445055, 28.438882207997196],
            [62.208600200461348, 28.459435975745336],
            [61.945963322008851, 28.531800131184426],
            [61.925000182108562, 28.541245027346932],
            [61.905545114231671, 28.555000065772433],
            [61.821936132737306, 28.626109115026296],
            [61.651382339913226, 28.785273074869437],
            [61.637218264611789, 28.806382059885479],
            [61.58277327710735, 28.909436050973014],
            [61.547618233986867, 28.984536059616076],
            [61.511936136793196, 29.071109049719738],
            [61.428045188075117, 29.151382201379064],
            [61.360273309760743, 29.265827031275748],
            [61.353463348687825, 29.291527119949833],
            [61.314436201565485, 29.395826997128708],
            [61.282491259732097, 29.43416414597975],
            [61.04151824828736, 29.683464242976228],
            [60.866300261609382, 29.863654039355666],
            [60.986382256683299, 29.828609134443255],
            [61.063045154996843, 29.807500149427213],
            [61.789436141274024, 29.603327045786799],
            [62.012218243482295, 29.540553966902209],
            [62.103609151544646, 29.514718091396119],
            [62.248327236500785, 29.473609047157467],
            [62.339709259745717, 29.447491204427749],
            [62.362500157457561, 29.440827088470471],
            [62.408045072507349, 29.427218062798758],
            [62.461382307684829, 29.411945061704088],
            [62.484436229519162, 29.406109077782716],
            [62.558600147213014, 29.412773193740108],
            [63.203327256452013, 29.470827093485681],
            [63.230436175415861, 29.473700074626137],
            [63.554709211403093, 29.501664115354217],
            [63.587209202866376, 29.503891187034284],
            [63.703045093417785, 29.484436119157479],
            [64.017073264401148, 29.430409053346651],
            [64.059145221679586, 29.414435995696678],
            [64.162754094758753, 29.457218067813969],
            [64.187200250714312, 29.48208214546311],
            [64.211382209081052, 29.498600194458916],
            [64.268600265439687, 29.521944968334239],
            [64.379700213045766, 29.553609116410499],
            [64.488172266361033, 29.574300179733015],
            [64.54942721493822, 29.580827000115917],
            [64.575000233959912, 29.5830540717959],
            [64.66053621499978, 29.587773083296824],
            [64.695527140455766, 29.586382190280077],
            [64.739972349805697, 29.58277311040068],
            [64.769472290476045, 29.578753987816768],
            [64.873309151322275, 29.569164252366946],
            [65.03414513321556, 29.541109016531934],
            [65.254991183422959, 29.598327072890655],
            [65.596936121200571, 29.68666411834154],
            [65.733872279588724, 29.721382129029294],
            [65.86249124307065, 29.753891172947974],
            [66.06805406626151, 29.805554039142564],
            [66.128036139019031, 29.820553957831024],
            [66.256654096672634, 29.851935971045677],
            [66.30247226176661, 29.893326982508043],
            [66.362063234920839, 29.959782063829991],
            [66.296518260648355, 30.020000170981476],
            [66.26123614787528, 30.040618144108137],
            [66.240672321843334, 30.065345093820909],
            [66.272491199852624, 30.149718001972914],
            [66.290545148790756, 30.186109040539137],
            [66.320891326408031, 30.227982179073635],
            [66.351791212550637, 30.442500060003923],
            [66.33957224170544, 30.494164099664985],
            [66.316445229675281, 30.51020907404434],
            [66.283182317382824, 30.570135993878907],
            [66.400263255835483, 30.943464118337445],
            [66.42096337161351, 30.951936043176701],
            [66.449845230741886, 30.959718137382637],
            [66.475818234184175, 30.961664247667201],
            [66.537491272092979, 30.970554094200068],
            [66.560954063165383, 30.975827149502337],
            [66.579018238025327, 30.986664112148262],
            [66.686782188158219, 31.091591123324505],
            [66.693863387618791, 31.126664191431715],
            [66.721582341497395, 31.207354091318066],
            [66.855818185951051, 31.280554090144093],
            [66.880809165614579, 31.292500146221172],
            [66.965545177813084, 31.320000164788198],
            [67.008045115068768, 31.322218016374663],
            [67.036372259843432, 31.318473149663546],
            [67.049909201147699, 31.302709136679411],
            [67.042136159397245, 31.264445078024309],
            [67.062763352617338, 31.241109021328313],
            [67.234291290231141, 31.218254085875955],
            [67.25648221131209, 31.222327020278314],
            [67.275409219287297, 31.214018039637281],
            [67.384718121817826, 31.215273145822309],
            [67.613036135812962, 31.271109026343524],
            [67.790554116728657, 31.34340914404207],
            [67.796854122811027, 31.38468213830744],
            [67.766518171115621, 31.410827138403349],
            [67.745263340983939, 31.41458206339847],
            [67.716518274515721, 31.412082076950313],
            [67.685809160489981, 31.403045044197412],
            [67.661091263232635, 31.401527081528073],
            [67.61415411406162, 31.429991186601129],
            [67.581372323012801, 31.52927315361093],
            [67.654982196905138, 31.531245080157518],
            [67.681654083436257, 31.523327031481685],
            [67.70832714343365, 31.524436124913009],
            [67.737409162410501, 31.531382208093916],
            [67.823045223374635, 31.580273178900612],
            [67.839709285124258, 31.607636069531353],
            [67.8613723139417, 31.628327132853869],
            [67.886172353850412, 31.639227127411644],
            [67.928318239514823, 31.641664249585759],
            [67.968736112015876, 31.633882155379936],
            [68.043872330480752, 31.686936082229252],
            [68.125327162853097, 31.767636040399609],
            [68.145827118782762, 31.80582700885877],
            [68.163627263692007, 31.82933606803671],
            [68.188000161813619, 31.829173123838487],
            [68.218872220037582, 31.816391056736407],
            [68.27138232900748, 31.773682074814943],
            [68.534572245432997, 31.726800078566967],
            [68.553872248100646, 31.736518057135768],
            [68.564636120550659, 31.760618040489149],
            [68.515827124757237, 31.768744966192756],
            [68.446300243550951, 31.78017318829022],
            [68.485354212763554, 31.812373107618882],
            [68.519154069774828, 31.824509097622482],
            [68.556372234548832, 31.827909132836041],
            [68.57610021719367, 31.823473094387211],
            [68.650545096282741, 31.782773087024466],
            [68.719709208718513, 31.721664151200898],
            [68.729991289372521, 31.701800046448014],
            [68.803736111906488, 31.614582152712259],
            [68.827763340340169, 31.605827087182988],
            [69.019091177328363, 31.65103605555241],
            [69.105754189072314, 31.711709132409638],
            [69.129154115870648, 31.734164251079378],
            [69.157482266473806, 31.769164228990846],
            [69.202909164326428, 31.83110917583933],
            [69.214563362534221, 31.858864171901857],
            [69.291309241689447, 31.924373103990533],
            [69.328245103963496, 31.940364098913321],
            [69.291645188369699, 32.04860011783434],
            [69.273036189801729, 32.139300189435104],
            [69.271100305438864, 32.170691087467063],
            [69.278318129921416, 32.197773184340676],
            [69.283054072866861, 32.217764191107975],
            [69.29068227533017, 32.290553979591422],
            [69.290672384684314, 32.321935992806075],
            [69.27777230038518, 32.355553962517902],
            [69.261382326870176, 32.383882113121146],
            [69.251027156020228, 32.411236118934468],
            [69.24789131839546, 32.441100169479668],
            [69.256518140805753, 32.470409170395257],
            [69.270536203353657, 32.500273220940571],
            [69.282772273281324, 32.520000197757085],
            [69.306927241919851, 32.540273171747984],
            [69.327209268366261, 32.548609142117343],
            [69.357209273381471, 32.558600035454347],
            [69.377763376405625, 32.568327066478545],
            [69.401236225761835, 32.591800083472762],
            [69.421372239454541, 32.616936070061527],
            [69.456100308426102, 32.674227048978125],
            [69.439145226997397, 32.715273228942905],
            [69.417345237881619, 32.740964097523587],
            [69.395409294296059, 32.766873230863425],
            [69.408045181006429, 32.790409112131599],
            [69.465818119356726, 32.836936050960119],
            [69.512700283242879, 32.872218163733194],
            [69.507491265680841, 32.915264098163178],
            [69.500827149723563, 32.945264103178303],
            [69.491782237981766, 32.996727144800715],
            [69.507072338158963, 33.036109181704362],
            [69.559982263911451, 33.079644954557367],
            [69.580827218976793, 33.086936036873922],
            [69.626372301664475, 33.08443605042585],
            [69.874282343834608, 33.089436023321909],
            [69.903736184037399, 33.095127000317873],
            [70.028872299102289, 33.135827007680618],
            [70.150545179403338, 33.205554048735124],
            [70.22790025528306, 33.251100137251299],
            [70.32477225748525, 33.332700144187029],
            [70.313863378109971, 33.383809134218282],
            [70.288036219783265, 33.420409049812164],
            [70.238591205175197, 33.465618018181786],
            [70.202627140757812, 33.477209016839296],
            [70.169700175145607, 33.519853961020189],
            [70.173309255024975, 33.546109099324283],
            [70.185527220041791, 33.573882200297604],
            [70.199291310922803, 33.606936067924224],
            [70.196636091627425, 33.64082712004226],
            [70.13338222677524, 33.733882171165689],
            [70.099436189372085, 33.726791081059574],
            [69.987063365946341, 33.753400103316537],
            [69.91665420560949, 33.852218048165398],
            [69.867200306183889, 33.935753939463879],
            [69.900954062727607, 34.029164215644855],
            [69.929982269885983, 34.041109098255632],
            [69.936918127144935, 34.041664147885442],
            [69.95166323597627, 34.044718010496936],
            [69.999927240423602, 34.051945055073062],
            [70.059145218885618, 34.035273114334345],
            [70.120818256794422, 34.01610906613648],
            [70.1997092326163, 33.992491209855032],
            [70.338318251244829, 33.963882097856683],
            [70.481791288299775, 33.943600071410359],
            [70.558318232143421, 33.944436082435388],
            [70.794145090300191, 33.953044967572865],
            [70.882345175452741, 33.965000076105426],
            [71.081172301232954, 34.056173222322613],
            [71.134709193344662, 34.163736006778763],
            [71.154918129595302, 34.355618055206534],
            [71.103527172340449, 34.381173136955212],
            [71.07749130462409, 34.390682070858261],
            [70.998600161164205, 34.465545039278837],
            [70.978318134717796, 34.507909022064624],
            [70.982272381370848, 34.537909027079834],
            [70.991745105452054, 34.555500127323327],
            [71.087418160220665, 34.561936087875367],
            [71.10359121008085, 34.616800170539634],
            [71.09290026018877, 34.643882099775155],
            [71.091936173682853, 34.668536126930249],
            [71.170254162602106, 34.723873116572875],
            [71.291654128135093, 34.829991196193802],
            [71.402209252033146, 34.915264153111295],
            [71.475818120097216, 34.94582708674524],
            [71.493800152305909, 34.95770005262635],
            [71.498900205126148, 34.966344979947635],
            [71.503054108713542, 34.984164235596197],
            [71.535400208434027, 35.084091106238176],
            [71.573882196572214, 35.120827143940048],
            [71.612763330940851, 35.230818165752979],
            [71.553872246703662, 35.289435999945781],
            [71.548045147599822, 35.317836067278293],
            [71.576718129700538, 35.358609164836977],
            [71.62442725215621, 35.395691207502992],
            [71.648318190843924, 35.428182146510792],
            [71.604709160157, 35.52693605361911],
            [71.568327174046232, 35.586936063649418],
            [71.528045088377013, 35.662764124404319],
            [71.545818243557932, 35.699709039134049],
            [71.442745141731194, 35.866936054578431],
            [71.382754184156369, 35.920826998281186],
            [71.331936213804255, 35.955554061424422],
            [71.222382224424138, 36.023673118169356],
            [71.187791283388862, 36.039445010142501],
            [71.189709230478826, 36.052109060047584],
            [71.246091275812518, 36.131382083017186],
            [71.464191246893876, 36.27206411394495],
            [71.581127178421212, 36.346445122931655],
            [71.590854209445439, 36.388644988052746],
            [71.61986330586447, 36.452709047668108],
            [71.644182224529544, 36.465973241842235],
            [71.709745136074929, 36.436945034683973],
            [71.745009143936926, 36.416118016891645],
            [71.973309220659218, 36.541927031145164],
            [72.145809291405925, 36.64773598617596],
            [72.176082211189254, 36.688127036586863],
            [72.174136100904633, 36.709091182315589],
            [72.226372289277919, 36.72948217350357],
            [72.330554149259825, 36.755718033430284],
            [72.40662712922628, 36.762400086660293],
            [72.470409221617928, 36.761700197743167],
            [72.497482266036144, 36.775027088553273],
            [72.535809188965146, 36.802654009134798],
            [72.552827134667808, 36.817591063549415],
            [72.578600313537919, 36.825427137211889],
            [72.646100282912499, 36.833491198641426],
            [72.745818276526819, 36.842373166185197],
            [72.767900232866083, 36.838773138761198],
            [72.809127126663924, 36.832954086284545],
            [72.865391322438455, 36.834200140013976],
            [72.894427240947749, 36.836553946070339],
            [72.918309294818044, 36.843500196889337],
            [72.93544509007981, 36.854818113140638],
            [73.05469124176679, 36.868773143776409],
            [73.385818159999786, 36.889191124692829],
            [73.484836097058775, 36.891836118066223],
            [73.539972256300956, 36.880453996436287],
            [73.636645104483108, 36.899635981906883],
            [73.643100175774549, 36.902664195894644],
            [73.668309252559339, 36.909636095337376],
            [73.835327223337885, 36.896118097134334],
            [73.927482225695854, 36.863418113460696],
            [73.957627237636331, 36.839809141996582],
            [74.03526327491133, 36.815373211963035],
            [74.06219131240411, 36.821344982716042],
            [74.122618128945277, 36.843582171902611],
            [74.205827126019045, 36.903327036799553],
            [74.416927202101107, 37.000044979620938],
            [74.476645077269751, 37.010473240666826],
            [74.507354191295661, 36.998736061617436],
            [74.530745233276519, 36.968254097168355],
            [74.554836164174532, 36.965054054165066],
            [74.571727207862892, 37.01902714051927],
            [74.565427201780466, 37.027818080594258],
            [74.675672362898382, 37.060091090118888],
            [74.696509271336623, 37.060791146673907],
            [74.817491315175999, 37.021764167189716],
            [74.982618160954843, 36.986927133476669],
            [75.014163286005697, 36.992345028066254],
            [75.147863359206809, 36.997191109219685],
            [75.181782239243745, 36.971245095505807],
            [75.212072258109487, 36.958900060836456],
            [75.239154187344951, 36.952654034210568],
            [75.277209201334387, 36.946253948204074],
            [75.315809206669627, 36.940154101970094],
            [75.376363260501392, 36.924891159159316],
            [75.399018203743395, 36.911554042427227],
            [75.425809280937898, 36.858227033171701],
            [75.424427272738541, 36.833636037928656],
            [75.423036212083531, 36.801553968158899],
            [75.42539119160628, 36.764045118982224],
            [75.435254177100461, 36.743491183595992],
            [75.451791336835669, 36.731691140272645],
            [75.47276336155349, 36.725582073945176],
            [75.524145098714683, 36.732818003338608],
            [75.551791297673532, 36.768109168567079],
            [75.639163250790176, 36.763545054637362],
            [75.66470022762806, 36.758536029285708],
            [75.71249132509692, 36.746600199130526],
            [75.754082328769726, 36.729454010308885],
            [75.789709273040359, 36.709664169218215],
            [75.838600243846969, 36.678573175682786],
            [75.859836130877483, 36.663427076602332],
            [75.904018148467031, 36.622954051178354],
            [76.002063281840918, 36.464436000795601],
            [76.010691277717598, 36.442082135516429],
            [76.041654195772338, 36.237509047455418],
            [75.99096329507276, 36.159582158139429],
            [75.941645182478993, 36.129435972732622],
            [75.918727215114501, 36.109945030310101],
            [75.928872335470288, 36.070818138539622],
            [75.953182201679937, 36.028873083275855],
            [75.968036107614779, 36.014991142836024],
            [75.986509151712852, 36.006100122836855],
            [76.00776331129228, 36.001654026103935],
            [76.029163315987347, 36.002218128189256],
            [76.058727126760033, 36.004854069107139],
            [76.085309159288869, 36.001809091313234],
            [76.109009158221653, 35.991800093065237],
            [76.127209287551437, 35.972354077643942],
            [76.154991273342176, 35.898882169878291],
            [76.153591327869918, 35.868327115233271],
            [76.15637227571321, 35.830964111171951],
            [76.180609219364982, 35.814573131828539],
            [76.229427267613829, 35.813327078099107],
            [76.296936121805885, 35.822491180504372],
            [76.38499120003317, 35.841382146295857],
            [76.440536228513253, 35.859164186294194],
            [76.553036121591589, 35.906654038162159],
            [76.570691259575511, 35.821518041543058],
            [76.572072261946545, 35.788882095609964],
            [76.594145165830497, 35.75721811517171],
            [76.624418253251832, 35.749436020965774],
            [76.693318168098727, 35.723182223766159],
            [76.719145158787313, 35.71013612671338],
            [76.736645231562051, 35.696100126892631],
            [76.756663395695824, 35.669718086574235],
            [76.772763355360127, 35.657773203963572],
            [76.895263361868871, 35.612500197853606],
            [77.109418138752773, 35.556100047609092],
            [77.251391318049201, 35.533609054393807],
            [77.392909192363987, 35.481518040583822],
            [77.42164520637678, 35.472764148520852],
            [77.453045156864135, 35.472764148520852],
            [77.483736165979082, 35.480818151666696],
            [77.50721823542878, 35.484164207423689],
            [77.535536160109956, 35.483882072562082],
            [77.561372370892087, 35.480818151666696],
            [77.618863342018955, 35.473318024684275],
            [77.684700174160781, 35.462353992385957],
            [77.713391261172404, 35.463527123557711],
            [77.752772292247727, 35.487491152441393],
            [77.783527171464954, 35.503745003848408],
            [77.823927274331226, 35.501326992413695],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "PAN", Country: "Panama" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.316666744999878, 4.999999932000151],
            [-82.898845786531382, 8.025672625773069],
            [-82.892781980451815, 8.035973127117387],
            [-82.886808868594244, 8.075273189007689],
            [-82.885981910024725, 8.101809121068897],
            [-82.933346033344662, 8.25750012314073],
            [-82.950008921627955, 8.270273137787413],
            [-82.972090877967247, 8.276391088932215],
            [-83.005008958762033, 8.29110904039716],
            [-83.030290958104615, 8.310554049990145],
            [-83.017790858226135, 8.340273093610136],
            [-82.952517960531452, 8.399444971604368],
            [-82.92229097357756, 8.416391168215753],
            [-82.876463923666279, 8.427500039801302],
            [-82.829926926553867, 8.4746539449891],
            [-82.825563978300778, 8.570000105533012],
            [-82.836745940082267, 8.636664231520626],
            [-82.87958199165621, 8.689445075963761],
            [-82.896663975099329, 8.710000017178302],
            [-82.915699947816222, 8.739654017229441],
            [-82.914463952370596, 8.762782202726086],
            [-82.879454922003731, 8.825836075367818],
            [-82.858481891457586, 8.842782104341126],
            [-82.813335787362121, 8.862500028702229],
            [-82.756326943307243, 8.881318071935951],
            [-82.719863988011554, 8.910827065061696],
            [-82.711881901595291, 8.925073115376577],
            [-82.743617798763097, 8.981036065550271],
            [-82.882235869847023, 9.067218123688647],
            [-82.930463999748696, 9.063127084375381],
            [-82.931117955836385, 9.199444993583668],
            [-82.934717815622207, 9.471664231411211],
            [-82.864455003315157, 9.585554011677914],
            [-82.837717905577307, 9.609718032771838],
            [-82.814182024309133, 9.606664170160343],
            [-82.748554907385198, 9.582982108500374],
            [-82.718899901505637, 9.546664160129922],
            [-82.682499978122109, 9.508744932972576],
            [-82.636263891334522, 9.488054037288137],
            [-82.610008920668633, 9.490418069266312],
            [-82.561399917085453, 9.535553947439894],
            [-82.563572978484018, 9.56287258070833],
            [-82.566666742999871, 9.571110936000068],
            [-81.433332741999948, 10.816666938000012],
            [-81.249999741999829, 10.999999938000201],
            [-79.999999741000011, 10.999999938000201],
            [-79.999999741000011, 11.833332938000126],
            [-78.999999740000021, 11.833332938000126],
            [-78.999999740000021, 12.499999939000176],
            [-77.999999738999861, 12.499999939000176],
            [-77.816666738999942, 12.316665939000245],
            [-77.716666738999891, 11.999999939000276],
            [-77.566666737999981, 11.449999938000218],
            [-77.249999737999929, 10.466665937000116],
            [-77.049999737999855, 9.449999936000083],
            [-77.216666737999901, 9.149999936000071],
            [-77.364166737999824, 8.685276936000093],
            [-77.366672894842878, 8.674999838264071],
            [-77.371946052463471, 8.646109127683047],
            [-77.438608837346692, 8.566664108059726],
            [-77.452235800291334, 8.556944956024552],
            [-77.475773022663873, 8.521109134726231],
            [-77.468581852633491, 8.471700162301858],
            [-77.429099903443756, 8.472500131143065],
            [-77.405772899203157, 8.451318055931182],
            [-77.375008967530476, 8.398609128217331],
            [-77.36861793397938, 8.364164199935772],
            [-77.368055005360532, 8.337218057532155],
            [-77.362499982834578, 8.285000141707769],
            [-77.348890957162865, 8.267773151339156],
            [-77.296108939253145, 8.215826976816572],
            [-77.273608893582264, 8.198609038903641],
            [-77.243899908246078, 8.145273144830597],
            [-77.215426918355632, 8.087918128173484],
            [-77.198335882457201, 7.999444960614795],
            [-77.215564046292116, 7.937218046542597],
            [-77.295208890487601, 7.904864235471095],
            [-77.311399877620829, 7.886945067536558],
            [-77.369999942178907, 7.778473181859354],
            [-77.330081966384654, 7.726173123383532],
            [-77.330982015150028, 7.701809110079424],
            [-77.577435785458391, 7.526182086525338],
            [-77.600146049261497, 7.539236062567142],
            [-77.61029100197922, 7.562500034895422],
            [-77.624172942419136, 7.603335996728248],
            [-77.663890926003063, 7.679445018878454],
            [-77.744035834543496, 7.72000001931579],
            [-77.758899966400236, 7.693054044550166],
            [-77.759445963574649, 7.667218001405999],
            [-77.759172881168354, 7.633336001743359],
            [-77.754590997603714, 7.612218131909913],
            [-77.729381920818895, 7.568891068446518],
            [-77.720981912709192, 7.536453941257463],
            [-77.73222690640273, 7.505900060078915],
            [-77.748617885746143, 7.484309115628832],
            [-77.775699982619841, 7.475418095629664],
            [-77.807499917527821, 7.478682176373482],
            [-77.889727314911312, 7.228890500743731],
            [-77.889166738999847, 7.210832934000152],
            [-78.299999738999873, 6.733332934000117],
            [-78.783333739999989, 6.466665933000201],
            [-79.04999974, 6.266665933000141],
            [-79.233333739999921, 5.999999933000225],
            [-79.866666740999875, 4.999999932000151],
            [-84.316666744999878, 4.999999932000151],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "PCN", Country: "Pitcairn Is." },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-133.424721623999915, -26.568055672999961],
            [-132.992221623999853, -25.677777672],
            [-132.68638862399996, -24.068888670000021],
            [-132.389721622999957, -22.381943668999952],
            [-132.143610622999972, -21.051388667999944],
            [-131.978610623, -20.764999666999984],
            [-131.888497623000035, -20.754827666999986],
            [-131.285269621999987, -20.619530666999978],
            [-130.666309621999972, -20.581879666999924],
            [-130.665512621999937, -20.581859666999947],
            [-130.048723621, -20.642920666999942],
            [-129.451423621, -20.801012666999966],
            [-128.922937620000027, -21.037317668000014],
            [-128.90467862, -21.032892668],
            [-128.285039618999917, -20.985613667999914],
            [-127.665759619000028, -21.037085668],
            [-127.064381618000027, -21.185850667999972],
            [-127.063480117999958, -21.186222667999928],
            [-126.497042617999924, -21.428070668000046],
            [-126.21706461799991, -21.606797667999984],
            [-126.096805616999987, -21.553090667999982],
            [-126.094163116999923, -21.55234666799997],
            [-126.090947116999928, -21.55145016799996],
            [-125.490533616999983, -21.393347667999947],
            [-124.870539615999974, -21.332231668000034],
            [-124.860804615999982, -21.332839667999977],
            [-124.854944615999983, -21.332249668000017],
            [-124.232901615999893, -21.36975266799999],
            [-123.626397614999874, -21.504836667999967],
            [-123.052646114999888, -21.733633667999911],
            [-123.050686614999947, -21.734410667999981],
            [-122.526137614000021, -22.050360668999957],
            [-122.066077614000037, -22.444414668999983],
            [-121.684200112999946, -22.90529766899995],
            [-121.683221612999986, -22.906498669000015],
            [-121.391426613000021, -23.42069167],
            [-121.39088461299994, -23.422256669999982],
            [-121.390365612999986, -23.423166669999958],
            [-121.198149612999956, -23.975709670000015],
            [-121.112452612999945, -24.55027567099998],
            [-121.112519612999989, -24.551839670999968],
            [-121.112379612999945, -24.552772670999985],
            [-121.137195612999946, -25.132174670999959],
            [-121.273325612999955, -25.698847671999928],
            [-121.518149613, -26.235359671999973],
            [-121.518969112999955, -26.236638171999985],
            [-121.866497613, -26.726575672999971],
            [-121.869298612999941, -26.729382672999961],
            [-122.309721613999983, -27.156792672999956],
            [-122.829747613999942, -27.507789674],
            [-122.832316613999964, -27.508955673999949],
            [-122.836828613999984, -27.511984674],
            [-123.420540614999965, -27.775361674000024],
            [-124.049227616, -27.94246567399999],
            [-124.050229615999854, -27.942568673999986],
            [-124.053346615999828, -27.943389673999974],
            [-124.70648261599986, -28.008645674000036],
            [-124.707766615999873, -28.00857267399995],
            [-124.708539615999882, -28.008647674000031],
            [-125.364542616999898, -27.969861674000029],
            [-126.001703617000032, -27.828291673999942],
            [-126.599105117999898, -27.588590673999931],
            [-126.600105618, -27.588183673999922],
            [-126.601075617999967, -27.587593673999947],
            [-126.605202618000021, -27.585925673999967],
            [-126.824675617999958, -27.451853673999963],
            [-127.022588618, -27.53513267400001],
            [-127.654729618999909, -27.687262673999953],
            [-127.792897618999973, -27.698014673999978],
            [-128.241943619000011, -27.975093673999936],
            [-128.244658618999949, -27.976690674],
            [-128.842641619999966, -28.219135674000043],
            [-128.844006619999931, -28.219447674],
            [-128.853887619999909, -28.223424674],
            [-129.49243562099997, -28.367855674000012],
            [-130.150725620999935, -28.409535675],
            [-130.151553620999977, -28.409553674999984],
            [-130.807368621999984, -28.347158674000028],
            [-131.439227622, -28.182776674000024],
            [-131.4400456219999, -28.182414674000015],
            [-131.445363621999945, -28.181018673999972],
            [-132.03257062299997, -27.920092673999989],
            [-132.034329622999849, -27.91892767399996],
            [-132.035618622999834, -27.918350674],
            [-132.036935623, -27.917478673999966],
            [-132.038653622999959, -27.91671067399993],
            [-132.042391622999986, -27.914303674],
            [-132.043732622999983, -27.913485673999986],
            [-132.049818622999965, -27.910762674000026],
            [-132.573479622999969, -27.561861673999928],
            [-133.017409623999981, -27.136420672999961],
            [-133.018339124, -27.135160172999932],
            [-133.36938562399996, -26.646807673],
            [-133.424721623999915, -26.568055672999961],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "PER", Country: "Peru (Peruvian point of view)" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.13701784701982, -0.045345970577273],
            [-75.021391001134134, -0.123054930409978],
            [-74.960554980078598, -0.170555008199926],
            [-74.938063986863142, -0.198890870153889],
            [-74.860281936834554, -0.232499955048411],
            [-74.841126773454221, -0.225281962927852],
            [-74.812646072212829, -0.205690940581178],
            [-74.791945956434859, -0.200281930808842],
            [-74.772372871361085, -0.207917844622955],
            [-74.749172936772879, -0.235554991126378],
            [-74.733608915999127, -0.260972944938885],
            [-74.72111786857613, -0.28971784376904],
            [-74.716946027715892, -0.311664013276584],
            [-74.715835760818265, -0.333608841679563],
            [-74.708763949089359, -0.361663909876427],
            [-74.632917951061529, -0.406526873281905],
            [-74.607508882066384, -0.383608905917583],
            [-74.556946056847721, -0.417781925259249],
            [-74.393890882263406, -0.543335794379573],
            [-74.376108842265069, -0.56805503274137],
            [-74.370281910799292, -0.577554914188909],
            [-74.35806394578259, -0.633055015667878],
            [-74.338899897584838, -0.706390968963717],
            [-74.316117884690243, -0.781663980088865],
            [-74.290282009184352, -0.809717874819341],
            [-74.247790956745973, -0.853608872729865],
            [-74.264172883633989, -0.898890931295171],
            [-74.282790934657442, -0.930281996965221],
            [-74.255281863634821, -0.986664042298912],
            [-74.240699866639829, -1.012917839498414],
            [-74.215426919752588, -1.034864009005901],
            [-74.077790872447281, -1.072781895058768],
            [-73.868055034930023, -1.208608960015738],
            [-73.840981990511864, -1.265135844636518],
            [-73.7522359081849, -1.289717955062144],
            [-73.713055036958053, -1.279163965468229],
            [-73.653617787908388, -1.28444607322615],
            [-73.5556998918251, -1.375281931658549],
            [-73.547499875925553, -1.436663949888498],
            [-73.542781870253009, -1.468055015558548],
            [-73.520008909813953, -1.493608923840839],
            [-73.476963981212407, -1.552499840439907],
            [-73.447154915952069, -1.610346036624321],
            [-73.476963981212407, -1.636664039202287],
            [-73.48695504218739, -1.654446079200625],
            [-73.507508809935473, -1.748335800244604],
            [-73.490573006884119, -1.766945972278847],
            [-73.416399869096949, -1.816663901655261],
            [-73.296946013848697, -1.873546011333644],
            [-73.238890940636395, -1.845554980877239],
            [-73.197017802102039, -1.806455078835086],
            [-73.132781913471035, -1.84916406075655],
            [-73.090426815502667, -1.923608939845622],
            [-73.058208958901162, -2.145555031028948],
            [-73.077790928792467, -2.200281985756817],
            [-73.101255060969265, -2.231945966195099],
            [-73.131746077873743, -2.246255048421915],
            [-73.115290893151837, -2.328754936485041],
            [-73.104735897729597, -2.34694601335957],
            [-73.073899881689442, -2.371390995848742],
            [-73.003063914841618, -2.410554935631239],
            [-72.975008846644755, -2.401663915632071],
            [-72.934581921688391, -2.399999940209],
            [-72.902508904374031, -2.453608916688324],
            [-72.881954968987714, -2.506390934598016],
            [-72.814735793370289, -2.489999955254518],
            [-72.683335882044901, -2.439999891016612],
            [-72.590146049918133, -2.407217932329615],
            [-72.450835801267999, -2.441390951671451],
            [-72.32778191859569, -2.483055045540084],
            [-72.299454941459032, -2.491391015909471],
            [-72.227508875351532, -2.498890975253687],
            [-72.070008890931433, -2.407781866776929],
            [-71.982226895110387, -2.369446059030452],
            [-71.931399872302848, -2.379008972390125],
            [-71.889799816174616, -2.377782029399896],
            [-71.871399862272426, -2.320282005817546],
            [-71.808481944100663, -2.230835866935365],
            [-71.721955054464559, -2.159164056700661],
            [-71.693199929712421, -2.147917889540736],
            [-71.683208868737381, -2.166526888108592],
            [-71.684172955243412, -2.196108971430192],
            [-71.674317848738326, -2.224999883014164],
            [-71.576672867423156, -2.236663971867642],
            [-71.520145982802433, -2.234581907113068],
            [-71.412226799822236, -2.281255026333383],
            [-71.361681911876246, -2.34694601335957],
            [-71.188064030518831, -2.360555039031283],
            [-71.101117878084779, -2.311946035448216],
            [-70.976390967534201, -2.278335777087193],
            [-70.85896402411754, -2.225346055616342],
            [-70.657645964344681, -2.358191007053108],
            [-70.649726909840354, -2.401255046393942],
            [-70.58237295321959, -2.483890888926965],
            [-70.564454958751355, -2.493335785089641],
            [-70.377746053339933, -2.511673042355881],
            [-70.355808936287815, -2.498172981425682],
            [-70.335645932866953, -2.492399861778438],
            [-70.29539083692606, -2.501281996960188],
            [-70.218826845070112, -2.570346029471693],
            [-70.208863947290013, -2.609426820774445],
            [-70.19459090724672, -2.644017929447784],
            [-70.120681967048426, -2.701663965783666],
            [-70.098481993511882, -2.698335847299575],
            [-70.067508849535443, -2.755555077124768],
            [-70.412226856167138, -3.294717815268314],
            [-70.447235886534031, -3.349445943462683],
            [-70.565846019406195, -3.533608929596539],
            [-70.627499946575767, -3.629163967167983],
            [-70.672226787873342, -3.697781914791165],
            [-70.724163909940501, -3.779717868407261],
            [-70.607726869291639, -3.829481898251004],
            [-70.527990829989221, -3.859235810588416],
            [-70.500835810557817, -3.859308900784242],
            [-70.479446031784562, -3.849999959091576],
            [-70.423899829838092, -3.816108906973454],
            [-70.406535879171258, -3.802499881301742],
            [-70.362226791929118, -3.787781929836797],
            [-70.341673024180977, -3.789999949061155],
            [-70.323064025613235, -3.799164051466619],
            [-70.234454903584606, -3.871254956861321],
            [-70.18188193034058, -3.937426897493197],
            [-70.162655017868929, -3.974863997578552],
            [-70.152508891684761, -4.005835800450654],
            [-70.144308875785271, -4.031808971531007],
            [-70.136126797158681, -4.050835891792417],
            [-70.123072988754757, -4.072363971968343],
            [-70.040846015459749, -4.140554945442716],
            [-70.021117865176734, -4.153890888708247],
            [-69.988617873713451, -4.175973012685574],
            [-69.956681984335432, -4.209445975472192],
            [-69.956926903546957, -4.236872903843505],
            [-69.950290950784506, -4.262499902321792],
            [-69.960563978982918, -4.283190965644224],
            [-69.979590899244499, -4.312499966559642],
            [-70.016963961589511, -4.337946083566919],
            [-70.197826824795925, -4.33265492335363],
            [-70.255846023462254, -4.2913909815436],
            [-70.301390938511872, -4.249999970081149],
            [-70.308763995841673, -4.23028187808211],
            [-70.298335902433962, -4.199026934519907],
            [-70.299726795450738, -4.174164030337295],
            [-70.320145949833545, -4.139717928589278],
            [-70.429381929806311, -4.1320178093967],
            [-70.465290841300543, -4.154726899733248],
            [-70.499999967170879, -4.17971787939689],
            [-70.584163998295139, -4.19555498257688],
            [-70.62569984904485, -4.160555004665582],
            [-70.762435847584527, -4.147708899823044],
            [-70.810290982794015, -4.170554950457984],
            [-70.832226926379519, -4.187017846530864],
            [-70.868899932169597, -4.251108895874381],
            [-70.872554944878345, -4.280117824655463],
            [-70.956264006297147, -4.382217955158751],
            [-71.036655007515378, -4.381935820296974],
            [-71.065145934678583, -4.362917952491159],
            [-71.169445979495492, -4.357782025125005],
            [-71.285935826134562, -4.381035939169749],
            [-71.38223584726245, -4.430835843559237],
            [-71.650564037986953, -4.508890975994177],
            [-71.726681944954578, -4.493282028219085],
            [-71.747190953339668, -4.49035506762209],
            [-71.88197296260563, -4.510863908369174],
            [-71.902481970990607, -4.518190865231304],
            [-72.028064003305758, -4.612499849073288],
            [-72.129181942392222, -4.693608844119638],
            [-72.234999949878556, -4.753335939381941],
            [-72.253617833263718, -4.76166403076229],
            [-72.27917291501268, -4.763608967580524],
            [-72.300981956583769, -4.75699095209086],
            [-72.325290984602987, -4.760835898726157],
            [-72.375290881202943, -4.784446043656772],
            [-72.389590910974448, -4.805690983142711],
            [-72.390563882297783, -4.838608896299434],
            [-72.403335891116058, -4.874517807793666],
            [-72.47696403755748, -4.941108843585255],
            [-72.617508940548873, -5.020281954268967],
            [-72.756673008807297, -5.076945966826145],
            [-72.786663961367026, -5.088891017074843],
            [-72.851964016428099, -5.124717953555717],
            [-72.898617857270949, -5.172781965792865],
            [-72.918335781632152, -5.32166401262009],
            [-72.961399820973043, -5.452499989498165],
            [-72.96417288982741, -5.477226939210937],
            [-72.952563953896799, -5.510281980303944],
            [-72.94279099004325, -5.537499864009305],
            [-72.961955038240887, -5.648608864071036],
            [-72.988890954722706, -5.711663910179254],
            [-73.048064006183381, -5.791945946656028],
            [-73.082226799603404, -5.827217833507177],
            [-73.130281927023077, -5.876390939175394],
            [-73.205563990603594, -6.017499944252165],
            [-73.218063922844067, -6.045281930042904],
            [-73.229735890686584, -6.093608966402371],
            [-73.225572934643878, -6.12139095219311],
            [-73.218608914190042, -6.146255029842166],
            [-73.16639988318309, -6.240281878822543],
            [-73.118617838169598, -6.381526838369155],
            [-73.120008898824381, -6.418055004871633],
            [-73.123899945927462, -6.447217825395342],
            [-73.145008930943504, -6.489164054125695],
            [-73.160846034123438, -6.51555497926131],
            [-73.186117807544179, -6.547363966624971],
            [-73.216673029827291, -6.566663969292676],
            [-73.276399957451417, -6.574999939661836],
            [-73.298763881014224, -6.574446063498499],
            [-73.335008906826886, -6.574446063498499],
            [-73.361190954934955, -6.581990949843942],
            [-73.587508879188704, -6.734999910530178],
            [-73.676390915985564, -6.804445990189379],
            [-73.744173020221808, -6.87694610009828],
            [-73.754454933237866, -6.894164038011297],
            [-73.79972693351931, -7.080835895410587],
            [-73.795135829861294, -7.122626891293578],
            [-73.775563918253738, -7.137217940744279],
            [-73.719999946672317, -7.223054999746921],
            [-73.70583587137088, -7.309235884419081],
            [-73.738199908364265, -7.333954955142758],
            [-73.931117792389216, -7.359164031927435],
            [-73.93052686821369, -7.442990942905169],
            [-73.96243576786344, -7.527364018695266],
            [-73.987155006225237, -7.528835880896793],
            [-74.002154924913839, -7.530454929318864],
            [-74.004590873621453, -7.554373025372911],
            [-73.97416389445732, -7.574446007153597],
            [-73.932508853044055, -7.609164017841351],
            [-73.865563933299313, -7.675281979016461],
            [-73.851954907627601, -7.69958195458031],
            [-73.842226870774937, -7.717363994578648],
            [-73.821535807452506, -7.731664024349982],
            [-73.751954946789681, -7.756946023692649],
            [-73.72416390854363, -7.764445983036978],
            [-73.706664003406956, -7.776390865647556],
            [-73.693755034290461, -7.8033358345848],
            [-73.69819995755671, -7.846181944442606],
            [-73.725390851533859, -7.879581984671347],
            [-73.777017843182705, -7.872881994168324],
            [-73.771955006012547, -7.948054925369092],
            [-73.75632677986016, -7.967082013268737],
            [-73.709872931227608, -7.983890914305675],
            [-73.651673018728104, -8.016663988175139],
            [-73.595699842632712, -8.121391007141142],
            [-73.556673030786442, -8.24917295376963],
            [-73.541399862053595, -8.302781930248955],
            [-73.533199846154218, -8.352363905155187],
            [-73.478617898351672, -8.398608876760079],
            [-73.404726895425938, -8.450555051282834],
            [-73.345699856718909, -8.602364058707167],
            [-73.273335868917911, -8.674235861152283],
            [-73.240981890208388, -8.679582006650492],
            [-73.215563936395881, -8.686108827033479],
            [-73.183899955957713, -8.700135942036638],
            [-73.166672965589271, -8.714164062868349],
            [-73.152508890287663, -8.734999965478266],
            [-73.141954900693918, -8.76222690163911],
            [-73.132781913471035, -8.790555052242155],
            [-73.12194595665369, -8.807499907748991],
            [-73.058817820349532, -8.902426805494827],
            [-73.005763893500045, -8.930346086859856],
            [-72.962363907478959, -8.988472909163306],
            [-72.95112679277446, -9.045835804809258],
            [-72.948064045345632, -9.066526868131859],
            [-72.949172971138864, -9.090135839595803],
            [-72.955290922283666, -9.119164046754065],
            [-72.966399961507364, -9.143054985441893],
            [-73.023617850227822, -9.221108944410275],
            [-73.119445970205618, -9.313335863497684],
            [-73.158063912813702, -9.344445967772515],
            [-73.182926816996371, -9.364999903158662],
            [-73.20069997217729, -9.400763975365749],
            [-72.980835778110645, -9.410835837887475],
            [-72.861954909764904, -9.412226898542485],
            [-72.840835866465113, -9.411663969923666],
            [-72.73000883362721, -9.413055030578391],
            [-72.606946066137652, -9.446108898205011],
            [-72.402235850140244, -9.483608862564381],
            [-72.371672916506327, -9.492635837033617],
            [-72.301526780291795, -9.533191005108961],
            [-72.252508907470599, -9.656945950164697],
            [-72.257646008303141, -9.692572894435443],
            [-72.269308923690176, -9.730554985866775],
            [-72.26035503941705, -9.759163930227146],
            [-72.226526851572885, -9.777781981250484],
            [-72.180281879967879, -9.803399927273233],
            [-72.164735796466857, -9.834717902747343],
            [-72.150772886842219, -9.88701796122325],
            [-72.168335823890914, -9.954446013868335],
            [-72.185835896665481, -10.00249996782162],
            [-72.143890841401799, -10.004717819408086],
            [-72.052226850933209, -10.004717819408086],
            [-72.01028196330742, -10.004446078106298],
            [-71.9791728648608, -10.004446078106298],
            [-71.93028189405436, -10.004446078106298],
            [-71.641954985211441, -10.004446078106298],
            [-71.45916394899325, -10.004163943244578],
            [-71.368055008154556, -10.003890860838453],
            [-71.296390909270656, -9.995417930170731],
            [-71.180417890782905, -9.932226929592787],
            [-71.158899868890728, -9.889999907105278],
            [-71.138545925714936, -9.863817858997152],
            [-71.013964022089567, -9.820835794669506],
            [-70.985354910091132, -9.796735811315841],
            [-70.969317814700958, -9.763890988355172],
            [-70.881954914039795, -9.669999926206685],
            [-70.843608880371335, -9.64555494371757],
            [-70.77917299953026, -9.605554992909958],
            [-70.643335876289484, -9.490835907140649],
            [-70.58861780637892, -9.440835842902743],
            [-70.568064038630837, -9.433473011494698],
            [-70.514663939179115, -9.427999963981904],
            [-70.545290910553661, -9.500835852932994],
            [-70.577226799931736, -9.538608899698716],
            [-70.607590914821628, -9.575663952636319],
            [-70.590835825603449, -9.631599913081516],
            [-70.595290974791624, -9.781390893491618],
            [-70.612646040641039, -9.798755011796629],
            [-70.625563894574952, -9.825972895502048],
            [-70.628890839592543, -9.895835891026479],
            [-70.628617924824425, -9.948491006921756],
            [-70.62945494167792, -10.068891011402854],
            [-70.629181859271682, -10.186390877377505],
            [-70.62945494167792, -10.338608921677917],
            [-70.630281900247553, -10.608608966814529],
            [-70.63084600233276, -10.781663919552898],
            [-70.631954928125936, -10.913054946060925],
            [-70.631954928125936, -10.975282027771186],
            [-70.631390993678679, -11.00916402743394],
            [-70.581681949119911, -10.971108845806555],
            [-70.52639994476209, -10.93569094620176],
            [-70.499308963071087, -10.95347298620004],
            [-70.47111794040427, -10.981108959236963],
            [-70.456945986113794, -11.001664068089497],
            [-70.436673012122753, -11.026946067432277],
            [-70.402508877598336, -11.04555506600019],
            [-70.352781895766668, -11.066108833748274],
            [-70.328899841896487, -11.069172922281666],
            [-70.226526796624796, -11.052781942938168],
            [-70.174999884900103, -11.037499889387902],
            [-70.154172867107775, -11.029999930043687],
            [-70.051681972277095, -10.979172907236148],
            [-69.999999827705324, -10.946945998179103],
            [-69.969454998982087, -10.928608908550984],
            [-69.950008815922786, -10.919999855775416],
            [-69.925981922765232, -10.914026911555936],
            [-69.780208891472569, -10.924717861447959],
            [-69.737917831244658, -10.961599911903534],
            [-69.688608938744437, -10.958608913565911],
            [-69.666117945528867, -10.953335858263642],
            [-69.586117876275807, -10.950281995652063],
            [-69.56750887770778, -10.950555078058528],
            [-69.568435916201679, -10.951091016948851],
            [-69.500563957963095, -11.062499921506856],
            [-69.432508938958563, -11.17360892156853],
            [-69.248899828988215, -11.472499878288829],
            [-69.214735862101918, -11.528054965052888],
            [-69.180554963771158, -11.583608878350503],
            [-69.069455016165023, -11.764163958071379],
            [-69.039463895967231, -11.812782014109871],
            [-69.00083589507534, -11.875282010588251],
            [-68.987226869403628, -11.896108860742686],
            [-68.957226864388417, -11.944726916781178],
            [-68.925281922554916, -11.998335893260332],
            [-68.880281998851274, -12.086946021117399],
            [-68.835554989915693, -12.176946036163031],
            [-68.749454906790504, -12.35028195029679],
            [-68.73639087246471, -12.37805505127011],
            [-68.698626878154641, -12.454172958237677],
            [-68.677508840683117, -12.495835878639753],
            [-68.673908813259231, -12.501155034409805],
            [-68.743617917040979, -12.584726800254032],
            [-68.768346040220138, -12.644164049303697],
            [-68.815563983148365, -12.733054970917749],
            [-68.879445987826216, -12.757499953407091],
            [-68.922226886477119, -12.802782011972454],
            [-68.973345934792178, -12.865490885478607],
            [-68.973345934792178, -13.001946090261185],
            [-68.963345988999777, -13.026673039973957],
            [-68.968481916365761, -13.096663943341298],
            [-68.968335903612115, -13.166108849534112],
            [-68.960554982872509, -13.272500011561135],
            [-68.959454941896752, -13.452782008875829],
            [-68.961255039427783, -13.506808907048594],
            [-68.979172866257954, -13.566108860523684],
            [-69.023090853896747, -13.642046053658248],
            [-69.062790900207801, -13.707781967685776],
            [-69.014173011807316, -13.791108981956597],
            [-69.001681964384261, -13.839446076599984],
            [-68.97514603232321, -13.966255051905136],
            [-68.96097290456612, -13.987081902059572],
            [-68.937372985557317, -14.005417818221133],
            [-68.89860886255741, -14.031808910995039],
            [-68.85789091792185, -14.200135799777229],
            [-68.912781990314556, -14.216946041918561],
            [-68.95889989226697, -14.223890951633052],
            [-68.986399910833995, -14.231663993383506],
            [-69.002372968483996, -14.248755029282222],
            [-68.999799891839871, -14.336046013213831],
            [-68.984235871066176, -14.378126855309517],
            [-69.115572918117437, -14.484163965745381],
            [-69.235417872969037, -14.588955022451785],
            [-69.244735867117129, -14.616391003278409],
            [-69.248608809309189, -14.65416405004413],
            [-69.248899828988215, -14.687499884894351],
            [-69.310563982079714, -14.766946077984073],
            [-69.343481895236437, -14.779863931917987],
            [-69.364799924918316, -14.800626911970028],
            [-69.381390896471885, -14.964791012347348],
            [-69.295008846123267, -15.079446060376256],
            [-69.1977819541396, -15.158608945137985],
            [-69.170835811735913, -15.187781991583563],
            [-69.137090940009557, -15.227635929637358],
            [-69.143135800958589, -15.254445949933086],
            [-69.169872898696553, -15.263335796465697],
            [-69.195981856608682, -15.261663942053701],
            [-69.26833578612576, -15.326946059841987],
            [-69.332508810482636, -15.438890903290485],
            [-69.420981978041482, -15.621526874299377],
            [-69.213817876063558, -16.157217828138073],
            [-69.159726772512272, -16.211873033774623],
            [-69.140008848151183, -16.222226863520007],
            [-69.070699896428152, -16.227573009018329],
            [-69.044726892985807, -16.207781994461186],
            [-68.968199949142218, -16.209099964920256],
            [-68.932563952416103, -16.250555014123165],
            [-68.8928999482888, -16.283617934205267],
            [-68.838481950512801, -16.29839992341067],
            [-68.824245958481697, -16.32631786367125],
            [-68.833346023146703, -16.354026926904112],
            [-68.990846007566802, -16.419726966385838],
            [-69.03418195584743, -16.473054981469829],
            [-69.041399947968159, -16.54764604095061],
            [-69.03201791607961, -16.608608964020561],
            [-69.014326903550057, -16.62936406508345],
            [-69.012390851549185, -16.656981933209465],
            [-69.050708889660768, -16.683755073131238],
            [-69.107645984624185, -16.711599923195877],
            [-69.191955022673824, -16.778055004517626],
            [-69.320699882341842, -16.926254932062648],
            [-69.386945918998038, -17.052917894614097],
            [-69.406399813408314, -17.072226949737171],
            [-69.465381925114229, -17.097046100385171],
            [-69.531535760835084, -17.129863933617628],
            [-69.592790877050504, -17.184717957998089],
            [-69.618899834962633, -17.214726847830718],
            [-69.6561909222944, -17.287246068478908],
            [-69.590835881948351, -17.295281966713731],
            [-69.533617825589715, -17.347782017399652],
            [-69.501108949309014, -17.378890948208152],
            [-69.500563957963095, -17.426946075627939],
            [-69.499726773471593, -17.505282001819921],
            [-69.630008874186274, -17.617499927674658],
            [-69.683899817889198, -17.663608944809653],
            [-69.7341729645334, -17.659999864930228],
            [-69.77028186823793, -17.656946002318648],
            [-69.814726909949798, -17.652226823179717],
            [-69.834872981926367, -17.68139098480799],
            [-69.846117807981955, -17.708335953745177],
            [-69.849726887861323, -17.749235953318191],
            [-69.838063972474174, -17.767499952750484],
            [-69.82167299313079, -17.782499871438972],
            [-69.8124998382699, -17.801108870006942],
            [-69.769590864138195, -17.952573045933548],
            [-69.77444599774708, -17.985281914424547],
            [-69.786390880357885, -18.015281919439758],
            [-69.796672961011836, -18.039717849473391],
            [-69.844590960495168, -18.132364031358804],
            [-69.867782010265785, -18.157781985171255],
            [-69.958481914228344, -18.249445975639958],
            [-69.9908459512219, -18.270146091417871],
            [-70.132508832461838, -18.32125508144901],
            [-70.178073025889034, -18.32889099526318],
            [-70.200835760406108, -18.330281888280012],
            [-70.249173022687387, -18.327781901831941],
            [-70.327790916103254, -18.319446099100674],
            [-70.367090977993541, -18.322781928935797],
            [-70.390017830175452, -18.335282028814277],
            [-70.405490173012879, -18.348545724588064],
            [-70.402785496999854, -18.351028077000024],
            [-73.155290673999843, -18.352222221999966],
            [-73.850041073999932, -18.352423161999923],
            [-73.880814125999905, -18.352526179599977],
            [-73.841633277999904, -18.380010659999982],
            [-73.835837826499869, -18.38406234699994],
            [-73.837378511666543, -18.384123812666644],
            [-73.838003215999947, -18.391878167000016],
            [-73.844358903999989, -18.470762660999981],
            [-73.839646903999949, -18.516920660999972],
            [-73.868941903999826, -18.878914661999943],
            [-73.809276903999887, -19.458969661999959],
            [-73.802263904000029, -19.482796661999942],
            [-73.80023290399987, -19.502423662000012],
            [-73.789733903999888, -19.538067661999946],
            [-73.761343855999939, -19.810947014999954],
            [-73.744065409999962, -19.977023917999929],
            [-73.740737632999924, -19.988257208],
            [-73.731260568999858, -20.02024609099999],
            [-73.71358856899991, -20.18892809099998],
            [-73.715322439199952, -20.210491349799952],
            [-73.807843469999852, -19.909520558999816],
            [-73.888283982999951, -19.81425922499983],
            [-73.918132916999809, -19.791620682999849],
            [-73.930195952999952, -19.782320523999886],
            [-73.978318823999984, -19.750763279999887],
            [-73.992000864999852, -19.741572221999888],
            [-74.068409569999972, -19.711022115999924],
            [-74.267096130999903, -19.620998405999842],
            [-74.459469457999887, -19.518172719999882],
            [-74.492276933999847, -19.498981097999831],
            [-74.520271804999879, -19.487282297999911],
            [-74.709311094999975, -19.401272344999768],
            [-74.817938233999939, -19.345204130999889],
            [-74.927515328999846, -19.307236783999826],
            [-75.111815533999845, -19.237208047999957],
            [-75.310502095999766, -19.147184337999875],
            [-75.502875422999978, -19.044358651999914],
            [-75.688111740999886, -18.929171306999763],
            [-75.792999012, -18.856141061999836],
            [-75.810638340999844, -18.847862763999842],
            [-75.868772052999873, -18.819890463999911],
            [-76.003081786999871, -18.749818066999865],
            [-76.027795828999956, -18.736200994999876],
            [-76.085859420999839, -18.703447705999906],
            [-76.271095739999851, -18.588260360999868],
            [-76.342653101999929, -18.539087314999961],
            [-76.525700195999832, -18.455588307999903],
            [-76.659417880999911, -18.384114715999885],
            [-76.710872570999925, -18.384115664999911],
            [-76.724608570999862, -18.374882664999859],
            [-76.770891476999907, -18.35411188899991],
            [-76.798005571999965, -18.341943665000031],
            [-76.79970957199987, -18.340863164999973],
            [-76.801698071999908, -18.339668164999978],
            [-76.803731571999833, -18.338375664999887],
            [-76.807061571999839, -18.336879664999913],
            [-76.808184571999959, -18.336123664999889],
            [-76.810111571999869, -18.335258664999913],
            [-76.812699571999929, -18.333516664999934],
            [-76.81920157199994, -18.330596664999973],
            [-76.853241571999945, -18.307671664999873],
            [-76.883896571999799, -18.293870164999873],
            [-76.884802571999955, -18.293437664999942],
            [-76.913274571999864, -18.274248664999959],
            [-76.922112571999889, -18.270274664999988],
            [-77.419261571999982, -17.934159664999981],
            [-77.434019571999784, -17.919871665000016],
            [-77.435283571999946, -17.919014664999906],
            [-77.861807572999908, -17.504932663999909],
            [-77.974862572999825, -17.347345663999931],
            [-77.987918572999945, -17.334616664],
            [-78.098091572999977, -17.259327664000011],
            [-78.147639572999935, -17.210946663999948],
            [-78.161199572999976, -17.20166866400001],
            [-78.584919572999922, -16.786711663999938],
            [-78.585807072999899, -16.785512663999953],
            [-78.81987957299998, -16.456047662999936],
            [-78.870909572999835, -16.405784662999963],
            [-78.876811072999942, -16.397485662999941],
            [-78.926802573999851, -16.326956663000033],
            [-78.931560074, -16.322221662999937],
            [-78.93443657399979, -16.31933966299988],
            [-79.273724073999915, -15.839125662999891],
            [-79.274576573999866, -15.837814662999961],
            [-79.28836557399984, -15.808205662999967],
            [-79.290243573999931, -15.805542662999983],
            [-79.381356573999881, -15.609725663],
            [-79.463310573999934, -15.493264662],
            [-79.463782573999879, -15.492249661999949],
            [-79.464270573999954, -15.491554662],
            [-79.709785573999966, -14.961922661999949],
            [-79.804918574, -14.594943662],
            [-79.964196573999857, -14.483478661999982],
            [-80.380458574999807, -14.066734661],
            [-80.381215074999886, -14.065739660999839],
            [-80.387680574999877, -14.056539660999988],
            [-80.393385574999968, -14.050816660999942],
            [-80.72731957499991, -13.569950660999837],
            [-80.969212574999972, -13.039880659999881],
            [-81.112284575999979, -12.47690566],
            [-81.122359575999951, -12.332872659999964],
            [-81.209425575999887, -11.989893658999961],
            [-81.242145575999984, -11.523738658999889],
            [-81.386756575999982, -11.20504565799996],
            [-81.416366575999973, -11.08819865800001],
            [-81.435147575999963, -11.046765657999913],
            [-81.45434557599998, -10.970980657999917],
            [-81.456333575999849, -10.966594657999906],
            [-81.458216575999813, -10.959158657999808],
            [-81.463903575999893, -10.946608657999775],
            [-81.565344575999973, -10.798867657999892],
            [-81.59313757599989, -10.737471658],
            [-81.642375575999836, -10.665699657999824],
            [-81.742325575999985, -10.444696657999884],
            [-81.918926575999933, -10.186835657999893],
            [-81.984937575999766, -10.040580656999794],
            [-82.437207576999867, -9.822301656999855],
            [-82.439816576999988, -9.820426656999985],
            [-82.441218576999802, -9.819748656999934],
            [-82.917396576999977, -9.476900656999931],
            [-83.325369577999879, -9.057407655999839],
            [-83.326185577999922, -9.056484655999782],
            [-83.653362577999872, -8.573907655999847],
            [-83.654542577999962, -8.571263655999928],
            [-83.657314577999841, -8.56717065599986],
            [-83.658441577999895, -8.564645655999755],
            [-83.661267577999979, -8.560473656],
            [-83.898227577999933, -8.029408655999873],
            [-83.95419957799993, -7.804121655000017],
            [-84.047664577999797, -7.665926654999851],
            [-84.049485077999805, -7.661968654999896],
            [-84.049973577999822, -7.660997654999846],
            [-84.050615577999906, -7.660049654999966],
            [-84.060193577999769, -7.638565655000036],
            [-84.069533577999891, -7.624646654999879],
            [-84.070391577999885, -7.623271654999826],
            [-84.073696577999954, -7.615858655000011],
            [-84.074463077999894, -7.614623654999889],
            [-84.075363577999951, -7.613163154999938],
            [-84.078291577999948, -7.608804654999972],
            [-84.078834577999942, -7.607585655000037],
            [-84.079286577999937, -7.606918654999902],
            [-84.316062578999947, -7.075699654999823],
            [-84.316315578999934, -7.074681654999893],
            [-84.31900157899986, -7.068653654999764],
            [-84.319637578999789, -7.066094654999858],
            [-84.319982578999941, -7.065320654999766],
            [-84.320199579000018, -7.064448654999921],
            [-84.321015078999949, -7.062484654999935],
            [-84.321928578999859, -7.060300655],
            [-84.323788078999939, -7.052988654999922],
            [-84.46402057899985, -6.489376653999841],
            [-84.503711578999855, -5.910919653999883],
            [-84.503402578999896, -5.908054653999898],
            [-84.503662578999865, -5.904291654000019],
            [-84.503115579, -5.899201653999967],
            [-84.504452078999918, -5.878957154000034],
            [-84.504478578999908, -5.877658653999902],
            [-84.501233578999972, -5.847464653999793],
            [-84.503060578999794, -5.821078653000029],
            [-84.50294557899997, -5.82000665299978],
            [-84.503042578999839, -5.8186026529998],
            [-84.499644578999948, -5.786938652999936],
            [-84.517958578999867, -5.713638652999975],
            [-84.524628578999909, -5.617650653],
            [-84.631355579, -5.191075652999814],
            [-84.672201578999932, -4.612193151999918],
            [-84.672216578999951, -4.611515651999923],
            [-84.671872578999967, -4.608239651999952],
            [-84.672169578999956, -4.604058651999935],
            [-84.671940578999909, -4.601870652],
            [-84.672138579000034, -4.599077652],
            [-84.61204257899999, -4.022738651999987],
            [-84.605261578999773, -3.999070651999943],
            [-84.588085578999824, -3.832798651999894],
            [-84.584449578999966, -3.820083652],
            [-84.584002578999957, -3.815748651999854],
            [-84.545109579000041, -3.679606650999915],
            [-84.543232578999834, -3.661324650999916],
            [-84.538198578999982, -3.643684651],
            [-84.53711457899999, -3.63311765100002],
            [-84.536304578999932, -3.630277650999858],
            [-84.536083578999921, -3.62812365100001],
            [-84.469150578999916, -3.392766650999874],
            [-80.342643004355523, -3.392766663678799],
            [-80.340427075914533, -3.380518032595887],
            [-80.259172907943935, -3.417426866442781],
            [-80.234999834394557, -3.497217891030061],
            [-80.212781923585283, -3.595835843668567],
            [-80.206117807628004, -3.636663926512369],
            [-80.207781950689082, -3.717217871928653],
            [-80.211399915385755, -3.739164041436311],
            [-80.176673019880809, -3.859164061496926],
            [-80.153335789718199, -3.884226790251887],
            [-80.175290844043161, -3.90305505940745],
            [-80.292235828025923, -3.996108937064491],
            [-80.318964040946639, -4.011663905382875],
            [-80.345699965217932, -3.998263924376943],
            [-80.389172873796952, -3.979026953621315],
            [-80.420290857060706, -3.97805498812636],
            [-80.46542690287248, -3.985282032702571],
            [-80.501681986968777, -4.050282015628966],
            [-80.501217797169545, -4.055346026265568],
            [-80.483608927291414, -4.11444598753053],
            [-80.480763941707608, -4.174164030337238],
            [-80.464582007030117, -4.207499865187458],
            [-80.445563971585898, -4.221181981055111],
            [-80.4105639936746, -4.20986406480381],
            [-80.382926847171177, -4.196663908370112],
            [-80.340281902990171, -4.19951794640923],
            [-80.382926847171177, -4.263473041283021],
            [-80.39917298722753, -4.281808957444809],
            [-80.431117929060861, -4.315555002637552],
            [-80.479999847412103, -4.388473034239951],
            [-80.481254953597045, -4.416455012240931],
            [-80.463763933277733, -4.44180892831298],
            [-80.409726809183212, -4.471663926402698],
            [-80.390563934451791, -4.478608836117303],
            [-80.358617819151846, -4.465555027713378],
            [-80.334173004300794, -4.452499878205117],
            [-80.262217885737982, -4.397499841070953],
            [-80.226390949256995, -4.364445973444219],
            [-80.186535837736699, -4.321255032088914],
            [-80.174581902670752, -4.298545941752252],
            [-80.134599889135927, -4.284490998830222],
            [-80.07792682412321, -4.300135821150946],
            [-80.058763949392016, -4.319582004210361],
            [-80.025145979680076, -4.354999903815155],
            [-79.99779096803826, -4.373055026219788],
            [-79.815635782996878, -4.478817880783026],
            [-79.783763931359431, -4.481946007056877],
            [-79.705281992413575, -4.457081929407707],
            [-79.668063995277748, -4.434308968968821],
            [-79.643199917628579, -4.435417894761883],
            [-79.621390876057319, -4.44749990530886],
            [-79.539172955217737, -4.520835858604755],
            [-79.519726939796442, -4.581181873599064],
            [-79.51528201653025, -4.620417897749121],
            [-79.451672926620347, -4.766390921252139],
            [-79.445035800391565, -4.797399939774323],
            [-79.365573011133336, -4.886108974089268],
            [-79.285708896349945, -4.964582028217535],
            [-79.231917864933337, -4.963854981934105],
            [-79.203608825069409, -4.962499963462903],
            [-79.183626870757507, -4.96389085647985],
            [-79.100273034396537, -4.975055048626416],
            [-79.063208929003281, -5.00030888477437],
            [-79.036663944486747, -4.995555004556081],
            [-79.011399882417095, -4.972217942031705],
            [-78.928617859492192, -4.868335818908236],
            [-78.918481959229837, -4.78263588784182],
            [-78.918399984216762, -4.741046057635515],
            [-78.906108929003949, -4.716663939420414],
            [-78.876108923988738, -4.677217864776253],
            [-78.859308907769162, -4.661526941987972],
            [-78.823763938511888, -4.635973033705568],
            [-78.790846025355052, -4.614163992134422],
            [-78.767226827968955, -4.603890963935783],
            [-78.723482010450425, -4.588890877609231],
            [-78.709026915469849, -4.584790953478375],
            [-78.683617846474533, -4.57444600855041],
            [-78.666808945437595, -4.554864038659105],
            [-78.65028201162437, -4.473608863221045],
            [-78.6528460358129, -4.417635854763546],
            [-78.670145948739275, -4.365135804077568],
            [-78.672499922433644, -4.340281952350381],
            [-78.672072948284722, -4.307090956787363],
            [-78.659472936119982, -4.300672933507883],
            [-78.64153583091246, -4.278472959971623],
            [-78.563608941596442, -4.095555021739017],
            [-78.558063977354379, -4.073054976068136],
            [-78.555554938450825, -4.046946018155779],
            [-78.55542686296991, -4.010135884429758],
            [-78.553617880621459, -3.978608864289697],
            [-78.540846039441249, -3.931945970991364],
            [-78.531681937036069, -3.91278192279367],
            [-78.466672901653908, -3.832499886316839],
            [-78.420990858667835, -3.78585493029135],
            [-78.414173018605993, -3.776526877859453],
            [-78.399455067141048, -3.746108951150688],
            [-78.38862682167445, -3.711390940462877],
            [-78.372508924737247, -3.633890857658002],
            [-78.367090862509485, -3.572845959574806],
            [-78.37069994238891, -3.539717828285859],
            [-78.367917821079232, -3.518335928501699],
            [-78.352235783108426, -3.456664064059282],
            [-78.346117831963454, -3.434999861775339],
            [-78.329454943680162, -3.417217821777001],
            [-78.301399875483412, -3.40778197806992],
            [-78.279445994624894, -3.409999997294392],
            [-78.261399924675857, -3.424717948759451],
            [-78.252926826370071, -3.451808930450397],
            [-78.255846075616205, -3.516946041313247],
            [-78.246946003161611, -3.519863949454816],
            [-78.181399855422569, -3.472217858911222],
            [-78.17056389860511, -3.451946058386682],
            [-78.181190978394966, -3.413126950101855],
            [-78.203472926944528, -3.392426834323828],
            [-78.215708829234188, -3.36055498268621],
            [-78.20042677568415, -3.334308896837797],
            [-78.175846006363145, -3.314163998327672],
            [-78.141399904615071, -3.294163939104862],
            [-78.116390987678642, -3.27597302986851],
            [-78.06722693446585, -3.215282015738467],
            [-78.03445503406266, -3.156181886835554],
            [-77.925381998288231, -3.039963949136222],
            [-77.859590931337777, -2.985835797572804],
            [-77.810245996653805, -2.96815501096512],
            [-77.79444594148589, -2.963335919539929],
            [-77.439726815595293, -2.841390962661023],
            [-77.224164046612145, -2.767217824873683],
            [-77.043335884485032, -2.704717828395246],
            [-76.90444607209929, -2.656664042080081],
            [-76.66062690160436, -2.572135901080856],
            [-76.403899935459378, -2.380282015847911],
            [-76.074172962698754, -2.131108820865734],
            [-75.977508831696014, -2.016390908562755],
            [-75.781399957122972, -1.78194605860557],
            [-75.719726919214281, -1.708055055679836],
            [-75.627281903005553, -1.596372901077643],
            [-75.58818200096357, -1.555708935898622],
            [-75.563946063140179, -1.539954981198321],
            [-75.553617882018443, -1.513890950287248],
            [-75.483608873740309, -1.241945968332459],
            [-75.461672930154919, -1.153608922881517],
            [-75.444173025018131, -1.085555077343599],
            [-75.431946007545832, -1.038054999553594],
            [-75.402235848743203, -0.922781869982998],
            [-75.376955022867151, -0.950835932351538],
            [-75.359590904562424, -0.967781961324761],
            [-75.330426910572157, -0.979717959118034],
            [-75.216845919619175, -0.96936396173453],
            [-75.263335810435706, -0.814717847715599],
            [-75.292781939287408, -0.730554990057726],
            [-75.283426897127185, -0.656217902243668],
            [-75.277235855786444, -0.650282006036377],
            [-75.266964001054134, -0.646035901513926],
            [-75.249026895846725, -0.629581890258464],
            [-75.244381980370008, -0.560972995090594],
            [-75.262508851865903, -0.523054941399607],
            [-75.339799890005281, -0.464655036689919],
            [-75.363890988541357, -0.466664011248611],
            [-75.389454955107453, -0.446946086887408],
            [-75.413346061433288, -0.41958202279028],
            [-75.434445993993904, -0.384163955547308],
            [-75.44861794828438, -0.346317818585817],
            [-75.459163891251194, -0.294999951526904],
            [-75.464735845221696, -0.273608831649256],
            [-75.481117939747662, -0.232364000578571],
            [-75.571190877351057, -0.180135858832216],
            [-75.624499949333824, -0.172026870401623],
            [-75.615817806362429, -0.106517938312834],
            [-75.519026773345217, -0.116872941524718],
            [-75.479872891846526, -0.143755046187891],
            [-75.425008809182373, -0.164308981574209],
            [-75.34972691323992, -0.151255005532221],
            [-75.305281871527882, -0.134717845797013],
            [-75.285845914390393, -0.119717927108468],
            [-75.259146032302738, -0.122481943507239],
            [-75.225908936271935, -0.095973001174457],
            [-75.216672917137146, -0.068335854671091],
            [-75.205008828283781, -0.036873039909494],
            [-75.13701784701982, -0.045345970577273],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "PHL", Country: "Philippines" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [125.537209864000118, 21.109201391000099],
            [125.537715617000316, 21.103354372000197],
            [125.537994617000294, 21.101542372000196],
            [125.538638617000146, 21.094097372000235],
            [125.538916617000069, 21.092299372000141],
            [125.53955861700021, 21.084866372000121],
            [125.539838617000129, 21.083055372000203],
            [125.540481617000211, 21.075624372000149],
            [125.540760617000018, 21.073812372000148],
            [125.5414036170001, 21.0663793720001],
            [125.541682617000134, 21.064569372000093],
            [125.542325617000216, 21.057134372000078],
            [125.542605617000248, 21.055325371999956],
            [125.543246617, 21.04790837200018],
            [125.543528617000248, 21.0460823720001],
            [125.54417161700016, 21.038648372000168],
            [125.544450617000138, 21.036838371999963],
            [125.545092617000165, 21.029420372000104],
            [125.545373617000308, 21.027595372000107],
            [125.546016617000049, 21.020161372000146],
            [125.546295617000027, 21.01835137199997],
            [125.546937617000225, 21.010932372000198],
            [125.547219617000138, 21.009108372000085],
            [125.547861617000279, 21.001688370999986],
            [125.548142617000309, 20.999864371000044],
            [125.548509617000292, 20.995617371000023],
            [125.550419617000131, 20.986952871000042],
            [125.552396617000198, 20.977879871000042],
            [125.554372617000155, 20.968811871000085],
            [125.556350617000163, 20.959736871000175],
            [125.558328117000229, 20.950662871000148],
            [125.560306117000124, 20.941587871000152],
            [125.562284117000189, 20.932513371000098],
            [125.564262117000084, 20.92343887100013],
            [125.566239617000264, 20.914363871000134],
            [125.568217617000158, 20.905291371000075],
            [125.570194617000226, 20.896223371000033],
            [125.572174617000229, 20.887141371000084],
            [125.574151117000071, 20.878073371000013],
            [125.576130117000247, 20.8689993710001],
            [125.578109117000082, 20.859924371000105],
            [125.580088117000258, 20.850849871000136],
            [125.582066617000152, 20.841775871000138],
            [125.584045617000328, 20.832700871000142],
            [125.586024117000164, 20.823626371000088],
            [125.588001617000174, 20.814558371000047],
            [125.589982617000118, 20.805478371000063],
            [125.591960617000183, 20.796410371000107],
            [125.593941617000127, 20.787328871000099],
            [125.595919117000136, 20.77826137100017],
            [125.597899117000196, 20.769186371000089],
            [125.599879117000199, 20.760111871000035],
            [125.601859117000203, 20.751037871000037],
            [125.603839117000149, 20.741962371000156],
            [125.605817617000156, 20.732895371000026],
            [125.607799617000211, 20.723812871000106],
            [125.609779617000157, 20.714738871000108],
            [125.611758117000221, 20.705671371],
            [125.613739117000165, 20.696596871000125],
            [125.615720617000164, 20.687522371000185],
            [125.617702617000219, 20.678440371000036],
            [125.619681117000113, 20.669373371000077],
            [125.621661117000286, 20.660305371000121],
            [125.623644117000111, 20.651223871000113],
            [125.625625117000226, 20.642148871000032],
            [125.627605617000171, 20.633074371000063],
            [125.629585617000231, 20.624007371000104],
            [125.631567617000172, 20.61493237100008],
            [125.633550617000111, 20.60585037100013],
            [125.635530117000286, 20.596782871000116],
            [125.637512617000169, 20.58770837100009],
            [125.639449617000224, 20.578927371000063],
            [125.671361617000258, 20.371503371],
            [125.689399617000021, 20.162575371000187],
            [125.689534617000191, 20.15571837100002],
            [125.689744617000173, 20.153291370999952],
            [125.689893617000081, 20.145778371000048],
            [125.690046617000093, 20.144002371000184],
            [125.690194617000117, 20.1365043710001],
            [125.690349617000123, 20.134714370999959],
            [125.690498617000202, 20.12719937100006],
            [125.6906516170001, 20.12542537100019],
            [125.690800617000122, 20.117923371000145],
            [125.690954617000074, 20.116136371000081],
            [125.691103617000152, 20.108633371000096],
            [125.691257617000105, 20.106847371000114],
            [125.691406617000183, 20.099342371000162],
            [125.691560617000135, 20.097558371000176],
            [125.691709617000043, 20.090052371000141],
            [125.691863617000166, 20.088269371000067],
            [125.692011617000134, 20.08077737100011],
            [125.692167617000081, 20.0789803710001],
            [125.692315617000105, 20.071472371000098],
            [125.692469617000057, 20.069691371000161],
            [125.692618617000136, 20.062181371000165],
            [125.692772617000088, 20.060402371000222],
            [125.69292161700011, 20.052906371000134],
            [125.693076617000173, 20.051113371000085],
            [125.693225617000081, 20.043601371000094],
            [125.693379617000204, 20.041824371000146],
            [125.693528617000112, 20.034324371000068],
            [125.693683617000119, 20.032534371000125],
            [125.693832617000197, 20.025035371000129],
            [125.693987617000033, 20.023245371000186],
            [125.694136617000112, 20.015744371000025],
            [125.694291617000346, 20.013956371000049],
            [125.694441617000138, 20.006454371000174],
            [125.69459561700026, 20.00466737100011],
            [125.694745617000052, 19.997164371000153],
            [125.694899617, 19.995378371000172],
            [125.695049617000365, 19.987871371000224],
            [125.695203617000317, 19.986088371000122],
            [125.695353617000109, 19.978597371000106],
            [125.695508617000286, 19.976799371000013],
            [125.695658617000305, 19.969291370999983],
            [125.695812617000257, 19.967510371000245],
            [125.69596261700022, 19.960000371000049],
            [125.696116617000172, 19.958220371000024],
            [125.696266617000305, 19.950725371000019],
            [125.696421617000198, 19.948930371],
            [125.69657161700016, 19.941434371000085],
            [125.696726617000166, 19.939640370999982],
            [125.696876617000186, 19.932143370999981],
            [125.697031617000192, 19.930351371000214],
            [125.697181617000155, 19.922837369999954],
            [125.697335617000107, 19.921061370000089],
            [125.697485617000126, 19.913577370000112],
            [125.697641617000073, 19.911772370000151],
            [125.697791617000036, 19.904270370000077],
            [125.697946617000042, 19.902482370000129],
            [125.698096617000061, 19.894979369999973],
            [125.698251617000238, 19.893192370000079],
            [125.698402617000141, 19.885689369999952],
            [125.698556617000094, 19.88390337],
            [125.698706617000227, 19.876412370000097],
            [125.698862617000174, 19.87461337000012],
            [125.699013617000247, 19.867106370000172],
            [125.699167617000199, 19.865323370000098],
            [125.699318617000273, 19.857831369999971],
            [125.699473617000109, 19.856034369999961],
            [125.699624617000183, 19.848525370000218],
            [125.699779617000189, 19.84674437000011],
            [125.699929617000151, 19.839248370000192],
            [125.700085617000099, 19.837454370000088],
            [125.700236617000172, 19.829957370000088],
            [125.700391617, 19.828164370000067],
            [125.700542617000082, 19.820667370000066],
            [125.700697617000316, 19.8188753700001],
            [125.700848617, 19.811376370000133],
            [125.701003617000225, 19.809585370000079],
            [125.701154617000299, 19.802084370000117],
            [125.701309617000305, 19.800295370000057],
            [125.701460617000208, 19.792808370000174],
            [125.701616617000155, 19.791005370000207],
            [125.701767617000172, 19.783503370000133],
            [125.701922617000065, 19.781715370000185],
            [125.702073617000138, 19.774211370000117],
            [125.702228617000145, 19.772425370000136],
            [125.702379617000048, 19.764935370000202],
            [125.702535617000336, 19.763135370000114],
            [125.702686617000012, 19.755643370000186],
            [125.7028426170003, 19.753845370000093],
            [125.702993617000203, 19.746352370000054],
            [125.70314961700015, 19.744554370000159],
            [125.703301617000278, 19.737046369999959],
            [125.70345561700023, 19.735264370000138],
            [125.703607617000188, 19.727769370000104],
            [125.703762617000081, 19.72597337],
            [125.703913617000097, 19.718493370000189],
            [125.704070617000156, 19.716683369999984],
            [125.704222617000113, 19.709186369999983],
            [125.704377617000347, 19.707393369999963],
            [125.704529617000304, 19.69989537000005],
            [125.704684617000311, 19.698103370000112],
            [125.704836617000268, 19.690603370000204],
            [125.704991617000161, 19.688813370000091],
            [125.705143617000118, 19.681325370000124],
            [125.70530061700012, 19.679522369999958],
            [125.705452617000077, 19.672020370000084],
            [125.70560761700014, 19.670232370000107],
            [125.705759617000098, 19.662743370000058],
            [125.705915617000215, 19.660942370000086],
            [125.706067617000173, 19.653451370000042],
            [125.70622361700012, 19.651651370000152],
            [125.706375617000248, 19.644160370000108],
            [125.706531617000195, 19.642361370000131],
            [125.706683617000152, 19.634868370000092],
            [125.706839617000099, 19.633070370000198],
            [125.706991617000057, 19.625577370000158],
            [125.707147617000174, 19.623780370000176],
            [125.707299617000302, 19.616284370000059],
            [125.70745561700025, 19.614489370000044],
            [125.707607617000207, 19.606994370000038],
            [125.707763617000154, 19.605199370000022],
            [125.707916617000052, 19.597701370000109],
            [125.708071617000058, 19.59590737],
            [125.708223617000016, 19.588425370000195],
            [125.708380617000245, 19.586617369999985],
            [125.708532617000202, 19.579133370000179],
            [125.708690617000144, 19.577326370000051],
            [125.708843617000156, 19.569826369999973],
            [125.708998617000049, 19.5680363700002],
            [125.70915161700006, 19.560549370000146],
            [125.709307617, 19.558745370000096],
            [125.709460617000246, 19.551256370000218],
            [125.709616617000194, 19.549454369999964],
            [125.709769617000262, 19.541964370000201],
            [125.709925617000152, 19.540163370000229],
            [125.71007861700005, 19.532673370000097],
            [125.710234617000168, 19.53087337000018],
            [125.710387617, 19.523380370000169],
            [125.710543617000354, 19.521582370000075],
            [125.710696617000195, 19.514088370000152],
            [125.710852617000143, 19.512291370000142],
            [125.711005617000211, 19.504796370000136],
            [125.711162617000042, 19.503000370000038],
            [125.711315617000054, 19.49551937000011],
            [125.711472617000112, 19.493709370000104],
            [125.711625617000351, 19.486211370000021],
            [125.711781617000241, 19.484418370000171],
            [125.711934617000139, 19.476934370000166],
            [125.712091617000141, 19.475127370000237],
            [125.712244617000039, 19.467642370000149],
            [125.71240161700004, 19.465835370000221],
            [125.712554617000109, 19.458350370000133],
            [125.712711617000281, 19.456544370000117],
            [125.712864617000179, 19.449058370000117],
            [125.71302161700018, 19.447253369999984],
            [125.713175617000132, 19.4397663700001],
            [125.713332617000361, 19.437962370000051],
            [125.713486617000314, 19.43047337],
            [125.713642617000261, 19.428671370000117],
            [125.713796617000213, 19.421181369999985],
            [125.713952617000103, 19.419380370000184],
            [125.714106617000056, 19.411888370000085],
            [125.714262617, 19.41008937000008],
            [125.714416617000353, 19.402612370000142],
            [125.714573617000184, 19.400798370000146],
            [125.714636617000252, 19.397731370000045],
            [125.714730617000299, 19.397073370000058],
            [125.716130117000063, 19.387885870000062],
            [125.717529617000167, 19.378704870000163],
            [125.718930117000269, 19.369516370000028],
            [125.720331617000198, 19.360321370000108],
            [125.721730617000134, 19.351140870000094],
            [125.723131617000234, 19.341952370000101],
            [125.724532617000335, 19.332763870000136],
            [125.725933117000096, 19.323576370000055],
            [125.727333617000085, 19.314394370000073],
            [125.72873561700024, 19.305198870000154],
            [125.730135617000172, 19.296018870000083],
            [125.731538117000213, 19.286822870000108],
            [125.732938117000145, 19.277641870000124],
            [125.734340117000187, 19.268453370000046],
            [125.735742617000227, 19.259257370000071],
            [125.737142617000217, 19.25007737],
            [125.738544617000144, 19.240889370000062],
            [125.739945617000188, 19.231707370000166],
            [125.741349117000226, 19.222511870000048],
            [125.742751117000324, 19.213323370000168],
            [125.744152117000198, 19.204141870000157],
            [125.745555617000065, 19.194946869999981],
            [125.746956617000109, 19.185765870000168],
            [125.748359617000204, 19.17657637000012],
            [125.749762117000245, 19.167388370000182],
            [125.75116511700017, 19.158200870000186],
            [125.75256761700021, 19.149011870000081],
            [125.753970617000078, 19.139823370000116],
            [125.755373617000174, 19.130634370000095],
            [125.756776617000213, 19.121446370000072],
            [125.758179117000253, 19.112264870000118],
            [125.759583117000119, 19.10306937],
            [125.760985617000216, 19.093887370000047],
            [125.762388617000198, 19.084699370000109],
            [125.76379311700012, 19.075510370000089],
            [125.765196617000157, 19.06632187000001],
            [125.766601117000192, 19.05713337000013],
            [125.768004617000173, 19.047944870000052],
            [125.769408617000096, 19.038755870000031],
            [125.770812117000133, 19.02956787],
            [125.772215617000171, 19.020386370000082],
            [125.773621117000204, 19.011190370000108],
            [125.775024117000186, 19.002009370000039],
            [125.776429117000276, 18.992820370000103],
            [125.77783411700014, 18.98363187000011],
            [125.779239117000117, 18.97444287000009],
            [125.780644117000151, 18.965253870000069],
            [125.782048617000186, 18.956065870000046],
            [125.783454117000218, 18.946876870000025],
            [125.784857617000142, 18.937694870000044],
            [125.786264117000059, 18.928498370000042],
            [125.787669117000263, 18.919310370000105],
            [125.789073117000129, 18.910128370000038],
            [125.790479117000217, 18.90093987000013],
            [125.791885117000191, 18.891750370000082],
            [125.793291117000166, 18.882562370000059],
            [125.79469711700014, 18.873373370000124],
            [125.796103117000172, 18.864184370000018],
            [125.797509117000146, 18.854995369000179],
            [125.798915117000121, 18.845805869000188],
            [125.800320117000211, 18.836624369000177],
            [125.801727617000182, 18.827429369000114],
            [125.803133117000158, 18.818247369000133],
            [125.804540117000187, 18.80905886900014],
            [125.80594661700016, 18.799868869000036],
            [125.807353617000189, 18.790679869000101],
            [125.808760617000161, 18.781490869],
            [125.810167617000133, 18.772301369000033],
            [125.811574117000106, 18.763112869000153],
            [125.812981117000135, 18.753923369000077],
            [125.814387617000108, 18.744741869000066],
            [125.815796117000076, 18.735545369000121],
            [125.817199617000171, 18.726396369000142],
            [125.817322617000201, 18.724981369000105],
            [125.818519617000135, 18.717194369000097],
            [125.818705617000234, 18.715054368999958],
            [125.81979161700022, 18.707985369000014],
            [125.819975617000154, 18.705863369000141],
            [125.821065617000301, 18.698776369000129],
            [125.821251617000058, 18.696638369000155],
            [125.822337616999988, 18.689567369000045],
            [125.822522617000203, 18.687443368999979],
            [125.823611617000068, 18.680357369000077],
            [125.823796617000113, 18.67822136900007],
            [125.824883617000154, 18.671148369000164],
            [125.825067617000315, 18.669027369000176],
            [125.82615661700018, 18.661939369000081],
            [125.826340617000113, 18.659817369000208],
            [125.827430617000203, 18.652729369000113],
            [125.827614617000364, 18.650609369000207],
            [125.82870361700023, 18.643520369000029],
            [125.828887617000163, 18.641398369000129],
            [125.82997761700031, 18.634310369000232],
            [125.830161617000073, 18.63219136900004],
            [125.831250617000109, 18.625101368999978],
            [125.831433617000158, 18.622998369000058],
            [125.832525617000073, 18.615892369000065],
            [125.832709617000233, 18.613773369000072],
            [125.833798617000099, 18.606682369000097],
            [125.833981617000319, 18.60458036900026],
            [125.835073617000063, 18.597473369000184],
            [125.835257617000167, 18.595354369000191],
            [125.836346617000203, 18.588263369000217],
            [125.836529617000252, 18.586162369000093],
            [125.837621617000224, 18.579054369000133],
            [125.837803617000162, 18.576951369000042],
            [125.838895617000247, 18.569845369000049],
            [125.839078617000126, 18.567743369000041],
            [125.840170617000268, 18.560636369000164],
            [125.840352617000036, 18.558533369000074],
            [125.841444617000349, 18.551426369],
            [125.841627617000171, 18.549323369000078],
            [125.842719617000313, 18.542216369000201],
            [125.842901617000251, 18.54011636900016],
            [125.843993617, 18.533007369000117],
            [125.844176617000215, 18.530906369000192],
            [125.845268617000187, 18.523797368999951],
            [125.845448617000301, 18.521713369000182],
            [125.846543617000151, 18.514588369000066],
            [125.846726617000201, 18.512487369000141],
            [125.847818617000115, 18.50537836900007],
            [125.847998617000115, 18.503293369000048],
            [125.849093617000136, 18.496168369000102],
            [125.849275617000245, 18.494071368999968],
            [125.8503686170001, 18.486959369000019],
            [125.850548617000044, 18.484875369000079],
            [125.851643617000235, 18.477749369000222],
            [125.851824617000119, 18.475665369000083],
            [125.85291961700014, 18.468539369000055],
            [125.853099617000083, 18.466459369000077],
            [125.854194617000275, 18.459330368999971],
            [125.854375617000159, 18.457248369000197],
            [125.855470617000179, 18.450120369000174],
            [125.855650617000123, 18.44803836900023],
            [125.856745617000314, 18.440910369000207],
            [125.856925617000144, 18.438846369000132],
            [125.858022617000273, 18.431701369000123],
            [125.858202617000103, 18.429620369000062],
            [125.859297617000294, 18.42249236900004],
            [125.859478617000121, 18.420411369000178],
            [125.860573617000313, 18.413282369000243],
            [125.860752617000202, 18.411216369000172],
            [125.861849617000217, 18.404072369000076],
            [125.862029617000161, 18.402006369000205],
            [125.863126617000177, 18.394862369000109],
            [125.863306617000177, 18.392784369000125],
            [125.864401617000311, 18.385653369000195],
            [125.864581617000141, 18.38358936900012],
            [125.865678617000327, 18.376443369000228],
            [125.865857617000216, 18.374379369000152],
            [125.866954617000175, 18.367233369000061],
            [125.867134617000175, 18.365169368999986],
            [125.868231617000305, 18.358023369000065],
            [125.86840961700014, 18.355960369000101],
            [125.86950761700021, 18.348813369000098],
            [125.869685617000215, 18.346765369000096],
            [125.87078561700028, 18.339603369000102],
            [125.870964617000169, 18.33754136900022],
            [125.872061617000298, 18.330393369000134],
            [125.872240617000188, 18.328331369000054],
            [125.873338617000314, 18.321183368999968],
            [125.873516617000263, 18.319136369000077],
            [125.874616617000157, 18.311973369000171],
            [125.874793617000222, 18.309927369000164],
            [125.875893617000287, 18.302763369000175],
            [125.876072617000176, 18.30070336900009],
            [125.877170617000246, 18.293553369000207],
            [125.877347617000197, 18.29151136900019],
            [125.878447617000262, 18.284344369000124],
            [125.87862561700021, 18.282300369000112],
            [125.879725617000275, 18.275134369000156],
            [125.87990261700017, 18.273088369000149],
            [125.881002617000235, 18.265923369000078],
            [125.881180617000069, 18.263880368999978],
            [125.882280617000134, 18.25671336900011],
            [125.882458617000083, 18.254671369000064],
            [125.883557617000093, 18.247504369000197],
            [125.883733617000104, 18.245475369],
            [125.884836617000047, 18.238294369000229],
            [125.885014617000053, 18.236251369000101],
            [125.886114617000118, 18.229084369000063],
            [125.886289617000187, 18.227056368999953],
            [125.887392617000131, 18.219874369000067],
            [125.887570617000137, 18.21783236900005],
            [125.888670617000201, 18.210664369000099],
            [125.888845617000101, 18.208638369000155],
            [125.889948617000044, 18.201454369000103],
            [125.890124617000225, 18.199429369000072],
            [125.891227617, 18.192244369000136],
            [125.891403617000179, 18.190219369000104],
            [125.892505617000239, 18.183034368999969],
            [125.89268161700025, 18.181007369000113],
            [125.893784617000193, 18.17382336900009],
            [125.893960617000204, 18.171799369000141],
            [125.895063617000318, 18.164613369000094],
            [125.895238617000217, 18.162589368999974],
            [125.896341617000218, 18.155403369000126],
            [125.896517617000171, 18.153379368999978],
            [125.897620617000172, 18.14619336900013],
            [125.897794617000187, 18.144184369],
            [125.898899617000126, 18.136983369000163],
            [125.899075617000079, 18.134958369000131],
            [125.90017861700008, 18.127772369000084],
            [125.90035361700015, 18.125765369000121],
            [125.901458617000088, 18.118562369000117],
            [125.901632617000104, 18.116556369000037],
            [125.902737617000042, 18.109352369000121],
            [125.902913617000223, 18.107330369000167],
            [125.904016617, 18.100142369000153],
            [125.904190617000182, 18.09813536900019],
            [125.905295617000291, 18.090931369000074],
            [125.905470617000248, 18.088926369000106],
            [125.906575617000186, 18.081721369000107],
            [125.906750617000256, 18.079717369000022],
            [125.907855617000195, 18.072511369000111],
            [125.90802961700021, 18.07050536900006],
            [125.909134617000149, 18.063300369000061],
            [125.90930761700011, 18.061311369000165],
            [125.910415617000098, 18.054091368999977],
            [125.910590617, 18.052085369000096],
            [125.911695617000333, 18.044880369000097],
            [125.911867617000354, 18.042891369],
            [125.912975617000171, 18.035670369000101],
            [125.913149617000187, 18.033667369000128],
            [125.914255617000236, 18.026460369000134],
            [125.914427617000257, 18.024469369000073],
            [125.915536617000186, 18.017249369000055],
            [125.91570961700009, 18.015247369000164],
            [125.91681561700014, 18.008039369000088],
            [125.916988617000044, 18.00604936900011],
            [125.918096617000032, 17.998828369],
            [125.918268617000052, 17.996842369000191],
            [125.919376617000097, 17.989618369000041],
            [125.919549617, 17.98763036900003],
            [125.920657617000217, 17.980407368999963],
            [125.920830617000291, 17.978422369000228],
            [125.921938617000166, 17.971197369000166],
            [125.922109617000245, 17.969225369000256],
            [125.923219617000115, 17.961986369000115],
            [125.923392617000189, 17.960002369000094],
            [125.924500617000064, 17.952776369000119],
            [125.924673617000138, 17.950790369000103],
            [125.925781617000354, 17.94356536900024],
            [125.925951617000038, 17.941596369000038],
            [125.927062617000303, 17.934355369000073],
            [125.927234617000323, 17.932384369000076],
            [125.928344617000135, 17.925144369000193],
            [125.92851761700021, 17.923162369000167],
            [125.929625617000085, 17.915934369000198],
            [125.929796617000164, 17.913964369000112],
            [125.930906617000375, 17.906723369000147],
            [125.931077617000113, 17.904754369000116],
            [125.932188617000207, 17.897512369000069],
            [125.932358617000233, 17.895546369000115],
            [125.933470617000211, 17.888302369000101],
            [125.933640617000293, 17.886335369000236],
            [125.93475161700016, 17.879091369000022],
            [125.934922617000126, 17.877124368999986],
            [125.936033617000049, 17.869880368999972],
            [125.936202617000134, 17.867930369000092],
            [125.937316617000334, 17.860670368999976],
            [125.93748661700036, 17.858704369000222],
            [125.938597617000227, 17.851459369000096],
            [125.938767617000309, 17.849508368999963],
            [125.939880617000171, 17.842248369000217],
            [125.940051617000137, 17.840285369000142],
            [125.941162617, 17.83303836900005],
            [125.941331617000145, 17.83108836900017],
            [125.942444617000234, 17.823827369000171],
            [125.94261461700026, 17.82187736900012],
            [125.943727617000178, 17.814616369000092],
            [125.943898617000258, 17.812652368999963],
            [125.945009617000181, 17.805406369000124],
            [125.945178617000153, 17.803456369000244],
            [125.946291617000014, 17.796195369000046],
            [125.946459617000102, 17.794264369000146],
            [125.94757561700024, 17.786985369000078],
            [125.947745617000265, 17.78503636900011],
            [125.948858617000127, 17.777774368000095],
            [125.949027617000269, 17.775825367999957],
            [125.950141617000071, 17.768563368000216],
            [125.950310617000042, 17.766615367999961],
            [125.951423617000302, 17.759352368000137],
            [125.951591617000162, 17.757420367999984],
            [125.952707617000129, 17.750141368000257],
            [125.952876617000271, 17.748195368000196],
            [125.953990617000073, 17.740930368],
            [125.954157617000163, 17.738999368000108],
            [125.955273617000358, 17.731719367999958],
            [125.955441617000048, 17.729790368000224],
            [125.956557617000186, 17.722509367999962],
            [125.956725617000217, 17.720579368000145],
            [125.957841617000184, 17.713298368000082],
            [125.958009617000215, 17.711353368000104],
            [125.959124617000299, 17.704087368000202],
            [125.959290617000164, 17.702173368000118],
            [125.960408617000127, 17.694876368000124],
            [125.960576617000157, 17.692947368000219],
            [125.961692617000125, 17.685665368000244],
            [125.961859617000101, 17.68373636799997],
            [125.962976617000351, 17.676454368],
            [125.9631436170001, 17.674526368000173],
            [125.964259617000238, 17.667243368000115],
            [125.964426617000214, 17.665331368000196],
            [125.965544617000177, 17.658032368000065],
            [125.965712617000207, 17.656105368000127],
            [125.966828617000175, 17.648821368000156],
            [125.96699461700004, 17.64691036800015],
            [125.968113617000114, 17.639610368000106],
            [125.968280617000261, 17.63768436800008],
            [125.969396617000058, 17.630399368000027],
            [125.969562617000093, 17.628489368000103],
            [125.970681617000281, 17.621188367999977],
            [125.970847617000373, 17.619278368000025],
            [125.971966617000049, 17.611977368000097],
            [125.972132617000256, 17.610066368000062],
            [125.973251617000329, 17.602765368000107],
            [125.97341661700014, 17.600856368000095],
            [125.974535617000157, 17.593554368000056],
            [125.974701617000022, 17.591646368000099],
            [125.975820617000096, 17.584343368000177],
            [125.975985617000248, 17.58245036800021],
            [125.97710661700026, 17.575132368000098],
            [125.977271617000071, 17.57322436800014],
            [125.978391617000142, 17.565921368000218],
            [125.978555617000239, 17.564028368000081],
            [125.979677617000362, 17.556710367999969],
            [125.979842617000116, 17.554801368000128],
            [125.98096161700019, 17.547498368000177],
            [125.981126617000342, 17.545608368000117],
            [125.982247617000183, 17.538287368000127],
            [125.982411617000224, 17.536397368000237],
            [125.983533617000177, 17.529076368000048],
            [125.983698617000158, 17.527171368000168],
            [125.984818617000116, 17.519865368000168],
            [125.984982617000156, 17.517976368000191],
            [125.986103617, 17.51065436800009],
            [126.004269617000119, 17.301425368000068],
            [126.008746617000185, 17.091491367999978],
            [126.005177617000101, 17.00998436800019],
            [126.1541106170001, 16.92309436800015],
            [126.33302161800006, 16.803057368000211],
            [126.503530618000212, 16.672344367000036],
            [126.664966618000193, 16.531495367000119],
            [126.816701618000053, 16.381082367000218],
            [126.958150618000246, 16.221720367000088],
            [126.962291618000194, 16.216405366999979],
            [126.963867618000194, 16.214628367000032],
            [127.094484618000166, 16.046959367000213],
            [127.213782618000238, 15.871659367000134],
            [127.321318619000323, 15.689433367000035],
            [127.324165619000127, 15.683810367000021],
            [127.325695619000243, 15.681216366999976],
            [127.328546619000264, 15.675585367000181],
            [127.330071619000108, 15.673000367000171],
            [127.332913619000095, 15.667383367000113],
            [127.334448619000256, 15.664783367000112],
            [127.337289619000302, 15.659169367000104],
            [127.338825619000232, 15.656566367000025],
            [127.341670619000098, 15.650943367000011],
            [127.343201619000098, 15.648349366999966],
            [127.346038619000154, 15.642742367000025],
            [127.347578619000075, 15.640132367000078],
            [127.350413619000136, 15.634528367000215],
            [127.351955619, 15.631915366000115],
            [127.354788619000118, 15.62631436600013],
            [127.356332619000142, 15.623698366000028],
            [127.359164619000154, 15.61810036599995],
            [127.360709619000119, 15.615482366000052],
            [127.363542619000185, 15.609883366000062],
            [127.365085619000155, 15.607266366000076],
            [127.367913619000177, 15.601675366000038],
            [127.369462619000302, 15.599049366000187],
            [127.372283619000115, 15.593472366000057],
            [127.373840619000163, 15.590832366000129],
            [127.376665619000306, 15.585247366000218],
            [127.378217619000083, 15.582615366000041],
            [127.381037619000182, 15.577040366000105],
            [127.38259461900023, 15.574399366000065],
            [127.385415619000213, 15.568821366000222],
            [127.386970619000266, 15.56618336600026],
            [127.389791619000079, 15.560607366000042],
            [127.391347619000072, 15.557966366000201],
            [127.39416261900007, 15.5524003660001],
            [127.395724619, 15.549750366000225],
            [127.398534619000174, 15.544193366000158],
            [127.400102619000251, 15.541533366000138],
            [127.402913619000259, 15.535974366000104],
            [127.40447961900017, 15.533317366],
            [127.407286619000246, 15.527766366000051],
            [127.408857619000031, 15.525100366000103],
            [127.411664619000106, 15.519547366000197],
            [127.413234619000178, 15.516884366000127],
            [127.416037619000093, 15.511340366000056],
            [127.417612619000039, 15.508668366000123],
            [127.420416619000235, 15.503121366],
            [127.421989619000357, 15.500452365999976],
            [127.424786619000287, 15.494920366000017],
            [127.426367619000217, 15.492236366000171],
            [127.429171619000016, 15.486689366000221],
            [127.430744619000137, 15.484019366000112],
            [127.433537619000134, 15.478494366000191],
            [127.435122619, 15.475803366000108],
            [127.437913619, 15.470282366000177],
            [127.439500619000256, 15.467587365999961],
            [127.442293619000253, 15.46206236600004],
            [127.443878619000117, 15.459371366000155],
            [127.446672619000168, 15.453843365999987],
            [127.448255619000037, 15.451155366000179],
            [127.451041619000165, 15.445643366000084],
            [127.452633619000295, 15.442939366],
            [127.455418619000142, 15.437430366000186],
            [127.457011619000156, 15.434723366000199],
            [127.459794619000178, 15.429217366000088],
            [127.461390619000241, 15.426507366000223],
            [127.464170619000214, 15.421005366000074],
            [127.465768619000272, 15.418291366000048],
            [127.468545619000196, 15.412792366000176],
            [127.470146619000133, 15.410076365999984],
            [127.472916619000188, 15.40459136600019],
            [127.474525619000104, 15.401860366000179],
            [127.477299619000263, 15.396366365999981],
            [127.478903619000135, 15.393644366000203],
            [127.481675619000185, 15.388154366000165],
            [127.483281619000167, 15.385428366000028],
            [127.486051619000051, 15.379941366000068],
            [127.487659619000198, 15.377212366000222],
            [127.490422619000043, 15.371740366000083],
            [127.492038619000169, 15.368996366000076],
            [127.494805619000289, 15.363515366000243],
            [127.4964166190002, 15.360781366000154],
            [127.499182619000095, 15.355303366000058],
            [127.500794619000231, 15.352565366],
            [127.503552619000146, 15.347102366000072],
            [127.505173619000146, 15.344349366000202],
            [127.507932619, 15.338884366000102],
            [127.509551619, 15.33613436600011],
            [127.51230661900027, 15.330678366000072],
            [127.513930619000149, 15.327918366000134],
            [127.51668361900019, 15.322465366000145],
            [127.518309619000121, 15.319702366000158],
            [127.521063619000046, 15.314247366],
            [127.522687619000322, 15.311488365999978],
            [127.525437619000314, 15.306041366000144],
            [127.527066619000237, 15.303272366000172],
            [127.529814619000234, 15.297828366000218],
            [127.531445619000039, 15.295056366000196],
            [127.53419461900009, 15.289610366000076],
            [127.53582361900024, 15.286841366000104],
            [127.538567619000247, 15.281403366000134],
            [127.540202619000155, 15.278625365999957],
            [127.542941619000345, 15.273198366000159],
            [127.544581619000127, 15.270411366000147],
            [127.547322619000084, 15.264979366000105],
            [127.548960619000269, 15.262195366000142],
            [127.551696619000126, 15.256773366000246],
            [127.553339619000184, 15.253980366000249],
            [127.556077619000263, 15.248554366000192],
            [127.557718619000326, 15.245764366000103],
            [127.560451619000133, 15.240349366000046],
            [127.562097619000241, 15.237549366000181],
            [127.56482861900011, 15.232137366000231],
            [127.566476619000042, 15.229334366000117],
            [127.569203619000035, 15.22393036600009],
            [127.570856619000239, 15.221119366000025],
            [127.573583619000232, 15.215713366000031],
            [127.575235619000154, 15.212904365999961],
            [127.577961619000092, 15.207501366000017],
            [127.579614619000296, 15.204689366000068],
            [127.58233861900024, 15.199289366],
            [127.583993619000211, 15.196474366000146],
            [127.586713619000221, 15.191083365999972],
            [127.588373619000066, 15.188258366],
            [127.591094619000131, 15.182865366000172],
            [127.592752619000208, 15.18004436599999],
            [127.595465619000123, 15.174665366000099],
            [127.597133619000118, 15.171829366000097],
            [127.59985061900025, 15.166441366000171],
            [127.60151261900026, 15.163614366000203],
            [127.604221619000242, 15.158242366000152],
            [127.605892619000116, 15.155399366000111],
            [127.608605619000031, 15.150018366000225],
            [127.610271619000031, 15.147184366000218],
            [127.612977619000304, 15.141818366000152],
            [127.614651619000227, 15.138970366000038],
            [127.617355619000165, 15.13360736600022],
            [127.619031619000083, 15.130755366000145],
            [127.621739619000351, 15.125383366000122],
            [127.623410619000225, 15.122540366000251],
            [127.626111619000227, 15.117182366000137],
            [127.627790619000081, 15.114325365999989],
            [127.630489619000258, 15.108971366000205],
            [127.632170619000277, 15.106110366000095],
            [127.634864619000069, 15.100766366000059],
            [127.636550619000133, 15.097897366000169],
            [127.639246619000261, 15.092549366],
            [127.640930619000329, 15.089682366000105],
            [127.643624619000121, 15.084336366000073],
            [127.645310619000185, 15.081467366000012],
            [127.648002619000152, 15.076125365999971],
            [127.64969061900004, 15.073252366000119],
            [127.652381619000295, 15.067913366000155],
            [127.654070619000237, 15.065037366000226],
            [127.65675661900022, 15.05970836600018],
            [127.658450619000092, 15.056824366000129],
            [127.661137619000357, 15.051490366000039],
            [127.662830619000289, 15.048609366000235],
            [127.665513619000222, 15.043286366000174],
            [127.667210619000144, 15.040395366000055],
            [127.669888619000204, 15.035080366000116],
            [127.671592619000336, 15.032180366000162],
            [127.674274618999988, 15.026856366000189],
            [127.675972619000135, 15.02396536600007],
            [127.678648619000256, 15.018652365999955],
            [127.68035261899999, 15.015752366000171],
            [127.683025619000176, 15.010446366000096],
            [127.684733619000127, 15.007537366000079],
            [127.687406619000313, 15.002229366000208],
            [127.689113619000153, 14.999323366000098],
            [127.6917796190003, 14.994030366000189],
            [127.693494619000234, 14.991109366000089],
            [127.696167619000249, 14.985801366000018],
            [127.697874619000089, 14.982894366000025],
            [127.700537619000357, 14.9776073660001],
            [127.702255619000226, 14.974681366000098],
            [127.704922619000087, 14.969384366000227],
            [127.706635619000082, 14.966467366000117],
            [127.709298619000123, 14.96117936600011],
            [127.711016619000162, 14.958252366000224],
            [127.713674618999988, 14.952974366000134],
            [127.715397619000072, 14.950038366000044],
            [127.718053619000131, 14.944764366000115],
            [127.719778619000209, 14.941824366000233],
            [127.722438619000201, 14.9365413660001],
            [127.724159619000119, 14.933611366000136],
            [127.726814619000237, 14.928335366000042],
            [127.728540619000256, 14.925396366000243],
            [127.731190619000103, 14.920131366000177],
            [127.732921619000166, 14.917182366000063],
            [127.735570619000356, 14.911920366000246],
            [127.737302619000303, 14.908968366000053],
            [127.7399496190001, 14.903708366000231],
            [127.741683619000042, 14.900755366000155],
            [127.744328619000015, 14.895497366000129],
            [127.74606461900035, 14.892541366000145],
            [127.748708619000269, 14.887287366000109],
            [127.750445619000089, 14.884327366],
            [127.753081619000199, 14.879088366000119],
            [127.754827619000281, 14.876113366000155],
            [127.757469619000204, 14.870865366000075],
            [127.759208619000191, 14.867899366000174],
            [127.761848619000119, 14.862655366000254],
            [127.763589619000328, 14.859686366000247],
            [127.766227619000261, 14.854444366000152],
            [127.767970619000067, 14.851472366000095],
            [127.770596619000258, 14.846252366000201],
            [127.772353619000313, 14.843259366000169],
            [127.774987619000143, 14.838024366000113],
            [127.776734619000109, 14.835045366000188],
            [127.779367619000169, 14.829813366000181],
            [127.78111561900019, 14.826832366000261],
            [127.783740619000156, 14.821613366000108],
            [127.78549761900004, 14.81861836600028],
            [127.788123619000231, 14.813398366000186],
            [127.789878619000348, 14.810405366000182],
            [127.792500619000208, 14.805193366000069],
            [127.794260619000141, 14.802191366000173],
            [127.796880619000063, 14.796982366000137],
            [127.798642619000162, 14.793977366000192],
            [127.801263619000309, 14.788767366000243],
            [127.803023619000129, 14.785765366000177],
            [127.805640619000116, 14.780562366000098],
            [127.80740561900032, 14.777551366],
            [127.810017619000035, 14.772359366000117],
            [127.811788619000055, 14.769338366000099],
            [127.814401619000222, 14.764142366000229],
            [127.816170619000246, 14.761124366000089],
            [127.818778619000199, 14.755937366000282],
            [127.82055261900004, 14.752912366000103],
            [127.823169619000083, 14.747708366000111],
            [127.824933619000177, 14.744698366000094],
            [127.82753861900008, 14.739517366000243],
            [127.829315619000198, 14.736485366],
            [127.83192361900015, 14.731300366000156],
            [127.833697619000219, 14.728271366000186],
            [127.836299619000187, 14.723095366000209],
            [127.838079619000013, 14.720059366000172],
            [127.840680619000324, 14.714886366000101],
            [127.842461619000204, 14.711846366000103],
            [127.845058619000184, 14.706681366000126],
            [127.84684561900022, 14.703632366000093],
            [127.849442619000371, 14.69846536600015],
            [127.85122761900007, 14.695419366],
            [127.853822619000169, 14.690256366000213],
            [127.855249619000148, 14.687822366000077],
            [127.960463619000194, 14.567796366000167],
            [127.966126119000165, 14.560442366000089],
            [127.971819619000172, 14.553051865000114],
            [127.977508619000133, 14.545666365000173],
            [127.983195619000156, 14.538284365000109],
            [127.988886119000227, 14.530898865000196],
            [127.994576119000243, 14.523513365000085],
            [128.000266119000202, 14.516127865000058],
            [128.005959619000095, 14.508737865000214],
            [128.011642619000128, 14.501361865000192],
            [128.01733511900008, 14.493973365000201],
            [128.023025619000094, 14.486587365000062],
            [128.028715619000053, 14.479202365000177],
            [128.034402619000133, 14.471821365000167],
            [128.038680619000246, 14.466265365000098],
            [128.040255619000192, 14.464466365000121],
            [128.043706619000119, 14.459978365000225],
            [128.046125619000208, 14.457217365000204],
            [128.049566619000217, 14.452740365000139],
            [128.051994619000169, 14.449968365000117],
            [128.055432619000072, 14.445496365000125],
            [128.05786361900013, 14.442720365000113],
            [128.061313619000288, 14.43823336500013],
            [128.063731618999981, 14.435470365000114],
            [128.067164619000181, 14.431004365000078],
            [128.069600619000283, 14.42822236500011],
            [128.073030619000036, 14.423759365000151],
            [128.075470619000129, 14.420974365000106],
            [128.078911619000081, 14.416497365000069],
            [128.081338619000036, 14.413724365000135],
            [128.084763619000256, 14.409268365000216],
            [128.087207619000338, 14.406476365000131],
            [128.090629619000282, 14.40202336500009],
            [128.093076619000243, 14.399227365000215],
            [128.096503619000117, 14.394768365000047],
            [128.09894461900015, 14.391979365000211],
            [128.10236161900022, 14.387532364999956],
            [128.104813619000055, 14.384730365000124],
            [128.108227619000132, 14.380288365000112],
            [128.110683619000298, 14.37748236500012],
            [128.114093619000158, 14.373042365000103],
            [128.116552619000203, 14.370234365000115],
            [128.119968619000275, 14.365788365000142],
            [128.12242061900011, 14.362985365000029],
            [128.125825619000096, 14.358552365000222],
            [128.128289619000014, 14.355737365000024],
            [128.131693619000288, 14.351306365000212],
            [128.134158619000317, 14.348488365000108],
            [128.137566619000069, 14.344053365000164],
            [128.140026619000224, 14.341240365000104],
            [128.143424619000172, 14.336817365000243],
            [128.145896619000069, 14.3339923650001],
            [128.149284619000269, 14.329580365000098],
            [128.151765619000372, 14.326744365000096],
            [128.155164619000033, 14.322318364999987],
            [128.157633619000222, 14.319496365000262],
            [128.161023619000076, 14.315082365000094],
            [128.163502619000184, 14.312247365000175],
            [128.292480619000202, 14.144287365000096],
            [128.296510619000088, 14.138280365000256],
            [128.297757619000237, 14.136656365],
            [128.415526620000179, 13.961105365000137],
            [128.521651620000256, 13.778664365000196],
            [128.524120620000275, 13.773715365000157],
            [128.526021620000193, 13.770447365000123],
            [128.528479620000184, 13.765520365000143],
            [128.530391620000302, 13.762231365000147],
            [128.532857620000215, 13.757287365000167],
            [128.534761620000182, 13.754014365000074],
            [128.537220620000056, 13.749085365000099],
            [128.539132620000174, 13.745798365000098],
            [128.54159662000032, 13.740859365],
            [128.543502620000282, 13.737581365000025],
            [128.545958620000107, 13.732656365000025],
            [128.547873620000274, 13.729364365000137],
            [128.550330620000153, 13.724437364999972],
            [128.552243620000155, 13.721148364999976],
            [128.554696620000101, 13.716229365000117],
            [128.556613620000263, 13.712931365000088],
            [128.559065620000098, 13.708015365000122],
            [128.560984620000255, 13.7047143650002],
            [128.563431620000216, 13.699807365000098],
            [128.565355620000247, 13.696498365000224],
            [128.567809620000077, 13.691575365000205],
            [128.569725620000298, 13.688282365000063],
            [128.573460620000077, 13.680790365000121],
            [128.573912620000186, 13.680013365000136],
            [128.667951620000025, 13.491405365000105],
            [128.74962262000011, 13.297384364000123],
            [128.818632620000272, 13.098715364000228],
            [128.874739620000213, 12.896186364000158],
            [128.917753620000127, 12.69058936400009],
            [128.947534620000027, 12.482731364000173],
            [128.960119620000199, 12.322737363000243],
            [129.04852662000016, 12.144631363000173],
            [129.129875620000263, 11.950561363000119],
            [129.198635620000317, 11.751840363000184],
            [129.254564620000309, 11.549249363000143],
            [129.297489620000107, 11.343445363000086],
            [129.299370620000133, 11.334338362000196],
            [129.301251620000158, 11.325238362000164],
            [129.303134620000293, 11.316123862000182],
            [129.305016620000146, 11.307016362000155],
            [129.30689662000006, 11.297917362000121],
            [129.308780120000023, 11.288800862000144],
            [129.31066062000022, 11.27970186200011],
            [129.312542620000244, 11.270594862000209],
            [129.314425620000151, 11.261487362000096],
            [129.31630762000006, 11.252379862000154],
            [129.318191620000079, 11.243265862000129],
            [129.320070620000109, 11.234172362000109],
            [129.321955620000182, 11.225057862000114],
            [129.323837620000148, 11.215950362000186],
            [129.325719620000058, 11.2068433620001],
            [129.327601620000081, 11.197744362000165],
            [129.329485620000213, 11.188626862000191],
            [129.331367620000179, 11.179528862000154],
            [129.333251620000141, 11.170413862000132],
            [129.335133120000165, 11.161314862000097],
            [129.337016120000186, 11.152207362000155],
            [129.338900620000146, 11.143098362000089],
            [129.340783120000111, 11.133991862000229],
            [129.3426671200003, 11.124884862000158],
            [129.344550120000093, 11.115777362000131],
            [129.346434620000224, 11.106668862000106],
            [129.348316120000248, 11.097569862000171],
            [129.350202120000205, 11.088455362000175],
            [129.352085120000169, 11.079347862000247],
            [129.353967620000191, 11.070246862000133],
            [129.355852120000151, 11.061140362000188],
            [129.357738120000107, 11.052025862000207],
            [129.35962012000013, 11.042925862000175],
            [129.361504620000204, 11.03381736200015],
            [129.363389120000164, 11.02471086200012],
            [129.365273620000181, 11.015603862000134],
            [129.367158120000141, 11.006494362000211],
            [129.369041620000104, 10.997395862000118],
            [129.370928120000173, 10.988280862000181],
            [129.372813120000188, 10.979173862000195],
            [129.374696120000152, 10.970072862000094],
            [129.376583120000163, 10.960958362000198],
            [129.378466120000184, 10.951858862000122],
            [129.380352120000197, 10.942750362000183],
            [129.382238120000153, 10.93363536200016],
            [129.384122120000114, 10.924536362000126],
            [129.386007120000073, 10.915427862000101],
            [129.387892621000248, 10.9063208620002],
            [129.389777621000206, 10.897213862000129],
            [129.391663121000164, 10.888112862000114],
            [129.393549621000233, 10.878998862000159],
            [129.395435621000132, 10.869891362000132],
            [129.397320621000148, 10.860790362000117],
            [129.399207621000102, 10.851675862000135],
            [129.401092121000062, 10.842576862000186],
            [129.402979121000129, 10.833467362000164],
            [129.404866621000195, 10.82435386200018],
            [129.406750621000214, 10.815254362000104],
            [129.408637621000167, 10.806145362000123],
            [129.410523621000181, 10.797038362000137],
            [129.412410621000191, 10.787929862000126],
            [129.414296121000206, 10.778831362000133],
            [129.416184621000156, 10.769715862000155],
            [129.418071121000168, 10.760606862000174],
            [129.419956621000125, 10.751508362000095],
            [129.421845621000131, 10.742393862000114],
            [129.423730621000317, 10.73329286200017],
            [129.425618621000211, 10.724185862000098],
            [129.427507121000104, 10.715069362000122],
            [129.429392621000233, 10.705970862000129],
            [129.431280121000185, 10.696861862000162],
            [129.433167621000251, 10.687755362000132],
            [129.435055621000146, 10.67864786200019],
            [129.4369426210001, 10.66953936200008],
            [129.438829121000339, 10.660439862000089],
            [129.440719121000285, 10.651323362000113],
            [129.442605121000128, 10.64222486200012],
            [129.444495621000186, 10.6331088620001],
            [129.446381121000144, 10.624008862000167],
            [129.448271121000204, 10.614894862000128],
            [129.450158121000101, 10.605793862000198],
            [129.452046121000222, 10.596686862000126],
            [129.453935621000227, 10.58757786200006],
            [129.455823621000178, 10.578471362000116],
            [129.45771262100007, 10.569362862000091],
            [129.459600621000135, 10.560256362000146],
            [129.461489621000254, 10.551147362000179],
            [129.463377121000093, 10.542048362000145],
            [129.465268121000207, 10.532932362000125],
            [129.467155121000218, 10.523833862000131],
            [129.469046121000105, 10.514716362000158],
            [129.470933121000172, 10.505617862000165],
            [129.472823121000232, 10.496509362000154],
            [129.474713621000177, 10.487394362000217],
            [129.476601121000073, 10.478293862000058],
            [129.478491121000246, 10.469187362000113],
            [129.480380621000194, 10.460078862000088],
            [129.482269121000087, 10.450979362000098],
            [129.484161121000255, 10.441862862000221],
            [129.486050621000146, 10.432756362000092],
            [129.487939121000096, 10.42365486200012],
            [129.489830621000266, 10.41454086200018],
            [129.491710621000124, 10.405504361999988],
            [129.492332621000259, 10.401158362000103],
            [129.493332621000064, 10.396359362000226],
            [129.523098621000173, 10.1883743610002],
            [129.539644621000349, 9.978932361000133],
            [129.539661621000135, 9.977816361000166],
            [129.540306621000042, 9.969652361000044],
            [129.54032462100011, 9.968535361000178],
            [129.540969621000187, 9.96037236100014],
            [129.540986621000371, 9.959254361000177],
            [129.54163162100005, 9.951093361000133],
            [129.541648621000064, 9.94997336100019],
            [129.542293621000141, 9.941813361000214],
            [129.542311621000209, 9.9406933610001],
            [129.542624621000158, 9.936727361000223],
            [129.543496621, 9.933563361000083],
            [129.544741621000156, 9.929956361000251],
            [129.546200621000111, 9.924659361000195],
            [129.547445621000094, 9.921053361000261],
            [129.548900621000058, 9.915772361000123],
            [129.550150621000085, 9.912151361000184],
            [129.551609620999983, 9.906854361000143],
            [129.552854621000137, 9.903248361000209],
            [129.554313621000261, 9.897953361000148],
            [129.555558621000074, 9.894345361000219],
            [129.557013621000038, 9.889065361000164],
            [129.558263621000066, 9.885442361000059],
            [129.6141136210002, 9.682751861000142],
            [129.616576621000121, 9.673808361000184],
            [129.659478621000147, 9.468048361000129],
            [129.659805621000061, 9.465763361000072],
            [129.661234621000148, 9.458911361000148],
            [129.66156462100011, 9.456610361],
            [129.662989621000037, 9.449774360999982],
            [129.663317621, 9.447489361000109],
            [129.664745621000037, 9.440637361000185],
            [129.665073621000175, 9.438352361000128],
            [129.666501621000208, 9.431500361000204],
            [129.666829621000176, 9.429215361000146],
            [129.668258621000263, 9.422363361000222],
            [129.668585621000176, 9.420078361000165],
            [129.670014621000092, 9.413226361000056],
            [129.670341621000119, 9.410941361000184],
            [129.671770621000093, 9.404089361000075],
            [129.67209762100012, 9.401804361000018],
            [129.673526621000036, 9.394952361000094],
            [129.673854621, 9.392667361000221],
            [129.675282621000036, 9.385815361000112],
            [129.675608621, 9.38354536100006],
            [129.677039621000318, 9.376678361000131],
            [129.677367621000229, 9.374393361000074],
            [129.678795621000262, 9.36754136100015],
            [129.679121621000235, 9.365271361000097],
            [129.680553621000087, 9.358403361000086],
            [129.680880621000114, 9.356118361000213],
            [129.682309621000087, 9.349266361000105],
            [129.682634621000119, 9.346997361000135],
            [129.684066621000142, 9.340129361000123],
            [129.684391621, 9.337860361000153],
            [129.685823621000196, 9.330992360999971],
            [129.686151621000164, 9.328707361000085],
            [129.687579621000197, 9.321855361000161],
            [129.687905621000169, 9.319586361],
            [129.689336621000081, 9.312718361000179],
            [129.689662621000053, 9.310449361000209],
            [129.691093621000135, 9.303581361000198],
            [129.691419621000108, 9.30130936100015],
            [129.692851621000131, 9.294443361000134],
            [129.693176621, 9.292174360999979],
            [129.694608621000185, 9.285306361000153],
            [129.694931621000279, 9.283052361000173],
            [129.69636662100018, 9.276169361000171],
            [129.696691621000213, 9.273900361000202],
            [129.698123621000065, 9.26703236100002],
            [129.698449621000037, 9.264762361000138],
            [129.699880621000119, 9.257895361000209],
            [129.700204621000097, 9.255641361000059],
            [129.701638621000285, 9.248758361000228],
            [129.701964621000258, 9.246488361000175],
            [129.703396621000223, 9.239621361000246],
            [129.703719621000317, 9.237367361000096],
            [129.705154621000048, 9.230484361000094],
            [129.705477621000142, 9.228230361000115],
            [129.706912621000043, 9.221347361000113],
            [129.707238621000357, 9.219074361000153],
            [129.708669621000098, 9.21220936100022],
            [129.708993621000246, 9.20995536100007],
            [129.710427621000264, 9.203072361000068],
            [129.710751621000242, 9.200818361000259],
            [129.712186621000143, 9.193935361000257],
            [129.71250962100018, 9.191681360000189],
            [129.713944621000081, 9.184798360000187],
            [129.714267621000175, 9.182544360000207],
            [129.715702621000247, 9.175661360000035],
            [129.716023621000346, 9.173421360000148],
            [129.717461621000126, 9.166524360000224],
            [129.717785621000274, 9.164266360000099],
            [129.719219621000121, 9.15738636000016],
            [129.719543621000099, 9.155131360000098],
            [129.72097762100006, 9.14824936],
            [129.721299621000043, 9.146010360000204],
            [129.722737621000221, 9.139112360000198],
            [129.723060621000315, 9.136857360000135],
            [129.724495621000159, 9.129975360000046],
            [129.724816621000258, 9.127735359999974],
            [129.726254621000038, 9.120838360000235],
            [129.726578621000073, 9.118579360000197],
            [129.728012621000204, 9.111699360000088],
            [129.728334621000016, 9.109460360000114],
            [129.729772621000194, 9.102562360000107],
            [129.730093621000293, 9.100323360000132],
            [129.731531621000073, 9.093425360000126],
            [129.731852621000172, 9.091186360000151],
            [129.733290621000123, 9.084288360000144],
            [129.733611621000051, 9.082045360000194],
            [129.735049621000172, 9.075150360000265],
            [129.735371621000155, 9.072910360000023],
            [129.736808621000051, 9.066013360000099],
            [129.737130621000205, 9.063773360000212],
            [129.738568620999985, 9.056876360000118],
            [129.738889621000084, 9.05463636000006],
            [129.740327621000262, 9.047739360000136],
            [129.740646621000366, 9.045512360000089],
            [129.742087621000195, 9.038601360000072],
            [129.742408621000124, 9.036361360000186],
            [129.743846621000074, 9.029464360000276],
            [129.744166621000119, 9.027240360000107],
            [129.745607621000289, 9.02032736000011],
            [129.745928621000218, 9.018087360000223],
            [129.747366621000168, 9.011190360000128],
            [129.747685621000272, 9.008963360000251],
            [129.749126621000158, 9.002052360000249],
            [129.749445621000092, 8.99982836000008],
            [129.750886621000262, 8.992915360000083],
            [129.751208621000302, 8.990675360000196],
            [129.752645621000141, 8.983778360000102],
            [129.752965621000186, 8.981550360000142],
            [129.754406621000015, 8.974640360000222],
            [129.754725621000119, 8.972415360000156],
            [129.756166621000176, 8.965503360000056],
            [129.75648562100011, 8.963278360000174],
            [129.757926621000109, 8.95636636000026],
            [129.758246621000154, 8.954141360000193],
            [129.7596866210001, 8.947229360000094],
            [129.760004621000093, 8.945017360000222],
            [129.761448621000199, 8.93809136000003],
            [129.761767621000303, 8.935866360000148],
            [129.763208621000189, 8.928954360000233],
            [129.763527621000122, 8.926729360000166],
            [129.764968621000349, 8.919817360000067],
            [129.765285621000061, 8.917605360000195],
            [129.766729621000167, 8.910679360000188],
            [129.767049621000211, 8.908454360000121],
            [129.768490621000041, 8.901542360000207],
            [129.76880762100015, 8.899329360000067],
            [129.770251621000256, 8.892404360000143],
            [129.770570621000019, 8.890179360000246],
            [129.772011621000075, 8.883266360000249],
            [129.772327621000301, 8.881057360000085],
            [129.77377262100012, 8.874129360000097],
            [129.774090621000113, 8.87191736000004],
            [129.775534621000219, 8.864991360000204],
            [129.775850621000103, 8.862782360000224],
            [129.777295621000093, 8.855854360000222],
            [129.777612621000202, 8.853644359999976],
            [129.779056621000308, 8.846717360000071],
            [129.779374621000358, 8.844504360000101],
            [129.780818621000122, 8.837579360000177],
            [129.781134621000291, 8.835370360000198],
            [129.782579621000338, 8.828442360000196],
            [129.782894621000111, 8.826245360000144],
            [129.784341621000095, 8.819304360000132],
            [129.784658621000204, 8.81709436000024],
            [129.78610262100014, 8.81016736000015],
            [129.786420621000133, 8.807957360000088],
            [129.787864621000239, 8.801030360000169],
            [129.788179621000012, 8.798832360000205],
            [129.789626621000053, 8.791892360000105],
            [129.789943621000162, 8.789682360000214],
            [129.791387621000268, 8.782755360000124],
            [129.791702621000042, 8.780557359999989],
            [129.793149621000197, 8.773617360000245],
            [129.793464621000311, 8.771422360000173],
            [129.794912621000236, 8.764480360000078],
            [129.79522762100018, 8.762282360000114],
            [129.796674621000165, 8.755342360000199],
            [129.79699162100033, 8.753132360000123],
            [129.798435621000039, 8.746205360000218],
            [129.798751621000093, 8.744007360000069],
            [129.800198621000249, 8.737067359999969],
            [129.800513621000022, 8.734872360000253],
            [129.801960621000063, 8.727930360000173],
            [129.802272621000299, 8.72575136],
            [129.803723621000273, 8.718793360000191],
            [129.804039621000101, 8.716595360000042],
            [129.805486621000085, 8.709655360000127],
            [129.805801621000256, 8.707460360000226],
            [129.807248621000241, 8.700518360000146],
            [129.807563621000185, 8.698320360000181],
            [129.809010621000169, 8.691380360000082],
            [129.809322621000234, 8.689200360000186],
            [129.810773621000209, 8.682243360000101],
            [129.811089621000093, 8.680045360000136],
            [129.812536621000078, 8.673104360000124],
            [129.812848621000313, 8.67092536000014],
            [129.814299621000288, 8.663967360000143],
            [129.814612621000066, 8.661785360000266],
            [129.816062621000157, 8.654829360000264],
            [129.816375621000105, 8.652650360000095],
            [129.81782662100025, 8.645692360000098],
            [129.818141621000194, 8.643494360000133],
            [129.819588621000179, 8.636554360000034],
            [129.819901621000355, 8.634371360000245],
            [129.821351621000218, 8.627416360000154],
            [129.821664621000167, 8.625237360000156],
            [129.823115621000142, 8.618279360000173],
            [129.823428621000318, 8.616096360000029],
            [129.824878621000011, 8.609141360000109],
            [129.825188621000251, 8.606976360000203],
            [129.826642621000161, 8.600004360000128],
            [129.826956621, 8.597821360000154],
            [129.828406621000255, 8.590866360000064],
            [129.828719621000204, 8.588686360000167],
            [129.830169621000124, 8.581729360000253],
            [129.830480621000248, 8.579561360000113],
            [129.831933621000218, 8.572591360000189],
            [129.832247621000107, 8.570411360000122],
            [129.833697621000141, 8.563454360000037],
            [129.834008621000322, 8.561286360000238],
            [129.835461621000292, 8.554316360000144],
            [129.83577462100007, 8.552133360000184],
            [129.837224621000104, 8.545178360000079],
            [129.837534621, 8.543013360000188],
            [129.838989621000309, 8.536041360000098],
            [129.839300621000149, 8.533873360000129],
            [129.840753621000061, 8.526903360000219],
            [129.841063621000359, 8.524738360000143],
            [129.842517621000212, 8.517766360000238],
            [129.842829621000106, 8.515598360000268],
            [129.844282621000019, 8.508628359999989],
            [129.8445936210002, 8.506460360000204],
            [129.84604662100017, 8.499490360000109],
            [129.846356621000069, 8.497325360000033],
            [129.847810621000093, 8.490353360000128],
            [129.848122621000158, 8.488185360000159],
            [129.849575621000128, 8.481214360000152],
            [129.849883621000203, 8.479064360000251],
            [129.851340621000332, 8.472077360000171],
            [129.851651621000116, 8.469908360000119],
            [129.85310562100014, 8.462939360000107],
            [129.853416621000321, 8.460771359999967],
            [129.85486962100029, 8.453801360000043],
            [129.855177621000195, 8.451651360000142],
            [129.856634621000097, 8.444664360000246],
            [129.856943621000113, 8.442511360000097],
            [129.858400621000243, 8.435526360000182],
            [129.858711621000197, 8.43335736000013],
            [129.860164621000166, 8.426388360000118],
            [129.860472621000241, 8.424238360000217],
            [129.861929621000201, 8.417251360000137],
            [129.862238621000159, 8.415098360000158],
            [129.863695621000289, 8.408113360000243],
            [129.864004621000248, 8.405960360000094],
            [129.865460621000096, 8.39897536],
            [129.865768621, 8.396825360000108],
            [129.867225621000301, 8.389838360000198],
            [129.867535621000201, 8.387684360000151],
            [129.868991621000049, 8.380700360000134],
            [129.869300621000235, 8.378546360000087],
            [129.870756621000254, 8.37156236000007],
            [129.871065621000099, 8.369408360000193],
            [129.872522621, 8.36242436000019],
            [129.872828621000309, 8.36028936000011],
            [129.87428862100009, 8.353287360000209],
            [129.874597621000106, 8.351133360000148],
            [129.876053621000295, 8.344149360000145],
            [129.876360621000146, 8.342011360000171],
            [129.877820621000154, 8.335011360000252],
            [129.878129621000284, 8.332860360000097],
            [129.879585621000189, 8.3258743600001],
            [129.879892621000153, 8.323734360000131],
            [129.881352621000161, 8.316736360000036],
            [129.881659621000182, 8.314596360000252],
            [129.883118621000079, 8.307598360000142],
            [129.912981621000228, 8.099498359000194],
            [129.913206621000228, 8.096675359000201],
            [129.914127621, 8.090264359],
            [129.914475621000207, 8.085893359000011],
            [129.915175621000202, 8.081016359000245],
            [129.915523621000233, 8.076645359000253],
            [129.916224621000168, 8.071768359000146],
            [129.916573621000254, 8.067396359000242],
            [129.917272621000194, 8.062520359000217],
            [129.917621621000109, 8.05814735900023],
            [129.918321621000104, 8.053272359000104],
            [129.918670621000018, 8.048897358999966],
            [129.9193696210003, 8.044023359000093],
            [129.919718621000214, 8.039649359000208],
            [129.920418621000096, 8.034775359000164],
            [129.920767621000181, 8.030402359000192],
            [129.921466621000292, 8.025527359000066],
            [129.921815621000206, 8.021168359000171],
            [129.922516621000085, 8.01628035900022],
            [129.922865621000057, 8.011903359000087],
            [129.923565621000222, 8.007031359000209],
            [129.923913621000253, 8.002671359000232],
            [129.924614621000131, 7.997783359000096],
            [129.924963621000046, 7.993407359000045],
            [129.925663621000268, 7.988535359000167],
            [129.926011621000299, 7.984174359000122],
            [129.926712621000178, 7.979287359000068],
            [129.927060621000038, 7.974923359000115],
            [129.927762621000198, 7.970038359000228],
            [129.928110621000229, 7.965676359000099],
            [129.928811621000108, 7.960790359000129],
            [129.929159621000139, 7.956425359000093],
            [129.929861621000299, 7.951541359000117],
            [129.930209621000159, 7.947177359000165],
            [129.930910621000095, 7.942293359000189],
            [129.93125962100018, 7.937929359000236],
            [129.931960621000286, 7.933045359000261],
            [129.93230862100026, 7.92867835900006],
            [129.933010621000079, 7.923796359000249],
            [129.93335862100011, 7.919431359000214],
            [129.934059621000216, 7.914548359000136],
            [129.934408621000301, 7.910180359000037],
            [129.935109621000066, 7.905299359000125],
            [129.935456621000156, 7.900949359000094],
            [129.936159621000257, 7.896051359000197],
            [129.936509621000226, 7.891682359000015],
            [129.937209621000051, 7.886802359000185],
            [129.937556621000198, 7.882451359000257],
            [129.938260621000182, 7.877554359000086],
            [129.938622621000292, 7.873021359000191],
            [129.939299621000174, 7.868304359000163],
            [129.94393662100012, 7.810237359000098],
            [129.489699530857223, 6.670852391428724],
            [128.985949543000146, 6.521357714000061],
            [127.127846254000218, 6.342833571000114],
            [126.60268694200019, 6.044375522000237],
            [126.189071668000253, 5.654907984000076],
            [125.879766658000193, 5.36188218500024],
            [125.582770320000208, 5.159384682000208],
            [125.385723383000169, 5.049731437000119],
            [125.056712212000235, 4.983259761],
            [124.849532764000145, 4.959697203000161],
            [124.135035258000045, 4.862369377000149],
            [124.133500092000219, 4.861128237000131],
            [124.032354192000327, 4.77935453000002],
            [123.169080665000109, 4.287784176000201],
            [122.883567848000183, 3.735909693000124],
            [122.73307160300007, 3.585192098],
            [122.652545697000249, 3.576025311000123],
            [122.638876892000241, 3.577233170000142],
            [121.37479963600012, 3.324840492000106],
            [121.138785297000169, 3.312258950000043],
            [120.480228210000206, 3.116454956999974],
            [120.264643702000313, 3.000332643000107],
            [119.94730941300017, 3.026916159000152],
            [119.467490771000058, 3.182881756000185],
            [119.289776432000139, 3.546517330000128],
            [119.202150171000113, 3.851074050000221],
            [119.075254351000041, 4.314518392000096],
            [119.072114276000178, 4.329987039000201],
            [119.069463368000214, 4.343241575000164],
            [119.066812461000183, 4.363123379000115],
            [119.061510647000176, 4.393608812000053],
            [119.054883379000131, 4.437348780000235],
            [119.048256111000143, 4.479763295000083],
            [119.033676122000173, 4.534106892000182],
            [119.024397946000221, 4.563266871000152],
            [119.013794318000265, 4.583148675000174],
            [119.003190689000149, 4.599054118000097],
            [118.995237967000122, 4.612308654000145],
            [118.987285246000141, 4.628214097000068],
            [118.960776174000188, 4.662675891000134],
            [118.92020030400019, 4.70194852700017],
            [118.91097794200013, 4.714959734000161],
            [118.890391971000128, 4.743558641000149],
            [118.869710152000124, 4.80678601700015],
            [118.876092218000252, 4.813451429000096],
            [118.927044668000093, 4.844311452000213],
            [118.951493819000035, 4.858897523000124],
            [118.967622037000098, 4.868205803000066],
            [118.987509796000069, 4.879769776000217],
            [118.993916980000137, 4.881916319000098],
            [119.000106797000086, 4.883406220000182],
            [119.002236944000202, 4.883898377000136],
            [119.01356537500024, 4.886406231500118],
            [119.025895042000144, 4.888182678000149],
            [119.03149813700017, 4.888506604000128],
            [119.04277755200016, 4.889191157000013],
            [119.045577022000231, 4.889320267000215],
            [119.072524373000107, 4.888470507000079],
            [119.108397873000143, 4.899526363000192],
            [119.121388751000183, 4.903443424000102],
            [119.123873282000289, 4.903910731000224],
            [119.144240783000186, 4.907022219000098],
            [119.147503499000351, 4.907491775000224],
            [119.177441129000073, 4.911883982000106],
            [119.18281946800019, 4.912358314000272],
            [119.1923100220001, 4.915596120000117],
            [119.200164248000164, 4.917790062000108],
            [119.201393133000096, 4.919397395000132],
            [119.223138894000073, 4.944919846000047],
            [119.225268020000186, 4.947386659000145],
            [119.227292020000135, 4.949699771000127],
            [119.245476266000338, 4.969961320000223],
            [119.256622749000115, 4.982018854000088],
            [119.257808901000118, 4.9832523850001],
            [119.260044834000126, 4.98563761600019],
            [119.272701043000239, 4.998615910000211],
            [119.274191820000198, 5.000118541000106],
            [119.280573335000184, 5.006034124000095],
            [119.300443400000148, 5.023692901000217],
            [119.313063124000308, 5.03257052800015],
            [119.316472911000091, 5.035009814000176],
            [119.330860048000176, 5.045649954000183],
            [119.337027604000042, 5.050119781000092],
            [119.339706352000093, 5.051963812000167],
            [119.347479997000136, 5.057206718000089],
            [119.377903437000185, 5.075519252000134],
            [119.394972842000215, 5.084880215000084],
            [119.397496340000288, 5.086230451000247],
            [119.399120379000095, 5.087105319000074],
            [119.40309404700011, 5.088918472000074],
            [119.410161137000074, 5.093549792000204],
            [119.454541436000198, 5.120706014000206],
            [119.455731913000108, 5.121425457000143],
            [119.515976336000193, 5.158835003000121],
            [119.525414862000247, 5.186834122000135],
            [119.526788068000172, 5.191069271000174],
            [119.529045433000164, 5.197081808000092],
            [119.540689425000124, 5.235536561000217],
            [119.541258514000248, 5.237411242000164],
            [119.541916880000173, 5.239530979000136],
            [119.542817850000091, 5.242415457000234],
            [119.546351695000197, 5.253371467000093],
            [119.558425416000176, 5.294801007000146],
            [119.560146602000117, 5.298894222000158],
            [119.563614861000104, 5.307260902000081],
            [119.564381707000138, 5.30916388100016],
            [119.565645837000119, 5.312883377000148],
            [119.566959981000224, 5.316627670000159],
            [119.579123090000195, 5.35158689299999],
            [119.599067316000145, 5.406020355000237],
            [119.604006668000096, 5.421257497000113],
            [119.606910618000114, 5.428956284500146],
            [119.559159891000178, 5.476072087000091],
            [119.53337624100007, 5.501247036000208],
            [119.530564556500138, 5.50409685400011],
            [119.51334152400031, 5.521421098000175],
            [119.502911715000238, 5.531980649000175],
            [119.50215453500013, 5.532752153000146],
            [119.488955472000129, 5.546287345000152],
            [119.461522775000134, 5.574929293000068],
            [119.448670757000258, 5.588931790000103],
            [119.446132967000295, 5.591814365000133],
            [119.414796985000265, 5.630052289000091],
            [119.399676224000103, 5.649427196000104],
            [119.361600870000217, 5.697820417000088],
            [119.345943498000196, 5.71375880400015],
            [119.33542906500017, 5.724725346000255],
            [119.317279873000103, 5.747523331000167],
            [119.28022399600016, 5.794562440000121],
            [119.252226422000177, 5.830315367000068],
            [119.141717414000055, 5.972566737000122],
            [119.103239146000107, 6.022780255000086],
            [119.082912730000345, 6.050249681000068],
            [119.078341544000267, 6.072539653000135],
            [118.989542963000162, 6.042309198000154],
            [118.859626341000165, 5.998409987000116],
            [118.806401279000141, 5.980997725000222],
            [118.768883207000243, 5.972794531999966],
            [118.735151275000106, 5.962136206000054],
            [118.692266229000239, 5.951717847000097],
            [118.663224222000082, 5.94594939300012],
            [118.644679742000136, 5.942438168000123],
            [118.637780491000257, 5.941108794],
            [118.623434521500229, 5.938467404000122],
            [118.590091815000136, 5.936205683000125],
            [118.526804270000156, 5.934873049000174],
            [118.503534299000165, 5.93560112900002],
            [118.484800567000178, 5.936424828000227],
            [118.479510062000116, 5.937497602000178],
            [118.463561104000149, 5.940916774000101],
            [118.461069464000076, 5.941676739000187],
            [118.447003120000176, 5.946288034000077],
            [118.436208630000095, 5.949669350000079],
            [118.423057190000151, 5.954020632000123],
            [118.408161079000166, 5.959144540000153],
            [118.40143846800018, 5.961514206000103],
            [118.366755176000169, 5.956913058000254],
            [118.348495493000172, 5.954334077000127],
            [118.329306469000272, 5.951855587000125],
            [118.314854693000171, 5.949630582000239],
            [118.308003356000228, 5.950977132000105],
            [118.29924202000015, 5.952625749000077],
            [118.28497719300023, 5.955760067000156],
            [118.266026198000219, 5.960877326000173],
            [118.246564719000077, 5.968665495000096],
            [118.241266011000249, 5.970864433999964],
            [118.24044611400015, 5.971254497000174],
            [118.22314168800014, 5.985971735000106],
            [118.200950532000178, 6.013402999000036],
            [118.178655583000051, 6.042269353000137],
            [118.177655812000097, 6.043599247000216],
            [118.176243788000164, 6.053469439000111],
            [118.178849256000177, 6.059012501000097],
            [118.186039231000251, 6.073948092000052],
            [118.186930202000241, 6.075808160000122],
            [118.201216009000262, 6.106706928000079],
            [118.178246848000157, 6.132127915000126],
            [118.162033927000181, 6.149783126000145],
            [118.152036748000256, 6.155958151000092],
            [118.129668777000148, 6.168329026000038],
            [118.123129547000161, 6.172315353000087],
            [118.114875557000204, 6.175675964000234],
            [118.112336849000116, 6.176363643000201],
            [118.108001483000095, 6.177872897000199],
            [118.103584505000214, 6.180025532000144],
            [118.086561104000253, 6.186234958000099],
            [118.085349103000198, 6.186655050000127],
            [118.082914862000138, 6.20225054000008],
            [118.068795742000276, 6.203342343000088],
            [118.062500547000099, 6.203955303000171],
            [118.057498387000152, 6.204344334000126],
            [118.04529278000021, 6.20553064400022],
            [118.031578468000106, 6.207117009000115],
            [117.99845850400007, 6.210230032000027],
            [117.95877922400021, 6.214561038000085],
            [117.955222050000231, 6.21320870800011],
            [117.94550974500018, 6.21250967700017],
            [117.929796821000167, 6.211650579999969],
            [117.880586698000201, 6.21138444800016],
            [117.879533623000128, 6.215314620000171],
            [117.871759616000105, 6.252880436000112],
            [117.868578845000201, 6.270001822000097],
            [117.867570273000212, 6.299982276000108],
            [117.867525943000118, 6.302877158000086],
            [117.867500291000198, 6.304957122000147],
            [117.867500285000034, 6.307462748000063],
            [117.867307099000215, 6.33907099400011],
            [117.867430568000117, 6.343127127000244],
            [117.867722771000302, 6.348782149000073],
            [117.869745339000161, 6.355134542000144],
            [117.872860291000137, 6.36451776400007],
            [117.876720561000155, 6.375168140000028],
            [117.880203832000149, 6.383648347000118],
            [117.894998602000186, 6.420360842000235],
            [117.925974758000137, 6.492152986000207],
            [117.937608613000322, 6.519316354000267],
            [117.961675929000165, 6.548270718000012],
            [118.007501168000232, 6.603000408000256],
            [118.070753130000213, 6.678776698000092],
            [118.028750359000213, 6.765498313000066],
            [118.01410185100022, 6.795381225000071],
            [117.97497116700012, 6.875599069000145],
            [117.937148534000158, 6.952708113000213],
            [117.93769136600028, 6.985219930000227],
            [117.937814612500091, 6.992602033000225],
            [117.933736260000245, 7.021406342000077],
            [117.926431769000118, 7.072305689000117],
            [117.929846095000102, 7.213407199000159],
            [117.933452535000157, 7.379846677000131],
            [117.843878346000309, 7.396811920000161],
            [117.444236363000158, 7.472430393000096],
            [117.416613196000156, 7.477381297000193],
            [117.406362888000359, 7.474477840000119],
            [117.366496742000294, 7.462445754000143],
            [117.337495160000202, 7.453930330000077],
            [117.330618577000138, 7.452106331000238],
            [117.31464847700019, 7.447699079000174],
            [117.304003681000125, 7.444873599000161],
            [117.302983415000114, 7.444544773000132],
            [117.288921098000202, 7.472993900000091],
            [117.284432132000035, 7.4816880770002],
            [117.277013457000209, 7.497349856000127],
            [117.270387890000251, 7.503272016000025],
            [117.256452566000206, 7.51701924600016],
            [117.255538945000097, 7.517925680000118],
            [117.254224108000102, 7.519195715000123],
            [117.253095494000178, 7.520235676000112],
            [117.236856759000119, 7.535030734000074],
            [117.234027904000101, 7.536271513000074],
            [117.190881743000176, 7.555675766000022],
            [117.187650424000168, 7.557120348000183],
            [117.184285896000262, 7.558574900000096],
            [117.080938009000334, 7.602834833000131],
            [117.046353691000121, 7.577912017000173],
            [117.01950555200014, 7.578912705000192],
            [117.016507150000137, 7.579017804000102],
            [117.013508751000103, 7.579090126000125],
            [117.008006485000038, 7.579210764000081],
            [116.973929965000167, 7.582515717999968],
            [116.968492148000252, 7.582982161000103],
            [116.908813574000163, 7.588227807000166],
            [116.788267555000203, 7.594999672000128],
            [116.676519568000259, 7.592436663000242],
            [116.229133875000201, 7.648759371000139],
            [116.162668189000129, 7.653489755000194],
            [116.041076143400261, 7.662437746000165],
            [116.117030054000196, 7.937156869000091],
            [116.188166311000231, 8.197670187000213],
            [116.207539572000286, 8.293066865000128],
            [116.21571048500013, 8.331539739000164],
            [116.2211828800001, 8.357519912000114],
            [116.229309894000181, 8.395797651000066],
            [116.269581095000063, 8.749785644000156],
            [116.281242119000154, 8.85300075100011],
            [116.280561868000262, 8.861691856000135],
            [116.279976217000211, 8.86891835700014],
            [116.279072849000244, 8.876518752000081],
            [116.299402597000125, 8.919132346000055],
            [116.322394994000234, 8.970054138000094],
            [116.445112489000252, 9.242569015000157],
            [116.4642949670002, 9.285214428000273],
            [116.484114727000218, 9.336349397000092],
            [116.501365412000183, 9.38363852800012],
            [116.511410625000082, 9.411984840000102],
            [116.52743647700018, 9.457085597000244],
            [116.599097360000229, 9.56471294400005],
            [116.633576537000266, 9.628048757000144],
            [116.737050505000099, 9.83692412000012],
            [116.769736560000325, 9.913930463000128],
            [116.774669236000051, 9.925819043000047],
            [116.789398581000199, 9.961212441000185],
            [116.894677298000232, 10.072902902000266],
            [117.084824848000125, 10.320299764000126],
            [117.234795633000118, 10.534425219000099],
            [117.256805893000291, 10.574528809000142],
            [117.30634017200012, 10.694111006000099],
            [117.316266889000161, 10.716106305000125],
            [117.354272570000177, 10.836284067000165],
            [117.475603276000044, 11.217709545000162],
            [117.494836782000306, 11.303485176000109],
            [117.502400402000177, 11.363367489000083],
            [117.500763700000192, 11.37775494300017],
            [117.314884130000252, 12.959360479000097],
            [117.179935270000357, 13.32555944400022],
            [116.980505068000156, 13.583928490000204],
            [116.978488542000122, 13.58653851300015],
            [116.759913764000174, 13.869241509000133],
            [116.599013179000252, 14.076759712000168],
            [116.574688785000291, 14.108131595000103],
            [116.605729609000178, 14.242799365000181],
            [116.592795609000092, 14.357385365000127],
            [116.590671609000168, 14.40128736500013],
            [116.539436609000091, 14.563537366000205],
            [116.488953608000287, 14.767770366000022],
            [116.471680608000014, 14.863493366000128],
            [116.452161608000068, 14.925034366000133],
            [116.401428608000316, 15.129238366000223],
            [116.392535608000259, 15.178212366000125],
            [116.360653608000206, 15.306223366000083],
            [116.322976608000289, 15.513100366000188],
            [116.298663608000169, 15.721864367000208],
            [116.287851274666792, 15.931904700333448],
            [116.287885608000153, 15.935124367000142],
            [116.287742608000059, 15.937892367000202],
            [116.289733608000091, 16.088421367000095],
            [116.287139608000047, 16.138514367000042],
            [116.287151608000187, 16.139347367000042],
            [116.28711160800006, 16.1401123670001],
            [116.289854608000184, 16.35021836700021],
            [116.306239608000141, 16.55976036700001],
            [116.336253608000192, 16.7679113680001],
            [116.34000360800033, 16.785637368000025],
            [116.340829608000234, 16.791363368000233],
            [116.342671608000245, 16.800070367999965],
            [116.353336608000205, 16.87399036800015],
            [116.396938608000056, 17.079935368000051],
            [116.398330608000123, 17.084889368000134],
            [116.404354608000261, 17.113331368000118],
            [116.461415608000124, 17.316245368000253],
            [116.463935608000128, 17.323378368000149],
            [116.464275608000179, 17.324587368000167],
            [116.47921560800026, 17.36687036800005],
            [116.552992609000142, 17.420598368000114],
            [116.717968609000053, 17.5345493680001],
            [116.98161360900022, 17.704278368000089],
            [117.432657609000188, 17.993957369000142],
            [117.451343609000247, 18.014331369000033],
            [117.795806610000142, 18.389793369000088],
            [118.098549610000049, 18.751249369000064],
            [118.108881610000054, 18.764546369000087],
            [118.496988609999988, 19.366753370000012],
            [118.839173611000234, 19.894783370000098],
            [118.871415611000231, 19.944672371000038],
            [118.875311611000171, 19.950707371000121],
            [118.877671611000125, 19.954378371000118],
            [119.048240611000068, 20.219612371000025],
            [119.080221611000155, 20.269259371000018],
            [119.105209611000163, 20.268598371000039],
            [119.282566611000078, 20.265436371000064],
            [119.394502611000036, 20.283606371000118],
            [119.709462611000077, 20.353953371000088],
            [119.767536612000214, 20.366913371000081],
            [120.055239612000065, 20.430556371],
            [120.232684612000099, 20.535336371000042],
            [120.464954612000128, 20.715397371000151],
            [120.559561612000181, 20.788833371000138],
            [120.65617661200011, 20.856154371000187],
            [120.935774613, 21.049446372000077],
            [121.033922613000101, 21.11918737200007],
            [121.060725613000187, 21.138117372000224],
            [121.136677613000217, 21.198144372000186],
            [121.231544613000239, 21.304584372000065],
            [121.262252613000243, 21.342120372000196],
            [121.270763613000128, 21.352447372000071],
            [121.603592613000188, 21.474300372000144],
            [121.794441613000259, 21.542999372000068],
            [121.889597614000223, 21.576919372000177],
            [121.947943614000138, 21.597530372000151],
            [122.211068614000141, 21.690443372000075],
            [122.318811614000168, 21.728531372000063],
            [122.936210614000089, 21.947144372000068],
            [122.939706614000244, 21.948371372000068],
            [122.94194861400004, 21.949157372000187],
            [122.944191615000165, 21.949943372000192],
            [122.946576615000055, 21.950777372000104],
            [122.948963615, 21.951612372000099],
            [123.609937251000105, 22.178960317000119],
            [123.790108428000138, 22.080934406000083],
            [124.168431326000103, 21.873127209000117],
            [124.172272680000361, 21.871012556000025],
            [124.729599660000105, 21.563022217000153],
            [124.745787483000214, 21.554049003000031],
            [125.525075928000177, 21.116546850000177],
            [125.537209864000118, 21.109201391000099],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "PLW", Country: "Palau" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [135.27467762600017, 11.376603363000015],
            [135.29051962600019, 11.341208363],
            [135.363374626000251, 11.177716362000112],
            [135.501782626000107, 10.863790362],
            [135.726043626000234, 10.345491362000118],
            [135.928823627000128, 9.061811360000149],
            [136.003945627000093, 8.585375360000128],
            [136.018171627000044, 8.496171360000119],
            [136.018320127000237, 8.495242360000077],
            [136.084539627000112, 8.077761359000135],
            [136.11909362700024, 7.865587359000159],
            [136.148458627000224, 7.617674359000048],
            [136.168496627000223, 7.546022359000034],
            [136.182220627000191, 7.496708358999982],
            [136.186722627000194, 7.477977359000135],
            [136.233065627000116, 7.316405359000115],
            [136.355613627000054, 6.887707358000128],
            [136.642795627000083, 6.014132358000083],
            [136.97550962800014, 5.008350357000069],
            [136.943317628000187, 4.959690357000127],
            [136.81165362700014, 4.811283356000118],
            [136.786334627000173, 4.786095356000075],
            [136.740735627000191, 4.734669356000154],
            [136.626313053000189, 4.620772285000015],
            [136.59201924400017, 4.586636197000175],
            [136.567369169000045, 4.564982223000058],
            [136.472367627000068, 4.481528356000112],
            [136.44620856900022, 4.455462661000098],
            [136.442387374000162, 4.451655210000013],
            [136.284873440000098, 4.313145522000013],
            [136.119086163000162, 4.184761848000122],
            [136.114894952000128, 4.181915307000139],
            [136.11423962700016, 4.181470356],
            [136.105892471500198, 4.175029795000015],
            [136.103835524000175, 4.173632476000137],
            [136.10215744100006, 4.172333348000024],
            [135.928744844000249, 4.054567610000163],
            [135.864351334000133, 4.016481236000047],
            [135.748372626000247, 3.947884356000131],
            [135.745914479000163, 3.9464301984287],
            [135.705986825000224, 3.951047215000173],
            [135.567025824000183, 3.947852797000181],
            [134.694819377000186, 3.443163018000121],
            [133.880094103000062, 2.96883258400014],
            [133.850249374000185, 2.933278317000102],
            [132.92644367400024, 1.811272240000235],
            [132.800614750000278, 1.648712881000137],
            [132.246119263000224, 1.796852927000089],
            [131.315929649000196, 2.044195140000127],
            [130.121637871000274, 1.990805497000096],
            [130.121083310000159, 1.991735373000211],
            [130.052634576000145, 2.106233631000165],
            [129.946745909000214, 2.635486752000119],
            [129.871606190000279, 3.007800592000095],
            [129.766183207000296, 3.525020506000146],
            [129.62696689100008, 4.50368339500011],
            [129.588384590000118, 4.77271105100003],
            [129.557354879000201, 4.824408321000192],
            [129.578176077000222, 5.938661671000119],
            [129.489699530857223, 6.670852391428724],
            [129.94393662100012, 7.810237359000098],
            [129.989374683000136, 7.850888032000114],
            [130.071735621000158, 7.924571359000069],
            [130.237200621000142, 8.054782359000171],
            [130.410624621000153, 8.174348360000039],
            [130.591323622000203, 8.28279236],
            [130.778585692000178, 8.37967739000014],
            [130.971671622000059, 8.46461036000008],
            [130.972817369500234, 8.465048226000064],
            [130.973676857000243, 8.465426065000074],
            [131.171084818000139, 8.537796249],
            [131.218472409000157, 8.648938011000155],
            [131.242258622000037, 8.704725360000154],
            [131.270848189000134, 8.761526423],
            [131.336858744000125, 8.892673984000155],
            [131.406788191000061, 9.012131893000145],
            [131.443194622000163, 9.074323360000136],
            [131.560863623000131, 9.248954361000017],
            [131.563649623000146, 9.252572361000148],
            [131.565972623000249, 9.259124361000119],
            [131.568239623000153, 9.26442536100005],
            [131.585582624000182, 9.313333360000087],
            [131.5920170750002, 9.328378846],
            [131.668303899000165, 9.506757116000131],
            [131.734737281000235, 9.638347741000075],
            [131.763146623000154, 9.694620361],
            [131.785567972000166, 9.732806080000159],
            [131.869754049000136, 9.876182008],
            [131.987721075000223, 10.050722227],
            [132.07755071700015, 10.167002838],
            [132.116634601333402, 10.217588995333401],
            [132.117601057000201, 10.21883458500001],
            [132.228601817000168, 10.345100670000122],
            [132.256894623000136, 10.377284362000069],
            [132.261699440000058, 10.382097908000091],
            [132.406064059000158, 10.526723922000073],
            [132.56453205700015, 10.666559439000082],
            [132.662506092000143, 10.742565557000106],
            [132.731676624000187, 10.796226362000013],
            [132.906844624000172, 10.915206362000163],
            [133.089342624000125, 11.023018362000059],
            [133.146023424000219, 11.051854217000127],
            [133.278448846000146, 11.119224062000029],
            [133.473409515000156, 11.203433261000129],
            [133.6734455160001, 11.275301252000162],
            [133.877754776000216, 11.33453124],
            [133.930282430000062, 11.34624954900012],
            [134.085815135000161, 11.380927308000025],
            [134.086733709000242, 11.381072903000074],
            [134.089471010500205, 11.381683403000054],
            [134.139258086000183, 11.38956178400008],
            [134.299915041000162, 11.414969372000087],
            [134.5120447460001, 11.435021137000149],
            [134.725072845000142, 11.441767356000113],
            [134.738919972000218, 11.441338931000189],
            [134.938132626000225, 11.435175363000027],
            [135.150354626000166, 11.415263363000165],
            [135.277609626000213, 11.395222363000101],
            [135.27467762600017, 11.376603363000015],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "PNG", Country: "Papua New Guinea" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [151.480530641000286, 1.578431353000099],
            [151.853225641000193, 1.022120353000219],
            [152.680823642000149, -0.247478647999884],
            [152.915090642000251, -0.426841647999865],
            [153.598081643000199, -0.806203648999841],
            [153.920269643000182, -0.9852766489999],
            [155.637883645000187, -1.123180648999877],
            [155.847690645000256, -1.140973648999832],
            [156.824362646000196, -1.167572648999922],
            [157.274430646000127, -1.180216648999902],
            [157.439803647000161, -1.414809648999878],
            [157.521806647000147, -1.422080649],
            [157.814437647000062, -1.500717648999938],
            [158.195295647000222, -1.323142648999919],
            [158.756025648000247, -1.172627648999836],
            [158.796326648000189, -1.169073648999856],
            [158.810861648000042, -1.165166648999886],
            [159.38913864800017, -1.113954648999908],
            [159.389884148000164, -1.11397614899991],
            [159.968505649000207, -1.163614648999911],
            [160.529537650000151, -1.312628648999947],
            [161.056316150000185, -1.55648064999987],
            [161.05731165000023, -1.55694365],
            [161.059714650000245, -1.558614649999868],
            [161.062288650000227, -1.559805649999831],
            [161.538930650000367, -1.891094649999928],
            [161.588161650000217, -1.939957649999883],
            [161.590857650000174, -1.941829649999875],
            [161.591761650000166, -1.942726649999841],
            [161.592853650000308, -1.94348464999986],
            [161.595264650000132, -1.945877649999872],
            [161.597850650000197, -1.947672649999888],
            [161.599526651000218, -1.949335649999909],
            [161.601841651000115, -1.950942649999917],
            [162.014063651000043, -2.359615649999924],
            [162.014861651000246, -2.360655149999857],
            [162.018163651000322, -2.365236649999858],
            [162.020061651000191, -2.3671166499999],
            [162.020858651000225, -2.368154649999894],
            [162.022232651000223, -2.370009649999901],
            [162.023067651000048, -2.370836649999845],
            [162.358399651000269, -2.844599650999953],
            [162.358726651000126, -2.845292650999909],
            [162.359408651000052, -2.846255650999922],
            [162.359852651000182, -2.847192650999915],
            [162.36051015100017, -2.848196150999897],
            [162.364409651000329, -2.853774650999881],
            [162.364737651000297, -2.854467650999837],
            [162.365419651000053, -2.85543065099985],
            [162.365777651000172, -2.856187650999871],
            [162.366413651000045, -2.85708565099992],
            [162.614715651000182, -3.381649650999918],
            [162.619721651000106, -3.399825150999902],
            [162.623133651000188, -3.412186650999871],
            [162.624827651000118, -3.415758650999933],
            [162.625779651000101, -3.419231650999876],
            [162.628853651000242, -3.425716650999888],
            [162.782583652000227, -3.985258651999857],
            [162.80194465200023, -4.142222651999816],
            [160.480277649000158, -4.751388651999918],
            [158.235277647000231, -4.871666652999906],
            [156.035833645, -6.549999653999905],
            [156.039722645000069, -6.658333653999819],
            [155.923055645000119, -6.847222653999907],
            [155.693610645, -6.925833654999906],
            [155.508333645000079, -6.958293654999892],
            [155.112222644000127, -7.246666654999871],
            [154.585555644000038, -8.134999655999849],
            [157.16111064600031, -11.373888658999846],
            [157.600833647000258, -12.436388659999949],
            [157.038333333000224, -14.067777777999879],
            [156.61773330000014, -14.081808299999921],
            [154.251066700000109, -14.748474999999857],
            [152.11773330000014, -14.631808299999889],
            [148.08441110000021, -13.1734861],
            [147.66772785300023, -12.93819871999986],
            [147.142744400000225, -12.640152799999882],
            [146.501077800000161, -12.331819399999858],
            [144.734444400000115, -9.848519399999844],
            [144.656780978000114, -9.792372755999963],
            [144.252321268000202, -9.499378120999864],
            [144.251111100000259, -9.498519399999864],
            [143.801102800000166, -9.365166699999875],
            [143.501102800000268, -9.3985],
            [143.334436100000147, -9.548499999999834],
            [143.330714220000203, -9.548501319999858],
            [143.248464917000177, -9.548520251999904],
            [143.195723972000366, -9.548522180999896],
            [143.084436100000261, -9.548499999999834],
            [143.040918113000174, -9.609437766999918],
            [143.00860196700026, -9.654671932999918],
            [143.001102800000098, -9.665166699999887],
            [142.950799629000159, -9.667968625999904],
            [142.851102800000177, -9.673499672285601],
            [142.851102780000161, -9.39294443999988],
            [142.851146416000148, -9.391362380999894],
            [142.851483627000306, -9.389293243999845],
            [142.85170767300022, -9.387435270999859],
            [142.8518618800002, -9.385570298999824],
            [142.851946034000292, -9.383700917999874],
            [142.851960021000281, -9.38182972099986],
            [142.851949017000123, -9.380098859999919],
            [142.851877974000189, -9.378369402999908],
            [142.851746978000193, -9.376643403],
            [142.851556185000305, -9.374922908999935],
            [142.85140266300013, -9.373204833999893],
            [142.851189842000252, -9.37149300499982],
            [142.850917972000133, -9.369789441999913],
            [142.850587375000231, -9.368096151999879],
            [142.850198442000277, -9.366415130999883],
            [142.849751633000182, -9.364748360999897],
            [142.849247474000293, -9.363097807999935],
            [142.848686563000371, -9.361465415999874],
            [142.848250131000128, -9.360102174999881],
            [142.847774794000344, -9.358751828999885],
            [142.847260942000247, -9.357415469999893],
            [142.846646401000129, -9.35565051999987],
            [142.845966235000361, -9.353909474999881],
            [142.84522138700018, -9.352194740999849],
            [142.844412886000356, -9.350508686999873],
            [142.843541852000129, -9.348853643999888],
            [142.842609488000306, -9.347231896999915],
            [142.841617083000216, -9.345645688999937],
            [142.840566011000107, -9.344097209999902],
            [142.839457723000208, -9.342588600999861],
            [142.838293753000045, -9.341121945999831],
            [142.837075708000071, -9.339699270999901],
            [142.835805273000204, -9.338322542999848],
            [142.83448420500028, -9.336993661999855],
            [142.833128053000195, -9.335646760999879],
            [142.831721473000158, -9.334351890999855],
            [142.830266488000206, -9.333110912999842],
            [142.828765189000165, -9.331925610999917],
            [142.82721973500017, -9.330797688999851],
            [142.825632348000198, -9.329728767999868],
            [142.824005309000341, -9.328720383999837],
            [142.822340959000115, -9.327773986999873],
            [142.820641689000098, -9.326890936999902],
            [142.81890994300025, -9.326072503999868],
            [142.817148209000123, -9.325319863999852],
            [142.815359020000159, -9.324634098999866],
            [142.813544949000089, -9.324016193999896],
            [142.811708602000266, -9.323467037999848],
            [142.809852619000111, -9.322987419999862],
            [142.807979668000115, -9.322578028999828],
            [142.806303205000148, -9.322351049999881],
            [142.732491670000087, -9.336277779999861],
            [142.696380560000165, -9.337666669999933],
            [142.592491670000157, -9.355444439999872],
            [142.558880560000119, -9.374333329999914],
            [142.52582500000014, -9.361833329999939],
            [142.49572563000018, -9.366199544999887],
            [142.345803465000103, -9.266258084999905],
            [142.275013890000224, -9.229891669999901],
            [142.236680560000224, -9.191280559999896],
            [142.216125000000119, -9.18794721999987],
            [142.172791670000294, -9.197947219999847],
            [142.143625000000185, -9.196002779999901],
            [142.108069440000151, -9.21239166999986],
            [142.05945833000024, -9.260826704999928],
            [142.059458300000159, -9.7551694],
            [142.001125000000258, -9.765169399999877],
            [141.945360958000066, -9.799384311999944],
            [141.660231832000164, -9.974074698999942],
            [140.001119400000192, -10.981808299999869],
            [139.384452800000162, -11.148474999999891],
            [139.2011408380001, -10.831851914999916],
            [139.600000000000165, -10.4],
            [140.483333000000158, -9.86667],
            [140.825000000000131, -9.40833],
            [140.866667000000149, -9.383332999999894],
            [141.016699218387856, -9.140007545412843],
            [141.01944400000022, -9.135555999999852],
            [141.012118999095122, -9.135112472927091],
            [141.007018185550038, -9.128463484662234],
            [141.006136241854648, -6.904781897707494],
            [141.006136241854648, -6.893281926518668],
            [140.99453619074157, -6.896664024459483],
            [140.98121818474894, -6.9065268423155],
            [140.949400312568031, -6.903608934173903],
            [140.902772287986892, -6.855835941615851],
            [140.875245279691541, -6.796108846353647],
            [140.871063380547724, -6.786390867784945],
            [140.859409349977994, -6.728608876978996],
            [140.85885413271015, -6.678335897972872],
            [140.86300920976376, -6.631390869812847],
            [140.893863330714879, -6.60097294310421],
            [140.947209283071714, -6.499999843304948],
            [140.951082392902123, -6.480555001350126],
            [140.947191345798927, -6.470691010027537],
            [140.928036182418651, -6.450835790091958],
            [140.932191259472432, -6.434445984215031],
            [140.967054277085509, -6.336872919629329],
            [140.985509216272504, -6.333054962722258],
            [141.006136241854648, -6.332926887241371],
            [141.006100367308846, -5.999999871477442],
            [141.006100367308846, -4.905554989510563],
            [141.006100367308846, -2.613890854780109],
            [141.002472349276132, -2.607081323345113],
            [141.0, -2.593611],
            [141.025000000000233, -2.141666999999899],
            [141.116094331926519, -1.905304959656291],
            [141.275423711768127, -1.491894357098644],
            [141.401741712000074, -1.164138170999877],
            [141.402139160000189, -1.16310691749986],
            [141.457682443000095, -1.018989187999878],
            [141.004388605000059, 0.106551654000143],
            [140.892636162000173, 0.491368749000188],
            [140.81052588000037, 0.774113712000087],
            [140.803522061000223, 0.997822216000174],
            [140.801714135000196, 1.085700884000119],
            [140.801642611000119, 1.089177419000094],
            [140.801432586000232, 1.099386233000118],
            [140.832305631000025, 1.120938353000241],
            [140.876207631000142, 1.141326353000139],
            [140.907047631000211, 1.162859353000101],
            [140.950983631000355, 1.183266353000164],
            [140.981793631000329, 1.204780353000146],
            [141.025762631000163, 1.225205353000092],
            [141.056541631000044, 1.246700353000108],
            [141.100544631000076, 1.267144353000035],
            [141.131291631000153, 1.288620353000255],
            [141.175327631000044, 1.309083353000148],
            [141.206045632000183, 1.330541353000115],
            [141.250113632000222, 1.351021353000178],
            [141.280801632000049, 1.372461353000261],
            [141.324901632000064, 1.392959353000023],
            [141.355560632000191, 1.414382353000121],
            [141.371653632000147, 1.421862353000137],
            [141.382828632000127, 1.429671353000202],
            [141.909156632000332, 1.674452352999978],
            [141.958804632000039, 1.687703354000092],
            [141.961638632000046, 1.689022354000159],
            [142.039812632000093, 1.709889354000083],
            [142.044253632000022, 1.711956354000222],
            [142.122454632000228, 1.732833354000093],
            [142.12687063200022, 1.734889354000202],
            [142.20509863200013, 1.75577635400019],
            [142.209489632000185, 1.757820354000202],
            [142.287744633000131, 1.77871835400002],
            [142.292110633000192, 1.780751354000017],
            [142.370391633000253, 1.801658354000224],
            [142.374733633000318, 1.803680354000193],
            [142.433923633000148, 1.819491354000121],
            [142.437190633000085, 1.82101135400012],
            [142.455391633000175, 1.825874354000135],
            [142.457313633000098, 1.826768354000208],
            [142.531896633000059, 1.846694354000078],
            [142.539754633000115, 1.850353354000134],
            [142.614362633000184, 1.870288354000223],
            [142.622197633000241, 1.87393635400008],
            [142.696830633000076, 1.893880354000018],
            [142.70464263300002, 1.897518354000113],
            [142.77930063300019, 1.917471354000085],
            [142.787089633000193, 1.921099354000063],
            [142.861772633000243, 1.94106135400024],
            [142.869538633000303, 1.944678354000203],
            [142.94424663300012, 1.964650354000142],
            [142.951989633000011, 1.968257354000158],
            [143.026721633000051, 1.988237354000063],
            [143.034442633000111, 1.991834354000133],
            [143.109198633000204, 2.011823354000242],
            [143.116898633000091, 2.01541035400021],
            [143.191677633000126, 2.035408354000168],
            [143.199355633000181, 2.038985354000019],
            [143.251281633000104, 2.05287335400017],
            [143.256598633000209, 2.055351354000209],
            [143.712816634000177, 2.177407354000223],
            [143.755076634, 2.197105354000058],
            [143.76847663400028, 2.200691354000114],
            [143.833801634000196, 2.231141354000144],
            [143.847222634000076, 2.23473335400017],
            [143.912528634000211, 2.265176354000161],
            [143.925971634000149, 2.268774354000143],
            [143.991259633999988, 2.299211354000178],
            [144.00472263400016, 2.302815354000117],
            [144.069993634000042, 2.333245354000098],
            [144.083476634000107, 2.336855354],
            [144.148730634000373, 2.367278354000121],
            [144.151195634, 2.367938354000202],
            [144.163112634000157, 2.373494354000186],
            [144.724076635000216, 2.523733354000242],
            [145.302687635000069, 2.57430435400012],
            [145.881305636000121, 2.523658354000077],
            [146.442288636000143, 2.373337354000157],
            [146.499255636000129, 2.346769354000159],
            [146.506362636000205, 2.344865354000177],
            [146.576073637000178, 2.312355354000232],
            [146.584753637000205, 2.310030354000233],
            [146.654478636999983, 2.277514354000147],
            [146.663141637000223, 2.275194354000206],
            [146.700200637000279, 2.257913354000166],
            [146.704796637000101, 2.256682354000091],
            [147.231034637000192, 2.011325354000249],
            [147.706577638000255, 1.678376354000207],
            [147.828418638000215, 1.55653035300007],
            [147.858504638000198, 1.570574352999984],
            [147.868185638000256, 1.573173353000257],
            [147.936950638000184, 1.605273353000214],
            [147.946640638000275, 1.60787535300004],
            [148.015399638000275, 1.639972353000104],
            [148.02509863800006, 1.642576353000081],
            [148.093852638000186, 1.674670353000252],
            [148.10355863800018, 1.677276354000142],
            [148.172307638000262, 1.709367354000236],
            [148.18202163800018, 1.711975354000202],
            [148.250765638000161, 1.744063354000232],
            [148.260486638000174, 1.746674354000078],
            [148.329227638000106, 1.778759354000044],
            [148.338912638000153, 1.781360354000128],
            [148.407358638000375, 1.813307354000216],
            [148.968591639000095, 1.964019354000158],
            [149.547429639000114, 2.01511335400015],
            [150.126239640000193, 1.965078354000212],
            [150.687403640000213, 1.815494354000165],
            [151.480530641000286, 1.578431353000099],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "POL", Country: "Poland" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.92575, 55.879799999000127],
            [18.936666667000139, 55.849999999000048],
            [19.06333333300006, 55.346666666000132],
            [19.345, 54.814999999000165],
            [19.315000000000168, 54.669999998999955],
            [19.358722584000049, 54.639172947000176],
            [19.397531291000064, 54.614061431000053],
            [19.452320053000221, 54.577535590000096],
            [19.513957410000131, 54.538726883000081],
            [19.607554879000105, 54.474806661000045],
            [19.621122897000163, 54.466862900000123],
            [19.627254045142593, 54.463273254901097],
            [19.651109109213451, 54.455827107138262],
            [19.766303624000216, 54.441392580000169],
            [19.791086352000207, 54.438532978000083],
            [19.797009257270901, 54.437544995144989],
            [19.857700224501912, 54.429909089118937],
            [20.004163254066015, 54.41915410148637],
            [20.090554021594244, 54.418600057684969],
            [20.158609208236612, 54.411936109365755],
            [20.301391241191936, 54.397773207530619],
            [20.329082199513749, 54.39383605995998],
            [20.331182201541225, 54.39352710300814],
            [20.371663273592389, 54.387773261738303],
            [20.432773215244424, 54.381664195410835],
            [20.738882164085453, 54.358044998024823],
            [20.898054170555696, 54.356100061206647],
            [21.126382242834524, 54.341664244603223],
            [21.181936156132025, 54.336382136845444],
            [21.231936052731953, 54.332773224604225],
            [21.283609144848526, 54.329991103294347],
            [21.367218126342863, 54.32721803443998],
            [21.411945135278529, 54.325827141423233],
            [21.442491137468039, 54.325554059016937],
            [21.524163228771329, 54.326945119671862],
            [21.570436195932757, 54.328209110674223],
            [21.577518233583675, 54.328400218067159],
            [21.625554082626024, 54.32971802088808],
            [21.731382148396222, 54.330835999136795],
            [21.855554176954911, 54.3316641311729],
            [21.98193617811134, 54.332773224604225],
            [22.165000129097706, 54.334991076190519],
            [22.26555413373697, 54.338045106440191],
            [22.296154115383132, 54.339909074073418],
            [22.42972711893168, 54.345545065784322],
            [22.455554109620351, 54.345545065784322],
            [22.630000123013843, 54.348873184268527],
            [22.664163251709795, 54.351109140765814],
            [22.766663198995701, 54.359718025903177],
            [22.785882232478428, 54.36383605494477],
            [22.812218172329295, 54.395964225182254],
            [22.835136139693702, 54.405127154121132],
            [22.863191207890537, 54.408318144668939],
            [22.995063355642088, 54.385754061257714],
            [23.115827134722338, 54.304436021545584],
            [23.204445141568328, 54.287218083632368],
            [23.333054046766648, 54.247218132824955],
            [23.356109142067339, 54.235409037046097],
            [23.457773245966308, 54.174164146752631],
            [23.484436080041746, 54.138327151987838],
            [23.494163278704207, 54.117209114516399],
            [23.517500173590435, 54.038745112843415],
            [23.508054103961456, 53.960544973483238],
            [23.504036154844044, 53.947045080191103],
            [23.502782222125489, 53.942491192183169],
            [23.503891147918722, 53.921382207167241],
            [23.510009099063524, 53.899318020462701],
            [23.539718084399709, 53.8408270882842],
            [23.580554046232265, 53.731100096422139],
            [23.590445194921074, 53.694427090632232],
            [23.592773184715526, 53.685273214148751],
            [23.608609114429214, 53.637500053952621],
            [23.617218167204669, 53.614018152141099],
            [23.64027326250536, 53.559154069476833],
            [23.673536174797846, 53.493745049674246],
            [23.700554066292909, 53.453045042311416],
            [23.785918218317249, 53.314336111396713],
            [23.858609100342818, 53.195827064276997],
            [23.933191275006266, 53.012082167474901],
            [23.92749124555499, 52.948373165279079],
            [23.931382292658014, 52.858609184627525],
            [23.938663316690764, 52.774709183454078],
            [23.939718095389281, 52.770273145005049],
            [23.941100271226702, 52.74971803615243],
            [23.935273172122834, 52.717491127095442],
            [23.911800155128731, 52.693182099076196],
            [23.883882214868066, 52.678045052451338],
            [23.746800211364302, 52.614645007207272],
            [23.715827067387693, 52.615954092848952],
            [23.688327216458731, 52.617209199033979],
            [23.655273181194019, 52.610273174136964],
            [23.633609146548167, 52.605553994997862],
            [23.59721810798186, 52.596382181241452],
            [23.52305402265003, 52.573609053164475],
            [23.503609180695122, 52.567500154474985],
            [23.417218077890794, 52.525273131987589],
            [23.397218186306077, 52.514445054159083],
            [23.377491209489477, 52.498327157221965],
            [23.248382239946579, 52.374482190525825],
            [23.165400224811407, 52.282273208711345],
            [23.192773173725868, 52.233191130511713],
            [23.214718169767025, 52.223464267125493],
            [23.297773275098052, 52.21166422380206],
            [23.348327215499381, 52.206791152920388],
            [23.595000256395394, 52.111936004266042],
            [23.638609119444226, 52.079436012802645],
            [23.660827197891592, 52.006100059506807],
            [23.626800191303573, 51.952073161334155],
            [23.61248222425931, 51.91595403170767],
            [23.558054168199476, 51.752491161351699],
            [23.547773261011855, 51.686382084993895],
            [23.555482265021936, 51.66520017742009],
            [23.534991193909661, 51.653591073851587],
            [23.539163202407792, 51.592764272889823],
            [23.567173175965621, 51.539600207832237],
            [23.60463609231283, 51.527691199767403],
            [23.61409121439732, 51.498627118063482],
            [23.643636082068809, 51.485009039936173],
            [23.692773313191452, 51.402354086663948],
            [23.681245178807814, 51.369300051399151],
            [23.683882293192283, 51.288609145684475],
            [23.731936079507648, 51.214718142758741],
            [23.756663196858398, 51.199436089208476],
            [23.811109190191075, 51.168882208029842],
            [23.905000252339619, 51.068054115156073],
            [23.93166325405312, 50.994073258228056],
            [23.96513604920176, 50.950409074617923],
            [23.988327098972263, 50.931109071950416],
            [24.034300161637447, 50.898254023067679],
            [24.061500108070049, 50.887527030991734],
            [24.090273170094946, 50.881936133920206],
            [24.14347327733617, 50.859582101002857],
            [24.131036209369739, 50.83818209630779],
            [24.076945105818453, 50.829436083234029],
            [24.05250012332931, 50.831382193518678],
            [24.021891256865729, 50.83175401474459],
            [23.980963261735923, 50.829582095987732],
            [23.958400184153078, 50.815200091202954],
            [23.954382235035666, 50.791700084480325],
            [24.014718191746255, 50.739991117818406],
            [24.07221821532849, 50.695827037501672],
            [24.10721819323976, 50.633609175884956],
            [24.108409094046351, 50.625900171874875],
            [24.111382155111073, 50.566935997441661],
            [24.00221809186769, 50.414435985917819],
            [23.983191171606194, 50.405964228716655],
            [23.929436182373166, 50.403600196738282],
            [23.881500245617076, 50.405409179086732],
            [23.846945179127545, 50.406654059349975],
            [23.814445187664262, 50.405818048324946],
            [23.791945141993381, 50.402491103307156],
            [23.755836070650787, 50.394436094333187],
            [23.717591122734888, 50.383836004271657],
            [23.697109104078123, 50.370118181496352],
            [23.684173312871394, 50.333700153201832],
            [23.591109209292654, 50.269153966514594],
            [23.568882246027783, 50.255827075704488],
            [23.540273301667526, 50.242782152118011],
            [23.496109221350792, 50.220827097793048],
            [23.378327220514507, 50.149718048538986],
            [23.34282717825846, 50.1274910852744],
            [23.324436109173575, 50.115273120257612],
            [23.303609091381247, 50.100827077732376],
            [23.22819124096884, 50.046664225089714],
            [23.146109106961148, 49.983045076896204],
            [23.110827161826165, 49.954991182165728],
            [22.780000148089613, 49.675000076054161],
            [22.717218184387605, 49.604436018146188],
            [22.686063320749611, 49.577091064788178],
            [22.678463281481299, 49.569436040234919],
            [22.656873175221619, 49.529854011120833],
            [22.69443617149301, 49.450827080829029],
            [22.732791257616896, 49.397209051894194],
            [22.758818240515808, 49.285900059622449],
            [22.726663248188089, 49.217764239071201],
            [22.703809150926247, 49.169891166588897],
            [22.778054037804964, 49.150545063453691],
            [22.852773172766717, 49.105827106973535],
            [22.876518098700643, 49.087418100616006],
            [22.863500164842662, 49.04982710878771],
            [22.886073300709313, 49.002918122811408],
            [22.863054079954253, 49.003045024825823],
            [22.737773293240309, 49.047218157598238],
            [22.59414519097615, 49.091536129657513],
            [22.568473265496777, 49.087909112505471],
            [22.558054056906286, 49.079436014199743],
            [22.537773203926349, 49.087918164960783],
            [22.348891206291711, 49.138473111190521],
            [22.323891174172644, 49.138609065660447],
            [22.225000139128042, 49.154718077780231],
            [22.029891225606832, 49.220236062324503],
            [22.025273299858355, 49.247218079273935],
            [22.020136199025927, 49.273882086815846],
            [21.958609173870741, 49.340273130397364],
            [21.838054104180287, 49.384436037247596],
            [21.612636061053934, 49.436527051057496],
            [21.53360913076213, 49.429718095812987],
            [21.500827172075333, 49.422218136468658],
            [21.459300206142956, 49.411945108270046],
            [21.436391123596081, 49.413891218554895],
            [21.400845148510314, 49.429054081441492],
            [21.28194516942537, 49.456391155810493],
            [21.071527212625767, 49.422082181998846],
            [21.050554014441559, 49.41054499515981],
            [21.035554095752985, 49.35971813999025],
            [20.982354156149853, 49.309436108528629],
            [20.955827108905964, 49.301664240244676],
            [20.913609138874079, 49.296109050080631],
            [20.812500252242984, 49.330827060768272],
            [20.740836153359027, 49.388882133980346],
            [20.602773299543003, 49.39555396128857],
            [20.360554066730572, 49.393053974840583],
            [20.327773281510247, 49.383327111454363],
            [20.206663329827961, 49.340273130397364],
            [20.14360912191006, 49.314991131054782],
            [20.103336088696153, 49.248600087473264],
            [20.09263608634862, 49.204854096488148],
            [20.073363241047446, 49.177873253005188],
            [20.039163231977284, 49.188891097122067],
            [20.020554065771421, 49.199436034260387],
            [19.998900257047467, 49.217627111134831],
            [19.979582149468968, 49.226391061481408],
            [19.936391208113463, 49.231109067153952],
            [19.911245163240892, 49.226173131998237],
            [19.867909214960179, 49.197127155205251],
            [19.842918235296622, 49.191800120446246],
            [19.783473274896011, 49.200200128555949],
            [19.765973202121387, 49.214436120586939],
            [19.773609115935614, 49.233054003972327],
            [19.798054098424757, 49.252773101799889],
            [19.825654029277985, 49.277564256891097],
            [19.805000181605664, 49.364718112886507],
            [19.778018164656231, 49.407491132548316],
            [19.71055406982731, 49.397500071573305],
            [19.65860923640912, 49.406664173978683],
            [19.577082152031238, 49.45901804427298],
            [19.535273218875346, 49.535554040572023],
            [19.520982241559324, 49.57416427182936],
            [19.475554170240144, 49.600000147335251],
            [19.4477731902779, 49.600827105904884],
            [19.26902709290539, 49.526664194039412],
            [19.242363253001457, 49.506800089286529],
            [19.199300219488947, 49.437773104787126],
            [19.191663299846397, 49.414027005386615],
            [19.159582235904935, 49.400273140427586],
            [19.031663328978141, 49.39193599659184],
            [18.974927064415397, 49.401936110022334],
            [18.968327153836526, 49.456382103354983],
            [18.968609121060155, 49.481664270335742],
            [18.851245041917394, 49.517354078880246],
            [18.852218180878793, 49.52777311983256],
            [18.847773257612545, 49.554164212606551],
            [18.83944516623211, 49.594991121983597],
            [18.810827169416342, 49.673327048175693],
            [18.786945115546132, 49.681936100951233],
            [18.634163304436555, 49.737782207394261],
            [18.579345154601839, 49.814991102882104],
            [18.578745177970887, 49.912218162503834],
            [18.55270914261655, 49.922427152962015],
            [18.350273272233125, 49.938891222501255],
            [18.270273202980007, 49.957773135837343],
            [18.094163214163444, 50.038053998847701],
            [18.05316330230437, 50.055927066314794],
            [18.009445139237783, 50.011109197548549],
            [17.920691177921867, 49.97736415818413],
            [17.876245130381449, 49.978954037582852],
            [17.840136059038741, 49.989582123201004],
            [17.657773170436087, 50.10805395467041],
            [17.606318175440862, 50.162700107851421],
            [17.642773251747514, 50.171945011803771],
            [17.695273302433606, 50.179027049454575],
            [17.763682205391063, 50.209373227071737],
            [17.762918111095388, 50.233327030033522],
            [17.753054119772997, 50.297773136796579],
            [17.724445175412711, 50.319018076282518],
            [17.69700919458603, 50.32027318246746],
            [17.693473204902574, 50.300000208476533],
            [17.661109167909188, 50.272354009517713],
            [17.626800194097541, 50.265545054273204],
            [17.433054010398479, 50.270273118229639],
            [17.378327055670496, 50.279445099624027],
            [17.283609202590497, 50.32027318246746],
            [17.226945022395199, 50.345273214586499],
            [17.20527327639843, 50.36097318983029],
            [17.118054041558196, 50.39666417184138],
            [17.057782290226129, 50.41055399127022],
            [16.941391182406704, 50.43500014722575],
            [16.910827075306287, 50.440127022136508],
            [16.890973196475272, 50.438673097207513],
            [16.8687450597441, 50.411182131095885],
            [16.937500135303793, 50.340554035447482],
            [16.972773195621357, 50.310000154269048],
            [17.002218151006673, 50.216945103145619],
            [16.968609233750129, 50.222773208077783],
            [16.911391177391494, 50.222354112917785],
            [16.846945070628436, 50.201800177531638],
            [16.811245036161949, 50.179436086330682],
            [16.802936055520917, 50.169654070021338],
            [16.786663261012507, 50.140545061316303],
            [16.715345167092778, 50.098327091284219],
            [16.640000239238134, 50.10889113916194],
            [16.614436105033974, 50.119718043523861],
            [16.587773270958337, 50.140000069970299],
            [16.565973281842673, 50.170345074121158],
            [16.562218189209574, 50.208191211082649],
            [16.548054113907966, 50.227082176874134],
            [16.458609148492229, 50.303600068262355],
            [16.441391210579297, 50.316664270226326],
            [16.371945130919897, 50.361109144300102],
            [16.303054100890307, 50.378053999807022],
            [16.266109186160776, 50.389718088660473],
            [16.219027197702445, 50.410273197513092],
            [16.207736103541379, 50.439018096343275],
            [16.315000156967471, 50.504718135824973],
            [16.358918144606321, 50.497909180580351],
            [16.406663309245744, 50.523044999531137],
            [16.447363316608374, 50.578818183416459],
            [16.430273286538153, 50.601527106115],
            [16.370000194101635, 50.644164171306997],
            [16.332009217852828, 50.664027102593408],
            [16.237500241800404, 50.670554090614402],
            [16.137500113324563, 50.656109053917575],
            [16.118054097903183, 50.657800186707036],
            [16.071391204604851, 50.635554112703048],
            [16.024436118160935, 50.630000096005475],
            [15.946945087811372, 50.68943600395049],
            [15.787500166572926, 50.74416413214486],
            [15.606663287266514, 50.772773244143295],
            [15.488336127446246, 50.786664237038522],
            [15.379718229015424, 50.779445071451519],
            [15.362845122599964, 50.840618045015432],
            [15.311663210010948, 50.860345021831947],
            [15.282773304255471, 50.892082092466055],
            [15.272500108418768, 50.924509161371574],
            [15.290418102887031, 50.948953976222739],
            [15.27527317727305, 50.975000069860883],
            [15.239445067325761, 50.991945093005782],
            [15.176945070847268, 51.014718053444781],
            [15.016391223815674, 50.974091136277977],
            [15.019163286841547, 50.950554081543416],
            [15.003891123937194, 50.867500149678605],
            [14.966663236155568, 50.859164179309332],
            [14.828336184750555, 50.865827121800251],
            [14.826182203266541, 50.883054112168665],
            [14.896945079918368, 50.959445101542499],
            [14.931109214442785, 51.002491203610489],
            [14.965554142724244, 51.052218017804236],
            [14.978891259456134, 51.078327143354599],
            [14.99444505430813, 51.118609061385641],
            [15.032218101073767, 51.239164131076095],
            [15.033818206394471, 51.286664208866128],
            [15.002636185390259, 51.316800168351151],
            [14.983336182722581, 51.333609069387975],
            [14.971663209051712, 51.357218040852089],
            [14.981836157326143, 51.368045112852215],
            [14.975554088516645, 51.440827022346539],
            [14.953054042845764, 51.469991183974813],
            [14.921109101012291, 51.481945119040788],
            [14.837500119517841, 51.498600128334985],
            [14.739445095498269, 51.526527121051132],
            [14.71500011300904, 51.554718143717977],
            [14.759236110055127, 51.607491109172187],
            [14.756109157247863, 51.666736077362359],
            [14.722082150659844, 51.690964136196627],
            [14.698054084035817, 51.702218182345675],
            [14.667982162291224, 51.723882216991527],
            [14.600973204805854, 51.82006422092239],
            [14.61013613374493, 51.848191205848607],
            [14.645418078879828, 51.865273189291727],
            [14.68986328822993, 51.896945048719019],
            [14.717554078913651, 51.943109051139047],
            [14.760763292817956, 52.069864214625753],
            [14.707918075358322, 52.245618140194338],
            [14.692500234976222, 52.254436069997567],
            [14.655836114003819, 52.260273227385241],
            [14.598891140051393, 52.272773159625714],
            [14.579863213961602, 52.288327122115604],
            [14.53444503328825, 52.39624513162947],
            [14.543954134829278, 52.42187313593611],
            [14.563191273222884, 52.433191219825389],
            [14.585136101625778, 52.439853994678217],
            [14.633536060543207, 52.49055411547134],
            [14.639582262596718, 52.572982086805197],
            [14.595782292154951, 52.606827038455705],
            [14.554582220447344, 52.627218029643714],
            [14.514445141703334, 52.639164253358857],
            [14.478745107236932, 52.652500196624359],
            [14.44832718052831, 52.675282209518755],
            [14.383336082419049, 52.73082707036086],
            [14.355000220464973, 52.748327143135683],
            [14.205418117082729, 52.81860906618202],
            [14.149163309039693, 52.862782198954065],
            [14.147636126276865, 52.959236111824751],
            [14.16889112404661, 52.973327096930419],
            [14.20028218971666, 52.984718103377574],
            [14.225418176305539, 52.98888223288678],
            [14.293736051794212, 53.018773105522328],
            [14.347363300822536, 53.048882242917202],
            [14.380273167352243, 53.110136018028086],
            [14.39169116352781, 53.144164198082407],
            [14.412773158815526, 53.304445130345997],
            [14.413263332514475, 53.338954096367885],
            [14.309718161899696, 53.555544989597522],
            [14.275627236733328, 53.699064048058617],
            [14.274491347000122, 53.706904977000036],
            [14.219888347000136, 53.862180977999955],
            [14.21974283600008, 53.863177978000024],
            [14.218891042922763, 53.869018121261732],
            [14.215827099751152, 53.899991087289592],
            [14.225554304327886, 53.928600077354801],
            [14.227994443999989, 53.929291666],
            [14.234770917000162, 53.954503273000043],
            [14.244122222000101, 53.989294444],
            [14.229578889000095, 54.040173932],
            [14.204560826000062, 54.127699011000104],
            [14.210894346000089, 54.127636388000084],
            [14.239372529000036, 54.127354638000028],
            [14.287376130000126, 54.145016508000069],
            [14.322594686000087, 54.157974401000089],
            [14.348425844000161, 54.167490340000072],
            [14.352222222000108, 54.168888887999955],
            [14.35660920300009, 54.172690636000013],
            [14.473029813000124, 54.273580482000042],
            [14.475184985000112, 54.275448150000031],
            [14.599677665500082, 54.383333234500057],
            [14.750000000000142, 54.499999999],
            [14.724682930000171, 54.508095502000046],
            [14.715146243000163, 54.51114499700013],
            [14.688589921000045, 54.519636768000098],
            [14.636722379600172, 54.536222170600112],
            [15.605336209000029, 54.960069011000016],
            [15.853153072000111, 55.068508888000181],
            [16.508250000000118, 55.355166666000073],
            [16.533655349000185, 55.360569979000033],
            [17.000000350000164, 55.499999979000066],
            [17.37800035000015, 55.587249979000049],
            [18.000000351000153, 55.783082979000213],
            [18.363333351000108, 55.921549980000137],
            [18.900000351000187, 55.881266979000188],
            [18.92575, 55.879799999000127],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "PRI", Country: "Puerto Rico" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-66.661952561999783, 21.840953372000058],
            [-66.654064561999974, 21.840152372000091],
            [-66.637714561999928, 21.84023737199999],
            [-66.626308561999963, 21.840254372],
            [-66.608403561999978, 21.840212372000053],
            [-66.590499562, 21.840084372000149],
            [-66.572596561999944, 21.839870372000178],
            [-66.554694561999895, 21.839571372000137],
            [-66.536794561999898, 21.839187371999969],
            [-66.518896561999952, 21.838718372000073],
            [-66.51081056199979, 21.838477372000057],
            [-66.496528561999867, 21.838821372000098],
            [-66.478627561999986, 21.839174372000173],
            [-66.469977561999883, 21.83931437200016],
            [-66.464352561999874, 21.839416372000073],
            [-66.446450561999882, 21.839683372000138],
            [-66.428546561999894, 21.839866372000017],
            [-66.41064156199991, 21.839963372000057],
            [-66.399235561999944, 21.83998037200007],
            [-66.38133056199996, 21.83993837200012],
            [-66.363426561999972, 21.839810372000215],
            [-66.345523561999983, 21.839596372000244],
            [-66.327621561999877, 21.839297372000203],
            [-66.309721561999822, 21.838913372000036],
            [-66.291823561999934, 21.83844437200014],
            [-66.273929561999921, 21.837889372000234],
            [-66.256037561999904, 21.837248371999976],
            [-66.238150561999987, 21.836523372000158],
            [-66.220267561999947, 21.835712372000046],
            [-66.202388561999896, 21.834816371999977],
            [-66.184515561999831, 21.833835372000181],
            [-66.166648561999978, 21.832768372000203],
            [-66.154493561999857, 21.831994372000111],
            [-66.138795561999871, 21.83112737200014],
            [-66.120928561999904, 21.830061372000046],
            [-66.117115561999981, 21.829822372000137],
            [-66.107368561999976, 21.829566372000102],
            [-66.102853561999893, 21.829440372000136],
            [-66.084960561999935, 21.828885372000059],
            [-66.067069561999972, 21.828245372000026],
            [-66.049183561999826, 21.82751937200004],
            [-66.031301560999907, 21.826709371999982],
            [-66.013424560999908, 21.825813372000084],
            [-65.995552560999897, 21.824831372],
            [-65.977685561000044, 21.823765372000082],
            [-65.959825560999775, 21.822613371999978],
            [-65.94197256099983, 21.821376371999975],
            [-65.924125560999983, 21.820054372000016],
            [-65.906287561, 21.8186473720001],
            [-65.888456560999941, 21.817155372000116],
            [-65.870634560999918, 21.815578372000175],
            [-65.852821561000042, 21.813916372],
            [-65.842561560999911, 21.812909372000149],
            [-65.841937060999896, 21.809129372000029],
            [-65.83897156099988, 21.792673372000024],
            [-65.835998560999883, 21.776179372000115],
            [-65.833026561, 21.759685372000149],
            [-65.830055560999881, 21.74319137200024],
            [-65.827084560999879, 21.726697372000103],
            [-65.824114560999931, 21.710203372000137],
            [-65.821145560999923, 21.693709372000228],
            [-65.820277560999841, 21.688888372000122],
            [-65.816949561, 21.672454372000175],
            [-65.81362156099982, 21.656020372000171],
            [-65.810294560999978, 21.639586372000053],
            [-65.806968560999906, 21.623151372000052],
            [-65.803642560999776, 21.606717372000048],
            [-65.80031856099987, 21.590282372000217],
            [-65.796993560999908, 21.5738483720001],
            [-65.793670560999942, 21.557413372000042],
            [-65.79034856099986, 21.540978372000211],
            [-65.787026560999891, 21.524543372000153],
            [-65.78370556099992, 21.508108372000095],
            [-65.780385560999946, 21.491673372000093],
            [-65.777065560999915, 21.475237372000151],
            [-65.77374756099988, 21.458802372000093],
            [-65.77042856099996, 21.442367372000035],
            [-65.767111560999865, 21.425931372000093],
            [-65.763795560999881, 21.409495372000151],
            [-65.760479560999954, 21.39306037200015],
            [-65.757164560999911, 21.376624372000038],
            [-65.753850560999922, 21.360188372000096],
            [-65.750536560999763, 21.343752372000154],
            [-65.747223560999942, 21.327315372000101],
            [-65.743911560999891, 21.310879372000159],
            [-65.740600560999894, 21.294443372000046],
            [-65.737289560999898, 21.27800637200005],
            [-65.733979560999899, 21.261570372000108],
            [-65.730670560999897, 21.245133372000055],
            [-65.72736256099995, 21.228696372000059],
            [-65.724054560999889, 21.212260372000117],
            [-65.720747560999882, 21.195823372000064],
            [-65.71744156099993, 21.179386372000067],
            [-65.714135560999921, 21.16294837200013],
            [-65.710831560999964, 21.146511372000077],
            [-65.707527560999893, 21.130074372000081],
            [-65.704223560999765, 21.113637372000028],
            [-65.700921560999973, 21.097199372000091],
            [-65.697619560999897, 21.080761372000154],
            [-65.694318560999932, 21.064324372000158],
            [-65.69101756099991, 21.047886372000221],
            [-65.687718560999826, 21.031448372000114],
            [-65.684419560999913, 21.015010372000177],
            [-65.681120560999886, 20.998572371000193],
            [-65.677823560999911, 20.982134371000086],
            [-65.674999560999879, 20.968055371000105],
            [-65.671656560999907, 20.951625371000091],
            [-65.66831256099988, 20.935195371000134],
            [-65.664970560999848, 20.918765371000177],
            [-65.661628560999873, 20.902335371000163],
            [-65.658287560999838, 20.885905371000206],
            [-65.654947560999858, 20.869475371000249],
            [-65.651608560999875, 20.85304437100018],
            [-65.64826956099995, 20.836613371000112],
            [-65.644931560999908, 20.820183371000098],
            [-65.641594560999806, 20.803752371000087],
            [-65.638257560999932, 20.787321371000189],
            [-65.637222560999874, 20.782222371000159],
            [-65.633598560999872, 20.765845371000125],
            [-65.62997556099981, 20.749468371000091],
            [-65.626353560999917, 20.733091371000057],
            [-65.62273156099991, 20.716714371000023],
            [-65.6191105609999, 20.700336371000105],
            [-65.615490560999945, 20.683959371000242],
            [-65.61187156099993, 20.667581371000097],
            [-65.60825356099997, 20.651204371000063],
            [-65.604635560999895, 20.634826371000145],
            [-65.601018560999876, 20.618448371000056],
            [-65.597402560999797, 20.602070371000082],
            [-65.593787560999829, 20.585692371000164],
            [-65.590172560999861, 20.569314371000189],
            [-65.586559560999888, 20.552936371000101],
            [-65.582946560999915, 20.536557371000072],
            [-65.579333560999885, 20.520179371000154],
            [-65.575722560999964, 20.503800371000125],
            [-65.572111560999872, 20.48742237100015],
            [-65.568501560999948, 20.471043371000178],
            [-65.564892560999965, 20.454664371000149],
            [-65.561284560999923, 20.43828537100012],
            [-65.557676560999823, 20.421906371000091],
            [-65.554069560999835, 20.405526371000178],
            [-65.550463560999901, 20.389147371000149],
            [-65.546858560999965, 20.37276837100012],
            [-65.543253560999915, 20.356388371000037],
            [-65.539649560999919, 20.340008371000067],
            [-65.536046560999921, 20.323629371000038],
            [-65.53244456099992, 20.307249371000125],
            [-65.528842560999919, 20.290869371000213],
            [-65.52524256099997, 20.274489371000129],
            [-65.521642560999794, 20.25810937100016],
            [-65.518042560999959, 20.241728371000193],
            [-65.514444560999891, 20.225348371000052],
            [-65.510846560999823, 20.208967371000028],
            [-65.507249560999924, 20.192587371000116],
            [-65.503653560999908, 20.176206371000092],
            [-65.500057560999892, 20.159825371000125],
            [-65.49646256099993, 20.143445371000155],
            [-65.492869560999907, 20.127063371000077],
            [-65.489275560999772, 20.110682371000053],
            [-65.485683560999973, 20.094301371000086],
            [-65.482091560999947, 20.077920371000232],
            [-65.478500560999919, 20.061538371000154],
            [-65.474910560999888, 20.04515737100013],
            [-65.471320560999914, 20.028775371000222],
            [-65.46773156099988, 20.012394371000028],
            [-65.464143560999958, 19.99601237100012],
            [-65.460556560999919, 19.979630371000042],
            [-65.456969560999823, 19.963248371000134],
            [-65.455833560999906, 19.958055371000114],
            [-65.450699560999851, 19.942046371000174],
            [-65.445566560999964, 19.926036370000247],
            [-65.440435560999902, 19.910026370000026],
            [-65.435304560999896, 19.894016370000145],
            [-65.430174560999831, 19.878006370000094],
            [-65.425046561000045, 19.861995370000159],
            [-65.419918561000031, 19.845985370000108],
            [-65.414791561000015, 19.829974370000173],
            [-65.409666560999938, 19.813963370000181],
            [-65.404541560999917, 19.797952370000246],
            [-65.399418560999948, 19.781941370000141],
            [-65.394295560999922, 19.765929370000094],
            [-65.389174561, 19.749917370000048],
            [-65.384053560999973, 19.733906370000113],
            [-65.37893456099988, 19.717894370000067],
            [-65.373816560999899, 19.701881370000137],
            [-65.368698560999917, 19.685869370000091],
            [-65.36358256099993, 19.669857370000102],
            [-65.358467560999884, 19.653844370000115],
            [-65.353352560999951, 19.637831370000185],
            [-65.34916656099989, 19.6247223700002],
            [-65.340519560999894, 19.610136370000191],
            [-65.331874560999893, 19.595549370000128],
            [-65.323231560999886, 19.580962370000236],
            [-65.314589560999877, 19.566374370000062],
            [-65.305948560999923, 19.551786370000116],
            [-65.297309560999906, 19.537198370000112],
            [-65.288672560999942, 19.522609370000225],
            [-65.280036560999918, 19.508020370000168],
            [-65.271402560999888, 19.493430370000169],
            [-65.262769560999857, 19.478840370000057],
            [-65.25413856099982, 19.464249370000175],
            [-65.245508560999895, 19.449658370000122],
            [-65.236880560999907, 19.43506737000024],
            [-65.228254560999858, 19.420475370000077],
            [-65.219629561000033, 19.405882370000086],
            [-65.211005560999979, 19.391290370000092],
            [-65.202383560999976, 19.376696370000161],
            [-65.193763560999969, 19.362103370000114],
            [-65.185144560999845, 19.347509370000182],
            [-65.176527560999887, 19.33291437000014],
            [-65.167911560999926, 19.318319370000097],
            [-65.159297560999903, 19.303724370000111],
            [-65.150684560999935, 19.289128370000128],
            [-65.142073560999961, 19.274532370000031],
            [-65.133463560999985, 19.259935370000051],
            [-65.12485556099989, 19.245338370000184],
            [-65.116248560999964, 19.230741370000146],
            [-65.107643560999918, 19.216143370000054],
            [-65.102222560999849, 19.206944370000087],
            [-65.098733560999904, 19.190545370000052],
            [-65.095246560999954, 19.17414637000013],
            [-65.091759561, 19.157746370000154],
            [-65.08827356099988, 19.141347370000062],
            [-65.084787560999928, 19.124947370000086],
            [-65.081302560999916, 19.10854737000011],
            [-65.077818560999901, 19.092148370000189],
            [-65.074335560999884, 19.075748370000042],
            [-65.070852560999924, 19.059348370000237],
            [-65.067370560999905, 19.04294837000009],
            [-65.063888560999828, 19.026547370000173],
            [-65.060407561000034, 19.010147370000027],
            [-65.056927560999952, 18.993747370000222],
            [-65.053448560999982, 18.977346370000134],
            [-65.049969560999898, 18.960946370000158],
            [-65.046491560999982, 18.944545370000128],
            [-65.043014560999893, 18.928144370000041],
            [-65.039537560999975, 18.911744370000235],
            [-65.036061560999883, 18.895343370000148],
            [-65.032586560999846, 18.878942370000118],
            [-65.029111560999922, 18.862541370000031],
            [-65.025637560999826, 18.846139369000184],
            [-65.022164560999954, 18.829738369000097],
            [-65.018691560999798, 18.81333736900018],
            [-65.015220560999978, 18.796935369000039],
            [-65.01174856099999, 18.780534369000179],
            [-65.008278560999884, 18.764132369000038],
            [-65.006111560999898, 18.753888369000094],
            [-65.002756560999956, 18.737465369000176],
            [-64.999403560999781, 18.721041369000147],
            [-64.996050560999947, 18.704617369000118],
            [-64.992698560999884, 18.68819436900003],
            [-64.992499560999818, 18.687222369000096],
            [-64.985167560999912, 18.672019369000168],
            [-64.97783656099989, 18.656817369000237],
            [-64.970506560999809, 18.641614369000138],
            [-64.963178560999893, 18.626410369000155],
            [-64.955851559999928, 18.611206369000058],
            [-64.94852556, 18.596002369000246],
            [-64.94120056, 18.580798369000092],
            [-64.933877559999871, 18.56559436900011],
            [-64.926555559999883, 18.550389369000072],
            [-64.919234559999893, 18.535183369000094],
            [-64.911915560000011, 18.519978369000057],
            [-64.9045975599999, 18.504772369000136],
            [-64.897251559999859, 18.489505369000085],
            [-64.892936559999896, 18.473219369000105],
            [-64.889444559999788, 18.460000369000056],
            [-64.884309559999849, 18.444001369000034],
            [-64.879176559999962, 18.428002369000183],
            [-64.877499559999819, 18.422777369000073],
            [-64.871944559999918, 18.408611369000198],
            [-64.86388855999985, 18.397500369000113],
            [-64.856388559999914, 18.395277369000041],
            [-64.838638559999936, 18.393639369000255],
            [-64.828333559999976, 18.396666369000229],
            [-64.816944559999911, 18.403055369000185],
            [-64.800131559999954, 18.407784369000183],
            [-64.799166559999946, 18.408055369000181],
            [-64.783333559999903, 18.405000369000049],
            [-64.777645559999883, 18.389173369000048],
            [-64.77694455999989, 18.387222369000085],
            [-64.761244559999909, 18.379786369000158],
            [-64.755833559999928, 18.377222369000094],
            [-64.744999559999826, 18.377777369000171],
            [-64.743333559999826, 18.378333369000188],
            [-64.739999559999887, 18.376944369000171],
            [-64.728333559999896, 18.377777369000171],
            [-64.726666559999842, 18.375000369000077],
            [-64.716111559999888, 18.373611369000116],
            [-64.707777559999954, 18.374166369000193],
            [-64.700833559999921, 18.371111369000175],
            [-64.68323906, 18.373035369000036],
            [-64.670833559999892, 18.36611136900018],
            [-64.653346559999932, 18.365028369000129],
            [-64.639444559999959, 18.364166369000031],
            [-64.637777559999961, 18.356111369000075],
            [-64.64222255999988, 18.344166369000106],
            [-64.638480559999834, 18.327820369000108],
            [-64.636944559999961, 18.321111369000107],
            [-64.637777559999961, 18.318611369000223],
            [-64.648299559999941, 18.305233369000177],
            [-64.658819559999984, 18.291854369000191],
            [-64.660277559999884, 18.290000369000097],
            [-64.661388559999978, 18.278611369000146],
            [-64.659079559999896, 18.26202436900013],
            [-64.656771559999925, 18.245438369000226],
            [-64.654462559999899, 18.228852369000151],
            [-64.652155559999983, 18.212265369000136],
            [-64.649847559999898, 18.19567836900012],
            [-64.649444559999949, 18.192777369000055],
            [-64.647651559999929, 18.176133369000183],
            [-64.645859559999963, 18.159488369000144],
            [-64.644067559999939, 18.142844369000045],
            [-64.642275559999916, 18.126199369000233],
            [-64.64048356, 18.109554369000023],
            [-64.638692559999924, 18.09290936900004],
            [-64.636902559999953, 18.076264369000057],
            [-64.635111559999928, 18.059620369000129],
            [-64.63416656000004, 18.050833369000145],
            [-64.616675559999862, 18.050632369000027],
            [-64.599184559999912, 18.050429369000085],
            [-64.581693559999906, 18.050225369000088],
            [-64.564202559999899, 18.050019369000097],
            [-64.546711559999835, 18.049812369000222],
            [-64.529220559999885, 18.049603369000181],
            [-64.511729559999878, 18.049393369000029],
            [-64.494238559999928, 18.049181369000053],
            [-64.493055559999959, 18.049166369000034],
            [-64.475573559999873, 18.048592369000175],
            [-64.4580915599999, 18.048017369000206],
            [-64.450833559999921, 18.047777369000244],
            [-64.433377559999883, 18.046699369000066],
            [-64.415922559999842, 18.045620369000119],
            [-64.398466559999974, 18.044538369000122],
            [-64.381011559999934, 18.043456369000126],
            [-64.363556559999836, 18.04237136900008],
            [-64.352222559999916, 18.041666369000154],
            [-64.335555559999875, 18.041944369000248],
            [-64.318181559999914, 18.040009369000188],
            [-64.300807559999896, 18.038073369000244],
            [-64.283434559999876, 18.036134369000024],
            [-64.266061559999855, 18.034195369000031],
            [-64.260833559999924, 18.033611369000027],
            [-64.243520559999922, 18.031231369000068],
            [-64.226208559999975, 18.028851369000165],
            [-64.208896559999914, 18.026468369000156],
            [-64.19158456, 18.024084369000093],
            [-64.174273559999961, 18.021699369000089],
            [-64.156963559999781, 18.019312369000147],
            [-64.139653559999942, 18.016923369000153],
            [-64.122343559999877, 18.014533369000105],
            [-64.105034559999808, 18.012141369000119],
            [-64.087725559999967, 18.009748369000192],
            [-64.070417559999782, 18.007353369000043],
            [-64.053109559999939, 18.004957369000238],
            [-64.041388559999916, 18.003333369000188],
            [-64.024143559999914, 18.000558369000089],
            [-64.01722255999988, 17.999444369000059],
            [-64.000502559999973, 17.994541369000103],
            [-63.983783559999893, 17.989637369000093],
            [-63.967066559999864, 17.984731369000087],
            [-63.95017456, 17.979773368999986],
            [-63.933312559999877, 17.974726369000052],
            [-63.916625559999943, 17.969729369000106],
            [-63.899939559999893, 17.964731369000106],
            [-63.898055547999888, 17.964166664],
            [-63.891192389399919, 17.948776901400095],
            [-63.888888934999898, 17.943611069000212],
            [-63.890216772499912, 17.929063850000091],
            [-63.89041048799993, 17.92694159500013],
            [-63.890762310999889, 17.923086437000165],
            [-63.891931756999838, 17.910272068000097],
            [-63.892566164999835, 17.903319133000082],
            [-63.893452742999898, 17.893602493000117],
            [-63.894631124999876, 17.880686185000059],
            [-63.89497354, 17.876932959000044],
            [-63.895164939999859, 17.874834473000135],
            [-63.89649396, 17.860263289000159],
            [-63.89721413399991, 17.852365951000024],
            [-63.89801409599994, 17.843593665000071],
            [-63.898325906999901, 17.840173720000166],
            [-63.898860464999984, 17.834310684000172],
            [-63.899533948999931, 17.826923908000026],
            [-63.900841374999914, 17.812581415000182],
            [-63.901053518999902, 17.810254199000212],
            [-63.901337791999879, 17.807135109000058],
            [-63.90257280499992, 17.793584361000114],
            [-63.904091807999862, 17.776914576000081],
            [-63.905614814666592, 17.760197614000163],
            [-63.907129058999885, 17.743574809000165],
            [-63.908098821999886, 17.732926388000067],
            [-63.908647211999835, 17.726904831000184],
            [-63.910165081999878, 17.710234821000086],
            [-63.911299538999856, 17.697773302000087],
            [-63.911682668999902, 17.693564783000113],
            [-63.912050167999894, 17.689527460000136],
            [-63.913200066999934, 17.676894717000039],
            [-63.914611142999888, 17.661388861000091],
            [-63.916460158999939, 17.644749545000025],
            [-63.917472046999876, 17.635642146000123],
            [-63.918308891999857, 17.628110212000081],
            [-63.918824133999919, 17.623472207000077],
            [-63.919333300999824, 17.618888898000023],
            [-63.921214514999917, 17.602252946000078],
            [-63.923095446999923, 17.585616891000029],
            [-63.923578996999879, 17.581339207000099],
            [-63.92498, 17.568980736000128],
            [-63.9260653259999, 17.559342218000211],
            [-63.926856175999887, 17.552344663000042],
            [-63.92873606899991, 17.535708493000243],
            [-63.930623889666549, 17.51899869566671],
            [-63.932111074999909, 17.50583333600008],
            [-63.933441038999831, 17.493690013000105],
            [-63.933933746999912, 17.489191309000034],
            [-63.935756040999877, 17.472549190000223],
            [-63.937578050999889, 17.455906982000045],
            [-63.939399778999928, 17.439264775000026],
            [-63.940113443999792, 17.432743803000164],
            [-63.941221127999881, 17.422622572000193],
            [-63.943042099999843, 17.405980284000236],
            [-63.944358779999902, 17.393944905000126],
            [-63.944862789000013, 17.389337913000134],
            [-63.94538999599996, 17.384517870000025],
            [-63.946683099999802, 17.372695550000117],
            [-63.948503126999981, 17.35605310800014],
            [-63.949542320999853, 17.346549185000072],
            [-63.950322871999902, 17.339410677000103],
            [-63.95123691799995, 17.331049519000146],
            [-63.952142237999908, 17.322768170000188],
            [-63.953961226999866, 17.306125588000214],
            [-63.954844704999914, 17.298041040000101],
            [-63.95577993299986, 17.289482932000226],
            [-63.956882407999956, 17.279392818000247],
            [-63.957598355999835, 17.272840296000084],
            [-63.959416399999895, 17.256197680000071],
            [-63.961234161999926, 17.239554905000148],
            [-63.963051545999974, 17.222912154000085],
            [-63.963610514999914, 17.217792554000141],
            [-63.964868645999843, 17.206269339000187],
            [-63.966277739999896, 17.193361070000151],
            [-63.966929453999853, 17.187219472000038],
            [-63.968044322999901, 17.176713204000123],
            [-63.969499681999878, 17.162996001000124],
            [-63.96981062299983, 17.160065277000115],
            [-63.971576547999916, 17.14341738000013],
            [-63.973342184999979, 17.126769338000088],
            [-63.975107540999943, 17.110121328000162],
            [-63.976872519999915, 17.093473264000068],
            [-63.977944423999929, 17.083361140000079],
            [-63.97892182299978, 17.07522208000006],
            [-63.979940741999826, 17.066737267000121],
            [-63.981936681999855, 17.050113257000078],
            [-63.98243831699989, 17.045934398000099],
            [-63.983932244000016, 17.033489294000162],
            [-63.98592752299993, 17.016865198000062],
            [-63.987922424999965, 17.000241152000058],
            [-63.988560591999828, 16.99492208200013],
            [-63.989916947999973, 16.983616976000178],
            [-63.990814188999934, 16.976137122000182],
            [-63.991911093999875, 16.966992763000178],
            [-63.992898536999917, 16.958759795000219],
            [-63.993904956999984, 16.950368604000062],
            [-63.995451708999894, 16.937469769000131],
            [-63.995898441999884, 16.933744321000063],
            [-63.997891549999963, 16.917119914000068],
            [-63.999884373999919, 16.900495568000167],
            [-64.00068838899989, 16.893787104000239],
            [-64.001876819999893, 16.883871192000129],
            [-64.003868888999875, 16.867246699000134],
            [-64.005860568499884, 16.850622273000056],
            [-64.007851986999924, 16.833997635000117],
            [-64.009843016999866, 16.817372978000094],
            [-64.011833763999903, 16.800748392000173],
            [-64.013824038999985, 16.784123695000176],
            [-64.014844776999894, 16.775596636000159],
            [-64.015814124999906, 16.76749888900008],
            [-64.017330214999902, 16.754830809000168],
            [-64.017803738, 16.750874159000148],
            [-64.018500022999888, 16.745055589000117],
            [-64.035228130999826, 16.740553251000108],
            [-64.039623630999984, 16.739369818000341],
            [-64.043559192999851, 16.738310218000038],
            [-64.051955483999848, 16.736049623000156],
            [-64.068681985999831, 16.731544613000267],
            [-64.085407645999908, 16.727038340500201],
            [-64.10213263199978, 16.722530540000037],
            [-64.103677179999863, 16.722114158000238],
            [-64.108222240999964, 16.720888891000186],
            [-64.112919147999946, 16.718875941000078],
            [-64.115999967999869, 16.717555596000182],
            [-64.126407375999975, 16.712895675000198],
            [-64.131748895999976, 16.710504007000168],
            [-64.134611084999904, 16.709222235000141],
            [-64.141318327999841, 16.706063080000149],
            [-64.150212521999777, 16.70187385700018],
            [-64.163232046999838, 16.695740020000301],
            [-64.165812732999939, 16.694524192000245],
            [-64.168222250999861, 16.693388920000075],
            [-64.182606047999911, 16.686597497000093],
            [-64.183815284999866, 16.686026547000324],
            [-64.185293978999823, 16.685328214000151],
            [-64.19940718599986, 16.678663068000048],
            [-64.211870747999967, 16.672775530000195],
            [-64.214997953999898, 16.67129830000016],
            [-64.228772606, 16.664789901000177],
            [-64.23058739999982, 16.663932427000077],
            [-64.233792718499842, 16.662417586000061],
            [-64.246175712999843, 16.656565356000272],
            [-64.253206935999856, 16.653241624000088],
            [-64.261762797999893, 16.649197180000272],
            [-64.264038193999966, 16.648121310000249],
            [-64.277348749999931, 16.64182771500009],
            [-64.28707739299989, 16.637226693000059],
            [-64.29293347499987, 16.634457144000351],
            [-64.308517065999808, 16.627085376000025],
            [-64.324099335999904, 16.619712410000147],
            [-64.339680566999903, 16.612338339000132],
            [-64.355260475999899, 16.60496307100027],
            [-64.367481686999895, 16.599176394000267],
            [-64.370839251999826, 16.597586605000288],
            [-64.374853647999942, 16.595685364000076],
            [-64.386416894999854, 16.590208942000118],
            [-64.393777737999869, 16.586722192000082],
            [-64.408988070999953, 16.578665080000121],
            [-64.420987211999829, 16.572307478000255],
            [-64.42419717599995, 16.570606717000317],
            [-64.439405052999973, 16.562547374000189],
            [-64.444922515999963, 16.55962274500007],
            [-64.454611608999983, 16.554486870000119],
            [-64.468675045999987, 16.547030621000033],
            [-64.469816936999962, 16.546425205000219],
            [-64.485020942999938, 16.538362471000141],
            [-64.50022372199993, 16.530298667000295],
            [-64.515425272999835, 16.522233702000108],
            [-64.53062550199985, 16.514167576000091],
            [-64.545824502999892, 16.506100472000185],
            [-64.548223395999912, 16.50482692200012],
            [-64.561022276999836, 16.498032116000161],
            [-64.568495980999899, 16.494063526000048],
            [-64.576218728999891, 16.489962691000301],
            [-64.591413954000018, 16.481892196000217],
            [-64.606607856999858, 16.47382054100018],
            [-64.621800531999895, 16.465747817000135],
            [-64.633678730999861, 16.459434914000155],
            [-64.636991978999959, 16.457674023000209],
            [-64.6521822, 16.449599069000158],
            [-64.66737109799999, 16.441522954000106],
            [-64.675896988999853, 16.436988672000098],
            [-64.682558767999978, 16.433445771000152],
            [-64.696801347999894, 16.425869595500046],
            [-64.697745211999916, 16.425367518000314],
            [-64.712930332999974, 16.417288196000243],
            [-64.722513638999942, 16.412188205000064],
            [-64.728114226999878, 16.409207715000207],
            [-64.737117302999877, 16.404415431000359],
            [-64.743296892999979, 16.401126073000341],
            [-64.758478330999822, 16.393043454000292],
            [-64.759515151999892, 16.392491352000263],
            [-64.764611092999928, 16.389777789000163],
            [-64.779340522999973, 16.380951521000327],
            [-64.785722141999884, 16.377126664000116],
            [-64.794068535999941, 16.372124210000095],
            [-64.800890194999795, 16.368034721000242],
            [-64.808795226999905, 16.363295765000146],
            [-64.823520690999828, 16.354466369000249],
            [-64.826432484999941, 16.352720082000246],
            [-64.83824473899989, 16.345635931000231],
            [-64.84787594199986, 16.339858715000219],
            [-64.852967558999978, 16.336804542000209],
            [-64.862083445999986, 16.331335207000222],
            [-64.867688961999932, 16.327972019000299],
            [-64.87093652, 16.326023150000253],
            [-64.882409043999871, 16.319138454000267],
            [-64.897127897999894, 16.310303847000171],
            [-64.90835658799989, 16.303562746000296],
            [-64.911845335999885, 16.30146828900007],
            [-64.919013491999976, 16.297164003000091],
            [-64.926561546999835, 16.292631599000288],
            [-64.930861195000034, 16.290049249000106],
            [-64.941276339999888, 16.283793958000103],
            [-64.953395643999897, 16.27651369050011],
            [-64.955989906999832, 16.274955275000195],
            [-64.970702056999869, 16.266115551000098],
            [-64.985412979999978, 16.257274785000334],
            [-64.994828036999877, 16.251615480000225],
            [-65.000122580000038, 16.248432979000256],
            [-65.014830763999839, 16.239590131000284],
            [-65.024325567499886, 16.233880579500067],
            [-65.029537720999826, 16.230746333000184],
            [-65.035736291999854, 16.227018107500157],
            [-65.044243355999868, 16.221901404000334],
            [-65.048370323999848, 16.219418686000267],
            [-65.058947668999849, 16.213055524000083],
            [-65.073650659999942, 16.204208604000144],
            [-65.088352422999947, 16.195360644000061],
            [-65.103052769999863, 16.186511734000305],
            [-65.117751889999852, 16.177661693000175],
            [-65.132449592999933, 16.168810702000144],
            [-65.147146314999929, 16.159958520000089],
            [-65.16184122199985, 16.151105601000268],
            [-65.176535053999885, 16.142251490000149],
            [-65.191227563999803, 16.133396431000165],
            [-65.205918845999804, 16.124540241000147],
            [-65.209012491999886, 16.122674952000068],
            [-65.220608711999773, 16.115683103000208],
            [-65.235297350999929, 16.1068250160003],
            [-65.242503491999855, 16.102478356000177],
            [-65.249984667000035, 16.097965799000121],
            [-65.264670661999958, 16.089105634000248],
            [-65.27861013799992, 16.080694159000132],
            [-65.279355428999907, 16.080244429000231],
            [-65.294038778999948, 16.071382186000164],
            [-65.308720902999823, 16.062518995000175],
            [-65.313080249999871, 16.05988680500019],
            [-65.323401703999821, 16.053654674000313],
            [-65.334365545999901, 16.047033405000207],
            [-65.338081276999901, 16.044789405000131],
            [-65.352759434999882, 16.035923189000187],
            [-65.358852197999965, 16.03224213100026],
            [-65.367436364999946, 16.027055844000245],
            [-65.382111971999791, 16.01818755100021],
            [-65.392447848999893, 16.011940516000152],
            [-65.396786352999868, 16.009318311000357],
            [-65.41145931699981, 16.000447942000108],
            [-65.41889561399995, 15.995951560000037],
            [-65.426131052999892, 15.991576626000267],
            [-65.437677545999861, 15.984593554000355],
            [-65.440801467999847, 15.982704273000081],
            [-65.455470654999829, 15.973830972000144],
            [-65.464005154999938, 15.968667437000022],
            [-65.470138519999864, 15.964956635000135],
            [-65.475599674999955, 15.961651848000145],
            [-65.484805062999897, 15.956081259000143],
            [-65.492518260999873, 15.951412725000239],
            [-65.499470378999888, 15.947204846000147],
            [-65.512358465499972, 15.939402544000245],
            [-65.514134277999972, 15.938327487000038],
            [-65.518842785499942, 15.935476486000141],
            [-65.52879704399993, 15.929449182000269],
            [-65.543458393999799, 15.920569749000151],
            [-65.556977714999846, 15.912380412000175],
            [-65.558118515999979, 15.911689370000204],
            [-65.572777316999918, 15.902808046000246],
            [-65.580463975999862, 15.898149950000118],
            [-65.587434889999884, 15.893925593000063],
            [-65.594755039999768, 15.889488728000231],
            [-65.60209114099996, 15.885042195000324],
            [-65.612271681999857, 15.878870386000074],
            [-65.616746069999976, 15.876157852000176],
            [-65.631399770999792, 15.867272473000128],
            [-65.635715798999854, 15.864654876000202],
            [-65.646052150999822, 15.858386057000303],
            [-65.657242514999837, 15.851598004000039],
            [-65.660703302999877, 15.849498697000172],
            [-65.665769293000039, 15.846425041000259],
            [-65.675353133000044, 15.840610300000264],
            [-65.690001640999924, 15.831720959000052],
            [-65.696645713999828, 15.827688243000182],
            [-65.704648920999887, 15.822830582000165],
            [-65.719294973999865, 15.81393916900015],
            [-65.733939610999926, 15.805046812000057],
            [-65.745493920999934, 15.798029642000358],
            [-65.748583114999917, 15.796153511000284],
            [-65.763225201999887, 15.787259174000212],
            [-65.774296441999866, 15.780532602000164],
            [-65.777866061999987, 15.778363803000047],
            [-65.792505693999885, 15.769467487000099],
            [-65.807144003999952, 15.760570137000229],
            [-65.821781086999863, 15.751671843000111],
            [-65.836416847999942, 15.742772514000137],
            [-65.844888487999867, 15.737620324000204],
            [-65.851051380999763, 15.733872242000189],
            [-65.865684592999969, 15.724970936000204],
            [-65.874507282999843, 15.719603086000348],
            [-65.880316481999841, 15.716068686000085],
            [-65.889501944999836, 15.710479038000187],
            [-65.894947238999919, 15.707165402000101],
            [-65.909576578999861, 15.698261176000187],
            [-65.924204784999858, 15.689355915000306],
            [-65.930546005999986, 15.6854947920001],
            [-65.938831669999843, 15.680449712000325],
            [-65.950801382999828, 15.673159937000321],
            [-65.953457232999824, 15.671542475000251],
            [-65.968081568999878, 15.662634296000078],
            [-65.969547691999765, 15.661741084000255],
            [-65.977694430999975, 15.656777814000122],
            [-65.989092761999871, 15.644202465000319],
            [-66.000489675999859, 15.631626516000097],
            [-66.003645921999862, 15.62814317900029],
            [-66.011885267999844, 15.61904996800007],
            [-66.021536788999924, 15.608396237000306],
            [-66.023279349999768, 15.606472730000235],
            [-66.024749614999848, 15.604849539000099],
            [-66.034672108999871, 15.593894986000123],
            [-66.043455048999874, 15.584196928000267],
            [-66.04606354699996, 15.58131664400014],
            [-66.057453567999971, 15.568737615000202],
            [-66.060190237999961, 15.565714743000228],
            [-66.06884217299978, 15.556157989000326],
            [-66.07735798199991, 15.546750050000185],
            [-66.080229360999965, 15.543577860000084],
            [-66.08344570599985, 15.540023959000109],
            [-66.091615227999853, 15.530997044000117],
            [-66.09884917099987, 15.523002526000141],
            [-66.10299967799989, 15.518415634000235],
            [-66.114382711999838, 15.505833631000257],
            [-66.118805543999827, 15.5009444330002],
            [-66.129368030999899, 15.487707396000133],
            [-66.139149741999972, 15.475446762000161],
            [-66.139929195999855, 15.474469775000273],
            [-66.140497634999861, 15.473757163000073],
            [-66.150488944999779, 15.461231755000256],
            [-66.158120163999968, 15.451663496000208],
            [-66.161047465999957, 15.447993153000198],
            [-66.171604570999932, 15.434753970000145],
            [-66.182160353999905, 15.421514390000198],
            [-66.188163442999837, 15.413983748000248],
            [-66.192714814999874, 15.408274229000085],
            [-66.203267954000012, 15.395033491000163],
            [-66.2138196759999, 15.381792357000108],
            [-66.217907578999814, 15.376661713000146],
            [-66.224370171000032, 15.368550645000141],
            [-66.234919249999905, 15.355308448000187],
            [-66.237691976999884, 15.351827272000321],
            [-66.245467006999831, 15.342065676000345],
            [-66.256013549000045, 15.328822393500161],
            [-66.266558648999819, 15.315578680000272],
            [-66.274299765499904, 15.305854873500209],
            [-66.277102439999851, 15.302334366000082],
            [-66.27913752499984, 15.29977763300019],
            [-66.287644908999766, 15.289089571000147],
            [-66.298186055999849, 15.27584429500024],
            [-66.30872588099993, 15.262598448000176],
            [-66.314075796999987, 15.25587389400016],
            [-66.31926438399995, 15.2493521210003],
            [-66.329876035999916, 15.236011685000165],
            [-66.332138848999989, 15.233166682000217],
            [-66.337426388999972, 15.226528883000128],
            [-66.342685094999865, 15.21992727900016],
            [-66.348648358999924, 15.212439938500211],
            [-66.353230018999852, 15.206687309000358],
            [-66.36377362199994, 15.193446772000186],
            [-66.374315995999837, 15.180205852000313],
            [-66.384856953999872, 15.166964366000173],
            [-66.395396575499888, 15.153722341000105],
            [-66.397210542999858, 15.151442926000243],
            [-66.405934998999953, 15.140479885000047],
            [-66.416471991999856, 15.127236890000063],
            [-66.427007756999842, 15.113993333000224],
            [-66.430080821999866, 15.110129839000138],
            [-66.437542199999825, 15.100749306000353],
            [-66.445824070999947, 15.090335563000338],
            [-66.448075320999862, 15.087504809000222],
            [-66.455795128999796, 15.077796271000182],
            [-66.45860721400004, 15.074259752000046],
            [-66.465796762999958, 15.065216539000119],
            [-66.469137690999901, 15.061014227000044],
            [-66.479666940999834, 15.047768143000269],
            [-66.490194868999822, 15.034521592000146],
            [-66.500721473999818, 15.021274575000177],
            [-66.505554250999921, 15.015191835000337],
            [-66.511246757999913, 15.008027002000176],
            [-66.521770814999911, 14.994778964000034],
            [-66.527152531999889, 14.988003188000221],
            [-66.532293548999974, 14.981530463000126],
            [-66.54281496099992, 14.968281406000187],
            [-66.551791184999843, 14.956976333000114],
            [-66.553335145999824, 14.955031796000071],
            [-66.554304749999972, 14.953810435000207],
            [-66.563854008999783, 14.941781724000123],
            [-66.572583583999801, 14.930783763000306],
            [-66.574487217333171, 14.928385474333425],
            [-66.582205527999861, 14.928331100000321],
            [-66.59184995399994, 14.928505301000257],
            [-66.609061136000037, 14.92881488900008],
            [-66.626272412999811, 14.929123283000195],
            [-66.643483689999869, 14.929430299000217],
            [-66.660694966999927, 14.929735937000089],
            [-66.67757134299984, 14.930034454000179],
            [-66.695117804999825, 14.930343540000194],
            [-66.712329270999874, 14.930645413000207],
            [-66.714069416000029, 14.930675794000138],
            [-66.729540831, 14.930945908000297],
            [-66.746752390999916, 14.931245118000163],
            [-66.763963951999983, 14.931543133000218],
            [-66.77036274999989, 14.931653415000142],
            [-66.781175605999977, 14.931839771000284],
            [-66.791996972999868, 14.932025465000152],
            [-66.798387355999978, 14.932135124000297],
            [-66.815599104999819, 14.932429190000278],
            [-66.832810947999803, 14.932721971000092],
            [-66.850022676999799, 14.933013370500134],
            [-66.860749904499869, 14.93319442100011],
            [-66.877657279999823, 14.936342999000203],
            [-66.894564982999839, 14.93949033600029],
            [-66.902430789999983, 14.940953915000136],
            [-66.911473252999883, 14.942636433000246],
            [-66.928382324999973, 14.945781329000226],
            [-66.945291302999919, 14.948924814000179],
            [-66.957736636999869, 14.951237487000299],
            [-66.962201082999968, 14.952067099000203],
            [-66.979111335999903, 14.955208235000214],
            [-66.996022059999916, 14.958348040000203],
            [-67.0059309479999, 14.960187040000335],
            [-67.012933256999872, 14.961486605000289],
            [-67.019054619999963, 14.962622188000182],
            [-67.029845019999868, 14.964623930000243],
            [-67.04675716099996, 14.967759923000131],
            [-67.055480035999892, 14.969376747000126],
            [-67.063669868999909, 14.970894769000324],
            [-67.068266843999879, 14.971746554000163],
            [-67.071583283, 14.972361065000143],
            [-67.087694422999959, 14.977361118000204],
            [-67.102694472999872, 14.980972205000114],
            [-67.108069781999973, 14.982288625000137],
            [-67.116305541999907, 14.984305574000132],
            [-67.122051696999904, 14.985469865000141],
            [-67.133161971999812, 14.987721038000132],
            [-67.150018874999944, 14.991135346000192],
            [-67.166876453999862, 14.994548340000165],
            [-67.183734285999833, 14.997960034000187],
            [-67.19384579899986, 15.000005645000044],
            [-67.200592793999988, 15.001370597000175],
            [-67.217451867999841, 15.004779820000294],
            [-67.234311509999941, 15.008187795000083],
            [-67.251171622999834, 15.01159461400033],
            [-67.259871383000018, 15.013351770000213],
            [-67.268032301999881, 15.01500009200015],
            [-67.284893453999871, 15.018404323000198],
            [-67.301755414999946, 15.021807336000109],
            [-67.318617457999949, 15.025209041000323],
            [-67.327035030999866, 15.026906537000059],
            [-67.335480308999763, 15.028609620000225],
            [-67.352343632999805, 15.032008859000143],
            [-67.36920752299983, 15.035406850000186],
            [-67.386071884999922, 15.038803594000058],
            [-67.394083311999907, 15.040416698000115],
            [-67.403576448999843, 15.042349633000299],
            [-67.410940497999832, 15.043849056000283],
            [-67.427798155999938, 15.047280165000188],
            [-67.444656380999902, 15.050709934000224],
            [-67.454845797999837, 15.05278217900036],
            [-67.461515171999963, 15.054138545000114],
            [-67.467661900999872, 15.055388123000114],
            [-67.47837453, 15.057565908000299],
            [-67.495234359999927, 15.06099193100016],
            [-67.503691475999915, 15.06270983200011],
            [-67.512094756999829, 15.064416797000206],
            [-67.526223738, 15.067285608000361],
            [-67.528955719999828, 15.067840322000222],
            [-67.531703934999939, 15.068398125000101],
            [-67.545817248999981, 15.071262691000356],
            [-67.562679250999906, 15.074683719000234],
            [-67.579541818999985, 15.078103499000235],
            [-67.582426079999834, 15.078688206000209],
            [-67.596404859999836, 15.081522031000077],
            [-67.606027824999899, 15.083472242000084],
            [-67.621114115999944, 15.086527248000266],
            [-67.622892092999791, 15.086887292000199],
            [-67.624169237999865, 15.087145820000103],
            [-67.639756927999827, 15.090301185000328],
            [-67.645945666999921, 15.0915534250002],
            [-67.656622233999826, 15.093713739000236],
            [-67.66769690999979, 15.09595371200021],
            [-67.673488106999969, 15.097125044000109],
            [-67.690354546999913, 15.100535102000208],
            [-67.707221458999982, 15.103943911000044],
            [-67.722971946999962, 15.107125818000156],
            [-67.724088936999919, 15.107351472000175],
            [-67.74095698199983, 15.110757786000136],
            [-67.757825498999921, 15.114162851000231],
            [-67.774694676999843, 15.117566578000208],
            [-67.783552042999929, 15.119353088000025],
            [-67.791564326999946, 15.120969148000199],
            [-67.805543249999971, 15.123787476000302],
            [-67.808434448999833, 15.124370378000037],
            [-67.825305231999948, 15.127770361000273],
            [-67.828611085999853, 15.12843633000017],
            [-67.842176486999847, 15.131169097000281],
            [-67.850078925999867, 15.132760424000026],
            [-67.859048214999916, 15.134566584000083],
            [-67.875920602999884, 15.137962825000159],
            [-67.892793463999794, 15.141357726000138],
            [-67.909666795999954, 15.144751471000177],
            [-67.91131972099987, 15.145083781000153],
            [-67.92654079, 15.148143877000223],
            [-67.943415255999923, 15.151535036000212],
            [-67.96029028799984, 15.1549249480002],
            [-67.971553331999871, 15.157186596000088],
            [-67.977165886999899, 15.158313612000086],
            [-67.98159960799984, 15.159203538000213],
            [-67.994041957999855, 15.161700937000148],
            [-68.01091859499985, 15.165087107000204],
            [-68.027795704999846, 15.168471939000312],
            [-68.040006222999864, 15.170919852000054],
            [-68.044673474999854, 15.171855523000318],
            [-68.061551717999862, 15.175237860000095],
            [-68.062444404999951, 15.175416682000161],
            [-68.079614701999958, 15.176854774000333],
            [-68.096785188000013, 15.178291481000258],
            [-68.101272332999883, 15.178666614000122],
            [-68.11395595699986, 15.179726986000105],
            [-68.131126914999811, 15.181161105000172],
            [-68.148298155999839, 15.182593930000166],
            [-68.15436933799981, 15.183100194000133],
            [-68.155499988999964, 15.183194476000324],
            [-68.160463530000015, 15.183593483000209],
            [-68.172675856999888, 15.184575203000236],
            [-68.1803033569999, 15.185187774000212],
            [-68.189852007999889, 15.185954636000247],
            [-68.207028347999824, 15.187332685000115],
            [-68.224204876999806, 15.188709440000082],
            [-68.226172438999981, 15.188867007000056],
            [-68.241381689000036, 15.19008499500012],
            [-68.258558689999916, 15.19145916500014],
            [-68.275735974999861, 15.192831951000244],
            [-68.287869971999783, 15.193800830000157],
            [-68.292913352999904, 15.194203535000213],
            [-68.310091014999841, 15.195573735000039],
            [-68.327268960999788, 15.19694264300017],
            [-68.340586301999849, 15.19800288600004],
            [-68.34444709499985, 15.198310258000276],
            [-68.348572501999968, 15.19863838200024],
            [-68.361625417999846, 15.199676580000357],
            [-68.378803929999947, 15.201041609000185],
            [-68.389648416999819, 15.201902439000207],
            [-68.395982724999783, 15.202405254000155],
            [-68.402048338999862, 15.202886278000165],
            [-68.413161708999837, 15.20376760600027],
            [-68.430340975999854, 15.205128666000064],
            [-68.447520431999976, 15.206488433000231],
            [-68.450288558999773, 15.20670734700019],
            [-68.458555564, 15.207361366000043],
            [-68.475387564000016, 15.210968366000088],
            [-68.481888563999973, 15.212361366000039],
            [-68.480482563999885, 15.229042366000215],
            [-68.479076563999968, 15.245724366000047],
            [-68.477669563999939, 15.262405366000223],
            [-68.476262563999967, 15.279087366000056],
            [-68.474855563999824, 15.295768366000232],
            [-68.473447563999855, 15.31244936600001],
            [-68.472040563999826, 15.32913136600024],
            [-68.470632563999857, 15.345812366000018],
            [-68.469224563999887, 15.362493366000137],
            [-68.467816563999918, 15.379175366000027],
            [-68.466407563999894, 15.395856366000146],
            [-68.464998563999814, 15.412537366000095],
            [-68.463589563999903, 15.4292183660001],
            [-68.462180563999823, 15.445899366000219],
            [-68.46077156399997, 15.462580366],
            [-68.459361563999892, 15.479261366000117],
            [-68.457951563999927, 15.495942366000122],
            [-68.456541563999963, 15.512623366000241],
            [-68.455131563999828, 15.529304366000019],
            [-68.453721563999977, 15.545985366000195],
            [-68.452310563999959, 15.562666365999974],
            [-68.450899563999883, 15.579347366000093],
            [-68.449488563999864, 15.596028366000041],
            [-68.448076563999905, 15.612708366000106],
            [-68.446665563999943, 15.629389366000055],
            [-68.445253563999927, 15.646070367000107],
            [-68.443841563999968, 15.662750367],
            [-68.442428563999954, 15.679431367000177],
            [-68.441016563999938, 15.696112367000126],
            [-68.439603563999981, 15.71279236700002],
            [-68.438190563999967, 15.729473367000139],
            [-68.436776563999956, 15.746153367000034],
            [-68.435363563999886, 15.762834367000153],
            [-68.434111563999977, 15.777611367000134],
            [-68.43268156399995, 15.794290367000144],
            [-68.431252563999919, 15.810969367000098],
            [-68.429822563999835, 15.827648367000052],
            [-68.428392563999978, 15.844327367000062],
            [-68.426962563999837, 15.861006367000016],
            [-68.425531563999982, 15.877685367000197],
            [-68.424100563999957, 15.894364367000151],
            [-68.422669563999762, 15.911043367000104],
            [-68.421238563999964, 15.927722367000115],
            [-68.419807563999939, 15.944401367000069],
            [-68.418375563999859, 15.961080367000022],
            [-68.416943563999951, 15.977758367000092],
            [-68.415511563999928, 15.994437367000103],
            [-68.414079563999962, 16.011116367000056],
            [-68.412646563999942, 16.027794367000126],
            [-68.411213563999866, 16.044473367000137],
            [-68.409780563999846, 16.061152367000091],
            [-68.408347563999939, 16.077830367000161],
            [-68.406913563999979, 16.094509367000114],
            [-68.405479563999961, 16.111187367000014],
            [-68.404045563999887, 16.127866367000024],
            [-68.402611563999983, 16.144544367000094],
            [-68.401176563999911, 16.161222367000164],
            [-68.399741563999839, 16.177901367000118],
            [-68.398307563999879, 16.194579367000188],
            [-68.39687156399998, 16.211257367000087],
            [-68.395436563999908, 16.227936367000041],
            [-68.394000563999953, 16.244614367000111],
            [-68.39256456399994, 16.26129236700001],
            [-68.391128563999871, 16.27797036700008],
            [-68.389691563999918, 16.29464836700015],
            [-68.388254563999965, 16.31132636700022],
            [-68.386818563999952, 16.328004367000119],
            [-68.385380564, 16.344682367000189],
            [-68.383943563999935, 16.361360367000088],
            [-68.382505563999928, 16.378038366999988],
            [-68.38106756399992, 16.394716367000058],
            [-68.379629563999856, 16.411394367000128],
            [-68.378191563999906, 16.428072367000027],
            [-68.376752563999844, 16.444749366999986],
            [-68.375313563999896, 16.461427367000056],
            [-68.373874563999891, 16.478105367000126],
            [-68.372434563999889, 16.494782367000141],
            [-68.370995563999941, 16.511460367000211],
            [-68.369555563999825, 16.528138367000054],
            [-68.368115563999936, 16.54481536700024],
            [-68.366674563999936, 16.561493367000139],
            [-68.36523456399982, 16.578170367000098],
            [-68.36379356399982, 16.594848367000168],
            [-68.362352563999877, 16.611525367000183],
            [-68.360910563999937, 16.628202367000142],
            [-68.359469563999937, 16.644880367000042],
            [-68.358027563999883, 16.661557367000228],
            [-68.356585563999943, 16.678234367000186],
            [-68.355142563999948, 16.694911367000202],
            [-68.353700563999894, 16.711589368000205],
            [-68.352257563999956, 16.728266368000163],
            [-68.350813563999964, 16.744943368000179],
            [-68.349370563999912, 16.761620368000138],
            [-68.347926563999977, 16.778297368000153],
            [-68.346483563999982, 16.794974368000112],
            [-68.345038563999879, 16.811651368000128],
            [-68.343594563999886, 16.828328368000086],
            [-68.34214956400001, 16.845005368000102],
            [-68.340704563999907, 16.861682368000061],
            [-68.339259563999917, 16.878358367999965],
            [-68.337814563999984, 16.895035367999981],
            [-68.336368563999883, 16.91171236800011],
            [-68.334922563999896, 16.928389368000126],
            [-68.333476563999966, 16.94506536800003],
            [-68.332030563999979, 16.961742367999989],
            [-68.330583563999824, 16.978418368000064],
            [-68.329136563999953, 16.995095368000079],
            [-68.327689563999968, 17.011771368000154],
            [-68.326241563999986, 17.028448368000113],
            [-68.324794563999887, 17.045124368000245],
            [-68.323346563999905, 17.061801368000204],
            [-68.321897563999983, 17.078477368000108],
            [-68.320449564, 17.095153368000013],
            [-68.31900056399985, 17.111830368000028],
            [-68.317551563999928, 17.128506368000103],
            [-68.316102563999948, 17.145182368],
            [-68.314652563999971, 17.161858368000082],
            [-68.313203563999821, 17.178534368000157],
            [-68.311753563999901, 17.195211368000116],
            [-68.310302563999926, 17.211887368000248],
            [-68.308852563999949, 17.228563368000152],
            [-68.307401563999974, 17.245239368000057],
            [-68.305950563999943, 17.261915368000132],
            [-68.304498563999971, 17.278590368000096],
            [-68.303047563999883, 17.295266368000171],
            [-68.301595563999911, 17.311942368000075],
            [-68.300143563999939, 17.328618368000207],
            [-68.29869156399991, 17.345294368000111],
            [-68.297722563999912, 17.356416368000168],
            [-68.296593563999977, 17.373115368000015],
            [-68.295463563999988, 17.389814368000032],
            [-68.294333564, 17.406513368000105],
            [-68.29320456399995, 17.423211368000239],
            [-68.292074563999961, 17.439910368000085],
            [-68.290944563999801, 17.456608368000218],
            [-68.289813563999928, 17.473307368000064],
            [-68.288683563999882, 17.490006368000081],
            [-68.287552563999839, 17.506704368000044],
            [-68.286421563999966, 17.523402368000177],
            [-68.285290563999979, 17.540101368000023],
            [-68.284158563999938, 17.556799368000156],
            [-68.283026563999783, 17.573498368],
            [-68.28189556399991, 17.590196368000136],
            [-68.280763563999983, 17.606894368000042],
            [-68.279630563999945, 17.623593368000115],
            [-68.279083563999961, 17.63166636800014],
            [-68.278296563999959, 17.648383368000054],
            [-68.277509563999956, 17.665099368000085],
            [-68.27672256399984, 17.681816368],
            [-68.275935563999894, 17.698532368000201],
            [-68.275148563999892, 17.715248368000061],
            [-68.274361563999776, 17.731965367999976],
            [-68.273573563999946, 17.748681368000177],
            [-68.272786563999944, 17.765397367999981],
            [-68.271998563999944, 17.782113369000115],
            [-68.27121056399983, 17.7988303690002],
            [-68.270422563999944, 17.81554636900006],
            [-68.269634563999944, 17.832262369000034],
            [-68.269361563999894, 17.838055369000131],
            [-68.268675563999977, 17.854775369000095],
            [-68.267989563999777, 17.87149636900017],
            [-68.26730356399986, 17.88821636900019],
            [-68.266617563999944, 17.904936369000154],
            [-68.26593156399997, 17.921656369000175],
            [-68.265244563999943, 17.938376369000139],
            [-68.264558563999969, 17.955096369000159],
            [-68.264083563999918, 17.966666369000166],
            [-68.263314563999984, 17.98338336900008],
            [-68.262546563999933, 18.000100369],
            [-68.261777563999885, 18.016816369000196],
            [-68.261009563999949, 18.033533369000111],
            [-68.260749563999866, 18.0391663690001],
            [-68.259771563999834, 18.055873369000039],
            [-68.258793563999916, 18.072579368999982],
            [-68.257815563999884, 18.089286369000149],
            [-68.257138563999945, 18.100833369000043],
            [-68.258438564000016, 18.117519369000036],
            [-68.258805564, 18.122222369000156],
            [-68.252811564000012, 18.137942369000029],
            [-68.247694563999971, 18.151361369000085],
            [-68.241010563999964, 18.166825369000037],
            [-68.234326563999844, 18.182290369000157],
            [-68.227640563999955, 18.197754369000108],
            [-68.220953563999956, 18.213218369000117],
            [-68.214265563999788, 18.228682369000069],
            [-68.20757556399991, 18.244145369000023],
            [-68.200885563999861, 18.259608369000091],
            [-68.194193563999931, 18.275071369000159],
            [-68.190749563999987, 18.283027368999967],
            [-68.180065562999857, 18.296286369000086],
            [-68.169379563, 18.309545369000091],
            [-68.160749562999968, 18.320250369000121],
            [-68.150057563000018, 18.333505368999965],
            [-68.139363562999904, 18.34675936900004],
            [-68.128668562999849, 18.360012369000231],
            [-68.117970562999915, 18.37326536900008],
            [-68.115472562999969, 18.376361369000108],
            [-68.103264562999925, 18.388366369000039],
            [-68.09105456299983, 18.400370369000086],
            [-68.082416562999924, 18.408861369000078],
            [-68.069919562999985, 18.420593369000017],
            [-68.068805562999842, 18.421639369000047],
            [-68.055769562999842, 18.432826369000225],
            [-68.042732562999845, 18.444012369000063],
            [-68.029692562999969, 18.455197369000246],
            [-68.016651562999982, 18.466381369000089],
            [-68.016027562999966, 18.466916368999989],
            [-68.002747562999957, 18.4778423690002],
            [-67.989465562999897, 18.488766369000246],
            [-67.976181562999898, 18.499690369000064],
            [-67.962895562999904, 18.510613369000225],
            [-67.949608562999856, 18.521534369000051],
            [-67.948805562999951, 18.52219436900009],
            [-67.93555556299998, 18.533157369000151],
            [-67.92230256299996, 18.544120369000098],
            [-67.918249562999904, 18.547472369000104],
            [-67.9042245629999, 18.557522368999969],
            [-67.890197562999958, 18.56757136900012],
            [-67.881027562999861, 18.574139368999965],
            [-67.875830562999965, 18.590119369000206],
            [-67.870631562999961, 18.60610036900016],
            [-67.865432562999843, 18.622081369000114],
            [-67.8602315629999, 18.638061369000184],
            [-67.8550305629999, 18.654041369000026],
            [-67.849827562999906, 18.670021369000096],
            [-67.844624562999854, 18.68600136900011],
            [-67.839420562999862, 18.70198136900018],
            [-67.834214562999932, 18.717960369000139],
            [-67.829008562999945, 18.733940369000038],
            [-67.823801562999904, 18.749919369],
            [-67.818592562999925, 18.765898369000126],
            [-67.813383562999775, 18.781877369000085],
            [-67.808173562999912, 18.79785536899999],
            [-67.80296256299988, 18.813834369000119],
            [-67.797749562999968, 18.829812369000024],
            [-67.792536562999942, 18.845790369000042],
            [-67.787322562999975, 18.86176837000005],
            [-67.782107562999784, 18.877746370000068],
            [-67.776891562999879, 18.893724369999973],
            [-67.772138562999885, 18.908277370000064],
            [-67.767051563, 18.9242933700001],
            [-67.761963562999938, 18.940308370000196],
            [-67.75687456299994, 18.956323370000121],
            [-67.751784562999774, 18.972338370000216],
            [-67.746693562999894, 18.988353370000141],
            [-67.741601562999961, 19.004368370000066],
            [-67.739916562999781, 19.009666370000048],
            [-67.734734562999961, 19.025655370000095],
            [-67.729552562999913, 19.041644370000029],
            [-67.724368562999985, 19.057632370000192],
            [-67.719183562999945, 19.073620370000185],
            [-67.713998562999848, 19.08960837],
            [-67.70881156299987, 19.10559637],
            [-67.703623562999951, 19.121584370000164],
            [-67.698434562999921, 19.137571370000103],
            [-67.693245562999948, 19.153559370000096],
            [-67.689638562999903, 19.164666370000191],
            [-67.684220562999883, 19.180585370000017],
            [-67.678800562999982, 19.196504370000127],
            [-67.673379562999912, 19.21242237000024],
            [-67.667957562999959, 19.228340370000126],
            [-67.662534562999895, 19.244258370000182],
            [-67.65711056299989, 19.260176370000067],
            [-67.651685563, 19.276094370000123],
            [-67.646259562999887, 19.292011370000125],
            [-67.640831562999836, 19.307928370000127],
            [-67.638249562999931, 19.315500370000109],
            [-67.63606756299987, 19.332101370000203],
            [-67.633884562999867, 19.34870337000001],
            [-67.633249562999765, 19.353527370000165],
            [-67.630611563, 19.37006937000001],
            [-67.627973562999955, 19.386611370000026],
            [-67.62533456299991, 19.403153370000211],
            [-67.622695562999922, 19.419695370000227],
            [-67.620055562999937, 19.436237370000072],
            [-67.617414562999841, 19.452779370000087],
            [-67.614773562999972, 19.469320370000162],
            [-67.612131562999821, 19.485862370000177],
            [-67.609489562999954, 19.502403370000081],
            [-67.60684656299992, 19.518945370000097],
            [-67.604203562999942, 19.535486370000058],
            [-67.60155956299991, 19.552027370000133],
            [-67.598915562999821, 19.568569370000148],
            [-67.596270562999848, 19.585110370000052],
            [-67.593625562999819, 19.601651370000013],
            [-67.590979562999905, 19.618192370000088],
            [-67.588332563, 19.634733370000049],
            [-67.585685562999913, 19.651274370000124],
            [-67.583038562999832, 19.667815370000028],
            [-67.580390563000037, 19.684355370000048],
            [-67.577741562999961, 19.70089637000018],
            [-67.575092563, 19.717437370000084],
            [-67.572442562999925, 19.733977370000105],
            [-67.569792562999965, 19.750518370000066],
            [-67.567141562999836, 19.767058370000086],
            [-67.564490562999936, 19.783598370000107],
            [-67.561838562999981, 19.800139370000011],
            [-67.559185562999971, 19.816679370000031],
            [-67.556532562999905, 19.833219370000052],
            [-67.553879562999953, 19.849759370000072],
            [-67.551224562999948, 19.866299370000092],
            [-67.548570562999885, 19.882839370000113],
            [-67.545915562999937, 19.899379370000133],
            [-67.543259562999879, 19.915918370000099],
            [-67.540602563, 19.932458371],
            [-67.537946562999878, 19.948998371000016],
            [-67.535288562999881, 19.965537370999982],
            [-67.532630562999941, 19.982077371],
            [-67.530749562999915, 19.993777371000135],
            [-67.526994562999846, 20.010126371000126],
            [-67.526027562999957, 20.014333371000077],
            [-67.524361562999957, 20.019333371000073],
            [-67.519958563000046, 20.035536371000092],
            [-67.517416562999983, 20.04488837100007],
            [-67.514083562999986, 20.056277371000078],
            [-67.509525562999954, 20.07244337100019],
            [-67.504966563000039, 20.088608371000134],
            [-67.500406562999956, 20.104773371000192],
            [-67.495845562999875, 20.120937371000196],
            [-67.491283562999968, 20.137102371000083],
            [-67.486720562999892, 20.153267371000197],
            [-67.486027562999936, 20.155722371000081],
            [-67.481332562999967, 20.171853371000168],
            [-67.476637562999827, 20.18798337100003],
            [-67.471940562999862, 20.204114371000173],
            [-67.467242562999786, 20.220244371000149],
            [-67.462544562999881, 20.236374371000011],
            [-67.457844562999981, 20.252505371000154],
            [-67.453143562999969, 20.268634371000246],
            [-67.448442562999958, 20.284764371000108],
            [-67.443739562999895, 20.30089437099997],
            [-67.439036562999888, 20.317023371000062],
            [-67.434331562999887, 20.333153371000094],
            [-67.429626562999886, 20.349282371000186],
            [-67.424919562999889, 20.365411371],
            [-67.420212562999893, 20.381540371000085],
            [-67.415503562999902, 20.397668371000123],
            [-67.410794562999968, 20.413797371000101],
            [-67.406084562999979, 20.429925371000138],
            [-67.401372563, 20.446053371000176],
            [-67.396660562999898, 20.462181371000213],
            [-67.391947562999917, 20.478309371000023],
            [-67.387232562999884, 20.49443737100006],
            [-67.382517562999851, 20.510564371000214],
            [-67.377801562999821, 20.526692371000081],
            [-67.373084562999964, 20.542819371000235],
            [-67.368365562999884, 20.558946371000161],
            [-67.363646562999975, 20.575073371000144],
            [-67.358926562999898, 20.59120037100007],
            [-67.354205562999937, 20.607326371000113],
            [-67.349482562999981, 20.623453371000096],
            [-67.344759562999968, 20.639579370999968],
            [-67.340035562999901, 20.655705371000181],
            [-67.335310562999894, 20.671831371000223],
            [-67.330584562999888, 20.687957371000095],
            [-67.325856562999832, 20.704083370999967],
            [-67.321128562999888, 20.720208371000126],
            [-67.316399562999948, 20.736333371000057],
            [-67.311669562999839, 20.752459371000157],
            [-67.306937562999792, 20.768584371000088],
            [-67.302205562999916, 20.784708371000136],
            [-67.297472563000042, 20.800833371000124],
            [-67.296861562999936, 20.802916370999981],
            [-67.290050563000023, 20.818370371000242],
            [-67.283238562999941, 20.833823371],
            [-67.276424562999978, 20.84927537100009],
            [-67.269609562999904, 20.864728371000183],
            [-67.262793562999946, 20.880180371000108],
            [-67.255975563, 20.895632371000147],
            [-67.249156563000042, 20.911083371000132],
            [-67.242335562999926, 20.926535371],
            [-67.235514562999811, 20.941986370999985],
            [-67.228690562999986, 20.957436371000085],
            [-67.221866562999935, 20.97288737100024],
            [-67.215040562999832, 20.988337371000114],
            [-67.208212562999961, 21.003786372000036],
            [-67.201383563000036, 21.01923637200008],
            [-67.194553562999886, 21.034685372000069],
            [-67.187721562999968, 21.050134372000059],
            [-67.180888562999826, 21.065582372],
            [-67.174053562999859, 21.081031371999984],
            [-67.167217562999838, 21.096479372000033],
            [-67.160380562999876, 21.111926372000028],
            [-67.153541562999919, 21.127373372000022],
            [-67.146701562999851, 21.142820372000074],
            [-67.139859562999959, 21.158267372000068],
            [-67.133016562999956, 21.173714372000234],
            [-67.126172562999898, 21.189160372000174],
            [-67.119326562999959, 21.204605372],
            [-67.112479562999795, 21.220051372000057],
            [-67.105630561999988, 21.235496372000114],
            [-67.098780561999945, 21.250941372000113],
            [-67.091928561999907, 21.266386372000113],
            [-67.085075561999986, 21.281830372000229],
            [-67.078221561999896, 21.297274372],
            [-67.071365561999926, 21.312717372],
            [-67.06450756199996, 21.328161372000125],
            [-67.057649561999881, 21.343604372000129],
            [-67.05078956199992, 21.35904737200002],
            [-67.043927561999965, 21.374489372000141],
            [-67.042416561999914, 21.377888371999973],
            [-67.035297561999926, 21.393229372000064],
            [-67.028177561999939, 21.408569372000102],
            [-67.021055561999958, 21.423908372000085],
            [-67.013932561999866, 21.439248372000122],
            [-67.006807561999949, 21.454587372000219],
            [-66.999681561999921, 21.469925372000091],
            [-66.992553561999898, 21.485263372000134],
            [-66.985424561999878, 21.500601372000176],
            [-66.984361561999947, 21.50288937199997],
            [-66.977366561999872, 21.518281372000217],
            [-66.970370561999971, 21.533673372000067],
            [-66.963373561999958, 21.549065371999973],
            [-66.957972562, 21.560944372000165],
            [-66.950993561999837, 21.576344372000165],
            [-66.944013561999839, 21.59174337200011],
            [-66.937032561999899, 21.607142372000055],
            [-66.930048561999854, 21.622541372],
            [-66.923064561999979, 21.637940372000116],
            [-66.916078561999882, 21.653338372000178],
            [-66.909091561999901, 21.668736372000183],
            [-66.902102561999925, 21.684134372000244],
            [-66.895111561999954, 21.699531372000195],
            [-66.888119561999986, 21.714928372000145],
            [-66.881126561999849, 21.730324371999984],
            [-66.874131562, 21.745721372000105],
            [-66.867135561999987, 21.761117372],
            [-66.86013756199992, 21.776512372000127],
            [-66.853138561999913, 21.791907372000082],
            [-66.846137561999967, 21.807302372000038],
            [-66.83913456199997, 21.822697372000164],
            [-66.832131561999915, 21.838091372000065],
            [-66.825125561999869, 21.853485371999966],
            [-66.824777561999952, 21.854251372000078],
            [-66.820612561999951, 21.854002372000082],
            [-66.802748561999977, 21.852850371999978],
            [-66.784891561999984, 21.851613371999974],
            [-66.767041561999974, 21.850291372000015],
            [-66.74919856199989, 21.8488843720001],
            [-66.731364561999953, 21.847392372000115],
            [-66.713538561999826, 21.845815372000175],
            [-66.705184561999943, 21.845046372000013],
            [-66.69757556199994, 21.844362372000035],
            [-66.679759561999958, 21.842700372000024],
            [-66.661952561999783, 21.840953372000058],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "PRK", Country: "North Korea" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [130.604372403691826, 42.421864144016482],
            [130.63275419847551, 42.411373186334785],
            [130.659009336779576, 42.375273167447702],
            [130.652772362609227, 42.339991222312804],
            [130.655045199480554, 42.318118143001115],
            [130.696997270351375, 42.292606797378696],
            [130.697581728576012, 42.292306307228429],
            [130.883333000000306, 42.15],
            [131.649680865000192, 41.621616065000111],
            [132.302260595000035, 40.843773211000112],
            [132.310758564000167, 40.825321074000087],
            [132.332950909000061, 40.777240319000086],
            [132.343145355000132, 40.76868474700008],
            [132.42020688400018, 40.703862171000182],
            [132.612218825000099, 40.492460605000147],
            [132.81555409600017, 40.268984662999955],
            [130.776437843000082, 39.28955680300011],
            [130.406049175000049, 39.154322595000039],
            [130.402482482000181, 39.153060801000109],
            [130.321440648000049, 39.047058552000109],
            [129.462357642000114, 38.802669947000012],
            [128.52369635400018, 38.652521137000136],
            [128.387266733000075, 38.62804458],
            [128.379997441000256, 38.627186323000117],
            [128.363554234935151, 38.625245047954323],
            [128.30850021827942, 38.587564202078099],
            [128.306636250646108, 38.560409182646808],
            [128.313382341616744, 38.524645110439693],
            [128.307736291621922, 38.488600076837642],
            [128.297336193770917, 38.459436082847546],
            [128.279691281708807, 38.430273094685774],
            [128.260527233511112, 38.411664263755981],
            [128.243563434903052, 38.395273116774305],
            [128.224972373607926, 38.38166425874077],
            [128.200809190704405, 38.365545020699003],
            [128.176091293447058, 38.35000011066434],
            [128.158445207918618, 38.339573190723101],
            [128.13707236058994, 38.329300162524405],
            [128.079954216517393, 38.311936044219678],
            [128.054691327914128, 38.306654104099906],
            [128.010527247597309, 38.305545010668581],
            [127.97970028401258, 38.307209153729715],
            [127.885272277145219, 38.313609072098231],
            [127.669009283968734, 38.319718138425699],
            [127.537491355446349, 38.307491120953429],
            [127.450818285418592, 38.312491093849488],
            [127.366382345354538, 38.323327050666919],
            [127.251391350645434, 38.31888212740067],
            [127.197482302031773, 38.311936044219678],
            [127.130818176044073, 38.298191064078154],
            [127.100963345592248, 38.28416411671283],
            [127.068045264797462, 38.259991210801573],
            [127.050118217873916, 38.24999109737108],
            [127.005800245814356, 38.229235996308134],
            [126.986645250072087, 38.213745065730095],
            [126.969836181397085, 38.188600194323911],
            [126.955109345114721, 38.15444511225509],
            [126.935254125179227, 38.128600184293575],
            [126.824436144796692, 38.016109176032572],
            [126.807209322066313, 38.000544987620614],
            [126.773463444511577, 37.973600186321576],
            [126.736645264158284, 37.955127142223418],
            [126.688491230140443, 37.833909063995023],
            [126.662918471000097, 37.785668867000069],
            [126.627168843000078, 37.77772450500008],
            [126.567586128000102, 37.769780143],
            [126.53183649900015, 37.781696686000018],
            [126.508003414000228, 37.79361322900003],
            [126.480198147000152, 37.805529771000138],
            [126.45239288000019, 37.821418495],
            [126.420615432000176, 37.841279400000175],
            [126.392810166000203, 37.845251581000028],
            [126.349116175000205, 37.841279400000175],
            [126.329255270000118, 37.837307218999953],
            [126.289533460000058, 37.833335038000016],
            [126.265700374000204, 37.833335038000016],
            [126.233922927000179, 37.833335038000016],
            [126.194201117000119, 37.817446314000151],
            [126.18625675500013, 37.789641048000092],
            [126.18625675500013, 37.769780143],
            [126.18625675500013, 37.749919238000118],
            [126.178312393000084, 37.722113971000041],
            [126.162423669000219, 37.718141790000018],
            [126.138590583000195, 37.718141790000018],
            [126.10681313600017, 37.718141790000018],
            [126.071063507000218, 37.710197428000029],
            [126.019425154000061, 37.702253066000068],
            [125.983675526000212, 37.702253066000068],
            [125.94792589700026, 37.694308704],
            [125.912176268000138, 37.690336523000141],
            [125.860537916000141, 37.682392160999981],
            [125.796983020000226, 37.679413026000034],
            [125.716546355000247, 37.691329569000047],
            [125.662921912000144, 37.694308704],
            [125.618234876000116, 37.682392160999981],
            [125.609275088000146, 37.646553006],
            [125.606318334000122, 37.634725990000121],
            [125.585464383000186, 37.55131018900012],
            [125.566164470000246, 37.484502797000019],
            [125.546735619000145, 37.41724908200014],
            [125.519923397000099, 37.321916738000127],
            [125.460340683000112, 37.29510451700007],
            [125.362029204000152, 37.26531316],
            [124.947794329000232, 37.107958476000036],
            [124.931146086000041, 37.224496177000091],
            [124.908948429000162, 37.346583293],
            [124.88675077100018, 37.479769236999957],
            [124.87010252800016, 37.607405767000031],
            [124.864553114000188, 37.696196397000037],
            [124.86600310500026, 37.788151480000025],
            [124.86376875400012, 37.890931662000057],
            [124.854831346000225, 37.975837031000125],
            [124.791487730000227, 38.011976904000122],
            [124.654057840000206, 38.044812592000014],
            [124.620794652000114, 38.051658843000084],
            [124.596253200000177, 38.056466389000136],
            [124.587950474000223, 38.057925047000126],
            [124.413582018000199, 38.081759360000078],
            [124.105387709000155, 38.126830507000179],
            [124.095665597000249, 38.128234683000088],
            [123.547708292000181, 38.20473401200006],
            [123.569518193000164, 38.227253603000136],
            [123.714893387000217, 38.368932246000142],
            [123.742038392000183, 38.395244135],
            [123.825871593000244, 38.476590175000169],
            [123.861888786000037, 38.545718821000023],
            [124.071747096000166, 38.946520818999986],
            [124.106684934000185, 39.110018029000045],
            [124.148555000000187, 39.365044793000081],
            [124.186618696000124, 39.585814230999986],
            [124.194231435000091, 39.692392580000032],
            [124.205650544000179, 39.768519971999964],
            [124.220876022000226, 39.814196407999972],
            [124.235275498000163, 39.842995359],
            [124.236101528000205, 39.844647392],
            [124.281777885000196, 39.890323794000082],
            [124.284696307000246, 39.893651015000145],
            [124.301676450000201, 39.913009965000171],
            [124.315204595415338, 39.929243369321725],
            [124.327696033000194, 39.944232752500042],
            [124.355449533000154, 39.982394252500072],
            [124.360887403993473, 39.99145712760496],
            [124.373663450000123, 40.012749965],
            [124.376265616000211, 40.03616654000011],
            [124.368459616000138, 40.063920540000012],
            [124.368820751500124, 40.0675331475001],
            [124.370194450000241, 40.081266965000154],
            [124.373600120183454, 40.093617845023303],
            [124.381363322338785, 40.102218031285204],
            [124.410809283552481, 40.130273099481954],
            [124.554700242301038, 40.242218110568658],
            [124.626927269803645, 40.283609122031024],
            [124.688718324909502, 40.296800058371318],
            [124.710991221003638, 40.307009048829386],
            [124.812472431774864, 40.396664232377347],
            [124.885127271616881, 40.470373180365456],
            [124.96220021263494, 40.459164228855641],
            [125.039982262663585, 40.460682191524981],
            [125.024709261568745, 40.49416420676711],
            [125.042763378144883, 40.537500155047653],
            [125.140545152120268, 40.585264262788471],
            [125.286100253929789, 40.647491176860669],
            [125.314991333151767, 40.649991163308655],
            [125.369136248521414, 40.639991217516339],
            [125.412691299751799, 40.653800067760315],
            [125.457491231245314, 40.70332705738177],
            [125.553036210532895, 40.741382071371035],
            [125.603863400978611, 40.755827108067777],
            [125.649845180823064, 40.802600139574182],
            [125.688582314094702, 40.852145066468452],
            [125.703109325804718, 40.859973093503939],
            [125.93254515041022, 40.877627058021389],
            [126.011791351289759, 40.896936113144463],
            [126.124691228788862, 41.034718173203643],
            [126.279163334497383, 41.152773088807848],
            [126.286227267237194, 41.17262713527704],
            [126.309982251455011, 41.214436068433017],
            [126.366927225407466, 41.275691184648181],
            [126.411100358179766, 41.319164260865293],
            [126.437763359893296, 41.3424910974678],
            [126.456100281883465, 41.357218101388057],
            [126.4840002848712, 41.366727035291191],
            [126.510272354619616, 41.389991175257549],
            [126.583182339594913, 41.570827048735552],
            [126.568045125331963, 41.589718014527236],
            [126.566082251240715, 41.611764263958761],
            [126.645536155681526, 41.66054509655747],
            [126.712772430381534, 41.691100151202491],
            [126.795536180757296, 41.708045006709241],
            [126.847900276973661, 41.73582699249998],
            [126.868863416873893, 41.761382074248942],
            [126.904709296456105, 41.792218090288983],
            [126.927618211365001, 41.796109137392008],
            [127.048027268301922, 41.708327141570933],
            [127.060809335403945, 41.691100151202491],
            [127.057036305498087, 41.643500161126482],
            [127.100809286211557, 41.621373110147985],
            [127.134991358008705, 41.593609061630062],
            [127.159418235586855, 41.529991086903109],
            [127.270827307783037, 41.47249106332076],
            [127.620818202079278, 41.418053954805416],
            [127.694154155375003, 41.413127072105212],
            [127.835272380545206, 41.409991066842267],
            [127.871091270399091, 41.424164194599385],
            [127.92802719189612, 41.447345018448104],
            [128.011654110663386, 41.415827050763639],
            [128.04412728003652, 41.389436125627739],
            [128.114336280525009, 41.371791213565686],
            [128.152909296131867, 41.381236109728277],
            [128.192672374354999, 41.407491080394195],
            [128.222472387159854, 41.441100165288887],
            [128.282200320612475, 41.530273221764716],
            [128.300245217095011, 41.582836136724765],
            [128.269436190783182, 41.636936125093357],
            [128.254272322067919, 41.650409028657279],
            [128.226627296575572, 41.663882099858981],
            [128.203854168498395, 41.676382199737546],
            [128.155672306702428, 41.714573168196708],
            [128.098436145432885, 41.803044994651017],
            [128.068845177293696, 41.884991174188897],
            [128.060791341786199, 41.910545082471188],
            [128.050109276711424, 41.98164507926974],
            [128.056636264732305, 42.001244986433946],
            [128.275527319837664, 42.038327029099875],
            [128.336091264315229, 42.039718089754714],
            [128.361354152918494, 42.037500070530157],
            [128.422482367119443, 42.028600165713655],
            [128.44670036766999, 42.012773120817585],
            [128.472882248140053, 41.997627021737046],
            [128.497054148222929, 41.995545124620563],
            [128.570254147049042, 42.000273188576998],
            [128.683091327912081, 42.021027116173585],
            [128.844972365496261, 42.029718143962398],
            [128.895536196543247, 42.022764181792482],
            [128.926927262213297, 42.027354111984224],
            [128.949263357857632, 42.052073182707872],
            [128.958082293489355, 42.078400070103342],
            [129.01410022894791, 42.09419107281569],
            [129.016691242864823, 42.094991209295173],
            [129.114409314375763, 42.139436083368949],
            [129.152463322536704, 42.168054080184717],
            [129.211209232210393, 42.221309172711059],
            [129.209409302317539, 42.26582713698069],
            [129.246063365006222, 42.375827043611039],
            [129.3492724213028, 42.44623603630977],
            [129.445118310915319, 42.432773191029852],
            [129.514709229861779, 42.386936082834652],
            [129.695527166067137, 42.435818168823928],
            [129.71662726626576, 42.452491115391055],
            [129.728291355119126, 42.475400197938043],
            [129.732882291139248, 42.522218156445476],
            [129.725509233809333, 42.547491103332746],
            [129.75330932451098, 42.713882107752099],
            [129.807463292336223, 42.853609104629172],
            [129.847045153812161, 42.942482088970749],
            [129.877672292824712, 42.991791149108948],
            [129.904591277862124, 43.004573216211028],
            [129.929218315288693, 43.006100063697701],
            [129.947554231450368, 42.995400061350168],
            [130.034145158826931, 42.958045103916035],
            [130.193163441192524, 42.906653979023176],
            [130.251418338976777, 42.88794506816923],
            [130.250272365171412, 42.831664108588186],
            [130.243291245635106, 42.811664217003383],
            [130.23621826043987, 42.786245089724602],
            [130.246782308317421, 42.714154016691722],
            [130.328445179527279, 42.641800087174616],
            [130.376618324144005, 42.61749105915537],
            [130.514163343980641, 42.568054091174375],
            [130.5224912677227, 42.537218075134049],
            [130.567472415963408, 42.443045045762076],
            [130.586491289597717, 42.42714507830793],
            [130.604372403691826, 42.421864144016482],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "PRT", Country: "Portugal" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-15.085312471999771, 29.283547531000124],
              [-15.115589513999907, 29.246080142999972],
              [-16.076909514999841, 29.30596014300005],
              [-16.333990224999809, 29.322441306000087],
              [-16.501499514999921, 29.333180143000163],
              [-16.87709951599993, 29.382180143000085],
              [-17.034773959999882, 29.560295191000193],
              [-17.758939516999931, 30.378340144000219],
              [-17.981632395999839, 30.626994785000136],
              [-18.20257951699989, 30.873700145000157],
              [-18.247031979999917, 30.877923857000042],
              [-18.934166784999832, 30.9432129220001],
              [-20.494289518999778, 31.091450145000181],
              [-20.701139518999867, 31.102340145000085],
              [-20.72046751899984, 31.136448145000088],
              [-20.748308970333198, 31.185581270000114],
              [-20.744660518999865, 31.190348381000092],
              [-20.772261518999926, 31.233192381000094],
              [-20.797683142999915, 31.274229339000044],
              [-20.907989518999784, 31.510600145000126],
              [-21.011409519999916, 31.72290014500004],
              [-21.076729519999844, 31.891650146000018],
              [-21.125719519999848, 32.062570146000098],
              [-21.180149519999816, 32.351080146000129],
              [-21.218259519999833, 32.715790146000131],
              [-21.191029519999859, 33.091390147000141],
              [-21.131149519999894, 33.472440147000157],
              [-21.027719519999835, 33.793600147000177],
              [-20.891629518999821, 34.120210148000183],
              [-20.739209518999871, 34.392380148000086],
              [-20.655929518999983, 34.503430148000177],
              [-20.563389518999827, 34.661290148000205],
              [-20.438189518999934, 34.808260147999988],
              [-20.345649518999949, 34.922580148000122],
              [-20.214999518999832, 35.05322014800015],
              [-20.089799518999826, 35.162090149000107],
              [-19.97003951899984, 35.270960149000189],
              [-19.844839517999901, 35.34716014900016],
              [-19.599879517999966, 35.543130149000206],
              [-19.40935951799986, 35.641110148999985],
              [-19.099079517999883, 35.804410148999978],
              [-18.777909526999906, 35.929610145000083],
              [-18.36965, 36.05481],
              [-18.30705, 36.06569],
              [-18.067529763666641, 36.114680082000177],
              [-18.029429517999858, 36.131010152999977],
              [-17.893339758499849, 36.174560074000055],
              [-17.659269707999954, 36.261660078000091],
              [-17.43609, 36.321530000000166],
              [-17.16936, 36.37052000000017],
              [-16.771979999999701, 36.424950000000138],
              [-16.4236, 36.45761],
              [-16.128019999999879, 36.45271],
              [-15.8504, 36.43638],
              [-15.610889999999813, 36.403720000000192],
              [-15.469359999999824, 36.38739],
              [-15.251619999999832, 36.349280000000135],
              [-15.03388, 36.300290000000132],
              [-14.827029999999866, 36.23497],
              [-14.582069999999817, 36.136980000000221],
              [-14.337109999999882, 36.039],
              [-14.23641, 36.00089],
              [-13.82271, 35.76682],
              [-13.539649999999824, 35.56541],
              [-13.191269999999747, 35.249690000000214],
              [-12.875549999999862, 34.890410000000202],
              [-12.679579999999817, 34.580130000000167],
              [-12.625847834999888, 34.469229949000066],
              [-12.590107907999936, 34.395464826000023],
              [-12.48362, 34.175680000000227],
              [-12.908772424999825, 32.754493583000169],
              [-12.913034371999856, 32.740247602000125],
              [-12.987132159999817, 32.492554649000169],
              [-13.055199511999774, 32.265020146000154],
              [-13.874449512999831, 31.722860145000084],
              [-14.680109513999923, 29.784980143999974],
              [-14.741072257999861, 29.709539658000153],
              [-15.085312471999771, 29.283547531000124],
            ],
          ],
          [
            [
              [-8.163808843654806, 41.817182080224484],
              [-8.136108832877312, 41.809164119262277],
              [-8.087781796517959, 41.809436028202072],
              [-8.067499770071606, 41.815273185589945],
              [-8.055063875571477, 41.819518116645838],
              [-8.03528191346993, 41.827218068200409],
              [-7.926663847401016, 41.877773182068267],
              [-7.876663950801117, 41.852636022012916],
              [-7.858054952233317, 41.860000194525412],
              [-7.75944588441223, 41.897500158884952],
              [-7.692781926062537, 41.90388213998034],
              [-7.598054852889049, 41.865273249827681],
              [-7.60916389211269, 41.835691166506081],
              [-7.4186088665993, 41.82277314493399],
              [-7.331108838002024, 41.844991055743264],
              [-7.26833676494573, 41.86360910676666],
              [-7.207346013957419, 41.883835980289916],
              [-7.200554995985556, 41.883609165989469],
              [-7.186945970313843, 41.898327117454471],
              [-7.178608826478069, 41.931591203213429],
              [-7.184999860029109, 41.977354047746132],
              [-7.131181838884004, 41.993809064829975],
              [-7.098890892086558, 41.983609126827446],
              [-7.075836802614276, 41.959164144338217],
              [-7.056108819969268, 41.951109135364106],
              [-7.0087549225712, 41.954582093135571],
              [-6.982845956869369, 41.973864158530262],
              [-6.940417768705061, 41.951109135364106],
              [-6.906108794893498, 41.94471810181301],
              [-6.697217853218433, 41.936664266305343],
              [-6.602290787834534, 41.949164198545759],
              [-6.548608888797617, 41.921944973735833],
              [-6.528054953411299, 41.839436033217396],
              [-6.547499795366207, 41.765000038945573],
              [-6.545626775277412, 41.687009111889154],
              [-6.501808867562829, 41.659436003126302],
              [-6.460972905730159, 41.670691055103646],
              [-6.429445885590212, 41.680000164434375],
              [-6.347217738828817, 41.672218070228325],
              [-6.303608875779958, 41.663327050229157],
              [-6.256108797990066, 41.640409082864778],
              [-6.190454858975954, 41.579636093721604],
              [-6.21229089027554, 41.536527127379372],
              [-6.295836672219764, 41.43610019239253],
              [-6.357781786706425, 41.38055399044606],
              [-6.438054938365781, 41.318882126003757],
              [-6.51028179823038, 41.265000067118137],
              [-6.558336758012047, 41.242218054223656],
              [-6.589163889234953, 41.241936087000056],
              [-6.618054968456818, 41.246382183732919],
              [-6.643890843962822, 41.244718040671728],
              [-6.688472845973081, 41.210273112390155],
              [-6.753336706791572, 41.136664244326198],
              [-6.75430883992459, 41.10749119788062],
              [-6.774445859445791, 41.070691122438234],
              [-6.805554957892127, 41.045553962382883],
              [-6.841663861596828, 41.030136122000897],
              [-6.924408836509429, 41.030964254037087],
              [-6.930554950849057, 41.015553957367786],
              [-6.891390843428439, 40.974718163173122],
              [-6.862917853538079, 40.952636039195909],
              [-6.801736833346979, 40.857009084894855],
              [-6.819445950787554, 40.733327062396882],
              [-6.796108888263177, 40.524436120722044],
              [-6.815554903684529, 40.387773212378136],
              [-6.831108866174532, 40.317218039287525],
              [-6.862299771996163, 40.271518059028608],
              [-6.878336699748502, 40.261800080459921],
              [-6.910281809220123, 40.256382185870052],
              [-6.946526835032444, 40.25898208460444],
              [-7.008336833239639, 40.226800102548452],
              [-7.02721774074729, 40.187773123064289],
              [-7.00889087704104, 40.132773085930182],
              [-6.982636744565355, 40.119164227896476],
              [-6.946945930192442, 40.113191116038934],
              [-6.928890807787923, 40.101664155121739],
              [-6.882499823429271, 40.044164131539446],
              [-6.870690895288533, 40.015973108872743],
              [-6.989717776387863, 39.724718065966499],
              [-7.017217794954888, 39.675000136590029],
              [-7.052872902420347, 39.663753969430161],
              [-7.157708886127807, 39.660345049399268],
              [-7.2055549688817, 39.66721804238415],
              [-7.263608868627387, 39.672082228448573],
              [-7.307217731676189, 39.656664220428411],
              [-7.332845903620921, 39.64527321398117],
              [-7.411108907255141, 39.64874499828619],
              [-7.532508872788128, 39.669418124335834],
              [-7.52499986098843, 39.636664161205601],
              [-7.502781950179156, 39.602773109087479],
              [-7.375690840012282, 39.493745000314107],
              [-7.322781920088175, 39.388327144886546],
              [-7.313608765227286, 39.344718114199736],
              [-7.242781850834945, 39.213054005285528],
              [-7.145836758436928, 39.113882176483742],
              [-7.124863895528847, 39.106382217139299],
              [-7.102781939189583, 39.104718074078278],
              [-7.053263834385518, 39.119918152615242],
              [-7.01319079338208, 39.111800111728996],
              [-6.982781919128854, 39.09138213081286],
              [-6.961108832027662, 39.056664120124992],
              [-6.955872824737355, 39.023191157338374],
              [-7.059999867072293, 38.860545019630308],
              [-7.084163888166188, 38.835544987511355],
              [-7.164163957419248, 38.781945063487569],
              [-7.207354898774668, 38.749264190553276],
              [-7.257154970802333, 38.723400151852502],
              [-7.267217780868691, 38.703327002433753],
              [-7.317081890636814, 38.444718131382558],
              [-7.296526781784166, 38.43138218811697],
              [-7.277636821821091, 38.41944501685731],
              [-7.192217852149781, 38.320273188055523],
              [-7.1697178064789, 38.284436025652553],
              [-7.144999909221553, 38.246109102723494],
              [-7.129999822894831, 38.225827076277255],
              [-7.102217837104263, 38.18916412877121],
              [-7.087781852862918, 38.177009028028237],
              [-7.01680875807881, 38.193809044247814],
              [-6.993336746912973, 38.209444981751176],
              [-6.947572896551719, 38.217427068167524],
              [-6.933745941396921, 38.209073160525236],
              [-6.9415268621363, 38.170409117449481],
              [-6.951663935864985, 38.140000075558135],
              [-7.004026858614793, 38.03110909472133],
              [-7.123608789343905, 38.009991057249863],
              [-7.210836741363494, 37.996936075379381],
              [-7.253545890922908, 37.983673222309903],
              [-7.251945953240408, 37.959444995837373],
              [-7.252781796627346, 37.9309731794134],
              [-7.281663823393842, 37.865554101327007],
              [-7.319999798778383, 37.815273243331774],
              [-7.386945891989626, 37.769718102360287],
              [-7.42305496333222, 37.743891111671587],
              [-7.446945902019934, 37.699436011675743],
              [-7.524445984824979, 37.55250007513348],
              [-7.512536809121968, 37.528545098705195],
              [-7.50305486494733, 37.522500070118156],
              [-7.484726827774693, 37.498882213836623],
              [-7.471390884509105, 37.481382141062056],
              [-7.461390938716704, 37.45721811996799],
              [-7.447217810959842, 37.418609062177325],
              [-7.434717878719255, 37.366945022516177],
              [-7.431854751575088, 37.253190727146375],
              [-7.431475612117765, 37.25192367034731],
              [-7.409445937830128, 37.201664117178439],
              [-7.407600767037877, 37.198967056974084],
              [-7.405979623499888, 37.1899227830001],
              [-7.396519182499901, 37.137143481000123],
              [-7.385854771999902, 37.077647299000034],
              [-7.366542881999862, 36.969907279000211],
              [-7.362671542999976, 36.895384004000107],
              [-7.357536033999793, 36.796525447000192],
              [-7.493573579999861, 35.999449025000104],
              [-7.531427592999876, 35.777653334000092],
              [-7.533329999999864, 35.76667],
              [-7.546606214999883, 35.758623647999968],
              [-7.548615374999827, 35.75740595100018],
              [-7.558469882999873, 35.751433403000192],
              [-8.08333, 35.433330000000183],
              [-8.349999999999739, 35.316670000000101],
              [-8.88333, 35.18333],
              [-9.216669999999851, 35.11667000000017],
              [-10.499999999999886, 35.01667],
              [-11.666669999999897, 34.916670000000124],
              [-12.045882988999892, 34.937164987000074],
              [-12.20526151099989, 34.945779148000042],
              [-12.211019510999847, 34.946090148000224],
              [-12.255529510999907, 34.991220148000025],
              [-12.31751951099983, 35.057240148000062],
              [-12.377589511999815, 35.124480149000078],
              [-12.43568951199984, 35.192900149000081],
              [-12.480729511999868, 35.248460149000152],
              [-12.524479511999857, 35.304730149000079],
              [-12.566899511999907, 35.36169014900014],
              [-12.607989511999847, 35.419320149000214],
              [-12.647719511999981, 35.477600149000011],
              [-12.686079511999822, 35.536500149000176],
              [-12.7230395119999, 35.596000149000076],
              [-12.758599511999705, 35.656080149000161],
              [-12.792739511999855, 35.716720149000196],
              [-12.825429511999801, 35.777890149000029],
              [-12.856669511999826, 35.839580149000142],
              [-12.886439511999924, 35.901750149000122],
              [-12.914599511999825, 35.963970148999977],
              [-12.941459511999824, 36.027030149000097],
              [-12.966789511999821, 36.090510149000124],
              [-12.990599511999903, 36.154370149000101],
              [-13.012869511999838, 36.218610150000245],
              [-13.033589511999821, 36.283180150000192],
              [-13.052739511999903, 36.348080149999987],
              [-13.07032951199983, 36.413270150000073],
              [-13.086319511999818, 36.478720150000186],
              [-13.100719511999898, 36.544420150000064],
              [-13.11350951199978, 36.610340150000184],
              [-13.124689511999861, 36.676440150000076],
              [-13.134239511999738, 36.742710150000221],
              [-13.142169511999839, 36.809130149999987],
              [-13.148459511999818, 36.875650150000013],
              [-13.153099511999869, 36.942260150000209],
              [-13.156089511999824, 37.008930150000026],
              [-13.160929511999711, 37.080450150000047],
              [-13.202119511999854, 37.13843015000009],
              [-13.240989511999885, 37.195440150000195],
              [-13.279579511999827, 37.25459015000007],
              [-13.316739511999856, 37.314330151000149],
              [-13.352449511999907, 37.374640151000108],
              [-13.386709511999868, 37.435490151000124],
              [-13.419489511999871, 37.49687015100011],
              [-13.450779512999873, 37.558750151000083],
              [-13.480559512999889, 37.621100151000235],
              [-13.50880951299979, 37.683910151000248],
              [-13.53553951299989, 37.747150150999971],
              [-13.560709512999836, 37.810790151000049],
              [-13.58430951299988, 37.874820151000108],
              [-13.606339512999909, 37.939190151000105],
              [-13.62677951299986, 38.003910151000184],
              [-13.645609512999727, 38.068920150999986],
              [-13.662839512999824, 38.134220151000136],
              [-13.678429512999855, 38.199770151000052],
              [-13.691809512999896, 38.25894015099999],
              [-13.706099512999913, 38.324680151000109],
              [-13.718749512999864, 38.390630152000142],
              [-13.729739512999856, 38.456770152000161],
              [-13.73906951299989, 38.523060152000141],
              [-13.746719512999846, 38.589480152000078],
              [-13.752699512999925, 38.656010152000079],
              [-13.756989512999894, 38.722620152000047],
              [-13.759589512999867, 38.789280152000117],
              [-13.7686495129999, 38.853010152000081],
              [-13.785459512999864, 38.918390152000086],
              [-13.800619512999845, 38.984030152000116],
              [-13.814119512999866, 39.049880152000128],
              [-13.825969512999961, 39.115930152000203],
              [-13.836129512999833, 39.182150152000247],
              [-13.84461951299977, 39.248510152],
              [-13.851409512999908, 39.314990152000121],
              [-13.856499512999847, 39.38156015200002],
              [-13.859889512999871, 39.448190153000155],
              [-13.861559512999861, 39.514860152999972],
              [-13.861519512999735, 39.581550153000023],
              [-13.859759512999858, 39.648220153000238],
              [-13.856259512999827, 39.714840153000011],
              [-13.851039512999819, 39.781410153000081],
              [-13.844089512999972, 39.847870153000088],
              [-13.835399512999857, 39.914210153],
              [-13.824979512999846, 39.98041015299998],
              [-13.81280951299982, 40.046430153000188],
              [-13.798909512999842, 40.112250153000048],
              [-13.783279512999968, 40.177840153000034],
              [-13.765899512999908, 40.243170153],
              [-13.746789512999783, 40.308220153000093],
              [-13.725959512999907, 40.372960153000122],
              [-13.703389512999934, 40.437360153000043],
              [-13.679099512999869, 40.501410154000098],
              [-13.653099512999859, 40.56506015399998],
              [-13.625379512999928, 40.628290154000069],
              [-13.595959512999855, 40.691090154],
              [-13.564839512999868, 40.753410154000107],
              [-13.532029512999713, 40.815240154000037],
              [-13.497539512999822, 40.876550153999972],
              [-13.461379512999827, 40.937320154000076],
              [-13.423559512999816, 40.997510154000054],
              [-13.384089511999889, 41.057100154000068],
              [-13.342979511999886, 41.116070154],
              [-13.300259511999911, 41.174380154],
              [-13.258309511999869, 41.24192015400007],
              [-13.270274808999829, 41.294685609000055],
              [-13.27341225299989, 41.308642341000166],
              [-13.285500304999857, 41.369146848500179],
              [-13.285812287999931, 41.370708421000131],
              [-13.28598851799984, 41.371590511000079],
              [-13.286379999999838, 41.373550000000137],
              [-13.286604730999954, 41.374846420000097],
              [-13.297839999999837, 41.43966],
              [-13.301739999999825, 41.466260000000148],
              [-13.298854328999823, 41.466517909000061],
              [-13.288206511999704, 41.467469154000185],
              [-12.556189856999822, 41.532893896000019],
              [-12.351526902999865, 41.551185858000053],
              [-12.097719999999867, 41.57387],
              [-10.893699999999853, 41.68129],
              [-9.689679999999896, 41.788540000000125],
              [-9.555880592999813, 41.810779492000108],
              [-9.190090806999876, 41.871579305000125],
              [-8.876279243999818, 41.871579305000125],
              [-8.86782520499986, 41.876379107000048],
              [-8.862170507999792, 41.879589576000058],
              [-8.845829436999907, 41.888867267000194],
              [-8.838564673999826, 41.894248966000077],
              [-8.832652096999823, 41.898683019000032],
              [-8.830790673999843, 41.900078966000024],
              [-8.823663673999818, 41.904613966000056],
              [-8.816537673999846, 41.908824966000111],
              [-8.809734673999913, 41.913359966000201],
              [-8.80401515799997, 41.915076351000039],
              [-8.80325667399984, 41.915303966000124],
              [-8.802107181999929, 41.91575367400003],
              [-8.795805673999837, 41.91821896600004],
              [-8.790621951999924, 41.91977456300009],
              [-8.789327673999821, 41.920162966000134],
              [-8.782524673999887, 41.92178296600008],
              [-8.776046673999815, 41.925021967000134],
              [-8.76794867399974, 41.929232967000189],
              [-8.76345683999989, 41.931585518000077],
              [-8.761145673999863, 41.932795967000118],
              [-8.75563867399984, 41.936358967000047],
              [-8.751427673999956, 41.942189967000047],
              [-8.750469639999835, 41.943787019000013],
              [-8.749484673999859, 41.945428967000169],
              [-8.745018112427715, 41.952474350576949],
              [-8.741181828634865, 41.967836061387743],
              [-8.639999851808, 42.045273112280768],
              [-8.613890893895643, 42.056382151504408],
              [-8.518054894928923, 42.083044985580102],
              [-8.473608847388562, 42.084718013458485],
              [-8.446108828821536, 42.084718013458485],
              [-8.310554846270804, 42.103609146887948],
              [-8.251945896895336, 42.138609124799416],
              [-8.197836688433142, 42.150673198073605],
              [-8.0837549625831, 42.060482075635093],
              [-8.084654843710496, 42.022982111275724],
              [-8.102226833214672, 42.010964138469092],
              [-8.134726824677955, 41.998882127922059],
              [-8.165281879323004, 41.974445024422039],
              [-8.208754955540087, 41.928745044163122],
              [-8.218336812000814, 41.907491219859764],
              [-8.193681946655488, 41.873400175531287],
              [-8.165490923988727, 41.858609133870459],
              [-8.16166391462616, 41.827500203062016],
              [-8.163808843654806, 41.817182080224484],
            ],
          ],
          [
            [
              [-28.473199999999849, 42.4162],
              [-28.376159999999857, 42.41049],
              [-28.239169999999831, 42.427610000000186],
              [-27.988019999999835, 42.4219],
              [-27.782529999999895, 42.427610000000186],
              [-27.565619999999853, 42.41048],
              [-27.245959999999855, 42.38764],
              [-27.000499999999874, 42.353380000000129],
              [-26.652299999999855, 42.256330000000162],
              [-26.189919999999859, 42.14216],
              [-26.007259999999746, 42.06223],
              [-25.87596, 41.999440000000163],
              [-25.73896, 41.96518],
              [-25.316539999999861, 41.828170000000114],
              [-25.076779999999872, 41.74254],
              [-24.71715, 41.59982],
              [-24.34609, 41.405730000000119],
              [-24.072079999999858, 41.217340000000206],
              [-24.017849999999839, 41.14655],
              [-23.926519999999869, 41.060920000000124],
              [-23.875139999999817, 41.04379],
              [-23.778099999999824, 41.009540000000129],
              [-23.675339999999892, 40.98671000000013],
              [-23.367079999999817, 40.895370000000128],
              [-23.178699999999822, 40.821150000000159],
              [-22.847599999999716, 40.67843],
              [-22.590709999999717, 40.54143],
              [-22.36808, 40.393],
              [-21.96848, 40.09615],
              [-21.66592, 39.78788],
              [-21.443289999999877, 39.496740000000216],
              [-21.237779999999816, 39.16563],
              [-21.10649, 38.89162],
              [-20.980899999999849, 38.4292200000001],
              [-20.89528, 37.88005],
              [-20.855319999999836, 37.64028],
              [-20.87816, 36.589890000000139],
              [-20.98663, 36.173160000000223],
              [-21.18644, 35.69364],
              [-21.426209999999855, 35.334000000000117],
              [-21.58605, 35.09994],
              [-22.1455, 34.56904],
              [-22.434929999999838, 34.31958],
              [-22.73178, 34.154030000000205],
              [-23.35973, 33.86289],
              [-24.010519999999872, 33.68022],
              [-24.56425, 33.61172],
              [-24.76976, 33.594600000000128],
              [-24.82114, 33.537510000000168],
              [-24.889639999999815, 33.52038],
              [-25.534709999999791, 33.54893],
              [-25.60321, 33.57177],
              [-25.71738, 33.634560000000135],
              [-26.008509999999887, 33.67453],
              [-26.404109999999832, 33.748740000000225],
              [-26.957829999999831, 33.92572000000024],
              [-27.31746, 34.09698],
              [-27.836909999999818, 34.39954],
              [-28.219359999999881, 34.70211],
              [-28.419149999999888, 34.907620000000236],
              [-28.504769999999837, 34.959],
              [-28.62464, 34.993250000000103],
              [-29.355269999999848, 35.0789],
              [-29.74341, 35.19308000000018],
              [-30.002539999999897, 35.258730000000128],
              [-30.21372, 35.32154],
              [-30.68174, 35.51564000000019],
              [-31.263879999999858, 35.778260000000159],
              [-31.70901, 35.95525],
              [-31.745981610999877, 35.98834134700013],
              [-31.81744, 36.05230000000023],
              [-31.85738, 36.063720000000103],
              [-32.0457, 36.09227],
              [-32.268249999999853, 36.13796],
              [-32.70191, 36.24074],
              [-33.124129999999866, 36.38920000000013],
              [-33.48925, 36.53765],
              [-33.78017, 36.70894],
              [-34.13951, 36.943040000000224],
              [-34.60486, 37.328440000000114],
              [-34.96974, 37.75094],
              [-35.226229999999873, 38.150590000000193],
              [-35.44274, 38.69296],
              [-35.55661, 39.246730000000127],
              [-35.579299999999876, 39.663470000000132],
              [-35.52219, 40.13728],
              [-35.476549999999861, 40.293690000000197],
              [-35.402399999999886, 40.51632],
              [-35.26553, 40.824560000000247],
              [-35.054549999999892, 41.16133],
              [-34.792239999999822, 41.509520000000123],
              [-34.52993, 41.81203],
              [-34.290409999999838, 42.0118],
              [-34.14214, 42.13737],
              [-33.8969, 42.31431],
              [-33.39497, 42.57685],
              [-33.00138, 42.73665],
              [-32.676199999999881, 42.856510000000185],
              [-32.3453, 42.92499],
              [-31.871739999999818, 43.016300000000172],
              [-31.500849999999843, 43.050530000000151],
              [-31.107109999999864, 43.06763],
              [-30.547849999999869, 43.03907],
              [-30.22826, 42.99339],
              [-30.05134, 42.96427],
              [-29.82876, 42.918590000000165],
              [-29.56051, 42.85008],
              [-29.35504, 42.78157],
              [-29.06966, 42.69023],
              [-28.81852, 42.58176],
              [-28.561669999999879, 42.45616],
              [-28.473199999999849, 42.4162],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "PRY", Country: "Paraguay" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-58.158890878368254, -20.168055068317969],
            [-58.155835842290259, -20.228617839329132],
            [-58.136946049965246, -20.278890985973248],
            [-58.097226892914875, -20.341390982451742],
            [-58.058890917530306, -20.401108857620372],
            [-58.008826815551942, -20.513472963866874],
            [-57.995972999358486, -20.618054975907455],
            [-57.967745934507803, -20.710135882241161],
            [-57.935908951587606, -20.671463960176439],
            [-57.908890892454536, -20.67639101051482],
            [-57.85958199995423, -20.734726877484022],
            [-57.856672976630051, -20.838609000607548],
            [-57.814590961067978, -20.978755092644718],
            [-57.852645975057328, -21.34166400851295],
            [-57.879172854662954, -21.380281951121034],
            [-57.915555008411729, -21.42917292192773],
            [-57.932499863918565, -21.461672913391013],
            [-57.954308905489825, -21.51389099685349],
            [-57.937781971676515, -21.636526957832118],
            [-57.926399850046579, -21.656663977353233],
            [-57.935672917193642, -21.771945991741333],
            [-57.96333587995889, -21.972226874812335],
            [-57.984026775643343, -22.025691012004344],
            [-57.987226818646548, -22.060835829202944],
            [-57.985108879346342, -22.091826910452383],
            [-57.928890951677346, -22.117781976621856],
            [-57.892226830704857, -22.129026970315394],
            [-57.824863989266589, -22.144026889003868],
            [-57.791672993703571, -22.129172983069097],
            [-57.767781887377851, -22.113055086131808],
            [-57.640008825566582, -22.121108921639546],
            [-57.609446059570729, -22.1749998653424],
            [-57.374590831632815, -22.217781937459705],
            [-57.331946055089787, -22.218055019865901],
            [-57.239581840428002, -22.212499997339933],
            [-57.197781959727621, -22.207226942037479],
            [-57.153335744549196, -22.203608977340764],
            [-56.988890850414577, -22.232782023786342],
            [-56.877499883129389, -22.274173035248708],
            [-56.808335770693787, -22.26472696561973],
            [-56.769526888330773, -22.248045972425615],
            [-56.758054912698555, -22.241390908923819],
            [-56.698126819397686, -22.216108909581237],
            [-56.677781928677319, -22.233681904913738],
            [-56.634172897990481, -22.251946071984122],
            [-56.57389092073646, -22.189446075505629],
            [-56.555972926268311, -22.166672947428538],
            [-56.545972980475796, -22.144445984163866],
            [-56.526117928178309, -22.115417944643582],
            [-56.495281912138154, -22.088308858041742],
            [-56.456945936753698, -22.075835915529581],
            [-56.396526831563364, -22.068681961149309],
            [-56.370835795344703, -22.126108894535705],
            [-56.360835849552387, -22.157709004871521],
            [-56.329445957348725, -22.186673006651347],
            [-56.309172983357797, -22.203335894934554],
            [-56.202917775800586, -22.274726911412131],
            [-56.168199932751008, -22.283891013817495],
            [-56.085563922579809, -22.292155067457401],
            [-56.046672897565429, -22.27833582365345],
            [-55.988890906759565, -22.273335850757206],
            [-55.849726838501141, -22.288890986713653],
            [-55.744308815435545, -22.397500000327156],
            [-55.741254952824136, -22.42014605875174],
            [-55.740835857664138, -22.453608963254467],
            [-55.739726764232813, -22.477782036803887],
            [-55.734308869643229, -22.526255085917072],
            [-55.727226831992425, -22.546254977501789],
            [-55.70944596546056, -22.573890950538825],
            [-55.689726867632885, -22.591117940907253],
            [-55.664172959350594, -22.607226953027137],
            [-55.626108892905847, -22.620908901256712],
            [-55.60917292221643, -22.638473011771879],
            [-55.610072970981889, -22.734099966072833],
            [-55.632854983876484, -22.777426861898064],
            [-55.648399893910977, -22.814926826257505],
            [-55.645008911152814, -22.857917943040562],
            [-55.60639096854473, -23.020281945886993],
            [-55.583890922873849, -23.085281928813572],
            [-55.549172912186009, -23.13750001227605],
            [-55.549655039258141, -23.307017969502667],
            [-55.54360883720463, -23.333055010685399],
            [-55.50242687040793, -23.379717903983817],
            [-55.535208829094927, -23.459172981890944],
            [-55.520835876765631, -23.605281959863873],
            [-55.505281914275571, -23.624172925655358],
            [-55.485008940284644, -23.638055033733366],
            [-55.462990854047632, -23.661390922791185],
            [-55.442154951437885, -23.70270884405771],
            [-55.433063939228276, -23.75055509444978],
            [-55.427781831470526, -23.802781895091499],
            [-55.420564006987973, -23.878335867611881],
            [-55.420281872126282, -23.924726851970505],
            [-55.412090908682245, -23.954308935292104],
            [-55.396664015844749, -23.971108951511695],
            [-55.371254946849689, -23.984446068243685],
            [-55.347499962631758, -23.992635858221163],
            [-55.196390844124693, -24.01930891821867],
            [-55.174999891885022, -24.020554971948201],
            [-55.150281994627761, -24.016391010077072],
            [-55.03139090036035, -23.994446014036001],
            [-54.924726823565123, -23.965282020045919],
            [-54.902572950496392, -23.948963963260283],
            [-54.881454913024839, -23.921108887273689],
            [-54.625699912374728, -23.804926824120102],
            [-54.539454989962394, -23.847500019209733],
            [-54.407226778962894, -23.916673016462838],
            [-54.288608934739671, -24.020281889542005],
            [-54.243899863077019, -24.053608839574821],
            [-54.269581846840197, -24.065691017759875],
            [-54.294308796553054, -24.085417826938397],
            [-54.325835816692916, -24.117499896708154],
            [-54.336672946976734, -24.148054951353174],
            [-54.316672887754038, -24.241672931095437],
            [-54.3026459403888, -24.257226893585411],
            [-54.280135836434113, -24.272635849150078],
            [-54.262990821078859, -24.293264048198637],
            [-54.249446000785667, -24.353546025452573],
            [-54.275972880391294, -24.413335817350671],
            [-54.293617792453347, -24.428890953307118],
            [-54.312499873427498, -24.45055498795297],
            [-54.323335830244844, -24.469726915139759],
            [-54.331390839218983, -24.492782010440465],
            [-54.334308914998559, -24.518473046659139],
            [-54.321535900351876, -24.554790995029492],
            [-54.311808869327677, -24.602781917070715],
            [-54.315563961960805, -24.627781949189668],
            [-54.325008858123397, -24.661946083714085],
            [-54.330554995831932, -24.67944598885073],
            [-54.396390822145463, -24.804445981807632],
            [-54.408054910998999, -24.838890910089091],
            [-54.453617930959496, -25.017226964757114],
            [-54.458335768994147, -25.066526972439902],
            [-54.455417860852549, -25.089726907027924],
            [-54.423546009215016, -25.133126893049081],
            [-54.427508805409303, -25.153055035542423],
            [-54.505699892314084, -25.280417887011012],
            [-54.524446018818338, -25.303199899905508],
            [-54.549590890224522, -25.332226933597383],
            [-54.574308787481868, -25.354446017873059],
            [-54.616455008422577, -25.445690913181636],
            [-54.598917887635736, -25.510455029352215],
            [-54.598917887635736, -25.573226934770432],
            [-54.623335880396553, -25.648608910637108],
            [-54.61167296500949, -25.781946047429741],
            [-54.604445920433477, -25.943608987892659],
            [-54.660354891150433, -25.976254992109659],
            [-54.660972972692292, -26.157917991795287],
            [-54.639590905270154, -26.187782042340586],
            [-54.640972913469483, -26.214308921946298],
            [-54.667499960713286, -26.238881979916343],
            [-54.65875495346782, -26.285555099136644],
            [-54.693199881749393, -26.428054997230092],
            [-54.842226767863821, -26.657499874291275],
            [-54.87659994705399, -26.65528202270481],
            [-54.907781968058202, -26.667781954945283],
            [-54.92889095307433, -26.688263973602048],
            [-54.940417913991496, -26.740835941017508],
            [-54.949308933990665, -26.768335959584647],
            [-54.963335881355789, -26.783199923803295],
            [-54.992508927801367, -26.793890873695332],
            [-55.034445936438146, -26.794999967126657],
            [-55.055626838183656, -26.803264020766548],
            [-55.12999980054326, -26.872708926959376],
            [-55.251108914035143, -26.936108972203343],
            [-55.271117858075456, -26.93749986522019],
            [-55.37944591029364, -26.966390944442168],
            [-55.400835856704902, -26.974026858256309],
            [-55.417645931208199, -26.986391003664963],
            [-55.547499884307655, -27.112226840008816],
            [-55.573335759813546, -27.166673000979557],
            [-55.581117854019482, -27.214999869701032],
            [-55.570281897202136, -27.235417850617253],
            [-55.568481967309197, -27.255835831533574],
            [-55.584590979429066, -27.316808980525323],
            [-55.599999934993633, -27.34208192741248],
            [-55.731672928725146, -27.436673046116155],
            [-55.757781886637446, -27.441946101418509],
            [-55.844726865605082, -27.406254951769341],
            [-55.859026895376417, -27.39152694202059],
            [-55.867572916240022, -27.358408869015449],
            [-55.892508910618545, -27.332635857783501],
            [-55.923199919733463, -27.32652695909411],
            [-55.965272882840054, -27.331955079605677],
            [-56.035699812811799, -27.305135839216376],
            [-56.064446052746348, -27.302782033160085],
            [-56.098617898621796, -27.304726969978347],
            [-56.144026859201489, -27.311464008493502],
            [-56.268126803392931, -27.389382012991973],
            [-56.271390884136565, -27.424999904807208],
            [-56.268890897688578, -27.448608876271322],
            [-56.274999964015961, -27.468335853087837],
            [-56.345554969468594, -27.570281924210434],
            [-56.365626777782779, -27.584726960907261],
            [-56.398055020154686, -27.584445999512056],
            [-56.418617840358138, -27.574717962659449],
            [-56.456390887123888, -27.547226828909743],
            [-56.493890851483229, -27.502364033142356],
            [-56.504172932137323, -27.475281936268743],
            [-56.520281944257221, -27.461945993003226],
            [-56.549999814410739, -27.452499923374248],
            [-56.578199889532982, -27.448617928726733],
            [-56.606945961829553, -27.448335961503119],
            [-56.644445926189007, -27.453890984029186],
            [-56.673055038187357, -27.461390943373416],
            [-56.69667289446889, -27.4741639580201],
            [-56.706272855840496, -27.483435851700662],
            [-56.731390905156644, -27.505281941283954],
            [-56.766535889993321, -27.501946111448831],
            [-56.792646021371894, -27.48972697296557],
            [-56.81333574358996, -27.470281963372585],
            [-56.835835789260841, -27.449446060762838],
            [-56.862499964440929, -27.428199947810413],
            [-56.880699926132678, -27.419582010217539],
            [-56.916181863478016, -27.419308927811244],
            [-56.964726829320739, -27.434173059667984],
            [-56.998608828983379, -27.450835947951205],
            [-57.044235886684419, -27.476808951393579],
            [-57.077781939666977, -27.492226959413657],
            [-57.1216729375775, -27.490282022595395],
            [-57.164455009694706, -27.484172956267912],
            [-57.207226855890212, -27.47194610643372],
            [-57.237363988841707, -27.459581961025052],
            [-57.269172976205198, -27.44221784272024],
            [-57.296954961995937, -27.43111785595201],
            [-57.32361779607146, -27.425554954437018],
            [-57.344926773297857, -27.426117883055852],
            [-57.413063934953669, -27.417781912686493],
            [-57.616954903732278, -27.371664010734179],
            [-57.644863959175609, -27.360699978435846],
            [-57.682499878004847, -27.334999889761676],
            [-57.703899882699915, -27.319446094909694],
            [-57.729590918918689, -27.307081949501125],
            [-57.791390858841964, -27.292226870099782],
            [-57.935835861391666, -27.275555096999241],
            [-58.011117924972353, -27.270282041696802],
            [-58.156390891920154, -27.268890981041963],
            [-58.289726855246329, -27.273890953938121],
            [-58.47972683112971, -27.28610891895498],
            [-58.505563880102187, -27.288608905403066],
            [-58.529163966748882, -27.293335795893029],
            [-58.560981838929877, -27.301109005281546],
            [-58.604617859345126, -27.316917945266908],
            [-58.598963930361265, -27.249235920954817],
            [-58.614863897815411, -27.227226887173302],
            [-58.653517882607218, -27.193045988842542],
            [-58.652090947406634, -27.158890906773621],
            [-58.628063886611017, -27.131108920982882],
            [-58.605835749879844, -27.125000022293577],
            [-58.578608813718915, -27.122499868207413],
            [-58.471163878821869, -26.987264062702124],
            [-58.394445995223293, -26.908055077472881],
            [-58.352763964081817, -26.885390914137389],
            [-58.31667299765013, -26.872782017155316],
            [-58.181672891262735, -26.656108981274429],
            [-58.168608856936942, -26.600000018347018],
            [-58.182917771525894, -26.571108939125054],
            [-58.217226912975519, -26.541390901333543],
            [-58.209935830659049, -26.42034599322011],
            [-58.161945914446136, -26.300555017825204],
            [-58.131390859801115, -26.2572269485335],
            [-58.144726803066732, -26.206946090538366],
            [-58.048335754470088, -26.119163927079214],
            [-58.002917909072806, -26.104173060846151],
            [-57.860073011843696, -25.997917853289024],
            [-57.890908860245844, -25.973890960131399],
            [-57.900772851568263, -25.955072916897677],
            [-57.853890855320373, -25.904446053938571],
            [-57.805554934143402, -25.833055037460909],
            [-57.810981881188667, -25.773135828977459],
            [-57.792917873966616, -25.766673046334986],
            [-57.74889092158628, -25.735135800273142],
            [-57.576663933245726, -25.549445966652684],
            [-57.553890972806641, -25.468335798139833],
            [-57.556326921514369, -25.439999936185757],
            [-57.588199946618289, -25.415417825760315],
            [-57.63208189207333, -25.386390959706532],
            [-57.668617937564932, -25.306117975685197],
            [-57.672672934694475, -25.29429999508902],
            [-57.706390984330682, -25.271390912542032],
            [-57.737290870473174, -25.233263981823313],
            [-57.749726764973218, -25.200282030926061],
            [-57.761108886603154, -25.171526906173995],
            [-57.858063869646998, -25.097499948778449],
            [-57.879172854662954, -25.08486406206805],
            [-57.908054881429507, -25.078054939185449],
            [-57.935563952452043, -25.077499889555639],
            [-58.059717876100024, -25.034446076136618],
            [-58.129173008214821, -25.008054983362726],
            [-58.181808845732547, -24.975554991899443],
            [-58.281672852100684, -24.978617906966434],
            [-58.352226851724822, -24.967917904618815],
            [-58.449308904421173, -24.859581973411608],
            [-58.5326459769758, -24.833199933093212],
            [-58.575835744864634, -24.826390977848703],
            [-58.604446030329484, -24.828608997073161],
            [-58.669726807013092, -24.822226848339483],
            [-58.807917903948265, -24.781526840976753],
            [-58.881390985180502, -24.729999929252074],
            [-58.922499861781262, -24.697781905012405],
            [-58.938608873901046, -24.685555055178213],
            [-58.98972691638761, -24.654726918126983],
            [-59.058617778779109, -24.618617846784304],
            [-59.101954900526323, -24.608335933768373],
            [-59.289726798901427, -24.515281888473353],
            [-59.338890852114218, -24.488890963337454],
            [-59.356945974518851, -24.471390890562816],
            [-59.383199939356359, -24.43513580646642],
            [-59.470835754785639, -24.354726867975273],
            [-59.533617886125739, -24.315699888490997],
            [-59.670281967936091, -24.233608869665886],
            [-59.982781950328189, -24.042781935212886],
            [-60.038063954686066, -24.00972689411978],
            [-60.066672899046353, -24.016391010077072],
            [-60.087781884062281, -24.024172936644931],
            [-60.115835778792757, -24.032217887335264],
            [-60.139454976178769, -24.037781962316728],
            [-60.162781980419169, -24.041673009419654],
            [-60.279726796763924, -24.03847296641645],
            [-60.456390829381803, -23.988890991510033],
            [-60.577426852677917, -23.946317964058494],
            [-60.592017902128475, -23.918963958245158],
            [-60.613890981440079, -23.894446053198152],
            [-60.732226858439589, -23.872217916466994],
            [-60.76083580279996, -23.872499883690608],
            [-60.875281973801123, -23.849726923251524],
            [-60.986390973862598, -23.821663976065651],
            [-61.010699834243752, -23.810835898237229],
            [-61.024726781609075, -23.786108948524472],
            [-61.034726895039483, -23.754173059146495],
            [-61.111326761440978, -23.608399860215727],
            [-61.182499848435384, -23.554726845996058],
            [-61.294726826745489, -23.489172986906141],
            [-61.364172906404889, -23.456108893357552],
            [-61.450835750510748, -23.418055052834774],
            [-61.594173000733974, -23.297090946268113],
            [-61.63263587813276, -23.283473035778982],
            [-61.671735780174913, -23.286317853724739],
            [-61.744163973354233, -23.234235892370151],
            [-61.746254922926312, -23.188054958505703],
            [-61.757635871089747, -23.171526851225991],
            [-61.855281858233241, -23.089999934486116],
            [-61.88902689759766, -23.074308844059757],
            [-61.91889094814286, -23.068335899840264],
            [-61.990490841648295, -23.005490904226193],
            [-62.006954911187535, -22.975282022183322],
            [-62.004654916949818, -22.950072945398517],
            [-62.025699864225317, -22.899026819641321],
            [-62.042226798038627, -22.87639098713862],
            [-62.101954899129225, -22.816390977108284],
            [-62.15472685875514, -22.756108999854348],
            [-62.237646009616242, -22.570417825129326],
            [-62.240281950534325, -22.542782019730382],
            [-62.250417850796538, -22.525281946955658],
            [-62.285281874237995, -22.49583581810387],
            [-62.315835755416629, -22.480835899415396],
            [-62.341390837165306, -22.47028190982158],
            [-62.363472961142861, -22.46847292747313],
            [-62.392363872726662, -22.456808838619679],
            [-62.521390867256372, -22.370835825147054],
            [-62.617781915853016, -22.275555043448151],
            [-62.643772856568205, -22.238899974931144],
            [-62.634172895196485, -22.221946066968911],
            [-62.601108801647911, -22.120555045476124],
            [-62.524445903334367, -21.883608867966245],
            [-62.486117806938807, -21.764999908560426],
            [-62.474808943142904, -21.728064046286292],
            [-62.470554959631414, -21.71417305339088],
            [-62.377781875731614, -21.42639096825603],
            [-62.2588998339196, -21.056946011911407],
            [-62.261090863415831, -21.000517866110258],
            [-62.264164004404648, -20.810835899634228],
            [-62.265281982653278, -20.742226836828337],
            [-62.26778196910135, -20.621946023010466],
            [-62.269445944524421, -20.562226806737272],
            [-62.198835786148834, -20.471390948304801],
            [-62.101954899129311, -20.337499935348731],
            [-61.913072901494587, -20.080008874908117],
            [-61.847499931665368, -19.910835916817149],
            [-61.774445945593158, -19.724726820398615],
            [-61.742499830293212, -19.645000001189771],
            [-61.668890962229256, -19.633335912336321],
            [-61.357226823223982, -19.583055054341173],
            [-60.796390830341124, -19.490281970441359],
            [-60.613335764172177, -19.459163987177519],
            [-60.516399891867621, -19.434717831221889],
            [-60.424446055186507, -19.410835944989657],
            [-60.057499911823555, -19.314726863616642],
            [-60.018526911795902, -19.302073039633427],
            [-59.981808811366875, -19.296808869148407],
            [-59.573617836933266, -19.32166406198013],
            [-59.428335817529955, -19.329999864711411],
            [-59.156390835575166, -19.345555000667858],
            [-59.095835775914935, -19.348890998141059],
            [-58.792617913125184, -19.501508859223904],
            [-58.520563966428824, -19.638617852456093],
            [-58.268054941611553, -19.764164010225386],
            [-58.232226831664178, -19.782499926387061],
            [-58.150972997330484, -19.831108929970227],
            [-58.131254905331303, -19.986117980397651],
            [-58.133055002862335, -20.082226894132575],
            [-58.137226843722488, -20.116391028656992],
            [-58.158890878368254, -20.168055068317969],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "PSE", Country: "Gaza Strip" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.267582213447014, 31.216545015813679],
            [34.239163203013305, 31.294718165445701],
            [34.216725776095103, 31.323247702243762],
            [34.218651097000105, 31.322977886000118],
            [34.219553770857573, 31.325756952009428],
            [34.248054210687229, 31.34971799597092],
            [34.276109244785772, 31.373881590336865],
            [34.401109241264209, 31.489163597531004],
            [34.455827276219424, 31.549718040627809],
            [34.482636283453047, 31.583053958052957],
            [34.490545273577226, 31.596099993406327],
            [34.558891235809909, 31.54000014568679],
            [34.539991217562971, 31.514718146344208],
            [34.513327210020947, 31.498609134224424],
            [34.478609199333192, 31.471109115657285],
            [34.388882266693855, 31.394718126283564],
            [34.36458229113012, 31.354300086144448],
            [34.371245065982833, 31.300000105565388],
            [34.334163358593088, 31.259718019896169],
            [34.267582213447014, 31.216545015813679],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "PYF", Country: "French Polynesia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-131.978610623, -20.764999666999984],
            [-132.143610622999972, -21.051388667999944],
            [-132.389721622999957, -22.381943668999952],
            [-132.68638862399996, -24.068888670000021],
            [-132.992221623999853, -25.677777672],
            [-133.424721623999915, -26.568055672999961],
            [-134.886187625999952, -26.575870672999983],
            [-134.887435626000013, -26.575795673],
            [-134.888239625999915, -26.575872672999978],
            [-135.535589625999933, -26.53549867299995],
            [-136.164133626999956, -26.392593672999979],
            [-136.184375626999952, -26.384374673000025],
            [-136.464723626999955, -26.454424673],
            [-137.110155627999973, -26.508964673],
            [-137.110944127999943, -26.508944172999989],
            [-137.757183628, -26.459630672999978],
            [-138.382498629, -26.308046673],
            [-138.966744628999948, -26.059195672],
            [-139.491194630000024, -25.721208671999932],
            [-139.75867863000002, -25.473354671999985],
            [-140.03993463, -25.401230672000011],
            [-140.616673631, -25.146573670999942],
            [-140.768218631000025, -25.046230670999947],
            [-141.330147631999864, -24.990100670999922],
            [-141.943836631999829, -24.825036671],
            [-141.65466963199998, -24.945135670999989],
            [-141.122448631000054, -25.268536671999982],
            [-140.657048630999952, -25.669167171999973],
            [-140.653162631000015, -25.672537671999962],
            [-140.268745630999945, -26.138645671999953],
            [-139.97725263000001, -26.656953672999975],
            [-139.78842863, -27.212352672999984],
            [-139.78812763000002, -27.214563672999958],
            [-139.787286629999983, -27.217021673000033],
            [-139.708210630000053, -27.793111673999974],
            [-139.742987629999902, -28.372640673999967],
            [-139.743525630000022, -28.374685673999977],
            [-139.743770630000029, -28.378761673999989],
            [-139.893188630000026, -28.944095675000014],
            [-139.89550463, -28.948849675],
            [-139.904946629999984, -28.984400675000018],
            [-140.166365631, -29.518039675999944],
            [-140.533196630999896, -30.00320667599992],
            [-140.534347630999832, -30.004261676],
            [-140.535173630999964, -30.005348676000054],
            [-140.997211630999914, -30.426524675999914],
            [-141.540642632000015, -30.770043676999947],
            [-142.148447631999943, -31.024605676999968],
            [-142.800940632999982, -31.181743676999943],
            [-143.476526633999981, -31.236190676999968],
            [-143.477601633999939, -31.236113677],
            [-143.478591634000054, -31.236191676999965],
            [-144.15466763400002, -31.186102677],
            [-144.808604635, -31.033165677],
            [-144.816604635000033, -31.029904676999919],
            [-144.828111635000027, -31.027180676999947],
            [-145.129696634999959, -30.903706676999946],
            [-145.669984635999981, -30.773187677000024],
            [-146.276367636000032, -30.518828676000012],
            [-146.300770635999982, -30.50347167599999],
            [-146.312971635999872, -30.498310676],
            [-146.854991637000012, -30.154848675999986],
            [-147.315890637, -29.733733675999929],
            [-147.681992638, -29.248443674999976],
            [-147.690669638, -29.236816674999943],
            [-147.692992637999907, -29.232089674999983],
            [-147.693547638000013, -29.23134867499995],
            [-147.954372637999882, -28.697737674999956],
            [-147.954599638000019, -28.696879674999934],
            [-147.956248638000034, -28.693489675],
            [-148.10548863799994, -28.128178674000026],
            [-148.140368637999984, -27.548654674],
            [-148.140204638, -27.547461673999962],
            [-148.140282637999974, -27.546156673999988],
            [-148.139685637999946, -27.541815674],
            [-148.139970638000023, -27.53706567399999],
            [-148.137294137999902, -27.517510173999952],
            [-148.137160638000012, -27.516437674000031],
            [-148.137200637999968, -27.515151173999911],
            [-148.13719963799997, -27.514541673999929],
            [-148.09511363799993, -27.206324673000026],
            [-148.242962637999966, -27.197851673],
            [-148.876094638999973, -27.057837672999966],
            [-149.470194638999885, -26.819720673],
            [-149.475074638999985, -26.816746672999969],
            [-149.48444663899997, -26.812963672999956],
            [-149.632374639000034, -26.72264767300004],
            [-150.11025464, -26.689157673000011],
            [-150.738187639999978, -26.541687672999984],
            [-151.32577364100004, -26.296634673],
            [-151.327487641, -26.295554672999984],
            [-151.328810640999961, -26.294999672999978],
            [-151.857158640999984, -25.960362671999974],
            [-151.934084641000027, -25.890387671999989],
            [-152.200438641999938, -25.952977672],
            [-152.844215642000023, -25.998063672],
            [-152.845109642000011, -25.997983672000046],
            [-152.846267641999987, -25.998062672],
            [-153.488602642999922, -25.939302671999982],
            [-153.490154643000011, -25.938903672],
            [-153.494764642999968, -25.938472671999961],
            [-153.522958643000038, -25.931224671999956],
            [-153.554192643000022, -25.928293671999924],
            [-153.55884964299986, -25.92709467200001],
            [-153.561351643000023, -25.92685967200002],
            [-154.181011644000023, -25.765940671999942],
            [-154.757947643999984, -25.508464671999945],
            [-155.27374664499996, -25.162853670999937],
            [-155.348167645000018, -25.091425670999982],
            [-155.429369645, -25.082812670999928],
            [-156.042695644999924, -24.914875670999962],
            [-156.049384644999861, -24.911794670999939],
            [-156.066154645000012, -24.907163671000021],
            [-156.142499644999987, -24.89444467099996],
            [-156.323055646000029, -21.405555668],
            [-154.805555644, -19.257222665999947],
            [-155.174444644999937, -18.919722665999927],
            [-156.041944644999973, -18.345555664999935],
            [-157.245833646, -17.318333664000022],
            [-157.868610646999969, -16.404999663000027],
            [-158.128055647, -15.868888662999936],
            [-157.966234646999965, -15.420382662],
            [-157.943464646999985, -15.210885662],
            [-157.77839864699996, -14.652877662000037],
            [-157.516883647000014, -14.130736660999958],
            [-157.515162647000011, -14.128426660999963],
            [-157.510766646999969, -14.119620660999928],
            [-157.161206645999982, -13.648846660999951],
            [-157.152415645999952, -13.64043666099991],
            [-157.150391145999947, -13.638353160999912],
            [-156.723369645999924, -13.23257065999988],
            [-156.232108645999972, -12.90428466],
            [-155.69098564500004, -12.662856659999917],
            [-155.115902644000016, -12.515354659999915],
            [-155.024568643999885, -12.507758659999894],
            [-154.524397643999976, -12.80559566],
            [-154.423412644, -12.86544065999982],
            [-154.382569643999943, -12.889645659999886],
            [-154.361832644000032, -12.901995659999969],
            [-153.400756643000022, -13.473636660999858],
            [-153.164586643000035, -13.613102661000028],
            [-152.803152641999986, -13.82607466099995],
            [-151.831780641000023, -13.83556966099988],
            [-151.826784641000017, -13.835607660999827],
            [-151.065675640999984, -13.838930660999978],
            [-150.502104640000027, -13.36118466100001],
            [-150.49011264, -13.350937659999843],
            [-149.997166639999989, -12.928852659999905],
            [-149.428047639, -12.437782659999911],
            [-149.398106638999963, -12.411796659999922],
            [-148.282541637999941, -12.071892658999957],
            [-148.155046637999931, -12.032448658999954],
            [-148.062897637999981, -11.994279658999929],
            [-147.979810638, -11.959874658999951],
            [-147.848408637999853, -11.849745658999836],
            [-147.487754636999966, -11.5455676589999],
            [-147.427149636999957, -11.495023658999955],
            [-147.342512637000027, -11.424338658999929],
            [-147.235360637000014, -11.334589658999946],
            [-147.065069636999851, -11.192069657999951],
            [-146.891372136999848, -11.148974157999902],
            [-146.88966963699994, -11.148596658000017],
            [-146.879958636999987, -11.147883658],
            [-146.870922636999921, -11.145626657999856],
            [-146.858909637000011, -11.144744657999979],
            [-146.454455636000034, -11.043299658000024],
            [-145.86486563599999, -10.998271657999851],
            [-145.859558636, -10.99877165800001],
            [-145.853017635999947, -10.9982636579999],
            [-145.842007636, -10.999301657999894],
            [-145.836246636, -10.998853657999959],
            [-145.247449635000038, -11.053602657999932],
            [-145.152992634999976, -11.079025657999878],
            [-144.875259635, -11.056529657999818],
            [-144.286204633999972, -11.109014657999964],
            [-144.278489633999868, -11.111060657999872],
            [-144.261531634, -11.112550657999876],
            [-143.690122634000033, -11.263306658999909],
            [-143.414311634, -11.38918065899999],
            [-143.165765633000035, -11.21767865899983],
            [-143.024519633000011, -11.152390657999959],
            [-143.072688633000013, -11.083431657999924],
            [-143.18409963299996, -10.842021658],
            [-143.219817633000048, -10.806184657999921],
            [-143.553023633999942, -10.327119657999887],
            [-143.79583163399991, -9.798346656999882],
            [-143.941305633999889, -9.236088656999812],
            [-143.967725634, -8.890142655999881],
            [-144.04454563399986, -8.593041655999926],
            [-144.044683633999909, -8.591241655999852],
            [-144.045509633999984, -8.588045656],
            [-144.089879633999942, -8.009445655999954],
            [-144.089777633999859, -8.008425655999844],
            [-144.090747634000024, -7.995529322666613],
            [-144.090727633999961, -7.994707155999862],
            [-144.033208634000033, -7.417081654999976],
            [-144.032265633999884, -7.413737654999863],
            [-144.032185634000029, -7.412933654999961],
            [-143.874794634000011, -6.853949153999935],
            [-143.873864633999972, -6.850782653999858],
            [-143.873109633999832, -6.84921665399996],
            [-143.872857633999956, -6.848320654],
            [-143.869632633999913, -6.837060153999985],
            [-143.868755634000024, -6.834161653999885],
            [-143.616795633999971, -6.310803653999926],
            [-143.615380633999962, -6.308834653999895],
            [-143.609751634, -6.297118653999959],
            [-143.270927632999928, -5.824883652999986],
            [-143.26765063299996, -5.821677652999924],
            [-143.264762132999891, -5.817707152999944],
            [-143.263916633, -5.816588652999855],
            [-142.848535632999983, -5.409619652999979],
            [-142.36909763299991, -5.080105653000018],
            [-142.280342633000032, -5.039550652999836],
            [-142.228766631999974, -5.00401265299999],
            [-141.699802631999944, -4.761613651999852],
            [-141.136933630999977, -4.613778651999922],
            [-141.081465630999901, -4.609143651999943],
            [-140.954205630999979, -4.575577651999922],
            [-140.374051630999986, -4.526499651999941],
            [-140.373295630999849, -4.526477651999983],
            [-139.79354563000004, -4.577850651999967],
            [-139.23125663, -4.728171651999972],
            [-138.703225629, -4.972953652999834],
            [-138.225252629000011, -5.304883652999948],
            [-137.811692627999832, -5.714033652999987],
            [-137.810850127999942, -5.715157652999906],
            [-137.474039627999957, -6.189429654000023],
            [-137.434865628000011, -6.271964653999973],
            [-137.353627628, -6.327886653999926],
            [-136.938025628, -6.736142653999977],
            [-136.936107127999975, -6.738133153999925],
            [-136.934197628, -6.74004365399999],
            [-136.917631628000038, -6.763227653999863],
            [-136.765799626999978, -6.867254653999936],
            [-136.762925127000017, -6.870031653999845],
            [-136.346126626999933, -7.277356654999963],
            [-136.00617362700001, -7.749810654999933],
            [-135.753309625999975, -8.273344655999892],
            [-135.728176625999936, -8.362496655999863],
            [-135.501578625999969, -8.675095655999868],
            [-135.246914626000034, -9.19810765699998],
            [-135.087305625999988, -9.756662656999907],
            [-135.027973625999948, -10.334179324666607],
            [-135.028031625999972, -10.335260657999882],
            [-135.027951626, -10.336277991333276],
            [-135.071179625999832, -10.915130657999853],
            [-135.216246625999986, -11.477578659000017],
            [-135.459311625999987, -12.006657658999842],
            [-135.793484625999952, -12.486123660000018],
            [-136.093469626999877, -12.78579266],
            [-135.820387625999956, -13.162426659999852],
            [-135.819949625999982, -13.16332966],
            [-135.819380625999969, -13.16411266],
            [-135.563813626000012, -13.688930660999972],
            [-135.404682625999982, -14.248810660999922],
            [-135.347489626000026, -14.826990661999957],
            [-135.347675625999955, -14.829270661999956],
            [-135.347405626000011, -14.831989661999955],
            [-135.385692625999923, -15.30072666199996],
            [-135.288605626000049, -15.324880661999984],
            [-134.740049624999926, -15.564422162999946],
            [-134.738130625000053, -15.565268663],
            [-134.736827624999876, -15.566124663000025],
            [-134.73517262499999, -15.566843662999929],
            [-134.730521625, -15.569899663],
            [-134.66796362499997, -15.59707166299998],
            [-134.664076124999951, -15.599607662999929],
            [-134.648064624999847, -15.610099663000028],
            [-134.617540625, -15.623342663000017],
            [-134.118343624999909, -15.94953516299995],
            [-134.113564625000038, -15.952699663000047],
            [-134.111193624999942, -15.954908663000026],
            [-134.109585624999852, -15.955954662999972],
            [-134.107910124999961, -15.95742766299999],
            [-134.097816624999979, -15.966742662999962],
            [-134.09663662499986, -15.967509662999916],
            [-133.662022123999918, -16.370929662999956],
            [-133.651091623999946, -16.381069662999977],
            [-133.294140624000022, -16.849905664],
            [-133.293353123999879, -16.85129866399997],
            [-133.024833624, -17.372187664000023],
            [-132.853852623999956, -17.929145664999979],
            [-132.853588623999968, -17.931408665],
            [-132.852751623999893, -17.934123665],
            [-132.785056624, -18.510831665000012],
            [-132.785288624000032, -18.514314664999986],
            [-132.78492162400002, -18.517427664999985],
            [-132.823569623999958, -19.096540665999939],
            [-132.863608624, -19.252613665999974],
            [-132.786839623999981, -19.321558665999916],
            [-132.785288624000032, -19.32354166600004],
            [-132.777836624, -19.330228665999982],
            [-132.411056623000036, -19.796985665999856],
            [-132.409321623000011, -19.800245666999942],
            [-132.407991623000015, -19.801932666999988],
            [-132.130655623000024, -20.320924666999929],
            [-132.130241622999961, -20.322218666999973],
            [-132.129604623, -20.323404666999963],
            [-131.978610623, -20.764999666999984],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "QAT", Country: "Qatar" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [53.034721886000085, 25.530555883200108],
            [52.614167000000208, 25.098611000000133],
            [52.454694071000205, 24.964529592000062],
            [52.453075472000052, 24.971923196000134],
            [52.452028338500128, 24.976091269499975],
            [52.450541154000149, 24.978250167333371],
            [52.449750685000112, 24.979387376999981],
            [52.449151578000198, 24.98021725],
            [52.448831785500062, 24.980690116500071],
            [52.447138252666804, 24.983159034333354],
            [52.446211810000051, 24.984567585999955],
            [52.445580685750116, 24.985576832250089],
            [52.444068196500183, 24.98801988700005],
            [52.443828877000072, 24.989112519000045],
            [52.433804735000223, 25.003493901999988],
            [52.432606994000054, 25.004695858000147],
            [52.431994463333496, 25.005176017333312],
            [52.431413394000145, 25.005611231500055],
            [52.430685971500026, 25.006528182000054],
            [52.412800642000064, 25.017362739000063],
            [52.391699566000199, 25.021167278],
            [52.390724270000163, 25.020991463000101],
            [52.389198483000115, 25.02126646000012],
            [52.388300477000058, 25.02126646000012],
            [52.367198481000088, 25.01746062399998],
            [52.349313907000095, 25.006624944000052],
            [52.346800202000196, 25.004134749999977],
            [52.345881394000145, 25.003210614500048],
            [52.344830732000133, 25.00207280700009],
            [52.336168473000185, 24.989109390000053],
            [52.331981925000179, 24.96998641],
            [52.333583468000171, 24.962704606500083],
            [52.333584402000184, 24.9607889440001],
            [52.33778846200002, 24.941668588000184],
            [52.338408572000191, 24.940827578500077],
            [52.338660594000117, 24.940100002500017],
            [52.339345553000129, 24.93903045200004],
            [52.339490996000023, 24.938369090000023],
            [52.3485841090002, 24.925640128000126],
            [52.352091061000095, 24.920623746],
            [52.355894536000136, 24.916823437],
            [52.356943423000104, 24.916149417999989],
            [52.357744067000027, 24.915063346000025],
            [52.362694359000074, 24.912065107],
            [52.363047852000108, 24.911638641750059],
            [52.363512715000212, 24.911259888],
            [52.366351365000156, 24.909652596000157],
            [52.371340531000129, 24.9068276470001],
            [52.375838603000176, 24.904195822000034],
            [52.378918191000054, 24.902536354000134],
            [52.380600087000204, 24.902232964000163],
            [52.341796712000104, 24.86960792100011],
            [52.272222000000198, 24.811111],
            [51.612386956000108, 24.669799242000053],
            [51.468055548000081, 24.638888526],
            [51.452446892000097, 24.634554567000052],
            [51.433024088000053, 24.63120580799999],
            [51.414940788000223, 24.625847793000148],
            [51.404224758000083, 24.619820026000028],
            [51.394178480000079, 24.613122508000103],
            [51.386811210000161, 24.600397221999955],
            [51.372746420000027, 24.58633243300001],
            [51.358681631000167, 24.572937395999972],
            [51.342607586000071, 24.560212110000109],
            [51.320505775000044, 24.553514591999985],
            [51.316487264000074, 24.559542358000115],
            [51.316487264000074, 24.570258388],
            [51.318496519000149, 24.582983674000147],
            [51.319836023000136, 24.595039207000113],
            [51.319166271000057, 24.607764492999976],
            [51.315817512000166, 24.619150275000024],
            [51.311129249000118, 24.627187297000134],
            [51.291036693000166, 24.630536056],
            [51.268934881000206, 24.631875559999983],
            [51.242144806000198, 24.630536056],
            [51.227410265000088, 24.629866304],
            [51.221382499000157, 24.626517545000141],
            [51.215163479330243, 24.62089122190666],
            [51.2044450726506, 24.608191178300032],
            [51.178891164368139, 24.586391189184368],
            [51.111363367074858, 24.556045011567122],
            [50.973054085304767, 24.576944951917412],
            [50.865909222542058, 24.690000062263536],
            [50.830954060568416, 24.749964128120794],
            [50.810887194000117, 24.779912006500112],
            [50.79269958900008, 24.825381607000011],
            [50.769964387000215, 24.889040171000076],
            [50.756323266000066, 24.929963533],
            [50.748299585000183, 24.946010895000015],
            [50.733588065000191, 24.975433935999988],
            [50.729188803000085, 24.985698881000033],
            [50.719946944000213, 25.007263218000119],
            [50.710852863000156, 25.029998420000069],
            [50.692664702000087, 25.070921781999985],
            [50.68357062200019, 25.102751064000117],
            [50.679023581000109, 25.130033305999959],
            [50.669929501000155, 25.161862588000176],
            [50.660835485500087, 25.184597626000013],
            [50.664113225000136, 25.194431204500049],
            [50.665382460000075, 25.198238910000114],
            [50.656288380000063, 25.230068192000047],
            [50.647194299000176, 25.270991555000066],
            [50.645416882000205, 25.279878640000149],
            [50.638100219000108, 25.316461958000062],
            [50.629006138000051, 25.366479401000149],
            [50.627537857500073, 25.371373669000079],
            [50.615365017000073, 25.411949804000059],
            [50.601723896000095, 25.45742020699997],
            [50.58353573500014, 25.502890610000051],
            [50.565347574000072, 25.548361013000076],
            [50.557395545000162, 25.568769795],
            [50.5675, 25.576111111],
            [50.58, 25.586111110999965],
            [50.689444444000202, 25.581388888999967],
            [50.693055556000019, 25.580555556000022],
            [50.734722222000158, 25.5725],
            [50.763611111000074, 25.558055555999985],
            [50.76972222200007, 25.546944444],
            [50.780000000000115, 25.548611111000056],
            [50.796111111000101, 25.545277778000141],
            [50.810000000000144, 25.535],
            [50.81500000000014, 25.544444444],
            [50.813333333000088, 25.548611111000056],
            [50.817777778000192, 25.562222222000045],
            [50.808888889000144, 25.563611111000128],
            [50.793611111000104, 25.575833333000119],
            [50.780277778000112, 25.5925],
            [50.798333333000102, 25.622500000000159],
            [50.828888889000183, 25.629166667],
            [50.839444444000122, 25.638611111000031],
            [50.840555556000112, 25.645277778000079],
            [50.835000000000178, 25.658611110999985],
            [50.841666667000112, 25.669444444000177],
            [50.861944444000159, 25.690833333000015],
            [50.852500000000219, 25.707500000000138],
            [50.866111111000095, 25.735277778000139],
            [50.868055556000144, 25.749444444000162],
            [50.864722222000211, 25.759722222000121],
            [50.861111111000042, 25.766666667000052],
            [50.856388889000215, 25.7825],
            [50.842222222000174, 25.811944444000105],
            [50.831388889000067, 25.861111111000142],
            [50.820000000000192, 25.873888888999986],
            [50.815833333000143, 25.895000000000138],
            [50.85, 26.011111111000147],
            [50.907500000000169, 26.077222222000088],
            [50.91750000000016, 26.183888888999988],
            [50.92277777800021, 26.265277778000055],
            [50.932777778000201, 26.299444444000031],
            [50.954444444000188, 26.333888889],
            [50.986666667000151, 26.436388889000014],
            [51.054444444000154, 26.732777778000028],
            [51.119722222000149, 27.033333333000101],
            [51.121801285000146, 27.043114553],
            [51.383333000000135, 27.009722],
            [51.734722000000119, 26.938889],
            [52.202778000000166, 26.556944000000115],
            [52.708333, 26.105556],
            [53.034721886000085, 25.530555883200108],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "REU", Country: "Reunion" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [58.23972255400011, -23.801388669999938],
            [58.149640554000115, -23.871313669999964],
            [58.144407054000141, -23.874854169999978],
            [58.140557554000139, -23.877425670000022],
            [58.136021554000166, -23.88172067],
            [57.621213554000207, -24.222027671000049],
            [57.619826554000184, -24.222690670999924],
            [57.615998554000186, -24.224429670999925],
            [57.611890554000212, -24.227131670999938],
            [57.037826553000201, -24.478728670999942],
            [56.422849553000077, -24.633457670999988],
            [56.398634553000164, -24.635513671000012],
            [56.396441553000187, -24.636059670999984],
            [56.393967553000124, -24.636269671000036],
            [56.387847553000171, -24.637795671],
            [56.386517553, -24.637907670999951],
            [56.366569552000072, -24.642878670999934],
            [56.365174552000127, -24.642996670999921],
            [56.362373552000093, -24.643694670999949],
            [56.360840552000099, -24.643824670999962],
            [56.358076552000199, -24.644512671000044],
            [56.356531552000064, -24.644642670999957],
            [56.35377955200002, -24.645328670999959],
            [56.342803552000106, -24.64625567100002],
            [56.294956552000116, -24.658160670999962],
            [55.65882755200019, -24.710473671],
            [55.657121552000177, -24.710338670999931],
            [55.655446552000143, -24.710473671],
            [55.626697552000138, -24.708204671000018],
            [55.619587552000013, -24.708774671000015],
            [55.618894052000172, -24.708756171],
            [54.982459551000119, -24.657265670999976],
            [54.942958551000203, -24.647492670999952],
            [54.938162551000147, -24.647093670999979],
            [54.926339551000211, -24.644166670999923],
            [54.921074551000146, -24.643727671],
            [54.30572755100016, -24.490014671000012],
            [54.273316551000136, -24.475953671000028],
            [54.237820551000112, -24.466999671],
            [53.663414550000056, -24.216116671],
            [53.661660550000164, -24.214966670999971],
            [53.638616550000023, -24.204788671],
            [53.637233550000104, -24.204128671000021],
            [53.631336550000157, -24.200258671],
            [53.603357550000197, -24.187956670999981],
            [53.602615550000081, -24.187469671000017],
            [53.601632550000119, -24.18703767099997],
            [53.571151550000167, -24.167015670999973],
            [53.517763050000241, -24.143456670999981],
            [53.516379550000153, -24.142795670999917],
            [53.001684549000203, -23.802719669999931],
            [53.000409049000183, -23.801520169999975],
            [52.999653549000215, -23.800812669999971],
            [52.997466549000166, -23.79936067],
            [52.557921549000213, -23.381541669999976],
            [52.557075549000132, -23.38038567],
            [52.554243549000176, -23.377681669999987],
            [52.535920549000167, -23.35261667],
            [52.51739804900015, -23.334904169999959],
            [52.515357549000072, -23.332934669999986],
            [52.164388549000051, -22.850651668999959],
            [51.911744548000087, -22.319226669],
            [51.910715548000127, -22.315309668999916],
            [51.906985548000222, -22.307433669],
            [51.906581548000105, -22.305896668999921],
            [51.900544548000113, -22.293100668999969],
            [51.89980554800016, -22.291493668999976],
            [51.896566548000038, -22.279150668999932],
            [51.843114548000102, -22.166179669000044],
            [51.842467048000145, -22.163875168999965],
            [51.835076548000103, -22.119954669000037],
            [51.835495548000125, -21.971136669000046],
            [51.835708548000156, -21.881962668000028],
            [51.836071548000092, -21.806570668],
            [51.836475548000209, -21.737062668000021],
            [51.837813548000071, -21.544704667999966],
            [51.837920548000199, -21.530806667999968],
            [51.839932548000064, -21.510622667999982],
            [51.840922548, -21.500851667999939],
            [51.856108548000151, -21.352589667999936],
            [51.860695548000166, -21.307940667999958],
            [51.864519548000118, -21.289047668000023],
            [51.886581548000066, -21.17649666799997],
            [51.922041548000124, -20.99554066799999],
            [51.955775548000105, -20.822340666999935],
            [51.97876754800015, -20.704339666999971],
            [52.028553548000019, -20.44966166699993],
            [52.219407549000067, -19.835391666999953],
            [52.220868549000073, -19.831878666999984],
            [52.223546549000133, -19.825480666999965],
            [52.266191549000126, -19.724534666],
            [52.278253549000198, -19.698663666000016],
            [52.28678154900004, -19.680534665999971],
            [52.405262549000128, -19.443656666000038],
            [52.471335549000202, -19.303577665999953],
            [52.814444549000058, -18.68333366499995],
            [54.320833551000163, -18.483055664999981],
            [55.505555552000175, -18.28638866499999],
            [55.845833552000187, -19.013610666],
            [56.294166552000121, -20.082499666999979],
            [56.462222553000146, -20.598610666999932],
            [56.835833553000185, -21.305277667999974],
            [57.244444553000022, -22.00888866899993],
            [58.23972255400011, -23.801388669999938],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ROU", Country: "Romania" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.214836072877034, 45.448644993892188],
            [28.226982288802532, 45.449382098459623],
            [28.279227194355173, 45.428945006804],
            [28.285382193512106, 45.395191082622247],
            [28.299163215837524, 45.362636105873932],
            [28.317845136963314, 45.337145061865513],
            [28.343327128516279, 45.315536012504779],
            [28.375827119979448, 45.300544978633454],
            [28.574718115862112, 45.242764161294062],
            [28.70080909733943, 45.220091113141152],
            [28.797600130356471, 45.237945069868871],
            [28.848609208101635, 45.301654072064778],
            [28.968054178532356, 45.32110913994147],
            [29.221663244325583, 45.425827106452047],
            [29.321527083055486, 45.439018042792256],
            [29.411382258813916, 45.43581816742703],
            [29.51082717002177, 45.41068218083818],
            [29.580691171374582, 45.389018146192328],
            [29.603336056332893, 45.376936135645266],
            [29.64097331626661, 45.34638208682874],
            [29.65500026363199, 45.327764203443351],
            [29.669027210997143, 45.294573207880532],
            [29.67221820154495, 45.272209116679377],
            [29.664327271064877, 45.211799844515454],
            [29.668190863986041, 45.210127217947345],
            [29.669285609813215, 45.210213891273909],
            [29.671526918485512, 45.210391340639788],
            [29.677900565000272, 45.208299194000105],
            [30.040833330000083, 45.089166670000125],
            [30.156833330000126, 45.05513889],
            [30.9770277800001, 44.777416670000136],
            [31.091327816000074, 44.754038580000085],
            [31.174361168000047, 44.737055389500128],
            [31.409703947000054, 44.048754833000103],
            [31.345261965166827, 43.446967096666796],
            [30.974197864000161, 43.520284825000132],
            [30.43400296300004, 43.627049161000016],
            [29.484910223000128, 43.704190902000036],
            [29.138088005000014, 43.680079121000048],
            [28.861578656000091, 43.715315895000018],
            [28.686211881000105, 43.733217774000011],
            [28.593660527000139, 43.742665393000024],
            [28.583243146419733, 43.747771689213991],
            [28.485000235678257, 43.74416419128373],
            [28.403891240631907, 43.75027308997322],
            [28.257773210203453, 43.764435991808355],
            [28.235554125927791, 43.770000066789734],
            [28.18194514944858, 43.785273235522666],
            [28.1330541786418, 43.801653988944096],
            [28.038463227576273, 43.835409086592406],
            [27.998054072254575, 43.859509069945986],
            [27.960273314137993, 43.944991071529145],
            [27.952218137525705, 43.974436026914631],
            [27.922218132510551, 44.003882155766419],
            [27.860409140131907, 43.980818175648309],
            [27.833327210896272, 43.969164145078665],
            [27.813191197203736, 43.965000183207437],
            [27.741800180726074, 43.959436108225972],
            [27.716800148607007, 43.969227009352537],
            [27.698354094237374, 43.993609127567808],
            [27.678745134617856, 44.029718031272225],
            [27.652427132039833, 44.041664254987367],
            [27.624791159002797, 44.02818213133007],
            [27.475827137162383, 44.019436118256394],
            [27.407500209218114, 44.021800150234569],
            [27.295273230907895, 44.084300146713062],
            [27.279300173258122, 44.103882116604169],
            [27.27270914749667, 44.132145056000567],
            [27.25054504850587, 44.124982216802792],
            [27.230273247981501, 44.120827139749068],
            [27.193600242191593, 44.123318073741743],
            [27.165836193673755, 44.129991074516354],
            [27.111109238945772, 44.140827031333899],
            [27.036427151996264, 44.147336082081878],
            [26.913400259052338, 44.136127130572149],
            [26.890209209281636, 44.131991164257656],
            [26.840827226585674, 44.115554084446629],
            [26.794718209450679, 44.095273231466692],
            [26.671100224693106, 44.071936001304309],
            [26.478609147179156, 44.049427070816009],
            [26.438418088978523, 44.04130902992982],
            [26.413036177349852, 44.043127064733781],
            [26.386663357124945, 44.043873221756527],
            [26.301936062105796, 44.030818072248039],
            [26.136382242177859, 43.982773170750377],
            [26.111663339092189, 43.968318075769744],
            [26.085282304602288, 43.949718129657171],
            [25.942773186415081, 43.848473120918115],
            [25.884563215631999, 43.793018114078379],
            [25.786945056407149, 43.713054087008899],
            [25.766527075490728, 43.703327055984786],
            [25.735309179940884, 43.694600153650228],
            [25.681336093586594, 43.690427139323603],
            [25.609163213178533, 43.66220912692863],
            [25.531936045141691, 43.64360012836066],
            [25.463854036409003, 43.639209184550865],
            [25.432063321594427, 43.627927142845195],
            [25.378791129985672, 43.62330904945874],
            [25.344163308576356, 43.633464228098447],
            [25.314445103146767, 43.652218065953534],
            [25.268609168417981, 43.675273161254424],
            [25.224718170507344, 43.687482073815701],
            [25.022754033636005, 43.710191164152334],
            [24.881382172075007, 43.712218075983955],
            [24.793609228709329, 43.71277312561368],
            [24.714163203257726, 43.714718062432027],
            [24.686927214641287, 43.718382127596371],
            [24.605827104412441, 43.751518137874442],
            [24.56250020858721, 43.759164109972389],
            [24.528609156469088, 43.761109046790565],
            [24.501800142001571, 43.761245001260463],
            [24.457773189621321, 43.744718067447153],
            [24.438609141423541, 43.736382097077794],
            [24.40528219139054, 43.719718035328199],
            [24.355273242335301, 43.696873158159732],
            [24.319709162338711, 43.696636117937359],
            [24.307782217000863, 43.697491072063613],
            [24.286391264761306, 43.697491072063613],
            [24.257218218315728, 43.694445088441128],
            [24.224163344860699, 43.689436063089474],
            [24.189236121869016, 43.684927102082767],
            [24.155618152157189, 43.689909137705925],
            [24.116082223510716, 43.699909083498326],
            [24.067982169089731, 43.720627136549169],
            [23.987500140402744, 43.74416419128373],
            [23.774163269633135, 43.788327098134076],
            [23.629718099445057, 43.795000098908773],
            [23.607082266942456, 43.797354072603142],
            [23.570273306682736, 43.806936096702131],
            [23.533700213179003, 43.822427027279971],
            [23.511518176915359, 43.836554054569362],
            [23.446663200914287, 43.851109061836084],
            [23.422682240586084, 43.855700165494326],
            [23.28360919979653, 43.850827094612484],
            [23.262073240631565, 43.847782116818493],
            [23.22450018607617, 43.838664114880643],
            [23.19860915764724, 43.83138208501957],
            [23.162109154339362, 43.81822702322502],
            [23.143191198819551, 43.809718050373718],
            [23.067527088091083, 43.802182216483672],
            [22.966945087895112, 43.816936042494447],
            [22.894445145624388, 43.836109143147624],
            [22.869300106580027, 43.845418084840318],
            [22.852545184999656, 43.860736012936215],
            [22.841963199849204, 43.896673255263167],
            [22.882218128152033, 43.984864120322257],
            [22.913400149156331, 44.000864167700556],
            [22.959427191278053, 44.012273111420612],
            [22.992236139693091, 44.013754026077933],
            [23.037636047817557, 44.050691061818455],
            [23.042982193315765, 44.072564141129973],
            [23.01277331127298, 44.096109074853743],
            [22.997445157255186, 44.100627088315932],
            [22.948327204509837, 44.104018071074066],
            [22.914582165145418, 44.11110916118038],
            [22.69166327027682, 44.215554045284662],
            [22.681436174907844, 44.224700042779133],
            [22.687773229002204, 44.27401815537273],
            [22.665273183331323, 44.288464197897966],
            [22.641109162237484, 44.294164227349242],
            [22.616673232203652, 44.298035996074972],
            [22.575618167421453, 44.305545007874699],
            [22.543327220624008, 44.329718081424019],
            [22.530000162175924, 44.345273217380495],
            [22.459927116157331, 44.461064180930791],
            [22.461527221477922, 44.483327018741122],
            [22.586245079573132, 44.547635997568065],
            [22.61541812601871, 44.551664172607374],
            [22.639027097482739, 44.545827015219615],
            [22.672500227907534, 44.528882159712779],
            [22.701182262463675, 44.523564177409284],
            [22.738891271488853, 44.533054000572932],
            [22.762509127770301, 44.552600095918578],
            [22.712773261121271, 44.605136021150216],
            [22.665836111950256, 44.619445103377146],
            [22.6444451597105, 44.618609092352116],
            [22.607291200315217, 44.622427049259286],
            [22.574445036249784, 44.638609151574926],
            [22.543054138217826, 44.658609043159643],
            [22.513891150056025, 44.67971802817577],
            [22.493473169139804, 44.699164211235157],
            [22.466109105042591, 44.713745034763704],
            [22.428818185348888, 44.712082232807049],
            [22.311591234142526, 44.659027132491232],
            [22.297773163805061, 44.635827030265133],
            [22.273327175487509, 44.604164223293466],
            [22.258609224022564, 44.585273089863804],
            [22.175482201961984, 44.48326415446725],
            [22.137500110530596, 44.480273156129797],
            [22.091663337611379, 44.498673110031817],
            [22.035418084938101, 44.559509131087268],
            [22.031263343160447, 44.58942716108912],
            [22.029163341132971, 44.593891195094855],
            [21.997009186995768, 44.633045076593461],
            [21.909163321072413, 44.649436055936846],
            [21.783609116676018, 44.661945040632745],
            [21.73416326387752, 44.655827089487943],
            [21.644236171389736, 44.66013605828438],
            [21.617709124146046, 44.682844980983035],
            [21.615600237301237, 44.710791084438299],
            [21.596945138265653, 44.755827050325891],
            [21.565554072595603, 44.771664153505967],
            [21.533745085232141, 44.777353957035345],
            [21.474718214163119, 44.777773219833435],
            [21.45360922914719, 44.77666412640211],
            [21.398663339107543, 44.783100086954349],
            [21.362573210866174, 44.828100178296111],
            [21.369582158321037, 44.866527013511259],
            [21.407773294418377, 44.876244992080061],
            [21.45360922914719, 44.875418033510428],
            [21.478882176034261, 44.875273194223112],
            [21.502218232730257, 44.879718117489446],
            [21.55395402148261, 44.8924270943957],
            [21.556800180532832, 44.913954001105353],
            [21.535827149986687, 44.940200086953737],
            [21.473191199038382, 44.962353960022583],
            [21.450136103737691, 44.962353960022583],
            [21.429718122821271, 44.961664129389348],
            [21.407218077150389, 44.972218118983065],
            [21.37343615741193, 45.013609130445516],
            [21.443054066086887, 45.053600196435625],
            [21.514100251066878, 45.142773252911624],
            [21.482773223137173, 45.183609047106202],
            [21.473373253975808, 45.187445108924081],
            [21.410827157029786, 45.215418034469664],
            [21.351391249084685, 45.217773181630506],
            [21.271945055994991, 45.231245079365848],
            [21.048882160029478, 45.316382081813529],
            [20.986663292584382, 45.345836089654242],
            [20.808327070278295, 45.478882206767864],
            [20.794163330252843, 45.50777311835175],
            [20.76756319281327, 45.613536140553109],
            [20.776527135370202, 45.633327155110052],
            [20.797500165916347, 45.653045079471255],
            [20.80160025768501, 45.758673153031012],
            [20.773018135415072, 45.768154091377326],
            [20.75277315698068, 45.746936141619713],
            [20.717636051133127, 45.743744983433928],
            [20.662363266868823, 45.798745020568006],
            [20.655973239146135, 45.827909182196166],
            [20.58527322676818, 45.898744981405841],
            [20.553745033161846, 45.911245081284406],
            [20.513745082354319, 45.914236079621944],
            [20.373191126907443, 45.981518119513439],
            [20.354027078709663, 46.017391156461869],
            [20.314027127902165, 46.069853991497737],
            [20.261027180509359, 46.1148540828395],
            [20.274300259500819, 46.140409164588377],
            [20.340273213750635, 46.159436084849759],
            [20.486945120342313, 46.181109171951206],
            [20.535273162530274, 46.173045110521471],
            [20.563327057260636, 46.164991107375826],
            [20.582773240320194, 46.155000046400843],
            [20.622082187028013, 46.130827140489586],
            [20.687663203484306, 46.146309186250008],
            [20.719654078147244, 46.166100200807151],
            [20.726954045281218, 46.175564207708859],
            [20.720273165517398, 46.189582102618587],
            [20.757354034717025, 46.242909111874397],
            [20.783054123391196, 46.259436045687707],
            [20.795900228233734, 46.265726999314623],
            [20.825000184483287, 46.275973205422801],
            [20.846382251905624, 46.279164195970608],
            [20.868609215170295, 46.276109159892812],
            [20.9897181610242, 46.254718040015163],
            [21.049373171918944, 46.240827047119737],
            [21.080827101862951, 46.24693611344712],
            [21.177363325022981, 46.297364157662557],
            [21.178609211114434, 46.326945067517769],
            [21.188327189683122, 46.371936106404021],
            [21.202773232208358, 46.396809068870581],
            [21.219163205723447, 46.408336029787833],
            [21.288891252606277, 46.460545060794814],
            [21.293609090640842, 46.532491126902201],
            [21.30805412733767, 46.589854022548153],
            [21.327563342308991, 46.620618121858925],
            [21.45353613895125, 46.684644965823892],
            [21.489409175899851, 46.684536001082407],
            [21.493891147178317, 46.6869451276999],
            [21.52572712427002, 46.726182157678139],
            [21.493400135288852, 46.754754054026293],
            [21.530209095548685, 46.827009077085606],
            [21.613327065153754, 46.884164269170284],
            [21.67082708873599, 46.961391101931056],
            [21.692218208613639, 47.006282228531219],
            [21.655500108184498, 47.020864225526353],
            [21.65250022502974, 47.02756421602929],
            [21.710000248611976, 47.079718094113346],
            [21.743191244174881, 47.098191138211305],
            [21.783473162206121, 47.114927116690367],
            [21.847218206585666, 47.198118176491377],
            [21.842291156247256, 47.229235992117154],
            [21.871663356712787, 47.296527084464131],
            [21.929445179880759, 47.358953990746684],
            [21.962500220973851, 47.368045002956009],
            [21.99853620212059, 47.373609077937587],
            [22.013473256535008, 47.390136011750982],
            [22.020554120719339, 47.425282170054047],
            [22.02027315932412, 47.450827025881026],
            [22.008327103247154, 47.478609179309657],
            [22.013882293411115, 47.510618158883673],
            [22.037773232098942, 47.533609048805843],
            [22.11800916810796, 47.578109075802828],
            [22.167909152421799, 47.588327118716293],
            [22.186391248975269, 47.598609031732323],
            [22.189836043551878, 47.603954003764144],
            [22.191391221871527, 47.628744991217346],
            [22.225273221534252, 47.679300105085261],
            [22.273745097181035, 47.7262451332452],
            [22.320973266030961, 47.746664119989916],
            [22.449854080169075, 47.794854028412985],
            [22.481109191369228, 47.795554084968202],
            [22.501800254691744, 47.790282203132236],
            [22.552218240623091, 47.767082100906336],
            [22.603609197877859, 47.767773105005958],
            [22.634163246694584, 47.771664152108869],
            [22.653609094477787, 47.776382157781413],
            [22.780000148089528, 47.842982078390591],
            [22.894800203043758, 47.954535989873918],
            [22.89610912104726, 47.95389108624191],
            [22.930000173165382, 47.960544976277319],
            [23.043327192451528, 47.993044967740516],
            [23.087773239991861, 48.013473174578806],
            [23.102218109050625, 48.034300192371134],
            [23.112500189704633, 48.062773182261608],
            [23.125618203486965, 48.089364267245841],
            [23.152354127758429, 48.105691208848711],
            [23.174163336967695, 48.108327149766794],
            [23.260691232432123, 48.090127020436753],
            [23.279163270701787, 48.075273114501996],
            [23.299100130374541, 48.044854014326873],
            [23.356109142067339, 48.021100203575443],
            [23.410273168176559, 48.000545094722824],
            [23.50298221433593, 47.982464156056452],
            [23.514263250213105, 47.989336143213094],
            [23.566100124718105, 47.999991218559487],
            [23.823891257293241, 47.966945062283898],
            [23.969718100404407, 47.961391213224132],
            [24.032363271446286, 47.955964266179151],
            [24.096182244212088, 47.932353953610473],
            [24.127500219686283, 47.912009062889908],
            [24.246945190117117, 47.903882137186471],
            [24.326945091732171, 47.928891221760935],
            [24.421527157980421, 47.957773248527602],
            [24.453891194973892, 47.958609091914454],
            [24.556391142259741, 47.953045016933075],
            [24.572291277351837, 47.949482205159299],
            [24.588336084093214, 47.945827024812459],
            [24.615409128511317, 47.933745014265398],
            [24.670100208693555, 47.895482129076768],
            [24.670900177534861, 47.866800094520656],
            [24.693882182639697, 47.849164234913999],
            [24.716945156929398, 47.83860907185381],
            [24.770273172013418, 47.820545064631673],
            [24.802773163476616, 47.817773169243949],
            [24.821109247276553, 47.808400189810826],
            [24.863327217308552, 47.759991178438199],
            [24.898709242367687, 47.717845125135582],
            [24.928918124410586, 47.713145056735854],
            [24.96124511339184, 47.72083612347312],
            [24.99975467799149, 47.724477641317705],
            [25.029718221727819, 47.728864142718933],
            [25.049436146088823, 47.733327003257997],
            [25.085545049793609, 47.744427157664219],
            [25.116936115463659, 47.759436128808275],
            [25.153818165919233, 47.790127137923221],
            [25.175136195601112, 47.823327018303544],
            [25.22443620328383, 47.876100151395818],
            [25.246382205153196, 47.888736038106131],
            [25.333882233750643, 47.916664204288665],
            [25.501663292996454, 47.933327092571872],
            [25.525000187882767, 47.934709100771315],
            [25.568327083707999, 47.935818026564462],
            [25.588609110154493, 47.93443601836502],
            [25.646109133736644, 47.933591122522486],
            [25.780482273764676, 47.936854029799846],
            [25.817218143828541, 47.955273094441353],
            [25.838327128844497, 47.963464225523367],
            [25.922218077562547, 47.974709051578927],
            [26.037218124727048, 47.983600071578095],
            [26.102709119543078, 47.981718166671882],
            [26.115000174755892, 47.981364115080638],
            [26.158609205442644, 47.985253988717162],
            [26.193463338238359, 48.00151806604616],
            [26.272218191952192, 48.076100073071714],
            [26.317700242727909, 48.176136076093186],
            [26.468882283792965, 48.217491213010092],
            [26.507500226401135, 48.213464211437085],
            [26.530836115458868, 48.208191156134731],
            [26.567773318837652, 48.222491185906151],
            [26.634991153350512, 48.257164269592678],
            [26.704163312413414, 48.263882197368517],
            [26.817218087483553, 48.246800046287419],
            [26.954718180318849, 48.185545097710062],
            [27.000554115047663, 48.155545092694936],
            [27.158600264280238, 47.985818090802482],
            [27.200827119129741, 47.902200056852436],
            [27.21110014732821, 47.845818179156922],
            [27.238327083489168, 47.785254067041379],
            [27.28027331221935, 47.693045085226785],
            [27.299436186950715, 47.658600156945326],
            [27.327773222371178, 47.636653987437782],
            [27.361191199872763, 47.616982163544193],
            [27.433327199906813, 47.565264144426592],
            [27.472918113838233, 47.485982236639572],
            [27.576100180406428, 47.366100066137832],
            [27.636309235102516, 47.305954043353708],
            [27.679436138717477, 47.293873206273233],
            [27.719282197782292, 47.285645027179058],
            [27.742218102419514, 47.262773160282208],
            [27.785409211412997, 47.203318141597876],
            [27.798054150578793, 47.152491118790337],
            [27.861382279093306, 47.102482169735012],
            [27.945382192552842, 47.043309118274308],
            [27.998718086625871, 47.027773093057064],
            [28.03388218220195, 47.015545069756385],
            [28.079373285433093, 46.982273105008503],
            [28.106382292110737, 46.920273172874985],
            [28.115827188273357, 46.887773181411589],
            [28.119718235376382, 46.854409183366656],
            [28.115754098077417, 46.84047309583211],
            [28.174436137648826, 46.739718093153996],
            [28.203882266500585, 46.69860016409784],
            [28.228745170683254, 46.672009079113693],
            [28.246800125449653, 46.6078001802111],
            [28.248427220498655, 46.42703605582436],
            [28.19360018584652, 46.330273186001946],
            [28.138882283574134, 46.190536130840783],
            [28.108327228929028, 46.102291118686978],
            [28.091382205784129, 46.075827103355309],
            [28.093445159799472, 45.991754099699833],
            [28.107218135497902, 45.966100111493276],
            [28.120273285006164, 45.921927146359124],
            [28.164509114414301, 45.720818131252017],
            [28.166800223834713, 45.668191178551623],
            [28.15930026449044, 45.627000159299413],
            [28.137273293436039, 45.62040008108265],
            [28.06916328914653, 45.583318038416635],
            [28.174436137648826, 45.47207325152344],
            [28.211309135648975, 45.450109144742967],
            [28.214836072877034, 45.448644993892188],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "RUS", Country: "Russia" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [21.258097520000149, 55.249876979000248],
              [21.263936327670592, 55.248982075697654],
              [21.274854079319255, 55.250618111693598],
              [21.300273206598206, 55.258891217789028],
              [21.327654034501734, 55.270627055733883],
              [21.370782279221032, 55.286773115865799],
              [21.431382265882519, 55.251936082152554],
              [21.564163347216834, 55.197209127424799],
              [21.647354071741802, 55.181800171859948],
              [21.72262708286695, 55.13873613251917],
              [22.059927105167674, 55.028527013584835],
              [22.103327091189044, 55.029436114805833],
              [22.122773274248232, 55.038891069252202],
              [22.138745158431846, 55.052700087134269],
              [22.190691165316593, 55.060964140774274],
              [22.258054174392839, 55.066382203001922],
              [22.27971820903872, 55.067218046388973],
              [22.51277317180751, 55.063327166923955],
              [22.602909141322925, 55.044854122825996],
              [22.645409246216616, 54.964473179891513],
              [22.682218206476364, 54.958327065551941],
              [22.719991253241972, 54.962627149530988],
              [22.838745051935121, 54.899300194483089],
              [22.851382279749885, 54.881664167238512],
              [22.86500019023913, 54.838600127897394],
              [22.86687321032781, 54.812144997383086],
              [22.840409194996141, 54.77762714654375],
              [22.813882147752167, 54.771664260607963],
              [22.745273252584752, 54.717209047181996],
              [22.720136092529401, 54.693182154024271],
              [22.694854093186621, 54.529436142978398],
              [22.691382141243821, 54.493609038859404],
              [22.698327218596319, 54.464573120350224],
              [22.710336138947241, 54.44391809921143],
              [22.736109150179288, 54.423464243749265],
              [22.776391235848507, 54.376936131454386],
              [22.785882232478514, 54.363836054944784],
              [22.766663198995843, 54.359718025903248],
              [22.664163251709908, 54.351109140765857],
              [22.630000123013758, 54.348873184268598],
              [22.455554109620493, 54.345545065784336],
              [22.429727118931794, 54.345545065784336],
              [22.296154115383274, 54.339909074073489],
              [22.265554133736885, 54.338045106440234],
              [22.165000129097848, 54.33499107619059],
              [21.981936178111425, 54.332773224604296],
              [21.855554176955025, 54.331664131173],
              [21.731382148396222, 54.330835999136809],
              [21.625554082626024, 54.329718020888151],
              [21.57751823358376, 54.328400218067145],
              [21.570436195932871, 54.328209110674322],
              [21.524163228771442, 54.326945119671961],
              [21.442491137468153, 54.325554059017009],
              [21.411945135278643, 54.325827141423304],
              [21.367218126343005, 54.327218034440079],
              [21.283609144848526, 54.329991103294446],
              [21.231936052732067, 54.332773224604296],
              [21.18193615613194, 54.336382136845543],
              [21.126382242834666, 54.341664244603294],
              [20.898054170555724, 54.356100061206689],
              [20.738882164085453, 54.358044998024866],
              [20.432773215244424, 54.381664195410906],
              [20.371663273592389, 54.387773261738346],
              [20.33118220154131, 54.393527103008125],
              [20.329082199513778, 54.393836059960051],
              [20.30139124119205, 54.39777320753069],
              [20.158609208236726, 54.411936109365797],
              [20.090554021594357, 54.418600057685012],
              [20.004163254066043, 54.41915410148647],
              [19.857700224502025, 54.429909089119008],
              [19.797009257270986, 54.437544995145061],
              [19.791086352000207, 54.438532978000069],
              [19.766303624000329, 54.44139258000024],
              [19.651109109213564, 54.455827107138248],
              [19.627254045142593, 54.463273254901139],
              [19.621122897000191, 54.466862900000194],
              [19.60755487900002, 54.474806661000116],
              [19.513957410000216, 54.538726883000152],
              [19.452320053000335, 54.577535590000195],
              [19.397531291000178, 54.614061431000152],
              [19.358722583999963, 54.639172947000247],
              [19.315000000000282, 54.669999999000026],
              [19.345000000000198, 54.814999999000236],
              [19.063333333000173, 55.346666666000203],
              [18.936666667000225, 55.849999999000147],
              [18.925750000000164, 55.879799999000227],
              [18.945283330000223, 55.891366669000149],
              [19.022537818000274, 55.931595476000041],
              [19.046750000000202, 55.923666666000031],
              [19.076443765000135, 55.913942791000068],
              [19.081083184000192, 55.912423511000156],
              [19.084805680000159, 55.911204498000075],
              [19.700383381000279, 55.709620072000178],
              [19.924433333000195, 55.636249999000142],
              [20.653431221000119, 55.384121808666862],
              [20.658835947000227, 55.382333531500194],
              [20.791725904500254, 55.338502843500237],
              [20.935216519000107, 55.291175979000343],
              [20.942836292885943, 55.287200074176269],
              [20.98480939243251, 55.276554647592491],
              [20.992784520000271, 55.276157979000288],
              [21.258097520000149, 55.249876979000248],
            ],
          ],
          [
            [
              [-169.742782592999845, 65.164169312000183],
              [-170.12306213399998, 64.994720459000291],
              [-170.501663207999883, 64.823890686000141],
              [-170.878616332999826, 64.652221680000252],
              [-171.253890990999849, 64.47944641100031],
              [-171.627777099999975, 64.305557251000209],
              [-171.999999999999886, 64.130554199000187],
              [-172.310836791999833, 63.990833282000096],
              [-172.636947631999959, 63.850276947000282],
              [-172.92832946799993, 63.709167480000247],
              [-173.235275268999885, 63.56694412200028],
              [-173.540832519999896, 63.424167633000309],
              [-173.845001220999876, 63.28055572500017],
              [-174.147781371999969, 63.136390686000254],
              [-174.449447631999817, 62.991390228000171],
              [-174.749725341999891, 62.845554352000306],
              [-175.048889159999817, 62.698890686000311],
              [-175.346664428999873, 62.551666260000275],
              [-175.643325805999865, 62.403610229000265],
              [-175.938613891999836, 62.25472259500026],
              [-176.233062743999909, 62.105278015000181],
              [-176.52610778799982, 61.955001831000232],
              [-176.817779540999879, 61.803890228000228],
              [-177.108612060999889, 61.652221680000366],
              [-177.398056029999822, 61.499721527000304],
              [-177.686386107999823, 61.34638977100019],
              [-177.973892211999811, 61.192501068000183],
              [-178.259994506999845, 61.038055420000262],
              [-178.544998168999854, 60.882499695000206],
              [-178.829162597999954, 60.726387024000246],
              [-179.112228393999828, 60.569721222000226],
              [-179.39389038099992, 60.412223816000221],
              [-179.675003051999937, 60.253887177000252],
              [-179.728995938999873, 60.223257424000224],
              [-179.750477634999953, 60.211071067000262],
              [-179.759652308999847, 60.206035236000048],
              [-179.777771194999843, 60.195923216333483],
              [-179.985253146999838, 60.082206579000172],
              [-179.985251481999882, 60.096045137000146],
              [-179.985194882999792, 60.566290090000223],
              [-179.991092667999823, 65.036566176000235],
              [-179.99112378440546, 65.07325181306652],
              [-179.999999999999886, 65.068909118642352],
              [-179.999999999999886, 66.068909062297507],
              [-179.999999999999886, 67.068909173590555],
              [-179.999999999999886, 68.068909117245539],
              [-179.999999779884405, 68.980100029723872],
              [-179.999999667999873, 69.933899993000296],
              [-179.999999667999873, 70.933899994000171],
              [-179.99999983399988, 70.997209150636024],
              [-179.99999983399988, 71.535845494063835],
              [-179.999999667999873, 71.933899994000114],
              [-179.999999667999873, 72.933899995000218],
              [-179.999999667999873, 73.933899996000093],
              [-179.999999667999873, 74.928166997000289],
              [-178.56295666599982, 74.928367997000237],
              [-178.290187665999866, 74.923422997000159],
              [-177.17982466499987, 74.897886997000285],
              [-177.173771664999975, 74.897446997000259],
              [-177.149695664999825, 74.896832997000104],
              [-177.136309664999828, 74.89585699700018],
              [-177.134084664999847, 74.895799997000267],
              [-177.109039664999926, 74.893972997000276],
              [-177.10405066499996, 74.893843997000261],
              [-177.094773664999934, 74.893165997000096],
              [-177.083592664999941, 74.892876997000144],
              [-177.012365664999834, 74.887653997000029],
              [-176.995933664999853, 74.887222997000265],
              [-176.995074664999834, 74.887159997000197],
              [-176.947688664999873, 74.885909997000169],
              [-176.924001664999963, 74.884159997000097],
              [-176.92128666499994, 74.884087997000222],
              [-176.919776664999887, 74.883975997000164],
              [-176.875462664999901, 74.882792997000195],
              [-176.850146664999926, 74.880912997000053],
              [-176.847859664999874, 74.88085199700015],
              [-176.774393664999877, 74.875377997000214],
              [-176.765082664999881, 74.875123997000173],
              [-176.728014664999847, 74.87235099700024],
              [-176.725524664999881, 74.872281997000186],
              [-176.714329664999866, 74.871442997000202],
              [-176.711111664999891, 74.871353997000142],
              [-174.91953666299986, 74.728744997000263],
              [-173.233254660999961, 74.661958997000227],
              [-173.225063660999894, 74.661197997000158],
              [-173.217761660999969, 74.660879997000166],
              [-172.189193659999972, 74.562348997000242],
              [-171.173548658999948, 74.459203997000145],
              [-171.171367658999969, 74.458848997000075],
              [-171.165478658999888, 74.458232997000266],
              [-170.97157865899996, 74.426472997000189],
              [-170.914222658999819, 74.420399997000061],
              [-169.107594656999908, 74.109278996000114],
              [-168.972941666999873, 74.073246486000244],
              [-168.971370842999846, 74.07282575000022],
              [-168.972209003666592, 72.783530292333296],
              [-168.976943969999923, 65.500000000000284],
              [-169.360549926999852, 65.332778931000121],
              [-169.742782592999845, 65.164169312000183],
            ],
          ],
          [
            [
              [57.769978921000302, 85.190131584000255],
              [58.5808742480001, 85.187467136000237],
              [58.922023555000351, 85.186346007000111],
              [59.229398368000261, 85.184819817000175],
              [60.071816598000169, 85.18063652300026],
              [60.724206153000182, 85.176297002000297],
              [61.218457557000306, 85.173009007000303],
              [61.886280563000355, 85.167436032000239],
              [62.361059196000383, 85.163473749000218],
              [63.498752864000153, 85.152040855000166],
              [64.630693450000194, 85.138724006500127],
              [64.663487997000288, 85.137758920000181],
              [65.833342561000109, 85.103332007000176],
              [66.411758440000312, 85.085029351000117],
              [67.018322511000292, 85.065835815000128],
              [67.414598724000257, 85.052401558000099],
              [68.185047564000115, 85.026282007000191],
              [68.676758257000245, 85.008480019000217],
              [69.333008822000295, 84.984720497000239],
              [71.033822984000153, 84.947568734000185],
              [71.060085464000281, 84.94649763200016],
              [71.064444869000113, 84.94639581600029],
              [71.065204566000148, 84.946378007000305],
              [71.091493566000167, 84.945304007000288],
              [71.092996203000041, 84.9452689270002],
              [71.096419239000113, 84.945188787000177],
              [71.113833610000086, 84.944476288000089],
              [71.11801856600016, 84.944305007000196],
              [71.12395219400014, 84.944165767000072],
              [71.142990954000311, 84.943718788000126],
              [71.860234644000059, 84.913380384000277],
              [72.325338567000244, 84.893707007000216],
              [72.682519169000216, 84.877645775000246],
              [73.484417984000288, 84.841586740000139],
              [74.62001073000016, 84.787420641000097],
              [75.731978313000241, 84.731271787000196],
              [76.820258663000175, 84.673202708000304],
              [76.822734017000187, 84.673005895000301],
              [76.841215863000116, 84.671991446000277],
              [76.869021572000293, 84.670465006000313],
              [76.874601769000265, 84.670020469000178],
              [76.879716448000408, 84.669612821000044],
              [76.882903439000359, 84.669437546000211],
              [76.88930657200018, 84.669085006000159],
              [77.888207101000262, 84.587055004000263],
              [78.053184948000251, 84.573506912000312],
              [79.176630879000413, 84.475795590000189],
              [79.784588611000174, 84.419875874000297],
              [80.26092857500015, 84.376062006000268],
              [80.448108158000423, 84.357880193000199],
              [80.82671399200035, 84.321103445000176],
              [81.057215787000189, 84.332681531000162],
              [82.01010752800039, 84.378081937000161],
              [82.089866576000304, 84.381882006000239],
              [82.226405721000049, 84.388041497000188],
              [83.140196870000125, 84.429263743000149],
              [83.192762273000312, 84.430557728000167],
              [83.201329719000228, 84.430932461000168],
              [83.251267365000103, 84.432155743000123],
              [83.25938256600017, 84.432359519000158],
              [83.262614016000384, 84.43250045200017],
              [83.302306368000245, 84.433468860000175],
              [83.338905308000108, 84.435059623000257],
              [83.911855033000279, 84.448669746000064],
              [83.923749979000377, 84.449171025000055],
              [83.945329578000383, 84.450080006000235],
              [84.736986961000412, 84.46756227800023],
              [85.115770507000263, 84.475926803000164],
              [85.900217489000113, 84.49158003000025],
              [86.296573580000313, 84.499489006000204],
              [87.486889581000298, 84.520738006000158],
              [87.788319129000286, 84.525492241000109],
              [88.685827951000249, 84.539647452000281],
              [89.89245139600024, 84.556192816000191],
              [89.922677556000224, 84.556070462000037],
              [89.938822584000263, 84.556005006000305],
              [89.943320326000276, 84.556061734000195],
              [89.96482813900019, 84.556332540000199],
              [91.234662982000373, 84.54976851500021],
              [92.500790220000056, 84.540579762000164],
              [93.826126198000111, 84.550273791000279],
              [95.155390709000187, 84.557094575000121],
              [96.48720551200023, 84.561031682000191],
              [96.958501975000104, 84.553664919000198],
              [97.378608591000329, 84.547098006000169],
              [97.901634762000185, 84.547046309000223],
              [98.01598565400019, 84.547034941000163],
              [99.211150124000369, 84.524898540000152],
              [100.164320922000059, 84.505226971000241],
              [100.396215594000182, 84.500441006000131],
              [100.849879185000191, 84.490106356000183],
              [101.570337865000226, 84.473693816000036],
              [102.732723396500319, 84.444690016000095],
              [103.882632597000338, 84.413465006000081],
              [104.942354598000179, 84.361247006000156],
              [105.982400599000187, 84.307172006000258],
              [106.640134259000149, 84.271148735000168],
              [107.002671711000346, 84.251292814000294],
              [107.457857049000268, 84.225071532000243],
              [108.003120601000205, 84.193661006000298],
              [108.298619364000331, 84.175782461000097],
              [108.983750328000326, 84.134329504000164],
              [109.774868826000102, 84.061553288000255],
              [110.035002602000247, 84.037623006000217],
              [111.052617603000101, 83.939018006000197],
              [111.90292775800026, 83.852336247000096],
              [112.0376056260003, 83.838606948000177],
              [112.175376753000251, 83.823848325000199],
              [112.990990605000206, 83.736476005000213],
              [113.837446739000143, 83.59754453800025],
              [114.457038128000136, 83.49584886100007],
              [115.818627928000183, 83.251394686000253],
              [117.54335656200044, 82.70933372400026],
              [118.074151734000338, 82.324649487000215],
              [118.379590346000214, 82.210201442000198],
              [118.385440496667002, 82.205527084000153],
              [118.526481246000344, 82.151416143000233],
              [118.802433791000453, 81.909658973000148],
              [119.181799611000201, 81.577303003000168],
              [119.146839668666757, 81.302781919666927],
              [119.699874767000324, 81.148093482000036],
              [119.829985799000156, 81.087291604000086],
              [120.472012741000299, 80.897473799000068],
              [120.537383734000173, 80.864749702000267],
              [120.588626298000122, 80.849107553000181],
              [120.596827612000197, 80.846604003000152],
              [120.741734612000187, 80.772792003000234],
              [120.841894430000366, 80.74160506000004],
              [121.148908367000217, 80.579601931000298],
              [121.181368301000361, 80.569132957000249],
              [121.191435613000408, 80.565886002000298],
              [121.863850613000409, 80.188473002000308],
              [122.501629602000321, 79.95669877000023],
              [123.155042847000232, 79.719242534000216],
              [123.95571970400033, 79.157450666000159],
              [124.002403218000268, 79.036454685000194],
              [124.077178616000197, 78.842649001000154],
              [124.178542335000429, 78.822322475000192],
              [124.369308852000245, 78.78406778100026],
              [124.883465328000284, 78.628139896000164],
              [125.847956617000136, 78.335639000000299],
              [126.849418618000186, 78.51665800100011],
              [127.354369827000369, 78.603442358000223],
              [127.88230552500022, 78.694176660000153],
              [128.517063643000142, 78.76529365100015],
              [129.17561462000026, 78.839076001000308],
              [130.501948622000441, 78.978326001000084],
              [130.53303233000031, 78.980252205000284],
              [130.578116495000359, 78.983045437000158],
              [130.641617517000327, 78.99246866000027],
              [130.908918279000204, 79.019239646000187],
              [131.317567172000167, 79.077866322000261],
              [131.750844623000262, 79.140026001000194],
              [132.537972749000232, 79.212160933000291],
              [133.145132613000442, 79.267802770000287],
              [134.571938679000368, 79.389263776000234],
              [134.891079057000184, 79.403756865000105],
              [136.185238382000421, 79.504508512000143],
              [137.228406983000127, 79.54458745400018],
              [138.27926166400016, 79.58127560600019],
              [139.33720663000031, 79.614536008000243],
              [140.765926818500134, 79.6086630315001],
              [140.991439456500302, 79.640061238000101],
              [141.001700015000438, 79.641027748000113],
              [141.003165542000033, 79.641230640333447],
              [141.019055631000242, 79.642727002000242],
              [141.030537631000129, 79.644315002000155],
              [142.483660633000227, 79.775994002000232],
              [143.973629634000304, 79.901088002000279],
              [143.980577209000444, 79.90140929200021],
              [143.981738812000344, 79.901462973000264],
              [143.991491528000324, 79.902249523000194],
              [144.007515920000287, 79.902988840000148],
              [144.011984698000219, 79.903348820000247],
              [144.027521156000148, 79.904064538000171],
              [144.033748501000332, 79.904565648000244],
              [144.043026581000277, 79.904992315000243],
              [144.052225634000138, 79.905415002000154],
              [144.055557634000138, 79.905683002000103],
              [144.056534634000172, 79.905728002000274],
              [144.058474159000298, 79.905883879000157],
              [144.068255869000154, 79.906669728000054],
              [144.092330790000233, 79.907773725000197],
              [144.101993288000358, 79.908548528000267],
              [144.106718007000381, 79.908924208000258],
              [144.10750849300041, 79.908960453000304],
              [144.1193084490003, 79.909905952000145],
              [144.132190088000243, 79.910494610000171],
              [144.139787365000188, 79.911102654000217],
              [144.197071641000321, 79.913711998000281],
              [144.204743759000394, 79.914322916000174],
              [144.27286313400009, 79.919734502000153],
              [144.276396732000308, 79.92000277800031],
              [144.285547338666788, 79.9207179316669],
              [144.294591090000267, 79.921424394000155],
              [144.297897523000188, 79.921686880000209],
              [144.29914155500046, 79.921742977000292],
              [144.366628132000415, 79.927050381000129],
              [144.635890192666807, 79.948221687666887],
              [144.641817907000132, 79.948678717000178],
              [144.64859963500021, 79.949202502000105],
              [144.661183635000157, 79.950178002000143],
              [144.679490839000209, 79.95097944600019],
              [144.683076996000182, 79.95113985900025],
              [144.686930635000294, 79.9514390020002],
              [144.693060707000313, 79.951707330000204],
              [144.696982253000442, 79.951878725000256],
              [144.704887117000453, 79.952492693000295],
              [145.789041491000262, 79.99786494000017],
              [146.047706542000128, 80.007762166000305],
              [146.88272363700014, 80.039712002000272],
              [147.985283638000283, 80.077988002000211],
              [148.002673869000205, 80.078034312000284],
              [148.005561792000321, 80.078041932000247],
              [148.006336441500252, 80.078067392000207],
              [148.903937639000219, 80.079226002000155],
              [149.504343949000258, 80.135975780000194],
              [150.621402244000365, 80.241558724000242],
              [151.717015039000216, 80.300372934000222],
              [152.825637935000259, 80.355670558000156],
              [153.591428299000313, 80.39099963200016],
              [153.946687643000359, 80.407389002000116],
              [154.329988475000278, 80.412288904000206],
              [155.110302091000221, 80.422263583000301],
              [156.277210845000354, 80.433262569000306],
              [157.446490742000151, 80.440370982000218],
              [157.457712122000203, 80.440095697000118],
              [157.49076020200016, 80.440238796000301],
              [158.644445930000302, 80.409824811000306],
              [159.79066755800045, 80.375640741000268],
              [160.928628490000165, 80.337724664000177],
              [161.509890898000208, 80.29765696700025],
              [161.99529065100046, 80.264197002000174],
              [162.386013864000347, 80.235653790000299],
              [163.046031970000286, 80.187437491000139],
              [163.626096098000176, 80.142626460000145],
              [164.080558653000367, 80.10751800200012],
              [164.085515975000135, 80.106927532000157],
              [164.088909481000314, 80.106524307500138],
              [164.091452063000361, 80.106222379000144],
              [164.097292026666992, 80.105526379000139],
              [164.102700870000376, 80.104881809000233],
              [164.104797014000269, 80.104715172000056],
              [164.109473653000236, 80.104343002000178],
              [164.112063653000291, 80.104034002000162],
              [164.121708653000383, 80.103267002000109],
              [164.123335060000159, 80.103072959000144],
              [164.128933247000418, 80.102404484000203],
              [164.132582839000406, 80.102113965000228],
              [165.499656273000369, 79.933350481000218],
              [166.469606204000456, 79.80564135100019],
              [166.822151655000198, 79.759223002000283],
              [167.906608656000458, 79.543763002000219],
              [168.948301657000343, 79.32484700100008],
              [169.937885658000141, 79.00447100100024],
              [170.10551659000032, 78.965666028000214],
              [170.193727267000327, 78.945245856000099],
              [171.618051063500388, 78.43670852050019],
              [171.624400188000322, 78.434304666000173],
              [172.482151646000375, 77.882747721000271],
              [172.816098185000328, 77.309355261000121],
              [172.682537662000414, 76.733179305000306],
              [172.681110843667057, 76.731761208666796],
              [172.680149661000314, 76.727345999000249],
              [172.679034277000142, 76.72623623200019],
              [172.678345444250226, 76.725488649000226],
              [172.677663284000346, 76.722349859000076],
              [172.137671604600428, 76.159860901400123],
              [172.135555660000023, 76.157643998000253],
              [172.127722660000359, 76.153121998000188],
              [172.12606068, 76.151304957000036],
              [172.125364849000221, 76.150544383000181],
              [171.239732896000191, 75.616497251000112],
              [171.235062686000447, 75.614618287000098],
              [171.233128671999907, 75.613400394000166],
              [170.056260246000193, 75.119845265000151],
              [169.578660764000233, 74.970773009000311],
              [169.162330658000428, 74.840824997000141],
              [168.840096713000435, 74.740246358000206],
              [168.637887947000422, 74.67713130400017],
              [168.081681176000188, 74.545096803000092],
              [167.023187656000459, 74.293827997000164],
              [167.021568944000251, 74.293550287000073],
              [167.020191301000182, 74.293314330000157],
              [167.015408331000458, 74.292131141000255],
              [166.443126902000131, 74.190154754000275],
              [165.445251914333539, 74.012340808000204],
              [166.222928381000315, 73.905255745000147],
              [166.845763655000155, 73.819491996000295],
              [168.488825657000348, 73.46138899600021],
              [168.715251657000408, 73.388460996000219],
              [169.375580658000445, 73.422844996000151],
              [170.536214659000166, 73.419852996000031],
              [170.676238659000433, 73.46595399600028],
              [170.936341659000163, 73.589123996000069],
              [172.316329660000292, 74.017412996000303],
              [172.318865462500185, 74.017942953500267],
              [172.321400293000352, 74.018699950000041],
              [172.323660573000325, 74.019374942000297],
              [172.330883962000144, 74.020882679000238],
              [172.332207297000309, 74.021277664000138],
              [172.354438305000258, 74.025911891000192],
              [172.374261661000332, 74.030043996000188],
              [172.69926066100038, 74.12599299600015],
              [172.701366686000483, 74.126424053500102],
              [172.712861632000113, 74.129776449000133],
              [172.7151680850003, 74.130247734000136],
              [172.723354640000025, 74.132640763000154],
              [172.72551923400033, 74.133082811000293],
              [172.727743936000365, 74.133732813000279],
              [172.730715661000318, 74.134600996000074],
              [172.742965661000426, 74.137100996000186],
              [172.744631661000426, 74.137586997000142],
              [172.786178661000463, 74.146053997000308],
              [172.787640661000296, 74.146479997000199],
              [174.518891851666922, 74.484113871000091],
              [174.519650040000556, 74.484250902000156],
              [174.524435663000304, 74.48514399700025],
              [174.525988114500137, 74.485336406000272],
              [174.530081086000109, 74.486078759000122],
              [174.53203999200025, 74.486320969000246],
              [174.533964929000035, 74.486680102000207],
              [174.541701981000415, 74.488102733000233],
              [174.543130816000257, 74.488279455000281],
              [174.545604026000206, 74.488740690000213],
              [174.547083916666793, 74.489012379000201],
              [174.550023136000277, 74.489555849000226],
              [174.552648663000355, 74.490044997000211],
              [175.542907663000278, 74.609085997000307],
              [175.558590232000142, 74.610870342000283],
              [175.575435765000293, 74.612786554000195],
              [175.60661668500029, 74.617824685000187],
              [175.637151664000498, 74.622757997000178],
              [175.700232648000338, 74.629255890000195],
              [175.739220963000321, 74.633271754000134],
              [175.795542079000256, 74.642286469000226],
              [175.846490664000385, 74.65044099700026],
              [176.313628664000134, 74.696756997000307],
              [176.548522664000302, 74.723476997000091],
              [176.557610891000422, 74.723993831000257],
              [176.629639146000045, 74.728089489000183],
              [176.87596700700044, 74.752512608000302],
              [177.458863842000255, 74.80695893300026],
              [177.91856866600051, 74.849897997000312],
              [177.919444426000155, 74.849934987000211],
              [177.930472666000469, 74.8509329970002],
              [177.933256118000372, 74.851049821000231],
              [177.934374412000352, 74.851143876000151],
              [177.942392666000501, 74.851868997000167],
              [177.945676205000268, 74.852006368000275],
              [177.948535995000498, 74.852125765000096],
              [177.952947849000338, 74.852309838000167],
              [177.954679666000516, 74.85238199700018],
              [177.960822666000013, 74.852637997000215],
              [177.96696666600053, 74.852893997000251],
              [177.973110666000139, 74.853149997000116],
              [177.973930466000297, 74.853184155000292],
              [177.979254572000286, 74.853405692000194],
              [177.983106072000282, 74.853565845000219],
              [177.985398666000037, 74.853660997000304],
              [177.987091722000173, 74.853731530000175],
              [177.991543407000336, 74.853916515000094],
              [177.997688125000195, 74.854171680000206],
              [178.003833044000316, 74.854426682000167],
              [178.009978162000266, 74.854681519000167],
              [178.014228413000296, 74.854857658000299],
              [178.01612366600034, 74.854935997000098],
              [178.018567717000337, 74.855037418000052],
              [178.022268999000289, 74.8551907020003],
              [178.027575826000316, 74.855410330000154],
              [178.028414666000344, 74.855444997000291],
              [178.034560666000289, 74.855698997000161],
              [178.040706666000347, 74.855952997000202],
              [178.046853666000288, 74.856206997000299],
              [178.049213967000014, 74.856304543000192],
              [178.052999583000314, 74.856460789000153],
              [178.055341478000514, 74.856557382000233],
              [178.059146666000402, 74.856713997000099],
              [178.062361975000044, 74.856846857000107],
              [178.065293212000256, 74.856967675000305],
              [178.07144032500014, 74.857220872000255],
              [178.077587636000317, 74.85747390500012],
              [178.083735146000208, 74.857726774000184],
              [178.089882855000269, 74.857979479000221],
              [178.095928367500278, 74.858227804000251],
              [178.102178666000327, 74.858483997000178],
              [178.105339191000326, 74.858614036000233],
              [178.108327171000042, 74.858736608000243],
              [178.114475672000367, 74.858988656000122],
              [178.120624372000293, 74.859240540000201],
              [178.12440534700022, 74.859395323000115],
              [178.126773666000531, 74.859491997000305],
              [178.130598728000336, 74.859648757000116],
              [178.132922364000365, 74.859743815000172],
              [178.135667848000139, 74.859856054000147],
              [178.139071666000405, 74.859994997000172],
              [178.140789877000543, 74.860065402000203],
              [178.14522114700037, 74.86024643400026],
              [178.151370834000545, 74.860497497000154],
              [178.154820339000025, 74.860638228000198],
              [178.157520666000323, 74.860747997000146],
              [178.163670666000257, 74.860998997000308],
              [178.165639175000251, 74.861079325000162],
              [178.169821080000133, 74.861249701000247],
              [178.174147833000347, 74.861425857000256],
              [178.175971666000351, 74.86149999700018],
              [178.18212266600051, 74.861749997000231],
              [178.188273666000327, 74.861999997000169],
              [178.194424666000145, 74.862249997000049],
              [178.200575666000532, 74.862499997000157],
              [178.202028775000031, 74.862559057000226],
              [178.206726883000101, 74.862749675000202],
              [178.211413807000326, 74.862939707000294],
              [178.212878666000279, 74.862998997000034],
              [178.219030666000549, 74.86324799700003],
              [178.221505277000574, 74.863348558000268],
              [178.225182434000317, 74.863497444000302],
              [178.228827105000278, 74.863644912000098],
              [178.23133466600018, 74.863745997000194],
              [178.237487666000561, 74.863994997000191],
              [178.239801737000448, 74.864088658000242],
              [178.243639748000305, 74.86424373400024],
              [178.247344732000442, 74.864393332000191],
              [178.249792666000303, 74.8644919970003],
              [178.251273137000112, 74.864551909000284],
              [178.255945602000338, 74.864740440000162],
              [178.262098821000563, 74.864988546000063],
              [178.268252236000308, 74.865236488000164],
              [178.272272681000345, 74.865398373000289],
              [178.274405666000348, 74.865483997000297],
              [178.278643904000205, 74.865654794000307],
              [178.280559650000441, 74.865731878000304],
              [178.282177547000288, 74.86579693300007],
              [178.286713666000537, 74.865978997000241],
              [178.289527767000209, 74.866092402000277],
              [178.292867843000408, 74.866226612000162],
              [178.299022231000095, 74.866473731000269],
              [178.305176814000333, 74.866720687000281],
              [178.311331591000567, 74.86696747800022],
              [178.316386706000458, 74.867170034000083],
              [178.317486666000264, 74.867213997000079],
              [178.318755725000187, 74.867264924000096],
              [178.323641728000325, 74.867460567000251],
              [178.329797087000202, 74.867706865000173],
              [178.335898832666999, 74.867950846000213],
              [178.336816716000271, 74.86798752500016],
              [178.342108386000177, 74.868198968000286],
              [178.348264326000049, 74.868444773000135],
              [178.350309940000216, 74.86852639600005],
              [178.354420666000351, 74.86868999700016],
              [178.359276955000269, 74.868884059000266],
              [178.360576787000355, 74.868935888000181],
              [178.362691195000139, 74.869020139000156],
              [178.366733666000187, 74.869180997000115],
              [178.372889666000333, 74.869425997000292],
              [178.379046666000249, 74.869670997000185],
              [178.38520366600028, 74.869915997000192],
              [178.387812861000384, 74.87001980600013],
              [178.39136131700036, 74.870160799000189],
              [178.393769844000417, 74.87025643200019],
              [178.397518666000451, 74.870404997000151],
              [178.400173859000347, 74.87051063600029],
              [178.403676478000307, 74.870649612000193],
              [178.409834347000242, 74.870893772000159],
              [178.41599240800042, 74.871137767000221],
              [178.42215066200049, 74.871381598000198],
              [178.425723059000575, 74.871522944000162],
              [178.428309666000246, 74.871624997000197],
              [178.431706666000309, 74.871759597000107],
              [178.434467744000131, 74.871868765000102],
              [178.438676163000082, 74.872035041000174],
              [178.440626666000298, 74.872111997000161],
              [178.446785666000437, 74.872354997000173],
              [178.452944666000576, 74.872597997000241],
              [178.459104666000258, 74.872840997000083],
              [178.461717785000246, 74.872944096000197],
              [178.465263805000291, 74.873083804000203],
              [178.467605265000429, 74.873175988000185],
              [178.471423666000305, 74.873325997000222],
              [178.474493212000198, 74.873447085000294],
              [178.477583569000558, 74.873568667000256],
              [178.483743737000054, 74.873810852000304],
              [178.489904096000259, 74.874052872000277],
              [178.496064645000047, 74.87429472700029],
              [178.498427527000331, 74.874387425000293],
              [178.502225666000299, 74.87453599700018],
              [178.50813127400022, 74.874767945500196],
              [178.509061028000247, 74.87480437600027],
              [178.51454766600034, 74.875018997000154],
              [178.516947904000176, 74.87511327700031],
              [178.520708748000288, 74.875260502000174],
              [178.526870249000012, 74.875501535000126],
              [178.53014119500051, 74.87562940000015],
              [178.533031666000284, 74.875741997000148],
              [178.539193666000301, 74.875982997000165],
              [178.540541612000311, 74.87603571600016],
              [178.54535589200043, 74.876223643000174],
              [178.551219125500324, 74.876452332500207],
              [178.557680666000124, 74.876703997000163],
              [178.563843666000253, 74.876943997000296],
              [178.570006666000381, 74.877183997000259],
              [178.573749005000309, 74.877329731000202],
              [178.576169088000256, 74.877423862000285],
              [178.577449371000284, 74.877473624000288],
              [178.582332666000298, 74.877662997000243],
              [178.586527637000131, 74.877826358000164],
              [178.588495690000173, 74.877902797000218],
              [178.59374825000009, 74.878106658000263],
              [178.594659666000439, 74.878141997000284],
              [178.600822666000113, 74.878380997000136],
              [178.604267043000277, 74.878514548000112],
              [178.60698700900025, 74.878619963000233],
              [178.613151159000495, 74.87885868800015],
              [178.616401974000269, 74.878984496000271],
              [178.619315666000261, 74.879096997000261],
              [178.621873325000251, 74.879196166000213],
              [178.625480024000126, 74.879335646000186],
              [178.631644738000205, 74.879573877000098],
              [178.637809640000341, 74.879811944000153],
              [178.643974730000309, 74.880049845000201],
              [178.650140008000562, 74.880287582000278],
              [178.654592085000218, 74.880459133000159],
              [178.656305666000549, 74.880524997000293],
              [178.658058348000054, 74.880592648000288],
              [178.662471126000128, 74.880762562000143],
              [178.668636967000225, 74.880999804000226],
              [178.674802994000515, 74.881236881000177],
              [178.680969208000533, 74.881473794000158],
              [178.687135610000269, 74.881710542000235],
              [178.691803676000291, 74.881889633000185],
              [178.693302666000136, 74.881946997000171],
              [178.694769173000282, 74.88200336500023],
              [178.699468973000307, 74.882183543000167],
              [178.705635935000146, 74.882419796000306],
              [178.711803083000063, 74.882655884000258],
              [178.717970417000402, 74.882891807000192],
              [178.72413793800024, 74.883127565000166],
              [178.728648578000048, 74.883299862000285],
              [178.730305666000277, 74.883362997000233],
              [178.732071035000558, 74.883430544000078],
              [178.736473537000279, 74.883598587000222],
              [178.742641616000014, 74.883833851000134],
              [178.748809880000408, 74.884068949000209],
              [178.754978330000313, 74.884303883000143],
              [178.761146966000297, 74.884538651000184],
              [178.764757072000407, 74.884675945000197],
              [178.767315666000172, 74.884772997000198],
              [178.770096601000319, 74.884878933000095],
              [178.773484793000421, 74.885007693000091],
              [178.779653985000323, 74.885241967000297],
              [178.781614394000258, 74.885316358000296],
              [178.785823667000045, 74.885475997000299],
              [178.79199279500051, 74.885710008000217],
              [178.798162669000476, 74.885943798000199],
              [178.800157385000375, 74.886019324000245],
              [178.804332667000267, 74.886176997000234],
              [178.808961397000076, 74.88635254400026],
              [178.810502715000325, 74.886410860000296],
              [178.813650265000092, 74.886529861000099],
              [178.816672667000262, 74.886643997000306],
              [178.82284366700037, 74.886876997000229],
              [178.829014667000251, 74.887109997000152],
              [178.835128757333791, 74.887340883666695],
              [178.841356056000564, 74.887575627000274],
              [178.846225833000517, 74.8877590620001],
              [178.847527667000577, 74.887807997000152],
              [178.853698667000344, 74.888039997000192],
              [178.856442341000275, 74.88814357400031],
              [178.859870267000019, 74.888272506000135],
              [178.866042038000273, 74.888504469000054],
              [178.870224176000306, 74.888661536000143],
              [178.872213667000096, 74.888735997000197],
              [178.876963618000332, 74.888914515000238],
              [178.878386130000194, 74.888967900000296],
              [178.879433005000237, 74.88900715800014],
              [178.884558667000306, 74.889198997000165],
              [178.887817767000342, 74.889321504000065],
              [178.890730954000219, 74.889430670000195],
              [178.896903640000232, 74.889661807000266],
              [178.903076509000016, 74.889892779000149],
              [178.909249561000252, 74.890123586000186],
              [178.913217860000259, 74.890271848000168],
              [178.915422667000144, 74.890353997000148],
              [178.918206222000322, 74.890458144000149],
              [178.921596212000395, 74.890584705000038],
              [178.927623229500341, 74.890809541000181],
              [178.933943667000108, 74.891044997000108],
              [178.940117667000436, 74.891274997000153],
              [178.945028374000231, 74.891457936000279],
              [178.946291699000199, 74.891504959000144],
              [178.952466026000479, 74.891734610000242],
              [178.957370821000495, 74.89191690500013],
              [178.958640667000395, 74.891963997000119],
              [178.964815667000266, 74.892192997000222],
              [178.968015717000412, 74.892312189000165],
              [178.970990095000047, 74.892422571000054],
              [178.977165148000267, 74.89265156100015],
              [178.980320923000477, 74.892768499000226],
              [178.983340667000562, 74.89287999700025],
              [178.98978603850037, 74.893119022],
              [178.99569139100015, 74.893337539000242],
              [179.001867168000558, 74.893565868000053],
              [179.006016027000555, 74.893719142000123],
              [179.008043667000265, 74.893793997000216],
              [179.01421966700002, 74.894021997000209],
              [179.015856227000313, 74.894082414000138],
              [179.02039558000024, 74.894249862000152],
              [179.026572079000289, 74.894477530000188],
              [179.032540693500437, 74.89469735300014],
              [179.038925667000399, 74.894931997000185],
              [179.041713346000392, 74.895034893000172],
              [179.045102654000289, 74.895159541000226],
              [179.051279872000123, 74.895386548000261],
              [179.054549196000266, 74.895506601000136],
              [179.057457667000335, 74.895612997000057],
              [179.063634667000542, 74.895839997000166],
              [179.064417869000522, 74.895868774000178],
              [179.069812605000266, 74.896066575000305],
              [179.075990541000579, 74.896292920000292],
              [179.078538259000538, 74.896386192000193],
              [179.08216866700036, 74.896518997000214],
              [179.088346667000337, 74.896744997000269],
              [179.093347375000121, 74.896927900000208],
              [179.094525425000256, 74.896970963000172],
              [179.100704078000149, 74.897196647000158],
              [179.10486969200042, 74.897348685000168],
              [179.106882667000264, 74.897421997000151],
              [179.108399441000529, 74.897477474000254],
              [179.113061919000302, 74.897647518000269],
              [179.119241107000335, 74.897872705000168],
              [179.12542047300019, 74.898097727000277],
              [179.131600018000313, 74.898322583000265],
              [179.13535698700025, 74.898459185000206],
              [179.137779667000132, 74.898546997000153],
              [179.141388621000146, 74.898678391000033],
              [179.143959642000141, 74.898771800000191],
              [179.147346013000288, 74.898894738000081],
              [179.150139667000531, 74.898995997000213],
              [179.156319667000275, 74.899219997000273],
              [179.162500667000245, 74.899443997000162],
              [179.168680667000558, 74.899667997000222],
              [179.173698380000133, 74.899849839000296],
              [179.174861812000358, 74.899891947000071],
              [179.181042778000347, 74.900115480000181],
              [179.187223922000499, 74.900338847000285],
              [179.191560799000285, 74.900495448000186],
              [179.193405667000093, 74.900561997000295],
              [179.19958666700029, 74.900784997000073],
              [179.204123598000251, 74.900948655000263],
              [179.20576841400009, 74.901007957000047],
              [179.211950264000166, 74.901230662000273],
              [179.215626235000286, 74.90136298900029],
              [179.218132667000191, 74.901452997000092],
              [179.220266700000479, 74.901529977000223],
              [179.224314495000272, 74.901675576000287],
              [179.230496875000199, 74.901897785000131],
              [179.236679431000539, 74.902119829000185],
              [179.242862163000154, 74.902341706000186],
              [179.245218981000335, 74.902426219000176],
              [179.249045667000246, 74.902562997000302],
              [179.255242521000241, 74.902785477666896],
              [179.261411667000175, 74.90300599700015],
              [179.264179631000275, 74.903105380000284],
              [179.267594849000375, 74.903227562000268],
              [179.273778460000131, 74.903448611000215],
              [179.279962245000092, 74.903669496000077],
              [179.284137639000051, 74.903818524000144],
              [179.286146667000025, 74.903889997000306],
              [179.289348823000296, 74.904004434000285],
              [179.292330342000355, 74.90411076800018],
              [179.295909712000025, 74.904238324000232],
              [179.298514667000177, 74.904330997000216],
              [179.304699667000364, 74.904550997000285],
              [179.307248660000369, 74.904642091000198],
              [179.310883799000067, 74.904771433000235],
              [179.314806459000465, 74.904910896000274],
              [179.317068667000285, 74.904990997000084],
              [179.323616625000227, 74.905223907500215],
              [179.329438826000342, 74.905430608000131],
              [179.335463701000435, 74.905644298500079],
              [179.341809667000234, 74.905868997000084],
              [179.347995667000305, 74.90608799700027],
              [179.354181667000375, 74.906306997000286],
              [179.358846520000043, 74.906472145000066],
              [179.360367355000221, 74.906525919000273],
              [179.366553582000392, 74.906744485000047],
              [179.372739982000326, 74.906962884000279],
              [179.375823150000315, 74.907071644000212],
              [179.378926667000314, 74.907180997000296],
              [179.385113667000496, 74.9073989970002],
              [179.391300667000337, 74.907616997000275],
              [179.393784376000326, 74.907704511000077],
              [179.397487315000262, 74.90783482500018],
              [179.399246004000133, 74.907896668000262],
              [179.403674667000018, 74.9080519970002],
              [179.407750114000351, 74.908195596000269],
              [179.409862020000105, 74.908269801000131],
              [179.414953821000154, 74.908448568000267],
              [179.416049667000038, 74.908486997000182],
              [179.422237667000331, 74.908703997000146],
              [179.428425667000397, 74.908920997000166],
              [179.429262931000153, 74.908950358000197],
              [179.43461349800009, 74.909137764000093],
              [179.437094704000287, 74.909224601000233],
              [179.440801667000073, 74.909353997000153],
              [179.444475855000519, 74.9094828220002],
              [179.446990271000345, 74.909570751000274],
              [179.45317879050026, 74.909786996500202],
              [179.453885626000215, 74.909811670000067],
              [179.459367667000038, 74.910002997000163],
              [179.46478598200008, 74.910192100000302],
              [179.465556692000291, 74.910218992500063],
              [179.471745876000227, 74.910434735000194],
              [179.474498065000148, 74.910530597000161],
              [179.477935667000338, 74.910649997000064],
              [179.481390225000382, 74.910770563000256],
              [179.484124708000422, 74.910865733000151],
              [179.490314523500388, 74.91108099000013],
              [179.496504667000409, 74.911295997000252],
              [179.502694452500236, 74.911510990500233],
              [179.508884423000268, 74.911725737000268],
              [179.511598192000179, 74.911819809000235],
              [179.515074667000135, 74.911939997000047],
              [179.518620218000137, 74.912063126000191],
              [179.521265305000043, 74.912154744000134],
              [179.527455834500302, 74.912368998000261],
              [179.533646667000312, 74.912582997000129],
              [179.539837786500357, 74.91279700400014],
              [179.546029113000372, 74.913010767000173],
              [179.548284553000258, 74.913088576000291],
              [179.55222066700037, 74.913223997000216],
              [179.556053722000343, 74.913356492000275],
              [179.55841203600005, 74.913437784000223],
              [179.563614942000299, 74.913616986000306],
              [179.564603667000142, 74.913650997000218],
              [179.570795667000539, 74.913863997000078],
              [179.576987667000253, 74.914076997000109],
              [179.578641490000336, 74.914133887000219],
              [179.583179917000052, 74.914289825000139],
              [179.584992912000303, 74.914352068000198],
              [179.589372667000134, 74.914501997000286],
              [179.593949319000103, 74.914659430000256],
              [179.595564873000399, 74.914714850000223],
              [179.599056849000476, 74.914834542000165],
              [179.601757667000356, 74.914926997000293],
              [179.607950667000409, 74.915138997000213],
              [179.614143667000349, 74.915350997000189],
              [179.617958684000428, 74.915481593000152],
              [179.62033681100047, 74.91556290900013],
              [179.626530216000333, 74.915774509000244],
              [179.632723790000114, 74.915985942000248],
              [179.634045188000414, 74.916031015000158],
              [179.638917667000214, 74.916196997000213],
              [179.645111667000265, 74.916407997000249],
              [179.651305667000315, 74.916618997000114],
              [179.65763564550025, 74.916834628500169],
              [179.663694179000345, 74.91704062000025],
              [179.669030292000343, 74.917221895000239],
              [179.669888667000578, 74.917250997000167],
              [179.676083667000285, 74.917460997000148],
              [179.678431810000347, 74.917540974000303],
              [179.682278424000287, 74.917671435000159],
              [179.685940938000272, 74.917795551000097],
              [179.688473667000153, 74.917880997000282],
              [179.694668667000428, 74.918090997000206],
              [179.697124131000351, 74.918174220000253],
              [179.700864173000014, 74.918300755000189],
              [179.704174502000342, 74.918412661000218],
              [179.707059667000408, 74.918509997000285],
              [179.70875727900011, 74.918567534000147],
              [179.713255507000326, 74.918719471000202],
              [179.719451423000351, 74.91892858000017],
              [179.725647506000314, 74.919137523000131],
              [179.729422983000063, 74.919264735000183],
              [179.731843667000362, 74.919345997000164],
              [179.736876979000272, 74.919515750000073],
              [179.738040171000421, 74.919554911000034],
              [179.739052704000301, 74.919588971000167],
              [179.74423666700045, 74.919762997000191],
              [179.74732712000025, 74.919867226000179],
              [179.75043350000044, 74.919971634000149],
              [179.756630413000039, 74.920179746000144],
              [179.762827492000042, 74.920387692000304],
              [179.769024736000347, 74.920595472000059],
              [179.774178091000522, 74.920768110000154],
              [179.775222667000435, 74.920802997000294],
              [179.776380432000565, 74.920841857000312],
              [179.781419721000134, 74.921010534000118],
              [179.787617461000309, 74.921217816000194],
              [179.793815366000558, 74.921424931000217],
              [179.800013437000302, 74.921631880000177],
              [179.806211671000483, 74.921838663000187],
              [179.80946274500036, 74.921947034000311],
              [179.812410667000563, 74.922044997000171],
              [179.815713996000454, 74.922155321000275],
              [179.818608635000373, 74.922251731000244],
              [179.824511169000289, 74.922448144000214],
              [179.831006667000338, 74.922663997000257],
              [179.837205667000035, 74.922869997000248],
              [179.839998799000227, 74.922962816000165],
              [179.843404535000332, 74.923075871000037],
              [179.849603920000277, 74.923281490000079],
              [179.855803468000431, 74.923486944000217],
              [179.856782010000188, 74.923519345000159],
              [179.862003668000284, 74.923691997000219],
              [179.868202668000322, 74.92389699700027],
              [179.874403668000241, 74.924101997000207],
              [179.880603668000163, 74.924306997000087],
              [179.882339624000338, 74.924364396000158],
              [179.886803664000126, 74.924511716000268],
              [179.890500375000215, 74.924633611000161],
              [179.893004668000572, 74.924715997000192],
              [179.894613189000324, 74.924769182000261],
              [179.899204885000358, 74.924920461000227],
              [179.905405740000447, 74.925124584000173],
              [179.911606757000413, 74.925328541000283],
              [179.915235172000394, 74.925447782000276],
              [179.91780766800025, 74.925531997000235],
              [179.923505813000133, 74.925719424000192],
              [179.92421177000017, 74.925742598500165],
              [179.9302106680002, 74.925938997000117],
              [179.933877887000335, 74.926059621000149],
              [179.936412450000148, 74.926142705000302],
              [179.942614279000395, 74.926345830000116],
              [179.948816269000417, 74.926548789000151],
              [179.955018422000308, 74.926751581000246],
              [179.959125382000252, 74.926885753000136],
              [179.961220668000237, 74.926953997000282],
              [179.963687650000224, 74.927034732000095],
              [179.967423211000323, 74.92715666700019],
              [179.973625848000324, 74.927358960000277],
              [179.975599397000224, 74.92742327100018],
              [179.979828668000096, 74.927560997000057],
              [179.986031668000351, 74.927762997000229],
              [179.987599232000434, 74.927814045000218],
              [179.99223472600022, 74.927964842000165],
              [179.998438008000107, 74.928166470000122],
              [179.999999668000555, 74.928166688000147],
              [179.999999668000555, 73.999999996000213],
              [179.999999668000555, 72.99999999500011],
              [179.999999668000555, 71.999999995000167],
              [180.000000001638568, 71.535854520291423],
              [180.000000001638568, 70.997209068209486],
              [179.999999668000555, 69.999999993000188],
              [179.999999668000555, 68.999999992000141],
              [180.000000001638568, 68.980100110091769],
              [180.000000335276354, 67.980100170644562],
              [180.000000335276354, 66.980100226989407],
              [180.000000335276354, 65.980100115696416],
              [180.000000335276354, 65.398609101674765],
              [180.00000000164232, 65.068909054758706],
              [179.999999668000555, 64.970454988000085],
              [179.999999668000555, 63.97045498700021],
              [179.999999668000555, 62.970454986000277],
              [179.999999668000555, 61.97045498500006],
              [179.999999668000555, 60.970454984000185],
              [179.999999668000555, 60.56131598400026],
              [179.991206282000576, 60.556222235000234],
              [179.990283387000318, 60.085964006000268],
              [180.000000000000455, 60.086679828000172],
              [174.007658905000312, 56.281543232000217],
              [173.983788590000131, 56.266385582000225],
              [173.94888305700033, 56.251945496000189],
              [173.68556213400052, 56.076110840000297],
              [173.674962569000286, 56.068996200000129],
              [173.597452783000222, 56.016970086000299],
              [173.472055972000248, 55.932801246000167],
              [173.422775270000216, 55.899723055000209],
              [173.160278321000419, 55.722778323000284],
              [173.003727429000492, 55.616417926000281],
              [172.898605348000501, 55.544998171000145],
              [172.637222291000057, 55.36639023100031],
              [172.376663209000299, 55.187221530000272],
              [172.17824742200014, 55.049998581000295],
              [172.116394044000231, 55.007221224000148],
              [171.856674195000437, 54.826667788000236],
              [171.597503663000566, 54.64527893300027],
              [171.422431553000138, 54.522111794000239],
              [171.338882446999946, 54.463333132000173],
              [171.306116896000276, 54.440150489000246],
              [171.080551148000154, 54.280555728000309],
              [170.905967248000252, 54.156384440000238],
              [170.822784425000236, 54.097221377000267],
              [170.5655517590003, 53.913055422000298],
              [170.466893784000149, 53.842127608000283],
              [170.308609010000282, 53.728332521000141],
              [170.272175462000178, 53.697769904000268],
              [170.09138488900021, 53.546112062000248],
              [170.031517454000209, 53.495414144000165],
              [169.87554931700015, 53.36333465700028],
              [169.720312851000131, 53.230812557000093],
              [169.661117555000146, 53.180278780000208],
              [169.44805908300043, 52.9966659560003],
              [169.284188144000154, 52.854118703000267],
              [169.236663819000228, 52.812778474000197],
              [169.179254667000237, 52.762429706000262],
              [169.02667236300033, 52.628612518000182],
              [168.818054199000244, 52.443889618000298],
              [168.610839844000225, 52.258609772000284],
              [168.404724121000299, 52.073055267000257],
              [168.200271606000342, 51.887222290000238],
              [167.996948242000258, 51.700832367000146],
              [167.814870244000304, 51.532535754500032],
              [167.794998169000223, 51.514167786000257],
              [167.64111328100023, 51.370834351000212],
              [167.584164554000154, 51.208904981000273],
              [166.977362237500074, 51.245310980500108],
              [166.974119824500235, 51.245851225000308],
              [166.958723655000199, 51.246746975000349],
              [166.789560817000279, 51.274538602000234],
              [166.060569655000222, 51.394303975000184],
              [166.058748655000159, 51.394818975000135],
              [165.749903654000263, 51.409061975000157],
              [164.936956681000197, 51.532866157666717],
              [164.935951125000315, 51.532048406000172],
              [164.931781654000361, 51.528619975000254],
              [164.930661654000232, 51.527972975000239],
              [164.926497052000286, 51.524546168000086],
              [164.925424615500333, 51.52368976300022],
              [164.924404654000341, 51.523100975000148],
              [164.92024411000034, 51.519677415000217],
              [164.919172144500294, 51.518821386500235],
              [164.91814765400045, 51.518228975000284],
              [164.913027654000302, 51.514014975000123],
              [164.911996822000219, 51.513418941000225],
              [164.910926645000131, 51.512564042000065],
              [164.906776654000168, 51.509146975000249],
              [164.905632654000215, 51.508485975000269],
              [164.900524654000151, 51.504278975000148],
              [164.899375654000323, 51.503613975000178],
              [164.894273654000244, 51.499410975000274],
              [164.893118654000432, 51.498741975000144],
              [164.888021654000227, 51.494542975000172],
              [164.886970894000115, 51.493933353500211],
              [164.881660754500217, 51.489610409000079],
              [164.880603654000197, 51.4889989750003],
              [164.876477200000181, 51.48559570600014],
              [164.875409189500374, 51.484742032000213],
              [164.874346654000306, 51.484126975000208],
              [164.870224258000235, 51.480726953000101],
              [164.869267654000168, 51.479937975000212],
              [164.868089654000187, 51.479254975000174],
              [164.867134179000459, 51.478466751000155],
              [164.863016654000148, 51.47506997500011],
              [164.861832654000295, 51.474383975000194],
              [164.856765654000128, 51.470201975000236],
              [164.855575654000404, 51.46951197500016],
              [164.850514654000335, 51.465333975000135],
              [164.849431284000048, 51.464705331000175],
              [164.848366380000243, 51.463853822000146],
              [164.844262654000318, 51.460465975000261],
              [164.843173849000209, 51.459833707500025],
              [164.842109700000378, 51.458982577000256],
              [164.838011654000411, 51.45559797500016],
              [164.836803654000278, 51.454896975000054],
              [164.831760654000277, 51.450729975000229],
              [164.83066097950018, 51.450091367000198],
              [164.829597771000294, 51.449240895000173],
              [164.825508654000259, 51.445861975000184],
              [164.824289654000154, 51.44515397500021],
              [164.81925765300025, 51.440993975000083],
              [164.818032653000159, 51.440281975000175],
              [164.81300665300023, 51.436125975000209],
              [164.811891579500298, 51.435477402500112],
              [164.810830162000457, 51.43462796700021],
              [164.806755653000209, 51.431257975000108],
              [164.805518653000149, 51.430538975000331],
              [164.800503653000192, 51.426389975000234],
              [164.799261653000258, 51.42566697500007],
              [164.794252653000171, 51.421521975000303],
              [164.793121275000203, 51.420863438500135],
              [164.792061551000302, 51.420015038000258],
              [164.788001653000151, 51.416653975000202],
              [164.786746653000137, 51.415923975000226],
              [164.781750653000131, 51.411785975000157],
              [164.78060840500018, 51.411121098000137],
              [164.779549622000445, 51.410273356000289],
              [164.775498653000341, 51.406917975000056],
              [164.77423265300024, 51.406180975000211],
              [164.769247653000434, 51.402049975000182],
              [164.767975653000349, 51.401308975000291],
              [164.7629966530003, 51.39718197500008],
              [164.761839005500292, 51.396507133500222],
              [164.760782014000142, 51.395660429000145],
              [164.756745653000394, 51.392313975000206],
              [164.755461653000339, 51.391565975000105],
              [164.750493653000376, 51.387445975000276],
              [164.74932604150024, 51.386764793000054],
              [164.748270085000399, 51.385918747000233],
              [164.744242653000242, 51.382577975000174],
              [164.742947653000215, 51.381822975000262],
              [164.737991653000336, 51.3777099750003],
              [164.73681317150033, 51.377022452000176],
              [164.735758346000353, 51.376177066000196],
              [164.731740653000202, 51.372841975000028],
              [164.730433653000432, 51.372079975000247],
              [164.725489653000182, 51.367973975000154],
              [164.724300302000188, 51.367280111500122],
              [164.723246417000269, 51.366435384000283],
              [164.719237653000164, 51.363105975000053],
              [164.717919653000195, 51.362336975000176],
              [164.713915759000201, 51.359010001000286],
              [164.712986653000144, 51.358237975000179],
              [164.711662653000189, 51.357464975000141],
              [164.706610404000344, 51.353296897500115],
              [164.705405653000298, 51.352593975000161],
              [164.701411499000187, 51.349272683000152],
              [164.700484653000444, 51.348501975000147],
              [164.699148653000407, 51.347721975000297],
              [164.694107273500208, 51.343560238000123],
              [164.692891653000288, 51.342850975000147],
              [164.687982653000404, 51.338766975000283],
              [164.686761598000317, 51.33805318300017],
              [164.685711009000215, 51.337210527000252],
              [164.681730653000386, 51.333898975000182],
              [164.680377653000164, 51.333107975000303],
              [164.675479653000252, 51.329030975000308],
              [164.674248633500383, 51.328310842500173],
              [164.673199270000168, 51.327468846000272],
              [164.669228653000346, 51.324162975000206],
              [164.667864653000265, 51.323364975000288],
              [164.663898096000253, 51.32006154000004],
              [164.662977653000212, 51.319294975000332],
              [164.661607653000374, 51.318492975000026],
              [164.656597071500187, 51.314351166000279],
              [164.655350653000141, 51.313621975000046],
              [164.650475653000171, 51.309559975000241],
              [164.649093653000136, 51.308750975000237],
              [164.644224653000151, 51.304691975000139],
              [164.642836653000131, 51.303878975000202],
              [164.637973653000131, 51.299823975000265],
              [164.63657965300024, 51.299007975000052],
              [164.632637445000228, 51.295719149000263],
              [164.631722653000338, 51.294955975000164],
              [164.630322653000349, 51.294135975000188],
              [164.625338341000202, 51.290010471000244],
              [164.624065653000457, 51.289264975000208],
              [164.619219653000414, 51.285220975000243],
              [164.61794243650013, 51.284470950000127],
              [164.616897875000177, 51.283632061000276],
              [164.61296865300028, 51.280352975000199],
              [164.611552653000217, 51.279521975000193],
              [164.606717653000374, 51.275484975000097],
              [164.605295653000326, 51.274650975000213],
              [164.6013761720003, 51.271376760000294],
              [164.60046665300024, 51.270616975000223],
              [164.599038653000207, 51.269778975000349],
              [164.59813026500035, 51.269020133000254],
              [164.594215653000333, 51.265749975000233],
              [164.592781653000316, 51.264907975000199],
              [164.587964653000199, 51.260881975000302],
              [164.586524653000424, 51.260036975000219],
              [164.581713653000293, 51.256013975000201],
              [164.580404732500256, 51.255244927500257],
              [164.57532600900015, 51.251065740000172],
              [164.574011653000184, 51.250293975000147],
              [164.569211653000139, 51.246278975000166],
              [164.567754653000293, 51.245422975000224],
              [164.562960653000459, 51.241410975000065],
              [164.561635427500278, 51.240631963500164],
              [164.556571407500314, 51.236461704000078],
              [164.555241653000394, 51.235679975000153],
              [164.551359509000349, 51.232429298000056],
              [164.550319842500244, 51.231593421500122],
              [164.548984653000161, 51.230808975000173],
              [164.544207653000285, 51.22680797500027],
              [164.542867028500154, 51.22601899950007],
              [164.537816713000325, 51.221857668500263],
              [164.536470653000151, 51.221065975000158],
              [164.532603117000235, 51.217824225000186],
              [164.531565242000283, 51.216989386000307],
              [164.530214653000144, 51.216194975000178],
              [164.525454653000338, 51.212204975000304],
              [164.524098629500259, 51.211406035500147],
              [164.519203653000204, 51.207336975000203],
              [164.517700653000361, 51.206451975000164],
              [164.516806391000273, 51.205701985000303],
              [164.512952653000298, 51.202469975000213],
              [164.511443653000129, 51.201580975000184],
              [164.506701653000164, 51.197601975000282],
              [164.505187653000235, 51.196709975000033],
              [164.501342844000419, 51.193482835000168],
              [164.500450653000144, 51.192733975000237],
              [164.49893065300023, 51.191837975000169],
              [164.498039592000282, 51.191090057000281],
              [164.494199653000464, 51.187866975000247],
              [164.492673653000338, 51.186966975000189],
              [164.487948653000331, 51.182998975000146],
              [164.486417653000331, 51.182095975000038],
              [164.482586642000342, 51.17887776300023],
              [164.481552910000232, 51.17804565500029],
              [164.480160653000439, 51.177224975000058],
              [164.47544665300029, 51.173263975000225],
              [164.474048961500102, 51.172438766000084],
              [164.469195653000384, 51.16839597500018],
              [164.467647653000199, 51.167481975000214],
              [164.466761865000194, 51.166737446000184],
              [164.46294465300025, 51.16352897500019],
              [164.461390653000308, 51.162610975000064],
              [164.456693653000343, 51.158660975000089],
              [164.455133653000189, 51.157739975000084],
              [164.450442653000209, 51.153793975000269],
              [164.448877653000409, 51.152868975000274],
              [164.444191653000303, 51.148925975000168],
              [164.442768598000157, 51.148084461500218],
              [164.437941653000166, 51.144057975000294],
              [164.436364653000169, 51.143125975000089],
              [164.435484329000133, 51.142384838000169],
              [164.431690653000146, 51.139190975000304],
              [164.430107653000277, 51.138254975000109],
              [164.426318848000392, 51.135063548000289],
              [164.425290013000222, 51.134234547],
              [164.423850653000159, 51.133383975000299],
              [164.419188653000447, 51.129455975000212],
              [164.417594653000378, 51.128512975000149],
              [164.413814776000322, 51.125327230000096],
              [164.412786977000223, 51.124498888000119],
              [164.41133765300026, 51.123641975000226],
              [164.406686653000406, 51.119720975000291],
              [164.405232800000022, 51.118859533000091],
              [164.400435653000272, 51.114852975000247],
              [164.398824653000133, 51.113898975000154],
              [164.39795073200014, 51.113161981000303],
              [164.394184653000366, 51.109985975000257],
              [164.392720836000308, 51.109118192500148],
              [164.387780905000341, 51.105027475000156],
              [164.386311653000348, 51.104156975000251],
              [164.381683653000209, 51.100250975000165],
              [164.38005565300034, 51.099285975000271],
              [164.37630337200028, 51.096118086000217],
              [164.375278775000083, 51.09529181600027],
              [164.373798653000449, 51.094414975000291],
              [164.369181653000169, 51.090515975000073],
              [164.367542653000442, 51.08954397500014],
              [164.363799302000416, 51.086381768000251],
              [164.362775739000199, 51.085556156500161],
              [164.361285653000209, 51.08467297500016],
              [164.356679653000128, 51.080780975000209],
              [164.355029653000202, 51.07980197500018],
              [164.350428653000336, 51.075913975000162],
              [164.348928603000218, 51.075022641000146],
              [164.344178653000313, 51.071045975000288],
              [164.342516653000303, 51.070058975000165],
              [164.341652338000131, 51.069328197000289],
              [164.337927653000406, 51.066178975000298],
              [164.336416733000192, 51.065281300000152],
              [164.331676653000272, 51.061310975000026],
              [164.330003653000404, 51.060316975000205],
              [164.329141410000375, 51.05958751500026],
              [164.325425653000138, 51.056443975000207],
              [164.323904768500142, 51.055539959000043],
              [164.322885540000243, 51.054717268000275],
              [164.319174653000232, 51.051576975000216],
              [164.317649239500156, 51.050669336000169],
              [164.312924653000209, 51.046708975000286],
              [164.311233653000272, 51.045703975000265],
              [164.310374799000442, 51.044976586000246],
              [164.306673653000189, 51.041841975000125],
              [164.30513736950013, 51.040927995000175],
              [164.300422653000169, 51.036973975000251],
              [164.298720653000373, 51.035961975000191],
              [164.297863870000242, 51.035235904000217],
              [164.294171653000149, 51.032106975000261],
              [164.292625405500189, 51.031186654500232],
              [164.2916090020002, 51.030365658000335],
              [164.287921653000353, 51.027239975000214],
              [164.286369970500118, 51.026316031000192],
              [164.281670653000447, 51.022371975000169],
              [164.279951653000126, 51.021348975000251],
              [164.279098073000227, 51.020624976000306],
              [164.275419653000313, 51.017504975000179],
              [164.273858006500404, 51.016574690000198],
              [164.272843015000262, 51.015754730000026],
              [164.269168653000179, 51.012637975000189],
              [164.267602477500304, 51.011704067000153],
              [164.262918653000156, 51.007769975000258],
              [164.261182653000219, 51.00673597500014],
              [164.260332275000223, 51.0060140490001],
              [164.25666765300025, 51.002902975000268],
              [164.255090607500506, 51.001962726000158],
              [164.254077216000155, 51.001143801000183],
              [164.250416653000229, 50.998035975000278],
              [164.24883417250021, 50.997092102500119],
              [164.244165653000209, 50.993167975000176],
              [164.242413653000199, 50.992122975000257],
              [164.237915653000186, 50.988300975000186],
              [164.236157653000191, 50.987251975000277],
              [164.235311419000141, 50.986532874000204],
              [164.231664653000394, 50.983433975000196],
              [164.230066773500198, 50.98248013850025],
              [164.229055548000247, 50.981662627000162],
              [164.22541365300026, 50.978566975000206],
              [164.223644653000292, 50.97751097500003],
              [164.220007628000189, 50.974416947000179],
              [164.218996496500381, 50.973599246000106],
              [164.217388653000285, 50.97263997500022],
              [164.21291265300033, 50.968831975000114],
              [164.211132653000277, 50.967768975000297],
              [164.206661653000197, 50.963964975000295],
              [164.204875653000158, 50.962897975000146],
              [164.200411653000174, 50.959097975000304],
              [164.198619653000264, 50.958026975000166],
              [164.195000483000285, 50.954945121000264],
              [164.193991425000036, 50.954128833500079],
              [164.192363653000257, 50.953155975000186],
              [164.188748542000326, 50.950077368000279],
              [164.187739860000192, 50.949261456999977],
              [164.186106653000365, 50.948284975000206],
              [164.181659653000452, 50.944495975000223],
              [164.179648653000186, 50.94329297500019],
              [164.175389653000366, 50.939663975000258],
              [163.637989654000307, 50.613655453000206],
              [163.51328565200015, 50.538004975000206],
              [163.509441652000305, 50.536353975000168],
              [163.506400652000252, 50.534482975000287],
              [163.502551652000193, 50.532828975000029],
              [163.499515652000241, 50.530960975000198],
              [163.495660652000311, 50.529303975000232],
              [163.49263065200023, 50.52743897500028],
              [163.491903452000315, 50.527126510000301],
              [163.488769652000201, 50.525779975000148],
              [163.485745652000446, 50.523917975000302],
              [163.481879652000316, 50.52225497500018],
              [163.478860652000208, 50.520395975000213],
              [163.47571792500014, 50.519043758000123],
              [163.474704816000099, 50.518555190500081],
              [163.471974652000199, 50.516873975000294],
              [163.468827866000424, 50.515519323000092],
              [163.467814381000267, 50.515030473000138],
              [163.465089652000188, 50.513351974000273],
              [163.461206652000442, 50.511680974000228],
              [163.458487358000298, 50.51000428750018],
              [163.457472179000206, 50.509514685000113],
              [163.454315652000332, 50.50815597400026],
              [163.451600887500149, 50.50648200500018],
              [163.447142982000287, 50.504457320000085],
              [163.444433652000157, 50.502786974000287],
              [163.440533652000227, 50.501106974000152],
              [163.437547652000262, 50.499264974000198],
              [163.433642652000458, 50.497581974000184],
              [163.430943286500224, 50.495916157500176],
              [163.426751652000348, 50.494056974000216],
              [163.423776652000129, 50.49222097400019],
              [163.42303909300017, 50.491903048000154],
              [163.419860652000125, 50.490532974000132],
              [163.416890652000234, 50.488699974000156],
              [163.41370734100019, 50.487326654000242],
              [163.412689619000275, 50.486835638500168],
              [163.410005652000336, 50.485177974000237],
              [163.406077652000135, 50.483483974000251],
              [163.403398214500385, 50.481828121500143],
              [163.398907654500249, 50.479787203500223],
              [163.396233652000319, 50.47813497400017],
              [163.392294652000146, 50.476434974000199],
              [163.389625178500182, 50.474784556500083],
              [163.385125691000212, 50.472738768500108],
              [163.382461652000188, 50.471091974000274],
              [163.378511652000157, 50.469385974000147],
              [163.375852142500321, 50.467740991500079],
              [163.371343726500299, 50.46569033350022],
              [163.368689652000171, 50.46404897400015],
              [163.36472765200017, 50.462336974000266],
              [163.362078106500121, 50.46069742650019],
              [163.35756166800013, 50.458641898500275],
              [163.354916652000384, 50.457005974000253],
              [163.35094465200018, 50.455287974000214],
              [163.348305071000141, 50.453653861500129],
              [163.347281415000225, 50.453160021000258],
              [163.3440526520003, 50.451763974000301],
              [163.341143652000142, 50.44996297400013],
              [163.337910831000272, 50.448563682000156],
              [163.337160652000193, 50.448238974000162],
              [163.334257652000247, 50.446440974000041],
              [163.33350634400017, 50.446115891000261],
              [163.330268652000427, 50.444714974000249],
              [163.327370652000241, 50.442919974000233],
              [163.323376652000206, 50.441190974000165],
              [163.320484652000346, 50.439398974000028],
              [163.317238032000319, 50.437992376000182],
              [163.316484652000327, 50.437665974000197],
              [163.313597652000226, 50.43587697400028],
              [163.309320340500335, 50.433973782000237],
              [163.30671065200022, 50.432355974000302],
              [163.302699652000342, 50.430617974000199],
              [163.299824652000325, 50.428834974000267],
              [163.295807652000349, 50.427093974000115],
              [163.293207927000253, 50.425480695500198],
              [163.29217994000021, 50.424984689000155],
              [163.28891465200013, 50.423569974000202],
              [163.286050652000199, 50.4217919740002],
              [163.282781306000174, 50.420374013000185],
              [163.28175341300016, 50.419877912000118],
              [163.27916365200042, 50.418270974000166],
              [163.275890248000451, 50.416850578000265],
              [163.274861883500193, 50.416354194500116],
              [163.272276652000187, 50.414749974000188],
              [163.26823765200038, 50.412996974000293],
              [163.265657855500194, 50.411394565500132],
              [163.264627796000127, 50.410897429000215],
              [163.261344652000275, 50.409472974000209],
              [163.258770290500252, 50.407873283000242],
              [163.25773985400042, 50.407375863000141],
              [163.254452652000396, 50.405948974000296],
              [163.251615652000169, 50.404185974000256],
              [163.247559652000291, 50.402424974000212],
              [163.244728652000163, 50.400664974000279],
              [163.240666652000186, 50.398900974000128],
              [163.237840652000273, 50.397143974000244],
              [163.233773652000195, 50.395376974000214],
              [163.230953652000153, 50.393622974000266],
              [163.226880652000204, 50.391852974000187],
              [163.224065652000263, 50.390101974000231],
              [163.219988652000438, 50.388328974000274],
              [163.217178652000257, 50.386580974000253],
              [163.21309565200022, 50.38480497400019],
              [163.21029065200014, 50.383059974000219],
              [163.206201652000459, 50.381280974000106],
              [163.203667147000033, 50.379702117000136],
              [163.202632379000192, 50.379202532000079],
              [163.199308652000354, 50.377756974000192],
              [163.196515652000357, 50.376017974000206],
              [163.192415652000136, 50.374232974000336],
              [163.189627652000127, 50.372496974000228],
              [163.185522652000259, 50.370708974000252],
              [163.182740652000234, 50.368975974000193],
              [163.179403939000167, 50.367522301000292],
              [163.178368134500033, 50.367022055500115],
              [163.175852652000231, 50.365454974000215],
              [163.172511068000176, 50.363998865000212],
              [163.171474700000203, 50.363498338500165],
              [163.168964652000341, 50.361933974000181],
              [163.164842652000175, 50.360137974000281],
              [163.162076652000223, 50.358412974000203],
              [163.157948652000186, 50.356613974000197],
              [163.15518865200022, 50.354891974000168],
              [163.151055652000423, 50.353089974000284],
              [163.148560097500422, 50.351532856500114],
              [163.144161652000207, 50.349565974000029],
              [163.141412652000326, 50.347849974000155],
              [163.137009336500341, 50.345880750500157],
              [163.134524652000437, 50.344329974000232],
              [163.130374652000228, 50.342518974000143],
              [163.127635652000208, 50.340808974000254],
              [163.123480652000239, 50.338994974000059],
              [163.120747652000205, 50.337287974000219],
              [163.117370356000379, 50.335813197000164],
              [163.116586652000365, 50.335470974000145],
              [163.113859652000315, 50.333766974000241],
              [163.113074818000428, 50.33342437500022],
              [163.109692652000149, 50.331947974000173],
              [163.1069706520002, 50.330246974000318],
              [163.10279865200016, 50.328423974000088],
              [163.10008265200031, 50.326725974000283],
              [163.096691557000327, 50.325243892000231],
              [163.095904652000286, 50.324899974000175],
              [163.093193652000309, 50.323204974000305],
              [163.092405805000453, 50.32286068000019],
              [163.089010652000297, 50.321376974000202],
              [163.086304652000194, 50.319684974000154],
              [163.082905441000207, 50.31819802300015],
              [163.081862386000353, 50.317693917000042],
              [163.079416652000305, 50.316163974000176],
              [163.075222652000207, 50.314329974000145],
              [163.072781447000125, 50.312801843500097],
              [163.068075328000191, 50.310647482000263],
              [163.065638652000189, 50.309122974000218],
              [163.06143365200046, 50.307282974000259],
              [163.059002411000279, 50.305760278000207],
              [163.054287363500293, 50.303601047000143],
              [163.0518606520003, 50.302081974000146],
              [163.047644652000145, 50.300235974000145],
              [163.045223375000205, 50.298718713000142],
              [163.040499399500163, 50.296554612000193],
              [163.038082652000185, 50.295040974000187],
              [163.03385565200017, 50.293188974000088],
              [163.031444339000245, 50.291677148000247],
              [163.026711435500033, 50.289508177000073],
              [163.02430465200041, 50.287999974000286],
              [163.020066652000423, 50.286141974000202],
              [163.01766530350028, 50.284635583500062],
              [163.016616314000203, 50.284128652000049],
              [163.013171652000324, 50.282618974000229],
              [163.010526652000294, 50.280958974000157],
              [163.007077930000264, 50.279446050000217],
              [163.006028942000285, 50.278939024500062],
              [163.003636652000182, 50.277438974000063],
              [162.999382652000349, 50.275571974000286],
              [162.996995796500187, 50.27407373550011],
              [162.995945301000233, 50.273565957000358],
              [162.99248765200025, 50.272048974000143],
              [162.989858652000294, 50.270397974000161],
              [162.986396132000323, 50.268877744000292],
              [162.985345543000221, 50.268369872],
              [162.982968652000295, 50.266877974000238],
              [162.979502074000152, 50.265355310000189],
              [162.978697652000392, 50.265001974000256],
              [162.97607865200041, 50.263356974000203],
              [162.97527328800038, 50.263003262000268],
              [162.971802652000292, 50.261478974000227],
              [162.969189652000182, 50.259836974000279],
              [162.964907652000193, 50.257955974000254],
              [162.962299652000411, 50.256316974000185],
              [162.958012652000434, 50.254432974000281],
              [162.955409652000185, 50.252796974000262],
              [162.95192521700028, 50.251264570000274],
              [162.951116652000223, 50.250908974000197],
              [162.948520652000184, 50.249275974000227],
              [162.944221652000238, 50.247385974000167],
              [162.941630652000299, 50.245755974000303],
              [162.940820015000327, 50.245399437000231],
              [162.937326652000365, 50.243862974000194],
              [162.934740652000301, 50.242235974000039],
              [162.930430652000382, 50.240339974000221],
              [162.927850652000416, 50.238715974000115],
              [162.923535652000282, 50.236816974000192],
              [162.92096065200019, 50.235195974000192],
              [162.916639652000413, 50.233293974000219],
              [162.914070652000305, 50.231675974000098],
              [162.909744652000313, 50.229770974000246],
              [162.907179652000195, 50.228155974000174],
              [162.90284865200033, 50.226247974000046],
              [162.900530639500062, 50.224786874500182],
              [162.899472799000137, 50.224275235000164],
              [162.89595265200046, 50.222724974000243],
              [162.893640074000245, 50.221266592000177],
              [162.892581858000199, 50.220754670000304],
              [162.889057652000361, 50.219201974000214],
              [162.886749603500107, 50.217746309500171],
              [162.885690727000224, 50.217234105000216],
              [162.88216165200015, 50.215678974000241],
              [162.879858132500203, 50.214226027000223],
              [162.878798786000289, 50.213713540000185],
              [162.875265652000166, 50.212155974000268],
              [162.872967567500154, 50.210705744500217],
              [162.871907655000427, 50.210192975000098],
              [162.868369652000297, 50.208632974000068],
              [162.866076096500251, 50.207185462000211],
              [162.865015714000265, 50.206672410000238],
              [162.861473652000313, 50.205109974000266],
              [162.858947652000211, 50.203515974000197],
              [162.85457765200033, 50.201586974000293],
              [162.852056652000442, 50.199995974000274],
              [162.84768165200046, 50.198063974000092],
              [162.845165652000333, 50.19647597400018],
              [162.841610603000163, 50.194905422000261],
              [162.840549184500219, 50.194391710500213],
              [162.838274652000337, 50.192955974000256],
              [162.834715544000233, 50.191382987000225],
              [162.833653749500172, 50.190868993000151],
              [162.831384652000224, 50.189435974000332],
              [162.826992652000172, 50.187495974000171],
              [162.824728989500386, 50.186064767000175],
              [162.82366549700032, 50.185550019000232],
              [162.820096652000302, 50.183972974000199],
              [162.81783751800026, 50.182544484000118],
              [162.816773557000261, 50.182029455000247],
              [162.813200652000319, 50.180449974000226],
              [162.810711652000464, 50.178876974000275],
              [162.807133878000172, 50.177294248000237],
              [162.806069821000278, 50.176779123000131],
              [162.803820652000127, 50.175356974000181],
              [162.799407652000241, 50.173404974000277],
              [162.796929652000358, 50.171836974000257],
              [162.792510652000146, 50.169881974000305],
              [162.790270540500273, 50.168464354000093],
              [162.785381328000312, 50.166211971000166],
              [162.783146652000369, 50.164797974000294],
              [162.778717652000182, 50.162836974000243],
              [162.776255652000259, 50.1612779740002],
              [162.771820652000201, 50.159313974000042],
              [162.769595940000158, 50.157904506500188],
              [162.764692835000346, 50.155644818500093],
              [162.76247265200027, 50.154238974000066],
              [162.75802665200024, 50.152268974000151],
              [162.755581652000274, 50.150718974000142],
              [162.751129652000373, 50.148745974000178],
              [162.74868965200028, 50.147199974000159],
              [162.744232652000278, 50.14522397400026],
              [162.741798652000284, 50.143679974000236],
              [162.737335652000183, 50.141700974000059],
              [162.735135397000249, 50.140305094000212],
              [162.730210377500157, 50.138033231000065],
              [162.728014652000411, 50.136640974000272],
              [162.723541652000335, 50.134655974000168],
              [162.721350455000106, 50.133265529000141],
              [162.716417413500153, 50.130988796000167],
              [162.714231652000421, 50.129601974000138],
              [162.709747652000146, 50.127610974000277],
              [162.707566419000159, 50.126225964000071],
              [162.706493982000211, 50.12570660300014],
              [162.702849652000282, 50.124088974000188],
              [162.700447652000321, 50.122562974000232],
              [162.696799264000219, 50.120942100000207],
              [162.695726920000311, 50.120422643500149],
              [162.693555652000327, 50.119043974000192],
              [162.68905565200032, 50.117043974000239],
              [162.686888912500308, 50.115667116500049],
              [162.681932956000196, 50.113378208500251],
              [162.679771652000227, 50.112004974000058],
              [162.675260652000134, 50.109998974000177],
              [162.673102970500281, 50.108627551000154],
              [162.672028086000296, 50.108106777000216],
              [162.66836265100028, 50.106476974000088],
              [162.665986651000367, 50.104965974000152],
              [162.662316348000331, 50.103332925000302],
              [162.661241462000362, 50.102812056000232],
              [162.659094651000146, 50.101446974000282],
              [162.654567651000207, 50.099431974000197],
              [162.652424463500324, 50.098068703500132],
              [162.65134807200036, 50.097547082000176],
              [162.647669651000228, 50.095909974000278],
              [162.645531898500167, 50.094549421500119],
              [162.644454941000163, 50.094027517000029],
              [162.640771651000136, 50.092387974000189],
              [162.638417651000282, 50.090888974000165],
              [162.634729491000144, 50.089246184000103],
              [162.633652440000219, 50.088724186000093],
              [162.631524651000177, 50.087369974000126],
              [162.629630651000298, 50.086525974000097],
              [162.411283651000304, 49.887013974000297],
              [162.409537215500222, 49.885889841999983],
              [162.408569311000178, 49.885050671000272],
              [162.405180651000364, 49.881937974000209],
              [162.403429686500203, 49.880810219000068],
              [162.3988961445001, 49.876743541000053],
              [162.3971406510002, 49.875613974000089],
              [162.393760920000432, 49.872506147000081],
              [162.392793579500221, 49.871667164000201],
              [162.39103265100016, 49.870533974000239],
              [162.387656978000223, 49.867429394000226],
              [162.386690203000171, 49.866590787500058],
              [162.384925651000458, 49.865453974000218],
              [162.381554036000239, 49.862352640000097],
              [162.380771651000259, 49.861632974000258],
              [162.37881765100019, 49.860373974000197],
              [162.378036396000198, 49.859655060000193],
              [162.374669651000431, 49.856556974000171],
              [162.372895135000135, 49.855412914000226],
              [162.371930338000311, 49.854575814000043],
              [162.368567651000376, 49.85148097400014],
              [162.366602651000335, 49.85021497400021],
              [162.362464651000437, 49.846404974000222],
              [162.360495651000406, 49.845134974000302],
              [162.356362651000381, 49.841328974000191],
              [162.354387651000252, 49.840054974000111],
              [162.350260651000326, 49.836252974000331],
              [162.348467112500202, 49.835095326500152],
              [162.347504293000384, 49.834259640000198],
              [162.344158651000271, 49.831176974000243],
              [162.342359678000207, 49.830015703000186],
              [162.341397423000217, 49.829180392000069],
              [162.338056651000443, 49.826100974000212],
              [162.336065651000126, 49.824815974000273],
              [162.33195465100016, 49.821024974000125],
              [162.329958651000197, 49.819735974000253],
              [162.325851651000448, 49.815948974000264],
              [162.323850651000271, 49.814655974000175],
              [162.319749651000166, 49.810872974000176],
              [162.317932561500356, 49.809698116000163],
              [162.316972189000126, 49.808864217000178],
              [162.313647651000338, 49.805796974000145],
              [162.311826032000226, 49.804618492500197],
              [162.310866132000342, 49.803784972000301],
              [162.307545651000169, 49.800720974000058],
              [162.305528651000259, 49.799416974000167],
              [162.302213041000186, 49.796354600000257],
              [162.301253234000228, 49.795520890500256],
              [162.29942165100033, 49.794336974000146],
              [162.29611009900043, 49.79127784700006],
              [162.295341651000172, 49.790567974000226],
              [162.293314651000401, 49.789256974000295],
              [162.28923965100023, 49.785491974000365],
              [162.287207651000131, 49.784176974000275],
              [162.282945821500107, 49.780291854500206],
              [162.281100651000202, 49.779097974000308],
              [162.277801274000126, 49.776047774000176],
              [162.276843256500342, 49.775215478000234],
              [162.274992651000389, 49.774017974000287],
              [162.2716981450003, 49.770971022000197],
              [162.270933651000291, 49.770263974000159],
              [162.26888565100046, 49.768937974000039],
              [162.268122099000152, 49.768231867000168],
              [162.264831651000236, 49.765188974000182],
              [162.26277865100019, 49.7638589740003],
              [162.258729651000181, 49.760112974000151],
              [162.256671651000261, 49.758778974000052],
              [162.252627651000125, 49.755036974000063],
              [162.250758929000199, 49.753824977000249],
              [162.249803925000265, 49.752994940000235],
              [162.246525651000297, 49.749960974000203],
              [162.244652400000405, 49.748745353500112],
              [162.243698056000198, 49.747915694000255],
              [162.240424651000126, 49.744884974000115],
              [162.238510651000411, 49.743643974000292],
              [162.235126862000129, 49.740509363000172],
              [162.234163382500128, 49.739666330000148],
              [162.232448651000141, 49.738553974000297],
              [162.228262651000136, 49.734675974000083],
              [162.22636365100027, 49.733443974000295],
              [162.22218365100025, 49.729569974000185],
              [162.220279651000283, 49.728333974000236],
              [162.216890992000458, 49.725192057000243],
              [162.215924875500036, 49.724346294000213],
              [162.214195651000296, 49.723223974000234],
              [162.210025651000251, 49.71935697400022],
              [162.208110651000425, 49.718113974000175],
              [162.203946651000138, 49.714250974000151],
              [162.202207462000388, 49.713120501000105],
              [162.197867651000251, 49.709143974000199],
              [162.195942651000223, 49.707892974000231],
              [162.19516026600013, 49.707166904000189],
              [162.191788651000138, 49.7040379740003],
              [162.190040404000115, 49.702901160500062],
              [162.189077207000309, 49.702057657000296],
              [162.185709651000252, 49.698931974000061],
              [162.183956875000263, 49.697791537000114],
              [162.179630651000139, 49.693824974000108],
              [162.177689651000264, 49.692562974000168],
              [162.176910279000253, 49.691838975000167],
              [162.173551651000139, 49.68871897400021],
              [162.171788911000135, 49.687572196500071],
              [162.170827221000195, 49.686729728000159],
              [162.167472651000139, 49.683612974000198],
              [162.165705381500288, 49.682462573000294],
              [162.161393651000139, 49.678505974000245],
              [162.159437651000189, 49.677232974000049],
              [162.155315651000137, 49.673399974000176],
              [162.153353651000202, 49.672122974000217],
              [162.152578234000202, 49.67140180000024],
              [162.149236651000251, 49.668293974000107],
              [162.147454888500192, 49.667133609000132],
              [162.146495176000144, 49.666292554000222],
              [162.143157651000365, 49.663187974000209],
              [162.141371359000345, 49.662023985500184],
              [162.137078651000252, 49.658080974000086],
              [162.135101651000127, 49.656792974000098],
              [162.134329059000152, 49.65607387200032],
              [162.130999651000366, 49.652974974000244],
              [162.129204301000073, 49.65180464450026],
              [162.12824600200031, 49.650964626000302],
              [162.124920651000252, 49.647868974000176],
              [162.12312077200022, 49.646695021500136],
              [162.12216313200031, 49.645855379000295],
              [162.11884265100025, 49.642762974000107],
              [162.117037337000284, 49.641585398500069],
              [162.112763651000364, 49.637655974000154],
              [162.110765651000406, 49.636352974000147],
              [162.109997015000431, 49.635636698000042],
              [162.106684651000251, 49.632549974000085],
              [162.10487027850013, 49.631366057500145],
              [162.103913957000145, 49.630527451000148],
              [162.100605651000365, 49.627443974000244],
              [162.098786749500164, 49.626256434000197],
              [162.097831086000383, 49.625418203000208],
              [162.094527651000135, 49.622337974000175],
              [162.092703314500341, 49.62114681050025],
              [162.088448651000249, 49.617230974000222],
              [162.086430651000228, 49.615912974000196],
              [162.085665782000319, 49.615199523000229],
              [162.082369651000135, 49.612124974000153],
              [162.080537162000155, 49.610927470000206],
              [162.079582912000433, 49.610090276000221],
              [162.076291651000133, 49.607018974000084],
              [162.074453727000105, 49.605817846500258],
              [162.073499853000271, 49.604981029000157],
              [162.070212651000247, 49.601912974000243],
              [162.068370198000252, 49.600708223500305],
              [162.064134651000245, 49.596805974000233],
              [162.062095651000277, 49.595472974000302],
              [162.058055651000132, 49.591699974000221],
              [162.05601165100029, 49.590362974000243],
              [162.055251867000152, 49.589653101000238],
              [162.051977651000129, 49.586593974000152],
              [162.049927651000303, 49.585253974000295],
              [162.04589865100013, 49.581487974000083],
              [162.043843651000316, 49.580143974000293],
              [162.03981965100013, 49.576381974000242],
              [162.037760651000212, 49.575033974000235],
              [162.033741651000241, 49.571275974000173],
              [162.027199852666854, 49.566983987333458],
              [161.909524860000261, 49.488911226000084],
              [161.402502650000201, 49.152545973000258],
              [161.399430650000227, 49.151097973000162],
              [161.396465675000286, 49.149104549000242],
              [161.395777650000127, 49.148641973000167],
              [161.392700650000279, 49.147190973000193],
              [161.392013567000163, 49.146729151000216],
              [161.389052650000167, 49.144738973000187],
              [161.38596965000022, 49.143284973000164],
              [161.383014414000172, 49.141296418000195],
              [161.382328650000431, 49.140834973000267],
              [161.379239650000272, 49.139377973000194],
              [161.37855482700013, 49.138917281000261],
              [161.375603650000357, 49.136931973000117],
              [161.372509650000325, 49.135471973000108],
              [161.369562532000373, 49.133488289000297],
              [161.368878650000283, 49.133027973000196],
              [161.365778650000266, 49.131564973000138],
              [161.365095900000256, 49.131105411000306],
              [161.362153650000209, 49.129124973000046],
              [161.359048650000318, 49.12765897300028],
              [161.356110460000423, 49.125680159000126],
              [161.355135773000143, 49.125082633500313],
              [161.352318650000143, 49.12375197300031],
              [161.348703650000175, 49.121317973000146],
              [161.345587650000311, 49.119845973000224],
              [161.342658388000245, 49.117872029000296],
              [161.341978650000328, 49.117413973000282],
              [161.338857650000136, 49.115938973000254],
              [161.338178854000375, 49.115481671000225],
              [161.335253650000254, 49.113510973000075],
              [161.332126650000191, 49.112032973000169],
              [161.32852865000018, 49.109607973000266],
              [161.325798486000394, 49.108316263000177],
              [161.321812650000311, 49.105685973000277],
              [161.318830650000336, 49.104275973000142],
              [161.314817357000152, 49.101625907500136],
              [161.312111650000247, 49.10034597300006],
              [161.309087424000268, 49.098305473000153],
              [161.308103792000225, 49.097698625000248],
              [161.305392650000272, 49.096415973000148],
              [161.302372483000227, 49.094377908000183],
              [161.301389320500334, 49.093771342000082],
              [161.298673650000183, 49.092485973000237],
              [161.29565754100031, 49.090450343000214],
              [161.294674849500211, 49.089844059500194],
              [161.291954650000434, 49.088555973000155],
              [161.288943410000343, 49.086522777000141],
              [161.287961191000136, 49.085916777500131],
              [161.285234650000234, 49.084625973000243],
              [161.282228280000368, 49.082595212000172],
              [161.281246720000468, 49.081989495000187],
              [161.278515650000145, 49.080695973000275],
              [161.275513338000223, 49.078667647000145],
              [161.274532249000345, 49.078062212000248],
              [161.271796650000169, 49.076765973000192],
              [161.268798398000285, 49.074740083000279],
              [161.267817683500198, 49.074134929500133],
              [161.265076650000196, 49.072835973000281],
              [161.262083267000207, 49.070812518000309],
              [161.261103213000297, 49.070207647000075],
              [161.258357650000221, 49.068905973000199],
              [161.25536832500029, 49.066884952000237],
              [161.254388648000258, 49.06628036450013],
              [161.251637650000248, 49.064975973000287],
              [161.247960650000209, 49.062489973000254],
              [161.244918650000272, 49.061045973000148],
              [161.241246650000221, 49.058562973000278],
              [161.238485688000196, 49.057252240500134],
              [161.23750817700045, 49.05664887800026],
              [161.234532650000347, 49.054635973000245],
              [161.231767158500361, 49.053322523000077],
              [161.230790119000403, 49.052719443000285],
              [161.227818650000131, 49.050708973000212],
              [161.225047724000319, 49.049392806000299],
              [161.220816011500233, 49.046644952000122],
              [161.218039650000151, 49.045326973000044],
              [161.215077922000205, 49.043320563000123],
              [161.214101446000086, 49.042717669500178],
              [161.211319650000291, 49.041396973000303],
              [161.207675650000169, 49.038928973000225],
              [161.205007650000312, 49.0376609730003],
              [161.200992650000444, 49.034940973000289],
              [161.199227192500075, 49.034101271000281],
              [161.198162572000228, 49.033416919000331],
              [161.194365650000236, 49.030843973000231],
              [161.192594757500274, 49.030001553500199],
              [161.191530513000316, 49.02931748400016],
              [161.187737650000258, 49.026746973000172],
              [161.185777650000205, 49.025814973000138],
              [161.181989656000383, 49.023246085000039],
              [161.180925507000325, 49.022561922000193],
              [161.179144650000353, 49.021714973000201],
              [161.175360715000352, 49.019148520000215],
              [161.174482650000186, 49.018552973000226],
              [161.172511650000274, 49.017614973000263],
              [161.171634716000312, 49.017020180000145],
              [161.16785565000032, 49.014456973000279],
              [161.16606392350036, 49.013603683500207],
              [161.165001845000347, 49.012920744000269],
              [161.161227650000455, 49.01035997300022],
              [161.159244650000346, 49.009415973000273],
              [161.155474513000399, 49.006856825000057],
              [161.154412341000238, 49.006173791500089],
              [161.152610650000156, 49.005315973000108],
              [161.14797265000027, 49.002166973000158],
              [161.146165524000253, 49.001305531000128],
              [161.145105048000346, 49.000623440000254],
              [161.141344650000406, 48.998069973000099],
              [161.139343650000228, 48.997116973000118],
              [161.135588123000275, 48.994565130000296],
              [161.134527740000181, 48.99388294400012],
              [161.132710650000263, 48.993016973000181],
              [161.128088650000336, 48.989876973000264],
              [161.126266125500251, 48.989007378500219],
              [161.125207249000141, 48.98832613400009],
              [161.121460650000245, 48.985779973000206],
              [161.119443650000335, 48.98481797300019],
              [161.115701109000156, 48.982273435000309],
              [161.114642139000125, 48.981592096500037],
              [161.112809650000372, 48.980717973000196],
              [161.108204650000175, 48.977586973000143],
              [161.106366726000147, 48.976709226500134],
              [161.10530945100038, 48.976028829000199],
              [161.101576650000311, 48.973489973000085],
              [161.099541650000333, 48.972518973000035],
              [161.095813719000375, 48.969981740000151],
              [161.094948650000219, 48.969392973000026],
              [161.092908650000254, 48.968418973000269],
              [161.092044523000254, 48.967830960000185],
              [161.088320650000242, 48.96529697300025],
              [161.086274650000178, 48.964319973000045],
              [161.082555647000277, 48.961787609000282],
              [161.081692650000377, 48.961199973000191],
              [161.079640650000215, 48.960219973000278],
              [161.078778595000244, 48.959633090000295],
              [161.075063650000175, 48.957103973000187],
              [161.073006650000252, 48.956120973000225],
              [161.069296575000294, 48.953593479000176],
              [161.068435650000197, 48.953006973000299],
              [161.066372650000403, 48.952020973000288],
              [161.065512855000406, 48.951435220000235],
              [161.061807650000333, 48.948910973000181],
              [161.059738650000213, 48.947921973000234],
              [161.056037503000312, 48.94539934900024],
              [161.055178650000244, 48.944813973000294],
              [161.053104650000137, 48.943821973000297],
              [161.052246927000397, 48.943237350000288],
              [161.048550650000379, 48.940717973000289],
              [161.046470650000401, 48.939722973000244],
              [161.042779243000155, 48.937205219000305],
              [161.041922650000288, 48.936620973000231],
              [161.039836650000211, 48.935622973000307],
              [161.038980997000181, 48.935039479000295],
              [161.035293650000199, 48.932524973000284],
              [161.033202650000135, 48.931523973000253],
              [161.028665650000335, 48.92842897300028],
              [161.026765224000314, 48.927518616500095],
              [161.021838605000084, 48.924237141000219],
              [161.019933650000212, 48.923324973000263],
              [161.01540765000027, 48.920235973000274],
              [161.013299650000249, 48.919225973000209],
              [161.00963097000033, 48.916720394000265],
              [161.008779650000406, 48.916138973000216],
              [161.006664650000175, 48.915125973000215],
              [161.005814460000124, 48.914545305000161],
              [161.002150650000203, 48.912042973000212],
              [161.000030650000326, 48.911026973000219],
              [160.995521650000342, 48.907946973000264],
              [160.993396650000363, 48.906927973000165],
              [160.989740956000219, 48.904429699000218],
              [160.988892650000366, 48.903849973000206],
              [160.98676165000029, 48.902827973000171],
              [160.982063403500092, 48.899657446000163],
              [160.980127650000441, 48.898728973000175],
              [160.975635650000186, 48.895657973000255],
              [160.973492650000367, 48.894629973000292],
              [160.969006650000438, 48.89156197300025],
              [160.96706093250009, 48.890627159000189],
              [160.962377650000235, 48.887464973000192],
              [160.960223650000444, 48.886430973000301],
              [160.955564354000217, 48.883280545000218],
              [160.953791650000198, 48.882429973000171],
              [160.94913565000013, 48.879242973000089],
              [160.948384719000273, 48.878881548000095],
              [160.942537016000188, 48.874910210000166],
              [160.941780650000169, 48.874546973000236],
              [160.9360946500002, 48.870653973000174],
              [160.935332849000361, 48.870287113000188],
              [160.934183850000181, 48.869516311000154],
              [160.929573650000179, 48.866358973000047],
              [160.928727650000383, 48.865951973000335],
              [160.924122319000276, 48.86279625800006],
              [160.923053650000384, 48.862063973000147],
              [160.92220065000015, 48.861653973000159],
              [160.921133111000131, 48.860922441000184],
              [160.916532650000136, 48.857769973000302],
              [160.915673650000258, 48.857356973000208],
              [160.911078059000261, 48.854206128000214],
              [160.910011650000229, 48.853474973000175],
              [160.909147650000364, 48.853058973000259],
              [160.908082181000395, 48.852328570000111],
              [160.903490650000322, 48.849180973000159],
              [160.902620650000131, 48.848761973000308],
              [160.898033987000304, 48.845615998000198],
              [160.896969650000301, 48.84488597300026],
              [160.896093650000239, 48.844463973000188],
              [160.895030441000131, 48.843734700000141],
              [160.890448650000394, 48.840591973000244],
              [160.889566650000233, 48.840166973000066],
              [160.884989727000175, 48.837025868000353],
              [160.883844024500149, 48.836256761500238],
              [160.883039650000228, 48.835869973000172],
              [160.877406650000239, 48.832002973000158],
              [160.876513650000447, 48.831571973000166],
              [160.871945655000218, 48.82843573800011],
              [160.870800989000145, 48.827667196499988],
              [160.869986650000214, 48.827274973000272],
              [160.864364650000311, 48.823413973000186],
              [160.863544876000333, 48.823018126500074],
              [160.857757859000458, 48.819077631000255],
              [160.856932650000317, 48.818679973000201],
              [160.851322650000157, 48.81482497300027],
              [160.850405650000312, 48.814382973000306],
              [160.84480165000025, 48.810530973000255],
              [160.843965571500121, 48.810126974000127],
              [160.838193163500137, 48.806193783500248],
              [160.837351650000414, 48.805787973000179],
              [160.831758650000211, 48.801941973000282],
              [160.830824650000181, 48.801490973000114],
              [160.825237650000304, 48.797647973000267],
              [160.824385266500371, 48.79723582150018],
              [160.818627468500154, 48.793309936000071],
              [160.8177696500004, 48.792895973000213],
              [160.812194650000265, 48.789058973000181],
              [160.811242650000167, 48.788598973000148],
              [160.805673650000131, 48.784764973000165],
              [160.80341465000015, 48.78367297300025],
              [160.799058650000347, 48.780672973000151],
              [160.595854360000203, 48.681849934000184],
              [160.548700650000455, 48.6589179730002],
              [160.534358650000399, 48.642984973000296],
              [160.533010287000252, 48.641950205000114],
              [160.527199650000284, 48.637490973000297],
              [160.522890649000374, 48.632697973000177],
              [160.061898206000251, 48.274357161000296],
              [159.954924649000304, 48.191203972000267],
              [159.914928074000272, 48.168953078000243],
              [159.742566649000423, 48.073064972000168],
              [159.528218649000337, 47.902194972000359],
              [159.339174046000153, 47.795063419000144],
              [159.264236188666814, 47.75259648533347],
              [159.03749764800034, 47.483828972000083],
              [159.036441648000164, 47.482568972000308],
              [158.596542636000322, 47.118172519000154],
              [158.494463648000306, 47.033613971000307],
              [158.488120367000306, 47.029869667000298],
              [158.460784648000299, 47.013733971000363],
              [158.256115647000343, 46.841185971000243],
              [157.915244647000236, 46.636725971000089],
              [157.854095371000199, 46.584168875000216],
              [157.839905647000137, 46.571972971000264],
              [157.826866711000434, 46.564064929000153],
              [157.7706766470003, 46.529985971000258],
              [157.504921647000288, 46.29924297100024],
              [157.396528647000309, 46.232517971000334],
              [157.193408646000194, 45.969574970000167],
              [157.191525646000173, 45.967818970000167],
              [156.776669154000274, 45.595880661000251],
              [156.68044964600017, 45.50963497000015],
              [156.673236646000191, 45.505037970000103],
              [156.63284753600044, 45.468246252000085],
              [156.458794646000342, 45.309695970000178],
              [156.454354646000297, 45.306838970000172],
              [156.451718646000359, 45.304425970000182],
              [156.04313557800026, 45.038980696000237],
              [155.948323645000301, 44.97738396900013],
              [155.916956292000236, 44.947995369000182],
              [155.781781645000251, 44.821347969000044],
              [155.771522645000346, 44.814568969000277],
              [155.770786207500237, 44.813876011000161],
              [155.768613423000147, 44.81239158600016],
              [155.767668645000185, 44.811502969000287],
              [155.701496860000248, 44.767697957000166],
              [155.686141645000134, 44.757532969000181],
              [155.680566062000139, 44.752267214000142],
              [155.656538645000182, 44.729574969000282],
              [155.175482130000319, 44.406627212000046],
              [155.063841949000107, 44.331682215666888],
              [155.060872644000142, 44.329669969000179],
              [154.968602644000157, 44.285918969000079],
              [154.857672644000218, 44.210208969000121],
              [154.313699126000301, 43.947527426000306],
              [154.187469644000203, 43.886571968000055],
              [154.097719603000314, 43.85731838900017],
              [153.710950643000274, 43.731252968000149],
              [153.67360364300032, 43.704714968000133],
              [153.627089314000273, 43.681562507000194],
              [153.616295643000285, 43.676189968000187],
              [153.548684533000255, 43.627693040000167],
              [153.257320643000298, 43.418699968000283],
              [153.247250643000257, 43.413609968000173],
              [153.198139844000139, 43.378091953000251],
              [153.186743643000341, 43.36984996800021],
              [153.185297529000422, 43.36911711700003],
              [153.179065643000285, 43.365958968000143],
              [153.113424668000448, 43.318347286000289],
              [153.098192643000175, 43.307298968000282],
              [152.752244642000278, 43.130289968000227],
              [152.739363550000235, 43.120885995500089],
              [152.736486642000273, 43.119404968000197],
              [152.733428326000194, 43.117155060000186],
              [152.732718642000464, 43.116632968000147],
              [152.611033268000369, 43.053216264000241],
              [152.086641642000245, 42.779927967000106],
              [152.017637980000245, 42.755536111000026],
              [152.001538642333458, 42.74983824766673],
              [152.000692579333531, 42.749511708666773],
              [151.992714116000229, 42.746668986000088],
              [151.958710642000142, 42.734619967000128],
              [151.957068642000195, 42.733750967000162],
              [151.956093642000155, 42.733404967000297],
              [151.955128642000432, 42.732893967000166],
              [151.380027348000226, 42.526137880000192],
              [151.246574641000279, 42.478159967000181],
              [151.228256004000286, 42.474138986000298],
              [151.149313409000229, 42.456810649000147],
              [150.3821985980004, 43.687438410000254],
              [149.748234471000274, 44.630414963000192],
              [149.562128916000461, 44.907233741000141],
              [149.554950061000341, 44.917875852000236],
              [149.528768769000408, 44.956687647000308],
              [149.524014507000288, 44.963735483000107],
              [149.493279384000402, 45.009024773000135],
              [149.455271949000235, 45.065030070000148],
              [149.339370948000123, 45.208470068000224],
              [149.334964835000278, 45.213706711000157],
              [149.298287623000192, 45.261995455000203],
              [149.275635869000212, 45.290989453000179],
              [149.254950910000161, 45.318234080000138],
              [149.244195806000448, 45.332953435000093],
              [149.233594062000407, 45.347462902000188],
              [149.219764768000232, 45.366761649000296],
              [149.211911962000158, 45.377720222000107],
              [149.195626262000246, 45.400220021000223],
              [149.186682634000249, 45.4125762500002],
              [149.180683858000179, 45.42863927000019],
              [149.160707722000325, 45.482129693000331],
              [149.159968474000266, 45.484077202000265],
              [149.159628925000447, 45.484972079500153],
              [149.159334494000177, 45.485751031500115],
              [149.156571113000268, 45.495450625000103],
              [149.155106904000149, 45.500635333000275],
              [149.152431248000312, 45.510109728000145],
              [149.134078293000243, 45.577297209000108],
              [149.127968763000354, 45.599663306000139],
              [149.126781430000165, 45.60409697200015],
              [149.124157443000286, 45.613895299000092],
              [149.119644533000269, 45.633444112000291],
              [149.116897354000457, 45.645344215000364],
              [149.112418914000273, 45.664743717000192],
              [149.109425831000294, 45.682190267000237],
              [149.108150122000211, 45.689626317000148],
              [149.103971352000258, 45.713984184000196],
              [149.08752560600044, 45.752878976000147],
              [149.063662400000226, 45.821667107000167],
              [149.018707538000172, 45.951509599000303],
              [149.001529500000288, 45.996258369000202],
              [148.963788273000318, 46.056324865000249],
              [148.898254685000325, 46.171494324000037],
              [148.809538734000284, 46.303857616000244],
              [148.794720773000222, 46.326765300000091],
              [148.753996155000436, 46.389723128000242],
              [148.667921607000267, 46.522507459000167],
              [148.559495328000139, 46.684363464000171],
              [148.152210255000227, 47.280370225000127],
              [148.030350824000266, 47.452917983000191],
              [147.932015710000201, 47.592156311000281],
              [147.85064704600029, 47.707370869000158],
              [147.551051266000172, 47.577876642000319],
              [146.468709920000265, 47.090939622000292],
              [146.459330951000425, 47.086657512000215],
              [146.438487791000171, 47.077141251000228],
              [146.285900028000356, 47.007371521000266],
              [146.275006529000279, 46.984631312000147],
              [146.265962910000212, 46.96575273100018],
              [146.257098161000158, 46.947247542000184],
              [146.072240472000203, 46.556360921000248],
              [146.014137154000196, 46.431514576000041],
              [145.997845005000158, 46.397722112000224],
              [145.982369275000337, 46.365623027000083],
              [145.975591775000339, 46.351565431000267],
              [145.970726774000241, 46.341421519000278],
              [145.966982885000334, 46.333641285000112],
              [145.833791782000162, 46.137472116000197],
              [145.745562693000238, 46.007524796000268],
              [145.670026750000261, 45.957979616000159],
              [145.650579080000171, 45.945223592000048],
              [145.627192471000399, 45.929820010000242],
              [145.613721952000219, 45.920947658000159],
              [145.603562886250188, 45.914237108000236],
              [145.581390847000421, 45.899591414000099],
              [145.522105857000156, 45.860933134000163],
              [145.39367377300016, 45.777185740000107],
              [145.340734849000341, 45.740463965000231],
              [145.229148123000186, 45.649528222000072],
              [144.380270070000279, 45.183789999000169],
              [144.370605985000225, 45.181751609000116],
              [144.320310949000145, 45.173057376000088],
              [144.168144177000272, 45.177412281000215],
              [143.937334866333572, 45.192815952000103],
              [143.882618292000302, 45.199614037000288],
              [143.732262638000407, 45.226776760000234],
              [143.717462868000325, 45.229881484000259],
              [143.604372560000229, 45.256010127000195],
              [143.489771186000326, 45.301044000000161],
              [143.416495490000329, 45.329810067000039],
              [143.380012171000203, 45.344053447000135],
              [143.314930824000271, 45.372812566000334],
              [143.013446312000383, 45.514410220000173],
              [142.996500655000432, 45.524657820000243],
              [142.994763227500243, 45.525709386500068],
              [142.99366471500025, 45.526385351000101],
              [142.952274231000274, 45.552142332000187],
              [142.918545397000344, 45.574904742000285],
              [142.912975533000235, 45.579096683000273],
              [142.891213265000374, 45.595838469000341],
              [142.878289294000297, 45.605883347000088],
              [142.876647016000334, 45.607159772000102],
              [142.871296769000196, 45.611369655000203],
              [142.860857620000388, 45.619583777000173],
              [142.837273581000204, 45.64026431800022],
              [142.806149544000164, 45.66765338200014],
              [142.664465782000434, 45.656767062000199],
              [142.49440727900037, 45.644653524000148],
              [142.486036498000431, 45.644071238000265],
              [142.470666745000358, 45.643140558000198],
              [142.424017309000419, 45.640421068000251],
              [142.401280225000278, 45.640076412000155],
              [142.395655213000282, 45.640022085000169],
              [142.377175281000291, 45.639893873000233],
              [142.299111693000327, 45.654438501000186],
              [142.274918317000356, 45.65898096900014],
              [142.081992219000426, 45.694566723000207],
              [141.969370008000169, 45.715511486000253],
              [141.937492827000256, 45.721277649000115],
              [141.838487341000359, 45.739775192000195],
              [141.707403399000327, 45.767594281000243],
              [141.621211712000445, 45.785973725000247],
              [141.492395862000308, 45.846728106000057],
              [141.352515396000399, 45.809310679000248],
              [141.247930172000338, 45.821185518000163],
              [141.163740651000353, 45.835313519000181],
              [141.073275676000321, 45.85081369500017],
              [140.966463322000209, 45.866930890000276],
              [140.749177092000195, 45.899524837000172],
              [140.606722557000239, 45.920199340000238],
              [139.689295723000328, 46.048717540000098],
              [139.658895357000233, 46.012981197000272],
              [139.557041506500241, 45.888446764500088],
              [139.549088319000361, 45.873568215000319],
              [139.414242701000262, 45.621303252000075],
              [139.337194844000237, 45.464878105000025],
              [139.304009431000281, 45.397692242000062],
              [139.241936085000248, 45.23985900300022],
              [139.239754675000199, 45.233439426000302],
              [139.236968178000325, 45.220475269000246],
              [139.235846443000128, 45.215256403000296],
              [139.211680076000221, 45.088251442000171],
              [139.130837799000233, 44.663389466000126],
              [139.125362070000278, 44.619600813000204],
              [139.009737445000269, 44.549593732000233],
              [138.802778891000344, 44.398113009000156],
              [138.671608892000336, 44.295961538000142],
              [138.49786143100016, 44.152024248000089],
              [138.45513104000031, 44.108644567000056],
              [138.40189088600016, 44.05020701900014],
              [138.266325485000152, 43.871480579000263],
              [138.175223458000232, 43.743690187000254],
              [138.149950746000286, 43.697106186000326],
              [138.053052746000276, 43.599513585000352],
              [137.804202017000307, 43.347838269000135],
              [137.734529372000338, 43.295949562000033],
              [137.720784129000322, 43.285712791000094],
              [137.704020823000292, 43.272779774000185],
              [137.703080580000233, 43.272054370000205],
              [137.626787119000255, 43.21319338700016],
              [137.607152504000169, 43.191515707000292],
              [137.603801164000402, 43.187815647000264],
              [137.563765713000294, 43.13686876700018],
              [137.498443535000206, 43.053743409000219],
              [137.483993967000401, 43.035728326000253],
              [137.453377532000331, 42.997553560000199],
              [137.308271673000377, 42.808911722000062],
              [137.206615714000236, 42.670147323000037],
              [137.084319215000164, 42.503207773000156],
              [136.913846747000207, 42.201775455000131],
              [136.77841265300026, 41.962298587000305],
              [136.545370760000424, 41.687104872000077],
              [136.4968548960004, 41.629685995000159],
              [136.421534663000216, 41.51635034800023],
              [136.393461942000386, 41.472885590000146],
              [136.193610425000429, 41.16171228200011],
              [136.116102527000407, 41.029526174000182],
              [135.88935705300014, 40.520664472000135],
              [135.868849302000285, 40.513740302000315],
              [135.277507338000191, 40.308936060000292],
              [134.798156541000253, 40.139728558000172],
              [134.742584522000129, 40.120112022000285],
              [134.544693151000274, 40.028961147000246],
              [134.371778558000301, 39.949314843000025],
              [134.221631595000304, 39.878541295000161],
              [133.640220926000211, 40.039988968000102],
              [133.110186505000343, 40.187170347000176],
              [132.815554096000255, 40.268984663000026],
              [132.612218825000241, 40.492460605000247],
              [132.420206884000208, 40.703862171000253],
              [132.343145355000161, 40.768684747000179],
              [132.332950909000147, 40.777240319000157],
              [132.310758564000253, 40.825321074000158],
              [132.302260595000121, 40.843773211000212],
              [131.649680865000278, 41.621616065000183],
              [130.883333000000448, 42.150000000000148],
              [130.697581728575983, 42.292306307228387],
              [130.696997270351403, 42.29260679737871],
              [130.655045199480583, 42.318118143001186],
              [130.652772362609312, 42.339991222312904],
              [130.659009336779718, 42.375273167447745],
              [130.632754198475482, 42.411373186334856],
              [130.604372403691855, 42.421864144016467],
              [130.580263367883049, 42.481100059751327],
              [130.607463314315623, 42.56360011545263],
              [130.618709313837485, 42.585409157023832],
              [130.605227190180358, 42.619709078380026],
              [130.584963436282692, 42.645000130178062],
              [130.536091240939299, 42.682000197830803],
              [130.497327285577398, 42.6848540682318],
              [130.471145237469159, 42.679164264702308],
              [130.411118237710639, 42.703118067664093],
              [130.432736171888791, 42.744854078262222],
              [130.475527296461678, 42.765273232645029],
              [130.532745185182193, 42.789991129902376],
              [130.638027253777977, 42.83166410858837],
              [130.795536290653672, 42.871936135973613],
              [130.81857244285311, 42.876100097844869],
              [130.841645140150604, 42.870273166379036],
              [130.865509256747828, 42.857773234138449],
              [130.887191228666779, 42.851800122281077],
              [131.02136337065599, 42.857082230038998],
              [131.128491301974236, 42.916445047788102],
              [131.13337241948318, 42.939709187754545],
              [131.114963413125452, 42.958609038363534],
              [131.103991334200032, 42.982209125010229],
              [131.103027247694058, 43.015554012315761],
              [131.106200300969022, 43.038891074840194],
              [131.191927221763649, 43.185818126565323],
              [131.264163301721851, 43.270273177368608],
              [131.310391341882337, 43.389582193329602],
              [131.301009309993901, 43.457145026978253],
              [131.283054267513535, 43.473418156762705],
              [131.272763302042108, 43.481936014431369],
              [131.23550926272253, 43.505554038351022],
              [131.223018215299476, 43.511664110506842],
              [131.191227165208716, 43.536245047465968],
              [131.199127276611648, 43.818882152779167],
              [131.231082276728898, 43.973873098295769],
              [131.244418219994486, 44.021382228541142],
              [131.262754136156104, 44.037354112724699],
              [131.298036248929179, 44.051936109719861],
              [131.284972382241563, 44.093609088405628],
              [131.255554248946282, 44.184718029244493],
              [131.128572438797164, 44.585818081209879],
              [131.09551823589436, 44.691373232211959],
              [131.055591375282688, 44.75346419181426],
              [131.01983618789302, 44.773044988238894],
              [131.000672307333616, 44.781936008238063],
              [130.952991348072715, 44.836491133950688],
              [131.067745134921097, 44.909718122504728],
              [131.092191290876599, 44.918600090048585],
              [131.124545269586292, 44.927353982111185],
              [131.149272386937128, 44.928054038666517],
              [131.177127295285459, 44.911864224999761],
              [131.212463387515072, 44.910545081074474],
              [131.286163450685876, 44.931800078844105],
              [131.31440929099972, 44.955127083084676],
              [131.341082350997226, 44.970953960342911],
              [131.469118269292721, 44.97387320958893],
              [131.628018199185021, 45.091518082488733],
              [131.646382278541751, 45.108054068757383],
              [131.652754201353332, 45.133736052520646],
              [131.70330931522119, 45.216100153752222],
              [131.8361542666579, 45.33430007628175],
              [131.868436160999977, 45.345818152381582],
              [131.895800225097332, 45.337564157025554],
              [131.910382389730444, 45.308609040063175],
              [131.923718332995691, 45.2861091620303],
              [131.961363304280553, 45.255964150089767],
              [131.99242730808794, 45.241436132551257],
              [132.122745283348308, 45.213609052121285],
              [132.196927305953182, 45.197491155184196],
              [132.299136233559807, 45.175273244374921],
              [132.364136216486372, 45.161100116617945],
              [132.568027185265237, 45.116391212593328],
              [132.799409287793651, 45.064991202883107],
              [132.871291316160637, 45.038182188415703],
              [132.931454270389281, 45.021409161924453],
              [132.952609355872823, 45.02458221519953],
              [133.025272409979834, 45.056936026270819],
              [133.074672329948953, 45.088882141570764],
              [133.117463454521385, 45.124573123581854],
              [133.126209299957281, 45.164718081314845],
              [133.113863427097613, 45.197209187960766],
              [133.09920029327975, 45.228882220854246],
              [133.101900271938007, 45.294444964761567],
              [133.130236301530203, 45.414573227941105],
              [133.149136152139249, 45.450827138571071],
              [133.19224528611926, 45.503818033508367],
              [133.216627236696354, 45.511109115824922],
              [133.244109317990592, 45.516109088721294],
              [133.268309213630346, 45.525553984883857],
              [133.382172339444878, 45.569991147606856],
              [133.417209197730131, 45.589718124423371],
              [133.470245187306887, 45.624573095409289],
              [133.48753621541573, 45.678253988618053],
              [133.474945255706416, 45.797736007061133],
              [133.476054181499762, 45.823464258930017],
              [133.521091320853657, 45.883418168492597],
              [133.550600313979317, 45.884645111482826],
              [133.579500278018713, 45.871291063306444],
              [133.660236278372963, 45.935164183167046],
              [133.723827263371618, 46.069027032928375],
              [133.744691329176248, 46.155818120153015],
              [133.830245247488847, 46.194991112391108],
              [133.901609274238012, 46.253627051494902],
              [133.929718321891528, 46.381882240378047],
              [133.902800342682781, 46.421218176814136],
              [133.850527273935199, 46.443227042957574],
              [133.847182391644651, 46.478882150423033],
              [133.908918293827583, 46.578118016965334],
              [133.970927278416639, 46.611318064983834],
              [133.997463378115867, 46.633609065988821],
              [133.997745177701518, 46.640164217204642],
              [134.005245137045563, 46.644154003127255],
              [134.01943620207544, 46.666664107081942],
              [134.043291266217693, 46.766827012118029],
              [134.039363338740372, 46.860491092327891],
              [134.065800364343886, 46.954164224993292],
              [134.113591294174853, 47.055827155425675],
              [134.13712717544297, 47.076691221230135],
              [134.187163449502805, 47.083918098168283],
              [134.215191360333279, 47.099673226334971],
              [134.222836158964924, 47.129982188302222],
              [134.190518222439096, 47.181109115606091],
              [134.149291328641425, 47.248600200163423],
              [134.182463381102934, 47.323327046476209],
              [134.295809343490191, 47.413609028745327],
              [134.314700309281875, 47.421109155727606],
              [134.342745151556755, 47.426245083093647],
              [134.387482386414263, 47.430827134296294],
              [134.417327326220175, 47.431309093730249],
              [134.459136259376237, 47.427491136823164],
              [134.485791382100956, 47.429573201577909],
              [134.554782324416465, 47.469145004769871],
              [134.760818222223861, 47.700273135631448],
              [134.768463356131406, 47.737282088101551],
              [134.714954124300448, 47.776500174978594],
              [134.684354142654115, 47.800900062828703],
              [134.593654238691556, 47.911091076851676],
              [134.557600320271945, 47.992073169883668],
              [134.566227310320301, 48.032082173146762],
              [134.581772387993055, 48.060409150283476],
              [134.601627272652308, 48.076245079997022],
              [134.636763372671567, 48.088045123320285],
              [134.661027306051665, 48.100618145756755],
              [134.681363311954897, 48.155264131299816],
              [134.686154240185033, 48.18283606659628],
              [134.680754282868179, 48.21472702897313],
              [134.716418275151113, 48.260409071959202],
              [134.740754125260423, 48.267126999735069],
              [134.717472383297491, 48.27128207678868],
              [134.643863347595357, 48.291664183159355],
              [134.48272729356745, 48.350273132534824],
              [134.46386331750432, 48.359718028697444],
              [134.432109315425691, 48.378745116596804],
              [134.415345173751916, 48.391118146822805],
              [134.206909201782423, 48.342491205966951],
              [134.066372345418131, 48.329436056458519],
              [134.039272311271816, 48.323054075362904],
              [134.01095421895252, 48.305691130524565],
              [133.884709345732517, 48.26416416459233],
              [133.751372376577933, 48.249718122067065],
              [133.596345221239517, 48.174018136792881],
              [133.57371827355405, 48.151800058345771],
              [133.55581821635883, 48.120827082007281],
              [133.54080036039727, 48.098873201148763],
              [133.515182246736458, 48.075900080861459],
              [133.484272302310046, 48.068882080950971],
              [133.449263439581273, 48.074300143178675],
              [133.428727273829963, 48.085409182402486],
              [133.27221819800593, 48.104164193724046],
              [133.194118306207997, 48.109436075559984],
              [133.082736223740199, 48.098882085966125],
              [132.863863441183867, 47.994436028395512],
              [132.662063421977223, 47.871936021886597],
              [132.577882291770379, 47.733254080700135],
              [132.542482329438371, 47.713882161303161],
              [132.521091377198672, 47.7102732490618],
              [132.466372301459614, 47.716936023914684],
              [132.418309295051131, 47.72526411529492],
              [132.389263318257917, 47.736245079037701],
              [132.365236257462357, 47.742073183970035],
              [132.341063351550929, 47.742491105663362],
              [132.289700222214719, 47.736382206974042],
              [132.255245235649568, 47.728045063138325],
              [132.21080036157548, 47.713464239609664],
              [132.121609200188686, 47.692764123831751],
              [131.914982378205877, 47.669991163392581],
              [131.815245273852383, 47.674709169065295],
              [131.618009200937195, 47.663882097065255],
              [131.547054211064051, 47.697491181959776],
              [131.534972368155081, 47.719227133334925],
              [131.486754128899179, 47.73193611024135],
              [131.446363413764743, 47.737773099990818],
              [131.416654260790551, 47.738600058560507],
              [131.268191309123267, 47.73026425582907],
              [131.224682358360496, 47.71804511734581],
              [131.197209329521797, 47.706382201958945],
              [131.159700312706718, 47.693045085226856],
              [131.08593621179557, 47.672182192888897],
              [131.030809272646934, 47.677491122736967],
              [130.992172386937455, 47.687209101305655],
              [130.954682313223884, 47.70610911955265],
              [130.93997240838587, 47.720273194854087],
              [130.93510922814994, 47.740827130240518],
              [130.946072422257998, 47.79777311002124],
              [130.939836286278137, 47.817491202020449],
              [130.929963410138043, 47.838600187036548],
              [130.913300354216858, 47.865273247033883],
              [130.88372732335074, 47.906236110880911],
              [130.853854220350058, 47.9311090733475],
              [130.798036277101573, 47.965000125465622],
              [130.75802727383882, 47.988044994844415],
              [130.679563439803928, 48.047636135636822],
              [130.659836295349237, 48.108391187507152],
              [130.67837237135916, 48.138953953503176],
              [130.753872364422932, 48.192491180891011],
              [130.83150035507083, 48.293891087201132],
              [130.823991343271132, 48.32680011554055],
              [130.737454227713101, 48.429436017296382],
              [130.609409256962294, 48.519991081971796],
              [130.523591308698656, 48.605754044950231],
              [130.528872410628196, 48.626100109137099],
              [130.57718234790525, 48.713327055328307],
              [130.633845186996041, 48.810545062494612],
              [130.670536297696685, 48.839709056484708],
              [130.674000370650674, 48.86040917226245],
              [130.642818182008227, 48.87540909095128],
              [130.627682308849899, 48.874644996655547],
              [130.564272372960318, 48.856382170689699],
              [130.538027292940114, 48.851664165017155],
              [130.509018196521083, 48.851518152263452],
              [130.484682346411432, 48.860973106709878],
              [130.47245415547286, 48.878326999092906],
              [130.451491350848528, 48.892354114095951],
              [130.418027272879499, 48.896100154273711],
              [130.380245173658182, 48.891382148601167],
              [130.352172335826594, 48.8849911150499],
              [130.264282381092244, 48.860273217792724],
              [130.224609324509743, 48.864645050863032],
              [130.200672285354131, 48.878736035968871],
              [130.110509326110559, 48.932209057978127],
              [129.907636255656143, 49.041727005174721],
              [129.912709318748512, 49.071718125372513],
              [129.773563355400711, 49.170164248995377],
              [129.74631831432896, 49.181554081976117],
              [129.732091374753395, 49.202309183039006],
              [129.736600335760272, 49.227654046655687],
              [129.725536223537688, 49.26217323859953],
              [129.686036169437102, 49.278900164623167],
              [129.653563335340209, 49.277364264680841],
              [129.605209309252359, 49.268327064289963],
              [129.579936194727111, 49.273600119592402],
              [129.552445228615397, 49.28776419489401],
              [129.53071832969556, 49.313864267988606],
              [129.523463457200819, 49.391236107674899],
              [129.511536176587015, 49.404582109224123],
              [129.490200209632349, 49.415800113189505],
              [129.421200214861187, 49.431345023223912],
              [129.390500320928936, 49.42786418646358],
              [129.184709342333235, 49.387518063053903],
              [129.165509251951789, 49.375927064396308],
              [129.136354142779055, 49.35239118312802],
              [129.111782258275412, 49.34675401795073],
              [129.076872302004404, 49.352791167548901],
              [129.052309302318008, 49.363964244512829],
              [129.039263372903292, 49.385136093802714],
              [129.024109227195936, 49.415273226754209],
              [129.002445192550226, 49.435964122438861],
              [128.875245285279789, 49.477764170777391],
              [128.803318329911349, 49.546935994564137],
              [128.790254127947719, 49.568191159971775],
              [128.711909316938204, 49.584436126561457],
              [128.653045222429455, 49.592218053129329],
              [128.513445127566456, 49.587491162639424],
              [128.397754243940312, 49.577073127515462],
              [128.244982323476734, 49.548327055218863],
              [128.214418216376203, 49.536945101227104],
              [128.186763300237885, 49.534164153383756],
              [128.102754166685031, 49.536664139831828],
              [127.838045281761651, 49.586653978147922],
              [127.81831830494491, 49.592627090005521],
              [127.713045288804665, 49.653600071359165],
              [127.68879124607065, 49.67040914003428],
              [127.678454180131439, 49.708182186799718],
              [127.671782352823129, 49.746036035112354],
              [127.628309276606274, 49.765000091099751],
              [127.591663428182756, 49.772491165626661],
              [127.56360031335862, 49.777354010586464],
              [127.535672314814292, 49.785827108892079],
              [127.515009247048397, 49.805882153400034],
              [127.5062451290637, 49.827491202760768],
              [127.485945165344674, 50.018882239299046],
              [127.489554245223871, 50.045418171360382],
              [127.503182381634815, 50.067218160475932],
              [127.55032723436716, 50.108182197789461],
              [127.574436270176193, 50.136936149075041],
              [127.585527204488812, 50.167709133203232],
              [127.587309197109107, 50.19760905829429],
              [127.586063311017597, 50.20856420577519],
              [127.575545195969312, 50.216100207303214],
              [127.543318286912381, 50.231100125991702],
              [127.469009194654973, 50.257218136359313],
              [127.434418253619697, 50.265827021496875],
              [127.396100215508341, 50.278327121375469],
              [127.375536221838047, 50.286800052043077],
              [127.351654167967808, 50.299436106391397],
              [127.334018308361351, 50.314718159941663],
              [127.290882352290964, 50.459227032594015],
              [127.333172406690323, 50.524573188122588],
              [127.361782356879189, 50.55124507465348],
              [127.36691828424523, 50.576109152302536],
              [127.358582313875786, 50.595864124676041],
              [127.309772312254154, 50.648182120424508],
              [127.279209210982117, 50.684709113460599],
              [127.291927240343853, 50.74138217847343],
              [127.193036205298995, 50.824718077561556],
              [127.150545152860786, 50.888464127769396],
              [127.133182375660454, 50.909573112785552],
              [127.106091226331387, 50.929436044071963],
              [127.046109321211929, 50.955354062229389],
              [126.933100311333305, 51.058253993936034],
              [126.912491223023949, 51.125264124887678],
              [126.898745237053959, 51.181100173046786],
              [126.906100357110972, 51.210691141185919],
              [126.932063302269626, 51.242218161325866],
              [126.975191379350804, 51.299718017270038],
              [126.968872430167266, 51.319227064603353],
              [126.937391342856813, 51.314045036769699],
              [126.893772421524204, 51.303154094667377],
              [126.900263367361504, 51.260618115228056],
              [126.893527167036723, 51.241173105634971],
              [126.851100319976922, 51.24124502236441],
              [126.817618304734879, 51.265554050383741],
              [126.807336224080927, 51.304573150878923],
              [126.819845208776655, 51.32735399030696],
              [126.855809273194041, 51.344035989329484],
              [126.890382276956643, 51.350345047867108],
              [126.913882283679072, 51.38138222958429],
              [126.893600257232492, 51.398609052314725],
              [126.868872301691454, 51.405264115816408],
              [126.794427254964148, 51.423118072544241],
              [126.726372403597793, 51.634091078973825],
              [126.718382270554571, 51.710091136382459],
              [126.609154169570843, 51.787218056857057],
              [126.471100368210159, 51.929991204994849],
              [126.44122726520925, 51.99436422156208],
              [126.454563376112901, 52.020691108957521],
              [126.476791345205925, 52.034027052222939],
              [126.509427291139104, 52.037353997240757],
              [126.534572330183437, 52.069445119465911],
              [126.554982264472756, 52.126936090592721],
              [126.490536157709585, 52.159718049279547],
              [126.46984526202516, 52.165691161137147],
              [126.391372375534957, 52.176382111029227],
              [126.351927306719062, 52.183045053520175],
              [126.318882323909975, 52.192218040742773],
              [126.307482265007025, 52.212554046645835],
              [126.327763453263174, 52.234709093181095],
              [126.348600361701301, 52.291664125417356],
              [126.347272332958482, 52.369153982300531],
              [126.333882242598406, 52.395827042297867],
              [126.275672271815097, 52.453464193816558],
              [126.238800279643328, 52.463391217051139],
              [126.209018204111544, 52.460691070754535],
              [126.196354154206233, 52.47644502545495],
              [126.209427240987566, 52.501391078117251],
              [126.213191218437942, 52.51000013089299],
              [126.201782274717743, 52.527773118435675],
              [126.179982285602136, 52.544027137480839],
              [126.0868633643762, 52.58915396319901],
              [126.060400354872854, 52.58055396287898],
              [126.033200240802216, 52.564309163927248],
              [125.994282393697972, 52.576100154795199],
              [125.976645192986894, 52.610827050300372],
              [125.972972410643024, 52.637491225480574],
              [126.037200252646812, 52.716100066440674],
              [126.096791225801212, 52.757218163134894],
              [126.064845278139273, 52.781591228894683],
              [126.010954166798342, 52.777973096559776],
              [125.972891273820096, 52.763954028183605],
              [125.875809221123745, 52.836109138956829],
              [125.855745124160535, 52.86283601077281],
              [125.744427247071371, 52.889991197842448],
              [125.722491303485754, 52.884718142539953],
              [125.657345140167308, 52.87520015618145],
              [125.644991220680595, 52.897564247382434],
              [125.660263383585061, 52.923600115098793],
              [125.688300346871188, 52.932073213404408],
              [125.722272368174231, 52.945691123893482],
              [125.727482391564422, 52.981518060374469],
              [125.620045168018123, 53.050273135934106],
              [125.568327316538671, 53.056936078424997],
              [125.485527188703145, 53.069164269363625],
              [125.398609199463522, 53.101664260826965],
              [125.356091324935164, 53.1183271491102],
              [125.281100281033673, 53.149718047142244],
              [125.164991308075969, 53.193045110605453],
              [125.134163338662688, 53.197491207338373],
              [125.061918206249175, 53.195545097053525],
              [124.988309338185218, 53.186654077054527],
              [124.963609210562709, 53.182773255873315],
              [124.919009271279606, 53.171936125589525],
              [124.879009320472164, 53.146727048804735],
              [124.818327191159483, 53.126936034247592],
              [124.69178224580503, 53.170545064934572],
              [124.643872292948686, 53.187764176314204],
              [124.614436222380732, 53.191654049950557],
              [124.545818274757494, 53.190409169687484],
              [124.515263387750451, 53.188882154562634],
              [124.493309339253983, 53.188327104932739],
              [124.402209283232537, 53.212209158803262],
              [124.3388633850833, 53.255553991901309],
              [124.32220921397959, 53.287918028894751],
              [124.296772317065802, 53.320000098664423],
              [124.269009274376458, 53.346800060676344],
              [124.229427245262173, 53.363054079721564],
              [124.194427267350875, 53.360827008041554],
              [124.159982339069529, 53.34971813645609],
              [124.133182377057437, 53.336236012798622],
              [124.1044272523053, 53.340827116456921],
              [124.0217912421341, 53.385827040160564],
              [124.005127180384648, 53.408464213767786],
              [123.981463391273678, 53.431264163935111],
              [123.910809311725075, 53.464718183620448],
              [123.889972403286947, 53.473045101534353],
              [123.866382374924228, 53.480545060878626],
              [123.614709193493695, 53.543609159442326],
              [123.53526333568027, 53.5422180987876],
              [123.487200329271673, 53.539436145115701],
              [123.442745229275829, 53.522491121971086],
              [123.374563308256825, 53.529164122745613],
              [123.339154125831215, 53.542773148417325],
              [123.314418291301195, 53.549436090908216],
              [123.283054215359243, 53.553600052779302],
              [123.254709300949912, 53.553745059704568],
              [123.225809336910629, 53.546109145890398],
              [123.193863389248691, 53.522764204376983],
              [123.175263443136259, 53.510273156954099],
              [123.153318279457153, 53.500545120101435],
              [123.119836264214996, 53.497073168158408],
              [122.92360031998976, 53.478600124060449],
              [122.876663338456638, 53.46193606231077],
              [122.840818297064857, 53.452218083741855],
              [122.814845125984675, 53.449718097293783],
              [122.765682246238043, 53.452354038211922],
              [122.716382238555298, 53.455554081215155],
              [122.612763307192381, 53.456654122190969],
              [122.481654247907954, 53.44887320145142],
              [122.24609125206382, 53.456936089414569],
              [122.061436248212203, 53.412909137034262],
              [122.026091271165342, 53.413973135826268],
              [121.983045169097068, 53.421109152933582],
              [121.953454200958163, 53.422218078726928],
              [121.855554242147718, 53.415827045175831],
              [121.829991281410003, 53.413045091503989],
              [121.777482345906492, 53.401100208893467],
              [121.636109310878851, 53.366100063344049],
              [121.58193623231432, 53.347773199637743],
              [121.552754133413487, 53.33999110543192],
              [121.523036263259769, 53.33305407470624],
              [121.473791240861942, 53.323818055571508],
              [121.4241361757596, 53.316109051561625],
              [121.414291295176668, 53.315709067140745],
              [121.333600221823644, 53.319164255277599],
              [121.313454149847303, 53.312073165171171],
              [121.27192718391484, 53.291109187080451],
              [121.211936226339958, 53.271518164733834],
              [121.180545160669908, 53.269991149609211],
              [121.129963392350078, 53.272491136057113],
              [121.107127232360767, 53.278535997006145],
              [121.066091278317799, 53.291382101848569],
              [120.86650927833557, 53.279782218373668],
              [120.830691226671888, 53.263745122983323],
              [120.693863362473053, 53.159154058487331],
              [120.563327290091479, 53.077218104871463],
              [120.46945416521578, 53.020073138708653],
              [120.350691314067262, 52.925282195432658],
              [120.330554126908169, 52.897773124410094],
              [120.268736249712106, 52.85708216950303],
              [120.168736288874129, 52.809718046182923],
              [120.148863299303912, 52.807209174917489],
              [120.116836214819159, 52.806854117497778],
              [120.031436188249018, 52.76819108025046],
              [120.025745211253138, 52.641473132414148],
              [120.040600290654538, 52.606827038455776],
              [120.081927264376645, 52.588045037405891],
              [120.161909228718628, 52.578873223649566],
              [120.185582237923313, 52.579818031778188],
              [120.210536169574652, 52.586382235449321],
              [120.254436219940544, 52.605136073304607],
              [120.289427313034594, 52.615545055972916],
              [120.440263349135535, 52.637218143074278],
              [120.465000357131998, 52.6308180570677],
              [120.712172288906686, 52.542664240020883],
              [120.683309205241358, 52.428464161697605],
              [120.663736287805364, 52.405273111926931],
              [120.625909261583274, 52.344227040377348],
              [120.652545273568649, 52.298673240510311],
              [120.721918263031853, 52.251664174609743],
              [120.751663290551846, 52.2105450720872],
              [120.78179137104803, 52.157627099707668],
              [120.776654102577481, 52.115000092799391],
              [120.71096328318913, 51.979991101594806],
              [120.652027271950828, 51.914345041569561],
              [120.62137230501969, 51.903600112220943],
              [120.557482253714852, 51.895273194307038],
              [120.491645253934735, 51.87860008010199],
              [120.310254163188915, 51.773045096738088],
              [120.222209310883727, 51.712491210544272],
              [120.197200226309064, 51.686100117770536],
              [120.161582334493914, 51.666654102349128],
              [120.107700275608522, 51.664218153641514],
              [120.064145224378308, 51.620827052437676],
              [119.983045281787099, 51.493045105809188],
              [119.975191270851951, 51.466727103231221],
              [119.971363423299067, 51.423609084433679],
              [119.956645136557881, 51.389991114721681],
              [119.86431830518427, 51.28718221048382],
              [119.815463376561524, 51.269154077807514],
              [119.776091230303564, 51.213609049327488],
              [119.766245176253733, 51.18860013239123],
              [119.780263406439872, 51.168736027638147],
              [119.747891322819214, 51.076518161006277],
              [119.709991374039191, 51.036664222952425],
              [119.688763365997829, 51.018436098065763],
              [119.665000335152854, 51.003654108860644],
              [119.643872407035815, 50.997918037225361],
              [119.589709219116912, 50.972764113363667],
              [119.520818189087322, 50.902354114836442],
              [119.519436180888079, 50.846382112207436],
              [119.516663447309611, 50.793609146753113],
              [119.506027315064443, 50.749018092287542],
              [119.45880031968079, 50.696236074377737],
              [119.361100353080815, 50.633327041023222],
              [119.29414520741409, 50.59749121972493],
              [119.263318243829303, 50.514164205454108],
              [119.255545202078906, 50.470545116483265],
              [119.238036244486693, 50.440964206628166],
              [119.204154244824053, 50.410818021221019],
              [119.166454120616208, 50.412554081011649],
              [119.138600218095917, 50.390273138290468],
              [119.176654226257028, 50.352773173930927],
              [119.198318260902852, 50.345273214586484],
              [119.253118305826547, 50.341235987091807],
              [119.289982251371271, 50.347773201034727],
              [119.347963396197088, 50.346173095713993],
              [119.359991259649519, 50.330273128260046],
              [119.330054118908379, 50.275273258763946],
              [119.32900923085549, 50.2104090626691],
              [119.345945201544964, 50.19326421495208],
              [119.346482313901902, 50.150191123155707],
              [119.321027312077263, 50.096309064270258],
              [119.216582260335031, 50.017082141768128],
              [119.174145187353474, 50.001391218979848],
              [119.135818264424415, 49.994991132973269],
              [119.099636270523888, 49.99044512395443],
              [119.066791279924928, 49.983045076896246],
              [119.000263443683366, 49.976936010568807],
              [118.956236156026876, 49.982909122426349],
              [118.921509260521702, 49.986309157639937],
              [118.870818192183947, 49.972491087302387],
              [118.715891284407803, 49.949364242910292],
              [118.674700265155707, 49.955273149389427],
              [118.640827150310429, 49.948873231020855],
              [118.574572396475219, 49.92263602998986],
              [118.526382320413859, 49.896664200013959],
              [118.492336203086552, 49.869991140016452],
              [118.480672281870966, 49.841727027153638],
              [118.365809195004886, 49.778327149547863],
              [118.205963283517349, 49.690964248886985],
              [118.172209191697448, 49.66888212490943],
              [118.049427217965103, 49.604291178859057],
              [117.874709295631988, 49.520573232623008],
              [117.794845180848824, 49.518182210916336],
              [117.738591210995992, 49.535000164408757],
              [117.573318184825325, 49.596100047776815],
              [117.525272277499198, 49.614300177106827],
              [117.494427209003561, 49.623045016714343],
              [117.434418314155664, 49.626654096593541],
              [117.354700212126431, 49.624718044592669],
              [117.318327278470861, 49.624164168429274],
              [117.287491262430535, 49.626654096593541],
              [117.259427309416424, 49.63110019332629],
              [117.2347092445209, 49.636109051039909],
              [117.209991347263554, 49.641936150143806],
              [117.169982344000573, 49.652491145565989],
              [116.98304511554602, 49.709436119518443],
              [116.96138225436664, 49.716382202699265],
              [116.923600322783727, 49.7330450909825],
              [116.752209177830196, 49.809436080356306],
              [116.720827164615685, 49.824164257743121],
              [116.711382268452894, 49.830464263825434],
              [116.700827273030882, 49.84430010379765],
              [116.616854181661324, 49.929018178723425],
              [116.499418185789182, 49.950827052656507],
              [116.45206328256279, 49.959854027125573],
              [116.42499124397284, 49.967491114406187],
              [116.352763378280088, 49.991100085870414],
              [116.290818263793312, 50.013882098764725],
              [116.246091254857674, 50.027491124436608],
              [116.216663398554942, 50.028327135461666],
              [116.15054526974177, 50.021100090885511],
              [116.097763419470084, 50.010409140993431],
              [116.069718241918963, 50.002773227179262],
              [116.049427163017072, 49.996100058766388],
              [115.887209340562549, 49.936936059761194],
              [115.818600277756559, 49.908882165030718],
              [115.788036170656028, 49.8955450482988],
              [115.761791258274002, 49.886382119359837],
              [115.729982270910455, 49.880818044378486],
              [115.673863417337429, 49.888882105807909],
              [115.63471825301815, 49.899164186462031],
              [115.609418316402525, 49.904436068297969],
              [115.579436248660272, 49.907209137152165],
              [115.555400303047236, 49.904300113828072],
              [115.522000262818324, 49.895273139359006],
              [115.414700334846657, 49.898327001970529],
              [115.389991322406559, 49.903882192134574],
              [115.260536180261511, 49.966100053751461],
              [115.213309184877858, 49.993327157550368],
              [115.09499124515105, 50.081109153371244],
              [115.052063327918063, 50.129854111424152],
              [115.034145165811992, 50.145135997336411],
              [115.010827214026904, 50.157491090289568],
              [114.880809310900872, 50.217764182726285],
              [114.854154188176267, 50.228327057137335],
              [114.748600210640802, 50.252773213093064],
              [114.665954142185797, 50.264436128479929],
              [114.622754148375179, 50.260827048600731],
              [114.579163390236999, 50.250000144238697],
              [114.555254179000372, 50.246100212318368],
              [114.49944512056959, 50.241382206645824],
              [114.443863379353388, 50.245273253748849],
              [114.421654185723469, 50.251936028601563],
              [114.396100277441064, 50.262773158885352],
              [114.377472335771927, 50.271382211660921],
              [114.349427158220806, 50.281245029517038],
              [114.322482356921682, 50.284300065594834],
              [114.237200347548651, 50.268054093176659],
              [114.195254118818411, 50.255827075704588],
              [114.17637237312033, 50.248327116360144],
              [114.011936196165038, 50.174164204494787],
              [113.989436150494157, 50.161936013556044],
              [113.965254192127418, 50.147354016560882],
              [113.932482291724341, 50.123882173033053],
              [113.896382272837059, 50.099718151939328],
              [113.872063354172155, 50.087073212773532],
              [113.829572301733947, 50.086245080737342],
              [113.78505416982614, 50.086800130367237],
              [113.709718294427034, 50.061664143778387],
              [113.586109194486994, 50.016391137668279],
              [113.556927263223884, 50.00193604268776],
              [113.484218276287379, 49.963600067303219],
              [113.460254247403668, 49.935891171708477],
              [113.357482223539932, 49.888600138584309],
              [113.239154225529319, 49.831382082225787],
              [113.211518252492397, 49.815682106981967],
              [113.186772359678343, 49.794300207197921],
              [113.098109258193176, 49.670200095368386],
              [113.096163315546505, 49.603673097317198],
              [112.831382346255737, 49.518327050203766],
              [112.803036258379848, 49.509718165066204],
              [112.774300244366941, 49.502353992553878],
              [112.735809203773329, 49.497909069287402],
              [112.710536256886257, 49.498882208248858],
              [112.687482335051754, 49.50485397900195],
              [112.654709261182461, 49.518053967797471],
              [112.590818204049015, 49.528882213263955],
              [112.536654177939909, 49.536382172608171],
              [112.491700354703767, 49.541382145504542],
              [112.41609122926036, 49.518327050203766],
              [112.170536166613033, 49.447491083355999],
              [112.131372394468599, 49.436936087933759],
              [111.963882354901727, 49.406936082918662],
              [111.921918188898644, 49.402218077245948],
              [111.868591347281097, 49.398882079772818],
              [111.840818246307776, 49.398045062919493],
              [111.799427234845439, 49.399991173204114],
              [111.742200293669214, 49.40526422850661],
              [111.691509225331629, 49.406936082918662],
              [111.645263415536533, 49.398327030143093],
              [111.593318246842273, 49.37915409712781],
              [111.570545118764926, 49.367491181740945],
              [111.544145141173885, 49.358191124865584],
              [111.506372262046057, 49.350127063435934],
              [111.477482356290523, 49.350000161421519],
              [111.452209241765274, 49.354436032232456],
              [111.326927281585029, 49.342491149621821],
              [111.173027156950582, 49.304709050400845],
              [111.128454207395862, 49.287500164943197],
              [111.067754140810507, 49.253327145601475],
              [111.033545246922927, 49.232136017934067],
              [111.008745207014101, 49.214300166117084],
              [110.981091296704562, 49.200827094915326],
              [110.955554152228501, 49.191100063891099],
              [110.928863322596158, 49.18220904389193],
              [110.788591334373052, 49.149436137660643],
              [110.737491229159161, 49.143609038556576],
              [110.700272393832904, 49.1422181455398],
              [110.662491300440252, 49.145827057781219],
              [110.630254165461224, 49.15249117373844],
              [110.48193622071949, 49.20000013634575],
              [110.352200284817116, 49.213609162017462],
              [110.32458224905298, 49.198318056011715],
              [110.294145211604871, 49.184991165201609],
              [110.268336158189413, 49.175000104226626],
              [110.245263293253799, 49.170554007493877],
              [110.208036243662377, 49.169154062021619],
              [110.170254144441401, 49.172491065323243],
              [110.088318190825305, 49.188327162674909],
              [109.801936218800847, 49.219153958621575],
              [109.611100231892408, 49.229718174137247],
              [109.581382361738918, 49.229991088905365],
              [109.553454195556526, 49.231518104030215],
              [109.51707237708365, 49.242218106377663],
              [109.4964451838635, 49.260409183252193],
              [109.344845221105118, 49.339153978682191],
              [109.313309316147809, 49.34638219672479],
              [109.19762714970102, 49.359854094460331],
              [109.153454184566783, 49.349018137642815],
              [109.075000241177747, 49.334436140647654],
              [109.026791222015277, 49.338327020112672],
              [108.984154156823365, 49.351936045784385],
              [108.959300305096122, 49.356518096987031],
              [108.711927207644976, 49.338045052889072],
              [108.650795221587487, 49.331700119805589],
              [108.601091289989597, 49.331664245259958],
              [108.563463417787318, 49.334854062341208],
              [108.527763383320746, 49.345754056899125],
              [108.363727190786108, 49.441800106360006],
              [108.298600305845298, 49.493327018084813],
              [108.169436183379162, 49.55138209129683],
              [108.1375002940012, 49.55804503378755],
              [108.113309283178978, 49.56944509269033],
              [108.071927156533945, 49.589991149087496],
              [108.041927151518792, 49.610827051697356],
              [107.948582254182668, 49.682209183357543],
              [107.975809190343568, 49.788891197425585],
              [107.984427295574619, 49.928936036072173],
              [107.905818286976341, 49.944709101511705],
              [107.816509276030615, 49.94888211583833],
              [107.753591357858625, 49.979545129396627],
              [107.737763307133946, 49.98027318150838],
              [107.551372411129989, 49.98276411550097],
              [107.462772341556672, 49.982345020341143],
              [107.254854203567049, 50.003191148872759],
              [107.17609130322586, 50.026936074806713],
              [107.080827285333555, 50.083191218125819],
              [107.063600294964942, 50.108609171938497],
              [107.051936206111634, 50.131382132377496],
              [107.007482279582234, 50.199154010691757],
              [106.825818274068126, 50.294153998633647],
              [106.78818235523886, 50.310544977977031],
              [106.760818291141561, 50.319991215244102],
              [106.737763363478962, 50.325554116759008],
              [106.663036181890163, 50.338600213811958],
              [106.627472269531495, 50.343044969440257],
              [106.599427259618551, 50.345545123526506],
              [106.54694514620536, 50.346100173156231],
              [106.486927198902151, 50.338327131405663],
              [106.43552718919193, 50.331664188914772],
              [106.335536280809606, 50.316100168141077],
              [106.298600250897096, 50.306382189572389],
              [106.274563299455735, 50.298809140032006],
              [106.244991274417941, 50.300000208476604],
              [106.074291301202095, 50.345818038294624],
              [106.069427282776019, 50.37526416714627],
              [106.051927210001111, 50.386936134988844],
              [106.025954206558879, 50.400544993022379],
              [105.989154131116493, 50.410545106452901],
              [105.807472355967718, 50.435964233731852],
              [105.728318188385344, 50.43082713289931],
              [105.52221825283749, 50.45443610436331],
              [105.361363327843094, 50.483045048723682],
              [105.335536169516445, 50.483609150809059],
              [105.312491300137594, 50.481518033599002],
              [105.283045171285892, 50.473045102931394],
              [105.248182321310821, 50.451936117915238],
              [105.209154168360158, 50.429991121874252],
              [105.180545223999786, 50.414709068323987],
              [105.154427213632005, 50.40416413118578],
              [105.132200250367589, 50.398327141436084],
              [105.099718196177037, 50.392218075108644],
              [105.059291271220616, 50.39208212063869],
              [105.024145112917438, 50.396664171841337],
              [104.998754148833314, 50.407845127794474],
              [104.966663361884116, 50.404836024546114],
              [104.709427278938676, 50.359118107014353],
              [104.676372405483789, 50.346609122318512],
              [104.651791300886657, 50.328573110653167],
              [104.62080021963726, 50.317982240685296],
              [104.564700309165346, 50.317664231278059],
              [104.496600195521694, 50.320436126665754],
              [104.450254138164098, 50.316036130400619],
              [104.41817240367061, 50.309173195699373],
              [104.392209290874064, 50.298618032639183],
              [104.293382293569778, 50.238209153370747],
              [104.27151826671377, 50.20837309838214],
              [104.254627223025409, 50.188718038295121],
              [104.11775409654922, 50.153500130900511],
              [104.093036199291873, 50.148664275669034],
              [104.063309276683015, 50.14709116007657],
              [104.030536202813551, 50.149244973922578],
              [103.935809297277956, 50.171636054851888],
              [103.91054523520819, 50.18003606296179],
              [103.856091362886502, 50.197764123503305],
              [103.825682320995384, 50.201245127901814],
              [103.803318229794229, 50.198464180058636],
              [103.771518294886164, 50.184853980920423],
              [103.75345412002622, 50.166153954883782],
              [103.734845121458193, 50.1467642658518],
              [103.706218239825148, 50.137527073250624],
              [103.648882333907522, 50.137354070768595],
              [103.607345142053305, 50.147500196952763],
              [103.531372409649123, 50.175554091683239],
              [103.33748222485309, 50.196382115304061],
              [103.316654201232268, 50.199436145553534],
              [103.280191245936578, 50.222000061326696],
              [103.265409256731118, 50.258045094929003],
              [103.254227294949715, 50.2930450728403],
              [103.223600323575226, 50.308600208796861],
              [103.198318156594667, 50.312491088261709],
              [103.157491247217479, 50.312764170668004],
              [103.091663299893071, 50.306654098512013],
              [103.043591241028821, 50.30360006826254],
              [103.002782268924534, 50.302773109692964],
              [102.96692716924872, 50.306382189572389],
              [102.941936189585249, 50.309991101813637],
              [102.918591248072005, 50.315273209571558],
              [102.891236236430188, 50.324300184040453],
              [102.849354213078357, 50.351109198507856],
              [102.818309320010542, 50.371382172498784],
              [102.779709314675188, 50.387218102212444],
              [102.748872292806595, 50.394436094333059],
              [102.672209226854932, 50.404991089755299],
              [102.626791213819672, 50.409154045798118],
              [102.606027227939308, 50.424153964486607],
              [102.595254135395919, 50.452773134768734],
              [102.540954154816973, 50.509027104621566],
              [102.486782249718715, 50.531664110590668],
              [102.453045256981284, 50.536664251124989],
              [102.381163396252532, 50.543664146124286],
              [102.373872313935976, 50.545545045202175],
              [102.353591293317834, 50.552773095606597],
              [102.332209225895781, 50.565827071648584],
              [102.312191229400014, 50.593609057439267],
              [102.296500306611733, 50.624154053800453],
              [102.294845216005967, 50.645409051570141],
              [102.307763405216235, 50.663609180900153],
              [102.342627261019629, 50.694854066178323],
              [102.355191231000447, 50.720273193457274],
              [102.339709185240054, 50.750691120165925],
              [102.276518184661711, 50.781109046874576],
              [102.235536210075395, 50.820545063234931],
              [102.232200212602436, 50.85110011787998],
              [102.262082368058486, 50.888600082239293],
              [102.254854150015888, 50.987000105394543],
              [102.228045135548655, 51.015545012014456],
              [102.215263403722645, 51.03096419350112],
              [102.18665412408626, 51.076945135155142],
              [102.168254170184071, 51.120427096189587],
              [102.157627258032392, 51.247700093655965],
              [102.195818226491554, 51.27846419296668],
              [102.224991272937132, 51.306382133227203],
              [102.223591327464874, 51.326591069477843],
              [102.082072279683587, 51.388882188928505],
              [102.048600322725463, 51.391109092970282],
              [102.023463330308289, 51.391654084316372],
              [101.992754216282378, 51.395545131419226],
              [101.867200179523991, 51.419445122562536],
              [101.77665416730423, 51.44860006409715],
              [101.740809293550399, 51.459164111974872],
              [101.601091349128581, 51.484164144093825],
              [101.575272405067096, 51.487773223973193],
              [101.533863288693738, 51.486109080912172],
              [101.508327150046171, 51.481654099361947],
              [101.44790033350489, 51.464991211078711],
              [101.408600271614432, 51.452082241962103],
              [101.385536123858344, 51.451936061570223],
              [101.347627290260789, 51.463744989711188],
              [101.315400213565681, 51.490409164891219],
              [101.287836157258198, 51.513327132255597],
              [101.24470925364335, 51.529164235435587],
              [101.209709275731882, 51.534164208331731],
              [101.12387238436736, 51.550827096614967],
              [101.03803616355475, 51.576100211140215],
              [100.913036170597763, 51.616664264033034],
              [100.892209320443442, 51.623600121292043],
              [100.78414513053815, 51.662209179082708],
              [100.694136230675099, 51.698873132417248],
              [100.55748237478673, 51.734991088577146],
              [100.525818226710385, 51.738045118826619],
              [100.493591317653284, 51.737209107801618],
              [100.42637230967398, 51.732209134905474],
              [100.333327149196236, 51.730544991844226],
              [100.252782256235093, 51.73165408527575],
              [100.021936260235464, 51.739573139779964],
              [99.978182222623218, 51.744709067145834],
              [99.946927279061072, 51.75139112037607],
              [99.919709227717476, 51.760554049315033],
              [99.887763280055708, 51.777354065534439],
              [99.854427277567481, 51.800545115305113],
              [99.810254144795238, 51.833882123621834],
              [99.757491237624777, 51.875545044024022],
              [99.728591273585437, 51.890545130350688],
              [99.697482342777164, 51.897354085595282],
              [99.615263416109201, 51.89596419840683],
              [99.570827259214639, 51.900545076142976],
              [99.344982241939306, 51.942218054829141],
              [99.317136218408223, 51.951027099814837],
              [99.293318202278328, 51.969718073396166],
              [99.140827243209799, 52.027218096978345],
              [99.02019120433431, 52.06527311096778],
              [98.941891320326079, 52.140682244200661],
              [98.925954137221879, 52.142773193772712],
              [98.899572264541462, 52.132218030712522],
              [98.879700280799739, 52.111664262964268],
              [98.873027280024814, 52.090264258269201],
              [98.873736221397451, 52.064718061337885],
              [98.871091228024, 52.044018113197922],
              [98.806091245097605, 51.941654120381884],
              [98.765272382347462, 51.884436064023191],
              [98.726091343482381, 51.843318134966921],
              [98.708036221077919, 51.828045133872195],
              [98.688872340518117, 51.813327014769129],
              [98.66818228302418, 51.79985411120532],
              [98.63123619482792, 51.782491166366981],
              [98.599154125058249, 51.774991207022765],
              [98.509154110012901, 51.761109098944928],
              [98.393600186684807, 51.736936025395494],
              [98.36942728077355, 51.730818074250692],
              [98.3383181823271, 51.719573248195161],
              [98.312482306821096, 51.699436061035954],
              [98.247200189032924, 51.578473127935695],
              [98.248600302143359, 51.555827069511281],
              [98.25984512819872, 51.528327050944085],
              [98.257627276612425, 51.507773115557825],
              [98.235609190375328, 51.465500160240992],
              [98.203318243577939, 51.460964209505789],
              [98.172763356570954, 51.467491197526783],
              [98.137927161048026, 51.47951805515072],
              [98.110354219923238, 51.47951805515072],
              [98.085936227162279, 51.47420912530265],
              [98.058082324642157, 51.462745028659469],
              [97.997754246920692, 51.37360013537824],
              [97.954709318318919, 51.252627143994175],
              [97.956518300667369, 51.218318170182727],
              [97.908872377761838, 51.119436019955344],
              [97.878936242848965, 51.062218131234772],
              [97.850682355908361, 51.029300050439872],
              [97.830554221204466, 50.999300213062781],
              [97.868800342586724, 50.937764135452198],
              [97.898600187753516, 50.919991147909343],
              [97.94830923231234, 50.905682233320533],
              [98.009436273046902, 50.853291147376126],
              [97.96811818414227, 50.799436078218832],
              [97.967072290261115, 50.773044985445097],
              [98.051372275855329, 50.643327154453743],
              [98.065536183518816, 50.628600150533202],
              [98.166027156246116, 50.569718118751609],
              [98.192900208453807, 50.565964199584982],
              [98.223309250345096, 50.566382121278309],
              [98.25110028859126, 50.564991228261533],
              [98.282900223499325, 50.555827125856297],
              [98.304700212615046, 50.542218100184584],
              [98.318318290742468, 50.527073174570489],
              [98.32596342465007, 50.507500089496716],
              [98.328354111080444, 50.47117325630893],
              [98.293245168427575, 50.302836141604928],
              [98.199009274781417, 50.16180005908609],
              [98.134718233227318, 50.077773155898171],
              [98.119427294859747, 50.060818074469523],
              [98.101791267615113, 50.0456910861285],
              [98.076382366258144, 50.031664138763063],
              [97.890827146003005, 49.955554110784533],
              [97.812191315314578, 49.959436105432076],
              [97.789845161386438, 49.968318072975933],
              [97.769572355033461, 49.967209147182587],
              [97.740263354117985, 49.958600094407018],
              [97.719145149008568, 49.951664237148009],
              [97.606509301460221, 49.876518128037446],
              [97.596645142499654, 49.855200098355624],
              [97.565409309676966, 49.830273156432611],
              [97.484145249421431, 49.787500136770632],
              [97.348454138934301, 49.736518048753794],
              [97.307900311963465, 49.73096419969437],
              [97.277482217616807, 49.733609193067878],
              [97.204709192939731, 49.751664147834333],
              [97.172618238352527, 49.760418039896933],
              [97.169027263384123, 49.761991155489397],
              [97.148600230012335, 49.796936148115719],
              [97.007482340118202, 49.889227104943643],
              [96.801654145872192, 49.914991063720208],
              [96.741363283800808, 49.92082704764141],
              [96.717900325090341, 49.920545080417753],
              [96.697345216237807, 49.913182081371758],
              [96.654163327337983, 49.882209105033212],
              [96.614009317149623, 49.863609158920838],
              [96.590263385387232, 49.866800149468531],
              [96.576936159300942, 49.889718116833137],
              [96.568036254484468, 49.912082208034121],
              [96.543727226465137, 49.926653979107073],
              [96.51830927265263, 49.930309159454112],
              [96.487482309068014, 49.926245109869058],
              [96.445263333207436, 49.908600197807118],
              [96.406791235714991, 49.895136011422608],
              [96.383463393284217, 49.893191074604431],
              [96.358600321463484, 49.899991145031549],
              [96.334427247914221, 49.910827101849065],
              [96.309982265424992, 49.929164191477128],
              [96.296791329084783, 49.944709101511705],
              [96.274700320289924, 49.953609173966356],
              [96.211654158999067, 49.97192715285513],
              [96.106645172809749, 50.00193604268776],
              [96.068109205214796, 50.005764225516828],
              [96.034709164985941, 49.991382220732021],
              [96.015827251649966, 49.976936010568807],
              [95.986591340930431, 49.951800191617906],
              [95.956518245719394, 49.944991068735305],
              [95.935118241024156, 49.961800137410421],
              [95.924845212825687, 49.979573124953276],
              [95.911518154377632, 50.009991219299877],
              [95.887500313675389, 50.021100090885511],
              [95.864009191770265, 50.025127092458263],
              [95.825963397874489, 50.023464122863686],
              [95.804427271071518, 50.016527092138233],
              [95.785545190097366, 50.00208222307964],
              [95.767345228405532, 49.977764142604826],
              [95.747554213848389, 49.960545031225365],
              [95.524154197736323, 49.895336003632963],
              [95.491572398897858, 49.907700149041659],
              [95.43858234215088, 49.949854081333285],
              [95.414018168998098, 49.956936118984004],
              [95.34915414054143, 49.958600094407018],
              [95.264163318485714, 49.959718072655676],
              [95.225536155784027, 49.959436105432076],
              [95.204709305629706, 49.958045044777123],
              [95.155818167184918, 49.957218086207604],
              [95.097345172279347, 49.9565180296525],
              [95.064491296863082, 49.964364161598951],
              [95.012345130130143, 50.023036142886383],
              [94.999709243419659, 50.037500122684435],
              [94.980263395636484, 50.052073234862064],
              [94.819845167798519, 50.059991115899834],
              [94.781100323176133, 50.056518158128313],
              [94.716654216412792, 50.035418057929689],
              [94.664700330539176, 50.025964109311758],
              [94.629009180890137, 50.027218042030313],
              [94.573882241741444, 50.088600060260205],
              [94.527209290159306, 50.147636151422489],
              [94.504845198958151, 50.167009076648128],
              [94.430954196032587, 50.18665407845134],
              [94.399991277978017, 50.203609159879989],
              [94.386936128469586, 50.219573165074564],
              [94.373309165525029, 50.246382179541968],
              [94.357345160330453, 50.311382162468362],
              [94.354427252188884, 50.346654049319625],
              [94.356372356645238, 50.382491211722424],
              [94.35220018050893, 50.414991203185764],
              [94.301654119096554, 50.548745088205408],
              [94.283736124628319, 50.564854100325363],
              [94.244427177920556, 50.575554102672811],
              [94.218872263809828, 50.57777312772555],
              [93.983045238014995, 50.585264202252461],
              [93.895263409832069, 50.584164161276647],
              [93.73595410778745, 50.5793001428504],
              [93.578045254129279, 50.590273227604143],
              [93.480545112101481, 50.617209144085962],
              [93.450809304675147, 50.622073162512379],
              [93.409145210806344, 50.619709130534034],
              [93.384154231142873, 50.614164166291971],
              [93.341509286961752, 50.604745086391176],
              [93.142209254203237, 50.596936002456857],
              [93.112763292989484, 50.596100159069977],
              [93.088882244947513, 50.599718123766706],
              [93.026518202938917, 50.610827162990347],
              [92.970782234704075, 50.670882158305744],
              [92.973318263335784, 50.692073118334918],
              [92.998591210223026, 50.74860905541135],
              [92.994354158155943, 50.772491109281646],
              [92.967763408447865, 50.793054097123218],
              [92.933172299774469, 50.799582090972706],
              [92.812627288367878, 50.794582118076335],
              [92.789363316039442, 50.780618034985082],
              [92.779154157943083, 50.74061808417764],
              [92.762491269660075, 50.714300081599731],
              [92.73650921376219, 50.697345000170912],
              [92.698863404287124, 50.68305402285506],
              [92.669982215710945, 50.681382168443008],
              [92.640545139314781, 50.688327078157329],
              [92.470263423068388, 50.787082158732119],
              [92.447754157304018, 50.810818032210761],
              [92.322772269257996, 50.814991214175336],
              [92.31720920010514, 50.790691070973537],
              [92.295391273716348, 50.754991204144915],
              [92.264163319882698, 50.727073096246386],
              [92.221372362947932, 50.705554068525942],
              [92.190263264501482, 50.697209045701015],
              [92.162200317315524, 50.693045083830043],
              [92.111927170671493, 50.690545097381801],
              [92.085263330767475, 50.69165402317509],
              [92.038318302607536, 50.697909102256119],
              [92.007218256616397, 50.705273107130608],
              [91.952209167026638, 50.714436036069628],
              [91.905545100261861, 50.714718170931235],
              [91.884718250107483, 50.713054027869987],
              [91.819718267181088, 50.703327164483994],
              [91.779854103205423, 50.692345027274769],
              [91.723872377568568, 50.672218066037544],
              [91.697627129910416, 50.660545092366647],
              [91.668872340434518, 50.644154113023262],
              [91.653182255836555, 50.631100136981274],
              [91.634154162108644, 50.595545109440081],
              [91.615536111085135, 50.57777312772555],
              [91.458036126665093, 50.507773171903011],
              [91.351091256112738, 50.476100139009361],
              [91.159709272029943, 50.433873116521795],
              [91.128582236310478, 50.43110910012291],
              [91.101091270199106, 50.431664149752805],
              [91.039563406853574, 50.434154077916901],
              [91.002491254833387, 50.422491162530036],
              [90.979709241938849, 50.409991062651443],
              [90.959154133086315, 50.39610006975613],
              [90.926091213004042, 50.367491125395759],
              [90.893054109183936, 50.339154089975409],
              [90.723600189697606, 50.231100125991702],
              [90.698591272761291, 50.219436037138166],
              [90.673309273418738, 50.213882188078571],
              [90.643327205676428, 50.211936077793951],
              [90.612900226512295, 50.215691170427021],
              [90.557754176624314, 50.226100153095558],
              [90.530545177736258, 50.226382120319158],
              [90.5097181599441, 50.224436010034538],
              [90.378582278569297, 50.185818067426339],
              [90.338463304736479, 50.169854062231764],
              [90.304554147707336, 50.150545007108747],
              [90.277209194349496, 50.134164253687175],
              [90.244427235662499, 50.120273260791862],
              [90.219145236319946, 50.11471807062776],
              [90.179154170329809, 50.110545056301135],
              [90.103591312992194, 50.098873256096795],
              [90.071927164915849, 50.090544997078382],
              [90.048600328313455, 50.080964146446036],
              [90.031927214108237, 50.068191131799381],
              [90.022627157232876, 50.046109175460003],
              [90.02110030974643, 49.996382193628165],
              [90.005691186543572, 49.980909032685076],
              [89.981372267878442, 49.971100194285611],
              [89.900272325287574, 49.948045098984835],
              [89.871091232214951, 49.946654038329882],
              [89.838045243577369, 49.949718126863388],
              [89.807482309943339, 49.951244974350004],
              [89.762772400090341, 49.946654038329882],
              [89.726382199714294, 49.940544972002613],
              [89.701382335233518, 49.934991122943018],
              [89.673036247357459, 49.924709042288896],
              [89.650818168910178, 49.911936027642241],
              [89.642700295662223, 49.904827000263197],
              [89.637218195693947, 49.858327051162917],
              [89.659009299992192, 49.788182088414942],
              [89.693454228273765, 49.775554080693666],
              [89.721645250940583, 49.764300202182596],
              [89.735463321278189, 49.745754067888697],
              [89.72200030836018, 49.722418178830821],
              [89.62692723022252, 49.684300132929593],
              [89.589982315492875, 49.680827007520065],
              [89.564991335829234, 49.678182181784621],
              [89.456936198379253, 49.648464143993124],
              [89.442200309641578, 49.627491113446865],
              [89.403045254676499, 49.591654118682072],
              [89.373109287401689, 49.582073100411776],
              [89.319845142419922, 49.60971812590401],
              [89.285536168608473, 49.632491086343236],
              [89.252972307042626, 49.642982211662968],
              [89.22331813935358, 49.636936009609485],
              [89.204145206338296, 49.622491140550892],
              [89.201027138348365, 49.601382155534793],
              [89.233382290524332, 49.57020013453058],
              [89.236445205591338, 49.545964196707189],
              [89.208600187888692, 49.520691082181941],
              [89.188591243848407, 49.509582210596307],
              [89.150545114676447, 49.498327158618963],
              [89.00819122933683, 49.46305409830137],
              [88.987409306183451, 49.468882203233534],
              [88.950127271307281, 49.518609185065372],
              [88.924563304741071, 49.546591163066353],
              [88.886445091201665, 49.539718170081301],
              [88.877472263827258, 49.478254009200384],
              [88.902827185727745, 49.449991069804071],
              [88.851654157956091, 49.441654093606303],
              [88.730818294508367, 49.450827080829072],
              [88.568882271639268, 49.475818060492543],
              [88.407763316693888, 49.483882121922193],
              [88.346372246008684, 49.48220909404364],
              [88.220827261705779, 49.461664211112918],
              [88.202063365566914, 49.452764138658438],
              [88.179427197787959, 49.434153966624081],
              [88.133527225318858, 49.369364201829683],
              [88.158872256573432, 49.310545034322047],
              [88.173382336839097, 49.287218030081419],
              [88.156645184893421, 49.268882113919858],
              [87.992282265772189, 49.179854064369238],
              [87.8880452528673, 49.162491119530728],
              [87.857482319233441, 49.167218177658981],
              [87.840700240286651, 49.17295408165586],
              [87.836109304266472, 49.173327076348244],
              [87.807482254995477, 49.171382139529896],
              [87.705263269104535, 49.163044995694179],
              [87.519291300622342, 49.117218113420847],
              [87.507345244545377, 49.099164164482886],
              [87.466936256861743, 49.079718149061478],
              [87.446636125504369, 49.077491077381524],
              [87.412200249678278, 49.078327088406581],
              [87.373872320920725, 49.084991204363973],
              [87.3482092802588, 49.092618065722434],
              [87.340272288481742, 49.101109101301063],
              [87.324991240760141, 49.128327152644488],
              [87.313872310890702, 49.167218177658981],
              [87.305327128217414, 49.201664111768821],
              [87.265545106892944, 49.228044978620744],
              [87.243318143628358, 49.238327059274809],
              [87.210263270173243, 49.247073239986776],
              [87.168591297315572, 49.250000200583713],
              [87.11220019952674, 49.250545024291625],
              [87.040263353512671, 49.271936144169331],
              [86.926654199364975, 49.353054024033213],
              [86.839009163842263, 49.454718127931926],
              [86.841018138400898, 49.482627015737194],
              [86.849918210855549, 49.513264213033437],
              [86.743600306662358, 49.564164158399024],
              [86.714427260216951, 49.553745117446738],
              [86.683318161770558, 49.551936135098288],
              [86.649645206773585, 49.560273111295828],
              [86.620818165292235, 49.583045065906731],
              [86.615536225172264, 49.60596420673761],
              [86.640272394978638, 49.633045130144637],
              [86.694700283400437, 49.672909126482182],
              [86.747754210249866, 49.673882097805574],
              [86.794572336395618, 49.69818224100743],
              [86.78193611440895, 49.784091216739569],
              [86.661927209530802, 49.802491170641986],
              [86.623454106210033, 49.799018045232287],
              [86.533054106743805, 49.727482189467423],
              [86.506863341456437, 49.688536011530175],
              [86.445254173649914, 49.645827029608654],
              [86.413363378911242, 49.631000113402195],
              [86.373918142457569, 49.620618120461984],
              [86.338172342799339, 49.616582234071757],
              [86.287491332745617, 49.585818134761098],
              [86.276382293521806, 49.564436067338647],
              [86.2499912007479, 49.507773228248027],
              [86.229427207078061, 49.488882094818507],
              [86.184072393592714, 49.476654071517771],
              [85.959154079535153, 49.538327109426575],
              [85.806636130738298, 49.564154100115218],
              [85.746372258395468, 49.579718120888913],
              [85.657763304004675, 49.608327065249284],
              [85.553872296063957, 49.595127076453593],
              [85.528182265673536, 49.590964120410945],
              [85.50416325150502, 49.590273116311153],
              [85.465263341673221, 49.597627062901665],
              [85.420191333601963, 49.620818112672168],
              [85.355545234628494, 49.610273175533962],
              [85.324291296895012, 49.595544998147091],
              [85.295682352534641, 49.583045065906731],
              [85.259227276227989, 49.592909057229122],
              [85.212145120131481, 49.649991159117747],
              [85.215263355759589, 49.699645050753816],
              [85.197482321589746, 49.7333270582061],
              [85.080691229349441, 49.88332708328204],
              [85.051782212854846, 49.892354057751106],
              [84.989082224166168, 49.905336117063484],
              [84.974291182504999, 49.927773130822231],
              [84.979154195102922, 49.997773254283118],
              [84.999709303955626, 50.003054020936418],
              [85.05581826688308, 50.012700082775666],
              [85.011518232096421, 50.077636027961944],
              [84.982754222526864, 50.088327145492087],
              [84.810527234186281, 50.144153973557707],
              [84.69552718702198, 50.177218067106253],
              [84.521054183900162, 50.216782158947524],
              [84.377200273164021, 50.212700172089683],
              [84.26540915382003, 50.272773104677867],
              [84.198527265987423, 50.4495090540251],
              [84.207000196654917, 50.474227118920453],
              [84.22415409682759, 50.506935987411509],
              [84.16900922040611, 50.598045095888381],
              [84.146945201339577, 50.615827135886718],
              [84.126082309001561, 50.631382104205045],
              [84.106218204248478, 50.642964218045165],
              [84.041091319307668, 50.671382222650493],
              [84.0013912729965, 50.692491207666649],
              [83.974572367883468, 50.711518127927832],
              [83.966382242629948, 50.730618138385353],
              [83.978318240422936, 50.771382183488527],
              [83.977827228533613, 50.795064245148467],
              [83.839572261496187, 50.879154012610229],
              [83.806645128245805, 50.886518017484775],
              [83.468318155165207, 50.989300099632487],
              [83.250272331178337, 51.002773170834246],
              [83.138309215180698, 50.999509090090442],
              [83.103327174542414, 50.952491139372739],
              [83.093245086098477, 50.931727153492375],
              [83.056645170504737, 50.90707312633748],
              [83.014854174621519, 50.891454120278638],
              [82.92276337764207, 50.892218046936023],
              [82.864991277481977, 50.896945105064276],
              [82.838591299890936, 50.905264143989029],
              [82.789982296307585, 50.918054090080204],
              [82.761109154358451, 50.910818160686574],
              [82.725327144878747, 50.890127097364143],
              [82.723109293292282, 50.846518066677163],
              [82.721509187971662, 50.81388212074404],
              [82.702772281560726, 50.800273095072328],
              [82.672209180288689, 50.78582705254712],
              [82.546645085246666, 50.737209164146805],
              [82.519991303626455, 50.727209050716112],
              [82.498872260326721, 50.721100152026963],
              [82.480809258933277, 50.731800154374241],
              [82.447545173174319, 50.754991204145142],
              [82.356372362233174, 50.759718094634991],
              [82.280191255715522, 50.753327061083894],
              [82.246718292928904, 50.726654001086388],
              [82.215818239148291, 50.713882159906177],
              [82.193036226253639, 50.710273247664986],
              [82.159154226590999, 50.70999111280338],
              [82.12360020487813, 50.714436036069628],
              [82.101509196083441, 50.720127013065394],
              [82.071927280399848, 50.735545021085557],
              [82.014854230966534, 50.769853994897232],
              [81.915545106590287, 50.786945030795891],
              [81.815809175703066, 50.771936059651921],
              [81.731091268415469, 50.751800213597051],
              [81.67859121772949, 50.741100211249602],
              [81.638318184515583, 50.737500183825716],
              [81.595400325566743, 50.735727076023068],
              [81.582218274043839, 50.736109123170934],
              [81.53803625645412, 50.737773098594005],
              [81.463882229406067, 50.743191160821652],
              [81.439218143967366, 50.768745069104057],
              [81.468045185448887, 50.816100139968682],
              [81.453327233984055, 50.877773177877486],
              [81.420118133510016, 50.966664267129659],
              [81.327772359035293, 50.969435994879404],
              [81.297209257763086, 50.9697090772857],
              [81.270827217444889, 50.965000124068638],
              [81.250000199652391, 50.958600205700236],
              [81.228045145327428, 50.952218056966444],
              [81.1885911916944, 50.944991180028524],
              [81.074854129703738, 50.951418088125081],
              [81.081663252586338, 50.987527159467845],
              [81.095263393440746, 51.024327067272054],
              [81.108872251474452, 51.050282133441613],
              [81.137082217242437, 51.094854077167895],
              [81.153318131376807, 51.11277324510263],
              [81.165400309561846, 51.130691071932688],
              [81.17803619627216, 51.164436111297107],
              [81.179427256926942, 51.193118145853248],
              [81.134282326297921, 51.203191181841476],
              [81.094709182001338, 51.181753961496327],
              [81.040836175571201, 51.181436119727039],
              [80.942645197081617, 51.219264151777622],
              [80.872254141655731, 51.259036114818116],
              [80.819763311063411, 51.276009133519892],
              [80.759091240034309, 51.29535406318854],
              [80.705336250801452, 51.310245017135628],
              [80.685054224354872, 51.312636038842072],
              [80.658400275096824, 51.288082091611273],
              [80.635654136747974, 51.251782080513806],
              [80.638218160936503, 51.221145050855569],
              [80.608982250216968, 51.212554102990794],
              [80.583163306155484, 51.212309183779496],
              [80.488709147750001, 51.217109164465114],
              [80.443927153529387, 51.199145069529436],
              [80.436372376538372, 51.111382184447677],
              [80.476382217991443, 51.083573208928726],
              [80.487345244461551, 51.05100012726956],
              [80.487900294091446, 51.021282089478063],
              [80.482336219109925, 50.995591220897438],
              [80.464782334516713, 50.966573239660931],
              [80.427918221333869, 50.944645007426288],
              [80.404309249869641, 50.932718062088441],
              [80.377791255081547, 50.92273605356894],
              [80.344036157433152, 50.921373156108814],
              [80.284054084675745, 50.923054062976348],
              [80.091854194478685, 50.849482242924523],
              [80.067127244765857, 50.799473126231135],
              [80.089336103119649, 50.784764227221558],
              [80.103009166531763, 50.764473148319667],
              [80.081700189305479, 50.757664193075072],
              [80.059054130880895, 50.768364195422691],
              [80.035718241823133, 50.783364114111293],
              [80.016700206378914, 50.796282135683214],
              [79.988591326363689, 50.819573097739976],
              [79.973309272813253, 50.844709084328883],
              [79.960536258166542, 50.873600163550861],
              [79.751100325145785, 51.143609093504779],
              [79.736645230165209, 51.160818146600377],
              [79.680818234461242, 51.232764212707934],
              [79.541509159277609, 51.441664206838141],
              [79.494136151140196, 51.499436139360284],
              [79.36609118038939, 51.646100166962924],
              [79.336927186399294, 51.669154088797256],
              [79.247209306215325, 51.782773133590752],
              [78.923036182514181, 52.176382111029227],
              [78.750827131446499, 52.372491153240105],
              [78.629427165913569, 52.509164119867933],
              [78.46804518684587, 52.686654105226978],
              [78.410809193214334, 52.7466541152574],
              [78.296100165728944, 52.852491065844731],
              [78.073045148752499, 53.075273168053286],
              [78.061918172256014, 53.094436042784366],
              [78.046372256393113, 53.126100190860711],
              [77.982754114027841, 53.194709086028524],
              [77.934982294936333, 53.244991117490201],
              [77.914700268489923, 53.265000061530202],
              [77.890272385083222, 53.286664263814203],
              [77.864991223931099, 53.306100053313628],
              [77.80009132092863, 53.345945106550005],
              [77.754163352902765, 53.373318055464665],
              [77.546936219012764, 53.493882177610544],
              [77.523882297178488, 53.506382109851074],
              [77.405254227033254, 53.561664114208781],
              [77.270263340739632, 53.625827080282022],
              [77.035809270688731, 53.748045119567109],
              [76.914427242428644, 53.799436076822019],
              [76.781936174944917, 53.851936127507997],
              [76.542754208575843, 53.973318155768084],
              [76.522418202672611, 53.99554511903267],
              [76.50602722332934, 54.037836011622517],
              [76.471645159321781, 54.084164131707269],
              [76.448309270264019, 54.109991122395741],
              [76.426091191816397, 54.130545057782001],
              [76.402209305584279, 54.156554103407984],
              [76.443591264591305, 54.171664160304715],
              [76.48081814654455, 54.172764201280359],
              [76.521382199437369, 54.17082714345122],
              [76.542482299635992, 54.168882206633043],
              [76.563872246047254, 54.164709192306418],
              [76.614009270583381, 54.142353985922739],
              [76.636791283477919, 54.137354013026595],
              [76.659154201212573, 54.138045017126217],
              [76.699418181970827, 54.14582711133221],
              [76.724154184139138, 54.155264128505792],
              [76.783600318006165, 54.207773231647309],
              [76.80247234069634, 54.227482103553029],
              [76.859082206158803, 54.30118216672372],
              [76.865745148649694, 54.340273183948341],
              [76.811645160280932, 54.44777310413059],
              [76.699700316832605, 54.444291093903644],
              [76.673591191282298, 54.423745037506592],
              [76.669709196634813, 54.397209105445484],
              [76.659427115980691, 54.370127008571785],
              [76.487763391535054, 54.325554059017009],
              [76.353591249545786, 54.340827060111792],
              [76.238872331414598, 54.35889106733373],
              [76.185527217247909, 54.2955450015466],
              [76.207218241622058, 54.259164188901991],
              [75.88693616502394, 54.175000157777845],
              [75.691363396979625, 54.110273257257518],
              [75.618045213318794, 54.0986001159485],
              [75.573609224062125, 54.097218107749086],
              [75.501100229335975, 54.115273230153718],
              [75.384154071886655, 54.067218102734103],
              [75.441363243427872, 54.010827004944929],
              [75.457218283881048, 53.994154058377944],
              [75.369427235604462, 53.970827054137317],
              [75.190536131306544, 53.88332702553987],
              [75.033872325549567, 53.806654068942635],
              [74.947209146167438, 53.838600184242409],
              [74.81219127014549, 53.844436000525604],
              [74.789427194523626, 53.836382165018108],
              [74.674145180135724, 53.759854047707904],
              [74.646945233703093, 53.688327076760345],
              [74.60860020586324, 53.68888212639024],
              [74.466382274993293, 53.693318164839184],
              [74.438872365780298, 53.609991150568362],
              [74.483600212906254, 53.584436068819628],
              [74.429154219573377, 53.478600124060449],
              [74.376372369301976, 53.471654040879628],
              [74.283872367808328, 53.527491094867059],
              [74.266936229480848, 53.566100152657896],
              [74.246645150578956, 53.602218108817965],
              [74.08137229204641, 53.632209061377637],
              [73.917482279903595, 53.654164115702599],
              [73.775545142790804, 53.617491109912578],
              [73.676927190152412, 53.619436046730925],
              [73.598872225355535, 53.566518074351222],
              [73.581663339898114, 53.519718053116719],
              [73.43719117998171, 53.436100186804765],
              [73.370254139225835, 53.466936035206913],
              [73.410127188019032, 53.521245068241342],
              [73.381654198128558, 53.539164236176077],
              [73.275545170963255, 53.57360916445765],
              [73.25387225150007, 53.579991145553038],
              [73.238591203778128, 53.644436078849878],
              [73.260272337506649, 53.683327103864201],
              [73.3288723478571, 53.694154008226178],
              [73.356372366423955, 53.789718098253104],
              [73.443036216358394, 53.875827066195654],
              [73.508463341072002, 53.882491182152989],
              [73.65359130109448, 53.864154092524927],
              [73.711654085657415, 53.874991222808603],
              [73.737763378845841, 53.969982158294954],
              [73.763882227403769, 54.065545074855606],
              [73.680954191725164, 54.08638215093174],
              [73.611645240002417, 54.077491130932572],
              [73.541654168996843, 54.015273101677678],
              [73.544436122668685, 53.98777325074883],
              [73.545818130868099, 53.959436047690076],
              [73.494427173613246, 53.951382212182409],
              [73.391109152575154, 53.948600090872731],
              [73.290818172058152, 53.953882198630652],
              [73.069445235415742, 53.988882176541949],
              [72.999709141905441, 54.056245017980245],
              [72.99359119076064, 54.116100188723408],
              [72.975263321226066, 54.128327038557529],
              [72.865818128949456, 54.123045098437728],
              [72.729709264407006, 54.13360914631528],
              [72.607891209542402, 54.143464252820365],
              [72.566509250535546, 54.120409157519759],
              [72.546236108906612, 54.102773130275125],
              [72.532472353301728, 54.059418071255095],
              [72.564845107474525, 54.053882159468344],
              [72.598327122716455, 54.059718143389716],
              [72.664636191284671, 54.06290913393741],
              [72.702209245839981, 54.024164121676847],
              [72.717754155874559, 54.000827059152471],
              [72.727691237392889, 53.96450911078233],
              [72.689418126282305, 53.959300093220179],
              [72.550536192885659, 53.960273232181578],
              [72.535263359428882, 53.947209197855784],
              [72.519718281756354, 53.934436015571123],
              [72.482418141969163, 53.912209052306537],
              [72.448591295229448, 53.912764269574438],
              [72.413609254590995, 53.922764215366726],
              [72.394291314650502, 53.929436042674979],
              [72.403045206713443, 53.957218028465604],
              [72.436372324384195, 54.034436144047163],
              [72.486372388622272, 54.11360908709247],
              [72.508882324939009, 54.142773248720744],
              [72.366382259207285, 54.176382165977259],
              [72.321654076805316, 54.186100144545946],
              [72.343872322890775, 54.217764124984114],
              [72.360263302233989, 54.24415404429169],
              [72.344436089699798, 54.26360911216841],
              [72.196927166254625, 54.373882101205027],
              [72.168872265695825, 54.376653996592779],
              [72.049218250599097, 54.380064257728293],
              [72.095409242747451, 54.250591178310401],
              [72.148882264756651, 54.213053998300609],
              [72.169436200143082, 54.209164124664085],
              [72.203663366579462, 54.196173180534231],
              [72.21220921980489, 54.156100139530849],
              [72.193872297814607, 54.138254061792054],
              [72.122209204759145, 54.133045044230073],
              [72.035600172472073, 54.217491210215996],
              [71.958327239243772, 54.247773182454921],
              [71.889436209214182, 54.258045037186889],
              [71.767763328912963, 54.255273141798966],
              [71.735682264971615, 54.219436147034173],
              [71.749145110251447, 54.154435996469772],
              [71.716091242624998, 54.130818140188239],
              [71.653591246146334, 54.109991122395741],
              [71.501100287077975, 54.113327119869041],
              [71.49525407723479, 54.135827165539922],
              [71.484427172872756, 54.186382111769774],
              [71.336382310537147, 54.215000108585457],
              [71.279436163118248, 54.151927125204224],
              [71.257763243655063, 54.126100134515696],
              [71.185527163696861, 54.103327006438576],
              [71.114700249304633, 54.181382138873403],
              [71.066936141563986, 54.193600103890219],
              [71.079436241442522, 54.209991083233604],
              [71.075000202993408, 54.270827104289197],
              [70.997345222617412, 54.280545082857941],
              [70.99650921159224, 54.3322181749744],
              [71.062482333480204, 54.339718134318616],
              [71.086382324623344, 54.339154032233409],
              [71.124982329958698, 54.317500055871335],
              [71.213609221622107, 54.325827141423304],
              [71.221645119856817, 54.410273139771107],
              [71.195818129168288, 54.471373190777342],
              [71.172763369143695, 54.51193607020366],
              [71.165745201595428, 54.599573226737334],
              [71.198318283254537, 54.615545110921119],
              [71.229982263692705, 54.623045070265334],
              [71.26443624442976, 54.628600092791203],
              [71.289427224093231, 54.635827137367357],
              [71.285263262222088, 54.661373166660724],
              [71.278872228670991, 54.690273130700177],
              [71.227482277244633, 54.733882161386987],
              [71.10525417967574, 54.73610906542882],
              [71.013045197861061, 54.797491083658656],
              [70.969218237691052, 54.892009112166363],
              [70.976091230675934, 54.980818058767341],
              [70.988872291949576, 55.00471804991048],
              [70.996372251294019, 55.024164232969838],
              [71.000545097982467, 55.055545072718076],
              [71.001100315250369, 55.079436011405733],
              [70.993736142738044, 55.098673149799424],
              [70.830272266553777, 55.231654055706144],
              [70.808172372941556, 55.246800154786513],
              [70.809427143850428, 55.267700095136604],
              [70.840127205420856, 55.30374512873891],
              [70.802200266912337, 55.318054043327777],
              [70.674709172324924, 55.313045017976151],
              [70.649845094675698, 55.301173225561399],
              [70.619563290075121, 55.285273258107452],
              [70.587345098197375, 55.277491163901402],
              [70.551091187567408, 55.278045040064853],
              [70.517209187904768, 55.285273258107452],
              [70.469982192521172, 55.296664264554522],
              [70.421918180284138, 55.261382151781447],
              [70.288036219783294, 55.187209181632284],
              [70.263882256973318, 55.164436053555107],
              [70.238872334208565, 55.13888214527293],
              [70.169436145194879, 55.160545006452253],
              [70.048327199341088, 55.206935990810791],
              [69.949145144617262, 55.204164263060932],
              [69.880809164217794, 55.263327088599794],
              [69.846100205985238, 55.295000121493501],
              [69.672763286023269, 55.354436029438489],
              [69.466091201762993, 55.36471811009261],
              [69.35138234191578, 55.394436147884278],
              [69.232754104132596, 55.332500085852985],
              [69.185527108749056, 55.34388220748275],
              [69.169982198714308, 55.393464182389152],
              [69.069445125519564, 55.421664257511509],
              [68.947618185837598, 55.442627062135728],
              [68.926018188932062, 55.426173218518386],
              [68.942618212941284, 55.368600104740096],
              [68.968318133977391, 55.364154008007347],
              [68.997345167669266, 55.351936042990417],
              [69.007218211447366, 55.313882202467653],
              [69.003463286452416, 55.290973119920579],
              [68.974427200305058, 55.283882197452328],
              [68.93963609942125, 55.285691179800779],
              [68.907972286621089, 55.317909036402398],
              [68.886509250014058, 55.339291103824621],
              [68.854982229874111, 55.350544982335634],
              [68.770936215946847, 55.365545068662129],
              [68.736509224938118, 55.369764183456368],
              [68.723309236142427, 55.368327022333972],
              [68.640545150490482, 55.218118120230201],
              [68.62192726710532, 55.200964220057699],
              [68.500272324076946, 55.190545011467407],
              [68.476382223579606, 55.192491121752028],
              [68.304427144178703, 55.205273188854051],
              [68.267763358482341, 55.200000133551782],
              [68.220263280692507, 55.187209181632284],
              [68.199709177668296, 55.17832704645059],
              [68.216654200813309, 55.132491111721663],
              [68.247627177151685, 55.103054035325442],
              [68.293045190186945, 55.094991147362293],
              [68.324718223080595, 55.087209053156414],
              [68.328182296034754, 55.06672703449965],
              [68.20595419846569, 54.967700212623072],
              [68.05525411683476, 54.950827106207726],
              [68.026309225794193, 54.95138215583745],
              [67.943936239745256, 54.978327124774751],
              [67.898036099638034, 54.978327124774751],
              [67.834854151515401, 54.96352703065844],
              [67.808591301860588, 54.940827160415211],
              [67.791372358119133, 54.917491103719499],
              [67.758472382235084, 54.889854124853855],
              [67.683045144091182, 54.874164207894069],
              [67.581372323012772, 54.854436057611053],
              [67.56052720030948, 54.853882181447659],
              [67.391918176665797, 54.852627075262831],
              [67.36123622000639, 54.863609044833879],
              [67.327627135111697, 54.867909128812869],
              [67.30373619642404, 54.861800062485429],
              [67.287627184304256, 54.849854006408464],
              [67.264436134533582, 54.822918089926645],
              [67.249009241696285, 54.808045073252401],
              [67.031936221394517, 54.7761001314189],
              [66.950272344356392, 54.773045095341104],
              [66.876372288975347, 54.772218136771414],
              [66.8181723764759, 54.767773213505109],
              [66.781163256368018, 54.757427095110586],
              [66.750545169810835, 54.734291198263008],
              [66.72276335165833, 54.726936078206165],
              [66.692472326963923, 54.725545017551269],
              [66.625263377268425, 54.727764210242015],
              [66.583054124415867, 54.72720916061229],
              [66.52360916401517, 54.725264223793943],
              [66.495254191321749, 54.723045031103027],
              [66.469145233409677, 54.718600107836721],
              [66.437191239120693, 54.710273189922816],
              [66.40525417627623, 54.702218180948648],
              [66.322218181684548, 54.684154006088704],
              [66.271927265405679, 54.673609068950327],
              [66.22053614051265, 54.663882205564278],
              [66.1083182146578, 54.645000124590126],
              [66.09166337300195, 54.639164140668754],
              [65.98567236303353, 54.627773134221684],
              [65.980818235252912, 54.665127085827351],
              [65.971309133712111, 54.708809039072094],
              [65.847209189520754, 54.684854062643865],
              [65.815682337018586, 54.647773193444493],
              [65.794982221240673, 54.621245140372196],
              [65.777072273399654, 54.606800103675482],
              [65.752627123272418, 54.600964119754281],
              [65.725263394451247, 54.60277310210256],
              [65.684418212525316, 54.615273201981097],
              [65.656645111551768, 54.621100133446987],
              [65.556363351128482, 54.637218030384247],
              [65.523318200681047, 54.640544975401895],
              [65.501936133258823, 54.640544975401895],
              [65.472136120454024, 54.630682157545777],
              [65.346936135286853, 54.568054085586596],
              [65.220818164081265, 54.530136031895552],
              [65.200954059328524, 54.464573120350224],
              [65.215545108778997, 54.405409121345031],
              [65.230954064343621, 54.376518042122882],
              [65.23984508434279, 54.344573100289381],
              [65.224982293590529, 54.324227036102513],
              [65.119982192218515, 54.312773165381316],
              [65.099572257929253, 54.318736051317103],
              [65.060400271519484, 54.347500060886489],
              [65.035809276276666, 54.370545097903459],
              [65.014709176078043, 54.38555406904743],
              [64.994009227938136, 54.397909162000587],
              [64.966927131064608, 54.407209051237771],
              [64.915818141033355, 54.408045062262829],
              [64.884427243001483, 54.39943600948726],
              [64.823882241625142, 54.372218125781842],
              [64.7634633040729, 54.345964160944334],
              [64.731791277007574, 54.341382109741687],
              [64.538882277800099, 54.363882155412568],
              [64.411372240111007, 54.341664244603294],
              [64.382472276071894, 54.338882123293615],
              [64.362200307909291, 54.339427114639534],
              [64.341091322893362, 54.341936153543088],
              [64.319427120609305, 54.347218093662889],
              [64.294427256128358, 54.34707325437563],
              [64.240818279649034, 54.331109081543048],
              [64.085263232047396, 54.318327014440911],
              [63.99548231995152, 54.311173227698703],
              [63.978045111450569, 54.292636145860286],
              [63.990482179417199, 54.271382153918921],
              [64.018345134392803, 54.244091180017563],
              [63.977354107350948, 54.214164265198406],
              [63.89336324634678, 54.201291170627655],
              [63.832773317969327, 54.23165411205116],
              [63.806100257971821, 54.24415404429169],
              [63.75937332693286, 54.257773128247209],
              [63.717773270804798, 54.258054089642542],
              [63.686936081298199, 54.249164243109874],
              [63.54221816398001, 54.206654079932207],
              [63.409300289985424, 54.173600212305587],
              [63.376100241967151, 54.169154115572667],
              [63.349854156118454, 54.172082081998042],
              [63.3258272629609, 54.182491232304756],
              [63.215000230122882, 54.187491205200899],
              [63.19249129963481, 54.187764119969017],
              [63.163745227338097, 54.184718136346703],
              [63.138118228859753, 54.168082237791623],
              [63.149854066804778, 54.135827165539922],
              [63.145063306212421, 54.112982120733307],
              [63.109991243933564, 54.095545079870703],
              [62.919154083558453, 54.085964229238357],
              [62.897491222379074, 54.087209109501259],
              [62.729709157304967, 54.08638215093174],
              [62.678182245580217, 54.083882164483668],
              [62.641109255369713, 54.075136151409879],
              [62.577354152706164, 54.043745085739829],
              [62.568327178237496, 53.952773105199185],
              [62.571945142933998, 53.893609106193992],
              [62.533045065464421, 53.881653997661488],
              [62.425482281007987, 53.936582118066326],
              [62.404018238572689, 53.961382157974924],
              [62.358045175907478, 54.022764176204589],
              [62.09610030738304, 54.029436003513013],
              [62.05331823526592, 54.028045110496066],
              [62.024709290905548, 54.024991080246366],
              [62.02596339126228, 53.988044992050447],
              [62.025827101516029, 53.939709070873562],
              [61.851727260724942, 53.960682101419764],
              [61.789718276135716, 54.004164230092215],
              [61.642218237508018, 54.001936152583767],
              [61.588418153635871, 53.998418100173126],
              [61.486654137450728, 54.015545010617473],
              [61.455554091459589, 54.057636078634971],
              [61.420273319791363, 54.066664226570708],
              [61.326100290418992, 54.06916421301861],
              [61.21554516652094, 54.025827091271594],
              [61.113054104052367, 53.963609062016701],
              [61.090545173563953, 53.963609062016701],
              [61.035482272155946, 53.955545000587222],
              [61.009163263749485, 53.943454105222713],
              [60.993463288505723, 53.919300142412567],
              [60.99999128235504, 53.892627082415231],
              [61.036100186059798, 53.886382229255844],
              [61.098873264944245, 53.885827011987942],
              [61.122073199532394, 53.881800178053197],
              [61.226727128302343, 53.830554060085774],
              [61.223045125865383, 53.807073164102462],
              [61.050409268286614, 53.642218059625236],
              [61.007218159293132, 53.640545031746854],
              [60.980963356265306, 53.657764143126315],
              [60.946936182039281, 53.664154003211081],
              [60.911382327964589, 53.659991214806439],
              [60.905891175541001, 53.622073161115395],
              [60.950827229142305, 53.618327120937806],
              [61.026654116430905, 53.61999109636065],
              [61.07332723565105, 53.618882170567531],
              [61.103182233740768, 53.613536025069322],
              [61.182500183711682, 53.56944503494833],
              [61.228600148391251, 53.563327083803529],
              [61.282936171154006, 53.561153991580227],
              [61.324436147357972, 53.555827124459313],
              [61.365273282657085, 53.575827016043945],
              [61.38749119346619, 53.590545135146954],
              [61.419436135299861, 53.595127018711594],
              [61.469982196712181, 53.594709097018097],
              [61.538891164014387, 53.593318036363144],
              [61.574582313663655, 53.520273102746444],
              [61.438600183497442, 53.446100132597053],
              [61.40470913137932, 53.444709071942157],
              [61.377209280450245, 53.45610913084505],
              [61.348873250858219, 53.480545060878626],
              [61.311100204092554, 53.502964137364529],
              [61.286773238800379, 53.505218031134802],
              [61.245963260867597, 53.507918177431236],
              [61.153327137266274, 53.368327135023833],
              [61.167500265023023, 53.323609178543848],
              [61.184718202936324, 53.306654097115199],
              [61.212209169047696, 53.28860920063255],
              [61.235827192967179, 53.280964234362727],
              [61.274436083119895, 53.278873117152671],
              [61.321382284746448, 53.279991095401499],
              [61.352491215554721, 53.279718180633381],
              [61.381245166840699, 53.275544998668579],
              [61.4116632611873, 53.265682180812632],
              [61.435818229825543, 53.251664118264728],
              [61.459163338977135, 53.238600083939161],
              [61.479154178106455, 53.229718116395304],
              [61.509718117568809, 53.219709118147478],
              [61.538045094705581, 53.215544988638328],
              [61.559991264213011, 53.215544988638328],
              [61.582354181947721, 53.219018114047685],
              [61.606382248571549, 53.228600138146646],
              [61.731373189072883, 53.206653968639159],
              [61.753327237569692, 53.192218152035935],
              [61.781109223360147, 53.180827145588523],
              [61.821109174167759, 53.169582151895156],
              [61.856945163104058, 53.162491229426905],
              [61.997773206785723, 53.139718101349956],
              [62.046663339402016, 53.133327067798689],
              [62.077491308815183, 53.130273205187336],
              [62.108182317930073, 53.121936061351619],
              [62.144018139228592, 53.076100126622634],
              [62.132209211087627, 53.038891181942347],
              [62.114018134213268, 52.995964270537684],
              [62.098463333532891, 52.977073137108164],
              [61.954782257640773, 52.93957317274868],
              [61.902209284396804, 52.966664154439684],
              [61.864918197064981, 52.98499118578394],
              [61.695545079125566, 52.983045075499263],
              [61.495545157449897, 53.022073228449926],
              [61.472354107679337, 53.028327134064682],
              [61.448182207596233, 53.028327134064682],
              [61.426936094643906, 53.020545039858632],
              [61.403045155956249, 53.003745023639226],
              [61.384018235694668, 52.994573209882731],
              [61.355000254458332, 52.991936095498431],
              [61.300136171794122, 52.994991131576455],
              [61.27221823153377, 53.003327101945672],
              [61.254436191535433, 53.013473228129897],
              [61.23485405400595, 53.019153979203907],
              [61.20332720150418, 53.021936100513585],
              [61.170691255570887, 53.014436141169313],
              [61.099163278795174, 52.981654014844366],
              [61.08055411258897, 52.929718066243595],
              [61.012909136289295, 52.883745003578383],
              [60.883882309397563, 52.822773195691411],
              [60.731654206813346, 52.749718036152501],
              [60.706654174694222, 52.726382147094739],
              [60.697073324061876, 52.707636020590485],
              [60.69560917321121, 52.676591127522499],
              [60.739718268242996, 52.63638213204905],
              [60.765273349991503, 52.634164112824578],
              [60.825127179630385, 52.63638213204905],
              [60.835827181977834, 52.599164134913337],
              [60.835136177877985, 52.536936047374638],
              [60.970827120726938, 52.494991159748849],
              [60.978600162477449, 52.413044980211168],
              [61.046109184307625, 52.356654050059944],
              [61.060127246855359, 52.34082700516376],
              [60.934991299428816, 52.269718123547875],
              [60.902209173103643, 52.254718037221267],
              [60.849163292881201, 52.239436151309008],
              [60.810827149858653, 52.230264169914676],
              [60.777773282232204, 52.216236049082966],
              [60.747218227587041, 52.179164232338962],
              [60.721654093382824, 52.159991131685842],
              [60.68388222008349, 52.149018046931928],
              [60.641663244223309, 52.148327042832307],
              [60.60249125781354, 52.152764254747694],
              [60.53874520760553, 52.159573209992345],
              [60.490682201196933, 52.155545034952922],
              [60.460273326943764, 52.144718130591059],
              [60.396100302586717, 52.106100187982918],
              [60.223045182210342, 51.999164202247869],
              [60.025554131799879, 52.000827004204439],
              [60.00874506312482, 51.97888217580163],
              [60.003191214065396, 51.955553998094558],
              [60.052009262314414, 51.883191183760118],
              [60.087491199659667, 51.866935991248454],
              [60.114154201372969, 51.862209100758605],
              [60.137491263897573, 51.864854094131886],
              [60.224163328097006, 51.871936131782604],
              [60.462773313391665, 51.815827168855378],
              [60.485827235225997, 51.809154000442504],
              [60.499227216232043, 51.794227004311836],
              [60.459300187982365, 51.735827099602375],
              [60.401100275482918, 51.713053971525028],
              [60.377073214687357, 51.690436076295214],
              [60.536109266687674, 51.627491168395068],
              [60.855554158794348, 51.622218113092629],
              [60.922218117143927, 51.620545085214246],
              [60.942300151379811, 51.616664264033034],
              [60.931654128489015, 51.598045039543194],
              [60.937763362454689, 51.552700116703818],
              [60.975545126399481, 51.50027315621378],
              [61.024018175512765, 51.480409051460697],
              [61.14054507016408, 51.459718155776272],
              [61.176654141506674, 51.466236091341784],
              [61.210273284685002, 51.468600123319959],
              [61.348873250858219, 51.458045127897719],
              [61.490827151777353, 51.425000145088632],
              [61.575554111520432, 51.309435995838726],
              [61.603882262123648, 51.289300149784196],
              [61.647773260034114, 51.274709100333723],
              [61.671663360531397, 51.268327119238108],
              [61.685818215739516, 51.265827132789866],
              [61.661100150843993, 51.261936085687068],
              [61.621654076200002, 51.252773156747878],
              [61.592218173270112, 51.243882136748709],
              [61.567073301863729, 51.232354002365241],
              [61.550973342199427, 51.217909133306534],
              [61.541109183238859, 51.199436089208575],
              [61.497773234958345, 51.025273216505127],
              [61.467763339297221, 50.897773237100239],
              [61.461663325425036, 50.873045113921137],
              [61.422354211079437, 50.800618094207891],
              [61.403600205585974, 50.789991182056212],
              [61.38138229477687, 50.783609033322648],
              [61.339427181229325, 50.776654065324465],
              [61.303045195118386, 50.773044985445097],
              [61.277773254059639, 50.768882197040284],
              [61.1780452021614, 50.751109041859422],
              [61.145000219352141, 50.74499109071445],
              [61.091936234218906, 50.734991144922162],
              [61.04804506867049, 50.723664176215323],
              [60.929991158894495, 50.694991194114721],
              [60.769991188026211, 50.661236096466325],
              [60.729709269995169, 50.660273183426852],
              [60.698045121918653, 50.661664244081805],
              [60.357773212019879, 50.686100174115552],
              [60.318118260348001, 50.690964192541799],
              [60.270545092362227, 50.707773261216857],
              [60.237491224735436, 50.721654028190244],
              [60.176663250307001, 50.769018151510352],
              [60.16922716106518, 50.795127109422481],
              [60.170409177054296, 50.83554498192359],
              [60.11950923168888, 50.862282079661384],
              [60.052909143441696, 50.864164152205774],
              [60.019154213431477, 50.858327162456078],
              [59.98790916051513, 50.843045108905812],
              [59.968045223400225, 50.813609038337745],
              [59.946373309765363, 50.778464221139245],
              [59.93638224879038, 50.749645058646706],
              [59.939709193808142, 50.711936049621556],
              [59.895273204551813, 50.645273097100244],
              [59.814400243899229, 50.546273097313929],
              [59.755000210500043, 50.53388212981514],
              [59.720691236688253, 50.532627023630084],
              [59.698600227893735, 50.536109033857088],
              [59.659018198779449, 50.535545099409887],
              [59.625545068354825, 50.522764205774138],
              [59.600273294934254, 50.511382084144259],
              [59.54250018894561, 50.478327043051138],
              [59.529718121843587, 50.483327015947452],
              [59.467563292138664, 50.533536124851082],
              [59.49708217591035, 50.557836100415102],
              [59.541936086860574, 50.553045004546618],
              [59.577563366406991, 50.560545131528841],
              [59.58409119261853, 50.586445044775076],
              [59.488745199712753, 50.630409132881653],
              [59.462627189344971, 50.634854056147901],
              [59.357773268364667, 50.635273151307899],
              [59.242218171570471, 50.666100114892743],
              [59.193318148308208, 50.668882236202421],
              [59.095545091512122, 50.669718079589472],
              [58.949991163169102, 50.682218179467839],
              [58.923882205256973, 50.686654050278776],
              [58.898045156284525, 50.697773147786393],
              [58.665545075507453, 50.804991100744871],
              [58.596654045477862, 50.866100204206418],
              [58.570236130613807, 50.921764255712162],
              [58.611100255641077, 50.959018127393733],
              [58.625827091923441, 51.002845087563742],
              [58.615273269967702, 51.031382115194447],
              [58.601391161889865, 51.046664168744712],
              [58.572909287181915, 51.063464184964289],
              [58.37804512523428, 51.127773163791232],
              [58.337773265486874, 51.156100140927833],
              [58.314436202962497, 51.149991074600337],
              [58.220545140814124, 51.117627037607065],
              [58.211936088038556, 51.096727097256803],
              [58.18270017731902, 51.058182244844545],
              [58.15138220184474, 51.053882160865328],
              [57.8435361873847, 51.102144991846274],
              [57.792682174848778, 51.116318119603591],
              [57.755000155551897, 51.082491105225699],
              [57.749718215432097, 51.052773235072209],
              [57.751936234656569, 51.022764177601573],
              [57.760554172249613, 50.980964129263043],
              [57.759991243630907, 50.958882172923666],
              [57.753463249781589, 50.929573172008361],
              [57.736100304943079, 50.910409123810553],
              [57.69589130946963, 50.906582114447986],
              [57.644436146836483, 50.924018149482265],
              [57.612773339864816, 50.926654090400234],
              [57.561663344005069, 50.923882195012368],
              [57.539436213102306, 50.909154017625553],
              [57.528191219408768, 50.887082119570152],
              [57.510136097004306, 50.872627024589406],
              [57.463536235617937, 50.865273245636899],
              [57.427773336877408, 50.873045113921137],
              [57.384573343066734, 50.888609134694832],
              [57.355973283523724, 50.903745007853388],
              [57.340545217219756, 50.920545024072794],
              [57.266109222948103, 51.018054050918067],
              [57.208045097280547, 51.06554507625242],
              [57.127354191565701, 51.084709124450342],
              [57.099718218528722, 51.075827156906485],
              [57.050827247722111, 51.07027314020894],
              [56.863600173055147, 51.059154042701323],
              [56.835263305272406, 51.064436150459244],
              [56.806100149472627, 51.082009145791915],
              [56.780273326422105, 51.091800214556372],
              [56.753609151242131, 51.084164133104252],
              [56.706036150894477, 51.063253966832008],
              [56.702218193987278, 51.042909076111812],
              [56.71331818075555, 51.01874505501786],
              [56.728318267082045, 50.984191161994715],
              [56.62298221902978, 50.989227009436547],
              [56.590545091840568, 51.012218066996866],
              [56.573327153927437, 51.028600161522945],
              [56.553609229566405, 51.049436064132806],
              [56.529991205646695, 51.074718063475359],
              [56.501863382530018, 51.080827129802856],
              [56.478873330798137, 51.069164214415594],
              [56.441236238502597, 51.033818063902118],
              [56.446936100315668, 51.006800172406997],
              [56.451309274490654, 50.976936121861797],
              [56.356100241883098, 50.901654058281281],
              [56.327636136810156, 50.889436093264521],
              [56.260963293643215, 50.897636109163898],
              [56.234436078761235, 50.904709094359305],
              [56.203327147952962, 50.915691063930353],
              [56.176036174051774, 50.913882081581903],
              [56.162763262698149, 50.894718033384265],
              [56.134509208119539, 50.814991214175336],
              [56.126936158579156, 50.772353981345304],
              [56.118882323071659, 50.743391153031837],
              [56.035000259170943, 50.692218125260354],
              [56.00027319602782, 50.672218066037544],
              [55.931382333636407, 50.639718074574148],
              [55.911663235808703, 50.631936148006332],
              [55.86749127650296, 50.62193603457581],
              [55.839154073444433, 50.613882199068314],
              [55.779718165499389, 50.591382153397433],
              [55.756663237836619, 50.578045036665401],
              [55.692491219308067, 50.532491069160187],
              [55.675273281395107, 50.53750009451187],
              [55.655545131112092, 50.546944990674433],
              [55.537845105289193, 50.612836137549152],
              [55.525963254590636, 50.638327013919422],
              [55.509854074832504, 50.654991075669102],
              [55.490273278407869, 50.663882095668271],
              [55.452636186112159, 50.669300157895918],
              [55.411800224279489, 50.664718106693272],
              [55.376382324674523, 50.652345076467157],
              [55.298736229115889, 50.687073145438774],
              [55.090136139481956, 50.814027127669419],
              [55.074100217558112, 50.835064195956079],
              [54.987773320132419, 50.89860908048712],
              [54.828882275057083, 50.983327155412894],
              [54.713745099956384, 51.029845041786018],
              [54.674163238480276, 51.037218099115819],
              [54.647218269543316, 51.036945016709524],
              [54.554227088522111, 51.009854035018691],
              [54.548609201722059, 50.922218051951347],
              [54.580827225961684, 50.917773128685099],
              [54.634854124134506, 50.904573139889351],
              [54.672427178689986, 50.871209141844361],
              [54.671663252032374, 50.793609146753113],
              [54.658327141128609, 50.727909107271444],
              [54.665827100473052, 50.69610011990784],
              [54.693882168669973, 50.649718020366663],
              [54.701800217345749, 50.609644979363281],
              [54.686800298657261, 50.589853964806139],
              [54.606663269105809, 50.542773149814309],
              [54.523936231465882, 50.528836056451496],
              [54.498745091954049, 50.533464208121757],
              [54.451100174876785, 50.557636108204576],
              [54.418882318275223, 50.588044982457689],
              [54.411936235094402, 50.596654035233428],
              [54.398400299618459, 50.625891119419293],
              [54.441236183554395, 50.769364156474239],
              [54.468045198021798, 50.795545031115978],
              [54.504091237452258, 50.830273100087595],
              [54.501663335371632, 50.85922704358353],
              [54.425827227989714, 50.885827013385097],
              [54.374436103096684, 50.895273250651996],
              [54.30943612017046, 50.904991229220911],
              [54.204436186436283, 50.966936008431333],
              [54.168463237201593, 50.99874499579505],
              [54.143882300242467, 51.084436042043876],
              [54.135127234713195, 51.104018179573359],
              [53.950263353834032, 51.196100091735445],
              [53.91499129934482, 51.199718056432175],
              [53.884918204133726, 51.192700056521915],
              [53.751663377630621, 51.214154040673407],
              [53.675545135386756, 51.229291087298293],
              [53.611936213115087, 51.301245032394661],
              [53.612500147562514, 51.349718081507945],
              [53.638118261223326, 51.383018041812534],
              [53.423745219580155, 51.492636068933166],
              [53.35540923918046, 51.501109167238837],
              [53.325691201388963, 51.492218147239669],
              [53.294445142644349, 51.486382163318297],
              [53.203882198979727, 51.493191118562891],
              [53.147491268828844, 51.501109167238837],
              [53.048336203833344, 51.491664271076218],
              [52.986109122122969, 51.470827027361906],
              [52.884991183036504, 51.465000095896244],
              [52.841382319987645, 51.484718020257219],
              [52.768254070253192, 51.503264154551118],
              [52.698609171849853, 51.472627124892881],
              [52.664154185284474, 51.456654067242994],
              [52.607627133025574, 51.456382158303143],
              [52.55915408391246, 51.470827027361906],
              [52.530691152305849, 51.484154085810019],
              [52.507354089781586, 51.503882236093204],
              [52.489854184645083, 51.528744972637583],
              [52.482773320460637, 51.549300081490287],
              [52.474091177489129, 51.582627031523202],
              [52.365273286847867, 51.759164162126581],
              [52.341800269853763, 51.780754100748197],
              [52.313327112325112, 51.778882086487613],
              [52.165263306888392, 51.718318142010105],
              [52.138736092006582, 51.681664246959599],
              [52.109436143546418, 51.665127087224334],
              [52.089154117100179, 51.661936096676584],
              [52.025545194828396, 51.663327157331537],
              [52.005554188061069, 51.666100058547556],
              [51.95651821032925, 51.683744970609666],
              [51.917073309151533, 51.686518039464033],
              [51.893609176974564, 51.681935988261444],
              [51.871382213709808, 51.671800087999031],
              [51.786936215362005, 51.60374506899467],
              [51.779573216315782, 51.582354116755084],
              [51.796382284990898, 51.544853984757367],
              [51.801973182062625, 51.503082099613607],
              [51.711936119005145, 51.461936007362738],
              [51.677009231289617, 51.455682101748039],
              [51.649091291028867, 51.476591094553839],
              [51.52499117919956, 51.492764144414025],
              [51.425336217497261, 51.469018045013456],
              [51.299436175774986, 51.481236010030443],
              [51.257563372516643, 51.540964111120843],
              [51.301654194999458, 51.554991226124059],
              [51.389227146154667, 51.570754065641836],
              [51.402282295662985, 51.615409157848205],
              [51.384709132692478, 51.640544976798878],
              [51.275273328147364, 51.683873213728589],
              [51.204163273065149, 51.678045108796425],
              [51.176663254497953, 51.676800060895175],
              [50.944300134019102, 51.688191067342416],
              [50.913536202346393, 51.701591215986468],
              [50.890136107910052, 51.733182106228867],
              [50.863327093442649, 51.75139112037607],
              [50.841936141203064, 51.7599911206961],
              [50.811663221419849, 51.764718178824296],
              [50.773300256307152, 51.76918221282989],
              [50.760691191686817, 51.752427123611426],
              [50.759018163808264, 51.721445094817568],
              [50.775963354591454, 51.69443608813981],
              [50.794991280681131, 51.672909181430157],
              [50.816527239846096, 51.640409189966988],
              [50.820273280023514, 51.615127022986371],
              [50.812354057881464, 51.59430017283205],
              [50.77860013369957, 51.57582712873409],
              [50.71054511469498, 51.572082094384768],
              [50.68331817853425, 51.575544993872313],
              [50.689227085013385, 51.595127131401739],
              [50.713536113032546, 51.62044500528998],
              [50.621382284141134, 51.644854113233407],
              [50.600409253594933, 51.637773249049133],
              [50.557500279463227, 51.58027322546684],
              [50.551382328318425, 51.52860013335038],
              [50.552627208581498, 51.493191118562891],
              [50.5502732348873, 51.471653985931596],
              [50.475618137665776, 51.432282174949648],
              [50.384373242357242, 51.423600199616374],
              [50.35687322379016, 51.37012700996894],
              [50.368600176917482, 51.327418028047418],
              [50.264163339440415, 51.277773188867002],
              [50.200273288135463, 51.266391067237123],
              [50.170409237590263, 51.263191191871897],
              [50.074436110687145, 51.250545079239771],
              [50.018327147759749, 51.240682093745534],
              [49.968045116298015, 51.226100096750542],
              [49.939573299874212, 51.211036140321482],
              [49.863882199417731, 51.158045077746181],
              [49.829154130445886, 51.129991183015534],
              [49.802845180323629, 51.111382184447677],
              [49.584718219513661, 51.10832714836971],
              [49.537491224130122, 51.109991123792895],
              [49.474709260427801, 51.124018071157991],
              [49.419718108111226, 51.082073183532202],
              [49.365273288244907, 50.970818170716996],
              [49.390273320364031, 50.94887317467601],
              [49.421800172865801, 50.930273228563465],
              [49.440127204210057, 50.903882135789559],
              [49.439227155444769, 50.866454088159514],
              [49.425827174438723, 50.851391137559062],
              [49.407209123415157, 50.842764147510536],
              [49.323609194376218, 50.815545090338787],
              [49.301100263888202, 50.809991073641015],
              [49.219154084350123, 50.79804501756405],
              [49.165827242732405, 50.794436105322802],
              [49.143191242591627, 50.785000093977544],
              [49.127627221817932, 50.769718040427506],
              [49.089991302988494, 50.735545021085557],
              [49.027773273733828, 50.686518095809049],
              [48.994709180185396, 50.668882236202421],
              [48.85832723323648, 50.605273146292802],
              [48.820409179545436, 50.596936002456857],
              [48.739436138969154, 50.608327008904098],
              [48.697491251343195, 50.591936029560884],
              [48.690545168162373, 50.504854090294941],
              [48.705827221712639, 50.424709181754508],
              [48.722763360040119, 50.353053967688197],
              [48.729154058315032, 50.331382221691342],
              [48.748882208598047, 50.267773131781553],
              [48.80971822965347, 50.162764145592234],
              [48.88110019367565, 50.099227140049834],
              [48.914718163387477, 50.032773232194586],
              [48.865000234011177, 49.98110014007807],
              [48.833882250747422, 49.959164196492281],
              [48.790554181455718, 49.939436046209437],
              [48.744154144641698, 49.922491190702431],
              [48.688600231344026, 49.905264200333988],
              [48.653045203803003, 49.895273139359006],
              [48.615545071805514, 49.886654028299631],
              [48.491663224735305, 49.846800090245949],
              [48.469436093832542, 49.829509062136879],
              [48.446663301031549, 49.817353961394076],
              [48.376654125115351, 49.833327019043963],
              [48.248745108834044, 49.871382200671349],
              [48.165545164215814, 49.96610005375129],
              [48.139718173527172, 50.008609043462457],
              [48.134854155100868, 50.0429091324566],
              [48.129709175279515, 50.071100155123474],
              [48.110545127081764, 50.09860017369067],
              [48.012218194122283, 50.191373257590385],
              [47.939436116989839, 50.250964230744614],
              [47.760827147553698, 50.375264167146497],
              [47.627354056291097, 50.457354012505107],
              [47.599718250892295, 50.460818085459096],
              [47.576100226972756, 50.456382214648158],
              [47.553882316163481, 50.449718098690767],
              [47.52082710743241, 50.436382155425349],
              [47.485691175051045, 50.417627144103619],
              [47.445782251712217, 50.378345019486119],
              [47.446400165616069, 50.376953958831336],
              [47.435691278451145, 50.355545069318794],
              [47.410545065940482, 50.328609152836975],
              [47.362354151688805, 50.312627042731606],
              [47.319645169767455, 50.296109161373806],
              [47.282009250938017, 50.181245068679004],
              [47.305263332620825, 50.157353962353227],
              [47.323463294312489, 50.144854030112981],
              [47.341100159747441, 50.128882145929424],
              [47.355545196444382, 50.099018095383997],
              [47.345273341712073, 50.075827045613494],
              [47.302491269594952, 50.031936047703027],
              [47.26277328601094, 49.99749111942134],
              [47.194163217376854, 49.947209087959777],
              [47.150827101457764, 49.934436073313123],
              [47.11970911819401, 49.928600089391921],
              [47.089427145955483, 49.921664232132912],
              [47.045273291560534, 49.910545134625465],
              [47.009163214389446, 49.900827156056607],
              [46.981654143366711, 49.891664227117758],
              [46.949300164657416, 49.878745032079394],
              [46.931382170189181, 49.865827010507132],
              [46.861109131960347, 49.596100047776815],
              [46.802218215361052, 49.365000080110178],
              [46.80401814525402, 49.33846414804907],
              [46.838045151842209, 49.331935986561746],
              [46.874709272814528, 49.319853976014713],
              [46.899718189750843, 49.308045047873975],
              [46.923045193991413, 49.295273206693594],
              [46.946100289292019, 49.28220917236797],
              [47.020409213911307, 49.239718119929762],
              [47.039991183802613, 49.224709148785792],
              [47.059154058534034, 49.197353969505798],
              [47.064573294228182, 49.159164174513137],
              [47.059573321331868, 49.133600207946927],
              [47.04374510296941, 49.10360020293183],
              [47.016391264793867, 49.071382178692261],
              [46.995545136262422, 49.049436009184603],
              [46.961800264536009, 49.018745000069828],
              [46.943600135205941, 49.006100060904032],
              [46.914709223621969, 48.990273183645684],
              [46.881936149752676, 48.978327127568718],
              [46.84832723249616, 48.966936121121478],
              [46.809573335418236, 48.95207316273121],
              [46.778882158665169, 48.936653981244774],
              [46.63113619499768, 48.663791180889518],
              [46.606945184175402, 48.61720908913793],
              [46.556100224094962, 48.523882129074821],
              [46.527491279734591, 48.47082702875872],
              [46.499163296769552, 48.417500187141172],
              [46.659709264812193, 48.381100096119496],
              [46.819991202904077, 48.343609184215438],
              [46.979991173772191, 48.306100167400587],
              [47.12123613331886, 48.272073160812738],
              [47.134873322185342, 48.24810913192897],
              [47.130273333709738, 48.237209137371167],
              [47.122209272280259, 48.102218083439197],
              [47.119982200600305, 47.946091222401151],
              [47.144645112572562, 47.81214505652224],
              [47.189418221975814, 47.783873232308395],
              [47.255827202830119, 47.750827076032806],
              [47.396200276805985, 47.696864215600613],
              [47.413045052388384, 47.771100050023733],
              [47.410809263529245, 47.813191118041402],
              [47.419845122815616, 47.837627048074978],
              [47.444845154934796, 47.842209099277795],
              [47.485263362711692, 47.832764203115175],
              [47.521382324700312, 47.820827031855515],
              [47.599991165660413, 47.794153971858009],
              [47.633054085742515, 47.779764255722398],
              [47.663318120708425, 47.769436074600833],
              [47.691373188905573, 47.765827162359471],
              [47.738045134659217, 47.773036102024605],
              [47.795536105785857, 47.778600177005956],
              [47.918873296786273, 47.782491224109151],
              [48.037345128255652, 47.782627010940871],
              [48.064709192353121, 47.779709102799302],
              [48.102763368152068, 47.76874507050087],
              [48.143036233727798, 47.749718150239687],
              [48.204427136774768, 47.704991141303992],
              [48.223309217748749, 47.690264137383679],
              [48.240818175341019, 47.674709169065295],
              [48.264436199260501, 47.641100084170603],
              [48.316091186466167, 47.57249118900296],
              [48.379991296055096, 47.501654048688749],
              [48.457554075495864, 47.431864143360144],
              [48.572491258386378, 47.365545016508122],
              [48.624700289393445, 47.270818110972698],
              [48.721100222807365, 47.098327092681359],
              [48.981654198314914, 46.824154033218349],
              [49.027209171648394, 46.776091194447531],
              [48.941373286112366, 46.704164239079489],
              [48.757209126511839, 46.703327054587987],
              [48.726100195703566, 46.746382209111573],
              [48.710536174929871, 46.75971815237699],
              [48.691091165336786, 46.767773161350931],
              [48.66582710326702, 46.772754023507957],
              [48.636936191683048, 46.774991153471717],
              [48.596382197074036, 46.771509143244941],
              [48.544573318125799, 46.754154077395413],
              [48.515409156497753, 46.737354061176006],
              [48.504991289011741, 46.719700096658528],
              [48.498600255460815, 46.688327135899328],
              [48.49442724113419, 46.667209098427861],
              [48.576173260823566, 46.561027148704795],
              [48.619982283720901, 46.559436095839487],
              [48.673045095387636, 46.563736012180527],
              [48.723454196501564, 46.561791075362351],
              [48.742900211923143, 46.556664200451621],
              [48.764436171088107, 46.536100206781725],
              [48.785827123327863, 46.515264136533744],
              [48.840509151054505, 46.482118067971896],
              [48.883327097717427, 46.481654045810728],
              [48.914754037933335, 46.487800160150357],
              [49.174991177499919, 46.369709034724224],
              [49.222527129835385, 46.34630910792589],
              [49.216191249207384, 46.334736046541082],
              [49.230582306447673, 46.277164106229463],
              [49.23400368630729, 46.266902814757657],
              [49.230853567000224, 46.267356298999971],
              [49.235544205000167, 46.242229462000125],
              [49.230251312000178, 46.226852417000146],
              [49.222112656000206, 46.194286346000069],
              [49.216211319000195, 46.17432403600003],
              [49.225742340000181, 46.159740448000093],
              [49.227489471000013, 46.156858444000022],
              [49.24028396600005, 46.137073517000147],
              [49.246065140000155, 46.127456665000125],
              [49.26971435500019, 46.090023041000109],
              [49.331651688000193, 45.984823226999936],
              [49.347267150999983, 45.957458496000015],
              [49.468479156000114, 45.749830246000101],
              [49.477266312000097, 45.734657288000051],
              [49.562690209594962, 45.586192678623547],
              [49.448318167654122, 45.530382128764302],
              [49.346854055965366, 45.444036120599321],
              [49.088136220172601, 45.18951812122333],
              [48.866345194198516, 44.98827315164641],
              [48.686154056714429, 44.75434512984026],
              [48.756618202336426, 44.587609126285315],
              [48.91719946860178, 44.356076373148312],
              [48.927448273000152, 44.307006836000085],
              [48.951732635000212, 44.185350418000098],
              [49.059949875000257, 43.637098312000148],
              [49.101816177000217, 43.422838211000112],
              [49.151535034000176, 43.37081909200009],
              [49.154357910000158, 43.367874146000133],
              [49.340692520000232, 43.174373627000193],
              [49.34301948500007, 43.17209243800005],
              [49.375694275000171, 43.138917923000065],
              [49.421380997000028, 43.098587036000083],
              [49.451820374000192, 43.077178955000193],
              [49.505123138000158, 43.039531708000027],
              [49.488710403000255, 43.017263411999977],
              [49.040697098000066, 42.411912918000098],
              [48.949129105000111, 42.296270370000116],
              [48.907737732000101, 42.244548798000096],
              [48.87170600900015, 42.200027466000165],
              [48.83630561800004, 42.156867981000062],
              [48.661607742000228, 41.947107315000096],
              [48.658018112000093, 41.943004608000024],
              [48.656328201000292, 41.940992355000049],
              [48.630675315500014, 41.909028053500037],
              [48.609922409000092, 41.88702011099997],
              [48.603282928000056, 41.870283127],
              [48.598165512000151, 41.855411530000083],
              [48.593451970375384, 41.846417367860226],
              [48.592125232004491, 41.844659377925268],
              [48.583954181563286, 41.835773141519553],
              [48.53138221414747, 41.767209173352683],
              [48.43692721755184, 41.639153976680063],
              [48.419573325168983, 41.609009132377537],
              [48.399709220415957, 41.589145027624511],
              [48.378318100538365, 41.574718095838762],
              [48.24887318431513, 41.509164236748916],
              [48.228463250025811, 41.501518097012649],
              [48.186100273068519, 41.492209155319927],
              [48.149991201725925, 41.488318108217129],
              [48.10582712140922, 41.480264105071456],
              [48.070263376688672, 41.46401813265328],
              [47.95831819796399, 41.35581815591587],
              [47.908391223921853, 41.278118080900583],
              [47.921791204927899, 41.251518111099017],
              [47.915473261572743, 41.224991063855214],
              [47.859154080513008, 41.207764241124778],
              [47.796100207871291, 41.198864168670127],
              [47.760818262736393, 41.196582111705368],
              [47.721373193920499, 41.210536136512644],
              [47.651518245023169, 41.235545053448959],
              [47.630682174775188, 41.232064216688627],
              [47.599436116030688, 41.215273085286526],
              [47.576173317168696, 41.211309115625568],
              [47.371091279945233, 41.271936092015324],
              [47.274645078425692, 41.321100145228229],
              [47.261036220392157, 41.374291200014],
              [47.259091283573582, 41.420264262679211],
              [47.15859125839097, 41.562909167697967],
              [47.129573277154805, 41.576382071261605],
              [47.092354106552364, 41.569291148793582],
              [47.0213821852347, 41.61860004129386],
              [46.940536214310669, 41.68387310662655],
              [46.861663343399755, 41.734709181889514],
              [46.806927168578284, 41.769145057715548],
              [46.774363307012663, 41.795618125502699],
              [46.769436089036276, 41.830964108378225],
              [46.761727085026166, 41.860473101504056],
              [46.642209192037512, 41.817627159284314],
              [46.564845063702251, 41.881864221381818],
              [46.505273201287537, 41.893600059326616],
              [46.451754078810268, 41.897054073996628],
              [46.446382284688241, 41.904427131326486],
              [46.425827175835707, 41.92262709301832],
              [46.400118202344117, 41.938045101038483],
              [46.239227235165856, 42.000964192676804],
              [46.054154141982735, 42.024991085834358],
              [45.986927087376159, 42.028600165713726],
              [45.655127102316385, 42.199991143029251],
              [45.637973202143883, 42.220191194462416],
              [45.650682179050136, 42.251936144085505],
              [45.689709158534356, 42.319153978598592],
              [45.707500250988005, 42.356100066794511],
              [45.757627217240497, 42.465264130037781],
              [45.760273216442272, 42.477909069203577],
              [45.750827146813208, 42.487753949786736],
              [45.727627212225173, 42.504854038140763],
              [45.70053623053434, 42.516109090118277],
              [45.57610017202478, 42.546100042678063],
              [45.55277333542233, 42.550264172187156],
              [45.52692723399457, 42.550818048350607],
              [45.48818222173378, 42.547636110258225],
              [45.43220921327628, 42.537491157540501],
              [45.367636204498751, 42.527209076886379],
              [45.342209198230762, 42.540682148088194],
              [45.333609197910732, 42.558736097026326],
              [45.319427185336281, 42.578044984511337],
              [45.241936154986604, 42.650827061643952],
              [45.212209232377631, 42.67610017616903],
              [45.165118191463989, 42.70332711232993],
              [45.143045119941917, 42.708600167632369],
              [45.120273332969418, 42.706936024571348],
              [45.091473281215997, 42.697418038212845],
              [45.066163286316794, 42.693527158747827],
              [45.045827112775783, 42.696091182936527],
              [45.009991291477434, 42.714564227034487],
              [44.974291257010862, 42.736936029586445],
              [44.954991254343213, 42.750409100788261],
              [44.931100148017549, 42.761109103135709],
              [44.893745190583445, 42.761664152765604],
              [44.85957317706999, 42.746791136091531],
              [44.808327226740744, 42.665264219351684],
              [44.765545154623226, 42.670536101187452],
              [44.750963325266298, 42.692909077205968],
              [44.705273235653181, 42.727209166200282],
              [44.678182253962177, 42.741791163195217],
              [44.648882305502184, 42.748600118439981],
              [44.638263272339714, 42.748809163105648],
              [44.629436122442826, 42.75221808313654],
              [44.593882268368077, 42.758327149464208],
              [44.558045105965334, 42.759718042480813],
              [44.527209257563186, 42.756653953947477],
              [44.499718123813295, 42.750827022481644],
              [44.369427138281196, 42.708045118002474],
              [44.241100200306619, 42.655954104192517],
              [44.223182205838327, 42.638464257339763],
              [44.195200227837347, 42.627053972515057],
              [43.911936156164984, 42.583318039813776],
              [43.77728222237954, 42.604018155591746],
              [43.739663234994623, 42.649573128925397],
              [43.762773315580375, 42.673044972453056],
              [43.806100211405578, 42.702209134081329],
              [43.833945061470217, 42.729227025576392],
              [43.829154133240081, 42.7493642127356],
              [43.756382282029449, 42.775827054600768],
              [43.704718242368472, 42.780818142679436],
              [43.670827190250179, 42.791100055695495],
              [43.641173190199083, 42.809991189125014],
              [43.621518130111895, 42.833182071257511],
              [43.597763313532141, 42.846518014523269],
              [43.560545148758195, 42.860827096749972],
              [43.532491254027946, 42.868318171276883],
              [43.447200192199432, 42.889154073886857],
              [43.392891159165004, 42.90012715864043],
              [43.377763332633606, 42.900536027878445],
              [43.207218256988938, 42.934018043120545],
              [43.183873315475523, 42.944427025789082],
              [43.139291145827315, 42.966791116990066],
              [43.010473195963414, 43.063664125020637],
              [42.94886335760458, 43.121653987025752],
              [42.855200283223212, 43.177764123419479],
              [42.769436146778276, 43.185818126565323],
              [42.69499126768909, 43.180264109867551],
              [42.669991235570137, 43.15929107932152],
              [42.645818162020703, 43.144718134781897],
              [42.619436121702506, 43.145409138881689],
              [42.53297326980686, 43.181927079462298],
              [42.483882306789553, 43.219427043821838],
              [42.460263277041747, 43.229982206882028],
              [42.424154038061147, 43.238464190005175],
              [42.379845118457013, 43.239018066168455],
              [42.363882286729108, 43.237491218681782],
              [42.270818183150084, 43.238045094845177],
              [42.189018184004112, 43.236382125250486],
              [42.1691540792512, 43.230954004738805],
              [42.111173269701567, 43.197282223208504],
              [42.031100277890403, 43.187482101988337],
              [41.597482297189003, 43.221509108576413],
              [41.564991190542969, 43.232200058468493],
              [41.435545100853403, 43.296100168057251],
              [41.213609235591889, 43.378873138526671],
              [41.193045074283816, 43.384718174903355],
              [41.168054094620402, 43.387218161351427],
              [41.126800211094405, 43.384154072817978],
              [41.068882265818388, 43.37290907912444],
              [41.040963319729485, 43.376100069672304],
              [41.014018183154178, 43.390682234305416],
              [40.961100210774646, 43.423609032279671],
              [40.890263238098441, 43.465400195800726],
              [40.863882203608512, 43.477209123941464],
              [40.834163327626698, 43.483327075086265],
              [40.810818218475276, 43.486927102510379],
              [40.743736170794136, 43.506936046550493],
              [40.718882319066893, 43.51957310672708],
              [40.695973236519819, 43.543018128164761],
              [40.680400163290642, 43.546244993258313],
              [40.643109243596939, 43.543882134746411],
              [40.608182188243234, 43.528600081196146],
              [40.577209211904915, 43.512291076866006],
              [40.54305412983598, 43.508609074428875],
              [40.518591210074078, 43.511654052223037],
              [40.488882224737893, 43.517773176834282],
              [40.352700269999502, 43.559436097236471],
              [40.324436157136915, 43.569709125435111],
              [40.294718119345248, 43.576100158986151],
              [40.253391145623311, 43.582518014627567],
              [40.211373335439589, 43.584718096579081],
              [40.169991208794727, 43.581244971169554],
              [40.126654087047484, 43.572218164338665],
              [40.098463232018844, 43.562354005378097],
              [40.081236074012224, 43.550973224852783],
              [40.021100277150083, 43.444154082848399],
              [40.009163273528429, 43.411936058608603],
              [40.002976785148178, 43.379261668852109],
              [40.002766805000249, 43.377831644000139],
              [40.001199732000373, 43.359882018000178],
              [39.999930800000357, 43.347990941000148],
              [39.995230023000147, 43.326420637000126],
              [39.993612713000289, 43.318999340000289],
              [39.990445292000118, 43.304465099000197],
              [39.9852538250002, 43.278014552000229],
              [39.982947116000219, 43.266261862000192],
              [39.975638806000177, 43.229025992000174],
              [39.96633964900019, 43.194554829000083],
              [39.962278323000248, 43.179499847000159],
              [39.95221034000042, 43.142178712000145],
              [39.94523275000023, 43.13675807400017],
              [39.943608480000307, 43.135496238000201],
              [39.700584624000271, 42.948359028000198],
              [39.699983638000276, 42.947894377000182],
              [39.67092024100026, 42.925424066000232],
              [39.169977683500235, 42.508408309000231],
              [38.973374679571634, 42.34312325928579],
              [38.61686047600017, 42.409426886000119],
              [38.536111111000082, 42.424444444000301],
              [37.95305275700025, 42.685613980000142],
              [36.845000000000255, 43.18194444400018],
              [36.182500000000118, 43.434444444000235],
              [36.151255549000155, 43.434495833000142],
              [36.182918124000253, 43.527811003000124],
              [36.231762855000397, 43.668437870000218],
              [36.234404217000247, 43.675173620000294],
              [36.242034776000196, 43.694632351000166],
              [36.247280532000104, 43.705008329500004],
              [36.270598800000357, 43.751131301000271],
              [36.346424046000266, 43.901111812000352],
              [36.532072111000218, 44.303407377000212],
              [36.537055726000403, 44.314206770000169],
              [36.564551347000133, 44.373351973000297],
              [36.693883287000176, 44.591704853000294],
              [36.65369003800015, 44.736157399000263],
              [36.639918814000225, 44.796936551000158],
              [36.625870940000368, 44.87137061600032],
              [36.614821225000128, 44.931101285000182],
              [36.614394637000373, 44.933593795000149],
              [36.607837828000072, 44.98325340450026],
              [36.60715887900011, 44.985720432000051],
              [36.602010223000093, 44.996386704000088],
              [36.587779456000078, 45.02915259800028],
              [36.583089835000123, 45.040219040000181],
              [36.581785588000116, 45.04319499900015],
              [36.571723760000339, 45.06539184900015],
              [36.56842978900022, 45.072449690000269],
              [36.558338576000097, 45.093407550000279],
              [36.555222712000386, 45.101210428000172],
              [36.554297038000186, 45.103259971000227],
              [36.549063174000366, 45.115337757000304],
              [36.548098394000135, 45.117410827000072],
              [36.546760336000261, 45.120090046000144],
              [36.535840338000185, 45.138417124000227],
              [36.532800176000364, 45.14247076900017],
              [36.531458590000341, 45.144348511000146],
              [36.526004286000102, 45.151727325000309],
              [36.52334660500037, 45.153958381000166],
              [36.516856166000252, 45.159155149000071],
              [36.504747064333458, 45.19600842816692],
              [36.505458382000256, 45.197725500000104],
              [36.51734698700011, 45.201555414000268],
              [36.535012419000168, 45.207241503000262],
              [36.546746609000252, 45.208862811000301],
              [36.554780333000281, 45.209708666000211],
              [36.555959454000231, 45.20981301300003],
              [36.557045148000157, 45.209909093000192],
              [36.559794018500099, 45.21036492200011],
              [36.560859087000154, 45.210524700000235],
              [36.565547515500157, 45.211231003500188],
              [36.56731828400018, 45.211594615000195],
              [36.571820228000149, 45.212349685000333],
              [36.579081846000236, 45.213567610000268],
              [36.579900491000217, 45.213704914000289],
              [36.580815058000269, 45.213908922000087],
              [36.589351966000208, 45.215813206000291],
              [36.595316525000158, 45.217143689000125],
              [36.596631866000166, 45.217535311000177],
              [36.598604946500188, 45.218122765000146],
              [36.59928622800021, 45.218307001000255],
              [36.605287826000335, 45.219903546000126],
              [36.6079006140003, 45.220598600000073],
              [36.608732210000198, 45.220958411000254],
              [36.611501653000118, 45.222057176000305],
              [36.6194155310003, 45.225196975000301],
              [36.620198401000266, 45.225559893000138],
              [36.621108714166951, 45.226198038666951],
              [36.622529591000216, 45.228166554000268],
              [36.62321148600023, 45.22920071700014],
              [36.624057025000297, 45.230649711000297],
              [36.624941235000279, 45.232164975000273],
              [36.625716974000227, 45.233494352000207],
              [36.628512615000204, 45.237501686000144],
              [36.629496728000163, 45.238912335000236],
              [36.631511451000165, 45.242240865000269],
              [36.636885157000165, 45.251118783000152],
              [36.639045451000186, 45.25422864300026],
              [36.639651896000373, 45.255101654000271],
              [36.645391584000095, 45.261352197000235],
              [36.640924124000321, 45.26302189300003],
              [36.633341318000305, 45.265312479000045],
              [36.624090042000262, 45.26899917500026],
              [36.603465925000137, 45.27738364000021],
              [36.59955538200029, 45.279353174000278],
              [36.597776559000295, 45.280291877000252],
              [36.59171519400013, 45.285440036000125],
              [36.58977417500023, 45.287863618000301],
              [36.588442837000173, 45.289435003000165],
              [36.583660382000176, 45.296397751000029],
              [36.575384640000209, 45.309225647500114],
              [36.575935861000289, 45.30968053700019],
              [36.576728778000245, 45.310334644500131],
              [36.579323431000262, 45.311675867000304],
              [36.588300695000157, 45.316218240000353],
              [36.59067914300033, 45.317320467000286],
              [36.592603031000124, 45.318212041000209],
              [36.593478481000261, 45.318663665000258],
              [36.594549168000299, 45.319216006000204],
              [36.597256633000256, 45.320284400000162],
              [36.600749958000392, 45.32166290300006],
              [36.601856785000223, 45.32205088000029],
              [36.604041368000196, 45.322816644000227],
              [36.605356387000199, 45.323277598000175],
              [36.6130713470003, 45.326903888000174],
              [36.616122163000284, 45.328149363000307],
              [36.617887592000102, 45.328870088000144],
              [36.622355097500019, 45.332395788000042],
              [36.623199902000351, 45.332977291000077],
              [36.627493672000355, 45.335947521000151],
              [36.630678448000253, 45.33815060000029],
              [36.635495941000158, 45.341220894000287],
              [36.638749118500129, 45.342215112000133],
              [36.646705878499972, 45.347544527000139],
              [36.647919415000274, 45.348699283000258],
              [36.648504503000368, 45.349259191000272],
              [36.649916135000069, 45.350497568000151],
              [36.650756862000264, 45.351235109000129],
              [36.651427542000192, 45.352033314000209],
              [36.652564394000251, 45.353386330000035],
              [36.653786369000215, 45.354840653000224],
              [36.667437425000116, 45.360727715000223],
              [36.669112410000253, 45.361428445000115],
              [36.671836346000362, 45.363717457000178],
              [36.674738008000304, 45.366453444000285],
              [36.678694988000302, 45.370184494000284],
              [36.682961379500171, 45.373169410000173],
              [36.68606126949993, 45.376662100500198],
              [36.687211401000184, 45.378455091000262],
              [36.688286250166868, 45.380186292833514],
              [36.690039632000207, 45.383601276000149],
              [36.6910437585002, 45.385538435000115],
              [36.691875769000148, 45.3881528630003],
              [36.692613163000203, 45.390470187000176],
              [36.692902575000176, 45.391379690000178],
              [36.693058742000403, 45.392465417000039],
              [36.69330371600023, 45.394168556000182],
              [36.693614499000319, 45.395883998000215],
              [36.694200552500092, 45.399264143500034],
              [36.694016548000263, 45.402636166000207],
              [36.69411995500019, 45.408673218000217],
              [36.69376484300011, 45.413510672500138],
              [36.69288194800032, 45.420549522000158],
              [36.692340854000349, 45.423072947000151],
              [36.689921460500216, 45.431317525500219],
              [36.690608331000249, 45.434456553000189],
              [36.690925276000257, 45.436693165000179],
              [36.691152758000271, 45.438298462000034],
              [36.69145915450008, 45.440156212000261],
              [36.692207115000201, 45.44469126100006],
              [36.692569718000243, 45.4474098310003],
              [36.692824768000207, 45.449322031000236],
              [36.692899798000212, 45.450144454000167],
              [36.692899858000203, 45.462876059000109],
              [36.692009578000125, 45.470732851000037],
              [36.691918430000214, 45.472376684000267],
              [36.692139106000354, 45.478124988500213],
              [36.692183301000171, 45.513207214000147],
              [36.690800460000048, 45.566376662000266],
              [36.690800522000245, 45.573723607000147],
              [36.691579110000191, 45.657201703000169],
              [36.691172394000318, 45.708326268000292],
              [36.690456894000249, 45.75397648600017],
              [36.682421054000088, 45.8929869990003],
              [36.6848067760003, 45.932119599000202],
              [36.686683940000194, 45.962910405000173],
              [36.689321271000154, 46.003465832500183],
              [36.689516012000183, 46.006460457000173],
              [36.691072295000254, 46.030392134000294],
              [36.691216771000143, 46.032470099500244],
              [36.691328705000331, 46.034080017000178],
              [36.697940118000361, 46.034283210000183],
              [36.750696862000268, 46.036382735000245],
              [36.757498045000148, 46.036653397000293],
              [36.865363053000351, 46.040430448000222],
              [36.876824395000085, 46.040869372000145],
              [36.944890691000296, 46.043476042000179],
              [36.950136583000244, 46.043676939000193],
              [36.954886690000421, 46.045734317000267],
              [36.993753488000323, 46.062568401000078],
              [37.01325605400018, 46.071015400000078],
              [37.052197465000177, 46.087957307000238],
              [37.067689678000249, 46.094697372000212],
              [37.07052579800029, 46.095935259000044],
              [37.088599298000133, 46.103823835000298],
              [37.107145011000284, 46.111843875000261],
              [37.113842981000289, 46.114740392000158],
              [37.14008242600039, 46.126109034000251],
              [37.153497270000116, 46.137300166000159],
              [37.188696235000407, 46.166730940000264],
              [37.189729513000088, 46.167635393000239],
              [37.195984545000158, 46.185198355000182],
              [37.199411789000237, 46.194821418000117],
              [37.200923425000269, 46.199037661000204],
              [37.203385871000251, 46.205905895000228],
              [37.20658722300027, 46.214835078000135],
              [37.207838171000219, 46.218303057000298],
              [37.208323998000196, 46.219633697000063],
              [37.208637349000071, 46.220491939000226],
              [37.209098003000179, 46.221753630000251],
              [37.212702803000411, 46.231569165000167],
              [37.214097684000421, 46.235612964000154],
              [37.215116830000397, 46.238567496000201],
              [37.216874168000118, 46.243662067000287],
              [37.217449278000117, 46.245349702000169],
              [37.217775759000176, 46.246307744000262],
              [37.218057927000245, 46.247135754000169],
              [37.219524237000257, 46.251492702250175],
              [37.220303531000241, 46.253911406000157],
              [37.221790501000214, 46.258526749000225],
              [37.223097826000298, 46.262584500000173],
              [37.223492950000093, 46.263827394000316],
              [37.224235788000101, 46.266111102000082],
              [37.226817219000282, 46.274026330000254],
              [37.230953062000253, 46.287885082000287],
              [37.23911141300016, 46.315616016000035],
              [37.239385939000158, 46.316668407000179],
              [37.240607329000284, 46.32135055700013],
              [37.241229662000194, 46.323736243000155],
              [37.2456556770004, 46.341316230000189],
              [37.247856679000051, 46.350874657000134],
              [37.25048719300014, 46.362298350000231],
              [37.251195593000261, 46.365374764000194],
              [37.258660392000195, 46.401488973000028],
              [37.26332145400022, 46.424749308666833],
              [37.263417782000374, 46.425329282000234],
              [37.267705831000171, 46.444944424000141],
              [37.267900774500049, 46.445989817500049],
              [37.2682827349999, 46.448043301500149],
              [37.270169113000406, 46.45759405400014],
              [37.271001972000136, 46.461801016000038],
              [37.27174075150009, 46.465572965000149],
              [37.272278099000118, 46.468826470000295],
              [37.27135265200036, 46.480825539000193],
              [37.270884044000127, 46.49273465500022],
              [37.269760860000076, 46.51588201300018],
              [37.296697983000172, 46.548081762000152],
              [37.422250919000362, 46.69390129500016],
              [37.445936945000255, 46.720701344000304],
              [37.457109982000247, 46.732944990000306],
              [37.457920932500429, 46.733825410500174],
              [37.481391717000179, 46.758580777000361],
              [37.489029737000124, 46.766370774000166],
              [37.504534720000379, 46.781919606000258],
              [37.512311875000194, 46.788992883000276],
              [37.519885700000259, 46.795901759000287],
              [37.536628740000197, 46.810315601000241],
              [37.550727328000164, 46.821847553000282],
              [37.576386433000209, 46.840772122000203],
              [37.579342531000407, 46.842952357000172],
              [37.583092793000162, 46.845393800000124],
              [37.586327991000132, 46.847499933000222],
              [37.592234965000216, 46.85073062500021],
              [37.596086800000137, 46.852837304000161],
              [37.596967337000251, 46.853299990000266],
              [37.598765051000157, 46.854244614000265],
              [37.60047018600028, 46.8551103180003],
              [37.60196244600013, 46.855867944000238],
              [37.641125660000228, 46.874382173000299],
              [37.65028723100022, 46.878510653000319],
              [37.66322009400028, 46.884338591000244],
              [37.672010212000032, 46.887967314500202],
              [37.673325185000181, 46.887993455000299],
              [37.682150090000391, 46.888168887000234],
              [37.69790080300038, 46.888430819000234],
              [37.725630073000161, 46.888975137000159],
              [37.747226904000172, 46.889399077000235],
              [37.755483761000278, 46.889370431000202],
              [37.760910601000205, 46.889351604000126],
              [37.769581872000117, 46.889087374000269],
              [37.782488607000346, 46.888694081000153],
              [37.793701125000098, 46.88739313300016],
              [37.818461817000298, 46.884488569000212],
              [37.847154372000148, 46.881122776000154],
              [37.864689704500336, 46.879273382000235],
              [37.881505079000249, 46.870215845000189],
              [37.886578987000348, 46.867500765000273],
              [37.896638417500128, 46.862227350500177],
              [37.899032453500126, 46.861043259000098],
              [37.907505846000163, 46.856886425000255],
              [37.909144963000131, 46.85609339600029],
              [37.912752474000285, 46.854348029000164],
              [37.920238297500191, 46.850803939500281],
              [37.930106609000234, 46.846131884000272],
              [37.932334575000198, 46.845180835000178],
              [37.944464353000399, 46.845877557000279],
              [37.950081998000201, 46.846213398000089],
              [37.963938224000231, 46.84704177000026],
              [37.965489800000171, 46.84713467375019],
              [38.086999442000092, 46.909752498000216],
              [38.087706659000105, 46.910103554000329],
              [38.09053001000018, 46.911505037000154],
              [38.132216005000259, 46.932523074000073],
              [38.149401894000334, 46.941224245000171],
              [38.193583962000218, 46.963101840000263],
              [38.207176054000229, 46.967587816000218],
              [38.223426510000081, 46.972842227000172],
              [38.22706089400026, 46.973994764000224],
              [38.230707354000174, 46.97505072000024],
              [38.250799909000364, 46.980745855000237],
              [38.271284085000275, 46.986634330000129],
              [38.278373307000265, 46.988960724000265],
              [38.305221997000217, 46.995876066000278],
              [38.309036148000246, 46.996915295000179],
              [38.320452154000293, 47.000194155000031],
              [38.323274800000263, 47.000929761000179],
              [38.30293566100022, 47.021353416000068],
              [38.291648943000382, 47.038417579000225],
              [38.280271567000113, 47.056628980000255],
              [38.276493790000075, 47.063301203000321],
              [38.267461827000375, 47.072974528000145],
              [38.251782709000167, 47.090651355000205],
              [38.237355505200185, 47.103961836800124],
              [38.235827297925482, 47.109427110817137],
              [38.225545067144196, 47.12776416907775],
              [38.21470911032668, 47.187627051171773],
              [38.227063365089521, 47.21096411369615],
              [38.253873217747412, 47.228045091310719],
              [38.301100213131008, 47.301100083211452],
              [38.223309110646937, 47.339991108225718],
              [38.280818186684371, 47.506100145421442],
              [38.300827130724883, 47.55512707069812],
              [38.353882231040586, 47.608673182903317],
              [38.453873307061144, 47.628873234336481],
              [38.496654038073927, 47.627073136805507],
              [38.537209206149271, 47.62803604984498],
              [38.57749129181849, 47.632482146577729],
              [38.610263359859687, 47.639436108747532],
              [38.758191210826681, 47.689564248466525],
              [38.770400123388185, 47.72081801856217],
              [38.789982260917441, 47.778873091774244],
              [38.822773272059749, 47.838454006644668],
              [38.845963316001928, 47.856800148728212],
              [38.867209261316134, 47.86442701008707],
              [38.897218151148763, 47.868318057190038],
              [38.924709117260477, 47.868318057190038],
              [39.038173264482793, 47.865800133469008],
              [39.118327225478708, 47.837482208787719],
              [39.141518107611091, 47.83430010305733],
              [39.189154139870993, 47.843036057847314],
              [39.254709172427283, 47.852909101625244],
              [39.353873289878265, 47.852482127476378],
              [39.384573183810517, 47.845545096750868],
              [39.40957321592947, 47.831727026413319],
              [39.432209216070305, 47.831382194915761],
              [39.479709126222133, 47.839427145605896],
              [39.604991254040556, 47.839009056274392],
              [39.64304509456349, 47.832764203115175],
              [39.683882229862434, 47.823745107635148],
              [39.731382307652439, 47.819154003977019],
              [39.764291168353679, 47.829300130161187],
              [39.781100237028795, 47.842209099277795],
              [39.803254110097441, 47.868600192051645],
              [39.817836107092546, 47.97436422008127],
              [39.799991202820252, 48.000827061946609],
              [39.776227166146839, 48.024018111717112],
              [39.903591191082, 48.181664108891027],
              [39.917491236432852, 48.198327164812213],
              [39.940818240673252, 48.21707312367846],
              [39.998882198702802, 48.29721803221895],
              [39.921518238005774, 48.368391119213413],
              [39.867209204971118, 48.503882237490018],
              [39.84891821581067, 48.556900122155753],
              [39.814854161210519, 48.577636112479354],
              [39.790127211497861, 48.583609056698947],
              [39.760554180631402, 48.582500130905601],
              [39.732491233445614, 48.582500130905601],
              [39.660191283385132, 48.603918072873682],
              [39.704163250480349, 48.718600110630746],
              [39.727209125687864, 48.762218026135088],
              [39.782773264907121, 48.802773194210545],
              [39.808045205966096, 48.819573210430008],
              [39.829027121329489, 48.824582235781634],
              [39.856945061590011, 48.82124506484206],
              [40.021382244373683, 48.808736080146161],
              [40.039718160535472, 48.820273099347105],
              [40.068954071255007, 48.849782092472935],
              [40.07485409291661, 48.876236049520628],
              [40.05930013042672, 48.890827098971272],
              [40.026800138963381, 48.893745007112841],
              [39.950000280351503, 48.867491209913339],
              [39.775618137060462, 48.901173217365624],
              [39.693736162901246, 48.976518145220268],
              [39.697909177227871, 49.01680006325131],
              [39.705409136571916, 49.035964111449289],
              [39.823327091878014, 49.052218130494282],
              [39.917636075720054, 49.050273193676105],
              [39.941445207032586, 49.064364178781773],
              [39.939436064835832, 49.089718094853822],
              [39.951100153689367, 49.107773217258455],
              [39.966382207239462, 49.123882229378239],
              [40.000200169161644, 49.149364220930977],
              [40.054163364870192, 49.174164260839746],
              [40.081745190812512, 49.183827086485508],
              [40.167636061633857, 49.251664176006727],
              [40.178745100857498, 49.333045079992928],
              [40.172354067306401, 49.352636102339716],
              [40.149018178248468, 49.370545044352468],
              [40.109718116358181, 49.403882220306969],
              [40.08888221374832, 49.424709070461518],
              [40.05464515666614, 49.474018130599802],
              [40.059154117672847, 49.505691163493452],
              [40.079300189649359, 49.530691195612405],
              [40.103609217668691, 49.5425001237532],
              [40.136245163601984, 49.555409092869581],
              [40.148954140508067, 49.577700093874796],
              [40.139763216012625, 49.601054087843522],
              [40.068327104895843, 49.613053955739304],
              [40.042773196613439, 49.616664209085002],
              [39.998463271180981, 49.612354066822206],
              [39.975127214484871, 49.599853966943442],
              [39.954300196692543, 49.580691092212362],
              [39.930409258004943, 49.568600196847967],
              [39.898327188235044, 49.559436094442503],
              [39.859536075506867, 49.55175408016089],
              [39.81242709732021, 49.550545074443505],
              [39.654300146540834, 49.60762700869418],
              [39.629854158223282, 49.627564203642805],
              [39.613054142003875, 49.66860015768583],
              [39.587700225931655, 49.721382175595409],
              [39.470273282514881, 49.757218164531878],
              [39.44277326394797, 49.756664120730306],
              [39.411936074441201, 49.745409068752963],
              [39.383191175611103, 49.735409122960675],
              [39.359718158616772, 49.732909136512774],
              [39.328609227808556, 49.736936138085468],
              [39.29639120356876, 49.74388222126629],
              [39.266109231330063, 49.756664120730306],
              [39.24805410892543, 49.774300147975111],
              [39.23915403647095, 49.802627125111712],
              [39.202973216036867, 49.871236020279639],
              [39.183736077643175, 49.880409175140414],
              [39.121936137719956, 49.856100147121083],
              [39.091936132704859, 49.831109167457669],
              [39.072836122247509, 49.819927038038088],
              [38.971382236480565, 49.807209176314529],
              [38.941863352709106, 49.811027133221387],
              [38.920827122612934, 49.835545038268606],
              [38.898945158483912, 49.861382087240884],
              [38.866391187564091, 49.874436063282872],
              [38.825827134671272, 49.881382146463693],
              [38.689154168043558, 49.935264205349313],
              [38.652218138131275, 49.958882229268795],
              [38.614436206548135, 49.963882202164996],
              [38.587773204834434, 49.964573206264788],
              [38.547218204397268, 49.95470904730422],
              [38.505682186009324, 49.952073106386194],
              [38.384573240155532, 49.984991187180924],
              [38.361245062448461, 50.000691162424687],
              [38.351245116656116, 50.022909073233791],
              [38.343882285248299, 50.046391142683603],
              [38.329645119750921, 50.06568209289577],
              [38.304854132297635, 50.073882108795317],
              [38.186863254433774, 50.065545132597379],
              [38.103609162720545, 49.938600202822215],
              [38.024227175009514, 49.903082223293325],
              [38.01360914767514, 49.934436073313123],
              [37.98165414755789, 49.969300096754637],
              [37.924782263801376, 50.024573216295039],
              [37.823327204568159, 50.061664143778387],
              [37.793327199552891, 50.06916427076078],
              [37.746109256624663, 50.08048218701208],
              [37.649436073166612, 50.184154092003268],
              [37.461936083731302, 50.436173110759682],
              [37.418600135450561, 50.438045125020096],
              [37.322627176185449, 50.428745068144735],
              [37.292500269155767, 50.412209081875915],
              [37.270827182054575, 50.393882218169836],
              [37.245691195465724, 50.379854097338125],
              [37.216936070713643, 50.369709144620231],
              [37.130545135547493, 50.348327077198178],
              [37.059227209265771, 50.337909042074159],
              [37.01680019456802, 50.346936016543225],
              [36.975963226906913, 50.349154035767697],
              [36.891800201611034, 50.337353992444264],
              [36.810273284871187, 50.314154057856456],
              [36.717491148515933, 50.274709156678739],
              [36.693463249529941, 50.262491191661752],
              [36.669991238364105, 50.243609110687601],
              [36.651445104070376, 50.219436037138166],
              [36.608463207380737, 50.21304500358724],
              [36.578463370003419, 50.217345087566287],
              [36.554054094422042, 50.231000213705613],
              [36.559891251809859, 50.269364184646633],
              [36.477209141170874, 50.301382216676245],
              [36.419991252450302, 50.313882148916434],
              [36.375127115578437, 50.2958270265122],
              [36.353745048156213, 50.287218141374638],
              [36.32708221408086, 50.280827107823541],
              [36.300136071676889, 50.283600176677908],
              [36.279163208768864, 50.294445018312558],
              [36.215827201265483, 50.355000077972875],
              [36.19957318222032, 50.373327109317131],
              [36.188391220438916, 50.398318088980602],
              [36.14846335399875, 50.422282117864199],
              [36.091936134101843, 50.431109100123081],
              [36.059436142638447, 50.434991094770623],
              [36.00777327644397, 50.439154050813215],
              [35.978600229998392, 50.438327092243526],
              [35.927491239967139, 50.435818053340142],
              [35.894154064012412, 50.433327119347553],
              [35.85805404512513, 50.429718039468128],
              [35.827636118416535, 50.42304503869326],
              [35.743191293535233, 50.385273165394096],
              [35.693091149372833, 50.347409091159875],
              [35.681382301156532, 50.346936016543225],
              [35.644718180183872, 50.354164234585653],
              [35.597900221676298, 50.373600191723426],
              [35.458282189540739, 50.488064132359341],
              [35.441054025705796, 50.511973175957962],
              [35.399318182745901, 50.641591094663056],
              [35.486073227786733, 50.678627036861428],
              [35.460863312811739, 50.763073202847409],
              [35.357791216813439, 50.928582095774118],
              [35.305773293199366, 50.960336097852746],
              [35.309663334473896, 50.982554008662021],
              [35.344945111970958, 51.006436062532146],
              [35.370500193719693, 51.021354006207332],
              [35.36882716584114, 51.042127044543236],
              [35.293545102260623, 51.059927021814531],
              [35.244091202835079, 51.050909099800776],
              [35.222145200965656, 51.048418165808243],
              [35.195063271730135, 51.048273158882807],
              [35.165745050721114, 51.058554066070428],
              [35.150745132032455, 51.073700165150797],
              [35.119636201224239, 51.151764182403156],
              [35.124918141343983, 51.178573196870389],
              [35.119009234864848, 51.19926426019282],
              [35.076445092230699, 51.220644986510763],
              [34.974991206463983, 51.2236001103023],
              [34.944291144893555, 51.216027060762315],
              [34.92027330419171, 51.197909074083725],
              [34.82721808542999, 51.17804513696899],
              [34.801100242700386, 51.173609098519876],
              [34.766663361046028, 51.171382194478099],
              [34.734991166342525, 51.172491120271218],
              [34.708600241206796, 51.1741641481496],
              [34.672354041927974, 51.178464232128817],
              [34.550545039518852, 51.233327141326697],
              [34.406936215632186, 51.263609113565394],
              [34.382209265919187, 51.263609113565394],
              [34.239854039475063, 51.431936002347641],
              [34.245200184973442, 51.501173204979068],
              [34.234645189551202, 51.54415409584044],
              [34.163045128407703, 51.614718153748186],
              [34.101863270026342, 51.647700104645537],
              [34.105545104825524, 51.674782201519236],
              [34.19777319737932, 51.696654107364282],
              [34.255273220961499, 51.701100204097202],
              [34.388954183423493, 51.715200074020004],
              [34.426109148647129, 51.72831808780262],
              [34.441100182518426, 51.754918057604186],
              [34.422218101544274, 51.804164253468343],
              [34.384036185540594, 51.843109090301084],
              [34.337354181502974, 51.856664136516542],
              [34.240545043574684, 51.91137315397151],
              [34.129154076289751, 51.986109052739607],
              [34.083609161239906, 52.037491125176928],
              [34.073609215447391, 52.058609162648565],
              [34.077636217020313, 52.121245113596785],
              [34.099782211100091, 52.145054077271311],
              [34.062909213099942, 52.196109087845969],
              [33.831663232679603, 52.363182211547439],
              [33.762218158848441, 52.368736060606977],
              [33.688882205552773, 52.35971813859345],
              [33.628600228298751, 52.341936098595113],
              [33.601663305988609, 52.331936152802768],
              [33.512773222564533, 52.326945064723759],
              [33.417700144426874, 52.355400117341389],
              [33.378600242385119, 52.365000078713194],
              [33.205682249944743, 52.378600051929595],
              [33.038045191795902, 52.305827027252519],
              [32.97192723062085, 52.272764274808367],
              [32.941654143199457, 52.253882193834386],
              [32.916800123834037, 52.247627114753016],
              [32.758609135314202, 52.25777324093724],
              [32.731654108093096, 52.261382153178602],
              [32.700827144508253, 52.268327062892979],
              [32.639163326693108, 52.289436047908907],
              [32.60889124510004, 52.300545087132718],
              [32.584436204327005, 52.309718074355317],
              [32.552491262493675, 52.319991102554127],
              [32.527218147968426, 52.324436025820376],
              [32.413318141779598, 52.335545065044016],
              [32.389163340779135, 52.334018049919337],
              [32.36443605579052, 52.31895409349022],
              [32.331700197570967, 52.169400153302604],
              [32.298118102404942, 52.109154050594384],
              [32.22499119377477, 52.079436012802717],
              [32.12374517920739, 52.044436034891476],
              [31.995827110470827, 52.05443614832194],
              [31.908882299141226, 52.069445119465911],
              [31.900000163959589, 52.093182166410827],
              [31.856109166048952, 52.107909170331368],
              [31.825273317646804, 52.111382128102662],
              [31.783882306184239, 52.108045124801095],
              [31.786109210226243, 52.124164195204855],
              [31.786036120030303, 52.157554009511728],
              [31.765418146903812, 52.176382111029227],
              [31.701382250483164, 52.221100067509212],
              [31.59381812492245, 52.313118109569075],
              [31.605973225665252, 52.331800198332871],
              [31.628745180275928, 52.367218097937666],
              [31.608891133806878, 52.491382247507431],
              [31.587082259873796, 52.592209166914984],
              [31.505136080335888, 52.687836121215867],
              [31.555136144574021, 52.701454031705111],
              [31.593745202364687, 52.737145013716145],
              [31.587500181567293, 52.782218027615897],
              [31.579718087361471, 52.804573233999349],
              [31.538473256290615, 52.854718137524685],
              [31.508891172969015, 52.857500091196357],
              [31.484854053889478, 52.861245125545679],
              [31.438882164690938, 52.879436034782032],
              [31.415273193226767, 52.893054112909056],
              [31.390827204909215, 52.910273224288687],
              [31.266663222977229, 53.023318108712999],
              [31.314718182759009, 53.051382229365458],
              [31.386836077881924, 53.104018066883157],
              [31.374827157531001, 53.121936061351391],
              [31.383191291095045, 53.153464254957839],
              [31.400000192131984, 53.188600187339205],
              [31.425754092624743, 53.208809123589504],
              [31.623191163578383, 53.217764181329301],
              [31.776454095931513, 53.19270011146989],
              [31.789445040061196, 53.170273155994778],
              [31.799654030519491, 53.132145051809573],
              [31.918327195303846, 53.098327089887391],
              [31.958045178887687, 53.094436042784366],
              [32.112073211364816, 53.087209165846275],
              [32.14207321638014, 53.08957319782462],
              [32.223318165896217, 53.105553966825312],
              [32.298882196700333, 53.131936007143906],
              [32.421936079372472, 53.19610014668342],
              [32.446100268104601, 53.214991112474934],
              [32.611936055256024, 53.313045130666126],
              [32.72443611597248, 53.360273131878159],
              [32.736545116247669, 53.438364138858844],
              [32.740054116202771, 53.458809109503477],
              [32.665127110041681, 53.498464228813361],
              [32.641245056171556, 53.501664271816537],
              [32.620273199091798, 53.499854116001814],
              [32.473736073503517, 53.55277326184779],
              [32.443527191460589, 53.572845070161861],
              [32.424436065820544, 53.601245137494573],
              [32.420545186355696, 53.647564205123615],
              [32.505482196592936, 53.68277322770075],
              [32.484300121381068, 53.712218183086122],
              [32.456663310153829, 53.72457310840133],
              [32.329436077878881, 53.762218079686079],
              [32.119709125178986, 53.819991185674496],
              [31.993045156799099, 53.802491112899816],
              [31.971382295619549, 53.798336035845978],
              [31.935554185672288, 53.78916422208971],
              [31.890200210377429, 53.778118047140197],
              [31.764227078459214, 53.804345022249208],
              [31.779163294683428, 53.837353962874886],
              [31.805200168228055, 53.856518178710644],
              [31.828882229888222, 53.886382229255844],
              [31.857218091842128, 53.969709075888659],
              [31.865273268454246, 54.00610011445491],
              [31.84555417062677, 54.060964197119233],
              [31.755836122804851, 54.104854021563199],
              [31.726245154665719, 54.10638221015455],
              [31.67555408632802, 54.106654119094344],
              [31.544163227458284, 54.143327124884138],
              [31.524163335873482, 54.150827084228411],
              [31.328609175654321, 54.243253995526061],
              [31.310409213962657, 54.300409187610796],
              [31.31200915164527, 54.341309187183924],
              [31.225963215614684, 54.459300065047785],
              [31.198600157345879, 54.460936044914035],
              [31.130554023158822, 54.478600067715263],
              [31.092700174846414, 54.4926270150807],
              [31.087291165074021, 54.516173122270743],
              [31.111382263610039, 54.54777323260646],
              [31.138191278077443, 54.576382176966831],
              [31.103054172229747, 54.645545115936216],
              [31.008745188387707, 54.70916426412964],
              [30.919445062259285, 54.751391118979029],
              [30.876382196385066, 54.769154048238079],
              [30.838054099989336, 54.78124511124048],
              [30.782709231357671, 54.799091188979219],
              [30.794718151708764, 54.843609153249076],
              [30.838891284480837, 54.922491076615472],
              [30.86471810753153, 54.947209141510825],
              [30.908191183748443, 54.954018096755419],
              [30.940409207988012, 54.96388208807781],
              [31.027536074255124, 55.048500083079318],
              [30.97805417927276, 55.152218088538348],
              [30.944027172684912, 55.191654104898532],
              [30.91790916231713, 55.200964220057699],
              [30.815973317116459, 55.301727101724794],
              [30.831391157498615, 55.329991214587437],
              [30.857773197816982, 55.35360907086897],
              [30.889300217956759, 55.375691194846297],
              [30.913463233222558, 55.378600218170618],
              [30.936245078478919, 55.399364204050983],
              [30.939991286294685, 55.495827169376867],
              [30.930273307725997, 55.577773181276768],
              [30.926245132686574, 55.602573221185366],
              [30.914582217299539, 55.610691094433491],
              [30.885000133977769, 55.618464136184002],
              [30.845691187269949, 55.614782133746644],
              [30.788745039851221, 55.594709151966072],
              [30.757500154572881, 55.597000093748306],
              [30.655273289693241, 55.660544978279518],
              [30.610963364260783, 55.717764208104711],
              [30.491518226191658, 55.79901804243832],
              [30.466663200998141, 55.805545030459314],
              [30.245827209074605, 55.854436001266095],
              [29.972518156193189, 55.85637322673324],
              [29.937354060617224, 55.853053993066624],
              [29.906936133908403, 55.846100198534828],
              [29.656382271831376, 55.765273170712078],
              [29.524436195693568, 55.692973220651425],
              [29.484991294515851, 55.692209126356033],
              [29.382291187381611, 55.759991230592107],
              [29.35979114171073, 55.796800190852025],
              [29.410273165382591, 55.89749115578951],
              [29.455654130405549, 55.919400109646858],
              [29.410063282526437, 55.957909087513428],
              [29.172218229717487, 56.015545065565505],
              [29.139163356262543, 56.021936099116601],
              [29.05943620177743, 56.032773229400391],
              [29.027636099231358, 56.029436058460817],
              [29.006109192521706, 56.023882209401222],
              [28.865136141915002, 55.978318183612259],
              [28.843809227415704, 55.951164170009235],
              [28.746109260815615, 55.95610915762046],
              [28.725491287689124, 55.968564162859707],
              [28.707009191135455, 56.003809059982643],
              [28.700554119844128, 56.033600187970137],
              [28.65000017944277, 56.091245050839689],
              [28.620963255105096, 56.099300059813629],
              [28.528191177033705, 56.105136043734831],
              [28.378954072786996, 56.09040903981446],
              [28.348036081733426, 56.055527079100102],
              [28.30990026619736, 56.051364123057454],
              [28.187082250281208, 56.134018070501497],
              [28.168609206183078, 56.148609119951914],
              [28.168009229552183, 56.150154072349437],
              [28.183463279755983, 56.16193601076202],
              [28.197082196073495, 56.181236013429725],
              [28.235963330442019, 56.276382181763097],
              [28.182773281484856, 56.406382147616227],
              [28.147773303573388, 56.471373245725317],
              [28.114163212850372, 56.517491147677731],
              [28.009445078702015, 56.648327124555919],
              [27.943609252388399, 56.722763286465579],
              [27.92672709351757, 56.794018180835167],
              [27.938818156519915, 56.822427132985183],
              [27.860554147057371, 56.868882155084123],
              [27.837773307629163, 56.875545097575184],
              [27.802500247311627, 56.878182211959484],
              [27.762500128866009, 56.873600160756837],
              [27.731109230834136, 56.869154064024144],
              [27.70166326962044, 56.914709204995631],
              [27.774718093882825, 57.057209103089349],
              [27.849373191104291, 57.179918154263703],
              [27.863882265541463, 57.262500184978308],
              [27.85534512949539, 57.305963202911414],
              [27.825273207750627, 57.323882203207972],
              [27.687218232923499, 57.379991166135369],
              [27.606382152645267, 57.410545047313917],
              [27.571391227189338, 57.418191187050013],
              [27.527773311685337, 57.444327134690468],
              [27.542773230373655, 57.493609205100597],
              [27.547218153640074, 57.536382224762235],
              [27.471663342929446, 57.531382251866262],
              [27.449436212026683, 57.531109169459967],
              [27.42500011435493, 57.531936128029486],
              [27.372054146418634, 57.53563606773946],
              [27.362500117876436, 57.545827120924798],
              [27.348436122499095, 57.588982187734473],
              [27.403327194891574, 57.681518063773922],
              [27.54860921429497, 57.818609119733082],
              [27.577218158655171, 57.822773249242402],
              [27.630000176564977, 57.828609065525598],
              [27.673609207251786, 57.830273208586448],
              [27.702218151612158, 57.831663263413077],
              [27.744018199950574, 57.837354072770893],
              [27.785273256943185, 57.848327157524807],
              [27.820200144658713, 57.86742716798193],
              [27.813263281571381, 57.886245043577674],
              [27.779027230317524, 57.900545073349178],
              [27.686663350931781, 57.965273147335779],
              [27.673609207251786, 58.075691143297604],
              [27.645554139054866, 58.095127100435207],
              [27.609718150118567, 58.107218163437551],
              [27.572918242314131, 58.122209197308734],
              [27.551527122436596, 58.134853968836524],
              [27.475754046966642, 58.213118145937074],
              [27.46590011392783, 58.296936004459326],
              [27.491245145182575, 58.324854112357912],
              [27.520418191628153, 58.344018160555663],
              [27.539854148765528, 58.364782146436028],
              [27.543609241398826, 58.393882102685893],
              [27.54250014796753, 58.413882161908646],
              [27.535273271029382, 58.45082707663812],
              [27.519445052666924, 58.504027016241253],
              [27.498327182833464, 58.554436117355522],
              [27.489300208364398, 58.573609050370465],
              [27.451109239905236, 58.68693606965661],
              [27.421663278691369, 58.784853965739899],
              [27.428327227010527, 58.815891147456853],
              [27.439436098596218, 58.83416319351619],
              [27.457218138594556, 58.852491230688884],
              [27.49360917716092, 58.881936018436136],
              [27.541945098337806, 58.904991113736742],
              [27.596945135471856, 58.931382206510648],
              [27.689436084509964, 58.979427108008394],
              [27.704991220466354, 58.985827026376967],
              [27.790136101902988, 59.060963244841957],
              [27.806945170578047, 59.093882163827175],
              [27.837218090361318, 59.150827137779402],
              [27.879845097269538, 59.228053970540259],
              [27.91649128096924, 59.273663258606575],
              [27.961309149735484, 59.28631808841817],
              [27.995836053030303, 59.284473231524089],
              [28.020618155665886, 59.278409092197762],
              [28.055418141366999, 59.27920017622165],
              [28.095491182370381, 59.283945003984513],
              [28.144000273667473, 59.290800059696551],
              [28.164300237386556, 59.303718081268642],
              [28.179318093348002, 59.32823615395364],
              [28.194091197735986, 59.36797324827694],
              [28.175554115897569, 59.393609131572589],
              [28.109436154722346, 59.436100184010968],
              [28.07610920468943, 59.453327174379353],
              [28.026391275313131, 59.474991209025234],
              [28.01582736190494, 59.478600075192901],
              [28.020698492944973, 59.482139280076012],
              [28.021945392526959, 59.483045275844404],
              [28.039436198949886, 59.502218145093082],
              [28.066245045864378, 59.544436115108965],
              [28.067511168993406, 59.554183299001011],
              [28.06836095425939, 59.560726087033231],
              [28.069582216834362, 59.570127151218514],
              [28.069420812910892, 59.57099328798347],
              [26.915000000000134, 59.631666669000253],
              [26.440000000000168, 59.938333329000329],
              [26.335783332999938, 59.994633333000081],
              [26.346666667000193, 59.999999999000238],
              [26.543333333000135, 60.106666666000137],
              [26.640000000000271, 60.113333332000366],
              [26.798333333000244, 60.146666666000215],
              [26.915000000000134, 60.173333332000027],
              [26.965000000000202, 60.176666666000131],
              [27.18833333300023, 60.176666666000131],
              [27.463888889000089, 60.228333332000091],
              [27.511944444000108, 60.259722221000231],
              [27.553424148000261, 60.281981681000104],
              [27.60621647000022, 60.315710108000246],
              [27.651676524000209, 60.337706909000303],
              [27.700069486000132, 60.371435336000104],
              [27.736730820000219, 60.406630217000043],
              [27.754328260000136, 60.444758005000324],
              [27.766059887000154, 60.474087072000202],
              [27.774858608000102, 60.513681313000234],
              [27.790989595000156, 60.531278754000255],
              [27.807827388046007, 60.546399787706093],
              [27.884718168151323, 60.589163235446733],
              [28.093891244687768, 60.712218123947196],
              [28.246391256211837, 60.793327118993545],
              [28.269445178046112, 60.804163243449068],
              [28.298609172036208, 60.820545002698793],
              [28.329445188076534, 60.841518033245165],
              [28.34194512031695, 60.858254011724171],
              [28.388882269487851, 60.886663299150371],
              [28.466391237110315, 60.925282247586836],
              [28.529509147492661, 60.954582196046829],
              [28.577773319578057, 60.961663227869053],
              [28.619645117007906, 60.960000090636527],
              [28.64555408270985, 60.97110912986011],
              [28.674163362346405, 61.001663178676665],
              [28.720000135265309, 61.045273215191969],
              [28.816663260439782, 61.105136097285992],
              [28.97083613220434, 61.162773248804569],
              [29.071945186473329, 61.199436028672608],
              [29.16582719616656, 61.232082200527486],
              [29.213609241179824, 61.253327140013539],
              [29.249718144884412, 61.275836070501725],
              [29.274300255309868, 61.292082210557908],
              [29.28507318021525, 61.302863182090448],
              [29.30193606070884, 61.318327122939934],
              [29.334163305042068, 61.345273265343678],
              [29.355273295886491, 61.359718134402442],
              [29.497500111573743, 61.436663167577706],
              [29.652500277183833, 61.517773168452379],
              [29.68332707313067, 61.533882180572334],
              [29.801945084991758, 61.603745008458702],
              [29.998473222362577, 61.734436146049461],
              [30.142173241356204, 61.846953976400698],
              [30.1551001477456, 61.856345060744616],
              [30.203891206266121, 61.890545069814721],
              [30.30055416380236, 61.956109154826493],
              [30.396109201373974, 62.016663208658315],
              [30.632500161616065, 62.166109189932683],
              [30.704163254671528, 62.210545011551233],
              [30.792500132484122, 62.253882133298248],
              [30.834445187748258, 62.271382206072985],
              [30.964163354015625, 62.339163304480905],
              [31.082773319249839, 62.413053972130513],
              [31.258191130500109, 62.508254119920537],
              [31.280282306933145, 62.536109195907272],
              [31.294163241544652, 62.564163258275755],
              [31.310554053249859, 62.598053975117693],
              [31.32194505969693, 62.615273254135332],
              [31.430000197147137, 62.758891130477309],
              [31.474027149527615, 62.812636061426531],
              [31.501391213624572, 62.835545143973604],
              [31.52166334942558, 62.853336068789304],
              [31.581963263952218, 62.907900079319177],
              [31.519582290499329, 62.98263614572511],
              [31.383054163158704, 63.069163202999334],
              [31.242636162181725, 63.204509146712496],
              [31.21971819481729, 63.223054107540122],
              [31.186391244784431, 63.238327108635076],
              [30.912218185321365, 63.353609123022977],
              [30.714718082455761, 63.424436037415262],
              [30.648891140959449, 63.439718090965528],
              [30.478400212409611, 63.480973147957968],
              [30.457218137197742, 63.503609148098747],
              [30.395418197274353, 63.548045137355473],
              [30.357500143583309, 63.560000078249743],
              [30.308891140000128, 63.572491125672855],
              [30.24555412666831, 63.593263158180378],
              [30.206391192714193, 63.612782096159378],
              [29.998954175968265, 63.735336082124945],
              [29.996709167015297, 63.748027121758355],
              [30.008891257486596, 63.754582105335942],
              [30.033054105114047, 63.760827126133165],
              [30.063054110129144, 63.766663277692544],
              [30.114718149790292, 63.775273168658373],
              [30.135000176236701, 63.780000226786456],
              [30.210554148757012, 63.800827076940777],
              [30.263473294603045, 63.822627066056555],
              [30.398054138192322, 63.930545075570137],
              [30.480273232498348, 63.973327147687598],
              [30.528609153675177, 64.000000207684934],
              [30.551245153816012, 64.015273208779888],
              [30.59527327966282, 64.046936015751555],
              [30.610273198351479, 64.070400147928353],
              [30.61277318479938, 64.09415412631779],
              [30.577354111728084, 64.223736002839246],
              [30.563536209028712, 64.24089107647842],
              [30.389718167822878, 64.309436101543724],
              [30.359445080401485, 64.319718182197846],
              [30.321663316456693, 64.329436160766704],
              [30.1969451230853, 64.348873123732631],
              [30.163191198903405, 64.352763165007161],
              [30.129163354125239, 64.364218041556853],
              [30.061527095004521, 64.405345023068435],
              [30.085691283736423, 64.459991176249616],
              [30.087909135322889, 64.480691124389352],
              [30.068609132655411, 64.494718071754562],
              [30.048882155838555, 64.50249111350513],
              [30.01052706971484, 64.512263239168647],
              [29.986945087979109, 64.553054106361969],
              [29.976454130297384, 64.57894513479107],
              [30.02694503878655, 64.59762705591686],
              [30.072500179758208, 64.604427126343808],
              [30.141663286365599, 64.619427045032467],
              [30.176454051973053, 64.631382153565085],
              [30.206873319786155, 64.663318042942933],
              [30.142773217987269, 64.772073236948188],
              [30.121109183341218, 64.784154074028777],
              [30.081109232533777, 64.792636057151924],
              [30.048054023802706, 64.795545080476018],
              [29.950273255655816, 64.795545080476018],
              [29.926391201785293, 64.79470906945096],
              [29.891663300451796, 64.793045094027946],
              [29.843054129230666, 64.792500102681856],
              [29.80889116817275, 64.794018065351167],
              [29.774436181607371, 64.809709155777455],
              [29.755000224469939, 64.822491222879648],
              [29.733609104592233, 64.83720917434465],
              [29.714445056394254, 64.852218145488791],
              [29.671945119138741, 64.887209070944607],
              [29.652773191952178, 64.904709143719344],
              [29.640827135874986, 64.920963162764338],
              [29.608127152201291, 65.005963204913712],
              [29.621182301709723, 65.052354021634244],
              [29.645973289163067, 65.071936159163499],
              [29.674163306001219, 65.081800150486117],
              [29.726391280109624, 65.092500152833736],
              [29.773891190261452, 65.094709119602726],
              [29.806945057887901, 65.099154042868975],
              [29.836663263317746, 65.106936137074968],
              [29.869445054366565, 65.119991118945279],
              [29.870418193327794, 65.202000162757088],
              [29.848327184533105, 65.218045137136642],
              [29.820691211496182, 65.223463199364119],
              [29.787500215933164, 65.226382113334239],
              [29.737218184471601, 65.226654022274033],
              [29.65889114309698, 65.223327077256386],
              [29.633054094124475, 65.225963185812361],
              [29.602045075602121, 65.244436062272371],
              [29.613054034901666, 65.271236024284292],
              [29.67110910811374, 65.30290017236041],
              [29.706109253663158, 65.31971812585283],
              [29.748609190918671, 65.346100166171254],
              [29.745554154840875, 65.414718113794549],
              [29.739718170919502, 65.455554075627163],
              [29.738327110264777, 65.481109157376068],
              [29.74610920447077, 65.514436107408756],
              [29.770273225564551, 65.526654072425742],
              [29.798054037888903, 65.537491202709589],
              [29.834163276869504, 65.588336162790029],
              [29.818882229147562, 65.653318040805573],
              [29.992500278143211, 65.685273208560886],
              [30.030273324908819, 65.690545090396824],
              [30.076663303439034, 65.675418102055801],
              [30.120200249758398, 65.668118134921883],
              [30.134927086040761, 65.708882180025057],
              [30.102500184773362, 65.818600119431778],
              [30.093891131997793, 65.838054013842054],
              [30.032218094088989, 65.922209160148896],
              [29.948327145370968, 66.049709139553954],
              [29.918891242441077, 66.11278212293513],
              [29.903745143360709, 66.133882223133753],
              [29.876391137547387, 66.152218139295371],
              [29.850000212411487, 66.163609145742612],
              [29.826109106085823, 66.175554028353304],
              [29.799163298958206, 66.192491172509108],
              [29.77652713117925, 66.209718162877493],
              [29.631945168331129, 66.329436048076559],
              [29.590273195473458, 66.38276322497029],
              [29.577154175862916, 66.403045083778693],
              [29.567927209183495, 66.428573175799215],
              [29.554445085526197, 66.470273144213536],
              [29.414163206657122, 66.599991142843066],
              [29.385545042203262, 66.628309067524128],
              [29.354863253181804, 66.677909147341552],
              [29.324445158835204, 66.704573154883576],
              [29.273327116348639, 66.7383270790653],
              [29.252918187887644, 66.750827178943837],
              [29.211936213301442, 66.770682231241267],
              [29.128745153500518, 66.820400160617567],
              [29.09381809814704, 66.851518143881492],
              [29.076109148344472, 66.877491147323724],
              [29.075136177021193, 66.903600105236023],
              [29.08152721057229, 66.951100183026028],
              [29.090136095709681, 66.972909056959111],
              [29.134163215728222, 67.013609064321656],
              [29.156945060984697, 67.030818117417255],
              [29.525273212547063, 67.293882137656851],
              [29.745554154840875, 67.416382144165595],
              [29.881663354659509, 67.483600146316633],
              [29.900000276649564, 67.492491166315801],
              [29.93278223533639, 67.513882118555387],
              [29.959718151818208, 67.538609068268215],
              [29.977773274222841, 67.572491235569032],
              [30.028609181847628, 67.694718159671481],
              [29.902500263097636, 67.740827176806533],
              [29.727500205902743, 67.803327173284856],
              [29.69166321113795, 67.815273229361992],
              [29.539445166837538, 67.938891214119394],
              [29.383609157840567, 68.062482209148641],
              [29.356945150298372, 68.082491153188755],
              [29.16000026470067, 68.116654114246842],
              [28.82332720875894, 68.172482115778791],
              [28.695000270784533, 68.195409135598709],
              [28.663054155484588, 68.239436087979186],
              [28.560000164397252, 68.385818148358283],
              [28.459854023167509, 68.534854086928135],
              [28.518054103304962, 68.583882185671087],
              [28.655000152338914, 68.679991099406095],
              [28.738609133833393, 68.73416317214236],
              [28.761663223305675, 68.754854067826614],
              [28.79971823729494, 68.810136072184491],
              [28.817354096901397, 68.847000185367335],
              [28.792773159942612, 68.857773277910724],
              [28.737782175263646, 68.875827059210678],
              [28.705827175146396, 68.87832704565875],
              [28.565000137293396, 68.874436166194073],
              [28.5091632509438, 68.872773196599155],
              [28.470827107921252, 68.874436166194073],
              [28.439863351676195, 68.882354047231672],
              [28.435554047603659, 68.902636073678082],
              [28.451800187659842, 68.916518181756089],
              [28.746391228039215, 68.997209087470765],
              [28.855554117816041, 69.029709078934161],
              [28.877782254547299, 69.036100112485258],
              [28.913327223804686, 69.043318104605873],
              [28.957336071274028, 69.05161820042963],
              [29.000054105650861, 69.036873259236302],
              [29.020000185417075, 69.031391159268196],
              [29.056527178453166, 69.028736107610712],
              [29.099163237816924, 69.036373194891496],
              [29.129718124823967, 69.048044995095722],
              [29.177773252243526, 69.070545040766604],
              [29.200418137201837, 69.083191153399071],
              [29.234863233121359, 69.104718060108553],
              [29.254445035374488, 69.119991228841286],
              [29.295691207549794, 69.188327041602975],
              [29.318554021991076, 69.243418106205809],
              [29.307918225021865, 69.264445116208492],
              [29.295554079613225, 69.295127072868127],
              [29.313882284424153, 69.316182246065409],
              [29.408336107553282, 69.333054011376476],
              [29.48360911867843, 69.340818168309511],
              [29.53832718858888, 69.347218086677913],
              [29.571945158300935, 69.353882202635305],
              [29.603336056332978, 69.360273236186401],
              [29.66500020942425, 69.373882261858114],
              [29.898609215994668, 69.432773178457012],
              [30.062773316372045, 69.487782100408594],
              [30.097773294283456, 69.503327178081349],
              [30.181527115065137, 69.57803608712112],
              [30.137282233201631, 69.628363213221974],
              [30.095136179898816, 69.628191216568212],
              [30.076663303439034, 69.646591170470458],
              [30.109582222424251, 69.66384515056717],
              [30.129445153710662, 69.666382185027373],
              [30.165000181251855, 69.668044986983944],
              [30.195973325228351, 69.665409213703981],
              [30.305273175303398, 69.630554075080113],
              [30.3405541146102, 69.617209079358986],
              [30.370554119625126, 69.603045004057549],
              [30.393054165296007, 69.590545071817019],
              [30.453327090094717, 69.567218067576562],
              [30.520273183305733, 69.547627045229774],
              [30.546109226449914, 69.54415408745848],
              [30.767218133141569, 69.530545061786768],
              [30.796945055750598, 69.530273152846974],
              [30.824718156724089, 69.531663207673375],
              [30.863891148962011, 69.536654128114264],
              [30.91236335988475, 69.550200121873843],
              [30.941245051375063, 69.57401813800368],
              [30.950827075474024, 69.608745033508853],
              [30.947218163232833, 69.654436128950238],
              [30.94333616858512, 69.690554085110364],
              [30.902773289158972, 69.731936044117219],
              [30.854836259964429, 69.792309114428974],
              [30.819463290375268, 69.796143345250186],
              [30.930712021000204, 69.867693006000309],
              [31.063023250000128, 69.952952889000187],
              [31.104363393500194, 69.979315370000165],
              [31.444800000000214, 70.099677778000228],
              [31.505397222000283, 70.120888889000241],
              [31.775991667000085, 70.197688889000176],
              [32.073329128000125, 70.274897766500089],
              [36.538457061000258, 73.366754043000185],
              [37.000000000000114, 73.686347222000279],
              [37.000000000000114, 74.932394768000051],
              [37.000000000000114, 75.194722222000166],
              [38.000000000000227, 75.800205556000037],
              [38.000000000000227, 78.622896405000176],
              [38.000000000000227, 78.62486111100003],
              [37.928517317000228, 78.640576650000298],
              [34.998888889000114, 79.284658333000209],
              [34.999187978000066, 80.305480129000273],
              [35.000080556000398, 83.351944444000196],
              [34.217595847333712, 83.762466538666843],
              [34.590028050000228, 83.815204463000157],
              [34.687924482000199, 83.824223741000196],
              [35.397611552000171, 83.889607786000113],
              [35.677793533000113, 83.915421006000201],
              [35.960066059000269, 83.940038407000202],
              [36.801315295000222, 84.013404469000193],
              [37.814343503000259, 84.096911487000284],
              [37.961512535000338, 84.109043006000093],
              [38.402562624000325, 84.17837524000015],
              [39.301401031000239, 84.319670698000209],
              [39.317676317000064, 84.321376967000106],
              [39.323032537000188, 84.322182006000162],
              [39.331945616000212, 84.323115624000309],
              [39.333228345500231, 84.323249974000248],
              [39.33420953700022, 84.323397006000164],
              [39.748545609000246, 84.365624048000313],
              [40.360447530000329, 84.427985458000308],
              [41.425354563000383, 84.530743691000168],
              [42.38879059500016, 84.618645819000278],
              [42.530411540000273, 84.631567006000239],
              [43.677094541000201, 84.73034400600028],
              [44.757583542000191, 84.791487006000182],
              [45.246805759000154, 84.817712493000215],
              [45.863214831000391, 84.85075571800013],
              [46.478927636000265, 84.881969949000222],
              [46.994042544000251, 84.908084007000184],
              [47.493899839000164, 84.932005687000071],
              [48.150048013000344, 84.963406648000159],
              [49.331128596000099, 85.016655974000173],
              [49.334733143000335, 85.01674995400009],
              [49.341438380000113, 85.017042483000068],
              [49.415954546000421, 85.020293007000078],
              [49.485989161000361, 85.022102298000277],
              [49.516944923000324, 85.022901844000216],
              [49.635433901000226, 85.028018476000227],
              [49.641930332500181, 85.028299000500226],
              [49.647132611000245, 85.028431679000221],
              [50.148174320000294, 85.049500607000198],
              [50.532325547000141, 85.065654007000205],
              [51.621315548000098, 85.090652007000131],
              [52.721011549000139, 85.113876007000215],
              [53.83076455000014, 85.135301007000123],
              [54.949881551000033, 85.1549030070002],
              [55.680980788000227, 85.166414538000083],
              [56.077630958000327, 85.172659882000232],
              [57.213244520000103, 85.188550774000191],
              [57.26290399300035, 85.188467567000146],
              [57.281025363000253, 85.18870380300018],
              [57.422834964000174, 85.188428216000204],
              [57.563830554000162, 85.188154007000151],
              [57.591200045000164, 85.188497868000297],
              [57.626869033000162, 85.188945724000234],
              [57.649891870000204, 85.188893765000273],
              [57.668832126000211, 85.189129755000067],
              [57.672538191000342, 85.189121214000181],
              [57.673889554000198, 85.189118007000161],
              [57.690079124000363, 85.189318489000243],
              [57.753108159000249, 85.190098652000188],
              [57.76495761800021, 85.190069839000046],
              [57.769978921000302, 85.190131584000255],
            ],
            [
              [149.339637142000129, 55.669620605000262],
              [149.302845639000225, 55.551855979000209],
              [149.302228639000305, 55.550889979000033],
              [149.301345457000252, 55.54801669450012],
              [149.236512681000136, 55.444811920000177],
              [148.957517639000343, 55.001347979000116],
              [148.62370074, 54.658814677000237],
              [148.640418293000153, 54.60932513500029],
              [148.644297640000332, 54.597840978000193],
              [148.64500463800016, 54.572705978000272],
              [148.647810638000351, 54.564286978000155],
              [148.66111563800041, 54.090327978000175],
              [148.772251639000274, 53.750704978000215],
              [148.772298639000269, 53.748884978000262],
              [148.772823639000308, 53.747267978000195],
              [148.772934639000255, 53.742942978000144],
              [148.775343639000312, 53.735523977000241],
              [148.775422656333603, 53.733562473000177],
              [148.775900639000383, 53.732090977000269],
              [148.775971639000204, 53.729314977000229],
              [148.779249913000399, 53.719212308000237],
              [148.780010640000341, 53.716867978000039],
              [148.780053639000243, 53.715159977000212],
              [148.780531916000086, 53.71368710250016],
              [148.780605637000122, 53.712594071500234],
              [148.781946640000342, 53.708458978000237],
              [148.782160637000175, 53.700055071500117],
              [148.786291640000286, 53.6873089770001],
              [148.786335639000299, 53.68560597700025],
              [148.786813916000142, 53.68412857300001],
              [148.786885637000296, 53.683100071500064],
              [148.788245640000127, 53.678902977000234],
              [148.788395639000214, 53.672970977000205],
              [148.789183639000385, 53.670538977000263],
              [148.789219637000315, 53.669125071500162],
              [148.791226640000218, 53.662927977000038],
              [148.791455639000446, 53.653838977000305],
              [148.792622639000342, 53.650232977000201],
              [148.792646636500308, 53.649310071500281],
              [148.793990640000288, 53.645156977000283],
              [148.794088436333482, 53.642169383666896],
              [148.79445863900014, 53.6410249770002],
              [148.794545639000148, 53.637583977000247],
              [148.795622639000271, 53.634256977000291],
              [148.795990443333494, 53.621931922333488],
              [148.796070639000391, 53.62025997700016],
              [148.798064639000131, 53.614094977000263],
              [148.798120639000331, 53.61184497700026],
              [148.800859163000268, 53.603373734000229],
              [148.801494640000328, 53.601407977000179],
              [148.801516639000283, 53.600499977000197],
              [148.802609938000273, 53.597117812000192],
              [148.802863640000282, 53.596332977000202],
              [148.803389639000216, 53.575327977000256],
              [148.804693639000448, 53.571287977000281],
              [148.804863455250256, 53.565573304250165],
              [148.805267455250259, 53.553024601250229],
              [148.80585995000024, 53.533633609666936],
              [148.806099639000195, 53.525229977000208],
              [148.806582445500226, 53.523732644500114],
              [148.806847639000353, 53.514974977000293],
              [148.807348639000224, 53.513421977000235],
              [148.807419639000159, 53.510581977000072],
              [148.807797369500236, 53.509409836500026],
              [148.807922637000274, 53.505827071500164],
              [148.808989640000391, 53.502516977000141],
              [148.81138363700029, 53.406610071000102],
              [148.811725640000191, 53.405545977000202],
              [148.812444637000112, 53.376770071000124],
              [148.812911640000152, 53.375317977000236],
              [148.813829639000346, 53.338509977000285],
              [148.814884639000184, 53.335226977000275],
              [148.814958637000132, 53.332260071000235],
              [148.815364640000439, 53.330993977000333],
              [148.815534639000134, 53.324166977000345],
              [148.81768963900015, 53.317455977000179],
              [148.832155629500335, 52.736973942000077],
              [148.83147064800005, 52.734392606000199],
              [148.831530629500321, 52.73199294200009],
              [148.816049648500325, 52.673616607000156],
              [148.816074639000135, 52.672644977000061],
              [148.814681262000136, 52.667378660000168],
              [148.808676648000187, 52.644683606000171],
              [148.809170629500159, 52.625342940500275],
              [148.798586648500077, 52.585255607000079],
              [148.798686629000258, 52.581414939500178],
              [148.798358648000203, 52.580170606000081],
              [148.798321718000125, 52.578768854000089],
              [148.7976876485003, 52.576362606500197],
              [148.798095639000167, 52.560589976000188],
              [148.79764563900028, 52.558882976000177],
              [148.79768663900029, 52.557300976000192],
              [148.797365699000238, 52.556082950000302],
              [148.795982648500285, 52.550833606500191],
              [148.796137629000214, 52.544840940000086],
              [148.793097180000245, 52.533292753000183],
              [148.792391639000243, 52.530612976000043],
              [148.792733629000281, 52.517428940000059],
              [148.792160648500044, 52.515250607000041],
              [148.792560639000385, 52.49989697600023],
              [148.791539639000462, 52.496015976000251],
              [148.791633629000216, 52.492417939500228],
              [148.791437074000214, 52.491668857000207],
              [148.791611639000394, 52.484101976000204],
              [148.791380639000295, 52.483221976000209],
              [148.791401639000242, 52.482405976000166],
              [148.789368639000259, 52.474412476000168],
              [148.773646123000162, 52.413828740000326],
              [148.70586569600016, 52.153763792500172],
              [148.7635956390003, 51.970820976000084],
              [148.76403263900022, 51.954643976000284],
              [148.768209639000133, 51.941347976000259],
              [148.768257639000126, 51.939563976000272],
              [148.769299803000308, 51.936246742000037],
              [148.769541640000284, 51.935476976000302],
              [148.769798639000328, 51.925926976000255],
              [148.771004639000125, 51.922083976000067],
              [148.771088639000141, 51.918948976000195],
              [148.772230639000327, 51.915311976000226],
              [148.772286636500212, 51.913216070000146],
              [148.772659939000278, 51.912026765000064],
              [148.774268639000184, 51.906901976000142],
              [148.774351636500228, 51.903777070000217],
              [148.774566917000357, 51.903090834000295],
              [148.775502639000251, 51.899845976000051],
              [148.776655883000331, 51.895905532000143],
              [148.77692164000041, 51.895057976000203],
              [148.776960636500235, 51.893598070000166],
              [148.777548640000418, 51.891722976000324],
              [148.777752575666796, 51.886021612666866],
              [148.778587639000307, 51.883357976000241],
              [148.778739639000264, 51.877688976000172],
              [148.779615639000212, 51.87489297600024],
              [148.779938639000193, 51.862822976000075],
              [148.780884639000305, 51.859803976000194],
              [148.781035875000157, 51.854513190333421],
              [148.78120363900041, 51.853977976000294],
              [148.781254636500307, 51.85206307000027],
              [148.78149421400019, 51.851298671000166],
              [148.782526639000253, 51.848004976000254],
              [148.78257363650016, 51.846246070000177],
              [148.782816227000239, 51.845471500000258],
              [148.783861639000406, 51.842133976000127],
              [148.784098139000093, 51.836617476000242],
              [148.784278636500062, 51.833199070000205],
              [148.784494670000299, 51.832509068000263],
              [148.785425639000323, 51.829535976000159],
              [148.78567163900027, 51.820287976000031],
              [148.786175639000135, 51.818678976000285],
              [148.786196639000309, 51.817874976000041],
              [148.788327639000272, 51.811066976000234],
              [148.788373639000156, 51.809312976000228],
              [148.78966363900031, 51.805192976000228],
              [148.789729639000257, 51.802688976000297],
              [148.814633644000196, 51.722920348000287],
              [148.820416639500081, 51.704257476000123],
              [148.82399110900019, 51.692641160000164],
              [148.824826139500374, 51.689723476000154],
              [148.826252639000245, 51.684905976000209],
              [148.826325639000288, 51.682081976000291],
              [148.826764639000316, 51.680673976000151],
              [148.826851637000232, 51.677266070000258],
              [148.829455139500169, 51.668561976000206],
              [148.831604639000261, 51.661316976000307],
              [148.831682637000313, 51.658273070000121],
              [148.8328406400002, 51.654547976000117],
              [148.832884639000213, 51.652851976000306],
              [148.834182639000346, 51.648677976000272],
              [148.83423563700012, 51.646607070000186],
              [148.836237640000149, 51.640167976000271],
              [148.836309639000319, 51.637352976000273],
              [148.83670910900014, 51.636069418000091],
              [148.836824637000063, 51.633043070000156],
              [148.837045945000341, 51.63233102900017],
              [148.838007637666919, 51.628934372000231],
              [148.839441139500082, 51.623942476000195],
              [148.840601812000273, 51.619977407000306],
              [148.840869640000165, 51.619114976000276],
              [148.840991669333562, 51.615093422666689],
              [148.841305639000325, 51.614083976000188],
              [148.84134863900033, 51.61239997600012],
              [148.842403790000162, 51.609001576000139],
              [148.842648640000334, 51.608212976000232],
              [148.842775639000138, 51.603238976000284],
              [148.848341157000164, 51.585307082000213],
              [148.849632640000209, 51.581145976000187],
              [148.849726639000437, 51.577429975000314],
              [148.852412404000347, 51.568771243000242],
              [148.853035640000371, 51.56676197600018],
              [148.85320163900019, 51.560171975000173],
              [148.854001639000217, 51.557493975000114],
              [148.854724639000437, 51.555061975000172],
              [148.854796639000142, 51.552230975000043],
              [148.863351639000143, 51.52461497500019],
              [148.8634696390003, 51.519911975000241],
              [148.882171139000093, 51.459293475000209],
              [148.883625139000287, 51.454244475000053],
              [148.88505963900036, 51.449391975000026],
              [148.88510363700027, 51.447607069500179],
              [148.88534716900017, 51.446818187000247],
              [148.886396639000282, 51.443418975000213],
              [148.886441637000303, 51.441597069500119],
              [148.886824356000261, 51.440356722000217],
              [148.888473639000381, 51.435011975000236],
              [148.888503637000213, 51.433794069500209],
              [148.889175842000327, 51.431615010000257],
              [148.892072639000276, 51.422224975000233],
              [148.892212633333543, 51.417026786000235],
              [148.892406639000342, 51.416395975000171],
              [148.892519637000305, 51.411810069500234],
              [148.892987488000131, 51.410292479000191],
              [148.895003639000151, 51.403752975000202],
              [148.895133637000185, 51.398420069500276],
              [148.895309553000402, 51.397849478000182],
              [148.896067639000421, 51.395390975000225],
              [148.896115637000321, 51.393437069500067],
              [148.898135640000191, 51.386880975000224],
              [148.898344385000087, 51.380367452333417],
              [148.898629637000113, 51.370033069500209],
              [148.899408070000163, 51.367504816000121],
              [148.902762639000343, 51.356609975000254],
              [148.902910637000105, 51.350535069500211],
              [148.907939642000315, 51.334166275000257],
              [148.929611639000314, 51.26362697500025],
              [148.932079639000222, 51.16020097500035],
              [148.934451294000269, 51.152450483000337],
              [148.935001640000337, 51.150651975000187],
              [148.93543663700018, 51.132384069000182],
              [148.940584681000132, 51.123904858000174],
              [148.962769639000157, 51.087364975000128],
              [148.963122005500054, 51.086207327500119],
              [148.963891678000437, 51.08487836400019],
              [148.967050639000263, 51.079669975000286],
              [148.969192639000198, 51.072641975000181],
              [148.984127639000462, 51.047990975000175],
              [148.984704639000256, 51.046096975000296],
              [148.98628817800045, 51.043480199000157],
              [148.986743878500306, 51.042583018000073],
              [148.987592639000241, 51.03979397500035],
              [148.988192296500074, 51.038803905500174],
              [149.050773639000226, 50.832345975000237],
              [149.055449639000244, 50.824569975000259],
              [149.055708662500138, 50.823809551000181],
              [149.056295639000268, 50.822833975000151],
              [149.057401113000424, 50.819166106000125],
              [149.057657639000354, 50.818314975000135],
              [149.090916455500292, 50.762869919000195],
              [149.091193639000267, 50.761945975000174],
              [149.095195639000281, 50.755270975000144],
              [149.098313639000139, 50.744850975000247],
              [149.236698357000137, 50.884616317000223],
              [149.268810639000208, 50.917048975000171],
              [149.319778540000129, 50.995174744000224],
              [149.539419639000243, 51.331849975000296],
              [149.947311898000294, 51.72894722500024],
              [150.041963640000404, 51.8210939760001],
              [150.129397450000283, 51.879019927000229],
              [150.506184640000242, 52.128645976000257],
              [150.712186560500186, 52.422277862500096],
              [150.668624640000303, 52.582159976000185],
              [150.667410640000412, 52.584243976000153],
              [150.666315640000221, 52.588221976000341],
              [150.665815640000233, 52.589079976000278],
              [150.657446666000396, 52.619459411000037],
              [150.655472904000248, 52.626563314000123],
              [150.655167640000286, 52.627085976000217],
              [150.651067972000192, 52.64194575400029],
              [150.650116640000306, 52.64539397600015],
              [150.647331872000109, 52.650248720000207],
              [150.646891640000149, 52.651842976000296],
              [150.645966640000324, 52.653425976000165],
              [150.645110633000286, 52.656525717500074],
              [150.643172640000444, 52.660010976000194],
              [150.642027640000379, 52.664153977000069],
              [150.641561640000191, 52.664949977000219],
              [150.641235050000319, 52.666131841000322],
              [150.639827640000249, 52.671224977000179],
              [150.633512640000163, 52.682019977000209],
              [150.6325370320003, 52.685545804000185],
              [150.632310640000128, 52.686363977000269],
              [150.631904640000357, 52.687057977000165],
              [150.631693640000321, 52.687818977000234],
              [150.629332640000371, 52.691852977000224],
              [150.627552942000193, 52.698037080500285],
              [150.626324640000348, 52.700134977000175],
              [150.624729640000282, 52.70567047700024],
              [150.62273465900023, 52.712648730000183],
              [150.622298640000281, 52.71422197700025],
              [150.621383734000233, 52.715822409000168],
              [150.621182640000143, 52.716547977000175],
              [150.614174813000204, 52.728502028000264],
              [150.612548640000227, 52.731275977000166],
              [150.608527640000148, 52.745756977000156],
              [150.605052640000281, 52.751678977000267],
              [150.604762024000252, 52.752724481000257],
              [150.603408187333457, 52.757402688000184],
              [150.603107640000303, 52.758356977000176],
              [150.594437567500336, 52.773203375000207],
              [150.594013640000298, 52.774726977000171],
              [150.593095640000229, 52.776288977000263],
              [150.592835640000203, 52.777222977000235],
              [150.590508640000394, 52.781182977000185],
              [150.585044581000261, 52.800792553000178],
              [150.583744904000469, 52.80539693750012],
              [150.583439640000279, 52.805915977000041],
              [150.57724664000034, 52.828112977000217],
              [150.575306640000235, 52.831406977000142],
              [150.57258164000018, 52.841164977000346],
              [150.561919640000156, 52.859253977000265],
              [150.537323251999965, 52.94692568500011],
              [150.531481915000086, 52.967495896500168],
              [150.530195640000215, 52.969668977000254],
              [150.527413640000191, 52.979552977000196],
              [150.523071640000353, 52.986880977000226],
              [150.522793640000259, 52.98786597700024],
              [150.520477640000308, 52.991773977000264],
              [150.519244737000349, 52.996148794000305],
              [150.518877832666931, 52.997299844333384],
              [150.518464775500092, 52.998535996500152],
              [150.516148640000438, 53.002498977000187],
              [150.515770820000171, 53.003838486000291],
              [150.514142640000159, 53.009610977000307],
              [150.513033640000231, 53.011479977000249],
              [150.510556640000232, 53.020255977000204],
              [150.506020640000315, 53.027897977000293],
              [150.504783678000308, 53.03227847800008],
              [150.504417813000146, 53.033428071666719],
              [150.504015640000148, 53.034628977000295],
              [150.501701640000192, 53.038523977000182],
              [150.498793640000258, 53.048810977000301],
              [150.497214783000118, 53.051466863000144],
              [150.496617635000462, 53.053273846000138],
              [150.494751640000345, 53.05987097700023],
              [150.49198065100029, 53.064531498000292],
              [150.491263140000115, 53.065875477000191],
              [150.490046640000173, 53.068057977000308],
              [150.48907026000029, 53.071505067000032],
              [150.484862640000301, 53.086359977000257],
              [150.48346564000019, 53.0887069770003],
              [150.479185640000196, 53.103800977000219],
              [150.478381140000124, 53.105191977000175],
              [150.475318370000196, 53.110371662000034],
              [150.474612640000259, 53.111555977000251],
              [150.471192640000254, 53.123602977000303],
              [150.470697293000399, 53.124433955000143],
              [150.468562640000187, 53.128014977000134],
              [150.467815332500095, 53.130643688500015],
              [150.46449416750022, 53.136473166000201],
              [150.463883640000176, 53.138619977000076],
              [150.455555640000171, 53.152575977000197],
              [150.454577596000263, 53.15600927700018],
              [150.454310899500115, 53.156872651000072],
              [150.453928640000186, 53.157513977000178],
              [150.451594640000167, 53.165708977000179],
              [150.448843130000228, 53.17031430700024],
              [150.448204640000313, 53.171382977000178],
              [150.442315640000174, 53.192037977000155],
              [150.440488316000142, 53.195092935000162],
              [150.432613640000341, 53.208257977000187],
              [150.430045564000125, 53.217245431000208],
              [150.429332390000241, 53.219527450000271],
              [150.427425640000251, 53.225869977000059],
              [150.419834640000204, 53.238542977000236],
              [150.41815058933355, 53.244298526666796],
              [150.41448164000019, 53.257023977000074],
              [150.410729640000454, 53.263280977000363],
              [150.409800722000341, 53.266520323000123],
              [150.405797640000401, 53.280479977000198],
              [150.397937640000237, 53.293569977000175],
              [150.396685257000172, 53.297929375000308],
              [150.396394640000267, 53.298940977000143],
              [150.395890252000299, 53.299780431000158],
              [150.393628472666819, 53.303704750666896],
              [150.392386074500223, 53.305917127000214],
              [150.392178640000338, 53.306639977000145],
              [150.387314640000227, 53.314730977000295],
              [150.386455640000349, 53.317716977000259],
              [150.383298640000248, 53.322965977000251],
              [150.3830305150002, 53.323898073500345],
              [150.382564926000327, 53.324722554000175],
              [150.380678640000383, 53.327857977000178],
              [150.380398739500038, 53.328830835500241],
              [150.379937200000313, 53.329650514000264],
              [150.378073640000139, 53.332746977000227],
              [150.376418640000338, 53.338373977000231],
              [150.375503649000393, 53.341429471000254],
              [150.375139107000223, 53.342391351500112],
              [150.373503140000139, 53.345153477000167],
              [150.35841964000042, 53.37023197700023],
              [150.357591640000123, 53.373096977000159],
              [150.354412640000305, 53.378367977000153],
              [150.354096640000307, 53.379463977000228],
              [150.352237951000234, 53.382545018000201],
              [150.351806640000177, 53.38325997700025],
              [150.351181203000351, 53.385271316500223],
              [150.350378640000145, 53.386601977000225],
              [150.348658640000281, 53.392552977000207],
              [150.345559741000329, 53.397685883000179],
              [150.344840640000257, 53.398876977000157],
              [150.344607281000208, 53.399683470000184],
              [150.343601640000202, 53.403158977000032],
              [150.34324202650032, 53.403753200000097],
              [150.342887640000299, 53.404912977000038],
              [150.340497817000141, 53.409048742500261],
              [150.339537640000231, 53.412364977000038],
              [150.337769640000147, 53.415292977000206],
              [150.336183667000455, 53.420765964000168],
              [150.335815640000305, 53.422035977000178],
              [150.333707681000419, 53.425522434000243],
              [150.324623640000254, 53.440546977000167],
              [150.324252251000303, 53.441824194000276],
              [150.318210640000189, 53.451878977000263],
              [150.317907405000142, 53.452922597000281],
              [150.316530589333638, 53.45753592300025],
              [150.316353640000443, 53.458060977000173],
              [150.298837640000329, 53.48697097700034],
              [150.298165625000138, 53.489278015000252],
              [150.295269640000129, 53.499219977000223],
              [150.278921640000135, 53.526149977000159],
              [150.277603512000383, 53.530661967000071],
              [150.277256048000368, 53.531777901000225],
              [150.27657033700018, 53.534011671000144],
              [150.276123299000233, 53.534862240000223],
              [150.274475640000219, 53.537573977000193],
              [150.274132331500141, 53.538747023500093],
              [150.273673617000213, 53.539565101000278],
              [150.271800640000151, 53.54273497700018],
              [150.268740212000125, 53.547855260000063],
              [150.268041640000433, 53.549003977000154],
              [150.267821464000349, 53.549757358000249],
              [150.266872640000315, 53.553003977000174],
              [150.260393198000315, 53.563651268000172],
              [150.258872971500409, 53.566178951500206],
              [150.25871264000034, 53.566726977000201],
              [150.257590640000444, 53.568570977000149],
              [150.256421045000138, 53.572564319000207],
              [150.256008475500153, 53.573722830000179],
              [150.254650640000364, 53.57595297700027],
              [150.25341264000042, 53.580175977000067],
              [150.249012658000311, 53.587398895000149],
              [150.247991640000237, 53.58907497700028],
              [150.24742641700027, 53.591001935000179],
              [150.244990640000196, 53.599305977000142],
              [150.239557423000406, 53.608215511000139],
              [150.238296640000272, 53.610282977000168],
              [150.237352876500154, 53.613394315500216],
              [150.236787640000216, 53.614320977000148],
              [150.236399640000172, 53.615639977000171],
              [150.234159640000257, 53.61931197700028],
              [150.233829640000295, 53.620434977000286],
              [150.231529640000304, 53.624203977000207],
              [150.23048422800025, 53.627759026000263],
              [150.230209621500194, 53.628636525500156],
              [150.229901640000207, 53.629141977000188],
              [150.228440662000139, 53.634107680000284],
              [150.228101640000318, 53.635259977000146],
              [150.22739346200035, 53.636419618000275],
              [150.224341640000262, 53.641416977000119],
              [150.223557193500255, 53.644081921500231],
              [150.220306640000331, 53.649546977000284],
              [150.218846640000152, 53.65450197700028],
              [150.217307640000172, 53.657115477000161],
              [150.216082697000388, 53.659213261000161],
              [150.211036640000202, 53.667466977000231],
              [150.21009864000041, 53.670647977000215],
              [150.209511640000301, 53.671606977000295],
              [150.208450640000137, 53.675205977000189],
              [150.20668464000039, 53.678091977000065],
              [150.205715640000335, 53.681375977000187],
              [150.205131640000332, 53.682329977000279],
              [150.204492125500224, 53.684497622500146],
              [150.204045936000171, 53.685343386000284],
              [150.202409640000155, 53.688016977000245],
              [150.201700640000126, 53.690417977000152],
              [150.200065967000228, 53.693087510000112],
              [150.19965349100039, 53.693819419500073],
              [150.199334640000302, 53.694900977000145],
              [150.197496242000398, 53.697902477000184],
              [150.197020482000426, 53.698765380000225],
              [150.196547640000176, 53.700365977000217],
              [150.193135640000378, 53.705935977000138],
              [150.192782640000132, 53.707127977000198],
              [150.190944242000228, 53.710127853000245],
              [150.19051764000028, 53.710823977000302],
              [150.189919832000214, 53.712845486000106],
              [150.187343640000222, 53.721556977000148],
              [150.185819640000432, 53.72404197700024],
              [150.181799513000442, 53.737618530000304],
              [150.180752162666863, 53.740956340333469],
              [150.177146640000046, 53.752692478000142],
              [150.175363744666811, 53.758496651666803],
              [150.174397729500129, 53.761374640000156],
              [150.172580640000319, 53.764333978000252],
              [150.172256353500075, 53.765426405500136],
              [150.16987541400033, 53.76950910950012],
              [150.169055640000352, 53.772270978000222],
              [150.168474721250277, 53.773217753500205],
              [150.166404951000345, 53.776586834000284],
              [150.165897643500102, 53.777471585500223],
              [150.165570640000283, 53.778573978000225],
              [150.163737112000263, 53.781556809000165],
              [150.163311640000188, 53.782248978000155],
              [150.162720424000213, 53.784238844000129],
              [150.160172640000269, 53.79281397800014],
              [150.158752302500375, 53.795122932000083],
              [150.158147338000163, 53.796901381000225],
              [150.156176640000126, 53.80353097800014],
              [150.152456826000275, 53.809575371000108],
              [150.151593640000442, 53.810977978000267],
              [150.150680012500175, 53.813721415000145],
              [150.148865640000281, 53.816668978000223],
              [150.142169640000134, 53.839152978000186],
              [150.134320640000169, 53.851885978000155],
              [150.132813397000405, 53.856936905000168],
              [150.13246364000031, 53.858108978000075],
              [150.131258640000283, 53.860061978000033],
              [150.129581640000197, 53.865680978000228],
              [150.129092319000392, 53.866474099000243],
              [150.126983640000276, 53.869891978000169],
              [150.125438640000311, 53.875064978000069],
              [150.124147836000247, 53.877154722000114],
              [150.123345203000213, 53.879608763000249],
              [150.120464640000364, 53.889247978000299],
              [150.119325109000442, 53.891092242500179],
              [150.118778343000372, 53.892716342000028],
              [150.1169326400003, 53.89888897800023],
              [150.116108337000242, 53.900222355500205],
              [150.115775531000168, 53.901187059000222],
              [150.114540140000258, 53.90502297800009],
              [150.112863640000455, 53.910331978000045],
              [150.111685117000206, 53.912237743000276],
              [150.111406886666828, 53.912696218666781],
              [150.108892640000136, 53.916773978000151],
              [150.106477640000321, 53.924838978000082],
              [150.104400640000449, 53.92819597800019],
              [150.099943845000212, 53.943057372000055],
              [150.098909640000329, 53.946505978000118],
              [150.098457234000193, 53.947236946000203],
              [150.096507640000368, 53.950386978000267],
              [150.085422059000166, 53.98728318000019],
              [150.082849640000177, 53.995844978000207],
              [150.082410640000148, 53.99655197800007],
              [150.081763295000314, 53.998703640000315],
              [150.078929140000241, 54.00804747799998],
              [150.077350612000373, 54.013212924000356],
              [150.076994640000407, 54.014394978000269],
              [150.075794899500124, 54.016360226000131],
              [150.075614640000254, 54.016958978000332],
              [150.074506640000209, 54.018742978000148],
              [150.072340333000284, 54.025932615000158],
              [150.071837640000126, 54.027600978000294],
              [150.071206640000241, 54.028616978000173],
              [150.070637461000274, 54.030504761000145],
              [150.068184640000254, 54.038639978000276],
              [150.06778364000013, 54.039285978000066],
              [150.067251013500254, 54.041050529500012],
              [150.066564684000355, 54.042248781000183],
              [150.063845640000295, 54.046621978000246],
              [150.063063205000276, 54.049215215000288],
              [150.062826454500396, 54.049905877000072],
              [150.062295640000457, 54.050760978000199],
              [150.061349250000148, 54.053894778000142],
              [150.06097020600032, 54.054878315500162],
              [150.059436640000172, 54.05734397800029],
              [150.058751834500185, 54.059612169500156],
              [150.058307340000169, 54.060448045000157],
              [150.056687140000349, 54.063072478000322],
              [150.054646503000413, 54.066370584000197],
              [150.054175640000267, 54.06712697800026],
              [150.04895585933366, 54.084320902000172],
              [150.048549143000173, 54.085556450500121],
              [150.048086284000192, 54.086360120000165],
              [150.046244640000396, 54.089316978000227],
              [150.044482058500193, 54.095100961250068],
              [150.044073640000192, 54.096432978000109],
              [150.039336640000215, 54.104033978000189],
              [150.026633640000227, 54.145930978000195],
              [150.025523640000301, 54.147707978000142],
              [150.024409238000203, 54.151379091000251],
              [150.02415064000013, 54.152230978000318],
              [150.02315015000022, 54.153832477000208],
              [150.018838640000354, 54.160733978000223],
              [150.012445640000124, 54.181762978000052],
              [150.01083054950027, 54.184344586000122],
              [150.008625964500197, 54.191120820500203],
              [150.007580640000356, 54.19279297800017],
              [150.003143640000417, 54.207361978000165],
              [150.002494640000123, 54.208397978000278],
              [150.002089640000236, 54.209726978000276],
              [150.000278838000327, 54.212618092000184],
              [149.999858640000411, 54.213288978000321],
              [149.998503640000195, 54.217734978000294],
              [149.997974767000414, 54.218579329000249],
              [149.995695640000349, 54.222217978000117],
              [149.99487164000027, 54.224918978000289],
              [149.99293664000038, 54.228005978000112],
              [149.98848364000014, 54.242595978000281],
              [149.98790662800036, 54.243515393000052],
              [149.984549640000296, 54.254404978000196],
              [149.983095640000215, 54.256722978000141],
              [149.964465703000201, 54.317258805500046],
              [149.962590640000258, 54.320238978000305],
              [149.951427640000134, 54.356605978000175],
              [149.950500640000314, 54.358076978000156],
              [149.945237058000203, 54.37519396800036],
              [149.943855546500345, 54.379419773000109],
              [149.942315640000288, 54.381860978000248],
              [149.93079095200028, 54.41926365900008],
              [149.928097230333492, 54.427973875000077],
              [149.926513818000217, 54.432793194333556],
              [149.892691715000296, 54.54051812400013],
              [149.747387637000202, 55.004037979000145],
              [149.747339639000302, 55.008728979000125],
              [149.738831639000296, 55.035422979000259],
              [149.738726639000333, 55.045260979000147],
              [149.732613639000192, 55.064409979000231],
              [149.732419476000246, 55.075351733000105],
              [149.731667637000328, 55.0777059790002],
              [149.731249643000183, 55.116046573000119],
              [149.718038637000291, 55.157292979000147],
              [149.717989867000142, 55.158796438500133],
              [149.717674637000187, 55.159781979000229],
              [149.717333643000188, 55.189646573000118],
              [149.715428637000173, 55.195580979000169],
              [149.715112639000154, 55.223080979000201],
              [149.713758639000389, 55.227293979000251],
              [149.713677584000266, 55.229758077000213],
              [149.713157637000222, 55.231374979000236],
              [149.708225643500214, 55.634678573500196],
              [149.415988497000285, 55.685219915000232],
              [149.348174639000149, 55.696947979000186],
              [149.339637142000129, 55.669620605000262],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "RWA", Country: "Rwanda" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.482218169316383, -1.063335825429945],
            [30.466945168221656, -1.074999914283396],
            [30.454445068343176, -1.101391007057288],
            [30.470554080462961, -1.160690960532406],
            [30.564718225017685, -1.330690877193064],
            [30.637500134512123, -1.389446006960256],
            [30.686109138095304, -1.387217929451978],
            [30.739163232582797, -1.43694608475009],
            [30.748891269435404, -1.517081940835041],
            [30.830936187792844, -1.654899875439853],
            [30.81110913105212, -1.700554928697613],
            [30.818327123172736, -1.728755003819771],
            [30.835000237377898, -1.80055505717354],
            [30.82471815672389, -1.846663906670457],
            [30.808327177380392, -1.929445929595275],
            [30.871427150489779, -2.038263987874529],
            [30.893263349427315, -2.075490869827775],
            [30.886182149966913, -2.097290858943524],
            [30.848191173718106, -2.18971793787928],
            [30.843191200821792, -2.224164039627226],
            [30.848745049881444, -2.261108954356857],
            [30.851800253597418, -2.297917914616576],
            [30.845827141739818, -2.325691015589911],
            [30.830763352948935, -2.354308844767601],
            [30.782218219468206, -2.380554930616086],
            [30.726391223764523, -2.35513580333722],
            [30.706245151787897, -2.347782024384685],
            [30.586245131727168, -2.387635794800417],
            [30.573327110155191, -2.399163929184056],
            [30.561391279999924, -2.399863985739174],
            [30.50944510547734, -2.383473006395775],
            [30.465136185873263, -2.352363907949339],
            [30.442427095536658, -2.32604607300938],
            [30.393118203036437, -2.301564042508105],
            [30.356391217789991, -2.336390850299352],
            [30.285945177079014, -2.355990925101551],
            [30.158336065294378, -2.428335802163161],
            [30.112500130565678, -2.420417921125406],
            [30.09305411514427, -2.409164042614449],
            [30.004163361168054, -2.336108883075738],
            [29.952218192473794, -2.309446049000215],
            [29.946945137171411, -2.321108964387179],
            [29.935273169328894, -2.370835778580911],
            [29.916382203537381, -2.566390944628338],
            [29.920273250640292, -2.641946090615122],
            [29.921945105052345, -2.652781879794574],
            [29.906454174474419, -2.69368187936756],
            [29.876391137547188, -2.737499954720235],
            [29.856945122125893, -2.755555077124782],
            [29.821945144214538, -2.772781899855218],
            [29.739163288927699, -2.802917859340326],
            [29.695827173009008, -2.805626890454164],
            [29.630827190082385, -2.781108985407158],
            [29.615836156211259, -2.79389088487126],
            [29.597009228160147, -2.802363983176903],
            [29.570554097645896, -2.799999951198728],
            [29.535554119734542, -2.824445939516281],
            [29.464300231193221, -2.805972895418222],
            [29.443891135094333, -2.795835821689522],
            [29.380482205032962, -2.825490995207062],
            [29.358954124857092, -2.817781991196981],
            [29.337500140705373, -2.757081924611526],
            [29.351663210178543, -2.736946078556912],
            [29.354163364264792, -2.716946019334102],
            [29.325836051851951, -2.683326876155689],
            [29.328891255567925, -2.657081963773592],
            [29.279718149899537, -2.634445963632814],
            [29.140554081641113, -2.589163905067437],
            [29.094163264920581, -2.592217935317009],
            [29.062563322222758, -2.603263942628601],
            [29.047082282290575, -2.666526859936184],
            [29.053327135449848, -2.697081914581119],
            [29.041527092126586, -2.729445951574505],
            [29.024436056227984, -2.74471794684095],
            [29.017118151821165, -2.724026883518434],
            [28.975900142840743, -2.694235923168975],
            [28.942918191943562, -2.691254983115343],
            [28.923473182350591, -2.6799999311379],
            [28.902218184580846, -2.65999987191519],
            [28.85444519202278, -2.52610885895912],
            [28.867427083697152, -2.398681969750086],
            [28.89347317733538, -2.366390855314563],
            [28.985000207505692, -2.334446081119168],
            [29.097773182990267, -2.26854604942713],
            [29.118327118376413, -2.241108895134047],
            [29.130491271574755, -2.212946035662],
            [29.143891252580687, -2.189164061715857],
            [29.156391184821103, -2.160835911112713],
            [29.166391130613505, -2.133190885620365],
            [29.170554086656324, -2.093335774100069],
            [29.167500224044801, -2.068608824387312],
            [29.148209106194571, -2.01436399673139],
            [29.135273314987813, -1.976390957755399],
            [29.12805414940064, -1.948890939188274],
            [29.124300230234013, -1.916045948589328],
            [29.129927169489548, -1.851045965662834],
            [29.143473163249183, -1.815135880702101],
            [29.251391172762879, -1.654717820502327],
            [29.270836182355794, -1.626391011003747],
            [29.362009160935003, -1.510899951949796],
            [29.442773324483795, -1.504163919263135],
            [29.456391234972926, -1.504163919263135],
            [29.470000260644696, -1.482499884617283],
            [29.495282259987192, -1.449581971460489],
            [29.538327188588909, -1.413608854587793],
            [29.573327166500263, -1.393473008533164],
            [29.596945190419802, -1.38583592125255],
            [29.617363338974116, -1.387081974982081],
            [29.640827135874787, -1.386664053288555],
            [29.677500141664638, -1.38166391275432],
            [29.697773283293742, -1.367499837452797],
            [29.717218125248536, -1.34749994586808],
            [29.8352822609464, -1.319717960077327],
            [29.855273267713841, -1.326946010481848],
            [29.884163341107381, -1.370417913232387],
            [29.884509178433376, -1.401808978902523],
            [29.890554039382295, -1.432781955240969],
            [29.910000222441766, -1.4650729020385],
            [29.929718146802912, -1.476108851066201],
            [29.93666322415541, -1.475835936298083],
            [29.979718211040819, -1.462217858170874],
            [30.109163294902118, -1.367499837452797],
            [30.171663291380554, -1.314999954404783],
            [30.229163314962904, -1.266664033227912],
            [30.283473186187649, -1.209655021535028],
            [30.297773215959097, -1.184717853690032],
            [30.315354090280579, -1.143764042298486],
            [30.360827088600928, -1.060972966918172],
            [30.451945081894991, -1.054445978897178],
            [30.476754174259185, -1.059326928767945],
            [30.482218169316383, -1.063335825429945],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SAU", Country: "Saudi Arabia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.546945174144838, 29.104200132996553],
            [46.548609149567966, 29.103053991553011],
            [46.587773256988527, 29.098054018656953],
            [46.800282163360208, 29.075273179228944],
            [46.996663282148887, 29.053054094953282],
            [47.459991254014966, 28.999436066018447],
            [47.463391289228497, 28.984464142886608],
            [47.506945166992381, 28.923053961462145],
            [47.540545199431421, 28.850554019191335],
            [47.56470922052543, 28.79666424895477],
            [47.574845120787558, 28.770136028244579],
            [47.576654103136008, 28.733053985578564],
            [47.574991301179438, 28.703053980563553],
            [47.6027632286862, 28.634991082570068],
            [47.617773205658665, 28.614164232415632],
            [47.688882254912613, 28.538882168835229],
            [48.302500265705277, 28.544164108955002],
            [48.416590767490476, 28.54527319793165],
            [48.421212451943319, 28.543869519812844],
            [48.42527297799117, 28.542636430500934],
            [48.434012775347554, 28.537208511022627],
            [48.435977146965655, 28.535979466944738],
            [48.589444000000157, 28.638889],
            [48.663889000000182, 28.665556],
            [48.688333000000199, 28.696944000000101],
            [49.445000000000135, 28.935000000000159],
            [49.524879616000106, 28.935000000000159],
            [49.529631747000195, 28.909422626999955],
            [49.534093686000148, 28.880738734000133],
            [49.539830464000175, 28.84886774200011],
            [49.555540989000207, 28.770971287999984],
            [49.571666667000073, 28.688333333000045],
            [49.558333333000149, 28.681666667000101],
            [49.603333333000052, 28.620000000000104],
            [49.661666667000219, 28.58],
            [49.7, 28.465000000000103],
            [49.790000000000219, 28.406666667000124],
            [49.796666667000153, 28.411666667],
            [49.848333333000113, 28.35],
            [49.936666667000196, 28.293333333000177],
            [50.078802740000043, 28.167756803000103],
            [50.014743085000106, 28.129933629],
            [50.013806283500145, 28.128884729000177],
            [50.009776026000083, 28.123740854000104],
            [50.004897205000162, 28.120654521999981],
            [50.00429851100003, 28.120052973000142],
            [50.003699824000051, 28.119451421000107],
            [49.964545259000175, 28.063823503999956],
            [49.947600988000119, 27.9945852580001],
            [49.94756047100023, 27.993549689500171],
            [49.947503223000098, 27.992086471000121],
            [49.947997088000108, 27.989690159000176],
            [49.947628187000163, 27.988036364500076],
            [49.947466432000084, 27.987311201000168],
            [49.947698481, 27.986291000000122],
            [49.947368676000082, 27.98481241399999],
            [49.96474642300015, 27.9083547850001],
            [49.983926163000177, 27.881290574500028],
            [50.040161062000067, 27.881184043000033],
            [50.042268065000059, 27.881189244000026],
            [50.133435945000116, 27.880934669000013],
            [50.176649093000123, 27.881205015],
            [50.281419149000129, 27.880488178000022],
            [50.311105033000018, 27.880327525999959],
            [50.368984142500125, 27.879722801750077],
            [50.383375318000191, 27.857676039],
            [50.385951897000126, 27.854254156000124],
            [50.386115404750143, 27.853531451250106],
            [50.386746456000054, 27.852553512],
            [50.394419809000084, 27.81811232800014],
            [50.403793051000065, 27.776041342000028],
            [50.402981618000098, 27.772464145000171],
            [50.402636694000222, 27.770943548000119],
            [50.616666667000032, 27.441666667000149],
            [50.758333333000024, 27.308333333000164],
            [50.900000000000119, 27.166667],
            [50.773333, 26.991667000000163],
            [50.556667000000203, 26.620833],
            [50.3875, 26.53],
            [50.376667, 26.4075],
            [50.341667000000172, 26.37333],
            [50.316667000000137, 26.252222],
            [50.311111, 26.171111000000124],
            [50.269722000000144, 26.07972200000016],
            [50.293056, 25.950833],
            [50.304167000000149, 25.87917],
            [50.366111000000188, 25.818889000000141],
            [50.41666700000016, 25.69833],
            [50.433333000000147, 25.679167000000106],
            [50.529167000000172, 25.593889],
            [50.557395545000162, 25.568769795],
            [50.565347574000072, 25.548361013000076],
            [50.58353573500014, 25.502890610000051],
            [50.601723896000095, 25.45742020699997],
            [50.615365017000073, 25.411949804000059],
            [50.627537857500073, 25.371373669000079],
            [50.629006138000051, 25.366479401000149],
            [50.638100219000108, 25.316461958000062],
            [50.645416882000205, 25.279878640000149],
            [50.647194299000176, 25.270991555000066],
            [50.656288380000063, 25.230068192000047],
            [50.665382460000075, 25.198238910000114],
            [50.664113225000136, 25.194431204500049],
            [50.660835485500087, 25.184597626000013],
            [50.669929501000155, 25.161862588000176],
            [50.679023581000109, 25.130033305999959],
            [50.68357062200019, 25.102751064000117],
            [50.692664702000087, 25.070921781999985],
            [50.710852863000156, 25.029998420000069],
            [50.719946944000213, 25.007263218000119],
            [50.729188803000085, 24.985698881000033],
            [50.733588065000191, 24.975433935999988],
            [50.748299585000183, 24.946010895000015],
            [50.756323266000066, 24.929963533],
            [50.769964387000215, 24.889040171000076],
            [50.79269958900008, 24.825381607000011],
            [50.810887194000117, 24.779912006500112],
            [50.830954060568416, 24.749964128120794],
            [50.865909222542058, 24.690000062263536],
            [50.973054085304767, 24.576944951917412],
            [51.111363367074858, 24.556045011567122],
            [51.178891164368139, 24.586391189184368],
            [51.2044450726506, 24.608191178300032],
            [51.215163479330243, 24.62089122190666],
            [51.221382499000157, 24.626517545000141],
            [51.227410265000088, 24.629866304],
            [51.242144806000198, 24.630536056],
            [51.268934881000206, 24.631875559999983],
            [51.291036693000166, 24.630536056],
            [51.311129249000118, 24.627187297000134],
            [51.315817512000166, 24.619150275000024],
            [51.319166271000057, 24.607764492999976],
            [51.319836023000136, 24.595039207000113],
            [51.318496519000149, 24.582983674000147],
            [51.316487264000074, 24.570258388],
            [51.316487264000074, 24.559542358000115],
            [51.320505775000044, 24.553514591999985],
            [51.342607586000071, 24.560212110000109],
            [51.358681631000167, 24.572937395999972],
            [51.372746420000027, 24.58633243300001],
            [51.386811210000161, 24.600397221999955],
            [51.394178480000079, 24.613122508000103],
            [51.404224758000083, 24.619820026000028],
            [51.414940788000223, 24.625847793000148],
            [51.433024088000053, 24.63120580799999],
            [51.452446892000097, 24.634554567000052],
            [51.468055548000081, 24.638888526],
            [51.612386956000108, 24.669799242000053],
            [51.610039472000125, 24.626370774999984],
            [51.593582543000053, 24.58029137400014],
            [51.568897149000037, 24.547377515999969],
            [51.537628984000065, 24.509526579000166],
            [51.519526362000164, 24.470029949000079],
            [51.509652204000105, 24.446990248000148],
            [51.508006512000094, 24.433824705],
            [51.511297897000219, 24.420659162000121],
            [51.51788066900022, 24.407493618999965],
            [51.51788066900022, 24.39432807499999],
            [51.519278606000086, 24.388736327999979],
            [51.521172055000164, 24.381162532000118],
            [51.523659435000098, 24.369969321],
            [51.524463441000165, 24.366351296000047],
            [51.535983291000065, 24.348248674000146],
            [51.544211755000077, 24.331791745000075],
            [51.554085913000137, 24.315334816000174],
            [51.562314377000092, 24.300523580000103],
            [51.570542842000037, 24.289003730000147],
            [51.577125614000153, 24.272546800999962],
            [51.58422716417985, 24.26046415582492],
            [51.583327213806825, 24.11666413714029],
            [52.58332715746181, 22.938891067436188],
            [55.141654166389031, 22.633327109940907],
            [55.199163242426863, 22.699718153522312],
            [55.666109178989558, 21.99971809238103],
            [55.422200154492344, 21.267509059455492],
            [55.000000169966398, 20.000000172294634],
            [51.99929122999086, 18.999345099085744],
            [51.221991245892781, 18.86500012225234],
            [50.783336076644929, 18.788891100102134],
            [49.116663357157535, 18.616664111761779],
            [48.183336089031485, 18.166664204172193],
            [47.836082221405974, 17.740044945003589],
            [47.60000021811581, 17.450000081281118],
            [47.466663248961169, 17.116664196279274],
            [47.429645076397691, 17.094891196891737],
            [47.183336145376558, 16.950000109453626],
            [47.000000285450454, 16.950000109453626],
            [46.750000131898588, 17.283335994455754],
            [46.366663344468321, 17.233336097855826],
            [46.100000134995327, 17.250000159605335],
            [45.400000241491938, 17.333336058693575],
            [45.216663375737454, 17.433336019531524],
            [44.650000283750813, 17.433336019531524],
            [44.566663378834193, 17.40555403374087],
            [44.466663250358152, 17.433336019531524],
            [44.366109245718889, 17.433336019531524],
            [44.185273204602794, 17.409164119448562],
            [44.089718167031293, 17.346664122970068],
            [43.939163259963522, 17.306453954030317],
            [43.760963327403573, 17.351945057261545],
            [43.738609126848331, 17.365554082933258],
            [43.671527079167191, 17.419718109042392],
            [43.653882167105252, 17.44360904773022],
            [43.638700193479252, 17.46959110362782],
            [43.620827126012131, 17.481244966559373],
            [43.513609173053595, 17.521944973922118],
            [43.445136064717616, 17.526453934928909],
            [43.407218178664635, 17.513882085958969],
            [43.37360926140812, 17.495135959454714],
            [43.3458361604348, 17.476664088823227],
            [43.31388216614593, 17.459718059849919],
            [43.25880920645389, 17.414645045950394],
            [43.191936203438701, 17.342218026237347],
            [43.151663337862971, 17.122218045338755],
            [43.123536185298491, 16.92513603180457],
            [43.16097328538396, 16.835553938452549],
            [43.197773193188283, 16.7158270007982],
            [43.206109163557556, 16.672218137749255],
            [43.134718147079894, 16.680000064317213],
            [43.102427200282449, 16.677773160275322],
            [43.079436142722187, 16.601109088495306],
            [43.070136085846997, 16.560409081132477],
            [43.059854172830995, 16.542364184649912],
            [42.918609213284384, 16.423053995222503],
            [42.823536135146838, 16.381182197792654],
            [42.789682298839494, 16.377500027858716],
            [42.772138891000196, 16.404111000000128],
            [42.149999908000126, 16.40411097150006],
            [41.783332778500125, 16.290000000000148],
            [41.082032369571635, 16.290000000000148],
            [41.042087113000122, 16.331235697000054],
            [40.962699509000259, 16.427628309000127],
            [40.91360292400006, 16.471419233],
            [40.891980034000227, 16.524604426000067],
            [40.888699804000254, 16.530236221000209],
            [40.839147425000164, 16.615671744999972],
            [40.826308780000062, 16.647618695000247],
            [40.770785531000257, 16.797320782000028],
            [40.711996826000217, 16.95631557200015],
            [40.680071132000336, 17.042187031000012],
            [40.67533807300012, 17.054961381000041],
            [40.607262813000119, 17.102030548000172],
            [40.49376674900023, 17.180744335000142],
            [40.229725513000318, 17.363213726000126],
            [40.123594806000227, 17.4385013200001],
            [39.959283343000124, 17.500021505999982],
            [39.929946422000256, 17.554711269000038],
            [39.916516497000117, 17.583333886000119],
            [39.863008838000184, 17.712967898000088],
            [39.83537700500014, 17.784205005999965],
            [39.826706344000314, 17.807011801000087],
            [39.823586364000079, 17.813399954000076],
            [39.815593247000123, 17.829805749000101],
            [39.813226793000098, 17.834725215000091],
            [39.793661121000042, 17.877100164000112],
            [39.760858448000107, 17.950420275000042],
            [39.727942036000258, 18.038168173000017],
            [39.725473466000125, 18.04509555200012],
            [39.715429534000151, 18.073328403000048],
            [39.716920657000088, 18.095436532999983],
            [39.728802097000113, 18.17429592900011],
            [39.72817787600016, 18.226608339],
            [39.74675960800019, 18.504953158000077],
            [39.746872420000187, 18.506653744],
            [39.750667132000018, 18.565184583000061],
            [39.747566790000093, 18.615405494000029],
            [39.745973541000154, 18.642112391000055],
            [39.511664467000145, 18.943482650000036],
            [39.394166530000149, 19.145209544000167],
            [39.340348125000077, 19.243369557],
            [39.308682349000179, 19.3011277],
            [39.278507060000123, 19.356449023],
            [39.244651485000219, 19.41242521],
            [39.124189851000125, 19.547830282000163],
            [39.013483713000056, 19.596967661999983],
            [39.011605557000081, 19.598037279000025],
            [39.010477708000195, 19.598671270000082],
            [38.941016528, 19.637795515000093],
            [38.801781420000026, 19.794625616000062],
            [38.586602230000182, 20.034387520999971],
            [38.439498433000182, 20.197229529000097],
            [38.330756326000056, 20.266434683000071],
            [38.323715022000016, 20.279165718000158],
            [38.329045683000089, 20.376633414000096],
            [38.332552506000155, 20.552492252000135],
            [38.337490058000213, 20.673430398000121],
            [38.351846928000015, 20.81621173300006],
            [38.343808051000025, 20.893808460000159],
            [38.32564350800007, 20.980362093999972],
            [38.321357192000136, 21.000999565000015],
            [38.211513095000072, 21.43074444600002],
            [38.205596047000057, 21.453188231000112],
            [38.193662741000168, 21.470932796000042],
            [38.189136257, 21.477167052000013],
            [38.049917074000206, 21.668744878000112],
            [38.003784269000079, 21.721663185000139],
            [37.908917864000188, 21.898682705000098],
            [37.904135866000303, 21.907605853999982],
            [37.896542395000182, 21.921775175000036],
            [37.893138265500141, 21.928131459500108],
            [37.893100553000153, 21.930728289000101],
            [37.893064556000098, 21.933213308000077],
            [37.893037680000049, 21.935068674000135],
            [37.89317107500014, 21.9895655830001],
            [37.893304517000189, 22.04415820500013],
            [37.893308084000211, 22.04777132800011],
            [37.893349818000303, 22.069502439000118],
            [37.89337398800015, 22.082087904999966],
            [37.893432940000054, 22.11278431099997],
            [37.893894291000123, 22.165472356000137],
            [37.894082244000202, 22.186937303999954],
            [37.894450053000099, 22.228942535000144],
            [37.890766399000057, 22.249436656000213],
            [37.885757506000033, 22.277303782999965],
            [37.873275429000131, 22.307556344000105],
            [37.85577981600008, 22.349960113000108],
            [37.813646563000219, 22.458710878000076],
            [37.77357440300014, 22.562141725500069],
            [37.735572053000141, 22.660230154000075],
            [37.734702659000135, 22.660944165000089],
            [37.729027449000256, 22.665605065000094],
            [37.723871896000077, 22.669839185000129],
            [37.663162092000192, 22.722988372000074],
            [37.598582828000104, 22.779525128333418],
            [37.587552110000189, 22.789182114000099],
            [37.581179649000063, 22.794770295000077],
            [37.450360782500155, 22.909488899500118],
            [37.446077609000071, 22.914461809000116],
            [37.415499247000099, 22.949964321999957],
            [37.414735195000191, 22.950850456000083],
            [37.388719035000094, 22.980847260000118],
            [37.353682237000186, 23.021456591000074],
            [37.325393749000085, 23.054244305000097],
            [37.268141821000057, 23.20929883899997],
            [37.232964915000281, 23.299997307],
            [37.131155674000098, 23.512375329],
            [37.081316480000197, 23.616341818000123],
            [37.074153350000273, 23.627361612000087],
            [37.062187868000052, 23.645769367000113],
            [37.027567116000085, 23.678166996500138],
            [36.849893290000324, 23.84443175],
            [36.743547698000128, 23.943328416000142],
            [36.731401977000104, 23.955534871000154],
            [36.696648616000147, 23.99048091],
            [36.668842553000133, 24.019085795],
            [36.657084271000116, 24.031141086000119],
            [36.485524516000197, 24.207184376000058],
            [36.479441958000137, 24.213495568000084],
            [36.441805279000192, 24.246766139000087],
            [36.432017849000061, 24.269556222999981],
            [36.338926508000128, 24.486771246999965],
            [36.337683884000143, 24.489668557000059],
            [36.317448653000184, 24.536662695000089],
            [36.298642555000214, 24.555581182000012],
            [36.165893595000313, 24.688780130999973],
            [36.156022309000122, 24.709085326999954],
            [36.112353960000206, 24.803694939000124],
            [36.013108581000068, 24.909709800000101],
            [35.969865659000078, 24.955931093000046],
            [35.96112897000009, 24.965257905000115],
            [35.955471200000119, 24.974699467999969],
            [35.952014971000125, 24.980564719000043],
            [35.942307688000113, 24.997079548000031],
            [35.908467726000168, 25.054779819000132],
            [35.906961801333438, 25.057338604000094],
            [35.898120394000074, 25.067455538000061],
            [35.862647238000278, 25.107693845000071],
            [35.850846926000287, 25.121234751000102],
            [35.778692729000085, 25.203640604000114],
            [35.709957703000129, 25.362213547000053],
            [35.686553917000111, 25.414991073000252],
            [35.630473564000084, 25.538771226000094],
            [35.626292357000153, 25.557189956000045],
            [35.622022089000069, 25.576001009000137],
            [35.613741438000176, 25.613626414000109],
            [35.607135447000275, 25.643642543000041],
            [35.58192919800004, 25.756463780000061],
            [35.569995781000245, 25.774443343000101],
            [35.588247319000089, 25.960996174000158],
            [35.60011717900008, 26.082320533000129],
            [35.599790352000099, 26.083424632000074],
            [35.595848406000101, 26.096741450000039],
            [35.548753661, 26.22685711299998],
            [35.520907019000134, 26.3030704130001],
            [35.406735778000069, 26.616704072000189],
            [35.368752495000052, 26.652519863000194],
            [35.338447274000288, 26.680655294000118],
            [35.278078353000154, 26.736815155000116],
            [35.212205926000166, 26.794132503000256],
            [35.154543203000259, 26.84637961500006],
            [34.983069276000293, 27.00177335000005],
            [34.980420654000142, 27.003176246000137],
            [34.841205933000111, 27.040477800000232],
            [34.824221959000255, 27.045077123000098],
            [34.778182592000093, 27.095236282000016],
            [34.774942388000255, 27.102412480000126],
            [34.773633209000167, 27.105311966000031],
            [34.771053661000082, 27.110933670000136],
            [34.758963726000189, 27.137281711000142],
            [34.732778829000296, 27.188066589000115],
            [34.725837731000098, 27.201504081000124],
            [34.719420448000136, 27.227918231000203],
            [34.725541576000211, 27.335740330000121],
            [34.708691653000216, 27.369189569000255],
            [34.708009325000063, 27.37054407500024],
            [34.685470591000097, 27.415249318],
            [34.616670505000258, 27.551713262],
            [34.567119630000235, 27.617227063000186],
            [34.534659147000156, 27.659293526000098],
            [34.511240863000097, 27.690297090000101],
            [34.495230288000101, 27.710591106999956],
            [34.465920010000133, 27.796063469000117],
            [34.46156377000014, 27.809061752000119],
            [34.459909356000111, 27.813477000000219],
            [34.458672818000167, 27.816663564000081],
            [34.458177036000308, 27.818032198000068],
            [34.458048671000171, 27.823386828000011],
            [34.456246555000149, 27.848424926],
            [34.449863276000229, 27.881264741000237],
            [34.449695338000112, 27.88404773800012],
            [34.448954869000175, 27.898952842000128],
            [34.448199922000157, 27.908106733],
            [34.450494022000242, 27.91673782600013],
            [34.456377401000083, 27.937390876000123],
            [34.45820519700024, 27.943051184000112],
            [34.460322883000032, 27.947897494000088],
            [34.462680315000142, 27.95431743500005],
            [34.46344539250012, 27.963984154500125],
            [34.465117868000021, 27.968744602000143],
            [34.466038665000241, 27.971772111000078],
            [34.466427278500106, 27.972851345000109],
            [34.467602428000163, 27.982866147000166],
            [34.468758543000234, 27.987258040000015],
            [34.469211805000072, 27.995847791000116],
            [34.46987605100017, 28.003342645000103],
            [34.471216786000269, 28.005742418000168],
            [34.475214376000139, 28.01548030100021],
            [34.477104357000115, 28.022046108000168],
            [34.478900413000218, 28.025848482],
            [34.4838355790001, 28.038387920000119],
            [34.48463559450019, 28.040659958500143],
            [34.491793072000206, 28.04939540900007],
            [34.497200359000232, 28.056639708000219],
            [34.500843216000163, 28.060728324000095],
            [34.50928743900019, 28.070785419000103],
            [34.508802048000092, 28.072629830000025],
            [34.506837448000255, 28.078520331000064],
            [34.506024677000283, 28.081165146000146],
            [34.506133204000065, 28.088193842000109],
            [34.50686555300004, 28.09376448400026],
            [34.50731647000012, 28.096633725999979],
            [34.507606235000111, 28.098964406000093],
            [34.509130232000103, 28.108593850000133],
            [34.51093794400029, 28.117214210000085],
            [34.511230742000151, 28.121185747000069],
            [34.512170334000103, 28.129355081000099],
            [34.514771784000203, 28.13588883000007],
            [34.517548715000231, 28.142403081000211],
            [34.519230656500127, 28.146025582000092],
            [34.524903839000189, 28.159929142000195],
            [34.525683116000124, 28.161698326000206],
            [34.52927380600002, 28.173573502000252],
            [34.529739536000164, 28.175447486000238],
            [34.532217381000095, 28.183930018000055],
            [34.533280352000219, 28.187172407000205],
            [34.533823614000056, 28.188671262000142],
            [34.539770588000266, 28.208295052000224],
            [34.539858979000172, 28.209508366000065],
            [34.540099772000275, 28.215229087000083],
            [34.541162139000022, 28.222230520000224],
            [34.543368841000159, 28.239706053000091],
            [34.543856744000124, 28.244116589000171],
            [34.543685356000054, 28.249570226000131],
            [34.543501629000133, 28.260911476999965],
            [34.544209075000055, 28.263248545000252],
            [34.544807262000148, 28.274847659000201],
            [34.544531569000156, 28.284309787000083],
            [34.549505273000108, 28.293498542000165],
            [34.551301732000155, 28.29691671000019],
            [34.558188810000075, 28.311750850000109],
            [34.568586368000155, 28.333131288000246],
            [34.575047219000197, 28.343839515000113],
            [34.576285784000135, 28.3473110460002],
            [34.581841354000147, 28.364696938000222],
            [34.582276572000211, 28.366143105000234],
            [34.583509950000092, 28.37036661],
            [34.583986626000268, 28.372053653000066],
            [34.584733387000171, 28.372821423],
            [34.596944696000065, 28.388158852000075],
            [34.598534821000243, 28.390607916000121],
            [34.602673551000322, 28.39720864000013],
            [34.60418945700016, 28.399693857000145],
            [34.612326225000146, 28.412332344000205],
            [34.61545211800015, 28.417911850000181],
            [34.616771626000087, 28.421127520000155],
            [34.621913762000105, 28.433143711000056],
            [34.623985238000245, 28.438170202000194],
            [34.627882186000107, 28.447373278000143],
            [34.632247548000265, 28.4573142200001],
            [34.634440004000027, 28.461654220000113],
            [34.634800782000212, 28.462966636000075],
            [34.638464706000121, 28.475018424000083],
            [34.641843098000066, 28.486701951000086],
            [34.643241411000218, 28.492421949000246],
            [34.644990478000153, 28.500741888000206],
            [34.64721912200028, 28.509466857000106],
            [34.6476195810001, 28.511704711000178],
            [34.654991327000317, 28.523901015000163],
            [34.659182467000079, 28.531506320000133],
            [34.660349349000143, 28.533739648000093],
            [34.66349399900011, 28.539629786000106],
            [34.664078566000086, 28.540629669000154],
            [34.66723529000015, 28.544047344999953],
            [34.667953182000105, 28.544876511000083],
            [34.670508773000194, 28.553827818000158],
            [34.675121413000255, 28.568509224000039],
            [34.677558346000211, 28.577405345000088],
            [34.680842237000235, 28.588656951000061],
            [34.684175701000157, 28.604203252000204],
            [34.684987075000123, 28.607708533000149],
            [34.685011441000114, 28.617933714000102],
            [34.682026692000221, 28.634221723000152],
            [34.681989322000192, 28.635520352000114],
            [34.682254476000281, 28.637454537000096],
            [34.687801724500076, 28.662268097500217],
            [34.688354917000112, 28.664468408000133],
            [34.690225845000072, 28.670613924000051],
            [34.691315569000295, 28.674018622000219],
            [34.692428611000281, 28.67733436450014],
            [34.694108417000081, 28.68174725100009],
            [34.69519258800014, 28.683986332],
            [34.695518489000136, 28.684694656000076],
            [34.696661023000075, 28.687566036000064],
            [34.697763227000138, 28.690070545000069],
            [34.702725127000093, 28.703446031000098],
            [34.704573328000066, 28.708127511000029],
            [34.70495264600018, 28.709033709999957],
            [34.708007256000059, 28.716620977000233],
            [34.71175036200006, 28.727500620999976],
            [34.712441508000239, 28.730349200000219],
            [34.712639930000108, 28.731221839000213],
            [34.713791062000126, 28.738943842000097],
            [34.714519146000185, 28.74458843500021],
            [34.716245942500194, 28.749518225500125],
            [34.719327805000063, 28.764734012000119],
            [34.720422163000165, 28.769110115000089],
            [34.721268288000118, 28.779993145000034],
            [34.721020674000243, 28.784601488000121],
            [34.721110727000138, 28.786168530000211],
            [34.721368033000118, 28.789568200000119],
            [34.722274550000151, 28.798191236000022],
            [34.723909908000024, 28.805003809000155],
            [34.729663499000281, 28.813975528000228],
            [34.734931668000144, 28.822400667000196],
            [34.735547320000109, 28.825570361000089],
            [34.736880805000141, 28.832018203000146],
            [34.739996474000094, 28.848539855000254],
            [34.737468573000086, 28.863272391000109],
            [34.736376835000073, 28.873396333000116],
            [34.736623545000072, 28.88127892200005],
            [34.736638715000282, 28.883762661000119],
            [34.737646107000131, 28.898024957000146],
            [34.738662725000125, 28.909345561000166],
            [34.738851767000057, 28.912036664000155],
            [34.739693193500187, 28.917339530000149],
            [34.741457480500145, 28.919655077000172],
            [34.742338221000324, 28.920741068000225],
            [34.753840153000311, 28.942145638000042],
            [34.754638882000194, 28.943758315000068],
            [34.757090139000098, 28.948212305000098],
            [34.760470239000171, 28.954558905000127],
            [34.761252373500184, 28.956234785500087],
            [34.761843348000156, 28.957498828000084],
            [34.76497800700011, 28.977282178999971],
            [34.76596171900033, 28.986226053000152],
            [34.766680426000079, 28.99122862600008],
            [34.766980252000309, 28.993675549000116],
            [34.768213057000224, 28.999524642000111],
            [34.769493073000177, 29.005392278000116],
            [34.768633083000196, 29.010075782000214],
            [34.767161589000096, 29.024073801000142],
            [34.765296227000107, 29.039921075000038],
            [34.764441920500161, 29.048000625500137],
            [34.76561885400011, 29.056568571000255],
            [34.765900315000209, 29.058117069000119],
            [34.768909300000047, 29.065467901000105],
            [34.769554395000029, 29.067102248000069],
            [34.772915798000071, 29.077413770000064],
            [34.775660500000214, 29.086187324000079],
            [34.77678888900013, 29.090244416000118],
            [34.777837423000136, 29.092269246000157],
            [34.778374630000286, 29.093355338000137],
            [34.780510980000116, 29.101157425000238],
            [34.780933866000026, 29.103294017000096],
            [34.781271960000112, 29.104954095500105],
            [34.786359629000145, 29.115739767000107],
            [34.792614262000114, 29.128333989000154],
            [34.79569599400017, 29.134936278000097],
            [34.797478080000218, 29.140025819500082],
            [34.798502343000138, 29.144277546000112],
            [34.806317939000024, 29.162161125000196],
            [34.81116463100031, 29.172827736500111],
            [34.812316357000299, 29.175117981000042],
            [34.815135439000215, 29.188398525000011],
            [34.81729521000014, 29.195528073000176],
            [34.821965097000174, 29.214821203500108],
            [34.822453948000117, 29.216883006000231],
            [34.824149685000151, 29.224458228000131],
            [34.824346342000098, 29.227385652000052],
            [34.824108009000241, 29.234765494000115],
            [34.82422846300031, 29.238528632000097],
            [34.826817082000133, 29.246859792000194],
            [34.830940078000054, 29.259037248000212],
            [34.833321454000242, 29.267793525000044],
            [34.834100403000065, 29.27035204600007],
            [34.834621726000108, 29.271523929000139],
            [34.834687557000137, 29.273335352000188],
            [34.83609627700028, 29.276239058000087],
            [34.838897571000331, 29.281965624000165],
            [34.851532380000066, 29.310353619000111],
            [34.855515134000115, 29.318975379000179],
            [34.860655079000111, 29.3258905550001],
            [34.863905740000149, 29.330046804000077],
            [34.86692092600012, 29.336249750000135],
            [34.867826454000095, 29.337949412000071],
            [34.869213293000172, 29.340521046000234],
            [34.870356931000316, 29.343500900000222],
            [34.871268782000158, 29.345876814000164],
            [34.873485822000106, 29.35125067],
            [34.873912369000237, 29.35224046],
            [34.874583180000315, 29.353797062000197],
            [34.877683743000233, 29.361467469999965],
            [34.881891514000159, 29.36896947800021],
            [34.884496975800147, 29.375334759400133],
            [34.930893521000058, 29.364446786000101],
            [34.940743346000119, 29.361538413000162],
            [34.952259674000146, 29.360858005000082],
            [34.95774414450014, 29.360553311500055],
            [34.961390935623882, 29.360836115336397],
            [35.187218145612036, 29.326945019554714],
            [35.541109139466613, 29.27250003205036],
            [36.070000190516083, 29.188891050555824],
            [36.272727080578505, 29.336527043653504],
            [36.500545197866842, 29.502773208785541],
            [36.743609158883459, 29.864718038147757],
            [37.034436221812399, 29.918054099858878],
            [37.410100215054484, 29.985582064790165],
            [37.502782271485643, 30.002218130983138],
            [37.667500248026357, 30.336391200476598],
            [38.001391182658125, 30.504164213095478],
            [37.56109114872649, 30.951091147334168],
            [37.490000204383449, 31.023053977247898],
            [37.294991203148328, 31.217227135096081],
            [37.005273233650797, 31.505554043938858],
            [37.202200181975769, 31.556164143091607],
            [37.471936197161511, 31.625000187836164],
            [37.709500120937179, 31.684664251186334],
            [37.963882165843273, 31.748336037731931],
            [37.986663340547437, 31.754054004456137],
            [38.488327120159994, 31.879164135620996],
            [39.005000206192591, 32.00555401576635],
            [39.072218208343457, 32.057782157512534],
            [39.196745126683908, 32.154945011755657],
            [39.487582247896654, 32.105553976604298],
            [40.074609173705056, 32.005864146184578],
            [40.413327078750939, 31.94832707459021],
            [41.44000025004155, 31.37332700640583],
            [42.085000274048724, 31.111664105105078],
            [42.924700174700973, 30.516154022707113],
            [43.065000158480842, 30.416664184498032],
            [43.810382190473689, 29.868500120509097],
            [44.721663376806106, 29.198327061900997],
            [46.12215417570215, 29.086073093862566],
            [46.426673245144372, 29.061664153557146],
            [46.546945174144838, 29.104200132996553],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SDN", Country: "Sudan" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [37.908917864000188, 21.898682705000098],
            [38.003784269000079, 21.721663185000139],
            [38.049917074000206, 21.668744878000112],
            [38.189136257, 21.477167052000013],
            [38.193662741000168, 21.470932796000042],
            [38.205596047000057, 21.453188231000112],
            [38.211513095000072, 21.43074444600002],
            [38.321357192000136, 21.000999565000015],
            [38.32564350800007, 20.980362093999972],
            [38.343808051000025, 20.893808460000159],
            [38.351846928000015, 20.81621173300006],
            [38.337490058000213, 20.673430398000121],
            [38.332552506000155, 20.552492252000135],
            [38.329045683000089, 20.376633414000096],
            [38.323715022000016, 20.279165718000158],
            [38.330756326000056, 20.266434683000071],
            [38.439498433000182, 20.197229529000097],
            [38.586602230000182, 20.034387520999971],
            [38.801781420000026, 19.794625616000062],
            [38.941016528, 19.637795515000093],
            [39.010477708000195, 19.598671270000082],
            [39.011605557000081, 19.598037279000025],
            [39.013483713000056, 19.596967661999983],
            [39.124189851000125, 19.547830282000163],
            [39.244651485000219, 19.41242521],
            [39.278507060000123, 19.356449023],
            [39.308682349000179, 19.3011277],
            [39.340348125000077, 19.243369557],
            [39.394166530000149, 19.145209544000167],
            [39.511664467000145, 18.943482650000036],
            [39.745973541000154, 18.642112391000055],
            [39.747566790000093, 18.615405494000029],
            [39.750667132000018, 18.565184583000061],
            [39.746872420000187, 18.506653744],
            [39.74675960800019, 18.504953158000077],
            [39.72817787600016, 18.226608339],
            [39.728802097000113, 18.17429592900011],
            [39.716920657000088, 18.095436532999983],
            [39.715429534000151, 18.073328403000048],
            [39.488183035000276, 18.051103599000029],
            [39.481296147000279, 18.050587659000072],
            [39.323881722000095, 18.04036355300012],
            [39.251175184000061, 18.039011845000189],
            [39.238761175000292, 18.039013792000091],
            [39.222837960000135, 18.039054994000125],
            [39.201318768000164, 18.039692502000108],
            [39.098842136000115, 18.042961009000081],
            [39.08465294700008, 18.045833958000216],
            [38.948616558000282, 18.072886774000082],
            [38.914303428000068, 18.079937992000069],
            [38.903975279000207, 18.08215506000009],
            [38.868781891000168, 18.090049095000083],
            [38.862684112000153, 18.091396588000109],
            [38.805653383000305, 18.064878427999972],
            [38.758415215000042, 18.043113167999977],
            [38.731307103000262, 18.03248409199999],
            [38.708830850000169, 18.023815822000259],
            [38.706168420000125, 18.022778042000013],
            [38.702489490000318, 18.02171961000009],
            [38.698671438000162, 18.020565282000248],
            [38.693502096, 18.019225005000109],
            [38.639525664000104, 18.004790306000118],
            [38.626987818000117, 18.003037920999972],
            [38.612376960000091, 18.000609863000065],
            [38.600691226203395, 17.994882127126843],
            [38.505827192934788, 17.913327047318987],
            [38.45860925000656, 17.871109077286732],
            [38.449163348015503, 17.851109018063923],
            [38.369163278762443, 17.664445039653842],
            [38.257500234899538, 17.532773219388801],
            [38.135554104553904, 17.49888216727048],
            [38.092254030819191, 17.543227129058451],
            [38.058745193486715, 17.552500028567223],
            [38.034718132691211, 17.54166423938797],
            [37.966527159216724, 17.499718010657531],
            [37.933882160828119, 17.458609134056772],
            [37.895554064432787, 17.441391196143726],
            [37.744718195969796, 17.381945062276841],
            [37.623054032847932, 17.357500079787613],
            [37.588745059036427, 17.350136074913095],
            [37.512073275905408, 17.32118213141716],
            [37.520273291804841, 17.277773092940762],
            [37.530691159290797, 17.249427005064703],
            [37.520573196301399, 17.195800091312478],
            [37.486109157280424, 17.110553956485305],
            [37.423291151394693, 17.034209067579113],
            [37.381382305952542, 17.043053987110838],
            [37.257354110852674, 17.026944974990855],
            [37.147773299382351, 17.017009066938996],
            [37.108327224738247, 17.047918005536914],
            [37.026109136260658, 17.077218121635084],
            [36.993809137007617, 17.064891191876526],
            [37.013054154390176, 17.022427129166843],
            [37.008954062621484, 16.786318136148381],
            [36.988882254307299, 16.725554031822398],
            [36.972636114251117, 16.700000123539994],
            [36.939300279400953, 16.677636032339038],
            [36.914027164875705, 16.64444503677602],
            [36.898327189632056, 16.536944948955764],
            [36.896663214209099, 16.514717985691178],
            [36.902773286364692, 16.493609000675136],
            [36.928054112240972, 16.459445033788811],
            [36.952836214876669, 16.433609158282806],
            [36.973882167980747, 16.285554070025213],
            [36.966873220525798, 16.259927071547068],
            [36.924718114767842, 16.227082080948023],
            [36.889445054450192, 16.14666409000138],
            [36.826109214584761, 15.97750001672766],
            [36.763609218106268, 15.808053976230681],
            [36.696245203201528, 15.748609015829985],
            [36.671663260414078, 15.659164218052538],
            [36.626109125270972, 15.489445095149094],
            [36.61054510449722, 15.437218126869013],
            [36.565554065610769, 15.302773070111627],
            [36.542773226182845, 15.234718051107265],
            [36.479718180074343, 15.180273063602911],
            [36.443282214507093, 15.14995404335194],
            [36.452218161507318, 15.068609013911512],
            [36.51554511655533, 14.450282049901816],
            [36.541663294561118, 14.278054055732881],
            [36.542818153183788, 14.262054008354582],
            [36.539445107698469, 14.246109113899408],
            [36.446391230041598, 13.956945020565215],
            [36.486936172195129, 13.839444986952557],
            [36.471109127298888, 13.804164215283961],
            [36.421036140503105, 13.686873226337241],
            [36.409027220151955, 13.657500020043315],
            [36.407354024635453, 13.635273224416622],
            [36.411382199674762, 13.615553958950954],
            [36.408745085290292, 13.595135978034634],
            [36.397500259234931, 13.567782139859474],
            [36.362218146461856, 13.519445045216116],
            [36.348882203196268, 13.502773104477498],
            [36.248600275134805, 13.368053959485323],
            [36.15735403872165, 13.033053931422245],
            [36.168882173105288, 12.990000118003309],
            [36.151109185562433, 12.971664201841548],
            [36.142082211093367, 12.952773068411986],
            [36.135345172578212, 12.925418056770255],
            [36.15916335634617, 12.88139110438982],
            [36.142545059788034, 12.714853919578673],
            [36.111936193324453, 12.694027069424422],
            [36.093400284952423, 12.706391047194998],
            [36.072291299936381, 12.724100164635473],
            [36.037218231829144, 12.715000099970553],
            [36.012636121403744, 12.720553949030133],
            [35.964718121920299, 12.715553976133975],
            [35.701082288244635, 12.666118013981276],
            [35.700136139011448, 12.62500008492502],
            [35.686245146116192, 12.602218072030524],
            [35.647354121101756, 12.591527122138402],
            [35.383609155046514, 12.165553940068463],
            [35.283882276614776, 11.983327005935607],
            [35.265436054606965, 11.933609076559293],
            [35.25360918919344, 11.917500064439409],
            [35.231382225928684, 11.898191176954413],
            [35.20166334994687, 11.886391133630966],
            [35.168327179820466, 11.878335957018834],
            [35.134436127702344, 11.863891087960084],
            [35.116245050827814, 11.850836106089787],
            [35.102354057932558, 11.834444959108396],
            [35.083609104894805, 11.80555404752441],
            [35.063191291616562, 11.759582158325799],
            [35.058327105552138, 11.730273157410309],
            [35.066800203857866, 11.646527047979575],
            [35.078191210305107, 11.623473126145271],
            [35.091663275678542, 11.598891183357907],
            [35.09520027119035, 11.576454001960897],
            [35.087491267180297, 11.535827084794107],
            [35.013327181848439, 11.367773110780192],
            [35.00443616184927, 11.348891029806026],
            [34.986382212911138, 11.318335975161006],
            [34.967082210243547, 11.274864240048558],
            [34.96416329627354, 11.250418084092928],
            [34.971109211816355, 11.231109028969755],
            [34.978882253566809, 11.223891036849224],
            [35.007500250382662, 11.198327070282943],
            [35.005554140097928, 11.174445016412619],
            [34.983600259239523, 11.110282050339478],
            [34.933327112595322, 10.956664228204986],
            [34.978609171160684, 10.915273216742619],
            [34.975273173687498, 10.864445020468594],
            [34.860618125658647, 10.732009105907807],
            [34.800554077887881, 10.723327130574489],
            [34.769863236411055, 10.749444973304008],
            [34.710827145248658, 10.802218106396296],
            [34.673882230519013, 10.834718097859579],
            [34.594445089884715, 10.887782082992814],
            [34.439718174318813, 10.784444951215377],
            [34.352773195351261, 10.631664145934309],
            [34.314718181361997, 10.586391139824329],
            [34.286109237001739, 10.554164230767341],
            [34.328045072172046, 10.35000001194426],
            [34.34194511752284, 10.280836067146652],
            [34.348327098618455, 10.23847309018926],
            [34.32305415173127, 10.117218131581666],
            [34.230554150237737, 10.027636038229559],
            [34.219718193420221, 9.973609140056809],
            [34.207218093541741, 9.905000077250918],
            [34.140000259028767, 9.75805408242465],
            [34.085827180464236, 9.553054020214532],
            [34.094163318471686, 9.45471803479974],
            [34.094991282869586, 9.453891076230107],
            [34.097773236541315, 9.419718056888371],
            [34.100554184384606, 9.385273128606912],
            [34.134991233677141, 8.955273112602043],
            [34.130000145598416, 8.805826963689611],
            [34.1236002272299, 8.634164077434292],
            [34.122800258388594, 8.584644966801847],
            [34.121663337038711, 8.579582129631717],
            [34.105545104825325, 8.557782140515968],
            [34.070827094137627, 8.523336038768022],
            [34.002218198969814, 8.460282166126191],
            [33.970754043103824, 8.437564190972139],
            [33.915273220002149, 8.430554070050803],
            [33.881527174809349, 8.430836037274418],
            [33.852491256300169, 8.425836064378259],
            [33.818745043469249, 8.411526982151329],
            [33.789718177415551, 8.384445052915794],
            [33.771327108330667, 8.367744948982377],
            [33.71527329832648, 8.375064194493746],
            [33.69048231087325, 8.395482175410066],
            [33.669991239761003, 8.437082063900192],
            [33.63437318030779, 8.467153985644771],
            [33.508609260693305, 8.466200125060737],
            [33.380827146426697, 8.437918074925221],
            [33.326736210513531, 8.453682087909357],
            [33.263536157479876, 8.46152704638925],
            [33.171245200652123, 8.399791144206503],
            [33.160827165528104, 8.356527112655243],
            [33.165836190879787, 8.27832697329498],
            [33.17805415589666, 8.222500145229361],
            [33.185918225115842, 8.140291109207084],
            [33.162773275812896, 8.115000057409105],
            [33.121382264350501, 8.100973110043782],
            [33.031945177923802, 7.997391059054948],
            [32.991800220190811, 7.926044969578427],
            [32.996827182815338, 7.887217982304492],
            [33.014973165050577, 7.846064178702591],
            [33.05221815191473, 7.790691146876227],
            [33.096663361264774, 7.781109122777252],
            [33.257500181348377, 7.763609050002529],
            [33.439163348672054, 7.744445001804849],
            [33.464445180376657, 7.744027080111323],
            [33.647218111683941, 7.685000041404336],
            [33.712491177016688, 7.658473161798611],
            [33.885554176382271, 7.525417992229592],
            [34.027709242978148, 7.378673163080052],
            [34.032773253614749, 7.304445040007664],
            [34.029745039627159, 7.280036099702244],
            [34.02708227661887, 7.248127032414601],
            [34.09916329136783, 7.19971802104169],
            [34.163327095631217, 7.171664126311228],
            [34.193882150276153, 7.090000081635139],
            [34.223609240523245, 7.042500171483283],
            [34.235691251070222, 7.01971799095071],
            [34.280554046837693, 6.975836045495669],
            [34.299573255748129, 6.964327021851346],
            [34.328745128727149, 6.958745009597052],
            [34.360963320604895, 6.959026976820667],
            [34.430954056334059, 6.935409120539134],
            [34.471800244088598, 6.914309020340511],
            [34.486973165259172, 6.877491175263373],
            [34.528818140598872, 6.747218127004118],
            [34.553609128052102, 6.735691166086966],
            [34.602773181264951, 6.734718027125552],
            [34.632082182180483, 6.729027050129673],
            [34.654718182321261, 6.716664078187492],
            [34.679163332448383, 6.700000016437883],
            [34.704718246559167, 6.677782105628708],
            [34.756991147668515, 6.613782083753691],
            [34.85000026596245, 6.36666413143594],
            [34.856382247058178, 6.291109153087149],
            [34.859300155199747, 6.267218046761428],
            [34.870554033710647, 6.237500008969832],
            [34.922427118037348, 6.132291030569974],
            [34.952218246024898, 6.103473209181942],
            [34.969718151161516, 6.086527012570642],
            [34.981382240014995, 6.061109058758163],
            [34.987827085384623, 6.013944927648481],
            [34.975827217489012, 5.964718010161633],
            [34.986382212911138, 5.878053992589201],
            [35.028327100536927, 5.814164108922341],
            [35.105409261648532, 5.688335983929349],
            [35.103882246523796, 5.632500103408233],
            [35.289991175304152, 5.507782077674946],
            [35.292700206418004, 5.487636005698434],
            [35.277082206187657, 5.458053922376749],
            [35.270418090230379, 5.438335998015731],
            [35.303054036163445, 5.377364190128461],
            [35.326245085934175, 5.357082163682051],
            [35.352845055735742, 5.350691130130954],
            [35.41909126002983, 5.398400084948562],
            [35.443709245000974, 5.425518056367906],
            [35.504718100900305, 5.431391088301211],
            [35.527918203126404, 5.426809037098579],
            [35.726245096923748, 5.364444995089983],
            [35.753745115490773, 5.346945089953351],
            [35.774991228443099, 5.337218058929153],
            [35.796945109301504, 5.331108992601671],
            [35.821663341835034, 5.328609006153599],
            [35.798745039194529, 5.265553960045381],
            [35.796245052746457, 5.235136033336644],
            [35.805136072745626, 5.206809056199901],
            [35.830691154494417, 5.168400158257569],
            [35.823609116843585, 5.136664093451856],
            [35.802773214233838, 5.105273195419983],
            [35.779991201339357, 5.082636021812718],
            [35.774436178813261, 4.798609029310882],
            [35.924718171112687, 4.640273201503845],
            [35.940554100826347, 4.622500046322827],
            [35.899718138993791, 4.619718092651226],
            [35.709718163110409, 4.617773155832964],
            [35.699236090246046, 4.619182153760534],
            [35.696109137438697, 4.618053949590006],
            [35.675827110992287, 4.617500073426584],
            [35.641936058873995, 4.617218106202969],
            [35.608454043632065, 4.617744992638166],
            [35.580827123050511, 4.616945023796859],
            [35.546945123387871, 4.616664230039632],
            [35.508882230409512, 4.616735979131107],
            [35.146945112398299, 4.613891161185265],
            [34.950273309206608, 4.61278206775404],
            [34.821391153964129, 4.612218133306811],
            [34.773891243812358, 4.612500100530426],
            [34.713054049290434, 4.612218133306811],
            [34.482218111574497, 4.611109039875672],
            [34.407773232485425, 4.610553990245677],
            [34.388191262594233, 4.609682104675002],
            [34.374163309400586, 4.596664170816922],
            [34.096109261118187, 4.320554053524447],
            [33.996663344081838, 4.222782170194961],
            [33.523609179381964, 3.758473180378544],
            [33.511236149155849, 3.752700060731229],
            [33.387773229607632, 3.758053917580554],
            [33.245000249107903, 3.764164157374324],
            [33.183882260828824, 3.766109094192686],
            [33.127218248271703, 3.808335949042089],
            [33.016663292011543, 3.888609100701501],
            [32.978609116212596, 3.863335986176253],
            [32.93832719818144, 3.837217975808557],
            [32.898045112512222, 3.811945028921485],
            [32.874718108271821, 3.803891025775741],
            [32.800282281638175, 3.780553963251435],
            [32.71944519553162, 3.755836065994075],
            [32.669163331708063, 3.753336079546003],
            [32.635273285418322, 3.751945018891064],
            [32.479163355824682, 3.745835952563681],
            [32.404445059053245, 3.743609048521805],
            [32.199773232172532, 3.609727088021231],
            [32.200827172680732, 3.575036067061802],
            [32.194527166598419, 3.512754000066479],
            [32.135827189754167, 3.521109081175041],
            [32.111245079328711, 3.526664103701194],
            [32.090409176718907, 3.5361089998637],
            [32.077082285908858, 3.559026967228007],
            [32.068491170406134, 3.582218016998695],
            [32.025836167941321, 3.59139117185947],
            [31.951109153990529, 3.593891158307642],
            [31.946391148317929, 3.621664091642884],
            [31.932082233729176, 3.689164228655528],
            [31.853473225130898, 3.784718092760642],
            [31.821663231938913, 3.806945056025327],
            [31.789445040061167, 3.808744985918182],
            [31.748609245866675, 3.761391088520142],
            [31.704027076218466, 3.714445054531623],
            [31.540836114802374, 3.653609033476272],
            [31.459718234938606, 3.698891092041649],
            [31.377218179237417, 3.745553985340067],
            [31.286663282200124, 3.795000005776572],
            [31.203891149920963, 3.795836016801601],
            [31.176663207931796, 3.795282140638179],
            [30.979163272704085, 3.694444995308913],
            [30.95471812257685, 3.671109106250995],
            [30.891391167528838, 3.565553955249001],
            [30.868536064438416, 3.522573064387743],
            [30.858818085869672, 3.493391133124831],
            [30.847218202394771, 3.528335958113175],
            [30.786391233794831, 3.660000067027269],
            [30.735209153567752, 3.637782156217995],
            [30.63110910096114, 3.611109096220574],
            [30.59187324444909, 3.604927107335243],
            [30.562009193903776, 3.613264083532997],
            [30.575136092503641, 3.641664150865594],
            [30.581391171584727, 3.668891087026438],
            [30.581663248162528, 3.700135972304693],
            [30.551036109150147, 3.8638181132483],
            [30.527218093020252, 3.873053964744955],
            [30.494445186788681, 3.865073051795065],
            [30.434718091526662, 3.885836031847134],
            [30.361382305868716, 3.925282106491323],
            [30.336109191343638, 3.940282192817875],
            [30.275209132547758, 3.959309113079357],
            [30.24305414022021, 3.955282111506534],
            [30.207773200913635, 3.961664092602035],
            [30.166663318484467, 4.039718051570418],
            [30.148054152278434, 4.099582107130928],
            [30.11950924565852, 4.116809097499541],
            [30.079718171878682, 4.122364120025424],
            [30.061800177410618, 4.131391094494589],
            [29.987782272470469, 4.233891041780424],
            [29.959445069411828, 4.29139106536276],
            [29.838745160434115, 4.346873061930893],
            [29.799163298958121, 4.375345045992773],
            [29.792500188829223, 4.433053946602783],
            [29.800000148173382, 4.468891109005654],
            [29.806391181724535, 4.496108992711001],
            [29.813536083649325, 4.559027078521098],
            [29.69860912668068, 4.617500073426584],
            [29.643327122322972, 4.643609031338869],
            [29.614718177962715, 4.656109131217434],
            [29.494236198467917, 4.683536059588533],
            [29.467773188964742, 4.663891057785193],
            [29.466527135235225, 4.6347180113397],
            [29.464163270894943, 4.591944991677806],
            [29.432782263508699, 4.525553948096487],
            [29.325000208465013, 4.390000133183847],
            [29.24360924619495, 4.346109135273224],
            [29.210273243706723, 4.342082133700487],
            [29.159445047432683, 4.386109086080836],
            [29.131382267884732, 4.412218043993036],
            [29.011454164553612, 4.496982051748333],
            [28.977218113299756, 4.48381810513645],
            [28.890273301970268, 4.477782129004822],
            [28.827427132889653, 4.48270901170504],
            [28.802909227842719, 4.497082131672414],
            [28.803609116759873, 4.519100050271433],
            [28.782500131743717, 4.55736410892635],
            [28.712500175921008, 4.537500171811629],
            [28.693400165463657, 4.510973124567741],
            [28.687773226208236, 4.484582199431927],
            [28.672773307519691, 4.455835959497279],
            [28.652500165890757, 4.425418032788642],
            [28.63347324562929, 4.416809147651165],
            [28.584718229292406, 4.401945015794425],
            [28.505836138288004, 4.373053936572546],
            [28.488327180695961, 4.350000014738242],
            [28.477218141472321, 4.330835966540377],
            [28.363054105332793, 4.29000000470792],
            [28.201109197646332, 4.347845027425734],
            [28.084445175058789, 4.432782037662975],
            [28.028054077269786, 4.502218059038569],
            [27.929718091854824, 4.551109029845165],
            [27.865273158558153, 4.557082141702736],
            [27.789445097803224, 4.600135955121857],
            [27.771663225442893, 4.66610907700975],
            [27.777709259858426, 4.697400062755634],
            [27.782909224964925, 4.757500152710122],
            [27.76889116241702, 4.787773072493451],
            [27.660209226245826, 4.896182093896599],
            [27.524436140745394, 4.943609081490493],
            [27.45527320177618, 5.016391158623094],
            [27.458563266419816, 5.025827002330189],
            [27.459718125042599, 5.071600072784932],
            [27.400827208443474, 5.150345035853121],
            [27.365418193656041, 5.17055397210359],
            [27.320273263026962, 5.208336071324737],
            [27.297782269811563, 5.230973077293925],
            [27.275000256916968, 5.282500156656681],
            [27.254718230470672, 5.331391127463277],
            [27.236318108930362, 5.426453979679025],
            [27.257427093946404, 5.509509085010137],
            [27.279863269514834, 5.544582153117346],
            [27.27889113638193, 5.583891099825152],
            [27.240836122392551, 5.647782156958485],
            [27.201945097378342, 5.708609125558439],
            [27.142773219384054, 5.771944965423884],
            [27.110273227920771, 5.791109013621565],
            [26.98166331689427, 5.859444994021246],
            [26.923882164278695, 5.865554060348813],
            [26.852982159690498, 5.891391109321106],
            [26.828327126707194, 5.91444503115541],
            [26.824027210366154, 5.960418093820508],
            [26.812909118687031, 5.977636031733624],
            [26.73555404280728, 6.009164225340058],
            [26.697500202284516, 6.01750002807124],
            [26.668891257924145, 6.007218115055224],
            [26.612218192911513, 6.014444991993344],
            [26.565282217206999, 6.02833598488867],
            [26.442291198808704, 6.077427115543799],
            [26.442773158242687, 6.102218102996915],
            [26.463536138294558, 6.130973060110989],
            [26.500000267056805, 6.156526968393365],
            [26.518745052456495, 6.169444989965456],
            [26.528473256947166, 6.19236413079625],
            [26.525418220869284, 6.216245011200172],
            [26.45485416296134, 6.297782153861846],
            [26.37250012001374, 6.346391157444927],
            [26.30437318427991, 6.390000020493858],
            [26.298054067458281, 6.465553993014069],
            [26.313609203414643, 6.496391182520881],
            [26.366109254100735, 6.583335993850341],
            [26.406282207390376, 6.6352449527228],
            [26.404027140153715, 6.644027007980384],
            [26.360836198798381, 6.683335954688289],
            [26.315900145196963, 6.700135970907795],
            [26.270418094421245, 6.705973128295454],
            [26.119163298436519, 6.82610910282601],
            [26.097636056450909, 6.845691072717216],
            [26.064163261302355, 6.903053968363167],
            [26.052218211053599, 6.93278206443857],
            [26.047773287787351, 6.963891162885005],
            [25.984445159272752, 7.018335982751367],
            [25.850273184921662, 7.109445091228039],
            [25.729718115231094, 7.168054040603522],
            [25.623891222927369, 7.21110902748903],
            [25.455000232059945, 7.297782097516858],
            [25.369863229612349, 7.343891114651768],
            [25.352082195442506, 7.372009047122674],
            [25.346800255322734, 7.401391138234104],
            [25.335000211999386, 7.420554012965397],
            [25.267773157392867, 7.475282141159752],
            [25.221454089763711, 7.494309061421234],
            [25.200273188018286, 7.516391185398589],
            [25.182636154945271, 7.577918042915584],
            [25.194718165492134, 7.595973165320217],
            [25.243891271160521, 7.623336055950958],
            [25.267709119652409, 7.626736091164503],
            [25.293745155006832, 7.645418012290236],
            [25.299300177532814, 7.682917976649776],
            [25.287218166985838, 7.77889110355278],
            [25.255827101315788, 7.845691184010306],
            [25.225554013894396, 7.872773113245827],
            [25.186109112716679, 7.901944986225033],
            [25.139445045951874, 7.897782197820291],
            [25.111736150356961, 7.891244983877499],
            [25.084718091223891, 7.894864122040701],
            [25.053891127639162, 7.921109034422713],
            [25.017773171479149, 7.955282053764449],
            [24.970273261327293, 8.003336007717721],
            [24.943882168553415, 8.041391189345148],
            [24.947500133250117, 8.067082057925745],
            [24.938609113250948, 8.088609132273206],
            [24.87013617255306, 8.160973120074203],
            [24.843891260171063, 8.175553943602765],
            [24.804445185526873, 8.192782107437679],
            [24.721663330240119, 8.209718078127182],
            [24.660000182976972, 8.21860909812635],
            [24.627636145983587, 8.220273073549308],
            [24.479718185662392, 8.238053940081258],
            [24.336936152707153, 8.262782063260502],
            [24.253054088806408, 8.27832697329498],
            [24.230554043135527, 8.28444509207786],
            [24.210000107749408, 8.293053977215337],
            [24.184854062876639, 8.309864219356911],
            [24.162773280003847, 8.330553941574863],
            [24.153327210374869, 8.34916411360922],
            [24.140273234332966, 8.37582711532292],
            [24.163609123390785, 8.473054007306402],
            [24.201945098775354, 8.526109107622304],
            [24.241391173419458, 8.562218011326905],
            [24.268327089901277, 8.587500010669572],
            [24.201109255388218, 8.686945089515689],
            [24.174463352757215, 8.695891094799805],
            [24.141182167915844, 8.686736044849837],
            [24.08693616679335, 8.688327097715032],
            [24.051873156970032, 8.693744992304801],
            [24.015554035133192, 8.703891118488812],
            [23.925827102493855, 8.718054020323947],
            [23.823891257293241, 8.72778205717664],
            [23.781945028562802, 8.72333596044372],
            [23.763745066871138, 8.714582068380935],
            [23.739163291721667, 8.70527312668834],
            [23.557500124397961, 8.704444994652235],
            [23.529654100866765, 8.708336041755246],
            [23.497636068837551, 8.77180012473957],
            [23.508054103961456, 8.80805403536948],
            [23.536527093851817, 8.831600142559637],
            [23.562773179700315, 8.868609095029711],
            [23.58263611098684, 8.912218125716635],
            [23.581663307301483, 8.993745042456425],
            [23.561182294473213, 9.000553997701033],
            [23.532218125055294, 8.961109096523231],
            [23.509863253947799, 8.958326975213552],
            [23.48930026610617, 8.966245023889385],
            [23.457918085253539, 8.990836019132246],
            [23.44832717633733, 9.019582091428902],
            [23.473054126050101, 9.129718120167141],
            [23.478609148576055, 9.145273088485425],
            [23.499645043396157, 9.177218030318897],
            [23.539027080299917, 9.179444934360774],
            [23.564445034112481, 9.187218143749391],
            [23.649300236974454, 9.275973110893702],
            [23.664718077356525, 9.434718143214923],
            [23.64200915465787, 9.443127036142144],
            [23.626036097007898, 9.542845029756464],
            [23.649436191444352, 9.579164151593204],
            [23.660273154090078, 9.597773150161174],
            [23.693891123802103, 9.668054067379117],
            [23.669163335898929, 9.866945063261667],
            [23.53666321595972, 10.083609046687059],
            [23.323891285465749, 10.430000081197392],
            [23.30944507530242, 10.451664115843244],
            [23.060273221424836, 10.664445098792811],
            [23.009445192788888, 10.698609065679051],
            [22.944163242638695, 10.79805397688709],
            [22.90139122880521, 10.865553946261741],
            [22.880000108927561, 10.899718080786158],
            [22.866500215635426, 10.922444940757529],
            [22.893609134599274, 10.976945081185008],
            [22.974091163286374, 11.209791166926038],
            [22.971391184627947, 11.280273082182731],
            [22.933191163713275, 11.408745027082645],
            [22.790273176288224, 11.42944497522258],
            [22.648327154358014, 11.511809076453972],
            [22.625554026281037, 11.534445076594665],
            [22.613327176446745, 11.550553921076471],
            [22.575273168285889, 11.601945045969231],
            [22.559027195867799, 11.629026975204852],
            [22.553191211946512, 11.66597306340087],
            [22.572773181837817, 11.7991641874398],
            [22.597773213956771, 11.926664166844773],
            [22.611382239628483, 11.9927730755645],
            [22.563609247070445, 12.074718081635893],
            [22.503327102178417, 12.16583607493007],
            [22.439027175807041, 12.347218113220563],
            [22.407636110136906, 12.485135960111378],
            [22.464300122694226, 12.614864184662707],
            [22.331663210094689, 12.673336006101891],
            [22.223327111249404, 12.747218124210136],
            [22.199718139785375, 12.715553976133975],
            [22.161945093019739, 12.668745070081755],
            [22.145273152281021, 12.657364121918405],
            [22.119854025002155, 12.648609056389049],
            [22.074445064422292, 12.640000171251572],
            [22.050000249571212, 12.637500017165507],
            [22.00471819100585, 12.636391091372261],
            [21.977218172438796, 12.638609110596732],
            [21.952773189949568, 12.643891050716505],
            [21.925554132777648, 12.656109015733463],
            [21.906945134209792, 12.66944495899898],
            [21.88847326357822, 12.693053930463009],
            [21.829100219907161, 12.797200083537234],
            [21.891945047883127, 12.95444509046213],
            [21.942082240057431, 13.051245008296775],
            [22.025000217452259, 13.140273225485274],
            [22.067500154707858, 13.14860902821664],
            [22.15305407302057, 13.186527081907684],
            [22.269445180839995, 13.32083601655718],
            [22.285000149158378, 13.340553940918198],
            [22.294654089986722, 13.372709100883995],
            [22.227500125576029, 13.495553938890396],
            [22.136109217513734, 13.665000147025538],
            [22.084445177852672, 13.779164183165094],
            [22.198327079130536, 13.92250009228367],
            [22.234300196003318, 13.96555407334067],
            [22.317082218928135, 14.010691124980823],
            [22.370827149877272, 14.028891086672601],
            [22.434445124604423, 14.051664214749692],
            [22.556182210284049, 14.129791096275923],
            [22.566800237618224, 14.167218138077587],
            [22.553745088109935, 14.229027130456373],
            [22.511663240185754, 14.240273129978206],
            [22.449445043292883, 14.330000062617543],
            [22.449163243707346, 14.379164115830449],
            [22.44610921345776, 14.473745008612227],
            [22.438191164781927, 14.492773102340081],
            [22.384718142772613, 14.525100091321363],
            [22.386527125120978, 14.562636097864512],
            [22.398609135667925, 14.586245069328626],
            [22.416109208442663, 14.600273190160337],
            [22.470000152145587, 14.629445063139357],
            [22.500000157160713, 14.637436034373067],
            [22.699927156278733, 14.704100160360767],
            [22.679863226953557, 14.758053968337592],
            [22.669582152127759, 14.814026976794977],
            [22.669163224605938, 14.85374496037899],
            [22.753327088092107, 14.976945023442923],
            [22.78610921441711, 15.015000037432188],
            [22.845691135115914, 15.074864092992712],
            [22.872773231989527, 15.09694504350368],
            [22.892773291212336, 15.104718085254106],
            [22.929373206806218, 15.1140270269468],
            [22.97874513121846, 15.207918089095344],
            [22.984718075438053, 15.231109138865847],
            [22.991391243850643, 15.283336107145644],
            [22.997218175316419, 15.335835990193758],
            [22.997154137576075, 15.371318095177159],
            [22.982709100879333, 15.414235954126283],
            [22.935418235393229, 15.464718145436152],
            [22.923054089984674, 15.487218023469026],
            [22.919582138041562, 15.515691180997479],
            [22.923336057208189, 15.540135995848559],
            [22.937218165286197, 15.561945037419818],
            [23.108609142601608, 15.706391045798014],
            [23.118154118688381, 15.710291145356436],
            [23.143609120513077, 15.711109051470643],
            [23.170273295693164, 15.710554001840748],
            [23.244445092375855, 15.701109105678242],
            [23.305554028199509, 15.686945030376606],
            [23.329718216931525, 15.683609032903419],
            [23.356945153092369, 15.681664096085157],
            [23.381382256592502, 15.685000093558344],
            [23.409027114446843, 15.690836077479631],
            [23.433891192095899, 15.700836023271947],
            [23.460136104478096, 15.716809080921834],
            [23.47916319237757, 15.725827002935588],
            [23.531663243063662, 15.741664106115579],
            [23.564163234526859, 15.749718109261224],
            [23.603054091903147, 15.757500035829153],
            [23.663327184339664, 15.757500035829153],
            [23.800418240298939, 15.747918011730277],
            [23.865000133894, 15.730554061063529],
            [23.951109101836522, 15.705554028944491],
            [23.973191225813878, 15.697918115130349],
            [23.997563285745173, 15.702844997830752],
            [24.00000024028131, 15.832773214592422],
            [24.00000024028131, 16.132773097106067],
            [23.999163223427871, 16.266109060432129],
            [23.999163223427871, 18.299444950230551],
            [23.999163223427871, 18.532500080637519],
            [23.999809132888373, 18.754300159067057],
            [23.999163223427871, 18.832773213195438],
            [23.999445190651386, 19.43278219831619],
            [23.999718105419504, 19.465827013487413],
            [24.002745145940878, 19.499064109517931],
            [24.000973211604531, 19.514164108130771],
            [24.000273155049427, 19.906391077369804],
            [24.005827171747086, 19.998817988667568],
            [24.043327136106427, 20.002218023881014],
            [24.154445188623498, 20.000554048458056],
            [24.455827079336473, 19.99694496857866],
            [24.577218160052155, 19.999164161269604],
            [24.634445101228209, 20.000000172294634],
            [24.763609223694232, 20.000273087062837],
            [24.982500111161585, 19.999444955026846],
            [25.000518185553915, 19.999113534574334],
            [25.001127214640263, 21.010300133876669],
            [25.001418234319459, 21.999691102652818],
            [25.09916329555881, 22.000827018174263],
            [25.216391252593667, 22.00110915303587],
            [25.407218187046482, 21.999718092381116],
            [25.778609253675882, 22.002500046052816],
            [26.339718161327028, 21.999718092381116],
            [26.64860923147782, 21.996391147363326],
            [26.674718189390006, 21.996109012501719],
            [26.838054157731392, 21.9952820539322],
            [26.914445147105113, 21.996664229769621],
            [26.971663203463748, 21.998609166587798],
            [27.093054116541367, 22.000273142010926],
            [27.138054040244953, 22.000553935768167],
            [27.267773212340927, 21.998053949319896],
            [27.327218172741453, 21.996109012501719],
            [27.386663300780043, 21.994164243321549],
            [27.412218214890657, 21.994445037078677],
            [27.724163315291236, 21.999445009974849],
            [28.222773232292212, 22.003327004622534],
            [28.261109207676583, 22.003609139484141],
            [28.319163275060333, 22.002773128459111],
            [28.596663279541161, 21.999445009974849],
            [29.013336069459825, 21.995827045278119],
            [29.070827208224586, 21.995553962871824],
            [29.092782262549548, 21.995553962871824],
            [29.11360911270387, 21.995553962871824],
            [29.135273314987927, 21.995553962871824],
            [29.15639118482116, 21.995553962871824],
            [29.17805404600054, 21.995553962871824],
            [29.25000011210804, 21.995553962871824],
            [29.527218149365325, 21.997500073156672],
            [29.611945109108404, 21.998053949319896],
            [30.101109124118324, 22.000553935768167],
            [30.271663252218644, 22.000827018174263],
            [30.377500202806033, 22.000827018174263],
            [30.463054121118631, 22.000827018174263],
            [30.499163360099402, 22.000827018174263],
            [30.598891244359635, 22.000273142010926],
            [30.691109110991448, 21.999445009974849],
            [30.748336052167502, 21.999164216217693],
            [31.165273207312936, 21.998336084181702],
            [31.207500229800445, 21.998336084181702],
            [31.274300142619978, 21.998744953419717],
            [31.320000122878781, 22.080000128857861],
            [31.335000209205333, 22.106109086770076],
            [31.395418140929365, 22.211382102910349],
            [31.421663220949455, 22.227218032623952],
            [31.448327228491365, 22.232218005520096],
            [31.479027122423616, 22.221391101158176],
            [31.50819128405189, 22.189718068264497],
            [31.513818223307311, 22.159854017719297],
            [31.46610910085181, 22.030827023189474],
            [31.460273284568501, 21.998191077256294],
            [31.495300252208295, 21.998336084181702],
            [31.579163205369554, 21.999445009974849],
            [31.99666328913375, 22.002773128459111],
            [32.339718158533032, 22.004164189113865],
            [32.388054079709917, 22.004164189113865],
            [32.444445177498864, 22.004164189113865],
            [32.473054121859121, 22.003609139484141],
            [32.493891197935426, 22.003054089854245],
            [32.521663293080479, 22.002773128459111],
            [32.648045126598646, 22.001944996422921],
            [33.166936064217538, 21.998882081355916],
            [33.179591229305373, 21.999427072702005],
            [33.35805418598764, 22.000273142010926],
            [33.461800187003092, 22.000273142010926],
            [33.739718113177531, 21.999718092381116],
            [33.760273222030122, 21.999718092381116],
            [33.78139125950176, 21.999718092381116],
            [33.865000240996238, 21.999445009974849],
            [33.954991203586275, 21.998882081355916],
            [34.074436174016995, 21.996945023526749],
            [34.099282314393207, 21.996444959181801],
            [34.116663364142568, 21.996109012501719],
            [34.346245201501944, 21.995553962871824],
            [34.66389116954403, 21.996109012501719],
            [34.704163364567449, 21.997218105932959],
            [34.892491150762595, 22.000553935768167],
            [34.940282248231739, 22.00110915303587],
            [34.967636086406884, 22.00110915303587],
            [34.988609116953199, 22.00110915303587],
            [35.153609228355521, 22.000553935768167],
            [35.354436108600936, 21.999164216217693],
            [35.445000225731832, 21.997773155562854],
            [35.781109179588015, 21.996945023526749],
            [35.909436117562592, 21.998336084181702],
            [36.024991214356959, 21.998882081355916],
            [36.256382201702735, 21.999718092381116],
            [36.297773213165073, 21.999164216217693],
            [36.408609130820395, 21.998882081355916],
            [36.545827088794255, 21.998609166587798],
            [36.688327154525695, 21.998336084181702],
            [36.728054190565075, 21.999445009974849],
            [36.821936200258136, 22.00110915303587],
            [36.882500144735758, 22.00110915303587],
            [36.888463506379225, 22.000109070138492],
            [36.889992117000048, 21.998615880000031],
            [36.929331711000117, 21.982684580000111],
            [36.943754962000327, 21.974730937000245],
            [36.992889561000112, 21.971619657000119],
            [37.046776452000159, 21.967743072],
            [37.188284824000078, 21.954397618000158],
            [37.908917864000188, 21.898682705000098],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SEN", Country: "Senegal" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-12.244836811774178, 14.764382137614632],
            [-12.244163912585464, 14.750554008993447],
            [-12.178608880029117, 14.607500067098442],
            [-12.216945861241982, 14.539718130500034],
            [-12.22569992094293, 14.505282087035994],
            [-12.206399918275338, 14.395291065223148],
            [-12.150836784884177, 14.374164142934205],
            [-12.030345920572131, 14.277500179569472],
            [-11.982226755412057, 14.172782045421059],
            [-11.979999851370223, 14.151391093181473],
            [-12.004446007325669, 14.061808999829509],
            [-12.014026857958186, 14.018473219186774],
            [-12.007917791630689, 13.96624507744059],
            [-11.986663967327445, 13.943745031769481],
            [-11.958754911884228, 13.92277317468978],
            [-11.943336736226058, 13.906809001857368],
            [-11.941663875985569, 13.88360906726939],
            [-11.94249988701074, 13.858891170011987],
            [-11.947781827130484, 13.830273173196133],
            [-11.955699875806317, 13.80097322473614],
            [-11.96778188635335, 13.78166416961318],
            [-11.989445920999231, 13.761245015230372],
            [-12.009999856385264, 13.746664191701882],
            [-12.041390922055314, 13.728609069297249],
            [-12.064446017356204, 13.695554028204185],
            [-12.057217799313605, 13.66471801216403],
            [-12.034446012340993, 13.610835953278411],
            [-11.990836814016006, 13.556391133412205],
            [-11.965490776932882, 13.528127020549618],
            [-11.878199793001272, 13.420418055701703],
            [-11.887290805210796, 13.381600120883149],
            [-11.809517807637519, 13.309273181094397],
            [-11.758608809816735, 13.364718129650271],
            [-11.740972950210164, 13.409027049254234],
            [-11.716254885314811, 13.412500174663762],
            [-11.634581955821147, 13.391526976479554],
            [-11.608054908577458, 13.358327096099174],
            [-11.552226739407274, 13.28277312357892],
            [-11.539372923213705, 13.254718055381943],
            [-11.556181824250643, 13.21242699515409],
            [-11.528890850349342, 13.140136097549146],
            [-11.51513681775225, 13.112082202818669],
            [-11.463336823621376, 13.085836116970029],
            [-11.376108871601787, 12.98291808035259],
            [-11.369926715078464, 12.932218127197359],
            [-11.3949998373933, 12.831945083953372],
            [-11.424163831383453, 12.656109015733477],
            [-11.416946006900844, 12.569444998161032],
            [-11.408890830288669, 12.535000069879459],
            [-11.373608885153715, 12.447773123688307],
            [-11.3730548413522, 12.407773172880695],
            [-11.427226746450458, 12.426382171448552],
            [-11.476526754133147, 12.435827067611172],
            [-11.519726747943992, 12.434445059411928],
            [-11.645554872936884, 12.421527037839837],
            [-11.675554877951981, 12.400554007293579],
            [-11.69360882689017, 12.390554061501291],
            [-11.736390899007517, 12.380553948070997],
            [-11.757499884023503, 12.379718104683946],
            [-11.841672967603245, 12.386391105458472],
            [-11.895281944082569, 12.409164233535648],
            [-12.033890795073034, 12.400554007293579],
            [-12.128054939627816, 12.382773140761742],
            [-12.149581846337242, 12.36486419874899],
            [-12.170281794477148, 12.353891113995246],
            [-12.196108785165734, 12.345553970159528],
            [-12.345399868869038, 12.301744947262364],
            [-12.373608828808813, 12.334164137178732],
            [-12.394654949550727, 12.367353959275079],
            [-12.445836694501736, 12.387218064028161],
            [-12.479172864628026, 12.395136112704108],
            [-12.50249986886854, 12.395136112704108],
            [-12.582781905345257, 12.363609092563991],
            [-12.587781878241458, 12.371945062933435],
            [-12.634026849846407, 12.436382117241067],
            [-12.786945956530303, 12.46500011405692],
            [-12.845836705491308, 12.512218056984977],
            [-12.896254859060832, 12.545000015671803],
            [-12.927017784905104, 12.542218062000302],
            [-12.946390877768636, 12.523191141738721],
            [-12.961599841122961, 12.473682089390081],
            [-13.045699834506877, 12.479027061421959],
            [-13.063890911381236, 12.502918000109617],
            [-13.06875492980754, 12.53208216173789],
            [-13.055554941011962, 12.555827087672014],
            [-13.042426868945711, 12.590000107013566],
            [-13.056317861841023, 12.634100149589869],
            [-13.206390809474726, 12.652218136268459],
            [-13.310226832130638, 12.649727034637692],
            [-13.356663916956961, 12.665273118138714],
            [-13.627781940342118, 12.677500135611183],
            [-13.713136704634792, 12.677218000749406],
            [-13.857499899809341, 12.676391042179716],
            [-13.998608904886112, 12.67610907495623],
            [-14.136946014575017, 12.677364181141115],
            [-14.341754969392014, 12.678891028627959],
            [-14.393054899178026, 12.678891028627959],
            [-14.516945966341837, 12.679717987197478],
            [-14.649308790706527, 12.680973093382534],
            [-14.874445872437718, 12.681945058877432],
            [-14.923336843244442, 12.682782075730984],
            [-15.218054953276294, 12.684718127731628],
            [-15.334445893457541, 12.615835982519741],
            [-15.361390862394956, 12.598053942521403],
            [-15.419399835139302, 12.551409154133864],
            [-15.614726845781831, 12.462773210014973],
            [-15.685281851234322, 12.430000136145452],
            [-15.809446000804144, 12.440553958101191],
            [-15.888472931095976, 12.44810907036856],
            [-15.962226806085255, 12.436109034834772],
            [-16.000554902480815, 12.451664170791332],
            [-16.019172953504153, 12.460554017324],
            [-16.039999803658588, 12.467218133281222],
            [-16.072363840651974, 12.472218106177365],
            [-16.102217832913311, 12.471391147607847],
            [-16.209163876932223, 12.4608359845476],
            [-16.381672832496349, 12.374718131787802],
            [-16.518608823246439, 12.347636034914103],
            [-16.548608828261649, 12.357218059013064],
            [-16.66333679884815, 12.360836023709624],
            [-16.692781921871699, 12.355826998358111],
            [-16.710281827008259, 12.336664123626633],
            [-16.71777276972864, 12.322427376363237],
            [-16.720089190500175, 12.324558334791277],
            [-16.718055555999911, 12.338888889000202],
            [-16.72887626209473, 12.332641550932181],
            [-19.653161798999975, 10.644304509000165],
            [-19.711919622999858, 10.725135964000117],
            [-19.96765720749994, 11.248452662500142],
            [-19.971725510999931, 11.256769578000103],
            [-19.973954728999843, 11.264568887000166],
            [-19.975841184999865, 11.268418460000078],
            [-19.9767607994998, 11.27145884450016],
            [-19.980446278999864, 11.28417400100011],
            [-19.982047823999892, 11.287441640000054],
            [-20.142270975999907, 11.846203382000169],
            [-20.142393311999854, 11.847400452000102],
            [-20.145402094999895, 11.857861570000239],
            [-20.146115469999813, 11.864839967000023],
            [-20.15173516099992, 11.884374608000144],
            [-20.211060695999834, 12.461866623999981],
            [-20.211049470999853, 12.46254142100014],
            [-20.210915980999857, 12.464820823000082],
            [-20.211126837999871, 12.466864158000135],
            [-20.211115614999812, 12.46753895400019],
            [-20.197371508999879, 12.648492338000153],
            [-20.205605732999857, 12.728343553000173],
            [-20.205454307999901, 12.73064784200011],
            [-20.205670963999978, 12.73274136100008],
            [-20.205661100499867, 12.733341663500028],
            [-20.205616954999869, 12.734392545000048],
            [-20.20570462399985, 12.735239649],
            [-20.204705501999968, 12.748371802000236],
            [-20.204899403999832, 12.750245128000131],
            [-20.204769815999839, 12.751948463000133],
            [-20.204945241999837, 12.753643220000129],
            [-20.20492676699979, 12.754768682000133],
            [-20.181979052999907, 13.0575],
            [-20.121698394999839, 13.0575],
            [-16.757104433999814, 13.0575],
            [-16.756110979999846, 13.057499970000038],
            [-16.755711879999865, 13.056901140000207],
            [-16.754999999999882, 13.055833000000121],
            [-16.750891670820977, 13.060018367553255],
            [-16.700345918956316, 13.157218080992209],
            [-16.618336707506387, 13.165000175198202],
            [-15.998890927057801, 13.163054064913354],
            [-15.89556385356417, 13.160554078465282],
            [-15.862499927653687, 13.159718067440281],
            [-15.809717742105988, 13.159718067440281],
            [-15.809446000804144, 13.245282044036685],
            [-15.80652675155801, 13.339445015125023],
            [-15.418054874951963, 13.375273125072511],
            [-15.360281936601609, 13.366391157528653],
            [-15.334163926234112, 13.365836107898929],
            [-15.290417767610734, 13.372773138624439],
            [-15.264445937634889, 13.383054045812059],
            [-15.247217773799889, 13.393891176095849],
            [-15.224308858890993, 13.413191178763384],
            [-15.210836793517501, 13.434164209309586],
            [-15.204726721361737, 13.461945021633767],
            [-15.204172845198286, 13.48777318578891],
            [-15.201526845996568, 13.525136022212052],
            [-15.175054951675918, 13.552800158443631],
            [-15.147917869517357, 13.586244958035479],
            [-15.111663958887448, 13.595836034589922],
            [-15.082081875565677, 13.561391106308349],
            [-15.045281800123291, 13.528053930353678],
            [-14.989726880997353, 13.49305395244221],
            [-14.972499890629024, 13.482500130486471],
            [-14.954726735447935, 13.471944967426282],
            [-14.925281947700682, 13.460553960979041],
            [-14.83333682819881, 13.435273135102932],
            [-14.770417736560432, 13.424445057274397],
            [-14.755281863402047, 13.404444998051588],
            [-14.735417758649021, 13.375553918829667],
            [-14.714726862964596, 13.360554000141178],
            [-14.686808755065954, 13.350482137619224],
            [-14.647781943219854, 13.343891111857772],
            [-14.547781814743814, 13.335936015169921],
            [-14.521108754746422, 13.313891106842846],
            [-14.388754982837099, 13.2487449435244],
            [-14.351108838085906, 13.23778208469264],
            [-14.214726723499098, 13.230554034288048],
            [-14.188336804191636, 13.23305402073612],
            [-14.151672850857381, 13.246391137468208],
            [-14.129999763755961, 13.260554039303315],
            [-14.095555003112452, 13.285000027620697],
            [-14.048890936347675, 13.294445091421267],
            [-13.992781805782272, 13.302500100395434],
            [-13.873054868127781, 13.320836016557166],
            [-13.858336749024772, 13.327773214920683],
            [-13.849999772827118, 13.333053981574153],
            [-13.824026769384716, 13.356109076874759],
            [-13.803472833998569, 13.384444938828835],
            [-13.798608815572265, 13.413054050827157],
            [-13.803754968860176, 13.443191183778652],
            [-13.827499894794244, 13.480554020201794],
            [-13.845554849560699, 13.497082127481633],
            [-13.86849980665346, 13.508036101496032],
            [-13.88701777775259, 13.54437316060563],
            [-13.919163885262833, 13.568191176735638],
            [-13.980417828011696, 13.582773173730629],
            [-14.082363899134293, 13.560553921816847],
            [-14.176108780890956, 13.526109161173508],
            [-14.198336749984037, 13.516245002212884],
            [-14.222917854581169, 13.498473188136359],
            [-14.237081929882663, 13.481526991525072],
            [-14.334863871496054, 13.454026972958047],
            [-14.369863849407409, 13.466809040060184],
            [-14.398336839297883, 13.48332708905599],
            [-14.429581892214287, 13.504718041295746],
            [-14.446390793251169, 13.526391128397108],
            [-14.472781886025132, 13.534444963904605],
            [-14.477781858921219, 13.570836002470799],
            [-14.483336713809081, 13.593745085017872],
            [-14.50458182093314, 13.628054058829491],
            [-14.535699804196952, 13.650691064798764],
            [-14.562363979377039, 13.662082071245834],
            [-14.598890804775181, 13.670273202328076],
            [-14.62569981924247, 13.667918055167206],
            [-14.646108747703295, 13.663054036740959],
            [-14.668336716796375, 13.65332700571679],
            [-14.697081950902657, 13.632364201092514],
            [-14.716526792857564, 13.61333610736466],
            [-14.791945984374536, 13.65332700571679],
            [-14.793608786331163, 13.678609005059172],
            [-14.802081884636777, 13.709309066629771],
            [-14.816108832002044, 13.741109001537666],
            [-14.828890899104067, 13.756664137494226],
            [-14.856336770576547, 13.778609133535383],
            [-14.860281797136054, 13.78083603757716],
            [-14.88305492521323, 13.793054002593919],
            [-14.911663869573488, 13.802364117753086],
            [-15.070281832242301, 13.826391178548647],
            [-15.164445976796969, 13.795000112878597],
            [-15.216663892621455, 13.763891182070324],
            [-15.251390788126514, 13.744718081417034],
            [-15.271526801819221, 13.770553956923038],
            [-15.300490803598962, 13.79041806167595],
            [-15.336672965137495, 13.792918048124022],
            [-15.364445898472752, 13.781391087206885],
            [-15.420281946631974, 13.743327020762308],
            [-15.4355549477267, 13.730273212358327],
            [-15.45555500694951, 13.70832704285084],
            [-15.474308844804796, 13.669026980960552],
            [-15.484081808658516, 13.640791031292565],
            [-15.488199837700108, 13.590836061693778],
            [-15.574717842518737, 13.590553926832172],
            [-15.645836782418542, 13.591109144099903],
            [-15.929726814622029, 13.592782171978399],
            [-16.08859992242418, 13.592218069893022],
            [-16.170836786365044, 13.592500037116622],
            [-16.565672874215863, 13.590000050834306],
            [-16.572779028999832, 13.592279689],
            [-16.58151132099988, 13.59240050000011],
            [-16.648912873999848, 13.593333],
            [-19.957158996999908, 13.593333],
            [-20.073611110999934, 13.593333],
            [-20.073611110999934, 13.650000000000148],
            [-20.073611110999934, 14.850000000000193],
            [-20.036852875999955, 14.883290477000173],
            [-20.0, 14.916666667000186],
            [-19.86148278599984, 15.161108808000108],
            [-19.858333332999848, 15.166666667000129],
            [-19.747222221999834, 15.416666667000072],
            [-19.641666666999924, 15.666666667000243],
            [-19.594444443999919, 15.916666667000015],
            [-19.558332162428485, 16.066666665571461],
            [-16.527793761364705, 16.062931289599703],
            [-16.527681898182948, 16.060244991640502],
            [-16.526602574713309, 16.062010511824951],
            [-16.50694590785929, 16.094164206953451],
            [-16.390036798422329, 16.220618124839007],
            [-16.367499872377266, 16.27374497424637],
            [-16.357499926584921, 16.322773072989492],
            [-16.345146007098151, 16.381109107596728],
            [-16.321808776935768, 16.456244990785478],
            [-16.30236393498086, 16.499027062902769],
            [-16.28528195153774, 16.517082185307402],
            [-16.145836754246034, 16.55193598282699],
            [-16.115417821708888, 16.54937313210479],
            [-16.099236725221715, 16.526664209406192],
            [-16.05659982766781, 16.48374500935256],
            [-15.985554983792213, 16.490000088433874],
            [-15.915699867256876, 16.509718012794906],
            [-15.888336808988015, 16.512364179634687],
            [-15.823054858837793, 16.501664177287239],
            [-15.74833672970442, 16.488891162640584],
            [-15.696317800262023, 16.480136097111313],
            [-15.67028193254572, 16.481109068434705],
            [-15.576390870397177, 16.513335977491636],
            [-15.509999826815772, 16.556664214421517],
            [-15.441390931648016, 16.579436001394015],
            [-15.354999828843745, 16.56166418731749],
            [-15.235972947744472, 16.559300155339315],
            [-15.088336787008757, 16.608053998209698],
            [-15.021663943841816, 16.638745007324644],
            [-15.003954994039361, 16.670064156265255],
            [-14.984517863435542, 16.690618091651459],
            [-14.96152680587511, 16.684718069989799],
            [-14.958090896115891, 16.672554084429521],
            [-14.951663988019106, 16.648400121619375],
            [-14.906945863900944, 16.635273223019794],
            [-14.854717889792653, 16.637217992199794],
            [-14.756390789195166, 16.644445036775949],
            [-14.639508837124481, 16.635344972111113],
            [-14.504999910264644, 16.626391087837931],
            [-14.453608785371785, 16.635136095083226],
            [-14.416108821012301, 16.650273141708112],
            [-14.34360887874152, 16.636109066406675],
            [-14.219163935414542, 16.543191143219701],
            [-14.029726720511917, 16.357773218538966],
            [-13.984236790747161, 16.299864158080425],
            [-13.969717825664134, 16.233891036192631],
            [-13.816108888347003, 16.135273083554239],
            [-13.703508747706394, 16.17874498630465],
            [-13.698890821958003, 16.166944942981218],
            [-13.710345866145701, 16.136245049048966],
            [-13.668190928025695, 16.098953961717314],
            [-13.639717770497214, 16.115835952950135],
            [-13.579863940858786, 16.135554044949345],
            [-13.5205549349281, 16.132218047476215],
            [-13.395417814034829, 16.055418021226217],
            [-13.324717801656959, 15.929445056946008],
            [-13.245836716480881, 15.680553996825722],
            [-13.184717890011484, 15.629445006794299],
            [-13.102690908926888, 15.521391042810762],
            [-13.09166384471655, 15.496664093098104],
            [-13.056599829064623, 15.479927108790662],
            [-12.963608815681653, 15.50038213771893],
            [-12.940136804515873, 15.392635957220875],
            [-12.929926808229311, 15.365482111255858],
            [-12.907217885530713, 15.351664208556485],
            [-12.879445958023894, 15.337217998393101],
            [-12.860836791817917, 15.324444983746389],
            [-12.841736781360623, 15.296453953289927],
            [-12.85007291936796, 15.265836034371091],
            [-12.885763901379107, 15.261700068056598],
            [-12.849726746765839, 15.208054043565141],
            [-12.78344600903057, 15.149653971217333],
            [-12.708054980708368, 15.097773175539658],
            [-12.68598190918641, 15.08790901657909],
            [-12.662781806960481, 15.104718085254035],
            [-12.629308844173863, 15.099864125111765],
            [-12.478754942934586, 15.007426987892075],
            [-12.451872838271242, 14.941182124702493],
            [-12.448054881364214, 14.9056270971613],
            [-12.395836797901779, 14.853053956279325],
            [-12.28360881376318, 14.785827069310983],
            [-12.244836811774178, 14.764382137614632],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SGP", Country: "Singapore" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [104.061780318000075, 1.272027508000122],
            [104.033388889000122, 1.2695],
            [103.880583333000146, 1.207250000000116],
            [103.859861111000129, 1.195972222000108],
            [103.805055556000156, 1.171444444000073],
            [103.74063888900028, 1.130361111000155],
            [103.67055555600021, 1.179444444000126],
            [103.651905967000147, 1.195610256],
            [103.628749224000188, 1.207188628000196],
            [103.612539504000011, 1.211819976000058],
            [103.602969469000158, 1.233021608000044],
            [103.599506375000061, 1.250337076000108],
            [103.596043282000124, 1.269384091000077],
            [103.596043282000124, 1.288431106000047],
            [103.606432563000084, 1.319598949000067],
            [103.61855339000013, 1.340377511000042],
            [103.630674218000166, 1.357692979],
            [103.635582811000205, 1.366563272000036],
            [103.641530835000054, 1.376476645000039],
            [103.651444208000129, 1.389694475000169],
            [103.660696689000162, 1.404234089000056],
            [103.671931845000103, 1.418112811000057],
            [103.687793242000026, 1.429347967000183],
            [103.701011072000171, 1.437278665],
            [103.725464059000132, 1.443226689000099],
            [103.758508635000084, 1.449174713],
            [103.786266079000171, 1.455122737000011],
            [103.806092825000206, 1.458427194000038],
            [103.834511161000165, 1.457766302999985],
            [103.851694340000023, 1.451818279000065],
            [103.866894846000065, 1.442565798000032],
            [103.879451785000043, 1.433313316000095],
            [103.893991398000111, 1.425382618000086],
            [103.907209229000131, 1.418773703000028],
            [103.93298399800014, 1.412825679000022],
            [103.957436985000214, 1.406877655000116],
            [103.988498887000077, 1.400268740000143],
            [104.022003799000089, 1.39405546200004],
            [104.039319267000081, 1.387129275000021],
            [104.046245454000172, 1.373276900000064],
            [104.049708548000098, 1.361156072000114],
            [104.051440095000117, 1.342109057000044],
            [104.056634735000131, 1.324793589],
            [104.060097829000057, 1.307478121000116],
            [104.058366282000208, 1.297088840000171],
            [104.061780318000075, 1.272027508000122],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ISO_A3: "SGS",
        Country: "South Georgia & the South Sandwich Is.",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-40.116307536999983, -50.480932695000035],
            [-39.400962536999941, -50.737306694999972],
            [-38.926446535999958, -50.678506695000017],
            [-38.468454536000024, -50.671259695],
            [-38.405620535999986, -50.663032695],
            [-38.237925535999949, -50.659804695],
            [-38.172163536000028, -50.651047694999917],
            [-37.258427534999981, -50.628394694999926],
            [-36.350226533999972, -50.699447694999932],
            [-35.809939533000033, -50.800450695],
            [-35.80786353299996, -50.800593695000018],
            [-34.923451532999962, -50.959283695999972],
            [-34.918180532999827, -50.96086969600001],
            [-34.874930531999865, -50.968417695999968],
            [-34.873814531999898, -50.968752695999974],
            [-34.701327531999965, -50.998662696000011],
            [-34.699399531999859, -50.99923769599998],
            [-34.676297532000035, -51.003219696000023],
            [-33.838269531999941, -51.24825869599993],
            [-33.56191553099984, -51.367142696000037],
            [-33.509938530999989, -51.381850696],
            [-32.736867530000012, -51.706845695999981],
            [-32.69865653, -51.729493696],
            [-32.680985529999816, -51.736792695999966],
            [-32.047884529999976, -52.106521696999955],
            [-31.295579529, -52.403877697000048],
            [-30.588406527999837, -52.794079697000043],
            [-29.990613528000011, -53.249612698],
            [-29.979004527999848, -53.262384698000012],
            [-29.079548526999986, -53.077030696999927],
            [-28.127816525999833, -52.978812696999988],
            [-27.163532524999965, -52.973452696999971],
            [-27.160850524999915, -52.973709697],
            [-27.121584524999889, -52.97331969699998],
            [-26.167515523999839, -53.06055169699998],
            [-25.24643552399985, -53.238590697999932],
            [-24.380904522999941, -53.503093698000029],
            [-23.593101521999984, -53.847550697999949],
            [-22.904597520999857, -54.263349698999988],
            [-22.336037520999895, -54.739859699],
            [-22.311322520999909, -54.770514698999953],
            [-21.951324519999844, -54.976329699000026],
            [-21.360001519999855, -55.447838699999977],
            [-20.908494518999845, -55.9686277],
            [-20.614758518999963, -56.524631700999983],
            [-20.58509151899986, -56.668288701],
            [-20.544224518999897, -56.713099700999969],
            [-20.237766518999877, -57.268051700999948],
            [-20.184412518999864, -57.50592070199999],
            [-20.00426951899982, -57.819913701999958],
            [-19.866823518999894, -58.394739702000031],
            [-19.868088518999855, -58.408491701999942],
            [-19.847134518000018, -58.494058702999943],
            [-19.900239518999854, -59.072665703000041],
            [-20.141624518999947, -59.612704703999967],
            [-20.147933518999878, -59.676406703999973],
            [-20.408077518999903, -60.240783703999959],
            [-20.873356518999969, -60.77272470499998],
            [-21.182564519999886, -60.99881570500002],
            [-21.327171519999894, -61.157642704999937],
            [-21.366532519999851, -61.185902704999961],
            [-21.379402519999985, -61.199928704999984],
            [-22.059954520999838, -61.679089705999971],
            [-22.926801521000016, -62.089785706000022],
            [-23.954540521999888, -62.416557705999956],
            [-25.107967522999928, -62.646590706000019],
            [-26.343425524999844, -62.770557706999966],
            [-27.611342525999873, -62.783295707],
            [-27.628903525999817, -62.781979707],
            [-27.788891525999873, -62.782790706999961],
            [-29.036572526999862, -62.682194706],
            [-30.213419528000031, -62.473883705999988],
            [-31.273996528999902, -62.16625670599997],
            [-32.180802529999966, -61.77140970600005],
            [-32.905932530999877, -61.304328704999925],
            [-33.431556530999927, -60.781988704999989],
            [-33.749412530999962, -60.222474704000028],
            [-33.859635531999942, -59.64419070400001],
            [-33.857103531999968, -59.628230704000018],
            [-33.862610532, -59.598805703999972],
            [-33.772316530999944, -59.019821702999941],
            [-33.494121531000019, -58.457325702999988],
            [-33.442632530999845, -58.397497701999967],
            [-33.576938530999939, -58.250932701999957],
            [-33.778112530999977, -58.28039470199996],
            [-34.878944532, -58.331106701999957],
            [-35.977975533999938, -58.271289701999962],
            [-36.134269533999884, -58.247024702],
            [-36.276796533999914, -58.251529701999971],
            [-37.37068553499995, -58.179944702],
            [-38.415469535999989, -58.000746701999986],
            [-39.371747537000033, -57.720947701999947],
            [-40.205714537, -57.351259700999982],
            [-40.238632536999972, -57.330157700999976],
            [-40.385448537999963, -57.284411701],
            [-40.409717538, -57.273384700999955],
            [-40.437445537999963, -57.264703701000045],
            [-41.250808537999916, -56.888273701000017],
            [-41.294914537999915, -56.858757700999988],
            [-41.299410537999961, -56.857292701],
            [-41.825307538999851, -56.902574700999963],
            [-42.88872354, -56.885365701000012],
            [-43.925298540999989, -56.759035700999952],
            [-44.895582542, -56.52855270099996],
            [-45.764348542999983, -56.2027927],
            [-45.798438543, -56.184187699999924],
            [-46.021621543000037, -56.0985227],
            [-46.75518454399986, -55.688207700000014],
            [-47.336223543999921, -55.209979698999959],
            [-47.750511544, -54.680511699],
            [-47.991061545, -54.117514697999979],
            [-48.057409545000013, -53.539028698000038],
            [-47.954657545, -52.96280969699999],
            [-47.692452544, -52.405825697000019],
            [-47.284011543999981, -51.883859695999945],
            [-46.745275543999952, -51.411192695999929],
            [-46.094199542999831, -51.000370695999933],
            [-45.350198541999958, -50.66202569499999],
            [-44.533716540999933, -50.404736694999933],
            [-43.665900540999957, -50.23493969499998],
            [-42.768355540000016, -50.156851695],
            [-41.862948538999888, -50.172424694999954],
            [-40.971637537999982, -50.281325694999978],
            [-40.116307536999983, -50.480932695000035],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ISO_A3: "SHN",
        Country: "Saint Helena, Ascension en Tristan da Cunha",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-12.226515510999945, -33.743045678999977],
              [-11.531685510999978, -33.797337679999941],
              [-10.85661150999988, -33.947197679999874],
              [-10.219631509999886, -34.188582679999968],
              [-10.21889751, -34.188998679999941],
              [-10.217547009999919, -34.189533679999954],
              [-10.213774509999951, -34.190976679999892],
              [-9.632509508999931, -34.517294679999907],
              [-9.628529508999975, -34.520466680000027],
              [-9.61965550899987, -34.525403679999954],
              [-9.110656507999977, -34.927679680999915],
              [-9.110149507999949, -34.928245680999851],
              [-9.100626507999891, -34.935710680999932],
              [-9.099331507999977, -34.937156680999976],
              [-9.096593507999899, -34.939302680999958],
              [-8.675056507999898, -35.406383680999909],
              [-8.674057507999862, -35.407981180999911],
              [-8.672992507999936, -35.40965468099995],
              [-8.67193000799989, -35.411324180999941],
              [-8.66994200799985, -35.414450180999893],
              [-8.66786950799991, -35.417635680999922],
              [-8.665733507999903, -35.420924680999917],
              [-8.663569507999853, -35.424304180999854],
              [-8.662446007999847, -35.426041680999901],
              [-8.341725507999911, -35.944868681999935],
              [-8.338797507999914, -35.952736681999909],
              [-8.338345507999918, -35.953462681999952],
              [-8.129890007999961, -36.509203181999965],
              [-8.127602507999825, -36.515315681999937],
              [-8.039955506999888, -37.091602682999962],
              [-8.040126506999911, -37.094180682999934],
              [-8.039633506999905, -37.097394682999948],
              [-8.052617506999923, -37.29318968299998],
              [-7.885452506999911, -37.349501182999859],
              [-7.883810506999907, -37.350107682999919],
              [-7.267867506999892, -37.663651682999955],
              [-7.264201506999882, -37.666310683],
              [-7.262954506999904, -37.666938682999955],
              [-7.247767506999935, -37.677947682999957],
              [-7.235324506999973, -37.68421468299988],
              [-6.691279505999887, -38.074883683999872],
              [-6.235526505999928, -38.53211368399991],
              [-5.882445504999907, -39.043156683999939],
              [-5.873539504999883, -39.063916683999878],
              [-5.863752504999923, -39.077957683999898],
              [-5.859484504999898, -39.084083683999893],
              [-5.620928004999939, -39.634544184999925],
              [-5.619786504999979, -39.637241684999935],
              [-5.505808704999936, -40.211350685999946],
              [-5.522908504999862, -40.791833686000018],
              [-5.523654504999911, -40.794664685999919],
              [-5.523680504999902, -40.79556368599998],
              [-5.674780504999887, -41.36445368699998],
              [-5.682555504999868, -41.379360686999973],
              [-5.682899504999909, -41.380646686999967],
              [-5.966811505999942, -41.920588686999963],
              [-5.973453505999885, -41.928657686999941],
              [-6.384394505999978, -42.422426687999973],
              [-6.91162150599996, -42.853730687999985],
              [-7.539770506999957, -43.208134687999973],
              [-8.248965507999912, -43.473481688999982],
              [-9.015492507999966, -43.64050768899989],
              [-9.812793508999903, -43.703306688999874],
              [-9.815896508999913, -43.703147688999877],
              [-9.818063508999956, -43.703311688999975],
              [-9.831062508999935, -43.702642688999958],
              [-9.840172508999899, -43.703328688999932],
              [-9.841417508999882, -43.70326468899998],
              [-9.84229250899989, -43.70333068900004],
              [-10.642211509999925, -43.659466688999885],
              [-11.416442510999957, -43.510672688999932],
              [-12.138017510999873, -43.262193687999968],
              [-12.782591511999897, -42.922704687999953],
              [-12.789569511999957, -42.917409687999964],
              [-12.792662511999879, -42.91576368799997],
              [-12.815647511999941, -42.898312687999976],
              [-12.880836511999973, -42.863547687999926],
              [-13.426593512999943, -42.444299687999887],
              [-13.858433512999909, -41.959938686999976],
              [-14.164883512999893, -41.426432686999874],
              [-14.165195512999844, -41.425427686999967],
              [-14.165781512999956, -41.424400687],
              [-14.340164512999934, -40.858902685999965],
              [-14.369151512999849, -40.442291685999976],
              [-14.716706513999895, -40.307054685999958],
              [-15.320275513999889, -39.954848685],
              [-15.321244513999886, -39.954047184999979],
              [-15.330816513999878, -39.946036684999967],
              [-15.527446513999905, -39.829843684999958],
              [-16.033483514999887, -39.399706684999899],
              [-16.035959514999945, -39.396648684999946],
              [-16.038389514999892, -39.394565684999975],
              [-16.039630514999885, -39.393031684999983],
              [-16.040359514999921, -39.392406684999969],
              [-16.042548514999964, -39.389701684999977],
              [-16.045269514999916, -39.387368684999956],
              [-16.440882514999885, -38.894639683999969],
              [-16.715824515999884, -38.355445683999903],
              [-16.718047515999956, -38.346984683999892],
              [-16.720168515999944, -38.34279268399996],
              [-16.868468515999893, -37.774315682999941],
              [-16.888516515999925, -37.193902682999919],
              [-16.888486515999887, -37.192552682999917],
              [-16.78276151599988, -36.618520681999939],
              [-16.557084514999872, -36.0675636819999],
              [-16.220418514999949, -35.555697681000026],
              [-16.218670514999928, -35.553875680999965],
              [-16.211042514999917, -35.54218068099982],
              [-16.210096514999861, -35.541149680999979],
              [-15.774070514999949, -35.082793680999941],
              [-15.252450513999918, -34.691120679999983],
              [-14.983002513999907, -34.548741679999921],
              [-14.883797513999923, -34.472933679999983],
              [-14.295059512999956, -34.156673679999983],
              [-13.652628512999968, -33.926268679999964],
              [-12.97417201199994, -33.787982179999972],
              [-12.970375511999976, -33.78720668],
              [-12.9682215119999, -33.787081679999972],
              [-12.96463251199998, -33.786337679999974],
              [-12.957655511999917, -33.785933679999971],
              [-12.955991511999912, -33.785588679999933],
              [-12.260071510999921, -33.743063678999874],
              [-12.258819510999899, -33.743165678999901],
              [-12.257204510999941, -33.743061678999823],
              [-12.250907510999923, -33.743574679],
              [-12.242810510999902, -33.743053678999956],
              [-12.240450510999892, -33.743245678999926],
              [-12.238978510999914, -33.74315167899988],
              [-12.235040510999909, -33.743471678999981],
              [-12.228445510999904, -33.743046678999974],
              [-12.22759851099994, -33.743115678999914],
              [-12.226515510999945, -33.743045678999977],
            ],
          ],
          [
            [
              [-5.698201504999815, -12.571314659999814],
              [-5.105687504999878, -12.621126659999788],
              [-5.092560504999824, -12.624523659999909],
              [-5.074138504999951, -12.626045659999988],
              [-4.498511003999795, -12.774167159999934],
              [-4.495895503999918, -12.774900659999844],
              [-4.492937503999826, -12.77622765999979],
              [-4.491946503999941, -12.776481659999831],
              [-3.950694503999955, -13.01834666000002],
              [-3.459345002999839, -13.347092159999818],
              [-3.454421502999935, -13.3503916599999],
              [-3.027649502999907, -13.756255660999898],
              [-3.027176502999851, -13.75689566099993],
              [-3.025661502999924, -13.758331661],
              [-2.676282501999765, -14.229424660999882],
              [-2.663868501999843, -14.254327661],
              [-2.658986501999834, -14.26088766099997],
              [-2.397690501999818, -14.783261661999973],
              [-2.232940501999963, -15.341424661999952],
              [-2.17041100199981, -15.918746162999923],
              [-2.166809501999921, -15.95191666300002],
              [-2.166868501999829, -15.95272166299992],
              [-2.166781501999878, -15.953516662999959],
              [-2.209184501999857, -16.532479662999947],
              [-2.356197501999873, -17.095552664000024],
              [-2.604244501999972, -17.625464663999935],
              [-2.946527502999913, -18.10580466499988],
              [-3.373117502999833, -18.521553664999942],
              [-3.871177503999888, -18.859593665999967],
              [-4.425453503999847, -19.109210665999967],
              [-4.426335503999894, -19.109579666],
              [-4.431767503999879, -19.110991665999961],
              [-4.4851635039999, -19.134927665999953],
              [-4.516080503999831, -19.142951665999874],
              [-4.538175503999867, -19.152799665999964],
              [-4.541985503999967, -19.154459665999923],
              [-4.545610503999967, -19.155399666],
              [-4.550237503999824, -19.157444666],
              [-4.551119503999814, -19.157812665999984],
              [-5.144149504999916, -19.310593665999932],
              [-5.757074504999764, -19.361964166],
              [-5.757820504999813, -19.361984666000012],
              [-6.370469505999836, -19.31032666599998],
              [-6.96342050599992, -19.157287665999945],
              [-7.517754506999836, -18.907789665999985],
              [-8.016120506999869, -18.569710664999945],
              [-8.019041506999827, -18.567688665],
              [-8.445800507999934, -18.151992665000023],
              [-8.453614507999873, -18.14106366499999],
              [-8.475662507999914, -18.11951466499994],
              [-8.476460507999832, -18.118470664999904],
              [-8.818934507999813, -17.637960663999934],
              [-9.066993507999825, -17.108046664000028],
              [-9.214009508999936, -16.544972662999953],
              [-9.256387008999866, -15.965810163000015],
              [-9.256394508999847, -15.965209662999939],
              [-9.193814508999878, -15.387951661999921],
              [-9.028997507999861, -14.829795662000038],
              [-9.028024007999875, -14.827814662000037],
              [-8.766620507999903, -14.305346660999987],
              [-8.761471507999829, -14.298428661],
              [-8.754432507999979, -14.284313661],
              [-8.745693507999817, -14.272570661000032],
              [-8.745328507999943, -14.27183766099995],
              [-8.742922507999822, -14.268603660999986],
              [-8.741272507999838, -14.26529366099993],
              [-8.391837507999981, -13.794196661],
              [-8.391195507999896, -13.793587660999947],
              [-8.389814507999859, -13.791719660999945],
              [-7.962978506999832, -13.385849660999838],
              [-7.962252506999903, -13.385365660999867],
              [-7.957988506999868, -13.381298660999903],
              [-7.926269506999915, -13.360144660999936],
              [-7.909212006999837, -13.34386916],
              [-7.90620850699986, -13.34100466000001],
              [-7.415011506999861, -13.01218165999984],
              [-6.873836505999918, -12.77017566],
              [-6.298219505999896, -12.622046159999854],
              [-6.29464850599993, -12.621186660000035],
              [-5.702138504999823, -12.571314659999814],
              [-5.700168504999851, -12.571482659999845],
              [-5.698201504999815, -12.571314659999814],
            ],
          ],
          [
            [
              [-14.37690551299994, -4.558828651999931],
              [-13.796836512999903, -4.60911465199996],
              [-13.234094511999956, -4.758425651999971],
              [-12.705496511999911, -5.00228865299988],
              [-12.704500511999925, -5.002978652999957],
              [-12.682590511999877, -5.01306765299995],
              [-12.203931510999837, -5.344150652999872],
              [-12.201162510999893, -5.346884652999961],
              [-12.185969510999882, -5.35737665299996],
              [-12.18531651099994, -5.358020652999869],
              [-12.178997510999977, -5.362384652999879],
              [-12.169749510999964, -5.37151165299997],
              [-12.159018510999886, -5.378920652999966],
              [-11.74461651099989, -5.787282652999977],
              [-11.742371510999874, -5.790435652999975],
              [-11.734627510999928, -5.798054652999951],
              [-11.733824010999854, -5.799095652999938],
              [-11.396019510999906, -6.272741653999958],
              [-11.39566951099988, -6.273475653999867],
              [-11.395006510999906, -6.274404653999966],
              [-11.144513509999967, -6.798738653999976],
              [-10.988895509999907, -7.35821165499982],
              [-10.933159509999882, -7.935971654999832],
              [-10.933258509999916, -7.937208654999949],
              [-10.933139509999933, -7.938440654999908],
              [-10.979364509999925, -8.517029655999977],
              [-11.126597509999954, -9.078889656999934],
              [-11.127829509999913, -9.081557656999905],
              [-11.128570509999918, -9.084384656999873],
              [-11.372801510999977, -9.61238465699995],
              [-11.707003510999868, -10.090335656999912],
              [-12.121295510999914, -10.503503657999943],
              [-12.136972510999954, -10.514438657999847],
              [-12.140318510999919, -10.517770657999847],
              [-12.6222425119999, -10.853324657999963],
              [-12.624044511999898, -10.854159657999958],
              [-12.625279511999906, -10.855018657999949],
              [-12.640245511999922, -10.861958657999878],
              [-12.648379511999963, -10.867612657999814],
              [-13.183187511999904, -11.115079657999956],
              [-13.186031011999887, -11.115870657999949],
              [-13.757293512999979, -11.267612658999951],
              [-13.775022512999897, -11.269162658999846],
              [-13.778511512999899, -11.270086658999901],
              [-13.782940512999915, -11.270473658999947],
              [-13.806745512999896, -11.27677265899996],
              [-14.396583012999827, -11.327778658999875],
              [-14.397345512999948, -11.32780065899982],
              [-14.986917513999913, -11.276661658999956],
              [-14.992939013999887, -11.275075658999867],
              [-15.564075513999967, -11.123365657999955],
              [-16.09887851499991, -10.875863657999957],
              [-16.580832514999884, -10.540307657999904],
              [-16.581645514999934, -10.539546657999949],
              [-16.583553514999949, -10.537696657999902],
              [-16.585814514999981, -10.536119657999961],
              [-16.586786514999915, -10.535151658000018],
              [-16.59083051499988, -10.532330657999978],
              [-17.005162515999871, -10.119168656999932],
              [-17.006417515999885, -10.117376656999966],
              [-17.007169515999919, -10.116625656999929],
              [-17.341541015999894, -9.638369656999942],
              [-17.345366515999956, -9.632788656999935],
              [-17.589608515999885, -9.104787656999918],
              [-17.736849516999882, -8.542926655999963],
              [-17.738157516999934, -8.526529655999866],
              [-17.740633516999878, -8.517075655999918],
              [-17.740751516999978, -8.515600655999947],
              [-17.742549516999873, -8.508732655999836],
              [-17.788758016999964, -7.929942654999877],
              [-17.788770516999932, -7.929334654999934],
              [-17.733038516999954, -7.351574655000036],
              [-17.732789516999958, -7.350680654999849],
              [-17.731993516999921, -7.342395654999848],
              [-17.576388515999952, -6.782921653999892],
              [-17.325911515999906, -6.258584653999947],
              [-17.323335515999929, -6.254976653999904],
              [-17.321888515999888, -6.251942654],
              [-17.315876515999946, -6.243523653999887],
              [-17.310796515999925, -6.232869653999899],
              [-16.973251515999891, -5.759407652999926],
              [-16.971105515999909, -5.757295652999971],
              [-16.970051515999899, -5.755902152999965],
              [-16.96924851599988, -5.754860652999923],
              [-16.964305015999969, -5.749968652999826],
              [-16.963853515999915, -5.749497652999935],
              [-16.963232515999948, -5.748625652999976],
              [-16.961407515999952, -5.746761652999965],
              [-16.546899514999893, -5.338153652999893],
              [-16.06822851499993, -5.00710165299995],
              [-15.539600513999915, -4.76329965199983],
              [-15.536771013999953, -4.762523151999972],
              [-15.53188251399996, -4.761201651999954],
              [-15.525673513999948, -4.758332651999979],
              [-14.962920513999904, -4.609067651999965],
              [-14.38284651299989, -4.558829651999929],
              [-14.380362512999966, -4.559045651999952],
              [-14.377633512999864, -4.558850151999934],
              [-14.37690551299994, -4.558828651999931],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SJM", Country: "Jan Mayen" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-6.60941867799977, 67.613321188000086],
            [-6.62946972099985, 67.645496455000128],
            [-7.254785368999819, 68.175629398000225],
            [-8.150119689, 68.652962831000067],
            [-9.297728212999914, 69.059185454000087],
            [-10.664004950999868, 69.377679685000075],
            [-12.156666999999857, 69.578333],
            [-13.266666666999981, 69.583333333000141],
            [-13.629555555999872, 69.909555555000154],
            [-12.767027777999857, 71.880777777000034],
            [-11.474638888999891, 72.822833333000247],
            [-5.007694443999924, 74.363027777000141],
            [-4.663369503999832, 74.333398421000041],
            [-3.651181503000032, 74.238223421],
            [-3.448405675999879, 74.205480699000063],
            [-1.826790496999962, 73.943633984000016],
            [-0.287918459999872, 73.554522197999987],
            [0.917869013000114, 73.088588465000115],
            [0.919193763000123, 73.087799285],
            [0.921581501000162, 73.08637642000015],
            [0.92382550100001, 73.08547942],
            [1.773973508999973, 72.562209409000033],
            [1.776698878000161, 72.559208015000124],
            [1.782484232000115, 72.552836469999974],
            [1.786488711000146, 72.550288181000084],
            [2.279627502000068, 71.989680419000123],
            [2.390471080000168, 71.566996930000144],
            [2.43130929300014, 71.411266726000022],
            [2.266562484000161, 70.83334458500012],
            [1.816493222000105, 70.272638641000029],
            [1.777530332500163, 70.244060290500087],
            [1.773195014000152, 70.238472482000091],
            [1.765957026000081, 70.233151009999958],
            [1.765111371333433, 70.232529288333453],
            [1.754905580000155, 70.219305315000028],
            [1.703541502, 70.152751417000033],
            [1.00909450100005, 69.62375941700013],
            [0.712714388000052, 69.466226890000016],
            [0.0987913620001, 69.139913907999969],
            [0.096881161500107, 69.139186997000209],
            [0.094402500000143, 69.137890416000047],
            [0.049562449000149, 69.12033476800012],
            [-0.997414166999846, 68.710436722000097],
            [-2.237831435999851, 68.348831803500062],
            [-2.240406252000014, 68.348062209000133],
            [-2.245983501999888, 68.346388416000011],
            [-2.249314501999891, 68.345703416000092],
            [-2.255593493999925, 68.34381757200002],
            [-2.256336315999818, 68.343594490000029],
            [-3.612840009999843, 68.055341835000078],
            [-3.615308820999843, 68.054998500999986],
            [-3.647095199999967, 68.048017854000179],
            [-3.803361775999861, 68.026023838000171],
            [-4.002733503999906, 67.997963415000143],
            [-4.161755249999857, 67.962178989000066],
            [-4.34387292299985, 67.921197935000166],
            [-4.365638040999841, 67.918038942],
            [-4.406474666999969, 67.908746448000031],
            [-4.458948503999892, 67.896806415000029],
            [-5.672363892999982, 67.712558924000021],
            [-5.88368526999983, 67.680471540000028],
            [-5.894605632999856, 67.679547992],
            [-5.899697504999892, 67.679117415],
            [-5.928304505999847, 67.674598415],
            [-6.60941867799977, 67.613321188000086],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SLB", Country: "Solomon Is." },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [173.574722222000418, -11.95444444399989],
            [173.525833333, -12.535833332999857],
            [173.316962661000304, -13.073936659999859],
            [173.072631661000088, -13.603314660999914],
            [172.736249661000414, -14.083225160999916],
            [172.735383661000327, -14.084399660999935],
            [172.317058660000356, -14.49967966199992],
            [171.829251659999983, -14.837480661999891],
            [171.616697660000199, -14.939974661999855],
            [171.595026660000173, -14.929074661999849],
            [171.556462660000278, -14.909493661999832],
            [171.518184660000173, -14.889870661999893],
            [171.480193660000253, -14.870204661999864],
            [171.44248866000018, -14.850495661999929],
            [171.405069660000294, -14.830744661999873],
            [171.36793566000037, -14.810951661999908],
            [171.331087660000293, -14.791115661999939],
            [171.294524660000405, -14.771238661999945],
            [171.258247659000261, -14.751319661999858],
            [171.222254659000413, -14.731357661999851],
            [171.186546659000186, -14.711355662],
            [171.151123659000262, -14.691310661999879],
            [171.115984659000191, -14.671224661999915],
            [171.081129659000197, -14.651097661999842],
            [171.046559659000167, -14.630928661999931],
            [171.012272659000047, -14.610718661999925],
            [170.978269659000233, -14.590467661999895],
            [170.94454965900033, -14.570175661999855],
            [170.911113659000165, -14.54984266199989],
            [170.877960659000195, -14.5294686619999],
            [170.845090659000135, -14.509053661999886],
            [170.812502659000273, -14.488598661999859],
            [170.780197659000265, -14.468103661999891],
            [170.748175659000168, -14.447566661999829],
            [170.716435659000155, -14.426990660999905],
            [170.684977659000225, -14.40637366099989],
            [170.653801659000152, -14.385716660999847],
            [170.622907659000163, -14.365019660999863],
            [170.5922956590002, -14.344282660999951],
            [170.561963659000156, -14.323505660999913],
            [170.53191465900025, -14.302688660999863],
            [170.502145659000263, -14.281831660999885],
            [170.472657659000248, -14.260934660999879],
            [170.44345165900026, -14.23999866099993],
            [170.414524659000136, -14.219023660999852],
            [170.38587965900021, -14.198008660999861],
            [170.357513659000148, -14.176953661000013],
            [170.329428659000229, -14.155859660999866],
            [170.312065659000126, -14.142664660999941],
            [170.098962658000204, -13.979990660999874],
            [169.920400658000148, -13.843392660999854],
            [169.665513658000151, -13.760384660999847],
            [169.638892658000174, -13.751770660999895],
            [169.607958658000229, -13.741573660999862],
            [169.573007658000193, -13.729763660999879],
            [169.538435658000282, -13.717789660999912],
            [169.504243658000149, -13.705653660999857],
            [169.470429658000199, -13.693353660999918],
            [169.461120658000112, -13.689884660999851],
            [169.436923658000097, -13.680920660999888],
            [169.403771658000295, -13.668336660999898],
            [169.370999658000159, -13.655588660999925],
            [169.367552658000278, -13.654214660999926],
            [169.338506658000114, -13.642719660999887],
            [169.306383658000328, -13.629692660999922],
            [169.27464165800032, -13.616501660999887],
            [169.243278658000207, -13.603146660999869],
            [169.218066658000311, -13.592145660999932],
            [169.212287658000179, -13.589631660999856],
            [169.181639658000194, -13.575968660999848],
            [169.151371658000045, -13.562142660999854],
            [169.12148365800013, -13.548152660999875],
            [169.09197565700012, -13.53399966099991],
            [169.062847657000219, -13.519682660999877],
            [169.034098657000158, -13.505202660999871],
            [169.005729657000103, -13.490558660999866],
            [168.977738657000117, -13.47575166099989],
            [168.950127657000138, -13.460781660999913],
            [168.922894657000114, -13.445648660999865],
            [168.896039657000159, -13.43035266099993],
            [168.86956265700033, -13.414893660999908],
            [168.863129657000144, -13.411043660999866],
            [168.843442657000168, -13.399281660999932],
            [168.817692657000151, -13.383509660999863],
            [168.792321657000315, -13.367574660999892],
            [168.767327657000379, -13.351476659999861],
            [168.745532657000041, -13.337079659999873],
            [168.729319657000218, -13.32310665999988],
            [168.705602657000242, -13.302163659999877],
            [168.682369657000123, -13.281136659999859],
            [168.659620657000261, -13.260026659999909],
            [168.63735665700014, -13.238831659999931],
            [168.615575657000164, -13.217552659999939],
            [168.594277657000106, -13.196190659999914],
            [168.573463657000133, -13.17474465999986],
            [168.553132657000134, -13.15321465999989],
            [168.533284657000223, -13.131601659999873],
            [168.515564657000112, -13.111748659999833],
            [168.474563657000232, -13.064683659999872],
            [168.238470657000249, -12.794516659999914],
            [167.807702656000146, -12.301191659999859],
            [167.798618656000173, -12.304616659999922],
            [167.78295765600015, -12.310484659999872],
            [167.756256656000318, -12.3201096599999],
            [167.729625656000252, -12.329331659999909],
            [167.703064656000123, -12.338148659999874],
            [167.676574656000213, -12.346561659999907],
            [167.650154656000183, -12.35456966],
            [167.62380465600009, -12.36217465999988],
            [167.597524656000104, -12.369375659999903],
            [167.571314656000055, -12.376172659999895],
            [167.545174656000114, -12.382565659999855],
            [167.51910465600011, -12.388554659999869],
            [167.493105656000097, -12.394140659999849],
            [167.467175656000137, -12.399322659999882],
            [167.442423656000102, -12.403896659999887],
            [167.441313656000176, -12.404117659999869],
            [167.419021656000126, -12.408218659999818],
            [167.415449656000163, -12.408923659999886],
            [167.389604656000216, -12.413623659999828],
            [167.363830656000374, -12.417911659999888],
            [167.338127656000353, -12.421788659999862],
            [167.312495656000152, -12.425253659999854],
            [167.286936656000336, -12.428308659999942],
            [167.261447656000229, -12.430950659999866],
            [167.236030656000281, -12.433182659999886],
            [167.230840656000197, -12.433554659999942],
            [167.210988656000126, -12.435094159999863],
            [167.185278656000122, -12.43716765999983],
            [167.180867656000061, -12.437450659999882],
            [167.159895656000145, -12.439205659999885],
            [167.154239656000215, -12.439586659999875],
            [167.134518656000097, -12.441204659999897],
            [167.109200656000127, -12.442865659999839],
            [167.083956656000083, -12.444101659999873],
            [167.058785656000197, -12.444913659999827],
            [167.033688656000237, -12.445302659999953],
            [167.00866465600015, -12.445267659999899],
            [166.983713656000106, -12.444808659999936],
            [166.958836655000113, -12.443926659999875],
            [166.934032655000209, -12.442619659999821],
            [166.909302655000062, -12.440889659999954],
            [166.884645655000242, -12.438735659999892],
            [166.860062655000178, -12.436157659999836],
            [166.8355526550001, -12.433156659999867],
            [166.811115655000179, -12.429731659999902],
            [166.786752655000186, -12.425882659999843],
            [166.762462655000121, -12.421609659999888],
            [166.738245655000043, -12.416912659999923],
            [166.714102655000119, -12.411791659999864],
            [166.690032655000238, -12.406247659999892],
            [166.686115655000293, -12.405273659999864],
            [166.668124655000128, -12.400897659999899],
            [166.666009655000124, -12.400429659999816],
            [166.653902655000223, -12.397529659999947],
            [166.644848655000118, -12.395359659999897],
            [166.530766655000235, -12.369514659999851],
            [166.519127655000176, -12.366970659999865],
            [166.304770655000226, -12.321799659999854],
            [166.278033655000087, -12.316458659999896],
            [166.244223655000127, -12.310328659999925],
            [166.20995165500014, -12.30472965999985],
            [166.175215655000187, -12.29966266],
            [166.140017655000094, -12.295125659999869],
            [166.104356655000203, -12.291118659999881],
            [166.068233655000171, -12.287642659999875],
            [166.031648655000112, -12.284696659999923],
            [165.994601655000196, -12.282279658999869],
            [165.957092655000253, -12.28039365899987],
            [165.91912265500028, -12.279035658999859],
            [165.880690654000347, -12.278207658999918],
            [165.841797654000146, -12.277907658999879],
            [165.802443654000086, -12.278136658999827],
            [165.762628654000167, -12.278893658999834],
            [165.722352654000105, -12.280178658999844],
            [165.681616654000294, -12.281990658999931],
            [165.640420654000224, -12.284330659999924],
            [165.598764654000178, -12.287196659999921],
            [165.556648654000213, -12.290588659999912],
            [165.514072654000159, -12.294507659999894],
            [165.471037654000128, -12.298951659999886],
            [165.427543654000175, -12.303921659999872],
            [165.383589654000133, -12.309415659999885],
            [165.339177654000224, -12.315434659999895],
            [165.294306654000167, -12.321976659999933],
            [165.248976654000131, -12.329042659999885],
            [165.203188654000229, -12.336631659999853],
            [165.156943654000173, -12.34474365999985],
            [165.11023965400031, -12.353377659999865],
            [165.063077654000239, -12.362532659999829],
            [165.015458654000184, -12.372208659999913],
            [164.967382654000147, -12.382405659999932],
            [164.918849654000184, -12.393122659999904],
            [164.86985965400018, -12.404358659999914],
            [164.820412654000137, -12.416113659999894],
            [164.770508653000178, -12.428387659999899],
            [164.720148653000109, -12.441179659999875],
            [164.669332653000282, -12.454487659999813],
            [164.618061653000069, -12.46831266],
            [164.5663336530001, -12.482654659999881],
            [164.514150653000314, -12.497510659999833],
            [164.461511653000258, -12.512882659999846],
            [164.408417653000157, -12.528767659999929],
            [164.354868653000239, -12.545166659999921],
            [164.300865653000102, -12.562078659999898],
            [164.246609653000149, -12.579437659999826],
            [164.147975653000259, -12.611638659999883],
            [163.946932653000232, -12.969430659999858],
            [163.933308653000068, -12.993357659999916],
            [163.91947665300026, -13.017365659999854],
            [163.905489653000103, -13.041365659999926],
            [163.897709653000305, -13.054564659999912],
            [163.818143653000192, -13.189291659999924],
            [163.80420665300008, -13.212646659999905],
            [163.789372653000299, -13.237232659999876],
            [163.774379653000238, -13.261816659999866],
            [163.759225653000129, -13.286398659999946],
            [163.743912652000319, -13.310978659999861],
            [163.728438652000108, -13.335557659999878],
            [163.712805652000242, -13.360134660999904],
            [163.697011652000157, -13.38470966099986],
            [163.681056652000137, -13.409281660999909],
            [163.664942652000235, -13.433852660999889],
            [163.648667652000341, -13.458421660999875],
            [163.632231652000172, -13.482988660999951],
            [163.615636652000234, -13.507553660999875],
            [163.598879652000136, -13.532116660999904],
            [163.581962652000101, -13.556677660999938],
            [163.564885652000129, -13.581235660999894],
            [163.556046652000219, -13.593826660999937],
            [163.166666652000202, -14.834166661999959],
            [162.323888651000203, -15.204721661999898],
            [160.248332649000162, -16.126943662999878],
            [158.760987648000111, -15.735239662999945],
            [157.716820647000333, -14.683294661999923],
            [157.038333333000224, -14.067777777999879],
            [157.600833647000258, -12.436388659999949],
            [157.16111064600031, -11.373888658999846],
            [154.585555644000038, -8.134999655999849],
            [155.112222644000127, -7.246666654999871],
            [155.508333645000079, -6.958293654999892],
            [155.693610645, -6.925833654999906],
            [155.923055645000119, -6.847222653999907],
            [156.039722645000069, -6.658333653999819],
            [156.035833645, -6.549999653999905],
            [158.235277647000231, -4.871666652999906],
            [160.480277649000158, -4.751388651999918],
            [162.80194465200023, -4.142222651999816],
            [162.847043652000167, -4.236959651999868],
            [163.000659652000223, -4.796763652999928],
            [163.022242652000017, -5.027399652999875],
            [163.446600652000114, -5.064149652999916],
            [164.009747653000119, -5.213400652999852],
            [164.062405653000184, -5.237707652999916],
            [164.067404653000239, -5.239027652999894],
            [164.596482653000209, -5.482720652999888],
            [164.599608653000047, -5.484883652999883],
            [164.600462653000164, -5.4852766529999],
            [165.079614654000039, -5.816191652999876],
            [165.080543654000138, -5.817107653],
            [165.081609654000061, -5.817842652999872],
            [165.496460654000117, -6.226079653999861],
            [165.812852654000125, -6.669117653999834],
            [165.908804655000239, -6.624833653999843],
            [166.473070655000043, -6.474912653999894],
            [167.054749656000041, -6.423771653999836],
            [167.63657565600019, -6.472925653999894],
            [168.20128565700017, -6.620903654000017],
            [168.206035657000285, -6.623078653999855],
            [168.20800965700019, -6.623593653999833],
            [168.20932965700004, -6.624153153999856],
            [168.740384657000078, -6.866592653999874],
            [169.221516658000127, -7.196168654999909],
            [169.228589658000061, -7.203083654999887],
            [169.241458658000255, -7.211879654999905],
            [169.3379096580002, -7.306125654999917],
            [169.345406658000201, -7.311244654999882],
            [169.348023158000132, -7.313715654999882],
            [169.365735658000204, -7.330928654999894],
            [169.369968658000147, -7.333818654999916],
            [169.387411658000275, -7.350847654999896],
            [169.391630658000309, -7.353727654999886],
            [169.397929658000265, -7.359875654999826],
            [169.399448658000182, -7.360912654999836],
            [169.816910658000069, -7.767593654999871],
            [170.15773365800024, -8.239551655999833],
            [170.175397658000207, -8.275965655999869],
            [170.42709565900023, -8.297203655999937],
            [170.994292659000081, -8.445180655999891],
            [171.527585660000142, -8.687439655999896],
            [172.011151660000309, -9.016797655999838],
            [172.430554661000286, -9.423479656999859],
            [172.431318161000235, -9.424433156999882],
            [172.774160661000309, -9.896587656999856],
            [172.811646661000196, -9.973556656999889],
            [173.028299661000432, -10.270666657999882],
            [173.28452866100028, -10.793454657999931],
            [173.445379662000192, -11.351878658999851],
            [173.574722222000418, -11.95444444399989],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SLE", Country: "Sierra Leone" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-10.266654850852888, 8.488373108868785],
            [-10.264308756147528, 8.455136012838253],
            [-10.297572841906486, 8.199027128235059],
            [-10.33375500344502, 8.159582059419265],
            [-10.358054979008756, 8.145691066523938],
            [-10.438263925289561, 8.141244969791117],
            [-10.474026824030091, 8.144164219037279],
            [-10.502363859450753, 8.13389102320059],
            [-10.524163848566332, 8.117773126263472],
            [-10.602636735056706, 8.032918091039321],
            [-10.601945898594977, 7.858891172805912],
            [-10.607781882516264, 7.772500070001698],
            [-10.652226756590125, 7.760554013924548],
            [-10.686808812807982, 7.74305394114991],
            [-10.705836738897773, 7.724164148824912],
            [-10.724726866499026, 7.70194506454915],
            [-10.74749982693811, 7.669717987853986],
            [-10.829999882639413, 7.566391081998432],
            [-10.845554850957683, 7.547500116206947],
            [-10.850863948443873, 7.541882061768845],
            [-10.868545908517945, 7.527364102514241],
            [-10.920099809970935, 7.508227044044787],
            [-11.102781881447527, 7.384444941622803],
            [-11.131945875437793, 7.351391073996183],
            [-11.137781859358881, 7.343564220427012],
            [-11.141663854006481, 7.327500135308455],
            [-11.193681945258533, 7.256453950328364],
            [-11.23889091362804, 7.243891153813919],
            [-11.267499857988327, 7.235000133814751],
            [-11.306390883002706, 7.214864120122044],
            [-11.338608907242474, 7.150836102690491],
            [-11.34166394332027, 7.116664089177149],
            [-11.351390806706462, 7.078609075187799],
            [-11.379999918704925, 7.018891032381092],
            [-11.425554892038321, 6.939027085235949],
            [-11.465836810069561, 6.923609077215787],
            [-11.492327160150523, 6.927090722721374],
            [-11.496002510999887, 6.920765358000125],
            [-11.496002510999887, 6.918155358000092],
            [-11.499224511000023, 6.906736358000032],
            [-11.521111510999873, 6.854860358000096],
            [-11.547195510999984, 6.825170358000051],
            [-11.572185510999986, 6.795196358000055],
            [-11.573327510999974, 6.793871358000118],
            [-11.635964510999969, 6.712990358000056],
            [-11.63783451099988, 6.711731358000165],
            [-11.656362510999884, 6.699077358000153],
            [-11.698099510999896, 6.670174358000125],
            [-11.78729251099989, 6.589697358],
            [-11.800036510999888, 6.577828358000119],
            [-12.069385510999837, 6.329857358],
            [-12.07328251099986, 6.326292358000089],
            [-12.171388510999861, 6.23697835800003],
            [-12.434151511999886, 5.984165358000126],
            [-12.525007511999888, 5.897830357000089],
            [-12.541027511999857, 5.877687357000028],
            [-12.566714511999891, 5.845235357000078],
            [-12.598828511999955, 5.804473357],
            [-12.613663511999846, 5.785448357000078],
            [-12.684992511999866, 5.692024357000136],
            [-12.726760511999856, 5.632949357000072],
            [-12.990936511999905, 5.259702357000151],
            [-12.991530511999855, 5.258860357000117],
            [-13.171890512, 4.942629357000072],
            [-13.582982512999962, 4.223879356000012],
            [-13.701887513000031, 4.255243356000094],
            [-13.703797512999955, 4.256075356000011],
            [-13.706865512999883, 4.256912355999987],
            [-13.708695012999897, 4.257729856000125],
            [-14.041957512999858, 4.411566356000037],
            [-14.112655512999822, 4.430198356000048],
            [-14.114386012999915, 4.430942356000145],
            [-14.643211513999915, 4.674220356000092],
            [-14.650663513999916, 4.679372356000044],
            [-14.661109514000032, 4.684170356000109],
            [-14.662437513999862, 4.685088356000122],
            [-14.664118513999853, 4.685860356000049],
            [-14.666695513999912, 4.687642356000055],
            [-14.668099514, 4.688286356000049],
            [-14.674423513999841, 4.692659356000121],
            [-14.682046513999978, 4.696159356000166],
            [-15.160784513999857, 5.026617357000077],
            [-15.190285513999896, 5.055685357000073],
            [-15.207687513999815, 5.067674357000129],
            [-15.622283514999879, 5.475471357000131],
            [-15.960162515, 5.948439357],
            [-15.960507514999847, 5.949161357000051],
            [-15.982266514999964, 5.979567358000097],
            [-16.233201514999905, 6.503497358000132],
            [-16.283409514999846, 6.683536358000097],
            [-16.404674514999982, 6.935766358],
            [-16.561360835999864, 7.494529396000175],
            [-16.559955290999937, 7.495131604],
            [-15.78018521899989, 7.829225841000124],
            [-15.397658742999937, 7.991710640000079],
            [-15.302818258999849, 8.040901861000108],
            [-15.282124984, 8.05151124300005],
            [-14.78053582299998, 8.307726204000062],
            [-14.712664564999869, 8.342682887000038],
            [-14.437230966999891, 8.491650067000123],
            [-14.434936727999883, 8.492891771000188],
            [-14.433433031, 8.493706298000077],
            [-13.996110064999925, 8.730401945000125],
            [-13.839185905999898, 8.897636752000082],
            [-13.782554941, 8.923482943000138],
            [-13.562370478, 8.96237552400008],
            [-13.526008345999884, 8.969494398000052],
            [-13.507585759999813, 8.973275097000055],
            [-13.426998134999963, 8.99047142000002],
            [-13.396353745999818, 8.996767841000164],
            [-13.385503620999856, 8.998501578000173],
            [-13.361009370999909, 9.003710032000086],
            [-13.355817593999973, 9.00456487300012],
            [-13.338507164999868, 9.013744884000019],
            [-13.314002594999891, 9.023943273000157],
            [-13.31148707899996, 9.025413967000162],
            [-13.297615135999877, 9.030799449750049],
            [-13.295764185385423, 9.032041052674515],
            [-13.290836807822643, 9.042773141199504],
            [-13.263681956029444, 9.062636072485915],
            [-13.182845875751099, 9.086182179676072],
            [-13.140836782746788, 9.053891065240549],
            [-13.113472886287639, 9.043891119448148],
            [-13.093190859841229, 9.047218064465753],
            [-13.031390919918039, 9.086391056703746],
            [-13.004445950980823, 9.10819104581941],
            [-12.976390882783875, 9.169444988568372],
            [-12.960836752655808, 9.234444971494952],
            [-12.948681819550984, 9.277291081352601],
            [-12.927226829570884, 9.289027086935505],
            [-12.856045863587468, 9.284445035732958],
            [-12.818054887338661, 9.286664228423902],
            [-12.797781913347649, 9.29805406140467],
            [-12.679581823179944, 9.419718056888371],
            [-12.664999826184896, 9.476109154677474],
            [-12.631108774066689, 9.572773118042122],
            [-12.616663905008124, 9.606109120530434],
            [-12.584090823348987, 9.63187307930697],
            [-12.585972895893093, 9.648473103316135],
            [-12.492946007964377, 9.863909137922988],
            [-12.456108884509945, 9.888336015501324],
            [-12.230763931579645, 9.935209126931795],
            [-12.219863937021671, 9.911944986965437],
            [-12.159726799054965, 9.87971807790835],
            [-12.134445973178856, 9.875000072235807],
            [-12.109136816469885, 9.880400029552575],
            [-12.105836693542415, 9.880553921295302],
            [-11.897499795668892, 9.935345081401792],
            [-11.900554999384866, 9.972500046625484],
            [-11.894726726814611, 9.996109018089697],
            [-11.576736765465284, 9.997473089016125],
            [-11.569446018424884, 9.9966642353575],
            [-11.481745997617082, 9.997473089016125],
            [-11.458890894526633, 9.997500078744537],
            [-11.214445931138897, 9.997500078744537],
            [-11.17423676802764, 9.975626999432933],
            [-11.104581811340466, 9.853609119996065],
            [-11.051672891416359, 9.809445039679332],
            [-11.023890905625706, 9.781945021112293],
            [-10.997217845628199, 9.75166422234004],
            [-10.979999907715182, 9.727500033607939],
            [-10.909172825684834, 9.60305391681446],
            [-10.83860876777689, 9.479718066918608],
            [-10.750136773684403, 9.389027047773538],
            [-10.699717781924562, 9.346109021186336],
            [-10.657290934864818, 9.302636112607402],
            [-10.67118192776033, 9.227709106446284],
            [-10.689308799256139, 9.206109109540876],
            [-10.723608888250368, 9.159445042775971],
            [-10.739654868458132, 9.091391029599905],
            [-10.721463959221865, 9.077564074445021],
            [-10.678608796908605, 9.077773119110773],
            [-10.611108827533968, 9.073191067908226],
            [-10.571526798419967, 9.059536109406793],
            [-10.577317855339999, 9.04120002560704],
            [-10.595836832267594, 8.98569103931068],
            [-10.601108881741567, 8.953609137179086],
            [-10.593190833065819, 8.866944951968492],
            [-10.583054932803492, 8.824445014712978],
            [-10.508055004084611, 8.717918065854136],
            [-10.536808787732127, 8.619927079574907],
            [-10.576390816846214, 8.599445060918143],
            [-10.619863893063325, 8.543744967228847],
            [-10.693890850458786, 8.361945007244827],
            [-10.695836793105457, 8.298608999741489],
            [-10.554581942913046, 8.308745067641695],
            [-10.529163821462561, 8.321809101967403],
            [-10.492636828426555, 8.348191142285799],
            [-10.435836693761246, 8.404027022807],
            [-10.42278187952914, 8.435836010170661],
            [-10.390417842535754, 8.49083604730474],
            [-10.323336800683023, 8.509164084477391],
            [-10.266654850852888, 8.488373108868785],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SLV", Country: "El Salvador" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-87.650738521999955, 13.195444608000173],
            [-87.718126359999928, 13.131364135000112],
            [-87.723613053999969, 13.124477602000084],
            [-87.733327154999898, 13.118240169000117],
            [-87.745571255000016, 13.105321008000104],
            [-87.791354049999939, 13.056107120999982],
            [-87.82569106299988, 13.01844253800013],
            [-87.832701676999875, 13.010639775000143],
            [-87.837167486, 13.006186272000079],
            [-87.84797336400004, 12.995921670000143],
            [-87.858930588, 12.985690279000025],
            [-87.885382185999958, 12.961486826],
            [-87.914174836, 12.936736262000181],
            [-87.920408714000018, 12.931651429000013],
            [-87.922831981999877, 12.929585237000026],
            [-87.933440670999858, 12.920380210000104],
            [-87.943636442999946, 12.91107966],
            [-87.955633406999965, 12.900285735000153],
            [-88.000356194999938, 12.853070249],
            [-88.052636214999893, 12.797491125],
            [-88.070559526999887, 12.775246056000086],
            [-88.076922371000023, 12.764873988000147],
            [-88.141916917999936, 12.659665163999989],
            [-88.204258192999902, 12.537720167000103],
            [-88.217503743999828, 12.511433339000178],
            [-88.220494678999955, 12.505485756000127],
            [-88.223500880999978, 12.499450786000125],
            [-88.31112965399987, 12.323064560000049],
            [-88.337296563, 12.270263351000125],
            [-88.371979861, 12.167436397000131],
            [-88.422350317, 12.020577838000079],
            [-88.506397681999829, 11.786566325000081],
            [-88.581739198000037, 11.577041188000067],
            [-89.180494938000038, 10.405912243000159],
            [-89.422342707000041, 9.972926435000048],
            [-89.751295584000047, 10.060442361000099],
            [-89.930320583999958, 10.107850361000104],
            [-89.937426584, 10.111119361000135],
            [-89.984649583999982, 10.123600361000129],
            [-89.994355583999976, 10.128061361000107],
            [-90.002928584000017, 10.130325361000118],
            [-90.019267584, 10.137834361000131],
            [-90.242802583999946, 10.240415361000018],
            [-90.250607583999937, 10.241080361000158],
            [-90.408548584000016, 10.254499361000043],
            [-90.978736584999922, 10.403360362000043],
            [-91.424937626999849, 10.605661582000096],
            [-91.326634822999921, 10.922294124000146],
            [-91.049115235000016, 11.816182491000134],
            [-90.925114851999865, 12.19253800750009],
            [-90.914508319, 12.224730063000152],
            [-90.872091647000047, 12.35346957500019],
            [-90.838213692999972, 12.448704165500061],
            [-90.794052864999969, 12.514439810500107],
            [-90.68929454299996, 12.670377915000145],
            [-90.548260574999915, 12.867059734000193],
            [-90.461845553999922, 12.987571578000072],
            [-90.373995508999855, 13.110084665000073],
            [-90.347218890999898, 13.160594232000108],
            [-90.233492749999868, 13.356317113000046],
            [-90.129616567999847, 13.567014567000243],
            [-90.113304965999987, 13.664450478000134],
            [-90.104145839999859, 13.71916170500009],
            [-90.096390922933836, 13.745836059832953],
            [-90.108063896538795, 13.789027001021111],
            [-90.103899934667766, 13.823327090015255],
            [-90.086535816362954, 13.868473194110905],
            [-90.020564035579525, 13.938609104403454],
            [-89.889026828679732, 14.044164087767271],
            [-89.854445945928376, 14.059444967851064],
            [-89.818199914287561, 14.066454082943991],
            [-89.73972686015918, 14.041391186551166],
            [-89.746672943340002, 14.069582209217927],
            [-89.726817891042515, 14.117918130394813],
            [-89.710145950303797, 14.145000059630334],
            [-89.686399850903484, 14.170000091749287],
            [-89.652090877091837, 14.195554000031663],
            [-89.626955058140965, 14.209309038457107],
            [-89.60514601656979, 14.208191060208449],
            [-89.579454980351017, 14.207500056108657],
            [-89.518163989590022, 14.233853933232425],
            [-89.543335850724617, 14.304164187111567],
            [-89.565490897259849, 14.316454068857794],
            [-89.589172958919903, 14.3212449970883],
            [-89.583346027454127, 14.385827058321254],
            [-89.570972997227955, 14.414718137543318],
            [-89.548482004012584, 14.42083608868812],
            [-89.495008982003242, 14.421391138317929],
            [-89.435564021602744, 14.414864150296935],
            [-89.348308912216766, 14.431982175923778],
            [-89.332164025551236, 14.410482091304644],
            [-89.315564001541986, 14.404091057753462],
            [-89.297791013999159, 14.40110911187152],
            [-89.21334602147968, 14.375827112528938],
            [-89.174454996465386, 14.354718127512811],
            [-89.119726868270845, 14.324373123362122],
            [-89.037226812569742, 14.334718068290087],
            [-89.024735932784751, 14.327636030639198],
            [-88.977782019807307, 14.253891040467337],
            [-88.96055502943878, 14.221945092805569],
            [-88.959472925735867, 14.216982167921444],
            [-88.956046068431988, 14.193891030436944],
            [-88.920208906029103, 14.205973208622069],
            [-88.897781950553991, 14.203609009005717],
            [-88.875145950413298, 14.187635951355745],
            [-88.860290871012069, 14.171109017542435],
            [-88.839446083584903, 14.13847307160934],
            [-88.831117824566377, 14.114164211228186],
            [-88.827572950065587, 14.100136090396475],
            [-88.806326837113176, 14.096182179019493],
            [-88.772435784995139, 14.133473098713168],
            [-88.752235901200066, 14.134445064208009],
            [-88.734172899806424, 14.115553930778546],
            [-88.719308935587861, 14.092364222112323],
            [-88.705699909916149, 14.044164087767271],
            [-88.661482017780855, 14.014082107738901],
            [-88.629981987369206, 14.014827091295146],
            [-88.506672959563701, 13.982364147843995],
            [-88.495008870710251, 13.970273084841622],
            [-88.499454967442887, 13.915835976326363],
            [-88.488890919565364, 13.865836079726449],
            [-88.467926941474701, 13.854717988047227],
            [-88.431673030844706, 13.87013599606739],
            [-88.403346053708049, 13.884026988962702],
            [-88.375972937155325, 13.882500141476143],
            [-88.352235890210324, 13.867773137555602],
            [-88.345226942755545, 13.874864227661831],
            [-88.319308924598289, 13.888744994635246],
            [-88.259608819064312, 13.905282154370624],
            [-88.234999886548621, 13.940273079826412],
            [-88.196882008285399, 13.987618092407232],
            [-88.161663933252782, 13.976945079787939],
            [-88.121945949668884, 13.990553937821559],
            [-88.06000888180921, 13.963891103746121],
            [-88.040435796735352, 13.931173182799583],
            [-88.005863966439478, 13.875044941494863],
            [-87.953335920196679, 13.896108999509678],
            [-87.940563911378291, 13.895000073716446],
            [-87.908335828854916, 13.895827032286164],
            [-87.897508924492968, 13.899091113029883],
            [-87.800845966956444, 13.896945010534708],
            [-87.750290853088728, 13.864164225314383],
            [-87.725708910301364, 13.834582141992783],
            [-87.707646076545871, 13.822500131445636],
            [-87.694672902050769, 13.819009068763322],
            [-87.725008853746147, 13.733327074969722],
            [-87.776945975813305, 13.572427055336078],
            [-87.783390988821026, 13.524164224355047],
            [-87.754864019474041, 13.514636012074718],
            [-87.715317864905757, 13.504754083479398],
            [-87.711090871122508, 13.48165406117765],
            [-87.713345938359083, 13.471109124039145],
            [-87.750290853088728, 13.443054055842296],
            [-87.815582023166201, 13.405382094914572],
            [-87.81752137, 13.404076823000111],
            [-87.828465193999989, 13.393132999000116],
            [-87.833253117999845, 13.379453219000141],
            [-87.81341743599998, 13.361669505000151],
            [-87.783372574, 13.334359442000121],
            [-87.768870786000036, 13.33036604900002],
            [-87.72810073699992, 13.299919920000036],
            [-87.677110155999969, 13.238001920000016],
            [-87.651841608000012, 13.215117637999981],
            [-87.650738521999955, 13.195444608000173],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SMR", Country: "San Marino" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.495000244800423, 43.914718151745816],
            [12.471527060168228, 43.898682229821972],
            [12.410082177664492, 43.912782099745058],
            [12.406945166573252, 43.951664239942019],
            [12.415800144388697, 43.957954020102463],
            [12.459654094287004, 43.983264182639729],
            [12.504654017990788, 43.986873094881062],
            [12.511109089282314, 43.941245031351528],
            [12.496391137817369, 43.923336089338775],
            [12.495000244800423, 43.914718151745816],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SOM", Country: "Somalia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [54.429914551000167, 8.957717360000132],
            [54.326320551000123, 8.742304360000119],
            [54.323930551000018, 8.738981360000068],
            [54.319550551000162, 8.72986436000005],
            [54.315437551000088, 8.724133860000109],
            [54.314986551000203, 8.723496360000027],
            [54.314511551000152, 8.722508360000077],
            [54.310878551000116, 8.717455360000059],
            [54.298383551000171, 8.691544860000093],
            [54.297620551000108, 8.690058360000123],
            [53.990668550000095, 8.262393360000075],
            [53.745760550000085, 7.74959185900012],
            [53.744998050000135, 7.748134359000147],
            [53.744219550000054, 7.746521359],
            [53.742970550000081, 7.744772359000066],
            [53.742428550000085, 7.743757859000056],
            [53.741667550000187, 7.742302859000105],
            [53.740868550000044, 7.740659859000147],
            [53.739895550000227, 7.73875985900014],
            [53.739169550000184, 7.737362358999974],
            [53.736313550000119, 7.733363359000123],
            [53.735844550000053, 7.732380359000146],
            [53.734120550000142, 7.729965359000076],
            [53.731492050000185, 7.724597859000056],
            [53.730794550000184, 7.723282359000109],
            [53.729165550000147, 7.7209443590001],
            [53.727376550000059, 7.71834469233346],
            [53.726561550000071, 7.716667358999985],
            [53.725323550000127, 7.714933359000042],
            [53.724950550000102, 7.714150359],
            [53.723237216666831, 7.711706025666757],
            [53.721620550000154, 7.708357359000132],
            [53.7199755500001, 7.706006359000114],
            [53.718206550000133, 7.703436025666747],
            [53.717387550000154, 7.701742359],
            [53.682338550000082, 7.652638359],
            [53.681182050000103, 7.650254359000101],
            [53.680293550000073, 7.648530859000147],
            [53.679524550000139, 7.647015359000079],
            [53.672496550000034, 7.637165359000164],
            [53.671896550000184, 7.636029859000175],
            [53.671164550000213, 7.634615359000122],
            [53.6455485500002, 7.598711359],
            [53.559946050000207, 7.418782859000032],
            [53.55914755000012, 7.417140359000115],
            [53.558359550000119, 7.415497359000071],
            [53.55673055000014, 7.413211359000016],
            [53.555668050000151, 7.411097359000067],
            [53.55493055000008, 7.40966635900007],
            [53.380196550000193, 7.164210359000123],
            [53.241393550000083, 6.871351358000055],
            [53.235605050000146, 6.863164858000118],
            [53.235207550000126, 6.862571358000125],
            [53.234609550000101, 6.861337358000156],
            [53.233805050000086, 6.859691358000035],
            [53.233033550000101, 6.858085358000125],
            [53.23014155000007, 6.854012358],
            [53.229521550000101, 6.852835858],
            [53.228760550000203, 6.851390858000087],
            [53.227809050000161, 6.849538358000103],
            [53.2270225500001, 6.84804685800006],
            [53.225466550000164, 6.844804358000104],
            [53.107145549000194, 6.678035358000045],
            [53.039726049000052, 6.535401358000129],
            [53.038142549000071, 6.532074358],
            [53.037675549000056, 6.531416358000101],
            [53.03723454900009, 6.530481358],
            [52.76314654900014, 6.143121358000059],
            [52.732726549000205, 6.078524357999981],
            [52.730999882333464, 6.076058024666779],
            [52.730205549000146, 6.074412358000103],
            [52.700328549000091, 6.032113358000046],
            [52.672118549000203, 5.973825358000084],
            [52.67138704900006, 5.972295358000139],
            [52.634321549000134, 5.895730357000062],
            [52.633718549000179, 5.894894357000169],
            [52.632503049000121, 5.892385857000093],
            [52.629945549000155, 5.887206357000082],
            [52.629200549000103, 5.88577135700001],
            [52.627949549000135, 5.88403635700017],
            [52.627447549000095, 5.883096857000055],
            [52.625731049000166, 5.879751357000089],
            [52.624977549000079, 5.878303857000049],
            [52.624169549000129, 5.876644857000102],
            [52.604091549000117, 5.835075357],
            [52.602836549000102, 5.833334357000112],
            [52.60233004900013, 5.832393357000143],
            [52.601583549000139, 5.830956357000076],
            [52.599822549000095, 5.82741485700015],
            [52.598263549000166, 5.824297857000175],
            [52.597316049000113, 5.82244335700004],
            [52.596563549000194, 5.820989357000087],
            [52.59543954900019, 5.819429357000061],
            [52.594802049000094, 5.818217357000051],
            [52.594019049000082, 5.816730857000167],
            [52.593252549000084, 5.815168357000019],
            [52.591781549000217, 5.813127357000084],
            [52.590737549000181, 5.81097035700013],
            [52.589941549000201, 5.809326357000089],
            [52.588348049000189, 5.807038857000094],
            [52.586994549000082, 5.805084357000112],
            [52.586588549000084, 5.804273357000156],
            [52.578195549000185, 5.786915357000041],
            [52.577589549000066, 5.786073357],
            [52.573172549000077, 5.776923857000142],
            [52.572211049000117, 5.775041357000092],
            [52.571466549000064, 5.773606357000105],
            [52.570209549000054, 5.771862357000046],
            [52.569855049000211, 5.771133357000124],
            [52.569058549000118, 5.769489357000168],
            [52.567273049000192, 5.766998357000119],
            [52.563212549000212, 5.761304857000042],
            [52.560395549000106, 5.757350357000135],
            [52.559705049000087, 5.756010857000149],
            [52.557167049000128, 5.750951857000075],
            [52.556422549000075, 5.749517357000045],
            [52.553380549000195, 5.745295357000032],
            [52.552206549000203, 5.742864857000015],
            [52.551409549000169, 5.741214357000146],
            [52.548685549000112, 5.737433357000029],
            [52.548100549000054, 5.736221357000119],
            [52.541089549000191, 5.726490357000102],
            [52.540575049000182, 5.725425357000091],
            [52.539777549000092, 5.723775357],
            [52.529697549000076, 5.709780357000142],
            [52.528848549000173, 5.70802035700018],
            [52.512209549000119, 5.684918357000157],
            [52.511260049000072, 5.682981857000158],
            [52.509699549000203, 5.679779357000129],
            [52.508098049000097, 5.67748635700012],
            [52.506366549000091, 5.674961857000042],
            [52.498025549000175, 5.663289857000109],
            [52.496538549000064, 5.661187357000145],
            [52.495455049000128, 5.65894785700003],
            [52.494660549000201, 5.65730635700001],
            [52.493030049000112, 5.654993357000123],
            [52.48468454900015, 5.64332135700009],
            [52.483000549000195, 5.640904357000124],
            [52.481760549000086, 5.639136357000041],
            [52.481141049000172, 5.637980357000131],
            [52.4804265490001, 5.636628357],
            [52.479272549000171, 5.635024357000077],
            [52.478659549000184, 5.633864357000107],
            [52.477916549000184, 5.632432357000013],
            [52.465425549000059, 5.615056857000056],
            [52.464119549, 5.613218357],
            [52.463784549000167, 5.612522357000046],
            [52.321395549000187, 5.414582356999986],
            [52.316744549000106, 5.404916357000019],
            [52.313676549000121, 5.40064935700002],
            [52.310777549000107, 5.394724357000058],
            [52.30905754900013, 5.391361857000106],
            [52.308304549000042, 5.389915357000064],
            [52.307512549000165, 5.388277357000163],
            [52.285178549000108, 5.357202357000105],
            [52.284158549000182, 5.355081357000117],
            [52.278370549000016, 5.34702735700003],
            [52.217180549, 5.128350357000144],
            [52.216412549000069, 5.126753357000084],
            [52.215388549000153, 5.12319935700009],
            [52.212023049000123, 5.111419857000143],
            [52.208785549000055, 5.099986357000134],
            [52.208298549000148, 5.098973356999977],
            [52.206114549000091, 5.091375357000061],
            [52.205449549000065, 5.089211357000067],
            [52.204956549000059, 5.088185357000086],
            [52.204401549000153, 5.086407357000141],
            [52.203735549000186, 5.084234357000028],
            [52.203249549000105, 5.083222357000139],
            [52.202686549000163, 5.081416857000136],
            [52.201874049000054, 5.078935857000033],
            [52.201209549000083, 5.076772357000081],
            [52.199926549000139, 5.074102357000143],
            [52.199364049000252, 5.072283357000089],
            [52.198688549000138, 5.070059357000019],
            [52.196524549000145, 5.065551357000061],
            [52.19616754900008, 5.064275357],
            [52.192959549000221, 5.057593357000115],
            [52.192744549000196, 5.056826356999977],
            [52.19195354900009, 5.055155357000089],
            [52.190836549000068, 5.052806357000165],
            [52.190326549000048, 5.050981357000154],
            [52.188170549000091, 5.046491357],
            [52.18780954900015, 5.045197357000134],
            [52.184637549000087, 5.038591357000044],
            [52.184242549000174, 5.037386857000214],
            [52.183577549000034, 5.035215357000041],
            [52.165361549000153, 4.997265357000117],
            [52.165051549000196, 4.996155357000021],
            [52.163981549000113, 4.993925356999981],
            [52.163439549000117, 4.991986357000044],
            [52.16291254900014, 4.990889357000142],
            [52.162531549000136, 4.989522357000098],
            [52.156924549000024, 4.977839357000136],
            [52.156689549000106, 4.976998357000184],
            [52.145381549000177, 4.953431357000056],
            [52.145014549000194, 4.952118357000046],
            [52.144593549, 4.951239357000063],
            [52.139957549000115, 4.934627357000124],
            [52.139430549000195, 4.933528357000142],
            [52.139049549000021, 4.932163357],
            [52.13824754900017, 4.930485356999981],
            [52.137177549000199, 4.928247356999989],
            [52.135720549000013, 4.923026357000012],
            [51.884683048000198, 4.398944356000101],
            [51.883720048000185, 4.397184356000054],
            [51.879651548000055, 4.388939356000151],
            [51.878905548000176, 4.387503356000082],
            [51.878100548000106, 4.385835356000101],
            [51.877167048000189, 4.383993356000104],
            [51.876406048000121, 4.382506356000164],
            [51.875595048000065, 4.380820356000115],
            [51.874634548000103, 4.378935356000113],
            [51.873899548000139, 4.377515356000131],
            [51.873099548000113, 4.376396356],
            [51.867118048000151, 4.36399485600009],
            [51.86636354800018, 4.362535356000123],
            [51.865572048000132, 4.360891856000123],
            [51.864753548000095, 4.359196356000083],
            [51.863968548000088, 4.357566356000135],
            [51.862365548000156, 4.355324356000082],
            [51.861295548000186, 4.353202856000138],
            [51.860557048000061, 4.351776356000059],
            [51.859738548000138, 4.350080356000063],
            [51.858819548000184, 4.348263856000045],
            [51.858036048000116, 4.346729856000039],
            [51.857250548000167, 4.345099356000048],
            [51.856557548000097, 4.344129356000082],
            [51.853822548000124, 4.338420856000084],
            [51.853018548000108, 4.336764856000116],
            [51.852080048000147, 4.334920356000097],
            [51.851319048000192, 4.333443356000103],
            [51.850514048000178, 4.331775856000064],
            [51.849579048000095, 4.32993085600009],
            [51.842877548000075, 4.316028356000061],
            [51.841299548000194, 4.312742356000143],
            [51.839672048000097, 4.310411856000144],
            [51.83795304800006, 4.30790935600011],
            [51.836693548000113, 4.306104356000162],
            [51.836119548000028, 4.305018356000105],
            [51.835385548000062, 4.303599356000021],
            [51.834628548000154, 4.302540356000151],
            [51.830207048000119, 4.293398356000111],
            [51.829416548000182, 4.291890356000039],
            [51.828665548000146, 4.290353356000054],
            [51.827458548000124, 4.288663356000114],
            [51.826891548000191, 4.287594356000128],
            [51.826151548000126, 4.286166856000051],
            [51.825345548000172, 4.284491856000102],
            [51.824553548000182, 4.282843356000043],
            [51.823847548000032, 4.281854356000011],
            [51.820340548000132, 4.274514356000125],
            [51.817227548000034, 4.270158356000039],
            [51.816131548000129, 4.267863356000134],
            [51.815424548000152, 4.266874356000017],
            [51.811918548000136, 4.259535356000029],
            [51.809113548000056, 4.255610356],
            [51.808611548000073, 4.254559356000073],
            [51.8074805480002, 4.252976356000104],
            [51.800087548000107, 4.237634856000042],
            [51.799363048000117, 4.236268856000081],
            [51.798578548000052, 4.234640356000071],
            [51.785626548000067, 4.216512356000024],
            [51.785137048000166, 4.215500856000091],
            [51.784348048000169, 4.213864856000171],
            [51.783380048000055, 4.211959856000021],
            [51.782646548000145, 4.210541356000078],
            [51.781203548000207, 4.208411356000056],
            [51.779445548000155, 4.205766356000055],
            [51.777897548000141, 4.203415356000122],
            [51.77467554800009, 4.198795356000048],
            [51.774333548000214, 4.198077356000155],
            [51.772680548000125, 4.195673022666739],
            [51.771824548000183, 4.193899356000145],
            [51.770189548000104, 4.19156485600007],
            [51.753496548000186, 4.168145356000068],
            [51.747549548000194, 4.159816355999979],
            [51.746662048000104, 4.158039356000032],
            [51.745878548000206, 4.156481356000086],
            [51.744582548000182, 4.154666356000106],
            [51.744117548000162, 4.15380085600016],
            [51.743370548000172, 4.152342356000105],
            [51.742085548, 4.150543356000114],
            [51.741599048000154, 4.14964185600013],
            [51.74086554800013, 4.148224356000085],
            [51.736822548000106, 4.142517356000127],
            [51.73510704800006, 4.14003285600009],
            [51.730417548000133, 4.133427356000041],
            [51.729946548000072, 4.132441356000172],
            [51.728736548000114, 4.130745356000091],
            [51.728341548000145, 4.129918356000047],
            [51.719628548000145, 4.117716356000045],
            [51.718989548000224, 4.116469856000109],
            [51.717201548000133, 4.112969356000121],
            [51.715714548000136, 4.110004356000019],
            [51.714529548000058, 4.108344356000075],
            [51.714092048000083, 4.107442856000091],
            [51.713308548000015, 4.105815356000164],
            [51.711658548000202, 4.103466856000097],
            [51.710004548000114, 4.101042356000121],
            [51.708459048000151, 4.098695356000093],
            [51.699653548000043, 4.086249356000152],
            [51.698909548000103, 4.08480835600011],
            [51.6973570480001, 4.081685856000121],
            [51.696360548000172, 4.079760856],
            [51.695671548000092, 4.078463356000128],
            [51.694444548, 4.076744356],
            [51.693265548000142, 4.074274356],
            [51.691615548000044, 4.071924856000024],
            [51.688567548000179, 4.067618356000139],
            [51.687352548000121, 4.065070356000078],
            [51.682955548000081, 4.058911356000024],
            [51.682345548000086, 4.05763235600007],
            [51.662575548000149, 4.029932356000018],
            [51.662139548000113, 4.029137856000091],
            [51.661401048000101, 4.027711856000153],
            [51.660603548000125, 4.026044356000114],
            [51.659158548000136, 4.023911356000013],
            [51.655735548000251, 4.018905856000103],
            [51.65395704800008, 4.016268856000053],
            [51.652679548000066, 4.014434356000152],
            [51.652286548000149, 4.013610356000186],
            [51.649231548000074, 4.009329356000094],
            [51.648881548000219, 4.008594356000117],
            [51.646067548000104, 4.004651356000011],
            [51.645398548000145, 4.003371356000144],
            [51.643860548000106, 4.000275856000172],
            [51.643069548000113, 3.998621356000157],
            [51.637962548000161, 3.991464356000037],
            [51.635441548000102, 3.98618385600011],
            [51.633846548000207, 3.982843356000032],
            [51.609719548000129, 3.949027356000116],
            [51.608629548000152, 3.946858856000105],
            [51.607854548, 3.945380856000114],
            [51.606927548000129, 3.943586856000181],
            [51.606195548000215, 3.942170356000133],
            [51.598301548000137, 3.931103356000065],
            [51.584536548000216, 3.902221356],
            [51.567990548000154, 3.879021356000138],
            [51.563598548000101, 3.869802356000122],
            [51.561914214666757, 3.867392022666749],
            [51.560919048000102, 3.865441856000018],
            [51.560178548000096, 3.86401385600017],
            [51.559387548000046, 3.862361356000136],
            [51.556626548000196, 3.858489356000035],
            [51.556067048000074, 3.85732385600015],
            [51.555278548000132, 3.85567835600007],
            [51.553668548000161, 3.853355356000151],
            [51.553145548000117, 3.852554356000141],
            [51.552671548000177, 3.85156035600005],
            [51.551467548000034, 3.849871356],
            [51.550905548000145, 3.848806856000124],
            [51.550160048000095, 3.847360856000122],
            [51.549363548000116, 3.845694356],
            [51.547719048000118, 3.843348856000077],
            [51.544885548000053, 3.839337356000087],
            [51.543553548000062, 3.836540356000029],
            [51.541972548000189, 3.834236856000032],
            [51.540558548000064, 3.832167356000141],
            [51.540076048000202, 3.831273356000153],
            [51.539323048000171, 3.829827856],
            [51.538377048000228, 3.827971356000148],
            [51.537638548000047, 3.826536356000076],
            [51.53603354800012, 3.824285356000075],
            [51.535112548000058, 3.822365855000058],
            [51.534309548000209, 3.820710355000131],
            [51.533363048000155, 3.818853855000086],
            [51.532625548000141, 3.81741935499997],
            [51.518403548000066, 3.797454688333431],
            [51.517599548000163, 3.795781355000116],
            [51.180449048000099, 3.322298355000171],
            [51.17818954800012, 3.319221355000039],
            [51.175512548000057, 3.316582355],
            [51.163196548000116, 3.29926035500003],
            [51.159905548000125, 3.29601635500012],
            [51.149669548000162, 3.281674355000064],
            [51.071430548000222, 3.171639355],
            [51.062386548000205, 3.152572355000061],
            [51.047949548000105, 3.132251355],
            [51.046542048000077, 3.129294855000083],
            [51.045589548000152, 3.127418855000116],
            [51.044834548000125, 3.125960855000102],
            [51.044030048000167, 3.124296355000041],
            [51.043234048000187, 3.122648855000065],
            [51.042281048000149, 3.120772855000098],
            [51.041528048000174, 3.119316855000065],
            [51.040574548000137, 3.117438355000061],
            [51.039819548000111, 3.115983855000067],
            [51.039030048000171, 3.114346855000065],
            [51.038071048000035, 3.112450855000134],
            [51.037311048000134, 3.110985355000111],
            [51.036513548000158, 3.109335855000054],
            [51.035566048000106, 3.107471355000072],
            [51.034832548000139, 3.10604335500004],
            [51.024837548000022, 3.091955355000124],
            [51.020627881333411, 3.086007688333396],
            [51.019791048000144, 3.084381855000103],
            [51.018952048000159, 3.082664355000105],
            [51.01807104800011, 3.080938355000114],
            [51.016307048000073, 3.077447855000145],
            [51.014784548000108, 3.074382355],
            [51.013994548000056, 3.072724355000034],
            [51.012344048000074, 3.070365355000064],
            [51.011051548000097, 3.068510355000058],
            [51.010689548000158, 3.067746355],
            [51.009011214666856, 3.065340688333421],
            [51.00800954800016, 3.06335835500002],
            [51.007283548000061, 3.061950354999965],
            [51.002367547000205, 3.055027355000021],
            [50.998954047000183, 3.04783485500009],
            [50.997987547000065, 3.045928855000128],
            [50.997261547000136, 3.044520355000017],
            [50.995816547000203, 3.04237535500009],
            [50.994822547000041, 3.04086635500002],
            [50.983903547000153, 3.017848355000027],
            [50.982965547000077, 3.016001855000013],
            [50.982225547000127, 3.014554354999973],
            [50.980773547000041, 3.012404355000072],
            [50.978965880333504, 3.009678021666758],
            [50.977947047000129, 3.007659855],
            [50.977199547000083, 3.006215355000023],
            [50.976389047000083, 3.004538355000093],
            [50.975612547, 3.002920355000072],
            [50.972353047000041, 2.998227855000124],
            [50.970749547000167, 2.995765355000032],
            [50.969032547000012, 2.99324535500007],
            [50.96793154700012, 2.991039855000025],
            [50.967198547000152, 2.989612355000034],
            [50.96574654700018, 2.987462355000048],
            [50.961265547000067, 2.981046355000046],
            [50.960384047000133, 2.97932935500009],
            [50.959686547000132, 2.978001355000075],
            [50.958454547, 2.976264355000069],
            [50.958085547000024, 2.975487355000084],
            [50.927832547000122, 2.932863355000137],
            [50.927081047000087, 2.931319355000099],
            [50.926319047000135, 2.929764355000145],
            [50.925395047000137, 2.927927855000149],
            [50.924634547000011, 2.926420355000033],
            [50.920541047000171, 2.920643855000108],
            [50.920047547000166, 2.919939355000167],
            [50.917050547000173, 2.913729855000028],
            [50.916318547000202, 2.912303355000034],
            [50.914667047000165, 2.909941855000042],
            [50.913387547000156, 2.908104355000063],
            [50.91301354700002, 2.907315355000151],
            [50.911354047, 2.904945855000022],
            [50.910092547000119, 2.90313735500014],
            [50.897831047000039, 2.877279855000126],
            [50.896251047000163, 2.873992855000111],
            [50.895269047000198, 2.872078855000026],
            [50.894544547000152, 2.870711855000167],
            [50.893769547000176, 2.869094355000087],
            [50.892563547000151, 2.867395355000113],
            [50.892168547000068, 2.866560355000033],
            [50.887703547000029, 2.860268355000173],
            [50.887159547000039, 2.859119355000047],
            [50.88157554700004, 2.85124835500001],
            [50.8762210470002, 2.839949855000157],
            [50.875266547000109, 2.838065355000111],
            [50.874534547000195, 2.836639355000159],
            [50.873274547000136, 2.834862355000027],
            [50.872908047000095, 2.834102855000069],
            [50.871953547000174, 2.832228855000096],
            [50.871228547000129, 2.830821355000168],
            [50.869618547000158, 2.828485855000011],
            [50.533702547000104, 2.354437854000039],
            [50.515559547000151, 2.328868354000051],
            [50.513262547000153, 2.326595354000105],
            [50.511361547000064, 2.323910354],
            [50.505399547000167, 2.318009353999969],
            [50.502798047000084, 2.314446854000082],
            [50.500568547000199, 2.31140935400002],
            [50.497278547000207, 2.308153354000098],
            [50.493071547000028, 2.30220935400007],
            [50.483895547000117, 2.293119354000069],
            [50.47357604700008, 2.282858354000084],
            [50.467379047000151, 2.276646354],
            [50.466585547000165, 2.275822354000027],
            [50.46468954700012, 2.273142354000157],
            [50.460292547000194, 2.268747354000041],
            [50.44695854700015, 2.25550535400005],
            [50.446501547000167, 2.25485935400016],
            [50.393023547000126, 2.201913354000055],
            [50.392233547000131, 2.200796354],
            [50.382060547000123, 2.190723354000028],
            [50.379843547000092, 2.187588354000169],
            [50.377198547000177, 2.184969354],
            [50.373143547000126, 2.179236354000125],
            [50.371934547000109, 2.178038354000122],
            [50.36984554700021, 2.175085354000032],
            [50.365445547000121, 2.170728354000047],
            [50.361180047000147, 2.164810854000081],
            [50.358951547000146, 2.161774354000016],
            [50.357812547000066, 2.160645354000039],
            [50.355389047000159, 2.157331354000021],
            [50.353152547000121, 2.154280354000093],
            [50.347821547000109, 2.149001354000021],
            [50.345656547000118, 2.145939354000063],
            [50.342714547000156, 2.143025354000017],
            [50.34066154700011, 2.140122353999985],
            [50.340109547000083, 2.139575354000016],
            [50.338160547000115, 2.136819354000068],
            [50.331274547000049, 2.129999354000134],
            [50.303173547000114, 2.09025435400001],
            [50.297431547000116, 2.084544354000073],
            [50.292794547000057, 2.07992835400006],
            [50.29067954700011, 2.076936354000125],
            [50.278645047000083, 2.064988854000035],
            [50.275023547000131, 2.061320854000115],
            [50.27031054700015, 2.056588854000097],
            [50.238784547000108, 2.025344353999969],
            [50.234113547000078, 2.018736354000069],
            [50.231799547000122, 2.01644335400006],
            [50.229915547000104, 2.013778354000095],
            [50.227247547000076, 2.011133354000094],
            [50.223220547000125, 2.005435354000085],
            [50.22036304700012, 2.002584854000034],
            [50.219544547000083, 2.001755354000068],
            [50.217420547000103, 1.99874935400004],
            [50.212062047000217, 1.993423854000028],
            [50.187405047000112, 1.968944353999987],
            [50.179106547000146, 1.960691354000048],
            [50.176646547000104, 1.957210353999983],
            [50.172583547000187, 1.953183354000032],
            [50.170568547000158, 1.950406854000065],
            [50.168250547000156, 1.947201354000057],
            [50.158274047000049, 1.937304354000077],
            [50.14723354700007, 1.926277854000077],
            [50.145833547000137, 1.924816354000029],
            [50.143837047000062, 1.922085854000059],
            [50.141565547000056, 1.918967354000145],
            [50.13927154700005, 1.91669235400002],
            [50.137367547000082, 1.91399735400006],
            [50.13515554700021, 1.911805353999966],
            [50.128984047000159, 1.903104854000048],
            [50.126523547000176, 1.899719354000055],
            [50.122472547000172, 1.894046354000167],
            [50.121001547000077, 1.892588354000054],
            [50.114974547, 1.884056354000037],
            [50.112076547000044, 1.881182354],
            [50.109975547000118, 1.878209354000049],
            [50.109367547000062, 1.87760635399998],
            [50.107474547000123, 1.874925354000112],
            [50.099759547000104, 1.867276354000055],
            [50.097481547000172, 1.86404935400013],
            [50.094394547000121, 1.860988353999986],
            [50.092482547000117, 1.858282354000096],
            [50.091500547000038, 1.857308354000153],
            [50.087486547000168, 1.851625353999964],
            [50.082163547000022, 1.846314854000042],
            [50.074312047000177, 1.838446354000112],
            [50.071563547000125, 1.83567035400003],
            [50.069797547000093, 1.833210354000059],
            [50.067496547000161, 1.829993354000067],
            [50.066178547000192, 1.828686354000013],
            [50.064198547000132, 1.825882354],
            [50.063492547000152, 1.825182354],
            [50.061373047000103, 1.822276354000067],
            [50.054999547000165, 1.813346353999989],
            [50.054425547000193, 1.812776354000079],
            [50.052502547000159, 1.810052354000021],
            [50.050378547000179, 1.807946354000123],
            [50.048300547000196, 1.805003354000064],
            [50.045953547000096, 1.802675354],
            [50.044101547000224, 1.800053354000042],
            [50.041188547000189, 1.79716335400002],
            [50.039106547000216, 1.794215354000173],
            [50.036440547000069, 1.791570353999987],
            [50.034207547000136, 1.788408354000026],
            [50.031977547000025, 1.786196353999969],
            [50.030008547000165, 1.783408354000031],
            [50.025274547, 1.778712354000064],
            [50.02320304700018, 1.775824854000064],
            [50.020914547000217, 1.772630354000171],
            [50.012535047000171, 1.764311854000056],
            [50.010973547000134, 1.762756354000047],
            [50.009118547000213, 1.760129354000028],
            [49.999790047000118, 1.750805354000022],
            [49.993511547000111, 1.744507354000021],
            [49.991629547000088, 1.741840353999976],
            [49.985503547000093, 1.735733854],
            [49.98289954700013, 1.733121354000033],
            [49.980835547000055, 1.730196354000043],
            [49.979735547000104, 1.729062354],
            [49.957951547000135, 1.707407354000168],
            [49.95584654700022, 1.704425354],
            [49.952256547000133, 1.700817354000066],
            [49.880571546000141, 1.62963535300004],
            [49.877588546000055, 1.625407353000071],
            [49.875954546000031, 1.623786353000156],
            [49.874190546000108, 1.621285353000076],
            [49.728658546000105, 1.476802353000025],
            [49.726767546000161, 1.474120353000075],
            [49.710216546000112, 1.45768335300005],
            [49.705975546000133, 1.451668353000102],
            [49.696139546000126, 1.441898353000056],
            [49.691881546000076, 1.435860353000066],
            [49.672972546000068, 1.417078353],
            [49.670733046000095, 1.414011353000078],
            [49.668275046000105, 1.410726853000099],
            [49.665991546000129, 1.407579353000088],
            [49.662325546000119, 1.403923853000052],
            [49.655141546000067, 1.396773353000029],
            [49.649300546000205, 1.388487353000031],
            [49.636760546000147, 1.376029352999979],
            [49.634189046000103, 1.372473853000045],
            [49.63190554600007, 1.369326353000133],
            [49.618677546000157, 1.35618435300006],
            [49.616813546000031, 1.353539352999974],
            [49.615676046000118, 1.352382353000081],
            [49.614436046000122, 1.351114853000098],
            [49.613017546000179, 1.349696353000056],
            [49.611013546000123, 1.346853353000014],
            [49.608918546000126, 1.344770353000058],
            [49.546024546000098, 1.282274353],
            [49.545244546000134, 1.281166353000174],
            [49.541041546000116, 1.276989352999976],
            [49.529349546000191, 1.260393353000026],
            [49.528219046000089, 1.259241353000107],
            [49.524507546000024, 1.255500353000016],
            [49.509574046000154, 1.240586853000096],
            [49.497913546000206, 1.228948353000092],
            [49.495853879333453, 1.226091686333405],
            [49.470361046000079, 1.200667853000056],
            [49.467446046000106, 1.197705352999989],
            [49.456919546000023, 1.18721135300008],
            [49.450832046000215, 1.178673853000134],
            [49.448372546000115, 1.175354353000017],
            [49.444888546000044, 1.171843353000042],
            [49.44108654600015, 1.166444352999989],
            [49.437315546000065, 1.162695353000132],
            [49.42913154600015, 1.151180019666668],
            [49.427659546000058, 1.149709353],
            [49.407794546000076, 1.121499353000033],
            [49.406968546000115, 1.120677353000048],
            [49.401093546000112, 1.112334353000037],
            [49.400545546000075, 1.111789353000063],
            [49.398451046000133, 1.108876353],
            [49.394396546000138, 1.103179352999987],
            [49.388916546000047, 1.097730352999974],
            [49.386899546000137, 1.094865353000074],
            [49.385760546000114, 1.093706353],
            [49.382445546000127, 1.090382353000052],
            [49.378601546000112, 1.084923353],
            [49.3773735460002, 1.083702353000149],
            [49.375302546000199, 1.080760353000088],
            [49.370640546000089, 1.076124353000012],
            [49.368606546000109, 1.073234352999989],
            [49.367776546000101, 1.072409353000126],
            [49.361905546000145, 1.064069353],
            [49.359904546000138, 1.062080353000013],
            [49.357805546000037, 1.05909835300011],
            [49.347646546000107, 1.048950103000124],
            [49.265203546000151, 0.966927353000102],
            [49.264544546000167, 0.965991353000106],
            [49.258888546000065, 0.960364353000017],
            [49.256948546000132, 0.957607353000071],
            [49.255047546000156, 0.955716353000113],
            [49.251151546000216, 0.95017935300001],
            [49.24820554600015, 0.947248353000063],
            [49.24615154600022, 0.944330353000026],
            [49.238198546000064, 0.936416352999984],
            [49.236153546000054, 0.933510352999974],
            [49.171489546000117, 0.869165353000056],
            [49.145384546000145, 0.832056352999984],
            [48.734087045000109, 0.422463352000065],
            [48.732899545000038, 0.421312352000044],
            [48.639388545, 0.356094352000014],
            [48.245879545000122, -0.036370648],
            [48.243152545000072, -0.03827464799997],
            [48.238386545000168, -0.043030647999842],
            [48.23584554500016, -0.044805647999979],
            [48.227596545000125, -0.053037647999957],
            [48.221868545000035, -0.057037647999891],
            [48.219203545000056, -0.059696647999985],
            [48.217050545000092, -0.061200648],
            [48.164347545000197, -0.113797647999903],
            [48.158934545000221, -0.117578647999935],
            [48.15185754500007, -0.124641648],
            [48.137864545000213, -0.134415648000015],
            [48.134374545000099, -0.137899647999973],
            [47.65871554400016, -0.470317647999963],
            [47.657780544000133, -0.470752647999987],
            [47.573808544000059, -0.529470649],
            [47.572155044000198, -0.530249648999884],
            [47.569017044000162, -0.531766148999864],
            [47.567155544000144, -0.532688148999938],
            [47.565584544000188, -0.533466648999962],
            [47.554678544000041, -0.538580648999968],
            [47.5404485440001, -0.548532648999966],
            [47.538871544000102, -0.549294648999961],
            [47.514231544000182, -0.560790648999898],
            [47.497194544000166, -0.572706648999954],
            [47.4955320440001, -0.573488648999913],
            [47.480596544000065, -0.580448648999976],
            [47.471230544000122, -0.586954648999964],
            [47.468923544000205, -0.588522649000026],
            [47.451863544000076, -0.596462648999818],
            [47.407288544000181, -0.627642648999952],
            [47.376145544000082, -0.642140648999856],
            [47.373125544000146, -0.644253648999893],
            [47.361940544000078, -0.649459648999965],
            [47.359541544000166, -0.651069149],
            [47.357274544000205, -0.652570648999983],
            [47.354840544000155, -0.654257648999845],
            [47.353185044000014, -0.655038148999949],
            [47.351765544000074, -0.655760148999917],
            [47.349889544000149, -0.656695148999916],
            [47.348067544000202, -0.657552648999982],
            [47.337360544000063, -0.665043648999841],
            [47.335825544000073, -0.665797148999928],
            [47.334112044000136, -0.666667648999919],
            [47.332499544000115, -0.667484648999931],
            [47.330701044000165, -0.668362148999975],
            [47.32951754400014, -0.668921648999898],
            [47.328969544000159, -0.669282148999983],
            [47.312382544000087, -0.680863648999974],
            [47.285493544000161, -0.693383648999912],
            [47.272421544000196, -0.702529648999928],
            [47.270766044000112, -0.703310148999947],
            [47.263891544000074, -0.706520649000012],
            [47.262221544000198, -0.707639648999873],
            [47.259936544000055, -0.709189648999953],
            [47.256901044000045, -0.710663648999969],
            [47.254286544000109, -0.711942649],
            [47.249943544000217, -0.714980648999841],
            [47.248416044000095, -0.715731649],
            [47.246740044000063, -0.71657364899994],
            [47.245119544000119, -0.717388648999957],
            [47.243113544000067, -0.718362648999985],
            [47.2408545440002, -0.719943648999973],
            [47.239361544000218, -0.720638649],
            [47.230762544000157, -0.726655648999852],
            [47.222564544000164, -0.730472649000021],
            [47.214976544000109, -0.735782648999987],
            [47.2134490440001, -0.736533148999911],
            [47.212889544000092, -0.736832648999908],
            [47.210591044000154, -0.738397148999951],
            [47.208284544, -0.739966648999868],
            [47.207054544000101, -0.740539649000027],
            [47.189657544000028, -0.752632648999906],
            [47.187232544000125, -0.754209648999904],
            [47.184943044000164, -0.755757148999876],
            [47.182508544000058, -0.75744664899986],
            [47.180720544000195, -0.758290982333335],
            [47.178312544000079, -0.759922649],
            [47.176974544000103, -0.760607648999923],
            [47.176035544000143, -0.761106649],
            [47.1741165440001, -0.762449648999919],
            [47.172549544000077, -0.763221648999945],
            [47.161725544000063, -0.770769648999988],
            [47.160062044000114, -0.771553148999971],
            [47.156999544000115, -0.772987649],
            [47.154133544000132, -0.774993648999981],
            [47.152603544000073, -0.775722648999903],
            [47.151760544000155, -0.776131649],
            [47.147536544000076, -0.779088648999959],
            [47.117191544, -0.79322164899996],
            [47.09310454400017, -0.810013148999914],
            [47.074201544000147, -0.823177648999973],
            [47.073385544000217, -0.823558648999878],
            [47.066807544000056, -0.828162648999964],
            [47.06527904400005, -0.828913648999929],
            [47.061731544000082, -0.830654315666649],
            [47.059214544000127, -0.832375649000028],
            [47.057691544000221, -0.833103148999925],
            [47.037122544000084, -0.842702648999975],
            [47.021046544000086, -0.853954648999888],
            [47.019312544000144, -0.854777148999915],
            [46.96245054400012, -0.881283648999982],
            [46.960996544000096, -0.882301648999828],
            [46.938107544000019, -0.892966648999945],
            [46.934418544000067, -0.895548648999878],
            [46.927229544000141, -0.898897648999863],
            [46.926025544000169, -0.89974064899998],
            [46.924368544000089, -0.900522648999939],
            [46.922947544000152, -0.901245148999863],
            [46.921191544000152, -0.902152648999987],
            [46.920647544000161, -0.902444648999889],
            [46.887655544000125, -0.925540648999856],
            [46.708589544000034, -1.0089956489999],
            [46.680408543000141, -1.028729648999871],
            [46.678557543000096, -1.029592648999866],
            [46.676310543000085, -1.031166648999985],
            [46.667398543000076, -1.035320648999871],
            [46.649529543000057, -1.047834649000023],
            [46.648168043000197, -1.048525648999913],
            [46.64188954300019, -1.051508648999899],
            [46.64116854300002, -1.0519981489999],
            [46.638736543000192, -1.053685648999902],
            [46.630003543000129, -1.057757648999939],
            [46.603758543000112, -1.076137648999875],
            [46.598040543000053, -1.078803648999838],
            [46.579612543000138, -1.091710648999822],
            [46.577295043000134, -1.094000149],
            [46.571091543000165, -1.100177648999974],
            [46.532008543000103, -1.127551648999869],
            [46.530299543000098, -1.129262648999955],
            [46.527480543000166, -1.131237648999942],
            [46.498769043000181, -1.159895148999908],
            [46.497703543000199, -1.160891648999936],
            [46.461522543000086, -1.186235648999912],
            [46.446496543000109, -1.201267148999918],
            [46.441909543000094, -1.205853648999877],
            [46.433180543000134, -1.21196764900003],
            [46.361453543000181, -1.283728148999955],
            [46.360208543000141, -1.28495864899989],
            [46.35712154300009, -1.287120648999988],
            [46.275613543000105, -1.368666649],
            [46.274401543000096, -1.369852648999981],
            [46.252289543000103, -1.38534664899997],
            [46.233066543000206, -1.404546148999941],
            [46.231901543000191, -1.405672648999982],
            [46.220507543000082, -1.413656648999861],
            [46.168593543000185, -1.465616648999912],
            [46.162925543000171, -1.469588648999931],
            [46.161895543000043, -1.470619648999971],
            [46.097680543000052, -1.515620648999956],
            [46.08315454300012, -1.530149148999982],
            [46.081892543000066, -1.531400648999977],
            [45.980920543000167, -1.602167649999942],
            [45.954710043000176, -1.62838315],
            [45.952684543000174, -1.630387649999946],
            [45.899651543000147, -1.667560649999984],
            [45.893961043000132, -1.67325115],
            [45.89188154300021, -1.675327649999943],
            [45.88367154300002, -1.681081649999967],
            [45.88311654300017, -1.681618649999905],
            [45.881878543, -1.682839649999934],
            [45.873564543000072, -1.688667649999957],
            [45.870639043000125, -1.691584649999911],
            [45.869375543000132, -1.69283765],
            [45.855222043000055, -1.702772649999943],
            [45.844198543000203, -1.710514649999965],
            [45.843574543000187, -1.71113865],
            [45.837969543000128, -1.71506765],
            [45.832773543000172, -1.72027065],
            [45.829739543000159, -1.722396649999965],
            [45.826870543000183, -1.72526865],
            [45.824217543000117, -1.727128649999983],
            [45.821872543000183, -1.729476649999924],
            [45.818811543000123, -1.731621649999937],
            [45.817815543000137, -1.73261965],
            [45.815929543000067, -1.735315649999961],
            [45.803440543000107, -1.74782065],
            [45.80172554300006, -1.750271649999931],
            [45.800353543000114, -1.751645649999929],
            [45.798424543000039, -1.754403649999858],
            [45.794630543000068, -1.758205649999937],
            [45.792839543000042, -1.76000165],
            [45.790921543000167, -1.762744649999973],
            [45.779278543000117, -1.77440165],
            [45.777514543000194, -1.77692265],
            [45.773040543000121, -1.78140265],
            [45.76920954300013, -1.786880649999929],
            [45.766860543000092, -1.789232650000017],
            [45.765007543000166, -1.79188165],
            [45.76215204300027, -1.794760149999959],
            [45.756705543000095, -1.800233649999953],
            [45.750392543, -1.80655465],
            [45.748402543000026, -1.809399649999932],
            [45.746686543000152, -1.811118649999969],
            [45.744998543000094, -1.813532649999956],
            [45.74376854300013, -1.814763650000017],
            [45.741696543000018, -1.817725649999957],
            [45.74046654300011, -1.81895765],
            [45.738395543000166, -1.82191964999987],
            [45.726883543000071, -1.833445649999987],
            [45.725091543000218, -1.836007649999871],
            [45.72274254300001, -1.838359649999973],
            [45.720889543000084, -1.841009649999847],
            [45.713390043000146, -1.848519149999916],
            [45.711037543, -1.850876650000018],
            [45.709184543000077, -1.853526649999893],
            [45.707649043000202, -1.855092649999975],
            [45.699194543000118, -1.863585649999962],
            [45.697475543000081, -1.866043649999938],
            [45.695398543000096, -1.86812465],
            [45.693376543000142, -1.871014649999836],
            [45.692343543000021, -1.872049649999852],
            [45.690773543000063, -1.874293649999984],
            [45.681430043, -1.883717649999909],
            [45.666639543, -1.898594649999907],
            [45.664964543000139, -1.900989649999829],
            [45.663519543000035, -1.902436650000013],
            [45.661662543000119, -1.905091649999946],
            [45.660090543000109, -1.906665649999979],
            [45.65825854300013, -1.909286649999942],
            [45.654450543000081, -1.913098649999867],
            [45.652456543000113, -1.91594964999986],
            [45.636132543000116, -1.93229465],
            [45.634149542000188, -1.93512965],
            [45.633587542000129, -1.935693649999948],
            [45.631645542000143, -1.938469649999959],
            [45.620461542000072, -1.949677149999928],
            [45.617060542000132, -1.95309065],
            [45.613338542000093, -1.958413649999983],
            [45.601060542000113, -1.970706649999968],
            [45.599133542000089, -1.973462649999917],
            [45.593347542000089, -1.979256649999968],
            [45.59140304200011, -1.981939649999916],
            [45.589126542000173, -1.985097649999901],
            [45.574904542000098, -1.999337649999958],
            [45.573219542000203, -2.001746649999973],
            [45.566325042000159, -2.008696649999919],
            [45.559077542000097, -2.016000649999938],
            [45.557412542000094, -2.018381650000038],
            [45.55125854200017, -2.024564149999989],
            [45.531623542000062, -2.044245649999965],
            [45.529792042000196, -2.046818649999878],
            [45.527498542000075, -2.050050649999946],
            [45.521890542000136, -2.055665650000023],
            [45.519995542000203, -2.058375649999888],
            [45.515261542000104, -2.063116649999941],
            [45.511591542000104, -2.068364650000021],
            [45.508699542000073, -2.071264649999989],
            [45.502670542000061, -2.077305649999957],
            [45.500786542000156, -2.07999965],
            [45.496635542000064, -2.084155649999886],
            [45.494882542000056, -2.08666365],
            [45.493742542000035, -2.087829649999946],
            [45.489156542000131, -2.092446649999857],
            [45.487378542000016, -2.094988649999863],
            [45.485282042000193, -2.097089149999931],
            [45.476799542, -2.105584649999955],
            [45.470772542000219, -2.11420364999988],
            [45.438323542000063, -2.146696650000024],
            [45.436554542000152, -2.149226649999932],
            [45.253696542000199, -2.332335649999976],
            [45.065429542000146, -2.60154465],
            [45.063106042000157, -2.60390365],
            [45.021073542000153, -2.646028651],
            [45.002091542000045, -2.673169650999981],
            [45.000898542000101, -2.674366150999944],
            [44.99921554200003, -2.676053650999947],
            [44.997189542000086, -2.678950650999923],
            [44.926834542000194, -2.749402650999855],
            [44.871209542000116, -2.828930650999965],
            [44.869275042000169, -2.830896150999877],
            [44.867522542000216, -2.832678651],
            [44.862002542000113, -2.840571651],
            [44.859110542000082, -2.843470650999976],
            [44.855869542000079, -2.846720650999941],
            [44.851997542000078, -2.852255650999965],
            [44.846724542000146, -2.857536651000018],
            [44.844592542000129, -2.860584650999968],
            [44.837941542000152, -2.867244651],
            [44.836187542000147, -2.869751650999945],
            [44.828056542000098, -2.877893651000022],
            [44.806965542000199, -2.908046650999907],
            [44.805861542000031, -2.909192650999969],
            [44.794415542000166, -2.920693650999965],
            [44.788654542000103, -2.928930651],
            [44.787446542000083, -2.930139650999934],
            [44.783402542000118, -2.935823984333297],
            [44.778668542000133, -2.940580650999891],
            [44.776947542000102, -2.943041650999945],
            [44.77405554200007, -2.945941150999957],
            [44.771726542000039, -2.948277651000012],
            [44.769541542000098, -2.951400650999858],
            [44.758267542000141, -2.962689650999906],
            [44.754533542000075, -2.968027650999971],
            [44.744240542000199, -2.978333650999957],
            [44.738623542000056, -2.986363651],
            [44.720594542000214, -3.004415650999988],
            [44.707001542000199, -3.023847650999926],
            [44.695973542000189, -3.034889650999872],
            [44.694394542000083, -3.037147650999827],
            [44.693600542000041, -3.037941650999983],
            [44.693071042000071, -3.038645650999882],
            [44.666070542000199, -3.077188650999929],
            [44.66494954200013, -3.078344150999882],
            [44.658597542000138, -3.084736650999886],
            [44.656867542000072, -3.08721065099995],
            [44.653218042000077, -3.090887150999947],
            [44.643638542000105, -3.100500651],
            [44.636052542000101, -3.11134465100001],
            [44.633568542000063, -3.113838650999867],
            [44.598265542000064, -3.149191650999938],
            [44.579079875333406, -3.176525984333367],
            [44.577561542000041, -3.178071650999925],
            [44.571544875333444, -3.186559650999953],
            [44.563728542000177, -3.194386650999988],
            [44.555999541000091, -3.205433651],
            [44.55391254100013, -3.207530150999915],
            [44.528050541000113, -3.23342865099994],
            [44.519211541000089, -3.252389650999987],
            [44.518735541000154, -3.25307065100003],
            [44.518310541000147, -3.253980651],
            [44.278013541000206, -3.606232650999885],
            [43.191495540000147, -2.841504651000022],
            [42.106690539000084, -2.07586864999989],
            [42.0756595390001, -2.054833649999921],
            [41.940962539000083, -1.963611649999919],
            [41.609655539000101, -1.715538649999985],
            [41.608704539000115, -1.714766649999973],
            [41.591574539000106, -1.701264649999956],
            [41.585349539000191, -1.696264649999975],
            [41.579375539000097, -1.694221649999861],
            [41.560135078139098, -1.672981193209026],
            [41.558154227735855, -1.674872846622847],
            [41.555273211974253, -1.592217824023834],
            [41.381382248210656, -1.364717883781097],
            [41.153882307967734, -1.068055004568976],
            [40.998327092727919, -0.866108972608529],
            [40.988609114159118, 0.000000125728491],
            [40.988609114159118, 1.313953976704596],
            [40.988609114159118, 2.309300120065487],
            [40.988609114159118, 2.716664135929477],
            [40.991354187456778, 2.835535951819594],
            [41.28638226026888, 3.118336001331201],
            [41.336936200670294, 3.167500054544092],
            [41.797773238543613, 3.824718043568168],
            [41.896436118183175, 3.96425393305249],
            [41.905163355793974, 3.980318018171047],
            [41.938745115683986, 4.022364159187575],
            [41.937845066918527, 4.052708995700357],
            [41.959436179006531, 4.088473067907387],
            [42.040836193732019, 4.142782100941744],
            [42.073882182369601, 4.176145093158468],
            [42.098327164858773, 4.186664214034863],
            [42.117773180280068, 4.193891090972897],
            [42.151663226569866, 4.203335987135489],
            [42.195827139248507, 4.208891177299634],
            [42.236109224917726, 4.20777319905099],
            [42.269854096644082, 4.203745024011596],
            [42.370273320280006, 4.202082054416948],
            [42.401382251088449, 4.207500116644695],
            [42.485836128425348, 4.228891068884266],
            [42.622291165569919, 4.260418089024313],
            [42.656109127492044, 4.264718005365253],
            [42.726527172646371, 4.271191181567687],
            [42.740827202417876, 4.272782066794889],
            [42.768609188208501, 4.278336083492462],
            [42.831663228488452, 4.29528211246577],
            [42.855691295112393, 4.304718123810858],
            [42.876663319830215, 4.318336034300074],
            [42.919991221483741, 4.364026962103381],
            [42.933609131972872, 4.388745026998819],
            [42.946518101089538, 4.446109096111158],
            [42.957218103436986, 4.481109074022527],
            [42.968882192290522, 4.504309008610534],
            [42.985691260965524, 4.52541799362649],
            [43.080827203377027, 4.604164130161152],
            [43.158191164074168, 4.666391044233364],
            [43.193600178861658, 4.687782164111098],
            [43.321663254523543, 4.748609132710953],
            [43.358891142305168, 4.765273194460647],
            [43.490554077752876, 4.822218000775095],
            [43.567773199162644, 4.852500140651813],
            [43.587218208755615, 4.860000099996142],
            [43.68639121102396, 4.891945041829615],
            [43.737354188301396, 4.900553926967092],
            [43.767491153614884, 4.90333604827677],
            [43.798054087248858, 4.905691027799634],
            [43.833054065160212, 4.910282131457677],
            [43.855827193237388, 4.913891043699081],
            [43.887218258907438, 4.92278206369825],
            [43.922218236818793, 4.934444979085214],
            [43.95985415564806, 4.951391175696514],
            [43.981945164442919, 4.963053923445486],
            [44.05902715791629, 4.958609000179152],
            [44.221663237340607, 4.948335971980541],
            [44.290273305974864, 4.944164131120488],
            [44.466900290580639, 4.933526993046925],
            [44.512773273321528, 4.930273138225076],
            [44.950827124834092, 4.902500037251741],
            [45.000000230502366, 4.954309083838112],
            [45.007836136526834, 4.962573137478003],
            [45.096109144237317, 5.060273104078121],
            [45.374463264654054, 5.363918108654886],
            [45.489400279906619, 5.489764170920708],
            [45.716663347564946, 5.73860913057365],
            [45.955827209023226, 5.999164111909565],
            [45.990273310771187, 6.0355539770093],
            [46.113982322997373, 6.1620270056344],
            [46.561391216670046, 6.619445065193034],
            [46.991391232674829, 7.056945040541947],
            [47.555545118119966, 7.592218072687018],
            [47.658054117861383, 7.688891088507347],
            [47.761836161060756, 7.786591055107465],
            [47.904045207113285, 7.920454072506814],
            [47.987218162003302, 8.00110910367593],
            [47.988245112783176, 8.004109154468779],
            [47.902500254715534, 8.003054040494106],
            [47.85055408019295, 8.003054040494106],
            [47.746382278494849, 8.003609090123916],
            [47.607500177460025, 8.003336007717721],
            [47.086936211962524, 8.001944947062881],
            [47.01193611560555, 8.00110910367593],
            [46.994718177692533, 8.006109076572088],
            [46.790836093731144, 8.076391167256418],
            [46.552773279077172, 8.15721802744109],
            [46.331663366557024, 8.232218123798063],
            [46.059163334972283, 8.323609031860357],
            [45.6161002904702, 8.473327089712598],
            [45.411663324517093, 8.541945037335893],
            [45.189991153930265, 8.616109122667837],
            [45.002218249726837, 8.67860911914633],
            [44.650473190729286, 8.795173061809706],
            [44.01055410880312, 9.007218113658325],
            [43.727773337668992, 9.262782174553493],
            [43.627218159563228, 9.354444991555596],
            [43.583054079246409, 9.336109075393921],
            [43.555273266922228, 9.348473220802589],
            [43.441663274584272, 9.41763599213381],
            [43.425827177232605, 9.434582188745111],
            [43.425136173132813, 9.460835985944627],
            [43.427773287517283, 9.48527308944476],
            [43.386663237450108, 9.556945067317542],
            [43.32847321559646, 9.619582191732334],
            [43.305273281008539, 9.628336083795119],
            [43.283336163956477, 9.634718064890805],
            [43.275273275993328, 9.645554021708236],
            [43.267218099381097, 9.664444987499721],
            [43.260554151061882, 9.684718129128726],
            [43.24638219677135, 9.750827037848438],
            [43.237773311633788, 9.799309139417204],
            [43.224854116595424, 9.86319114409504],
            [43.204091304181503, 9.892291100344679],
            [43.180409242521449, 9.902773173209042],
            [43.147845213317822, 9.902773173209042],
            [43.1102733322289, 9.908891124353843],
            [43.084300161148548, 9.924864182003816],
            [43.063045163378803, 9.948745062407752],
            [43.051663377025108, 9.968191077829047],
            [43.042773195216256, 9.993053982011716],
            [43.034718186242031, 10.025000097311576],
            [43.013054151596265, 10.081109060238973],
            [42.980554160132982, 10.117636053275092],
            [42.953254133776198, 10.13250001749384],
            [42.922773175155527, 10.14778207104392],
            [42.890973240247462, 10.172082046607756],
            [42.864163219951735, 10.200554030669906],
            [42.850691154578243, 10.219444996461391],
            [42.816936224567968, 10.313891108239659],
            [42.806109152567984, 10.35000001194426],
            [42.803327198896199, 10.380554060760886],
            [42.797082178098918, 10.422782089076676],
            [42.786109260983181, 10.455553989479768],
            [42.746663353977141, 10.495691068223678],
            [42.722218203849906, 10.517918031488364],
            [42.709445189203251, 10.536109108362709],
            [42.681109159611054, 10.582773175127613],
            [42.66479127046361, 10.632918078652764],
            [42.764163259113758, 10.780418117280632],
            [42.838609144031267, 10.873891090097487],
            [42.855000291012772, 10.892218121441829],
            [42.917500287491151, 10.955273167550047],
            [42.944091204837235, 11.002435957555164],
            [42.953609191195682, 11.020273150476626],
            [42.975554187236838, 11.053609152964938],
            [43.064163309265524, 11.186944948653021],
            [43.106200230188364, 11.250000162399317],
            [43.186391239196524, 11.370282149683675],
            [43.20547331238086, 11.397827095251856],
            [43.225000129349127, 11.430826983421923],
            [43.249218334949973, 11.469536158249483],
            [43.257847540000142, 11.482899363000158],
            [43.264110540000189, 11.489013363000126],
            [43.269780540000085, 11.49562436300009],
            [43.271066040000136, 11.497217863000131],
            [43.30448754000011, 11.545578363000075],
            [43.295088540000194, 11.582913363000102],
            [43.294424540000165, 11.585308363000024],
            [43.282899540000102, 11.626393363000091],
            [43.312556540000088, 11.648557363000123],
            [43.370299540000161, 11.692342363000122],
            [43.41287054, 11.724772363000113],
            [43.444720540000134, 11.763504363],
            [43.457088540000115, 11.766087363000111],
            [43.469851540000178, 11.768846363000037],
            [43.565667541000124, 11.78915636300006],
            [43.576681541, 11.791368363000032],
            [43.621612541000133, 11.799905363000107],
            [43.635839541000081, 11.802591363000033],
            [43.732588541000069, 11.820200363000041],
            [43.798964541000174, 11.830725363000028],
            [43.803998541000141, 11.831413363000109],
            [44.032857541000141, 11.86158536300006],
            [44.138560541000146, 11.869546363000083],
            [44.146206541000112, 11.870122363000135],
            [44.196301541000054, 11.839283363000064],
            [44.256742541000079, 11.801199362999967],
            [44.282970541000196, 11.784447363000083],
            [44.316712541000044, 11.762685363],
            [44.3568165410002, 11.73464736300005],
            [44.465807541000032, 11.655939363000115],
            [44.56199254200007, 11.596689363000138],
            [44.602446542000138, 11.580304363000153],
            [44.604650542000087, 11.579709363000035],
            [44.610555542000071, 11.578303363000146],
            [44.704149542000124, 11.60521036300004],
            [44.705469542000202, 11.605588363000052],
            [44.816429542000066, 11.630287363000136],
            [44.845652542000067, 11.636808363000029],
            [44.90066754200015, 11.649104363],
            [44.91814654200013, 11.653045363000118],
            [45.077601542000053, 11.686574363000091],
            [45.111438542000116, 11.698486363000086],
            [45.131704542000222, 11.705637363000051],
            [45.136625542000132, 11.707301863000112],
            [45.16511254200006, 11.715134363000161],
            [45.23902454200018, 11.742800363000057],
            [45.298564542000094, 11.76480036300012],
            [45.322708542000072, 11.773740363000144],
            [45.418157542000159, 11.809154363000118],
            [45.486862542000125, 11.834720363000088],
            [45.515261542000104, 11.845000363000139],
            [45.609606542000137, 11.880754363000094],
            [45.806182543000062, 11.955057363000066],
            [46.040986543000173, 12.043670363000118],
            [46.170438543000074, 12.067281363000149],
            [46.260026543000066, 12.082397363000027],
            [46.300539543000042, 12.120716363000028],
            [46.301323543000166, 12.121459363000042],
            [46.399128543000216, 12.159716362999973],
            [46.413380543000102, 12.164559363000023],
            [46.693947543000178, 12.260651363],
            [46.705091543000066, 12.264479363000106],
            [47.104360544, 12.361513363000043],
            [47.250825544000094, 12.392118363000023],
            [47.276557544000212, 12.397494363000035],
            [47.364820544000082, 12.413800364000153],
            [47.367289544000158, 12.414216364000112],
            [47.599609544000174, 12.421732364],
            [47.609875544000062, 12.423005364000161],
            [47.625758544000149, 12.423543364000182],
            [47.793102545000096, 12.431177364000149],
            [48.087411545000151, 12.543282364000063],
            [48.127523545000173, 12.558144364000157],
            [48.319126545000103, 12.644252364000153],
            [48.32255254500015, 12.645717364000134],
            [48.405846545000117, 12.653008364000144],
            [48.465901545000094, 12.657499364000117],
            [48.569405545000137, 12.670421364],
            [48.714257545000095, 12.682467364000161],
            [48.930366546000215, 12.680396364000046],
            [48.995069546000167, 12.701217364000073],
            [49.336798546000153, 12.809292364000058],
            [49.512969546000107, 12.872932364000178],
            [49.54369654600012, 12.898175364000153],
            [49.61834254600015, 12.958278364],
            [49.719752546000137, 13.041880364000136],
            [49.905540546000083, 13.178219364000029],
            [50.000954547000021, 13.251401364000159],
            [50.143607547000073, 13.343740364000126],
            [50.337275547000075, 13.381615364000069],
            [50.347007547, 13.38397236400013],
            [50.932735547000078, 13.497539365000137],
            [50.984250547000073, 13.388363364],
            [51.102225548000121, 13.138336364000125],
            [51.282417548000041, 12.751811364000076],
            [51.296297548000069, 12.722851364000135],
            [51.338782548000125, 12.652496364000143],
            [51.357787548000175, 12.621162364000057],
            [51.384790548000211, 12.57684836400017],
            [51.393545548000105, 12.564906364000095],
            [51.463233548000147, 12.434299364],
            [51.50626554800013, 12.353168363000137],
            [51.529430548000192, 12.310065363000078],
            [51.552829548, 12.266688363000085],
            [51.567136548000093, 12.240461363000051],
            [51.569199548000171, 12.236664363000131],
            [51.57099054800014, 12.23331736300004],
            [51.616132548000138, 12.148766363],
            [51.682166548000197, 12.025955363000037],
            [51.689267548000117, 12.012670363000041],
            [51.734822548000096, 11.927868363000016],
            [51.758809548000073, 11.882839363000031],
            [51.787767548000119, 11.82952536300013],
            [51.790676548000107, 11.824086363000049],
            [51.820426548000142, 11.768810363000171],
            [51.842346548000052, 11.725236363000135],
            [51.847772548000108, 11.714573363000014],
            [51.88529954800012, 11.642330363000042],
            [51.900391548000158, 11.613109363],
            [51.90240754800007, 11.608709363],
            [51.95478554800016, 11.520548363000046],
            [51.965685548000096, 11.502013363000074],
            [51.99629954800011, 11.442260363000045],
            [52.027645548000095, 11.381301363000063],
            [52.057088548000053, 11.323708362000048],
            [52.072667548000055, 11.293584362000075],
            [52.121474549000169, 11.244577362000015],
            [52.130439549000045, 11.235144361999971],
            [52.208881549000097, 11.151689362000013],
            [52.256516549000054, 11.10135336200004],
            [52.299141549000154, 11.079131362000098],
            [52.335739549000124, 11.060007361999965],
            [52.347673549, 11.054034362000124],
            [52.476907549, 10.987400362000074],
            [52.632276549000068, 10.90319836200004],
            [52.658103549000117, 10.889135362000161],
            [53.011138549000208, 10.552877362000146],
            [53.012993549000129, 10.550887361999983],
            [53.101926549000183, 10.455073362000178],
            [53.115827549000102, 10.439666362000111],
            [53.312974550000177, 10.221156361000098],
            [53.934399550000109, 9.526876361000092],
            [54.016285550000106, 9.4356673610001],
            [54.429914551000167, 8.957717360000132],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SPM", Country: "St. Pierre & Miquelon" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-56.155083332999965, 44.462500000000105],
            [-56.157222497333265, 43.4161111133334],
            [-56.236099071999888, 43.416702528000073],
            [-56.314665482999828, 43.41729161800005],
            [-56.398027778000028, 43.417916667000171],
            [-56.400027777999952, 44.401111111000191],
            [-56.400731257999979, 44.733153887000114],
            [-56.402111110999925, 45.384444444000238],
            [-56.403229913999866, 45.879486822000089],
            [-56.40433333299984, 46.367722222000211],
            [-56.458515779999942, 46.383723987000096],
            [-56.506666666999848, 46.397944444000231],
            [-56.648333871999881, 46.444740377500167],
            [-56.688138889000015, 46.457888889000102],
            [-56.837916611499878, 46.554138853500163],
            [-56.844475379999921, 46.560140052000165],
            [-56.89869444399983, 46.60975],
            [-56.935917499999931, 46.689527110000128],
            [-56.986749999999859, 46.798472221999972],
            [-57.096777595999924, 46.982944391000117],
            [-57.006668576999914, 47.040817767000121],
            [-56.86841666700002, 47.129611111000102],
            [-56.730314127000042, 47.187818388000224],
            [-56.662527777999856, 47.216388889000072],
            [-56.661142773999927, 47.217482442000247],
            [-56.654672732, 47.222590971999963],
            [-56.631111110999967, 47.241194444000016],
            [-56.523136888999971, 47.339007678000215],
            [-56.506884570999858, 47.353730560000074],
            [-56.494444443999868, 47.365000000000194],
            [-56.430159967999884, 47.348966169000036],
            [-56.359702257999913, 47.331392610000123],
            [-56.254999999999853, 47.305277778000047],
            [-56.105, 47.10055555600006],
            [-56.112838525999848, 47.046830827000107],
            [-56.119499489999868, 47.001177031000097],
            [-56.129722221999884, 46.931111111000135],
            [-56.099444443999886, 46.860000000000113],
            [-56.096920245999854, 46.858557601000072],
            [-56.096203410999863, 46.858147981000101],
            [-56.091666667000027, 46.855555556000084],
            [-55.9825, 46.802777778000248],
            [-55.945872020999985, 46.737232974000065],
            [-55.938005343999947, 46.723155762000076],
            [-55.92444444399996, 46.698888889000145],
            [-55.903333468499966, 46.646111032500144],
            [-55.94494278499991, 46.622027505000133],
            [-55.953388888999939, 46.617138889000117],
            [-56.029194443999899, 46.581111111000084],
            [-56.058580160999981, 46.57472905900012],
            [-56.125402287999862, 46.560216487000176],
            [-56.15044444399993, 46.554777778000116],
            [-56.152833332999961, 45.508333333000095],
            [-56.155083332999965, 44.462500000000105],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SRB", Country: "Serbia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.261027180509359, 46.1148540828395],
            [20.314027127902165, 46.069853991497737],
            [20.354027078709663, 46.017391156461869],
            [20.373191126907443, 45.981518119513439],
            [20.513745082354319, 45.914236079621944],
            [20.553745033161846, 45.911245081284406],
            [20.58527322676818, 45.898744981405841],
            [20.655973239146135, 45.827909182196166],
            [20.662363266868823, 45.798745020568006],
            [20.717636051133127, 45.743744983433928],
            [20.75277315698068, 45.746936141619713],
            [20.773018135415072, 45.768154091377326],
            [20.80160025768501, 45.758673153031012],
            [20.797500165916347, 45.653045079471255],
            [20.776527135370202, 45.633327155110052],
            [20.76756319281327, 45.613536140553109],
            [20.794163330252843, 45.50777311835175],
            [20.808327070278295, 45.478882206767864],
            [20.986663292584382, 45.345836089654242],
            [21.048882160029478, 45.316382081813529],
            [21.271945055994991, 45.231245079365848],
            [21.351391249084685, 45.217773181630506],
            [21.410827157029786, 45.215418034469664],
            [21.473373253975808, 45.187445108924081],
            [21.482773223137173, 45.183609047106202],
            [21.514100251066878, 45.142773252911624],
            [21.443054066086887, 45.053600196435625],
            [21.37343615741193, 45.013609130445516],
            [21.407218077150389, 44.972218118983065],
            [21.429718122821271, 44.961664129389348],
            [21.450136103737691, 44.962353960022583],
            [21.473191199038382, 44.962353960022583],
            [21.535827149986687, 44.940200086953737],
            [21.556800180532832, 44.913954001105353],
            [21.55395402148261, 44.8924270943957],
            [21.502218232730257, 44.879718117489446],
            [21.478882176034261, 44.875273194223112],
            [21.45360922914719, 44.875418033510428],
            [21.407773294418377, 44.876244992080061],
            [21.369582158321037, 44.866527013511259],
            [21.362573210866174, 44.828100178296111],
            [21.398663339107543, 44.783100086954349],
            [21.45360922914719, 44.77666412640211],
            [21.474718214163119, 44.777773219833435],
            [21.533745085232141, 44.777353957035345],
            [21.565554072595603, 44.771664153505967],
            [21.596945138265653, 44.755827050325891],
            [21.615600237301237, 44.710791084438299],
            [21.617709124146046, 44.682844980983035],
            [21.644236171389736, 44.66013605828438],
            [21.73416326387752, 44.655827089487943],
            [21.783609116676018, 44.661945040632745],
            [21.909163321072413, 44.649436055936846],
            [21.997009186995768, 44.633045076593461],
            [22.029163341132971, 44.593891195094855],
            [22.031263343160447, 44.58942716108912],
            [22.035418084938101, 44.559509131087268],
            [22.091663337611379, 44.498673110031817],
            [22.137500110530596, 44.480273156129797],
            [22.175482201961984, 44.48326415446725],
            [22.258609224022564, 44.585273089863804],
            [22.273327175487509, 44.604164223293466],
            [22.297773163805061, 44.635827030265133],
            [22.311591234142526, 44.659027132491232],
            [22.428818185348888, 44.712082232807049],
            [22.466109105042591, 44.713745034763704],
            [22.493473169139804, 44.699164211235157],
            [22.513891150056025, 44.67971802817577],
            [22.543054138217826, 44.658609043159643],
            [22.574445036249784, 44.638609151574926],
            [22.607291200315217, 44.622427049259286],
            [22.6444451597105, 44.618609092352116],
            [22.665836111950256, 44.619445103377146],
            [22.712773261121271, 44.605136021150216],
            [22.762509127770301, 44.552600095918578],
            [22.738891271488853, 44.533054000572932],
            [22.701182262463675, 44.523564177409284],
            [22.672500227907534, 44.528882159712779],
            [22.639027097482739, 44.545827015219615],
            [22.61541812601871, 44.551664172607374],
            [22.586245079573132, 44.547635997568065],
            [22.461527221477922, 44.483327018741122],
            [22.459927116157331, 44.461064180930791],
            [22.530000162175924, 44.345273217380495],
            [22.543327220624008, 44.329718081424019],
            [22.575618167421453, 44.305545007874699],
            [22.616673232203652, 44.298035996074972],
            [22.641109162237484, 44.294164227349242],
            [22.665273183331323, 44.288464197897966],
            [22.687773229002204, 44.27401815537273],
            [22.681436174907844, 44.224700042779133],
            [22.67639127501036, 44.224718147689927],
            [22.651800112129422, 44.213191186772789],
            [22.61916332800601, 44.170000077779477],
            [22.614854023933503, 44.119582091847931],
            [22.544854068110766, 44.060836014536264],
            [22.41860919489082, 44.008191124562856],
            [22.367218237635967, 43.826945040742245],
            [22.410418231446783, 43.717500183741819],
            [22.54166324520105, 43.475618071076113],
            [22.628054012729109, 43.430827024400102],
            [22.669163224605938, 43.415835990529061],
            [22.721382146258634, 43.40069106491508],
            [22.764309225301332, 43.386664117549955],
            [22.829509200438167, 43.331873125081543],
            [22.846945067834469, 43.286727020985964],
            [22.905973280007942, 43.229718009293194],
            [22.940554162759298, 43.21054507627791],
            [23.005000269522441, 43.192773094563464],
            [22.992082247950464, 43.137353962269387],
            [22.966391211731775, 43.101664153724798],
            [22.74159125014728, 42.892145072224068],
            [22.658609235012136, 42.88290905308925],
            [22.615000204325298, 42.894718148868193],
            [22.594854132348701, 42.894445066461898],
            [22.521245096646652, 42.876873244595785],
            [22.442909170454556, 42.820409056610842],
            [22.447218139251106, 42.599444989206333],
            [22.468191169797137, 42.566936112925632],
            [22.522500202831679, 42.531109176444645],
            [22.546945185320823, 42.512909047114803],
            [22.558473152066284, 42.483327131431295],
            [22.537218154296625, 42.425418070972839],
            [22.520836059770545, 42.400691121260181],
            [22.459445156723376, 42.363054028964356],
            [22.410827100684799, 42.335273216640175],
            [22.365273300817705, 42.323882210192821],
            [22.325345099101554, 42.358954104833558],
            [22.21687321342435, 42.336136049755353],
            [22.196182150101919, 42.31639113566591],
            [22.075209158717854, 42.300973127645861],
            [22.023609156797335, 42.316936127011942],
            [21.850691164357073, 42.330344992835293],
            [21.803191254205217, 42.298745050137498],
            [21.788954088707754, 42.268264259154805],
            [21.729854127442906, 42.235136127865857],
            [21.707773176932022, 42.232491134492463],
            [21.681391136613541, 42.239718011430497],
            [21.619718098704737, 42.254164221593712],
            [21.472636149408487, 42.265973149734478],
            [21.346245095796746, 42.194300166033301],
            [21.305554140889512, 42.149582209553145],
            [21.218191240228435, 42.147636099268496],
            [21.136454105356307, 42.200000195484577],
            [21.110836159333473, 42.200691199584284],
            [20.915000199528919, 42.11999107377595],
            [20.795409216344268, 42.083118075775886],
            [20.770836158374237, 42.046800127405433],
            [20.759854021165012, 41.985618101386009],
            [20.777563306243593, 41.937391144950723],
            [20.736945106256115, 41.868118067773437],
            [20.707782285732407, 41.856391114645945],
            [20.618954060754078, 41.861518157194595],
            [20.589645059838688, 41.88219111560629],
            [20.583400206679471, 41.918818020928498],
            [20.60332717570634, 41.934718156020708],
            [20.621736182063955, 41.95652702995379],
            [20.59527317256061, 42.043609136857626],
            [20.525136088801474, 42.213054003888473],
            [20.508891122211878, 42.230273115267906],
            [20.457536207140834, 42.27390913568324],
            [20.386945159504563, 42.30416411819364],
            [20.355673284497982, 42.311845126647015],
            [20.249509104409555, 42.327073200740628],
            [20.239582248813065, 42.360554042516171],
            [20.222500265369945, 42.428327094297103],
            [20.161663238486199, 42.511318161887786],
            [20.102082155977712, 42.533473208422905],
            [20.071418136591092, 42.5609091892495],
            [20.099927168665289, 42.657700054628734],
            [20.070000253846018, 42.673882156944487],
            [20.048609133968284, 42.677773204047398],
            [20.014027077750427, 42.682145037117991],
            [19.977500252352399, 42.707009114767047],
            [19.985554087860066, 42.72763614034902],
            [20.015000216711854, 42.752491165542679],
            [20.039445031562906, 42.766109076031896],
            [20.071663223440567, 42.773464196088909],
            [20.104654059155251, 42.769718155911292],
            [20.231945161532536, 42.761109103135638],
            [20.37902711082873, 42.841800176488491],
            [20.346109197671922, 42.888745037010537],
            [20.29221808633099, 42.929435991917771],
            [20.259718094867708, 42.941664182856513],
            [20.171245094947011, 42.969300155893464],
            [20.150273237867253, 42.971944981628852],
            [20.059718173191925, 43.005554066523374],
            [20.035827066866091, 43.037218046961627],
            [19.969718158146463, 43.090554108672663],
            [19.92027331117626, 43.106382159397342],
            [19.783609229366022, 43.148045079799502],
            [19.69250012088915, 43.170827092694097],
            [19.663745163775189, 43.176244987283681],
            [19.644863250439187, 43.183882074564295],
            [19.585827159276704, 43.236109042844177],
            [19.557636136609858, 43.268744988777385],
            [19.514863284586056, 43.319573185051311],
            [19.444163272208186, 43.373600083224161],
            [19.279445128029295, 43.449718157829679],
            [19.254718178316551, 43.460000070845794],
            [19.228809212614692, 43.513218115359834],
            [19.287500137003434, 43.544164269608032],
            [19.408609250495402, 43.584445014172772],
            [19.480554143136402, 43.571391205768876],
            [19.49389125986832, 43.588054094052083],
            [19.510554148151527, 43.685827150848141],
            [19.507636072371923, 43.709582135065986],
            [19.499582236864171, 43.737082153633011],
            [19.490136167235192, 43.758473105872767],
            [19.475273208844953, 43.772773135644101],
            [19.437500162079289, 43.800282206666637],
            [19.366945156626656, 43.859445032205528],
            [19.251218230816818, 43.962382179562283],
            [19.239454062039187, 44.010609135997569],
            [19.308927131426827, 43.997736041426819],
            [19.366127082874556, 43.970873215140941],
            [19.428527167066761, 43.96065399876089],
            [19.485273154637355, 43.958882232062635],
            [19.525791274700538, 43.960445121733031],
            [19.61978224913517, 44.019753960025739],
            [19.618563352772043, 44.052618061363901],
            [19.59694508331765, 44.060273253555422],
            [19.485836083256174, 44.120282148403064],
            [19.35795405670342, 44.209409104411449],
            [19.237436202663076, 44.265182120658594],
            [19.160909091181253, 44.286782117564016],
            [19.128991139076192, 44.315227111897755],
            [19.104445070834487, 44.355827039336233],
            [19.105554164265698, 44.376664115412453],
            [19.130827111152882, 44.521427127369677],
            [19.193982237185253, 44.560154034719361],
            [19.313609094915535, 44.704718060294638],
            [19.336109140586416, 44.747218165188414],
            [19.345218090068755, 44.76773605839081],
            [19.382200220448595, 44.864818111087075],
            [19.371391253359576, 44.889164187118539],
            [19.304863249479865, 44.909435987642965],
            [19.175345075422683, 44.922427099410996],
            [19.121109132584166, 44.901382152135298],
            [19.039718170314188, 44.861382201327885],
            [19.028609131090462, 44.88027316711937],
            [19.009818077585066, 44.905482076266068],
            [19.04076322600497, 44.930764243246742],
            [19.115554110058014, 45.031945046607191],
            [19.10416327124895, 45.096664235776629],
            [19.167354104189002, 45.21430005622112],
            [19.187500176165514, 45.216945049594514],
            [19.212827102509209, 45.210000139879995],
            [19.248682202184995, 45.186664250822176],
            [19.352500119929914, 45.173336018907591],
            [19.418536106091864, 45.179991082409387],
            [19.425000229838815, 45.21791802091785],
            [19.409209227126354, 45.235273254405158],
            [19.388963242863753, 45.235744987917329],
            [19.350563229738754, 45.234582082667359],
            [19.320336075146884, 45.238364165028784],
            [19.285000150555362, 45.243409064926155],
            [19.260518120054087, 45.24742701404368],
            [19.190200154823941, 45.270336096590583],
            [19.138609205358819, 45.287500055047062],
            [19.112500247446604, 45.298044992185382],
            [18.981263280319524, 45.382218075765039],
            [19.001082290433203, 45.489691173856968],
            [19.025573205751783, 45.497073116004216],
            [19.056400169336712, 45.487018017288776],
            [19.096018072996458, 45.49800903931542],
            [19.097927135268975, 45.51886422030266],
            [19.007091276836405, 45.561136002153106],
            [18.969663229206418, 45.539991142591518],
            [18.948727079034427, 45.537936067565184],
            [18.929182157155253, 45.544845102733873],
            [18.902236182389629, 45.573136037686808],
            [18.910418093378183, 45.620173099143997],
            [18.936391264458564, 45.628054099807699],
            [18.966891166180545, 45.664073149510031],
            [18.956663232621167, 45.782500053978296],
            [18.916663281813641, 45.815691217179378],
            [18.889854099708145, 45.825764253167549],
            [18.83235407612591, 45.875509172272288],
            [18.817018210757084, 45.912964209630488],
            [18.835827201535409, 45.911109126814509],
            [18.858891181653519, 45.911109126814509],
            [19.002636127648174, 45.959445047991395],
            [19.093054064387132, 46.022218126876069],
            [19.127500166135178, 46.020900156417085],
            [19.154091251119411, 45.988609041981576],
            [19.284027179232027, 45.988882124387757],
            [19.463891254853195, 46.076382152985232],
            [19.516318215343347, 46.118118163583247],
            [19.506318101912939, 46.139227148599289],
            [19.565273223890472, 46.172773201581848],
            [19.666527117446861, 46.181109171951206],
            [19.703718124854475, 46.176391166278577],
            [19.744718204351727, 46.162218038521459],
            [19.849582183615837, 46.152145002533203],
            [19.961109105370923, 46.170827091296999],
            [20.115273259955984, 46.16721817905578],
            [20.202773288553345, 46.150553949668009],
            [20.257773158049446, 46.118045073387393],
            [20.261027180509359, 46.1148540828395],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "STP", Country: "Sao Tome & Principe" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.195, 0.623611111000059],
            [8.187500000000171, 0.566666667000064],
            [7.841111111000089, -0.001388888999898],
            [7.689166667000137, -0.293888889000016],
            [7.628333333000171, -0.429166666999947],
            [7.473611111000054, -0.880833332999842],
            [7.275, -1.484444443999962],
            [6.191861111000094, -0.787722221999942],
            [5.893633505000082, -0.591128662],
            [5.323055556000043, -0.215],
            [4.492354369000026, 0.228681944000186],
            [3.617555556000127, 0.695916667000063],
            [3.209152778, 0.916527778000017],
            [3.272888503000161, 1.193194353000138],
            [3.345777503000107, 1.412222353000018],
            [3.427194503000095, 1.612639353000134],
            [3.575833333000105, 1.92166666700011],
            [3.839722222, 1.713888888999989],
            [4.115277778000035, 1.5275],
            [4.403888889000086, 1.358055556000082],
            [4.690833333000171, 1.220833333000115],
            [4.860555556000151, 1.15472222199999],
            [5.965, 1.67],
            [6.879166667000163, 2.349722222000139],
            [7.606793754000108, 2.710480842999985],
            [7.703611111000157, 2.642777778000038],
            [7.889000000000181, 2.526472222000109],
            [8.044444444, 2.425555556000077],
            [8.218109026000121, 2.310879894000095],
            [8.365833333000126, 2.213333333000023],
            [8.49999170100017, 2.106896310000025],
            [8.550138889000152, 2.067111111000131],
            [8.520833333000127, 1.9125],
            [8.504166667000106, 1.819444444000055],
            [8.466111111000146, 1.645833333000013],
            [8.40222222200012, 1.403888889],
            [8.380000000000166, 1.296666667000054],
            [8.360763889000168, 1.192402778000101],
            [8.302777778000149, 1.004166667000021],
            [8.195, 0.623611111000059],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SUR", Country: "Suriname" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-52.6640418369999, 8.877153263000096],
            [-53.069731575999981, 7.910777699],
            [-53.473706027, 6.943567262],
            [-53.715060147999907, 6.367084492000075],
            [-53.72198905, 6.350381673000172],
            [-53.802065101999858, 6.158692586000129],
            [-53.886874509999984, 5.958465692000118],
            [-53.891852394000011, 5.946441748000098],
            [-53.936657974999974, 5.848213273],
            [-53.937733738999896, 5.845537091500034],
            [-53.942061493999859, 5.83552301600001],
            [-53.943781243999865, 5.830860932000093],
            [-53.946256283000025, 5.823590697000114],
            [-53.946533356000032, 5.821272979000014],
            [-53.94798618199988, 5.818550075000047],
            [-53.949745198999949, 5.814760057000171],
            [-53.955014643999846, 5.802970397],
            [-53.959302024999943, 5.791090979000032],
            [-53.960563760999975, 5.785661763000149],
            [-53.962598209999896, 5.778137479000023],
            [-53.963000400999874, 5.776375674000178],
            [-53.964399747999835, 5.768828809000141],
            [-53.965940807999885, 5.762064685000112],
            [-53.966950573999952, 5.755429728000024],
            [-53.967147940999951, 5.754324106000027],
            [-53.985876156000018, 5.718012300000112],
            [-54.001816455999972, 5.690686069999984],
            [-54.017756755999926, 5.665637027000159],
            [-54.026865499999872, 5.636033612000091],
            [-54.031419870999855, 5.606430197000023],
            [-54.029142685000011, 5.572272410000068],
            [-54.029142685000011, 5.542668995],
            [-54.0428058, 5.506234023000104],
            [-54.065577658, 5.46752186500008],
            [-54.086072329999922, 5.437918450000012],
            [-54.111121372999833, 5.406037849],
            [-54.145279159999973, 5.371880063000134],
            [-54.166525158018914, 5.347595021118053],
            [-54.170699864250906, 5.342218031825311],
            [-54.193335864391685, 5.315273062888082],
            [-54.244999904052747, 5.285000143104668],
            [-54.28348189219102, 5.254864183619645],
            [-54.317326843841528, 5.208627091003848],
            [-54.347781986200374, 5.148336061294415],
            [-54.378890917008817, 5.112218105134403],
            [-54.408617839617733, 5.080553957058157],
            [-54.448063914261922, 5.024164200373619],
            [-54.455281906382652, 5.004027013214341],
            [-54.476108924175065, 4.867773141746667],
            [-54.477781952053533, 4.754164155237021],
            [-54.473335855320784, 4.734582185345815],
            [-54.439726770426091, 4.669164113087731],
            [-54.422090910819634, 4.601527015776696],
            [-54.423263874353324, 4.564309018640955],
            [-54.442917928611962, 4.530691048929029],
            [-54.451808948611131, 4.512364185222765],
            [-54.440235887226549, 4.410644928400757],
            [-54.431399852512328, 4.3691642305741],
            [-54.393890835697476, 4.237217986798214],
            [-54.36500880893081, 4.163609118734172],
            [-54.360763877874916, 4.042427082684341],
            [-54.290281962618224, 3.933891159266878],
            [-54.188681896459684, 3.808744985918096],
            [-54.14639083623166, 3.79778212708635],
            [-54.12764605083197, 3.788609139863567],
            [-53.99778187181073, 3.626945025934262],
            [-53.986117782957194, 3.601527072121783],
            [-54.001108816828349, 3.448336056498036],
            [-54.071108940289264, 3.312782073947318],
            [-54.094454887630974, 3.29500003394898],
            [-54.118755030832801, 3.277782096035949],
            [-54.187226798064302, 3.19486411864122],
            [-54.206117931493964, 3.145282143734818],
            [-54.208472911016742, 3.124309113188772],
            [-54.195745996837672, 3.096200065535186],
            [-54.176117926478753, 3.04389112224203],
            [-54.160835872928487, 2.953609139973068],
            [-54.195281974676533, 2.796244942384618],
            [-54.204590916369227, 2.775000002898764],
            [-54.222917780075477, 2.75555398747737],
            [-54.256955012585308, 2.718891039971354],
            [-54.315135814345382, 2.629164107332116],
            [-54.321808815119994, 2.598473098217184],
            [-54.351955000526999, 2.523891091191729],
            [-54.365908857696382, 2.496600117290441],
            [-54.420563895694897, 2.434718034715814],
            [-54.499172904293175, 2.365000046116705],
            [-54.603781906062068, 2.329191046908718],
            [-54.634726886843794, 2.320073212608875],
            [-54.687499852298004, 2.325553971472601],
            [-54.709099849203511, 2.387918013481098],
            [-54.690208883411941, 2.399509012138694],
            [-54.689863884276178, 2.451600025948679],
            [-54.753126801583761, 2.470627113848153],
            [-54.775381928043345, 2.456845085694411],
            [-54.803755005647616, 2.438054032189015],
            [-54.847499823166373, 2.436109095370753],
            [-54.869164025450203, 2.441945079291941],
            [-54.969445953511666, 2.550553925267351],
            [-55.091945960020496, 2.534717995553848],
            [-55.111946019243192, 2.527218036209433],
            [-55.503335803990922, 2.438891049042439],
            [-55.713681844061057, 2.400135978497971],
            [-55.864099958468614, 2.471454072417771],
            [-55.886390959473744, 2.497082076724411],
            [-55.917508942737499, 2.520691048188525],
            [-55.941808918301234, 2.530554033682719],
            [-55.962781948847464, 2.533054020130706],
            [-55.982499873208582, 2.522082108843364],
            [-56.000417867676816, 2.450836099291109],
            [-56.084445944331179, 2.357500086772461],
            [-56.115835836534728, 2.249164155565353],
            [-56.036390816911421, 2.208891122351446],
            [-55.90396395480613, 2.04778205805205],
            [-55.901735877297966, 1.901044940253357],
            [-55.942645935154758, 1.856109054290116],
            [-55.965835811458874, 1.845282149928096],
            [-55.995972944410482, 1.837636010192156],
            [-56.027499964550429, 1.836244949537132],
            [-56.068064017443163, 1.845554058867904],
            [-56.113335850086656, 1.86360901363436],
            [-56.201664010720123, 1.891664081831294],
            [-56.326955023356135, 1.92528205154332],
            [-56.426390882108763, 1.934164186724985],
            [-56.470635763972183, 1.944500079197553],
            [-56.477363917669919, 1.962082126985536],
            [-56.555972926268311, 2.020553948424634],
            [-56.579726904657633, 2.027364077135729],
            [-56.64611778060106, 2.028054075407042],
            [-56.682090897473842, 2.026764100504678],
            [-56.774999935843567, 2.14083610334707],
            [-56.810564015840242, 2.193335986395084],
            [-56.853890911665275, 2.283336001440617],
            [-56.917781968798607, 2.428053918758621],
            [-56.944726770097844, 2.4830539558927],
            [-57.116881841709016, 2.757191140810278],
            [-57.200835822339116, 2.822845079824262],
            [-57.213335754579504, 2.878891178477616],
            [-57.207217803434617, 3.004236002932089],
            [-57.210281891968009, 3.035835945629898],
            [-57.22028183776041, 3.065000107258072],
            [-57.255008900903675, 3.103608997410845],
            [-57.283617845263848, 3.140973174938466],
            [-57.289999826359633, 3.181391047439419],
            [-57.292499812807705, 3.267364228550207],
            [-57.28354592853438, 3.318682095609034],
            [-57.304172954116552, 3.380417997791966],
            [-57.429454914296969, 3.347218117411543],
            [-57.530554916110731, 3.346391158841826],
            [-57.610417857427478, 3.360000016875645],
            [-57.642226844791139, 3.356391104634326],
            [-57.657781980747501, 3.413891128216576],
            [-57.638408887883969, 3.457009147014034],
            [-57.654445983274485, 3.518609094727054],
            [-57.668617937564932, 3.538336071543569],
            [-57.740835744974049, 3.613745037138557],
            [-57.760563895257235, 3.624164078090871],
            [-57.816672858184546, 3.650282088458567],
            [-57.838481899755607, 3.669309008720049],
            [-57.845972974282517, 3.692636012960548],
            [-57.849308804117641, 3.738891042849261],
            [-57.859454930301837, 3.771664116718654],
            [-57.86749988099217, 3.790836043905443],
            [-57.876672868214769, 3.809164081078094],
            [-57.931945987755142, 3.890691165456062],
            [-57.9504178583868, 3.913191043488951],
            [-57.968890902484958, 3.929164101138838],
            [-58.016399865092211, 3.963609029420297],
            [-58.04375504437192, 4.001527083111256],
            [-58.071399902226375, 4.155691070058239],
            [-58.061808825671903, 4.180136052547567],
            [-58.039172993169302, 4.211391163747621],
            [-58.00278195460308, 4.239717973246201],
            [-57.954517782517485, 4.288336029284778],
            [-57.949172978123755, 4.317218056051345],
            [-57.948617928493945, 4.349164171351205],
            [-57.902781993765046, 4.503609119693408],
            [-57.846945945605853, 4.628891079873924],
            [-57.8403549198446, 4.669026985151348],
            [-57.887781907438494, 4.768336109527652],
            [-57.909026846924348, 4.777782179156631],
            [-57.924863950104424, 4.796391177724502],
            [-57.9233357615133, 4.821945086006792],
            [-57.901390933110207, 4.855554003263407],
            [-57.841117840673775, 4.927782036594408],
            [-57.676255024845659, 5.011526972558755],
            [-57.634173009283387, 5.00028214650321],
            [-57.610981959512884, 4.992218085073659],
            [-57.473617821147315, 4.98916422246225],
            [-57.417090936526392, 4.989445016219292],
            [-57.327226875950856, 5.026109137191881],
            [-57.321945941659493, 5.075973079321813],
            [-57.292364025975786, 5.166245003307068],
            [-57.272090884346881, 5.177082133591071],
            [-57.250554925181916, 5.17263603685825],
            [-57.231526831453863, 5.14645398875011],
            [-57.19104592704096, 5.172009070498973],
            [-57.207508823113727, 5.223609072419407],
            [-57.235208833891221, 5.259944958062675],
            [-57.262226893024376, 5.221664135601145],
            [-57.282781834238818, 5.226391026091193],
            [-57.324445928107565, 5.303609141672624],
            [-57.299445895988612, 5.359718104600034],
            [-57.271599872457415, 5.38486414947279],
            [-57.248508856406005, 5.486109161563135],
            [-57.226554897999847, 5.504994809999971],
            [-57.192448234999858, 5.539101473000144],
            [-57.177100235999973, 5.56468147000011],
            [-57.166868236999989, 5.60049346600016],
            [-57.15834157199987, 5.65165346],
            [-57.151520239, 5.701108122000065],
            [-57.148109572999886, 5.736920118000114],
            [-57.142993572999984, 5.77614278],
            [-57.134466907999865, 5.81195477600005],
            [-57.129350907999878, 5.844356105999978],
            [-57.11911890899998, 5.881873435000088],
            [-57.107181576999949, 5.926212097000061],
            [-57.098654911999915, 5.951792094000027],
            [-57.092424965999953, 5.982623907000132],
            [-57.091359464999982, 5.987624916000115],
            [-57.087088611999832, 6.00209661600006],
            [-57.08680997899998, 6.003083225000069],
            [-57.085233360999894, 6.008073870000089],
            [-57.073482473000013, 6.034430773000125],
            [-57.072204590999974, 6.039185561000068],
            [-57.071537120999892, 6.041415616000023],
            [-57.070720618999928, 6.043342205],
            [-57.066321687999846, 6.052998921],
            [-57.065520676999824, 6.054561795999987],
            [-57.064932168999889, 6.055425373000034],
            [-57.063420344999855, 6.058432982000014],
            [-57.062277105999954, 6.060682738],
            [-57.059815692999962, 6.06530237400014],
            [-57.057889360499956, 6.068679460000041],
            [-57.056832084999911, 6.070511749000133],
            [-57.055710323, 6.072396476000108],
            [-57.055107532999955, 6.073396527000071],
            [-57.052952281999978, 6.076937895000171],
            [-57.047444976999941, 6.087074114000046],
            [-57.041763630999981, 6.099466784000015],
            [-57.035824999999846, 6.112930603000066],
            [-57.034454621999885, 6.115927840000083],
            [-57.032012868999914, 6.121082779],
            [-57.024987174999922, 6.135433740000096],
            [-57.014195327999886, 6.162358035000182],
            [-57.011300805999838, 6.169449863000054],
            [-57.010589384999861, 6.171288585000042],
            [-56.999049360999834, 6.195098241],
            [-56.949857044999987, 6.280828670000105],
            [-56.878891559999943, 6.40601806200003],
            [-56.853709001, 6.449664297],
            [-56.838218061999896, 6.480852529000103],
            [-56.830823409999937, 6.497650120000031],
            [-56.815221726999937, 6.529249813999982],
            [-56.805924922999964, 6.548807108000076],
            [-56.789784600999866, 6.581608577000168],
            [-56.734918893999918, 6.695997062000103],
            [-56.731309239000041, 6.703445783000106],
            [-56.719014287999983, 6.729137115000142],
            [-56.684506873999965, 6.799873494000153],
            [-56.67300162899997, 6.82363774900017],
            [-56.648997061999978, 6.873592557000038],
            [-56.643559214, 6.884989835000169],
            [-56.624341471, 6.925554491000071],
            [-56.620134699999937, 6.93611662100011],
            [-56.588848077999955, 7.006524660000139],
            [-56.528778984999974, 7.135106226000062],
            [-56.41068567100001, 7.389644164000146],
            [-56.360621192999929, 7.514205617000158],
            [-56.20297017199988, 7.924384240000037],
            [-56.200847772999964, 7.936204615000179],
            [-56.200689143999881, 7.937048324000088],
            [-56.116562058999932, 8.390340521000098],
            [-56.094946363999867, 8.47230335600004],
            [-56.076524948999975, 8.531445685],
            [-56.057614459999911, 8.591745570000157],
            [-56.01208957800003, 8.741473684000042],
            [-55.92077751100004, 9.023210691000116],
            [-55.911029619999965, 9.051763166],
            [-55.776305529999917, 9.320455675000176],
            [-55.775762189, 9.321539308000126],
            [-55.668226823999987, 9.330932786000105],
            [-55.663329151999932, 9.33051122700013],
            [-55.659157571, 9.330873067000041],
            [-55.625455823999857, 9.327970622000151],
            [-55.597701865999966, 9.330375181000065],
            [-55.596037960999865, 9.330231729],
            [-55.589647178, 9.330785171000144],
            [-55.579504367000027, 9.329910467000161],
            [-55.569510379999826, 9.330775573999972],
            [-55.405817104999983, 9.316626331000052],
            [-55.037585171999865, 9.348236645000085],
            [-55.035358792999972, 9.34804217000007],
            [-55.033557771999881, 9.348196081000012],
            [-54.858522977, 9.332874356000076],
            [-54.853253912999946, 9.333322736000142],
            [-54.851809785999933, 9.333196048000048],
            [-54.841171406999877, 9.334101133000061],
            [-54.84018977300002, 9.334014995000132],
            [-54.829088843999898, 9.334959161],
            [-54.824790984999822, 9.334581904000046],
            [-54.820019552000019, 9.334987609000137],
            [-54.234238120999862, 9.283211339],
            [-54.231387797999844, 9.282449078000084],
            [-54.228916245999898, 9.282178740000106],
            [-54.222129804, 9.28152435000014],
            [-54.201196402999898, 9.27596164550009],
            [-54.199241682999883, 9.275475254000028],
            [-54.194968483, 9.275094687000077],
            [-54.192183159000024, 9.274349562000154],
            [-54.190943262999838, 9.274239124000161],
            [-54.188211586999955, 9.273508327],
            [-54.186918028999855, 9.27339309900006],
            [-54.184685182999942, 9.272795733000109],
            [-54.18153201949994, 9.272453017500126],
            [-54.173826149000035, 9.271704627000062],
            [-54.171818569999914, 9.271167470000123],
            [-54.168786417999854, 9.270897264000055],
            [-54.166554522999945, 9.270300063000022],
            [-54.163746776999972, 9.270049825000015],
            [-54.16157396499986, 9.269468410000044],
            [-54.158707118, 9.269212875000136],
            [-54.156307320999929, 9.268570693000115],
            [-54.153667550000023, 9.268335372000095],
            [-54.147991969, 9.266816510000098],
            [-54.146631474999964, 9.266695208000101],
            [-54.123311878999971, 9.260453735000155],
            [-54.089228538000043, 9.257412004000102],
            [-54.08656005399996, 9.256697509000134],
            [-54.085203378999978, 9.256576378000148],
            [-54.082700814999953, 9.255906286000126],
            [-54.081178161, 9.255770322999979],
            [-54.056101857999863, 9.249070188500141],
            [-53.994960187999965, 9.232705885000044],
            [-53.876771834999943, 9.222117431000143],
            [-53.870780524, 9.220510396000137],
            [-53.869736401999944, 9.220416715000113],
            [-53.86675385399991, 9.219627177500115],
            [-53.863977267999928, 9.218892870000062],
            [-53.862670107999861, 9.218775570000034],
            [-53.85662376, 9.217153581000034],
            [-53.855634729999963, 9.217064814000068],
            [-53.852751746, 9.216291392000016],
            [-53.851609892999932, 9.216188901000066],
            [-53.850177115999855, 9.215804517000151],
            [-53.845556078999863, 9.215389698000109],
            [-53.843996212000036, 9.214971198000029],
            [-53.83953305599988, 9.214570497000096],
            [-53.838658666000015, 9.214335894000044],
            [-53.831450643000039, 9.213688662000052],
            [-53.82983551099997, 9.213255285000074],
            [-53.825427656999949, 9.212859423000097],
            [-53.82371586, 9.212400085000013],
            [-53.819070301499977, 9.211928603000089],
            [-53.810961393999918, 9.211081520500116],
            [-53.803105053999928, 9.21028167850011],
            [-53.794204446999885, 9.2094522740001],
            [-53.785466748, 9.207106967000115],
            [-53.784127781999871, 9.2069866020001],
            [-53.782964596999818, 9.206674372000023],
            [-53.777090471999884, 9.206146263000022],
            [-53.7758689879999, 9.205818364999985],
            [-53.770053194000013, 9.205295418000148],
            [-53.76845970899987, 9.204867635000014],
            [-53.763999527999971, 9.204466521000043],
            [-53.762778966999974, 9.204138837000016],
            [-53.756962263, 9.203615654000075],
            [-53.755757433000042, 9.20329217400004],
            [-53.749894265999956, 9.202764729],
            [-53.72486469899988, 9.196066747000131],
            [-53.709410483999989, 9.19193931200013],
            [-53.708637855999825, 9.19186973800015],
            [-53.705817276999966, 9.191112143999987],
            [-53.704613141999886, 9.19100370500017],
            [-53.664925689, 9.180341838000047],
            [-53.662154492499894, 9.180063872500099],
            [-53.653246456, 9.179232630000115],
            [-53.651194614999866, 9.178681263000144],
            [-53.648238045000028, 9.178414677000021],
            [-53.646036060999904, 9.17782294],
            [-53.643198974, 9.177567098000154],
            [-53.602445834000036, 9.166613343000037],
            [-53.593861853, 9.165838455000141],
            [-53.591660037999873, 9.165246498000101],
            [-53.588822853999972, 9.164990336000045],
            [-53.586623970999938, 9.164399143000068],
            [-53.583783857999947, 9.164142687999984],
            [-53.582127775999879, 9.163697416],
            [-53.577761213, 9.163303080000063],
            [-53.575280228999873, 9.162635980000019],
            [-53.57373649799996, 9.162496552000121],
            [-53.006722260999908, 9.009675124000083],
            [-53.005207534999897, 9.008967717000175],
            [-53.00455602, 9.00876387750013],
            [-53.001689281999944, 9.00796159000015],
            [-53.000172248000013, 9.007253086000148],
            [-52.9995497984998, 9.007062358000084],
            [-52.996656271999882, 9.006258069000154],
            [-52.995555928999977, 9.005744158000084],
            [-52.994661797999981, 9.005502592000084],
            [-52.992739490999924, 9.004624916500063],
            [-52.991333226999899, 9.00398825500011],
            [-52.990497119499963, 9.003733662000087],
            [-52.987604075, 9.002923317000011],
            [-52.985854439999912, 9.002154255500102],
            [-52.984246034999984, 9.001451152000058],
            [-52.983392979499968, 9.00118286350002],
            [-52.980576943999978, 9.000384178000061],
            [-52.974687005, 8.997633018000073],
            [-52.972538865999979, 8.997052555000096],
            [-52.970844601999858, 8.996326254000124],
            [-52.968603035333331, 8.995388465333406],
            [-52.965480972999984, 8.994533350000111],
            [-52.962460230999966, 8.993122269000068],
            [-52.961462015999984, 8.992852508000126],
            [-52.959686043499886, 8.992065826500095],
            [-52.957673266499881, 8.991213823500203],
            [-52.955743545999951, 8.990415669500067],
            [-52.95355135749989, 8.989474595000146],
            [-52.945339381999929, 8.985663219],
            [-52.868954344, 8.965011521000136],
            [-52.867287940499921, 8.964303278500111],
            [-52.865577373999827, 8.963574388000154],
            [-52.864867801499969, 8.963369194500004],
            [-52.86189648300001, 8.962552291000137],
            [-52.860097453999913, 8.96174982000008],
            [-52.858209568999939, 8.960971173000047],
            [-52.6640418369999, 8.877153263000096],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SVK", Country: "Slovakia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.558054056906286, 49.079436014199743],
            [22.551663190993366, 49.039436063392216],
            [22.534854122318364, 48.994854061381957],
            [22.461382214552629, 48.949436048346669],
            [22.425000228441831, 48.889436038316362],
            [22.388745144345506, 48.800200117566291],
            [22.328673217585731, 48.687000167932936],
            [22.235273167326568, 48.624709048482103],
            [22.173609181873189, 48.592764274286807],
            [22.159791279173788, 48.57825402638305],
            [22.151445082882475, 48.411918174886821],
            [22.150554086572498, 48.408609167141933],
            [22.098609253154308, 48.377982195767558],
            [22.06486320796148, 48.385136150147716],
            [22.029445140718508, 48.387500182125891],
            [21.965000207421838, 48.381944991961845],
            [21.935836045793764, 48.379164211756731],
            [21.852500146705523, 48.362145092587568],
            [21.828127080945734, 48.343464177290045],
            [21.780973175758049, 48.340691108435877],
            [21.722354168098747, 48.35533613734286],
            [21.625409243338709, 48.458882146148071],
            [21.617291202452606, 48.492500115860096],
            [21.507218205626316, 48.548882161193589],
            [21.438191221127028, 48.575345003058871],
            [21.308709089253654, 48.548044976702087],
            [21.253891274695007, 48.522218153651593],
            [21.153327211771852, 48.511109114427953],
            [21.084445066559681, 48.513609100876025],
            [20.946391265198969, 48.524718140099665],
            [20.870000108187156, 48.550827098011965],
            [20.843473228581445, 48.56763599904879],
            [20.818054101302494, 48.576173135094976],
            [20.713336134792087, 48.569164187640112],
            [20.652918203068253, 48.561664228295783],
            [20.539718085796608, 48.536944989933957],
            [20.500827060782228, 48.504436113653256],
            [20.4994450525829, 48.483054046231004],
            [20.451109131406014, 48.448745072419356],
            [20.435836130311259, 48.433609031622822],
            [20.414163210847988, 48.399718147142764],
            [20.402500127822947, 48.361245043821995],
            [20.363054053178757, 48.304582204731261],
            [20.340827089914058, 48.286109160633302],
            [20.317218118450029, 48.269718181289718],
            [20.283018109379896, 48.255718056014715],
            [20.235554073773784, 48.274991068954179],
            [20.14055408583198, 48.226109150602881],
            [20.09332709044844, 48.198327164812142],
            [20.059327073588719, 48.176309078575215],
            [20.010136198285494, 48.173882182322998],
            [19.969027154046756, 48.153473253862174],
            [19.939863327694667, 48.136245090027259],
            [19.910627081698948, 48.130482196301827],
            [19.786836094459545, 48.196909114428976],
            [19.755000117367558, 48.209445088853201],
            [19.653327128651256, 48.232918105847503],
            [19.630554168212257, 48.233891077170725],
            [19.530218093056078, 48.208927087235494],
            [19.508609211333265, 48.173044997831582],
            [19.497291127443873, 48.135900090891582],
            [19.472427217432795, 48.089436016337046],
            [19.208336078775346, 48.059445063777432],
            [19.139454101201267, 48.061673141285596],
            [19.041709207600007, 48.070636078014232],
            [19.001273230188048, 48.068953997680282],
            [18.989800248727533, 48.067100088330776],
            [18.965673275645742, 48.059800121196801],
            [18.907282255753501, 48.057973201575621],
            [18.84533613543843, 48.049127108577679],
            [18.827363323323226, 48.036127111992229],
            [18.771663229633845, 47.965554001628888],
            [18.75965414164483, 47.914718094003931],
            [18.783500153331431, 47.872082202278321],
            [18.820000156639225, 47.855554094998539],
            [18.854718167326979, 47.83166416213929],
            [18.849718194430807, 47.817773169243864],
            [18.788054041339365, 47.817218119614054],
            [18.757782295022423, 47.814354023291017],
            [18.729654136629534, 47.795482168238848],
            [18.682918153135262, 47.766382211989097],
            [18.663054048382463, 47.759718096031818],
            [18.636945090470164, 47.759164219868396],
            [18.597218222068932, 47.76305409350492],
            [18.54083617673524, 47.767491137782329],
            [18.449300261747652, 47.76735400984596],
            [18.413327144874955, 47.753882112110531],
            [18.378609134187201, 47.74721816379143],
            [18.337500257586441, 47.740827130240334],
            [18.304718131261467, 47.737500185222629],
            [18.195827150424435, 47.74777321342124],
            [18.125409105270194, 47.756109183790599],
            [18.086109211017799, 47.75943612880819],
            [17.976109136749471, 47.763609143134815],
            [17.908882249781101, 47.750691121562838],
            [17.89694507852127, 47.747554110471683],
            [17.787282292037759, 47.746427079767543],
            [17.712218157940356, 47.770554052849249],
            [17.485863353312567, 47.87796411902913],
            [17.462082217556741, 47.892082093863038],
            [17.445209111141367, 47.913053950942796],
            [17.411882161108366, 47.944745088747354],
            [17.341945069559699, 47.99575399885434],
            [17.311945064544574, 47.998053993092171],
            [17.263191221674191, 48.007500062721149],
            [17.251654034835155, 48.024991083040376],
            [17.239191150606871, 48.025691139595594],
            [17.181109087666499, 48.020764256895191],
            [17.16638225138405, 48.0125002032553],
            [17.116663316179341, 48.038609161167571],
            [17.081109126828551, 48.088336142999296],
            [17.0691451334786, 48.135264239714914],
            [17.033545178936265, 48.145264185507315],
            [16.998018146951722, 48.169700115540948],
            [16.975273182069429, 48.188327051381734],
            [16.952363261332039, 48.250273171696719],
            [16.949718100320581, 48.278045099203652],
            [16.923891277270059, 48.282082159060295],
            [16.844718166586489, 48.361973095933834],
            [16.855827205810101, 48.449436076519092],
            [16.865691197132605, 48.468745131642166],
            [16.907500130288582, 48.506944984918746],
            [16.933327120977168, 48.532773149073805],
            [16.943336119224966, 48.573327143682732],
            [16.946182278275188, 48.619064171953752],
            [16.988327158111332, 48.681664248356427],
            [17.022500177453082, 48.743609195205025],
            [17.041527097714464, 48.768327092462371],
            [17.112218225275001, 48.831800060264115],
            [17.187909158093504, 48.869445031548864],
            [17.23832714402505, 48.872427145068983],
            [17.33082714551864, 48.850273104362159],
            [17.382218102773436, 48.825273239881213],
            [17.411245136465311, 48.824718022613311],
            [17.502782224919372, 48.838882097914833],
            [17.650273211091758, 48.851945126412303],
            [17.677500147252601, 48.851945126412303],
            [17.712218157940356, 48.856109088283361],
            [17.760273285360086, 48.878191212260802],
            [17.841945041387106, 48.928327063330627],
            [17.911109153822906, 48.984991075887777],
            [18.075282139017702, 49.041945102295685],
            [18.12194503231612, 49.082773185139231],
            [18.149445050883145, 49.121391127747401],
            [18.145282262478503, 49.14500009921143],
            [18.158118141399143, 49.164718023572547],
            [18.17416328341659, 49.210273164544205],
            [18.176245180533158, 49.244300171132252],
            [18.211318081002275, 49.286382186694411],
            [18.275691265207655, 49.300973236144969],
            [18.312500225467403, 49.305691074179421],
            [18.383191185389848, 49.333053964810162],
            [18.544373172247191, 49.461800165582744],
            [18.565900246594737, 49.493673190686778],
            [18.655554089038134, 49.504164148368446],
            [18.792773220478296, 49.513882126937247],
            [18.851245041917394, 49.517354078880246],
            [18.968609121060155, 49.481664270335742],
            [18.968327153836526, 49.456382103354983],
            [18.974927064415397, 49.401936110022334],
            [19.031663328978141, 49.39193599659184],
            [19.159582235904935, 49.400273140427586],
            [19.191663299846397, 49.414027005386615],
            [19.199300219488947, 49.437773104787126],
            [19.242363253001457, 49.506800089286529],
            [19.26902709290539, 49.526664194039412],
            [19.4477731902779, 49.600827105904884],
            [19.475554170240144, 49.600000147335251],
            [19.520982241559324, 49.57416427182936],
            [19.535273218875346, 49.535554040572023],
            [19.577082152031238, 49.45901804427298],
            [19.65860923640912, 49.406664173978683],
            [19.71055406982731, 49.397500071573305],
            [19.778018164656231, 49.407491132548316],
            [19.805000181605664, 49.364718112886507],
            [19.825654029277985, 49.277564256891097],
            [19.798054098424757, 49.252773101799889],
            [19.773609115935614, 49.233054003972327],
            [19.765973202121387, 49.214436120586939],
            [19.783473274896011, 49.200200128555949],
            [19.842918235296622, 49.191800120446246],
            [19.867909214960179, 49.197127155205251],
            [19.911245163240892, 49.226173131998237],
            [19.936391208113463, 49.231109067153952],
            [19.979582149468968, 49.226391061481408],
            [19.998900257047467, 49.217627111134831],
            [20.020554065771421, 49.199436034260387],
            [20.039163231977284, 49.188891097122067],
            [20.073363241047446, 49.177873253005188],
            [20.09263608634862, 49.204854096488148],
            [20.103336088696153, 49.248600087473264],
            [20.14360912191006, 49.314991131054782],
            [20.206663329827961, 49.340273130397364],
            [20.327773281510247, 49.383327111454363],
            [20.360554066730572, 49.393053974840583],
            [20.602773299543003, 49.39555396128857],
            [20.740836153359027, 49.388882133980346],
            [20.812500252242984, 49.330827060768272],
            [20.913609138874079, 49.296109050080631],
            [20.955827108905964, 49.301664240244676],
            [20.982354156149853, 49.309436108528629],
            [21.035554095752985, 49.35971813999025],
            [21.050554014441559, 49.41054499515981],
            [21.071527212625767, 49.422082181998846],
            [21.28194516942537, 49.456391155810493],
            [21.400845148510314, 49.429054081441492],
            [21.436391123596081, 49.413891218554895],
            [21.459300206142956, 49.411945108270046],
            [21.500827172075333, 49.422218136468658],
            [21.53360913076213, 49.429718095812987],
            [21.612636061053934, 49.436527051057496],
            [21.838054104180287, 49.384436037247596],
            [21.958609173870741, 49.340273130397364],
            [22.020136199025927, 49.273882086815846],
            [22.025273299858355, 49.247218079273935],
            [22.029891225606832, 49.220236062324503],
            [22.225000139128042, 49.154718077780231],
            [22.323891174172644, 49.138609065660447],
            [22.348891206291711, 49.138473111190521],
            [22.537773203926349, 49.087918164960783],
            [22.558054056906286, 49.079436014199743],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SVN", Country: "Slovenia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.607873242467321, 46.476235983582896],
            [16.591991212286104, 46.474009079541119],
            [16.564454145706861, 46.477091105347242],
            [16.517791252408529, 46.491645106785754],
            [16.495482146492492, 46.50569116489028],
            [16.463891256250179, 46.52096416598495],
            [16.384163263574862, 46.535827124375302],
            [16.335554092353703, 46.533882187557126],
            [16.292509163751987, 46.530818099023648],
            [16.2675002468157, 46.514445056952965],
            [16.252409133020308, 46.495564149445372],
            [16.266109186160776, 46.41944506901136],
            [16.137918202655982, 46.405964118820449],
            [16.075318126253507, 46.378418167423874],
            [16.075836127871099, 46.358191126262412],
            [16.080409126618434, 46.33603607972718],
            [16.01721812604012, 46.298045103478373],
            [15.797782247227019, 46.233327087775407],
            [15.685973190610241, 46.225964256367476],
            [15.659163337952606, 46.220900078092782],
            [15.611945059748109, 46.166936044194003],
            [15.600418098831142, 46.144300044053395],
            [15.606391210688713, 46.115827054162835],
            [15.632918090294424, 46.08458216888458],
            [15.663609099409257, 46.06798214487533],
            [15.711491224346986, 46.05319110321453],
            [15.724445120464708, 45.938609145381733],
            [15.696109090872454, 45.843054107810147],
            [15.65263618229352, 45.823391168733892],
            [15.629718214929227, 45.833191122315938],
            [15.611800220460879, 45.846736110247306],
            [15.499445166669773, 45.835827063234021],
            [15.333891179104029, 45.766391209496604],
            [15.31583605669951, 45.756664178472406],
            [15.294027182766428, 45.734300087271336],
            [15.288645162722389, 45.700544989623111],
            [15.356627091530811, 45.712009086266207],
            [15.352591205140669, 45.651754098740597],
            [15.307091217092022, 45.629864255622451],
            [15.294000193038102, 45.594536042381961],
            [15.303973149102177, 45.538627071664806],
            [15.277218114091312, 45.462773194648079],
            [15.174454136854507, 45.425818053996451],
            [15.156673270322756, 45.429291179405951],
            [15.092027171349343, 45.471518034255539],
            [14.979063256109868, 45.500473151217776],
            [14.912836162555067, 45.482491119009168],
            [14.863718209809718, 45.468654105570394],
            [14.826091175797785, 45.465445010111793],
            [14.723609165784666, 45.531391142271374],
            [14.620836136092464, 45.612082215624113],
            [14.609582257581508, 45.63304502024836],
            [14.609791134609083, 45.668436097763006],
            [14.570345059964978, 45.666382196203259],
            [14.535000250556038, 45.612218170094124],
            [14.496109225541659, 45.543682197484074],
            [14.413473215370658, 45.491664106232051],
            [14.392500184824513, 45.486109083705898],
            [14.13721809115296, 45.475273126888467],
            [13.988263289405864, 45.463400161007243],
            [13.952500223027329, 45.456664128320583],
            [13.906945082055756, 45.453327125019186],
            [13.703609162906872, 45.444164196080109],
            [13.673473203421764, 45.445691211204846],
            [13.640482200069101, 45.453745046712513],
            [13.601391182844367, 45.473191062133893],
            [13.591736068774964, 45.481700202811666],
            [13.590527010000187, 45.483366360000062],
            [13.587668780000115, 45.48730417],
            [13.586272810000082, 45.488699840000109],
            [13.584242720000162, 45.490729930000029],
            [13.580614860000111, 45.493954700000032],
            [13.578539260000042, 45.495038040000154],
            [13.57597901000014, 45.496373820000102],
            [13.571746760000167, 45.498993390000024],
            [13.570816950000051, 45.499516400000047],
            [13.568521989999965, 45.500807320000021],
            [13.565169229000134, 45.502087405000154],
            [13.560809531000189, 45.50343914],
            [13.557593818000129, 45.504829014000066],
            [13.554753517000023, 45.505901162000143],
            [13.552065292000179, 45.50732703400017],
            [13.54976288600011, 45.508753718000079],
            [13.546840899000188, 45.510271484000057],
            [13.544727276000117, 45.511719186000036],
            [13.541228947000121, 45.514511050000024],
            [13.540668612000104, 45.515080544000071],
            [13.539495389000109, 45.516097377000094],
            [13.538272190000157, 45.517395830000154],
            [13.535423714000103, 45.520699285],
            [13.532071196, 45.522952081000071],
            [13.526970012, 45.52682215500009],
            [13.523037963000093, 45.530843415],
            [13.508600213000108, 45.544618033000106],
            [13.463980999111214, 45.590793996555604],
            [13.483341005000113, 45.599420219000038],
            [13.519830874000121, 45.615255800000128],
            [13.53505425100019, 45.619889764000121],
            [13.548684572000155, 45.622500802999966],
            [13.552216218000154, 45.623635559000078],
            [13.56614206100005, 45.627977732000076],
            [13.568782778000156, 45.628785022000088],
            [13.57878068600013, 45.632118707000032],
            [13.581249916000161, 45.632534212000039],
            [13.602608857, 45.64491118100004],
            [13.605328185000133, 45.646534897000109],
            [13.606602411000125, 45.647278352000072],
            [13.699108510000144, 45.605448531000022],
            [13.705299855000135, 45.601912631000019],
            [13.707250461000143, 45.601037297000076],
            [13.716945106086229, 45.596109157987101],
            [13.802218230727874, 45.586391179405538],
            [13.827773144838545, 45.58444506912069],
            [13.857218100224031, 45.587218137975057],
            [13.918336088502912, 45.636391076005467],
            [13.835282156638385, 45.717773153458026],
            [13.791527113197759, 45.752773131369295],
            [13.69750009657929, 45.784164197039345],
            [13.603745156538736, 45.808882094296692],
            [13.578327202726257, 45.850273105759058],
            [13.590691180496833, 45.888118069254162],
            [13.614100159750706, 45.905136014956938],
            [13.634718132877367, 45.947909034618746],
            [13.635100180025148, 45.982009131402705],
            [13.593336173870227, 45.993045080430406],
            [13.56895405565524, 45.979373190484736],
            [13.540282247020912, 45.968327015535053],
            [13.509863314483766, 45.986245010003401],
            [13.47971813490517, 46.01221801344569],
            [13.521945157392651, 46.063327003476743],
            [13.538891186365959, 46.079436015596727],
            [13.557363224635708, 46.087282147543007],
            [13.593609088638431, 46.105273232207097],
            [13.648191204079183, 46.145545091954531],
            [13.666036108351477, 46.175309062575579],
            [13.550554101753022, 46.218045034225341],
            [13.488473200434527, 46.231945079576164],
            [13.458954149024976, 46.223191187513194],
            [13.418891166305315, 46.213054113784594],
            [13.38347326670052, 46.295136080154194],
            [13.440836162346386, 46.346382198121646],
            [13.511391167798934, 46.3847181735063],
            [13.657773228178058, 46.44971815643278],
            [13.692009111793766, 46.45166426671743],
            [13.711109122251003, 46.492773143318189],
            [13.719163293034825, 46.518464179536963],
            [13.718654008596474, 46.526609042513385],
            [13.776109105177682, 46.518327051600565],
            [13.845554011370496, 46.520000079479033],
            [13.936945087070967, 46.515827065152493],
            [14.082218221656859, 46.48916423107697],
            [14.115836191368714, 46.478882150422962],
            [14.128891173238998, 46.463054099698283],
            [14.153682160692199, 46.442636118782062],
            [14.478054103137225, 46.41944506901136],
            [14.545000196348354, 46.4074911339453],
            [14.813191259136687, 46.511382141886244],
            [14.825000187277368, 46.54138214690137],
            [14.833745026884742, 46.57069114781676],
            [14.867500124533052, 46.613327039542256],
            [15.055136068438259, 46.652082110086738],
            [15.074409249015616, 46.648573110131608],
            [15.102918113451722, 46.65666416128947],
            [15.139445106487841, 46.657218037452893],
            [15.406109154151238, 46.652500199418327],
            [15.602500163585603, 46.686664166304681],
            [15.806663208942297, 46.718609108138068],
            [15.832782225138317, 46.722218020379287],
            [15.8590271375204, 46.723191159340686],
            [15.987918177580212, 46.755136101174159],
            [15.988327214456319, 46.831591128288338],
            [16.009309129819883, 46.835491060208781],
            [16.111800192288484, 46.869718059007155],
            [16.139791222744975, 46.859164237051417],
            [16.181036053815546, 46.864582131641001],
            [16.229445065188372, 46.876245047028064],
            [16.269718098401995, 46.874445117135096],
            [16.298191255930448, 46.869300137313544],
            [16.349027163555405, 46.842353994909828],
            [16.337218235414639, 46.809164172813311],
            [16.315482284039518, 46.794300208594748],
            [16.325273185166083, 46.755827105273895],
            [16.359300191754102, 46.720200161003149],
            [16.375327061222634, 46.722773237647274],
            [16.385554156591695, 46.701664252631232],
            [16.408609084254323, 46.658882180513942],
            [16.530973303931319, 46.509027162363381],
            [16.607873242467321, 46.476235983582896],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SWE", Country: "Sweden" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.481606388111175, 58.844569860222336],
            [20.476615360500148, 58.779565086000034],
            [20.475979811500139, 58.774178053500137],
            [20.461555045000125, 58.651910985000129],
            [20.441666670000075, 58.483333329000246],
            [20.397164703000101, 58.298812997000041],
            [20.380421750999972, 58.229391009000082],
            [20.373333330000207, 58.19999999900007],
            [20.396250144400142, 58.023999973000073],
            [20.410456307000118, 57.940263435000091],
            [20.415333311500092, 57.911516793500184],
            [20.362109418000216, 57.858737066000103],
            [20.334940664000158, 57.831795117000254],
            [20.235650000000192, 57.733333333000118],
            [20.094906952000144, 57.592230612000165],
            [20.066083333000023, 57.563333333000074],
            [20.058549675000108, 57.533770506000181],
            [20.036000000000143, 57.445283333000148],
            [19.892750000000149, 57.236533333000054],
            [19.775604986000133, 57.093862249000082],
            [19.695444189000142, 56.996234296000111],
            [19.671166667000023, 56.966666667000069],
            [19.663446597000132, 56.954928550000076],
            [19.528666667000095, 56.75],
            [19.468444207000118, 56.659439911000149],
            [19.44525896900015, 56.624574891000194],
            [19.431204363000148, 56.603440144000231],
            [19.417833333000146, 56.583333333000056],
            [19.397953551000114, 56.543573768999963],
            [19.391811806000163, 56.531290279000061],
            [19.351166667000058, 56.45],
            [19.237715717000157, 56.268599414500116],
            [19.226083333000133, 56.25],
            [19.09448340650016, 56.040550116500057],
            [19.093579714000128, 56.036482006000128],
            [19.090729346000103, 56.023649955000209],
            [19.081266667000079, 55.98105],
            [19.068684760000082, 55.957270653000094],
            [19.06748334460022, 55.955000000400119],
            [19.02253781800016, 55.93159547599997],
            [18.945283330000223, 55.89136666900005],
            [18.92575, 55.879799999000127],
            [18.900000351000187, 55.881266979000188],
            [18.363333351000108, 55.921549980000137],
            [18.000000351000153, 55.783082979000213],
            [17.37800035000015, 55.587249979000049],
            [17.000000350000164, 55.499999979000066],
            [16.533655349000185, 55.360569979000033],
            [16.508250000000118, 55.355166666000073],
            [15.768662003000117, 55.52491874600021],
            [15.330921702000097, 55.625389990000158],
            [15.042888889000125, 55.691499999000101],
            [14.460000000000122, 55.312222221000255],
            [13.992836471111275, 54.962441875777827],
            [13.785666670000182, 55.02136110900021],
            [13.147958642000077, 55.008304681000126],
            [13.055555556000229, 55.064999999000193],
            [12.794888889000134, 55.166666666000012],
            [12.677222222000125, 55.249999999000195],
            [12.638888889000071, 55.308333332000174],
            [12.641972364000139, 55.337278303000133],
            [12.610583330000111, 55.431722219000179],
            [12.715277778000086, 55.489194444000233],
            [12.729444440000179, 55.540861109000176],
            [12.881361110000142, 55.614166669000028],
            [12.895222221000125, 55.644166666999965],
            [12.846916667000187, 55.699166666000096],
            [12.708611110000106, 55.827194439000138],
            [12.693344115000173, 55.866687520000099],
            [12.685658489000105, 55.8829127300002],
            [12.671995155000161, 55.893160231000124],
            [12.666871404000233, 55.904261690000212],
            [12.659185778000165, 55.917925024999974],
            [12.652354111000079, 55.926464609000178],
            [12.655769945000088, 55.985387739000018],
            [12.659185778000165, 56.003320866000209],
            [12.656623903000138, 56.027231702000179],
            [12.654915986000134, 56.040041078000158],
            [12.64979223600011, 56.048580662000191],
            [12.642106610000212, 56.05712024600011],
            [12.631005151000153, 56.063951913000224],
            [12.620757650000201, 56.069075664000152],
            [12.603678482000163, 56.079323165],
            [12.586599313000107, 56.091278583000118],
            [12.572935979000079, 56.102380042000206],
            [12.557564727000141, 56.111773584000176],
            [12.531945975000156, 56.125436919000023],
            [12.500349513000117, 56.140808171000074],
            [12.464483260000122, 56.162157131000086],
            [12.416661589000171, 56.186921925000107],
            [12.363333333000156, 56.216361110000165],
            [12.358196406000104, 56.217993163000116],
            [12.236991577000083, 56.256501138000132],
            [12.087750000000113, 56.303916666000205],
            [12.108244352000128, 56.373893218000163],
            [12.147805556000151, 56.50897222100015],
            [11.399277778000169, 57.449999999000042],
            [11.048775470000095, 57.816834775000203],
            [10.542444444000125, 58.133361110000251],
            [10.030027778000203, 58.261444444],
            [10.146361084500086, 58.511444387000111],
            [10.450858163000078, 58.68135194900016],
            [10.594444444000089, 58.761472221000147],
            [10.640277778000012, 58.892777777000035],
            [10.757805556, 58.913944444000165],
            [10.820251048000046, 58.924946346999988],
            [10.870665760000151, 58.93502928900007],
            [10.924441454000146, 58.948473213000071],
            [10.96477322400014, 58.958556154999982],
            [11.008465975000149, 58.972000078999983],
            [11.042075783000143, 58.972000078999983],
            [11.075685590999967, 58.982083021000093],
            [11.095315485000043, 58.996907910000218],
            [11.10732169000002, 59.007501621000202],
            [11.115796659000182, 59.015976590000022],
            [11.123565380000031, 59.029395290000068],
            [11.129215359000227, 59.047051474000071],
            [11.13133410100022, 59.059763927000219],
            [11.134159091000186, 59.074595122000161],
            [11.141927812000034, 59.07953885400012],
            [11.156052760000051, 59.084482584999975],
            [11.176533934000048, 59.086601328000214],
            [11.188714614000133, 59.089060014000083],
            [11.212271062000184, 59.093771327999988],
            [11.235356562, 59.097540328000079],
            [11.245534779000081, 59.099295116000135],
            [11.249019344, 59.09989598200012],
            [11.262682344000012, 59.101780982000136],
            [11.283411562000083, 59.104607328000029],
            [11.294851261000076, 59.105534933000129],
            [11.300843562000182, 59.106020828000098],
            [11.317804344000024, 59.105549983000131],
            [11.329582344000016, 59.103664982000041],
            [11.338443713000032, 59.100938185000118],
            [11.347956062000151, 59.09801132799997],
            [11.361619203000174, 59.085762155000069],
            [11.371984062000138, 59.073041828000058],
            [11.382348562000175, 59.05937882800005],
            [11.393655562000106, 59.042418328000238],
            [11.397971873000188, 59.036070907000052],
            [11.401665344000094, 59.03063998200021],
            [11.409203062000074, 59.018861828],
            [11.416270344000054, 59.007554982000087],
            [11.424279344000126, 58.994833982000031],
            [11.429173923022034, 58.987636896715756],
            [11.423473162559958, 58.919163235665465],
            [11.431245030844082, 58.8910360831009],
            [11.456945119518252, 58.883745000784515],
            [11.496663270740243, 58.884718139745743],
            [11.585836159578093, 58.896663189994584],
            [11.599963186867399, 58.899182119543795],
            [11.624027128037255, 58.906936050555018],
            [11.75180018984841, 59.09513609654563],
            [11.741873166613885, 59.119991121739176],
            [11.752636033235291, 59.181245064488152],
            [11.761945142566219, 59.206245096606935],
            [11.769545181834445, 59.217536023130108],
            [11.792654088953697, 59.23783615448751],
            [11.797918091800824, 59.266527073860956],
            [11.79083605415002, 59.303327149303371],
            [11.783054127582091, 59.324718101542928],
            [11.739163297309801, 59.429718035276935],
            [11.667009192364702, 59.593573178702556],
            [11.764654006041894, 59.638045042504743],
            [11.818054105493445, 59.648682180578405],
            [11.899100236265753, 59.699718080413717],
            [11.903191275579132, 59.729300163735317],
            [11.898845091132557, 59.772473167817807],
            [11.896391205152099, 59.784718122562893],
            [11.882082290563176, 59.802353982169549],
            [11.815963323559458, 59.846100140792757],
            [11.852773289647672, 59.861382194343022],
            [11.875827211481976, 59.869991079480386],
            [11.96548222739176, 59.896945100873026],
            [12.003191236417138, 59.889582101827173],
            [12.03416320692719, 59.886109144055681],
            [12.107218198827724, 59.885827009193903],
            [12.134863224320185, 59.887636159180531],
            [12.163054079348854, 59.896945100873026],
            [12.191945158571002, 59.910273165149604],
            [12.316945151527619, 59.972636033691728],
            [12.46944516305166, 60.070000221249572],
            [12.494509232911241, 60.113044982213196],
            [12.502782171368381, 60.144718182745024],
            [12.507845176176659, 60.174091221400957],
            [12.506109116385971, 60.202073199401795],
            [12.532773291566173, 60.339027127424956],
            [12.571945110337765, 60.378054106908948],
            [12.589718097880592, 60.399163259563252],
            [12.60013613300444, 60.433882108441239],
            [12.607218170655159, 60.468400126918723],
            [12.590973204065449, 60.521800058732211],
            [12.505836033979875, 60.630000203107599],
            [12.425000121339792, 60.710273187128919],
            [12.382500184084108, 60.75555407222781],
            [12.364718144085771, 60.779445010915467],
            [12.352363218770677, 60.802218138992643],
            [12.33610919972557, 60.835827056249158],
            [12.308609181158545, 60.887218181142018],
            [12.271945060185857, 60.946109097740916],
            [12.239582196659057, 60.976936061325759],
            [12.216218144406326, 60.998745102897018],
            [12.247773160102923, 61.018609207649902],
            [12.294163306271315, 61.02944499682927],
            [12.388054033143675, 61.050553981845198],
            [12.407773298609357, 61.053891152784971],
            [12.432500248322015, 61.054445028948223],
            [12.458054156604589, 61.053891152784971],
            [12.500273300102975, 61.050827064251493],
            [12.567218219847717, 61.048045110579793],
            [12.602218197759157, 61.049718138458346],
            [12.621945174575671, 61.053053968293469],
            [12.637827204756888, 61.057536107209827],
            [12.670273216763547, 61.08777315244761],
            [12.772218114419559, 61.200827089327262],
            [12.796663264546964, 61.244991169644166],
            [12.831109198656918, 61.312218056612366],
            [12.856109230775786, 61.362500088074071],
            [12.773891142298112, 61.414718171536649],
            [12.526527097302306, 61.566663301068743],
            [12.474863225279506, 61.569582215038665],
            [12.437500221218187, 61.56901811295343],
            [12.399718121997125, 61.576936161629177],
            [12.144445080780969, 61.717500175359859],
            [12.124445021558188, 61.728609046945522],
            [12.159445167107634, 61.844445105135023],
            [12.170000162529846, 61.878609072021462],
            [12.181663245554887, 61.912500124139584],
            [12.200282134768656, 61.963336031764541],
            [12.215000253871608, 62.006100166608846],
            [12.258609116920525, 62.142491166013073],
            [12.294618108338767, 62.258218091823025],
            [12.293745049301634, 62.267082122093868],
            [12.271391184022633, 62.308054038396406],
            [12.256391265333974, 62.327218086594016],
            [12.245345090384291, 62.337982126682064],
            [12.204863347780901, 62.397353996886821],
            [12.178336132899119, 62.430827127311247],
            [12.149163254091775, 62.46027325616312],
            [12.084718153156842, 62.529163280364216],
            [12.047500156021101, 62.590000139609984],
            [12.046663306805669, 62.66527315073499],
            [12.07277327054635, 62.715827091136518],
            [12.08944504364689, 62.749445060848345],
            [12.066663198390387, 62.803054037327499],
            [12.050282277330808, 62.838882147275129],
            [12.028891157453245, 62.892491123754283],
            [12.058336112838447, 62.918609134122065],
            [12.113336149972525, 62.967218137705046],
            [12.15110919673819, 62.999445046762148],
            [12.168609101874921, 63.015827141288227],
            [12.144163281195347, 63.04527327013993],
            [12.036945160598975, 63.173882175338136],
            [12.027491211980816, 63.182445127646062],
            [11.936391155959456, 63.272218160752928],
            [11.998891152437949, 63.323882200414076],
            [12.078336172061256, 63.388327133710746],
            [12.137218203842849, 63.43666322252561],
            [12.195000194648799, 63.485273231937086],
            [12.178609215305386, 63.512218033236238],
            [12.146663267643447, 63.589573109116159],
            [12.346663189319315, 63.728882184299792],
            [12.473054075293049, 63.833327068404074],
            [12.530836066098914, 63.872773143048093],
            [12.633891230652665, 63.942773266508809],
            [12.681945184606093, 63.967218081360059],
            [12.794445077684344, 64.007218032167671],
            [12.846945128370521, 64.025273154572133],
            [12.938054069209187, 64.053327049302581],
            [12.988336100670722, 64.064436088526222],
            [13.032218213763855, 64.071109089300919],
            [13.135554004436813, 64.083600136724002],
            [13.19360924528695, 64.090000222730581],
            [13.230000116215251, 64.093045032886593],
            [13.291391186900512, 64.086653999335496],
            [13.985000214490611, 64.015545117719441],
            [14.150691162354661, 64.179845004928907],
            [14.150554034418462, 64.33929109963357],
            [14.116391240998524, 64.470554050660766],
            [14.032500124642468, 64.488054123435475],
            [13.900836183366266, 64.507491086401203],
            [13.820273185494329, 64.529436082442359],
            [13.668263347669608, 64.580200073337849],
            [13.676945155364933, 64.607773182100843],
            [13.702218102251834, 64.634573144112736],
            [13.725573269687231, 64.652263150813823],
            [13.8327821701902, 64.733318166403748],
            [13.879163263903109, 64.771100097986888],
            [13.955827168045118, 64.835536146466055],
            [14.091391208879571, 64.949154017793106],
            [14.235282167628043, 65.048873184873841],
            [14.296436198090731, 65.102127103933597],
            [14.308836050407052, 65.115236065260362],
            [14.319445025285802, 65.129991232375502],
            [14.329163339130844, 65.149718041554209],
            [14.356109146258206, 65.208600073335802],
            [14.368891213360399, 65.246654081496743],
            [14.493054021825685, 65.313600174707986],
            [14.494027160787113, 65.367636125336148],
            [14.49528226697214, 65.446382094232646],
            [14.497218151335005, 65.516100082831755],
            [14.500554148808106, 65.585827123886375],
            [14.534718115694432, 65.700963293158949],
            [14.565282222794877, 65.736373146136728],
            [14.588054009767376, 65.756945018795776],
            [14.603891280585543, 65.773327113321855],
            [14.621391185722274, 65.79720916719198],
            [14.633191229045536, 65.82180016243494],
            [14.608891253481687, 65.876391162692997],
            [14.580273256666004, 65.931109064965469],
            [14.569445178837498, 65.949436096309725],
            [14.537636191474121, 66.016382189520968],
            [14.519163315014225, 66.078891238454773],
            [14.507082142657396, 66.123600142479617],
            [14.717782234318719, 66.140544997986424],
            [14.981391245904234, 66.149154050761993],
            [15.025273191359361, 66.149991235253495],
            [15.468054100999723, 66.283873195753969],
            [15.446854088515011, 66.321382212568821],
            [15.400554131625256, 66.406936130881618],
            [15.371391143463484, 66.46165403315409],
            [15.362782258326092, 66.480000175237663],
            [15.52777314963501, 66.558045081750734],
            [15.625827167826287, 66.605818074308687],
            [15.731109236421986, 66.685000069809703],
            [16.010000133919675, 66.890827090589241],
            [16.353882297164432, 67.017773193830905],
            [16.402500185564918, 67.170545114294541],
            [16.361382256508676, 67.237782227184894],
            [16.337500202638353, 67.260818044108206],
            [16.26194505665157, 67.305545053043843],
            [16.212636164151434, 67.333736075710519],
            [16.161663296228085, 67.355818032049896],
            [16.138609206755802, 67.367491173358744],
            [16.107636062779278, 67.385691135050777],
            [16.088054092888058, 67.405400174594476],
            [16.199163260587625, 67.500827136685004],
            [16.382500126342109, 67.515545088150034],
            [16.403327144134465, 67.530000183130582],
            [16.508327077868472, 67.60916323553046],
            [16.5736091956569, 67.661236144429452],
            [16.588054064715379, 67.682218059792817],
            [16.620282147239038, 67.73220907157561],
            [16.68471819571829, 67.832218084868956],
            [16.726945050567707, 67.899154119796194],
            [17.1883270797872, 68.030273237364455],
            [17.234545061663624, 68.062100162000846],
            [17.252582246795498, 68.074573272150914],
            [17.273609089159919, 68.090544988696522],
            [17.592500105103312, 68.029709135279248],
            [17.648891202892401, 68.014163219416176],
            [17.680273216106883, 68.004436020754],
            [17.743327088748799, 67.984709211575293],
            [17.801936038124268, 67.964154102722588],
            [17.825554062043807, 67.955273141007396],
            [17.858054053507004, 67.94860919268821],
            [17.884163346695345, 67.945545104154903],
            [17.939718098183249, 67.997773245901087],
            [18.135554057987832, 68.150273257424928],
            [18.155282208270819, 68.166109187138616],
            [18.103882198560655, 68.280818046985999],
            [18.085827076156193, 68.317763296991799],
            [18.06944514926812, 68.354427082688133],
            [18.04958221798168, 68.400273243338731],
            [18.058054142820851, 68.439991226922743],
            [18.086245165487696, 68.499300065215337],
            [18.099536181752001, 68.508927183953276],
            [18.14943616606584, 68.515273122865224],
            [18.358054025334582, 68.539154003269076],
            [18.611945058351296, 68.475263281411983],
            [18.952218141716742, 68.487782156753681],
            [19.413327088529769, 68.419436118070209],
            [19.543891156468135, 68.399718193708836],
            [19.717218185784589, 68.372773224771706],
            [19.86055409490325, 68.349991211877196],
            [19.898327141668886, 68.341382159101656],
            [19.930827133132084, 68.337073190305304],
            [19.952363259935055, 68.342763161472774],
            [19.970418214701681, 68.355545060936791],
            [20.006663240514172, 68.381100142685639],
            [20.039163231977369, 68.399154091623799],
            [20.075836070129299, 68.414718112397665],
            [20.171527062170526, 68.445963165313813],
            [20.20423609829956, 68.470891113065321],
            [20.088054035146001, 68.501663259003195],
            [19.956391267336357, 68.543882234863602],
            [20.063054170665112, 68.583054053635195],
            [20.177218206804497, 68.646945110768442],
            [20.20250020614705, 68.662218111863197],
            [20.238327142628037, 68.691373221035789],
            [20.313891173432324, 68.754718113356816],
            [20.350273159543036, 68.786654002734778],
            [20.314718132001872, 68.928327109896784],
            [20.238891244713272, 68.968600143110521],
            [20.09694505514517, 69.04221806362996],
            [20.535273162530359, 69.056382138931568],
            [20.580927209959526, 69.06030017576272],
            [20.604163354369348, 69.053054020447391],
            [20.650063326838591, 69.043791179222424],
            [20.744163266014993, 69.031100139588816],
            [20.791663343804828, 69.023327097838475],
            [20.846382251905709, 69.011936091391235],
            [20.875554124884815, 69.001245141499098],
            [20.908882248384202, 68.985544998617328],
            [20.930691122317285, 68.970691092682486],
            [20.930973257178891, 68.949227050247075],
            [20.885900243279281, 68.918191209634571],
            [20.900827071771971, 68.89207319926679],
            [20.952082242194933, 68.880682192819719],
            [20.990836139272915, 68.879436139090004],
            [21.024718138935555, 68.877491202271827],
            [21.05889115827739, 68.873318187945202],
            [21.081391203948272, 68.866654071988009],
            [21.212218128370864, 68.817909113934931],
            [21.420554020416006, 68.724154006256285],
            [21.45389119637079, 68.686727132092884],
            [21.494163223756118, 68.669854025677338],
            [21.550554153907228, 68.661654009777791],
            [21.593191219099111, 68.65734504098144],
            [21.623054096177952, 68.649427159943698],
            [21.642773194005542, 68.642491135046598],
            [21.706591160942935, 68.614991116479459],
            [21.726945104118812, 68.587354137614042],
            [21.765418207439666, 68.575682169771667],
            [21.81694511916433, 68.570400229651895],
            [21.864718111722198, 68.573609157472603],
            [21.889300222147824, 68.571245125494428],
            [21.933891276613565, 68.555263183027037],
            [21.95944518489577, 68.543882234863602],
            [22.000827143902825, 68.521518143662618],
            [22.028745084163376, 68.502345043009527],
            [22.048609188916373, 68.481518192855006],
            [22.159163306986216, 68.464991091403519],
            [22.37194512812593, 68.463609083204275],
            [22.430554077501398, 68.451663194765288],
            [22.500000157160798, 68.44012718139264],
            [22.581663196008662, 68.427218044637968],
            [22.666800198456258, 68.421654137294567],
            [22.826663209026123, 68.385963155283591],
            [22.863609129584148, 68.357500223676922],
            [22.905000141046429, 68.329991152654387],
            [22.936391206716479, 68.322218110903876],
            [22.96944507434327, 68.317763296991799],
            [23.055418087815895, 68.291100127640121],
            [23.353336073213143, 68.083600078982002],
            [23.372218154187124, 68.0601270619877],
            [23.394445117451795, 68.042500087198576],
            [23.531663243063662, 67.992491138143222],
            [23.638336204676108, 67.958054088850801],
            [23.659791194656123, 67.945891109118961],
            [23.655691270525523, 67.921173211861614],
            [23.602082294046284, 67.896382224408299],
            [23.545554068320911, 67.890000075674536],
            [23.511109140039338, 67.883609209761616],
            [23.488818139034123, 67.870963264767525],
            [23.470554139602001, 67.819854107098294],
            [23.491391215678249, 67.712491147214322],
            [23.507773310204328, 67.665545113225889],
            [23.471945032618862, 67.556382223449063],
            [23.430827103562507, 67.479782189409676],
            [23.44832717633733, 67.452500100325622],
            [23.469300206883474, 67.442909191409328],
            [23.493745189372703, 67.437218046775357],
            [23.519718192814992, 67.443318060647343],
            [23.542909242585409, 67.452627169978015],
            [23.581945106887105, 67.45000011387755],
            [23.735000168040955, 67.426100122734411],
            [23.765136127525977, 67.41687315605499],
            [23.780345090880189, 67.326309206562215],
            [23.743191131484991, 67.286318140572106],
            [23.708054025637239, 67.278045034476818],
            [23.683054161156264, 67.275545048028548],
            [23.630273316712959, 67.26749121252108],
            [23.610000175084053, 67.261373261376278],
            [23.590554159662673, 67.240682198053676],
            [23.573327169294316, 67.157009178818768],
            [23.681109224337916, 67.047482179166977],
            [23.731109120937845, 67.008327124201898],
            [23.751109180160825, 66.995973204715128],
            [23.787773301133143, 66.981382155264598],
            [23.866945070712177, 66.93110917625846],
            [23.94069123435051, 66.879773204288725],
            [24.000763328748377, 66.802282173939105],
            [23.94250021669896, 66.787909053971632],
            [23.892082230767613, 66.744782150356841],
            [23.900836122830299, 66.712491203559225],
            [23.902773180659636, 66.680263288673913],
            [23.889718198789353, 66.575827121748873],
            [23.87416323047097, 66.555345103092307],
            [23.82694511990465, 66.543882179915599],
            [23.807218143088136, 66.537763222942303],
            [23.725273304654735, 66.500827025392013],
            [23.656036102023194, 66.457209109887671],
            [23.63971821287555, 66.433318171200014],
            [23.662500225770145, 66.312209057708046],
            [23.684718136579335, 66.263318086901265],
            [23.726109148041786, 66.198254066234441],
            [23.754718092402044, 66.185000097982183],
            [23.814300180738769, 66.168182144489762],
            [23.856109113894831, 66.16027314826944],
            [23.886391253771649, 66.156663230199726],
            [23.919027199704828, 66.144163297959139],
            [23.935554133518139, 66.12846315507737],
            [23.948609115388535, 66.101382231670343],
            [23.967782216041627, 66.072491152448364],
            [24.031391138313268, 66.020263178340159],
            [24.164091250462832, 65.832636119252285],
            [24.167009158802216, 65.814027120842226],
            [24.16681749900016, 65.812913169000083],
            [24.166661271000095, 65.812006198000105],
            [24.164145907000091, 65.797406190000103],
            [24.15762457600016, 65.761901169000154],
            [24.157464510000153, 65.761029698000058],
            [24.157283567000093, 65.760044565000086],
            [24.157034416000101, 65.758688073000215],
            [24.157034416000101, 65.740514263],
            [24.156261399000073, 65.731238066000159],
            [24.153873753000056, 65.702586312000037],
            [24.152772832000096, 65.68717341300011],
            [24.152293422000128, 65.680461673000067],
            [24.151924083000125, 65.67566027000008],
            [24.150713091000114, 65.659917366000101],
            [24.148332805999985, 65.639684946000116],
            [24.147552428000068, 65.633051733999977],
            [24.146477658000151, 65.623378805000172],
            [24.144391765000137, 65.60460577000002],
            [24.143601600000125, 65.584061463000253],
            [24.14322607400004, 65.579930675000057],
            [24.142557144000136, 65.572572450000095],
            [24.141231103000109, 65.557985996000056],
            [24.14062888050006, 65.544295994000109],
            [24.1400000000001, 65.529999999000054],
            [24.137987969000164, 65.518398030000142],
            [24.137163477000172, 65.513643763000132],
            [24.136933330000033, 65.5123166690001],
            [24.138560621000124, 65.495611329000084],
            [24.142111175750159, 65.459162284250098],
            [24.148271610500046, 65.395919778000078],
            [23.005302947000189, 64.649590698000125],
            [21.500000000000227, 63.666666669000094],
            [20.939999920000105, 63.521666657000068],
            [20.696666670000013, 63.48499999900001],
            [20.63078660700009, 63.451319742000138],
            [20.569293744000078, 63.419882379000214],
            [20.400000000000205, 63.333333328999956],
            [19.64139705499997, 62.784249296000112],
            [19.524999993000108, 62.699999949500111],
            [19.235000000000184, 60.678333329000097],
            [19.18564895700024, 60.483074865000148],
            [19.158333330000119, 60.374999999000167],
            [19.158333330000119, 60.371666800000057],
            [19.145568873000116, 60.340367360000087],
            [19.134154253000162, 60.31237794500008],
            [19.1027, 60.235249999000118],
            [19.083200193500062, 60.191683197500097],
            [19.217784452000132, 60.098072932000079],
            [19.658283333000043, 59.791683332000119],
            [19.907773738000117, 59.616973693000219],
            [20.153333333, 59.445016666000058],
            [20.241062315000107, 59.284399413666748],
            [20.419455439000217, 58.957791118000202],
            [20.471266667000037, 58.862933332000111],
            [20.481606388111175, 58.844569860222336],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SXM", Country: "Sint Maarten" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-63.102816314999927, 17.812076913000041],
            [-63.110196272999929, 17.815746009000136],
            [-63.13172, 17.824861],
            [-63.13811, 17.827667],
            [-63.15783, 17.834333],
            [-63.16781, 17.8378060000001],
            [-63.17731, 17.840667],
            [-63.18378, 17.842944],
            [-63.1911, 17.844867],
            [-63.19658, 17.846306],
            [-63.20385, 17.848431],
            [-63.284350917000012, 17.87193953200007],
            [-63.290176064999969, 17.873241763000081],
            [-63.248727136999918, 17.920329006000017],
            [-63.244301267999901, 17.92528743900003],
            [-63.234853241999957, 17.936250750000042],
            [-63.184446621999911, 18.003316112000064],
            [-63.162104802999977, 18.034969992000057],
            [-63.153877833, 18.047977445000114],
            [-63.148599696999895, 18.053168460000094],
            [-63.144970041999954, 18.055749902000073],
            [-63.01053105799997, 18.056549974000106],
            [-62.997360716999964, 18.056549506000053],
            [-62.975959454, 18.047290035000074],
            [-62.928999154999985, 18.051083279000139],
            [-62.950105720999915, 18.022693605000043],
            [-62.950697744999957, 18.021893626000107],
            [-62.955330317999938, 18.015434743000114],
            [-62.973877444999943, 17.990227224000066],
            [-63.000331595999938, 17.971780100000061],
            [-63.004042489999932, 17.968071893000101],
            [-63.011614359, 17.956040642000104],
            [-63.012567907999937, 17.954540217000059],
            [-63.013534285999953, 17.953090329000062],
            [-63.021983740999985, 17.940505995000066],
            [-63.044005947999921, 17.90608616100009],
            [-63.102816314999927, 17.812076913000041],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SYC", Country: "Seychelles" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [59.386944555000099, -8.436944655999937],
            [58.338611554000153, -8.558055655999922],
            [57.252499553000092, -8.672499656000014],
            [55.933333552000164, -8.815277656000035],
            [54.467777551000182, -8.966666655999859],
            [53.90472255, -9.637777656999987],
            [53.86611155000017, -10.604166657999954],
            [53.823611550000095, -11.73749965899998],
            [53.800555550000155, -12.297777659999909],
            [53.793388806000195, -12.306489424999882],
            [53.780224377000224, -12.3224916409999],
            [53.777278973666824, -12.325448664666538],
            [53.566632023000153, -12.534652674999961],
            [53.381666550000062, -12.718333659999814],
            [50.483333547000058, -11.516666658999867],
            [50.199999547000203, -11.083333657999987],
            [49.433333546000114, -10.283333657999989],
            [48.485277545000116, -11.020832657999961],
            [46.911110544000138, -10.650277657999936],
            [45.767499836800113, -11.139721854799944],
            [45.51666666700001, -10.916666666999845],
            [44.992973945000159, -10.550081761999948],
            [44.849999838000059, -10.449999872333308],
            [43.195446917000169, -8.013027249999908],
            [43.203793833000219, -7.983157805999852],
            [43.212139556000096, -7.953288110999964],
            [43.22048405600006, -7.923418221999867],
            [43.228827333000169, -7.893548138999947],
            [43.237169417000104, -7.863677833],
            [43.245510333000055, -7.833807305999855],
            [43.25385002800013, -7.803936582999967],
            [43.262188556000211, -7.774065666999874],
            [43.270525918000118, -7.744194317999941],
            [43.278161693000158, -7.729102277],
            [43.291898540000119, -7.702042654999985],
            [43.29265330150011, -7.700582151499901],
            [43.294376656333412, -7.698122978166566],
            [43.29678654, -7.693156655],
            [43.636851536000137, -7.22091065],
            [43.640633425666721, -7.217218244333267],
            [44.057377535000143, -6.810180137000017],
            [44.53820277700018, -6.480573098],
            [44.540789799000066, -6.479393939999952],
            [44.541484088000146, -6.478917071000012],
            [44.542728613000094, -6.478348095799902],
            [44.543172541000132, -6.478054653999976],
            [45.073701542000123, -6.235678653999969],
            [45.074617543500182, -6.235438993499997],
            [45.075540167000128, -6.235022093999916],
            [45.077773542000074, -6.233999653999959],
            [45.079634542000036, -6.233513653999978],
            [45.0819011990001, -6.232475415999971],
            [45.383712789000157, -6.153352339999969],
            [45.646459543000077, -6.084494653999968],
            [45.648490225000131, -6.084324938999956],
            [45.650252331000189, -6.08417776899995],
            [45.652214895000128, -6.083661710999877],
            [46.233737684000147, -6.034627971],
            [46.235389882000078, -6.034775130000014],
            [46.237580543000064, -6.034970653999849],
            [46.241431433000088, -6.034642507999877],
            [46.242114832333442, -6.034609824333359],
            [46.251883543000162, -6.033814653999954],
            [46.25764654300022, -6.034327653999938],
            [46.264280543000069, -6.033762653999915],
            [46.267203483000145, -6.034022349],
            [46.268557298000161, -6.034142820999833],
            [46.292340518000145, -6.032115691999977],
            [46.293493550000136, -6.032218202999928],
            [46.301256543000051, -6.032908653999954],
            [46.310589543000077, -6.032112653999988],
            [46.323465373000062, -6.033256399999942],
            [46.324312692000177, -6.033331685999954],
            [46.325969836000155, -6.033190213999973],
            [46.338654543000217, -6.03210765399993],
            [46.341626543000103, -6.032371654000016],
            [46.343373392000132, -6.032222007999977],
            [46.345198594000152, -6.032066129999961],
            [46.359132961000142, -6.03330292299998],
            [46.368765030000219, -6.032479598999885],
            [46.383276543000107, -6.031239653999876],
            [46.390179543000073, -6.031851653999979],
            [46.394888101000021, -6.031448399999917],
            [46.397360276000114, -6.03123693500001],
            [46.97871801800008, -6.082313150999852],
            [46.979658424000178, -6.082563955],
            [46.98044354400011, -6.082773653999851],
            [46.982519805000123, -6.08295415299996],
            [46.98446991900019, -6.083123937999972],
            [46.985855108000152, -6.083493337999982],
            [46.987720044000156, -6.083986653999858],
            [46.98864654400009, -6.084229653999884],
            [46.992916459000156, -6.084600978999973],
            [46.995281471000084, -6.084806824999944],
            [46.996157954500092, -6.085040585999934],
            [46.997929272000221, -6.085225639999962],
            [47.002622343000127, -6.085634021],
            [47.006343822000218, -6.086626247999945],
            [47.008493544, -6.08719965399996],
            [47.030055449000116, -6.089074276999895],
            [47.037634209500112, -6.089786062],
            [47.040129544000109, -6.090451653999907],
            [47.041254322000071, -6.090548924999965],
            [47.048010488000074, -6.092304083999878],
            [47.050323544000065, -6.092920653999911],
            [47.05152954400009, -6.093025653999987],
            [47.054975593000137, -6.093943696999915],
            [47.057539250000019, -6.094598475000012],
            [47.058871544000141, -6.094714654000029],
            [47.0670055410001, -6.09688153],
            [47.071262636500109, -6.098013739999885],
            [47.074296847666773, -6.098797646333239],
            [47.638538405000105, -6.248598616999956],
            [47.641702431500079, -6.250019772499911],
            [47.642709544000155, -6.250286654],
            [47.645312544000063, -6.251488653999985],
            [47.646058837000083, -6.251686047999883],
            [47.646881162000085, -6.251903712999862],
            [47.655824464, -6.25599586649993],
            [47.826562430000223, -6.334750143],
            [47.991412555000039, -6.348644314999959],
            [48.153304545, -6.362289653999923],
            [48.456212401000158, -6.441737412999871],
            [48.718021252, -6.51040600099995],
            [48.832238512000089, -6.562603004999986],
            [48.952303087000161, -6.480030698999968],
            [49.037906546000073, -6.421158654],
            [49.038755348000137, -6.42076991499998],
            [49.039535812666685, -6.420396665999917],
            [49.116900463000121, -6.384900688999906],
            [49.469300866000054, -6.223277018999966],
            [49.488246236000037, -6.155645036999857],
            [49.491425546000102, -6.144295653999876],
            [49.485655546000061, -6.070779153999879],
            [49.485641546000096, -6.070164653999925],
            [49.541589546000154, -5.492715652999891],
            [49.697047546000107, -4.933581653000019],
            [49.743548163000099, -4.836149592999917],
            [49.947117940000027, -4.409614106999982],
            [50.083503100000115, -4.218320261999864],
            [50.112346547000158, -4.177864651999926],
            [50.125071905000055, -4.131651287],
            [50.173062594000129, -3.957368936],
            [50.189076830000062, -3.923661139999979],
            [50.222375547000098, -3.853572651999926],
            [50.241089087, -3.785475924999915],
            [50.258055726000151, -3.723736982999881],
            [50.369882432000139, -3.48780992199984],
            [50.506639547000162, -3.199286650999866],
            [50.73596890200011, -2.875645179999964],
            [50.842229899000102, -2.725684402999917],
            [51.244862439000173, -2.326913710999889],
            [51.254686547500199, -2.317189649999918],
            [51.255756955000066, -2.316133708499905],
            [51.732816881000218, -1.984878609999896],
            [52.259726321000045, -1.741259977000013],
            [52.495889912000138, -1.678664802000014],
            [52.592208549000105, -1.653135649999982],
            [52.634649690000202, -1.592732248999965],
            [52.651124470000155, -1.569363536499949],
            [52.652059805666767, -1.568388722333324],
            [52.705525994000169, -1.515028661],
            [53.063114549000176, -1.158387648999962],
            [53.528097269000142, -0.833314738000013],
            [53.538749552000098, -0.825867651999971],
            [53.590524455000121, -0.801738072999925],
            [54.064693788000085, -0.580752999000026],
            [54.625069666000201, -0.430448700999975],
            [55.20319560066676, -0.379514796666669],
            [55.204384722500123, -0.379467178499937],
            [55.406104750000196, -0.396847250999954],
            [55.782584552000088, -0.42937064799986],
            [56.039010552000065, -0.497722648999854],
            [56.15789827200004, -0.507890368999853],
            [56.242018384000204, -0.515084835999957],
            [56.802658423000167, -0.664020975999961],
            [57.088752358000221, -0.796520633999975],
            [57.329021553000103, -0.90779764899996],
            [57.805238554000113, -1.239034649000018],
            [57.807268925666818, -1.241041676],
            [58.219061554000092, -1.649774649999983],
            [58.450259695000085, -1.976941099999863],
            [58.553879506000129, -2.123572978999988],
            [58.554718952666718, -2.125326515999959],
            [58.555379704000103, -2.12634248],
            [58.55638355500011, -2.12776165],
            [58.796153555000075, -2.635415650999917],
            [58.804138893000157, -2.646678531999939],
            [58.810552720000061, -2.655724990999886],
            [58.811325055000196, -2.657266651],
            [58.943045505000129, -2.935288569999983],
            [59.059850245000149, -3.181966654499931],
            [59.060458661000126, -3.183306612499948],
            [59.104446606000039, -3.343227655999925],
            [59.214309185000133, -3.742858571999989],
            [59.232768797000091, -3.939212871999942],
            [59.236000555000061, -3.973589651999987],
            [59.241230555000158, -3.992550651999949],
            [59.295864555000122, -4.570130651999989],
            [59.295849398333445, -4.570792371333269],
            [59.249254519000061, -5.149322792999911],
            [59.237379436000111, -5.194762905999895],
            [59.184703388000145, -5.396327445999916],
            [59.186031628000165, -5.39818218],
            [59.192102555000048, -5.406659652999934],
            [59.237138826, -5.500585397],
            [59.443254313000153, -5.930451349999899],
            [59.486934021000053, -6.086492452999977],
            [59.599739556000117, -6.489479653999908],
            [59.624155832000184, -6.73744418899993],
            [59.656596153000038, -7.066903051999958],
            [59.656429535000115, -7.069046516333273],
            [59.656624556000082, -7.071089654999966],
            [59.656502381000081, -7.072664925000012],
            [59.656389305000147, -7.074119743999944],
            [59.656557783000068, -7.075822732999896],
            [59.656664556000095, -7.076903654999981],
            [59.656371556000209, -7.080673654999984],
            [59.65655138200006, -7.082488550999869],
            [59.656825157000156, -7.085255587999839],
            [59.612023343000061, -7.663643050999895],
            [59.610985296000223, -7.667650941999952],
            [59.609656556000147, -7.672780654999926],
            [59.609605781000113, -7.673441067999946],
            [59.524581031000054, -8.001897697999908],
            [59.464005248500115, -8.235661106499947],
            [59.463830820000027, -8.236286369000027],
            [59.46150914450007, -8.245003638999918],
            [59.461042513000137, -8.246114516499958],
            [59.460622555000242, -8.247483155999902],
            [59.459988555000081, -8.249678655999929],
            [59.408324308000175, -8.382132477999974],
            [59.386944555000099, -8.436944655999937],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "SYR", Country: "Syria" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.355609180633707, 37.106927153537228],
            [42.378327155787673, 37.072064135924265],
            [42.37410016200451, 37.063164231107677],
            [42.367291206759973, 37.055891086064022],
            [41.835273202903153, 36.598882231019573],
            [41.403045109390092, 36.52555398907468],
            [41.367209120453623, 36.472773144631347],
            [41.290273307371791, 36.355554072413995],
            [41.25499119459883, 36.054991093643551],
            [41.271100206718614, 36.027773209938019],
            [41.356936092255097, 35.876382124207325],
            [41.378327212132746, 35.836936049563221],
            [41.384018189128625, 35.630964189496254],
            [41.322491163973467, 35.550564135822427],
            [41.277773207493311, 35.49554498794906],
            [41.272491267373624, 35.468318051788216],
            [41.270818239495071, 35.44332707212466],
            [41.269163316527539, 35.398745070114316],
            [41.262218239175041, 35.367773099604264],
            [41.254436144969105, 35.343318058831315],
            [41.240963241405183, 35.306518151026992],
            [41.222491203135604, 35.253609063464793],
            [41.215273211014988, 35.22470909942551],
            [41.212409114691951, 35.200618168527328],
            [41.211245203613686, 35.188600195720809],
            [41.212491257343203, 35.112782193249714],
            [41.214718161385093, 35.069154051823674],
            [41.223054131754367, 34.916936007523262],
            [41.224845176829973, 34.78290904009738],
            [41.099436146997078, 34.608600154640371],
            [41.030536064512006, 34.473609100708373],
            [41.00387323043654, 34.419436022143927],
            [40.663600147070923, 34.260273235767173],
            [40.199436164179843, 34.040827131031918],
            [40.165545112061778, 34.024435984050442],
            [40.000000176951374, 33.947654062711408],
            [39.798045092535432, 33.853336026413942],
            [39.579136100157172, 33.750000068102892],
            [39.386109251390593, 33.658882074808716],
            [38.961391175505668, 33.455554034648841],
            [38.810273172181184, 33.382218081353003],
            [38.794700266589899, 33.377591103149129],
            [38.69749114424107, 33.327500179080531],
            [38.533563245895749, 33.241100191458898],
            [38.318927180130373, 33.128736085212481],
            [37.958882192947186, 32.937491061427878],
            [37.624991258315248, 32.758327042361927],
            [37.498600204703592, 32.689718147193943],
            [37.495663353460685, 32.688882136168914],
            [37.493891251486474, 32.687218160745871],
            [37.15327316898518, 32.500291158213386],
            [36.86639130025398, 32.341664143089147],
            [36.837773303438127, 32.313609074892113],
            [36.72416331110017, 32.336664170192805],
            [36.643054148415814, 32.349164102433392],
            [36.516109218640651, 32.363054089500324],
            [36.488609200073512, 32.375544969285201],
            [36.400273328089185, 32.381945055291894],
            [36.346663345781479, 32.433054045322947],
            [36.314436101448308, 32.459718052865057],
            [36.197491285103553, 32.533335973384496],
            [36.165600155088811, 32.518227089954266],
            [36.125827186219766, 32.527773239507511],
            [36.079991251491066, 32.543327034359592],
            [36.025836110199265, 32.613609125043823],
            [36.003327179710965, 32.663327054420236],
            [35.931245159133681, 32.720273201838964],
            [35.793609111828488, 32.744164140526792],
            [35.763318087134252, 32.748882146199335],
            [35.689991186293724, 32.711936058003218],
            [35.648891194510469, 32.685273223927695],
            [35.646663284640198, 32.702773129064425],
            [35.651936172304573, 32.727491193959779],
            [35.665482166064152, 32.761991107526271],
            [35.662845051679852, 32.797218067376306],
            [35.639209258125419, 32.815318116781881],
            [35.641591227376495, 32.85687307827088],
            [35.618609222271658, 32.891391096748279],
            [35.614463365311423, 32.894909149159005],
            [35.624582166491138, 32.90582708098971],
            [35.633745095430044, 32.935691131534824],
            [35.634991149159561, 33.000000110361682],
            [35.642491276141953, 33.050554050763125],
            [35.650027110031857, 33.091209131124614],
            [35.65250027438978, 33.124991050863187],
            [35.666936090993119, 33.205273087339819],
            [35.669018155747693, 33.212173237691374],
            [35.681109218749981, 33.244509111489961],
            [35.669618132378645, 33.25171805115518],
            [35.659573259585187, 33.254164225784692],
            [35.632491162711489, 33.246373079123359],
            [35.623636184896128, 33.24572700202468],
            [35.626663225417389, 33.276318098853423],
            [35.761718149451639, 33.337609089614531],
            [35.769436205917117, 33.341109037114151],
            [35.786663363923736, 33.347218103441733],
            [35.794163323267895, 33.350554100914835],
            [35.814718096844416, 33.371109042129362],
            [35.826382185697952, 33.403264202095087],
            [35.8520001317207, 33.417291149460212],
            [35.883327159650293, 33.429573152217628],
            [35.934163234913427, 33.466664247338954],
            [36.034436110519408, 33.553327091444899],
            [36.05874513853874, 33.58561803824243],
            [36.025554142975722, 33.619164258862881],
            [35.967218108368371, 33.640691165572449],
            [35.940200216873365, 33.644164123343771],
            [35.971109155471282, 33.718191080739516],
            [36.017218172606391, 33.769718160102272],
            [36.071036193751439, 33.827527140636263],
            [36.240827233384351, 33.856382177674604],
            [36.353336178918113, 33.826945101278127],
            [36.373954152044774, 33.831109063149356],
            [36.38874519370566, 33.854227022724004],
            [36.357773223195437, 33.879927111398175],
            [36.329445072592392, 33.878327006077583],
            [36.283463292747939, 33.910900087736721],
            [36.327918225105719, 33.974991137080409],
            [36.350273263851278, 33.997218100344995],
            [36.397500259234931, 34.033882221317398],
            [36.516936177210169, 34.107218006975344],
            [36.623745093292627, 34.204991063771317],
            [36.593609133807689, 34.228191165997416],
            [36.546663267457262, 34.344164184485138],
            [36.525836082026757, 34.431936122022407],
            [36.454436180731847, 34.49139114070671],
            [36.429991198242618, 34.501936077845144],
            [36.351227124435212, 34.500745009400461],
            [36.36416325091821, 34.538054034005043],
            [36.397500259234931, 34.554445013348456],
            [36.452773211137213, 34.594718046562363],
            [36.459300199158207, 34.630409028573453],
            [36.376391274218889, 34.639445055497831],
            [36.2985912869174, 34.641935989490506],
            [36.121936139116855, 34.644164234636847],
            [36.114463337138915, 34.64001804240057],
            [36.112973202388304, 34.638764109682015],
            [36.087491210835225, 34.633891206438349],
            [36.03082719827816, 34.63250014578351],
            [35.97277329876627, 34.647500064235984],
            [35.967733533000143, 34.647348384000153],
            [35.933510033000147, 34.642395884000095],
            [35.83556153300006, 34.615822384000168],
            [35.827549533000166, 34.613527384000079],
            [35.825280533000097, 34.615307384000104],
            [35.820826533000087, 34.618750384000137],
            [35.810028533000064, 34.626976384000145],
            [35.800221533000155, 34.63396138399996],
            [35.797465533000121, 34.635933384000154],
            [35.790424533000106, 34.640325384],
            [35.785380533000222, 34.643429384000072],
            [35.781486533000162, 34.645983384000104],
            [35.770347533000091, 34.652979384000119],
            [35.769393533000112, 34.65353638400002],
            [35.760618533000155, 34.658598384000172],
            [35.756041533000172, 34.661039383999977],
            [35.746290533, 34.663895384000128],
            [35.727045533000165, 34.669497384],
            [35.581287533000051, 34.714001383999985],
            [35.518702533000095, 34.731481384000162],
            [35.171294533000179, 34.795419384000056],
            [34.965692911000161, 34.832821350000032],
            [34.967585533000175, 34.854512384000017],
            [34.976469533000085, 34.958651384000021],
            [34.977816533000151, 34.990870385000065],
            [34.977785533, 34.992191385000027],
            [34.985688533000115, 35.005853385000037],
            [35.06844153300014, 35.116952385000033],
            [35.090167533000084, 35.152704385000092],
            [35.108645533000157, 35.263400385000139],
            [35.114661533000088, 35.296794385000041],
            [35.129107533000052, 35.465065385000131],
            [35.149392533000054, 35.584974385000137],
            [35.191951533000207, 35.837024385000049],
            [35.189261533000121, 35.851818385000016],
            [35.159257533000215, 36.014479385],
            [35.265326533000149, 36.060132386],
            [35.44259253300018, 36.061884386000017],
            [35.483736533000155, 36.06115738600009],
            [35.499969533000154, 36.060900386000142],
            [35.535501533000144, 36.059447386],
            [35.566219533000066, 36.056487386000143],
            [35.575767533000118, 36.055495386000032],
            [35.600706533000078, 36.052711385999984],
            [35.632945533000083, 36.048322386000123],
            [35.678903533000181, 36.040667386000095],
            [35.695661533000049, 36.036193386000107],
            [35.696308533000121, 36.035673385999971],
            [35.780146533000135, 35.996345385000083],
            [35.80642953300017, 35.984056385000073],
            [35.845624533, 35.966641385000131],
            [35.889264533000159, 35.946248384999976],
            [35.892021533000189, 35.944441385000133],
            [35.922436114074031, 35.926991049946807],
            [36.015827111951438, 35.899718013265144],
            [36.109991256506106, 35.861382205518666],
            [36.128054090261656, 35.852218103113486],
            [36.144163270019618, 35.833609104545431],
            [36.155873291702591, 35.818445068192247],
            [36.168473303867273, 35.819718111650204],
            [36.182500251232483, 35.855000056784988],
            [36.182500251232483, 35.879718121680426],
            [36.186800167573523, 35.910273176325447],
            [36.223609127833157, 35.962218009743552],
            [36.30000028484497, 35.969718136726058],
            [36.374973223835724, 35.997909159392819],
            [36.383609266339505, 36.030536052870403],
            [36.381382194659551, 36.097073109205539],
            [36.37492712336811, 36.176591051386808],
            [36.392218151476897, 36.213327089088679],
            [36.506654096556161, 36.233327148311375],
            [36.535554060595558, 36.235544999897954],
            [36.575554179041234, 36.227773131613802],
            [36.616454178614248, 36.21950907797391],
            [36.68311813696377, 36.232736056497842],
            [36.690136136874145, 36.286245120691078],
            [36.661136092910652, 36.310982128687655],
            [36.582763286344374, 36.397491081050774],
            [36.567636130365401, 36.420964098045076],
            [36.54957329660985, 36.487773230958098],
            [36.586391141686903, 36.619709081173923],
            [36.662563363387306, 36.815136004102371],
            [36.659945192104061, 36.833709128124596],
            [36.666709220347542, 36.834282115027335],
            [36.702763306405103, 36.829427149056485],
            [36.933600249949535, 36.778045076619136],
            [36.988318152222092, 36.757418051036964],
            [37.03819114680752, 36.719645004271413],
            [37.127491272935828, 36.659153982351526],
            [37.248882186013333, 36.664718057333104],
            [37.26943612139965, 36.664444974926894],
            [37.373809256412443, 36.655336025444555],
            [37.533336152664077, 36.677773206841479],
            [37.568318193302702, 36.696936081572673],
            [37.669718099612822, 36.737218167241892],
            [37.710273267688223, 36.748327038827441],
            [37.736109143194227, 36.747764110208706],
            [37.776936220209478, 36.747982207329969],
            [37.891391276027861, 36.781100112696933],
            [37.917773316346342, 36.791100058489334],
            [37.956100239275401, 36.812909100060594],
            [37.993327121228646, 36.82471802820136],
            [38.02337322671147, 36.830364245834076],
            [38.055545150483368, 36.850545018889946],
            [38.09729121936536, 36.873745121115846],
            [38.181109245525562, 36.905818138430206],
            [38.243400197338218, 36.913882199859856],
            [38.386373170048302, 36.898327063903295],
            [38.432773206862322, 36.885544996801215],
            [38.51360911950249, 36.858044978234076],
            [38.54110025325221, 36.844300165730644],
            [38.62831814698805, 36.768044963188643],
            [38.68416324760264, 36.725545025933044],
            [38.72526323938601, 36.70360908234737],
            [38.774709259822572, 36.698045007365906],
            [38.917627079609559, 36.695135984041642],
            [38.966100128722729, 36.696936081572673],
            [39.003673183278039, 36.70561805690609],
            [39.043463251229554, 36.708191133550017],
            [39.074718194791643, 36.702764186505036],
            [39.096373176981984, 36.695545020917834],
            [39.130545190495326, 36.683600138307256],
            [39.171654067096171, 36.669709145411829],
            [39.221527229319605, 36.665336138874963],
            [39.257763370314848, 36.668054054806291],
            [39.308600283768129, 36.678318030549306],
            [39.439300138538243, 36.697518120930809],
            [39.588054109884553, 36.718882083442239],
            [39.818054036575546, 36.75360914658539],
            [39.923327220353912, 36.786382220454882],
            [39.94277323577532, 36.792764201550469],
            [40.007763328056086, 36.814709029953448],
            [40.214427198051141, 36.895818024999912],
            [40.263882270943071, 36.919491201842462],
            [40.279709148201249, 36.928600151324801],
            [40.415263298389874, 37.001382228457217],
            [40.447773180498899, 37.015273221352643],
            [40.48249119118671, 37.020818017956728],
            [40.513045072365372, 37.023182217572881],
            [40.634991202710665, 37.068882197831769],
            [40.670273315483797, 37.085126996783472],
            [40.688463218891599, 37.095545031907491],
            [40.770818100029572, 37.118045077578373],
            [40.806654088965985, 37.122491174311094],
            [40.901109253199735, 37.128609125455895],
            [40.929991279966231, 37.129718051249242],
            [41.118327112788478, 37.097491142192055],
            [41.216382304446228, 37.072491110073187],
            [41.362491282419086, 37.07500014897677],
            [41.454163319514663, 37.078891196079681],
            [41.483045178643152, 37.080273204279123],
            [41.548191174323421, 37.085273177175267],
            [41.840273175799297, 37.129991133655338],
            [41.995273173771324, 37.172764153317232],
            [42.056927100940896, 37.192773097357545],
            [42.083318193714803, 37.208318007391924],
            [42.14874515079029, 37.259300095408676],
            [42.180827220559962, 37.290544980686931],
            [42.21582719847143, 37.289445107349295],
            [42.294436207069708, 37.26971813053278],
            [42.35151814132027, 37.231173110482459],
            [42.357073331484315, 37.129709166431823],
            [42.355609180633707, 37.106927153537228],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "TCA", Country: "Turks & Caicos Is." },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-67.667777562999959, 22.413056373000117],
            [-69.483332564999984, 21.191667372000026],
            [-70.326388564999974, 20.723333370999953],
            [-71.462221567, 20.557500370999989],
            [-72.147221567, 20.545278371],
            [-72.221686566999864, 20.595638371000021],
            [-72.241180566999873, 20.608186370999974],
            [-72.268004566999934, 20.626022371000019],
            [-72.325365566999835, 20.664265371000127],
            [-72.359135566999953, 20.687086371000063],
            [-72.439003354000022, 20.714709268999954],
            [-72.448975394, 20.747607032000118],
            [-72.473837426999978, 20.833441508000035],
            [-72.480552286999909, 20.856745559000089],
            [-72.482926849000023, 20.865144544000017],
            [-72.486596896999913, 20.878210506],
            [-72.497781720999967, 20.918285479000176],
            [-72.518330366999919, 20.995507367000059],
            [-72.533353156, 21.058776756000086],
            [-72.539310218999958, 21.083503402000147],
            [-72.552452631999955, 21.140240715000047],
            [-72.55544954999985, 21.153674003000148],
            [-72.559813285999866, 21.173021687000031],
            [-72.563574087, 21.191783549000135],
            [-72.56700580399999, 21.214639311000028],
            [-72.588690960999941, 21.242214804000085],
            [-72.634495396999853, 21.369893855000029],
            [-72.644219247, 21.397329821000099],
            [-72.646516489, 21.40414228500002],
            [-72.656654913999972, 21.43473509899998],
            [-72.675988413000027, 21.494542221000145],
            [-72.678674273999945, 21.502738661],
            [-72.679618852000033, 21.505640047],
            [-72.707783268999862, 21.595821966],
            [-72.716363726, 21.624438511000037],
            [-72.737183661000017, 21.69333216599999],
            [-72.738387113000016, 21.697207898000144],
            [-72.743465617000027, 21.713144854],
            [-72.754656578000038, 21.737754228000043],
            [-72.767369469999949, 21.767598469000049],
            [-72.778865885999835, 21.794113604000032],
            [-72.781462836999964, 21.802610659],
            [-72.813606889999903, 21.902789989000027],
            [-72.791720143999896, 21.913031267999983],
            [-72.772869265999987, 21.921739616000039],
            [-72.677121822999823, 21.966154543000115],
            [-72.622566494999887, 22.014911110000142],
            [-72.597783163000031, 22.037177849000059],
            [-72.590457896000032, 22.043828140000173],
            [-72.58702886399999, 22.046956669000124],
            [-72.578406760999883, 22.054671353000103],
            [-72.565005459999952, 22.066380632000019],
            [-72.333509839000016, 22.229618916000064],
            [-72.305124090999982, 22.249640263000103],
            [-72.256163168, 22.325760599999981],
            [-72.255487867, 22.326808699000011],
            [-72.230369374999839, 22.365603257000103],
            [-72.213881320000041, 22.391184468999981],
            [-72.064626203000017, 22.631522754999978],
            [-71.973095691999987, 22.785251239],
            [-71.917428761999901, 22.879306375000041],
            [-71.721508934000013, 23.208511935999965],
            [-71.180441567, 24.136298748000044],
            [-70.631393941499937, 25.062369649000075],
            [-70.339071565999888, 24.934558375000123],
            [-70.22743756499986, 24.885549375000082],
            [-70.061777064999973, 24.812558375000108],
            [-70.060015564999958, 24.811720375000149],
            [-70.026875564999926, 24.78992137500002],
            [-69.825652565000013, 24.700943375000051],
            [-69.732710565, 24.639522375000141],
            [-69.730211565, 24.637869374999966],
            [-69.620921564999918, 24.58929537500002],
            [-69.340783564999896, 24.403172375000096],
            [-69.276843565, 24.374536375000034],
            [-68.764037563999949, 24.030983374000144],
            [-68.762361563999917, 24.029465874000081],
            [-68.325180564, 23.608503374000023],
            [-67.979788562999971, 23.127460373000119],
            [-67.97731356299991, 23.124000372999987],
            [-67.728335562999973, 22.591037373000049],
            [-67.714393562999874, 22.536333372999977],
            [-67.692535563000035, 22.489341373000016],
            [-67.667777562999959, 22.413056373000117],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "TCD", Country: "Chad" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [24.002745145940878, 19.499064109517931],
            [23.999718105419504, 19.465827013487413],
            [23.999445190651386, 19.43278219831619],
            [23.999163223427871, 18.832773213195438],
            [23.999809132888373, 18.754300159067057],
            [23.999163223427871, 18.532500080637519],
            [23.999163223427871, 18.299444950230551],
            [23.999163223427871, 16.266109060432129],
            [24.00000024028131, 16.132773097106067],
            [24.00000024028131, 15.832773214592422],
            [23.997563285745173, 15.702844997830752],
            [23.973191225813878, 15.697918115130349],
            [23.951109101836522, 15.705554028944491],
            [23.865000133894, 15.730554061063529],
            [23.800418240298939, 15.747918011730277],
            [23.663327184339664, 15.757500035829153],
            [23.603054091903147, 15.757500035829153],
            [23.564163234526859, 15.749718109261224],
            [23.531663243063662, 15.741664106115579],
            [23.47916319237757, 15.725827002935588],
            [23.460136104478096, 15.716809080921834],
            [23.433891192095899, 15.700836023271947],
            [23.409027114446843, 15.690836077479631],
            [23.381382256592502, 15.685000093558344],
            [23.356945153092369, 15.681664096085157],
            [23.329718216931525, 15.683609032903419],
            [23.305554028199509, 15.686945030376606],
            [23.244445092375855, 15.701109105678242],
            [23.170273295693164, 15.710554001840748],
            [23.143609120513077, 15.711109051470643],
            [23.118154118688381, 15.710291145356436],
            [23.108609142601608, 15.706391045798014],
            [22.937218165286197, 15.561945037419818],
            [22.923336057208189, 15.540135995848559],
            [22.919582138041562, 15.515691180997479],
            [22.923054089984674, 15.487218023469026],
            [22.935418235393229, 15.464718145436152],
            [22.982709100879333, 15.414235954126283],
            [22.997154137576075, 15.371318095177159],
            [22.997218175316419, 15.335835990193758],
            [22.991391243850643, 15.283336107145644],
            [22.984718075438053, 15.231109138865847],
            [22.97874513121846, 15.207918089095344],
            [22.929373206806218, 15.1140270269468],
            [22.892773291212336, 15.104718085254106],
            [22.872773231989527, 15.09694504350368],
            [22.845691135115914, 15.074864092992712],
            [22.78610921441711, 15.015000037432188],
            [22.753327088092107, 14.976945023442923],
            [22.669163224605938, 14.85374496037899],
            [22.669582152127759, 14.814026976794977],
            [22.679863226953557, 14.758053968337592],
            [22.699927156278733, 14.704100160360767],
            [22.500000157160713, 14.637436034373067],
            [22.470000152145587, 14.629445063139357],
            [22.416109208442663, 14.600273190160337],
            [22.398609135667925, 14.586245069328626],
            [22.386527125120978, 14.562636097864512],
            [22.384718142772613, 14.525100091321363],
            [22.438191164781927, 14.492773102340081],
            [22.44610921345776, 14.473745008612227],
            [22.449163243707346, 14.379164115830449],
            [22.449445043292883, 14.330000062617543],
            [22.511663240185754, 14.240273129978206],
            [22.553745088109935, 14.229027130456373],
            [22.566800237618224, 14.167218138077587],
            [22.556182210284049, 14.129791096275923],
            [22.434445124604423, 14.051664214749692],
            [22.370827149877272, 14.028891086672601],
            [22.317082218928135, 14.010691124980823],
            [22.234300196003318, 13.96555407334067],
            [22.198327079130536, 13.92250009228367],
            [22.084445177852672, 13.779164183165094],
            [22.136109217513734, 13.665000147025538],
            [22.227500125576029, 13.495553938890396],
            [22.294654089986722, 13.372709100883995],
            [22.285000149158378, 13.340553940918198],
            [22.269445180839995, 13.32083601655718],
            [22.15305407302057, 13.186527081907684],
            [22.067500154707858, 13.14860902821664],
            [22.025000217452259, 13.140273225485274],
            [21.942082240057431, 13.051245008296775],
            [21.891945047883127, 12.95444509046213],
            [21.829100219907161, 12.797200083537234],
            [21.88847326357822, 12.693053930463009],
            [21.906945134209792, 12.66944495899898],
            [21.925554132777648, 12.656109015733463],
            [21.952773189949568, 12.643891050716505],
            [21.977218172438796, 12.638609110596732],
            [22.00471819100585, 12.636391091372261],
            [22.050000249571212, 12.637500017165507],
            [22.074445064422292, 12.640000171251572],
            [22.119854025002155, 12.648609056389049],
            [22.145273152281021, 12.657364121918405],
            [22.161945093019739, 12.668745070081755],
            [22.199718139785375, 12.715553976133975],
            [22.223327111249404, 12.747218124210136],
            [22.331663210094689, 12.673336006101891],
            [22.464300122694226, 12.614864184662707],
            [22.407636110136906, 12.485135960111378],
            [22.439027175807041, 12.347218113220563],
            [22.503327102178417, 12.16583607493007],
            [22.563609247070445, 12.074718081635893],
            [22.611382239628483, 11.9927730755645],
            [22.597773213956771, 11.926664166844773],
            [22.572773181837817, 11.7991641874398],
            [22.553191211946512, 11.66597306340087],
            [22.559027195867799, 11.629026975204852],
            [22.575273168285889, 11.601945045969231],
            [22.613327176446745, 11.550553921076471],
            [22.625554026281037, 11.534445076594665],
            [22.648327154358014, 11.511809076453972],
            [22.790273176288224, 11.42944497522258],
            [22.933191163713275, 11.408745027082645],
            [22.971391184627947, 11.280273082182731],
            [22.974091163286374, 11.209791166926038],
            [22.893609134599274, 10.976945081185008],
            [22.866500215635426, 10.922444940757529],
            [22.825000239431375, 10.931109146456123],
            [22.67639127501036, 10.969444954202601],
            [22.463327151370891, 11.000836019872651],
            [22.432782155009676, 10.986945026977324],
            [22.413818099022251, 10.964164187549216],
            [22.364109222101433, 10.955045012144993],
            [22.327918175745481, 10.943473124226699],
            [22.244718231127138, 10.910827120009699],
            [22.006109251660689, 10.768891156363395],
            [21.957218113215902, 10.732217982935481],
            [21.895836094986151, 10.705000099230148],
            [21.819718188018527, 10.67819108476273],
            [21.785827135900405, 10.668335978257645],
            [21.719027223080957, 10.63812709621476],
            [21.702500121629583, 10.563336044523552],
            [21.701109228612722, 10.520691100342631],
            [21.704854095323952, 10.498053926735366],
            [21.719445144774483, 10.468336056581862],
            [21.737773181947148, 10.448535989569407],
            [21.747218078109626, 10.409444972344772],
            [21.718400256721679, 10.296600080130688],
            [21.666945094088391, 10.23583597580479],
            [21.638882146902517, 10.223054076340702],
            [21.608745181589001, 10.212364132276974],
            [21.568891243535205, 10.212918008440397],
            [21.537218210641527, 10.211664075721842],
            [21.520273187496713, 10.199444937238496],
            [21.5000002135057, 10.177773191241712],
            [21.473609120731822, 10.14778207104392],
            [21.454445072534128, 10.113745006172081],
            [21.454163272948591, 10.092773149092423],
            [21.43527331298543, 10.0418089983485],
            [21.408336055399133, 10.006944974907043],
            [21.363336131695348, 9.969027088854077],
            [21.339163225784006, 9.95847309926026],
            [21.292773247253791, 9.972082124932058],
            [21.265627112639834, 9.978054063323142],
            [21.225000195472944, 9.942500041610373],
            [21.048054027993373, 9.766391058622318],
            [20.974445159929417, 9.603336051676152],
            [20.894027168982774, 9.520136107057823],
            [20.875691252821014, 9.511245087058654],
            [20.837773199130055, 9.476391121900988],
            [20.827218203707844, 9.451391089782035],
            [20.81684509558508, 9.415664233225215],
            [20.800827110933966, 9.4205540679134],
            [20.498473254725951, 9.275853920230134],
            [20.499854089458921, 9.241664137082054],
            [20.487782137195836, 9.209718021782095],
            [20.459163302189751, 9.175554054895855],
            [20.422218219822042, 9.139718065959457],
            [20.371663273592304, 9.108327000289407],
            [20.325418134349405, 9.104718088048088],
            [20.270273257927926, 9.118327113719801],
            [20.186945070190689, 9.125827073064045],
            [20.064718146088069, 9.134864105816916],
            [19.963336177050792, 9.08000002315265],
            [19.933754093729107, 9.057518082392676],
            [19.905554018606836, 9.059164120542903],
            [19.830554089887954, 9.053054048387025],
            [19.610836076212991, 9.02666412907962],
            [19.411391204167018, 9.016109133657395],
            [19.193318222813872, 9.020991089356571],
            [19.109445043730631, 9.013609147209323],
            [19.086936113242331, 9.009445017700116],
            [19.023473203724421, 8.984444985581149],
            [18.988891147506564, 8.964164132601212],
            [18.929445181277657, 8.920553928447816],
            [18.892500266548012, 8.891391107924207],
            [18.869718086015439, 8.861245090155293],
            [18.87637314951732, 8.841427085869924],
            [18.925554134174632, 8.79416421594064],
            [18.987218119628011, 8.765273136718662],
            [19.030691195845037, 8.754864154050154],
            [19.115000233894705, 8.691391186248424],
            [19.125827138256625, 8.672782187680454],
            [19.088609141120799, 8.64110915478679],
            [19.058791191043127, 8.578382176369729],
            [18.987500254489703, 8.493609116159021],
            [18.931800160800435, 8.423891127559898],
            [18.922500103925159, 8.392426971693993],
            [18.904163349573082, 8.367218062547281],
            [18.840273298268215, 8.292218133828385],
            [18.802154078900344, 8.256109062485791],
            [18.759163297393457, 8.243473175775407],
            [18.704718142251039, 8.223609071022508],
            [18.686663187484584, 8.214164174859917],
            [18.665554034830535, 8.193891033230912],
            [18.645273181850513, 8.16528208887064],
            [18.634718186428302, 8.13527319903811],
            [18.635554029815324, 8.087218071618366],
            [18.618327207084889, 8.060273102681137],
            [18.588891136516906, 8.040282095913739],
            [18.561109150726253, 8.039717993828518],
            [18.408054089572431, 8.033054045509218],
            [18.240836126583531, 8.02555391852691],
            [18.032773149306593, 8.013473081446421],
            [17.883609135255853, 7.958336084013951],
            [17.718327056629732, 7.983609030901121],
            [17.648891202892315, 7.988473216965446],
            [17.62763620512257, 7.967154013817265],
            [17.587218164983454, 7.93374508877109],
            [17.51082717560962, 7.912218014423544],
            [17.450691211109387, 7.901600154727362],
            [17.369445088126753, 7.867218090719774],
            [17.255000258230154, 7.812500020809225],
            [17.237218218231817, 7.803053951180232],
            [17.218609219663932, 7.758609077106371],
            [17.13833606800452, 7.700554003894311],
            [17.058891216019276, 7.662773078139651],
            [16.984445163463818, 7.654444986759316],
            [16.913327061754359, 7.642500104148638],
            [16.86979112126329, 7.621244938740816],
            [16.866182209021986, 7.59423609970122],
            [16.860000220136726, 7.569164150852799],
            [16.831527062608274, 7.549309098555312],
            [16.817454014775421, 7.546300162945045],
            [16.781663288116334, 7.565836032368622],
            [16.568054173130662, 7.783191187531813],
            [16.563682172422176, 7.8302091382497],
            [16.567709173995098, 7.864945086210355],
            [16.533609244849202, 7.867773140349584],
            [16.503054022566118, 7.850554028970166],
            [16.430827162701576, 7.798053978284074],
            [16.410000144909162, 7.767218129881925],
            [16.404163322797558, 7.714718079195833],
            [16.40750015846109, 7.69110910773172],
            [16.217636137047521, 7.614373119222321],
            [16.148609152548119, 7.600836010279991],
            [16.113054125006926, 7.596109119790029],
            [16.079445040112432, 7.594444976728894],
            [16.034445116408733, 7.571391054894676],
            [16.01874514116497, 7.54007307942048],
            [15.974863195709929, 7.501318008876012],
            [15.913891220184667, 7.482782100503997],
            [15.782709238342449, 7.458536104396799],
            [15.72791807823603, 7.476944943116322],
            [15.713609163647192, 7.506808993661622],
            [15.690136146652975, 7.52291800578142],
            [15.642636068863055, 7.523053960251318],
            [15.616945032644367, 7.518609036984984],
            [15.565691203325883, 7.51263609276549],
            [15.542500153555295, 7.51389103131244],
            [15.518609214867638, 7.518335954578774],
            [15.499009140065453, 7.526609060674076],
            [15.501945153118044, 7.5294449938025],
            [15.547009114562087, 7.58200908222895],
            [15.572773240976773, 7.63000017190825],
            [15.584300201893939, 7.690135968770392],
            [15.581945054733097, 7.736109031435575],
            [15.579982180641991, 7.76040900699941],
            [15.524027109457222, 7.773891130656722],
            [15.502291158082016, 7.774926966253929],
            [15.477918092322312, 7.810273116767434],
            [15.444718211941989, 7.882218009408334],
            [15.433336090312082, 7.911664138260207],
            [15.389163292815994, 8.039582039358706],
            [15.380273278645234, 8.077773175455945],
            [15.376527070829553, 8.112082149267593],
            [15.361945073834505, 8.14250007597623],
            [15.34360915767283, 8.171945031361531],
            [15.264718181850924, 8.339164167816904],
            [15.225200190477267, 8.436200120045626],
            [15.207218158268603, 8.477364149569325],
            [15.074163323975739, 8.643609141234961],
            [14.922782296528908, 8.775273082511063],
            [14.876109177308621, 8.809309141554508],
            [14.855836035679602, 8.817218137774944],
            [14.832773229027879, 8.813609057895547],
            [14.621109218498646, 8.960554046893506],
            [14.559445065407203, 9.00750008088194],
            [14.348054137284066, 9.196945007135497],
            [14.352500234016986, 9.230000048228518],
            [14.32860912769118, 9.258336077820758],
            [14.007845091659078, 9.617500126977689],
            [13.96944507853425, 9.629718091994647],
            [13.957636150393483, 9.646454070473624],
            [14.010000246609593, 9.73000002005611],
            [14.034163261875193, 9.758191042722856],
            [14.059445093579598, 9.774164100372843],
            [14.07750021598423, 9.783608996535435],
            [14.108609146792674, 9.811391149964081],
            [14.123336150713044, 9.831664123955079],
            [14.140973183785974, 9.860000153547247],
            [14.180554039433588, 9.935827040835747],
            [14.194773267658235, 9.981754003033231],
            [14.450554084570172, 9.998053954907959],
            [14.486109112111365, 9.993891166503218],
            [14.652773198936814, 9.959164103360067],
            [14.783473221345105, 9.923191154125362],
            [14.926663285348042, 9.970064097917941],
            [15.017500149608907, 9.949718033731074],
            [15.053054171321691, 9.948336025531646],
            [15.083473271496814, 9.955418063182449],
            [15.101245085573169, 9.975826991643274],
            [15.123609176774153, 9.98416413547902],
            [15.159445165710622, 9.989164108375178],
            [15.240000116955571, 9.987500132952221],
            [15.284163191443923, 9.973609140056809],
            [15.422218166271108, 9.926945073291989],
            [15.535000194211136, 9.953609080833999],
            [15.591663200939877, 9.967218106505797],
            [15.675300177991033, 9.988018134569913],
            [15.653473199147044, 10.011809160971467],
            [15.63028214937637, 10.027218116536133],
            [15.589163214491691, 10.049445079800805],
            [15.541945103925485, 10.07250017510151],
            [15.499027077338269, 10.101391086685481],
            [15.373054113058032, 10.248609158089565],
            [15.276945031685216, 10.390554006553202],
            [15.190282187579271, 10.502773105874482],
            [15.150000101910052, 10.615553960348038],
            [15.136663320454119, 10.6605540516898],
            [15.11513607846851, 10.704027127906897],
            [15.092218111104103, 10.731664106772158],
            [15.058191272154232, 10.8019450239901],
            [15.058054144217863, 10.825282086514406],
            [15.066391120415602, 10.845000010875609],
            [15.077218192415614, 10.880136110894867],
            [15.07597331215257, 10.903609127889084],
            [15.026063269554925, 11.079409153925198],
            [15.019163286841547, 11.136945052053179],
            [15.018263238076088, 11.202154079645425],
            [15.050973280033446, 11.394864092471124],
            [15.067636168316739, 11.436664140809768],
            [15.109300262185315, 11.496527022903706],
            [15.090763347984876, 11.588191181010373],
            [15.062500240950669, 11.690000124196587],
            [15.081945082905577, 11.754718139899452],
            [15.042600261652126, 12.078891095962604],
            [14.973400107032631, 12.091391028203006],
            [14.951527195359006, 12.097636049000386],
            [14.894336128728611, 12.155809139409527],
            [14.898891190203102, 12.200282176678186],
            [14.902218135220807, 12.228336071408648],
            [14.908336086365694, 12.320553938040476],
            [14.902345037235136, 12.375973070334553],
            [14.822427110633356, 12.633773087727192],
            [14.708745033927926, 12.714718132747024],
            [14.624200129122272, 12.734600174772751],
            [14.581527189384644, 12.738054021804857],
            [14.546245076611513, 12.771664112527773],
            [14.539718088590689, 12.853054068969342],
            [14.525554013289081, 12.975000031676572],
            [14.500873164043924, 13.001318034254709],
            [14.486945123136394, 13.019717988156728],
            [14.472773168845748, 13.040273097009432],
            [14.457082246057468, 13.065973185683603],
            [14.435973261041539, 13.085000105944914],
            [14.159718136823841, 13.083053995660251],
            [14.074718094674381, 13.081664108471799],
            [13.901082276044235, 13.328218126342506],
            [13.760018197968691, 13.527800126324777],
            [13.634718132877367, 13.703891172039917],
            [13.625118171505562, 13.718336041098667],
            [13.600554165990957, 13.828609030135112],
            [13.562227075423891, 13.993264142401884],
            [13.461945147362343, 14.430554067256807],
            [13.46500018344031, 14.450836093703245],
            [13.475827087802259, 14.468054031616262],
            [13.494718221231835, 14.484445010959675],
            [13.51721809926471, 14.496944943200148],
            [13.547500239141527, 14.511809075056888],
            [13.615182263453619, 14.51626422424512],
            [13.629718159981138, 14.521664181561974],
            [13.64778216720299, 14.53166412735429],
            [13.674373252187223, 14.552291152936462],
            [13.684445114709007, 14.579444998901366],
            [13.682918099584271, 14.608473206059713],
            [13.759163243842352, 14.705554085289563],
            [13.794163221753735, 14.732773142461397],
            [13.778609091625668, 14.802218048654325],
            [13.767218085178428, 14.848053983383025],
            [13.786391185831604, 14.888891118682054],
            [13.8591632046803, 15.037773165509364],
            [13.997218179507399, 15.205826971885202],
            [14.072218108226281, 15.313891161790664],
            [14.19555412576014, 15.489164133753704],
            [14.271663315548437, 15.596945015331087],
            [14.368891213360399, 15.733891064365039],
            [14.572218080053801, 15.947773094118745],
            [14.904445039262612, 16.2947180047925],
            [15.122218116119313, 16.521382101648371],
            [15.238609223938653, 16.641391174164525],
            [15.354163314904639, 16.761109059363477],
            [15.456945061776196, 16.867773136158618],
            [15.482500143524959, 16.894445022689737],
            [15.485136084442956, 16.898091150581237],
            [15.489718135645575, 16.920973075761836],
            [15.52360918776381, 17.360000066235685],
            [15.558982160367464, 17.999453952534154],
            [15.573054034733843, 18.24888212501169],
            [15.574445095388768, 18.289718086844331],
            [15.602500163585603, 18.782218099327523],
            [15.610836133954962, 18.841664233194521],
            [15.634163305833567, 19.01889119443122],
            [15.713054114017382, 19.618891127096546],
            [15.730273225396871, 19.755273074045292],
            [15.75389124931641, 19.93250003528199],
            [15.80416322249414, 20.020000063879436],
            [15.873336052109153, 20.13971811671648],
            [15.981945065722641, 20.32638209512676],
            [15.996663184825678, 20.353053981657794],
            [15.668336157537226, 20.700691069897644],
            [15.633054044764236, 20.716664127547617],
            [15.61236331671779, 20.731800168344094],
            [15.57750013146665, 20.765973187685816],
            [15.55721810502024, 20.815827071532041],
            [15.554163236580422, 20.852500077321864],
            [15.556663223028494, 20.87527320539904],
            [15.566109125019409, 20.905554004171378],
            [15.584445041181084, 20.930000160126909],
            [15.615691267563903, 20.951182067700799],
            [15.583054148164308, 21.018609114517616],
            [15.533336051149917, 21.089717996133473],
            [15.284445158667438, 21.445273133049184],
            [15.209582190246863, 21.491809124333187],
            [15.201391226802826, 21.517773075319994],
            [15.200000166147959, 21.614164123916609],
            [15.199445116518149, 21.655282052972765],
            [15.197500179699887, 21.820000029513679],
            [15.194718226028101, 21.998882081356001],
            [15.1544632977253, 22.202773217772787],
            [15.047782289485724, 22.751664160407003],
            [15.00694515418661, 22.959445002822221],
            [14.997891189989303, 23.000591095073204],
            [15.132773279179617, 23.062218032514451],
            [15.335282239758868, 23.153327140991209],
            [16.000827146696821, 23.450553954650658],
            [16.346109087775915, 23.292218126843423],
            [16.980273154965602, 22.998336027836601],
            [17.656327124496102, 22.680918047561519],
            [17.687182251275743, 22.666254075553113],
            [18.036636200852882, 22.500000031432151],
            [18.363609215498542, 22.344444983830229],
            [18.768327064522765, 22.150273167086809],
            [18.999718219506576, 22.038282055532576],
            [19.298054126597265, 21.89389120007742],
            [19.873891211635168, 21.611664137468537],
            [20.408336111744205, 21.347500076253212],
            [20.959445073602865, 21.072500058220456],
            [21.381945130263318, 20.858745098119343],
            [21.781663341667439, 20.656109067887385],
            [22.321391187724572, 20.380000124061482],
            [23.020554064374437, 20.017773159837574],
            [23.456663314344667, 19.789445087558633],
            [24.002745145940878, 19.499064109517931],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "TGO", Country: "Togo" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.089717796364766, 11.127218021028909],
            [0.050282115280709, 11.098891043892309],
            [0.190000227340533, 11.070554008471731],
            [0.218891138924505, 11.064164148387135],
            [0.41694511795373, 11.02444499133685],
            [0.505554072344125, 11.004582060050438],
            [0.514863181675025, 10.975418066060172],
            [0.504163179327406, 10.936944962739403],
            [0.630836032524769, 10.983891164365787],
            [0.666109092842305, 10.996109129382774],
            [0.724445127449656, 10.996944972769654],
            [0.811945156046932, 10.996944972769654],
            [0.917973214027484, 10.996400149061685],
            [0.89055399700726, 10.911109087233399],
            [0.893609200723233, 10.866945006916637],
            [0.879445125421626, 10.799718119948295],
            [0.807082143449009, 10.723327130574489],
            [0.802191135294436, 10.712445073289359],
            [0.800554149599691, 10.701664101756933],
            [0.800282240659897, 10.681109160542405],
            [0.804027107371212, 10.641109042096787],
            [0.807500232780683, 10.607217989978665],
            [0.805973217655662, 10.57360907272232],
            [0.798053995513612, 10.54666410378502],
            [0.787500173557873, 10.529309037935491],
            [0.776663210911863, 10.376664187124277],
            [0.999373228752802, 10.220073136287056],
            [1.066945114856935, 10.174445072757635],
            [1.172400185934748, 10.1086000263507],
            [1.187218217323846, 10.100000026030614],
            [1.307782171831718, 10.025273179717828],
            [1.355000114759946, 9.995282059520036],
            [1.358891161862971, 9.914718055819776],
            [1.370000201086611, 9.636391092769259],
            [1.368336058025363, 9.596664224367942],
            [1.337500209623215, 9.542500030620715],
            [1.361109181087443, 9.485554050839937],
            [1.364254071167522, 9.481344994329504],
            [1.389927170113481, 9.486245054939616],
            [1.398545107706354, 9.429900057618227],
            [1.399445156471813, 9.387918121980306],
            [1.406945115816256, 9.344718128169461],
            [1.41805415503967, 9.319027091950716],
            [1.431627138527745, 9.301245051952378],
            [1.4524541563203, 9.28041803416005],
            [1.493609133388475, 9.228336072805519],
            [1.510554156533487, 9.210836000030838],
            [1.540000117747184, 9.189653924818913],
            [1.568609229745562, 9.167918141081771],
            [1.605973239635148, 9.099164071350401],
            [1.619718219776587, 9.034236005153332],
            [1.626663297129141, 8.84694506038386],
            [1.626109085689507, 8.726664078928081],
            [1.625400144317268, 8.587282081186459],
            [1.624163310681013, 8.425282188214908],
            [1.617054115664018, 8.36710909780578],
            [1.63097327175393, 8.360282205288343],
            [1.638054135938376, 7.998609117227659],
            [1.639309074485425, 7.75986418329137],
            [1.641945015403394, 7.628191189559857],
            [1.650691196115133, 7.547782083430661],
            [1.655418086605209, 7.529773061493643],
            [1.650136146485409, 7.513891031312539],
            [1.644445169489643, 7.443054058636278],
            [1.643745112934312, 7.409673129146881],
            [1.645554095282762, 7.333891168859452],
            [1.642500232671296, 6.995553969856871],
            [1.553882225825191, 6.996564156830232],
            [1.562363203119844, 6.948191187641214],
            [1.57702717512808, 6.909273172898622],
            [1.60416325145826, 6.894309128755765],
            [1.607500087121821, 6.796664147440595],
            [1.590136136454987, 6.715691106864313],
            [1.575282230520259, 6.679717989991445],
            [1.616809196452493, 6.596244962966921],
            [1.647500205567439, 6.573053913196247],
            [1.655418086605209, 6.570673117411729],
            [1.664027139380778, 6.577008998039673],
            [1.693054005434647, 6.549164147975091],
            [1.749163303637999, 6.456664146481444],
            [1.771527059563027, 6.414864098142914],
            [1.795282211418737, 6.308335975817556],
            [1.797800135139653, 6.28025391789248],
            [1.742500193509159, 6.269718033209415],
            [1.683063279735791, 6.259591185402655],
            [1.652500178463583, 6.250282076071983],
            [1.62977315085385, 6.235135976991671],
            [1.635399748362829, 6.218718322112863],
            [1.638774502000103, 6.197633358000246],
            [1.709177502000045, 5.952697357000147],
            [1.759511502000237, 5.754271357000107],
            [1.931737502000146, 5.024058357000172],
            [2.022745502000021, 4.639812356000107],
            [2.229740502000141, 3.794886355000131],
            [2.410321502000215, 2.991686355000127],
            [2.403179502000228, 2.991047355000205],
            [2.397470502000033, 2.990531355000144],
            [2.393041002000189, 2.989360855000086],
            [2.389545002000091, 2.988472855000168],
            [2.386241502000189, 2.987663855000164],
            [2.379596002000085, 2.985968855000124],
            [2.376496502000123, 2.985181355000122],
            [2.375606502000039, 2.985104355000146],
            [2.369889502000092, 2.983577355000136],
            [2.368611502000022, 2.983466355000189],
            [2.365500502000202, 2.982672855000033],
            [2.362405502000058, 2.981884355000034],
            [2.360905502000151, 2.981753355000023],
            [2.358024002000093, 2.981020855000168],
            [2.354798502000222, 2.980218855000032],
            [2.351601502000165, 2.979391855000188],
            [2.348178835333499, 2.97850202166677],
            [2.346716502000106, 2.978120355000158],
            [2.345630502000176, 2.977614355000185],
            [2.326347502000203, 2.972463355000173],
            [2.318595502000051, 2.970392355000058],
            [2.272579502000099, 2.958095355000069],
            [2.099101502000167, 2.911652355000172],
            [1.322501501000204, 5.795625357000119],
            [1.280774501000053, 5.902975357000173],
            [1.219945501000211, 6.064225358000101],
            [1.210001501000022, 6.088486358000182],
            [1.19889093954589, 6.100545115029206],
            [1.202782238097711, 6.117773071315469],
            [1.201109210219158, 6.160000093802864],
            [1.115000242276665, 6.160836104827865],
            [1.099973166221673, 6.160345092938599],
            [1.062218056729023, 6.219445054203391],
            [1.00152704259898, 6.32528217242907],
            [0.904718072308867, 6.329444960833541],
            [0.788336184582931, 6.415553928776035],
            [0.758891229197332, 6.447782178937814],
            [0.727363203229288, 6.501527109886865],
            [0.722782157854738, 6.524445077251414],
            [0.724673282854667, 6.535644976305662],
            [0.743891142870893, 6.557217983482758],
            [0.728363331919013, 6.58420016807014],
            [0.690836042554963, 6.589164098782533],
            [0.659718059291208, 6.61041809072384],
            [0.643336132403135, 6.646809129290091],
            [0.647218127050678, 6.67666412738015],
            [0.650691252460149, 6.708191147519926],
            [0.64381809183709, 6.733536011136437],
            [0.569027040146068, 6.814718096378783],
            [0.525000087765534, 6.947782150765192],
            [0.589309234230484, 6.993609033038695],
            [0.608745023729682, 7.017218004502695],
            [0.640000134930006, 7.205554004963062],
            [0.652218099946992, 7.282773126372888],
            [0.652954031047784, 7.369645015144499],
            [0.642636075848202, 7.397845090266856],
            [0.598336041061373, 7.387636099808674],
            [0.568263281126463, 7.386664134313719],
            [0.536527048682672, 7.415973135229251],
            [0.510554045240497, 7.460553963773009],
            [0.512782122748774, 7.53110913686362],
            [0.514163292757871, 7.557782196861126],
            [0.521245162770583, 7.585418002259928],
            [0.59319122887797, 7.701873147819754],
            [0.622927203942311, 7.896727083845576],
            [0.597500197674492, 8.094718030962667],
            [0.611663267147662, 8.216109111678236],
            [0.638336159507162, 8.237500063917821],
            [0.666945103867363, 8.253745030507503],
            [0.704445068226676, 8.273053917992684],
            [0.728609089320628, 8.295209132165837],
            [0.726245057342453, 8.323744986330439],
            [0.698891219167308, 8.388609014786937],
            [0.649863288062306, 8.473054007306416],
            [0.63221820836219, 8.493473161689167],
            [0.538336031031122, 8.555554063007662],
            [0.470073140827196, 8.595554013815104],
            [0.41236323438909, 8.690554001756823],
            [0.38847313389158, 8.738473174706826],
            [0.382736056427973, 8.760753949789887],
            [0.48868213939528, 8.795827017897295],
            [0.511173132610679, 8.842491084662072],
            [0.512782122748774, 8.844445073935788],
            [0.520553991032784, 8.88874494108444],
            [0.513473294486573, 8.919864097814639],
            [0.448682188587838, 9.032217978139215],
            [0.455282266804602, 9.060273213974313],
            [0.501109149077877, 9.157500105957809],
            [0.519445065239665, 9.1880539871363],
            [0.527500241851612, 9.206391076764589],
            [0.552782241194336, 9.367364186594045],
            [0.550627086243878, 9.408891152526508],
            [0.501245103547774, 9.472500074798177],
            [0.462782226148818, 9.488336004511666],
            [0.422991152368866, 9.496182136458117],
            [0.355282138328562, 9.485135961508433],
            [0.336318082341165, 9.444926966035155],
            [0.28208213950262, 9.420000024111971],
            [0.248609176716002, 9.42278214542182],
            [0.229309174048524, 9.431018035866828],
            [0.217218111045952, 9.46028210978119],
            [0.223191222903552, 9.550136112073005],
            [0.25930917906345, 9.664927114571867],
            [0.324445116459913, 9.762218044295764],
            [0.356182187094021, 10.030282205069511],
            [0.400227076747399, 10.030491082097171],
            [0.395691126012196, 10.067564072307675],
            [0.372500243879699, 10.082773203300007],
            [0.355136125574859, 10.093473205647626],
            [0.349718063347041, 10.115273194763404],
            [0.357782124776634, 10.19694495079051],
            [0.3665271320221, 10.254164180615533],
            [0.303336131443984, 10.38944508076014],
            [0.28513616975215, 10.414373196149654],
            [0.228891252354799, 10.409444972344772],
            [0.120600248148833, 10.521673124121378],
            [0.037845047314249, 10.590418141397265],
            [-0.00221776776732, 10.608327083409961],
            [-0.06375485120634, 10.631245050774396],
            [-0.078754937532892, 10.650836073121184],
            [-0.083608897675276, 10.698609065678966],
            [-0.082054892822327, 10.70260002506808],
            [-0.059445882409761, 10.782218047173558],
            [-0.020281942627435, 10.858609036547421],
            [0.032218108058544, 10.990000063055277],
            [0.034718094506843, 11.021391128725327],
            [0.031318059293255, 11.077153919050545],
            [-0.005136681737326, 11.09883605860739],
            [-0.043508866943569, 11.102044986428098],
            [-0.077217864124293, 11.091664166954388],
            [-0.099163865993717, 11.093191182079011],
            [-0.144863846252633, 11.124300112887283],
            [-0.149763906862802, 11.138536104918387],
            [-0.142217847050745, 11.137782068906574],
            [-0.089717796364766, 11.127218021028909],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "THA", Country: "Thailand" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [100.091372281610717, 20.348609058391432],
            [100.1052632745062, 20.309436066153452],
            [100.165545251760165, 20.291935993378829],
            [100.227200184758004, 20.356664235003578],
            [100.260891244665885, 20.386109022750887],
            [100.287491214467565, 20.392773138708165],
            [100.321100299361973, 20.391527084978648],
            [100.343318210171248, 20.382218143285954],
            [100.35990918172493, 20.365545029080849],
            [100.379282274588462, 20.337082097474294],
            [100.387618244957793, 20.308473153114008],
            [100.418727175766236, 20.238191062429578],
            [100.45786328763009, 20.188082201088079],
            [100.521027130841816, 20.145827015405885],
            [100.574827214714077, 20.162464087427352],
            [100.5804633740631, 20.157773071482936],
            [100.570972377432867, 20.091664162763223],
            [100.550945160843781, 20.017636031901176],
            [100.506236256819108, 19.87853600138321],
            [100.486100243126401, 19.859435990925974],
            [100.446091239863364, 19.81500016930751],
            [100.411236268877389, 19.771800175496693],
            [100.404991248080051, 19.749027047419517],
            [100.407563318895626, 19.73855402701075],
            [100.445536190233582, 19.627773094640318],
            [100.490882286539403, 19.537773079594885],
            [100.516391267820637, 19.518891166258712],
            [100.578945243755669, 19.493464159990836],
            [100.5849542301589, 19.493973109153117],
            [100.613027235628664, 19.538191168926389],
            [100.640882311615229, 19.550618010970922],
            [100.700000210152922, 19.533609117723657],
            [100.755336193967338, 19.507909029049486],
            [100.773882328261237, 19.485753982514254],
            [100.813600311845249, 19.520553968215282],
            [100.852200317180433, 19.560000042859471],
            [100.867482370730755, 19.57750011563418],
            [100.883882234891502, 19.598327133426622],
            [100.912200327210854, 19.619718085666179],
            [100.958600196386868, 19.621382061089221],
            [101.032200179633406, 19.619164209502841],
            [101.063600297758938, 19.605273216607529],
            [101.086109228247238, 19.590273130280977],
            [101.225109178841052, 19.594382106866973],
            [101.259291250638313, 19.585691079078245],
            [101.279082265195228, 19.566245063656751],
            [101.285127293782267, 19.520553968215282],
            [101.264363307901903, 19.474373201988996],
            [101.215854216604981, 19.46149105496265],
            [101.201100222956342, 19.374582118178822],
            [101.202472340509956, 19.353882170038887],
            [101.289982259753145, 19.106109088167059],
            [101.305818189466748, 19.092773144901443],
            [101.353463274181962, 19.044373185984128],
            [101.339563396469231, 19.010691178531758],
            [101.318600256568828, 18.996382096304828],
            [101.29998237318361, 18.975826987452209],
            [101.253945105139991, 18.894791082601884],
            [101.249709226539409, 18.86888211689994],
            [101.236927159437215, 18.741382137494966],
            [101.267972387781327, 18.684582170467834],
            [101.241091288946421, 18.637635968841337],
            [101.180118307592778, 18.560136053674469],
            [101.147218164070779, 18.540554083783078],
            [101.106991231324599, 18.518745042211904],
            [101.057827178111751, 18.441591132009009],
            [101.059000309283391, 18.418609126904258],
            [101.086691267605403, 18.387654087838527],
            [101.095818154360558, 18.373954034698059],
            [101.154572278299412, 18.345000091202039],
            [101.176654234638789, 18.175273089309755],
            [101.170818250717531, 18.087218011082385],
            [101.143336169423293, 18.022500163017511],
            [101.113736148828792, 17.957082090759528],
            [101.087209269223052, 17.930136115993903],
            [101.063182376065441, 17.914582153503929],
            [101.028809196875358, 17.897982129494679],
            [100.989700242377779, 17.806109094360338],
            [100.980554244883336, 17.763053939836752],
            [100.984391312529709, 17.750144970720257],
            [100.988454188648376, 17.732073084509196],
            [100.953454210736908, 17.649718035733301],
            [100.924145209821432, 17.619164154554753],
            [100.911227188249399, 17.599445056727163],
            [100.92216339262913, 17.568954039822685],
            [100.941309168277911, 17.564344998891627],
            [100.963045119653117, 17.565554004609041],
            [100.987900312484925, 17.561527003036204],
            [101.033454112351961, 17.541244976589795],
            [101.075754225035354, 17.499853965127343],
            [101.15970921149389, 17.460754063085389],
            [101.227172300494402, 17.531353995539078],
            [101.309709236569716, 17.624718003614433],
            [101.355818253704712, 17.657217995077715],
            [101.497218278460451, 17.743464090956522],
            [101.581063294348979, 17.797354028830981],
            [101.559709222483519, 17.813836035643149],
            [101.577209295258143, 17.860064243441613],
            [101.63609132703985, 17.889582121384862],
            [101.689145253889279, 17.905827087974572],
            [101.74165418939279, 17.972773181185786],
            [101.761936215839199, 18.038327040275803],
            [101.784145241831055, 18.063536117060423],
            [101.850809200180578, 18.044164197663363],
            [101.885054136251796, 18.03139118301668],
            [101.910672249912608, 18.036800025150953],
            [101.999418164601508, 18.127491044296121],
            [102.047763305871939, 18.191664236291146],
            [102.070827285990077, 18.208327124574353],
            [102.089391357556821, 18.214982188076149],
            [102.108218285607933, 18.209445102823011],
            [102.157063323585106, 18.20422703280569],
            [102.179982296777865, 18.190964179735928],
            [102.213318299266206, 18.138327001113552],
            [102.25776334097813, 18.087773228350287],
            [102.299145132347036, 18.047635981968298],
            [102.423736256065894, 17.981936110124778],
            [102.472491272402664, 17.970553988494842],
            [102.523327180027621, 17.973327057349124],
            [102.544145145364581, 17.971664087754576],
            [102.604218245590943, 17.946800177743498],
            [102.612763428264003, 17.918609155076652],
            [102.610682369337866, 17.886727077517335],
            [102.683591348484669, 17.82000008725565],
            [102.730554146279559, 17.883053959897396],
            [102.841372294300101, 17.962491100531778],
            [102.858600290496923, 17.974718118004049],
            [102.946509188332385, 18.005973229204201],
            [102.97838221343639, 17.998809048902146],
            [103.015891230251356, 17.979991173306516],
            [103.047127230712221, 17.997009119009178],
            [103.068409218210178, 18.028826991190087],
            [103.069418231717322, 18.123027010290471],
            [103.120263359435882, 18.148745036237457],
            [103.140827185467714, 18.165273143517354],
            [103.256654191201818, 18.275000135379386],
            [103.241091343894453, 18.339026979344553],
            [103.242200269687686, 18.365409019662948],
            [103.276382341484833, 18.40221814756076],
            [103.301091353924761, 18.41902704859767],
            [103.371372271142803, 18.431109059144646],
            [103.397218204932614, 18.43499105379216],
            [103.417482294106122, 18.43443600416235],
            [103.50776327054686, 18.421664162982054],
            [103.619218275572479, 18.388400077223295],
            [103.75942723188362, 18.337773214264089],
            [103.842482337214705, 18.311109039084002],
            [103.940672309875907, 18.326664175040364],
            [103.97615424722116, 18.326873219706201],
            [104.04692718215685, 18.238327129589479],
            [104.071927214275803, 18.199718071798813],
            [104.091936158316145, 18.146382177725783],
            [104.101791264821117, 18.109436089529666],
            [104.195536146577894, 18.015000036035204],
            [104.215754135283845, 17.989018147775496],
            [104.239436196943785, 17.93721798600653],
            [104.257000307458981, 17.887636011100128],
            [104.26638233934753, 17.865000178597413],
            [104.285818296485019, 17.845553995538054],
            [104.335536225861318, 17.826382068351265],
            [104.401654187036542, 17.728117999665827],
            [104.41748223776122, 17.703882061842435],
            [104.461372397481313, 17.64930011403986],
            [104.641027260798666, 17.557709046129148],
            [104.692054108178439, 17.528535999683655],
            [104.718318298937874, 17.503327090536928],
            [104.793036260433126, 17.40819114812534],
            [104.805545245129139, 17.381936009821246],
            [104.809418187321143, 17.361382074435127],
            [104.81360025410325, 17.286109063310036],
            [104.8144181602174, 17.242491147805694],
            [104.809145104914961, 17.190273064343316],
            [104.802463386960909, 17.152773099983776],
            [104.797491241983352, 17.133327084562481],
            [104.77442726186527, 17.091109114530397],
            [104.746372361306413, 17.03763609252097],
            [104.741082374559511, 17.010691123583754],
            [104.739963390482473, 16.973191159224299],
            [104.742418282291368, 16.946718091437148],
            [104.745527297825873, 16.910273073414302],
            [104.760818236193558, 16.753327132795718],
            [104.768736284869391, 16.724718020797255],
            [104.767972358211836, 16.691382185947035],
            [104.760127232093765, 16.657773101052427],
            [104.753054246898529, 16.636382148812956],
            [104.742618274501723, 16.566245065053849],
            [104.749572404309703, 16.524445016715376],
            [104.909982250244383, 16.346245084155257],
            [104.96192725130058, 16.299718145326736],
            [104.978854169534571, 16.28759104014064],
            [104.992482305945572, 16.276945017249645],
            [105.012500302441225, 16.251664191373564],
            [105.024291293309176, 16.226664159254497],
            [105.028672346473144, 16.175553995756886],
            [105.044854113512685, 16.132909051575965],
            [105.05748228887208, 16.115000109563127],
            [105.080672332814373, 16.095000050340317],
            [105.18887230955167, 16.056382107732233],
            [105.22026337522172, 16.050273209042828],
            [105.245245134791702, 16.04639104675725],
            [105.276382228794859, 16.043891060309178],
            [105.308591200579002, 16.038609120189406],
            [105.422718188706341, 16.009373209469757],
            [105.429745241072141, 16.000464084559681],
            [105.385545118571684, 15.989927026410371],
            [105.371372326090778, 15.97332700240112],
            [105.345954204640265, 15.926035969277038],
            [105.389427280857234, 15.828054035453405],
            [105.401091202072706, 15.807218132843545],
            [105.442336200781398, 15.761317992736323],
            [105.48450019135683, 15.761873210004225],
            [105.507754105401347, 15.766527010298248],
            [105.557482260699686, 15.748882098236194],
            [105.603863354412482, 15.720064109210156],
            [105.635127182791933, 15.669164163844854],
            [105.639291312301083, 15.639444952586686],
            [105.631236135688908, 15.585691136820131],
            [105.606372393315922, 15.471109011349157],
            [105.600327197090934, 15.436682187978604],
            [105.599991250410682, 15.433053997359892],
            [105.583318303843583, 15.416944985240022],
            [105.565809178613421, 15.405836113654559],
            [105.537072326410367, 15.395273071605331],
            [105.474700237774726, 15.174718041076844],
            [105.471163409900981, 15.11020907003963],
            [105.524427219606451, 15.070273156972647],
            [105.5905542332371, 15.000553994907037],
            [105.5772092375162, 14.911664079121223],
            [105.566382333154166, 14.883053961294465],
            [105.545254237398893, 14.858609146443413],
            [105.515963341394325, 14.815553991919828],
            [105.528318266709476, 14.648054061707327],
            [105.53637226985515, 14.607636021568297],
            [105.535400304360309, 14.563891036411476],
            [105.515263284839165, 14.53110907772448],
            [105.493318288798122, 14.503891194019147],
            [105.444427150353334, 14.450553958841638],
            [105.370527262610295, 14.404718024112739],
            [105.2974912138109, 14.369444963795175],
            [105.225609185443858, 14.342353982104228],
            [105.210600214299774, 14.349645064420699],
            [105.193145236164384, 14.349653949238117],
            [105.16470024183073, 14.302500044050433],
            [105.148245224746773, 14.249927070806578],
            [105.121372340177089, 14.230827060349142],
            [105.082136148389083, 14.21895392682994],
            [105.041172278713617, 14.238473200085267],
            [105.009427161452408, 14.288891186016698],
            [104.998454244336671, 14.31735394998519],
            [105.001391263217585, 14.346382157143452],
            [105.003354137308776, 14.377326970287285],
            [104.970836208572479, 14.393327017665584],
            [104.91829123088533, 14.401245066341417],
            [104.889709276253285, 14.419445028033195],
            [104.809009318083127, 14.447636050699927],
            [104.644145161150533, 14.429582101761795],
            [104.605400316528033, 14.398891092647048],
            [104.583736281882096, 14.369373214703884],
            [104.553036220311839, 14.36277313648695],
            [104.475336145296438, 14.357145023765028],
            [104.458554233987826, 14.36616411924517],
            [104.423027202003283, 14.376664129382263],
            [104.283582339987646, 14.407773227828713],
            [104.123727208406791, 14.384582178058125],
            [104.056091284562314, 14.359445018002859],
            [104.045000182611517, 14.354473208301314],
            [104.036027187598989, 14.347844966889866],
            [103.922209324061583, 14.339164165022922],
            [103.722763278549309, 14.390273155053976],
            [103.693727192402008, 14.438535986035092],
            [103.659145136184151, 14.447773178636325],
            [103.564563405212056, 14.423053940274499],
            [103.513182338603116, 14.399444968810471],
            [103.471227225055344, 14.373609093304381],
            [103.379009190785354, 14.357426990988742],
            [103.328327174903023, 14.358400129950141],
            [103.273882355036761, 14.353473079611675],
            [103.244263391341036, 14.335291055192826],
            [103.183863396890075, 14.330553938780795],
            [103.143882221545624, 14.317217995515378],
            [103.094645245774956, 14.291126974876093],
            [103.063736139538861, 14.272427116477459],
            [103.036327148440506, 14.238154017211443],
            [103.023800226471707, 14.227982074765421],
            [102.984836278899564, 14.211245090458036],
            [102.962772259833088, 14.206391130315595],
            [102.940872358431164, 14.18353602722506],
            [102.935391264291439, 14.168609031094448],
            [102.939700233087962, 14.145691063729956],
            [102.902482235952135, 14.036664128423112],
            [102.858036188411717, 13.993053924269631],
            [102.809418300011231, 13.95555395991029],
            [102.79525422470968, 13.94139105807507],
            [102.78470928757136, 13.923473063607005],
            [102.775545185165896, 13.88860904016552],
            [102.762763285701823, 13.850827108582479],
            [102.722072330794646, 13.765000107863386],
            [102.629436207192981, 13.716944980443827],
            [102.5655362652424, 13.681182081703284],
            [102.566927158259062, 13.646664230863962],
            [102.599018280484444, 13.615136037257528],
            [102.563872289819273, 13.580553981039586],
            [102.541018192557232, 13.570554035247184],
            [102.465818271628081, 13.56194498247163],
            [102.439418294036869, 13.56221806487801],
            [102.396654159192309, 13.568609098429008],
            [102.377200264781976, 13.57389103854878],
            [102.359845198932561, 13.496944999545235],
            [102.356091279765934, 13.379164172175351],
            [102.353045128505386, 13.310273142145945],
            [102.350100230635547, 13.29897316316746],
            [102.346509255667144, 13.281736114515041],
            [102.395136196523055, 13.16166417772493],
            [102.417209268045013, 13.116945047778472],
            [102.492482279170105, 12.976664174737707],
            [102.533809252892098, 12.798400036799151],
            [102.523609314889342, 12.756664193839114],
            [102.521654152149182, 12.750800046723128],
            [102.50680024621434, 12.736945096011624],
            [102.50360020321105, 12.685482054389311],
            [102.522900205878642, 12.657773158794413],
            [102.57011814880687, 12.644791099481978],
            [102.633736123534021, 12.602909076130246],
            [102.779427179813439, 12.451944964548375],
            [102.787782260922, 12.415853998116688],
            [102.747754146919675, 12.373682128552232],
            [102.717209318196552, 12.195553945083674],
            [102.717272350108686, 12.166318034364124],
            [102.846427252481277, 11.839791104606689],
            [102.886918215178099, 11.786945048956454],
            [102.898418186367024, 11.778882160993476],
            [102.906909221945483, 11.767782174225161],
            [102.91539120506863, 11.745691165430472],
            [102.924054237300737, 11.652153987234911],
            [102.916091261811886, 11.635854035180145],
            [102.910935468500128, 11.632391058500062],
            [102.884721555000084, 11.625837692000118],
            [102.859818303000139, 11.619284204000024],
            [102.825740168000124, 11.611420019000107],
            [102.785108546000089, 11.600934440000103],
            [102.764137387000147, 11.593070255],
            [102.767061102000213, 11.568966739000075],
            [102.768309464500049, 11.561046373500076],
            [102.76903455600015, 11.54531242600001],
            [102.772528549000157, 11.495214252],
            [102.773266539000105, 11.485660723],
            [102.771409253000087, 11.447294410000097],
            [102.751156419000182, 11.400714880000166],
            [102.749855228000087, 11.397813804000023],
            [102.749471896000074, 11.39696332200009],
            [102.748723928000089, 11.395312914000044],
            [102.747965034000089, 11.393662497000093],
            [102.746993882000226, 11.391561785000093],
            [102.746027217000147, 11.38951114200006],
            [102.745624420000155, 11.388660640000126],
            [102.727696259000112, 11.350997491000129],
            [102.726855581000081, 11.349209346000052],
            [102.595503749000187, 11.292085578000055],
            [102.569627049000104, 11.280767095000144],
            [102.487612738000138, 11.245153947000119],
            [102.428374112000057, 11.218869201000103],
            [102.264566956000095, 11.145548712000064],
            [102.231875599000062, 11.130220556000125],
            [102.194482935000138, 11.110606641000146],
            [101.330389756000187, 10.655609620000121],
            [101.39296129900012, 10.529363438000061],
            [101.442492280000153, 10.234884722000089],
            [101.442648910000145, 10.233950138000125],
            [101.480212360000138, 10.009671512000025],
            [101.482463346000173, 9.969161258],
            [101.482744448000147, 9.964960854000097],
            [101.485755027000124, 9.91997480400002],
            [101.492351811000077, 9.820519433000015],
            [101.493290337000104, 9.806369901999986],
            [101.494340024000081, 9.79710893000005],
            [101.499032835000065, 9.755706095999983],
            [101.603895904000211, 9.624551957000094],
            [101.712228968000119, 9.475228004000073],
            [101.966958064000124, 9.141445051000119],
            [102.075291128000146, 8.977481495000106],
            [102.151417064000128, 8.86036466900002],
            [102.203237000000144, 8.781882000000166],
            [103.041667000000103, 7.8],
            [103.133228143000082, 7.724810466000065],
            [103.196732208000157, 7.67266125900008],
            [103.404792110000159, 7.501803551000123],
            [103.625385942000122, 7.320653046000118],
            [103.1, 7.050000000000182],
            [102.566667000000137, 6.883333],
            [102.353333000000106, 6.833333000000152],
            [102.16000000000011, 6.463333],
            [102.166667000000103, 6.458333000000138],
            [102.093332714500178, 6.241666966500148],
            [102.095227173920108, 6.236136105840728],
            [102.090527273078379, 6.154164109881677],
            [102.011091305910469, 6.038327045863667],
            [101.983927234023582, 6.013682071164084],
            [101.952609258549472, 5.975553966979064],
            [101.943727291005786, 5.956527046717497],
            [101.940800330408621, 5.927500013025707],
            [101.943709186094878, 5.903335991931868],
            [101.944054185230385, 5.867082081301959],
            [101.826091302923231, 5.740973162551825],
            [101.800718276111894, 5.745973135448068],
            [101.771854186618242, 5.777500155587845],
            [101.746354090154426, 5.789164076803388],
            [101.699545184102391, 5.760973054136542],
            [101.692318307164243, 5.764309051609743],
            [101.658572261971443, 5.821735984996138],
            [101.659409278824938, 5.850627064218017],
            [101.606491306445349, 5.905273217399198],
            [101.571018253917515, 5.915209125451071],
            [101.506045260719276, 5.880573089776675],
            [101.452191197390363, 5.863054073900642],
            [101.311900266065805, 5.807218025741449],
            [101.259418152652785, 5.714582069778018],
            [101.223227273934839, 5.670973206729087],
            [101.189691279236087, 5.651109101976104],
            [101.164982266796329, 5.638336087329421],
            [101.142327155916149, 5.633473074731654],
            [101.057454183419367, 5.729164234411144],
            [100.994663334900025, 5.779618094888136],
            [100.991145114851207, 5.794309056624854],
            [101.04225410488246, 5.901809144445025],
            [101.069000255075792, 5.914582159091708],
            [101.095663424427499, 5.923953965058359],
            [101.119200311523997, 5.990273091910311],
            [101.106354206681459, 6.050273101940817],
            [101.099136214560843, 6.118891049564027],
            [101.114263370540044, 6.182464097290023],
            [101.112045183677338, 6.250691112948118],
            [101.009700301600702, 6.269444950803305],
            [100.952063317720132, 6.240827121625614],
            [100.853236152777896, 6.243891042520829],
            [100.846918209422569, 6.285000086759766],
            [100.847872405282857, 6.296964080109646],
            [100.854418168766898, 6.322500051119206],
            [100.828163365739073, 6.427773067259409],
            [100.751363339489245, 6.502773163616382],
            [100.716918243569609, 6.480000035539305],
            [100.681354163572934, 6.453336027997295],
            [100.656636266315587, 6.448682060065195],
            [100.436900315367694, 6.515554057252174],
            [100.387482290487895, 6.536108998466617],
            [100.379045234366146, 6.540764139865288],
            [100.374400318889371, 6.543891092672638],
            [100.33517234136653, 6.586109062704622],
            [100.330663380359653, 6.620418036516185],
            [100.300245118374761, 6.699791139410124],
            [100.264136214670344, 6.70666413239509],
            [100.214982219741245, 6.711109055661524],
            [100.203845184960898, 6.709364111053517],
            [100.194063336289759, 6.697427107431864],
            [100.174545236500904, 6.622364146801019],
            [100.184127260599809, 6.597500069151963],
            [100.192745198192682, 6.575282158342688],
            [100.168445222628833, 6.470836100772019],
            [100.141918175385143, 6.436664087258578],
            [100.127108811725719, 6.424945473060092],
            [100.112300427000179, 6.412048933000136],
            [100.092289427000225, 6.393466933000184],
            [100.073707427000073, 6.384890933000165],
            [100.047979427000115, 6.384890933000165],
            [100.015104427000068, 6.396325933000128],
            [99.999381427000145, 6.429200933000075],
            [99.980799427000107, 6.457787932999977],
            [99.957929427000096, 6.473510933000171],
            [99.917907427000131, 6.490663933000107],
            [99.886461427000228, 6.50352793400009],
            [99.85358542700007, 6.517821934000082],
            [99.827857427000112, 6.530685934000147],
            [99.803558427000013, 6.534973934000021],
            [99.784976427000146, 6.527826934000117],
            [99.764965427000078, 6.509245934000063],
            [99.753530427000129, 6.487804933000163],
            [99.739236427000122, 6.473510933000171],
            [99.71064942700005, 6.469222933000026],
            [99.679203427000203, 6.47208193299997],
            [99.653332427000151, 6.474999933000092],
            [99.556666697000111, 6.503332854000021],
            [99.511667, 6.481667000000172],
            [99.458333000000181, 6.306667000000132],
            [99.32166674033337, 6.271666987000032],
            [99.111666685000188, 6.299999898000053],
            [98.024999986800054, 5.950000043200006],
            [97.9, 6.363333000000267],
            [96.608333000000272, 7.096667000000153],
            [95.551667000000151, 7.768333],
            [95.530000000000115, 7.783333],
            [95.54666666700021, 7.8],
            [95.69666666700013, 7.958333333000041],
            [95.65444444400012, 8.165],
            [95.653055556000055, 8.229722222000021],
            [95.628333333000086, 8.753055556000078],
            [95.627777778000137, 8.801111111000012],
            [95.608611111000215, 9.288333333000054],
            [95.590277843200141, 9.633333],
            [96.493055556000144, 9.758333333000024],
            [97.443333333000197, 9.676388889000179],
            [97.62666666700008, 9.623333333000161],
            [97.724722222000167, 9.600555556000145],
            [97.75805555600013, 9.594166667000081],
            [97.853333333000052, 9.581666667000022],
            [97.869444444000038, 9.574722222000091],
            [97.917543225000173, 9.548941276],
            [97.938888889000083, 9.5375],
            [97.979344388000101, 9.5158832340001],
            [98.010444194000087, 9.505516632000081],
            [98.062277204000026, 9.48823896200004],
            [98.121021282000157, 9.491694496000136],
            [98.165943223000141, 9.498605564000059],
            [98.20395409700015, 9.533160904000141],
            [98.224687301000102, 9.591904982000088],
            [98.238509437000147, 9.629915856000082],
            [98.248876039000066, 9.66447119599998],
            [98.262698175000111, 9.702482069999974],
            [98.276520311000041, 9.73358187600013],
            [98.293797981000154, 9.768137215000024],
            [98.307620117000198, 9.806148089000018],
            [98.321442253000129, 9.840703429000101],
            [98.342175457000195, 9.875258769000084],
            [98.36981972900017, 9.902903041000144],
            [98.390552933000123, 9.934002847000031],
            [98.400355498000096, 9.943520153000037],
            [98.406773845000117, 9.948512200000081],
            [98.421036837000116, 9.957069995000012],
            [98.43601297800015, 9.964914641000178],
            [98.455981167000147, 9.959922593000044],
            [98.472383608000229, 9.954930546000185],
            [98.489499199000164, 9.952077948000152],
            [98.498056994000109, 9.952077948000152],
            [98.5059016390002, 9.955643696000038],
            [98.508754238000137, 9.961348893000022],
            [98.513033136000132, 9.969906688000037],
            [98.52373038000016, 9.979890782000126],
            [98.532288175000105, 9.974898735000082],
            [98.534427624000188, 9.971332987000011],
            [98.543698568000167, 9.967054089],
            [98.552969513000022, 9.963488341000115],
            [98.564379907000074, 9.962062042000142],
            [98.577929749000219, 9.974898735000082],
            [98.587200694000018, 9.986309129000048],
            [98.599324238000094, 9.997006373000076],
            [98.605742584000126, 10.006990467000065],
            [98.612160930000215, 10.024819207000135],
            [98.621434729333458, 10.044795098666711],
            [98.638279426000082, 10.08401193700017],
            [98.664754426000201, 10.136961937000066],
            [98.689023426000148, 10.18329393700013],
            [98.711085426000096, 10.242862937000027],
            [98.726529426000099, 10.282574937000092],
            [98.737560426000215, 10.322287937000141],
            [98.742751530806999, 10.348602299841673],
            [98.748872350548396, 10.378054074312715],
            [98.759427178332686, 10.409718054750883],
            [98.771518241335144, 10.432636022115375],
            [98.796091299305118, 10.456600050999043],
            [98.824291206789354, 10.519864141772928],
            [98.797063264799959, 10.59500002496172],
            [98.775272328139778, 10.621735949233283],
            [98.784982260081478, 10.677500080663108],
            [98.82457233582258, 10.731945068167278],
            [98.868482276834328, 10.77336407518645],
            [98.897918179764218, 10.776527070177622],
            [98.935254194097041, 10.822217997981113],
            [99.048872400700276, 10.958053947755261],
            [99.110672340623552, 11.013335952113138],
            [99.128036123652208, 11.024164197579637],
            [99.144136250954574, 11.030273096269028],
            [99.167072323229974, 11.042427023545457],
            [99.21331830066319, 11.099164126298433],
            [99.25443622971946, 11.159445097723975],
            [99.265545101304923, 11.176664209103478],
            [99.324436185542169, 11.278891073983303],
            [99.358318185204809, 11.350000123237351],
            [99.465545190618883, 11.56832707625739],
            [99.560118204411452, 11.630482073600135],
            [99.638182221663925, 11.74180900314488],
            [99.657345264033182, 11.8264539878747],
            [99.628727267217442, 11.833054066091449],
            [99.584218187764947, 11.884445023346316],
            [99.556636194184563, 12.0158360498543],
            [99.56330919495943, 12.101664224039595],
            [99.570263324767353, 12.138745093239137],
            [99.499709325143186, 12.188327068145455],
            [99.455554129643787, 12.298609109637496],
            [99.447063429341398, 12.331391068324393],
            [99.428863300011557, 12.49888211371939],
            [99.436236189703294, 12.574436086239871],
            [99.305254200071573, 12.665273118138742],
            [99.239009169243701, 12.734718024331571],
            [99.112409238604386, 13.061108999619094],
            [99.124918223300284, 13.179235999591029],
            [99.15457239098933, 13.197918088354839],
            [99.20338222497324, 13.203582075622492],
            [99.210682359745221, 13.21139115955674],
            [99.213882235110333, 13.234717996159162],
            [99.216654130498142, 13.412082085332244],
            [99.210263264585222, 13.468327002729467],
            [99.188309216088811, 13.541664129491693],
            [99.176927262096882, 13.578053994591599],
            [99.169145167890889, 13.63471800714882],
            [99.170254093684122, 13.665273061793656],
            [99.173963421125762, 13.72778211072756],
            [99.12929122983715, 13.771664223820679],
            [99.036372300821625, 13.934718057300543],
            [99.003327150374417, 14.01082707945055],
            [98.973872304343132, 14.079164233316718],
            [98.93928236913635, 14.101109061719782],
            [98.886654242969456, 14.131382149141203],
            [98.807209223346064, 14.185000178075924],
            [98.759718198011711, 14.217500169539207],
            [98.614700208559185, 14.322153930671178],
            [98.570263381112255, 14.384164088726592],
            [98.540818258088876, 14.44694504660022],
            [98.51943619066671, 14.486664203650705],
            [98.468045233411857, 14.578327020652793],
            [98.443309231243603, 14.611527068671123],
            [98.41790921470411, 14.636391146320349],
            [98.392491260891546, 14.651291152722749],
            [98.32540921321052, 14.715000154918457],
            [98.262082258162394, 14.811800072753201],
            [98.247482323894445, 14.874026986825314],
            [98.255272297089476, 14.898745051720752],
            [98.252063369268768, 14.928326967404345],
            [98.234563296494144, 14.965827099401977],
            [98.216154122498352, 14.993844951948532],
            [98.201100224353326, 15.075000047462609],
            [98.203045161171502, 15.204444963685759],
            [98.205472392699818, 15.226782065158417],
            [98.23429121991623, 15.221182115631294],
            [98.397763310365605, 15.271109089673374],
            [98.420254135942997, 15.284164239181663],
            [98.423936138380242, 15.360482138359728],
            [98.50054522487514, 15.387500029854706],
            [98.552127289522929, 15.357082103146084],
            [98.562963413978451, 15.334582057475203],
            [98.581791347857887, 15.357909061715603],
            [98.589427261672114, 15.382773139364858],
            [98.593600275998568, 15.416944985240022],
            [98.59610026244664, 15.452364225949466],
            [98.59248229774991, 15.477500044900339],
            [98.576936214248946, 15.53305395819784],
            [98.571382365189294, 15.555273210111594],
            [98.565400200876326, 15.60763596522311],
            [98.570827147921392, 15.643891049319606],
            [98.575572310960439, 15.661882133983696],
            [98.568882211103215, 15.698326984368364],
            [98.565263408216168, 15.722773140324094],
            [98.61499122823821, 15.965827043056791],
            [98.631654116521446, 16.046109079533537],
            [98.679282269792253, 16.120827041028903],
            [98.698863401493128, 16.129300139334546],
            [98.789282344060581, 16.118400144776771],
            [98.8123362658948, 16.104853983378931],
            [98.857763331385485, 16.141035977279373],
            [98.915954191429449, 16.261391054759585],
            [98.921200257003505, 16.395345099627789],
            [98.864991214152013, 16.43860913117895],
            [98.827482364975168, 16.402500059836271],
            [98.788591339960789, 16.353054039399765],
            [98.714572261554252, 16.27555395659472],
            [98.691227152402831, 16.283054083577142],
            [98.66804515508764, 16.381936066166347],
            [98.662072378506224, 16.459854070664804],
            [98.563600270983358, 16.630827126286803],
            [98.52249122674462, 16.685273119619652],
            [98.480018279217319, 16.738954012828245],
            [98.506691339214655, 16.782109079637834],
            [98.549991245311645, 16.854717986650328],
            [98.543872288338349, 16.883882148278488],
            [98.511109272752861, 16.941109089454457],
            [98.46165419986076, 17.015827050949909],
            [98.42360019169999, 17.05332701530925],
            [98.400545264037277, 17.052773139145827],
            [98.374691283620479, 17.050553946454883],
            [98.304427130208779, 17.14082704390681],
            [98.272763317408618, 17.173882084999818],
            [98.205554200074999, 17.235827031848416],
            [98.179427137251963, 17.25777320135596],
            [98.118863360412519, 17.313882164283271],
            [98.053036251278314, 17.415827061939481],
            [97.968872387792061, 17.528327122655796],
            [97.923872296450298, 17.568964098106491],
            [97.850963317303382, 17.623473123351189],
            [97.793872330597225, 17.684164137481318],
            [97.778318200469272, 17.703327012212625],
            [97.687272291542541, 17.867709042073173],
            [97.706991221731954, 17.928191179175641],
            [97.743945188917081, 17.954854013251079],
            [97.751109201581158, 17.974854072473974],
            [97.687191322357677, 18.128609022544751],
            [97.652482364125405, 18.175000174541552],
            [97.6209541705189, 18.225482198213328],
            [97.623454156966972, 18.252773172114715],
            [97.643354136265685, 18.280482067709428],
            [97.618454184070828, 18.315000086186913],
            [97.586100205361248, 18.326664175040364],
            [97.554363302365203, 18.31958213738956],
            [97.538936241889729, 18.275482094813256],
            [97.519291240086403, 18.266391082603931],
            [97.497909172664237, 18.279164097250614],
            [97.45095408622015, 18.336873165498517],
            [97.450272302214017, 18.370273205727287],
            [97.445945228506758, 18.390826973475427],
            [97.437482356122899, 18.410553950291941],
            [97.393600243029795, 18.493464216335823],
            [97.367545096936141, 18.5218000782899],
            [97.34727229058322, 18.542218059206121],
            [97.354082251656138, 18.575209062558784],
            [97.362291320010996, 18.579653985825118],
            [97.368454198157025, 18.558191116856094],
            [97.446645117423799, 18.496309034281481],
            [97.506654179909532, 18.496109042071126],
            [97.551372304027865, 18.507082126824955],
            [97.626918229921074, 18.555273208714496],
            [97.652772377976049, 18.56221811842893],
            [97.751854185137432, 18.572500031445045],
            [97.775682259551189, 18.572354018691144],
            [97.778872411908679, 18.604718055684529],
            [97.780272357380937, 18.634991143106035],
            [97.781518243472391, 18.678464219322947],
            [97.746227245881897, 18.867500108700412],
            [97.719700198638208, 18.889436052286172],
            [97.679836202300663, 18.932145034207437],
            [97.747200217205233, 19.031527081141505],
            [97.784291312326758, 19.068191034476015],
            [97.831791222478586, 19.098673166563074],
            [97.84054511454147, 19.167773073620438],
            [97.841809273181894, 19.207100125239052],
            [97.83415424862838, 19.290000165360951],
            [97.802754130502905, 19.359853940791893],
            [97.792345147834482, 19.398882093742557],
            [97.866382331151755, 19.521664235112993],
            [97.869082309810352, 19.570173158771993],
            [97.907482322935294, 19.574717994324416],
            [97.964291342417738, 19.601527008791734],
            [98.035954100197017, 19.647773153863128],
            [98.039700308012726, 19.671936001490579],
            [98.028736275714408, 19.713953979312308],
            [98.04935424884107, 19.804791178849356],
            [98.074718223196982, 19.809300139856035],
            [98.139436238899776, 19.78305405400765],
            [98.177200233210016, 19.758054021888583],
            [98.215272346281864, 19.72638216246132],
            [98.242200216136581, 19.689991123895211],
            [98.337554088031339, 19.693673126332371],
            [98.391100200236707, 19.695827107816299],
            [98.476027152190142, 19.693673126332371],
            [98.520536231642524, 19.709718100711811],
            [98.64915418929607, 19.741935957313402],
            [98.838182367322673, 19.81500016930751],
            [98.891109224519624, 19.776109144293045],
            [98.945818241974763, 19.771109003758895],
            [99.000472274144869, 19.784436062206979],
            [99.032909233696017, 19.83582701946186],
            [99.038182288998456, 19.857218139339594],
            [99.03011822756892, 19.890827056596024],
            [99.022763275149913, 19.924300019382642],
            [99.041927155709601, 20.042773191956528],
            [99.079091341026952, 20.098473118007732],
            [99.164145194994802, 20.126664140674578],
            [99.19303627421678, 20.126800095144503],
            [99.243863297024319, 20.117218071045599],
            [99.27012715250774, 20.10152698061934],
            [99.299572275531119, 20.076800030906483],
            [99.331927260069079, 20.067082052337682],
            [99.45207228705496, 20.09693604459919],
            [99.487618262140842, 20.118609131700438],
            [99.515727142156067, 20.145618138378111],
            [99.539563263196925, 20.153745064081718],
            [99.554836264291765, 20.202845079554166],
            [99.502209311591201, 20.308891074807448],
            [99.528454223973284, 20.350136073516282],
            [99.61110029242829, 20.323327059048879],
            [99.669991209027472, 20.311382176438187],
            [99.788318201209592, 20.329718092599862],
            [99.81568226530672, 20.340409042492084],
            [99.834436270800069, 20.364164194347993],
            [99.851382299773292, 20.404718021318857],
            [99.873663410132536, 20.428882210050759],
            [99.962627254304692, 20.454582131086866],
            [99.980818163541045, 20.435554037358983],
            [99.986091218843484, 20.415964188478867],
            [100.019991323417031, 20.383327069079101],
            [100.084400214529893, 20.348636048119673],
            [100.091372281610717, 20.348609058391432],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "TKL", Country: "Tokelau" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-168.953453656999983, -6.887176653999916],
            [-168.72962065699997, -7.111009655],
            [-168.652232657000013, -7.188397654999974],
            [-168.650659656999977, -7.190643655],
            [-168.646235657000034, -7.195067654999846],
            [-168.644995657000038, -7.196839655],
            [-168.644236657000022, -7.197597654999925],
            [-168.643440656999985, -7.198651654999921],
            [-168.642180656999983, -7.200367654999965],
            [-168.641230657, -7.201316654999971],
            [-168.638393657, -7.205303655],
            [-168.636999656999961, -7.207228654999923],
            [-168.636241657000028, -7.207986654999843],
            [-168.635022656999979, -7.209727654999924],
            [-168.634242657000016, -7.210507654999887],
            [-168.599394656999976, -7.260275654999944],
            [-168.545798657, -7.336818654999888],
            [-168.30119665700002, -7.686145654999876],
            [-168.299294157, -7.690158654999919],
            [-168.298887657000023, -7.690959654999929],
            [-168.298190657, -7.691955654999916],
            [-168.23460365699998, -7.828318654999933],
            [-168.052798657000011, -8.218200655999922],
            [-167.98861465600001, -8.308218655999866],
            [-167.989493655999979, -8.311035655999888],
            [-168.523610657, -10.023888656999972],
            [-169.780555658000026, -10.131110657],
            [-170.26944465899993, -10.171666657999893],
            [-171.258888659000036, -10.254722657999963],
            [-171.849444659999989, -10.297222657999953],
            [-172.183610660000028, -10.423888657999839],
            [-173.064722660999905, -10.770833657999916],
            [-173.746666662000024, -11.038055658],
            [-173.854222662000041, -11.006931657999885],
            [-174.075336651999947, -10.958864001999927],
            [-174.273371210000022, -10.946565564999844],
            [-174.365323269000044, -10.875869880999915],
            [-174.847757197000021, -10.503167606000034],
            [-174.958489646999965, -10.417199395999972],
            [-174.987993492999976, -10.394287553999874],
            [-175.032556845999977, -10.359680928999907],
            [-175.259355947999978, -10.183258713999876],
            [-175.621849668000038, -9.900742294999887],
            [-175.65420166399997, -9.725989656999985],
            [-175.804484664000029, -9.165127656999957],
            [-175.838566663999927, -8.775563655999989],
            [-175.85509066399996, -8.586689655999891],
            [-175.854543664, -8.580436655999975],
            [-175.858081664, -8.539999655999878],
            [-175.858008663999897, -8.539169655999856],
            [-175.858081664, -8.538339655999934],
            [-175.807474663999983, -7.959901654999868],
            [-175.761499663999984, -7.773391654999884],
            [-175.368385663, -7.571324654999856],
            [-174.499503663000041, -7.124143654999941],
            [-173.63454266200003, -6.679656653999814],
            [-173.330202661000015, -6.523456653999915],
            [-173.219807661, -6.466727653999982],
            [-172.907617661000018, -6.491141653999932],
            [-172.569820661000023, -6.518947653999874],
            [-172.19438166, -6.549570654000021],
            [-172.07127366, -6.559827653999946],
            [-171.56715166, -6.599391653999831],
            [-171.242996659, -6.698969653999853],
            [-170.878947659000033, -6.8124126539999],
            [-170.628736659000026, -6.890064653999957],
            [-170.591047659000026, -6.89467865399989],
            [-170.22557365900002, -6.893023653999819],
            [-170.208228659000014, -6.892937654],
            [-170.169527658000021, -6.892794653999971],
            [-169.700010657999968, -6.890861653999906],
            [-168.953453656999983, -6.887176653999916],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "TKM", Country: "Turkmenistan" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [66.537736191304447, 37.366382093897428],
            [66.512418149778142, 37.361664255862863],
            [66.489836129094016, 37.344153957166256],
            [66.454500204502409, 37.321518124663655],
            [66.440845078363139, 37.318882183745572],
            [66.413882339791002, 37.343736035472844],
            [66.352345088714031, 37.336653997822012],
            [66.317627245664283, 37.326109060683606],
            [66.287482233723864, 37.326518097559713],
            [66.186372341264388, 37.371936110595087],
            [66.105254126124436, 37.421382131031592],
            [66.008045171413784, 37.455273183149714],
            [65.771927125939868, 37.537500156444722],
            [65.697545111124867, 37.532564221289007],
            [65.681091267507412, 37.516935995136706],
            [65.66665410979968, 37.494991166733726],
            [65.645691305175461, 37.45388212249479],
            [65.644291192065026, 37.42749119735906],
            [65.649918131320447, 37.396173221884865],
            [65.648527238303672, 37.352282223974427],
            [65.563045069082335, 37.261318122422878],
            [65.524154211706076, 37.247909088961521],
            [65.467754061461648, 37.244991180819923],
            [65.437254159739638, 37.244373099278064],
            [65.39138218282713, 37.248600093061143],
            [65.362200251564133, 37.248600093061143],
            [65.093318184676235, 37.246100106613071],
            [65.066791305070552, 37.242909116065263],
            [65.043591202844453, 37.235545111190945],
            [64.844436177011204, 37.148045082593583],
            [64.823045224771619, 37.138600186430992],
            [64.793727171400661, 37.12151803534978],
            [64.780682247814156, 37.102491115088398],
            [64.777627211736245, 37.062636003568102],
            [64.78553620795671, 37.028045062532826],
            [64.790263266084736, 37.007218044740412],
            [64.800045114755875, 36.955409165792119],
            [64.792900212831029, 36.919436048919337],
            [64.776382331473229, 36.893882140636961],
            [64.74432725143177, 36.850626993903106],
            [64.715545136951363, 36.802773199798267],
            [64.693591256092901, 36.76832709805025],
            [64.652072336787654, 36.710136070368279],
            [64.637354217684702, 36.68901803289684],
            [64.626091286718321, 36.663882213946053],
            [64.6169271843128, 36.636382195379028],
            [64.609845146662082, 36.59999115681272],
            [64.614572372428114, 36.567354037413239],
            [64.630391203059247, 36.532082150562061],
            [64.63304508125006, 36.486382170303145],
            [64.625954158781866, 36.45360004397817],
            [64.615263376527906, 36.423609091418555],
            [64.601382274278393, 36.396945083876446],
            [64.581372324409784, 36.36943601285391],
            [64.523882191473234, 36.299436057031286],
            [64.503600165026938, 36.280545091239716],
            [64.466382335529062, 36.251382103077916],
            [64.427127200639831, 36.246036125217799],
            [64.353318172727455, 36.230818109407991],
            [64.164572297200635, 36.164718085505498],
            [64.094982216444379, 36.123318021587735],
            [63.977773202510747, 36.039164216385259],
            [63.927209203825527, 36.038327031893758],
            [63.888973308365308, 36.03162704139082],
            [63.801382252299192, 35.987636131194208],
            [63.768882260835795, 35.978736058739528],
            [63.698045120521641, 35.970827062519206],
            [63.655545183265957, 35.969154034640653],
            [63.633327104818676, 35.969436001864366],
            [63.613054130827692, 35.967491065045991],
            [63.589991324176054, 35.958254040082849],
            [63.550136212655758, 35.917909090139588],
            [63.522773322025017, 35.902909171451128],
            [63.342491324710437, 35.857218076009545],
            [63.317218210185189, 35.852218103113486],
            [63.295827257945604, 35.851664226950064],
            [63.272763277827522, 35.852773152743296],
            [63.240273344648102, 35.856653973924338],
            [63.204436182245246, 35.86166417274238],
            [63.123109257715697, 35.86279120344652],
            [63.104854143100823, 35.831936076666963],
            [63.158327165110251, 35.764164198352589],
            [63.184718257884043, 35.67360913367726],
            [63.137218180094209, 35.652764178612003],
            [63.105336102534778, 35.633736084884148],
            [63.099300294041342, 35.55638218247087],
            [63.12442722817471, 35.53971812072119],
            [63.107354129548895, 35.456936097796458],
            [63.086800194162748, 35.432209148083516],
            [63.050545110066338, 35.429991128859044],
            [63.023045091499313, 35.427773109634686],
            [62.986109229225093, 35.415827053557521],
            [62.922491254498055, 35.38416424658574],
            [62.903318153844879, 35.374164133155361],
            [62.883191192607654, 35.357491186588234],
            [62.836936162718843, 35.324164236555518],
            [62.727073216386913, 35.257636065037715],
            [62.629991163690676, 35.225818025218743],
            [62.549573340382011, 35.236664207958071],
            [62.527909138098181, 35.258609036361051],
            [62.513054058696838, 35.2737450771575],
            [62.469536223116762, 35.287564153323373],
            [62.427218173160526, 35.265000069912148],
            [62.410545226593484, 35.250544974931401],
            [62.387218222352914, 35.227209085873582],
            [62.361109264440785, 35.198600141513211],
            [62.339018255645925, 35.169991197153038],
            [62.312118213709851, 35.145991126085633],
            [62.273809228053693, 35.201444959458954],
            [62.269991271146608, 35.256245004382876],
            [62.16360916157501, 35.335818099486985],
            [62.132563262678701, 35.368054060999569],
            [62.127773340276605, 35.374709124501365],
            [62.072491168280834, 35.419991183066728],
            [62.04270020793146, 35.441236122552581],
            [61.991663302267767, 35.458882208081022],
            [61.965691304653859, 35.459164175304736],
            [61.817500261926369, 35.418882089635517],
            [61.798327161273193, 35.411654039230896],
            [61.595827253149338, 35.444154030694179],
            [61.57666337258965, 35.450827031468904],
            [61.528463238244598, 35.483736059808294],
            [61.503327251655634, 35.509435980844373],
            [61.44680019939679, 35.551653950876457],
            [61.429345221261286, 35.554773192332775],
            [61.375054125499645, 35.636100116862409],
            [61.29638225262741, 35.628536119777536],
            [61.276554190058249, 35.607245079824068],
            [61.253609232965545, 35.632764119389108],
            [61.233182199593813, 35.66262699646812],
            [61.230473336117967, 35.69228216998566],
            [61.262082331271273, 35.816518068646801],
            [61.237073246696724, 35.891382210533877],
            [61.22581819471938, 35.90388214277435],
            [61.211663339511432, 35.937491060030894],
            [61.201100297462034, 36.069164221400385],
            [61.216936227175694, 36.108609122578187],
            [61.221373271453132, 36.13999113579284],
            [61.220818221823237, 36.178882160807106],
            [61.184436235712525, 36.28666421585099],
            [61.162627194141265, 36.41970915949814],
            [61.189500246349127, 36.568953975095653],
            [61.166663248169499, 36.632209181052488],
            [61.153736174142097, 36.650409142744152],
            [61.07277319184945, 36.650545097214163],
            [60.934436082160715, 36.649718138644531],
            [60.796391165617337, 36.648882127619501],
            [60.666936191110295, 36.647491066964662],
            [60.640827233197996, 36.647218152196459],
            [60.502782316654617, 36.646100173947715],
            [60.364718121734114, 36.645544956679814],
            [60.331245158947496, 36.658045056558294],
            [60.15749115548212, 36.871100127742565],
            [60.141382311000399, 36.891664121412489],
            [60.106100198227438, 36.93693612169416],
            [60.09180016845599, 36.958609041157345],
            [60.076382328073834, 36.988882128578652],
            [60.063463300673419, 37.011664141473247],
            [60.015827100775681, 37.043818127972472],
            [59.813045225428112, 37.124709025897587],
            [59.715545083400372, 37.147218124023865],
            [59.546109268825006, 37.20832705984752],
            [59.479991307649783, 37.232764163347653],
            [59.393054207671156, 37.32305402460581],
            [59.387218223749898, 37.343609133458429],
            [59.371936170199803, 37.404153967196677],
            [59.370545109544793, 37.424709076049297],
            [59.375891255043172, 37.471309105073757],
            [59.37554508244105, 37.495964138057062],
            [59.343045090977824, 37.535544993704477],
            [59.315682200347084, 37.540827101462341],
            [59.293473341993234, 37.535973141320071],
            [59.259854198814907, 37.517145039802372],
            [59.238045157243761, 37.51805397338525],
            [59.202218220762774, 37.533054059712001],
            [59.133882240363079, 37.576664263865311],
            [59.08166331871027, 37.611935983078396],
            [59.052773245316786, 37.631382166137868],
            [58.980818126753746, 37.654709170378268],
            [58.933600183825689, 37.668600163273695],
            [58.806309249086468, 37.670964195251869],
            [58.719436186848299, 37.649718082299543],
            [58.674709177912717, 37.653118117512989],
            [58.64638220077606, 37.661664138376509],
            [58.492491296235272, 37.645545067972918],
            [58.470682254664013, 37.640273186137037],
            [58.407209286862354, 37.641109029523889],
            [58.233736077154134, 37.678673199261951],
            [58.2209632301456, 37.69880904531648],
            [58.22443618791695, 37.721236000791507],
            [58.19777318620325, 37.786245036173497],
            [58.17443612367893, 37.796391162357693],
            [57.699645060998222, 37.926445107667391],
            [57.614436141821187, 37.925273149962109],
            [57.528600256284875, 37.927218086780371],
            [57.454018249259406, 37.938464253940211],
            [57.378873313615287, 37.974709112114809],
            [57.351663308898793, 37.993254072942236],
            [57.370818137002999, 38.070000119735525],
            [57.372073243188112, 38.090545002666246],
            [57.241518227705143, 38.272354015105677],
            [57.208463354250199, 38.282636095759685],
            [57.182491188998057, 38.281800084734655],
            [57.158882217533943, 38.270273123817603],
            [57.14979120532459, 38.260091123087662],
            [57.136863293106813, 38.239782106912926],
            [57.062627123407225, 38.19540914956842],
            [56.942491316514833, 38.211382207218506],
            [56.866709188589397, 38.225609146793985],
            [56.839573279897223, 38.234045029449518],
            [56.796027113484371, 38.252818145682099],
            [56.773882292871036, 38.266936120516007],
            [56.755745195453216, 38.286264118740391],
            [56.695263225988839, 38.266936120516007],
            [56.569718241686104, 38.25901807184006],
            [56.542773272748803, 38.266109161946375],
            [56.471100289047598, 38.263053958230401],
            [56.442763253627135, 38.259991210801573],
            [56.419300294916724, 38.25207316212574],
            [56.385554082085747, 38.231373213985805],
            [56.367209281106767, 38.218600199339122],
            [56.322982168877985, 38.182836127132092],
            [56.326727203227193, 38.162700113439385],
            [56.349645170591572, 38.136027053441879],
            [56.331800266319277, 38.086936090424942],
            [56.219700190023474, 38.071209125452825],
            [56.168745091735133, 38.094718016992871],
            [56.109991303072349, 38.086653955563335],
            [56.077082274733044, 38.080409102404033],
            [56.043191222614809, 38.075000092631669],
            [55.98707320723193, 38.0724910537282],
            [55.868327119889813, 38.093882173605934],
            [55.834709150177872, 38.106654014786031],
            [55.806382173041214, 38.122491117966021],
            [55.76388223578553, 38.124164145844659],
            [55.733318128685113, 38.120682135617656],
            [55.691654202454544, 38.11138207874238],
            [55.670273308498878, 38.108891144749904],
            [55.556654096067319, 38.098327096872268],
            [55.46943620233148, 38.090827137527853],
            [55.437627214967989, 38.083327010545631],
            [55.414154197973772, 38.067773215693549],
            [55.394436105974506, 38.048609167495854],
            [55.376518111506272, 38.038464214778244],
            [55.229427109754766, 37.981935989052843],
            [55.179436097972172, 37.964709166322407],
            [55.140063281162014, 37.954918097557666],
            [55.070409162665243, 37.905818082085133],
            [55.054163357885244, 37.887218135972759],
            [55.037773216732063, 37.865827016095025],
            [54.954709226583617, 37.804991162677752],
            [54.919991215895863, 37.784164144885423],
            [54.890273345742429, 37.774991157662541],
            [54.863327203338741, 37.766664239748778],
            [54.833054115917349, 37.746382213302368],
            [54.809718226859417, 37.711109152984804],
            [54.781863318510915, 37.643045081524733],
            [54.793536124543692, 37.623945071067411],
            [54.808873331016883, 37.577909144128355],
            [54.780691193167542, 37.513882132525211],
            [54.756382332786387, 37.495545042897064],
            [54.668745176252543, 37.440127084069459],
            [54.630545155337956, 37.440273096823077],
            [54.604709279832065, 37.448327099968807],
            [54.580127169406438, 37.452909151171454],
            [54.545827248050301, 37.444154085642182],
            [54.512773212785675, 37.43054505997047],
            [54.48220927332315, 37.414154080627085],
            [54.424709249740914, 37.386109070714028],
            [54.405682329479504, 37.377909054814481],
            [54.356100186935038, 37.360827071371361],
            [54.201663285220008, 37.330273190192813],
            [53.90563631801794, 37.350853931785181],
            [53.849323199613508, 37.347028801355989],
            [53.742843628000145, 37.343729019],
            [53.707464820331978, 37.337392835999239],
            [53.677318243189376, 37.335345079818538],
            [53.527100288630407, 37.323436071753505],
            [53.264401618805977, 37.463585198969454],
            [53.248907089000141, 37.475503922000101],
            [53.230482101000092, 37.487510681000018],
            [53.207355499000101, 37.502763748000049],
            [53.200782776000011, 37.507247925000101],
            [53.190402985000134, 37.514410019000096],
            [53.183105469000139, 37.518768311000102],
            [53.127723694000025, 37.555469513000091],
            [53.080326080000162, 37.587553024],
            [53.02530479400005, 37.624492645000103],
            [53.012269974000134, 37.633321762000108],
            [52.999711990000179, 37.642356873],
            [52.956703186000169, 37.672836303999972],
            [52.922651291000108, 37.697946548000075],
            [52.902633667000117, 37.713289261000156],
            [52.897546768000126, 37.717275620000024],
            [52.887678146000013, 37.724163055000034],
            [52.867570877000134, 37.737918854],
            [52.836921692000061, 37.759357452000145],
            [52.786849976000013, 37.795392990000025],
            [52.780427933000141, 37.799928664999968],
            [52.755672455000166, 37.817527771000144],
            [52.726230621000155, 37.838867187999981],
            [52.717552185000187, 37.845197678000105],
            [52.658569336000113, 37.887458801000136],
            [52.636650085000184, 37.90509986900004],
            [52.596994400000057, 37.936838150000099],
            [52.591415405000049, 37.941230774],
            [52.551292419000077, 37.972999573],
            [52.517494202000222, 38.002464294000148],
            [52.485445023000096, 38.03069496200014],
            [52.481903076000066, 38.033618927000035],
            [52.480001450000174, 38.035221099999987],
            [52.472200394, 38.036623001],
            [52.463626862000098, 38.038208007999955],
            [52.379053116000108, 38.055639266999989],
            [52.358242035000018, 38.060144424000086],
            [52.331420898000118, 38.067680359000107],
            [52.290206909000148, 38.079502106],
            [52.237651825000142, 38.094718932999953],
            [52.219614029000098, 38.100036621000029],
            [52.090948105000194, 38.137704849],
            [52.035282135000017, 38.154401779000139],
            [52.003511429000042, 38.164907455000034],
            [51.998817444000139, 38.166482925000096],
            [51.969062805000107, 38.176753998000024],
            [51.909090042000088, 38.197785377500097],
            [51.902812958000055, 38.201423645],
            [51.862430573000125, 38.227014542000163],
            [51.693935394000135, 38.344964981000018],
            [51.659566879000153, 38.368946075000125],
            [51.627344131, 38.391536713000121],
            [51.604780197000167, 38.413887024000118],
            [51.517299652000048, 38.556043624999958],
            [51.508251190000095, 38.570652008000124],
            [51.442623138000073, 38.675939560000032],
            [51.433158875000089, 38.689397812000081],
            [51.403982162000062, 38.731023788000144],
            [51.478010178000119, 38.969467163],
            [51.493192062790257, 39.173058888588756],
            [51.653309232356634, 39.40817314472848],
            [51.631909227661566, 39.475773194027411],
            [51.63542711243403, 39.527054013074107],
            [51.652654102802643, 39.59908222183283],
            [51.668445105515019, 39.644382217671122],
            [51.673436193593858, 39.825509110828264],
            [51.677009231289418, 40.296009141985323],
            [51.671227226824868, 40.470073108230949],
            [51.638273271484337, 40.672036071635816],
            [51.587536102679081, 40.780744997535393],
            [51.533618169247831, 40.925191173551852],
            [51.412741538360393, 41.054360745226504],
            [51.955533981000173, 41.344839096000115],
            [52.090658188000106, 41.416442871000029],
            [52.21380043000002, 41.480810165000022],
            [52.256681442000087, 41.503664017000133],
            [52.262941360000156, 41.50696754500008],
            [52.441585270500156, 41.740112114500064],
            [52.440073254094585, 41.740936121668327],
            [52.491382205597944, 41.780818031386431],
            [52.807209133007831, 42.016109118290686],
            [52.92304519119736, 42.093600148640135],
            [52.957073203613874, 42.112353986495336],
            [53.006245135815703, 42.135691216657719],
            [53.316663221091432, 42.235827131965479],
            [53.34082724218527, 42.243327091309808],
            [53.398045130905899, 42.257218084205121],
            [53.446591270215009, 42.267909034097258],
            [53.540554081454758, 42.279435995014396],
            [53.598045220219689, 42.284164226608837],
            [53.641936218130212, 42.286945006814236],
            [53.663045203146254, 42.28916419950518],
            [53.771936183983343, 42.306654046357821],
            [53.87721825257907, 42.324854008049684],
            [53.90360029289738, 42.330273243743832],
            [53.935127145399321, 42.33860016165788],
            [53.995545077123182, 42.348600107450196],
            [54.023327230551985, 42.350409089798561],
            [54.05554508715349, 42.348600107450196],
            [54.173045120766147, 42.337209101002841],
            [54.198600202514939, 42.332500147785808],
            [54.225818253858364, 42.322491149537896],
            [54.248327184346664, 42.310545093460846],
            [54.315273277557964, 42.268327123428762],
            [54.566936233066343, 42.153318023808865],
            [54.732209259236953, 42.073327007011144],
            [54.76110922327635, 42.05888213795258],
            [54.934154117731083, 41.929991097892568],
            [54.962736240000964, 41.900618059236635],
            [54.958327191280347, 41.8552731363974],
            [54.962773288013153, 41.816454028112375],
            [54.980691282481331, 41.792500057512612],
            [55.00193622196727, 41.771382187679237],
            [55.047909284632368, 41.733600088458203],
            [55.125827121492847, 41.65193604378193],
            [55.146663359378834, 41.6249910748448],
            [55.193882308135386, 41.562764160772403],
            [55.207218251400974, 41.543609165030134],
            [55.227763301969702, 41.505553983402976],
            [55.239991157632318, 41.488745082365867],
            [55.289163257472381, 41.439709104633963],
            [55.316663276039407, 41.423882227375671],
            [55.355545080960297, 41.408327091419224],
            [55.39249116915633, 41.388882081826225],
            [55.407491255482881, 41.370691172589872],
            [55.414718132421029, 41.351654026406592],
            [55.436100199843253, 41.308318078125879],
            [55.454782288607049, 41.288673243960702],
            [55.528882168560386, 41.263882088869522],
            [55.65249126850054, 41.267209033887113],
            [55.805827123411319, 41.288744993052077],
            [55.833882191608353, 41.300000045029421],
            [55.865273257278403, 41.320973243213743],
            [55.894436077802112, 41.331518012713886],
            [55.933600185222673, 41.333600077468631],
            [56.000963361936925, 41.328454091818585],
            [56.015000199948048, 41.325553953311911],
            [56.215000289261837, 41.315273213762268],
            [56.360273256209638, 41.306382193763099],
            [56.523609224551109, 41.296945008951539],
            [56.533754177268833, 41.298291142605237],
            [56.542773272748803, 41.296109165564602],
            [56.644154068319693, 41.289164255850082],
            [56.666936081214175, 41.288045104134952],
            [56.69720916863551, 41.287218145565433],
            [56.748054128716063, 41.292500085685205],
            [56.79833616017757, 41.298882234418855],
            [56.835545104857914, 41.298609152012673],
            [56.885273260156197, 41.288327071358665],
            [56.922082220415945, 41.276936064911311],
            [56.956100174548482, 41.269991155196905],
            [57.037909226150049, 41.259445044592084],
            [57.057073274347744, 41.26790909044233],
            [57.13388218541499, 41.345827094940816],
            [57.115963352756438, 41.390827018644686],
            [57.089436137874742, 41.406800076294573],
            [57.057073274347744, 41.442764140711759],
            [57.041100216697856, 41.471791174403634],
            [56.977482241970705, 41.681936048797226],
            [56.969709200220194, 41.86013598135726],
            [56.982627221792285, 41.889018008123642],
            [57.004436095725367, 41.904018094450279],
            [57.029163380714266, 41.910264121076054],
            [57.067354181535364, 41.918464136975516],
            [57.097218232080564, 41.927627065914393],
            [57.122073257274309, 41.943327041158184],
            [57.142491238190615, 41.961109081156522],
            [57.167909192003123, 41.990273242784596],
            [57.190336147478121, 42.032909134510177],
            [57.21110918581391, 42.063609028442542],
            [57.245827196501665, 42.095273176518688],
            [57.264163280301347, 42.109718045577537],
            [57.287491290370241, 42.123045104025536],
            [57.323609246530253, 42.13860007234392],
            [57.352218190890625, 42.149718164023042],
            [57.378191194332913, 42.159291135666436],
            [57.417500141040676, 42.171109116262684],
            [57.44499127479034, 42.173882185116966],
            [57.473873301556893, 42.175273245771919],
            [57.497773292700032, 42.173327135487156],
            [57.522218107551197, 42.17027310523757],
            [57.579991213539557, 42.163045054833063],
            [57.620127118817067, 42.154154034833894],
            [57.664436206059094, 42.153600158670557],
            [57.838600252228986, 42.187764125556882],
            [57.872763380924908, 42.210818047391101],
            [57.9083271256454, 42.26527309317926],
            [57.95416322801222, 42.342218126354496],
            [57.961654134901124, 42.370473186761799],
            [57.948045109229412, 42.399164106135331],
            [57.943600185962993, 42.445545032210148],
            [58.021500253188748, 42.501345038185619],
            [58.053609145048739, 42.508045028688556],
            [58.076382273125745, 42.507500204980616],
            [58.101663266640088, 42.503882072645823],
            [58.251391215138057, 42.471373196365121],
            [58.283336156971444, 42.462773196045063],
            [58.325000250840191, 42.446936092864988],
            [58.371245222445083, 42.425136103749324],
            [58.404363295450224, 42.399018093381628],
            [58.416236093693357, 42.358609105697994],
            [58.408809224544967, 42.30634508940598],
            [58.44165404750575, 42.294718048564661],
            [58.50676333045007, 42.302527132498923],
            [58.34276334773719, 42.521382145420532],
            [58.312909187837732, 42.550136096706225],
            [58.29319126347653, 42.561100129004544],
            [58.254718160155761, 42.572773102675299],
            [58.229154193589494, 42.578609086596501],
            [58.199436155797997, 42.588327065165387],
            [58.15179123872079, 42.611173115800327],
            [58.152973254709906, 42.64241816871656],
            [58.266663378042523, 42.694018170637193],
            [58.302082283475698, 42.696518157085265],
            [58.333600251160163, 42.688600108409432],
            [58.367209168416679, 42.674436033107909],
            [58.405754188467, 42.663318109066765],
            [58.461109283020704, 42.659153979557559],
            [58.52166333685247, 42.657773144824588],
            [58.556518140200438, 42.660964135372396],
            [58.57332720887544, 42.673327107314677],
            [58.582500196098209, 42.695545018123838],
            [58.586236177991935, 42.716382094200085],
            [58.591663292675179, 42.759718042480799],
            [58.596518258645943, 42.780827027496755],
            [58.613536204348719, 42.796173118787451],
            [58.730545226071882, 42.703882161959697],
            [58.775754194441362, 42.662909072190658],
            [58.804573189295837, 42.637627072848076],
            [58.850273337192817, 42.604164168345349],
            [58.951663352857196, 42.54083603983095],
            [58.978045225537556, 42.532354056707888],
            [59.023882333732814, 42.525273192523557],
            [59.047218222790747, 42.523882131868717],
            [59.068054125400494, 42.525553986280599],
            [59.09331818747026, 42.531382091212848],
            [59.1433911742661, 42.54443606725475],
            [59.216100161202661, 42.49735407879642],
            [59.2394362178986, 42.476653963018506],
            [59.259991159113127, 42.446800138395176],
            [59.259718244345009, 42.422082073499652],
            [59.259027240245274, 42.394853963872421],
            [59.265827143034386, 42.367491073241681],
            [59.279300214236201, 42.351109146353664],
            [59.425554199134439, 42.299718021460919],
            [59.449018163673173, 42.293882205177724],
            [59.487209132132335, 42.291664185953167],
            [59.529991204249626, 42.293609122771429],
            [59.558891168289023, 42.299436054237205],
            [59.622218123337149, 42.308882123866184],
            [59.735827109846667, 42.322491149537981],
            [59.857500157785893, 42.316100115986998],
            [59.914227202255148, 42.298464256380342],
            [60.014445092576153, 42.217491215803804],
            [60.026654172775608, 42.163609156918469],
            [60.028045065792497, 42.03249121281668],
            [60.137218181491193, 41.911100132101083],
            [60.149154179284466, 41.891518162209891],
            [60.152763259163834, 41.883873195940154],
            [60.180409290484562, 41.860545018233367],
            [60.253045187225297, 41.824227069862914],
            [60.274154172241339, 41.791036074299981],
            [60.259300266306496, 41.774091218793259],
            [60.225682296594556, 41.780691129371931],
            [60.19331825960117, 41.795000043960769],
            [60.143327247818576, 41.806245037654307],
            [60.116800200574943, 41.802773253349287],
            [60.094436109373902, 41.787354071862737],
            [60.071318149799225, 41.759164222662363],
            [60.075827110805932, 41.730545052380208],
            [60.13610925569796, 41.659718137987952],
            [60.160682313667991, 41.631800197727401],
            [60.18394511253004, 41.590544973096755],
            [60.160691198485409, 41.57360917004543],
            [60.121373199322107, 41.557627059939946],
            [60.097354185153478, 41.530754007732284],
            [60.077773221090666, 41.445473171825668],
            [60.092145167591553, 41.410753987671441],
            [60.14027321756916, 41.38110015525838],
            [60.159718227162074, 41.372773237344617],
            [60.194154102988222, 41.359436120612528],
            [60.222763382624663, 41.349164265880503],
            [60.264573321608935, 41.338745057290097],
            [60.325827096719735, 41.314436029270766],
            [60.344300140817694, 41.305409054801785],
            [60.374436100302802, 41.287773195195243],
            [60.408882202050762, 41.263053956833502],
            [60.432491173514876, 41.24221805422367],
            [60.453745165456183, 41.229018065427951],
            [60.47999125130471, 41.22110018439021],
            [60.518118182023528, 41.21762705898071],
            [60.593882205038057, 41.231654006345948],
            [60.656382201516379, 41.245827134103067],
            [60.679991172980493, 41.25277321728386],
            [60.70499120509956, 41.258045099119826],
            [60.811936243289978, 41.257773190180032],
            [60.939991272324761, 41.250973119752828],
            [60.962773285219185, 41.245827134103067],
            [60.981800205480653, 41.234436127655712],
            [61.004200171227382, 41.209873127969487],
            [61.022491160387887, 41.230273171612893],
            [61.085827167891438, 41.237209028872016],
            [61.138954184936694, 41.232209055975758],
            [61.161518268348033, 41.206100098063658],
            [61.245273262596271, 41.167354079974686],
            [61.289991219076313, 41.162900104252842],
            [61.329300165784133, 41.173045056970452],
            [61.361936111717313, 41.192909161723364],
            [61.379709266898175, 41.214018146739491],
            [61.394854192512099, 41.26790909044233],
            [61.414845199279426, 41.298809144223029],
            [61.446382277703293, 41.30235401872379],
            [61.611382221467721, 41.267491168748805],
            [61.632354078547365, 41.259300205304768],
            [61.737773275079434, 41.202773153045953],
            [61.87416326865511, 41.125544979180617],
            [61.893327149214798, 41.111664212207174],
            [61.947909264655522, 41.044300197302434],
            [61.970545097158237, 41.008609047653366],
            [61.984154122829949, 40.971235985308326],
            [61.987500178586942, 40.945827083951244],
            [61.985336138819122, 40.921244973525816],
            [61.986382200338284, 40.877627058021389],
            [61.999300221910374, 40.839973201919307],
            [62.044718234945577, 40.719154102277955],
            [62.112500171543815, 40.60665404156164],
            [62.141109283542193, 40.564436071529556],
            [62.185527167887841, 40.504209079560567],
            [62.192491188341563, 40.4936091571373],
            [62.216663256062503, 40.480264161416287],
            [62.259227231058645, 40.473391168431235],
            [62.336109232321832, 40.439436078572655],
            [62.352636166135056, 40.424573120182387],
            [62.398882311206449, 40.278045047049645],
            [62.410818141361602, 40.220273114527501],
            [62.425136108405951, 40.128327156835482],
            [62.418327153161357, 40.107491086587558],
            [62.421800278570828, 40.082354094170299],
            [62.441209245980104, 40.032336092659463],
            [62.555409156665206, 39.930545086746079],
            [62.584436190357081, 39.909709184136247],
            [62.72720917085681, 39.82943603247692],
            [62.789436084928951, 39.799718162323401],
            [62.813473204008545, 39.787491144851131],
            [62.879718234836133, 39.748882087060267],
            [62.901654178421751, 39.735827105190069],
            [62.955273213184881, 39.697773097029213],
            [63.000000222120519, 39.666936075160478],
            [63.038609279911185, 39.644436029489597],
            [63.09331812972826, 39.617209093328754],
            [63.130273270379774, 39.599164196846104],
            [63.199154074487382, 39.566664205382821],
            [63.28081811916357, 39.52832705653195],
            [63.342491324710437, 39.498327051516739],
            [63.478600189252887, 39.423327122797858],
            [63.518609192515868, 39.398609057902419],
            [63.56138221217779, 39.364436038560783],
            [63.595827140459249, 39.329718027873028],
            [63.643609185472798, 39.279164255109762],
            [63.657209158689085, 39.263327151929687],
            [63.693882332116999, 39.224164217975598],
            [63.710409265930224, 39.207764186176675],
            [63.813882184539551, 39.153600160067541],
            [63.893054121756705, 39.113882176483642],
            [63.983327219208405, 39.068053953105775],
            [64.043872220584745, 39.029718145359297],
            [64.086382216124235, 39.000691111667422],
            [64.132472290157949, 38.975545066794851],
            [64.177900193839008, 38.953745077678988],
            [64.205672288983948, 38.952073223267107],
            [64.23441819364254, 38.959573182611351],
            [64.266936122378667, 38.972764118951446],
            [64.302900186795938, 38.985409058117241],
            [64.338118261828555, 38.990754030149063],
            [64.366927198399168, 38.971518065222014],
            [64.38302715806347, 38.953126996137129],
            [64.388600285500416, 38.94554506177964],
            [64.401927176310522, 38.929436049659742],
            [64.416382271291155, 38.913882087169767],
            [64.434836204649827, 38.896936058196459],
            [64.464709140012559, 38.873882136362155],
            [64.631654188233227, 38.761382075645926],
            [64.671518184570942, 38.739018152082949],
            [64.746645182942331, 38.708327142968002],
            [64.798036140197183, 38.689991059168264],
            [64.874982179200714, 38.666100120480436],
            [64.907627177589319, 38.65680006360536],
            [64.935254098170873, 38.648882182567505],
            [64.976382253149012, 38.631935985956105],
            [64.995263328294669, 38.621654072940188],
            [65.106645075486284, 38.548045037238055],
            [65.123872233492904, 38.533336138228705],
            [65.151654051645465, 38.511936133533638],
            [65.198863277394452, 38.476935987984106],
            [65.256654153017649, 38.434718017952022],
            [65.292209180558785, 38.410818026808883],
            [65.328182297431596, 38.389854048718334],
            [65.564418192464387, 38.268327013532755],
            [65.597209203606695, 38.253882144474105],
            [65.627763252423307, 38.243609116275493],
            [65.657763257438518, 38.236382071699367],
            [65.695527251748757, 38.228045095501713],
            [65.716382265097906, 38.225818023821759],
            [65.739154052070404, 38.225818023821759],
            [65.776927098836069, 38.23443612905281],
            [65.805054083762371, 38.25742701897498],
            [65.840272326433166, 38.273609121290704],
            [65.878245197771065, 38.282009129400407],
            [66.055818163971736, 38.241100077371911],
            [66.078327262098213, 38.233191081151588],
            [66.26560915441226, 38.147354022148775],
            [66.285954212770633, 38.12221803556001],
            [66.306363308869635, 38.095682103498788],
            [66.326100176331948, 38.081800163058958],
            [66.423872227299597, 38.043609026961619],
            [66.451436115969017, 38.037427038076387],
            [66.492200161072191, 38.042082179474946],
            [66.52053619066433, 38.043327059738104],
            [66.541363376094836, 38.040836125745429],
            [66.570263340134119, 38.035000141824142],
            [66.600263345149273, 38.024436093946534],
            [66.620254184278593, 38.015545073947365],
            [66.651372335180525, 37.996518153685869],
            [66.666091292473851, 37.981636084556314],
            [66.670882220704272, 37.964644960943971],
            [66.659982226146298, 37.927073247493055],
            [66.637763309508813, 37.904853995579302],
            [66.616091228235831, 37.884718149524687],
            [66.554282235857073, 37.818191151473357],
            [66.539836193332036, 37.780827141583913],
            [66.516936163240445, 37.531936081463257],
            [66.519145130009491, 37.390264147767837],
            [66.537736191304447, 37.366382093897428],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "TLS", Country: "Timor-Leste" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [124.468968477500198, -9.183027424249872],
              [124.468812532171683, -9.184414237788303],
              [124.462809259426194, -9.184409025019761],
              [124.449300324146407, -9.282655080304721],
              [124.368500286051813, -9.428964050487934],
              [124.34040029085395, -9.461664034161458],
              [124.293400277408864, -9.46362690825265],
              [124.262300231417726, -9.427664017301765],
              [124.249400314756826, -9.404364002789578],
              [124.200100307073853, -9.399035794564099],
              [124.176100236006619, -9.418646095288182],
              [124.118800204634681, -9.432281943050157],
              [124.066600226083096, -9.401599986390693],
              [124.046100052102247, -9.339999536359755],
              [124.062076319098537, -9.334824838008444],
              [124.061282364000135, -9.332650944999898],
              [124.059440053000344, -9.327700616999834],
              [124.054699552000443, -9.314962788999821],
              [124.036857536000184, -9.277277250999816],
              [124.021840482500011, -9.245558538499836],
              [124.018718427000294, -9.228972226999872],
              [123.999561224000331, -9.109961355999815],
              [123.995943614000339, -9.087487569999837],
              [123.995122020000395, -9.085712913999885],
              [123.941526062000321, -8.966261932999771],
              [123.929083686500235, -8.938531125499878],
              [123.970426850000422, -8.931325546999858],
              [124.069529208000176, -8.914053288999924],
              [124.113759567000443, -8.904704421999838],
              [124.129023739000218, -8.901478069999882],
              [124.213865758000338, -8.877548311999817],
              [124.27670049000028, -8.859825725999826],
              [124.334945990000278, -8.837045350999801],
              [124.403296458000227, -8.822198288999857],
              [124.428574760000288, -8.819653118999895],
              [124.438675646000206, -8.818636100999925],
              [124.441008418000138, -8.823891569999887],
              [124.451693865000152, -8.847964663999903],
              [124.466792122000129, -8.881145398999934],
              [124.473059396000167, -8.894918694999888],
              [124.482497667500297, -8.915815860999828],
              [124.498325833000422, -8.950860819999832],
              [124.50129811800025, -8.957516293999845],
              [124.506074240000316, -8.968210882999813],
              [124.485499333000348, -9.086674225999843],
              [124.479375779000293, -9.120716349999896],
              [124.475501740000254, -9.142252944999825],
              [124.471268224000141, -9.160040766999884],
              [124.470412583000325, -9.163635882999984],
              [124.468968477500198, -9.183027424249872],
            ],
          ],
          [
            [
              [125.784323837000215, -8.161698191999903],
              [125.845319366000126, -8.247646475999829],
              [125.981173085000137, -8.231011318999833],
              [126.08098396000014, -8.211603662999892],
              [126.178022304000251, -8.194968506999885],
              [126.313876023000148, -8.194968506999885],
              [126.395090047000394, -8.196857209999848],
              [126.43309458500039, -8.19774103799989],
              [126.483785219000424, -8.196835845999829],
              [126.588355960000285, -8.194968506999885],
              [126.760252461000391, -8.158925693999834],
              [126.876698528000247, -8.122882866499822],
              [127.05691258600018, -8.20328606899983],
              [127.156589289000294, -8.217755265999756],
              [127.22880911700031, -8.228238787999885],
              [127.392388055000197, -8.231011318999833],
              [127.48665389900043, -8.341912287999889],
              [127.586464774000433, -8.474993475999895],
              [127.819356837000328, -8.793833788999905],
              [127.882604455000319, -9.072014940999907],
              [127.966580208666869, -9.441217795333216],
              [127.979104145000179, -9.49644822800002],
              [127.97966624150024, -9.499084371999913],
              [127.936116667000192, -9.468616662999978],
              [127.933333478500117, -9.466666763999854],
              [127.811666668000271, -9.38138888799989],
              [126.006944537000209, -10.111111070999812],
              [126.004913030000239, -10.215119948999813],
              [126.000128141000346, -10.460105412999894],
              [126.000016073000097, -10.465833816999918],
              [125.999344913857271, -10.466151765428435],
              [125.092271745331345, -9.459827455378615],
              [125.108036320419956, -9.448336077123372],
              [125.12469109106712, -9.437499926714679],
              [125.129418176415925, -9.435290890440342],
              [125.062300253396984, -9.367772972013029],
              [125.019900228427446, -9.336226841133737],
              [124.971000205165524, -9.274390859026767],
              [124.966300304403603, -9.22130893662063],
              [124.986800260333212, -9.194363967683387],
              [125.019700236217091, -9.167855025350491],
              [125.069100323824159, -9.167172906068117],
              [125.140000328412498, -9.150399879576923],
              [125.165900241658733, -9.109208860324827],
              [125.159700315500572, -9.068099983724153],
              [125.139900248488289, -9.024117958344846],
              [125.093300219463913, -9.011673011389405],
              [125.018500282955188, -9.034317896347488],
              [124.965300343352055, -9.03727302013931],
              [124.946300245180964, -9.008946043002652],
              [124.951900113563966, -8.950126872663247],
              [124.967853592749861, -8.936942649926209],
              [124.964807751333524, -8.932980020333218],
              [124.923917053000196, -8.879782069999919],
              [124.878827811000406, -8.821810171999914],
              [124.872163271000318, -8.81324147599986],
              [124.8351962710002, -8.757790975999853],
              [124.820409459000302, -8.709733881999853],
              [124.84628636500031, -8.676463600999909],
              [124.890646771000206, -8.628406506999909],
              [124.953490646000319, -8.576652725999907],
              [124.988030027000377, -8.554303706999917],
              [125.01633455300032, -8.535989006999785],
              [125.134628928000438, -8.487931912999898],
              [125.167795445000252, -8.473717693999845],
              [125.238136490000329, -8.443571537999901],
              [125.279535833000295, -8.424941826999884],
              [125.3120705150003, -8.410301063499958],
              [125.314775490000443, -8.392448236999883],
              [125.33055399000034, -8.288310162999835],
              [125.335739396000122, -8.244234245999849],
              [125.337947397000335, -8.22546625699988],
              [125.335152948000314, -8.187741243999824],
              [125.33055399000034, -8.125655381999877],
              [125.33055399000034, -8.062811506999878],
              [125.33920514900035, -8.035127803999842],
              [125.349037491000217, -8.003664318999881],
              [125.364506503000143, -7.992062547999922],
              [125.37861108400034, -7.981484099999818],
              [125.433571852000341, -7.975988039999834],
              [125.452545053000335, -7.974090724999883],
              [125.48326146300019, -7.981071726999915],
              [125.533872459000236, -7.992574224999885],
              [125.607806428000231, -8.03323791299988],
              [125.698375585000122, -8.103475193999827],
              [125.784323837000215, -8.161698191999903],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "TON", Country: "Tonga" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-171.84555566, -15.836666663000017],
            [-171.83972266, -15.846666663],
            [-171.76833366, -15.972222662999968],
            [-171.710277659999974, -16.079722662999984],
            [-171.628055659999973, -16.224722663],
            [-171.284166659999983, -16.825833664000015],
            [-171.445075660000043, -17.034539664000022],
            [-171.54118666, -17.145749663999965],
            [-171.54353566, -17.148469663999947],
            [-171.711473659999967, -17.342977664],
            [-171.76605665999989, -17.405814663999934],
            [-171.78435066, -17.575956663999918],
            [-171.79615666, -17.697351664999957],
            [-171.803214659999924, -17.763481665000043],
            [-171.83307166000003, -18.039614664999974],
            [-171.848109660000034, -18.179555665],
            [-171.86182766, -18.305598664999948],
            [-171.86485666, -18.333824665],
            [-171.90055066, -18.618197664999954],
            [-171.944772659999899, -18.969934666000043],
            [-171.954159659999959, -19.045288666000019],
            [-171.956643659999969, -19.065413665999927],
            [-171.97354965999989, -19.201010665999974],
            [-171.983585660000017, -19.290672665999963],
            [-171.985187659999951, -19.305529666],
            [-171.986940659999959, -19.325884666],
            [-171.997829660000036, -19.916573666999952],
            [-172.00253766000003, -20.053361666999962],
            [-171.956874659999983, -20.365883667],
            [-171.87025166, -20.90118166799995],
            [-171.56484466, -21.866545668000029],
            [-171.524326660000042, -21.941586667999957],
            [-171.47620766, -22.030780669000023],
            [-171.408884659999984, -22.157162668999945],
            [-171.51131966, -22.54746266899997],
            [-171.763358659999938, -23.079262669999963],
            [-171.763833659999989, -23.079919669999967],
            [-171.764338659999851, -23.08098067],
            [-172.114978659999849, -23.563796669999974],
            [-172.11640166, -23.56515867],
            [-172.116964659999951, -23.565929669999989],
            [-172.556212660999961, -23.984514669999967],
            [-173.070883661000039, -24.325501671000012],
            [-173.416570662000026, -24.47781467099999],
            [-173.857621661999985, -24.888437670999963],
            [-174.37930516199998, -25.225651171999985],
            [-174.381141662000033, -25.226791671999933],
            [-174.961733662999961, -25.474770672],
            [-175.582841663999972, -25.62552267199996],
            [-176.224266664000027, -25.674114671999973],
            [-176.864985665000034, -25.618957671999979],
            [-176.871928664999928, -25.617247672],
            [-177.491157664999974, -25.460120671999945],
            [-178.068354666000033, -25.206251671999979],
            [-178.585324666, -24.863900670999953],
            [-178.590158665999923, -24.859315670999933],
            [-178.595359666000036, -24.85585367100002],
            [-179.071111111, -24.368888888999962],
            [-178.630648792999978, -23.610233408],
            [-178.519362649999948, -23.418553349],
            [-177.903333332999978, -22.3575],
            [-177.767388735000026, -22.135250324999959],
            [-177.300833333000043, -21.3725],
            [-177.20780465699994, -21.217912743999989],
            [-177.189030048999967, -21.186714674999962],
            [-177.1325, -21.092777778000041],
            [-177.078920730999869, -21.022917514999946],
            [-177.044847128999947, -20.978490056],
            [-177.031944443999976, -20.961666666999974],
            [-177.024166666999918, -20.91],
            [-176.883333332999968, -20.435277777999971],
            [-176.666944444000023, -19.341944444],
            [-176.669444444000021, -19.2525],
            [-176.634684429999879, -19.052282314000024],
            [-176.627777777999967, -19.0125],
            [-176.624004245000037, -18.998438624],
            [-176.601388888999963, -18.914166666999947],
            [-176.592298253, -18.890231828999944],
            [-176.579444443999961, -18.856388889000016],
            [-176.450555556000012, -18.588055555999986],
            [-176.433320433000034, -18.476310226999985],
            [-176.427122832999885, -18.436127574999972],
            [-176.3575, -17.984722221999945],
            [-176.277777777999972, -17.68027777799999],
            [-176.275819447999964, -17.589582610999969],
            [-176.274407433000022, -17.59266217000004],
            [-176.275784562000013, -17.587966954999956],
            [-176.275088662999963, -17.55573814],
            [-176.272425219000041, -17.558818481999964],
            [-176.275054563999902, -17.554158945],
            [-176.273333333000039, -17.474444444],
            [-176.889722222000017, -16.835],
            [-176.891772012999979, -16.832786226],
            [-176.896666666999948, -16.8275],
            [-177.375277778, -15.985555556],
            [-177.375554327999964, -15.971420118],
            [-177.376235, -15.93662852],
            [-177.323963825000021, -15.832081562999988],
            [-177.220593060999988, -15.648176142999944],
            [-177.201615618, -15.61441365],
            [-177.189441516999977, -15.593673749999937],
            [-176.979066263999982, -15.233467333999954],
            [-176.973905275, -15.224633611999934],
            [-176.947546714, -15.179209168],
            [-176.916658805999873, -15.125979175999944],
            [-176.870943169999919, -15.046472749000017],
            [-176.808186231000036, -14.93732891099998],
            [-176.783026394000018, -14.893350730999984],
            [-176.769279896999933, -14.869322516999972],
            [-176.642178818999923, -14.646062698999984],
            [-176.384913672999971, -14.586353158],
            [-176.36227067400003, -14.581025938000025],
            [-176.215421575499988, -14.546050635499967],
            [-176.170765247, -14.535413470999984],
            [-176.082782740499908, -14.516068675999975],
            [-176.047642386999883, -14.508151496000025],
            [-176.039427187999877, -14.506272205999963],
            [-176.002081478000036, -14.497729086],
            [-175.85923219599988, -14.464639065000028],
            [-175.81021454, -14.453284472999968],
            [-175.628483066, -14.410539516999961],
            [-175.600900909000018, -14.404000688999929],
            [-175.562887242999864, -14.394988891],
            [-175.298387351000031, -14.33170119499998],
            [-175.071868221000017, -14.277501271999967],
            [-175.067239386000011, -14.27637924],
            [-175.065335716999869, -14.275917789999951],
            [-174.984545587999975, -14.256224681],
            [-174.966941835999961, -14.251916149999929],
            [-174.868191862999964, -14.22775607200002],
            [-174.782391504000032, -14.206764234],
            [-174.684434217999979, -14.182778737999968],
            [-174.626535248999971, -14.168601788999979],
            [-174.570091808999848, -14.15345308299996],
            [-174.532155416, -14.143271433999971],
            [-174.442478662000042, -14.25943866099999],
            [-174.110799661999977, -14.379271661000018],
            [-173.956658662000024, -14.436812662],
            [-173.763338662000024, -14.50890766199997],
            [-173.707569661999941, -14.529599661999981],
            [-173.652719661999868, -14.550575661999957],
            [-173.466896661999982, -14.645182662],
            [-173.395191661000013, -14.682777662000035],
            [-172.942550660999984, -14.922061662000019],
            [-172.907155660999877, -14.940807661999955],
            [-172.826372660999937, -14.983233662000032],
            [-172.734123660999956, -15.031366661999982],
            [-172.640544660999979, -15.079925661999923],
            [-172.539516661, -15.169437662],
            [-172.487615661000035, -15.215307661999944],
            [-172.458507661, -15.241100661999951],
            [-172.427259661000022, -15.268821661999965],
            [-172.287005660000034, -15.393382661999951],
            [-171.945218659999853, -15.728741663],
            [-171.84555566, -15.836666663000017],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "TTO", Country: "Trinidad & Tobago" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-57.286916251999855, 10.683777465000063],
            [-57.309874260999862, 10.674757307000164],
            [-57.759170099999977, 10.498229830000128],
            [-57.814702555999929, 10.479145556000091],
            [-57.873431466999961, 10.459153167000053],
            [-57.936380256999911, 10.437724254],
            [-57.963534491999837, 10.428524052000029],
            [-57.994334470999974, 10.418088627000088],
            [-58.096607221999932, 10.383336056000033],
            [-58.129787935999957, 10.372061155],
            [-58.163994606999864, 10.36055775800007],
            [-58.26503543799987, 10.326578631000061],
            [-58.328847084999893, 10.307388296000042],
            [-58.374841132999876, 10.293556319000174],
            [-58.502693674999989, 10.258994927000131],
            [-58.753615970999846, 10.192231009000082],
            [-58.801063746999858, 10.179606387000106],
            [-58.829328111999985, 10.172124731000039],
            [-58.887168687999974, 10.15681417100005],
            [-58.902283962999974, 10.152885942000012],
            [-58.954945716, 10.142316940000029],
            [-59.922499555999877, 9.969722361000066],
            [-59.987777555999912, 9.954722361000094],
            [-60.267222555999865, 9.890555361000168],
            [-60.664166555999827, 9.831944361000126],
            [-60.890833556999837, 9.848611361000167],
            [-61.223333556999961, 9.875833361000105],
            [-61.499999557000024, 9.970000361000061],
            [-61.63055555699998, 9.986666361000204],
            [-61.854999558, 9.986666361000204],
            [-61.973611557999988, 10.00805536100016],
            [-62.083055557999899, 10.046111361000158],
            [-61.862499557999882, 10.588611362000137],
            [-61.80277755799986, 10.588889362000216],
            [-61.80277755799986, 10.714444362000194],
            [-61.771388558000012, 10.793889362000272],
            [-61.763055557999849, 10.811389362000142],
            [-61.731111556999934, 10.904166362000197],
            [-61.729444556999937, 10.911111362000085],
            [-61.729444556999937, 11.175000362000091],
            [-61.72914855699986, 11.360453363000019],
            [-61.70209955699994, 11.363665363000038],
            [-61.689339556999869, 11.365203863000062],
            [-61.661975556999892, 11.370623363000121],
            [-61.612936556999955, 11.380232363000076],
            [-61.54593455700001, 11.399752363000104],
            [-61.422685556999966, 11.434472363000125],
            [-61.388930557000037, 11.469069363000187],
            [-61.370853557000032, 11.486129362999975],
            [-61.264899557000035, 11.584059363000065],
            [-61.220886557, 11.624805363000149],
            [-61.205726556999934, 11.638175363000158],
            [-61.131381556999855, 11.71262236300015],
            [-61.108128557000043, 11.737532363000014],
            [-61.095834556999989, 11.752132363000101],
            [-61.092200556999956, 11.756403363000075],
            [-61.085507556999943, 11.764319363000112],
            [-61.08430755699996, 11.765910363000032],
            [-61.079837556999848, 11.771728363000022],
            [-61.078844557000025, 11.773011363000037],
            [-61.045258556999954, 11.815223363],
            [-61.013151556999958, 11.855732363000016],
            [-60.843837557, 12.024282363000083],
            [-60.82328555700002, 12.0365853630001],
            [-60.765619557, 12.063203363000156],
            [-60.346660555999989, 12.298193363000109],
            [-60.288264555999973, 12.331427363000103],
            [-59.966602556, 12.170582363000108],
            [-59.946817555999871, 12.160484363],
            [-59.937607555999989, 12.155358363000076],
            [-59.639747556000032, 11.988688363000136],
            [-59.445723554999972, 11.879732363000173],
            [-59.353079555, 11.82748036300012],
            [-59.149277555000026, 11.711725363],
            [-59.087565554999912, 11.678553363000077],
            [-58.922482554999817, 11.589491363000093],
            [-58.671085554999934, 11.452186363000024],
            [-57.769490553999901, 10.956662362000102],
            [-57.286916251999855, 10.683777465000063],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "TUN", Country: "Tunisia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.580132434000149, 35.270745170000154],
            [12.125978794000019, 34.174961805000052],
            [12.000000308500148, 33.916668326000021],
            [11.612536926500155, 33.401243490000169],
            [11.58510751100016, 33.364755383000059],
            [11.566442511, 33.298784382999983],
            [11.539969511000123, 33.227321383000159],
            [11.537370535666781, 33.213983507333481],
            [11.536198158000104, 33.210703704000011],
            [11.53124651100012, 33.196851383000066],
            [11.526081874409812, 33.171136194453155],
            [11.525000138522643, 33.146664137964436],
            [11.522173257849801, 33.125918089357057],
            [11.516954014365922, 33.102473235557554],
            [11.504745101804446, 33.074809099325719],
            [11.494027162184182, 33.043473186578794],
            [11.483700154528947, 32.942909123655639],
            [11.47721809350918, 32.863882193363921],
            [11.466663265724947, 32.650973134933466],
            [11.472773170242846, 32.622500145042991],
            [11.494445083877622, 32.598882121123566],
            [11.516945129548589, 32.581245088050551],
            [11.536945021133306, 32.567500107908828],
            [11.558327088555558, 32.545900111003519],
            [11.580000175656807, 32.503054001145685],
            [11.581663312889532, 32.470827092088697],
            [11.567500243416333, 32.442218147728411],
            [11.550973141964931, 32.428326987195007],
            [11.515882136584793, 32.409400146857692],
            [11.483327159836563, 32.390000064265948],
            [11.414718097030772, 32.353053976069916],
            [11.325836060233883, 32.3194450588134],
            [11.181927164212595, 32.261664241473923],
            [11.144163337540448, 32.246382187923743],
            [11.029163290375948, 32.199718121158853],
            [10.878536131302639, 32.13228218952473],
            [10.859718088069116, 32.092500168200289],
            [10.830554094078934, 32.041382125713639],
            [10.814027160265624, 32.025000031187645],
            [10.710345029352425, 31.964444971527413],
            [10.616109135706409, 31.861244967686375],
            [10.530836178788917, 31.761391187240221],
            [10.498473147623827, 31.73708215922089],
            [10.469445108103656, 31.7212450560409],
            [10.444163276399166, 31.720554051941278],
            [10.416527135724039, 31.72569115277382],
            [10.387636056502174, 31.729718154346656],
            [10.349445088043012, 31.730273203976466],
            [10.314927069565499, 31.719300119222638],
            [10.2872181739707, 31.694164132633873],
            [10.136454054599284, 31.502364226857438],
            [10.123336040816838, 31.422500112074218],
            [10.169845042372629, 31.315318033661399],
            [10.220554047983086, 31.197909027517582],
            [10.264582173830007, 31.076809134119102],
            [10.290691131742108, 30.91041812969975],
            [10.277500195402013, 30.869718122337005],
            [10.223054034431271, 30.750553945663299],
            [10.211945162845524, 30.728473162790507],
            [10.083609172415606, 30.588053988347056],
            [9.982218150922733, 30.461391193433499],
            [9.89229122607324, 30.356318001865461],
            [9.86402711321054, 30.339445063088093],
            [9.840691224152721, 30.340273195124198],
            [9.735136073150727, 30.315953941183082],
            [9.637500144290954, 30.278053992403031],
            [9.537109083849913, 30.234391149897547],
            [9.534163180151609, 30.248336122249512],
            [9.520554154479896, 30.303335991745499],
            [9.468054103793804, 30.510000029378674],
            [9.436391129183846, 30.633891096542342],
            [9.383609111274325, 30.840273166951803],
            [9.334163258475826, 31.032773129283257],
            [9.298609236763042, 31.170554015875766],
            [9.213336112207458, 31.5005540710426],
            [9.181391170373985, 31.624164176811135],
            [9.105836192025379, 31.912773220515604],
            [9.08256333487941, 32.000082141720029],
            [9.057218135986801, 32.095554030811783],
            [9.008336049997524, 32.129436030474508],
            [8.864445091249053, 32.218044984865216],
            [8.688327055805587, 32.326109174770593],
            [8.560000117830896, 32.404164139567555],
            [8.415282200512991, 32.491664168164917],
            [8.350982274141643, 32.531218034084119],
            [8.348609189707958, 32.533335973384496],
            [8.342782258242181, 32.57360917423641],
            [8.323327190365291, 32.71027308840867],
            [8.319445028079798, 32.738327150777209],
            [8.312218151141764, 32.7930539378671],
            [8.306109084814182, 32.834164155572225],
            [8.274163304790392, 32.876664260465915],
            [8.157773202799547, 33.028054005092244],
            [8.078400099905508, 33.110827143199643],
            [8.045418149008441, 33.107635985013857],
            [8.019445145566152, 33.113891064094943],
            [7.762573172495792, 33.211664120890916],
            [7.743054066878557, 33.229164193665738],
            [7.731945027654916, 33.248327068396932],
            [7.72639117859535, 33.286664217247889],
            [7.724163268724965, 33.318600106625965],
            [7.724445068310501, 33.373053978947624],
            [7.724718150716797, 33.399164110326311],
            [7.725027107668723, 33.419400036305106],
            [7.675554097503806, 33.507773123939714],
            [7.557363227429619, 33.676245019647141],
            [7.515000250472212, 33.810553954296736],
            [7.492500204801331, 33.887500160938359],
            [7.492218069939725, 33.919853972009761],
            [7.500282131369289, 33.947491118513184],
            [7.508609216921229, 33.967773144959594],
            [7.518191241020105, 34.004300137995799],
            [7.515263274594702, 34.071918124567546],
            [7.518054113083622, 34.083053985881421],
            [7.528891243367639, 34.104309151289058],
            [7.62597329606379, 34.194573196285475],
            [7.649163340006169, 34.208744982937944],
            [7.681663331469366, 34.219991150097954],
            [7.701663223054084, 34.223882197200865],
            [7.731109184267893, 34.22639106846627],
            [7.759309091751959, 34.233464221299855],
            [7.783745021785791, 34.250618121472442],
            [7.821945042700378, 34.338600109503673],
            [7.824236152120676, 34.380827131991154],
            [7.845136092470966, 34.417918059474587],
            [7.868891244326875, 34.436100083893436],
            [8.036109207315661, 34.509164128249651],
            [8.0702731742021, 34.521945021885259],
            [8.106554074560336, 34.532718114428732],
            [8.12083616705894, 34.537909027079834],
            [8.252709153000808, 34.655209068481966],
            [8.23833603303342, 34.670964196648768],
            [8.227082154522378, 34.693673119347508],
            [8.243609088335774, 34.718609113726032],
            [8.265273290619717, 34.73805395568094],
            [8.270154072852307, 34.74010903070716],
            [8.285482226870187, 34.750691183495789],
            [8.27528228886743, 34.843054057052925],
            [8.286109193229549, 34.965554063561839],
            [8.329509179250635, 35.108673216663718],
            [8.401109240394049, 35.192218160417625],
            [8.34055401309584, 35.290273184437197],
            [8.319300188792482, 35.312773230108277],
            [8.308336156494249, 35.339164155243893],
            [8.302082250879437, 35.373882165931647],
            [8.302773254979172, 35.398891082868019],
            [8.312082196671753, 35.435691158310519],
            [8.32221809693408, 35.457773114649697],
            [8.348891156931586, 35.584444962018765],
            [8.345273192234856, 35.623600184621822],
            [8.344863317168432, 35.644164178291859],
            [8.337918072177843, 35.682427063480574],
            [8.323891124812519, 35.704718064485704],
            [8.298609125469852, 35.725273173338323],
            [8.279163277686735, 35.746526997641595],
            [8.271109106902912, 35.767218060964112],
            [8.262500221765436, 35.816800035870514],
            [8.260554111480701, 35.85638206498443],
            [8.266663177808169, 35.921800137242698],
            [8.280282261763773, 35.961936042520037],
            [8.282973187966718, 35.965309088005355],
            [8.290836083719512, 36.000000108964784],
            [8.305273241427329, 36.097500083354461],
            [8.319300188792482, 36.204991118719406],
            [8.333891238243012, 36.291382221523619],
            [8.371663279180353, 36.357500182698757],
            [8.374445065213962, 36.37499120301807],
            [8.376391175498696, 36.420136133647063],
            [8.370273224353809, 36.452564208380792],
            [8.290691244432196, 36.481727028904587],
            [8.245836160015756, 36.481527036694231],
            [8.215273226381697, 36.483891068672406],
            [8.187291248380802, 36.495545099242136],
            [8.181663303296887, 36.505273136094743],
            [8.186245019223435, 36.527291054693578],
            [8.207636139101169, 36.556109043719701],
            [8.241109101887787, 36.5694361021677],
            [8.34971811550119, 36.604018158385642],
            [8.450000211200745, 36.660827010229994],
            [8.47569124741932, 36.720273144097078],
            [8.476391136336559, 36.743609033154897],
            [8.638054076799477, 36.831109061752258],
            [8.63221809287819, 36.881944969377216],
            [8.622027480180947, 36.94136378435492],
            [8.633162508000112, 36.969690385999954],
            [8.633054508000072, 36.972104386000026],
            [8.626400508000188, 37.013154386000039],
            [8.608522508000021, 37.061422386000046],
            [8.552171508000015, 37.209415387],
            [8.522390508000086, 37.285194386999976],
            [8.439858508000128, 37.309275387],
            [8.188369508000164, 37.494278387],
            [8.054290508000122, 37.5918703870001],
            [7.971244507000165, 37.699232387000151],
            [7.845657507000112, 37.938804387000161],
            [7.816666667000106, 38.001666666000048],
            [8.151666667000029, 38.069166666000157],
            [8.3675, 38.118888888000058],
            [8.534722222000084, 38.168055555000095],
            [8.802222222000182, 38.21694444300006],
            [9.552222222000012, 38.249999999000025],
            [9.667777778000158, 38.218055555000078],
            [9.900555556000029, 38.234722221000098],
            [10.685277778, 38.401666666],
            [10.868611111000149, 38.051388888000034],
            [11.1675, 37.784444443],
            [11.15, 37.683888888],
            [11.700000000000159, 37.401388888000142],
            [11.751388889000111, 37.367777777000114],
            [11.800555556000148, 37.334999999000033],
            [11.868611111000121, 37.233888887999981],
            [11.935555556, 37.134444443000106],
            [12.0025, 37.052222221000179],
            [12.015770308000185, 37.034269681000168],
            [12.007007552000175, 37.036191632000126],
            [11.990801552000164, 37.038843632000166],
            [11.974413552000101, 37.040638632000096],
            [11.957913552, 37.041566632000141],
            [11.941372552000189, 37.041626632000131],
            [11.924863552000147, 37.04081563199999],
            [11.919270552000114, 37.040342632000019],
            [11.917508552000072, 37.040214632],
            [11.901101552000142, 37.038536632000088],
            [11.884868552000114, 37.035999632000042],
            [11.87036155200019, 37.032966632],
            [11.862953552000079, 37.031286632000089],
            [11.847275552000156, 37.027067632000055],
            [11.831978552000123, 37.022032632],
            [11.828721552000019, 37.020755631999961],
            [11.824525552000125, 37.019373632000011],
            [11.80967555200013, 37.013544632000091],
            [11.795335552000154, 37.006948632000118],
            [11.781566552000072, 36.999611632000025],
            [11.768428552000074, 36.99156763200007],
            [11.755978552000158, 36.982849632000026],
            [11.74426755200011, 36.973496632000021],
            [11.733348552000109, 36.9635476320001],
            [11.723266552000098, 36.953046632000152],
            [11.714064552000082, 36.942037632000094],
            [11.705781552000047, 36.930569132000116],
            [11.705292552000088, 36.929833632000097],
            [11.699891552000139, 36.922160632],
            [11.692565552000076, 36.910281632000178],
            [11.686225552000167, 36.898042632000127],
            [11.680897552000118, 36.885496632000169],
            [11.676603552000188, 36.872698632000137],
            [11.673361552000188, 36.859701631999982],
            [11.67259255200014, 36.855747632000131],
            [11.671813552000174, 36.852187632],
            [11.669637552000154, 36.839048632],
            [11.668536552, 36.825823632],
            [11.668410552000154, 36.816336632000016],
            [11.668320552000182, 36.814460632000149],
            [11.668297552000126, 36.801206632000131],
            [11.66936005200003, 36.787911632000018],
            [11.669932552000148, 36.782400632000119],
            [11.672059552000178, 36.769258632000046],
            [11.675249552000082, 36.75625563200002],
            [11.679487552000154, 36.743448632000067],
            [11.684755552000126, 36.730891632000166],
            [11.691030552000086, 36.718638632000122],
            [11.698284552000047, 36.706742632000115],
            [11.706487552000112, 36.695252632000134],
            [11.715602552000149, 36.684219632000136],
            [11.719739552000135, 36.679859631999975],
            [11.723807552000096, 36.673178631999988],
            [11.732002552000125, 36.66168763200001],
            [11.741108552000128, 36.650651632000134],
            [11.751089552000025, 36.640118632000011],
            [11.751872552000151, 36.639394632000048],
            [11.752474552000137, 36.638549632000135],
            [11.761575552000068, 36.627512632000062],
            [11.771548552000127, 36.616977632000143],
            [11.782352552000191, 36.606989632000179],
            [11.793940552, 36.597592632],
            [11.796169552000151, 36.59600663200014],
            [11.803106552000088, 36.590379632000051],
            [11.81542655200019, 36.581609631999967],
            [11.82842855200002, 36.573508632000099],
            [11.842056551999974, 36.566107632000026],
            [11.856252552000029, 36.559439632000149],
            [11.870956552000109, 36.553533632000082],
            [11.886106552000143, 36.548413632000106],
            [11.901636552000184, 36.544101632000107],
            [11.906025552000159, 36.543046632000127],
            [11.911983552000095, 36.541499632000111],
            [11.92782655200017, 36.538012632000076],
            [11.943917552000158, 36.535366632],
            [11.960188552000034, 36.533573632000142],
            [11.964854552000105, 36.533214632000195],
            [11.974550552000153, 36.532547631999975],
            [11.976542552000126, 36.532374631999957],
            [11.992922552000152, 36.53143863200016],
            [12.009343552000189, 36.531366632000143],
            [12.025735552000128, 36.532159632000131],
            [12.042028552000033, 36.533813632000104],
            [12.05815455200019, 36.536321632000138],
            [12.074044552000061, 36.539673632000117],
            [12.08963055300012, 36.543853632000108],
            [12.104846553000186, 36.548846632000036],
            [12.119627553000157, 36.554628631999989],
            [12.133910553000021, 36.561177632000167],
            [12.143634553000084, 36.566231632000083],
            [12.155336553000012, 36.57250763200004],
            [12.168445553000112, 36.580499632000155],
            [12.18088155300012, 36.589165632000046],
            [12.192591553000085, 36.598467632],
            [12.195652553000144, 36.601239632000031],
            [12.200066553000141, 36.604744632000049],
            [12.203975553000191, 36.608135631999957],
            [12.206867553000109, 36.610545632000068],
            [12.217804553000065, 36.620443632000033],
            [12.227918553000166, 36.630896632000102],
            [12.237165553000182, 36.641858632],
            [12.245505553000129, 36.653284632000108],
            [12.252902553000098, 36.665125632],
            [12.259373553000131, 36.677444132000133],
            [12.261431553000136, 36.681373632000017],
            [12.266851553000151, 36.693890631999963],
            [12.267305281500171, 36.695168985500018],
            [12.350555556000188, 36.583333332000038],
            [12.484444444000133, 36.383333332000049],
            [12.500555556000108, 36.351944442999965],
            [12.534444444000115, 36.250555555000133],
            [12.633611111000107, 36.135277777000155],
            [12.710674571000141, 36.039912982000018],
            [12.703913553, 36.03635363100004],
            [12.690837553000165, 36.028432631000143],
            [12.678429553000171, 36.019832631000085],
            [12.666743553000146, 36.010590631000028],
            [12.655829553000132, 36.0007456310001],
            [12.645735553000179, 35.990342631000075],
            [12.636501553000073, 35.979423630999989],
            [12.628169553000191, 35.968037631],
            [12.620773553000106, 35.956231631],
            [12.614345553, 35.944058631000175],
            [12.608912553000152, 35.93156963100013],
            [12.604496553000075, 35.918817631000096],
            [12.601117553000108, 35.905858631000157],
            [12.598787553000136, 35.892748631000174],
            [12.597516553000133, 35.879541631000038],
            [12.597282553000042, 35.867693631000023],
            [12.597342553000033, 35.863933631000137],
            [12.59737155300013, 35.862535631000114],
            [12.598229553000152, 35.84930763100003],
            [12.600147553000141, 35.836154631000127],
            [12.60311655300012, 35.823130630999955],
            [12.607124553000091, 35.810293631],
            [12.612152553000101, 35.797697631000162],
            [12.618178553000149, 35.785395630999957],
            [12.621056553000074, 35.780222131],
            [12.628143553000086, 35.768099631],
            [12.636082553000136, 35.756542630999988],
            [12.637914553000115, 35.754110631000017],
            [12.639515552999967, 35.751915631000045],
            [12.648361553000029, 35.740806631000154],
            [12.658076553000143, 35.730191631000096],
            [12.668619553000013, 35.720116631000124],
            [12.669811553000159, 35.719072631000174],
            [12.664365553000124, 35.720235631000023],
            [12.648479553000129, 35.722993631000136],
            [12.632406553000095, 35.724896631000021],
            [12.616214553000191, 35.72593663100001],
            [12.610905553000123, 35.726087631000084],
            [12.608209553000165, 35.726140631],
            [12.597228553000122, 35.726159131000045],
            [12.595309553000135, 35.726127631000097],
            [12.585626553000111, 35.725811631000155],
            [12.581094553000014, 35.726520631000014],
            [12.577836553000111, 35.727012631000136],
            [12.56176155300011, 35.728904631000105],
            [12.545567553000012, 35.729933631000151],
            [12.533998553000174, 35.730136630999979],
            [12.524079553000149, 35.730130631000108],
            [12.507860553000114, 35.729421630999965],
            [12.491734553000157, 35.727846631000133],
            [12.475769553000191, 35.725411631],
            [12.460036553000151, 35.722129630999959],
            [12.458746553, 35.72181263100002],
            [12.453970552999976, 35.720816631000034],
            [12.438537553000174, 35.716698631000114],
            [12.423468553000077, 35.71176463099998],
            [12.408830553000143, 35.706035631000177],
            [12.39468555300013, 35.699536631000043],
            [12.383048553000151, 35.693400631000131],
            [12.381819553000071, 35.692712631000049],
            [12.379866553000141, 35.691606631000113],
            [12.366888553000138, 35.683653631000126],
            [12.354578553000067, 35.675024631000085],
            [12.342990553000163, 35.66575563100001],
            [12.332172553, 35.655887631000027],
            [12.328939553000112, 35.652668631000054],
            [12.325785553000088, 35.649653631],
            [12.315786553000123, 35.639227631000026],
            [12.306646553000149, 35.62829063100007],
            [12.298405553000151, 35.616887631000097],
            [12.291097553000156, 35.605069631000148],
            [12.284754553000141, 35.592885631000129],
            [12.279402553000068, 35.580389631000131],
            [12.275063553000166, 35.567634631000104],
            [12.271756553000102, 35.554674631],
            [12.269493553000075, 35.541565631000097],
            [12.26853355300014, 35.532283631],
            [12.268469552999989, 35.531432631000129],
            [12.268222053000045, 35.52751813100015],
            [12.268073553000107, 35.514276631000016],
            [12.268982553000171, 35.501059630999961],
            [12.270947553000127, 35.487920631000051],
            [12.273956553000147, 35.474914631000146],
            [12.277998553000089, 35.462097631000049],
            [12.28305455300017, 35.449523631000105],
            [12.289102553000191, 35.437247631],
            [12.296116553000104, 35.425320631000105],
            [12.304065553000015, 35.413794630999988],
            [12.31291655300015, 35.402717631000073],
            [12.322629553000183, 35.392137631000068],
            [12.333164553000188, 35.382099631000145],
            [12.344475553000109, 35.372646631000137],
            [12.356514553000125, 35.363817631000146],
            [12.369229553000025, 35.355651631000129],
            [12.382566553000061, 35.348182631000029],
            [12.383514553000168, 35.347690631000106],
            [12.385769553000046, 35.346525631000063],
            [12.39872355300011, 35.34027763100012],
            [12.413130553000116, 35.334293631000079],
            [12.428264053000134, 35.329012631000026],
            [12.429461553000181, 35.32861063100016],
            [12.438736553000012, 35.325825631000114],
            [12.443379553000142, 35.324524631000017],
            [12.446714553000021, 35.32354163100014],
            [12.454915553, 35.321287631000061],
            [12.470464553000113, 35.317714631000015],
            [12.47301655300015, 35.317272631000137],
            [12.478142553000168, 35.315814631000123],
            [12.488751552999986, 35.311397631000133],
            [12.503594553000113, 35.306186631000102],
            [12.518819553000128, 35.301779631000144],
            [12.521162553000096, 35.301239631000144],
            [12.530966553000127, 35.298400630999978],
            [12.546508553000081, 35.294815631000105],
            [12.562301553000111, 35.292069631000103],
            [12.578278553000075, 35.290173631000059],
            [12.594372553000142, 35.289135631000065],
            [12.610514553, 35.288961631000163],
            [12.626949053000089, 35.289672631000016],
            [12.63683855300016, 35.289692630999966],
            [12.652960553000156, 35.290377631000169],
            [12.668995553000144, 35.291923631000103],
            [12.684873553000102, 35.294323630999983],
            [12.700527553000086, 35.297567630999978],
            [12.71589055300015, 35.301641631000095],
            [12.730898553000145, 35.306529631000146],
            [12.745486553000035, 35.31220863099999],
            [12.759592553000118, 35.318656631000167],
            [12.766185053000157, 35.322022131],
            [12.773827553000103, 35.326198631000054],
            [12.786792553, 35.33409763100012],
            [12.799101553000156, 35.342673631000125],
            [12.810702553000084, 35.351889631000162],
            [12.821546553000189, 35.361706631000104],
            [12.831585553, 35.372082631],
            [12.840778553000177, 35.382974631000067],
            [12.849083553000156, 35.394334631000035],
            [12.856465553000049, 35.406114631000023],
            [12.86289455300016, 35.418264631000099],
            [12.868339553000112, 35.430733631000166],
            [12.872778553000131, 35.443466631000135],
            [12.876191553000069, 35.456410631000082],
            [12.878563553000134, 35.469509631000037],
            [12.879883553000013, 35.482707631000025],
            [12.880145553000119, 35.495948631000132],
            [12.880097553000041, 35.49780563100002],
            [12.880083553000134, 35.503712631],
            [12.879338553000139, 35.516056631000041],
            [12.879398553000129, 35.519086631],
            [12.878600553000183, 35.532313631000093],
            [12.876744553000094, 35.545469631000074],
            [12.873839553000153, 35.55849863100012],
            [12.869895553000049, 35.571344631000116],
            [12.866127553000126, 35.58115863099998],
            [12.865771553000172, 35.582009631000048],
            [12.864573553000156, 35.584803631000128],
            [12.858607553000184, 35.597118631000157],
            [12.851666553000086, 35.609087631000151],
            [12.843778553000163, 35.620660631000064],
            [12.834977552999987, 35.631786631000139],
            [12.825301553000088, 35.6424176310001],
            [12.814791553000191, 35.652508631000146],
            [12.813066553000112, 35.65395963100012],
            [12.828083553000056, 35.652153631000076],
            [12.844258553000088, 35.65108163100011],
            [12.84948255300003, 35.650920631000119],
            [12.851249553000144, 35.650845631000024],
            [12.863356553000187, 35.650607631000057],
            [12.865602553000031, 35.65050963100002],
            [12.881828553000105, 35.650297630999987],
            [12.898036553, 35.650950631000015],
            [12.914158553000163, 35.652464631000143],
            [12.930125553000124, 35.65483363100013],
            [12.945870553000077, 35.658047631000144],
            [12.961325553000137, 35.662092631000164],
            [12.976425553000013, 35.666952631000115],
            [12.991106553000151, 35.672605631000025],
            [13.005304553, 35.67902863099998],
            [13.016680553000157, 35.684924631000044],
            [13.019043553000188, 35.686239631000134],
            [13.032099553000052, 35.694116631000142],
            [13.044498553000011, 35.702672631],
            [13.056187553000029, 35.711870631000053],
            [13.067117553000145, 35.721671631000092],
            [13.067745553000151, 35.722275131000018],
            [13.074314512000171, 35.729025914000104],
            [13.184722222000175, 35.651666666000111],
            [13.580132434000149, 35.270745170000154],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "TUR", Country: "Turkey" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.151255549000069, 43.434495833000042],
            [36.1825, 43.434444444000164],
            [36.845000000000283, 43.181944444000109],
            [37.953052757000165, 42.68561398000007],
            [38.536111111000054, 42.424444444000201],
            [38.616860476000141, 42.409426886000105],
            [38.973374679571549, 42.343123259285775],
            [39.003611536000079, 42.337500391000034],
            [39.826944537000116, 42.13916639100016],
            [40.433333538000198, 42.031111390999953],
            [40.692499538000135, 41.950000391000046],
            [41.275833538000114, 41.594722391],
            [41.533121056313149, 41.525921520074675],
            [41.531554552112283, 41.523873066036387],
            [41.56915414658576, 41.51804503082586],
            [41.724709194187511, 41.492491122543569],
            [41.759718224554348, 41.468600183855742],
            [41.830127217253192, 41.430127080534973],
            [41.883045189632725, 41.458318103201734],
            [41.910127118868246, 41.488254070476486],
            [41.958463375321372, 41.516800150562901],
            [41.979436070591333, 41.51804503082586],
            [42.097773288695436, 41.50750009368754],
            [42.17470926941516, 41.50750009368754],
            [42.28013617729826, 41.489573214401972],
            [42.311936112206268, 41.476664245285477],
            [42.366936149340432, 41.460273098303887],
            [42.455273194791317, 41.43470913173762],
            [42.472209165480791, 41.433327123538263],
            [42.48332708952168, 41.43832709643452],
            [42.557909264185213, 41.501800064236264],
            [42.590936142083621, 41.578753982228804],
            [42.60416328824553, 41.584718041630893],
            [42.651663366035365, 41.592764165787614],
            [42.681391294472775, 41.594718155061472],
            [42.831018157218267, 41.582418047393148],
            [42.89277333777838, 41.506945044057815],
            [43.016109187674175, 41.393327005092758],
            [43.039718159138289, 41.374164130361279],
            [43.126382176710621, 41.32000010425223],
            [43.23360012966927, 41.190273220805366],
            [43.349709270264981, 41.20193613619243],
            [43.378463221550675, 41.201664227252721],
            [43.429991306741641, 41.183464265560858],
            [43.455482183112082, 41.168045084074308],
            [43.474363258257739, 41.137982214785225],
            [43.460773175687251, 41.112964245393258],
            [43.454163374462752, 41.094927060261639],
            [43.483873197989141, 41.022773122954888],
            [43.56597326926979, 40.996664165042588],
            [43.5952001275339, 40.993326994103015],
            [43.672491165673108, 40.92651802882817],
            [43.742491289133937, 40.770264265775594],
            [43.75040917017165, 40.745000203705942],
            [43.741382195702698, 40.666654051591863],
            [43.717936168436694, 40.620918029149252],
            [43.653045150251813, 40.531382203902851],
            [43.592763340635855, 40.501100064026033],
            [43.583054079246409, 40.451109052243524],
            [43.606936133116818, 40.368891131403913],
            [43.661654035389205, 40.274436134808226],
            [43.715373317714665, 40.157182193873538],
            [43.66852719601232, 40.146173234574164],
            [43.64881815646865, 40.124854031425897],
            [43.668400126359842, 40.103182117791121],
            [43.91249120579451, 40.01860016497325],
            [43.933882158034152, 40.013609076894497],
            [43.971373237576159, 40.010818070767314],
            [44.044291269178615, 40.010344996150863],
            [44.28263621869425, 40.046100183540474],
            [44.351663370831744, 40.022218129670065],
            [44.399154060890027, 40.003609131102095],
            [44.487782293658, 39.962773169269539],
            [44.551800252805748, 39.898744984199922],
            [44.560263292827671, 39.879436096714826],
            [44.592491207712982, 39.83180006445518],
            [44.641382178519763, 39.803744996258231],
            [44.746100145030169, 39.730554049887715],
            [44.778863328253834, 39.706382149804668],
            [44.799373174829242, 39.686445122493907],
            [44.816373183259174, 39.645818037689125],
            [44.813045064774911, 39.630809066545154],
            [44.73671811314162, 39.701800098601936],
            [44.608463259534659, 39.779154001015385],
            [44.471082189724683, 39.698864253187608],
            [44.477891144969277, 39.640653947128257],
            [44.425827120887533, 39.436444968942212],
            [44.401100171174761, 39.416518167553349],
            [44.340273202574821, 39.395544969369027],
            [44.304527235278698, 39.387291141651119],
            [44.230918199576735, 39.408454106123699],
            [44.200954069107098, 39.416936089246761],
            [44.076863345009343, 39.41165398148901],
            [44.036382272958264, 39.385473106847357],
            [44.034954164291122, 39.37744508760143],
            [44.060545120585715, 39.352482103494694],
            [44.074718248342748, 39.336100176606706],
            [44.216445167323201, 39.136518008986442],
            [44.198036160965586, 39.056673172580517],
            [44.162418101512372, 39.005200072674299],
            [44.173327148525658, 38.968036054995011],
            [44.209427167412827, 38.893464106253546],
            [44.250545096469097, 38.865544992526409],
            [44.283663337112301, 38.862491129914915],
            [44.30026336112158, 38.842627025162116],
            [44.303191159908891, 38.809982194411489],
            [44.26180014844644, 38.724291148162408],
            [44.306518104926596, 38.647282077246913],
            [44.317218107274044, 38.61304502016462],
            [44.32152724370863, 38.509373115173432],
            [44.311763332310392, 38.469927040529328],
            [44.30416329304191, 38.449436137055145],
            [44.305263334017724, 38.400536113792853],
            [44.318145145767829, 38.382054017239497],
            [44.436727115445393, 38.394809094613251],
            [44.48251812317298, 38.341300198058192],
            [44.473218233935796, 38.316745077360977],
            [44.399018106420129, 38.211300064567055],
            [44.323318121146173, 38.097209118623439],
            [44.238882181082118, 37.960273127873378],
            [44.223973289862244, 37.899153966128026],
            [44.293054086180206, 37.873318090621936],
            [44.576791232469219, 37.76680019421859],
            [44.617763316409849, 37.71797309351426],
            [44.598873188808597, 37.690818074082884],
            [44.584436198738928, 37.613882093363046],
            [44.588854132276964, 37.443091092678586],
            [44.612800223887717, 37.434227062407743],
            [44.648600170640378, 37.427773164582689],
            [44.801654058327671, 37.321664137417358],
            [44.818182165607453, 37.29741814131016],
            [44.820545191757304, 37.268744991571268],
            [44.792763373604743, 37.174991057359208],
            [44.787336091283493, 37.149709058016626],
            [44.774091175486575, 37.16596425052812],
            [44.745827230262051, 37.174427122911979],
            [44.642218189544707, 37.188464128560909],
            [44.582763338498552, 37.163182129218441],
            [44.567627130063926, 37.149709058016626],
            [44.550545146620806, 37.132636127028817],
            [44.49055418904598, 37.091653984804381],
            [44.470273336065958, 37.080827080442546],
            [44.436382283947836, 37.063045040444209],
            [44.349709213919908, 37.038318090731352],
            [44.341100161144453, 37.006653942655092],
            [44.319163211730455, 36.971236043050212],
            [44.257073257956478, 36.985691138030845],
            [44.1959633163045, 37.096382216398908],
            [44.230263237660751, 37.154018026812977],
            [44.26260028492581, 37.163464264080133],
            [44.268327136467434, 37.195000169037414],
            [44.262145147582174, 37.23651808251428],
            [44.230554089701769, 37.276245118553689],
            [44.118882161021389, 37.315682140742283],
            [44.096100148126794, 37.317218040684438],
            [44.072945140539929, 37.31637314484199],
            [44.048045188345242, 37.322218013580695],
            [44.009018208861022, 37.317764205497028],
            [43.955818101619826, 37.279636101311823],
            [43.936936188283738, 37.251936090534443],
            [43.91298221768389, 37.226591059279727],
            [43.816373239604246, 37.221927033063636],
            [43.623045145236603, 37.230000146948683],
            [43.489991149134056, 37.251245086434622],
            [43.156936225527232, 37.373882220879736],
            [43.002763353762845, 37.339153984270197],
            [42.960618138650631, 37.323527099222261],
            [42.924163229982042, 37.327764151289344],
            [42.894436139735063, 37.335536019573382],
            [42.822836078591621, 37.367182230376713],
            [42.786800265082974, 37.3836731220063],
            [42.765727154612676, 37.371218116767054],
            [42.724709137842609, 37.351945103827788],
            [42.588045056032257, 37.175682229097006],
            [42.579509261090635, 37.153673195315307],
            [42.531663345974806, 37.147491206430161],
            [42.376936095132834, 37.117491201415035],
            [42.355609180633707, 37.106927153537228],
            [42.357073331484315, 37.129709166431823],
            [42.35151814132027, 37.231173110482459],
            [42.294436207069708, 37.26971813053278],
            [42.21582719847143, 37.289445107349295],
            [42.180827220559962, 37.290544980686931],
            [42.14874515079029, 37.259300095408676],
            [42.083318193714803, 37.208318007391924],
            [42.056927100940896, 37.192773097357545],
            [41.995273173771324, 37.172764153317232],
            [41.840273175799297, 37.129991133655338],
            [41.548191174323421, 37.085273177175267],
            [41.483045178643152, 37.080273204279123],
            [41.454163319514663, 37.078891196079681],
            [41.362491282419086, 37.07500014897677],
            [41.216382304446228, 37.072491110073187],
            [41.118327112788478, 37.097491142192055],
            [40.929991279966231, 37.129718051249242],
            [40.901109253199735, 37.128609125455895],
            [40.806654088965985, 37.122491174311094],
            [40.770818100029572, 37.118045077578373],
            [40.688463218891599, 37.095545031907491],
            [40.670273315483797, 37.085126996783472],
            [40.634991202710665, 37.068882197831769],
            [40.513045072365372, 37.023182217572881],
            [40.48249119118671, 37.020818017956728],
            [40.447773180498899, 37.015273221352643],
            [40.415263298389874, 37.001382228457217],
            [40.279709148201249, 36.928600151324801],
            [40.263882270943071, 36.919491201842462],
            [40.214427198051141, 36.895818024999912],
            [40.007763328056086, 36.814709029953448],
            [39.94277323577532, 36.792764201550469],
            [39.923327220353912, 36.786382220454882],
            [39.818054036575546, 36.75360914658539],
            [39.588054109884553, 36.718882083442239],
            [39.439300138538243, 36.697518120930809],
            [39.308600283768129, 36.678318030549306],
            [39.257763370314848, 36.668054054806291],
            [39.221527229319605, 36.665336138874963],
            [39.171654067096171, 36.669709145411829],
            [39.130545190495326, 36.683600138307256],
            [39.096373176981984, 36.695545020917834],
            [39.074718194791643, 36.702764186505036],
            [39.043463251229554, 36.708191133550017],
            [39.003673183278039, 36.70561805690609],
            [38.966100128722729, 36.696936081572673],
            [38.917627079609559, 36.695135984041642],
            [38.774709259822572, 36.698045007365906],
            [38.72526323938601, 36.70360908234737],
            [38.68416324760264, 36.725545025933044],
            [38.62831814698805, 36.768044963188643],
            [38.54110025325221, 36.844300165730644],
            [38.51360911950249, 36.858044978234076],
            [38.432773206862322, 36.885544996801215],
            [38.386373170048302, 36.898327063903295],
            [38.243400197338218, 36.913882199859856],
            [38.181109245525562, 36.905818138430206],
            [38.09729121936536, 36.873745121115846],
            [38.055545150483368, 36.850545018889946],
            [38.02337322671147, 36.830364245834076],
            [37.993327121228646, 36.82471802820136],
            [37.956100239275401, 36.812909100060594],
            [37.917773316346342, 36.791100058489334],
            [37.891391276027861, 36.781100112696933],
            [37.776936220209478, 36.747982207329969],
            [37.736109143194227, 36.747764110208706],
            [37.710273267688223, 36.748327038827441],
            [37.669718099612822, 36.737218167241892],
            [37.568318193302702, 36.696936081572673],
            [37.533336152664077, 36.677773206841479],
            [37.373809256412443, 36.655336025444555],
            [37.26943612139965, 36.664444974926894],
            [37.248882186013333, 36.664718057333104],
            [37.127491272935828, 36.659153982351526],
            [37.03819114680752, 36.719645004271413],
            [36.988318152222092, 36.757418051036964],
            [36.933600249949535, 36.778045076619136],
            [36.702763306405103, 36.829427149056485],
            [36.666709220347542, 36.834282115027335],
            [36.659945192104061, 36.833709128124596],
            [36.662563363387306, 36.815136004102371],
            [36.586391141686903, 36.619709081173923],
            [36.54957329660985, 36.487773230958098],
            [36.567636130365401, 36.420964098045076],
            [36.582763286344374, 36.397491081050774],
            [36.661136092910652, 36.310982128687655],
            [36.690136136874145, 36.286245120691078],
            [36.68311813696377, 36.232736056497842],
            [36.616454178614248, 36.21950907797391],
            [36.575554179041234, 36.227773131613802],
            [36.535554060595558, 36.235544999897954],
            [36.506654096556161, 36.233327148311375],
            [36.392218151476897, 36.213327089088679],
            [36.37492712336811, 36.176591051386808],
            [36.381382194659551, 36.097073109205539],
            [36.383609266339505, 36.030536052870403],
            [36.374973223835724, 35.997909159392819],
            [36.30000028484497, 35.969718136726058],
            [36.223609127833157, 35.962218009743552],
            [36.186800167573523, 35.910273176325447],
            [36.182500251232483, 35.879718121680426],
            [36.182500251232483, 35.855000056784988],
            [36.168473303867273, 35.819718111650204],
            [36.155873291702591, 35.818445068192247],
            [36.144163270019618, 35.833609104545431],
            [36.128054090261656, 35.852218103113486],
            [36.109991256506106, 35.861382205518666],
            [36.015827111951438, 35.899718013265144],
            [35.922436114074031, 35.926991049946807],
            [35.892021533000189, 35.944441385000133],
            [35.889264533000159, 35.946248384999976],
            [35.845624533, 35.966641385000131],
            [35.80642953300017, 35.984056385000073],
            [35.780146533000135, 35.996345385000083],
            [35.696308533000121, 36.035673385999971],
            [35.695661533000049, 36.036193386000107],
            [35.678903533000181, 36.040667386000095],
            [35.632945533000083, 36.048322386000123],
            [35.600706533000078, 36.052711385999984],
            [35.575767533000118, 36.055495386000032],
            [35.566219533000066, 36.056487386000143],
            [35.535501533000144, 36.059447386],
            [35.499969533000154, 36.060900386000142],
            [35.483736533000155, 36.06115738600009],
            [35.44259253300018, 36.061884386000017],
            [35.265326533000149, 36.060132386],
            [35.159257533000215, 36.014479385],
            [34.9456915330002, 36.141560386000023],
            [34.90145053300003, 36.162357386000124],
            [34.827918532000155, 36.191090386000113],
            [34.73997753200004, 36.207549386],
            [34.710235532000212, 36.199084386000109],
            [34.693808532000133, 36.19437838600011],
            [34.653565532000101, 36.179617386000118],
            [34.415067532000222, 36.046665385999958],
            [34.353824532000118, 36.012318385000171],
            [34.313400532000202, 35.998670385000068],
            [34.274956532000175, 35.978659385],
            [34.17510453200012, 35.924837385000089],
            [34.150431532000056, 35.911447385000102],
            [34.141450532000107, 35.906512384999971],
            [34.140630532000131, 35.906056385000099],
            [33.993544532000072, 35.873095385000127],
            [33.961204532000153, 35.859174385000173],
            [33.927593532000145, 35.841066385],
            [33.849076532000112, 35.798061384999968],
            [33.781615531000199, 35.774015385],
            [33.710983531000039, 35.770673385000165],
            [33.675870531000015, 35.764488385000092],
            [33.504815531000162, 35.731067385000173],
            [33.494301531000104, 35.730495385],
            [33.431052531, 35.718191385000168],
            [33.423665531000125, 35.718828385000123],
            [33.306255531000119, 35.72871838500005],
            [33.263032531000107, 35.720222384999985],
            [33.260925531000026, 35.719802384999966],
            [33.143725531000058, 35.715579385000055],
            [33.101725531000142, 35.71379938500003],
            [33.069549531000035, 35.718691385000156],
            [32.98504753100022, 35.731433385000159],
            [32.79084853100008, 35.705441385000128],
            [32.616654530000034, 35.681241385000121],
            [32.590314530000143, 35.675176385000114],
            [32.417833530000081, 35.631032384999983],
            [32.359200530000095, 35.636041385000098],
            [32.295580530000194, 35.636414385000151],
            [32.253312530000045, 35.633869385000068],
            [32.248600530000061, 35.633486384999983],
            [32.186708530000175, 35.639799384999989],
            [32.087475530000091, 35.649349385000036],
            [32.04210153, 35.652639385000029],
            [31.986997530000195, 35.656548385000079],
            [31.88336053000009, 35.662741385000103],
            [31.534384529000164, 35.681191384999963],
            [31.471074529000106, 35.68201438500013],
            [31.371011529000185, 35.682789385000021],
            [31.07405252900017, 35.34266338500008],
            [31.00558452900006, 35.265310385000063],
            [30.784057529000194, 35.019716385000081],
            [30.478648528000093, 34.687190384000033],
            [30.397378528000075, 34.599004384000054],
            [30.27107352800013, 34.472379384],
            [30.224294528000115, 34.425481384],
            [30.079112528000138, 35.461876385000139],
            [29.620006528000062, 36.085448385999982],
            [29.61885752800012, 36.105446386000139],
            [29.619695528000165, 36.10972738599996],
            [29.620672528000085, 36.114050386000073],
            [29.620994528000011, 36.115730385999981],
            [29.622360528000144, 36.121757386000112],
            [29.624338528000266, 36.128428386000067],
            [29.625219028000146, 36.131275386000098],
            [29.624550528000071, 36.132792386000105],
            [29.622952528000184, 36.13476838600009],
            [29.619357528000165, 36.140151386000142],
            [29.61565252800014, 36.147991386000101],
            [29.613533528000033, 36.151971886000027],
            [29.612774528000131, 36.153359386000076],
            [29.609103028000192, 36.156681386000159],
            [29.609964528000063, 36.158777386000096],
            [29.612176528000276, 36.166381386000083],
            [29.613318528000121, 36.170976386000135],
            [29.614124528000076, 36.172746386000043],
            [29.613789528000012, 36.174668386000079],
            [29.610115528000136, 36.175650386000157],
            [29.603494528000141, 36.177558386000086],
            [29.600254528000306, 36.177295386000011],
            [29.599035528000144, 36.177008386000253],
            [29.598119528000183, 36.176950386000058],
            [29.592900528000229, 36.176254386000025],
            [29.589196528000087, 36.176138386000204],
            [29.586244528000151, 36.175850386000079],
            [29.584056528000161, 36.175850386000079],
            [29.580696528000232, 36.175455386],
            [29.579738528000092, 36.175399386000166],
            [29.577009528000161, 36.175399386000166],
            [29.573410528000153, 36.175219386000194],
            [29.570295528000173, 36.175300386000131],
            [29.56863452800016, 36.175318386000114],
            [29.566900528000104, 36.173540386],
            [29.565995528000116, 36.172582386000144],
            [29.560953528000113, 36.16839738600008],
            [29.551621528000197, 36.16355238600012],
            [29.548700528000239, 36.162073386000159],
            [29.547388528000141, 36.161385386000248],
            [29.544669528000071, 36.160084386000065],
            [29.536117528000062, 36.167634386000017],
            [29.530695528000166, 36.172018386000204],
            [29.529581528000023, 36.172942386000088],
            [29.515318527, 36.184423386000034],
            [29.510743527000187, 36.183969386000129],
            [29.508418527000032, 36.184071386000042],
            [29.506201527000229, 36.184319385999956],
            [29.503322527000279, 36.184479386000234],
            [29.495066527000148, 36.185509386000192],
            [29.493699527000189, 36.185584386000173],
            [29.48994852700028, 36.185923386000141],
            [29.484807527000072, 36.186093386000195],
            [29.480108527000226, 36.185236385999971],
            [29.470050527000154, 36.17338038600009],
            [29.468661527000251, 36.171939386000233],
            [29.466128527000279, 36.169247386000066],
            [29.457538527000253, 36.16197238600013],
            [29.449758527000171, 36.155841386],
            [29.44705052700013, 36.153764386000105],
            [29.355762527000167, 36.082008386000112],
            [29.348302527000186, 36.070193386000071],
            [29.339309527000154, 36.055589386],
            [28.872636527000026, 35.299706385000022],
            [28.84943852700016, 35.424465385000119],
            [28.771194527000119, 35.84324938500005],
            [28.729932527000102, 35.944295385000217],
            [28.724215527000212, 35.957160385000108],
            [28.709797527000177, 36.015379385000116],
            [28.702965527000089, 36.038032386000211],
            [28.698852527000128, 36.056009386000099],
            [28.685906527000128, 36.11314138600018],
            [28.68563152700014, 36.115603386000132],
            [28.677484527000189, 36.188071386000061],
            [28.67196552700014, 36.19705838600008],
            [28.664124527000155, 36.210076386000182],
            [28.640628527000132, 36.258927386000025],
            [28.571636527000152, 36.403063385999971],
            [28.555510527, 36.436572386000151],
            [28.551841527000164, 36.441673385999977],
            [28.534910527000164, 36.472159385999973],
            [28.522017527000088, 36.484574386000091],
            [28.5069055270001, 36.499336386000081],
            [28.488698527000139, 36.516809386000219],
            [28.48698152700004, 36.518445386000039],
            [28.47759452700015, 36.52745938600016],
            [28.442382526000159, 36.560980386000082],
            [28.406088526000104, 36.565369385999972],
            [28.391167526000146, 36.56786738600006],
            [28.355671526, 36.573845386000144],
            [28.281937526000121, 36.574258386000039],
            [28.207325526000119, 36.562001386],
            [28.12542952600009, 36.525124386000229],
            [28.002097526000199, 36.469589386000123],
            [27.960676526000128, 36.470373386],
            [27.930916526000175, 36.537332386000102],
            [27.918692526000115, 36.592534386000168],
            [27.907992526000015, 36.640854386000129],
            [27.895497526000128, 36.69727638600007],
            [27.813951526000125, 36.708633386000045],
            [27.774830526000159, 36.693490386000164],
            [27.725615526000041, 36.644274386000134],
            [27.641670526000127, 36.574321386000108],
            [27.49973552600008, 36.545406386000067],
            [27.493237526000115, 36.544281386000236],
            [27.485093526000156, 36.543273386000138],
            [27.476886526000129, 36.542697386000086],
            [27.468649526000064, 36.542554386000162],
            [27.462141526000124, 36.542784386000093],
            [27.351478525000147, 36.586100386000098],
            [27.298108525000231, 36.633572386000068],
            [27.272613525000082, 36.670540386000013],
            [27.250233525000084, 36.702990386000167],
            [27.247118525000047, 36.725991386000075],
            [27.351010525000135, 36.807575386000039],
            [27.384790526000103, 36.856652386000107],
            [27.393714526000025, 36.905093385999962],
            [27.351647525000061, 36.918478386000245],
            [27.324877525000119, 36.921027386000119],
            [27.287272525000134, 36.938874386000109],
            [27.217799525000117, 36.94524738600019],
            [27.207601525000229, 36.968830386000107],
            [27.162985525000039, 37.060612386000102],
            [27.161710525000245, 37.165141387000048],
            [27.158775525000095, 37.262032386999962],
            [27.153627525000076, 37.311796387000228],
            [27.109011525000113, 37.457655387000088],
            [27.054099525000112, 37.564047387000258],
            [27.043803525000129, 37.667006387000256],
            [27.059247525000075, 37.691030387000083],
            [27.086703525000161, 37.699610387000163],
            [27.164928525000022, 37.724055387],
            [27.160491525000083, 37.790558387000047],
            [27.06818752500007, 37.886451387000179],
            [27.053961525000233, 37.913859387000045],
            [26.918741525000087, 37.96478438700008],
            [26.589414525000137, 38.040890387000118],
            [26.587574525000036, 38.04132438699996],
            [26.3632045250001, 38.095432387000116],
            [26.359348525000087, 38.096410387000205],
            [26.351471525000193, 38.098746387000219],
            [26.343804525000053, 38.101483387000016],
            [26.336379525000154, 38.10461138700019],
            [26.329229525000187, 38.108115387000211],
            [26.326705524999966, 38.10947838700001],
            [26.221701523999968, 38.167533387000233],
            [26.19461752400008, 38.241496387999959],
            [26.223011524000185, 38.311061388000226],
            [26.213073524, 38.383465388000204],
            [26.242887524, 38.445932388000259],
            [26.315291525000248, 38.453030388000101],
            [26.320970525000092, 38.498460388000225],
            [26.312452525, 38.536792387999967],
            [26.279799524000026, 38.573704388000166],
            [26.250277524000126, 38.607077388000121],
            [26.215779524000084, 38.651550388000103],
            [26.215910524000094, 38.656517388000211],
            [26.216163524000052, 38.662197387999967],
            [26.21697352400011, 38.668815388000056],
            [26.218334524000113, 38.675376388000259],
            [26.220239524000249, 38.681854387999962],
            [26.222682524000135, 38.688221388000088],
            [26.225651524000142, 38.694448388000168],
            [26.229133524000105, 38.700510388000097],
            [26.233115524000056, 38.706380388000113],
            [26.237579524000125, 38.712033388000137],
            [26.242506524000191, 38.717446388000198],
            [26.247875524000165, 38.72259438799999],
            [26.25366352400016, 38.727455388000109],
            [26.259845524000241, 38.732009388000236],
            [26.266395524000131, 38.736237388000106],
            [26.273285524000187, 38.740119387999968],
            [26.280485524000113, 38.743640388000145],
            [26.287964524000046, 38.746784388000052],
            [26.295691524000176, 38.749538388000204],
            [26.303631524000139, 38.751889388000208],
            [26.311752525000088, 38.753829388000142],
            [26.320017525000111, 38.755347388000146],
            [26.323875525000119, 38.75590538799996],
            [26.451229525000088, 38.77268638800011],
            [26.618532525, 38.809115388000052],
            [26.64656352500009, 38.909730388000256],
            [26.699302525000121, 38.979992388000099],
            [26.704688525000165, 39.032056388000044],
            [26.674167525000115, 39.121822388000098],
            [26.586197525000159, 39.220564388000142],
            [26.431800525000142, 39.434207389000065],
            [26.142754524000168, 39.405482389000113],
            [25.98173252400008, 39.396180389000179],
            [25.965531524000141, 39.408879389000177],
            [25.960560524000158, 39.412994389000133],
            [25.955090524000184, 39.418109389000037],
            [25.950061524000091, 39.423491389000091],
            [25.945497524000132, 39.4291163890001],
            [25.941416524000147, 39.43496038900021],
            [25.937836524000147, 39.440999389000197],
            [25.93477252400001, 39.447206389000215],
            [25.932238524000155, 39.453555389000073],
            [25.9302455240001, 39.460019389000067],
            [25.928801524000164, 39.466571389000208],
            [25.927913524000047, 39.473181389000089],
            [25.927584524, 39.479822389000233],
            [25.927582524000115, 39.480612389000228],
            [25.928242524000098, 39.71435238900024],
            [25.910906524000126, 39.788522389000136],
            [25.892636524000125, 39.841675389000116],
            [25.89092252400016, 39.847300389000125],
            [25.889467524000111, 39.853849389000217],
            [25.888570524000158, 39.860458389000101],
            [25.888236524000035, 39.867098389000077],
            [25.888467524000163, 39.873740389000019],
            [25.889261524000119, 39.880357389000224],
            [25.890615524000054, 39.886920389000224],
            [25.891612524000124, 39.890548389000102],
            [25.757572524000125, 39.94419338900012],
            [25.753214524000128, 39.946013389000072],
            [25.745849524000135, 39.949480389000158],
            [25.73879352400013, 39.953309389000069],
            [25.73207752400009, 39.95748538900007],
            [25.725730524000113, 39.96199038900015],
            [25.719778524000219, 39.966804389000103],
            [25.714247524000086, 39.971907389],
            [25.709161524000081, 39.977277389000236],
            [25.70454252400009, 39.982891389000045],
            [25.70040952400015, 39.988726389000206],
            [25.698335524000242, 39.992046389000095],
            [25.633361524000236, 40.10084638900021],
            [25.631802524000108, 40.103555389],
            [25.628683524000166, 40.109753388999977],
            [25.62609952400004, 40.116093389],
            [25.624059524000188, 40.122550389000097],
            [25.62257352400016, 40.129096389000111],
            [25.62164952400019, 40.135702389000116],
            [25.621289524000161, 40.142340389000097],
            [25.621495524000153, 40.148982389000039],
            [25.622267524000165, 40.155599389000074],
            [25.623602524000034, 40.16216438900004],
            [25.625494524000089, 40.168647388999986],
            [25.627935524000179, 40.175022389000063],
            [25.630914524000104, 40.181260389000172],
            [25.634420524, 40.187335389000097],
            [25.63843752400021, 40.193222389000127],
            [25.642948524000047, 40.198894389000102],
            [25.64793452400022, 40.204327389000227],
            [25.6533745240001, 40.209498388999961],
            [25.659244524000144, 40.214386389000097],
            [25.665519524000104, 40.218968389000153],
            [25.672172524000075, 40.22322538900012],
            [25.679175524000243, 40.227140389000041],
            [25.686498524000058, 40.230694389000107],
            [25.693720524000042, 40.233723389000076],
            [26.049914524000144, 40.371418390000102],
            [25.906418524000031, 40.697200390000077],
            [25.904181524000137, 40.70277839000012],
            [25.902151524000118, 40.709236390000129],
            [25.900679524, 40.715783390000226],
            [25.899774524000179, 40.72238839000012],
            [25.899438524000175, 40.729024390000134],
            [25.899544524000248, 40.730976390000151],
            [26.03829452400015, 40.733731390000216],
            [26.044718295867114, 40.735827162712738],
            [26.059509125732177, 40.734264159174771],
            [26.090545133982772, 40.736109183706859],
            [26.120200139862305, 40.747700182364454],
            [26.17332715690759, 40.818600186952651],
            [26.213609242576808, 40.877209136327949],
            [26.251109206936235, 40.888609027592608],
            [26.287491193046947, 40.901927033585295],
            [26.360409224649459, 40.953882092925383],
            [26.372982247085787, 41.027354000691119],
            [26.323891284068651, 41.093036102900001],
            [26.325000209861997, 41.23470904242383],
            [26.372845119149417, 41.254436019240345],
            [26.415273307313726, 41.259435992136687],
            [26.607500187238969, 41.330964136550548],
            [26.624718125152015, 41.343882158122639],
            [26.635763294273289, 41.364718060732386],
            [26.636382214005465, 41.413464192251851],
            [26.604445151161087, 41.546100099022979],
            [26.570273305285724, 41.611382216811066],
            [26.519445109011713, 41.633609180075737],
            [26.398327110702326, 41.690818016340799],
            [26.361100228749166, 41.711054109957686],
            [26.351173205514527, 41.7190821292035],
            [26.332636123676139, 41.745409184236948],
            [26.343954039927524, 41.782345046511082],
            [26.381318217455146, 41.822000165821024],
            [26.446663199517189, 41.825000048975895],
            [26.472773163257784, 41.824153979666974],
            [26.535727123613441, 41.828045026769971],
            [26.558327081570496, 41.851664224155982],
            [26.565827208552918, 41.871382148517185],
            [26.575291215454712, 41.898645126861751],
            [26.566954071618966, 41.934754030566339],
            [26.621382295316977, 41.9730539637671],
            [26.933054145673083, 42.006945015885137],
            [26.962218139663179, 42.003318166370988],
            [27.037218236020038, 42.083609087665238],
            [27.070273277113159, 42.089991068760938],
            [27.233054028186615, 42.109991127983548],
            [27.286245082972499, 42.100964153514568],
            [27.309163217974998, 42.091382129415777],
            [27.363263206343589, 42.062845101784959],
            [27.373054107470097, 42.039982119705414],
            [27.393463203569098, 42.009291110590581],
            [27.411527210791036, 41.99471816605093],
            [27.441109126474629, 41.976945010870011],
            [27.56958224484103, 41.909264160024506],
            [27.595554074816818, 41.93555399940756],
            [27.633327121582482, 41.95582714103675],
            [27.705827063853377, 41.977482123227006],
            [27.83250025232681, 42.001664249231837],
            [27.867082308544667, 42.005545070412879],
            [27.903609133942695, 41.994709113595533],
            [27.971100218499856, 41.98415395053523],
            [28.013226487860521, 41.982217792724171],
            [28.031463526000067, 41.978785391000073],
            [28.034749738666761, 41.978194444000039],
            [28.035553553000085, 41.978720132000021],
            [28.037722222000156, 41.980138887999985],
            [28.040333365000066, 41.981333332000148],
            [28.323833333000124, 41.981333361500177],
            [28.323852688000073, 41.987062262000038],
            [28.323888888500079, 41.997777627000133],
            [28.896717639500167, 42.134172989],
            [29.345833333000115, 42.241111110000062],
            [29.572222222000192, 42.439999999000136],
            [29.826666667000069, 42.489999999],
            [29.975000000000108, 42.557499999000029],
            [30.569444486500203, 42.800833361500153],
            [30.604999999000171, 42.825277776000107],
            [30.751666667000137, 42.945277776999987],
            [31.10916666700021, 43.331666665999975],
            [31.284952724000078, 43.417491622500037],
            [31.345261965166827, 43.446967096666796],
            [31.999999530000053, 43.345277392],
            [33.615555531000183, 43.197222392000171],
            [34.219444532000097, 43.18805539200001],
            [35.506944533000166, 43.435555392000069],
            [36.151255549000069, 43.434495833000042],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "TUV", Country: "Tuvalu" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-179.931455688999904, -12.898626396999816],
              [-180.0, -12.943964335999965],
              [-179.999999979999984, -11.839861772999882],
              [-179.999960402999903, -4.145041505],
              [-179.999066009999893, -4.146299907499895],
              [-179.998277084999984, -4.147416152999938],
              [-179.997343204999908, -4.148737601999926],
              [-179.996409319999913, -4.150059047999832],
              [-179.995565878499974, -4.151252585999941],
              [-179.994541833999932, -4.152702076000025],
              [-179.99360773449996, -4.154023221999921],
              [-179.992673833999987, -4.15534507599989],
              [-179.991856747999975, -4.156500788999836],
              [-179.991448622999968, -4.157078261000038],
              [-179.990805833999985, -4.157988075999981],
              [-179.990071624, -4.159026619499969],
              [-179.98893806399991, -4.160630512999944],
              [-179.988004135999972, -4.161951932999841],
              [-179.987070201999984, -4.16327335099993],
              [-179.986136263999981, -4.164594764999947],
              [-179.985011356499939, -4.166186365499868],
              [-179.98426883399992, -4.16723807599999],
              [-179.983334833999919, -4.168559075999951],
              [-179.982400833999918, -4.169880075999913],
              [-179.981466833999974, -4.171202075999872],
              [-179.980532833999973, -4.172523076],
              [-179.979598833999972, -4.173845075999964],
              [-179.978664833999886, -4.175166075999925],
              [-179.977730833999942, -4.176487076],
              [-179.976796833999941, -4.17780907599996],
              [-179.97586283399994, -4.179130075999922],
              [-179.975301639999969, -4.17992379899988],
              [-179.974813304999884, -4.18061467449985],
              [-179.97399483399991, -4.181773076000013],
              [-179.973060833999909, -4.183094075999975],
              [-179.972126833999909, -4.184416075999934],
              [-179.971192833999822, -4.185737075999896],
              [-179.970258834, -4.187058075999971],
              [-179.969324833999963, -4.18838007599993],
              [-179.968390833999962, -4.189701075999892],
              [-179.967456833999876, -4.191022076000024],
              [-179.96652283399996, -4.192344075999983],
              [-179.965588833999931, -4.193665075999945],
              [-179.964492637999911, -4.195215405499937],
              [-179.963720292999966, -4.196307873999956],
              [-179.962786232999946, -4.197629217999861],
              [-179.96185216899994, -4.198950560000014],
              [-179.9609181, -4.200271899000029],
              [-179.959984025999916, -4.201593234000029],
              [-179.959049946999983, -4.202914567],
              [-179.958197126499897, -4.204121012999906],
              [-179.957331561499956, -4.205345341999873],
              [-179.956100889499908, -4.207086191999849],
              [-179.955313833999952, -4.208200075999969],
              [-179.954379833999951, -4.209521075999874],
              [-179.953261205499899, -4.21110312299993],
              [-179.952511258999976, -4.212163818999954],
              [-179.951577140999945, -4.213485130000038],
              [-179.950643017999852, -4.214806436999936],
              [-179.949844456499989, -4.215936113999874],
              [-179.948774833999977, -4.21744907599998],
              [-179.947840833999976, -4.218770075999885],
              [-179.946906833999975, -4.220092075999844],
              [-179.946146659999954, -4.221166355999912],
              [-179.945038178999908, -4.222734222999975],
              [-179.944104022999966, -4.224055510999847],
              [-179.943289627499951, -4.225207518499928],
              [-179.942235833999945, -4.226698075999934],
              [-179.941301833999916, -4.228019075999896],
              [-179.940367833999915, -4.229341075999855],
              [-179.9395308954999, -4.230523680999909],
              [-179.938498978999917, -4.231983178999883],
              [-179.937424004999968, -4.233503560499855],
              [-179.93663083399997, -4.23462607599987],
              [-179.93569683399997, -4.235947075999945],
              [-179.935204259999978, -4.23664300199988],
              [-179.934762184999926, -4.237268234],
              [-179.933827973999968, -4.238589490999971],
              [-179.933088366999897, -4.239635730500027],
              [-179.93178642649994, -4.241477072499833],
              [-179.931025312999822, -4.242553243999964],
              [-179.930091082999951, -4.24387448899995],
              [-179.929295990499952, -4.244999111499965],
              [-179.928222833999911, -4.246517077000021],
              [-179.927096072999944, -4.24811041549998],
              [-179.92635411299986, -4.24915944299994],
              [-179.925572037499848, -4.250265645499951],
              [-179.924298668499915, -4.252066509999963],
              [-179.923551333999939, -4.253123127999913],
              [-179.922617064, -4.254444350999904],
              [-179.921511292499929, -4.256008095499908],
              [-179.920748833999966, -4.257087076999881],
              [-179.919997480999882, -4.258148853499961],
              [-179.918879936999957, -4.259729214],
              [-179.917916278499945, -4.261091947999944],
              [-179.917011833999936, -4.262372076999839],
              [-179.916027125666659, -4.263763415333244],
              [-179.915142833999937, -4.265014076999933],
              [-179.914033002499878, -4.266583496999942],
              [-179.913274098999949, -4.267656424],
              [-179.912540298999886, -4.268694334999793],
              [-179.911405833999908, -4.270299076999891],
              [-179.910599217999959, -4.271438844999864],
              [-179.909522134333258, -4.27296187099995],
              [-179.908479228499914, -4.274436710999908],
              [-179.907668082999976, -4.275583531999871],
              [-179.906915763999905, -4.276647569999966],
              [-179.905799833999964, -4.278226076999943],
              [-179.904960780999886, -4.279411628499929],
              [-179.904286093999929, -4.280365615999926],
              [-179.903870891999873, -4.2809527645],
              [-179.902996267999896, -4.282189377999941],
              [-179.901878936999907, -4.28376922249987],
              [-179.901127833999936, -4.284832077000033],
              [-179.90027504199989, -4.286037019499929],
              [-179.899777619999952, -4.286740333000012],
              [-179.899154288499915, -4.287621781999917],
              [-179.898324329999951, -4.288795153000024],
              [-179.897389926999921, -4.290116298999919],
              [-179.896540004499883, -4.291318029999957],
              [-179.895520834, -4.292759076999914],
              [-179.894586833999966, -4.294080076999933],
              [-179.893845275999894, -4.295127970999886],
              [-179.892795356999898, -4.296612431999847],
              [-179.891783833999881, -4.298043076999932],
              [-179.891032642499852, -4.299104566499977],
              [-179.890173279999914, -4.300319544999979],
              [-179.889843010499902, -4.300786551499868],
              [-179.888980080999971, -4.302006489999769],
              [-179.888510618999931, -4.302670210999963],
              [-179.888045833999939, -4.303328076999946],
              [-179.887082798333267, -4.304688837333288],
              [-179.88617683399994, -4.305970076999984],
              [-179.885728128999915, -4.306604023000034],
              [-179.885242243999954, -4.307290946000023],
              [-179.884328549999935, -4.308582714666557],
              [-179.883373294999956, -4.309933156999989],
              [-179.882438812999908, -4.311254257999963],
              [-179.881504325999941, -4.312575356999844],
              [-179.880569834999818, -4.313896452],
              [-179.879813980499932, -4.314965115500002],
              [-179.878700833999972, -4.316539076999959],
              [-179.877972538999927, -4.317568209499882],
              [-179.877259406, -4.318576341999858],
              [-179.876653031999922, -4.31943369399994],
              [-179.875897300999924, -4.320501888000024],
              [-179.87522712599997, -4.321449274999907],
              [-179.874845886999964, -4.32198830349995],
              [-179.874028252999977, -4.323144041999967],
              [-179.873097334999869, -4.324460046666616],
              [-179.872159185999976, -4.325786184999913],
              [-179.871225228666646, -4.327106456333354],
              [-179.87029009799997, -4.328428316999862],
              [-179.869353666333268, -4.32975206999987],
              [-179.868420989999862, -4.331070437],
              [-179.867479672333275, -4.33240109433325],
              [-179.866551861999909, -4.333712545999902],
              [-179.865516077999928, -4.335176662999913],
              [-179.864682834, -4.336355076999894],
              [-179.863751242333251, -4.337671292999971],
              [-179.862813833999979, -4.338997076999988],
              [-179.861878833999981, -4.340318076999949],
              [-179.86094483399998, -4.341639077000025],
              [-179.860009833999897, -4.342960076999987],
              [-179.859460480999985, -4.343736220999972],
              [-179.858939801999895, -4.344472179499917],
              [-179.858140833999954, -4.34560207699991],
              [-179.857183172499902, -4.346955093499901],
              [-179.856271302999829, -4.348243943],
              [-179.855517867499884, -4.349309032499889],
              [-179.854752876999953, -4.350390111000024],
              [-179.854295544499905, -4.351036506999947],
              [-179.853467833999929, -4.352207076999832],
              [-179.852379760499844, -4.353744343999836],
              [-179.851423757999925, -4.355095450500016],
              [-179.850663833999931, -4.356170076999888],
              [-179.849644775499939, -4.357609837499979],
              [-179.848600257999806, -4.359086056499905],
              [-179.847859833999905, -4.360133076999887],
              [-179.846825957499959, -4.361593772499987],
              [-179.845820649999951, -4.363014538000016],
              [-179.845055833999908, -4.364096076999886],
              [-179.843905383999839, -4.365721471500024],
              [-179.843081056999836, -4.366886379999812],
              [-179.842251834, -4.368059076999771],
              [-179.841316833999969, -4.369380076999903],
              [-179.840381905499953, -4.370700979499929],
              [-179.839273801499928, -4.372266998499981],
              [-179.838512833999971, -4.373343076999959],
              [-179.837577833999973, -4.374664076999863],
              [-179.83667379366662, -4.375941375333184],
              [-179.835708833999945, -4.377306076999957],
              [-179.834773833999947, -4.378627076999919],
              [-179.833705148999911, -4.380136807999975],
              [-179.832904145999976, -4.381268569999975],
              [-179.832044832499889, -4.382483002499896],
              [-179.831213329499917, -4.383657947499955],
              [-179.830099875999935, -4.385231405999946],
              [-179.829165109999963, -4.386552345999917],
              [-179.828430796499902, -4.387590260499962],
              [-179.827295833999926, -4.389194076999843],
              [-179.826360806999929, -4.390515112499941],
              [-179.825425992999925, -4.391836075999947],
              [-179.824318326999958, -4.393401282999946],
              [-179.823556834000016, -4.394478076999974],
              [-179.822621833999904, -4.395799076999936],
              [-179.821686833999905, -4.397120076999897],
              [-179.820751833999907, -4.39844107700003],
              [-179.81982, -4.399761831999967],
              [-179.818882833999822, -4.401082076999955],
              [-179.817947833999909, -4.402403077000031],
              [-179.817012759499931, -4.403724190499872],
              [-179.816077848999981, -4.405045203999975],
              [-179.815143005999829, -4.406366101],
              [-179.814208158999918, -4.407686995999882],
              [-179.813103900499897, -4.409247246499888],
              [-179.812338833999974, -4.410329076999915],
              [-179.81140383399989, -4.411650076999877],
              [-179.810602845999853, -4.412781036499894],
              [-179.809533846999955, -4.414291424999931],
              [-179.808598969999963, -4.415612301999886],
              [-179.807664086999978, -4.41693317599993],
              [-179.806729198999818, -4.418254048],
              [-179.805624781499944, -4.419814429499979],
              [-179.80485983399987, -4.420896076999895],
              [-179.803924833999957, -4.422217076999857],
              [-179.803035317499933, -4.423472911999966],
              [-179.802054684999916, -4.424858363999817],
              [-179.801119766999932, -4.426179217999788],
              [-179.80018483899994, -4.427500072999919],
              [-179.799249833999937, -4.428821076999782],
              [-179.798314833999854, -4.430142077000028],
              [-179.797292835499888, -4.431585898499975],
              [-179.796445100999875, -4.432783449],
              [-179.795510151999878, -4.434104286000036],
              [-179.794575198999951, -4.43542512099981],
              [-179.793640239999945, -4.436745953000013],
              [-179.792705275999964, -4.438066781999964],
              [-179.79177030699995, -4.439387608],
              [-179.790835333999951, -4.440708430999791],
              [-179.763956400999973, -4.497633479999934],
              [-179.75731883399996, -4.511691077000023],
              [-179.656784832999961, -4.557861076999984],
              [-179.178158832999941, -4.888228077],
              [-179.175264551999902, -4.891070894249992],
              [-179.155391065999936, -4.910626103999789],
              [-179.154197500999942, -4.911448489999884],
              [-179.15125319266653, -4.9143265583333],
              [-179.122549993999939, -4.942550966999988],
              [-178.736737882999904, -5.322091543999989],
              [-178.465699091999937, -5.701567299999795],
              [-178.398929855, -5.795069551999973],
              [-178.398660313999983, -5.795633440000017],
              [-178.397945850999946, -5.796632112999873],
              [-178.374447087999869, -5.845717940999862],
              [-178.14703733199994, -6.320894577999979],
              [-178.145691741, -6.32384634899978],
              [-178.145111116999914, -6.325056963],
              [-178.097170911999939, -6.497062868666603],
              [-177.910028934999872, -6.757400505499959],
              [-177.90975028099993, -6.757979114999841],
              [-177.909106031999983, -6.758874236999816],
              [-177.908230628999945, -6.760693382999875],
              [-177.907225831999938, -6.762782079000033],
              [-177.904069831999863, -6.767167078999762],
              [-177.900949831999981, -6.773650079],
              [-177.90001703599998, -6.774945328999763],
              [-177.899043209999974, -6.776298215],
              [-177.89769842299998, -6.779092381999874],
              [-177.897059831999911, -6.78039707899984],
              [-177.89670083999988, -6.781119767499831],
              [-177.896013329999931, -6.782105822999824],
              [-177.895628045999928, -6.782906328999786],
              [-177.894978157499935, -6.783834620499817],
              [-177.894674831999936, -6.784466078999969],
              [-177.89400183199993, -6.785400078999885],
              [-177.887909611999987, -6.798050798999896],
              [-177.824675805999988, -6.929358523999952],
              [-177.685313944999905, -7.064750459999914],
              [-177.562312831999947, -7.184248078999929],
              [-177.450110802999831, -7.339290427999856],
              [-177.221092317999933, -7.655752382999935],
              [-177.2201554802499, -7.657665578499916],
              [-177.219673323999956, -7.65863370399984],
              [-177.219168109999885, -7.659330510499899],
              [-177.21843468299997, -7.660779734999949],
              [-177.215557202000014, -7.666707542999802],
              [-177.191919308999957, -7.699300253999922],
              [-177.190414763999911, -7.702398582999876],
              [-177.189958048333295, -7.703307331666565],
              [-176.935508867999943, -8.226111362999973],
              [-176.934384294999973, -8.230056826999828],
              [-176.933129285999968, -8.23262979499998],
              [-176.931445830999934, -8.236082080000017],
              [-176.827562697999952, -8.599520395999889],
              [-176.771861152999946, -8.794394645999944],
              [-176.771511250999964, -8.797800065999979],
              [-176.770820279999981, -8.800210705999859],
              [-176.711221911999957, -9.377332625],
              [-176.749347613999959, -9.89451762099992],
              [-176.78230298699998, -9.925576176999868],
              [-176.885394346999902, -10.022378974999924],
              [-177.173988819999835, -10.293369214999871],
              [-177.25623334799991, -10.378501373999882],
              [-177.283471222999907, -10.406695578999859],
              [-177.311633756999953, -10.43584690800003],
              [-177.317988215999918, -10.442422095999859],
              [-177.320128879999942, -10.444637119],
              [-177.362452212999955, -10.488430593999965],
              [-177.380518447999975, -10.507124377999844],
              [-177.553614362999923, -10.686564319999832],
              [-177.711038977999976, -10.849758603999874],
              [-177.95089052499992, -11.275836695999885],
              [-178.001778256999984, -11.366234893999945],
              [-178.220138468999949, -11.754135258999838],
              [-178.225824530999972, -11.764236116999882],
              [-178.823394347, -12.161580694999941],
              [-178.824399812999957, -12.162249262999865],
              [-178.973109661999985, -12.261131519999935],
              [-178.979347673, -12.265274826999871],
              [-178.982955671999918, -12.267671271999973],
              [-178.985986079999918, -12.269684078999887],
              [-179.661684262, -12.719134750999842],
              [-179.699718081999919, -12.744489896999937],
              [-179.702992385999863, -12.746672703],
              [-179.729749593999969, -12.764510326],
              [-179.890047826999933, -12.871215309999968],
              [-179.931455688999904, -12.898626396999816],
            ],
          ],
          [
            [
              [179.999855501000297, -4.144774075999806],
              [179.999999501000275, -12.943964864999828],
              [179.530153501000086, -13.234684084999969],
              [179.044444501000271, -12.609445083999958],
              [178.734444500000222, -12.206945083999983],
              [178.668888500000207, -12.12083408399991],
              [178.56333350000034, -11.98277808399996],
              [178.308055500000137, -11.645834082999954],
              [178.246388500000194, -11.563889082999879],
              [177.316944499000101, -10.305000082],
              [177.291666499000257, -10.268056081999987],
              [177.186110499000165, -10.114723081999955],
              [177.100833499000032, -10.088056082000037],
              [176.229999498000097, -9.831111081999836],
              [176.086666498000341, -9.826667081999858],
              [175.94444449800028, -9.821945081999957],
              [175.864444498000353, -9.819167081999865],
              [174.949999497000135, -9.78305608199986],
              [174.567379496000171, -9.422885080999947],
              [174.234426996000366, -8.944094080999946],
              [174.233588496000266, -8.942781081000021],
              [174.205438496000284, -8.88162308099993],
              [174.162348496000163, -8.851216080999961],
              [173.751168496000105, -8.436717079999937],
              [173.750342996000199, -8.43559408],
              [173.749710091000338, -8.434750451999946],
              [173.749075764500162, -8.433843196499993],
              [173.748189955000271, -8.432949181999845],
              [173.416763478000348, -7.954022675999909],
              [173.416036060000238, -7.952434011999912],
              [173.414673223500245, -7.950382318499919],
              [173.413783310000326, -7.949014675999877],
              [173.402408436000087, -7.924154963999911],
              [173.171909835000179, -7.42040087],
              [173.171683960000223, -7.419527364000032],
              [173.170919354000034, -7.417855227999837],
              [173.10054312300008, -7.145682867],
              [172.955304837000199, -6.827825994999898],
              [172.923128495000128, -6.757407079000018],
              [172.878029134000144, -6.582882183999857],
              [172.777859719000276, -6.195246691000023],
              [172.732953758000235, -5.616708926],
              [172.732977499666845, -5.616093771666613],
              [172.758054641000285, -5.359215108999933],
              [172.789417885000034, -5.038471010999785],
              [172.945236575000308, -4.479659897999966],
              [173.084879375750006, -4.168747671749998],
              [174.306519907000052, -4.128064208999945],
              [174.408370699000216, -4.124778807999974],
              [174.527989501000206, -4.120920264999768],
              [174.549383951000209, -4.120230143999777],
              [174.587625876000288, -4.119186820999971],
              [174.673063835000107, -4.116855887999918],
              [175.037088980000249, -4.106873956000015],
              [175.117258210000045, -4.104675634999921],
              [175.132630394000216, -4.104254112999968],
              [175.158560869666871, -4.103543071666593],
              [175.313972847000173, -4.099108346999969],
              [175.98449018000008, -4.079991806999942],
              [176.057316419000244, -4.077992172999984],
              [176.145227879000117, -4.075456022000026],
              [176.26386651200005, -4.105480887999875],
              [176.395870431000276, -4.138888217999977],
              [176.578744497000116, -4.184850970999946],
              [176.727385880500151, -4.219612183999914],
              [176.844237355000018, -4.246814579999921],
              [177.00760492600034, -4.284845674],
              [177.31623846500031, -4.356314296999926],
              [177.557355064000262, -4.324248606999959],
              [177.564726664000318, -4.323271371000033],
              [177.57059610500005, -4.32249327299985],
              [178.119529917000136, -4.247783633999973],
              [178.532752402000142, -4.190958475],
              [178.750140095000233, -4.161063949999914],
              [179.434312922000117, -4.066556992999892],
              [179.751784009000176, -4.022263426999757],
              [179.892221433250143, -4.002669841499994],
              [179.916979501000014, -4.033560075999901],
              [179.951898501000301, -4.076896075999969],
              [179.974764501000095, -4.109261076],
              [179.999855501000297, -4.144774075999806],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "TWN", Country: "Taiwan" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [122.936210614000174, 21.947144372000139],
            [122.31881161400031, 21.728531372000134],
            [122.211068614000283, 21.690443372000175],
            [121.947943614000224, 21.597530372000222],
            [121.889597614000195, 21.576919372000134],
            [121.794441613000231, 21.542999372000054],
            [121.603592613000274, 21.474300372000243],
            [121.270763613000213, 21.352447372000142],
            [121.262252613000328, 21.342120372000295],
            [121.23154461300021, 21.304584372000136],
            [121.136677613000302, 21.198144372000286],
            [121.060725613000159, 21.138117372000295],
            [121.033922613000072, 21.119187372000056],
            [120.935774613000149, 21.049446372000148],
            [120.656176612000195, 20.856154371000287],
            [120.559561612000152, 20.788833371000237],
            [120.46495461200027, 20.715397371000108],
            [120.232684612000185, 20.535336371000142],
            [120.055239612000037, 20.43055637100008],
            [119.767536612000185, 20.366913371000066],
            [119.709462611000049, 20.353953371000159],
            [119.394502611, 20.283606371000189],
            [119.28256661100022, 20.265436371000135],
            [119.105209611000305, 20.26859837100011],
            [119.080221611000184, 20.269259371000089],
            [119.04824061100021, 20.219612371000096],
            [118.87767161100021, 19.954378371000189],
            [118.875311611000143, 19.950707371000192],
            [118.871415611000202, 19.944672371000024],
            [118.839173611000206, 19.894783370000198],
            [118.49698861000013, 19.366753370000083],
            [118.108881610000026, 18.764546369000186],
            [118.098549610000191, 18.75124936900005],
            [117.795806610000227, 18.389793369000188],
            [117.451343609000219, 18.014331369000104],
            [117.43265760900033, 17.9939573690001],
            [116.981613609000192, 17.704278368000075],
            [116.717968609000195, 17.534549368000171],
            [116.552992609000171, 17.420598368000185],
            [116.479215608000231, 17.366870368000036],
            [116.279875608000111, 17.384671368000113],
            [116.133428608000031, 17.406528368000068],
            [116.105702608000115, 17.437603368000168],
            [116.006309608000151, 17.534741368000084],
            [115.768374608000158, 17.748402368000143],
            [115.475284608000038, 18.010734369000033],
            [115.447483608000198, 18.035608369000215],
            [114.646751607000169, 18.744807369000114],
            [114.600525607000122, 18.785526369000024],
            [114.324549306000222, 19.027919940000061],
            [114.697750333000158, 19.859903555000187],
            [114.778808981000111, 20.039002396000058],
            [114.887922077000212, 20.27920863300011],
            [115.087245173000042, 20.706584854000084],
            [115.088111779000201, 20.708442767000065],
            [115.11411149800017, 20.764180354000132],
            [115.470459494000124, 21.324129526000149],
            [115.491423336000139, 21.356304977000036],
            [115.570996765000245, 21.396959933000119],
            [116.202871867000226, 21.716502858000183],
            [116.3454752560001, 21.752007277000189],
            [116.570073291000199, 21.807941624000023],
            [116.676961452000114, 21.834389108000153],
            [116.750428150000062, 21.840403423000168],
            [117.003735577000043, 21.860487248000197],
            [117.063510609000105, 21.865260152000246],
            [117.393139387000133, 21.891559605000111],
            [117.451784123000039, 21.896277038000107],
            [117.92169088900016, 21.805302464000135],
            [118.054835586000308, 21.779483811000034],
            [118.191846795000174, 21.752204945000187],
            [118.301129376000176, 21.730446835000066],
            [118.304119133000199, 22.043049570000221],
            [118.320570962000261, 22.413782509000043],
            [118.32346985800018, 22.479882768000095],
            [118.328066631000297, 22.571504669000205],
            [118.318334088000142, 22.955758444000026],
            [118.432601262000162, 23.292317138000101],
            [118.439459696000199, 23.310903071000041],
            [118.446023640000135, 23.324675923000086],
            [118.449930951000169, 23.332885665000106],
            [118.478181514000312, 23.400709632000087],
            [118.478804824000122, 23.402212932000111],
            [118.50376188000007, 23.462422298000092],
            [118.689564329000177, 23.68507175100018],
            [118.853414716000202, 23.883306742000173],
            [118.865273893000222, 23.891222906000223],
            [118.877882949000309, 23.90447356400017],
            [118.87934040600021, 23.906060716000241],
            [118.958040457000152, 23.991383749000192],
            [118.989452131, 24.029999189000137],
            [119.046125416000223, 24.106785277000142],
            [119.046781407000253, 24.107390785000121],
            [119.074531446000037, 24.132900608000057],
            [119.278009969000323, 24.327867097000137],
            [119.328765912000222, 24.350969334000183],
            [119.592591034000179, 24.382445248000181],
            [119.717972634000148, 24.397333346000153],
            [119.789119805000155, 24.40311854100014],
            [119.81660741200028, 24.424927529000058],
            [119.838021062000166, 24.445232462000092],
            [119.879123248000155, 24.484295055000132],
            [119.902470401000187, 24.509679992000031],
            [119.946071419000305, 24.564278827000066],
            [119.977441085000237, 24.611744498000121],
            [120.046611232000032, 24.715377821000232],
            [120.077769435500272, 24.766010090500117],
            [120.086084892000144, 24.774732934000099],
            [120.149882171000172, 24.844809890000249],
            [120.180823835000297, 24.879798225000172],
            [120.212112113000217, 24.903994062000095],
            [120.244883453000057, 24.929272796000191],
            [120.337671486000318, 25.000954600000114],
            [120.379395276000196, 25.038336314000105],
            [120.401667441000313, 25.067977836000182],
            [120.403013553000278, 25.069776029000025],
            [120.41008974000033, 25.079265933000102],
            [120.453214522000167, 25.138713234000136],
            [120.470400317000099, 25.168309374000216],
            [120.502714660000038, 25.22417165100012],
            [120.52721973600012, 25.27773097000005],
            [120.535350370000288, 25.295661667000161],
            [120.551338772000122, 25.331731358000184],
            [120.557658522000224, 25.346226329000046],
            [120.562413645000333, 25.357220888000143],
            [120.56627531100014, 25.363169370000094],
            [120.579562526000018, 25.382756279000034],
            [120.652761019000081, 25.494036880000124],
            [120.665750083000148, 25.513893100000132],
            [120.69409045600014, 25.557668417000173],
            [120.708211573000085, 25.579599318000135],
            [120.710279005000075, 25.583059350000042],
            [120.72197308900013, 25.60259226300019],
            [120.786041772000317, 25.635513381000067],
            [120.857361796000305, 25.672995144000026],
            [120.879883183000175, 25.685066792000214],
            [120.936665780000226, 25.720387573000096],
            [121.01672172200017, 25.795493576000069],
            [121.0337880000003, 25.81223737900018],
            [121.175478560000215, 25.958567512000172],
            [121.210198720000221, 25.984348273000194],
            [121.333497405000344, 26.078848950000122],
            [121.353969851000215, 26.113542341000141],
            [121.5068244280003, 26.372418656000093],
            [121.510718202000021, 26.378980928000203],
            [121.615304255000297, 26.450057121000157],
            [121.960284439000134, 26.683875384000203],
            [122.009942127000159, 26.717625099000145],
            [122.078487258000337, 26.74573040700011],
            [122.446578174000138, 26.896015637000119],
            [122.607522531000313, 26.950310319000096],
            [122.646802295000157, 26.780702919000078],
            [122.699844265000138, 26.550941319000117],
            [122.726954591000322, 26.31501376000017],
            [122.788135074000024, 25.761118902000192],
            [122.78831292000018, 25.759470017000126],
            [122.788409264000165, 25.758570275000068],
            [122.799734683000111, 25.651933912000061],
            [122.838929477000192, 25.485668778000218],
            [122.894102414000031, 25.255099312000141],
            [122.90009616500015, 25.229875222000146],
            [122.745906568000123, 25.123404420500037],
            [122.668825932000345, 25.012092216000042],
            [122.666591059000211, 25.008851976000187],
            [122.575746951000156, 24.875529499000152],
            [122.565646677000132, 24.860940060000132],
            [122.531616146000147, 24.814228339000067],
            [122.416187526000158, 24.577618974000245],
            [122.401274383000214, 24.493621700000119],
            [122.396276574000126, 24.44509918600005],
            [122.396162602000345, 24.443998283000042],
            [122.392998413000214, 24.413983732000247],
            [122.382001622000075, 24.150545904000239],
            [122.402802027000121, 23.998613588000069],
            [122.409449681000183, 23.949155698000084],
            [122.41004786200017, 23.944668826000111],
            [122.413092893000226, 23.927570783000192],
            [122.42056029000014, 23.886336468000195],
            [122.47994240100013, 23.741603642000086],
            [122.498255953000125, 23.697401350000121],
            [122.522594456000206, 23.649015599000109],
            [122.550704843000176, 23.594627834000164],
            [122.585221175000015, 23.53624394100018],
            [122.635313431000213, 23.449853064000138],
            [122.652484648000154, 23.423633782000024],
            [122.654908923000335, 23.419881493000162],
            [122.682416488000172, 23.379820183000163],
            [122.749825674000022, 23.292118354000195],
            [122.82125591400029, 23.115487821000045],
            [122.832888836000166, 23.075791852000179],
            [123.005113172000165, 22.83345732000015],
            [123.113334589000033, 22.682504164000136],
            [123.129913734000183, 22.659309463500108],
            [123.224417122000091, 22.527030533000129],
            [123.373233447000189, 22.390114640000093],
            [123.422551685000144, 22.346462449000057],
            [123.521648781000181, 22.258140235000042],
            [123.530918580000161, 22.24987834600006],
            [123.585420708000242, 22.201050767000083],
            [123.609937251000247, 22.17896031700019],
            [122.948963615000139, 21.95161237200017],
            [122.94657661500014, 21.950777372000175],
            [122.944191615000136, 21.949943372000291],
            [122.941948614000125, 21.949157372000286],
            [122.939706614000215, 21.948371372000054],
            [122.936210614000174, 21.947144372000139],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "TZA", Country: "Tanzania" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.921100151468039, -1.001945928211171],
            [33.948327087628883, -1.001108911357747],
            [34.018745132783039, -1.001108911357747],
            [34.075554152265653, -1.039163925346998],
            [34.409718169303716, -1.227217958583807],
            [34.500273233978959, -1.277781957268957],
            [34.717218178799754, -1.398890903122847],
            [35.268882190288224, -1.707217871188419],
            [35.678327097440359, -1.935835789679885],
            [35.880827173202334, -2.048335850396299],
            [36.033282257725119, -2.13308192087878],
            [36.153327204786905, -2.199999850895296],
            [36.325554025489367, -2.298335836310102],
            [36.685273291914285, -2.49694603843551],
            [37.195827085697516, -2.778890966182601],
            [37.222582288346274, -2.794454986956566],
            [37.278609108622419, -2.825555032947506],
            [37.425282188680484, -2.906945995217484],
            [37.501245198076987, -2.938054926025927],
            [37.522500195846732, -2.949163965249468],
            [37.602773179867967, -2.995835911003297],
            [37.675282174594287, -3.051390997767371],
            [37.698054129204962, -3.186664018922784],
            [37.712491286912694, -3.267499931562952],
            [37.719991246257024, -3.311945979103299],
            [37.690554169860576, -3.316663984775843],
            [37.610273306850303, -3.401526898988834],
            [37.600136065483611, -3.424717948759522],
            [37.600000278651777, -3.450281915325704],
            [37.626382151332024, -3.509517831060464],
            [37.686391213817927, -3.512499944580497],
            [37.737354191095363, -3.528754969454084],
            [37.750000136089483, -3.550690913039659],
            [37.756382284823161, -3.578608853300125],
            [37.764854042024325, -3.615282026728124],
            [37.78305417135428, -3.651108963209111],
            [37.851391157582356, -3.700554983645617],
            [38.417500205767652, -4.105835928926638],
            [38.48666331237493, -4.154999982139529],
            [38.624445204795933, -4.253335799916343],
            [38.721109168160666, -4.322499912352029],
            [39.037773280061998, -4.549999852594951],
            [39.161663341397258, -4.639445991477061],
            [39.203027195246676, -4.669617825753932],
            [39.349443898500255, -4.832221885999928],
            [39.477778, -4.891999999999896],
            [39.605, -4.681111],
            [43.192472232000142, -4.68101144799995],
            [43.212197908000149, -4.891972086999985],
            [43.211890534000105, -4.895723969],
            [43.212238155000165, -4.899428346],
            [43.211859900000064, -4.904045278],
            [43.212270652500166, -4.908811740499985],
            [43.21221928300011, -4.909438462000011],
            [43.212296660000135, -4.910262706],
            [43.211548284000202, -4.919401401999934],
            [43.211477566000127, -4.920267902999953],
            [43.211557725000176, -4.921121957000011],
            [43.21145597100022, -4.922364204999852],
            [43.211564650000213, -4.923669377499976],
            [43.215923590000017, -4.970241407999865],
            [43.215860357500077, -4.971149761999982],
            [43.215932721000144, -4.971920461999972],
            [43.212141156000058, -5.018238199999914],
            [43.212851760000063, -5.025799216999971],
            [43.215695855000178, -5.056061082999989],
            [43.215490092000124, -5.058576221],
            [43.215723452000162, -5.061058605999946],
            [43.211301069000086, -5.115136245999977],
            [43.205924658000214, -5.180879648999962],
            [43.206401604000092, -5.186426580399939],
            [43.206041789000068, -5.191512947499888],
            [43.206178821000123, -5.192968729999947],
            [43.206468945000125, -5.196050995999983],
            [43.206364961000162, -5.197323342999923],
            [43.206453670500167, -5.198265964499981],
            [43.159292278000095, -5.77708357799996],
            [43.157983693000091, -5.782067904999977],
            [43.156412919000132, -5.788050894999941],
            [43.155335346000157, -5.801286524999938],
            [43.152340908000127, -5.81269245099989],
            [43.152116476000202, -5.815449269999817],
            [43.12265610200015, -5.927660157999881],
            [43.229400846000118, -6.312825838],
            [43.284670721000026, -6.890582088999864],
            [43.284605030000108, -6.89139926499989],
            [43.284682015000072, -6.892201049999883],
            [43.246440443000068, -7.36846805599987],
            [43.2717332120001, -7.630274710999885],
            [43.271060379000033, -7.638691631999976],
            [43.271533417000086, -7.643578937999877],
            [43.271859283000055, -7.646945901999928],
            [43.270846721000026, -7.659614838999929],
            [43.271072218000114, -7.661943834999988],
            [43.270525918000118, -7.744194317999941],
            [43.262188556000211, -7.774065666999874],
            [43.25385002800013, -7.803936582999967],
            [43.245510333000055, -7.833807305999855],
            [43.237169417000104, -7.863677833],
            [43.228827333000169, -7.893548138999947],
            [43.22048405600006, -7.923418221999867],
            [43.212139556000096, -7.953288110999964],
            [43.203793833000219, -7.983157805999852],
            [43.195446917000169, -8.013027249999908],
            [42.738473978000258, -8.510414871499975],
            [42.636769273000112, -8.787291589999938],
            [42.575823177000217, -8.956265079999966],
            [42.538980843000019, -9.063995185999829],
            [42.221581920000148, -10.091388889000015],
            [41.033611111000113, -10.091388889000015],
            [40.66861111100016, -10.312777777999841],
            [40.492777778000089, -10.414722221999909],
            [40.438611109000163, -10.467777776999924],
            [40.43680914810048, -10.478172974178435],
            [40.427773288914267, -10.499446077037703],
            [40.381382304555558, -10.540282038870259],
            [40.346109244238079, -10.564372969768243],
            [40.158882169570887, -10.677499996843935],
            [40.019718101312463, -10.779163933104925],
            [39.962773294998129, -10.808891023351833],
            [39.942218186145425, -10.814999922041224],
            [39.898327188234958, -10.831663983790918],
            [39.868327183219748, -10.848608839297839],
            [39.839027234759754, -10.866108912072562],
            [39.81194513788617, -10.885554927493772],
            [39.795273197147623, -10.901808946538964],
            [39.766936161726989, -10.920555073043218],
            [39.691245061270394, -10.944235793598807],
            [39.663891223095135, -10.946108981325693],
            [39.522909120032836, -10.99028194645966],
            [39.503891252226794, -10.997635893050273],
            [39.391391191510451, -11.078055057463402],
            [39.315282169360245, -11.135281998639357],
            [39.292636110935661, -11.152363982082662],
            [39.262500151450553, -11.169445965525696],
            [39.233054190236857, -11.17416397119824],
            [39.20596320854591, -11.173190999874905],
            [39.182636204305396, -11.15944601973338],
            [39.136100213021479, -11.14236403629026],
            [38.9313911704904, -11.165555086060763],
            [38.905691249454293, -11.170135796158931],
            [38.67249127976018, -11.270835813551997],
            [38.655273174208986, -11.281108841750694],
            [38.635136154687956, -11.294445958482683],
            [38.571109143084726, -11.342781879659384],
            [38.533882261131481, -11.372499917451066],
            [38.491709218100738, -11.415308979296682],
            [38.416800149212435, -11.397226867163738],
            [38.377782222183754, -11.377226807941028],
            [38.357218228513631, -11.364308954007114],
            [38.333882171817805, -11.347917974663616],
            [38.173609118543169, -11.284999888853704],
            [38.096691242734408, -11.256326906752804],
            [38.03277319587275, -11.260835867759681],
            [37.962491272826497, -11.278917812254363],
            [37.934436204629634, -11.288054924931501],
            [37.900273243571604, -11.304446071912992],
            [37.865136137723965, -11.332781933867068],
            [37.819163242696931, -11.451108926049258],
            [37.810200138330316, -11.483817962178392],
            [37.822700238208796, -11.51007293284421],
            [37.816936171016977, -11.536254980952435],
            [37.791936138898024, -11.561108832679608],
            [37.693327238714943, -11.602781979003666],
            [37.597218157341928, -11.6397178412778],
            [37.473209240619354, -11.718708897023959],
            [37.39958226764449, -11.723190868302339],
            [37.267773319442966, -11.702781939841515],
            [37.190827112801372, -11.689999872739335],
            [37.118954136889812, -11.670072903712565],
            [36.997773274306468, -11.591390972556326],
            [36.826945057971813, -11.572190882174922],
            [36.785827128915543, -11.617499930468526],
            [36.766109204554454, -11.643608888380811],
            [36.746109145331644, -11.663890914827135],
            [36.725000160315602, -11.681946037231768],
            [36.677773164931949, -11.714726822452093],
            [36.557427139907162, -11.740417858670952],
            [36.517491226840065, -11.720346050356682],
            [36.375545204909969, -11.68389097405003],
            [36.342500222100767, -11.696108939066903],
            [36.293882166062104, -11.707499945514058],
            [36.26721815852008, -11.709446055798892],
            [36.187636178598467, -11.705417880759498],
            [36.17458220255665, -11.668817965165616],
            [36.177773193104457, -11.643608888380811],
            [36.168191169005553, -11.585835782392365],
            [35.938191242314673, -11.432781894704917],
            [35.828473302908009, -11.416873042433465],
            [35.733327134574523, -11.48333583510626],
            [35.672491281157164, -11.558335931463319],
            [35.648054177657031, -11.587082003759889],
            [35.573327163706182, -11.607081895344606],
            [35.437073292238409, -11.593755004534685],
            [35.238609102866718, -11.575835836599964],
            [35.011109162623967, -11.573335850151793],
            [34.966727152824063, -11.572108907161564],
            [34.964436211041772, -11.508890916855123],
            [34.957636140614483, -11.481945947917893],
            [34.890545040477917, -11.383055080511284],
            [34.863473169526316, -11.357226916356211],
            [34.802491135717247, -11.331808962543732],
            [34.70888220843031, -11.199026875380994],
            [34.638673207941821, -11.137017890791867],
            [34.616036201972719, -11.110763925954359],
            [34.593118234608227, -11.020972955574678],
            [34.599582190717143, -10.981526880930488],
            [34.627218163754122, -10.951664003851661],
            [34.64138223905573, -10.92667302418802],
            [34.649991291831299, -10.890281985621812],
            [34.663054152690592, -10.76110897833847],
            [34.663054152690592, -10.728890954098702],
            [34.657909172869012, -10.664172938395836],
            [34.651382184848131, -10.643890911949427],
            [34.621109265064803, -10.606945997219796],
            [34.585000193722152, -10.561672991109816],
            [34.580827179395584, -10.538890978215321],
            [34.580227202764632, -10.534463992221802],
            [34.575827206499326, -10.516672899768054],
            [34.565554178300744, -10.403055028440988],
            [34.569163258180112, -10.367781968123424],
            [34.573327220051254, -10.346672983107482],
            [34.534854116730486, -10.045973044038632],
            [34.52458226199829, -10.015554949691818],
            [34.500545142918753, -9.96902683739691],
            [34.482773328842399, -9.951390977790368],
            [34.426663360086678, -9.88139102196773],
            [34.373891232822729, -9.811673033368606],
            [34.329645177492722, -9.740208926695189],
            [34.205827200524908, -9.627499988950916],
            [34.176382245139592, -9.603608882625196],
            [34.13636335123087, -9.571172928902442],
            [34.074436174016995, -9.507781936113972],
            [34.041527145677634, -9.483755042956247],
            [34.002700158403769, -9.487781876891077],
            [33.957218107628051, -9.526672901905457],
            [33.949300226590282, -9.551808888494321],
            [33.952427179397631, -9.578399973478469],
            [33.960273311344025, -9.604446067116712],
            [33.958609168282891, -9.628608914744248],
            [33.944509130721798, -9.686254951080201],
            [33.918818094503052, -9.706735796270394],
            [33.900273301313604, -9.684164001508137],
            [33.871382222091739, -9.659726898008003],
            [33.762909162948148, -9.585135838527137],
            [33.578891183739728, -9.584717916833711],
            [33.54943617007055, -9.594999997487719],
            [33.531527228057797, -9.613890963279204],
            [33.493473219896856, -9.622081926723254],
            [33.429163235241589, -9.609655084678707],
            [33.417918241548165, -9.578472896036331],
            [33.398054136795253, -9.542845951765699],
            [33.31193611639722, -9.486045984738567],
            [33.254718227676591, -9.492226800157425],
            [33.199645100346544, -9.507635923360169],
            [33.128954140424213, -9.491872916204443],
            [33.03196328283471, -9.419046079708878],
            [33.019391266226677, -9.399754961858619],
            [33.001245116353374, -9.37667304446768],
            [32.967773327033257, -9.384163951356513],
            [32.943336055895116, -9.399863926600204],
            [32.94040021048059, -9.405081996617611],
            [32.915273276347165, -9.390281902501314],
            [32.874718108271821, -9.377226920631017],
            [32.844163221264893, -9.373890923157816],
            [32.767773237719467, -9.33249991169545],
            [32.702773254792902, -9.282217880233915],
            [32.628327202237443, -9.274172929543582],
            [32.540063246982385, -9.261181985413728],
            [32.505482196592965, -9.217290987503191],
            [32.49069115493208, -9.176735819427776],
            [32.421245075272765, -9.129026864610168],
            [32.365554034038979, -9.132499990019753],
            [32.301663312181773, -9.134726894061544],
            [32.258609163486511, -9.133335833406704],
            [32.221382281533266, -9.120282025002794],
            [32.1997182468875, -9.099726916150175],
            [32.160345094801158, -9.064445976843686],
            [32.094936074998571, -9.058717951835504],
            [32.086391227601467, -9.051946044603113],
            [32.058745196280626, -9.041526836012807],
            [32.029927207254701, -9.046317931881291],
            [32.002636065715222, -9.068335850480125],
            [31.981663202807198, -9.070626959900537],
            [31.939300225849792, -9.031254981280682],
            [31.93278229028428, -9.009726901104642],
            [31.931800266505576, -8.973890912168343],
            [31.938327086888393, -8.953055009558412],
            [31.952082292951985, -8.936254993338991],
            [31.897782312372925, -8.914163984544217],
            [31.835000181032825, -8.897781890018138],
            [31.787382253684001, -8.887682031939548],
            [31.741245073354293, -8.912754986616463],
            [31.706391275834704, -8.917081892685729],
            [31.683609262940223, -8.908890929241679],
            [31.592782289325072, -8.839726816806078],
            [31.573954020169452, -8.817781988402999],
            [31.563891210103094, -8.75139094482158],
            [31.48555411044461, -8.666390902672205],
            [31.414718143596843, -8.633608943985394],
            [31.295554134561229, -8.619999918313596],
            [31.226800232468037, -8.579717832644377],
            [31.202773171672305, -8.578890874074844],
            [31.17027318020908, -8.59528202105642],
            [31.142291202208099, -8.612845963933509],
            [31.110418177104094, -8.61389101962429],
            [31.078891156964147, -8.607499986073208],
            [31.059445141542852, -8.599726944322768],
            [31.032636127075449, -8.584999940402227],
            [30.97694508584155, -8.547499976042786],
            [30.960973201658049, -8.53221792249262],
            [30.907500179648736, -8.467781874013269],
            [30.852773224920753, -8.381672906070747],
            [30.835836080764835, -8.352917948956772],
            [30.794163269716847, -8.265555048295695],
            [30.770836097838384, -8.198335872678271],
            [30.77123608225898, -8.192246084728083],
            [30.759445091391143, -8.144999978605142],
            [30.707218123111232, -7.994999953529373],
            [30.69694509491265, -7.970281888633849],
            [30.668891200182173, -7.914999884276156],
            [30.650554110553941, -7.880417828058313],
            [30.617500242927321, -7.833890889229792],
            [30.577500124481872, -7.778055008708677],
            [30.520554144700981, -7.696390964032503],
            [30.509718187883522, -7.679445940887589],
            [30.471391264954462, -7.613472986637774],
            [30.45582707654259, -7.58028199107477],
            [30.448609252059981, -7.559717829766655],
            [30.439718232060812, -7.529999959613235],
            [30.431945190310358, -7.484999868271473],
            [30.42944503622428, -7.45597300221759],
            [30.4261092063891, -7.427781979550744],
            [30.420827098631236, -7.405281933879863],
            [30.409445144639477, -7.364308844110923],
            [30.37916334003873, -7.279726891293151],
            [30.354445107505313, -7.220281930892639],
            [30.338054128161815, -7.187499972205828],
            [30.315000206327596, -7.143754987049093],
            [30.246663220099521, -7.043608845819364],
            [30.226663328514718, -7.014999901459092],
            [30.18832718549217, -6.962499850773],
            [30.172082218902545, -6.94416393461124],
            [30.141663286365514, -6.919163902492372],
            [30.070554069473388, -6.851391018349602],
            [30.064854040022027, -6.845763911456089],
            [30.037154029244647, -6.822635893597536],
            [30.002500223935414, -6.802217912681215],
            [29.984163301945244, -6.792781901335943],
            [29.950000173249322, -6.778890908440616],
            [29.914718228114424, -6.766108841338436],
            [29.885418112016254, -6.750835840243766],
            [29.76139125802078, -6.653890915483814],
            [29.739163288927699, -6.634582027998718],
            [29.720418168251939, -6.611808899921726],
            [29.703891234438544, -6.586390946109248],
            [29.61305403490158, -6.39972680006089],
            [29.567218100172767, -6.33125503282939],
            [29.550282297121356, -6.295281915956608],
            [29.540836059854399, -6.263055006899521],
            [29.498336122598772, -6.056390969266445],
            [29.495000125125586, -6.008890891476696],
            [29.497773193979953, -5.979726897486515],
            [29.504718103694387, -5.945835845368393],
            [29.516391245003234, -5.92305500594037],
            [29.538327188588909, -5.889999964847277],
            [29.570000221482559, -5.851945956686322],
            [29.592082177821936, -5.82847293969202],
            [29.608745066105058, -5.80721794192236],
            [29.630000231512867, -5.754863903990071],
            [29.631663201107614, -5.721945990833177],
            [29.626109184409955, -5.685554952266969],
            [29.597500240049584, -5.579163957878123],
            [29.573054084093968, -5.52055500850264],
            [29.562218127276623, -5.496390987408617],
            [29.543891263570373, -5.466390982393506],
            [29.534445193941366, -5.448055066231831],
            [29.527218149365268, -5.427499957379212],
            [29.513609123693556, -5.385555069753423],
            [29.487218198557656, -5.293608944423056],
            [29.476109159334015, -5.236663970470715],
            [29.454300117762756, -5.176663960440393],
            [29.43000014219902, -5.147499966450297],
            [29.414163206657037, -5.12528188800303],
            [29.403327082201514, -5.101390949315302],
            [29.3797181107374, -5.041108972061366],
            [29.365273241678807, -4.999999927822429],
            [29.363054048987777, -4.995281922149886],
            [29.351391133600742, -4.951390924239448],
            [29.34790912337391, -4.930417893693217],
            [29.341945063971849, -4.883890954864796],
            [29.340827085723191, -4.850281869970274],
            [29.341391187808398, -4.797781986922274],
            [29.347773168904013, -4.763335885174328],
            [29.358745080191255, -4.729726800279721],
            [29.37180022969963, -4.70166402073194],
            [29.397782285597515, -4.638054930822122],
            [29.41110917640745, -4.603335914305973],
            [29.420273278812971, -4.578335882187105],
            [29.429718174975477, -4.545072969894548],
            [29.430273224605315, -4.501108881788255],
            [29.426936053665628, -4.455835875678289],
            [29.423336193879805, -4.447499905308931],
            [29.430827100768653, -4.44805495493874],
            [29.672500168768494, -4.446390979515698],
            [29.766391230917037, -4.438055009146424],
            [29.935273169328951, -4.334164001205565],
            [30.026109195399528, -4.269445985502614],
            [30.174582205350561, -4.082081950537216],
            [30.224718224058478, -3.965281973479762],
            [30.226800121175046, -3.933608940586097],
            [30.31971821200014, -3.788755068798196],
            [30.343054101058073, -3.770973028799858],
            [30.372354049518066, -3.772846048888752],
            [30.400836091863852, -3.786108901958229],
            [30.403891127941819, -3.765972888265622],
            [30.391391195701345, -3.711108973239433],
            [30.396663245175404, -3.689163977198362],
            [30.448473297590141, -3.551045970459128],
            [30.502218228539306, -3.505555034866077],
            [30.534445137596293, -3.489164055522579],
            [30.591527071847025, -3.460763988190067],
            [30.625409239147729, -3.454517961564292],
            [30.663473305592476, -3.416808952539085],
            [30.666036156314618, -3.394655079470255],
            [30.649300177835443, -3.37854606735047],
            [30.632273179677298, -3.373626896001099],
            [30.625545193617626, -3.371726886183978],
            [30.635554024227389, -3.350555036893965],
            [30.653191224938411, -3.332154915353769],
            [30.738327221557682, -3.282781985113118],
            [30.766109207348421, -3.299099874260676],
            [30.803054122078066, -3.283608943682736],
            [30.833609176723002, -3.259026833257295],
            [30.853191146614193, -3.15749985729461],
            [30.833400132057221, -3.086255021208828],
            [30.837227141419788, -2.994726817572072],
            [30.843663269610005, -2.978790975572309],
            [30.748609134573684, -2.990554976711834],
            [30.661527195307855, -2.974163997368521],
            [30.652354040446966, -2.953955061117952],
            [30.612782237254777, -2.918890877828062],
            [30.559100170579683, -2.894999939140419],
            [30.494827066298484, -2.949035889768581],
            [30.485000122988112, -2.947217854964819],
            [30.416945103983693, -2.856046049852083],
            [30.479718182868424, -2.591390976747391],
            [30.522082165654211, -2.461599887922006],
            [30.573327110155191, -2.399163929184056],
            [30.586245131727168, -2.387635794800417],
            [30.706245151787897, -2.347782024384685],
            [30.726391223764523, -2.35513580333722],
            [30.782218219468206, -2.380554930616086],
            [30.830763352948935, -2.354308844767601],
            [30.845827141739818, -2.325691015589911],
            [30.851800253597418, -2.297917914616576],
            [30.848745049881444, -2.261108954356857],
            [30.843191200821792, -2.224164039627226],
            [30.848191173718106, -2.18971793787928],
            [30.886182149966913, -2.097290858943524],
            [30.893263349427315, -2.075490869827775],
            [30.871427150489779, -2.038263987874529],
            [30.808327177380392, -1.929445929595275],
            [30.82471815672389, -1.846663906670457],
            [30.835000237377898, -1.80055505717354],
            [30.818327123172736, -1.728755003819771],
            [30.81110913105212, -1.700554928697613],
            [30.830936187792844, -1.654899875439853],
            [30.748891269435404, -1.517081940835041],
            [30.739163232582797, -1.43694608475009],
            [30.686109138095304, -1.387217929451978],
            [30.637500134512123, -1.389446006960256],
            [30.564718225017685, -1.330690877193064],
            [30.470554080462961, -1.160690960532406],
            [30.454445068343176, -1.101391007057288],
            [30.466945168221656, -1.074999914283396],
            [30.482218169316383, -1.063335825429945],
            [30.593327169378057, -1.065555018120889],
            [30.69694509491265, -1.014581982559548],
            [30.756382176324138, -0.997754976611631],
            [30.888609213857166, -0.997217864254736],
            [31.060000191172577, -0.997499999116428],
            [31.272500212726897, -0.99833584250338],
            [31.293054148113157, -0.99889089213319],
            [31.546945181129871, -0.999445941763],
            [31.677218229389126, -0.999717850702808],
            [32.485273260342382, -1.000281952788029],
            [32.615554187590675, -1.000281952788029],
            [32.684163250396551, -0.999999985564415],
            [32.742418148180974, -0.999717850702808],
            [32.890354045775069, -0.999708965885404],
            [33.001109161883591, -0.999999985564415],
            [33.110836153745623, -1.000281952788029],
            [33.186391132094428, -0.999717850702808],
            [33.29610018668356, -0.999999985564415],
            [33.405827178545763, -1.000281952788029],
            [33.438873167183289, -1.00071781175437],
            [33.460827215679927, -1.000281952788029],
            [33.920273192898406, -1.001108911357747],
            [33.921100151468039, -1.001945928211171],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "UGA", Country: "Uganda" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.921100151468039, -1.001945928211171],
            [33.920273192898406, -1.001108911357747],
            [33.460827215679927, -1.000281952788029],
            [33.438873167183289, -1.00071781175437],
            [33.405827178545763, -1.000281952788029],
            [33.29610018668356, -0.999999985564415],
            [33.186391132094428, -0.999717850702808],
            [33.110836153745623, -1.000281952788029],
            [33.001109161883591, -0.999999985564415],
            [32.890354045775069, -0.999708965885404],
            [32.742418148180974, -0.999717850702808],
            [32.684163250396551, -0.999999985564415],
            [32.615554187590675, -1.000281952788029],
            [32.485273260342382, -1.000281952788029],
            [31.677218229389126, -0.999717850702808],
            [31.546945181129871, -0.999445941763],
            [31.293054148113157, -0.99889089213319],
            [31.272500212726897, -0.99833584250338],
            [31.060000191172577, -0.997499999116428],
            [30.888609213857166, -0.997217864254736],
            [30.756382176324138, -0.997754976611631],
            [30.69694509491265, -1.014581982559548],
            [30.593327169378057, -1.065555018120889],
            [30.482218169316383, -1.063335825429945],
            [30.476754174259185, -1.059326928767945],
            [30.451945081894991, -1.054445978897178],
            [30.360827088600928, -1.060972966918172],
            [30.315354090280579, -1.143764042298486],
            [30.297773215959097, -1.184717853690032],
            [30.283473186187649, -1.209655021535028],
            [30.229163314962904, -1.266664033227912],
            [30.171663291380554, -1.314999954404783],
            [30.109163294902118, -1.367499837452797],
            [29.979718211040819, -1.462217858170874],
            [29.93666322415541, -1.475835936298083],
            [29.929718146802912, -1.476108851066201],
            [29.910000222441766, -1.4650729020385],
            [29.890554039382295, -1.432781955240969],
            [29.884509178433376, -1.401808978902523],
            [29.884163341107381, -1.370417913232387],
            [29.855273267713841, -1.326946010481848],
            [29.8352822609464, -1.319717960077327],
            [29.717218125248536, -1.34749994586808],
            [29.697773283293742, -1.367499837452797],
            [29.677500141664638, -1.38166391275432],
            [29.640827135874787, -1.386664053288555],
            [29.617363338974116, -1.387081974982081],
            [29.596945190419802, -1.38583592125255],
            [29.606109125187146, -1.320690931400662],
            [29.599027087536257, -1.214373027207571],
            [29.574300137823599, -1.18749997499981],
            [29.575136148848543, -0.916391004070064],
            [29.584445090541323, -0.895690888292052],
            [29.618891192289169, -0.891599848978686],
            [29.63305409412439, -0.787781931233766],
            [29.619300229165361, -0.743473011629803],
            [29.63471823718541, -0.641390986037308],
            [29.666663346656975, -0.580554964981957],
            [29.652773191951979, -0.476663957041097],
            [29.648609230080837, -0.43971786884498],
            [29.670827140889941, -0.304717930095677],
            [29.687763279217535, -0.202781917256985],
            [29.709000172076372, -0.074445926826883],
            [29.733327137368434, -0.003164042728983],
            [29.718191264210049, 0.042845062119838],
            [29.718609185903489, 0.077082119202032],
            [29.731945129169077, 0.104718092238969],
            [29.780482216022705, 0.173473167798647],
            [29.806945057887873, 0.162782050268447],
            [29.839445049351156, 0.277500130209418],
            [29.85832713032525, 0.366664134229922],
            [29.912445055966856, 0.429044940044761],
            [29.959863326381509, 0.483745072682495],
            [29.955827104715098, 0.551664137217145],
            [29.938745121271978, 0.624582168819458],
            [29.94750018680125, 0.787782182690933],
            [29.957500132593651, 0.818336063869467],
            [29.967109146420768, 0.835909059201967],
            [29.986727158495768, 0.846709141473752],
            [29.990836135081878, 0.850553920471057],
            [30.01582711474552, 0.866664106057328],
            [30.036109141191929, 0.874718109203073],
            [30.086391172653379, 0.893335992588362],
            [30.131182219329474, 0.895209012677256],
            [30.155554111622763, 0.909100005572583],
            [30.217363271639528, 0.994445046857521],
            [30.219309214286199, 1.043609100070412],
            [30.213745139304734, 1.065000052310069],
            [30.234373170715202, 1.130836046261663],
            [30.27458216618848, 1.167082077902563],
            [30.370827202031393, 1.198336015636229],
            [30.437636167306238, 1.202153972543385],
            [30.456109211404311, 1.212782058161466],
            [30.483891197195049, 1.24124498976802],
            [30.548882295304082, 1.321664154181065],
            [30.671391186630359, 1.473336033668986],
            [30.695482285166321, 1.500000041210996],
            [30.848327128187947, 1.618891135478506],
            [31.036663296286321, 1.765553989614773],
            [31.209718249024917, 1.987500080798014],
            [31.302773300148345, 2.121391093754085],
            [31.299200262452672, 2.162854021946004],
            [31.202500256904187, 2.229164096342458],
            [31.069163287749603, 2.325282062532793],
            [31.003054043753735, 2.396944987950178],
            [30.984863302155389, 2.409309133358832],
            [30.964027231907636, 2.411945074276815],
            [30.939718203888305, 2.400282158889766],
            [30.926936136786225, 2.360836084245662],
            [30.914718171769351, 2.342500168083902],
            [30.886245181878991, 2.336809023449945],
            [30.729718168782114, 2.448053977981417],
            [30.730973274967056, 2.473335977323998],
            [30.755973307086123, 2.601109039135167],
            [30.786945109958168, 2.674718074837301],
            [30.81721819737956, 2.739164181600358],
            [30.851663293299197, 2.785482075763213],
            [30.876527203310189, 2.840000153463322],
            [30.879445111451702, 2.885900125932565],
            [30.852354129760755, 2.962844991469723],
            [30.80624511262576, 3.013609150003305],
            [30.772363280601127, 3.028745023161775],
            [30.763473266430452, 3.051736080722023],
            [30.782218219468206, 3.130000090184652],
            [30.828054154197019, 3.240282131676508],
            [30.859718134635187, 3.290282195914514],
            [30.882773229935964, 3.324718071740662],
            [30.934445148585866, 3.413891128216576],
            [30.924791207757579, 3.510764136247062],
            [30.905973164523886, 3.52471799341636],
            [30.858818085869672, 3.493391133124831],
            [30.868536064438416, 3.522573064387743],
            [30.891391167528838, 3.565553955249001],
            [30.95471812257685, 3.671109106250995],
            [30.979163272704085, 3.694444995308913],
            [31.176663207931796, 3.795282140638179],
            [31.203891149920963, 3.795836016801601],
            [31.286663282200124, 3.795000005776572],
            [31.377218179237417, 3.745553985340067],
            [31.459718234938606, 3.698891092041649],
            [31.540836114802374, 3.653609033476272],
            [31.704027076218466, 3.714445054531623],
            [31.748609245866675, 3.761391088520142],
            [31.789445040061167, 3.808744985918182],
            [31.821663231938913, 3.806945056025327],
            [31.853473225130898, 3.784718092760642],
            [31.932082233729176, 3.689164228655528],
            [31.946391148317929, 3.621664091642884],
            [31.951109153990529, 3.593891158307642],
            [32.025836167941321, 3.59139117185947],
            [32.068491170406134, 3.582218016998695],
            [32.077082285908858, 3.559026967228007],
            [32.090409176718907, 3.5361089998637],
            [32.111245079328711, 3.526664103701194],
            [32.135827189754167, 3.521109081175041],
            [32.194527166598419, 3.512754000066479],
            [32.200827172680732, 3.575036067061802],
            [32.199773232172532, 3.609727088021231],
            [32.404445059053245, 3.743609048521805],
            [32.479163355824682, 3.745835952563681],
            [32.635273285418322, 3.751945018891064],
            [32.669163331708063, 3.753336079546003],
            [32.71944519553162, 3.755836065994075],
            [32.800282281638175, 3.780553963251435],
            [32.874718108271821, 3.803891025775741],
            [32.898045112512222, 3.811945028921485],
            [32.93832719818144, 3.837217975808557],
            [32.978609116212596, 3.863335986176253],
            [33.016663292011543, 3.888609100701501],
            [33.127218248271703, 3.808335949042089],
            [33.183882260828824, 3.766109094192686],
            [33.245000249107903, 3.764164157374324],
            [33.387773229607632, 3.758053917580554],
            [33.511236149155849, 3.752700060731229],
            [33.523609179381964, 3.758473180378544],
            [33.996663344081838, 4.222782170194961],
            [34.026245092127368, 4.208053992808033],
            [34.049445194353467, 4.19055392003331],
            [34.063045167569754, 4.173609064526573],
            [34.088054084506126, 4.097218075152753],
            [34.128327117719863, 3.951809153735027],
            [34.184718215508866, 3.840000097118335],
            [34.222218179868406, 3.779164076062983],
            [34.361109165720535, 3.738054025995737],
            [34.381663268744859, 3.731664165911127],
            [34.463609113006527, 3.664654034959455],
            [34.46500017366148, 3.614444926055697],
            [34.453473212744342, 3.521109081175041],
            [34.435482295718231, 3.500073186355039],
            [34.403400225948559, 3.48416416644541],
            [34.403745057446116, 3.385282183856205],
            [34.453891134437839, 3.245554013512475],
            [34.508745158818186, 3.145282143734818],
            [34.545000242914597, 3.127500103736566],
            [34.561663298835896, 3.106809040414049],
            [34.572354081089856, 3.082918101726406],
            [34.582500207273966, 3.007218116452208],
            [34.59340020183194, 2.936736033557523],
            [34.665482222409224, 2.860973184009552],
            [34.685618236101931, 2.86389109215115],
            [34.712218205903497, 2.8780539939862],
            [34.755554154184125, 2.849927009059883],
            [34.781663279734488, 2.78958216753189],
            [34.789300199377038, 2.70069107827959],
            [34.792500242380214, 2.680418104288577],
            [34.843463219657764, 2.597782094117562],
            [34.909436173907494, 2.521109137520128],
            [34.880200263187959, 2.403400059241704],
            [34.881100144315354, 2.378335989382308],
            [34.887773312728228, 2.357218119548847],
            [34.919163204931777, 2.277782152381036],
            [34.94193616537089, 2.223891041040105],
            [34.994436216056869, 2.085000055187876],
            [35.009718101969071, 1.895282046528024],
            [34.999718156176726, 1.670282092733274],
            [34.959163323377396, 1.587218102584842],
            [34.94346334813369, 1.568891071240586],
            [34.918191239436766, 1.562918127021007],
            [34.900409199438485, 1.55305396806051],
            [34.885273326280043, 1.530836057251236],
            [34.874300241526242, 1.506109107538478],
            [34.863882206402394, 1.459445040773659],
            [34.853191256510257, 1.428053975103609],
            [34.795682180472539, 1.398554034433275],
            [34.792218107518551, 1.393609046822064],
            [34.803745068435688, 1.335000097446581],
            [34.822082158063864, 1.315691042323493],
            [34.82888222849104, 1.295000146639055],
            [34.829718239516041, 1.269164103495058],
            [34.820345092444853, 1.235973107932153],
            [34.801663338957297, 1.221944987100443],
            [34.764718088951412, 1.216945014204285],
            [34.739718224470636, 1.214164066360894],
            [34.69166326468897, 1.208127084400957],
            [34.597145068543142, 1.150482053893313],
            [34.575545071637663, 1.092682125814633],
            [34.520000210795644, 1.105836014142625],
            [34.504300235551881, 1.080835982023657],
            [34.487773301738486, 1.043191178376986],
            [34.483118160339899, 1.011664158237025],
            [34.485554109047627, 0.984027011733701],
            [34.480136214457929, 0.959164107550947],
            [34.442218160766998, 0.871945040348706],
            [34.410827095096948, 0.821944976110785],
            [34.269163208028345, 0.648473107507101],
            [34.248600220186717, 0.639444959571733],
            [34.213463281977198, 0.632917971550739],
            [34.163600178037399, 0.608473156699674],
            [34.106382289316883, 0.387782171701275],
            [34.053600271407248, 0.301664151303342],
            [33.981809102870812, 0.205073110496372],
            [33.907218211028038, 0.103053949177934],
            [33.912500151147896, 0.082500013791702],
            [33.923045088286216, 0.048609129311657],
            [33.936836168895439, 0.000000125728576],
            [33.950482242579284, -0.04813580323804],
            [33.966382210033402, -0.100835846134387],
            [33.976100188602089, -0.13416396963369],
            [33.951663252740076, -0.271808901756287],
            [33.918609217475279, -0.452781903170774],
            [33.923336107965241, -0.953054957404476],
            [33.921100151468039, -1.001945928211171],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "UKR", Country: "Ukraine" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.235827297925397, 47.109427110817037],
            [38.229973844024784, 47.106720388946755],
            [38.237355505200156, 47.103961836800138],
            [38.251782709000082, 47.090651355000105],
            [38.26746182700029, 47.072974528000074],
            [38.276493789999989, 47.063301203000222],
            [38.280271567000028, 47.056628980000156],
            [38.291648943000297, 47.038417579000125],
            [38.302935661000248, 47.021353416],
            [38.323274800000178, 47.00092976100008],
            [38.320452154000208, 47.00019415499996],
            [38.30903614800016, 46.996915295000107],
            [38.305221997000132, 46.995876066000179],
            [38.27837330700018, 46.988960724000165],
            [38.271284085000133, 46.986634330000058],
            [38.250799909000278, 46.980745855000194],
            [38.230707354000032, 46.975050720000141],
            [38.227060894000118, 46.973994764000182],
            [38.22342651, 46.9728422270001],
            [38.207176054000143, 46.967587816000119],
            [38.193583962000133, 46.96310184000022],
            [38.149401894000249, 46.941224245000072],
            [38.132216005000117, 46.932523074],
            [38.090530010000094, 46.911505037000111],
            [38.087706658999963, 46.91010355400023],
            [38.086999442, 46.909752498000145],
            [37.965489800000142, 46.847134673750205],
            [37.963938224000145, 46.84704177000016],
            [37.950081998000229, 46.846213398000017],
            [37.944464353000257, 46.845877557000208],
            [37.932334575000112, 46.845180835000107],
            [37.930106609000148, 46.846131884000201],
            [37.920238297500219, 46.850803939500182],
            [37.9127524740002, 46.854348029000093],
            [37.909144963000045, 46.85609339600019],
            [37.907505846000021, 46.856886425000155],
            [37.899032453500098, 46.861043259000027],
            [37.8966384175001, 46.862227350500163],
            [37.886578987000206, 46.867500765000173],
            [37.881505079000277, 46.870215845000118],
            [37.86468970450025, 46.879273382000164],
            [37.847154372000062, 46.881122776000083],
            [37.818461817000326, 46.884488569000112],
            [37.793701125000013, 46.887393133000089],
            [37.782488607000261, 46.888694081000082],
            [37.769581872000032, 46.889087374000226],
            [37.76091060100012, 46.889351604000055],
            [37.755483761000136, 46.889370431000103],
            [37.747226904000087, 46.889399077000164],
            [37.725630073000076, 46.888975137000088],
            [37.697900803000238, 46.888430819000163],
            [37.682150090000249, 46.888168887000162],
            [37.673325185000095, 46.887993455000199],
            [37.67201021200006, 46.887967314500131],
            [37.663220094000309, 46.884338591000144],
            [37.650287231000135, 46.87851065300022],
            [37.641125660000142, 46.874382173000228],
            [37.601962446000044, 46.855867944000195],
            [37.600470186000194, 46.8551103180002],
            [37.598765051000072, 46.854244614000166],
            [37.596967337000279, 46.853299990000167],
            [37.596086800000052, 46.852837304000062],
            [37.592234965000131, 46.850730625000111],
            [37.586327991000047, 46.847499933000151],
            [37.583092793000077, 46.845393800000053],
            [37.579342531000265, 46.842952357000101],
            [37.576386433000124, 46.840772122000104],
            [37.550727328000079, 46.821847553000239],
            [37.536628740000111, 46.810315601000141],
            [37.519885700000174, 46.795901759000188],
            [37.512311875000108, 46.788992883000176],
            [37.504534720000294, 46.781919606000159],
            [37.489029737000038, 46.766370774000066],
            [37.481391717000093, 46.758580777000262],
            [37.457920932500286, 46.733825410500074],
            [37.457109982000162, 46.732944990000206],
            [37.44593694500017, 46.720701344000204],
            [37.42225091900022, 46.693901295000117],
            [37.296697983000087, 46.548081762000052],
            [37.26976086, 46.515882013000109],
            [37.270884044000042, 46.492734655000149],
            [37.271352652000274, 46.480825539000122],
            [37.272278098999976, 46.468826470000224],
            [37.271740751500062, 46.465572965000135],
            [37.271001972, 46.461801015999967],
            [37.270169113000321, 46.45759405400004],
            [37.268282734999985, 46.448043301500164],
            [37.267900774500077, 46.445989817500092],
            [37.267705831000029, 46.444944424000042],
            [37.263417782000289, 46.425329282000135],
            [37.263321454000135, 46.42474930866679],
            [37.25866039200011, 46.401488972999957],
            [37.25119559300029, 46.365374764000123],
            [37.250487193, 46.362298350000131],
            [37.24785667900008, 46.350874657000062],
            [37.245655677000315, 46.341316230000118],
            [37.241229662000109, 46.323736243000113],
            [37.240607329000198, 46.321350557000059],
            [37.239385939000073, 46.31666840700008],
            [37.239111413000074, 46.315616015999964],
            [37.230953062000168, 46.287885082000187],
            [37.226817219000139, 46.274026330000154],
            [37.224235788000016, 46.266111102000011],
            [37.223492950000065, 46.263827394000245],
            [37.223097826000156, 46.262584500000074],
            [37.221790501000072, 46.258526749000154],
            [37.220303531000269, 46.253911406000114],
            [37.219524237000172, 46.251492702250189],
            [37.218057927000274, 46.247135754000098],
            [37.217775759000091, 46.246307744000163],
            [37.217449277999975, 46.245349702000127],
            [37.216874168000032, 46.243662067000187],
            [37.215116830000255, 46.238567496000101],
            [37.214097684000336, 46.235612964000083],
            [37.212702803000269, 46.231569165000067],
            [37.209098003000094, 46.221753630000151],
            [37.208637348999986, 46.220491939000127],
            [37.208323998000111, 46.219633697],
            [37.207838171000134, 46.218303057000199],
            [37.206587223000298, 46.214835078000064],
            [37.203385871000165, 46.205905895000129],
            [37.200923425000184, 46.199037661000105],
            [37.199411789000152, 46.194821418000046],
            [37.195984545000073, 46.185198355000082],
            [37.189729513, 46.16763539300014],
            [37.188696235000322, 46.166730940000193],
            [37.153497269999974, 46.137300166000117],
            [37.140082426000248, 46.126109034000152],
            [37.113842981000204, 46.114740392000087],
            [37.107145011000313, 46.111843875000162],
            [37.088599298000048, 46.103823835000227],
            [37.070525798000148, 46.095935258999972],
            [37.067689678000164, 46.094697372000141],
            [37.052197465000035, 46.087957307000138],
            [37.013256054000095, 46.0710154],
            [36.993753488000237, 46.062568401],
            [36.954886690000336, 46.045734317000168],
            [36.950136583000102, 46.043676939000122],
            [36.944890691000325, 46.043476042000108],
            [36.876824395, 46.040869372000074],
            [36.865363053000209, 46.04043044800018],
            [36.757498045000062, 46.036653397000194],
            [36.750696862000126, 46.036382735000203],
            [36.697940118000275, 46.034283210000112],
            [36.691328705000245, 46.034080017000079],
            [36.691216771000114, 46.032470099500145],
            [36.691072295000282, 46.030392134000223],
            [36.689516012000098, 46.006460457000131],
            [36.689321271000125, 46.003465832500112],
            [36.686683940000052, 45.962910405000102],
            [36.684806776000215, 45.932119599000131],
            [36.682421053999946, 45.892986999000229],
            [36.690456894000164, 45.75397648600007],
            [36.691172394000176, 45.708326268000221],
            [36.691579110000106, 45.657201703000098],
            [36.69080052200016, 45.573723607000105],
            [36.690800459999963, 45.566376662000167],
            [36.692183301000085, 45.513207214000047],
            [36.692139106000269, 45.478124988500113],
            [36.691918430000129, 45.472376684000167],
            [36.692009577999983, 45.470732850999966],
            [36.692899858000118, 45.462876059000038],
            [36.692899798000127, 45.450144454000096],
            [36.692824768000122, 45.449322031000165],
            [36.692569718000158, 45.447409831000229],
            [36.692207115000116, 45.444691260999988],
            [36.691459154500052, 45.44015621200019],
            [36.691152758000186, 45.438298461999963],
            [36.690925276000172, 45.436693165000108],
            [36.690608331000163, 45.434456553000146],
            [36.689921460500187, 45.431317525500148],
            [36.692340854000264, 45.42307294700008],
            [36.692881948000235, 45.420549522000087],
            [36.693764843000082, 45.413510672500038],
            [36.694119955000104, 45.408673218000118],
            [36.694016548000178, 45.402636166000136],
            [36.694200552500121, 45.399264143500076],
            [36.693614499000176, 45.395883998000116],
            [36.693303716000145, 45.39416855600011],
            [36.693058742000318, 45.392465416999968],
            [36.69290257500009, 45.391379690000107],
            [36.692613163000118, 45.390470187000076],
            [36.691875769000063, 45.388152863000201],
            [36.691043758500058, 45.385538435000157],
            [36.690039632000065, 45.383601276000107],
            [36.68828625016684, 45.380186292833528],
            [36.687211401000098, 45.378455091000191],
            [36.686061269500016, 45.376662100500099],
            [36.682961379500199, 45.373169410000102],
            [36.678694988000331, 45.370184494000213],
            [36.674738008000219, 45.366453444000243],
            [36.671836346000276, 45.363717457000078],
            [36.669112410000167, 45.361428445000044],
            [36.667437425000031, 45.360727715000152],
            [36.65378636900013, 45.354840653000124],
            [36.652564394000279, 45.353386329999964],
            [36.651427542000107, 45.35203331400011],
            [36.650756862000179, 45.351235109000058],
            [36.649916134999984, 45.350497568000051],
            [36.648504503000282, 45.349259191000172],
            [36.647919415000189, 45.348699283000215],
            [36.646705878500057, 45.347544527000153],
            [36.638749118500158, 45.342215112000062],
            [36.635495941000016, 45.341220894000244],
            [36.630678448000168, 45.338150600000219],
            [36.62749367200027, 45.335947521000108],
            [36.623199902000266, 45.332977291],
            [36.622355097500048, 45.332395788000028],
            [36.617887592000017, 45.328870088000073],
            [36.616122163000199, 45.328149363000236],
            [36.613071347000215, 45.326903888000103],
            [36.605356387000114, 45.323277598000104],
            [36.604041368000111, 45.322816644000156],
            [36.601856785000081, 45.322050880000219],
            [36.60074995800025, 45.321662902999989],
            [36.597256633000171, 45.320284400000062],
            [36.594549168000327, 45.319216006000133],
            [36.59347848100029, 45.318663665000159],
            [36.592603031000039, 45.318212041000109],
            [36.590679143000187, 45.317320467000243],
            [36.588300695000072, 45.316218240000254],
            [36.57932343100012, 45.311675867000204],
            [36.576728778000216, 45.310334644500116],
            [36.575935861000147, 45.309680537000119],
            [36.575384640000181, 45.309225647500099],
            [36.58366038200009, 45.296397750999958],
            [36.588442837000088, 45.289435003000122],
            [36.589774175000088, 45.287863618000202],
            [36.591715194000045, 45.285440036000054],
            [36.597776559000209, 45.280291877000153],
            [36.599555382000204, 45.279353174000207],
            [36.603465925, 45.277383640000139],
            [36.62409004200029, 45.268999175000161],
            [36.63334131800022, 45.265312479000031],
            [36.640924124000179, 45.263021892999959],
            [36.645391584, 45.261352197000193],
            [36.639651896000288, 45.2551016540002],
            [36.639045451000101, 45.254228643000161],
            [36.63688515700008, 45.251118783000081],
            [36.63151145100008, 45.242240865000227],
            [36.629496728000078, 45.238912335000137],
            [36.628512615000062, 45.237501686000044],
            [36.625716974000142, 45.233494352000136],
            [36.624941235000193, 45.232164975000174],
            [36.624057025000212, 45.230649711000225],
            [36.623211486000145, 45.22920071700004],
            [36.622529591000131, 45.228166554000197],
            [36.621108714166866, 45.22619803866688],
            [36.620198401000124, 45.225559893000067],
            [36.619415531000215, 45.225196975000259],
            [36.611501652999976, 45.222057176000263],
            [36.608732210000113, 45.220958411000211],
            [36.607900614000215, 45.2205986],
            [36.605287826000193, 45.219903546000054],
            [36.599286228000238, 45.218307001000213],
            [36.598604946500103, 45.21812276500016],
            [36.596631866000081, 45.217535311000134],
            [36.595316525000015, 45.217143689000054],
            [36.589351966000066, 45.215813206000192],
            [36.580815058000184, 45.213908922000016],
            [36.579900491000132, 45.213704914000189],
            [36.57908184600015, 45.213567610000197],
            [36.571820228000064, 45.212349685000234],
            [36.567318284000095, 45.211594615000095],
            [36.565547515500185, 45.211231003500174],
            [36.560859087000068, 45.210524700000136],
            [36.559794018500014, 45.210364922000153],
            [36.557045148000071, 45.209909093000093],
            [36.555959454000146, 45.209813012999959],
            [36.554780333000195, 45.209708666000111],
            [36.54674660900028, 45.208862811000202],
            [36.535012419000083, 45.207241503000162],
            [36.517346987000025, 45.201555414000168],
            [36.505458382000171, 45.197725500000089],
            [36.504747064333429, 45.196008428166849],
            [36.516856166000167, 45.159155149],
            [36.523346605000285, 45.153958381000066],
            [36.526004286000017, 45.151727325000209],
            [36.531458590000256, 45.144348511000047],
            [36.532800176000222, 45.142470769000099],
            [36.535840338000099, 45.138417124000185],
            [36.546760336000176, 45.120090046000072],
            [36.548098394, 45.117410827],
            [36.549063174000281, 45.115337757000262],
            [36.554297038000044, 45.103259971000128],
            [36.555222712000244, 45.101210428000101],
            [36.558338575999954, 45.093407550000208],
            [36.568429789000135, 45.072449690000226],
            [36.571723760000253, 45.065391849000051],
            [36.581785588000031, 45.04319499900005],
            [36.583089835000038, 45.04021904000011],
            [36.587779456, 45.029152598000181],
            [36.602010223, 44.996386704000017],
            [36.607158879000025, 44.985720431999979],
            [36.607837828000157, 44.983253404500189],
            [36.614394637000288, 44.933593795000078],
            [36.614821225000043, 44.931101285000082],
            [36.625870940000283, 44.87137061600022],
            [36.639918814000254, 44.796936551000059],
            [36.653690038000065, 44.73615739900022],
            [36.693883287000034, 44.591704853000195],
            [36.564551347000048, 44.373351973000197],
            [36.537055726000261, 44.314206770000098],
            [36.532072111000133, 44.30340737700017],
            [36.346424046000124, 43.901111812000252],
            [36.270598800000272, 43.751131301000171],
            [36.247280532000019, 43.705008329500046],
            [36.242034776000224, 43.694632351000095],
            [36.234404217000275, 43.675173620000251],
            [36.231762855000312, 43.668437870000176],
            [36.182918124000167, 43.527811003000053],
            [36.151255549000069, 43.434495833000042],
            [35.506944533000166, 43.435555392000069],
            [34.219444532000097, 43.18805539200001],
            [33.615555531000183, 43.197222392000171],
            [31.999999530000053, 43.345277392],
            [31.345261965166827, 43.446967096666796],
            [31.409703947000054, 44.048754833000103],
            [31.174361168000047, 44.737055389500128],
            [31.091327816000074, 44.754038580000085],
            [30.9770277800001, 44.777416670000136],
            [30.156833330000126, 45.05513889],
            [30.040833330000083, 45.089166670000125],
            [29.677900565000272, 45.208299194000105],
            [29.671526918485512, 45.210391340639788],
            [29.669285609813215, 45.210213891273909],
            [29.668190863986041, 45.210127217947345],
            [29.664327271064877, 45.211799844515454],
            [29.67221820154495, 45.272209116679377],
            [29.669027210997143, 45.294573207880532],
            [29.65500026363199, 45.327764203443351],
            [29.64097331626661, 45.34638208682874],
            [29.603336056332893, 45.376936135645266],
            [29.580691171374582, 45.389018146192328],
            [29.51082717002177, 45.41068218083818],
            [29.411382258813916, 45.43581816742703],
            [29.321527083055486, 45.439018042792256],
            [29.221663244325583, 45.425827106452047],
            [28.968054178532356, 45.32110913994147],
            [28.848609208101635, 45.301654072064778],
            [28.797600130356471, 45.237945069868871],
            [28.70080909733943, 45.220091113141152],
            [28.574718115862112, 45.242764161294062],
            [28.375827119979448, 45.300544978633454],
            [28.343327128516279, 45.315536012504779],
            [28.317845136963314, 45.337145061865513],
            [28.299163215837524, 45.362636105873932],
            [28.285382193512106, 45.395191082622247],
            [28.279227194355173, 45.428945006804],
            [28.226982288802532, 45.449382098459623],
            [28.214836072877034, 45.448644993892188],
            [28.215409227417666, 45.463453972825974],
            [28.232354082924502, 45.495400088125749],
            [28.246936079919664, 45.511109115824937],
            [28.266527102266451, 45.52012703783852],
            [28.288882308649903, 45.525953969304297],
            [28.316382159579035, 45.527491210350931],
            [28.345554032558056, 45.523036061162699],
            [28.366382223816942, 45.517773231782243],
            [28.399573219380073, 45.511800119924757],
            [28.483045072938097, 45.506518012166723],
            [28.515827199262986, 45.514918020276596],
            [28.530554035545293, 45.531664224677556],
            [28.524791141820032, 45.647000050884301],
            [28.490691212674022, 45.665754056377594],
            [28.524436084400492, 45.711100152683414],
            [28.597009116867213, 45.76804512663584],
            [28.679963304083657, 45.779673173305568],
            [28.766727066304298, 45.862836069911779],
            [28.75048226735251, 45.926373243092044],
            [28.763363240912469, 45.961864232892779],
            [28.801109130311687, 45.972482092588862],
            [28.829436107448345, 45.978327128965546],
            [28.896663329692984, 45.990536041527022],
            [28.968254170742711, 46.006100062300888],
            [29.040136199109753, 46.195536103737041],
            [29.021663322649687, 46.217627112531815],
            [28.950000229594394, 46.289426998247322],
            [28.933954081748567, 46.429991179616181],
            [28.976382269912818, 46.468182148075428],
            [28.994436051212773, 46.478327100793038],
            [29.038882266391312, 46.496653964499316],
            [29.173882205140416, 46.539718171478214],
            [29.203300170797576, 46.54496423705244],
            [29.2231912652787, 46.519500182772433],
            [29.228327192644741, 46.484709081888738],
            [29.219854094339127, 46.406800129845649],
            [29.202427111760329, 46.383109183368177],
            [29.217909157520836, 46.367273253654602],
            [29.238327138437086, 46.368318141707377],
            [29.307354122936573, 46.404227053201637],
            [29.305409186118226, 46.428245061541773],
            [29.350827199153485, 46.469982077968282],
            [29.499436163574529, 46.48480916181289],
            [29.494436190678329, 46.442282234828994],
            [29.602082291252344, 46.376791072374871],
            [29.633609143754114, 46.369709034724153],
            [29.662209203297124, 46.379436065748351],
            [29.734436063161667, 46.440954038448112],
            [29.825273262698687, 46.368600108931005],
            [29.866382306937481, 46.346373145666334],
            [29.923327113251815, 46.382000089936966],
            [29.966663229170621, 46.379718032972065],
            [29.996382272790612, 46.378873137129617],
            [30.048609241070466, 46.378873137129617],
            [30.098473183200355, 46.381235995641404],
            [30.128709222609729, 46.405091059783402],
            [30.116554121866812, 46.426464242388207],
            [30.096518188098372, 46.429573090284649],
            [30.065827178983426, 46.43526423491862],
            [30.035273297804878, 46.443044988019992],
            [30.006945147201833, 46.456645128874371],
            [29.92860922100968, 46.505273243196839],
            [29.899436174564272, 46.535200158016025],
            [29.916154048132313, 46.558564210268642],
            [29.965027081666193, 46.570127045731525],
            [29.971663202066765, 46.741927059923157],
            [29.94430014379796, 46.818254011556448],
            [29.867009105658639, 46.861309166080034],
            [29.73749126687764, 46.890264115404264],
            [29.574236100083141, 46.947418134022556],
            [29.592491214698015, 47.023182157036999],
            [29.572218240707087, 47.142773140221649],
            [29.599227079746669, 47.279091217067915],
            [29.590545104413167, 47.310264185616731],
            [29.567009223145106, 47.337700166443412],
            [29.501873285748644, 47.344500069232524],
            [29.490127221881806, 47.327636015272489],
            [29.478536055586261, 47.297209036108356],
            [29.41395416199137, 47.291100137418866],
            [29.380273160367182, 47.329082228850453],
            [29.380973216922399, 47.356109172801027],
            [29.358891260583135, 47.378318031154706],
            [29.29943607426074, 47.422209029065343],
            [29.245618220753755, 47.457564232034159],
            [29.223045084886991, 47.448873204245345],
            [29.190100182001885, 47.439536099357937],
            [29.142909228801983, 47.511791122417335],
            [29.135373227273902, 47.549082209749017],
            [29.160127166715, 47.581664176225487],
            [29.195409111849955, 47.609153968870729],
            [29.257500239090433, 47.7663731595337],
            [29.175554059552468, 47.954700107538613],
            [29.144854165620217, 47.983518096564652],
            [29.103463321795829, 47.986509094902189],
            [29.073391232413115, 47.976991108543743],
            [29.061173267396299, 47.951718161656586],
            [29.043045054795954, 47.935673187277231],
            [29.016527227645639, 47.932336016337629],
            [28.982627123071978, 47.936918067540276],
            [28.961654092526061, 47.946645098564474],
            [28.90040920223251, 47.988591159656735],
            [28.882218125358037, 48.0055539524365],
            [28.879809166378635, 48.01045401304647],
            [28.828327181655226, 48.109718042783541],
            [28.807363203564449, 48.125409133209914],
            [28.737500208040018, 48.138600069550009],
            [28.642491167642874, 48.153600155876674],
            [28.557700170159336, 48.161800171776022],
            [28.501945091185092, 48.134164198739185],
            [28.436663308672877, 48.145827114126135],
            [28.388609187081641, 48.163882236530682],
            [28.367500202065713, 48.190964165766204],
            [28.37048214794757, 48.221791129350933],
            [28.34700913095341, 48.242044992602658],
            [28.290554163061955, 48.236382178801577],
            [28.260000114245457, 48.220545075621416],
            [28.2358272083342, 48.206800095479807],
            [28.213054080257024, 48.206518128256178],
            [28.08610915048186, 48.24260020987056],
            [28.093682200022016, 48.291173171269904],
            [28.074927188700343, 48.311100140296773],
            [28.014718134004198, 48.316664215278237],
            [27.955827217405187, 48.327353991703873],
            [27.932218245941073, 48.337491065432559],
            [27.912773236347988, 48.348045055026304],
            [27.890973247232324, 48.364154067146174],
            [27.880273244884762, 48.381100096119482],
            [27.866245124053052, 48.400553990529815],
            [27.763191132965716, 48.44958208927288],
            [27.547218153639989, 48.468318157493243],
            [27.519518142862552, 48.465500161637735],
            [27.454163270154623, 48.439709045494979],
            [27.239436176920407, 48.368327081472714],
            [26.981109105454749, 48.357773259516975],
            [26.808327067484385, 48.333054021155235],
            [26.702073201031652, 48.339991219518637],
            [26.651663261727236, 48.300827112098247],
            [26.637827086478865, 48.273609060754737],
            [26.634991153350512, 48.257164269592678],
            [26.567773318837652, 48.222491185906151],
            [26.530836115458868, 48.208191156134731],
            [26.507500226401135, 48.213464211437085],
            [26.468882283792965, 48.217491213010092],
            [26.317700242727909, 48.176136076093186],
            [26.272218191952192, 48.076100073071714],
            [26.193463338238359, 48.00151806604616],
            [26.158609205442644, 47.985253988717162],
            [26.115000174755892, 47.981364115080638],
            [26.102709119543078, 47.981718166671882],
            [26.037218124727048, 47.983600071578095],
            [25.922218077562547, 47.974709051578927],
            [25.838327128844497, 47.963464225523367],
            [25.817218143828541, 47.955273094441353],
            [25.780482273764676, 47.936854029799846],
            [25.646109133736644, 47.933591122522486],
            [25.588609110154493, 47.93443601836502],
            [25.568327083707999, 47.935818026564462],
            [25.525000187882767, 47.934709100771315],
            [25.501663292996454, 47.933327092571872],
            [25.333882233750643, 47.916664204288665],
            [25.246382205153196, 47.888736038106131],
            [25.22443620328383, 47.876100151395818],
            [25.175136195601112, 47.823327018303544],
            [25.153818165919233, 47.790127137923221],
            [25.116936115463659, 47.759436128808275],
            [25.085545049793609, 47.744427157664219],
            [25.049436146088823, 47.733327003257997],
            [25.029718221727819, 47.728864142718933],
            [24.99975467799149, 47.724477641317705],
            [24.96124511339184, 47.72083612347312],
            [24.928918124410586, 47.713145056735854],
            [24.898709242367687, 47.717845125135582],
            [24.863327217308552, 47.759991178438199],
            [24.821109247276553, 47.808400189810826],
            [24.802773163476616, 47.817773169243949],
            [24.770273172013418, 47.820545064631673],
            [24.716945156929398, 47.83860907185381],
            [24.693882182639697, 47.849164234913999],
            [24.670900177534861, 47.866800094520656],
            [24.670100208693555, 47.895482129076768],
            [24.615409128511317, 47.933745014265398],
            [24.588336084093214, 47.945827024812459],
            [24.572291277351837, 47.949482205159299],
            [24.556391142259741, 47.953045016933075],
            [24.453891194973892, 47.958609091914454],
            [24.421527157980421, 47.957773248527602],
            [24.326945091732171, 47.928891221760935],
            [24.246945190117117, 47.903882137186471],
            [24.127500219686283, 47.912009062889908],
            [24.096182244212088, 47.932353953610473],
            [24.032363271446286, 47.955964266179151],
            [23.969718100404407, 47.961391213224132],
            [23.823891257293241, 47.966945062283898],
            [23.566100124718105, 47.999991218559487],
            [23.514263250213105, 47.989336143213094],
            [23.50298221433593, 47.982464156056452],
            [23.410273168176559, 48.000545094722824],
            [23.356109142067339, 48.021100203575443],
            [23.299100130374541, 48.044854014326873],
            [23.279163270701787, 48.075273114501996],
            [23.260691232432123, 48.090127020436753],
            [23.174163336967695, 48.108327149766794],
            [23.152354127758429, 48.105691208848711],
            [23.125618203486965, 48.089364267245841],
            [23.112500189704633, 48.062773182261608],
            [23.102218109050625, 48.034300192371134],
            [23.087773239991861, 48.013473174578806],
            [23.043327192451528, 47.993044967740516],
            [22.930000173165382, 47.960544976277319],
            [22.89610912104726, 47.95389108624191],
            [22.894800203043758, 47.954535989873918],
            [22.85916320048932, 47.9938912046875],
            [22.883227141659177, 48.040482181256394],
            [22.846545083413844, 48.083164173449504],
            [22.790273176288224, 48.116654067680557],
            [22.737773293240309, 48.114436048456085],
            [22.679791142586026, 48.094991206501177],
            [22.647036173627441, 48.094318139674201],
            [22.625718143945733, 48.100345063350417],
            [22.605236125288968, 48.116482238665],
            [22.597773213956771, 48.145827114126135],
            [22.574718118656165, 48.187209073133189],
            [22.494609252299341, 48.249700184794179],
            [22.454718098595322, 48.243609055739526],
            [22.380973276061468, 48.244854103640748],
            [22.347773228043053, 48.274991068954265],
            [22.270273312876014, 48.35999111110344],
            [22.270209107497379, 48.402218133591035],
            [22.214300136780508, 48.417773101909219],
            [22.171718224511551, 48.409054078563955],
            [22.151445082882475, 48.411918174886821],
            [22.159791279173788, 48.57825402638305],
            [22.173609181873189, 48.592764274286807],
            [22.235273167326568, 48.624709048482103],
            [22.328673217585731, 48.687000167932936],
            [22.388745144345506, 48.800200117566291],
            [22.425000228441831, 48.889436038316362],
            [22.461382214552629, 48.949436048346669],
            [22.534854122318364, 48.994854061381957],
            [22.551663190993366, 49.039436063392216],
            [22.558054056906286, 49.079436014199743],
            [22.568473265496777, 49.087909112505471],
            [22.59414519097615, 49.091536129657513],
            [22.737773293240309, 49.047218157598238],
            [22.863054079954253, 49.003045024825823],
            [22.886073300709313, 49.002918122811408],
            [22.863500164842662, 49.04982710878771],
            [22.876518098700643, 49.087418100616006],
            [22.852773172766717, 49.105827106973535],
            [22.778054037804964, 49.150545063453691],
            [22.703809150926247, 49.169891166588897],
            [22.726663248188089, 49.217764239071201],
            [22.758818240515808, 49.285900059622449],
            [22.732791257616896, 49.397209051894194],
            [22.69443617149301, 49.450827080829029],
            [22.656873175221619, 49.529854011120833],
            [22.678463281481299, 49.569436040234919],
            [22.686063320749611, 49.577091064788178],
            [22.717218184387605, 49.604436018146188],
            [22.780000148089613, 49.675000076054161],
            [23.110827161826165, 49.954991182165728],
            [23.146109106961148, 49.983045076896204],
            [23.22819124096884, 50.046664225089714],
            [23.303609091381247, 50.100827077732376],
            [23.324436109173575, 50.115273120257612],
            [23.34282717825846, 50.1274910852744],
            [23.378327220514507, 50.149718048538986],
            [23.496109221350792, 50.220827097793048],
            [23.540273301667526, 50.242782152118011],
            [23.568882246027783, 50.255827075704488],
            [23.591109209292654, 50.269153966514594],
            [23.684173312871394, 50.333700153201832],
            [23.697109104078123, 50.370118181496352],
            [23.717591122734888, 50.383836004271657],
            [23.755836070650787, 50.394436094333187],
            [23.791945141993381, 50.402491103307156],
            [23.814445187664262, 50.405818048324946],
            [23.846945179127545, 50.406654059349975],
            [23.881500245617076, 50.405409179086732],
            [23.929436182373166, 50.403600196738282],
            [23.983191171606194, 50.405964228716655],
            [24.00221809186769, 50.414435985917819],
            [24.111382155111073, 50.566935997441661],
            [24.108409094046351, 50.625900171874875],
            [24.10721819323976, 50.633609175884956],
            [24.07221821532849, 50.695827037501672],
            [24.014718191746255, 50.739991117818406],
            [23.954382235035666, 50.791700084480325],
            [23.958400184153078, 50.815200091202954],
            [23.980963261735923, 50.829582095987732],
            [24.021891256865729, 50.83175401474459],
            [24.05250012332931, 50.831382193518678],
            [24.076945105818453, 50.829436083234029],
            [24.131036209369739, 50.83818209630779],
            [24.14347327733617, 50.859582101002857],
            [24.090273170094946, 50.881936133920206],
            [24.061500108070049, 50.887527030991734],
            [24.034300161637447, 50.898254023067679],
            [23.988327098972263, 50.931109071950416],
            [23.96513604920176, 50.950409074617923],
            [23.93166325405312, 50.994073258228056],
            [23.905000252339619, 51.068054115156073],
            [23.811109190191075, 51.168882208029842],
            [23.756663196858398, 51.199436089208476],
            [23.731936079507648, 51.214718142758741],
            [23.683882293192283, 51.288609145684475],
            [23.681245178807814, 51.369300051399151],
            [23.692773313191452, 51.402354086663948],
            [23.643636082068809, 51.485009039936173],
            [23.61409121439732, 51.498627118063482],
            [23.60463609231283, 51.527691199767403],
            [23.641073231346667, 51.523500080529956],
            [23.62777316262688, 51.579718175836845],
            [23.613291245556098, 51.606727014876626],
            [23.650000125891665, 51.6361731437283],
            [23.751318224826491, 51.651791143958818],
            [23.901591164670549, 51.634154110885802],
            [23.922909194352343, 51.619854081114354],
            [23.938400124930297, 51.596100102724947],
            [23.963463356599362, 51.585545107302707],
            [23.998191257932859, 51.592909112177139],
            [24.043054053700331, 51.610273230481866],
            [24.070554072267356, 51.624991181946896],
            [24.283400266423769, 51.741173245100484],
            [24.297354123593067, 51.764573171898846],
            [24.30610918912231, 51.803045101753227],
            [24.314582287428067, 51.824027017116677],
            [24.394227131623637, 51.88471803124672],
            [24.700273216190709, 51.893882133652184],
            [24.740554128393427, 51.889718171780956],
            [24.897500236650188, 51.900691088896608],
            [24.991936122506672, 51.911936082590145],
            [25.051936132537094, 51.932209056581073],
            [25.09360911122306, 51.94221805482907],
            [25.173054130846367, 51.959300205910168],
            [25.240963304735203, 51.959854082073591],
            [25.269582139741203, 51.95360906127631],
            [25.312218199104791, 51.939709183563579],
            [25.352082195442506, 51.928609196795335],
            [25.439991260916059, 51.922218163244267],
            [25.546673274984016, 51.922136020592987],
            [25.562773234648404, 51.919991091564285],
            [25.598327088723124, 51.918882165771052],
            [25.68596323942856, 51.922354117714164],
            [25.733600109878608, 51.927354090610308],
            [25.770418122593838, 51.938182168438814],
            [25.818327069621688, 51.935818136460554],
            [26.026945096528436, 51.910273112995597],
            [26.144436077685697, 51.878464125632021],
            [26.175409221662221, 51.862909157313638],
            [26.207773258655521, 51.858327106110821],
            [26.296663342079512, 51.853600215620972],
            [26.33860923553371, 51.857500147541387],
            [26.364163311454064, 51.858891208196226],
            [26.435545107838152, 51.856173124626807],
            [26.439991204571072, 51.823054045793455],
            [26.457218194939429, 51.812764253788416],
            [26.498600153946455, 51.811936121752396],
            [26.530827063003557, 51.813327014769058],
            [26.571109148672775, 51.818327155303407],
            [26.608054063402392, 51.821109108975079],
            [26.642354152396535, 51.820691187281668],
            [26.672500170165478, 51.814991157830278],
            [26.722500234403469, 51.80249122558989],
            [26.772500131003397, 51.78582716384021],
            [26.820827167362864, 51.772491220574594],
            [26.895000137512142, 51.75555407641879],
            [26.926527157652174, 51.750409096597238],
            [26.948054064361571, 51.748882081472388],
            [26.971109159662348, 51.752218078945688],
            [27.019300241551917, 51.768118046399636],
            [27.080827099068898, 51.77110015991984],
            [27.170000155544898, 51.764164135022654],
            [27.202563346558208, 51.751309145362697],
            [27.204582211762755, 51.717491183440515],
            [27.199373194200831, 51.675136085472147],
            [27.282563248173375, 51.598191219935003],
            [27.416663305795197, 51.597491163379786],
            [27.453191137021719, 51.602773103499644],
            [27.511973256517223, 51.628864124138929],
            [27.597218217877895, 51.615827079541461],
            [27.687218232923414, 51.601391095300201],
            [27.724473278071486, 51.58165406019971],
            [27.709754153140096, 51.550509087207629],
            [27.681663210397431, 51.493191118562819],
            [27.747909247053457, 51.466518058565313],
            [27.816800277083047, 51.533254101282395],
            [27.814227200439035, 51.568327001751598],
            [27.813891253758783, 51.592209055621922],
            [27.832082162995135, 51.609164137050627],
            [27.865973215113257, 51.621800191399117],
            [27.895827207374822, 51.613054010687264],
            [27.925618167724139, 51.59166423191401],
            [27.955273173603672, 51.575000170164301],
            [27.983745157665652, 51.565827015303526],
            [28.006109248866693, 51.562209050606796],
            [28.079027112831142, 51.564436122286779],
            [28.109163239954142, 51.569444980000256],
            [28.138191279474341, 51.583045120854635],
            [28.157500166959522, 51.609991095620259],
            [28.182009187188982, 51.637773249049005],
            [28.21569119464138, 51.655682191061643],
            [28.256736201139773, 51.659291103303062],
            [28.319445074645927, 51.58915401954404],
            [28.433327143561797, 51.566100097709736],
            [28.468191167003141, 51.577909193488566],
            [28.490554084737738, 51.581109068853792],
            [28.628263222238985, 51.564854043980105],
            [28.632773189074072, 51.543600052038855],
            [28.637218112340491, 51.510000187237821],
            [28.652918087584197, 51.464718128672345],
            [28.666527113255967, 51.446654121450493],
            [28.75715409466082, 51.415645102928224],
            [28.769445149873633, 51.448045014467354],
            [28.766036062204392, 51.481382190422053],
            [28.793054121337633, 51.516109085927226],
            [28.810554026474193, 51.534436117271568],
            [28.836945119248099, 51.550273220451444],
            [29.052500176880329, 51.631100080636301],
            [29.08395410682445, 51.63798212607675],
            [29.118054035970346, 51.636936064557389],
            [29.163191255248279, 51.627773135618597],
            [29.181382164484745, 51.618045098765904],
            [29.24151812898495, 51.540964111120772],
            [29.2495821904146, 51.502282130772343],
            [29.312009096696983, 51.387627082743407],
            [29.342218146377917, 51.373182213684757],
            [29.353836134763952, 51.375318090257863],
            [29.409718115752668, 51.402773181823861],
            [29.624027119655381, 51.490682079659237],
            [29.675827113786255, 51.499300184890288],
            [29.718954185039223, 51.491027078795],
            [29.738218145523064, 51.457145079132275],
            [29.762773266220307, 51.440818137529206],
            [29.834445076455012, 51.441936115777864],
            [29.881663354659423, 51.459718155776201],
            [29.91166335967452, 51.472764252829066],
            [29.983327123282123, 51.486382163318197],
            [30.012218202504215, 51.489154058706092],
            [30.043054050906363, 51.490273210421222],
            [30.152218114149576, 51.493327073032717],
            [30.179991215122897, 51.491518090684266],
            [30.20721815128374, 51.483735996478416],
            [30.347636152260719, 51.400064150709838],
            [30.350273266645246, 51.358327134283414],
            [30.464718096541759, 51.295000179235487],
            [30.551418156297899, 51.251845112425812],
            [30.567773261095653, 51.297773248089769],
            [30.615827215048938, 51.323882206001954],
            [30.642600187332647, 51.331200110408659],
            [30.643745155309517, 51.368882129705625],
            [30.586382259663566, 51.489154058706092],
            [30.553891153017759, 51.541936076615784],
            [30.565000192241286, 51.643327098108486],
            [30.625973173594986, 51.707909159341554],
            [30.66693620508002, 51.801109049752299],
            [30.662082244937579, 51.821654100321084],
            [30.746936106695131, 51.895273194307023],
            [30.825000123947433, 51.947491110131423],
            [30.896945184226496, 51.97776419755273],
            [30.95888225208617, 52.000827004204368],
            [30.930618139223355, 52.032218069874418],
            [30.935000198215761, 52.061800153196188],
            [30.959663277826138, 52.07934498533406],
            [31.106382290713753, 52.086382095983637],
            [31.132218166219758, 52.086382095983637],
            [31.296245138660964, 52.086936139785067],
            [31.323891169981692, 52.105000147007004],
            [31.345973293959247, 52.113744986614378],
            [31.37082714568632, 52.117354066493689],
            [31.42250023780278, 52.119991180878159],
            [31.50278227427961, 52.117491194430087],
            [31.523609124433932, 52.116391153454273],
            [31.622218192254849, 52.108891194110029],
            [31.734718085333299, 52.106100187982847],
            [31.783882306184154, 52.108045124801109],
            [31.825273317646719, 52.111382128102619],
            [31.856109166048867, 52.107909170331297],
            [31.900000163959504, 52.093182166410756],
            [31.90888229914114, 52.06944511946584],
            [31.995827110470685, 52.054436148321869],
            [32.123745179207305, 52.044436034891376],
            [32.224991193774684, 52.079436012802645],
            [32.298118102404857, 52.109154050594341],
            [32.331700197570882, 52.169400153302533],
            [32.364436055790378, 52.318954093490177],
            [32.389163340779049, 52.334018049919266],
            [32.413318141779513, 52.335545065043917],
            [32.527218147968341, 52.324436025820305],
            [32.552491262493589, 52.319991102554027],
            [32.584436204326977, 52.309718074355246],
            [32.608891245099954, 52.300545087132647],
            [32.639163326693023, 52.289436047908836],
            [32.700827144508168, 52.26832706289288],
            [32.731654108093068, 52.261382153178559],
            [32.758609135314117, 52.257773240937141],
            [32.916800123833895, 52.247627114752945],
            [32.941654143199315, 52.253882193834315],
            [32.971927230620764, 52.272764274808296],
            [33.038045191795874, 52.30582702725242],
            [33.205682249944772, 52.378600051929524],
            [33.378600242385033, 52.365000078713123],
            [33.417700144426789, 52.355400117341318],
            [33.512773222564448, 52.326945064723688],
            [33.601663305988524, 52.331936152802697],
            [33.628600228298609, 52.341936098595014],
            [33.688882205552687, 52.359718138593351],
            [33.762218158848299, 52.368736060606935],
            [33.831663232679517, 52.36318221154734],
            [34.062909213099857, 52.196109087845869],
            [34.09978221110012, 52.145054077271269],
            [34.077636217020228, 52.121245113596714],
            [34.073609215447306, 52.058609162648494],
            [34.083609161239764, 52.037491125176857],
            [34.129154076289666, 51.986109052739508],
            [34.240545043574542, 51.911373153971411],
            [34.337354181502889, 51.856664136516443],
            [34.384036185540623, 51.843109090301013],
            [34.422218101544189, 51.804164253468272],
            [34.44110018251834, 51.754918057604115],
            [34.426109148646987, 51.728318087802549],
            [34.388954183423408, 51.715200074019933],
            [34.255273220961413, 51.701100204097102],
            [34.197773197379348, 51.696654107364211],
            [34.105545104825552, 51.674782201519164],
            [34.101863270026371, 51.647700104645466],
            [34.163045128407617, 51.614718153748115],
            [34.234645189551117, 51.544154095840341],
            [34.245200184973299, 51.501173204978997],
            [34.23985403947492, 51.431936002347541],
            [34.382209265919045, 51.263609113565352],
            [34.406936215632101, 51.263609113565352],
            [34.55054503951888, 51.233327141326598],
            [34.672354041927889, 51.178464232128746],
            [34.708600241206653, 51.174164148149529],
            [34.734991166342382, 51.172491120271175],
            [34.766663361045943, 51.171382194478028],
            [34.801100242700301, 51.173609098519805],
            [34.827218085429905, 51.178045136968919],
            [34.920273304191568, 51.197909074083654],
            [34.944291144893469, 51.216027060762244],
            [34.974991206463898, 51.223600110302229],
            [35.076445092230614, 51.220644986510692],
            [35.119009234864762, 51.199264260192749],
            [35.124918141343898, 51.178573196870317],
            [35.119636201224097, 51.151764182403085],
            [35.15074513203237, 51.073700165150726],
            [35.165745050721029, 51.058554066070357],
            [35.195063271730106, 51.048273158882736],
            [35.222145200965514, 51.048418165808144],
            [35.244091202835108, 51.050909099800734],
            [35.293545102260538, 51.059927021814488],
            [35.368827165840997, 51.042127044543136],
            [35.370500193719664, 51.021354006207261],
            [35.344945111970873, 51.006436062532075],
            [35.309663334473811, 50.982554008661921],
            [35.30577329319928, 50.960336097852647],
            [35.357791216813354, 50.928582095774019],
            [35.460863312811767, 50.763073202847337],
            [35.486073227786591, 50.678627036861386],
            [35.399318182745759, 50.641591094662985],
            [35.441054025705711, 50.511973175957891],
            [35.458282189540654, 50.488064132359241],
            [35.597900221676213, 50.373600191723355],
            [35.64471818018373, 50.354164234585582],
            [35.681382301156447, 50.346936016543182],
            [35.693091149372748, 50.347409091159804],
            [35.743191293535091, 50.385273165394025],
            [35.827636118416393, 50.423045038693189],
            [35.858054045125044, 50.429718039468085],
            [35.894154064012326, 50.433327119347481],
            [35.927491239967054, 50.435818053340071],
            [35.978600229998307, 50.438327092243455],
            [36.007773276443885, 50.439154050813173],
            [36.059436142638361, 50.434991094770524],
            [36.091936134101758, 50.43110910012301],
            [36.148463353998665, 50.4222821178641],
            [36.188391220438774, 50.398318088980531],
            [36.199573182220178, 50.37332710931706],
            [36.215827201265398, 50.355000077972804],
            [36.279163208768722, 50.294445018312487],
            [36.300136071676803, 50.283600176677808],
            [36.327082214080775, 50.280827107823441],
            [36.353745048156128, 50.287218141374538],
            [36.375127115578351, 50.2958270265121],
            [36.419991252450217, 50.313882148916363],
            [36.477209141170789, 50.301382216676146],
            [36.559891251809773, 50.269364184646562],
            [36.554054094422014, 50.231000213705542],
            [36.578463370003334, 50.217345087566216],
            [36.608463207380652, 50.213045003587197],
            [36.651445104070291, 50.219436037138095],
            [36.66999123836402, 50.24360911068753],
            [36.693463249529799, 50.262491191661681],
            [36.717491148515961, 50.274709156678639],
            [36.810273284871101, 50.314154057856356],
            [36.891800201610948, 50.337353992444193],
            [36.975963226906828, 50.349154035767626],
            [37.016800194567935, 50.346936016543182],
            [37.059227209265686, 50.337909042074088],
            [37.130545135547408, 50.348327077198107],
            [37.216936070713558, 50.36970914462016],
            [37.245691195465639, 50.379854097338054],
            [37.270827182054489, 50.393882218169765],
            [37.292500269155624, 50.412209081875844],
            [37.322627176185364, 50.428745068144664],
            [37.418600135450475, 50.438045125020025],
            [37.46193608373116, 50.436173110759611],
            [37.64943607316647, 50.184154092003226],
            [37.746109256624521, 50.080482187012009],
            [37.793327199552749, 50.069164270760709],
            [37.823327204568074, 50.061664143778287],
            [37.924782263801404, 50.024573216294939],
            [37.981654147557805, 49.969300096754594],
            [38.013609147675055, 49.934436073313023],
            [38.024227175009429, 49.903082223293282],
            [38.103609162720403, 49.938600202822144],
            [38.186863254433632, 50.065545132597308],
            [38.304854132297606, 50.073882108795246],
            [38.329645119750836, 50.065682092895699],
            [38.34388228524827, 50.046391142683532],
            [38.351245116656031, 50.02290907323372],
            [38.361245062448376, 50.000691162424644],
            [38.384573240155447, 49.984991187180853],
            [38.505682186009238, 49.952073106386152],
            [38.547218204397183, 49.954709047304121],
            [38.587773204834349, 49.964573206264717],
            [38.614436206548049, 49.963882202164896],
            [38.65221813813119, 49.958882229268752],
            [38.689154168043473, 49.935264205349213],
            [38.82582713467113, 49.881382146463622],
            [38.866391187564005, 49.874436063282801],
            [38.898945158483826, 49.861382087240813],
            [38.920827122612849, 49.835545038268506],
            [38.941863352709021, 49.811027133221316],
            [38.97138223648048, 49.80720917631443],
            [39.072836122247423, 49.819927038037989],
            [39.091936132704717, 49.831109167457569],
            [39.121936137719871, 49.85610014712104],
            [39.183736077643033, 49.880409175140372],
            [39.202973216036781, 49.871236020279596],
            [39.239154036470808, 49.802627125111613],
            [39.248054108925288, 49.77430014797504],
            [39.266109231330034, 49.756664120730235],
            [39.296391203568675, 49.743882221266219],
            [39.328609227808414, 49.736936138085426],
            [39.359718158616687, 49.732909136512674],
            [39.383191175611017, 49.735409122960576],
            [39.411936074441115, 49.745409068752892],
            [39.442773263947998, 49.756664120730235],
            [39.470273282514739, 49.757218164531835],
            [39.58770022593157, 49.721382175595338],
            [39.61305414200379, 49.668600157685731],
            [39.629854158223196, 49.627564203642734],
            [39.654300146540749, 49.607627008694081],
            [39.812427097320125, 49.550545074443434],
            [39.859536075506782, 49.551754080160848],
            [39.898327188235072, 49.559436094442432],
            [39.930409258004801, 49.568600196847896],
            [39.954300196692571, 49.580691092212263],
            [39.975127214484786, 49.599853966943371],
            [39.998463271180896, 49.612354066822135],
            [40.042773196613354, 49.616664209084959],
            [40.068327104895701, 49.613053955739261],
            [40.13976321601254, 49.601054087843451],
            [40.148954140507925, 49.577700093874725],
            [40.136245163601899, 49.55540909286951],
            [40.103609217668605, 49.542500123753101],
            [40.079300189649274, 49.530691195612334],
            [40.059154117672762, 49.505691163493381],
            [40.054645156666055, 49.474018130599703],
            [40.088882213748349, 49.424709070461418],
            [40.109718116358096, 49.403882220306897],
            [40.149018178248383, 49.370545044352369],
            [40.172354067306316, 49.352636102339616],
            [40.178745100857412, 49.333045079992829],
            [40.167636061633772, 49.251664176006656],
            [40.081745190812427, 49.183827086485465],
            [40.054163364870107, 49.174164260839703],
            [40.000200169161502, 49.149364220930906],
            [39.96638220723932, 49.123882229378168],
            [39.951100153689282, 49.107773217258384],
            [39.939436064835746, 49.089718094853751],
            [39.941445207032501, 49.064364178781702],
            [39.917636075719969, 49.050273193676034],
            [39.823327091877928, 49.052218130494211],
            [39.705409136571831, 49.035964111449189],
            [39.697909177227729, 49.016800063251239],
            [39.693736162901104, 48.976518145220197],
            [39.775618137060377, 48.901173217365553],
            [39.950000280351418, 48.867491209913268],
            [40.026800138963296, 48.893745007112798],
            [40.059300130426635, 48.890827098971201],
            [40.074854092916524, 48.876236049520557],
            [40.068954071254922, 48.849782092472893],
            [40.03971816053533, 48.820273099347062],
            [40.021382244373598, 48.80873608014609],
            [39.856945061589926, 48.821245064841989],
            [39.82902712132946, 48.824582235781563],
            [39.80804520596601, 48.819573210429908],
            [39.782773264907036, 48.802773194210502],
            [39.727209125687722, 48.762218026134988],
            [39.704163250480264, 48.718600110630646],
            [39.660191283385046, 48.603918072873583],
            [39.732491233445529, 48.58250013090553],
            [39.760554180631317, 48.58250013090553],
            [39.790127211497776, 48.583609056698847],
            [39.814854161210434, 48.577636112479283],
            [39.848918215810585, 48.55690012215571],
            [39.867209204971033, 48.503882237489918],
            [39.921518238005632, 48.368391119213342],
            [39.998882198702717, 48.297218032218851],
            [39.940818240673167, 48.217073123678389],
            [39.91749123643271, 48.198327164812142],
            [39.903591191081915, 48.181664108890928],
            [39.776227166146754, 48.024018111717041],
            [39.799991202820166, 48.000827061946538],
            [39.817836107092461, 47.974364220081171],
            [39.803254110097356, 47.868600192051602],
            [39.781100237028653, 47.842209099277696],
            [39.764291168353594, 47.829300130161116],
            [39.731382307652467, 47.819154003976919],
            [39.683882229862348, 47.823745107635048],
            [39.643045094563405, 47.832764203115104],
            [39.60499125404047, 47.839009056274321],
            [39.479709126222048, 47.839427145605825],
            [39.432209216070163, 47.831382194915662],
            [39.409573215929385, 47.831727026413247],
            [39.384573183810545, 47.845545096750797],
            [39.353873289878294, 47.852482127476307],
            [39.254709172427198, 47.852909101625144],
            [39.189154139870908, 47.843036057847243],
            [39.141518107610949, 47.834300103057259],
            [39.118327225478623, 47.837482208787648],
            [39.038173264482708, 47.865800133468909],
            [38.924709117260335, 47.868318057189995],
            [38.897218151148621, 47.868318057189995],
            [38.867209261315992, 47.86442701008697],
            [38.845963316001843, 47.856800148728141],
            [38.822773272059663, 47.838454006644596],
            [38.789982260917355, 47.778873091774173],
            [38.770400123388043, 47.720818018562127],
            [38.758191210826709, 47.689564248466453],
            [38.610263359859601, 47.639436108747461],
            [38.577491291818404, 47.63248214657763],
            [38.537209206149186, 47.62803604984488],
            [38.496654038073842, 47.627073136805464],
            [38.453873307061002, 47.62887323433641],
            [38.353882231040501, 47.608673182903246],
            [38.300827130724798, 47.555127070698077],
            [38.280818186684286, 47.506100145421371],
            [38.223309110646795, 47.339991108225647],
            [38.301100213130866, 47.301100083211352],
            [38.253873217747326, 47.228045091310648],
            [38.227063365089435, 47.210964113696107],
            [38.214709110326595, 47.187627051171702],
            [38.225545067144111, 47.127764169077679],
            [38.235827297925397, 47.109427110817037],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ISO_A3: "UMI",
        Country: "United States Minor Outlying Islands",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-160.005585648999926, -3.736816651999959],
              [-160.034666648999917, -3.736944651999977],
              [-160.063747648999936, -3.736816651999959],
              [-160.092827648999872, -3.73643365199996],
              [-160.121899648999914, -3.735794651999925],
              [-160.150969648999904, -3.734899651999797],
              [-160.180027648999982, -3.733752652000021],
              [-160.209074648999916, -3.73234765199993],
              [-160.23810864899994, -3.730685651999977],
              [-160.267127648999974, -3.728772651999805],
              [-160.296277648999848, -3.72660265199994],
              [-160.32510864899993, -3.724277651999955],
              [-160.354066648999975, -3.721499651999864],
              [-160.382999648999913, -3.718566651999936],
              [-160.411908648999912, -3.715377652000029],
              [-160.440785648999906, -3.711938651999958],
              [-160.469633648999917, -3.708244651999962],
              [-160.498447648999871, -3.704294651999987],
              [-160.527249649999959, -3.700094651999848],
              [-160.55596365, -3.695641651999892],
              [-160.58466365000001, -3.690933651000023],
              [-160.613322649999901, -3.685977650999916],
              [-160.641935649999908, -3.680766651],
              [-160.670502649999861, -3.675305651000031],
              [-160.699022649999819, -3.669594651000011],
              [-160.727488649999913, -3.663833650999777],
              [-160.755902649999967, -3.65742265099982],
              [-160.784260649999936, -3.650960650999764],
              [-160.81256065, -3.644252650999931],
              [-160.840802649999858, -3.637294650999934],
              [-160.868983649999905, -3.630088650999767],
              [-160.897097649999949, -3.622633650999944],
              [-160.925147649999985, -3.614933650999944],
              [-160.953127649999942, -3.606988650999938],
              [-160.981038649999874, -3.59879765099987],
              [-161.00887765, -3.590360650999969],
              [-161.036641649999979, -3.581805650999854],
              [-161.064327649999939, -3.572755650999966],
              [-161.091935649999925, -3.563588650999975],
              [-161.119610649999885, -3.554305651],
              [-161.146905649999979, -3.54452765100001],
              [-161.174263650000086, -3.534638650999966],
              [-161.201533649999902, -3.524505651000027],
              [-161.228713649999833, -3.51413565099989],
              [-161.255805649999928, -3.503527650999956],
              [-161.28299965000005, -3.492680651],
              [-161.309699649999942, -3.481597651000016],
              [-161.336502649999943, -3.47030565099999],
              [-161.363205649999855, -3.458724651000011],
              [-161.389808649999821, -3.446938650999982],
              [-161.41630565, -3.434916651000037],
              [-161.442694649999936, -3.42266365099988],
              [-161.468977649999971, -3.410177650999913],
              [-161.495152649999966, -3.397463650999839],
              [-161.521213649999936, -3.384519651],
              [-161.547160649999824, -3.371347651],
              [-161.572994650000027, -3.357947650999904],
              [-161.598708650999896, -3.344319650999978],
              [-161.624302650999937, -3.33046965099993],
              [-161.649774650999944, -3.316416651],
              [-161.675122651, -3.302094650999891],
              [-161.700344650999909, -3.287572650999948],
              [-161.725441650999983, -3.272830650999765],
              [-161.750408650999958, -3.257866651000029],
              [-161.775241650999845, -3.242685650999988],
              [-161.799944650999976, -3.227288651000038],
              [-161.824508650999832, -3.211749651],
              [-161.848938650999941, -3.195944650999934],
              [-161.873230650999915, -3.179799651],
              [-161.897377650999914, -3.163544650999768],
              [-161.921385650999838, -3.147077651000018],
              [-161.945249650999926, -3.130399650999948],
              [-161.968966650999931, -3.113510650999899],
              [-161.992533650999974, -3.09641665099997],
              [-162.015952650999935, -3.079116650999936],
              [-162.039219651000025, -3.061610650999853],
              [-162.06233365099996, -3.044027650999965],
              [-162.085416650999917, -3.02599165099997],
              [-162.108094650999931, -3.007877650999831],
              [-162.130738651000087, -2.989566651000018],
              [-162.153222650999936, -2.971055650999972],
              [-162.175541650999918, -2.952349650999793],
              [-162.197699650999908, -2.933447651],
              [-162.21969165099992, -2.914349650999895],
              [-162.241516650999898, -2.89506365099993],
              [-162.263172650999905, -2.875583650999943],
              [-162.284658650999972, -2.85591665100003],
              [-162.305972650999934, -2.836058650999973],
              [-162.327113650999962, -2.816016650999757],
              [-162.348277650999961, -2.795788650999782],
              [-162.368866650999962, -2.775377650999928],
              [-162.389477650999936, -2.75478565100002],
              [-162.409908650999938, -2.734013650999771],
              [-162.430158650999971, -2.713110650999965],
              [-162.450222650999905, -2.691933651],
              [-162.470105650999926, -2.670630650999954],
              [-162.48979965099997, -2.649152651],
              [-162.509308650999969, -2.627527650999923],
              [-162.528777650999956, -2.605683649999946],
              [-162.547755650999903, -2.583694649999927],
              [-162.566694650999835, -2.561538649999932],
              [-162.585435650999983, -2.53921965],
              [-162.603985650999931, -2.516735649999816],
              [-162.62233865099995, -2.494088649999981],
              [-162.640494650999926, -2.471280649999755],
              [-162.658449650999955, -2.448316649999981],
              [-162.676208651999872, -2.42519465],
              [-162.693760651999952, -2.401916649999976],
              [-162.711138651999931, -2.378488649999895],
              [-162.72826065199996, -2.354905650000035],
              [-162.745205651999925, -2.331174649999923],
              [-162.761941651999905, -2.30729765000001],
              [-162.778469651999927, -2.283272649999901],
              [-162.794785651999888, -2.259102649999818],
              [-162.810894651999945, -2.234791649999863],
              [-162.826791651999969, -2.210338649999869],
              [-162.84247465200005, -2.185749649999934],
              [-162.857944651999901, -2.16102265],
              [-162.873197651999931, -2.136160650000022],
              [-162.88823565199985, -2.111166649999973],
              [-162.903083651999935, -2.08604165],
              [-162.917658651999915, -2.0607856499999],
              [-162.932041651999981, -2.035405649999973],
              [-162.946202651999982, -2.009897649999914],
              [-162.960141651999919, -1.98426665],
              [-162.973858651999933, -1.958513649999816],
              [-162.987352651999913, -1.932644649999929],
              [-163.000619651999926, -1.906655649999948],
              [-163.013660652000084, -1.880549649999978],
              [-163.026477651999983, -1.854333649999887],
              [-163.039063651999953, -1.828005650000023],
              [-163.051422651999985, -1.801566649999756],
              [-163.063552651999913, -1.775222649999989],
              [-163.075449651999975, -1.748369650000029],
              [-163.087116651999878, -1.721613649999938],
              [-163.09855265199991, -1.694758649999812],
              [-163.109752651999969, -1.66802765],
              [-163.120719651999849, -1.640752650000024],
              [-163.131452651999979, -1.613602649999905],
              [-163.141999651999839, -1.586363649999896],
              [-163.152208651999956, -1.559030650000011],
              [-163.162305651999958, -1.531610648999958],
              [-163.172016651999911, -1.50410264899989],
              [-163.181563651999824, -1.476510648999977],
              [-163.190869651999918, -1.448835648999875],
              [-163.199935651999965, -1.421080648999862],
              [-163.208760651999825, -1.393244648999939],
              [-163.217347651999859, -1.365333649000036],
              [-163.225688652000059, -1.337349648999975],
              [-163.23378865199993, -1.309291648999817],
              [-163.241644652000076, -1.281166648999772],
              [-163.249255651999903, -1.252969648999795],
              [-163.256624651999971, -1.224710649000031],
              [-163.263747651999864, -1.196385648999865],
              [-163.270624651999981, -1.16799964899991],
              [-163.277255651999923, -1.139555648999931],
              [-163.283641651999915, -1.111052648999873],
              [-163.289777652000026, -1.082497648999947],
              [-163.295669651999901, -1.053885648999938],
              [-163.301310652000012, -1.02522764899993],
              [-163.306705651999977, -0.996519649],
              [-163.311849651999978, -0.967763648999949],
              [-163.316744651999983, -0.938963648999902],
              [-163.321416651999925, -0.910124648999954],
              [-163.325788651999915, -0.881244648999939],
              [-163.329935651999932, -0.852324649000025],
              [-163.333830651999818, -0.823372648999964],
              [-163.337477651999933, -0.794385649],
              [-163.340872651999831, -0.765369648999865],
              [-163.344013651999916, -0.736324648999982],
              [-163.346905651999919, -0.707252648999827],
              [-163.349544651999963, -0.678155649000018],
              [-163.351933651999929, -0.649035648999984],
              [-163.354069651999907, -0.619899648999933],
              [-163.355952651999843, -0.590741648999938],
              [-163.357583651999875, -0.561572648999856],
              [-163.358963651999915, -0.532385648999878],
              [-163.360088651999916, -0.50319164900003],
              [-163.360960652000045, -0.473985648999985],
              [-163.361583651999979, -0.444774647999964],
              [-163.361999652000037, -0.415610647999927],
              [-163.362066651999982, -0.386344647999977],
              [-163.361927651999935, -0.35712464799991],
              [-163.361535652000015, -0.327910648],
              [-163.360894651999985, -0.298699647999968],
              [-163.359997651999862, -0.269497647999913],
              [-163.358849651999975, -0.240527647999954],
              [-163.357447652, -0.211194647999946],
              [-163.355794651999986, -0.181972648],
              [-163.353888651999938, -0.152944647999846],
              [-163.351730651999901, -0.123658647999946],
              [-163.349319651999906, -0.094541647999961],
              [-163.346655651999924, -0.065447648000031],
              [-163.343741651999977, -0.036374648],
              [-163.340777651999957, -0.007333647999985],
              [-163.337494651999918, 0.023014352000132],
              [-163.333827651999911, 0.051997352000114],
              [-163.329908651999915, 0.08094735200001],
              [-163.325741651999948, 0.109861352],
              [-163.321322651999964, 0.138739352000186],
              [-163.316655651999923, 0.167575352000085],
              [-163.311735651999896, 0.196369352000033],
              [-163.306569651999922, 0.225122351999971],
              [-163.301155651999892, 0.253825352000092],
              [-163.295491651999924, 0.282480352000107],
              [-163.289580652000069, 0.311086352000132],
              [-163.283422651999928, 0.339639352000063],
              [-163.277019651999922, 0.368136352000022],
              [-163.27036665199995, 0.39657535200007],
              [-163.263469651999941, 0.424955352000097],
              [-163.256324651999932, 0.453275352000105],
              [-163.248938651999964, 0.481530352000107],
              [-163.241305651999909, 0.509719352000104],
              [-163.233430651999981, 0.537839352000105],
              [-163.225310652000047, 0.565891352000108],
              [-163.216949651999954, 0.59386935200007],
              [-163.208347651999844, 0.621775353000032],
              [-163.19950265199995, 0.649602353000148],
              [-163.190419652000088, 0.677350353000065],
              [-163.181094651999985, 0.705019353000012],
              [-163.171530651999944, 0.732605353000054],
              [-163.161727651999939, 0.760105353000085],
              [-163.151688651999933, 0.787519353000107],
              [-163.141410651999934, 0.814844353000069],
              [-163.130897651999902, 0.842077352999979],
              [-163.120147651999929, 0.869216353000013],
              [-163.109163652000092, 0.896261353000057],
              [-163.097947652000045, 0.92320835300012],
              [-163.086497651999906, 0.950055353000153],
              [-163.074816651999924, 0.976802353000039],
              [-163.062902651999934, 1.003444353000134],
              [-163.050758652000042, 1.029983353000034],
              [-163.038385651999903, 1.056411352999987],
              [-163.025783651999944, 1.082733353000094],
              [-163.012955651999931, 1.108941353000148],
              [-162.999899651999954, 1.135036353000089],
              [-162.986616651999867, 1.161014353000098],
              [-162.973110651999946, 1.186877353000057],
              [-162.959383651999985, 1.212619353000093],
              [-162.945430651999885, 1.238241352999978],
              [-162.93125865199994, 1.263739353000119],
              [-162.916863651999932, 1.289111353000123],
              [-162.902249651999938, 1.314355353],
              [-162.872369651999975, 1.364455352999983],
              [-162.857105651999944, 1.38930835299999],
              [-162.841627651999943, 1.41402535300017],
              [-162.825933651999861, 1.438605353000185],
              [-162.810027651999974, 1.463044353000043],
              [-162.79391065199988, 1.487347353000075],
              [-162.777585651999885, 1.511505353000132],
              [-162.761049651999912, 1.535519353000041],
              [-162.750733652000093, 1.550169353],
              [-162.733991651999958, 1.574039353000046],
              [-162.717041651999949, 1.597758353000074],
              [-162.699885651999949, 1.621330353000133],
              [-162.682527651999834, 1.64475035300012],
              [-162.664966650999958, 1.668014353000103],
              [-162.647205651, 1.691125354000178],
              [-162.629244650999908, 1.714080354000032],
              [-162.611083650999973, 1.736877354000114],
              [-162.592727650999961, 1.75951135400004],
              [-162.574172650999941, 1.781986353999969],
              [-162.555424650999925, 1.804297353999971],
              [-162.536485650999936, 1.826441354000053],
              [-162.517355650999917, 1.8484193540001],
              [-162.498033650999929, 1.870227354000065],
              [-162.478522650999935, 1.89186435400012],
              [-162.458824650999844, 1.913330354000038],
              [-162.438941650999965, 1.934625354],
              [-162.418874650999982, 1.955741354000111],
              [-162.398624650999949, 1.976680354000109],
              [-162.378194650999944, 1.997441353999989],
              [-162.366666650999917, 2.016666354000108],
              [-162.026388650999849, 2.028333353999983],
              [-161.692499650999906, 2.055555354000091],
              [-161.605555650999918, 2.041666354000085],
              [-161.373333649999921, 2.003611354000157],
              [-160.344999648999931, 1.838333354000099],
              [-159.883055648999942, 1.762777354000093],
              [-159.657499648999931, 1.72527735400007],
              [-158.984444647999823, 0.981389353000111],
              [-158.80666664799989, 0.782777352999972],
              [-158.301666646999905, 0.210000352000066],
              [-158.124166646999953, -0.004722647999984],
              [-157.828888646999928, -0.406388648000018],
              [-157.811944646999962, -0.428888647999941],
              [-157.41444464699984, -0.970833648999928],
              [-157.816944646999957, -2.223888649999765],
              [-158.174999646999851, -3.177777650999928],
              [-158.626638647999926, -3.42266365099988],
              [-158.653027647999949, -3.434916651000037],
              [-158.679524647999955, -3.446938650999982],
              [-158.706277647999912, -3.458724651000011],
              [-158.732830647999918, -3.47030565099999],
              [-158.759633648, -3.481597651000016],
              [-158.786533647999931, -3.492680651],
              [-158.813527647999848, -3.503527650999956],
              [-158.840619647999944, -3.51413565099989],
              [-158.867999647999909, -3.524505651000027],
              [-158.895069647999946, -3.534638650999966],
              [-158.922427647999967, -3.54452765100001],
              [-158.949872647999968, -3.554305651],
              [-158.977397647999936, -3.563588650999975],
              [-159.005055647999967, -3.572755650999966],
              [-159.032691647999883, -3.581805650999854],
              [-159.060455647999817, -3.590360650999969],
              [-159.088294647999902, -3.59879765099987],
              [-159.116205647999919, -3.606988650999938],
              [-159.144360647999946, -3.614933650999944],
              [-159.172360647999966, -3.622633650999944],
              [-159.200349647999957, -3.630088650999767],
              [-159.228530647999918, -3.637294650999934],
              [-159.256772647999952, -3.644252650999931],
              [-159.28507264799984, -3.650960650999764],
              [-159.313430647999979, -3.65742265099982],
              [-159.341844647999864, -3.663833650999777],
              [-159.370310647999958, -3.669594651000011],
              [-159.398830647999915, -3.675305651000031],
              [-159.427397648000039, -3.680766651],
              [-159.45601064899995, -3.685977650999916],
              [-159.484669648999869, -3.690933651000023],
              [-159.513369648999884, -3.695641651999892],
              [-159.542108648999914, -3.700094651999848],
              [-159.570885648999905, -3.704294651999987],
              [-159.599699648999859, -3.708244651999962],
              [-159.628547648999955, -3.711938651999958],
              [-159.65742464899995, -3.715377652000029],
              [-159.686333648999863, -3.718566651999936],
              [-159.715266648999972, -3.721499651999864],
              [-159.744224648999904, -3.724277651999955],
              [-159.773205648999834, -3.72660265199994],
              [-159.802205648999887, -3.728772651999805],
              [-159.831224649, -3.730685651999977],
              [-159.86025864899986, -3.73234765199993],
              [-159.889305648999965, -3.733752652000021],
              [-159.918363649000042, -3.734899651999797],
              [-159.947433648999919, -3.735794651999925],
              [-159.976505648999904, -3.73643365199996],
              [-160.005585648999926, -3.736816651999959],
            ],
          ],
          [
            [
              [-177.433333664999822, -2.979166650999957],
              [-177.487730664999958, -3.004788650999956],
              [-177.515402664999925, -2.995869650999964],
              [-177.542997664999831, -2.986705650999966],
              [-177.570508664999949, -2.977302651],
              [-177.597938664999958, -2.967658650999908],
              [-177.625333664999914, -2.957772650999857],
              [-177.652541664999859, -2.947644650999962],
              [-177.679708664999936, -2.93728065099998],
              [-177.706785665999973, -2.926777650999952],
              [-177.733769665999915, -2.915860650999775],
              [-177.760655665999963, -2.904760651],
              [-177.787447665999849, -2.893447650999917],
              [-177.814135665999913, -2.881899651000026],
              [-177.840724665999886, -2.870119650999925],
              [-177.867210665999949, -2.858102650999967],
              [-177.893591665999935, -2.846055650999972],
              [-177.919863665999941, -2.833377650999864],
              [-177.946024665999943, -2.820669651],
              [-177.972077665999961, -2.807733651000035],
              [-177.998013665999906, -2.794566650999911],
              [-178.023835665999911, -2.781172650999792],
              [-178.049538665999933, -2.767552650999789],
              [-178.07512266599997, -2.753708651000011],
              [-178.100585665999944, -2.73963865099978],
              [-178.125924665999946, -2.725347650999936],
              [-178.151138665999923, -2.710833650999973],
              [-178.17622766599996, -2.696097651],
              [-178.201183666, -2.68114465099984],
              [-178.226010666, -2.665969650999784],
              [-178.250702665999938, -2.650805650999928],
              [-178.275260666, -2.634972650999885],
              [-178.299683666, -2.61914965],
              [-178.323966665999848, -2.603113649999784],
              [-178.348110665999911, -2.586866649999934],
              [-178.372110665999912, -2.570405649999941],
              [-178.395966665999964, -2.553735649999851],
              [-178.419677665999927, -2.53685865],
              [-178.443241665999977, -2.519769649999944],
              [-178.466652665999987, -2.502477649999946],
              [-178.489916666, -2.484980650000011],
              [-178.513024665999893, -2.467277650000028],
              [-178.535977665999923, -2.449374649999925],
              [-178.558777665999827, -2.431272649999983],
              [-178.58141666599991, -2.412966649999873],
              [-178.583305665999973, -2.411302649999925],
              [-178.605583665999916, -2.392999649999979],
              [-178.627877665999961, -2.37410265],
              [-178.650030665999822, -2.355208649999952],
              [-178.672019665999926, -2.336194649999982],
              [-178.693841665999912, -2.316841649999901],
              [-178.715497665999919, -2.297369649999951],
              [-178.736980665999937, -2.277708649999965],
              [-178.758294665999898, -2.25786065],
              [-178.77943366699995, -2.237827649999986],
              [-178.80039766699997, -2.217608649999931],
              [-178.821185666999867, -2.197205649999944],
              [-178.841794666999846, -2.176622649999899],
              [-178.862249666999958, -2.156083650000028],
              [-178.882474666999968, -2.134913649999874],
              [-178.902541666999952, -2.113794649999988],
              [-178.922422666999921, -2.092499649999979],
              [-178.942119666999929, -2.071030650000012],
              [-178.961627666999874, -2.049388649999798],
              [-178.980949666999862, -2.027577650000012],
              [-179.000080666999935, -2.005597650000027],
              [-179.019019666999952, -1.983449649999898],
              [-179.037766666999971, -1.961138649999953],
              [-179.056316666999919, -1.938663650000024],
              [-179.07467266699993, -1.91602465],
              [-179.092833666999951, -1.893224650000036],
              [-179.110791666999972, -1.870269650000012],
              [-179.128552666999866, -1.84715565],
              [-179.146110666999988, -1.823885650000022],
              [-179.16346966699993, -1.800463649999813],
              [-179.180622666999938, -1.776891649999982],
              [-179.197569666999982, -1.753166650000026],
              [-179.21431066699995, -1.729297650000035],
              [-179.230944666999932, -1.705305650000014],
              [-179.24716966699998, -1.681194649999895],
              [-179.263283666999854, -1.65681665],
              [-179.27936066699985, -1.632372649999979],
              [-179.294874666999959, -1.60788865],
              [-179.310352666999876, -1.58319465],
              [-179.325613666999942, -1.558213649999914],
              [-179.340658666999985, -1.533227648999969],
              [-179.355488666999833, -1.508110648999946],
              [-179.370097666999953, -1.482860648999917],
              [-179.384488666999971, -1.457488648999856],
              [-179.398658666999921, -1.431988649],
              [-179.412608666999944, -1.406363648999957],
              [-179.426333666999938, -1.380619648999755],
              [-179.439835666999954, -1.354755648999912],
              [-179.453113666999826, -1.328772648999916],
              [-179.466166666999897, -1.302674648999869],
              [-179.478991666999917, -1.276466648999985],
              [-179.491588666999917, -1.250141648999943],
              [-179.503958666999978, -1.223710649],
              [-179.516097666999912, -1.197172648999981],
              [-179.528008666999909, -1.170527649],
              [-179.539685666999929, -1.143777648999844],
              [-179.551305666999923, -1.11692764899999],
              [-179.562344666999962, -1.08998064899987],
              [-179.573655666999969, -1.061933648999798],
              [-179.584399666999957, -1.034791649],
              [-179.594908666999942, -1.007558649000032],
              [-179.605180666999956, -0.980230649000021],
              [-179.61521666699997, -0.952816649],
              [-179.625166666999831, -0.925313648999918],
              [-179.634574666999924, -0.897724649],
              [-179.643944666999914, -0.870055648999823],
              [-179.652974666999938, -0.842305648999911],
              [-179.661813666999933, -0.814474649000033],
              [-179.670413666999906, -0.786569648999887],
              [-179.678769666999841, -0.758588648999876],
              [-179.686883666999904, -0.730535648999989],
              [-179.69475266699996, -0.702413648999936],
              [-179.702380666999943, -0.674224648999939],
              [-179.709763666999862, -0.645966648999888],
              [-179.716899666999922, -0.617647648999935],
              [-179.723791666999944, -0.589266648999853],
              [-179.730438666999959, -0.560824648999869],
              [-179.736838666999972, -0.532324648999975],
              [-179.742991666999956, -0.50377264899987],
              [-179.748972666999947, -0.475166649000016],
              [-179.754555666999948, -0.446508647999906],
              [-179.759966666999929, -0.417805648000012],
              [-179.765127666999916, -0.389052647999961],
              [-179.770038666999909, -0.360255647999963],
              [-179.774699666999908, -0.331419648],
              [-179.783733666999979, -0.295141648000026],
              [-179.793166666999952, -0.267477647999954],
              [-179.802160666999981, -0.239805647999958],
              [-179.811016666999905, -0.211908647999962],
              [-179.819630666999956, -0.184005647999925],
              [-179.828002666999964, -0.156030648000012],
              [-179.836333666999963, -0.12798364799977],
              [-179.844019666999969, -0.099866647999932],
              [-179.851660667999909, -0.071805647999952],
              [-179.859060667999955, -0.043427648000034],
              [-179.866213667999915, -0.015110647999961],
              [-179.869713667999946, -0.000135647999855],
              [-179.876622667999982, 0.028241352000066],
              [-179.883285667999985, 0.056680352000171],
              [-179.889702667999899, 0.085175352000078],
              [-179.895872667999953, 0.113722352000082],
              [-179.901797667999915, 0.142325352000057],
              [-179.907472668, 0.170980352000072],
              [-179.912899667999966, 0.199680352000087],
              [-179.918077667999938, 0.228430351999975],
              [-179.923005667999831, 0.257222352000156],
              [-179.927685667999896, 0.286058352000055],
              [-179.932116667999964, 0.314933351999969],
              [-179.936297667999952, 0.343847352000068],
              [-179.940227667999949, 0.372794352000142],
              [-179.943908667999978, 0.401775352000129],
              [-179.947335667999937, 0.430789351999977],
              [-179.950513667999985, 0.459830351999983],
              [-179.953441667999954, 0.48890035200003],
              [-179.956116667999936, 0.517994352000187],
              [-179.95853866799996, 0.547108352000123],
              [-179.96070866799991, 0.576244352],
              [-179.962627667999982, 0.605397353000114],
              [-179.964294667999894, 0.634566353000025],
              [-179.965708667999905, 0.663750353000125],
              [-179.966869667999958, 0.692944352999973],
              [-179.96777766799994, 0.722147353000025],
              [-179.96843366799996, 0.751358353000057],
              [-179.968835667999912, 0.780572352999968],
              [-179.968985667999988, 0.809789353000099],
              [-179.968885667999956, 0.839008353000111],
              [-179.968530668, 0.868222353000021],
              [-179.967922667999943, 0.897433353],
              [-179.967060667999959, 0.926639352999985],
              [-179.965947667999956, 0.955833353],
              [-179.964580667999911, 0.985019353000098],
              [-179.962960667999909, 1.014191353000058],
              [-179.961088667999917, 1.043347353],
              [-179.958966667999931, 1.07248935299998],
              [-179.956591667999902, 1.101608353000017],
              [-179.953963667999886, 1.130705353000053],
              [-179.951083667999967, 1.159777353000095],
              [-179.947952667999829, 1.188825353000141],
              [-179.944569667999957, 1.217844353000089],
              [-179.940935667999923, 1.246830353000121],
              [-179.937052667999978, 1.275786353],
              [-179.932916667999905, 1.304705353000031],
              [-179.928530667999979, 1.333586353000101],
              [-179.92389766799991, 1.362430353000093],
              [-179.919010667999942, 1.391230353000026],
              [-179.913877667999969, 1.419989353000176],
              [-179.907666667999962, 1.451694353000107],
              [-179.902035667999911, 1.480355353000107],
              [-179.896155667999921, 1.508966353],
              [-179.890030667999952, 1.537527353000087],
              [-179.88365566799996, 1.566030352999974],
              [-179.877035667999877, 1.594480353000108],
              [-179.870169667999932, 1.622866353000063],
              [-179.86305566799993, 1.651194353],
              [-179.855699667999943, 1.679458354000133],
              [-179.848097666999962, 1.707655354000053],
              [-179.840249666999966, 1.735786354000084],
              [-179.832160666999926, 1.763847354000177],
              [-179.823827666999932, 1.791836354000168],
              [-179.815255667, 1.819750354000121],
              [-179.806438666999924, 1.8475863540001],
              [-179.797380666999914, 1.875347354000041],
              [-179.788083666999938, 1.903025354000022],
              [-179.77854766699997, 1.930622354000093],
              [-179.768769666999987, 1.958133354000154],
              [-179.758755666999917, 1.985558353999977],
              [-179.748505666999932, 2.012894354000139],
              [-179.738016666999926, 2.040139354000019],
              [-179.727291666999918, 2.06729135400002],
              [-179.716333666999986, 2.094347354000092],
              [-179.705141666999964, 2.121305354000015],
              [-179.693713666999969, 2.148166354000125],
              [-179.682055666999929, 2.174925354000095],
              [-179.670163666999912, 2.201580354000043],
              [-179.658041666999964, 2.228133354000022],
              [-179.645691666999966, 2.25457535400011],
              [-179.633110666999954, 2.280908354000019],
              [-179.620302666999976, 2.30713035399998],
              [-179.607266666999976, 2.333239354000057],
              [-179.594005666999919, 2.359233353999969],
              [-179.580516666999927, 2.385111354000117],
              [-179.566805666999954, 2.410866354000177],
              [-179.552872666999974, 2.43650235399997],
              [-179.53871666699996, 2.462014354000189],
              [-179.524338666999853, 2.487402354000039],
              [-179.509741666999929, 2.512661354000102],
              [-179.494927666999985, 2.537794354000027],
              [-179.47989466699994, 2.562794354000175],
              [-179.464644666999988, 2.58766135400009],
              [-179.44917766699993, 2.612391354000124],
              [-179.433499666999836, 2.636989353999979],
              [-179.417605666999975, 2.661447354000018],
              [-179.40150266699996, 2.685764354000128],
              [-179.385185666999831, 2.709939354000028],
              [-179.368663666999879, 2.733966354000131],
              [-179.351930666999948, 2.757852355000182],
              [-179.334988666999976, 2.781589355000051],
              [-179.317844666999974, 2.805177355000069],
              [-179.300494666999924, 2.828614355000184],
              [-179.282941666999847, 2.85189435500007],
              [-179.265188666999904, 2.875022355],
              [-179.247233666999961, 2.897994355000037],
              [-179.229077666999984, 2.920808355000133],
              [-179.210727666999873, 2.943458355000132],
              [-179.192177666999982, 2.965950355000018],
              [-179.173435666999922, 2.98827735499998],
              [-179.154499666999982, 3.01043935500013],
              [-179.135369666999907, 3.032433355000023],
              [-179.116049666999828, 3.054258355000172],
              [-179.096541666999968, 3.075914355],
              [-179.076847666999953, 3.097397355000112],
              [-179.056963666999906, 3.118708354999967],
              [-179.03689766699992, 3.139841355000044],
              [-179.016647666999887, 3.160800355000163],
              [-178.99621666699997, 3.181577355000059],
              [-178.975602666999919, 3.202177355000117],
              [-178.954813666999968, 3.222594355000126],
              [-178.93384466699996, 3.242827354999974],
              [-178.912702666999934, 3.262875355],
              [-178.89138566699998, 3.282739355000047],
              [-178.869897666999918, 3.30241435500011],
              [-178.848235666999955, 3.32189735500009],
              [-178.82640866700001, 3.341191355000092],
              [-178.80441366699992, 3.360294355000065],
              [-178.782252666999938, 3.379202355000075],
              [-178.759927665999925, 3.397916355000064],
              [-178.737438665999974, 3.416430354999989],
              [-178.714791665999968, 3.434750355000176],
              [-178.691985665999908, 3.452869355000132],
              [-178.669022665999933, 3.47078635500003],
              [-178.645902665999927, 3.488500355000212],
              [-178.622630665999964, 3.506011355000112],
              [-178.599205665999961, 3.52331935500024],
              [-178.575630665999967, 3.54041935500004],
              [-178.551908665999832, 3.557311355000081],
              [-178.528038665999929, 3.573994355000195],
              [-178.504024665999879, 3.590469355000153],
              [-178.479869665999928, 3.606730355000195],
              [-178.455572665999824, 3.622780355000202],
              [-178.406563665999869, 3.654236355000137],
              [-178.381852665999929, 3.669639355000243],
              [-178.357010665999923, 3.684825355000157],
              [-178.332035665999911, 3.699794355000222],
              [-178.306933665999964, 3.714541355],
              [-178.281702665999916, 3.7290663550001],
              [-178.256344665999933, 3.74337235500019],
              [-178.230863665999976, 3.757452355000055],
              [-178.205260665999987, 3.771311355000137],
              [-178.179538665999985, 3.784941355000058],
              [-178.153697665999914, 3.798347354999976],
              [-178.127738665999942, 3.811525355000185],
              [-178.101669665999935, 3.82447535600005],
              [-178.075485665999906, 3.837194356000111],
              [-178.049191666000013, 3.849683356000185],
              [-178.022791665999961, 3.861941356000102],
              [-177.996283665999925, 3.873966356000096],
              [-177.969672665999923, 3.885758356000224],
              [-177.942958665999925, 3.897316356000147],
              [-177.91614466599998, 3.908641355999976],
              [-177.889233665999939, 3.919727356000067],
              [-177.862224665999946, 3.930577356000242],
              [-177.835124665999984, 3.941191356000218],
              [-177.807930665999976, 3.951566356000114],
              [-177.780649665999931, 3.961700356],
              [-177.75328066599991, 3.971597356000132],
              [-177.725824666, 3.981250356000032],
              [-177.698285664999929, 3.990664356000195],
              [-177.670666664999914, 3.999833356000124],
              [-177.642966664999875, 4.008761356000093],
              [-177.615191664999912, 4.017447356000048],
              [-177.587338664999976, 4.025886356000171],
              [-177.559416664999873, 4.034080356000118],
              [-177.531422664999923, 4.042030356000112],
              [-177.503360664999917, 4.049733356000104],
              [-177.475230664999913, 4.057189356000094],
              [-177.473897664999924, 4.05735535600013],
              [-177.445705664999934, 4.064564356000119],
              [-177.417449664999964, 4.071525356000166],
              [-177.389135664999912, 4.07823935600004],
              [-177.360763664999951, 4.08470235600015],
              [-177.332335664999903, 4.090916356000207],
              [-177.303855664999958, 4.096880356000213],
              [-177.275322664999919, 4.102594356000111],
              [-177.246741664999973, 4.108058356000129],
              [-177.218113664999947, 4.11326935600016],
              [-177.189441664999947, 4.118227356000034],
              [-177.160727664999939, 4.122936356000082],
              [-177.131974664999973, 4.127391356000146],
              [-177.103180664999911, 4.131594356000051],
              [-177.074352664999964, 4.135544356000025],
              [-177.045491664999957, 4.13924135600007],
              [-177.016599664999944, 4.142683356000077],
              [-176.98767766499995, 4.145872356000154],
              [-176.958730664999933, 4.148805356000196],
              [-176.929755664999817, 4.151486355999964],
              [-176.900760664999979, 4.153911356000037],
              [-176.871744664999937, 4.156083356000181],
              [-176.842713665, 4.157997356000124],
              [-176.81366366499995, 4.159658356000023],
              [-176.784599664999945, 4.161064356000225],
              [-176.75552766499996, 4.162214356000163],
              [-176.726444664999889, 4.163108356000237],
              [-176.697355664999918, 4.163747356000158],
              [-176.668263664999898, 4.164130356000214],
              [-176.639166664999976, 4.164258356000175],
              [-176.610069663999894, 4.164130356000214],
              [-176.580977663999931, 4.163747356000158],
              [-176.551888663999904, 4.163108356000237],
              [-176.522805663999975, 4.162214356000163],
              [-176.493733663999848, 4.161064356000225],
              [-176.464669663999985, 4.159658356000023],
              [-176.435619663999972, 4.157997356000124],
              [-176.406588663999912, 4.156083356000181],
              [-176.377572663999956, 4.153911356000037],
              [-176.348577663999976, 4.151486355999964],
              [-176.319602663999859, 4.148805356000196],
              [-176.290655664000013, 4.145872356000154],
              [-176.261733663999905, 4.142683356000077],
              [-176.232841663999835, 4.13924135600007],
              [-176.203980664, 4.135544356000025],
              [-176.175152663999938, 4.131594356000051],
              [-176.146358663999962, 4.127391356000146],
              [-176.117605663999939, 4.122936356000082],
              [-176.088891663999902, 4.118227356000034],
              [-176.060219663999987, 4.11326935600016],
              [-176.031591663999905, 4.108058356000129],
              [-176.003010663999959, 4.102594356000111],
              [-175.974477663999977, 4.096880356000213],
              [-175.945997663999975, 4.090916356000207],
              [-175.917569663999984, 4.08470235600015],
              [-175.889197663999937, 4.07823935600004],
              [-175.860883663999971, 4.071525356000166],
              [-175.832627663999915, 4.064564356000119],
              [-175.804435663999925, 4.05735535600013],
              [-175.776305663999921, 4.04990035600008],
              [-175.748244663999913, 4.042197356000088],
              [-175.720249663999965, 4.034247356000094],
              [-175.692324663999955, 4.026052356000037],
              [-175.664474663999925, 4.017614356000024],
              [-175.636699664, 4.008927356000129],
              [-175.608999663999924, 4.000000356000101],
              [-175.581380663999909, 3.99083035600006],
              [-175.553841662999872, 3.981416356000238],
              [-175.526385662999843, 3.971764356000108],
              [-175.499016662999821, 3.96186635600003],
              [-175.471733662999981, 3.951733356000091],
              [-175.467569662999978, 3.950066356000207],
              [-175.440377662999936, 3.939691356000083],
              [-175.413277662999974, 3.929080355999986],
              [-175.386269662999979, 3.918227356000159],
              [-175.359358662999938, 3.907141356000182],
              [-175.332544662999908, 3.895819356000061],
              [-175.305830662999909, 3.884261355999968],
              [-175.279219663, 3.872466356000189],
              [-175.252710662999959, 3.860441356000138],
              [-175.226310662999936, 3.84818335600022],
              [-175.200016662999928, 3.835694356000033],
              [-175.173833662999982, 3.822975356000143],
              [-175.147763662999978, 3.810025355000221],
              [-175.121805662999833, 3.796847355000182],
              [-175.095963662999935, 3.78344135500015],
              [-175.070241662999933, 3.769811355],
              [-175.044638662999859, 3.755952355000147],
              [-175.019158662999899, 3.741872355000112],
              [-174.993799662999919, 3.727569355000071],
              [-174.968569662999954, 3.713041355000087],
              [-174.943466662999839, 3.698294355000087],
              [-174.918491662999912, 3.683327355000188],
              [-174.89364966299982, 3.668141355000103],
              [-174.868938662999966, 3.652736355],
              [-174.844366662999931, 3.637116355000103],
              [-174.819930662999923, 3.621280355000067],
              [-174.79563366299999, 3.605233355000166],
              [-174.771477662999956, 3.588969355000074],
              [-174.74746366299982, 3.572497355000166],
              [-174.723594662999915, 3.555814355000223],
              [-174.699872662999951, 3.538919355000132],
              [-174.676297662999872, 3.521819355000105],
              [-174.652872662999954, 3.504514355000026],
              [-174.629599662999908, 3.487002355000243],
              [-174.606480662999985, 3.469286355000065],
              [-174.583516662999926, 3.451369355],
              [-174.560710662999981, 3.433250355000098],
              [-174.538063662999861, 3.41493335500013],
              [-174.51557466299991, 3.396416355000099],
              [-174.493249662999972, 3.377702355000167],
              [-174.471088661999914, 3.358794355000157],
              [-174.469427661999902, 3.35696435500023],
              [-174.447430661999903, 3.337861355000086],
              [-174.42560266199996, 3.31856635500003],
              [-174.403944661999958, 3.29908335500005],
              [-174.382455661999984, 3.279408355000157],
              [-174.361138661999888, 3.25954435500006],
              [-174.339994661999953, 3.239497354999969],
              [-174.319027661999911, 3.219264355000121],
              [-174.298235662, 3.198847355000112],
              [-174.264474661999913, 3.171419355000182],
              [-174.244041661999916, 3.150641355000175],
              [-174.223791661999968, 3.129683355000168],
              [-174.203724661999985, 3.108550355000091],
              [-174.183841661999935, 3.087241355],
              [-174.16414766199992, 3.065758355000071],
              [-174.144638661999977, 3.044102355000064],
              [-174.125319661999981, 3.022277355000085],
              [-174.106191661999958, 3.000283355000022],
              [-174.087255661999933, 2.978119355000047],
              [-174.068510661999909, 2.955794355000137],
              [-174.049963661999925, 2.933302355000023],
              [-174.031610661999906, 2.91065035500003],
              [-174.01345866199992, 2.88783935500004],
              [-173.995502661999893, 2.864866355000117],
              [-173.977747661999956, 2.841739355000072],
              [-173.960194661999964, 2.818458355000075],
              [-173.942844661999914, 2.795022355000015],
              [-173.925699661999914, 2.771433355000113],
              [-173.90875866199994, 2.747697354000081],
              [-173.892027661999919, 2.723814354000183],
              [-173.875502661999974, 2.69978335400009],
              [-173.859185661999931, 2.675608354000019],
              [-173.843083661999913, 2.651291354000136],
              [-173.827188661999969, 2.626833354000098],
              [-173.811510661999961, 2.602239354000176],
              [-173.796044661999986, 2.577505354000039],
              [-173.780794661999863, 2.552639354000178],
              [-173.765760661999906, 2.52763935400003],
              [-173.75094466199991, 2.502508354000099],
              [-173.736349661999952, 2.477247354000042],
              [-173.721972661999928, 2.45186135400013],
              [-173.707816661999829, 2.426350354000022],
              [-173.693880661999884, 2.400714354000058],
              [-173.680169661999969, 2.374958354000057],
              [-173.666683661999883, 2.349080353999966],
              [-173.653422661999826, 2.323086354],
              [-173.640385661999915, 2.296977353999978],
              [-173.627577661999936, 2.270755354000187],
              [-173.614997661999922, 2.244422354000051],
              [-173.60264466199996, 2.217980354000019],
              [-173.590522661999984, 2.19143035400009],
              [-173.578633661999874, 2.164775353999971],
              [-173.56697266199987, 2.138014354],
              [-173.555547661999924, 2.111155354000061],
              [-173.544352661999852, 2.084194354000033],
              [-173.533394661999836, 2.057139354000071],
              [-173.522669661999913, 2.029986354000187],
              [-173.512180661999963, 2.002741354000079],
              [-173.501930661999921, 1.975405353999975],
              [-173.491913661999945, 1.947980354000094],
              [-173.482138661999926, 1.92046935400009],
              [-173.4726026619999, 1.892875354000068],
              [-173.463302661999847, 1.865194353999982],
              [-173.454247661999972, 1.837436354000147],
              [-173.445430661999836, 1.809597354000118],
              [-173.436855661999971, 1.781683354000108],
              [-173.428522661999835, 1.753697354000053],
              [-173.420433661999965, 1.72563635400013],
              [-173.412585661999969, 1.6975053540001],
              [-173.40498366099996, 1.669308353000076],
              [-173.398466660999958, 1.637550353],
              [-173.391352660999956, 1.609222353],
              [-173.384485660999928, 1.580833353000173],
              [-173.377863660999935, 1.552386353000088],
              [-173.371491660999936, 1.523883353000031],
              [-173.365363660999975, 1.495322353],
              [-173.359483660999928, 1.466711353000051],
              [-173.353852660999934, 1.438050353000051],
              [-173.348469660999967, 1.409339353000064],
              [-173.343335660999912, 1.380580353000141],
              [-173.338449660999942, 1.351780352999981],
              [-173.333816660999958, 1.322939353000038],
              [-173.329430660999947, 1.29405535300009],
              [-173.325516660999938, 1.289130353000076],
              [-173.316205660999941, 1.261455352999974],
              [-173.307135660999904, 1.233702353000126],
              [-173.298305660999944, 1.205869353000082],
              [-173.289716660999915, 1.177961353000057],
              [-173.281369660999843, 1.149977353000168],
              [-173.273266660999951, 1.121922353],
              [-173.26540566099996, 1.093794353000078],
              [-173.257788660999978, 1.065602353000031],
              [-173.250416660999917, 1.037341353000102],
              [-173.249585660999912, 1.032183353],
              [-173.242460660999967, 1.003861353],
              [-173.235577660999951, 0.975477352999974],
              [-173.228941660999965, 0.947033353000052],
              [-173.22255266099998, 0.918533353000043],
              [-173.216408660999917, 0.889977353000063],
              [-173.210513660999965, 0.861369352999986],
              [-173.204866660999983, 0.832711353000093],
              [-173.199466660999946, 0.804002353000044],
              [-173.194316660999817, 0.775247353000111],
              [-173.18941666099991, 0.746450353000057],
              [-173.184763660999948, 0.717611353000052],
              [-173.18036066099998, 0.688730352999983],
              [-173.176210660999971, 0.659814353000058],
              [-173.172308660999903, 0.630861353000057],
              [-173.168658660999967, 0.601875353000025],
              [-173.165258660999939, 0.572861352000018],
              [-173.162108660999962, 0.543814352000084],
              [-173.159210660999832, 0.514744352000037],
              [-173.156566660999914, 0.485647352],
              [-173.154174660999956, 0.45652735200008],
              [-173.152033660999933, 0.427391352000029],
              [-173.150144660999956, 0.398236352000026],
              [-173.148505660999945, 0.369064352000066],
              [-173.147122660999912, 0.339880351999966],
              [-173.145991660999954, 0.310683352000012],
              [-173.145110660999904, 0.281480352000131],
              [-173.144485660999976, 0.252269352000098],
              [-173.457777661999927, -0.242222647999938],
              [-173.758333661999927, -0.731110648999959],
              [-174.294722661999941, -1.068333648999953],
              [-174.517222662999956, -1.210833649],
              [-174.579999662999967, -1.247777649],
              [-175.580833663999954, -1.876666649999947],
              [-175.758055663999983, -1.988055649999865],
              [-176.23277766399994, -2.285833649999972],
              [-176.649722664999871, -2.547499649999907],
              [-176.850833664999925, -2.67388865099997],
              [-176.966944664999914, -2.746944650999978],
              [-176.968888664999923, -2.748055650999959],
              [-177.278610664999832, -2.942499650999878],
              [-177.433333664999822, -2.979166650999957],
            ],
          ],
          [
            [
              [-160.741177649999855, 9.349211361000073],
              [-160.715705649999933, 9.33461636100003],
              [-160.732860649999935, 9.345380361000025],
              [-160.707388649999956, 9.330786361000037],
              [-160.68204765, 9.315966361000221],
              [-160.656838649999855, 9.300930361000155],
              [-160.63176665, 9.285672361000138],
              [-160.606830649999921, 9.270197361000157],
              [-160.582033649999829, 9.25450236100005],
              [-160.557377649999836, 9.238594361000082],
              [-160.53286064999989, 9.2224723610002],
              [-160.508491648999922, 9.206136361],
              [-160.48426964899997, 9.189589360000014],
              [-160.460194648999902, 9.172830359999978],
              [-160.436269648999911, 9.155861360000017],
              [-160.41249764899996, 9.13868636],
              [-160.388877648999909, 9.121302360000186],
              [-160.365413648999947, 9.10371436000014],
              [-160.342108648999925, 9.08592236000004],
              [-160.318960648999905, 9.067927360000112],
              [-160.295974648999817, 9.049730360000126],
              [-160.273152648999968, 9.031333360000076],
              [-160.250491648999912, 9.012739360000126],
              [-160.227999648999912, 8.993947360000107],
              [-160.236885648999845, 9.002747360000114],
              [-160.214391648999936, 8.983955360000095],
              [-160.192066648999827, 8.964969360000168],
              [-160.169910648999831, 8.945789360000219],
              [-160.147927649000025, 8.926416360000076],
              [-160.126116648999954, 8.906852360000187],
              [-160.104480648999839, 8.887097360000212],
              [-160.083019648999965, 8.867155360000083],
              [-160.061738648999977, 8.847027360000197],
              [-160.040635648999967, 8.826714360000096],
              [-160.019713648999954, 8.806219360000227],
              [-159.998972648999967, 8.785539360000143],
              [-159.978416649000025, 8.76468036000017],
              [-159.958047649, 8.743644360000133],
              [-159.9840606489999, 8.771719360000134],
              [-159.96350564899987, 8.750861360000044],
              [-159.943135648999856, 8.72982536],
              [-159.922955648999931, 8.708611360000134],
              [-159.902960648999937, 8.68722536000007],
              [-159.883158648999938, 8.665661360000229],
              [-159.863547648999855, 8.643927359999964],
              [-159.844130648999823, 8.62202236000013],
              [-159.824908648999923, 8.59995036],
              [-159.805880648999931, 8.57771136000008],
              [-159.787049648999897, 8.555305360000148],
              [-159.7809246489999, 8.549314360000096],
              [-159.762294648999955, 8.526744360000123],
              [-159.743863649000076, 8.504016360000207],
              [-159.725635648999912, 8.481125360000135],
              [-159.707610648999946, 8.458077360000232],
              [-159.689791648999972, 8.434875360000035],
              [-159.672177648999906, 8.411516360000178],
              [-159.654769648999917, 8.388005360000136],
              [-159.637569648999914, 8.364341360000083],
              [-159.620580648999947, 8.340530360000116],
              [-159.60380264899996, 8.316572360000066],
              [-159.587235648999922, 8.292469360000041],
              [-159.57088364899991, 8.268222360000095],
              [-159.554744648999957, 8.243833360000224],
              [-159.538822648999911, 8.219305360000078],
              [-159.523116648999888, 8.194639360000053],
              [-159.507630648999935, 8.16983636000009],
              [-159.49236064899992, 8.144897360000073],
              [-159.477313648999967, 8.119827360000158],
              [-159.462488648999937, 8.094630359],
              [-159.447885647999868, 8.069302359000119],
              [-159.433505647999937, 8.043847358999983],
              [-159.419352647999972, 8.018269359000044],
              [-159.405422648000098, 7.992569359000242],
              [-159.391722647999956, 7.966747359000124],
              [-159.378247647999871, 7.94080835900013],
              [-159.37472264799996, 7.91777735900007],
              [-159.658333648999928, 7.518055359000186],
              [-159.909722648999974, 7.161944359000188],
              [-160.33083364899997, 6.561111358000119],
              [-160.354999648999836, 6.52694435799998],
              [-160.427777648999921, 6.42527735800013],
              [-160.694999649999914, 6.051389358000108],
              [-160.920277649999917, 5.736666357000047],
              [-161.471944649999983, 4.956944357],
              [-161.62166665099997, 4.743889356000238],
              [-162.215555650999846, 3.906944356000224],
              [-163.087222651999923, 2.663889354000105],
              [-165.107138653999925, 4.417219356000146],
              [-165.12424765399993, 4.440855356000043],
              [-165.141152653999967, 4.464636356000199],
              [-165.157852653999981, 4.488566356000206],
              [-165.174347653999916, 4.512641356000188],
              [-165.190630653999904, 4.536858356000209],
              [-165.206705653999904, 4.561216356000102],
              [-165.222566653999962, 4.585714356000096],
              [-165.238219653999892, 4.610347356000204],
              [-165.253655653999942, 4.635119356000132],
              [-165.268880653999958, 4.660022356000241],
              [-165.283885653999903, 4.685058356000127],
              [-165.298677653999988, 4.710222356000145],
              [-165.313249653999975, 4.735516356000232],
              [-165.327602653999946, 4.760936356000116],
              [-165.341735653999962, 4.786480356000141],
              [-165.355649653999933, 4.812147356000196],
              [-165.369338653999961, 4.837933356000121],
              [-165.382805653999924, 4.863839356000085],
              [-165.396047653999915, 4.889858356000161],
              [-165.409060653999887, 4.915994357000045],
              [-165.421852653999935, 4.942244357000163],
              [-165.434413654, 4.968602357000066],
              [-165.446747653999978, 4.995069357000034],
              [-165.458852653999969, 5.021641357000192],
              [-165.47072765399983, 5.048322357000131],
              [-165.482369653999939, 5.075102357000105],
              [-165.493780653999949, 5.101983357000222],
              [-165.504958653999978, 5.128964357000143],
              [-165.515902653999973, 5.156039357],
              [-165.52661065399991, 5.183208357000183],
              [-165.537085653999867, 5.210472357000015],
              [-165.547322653999913, 5.237825357000133],
              [-165.557322653999961, 5.265266357000087],
              [-165.567083653999987, 5.292794357000105],
              [-165.576608653999983, 5.320405357000084],
              [-165.585891653999965, 5.348097357000029],
              [-165.594933653999902, 5.375872357000219],
              [-165.603735654, 5.403722357000049],
              [-165.612297654, 5.431647357000088],
              [-165.620616653999974, 5.459650356999987],
              [-165.628691653999908, 5.487719357],
              [-165.636524653999913, 5.515861357000063],
              [-165.644110653999917, 5.54406935700024],
              [-165.651455653999875, 5.572341356999971],
              [-165.658552653999976, 5.600677357000109],
              [-165.665405653999983, 5.629075356999977],
              [-165.672010653999905, 5.657530356999985],
              [-165.678369653999908, 5.686041356999965],
              [-165.684477653999977, 5.714608357000031],
              [-165.69034165399998, 5.74322735700008],
              [-165.69595565399996, 5.771894357000178],
              [-165.701319653999946, 5.800611357000207],
              [-165.706435653999932, 5.829372357000125],
              [-165.711302653999866, 5.858177357000102],
              [-165.715916653999955, 5.887025357000084],
              [-165.720280653999907, 5.915911357000084],
              [-165.724394653999951, 5.944833357000164],
              [-165.728258653999916, 5.973791358000085],
              [-165.731869653999837, 6.002780358000223],
              [-165.735227653999942, 6.031800358000055],
              [-165.738333653999973, 6.060850358000096],
              [-165.741185653999963, 6.089922358000138],
              [-165.743788653999871, 6.119022358000223],
              [-165.746135654, 6.148141358000089],
              [-165.748230653999968, 6.177280358000189],
              [-165.75007265399995, 6.206436358000076],
              [-165.751658653999982, 6.235605358000043],
              [-165.75299165399997, 6.264789358000087],
              [-165.75407265399997, 6.293983358000162],
              [-165.754897653999933, 6.323186358000044],
              [-165.755469653999938, 6.35239435800014],
              [-165.75578565399988, 6.381608358000051],
              [-165.755849653999917, 6.410822358000132],
              [-165.755658653999944, 6.440033358],
              [-165.75521065399991, 6.469244358000026],
              [-165.754510653999972, 6.498450358000127],
              [-165.753555653999911, 6.527647358000081],
              [-165.752347653999976, 6.556836358000226],
              [-165.750883653999921, 6.586014358],
              [-165.749163653999972, 6.615177358000153],
              [-165.747191653999977, 6.644325358000117],
              [-165.74496665399991, 6.673452358000247],
              [-165.742485653999978, 6.702561358000025],
              [-165.739749653999979, 6.731647357999975],
              [-165.73676365399993, 6.760705358000166],
              [-165.733522653999984, 6.789739358000077],
              [-165.730027653999912, 6.818744358000117],
              [-165.732238653999985, 6.80563035800003],
              [-165.728997653999954, 6.834664358],
              [-165.725505653999875, 6.863666358000103],
              [-165.721758653999984, 6.892639358],
              [-165.717758653999965, 6.92157735800015],
              [-165.71350565399996, 6.950480358000107],
              [-165.708999653999825, 6.979344358],
              [-165.70424465399995, 7.008169358000032],
              [-165.699235653999921, 7.036950358000013],
              [-165.693974653999902, 7.065686359000097],
              [-165.688466653999825, 7.094377359000077],
              [-165.682702653999939, 7.123016359000189],
              [-165.676691653999967, 7.151605359000087],
              [-165.670430653999915, 7.180141359000174],
              [-165.663919653999955, 7.208622359000231],
              [-165.657158653999943, 7.237044359000151],
              [-165.650149653999819, 7.265405359],
              [-165.642894653999946, 7.293705359000114],
              [-165.635388653999911, 7.321941359000107],
              [-165.627638653999924, 7.350111359000152],
              [-165.619641653999935, 7.37821135900009],
              [-165.611397653999944, 7.406239359000097],
              [-165.602908653999947, 7.434197359000166],
              [-165.594174653999829, 7.462077359000205],
              [-165.585197653999927, 7.489880359000097],
              [-165.575974653999936, 7.517605359000243],
              [-165.566510653999984, 7.545250359000022],
              [-165.556805653999902, 7.572811359000127],
              [-165.546858653999976, 7.600283359000116],
              [-165.536669653999979, 7.627669359000151],
              [-165.526238653999968, 7.65496635900007],
              [-165.515572653999953, 7.682172359000106],
              [-165.504666653999976, 7.709280359000218],
              [-165.493522653999918, 7.736297359000218],
              [-165.482138653999925, 7.763211359000081],
              [-165.470522653999836, 7.790027359000021],
              [-165.45866965399992, 7.816741359000162],
              [-165.446583653999909, 7.843350359000226],
              [-165.434263653999921, 7.869852359000049],
              [-165.421710653999838, 7.896247358999972],
              [-165.408924653999918, 7.922530359],
              [-165.395910653999948, 7.948702359000151],
              [-165.382663653999913, 7.97475835900002],
              [-165.369191653999962, 8.00070035900012],
              [-165.355488653999942, 8.026519359000133],
              [-165.341558653999954, 8.052222359000154],
              [-165.32740265399994, 8.07780035900015],
              [-165.313024653999918, 8.103255359000059],
              [-165.298422653999921, 8.12858336000005],
              [-165.283594653999984, 8.153783360000205],
              [-165.268547653999946, 8.17885236],
              [-165.25327765399993, 8.203789360000087],
              [-165.237791654, 8.228594360000216],
              [-165.222085653999955, 8.253261360000124],
              [-165.206160653999945, 8.277789360000099],
              [-165.190022653999961, 8.302177360000201],
              [-165.173669653999951, 8.326427360000082],
              [-165.157102653999914, 8.35053036000005],
              [-165.140322653999931, 8.374489360000211],
              [-165.123333653999822, 8.398302360000173],
              [-165.106133653999905, 8.421964360000061],
              [-165.088724653999833, 8.445475360000103],
              [-165.071108653999943, 8.468833360000247],
              [-165.053288653999971, 8.492039360000092],
              [-165.035260654000041, 8.515086360000055],
              [-165.01703365399996, 8.537977360000127],
              [-164.998602653999967, 8.560705360000043],
              [-164.979969653999973, 8.583275360000187],
              [-164.961141653999903, 8.605680360000179],
              [-164.942110653999947, 8.627919360000078],
              [-164.922888653999962, 8.649994360000164],
              [-164.903469653999935, 8.67189736],
              [-164.883858653999852, 8.693633360000035],
              [-164.864052653999948, 8.715194360000226],
              [-164.844058653999952, 8.736583360000168],
              [-164.823877653999944, 8.757797360000211],
              [-164.803505652999945, 8.778833360000078],
              [-164.782949652999918, 8.799691360000111],
              [-164.762210652999926, 8.820372360000135],
              [-164.74128865299997, 8.840866360000064],
              [-164.720183652999907, 8.861180360000105],
              [-164.698899652999927, 8.881308360000162],
              [-164.677438652999967, 8.901252360000115],
              [-164.655802652999938, 8.921005360000208],
              [-164.633991652999981, 8.940569359999984],
              [-164.612005652999841, 8.959944360000065],
              [-164.589849652999845, 8.979125360000126],
              [-164.567524653, 8.998111360000053],
              [-164.54503365299999, 9.016902360000131],
              [-164.522372652999934, 9.035497360000022],
              [-164.499549652999917, 9.053894360000186],
              [-164.476563653, 9.07208936],
              [-164.453413652999984, 9.090086360000157],
              [-164.430108652999962, 9.107877360000145],
              [-164.406644652999915, 9.125466360000075],
              [-164.38302465299995, 9.142850360000125],
              [-164.359252652999913, 9.160025360000077],
              [-164.335327652999922, 9.176994360000094],
              [-164.311252652999968, 9.193752361000122],
              [-164.287030652999903, 9.210300361000066],
              [-164.26265865299996, 9.226636361000089],
              [-164.238144652999921, 9.242758361000028],
              [-164.213488652999928, 9.25866636100011],
              [-164.188691652999978, 9.274361361000047],
              [-164.163752652999932, 9.289836361000084],
              [-164.138680652999909, 9.305094361000215],
              [-164.113472652999974, 9.320130361000167],
              [-164.088130652999979, 9.334950360999983],
              [-164.062658653, 9.349544361000085],
              [-164.037055652999982, 9.363919361000228],
              [-164.011327652999938, 9.378066361000151],
              [-163.98547265299996, 9.391991361000123],
              [-163.959494653, 9.405689361000213],
              [-163.933397653000071, 9.419161361000022],
              [-163.90717765299982, 9.432402361000129],
              [-163.880844652999969, 9.445416361000241],
              [-163.854394652999957, 9.458200361000024],
              [-163.827830652999921, 9.470750361000114],
              [-163.801155652999881, 9.483069361000105],
              [-163.774372652999915, 9.495155361000229],
              [-163.747483652999932, 9.507008361000032],
              [-163.720488651999943, 9.518625361000204],
              [-163.693388651999953, 9.530005361000178],
              [-163.666191651999952, 9.541150361000177],
              [-163.638894651999948, 9.5520553610001],
              [-163.611502651999984, 9.562722361000169],
              [-163.584013651999982, 9.573150361000046],
              [-163.556435651999976, 9.583339361000014],
              [-163.528766651999916, 9.593283360999976],
              [-163.501008651999939, 9.602989361000141],
              [-163.47316665200006, 9.612450361000128],
              [-163.445238651999972, 9.621669361000158],
              [-163.417230651999915, 9.630644361000066],
              [-163.389144652, 9.639375361000191],
              [-163.36098065199991, 9.647858361000033],
              [-163.332741651999953, 9.656097360999979],
              [-163.304430651999951, 9.664089361000094],
              [-163.27604765199996, 9.671833361000097],
              [-163.247597651999939, 9.679327361000048],
              [-163.219083651999966, 9.686575361000052],
              [-163.190502651999935, 9.693575361000114],
              [-163.161860651999916, 9.700322361000246],
              [-163.133160651999901, 9.70682236100015],
              [-163.104402651999976, 9.713069361000123],
              [-163.075591651999929, 9.719066360999989],
              [-163.04672765199993, 9.724811360999979],
              [-163.01781065199998, 9.730302361000099],
              [-162.988847651999976, 9.735541361000173],
              [-162.959838651999974, 9.74053036100014],
              [-162.930785651999969, 9.745264361000125],
              [-162.901691651999954, 9.749744361000239],
              [-162.872560651999976, 9.753969361000088],
              [-162.843391651999895, 9.757939361000126],
              [-162.81418565199985, 9.761655361000237],
              [-162.784949651999966, 9.765119361000188],
              [-162.755685651999926, 9.768325361000052],
              [-162.726391651999961, 9.77127536100005],
              [-162.697072651999974, 9.773969361000184],
              [-162.667730650999971, 9.776408361000222],
              [-162.638369650999977, 9.778591361],
              [-162.608988650999919, 9.780516361000139],
              [-162.579591650999959, 9.782186361000072],
              [-162.55018065099992, 9.783600361000197],
              [-162.520758650999852, 9.78475536100018],
              [-162.491327650999921, 9.785655361000067],
              [-162.461888650999953, 9.786297361000038],
              [-162.43244465099994, 9.7866833610002],
              [-162.402999650999931, 9.786811361000161],
              [-162.373555650999975, 9.7866833610002],
              [-162.344110650999966, 9.786297361000038],
              [-162.314672650999825, 9.785655361000067],
              [-162.285241650999978, 9.78475536100018],
              [-162.255819650999911, 9.783600361000197],
              [-162.226408650999957, 9.782186361000072],
              [-162.197010650999914, 9.780516361000139],
              [-162.167630651000053, 9.778591361],
              [-162.138269651000059, 9.776408361000222],
              [-162.108927650999931, 9.773969361000184],
              [-162.079608650999944, 9.77127536100005],
              [-162.050313650999925, 9.768325361000052],
              [-162.021049650999942, 9.765119361000188],
              [-161.991813650999831, 9.761655361000237],
              [-161.962608650999954, 9.757939361000126],
              [-161.933438650999932, 9.753969361000088],
              [-161.90430865099998, 9.749744361000239],
              [-161.875213651000053, 9.745264361000125],
              [-161.846160650999963, 9.74053036100014],
              [-161.843997952154695, 9.740178166793726],
              [-161.817149336661089, 9.735560632528518],
              [-161.814985650999887, 9.735208361000161],
              [-161.786022650999968, 9.729969361000087],
              [-161.757108651000095, 9.724477361000083],
              [-161.728241650999934, 9.718733361000147],
              [-161.699430650999972, 9.712736361000111],
              [-161.670672650999876, 9.706489360999967],
              [-161.641972650999861, 9.699989361000064],
              [-161.613330650999927, 9.693241361000219],
              [-161.584752649999956, 9.686244360999979],
              [-161.556235649999905, 9.678994361000207],
              [-161.52778565, 9.671500361000085],
              [-161.499405649999886, 9.663755361000142],
              [-161.47109165, 9.655764361000138],
              [-161.442852649999935, 9.647525361000191],
              [-161.414688649999874, 9.639041361000238],
              [-161.386602649999929, 9.630311361000224],
              [-161.358594649999901, 9.621336361000147],
              [-161.330669649999976, 9.612119361000111],
              [-161.302824649999906, 9.602655361000245],
              [-161.275069649999836, 9.592950361000135],
              [-161.247399649999949, 9.583005361000119],
              [-161.219819649999891, 9.57281636100015],
              [-161.192333649999938, 9.562389360999987],
              [-161.164938649999982, 9.551722361000088],
              [-161.137641649999978, 9.540816361000111],
              [-161.110444649999977, 9.529672361000166],
              [-161.083347649999979, 9.518291361000138],
              [-161.056352649999923, 9.506675361000191],
              [-161.029460649999919, 9.494822361000047],
              [-161.002677649999981, 9.482736361000093],
              [-160.976005649999905, 9.470416361000048],
              [-160.949441649999955, 9.457866361000072],
              [-160.922991649999943, 9.445083361000059],
              [-160.896655649999957, 9.432069361000117],
              [-160.870438649999954, 9.418827361000126],
              [-160.844338649999941, 9.40535536100009],
              [-160.818360649999903, 9.391658361000111],
              [-160.792508649999917, 9.377733361000082],
              [-160.766780649999959, 9.363586361000046],
              [-160.741177649999855, 9.349211361000073],
            ],
          ],
          [
            [
              [-169.49452465799996, 13.359300364000148],
              [-169.554166657999957, 13.358797364000111],
              [-169.613808657999954, 13.359300364000148],
              [-169.673430657999972, 13.360805364000157],
              [-169.733019657999932, 13.363314364000075],
              [-169.792555657999884, 13.366822364000086],
              [-169.852022657999953, 13.371333364000122],
              [-169.911399657999965, 13.376847364000184],
              [-169.970677657999943, 13.383355364000238],
              [-170.029833657999944, 13.390861364000102],
              [-170.088847657999935, 13.399361364000129],
              [-170.147708657999971, 13.408852364000097],
              [-170.206397659, 13.419333364000238],
              [-170.26489465899985, 13.430800364000106],
              [-170.323185658999961, 13.443250364000107],
              [-170.381249658999963, 13.456677364000143],
              [-170.439077658999878, 13.471080364000102],
              [-170.496644658999941, 13.486452364000115],
              [-170.553935658999848, 13.50279436500017],
              [-170.61093865899997, 13.520094365000205],
              [-170.667633658999932, 13.538352365000037],
              [-170.723999658999929, 13.55756136500014],
              [-170.780027658999956, 13.577714365000134],
              [-170.835697658999948, 13.598808365000195],
              [-170.89099165899998, 13.62083336500018],
              [-170.945897658999968, 13.64378636500021],
              [-171.000397658999958, 13.667661365000129],
              [-171.054474658999851, 13.692447365000135],
              [-171.108113658999912, 13.718139365000127],
              [-171.161297658999985, 13.744730365000066],
              [-171.214010658999968, 13.772211364999976],
              [-171.26624166, 13.800575365000043],
              [-171.317969659999932, 13.829814365000118],
              [-171.369183659999976, 13.859916365000117],
              [-171.419863659999834, 13.890877365000165],
              [-171.469999659999957, 13.922683365000125],
              [-171.519574659999847, 13.955330365000066],
              [-171.568572659999944, 13.988805365000076],
              [-171.620277659999942, 14.023100365000118],
              [-171.664783659999927, 14.058202365000113],
              [-171.711969659999937, 14.094102365000083],
              [-171.758522659999983, 14.130791365000107],
              [-171.849669659999904, 14.206489365000152],
              [-171.894238659999928, 14.245475365],
              [-171.938122659999948, 14.285205365000138],
              [-171.981302659999983, 14.325666365000131],
              [-172.023769659999829, 14.366850365000175],
              [-172.06550866, 14.408739365000031],
              [-172.072124659999929, 14.419064365000054],
              [-172.113127659999947, 14.461647365000033],
              [-172.153377659999933, 14.504914365000218],
              [-172.192866659999936, 14.548850365000021],
              [-172.231574659999893, 14.593444365999972],
              [-172.269499659999951, 14.638680366000187],
              [-172.30662266, 14.684550366000167],
              [-172.342933660999904, 14.73103336600019],
              [-172.378422660999945, 14.778122366000105],
              [-172.413080660999981, 14.825797366000131],
              [-172.446891660999938, 14.874050366000063],
              [-172.479849660999918, 14.922861366],
              [-172.511944660999831, 14.972219366000047],
              [-172.543160660999916, 15.022111366000047],
              [-172.573497660999919, 15.072519366000108],
              [-172.602935660999975, 15.123427365999987],
              [-172.631472660999918, 15.174822366000171],
              [-172.659094660999841, 15.226689366000187],
              [-172.685797660999981, 15.27901436600024],
              [-172.711569660999942, 15.331777366000097],
              [-172.736402660999914, 15.384966366000072],
              [-172.760288660999976, 15.438564366000094],
              [-172.78322266099994, 15.492552366000098],
              [-172.805194660999831, 15.546919366],
              [-172.826197660999981, 15.601647366000122],
              [-172.846222660999956, 15.656719367000164],
              [-172.865266660999879, 15.712119367000071],
              [-172.883319660999916, 15.767830366999988],
              [-172.900377660999908, 15.823836367000126],
              [-172.916435660999923, 15.880119367000134],
              [-172.931485660999869, 15.936664367000105],
              [-172.945524660999979, 15.993452366999975],
              [-172.958547660999955, 16.050469367000062],
              [-172.970544660999963, 16.107694367000022],
              [-172.981516660999915, 16.165111367000065],
              [-172.991460660999877, 16.222702367000238],
              [-173.000366660999845, 16.280452367000123],
              [-173.008235660999958, 16.338344367000161],
              [-173.015063660999942, 16.396358367],
              [-173.020847660999976, 16.454480367000087],
              [-173.025583660999956, 16.512686367000015],
              [-173.029272660999851, 16.57096636700021],
              [-173.031908660999903, 16.629297367],
              [-173.033488660999893, 16.687661367000203],
              [-173.034016660999953, 16.746044368000128],
              [-173.033488660999893, 16.80442736800012],
              [-173.031905660999911, 16.862794368000039],
              [-173.029263660999817, 16.921122368000113],
              [-173.025566660999971, 16.979400367999972],
              [-173.020810660999928, 17.03760236800008],
              [-173.01499766099991, 17.095719368000118],
              [-173.00813066099991, 17.153727368000034],
              [-173.000208660999903, 17.211611368000149],
              [-172.991230660999946, 17.269352368000114],
              [-172.981202660999941, 17.326936368000077],
              [-172.970124660999943, 17.384339368000042],
              [-172.958002660999824, 17.441550368000037],
              [-172.944833660999905, 17.498544368000239],
              [-172.915374660999902, 17.611830368000085],
              [-172.899094661, 17.668086368000161],
              [-172.881780660999965, 17.724055368000052],
              [-172.863441660999939, 17.779727368000124],
              [-172.844083660999985, 17.835083369000074],
              [-172.823708660999927, 17.890105369000139],
              [-172.802322660999948, 17.94477536900024],
              [-172.779930660999895, 17.999077369000076],
              [-172.756538660999951, 18.052994369000089],
              [-172.732155660999979, 18.106511368999975],
              [-172.706785660999969, 18.159611369000231],
              [-172.680435660999905, 18.212272369000175],
              [-172.653113660999935, 18.26448636900011],
              [-172.624827660999983, 18.316233369000088],
              [-172.595585660999973, 18.367494369000042],
              [-172.565394660999885, 18.418258369000114],
              [-172.534263660999983, 18.468505369000013],
              [-172.502202660999956, 18.518222369000057],
              [-172.469219660999954, 18.56739136900012],
              [-172.436858660999974, 18.61482236900008],
              [-172.402955660999964, 18.663430369000082],
              [-172.368149660999933, 18.711464369],
              [-172.332452659999916, 18.758902369],
              [-172.295872659999986, 18.805736369000101],
              [-172.258422659999951, 18.851950369000122],
              [-172.220108659999852, 18.897525370000039],
              [-172.180947659999987, 18.942447370000082],
              [-172.140949659999904, 18.986708370000088],
              [-172.100124659999949, 19.030289370000162],
              [-172.058488659999938, 19.073175370000115],
              [-172.016049659999936, 19.115355370000145],
              [-171.972822659999906, 19.156819370000164],
              [-171.928819659999903, 19.197547370000109],
              [-171.884058659999909, 19.237530370000059],
              [-171.838547659999904, 19.276755370000103],
              [-171.792299659999884, 19.315211369999986],
              [-171.745333659999943, 19.35288037000015],
              [-171.697663659999847, 19.389755370000103],
              [-171.649299659999969, 19.425825370000098],
              [-171.600260659999918, 19.461077370000055],
              [-171.55056066, 19.495497370000123],
              [-171.500216659999978, 19.529077369999982],
              [-171.449241659999899, 19.561808370000108],
              [-171.397652659999977, 19.593675369999971],
              [-171.360085659999953, 19.616655370000103],
              [-171.30789166, 19.647652370000117],
              [-171.255113658999932, 19.677766370000086],
              [-171.201772658999914, 19.706991370000196],
              [-171.147883658999973, 19.735314370000083],
              [-171.093460658999959, 19.762725370000226],
              [-171.038527658999868, 19.789216370000076],
              [-170.983094658999875, 19.814783370000214],
              [-170.927180658999958, 19.839414370000156],
              [-170.870808658999977, 19.863100370000097],
              [-170.813988658999989, 19.885836370000106],
              [-170.756747658999956, 19.90761437000009],
              [-170.699094658999968, 19.928425370000184],
              [-170.641052658999911, 19.948264371000221],
              [-170.582641658999904, 19.967125371000066],
              [-170.523877658999908, 19.985002371000235],
              [-170.464783658999949, 20.001889371],
              [-170.405372659, 20.017777371000079],
              [-170.345663658999939, 20.032669371],
              [-170.285683658999972, 20.046552371000075],
              [-170.225444658999976, 20.059425371000088],
              [-170.164966657999912, 20.071283371000163],
              [-170.104272657999985, 20.082125371000018],
              [-170.043377657999969, 20.091941371000019],
              [-169.982305657999973, 20.100736371000153],
              [-169.921074657999952, 20.108502371000156],
              [-169.859705657999967, 20.11523937100003],
              [-169.798213657999867, 20.120941371000015],
              [-169.736624657999897, 20.125608371000112],
              [-169.674955657999959, 20.129241371000148],
              [-169.61322465799995, 20.131836371000134],
              [-169.551455657999924, 20.133394371000065],
              [-169.489666657999919, 20.133914371000117],
              [-169.46749965799998, 20.133914371000117],
              [-169.405710658, 20.133394371000065],
              [-169.343941657999949, 20.131836371000134],
              [-169.282210657999968, 20.129241371000148],
              [-169.22054165799986, 20.125608371000112],
              [-169.15895265799989, 20.120941371000015],
              [-169.097460656999857, 20.11523937100003],
              [-169.036091656999957, 20.108502371000156],
              [-168.974860656999937, 20.100736371000153],
              [-168.913788656999856, 20.091941371000019],
              [-168.852894656999837, 20.082125371000018],
              [-168.792199656999912, 20.071283371000163],
              [-168.731722656999949, 20.059425371000088],
              [-168.671483656999925, 20.046552371000075],
              [-168.61150265699996, 20.032669371],
              [-168.551794656999931, 20.017777371000079],
              [-168.492383656999948, 20.001889371],
              [-168.433288656999906, 19.985002371000235],
              [-168.37452465699991, 19.967125371000066],
              [-168.316113656999903, 19.948264371000221],
              [-168.258072656999872, 19.928425370000184],
              [-168.200419656999941, 19.90761437000009],
              [-168.143177656999939, 19.885836370000106],
              [-168.08635865699992, 19.863100370000097],
              [-168.029985655999951, 19.839414370000156],
              [-167.974072655999947, 19.814783370000214],
              [-167.918638655999956, 19.789216370000076],
              [-167.863705655999951, 19.762725370000226],
              [-167.809283655999934, 19.735314370000083],
              [-167.755394655999908, 19.706991370000196],
              [-167.702052655999978, 19.677766370000086],
              [-167.649274655999932, 19.647652370000117],
              [-167.597080655999974, 19.616655370000103],
              [-167.54548565599984, 19.58478937000018],
              [-167.49450265599998, 19.552058370000054],
              [-167.444152655999829, 19.518477370000085],
              [-167.394447655999926, 19.484055370000078],
              [-167.345399655999927, 19.44880237000001],
              [-167.297033655999968, 19.412733370000069],
              [-167.249355655999921, 19.375858370000117],
              [-167.202383655999938, 19.338186370000074],
              [-167.156130655999931, 19.29973037000002],
              [-167.11061365599997, 19.260505370000089],
              [-167.065844655999911, 19.220522370000197],
              [-167.021838655999858, 19.179791370000203],
              [-166.978605655999957, 19.138330370000176],
              [-166.936163654999973, 19.096147370000153],
              [-166.894519654999925, 19.053258370000151],
              [-166.853691654999921, 19.009677370000247],
              [-166.813688654999936, 18.965416370000185],
              [-166.793819655, 18.946608370000092],
              [-166.754658654999872, 18.901686370000107],
              [-166.716344654999915, 18.856111370000065],
              [-166.678891655, 18.809897369],
              [-166.642310654999903, 18.763064369000119],
              [-166.606613654999961, 18.715625369000236],
              [-166.571808654999927, 18.667591369000093],
              [-166.537902654999925, 18.618983369000091],
              [-166.504913654999967, 18.569811368999979],
              [-166.472844654999903, 18.520091369],
              [-166.441708654999871, 18.4698443690001],
              [-166.411510654999972, 18.419080369000085],
              [-166.382260654999925, 18.367816369000082],
              [-166.353969654999929, 18.316069369000218],
              [-166.326644655, 18.263855369000112],
              [-166.300288654999946, 18.211191369000062],
              [-166.274913654999949, 18.158091369000203],
              [-166.250527654999928, 18.104575369000202],
              [-166.227130654999939, 18.050655368999969],
              [-166.204735654999951, 17.996352369000022],
              [-166.18334765499992, 17.941680368999982],
              [-166.162969654999955, 17.886658369000031],
              [-166.143608654999923, 17.831302369000014],
              [-166.125266654999933, 17.775630368000236],
              [-166.107952654999906, 17.719658368000239],
              [-166.091669654999947, 17.663402368],
              [-166.076419654999967, 17.60688336800024],
              [-166.062208654999921, 17.550116367999976],
              [-166.049041654999911, 17.493119368000066],
              [-166.03691665499997, 17.43591136800012],
              [-166.025838654999973, 17.378505368000106],
              [-166.01581065499991, 17.320922368000083],
              [-166.006835654999918, 17.263180368000178],
              [-165.998916654999903, 17.205297368000117],
              [-165.992049654999903, 17.147289368000088],
              [-165.98623865499988, 17.08917236800022],
              [-165.981485654999972, 17.030969368],
              [-165.977788654999898, 16.972694368000077],
              [-165.975149654999939, 16.914364368],
              [-165.973569654999949, 16.856000368000139],
              [-165.973044654999939, 16.797616368000035],
              [-165.973574654999908, 16.739233368000214],
              [-165.975160654999883, 16.680869367000071],
              [-165.977802654999977, 16.622539367000059],
              [-165.981494654999921, 16.564261367000029],
              [-165.986235654999973, 16.506052367000166],
              [-165.992022654999971, 16.447933367000019],
              [-165.998855654999915, 16.389919367000175],
              [-166.006730654999927, 16.332030367000186],
              [-166.015644654999932, 16.274280367000131],
              [-166.025591654999971, 16.216686367000023],
              [-166.036572654999929, 16.159272367000085],
              [-166.048577655, 16.102047367000125],
              [-166.061602654999973, 16.045033367000087],
              [-166.07564965499995, 15.988244367000163],
              [-166.090708655000014, 15.931702367000241],
              [-166.106772654999986, 15.875419367000063],
              [-166.123838654999929, 15.819416367000031],
              [-166.141899654999918, 15.763705367000114],
              [-166.160949654999968, 15.708308367000086],
              [-166.180985654999972, 15.65323636700009],
              [-166.201997654999985, 15.598511365999968],
              [-166.223974654999921, 15.544144366000069],
              [-166.24691665499995, 15.49015536600001],
              [-166.270813654999841, 15.436558366000042],
              [-166.295655654999933, 15.383372366000174],
              [-166.321435654999931, 15.330611366000085],
              [-166.348147654999963, 15.278289365999967],
              [-166.375780654999886, 15.226422366000122],
              [-166.404324654999954, 15.175027366000108],
              [-166.433774654999951, 15.124122366000108],
              [-166.464119654999905, 15.073716366000042],
              [-166.495347654999932, 15.023827366000091],
              [-166.527449654999941, 14.974469366000051],
              [-166.560419654999947, 14.925658366000164],
              [-166.594241654999934, 14.877408366000111],
              [-166.628908654999833, 14.829733366000028],
              [-166.664408654999903, 14.782647366000049],
              [-166.700733654999937, 14.736166366000134],
              [-166.737866654999948, 14.690300366000031],
              [-166.775799654999986, 14.645064366000156],
              [-166.811955654999906, 14.604600366000057],
              [-166.856694654999956, 14.560008365000215],
              [-166.890158654999965, 14.51607236500007],
              [-166.930410654999946, 14.472805365000227],
              [-166.971416655999946, 14.430222365000134],
              [-167.013160655999911, 14.388333365000221],
              [-167.055633656, 14.347152365000113],
              [-167.098819655999904, 14.30669136500012],
              [-167.142705655999976, 14.266961364999986],
              [-167.187280655999899, 14.227975365000134],
              [-167.232527655999974, 14.189744365000195],
              [-167.278438655999935, 14.152280365000195],
              [-167.324994655999944, 14.115591365000114],
              [-167.372185655999942, 14.079691365000144],
              [-167.41999465599983, 14.044589364999979],
              [-167.468405655999959, 14.010297365000042],
              [-167.517410655999925, 13.976822365000146],
              [-167.566988655999978, 13.94417736500003],
              [-167.617130655999972, 13.912372365000181],
              [-167.667816655999872, 13.881411365000133],
              [-167.719035655999988, 13.851308365000023],
              [-167.77076965599997, 13.822072365000054],
              [-167.823002655999971, 13.793708365000157],
              [-167.875722655999851, 13.766227365000077],
              [-167.928910655999971, 13.739639365000016],
              [-167.982555655999903, 13.713947365000195],
              [-168.036638655999951, 13.689161365000018],
              [-168.09114165699998, 13.6652863650001],
              [-168.146052656999956, 13.64233336500007],
              [-168.201352656999887, 13.620308365000085],
              [-168.257027657, 13.599216365000188],
              [-168.313060656999909, 13.5790613650002],
              [-168.369433656999945, 13.559855365000203],
              [-168.426133656999895, 13.541597365000143],
              [-168.483138656999927, 13.524297365000109],
              [-168.497394656999973, 13.520094365000205],
              [-168.554397656999839, 13.50279436500017],
              [-168.611688656999974, 13.486452364000115],
              [-168.669255656999979, 13.471080364000102],
              [-168.72708365699998, 13.456677364000143],
              [-168.785147656999897, 13.443250364000107],
              [-168.843438656999837, 13.430800364000106],
              [-168.901935656999939, 13.419333364000238],
              [-168.960624656999954, 13.408852364000097],
              [-169.019485656999819, 13.399361364000129],
              [-169.078499656999981, 13.390861364000102],
              [-169.137655657999915, 13.383355364000238],
              [-169.196933657999978, 13.376847364000184],
              [-169.256310657999904, 13.371333364000122],
              [-169.315777657999973, 13.366822364000086],
              [-169.375313657999982, 13.363314364000075],
              [-169.434902657999942, 13.360805364000157],
              [-169.49452465799996, 13.359300364000148],
            ],
          ],
          [
            [
              [166.268825655000342, 16.223459367000146],
              [165.858614654000235, 16.037315367000076],
              [165.490637654000125, 16.1286523670002],
              [165.486786154000129, 16.130309367000166],
              [165.466446654000151, 16.139124366999965],
              [165.462080654000033, 16.140195367000103],
              [165.460282154000311, 16.140943367000034],
              [165.459272654000188, 16.141349367000032],
              [165.457151654000199, 16.141869367000083],
              [165.307349654000291, 16.206817367000042],
              [164.964504654000166, 16.354864367000118],
              [164.905584654000108, 16.380223367000099],
              [164.902711654000086, 16.382094367000036],
              [164.897682654000164, 16.384257367000089],
              [164.41830365300018, 16.695067366999979],
              [164.395893653000144, 16.709541368000032],
              [164.393103653000253, 16.712121368000169],
              [164.391898653000339, 16.712898367999969],
              [164.389810653000268, 16.71482936800021],
              [164.383951653000054, 16.718612368000038],
              [164.114675653000177, 16.966976368000189],
              [163.946580653000296, 17.12151236800014],
              [163.943727653000337, 17.124185367999985],
              [163.584231652000312, 17.592562368000131],
              [163.313587652000251, 18.11282936900011],
              [163.140526652000176, 18.669589369000221],
              [163.071103652000261, 19.24624637],
              [163.071190652000155, 19.24759437],
              [163.071050652000167, 19.248744370000111],
              [163.072426652000331, 19.270055370000136],
              [163.108384652000268, 19.827957370000036],
              [163.252457652000317, 20.392138370999987],
              [163.499970652000229, 20.923974371000213],
              [163.844303653000225, 21.406963372000121],
              [163.854883653000229, 21.417276372000231],
              [163.860186653000255, 21.4246903720001],
              [163.901632653000092, 21.465070372000014],
              [164.291548653000177, 21.843651372000181],
              [164.796924653000133, 22.185321373000193],
              [165.220707654000194, 22.376061373000027],
              [165.360811654000258, 22.438801873000159],
              [165.361699654000176, 22.439173373000017],
              [165.965978655000185, 22.596212373000128],
              [166.59136065500013, 22.651747373000205],
              [166.59213965500021, 22.651726873000086],
              [166.614445655000168, 22.650097373000108],
              [167.218600656000035, 22.60395637300013],
              [167.825373656000238, 22.454379373000108],
              [167.829023656000231, 22.452801373000113],
              [167.839560656000231, 22.448248373000183],
              [167.855900656000017, 22.444189373000086],
              [167.857756656000333, 22.443419373000154],
              [168.047771657000112, 22.361172373000045],
              [168.425621657000192, 22.196752373000095],
              [168.936279657000227, 21.861124372000177],
              [169.374040658000126, 21.447230372000092],
              [169.37498765800035, 21.445965372000046],
              [169.521192658000246, 21.247232372000155],
              [169.726702658000022, 20.966887371000098],
              [169.982116658000109, 20.437776371000098],
              [170.0646636580002, 20.133104371000229],
              [170.134398158000295, 19.874798370000065],
              [170.13903365800013, 19.857450369999981],
              [170.139994658000205, 19.845235370000239],
              [170.141649658000233, 19.839101370000037],
              [170.148219658000244, 19.75561237],
              [170.187167658000135, 19.260041370000067],
              [170.187069658000155, 19.259130370000207],
              [170.187131658000339, 19.258342370000207],
              [170.184937658000194, 19.237806370000158],
              [170.125681658000417, 18.680591369000126],
              [169.960158658000182, 18.12156236900006],
              [169.839400658000272, 17.87969936900015],
              [169.006423657000028, 17.501498368000227],
              [167.713932656000225, 16.90491436800022],
              [166.68893665500002, 16.4230453670001],
              [166.657593655000142, 16.40820436700011],
              [166.268825655000342, 16.223459367000146],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "URY", Country: "Uruguay" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-52.866235013349296, -34.072477274160576],
            [-52.857561158665987, -34.078118952941622],
            [-52.729435778505206, -34.16137191500718],
            [-52.601184469135774, -34.244623902560306],
            [-52.472806892007668, -34.327874769945197],
            [-52.344302483714728, -34.411124374932513],
            [-52.215670568913481, -34.494372937150729],
            [-52.086910892641413, -34.577620311387989],
            [-51.958022758424534, -34.660866537622894],
            [-51.829005677280918, -34.744111563793126],
            [-51.69985904368383, -34.827355519291302],
            [-51.570582458784884, -34.910598261978748],
            [-51.441175407710489, -34.993839921521214],
            [-51.311637145055101, -35.077080358045123],
            [-51.181967355783456, -35.160319700980324],
            [-51.052165385515082, -35.243557810673678],
            [-50.922230570442423, -35.326794817841858],
            [-50.792162676783676, -35.410030673437859],
            [-50.661960911363792, -35.493265328570146],
            [-50.531624911453093, -35.576498825516296],
            [-50.401153973522582, -35.659731206074923],
            [-50.27054749462593, -35.742962422253072],
            [-50.139805083330316, -35.826192427801232],
            [-50.091538806964536, -35.856896100266795],
            [-50.150745141999948, -35.971381578999967],
            [-50.533931004999943, -36.462609134999923],
            [-51.022175059999881, -36.891091151999888],
            [-51.312727802999916, -37.068268104000026],
            [-51.336129947999893, -37.088482958999975],
            [-51.429609163999885, -37.169085768999935],
            [-52.012381748999928, -37.518782384999952],
            [-52.349064841999962, -37.652841668999883],
            [-52.596452355999929, -37.751345554999943],
            [-52.63103144899992, -37.765114135999966],
            [-52.646188850999863, -37.754691817999969],
            [-52.668898873999865, -37.739076272999966],
            [-52.676008684999886, -37.734187526000028],
            [-52.829100816999869, -37.641877891999911],
            [-52.835574823999934, -37.637974273999873],
            [-52.931050641999832, -37.580405425999913],
            [-53.160775766999905, -37.356023940999989],
            [-53.613186953999787, -36.91413629099992],
            [-53.872030356999915, -36.688701720999916],
            [-54.069623204999857, -36.585734764999984],
            [-54.415796900499856, -36.405341335499884],
            [-54.892662927999879, -36.156843256999878],
            [-55.661348129999965, -35.743688444999975],
            [-55.866666666999834, -35.633333332999882],
            [-55.962571004999916, -35.581244113999958],
            [-56.209947899999804, -35.446884506499941],
            [-56.71666666699997, -35.17166666699984],
            [-56.824585851999871, -35.176110397999878],
            [-56.999999884999795, -35.183333328499998],
            [-57.253449206999846, -34.942556587000013],
            [-57.333333400499896, -34.866666638499964],
            [-57.360616080999762, -34.855298855],
            [-57.533333332999916, -34.783333332999916],
            [-57.786039933999973, -34.712857388000032],
            [-57.95166666699987, -34.666666667000015],
            [-57.945034466999914, -34.620241266999983],
            [-57.934999999999832, -34.549999999999898],
            [-57.939999999999827, -34.443333332999885],
            [-58.009999999999764, -34.38],
            [-58.01593820599993, -34.36911328899987],
            [-58.02, -34.361666666999952],
            [-58.064999999999884, -34.333333332999985],
            [-58.091666666999799, -34.295],
            [-58.151692639999851, -34.261652236999964],
            [-58.166666666999902, -34.253333332999887],
            [-58.20833339099994, -34.221666637999988],
            [-58.251666666999881, -34.2],
            [-58.290628695999942, -34.168830375999846],
            [-58.293333332999907, -34.166666666999959],
            [-58.294150637999849, -34.166082877999884],
            [-58.316666666999879, -34.15],
            [-58.319735320999939, -34.137725381999871],
            [-58.323333332999766, -34.123333332999948],
            [-58.333333332999928, -34.103333332999966],
            [-58.337814633999869, -34.078366088999985],
            [-58.344999999999857, -34.038333332999969],
            [-58.376666666999768, -34.0],
            [-58.405, -33.955],
            [-58.419085523999854, -33.922603294999874],
            [-58.421666553666569, -33.916666661999898],
            [-58.4287833059999, -33.90126438599998],
            [-58.440660114999901, -33.875559843999895],
            [-58.449557952999896, -33.846641869999985],
            [-58.458647553999867, -33.817099528999904],
            [-58.471396508999931, -33.759730649999881],
            [-58.476635442999793, -33.736155448999966],
            [-58.485629274999894, -33.655210961999899],
            [-58.485629274999894, -33.529297314999894],
            [-58.476581450999845, -33.509392101999879],
            [-58.463144553999882, -33.479831527999977],
            [-58.449653553999894, -33.434862527999826],
            [-58.444863745999896, -33.401330669999879],
            [-58.440660114999901, -33.371905255999877],
            [-58.426724238999782, -33.351001441999927],
            [-58.404684553999914, -33.317942527999946],
            [-58.391193553999926, -33.25498552800002],
            [-58.395690553999771, -33.151556527999958],
            [-58.395690553999771, -33.115581528],
            [-58.355218553999805, -33.097593527999891],
            [-58.296758553999894, -33.102090528000019],
            [-58.229305553999779, -33.106587528000034],
            [-58.179838553999843, -33.102090528000019],
            [-58.145552409999937, -33.078089692999981],
            [-58.13486970699995, -33.070611690333351],
            [-58.130914985999937, -33.060724779],
            [-58.116882165999812, -33.025642726999877],
            [-58.103391417999831, -32.967182819999891],
            [-58.107888478666609, -32.90422571199997],
            [-58.130372553999905, -32.863753527999961],
            [-58.152857520999873, -32.805293614],
            [-58.161851324999816, -32.728846273999977],
            [-58.170845157000031, -32.661392534999948],
            [-58.177774048999822, -32.631367335999983],
            [-58.184335553999802, -32.602932526999894],
            [-58.200233528999888, -32.527418914999942],
            [-58.202323568999816, -32.517491223999912],
            [-58.20651253099993, -32.487902697999914],
            [-58.204403286999764, -32.469341318999966],
            [-58.199664270999847, -32.451854511000022],
            [-58.1992460541166, -32.450308947214467],
            [-58.176946000772773, -32.404026927812197],
            [-58.150281993230692, -32.369726838817883],
            [-58.135281906904197, -32.354173043965886],
            [-58.101599899451742, -32.325626963879586],
            [-58.097554960606089, -32.294590955629076],
            [-58.101581962178841, -32.254645990106496],
            [-58.139999912576741, -32.221672924026677],
            [-58.162781925471165, -32.196673059545788],
            [-58.182290972804594, -32.156846111220204],
            [-58.178335887961055, -32.126255014391461],
            [-58.161235799607027, -32.101064042517635],
            [-58.15111783661763, -32.06639095883105],
            [-58.145972856796163, -31.993055005535211],
            [-58.160426778310409, -31.951108944442922],
            [-58.200145935360865, -31.894790936849859],
            [-58.184590967042425, -31.860281970827771],
            [-58.162226875841441, -31.841117922630019],
            [-58.139026773615171, -31.834308967385482],
            [-58.097917897014497, -31.827081922809441],
            [-58.063608923202764, -31.812499925814336],
            [-58.040208828766481, -31.789172921573822],
            [-57.981535841650668, -31.619446087319432],
            [-57.977781922483928, -31.580899893802723],
            [-57.989863933030904, -31.548335864598926],
            [-58.012917854865123, -31.523608914886324],
            [-58.035690982942299, -31.50726403601044],
            [-58.065281951081317, -31.494163959500838],
            [-58.07889097675303, -31.475899960068716],
            [-58.068055019935684, -31.44971791196059],
            [-58.038754903837514, -31.433472945370795],
            [-58.004445930025952, -31.417708932386745],
            [-57.920835775065029, -31.270972988054552],
            [-57.853617772913992, -31.065555004151008],
            [-57.79694604900584, -30.883472909305453],
            [-57.797781892392948, -30.80569991173212],
            [-57.799326844790528, -30.791763991835751],
            [-57.802917819759102, -30.765417993701078],
            [-57.806399829985935, -30.748217993060791],
            [-57.813890904512846, -30.713754959868425],
            [-57.845835846346233, -30.64264607825254],
            [-57.871535767382397, -30.621526867314515],
            [-57.889863972193041, -30.598335817544012],
            [-57.889454935316962, -30.534999977678581],
            [-57.882781934542322, -30.515135872925669],
            [-57.868617859240715, -30.499445955965712],
            [-57.796954933823258, -30.448335960106135],
            [-57.746117852731913, -30.417499944065924],
            [-57.66889085233305, -30.354726865181306],
            [-57.631390887973623, -30.298755030190193],
            [-57.624999854422526, -30.278890925437395],
            [-57.629445951155333, -30.23778204883655],
            [-57.607999845992765, -30.184926940730975],
            [-57.576808940171134, -30.197082041473891],
            [-57.564308840292483, -30.215146048695829],
            [-57.566599949712895, -30.247435822026887],
            [-57.547363984785648, -30.266390993196808],
            [-57.524863939114766, -30.274581956640759],
            [-57.495417810262893, -30.279864064398794],
            [-57.356390869940867, -30.27222697711818],
            [-57.322226903054684, -30.266672960420522],
            [-57.303755032422941, -30.275417967665874],
            [-57.285281988324982, -30.293335962134051],
            [-57.233890863432123, -30.293335962134051],
            [-57.21389097184732, -30.292082029415496],
            [-57.193617830218386, -30.282635959786603],
            [-57.147917849959583, -30.238199970530047],
            [-57.147090891389894, -30.208054958589628],
            [-57.144172983248211, -30.184999863288681],
            [-57.103890897578992, -30.135417888382335],
            [-57.070972984422269, -30.108891008776709],
            [-56.933617898512296, -30.097500002329468],
            [-56.860555027622638, -30.096673043759836],
            [-56.811390974409676, -30.105281928897398],
            [-56.790281989393634, -30.115554957095924],
            [-56.639172870886455, -30.228472939505934],
            [-56.567781854408963, -30.299164067066386],
            [-56.548055045230456, -30.323064058209525],
            [-56.526946060214414, -30.345835845182137],
            [-56.511117841851672, -30.358890994690512],
            [-56.387508909549865, -30.459999881321437],
            [-56.315281882047088, -30.50833580249838],
            [-56.266945960870203, -30.535835821065518],
            [-56.235835856595372, -30.5583358667364],
            [-56.116390886164538, -30.675835900349057],
            [-56.00167297386173, -30.795835920409615],
            [-55.989726917784594, -30.812781949383009],
            [-55.976808896212674, -30.85104600803794],
            [-55.98500891211205, -30.890699953881494],
            [-56.002226850025068, -30.921872922430282],
            [-56.006672946757817, -30.963608933028411],
            [-56.012217911000164, -31.066391015176123],
            [-56.008917788072608, -31.079790996181998],
            [-55.986672887535178, -31.081673068726218],
            [-55.910563865384972, -31.082226944889726],
            [-55.890554921344688, -31.079999873209829],
            [-55.837917910360375, -31.072782048727049],
            [-55.759446029698552, -31.016946000567827],
            [-55.671954885918353, -30.949446031193361],
            [-55.640417807494771, -30.870699894658685],
            [-55.629026801047416, -30.850417868212276],
            [-55.584172890097364, -30.846255079807634],
            [-55.521117843989146, -30.888890971533215],
            [-55.421672932781121, -30.963055056865073],
            [-55.350699837996956, -31.045699951853521],
            [-55.330208934522886, -31.078399935527159],
            [-55.24736387968602, -31.23125500447054],
            [-55.22875488111805, -31.249726875102112],
            [-55.089581928042207, -31.32569089032711],
            [-55.063126797527957, -31.325282021088924],
            [-54.976954965311222, -31.318890987537998],
            [-54.942508863563376, -31.350835929371385],
            [-54.913608899523979, -31.374446074301886],
            [-54.865835739327849, -31.409726845970397],
            [-54.834035804419784, -31.426673042581683],
            [-54.807090835482541, -31.435690964595267],
            [-54.763054998284701, -31.441108859185022],
            [-54.72833581413056, -31.43860887273695],
            [-54.705008809890217, -31.440135887861686],
            [-54.594099802039011, -31.460908926197476],
            [-54.472226761889374, -31.57979096800949],
            [-54.463399947268726, -31.672608978910432],
            [-54.441672880710826, -31.681663948936148],
            [-54.414999820713319, -31.693608999184903],
            [-54.383199885805254, -31.715700007979763],
            [-54.351108931218164, -31.750146109727609],
            [-54.285835865885304, -31.804446090306669],
            [-54.164455011091604, -31.887217887309589],
            [-54.095835890001922, -31.88416402469818],
            [-54.071326869772406, -31.875699978847933],
            [-53.918545896853175, -31.942155060169796],
            [-53.875417819771826, -31.974446006967241],
            [-53.743054995406965, -32.096672931069861],
            [-53.6647267805659, -32.206946087744313],
            [-53.637226761998704, -32.271391021041154],
            [-53.643617795549972, -32.305626904656776],
            [-53.630763979356232, -32.357991000872971],
            [-53.567508941037829, -32.43111790950303],
            [-53.553335813280739, -32.44610894337427],
            [-53.537226801161012, -32.46083594729464],
            [-53.521117789041227, -32.474445978794733],
            [-53.404172972696301, -32.568055073719677],
            [-53.378199801616006, -32.574308979334376],
            [-53.349726811725645, -32.579172997760622],
            [-53.259999879086308, -32.600835858940002],
            [-53.191672951142039, -32.63402685450302],
            [-53.159172959678926, -32.661117836194023],
            [-53.098299890611258, -32.723435945373069],
            [-53.170799832882153, -32.802181914269568],
            [-53.245563894844963, -32.862226851301045],
            [-53.298617821694393, -32.946673017287083],
            [-53.435563870728515, -33.040281944573849],
            [-53.462226872442045, -33.059172910365419],
            [-53.480417781678398, -33.074864000791706],
            [-53.50028188643131, -33.099999987380556],
            [-53.515835848921199, -33.129308988295975],
            [-53.521046039949681, -33.141608928326093],
            [-53.524581861995216, -33.157081921631288],
            [-53.530281891446521, -33.199446072055082],
            [-53.52861791602345, -33.221117985689943],
            [-53.522226882472296, -33.25944608208539],
            [-53.515699894451302, -33.290417884957549],
            [-53.509172906430479, -33.321391028933988],
            [-53.501108845000942, -33.40805504650649],
            [-53.501399864680025, -33.428063990546548],
            [-53.505008944559336, -33.46028201478623],
            [-53.50833588957704, -33.486390972698416],
            [-53.513890912103022, -33.5247269480829],
            [-53.523055014508486, -33.574172968519406],
            [-53.528890830791568, -33.611117883249108],
            [-53.534446020955556, -33.656954991444309],
            [-53.521317781251412, -33.681735920613789],
            [-53.397635758753665, -33.741117849102068],
            [-53.374109608532763, -33.740571005893401],
            [-53.370570670999882, -33.742844227999967],
            [-53.359893507914819, -33.750915109238576],
            [-53.241187842021304, -33.828352755031801],
            [-53.113436711173392, -33.911609228802433],
            [-52.985561361228349, -33.994864615266636],
            [-52.866235013349296, -34.072477274160576],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "USA", Country: "United States" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-75.170290777616856, 19.931390740244638],
              [-75.223726841132248, 19.901554398984274],
              [-75.226963882608743, 19.924445026308035],
              [-75.193208952598468, 19.960973192810627],
              [-75.159181946010449, 19.963054084098871],
              [-75.159181820673552, 19.960690828965937],
              [-75.170290777616856, 19.931390740244638],
            ],
          ],
          [
            [
              [-75.121399708394506, 19.887499740789281],
              [-75.134445756920968, 19.886945215974436],
              [-75.159727253678113, 19.890481713730253],
              [-75.164463728065016, 19.902499700133603],
              [-75.139735820018416, 19.962872936241467],
              [-75.087235879903005, 19.965554070546773],
              [-75.085554973035528, 19.917218149369887],
              [-75.085281892306, 19.893036171848848],
              [-75.121399708394506, 19.887499740789281],
            ],
          ],
          [
            [
              [179.999999501000275, 31.468262957000263],
              [179.999919001000194, 25.389793951000058],
              [179.983298501000263, 25.397132951000117],
              [179.966796501000545, 25.404516951000176],
              [179.9503335010005, 25.411973951000277],
              [179.933909501000471, 25.419502951000254],
              [179.917525501000569, 25.427103951000163],
              [179.90118050100034, 25.434777951000228],
              [179.884876501000349, 25.442524951000337],
              [179.868613501000482, 25.450342951000039],
              [179.8523905010004, 25.458232951000241],
              [179.836209501000212, 25.466193951000264],
              [179.820070501000146, 25.474226951000219],
              [179.8039735010002, 25.482331951000049],
              [179.78791950100026, 25.490506951000214],
              [179.771908501000098, 25.498752951000199],
              [179.755940501000168, 25.507069951000176],
              [179.740015501000244, 25.515457951000201],
              [179.724135501000092, 25.523914951000222],
              [179.708300501000167, 25.532442951000064],
              [179.692509501000359, 25.541040951000241],
              [179.676763501000437, 25.549707951000187],
              [179.661063501000285, 25.558444951000126],
              [179.645409501000245, 25.56725095100029],
              [179.629802501000086, 25.576126951000163],
              [179.614241501000265, 25.585070951000148],
              [179.598727501000212, 25.594083951000187],
              [179.583261501000266, 25.603164951000338],
              [179.567842501000314, 25.612314951000144],
              [179.552472501000466, 25.621531951000179],
              [179.537150501000269, 25.630817951000211],
              [179.521878501000174, 25.6401709510003],
              [179.506654501000526, 25.64959095100005],
              [179.491480501000183, 25.659077951000256],
              [179.476357501000166, 25.668632951000177],
              [179.46128350100048, 25.678253951000215],
              [179.446261501000095, 25.687941951000141],
              [179.431289501000151, 25.697695951000185],
              [179.416369501000418, 25.707515951000175],
              [179.401501501000325, 25.71740195100017],
              [179.386685501000329, 25.727353951000055],
              [179.371922501000313, 25.737369951000119],
              [179.357211501000279, 25.74745195100013],
              [179.342554501000222, 25.757598951000205],
              [179.327950501000146, 25.767810951000115],
              [179.313401501000385, 25.778085951000321],
              [179.298905501000377, 25.788426951000247],
              [179.284465501000568, 25.798829951000187],
              [179.270079501000282, 25.809297951000133],
              [179.255749501000082, 25.81982895200008],
              [179.251425501000085, 25.823026952000191],
              [179.240490501000181, 25.831151952000312],
              [179.226271501000383, 25.841808952000292],
              [179.222534501000268, 25.84462795200028],
              [179.20989250100024, 25.854201952000153],
              [179.195786501000271, 25.864983952000216],
              [179.181738501000268, 25.87582895200029],
              [179.167747501000235, 25.886734952000268],
              [179.1538135010004, 25.897702952000031],
              [179.139938501000302, 25.90873295200015],
              [179.126121501000284, 25.919823952000172],
              [179.112363501000232, 25.93097595200004],
              [179.098664501000258, 25.942187952000154],
              [179.085025501000473, 25.953460952000285],
              [179.07144650100031, 25.964792952000153],
              [179.057926501000225, 25.976185952000094],
              [179.044467501000213, 25.987637952000114],
              [179.031069501000275, 25.999149952000153],
              [179.017732501000296, 26.010719952000159],
              [179.004456501000277, 26.022349952000184],
              [178.991243501000213, 26.034037952000233],
              [178.978091501000563, 26.045783952000249],
              [178.965002500000537, 26.05758795200029],
              [178.951976500000342, 26.069449952000298],
              [178.939012500000445, 26.081368952000275],
              [178.926112500000272, 26.093344952000109],
              [178.913276500000279, 26.105377952000083],
              [178.900504500000352, 26.11746795200014],
              [178.887796500000263, 26.129612952000173],
              [178.875153500000238, 26.141814952000061],
              [178.862575500000503, 26.154072952000149],
              [178.850062500000263, 26.166385952000212],
              [178.837615500000311, 26.178753952000193],
              [178.825234500000306, 26.191176952000092],
              [178.812919500000362, 26.203653952000025],
              [178.800671500000135, 26.216185952000274],
              [178.788489500000082, 26.228770952000048],
              [178.77637550000054, 26.241409952000254],
              [178.764328500000488, 26.254102952000096],
              [178.75234950000015, 26.266848952000089],
              [178.740439500000321, 26.279646952000121],
              [178.728596500000208, 26.292497952000076],
              [178.716822500000148, 26.305400952000298],
              [178.705117500000142, 26.31835495200005],
              [178.693482500000073, 26.331361952000179],
              [178.681916500000398, 26.344418952000296],
              [178.670420500000318, 26.357526952000228],
              [178.658994500000063, 26.370685952000258],
              [178.647639500000309, 26.383895952000273],
              [178.63635450000038, 26.397154952000051],
              [178.625140500000498, 26.410463952000271],
              [178.623662500000023, 26.412228952000305],
              [178.613768500000333, 26.424096952000298],
              [178.602697500000545, 26.43750395200027],
              [178.591699500000232, 26.450959952000176],
              [178.580772500000307, 26.464463952000187],
              [178.56991850000054, 26.478016952000132],
              [178.559137500000247, 26.4916169520003],
              [178.548429500000111, 26.505263952000178],
              [178.537794500000246, 26.518958952000219],
              [178.527232500000309, 26.532700952000141],
              [178.516745500000297, 26.546488952000232],
              [178.506332500000326, 26.560322952000149],
              [178.495993500000282, 26.574201952000294],
              [178.485729500000161, 26.588127952000207],
              [178.475539500000082, 26.602097952000179],
              [178.465425500000265, 26.616112952000208],
              [178.45538750000037, 26.63017295200018],
              [178.445424500000286, 26.644276952000098],
              [178.435537500000351, 26.658424952000246],
              [178.425727500000107, 26.672615952000285],
              [178.415993500000127, 26.686849952000273],
              [178.406336500000293, 26.701126952000095],
              [178.396756500000265, 26.715445952000209],
              [178.387253500000384, 26.729807952000158],
              [178.377828500000419, 26.744210952000287],
              [178.36848150000003, 26.75865595200014],
              [178.359212500000353, 26.773141952000231],
              [178.350021500000139, 26.78766795200022],
              [178.340908500000182, 26.802234952000049],
              [178.331875500000365, 26.816841952000175],
              [178.331222500000081, 26.817902952000225],
              [178.327293500000451, 26.824310952000189],
              [178.324507500000323, 26.828780952000216],
              [178.315471500000172, 26.843387952000171],
              [178.306513500000051, 26.858033952000142],
              [178.297636500000294, 26.872719952000239],
              [178.288838500000338, 26.887443952000297],
              [178.280119500000069, 26.902206953000075],
              [178.271481500000164, 26.917008953000163],
              [178.262923500000511, 26.931847953000158],
              [178.254445500000315, 26.946724953000057],
              [178.246048500000484, 26.961638953000204],
              [178.237733500000331, 26.9765899530002],
              [178.229498500000318, 26.991577953000046],
              [178.221345500000211, 27.006601953000256],
              [178.213273500000241, 27.021660953000094],
              [178.205284500000289, 27.036755953000295],
              [178.197376500000132, 27.051885953000181],
              [178.196308500000441, 27.053944953000212],
              [178.189413500000114, 27.067315953000218],
              [178.181671500000334, 27.0825149530001],
              [178.174011500000347, 27.09774795300018],
              [178.166434500000264, 27.113013953000291],
              [178.158940500000313, 27.128313953000202],
              [178.151530500000263, 27.143645953000259],
              [178.144203500000458, 27.159010953000063],
              [178.136960500000328, 27.174407953000184],
              [178.129802500000324, 27.189836953000338],
              [178.122727500000224, 27.2052969530003],
              [178.11573750000025, 27.220788953000294],
              [178.108832500000403, 27.236309953000045],
              [178.102011500000344, 27.251862953000114],
              [178.095276500000182, 27.267444953000279],
              [178.088625500000376, 27.283055953000144],
              [178.08206050000058, 27.298696953000047],
              [178.075581500000226, 27.314365953000333],
              [178.069188500000337, 27.33006395300032],
              [178.062881500000344, 27.345788953000181],
              [178.056660500000248, 27.361542953000026],
              [178.050525500000276, 27.377322953000203],
              [178.044477500000312, 27.393130953000252],
              [178.038515500000244, 27.408963953000239],
              [178.032641500000295, 27.4248239530001],
              [178.026854500000354, 27.44070995300018],
              [178.021154500000534, 27.456620953000197],
              [178.015541500000268, 27.472556953000208],
              [178.010016500000347, 27.488516953000158],
              [178.004579500000318, 27.504500953000218],
              [177.999230500000181, 27.520509953000158],
              [177.993969500000276, 27.536540953000156],
              [177.98879750000026, 27.552595953000207],
              [177.983712500000365, 27.568672953000259],
              [177.978717500000243, 27.584771953000256],
              [177.973810500000127, 27.600893953000138],
              [177.968993500000352, 27.617035953000254],
              [177.96426450000024, 27.633199953000258],
              [177.95962550000047, 27.649383953000154],
              [177.955075500000021, 27.665588953000167],
              [177.950614500000256, 27.681812953000303],
              [177.946244500000148, 27.698056953000162],
              [177.941963500000384, 27.714319953000199],
              [177.937772500000278, 27.730600953000248],
              [177.933641500000249, 27.747024953000107],
              [177.929590500000472, 27.76350595300022],
              [177.925670500000138, 27.77984095300036],
              [177.921840500000144, 27.79619395300017],
              [177.918102500000487, 27.81256195300017],
              [177.914454500000261, 27.828946953000298],
              [177.910897500000488, 27.845347953000214],
              [177.907432500000368, 27.861763953000093],
              [177.904057500000363, 27.878195953000215],
              [177.900774500000466, 27.894641953000303],
              [177.897582499000578, 27.911101953000298],
              [177.894482499000219, 27.927575953000144],
              [177.891473499000313, 27.944062953000241],
              [177.888557499000285, 27.960563953000246],
              [177.885732499000483, 27.977076954000211],
              [177.882999499000334, 27.993601954000269],
              [177.880359499000178, 28.010139954000294],
              [177.877811499000245, 28.026687954000238],
              [177.875355499000193, 28.043247954000151],
              [177.87299149900025, 28.059817954000152],
              [177.870720499000413, 28.076397954000129],
              [177.868542499000114, 28.092987954000023],
              [177.866456499000265, 28.109587954000233],
              [177.864464499000292, 28.126195954000195],
              [177.862564499000428, 28.142812954000192],
              [177.860757499000329, 28.159437954000111],
              [177.859044499000106, 28.176070954000181],
              [177.857423499000106, 28.192710954000347],
              [177.855896499000437, 28.209357954000154],
              [177.854462499000306, 28.226011954000171],
              [177.853122499000278, 28.242671954000173],
              [177.851875499000244, 28.259336954000048],
              [177.850722499000312, 28.276006954000195],
              [177.849662499000488, 28.29268295400027],
              [177.848697499000195, 28.309362954000164],
              [177.847825499000351, 28.326045954000278],
              [177.847046499000271, 28.342733954000096],
              [177.846362499000293, 28.359423954000249],
              [177.845772499000304, 28.376117954000222],
              [177.845276499000306, 28.392812954000306],
              [177.844874499000071, 28.409510954000268],
              [177.844566499000166, 28.426209954000285],
              [177.844352499000252, 28.442910954000297],
              [177.844233499000325, 28.459611954000138],
              [177.844208499000274, 28.47631295400015],
              [177.844277499000441, 28.493013954000162],
              [177.844440499000257, 28.509714954000174],
              [177.844698499000287, 28.526413954000247],
              [177.845051499000533, 28.543111954000153],
              [177.8454984990002, 28.559808954000232],
              [177.84604049900031, 28.576502954000205],
              [177.846315499000298, 28.584037954000166],
              [177.84668649900027, 28.59347795400015],
              [177.847233499000254, 28.606170954000163],
              [177.847601499000348, 28.614295954000283],
              [177.848426499000539, 28.630980954000222],
              [177.849346499000262, 28.647661954000171],
              [177.850361499000428, 28.664338954000186],
              [177.851470499000129, 28.681010954000271],
              [177.852674499000273, 28.697677954000142],
              [177.85397349900029, 28.714339954000138],
              [177.855367499000181, 28.730994954000266],
              [177.856855499000176, 28.747643954000239],
              [177.857601499000339, 28.755610954000247],
              [177.858510499000545, 28.765040954000312],
              [177.860188499000287, 28.78167595400015],
              [177.861960499000361, 28.798302954000292],
              [177.863828499000192, 28.814921954000283],
              [177.865790499000354, 28.831532954000124],
              [177.867847499000163, 28.848134954000329],
              [177.869999499000301, 28.864727954000273],
              [177.872245499000087, 28.881309954000187],
              [177.874586499000543, 28.897882954000295],
              [177.875416499000266, 28.903597954000304],
              [177.877130499000145, 28.915185954000208],
              [177.879661499000349, 28.931736954000257],
              [177.88228749900054, 28.948276954000278],
              [177.884916499000269, 28.964262954000105],
              [177.885130499000411, 28.965544954000336],
              [177.887945499000239, 28.982060954000303],
              [177.88825499900031, 28.983842954000124],
              [177.889411499000289, 28.990474954000149],
              [177.889603499000486, 28.991563954000185],
              [177.891318499000249, 29.001229954000053],
              [177.894322499000509, 29.017719954000029],
              [177.897421499000416, 29.034195954000268],
              [177.900614500000415, 29.050658955000245],
              [177.903901500000188, 29.06710695500027],
              [177.906387500000164, 29.079231955000182],
              [177.907336500000156, 29.083795955000198],
              [177.910813500000472, 29.100214955000126],
              [177.914384500000324, 29.116616955000097],
              [177.918049500000166, 29.133003955000277],
              [177.921809500000222, 29.149373955000272],
              [177.925662500000158, 29.165726955000252],
              [177.929610500000535, 29.182062955000276],
              [177.933652500000335, 29.198381955000116],
              [177.937788500000579, 29.214681955000174],
              [177.942019500000356, 29.230963955000163],
              [177.946343500000353, 29.247226955000201],
              [177.95076150000034, 29.26347095500023],
              [177.955272500000547, 29.279694955000025],
              [177.959878500000286, 29.295898955000155],
              [177.960208500000249, 29.297045955000215],
              [177.964785500000175, 29.312804955000274],
              [177.969578500000466, 29.328966955000283],
              [177.974464500000295, 29.345107955000117],
              [177.979444500000341, 29.36122695500012],
              [177.984517500000152, 29.377323955000293],
              [177.989684500000294, 29.393397955000296],
              [177.994944500000315, 29.409448955000187],
              [178.000296500000331, 29.425476955000249],
              [178.005742500000224, 29.441480955000202],
              [178.011281500000223, 29.457460955000045],
              [178.016912500000558, 29.473415955000178],
              [178.022637500000315, 29.489345955000147],
              [178.028454500000407, 29.505249955000124],
              [178.034363500000268, 29.521128955000165],
              [178.040365500000576, 29.536980955000104],
              [178.04645950000031, 29.552806955000221],
              [178.052645500000381, 29.568604955000296],
              [178.058924500000103, 29.584375955000098],
              [178.065294500000505, 29.600118955000084],
              [178.071756500000106, 29.615833955000255],
              [178.078310500000271, 29.631518955000217],
              [178.084955500000092, 29.647175955000193],
              [178.091692500000477, 29.662802955000359],
              [178.098520500000177, 29.678400955000029],
              [178.101215500000421, 29.684490955000172],
              [178.105661500000451, 29.694465955000169],
              [178.11267150000026, 29.71000195500028],
              [178.119773500000292, 29.725506955000299],
              [178.12696550000021, 29.740980955000225],
              [178.134247500000015, 29.756421955000064],
              [178.141621500000156, 29.771830955000326],
              [178.149084500000185, 29.787207955000213],
              [178.156638500000327, 29.802550955000299],
              [178.16428250000024, 29.817859955000245],
              [178.172016500000268, 29.833135955000159],
              [178.179839500000298, 29.848376955000163],
              [178.187752500000556, 29.863583955000195],
              [178.195754500000248, 29.878755955000145],
              [178.203846500000282, 29.893890955000302],
              [178.210918500000275, 29.906956955000283],
              [178.212149500000351, 29.90921595500015],
              [178.220418500000278, 29.924278955000148],
              [178.228777500000547, 29.939305955000236],
              [178.237223500000368, 29.954295955000077],
              [178.245759500000418, 29.969248955000069],
              [178.254382500000247, 29.984162955000215],
              [178.263093500000537, 29.999039955000114],
              [178.271937500000263, 30.013951955000266],
              [178.275234500000352, 30.01948895500027],
              [178.284121500000083, 30.034287955000138],
              [178.293096500000161, 30.049046955000222],
              [178.30215850000036, 30.06376695500029],
              [178.311307500000225, 30.078445955000177],
              [178.318598500000235, 30.090016955000294],
              [178.320971500000496, 30.093760955000278],
              [178.321431500000244, 30.094485955000209],
              [178.330668500000456, 30.108948955000301],
              [178.340077500000348, 30.123504956000261],
              [178.349572500000136, 30.138019956000221],
              [178.353650500000413, 30.144200956000248],
              [178.359301500000242, 30.152715956000065],
              [178.368968500000392, 30.167145956000127],
              [178.378721500000324, 30.181532956000183],
              [178.388559500000042, 30.195876956000234],
              [178.398482500000227, 30.21017795600028],
              [178.408490500000539, 30.224433956000155],
              [178.410169500000165, 30.226809956000068],
              [178.418890500000202, 30.239079956000296],
              [178.429067500000315, 30.253246956000282],
              [178.43932950000044, 30.267368956000041],
              [178.449674500000356, 30.281445956000027],
              [178.46010450000017, 30.2954769560003],
              [178.470616500000233, 30.309460956000237],
              [178.481213500000308, 30.323398956000233],
              [178.483217500000137, 30.326017956000214],
              [178.48639350000019, 30.330157956000278],
              [178.492380500000309, 30.337925956000163],
              [178.503142500000308, 30.351768956000114],
              [178.513987500000553, 30.365564956000298],
              [178.524914500000136, 30.379312956000263],
              [178.535923500000081, 30.393012956000291],
              [178.547014500000273, 30.406662956000105],
              [178.552339500000357, 30.41316495600023],
              [178.558641500000249, 30.420817956000349],
              [178.56989550000003, 30.434369956000182],
              [178.570550500000309, 30.435155956000244],
              [178.579721500000574, 30.446085956000047],
              [178.581894500000203, 30.448663956000189],
              [178.593310500000484, 30.462115956000162],
              [178.604806500000223, 30.475516956000149],
              [178.616383500000325, 30.488867956000263],
              [178.628039500000341, 30.502166956000224],
              [178.639775500000269, 30.515414956000143],
              [178.651591500000222, 30.528609956000139],
              [178.653399500000432, 30.530616956000131],
              [178.664343500000541, 30.542704956000193],
              [178.676317500000209, 30.555794956000057],
              [178.688319000000433, 30.568778456000132],
              [178.690692000000126, 30.571330456000283],
              [178.691538500000547, 30.572241956000198],
              [178.703669500000274, 30.585225956000158],
              [178.715877500000147, 30.598155956000085],
              [178.728164500000162, 30.611032956000258],
              [178.740528500000323, 30.623854956000343],
              [178.75296950000029, 30.636621956000283],
              [178.765487500000063, 30.649333956000305],
              [178.778081500000098, 30.661990956000182],
              [178.790752500000281, 30.674591956000256],
              [178.803499500000157, 30.687136956000302],
              [178.816321500000413, 30.699624956000264],
              [178.829218500000252, 30.712056956000197],
              [178.842190500000129, 30.724430956000162],
              [178.855237500000158, 30.736747956000158],
              [178.868359500000338, 30.749007956000071],
              [178.881554500000334, 30.761208956000075],
              [178.894823500000257, 30.773351956000283],
              [178.908165500000337, 30.785435956000185],
              [178.921581500000343, 30.797460956000236],
              [178.935069500000282, 30.809426956000152],
              [178.948629500000266, 30.821332956000276],
              [178.962262500000293, 30.833178956000211],
              [178.975966501000244, 30.844964956000183],
              [178.989742501000364, 30.856689956000253],
              [179.003588501000422, 30.868353956000192],
              [179.017506501000184, 30.879956956000285],
              [179.031493501000568, 30.891497956000251],
              [179.045551501000205, 30.902976956000202],
              [179.059678501000235, 30.914393956000197],
              [179.067692501000352, 30.920818956000119],
              [179.073920501000543, 30.925784956000143],
              [179.088185501000453, 30.937076956000283],
              [179.102519501000188, 30.948305956000297],
              [179.116921501000434, 30.959470956000246],
              [179.13139150100028, 30.970571956000185],
              [179.145929501000523, 30.981608956000173],
              [179.160533501000032, 30.992582956000263],
              [179.175204501000508, 31.003490956000235],
              [179.189942501000473, 31.014334956000198],
              [179.204746501000386, 31.025112956000271],
              [179.219615501000135, 31.035825956000281],
              [179.234549501000402, 31.046472956000116],
              [179.249549501000502, 31.057053956000232],
              [179.264612501000329, 31.067567956000289],
              [179.276472501000171, 31.075767956000163],
              [179.279959501000235, 31.078166956000246],
              [179.295151501000078, 31.088547956000298],
              [179.310406501000557, 31.098861956000349],
              [179.325724501000423, 31.10910795600023],
              [179.341104501000132, 31.11928695600028],
              [179.356547501000364, 31.129396956000107],
              [179.372051501000328, 31.139439956000274],
              [179.387616501000252, 31.149412956000162],
              [179.403243501000247, 31.15931795600028],
              [179.418930501000204, 31.169153956000173],
              [179.434677501000351, 31.178919956000129],
              [179.450484501000346, 31.188615957000252],
              [179.466350501000306, 31.198242957000275],
              [179.482276501000342, 31.207799957000191],
              [179.498259501000462, 31.217285957000115],
              [179.514301501000318, 31.226700957000162],
              [179.530400501000258, 31.236044957000217],
              [179.546557501000393, 31.245318957000165],
              [179.562770501000159, 31.254519957000127],
              [179.579040501000236, 31.263649957000268],
              [179.587152501000332, 31.268163957000183],
              [179.601524501000426, 31.276131957000246],
              [179.617906501000334, 31.285117957000182],
              [179.634343501000103, 31.294031957000186],
              [179.650835501000074, 31.302871957000264],
              [179.66738150100025, 31.311639957000295],
              [179.683980501000178, 31.320334957000284],
              [179.700633501000311, 31.328955957000176],
              [179.717340501000308, 31.337502957000027],
              [179.73409850100029, 31.345976957000232],
              [179.750909501000478, 31.35437695700017],
              [179.764148501000022, 31.360920957000189],
              [179.777054501000293, 31.367283957000154],
              [179.793968501000336, 31.375534957000184],
              [179.810933501000363, 31.383710957000233],
              [179.827949501000148, 31.3918119570003],
              [179.845014501000264, 31.399836957000275],
              [179.86212850100037, 31.407786957000269],
              [179.879292501000123, 31.415661957000282],
              [179.896503501000439, 31.423460957000202],
              [179.913763501000517, 31.431182957000203],
              [179.931071501000133, 31.438828957000283],
              [179.948425501000543, 31.44639895700027],
              [179.965826501000265, 31.45389195700011],
              [179.98327450100021, 31.461307957000145],
              [179.999999501000275, 31.468262957000263],
            ],
          ],
          [
            [
              [-172.187951826999978, 29.117494955000097],
              [-172.182369326999833, 29.104644955000197],
              [-172.163158826999933, 29.106667955000262],
              [-172.14426682699991, 29.10856795500024],
              [-172.12536382599987, 29.110383955000259],
              [-172.106449825999874, 29.112112955000327],
              [-172.087524825999964, 29.113756955000269],
              [-172.068590825999848, 29.115315955000312],
              [-172.04964782599987, 29.116788955000118],
              [-172.030695825999828, 29.11817595500014],
              [-172.011735825999864, 29.119476955000152],
              [-171.992768825999946, 29.120691955000325],
              [-171.973793825999792, 29.1218219550002],
              [-171.954812825999966, 29.122865955000236],
              [-171.935824825999987, 29.123823955000091],
              [-171.916831825999878, 29.124694955000223],
              [-171.897833825999953, 29.125480955000057],
              [-171.878831825999981, 29.126181955000163],
              [-171.859825825999934, 29.126794955000264],
              [-171.840815825999897, 29.127323955000293],
              [-171.821802825999896, 29.127765955000143],
              [-171.802787825999843, 29.128121955000267],
              [-171.783770825999852, 29.128391955000041],
              [-171.76475182599998, 29.128574955000261],
              [-171.745732825999823, 29.128672955000184],
              [-171.733615825999834, 29.128690955000081],
              [-171.714596825999877, 29.128647955000076],
              [-171.695577825999919, 29.128518955000061],
              [-171.676559825999846, 29.128302955000152],
              [-171.657542825999968, 29.128001955000286],
              [-171.638528825999884, 29.12761495500024],
              [-171.619516825999966, 29.127141955000184],
              [-171.600507825999841, 29.126582955000288],
              [-171.581502825999792, 29.125936955000157],
              [-171.5625028259999, 29.12520595500024],
              [-171.543505825999887, 29.124388955000143],
              [-171.524515825999828, 29.123485955000206],
              [-171.505529825999844, 29.122496955000258],
              [-171.486550825999899, 29.121421955000301],
              [-171.483463825999934, 29.121238955000081],
              [-171.478267825999978, 29.12093595500005],
              [-171.459295825999817, 29.119774955000253],
              [-171.440330825999894, 29.118528955000215],
              [-171.421373825999893, 29.117195955000227],
              [-171.402424825999873, 29.115777955000169],
              [-171.383484825999858, 29.114273955000044],
              [-171.364554825999875, 29.112684955000191],
              [-171.345633825999954, 29.111009955000156],
              [-171.326723825999949, 29.109248955000112],
              [-171.307824825999859, 29.107402955000168],
              [-171.288936825999912, 29.105470955000158],
              [-171.270060825999963, 29.103453955000077],
              [-171.25119682599987, 29.101350955000157],
              [-171.23234682599994, 29.099162955000338],
              [-171.213509825999836, 29.096889955000165],
              [-171.194686825999923, 29.09453195500015],
              [-171.191763825999828, 29.094157955000242],
              [-171.175523825999846, 29.092042955000238],
              [-171.156729825999946, 29.089513955000257],
              [-171.137951825999863, 29.086900955000203],
              [-171.119190825999823, 29.084201955000196],
              [-171.100444825999887, 29.081418955000174],
              [-171.081716825999933, 29.078550955000196],
              [-171.063005824999863, 29.075597955000262],
              [-171.044313824999875, 29.072560955000142],
              [-171.025639824999956, 29.069438955000123],
              [-171.006984824999904, 29.066231955000148],
              [-170.988349824999915, 29.062941955000269],
              [-170.96973482499979, 29.059566955000264],
              [-170.951139824999927, 29.056107955000243],
              [-170.932566824999839, 29.052563955000096],
              [-170.91401582499995, 29.048936955000215],
              [-170.895485824999952, 29.045225955000262],
              [-170.876978824999895, 29.04143095500018],
              [-170.858495824999949, 29.037552954000205],
              [-170.840035824999887, 29.033589954000036],
              [-170.821599824999822, 29.029544954000187],
              [-170.803188824999893, 29.025415954000152],
              [-170.7848028249999, 29.021203954000157],
              [-170.766441824999845, 29.016907954000146],
              [-170.748107824999892, 29.012529954000286],
              [-170.72980082499987, 29.00806895400018],
              [-170.711519824999954, 29.003524954000056],
              [-170.69326782499985, 28.998897954000199],
              [-170.675042824999849, 28.994188954000151],
              [-170.65684682499986, 28.989397954000026],
              [-170.638679824999969, 28.984523954000167],
              [-170.620542824999973, 28.979567954000288],
              [-170.602435824999873, 28.974529954000161],
              [-170.584359824999865, 28.969409954000184],
              [-170.566313824999867, 28.964208954000071],
              [-170.548299824999901, 28.958925954000279],
              [-170.530318824999853, 28.953561954000236],
              [-170.512368824999868, 28.948115954000286],
              [-170.494452824999968, 28.9425889540002],
              [-170.476570824999925, 28.936980954000205],
              [-170.458721824999856, 28.931292954000298],
              [-170.440907824999869, 28.925523954000084],
              [-170.423127824999824, 28.919673954000302],
              [-170.405383824999831, 28.913743954000267],
              [-170.387675824999889, 28.907732954000323],
              [-170.370004824999825, 28.901642954000181],
              [-170.352369824999897, 28.895472954000184],
              [-170.334772824999817, 28.889222954000161],
              [-170.317212824999871, 28.882892954000283],
              [-170.29969182499994, 28.876484954000148],
              [-170.282208824999969, 28.869996954000158],
              [-170.264765824999927, 28.863429954000139],
              [-170.247361824999814, 28.856783954000207],
              [-170.229997824999828, 28.850059954000244],
              [-170.21267482499988, 28.84325695400031],
              [-170.195392824999828, 28.836375954000289],
              [-170.178152824999898, 28.829415954000297],
              [-170.164425824999824, 28.823799954000151],
              [-170.156283824999832, 28.822889954000175],
              [-170.137483824999862, 28.82070295400024],
              [-170.11869682499983, 28.818430954000178],
              [-170.099923824999877, 28.816072954000106],
              [-170.081165824999829, 28.813630954000189],
              [-170.062421824999888, 28.811102954000148],
              [-170.043693824999934, 28.808489954000095],
              [-170.024981824999969, 28.805792954000253],
              [-170.006286824999904, 28.803009954000174],
              [-169.987608823999807, 28.800142954000137],
              [-169.968947823999798, 28.797190954000143],
              [-169.950304823999858, 28.794154954000248],
              [-169.9316798239999, 28.79103395400017],
              [-169.913074823999835, 28.787827954000079],
              [-169.894488823999893, 28.784538954000084],
              [-169.875923823999926, 28.781164954000189],
              [-169.857378823999824, 28.777705954000169],
              [-169.838854823999867, 28.774163954000187],
              [-169.820351823999886, 28.77053795400019],
              [-169.801871823999932, 28.766827954000178],
              [-169.783413823999837, 28.763034954000034],
              [-169.764978823999883, 28.759156954000161],
              [-169.746567823999953, 28.755195954000158],
              [-169.728180823999878, 28.75115195400025],
              [-169.709817823999828, 28.747023954000269],
              [-169.69148082399991, 28.742812954000215],
              [-169.673168823999902, 28.738518954000199],
              [-169.654882823999827, 28.734141954000222],
              [-169.636623823999884, 28.729681954000171],
              [-169.618391823999929, 28.725139954000042],
              [-169.600186823999792, 28.72051495400018],
              [-169.582010823999866, 28.715806954000186],
              [-169.563861823999844, 28.711016954000172],
              [-169.545742823999831, 28.706144954000138],
              [-169.527653823999827, 28.701189954000142],
              [-169.509593823999978, 28.696153954000181],
              [-169.491564823999852, 28.691035954000199],
              [-169.473566823999903, 28.685835954000197],
              [-169.455599823999847, 28.680553954000118],
              [-169.437665823999879, 28.675191954000184],
              [-169.419763823999858, 28.669747954000059],
              [-169.401893823999842, 28.664221954000254],
              [-169.384057823999882, 28.658615954000197],
              [-169.366255823999893, 28.652928954000231],
              [-169.348487823999875, 28.647161954000183],
              [-169.330754823999911, 28.641313954000225],
              [-169.313057823999912, 28.635384954000301],
              [-169.295395823999854, 28.629376954000179],
              [-169.277769823999961, 28.623287954000148],
              [-169.260180823999832, 28.617119954000145],
              [-169.242628823999922, 28.610871954000118],
              [-169.22511482399986, 28.604543954000235],
              [-169.207638823999844, 28.598136954000154],
              [-169.190201823999956, 28.591650954000158],
              [-169.172802823999916, 28.585085954000192],
              [-169.155444823999886, 28.578441954000198],
              [-169.1381258239999, 28.57171995400023],
              [-169.120846823999841, 28.564918954000063],
              [-169.103609823999875, 28.558039954000037],
              [-169.086413823999948, 28.55108195400021],
              [-169.069258823999832, 28.544046954000294],
              [-169.052146823999919, 28.536933954000062],
              [-169.035077823999842, 28.529743954000253],
              [-169.018051823999826, 28.52247695400024],
              [-169.00106882399993, 28.515131954000253],
              [-168.984130823999834, 28.507709954000234],
              [-168.967236823999826, 28.500211954000122],
              [-168.950387823999819, 28.492636954000261],
              [-168.933584823999894, 28.484985954000138],
              [-168.916826822999866, 28.477258954000092],
              [-168.900115822999823, 28.469455954000182],
              [-168.883451822999945, 28.461576954000179],
              [-168.866833822999951, 28.453622954000025],
              [-168.850264822999833, 28.44559395400006],
              [-168.833742822999881, 28.43748895400023],
              [-168.81726982299989, 28.429309954000246],
              [-168.800845822999975, 28.421055954000167],
              [-168.78447082299985, 28.412727954000161],
              [-168.768145822999855, 28.404325954000228],
              [-168.751870822999905, 28.395849954000255],
              [-168.735646822999911, 28.387299954000298],
              [-168.719473822999873, 28.378676954000241],
              [-168.703351822999906, 28.369979954000257],
              [-168.687282822999947, 28.361210954000171],
              [-168.671265822999942, 28.352368954000269],
              [-168.655300822999891, 28.343453954000154],
              [-168.639389822999959, 28.334466954000163],
              [-168.623531822999865, 28.325407954000241],
              [-168.607727822999919, 28.316276954000216],
              [-168.591978822999891, 28.3070749540002],
              [-168.583450822999936, 28.302035954000189],
              [-168.576341822999979, 28.303052954000293],
              [-168.557696822999873, 28.305633954000143],
              [-168.539036822999861, 28.308128954000153],
              [-168.520362822999829, 28.310539954000205],
              [-168.501673822999891, 28.31286495400019],
              [-168.482971822999872, 28.315104954000049],
              [-168.464256822999829, 28.317259954000178],
              [-168.445528822999876, 28.319329954000239],
              [-168.426788822999811, 28.321314954000172],
              [-168.408036822999833, 28.323213954000266],
              [-168.389273822999911, 28.32502695400018],
              [-168.370499822999875, 28.326755954000191],
              [-168.35171682299989, 28.328397954000195],
              [-168.332922822999905, 28.329954954000243],
              [-168.314120822999854, 28.331426954000165],
              [-168.295309822999968, 28.332812954000076],
              [-168.276490822999847, 28.334112954000204],
              [-168.257664822999857, 28.335326954000266],
              [-168.238830822999859, 28.336455954000257],
              [-168.219990822999847, 28.337498954000182],
              [-168.201144822999908, 28.338454954000269],
              [-168.182293822999895, 28.339326954000285],
              [-168.163436822999927, 28.340111954000236],
              [-168.144575822999883, 28.340810954000176],
              [-168.125711822999961, 28.341424954000161],
              [-168.10684282299988, 28.341951954000251],
              [-168.087971822999833, 28.342393954000215],
              [-168.069098822999848, 28.342749954000169],
              [-168.050222822999899, 28.343018954000229],
              [-168.031345822999896, 28.343202954000162],
              [-168.012468822999864, 28.343300954000142],
              [-168.000441822999846, 28.343317954000099],
              [-167.981563822999817, 28.343274954000094],
              [-167.962686822999984, 28.343145954000079],
              [-167.94381082299995, 28.342930954000053],
              [-167.924935822999913, 28.342630954000299],
              [-167.906062822999843, 28.342243954000253],
              [-167.887192822999879, 28.341770954000197],
              [-167.868325822999878, 28.3412119540003],
              [-167.849461822999842, 28.340566954000224],
              [-167.830602821999889, 28.339836954000191],
              [-167.81174782199983, 28.339019954000264],
              [-167.792898821999898, 28.338117954000268],
              [-167.774054821999925, 28.337129954000204],
              [-167.76305982199986, 28.336513954000054],
              [-167.755055821999832, 28.336046954000153],
              [-167.736224821999883, 28.334886954000297],
              [-167.717400821999888, 28.333641954000313],
              [-167.698584821999845, 28.332309954000209],
              [-167.679777821999835, 28.330892954000205],
              [-167.660977821999865, 28.329390954000303],
              [-167.642188821999838, 28.327802954000163],
              [-167.623407821999848, 28.326128954000183],
              [-167.60463882199997, 28.324369954000304],
              [-167.585879821999839, 28.322524954000073],
              [-167.567131821999823, 28.320594954000285],
              [-167.548395821999833, 28.318579954000143],
              [-167.529672821999839, 28.316478954000047],
              [-167.510961821999871, 28.314292954000223],
              [-167.492264821999896, 28.312021954000045],
              [-167.47358082199986, 28.309665954000138],
              [-167.4549118219999, 28.307223954000278],
              [-167.436257821999845, 28.304697954000176],
              [-167.41761882199998, 28.302086954000117],
              [-167.398995821999819, 28.29939095400016],
              [-167.380389821999927, 28.296609954000303],
              [-167.361799821999938, 28.29374395400032],
              [-167.343227821999818, 28.290793954000094],
              [-167.324672821999968, 28.28775895400014],
              [-167.306137821999897, 28.284639954000227],
              [-167.287619821999925, 28.281435954000131],
              [-167.2691228219999, 28.278148954000301],
              [-167.250644821999828, 28.27477595400029],
              [-167.232187821999815, 28.271319954000148],
              [-167.213751821999864, 28.267779954000162],
              [-167.195336821999973, 28.264155954000159],
              [-167.176943821999828, 28.260447954000142],
              [-167.158573821999852, 28.25665595400028],
              [-167.140225821999877, 28.252780954000286],
              [-167.121901821999955, 28.248822954000161],
              [-167.103601821999973, 28.244779954000137],
              [-167.085325821999845, 28.240654954000263],
              [-167.067074821999825, 28.236445954000203],
              [-167.04884882199994, 28.232154954000237],
              [-167.030649821999958, 28.227779954000084],
              [-167.012476821999826, 28.223322954000082],
              [-166.994329821999884, 28.218782954000346],
              [-166.976210821999871, 28.214159954000081],
              [-166.958119821999958, 28.209454954000194],
              [-166.94005682199986, 28.204666954000288],
              [-166.92202282199986, 28.199797954000303],
              [-166.90401882199987, 28.194845954000243],
              [-166.886043821999948, 28.189811954000277],
              [-166.868098821999837, 28.184696954000174],
              [-166.850184821999818, 28.17949995400005],
              [-166.832302821999974, 28.174220954000248],
              [-166.814451821999825, 28.168860954000138],
              [-166.79663282199985, 28.163419954000233],
              [-166.792700821999858, 28.162206954000283],
              [-166.777778821999959, 28.157567954000115],
              [-166.760026820999855, 28.151964954000164],
              [-166.742306820999886, 28.146280954000247],
              [-166.724621820999801, 28.140515954000193],
              [-166.706971820999968, 28.134670954000285],
              [-166.689356820999905, 28.128745954000124],
              [-166.671776820999895, 28.122740954000278],
              [-166.654232820999852, 28.116654954000182],
              [-166.636725820999942, 28.110489954000059],
              [-166.619255820999882, 28.10424495400008],
              [-166.601822820999871, 28.097920954000358],
              [-166.584427820999821, 28.091517954000267],
              [-166.567070820999959, 28.08503495400015],
              [-166.549752820999856, 28.078472954000233],
              [-166.53247482099988, 28.071832954000229],
              [-166.515235820999948, 28.06511395400014],
              [-166.498036820999943, 28.05831595400025],
              [-166.480878820999948, 28.051440954000213],
              [-166.463761820999963, 28.044486954000206],
              [-166.446686820999901, 28.037454954000282],
              [-166.429652820999962, 28.030345954000211],
              [-166.41266182099983, 28.023159954000221],
              [-166.395713820999816, 28.015895954000143],
              [-166.378809820999862, 28.00855495400026],
              [-166.361948820999856, 28.001136954000231],
              [-166.34513182099991, 27.993641954000168],
              [-166.328359820999964, 27.986070954000297],
              [-166.311632820999847, 27.978423954000334],
              [-166.29495182099987, 27.970700954000279],
              [-166.278316820999891, 27.962901953000255],
              [-166.261727820999909, 27.955026953000242],
              [-166.245185820999893, 27.947076953000192],
              [-166.228691820999956, 27.939050953000162],
              [-166.212244820999899, 27.930950953000149],
              [-166.195845820999835, 27.922774953000271],
              [-166.179495820999904, 27.914525953000066],
              [-166.163195820999846, 27.90620195300022],
              [-166.146943820999837, 27.897802953000166],
              [-166.130742820999984, 27.889330953000183],
              [-166.114590820999837, 27.880785953000156],
              [-166.106830820999818, 27.876643953000269],
              [-166.098276820999843, 27.872051953000096],
              [-166.082227820999861, 27.863358953000272],
              [-166.066229820999837, 27.854593953000176],
              [-166.050283820999937, 27.845755953000037],
              [-166.034390820999903, 27.836845953000193],
              [-166.018550820999877, 27.827862953000363],
              [-166.002763820999803, 27.818807953000032],
              [-165.987029820999879, 27.809681953000279],
              [-165.971350820999845, 27.800483953000196],
              [-165.955725820999959, 27.791213953000238],
              [-165.940155820999905, 27.781873953000172],
              [-165.924640820999883, 27.772462953000229],
              [-165.909181820999947, 27.762981953000235],
              [-165.893778820999842, 27.753429953000193],
              [-165.878432820999848, 27.743807953000214],
              [-165.86302032099988, 27.734038453000096],
              [-165.84770182099993, 27.724222953000151],
              [-165.832527820999957, 27.714391953000302],
              [-165.817410820999896, 27.70449295300034],
              [-165.802352820999857, 27.694524953000155],
              [-165.787352820999871, 27.684488953000027],
              [-165.772412820999818, 27.674384953000356],
              [-165.757532820999927, 27.664211953000063],
              [-165.755889820999869, 27.663082953000298],
              [-165.742438820999809, 27.653784953000297],
              [-165.727678820999898, 27.643477953000342],
              [-165.712979820999976, 27.633102953000218],
              [-165.698340819999856, 27.622661953000147],
              [-165.683764819999794, 27.612154953000186],
              [-165.669249819999919, 27.60158095300028],
              [-165.654796819999916, 27.590940953000143],
              [-165.640406819999868, 27.580235953000283],
              [-165.626079819999831, 27.569464953000079],
              [-165.611815819999919, 27.558628953000039],
              [-165.597614819999876, 27.547728953000217],
              [-165.583478819999812, 27.53676295300005],
              [-165.569406819999898, 27.525733953000213],
              [-165.555399819999849, 27.514640953000026],
              [-165.541457819999863, 27.503483953000227],
              [-165.527581819999796, 27.492263953000247],
              [-165.513770819999905, 27.480980953000142],
              [-165.500025819999848, 27.469634953000195],
              [-165.48634781999985, 27.458226953000235],
              [-165.472736819999881, 27.446755953000036],
              [-165.459192819999856, 27.435223953000161],
              [-165.44571581999989, 27.423629953000273],
              [-165.432306819999923, 27.41197495300014],
              [-165.418966819999895, 27.400259953000159],
              [-165.405694819999923, 27.388482953000278],
              [-165.392490819999864, 27.376646953000261],
              [-165.379356819999941, 27.364749953000171],
              [-165.366292819999842, 27.352793953000173],
              [-165.353297819999852, 27.340777953000213],
              [-165.340372819999885, 27.328703953000229],
              [-165.327518819999852, 27.316570953000166],
              [-165.314734819999927, 27.304378953000139],
              [-165.30202281999982, 27.292129953000085],
              [-165.289381819999818, 27.279821953000294],
              [-165.276811819999921, 27.267457953000303],
              [-165.264314819999839, 27.255035953000288],
              [-165.251889819999946, 27.242557953000244],
              [-165.239537819999867, 27.230022953000173],
              [-165.227257819999892, 27.217431953000187],
              [-165.215051819999843, 27.204785953000169],
              [-165.202918819999837, 27.192083953000065],
              [-165.190859819999872, 27.179326953000214],
              [-165.178874819999862, 27.166514953000274],
              [-165.166964819999862, 27.153648953000356],
              [-165.155128819999845, 27.140728953000178],
              [-165.143367819999924, 27.127754953000135],
              [-165.131682819999895, 27.11472795300017],
              [-165.120071819999936, 27.10164695300017],
              [-165.108537819999952, 27.088514953000242],
              [-165.097079819999863, 27.07532895300028],
              [-165.085697819999837, 27.062091953000163],
              [-165.07733581999986, 27.052262953000309],
              [-165.066648819999983, 27.04907995300016],
              [-165.048976819999808, 27.043725953000205],
              [-165.031335819999839, 27.038290953000285],
              [-165.0137278199999, 27.032775953000169],
              [-164.996152819999878, 27.027178953000146],
              [-164.978610819999886, 27.021501953000268],
              [-164.961101819999897, 27.015743953000083],
              [-164.943627819999875, 27.009905953000043],
              [-164.92618782, 27.003986953000037],
              [-164.908782819999914, 26.997988953000288],
              [-164.891413819999855, 26.991909953000174],
              [-164.874079819999878, 26.985751953000317],
              [-164.856782819999921, 26.979514953000091],
              [-164.839522819999928, 26.973197953000181],
              [-164.836909819999903, 26.972232953000116],
              [-164.822026819999849, 26.966699953000273],
              [-164.804841819999837, 26.960224953000079],
              [-164.787694819999956, 26.953670953000142],
              [-164.77448082, 26.948555953000039],
              [-164.769415819999836, 26.946581953000134],
              [-164.76161281999984, 26.943525953000233],
              [-164.756200819999862, 26.941504953000162],
              [-164.739019819999925, 26.935029953000196],
              [-164.721876819999835, 26.928475953000031],
              [-164.707018819999888, 26.922713953000255],
              [-164.701642819999876, 26.922721953000178],
              [-164.683006819999804, 26.922678953000172],
              [-164.664371819999815, 26.922550953000268],
              [-164.645737819999823, 26.922335953000243],
              [-164.627104819999829, 26.922035953000091],
              [-164.608474818999895, 26.921648953000044],
              [-164.58984681899986, 26.92117695300027],
              [-164.571221818999817, 26.920618953000087],
              [-164.552599818999937, 26.919975953000176],
              [-164.533982818999817, 26.9192459530002],
              [-164.515369818999972, 26.918430953000268],
              [-164.496761818999829, 26.917529953000155],
              [-164.478159818999984, 26.916543953000257],
              [-164.459562818999842, 26.915470953000295],
              [-164.440973818999851, 26.914312953000206],
              [-164.422390818999901, 26.913069953000047],
              [-164.403815818999902, 26.911740953000049],
              [-164.385248818999855, 26.910325953000211],
              [-164.366690818999842, 26.908825953000246],
              [-164.348141818999864, 26.907239953000158],
              [-164.329601818999919, 26.905568953000284],
              [-164.311072818999918, 26.903812953000283],
              [-164.292553818999835, 26.901970953000273],
              [-164.274045818999866, 26.900043953000193],
              [-164.255549818999839, 26.898031953000157],
              [-164.23706581899981, 26.895933953000281],
              [-164.218593818999807, 26.893751953000219],
              [-164.200135818999911, 26.891483952000215],
              [-164.18169081899984, 26.889130952000244],
              [-164.163259818999933, 26.88669395200003],
              [-164.144843818999846, 26.884170952000204],
              [-164.126442818999863, 26.881563952000306],
              [-164.108057818999868, 26.878871952000168],
              [-164.089688818999861, 26.876095952000298],
              [-164.071335818999842, 26.873234952000189],
              [-164.053000818999891, 26.87028895200018],
              [-164.03468281899984, 26.86725895200027],
              [-164.016382818999858, 26.86414395200012],
              [-163.998101818999857, 26.860945952000179],
              [-163.979838818999923, 26.857662952000169],
              [-163.961596818999936, 26.854295952000143],
              [-163.94337381899993, 26.850844952000216],
              [-163.925171818999985, 26.847309952000273],
              [-163.906990818999901, 26.843691952000256],
              [-163.888831818999847, 26.839989952000224],
              [-163.870694818999851, 26.83620395200029],
              [-163.852579818999828, 26.832334952000281],
              [-163.834487818999889, 26.8283819520002],
              [-163.816419818999833, 26.824345952000215],
              [-163.798374818999918, 26.820226952000155],
              [-163.780354818999911, 26.816023952000194],
              [-163.76235981899984, 26.811738952000212],
              [-163.744390818999904, 26.807370952000099],
              [-163.726446818999875, 26.802920952000306],
              [-163.708529818999978, 26.798386952000158],
              [-163.690638818999901, 26.79377195200027],
              [-163.672775818999838, 26.78907295200014],
              [-163.654940818999876, 26.784292952000214],
              [-163.637134818999812, 26.779430952000268],
              [-163.619356818999876, 26.774485952000191],
              [-163.60160781899981, 26.769459952000204],
              [-163.583888818999952, 26.764352952000252],
              [-163.56619981899982, 26.759162952000167],
              [-163.548541817999819, 26.753891952000345],
              [-163.530914817999843, 26.748539952000158],
              [-163.513319817999928, 26.743106952000232],
              [-163.495756817999819, 26.73759295200017],
              [-163.47822581799997, 26.731997952000143],
              [-163.460728817999893, 26.726322952000089],
              [-163.443264817999903, 26.72056695200007],
              [-163.425834817999856, 26.714729952000141],
              [-163.408438817999894, 26.708813952000185],
              [-163.3910778179999, 26.702816952000148],
              [-163.373752817999844, 26.6967399520002],
              [-163.356463817999838, 26.690583952000338],
              [-163.339210817999884, 26.684347952000223],
              [-163.321993817999896, 26.678032952000137],
              [-163.304814817999869, 26.671638952000251],
              [-163.287673817999945, 26.665164952000168],
              [-163.270569817999871, 26.658612952000169],
              [-163.253505817999837, 26.651981952000256],
              [-163.23647981799985, 26.645271952000201],
              [-163.219493817999876, 26.638484952000283],
              [-163.202546817999831, 26.63161895200011],
              [-163.185640817999825, 26.624674952000191],
              [-163.168775817999915, 26.617652952000071],
              [-163.151951817999844, 26.610553952000146],
              [-163.135169817999866, 26.60337695200036],
              [-163.118429817999839, 26.596123952000085],
              [-163.101732817999959, 26.588792952000176],
              [-163.085078817999829, 26.581384952000235],
              [-163.068467817999789, 26.573900952000258],
              [-163.05190081799995, 26.566339952000078],
              [-163.035378817999828, 26.558703952000144],
              [-163.01890081799985, 26.550990952000234],
              [-163.002468817999983, 26.543201952000231],
              [-162.986081817999832, 26.535337952000191],
              [-162.969740817999934, 26.527398952000055],
              [-162.953446817999833, 26.519383952000169],
              [-162.937199817999897, 26.511294952000242],
              [-162.920999817999956, 26.503130952000106],
              [-162.904847817999979, 26.49489195200016],
              [-162.888743817999966, 26.486578952000173],
              [-162.87268881799983, 26.478191952000259],
              [-162.856681817999856, 26.469730952000248],
              [-162.840725817999839, 26.461195952000139],
              [-162.824818817999898, 26.452588952000156],
              [-162.808961817999887, 26.443907952000245],
              [-162.800264817999903, 26.439102952000212],
              [-162.785378817999856, 26.430855952000172],
              [-162.769624817999869, 26.422028952000062],
              [-162.753921818, 26.413129952000247],
              [-162.738271817999902, 26.404158952000159],
              [-162.722673817999834, 26.39511595200014],
              [-162.70712781799989, 26.386000952000188],
              [-162.691635817999895, 26.376814952000245],
              [-162.676196817999852, 26.367557952000084],
              [-162.660811817999928, 26.358228952000218],
              [-162.645481817999922, 26.348829952000244],
              [-162.639319817999876, 26.345010952000052],
              [-162.622354817999963, 26.348042952000299],
              [-162.604147817999859, 26.351208952000263],
              [-162.585922817999858, 26.354290952000156],
              [-162.567679817999903, 26.35728895200026],
              [-162.54941881799985, 26.360201952000182],
              [-162.531141818, 26.363030952000145],
              [-162.512848817999981, 26.365775952000263],
              [-162.494539817999879, 26.3684349520002],
              [-162.476214816999857, 26.371009952000065],
              [-162.457875816999859, 26.373500952000143],
              [-162.439521816999928, 26.375905952000153],
              [-162.421153816999919, 26.378225952000264],
              [-162.402772816999885, 26.380461952000189],
              [-162.384379816999939, 26.382611952000275],
              [-162.365972816999886, 26.384677952000118],
              [-162.347554816999832, 26.386657952000178],
              [-162.329125816999834, 26.388552952000111],
              [-162.310685817, 26.390362952000146],
              [-162.292234816999951, 26.392086952000341],
              [-162.273774816999975, 26.393726952000293],
              [-162.255304816999853, 26.39527995200018],
              [-162.236826816999894, 26.396748952000223],
              [-162.218339816999816, 26.398131952000256],
              [-162.199844816999956, 26.399428952000278],
              [-162.181342816999944, 26.400640952000174],
              [-162.162833816999893, 26.401766952000287],
              [-162.144317816999887, 26.402807952000273],
              [-162.125796816999838, 26.40376295200025],
              [-162.107269816999803, 26.404631952000216],
              [-162.088738817, 26.405414952000342],
              [-162.070202816999853, 26.406112952000171],
              [-162.05166381699982, 26.406725952000272],
              [-162.033120816999968, 26.407251952000252],
              [-162.014574816999897, 26.407692952000161],
              [-161.996026817, 26.408047952000231],
              [-161.977476816999939, 26.408316952000291],
              [-161.95892581699988, 26.408499952000113],
              [-161.940372816999826, 26.408597952000264],
              [-161.928553817, 26.40861495200005],
              [-161.927362816999846, 26.40861495200005],
              [-161.909882816999954, 26.408571952000045],
              [-161.891330816999982, 26.40844295200003],
              [-161.872780816999921, 26.408228952000286],
              [-161.854230816999859, 26.407928952000134],
              [-161.835683816999875, 26.407542952000199],
              [-161.817138816999858, 26.407070952000197],
              [-161.798596816999947, 26.406512952000242],
              [-161.780058816999883, 26.40586995200033],
              [-161.761524816999895, 26.405140952000181],
              [-161.742994816999897, 26.404325952000079],
              [-161.724469816999857, 26.403425952000248],
              [-161.705950816999888, 26.402439952000179],
              [-161.68743781699996, 26.401367952000157],
              [-161.668930816999904, 26.40021095200035],
              [-161.650431816999884, 26.398967952000135],
              [-161.631939816999818, 26.397639952000247],
              [-161.613455816999902, 26.396225952000293],
              [-161.59497981699991, 26.394725952000158],
              [-161.576513816999864, 26.393141952000235],
              [-161.55805681699988, 26.391470952000134],
              [-161.539609816999899, 26.389715952000245],
              [-161.521173816999948, 26.387874952000175],
              [-161.502748816999912, 26.385948952000149],
              [-161.484334816999819, 26.383937952000167],
              [-161.465932816999839, 26.381841952000343],
              [-161.447543816999968, 26.379659952000281],
              [-161.429167816999893, 26.377393952000148],
              [-161.410804816999956, 26.375042952000172],
              [-161.392456815999907, 26.37260695200024],
              [-161.374122815999868, 26.370085952000181],
              [-161.355803815999906, 26.367479952000224],
              [-161.337499815999848, 26.36478895200014],
              [-161.319211815999978, 26.362013952000211],
              [-161.300940815999809, 26.359154952000267],
              [-161.282686815999824, 26.356210952000026],
              [-161.264450815999822, 26.353181952000057],
              [-161.246231815999863, 26.350069952000126],
              [-161.241719815999858, 26.349284952000232],
              [-161.233257815999934, 26.347828952000157],
              [-161.21505781599987, 26.344631952000157],
              [-161.196876815999872, 26.341350952000312],
              [-161.178715815999851, 26.337985952000224],
              [-161.160574815999979, 26.334536952000121],
              [-161.142453815999971, 26.331003952000174],
              [-161.124352815999856, 26.327386952000268],
              [-161.106274815999853, 26.323686952000173],
              [-161.088217815999883, 26.319902952000064],
              [-161.070182815999914, 26.31603595200022],
              [-161.052171815999856, 26.312085952000245],
              [-161.034183815999825, 26.308051952000255],
              [-161.016218815999963, 26.303934952000304],
              [-160.998278815999811, 26.299734952000279],
              [-160.980363815999823, 26.295451952000064],
              [-160.962473815999914, 26.291086952000228],
              [-160.944608815999857, 26.286637952000206],
              [-160.926770815999816, 26.28210795200016],
              [-160.908959815999879, 26.277493952000157],
              [-160.891175815999873, 26.272798952000187],
              [-160.873418815999884, 26.268020952000256],
              [-160.855690815999878, 26.263160952000305],
              [-160.837990815999916, 26.258219952000047],
              [-160.820320815999821, 26.253195952000226],
              [-160.802679815999824, 26.248090952000041],
              [-160.785068815999836, 26.242903952000233],
              [-160.767487815999829, 26.237635952000289],
              [-160.749937815999914, 26.232286952000209],
              [-160.732419815999975, 26.226856952000162],
              [-160.714933815999842, 26.221345952000149],
              [-160.697479815999969, 26.215753952000227],
              [-160.680058815999871, 26.210080952000339],
              [-160.662671815999857, 26.204327952000199],
              [-160.645317815999903, 26.198494952000203],
              [-160.627997815999919, 26.192580952000071],
              [-160.610712815999875, 26.186587952000195],
              [-160.593463815999911, 26.180513952000354],
              [-160.576248815999804, 26.174360952000143],
              [-160.559070815999945, 26.168128952000188],
              [-160.54192981599985, 26.161816952000208],
              [-160.524824815999892, 26.155424952000146],
              [-160.507757815999867, 26.148954952000167],
              [-160.490728815999887, 26.142406952000215],
              [-160.473737815999954, 26.13577895200018],
              [-160.45678581599995, 26.129072952000229],
              [-160.439872815999877, 26.122288952000247],
              [-160.42299981599993, 26.115425952000123],
              [-160.40616681599991, 26.108485952000024],
              [-160.389374815999815, 26.101467952000235],
              [-160.372622815999847, 26.094371952000188],
              [-160.355912815999972, 26.087198952000165],
              [-160.339244815999933, 26.079948952000166],
              [-160.322618814999913, 26.072621952000191],
              [-160.306036814999857, 26.065217952000069],
              [-160.289496814999836, 26.057736952000141],
              [-160.273000814999818, 26.050179952000178],
              [-160.256548814999888, 26.042546952000293],
              [-160.240141814999873, 26.034838952000257],
              [-160.223778814999832, 26.027053952000188],
              [-160.207461814999874, 26.019193952000194],
              [-160.191190814999828, 26.011257952000108],
              [-160.174965814999837, 26.003246952000211],
              [-160.15878781499984, 25.995161952000046],
              [-160.142655814999927, 25.987000952000187],
              [-160.126572814999861, 25.978765952000231],
              [-160.110536814999904, 25.970456952000177],
              [-160.094548814999911, 25.962073952000253],
              [-160.078610814999962, 25.953617952000116],
              [-160.062720814999892, 25.945086952000167],
              [-160.046880814999867, 25.936482952000063],
              [-160.031090814999914, 25.927806952000026],
              [-160.015351814999832, 25.91905695200029],
              [-159.99966281499988, 25.910233952000169],
              [-159.984025814999967, 25.901339952000228],
              [-159.968439814999925, 25.892372952000301],
              [-159.952905814999895, 25.883333952000044],
              [-159.937424814999844, 25.874222952000082],
              [-159.921995814999917, 25.8650409520003],
              [-159.906620814999826, 25.855787952000128],
              [-159.891298814999857, 25.846463952000306],
              [-159.876031814999834, 25.837068952000322],
              [-159.860818814999931, 25.827602952000291],
              [-159.845659814999948, 25.81806795100016],
              [-159.830556814999795, 25.808461951000254],
              [-159.815509814999956, 25.798786951000181],
              [-159.800517814999949, 25.789041951000172],
              [-159.785582814999856, 25.779228951000164],
              [-159.770704814999846, 25.769345951000105],
              [-159.755883814999947, 25.75939395100022],
              [-159.741155314999787, 25.749398951000103],
              [-159.726306814999845, 25.739213951000295],
              [-159.711658814999879, 25.729057951000186],
              [-159.697069814999821, 25.718834951000247],
              [-159.682539814999842, 25.708543951000138],
              [-159.668069814999939, 25.698186951000139],
              [-159.653658814999943, 25.687761951000141],
              [-159.639307814999853, 25.677270951000253],
              [-159.625017814999836, 25.66671395100019],
              [-159.610788814999893, 25.656090951000181],
              [-159.596620814999852, 25.645402951000108],
              [-159.582513814999857, 25.634648951000088],
              [-159.568469814999872, 25.623829951000232],
              [-159.554487814999931, 25.612945951000313],
              [-159.540567814999889, 25.601997951000214],
              [-159.526710814999888, 25.590985951000164],
              [-159.512917814999895, 25.579908951000277],
              [-159.510474814999895, 25.577926951000222],
              [-159.506223814999885, 25.578048951000198],
              [-159.487807814999911, 25.578488951000168],
              [-159.469389814999829, 25.578843951000238],
              [-159.450968814999868, 25.579112951000297],
              [-159.432547814999822, 25.579295951000177],
              [-159.414125814999892, 25.579392951000216],
              [-159.402388814999881, 25.579410951000284],
              [-159.383966814999837, 25.579367951000279],
              [-159.365543814999825, 25.579238951000264],
              [-159.347122814999949, 25.579024951000292],
              [-159.328703814999898, 25.57872495100014],
              [-159.310285814999816, 25.578338951000205],
              [-159.291870814999839, 25.577867951000144],
              [-159.273458814999827, 25.577310951000243],
              [-159.255049813999875, 25.576667951000161],
              [-159.2366458139999, 25.575939951000123],
              [-159.218244813999917, 25.575125951000302],
              [-159.199849813999975, 25.574225951000244],
              [-159.181459813999822, 25.573240951000287],
              [-159.163076813999908, 25.572169951000149],
              [-159.144698813999867, 25.571013951000225],
              [-159.126328813999862, 25.569771951000121],
              [-159.107965813999925, 25.568444951000288],
              [-159.089610813999826, 25.567031951000274],
              [-159.071264813999846, 25.565533951000305],
              [-159.052926813999846, 25.563950951000265],
              [-159.03459881399985, 25.562281951000159],
              [-159.01628081399997, 25.560527951000154],
              [-158.997972813999951, 25.558688951000249],
              [-158.979676813999845, 25.556764951000218],
              [-158.961390813999884, 25.554755951000288],
              [-158.943117813999919, 25.552660951000291],
              [-158.924856813999952, 25.550481951000108],
              [-158.906608813999839, 25.548216951000143],
              [-158.888373813999891, 25.545867951000275],
              [-158.87015281399988, 25.543433951000168],
              [-158.851945813999862, 25.540914951000275],
              [-158.833754813999946, 25.538311951000196],
              [-158.815578813999906, 25.535623951000161],
              [-158.797417813999886, 25.532850951000057],
              [-158.779273813999936, 25.529993951000108],
              [-158.761146813999858, 25.527052951000144],
              [-158.743036813999879, 25.524026951000053],
              [-158.741613813999919, 25.523785951000207],
              [-158.724781813999897, 25.520889951000186],
              [-158.706707813999884, 25.517695951000064],
              [-158.688652813999823, 25.514416951000214],
              [-158.670616813999828, 25.511054951000176],
              [-158.652600813999896, 25.507608951000179],
              [-158.634604813999857, 25.504079951000222],
              [-158.616629813999936, 25.500465951000137],
              [-158.598675813999904, 25.496768951000149],
              [-158.580743813999817, 25.492988951000029],
              [-158.562833813999845, 25.489124951000065],
              [-158.544945813999846, 25.485177951000196],
              [-158.527081813999899, 25.481146951000255],
              [-158.509240813999867, 25.477033951000294],
              [-158.491423813999887, 25.472837951000201],
              [-158.473631813999845, 25.468558951000205],
              [-158.455864813999909, 25.464196951000247],
              [-158.438122813999911, 25.459751951000158],
              [-158.420407813999816, 25.455224951000218],
              [-158.402717813999857, 25.450615951000259],
              [-158.385055813999912, 25.445923951000168],
              [-158.367420813999985, 25.441150951000338],
              [-158.349813813999873, 25.43629495100015],
              [-158.332234813999861, 25.431357951000052],
              [-158.314684813999946, 25.426337951000164],
              [-158.297164813999842, 25.42123795100008],
              [-158.279673814, 25.416054951000262],
              [-158.26221281399998, 25.410791951000192],
              [-158.244782813999848, 25.405446951000044],
              [-158.22738481399989, 25.400021951000269],
              [-158.210016813999943, 25.394514951000247],
              [-158.192681813999855, 25.388927951000198],
              [-158.175379812999864, 25.383259951000184],
              [-158.158109812999925, 25.377511951000088],
              [-158.140873812999956, 25.371682951000082],
              [-158.123670812999876, 25.365773951000222],
              [-158.10650281299985, 25.359785951000163],
              [-158.089369812999877, 25.353716951000024],
              [-158.07227281299987, 25.347568951000085],
              [-158.055209812999834, 25.341341951000175],
              [-158.038183812999847, 25.335034951000239],
              [-158.02119481299988, 25.328648951000162],
              [-158.004242812999877, 25.322183951000284],
              [-157.987327812999837, 25.315640951000148],
              [-157.976170812999953, 25.311272951000035],
              [-157.969370812999983, 25.308705951000093],
              [-157.952420812999975, 25.302240951000044],
              [-157.943280812999916, 25.298715951000247],
              [-157.93536681299986, 25.295642951000161],
              [-157.91849281299983, 25.289019951000228],
              [-157.901657812999844, 25.282319951000261],
              [-157.88486081299996, 25.275540951000153],
              [-157.868102812999837, 25.268683951000185],
              [-157.851385812999837, 25.261749951000184],
              [-157.834707812999937, 25.254736951000098],
              [-157.818070812999878, 25.247646951000206],
              [-157.801475812999939, 25.240479951000168],
              [-157.784920813000014, 25.233235951000324],
              [-157.768408812999894, 25.225914951000107],
              [-157.751938812999896, 25.21851695100014],
              [-157.735511812999817, 25.211041951000198],
              [-157.719127812999915, 25.203491951000046],
              [-157.702786812999847, 25.195864951000146],
              [-157.686490812999835, 25.188161951000154],
              [-157.670238812999941, 25.180383951000181],
              [-157.65403281299993, 25.172528951000231],
              [-157.637870812999921, 25.164599951000184],
              [-157.621755812999879, 25.156595951000156],
              [-157.605685812999951, 25.148515951000036],
              [-157.589663812999987, 25.140361951000216],
              [-157.573687812999907, 25.132133951000299],
              [-157.565196812999915, 25.127720951000185],
              [-157.553735812999832, 25.121735951000062],
              [-157.537855812999879, 25.113359951000177],
              [-157.533989812999835, 25.111305951000247],
              [-157.518958812999898, 25.103279951000161],
              [-157.503175812999899, 25.094755951000081],
              [-157.487441812999947, 25.086158951000186],
              [-157.471757812999869, 25.077488951000134],
              [-157.456123812999948, 25.068745951000267],
              [-157.440539812999873, 25.059929951000186],
              [-157.425005812999871, 25.051041951000173],
              [-157.409523812999822, 25.042081951000114],
              [-157.394093812999984, 25.033049951000294],
              [-157.378714812999846, 25.023945951000201],
              [-157.363388813, 25.014770951000287],
              [-157.350394812999809, 25.006904951000138],
              [-157.344101812999867, 25.005909951000035],
              [-157.326051812999879, 25.002969951000125],
              [-157.308018812999876, 24.999945951000029],
              [-157.290003812999856, 24.996837951000089],
              [-157.272006812999791, 24.993644951000249],
              [-157.254028812999906, 24.990368951000278],
              [-157.236069812999887, 24.987008951000064],
              [-157.218129812999848, 24.983564951000233],
              [-157.200210812999956, 24.980036951000159],
              [-157.182311812999842, 24.976425951000181],
              [-157.164433812999874, 24.972730951000358],
              [-157.146577812999851, 24.968951951000292],
              [-157.128743812999886, 24.965089951000152],
              [-157.110931812999866, 24.961144951000222],
              [-157.093142811999883, 24.957116951000216],
              [-157.075377811999942, 24.953005951000023],
              [-157.057636811999856, 24.948811951000152],
              [-157.039919811999965, 24.944534951000094],
              [-157.022227811999841, 24.940175951000185],
              [-157.004560811999823, 24.935733951000202],
              [-156.986919811999854, 24.931208951000258],
              [-156.969305811999874, 24.926601951000293],
              [-156.951717811999828, 24.921912951000081],
              [-156.934156811999884, 24.917141951000247],
              [-156.916623811999841, 24.912288951000164],
              [-156.899119811999839, 24.907353951000175],
              [-156.881643811999908, 24.902337951000334],
              [-156.864196811999875, 24.897239951000188],
              [-156.846778811999883, 24.892059951000249],
              [-156.829391811999841, 24.886798951000173],
              [-156.812034811999894, 24.881457951000186],
              [-156.794708811999868, 24.876034951000179],
              [-156.777413811999821, 24.870530951000262],
              [-156.760151811999918, 24.864946951000263],
              [-156.742920811999824, 24.859281951000128],
              [-156.725723811999899, 24.853535951000254],
              [-156.708559811999947, 24.847710951000238],
              [-156.691428811999884, 24.841804951000029],
              [-156.674332811999847, 24.835819951000076],
              [-156.657270811999894, 24.829754951000268],
              [-156.640243811999909, 24.823609951000037],
              [-156.623252811999862, 24.817384951000122],
              [-156.606297811999895, 24.811081951000176],
              [-156.589378811999865, 24.804698951000148],
              [-156.572496811999969, 24.79823795100026],
              [-156.555651811999837, 24.79169695100029],
              [-156.538844811999837, 24.785078951000173],
              [-156.522075811999883, 24.778380951000031],
              [-156.50534581199986, 24.771605951000083],
              [-156.488654811999965, 24.764752951000276],
              [-156.472003811999826, 24.757820951000099],
              [-156.455391811999874, 24.750811951000173],
              [-156.438820811999875, 24.743725950000169],
              [-156.422290811999886, 24.73656195000018],
              [-156.405801811999908, 24.729320950000215],
              [-156.389353811999854, 24.722003950000158],
              [-156.372948811999805, 24.714608950000297],
              [-156.356585811999963, 24.707137950000288],
              [-156.34026681199984, 24.699590950000243],
              [-156.323989811999837, 24.691967950000105],
              [-156.307757811999892, 24.684268950000273],
              [-156.291569811999949, 24.676493950000179],
              [-156.275425811999838, 24.668642950000219],
              [-156.259327811999867, 24.660717950000162],
              [-156.243274811999839, 24.652716950000183],
              [-156.227267811999951, 24.644640950000053],
              [-156.211307811999859, 24.636490950000223],
              [-156.19539381199985, 24.628266950000295],
              [-156.179527811999918, 24.619967950000159],
              [-156.163708811999953, 24.611594950000153],
              [-156.147937811999867, 24.603148950000161],
              [-156.132215811999856, 24.594628950000072],
              [-156.116541811999895, 24.586035950000166],
              [-156.100917811999835, 24.577368950000164],
              [-156.08534281199988, 24.568629950000059],
              [-156.069818811999909, 24.559817950000138],
              [-156.0543438119999, 24.550933950000172],
              [-156.03892081199993, 24.541977950000046],
              [-156.023548810999927, 24.532949950000216],
              [-156.008228810999952, 24.523849950000283],
              [-155.992960810999818, 24.514678950000132],
              [-155.977744810999837, 24.505435950000276],
              [-155.962581810999893, 24.496122950000313],
              [-155.947471810999787, 24.486738950000301],
              [-155.932415810999913, 24.477284950000183],
              [-155.917413810999875, 24.467759950000072],
              [-155.902465810999843, 24.458165950000136],
              [-155.887572810999956, 24.448501950000264],
              [-155.872734810999873, 24.438767950000056],
              [-155.857952810999933, 24.428965950000133],
              [-155.843226810999795, 24.419093950000274],
              [-155.829391810999965, 24.409719950000238],
              [-155.820116810999849, 24.407472950000283],
              [-155.802499810999876, 24.403115950000029],
              [-155.784907810999954, 24.398675950000211],
              [-155.767341810999852, 24.394153950000145],
              [-155.749800810999972, 24.38954995000023],
              [-155.732287810999907, 24.384862950000183],
              [-155.714800810999861, 24.380094950000228],
              [-155.697341810999831, 24.37524395000014],
              [-155.6799108109999, 24.370311950000087],
              [-155.662507810999983, 24.365297950000183],
              [-155.645134810999963, 24.360202950000144],
              [-155.627789810999843, 24.355025950000083],
              [-155.61047581099993, 24.349767950000057],
              [-155.593191810999883, 24.344428950000292],
              [-155.575937810999847, 24.339008950000164],
              [-155.558715810999985, 24.33350895000018],
              [-155.541525810999843, 24.327926950000176],
              [-155.524367810999848, 24.322264950000147],
              [-155.50724181099983, 24.316522950000035],
              [-155.49014881099987, 24.310700950000069],
              [-155.473089810999824, 24.304797950000193],
              [-155.456064810999891, 24.298815950000289],
              [-155.439073810999872, 24.29275395000019],
              [-155.422117810999879, 24.286611950000179],
              [-155.405196810999968, 24.280390950000083],
              [-155.388311810999909, 24.274090950000186],
              [-155.371463810999984, 24.267711950000148],
              [-155.354651810999826, 24.261252950000028],
              [-155.337876810999916, 24.254716950000159],
              [-155.32113881099994, 24.248100950000037],
              [-155.304439810999924, 24.241406950000226],
              [-155.287778810999924, 24.234635950000268],
              [-155.271155810999858, 24.227785950000339],
              [-155.254572810999832, 24.220857950000152],
              [-155.238029810999848, 24.213851950000276],
              [-155.221526810999848, 24.206769950000137],
              [-155.205063810999917, 24.199608950000254],
              [-155.18864281099988, 24.192371950000052],
              [-155.172262810999854, 24.1850579500001],
              [-155.155924810999949, 24.177667950000284],
              [-155.139628810999852, 24.170200950000265],
              [-155.123375810999875, 24.162656950000269],
              [-155.120204810999866, 24.161173950000148],
              [-155.109095810999889, 24.156072950000123],
              [-155.092844810999878, 24.148529950000238],
              [-155.076636810999872, 24.140910950000091],
              [-155.060471810999815, 24.133214950000138],
              [-155.044351810999984, 24.125443950000033],
              [-155.028274810999818, 24.117597950000288],
              [-155.01224381099982, 24.10967595000011],
              [-154.996257810999879, 24.101679950000232],
              [-154.980316810999881, 24.093607950000262],
              [-154.964422810999821, 24.085461950000195],
              [-154.948574809999855, 24.077241950000257],
              [-154.932773809999873, 24.068946950000111],
              [-154.917020809999968, 24.060577950000265],
              [-154.901314809999946, 24.052135950000036],
              [-154.885656809999887, 24.043619950000107],
              [-154.870047809999818, 24.035030950000191],
              [-154.854487809999881, 24.026368950000062],
              [-154.838976809999821, 24.017633950000118],
              [-154.835564809999852, 24.015690950000305],
              [-154.822148809999845, 24.010379950000186],
              [-154.805478809999926, 24.003686950000258],
              [-154.788847809999822, 23.996916950000184],
              [-154.772255809999848, 23.99006895000025],
              [-154.75570181, 23.983142950000229],
              [-154.739188809999831, 23.976138950000177],
              [-154.722714809999843, 23.969056950000208],
              [-154.713964809999908, 23.965257950000137],
              [-154.704706809999863, 23.961212950000288],
              [-154.688314809999923, 23.953977950000308],
              [-154.671964809999821, 23.94666495000024],
              [-154.655655809999899, 23.939275950000308],
              [-154.639388809999815, 23.931810950000283],
              [-154.623164809999821, 23.924268950000283],
              [-154.606982809999948, 23.916650950000189],
              [-154.590845809999877, 23.908957950000172],
              [-154.588096809999882, 23.907636950000096],
              [-154.586998809999983, 23.907110950000288],
              [-154.570905809999914, 23.899341950000178],
              [-154.554856809999848, 23.891496950000146],
              [-154.538851809999841, 23.883576950000304],
              [-154.522892809999945, 23.875581950000139],
              [-154.506978809999936, 23.867511950000164],
              [-154.501068809999879, 23.864490950000118],
              [-154.490721809999883, 23.859168950000139],
              [-154.474900809999838, 23.850949950000086],
              [-154.459125809999875, 23.842656950000332],
              [-154.44339881, 23.834289950000084],
              [-154.427718809999874, 23.825848950000136],
              [-154.412469809999834, 23.817544950000183],
              [-154.40447480999984, 23.813177950000124],
              [-154.388891809999876, 23.804589950000093],
              [-154.373357809999874, 23.795929950000186],
              [-154.357872809999918, 23.787196950000236],
              [-154.342437809999836, 23.778390950000073],
              [-154.327052809999913, 23.769512950000035],
              [-154.311717809999834, 23.760562950000065],
              [-154.296434809999909, 23.751540950000219],
              [-154.281201809999942, 23.742446950000215],
              [-154.266020809999844, 23.733281950000048],
              [-154.250891809999843, 23.724045950000232],
              [-154.235815809999821, 23.714738950000026],
              [-154.220791809999895, 23.705361950000281],
              [-154.205821809999946, 23.695912950000036],
              [-154.190904809999921, 23.686394950000192],
              [-154.187983809999906, 23.684518950000211],
              [-154.185929809999863, 23.683393950000209],
              [-154.170361809999889, 23.674807950000172],
              [-154.154842809999963, 23.666147949000106],
              [-154.139372809999827, 23.657415949000267],
              [-154.123951809999852, 23.648610949000215],
              [-154.108580809999836, 23.63973394900006],
              [-154.093260809999862, 23.630784949000201],
              [-154.077991809999929, 23.621763949000183],
              [-154.062772809999871, 23.61267194900023],
              [-154.047606809999849, 23.603507949000118],
              [-154.032491809999868, 23.594272949000242],
              [-154.017428809999842, 23.584966949000091],
              [-154.00241980999985, 23.575589949000175],
              [-153.987462809999982, 23.566142949000096],
              [-153.972559809999865, 23.556625949000306],
              [-153.957710809999867, 23.547038949000239],
              [-153.942915809999818, 23.537381949000178],
              [-153.928174809999888, 23.527655949000177],
              [-153.913489809999959, 23.517860949000294],
              [-153.898859809999863, 23.507996949000187],
              [-153.884285808999891, 23.498063949000255],
              [-153.881553808999882, 23.496189949000268],
              [-153.87769280899991, 23.493548949000228],
              [-153.863174808999844, 23.483547949000183],
              [-153.848713808999889, 23.473478949000196],
              [-153.834309808999961, 23.463341949000039],
              [-153.819962808999833, 23.453137949000052],
              [-153.805672808999816, 23.44286594900035],
              [-153.791441808999878, 23.432527949000075],
              [-153.787811808999834, 23.429872949000355],
              [-153.77648680899992, 23.421549949000223],
              [-153.762372808999885, 23.411078949000171],
              [-153.748317808999872, 23.40054094900006],
              [-153.734321808999852, 23.38993694900023],
              [-153.72038480899991, 23.379266949000225],
              [-153.706508808999871, 23.368532949000269],
              [-153.692693808999877, 23.357732949000194],
              [-153.678938808999902, 23.346867949000227],
              [-153.665244808999915, 23.335938949000138],
              [-153.651612808999886, 23.324945949000039],
              [-153.638041808999844, 23.313888949000159],
              [-153.624533808999871, 23.302767949000156],
              [-153.611087808999883, 23.291583949000142],
              [-153.59770480899985, 23.280336949000173],
              [-153.584385808999883, 23.269026949000363],
              [-153.571129808999899, 23.257654949000198],
              [-153.55793680899987, 23.24622094900019],
              [-153.544808808999818, 23.234724949000281],
              [-153.531745808999887, 23.223166949000188],
              [-153.51874680899985, 23.211547949000192],
              [-153.505812808999934, 23.199868949000177],
              [-153.49294480899988, 23.188127949000034],
              [-153.480142808999886, 23.176327949000154],
              [-153.46740680899984, 23.16446694900003],
              [-153.454736808999854, 23.152546949000168],
              [-153.44213380899987, 23.140566949000174],
              [-153.429597808999944, 23.128527949000045],
              [-153.417128808999877, 23.116430949000119],
              [-153.404727808999922, 23.104274949000285],
              [-153.392394808999825, 23.092060949000256],
              [-153.380130808999837, 23.079789949000144],
              [-153.367934808999877, 23.067459949000295],
              [-153.355806808999972, 23.055073949000246],
              [-153.343748808999891, 23.042630949000284],
              [-153.331760808999917, 23.030130949000238],
              [-153.319841808999882, 23.017575949000047],
              [-153.309352808999847, 23.006417949000195],
              [-153.30403880899982, 23.000803949000215],
              [-153.29219180899986, 22.988191949000111],
              [-153.28041480899995, 22.975524949000089],
              [-153.268707808999864, 22.96280294900015],
              [-153.257071808999882, 22.950026949000176],
              [-153.249136808999879, 22.941235949000202],
              [-153.245067808999835, 22.936705949000213],
              [-153.233574808999862, 22.923819949000176],
              [-153.222153808999821, 22.910880949000216],
              [-153.210804808999882, 22.897887949000221],
              [-153.199527808999932, 22.884841949000304],
              [-153.188323808999911, 22.871742949000236],
              [-153.177191808999879, 22.858591949000299],
              [-153.166133808999831, 22.845388949000153],
              [-153.155148808999826, 22.832133949000138],
              [-153.144237808999861, 22.81882694900014],
              [-153.133400808999824, 22.805469949000212],
              [-153.122637808999855, 22.792060949000245],
              [-153.111948808999898, 22.778602949000174],
              [-153.101334808999837, 22.765093949000288],
              [-153.090795808999843, 22.751535949000242],
              [-153.080331808999944, 22.737927949000152],
              [-153.069942808999855, 22.724270949000299],
              [-153.059629808999915, 22.71056594900017],
              [-153.049393808999838, 22.696811949000107],
              [-153.039232808999969, 22.683009949000166],
              [-153.02914780899988, 22.669160949000229],
              [-153.01914080899985, 22.655264949000298],
              [-153.009209808999827, 22.641321949000201],
              [-152.999355808999837, 22.627331949000165],
              [-152.989579808999935, 22.613296949000073],
              [-152.979880808999894, 22.599214949000043],
              [-152.970259808999828, 22.585088948000191],
              [-152.967956808999901, 22.581668948000186],
              [-152.958578808999874, 22.574379948000171],
              [-152.944836808999895, 22.563585948000025],
              [-152.931154808999821, 22.552728948000208],
              [-152.917533808999821, 22.541805948000274],
              [-152.903972808999896, 22.530819948000044],
              [-152.890473808999872, 22.519768948000149],
              [-152.881216808999824, 22.51212394800018],
              [-152.872039808999915, 22.504827948000298],
              [-152.858422808999876, 22.493904948000136],
              [-152.844866808999853, 22.482918948000304],
              [-152.831372808999873, 22.471868948000292],
              [-152.81793980899991, 22.460755948000269],
              [-152.804568807999885, 22.449578948000237],
              [-152.791259807999865, 22.438338948000137],
              [-152.778013807999884, 22.427035948000196],
              [-152.764830807999914, 22.415670948000241],
              [-152.751710807999871, 22.404242948000046],
              [-152.738654807999836, 22.392753948000177],
              [-152.72566280800001, 22.381203948000177],
              [-152.719029807999817, 22.375259948000064],
              [-152.715733807999953, 22.372377948000178],
              [-152.702679807999885, 22.360888948000138],
              [-152.689690807999852, 22.349338948000138],
              [-152.676765807999885, 22.337726948000181],
              [-152.663904807999899, 22.326054948000262],
              [-152.651108807999861, 22.314321948000043],
              [-152.638378807999857, 22.302527948000147],
              [-152.62571380799983, 22.290674948000174],
              [-152.613114807999835, 22.278761948000181],
              [-152.600582807999984, 22.266788948000169],
              [-152.588115807999884, 22.25475794800019],
              [-152.575716807999839, 22.242667948000133],
              [-152.563384807999824, 22.230518948000167],
              [-152.551119807999839, 22.218311948000178],
              [-152.53892280799991, 22.206047948000332],
              [-152.53738180799985, 22.204489948000173],
              [-152.525434807999886, 22.192474948000097],
              [-152.513306807999896, 22.180152948000227],
              [-152.50124680799982, 22.167773948000217],
              [-152.489255807999854, 22.155337948000295],
              [-152.477332807999943, 22.142845948000172],
              [-152.465479807999856, 22.130297948000248],
              [-152.453695807999878, 22.117692948000183],
              [-152.441982807999835, 22.10503294800003],
              [-152.430338807999902, 22.092317948000129],
              [-152.418764807999906, 22.079548948000024],
              [-152.407261807999816, 22.06672394800006],
              [-152.395829807999831, 22.053845948000173],
              [-152.384468807999866, 22.040912948000198],
              [-152.373179807999833, 22.027927948000126],
              [-152.361961807999876, 22.014888948000248],
              [-152.351419807999918, 22.002509948000068],
              [-152.348454807999815, 21.999119948000271],
              [-152.337167807999833, 21.986134948000199],
              [-152.325952807999869, 21.97309594800015],
              [-152.314809807999922, 21.960003948000292],
              [-152.303738807999878, 21.946859948000053],
              [-152.292740807999934, 21.933663948000174],
              [-152.281815807999891, 21.920415948000198],
              [-152.27096280799995, 21.907116948000294],
              [-152.260183807999823, 21.89376594800018],
              [-152.249477807999966, 21.880364948000135],
              [-152.238845807999922, 21.86691294800022],
              [-152.22828880799986, 21.853410948000203],
              [-152.217804807999897, 21.839859948000026],
              [-152.207395807999916, 21.826258948000202],
              [-152.19706180799983, 21.812608948000161],
              [-152.18680280799984, 21.798910948000298],
              [-152.176618807999915, 21.785163948000275],
              [-152.16651080799997, 21.771368948000145],
              [-152.156478807999832, 21.757525948000193],
              [-152.14652180799996, 21.74363694800013],
              [-152.139541807999819, 21.733807948000276],
              [-152.128785807999833, 21.722153948000027],
              [-152.117168807999832, 21.709442948000287],
              [-152.105620807999827, 21.696675948000177],
              [-152.09414380799987, 21.683853948000092],
              [-152.082737807999905, 21.670978948000311],
              [-152.071402807999874, 21.658049948000269],
              [-152.060137807999951, 21.645066948000192],
              [-152.048945807999843, 21.632030948000022],
              [-152.03782480799984, 21.61894194800027],
              [-152.02677580799994, 21.605800948000081],
              [-152.015798807999857, 21.592606948000196],
              [-152.004894807999875, 21.579361948000098],
              [-151.994063807999879, 21.566064948000189],
              [-151.983304807999815, 21.552716948000125],
              [-151.972619807999905, 21.539317948000303],
              [-151.962008807999922, 21.525868948000038],
              [-151.951470807999925, 21.512369947000195],
              [-151.94100780799991, 21.498820947000183],
              [-151.930618807999821, 21.485222947000068],
              [-151.920303807999886, 21.471574947000249],
              [-151.910063807999848, 21.457878947000154],
              [-151.899899807999901, 21.44413494700018],
              [-151.889809807999853, 21.430342947000156],
              [-151.879795807999955, 21.416502947000083],
              [-151.869857807999864, 21.402615947000186],
              [-151.859995807999866, 21.38868194700018],
              [-151.8502108079999, 21.374700947000122],
              [-151.840500807999859, 21.360674947000291],
              [-151.830868807999849, 21.346601947000295],
              [-151.821312807999846, 21.332483947000298],
              [-151.811834807999816, 21.318320947000302],
              [-151.80243280799985, 21.304112947000306],
              [-151.793109807999826, 21.28986094700025],
              [-151.783863807999893, 21.275564947000078],
              [-151.774696807999817, 21.261225947000298],
              [-151.765606807999831, 21.246842947000175],
              [-151.756595807999872, 21.232416947000104],
              [-151.747663807999885, 21.217948947000139],
              [-151.738809806999967, 21.203437947000111],
              [-151.730035806999837, 21.188885947000074],
              [-151.72134080699982, 21.174292947000254],
              [-151.712724806999859, 21.159658947000196],
              [-151.704188806999866, 21.144983947000298],
              [-151.695732806999843, 21.130267947000164],
              [-151.687356806999844, 21.115512947000241],
              [-151.679061806999897, 21.100718947000075],
              [-151.670845806999836, 21.085884947000181],
              [-151.662710806999883, 21.071011947000045],
              [-151.654656806999895, 21.056101947000286],
              [-151.646684806999957, 21.041152947000057],
              [-151.638792806999874, 21.026166947000206],
              [-151.630981806999898, 21.011142947000224],
              [-151.623252806999886, 20.996082947000275],
              [-151.615605806999923, 20.980985947000249],
              [-151.608040806999981, 20.965853947000198],
              [-151.60055680699989, 20.950684947000298],
              [-151.593155806999931, 20.935481947000028],
              [-151.58583780699982, 20.92024294700019],
              [-151.578600806999845, 20.904969947000211],
              [-151.571447806999885, 20.889662947000261],
              [-151.56437680699986, 20.874322947000223],
              [-151.557388806999967, 20.858948947000215],
              [-151.550484806999833, 20.843541947000347],
              [-151.54366280699989, 20.828101947000164],
              [-151.536924806999906, 20.812630947000173],
              [-151.530270806999937, 20.797127947000149],
              [-151.523700806999983, 20.781592947000149],
              [-151.517213806999877, 20.766027947000168],
              [-151.510810806999899, 20.750431947000266],
              [-151.504492806999821, 20.734805947000154],
              [-151.498257806999874, 20.71914994700029],
              [-151.492108806999852, 20.703464947000157],
              [-151.486042806999961, 20.687750947000268],
              [-151.480062806999882, 20.672007947000282],
              [-151.474166806999932, 20.656236947000309],
              [-151.468355806999881, 20.640438947000177],
              [-151.462629806999871, 20.624612947000287],
              [-151.456988806999874, 20.608759947000067],
              [-151.451433806999972, 20.592880947000026],
              [-151.445963806999913, 20.576975947000165],
              [-151.440578806999866, 20.561044947000255],
              [-151.43527980699983, 20.545087947000354],
              [-151.430066806999889, 20.529106947000173],
              [-151.424938806999876, 20.513100947000112],
              [-151.41989780699987, 20.497070947000225],
              [-151.414941806999877, 20.481017947000169],
              [-151.410072806999892, 20.464940947000059],
              [-151.405289806999832, 20.448840946000132],
              [-151.400592806999867, 20.43271894600025],
              [-151.395982806999911, 20.416574946000139],
              [-151.391458806999964, 20.400408946000141],
              [-151.387020806999857, 20.384221946000196],
              [-151.382670806999897, 20.368013946000303],
              [-151.378406806999891, 20.351785946000348],
              [-151.374228806999866, 20.335537946000329],
              [-151.370138806999847, 20.319269946000247],
              [-151.366135806999949, 20.302982946000157],
              [-151.362219806999946, 20.286676946000171],
              [-151.358390806999836, 20.27035294600006],
              [-151.354648806999847, 20.254009946000167],
              [-151.350993806999867, 20.237650946000031],
              [-151.347426806999863, 20.221273946000167],
              [-151.343946806999867, 20.20488094600006],
              [-151.340553806999907, 20.188470946000166],
              [-151.337248806999895, 20.172044946000142],
              [-151.334031806999832, 20.155603946000156],
              [-151.330901806999833, 20.139147946000151],
              [-151.327859806999953, 20.122676946000354],
              [-151.324905806999965, 20.106191946000251],
              [-151.322038806999984, 20.089692946000241],
              [-151.319259806999895, 20.073180946000036],
              [-151.316568806999925, 20.056655946000205],
              [-151.313965806999846, 20.04011794600018],
              [-151.311449806999946, 20.023567946000185],
              [-151.309022806999849, 20.007006946000047],
              [-151.306683806999871, 19.990433946000167],
              [-151.304431806999872, 19.97384994600003],
              [-151.302268806999876, 19.957255946000146],
              [-151.300193806999857, 19.940650946000289],
              [-151.298206806999957, 19.924036946000172],
              [-151.296307806999863, 19.90741394600019],
              [-151.294496806999945, 19.890781946000232],
              [-151.292773806999833, 19.874141946000293],
              [-151.291139806999837, 19.857492946000093],
              [-151.289592806999821, 19.840836946000081],
              [-151.288134806999977, 19.824173946000201],
              [-151.286764806999855, 19.807504946000165],
              [-151.285482806999823, 19.790827946000149],
              [-151.28428980699988, 19.774145946000147],
              [-151.283184806999856, 19.757458946000213],
              [-151.282166806999925, 19.740766946000235],
              [-151.28123880699988, 19.724068946000273],
              [-151.280397806999815, 19.707367946000261],
              [-151.279644806999869, 19.690662946000259],
              [-151.278980807000011, 19.673953946000097],
              [-151.278404806999873, 19.657242946000167],
              [-151.277916806999883, 19.640528946000302],
              [-151.277516806999841, 19.623812946000271],
              [-151.277205806999859, 19.607094946000075],
              [-151.276981806999885, 19.590375946000165],
              [-151.276846806999828, 19.57365494600009],
              [-151.276798806999949, 19.556934946000297],
              [-151.276839806999959, 19.540213946000222],
              [-151.276968806999975, 19.523493946000031],
              [-151.277184806999827, 19.506774946000121],
              [-151.277489806999824, 19.490055946000041],
              [-151.277882806999827, 19.473339946000237],
              [-151.278362806999837, 19.45662494600009],
              [-151.278930806999853, 19.439912946000049],
              [-151.279586806999959, 19.423203946000285],
              [-151.280330806999871, 19.406497946000172],
              [-151.281162806999873, 19.389795946000277],
              [-151.282081806999884, 19.37309794500004],
              [-151.283088806999899, 19.356403945000068],
              [-151.284182806999837, 19.339714945000139],
              [-151.285364806999837, 19.323031945000025],
              [-151.286634806999842, 19.306353945000126],
              [-151.287990806999858, 19.289682945000322],
              [-151.289434806999878, 19.273017945000049],
              [-151.290966806999819, 19.256359945000042],
              [-151.292584806999827, 19.239709945000186],
              [-151.294290806999953, 19.223066945000141],
              [-151.296083806999974, 19.206432945000188],
              [-151.297963806999832, 19.189807945000211],
              [-151.299930806999868, 19.173190945000215],
              [-151.301983806999914, 19.156583945000136],
              [-151.304124806999965, 19.139986945000032],
              [-151.306351806999828, 19.123399945000244],
              [-151.308665806999983, 19.106823945000258],
              [-151.311065806999864, 19.090258945000301],
              [-151.313552806999866, 19.073704945000145],
              [-151.31612580699985, 19.057163945000241],
              [-151.318784806999844, 19.040633945000138],
              [-151.321530806999959, 19.024117945000171],
              [-151.324361806999889, 19.007614945000171],
              [-151.327279806999826, 18.991124945000252],
              [-151.330283806999859, 18.974648945000183],
              [-151.333372806999847, 18.958187945000304],
              [-151.33423280699995, 18.953687945000297],
              [-151.337366806999825, 18.937457945000176],
              [-151.340626806999893, 18.921025945000167],
              [-151.343972806999886, 18.904610945000229],
              [-151.347404806999833, 18.888210945000253],
              [-151.347666306999884, 18.886971945000198],
              [-151.351135806999878, 18.87038094500025],
              [-151.35465180699984, 18.853996945000176],
              [-151.3582528069999, 18.837630945000342],
              [-151.361939806999857, 18.821280945000183],
              [-151.365710806999857, 18.804948945000149],
              [-151.36956680699987, 18.788635945000294],
              [-151.373506806999899, 18.772339945000169],
              [-151.377532806999852, 18.756063945000164],
              [-151.381641806999852, 18.739806945000112],
              [-151.38583580699995, 18.723568945000238],
              [-151.390114806999861, 18.707350945000201],
              [-151.394476806999819, 18.69115394500011],
              [-151.398922806999821, 18.674977945000023],
              [-151.403452806999894, 18.65882194500017],
              [-151.408066806999926, 18.642688945000259],
              [-151.412763806999891, 18.626576945000295],
              [-151.417544806999871, 18.610487945000273],
              [-151.422408806999982, 18.594421945000022],
              [-151.427355806999941, 18.578378945000281],
              [-151.432385806999918, 18.562358945000142],
              [-151.437498806999855, 18.546362945000169],
              [-151.442694806999981, 18.53039194500036],
              [-151.447972806999957, 18.514445945000261],
              [-151.453332806999896, 18.498523945000215],
              [-151.458775806999853, 18.482628945000272],
              [-151.464300806999944, 18.466758945000265],
              [-151.469907806999885, 18.450914945000079],
              [-151.475595806999848, 18.435097945000223],
              [-151.481365806999861, 18.419308945000182],
              [-151.487217806999837, 18.403545945000303],
              [-151.493150806999836, 18.38781194500018],
              [-151.499163806999974, 18.372105945000044],
              [-151.505258806999876, 18.356428945000232],
              [-151.511434806999915, 18.340779945000179],
              [-151.517690806999923, 18.325160945000164],
              [-151.524027806999953, 18.30957194500013],
              [-151.530444806999839, 18.294012944000201],
              [-151.536941806999977, 18.278483944000186],
              [-151.54351880699997, 18.262985944000206],
              [-151.550174806999934, 18.24751994400026],
              [-151.556910806999838, 18.232084944000178],
              [-151.563726806999824, 18.216682944000354],
              [-151.570620806999926, 18.201312944000108],
              [-151.577594806999826, 18.185974944000066],
              [-151.584646806999871, 18.170670944000165],
              [-151.591777806999858, 18.15539994400018],
              [-151.598987806999929, 18.140163944000051],
              [-151.606274806999863, 18.124960944000179],
              [-151.613640806999854, 18.109793944000046],
              [-151.621083806999849, 18.094660944000282],
              [-151.628604806999959, 18.079563944000313],
              [-151.636203806999873, 18.064501944000142],
              [-151.640463806999918, 18.05613794400017],
              [-151.647907806999882, 18.041382944000247],
              [-151.655581806999891, 18.02635694400027],
              [-151.663332806999932, 18.011367944000142],
              [-151.671160806999865, 17.996415944000262],
              [-151.679065806999887, 17.981500944000231],
              [-151.687046806999973, 17.966622944000221],
              [-151.695103806999839, 17.951783944000056],
              [-151.703236806999882, 17.936982944000249],
              [-151.711445806999819, 17.922219944000176],
              [-151.719729806999936, 17.907496944000172],
              [-151.728089806999833, 17.89281294400007],
              [-151.736523806999827, 17.878167944000154],
              [-151.745033807999988, 17.863563944000305],
              [-151.753617807999859, 17.848999944000184],
              [-151.762276807999825, 17.834476944000244],
              [-151.771008807999948, 17.819995944000027],
              [-151.779815807999881, 17.805554944000164],
              [-151.788695807999829, 17.791156944000249],
              [-151.797649807999875, 17.776800944000286],
              [-151.806676807999878, 17.762486944000045],
              [-151.815777807999922, 17.748215944000208],
              [-151.824950807999841, 17.733988944000259],
              [-151.834195807999976, 17.719804944000089],
              [-151.843513807999926, 17.705664944000262],
              [-151.852903807999866, 17.691568944000096],
              [-151.862364807999853, 17.677517944000158],
              [-151.871897807999829, 17.663511944000163],
              [-151.88150280799988, 17.649551944000109],
              [-151.891178807999864, 17.635635944000342],
              [-151.900924807999985, 17.621766944000342],
              [-151.910741807999898, 17.607943944000226],
              [-151.920628807999861, 17.594167944000276],
              [-151.930586807999816, 17.58043894400015],
              [-151.940613807999824, 17.566756944000133],
              [-151.950710807999826, 17.553122944000052],
              [-151.960876807999853, 17.53953694400019],
              [-151.971111807999819, 17.525998944000037],
              [-151.981415807999923, 17.512509944000044],
              [-151.991788807999939, 17.499068944000101],
              [-152.002228807999899, 17.485677944000258],
              [-152.012737807999883, 17.472336944000233],
              [-152.023314807999924, 17.459044944000198],
              [-152.03395880799988, 17.44580394400009],
              [-152.044669807999895, 17.432612944000255],
              [-152.045253807999899, 17.43189694400013],
              [-152.056084807999895, 17.418691944000216],
              [-152.066929807999969, 17.405603944000177],
              [-152.07784080799982, 17.392566944000066],
              [-152.088817807999902, 17.379580944000111],
              [-152.099860807999846, 17.366647944000135],
              [-152.110968807999853, 17.353766944000142],
              [-152.122142807999865, 17.340938944000129],
              [-152.133380807999885, 17.32816494400015],
              [-152.144684807999823, 17.31544294400021],
              [-152.156052807999941, 17.302774944000078],
              [-152.167484808000012, 17.29016194400009],
              [-152.178979807999866, 17.277601944000025],
              [-152.190539807999954, 17.265096944000277],
              [-152.202161807999829, 17.252646944000276],
              [-152.213847807999883, 17.240252944000304],
              [-152.225853807999925, 17.227643943000146],
              [-152.237633807999913, 17.215271943000232],
              [-152.249443807999825, 17.20298894300015],
              [-152.261314807999867, 17.190760943000043],
              [-152.27324880799992, 17.178589943000247],
              [-152.285243807999933, 17.166475943000137],
              [-152.297299807999849, 17.154417943000226],
              [-152.306998807999975, 17.144802943000116],
              [-152.311069807999814, 17.1407449430003],
              [-152.32318580799992, 17.128744943000186],
              [-152.335362807999815, 17.116801943000212],
              [-152.347599807999956, 17.104916943000262],
              [-152.359896807999831, 17.09308994300028],
              [-152.372253807999954, 17.081321943000205],
              [-152.384669807999899, 17.069611943000325],
              [-152.397144807999837, 17.057960943000353],
              [-152.409678807999882, 17.046369943000172],
              [-152.422270807999922, 17.03483794300007],
              [-152.434921807999842, 17.023365943000158],
              [-152.447629807999903, 17.011953943000265],
              [-152.460395807999845, 17.000601943000333],
              [-152.473218807999984, 16.989310943000305],
              [-152.486098807999866, 16.978080943000123],
              [-152.499034807999948, 16.966911943000071],
              [-152.512027807999942, 16.955803943000262],
              [-152.525075807999855, 16.944758943000352],
              [-152.538179807999882, 16.933774943000287],
              [-152.551339807999938, 16.922852943000237],
              [-152.564553807999829, 16.911993943000255],
              [-152.577822807999922, 16.901197943000113],
              [-152.591146807999849, 16.890463943000157],
              [-152.600146807999863, 16.883273943000177],
              [-152.603317807999872, 16.880676943000253],
              [-152.616583807999888, 16.869880943000112],
              [-152.62990480799985, 16.859146943000155],
              [-152.632203807999815, 16.857305943000256],
              [-152.634497807999821, 16.855388943000264],
              [-152.647651807999836, 16.844466943000214],
              [-152.660859807999856, 16.83360694300012],
              [-152.674122807999879, 16.822809943000095],
              [-152.687439807999851, 16.812075943000139],
              [-152.700810807999858, 16.80140494300025],
              [-152.714235807999898, 16.790796943000259],
              [-152.727713807999891, 16.780253943000275],
              [-152.741244807999891, 16.769773943000189],
              [-152.754828807999843, 16.759358943000166],
              [-152.768464807999834, 16.749008943000206],
              [-152.782152807999864, 16.738722943000198],
              [-152.795892807999849, 16.728501943000026],
              [-152.809683807999903, 16.718345943000315],
              [-152.823525808999875, 16.708255943000154],
              [-152.837418808999814, 16.698231943000337],
              [-152.851157808999886, 16.688418943000158],
              [-152.85536380899984, 16.685362943000086],
              [-152.86925480899987, 16.675337943000216],
              [-152.883195808999886, 16.665379943000119],
              [-152.897186808999834, 16.655487943000026],
              [-152.911227808999854, 16.64566194300005],
              [-152.925317808999949, 16.635902943000133],
              [-152.939456808999893, 16.626210943000046],
              [-152.953643808999828, 16.616585943000189],
              [-152.967879808999925, 16.607028943000103],
              [-152.982163808999871, 16.597538943000245],
              [-152.996494808999898, 16.588116943000102],
              [-153.010872808999835, 16.578762943000299],
              [-153.025298808999821, 16.569477943000152],
              [-153.039769808999893, 16.560260943000117],
              [-153.054287808999874, 16.551111943000024],
              [-153.068851808999824, 16.542032943000265],
              [-153.083460808999831, 16.533022943000105],
              [-153.09811580899995, 16.524081943000169],
              [-153.112813808999874, 16.51521094300017],
              [-153.12755780899991, 16.506409943000108],
              [-153.142344808999923, 16.497678943000153],
              [-153.157175808999938, 16.489017943000135],
              [-153.172050808999842, 16.480427943000279],
              [-153.186967808999867, 16.47190794300019],
              [-153.201927808999898, 16.463458943000091],
              [-153.216929808999851, 16.455081943000323],
              [-153.231973808999925, 16.446774943000264],
              [-153.247059808999978, 16.438539943000137],
              [-153.262185808999988, 16.430376943000283],
              [-153.277353808999891, 16.42228494300025],
              [-153.292560808999838, 16.414265943000203],
              [-153.307808808999908, 16.406318943000088],
              [-153.323096808999907, 16.398443943000075],
              [-153.33842380899992, 16.390641943000276],
              [-153.353789808999892, 16.382912943000235],
              [-153.369193808999881, 16.375256943000238],
              [-153.383272808999834, 16.368339943000251],
              [-153.389129808999854, 16.365438943000186],
              [-153.40457180899989, 16.357855943000175],
              [-153.418911808999866, 16.350895943000239],
              [-153.42440780899986, 16.34659694300035],
              [-153.437904808999832, 16.336112943000273],
              [-153.451455808999896, 16.325692943000206],
              [-153.459658808999848, 16.319435943000087],
              [-153.466012808999977, 16.31456294300034],
              [-153.479614808999912, 16.304206943000224],
              [-153.493267808999889, 16.293916943000227],
              [-153.506972808999905, 16.283691943000292],
              [-153.520728808999877, 16.273531943000194],
              [-153.534536808999889, 16.263436943000158],
              [-153.548394808999859, 16.253408943000181],
              [-153.562302808999817, 16.243445943000268],
              [-153.57626080899999, 16.233549943000185],
              [-153.590268808999838, 16.223719943000049],
              [-153.599277808999886, 16.217450943000244],
              [-153.61223480899983, 16.20845494300022],
              [-153.626339808999916, 16.198758943000144],
              [-153.640493808999878, 16.189129943000125],
              [-153.65469580899989, 16.179567943000166],
              [-153.668945808999894, 16.170073943000148],
              [-153.67954480899985, 16.163075943000024],
              [-153.690600808999903, 16.155795943000214],
              [-153.704944808999954, 16.146437942000148],
              [-153.719335808999887, 16.137147942000126],
              [-153.733773808999985, 16.127926942000329],
              [-153.748256808999884, 16.11877494200013],
              [-153.762785808999865, 16.109691942000211],
              [-153.777360808999845, 16.100677942000289],
              [-153.791980808999909, 16.091732942000192],
              [-153.806644808999863, 16.082857942000203],
              [-153.821353808999817, 16.074052942000151],
              [-153.836106808999858, 16.065317942000036],
              [-153.85090280899982, 16.056652942000028],
              [-153.865742808999869, 16.048058942000239],
              [-153.880624808999841, 16.039534942000159],
              [-153.895550809999833, 16.031081942000299],
              [-153.910517809999902, 16.022700942000142],
              [-153.925526809999894, 16.014390942000205],
              [-153.940577809999894, 16.006151942000088],
              [-153.95566880999985, 15.997984942000073],
              [-153.970801809999983, 15.989888942000277],
              [-153.985974809999874, 15.98186594200007],
              [-154.001186809999894, 15.973915942000247],
              [-154.016439809999923, 15.966036942000073],
              [-154.031731809999968, 15.958230942000284],
              [-154.047061809999974, 15.950498942000195],
              [-154.062430809999825, 15.942838942000208],
              [-154.077838809999975, 15.935251942000036],
              [-154.093283809999889, 15.927738942000133],
              [-154.108765809999852, 15.920298942000045],
              [-154.124285809999861, 15.912932942000054],
              [-154.139841809999922, 15.905641942000045],
              [-154.155434809999832, 15.898423942000022],
              [-154.157826809999847, 15.897324942000239],
              [-154.163289809999867, 15.894805942000175],
              [-154.178917809999831, 15.88766194200025],
              [-154.194581809999846, 15.880592942000135],
              [-154.21028080999983, 15.873597942000288],
              [-154.226014809999867, 15.866678942000135],
              [-154.241782809999876, 15.85983394200008],
              [-154.25758481, 15.853063942000176],
              [-154.273420809999976, 15.846369942000024],
              [-154.289289809999815, 15.839750942000194],
              [-154.305191809999883, 15.833206942000174],
              [-154.321126809999868, 15.826739942000131],
              [-154.337093809999885, 15.820347942000069],
              [-154.353091809999825, 15.81403194200027],
              [-154.369121809999939, 15.807792942000276],
              [-154.385182809999861, 15.801628942000264],
              [-154.401274809999933, 15.795542942000282],
              [-154.417395809999817, 15.789532942000221],
              [-154.433547809999908, 15.783599942000137],
              [-154.449728809999868, 15.777742942000089],
              [-154.465938809999869, 15.771963942000127],
              [-154.482177809999854, 15.766261942000142],
              [-154.498444809999938, 15.760636942000133],
              [-154.514739809999838, 15.755089942000211],
              [-154.531061809999841, 15.749620942000149],
              [-154.547411809999886, 15.744228942000234],
              [-154.563787809999923, 15.738914942000179],
              [-154.580190809999891, 15.733678942000211],
              [-154.596619809999851, 15.728520942000102],
              [-154.613073809999861, 15.723441942000136],
              [-154.629552809999922, 15.718439942000202],
              [-154.646056809999976, 15.713517942000067],
              [-154.646819809999869, 15.713291942000183],
              [-154.663506809999973, 15.708400942000196],
              [-154.680059809999818, 15.70363594200029],
              [-154.69663580999989, 15.698948942000243],
              [-154.713234809999818, 15.694341942000278],
              [-154.728816809999984, 15.690093942000146],
              [-154.73638480999989, 15.688042942000266],
              [-154.753028809999819, 15.683592942000132],
              [-154.76969580999986, 15.679222942000251],
              [-154.786383809999876, 15.674931942000285],
              [-154.803092809999839, 15.67072094200023],
              [-154.819822809999863, 15.666588942000089],
              [-154.836572809999865, 15.662536942000202],
              [-154.853342809999816, 15.658564942000169],
              [-154.870132809999831, 15.654672942000218],
              [-154.886941809999882, 15.650859942000181],
              [-154.903769809999943, 15.647127942000168],
              [-154.920616809999871, 15.643475942000123],
              [-154.937480809999869, 15.63990394200016],
              [-154.95436280999985, 15.636411942000279],
              [-154.971262810999832, 15.633000942000137],
              [-154.988178810999869, 15.629669942000191],
              [-155.005110810999895, 15.626418942000157],
              [-155.022059810999934, 15.623249942000143],
              [-155.039023810999851, 15.620160942000155],
              [-155.056002810999871, 15.617152942000359],
              [-155.072996810999882, 15.614225942000076],
              [-155.090005810999827, 15.611378942000272],
              [-155.107027810999824, 15.60861394200009],
              [-155.124063810999957, 15.605929942000046],
              [-155.141112810999886, 15.603325942000254],
              [-155.158174810999952, 15.600803942000141],
              [-155.175249810999816, 15.598363942000219],
              [-155.192335810999822, 15.596003942000152],
              [-155.209433810999911, 15.593725942000162],
              [-155.226542810999831, 15.591528942000082],
              [-155.243662810999894, 15.589413942000249],
              [-155.260792810999874, 15.587380942000266],
              [-155.277932810999886, 15.585427942000138],
              [-155.295082810999844, 15.583557942000141],
              [-155.312240810999839, 15.581768942000281],
              [-155.329408810999865, 15.58006194200027],
              [-155.346584810999929, 15.578435942000169],
              [-155.36376781099986, 15.576892942000256],
              [-155.380958810999829, 15.575430942000253],
              [-155.398157810999834, 15.57405094200027],
              [-155.415361810999826, 15.572752942000193],
              [-155.432573810999855, 15.57153694200008],
              [-155.449790810999929, 15.57040194200016],
              [-155.467012810999876, 15.569349942000144],
              [-155.484240810999893, 15.568379942000206],
              [-155.5014728109999, 15.567491942000061],
              [-155.518708810999868, 15.566684942000279],
              [-155.535948810999884, 15.565960942000231],
              [-155.553192810999889, 15.565318942000033],
              [-155.570438810999889, 15.564758942000253],
              [-155.587687810999853, 15.564280942000153],
              [-155.604939810999895, 15.563885942000127],
              [-155.622192810999849, 15.563571942000294],
              [-155.639446810999885, 15.563339942000084],
              [-155.656702810999917, 15.563190942000176],
              [-155.673957810999838, 15.563123942000175],
              [-155.691214810999867, 15.563138942000137],
              [-155.708469810999787, 15.563235942000176],
              [-155.725724810999822, 15.563414942000065],
              [-155.742978810999858, 15.563675942000202],
              [-155.760231810999898, 15.564019942000243],
              [-155.777481810999859, 15.564444942000023],
              [-155.794730810999937, 15.564952942000161],
              [-155.81197581099994, 15.565542942000093],
              [-155.829218810999947, 15.566214942000101],
              [-155.846456810999882, 15.56696894200013],
              [-155.863691810999853, 15.567804942000237],
              [-155.880922810999863, 15.568722942000136],
              [-155.898147810999887, 15.569722942000283],
              [-155.915368810999865, 15.570804942000279],
              [-155.932583810999859, 15.571968942000126],
              [-155.949792810999895, 15.573213942000109],
              [-155.966994810999893, 15.574541942000224],
              [-155.984190810999934, 15.575951942000188],
              [-155.995105810999945, 15.57688994200015],
              [-156.002794810999831, 15.577566942000033],
              [-156.019975810999966, 15.579139942000154],
              [-156.037147811999859, 15.580794942000125],
              [-156.054312811999836, 15.582531942000173],
              [-156.071467811999838, 15.584350942000185],
              [-156.088613811999892, 15.58625094200022],
              [-156.105750811999854, 15.588232942000047],
              [-156.122876811999873, 15.590295942000239],
              [-156.139992811999832, 15.592440942000053],
              [-156.157098811999788, 15.594666942000231],
              [-156.174191811999833, 15.596974942000031],
              [-156.191273811999935, 15.599362942000084],
              [-156.208343811999839, 15.601833942000269],
              [-156.225401811999831, 15.604384942000138],
              [-156.242445811999886, 15.607017942000198],
              [-156.259476811999861, 15.609731942000224],
              [-156.276494811999896, 15.612525942000104],
              [-156.293497811999856, 15.615401942000233],
              [-156.310486811999823, 15.6183589420001],
              [-156.327460811999856, 15.62139594200022],
              [-156.34441881199993, 15.624513942000078],
              [-156.361361811999899, 15.627712942000301],
              [-156.378288811999965, 15.630992942000262],
              [-156.395198811999961, 15.634352942000248],
              [-156.412091811999858, 15.637792942000146],
              [-156.428966811999885, 15.641313942000124],
              [-156.445824811999842, 15.644914942000185],
              [-156.462664811999815, 15.648596942000211],
              [-156.479485811999922, 15.652357942000151],
              [-156.496288811999847, 15.656198942000174],
              [-156.513071811999822, 15.660120942000162],
              [-156.529834811999905, 15.664121942000065],
              [-156.546577811999924, 15.66820294200005],
              [-156.563299811999826, 15.672363942000061],
              [-156.580001811999836, 15.67660394200027],
              [-156.596681811999787, 15.680922942000223],
              [-156.613339811999907, 15.685321942000201],
              [-156.629975811999969, 15.68979994200015],
              [-156.646589811999974, 15.694357942000181],
              [-156.663180811999865, 15.698993942000243],
              [-156.679747811999817, 15.703708942000276],
              [-156.696291811999885, 15.70850294200028],
              [-156.712810811999958, 15.713375942000255],
              [-156.729305811999978, 15.71832694200026],
              [-156.745775811999891, 15.723356942000237],
              [-156.762220811999867, 15.728464942000301],
              [-156.778639811999824, 15.733650942000224],
              [-156.795032811999846, 15.738914942000179],
              [-156.811399811999848, 15.74425694200022],
              [-156.827738811999865, 15.749676942000349],
              [-156.844051811999833, 15.75517494200011],
              [-156.860335811999818, 15.760749942000075],
              [-156.87659281199987, 15.766402942000298],
              [-156.892820811999826, 15.772132942000269],
              [-156.909020811999966, 15.777940942000157],
              [-156.92519081199984, 15.783824942000081],
              [-156.941331811999873, 15.789785942000208],
              [-156.957441811999843, 15.795823942000084],
              [-156.973522811999914, 15.801937942000052],
              [-156.98957181199998, 15.808128942000167],
              [-157.005590811999866, 15.814395942000203],
              [-157.021576811999978, 15.820739942000159],
              [-157.037531811999912, 15.827158942000153],
              [-157.053454811999956, 15.83365394200024],
              [-157.069344811999827, 15.840224942000361],
              [-157.085201811999923, 15.846870942000294],
              [-157.101025811999847, 15.853592942000091],
              [-157.116815812999903, 15.860389942000268],
              [-157.132570813, 15.867261942000255],
              [-157.148291812999929, 15.874208942000223],
              [-157.163977812999889, 15.881230942000172],
              [-157.179628812999823, 15.888326942000219],
              [-157.195243812999848, 15.895497942000247],
              [-157.210822812999851, 15.902741942000262],
              [-157.226365812999887, 15.910060942000086],
              [-157.241870812999906, 15.917453942000179],
              [-157.257339812999959, 15.924920942000028],
              [-157.27277081299988, 15.93245994200015],
              [-157.288163812999841, 15.940073942000026],
              [-157.303518812999869, 15.947759942000232],
              [-157.318834812999881, 15.955518942000253],
              [-157.334111812999851, 15.963351942000145],
              [-157.349349812999861, 15.971255942000255],
              [-157.364547812999973, 15.979232942000181],
              [-157.379704812999961, 15.987282942000093],
              [-157.394822812999905, 15.995403942000223],
              [-157.409898812999955, 16.003596942000058],
              [-157.424933812999967, 16.011861942000223],
              [-157.439927812999969, 16.020197942000152],
              [-157.454878812999965, 16.02860594200024],
              [-157.469788812999951, 16.037083942000038],
              [-157.484654812999821, 16.045633942000109],
              [-157.499478812999882, 16.054253942000344],
              [-157.514258812999856, 16.062943942000288],
              [-157.528994812999827, 16.07170394200034],
              [-157.543687812999877, 16.080534942000213],
              [-157.558334812999959, 16.08943494200031],
              [-157.572937812999839, 16.098404942000116],
              [-157.587495812999833, 16.107444942000257],
              [-157.602008812999912, 16.116552942000169],
              [-157.616474812999826, 16.125729942000248],
              [-157.630894812999856, 16.13497594200021],
              [-157.645268813, 16.144290942000168],
              [-157.65959481299987, 16.153673942000239],
              [-157.674090812999879, 16.163267943000108],
              [-157.686492812999916, 16.170421943000349],
              [-157.701326812999838, 16.179039943000191],
              [-157.716116813000014, 16.187729943000136],
              [-157.730862812999817, 16.196488943000077],
              [-157.745564812999817, 16.205317943000182],
              [-157.760222813000013, 16.214217943000278],
              [-157.774835812999839, 16.22318594300009],
              [-157.789403812999836, 16.232223943000065],
              [-157.803925812999864, 16.241331943000148],
              [-157.818401812999838, 16.250507943000343],
              [-157.832831812999871, 16.259752943000194],
              [-157.847214812999965, 16.269065943000157],
              [-157.861551812999949, 16.278447943000174],
              [-157.875840812999883, 16.287896943000192],
              [-157.890081812999853, 16.297414943000263],
              [-157.904275812999828, 16.306999943000164],
              [-157.918420812999926, 16.316651943000352],
              [-157.932516812999864, 16.326371943000254],
              [-157.946564812999839, 16.336157943000273],
              [-157.960562812999854, 16.346010943000351],
              [-157.974510812999824, 16.355929943000206],
              [-157.988409812999834, 16.365915943000061],
              [-158.002257812999858, 16.375966943000151],
              [-158.016054812999954, 16.386083943000244],
              [-158.02980081299998, 16.396266943000285],
              [-158.043495812999879, 16.406514943000161],
              [-158.057138812999824, 16.416827943000271],
              [-158.070729812999929, 16.427204943000163],
              [-158.084268812999881, 16.437646943000118],
              [-158.097754812999909, 16.448152943000252],
              [-158.111187812999816, 16.458722943000112],
              [-158.124566812999859, 16.46935694300015],
              [-158.137892812999951, 16.480054943000141],
              [-158.151164812999951, 16.490814943000316],
              [-158.164381813, 16.501637943000162],
              [-158.177544812999855, 16.512524943000358],
              [-158.190651813999949, 16.523472943000058],
              [-158.203704813999849, 16.534483943000225],
              [-158.216700814, 16.545555943000238],
              [-158.229641813999962, 16.556689943000265],
              [-158.24252581399989, 16.567885943000249],
              [-158.255353813999818, 16.579141943000025],
              [-158.268124813999833, 16.590459943000155],
              [-158.28083881399985, 16.601837943000305],
              [-158.293494813999928, 16.613275943000247],
              [-158.301751813999829, 16.620799943000179],
              [-158.307580813999863, 16.62613394300007],
              [-158.320120813999836, 16.637691943000164],
              [-158.332602813999927, 16.649308943000221],
              [-158.345025813999825, 16.660985943000185],
              [-158.357388813999847, 16.672720943000172],
              [-158.36969381399993, 16.684514943000295],
              [-158.381937813999855, 16.696366943000214],
              [-158.394122813999871, 16.708277943000212],
              [-158.406246814000014, 16.720245943000293],
              [-158.418310813999966, 16.732270943000117],
              [-158.430312813999933, 16.744353943000135],
              [-158.442254813999824, 16.756492943000183],
              [-158.454133813999846, 16.768688943000143],
              [-158.465952813999962, 16.780941943000187],
              [-158.477707813999928, 16.793249943000149],
              [-158.48940181399982, 16.805613943000139],
              [-158.501032813999842, 16.81803394300016],
              [-158.512600813999967, 16.830507943000214],
              [-158.524104813999855, 16.843036943000186],
              [-158.53554581399996, 16.855620943000076],
              [-158.546922813999828, 16.868258943000228],
              [-158.558235813999914, 16.880950943000357],
              [-158.569484813999935, 16.89369694300035],
              [-158.580668813999807, 16.906494943000155],
              [-158.591786813999846, 16.919346943000221],
              [-158.602840813999904, 16.93225194300004],
              [-158.613828813999817, 16.945207943000185],
              [-158.624750813999867, 16.958216943000252],
              [-158.635606813999885, 16.971276943000305],
              [-158.646396813999871, 16.98438894300017],
              [-158.657119813999884, 16.99755194300019],
              [-158.667775813999867, 17.010765943000195],
              [-158.678364813999821, 17.024029943000244],
              [-158.688886813999915, 17.03734394300011],
              [-158.699340813999896, 17.050707943000077],
              [-158.709725813999825, 17.064121943000089],
              [-158.720043813999922, 17.077584943000261],
              [-158.730292813999881, 17.091095943000141],
              [-158.740472813999844, 17.104655943000125],
              [-158.750583813999953, 17.118263943000215],
              [-158.760625813999837, 17.131919943000185],
              [-158.770598813999896, 17.145623943000203],
              [-158.780500813999851, 17.159374943000216],
              [-158.790333813999865, 17.173171943000284],
              [-158.800095813999945, 17.187015943000176],
              [-158.809786813999835, 17.200906943000234],
              [-158.819407813999817, 17.214842943000065],
              [-158.828957813999864, 17.228824943000177],
              [-158.838435814, 17.242850944000111],
              [-158.847842813999932, 17.256922944000223],
              [-158.857177813999954, 17.271038944000225],
              [-158.866440813999873, 17.285198944000342],
              [-158.875631813999888, 17.299402944000349],
              [-158.884749813999889, 17.313650944000244],
              [-158.893795813999901, 17.327941944000145],
              [-158.902767813999816, 17.342274944000167],
              [-158.911667813999912, 17.356650944000023],
              [-158.920493813999911, 17.371068944000172],
              [-158.929245813999927, 17.38552794400033],
              [-158.937923813999845, 17.400028944000212],
              [-158.946527813999865, 17.414570944000104],
              [-158.955057813999815, 17.429153944000234],
              [-158.96351281399987, 17.443776944000263],
              [-158.970322813999957, 17.455678944000226],
              [-158.971720813999895, 17.45805194400026],
              [-158.980252813999897, 17.472633944000279],
              [-158.98871081399983, 17.487255944000253],
              [-158.997093813999868, 17.501918944000181],
              [-159.005401813999839, 17.516620944000181],
              [-159.013634813999886, 17.531361944000253],
              [-159.021791813999869, 17.546142944000223],
              [-159.02987381399987, 17.560960944000215],
              [-159.037879813999979, 17.57581794400005],
              [-159.045808813999827, 17.590712944000245],
              [-159.053661813999895, 17.605644944000062],
              [-159.061438813999871, 17.620614944000238],
              [-159.069138813999899, 17.635620944000152],
              [-159.076761813999838, 17.650662944000203],
              [-159.084307813999885, 17.665740944000049],
              [-159.091776813999985, 17.680854944000032],
              [-159.099167813999912, 17.696003944000267],
              [-159.10648081399998, 17.711187944000358],
              [-159.11371581399996, 17.726406944000303],
              [-159.12087281399991, 17.741659944000048],
              [-159.127951813999857, 17.756945944000051],
              [-159.134952813999945, 17.772265944000026],
              [-159.141873813999894, 17.787618944000258],
              [-159.148716813999869, 17.803003944000295],
              [-159.155480813999901, 17.818421944000193],
              [-159.162164813999851, 17.833871944000293],
              [-159.168769813999859, 17.849352944000259],
              [-159.175294813999898, 17.864864944000317],
              [-159.181740813999824, 17.88040794400024],
              [-159.188105813999954, 17.895981944000084],
              [-159.194390813999945, 17.911584944000026],
              [-159.200595813999882, 17.927217944000176],
              [-159.206720813999965, 17.94288094400008],
              [-159.212764813999911, 17.958571944000198],
              [-159.218726813999837, 17.974290944000131],
              [-159.220454813999908, 17.978890944000284],
              [-159.225078813999858, 17.990912944000229],
              [-159.231042813999949, 18.006631944000162],
              [-159.236926813999986, 18.022379944000193],
              [-159.237963813999983, 18.025180944000113],
              [-159.243288813999953, 18.039667944000087],
              [-159.249009814, 18.055469944000151],
              [-159.254649813999919, 18.071299944000259],
              [-159.260206814999975, 18.087155944000358],
              [-159.265683814999818, 18.103037944000278],
              [-159.271077814999899, 18.118945944000245],
              [-159.276389814999845, 18.134878944000093],
              [-159.277699814999977, 18.138878944000339],
              [-159.284104814999864, 18.14100394400009],
              [-159.300601814999879, 18.146563944000263],
              [-159.317069815, 18.152200944000185],
              [-159.331194814999861, 18.157104944000025],
              [-159.333956814999937, 18.158070944000201],
              [-159.350367814999942, 18.163862944000186],
              [-159.366748814999966, 18.169730944000037],
              [-159.383100814999864, 18.17567494400015],
              [-159.399422814999895, 18.18169694400035],
              [-159.415713814999918, 18.187793944000305],
              [-159.431974814999847, 18.193967944000292],
              [-159.448203814999886, 18.200218944000198],
              [-159.464400814999863, 18.206544944000143],
              [-159.480565814999949, 18.212946944000294],
              [-159.49669881499986, 18.219423944000084],
              [-159.512798814999883, 18.225976944000138],
              [-159.528865814999847, 18.232605944000284],
              [-159.54489881499984, 18.239308944000129],
              [-159.560898814999859, 18.246087944000237],
              [-159.576862814999828, 18.252941944000156],
              [-159.592792814999825, 18.259869944000343],
              [-159.608687814999968, 18.266872944000113],
              [-159.624547814999943, 18.273949944000208],
              [-159.640371814999952, 18.281101944000056],
              [-159.656158814999941, 18.288326944000119],
              [-159.671909814999964, 18.295626944000162],
              [-159.687622814999855, 18.302999945000295],
              [-159.697775814999972, 18.307811945000196],
              [-159.704051814999929, 18.310803945000146],
              [-159.719690814999808, 18.318324945000143],
              [-159.735290814999956, 18.325917945000299],
              [-159.750852814999888, 18.333583945000044],
              [-159.766375814999861, 18.341322945000172],
              [-159.781859814999819, 18.349134945000117],
              [-159.797303814999935, 18.357018945000164],
              [-159.812708814999922, 18.364974945000142],
              [-159.82576081499991, 18.371795945000144],
              [-159.83521481499983, 18.369967945000269],
              [-159.85239681499985, 18.366727945000264],
              [-159.869595814999883, 18.363568945000168],
              [-159.886809814999879, 18.360489945000154],
              [-159.904038814999893, 18.357491945000049],
              [-159.921283814999896, 18.354573945000027],
              [-159.938541814999894, 18.351736945000141],
              [-159.955814814999911, 18.348980945000164],
              [-159.973101814999922, 18.346305945000211],
              [-159.990401814999842, 18.343710945000055],
              [-159.997767814999833, 18.342631945000335],
              [-160.011220814999973, 18.340685945000246],
              [-160.028545814999831, 18.338252945000193],
              [-160.0458828149998, 18.335900945000276],
              [-160.063231814999881, 18.333630945000209],
              [-160.080590814999908, 18.331440945000224],
              [-160.097961814999849, 18.329332945000033],
              [-160.115342814999821, 18.327305945000205],
              [-160.132733814999909, 18.325360945000227],
              [-160.150134814999859, 18.323495945000275],
              [-160.167544815000014, 18.321713945000056],
              [-160.184962814999835, 18.320011945000317],
              [-160.202389814999975, 18.318391945000201],
              [-160.219824814999896, 18.316853945000105],
              [-160.237266814999828, 18.315396945000202],
              [-160.254716814999824, 18.314020945000209],
              [-160.272172814999891, 18.312726945000065],
              [-160.289634814999886, 18.31151494500017],
              [-160.307103814999863, 18.310384945000294],
              [-160.324577814999884, 18.309335945000157],
              [-160.342056816, 18.308368945000268],
              [-160.359539815999966, 18.307483945000229],
              [-160.377027815999895, 18.306679945000155],
              [-160.394519815999899, 18.305958945000157],
              [-160.412014815999896, 18.305318945000352],
              [-160.429512815999971, 18.304760945000169],
              [-160.447012815999841, 18.304283945000122],
              [-160.464515815999846, 18.303889945000037],
              [-160.482020815999874, 18.303576945000259],
              [-160.499526815999985, 18.303346945000044],
              [-160.51703381599998, 18.303197945000136],
              [-160.53454081599989, 18.303130945000305],
              [-160.552048815999797, 18.303145945000324],
              [-160.569555815999905, 18.303241945000252],
              [-160.5870628159999, 18.303420945000141],
              [-160.604568815999841, 18.303680945000167],
              [-160.622072815999843, 18.304023945000097],
              [-160.639574815999879, 18.30444794500022],
              [-160.657073815999951, 18.304953945000136],
              [-160.674570815999829, 18.3055419450003],
              [-160.692064815999828, 18.306210945000259],
              [-160.709555815999835, 18.306962945000294],
              [-160.727041815999883, 18.307795945000294],
              [-160.744523815999855, 18.308710945000144],
              [-160.762000815999869, 18.3097069450003],
              [-160.779472815999924, 18.310785945000248],
              [-160.796938815999823, 18.311945945000105],
              [-160.814398815999851, 18.313187945000209],
              [-160.831852815999895, 18.314510945000052],
              [-160.849299815999842, 18.315915945000143],
              [-160.866739815999978, 18.317402945000254],
              [-160.884171815999906, 18.31897094500016],
              [-160.901595815999968, 18.320619945000146],
              [-160.919010815999911, 18.32235094500021],
              [-160.936417815999903, 18.324163945000294],
              [-160.953814815999863, 18.326056945000232],
              [-160.971201815999791, 18.328032945000302],
              [-160.988579815999969, 18.330088945000057],
              [-161.005946815999863, 18.33222694500023],
              [-161.023301815999815, 18.334444945000257],
              [-161.040646815999906, 18.336744945000305],
              [-161.057979815999914, 18.339125945000148],
              [-161.075299815999898, 18.341587945000072],
              [-161.092607815999855, 18.344130945000188],
              [-161.109902815999817, 18.346754945000214],
              [-161.127184815999868, 18.349459945000206],
              [-161.144452815999898, 18.352245945000163],
              [-161.161705815999937, 18.355111945000147],
              [-161.178944815999984, 18.358058945000266],
              [-161.196168815999926, 18.361085945000241],
              [-161.213376815999879, 18.364193945000181],
              [-161.230569815999928, 18.367381945000147],
              [-161.247746815999818, 18.370650945000136],
              [-161.264905815999981, 18.373999945000094],
              [-161.282048815999872, 18.377428945000304],
              [-161.299173815999893, 18.380938945000139],
              [-161.316281815999844, 18.384527945000059],
              [-161.333370815999899, 18.388196945000232],
              [-161.350441815999886, 18.391945945000089],
              [-161.367492815999839, 18.395774945000198],
              [-161.384524815999981, 18.399683945000163],
              [-161.401536815999862, 18.403671945000269],
              [-161.418528816999896, 18.407738945000119],
              [-161.427730816999855, 18.409977945000094],
              [-161.43740281699985, 18.412350945000185],
              [-161.454352816999886, 18.416577945000142],
              [-161.471280816999837, 18.420882945000187],
              [-161.488187816999954, 18.425267945000087],
              [-161.505072817000013, 18.429730945000244],
              [-161.521934816999845, 18.434273945000257],
              [-161.538772816999824, 18.438894945000129],
              [-161.555588816999943, 18.4435949450002],
              [-161.57237981699987, 18.448373945000185],
              [-161.589146816999829, 18.453230945000257],
              [-161.598167816999904, 18.455878945000109],
              [-161.606445816999866, 18.45832994500006],
              [-161.623163816999863, 18.46334294500025],
              [-161.639855816999841, 18.468434945000183],
              [-161.656521816999913, 18.473604945000034],
              [-161.673161816999936, 18.478851945000031],
              [-161.689774816999972, 18.484176945000286],
              [-161.706361816999959, 18.48957994500023],
              [-161.722919816999962, 18.49505994500015],
              [-161.739451816999946, 18.500617945000272],
              [-161.755953816999835, 18.506251945000145],
              [-161.772428816999906, 18.511963945000048],
              [-161.788873816999882, 18.517752945000154],
              [-161.805289816999846, 18.52361894500018],
              [-161.821675816999914, 18.529560945000128],
              [-161.838031816999887, 18.535579945000052],
              [-161.854356816999882, 18.541674945000238],
              [-161.870651816999981, 18.54784594500012],
              [-161.88691481699982, 18.554093945000147],
              [-161.892295816999905, 18.556179945000281],
              [-161.904310816999981, 18.560869945000206],
              [-161.920509816999839, 18.567268945000137],
              [-161.936676816999807, 18.573743945000103],
              [-161.952810816999914, 18.580293945000278],
              [-161.968911816999849, 18.586919945000147],
              [-161.984978816999984, 18.593620945000225],
              [-162.001012816999975, 18.600396945000227],
              [-162.017010816999829, 18.607247945000267],
              [-162.032974816999968, 18.614172945000178],
              [-162.048903816999882, 18.621172945000069],
              [-162.064797816999828, 18.628247945000169],
              [-162.080654816999839, 18.635395945000255],
              [-162.09647581699997, 18.642618945000152],
              [-162.11226081699985, 18.649915945000089],
              [-162.128007816999826, 18.657285945000069],
              [-162.143717816999896, 18.664729945000147],
              [-162.159389816999862, 18.672246945000211],
              [-162.175023816999982, 18.679836945000261],
              [-162.190619816999885, 18.687499945000127],
              [-162.206175816999831, 18.695235945000206],
              [-162.221692816999848, 18.703044945000272],
              [-162.237170816999935, 18.710925945000042],
              [-162.252608816999896, 18.718878945000142],
              [-162.268005816999846, 18.726903945000288],
              [-162.283361816999872, 18.735000945000195],
              [-162.298676816999972, 18.743169945000204],
              [-162.313950816999863, 18.751409945000205],
              [-162.329182816999833, 18.759721945000138],
              [-162.344372816999822, 18.768104945000289],
              [-162.359519816999949, 18.77655794500015],
              [-162.3746238169999, 18.785081945000229],
              [-162.389683816999849, 18.7936769450003],
              [-162.404700816999906, 18.802341945000137],
              [-162.419673816999847, 18.811076945000252],
              [-162.434602816999899, 18.819881945000077],
              [-162.449486816999951, 18.828756945000066],
              [-162.464324816999834, 18.837700945000051],
              [-162.479117818, 18.84671394500009],
              [-162.493864817999878, 18.855795945000125],
              [-162.508566817999878, 18.864946945000213],
              [-162.523220817999828, 18.874166945000127],
              [-162.537828817999952, 18.883454945000153],
              [-162.552388817999827, 18.892810945000178],
              [-162.56690181799982, 18.902234945000259],
              [-162.581365817999938, 18.911726945000112],
              [-162.59578281799989, 18.921285945000022],
              [-162.610149817999883, 18.930911945000162],
              [-162.624468817999826, 18.940605945000186],
              [-162.638737817999868, 18.950365945000044],
              [-162.652957817999862, 18.960192945000301],
              [-162.667127817999898, 18.970085945000278],
              [-162.681246817999892, 18.980044945000259],
              [-162.695314817999929, 18.990069945000357],
              [-162.709331817999924, 19.000160945000175],
              [-162.723297817999963, 19.010316945000113],
              [-162.737211817999849, 19.020537945000285],
              [-162.751073817999895, 19.030823945000293],
              [-162.764883817999902, 19.041174945000137],
              [-162.778640817999957, 19.051589945000217],
              [-162.792343817999893, 19.062068945000192],
              [-162.805994817999903, 19.072611945000176],
              [-162.819590817999853, 19.083218945000283],
              [-162.833132817999797, 19.09388894500006],
              [-162.846620817999906, 19.104621945000304],
              [-162.860053817999898, 19.115417945000047],
              [-162.873431817999943, 19.126276945000257],
              [-162.886754817999872, 19.137197945000025],
              [-162.900020817999831, 19.148180945000149],
              [-162.9132318179999, 19.159225945000287],
              [-162.926385817999801, 19.170331945000271],
              [-162.939483817999871, 19.181499945000269],
              [-162.952523817999946, 19.19272894500034],
              [-162.965506817999909, 19.204018945000087],
              [-162.978431817999962, 19.215368945000023],
              [-162.991299817999817, 19.226778945000149],
              [-163.004108817999963, 19.238249945000177],
              [-163.016858817999832, 19.249779945000057],
              [-163.029549817999879, 19.261368945000243],
              [-163.042181817999847, 19.273017945000049],
              [-163.054754817999822, 19.28472494500005],
              [-163.067266817999894, 19.29649094500013],
              [-163.079719817999887, 19.308314945000234],
              [-163.092111817999893, 19.320197945000189],
              [-163.104442817999825, 19.332137945000113],
              [-163.11671181799997, 19.344134945000121],
              [-163.128920817999926, 19.356189945000153],
              [-163.141066817999928, 19.368300945000158],
              [-163.153151817999827, 19.380468946000178],
              [-163.165173817999857, 19.392692946000182],
              [-163.17713381799993, 19.404973946000212],
              [-163.189029817999824, 19.417309946000216],
              [-163.200863817999846, 19.429700946000139],
              [-163.212632817999918, 19.442147946000262],
              [-163.224338817999922, 19.454648946000191],
              [-163.235980817999859, 19.467204946000095],
              [-163.247558817999931, 19.479814946000204],
              [-163.259070817999856, 19.492478946000176],
              [-163.270518817999829, 19.505195946000242],
              [-163.28190081799994, 19.517966946000172],
              [-163.293217817999817, 19.530790946000195],
              [-163.304468817999918, 19.543666946000087],
              [-163.315653817999788, 19.55659594600013],
              [-163.326771817999912, 19.569576946000211],
              [-163.337823817999976, 19.582609946000332],
              [-163.348807817999926, 19.59569394600021],
              [-163.359725817999816, 19.608828946000187],
              [-163.370575817999878, 19.622014946000206],
              [-163.381357817999799, 19.635250946000212],
              [-163.392071817999977, 19.648537946000204],
              [-163.402716817999845, 19.661873946000355],
              [-163.413293817999971, 19.675260946000265],
              [-163.423801817999873, 19.688695946000223],
              [-163.434240817999836, 19.702179946000172],
              [-163.44460981799989, 19.715712946000053],
              [-163.454909817999834, 19.729293946000212],
              [-163.458635817999919, 19.734240946000284],
              [-163.466635817999872, 19.744911946000173],
              [-163.476795817999857, 19.758588946000259],
              [-163.486884817999822, 19.77231294600017],
              [-163.496902817999882, 19.786082946000136],
              [-163.50685081799989, 19.799899946000096],
              [-163.516725817999827, 19.813763946000222],
              [-163.526530817999941, 19.827672946000177],
              [-163.536263817999838, 19.841627946000187],
              [-163.545923817999835, 19.855627946000141],
              [-163.55551281899983, 19.869672946000151],
              [-163.565027818999823, 19.883762946000161],
              [-163.574470818999799, 19.89789694600023],
              [-163.583840818999875, 19.912073946000305],
              [-163.593137818999963, 19.926295946000209],
              [-163.602360818999955, 19.940559946000178],
              [-163.611510818999847, 19.954867946000093],
              [-163.620585818999956, 19.969217946000299],
              [-163.629587818999852, 19.983609946000229],
              [-163.638513818999968, 19.99804394600028],
              [-163.647365818999816, 20.012518946000114],
              [-163.656142818999854, 20.027035946000296],
              [-163.66484481899991, 20.041592946000151],
              [-163.673471818999872, 20.056190946000299],
              [-163.682022818999855, 20.070827946000236],
              [-163.690497818999887, 20.085505946000183],
              [-163.698896818999799, 20.100222946000201],
              [-163.707219818999846, 20.114978946000235],
              [-163.715465818999832, 20.129772946000173],
              [-163.723634818999926, 20.144605946000183],
              [-163.731727818999843, 20.159476946000154],
              [-163.738421818999939, 20.171927946000039],
              [-163.741364818999898, 20.173534946000132],
              [-163.756649818999819, 20.181974946000196],
              [-163.771892818999817, 20.190485946000251],
              [-163.787090818999928, 20.199066946000244],
              [-163.802245818999921, 20.207718946000227],
              [-163.817356818999826, 20.216439946000264],
              [-163.83242281899993, 20.225231946000292],
              [-163.847443818999949, 20.234092946000146],
              [-163.862419818999967, 20.243022946000281],
              [-163.877349818999818, 20.252021946000184],
              [-163.89223381899987, 20.261090946000252],
              [-163.907071818999839, 20.270227946000205],
              [-163.921862818999927, 20.279432946000156],
              [-163.936606818999934, 20.288706946000275],
              [-163.951302818999892, 20.298048946000165],
              [-163.955890818999876, 20.300996946000168],
              [-163.967994818999955, 20.298630946000173],
              [-163.985364818999898, 20.295318946000236],
              [-164.00275181899994, 20.292086946000154],
              [-164.020153818999859, 20.288935946000208],
              [-164.037572818999962, 20.285863946000234],
              [-164.055005818999973, 20.282873946000279],
              [-164.072454818999972, 20.279962946000296],
              [-164.089917818999879, 20.277132946000279],
              [-164.10739581899989, 20.274382946000287],
              [-164.124886818999897, 20.271713946000261],
              [-164.142390818999843, 20.269125946000145],
              [-164.159908818999867, 20.266618946000222],
              [-164.177438818999917, 20.264191946000324],
              [-164.194979818999883, 20.261845946000165],
              [-164.212533818999873, 20.259580946000142],
              [-164.230098818999977, 20.257396946000142],
              [-164.247674818999826, 20.255293946000222],
              [-164.265260818999877, 20.253271946000041],
              [-164.282856818999875, 20.251330946000223],
              [-164.300462818999819, 20.249470946000145],
              [-164.318077818999967, 20.247692946000143],
              [-164.335700818999982, 20.245994946000337],
              [-164.353333818999829, 20.244378946000211],
              [-164.370973818999829, 20.242843946000164],
              [-164.388621818999866, 20.241390946000251],
              [-164.406276818999856, 20.240018946000191],
              [-164.423937818999917, 20.238727946000154],
              [-164.441605818999818, 20.237518946000137],
              [-164.459279818999875, 20.23639194600014],
              [-164.476958818999918, 20.235344946000225],
              [-164.49464381899989, 20.234380946000215],
              [-164.512332818999965, 20.23349794600017],
              [-164.530026818999886, 20.232695946000035],
              [-164.547723818999884, 20.231975946000148],
              [-164.565424818999873, 20.23133794600011],
              [-164.58107681899989, 20.230840946000228],
              [-164.587656818999847, 20.230643946000271],
              [-164.601710818999976, 20.230259946000103],
              [-164.605719818999944, 20.230159946000185],
              [-164.623428818999969, 20.229765946000271],
              [-164.641138819999981, 20.229453946000206],
              [-164.658850819999884, 20.229223946000161],
              [-164.676562819999901, 20.229075946000194],
              [-164.694276819999885, 20.229008946000135],
              [-164.700578819999862, 20.229004946000202],
              [-164.712187819999826, 20.229023946000154],
              [-164.729900819999926, 20.22911994600031],
              [-164.747613819999827, 20.229297946000088],
              [-164.765324819999933, 20.229557946000284],
              [-164.78303481999987, 20.22989994600016],
              [-164.800741819999899, 20.230322946000172],
              [-164.818447819999932, 20.23082694600015],
              [-164.836149819999804, 20.231413946000202],
              [-164.85384981999988, 20.232081946000221],
              [-164.871545819999909, 20.232830946000206],
              [-164.889237819999863, 20.233661946000211],
              [-164.906924819999915, 20.234574946000066],
              [-164.92460782, 20.235568946000285],
              [-164.942284819999855, 20.236644946000297],
              [-164.95995681999986, 20.237801946000275],
              [-164.977622819999851, 20.239040946000102],
              [-164.995281819999832, 20.240360946000294],
              [-165.012933819999859, 20.241762946000279],
              [-165.030578819999903, 20.243245946000229],
              [-165.035133819999828, 20.243641946000139],
              [-165.049157819999863, 20.244891946000166],
              [-165.066786819999862, 20.246537946000103],
              [-165.084407819999882, 20.248264946000177],
              [-165.102019819999953, 20.250072946000216],
              [-165.119621819999821, 20.251961946000222],
              [-165.137214819999826, 20.253931946000137],
              [-165.154796819999831, 20.255983946000072],
              [-165.172368819999974, 20.258115946000089],
              [-165.189929819999918, 20.260329946000127],
              [-165.207478819999835, 20.262623946000247],
              [-165.225016819999837, 20.264998946000276],
              [-165.242541819999815, 20.267454946000271],
              [-165.26005481999988, 20.269991946000232],
              [-165.277553819999838, 20.272609946000102],
              [-165.295039819999886, 20.275307946000055],
              [-165.312512819999853, 20.278086946000144],
              [-165.329970819999886, 20.280945946000259],
              [-165.347413819999815, 20.283885946000169],
              [-165.364841819999867, 20.286905946000275],
              [-165.382254819999901, 20.290005946000292],
              [-165.399651819999889, 20.293186946000219],
              [-165.417031819999949, 20.296447946000171],
              [-165.434395819999935, 20.299788946000035],
              [-165.451742819999851, 20.303209946000152],
              [-165.469071819999954, 20.306710946000067],
              [-165.486382819999903, 20.31029094600018],
              [-165.491980819999895, 20.311466946000337],
              [-165.504022819999904, 20.314024946000245],
              [-165.52129681999989, 20.317764946000239],
              [-165.538551819999839, 20.321584946000087],
              [-165.548336319999834, 20.32366844600017],
              [-165.566189819999835, 20.321027946000186],
              [-165.5837138199999, 20.318520946000262],
              [-165.601248819999824, 20.316093946000194],
              [-165.618796819999943, 20.313747946000035],
              [-165.636355819999892, 20.31148294600024],
              [-165.653926819999867, 20.309298946000183],
              [-165.671508819999957, 20.307196946000147],
              [-165.689100819999879, 20.305174946000193],
              [-165.706702820999936, 20.303233946000148],
              [-165.724314820999922, 20.301373946000297],
              [-165.741934820999859, 20.299595946000068],
              [-165.759564820999827, 20.297898946000146],
              [-165.777202820999833, 20.296282946000247],
              [-165.794848820999789, 20.29474794600003],
              [-165.812502820999981, 20.293294946000287],
              [-165.830163820999843, 20.291922946000057],
              [-165.847830820999945, 20.29063194600019],
              [-165.865504820999917, 20.289422946000172],
              [-165.883184820999844, 20.288295946000176],
              [-165.90086982099993, 20.287249946000145],
              [-165.918559820999974, 20.286284946000251],
              [-165.936254820999892, 20.285401946000206],
              [-165.953954820999883, 20.284599946000071],
              [-165.971657820999866, 20.283879946000184],
              [-165.989364820999981, 20.283241946000146],
              [-166.007073820999921, 20.282684946000074],
              [-166.024785820999824, 20.282209946000023],
              [-166.042500820999891, 20.28181694600022],
              [-166.060216820999869, 20.281504946000155],
              [-166.077934820999843, 20.28127494600011],
              [-166.095652820999902, 20.281125946000259],
              [-166.113371820999959, 20.281059946000084],
              [-166.131090820999816, 20.28107394600022],
              [-166.148809820999958, 20.281170946000032],
              [-166.166528820999844, 20.281348946000207],
              [-166.184245820999791, 20.281608946000233],
              [-166.201960820999972, 20.281949946000225],
              [-166.219674820999956, 20.28237294600018],
              [-166.23738582099989, 20.282877946000269],
              [-166.255094820999886, 20.28346394600004],
              [-166.27279982099995, 20.284131946000286],
              [-166.290501820999822, 20.284881946000155],
              [-166.308199820999818, 20.28571294600016],
              [-166.325892820999854, 20.286625946000186],
              [-166.343581820999816, 20.287619946000177],
              [-166.361264820999821, 20.288695946000246],
              [-166.364795820999859, 20.288919946000306],
              [-166.379162820999852, 20.289866946000132],
              [-166.396834820999828, 20.291104946000246],
              [-166.41449982099985, 20.29242494600004],
              [-166.432157820999919, 20.293826946000252],
              [-166.449808820999948, 20.295309946000202],
              [-166.467451820999827, 20.296873946000289],
              [-166.485086820999953, 20.298518946000115],
              [-166.502713820999929, 20.300245946000189],
              [-166.520330820999789, 20.302053946000228],
              [-166.537939820999895, 20.303942946000234],
              [-166.555537820999973, 20.305912946000149],
              [-166.573125820999934, 20.307964946000084],
              [-166.5902728209999, 20.310043946000178],
              [-166.600424820999933, 20.311284946000171],
              [-166.617990820999836, 20.313497946000155],
              [-166.635546820999906, 20.315792946000158],
              [-166.653089820999867, 20.318167946000187],
              [-166.670621820999912, 20.320623946000183],
              [-166.688139820999851, 20.323159946000033],
              [-166.705645820999848, 20.325777946000301],
              [-166.723137820999852, 20.328475946000253],
              [-166.740616820999946, 20.331253946000231],
              [-166.758079820999853, 20.334112946000175],
              [-166.775529821999953, 20.337052946000256],
              [-166.792963821999962, 20.340072946000191],
              [-166.810382821999866, 20.343172946000152],
              [-166.827785821999981, 20.346353946000136],
              [-166.845171821999827, 20.349613946000204],
              [-166.862541821999883, 20.352954946000239],
              [-166.879893821999929, 20.356375946000128],
              [-166.897228821999818, 20.359875946000159],
              [-166.914545821999923, 20.363456946000156],
              [-166.931844821999846, 20.367116946000124],
              [-166.949124821999817, 20.370856946000174],
              [-166.966385821999893, 20.374675946000139],
              [-166.976390821999843, 20.376928946000191],
              [-166.992681821999867, 20.380614946000151],
              [-167.009903821999927, 20.384592946000168],
              [-167.027104821999927, 20.388650946000212],
              [-167.044284821999867, 20.392787946000169],
              [-167.061443821999859, 20.397003946000154],
              [-167.078581821999876, 20.401298946000054],
              [-167.095697821999863, 20.405672946000323],
              [-167.112790821999909, 20.410125946000164],
              [-167.129860821999898, 20.414656946000036],
              [-167.146907821999861, 20.419266946000278],
              [-167.162995821999885, 20.423695946000294],
              [-167.177317821999964, 20.427573946000166],
              [-167.194341821999984, 20.432262946000151],
              [-167.211342821999949, 20.437029946000052],
              [-167.228318821999892, 20.441874946000212],
              [-167.245269821999898, 20.446797946000231],
              [-167.262195821999882, 20.451798947000214],
              [-167.279096821999843, 20.456878947000234],
              [-167.295971821999871, 20.462035947000231],
              [-167.312819821999881, 20.467270947000145],
              [-167.329640821999874, 20.472582947000149],
              [-167.346434821999964, 20.477972947000296],
              [-167.363201821999837, 20.483439947000193],
              [-167.379940821999895, 20.488983947000236],
              [-167.396650821999856, 20.494604947000084],
              [-167.41333282199983, 20.500302947000307],
              [-167.429984821999966, 20.506077947000279],
              [-167.446607821999919, 20.511929947000226],
              [-167.463200821999976, 20.517857947000266],
              [-167.479763821999853, 20.523862947000282],
              [-167.496295821999922, 20.529942947000279],
              [-167.512795821999845, 20.536099947000253],
              [-167.529265821999928, 20.542332947000091],
              [-167.539892821999899, 20.546401947000163],
              [-167.546967821999885, 20.549127947000102],
              [-167.563372821999849, 20.555511947000241],
              [-167.579745821999808, 20.561971947000188],
              [-167.596084821999909, 20.568506947000174],
              [-167.612390821999838, 20.575116947000197],
              [-167.628663821999879, 20.581801947000145],
              [-167.644900821999869, 20.588561947000073],
              [-167.6611048219998, 20.595396947000211],
              [-167.677272821999964, 20.602306947000329],
              [-167.693405821999875, 20.609290947000147],
              [-167.709503821999846, 20.616348947000233],
              [-167.725564821999825, 20.623480947000246],
              [-167.741589821999867, 20.630686947000129],
              [-167.757576821999891, 20.637965947000225],
              [-167.773527821999835, 20.645319947000303],
              [-167.789440821999847, 20.652745947000085],
              [-167.805315821999926, 20.660245947000192],
              [-167.821151821999962, 20.667818947000058],
              [-167.836949821999951, 20.675464947000137],
              [-167.852707822999832, 20.683182947000148],
              [-167.868426822999965, 20.690973947000316],
              [-167.884105822999885, 20.698836947000189],
              [-167.899744822999878, 20.70677194700022],
              [-167.915342822999861, 20.714779947000181],
              [-167.930899822999891, 20.722858947000248],
              [-167.946415822999825, 20.731008947000248],
              [-167.961889822999836, 20.739230947000181],
              [-167.977321822999841, 20.747523947000161],
              [-167.99271082299984, 20.755887947000303],
              [-168.008056822999833, 20.764322947000096],
              [-168.02336082299982, 20.772827947000224],
              [-168.038619822999806, 20.781403947000115],
              [-168.053835822999901, 20.790049947000171],
              [-168.069006822999881, 20.798765947000334],
              [-168.084133822999974, 20.807551947000206],
              [-168.099214822999869, 20.816406947000303],
              [-168.114250822999793, 20.825330947000054],
              [-168.129240822999918, 20.834324947000141],
              [-168.144184822999961, 20.843386947000283],
              [-168.15908282299992, 20.852518947000192],
              [-168.173933822999828, 20.861717947000159],
              [-168.188736822999914, 20.870986947000233],
              [-168.203492822999948, 20.880322947000138],
              [-168.218200822999904, 20.889726947000213],
              [-168.232860822999982, 20.899197947000289],
              [-168.247471822999898, 20.908736947000136],
              [-168.262033822999882, 20.918342947000212],
              [-168.276546822999961, 20.928015947000119],
              [-168.291009822999797, 20.937755947000255],
              [-168.305422822999873, 20.947561947000167],
              [-168.319784822999821, 20.957433947000027],
              [-168.334096822999868, 20.967372947000115],
              [-168.348357822999986, 20.977376947000266],
              [-168.362566822999952, 20.987446947000137],
              [-168.376723822999878, 20.997581947000299],
              [-168.390829822999962, 21.007781947000296],
              [-168.404882822999895, 21.018046947000187],
              [-168.418882822999819, 21.0283759470002],
              [-168.43282882299988, 21.038769947000276],
              [-168.446722822999817, 21.049227947000304],
              [-168.460561822999836, 21.05974894700023],
              [-168.474347822999874, 21.070334947000163],
              [-168.488078822999825, 21.080982947000109],
              [-168.501754822999942, 21.091694947000235],
              [-168.515375822999943, 21.102469947000202],
              [-168.528940822999829, 21.113306947000126],
              [-168.542449822999885, 21.124205947000064],
              [-168.555903822999824, 21.135166947000187],
              [-168.56930082299985, 21.14619094700015],
              [-168.582640822999878, 21.157274947000076],
              [-168.595923822999879, 21.168420947000186],
              [-168.609148822999884, 21.17962894700014],
              [-168.622315822999894, 21.190895947000115],
              [-168.635425822999878, 21.202223947000221],
              [-168.648476822999982, 21.213612947000172],
              [-168.661468822999979, 21.225060947000259],
              [-168.674401822999982, 21.236568947000137],
              [-168.687275822999908, 21.248136947000148],
              [-168.700089822999814, 21.259762947000183],
              [-168.712842822999846, 21.271448947000238],
              [-168.725536822999885, 21.283191947000205],
              [-168.738169822999851, 21.294994947000305],
              [-168.750741822999828, 21.306854947000147],
              [-168.76325182299982, 21.318772947000184],
              [-168.775700822999937, 21.330747947000191],
              [-168.78808782299987, 21.342779947000054],
              [-168.800412822999846, 21.354869947000111],
              [-168.812674822999838, 21.367015947000198],
              [-168.824873822999962, 21.379217947000143],
              [-168.837009822999931, 21.391475947000231],
              [-168.849082822999918, 21.403789947000178],
              [-168.861091822999839, 21.416158947000042],
              [-168.873036822999893, 21.428582947000223],
              [-168.884916822999912, 21.441061947000151],
              [-168.896732822999866, 21.453595947000167],
              [-168.908483822999955, 21.466183947000047],
              [-168.920169823999885, 21.478825947000132],
              [-168.931789823999878, 21.49152094700014],
              [-168.943343823999925, 21.504269947000239],
              [-168.954831823999911, 21.517071947000034],
              [-168.966253823999949, 21.529925948000198],
              [-168.977608823999873, 21.542832948000182],
              [-168.988896823999823, 21.555791948000206],
              [-168.991788823999826, 21.55913294800024],
              [-169.000537823999963, 21.569286948000126],
              [-169.011690823999942, 21.582349948000058],
              [-169.022775823999893, 21.595462948000034],
              [-169.033792823999818, 21.608626948000165],
              [-169.044741823999885, 21.621841948000224],
              [-169.055621823999843, 21.635105948000273],
              [-169.066433823999859, 21.648420948000251],
              [-169.077174823999968, 21.661784948000218],
              [-169.087847823999851, 21.675198948000229],
              [-169.098449823999886, 21.68866094800029],
              [-169.108982823999924, 21.702171948000171],
              [-169.119444823999828, 21.715731948000155],
              [-169.129836823999824, 21.729338948000191],
              [-169.140157823999886, 21.742993948000219],
              [-169.150406823999958, 21.756695948000072],
              [-169.160585823999924, 21.77044494800009],
              [-169.17069182399996, 21.784240948000274],
              [-169.18072682399989, 21.798082948000172],
              [-169.190688823999835, 21.811970948000351],
              [-169.200578823999962, 21.825904948000186],
              [-169.202338823999838, 21.828399948000026],
              [-169.204938823999925, 21.831743948000167],
              [-169.215412823999856, 21.845301948000156],
              [-169.21704282399989, 21.847434948000284],
              [-169.221973823999861, 21.849049948000129],
              [-169.238840823999851, 21.854661948000114],
              [-169.25567882399983, 21.860349948000191],
              [-169.27248782399991, 21.866115948000356],
              [-169.289266823999895, 21.871956948000275],
              [-169.306015823999985, 21.87787594800028],
              [-169.32273482399998, 21.883869948000267],
              [-169.339422823999911, 21.88994094800023],
              [-169.356078823999837, 21.896086948000175],
              [-169.372703823999871, 21.902309948000095],
              [-169.389296823999928, 21.908607948000167],
              [-169.40585782399981, 21.914980948000277],
              [-169.42238482399992, 21.921430948000136],
              [-169.438879823999855, 21.92795494800032],
              [-169.455340823999819, 21.934553948000257],
              [-169.471767823999812, 21.941227948000176],
              [-169.488159823999951, 21.947976948000303],
              [-169.504517823999919, 21.954800948000184],
              [-169.520839823999836, 21.961698948000219],
              [-169.537126823999898, 21.968671948000178],
              [-169.553378823999878, 21.975717948000181],
              [-169.569592823999841, 21.982838948000165],
              [-169.585771823999835, 21.990032948000135],
              [-169.601912823999982, 21.997300948000202],
              [-169.618015823999883, 22.004641948000256],
              [-169.634081823999878, 22.012056948000236],
              [-169.650109823999884, 22.019544948000203],
              [-169.666098823999874, 22.027104948000272],
              [-169.682048823999935, 22.03473894800004],
              [-169.697959823999867, 22.042444948000309],
              [-169.713830823999871, 22.050222948000282],
              [-169.729661823999862, 22.058073948000242],
              [-169.745451823999986, 22.065996948000134],
              [-169.761201823999926, 22.073990948000244],
              [-169.776909823999944, 22.08205794800017],
              [-169.792576823999838, 22.090194948000146],
              [-169.808200823999925, 22.098403948000282],
              [-169.823783823999889, 22.106683948000239],
              [-169.839323823999962, 22.115034948000186],
              [-169.854819823999833, 22.123456948000182],
              [-169.870272823999983, 22.131948948000058],
              [-169.885682823999844, 22.140510948000269],
              [-169.901047823999818, 22.149143948000301],
              [-169.916367823999877, 22.157845948000158],
              [-169.931643823999849, 22.166617948000123],
              [-169.946873823999937, 22.175459948000025],
              [-169.962058823999854, 22.184370948000151],
              [-169.977197823999944, 22.193350948000102],
              [-169.992289823999869, 22.202398948000166],
              [-170.006541824999857, 22.211035948000188],
              [-170.015812824999927, 22.209556948000227],
              [-170.033520824999954, 22.206813948000274],
              [-170.051242824999974, 22.204151948000288],
              [-170.068977824999848, 22.201569948000156],
              [-170.086725824999888, 22.199068948000161],
              [-170.104485824999955, 22.196647948000248],
              [-170.122258824999847, 22.194307948000244],
              [-170.14004382499985, 22.192047948000152],
              [-170.157839824999883, 22.189869948000023],
              [-170.175645824999862, 22.187771948000204],
              [-170.193462824999926, 22.185754948000294],
              [-170.211290824999935, 22.183818948000123],
              [-170.229127824999978, 22.181963948000146],
              [-170.246973824999969, 22.180189948000248],
              [-170.264828824999796, 22.178496948000145],
              [-170.282692824999856, 22.176884948000179],
              [-170.30056382499987, 22.175353948000179],
              [-170.318443824999832, 22.173904948000143],
              [-170.336329824999837, 22.172535948000245],
              [-170.354222824999908, 22.171248948000141],
              [-170.372122824999821, 22.170041948000289],
              [-170.390027824999805, 22.168917948000171],
              [-170.407938824999945, 22.167873948000135],
              [-170.425855824999843, 22.16691194800012],
              [-170.443775824999847, 22.166030948000241],
              [-170.461701824999864, 22.165230948000328],
              [-170.479630824999987, 22.164512948000038],
              [-170.497562824999875, 22.163876948000166],
              [-170.515498824999952, 22.163320948000205],
              [-170.53343682499991, 22.162846948000038],
              [-170.551376824999949, 22.162454948000118],
              [-170.569318824999897, 22.162143948000164],
              [-170.587262824999897, 22.161913948000119],
              [-170.605207824999809, 22.161765948000152],
              [-170.62315282499992, 22.161698948000094],
              [-170.629483824999824, 22.161694948000104],
              [-170.641359824999853, 22.161713948000283],
              [-170.65930482499985, 22.161809948000041],
              [-170.677248824999964, 22.161987948000217],
              [-170.695192824999879, 22.162246948000131],
              [-170.713133824999915, 22.162587948000294],
              [-170.731073824999953, 22.163009948000024],
              [-170.749010824999829, 22.163512948000289],
              [-170.766944824999911, 22.164097948000176],
              [-170.784876824999827, 22.164764948000141],
              [-170.802803824999842, 22.165511948000301],
              [-170.820727824999977, 22.166340948000141],
              [-170.83864682499987, 22.167251948000171],
              [-170.856561824999886, 22.168242948000113],
              [-170.874470824999861, 22.169315948000076],
              [-170.892373824999936, 22.170470948000059],
              [-170.910271824999967, 22.171705948000294],
              [-170.928161824999876, 22.173022948000209],
              [-170.946045824999885, 22.174420948000034],
              [-170.963922824999855, 22.175899948000222],
              [-170.98179082499982, 22.177460948000032],
              [-170.999651824999972, 22.179101948000095],
              [-171.01750382499992, 22.180824948000236],
              [-171.035346824999834, 22.182627948000174],
              [-171.05317982499983, 22.18451294800019],
              [-171.071003825999838, 22.186477948000231],
              [-171.088817825999854, 22.188523948000238],
              [-171.106620825999926, 22.190650948000155],
              [-171.124411825999886, 22.192858948000264],
              [-171.142192825999842, 22.195147948000113],
              [-171.159960825999946, 22.197516948000214],
              [-171.177716825999852, 22.199966948000224],
              [-171.195459825999933, 22.20249794800003],
              [-171.213190825999817, 22.205108948000259],
              [-171.230906825999881, 22.207799948000343],
              [-171.248609825999949, 22.210571948000165],
              [-171.266297825999828, 22.213423948000241],
              [-171.283971825999885, 22.216355948000171],
              [-171.301629825999953, 22.219368948000067],
              [-171.319272825999832, 22.222461948000216],
              [-171.336899825999836, 22.22563494800022],
              [-171.354509825999827, 22.228886948000138],
              [-171.372103825999829, 22.23221994800025],
              [-171.389679825999877, 22.235631948000275],
              [-171.407237825999829, 22.239124948000097],
              [-171.424778825999795, 22.242695948000176],
              [-171.442300825999894, 22.246347948000164],
              [-171.459803825999927, 22.250078948000123],
              [-171.477287825999895, 22.253888948000281],
              [-171.494751825999884, 22.257778948000237],
              [-171.512196825999922, 22.261747948000163],
              [-171.529619825999902, 22.265795948000289],
              [-171.547022825999846, 22.269921948000274],
              [-171.564403825999904, 22.274127948000285],
              [-171.5817638259999, 22.278412948000266],
              [-171.599101825999867, 22.282776948000219],
              [-171.616416825999977, 22.287218948000202],
              [-171.633708825999861, 22.291738948000273],
              [-171.650977825999888, 22.296338948000198],
              [-171.668222825999891, 22.301015948000099],
              [-171.685442825999871, 22.305771948000142],
              [-171.702639825999796, 22.310604948000162],
              [-171.7198108259999, 22.315516948000152],
              [-171.736956825999869, 22.320506948000229],
              [-171.754077825999929, 22.325573948000056],
              [-171.771171825999858, 22.33071894800014],
              [-171.788238825999969, 22.335941948000141],
              [-171.805279825999861, 22.341241948000231],
              [-171.822292825999853, 22.346618948000355],
              [-171.839278825999827, 22.352072948000227],
              [-171.856235825999903, 22.357603948000076],
              [-171.873164825999851, 22.363212948000182],
              [-171.890064825999815, 22.36889794800021],
              [-171.906935825999966, 22.374658948000274],
              [-171.923776825999937, 22.380496948000143],
              [-171.940586825999901, 22.386411948000159],
              [-171.957366825999884, 22.392401948000156],
              [-171.974116825999971, 22.398468948000186],
              [-171.990834825999883, 22.404611948000024],
              [-172.007520825999819, 22.410829948000071],
              [-172.02417482599995, 22.417124948000094],
              [-172.039994825999912, 22.423186948000023],
              [-172.056534825999933, 22.424476948000176],
              [-172.074442825999881, 22.425955948000194],
              [-172.092343825999905, 22.427515948000291],
              [-172.110235825999865, 22.429156948000184],
              [-172.128119825999903, 22.430877948000102],
              [-172.145994826999896, 22.432680948000268],
              [-172.163860826999837, 22.434564948000173],
              [-172.181715826999863, 22.436529948000214],
              [-172.19956182699994, 22.438574948000337],
              [-172.21739582699982, 22.440701948000083],
              [-172.235219826999952, 22.442908948000081],
              [-172.253031826999887, 22.445196948000216],
              [-172.270831826999967, 22.447564948000206],
              [-172.288619826999849, 22.450013948000162],
              [-172.306394826999934, 22.452543948000255],
              [-172.324156826999882, 22.455153948000202],
              [-172.341904826999865, 22.457844948000286],
              [-172.359639826999882, 22.460615948000225],
              [-172.377359826999935, 22.463466948000189],
              [-172.395064826999885, 22.466397948000235],
              [-172.412754826999844, 22.469409948000248],
              [-172.430429826999898, 22.472501948000286],
              [-172.448087826999966, 22.475673948000178],
              [-172.465729826999876, 22.478924948000042],
              [-172.483354826999857, 22.482256948000042],
              [-172.500962826999967, 22.485667948000184],
              [-172.518552826999809, 22.489158948000181],
              [-172.536124826999867, 22.49272994800026],
              [-172.553678826999857, 22.496379948000083],
              [-172.571213826999866, 22.500109948000159],
              [-172.588728826999926, 22.503918948000205],
              [-172.606224826999892, 22.507806948000223],
              [-172.623700826999823, 22.511774948000266],
              [-172.641155826999977, 22.515820948000169],
              [-172.658589826999986, 22.519946948000154],
              [-172.676002826999934, 22.524151948000053],
              [-172.693393826999937, 22.528434948000267],
              [-172.710762826999883, 22.532796948000225],
              [-172.728109826999884, 22.537237948000325],
              [-172.74543282699986, 22.541756948000284],
              [-172.762732826999894, 22.546354948000044],
              [-172.780008826999961, 22.551030948000232],
              [-172.797261826999915, 22.55578494800011],
              [-172.814488826999849, 22.560616948000245],
              [-172.831691826999815, 22.56552694800024],
              [-172.848868826999876, 22.570514948000323],
              [-172.866020826999915, 22.575580948000265],
              [-172.883145826999936, 22.580723948000184],
              [-172.900244826999852, 22.585944948000133],
              [-172.917316826999837, 22.591242948000286],
              [-172.934360826999892, 22.596618948000298],
              [-172.951377826999845, 22.602070949000279],
              [-172.9683658269999, 22.607600949000243],
              [-172.985325826999855, 22.6132069490003],
              [-173.002256826999911, 22.618889949000106],
              [-173.01915882699987, 22.624649949000286],
              [-173.036029826999908, 22.630486949000044],
              [-173.052871826999962, 22.636398949000181],
              [-173.069682826999838, 22.642387949000295],
              [-173.086462826999821, 22.648452949000102],
              [-173.10321182699991, 22.654593949000173],
              [-173.119928826999825, 22.660809949000054],
              [-173.136613826999849, 22.667101949000198],
              [-173.153266826999896, 22.673469949000037],
              [-173.169885826999888, 22.679912949000254],
              [-173.186472826999875, 22.686430949000055],
              [-173.203024826999837, 22.693023949000235],
              [-173.219543827999985, 22.699691949000226],
              [-173.236027827999891, 22.70643494900014],
              [-173.252476827999942, 22.71325194900021],
              [-173.268890827999826, 22.72014394900026],
              [-173.285268827999857, 22.72710994900018],
              [-173.301610827999838, 22.734149949000027],
              [-173.317916827999881, 22.741263949000142],
              [-173.334185827999846, 22.748450949000073],
              [-173.345569827999839, 22.753543949000118],
              [-173.359491827999875, 22.751202949000231],
              [-173.377239827999944, 22.748301949000165],
              [-173.395001827999891, 22.745480949000182],
              [-173.412777827999861, 22.742739949000054],
              [-173.430568827999849, 22.740078949000178],
              [-173.448371827999807, 22.737499949000096],
              [-173.466188827999986, 22.734999949000212],
              [-173.484017827999907, 22.732580949000067],
              [-173.501859827999823, 22.730242949000058],
              [-173.519712827999854, 22.727984949000302],
              [-173.537576827999914, 22.725807949000284],
              [-173.555452827999886, 22.723711949000233],
              [-173.573338827999805, 22.721695949000036],
              [-173.591234827999955, 22.719761949000258],
              [-173.609140827999823, 22.717907949000164],
              [-173.62705582799984, 22.716134949000207],
              [-173.64497982799989, 22.714442949000158],
              [-173.662911827999892, 22.712831949000304],
              [-173.680852827999928, 22.711302949000242],
              [-173.698800827999918, 22.709853949000262],
              [-173.716755827999862, 22.708485949000249],
              [-173.734718827999842, 22.707199949000028],
              [-173.752686827999895, 22.705994949000171],
              [-173.770661827999902, 22.704870949000281],
              [-173.788641827999868, 22.703827949000299],
              [-173.806626827999906, 22.702865949000284],
              [-173.824616827999819, 22.701985949000289],
              [-173.842610827999891, 22.70118694900026],
              [-173.860608827999926, 22.700468949000197],
              [-173.878609827999952, 22.699832949000154],
              [-173.896614827999855, 22.699277949000248],
              [-173.914621827999838, 22.698804949000191],
              [-173.932630827999901, 22.69841194900016],
              [-173.950642827999872, 22.69810194900009],
              [-173.968654827999899, 22.697871949000273],
              [-173.986668827999864, 22.697723949000078],
              [-174.004682827999886, 22.697656949000248],
              [-174.022696827999823, 22.697671949000039],
              [-174.040710827999959, 22.697767949000195],
              [-174.058723827999899, 22.697945949000143],
              [-174.076735827999954, 22.698204949000285],
              [-174.094746827999899, 22.698544949000166],
              [-174.112755827999877, 22.698966949000067],
              [-174.130761827999862, 22.699469949000331],
              [-174.148764827999884, 22.700054949000219],
              [-174.16676582799991, 22.700719949000188],
              [-174.184761827999864, 22.701467949000062],
              [-174.202754827999854, 22.702295949000188],
              [-174.220742827999857, 22.703205949000164],
              [-174.2387258279999, 22.704196949000277],
              [-174.256704827999982, 22.705268949000128],
              [-174.274676827999826, 22.706422949000057],
              [-174.292642828999874, 22.707656949000182],
              [-174.31060282899989, 22.708972949000213],
              [-174.328555828999839, 22.710369949000153],
              [-174.34650082899995, 22.71184794900023],
              [-174.364438828999909, 22.713407949000157],
              [-174.382367828999833, 22.715047949000336],
              [-174.400288828999891, 22.716768949000254],
              [-174.418200828999829, 22.718570949000139],
              [-174.436102828999935, 22.720453949000159],
              [-174.453995828999894, 22.722417949000089],
              [-174.471877828999851, 22.724462949000213],
              [-174.489749828999948, 22.726588949000245],
              [-174.507609828999847, 22.728794949000189],
              [-174.525458828999916, 22.731081949000213],
              [-174.543295828999959, 22.733449949000203],
              [-174.56112082899989, 22.735897949000275],
              [-174.57893282899991, 22.738426949000257],
              [-174.596731828999822, 22.741035949000093],
              [-174.614516828999825, 22.743724949000182],
              [-174.632287828999921, 22.746494949000237],
              [-174.650044828999825, 22.749344949000147],
              [-174.667786828999823, 22.752275949000193],
              [-174.685513828999916, 22.755285949000211],
              [-174.703224828999822, 22.758376949000137],
              [-174.720919828999939, 22.761546949000262],
              [-174.738598828999869, 22.764797949000297],
              [-174.756260828999814, 22.768127949000302],
              [-174.773905828999972, 22.771537949000162],
              [-174.791532828999863, 22.775027949000105],
              [-174.809140828999972, 22.778596949000189],
              [-174.826731828999897, 22.782245949000298],
              [-174.844302828999872, 22.785973949000208],
              [-174.861854828999867, 22.789781949000144],
              [-174.87938782899991, 22.79366894900005],
              [-174.896899828999977, 22.797634949000326],
              [-174.914390828999984, 22.801679949000345],
              [-174.931861828999843, 22.805803949000108],
              [-174.949310828999842, 22.810006949000069],
              [-174.966738828999894, 22.814288949000172],
              [-174.984144828999888, 22.818648949000305],
              [-175.001526828999857, 22.823087949000239],
              [-175.018886828999968, 22.827605949000144],
              [-175.036223828999852, 22.83220194900008],
              [-175.053536828999967, 22.836875949000103],
              [-175.070824828999974, 22.841627949000213],
              [-175.088088828999844, 22.846458949000237],
              [-175.105327828999975, 22.851366949000237],
              [-175.122541828999886, 22.856352949000154],
              [-175.139728828999893, 22.861416949000272],
              [-175.156890828999849, 22.866558949000307],
              [-175.174025828999902, 22.871777949000091],
              [-175.191133828999853, 22.877073949000248],
              [-175.208213828999874, 22.882446949000155],
              [-175.225266828999878, 22.887897949000205],
              [-175.242291828999981, 22.893425949000175],
              [-175.259286828999819, 22.89902994900018],
              [-175.276253828999899, 22.904710949000048],
              [-175.293191828999824, 22.910468949000233],
              [-175.310099828999824, 22.916302949000112],
              [-175.326976828999818, 22.922212949000084],
              [-175.343823828999945, 22.928199949000202],
              [-175.360639828999894, 22.934262949000242],
              [-175.377424829999825, 22.940400949000036],
              [-175.394177829999791, 22.946615949000204],
              [-175.410898829999866, 22.952904949000072],
              [-175.427586829999882, 22.959270949000313],
              [-175.444241829999925, 22.965711949000138],
              [-175.46086382999988, 22.97222694900006],
              [-175.477451829999978, 22.978817949000245],
              [-175.494005829999907, 22.985483949000241],
              [-175.510524829999866, 22.992223949000106],
              [-175.527009829999969, 22.99903894900018],
              [-175.543458829999821, 23.005927949000352],
              [-175.559872829999904, 23.012891949000277],
              [-175.57625082999985, 23.019928949000246],
              [-175.592591829999918, 23.027040949000195],
              [-175.608895829999852, 23.034225949000302],
              [-175.625162829999852, 23.041484949000164],
              [-175.641392829999887, 23.048816949000184],
              [-175.657583829999822, 23.056221949000189],
              [-175.673737829999965, 23.063699949000238],
              [-175.689851829999895, 23.071251949000157],
              [-175.705926829999896, 23.078874949000294],
              [-175.721962829999882, 23.086571949000131],
              [-175.737958829999826, 23.09434094900007],
              [-175.753914829999843, 23.102180949000228],
              [-175.76982982999985, 23.110093949000202],
              [-175.785703829999846, 23.118078949000051],
              [-175.801535829999892, 23.126134949000118],
              [-175.817326829999843, 23.134262949000288],
              [-175.833075829999871, 23.142461949000278],
              [-175.848781829999893, 23.150730949000206],
              [-175.864444829999826, 23.159071949000236],
              [-175.880064829999952, 23.167482949000203],
              [-175.89564183, 23.175964949000161],
              [-175.911173829999882, 23.184516949000226],
              [-175.926661829999802, 23.193138949000229],
              [-175.942104829999835, 23.201830949000168],
              [-175.957502829999868, 23.210591949000104],
              [-175.972855829999901, 23.219422949000204],
              [-175.988161829999882, 23.228322949000074],
              [-176.003422829999863, 23.237292949000107],
              [-176.018636829999849, 23.246329949000142],
              [-176.033803829999869, 23.25543694900017],
              [-176.048922829999839, 23.264611949000255],
              [-176.063994829999984, 23.273854949000111],
              [-176.079018829999882, 23.283165949000306],
              [-176.0939948299999, 23.292543949000162],
              [-176.108921829999872, 23.301990949000185],
              [-176.12379883, 23.311503949000212],
              [-176.138626829999907, 23.321084949000351],
              [-176.153405829999883, 23.33073194900004],
              [-176.168133829999903, 23.340445949000184],
              [-176.182811829999963, 23.350225949000048],
              [-176.197437829999984, 23.360072949000141],
              [-176.212013829999847, 23.369984949000127],
              [-176.226536829999873, 23.37996294900006],
              [-176.241008829999942, 23.390006949000167],
              [-176.255428829999886, 23.400115949000281],
              [-176.269795829999879, 23.410288949000176],
              [-176.28410983, 23.420527949000189],
              [-176.298369829999842, 23.43083094900021],
              [-176.312576829999841, 23.441197949000184],
              [-176.326729829999891, 23.45162894900028],
              [-176.340828829999822, 23.462123949000159],
              [-176.354871829999922, 23.472681949000332],
              [-176.368860829999903, 23.483303949000231],
              [-176.382793829999855, 23.493988949000027],
              [-176.396671829999889, 23.504735949000178],
              [-176.410492829999839, 23.515545949000227],
              [-176.424257829999846, 23.526417949000233],
              [-176.437966830999841, 23.537352949000137],
              [-176.451617830999822, 23.548348949000058],
              [-176.465210830999837, 23.559405949000109],
              [-176.478746830999881, 23.570524949000117],
              [-176.492224830999874, 23.581703949000143],
              [-176.505644830999842, 23.592944949000298],
              [-176.519004830999819, 23.60424494900019],
              [-176.532306830999801, 23.615605949000155],
              [-176.545548830999792, 23.62702694900031],
              [-176.558731830999875, 23.638506949000146],
              [-176.571853830999885, 23.650046949000171],
              [-176.584915830999819, 23.661645949000103],
              [-176.59791683099985, 23.673303950000047],
              [-176.610857830999805, 23.685019950000083],
              [-176.623736830999974, 23.696793950000142],
              [-176.636553830999873, 23.708626950000053],
              [-176.6493088309999, 23.720516950000274],
              [-176.662001830999856, 23.732463950000238],
              [-176.674631830999829, 23.744468950000169],
              [-176.687199830999845, 23.756529950000186],
              [-176.69970383099988, 23.768647950000229],
              [-176.71214383099985, 23.780822950000243],
              [-176.72452083099995, 23.793052950000174],
              [-176.736833830999899, 23.805338950000078],
              [-176.749081830999984, 23.817679950000127],
              [-176.761264830999835, 23.830076950000205],
              [-176.773382830999822, 23.842527950000317],
              [-176.78543583099983, 23.85503395000012],
              [-176.797422830999921, 23.867593950000185],
              [-176.809343830999836, 23.880207950000283],
              [-176.821198830999833, 23.892875950000189],
              [-176.832986830999857, 23.905595950000361],
              [-176.844707830999852, 23.918369950000169],
              [-176.856361830999901, 23.931196950000071],
              [-176.867948830999836, 23.94407595000024],
              [-176.879466830999917, 23.957006950000277],
              [-176.890917830999854, 23.969989950000127],
              [-176.90229983099988, 23.983024950000242],
              [-176.913613830999878, 23.996110950000286],
              [-176.924858830999824, 24.009246950000204],
              [-176.936033830999946, 24.022433950000277],
              [-176.947139830999873, 24.035670950000224],
              [-176.958175830999835, 24.048958950000269],
              [-176.969141830999803, 24.062295950000305],
              [-176.980037830999862, 24.07568195000033],
              [-176.990862830999873, 24.089116950000289],
              [-177.001616830999893, 24.102600950000237],
              [-177.012299830999893, 24.116132950000235],
              [-177.022910830999876, 24.129712950000112],
              [-177.033450830999868, 24.143340950000265],
              [-177.043917830999845, 24.157015950000186],
              [-177.054313830999803, 24.170737950000273],
              [-177.06463583099989, 24.184506950000184],
              [-177.074885830999847, 24.198322950000033],
              [-177.085062830999902, 24.212183950000281],
              [-177.095165830999861, 24.226090950000241],
              [-177.105195830999833, 24.240043950000256],
              [-177.115151830999821, 24.25404095000016],
              [-177.125033830999882, 24.268083950000175],
              [-177.134840830999877, 24.282169950000196],
              [-177.144573830999889, 24.296300950000216],
              [-177.15423183099989, 24.310474950000241],
              [-177.163813830999942, 24.324692950000156],
              [-177.173320830999984, 24.338953950000246],
              [-177.182752830999846, 24.35325795000017],
              [-177.19210783099993, 24.367603950000216],
              [-177.201387830999835, 24.381990950000272],
              [-177.210590830999962, 24.396420950000334],
              [-177.219716830999914, 24.410891950000178],
              [-177.22876583099989, 24.425403950000316],
              [-177.23773783099989, 24.439955950000126],
              [-177.246632830999857, 24.454548950000174],
              [-177.255449830999908, 24.469180950000066],
              [-177.264188830999899, 24.483852950000255],
              [-177.272849830999945, 24.498564950000173],
              [-177.281432830999819, 24.513314950000279],
              [-177.289936830999835, 24.528103950000173],
              [-177.298362830999963, 24.542930950000027],
              [-177.306708830999867, 24.5577949500003],
              [-177.314975830999884, 24.572697950000247],
              [-177.32316383099996, 24.587636950000103],
              [-177.331271830999896, 24.602612950000037],
              [-177.339298830999866, 24.617624950000106],
              [-177.347246830999865, 24.632672950000142],
              [-177.355114830999838, 24.647756950000144],
              [-177.362500830999892, 24.662099950000083],
              [-177.368072830999864, 24.664154950000125],
              [-177.369122830999885, 24.664540950000287],
              [-177.386070830999813, 24.67081695000013],
              [-177.402985830999967, 24.677167950000182],
              [-177.419866830999865, 24.683594950000099],
              [-177.436715830999873, 24.690095950000114],
              [-177.453529830999827, 24.69667295000022],
              [-177.470309830999895, 24.703323950000197],
              [-177.487054830999824, 24.710049950000155],
              [-177.503763830999986, 24.716849950000267],
              [-177.520438831999883, 24.723723950000192],
              [-177.53707683199994, 24.730672950000155],
              [-177.553678831999832, 24.737694950000105],
              [-177.570244831999872, 24.744790950000151],
              [-177.58677283199998, 24.751960951000171],
              [-177.60326383199984, 24.759203951000131],
              [-177.619716831999966, 24.766519951000078],
              [-177.63613083199985, 24.773909951000292],
              [-177.652506831999887, 24.781371951000267],
              [-177.668843831999908, 24.788906951000172],
              [-177.685141831999886, 24.79651495100012],
              [-177.701399831999936, 24.80419495100017],
              [-177.717616831999862, 24.811947951000036],
              [-177.733793831999975, 24.819771951000291],
              [-177.749929831999879, 24.827667951000251],
              [-177.766024831999829, 24.835635951000143],
              [-177.782077831999885, 24.843675951000137],
              [-177.798088831999934, 24.851785951000238],
              [-177.814056831999864, 24.859967951000044],
              [-177.829982831999985, 24.868220951000069],
              [-177.836912831999967, 24.871851951000338],
              [-177.850335831999843, 24.873296951000157],
              [-177.868515831999929, 24.875335951000295],
              [-177.886684831999901, 24.877455951000172],
              [-177.904842831999957, 24.879654951000248],
              [-177.922988831999902, 24.881935951000116],
              [-177.941122831999905, 24.884295951000183],
              [-177.959244831999825, 24.886736951000159],
              [-177.971957831999902, 24.888498951000145],
              [-177.978335831999885, 24.889395951000267],
              [-177.996431831999843, 24.891997951000292],
              [-178.014513831999835, 24.89467895100023],
              [-178.032581831999977, 24.897440951000135],
              [-178.050634831999872, 24.900282951000293],
              [-178.068673831999831, 24.903204951000305],
              [-178.086696831999888, 24.906206951000172],
              [-178.104703831999871, 24.909287951000181],
              [-178.122694831999866, 24.912449951000156],
              [-178.140669831999872, 24.915690951000272],
              [-178.158627831999894, 24.919010951000132],
              [-178.160064831999961, 24.919280951000303],
              [-178.165779831999856, 24.920345951000286],
              [-178.183719831999895, 24.923746951000339],
              [-178.201642831999862, 24.927225951000025],
              [-178.21954683199985, 24.930784951000192],
              [-178.237432831999882, 24.934423951000156],
              [-178.255298831999824, 24.938140951000037],
              [-178.273145831999898, 24.94193795100017],
              [-178.290973831999906, 24.945813951000048],
              [-178.308780831999854, 24.949767951000183],
              [-178.326566831999855, 24.953801951000173],
              [-178.344331831999796, 24.957913951000251],
              [-178.362074831999877, 24.962104951000072],
              [-178.379796831999926, 24.966374951000262],
              [-178.397495831999919, 24.970722951000084],
              [-178.415171831999857, 24.975148951000222],
              [-178.432825831999963, 24.979653951000273],
              [-178.450454831999934, 24.98423595100013],
              [-178.468060831999964, 24.988896951000129],
              [-178.485641831999857, 24.993635951000215],
              [-178.503198831999839, 24.998452951000161],
              [-178.520729831999887, 25.003346951000253],
              [-178.538235831999884, 25.008318951000035],
              [-178.555714831999893, 25.013368951000075],
              [-178.573167831999854, 25.018495951000091],
              [-178.590594832999898, 25.023699951000083],
              [-178.607993832999853, 25.028981951000162],
              [-178.614463832999888, 25.030966951000266],
              [-178.626273832999885, 25.034618951000084],
              [-178.643616832999896, 25.040054951000286],
              [-178.660931832999921, 25.045566951000183],
              [-178.678217832999849, 25.051155951000226],
              [-178.695474832999849, 25.056820951000361],
              [-178.70078683299991, 25.058590951000042],
              [-178.703242832999905, 25.058810951000169],
              [-178.72148983299985, 25.060526951000213],
              [-178.739728832999845, 25.062322951000112],
              [-178.757956832999838, 25.064199951000148],
              [-178.776175832999854, 25.06615795100015],
              [-178.794383832999955, 25.068195951000234],
              [-178.812581832999939, 25.070314951000228],
              [-178.830767832999896, 25.072513951000076],
              [-178.832065832999916, 25.072674951000067],
              [-178.84916583299983, 25.074821951000274],
              [-178.867328832999846, 25.077181951000341],
              [-178.885478832999837, 25.079621951000036],
              [-178.903615832999833, 25.082142951000094],
              [-178.921740832999802, 25.084743951000178],
              [-178.939850832999895, 25.087424951000173],
              [-178.958027832999903, 25.090198451000106],
              [-178.965605832999955, 25.091364951000173],
              [-178.977277832999874, 25.093188951000286],
              [-178.981415832999886, 25.093827951000037],
              [-178.999498832999933, 25.096668951000083],
              [-179.017565832999821, 25.099589951000212],
              [-179.03561783299989, 25.102590951000195],
              [-179.05365383299997, 25.105671951000204],
              [-179.071673832999863, 25.108831951000184],
              [-179.089677832999968, 25.112071951000189],
              [-179.10766383299989, 25.115391951000277],
              [-179.125632832999855, 25.118790951000108],
              [-179.143583832999838, 25.122269951000192],
              [-179.161516832999894, 25.125827951000076],
              [-179.179430832999827, 25.129464951000273],
              [-179.197325832999894, 25.133181951000154],
              [-179.215201832999867, 25.136977951000176],
              [-179.233056832999978, 25.140851951000059],
              [-179.250892832999938, 25.144805951000023],
              [-179.26870683299984, 25.148837951000189],
              [-179.286500832999877, 25.152949951000267],
              [-179.304272832999885, 25.15713895100015],
              [-179.322022832999835, 25.161407951000228],
              [-179.339749832999843, 25.165754951000167],
              [-179.357454832999878, 25.170179951000193],
              [-179.361257832999854, 25.171141951000209],
              [-179.370237832999806, 25.173423951000188],
              [-179.381989832999892, 25.176425951000056],
              [-179.39964783299996, 25.181007951000083],
              [-179.416817832999868, 25.185543951000056],
              [-179.417557832999819, 25.185740951000184],
              [-179.43516683299984, 25.190478951000159],
              [-179.452751832999894, 25.195293951000167],
              [-179.47031183299984, 25.200186951000148],
              [-179.487845832999852, 25.205157951000047],
              [-179.505353832999873, 25.210205951000091],
              [-179.522835832999846, 25.215331951000223],
              [-179.540289832999804, 25.220534951000332],
              [-179.557717832999856, 25.225814951000189],
              [-179.575117832999894, 25.231170951000138],
              [-179.592488832999834, 25.236604951000174],
              [-179.609832832999842, 25.242115951000358],
              [-179.62714683299987, 25.247702951000235],
              [-179.6444318329998, 25.25336695100026],
              [-179.661686833999937, 25.259106951000206],
              [-179.678912833999874, 25.264923951000299],
              [-179.696106833999806, 25.270815951000145],
              [-179.713270833999957, 25.276784951000025],
              [-179.730403833999929, 25.282828951000283],
              [-179.747504833999898, 25.288948951000179],
              [-179.764573833999833, 25.295143951000284],
              [-179.781609833999852, 25.301414951000083],
              [-179.798613833999894, 25.307760951000262],
              [-179.815583833999966, 25.314182951000305],
              [-179.832519833999896, 25.320678951000275],
              [-179.849422833999824, 25.327249951000226],
              [-179.866290833999898, 25.333895951000159],
              [-179.883123833999832, 25.340615951000359],
              [-179.899921833999883, 25.347410951000143],
              [-179.916683833999883, 25.354279951000251],
              [-179.933410833999829, 25.361221951000175],
              [-179.950099833999928, 25.368238951000251],
              [-179.966752833999891, 25.375328951000142],
              [-179.968940833999909, 25.376267951000216],
              [-179.983582833999918, 25.382584951000069],
              [-179.99999983399988, 25.389764951000132],
              [-179.99999983399988, 25.999999952000167],
              [-179.99999983399988, 26.999999953000213],
              [-179.99999983399988, 27.999999954000089],
              [-179.99999983399988, 28.328559954000241],
              [-179.99999983399988, 28.999999954000259],
              [-179.99999983399988, 29.999999955000135],
              [-179.99999983399988, 30.999999956000238],
              [-179.99999983399988, 31.468262957000263],
              [-179.999232833999827, 31.468645957000149],
              [-179.981694833999853, 31.475907957000061],
              [-179.964111833999851, 31.483091957000283],
              [-179.946483833999878, 31.490197957000078],
              [-179.928812833999899, 31.497225957000182],
              [-179.911098833999887, 31.504176957000141],
              [-179.8933408339999, 31.511047957000187],
              [-179.875541833999904, 31.517841957000144],
              [-179.857699833999988, 31.524555957000189],
              [-179.839816833999862, 31.531191957000146],
              [-179.821892833999897, 31.53774895700019],
              [-179.803927833999836, 31.544226957000262],
              [-179.785923833999931, 31.550624957000309],
              [-179.767879833999984, 31.556942957000274],
              [-179.749796833999824, 31.563181957000268],
              [-179.731674833999847, 31.569340957000236],
              [-179.713514833999881, 31.575418957000238],
              [-179.695317833999894, 31.581417957000099],
              [-179.677083833999887, 31.587335957000221],
              [-179.658812833999889, 31.593172957000149],
              [-179.640505832999878, 31.59892995700028],
              [-179.622163832999973, 31.604604957000163],
              [-179.603785832999932, 31.610199957000304],
              [-179.58537383299992, 31.615712957000142],
              [-179.566927832999937, 31.621144957000183],
              [-179.548447832999983, 31.626495957000259],
              [-179.529934832999857, 31.631764957000087],
              [-179.511389832999953, 31.636951957000292],
              [-179.507355832999878, 31.63806795700026],
              [-179.492331832999866, 31.642188957000144],
              [-179.473722832999897, 31.647211957000252],
              [-179.455082832999864, 31.652152957000169],
              [-179.436411832999823, 31.657010957000296],
              [-179.417710832999859, 31.661786957000061],
              [-179.398980832999825, 31.666479957000263],
              [-179.380221832999894, 31.671089957000106],
              [-179.36143383299995, 31.675617957000327],
              [-179.342617832999821, 31.680061957000305],
              [-179.340155832999841, 31.680636957000274],
              [-179.33211383299988, 31.682524957000169],
              [-179.31327083299982, 31.686886957000297],
              [-179.294399832999858, 31.691163957000299],
              [-179.282438832999873, 31.693829957000105],
              [-179.274633832999882, 31.695550957000194],
              [-179.255710832999966, 31.699661957000217],
              [-179.236761832999832, 31.703689957000222],
              [-179.217788832999958, 31.707633957000212],
              [-179.198790832999862, 31.711492957000132],
              [-179.179769832999909, 31.715268957000262],
              [-179.160724832999932, 31.718960957000206],
              [-179.1416568329999, 31.722568957000306],
              [-179.122566832999837, 31.726091957000165],
              [-179.103454832999802, 31.729530957000293],
              [-179.084321832999819, 31.732885957000065],
              [-179.06516783299989, 31.736155957000278],
              [-179.045993832999983, 31.739340957000195],
              [-179.026800832999839, 31.742441957000267],
              [-179.007587832999889, 31.745457957000042],
              [-178.98835683299987, 31.748388957000259],
              [-178.969106832999898, 31.751234957000179],
              [-178.949839832999828, 31.7539959570002],
              [-178.943612832999946, 31.754868957000156],
              [-178.92669683299988, 31.757211957000209],
              [-178.90739683299995, 31.759801957000263],
              [-178.888080832999833, 31.762307957000132],
              [-178.868748832999898, 31.764727957000161],
              [-178.849401832999888, 31.76706195700018],
              [-178.84069583299987, 31.768083957000215],
              [-178.829765832999868, 31.769342957000276],
              [-178.810390832999929, 31.77150695700027],
              [-178.791002832999794, 31.773584957000253],
              [-178.771601832999949, 31.775576957000226],
              [-178.752188832999877, 31.7774839570003],
              [-178.732763832999893, 31.779304957000306],
              [-178.713327832999795, 31.78103995700036],
              [-178.693881832999864, 31.782688957000175],
              [-178.674424832999904, 31.784251957000151],
              [-178.654958832999881, 31.785729957000228],
              [-178.635483832999853, 31.787120957000241],
              [-178.616000832999873, 31.788425957000186],
              [-178.596508832999945, 31.789645957000232],
              [-178.577009831999874, 31.790778957000214],
              [-178.557504831999836, 31.791825957000356],
              [-178.537992831999844, 31.79278695700026],
              [-178.518474831999896, 31.7936609570001],
              [-178.498952831999873, 31.794449957000211],
              [-178.479424831999893, 31.795151957000201],
              [-178.467065831999975, 31.795551957000271],
              [-178.453671831999827, 31.795964957000137],
              [-178.434136831999894, 31.796493957000223],
              [-178.414597831999885, 31.796937957000182],
              [-178.395056831999824, 31.797294957000247],
              [-178.375514831999823, 31.797565957000074],
              [-178.355969831999857, 31.797749957000235],
              [-178.336424831999892, 31.797847957000158],
              [-178.323973831999893, 31.797865957000226],
              [-178.304427831999845, 31.797822957000221],
              [-178.28488283199988, 31.797692957000265],
              [-178.26533983199991, 31.797476957000185],
              [-178.245627831999798, 31.797172290333549],
              [-178.22599783199982, 31.796781957000235],
              [-178.206460831999891, 31.796307957000295],
              [-178.186926831999955, 31.795746957000233],
              [-178.167396831999895, 31.795098957000221],
              [-178.147870831999825, 31.794365957000196],
              [-178.128349831999884, 31.793545957000163],
              [-178.108833831999817, 31.792639957000176],
              [-178.08932383199982, 31.79164795700018],
              [-178.069820831999863, 31.790569957000173],
              [-178.050324831999973, 31.789404957000215],
              [-178.030835831999838, 31.788154957000188],
              [-178.011355831999822, 31.78681795700021],
              [-177.991883831999871, 31.785395957000162],
              [-177.972420831999841, 31.783886957000163],
              [-177.952967831999871, 31.782292957000266],
              [-177.933524831999904, 31.780612957000187],
              [-177.914092831999852, 31.778845957000158],
              [-177.894672831999941, 31.776993957000229],
              [-177.87526383199986, 31.775056957000174],
              [-177.855866831999975, 31.773032957000055],
              [-177.836483831999914, 31.77092395700015],
              [-177.82201383199984, 31.769292957000232],
              [-177.816471831999849, 31.768655957000306],
              [-177.797115831999889, 31.766375957000093],
              [-177.777773831999838, 31.764009957000269],
              [-177.758447831999973, 31.761558957000148],
              [-177.739136831999815, 31.759022957000298],
              [-177.719841831999844, 31.756400957000267],
              [-177.700564831999856, 31.753694957000164],
              [-177.681303831999855, 31.750902957000278],
              [-177.662060831999923, 31.748025957000266],
              [-177.642835831999861, 31.745063957000127],
              [-177.623629831999892, 31.742017957000144],
              [-177.604442831999876, 31.738885957000207],
              [-177.585276831999835, 31.735669957000198],
              [-177.566129831999859, 31.73236895700029],
              [-177.557880831999881, 31.73091995700014],
              [-177.549952831999832, 31.729521957000259],
              [-177.541412831999963, 31.727998957000182],
              [-177.522308831999879, 31.724529957000243],
              [-177.503227830999862, 31.720975957000178],
              [-177.484167830999866, 31.717337957000211],
              [-177.465131830999837, 31.713614957000232],
              [-177.446118830999836, 31.709808957000064],
              [-177.42712983099986, 31.705918957000279],
              [-177.408164830999937, 31.70194495700008],
              [-177.389225830999834, 31.697886957000208],
              [-177.370311830999952, 31.69374595700026],
              [-177.351424830999889, 31.689520957000298],
              [-177.332563830999845, 31.685212957000147],
              [-177.313729830999819, 31.680820957000208],
              [-177.294923830999892, 31.676346957000248],
              [-177.276145830999894, 31.671788957000217],
              [-177.25739683099988, 31.667148957000165],
              [-177.238676830999879, 31.66242595700021],
              [-177.219985830999889, 31.657619957000236],
              [-177.201325830999849, 31.652731957000299],
              [-177.182696830999845, 31.64776095700023],
              [-177.164098830999876, 31.642708957000195],
              [-177.163030830999986, 31.642415957000139],
              [-177.160956830999879, 31.641847957000209],
              [-177.14239083099983, 31.636712957000043],
              [-177.123856830999841, 31.631495957000197],
              [-177.10535583099994, 31.626197957000272],
              [-177.086887830999899, 31.620817957000099],
              [-177.06845283099986, 31.615355957000077],
              [-177.050008330999816, 31.609799957000121],
              [-177.032914830999829, 31.608537957000124],
              [-177.013488830999847, 31.607029957000236],
              [-176.994072830999897, 31.605435957000168],
              [-176.993298830999805, 31.605370957000275],
              [-176.991084830999853, 31.605184957000176],
              [-176.971678830999849, 31.603504957000268],
              [-176.95228383099996, 31.60173895700035],
              [-176.932899830999986, 31.599886957000024],
              [-176.913527830999868, 31.597949957000196],
              [-176.894168830999917, 31.595926957000302],
              [-176.874821830999821, 31.593817957000056],
              [-176.855488830999889, 31.591623957000252],
              [-176.836168830999981, 31.589343957000267],
              [-176.816863830999978, 31.586978957000156],
              [-176.797574830999878, 31.584528957000316],
              [-176.778299830999856, 31.581992957000068],
              [-176.759041830999848, 31.579371957000092],
              [-176.739800830999798, 31.576665957000216],
              [-176.720575830999849, 31.573874957000214],
              [-176.70136983099988, 31.570997957000202],
              [-176.682180830999869, 31.568036957000174],
              [-176.663010830999838, 31.564990957000191],
              [-176.64386083099987, 31.561859957000138],
              [-176.624729830999883, 31.55864495700024],
              [-176.605619830999842, 31.555344957000216],
              [-176.586529830999865, 31.551959957000065],
              [-176.569441830999892, 31.548855957000285],
              [-176.567196830999961, 31.548442957000248],
              [-176.548149830999904, 31.544888957000353],
              [-176.529126830999957, 31.541251957000156],
              [-176.510125830999868, 31.537529957000174],
              [-176.491147830999836, 31.533724957000345],
              [-176.472194830999825, 31.529834957000162],
              [-176.453265830999868, 31.525861957000245],
              [-176.434361829999801, 31.521804957000313],
              [-176.420420829999898, 31.51875595700028],
              [-176.412830829999933, 31.517080957000246],
              [-176.393977829999841, 31.512856957000338],
              [-176.383662829999821, 31.5105079570003],
              [-176.370963829999823, 31.507591957000272],
              [-176.35216482999985, 31.503201957000101],
              [-176.333393829999892, 31.498727957000142],
              [-176.331021829999827, 31.498155957000222],
              [-176.312868829999843, 31.493741957000225],
              [-176.294154829999883, 31.489101957000173],
              [-176.275469829999849, 31.484379957000272],
              [-176.256814829999882, 31.479574957000068],
              [-176.238188829999842, 31.474687957000185],
              [-176.219594829999863, 31.469718957000111],
              [-176.201031829999891, 31.464666957000077],
              [-176.182499829999898, 31.459532957000192],
              [-176.164000829999878, 31.454317957000171],
              [-176.145533829999948, 31.449019957000246],
              [-176.127099829999878, 31.443640957000355],
              [-176.108699829999978, 31.43818095700027],
              [-176.090334829999847, 31.432638957000222],
              [-176.072003829999886, 31.427015957000208],
              [-176.053707829999865, 31.421311957000228],
              [-176.035446829999927, 31.415526957000282],
              [-176.017222829999838, 31.409661957000139],
              [-175.999035829999968, 31.403715957000259],
              [-175.980885829999863, 31.397688957000184],
              [-175.962772829999807, 31.391582957000139],
              [-175.944697829999882, 31.385395957000185],
              [-175.926661829999802, 31.379129957000259],
              [-175.908665829999848, 31.372783957000308],
              [-175.890707829999826, 31.366357957000275],
              [-175.872790829999843, 31.359852957000271],
              [-175.854914829999842, 31.353268957000125],
              [-175.843506829999825, 31.349017957000115],
              [-175.836449829999879, 31.346369957000206],
              [-175.818655829999841, 31.339627957000175],
              [-175.800903829999839, 31.332807957000227],
              [-175.783193829999902, 31.325908957000138],
              [-175.765527829999883, 31.318930957000305],
              [-175.747904829999868, 31.311875957000268],
              [-175.730324829999859, 31.304742957000201],
              [-175.727749329999853, 31.303697457000169],
              [-175.708274829999851, 31.303394957000194],
              [-175.688836829999843, 31.303006957000264],
              [-175.67362582999985, 31.30264295700016],
              [-175.663487829999895, 31.302384957000299],
              [-175.644055829999871, 31.301823957000238],
              [-175.624628829999892, 31.301176957000166],
              [-175.605204829999821, 31.300443957000255],
              [-175.585786829999876, 31.299624957000219],
              [-175.576351829999851, 31.299195957000222],
              [-175.563017829999893, 31.298564957000053],
              [-175.543609829999895, 31.297572957000227],
              [-175.524208829999935, 31.296495957000104],
              [-175.504815829999842, 31.295331957000258],
              [-175.485428829999904, 31.29408195700023],
              [-175.466050829999887, 31.292745957000193],
              [-175.446680829999849, 31.291324957000199],
              [-175.4273208299999, 31.289816957000141],
              [-175.407969829999814, 31.288223957000298],
              [-175.388628829999874, 31.28654395700022],
              [-175.369298829999849, 31.284778957000185],
              [-175.349979828999921, 31.28292795700014],
              [-175.330672828999838, 31.280990957000313],
              [-175.311377828999866, 31.278968957000131],
              [-175.292095828999891, 31.276860957000167],
              [-175.272827828999851, 31.274667957000077],
              [-175.253572828999893, 31.272388957000146],
              [-175.234332828999953, 31.270024957000146],
              [-175.215107828999891, 31.267574957000306],
              [-175.195897828999847, 31.265040957000053],
              [-175.191520828999899, 31.264450957000292],
              [-175.173524828999831, 31.261991957000191],
              [-175.154347828999875, 31.259286957000029],
              [-175.135187828999875, 31.256495957000027],
              [-175.116045828999944, 31.25362095700018],
              [-175.100200828999959, 31.25117495700033],
              [-175.088414828999902, 31.249339957000188],
              [-175.069309828999849, 31.246294957000146],
              [-175.050222828999864, 31.243165957000031],
              [-175.031156828999912, 31.239950957000303],
              [-175.012110828999852, 31.236652957000274],
              [-174.993084828999827, 31.233268957000234],
              [-174.97408082899986, 31.229801957000234],
              [-174.955098828999837, 31.226249957000164],
              [-174.936138828999873, 31.222613957000249],
              [-174.917200828999881, 31.218892957000207],
              [-174.898286828999886, 31.215088957000262],
              [-174.879396828999944, 31.211200957000244],
              [-174.860531828999825, 31.207228957000041],
              [-174.841690828999845, 31.203173957000274],
              [-174.837428828999833, 31.202243957000235],
              [-174.821134828999817, 31.198654957000258],
              [-174.802345828999876, 31.19443195700029],
              [-174.783582828999869, 31.190126957000246],
              [-174.764846828999879, 31.185737956000082],
              [-174.746137828999878, 31.181265956000288],
              [-174.727457828999803, 31.176710956000306],
              [-174.708805828999829, 31.17207295600025],
              [-174.690182828999866, 31.167352956000173],
              [-174.671588828999887, 31.162549956000305],
              [-174.653025828999915, 31.157663956000192],
              [-174.634492828999839, 31.152696956000284],
              [-174.615990828999827, 31.147646956000244],
              [-174.597519828999822, 31.142514956000298],
              [-174.579081828999875, 31.137300956000217],
              [-174.560675828999848, 31.13200495600006],
              [-174.542302828999851, 31.126627956000164],
              [-174.523963828999939, 31.121169956000301],
              [-174.505658828999884, 31.115629956000191],
              [-174.487387828999914, 31.110008956000172],
              [-174.469151828999884, 31.104306956000187],
              [-174.450951828999933, 31.098523956000065],
              [-174.432786828999838, 31.092660956000259],
              [-174.414658828999876, 31.086716956000146],
              [-174.396568828999847, 31.080691956000237],
              [-174.378514828999982, 31.074587956000244],
              [-174.360499828999963, 31.068402956000227],
              [-174.342522828999876, 31.062138956000126],
              [-174.324584828999917, 31.055794956000341],
              [-174.306685828999889, 31.049371956000186],
              [-174.288826828999987, 31.042868956000177],
              [-174.271008827999822, 31.036286956000026],
              [-174.253230827999857, 31.029625956000302],
              [-174.235494827999929, 31.022885956000209],
              [-174.217800827999895, 31.016067956000256],
              [-174.200148827999982, 31.009170956000162],
              [-174.182538827999878, 31.002195956000207],
              [-174.167836827999878, 30.996299956000144],
              [-174.158179827999874, 30.992410956000242],
              [-174.14065782799986, 30.98527995600017],
              [-174.123179827999849, 30.978070956000181],
              [-174.105746827999837, 30.970785956000327],
              [-174.088358827999826, 30.963422956000215],
              [-174.071015827999901, 30.955981956000187],
              [-174.053718827999944, 30.948465956000234],
              [-174.036468827999983, 30.940871956000024],
              [-174.019265827999817, 30.933201956000289],
              [-174.002109827999789, 30.925455956000292],
              [-173.985001827999866, 30.917633956000032],
              [-173.967942827999877, 30.909735956000247],
              [-173.950931827999881, 30.90176195600003],
              [-173.933969827999846, 30.893712956000172],
              [-173.927148827999815, 30.89044795600023],
              [-173.913956827999897, 30.884102956000163],
              [-173.897095827999863, 30.875903956000172],
              [-173.880284827999986, 30.867630956000255],
              [-173.86352382799987, 30.859281956000302],
              [-173.846815827999819, 30.850859956000306],
              [-173.830158827999895, 30.84236395600027],
              [-173.813553827999897, 30.833793956000306],
              [-173.797001827999878, 30.825149956000246],
              [-173.780503827999951, 30.816432956000199],
              [-173.764058827999975, 30.807642956000166],
              [-173.748294827999842, 30.799120956000252],
              [-173.74548882799985, 30.797596956000291],
              [-173.729152827999826, 30.788661956000283],
              [-173.712870827999836, 30.779653956000175],
              [-173.696644827999876, 30.770573956000305],
              [-173.680474827999916, 30.761421956000277],
              [-173.673347827999976, 30.757354956000256],
              [-173.664095827999887, 30.752046956000186],
              [-173.648038827999869, 30.742752956000174],
              [-173.632037827999852, 30.733386956000061],
              [-173.616094827999945, 30.723949956000126],
              [-173.600209827999834, 30.714442956000084],
              [-173.584382827999832, 30.704865956000106],
              [-173.56861382799994, 30.695217956000306],
              [-173.552904827999839, 30.685500956000112],
              [-173.537254827999817, 30.675713956000209],
              [-173.521664827999984, 30.665856956000141],
              [-173.506135827999856, 30.655931956000302],
              [-173.490666827999888, 30.645937956000125],
              [-173.475258827999852, 30.635874956000293],
              [-173.459911827999946, 30.625744956000176],
              [-173.453076827999922, 30.621196956000119],
              [-173.438560827999851, 30.611507956000139],
              [-173.4233388279998, 30.601241956000194],
              [-173.40817982799993, 30.590908956000192],
              [-173.393083827999845, 30.580507956000247],
              [-173.378050827999886, 30.570039956000073],
              [-173.363082827999818, 30.55950595600018],
              [-173.356447827999915, 30.554800956000236],
              [-173.344799827999822, 30.546506956000201],
              [-173.329959827999858, 30.535840956000186],
              [-173.315184827999957, 30.525108956000224],
              [-173.300475827999804, 30.514310956000315],
              [-173.285831827999829, 30.503448956000227],
              [-173.271253827999857, 30.492520956000192],
              [-173.256742827999858, 30.481529956000145],
              [-173.242297827999892, 30.470473956000149],
              [-173.227920827999867, 30.459353956000257],
              [-173.213610826999883, 30.448169956000186],
              [-173.199369826999856, 30.436922956000046],
              [-173.185195826999831, 30.425612956000236],
              [-173.171090826999858, 30.414240956000242],
              [-173.159616826999951, 30.40490195600006],
              [-173.147872826999901, 30.395325956000192],
              [-173.13390682699989, 30.383828956000173],
              [-173.120010826999959, 30.372269956000139],
              [-173.106184826999964, 30.360649956000259],
              [-173.092428826999793, 30.348967956000195],
              [-173.07874382699984, 30.337225956000339],
              [-173.065128826999796, 30.325422956000182],
              [-173.051586826999852, 30.313559956000233],
              [-173.038114826999845, 30.301636956000152],
              [-173.024715826999824, 30.289653956000052],
              [-173.011389826999817, 30.277611956000214],
              [-172.998134826999916, 30.265510956000355],
              [-172.984953826999828, 30.253351956000245],
              [-172.971846826999979, 30.241133956000056],
              [-172.958811826999977, 30.22885795600024],
              [-172.945851826999956, 30.216524956000171],
              [-172.932965826999833, 30.204133956000248],
              [-172.920154826999891, 30.191685956000185],
              [-172.907418826999844, 30.17918195600015],
              [-172.894757826999893, 30.166620956000031],
              [-172.882171826999894, 30.154003956000054],
              [-172.869661826999902, 30.141331956000158],
              [-172.857227826999974, 30.128604956000174],
              [-172.849828826999811, 30.120966956000188],
              [-172.843252826999901, 30.114146956000241],
              [-172.83097282699984, 30.101309955000147],
              [-172.818768826999843, 30.088418955000236],
              [-172.80664282699982, 30.07547295500018],
              [-172.794594826999855, 30.062473955000257],
              [-172.782623826999867, 30.049421955000184],
              [-172.77073182699985, 30.036315955000248],
              [-172.758917826999976, 30.023157955000272],
              [-172.747182826999875, 30.009947955000257],
              [-172.735526826999859, 29.996685955000032],
              [-172.723949826999871, 29.98337295500022],
              [-172.712452826999822, 29.970007955000142],
              [-172.701034826999859, 29.956592955000247],
              [-172.689697826999833, 29.943126955000196],
              [-172.678440826999861, 29.929610955000271],
              [-172.667264826999826, 29.916044955000302],
              [-172.656169826999843, 29.902429955000173],
              [-172.645154826999828, 29.88876495500017],
              [-172.634221826999834, 29.875051955000117],
              [-172.623370826999974, 29.861290955000186],
              [-172.612601826999963, 29.847481955000148],
              [-172.601914826999888, 29.833624955000289],
              [-172.599046826999825, 29.829879955000024],
              [-172.58945282699986, 29.817294955000193],
              [-172.57893082699988, 29.803343955000173],
              [-172.568491826999889, 29.789346955000269],
              [-172.558135826999859, 29.775302955000143],
              [-172.547863826999844, 29.761213955000073],
              [-172.537674826999961, 29.747079955000174],
              [-172.527569826999809, 29.732899955000221],
              [-172.517548826999871, 29.718675955000151],
              [-172.507612826999861, 29.704407955000192],
              [-172.497759826999982, 29.690094955000177],
              [-172.487992826999829, 29.675738955000043],
              [-172.478310826999888, 29.661339955000074],
              [-172.468712826999848, 29.646898955000154],
              [-172.459200826999933, 29.632414955000286],
              [-172.449774826999828, 29.617888955000296],
              [-172.44043382699985, 29.603320955000186],
              [-172.431179826999966, 29.588711955000235],
              [-172.422011826999892, 29.574061955000275],
              [-172.412929826999942, 29.559371955000188],
              [-172.403933826999804, 29.544640955000091],
              [-172.395025826999955, 29.529870955000149],
              [-172.386203826999832, 29.515061955000192],
              [-172.377469826999885, 29.500212955000279],
              [-172.36882282699986, 29.485326955000176],
              [-172.360263826999841, 29.47040195500017],
              [-172.351791826999886, 29.455438955000261],
              [-172.343407826999879, 29.44043795500022],
              [-172.335111826999849, 29.425400955000214],
              [-172.326904826999879, 29.410326955000357],
              [-172.318785826999857, 29.395216955000137],
              [-172.31075582699998, 29.38007195500029],
              [-172.302813826999937, 29.364889955000137],
              [-172.294961826999952, 29.349673955000242],
              [-172.287197826999886, 29.33442295500015],
              [-172.279523826999906, 29.319137955000031],
              [-172.271938826999985, 29.303818955000168],
              [-172.264443826999894, 29.288465955000106],
              [-172.257038826999946, 29.273080955000239],
              [-172.249722826999914, 29.257662955000171],
              [-172.242497826999852, 29.242211955000187],
              [-172.235362826999875, 29.226729955000167],
              [-172.22831782699987, 29.211215955000284],
              [-172.221362826999865, 29.195670955000139],
              [-172.214498826999858, 29.18009595500024],
              [-172.207725826999877, 29.164489955000192],
              [-172.201043826999893, 29.148854955000104],
              [-172.194451826999909, 29.133189955000205],
              [-172.187951826999978, 29.117494955000097],
            ],
          ],
          [
            [
              [-95.081863964708987, 49.359591070337842],
              [-95.025835803328448, 49.357500120765962],
              [-94.998608867167547, 49.357500120765962],
              [-94.962090926586939, 49.360973246175263],
              [-94.931964019557199, 49.358536124001148],
              [-94.817781878506878, 49.305545061425846],
              [-94.80569098314254, 49.186664193080162],
              [-94.798335863085526, 49.157491146634754],
              [-94.766955023337459, 49.075554019552385],
              [-94.745272883780615, 49.028600106574913],
              [-94.729999882685945, 48.996936126136575],
              [-94.718608876238704, 48.974709162871818],
              [-94.70583586159205, 48.933182196939754],
              [-94.700981901449609, 48.902491187824808],
              [-94.707035814853839, 48.858000045645156],
              [-94.708055054282909, 48.796800082353002],
              [-94.694991019957172, 48.778736075131064],
              [-94.640417956972101, 48.741100156301627],
              [-94.605835900754073, 48.724436094552118],
              [-94.523899947138091, 48.701936048881237],
              [-94.500835799381889, 48.696936075984866],
              [-94.461046066706615, 48.694991139166518],
              [-94.433317892734522, 48.701936048881237],
              [-94.400355052576572, 48.710827068880405],
              [-94.298473019194319, 48.707218156638987],
              [-94.267363920747982, 48.695891187932148],
              [-94.247890915598362, 48.661000174762307],
              [-94.134172964347044, 48.642773223342317],
              [-94.111935775160475, 48.64110908028124],
              [-94.063890873662615, 48.63804499174779],
              [-93.882917872248299, 48.630273123463553],
              [-93.85874597216548, 48.628609148040709],
              [-93.83395498471225, 48.616382130568411],
              [-93.81889086064507, 48.586244997616916],
              [-93.804790990722154, 48.531864215490998],
              [-93.785835819552062, 48.517082226285709],
              [-93.724163955109702, 48.513882183282306],
              [-93.660699872125349, 48.515136116001031],
              [-93.496254977990645, 48.538609132995134],
              [-93.464235940133108, 48.551727146777409],
              [-93.454063997687001, 48.584327050526895],
              [-93.408335854233371, 48.6086090888179],
              [-93.31569989826977, 48.629153971748622],
              [-93.2449998858919, 48.640544978195862],
              [-92.951317946733468, 48.622627151365805],
              [-92.715290928728393, 48.541382201849501],
              [-92.697772918680755, 48.485264186466623],
              [-92.58222687434187, 48.441373188556156],
              [-92.455272892111395, 48.394154072161484],
              [-92.426390865344729, 48.311664242382165],
              [-92.359854982476179, 48.231727037402891],
              [-92.331681897082149, 48.234164159577006],
              [-92.291408863868469, 48.248864173769221],
              [-92.286108986475767, 48.269991096057993],
              [-92.299790934705413, 48.299364134713926],
              [-92.276808929600463, 48.334991078984558],
              [-92.25723584452669, 48.346936129233256],
              [-92.162217919312127, 48.356654107802115],
              [-92.141681921198767, 48.357218042249315],
              [-92.039372913667705, 48.345336023912637],
              [-92.014164004521149, 48.304436024339566],
              [-92.008063990648907, 48.280964180811907],
              [-91.991799913319994, 48.261245082984203],
              [-91.963717855394862, 48.240536082388815],
              [-91.940281886412777, 48.230545021413832],
              [-91.85055495377344, 48.203882187338252],
              [-91.78389099542386, 48.194709032477476],
              [-91.740135784345057, 48.19137320264241],
              [-91.687499946827245, 48.144718020695052],
              [-91.645146022325264, 48.098344973609187],
              [-91.5736269303668, 48.093045096216599],
              [-91.462781960256109, 48.057773209365337],
              [-91.418335912715861, 48.041109147615828],
              [-91.386799840120318, 48.058882135158683],
              [-91.347226863461799, 48.068054116552958],
              [-91.318608866645945, 48.069436124752599],
              [-91.276390896614089, 48.072627115300293],
              [-91.240555075315683, 48.083600200054036],
              [-91.192490895440528, 48.115000150541562],
              [-91.149172884432687, 48.144154086247681],
              [-91.12609985185901, 48.154991216531698],
              [-90.969163969524118, 48.214718144155825],
              [-90.928326834225174, 48.228600084595655],
              [-90.898054920270283, 48.236654087741272],
              [-90.86860895905653, 48.237500157050306],
              [-90.835208918827789, 48.227073237108982],
              [-90.835799843003258, 48.204464226696473],
              [-90.77389999079378, 48.103745098564048],
              [-90.749864045180743, 48.092773187276805],
              [-90.279999865200352, 48.113054040256714],
              [-90.146517889120389, 48.121444995911133],
              [-90.065555074465749, 48.10645396203995],
              [-90.032772948140916, 48.069718091976029],
              [-89.994235807079633, 48.025273217902168],
              [-89.899790868767752, 47.990618071488257],
              [-89.862499949073992, 48.000827061946609],
              [-89.838899862427354, 48.011664192230455],
              [-89.755563963339114, 48.029573134243151],
              [-89.606382012015501, 48.011864184440753],
              [-89.572917934046188, 48.002253997147193],
              [-89.493126909458795, 48.003164271834635],
              [-89.44777293416405, 48.003327048394681],
              [-89.356654940869873, 47.979718076930681],
              [-89.32333586982611, 47.993044967740559],
              [-88.97416405511035, 48.139164171635343],
              [-88.691663910095315, 48.255554105988438],
              [-88.645555060598383, 48.26416416459233],
              [-88.368055056117612, 48.312209066090077],
              [-88.188326934966256, 48.244154047085715],
              [-87.444717843138108, 47.955827138242853],
              [-87.341672904506083, 47.915545052573634],
              [-87.201399910454597, 47.860273106499733],
              [-86.884445952340513, 47.734718063913022],
              [-86.568890933870421, 47.608327010301252],
              [-86.466663901352547, 47.567218133700521],
              [-86.051390889268134, 47.398882192462963],
              [-86.014726935933822, 47.383882106136355],
              [-85.839717826283561, 47.312209122435092],
              [-85.738890906875952, 47.270827163428009],
              [-85.46417302370503, 47.157209124462952],
              [-85.354446031842883, 47.111664209413277],
              [-84.917499932657222, 46.928600090788905],
              [-84.864581960277519, 46.905818077894423],
              [-84.832782025369625, 46.829164232036305],
              [-84.825564033248952, 46.806936095305218],
              [-84.806945982225386, 46.74832714592975],
              [-84.787781934027862, 46.689709144098742],
              [-84.775008919381094, 46.653045023126424],
              [-84.564999831819364, 46.466382218182446],
              [-84.521255014300664, 46.460273151855176],
              [-84.483955042151479, 46.460200061659236],
              [-84.458064013722549, 46.483744995383006],
              [-84.431381901269674, 46.501664163317344],
              [-84.408617825647866, 46.508609073032062],
              [-84.192764036985693, 46.546664254659277],
              [-84.125346042624415, 46.52943609082439],
              [-84.120063934866607, 46.50707317308985],
              [-84.144582007551605, 46.465691214082824],
              [-84.154445998874053, 46.445264180711035],
              [-84.160281982795198, 46.425000091537413],
              [-84.157646041877342, 46.394164243135435],
              [-84.102499991989475, 46.24027317095647],
              [-84.089717924887339, 46.220273111733661],
              [-84.076673001300776, 46.203045115536781],
              [-83.954317834079347, 46.070482131323729],
              [-83.923755068083437, 46.07056410633686],
              [-83.890545967609398, 46.094618156860861],
              [-83.838335930774065, 46.125545032731793],
              [-83.663054912184009, 46.126100082361518],
              [-83.619163914273543, 46.121100109465374],
              [-83.596117871428248, 46.114154026284552],
              [-83.572081925815212, 46.101936061267793],
              [-83.479017822236244, 46.040136121344403],
              [-83.447772936957961, 46.011936046222047],
              [-83.487781940221055, 45.961664240682524],
              [-83.52389989638101, 45.918054036529213],
              [-83.597782014489383, 45.827218178096643],
              [-83.500290924916897, 45.78500020806456],
              [-83.270846047855855, 45.683327051710137],
              [-83.112208974447753, 45.612773219724204],
              [-83.050826788579911, 45.585264148701469],
              [-82.954181935954352, 45.541936079409936],
              [-82.66500895780274, 45.411936113556806],
              [-82.629990874980479, 45.396100183843316],
              [-82.543064000923835, 45.355827150629409],
              [-82.430555055389902, 44.882764268750122],
              [-82.331681957618116, 44.460818088252893],
              [-82.214445953956272, 43.952218116105428],
              [-82.130281922832012, 43.585264261391444],
              [-82.146117852545672, 43.553045063685545],
              [-82.228881938197674, 43.39138212322257],
              [-82.252790981796352, 43.346382199518814],
              [-82.322235887989223, 43.210545076277981],
              [-82.404173015071535, 43.049164103038777],
              [-82.418782001794966, 43.018636038122224],
              [-82.463482021002164, 42.901382097187479],
              [-82.481526917484814, 42.826382168468399],
              [-82.474090995880999, 42.796382163453472],
              [-82.472917864709302, 42.76082713591228],
              [-82.484726792849983, 42.719153989588108],
              [-82.517363912249607, 42.627636011873278],
              [-82.535826898063817, 42.599436104389099],
              [-82.579035776692081, 42.563745122378009],
              [-82.618190999295109, 42.555753983506293],
              [-82.662654984108372, 42.545300073836643],
              [-82.704455032446845, 42.508326995912228],
              [-82.729999888273824, 42.483327131431452],
              [-82.769172880511803, 42.442909091292336],
              [-82.801246065464113, 42.418045013643109],
              [-82.841381970741622, 42.396945081082663],
              [-82.94055497300991, 42.357500179904946],
              [-82.975826859860945, 42.34470906034727],
              [-83.002226837452213, 42.339154037821345],
              [-83.027217817115854, 42.331936045700559],
              [-83.055663984915896, 42.31957324139637],
              [-83.084546011682448, 42.299354079223917],
              [-83.11291791582039, 42.265273093179417],
              [-83.123317846033274, 42.245827077758065],
              [-83.132491000894049, 42.220827045639112],
              [-83.137217891384012, 42.201382203684204],
              [-83.168608957054062, 42.046109123305939],
              [-83.150281925709805, 42.008327024084849],
              [-83.130826857833028, 41.970545092501879],
              [-83.117417824371557, 41.946191137481378],
              [-83.076390922784185, 41.867353973477975],
              [-82.696655000968178, 41.68387310662655],
              [-82.649990934203402, 41.681936048797411],
              [-82.462781964447117, 41.676100064876039],
              [-82.425282000087634, 41.675554067701626],
              [-82.238890936445387, 41.763882228335092],
              [-82.218063918653058, 41.774436050290831],
              [-81.822235916161674, 41.96027323776957],
              [-81.62360895022988, 42.052773239263161],
              [-81.424435819485723, 42.145000158350683],
              [-81.249164020989099, 42.224991175148205],
              [-80.869155016766911, 42.279164253712906],
              [-80.528545986721042, 42.326618063397177],
              [-80.51028198728892, 42.329164150312863],
              [-80.091535849795093, 42.39819113481218],
              [-79.776673008891123, 42.52027321962737],
              [-79.763426919627932, 42.524700205621002],
              [-79.566455044301676, 42.600709147846942],
              [-79.299435771580818, 42.702491101304929],
              [-79.154445945323005, 42.757218056032912],
              [-79.121108937006227, 42.769154053826071],
              [-78.986935789188522, 42.819991134917473],
              [-78.965835856628075, 42.833600160589185],
              [-78.93415494474533, 42.865964197582684],
              [-78.917781902674847, 42.90193614098888],
              [-78.920345926863263, 42.936245114800556],
              [-78.945199946228627, 42.954018102343355],
              [-78.9752728738016, 42.959709079339348],
              [-79.013755029577908, 42.982209125010229],
              [-79.042082006714566, 43.009718028394786],
              [-79.08111787101609, 43.085545083321165],
              [-79.054645976695326, 43.140545120455442],
              [-79.044863960386181, 43.163044998488317],
              [-79.053745927929981, 43.259582227476642],
              [-79.06679085151643, 43.279400064123934],
              [-79.132217808591747, 43.382491103223401],
              [-79.184717859277839, 43.465545035087928],
              [-79.09527289386233, 43.49777311761153],
              [-79.028063944166604, 43.521936132877101],
              [-78.938326785605568, 43.553882080538926],
              [-78.72471783825813, 43.629436053059237],
              [-78.663055026270968, 43.63750011448866],
              [-78.388063893055801, 43.638327073058406],
              [-77.887217851919331, 43.639435998851525],
              [-77.857772896534016, 43.639435998851525],
              [-77.729999834722719, 43.639164257549908],
              [-77.582781930956628, 43.638600155464644],
              [-77.288326845047209, 43.636654045179853],
              [-76.974163893060222, 43.634436025955381],
              [-76.809445916519451, 43.633327100162205],
              [-76.697490847149197, 43.768600121317661],
              [-76.583617830688752, 43.915827077539234],
              [-76.569454928853531, 43.93415410888349],
              [-76.531390862408898, 43.983045079690271],
              [-76.437354960973096, 44.102073134255988],
              [-76.410281916554879, 44.121100054517342],
              [-76.363372930578578, 44.150991094791095],
              [-76.061099875917364, 44.343182100170623],
              [-76.040835786743799, 44.351382116070113],
              [-76.019455060426196, 44.353327052888289],
              [-75.989972889390685, 44.357064208248573],
              [-75.966108940431354, 44.364153957250153],
              [-75.904445960806243, 44.384991200964464],
              [-75.872081923812857, 44.398045009368445],
              [-75.845272909345397, 44.418882085444636],
              [-75.831255014435669, 44.440545114261965],
              [-75.822635903376238, 44.472491061923961],
              [-75.806664019192908, 44.487073226557072],
              [-75.736117898557723, 44.546391117305006],
              [-75.682499869622859, 44.588044985251827],
              [-75.623054909222446, 44.631382106998899],
              [-75.5624998495623, 44.67388221189276],
              [-75.537217850219633, 44.691373232211959],
              [-75.39584598865855, 44.785827055341258],
              [-75.309717909976769, 44.841936018268484],
              [-75.278063987822406, 44.85721807181875],
              [-75.170545962729364, 44.898609083281258],
              [-74.996117886608886, 44.98360007297498],
              [-74.850281991042095, 45.016664166523583],
              [-74.820546015977641, 45.018464264054728],
              [-74.777781881133251, 45.00888223995554],
              [-74.751108821135745, 45.002218123998375],
              [-74.682017798896027, 45.006718032549713],
              [-74.249163912490019, 44.992218178206031],
              [-73.911645960705926, 45.000000104773903],
              [-73.622772887050758, 45.006664220731238],
              [-73.367964035633804, 45.010582089924412],
              [-73.352999823852826, 45.00941801120814],
              [-73.341646033055895, 45.011627145615307],
              [-72.956390963259366, 45.01832713611833],
              [-72.778881867160976, 45.020827122566402],
              [-72.510281935134742, 45.017209157869672],
              [-72.459163892648178, 45.017491125093102],
              [-72.271655018395563, 45.018773221006484],
              [-72.049990894435723, 45.019436061911449],
              [-71.892772877239338, 45.019154094687849],
              [-71.554717813098307, 45.019991111541344],
              [-71.494155042087129, 45.020544987704625],
              [-71.498054974007573, 45.049436066926603],
              [-71.488746032315021, 45.077973094557308],
              [-71.459163948993194, 45.102773134466076],
              [-71.434299871344138, 45.127773166585087],
              [-71.401390843004833, 45.214400136145343],
              [-71.42443588002169, 45.250000090687706],
              [-71.321108806528059, 45.296936066392277],
              [-71.295690852715666, 45.303745021636928],
              [-71.272508855400474, 45.296382190228883],
              [-71.236390899240405, 45.276518085475971],
              [-71.211673001983058, 45.266109102807491],
              [-71.179717834227688, 45.255827022153369],
              [-71.143199893647079, 45.252773159542073],
              [-71.085126883162161, 45.307709158935666],
              [-71.021117808831832, 45.326664162467637],
              [-70.876590831268686, 45.241027095675236],
              [-70.697081980704979, 45.463600153217783],
              [-70.712781955948799, 45.477764228519391],
              [-70.724999920965558, 45.49721812292978],
              [-70.720272862837589, 45.528327053738053],
              [-70.693054979132057, 45.571936084425033],
              [-70.631946043308631, 45.627773138412465],
              [-70.576955058630006, 45.660818121221951],
              [-70.555264034255742, 45.672764177298916],
              [-70.466663964682652, 45.711935996070338],
              [-70.393890940005463, 45.778045072428256],
              [-70.25805499023113, 45.909091099800378],
              [-70.244926918165106, 45.960409134497468],
              [-70.269445996678371, 45.973318103614076],
              [-70.304855011465975, 45.979991104388773],
              [-70.305554900383072, 46.07888213943346],
              [-70.287781912840046, 46.203045115536781],
              [-70.242490969457435, 46.279164195970793],
              [-70.200290936698138, 46.336382084691309],
              [-70.119154951923406, 46.39360919350554],
              [-70.078408844093133, 46.417564169933826],
              [-70.057081929593949, 46.431382240271205],
              [-70.047772987901453, 46.45388211830408],
              [-70.04415502320461, 46.474991103320008],
              [-70.038608885495989, 46.509991081231476],
              [-70.026945970108954, 46.587491164036408],
              [-70.009172982566156, 46.698045114468187],
              [-69.992763898311694, 46.715827154466524],
              [-69.84722690141308, 46.862209047207557],
              [-69.712508929887463, 46.9969360711886],
              [-69.653881875601201, 47.055264226806912],
              [-69.423617918959508, 47.283336148123908],
              [-69.305263937048949, 47.400273253117746],
              [-69.236063950067432, 47.467891072051486],
              [-69.128326822024974, 47.459164169716814],
              [-69.045626774113259, 47.431027126506649],
              [-69.044581886060541, 47.403118071063602],
              [-69.052490882281063, 47.380545102834731],
              [-69.055826879754079, 47.342073172980463],
              [-69.055126823198862, 47.302073222172851],
              [-69.05359092325682, 47.293782178804747],
              [-69.051108874081535, 47.281936035013644],
              [-69.032226793107327, 47.255553994695276],
              [-68.96195492834488, 47.218873109916444],
              [-68.89153587736223, 47.189018111826556],
              [-68.831681880085625, 47.208882216579582],
              [-68.787508914951388, 47.224709093837816],
              [-68.761945954213729, 47.232764270449934],
              [-68.56470887547016, 47.289718129219693],
              [-68.370408815607789, 47.349164263086493],
              [-68.343054977432416, 47.361800149796977],
              [-68.314854902310344, 47.365136147270107],
              [-68.244999953412957, 47.351935990836409],
              [-68.208617799664125, 47.341664136104271],
              [-68.18581784949663, 47.332773116105102],
              [-67.960972960911135, 47.19047321022191],
              [-67.950008928612704, 47.168609183365731],
              [-67.892226770168918, 47.114436104801143],
              [-67.866945944292638, 47.100545111905831],
              [-67.794999878185251, 47.069991063089276],
              [-67.79167293316749, 46.921382098668289],
              [-67.788890979496045, 46.787773220573911],
              [-67.779173000927074, 46.283336036831088],
              [-67.772508884969966, 45.957500111173374],
              [-67.786663907815921, 45.888327113920184],
              [-67.806381999815187, 45.784718073202953],
              [-67.804445947814258, 45.731373126674384],
              [-67.794508866295928, 45.69582715158873],
              [-67.660554989065758, 45.632073222391682],
              [-67.573899856310845, 45.611664126292681],
              [-67.507508812729611, 45.601382213276906],
              [-67.477781890120468, 45.608327122991284],
              [-67.458054913303954, 45.613609063111028],
              [-67.413126906329694, 45.585473193367307],
              [-67.421245953044206, 45.52499122390293],
              [-67.481835881421659, 45.495827062274827],
              [-67.450564006415163, 45.333054022552403],
              [-67.46430881891871, 45.283882090350517],
              [-67.455272959632339, 45.263054066729694],
              [-67.422499885762818, 45.215000112776409],
              [-67.405963899494054, 45.196382229390849],
              [-67.338681859602445, 45.150273212255968],
              [-67.305826810719736, 45.154918127732742],
              [-67.290073023657442, 45.180127036879355],
              [-67.264246032968856, 45.199991141632267],
              [-67.236117874576166, 45.193882075304771],
              [-67.215413502859803, 45.186288371480998],
              [-67.193842909999887, 45.167001723000226],
              [-67.192026779499884, 45.167026520000206],
              [-67.18989024799987, 45.167051317000244],
              [-67.189303614999915, 45.166838934000168],
              [-67.187691309999821, 45.166319473000272],
              [-67.18697166949994, 45.165536942000074],
              [-67.185281341749885, 45.164963488500121],
              [-67.182607333499959, 45.164300926000124],
              [-67.181420497999852, 45.164051051000172],
              [-67.177756325999894, 45.162167028000169],
              [-67.174931122999908, 45.163749692000181],
              [-67.171508794999852, 45.163749692000181],
              [-67.170526528999801, 45.164247817000273],
              [-67.169509887999823, 45.164391692000208],
              [-67.168695200999821, 45.164625864000186],
              [-67.165689841499784, 45.164347621500212],
              [-67.163351091999857, 45.164678457000207],
              [-67.160938091999867, 45.164150239000207],
              [-67.159325545356353, 45.164150239000207],
              [-67.156611841999847, 45.164150239000207],
              [-67.15455943499984, 45.163799286000199],
              [-67.152305559999888, 45.163799286000199],
              [-67.151644904000023, 45.161849129000245],
              [-67.150903833499854, 45.159949238500189],
              [-67.150047091999966, 45.156968192000249],
              [-67.148349981999843, 45.15377095700029],
              [-67.147909137999818, 45.152005676000158],
              [-67.146011619390833, 45.147967279171439],
              [-67.145513095119099, 45.147201860231519],
              [-67.145355294999831, 45.145933270000057],
              [-67.144597903999852, 45.144853598000282],
              [-67.143566012999827, 45.142186567000294],
              [-67.143314419999967, 45.14092831700026],
              [-67.142060356999821, 45.139043614000229],
              [-67.140895903999819, 45.136585817000139],
              [-67.139199887999894, 45.134816645000171],
              [-67.138755747999824, 45.134140207000144],
              [-67.137648700999819, 45.132982473000084],
              [-67.132735169999762, 45.1286522070003],
              [-67.131994333499904, 45.128414254500058],
              [-67.130546387999857, 45.127153489000079],
              [-67.128151685499915, 45.122167918500168],
              [-67.12741904499984, 45.121329114000162],
              [-67.125291622999839, 45.119367457000351],
              [-67.124715185000014, 45.118629192000071],
              [-67.122392739999896, 45.116081668500215],
              [-67.121289341499846, 45.115289606000147],
              [-67.119288857000015, 45.11449311400014],
              [-67.118193893938894, 45.114178778167968],
              [-67.116299419999848, 45.113355473000297],
              [-67.115533372999835, 45.113073661000044],
              [-67.114316481999822, 45.111906067000064],
              [-67.113141200499797, 45.110301535500241],
              [-67.112560856999892, 45.109381254000141],
              [-67.1119327319999, 45.108179098000164],
              [-67.109490434999884, 45.105293067000218],
              [-67.108184130783343, 45.102185148833826],
              [-67.10765595099997, 45.100928520000139],
              [-67.106132891567313, 45.099710588362655],
              [-67.105574256387058, 45.098829452865061],
              [-67.105224899925531, 45.098245391385888],
              [-67.104021887999977, 45.094552036000266],
              [-67.103300606999937, 45.093414176000294],
              [-67.102145450999899, 45.092045817000155],
              [-67.101138341999956, 45.0906210360003],
              [-67.1000792399999, 45.087630317000219],
              [-67.098638747999871, 45.08634802000023],
              [-67.097020111618576, 45.085303446236196],
              [-67.095635700499827, 45.081936035500178],
              [-67.09518363799998, 45.076962692000336],
              [-67.095432028999824, 45.074800989000266],
              [-67.092175637999958, 45.067998879000186],
              [-67.091820127999824, 45.067277827333442],
              [-67.091343997999957, 45.066148754000153],
              [-67.0881691539999, 45.05961384800014],
              [-67.087459091999847, 45.059091410000292],
              [-67.085969559999796, 45.058352285000126],
              [-67.082779434999907, 45.056398567000144],
              [-67.081299856999919, 45.053993434000176],
              [-67.078172294999831, 45.051460442],
              [-67.076480075999882, 45.050373176000278],
              [-67.073776847897136, 45.048178330336157],
              [-67.070903294999766, 45.045845192000286],
              [-67.070092419999867, 45.045333035000283],
              [-67.067482325999777, 45.043449957000234],
              [-67.066470262999985, 45.04244950400016],
              [-67.065249466999887, 45.041503723000176],
              [-67.062198731999956, 45.038985692000097],
              [-67.059370700999864, 45.036378035000268],
              [-67.058649512999921, 45.035837395000101],
              [-67.056926966499816, 45.034631965000187],
              [-67.056262684999922, 45.034026629000209],
              [-67.055609841999853, 45.033445442000186],
              [-67.046548325999822, 45.026000785000178],
              [-67.045501715999819, 45.025249020000217],
              [-67.043568872999856, 45.023810535000109],
              [-67.041461715999901, 45.02319898900015],
              [-67.031029482, 45.019986410000172],
              [-67.031274762999772, 45.017978895000056],
              [-67.030779950999829, 45.007633317000284],
              [-67.030929529, 45.006532239000308],
              [-67.033323411999902, 44.996009785500121],
              [-67.033750106999889, 44.993963801000234],
              [-67.034689200999935, 44.987324645000172],
              [-67.035038510611514, 44.985959055964884],
              [-67.035507904016356, 44.984124012022164],
              [-67.03575138049996, 44.983150481000337],
              [-67.036531778999802, 44.979599942000277],
              [-67.034301801999902, 44.974857613000211],
              [-67.033363762999954, 44.972862754000062],
              [-67.032883934999916, 44.972225473000208],
              [-67.031320356999913, 44.968749207000144],
              [-67.030746325999928, 44.96773255100004],
              [-67.030077685000037, 44.965691160000176],
              [-67.027043137999954, 44.961178910000228],
              [-67.025287559999867, 44.957351692000088],
              [-67.024517200999981, 44.956001285000184],
              [-67.023959419999983, 44.955098129000248],
              [-67.023122325999907, 44.954482239000072],
              [-67.021876472499912, 44.954107670000099],
              [-67.021329294999873, 44.953919910000195],
              [-67.018317887999842, 44.95342244200026],
              [-67.015723075999915, 44.950309871500224],
              [-67.01527648199982, 44.949740801000189],
              [-67.011528575999876, 44.947422723000216],
              [-67.008276887999841, 44.943812785000148],
              [-67.006557700999792, 44.942582348000201],
              [-67.003292528999907, 44.938501364000047],
              [-67.001947966666535, 44.936721764666743],
              [-67.001541762999921, 44.935731082000189],
              [-67.001416104499867, 44.933786839000163],
              [-66.999998403999939, 44.931862676000208],
              [-66.998689872999933, 44.929397504000349],
              [-66.99714007599988, 44.928015738000227],
              [-66.995384544999922, 44.927238145000274],
              [-66.994223208499932, 44.926368324500174],
              [-66.993078708499866, 44.925449973000127],
              [-66.991215825999973, 44.924671832000115],
              [-66.988878716000045, 44.922803551000186],
              [-66.987063798999884, 44.922123150333505],
              [-66.985700419499949, 44.921161585500045],
              [-66.984244458499802, 44.920799254000144],
              [-66.979419606999784, 44.919605254000146],
              [-66.979074450999917, 44.918915910000294],
              [-66.97606127899985, 44.915678379000212],
              [-66.973801279499924, 44.913676904500107],
              [-66.96989461499976, 44.908746597500112],
              [-66.969910387999846, 44.9081188950002],
              [-66.970496669999932, 44.904155098000217],
              [-66.972081309999908, 44.898779645000161],
              [-66.97364440399997, 44.893548973000293],
              [-66.974108778999835, 44.892511129000184],
              [-66.97942018, 44.889307118666864],
              [-66.97999573199985, 44.879350660000114],
              [-66.97999573199985, 44.878700254000307],
              [-66.980011809999894, 44.878099441500126],
              [-66.97999573199985, 44.877469926000288],
              [-66.981654090999939, 44.874950738000223],
              [-66.982250434999912, 44.87433556700023],
              [-66.983198106999964, 44.872864285000162],
              [-66.983619247999854, 44.87239775400019],
              [-66.984357387999864, 44.869733973000166],
              [-66.983644200999834, 44.869100567000146],
              [-66.982773028999873, 44.867900848000261],
              [-66.982142213499856, 44.866830307000214],
              [-66.980778512999962, 44.865897254000231],
              [-66.98044509149986, 44.864675519500167],
              [-66.980567262999841, 44.862100598000154],
              [-66.979518341500011, 44.86130230900028],
              [-66.979515348999939, 44.859510941500218],
              [-66.975592122999956, 44.856479848000333],
              [-66.97434277099984, 44.852256722500158],
              [-66.975045626999929, 44.851399897000192],
              [-66.977363885627938, 44.848462608189777],
              [-66.975878557710985, 44.843785764505185],
              [-66.972576161978282, 44.833387901673802],
              [-66.971837997499847, 44.833301551000204],
              [-66.968925117999845, 44.833690446000332],
              [-66.967232528999887, 44.833917692000284],
              [-66.963889106999829, 44.833767238000291],
              [-66.961502075999931, 44.832220426000163],
              [-66.956250427499867, 44.830495371500035],
              [-66.951995856999872, 44.830398567000145],
              [-66.948643715999879, 44.829948426000158],
              [-66.944132747999845, 44.829948426000158],
              [-66.942025872499926, 44.828536910500077],
              [-66.940498059999982, 44.826314484000193],
              [-66.939992356999937, 44.825586098000144],
              [-66.93759774799986, 44.821556817000271],
              [-66.933201872999859, 44.816450113000144],
              [-66.929992669999933, 44.812793082000155],
              [-66.90196430199984, 44.777490477000185],
              [-66.903065065666567, 44.77653629966693],
              [-66.903706685999907, 44.775953267000205],
              [-66.938055559999952, 44.744722220000085],
              [-66.940555559999837, 44.73222222000021],
              [-66.958055559999764, 44.653611110000213],
              [-67.01, 44.61611111000019],
              [-67.04916667, 44.557500000000175],
              [-67.047950069999899, 44.546678672000269],
              [-67.043888897999864, 44.5105556325002],
              [-67.061666650999825, 44.484166698000195],
              [-67.037777779999828, 44.424166670000147],
              [-67.042499991999875, 44.361944544500147],
              [-67.087875758999871, 44.30513150600018],
              [-67.143888889999857, 44.235000000000298],
              [-67.168447845999879, 44.226243323000233],
              [-67.279444439999907, 44.18666667000025],
              [-67.74305549699983, 42.887222396000254],
              [-67.529346030999875, 42.600980975000255],
              [-67.468055559999868, 42.518888890000085],
              [-65.699722220000012, 40.451388890000203],
              [-65.703311109999902, 40.44214444000022],
              [-65.713405867999768, 40.415297988000077],
              [-65.71382, 40.414183330000242],
              [-65.714262207999866, 40.413056532000269],
              [-65.724647219999781, 40.386291670000332],
              [-65.735777779999864, 40.358472220000294],
              [-65.742522330999918, 40.342108042000291],
              [-65.747213890000012, 40.330725],
              [-65.750004436999973, 40.324150580000037],
              [-65.758958329999984, 40.303055560000075],
              [-65.771005559999935, 40.27546389000031],
              [-65.783358329999942, 40.247952780000048],
              [-65.796011109999938, 40.220522220000078],
              [-65.808966669999847, 40.193175000000281],
              [-65.822222219999901, 40.165916670000172],
              [-65.835774866499889, 40.138744704500141],
              [-65.836112138999852, 40.138085172000274],
              [-65.849624999999833, 40.111661110000227],
              [-65.863769439999885, 40.084672220000243],
              [-65.878211109999882, 40.057775000000163],
              [-65.887732405333225, 40.040450957555663],
              [-65.892942018999918, 40.030972189000181],
              [-65.907967018999955, 40.004270189000295],
              [-65.92328101899983, 39.977667189000044],
              [-65.938887018999935, 39.951164189000281],
              [-65.954775018999783, 39.924764189000143],
              [-65.970953018999921, 39.898472189000245],
              [-65.987414018999971, 39.872284189000254],
              [-66.004156018999879, 39.84620618900027],
              [-66.021181018999926, 39.820239189000063],
              [-66.038487018999774, 39.794386189000249],
              [-66.056070018999947, 39.768645189000097],
              [-66.073928018999766, 39.743022189000158],
              [-66.089998018999893, 39.718072189000054],
              [-66.108125018999942, 39.692567189000272],
              [-66.126525018999985, 39.667181189000189],
              [-66.145198018999906, 39.641917189000253],
              [-66.164142018999939, 39.616775189000293],
              [-66.183353018999981, 39.591759189000072],
              [-66.202831018999916, 39.566872189000264],
              [-66.222575018999919, 39.542111189000195],
              [-66.242584019, 39.517484189000186],
              [-66.26285301899992, 39.492986189000248],
              [-66.283384018999925, 39.468622189000143],
              [-66.304173018999961, 39.444397189000199],
              [-66.325220018999858, 39.420306189000144],
              [-66.346520018999911, 39.396356189000244],
              [-66.36807500999987, 39.372547204500222],
              [-66.389881018999915, 39.348878189000288],
              [-66.411939018999874, 39.325356189000274],
              [-66.434242018999953, 39.301978189000238],
              [-66.456795018999969, 39.278750189000164],
              [-66.479589018999775, 39.255667189000235],
              [-66.502628018999928, 39.232739189000313],
              [-66.525909018999926, 39.209961189000182],
              [-66.54942801899989, 39.187336188000188],
              [-66.573184018999939, 39.164867188000244],
              [-66.597175019999781, 39.142556188000185],
              [-66.62140001999984, 39.120403188000239],
              [-66.645856019999826, 39.098409188000176],
              [-66.670542019999857, 39.076575188000163],
              [-66.695459019999873, 39.054909188000238],
              [-66.720598019999841, 39.033403188000193],
              [-66.745962019999922, 39.012064188000295],
              [-66.771548019999898, 38.990895188000252],
              [-66.797356019999938, 38.969892188000074],
              [-66.82337802, 38.949061188000314],
              [-66.849620019999975, 38.928403188000061],
              [-66.87607501999986, 38.907917188000226],
              [-66.902742019999891, 38.88760618800012],
              [-66.929620019999959, 38.867470188000311],
              [-66.956706019999842, 38.847511188000169],
              [-66.983998019999888, 38.827734188000193],
              [-67.011495019999927, 38.80813418800011],
              [-67.039192019999916, 38.788717188000078],
              [-67.067092019999905, 38.769484188000206],
              [-67.09518901999985, 38.750434188000156],
              [-67.123481019999986, 38.731567188000156],
              [-67.151967020000029, 38.712889188000133],
              [-67.180645019999986, 38.694400188000145],
              [-67.209514019999915, 38.676100188000191],
              [-67.238570019999941, 38.657989188000215],
              [-67.267809019999902, 38.640072188000261],
              [-67.297234020000019, 38.622347188000276],
              [-67.326839019999852, 38.604817188000254],
              [-67.356625020000024, 38.587481188000254],
              [-67.386587019999922, 38.570342188000041],
              [-67.416725019999944, 38.553400188000182],
              [-67.447034019999819, 38.536659188000215],
              [-67.477514019999944, 38.520117188000199],
              [-67.508164019999924, 38.503775188000247],
              [-67.538978019999774, 38.487636188000181],
              [-67.569959019999885, 38.471700188000227],
              [-67.60109802, 38.455970188000208],
              [-67.632398019999926, 38.440445188000183],
              [-67.663856020999958, 38.425125188000209],
              [-67.695470020999949, 38.410014188000105],
              [-67.727237020999894, 38.395111188000158],
              [-67.759153020999918, 38.380420188000187],
              [-67.791220020999901, 38.365936188000319],
              [-67.823431020999863, 38.351667188000249],
              [-67.855787020999912, 38.337609188000044],
              [-67.888287020999883, 38.323764188000268],
              [-67.920923020999965, 38.310134188000347],
              [-67.95369802099998, 38.296720188000336],
              [-67.986609020999936, 38.283522188000234],
              [-68.019653020999897, 38.270539188000157],
              [-68.052825020999819, 38.257775188000323],
              [-68.086128020999922, 38.24523418800004],
              [-68.119556020999823, 38.232909188000065],
              [-68.153106020999928, 38.220803188000104],
              [-68.186781020999831, 38.20892018800032],
              [-68.22057302099978, 38.197261188000198],
              [-68.254481020999947, 38.185822188000145],
              [-68.28850302099994, 38.174609188000204],
              [-68.322639020999873, 38.163620188000266],
              [-68.356884020999871, 38.152856188000158],
              [-68.391239020999876, 38.142317188000163],
              [-68.425695020999854, 38.132006188000219],
              [-68.460256020999907, 38.121922188000212],
              [-68.494920020999871, 38.112064187000158],
              [-68.529678020999881, 38.102436187000251],
              [-68.564534020999872, 38.093039187000045],
              [-68.599484020999967, 38.083870187000286],
              [-68.634523020999893, 38.074934187000224],
              [-68.704867020999927, 38.057750187000181],
              [-68.74016702199998, 38.049509187000297],
              [-68.775548021999896, 38.041497187000118],
              [-68.811009021999837, 38.033722187000194],
              [-68.851814021999928, 38.024886187000277],
              [-68.887348021999969, 38.017342187000111],
              [-68.92295602199988, 38.010034187000144],
              [-68.958637021999834, 38.00296118700021],
              [-68.994387021999898, 37.99612218700014],
              [-69.030202900999882, 37.989522204000195],
              [-69.066087021999863, 37.983156187000247],
              [-69.102031021999892, 37.977025187000265],
              [-69.138037021999821, 37.971134187000303],
              [-69.174100021999891, 37.965478187000201],
              [-69.210217021999938, 37.960061187000179],
              [-69.246389021999903, 37.954884187000289],
              [-69.282612021999967, 37.949945187000196],
              [-69.31888402199985, 37.945242187000247],
              [-69.355200021999849, 37.940781187000084],
              [-69.391562022000016, 37.936559187000171],
              [-69.427962021999861, 37.932575187000225],
              [-69.464400021999836, 37.928834187000291],
              [-69.500878021999824, 37.925331187000211],
              [-69.537387021999905, 37.922067187000152],
              [-69.573927900999877, 37.919047203500043],
              [-69.574906099999794, 37.918972839000276],
              [-69.610500021999826, 37.916267187000244],
              [-69.647098021999852, 37.913728187000288],
              [-69.683717021999826, 37.911431187000119],
              [-69.720362021999961, 37.909375187000194],
              [-69.757025022, 37.907559187000231],
              [-69.793703021999931, 37.905986187000281],
              [-69.830398022999873, 37.904656187000171],
              [-69.86710302299997, 37.903567187000306],
              [-69.90382002299998, 37.902720187000227],
              [-69.940542022999864, 37.902114187000166],
              [-69.977270022999903, 37.901750187000061],
              [-70.014000022999824, 37.901628187000256],
              [-70.050731022999969, 37.901750187000061],
              [-70.087459022999781, 37.902114187000166],
              [-70.124181022999835, 37.902720187000227],
              [-70.160898022999902, 37.903567187000306],
              [-70.197603022999942, 37.904656187000171],
              [-70.234298022999951, 37.905986187000281],
              [-70.254070022999827, 37.906567187000178],
              [-70.290778022999859, 37.907656187000214],
              [-70.327473022999868, 37.908989187000032],
              [-70.364153022999858, 37.910561187000269],
              [-70.379818904999979, 37.911325972000213],
              [-70.393055756666627, 37.911972192666724],
              [-70.413728022999976, 37.903956187000176],
              [-70.446864022999989, 37.891406187000257],
              [-70.480128022999793, 37.879072187000247],
              [-70.513514022999971, 37.866961187000186],
              [-70.547023022999895, 37.855072187000246],
              [-70.580650022999976, 37.843403187000206],
              [-70.593612022999764, 37.83894518700032],
              [-70.600906022999936, 37.828870187000177],
              [-70.619292022999844, 37.803681187000223],
              [-70.637939022999944, 37.778620187000172],
              [-70.65685002299989, 37.753684187000033],
              [-70.676023022999857, 37.728875187000085],
              [-70.695450011499872, 37.7041972035002],
              [-70.715137022999841, 37.67965318700027],
              [-70.735078022999858, 37.655242187000226],
              [-70.755273022999916, 37.630967187000238],
              [-70.775720022999906, 37.606828187000303],
              [-70.79641702299989, 37.582828187000302],
              [-70.817364022999811, 37.55897018700017],
              [-70.838559022999959, 37.535250187000088],
              [-70.859995022999897, 37.5116781870002],
              [-70.881678023999882, 37.48825018700029],
              [-70.903600011999856, 37.464972203500054],
              [-70.925764024000046, 37.441839187000141],
              [-70.948164023999823, 37.41885918700018],
              [-70.970803023999963, 37.396031187000176],
              [-70.99367502399997, 37.37335618700007],
              [-71.016781023999954, 37.350836187000141],
              [-71.040120023999918, 37.328472187000159],
              [-71.063684023999883, 37.306270187000109],
              [-71.087478024, 37.284225187000061],
              [-71.089112023999974, 37.282500187000153],
              [-71.09033702399995, 37.279631187000291],
              [-71.102612023999768, 37.252225187000192],
              [-71.115173024, 37.224903187000166],
              [-71.128025023999896, 37.197670187000028],
              [-71.141162023999925, 37.170525187000067],
              [-71.154587023999909, 37.143470187000275],
              [-71.168295023999917, 37.116509187000077],
              [-71.182284023999898, 37.089645187000087],
              [-71.196556023999847, 37.062875187000259],
              [-71.211109023999768, 37.03620318600008],
              [-71.225942023999949, 37.009634186000198],
              [-71.241146023999875, 36.982800686000076],
              [-71.254623023999841, 36.95537518600014],
              [-71.268287023999903, 36.928411186000062],
              [-71.282234023999877, 36.901542186000199],
              [-71.296462023999823, 36.874767186000327],
              [-71.310970023999971, 36.848092186000144],
              [-71.325756023999872, 36.821520186000157],
              [-71.340820023999981, 36.795047186000204],
              [-71.356159024000021, 36.768681186000038],
              [-71.371773023999936, 36.742420186000231],
              [-71.387659023999902, 36.716267186000266],
              [-71.40381702399992, 36.690225186000077],
              [-71.420245023999826, 36.664295186000288],
              [-71.440073023999844, 36.633095186000162],
              [-71.456209023999861, 36.607050186000265],
              [-71.472614023999881, 36.581120186000135],
              [-71.489289023999902, 36.555300186000181],
              [-71.502167023999903, 36.535834186000216],
              [-71.496634023999945, 36.516239186000178],
              [-71.488895023999874, 36.487806186000057],
              [-71.481470023999975, 36.459317186000249],
              [-71.47435602399986, 36.430775186000176],
              [-71.467556023999975, 36.402186186000108],
              [-71.461070023999866, 36.373550186000273],
              [-71.454898023999988, 36.344867186000215],
              [-71.449037023999949, 36.316142186000263],
              [-71.443489023999916, 36.287378186000296],
              [-71.439742024, 36.269984186000329],
              [-71.433570023999891, 36.241303186000266],
              [-71.427712024000016, 36.212578186000144],
              [-71.422167023999862, 36.183814186000177],
              [-71.416937023999765, 36.155009186000143],
              [-71.412020023999901, 36.126170186000195],
              [-71.40741402399982, 36.097295186000281],
              [-71.403123023999967, 36.068389186000275],
              [-71.399145023999893, 36.03945318600006],
              [-71.395481023999878, 36.010492186000306],
              [-71.392128023999817, 35.981503186000168],
              [-71.389092023999979, 35.952495185000203],
              [-71.386367023999867, 35.923461185000235],
              [-71.383953023999936, 35.894414185000301],
              [-71.381853023999952, 35.865347185000303],
              [-71.380867023999826, 35.857175185000187],
              [-71.378764023999906, 35.828111185000296],
              [-71.376978023999925, 35.799031185000274],
              [-71.375500012499856, 35.769939038000018],
              [-71.374337023999942, 35.740839185000254],
              [-71.373487023999871, 35.711731185000247],
              [-71.372945023999876, 35.682617185000254],
              [-71.372714023999947, 35.653500185000212],
              [-71.372798023999906, 35.624384185000224],
              [-71.373189024, 35.595267185000182],
              [-71.373889023999823, 35.566156185000239],
              [-71.374900023999828, 35.537050185000226],
              [-71.376223023999842, 35.50795318500019],
              [-71.377853023999933, 35.47886718500024],
              [-71.379789023999876, 35.449792185000092],
              [-71.382037023999771, 35.42073118500025],
              [-71.384589023999922, 35.391689185000189],
              [-71.387450023999918, 35.362664185000256],
              [-71.390617023999937, 35.333661185000039],
              [-71.394089023999982, 35.304684185000212],
              [-71.397870023999928, 35.275728185000162],
              [-71.40313902399987, 35.250292185000205],
              [-71.407220024000026, 35.22136718500019],
              [-71.411603023999987, 35.192470185000047],
              [-71.416292023999915, 35.163606185000162],
              [-71.421281023999882, 35.134778185000187],
              [-71.426573023999822, 35.105984185000239],
              [-71.432167024000023, 35.077228185000195],
              [-71.438059023999926, 35.048514185000272],
              [-71.444253023999977, 35.019845185000179],
              [-71.450745023999957, 34.991220185000145],
              [-71.456562023999822, 34.966850185000283],
              [-71.46183402399987, 34.938056185000107],
              [-71.467406023999956, 34.909300185000234],
              [-71.473278024000024, 34.88058418400027],
              [-71.479450023999902, 34.851911184000187],
              [-71.485920023999881, 34.823284184000158],
              [-71.492689023999844, 34.794700184000135],
              [-71.499753023999972, 34.766170184000146],
              [-71.507114023999861, 34.737689184000146],
              [-71.514770023999915, 34.70926118400007],
              [-71.522720023999852, 34.680889184000193],
              [-71.529445023999926, 34.657778184000051],
              [-71.531975023999848, 34.646881184000108],
              [-71.539020023999967, 34.618347184000356],
              [-71.546362023999848, 34.589864184000305],
              [-71.55399502399996, 34.561436184000286],
              [-71.561923023999896, 34.533061184000132],
              [-71.570145024, 34.504745184000285],
              [-71.578659023999762, 34.476489184000229],
              [-71.587462023999819, 34.448295184000358],
              [-71.596556023999881, 34.420164184000157],
              [-71.605939023999838, 34.392097184000306],
              [-71.615612023999802, 34.364100184000165],
              [-71.625573023999834, 34.336172184000077],
              [-71.635817023999891, 34.308320184000252],
              [-71.646348023999906, 34.280536184000141],
              [-71.657162023999888, 34.252834184000278],
              [-71.66826202399983, 34.225206184000172],
              [-71.679639023999982, 34.197659184000145],
              [-71.691300023999986, 34.170195184000249],
              [-71.703242023999962, 34.142814184000144],
              [-71.715462023999862, 34.115520184000104],
              [-71.727956023999866, 34.088314184000069],
              [-71.740731023999899, 34.06119718400015],
              [-71.753778023999871, 34.034175184000219],
              [-71.767100023999831, 34.007245184000283],
              [-71.780695023999897, 33.980411184000104],
              [-71.794562023999845, 33.953678184000125],
              [-71.808698023999966, 33.927042184000186],
              [-71.823103023999863, 33.900509184000214],
              [-71.837775023999825, 33.874078184000211],
              [-71.852714023999852, 33.847753184000226],
              [-71.867920023999943, 33.821539184000187],
              [-71.883387023999944, 33.795431183000176],
              [-71.899117023999963, 33.769436183000153],
              [-71.915109023999946, 33.743553183000131],
              [-71.931362023999839, 33.717786183000158],
              [-71.947870024999872, 33.69213618300023],
              [-71.964637024999945, 33.666603183000291],
              [-71.981659024999828, 33.64119518300015],
              [-71.998934024999869, 33.615906183000277],
              [-72.016462024999839, 33.590742183000259],
              [-72.034242024999969, 33.565703183000096],
              [-72.052270024999814, 33.540795183000341],
              [-72.070548024999937, 33.516014183000209],
              [-72.089070024999899, 33.491364183000258],
              [-72.107839024999919, 33.466847183000141],
              [-72.126850024999897, 33.44246718300036],
              [-72.131589025000039, 33.436311183000271],
              [-72.150842024999918, 33.412067183000147],
              [-72.170337024999924, 33.3879611830003],
              [-72.190067024999905, 33.36399518300027],
              [-72.210037024999963, 33.34017218300022],
              [-72.230239024999833, 33.316492183000264],
              [-72.250678024999786, 33.292956183000285],
              [-72.271350024999833, 33.269570183000155],
              [-72.292250024999817, 33.246328183000173],
              [-72.313381025000012, 33.22323918300026],
              [-72.334739024999976, 33.200303183000187],
              [-72.356323024999881, 33.177520183000183],
              [-72.378131024999959, 33.154889183000137],
              [-72.400162024999815, 33.132417183000143],
              [-72.422412024999915, 33.110103183000206],
              [-72.444881024999916, 33.087950183000089],
              [-72.467567024999823, 33.065959183000246],
              [-72.490470024999865, 33.044128183000169],
              [-72.513587024999822, 33.022461183000303],
              [-72.536912024999822, 33.000964183000292],
              [-72.560450024999852, 32.979631183000151],
              [-72.584198024999921, 32.958470183000259],
              [-72.608148024999934, 32.93747818300011],
              [-72.632306024999934, 32.916659183000263],
              [-72.656667024999763, 32.896011183000155],
              [-72.681225024999833, 32.875539183000228],
              [-72.705987024999956, 32.855245183000306],
              [-72.730945024999983, 32.835128183000052],
              [-72.756098024999972, 32.8151891830002],
              [-72.781442024999819, 32.795434183000225],
              [-72.806981024999857, 32.77585618300003],
              [-72.832709024999986, 32.756464183000276],
              [-72.858623024999872, 32.737259182000287],
              [-72.884725024999966, 32.718236182000169],
              [-72.91101202499982, 32.69940318200014],
              [-72.937478024999962, 32.680756182000266],
              [-72.96412502499993, 32.662300182000138],
              [-72.990950024999904, 32.64403618200015],
              [-73.01795302599993, 32.625967182000068],
              [-73.045128026000043, 32.608089182000185],
              [-73.072478026, 32.590406182000095],
              [-73.099995025999874, 32.572920182000132],
              [-73.12768402599977, 32.555631182000297],
              [-73.155537025999877, 32.538542182000185],
              [-73.183553025999913, 32.521650182000144],
              [-73.211734025999931, 32.504961182000272],
              [-73.240073026, 32.488475182000229],
              [-73.26857002599985, 32.472192182000185],
              [-73.29722302599987, 32.456114182000192],
              [-73.326031025999839, 32.440239182000141],
              [-73.354992025999877, 32.424575182000297],
              [-73.384100025999942, 32.409114182000224],
              [-73.413359025999796, 32.393867182000236],
              [-73.442762025999798, 32.378825182000185],
              [-73.472309025999948, 32.363997182000219],
              [-73.501995025999975, 32.349381182000229],
              [-73.531823025999927, 32.334975182000221],
              [-73.561789025999872, 32.320786182000177],
              [-73.591889025999876, 32.306811182000331],
              [-73.62212302599994, 32.293053182000278],
              [-73.629945025999916, 32.289306182000189],
              [-73.63304502599982, 32.286575182000206],
              [-73.657012025999961, 32.265739182000175],
              [-73.681178026, 32.245078182000043],
              [-73.705548025999974, 32.224589182000159],
              [-73.730114025999853, 32.204278182000223],
              [-73.754875025999922, 32.184145182000293],
              [-73.779834025999889, 32.164192182000306],
              [-73.80498102599978, 32.144417182000154],
              [-73.83032302599986, 32.124828182000272],
              [-73.855850025999985, 32.105420182000273],
              [-73.881567025999914, 32.086195182000324],
              [-73.907467025999892, 32.067159182000182],
              [-73.933550025999921, 32.048309182000139],
              [-73.959817025999882, 32.029647182000247],
              [-73.986262025999963, 32.011178182000151],
              [-74.012884025999824, 31.992897182000149],
              [-74.039678025999876, 31.97481118200028],
              [-74.066650025999877, 31.956920182000204],
              [-74.093792026999893, 31.93922018200027],
              [-74.121103026999776, 31.921720182000058],
              [-74.148581026999977, 31.904417182000145],
              [-74.17622502699993, 31.88731118200019],
              [-74.204034026999807, 31.870406182000181],
              [-74.232000027000026, 31.853703182000174],
              [-74.26012802699978, 31.837200182000174],
              [-74.288414026999817, 31.820903182000222],
              [-74.316856026999801, 31.804811182000151],
              [-74.343551634999983, 31.789977073000273],
              [-74.34545, 31.788922220000302],
              [-74.346249940999769, 31.788485839000202],
              [-74.374195026999871, 31.773242182000047],
              [-74.403089026999965, 31.757770182000286],
              [-74.432131026999912, 31.74250618200017],
              [-74.461320026999886, 31.727453182000261],
              [-74.490648026999907, 31.712609182000222],
              [-74.520120026999848, 31.697981182000149],
              [-74.549728026999844, 31.683561182000176],
              [-74.579475026999887, 31.669359181000232],
              [-74.609356026999933, 31.655372181000075],
              [-74.639370027000041, 31.641600181000115],
              [-74.669512027, 31.628045181000175],
              [-74.699784026999964, 31.614709181000023],
              [-74.730181026999901, 31.601592181000285],
              [-74.76070302699992, 31.58869218100017],
              [-74.791348026999856, 31.576017181000168],
              [-74.82210902699984, 31.563561181000182],
              [-74.852989026999865, 31.551328181000201],
              [-74.883987026999932, 31.539317181000285],
              [-74.915095026999779, 31.527534181000192],
              [-74.946313958999895, 31.515972200500073],
              [-74.977645026999824, 31.504636181000137],
              [-75.009081026999866, 31.493528181000272],
              [-75.040620026999875, 31.482647181000232],
              [-75.072262026999965, 31.471995181000182],
              [-75.104003026999976, 31.461570181000184],
              [-75.135842026999796, 31.45137518100006],
              [-75.167778028000043, 31.441411181000035],
              [-75.172362027999895, 31.439945181000269],
              [-75.179456027999947, 31.434067181000273],
              [-75.203995027999838, 31.41391418100028],
              [-75.228728027999807, 31.39394218100017],
              [-75.253653028000031, 31.374150181000175],
              [-75.278767027999947, 31.354539181000064],
              [-75.304070027999956, 31.335114181000279],
              [-75.329559027999949, 31.315872181000145],
              [-75.355228027999942, 31.296817181000222],
              [-75.381084027999975, 31.277947181000172],
              [-75.407117027999902, 31.259270181000261],
              [-75.433328027999835, 31.240781181000273],
              [-75.459717027999829, 31.222484181000141],
              [-75.486281027999951, 31.204378181000266],
              [-75.513014027999873, 31.186470181000175],
              [-75.539920027999926, 31.168753181000284],
              [-75.566995027999781, 31.151234181000291],
              [-75.594237027999952, 31.133914181000193],
              [-75.62164202799994, 31.116792181000164],
              [-75.649209027999916, 31.099870181000142],
              [-75.676937027999884, 31.083147181000299],
              [-75.704823027999907, 31.066628181000226],
              [-75.732867027999873, 31.05031418100026],
              [-75.761064027999964, 31.034206181000286],
              [-75.78941402799984, 31.018300181000143],
              [-75.81791702799984, 31.00260318100004],
              [-75.846564027999875, 30.987114181000322],
              [-75.875362027999927, 30.971834181000077],
              [-75.904300027999909, 30.95676418100021],
              [-75.933384027999807, 30.941906181000263],
              [-75.962606027999982, 30.927261181000176],
              [-75.99196402799987, 30.912828181000236],
              [-76.021462027999917, 30.898609181000211],
              [-76.051092027999971, 30.884606181000152],
              [-76.080853027999922, 30.870820181000283],
              [-76.110745027999826, 30.857250181000154],
              [-76.140764027999978, 30.843900181000265],
              [-76.170909027999812, 30.830767181000226],
              [-76.201178027999902, 30.817856181000082],
              [-76.231567027999859, 30.805164181000123],
              [-76.262073028999907, 30.79269518100017],
              [-76.292698028999951, 30.78044718100017],
              [-76.323439028999815, 30.768425181000225],
              [-76.354292028999794, 30.756625181000175],
              [-76.385253028999841, 30.745050181000067],
              [-76.41632502899995, 30.73370318100018],
              [-76.447500028999855, 30.722584181000173],
              [-76.47878102899989, 30.711689181000054],
              [-76.510164028999952, 30.701025181000205],
              [-76.516872535999823, 30.698767493000275],
              [-76.517750015999809, 30.698472202000119],
              [-76.529031028999952, 30.688445181000304],
              [-76.552759028999901, 30.667745181000157],
              [-76.576684029000035, 30.64722018100008],
              [-76.600806028999898, 30.626870181000129],
              [-76.625123028999894, 30.606700181000349],
              [-76.649628028999871, 30.586709180000128],
              [-76.67432802899981, 30.566897180000296],
              [-76.699214028999847, 30.547267180000233],
              [-76.724287029000038, 30.527822180000157],
              [-76.749545028999876, 30.508561180000072],
              [-76.774987028999931, 30.489489180000191],
              [-76.800609028999816, 30.470603180000182],
              [-76.826409028999876, 30.451903180000158],
              [-76.852387028999942, 30.433397180000213],
              [-76.87853902899991, 30.4150811800003],
              [-76.904867029, 30.396959180000124],
              [-76.931364028999894, 30.379028180000148],
              [-76.958034028999862, 30.361295180000184],
              [-76.967540610499952, 30.355081346000077],
              [-76.984863280999946, 30.343750000000284],
              [-77.011863707999851, 30.326419830000248],
              [-77.039031981999869, 30.309270859000208],
              [-77.066352844, 30.292329788000302],
              [-77.093841552999891, 30.275590897000257],
              [-77.12148284899996, 30.259059906000175],
              [-77.149276732999908, 30.242719650000197],
              [-77.177230834999818, 30.226589203000174],
              [-77.205337523999958, 30.210680008000054],
              [-77.211173244999827, 30.207433652000191],
              [-77.233596801999909, 30.194959641000082],
              [-77.235243843999854, 30.194060512000192],
              [-77.25707244899985, 30.182144165000238],
              [-77.245456662999857, 30.164756763000241],
              [-77.24485778799982, 30.1638603210003],
              [-77.228271483999833, 30.138530731000287],
              [-77.211936950999871, 30.113079071000129],
              [-77.19589233399978, 30.087509155000077],
              [-77.18009948699995, 30.061800003000258],
              [-77.164581298999849, 30.035980225000174],
              [-77.149322509999877, 30.01004028300008],
              [-77.134323119999863, 29.983980179000184],
              [-77.119613646999937, 29.957790375000229],
              [-77.105163573999846, 29.931520462000265],
              [-77.0909729, 29.905120850000174],
              [-77.077072143999828, 29.878620148000209],
              [-77.063430785999799, 29.85201072700022],
              [-77.050079345999904, 29.825290680000194],
              [-77.036987304999798, 29.798479080000277],
              [-77.024169921999771, 29.771560669000166],
              [-77.011642455999834, 29.744539261000284],
              [-76.999366760000044, 29.717439651000177],
              [-76.987396239999953, 29.69022941600025],
              [-76.975692748999847, 29.66292953500016],
              [-76.964256286999955, 29.635540009000181],
              [-76.953132628999811, 29.608079910000185],
              [-76.942260741999917, 29.58052063000008],
              [-76.931671142999846, 29.552890778000119],
              [-76.921371459999875, 29.525180817000205],
              [-76.911346435999917, 29.497379303000116],
              [-76.901611327999831, 29.46949958800019],
              [-76.892173767000031, 29.441560745000231],
              [-76.883003234999933, 29.413539886000137],
              [-76.874099731, 29.385450363000302],
              [-76.865509032999853, 29.357299805000082],
              [-76.857177733999833, 29.32906913800025],
              [-76.849151610999911, 29.300779343000158],
              [-76.841400145999899, 29.272449493000238],
              [-76.833946227999945, 29.24402999900019],
              [-76.826766967999845, 29.21557998700024],
              [-76.819892882999852, 29.1870708470002],
              [-76.813306513166594, 29.158565605166757],
              [-76.807680661499887, 29.13307299100012],
              [-76.806976317999784, 29.129880905000164],
              [-76.806318512999894, 29.126754039500099],
              [-76.801833778599871, 29.105435938600067],
              [-76.800949096999886, 29.101230621000354],
              [-76.799502325999867, 29.093988109500117],
              [-76.7972588716666, 29.082757413000252],
              [-76.795211791999918, 29.07250976600028],
              [-76.792689763999874, 29.059217972000056],
              [-76.792096749999928, 29.056092621000232],
              [-76.789756774999944, 29.043760300000258],
              [-76.784606933999896, 29.01497077900018],
              [-76.77971649199992, 28.986129761000086],
              [-76.77514648399989, 28.957279205000191],
              [-76.770843505999835, 28.928359985000156],
              [-76.766853332999915, 28.899429321000071],
              [-76.763122558999783, 28.87047004700031],
              [-76.759696959999872, 28.841480255000249],
              [-76.756561278999868, 28.812450409000348],
              [-76.753707885999859, 28.78342056300022],
              [-76.751136779999911, 28.754360199000189],
              [-76.74887085, 28.725280762000352],
              [-76.746887206999929, 28.696180344000197],
              [-76.745178222999925, 28.667070389000116],
              [-76.743766784999934, 28.637950897000223],
              [-76.740351252999858, 28.615944974500167],
              [-76.739791869999976, 28.612340927000105],
              [-76.730377196999825, 28.584400177000191],
              [-76.72125244099982, 28.556379318000154],
              [-76.717330932999971, 28.543590546000189],
              [-76.707366943000011, 28.515810013000134],
              [-76.697692870999845, 28.487930298000208],
              [-76.685089110999968, 28.458019257000103],
              [-76.672927855999774, 28.430919647000167],
              [-76.661041259999877, 28.40373039200017],
              [-76.649436950999871, 28.376449585000216],
              [-76.638107299999831, 28.349079132000213],
              [-76.627037047999806, 28.321609497000281],
              [-76.616271973000039, 28.294069290000209],
              [-76.61260184266655, 28.286320412666839],
              [-76.616862068999922, 28.286111832000302],
              [-76.627649163999877, 28.286111832000302],
              [-79.18999406249992, 28.286111471000197],
              [-79.233487615999934, 28.224756491000278],
              [-79.476666669999872, 27.881666670000072],
              [-79.527222219999942, 27.433333330000323],
              [-79.571666669999956, 27.270000000000209],
              [-79.582222219999892, 27.198055560000057],
              [-79.588611109999874, 27.099444440000127],
              [-79.588055559999816, 27.007500000000221],
              [-79.577500017499858, 26.920833503000097],
              [-79.577310103999935, 26.919599006000169],
              [-79.574166669999954, 26.89916667000017],
              [-79.544722219999983, 26.7625],
              [-79.539722219999817, 26.741388890000167],
              [-79.538888889999782, 26.727500000000134],
              [-79.533611109999867, 26.68638889000016],
              [-79.525833329999955, 26.636666670000295],
              [-79.518611109999767, 26.608055560000196],
              [-79.513888889999976, 26.588888890000078],
              [-79.512777779999965, 26.580555560000164],
              [-79.51055555999983, 26.569444440000098],
              [-79.504166669999847, 26.519722220000119],
              [-79.498055559999955, 26.484444440000118],
              [-79.499444439999934, 26.425000000000239],
              [-79.498611109999899, 26.391111110000224],
              [-79.498333329999923, 26.388888890000203],
              [-79.531944439999847, 26.315555560000064],
              [-79.554722219999917, 26.256944440000211],
              [-79.556388889999937, 26.253333330000203],
              [-79.598055559999978, 26.135555560000171],
              [-79.6025, 26.129444440000043],
              [-79.609722219999924, 26.116111110000134],
              [-79.639444439999863, 26.047500000000298],
              [-79.667499999999848, 25.991388890000167],
              [-79.668888889999835, 25.9875],
              [-79.677222219999976, 25.9630555600001],
              [-79.684999999999832, 25.93805556000018],
              [-79.693888889999869, 25.900833330000239],
              [-79.69611110999989, 25.889722220000181],
              [-79.699722219999842, 25.864722220000033],
              [-79.704444439999804, 25.825555560000168],
              [-79.706388889999971, 25.806388890000051],
              [-79.70666667, 25.805277780000154],
              [-79.712222219999887, 25.773611110000161],
              [-79.712499999999864, 25.770833330000187],
              [-79.716388889999905, 25.727500000000362],
              [-79.713333329999955, 25.708333330000244],
              [-79.707499999999868, 25.676666670000088],
              [-79.707499999999868, 25.623055560000182],
              [-79.707499999999868, 25.618611110000359],
              [-79.703333329999793, 25.517222220000235],
              [-79.703055559999825, 25.46611111000027],
              [-79.703333329999793, 25.400833330000353],
              [-79.70555556, 25.372222220000026],
              [-79.70222221999984, 25.35777778000022],
              [-79.690332679999926, 25.282927704000144],
              [-79.690000020999889, 25.280833462500141],
              [-79.691944439999929, 25.265555560000223],
              [-79.691944439999929, 25.177222220000147],
              [-79.693333329999916, 25.163888890000237],
              [-79.695833329999914, 25.150555560000157],
              [-79.708333329999789, 25.064722220000135],
              [-79.715833329999953, 25.049444440000116],
              [-79.734619483999836, 25.008604988000172],
              [-79.734999999999786, 25.00777778000014],
              [-79.73537088799992, 25.007027378000032],
              [-79.746944439999822, 24.983611110000027],
              [-79.76611111, 24.923888890000057],
              [-79.823611109999945, 24.737777780000044],
              [-79.826482566999857, 24.722600079000188],
              [-79.827500001333306, 24.717222253000045],
              [-79.829166732999909, 24.716574046000176],
              [-79.8475, 24.709444440000198],
              [-79.882777779999913, 24.695833330000198],
              [-79.99972222, 24.641666670000177],
              [-80.064444439999875, 24.606944440000348],
              [-80.212222219999944, 24.554444440000168],
              [-80.222777779999831, 24.55083333000016],
              [-80.254722219999962, 24.536388890000183],
              [-80.282222219999767, 24.523611110000218],
              [-80.296666669999865, 24.515277780000133],
              [-80.32277778, 24.503333330000203],
              [-80.329166669999893, 24.501111110000181],
              [-80.351666669999929, 24.493333330000041],
              [-80.41, 24.471111110000095],
              [-80.419722219999983, 24.467777780000347],
              [-80.455833329999848, 24.455833330000246],
              [-80.491944439999884, 24.44111111000035],
              [-80.539722219999931, 24.418055560000141],
              [-80.602777779999883, 24.391111110000111],
              [-80.649166669999943, 24.37527778000009],
              [-80.664444439999784, 24.368055560000073],
              [-80.756111109999836, 24.324722220000069],
              [-80.763333329999909, 24.320555560000287],
              [-80.780555559999982, 24.310000000000173],
              [-80.781944439999961, 24.309166670000138],
              [-80.996666669999854, 24.163611110000147],
              [-80.997777779999865, 24.16277778000017],
              [-81.018888889999801, 24.14888889000008],
              [-81.032777779999947, 24.140000000000214],
              [-81.051944439999772, 24.12388889000016],
              [-81.151666669999827, 24.038333330000228],
              [-81.187777779999863, 23.999444440000048],
              [-81.215277779999781, 23.925000000000182],
              [-81.328888889999973, 23.897222220000288],
              [-81.5, 23.847222220000219],
              [-81.66666666999987, 23.833333330000187],
              [-81.833333329999903, 23.817500000000166],
              [-82.003333329999862, 23.817500000000166],
              [-82.16666666999987, 23.82777778000019],
              [-82.386840863999907, 23.850284470000076],
              [-82.416666669999813, 23.853333330000112],
              [-82.66666666999987, 23.853333330000112],
              [-82.674899076999907, 23.85191501300028],
              [-82.814999999999884, 23.82777778000019],
              [-82.853333329999828, 23.825],
              [-83.0, 23.822777780000251],
              [-83.25, 23.830555560000164],
              [-83.430555559999846, 23.855555560000141],
              [-83.55055555999985, 23.873611110000127],
              [-83.693333329999916, 23.900555560000214],
              [-83.80333333, 23.929166670000143],
              [-83.999999999999886, 23.976666670000327],
              [-84.491111109999906, 24.159722220000276],
              [-84.644444439999859, 24.221666670000275],
              [-84.768888889999857, 24.277500000000146],
              [-84.999999999999773, 24.391111110000281],
              [-85.105555559999971, 24.443055560000289],
              [-85.531944439999904, 24.648611110000104],
              [-85.72, 24.737500000000182],
              [-85.999999999999886, 24.898611110000047],
              [-86.553333329999816, 25.206944440000143],
              [-86.548612037999987, 25.227778175000083],
              [-86.546025037999982, 25.242072176000192],
              [-86.540331037999863, 25.270772176000207],
              [-86.534356037999942, 25.299425176000227],
              [-86.528106037999862, 25.328031176000081],
              [-86.521578037999973, 25.356584176000183],
              [-86.514773037999873, 25.385084176000191],
              [-86.507692037999902, 25.41352817600017],
              [-86.500337037999827, 25.441917176000231],
              [-86.492703037999888, 25.470245176000162],
              [-86.484798037999951, 25.498509176000198],
              [-86.47661403799998, 25.526711176000219],
              [-86.468159037999897, 25.554847176000067],
              [-86.459428037999942, 25.582914176000145],
              [-86.450425037999764, 25.610909176000064],
              [-86.441148037999881, 25.638831176000224],
              [-86.431598037999834, 25.666681176000225],
              [-86.4217780379999, 25.694450176000146],
              [-86.411687037999911, 25.722142176000034],
              [-86.40132303799993, 25.749753176000183],
              [-86.390689037999834, 25.777278176000152],
              [-86.379789037999842, 25.80472017600016],
              [-86.368617037999854, 25.832070176000229],
              [-86.357178037999859, 25.859334176000289],
              [-86.345473037999852, 25.886503176000076],
              [-86.333500038000011, 25.913578176000101],
              [-86.321262037999873, 25.940559176000193],
              [-86.308756037999842, 25.967439176000255],
              [-86.295989037999902, 25.994217176000234],
              [-86.282959037999888, 26.020895176000295],
              [-86.269664037999917, 26.047467176000055],
              [-86.256109037999977, 26.073931176000201],
              [-86.242289037999853, 26.100289176000217],
              [-86.228214037999862, 26.126534176000121],
              [-86.213878037999848, 26.152664176000144],
              [-86.199284037999917, 26.178681176000225],
              [-86.184434037999836, 26.204581176000204],
              [-86.17422303799998, 26.221889176000161],
              [-86.183884037999917, 26.241631176000283],
              [-86.196678037999845, 26.268411176000029],
              [-86.209220038000012, 26.295289176000097],
              [-86.221506037999859, 26.322264177000307],
              [-86.233537037999838, 26.349334177000287],
              [-86.245312037999952, 26.376495177000152],
              [-86.256831037999973, 26.403745177000076],
              [-86.258389037999791, 26.407722177000153],
              [-86.273575037999876, 26.411070177000056],
              [-86.304953037999951, 26.418275177000055],
              [-86.336264037999911, 26.425720177000187],
              [-86.367503037999768, 26.433400177000067],
              [-86.398673037999913, 26.441322177000245],
              [-86.429770037999788, 26.449481177000337],
              [-86.460792037999909, 26.45787517700029],
              [-86.49173403799989, 26.466506177000156],
              [-86.522598037999899, 26.475372177000281],
              [-86.553378037999835, 26.484472177000214],
              [-86.584073037999872, 26.49380917700023],
              [-86.610056038000039, 26.501945177000152],
              [-86.611998037999967, 26.500409177000051],
              [-86.636237037999933, 26.481056177000198],
              [-86.66065303799985, 26.461895177000201],
              [-86.685248037999941, 26.442917177000254],
              [-86.710017038000046, 26.424131177000163],
              [-86.734959037999886, 26.405536177000158],
              [-86.760070037999867, 26.387131177000185],
              [-86.785353037999869, 26.368920177000291],
              [-86.810800038000025, 26.350903177000248],
              [-86.836417038000036, 26.333084177000273],
              [-86.862195037999868, 26.315461177000202],
              [-86.888134037999919, 26.29803617600021],
              [-86.91423403799979, 26.280811176000157],
              [-86.940489037999839, 26.263786176000167],
              [-86.966903037999884, 26.24696417600029],
              [-86.993470038999988, 26.230345176000299],
              [-87.020189038999774, 26.213928176000138],
              [-87.047056038999926, 26.197720176000246],
              [-87.07407303899987, 26.181717176000234],
              [-87.101237038999841, 26.165920176000043],
              [-87.12854503899996, 26.150334176000285],
              [-87.155995038999833, 26.134959176000166],
              [-87.183584038999868, 26.119795176000139],
              [-87.211312038999893, 26.104842176000147],
              [-87.239175038999917, 26.090103176000298],
              [-87.267175038999937, 26.075578176000192],
              [-87.295306038999854, 26.061267176000229],
              [-87.323567038999897, 26.047172176000174],
              [-87.351956038999845, 26.03329717600019],
              [-87.380470038999988, 26.019639176000283],
              [-87.409109038999929, 26.006200176000164],
              [-87.437870038999847, 25.992984176000164],
              [-87.466750038999919, 25.979986176000295],
              [-87.495750038999972, 25.967211176000035],
              [-87.524864038999965, 25.954659176000291],
              [-87.554092038999784, 25.942331176000266],
              [-87.58343403899994, 25.930228176000185],
              [-87.61288403899988, 25.918350176000274],
              [-87.642439038999953, 25.906697176000307],
              [-87.672103038999865, 25.895272176000162],
              [-87.701870038999971, 25.884078176000173],
              [-87.731737038999825, 25.873111176000293],
              [-87.761703038999883, 25.862372176000292],
              [-87.791764038999872, 25.85186417600022],
              [-87.821923038999842, 25.841589176000184],
              [-87.852173038999865, 25.831545176000134],
              [-87.882514038999943, 25.821734176000177],
              [-87.912945038999965, 25.812153176000038],
              [-87.943459038999833, 25.802809176000153],
              [-87.97405903899994, 25.793700176000186],
              [-88.004739038999958, 25.784825176000027],
              [-88.035500038999885, 25.776186176000238],
              [-88.066339039999946, 25.767784176000305],
              [-88.097253039999828, 25.75961717600029],
              [-88.128242039999975, 25.751689176000184],
              [-88.159300039999891, 25.74400017600027],
              [-88.190428039999858, 25.736547176000158],
              [-88.22162304, 25.729336176000231],
              [-88.25288104, 25.722364176000326],
              [-88.284200039999916, 25.715634176000151],
              [-88.315584039999862, 25.709142176000171],
              [-88.34702303999984, 25.702892176000148],
              [-88.384872219999977, 25.699033330000077],
              [-90.494722219999858, 25.78111111000004],
              [-91.09024721999981, 25.703625000000216],
              [-91.204925232999813, 25.706199939000214],
              [-91.232303041999955, 25.708200176000048],
              [-91.264364041999784, 25.710789176000219],
              [-91.296400042999778, 25.713625176000221],
              [-91.328409042999851, 25.71670617600023],
              [-91.360389042999827, 25.72003417600007],
              [-91.392337043, 25.723606176000033],
              [-91.424253042999851, 25.727422176000289],
              [-91.456134042999963, 25.731484176000322],
              [-91.48797304299984, 25.735789176000139],
              [-91.519775042999981, 25.740339176000191],
              [-91.55153404299989, 25.745134176000306],
              [-91.583248042999969, 25.750172176000035],
              [-91.614914042999828, 25.755453176000174],
              [-91.646534042999917, 25.760975176000215],
              [-91.678100042999972, 25.766742176000264],
              [-91.709612042999822, 25.772750176000159],
              [-91.741070042999866, 25.779000176000181],
              [-91.772470042999828, 25.785492176000162],
              [-91.803809042999944, 25.792222176000109],
              [-91.83508704299993, 25.799195176000296],
              [-91.866298042999972, 25.806406176000053],
              [-91.897445042999891, 25.813856176000286],
              [-91.928523042999984, 25.821545176000143],
              [-91.959528042999921, 25.829472176000024],
              [-91.990459042999873, 25.83763617600016],
              [-92.02131704299984, 25.846039176000147],
              [-92.052098043, 25.854675176000057],
              [-92.082798043000025, 25.863550176000047],
              [-92.113414043000034, 25.872659176000184],
              [-92.143948042999909, 25.882003176000069],
              [-92.174395042999834, 25.891581176000159],
              [-92.204756042999918, 25.901392176000286],
              [-92.235023042999956, 25.911436176000166],
              [-92.265200042999936, 25.921711176000144],
              [-92.295281042999989, 25.932217176000279],
              [-92.305356042999961, 25.935547176000284],
              [-92.335442042999887, 25.946053176000191],
              [-92.36543104399999, 25.956789176000143],
              [-92.395317043999796, 25.967756176000194],
              [-92.425106043999961, 25.978950176000183],
              [-92.454789043999938, 25.990372176000051],
              [-92.484367043999953, 26.002022176000139],
              [-92.513839043999894, 26.01390017600005],
              [-92.543200043999889, 26.026000176000252],
              [-92.572450043999936, 26.038328176000277],
              [-92.601587043999871, 26.050878176000026],
              [-92.63060604399999, 26.063653176000287],
              [-92.659509043999833, 26.076647176000165],
              [-92.688292043999866, 26.089864176000049],
              [-92.716953043999865, 26.103303176000168],
              [-92.745489043999896, 26.116959176000137],
              [-92.773898043999907, 26.130834176000292],
              [-92.802181043999781, 26.144925176000186],
              [-92.83033104399999, 26.159234176000155],
              [-92.858350043999963, 26.173756176000211],
              [-92.886237043999813, 26.18849517600006],
              [-92.913984043999847, 26.203447176000168],
              [-92.941595043999826, 26.2186091760002],
              [-92.969064043999765, 26.233984176000092],
              [-92.996392043999947, 26.249570176000248],
              [-93.016917043999911, 26.261500176000027],
              [-93.039245043999927, 26.245620176000273],
              [-93.064834043999838, 26.227795176000143],
              [-93.090587043999847, 26.210170176000247],
              [-93.116500043999906, 26.192742176000309],
              [-93.142575043999898, 26.175517176000255],
              [-93.168806043999894, 26.15848917600016],
              [-93.195195043999831, 26.141664176000177],
              [-93.221737043999951, 26.125042176000136],
              [-93.248428043999866, 26.108625176000203],
              [-93.275273043999789, 26.092414176000204],
              [-93.302264043999799, 26.076409176000027],
              [-93.329403043999889, 26.060611176000123],
              [-93.356684043999905, 26.045025176000195],
              [-93.384106043999907, 26.029645176000201],
              [-93.411670043999777, 26.014478176000296],
              [-93.439373044999911, 25.999525176000304],
              [-93.445052779999912, 25.996744440000271],
              [-95.657222219999824, 26.008333330000255],
              [-96.808055559999843, 26.008611110000118],
              [-96.924269439999819, 25.97515833000017],
              [-97.138583410066019, 25.95652026926382],
              [-97.138617886108136, 25.959326984114171],
              [-97.140735825637478, 25.966427126842518],
              [-97.16084602294768, 25.967218043176047],
              [-97.265290907051792, 25.941109085263747],
              [-97.315290971289755, 25.920000100247591],
              [-97.346682036959805, 25.893053957844018],
              [-97.344735926675185, 25.859654085253339],
              [-97.364726933442455, 25.839854018240885],
              [-97.417226816490597, 25.843327143650356],
              [-97.514445997123119, 25.898327013146456],
              [-97.559446088464881, 25.951109031056035],
              [-97.614455010416577, 26.005000142396966],
              [-97.648055042855731, 26.029444957248245],
              [-97.678899943713134, 26.038054010023586],
              [-97.803346060506613, 26.058054069246396],
              [-97.84750896735693, 26.063609091772491],
              [-97.979735837251781, 26.058609118876291],
              [-98.033335928913687, 26.047773162058775],
              [-98.060145949209584, 26.038891194515315],
              [-98.200008900556782, 26.062500165979316],
              [-98.286117868499275, 26.097773226296908],
              [-98.30528191669697, 26.109164232744149],
              [-98.361390879624366, 26.15360910681801],
              [-98.388063939621873, 26.191664120807275],
              [-98.439872986208229, 26.223327095417119],
              [-98.578335824445048, 26.256109054104115],
              [-98.604735802036259, 26.25666410373384],
              [-98.695563949117854, 26.289718138998637],
              [-98.730008877399371, 26.314445088711295],
              [-98.784181955963902, 26.348891190459142],
              [-98.925290961040503, 26.391391127714826],
              [-98.97557299250218, 26.405827111956285],
              [-99.104735941501758, 26.435000158401863],
              [-99.132508874837015, 26.526945110265558],
              [-99.200564061479554, 26.714445099700868],
              [-99.239726827795664, 26.803609103721385],
              [-99.253346079389303, 26.830826987426803],
              [-99.271126945921026, 26.859927111314619],
              [-99.31924594344332, 26.869853966910966],
              [-99.458617882900967, 27.046944973677796],
              [-99.443346055272684, 27.258053934577106],
              [-99.473199879895958, 27.47666419592548],
              [-99.503899941466386, 27.568054098159507],
              [-99.530835857948205, 27.600064251199683],
              [-99.606181959269236, 27.64124504452991],
              [-99.653626884136088, 27.641391057283613],
              [-99.69639990379784, 27.65694501977373],
              [-99.713899976572577, 27.668936002851808],
              [-99.72743591204835, 27.689164217479743],
              [-99.741399995139602, 27.714718125762147],
              [-99.758899900276333, 27.727144967806794],
              [-99.78229999471273, 27.737636093126525],
              [-99.798199962166734, 27.766664132646724],
              [-99.806699882562611, 27.771745074727846],
              [-99.83292685767185, 27.774300046460894],
              [-99.860908835672944, 27.805136062501219],
              [-99.871945958166975, 27.855554048432452],
              [-99.880835804699757, 27.903891143076009],
              [-99.935008883264402, 27.961664249064256],
              [-99.962235819425246, 27.984718003260582],
              [-100.050845947282312, 28.116391164630272],
              [-100.078335907565588, 28.15561813632462],
              [-100.108473040516969, 28.165691172313075],
              [-100.186399929832987, 28.197500159676622],
              [-100.24028198871838, 28.242773165786502],
              [-100.281463955515108, 28.280554091541148],
              [-100.331264027542829, 28.400409104676612],
              [-100.329590999664276, 28.42541802161287],
              [-100.350845997434078, 28.494718088518482],
              [-100.359172915347926, 28.51832705998271],
              [-100.399445948561606, 28.571109077892288],
              [-100.443890990273644, 28.626391082250166],
              [-100.479863939508391, 28.675836096858177],
              [-100.492372924204233, 28.704164247461392],
              [-100.491817874574338, 28.725709091443719],
              [-100.527364017298282, 28.822773206867112],
              [-100.564454944781573, 28.863609168699782],
              [-100.590564070331766, 28.894718099508225],
              [-100.621946083546277, 28.933326989660713],
              [-100.634455068242346, 28.958609156641444],
              [-100.628208873978394, 28.995826986139207],
              [-100.665835907990413, 29.109027103410767],
              [-100.795708971829072, 29.258744993625101],
              [-100.936954937204064, 29.350000114855504],
              [-101.025281924371143, 29.437773225859246],
              [-101.042999926628852, 29.461500046882179],
              [-101.106673054279042, 29.48208214546321],
              [-101.139035917806098, 29.490691198238551],
              [-101.218608845272172, 29.540273173145124],
              [-101.310835931997531, 29.615273101864034],
              [-101.355835855701343, 29.660553986962952],
              [-101.369355027370773, 29.692473112534572],
              [-101.405008961369731, 29.772782138739728],
              [-101.45668188584807, 29.772218036654294],
              [-101.539173056731954, 29.771109110861175],
              [-101.628764034901337, 29.766391105188632],
              [-101.706682039399993, 29.778054020575496],
              [-101.745008962329052, 29.788891150859513],
              [-101.774735884937854, 29.796664192609853],
              [-101.825835822513625, 29.804718028117691],
              [-101.892226866095143, 29.806109088772473],
              [-101.990845992200036, 29.805827121548816],
              [-102.048200003028569, 29.798473174958303],
              [-102.072781945816018, 29.798191207734703],
              [-102.097500010711371, 29.802773091299343],
              [-102.12194599902881, 29.810554012038892],
              [-102.227781943787988, 29.847218133011381],
              [-102.261255074212613, 29.867918081151288],
              [-102.301808901183506, 29.887982178114555],
              [-102.355564058054711, 29.850553962846334],
              [-102.496254973799751, 29.781664106283415],
              [-102.560835861566375, 29.767773113387875],
              [-102.670290944488613, 29.742773081268922],
              [-102.804763996802762, 29.474082121774018],
              [-102.851117933149339, 29.352218134080147],
              [-102.895845947913301, 29.254164115888784],
              [-102.954999888634802, 29.183054060806398],
              [-103.051955039316681, 29.0941641450205],
              [-103.163691005737348, 28.984027110453951],
              [-103.290872975734942, 28.996518157877006],
              [-103.296254995778895, 28.997364227185869],
              [-103.37499995884707, 29.023609139568066],
              [-103.401117969214681, 29.036391206670032],
              [-103.461945943643116, 29.073053986538071],
              [-103.484999865477334, 29.088891089718231],
              [-103.531399902291355, 29.126109086853944],
              [-103.735835862416195, 29.199164246392684],
              [-103.889173058431595, 29.285827090498628],
              [-103.975008943968021, 29.305826982083431],
              [-104.023617947551031, 29.321109035633526],
              [-104.045290867014273, 29.330827014202384],
              [-104.063608845903161, 29.342500155511232],
              [-104.080835836271547, 29.355273170157943],
              [-104.169726925523889, 29.42277313953258],
              [-104.202435794014946, 29.460973160447054],
              [-104.228346100821227, 29.494444949767399],
              [-104.252790915672506, 29.508327057845179],
              [-104.285135841926547, 29.525973143373619],
              [-104.316746010546069, 29.530900193712],
              [-104.33805498777258, 29.525000172050397],
              [-104.419173035274355, 29.569445046124258],
              [-104.453335828694208, 29.595835971260158],
              [-104.541817881070358, 29.672918132371592],
              [-104.678064041187099, 29.940973240689857],
              [-104.695008896693935, 30.005000084655023],
              [-104.701326840049262, 30.058882143540586],
              [-104.679308921450286, 30.105418134824561],
              [-104.674999952653934, 30.167773124377447],
              [-104.682235882047337, 30.187500101194132],
              [-104.705564059754238, 30.233326983467464],
              [-104.776946023776475, 30.318053943210714],
              [-104.822235793692698, 30.386109129853082],
              [-104.865008980992627, 30.466391166329913],
              [-104.879726932457629, 30.525000115705211],
              [-104.896526948677263, 30.566245114414073],
              [-104.934726801953616, 30.607636125876411],
              [-104.990845990802995, 30.63221806866386],
              [-105.061955040057001, 30.694164188978959],
              [-105.124999860243292, 30.749718102276461],
              [-105.169446075421718, 30.778609013860432],
              [-105.222572924829137, 30.804718139410511],
              [-105.253063941733501, 30.797218012428289],
              [-105.39306402101704, 30.865836127689761],
              [-105.490708834694203, 30.946391078934539],
              [-105.541399903031845, 30.996391143172616],
              [-105.580699964922246, 31.05791800068954],
              [-105.60000885240737, 31.081527139791717],
              [-105.710008926675584, 31.144164096568431],
              [-105.771664027311544, 31.178264193352391],
              [-105.786954965679342, 31.208191108171775],
              [-105.82751784510566, 31.254444964593915],
              [-105.841945950357911, 31.269164257163425],
              [-105.858064014933177, 31.282500032790836],
              [-105.972781927236099, 31.369718094164682],
              [-106.011672952250478, 31.39527317591336],
              [-106.06292694920694, 31.402500052851508],
              [-106.143617854921672, 31.43194500823688],
              [-106.209872944033179, 31.472218041450617],
              [-106.272226927757913, 31.559300148354566],
              [-106.289172956731306, 31.59750016926921],
              [-106.303346084488282, 31.637500120076822],
              [-106.334308834904846, 31.687354003922849],
              [-106.395846085981816, 31.74750019434515],
              [-106.416535808199853, 31.754027014727967],
              [-106.439182034262444, 31.751664156216293],
              [-106.460008884416879, 31.75027309556134],
              [-106.49639992298313, 31.757082218443941],
              [-106.52288204322565, 31.780754054182125],
              [-106.539564042248173, 31.782054087368124],
              [-106.608335881614323, 31.783609098049567],
              [-107.075290870632443, 31.783336015643272],
              [-107.141681914213905, 31.783882180455862],
              [-107.174726897023106, 31.783336015643272],
              [-107.241954957457949, 31.783882180455862],
              [-107.274726857861083, 31.783336015643272],
              [-107.341135838715275, 31.783882180455862],
              [-107.374999901105184, 31.783336015643272],
              [-107.441390944686589, 31.783882180455862],
              [-107.50778198826788, 31.783336015643272],
              [-107.575008875236279, 31.783882180455862],
              [-107.608063916329513, 31.783336015643272],
              [-107.708072929622858, 31.783882180455862],
              [-107.908335875420903, 31.783054048419842],
              [-108.174999923084442, 31.783882180455862],
              [-108.208617892796156, 31.783336015643272],
              [-108.207781881771211, 31.699445066925421],
              [-108.208617892796156, 31.599445106087558],
              [-108.207781881771211, 31.532773101110934],
              [-108.208617892796156, 31.433327016436294],
              [-108.207781881771211, 31.366391149147091],
              [-108.208345983856475, 31.333053973192193],
              [-108.778635925357378, 31.332773179435037],
              [-108.816655064800898, 31.333173163855747],
              [-108.831935944884719, 31.332626999043157],
              [-108.832764076920739, 31.332600176953008],
              [-109.047363932864528, 31.332600176953008],
              [-109.05892693596536, 31.332873091721126],
              [-109.512217914026905, 31.333327055598488],
              [-109.879181994662758, 31.332773179435037],
              [-110.245564035940276, 31.332773179435037],
              [-110.311955079521681, 31.332773179435037],
              [-111.045835799221209, 31.333053973192193],
              [-111.417509000712244, 31.454164092512656],
              [-111.946381946850863, 31.62360912718151],
              [-112.299435923851945, 31.735545085812475],
              [-112.499999947613048, 31.79837314998224],
              [-112.867282037656196, 31.913409071692342],
              [-113.052890902091747, 31.971073212939245],
              [-113.344508881406625, 32.061082112802239],
              [-113.692008841710106, 32.167573187115295],
              [-113.858281996570497, 32.218300129998681],
              [-114.061317843584959, 32.279727075229459],
              [-114.343899963613353, 32.364945046862204],
              [-114.585535815962942, 32.437091105179945],
              [-114.79522689411715, 32.500500035241032],
              [-114.809826828385098, 32.506991148716395],
              [-114.791672967160878, 32.557782129340183],
              [-114.731390989907027, 32.685273223927766],
              [-114.721390876476448, 32.71110909943377],
              [-114.719090882238618, 32.718453993568971],
              [-114.729799937041662, 32.717709177650704],
              [-114.871345974551161, 32.707854071145618],
              [-115.025891002817616, 32.696991124599776],
              [-115.20249988251237, 32.684436039436434],
              [-115.404163947249288, 32.669718087971376],
              [-115.605563982035335, 32.654718001644767],
              [-115.907782051411687, 32.631945041205768],
              [-116.242635899082984, 32.605773219019511],
              [-116.508146093952007, 32.585000180683835],
              [-116.713054961055093, 32.568600148884912],
              [-116.914173028617597, 32.552218054358832],
              [-117.095291036957406, 32.536664259506949],
              [-117.122373100760001, 32.535327385356709],
              [-117.124000072499769, 32.534219196000265],
              [-117.238191912499872, 32.527442005000182],
              [-117.262432999999874, 32.553344000000266],
              [-117.365999887499925, 32.572480978999977],
              [-117.463727799999958, 32.589475000000164],
              [-117.82527779999981, 32.626944440000102],
              [-118.604999999999791, 31.132777780000254],
              [-121.86339857774982, 30.542510141750142],
              [-121.86621374233323, 30.542000213333495],
              [-121.868226946399886, 30.543019729800108],
              [-121.870319570999925, 30.543745680000143],
              [-121.877413570999977, 30.548442180000279],
              [-121.881172570999922, 30.552059180000242],
              [-121.884930570999813, 30.554948680000223],
              [-121.889941571, 30.558564680000075],
              [-121.894119570999919, 30.561817680000104],
              [-121.898710570999867, 30.565431680000188],
              [-121.903305570999919, 30.568681680000168],
              [-121.908316570999887, 30.571934680000197],
              [-121.913741570999775, 30.57554518000012],
              [-121.920427571, 30.579881680000312],
              [-121.92752257099977, 30.584217680000222],
              [-121.934208570999829, 30.588192680000134],
              [-121.940885570999967, 30.592170680000322],
              [-121.947566570999811, 30.596503180000298],
              [-121.954249570999878, 30.600114681000264],
              [-121.960513570999922, 30.604089681000175],
              [-121.967194571, 30.608062681000263],
              [-121.973458570999981, 30.612034681000296],
              [-121.980558570999904, 30.61637018100015],
              [-121.986822570999891, 30.620345181000289],
              [-121.992663570999923, 30.623595681000268],
              [-121.997258570999804, 30.626845681000248],
              [-122.001855570999965, 30.630453681000176],
              [-122.006866570999932, 30.634064181000099],
              [-122.011038570999801, 30.637314681000305],
              [-122.016049570999826, 30.640564681000285],
              [-122.021060570999907, 30.644537681000202],
              [-122.026072570999929, 30.647787181000297],
              [-122.030666570999983, 30.651395681000224],
              [-122.035677570999894, 30.654645681000034],
              [-122.040266570999904, 30.658259681000288],
              [-122.04569957099983, 30.661867181000162],
              [-122.050710570999968, 30.665117181000141],
              [-122.054885570999772, 30.66872568100024],
              [-122.059894570999802, 30.672337681000158],
              [-122.06490557099994, 30.675945181000031],
              [-122.069502570999987, 30.679195181000182],
              [-122.073674570999856, 30.682442681000111],
              [-122.078685570999824, 30.685689681000213],
              [-122.082860570999856, 30.688939181000137],
              [-122.087872570999934, 30.692187181000293],
              [-122.092883570999959, 30.695434681000222],
              [-122.09789457099987, 30.699406681000255],
              [-122.102905570999894, 30.702653181000073],
              [-122.107499570999948, 30.705900681000173],
              [-122.112510570999859, 30.709509681000213],
              [-122.117105570999911, 30.713114181000208],
              [-122.121280570999772, 30.716362681000192],
              [-122.125452570999812, 30.719609681000293],
              [-122.129633570999886, 30.722856681000167],
              [-122.134222570999896, 30.726467181000146],
              [-122.138816570999779, 30.730073681000306],
              [-122.143410570999833, 30.73332068100018],
              [-122.148002570999893, 30.736567181000225],
              [-122.153013570999917, 30.740173681000158],
              [-122.158024570999828, 30.743781681000257],
              [-122.16261957099988, 30.747387181000192],
              [-122.167213570999934, 30.750992681000241],
              [-122.171388570999795, 30.753878681000288],
              [-122.175983570999847, 30.757484181000166],
              [-122.180158570999879, 30.760731681000323],
              [-122.184752570999933, 30.763975681000147],
              [-122.189341570999886, 30.767220181000141],
              [-122.193522571000017, 30.770467681000298],
              [-122.197694570999886, 30.773712681000177],
              [-122.202291570999876, 30.776956681000172],
              [-122.206463570999972, 30.780564681000101],
              [-122.211058570999967, 30.783812681000086],
              [-122.214816570999915, 30.786692681000147],
              [-122.220660570999883, 30.791381681000189],
              [-122.225255570999934, 30.794987181000067],
              [-122.229430570999909, 30.798589681000067],
              [-122.234024570999793, 30.802198681000277],
              [-122.238199570999825, 30.805800681000221],
              [-122.243208570999855, 30.809045681000327],
              [-122.247805570999844, 30.812648181000327],
              [-122.252399570999899, 30.81589268100015],
              [-122.256574570999931, 30.819498181000256],
              [-122.261583570999903, 30.823100681000255],
              [-122.26492757099993, 30.825623681000081],
              [-122.269516570999826, 30.828864681000198],
              [-122.275780570999871, 30.833912681000072],
              [-122.279958570999781, 30.837514681000187],
              [-122.284549570999957, 30.841120681000348],
              [-122.289980570999944, 30.844723181000347],
              [-122.294155570999919, 30.848323681000181],
              [-122.299166570999944, 30.852289181000174],
              [-122.303341570999976, 30.85553168100023],
              [-122.307519570999887, 30.858773181000174],
              [-122.312108570999897, 30.862375681000174],
              [-122.315866570999788, 30.865978181000173],
              [-122.320047570999861, 30.868859681000231],
              [-122.324222570999893, 30.872100681000177],
              [-122.330069570999797, 30.87642368100029],
              [-122.333405570999957, 30.880387181000287],
              [-122.339669570999888, 30.885431681000227],
              [-122.344263570999772, 30.889031181000178],
              [-122.348858570999823, 30.89299268100018],
              [-122.353449570999942, 30.896950681000305],
              [-122.35804457100005, 30.900556681000239],
              [-122.362638570999877, 30.903798681000069],
              [-122.366397570999823, 30.907395681000139],
              [-122.371408570999961, 30.911359681000192],
              [-122.375999570999909, 30.914956181000264],
              [-122.382683570999916, 30.920359681000207],
              [-122.386441570999864, 30.92432068100004],
              [-122.391030570999988, 30.928275181000231],
              [-122.39521057099995, 30.931517681000287],
              [-122.401474570999881, 30.936200681000173],
              [-122.405233570999826, 30.940156181000248],
              [-122.409822571999825, 30.943759681000301],
              [-122.41441657199988, 30.947714181000322],
              [-122.419010572, 30.951317681000205],
              [-122.425274571999921, 30.956353681000166],
              [-122.42861357199979, 30.959234181000284],
              [-122.433624571999815, 30.964273681000179],
              [-122.437383571999874, 30.96787068100025],
              [-122.44281357199992, 30.97290968100026],
              [-122.447824571999831, 30.976864181000053],
              [-122.451999571999863, 30.980823681000288],
              [-122.458677571999829, 30.985862681000128],
              [-122.464524571999959, 30.990895681000211],
              [-122.46912257199989, 30.994853181000224],
              [-122.473294571999986, 30.998812681000118],
              [-122.477891571999805, 31.002767681000137],
              [-122.481649571999981, 31.005645181000205],
              [-122.487491571999897, 31.010320181000282],
              [-122.491249571999845, 31.013920681000343],
              [-122.496260571999812, 31.019314681000196],
              [-122.502944571999876, 31.024709681000218],
              [-122.508788571999787, 31.030103681000298],
              [-122.515049571999839, 31.035498681000092],
              [-122.520483571999989, 31.040528681000069],
              [-122.523405571999774, 31.044845681000197],
              [-122.530505571999925, 31.05023768100034],
              [-122.53593057199987, 31.054914681000241],
              [-122.54178057199988, 31.059945681000102],
              [-122.547205571999825, 31.064620681000235],
              [-122.554722571999775, 31.07037068100027],
              [-122.5597335719998, 31.075042681000298],
              [-122.565160571999854, 31.079717681000204],
              [-122.570591571999842, 31.084031181000057],
              [-122.576019571999893, 31.088700681000262],
              [-122.581444571999839, 31.093370681000295],
              [-122.587294571999848, 31.097325181000087],
              [-122.593972571999814, 31.102714181000295],
              [-122.59940557199991, 31.108825681000042],
              [-122.605247571999769, 31.113853681000251],
              [-122.611094571999843, 31.11924268100006],
              [-122.616105572000038, 31.124273681000147],
              [-122.621952571999941, 31.129662181000299],
              [-122.627797571999849, 31.134692181000275],
              [-122.632808572000044, 31.14008168100014],
              [-122.636985571999787, 31.143312181000113],
              [-122.640324571999827, 31.146545681000134],
              [-122.645755571999871, 31.151212681000288],
              [-122.648260571999913, 31.154087681000306],
              [-122.652849571999923, 31.158398681000335],
              [-122.656194571999833, 31.161267181000142],
              [-122.662041571999964, 31.166653681000298],
              [-122.66496357199992, 31.169887181000149],
              [-122.670810571999823, 31.174914681000303],
              [-122.67373057199984, 31.177787181000269],
              [-122.679580571999907, 31.183173681000312],
              [-122.682499571999927, 31.186403181000173],
              [-122.686674571999788, 31.190712181000151],
              [-122.69001657199982, 31.193581681000296],
              [-122.695863571999894, 31.198967181000171],
              [-122.698785571999906, 31.202198681000027],
              [-122.703797571999814, 31.207942681000304],
              [-122.709644571999888, 31.213684681000245],
              [-122.715072571999769, 31.219067181000241],
              [-122.720497571999886, 31.224809681000181],
              [-122.725930571999868, 31.229834681000114],
              [-122.730941571999779, 31.23521418100006],
              [-122.736366571999895, 31.240239681000219],
              [-122.738872572, 31.243109181000136],
              [-122.743883571999902, 31.248134681000295],
              [-122.747227571999929, 31.251720681000165],
              [-122.751399571999798, 31.256742681000219],
              [-122.754322571999978, 31.259614681000301],
              [-122.759333571999889, 31.264634681000189],
              [-122.762674571999924, 31.268223681000165],
              [-122.767685571999948, 31.273242181000057],
              [-122.770608571999844, 31.27647018100015],
              [-122.774785571999871, 31.281137181000304],
              [-122.777708572, 31.284000181000238],
              [-122.783555571999898, 31.289742681000178],
              [-122.786060571999826, 31.292970681000099],
              [-122.791488571999878, 31.299064181000176],
              [-122.794408571999895, 31.302650181000217],
              [-122.799841571999821, 31.308031681000159],
              [-122.802763571999833, 31.311256681000202],
              [-122.807772571999976, 31.316992181000273],
              [-122.811116571999776, 31.320575681000321],
              [-122.816958571999805, 31.326314681000156],
              [-122.819885571999862, 31.329898681000032],
              [-122.824474571999872, 31.335275681000155],
              [-122.827816571999847, 31.338142181000137],
              [-122.833663571999978, 31.344234181000274],
              [-122.837002571999847, 31.347820181000145],
              [-122.841180571999985, 31.352834681000274],
              [-122.84410257199977, 31.356059681000318],
              [-122.849530571999821, 31.362153681000223],
              [-122.85245557199994, 31.365737681000269],
              [-122.856213571999888, 31.370034681000163],
              [-122.859972571999776, 31.374334181000108],
              [-122.864147571999808, 31.378278181000155],
              [-122.869572571999868, 31.381500181000263],
              [-122.873752571999773, 31.384370681000291],
              [-122.879177571999946, 31.387950181000349],
              [-122.883774571999936, 31.391531181000346],
              [-122.889199571999882, 31.394756181000162],
              [-122.893794571999877, 31.397978181000155],
              [-122.899222571999928, 31.401564181000197],
              [-122.904233571999896, 31.405145681000249],
              [-122.90882757199978, 31.408012181000231],
              [-122.914255571999831, 31.41087318100017],
              [-122.91926657199997, 31.414098181000213],
              [-122.924277571999937, 31.41732018100015],
              [-122.928872571999818, 31.4205426810002],
              [-122.933883571999957, 31.423764681000307],
              [-122.93847257199991, 31.42698968100018],
              [-122.943483571999877, 31.430212681000228],
              [-122.948494571999845, 31.433795681000163],
              [-122.95392757199977, 31.437373681000111],
              [-122.958935571999803, 31.440953681000224],
              [-122.963110571999835, 31.444175681000161],
              [-122.96812257199997, 31.447398681000209],
              [-122.972297571999775, 31.450262681000027],
              [-122.976891571999829, 31.45384268100014],
              [-122.981902571999854, 31.457423181000252],
              [-122.986497571999905, 31.460645181000189],
              [-122.991508571999816, 31.464223681000192],
              [-122.996097571999826, 31.467445681000129],
              [-123.000694571999986, 31.470667681000293],
              [-123.00528857199987, 31.473887681000065],
              [-123.010299571999838, 31.477825681000297],
              [-123.014472571999875, 31.481048681000175],
              [-123.019069571999921, 31.483906181000179],
              [-123.024080571999832, 31.487489181000342],
              [-123.028669571999842, 31.491064681000239],
              [-123.033263571999896, 31.49464268100013],
              [-123.037438571999928, 31.497506681000345],
              [-123.04203357199998, 31.500725681000233],
              [-123.046627571999863, 31.504664181000294],
              [-123.051219571999923, 31.507523181000238],
              [-123.055813571999806, 31.51110318100018],
              [-123.060824571999945, 31.515037681000081],
              [-123.065835571999912, 31.518617681000194],
              [-123.072513571999821, 31.523981681000294],
              [-123.077108571999872, 31.527914181000028],
              [-123.081705571999919, 31.531495681000251],
              [-123.086294571999872, 31.535425681000163],
              [-123.090474571999835, 31.538289681000151],
              [-123.095483572000035, 31.542581681000229],
              [-123.101747571999795, 31.54687368100025],
              [-123.10633857199997, 31.551164681000159],
              [-123.112185571999873, 31.556170181000084],
              [-123.117613571999925, 31.560462681000161],
              [-123.123460571999829, 31.564753181000242],
              [-123.128472571999907, 31.569042681000212],
              [-123.134319571999811, 31.573689681000303],
              [-123.140160571999843, 31.577981181000155],
              [-123.146008571999914, 31.582625681000195],
              [-123.151435571999798, 31.587275681000165],
              [-123.157283571999869, 31.591920681000261],
              [-123.163130571999773, 31.596212681000338],
              [-123.168141571999968, 31.600500681000199],
              [-123.186933571999987, 31.614081681000187],
              [-123.195280571999831, 31.620870681000213],
              [-123.201966571999833, 31.62658768100016],
              [-123.212819571999773, 31.634806681000214],
              [-123.220758571999852, 31.641592681000191],
              [-123.228688571999783, 31.648023681000268],
              [-123.236622571999931, 31.65481268100018],
              [-123.244558571999789, 31.661598181000272],
              [-123.25123857199992, 31.666956681000272],
              [-123.260844571999939, 31.675170682000157],
              [-123.267524571999843, 31.680884682000055],
              [-123.276708571999848, 31.689453682000305],
              [-123.282972571999835, 31.695164682000154],
              [-123.292577571999857, 31.703023682000264],
              [-123.298841571999844, 31.708378182000274],
              [-123.30844757199992, 31.716945682000187],
              [-123.314710571999854, 31.722656182000151],
              [-123.324313571999937, 31.731223682000291],
              [-123.331827571999838, 31.737645682000164],
              [-123.33851357199984, 31.743353682000304],
              [-123.348113571999988, 31.751917682000283],
              [-123.354377571999919, 31.757631682000181],
              [-123.363983572, 31.766548682000291],
              [-123.370247571999926, 31.772614682000153],
              [-123.379016571999841, 31.780817682000304],
              [-123.385280571999886, 31.786167682000155],
              [-123.394885571999851, 31.795442682000157],
              [-123.401566571999922, 31.801148682000132],
              [-123.411172571999941, 31.810064682000302],
              [-123.417435571999988, 31.815770682000277],
              [-123.427035571999909, 31.824681682000175],
              [-123.433299571999896, 31.830745182000271],
              [-123.442488571999831, 31.839656182000169],
              [-123.448752571999989, 31.8453626820002],
              [-123.457102571999826, 31.853559682000196],
              [-123.463363571999878, 31.859264182000231],
              [-123.472972571999833, 31.86888768200015],
              [-123.479649571999857, 31.875300682000159],
              [-123.487588572999869, 31.883853682000108],
              [-123.494266572999834, 31.889553682000269],
              [-123.502619572999777, 31.898817682000299],
              [-123.508883572999821, 31.90451468200024],
              [-123.518485572999907, 31.913778682000213],
              [-123.524333572999979, 31.920189182000229],
              [-123.533102572999894, 31.929448682000213],
              [-123.538949572999968, 31.935856682000178],
              [-123.548549572999889, 31.945470682000177],
              [-123.55439757299979, 31.952239682000197],
              [-123.563166572999876, 31.961492682000141],
              [-123.569013572999779, 31.967900682000106],
              [-123.577783572999863, 31.976445682000133],
              [-123.584463572999937, 31.982850182000163],
              [-123.59323357300002, 31.992106682000269],
              [-123.59949757299978, 31.999225682000258],
              [-123.607849572999953, 32.008475182000154],
              [-123.613691572999983, 32.014878682000244],
              [-123.622044572999982, 32.02448468200015],
              [-123.627894573, 32.031248682000239],
              [-123.637494572999969, 32.041206182000224],
              [-123.6437585729999, 32.048675182000238],
              [-123.652110572999902, 32.057923682000023],
              [-123.657958572999974, 32.064678682000306],
              [-123.665891572999953, 32.073567682000146],
              [-123.672155572999941, 32.079967682000188],
              [-123.68050857299994, 32.089567682000279],
              [-123.686772572999871, 32.096678682000288],
              [-123.694702572999915, 32.10592068200009],
              [-123.700552572999982, 32.111962182000298],
              [-123.706394572999955, 32.119425182000157],
              [-123.712658572999771, 32.126887682000302],
              [-123.718922572999929, 32.134350682000161],
              [-123.725185572999919, 32.141812682000307],
              [-123.731869572999813, 32.149273682000171],
              [-123.738133572999971, 32.15673418200015],
              [-123.744397572999787, 32.164192682000362],
              [-123.750660572999834, 32.17165068200012],
              [-123.757338572999913, 32.179112682000266],
              [-123.763188572999809, 32.186567182000317],
              [-123.769866572999888, 32.194025682000301],
              [-123.775713572999791, 32.201481682000292],
              [-123.784063572999855, 32.211425682000254],
              [-123.789910572999929, 32.21817068200022],
              [-123.79784457299985, 32.228109182000253],
              [-123.804105572999902, 32.235562682000193],
              [-123.809538572999884, 32.243014182000138],
              [-123.818724572999884, 32.252598682000041],
              [-123.824572572999784, 32.259342682000295],
              [-123.832088572999851, 32.268923682000036],
              [-123.83751357299991, 32.275662682000188],
              [-123.845866572999853, 32.285595682000348],
              [-123.851710572999878, 32.292689182000174],
              [-123.859647572999904, 32.302620182000283],
              [-123.865074573000015, 32.309714682000219],
              [-123.873427572999788, 32.319289682000203],
              [-123.878855572999839, 32.32638168200026],
              [-123.885955572999933, 32.335956682000244],
              [-123.891797572999963, 32.343042682000146],
              [-123.897230572999888, 32.350487182000279],
              [-123.905160572999932, 32.360414182000227],
              [-123.910588572999984, 32.36714868200022],
              [-123.918105573, 32.377070682000294],
              [-123.92353557299981, 32.384512682000206],
              [-123.928963572999862, 32.391950682000299],
              [-123.936480572999869, 32.401875682000082],
              [-123.941905572999815, 32.408956682000337],
              [-123.949422573, 32.41923118200026],
              [-123.954855572999804, 32.426317682000047],
              [-123.961949572999856, 32.436231682000198],
              [-123.967383572999779, 32.443667682000296],
              [-123.972394572999917, 32.450750682000148],
              [-123.977819572999863, 32.458539182000266],
              [-123.982830572999774, 32.465623682000285],
              [-123.988258572999825, 32.473409182000182],
              [-123.99285257299988, 32.480489682000155],
              [-123.997863572999904, 32.488275182000223],
              [-124.002458572999956, 32.495356682000306],
              [-124.007047572999852, 32.503139682000153],
              [-124.012480572999891, 32.510217182000304],
              [-124.017069572999787, 32.518000682000206],
              [-124.022080572999982, 32.525078682000355],
              [-124.027091572999893, 32.532859682000208],
              [-124.032102572999918, 32.539937682000186],
              [-124.036697572999969, 32.547717182000099],
              [-124.041294572999959, 32.554792182000199],
              [-124.046302572999934, 32.562570682000228],
              [-124.05089457299988, 32.5696456820001],
              [-124.055488573, 32.577423182000246],
              [-124.060083572999986, 32.584495182000239],
              [-124.065094572999783, 32.592270682000162],
              [-124.069685572999845, 32.599342682000213],
              [-124.074694572999874, 32.607117682000251],
              [-124.079291572999921, 32.614187682000306],
              [-124.08346357299979, 32.621962182000175],
              [-124.088060573, 32.62903118200029],
              [-124.092233572999874, 32.636803182000108],
              [-124.096413572999779, 32.643870182000057],
              [-124.101424572999917, 32.651642682000329],
              [-124.105183572999863, 32.658709182000223],
              [-124.109355572999903, 32.666478682000218],
              [-124.113530572999935, 32.673545682000167],
              [-124.117288572999826, 32.681312682000282],
              [-124.122299572999964, 32.688378182000292],
              [-124.126477572999931, 32.696145182000066],
              [-124.130652572999793, 32.703209182000137],
              [-124.135247572999845, 32.710973182000203],
              [-124.139422572999877, 32.718037682000102],
              [-124.143594572999916, 32.725800682000283],
              [-124.148191572999906, 32.732862682000189],
              [-124.152363572999832, 32.740625682000143],
              [-124.156544572999906, 32.747684183000217],
              [-124.160716572999775, 32.755448183000283],
              [-124.164891572999807, 32.762506683000026],
              [-124.168649572999868, 32.770264683000164],
              [-124.172830572999771, 32.777323183000249],
              [-124.176588572999947, 32.785081683000215],
              [-124.1803445729999, 32.792139683000357],
              [-124.184519572999875, 32.799898683000151],
              [-124.188694572999907, 32.806950683000139],
              [-124.192452572999855, 32.814709683000274],
              [-124.196210572999803, 32.821762183000317],
              [-124.200388572999941, 32.829517683000176],
              [-124.204563572999916, 32.836570183000163],
              [-124.208322572999862, 32.84432568300025],
              [-124.211241572999882, 32.851375683000242],
              [-124.214169573, 32.859128183000223],
              [-124.217505572999869, 32.866178183000159],
              [-124.221263572999987, 32.873931183000252],
              [-124.224191572999928, 32.880978183000138],
              [-124.227949572999819, 32.888731683000231],
              [-124.230869572999836, 32.895778183000232],
              [-124.234627572999955, 32.903525683000169],
              [-124.23754957299991, 32.910573683000166],
              [-124.241308572999799, 32.918320683000047],
              [-124.244649572999833, 32.925367683000331],
              [-124.247991572999865, 32.933112183000162],
              [-124.251749572999984, 32.94015618300017],
              [-124.25508857299991, 32.947903683000277],
              [-124.258430572999885, 32.95494568300029],
              [-124.261772572999917, 32.962689183000293],
              [-124.265110572999845, 32.969731683000361],
              [-124.268035572999906, 32.977473683000198],
              [-124.271794572999795, 32.9845146830001],
              [-124.275133572999891, 32.992256183000279],
              [-124.278058572999839, 32.999295183000186],
              [-124.281394572999943, 33.007034683000199],
              [-124.284322572999827, 33.014073683000163],
              [-124.287658572999931, 33.021812683000292],
              [-124.291002572999957, 33.028848683000149],
              [-124.294344572999933, 33.036587683000278],
              [-124.297263573, 33.043620683000256],
              [-124.30060857299992, 33.051356683000336],
              [-124.303527572999769, 33.058392683000193],
              [-124.306872572999964, 33.066125683000166],
              [-124.310208572999841, 33.073159683000256],
              [-124.313135572999954, 33.080892683000229],
              [-124.316055572999971, 33.087925683000208],
              [-124.318977572999927, 33.095659683000292],
              [-124.321902572999875, 33.102689183000336],
              [-124.325241572999971, 33.1104206830002],
              [-124.32774757299984, 33.117450683000072],
              [-124.331088572999874, 33.125178683000172],
              [-124.333594572999914, 33.132209683000156],
              [-124.336516572999926, 33.139937683000312],
              [-124.339441572999817, 33.14696418300008],
              [-124.34236357299983, 33.154689183000301],
              [-124.345283572999847, 33.161717683000234],
              [-124.348210572999903, 33.169092683000315],
              [-124.351133572999913, 33.176467183000284],
              [-124.353638572999785, 33.183842683000194],
              [-124.356980572999987, 33.191214683000339],
              [-124.359485572999859, 33.198587683000198],
              [-124.361991572999898, 33.205962683000223],
              [-124.364497573, 33.213331683000092],
              [-124.367002572999922, 33.220703683000238],
              [-124.369508572999791, 33.228073683000218],
              [-124.372013572999833, 33.235445183000138],
              [-124.374933572999851, 33.242814183000178],
              [-124.377024572999858, 33.250181183000279],
              [-124.380360572999905, 33.257550183000149],
              [-124.382449572999917, 33.264917683000306],
              [-124.384955572999786, 33.27228468300018],
              [-124.387044572999798, 33.27965068300017],
              [-124.38954957299984, 33.287017683000272],
              [-124.392055572999936, 33.294381683000097],
              [-124.394141572999956, 33.30174568300032],
              [-124.397066572999904, 33.309109683000145],
              [-124.399152572999924, 33.316473183000085],
              [-124.401658572999793, 33.323834183000031],
              [-124.403747572999805, 33.331198683000082],
              [-124.406252572999847, 33.338559683000199],
              [-124.408758572999943, 33.345917683000266],
              [-124.411263572999871, 33.353278183000157],
              [-124.413769572999911, 33.360637683000164],
              [-124.416274572999782, 33.367995683000174],
              [-124.418363572999795, 33.375353183000186],
              [-124.420869572999834, 33.382712683000193],
              [-124.423791572999846, 33.390067683000154],
              [-124.425880572999972, 33.397425183000166],
              [-124.427549572999908, 33.404781683000238],
              [-124.42963857299992, 33.412134683000261],
              [-124.431305572999918, 33.419489683000222],
              [-124.432974572999854, 33.426842683000189],
              [-124.43548057299995, 33.434195183000156],
              [-124.437155572999984, 33.441548683000178],
              [-124.438408572999833, 33.448900183000205],
              [-124.439238572999841, 33.456250683000178],
              [-124.441327572999853, 33.463600683000095],
              [-124.442997572999957, 33.470950683000183],
              [-124.445085572999915, 33.478300683000271],
              [-124.447177572999919, 33.485650183000189],
              [-124.44884457299986, 33.492998683000167],
              [-124.450933572999816, 33.50034518300015],
              [-124.453019572999835, 33.507692683000187],
              [-124.454691573, 33.51503768300023],
              [-124.45636057299987, 33.522384683000269],
              [-124.458449572999882, 33.529728183000145],
              [-124.459702572999902, 33.537073683000244],
              [-124.463041572999771, 33.544414683000298],
              [-124.465966572999946, 33.551759683000171],
              [-124.468472572999815, 33.559100183000169],
              [-124.471394573, 33.566442683000162],
              [-124.473899572999869, 33.573784683000156],
              [-124.476822573000049, 33.581125683000209],
              [-124.47974757299977, 33.588464683000097],
              [-124.482669572999953, 33.595803683000156],
              [-124.486010572999987, 33.603142183000216],
              [-124.488516572999856, 33.610478183000225],
              [-124.491022572999896, 33.617817183000284],
              [-124.493944572999908, 33.625153183000293],
              [-124.497285572999942, 33.632489183000303],
              [-124.499791572999811, 33.639825183000141],
              [-124.504380573, 33.648903183000186],
              [-124.509813572999917, 33.655542683000306],
              [-124.518160572999818, 33.664617683000301],
              [-124.52400857299989, 33.671603183000229],
              [-124.532360572999835, 33.68033168300019],
              [-124.537788572999887, 33.6866146830003],
              [-124.545722572999807, 33.696039183000266],
              [-124.551569573, 33.702673683000114],
              [-124.559922573999927, 33.711745683000231],
              [-124.565763573999789, 33.718378183000311],
              [-124.574533573999872, 33.727800683000282],
              [-124.579966573999798, 33.734775683000237],
              [-124.588733573999889, 33.744548683000289],
              [-124.594577573999914, 33.751873683000269],
              [-124.60334757399994, 33.761639683000226],
              [-124.60961057399993, 33.76861468300018],
              [-124.61796357399993, 33.778031683000279],
              [-124.623810573999833, 33.784656183000209],
              [-124.631744573999981, 33.794417683000347],
              [-124.637591573999885, 33.801042183000277],
              [-124.645938573999786, 33.810456683000098],
              [-124.651372573999879, 33.817078683000204],
              [-124.659302573999923, 33.826489684000194],
              [-124.665149573999827, 33.833459684000275],
              [-124.672666573999834, 33.842867684000112],
              [-124.678510573999802, 33.849487684000223],
              [-124.686024573999873, 33.859242684000151],
              [-124.691874573999939, 33.866212684000288],
              [-124.700227573999882, 33.875962684000172],
              [-124.706069573999912, 33.882931684000198],
              [-124.711916573999815, 33.890245684000149],
              [-124.719849573999909, 33.899645684000177],
              [-124.729455573999985, 33.908350684000141],
              [-124.739477573999864, 33.916706684000189],
              [-124.749499573999969, 33.925412184000152],
              [-124.759935573999826, 33.93376718400026],
              [-124.770380573999773, 33.942467684000178],
              [-124.780816573999857, 33.951167684000268],
              [-124.788333573999864, 33.957431684000198],
              [-124.796266573999844, 33.964045684000155],
              [-124.805035573999874, 33.970653684000126],
              [-124.814641573999893, 33.978306184000189],
              [-124.823824573999957, 33.985264684000242],
              [-124.833016573999771, 33.992917184000135],
              [-124.842616573999919, 33.999875684000187],
              [-124.851385573999778, 34.006831184000305],
              [-124.860574573999884, 34.014134184000056],
              [-124.87226657399998, 34.022131184000045],
              [-124.878530573999967, 34.028389684000274],
              [-124.888966573999824, 34.037084684000263],
              [-124.895652573999826, 34.043339184000274],
              [-124.905252573999974, 34.052028184000164],
              [-124.911516573999904, 34.057939684000246],
              [-124.921960573999854, 34.066973684000232],
              [-124.92863857399999, 34.073228684000298],
              [-124.938244573999839, 34.082612684000139],
              [-124.945341573999769, 34.088864684000157],
              [-124.955363573999932, 34.098245184000234],
              [-124.962463573999855, 34.10484868400016],
              [-124.97164757399986, 34.113875684000107],
              [-124.978333573999919, 34.120128684000235],
              [-124.987516573999869, 34.12881468400019],
              [-124.993780573999913, 34.134714684000187],
              [-125.002549573999829, 34.143048184000349],
              [-125.008813573999987, 34.1486061840003],
              [-125.017166573999987, 34.15728468400016],
              [-125.023430573999917, 34.163187684000093],
              [-125.032616573999974, 34.172209684000165],
              [-125.039294573999882, 34.178112184000213],
              [-125.047647573999825, 34.186787684000194],
              [-125.054327573999956, 34.193031684000289],
              [-125.063933573999975, 34.202400684000281],
              [-125.070197573999963, 34.208995684000229],
              [-125.078966573999821, 34.218012184000202],
              [-125.085230573999866, 34.224256684000295],
              [-125.093583573999808, 34.232931684000221],
              [-125.099424573999841, 34.23917368400015],
              [-125.108616573999882, 34.248537184000213],
              [-125.114880573999869, 34.255125684000177],
              [-125.122810573999914, 34.26379268400018],
              [-125.128658573999985, 34.269687184000304],
              [-125.137008574, 34.278353684000251],
              [-125.142855573999952, 34.28528718400014],
              [-125.151624573999982, 34.294300184000178],
              [-125.157888573999799, 34.301228684000193],
              [-125.167072574000031, 34.310934684000188],
              [-125.172505573999786, 34.317862684000204],
              [-125.181274573999872, 34.327217684000289],
              [-125.187116573999845, 34.333800184000097],
              [-125.195885573999931, 34.343153684000242],
              [-125.202149573999918, 34.349734684000111],
              [-125.210502573999861, 34.359428184000137],
              [-125.219272573999774, 34.368434684000363],
              [-125.225119573999848, 34.375362684000152],
              [-125.23346657399992, 34.384362184000281],
              [-125.239316573999815, 34.390942184000096],
              [-125.247247573999971, 34.400631684000302],
              [-125.25351057399979, 34.407206684000244],
              [-125.261027573999854, 34.416550684000299],
              [-125.266874573999928, 34.422781184000144],
              [-125.274391574, 34.431778684000278],
              [-125.280655573999923, 34.43835068400017],
              [-125.288588573999903, 34.44734868400019],
              [-125.29401957399989, 34.453575184000215],
              [-125.302788573999806, 34.463259684000207],
              [-125.308216573999857, 34.470178684000189],
              [-125.314063573999931, 34.47743968400016],
              [-125.320327573999919, 34.484703684000237],
              [-125.326591573999906, 34.49196418400021],
              [-125.333269573999871, 34.499223684000299],
              [-125.339116573999945, 34.506484684000156],
              [-125.345797573999846, 34.513742184000193],
              [-125.352060573999836, 34.521003184000051],
              [-125.360413573999892, 34.530678184000294],
              [-125.367508573999885, 34.537592684000288],
              [-125.373358573999894, 34.544848684000215],
              [-125.378369573999919, 34.552103684000087],
              [-125.384216573999822, 34.559359184000186],
              [-125.38922757399996, 34.566614684000285],
              [-125.393816573999914, 34.573867184000107],
              [-125.399247573999958, 34.581123684000318],
              [-125.405091573999869, 34.588375184000256],
              [-125.410102573999836, 34.595625684000083],
              [-125.415533573999824, 34.602878184000303],
              [-125.420960573999878, 34.610128684000131],
              [-125.425972573999843, 34.61737868400013],
              [-125.430983573999811, 34.624628184000073],
              [-125.435577573999865, 34.631878684000299],
              [-125.441005573999917, 34.639125684000192],
              [-125.446430573999805, 34.646373184000197],
              [-125.45102457399986, 34.653620684000146],
              [-125.456452573999911, 34.660867684000266],
              [-125.461463573999936, 34.668114184000274],
              [-125.466474573999847, 34.675359684000284],
              [-125.471485573999871, 34.682603684000298],
              [-125.476916573999802, 34.689848684000253],
              [-125.481508573999974, 34.697089184000163],
              [-125.486102574000029, 34.704334184000288],
              [-125.491949573999932, 34.711575684000081],
              [-125.496541573999821, 34.718817684000328],
              [-125.501135573999875, 34.726056684000298],
              [-125.506147573999783, 34.733298684000147],
              [-125.511158573999808, 34.740537684000287],
              [-125.515752573999862, 34.747775684000146],
              [-125.520341573999985, 34.755014684000344],
              [-125.525352573999783, 34.762250684000037],
              [-125.529947573999834, 34.769487684000183],
              [-125.534544573999881, 34.776723684000274],
              [-125.539552573999856, 34.783959684000195],
              [-125.544144573999972, 34.791195684000229],
              [-125.548738573999856, 34.798428684000271],
              [-125.553333573999907, 34.805662684000197],
              [-125.557924573999799, 34.812895684000182],
              [-125.562935574, 34.820128684000167],
              [-125.567530573999989, 34.827359684000214],
              [-125.572541573999786, 34.83458968400015],
              [-125.57713557399984, 34.84182068400014],
              [-125.581310573999872, 34.849050684000247],
              [-125.585905573999923, 34.856278684000245],
              [-125.590077573999793, 34.863509684000235],
              [-125.594674573999839, 34.870737684000176],
              [-125.598847573999876, 34.877964684000233],
              [-125.603022573999908, 34.885189184000239],
              [-125.607199573999821, 34.892414684000187],
              [-125.612210573999846, 34.899642685000174],
              [-125.616385574, 34.906864685000187],
              [-125.620144573999937, 34.914089685000249],
              [-125.624738573999821, 34.921312685000146],
              [-125.628913573999853, 34.928537685000208],
              [-125.633508574999894, 34.935759685000164],
              [-125.637683574999926, 34.942978685000071],
              [-125.641441574999817, 34.950200685000254],
              [-125.646035574999871, 34.957420685000216],
              [-125.650624574999881, 34.964639685000179],
              [-125.655219574999933, 34.97185968500014],
              [-125.659394574999965, 34.979075685000169],
              [-125.663988574999848, 34.986295685000186],
              [-125.668163575, 34.993512685000269],
              [-125.671505574999799, 35.000728685000297],
              [-125.675263574999974, 35.00794268500016],
              [-125.679022574999863, 35.015159685000071],
              [-125.683197574999895, 35.022373685000105],
              [-125.687374574999865, 35.029587685000138],
              [-125.691133574999981, 35.036800685000287],
              [-125.694891574999872, 35.044012685000155],
              [-125.699066574999904, 35.051223685000252],
              [-125.702824574999795, 35.058434685000236],
              [-125.706997574999889, 35.06564568500022],
              [-125.710755574999837, 35.072856685000147],
              [-125.714935574999913, 35.080064685000252],
              [-125.718272574999844, 35.087273685000184],
              [-125.721613574999878, 35.094481685000289],
              [-125.724958574999846, 35.101689685000167],
              [-125.72829457499995, 35.108895685000277],
              [-125.731635574999984, 35.116100685000276],
              [-125.734980574999781, 35.123306685000159],
              [-125.738316574999885, 35.130512685000213],
              [-125.74165857499986, 35.137714685000162],
              [-125.744999574999895, 35.144920685000272],
              [-125.748338575, 35.152123685000277],
              [-125.751680574999796, 35.159325685000169],
              [-125.755438574999971, 35.166525685000124],
              [-125.758360574999926, 35.17372568500025],
              [-125.762119574999872, 35.180925685000204],
              [-125.765877574999934, 35.18812568500033],
              [-125.769219574999795, 35.195325685000057],
              [-125.772560574999829, 35.202523685000187],
              [-125.775897574999874, 35.209723685000142],
              [-125.778824574999987, 35.216917685000112],
              [-125.782583574999933, 35.223773685000197],
              [-125.78466657499979, 35.224800685000275],
              [-125.793435574999876, 35.230970685000216],
              [-125.801788574999819, 35.236792685000182],
              [-125.810141574999875, 35.242617685000255],
              [-125.818491574999825, 35.249125685000138],
              [-125.826844574999882, 35.254950685000153],
              [-125.835197574999825, 35.260773685000231],
              [-125.843544574999896, 35.266592685000262],
              [-125.852735574999826, 35.272075685000289],
              [-125.861083574999896, 35.27789568500026],
              [-125.86902257499986, 35.283717685000227],
              [-125.877369574999932, 35.289881685000239],
              [-125.886138574999848, 35.295703685000205],
              [-125.89449157499979, 35.301520685000298],
              [-125.902844574999847, 35.307339685000159],
              [-125.911191574999918, 35.313500685000122],
              [-125.918708574999926, 35.319323685000199],
              [-125.927060574999928, 35.325481685000057],
              [-125.934994574999848, 35.332323685000233],
              [-125.943347574999791, 35.338142685000321],
              [-125.95169957499985, 35.343962685000292],
              [-125.9600495749998, 35.350120685000149],
              [-125.967985574999886, 35.356278685000234],
              [-125.975919574999807, 35.362437685000145],
              [-125.984272574999864, 35.36824868500014],
              [-125.992202574999794, 35.374750685000208],
              [-126.000135574999945, 35.380906685000298],
              [-126.008074574999796, 35.38706268500016],
              [-126.014752574999932, 35.392531685000051],
              [-126.023522574999788, 35.399373685000228],
              [-126.031455574999939, 35.405867685000203],
              [-126.041474574999938, 35.413731685000187],
              [-126.051497574999985, 35.421934685000167],
              [-126.061941574999935, 35.430478685000253],
              [-126.06987257499992, 35.436970685000233],
              [-126.077805574999843, 35.443467685000144],
              [-126.085741574999929, 35.449617685000248],
              [-126.092005574999973, 35.454742685000269],
              [-126.101608574999773, 35.462600685000098],
              [-126.107872574999817, 35.467723185000295],
              [-126.117477574999782, 35.476606685000263],
              [-126.124994574999846, 35.483439685000178],
              [-126.133347574999789, 35.489587685000288],
              [-126.140863574999855, 35.495392685000297],
              [-126.147541575, 35.50119868500019],
              [-126.15714757499984, 35.508709185000043],
              [-126.163410574999887, 35.514175685000282],
              [-126.171760574999837, 35.523392685000147],
              [-126.179274574999965, 35.530217685000139],
              [-126.190135575, 35.539434685000174],
              [-126.197235574999922, 35.545920685000169],
              [-126.208510574999877, 35.555137685000204],
              [-126.216024574999778, 35.561956685000041],
              [-126.225210574999892, 35.570148685000163],
              [-126.232727574999785, 35.575948685000299],
              [-126.241080574999899, 35.584475685000257],
              [-126.247344574999943, 35.590614685000162],
              [-126.256113574999858, 35.59948468500005],
              [-126.261955574999774, 35.605623685000296],
              [-126.270308574999831, 35.613809685000149],
              [-126.27615857499984, 35.619262685000137],
              [-126.284505574999969, 35.627789185000211],
              [-126.291188574999978, 35.633581685000252],
              [-126.299538574999815, 35.642787685000258],
              [-126.307891574999985, 35.651650685000277],
              [-126.317074574999822, 35.660170685000196],
              [-126.32333857499998, 35.66562568500018],
              [-126.332530574999851, 35.67448168500016],
              [-126.338794574999895, 35.680614685000307],
              [-126.346724574999826, 35.689134685000226],
              [-126.352572574999954, 35.694923685000276],
              [-126.361341574999813, 35.703781685000081],
              [-126.367185574999837, 35.709573685000237],
              [-126.375122574999864, 35.718087685000171],
              [-126.381385574999854, 35.723875685000166],
              [-126.388902574999918, 35.732387685000162],
              [-126.395166574999905, 35.738178685000207],
              [-126.403935575, 35.747370685000305],
              [-126.409777574999794, 35.753837685000178],
              [-126.418547574999877, 35.76268768500023],
              [-126.424394574999781, 35.768470685000295],
              [-126.432747574999894, 35.777320685000177],
              [-126.43901057499977, 35.783784685000171],
              [-126.447360574999948, 35.792970685000284],
              [-126.453208574999906, 35.799437685000157],
              [-126.461560574999851, 35.808623685000271],
              [-126.468238574999987, 35.815423685000155],
              [-126.476172574999907, 35.824948685000265],
              [-126.482435574999897, 35.83174868500015],
              [-126.49037257499981, 35.840589685000168],
              [-126.496216574999949, 35.846709685000292],
              [-126.504985574999807, 35.85588968500025],
              [-126.510833574999936, 35.862348685000143],
              [-126.519185574999938, 35.871867185000269],
              [-126.525449574999925, 35.878323685000169],
              [-126.533383574999846, 35.888178685000241],
              [-126.539230574999976, 35.894973685000195],
              [-126.545072574999949, 35.902109685000198],
              [-126.553008574999808, 35.911281685000233],
              [-126.558852574999833, 35.917737685000247],
              [-126.56428357499999, 35.924870685000144],
              [-126.57346957499999, 35.934042685000179],
              [-126.579316574999893, 35.940831685000205],
              [-126.586410574999945, 35.949998685000025],
              [-126.592674574999876, 35.956792685000153],
              [-126.600191574999769, 35.966295685000205],
              [-126.606038574999843, 35.973087686000213],
              [-126.611466574999895, 35.979875686000184],
              [-126.619405574999917, 35.989039186000241],
              [-126.625247574999889, 35.995487186000105],
              [-126.632347574999869, 36.004648686000166],
              [-126.638194574999829, 36.011095686000147],
              [-126.644038574999911, 36.018220686000291],
              [-126.651138574999834, 36.027378686000247],
              [-126.656985574999965, 36.033825686000228],
              [-126.662827574999937, 36.046037686000261],
              [-126.667008574999841, 36.051123686000267],
              [-126.671597574999851, 36.056548686000042],
              [-126.675777574999927, 36.061970686000336],
              [-126.680366574999937, 36.067737686000157],
              [-126.68454757499984, 36.073159686000281],
              [-126.690388574999872, 36.08027868600027],
              [-126.694983574999924, 36.086042686000212],
              [-126.699577574999978, 36.091125686000339],
              [-126.705005575999849, 36.098581686000273],
              [-126.709599575999903, 36.104006186000049],
              [-126.713774575999935, 36.109425686000293],
              [-126.719622575999836, 36.116542686000116],
              [-126.723797575999868, 36.121623686000248],
              [-126.727555575999929, 36.127723186000253],
              [-126.730897575999791, 36.13178768600028],
              [-126.734655575999909, 36.138564686000223],
              [-126.737991575999843, 36.142964686000141],
              [-126.742585575999897, 36.149742686000138],
              [-126.745930575999864, 36.154478686000289],
              [-126.750102575999961, 36.161253686000236],
              [-126.753860575999852, 36.166334686000198],
              [-126.758035575999884, 36.172764686000221],
              [-126.761377575999916, 36.177506686000186],
              [-126.766388575999827, 36.184953686000313],
              [-126.769730575999859, 36.190031686000168],
              [-126.773905575999891, 36.196803186000182],
              [-126.778080575999923, 36.201878686000214],
              [-126.782674575999977, 36.208987686000057],
              [-126.786016575999838, 36.214062686000034],
              [-126.7897745759999, 36.219817686000169],
              [-126.794363575999853, 36.226581686000259],
              [-126.798544575999983, 36.230981686000177],
              [-126.802302575999875, 36.236731686000212],
              [-126.805224575999887, 36.242145686000185],
              [-126.80898057599984, 36.247220686000162],
              [-126.813155575999815, 36.254323686000248],
              [-126.816497575999847, 36.258717686000182],
              [-126.820255575999852, 36.264470686000095],
              [-126.825266575999933, 36.271228686000256],
              [-126.828608575999965, 36.27664268600023],
              [-126.832783575999827, 36.28238768600022],
              [-126.836958575999859, 36.289150686000028],
              [-126.83988357599992, 36.29422368600018],
              [-126.844058575999952, 36.299970686000165],
              [-126.848233575999984, 36.307067686000153],
              [-126.851574575999848, 36.312137686000199],
              [-126.855747575999828, 36.318895686000189],
              [-126.859091575999798, 36.323625686000241],
              [-126.86243357599983, 36.329031686000235],
              [-126.866608575999862, 36.336128686000166],
              [-126.870780575999902, 36.341537686000265],
              [-126.873708575999785, 36.346603686000208],
              [-126.877044575999946, 36.352006686000152],
              [-126.880385575999981, 36.357409686000267],
              [-126.883730575999891, 36.362475686000209],
              [-126.887488575999839, 36.367884686000082],
              [-126.891660575999936, 36.374634686000093],
              [-126.895002575999968, 36.380039686000202],
              [-126.898760575999859, 36.385106686000199],
              [-126.901683575999868, 36.390506686000265],
              [-126.905024575999846, 36.39590668600016],
              [-126.907947575999799, 36.400970686000278],
              [-126.911705575999974, 36.408059686000286],
              [-126.915047575999949, 36.412787686000115],
              [-126.917552575999878, 36.417850686000179],
              [-126.921310575999939, 36.423925686000302],
              [-126.925069575999828, 36.430334686000151],
              [-126.927574575999984, 36.435398686000099],
              [-126.93091057599986, 36.441134686000055],
              [-126.933838575999914, 36.446195686000124],
              [-126.936760575999926, 36.451592686000254],
              [-126.940102575999958, 36.456992686000149],
              [-126.943438575999835, 36.464412686000173],
              [-126.946780575999867, 36.469470686000363],
              [-126.949702575999879, 36.474531686000034],
              [-126.953044575999911, 36.479925686000115],
              [-126.955966575999867, 36.485323686000186],
              [-126.958894575999977, 36.490717686000266],
              [-126.961813575999827, 36.496112186000232],
              [-126.965155575999972, 36.501167686000144],
              [-126.967660575999901, 36.506564686000161],
              [-126.97016657599994, 36.511956686000246],
              [-126.974341575999972, 36.517014686000039],
              [-126.977683575999833, 36.524428686000306],
              [-126.980605575999959, 36.529484686000103],
              [-126.983527575999915, 36.534878686000184],
              [-126.986869575999947, 36.54026768600022],
              [-126.989374575999818, 36.545323686000245],
              [-126.99271657599985, 36.550712686000054],
              [-126.994799575999934, 36.556106686000135],
              [-126.997727575999875, 36.561495686000171],
              [-127.000233575999914, 36.566884686000208],
              [-127.00315557599987, 36.571937686000297],
              [-127.006074575999889, 36.577328686000158],
              [-127.00900257599983, 36.582714686000259],
              [-127.011508575999869, 36.587767686000177],
              [-127.014427575999889, 36.593153686000164],
              [-127.017349575999845, 36.598542686000144],
              [-127.020277575999955, 36.603928686000302],
              [-127.024035575999847, 36.611000686000125],
              [-127.026955575999864, 36.61604868600017],
              [-127.029877575999876, 36.621434686000157],
              [-127.031966575999832, 36.627156686000149],
              [-127.034055575999787, 36.632542686000306],
              [-127.036141575999807, 36.637928686000066],
              [-127.03864757599996, 36.642975686000227],
              [-127.040735575999918, 36.648359686000163],
              [-127.042824575999873, 36.653742186000215],
              [-127.045330575999969, 36.659128686000201],
              [-127.047416575999989, 36.664509686000088],
              [-127.049505575999774, 36.669556686000249],
              [-127.051594575999957, 36.674937686000305],
              [-127.054099575999828, 36.680656686000304],
              [-127.056185575999848, 36.686037686000191],
              [-127.058688575999838, 36.691420686000242],
              [-127.0607805759999, 36.696464686000127],
              [-127.064122575999932, 36.701845686000183],
              [-127.066205575999845, 36.707562686000188],
              [-127.068297575999964, 36.712939686000254],
              [-127.070385575999978, 36.718317686000091],
              [-127.072469575999833, 36.723359686000208],
              [-127.075813575999859, 36.730759686000169],
              [-127.077902575999985, 36.735800686000175],
              [-127.079985575999899, 36.741175686000247],
              [-127.082491575999939, 36.746550686000205],
              [-127.084997575999807, 36.751931686000091],
              [-127.087085575999822, 36.75730668600022],
              [-127.08917757599994, 36.762681686000121],
              [-127.091260575999854, 36.768059686000129],
              [-127.093349575999866, 36.773434686000257],
              [-127.09627257599999, 36.77880668600028],
              [-127.097944575999861, 36.784184686000287],
              [-127.10044957599996, 36.78955668600014],
              [-127.10253557599998, 36.795267686000159],
              [-127.104624576, 36.800639686000181],
              [-127.107130575999861, 36.806012686000145],
              [-127.109635575999903, 36.811048686000277],
              [-127.111305575999893, 36.816423686000178],
              [-127.113810575999935, 36.8217956860002],
              [-127.115899575999947, 36.827164186000175],
              [-127.117569575999823, 36.832539686000302],
              [-127.119658575999836, 36.837573686000269],
              [-127.121747575999848, 36.842942686000242],
              [-127.123833575999868, 36.84831268600027],
              [-127.12550557599991, 36.853345686000353],
              [-127.128010575999838, 36.858717686000205],
              [-127.130094575999919, 36.864084686000183],
              [-127.132185575999813, 36.86945068600005],
              [-127.134274575999825, 36.875156686000196],
              [-127.135944575999986, 36.880189186000223],
              [-127.138033575999941, 36.885556686000257],
              [-127.140538575999869, 36.890925686000173],
              [-127.142208575999916, 36.896289686000102],
              [-127.144710575999909, 36.901992686000199],
              [-127.146380575999785, 36.907023686000059],
              [-127.148469575999798, 36.912387686000159],
              [-127.150560575999975, 36.917750686000147],
              [-127.152227575999916, 36.923117186000241],
              [-127.154733575999842, 36.928814686000237],
              [-127.156402575999948, 36.933845686000325],
              [-127.158077575999982, 36.939209686000027],
              [-127.160583575999851, 36.944573686000297],
              [-127.162249575999851, 36.94993468600012],
              [-127.164755575999948, 36.955631686000231],
              [-127.166844575999903, 36.960659686000042],
              [-127.168513575999839, 36.966020686000263],
              [-127.171019575999878, 36.972050686000216],
              [-127.17310857599989, 36.977075686000319],
              [-127.174777575999826, 36.982439686000191],
              [-127.177283575999923, 36.987798686000247],
              [-127.178952575999858, 36.992823686000179],
              [-127.180624575999957, 36.998181686000294],
              [-127.182710575999977, 37.003542686000287],
              [-127.184799575999989, 37.008900686000231],
              [-127.186469575999865, 37.014256686000181],
              [-127.188141575999964, 37.019281686000113],
              [-127.191899575999912, 37.026312686000097],
              [-127.196488575999865, 37.030667686000186],
              [-127.201922575999845, 37.037698686000169],
              [-127.206097575999877, 37.042720687000156],
              [-127.211108575999958, 37.048406687000238],
              [-127.214863575999971, 37.052762687000211],
              [-127.219874575999881, 37.059117687000196],
              [-127.223633575999827, 37.063134687000229],
              [-127.229483575999836, 37.06949268700015],
              [-127.233238575999849, 37.073173687000235],
              [-127.239083575999985, 37.080534687000181],
              [-127.242424575999792, 37.085220687000117],
              [-127.247435575999987, 37.091573687000164],
              [-127.251610575999848, 37.09625968700027],
              [-127.256622575999927, 37.101948687000061],
              [-127.260380575999875, 37.106292687000121],
              [-127.26538857599985, 37.111981687000309],
              [-127.268316575999961, 37.115992687000357],
              [-127.273744575999785, 37.122012687000165],
              [-127.276663575999805, 37.125689687000261],
              [-127.28167457599983, 37.132045687000186],
              [-127.285855575999904, 37.136056687000064],
              [-127.290866575999871, 37.142739687000244],
              [-127.29462457599999, 37.147089687000232],
              [-127.300049575999878, 37.15343968700023],
              [-127.30422457599991, 37.158120687000292],
              [-127.308819575999962, 37.164800687000195],
              [-127.312577575999853, 37.169148687000245],
              [-127.317588575999878, 37.175164687000063],
              [-127.320930575999853, 37.179173687000286],
              [-127.326358575999905, 37.185520187000179],
              [-127.330116575999853, 37.190198687000191],
              [-127.335127576, 37.196545687000253],
              [-127.33929957599986, 37.200887687000147],
              [-127.34348057599999, 37.206234687000062],
              [-127.346402575999946, 37.210242687000232],
              [-127.35099757599977, 37.215917687000285],
              [-127.354333575999931, 37.219925687000284],
              [-127.356008575999965, 37.225270687000204],
              [-127.360180575999834, 37.230278687000293],
              [-127.364355575999866, 37.235620687000164],
              [-127.368533575999777, 37.240962687000035],
              [-127.374377575999972, 37.248309687000244],
              [-127.378972575999853, 37.253648687000236],
              [-127.382730575999915, 37.258987687000172],
              [-127.386905575999947, 37.264331687000038],
              [-127.391083575999971, 37.269337687000188],
              [-127.395258575999947, 37.27467568700024],
              [-127.399852575999773, 37.280350687000293],
              [-127.404441575999954, 37.285687687000291],
              [-127.40903857599983, 37.29002368700003],
              [-127.413633575999881, 37.296364687000164],
              [-127.416969575999929, 37.301034687000197],
              [-127.421563575999812, 37.307373687000108],
              [-127.424908575999837, 37.311709687000246],
              [-127.428666575999898, 37.318045687000279],
              [-127.431172576, 37.322048687000176],
              [-127.436180575999799, 37.328717687000221],
              [-127.439519575999839, 37.333720687000152],
              [-127.44411357599995, 37.340389687000197],
              [-127.447455575999925, 37.345056687000181],
              [-127.45121357599993, 37.351056687000096],
              [-127.454552575999969, 37.355056687000285],
              [-127.45956357599988, 37.361725687000217],
              [-127.462905575999912, 37.365725687000236],
              [-127.467077575999781, 37.372392687000286],
              [-127.470835575999956, 37.377053687000114],
              [-127.475016575999859, 37.383053187000201],
              [-127.477522575999899, 37.38772068700024],
              [-127.482110575999911, 37.393717687000276],
              [-127.484616575999951, 37.397714687000246],
              [-127.489210575999834, 37.404712687000142],
              [-127.492555575999972, 37.409375687000136],
              [-127.496727575999842, 37.415703687000246],
              [-127.499649575999797, 37.420031687000233],
              [-127.504660576, 37.426695687000233],
              [-127.507166575999861, 37.431689687000244],
              [-127.512177575999829, 37.439014687000224],
              [-127.515519575999804, 37.444009687000175],
              [-127.519694575999836, 37.45067068700024],
              [-127.52387257599986, 37.455664687000308],
              [-127.526916575999905, 37.456545687000187],
              [-127.529413575999968, 37.461195687000156],
              [-127.533163575999879, 37.467839687000094],
              [-127.536497575999874, 37.47248468700019],
              [-127.539824575999887, 37.479125687000078],
              [-127.542324575999885, 37.48377368700028],
              [-127.546074575999967, 37.489748687000088],
              [-127.548994575999984, 37.4943926870003],
              [-127.552322575999881, 37.500700687000347],
              [-127.555655575999879, 37.505678687000284],
              [-127.558569575999968, 37.510323687000209],
              [-127.561488575999988, 37.516295687000138],
              [-127.564402575999907, 37.521273687000075],
              [-127.567316575999882, 37.526248687000304],
              [-127.570652575999986, 37.532556687000181],
              [-127.573566575999905, 37.536867187000325],
              [-127.576066575999903, 37.541842687000212],
              [-127.578983575999928, 37.546817687000271],
              [-127.58231357599999, 37.553117187000282],
              [-127.58523357599978, 37.557762687000093],
              [-127.587730575999842, 37.562734687000273],
              [-127.591060575999904, 37.568373687000189],
              [-127.593980575999922, 37.572684687000162],
              [-127.596058575999905, 37.577325687000155],
              [-127.60064157599993, 37.584287687000085],
              [-127.603141575999928, 37.588595687000179],
              [-127.607305575999931, 37.595225687000209],
              [-127.61022457599978, 37.600198687000272],
              [-127.613552575999904, 37.606492687000241],
              [-127.616472575999921, 37.611134687000288],
              [-127.618972575999862, 37.61610668700007],
              [-127.621885575999784, 37.621075687000143],
              [-127.625635575999922, 37.627370687000166],
              [-127.628549575999784, 37.63167568700004],
              [-127.630633575999923, 37.636645687000225],
              [-127.633133575999921, 37.641284687000166],
              [-127.635633575999861, 37.64625068700019],
              [-127.638966575999802, 37.651550687000338],
              [-127.6414665759998, 37.656187687000283],
              [-127.644380575999946, 37.661484687000154],
              [-127.647713575999944, 37.66810968700014],
              [-127.650627575999863, 37.672745687000202],
              [-127.653541575999782, 37.678042687000243],
              [-127.656460575999802, 37.683670687000301],
              [-127.659788575999926, 37.688303687000143],
              [-127.662288575999867, 37.693270687000222],
              [-127.664374575999943, 37.697903687000064],
              [-127.667288575999805, 37.702539687000126],
              [-127.669785575999924, 37.707834687000172],
              [-127.673122575999969, 37.713125687000286],
              [-127.675205575999883, 37.717762687000231],
              [-127.67853357599995, 37.723053187000119],
              [-127.68061957599997, 37.727687687000241],
              [-127.683116575999804, 37.732650687000216],
              [-127.685616575999802, 37.737284687000283],
              [-127.688530575999948, 37.742575687000169],
              [-127.691030575999946, 37.747864687000288],
              [-127.693949575999966, 37.752498687000184],
              [-127.696449575999964, 37.757787687000302],
              [-127.698527575999947, 37.762748687000055],
              [-127.701027575999944, 37.767378687000189],
              [-127.703527575999885, 37.772670687000357],
              [-127.706447575999903, 37.777300687000263],
              [-127.708944575999965, 37.782587687000216],
              [-127.711444575999906, 37.787217687000293],
              [-127.713944575999903, 37.792506687000241],
              [-127.716022575999887, 37.797134687000153],
              [-127.718108575999906, 37.802092687000197],
              [-127.721022575999825, 37.807378687000266],
              [-127.723519575999887, 37.812337687000195],
              [-127.726438575999907, 37.817625687000259],
              [-127.728519575999826, 37.822250687000292],
              [-127.731852575999824, 37.82918968700011],
              [-127.734352576, 37.834145687000216],
              [-127.737266575999968, 37.84042568700022],
              [-127.739766575999909, 37.84537868700005],
              [-127.742263575999971, 37.850000687000204],
              [-127.744763575999968, 37.85495668700031],
              [-127.747263575999966, 37.860242687000152],
              [-127.749347576, 37.864864687000306],
              [-127.751847575999989, 37.870150687000148],
              [-127.753930575999846, 37.874770687000307],
              [-127.756430575999843, 37.880056687000149],
              [-127.758930575999841, 37.885009687000149],
              [-127.761430575999839, 37.889628687000254],
              [-127.763927575999844, 37.894912687000101],
              [-127.766427575999842, 37.899531687000206],
              [-127.768508575999988, 37.904814687000169],
              [-127.770591575999845, 37.909431687000051],
              [-127.773505576, 37.914714687000185],
              [-127.775588575999848, 37.919334187000345],
              [-127.778088576999892, 37.924284687000295],
              [-127.780174576999968, 37.929564187000153],
              [-127.782252576999952, 37.934181687000091],
              [-127.784335576999979, 37.939462687000059],
              [-127.786422576999826, 37.944078687000285],
              [-127.788922577, 37.949359687000253],
              [-127.79099957699998, 37.954306687000269],
              [-127.793083576999834, 37.958920687000273],
              [-127.795583576999832, 37.964200687000186],
              [-127.797669576999851, 37.96881468700019],
              [-127.799747576999835, 37.974095687000158],
              [-127.801833576999911, 37.978706687000283],
              [-127.803916577, 37.983987687000251],
              [-127.806416577, 37.988598687000206],
              [-127.808494576999976, 37.993542687000229],
              [-127.810580576999826, 37.998159687000111],
              [-127.812244576999831, 38.003431687000045],
              [-127.814327576999915, 38.008709187000079],
              [-127.816413577, 38.01332068700026],
              [-127.818077576999826, 38.018595687000243],
              [-127.819741576999832, 38.023539687000266],
              [-127.821824576999973, 38.028148687000225],
              [-127.823491576999913, 38.032764687000224],
              [-127.825574576999827, 38.038034687000163],
              [-127.827238576999832, 38.043306687000097],
              [-127.828908577, 38.047917687000052],
              [-127.830988576999914, 38.053189687000156],
              [-127.832658576999847, 38.057798687000286],
              [-127.834322576999853, 38.062739687000033],
              [-127.836405577, 38.067350687000157],
              [-127.838072577, 38.072617687000218],
              [-127.840155576999848, 38.077889687000152],
              [-127.841819576999853, 38.082495687000232],
              [-127.843905576999873, 38.087767687000166],
              [-127.845569576999935, 38.092706687000259],
              [-127.847652576999849, 38.097312687000169],
              [-127.849316576999854, 38.102581687000168],
              [-127.851402576999874, 38.107187687000078],
              [-127.853066577, 38.112456687000304],
              [-127.854730576999827, 38.11705968800004],
              [-127.856816576999847, 38.122328688000266],
              [-127.858899576999875, 38.126931688000241],
              [-127.860563576999937, 38.131867188000058],
              [-127.862227576999828, 38.136475688000189],
              [-127.863897576999875, 38.141409688000181],
              [-127.866397576999873, 38.146673688000192],
              [-127.867647576999843, 38.151278688000161],
              [-127.869727576999935, 38.156539188000238],
              [-127.870974576999856, 38.161148688000253],
              [-127.873060576999876, 38.166406688000279],
              [-127.874310576999846, 38.171012688000303],
              [-127.875974576999852, 38.175945688000297],
              [-127.877644576999955, 38.180878688000234],
              [-127.879308576999847, 38.185809688000177],
              [-127.880558576999874, 38.191067688000032],
              [-127.882222576999936, 38.195673688000284],
              [-127.884308576999956, 38.200931688000139],
              [-127.885972576999848, 38.205534688000284],
              [-127.887641576999954, 38.210464688000172],
              [-127.889305576999845, 38.215720688000033],
              [-127.890555576999873, 38.220323688000235],
              [-127.892219576999878, 38.225581688000261],
              [-127.893469576999848, 38.230181688000187],
              [-127.895138576999955, 38.235437688000218],
              [-127.896388576999868, 38.240039688000138],
              [-127.898052576999874, 38.244967688000202],
              [-127.899716576999879, 38.249895188000039],
              [-127.900966576999849, 38.254820688000279],
              [-127.902635576999955, 38.260075688000029],
              [-127.903885576999869, 38.264675688000352],
              [-127.905135576999896, 38.269928188000222],
              [-127.906799576999958, 38.274525688000153],
              [-127.908049576999872, 38.279450688000168],
              [-127.90888357699987, 38.284703688000263],
              [-127.91054957699987, 38.289300688000139],
              [-127.912213576999875, 38.294553688000292],
              [-127.913463576999959, 38.299150688000168],
              [-127.914713576999873, 38.304400188000159],
              [-127.916383576999976, 38.308998688000145],
              [-127.91763357699989, 38.31392068800028],
              [-127.918880576999868, 38.318517688000156],
              [-127.920547576999866, 38.323767688000203],
              [-127.921794576999957, 38.328689688000281],
              [-127.923460576999958, 38.333612188000302],
              [-127.924710576999871, 38.338859688000127],
              [-127.92595857699996, 38.343453688000295],
              [-127.927627576999896, 38.348373688000038],
              [-127.928877576999867, 38.352967688000206],
              [-127.930127576999894, 38.358214688000203],
              [-127.931791576999899, 38.363462688000084],
              [-127.933041576999869, 38.368053188000147],
              [-127.934705576999875, 38.373300688000143],
              [-127.935127576999889, 38.377892688000145],
              [-127.936374576999867, 38.382809688000179],
              [-127.938041576999979, 38.387400688000298],
              [-127.939291576999892, 38.3926456880003],
              [-127.940124576999892, 38.394287688000247],
              [-127.93970557699987, 38.395923688000266],
              [-127.94053857699987, 38.397564688000159],
              [-127.943874576999917, 38.401170688000263],
              [-127.948872576999918, 38.40707068800009],
              [-127.95220257699998, 38.411003688000278],
              [-127.957619576999889, 38.417228688000193],
              [-127.960949576999894, 38.421162688000265],
              [-127.965947576999895, 38.426403688000107],
              [-127.969280576999893, 38.430992688000174],
              [-127.973444576999896, 38.435909688000265],
              [-127.976363576999915, 38.439839688000177],
              [-127.980527576999918, 38.444753688000162],
              [-127.983860576999916, 38.448684688000299],
              [-127.988024576999919, 38.453273688000252],
              [-127.991358576999914, 38.457203688000163],
              [-127.995108576999769, 38.461787688000186],
              [-127.998022576999915, 38.464734688000306],
              [-128.002185576999921, 38.470300688000236],
              [-128.005935576999775, 38.47390668800017],
              [-128.01010257699977, 38.47881768800022],
              [-128.013019576999852, 38.482417688000226],
              [-128.017602576999934, 38.48798468800004],
              [-128.020516576999853, 38.49093168800016],
              [-128.024680576999856, 38.496498688000145],
              [-128.028427576999775, 38.500425688000178],
              [-128.032177576999914, 38.505337688000168],
              [-128.035510576999854, 38.508934688000238],
              [-128.039260576999936, 38.514173688000142],
              [-128.042594576999875, 38.517770688000212],
              [-128.046758576999935, 38.523334688000148],
              [-128.050091576999932, 38.527917688000286],
              [-128.055505576999792, 38.534134688000051],
              [-128.05883857699996, 38.538384688000178],
              [-128.064666576999798, 38.544928188000142],
              [-128.068002576999959, 38.54951268800022],
              [-128.07341357699994, 38.556050688000084],
              [-128.077163576999794, 38.560631688000228],
              [-128.082160576999797, 38.566842688000236],
              [-128.085910576999879, 38.571748688000071],
              [-128.089660576999961, 38.576328688000331],
              [-128.094658576999961, 38.582870688000185],
              [-128.098408576999816, 38.587773688000141],
              [-128.103824576999898, 38.59430968800018],
              [-128.10757457699998, 38.598887688000275],
              [-128.11257257699998, 38.605098688000226],
              [-128.116322576999835, 38.609675688000209],
              [-128.121319576999838, 38.615884688000222],
              [-128.124649576999957, 38.620784688000128],
              [-128.127983576999839, 38.625362688000223],
              [-128.132147576999898, 38.631898688000263],
              [-128.135480576999839, 38.636470688000202],
              [-128.138813576999837, 38.641370688000279],
              [-128.143394576999981, 38.647906688000148],
              [-128.146727576999979, 38.652478688000258],
              [-128.150477576999833, 38.658684688000164],
              [-128.153810576999831, 38.662603688000274],
              [-128.157974576999834, 38.668806688000132],
              [-128.16088857699998, 38.673706688000209],
              [-128.163808576999827, 38.678934688000197],
              [-128.166722576999859, 38.682523688000174],
              [-128.170469576999835, 38.688073688000202],
              [-128.173805577, 38.692317688000173],
              [-128.17838357699992, 38.698848688000169],
              [-128.18130257699994, 38.703417688000172],
              [-128.185466576999829, 38.709620688000257],
              [-128.188799576999941, 38.71418968800009],
              [-128.192127576999837, 38.719409688000155],
              [-128.195047576999855, 38.723000688000297],
              [-128.19879757699988, 38.729198688000281],
              [-128.202124576999836, 38.733767688000285],
              [-128.205044576999853, 38.73833468800018],
              [-128.209208576999856, 38.744859688000247],
              [-128.212541576999854, 38.749428688000251],
              [-128.215872576999857, 38.754645688000267],
              [-128.218791576999877, 38.7592146880001],
              [-128.221288576999882, 38.763775188000352],
              [-128.22503857699985, 38.770300688000248],
              [-128.227952576999883, 38.774539688000345],
              [-128.231702576999851, 38.780409688000191],
              [-128.235035576999962, 38.785298688000239],
              [-128.237949576999881, 38.789864688000193],
              [-128.242113576999884, 38.796387688000209],
              [-128.245447576999879, 38.801600688000235],
              [-128.248360576999858, 38.806164688000251],
              [-128.252530576999902, 38.812681688000168],
              [-128.255858576999856, 38.817245688000298],
              [-128.258777576999876, 38.822456688000159],
              [-128.261691576999965, 38.826367688000289],
              [-128.264608576999876, 38.831909688000167],
              [-128.267941576999874, 38.836470688000077],
              [-128.270855576999963, 38.841356688000246],
              [-128.274602576999882, 38.847220688000107],
              [-128.277938576999873, 38.851453688000049],
              [-128.281272576999982, 38.857317688000307],
              [-128.284185576999903, 38.86220368800025],
              [-128.286685576999901, 38.86676468800016],
              [-128.290019576999896, 38.873273688000154],
              [-128.292933576999985, 38.877509688000146],
              [-128.296683576999897, 38.883695688000216],
              [-128.299597576999986, 38.888253688000248],
              [-128.302516576999835, 38.892809688000227],
              [-128.305844576999903, 38.899320688000216],
              [-128.308763576999922, 38.903878688000191],
              [-128.312510576999898, 38.910387688000185],
              [-128.315427576999923, 38.91494268800011],
              [-128.318341576999899, 38.919825688000174],
              [-128.321260576999919, 38.924706688000299],
              [-128.324174576999837, 38.929587688000197],
              [-128.327922576999924, 38.935767688000112],
              [-128.330835576999903, 38.940648688000238],
              [-128.33375557699992, 38.945525688000146],
              [-128.336669576999839, 38.951381688000083],
              [-128.339583576999928, 38.95593768800029],
              [-128.342083576999926, 38.960814188000199],
              [-128.345416576999924, 38.967320688000143],
              [-128.348752576999857, 38.971870688000195],
              [-128.351249576999919, 38.977723188000198],
              [-128.354163576999781, 38.98195368800009],
              [-128.357499576999942, 38.98845368800022],
              [-128.360413576999861, 38.99300668800015],
              [-128.362910576999923, 38.997559688000308],
              [-128.366247576999797, 39.002756188000205],
              [-128.369160576999946, 39.007631688000174],
              [-128.372494576999941, 39.013806688000216],
              [-128.374994576999939, 39.018356688000097],
              [-128.377908576999801, 39.022909688000254],
              [-128.380405576999863, 39.02810668800015],
              [-128.384569576999922, 39.034603688000232],
              [-128.387069576999863, 39.039150688000177],
              [-128.38915557699994, 39.044023688000152],
              [-128.391652576999945, 39.048573688000033],
              [-128.394566576999921, 39.053767688000278],
              [-128.397066576999862, 39.058967688000109],
              [-128.399566576999803, 39.063512688000287],
              [-128.4020665769998, 39.068709188000184],
              [-128.404983576999882, 39.073581688000104],
              [-128.407897576999801, 39.078123688000233],
              [-128.410397576999799, 39.083320688000185],
              [-128.412897576999967, 39.087862688000087],
              [-128.415810576999945, 39.093059688000039],
              [-128.418310576999943, 39.097600688000057],
              [-128.42123057699996, 39.102798688000291],
              [-128.423727576999966, 39.107339688000138],
              [-128.426227576999963, 39.112206688000299],
              [-128.428727576999961, 39.117400688000146],
              [-128.431641576999823, 39.122267688000193],
              [-128.433724576999964, 39.127459188000216],
              [-128.435805576999883, 39.132003688000168],
              [-128.438305576999824, 39.137192688000141],
              [-128.440388576999965, 39.142056688000253],
              [-128.44205257699997, 39.146600688000149],
              [-128.444138576999819, 39.15146468800009],
              [-128.446635576999824, 39.15633168800008],
              [-128.448722576999842, 39.161517688000174],
              [-128.451219576999904, 39.166381688000115],
              [-128.453299576999825, 39.171245688000226],
              [-128.455383576999964, 39.176109688000167],
              [-128.457469576999983, 39.180650188000129],
              [-128.459547576999967, 39.185187688000042],
              [-128.462047576999964, 39.190375689000234],
              [-128.464130576999821, 39.195562689000042],
              [-128.467466576999982, 39.201717689000191],
              [-128.470380576999844, 39.20625668900027],
              [-128.472044576999963, 39.210795689000292],
              [-128.474544576999904, 39.215978689000167],
              [-128.476627576999988, 39.221162689000266],
              [-128.478291576999823, 39.225698689000239],
              [-128.480377576999842, 39.230881189000229],
              [-128.482460576999983, 39.23574268900029],
              [-128.484541576999845, 39.240278689000263],
              [-128.487038576999964, 39.245459689000143],
              [-128.488708576999841, 39.249995689000286],
              [-128.491208576999838, 39.255175689000225],
              [-128.493288576999987, 39.259712689000082],
              [-128.495372576999841, 39.264889689000142],
              [-128.497455576999926, 39.269425689000116],
              [-128.499122576999923, 39.274281689000304],
              [-128.501205576999837, 39.279462689000297],
              [-128.502869576999842, 39.284317689000034],
              [-128.504955576999862, 39.289498689000254],
              [-128.507033576999845, 39.294028689000072],
              [-128.509116576999986, 39.299209689000122],
              [-128.511202576999835, 39.304064689000199],
              [-128.513280576999989, 39.308592689000193],
              [-128.514949576999925, 39.313445689000105],
              [-128.517030576999844, 39.318300689000182],
              [-128.519530576999841, 39.323478689000183],
              [-128.521613576999925, 39.328331689000265],
              [-128.523277576999988, 39.333184689000348],
              [-128.525363576999837, 39.338037689000203],
              [-128.527027576999842, 39.342562689000147],
              [-128.52952757699984, 39.347092689000192],
              [-128.530777576999867, 39.352264689000208],
              [-128.532860576999838, 39.357442689000152],
              [-128.534524576999843, 39.361967689000096],
              [-128.537024576999841, 39.367142689000161],
              [-128.539108576999865, 39.371992689000194],
              [-128.540772576999927, 39.376517689000138],
              [-128.542858576999947, 39.381367689000172],
              [-128.544522576999839, 39.386214689000269],
              [-128.546605576999866, 39.391389689000164],
              [-128.548272576999864, 39.396237689000202],
              [-128.549519576999842, 39.401084689000129],
              [-128.551605576999862, 39.405934689000162],
              [-128.552855576999946, 39.410453689000121],
              [-128.554938576999859, 39.414978689000066],
              [-128.556602576999865, 39.419825689000163],
              [-128.558269576999862, 39.424995689000184],
              [-128.559938576999855, 39.429517689000079],
              [-128.562016576999838, 39.434684689000221],
              [-128.563685576999887, 39.439531689000148],
              [-128.565349576999949, 39.444375689000196],
              [-128.567016576999947, 39.449220689000356],
              [-128.568685576999883, 39.453742189000138],
              [-128.570349576999888, 39.458909689000166],
              [-128.57201357699995, 39.463428689000125],
              [-128.573683576999883, 39.468595689000267],
              [-128.575347576999889, 39.473114689000056],
              [-128.577010576999839, 39.478278689000149],
              [-128.578260576999867, 39.482800689000157],
              [-128.579930576999857, 39.487642689000211],
              [-128.581597576999968, 39.492806689000304],
              [-128.583260576999862, 39.497325689000093],
              [-128.584930576999966, 39.502487689000191],
              [-128.58618057699988, 39.50700668900015],
              [-128.587844576999885, 39.512167689000307],
              [-128.58950857699989, 39.517006689000141],
              [-128.591177576999883, 39.5215256890001],
              [-128.592427576999967, 39.526687689000198],
              [-128.59367757699988, 39.531203689000279],
              [-128.595341576999886, 39.536362689000271],
              [-128.597005576999891, 39.540878689000181],
              [-128.598674576999883, 39.546039689000168],
              [-128.600341576999881, 39.550553189000027],
              [-128.601174576999881, 39.555392689000257],
              [-128.60325557699997, 39.5605486890002],
              [-128.604924576999906, 39.56506468900028],
              [-128.606588576999968, 39.570223189000217],
              [-128.60825257699986, 39.574737689000187],
              [-128.610335576999887, 39.579892689000189],
              [-128.612002576999885, 39.584728689000144],
              [-128.612835576999885, 39.58924268900023],
              [-128.61449957699989, 39.594395689000237],
              [-128.61616957699988, 39.598909689000209],
              [-128.61783557699988, 39.604064689000211],
              [-128.619919576999905, 39.608575689000247],
              [-128.621169576999989, 39.61373168900019],
              [-128.622833576999881, 39.618242689000169],
              [-128.624497576999886, 39.62307368900025],
              [-128.626166576999879, 39.627584689000287],
              [-128.62783357699999, 39.632737689000294],
              [-128.629497576999881, 39.637889689000247],
              [-128.631166576999988, 39.642400689000283],
              [-128.633244576999971, 39.647550689000241],
              [-128.634494576999884, 39.652381689000094],
              [-128.636163577, 39.656889689000252],
              [-128.637827576999882, 39.661400689000288],
              [-128.63907757699991, 39.666550689000246],
              [-128.640744576999907, 39.671698689000209],
              [-128.642827577, 39.676206689000196],
              [-128.644491576999883, 39.681356689000154],
              [-128.646160576999989, 39.68586268900026],
              [-128.647824576999881, 39.69068968900018],
              [-128.649491577, 39.695198689000222],
              [-128.651160576999928, 39.700345689000301],
              [-128.65282457699999, 39.705492689000152],
              [-128.654488576999881, 39.709998689000145],
              [-128.656158576999928, 39.715142689000174],
              [-128.658238576999906, 39.719967689000043],
              [-128.659485576999884, 39.724473689000206],
              [-128.661155576999931, 39.729617689000179],
              [-128.662822576999929, 39.734123689000171],
              [-128.664905576999843, 39.739267689000144],
              [-128.666985577, 39.743770689000257],
              [-128.668235576999905, 39.74891468900006],
              [-128.669905576999781, 39.753417689000173],
              [-128.67115257699993, 39.758239689000163],
              [-128.672402576999843, 39.763381189000143],
              [-128.674066576999905, 39.768203689000188],
              [-128.675733576999903, 39.773350689000267],
              [-128.67698357699993, 39.77784868900028],
              [-128.678652576999923, 39.782992689000082],
              [-128.680316576999928, 39.787812689000305],
              [-128.681566576999785, 39.792309689000035],
              [-128.682816576999926, 39.797128689000147],
              [-128.684480576999931, 39.801948689000142],
              [-128.685730576999845, 39.807092689000172],
              [-128.68739957699978, 39.811912689000167],
              [-128.689063576999786, 39.816731689000108],
              [-128.690313576999927, 39.821548689000053],
              [-128.691563576999783, 39.826042689000303],
              [-128.693647576999922, 39.830539689000034],
              [-128.69531057699993, 39.83567868900019],
              [-128.696974576999935, 39.840817689000289],
              [-128.698224576999849, 39.845312689000252],
              [-128.699474576999933, 39.850450689000297],
              [-128.700310576999925, 39.855267689000243],
              [-128.701558576999787, 39.859759689000271],
              [-128.702808576999928, 39.864573689000167],
              [-128.703644576999864, 39.869389689000172],
              [-128.704894576999948, 39.874525689000052],
              [-128.705722576999847, 39.87933968900029],
              [-128.706972576999931, 39.884153689000186],
              [-128.708222576999788, 39.888967689000197],
              [-128.709472576999929, 39.893459689000281],
              [-128.710308576999864, 39.897950689000254],
              [-128.711141576999864, 39.902764689000151],
              [-128.712391576999948, 39.90789568900027],
              [-128.713641576999805, 39.912387689000184],
              [-128.714891576999946, 39.917520689000355],
              [-128.716141576999803, 39.922331689000146],
              [-128.716969576999929, 39.927139689000228],
              [-128.718219576999786, 39.931950689000189],
              [-128.719469576999927, 39.936442689000273],
              [-128.720302576999927, 39.941570689000173],
              [-128.721138576999806, 39.946062689000257],
              [-128.721969576999925, 39.951189689000273],
              [-128.723219576999952, 39.955678689000251],
              [-128.724052576999952, 39.960806689000151],
              [-128.725302576999866, 39.9652956890003],
              [-128.726135576999866, 39.970103689000211],
              [-128.726966576999928, 39.975231689000168],
              [-128.728635576999807, 39.979717689000267],
              [-128.729466576999869, 39.984845689000167],
              [-128.731135576999804, 39.989331689000267],
              [-128.731966576999866, 39.994456689000287],
              [-128.732799576999867, 39.999262689000034],
              [-128.733635576999802, 40.003748689000304],
              [-128.734883576999948, 40.008873689000154],
              [-128.736133576999805, 40.013359689000254],
              [-128.737383576999946, 40.018481689000225],
              [-128.737799576999805, 40.022967689000325],
              [-128.739047576999951, 40.028089689000069],
              [-128.739883576999944, 40.032573689000174],
              [-128.741133576999971, 40.037375689000157],
              [-128.741960576999929, 40.042498689000183],
              [-128.743210576999786, 40.046981689000177],
              [-128.744047576999947, 40.052103689000148],
              [-128.745297576999803, 40.056584689000204],
              [-128.746130576999803, 40.061706689000346],
              [-128.746960576999868, 40.066506189000052],
              [-128.748210576999952, 40.070987689000162],
              [-128.74904457699995, 40.076106689000198],
              [-128.750294576999806, 40.080587689000254],
              [-128.751130576999969, 40.085706689000119],
              [-128.751544576999947, 40.090187689000174],
              [-128.752380576999826, 40.095303689000161],
              [-128.752794576999804, 40.09978468900016],
              [-128.753627576999804, 40.10458168900027],
              [-128.754458576999809, 40.109062689000098],
              [-128.754877576999945, 40.114178689000255],
              [-128.755294576999972, 40.119292689000304],
              [-128.756544576999886, 40.123773689000132],
              [-128.756958576999807, 40.128887689000351],
              [-128.757377576999886, 40.133684689000233],
              [-128.757791576999807, 40.138159689000304],
              [-128.75862757699997, 40.142637689000253],
              [-128.759458576999805, 40.147750689000191],
              [-128.759458576999805, 40.152864689000182],
              [-128.760291576999805, 40.157339689000253],
              [-128.760705576999953, 40.162453689000074],
              [-128.761127576999968, 40.166928689000144],
              [-128.76195557699981, 40.171723689000203],
              [-128.762791576999973, 40.176195689000224],
              [-128.763624576999973, 40.181306689000166],
              [-128.764041576999887, 40.18641768900028],
              [-128.764455576999808, 40.190892689000179],
              [-128.765291576999971, 40.196000689000186],
              [-128.765705576999949, 40.200792689000195],
              [-128.766124576999971, 40.205267689000266],
              [-128.766955576999806, 40.210375689000159],
              [-128.767788576999806, 40.214848689000235],
              [-128.768624576999969, 40.219956689000298],
              [-128.76903857699989, 40.224425689000213],
              [-128.769452576999811, 40.229534689000161],
              [-128.770288576999974, 40.234006189000297],
              [-128.770702576999952, 40.238795689000085],
              [-128.771124576999966, 40.243264689000227],
              [-128.771952576999809, 40.248053189000302],
              [-128.772372576999828, 40.253159689000199],
              [-128.77320257699995, 40.257628689000114],
              [-128.774038576999828, 40.262731689000304],
              [-128.774452576999806, 40.267200690000152],
              [-128.774872576999826, 40.272306690000164],
              [-128.775285576999806, 40.276773690000141],
              [-128.775285576999806, 40.281559690000165],
              [-128.776122576999967, 40.286345690000189],
              [-128.776535576999947, 40.291128690000164],
              [-128.776952576999975, 40.296231690000184],
              [-128.777785576999975, 40.300698690000104],
              [-128.778202576999888, 40.305798690000188],
              [-128.778622576999908, 40.310264690000054],
              [-128.779035576999888, 40.31504869000014],
              [-128.779449576999809, 40.320148690000224],
              [-128.779872577, 40.32461469000026],
              [-128.780285576999972, 40.329714690000174],
              [-128.78111957699997, 40.334178690000215],
              [-128.78111957699997, 40.33927569000025],
              [-128.78111957699997, 40.343739690000291],
              [-128.781949576999807, 40.348520690000271],
              [-128.782369576999827, 40.353300690000196],
              [-128.78278557699997, 40.358081690000176],
              [-128.783619576999968, 40.363178690000154],
              [-128.784035576999827, 40.367642690000253],
              [-128.784449576999975, 40.372737690000235],
              [-128.784449576999975, 40.377198690000057],
              [-128.785283576999973, 40.381978690000153],
              [-128.785699576999889, 40.387073690000193],
              [-128.786119576999965, 40.391534690000356],
              [-128.78653357699983, 40.396628690000284],
              [-128.786949576999973, 40.401087690000281],
              [-128.786949576999973, 40.406181690000039],
              [-128.786949576999973, 40.410642690000202],
              [-128.786949576999973, 40.415417690000197],
              [-128.787783576999971, 40.419873690000145],
              [-128.787783576999971, 40.424967690000244],
              [-128.788197576999949, 40.429742690000296],
              [-128.788197576999949, 40.434517690000291],
              [-128.788197576999949, 40.439609690000282],
              [-128.788619576999906, 40.444067690000168],
              [-128.788619576999906, 40.448839690000341],
              [-128.788619576999906, 40.453292690000183],
              [-128.789033576999827, 40.458387690000222],
              [-128.789033576999827, 40.463475690000166],
              [-128.789866576999827, 40.467931690000285],
              [-128.789866576999827, 40.473020190000284],
              [-128.789866576999827, 40.477475690000176],
              [-128.790283576999968, 40.482245690000354],
              [-128.790697576999889, 40.486695690000261],
              [-128.790697576999889, 40.491467690000263],
              [-128.790697576999889, 40.495920690000275],
              [-128.790283576999968, 40.501006690000281],
              [-128.790283576999968, 40.506098690000044],
              [-128.790283576999968, 40.510545690000299],
              [-128.790283576999968, 40.514998690000141],
              [-128.789866576999827, 40.520084690000147],
              [-128.789447576999976, 40.52453469000028],
              [-128.789447576999976, 40.529303190000292],
              [-128.789447576999976, 40.534387690000187],
              [-128.789447576999976, 40.538837690000321],
              [-128.789033576999827, 40.543603690000282],
              [-128.789033576999827, 40.548053190000189],
              [-128.789033576999827, 40.552500690000329],
              [-128.789033576999827, 40.557267690000174],
              [-128.789033576999827, 40.562350690000301],
              [-128.789033576999827, 40.567117690000202],
              [-128.788619576999906, 40.571881690000225],
              [-128.788619576999906, 40.576325690000203],
              [-128.788619576999906, 40.581089690000169],
              [-128.788197576999949, 40.586175690000175],
              [-128.787783576999971, 40.59061719000016],
              [-128.787783576999971, 40.595700690000115],
              [-128.787783576999971, 40.600145690000204],
              [-128.787783576999971, 40.604589690000182],
              [-128.786949576999973, 40.609353690000205],
              [-128.786949576999973, 40.613798690000294],
              [-128.786949576999973, 40.618875690000209],
              [-128.78653357699983, 40.623953690000292],
              [-128.78653357699983, 40.628714690000209],
              [-128.78653357699983, 40.633473690000358],
              [-128.786119576999965, 40.638234690000274],
              [-128.786119576999965, 40.642992690000142],
              [-128.785699576999889, 40.647753690000229],
              [-128.785283576999973, 40.652831690000255],
              [-128.785283576999973, 40.657270690000189],
              [-128.784869576999824, 40.662348690000272],
              [-128.784449576999975, 40.666787690000206],
              [-128.784449576999975, 40.671228690000305],
              [-128.783619576999968, 40.676303690000282],
              [-128.783619576999968, 40.681059690000154],
              [-128.783199576999948, 40.685814190000258],
              [-128.78278557699997, 40.690573690000235],
              [-128.78278557699997, 40.695328690000224],
              [-128.781949576999807, 40.700084690000267],
              [-128.781949576999807, 40.704839690000028],
              [-128.781535576999829, 40.709595690000242],
              [-128.78111957699997, 40.714348690000065],
              [-128.78111957699997, 40.719423690000042],
              [-128.780285576999972, 40.723856690000218],
              [-128.780285576999972, 40.728928690000089],
              [-128.779449576999809, 40.733364690000144],
              [-128.779449576999809, 40.737800690000199],
              [-128.778622576999908, 40.742870690000302],
              [-128.778202576999888, 40.74730669000013],
              [-128.777785576999975, 40.752373690000184],
              [-128.777372576999824, 40.757125690000237],
              [-128.776952576999975, 40.761875690000352],
              [-128.776535576999947, 40.76662569000024],
              [-128.776122576999967, 40.771378690000233],
              [-128.775285576999806, 40.776128690000121],
              [-128.775285576999806, 40.780878690000236],
              [-128.774872576999826, 40.785625690000245],
              [-128.774452576999806, 40.790375690000133],
              [-128.774038576999828, 40.795442690000186],
              [-128.77320257699995, 40.79987369000014],
              [-128.772788576999972, 40.804306690000317],
              [-128.771952576999809, 40.80936719000016],
              [-128.771952576999809, 40.813800690000164],
              [-128.770702576999952, 40.818864690000282],
              [-128.770288576999974, 40.823609690000126],
              [-128.770288576999974, 40.828356690000135],
              [-128.769452576999811, 40.833103690000144],
              [-128.768624576999969, 40.837848690000214],
              [-128.767788576999806, 40.842592690000288],
              [-128.767374576999828, 40.847339690000354],
              [-128.766955576999806, 40.852084690000197],
              [-128.766124576999971, 40.856828690000327],
              [-128.765705576999949, 40.861889690000169],
              [-128.765291576999971, 40.866314690000024],
              [-128.764455576999808, 40.87074269000027],
              [-128.764041576999887, 40.875487190000285],
              [-128.762791576999973, 40.879914690000305],
              [-128.762374576999889, 40.884970690000159],
              [-128.76195557699981, 40.889714690000289],
              [-128.761127576999968, 40.894456690000254],
              [-128.759877576999827, 40.899195690000113],
              [-128.759458576999805, 40.903937690000078],
              [-128.75862757699997, 40.908678190000273],
              [-128.758208576999948, 40.913103690000355],
              [-128.757377576999886, 40.918159690000152],
              [-128.756544576999886, 40.922584690000178],
              [-128.756127576999972, 40.927637690000097],
              [-128.755294576999972, 40.932062690000123],
              [-128.754458576999809, 40.937114690000158],
              [-128.753627576999804, 40.941853690000244],
              [-128.753208576999953, 40.946275190000279],
              [-128.751958576999868, 40.951328690000253],
              [-128.751544576999947, 40.956064690000289],
              [-128.750294576999806, 40.960800690000269],
              [-128.749880576999885, 40.96553769000019],
              [-128.74904457699995, 40.97027369000017],
              [-128.748210576999952, 40.975009690000149],
              [-128.747794576999809, 40.979431690000297],
              [-128.746960576999868, 40.984481690000166],
              [-128.746130576999803, 40.988900690000207],
              [-128.745297576999803, 40.993950690000247],
              [-128.74446057699987, 40.998370690000172],
              [-128.743633576999969, 41.003417690000163],
              [-128.742797576999806, 41.008150190000038],
              [-128.741960576999929, 41.012570690000246],
              [-128.741547576999949, 41.017617690000179],
              [-128.740713576999951, 41.022348690000285],
              [-128.739883576999944, 41.027081690000045],
              [-128.739047576999951, 41.031812690000152],
              [-128.738213576999954, 41.036545690000082],
              [-128.737383576999946, 41.041275190000079],
              [-128.736963576999926, 41.04569269000018],
              [-128.736133576999805, 41.050737690000176],
              [-128.735299576999807, 41.055150690000289],
              [-128.734049576999951, 41.060195690000057],
              [-128.733635576999802, 41.06461269000016],
              [-128.732385576999945, 41.069653690000166],
              [-128.731966576999866, 41.074381690000166],
              [-128.731135576999804, 41.078798690000269],
              [-128.729885576999948, 41.083839690000275],
              [-128.729466576999869, 41.088253690000272],
              [-128.728635576999807, 41.093292690000283],
              [-128.727802576999807, 41.098020190000284],
              [-128.726135576999866, 41.102748690000169],
              [-128.72446657699993, 41.107473690000177],
              [-128.723638576999804, 41.111884690000068],
              [-128.721969576999925, 41.116925690000073],
              [-128.720719576999784, 41.121337690000246],
              [-128.719469576999927, 41.126375690000202],
              [-128.717805576999865, 41.130784690000155],
              [-128.716555576999951, 41.135823690000166],
              [-128.714891576999946, 41.140548690000287],
              [-128.713641576999805, 41.144956690000186],
              [-128.711972576999926, 41.149992190000262],
              [-128.711141576999864, 41.154403690000038],
              [-128.709472576999929, 41.15943969000017],
              [-128.708222576999788, 41.164162690000353],
              [-128.706558576999782, 41.168881690000148],
              [-128.705722576999847, 41.17360369000005],
              [-128.704474576999928, 41.178012690000173],
              [-128.702808576999928, 41.182414690000257],
              [-128.70197457699993, 41.187134690000164],
              [-128.700310576999925, 41.192173690000175],
              [-128.698644576999925, 41.196575690000259],
              [-128.697397576999947, 41.201612690000275],
              [-128.696147576999863, 41.206014690000188],
              [-128.69447757699993, 41.211048690000155],
              [-128.693647576999922, 41.215767690000291],
              [-128.691977576999932, 41.220167690000039],
              [-128.690727576999905, 41.225203690000171],
              [-128.689477576999934, 41.22960369000026],
              [-128.687813576999929, 41.234637690000227],
              [-128.686563576999845, 41.239037690000316],
              [-128.685313576999931, 41.244070690000171],
              [-128.683649576999926, 41.249100690000148],
              [-128.682399576999785, 41.253500690000237],
              [-128.680730576999906, 41.258531690000325],
              [-128.679480577, 41.262931690000187],
              [-128.67781657699993, 41.267959690000225],
              [-128.67698357699993, 41.272673690000147],
              [-128.676152576999925, 41.273617690000094],
              [-128.675316576999933, 41.276917690000118],
              [-128.675109576999887, 41.278645690000076],
              [-128.674066576999905, 41.282100690000163],
              [-128.674902576999784, 41.286814690000085],
              [-128.67698357699993, 41.291528690000234],
              [-128.679480577, 41.296553690000167],
              [-128.681980576999933, 41.30095069000015],
              [-128.684063576999904, 41.305664690000299],
              [-128.686149576999924, 41.310687690000236],
              [-128.688649576999921, 41.315087690000155],
              [-128.691147576999924, 41.320109690000152],
              [-128.693227576999845, 41.324506690000192],
              [-128.696147576999863, 41.329531690000294],
              [-128.698644576999925, 41.335812690000182],
              [-128.701558576999787, 41.340206691000049],
              [-128.703644576999864, 41.345228691000273],
              [-128.705722576999847, 41.349625691000256],
              [-128.707808576999867, 41.354645691000144],
              [-128.710308576999864, 41.359042691000184],
              [-128.713222576999783, 41.365003691000254],
              [-128.715305576999924, 41.369709691000253],
              [-128.717805576999865, 41.374417691000247],
              [-128.719888576999949, 41.379123691000245],
              [-128.722388576999947, 41.38382869100036],
              [-128.72446657699993, 41.388534691000359],
              [-128.726135576999866, 41.392928691000293],
              [-128.729466576999869, 41.398573691000365],
              [-128.731966576999866, 41.403278691000253],
              [-128.735299576999807, 41.409237191000159],
              [-128.737799576999805, 41.413628691000213],
              [-128.739883576999944, 41.418331691000162],
              [-128.742383576999885, 41.424287691000188],
              [-128.744880576999947, 41.428989691000254],
              [-128.747380576999888, 41.433692691000147],
              [-128.749460576999809, 41.438084191000257],
              [-128.752380576999826, 41.443098691000216],
              [-128.754877576999945, 41.448739691000299],
              [-128.757377576999886, 41.453439691000142],
              [-128.759458576999805, 41.457828691000202],
              [-128.76195557699981, 41.462528691000045],
              [-128.764874576999887, 41.468792691000203],
              [-128.767374576999828, 41.473181691000264],
              [-128.769874576999825, 41.478192691000061],
              [-128.771952576999809, 41.482578691000242],
              [-128.774038576999828, 41.486964691000253],
              [-128.776952576999975, 41.491662691000272],
              [-128.779035576999888, 41.496045691000177],
              [-128.78111957699997, 41.50105969100025],
              [-128.784035576999827, 41.505756691000215],
              [-128.786119576999965, 41.51045369100018],
              [-128.788619576999906, 41.515148691000206],
              [-128.790697576999889, 41.519845691000171],
              [-128.79319757699983, 41.52453969100003],
              [-128.795280576999971, 41.528920691000167],
              [-128.797780576999969, 41.533931691000362],
              [-128.801113576999967, 41.539875691000248],
              [-128.803613576999908, 41.544570691000274],
              [-128.806113576999849, 41.548953691000179],
              [-128.808610576999911, 41.553648691000149],
              [-128.811110576999909, 41.559589691000212],
              [-128.813610576999849, 41.563973191000173],
              [-128.816110576999847, 41.568975691000162],
              [-128.81860857699985, 41.573356691000299],
              [-128.821522577, 41.578987691000066],
              [-128.824022577, 41.583364691000213],
              [-128.826522577, 41.587742691000244],
              [-128.82943557699997, 41.593998691000252],
              [-128.831935576999911, 41.598062691000223],
              [-128.83443357699997, 41.602442691000249],
              [-128.83651957699999, 41.607131191000235],
              [-128.839433576999852, 41.611506191000217],
              [-128.84193357699985, 41.617759691000174],
              [-128.844430576999912, 41.621820691000266],
              [-128.846516576999932, 41.626509691000081],
              [-128.84943057699985, 41.631198691000293],
              [-128.851930577999894, 41.637137691000362],
              [-128.854427577999957, 41.641825691000292],
              [-128.856927577999897, 41.646512691000112],
              [-128.859427577999895, 41.651198691000047],
              [-128.861924577999957, 41.657759691000251],
              [-128.864424577999955, 41.662128691000248],
              [-128.866924577999896, 41.666814691000184],
              [-128.869424577999837, 41.671500691000119],
              [-128.872758577999832, 41.677437691000193],
              [-128.87525857799983, 41.68212069100025],
              [-128.877341577999914, 41.686489691000247],
              [-128.879422577999833, 41.691489691000243],
              [-128.882755577999831, 41.697731691000342],
              [-128.885252577999836, 41.702412691000177],
              [-128.887752577999834, 41.707409691000294],
              [-128.890252577999831, 41.71209269100018],
              [-128.892752577999829, 41.716773691000242],
              [-128.895666577999975, 41.722703691000277],
              [-128.898163577999981, 41.727073691000157],
              [-128.90024957799983, 41.732064191000063],
              [-128.902749577999828, 41.736434691000227],
              [-128.90524757799983, 41.741423691000193],
              [-128.90733357799985, 41.74579269100019],
              [-128.909410577999836, 41.750473691000252],
              [-128.911910577999834, 41.754837691000205],
              [-128.914410577999831, 41.759828691000337],
              [-128.916494577999856, 41.764817691000303],
              [-128.918158577999918, 41.769187691000184],
              [-128.920658577999859, 41.774173691000271],
              [-128.92274157799983, 41.778850691000173],
              [-128.925241577999827, 41.783217691000175],
              [-128.927741577999939, 41.788203691000263],
              [-128.929824577999852, 41.792570691000094],
              [-128.931905577999828, 41.797556691000182],
              [-128.933988577999855, 41.801920691000305],
              [-128.936074577999875, 41.806906691000165],
              [-128.938572577999878, 41.811270691000288],
              [-128.940652577999856, 41.815945691000252],
              [-128.943152577999854, 41.820931691000283],
              [-128.945649577999859, 41.82560369100014],
              [-128.947735577999879, 41.830587691000062],
              [-128.949819577999847, 41.834950691000302],
              [-128.951899577999939, 41.839934691000167],
              [-128.954397577999941, 41.844606691000195],
              [-128.955233577999877, 41.848967691000269],
              [-128.957733577999875, 41.853639691000296],
              [-128.95939757799988, 41.858312691000208],
              [-128.961480577999851, 41.86329269100014],
              [-128.963144577999856, 41.86796269100023],
              [-128.964813577999848, 41.872634691000258],
              [-128.96648057799996, 41.877303691000179],
              [-128.968144577999851, 41.881662691000258],
              [-128.970227577999879, 41.886020691000056],
              [-128.972313577999898, 41.891000691000158],
              [-128.974391577999882, 41.895978691000266],
              [-128.976477577999958, 41.900337691000175],
              [-128.97814157799985, 41.905314691000228],
              [-128.980638577999855, 41.909981691000212],
              [-128.982310577999897, 41.91433969100018],
              [-128.983558577999872, 41.919006191000278],
              [-128.98563857799985, 41.923673691000261],
              [-128.987308577999897, 41.928648691000149],
              [-128.988558577999868, 41.933314191000193],
              [-128.990635577999853, 41.937981691000175],
              [-128.992722577999871, 41.94264569100028],
              [-128.993972577999898, 41.947000691000198],
              [-128.996055577999869, 41.951356691000171],
              [-128.997719577999874, 41.956020691000276],
              [-128.998969577999901, 41.960992691000229],
              [-129.000633577999963, 41.965348691000202],
              [-129.002302577999899, 41.970320691000211],
              [-129.004383577999874, 41.97498169100021],
              [-129.006052577999981, 41.979645691000314],
              [-129.007716577999872, 41.984306691000143],
              [-129.0089665779999, 41.988659691000237],
              [-129.01021657799987, 41.993631691000189],
              [-129.011880577999875, 41.997981691000234],
              [-129.013963577999903, 42.002950691000137],
              [-129.0156305779999, 42.007303691000061],
              [-129.017299577999893, 42.012273691000246],
              [-129.018963577999898, 42.016623691000291],
              [-129.020627577999903, 42.021281691000183],
              [-129.022297577999893, 42.026250691000314],
              [-129.023960577999901, 42.030598691000307],
              [-129.025210577999871, 42.035567691000267],
              [-129.026874577999877, 42.039914691000206],
              [-129.02854457799998, 42.044881691000114],
              [-129.030210577999981, 42.049539691000234],
              [-129.031874577999872, 42.053887691000284],
              [-129.032708577999983, 42.058853691000138],
              [-129.034374577999984, 42.063200691000077],
              [-129.036044577999917, 42.068164691000106],
              [-129.037294577999774, 42.072512691000156],
              [-129.038541577999922, 42.077475691000302],
              [-129.04020857799992, 42.081820691000246],
              [-129.041872577999982, 42.086475691000317],
              [-129.043122577999895, 42.091439191000291],
              [-129.044372577999923, 42.09578469100029],
              [-129.046041577999915, 42.10074569100027],
              [-129.047288577999836, 42.10508969100016],
              [-129.048538577999921, 42.110053691000189],
              [-129.049788577999834, 42.114703691000159],
              [-129.051038577999918, 42.11904869100033],
              [-129.052702577999924, 42.124009691000083],
              [-129.053538577999916, 42.128350691000264],
              [-129.055202577999921, 42.133312691000299],
              [-129.056866577999983, 42.137653691000139],
              [-129.058116577999897, 42.142612691000295],
              [-129.059366577999924, 42.146953691000078],
              [-129.060616577999838, 42.151603691000275],
              [-129.061866577999922, 42.155939691000185],
              [-129.063535577999914, 42.160900691000165],
              [-129.064363577999984, 42.165859691000321],
              [-129.066033577999917, 42.17019869100028],
              [-129.067283577999774, 42.175156691000211],
              [-129.068533577999915, 42.179803691000302],
              [-129.069783577999772, 42.184142691000318],
              [-129.071033577999913, 42.188475691000178],
              [-129.07228357799994, 42.193434691000334],
              [-129.073533577999854, 42.19838969100033],
              [-129.074780577999775, 42.202725691000239],
              [-129.076030577999916, 42.207681691000346],
              [-129.077280577999773, 42.212017691000256],
              [-129.078530577999857, 42.216662691000181],
              [-129.079780577999941, 42.220995691000041],
              [-129.081030577999854, 42.225950691000037],
              [-129.081858577999924, 42.230903691000208],
              [-129.083527577999917, 42.235239691000174],
              [-129.084777577999773, 42.24018919100024],
              [-129.085608577999778, 42.244834691000221],
              [-129.086858577999919, 42.249167691000252],
              [-129.087691577999919, 42.254117691000204],
              [-129.089358577999917, 42.258450691000235],
              [-129.090608577999774, 42.263400691000186],
              [-129.091441577999774, 42.267734691000328],
              [-129.092691577999915, 42.272681691000173],
              [-129.093941577999942, 42.277014691000034],
              [-129.095191577999856, 42.281653691000201],
              [-129.096438577999777, 42.286603691000266],
              [-129.09727457799994, 42.291242191000208],
              [-129.098524577999797, 42.296189691000109],
              [-129.099355577999916, 42.300520191000146],
              [-129.101024577999794, 42.305464691000168],
              [-129.101852577999921, 42.310103691000108],
              [-129.102688577999857, 42.314434691000145],
              [-129.103938577999941, 42.319070691000263],
              [-129.104772577999938, 42.323706691000154],
              [-129.106022577999795, 42.328650691000291],
              [-129.107685577999916, 42.333289691000061],
              [-129.108522577999793, 42.337923691000299],
              [-129.109772577999877, 42.34255969100019],
              [-129.111019577999798, 42.346887691000177],
              [-129.111849577999919, 42.35121469100028],
              [-129.113519577999796, 42.356156691000081],
              [-129.114349577999917, 42.361098691000279],
              [-129.115599577999944, 42.365425691000155],
              [-129.116849577999858, 42.370364691000248],
              [-129.118519577999962, 42.374998691000144],
              [-129.11893357799994, 42.379323691000252],
              [-129.119769577999875, 42.383956691000265],
              [-129.120597577999945, 42.38858969100022],
              [-129.121433577999937, 42.393528691000142],
              [-129.121847577999858, 42.39815969100016],
              [-129.122683577999794, 42.402789191000238],
              [-129.123516577999794, 42.407420691000311],
              [-129.123930577999943, 42.411745692000295],
              [-129.124766577999878, 42.416067692000126],
              [-129.12559757799994, 42.420695692000265],
              [-129.126016577999962, 42.425634692000187],
              [-129.126847577999797, 42.42995369200014],
              [-129.127680577999797, 42.434889692000297],
              [-129.12851657799996, 42.439520692000144],
              [-129.129344577999859, 42.444148692000283],
              [-129.130180577999795, 42.448775192000085],
              [-129.131013577999795, 42.453095692000147],
              [-129.1318445779998, 42.458028692000141],
              [-129.132263577999936, 42.462348692000148],
              [-129.133513577999963, 42.467281692000256],
              [-129.133927577999941, 42.471600692000209],
              [-129.134344577999798, 42.476534692000087],
              [-129.135177577999798, 42.480850692000161],
              [-129.135594577999939, 42.485475692000193],
              [-129.136013577999961, 42.490409692000242],
              [-129.13684157799986, 42.494725692000316],
              [-129.137677577999796, 42.499656692000258],
              [-129.138091577999944, 42.50397319200016],
              [-129.138927577999937, 42.508903692000274],
              [-129.139341577999801, 42.513525692000258],
              [-129.13976057799988, 42.517842692000215],
              [-129.140591577999942, 42.522770692000108],
              [-129.140591577999942, 42.527084692000187],
              [-129.141010577999964, 42.532014692000246],
              [-129.141841577999799, 42.536328692000154],
              [-129.142674577999799, 42.541256692000218],
              [-129.142674577999799, 42.545570692000297],
              [-129.14392457799994, 42.550189192000346],
              [-129.144341577999796, 42.555114692000188],
              [-129.144760577999818, 42.559428692000267],
              [-129.145174577999796, 42.564353692000282],
              [-129.145591577999937, 42.568664692000254],
              [-129.146010577999959, 42.573589692000269],
              [-129.146838577999802, 42.578209692000257],
              [-129.147260577999816, 42.58252069200006],
              [-129.147674577999965, 42.587445692000244],
              [-129.148508577999962, 42.591753692000168],
              [-129.149338577999799, 42.596678692000182],
              [-129.149758577999819, 42.600987692000217],
              [-129.150174577999962, 42.605909692000296],
              [-129.15100857799996, 42.610217692000049],
              [-129.15100857799996, 42.614834692000159],
              [-129.151422577999881, 42.619139692000203],
              [-129.151838577999797, 42.624064692000161],
              [-129.152258577999817, 42.628984692000301],
              [-129.152672577999965, 42.633292692000055],
              [-129.153088577999881, 42.638212692000195],
              [-129.153508577999958, 42.642825692000144],
              [-129.153508577999958, 42.647131192000188],
              [-129.154338577999965, 42.651434692000294],
              [-129.154758577999814, 42.656356692000031],
              [-129.155172577999963, 42.661273692000293],
              [-129.155172577999963, 42.665578692000281],
              [-129.156008577999899, 42.670495692000202],
              [-129.156008577999899, 42.67480069200019],
              [-129.15642257799982, 42.679412692000255],
              [-129.15642257799982, 42.683712692000256],
              [-129.15642257799982, 42.688631692000286],
              [-129.1568355779998, 42.693548692000149],
              [-129.1568355779998, 42.697850692000259],
              [-129.15725557799982, 42.702764692000073],
              [-129.157672577999961, 42.707373692000203],
              [-129.157672577999961, 42.711675692000199],
              [-129.158085577999941, 42.716589692000184],
              [-129.158505577999961, 42.720889692000355],
              [-129.158505577999961, 42.725803692000284],
              [-129.158922577999817, 42.730103692000284],
              [-129.159335577999798, 42.73501469200022],
              [-129.159335577999798, 42.739314692000164],
              [-129.159755577999817, 42.743920692000245],
              [-129.160172577999958, 42.748831692000181],
              [-129.160172577999958, 42.753437692000205],
              [-129.160172577999958, 42.758348192000085],
              [-129.160585577999939, 42.762645692000035],
              [-129.161005577999958, 42.767556692000142],
              [-129.161005577999958, 42.772159692000287],
              [-129.161005577999958, 42.776456692000238],
              [-129.161419577999879, 42.781059692000042],
              [-129.161419577999879, 42.785662692000244],
              [-129.161835577999966, 42.790570692000244],
              [-129.161835577999966, 42.79517069200017],
              [-129.161835577999966, 42.799773692000201],
              [-129.161835577999966, 42.804373692000297],
              [-129.161835577999966, 42.808670692000192],
              [-129.162255577999815, 42.812964692000207],
              [-129.162669577999964, 42.817870692000213],
              [-129.162669577999964, 42.822775692000164],
              [-129.16308557799988, 42.827684692000275],
              [-129.162669577999964, 42.832587692000232],
              [-129.16308557799988, 42.836881692000247],
              [-129.16308557799988, 42.841170692000219],
              [-129.163505577999956, 42.846078692000219],
              [-129.163505577999956, 42.850675692000266],
              [-129.163505577999956, 42.854964692000237],
              [-129.163505577999956, 42.859870692000243],
              [-129.162669577999964, 42.862317692000204],
              [-129.161835577999966, 42.864156692000336],
              [-129.161419577999879, 42.868753692000155],
              [-129.161419577999879, 42.873045692000176],
              [-129.161005577999958, 42.877945692000083],
              [-129.161005577999958, 42.882234692000225],
              [-129.161005577999958, 42.886828692000165],
              [-129.161005577999958, 42.891423692000217],
              [-129.160585577999939, 42.896323692000124],
              [-129.160585577999939, 42.901225692000196],
              [-129.160172577999958, 42.905512692000173],
              [-129.160172577999958, 42.910412692000193],
              [-129.160172577999958, 42.915309692000221],
              [-129.160172577999958, 42.919598192000308],
              [-129.159335577999798, 42.924189692000255],
              [-129.159335577999798, 42.928473692000125],
              [-129.159335577999798, 42.933064692000187],
              [-129.159335577999798, 42.93735069200028],
              [-129.158922577999817, 42.942245692000256],
              [-129.158922577999817, 42.946531692000178],
              [-129.158505577999961, 42.951425692000271],
              [-129.158505577999961, 42.956325692000178],
              [-129.158505577999961, 42.960606692000169],
              [-129.158085577999941, 42.965195692000066],
              [-129.158085577999941, 42.969478692000052],
              [-129.157672577999961, 42.974373692000256],
              [-129.157672577999961, 42.978656692000243],
              [-129.157672577999961, 42.98324269200026],
              [-129.157672577999961, 42.988134692000187],
              [-129.1568355779998, 42.992723192000255],
              [-129.1568355779998, 42.997617692000176],
              [-129.1568355779998, 43.002203692000194],
              [-129.15642257799982, 43.006481692000307],
              [-129.15642257799982, 43.011375692000229],
              [-129.15642257799982, 43.015650192000066],
              [-129.15642257799982, 43.020545692000326],
              [-129.156008577999899, 43.024820692000162],
              [-129.155585577999943, 43.02940669200018],
              [-129.155585577999943, 43.033684692000293],
              [-129.155585577999943, 43.03857369200017],
              [-129.155172577999963, 43.042850692000172],
              [-129.155172577999963, 43.047434692000195],
              [-129.155172577999963, 43.052320692000194],
              [-129.154758577999814, 43.056598692000307],
              [-129.154758577999814, 43.061484692000249],
              [-129.154338577999965, 43.065762692000192],
              [-129.153508577999958, 43.070648692000191],
              [-129.153088577999881, 43.074923692000198],
              [-129.152672577999965, 43.079503692000287],
              [-129.152672577999965, 43.08408469200026],
              [-129.151838577999797, 43.088664692000293],
              [-129.151422577999881, 43.09324269200016],
              [-129.15100857799996, 43.097823692000134],
              [-129.150174577999962, 43.102706692000027],
              [-129.150174577999962, 43.106978692000212],
              [-129.149338577999799, 43.111556692000249],
              [-129.148924577999878, 43.116439192000314],
              [-129.148508577999962, 43.120712692000211],
              [-129.148088577999943, 43.125592692000225],
              [-129.147674577999965, 43.129867692000232],
              [-129.146838577999802, 43.134745692000251],
              [-129.146424577999881, 43.139017692000266],
              [-129.146010577999959, 43.143595692000133],
              [-129.145174577999796, 43.148170692000122],
              [-129.144760577999818, 43.152745692000281],
              [-129.144341577999796, 43.157317692000049],
              [-129.143510577999962, 43.161892692000038],
              [-129.14309157799994, 43.166770192000229],
              [-129.142674577999799, 43.171039692000136],
              [-129.141841577999799, 43.175614692000295],
              [-129.141427577999877, 43.180489692000265],
              [-129.141010577999964, 43.184759692000227],
              [-129.140591577999942, 43.189634692000197],
              [-129.13976057799988, 43.193903692000276],
              [-129.139341577999801, 43.198778692000246],
              [-129.138927577999937, 43.20304569200016],
              [-129.138091577999944, 43.207617692000269],
              [-129.137263577999818, 43.211884692000183],
              [-129.13684157799986, 43.216453692000186],
              [-129.136013577999961, 43.221023692000301],
              [-129.135594577999939, 43.225592692000362],
              [-129.135177577999798, 43.230464692000226],
              [-129.134763577999877, 43.234731692000139],
              [-129.134344577999798, 43.239600692000295],
              [-129.133513577999963, 43.243867692000208],
              [-129.132680577999963, 43.248128692000307],
              [-129.1318445779998, 43.253000692000228],
              [-129.131013577999795, 43.257567692000066],
              [-129.130180577999795, 43.262134692000302],
              [-129.129763577999938, 43.266700692000256],
              [-129.129344577999859, 43.271267692000151],
              [-129.128094577999946, 43.27583469200016],
              [-129.127266577999876, 43.280398692000347],
              [-129.126847577999797, 43.284964692000301],
              [-129.12559757799994, 43.28952869200026],
              [-129.125180577999799, 43.294395692000251],
              [-129.123930577999943, 43.298656692000179],
              [-129.123516577999794, 43.303523692000226],
              [-129.123097577999943, 43.307784692000155],
              [-129.121847577999858, 43.312039692000155],
              [-129.121016577999796, 43.316909692000195],
              [-129.120183577999796, 43.321470692000105],
              [-129.119347577999918, 43.326031692000186],
              [-129.118519577999962, 43.330592692000266],
              [-129.117269577999934, 43.335153692000176],
              [-129.116019577999793, 43.339714692000257],
              [-129.115599577999944, 43.344275692000167],
              [-129.114349577999917, 43.348837692000131],
              [-129.113519577999796, 43.353395692000163],
              [-129.112269577999939, 43.358259692000274],
              [-129.111435577999941, 43.362514692000218],
              [-129.110185577999857, 43.36737569200028],
              [-129.109349577999922, 43.371631692000165],
              [-129.108102577999944, 43.37588469200017],
              [-129.106852577999859, 43.380748692000282],
              [-129.106022577999795, 43.384998692000181],
              [-129.105188577999797, 43.389862192000237],
              [-129.103938577999941, 43.394417692000218],
              [-129.103102577999778, 43.398973192000199],
              [-129.101852577999921, 43.403531692000286],
              [-129.101024577999794, 43.408087692000322],
              [-129.099774577999938, 43.412642692000247],
              [-129.098524577999797, 43.417195692000178],
              [-129.097688577999918, 43.42175069200033],
              [-129.096438577999777, 43.426303692000261],
              [-129.095191577999856, 43.431162692000328],
              [-129.09435557799992, 43.435412692000227],
              [-129.093105577999779, 43.439659692000077],
              [-129.091855577999922, 43.444517692000204],
              [-129.091027577999796, 43.448764692000282],
              [-129.089777577999939, 43.453623692000122],
              [-129.088527577999855, 43.458175692000168],
              [-129.087277577999942, 43.462725692000049],
              [-129.086027577999914, 43.467278692000207],
              [-129.085194577999857, 43.471828692000258],
              [-129.083944577999773, 43.476378692000139],
              [-129.082694577999916, 43.480928692000191],
              [-129.081030577999854, 43.485478693000175],
              [-129.080194577999919, 43.490028693000227],
              [-129.078944577999778, 43.494878693000203],
              [-129.077697577999913, 43.499125693000281],
              [-129.076030577999916, 43.503367693000257],
              [-129.075197577999916, 43.507917693000309],
              [-129.073947577999775, 43.512162693000164],
              [-129.072697577999918, 43.517012693000197],
              [-129.071033577999913, 43.521559693000142],
              [-129.069783577999772, 43.526106693000145],
              [-129.068533577999915, 43.530650193000213],
              [-129.067699577999917, 43.535198693000154],
              [-129.066449577999776, 43.539742693000051],
              [-129.064785577999771, 43.543987693000304],
              [-129.063535577999914, 43.548834693000231],
              [-129.062699577999922, 43.553075693000324],
              [-129.061035577999917, 43.557923693000134],
              [-129.059785577999776, 43.562164193000172],
              [-129.058116577999897, 43.567009693000159],
              [-129.057285577999835, 43.571553693000283],
              [-129.056035577999921, 43.575795693000259],
              [-129.054366577999986, 43.580637693000142],
              [-129.053119577999894, 43.585181693000038],
              [-129.051452577999896, 43.589723693000167],
              [-129.050205577999918, 43.594264693000184],
              [-129.048538577999921, 43.598803693000207],
              [-129.047288577999836, 43.603345693000165],
              [-129.045619577999901, 43.607584693000035],
              [-129.043955577999895, 43.612425693000205],
              [-129.042705577999982, 43.616664693000303],
              [-129.04104157799992, 43.6215061930003],
              [-129.039372577999984, 43.625742693000177],
              [-129.037708577999922, 43.630584193000175],
              [-129.036044577999917, 43.635120693000204],
              [-129.034794577999833, 43.639359693000301],
              [-129.033544577999919, 43.644198693000305],
              [-129.031874577999872, 43.648734693000222],
              [-129.030210577999981, 43.653270693000024],
              [-129.02854457799998, 43.657809693000274],
              [-129.026874577999877, 43.662345693000191],
              [-129.025627577999899, 43.666881693000164],
              [-129.023960577999901, 43.671114693000277],
              [-129.021877577999874, 43.675950693000232],
              [-129.020213577999982, 43.680184693000058],
              [-129.018127577999962, 43.685020693000183],
              [-129.016880577999871, 43.689253693000296],
              [-129.014380577999873, 43.694089693000194],
              [-129.013130577999902, 43.698623693000172],
              [-129.01104957799987, 43.702853693000236],
              [-129.0089665779999, 43.707689193000306],
              [-129.007716577999872, 43.711920693000309],
              [-129.005216577999875, 43.716753693000157],
              [-129.003969577999897, 43.72128469300003],
              [-129.001883577999877, 43.725817693000295],
              [-129.000633577999963, 43.730348693000224],
              [-128.99855557799998, 43.73457869300006],
              [-128.996885577999876, 43.739409693000312],
              [-128.995219577999876, 43.743637693000153],
              [-128.993135577999851, 43.748467693000123],
              [-128.9914725779999, 43.75269869300007],
              [-128.989808577999895, 43.757528693000211],
              [-128.987722577999875, 43.762056693000204],
              [-128.98605857799987, 43.766284693000102],
              [-128.98438857799988, 43.771112193000192],
              [-128.982310577999897, 43.775339693000262],
              [-128.980638577999855, 43.78016769300018],
              [-128.979391577999877, 43.784695693000231],
              [-128.976891577999879, 43.789220693000175],
              [-128.975227577999874, 43.793748693000168],
              [-128.973560577999876, 43.797973693000188],
              [-128.971477577999849, 43.80219869300015],
              [-128.969813577999957, 43.806723693000094],
              [-128.967727577999881, 43.811548693000191],
              [-128.966063577999876, 43.815773693000153],
              [-128.963980577999848, 43.82059869300025],
              [-128.962316577999957, 43.824820693000163],
              [-128.96023057799988, 43.829645693000259],
              [-128.958566577999875, 43.834167693000268],
              [-128.956483577999848, 43.838389693000238],
              [-128.954397577999941, 43.843214693000107],
              [-128.952733577999879, 43.847434693000196],
              [-128.950649577999855, 43.852256693000243],
              [-128.948985577999849, 43.856478693000156],
              [-128.947319577999849, 43.861300693000146],
              [-128.945235577999938, 43.865520193000236],
              [-128.943572577999873, 43.87004269300013],
              [-128.941485577999856, 43.874862193000297],
              [-128.939402577999829, 43.87908169300016],
              [-128.937322577999851, 43.883900693000101],
              [-128.936074577999875, 43.888120693000246],
              [-128.933574577999877, 43.892939693000358],
              [-128.931488577999858, 43.897756193000248],
              [-128.929824577999852, 43.901975693000281],
              [-128.92815557799986, 43.90619269300015],
              [-128.926905577999833, 43.908300693000172],
              [-128.926077577999877, 43.908903693000298],
              [-128.925241577999827, 43.910709693000172],
              [-128.92274157799983, 43.914923693000333],
              [-128.92190857799983, 43.919439693000243],
              [-128.920244578, 43.924259693000238],
              [-128.919408577999832, 43.928473693000228],
              [-128.918158577999918, 43.932987193000258],
              [-128.916908577999834, 43.937806693000255],
              [-128.916080577999935, 43.942017693000139],
              [-128.914410577999831, 43.946837693000305],
              [-128.913994577999858, 43.951048693000246],
              [-128.912330577999853, 43.95586469300008],
              [-128.911497577999853, 43.960075693000192],
              [-128.910247577999826, 43.964587693000283],
              [-128.908583577999934, 43.968800693000162],
              [-128.908160577999979, 43.973314193000249],
              [-128.906913577999831, 43.977825693000113],
              [-128.905663577999917, 43.982337693000204],
              [-128.904413577999833, 43.987148693000222],
              [-128.903583578, 43.991362693000156],
              [-128.902333577999855, 43.996170693000295],
              [-128.901083577999827, 44.000381693000179],
              [-128.899833577999857, 44.004592693000063],
              [-128.898585578, 44.009400193000317],
              [-128.897749577999832, 44.013912693000293],
              [-128.896499577999919, 44.018420693000223],
              [-128.895666577999975, 44.02292869300021],
              [-128.894416577999834, 44.027437693000252],
              [-128.893585577999829, 44.031945693000182],
              [-128.891916577999837, 44.036453693000169],
              [-128.891085577999831, 44.04096269300021],
              [-128.890252577999831, 44.045467693000262],
              [-128.888588577999826, 44.050273693000179],
              [-128.887338577999856, 44.054481693000184],
              [-128.886502577999977, 44.059287693000101],
              [-128.885252577999836, 44.063492693000228],
              [-128.884005577999915, 44.067698693000239],
              [-128.882755577999831, 44.072503693000272],
              [-128.881919577999838, 44.077009693000036],
              [-128.880669577999981, 44.08151269300015],
              [-128.879422577999833, 44.086017693000201],
              [-128.878172577999976, 44.090520193000202],
              [-128.876922577999835, 44.095023693000144],
              [-128.87609157799983, 44.099525693000146],
              [-128.874841577999916, 44.104028693000259],
              [-128.873591577999832, 44.108531693000145],
              [-128.871922577999896, 44.113331693000305],
              [-128.871094577999827, 44.117534693000039],
              [-128.869844577999913, 44.122334693000198],
              [-128.868594577999829, 44.126537693000159],
              [-128.867344577999972, 44.130737693000185],
              [-128.866094577999831, 44.135537693000174],
              [-128.864844577999975, 44.1397376930002],
              [-128.863594577999834, 44.144537693000188],
              [-128.861924577999957, 44.14903469300009],
              [-128.860677577999979, 44.153534693000097],
              [-128.859427577999895, 44.158031693000225],
              [-128.857760577999954, 44.162531693000233],
              [-128.856097577999833, 44.167028693000191],
              [-128.854847577999976, 44.171525693000092],
              [-128.85359957799983, 44.176023693000275],
              [-128.851930577999894, 44.180520193000177],
              [-128.850263576999851, 44.185314693000237],
              [-128.848599576999845, 44.189512693000324],
              [-128.847766576999845, 44.193709193000245],
              [-128.846099576999848, 44.198503693000305],
              [-128.844430576999912, 44.202698693000286],
              [-128.84276657699985, 44.207492193000235],
              [-128.84193357699985, 44.211987693000196],
              [-128.840266576999852, 44.216481693000276],
              [-128.838602576999847, 44.220973693000133],
              [-128.83735257699999, 44.225467693000212],
              [-128.835269576999849, 44.229959193000184],
              [-128.834019577, 44.234453693000262],
              [-128.832355576999987, 44.238945693000176],
              [-128.831105576999846, 44.243437693000033],
              [-128.82943557699997, 44.248225693000279],
              [-128.827355577, 44.252420693000261],
              [-128.826935576999972, 44.256612693000193],
              [-128.825272576999851, 44.261103693000166],
              [-128.823188576999826, 44.265289193000058],
              [-128.821108576999848, 44.270081693000293],
              [-128.819438576999971, 44.27457369300015],
              [-128.817358577, 44.279062693000185],
              [-128.815274576999911, 44.283550693000052],
              [-128.813191576999827, 44.288037693000263],
              [-128.811527577, 44.292525193000301],
              [-128.809860577, 44.296712693000188],
              [-128.807777576999968, 44.301500693000037],
              [-128.806113576999849, 44.305687693000266],
              [-128.804027576999829, 44.310475693000114],
              [-128.801944576999972, 44.314659693000294],
              [-128.80028057699991, 44.319448693000027],
              [-128.797780576999969, 44.323934693000297],
              [-128.796116576999907, 44.328117193000139],
              [-128.79403057699983, 44.332903693000048],
              [-128.792366576999825, 44.337387693000153],
              [-128.790283576999968, 44.341873693000252],
              [-128.788197576999949, 44.346356693000303],
              [-128.78653357699983, 44.350839693000296],
              [-128.784449576999975, 44.35532369300023],
              [-128.78278557699997, 44.359503693000249],
              [-128.78111957699997, 44.364289693000273],
              [-128.779035576999888, 44.368470693000177],
              [-128.776952576999975, 44.373253693000152],
              [-128.775285576999806, 44.377434693000225],
              [-128.77320257699995, 44.382214693000151],
              [-128.771124576999966, 44.386695693000149],
              [-128.769452576999811, 44.390875693000169],
              [-128.766955576999806, 44.395656693000149],
              [-128.765291576999971, 44.400137693000204],
              [-128.763205576999951, 44.404617693000148],
              [-128.761127576999968, 44.409095693000097],
              [-128.759041576999948, 44.413573693000217],
              [-128.75862757699997, 44.415064693000147],
              [-128.756958576999807, 44.416262693000306],
              [-128.756544576999886, 44.418053193000162],
              [-128.756127576999972, 44.422228693000307],
              [-128.75570857699995, 44.427009693000116],
              [-128.75570857699995, 44.431184693000262],
              [-128.755294576999972, 44.435962693000306],
              [-128.754877576999945, 44.440139693000276],
              [-128.754458576999809, 44.444914693000101],
              [-128.754458576999809, 44.44939269300022],
              [-128.754458576999809, 44.453567693000196],
              [-128.753627576999804, 44.458342693000191],
              [-128.753627576999804, 44.462517693000166],
              [-128.753208576999953, 44.467292693000161],
              [-128.752794576999804, 44.471767693000061],
              [-128.752794576999804, 44.476239693000252],
              [-128.752380576999826, 44.480714693000152],
              [-128.751958576999868, 44.484887693000076],
              [-128.751958576999868, 44.489659693000192],
              [-128.751544576999947, 44.493831693000288],
              [-128.751130576999969, 44.498603693000234],
              [-128.751130576999969, 44.502775693000274],
              [-128.750294576999806, 44.507548693000274],
              [-128.750294576999806, 44.512020693000295],
              [-128.749880576999885, 44.516189693000285],
              [-128.749460576999809, 44.520962693000286],
              [-128.749460576999809, 44.525131693000105],
              [-128.74904457699995, 44.529900693000172],
              [-128.748630576999972, 44.534667693000245],
              [-128.748630576999972, 44.538839693000284],
              [-128.748630576999972, 44.543306693000204],
              [-128.748630576999972, 44.548073693000276],
              [-128.748630576999972, 44.552245693000145],
              [-128.748630576999972, 44.557009693000168],
              [-128.748630576999972, 44.561178194000206],
              [-128.74904457699995, 44.565942694000057],
              [-128.74904457699995, 44.570112694000272],
              [-128.749460576999809, 44.574578694000309],
              [-128.749460576999809, 44.578745694000133],
              [-128.749460576999809, 44.583512694000035],
              [-128.749460576999809, 44.587975694000193],
              [-128.749460576999809, 44.592442694000113],
              [-128.749460576999809, 44.597200694000151],
              [-128.749880576999885, 44.601370694000195],
              [-128.749880576999885, 44.605834694000293],
              [-128.749880576999885, 44.609998694000183],
              [-128.750294576999806, 44.614762694000206],
              [-128.749880576999885, 44.619520694000244],
              [-128.75071057699995, 44.623981694000179],
              [-128.750294576999806, 44.628739694000217],
              [-128.75071057699995, 44.633203694000315],
              [-128.751130576999969, 44.637367694000204],
              [-128.751130576999969, 44.642125694000242],
              [-128.751130576999969, 44.646289694000188],
              [-128.751544576999947, 44.651045694000231],
              [-128.751544576999947, 44.655209694000121],
              [-128.751958576999868, 44.65996469400028],
              [-128.751958576999868, 44.664128694000169],
              [-128.751958576999868, 44.668589694000161],
              [-128.752380576999826, 44.673342694000155],
              [-128.752794576999804, 44.677506694000272],
              [-128.752794576999804, 44.682259694000038],
              [-128.753208576999953, 44.686420694000276],
              [-128.753208576999953, 44.691175694000037],
              [-128.753627576999804, 44.695631694000156],
              [-128.753627576999804, 44.699792694000166],
              [-128.754044576999888, 44.70454569400016],
              [-128.754458576999809, 44.708703694000292],
              [-128.754458576999809, 44.713456694000229],
              [-128.754458576999809, 44.71761469400019],
              [-128.754877576999945, 44.722364694000305],
              [-128.754877576999945, 44.726525694000145],
              [-128.754877576999945, 44.730978694000157],
              [-128.755294576999972, 44.73513769400023],
              [-128.75570857699995, 44.739887694000288],
              [-128.75570857699995, 44.744634694000126],
              [-128.756127576999972, 44.748792694000258],
              [-128.756544576999886, 44.753539694000096],
              [-128.756544576999886, 44.757992694000279],
              [-128.756958576999807, 44.762148694000246],
              [-128.756958576999807, 44.766303694000158],
              [-128.757377576999886, 44.771050694000166],
              [-128.757791576999807, 44.775798694000287],
              [-128.757791576999807, 44.779953694000142],
              [-128.758208576999948, 44.784698694000156],
              [-128.75862757699997, 44.788853694000238],
              [-128.75862757699997, 44.793300694000152],
              [-128.75862757699997, 44.797456694000289],
              [-128.759041576999948, 44.802198694000253],
              [-128.759458576999805, 44.806942694000156],
              [-128.759458576999805, 44.811095694000244],
              [-128.759458576999805, 44.815839694000204],
              [-128.759458576999805, 44.820287694000172],
              [-128.759458576999805, 44.824437694000153],
              [-128.759877576999827, 44.829178694000234],
              [-128.759877576999827, 44.833331694000265],
              [-128.760291576999805, 44.83807069400018],
              [-128.760291576999805, 44.842223694000268],
              [-128.760705576999953, 44.846962694000126],
              [-128.760705576999953, 44.851112194000279],
              [-128.761127576999968, 44.855556694000143],
              [-128.761127576999968, 44.860295694000172],
              [-128.76195557699981, 44.864739694000207],
              [-128.76195557699981, 44.869481694000172],
              [-128.76195557699981, 44.873625694000168],
              [-128.762374576999889, 44.878367694000133],
              [-128.762791576999973, 44.882809694000173],
              [-128.762791576999973, 44.886953694000169],
              [-128.763205576999951, 44.891395694000209],
              [-128.763624576999973, 44.895837694000193],
              [-128.764041576999887, 44.900278694000292],
              [-128.763624576999973, 44.904423694000059],
              [-128.763624576999973, 44.909159694000209],
              [-128.763624576999973, 44.913898694000295],
              [-128.762791576999973, 44.918039694000242],
              [-128.762791576999973, 44.922184694000237],
              [-128.762791576999973, 44.926917694000338],
              [-128.762791576999973, 44.931356694000272],
              [-128.762374576999889, 44.935498694000273],
              [-128.762374576999889, 44.940231694000033],
              [-128.762374576999889, 44.94496769400024],
              [-128.76195557699981, 44.949106694000193],
              [-128.76195557699981, 44.953542694000248],
              [-128.76195557699981, 44.957687694000185],
              [-128.76195557699981, 44.962417694000237],
              [-128.761541576999946, 44.966559694000239],
              [-128.76195557699981, 44.970992694000245],
              [-128.761127576999968, 44.9754286940003],
              [-128.760705576999953, 44.979864694000128],
              [-128.760291576999805, 44.984298694000188],
              [-128.759877576999827, 44.988731694000364],
              [-128.759458576999805, 44.993167694000192],
              [-128.759041576999948, 44.997600694000198],
              [-128.75862757699997, 45.002034694000201],
              [-128.758208576999948, 45.006759694000209],
              [-128.757791576999807, 45.010898694000161],
              [-128.757377576999886, 45.015625694000164],
              [-128.756958576999807, 45.019762694000121],
              [-128.756544576999886, 45.023898694000195],
              [-128.75570857699995, 45.028625694000198],
              [-128.755294576999972, 45.032762694000155],
              [-128.754877576999945, 45.037487694000163],
              [-128.754458576999809, 45.041914694000354],
              [-128.754044576999888, 45.046345694000308],
              [-128.753627576999804, 45.050775694000208],
              [-128.752794576999804, 45.055203694000113],
              [-128.752794576999804, 45.05963119400019],
              [-128.751958576999868, 45.064062694000199],
              [-128.751130576999969, 45.06848969400022],
              [-128.75071057699995, 45.072917694000296],
              [-128.750294576999806, 45.077637694000202],
              [-128.749460576999809, 45.081770194000171],
              [-128.74904457699995, 45.085903694000251],
              [-128.748630576999972, 45.090623694000158],
              [-128.747794576999809, 45.094756694000182],
              [-128.747794576999809, 45.099475694000205],
              [-128.746960576999868, 45.103900694000174],
              [-128.746960576999868, 45.10832569400003],
              [-128.746130576999803, 45.112748694000061],
              [-128.745710576999954, 45.117173694000087],
              [-128.745297576999803, 45.121598694000284],
              [-128.74446057699987, 45.12602069400026],
              [-128.743633576999969, 45.13044569400023],
              [-128.743210576999786, 45.134867694000206],
              [-128.742797576999806, 45.139584694000234],
              [-128.741960576999929, 45.143712694000158],
              [-128.741960576999929, 45.147839694000254],
              [-128.741133576999971, 45.152262694000285],
              [-128.740713576999951, 45.15638469400028],
              [-128.740297576999808, 45.161103694000246],
              [-128.739883576999944, 45.165523694000228],
              [-128.739047576999951, 45.169942694000099],
              [-128.738213576999954, 45.174362694000251],
              [-128.737799576999805, 45.178781694000122],
              [-128.737383576999946, 45.183200694000163],
              [-128.736963576999926, 45.18732369400027],
              [-128.736549576999948, 45.192037694000192],
              [-128.735713576999785, 45.196159694000187],
              [-128.735299576999807, 45.200873694000165],
              [-128.734463576999929, 45.204995694000161],
              [-128.733635576999802, 45.209709694000253],
              [-128.733213576999788, 45.214417694000076],
              [-128.732799576999867, 45.218539694000071],
              [-128.731966576999866, 45.223250694000342],
              [-128.731966576999866, 45.227959694000219],
              [-128.731966576999866, 45.232081694000215],
              [-128.731966576999866, 45.236495694000212],
              [-128.732385576999945, 45.240617194000151],
              [-128.732799576999867, 45.245323694000206],
              [-128.732799576999867, 45.250031694000199],
              [-128.732799576999867, 45.254150694000259],
              [-128.733213576999788, 45.258856694000258],
              [-128.733213576999788, 45.263270694000255],
              [-128.733635576999802, 45.267389694000201],
              [-128.733635576999802, 45.2720956940002],
              [-128.733635576999802, 45.276214694000146],
              [-128.734049576999951, 45.280917694000209],
              [-128.734463576999929, 45.285037694000266],
              [-128.734463576999929, 45.289739694000275],
              [-128.734463576999929, 45.293856694000226],
              [-128.734463576999929, 45.298267694000117],
              [-128.734463576999929, 45.302384694000239],
              [-128.734463576999929, 45.306792694000251],
              [-128.734883576999948, 45.3114956940002],
              [-128.734883576999948, 45.315612694000151],
              [-128.734883576999948, 45.320312694000165],
              [-128.734883576999948, 45.324428694000233],
              [-128.735299576999807, 45.329128694000246],
              [-128.735299576999807, 45.333242694000262],
              [-128.735299576999807, 45.337648694000166],
              [-128.735299576999807, 45.342056694000178],
              [-128.735713576999785, 45.346462694000252],
              [-128.736133576999805, 45.351159694000216],
              [-128.736133576999805, 45.355273694000061],
              [-128.736133576999805, 45.359970694000026],
              [-128.736133576999805, 45.364084694000269],
              [-128.736133576999805, 45.368487694000237],
              [-128.736133576999805, 45.373184694000201],
              [-128.736133576999805, 45.377295694000168],
              [-128.736133576999805, 45.381992694000303],
              [-128.736133576999805, 45.386103694000099],
              [-128.736133576999805, 45.390798694000068],
              [-128.736549576999948, 45.394909694000205],
              [-128.736963576999926, 45.399309694000124],
              [-128.736963576999926, 45.403712694000262],
              [-128.736963576999926, 45.408112694000181],
              [-128.736963576999926, 45.412806694000267],
              [-128.736963576999926, 45.416914694000184],
              [-128.736963576999926, 45.421606694000275],
              [-128.736963576999926, 45.425714694000305],
              [-128.736963576999926, 45.430114694000054],
              [-128.736963576999926, 45.434806694000144],
              [-128.736549576999948, 45.438912694000237],
              [-128.736963576999926, 45.443603694000046],
              [-128.736963576999926, 45.447709694000139],
              [-128.736549576999948, 45.452400694000175],
              [-128.736549576999948, 45.456506194000212],
              [-128.736549576999948, 45.460903694000251],
              [-128.736133576999805, 45.465003694000188],
              [-128.736133576999805, 45.469695694000109],
              [-128.736133576999805, 45.474092694000149],
              [-128.736133576999805, 45.478489694000132],
              [-128.736133576999805, 45.483175694000067],
              [-128.736133576999805, 45.487570694000283],
              [-128.736133576999805, 45.491964694000217],
              [-128.736133576999805, 45.494603694000261],
              [-128.734463576999929, 45.496653694000258],
              [-128.736133576999805, 45.500753694000196],
              [-128.737799576999805, 45.505439694000302],
              [-128.739047576999951, 45.509542694000174],
              [-128.741133576999971, 45.514225694000174],
              [-128.741960576999929, 45.518620694000219],
              [-128.744047576999947, 45.522720694000157],
              [-128.745710576999954, 45.527403694000043],
              [-128.746960576999868, 45.531503694000151],
              [-128.748630576999972, 45.536187694000148],
              [-128.750294576999806, 45.540287694000256],
              [-128.751958576999868, 45.544967694000263],
              [-128.752794576999804, 45.5490676940002],
              [-128.754877576999945, 45.553456694000261],
              [-128.756544576999886, 45.558139694000147],
              [-128.757791576999807, 45.56223769400026],
              [-128.759458576999805, 45.566917694000267],
              [-128.761127576999968, 45.571014694000326],
              [-128.762791576999973, 45.575692694000111],
              [-128.763624576999973, 45.580081694000171],
              [-128.765291576999971, 45.584178694000229],
              [-128.767374576999828, 45.588856694000242],
              [-128.768624576999969, 45.592950694000194],
              [-128.770288576999974, 45.597628694000207],
              [-128.771538576999887, 45.60172369400027],
              [-128.77320257699995, 45.606400694000172],
              [-128.774452576999806, 45.610495694000065],
              [-128.776122576999967, 45.614881694000246],
              [-128.777372576999824, 45.618975694000255],
              [-128.779035576999888, 45.623650694000162],
              [-128.780285576999972, 45.628325694000068],
              [-128.781949576999807, 45.632417695000186],
              [-128.783619576999968, 45.637092695000263],
              [-128.785283576999973, 45.641475695000167],
              [-128.78653357699983, 45.645567695000182],
              [-128.788197576999949, 45.649659695000196],
              [-128.789866576999827, 45.654331695000224],
              [-128.791116576999968, 45.659003695000251],
              [-128.792783576999966, 45.663095695000209],
              [-128.793616576999909, 45.667767695000236],
              [-128.795280576999971, 45.671856695000201],
              [-128.796116576999907, 45.676237695000111],
              [-128.797366577, 45.680325695000136],
              [-128.79819457699989, 45.684995695000168],
              [-128.799444576999974, 45.689667695000026],
              [-128.80028057699991, 45.693753695000225],
              [-128.801530577, 45.698423695000315],
              [-128.802777576999972, 45.702803695000341],
              [-128.80444457699997, 45.7068896950002],
              [-128.80527757699997, 45.711556695000297],
              [-128.806527576999827, 45.715645695000262],
              [-128.80736357699999, 45.720312695000246],
              [-128.808610576999911, 45.724398695000275],
              [-128.810277576999908, 45.729064695000147],
              [-128.811110576999909, 45.733150195000292],
              [-128.81194157699997, 45.737525195000103],
              [-128.813610576999849, 45.742189695000036],
              [-128.814441576999968, 45.746564695000188],
              [-128.816110576999847, 45.751234695000051],
              [-128.816938576999974, 45.755314695000152],
              [-128.81818857699983, 45.759981695000249],
              [-128.819438576999971, 45.76435669500006],
              [-128.820688576999828, 45.768434695000167],
              [-128.821938576999912, 45.772806695000043],
              [-128.82277257699991, 45.777178695000146],
              [-128.82443857699991, 45.781845695000243],
              [-128.825272576999851, 45.786217695000175],
              [-128.826522577, 45.790587695000227],
              [-128.827355577, 45.794959195000104],
              [-128.828185576999829, 45.799037695000209],
              [-128.828605576999848, 45.803117195000084],
              [-128.83026957699991, 45.807775695000259],
              [-128.831519577, 45.812439695000137],
              [-128.832769576999851, 45.816514695000308],
              [-128.833605576999844, 45.821178195000186],
              [-128.834855576999928, 45.825545695000244],
              [-128.836102576999849, 45.829620695000074],
              [-128.836933576999911, 45.833989695000241],
              [-128.838183577, 45.8383566950003],
              [-128.839433576999852, 45.843017695000299],
              [-128.840266576999852, 45.847384695000301],
              [-128.841516577, 45.851750695000248],
              [-128.842352576999929, 45.85611769500025],
              [-128.843180576999828, 45.860189195000146],
              [-128.844016577, 45.864264695000031],
              [-128.844430576999912, 45.868628695000154],
              [-128.845680577, 45.873284695000279],
              [-128.846930576999853, 45.877359695000109],
              [-128.847766576999845, 45.882012695000185],
              [-128.849013577, 45.886375695000027],
              [-128.84943057699985, 45.89073969500015],
              [-128.850680577, 45.895103695000103],
              [-128.852349577999973, 45.899175695000224],
              [-128.852763577999895, 45.9038286950003],
              [-128.854013577999979, 45.907900695000251],
              [-128.854847577999976, 45.91255069500022],
              [-128.855263577999835, 45.916623695000055],
              [-128.856097577999833, 45.921273695000195],
              [-128.856927577999897, 45.925345695000146],
              [-128.857760577999954, 45.92970669500022],
              [-128.858597577999831, 45.934353695000141],
              [-128.859427577999895, 45.938425695000262],
              [-128.860260577999895, 45.943073695000066],
              [-128.861097577999828, 45.947142695000139],
              [-128.861510577999979, 45.951792695000279],
              [-128.862347577999913, 45.956148695000252],
              [-128.863174577999814, 45.960217695000154],
              [-128.864424577999955, 45.964864695000244],
              [-128.864844577999975, 45.968931695000151],
              [-128.865674577999982, 45.973578695000242],
              [-128.866094577999831, 45.977645695000092],
              [-128.866924577999896, 45.982292695000183],
              [-128.867758577999837, 45.98635969500026],
              [-128.868594577999829, 45.990714695000179],
              [-128.869424577999837, 45.995359695000275],
              [-128.869844577999913, 45.999423695000075],
              [-128.871094577999827, 46.004067695000288],
              [-128.871508577999975, 46.008131695000088],
              [-128.871922577999896, 46.0127756950003],
              [-128.872758577999832, 46.017128695000224],
              [-128.873172577999981, 46.021192695000195],
              [-128.874422577999837, 46.025834695000242],
              [-128.874841577999916, 46.029898695000213],
              [-128.87525857799983, 46.034537695000211],
              [-128.87609157799983, 46.038600695000298],
              [-128.876505577999978, 46.043242695000345],
              [-128.876922577999835, 46.047303695000267],
              [-128.877341577999914, 46.051653695000084],
              [-128.878172577999976, 46.055714695000177],
              [-128.879005577999976, 46.060353695000174],
              [-128.879422577999833, 46.064992195000229],
              [-128.879422577999833, 46.069053695000036],
              [-128.880255577999833, 46.073689695000098],
              [-128.880669577999981, 46.078039695000143],
              [-128.881091578, 46.082098195000185],
              [-128.881919577999838, 46.086159695000163],
              [-128.881919577999838, 46.090795695000054],
              [-128.882755577999831, 46.095431695000286],
              [-128.883588577999831, 46.099489695000159],
              [-128.884005577999915, 46.104123695000055],
              [-128.884419577999836, 46.108181695000269],
              [-128.885252577999836, 46.112525195000273],
              [-128.885669577999977, 46.116584695000086],
              [-128.886088577999828, 46.121217695000269],
              [-128.886919577999834, 46.125850695000281],
              [-128.887338577999856, 46.129906695000159],
              [-128.887752577999834, 46.134539695000285],
              [-128.888588577999826, 46.138884695000286],
              [-128.889002577999975, 46.142937695000228],
              [-128.889416577999839, 46.14757069500007],
              [-128.890252577999831, 46.151623695000239],
              [-128.89066657799998, 46.156253695000146],
              [-128.891085577999831, 46.160309695000194],
              [-128.891916577999837, 46.164937695000333],
              [-128.892752577999829, 46.16899269500027],
              [-128.895249577999834, 46.173331695000286],
              [-128.897749577999832, 46.177962695000303],
              [-128.90024957799983, 46.182300695000208],
              [-128.902749577999828, 46.186934695000105],
              [-128.904413577999833, 46.190981695000119],
              [-128.90733357799985, 46.195612695000307],
              [-128.909410577999836, 46.199950695000211],
              [-128.911910577999834, 46.203998695000166],
              [-128.914410577999831, 46.208337695000182],
              [-128.916908577999834, 46.214120695000247],
              [-128.920244578, 46.218459695000092],
              [-128.922327577999852, 46.222795695000229],
              [-128.925241577999827, 46.226845695000293],
              [-128.927741577999939, 46.230889695000258],
              [-128.930655577999858, 46.235517695000169],
              [-128.933155577999855, 46.240139695000153],
              [-128.935238577999939, 46.244187695000051],
              [-128.937738577999937, 46.248809695000034],
              [-128.940235577999829, 46.252856695000219],
              [-128.942322577999846, 46.257192695000185],
              [-128.944399577999832, 46.261234695000098],
              [-128.946485577999852, 46.265859695000358],
              [-128.949399577999941, 46.270481695000285],
              [-128.951483577999852, 46.27452569500025],
              [-128.953569577999872, 46.279145695000238],
              [-128.956066577999877, 46.283478695000099],
              [-128.958147577999853, 46.287523695000118],
              [-128.961066577999873, 46.292142695000223],
              [-128.96356657799987, 46.296184695000306],
              [-128.966063577999876, 46.300803695000241],
              [-128.968563577999873, 46.304845695000211],
              [-128.971063577999871, 46.309464695000088],
              [-128.973560577999876, 46.313506695000228],
              [-128.976060577999874, 46.317837695000094],
              [-128.978560577999872, 46.322453695000263],
              [-128.980638577999855, 46.32678169500025],
              [-128.983558577999872, 46.331398695000189],
              [-128.98563857799985, 46.335437695000223],
              [-128.988138577999962, 46.340053695000279],
              [-128.990222577999873, 46.344381695000266],
              [-128.992722577999871, 46.3484206950003],
              [-128.995219577999876, 46.352748695000287],
              [-128.997719577999874, 46.357359695000241],
              [-129.000219577999871, 46.361398695000275],
              [-129.003133577999961, 46.365725695000208],
              [-129.006052577999981, 46.368898695000212],
              [-129.009380577999877, 46.373223695000263],
              [-129.012299577999897, 46.376392695000277],
              [-129.0156305779999, 46.380717695000214],
              [-129.017713577999871, 46.383889695000278],
              [-129.021463577999896, 46.388789695000185],
              [-129.024797577999891, 46.392537695000158],
              [-129.028960577999896, 46.397437695000235],
              [-129.031874577999872, 46.401181695000218],
              [-129.035622577999902, 46.405792695000173],
              [-129.0381225779999, 46.408962695000241],
              [-129.042705577999982, 46.415014695000252],
              [-129.045619577999901, 46.418756695000241],
              [-129.050205577999918, 46.424234695000166],
              [-129.052288577999775, 46.427689195000141],
              [-129.056452577999835, 46.43258469500023],
              [-129.059366577999924, 46.43661769500028],
              [-129.062699577999922, 46.440937695000173],
              [-129.065613577999898, 46.444106695000187],
              [-129.068947577999836, 46.44842569500014],
              [-129.071447577999777, 46.451878695000175],
              [-129.074360577999926, 46.456198695000239],
              [-129.077697577999913, 46.4596536950001],
              [-129.081030577999854, 46.464256695000302],
              [-129.083944577999773, 46.46742369500015],
              [-129.087691577999919, 46.472606695000366],
              [-129.090608577999774, 46.476345695000077],
              [-129.094774577999942, 46.48123969500017],
              [-129.097688577999918, 46.484692695000263],
              [-129.101438577999943, 46.489292695000188],
              [-129.103938577999941, 46.492459195000151],
              [-129.108102577999944, 46.497637695000208],
              [-129.110599577999778, 46.500800695000237],
              [-129.115183577999858, 46.505975695000132],
              [-129.117683577999799, 46.509142695000151],
              [-129.122266577999937, 46.514606695000168],
              [-129.124347577999799, 46.518631695000124],
              [-129.126847577999797, 46.522081695000281],
              [-129.127499999999827, 46.529722220000167],
              [-128.865555599999823, 46.701388890000203],
              [-127.689722199999949, 47.366666670000143],
              [-127.199444399999777, 47.613055560000248],
              [-126.682499999999891, 47.820833330000141],
              [-125.896666699999912, 48.184722220000253],
              [-125.499444399999845, 48.306111110000302],
              [-125.38, 48.337777780000295],
              [-125.153333299999929, 48.446388890000037],
              [-125.140277799999865, 48.45277778000019],
              [-125.096388899999852, 48.469166670000163],
              [-125.001666699999873, 48.495555560000071],
              [-124.990965568999968, 48.498230832000274],
              [-124.987222199999849, 48.499166670000079],
              [-124.914444399999866, 48.503888890000155],
              [-124.839166699999794, 48.506111110000177],
              [-124.803729748999785, 48.504037678000259],
              [-124.786944399999811, 48.503055560000178],
              [-124.751097047999849, 48.497541707000209],
              [-124.725870870499989, 48.493676682000057],
              [-124.723310678999894, 48.492986694000365],
              [-124.678631950999886, 48.480648856000187],
              [-124.665775458999917, 48.477098591000185],
              [-124.659187877999841, 48.475279459000262],
              [-124.583480647999863, 48.454373234000229],
              [-124.048730007999836, 48.306704149000268],
              [-124.006770510999843, 48.295117215000062],
              [-123.869381810999869, 48.271009918000175],
              [-123.793538754999929, 48.257701901000303],
              [-123.531173060999834, 48.221055966000222],
              [-123.255689080999787, 48.279199174000269],
              [-123.18535179599985, 48.351360020000186],
              [-123.118543378000027, 48.419900509000172],
              [-123.163861087999919, 48.457989457000224],
              [-123.218719369999917, 48.545960139000158],
              [-123.247341082, 48.617965705000188],
              [-123.272385079999879, 48.691447223000125],
              [-123.009065328999952, 48.766397781000308],
              [-123.009065328999952, 48.829741845000285],
              [-123.323904161999906, 49.000306302000126],
              [-123.226354767999908, 49.000306302000126],
              [-123.21303567199999, 49.000306302000126],
              [-123.094203663133328, 49.000306302000126],
              [-123.093745876522576, 48.999436910042618],
              [-123.034318620953115, 48.999436641056491],
              [-123.035041153506256, 49.000306190951164],
              [-122.767494192179242, 49.000306302000126],
              [-122.760299050964591, 48.999436647917719],
              [-122.699999976390814, 49.000000047031904],
              [-122.566673065520149, 49.000000047031904],
              [-122.433609011133797, 49.000000047031904],
              [-122.33389101751942, 49.000000047031904],
              [-122.100563978172772, 49.000000047031904],
              [-121.084973024015767, 48.999718079808304],
              [-120.534726895272286, 48.999436112584874],
              [-120.034154942370492, 48.999436112584874],
              [-119.934154981532515, 48.999436112584874],
              [-119.867491023183106, 48.999436112584874],
              [-119.46777297941702, 48.999436112584874],
              [-119.267226892928761, 48.999436112584874],
              [-119.134173064464278, 48.999436112584874],
              [-118.967499925183233, 48.999436112584874],
              [-118.767772918275739, 48.999436112584874],
              [-118.36805504214783, 48.999436112584874],
              [-118.134172953171287, 48.999436112584874],
              [-118.000835816378469, 48.999436112584874],
              [-117.867490968235018, 48.999436112584874],
              [-117.834445985425816, 49.000000047031904],
              [-117.567499970538677, 49.000000047031904],
              [-117.300554961480145, 49.000000047031904],
              [-117.234735898972872, 49.000000047031904],
              [-117.200846020321308, 48.999436112584874],
              [-117.06722691630506, 48.999718079808304],
              [-117.036617882203302, 49.003126999839367],
              [-117.001399974808862, 48.999718079808304],
              [-116.048335839220385, 48.999718079808304],
              [-115.734445969639751, 48.999436112584874],
              [-115.60139096770888, 48.999436112584874],
              [-115.567226833184463, 49.000000047031904],
              [-115.468064056838045, 49.000000047031904],
              [-115.3680550435447, 49.000000047031904],
              [-115.167499904601016, 48.999436112584874],
              [-115.034155056457564, 48.999436112584874],
              [-114.901108939343942, 48.999436112584874],
              [-114.633899900334484, 49.000000047031904],
              [-114.534726898066253, 49.000000047031904],
              [-114.467500011097854, 48.999436112584874],
              [-114.335008943614184, 48.999436112584874],
              [-114.059863918655935, 49.000600191300919],
              [-114.034435906559565, 48.999436112584874],
              [-113.567499860642613, 48.999436112584874],
              [-113.368326897536406, 48.999436112584874],
              [-113.234163975640627, 48.999436112584874],
              [-113.034435795266745, 49.000000047031904],
              [-112.934717969290318, 49.000000047031904],
              [-112.601672936329408, 49.000000047031904],
              [-112.53499992552446, 49.000000047031904],
              [-112.434999964686426, 49.000000047031904],
              [-112.335008888666039, 49.000000047031904],
              [-112.234735845421994, 49.000000047031904],
              [-112.168326864567632, 48.999436112584874],
              [-112.034726871290673, 48.999436112584874],
              [-111.801099927447581, 48.999436112584874],
              [-111.368326842588488, 48.999436112584874],
              [-110.768626814419576, 48.999436112584874],
              [-110.667772905283698, 49.000000047031904],
              [-110.501108986096312, 49.000000047031904],
              [-110.36776397031457, 49.000000047031904],
              [-110.301663946412305, 49.000000047031904],
              [-110.201108935944603, 48.999436112584874],
              [-110.101390942330283, 48.999436112584874],
              [-109.99965508933991, 49.000600191300919],
              [-109.967773011780537, 48.999718079808304],
              [-109.801935883524607, 48.999436112584874],
              [-109.634746083730306, 48.999436112584874],
              [-109.334726922839366, 48.999436112584874],
              [-108.834726951011874, 48.999436112584874],
              [-108.66777301797373, 48.999436112584874],
              [-108.534726900860164, 48.999436112584874],
              [-108.335008946407982, 48.999436112584874],
              [-108.168872919484102, 48.999436112584874],
              [-107.801099985189524, 48.999436112584874],
              [-107.735545958461671, 48.999436112584874],
              [-107.634746028782388, 48.999436112584874],
              [-107.434999911135549, 49.000000047031904],
              [-107.335281917521229, 49.000000047031904],
              [-106.73554601480663, 48.999436112584874],
              [-106.468064061029054, 48.999436112584874],
              [-106.26862690033397, 48.999436112584874],
              [-106.135272999734923, 48.999436112584874],
              [-106.03472687408464, 48.999436112584874],
              [-105.935545992827315, 48.999436112584874],
              [-105.70221794765223, 48.999436112584874],
              [-105.268335937000074, 49.000000047031904],
              [-105.001399980396741, 48.999436112584874],
              [-104.835008975977473, 48.999436112584874],
              [-104.335008836511975, 48.999436112584874],
              [-104.135272944786891, 48.999718079808304],
              [-104.033099891725726, 49.000254018698911],
              [-103.735281986252744, 48.999436112584874],
              [-103.535281896938955, 48.999436112584874],
              [-103.435545966051848, 49.000273129438199],
              [-103.268890931681881, 49.000000047031904],
              [-103.168326868758641, 48.999436112584874],
              [-103.035281925111406, 48.999436112584874],
              [-102.768335910224437, 48.999436112584874],
              [-102.535555035690152, 49.000273129438199],
              [-102.335563998831958, 48.999436112584874],
              [-102.168872922277899, 49.000000047031904],
              [-101.468890966047525, 48.999436112584874],
              [-101.367235914603953, 48.998791208952639],
              [-101.302226879222019, 49.000273129438199],
              [-101.069164037464191, 49.000000047031904],
              [-100.501954948303137, 48.999718079808304],
              [-100.002226885415439, 49.000000047031904],
              [-99.835555087238902, 49.000000047031904],
              [-99.335554947773403, 48.999436112584874],
              [-98.868608843572417, 49.000000047031904],
              [-98.502226802294842, 48.999436112584874],
              [-98.269163960536844, 49.000273129438199],
              [-97.969163910385134, 49.000273129438199],
              [-97.801935889112542, 49.000000047031904],
              [-97.6358359043723, 48.999436112584874],
              [-97.502790960725065, 48.999436112584874],
              [-97.21993592592861, 48.999718079808304],
              [-95.266555010744185, 48.999973224941755],
              [-95.154172967224838, 48.999436112584874],
              [-95.153963922559001, 49.173336128803783],
              [-95.154446049631133, 49.333327047216528],
              [-95.154172967224838, 49.366391140765131],
              [-95.152790959025538, 49.376654110679738],
              [-95.142499993554111, 49.371653970145587],
              [-95.120835791270054, 49.365000080110178],
              [-95.081863964708987, 49.359591070337842],
            ],
          ],
          [
            [
              [179.066251755000167, 55.350065231000315],
              [179.115209579000407, 55.342088699000044],
              [179.168863297000257, 55.346014023000293],
              [179.219617844000254, 55.348340988000189],
              [179.27041435200033, 55.350397110000131],
              [179.321241379000526, 55.352174759000036],
              [179.372102737000432, 55.353681564000283],
              [179.422986984000204, 55.354913712000268],
              [179.473890305000282, 55.355875015000152],
              [179.524805069000195, 55.356557846000044],
              [179.5757350920004, 55.35696601900014],
              [179.626668930000278, 55.357107162000034],
              [179.677602768000384, 55.35696601900014],
              [179.728528976000462, 55.356557846000044],
              [179.77944374100025, 55.355875015000152],
              [179.830347061000339, 55.354913712000268],
              [179.881231308000224, 55.353681564000283],
              [179.932092667000234, 55.352174759000036],
              [179.982919693000326, 55.350397110000131],
              [179.995818669000187, 55.350393273500117],
              [179.999999503000254, 55.35039398400005],
              [179.999999503000254, 47.929979977000244],
              [179.965474503000451, 47.933451977000288],
              [179.9229025030005, 47.938521977000164],
              [179.880388502000301, 47.943821977000141],
              [179.837935502000278, 47.949357977000204],
              [179.795547502000318, 47.955124977000082],
              [179.7532275020003, 47.961124977000225],
              [179.71097450200034, 47.967354977000184],
              [179.668794502000424, 47.973815977000243],
              [179.626688502000093, 47.980510977000165],
              [179.584660502000247, 47.987435977000246],
              [179.542713502000197, 47.99459097700003],
              [179.500847502000283, 48.001974977000259],
              [179.459066502000269, 48.009587977000308],
              [179.43820850200018, 48.013482977000308],
              [179.417374502000143, 48.017429977000234],
              [179.39656050200017, 48.021437977000232],
              [179.375772502000018, 48.025501977000204],
              [179.372408502000212, 48.022487977000253],
              [179.329108502000508, 48.021896977000154],
              [179.28580550200013, 48.021540977000029],
              [179.242499502000442, 48.021421977000159],
              [179.199194502000068, 48.021540977000029],
              [179.155891502000259, 48.021896977000154],
              [179.112591502000328, 48.022487977000253],
              [179.069302502000255, 48.023315977000152],
              [179.026022502000274, 48.024382977000187],
              [178.982755502000373, 48.025682977000258],
              [178.939505502000316, 48.027221977000238],
              [178.896274502000438, 48.02899697700019],
              [178.853066502000274, 48.031007977000343],
              [178.809880501000293, 48.03325497700007],
              [178.766722501000459, 48.035737977000167],
              [178.723591501000442, 48.038457977000178],
              [178.680494501000226, 48.04141297700005],
              [178.637430501000495, 48.044604977000176],
              [178.59440550100021, 48.048029977000226],
              [178.551422501000047, 48.051690977000305],
              [178.508480501000463, 48.0555879770003],
              [178.46558350100031, 48.059718977000159],
              [178.422733501000494, 48.064082977000112],
              [178.379935501000318, 48.068682977000208],
              [178.337191501000234, 48.073515977000284],
              [178.294505501000117, 48.078582977000281],
              [178.251874501000316, 48.083882977000201],
              [178.209308501000351, 48.089412977000165],
              [178.166802501000234, 48.095179977000043],
              [178.124366501000281, 48.101176977000307],
              [178.081999501000269, 48.107404977000272],
              [178.039705501000412, 48.11386597700016],
              [177.997483501000147, 48.120557977000203],
              [177.955341501000476, 48.127479977000235],
              [177.913277501000493, 48.134632977000194],
              [177.871297501000186, 48.142012977000263],
              [177.829402501000345, 48.149624977000201],
              [177.787597501000278, 48.157465977000243],
              [177.745880500000339, 48.165532977000339],
              [177.704255500000272, 48.173829977000253],
              [177.662730500000293, 48.182351977000167],
              [177.621299500000305, 48.191101977000073],
              [177.57997450000039, 48.200076977000151],
              [177.538749500000336, 48.209279977000278],
              [177.497633500000347, 48.2187049770003],
              [177.456624500000203, 48.228357977000144],
              [177.415730500000222, 48.238229977000174],
              [177.374947500000189, 48.248329977000083],
              [177.334283500000311, 48.258649977000232],
              [177.293738500000245, 48.269190977000051],
              [177.253313500000104, 48.279954977000159],
              [177.213016500000322, 48.29093797700034],
              [177.172844500000224, 48.302143977000242],
              [177.132805500000472, 48.31356597700028],
              [177.092897500000163, 48.325207977000048],
              [177.053122500000427, 48.337065977000293],
              [177.013485500000343, 48.349140977000161],
              [176.973991500000579, 48.361432977000277],
              [176.934641500000453, 48.373940977000075],
              [176.895433500000308, 48.386662977000242],
              [176.856374500000129, 48.399599977000207],
              [176.817466500000137, 48.412746977000154],
              [176.778713500000208, 48.426107977000186],
              [176.740113500000348, 48.439679977000139],
              [176.701674500000308, 48.453460977000304],
              [176.663394499000333, 48.46745197700011],
              [176.625280499000269, 48.481654977000233],
              [176.587330499000245, 48.496060977000241],
              [176.54954949900025, 48.51067697700023],
              [176.530722499000376, 48.518060977000118],
              [176.512055499000326, 48.520965977000344],
              [176.468872499000327, 48.525560977000168],
              [176.425744499000302, 48.530385977000265],
              [176.382672499000364, 48.535446977000163],
              [176.339658499000279, 48.540740977000155],
              [176.29670849900026, 48.546265977000189],
              [176.253819499000542, 48.552024977000144],
              [176.210999499000536, 48.558015977000196],
              [176.168252499000346, 48.564237977000232],
              [176.125574499000209, 48.570690977000197],
              [176.082972499000107, 48.577374977000261],
              [176.040447499000379, 48.584287977000258],
              [175.998002499000336, 48.591432977000068],
              [175.955644499000414, 48.598804977000214],
              [175.91336949900051, 48.606407977000288],
              [175.871183499000267, 48.6142379770003],
              [175.829088499000136, 48.622296977000076],
              [175.787085499000227, 48.630582977000188],
              [175.745180499000298, 48.639096977000293],
              [175.703374499000347, 48.647835977000227],
              [175.66167249900036, 48.656801977000271],
              [175.620072499000344, 48.665993977000142],
              [175.578580498000179, 48.675407977000305],
              [175.537197498000296, 48.685049977000176],
              [175.495927498000242, 48.694912977000172],
              [175.454772498000352, 48.704999977000227],
              [175.413733498000056, 48.715307977000123],
              [175.372816498000134, 48.725837977000083],
              [175.33202249800047, 48.736587977000113],
              [175.291352498000151, 48.747560977000148],
              [175.250810498000192, 48.758751977000259],
              [175.210402498000462, 48.770162977000268],
              [175.170124498000291, 48.781790977000298],
              [175.129983498000229, 48.793635977000179],
              [175.089980498000273, 48.805699977000188],
              [175.050119498000299, 48.817976977000285],
              [175.01040249800019, 48.830471977000286],
              [174.970833498000275, 48.843179978000251],
              [174.931410498000105, 48.856101978000197],
              [174.892138498000236, 48.869235978000177],
              [174.853024498000082, 48.88258297800013],
              [174.814063498000337, 48.89613797800024],
              [174.775263498000072, 48.909904978000156],
              [174.736624498000538, 48.923882978000336],
              [174.698152498000013, 48.938068978000274],
              [174.659844498000325, 48.952460978000204],
              [174.621708498000089, 48.967060978000291],
              [174.583744498000442, 48.981865978000258],
              [174.545952498000247, 48.996874978000278],
              [174.508341497000401, 49.012087978000238],
              [174.470908497000437, 49.027504978000081],
              [174.433655497000245, 49.043124978000208],
              [174.421308497000268, 49.055110978000187],
              [174.399427497000261, 49.053079978000198],
              [174.355630497000448, 49.049193978000176],
              [174.311788497000293, 49.045540978000076],
              [174.267908497000349, 49.042124978000231],
              [174.223991497000497, 49.038940978000198],
              [174.180038497000169, 49.035993978000306],
              [174.136052497000151, 49.033282978000159],
              [174.092035497000438, 49.030804978000106],
              [174.047991497000226, 49.028562978000252],
              [174.003924497000298, 49.026557978000255],
              [173.959835497000427, 49.024787978000177],
              [173.915727497000262, 49.023251978000246],
              [173.871602497000254, 49.021954978000224],
              [173.827466497000273, 49.020893978000231],
              [173.783316497000556, 49.020065978000275],
              [173.739160497000512, 49.019474978000233],
              [173.694997497000031, 49.019121978000157],
              [173.650833497000463, 49.019001978000176],
              [173.606669497000212, 49.019121978000157],
              [173.562505497000416, 49.019474978000233],
              [173.518349497000145, 49.020065978000275],
              [173.474199497000313, 49.020893978000231],
              [173.430063496000344, 49.021954978000224],
              [173.385938496000563, 49.023251978000246],
              [173.341830496000398, 49.024787978000177],
              [173.297741496000185, 49.026557978000255],
              [173.253674496000031, 49.028562978000252],
              [173.209630496000273, 49.030804978000106],
              [173.165613496000333, 49.033282978000159],
              [173.121627496000315, 49.035993978000306],
              [173.077674496000327, 49.038940978000198],
              [173.033758496000246, 49.042124978000231],
              [172.989877496000418, 49.045540978000076],
              [172.946035496000377, 49.049193978000176],
              [172.902238496000223, 49.053079978000198],
              [172.858488496000518, 49.057199978000199],
              [172.814788496000233, 49.061551978000182],
              [172.771135496000397, 49.066137978000199],
              [172.727541496000299, 49.07096097800013],
              [172.684002496000289, 49.076012978000165],
              [172.640522496000244, 49.081299978000288],
              [172.597105496000381, 49.086815978000345],
              [172.553752496000129, 49.092568978000259],
              [172.510466496000504, 49.098549978000051],
              [172.467252496000242, 49.104762978000224],
              [172.424110496000026, 49.11120497800016],
              [172.381044496000186, 49.11787997800019],
              [172.338055495000276, 49.124785978000148],
              [172.29514949500026, 49.131918978000215],
              [172.25232449500038, 49.13928297800004],
              [172.226827495000407, 49.143610978000027],
              [172.183649495000395, 49.150051978000249],
              [172.140549495000187, 49.156726978000279],
              [172.097530495, 49.163629978000131],
              [172.054588495000417, 49.170762978000027],
              [172.011730495000165, 49.178124978000255],
              [171.968960495000374, 49.185715978000189],
              [171.926280495000242, 49.193535978000284],
              [171.883691495000107, 49.201582978000147],
              [171.841197495000074, 49.209854978000124],
              [171.798797495000258, 49.218357978000256],
              [171.756499495000298, 49.227082978000055],
              [171.714305495000531, 49.236035978000245],
              [171.672213495000165, 49.245212978000211],
              [171.630233495000425, 49.254615978000174],
              [171.588360495000302, 49.264240978000203],
              [171.546602495000229, 49.274090978000174],
              [171.50495849500021, 49.284160978000273],
              [171.463433495000231, 49.29445497800026],
              [171.422030495000172, 49.304971978000196],
              [171.380752495000252, 49.315704978000042],
              [171.339599495000357, 49.326662978000059],
              [171.298574494000263, 49.337837978000096],
              [171.257683494000389, 49.349229978000153],
              [171.216924494000523, 49.360843978000275],
              [171.176302494000311, 49.372671978000142],
              [171.135822494000308, 49.384715978000145],
              [171.095483494000405, 49.396976978000282],
              [171.055288494000365, 49.409454978000156],
              [171.015241494000293, 49.422143978000236],
              [170.995274494000455, 49.428568978000158],
              [170.97534449400041, 49.43504697800023],
              [170.955455494000375, 49.44157997800022],
              [170.935602494000364, 49.448162978000084],
              [170.915788494000367, 49.454801978000148],
              [170.896013494000385, 49.461490978000086],
              [170.876277494000419, 49.468235978000223],
              [170.856583494000347, 49.475029978000293],
              [170.836927494000292, 49.481876978000173],
              [170.836877494000419, 49.483646978000081],
              [170.797427494000317, 49.497185978000289],
              [170.75814149400037, 49.510932978000142],
              [170.71901649400013, 49.524890978000258],
              [170.680055494000271, 49.539054978000138],
              [170.641263494000327, 49.553426978000175],
              [170.602644494000288, 49.568004978000261],
              [170.564197494000268, 49.582790978000276],
              [170.525927494000143, 49.597779978000176],
              [170.487835494000365, 49.612971978000246],
              [170.449924494000129, 49.628365978000147],
              [170.412199494000333, 49.643962978000275],
              [170.3746584940003, 49.659760978000179],
              [170.337308494000126, 49.675757978000036],
              [170.300147494000271, 49.691951978000191],
              [170.263183494000373, 49.708346978000293],
              [170.226413493000223, 49.724935978000303],
              [170.189844493000237, 49.741718978000165],
              [170.153474493000203, 49.758699978000095],
              [170.117310493000332, 49.775871978000168],
              [170.081352493000395, 49.793235978000155],
              [170.045605493000267, 49.81079397800022],
              [170.010066493000181, 49.828537978000213],
              [169.974744493000458, 49.846471978000295],
              [169.939638493000302, 49.8645939780003],
              [169.904752493000387, 49.882904978000283],
              [169.870088493000367, 49.90139697800015],
              [169.835647493000351, 49.920076979000214],
              [169.801433493000445, 49.938935979000291],
              [169.767447493000418, 49.957979979000186],
              [169.733694493000257, 49.977201979000256],
              [169.700174493000418, 49.99660497900021],
              [169.666891493000207, 50.016185979000113],
              [169.633847493000189, 50.035940979000202],
              [169.601044493000359, 50.055874979000237],
              [169.568483493000258, 50.075979979000181],
              [169.53616949300033, 50.0962579790002],
              [169.504105493000338, 50.116707979000239],
              [169.472291493000284, 50.137329979000185],
              [169.443163493000384, 50.157315979000202],
              [169.405408493000209, 50.173296979000156],
              [169.36784749300034, 50.189471979000189],
              [169.330480493000323, 50.205846979000228],
              [169.293310493000263, 50.222415979000175],
              [169.25634149300015, 50.239182979000304],
              [169.219577493000315, 50.256143979000171],
              [169.183016493000196, 50.273296979000293],
              [169.14666649200035, 50.290640979000216],
              [169.110524492000309, 50.308176979000223],
              [169.074597492000407, 50.325904979000143],
              [169.038883492000423, 50.343818979000162],
              [169.003388492000227, 50.361921979000158],
              [168.968116492000149, 50.38021097900014],
              [168.933066492000194, 50.398682979000284],
              [168.898241492000352, 50.417340979000244],
              [168.863647492000268, 50.436182979000307],
              [168.82928049200018, 50.455204979000143],
              [168.795149492000178, 50.474407979000262],
              [168.761252492000267, 50.493787979000274],
              [168.727594492000321, 50.513349979000225],
              [168.694177492000222, 50.533085979000361],
              [168.661002492000193, 50.552996979000227],
              [168.628072492000342, 50.573082979000162],
              [168.595391492000431, 50.593340979000175],
              [168.562960492000457, 50.613768979000156],
              [168.530783492000182, 50.634368979000214],
              [168.49886049200029, 50.655137979000187],
              [168.467194492000203, 50.676074979000248],
              [168.435788492000256, 50.697174979000067],
              [168.404647492000436, 50.71844097900015],
              [168.373769492000179, 50.739871979000213],
              [168.343158492000271, 50.761460979000162],
              [168.312816492000138, 50.783212979000155],
              [168.28274749200034, 50.805124979000198],
              [168.252952492000304, 50.827190979000193],
              [168.223433492000254, 50.849415979000241],
              [168.194194492000406, 50.871793979000302],
              [168.165235492000193, 50.894326979000027],
              [168.136560492000172, 50.917007979000118],
              [168.108169492000343, 50.939840979000166],
              [168.080069491000131, 50.962824979000118],
              [168.052258491000316, 50.985951979000163],
              [168.024741491000327, 51.009226980000108],
              [167.997516491000283, 51.032646980000266],
              [167.970591491000164, 51.056204980000246],
              [167.948219491000145, 51.073824980000268],
              [167.921580491000213, 51.097524980000117],
              [167.895244491000312, 51.121365980000292],
              [167.869210491000445, 51.145340980000128],
              [167.843485491000365, 51.169454980000125],
              [167.818066491000195, 51.193704980000177],
              [167.811207652000348, 51.200365222000244],
              [167.792958491000149, 51.218085980000069],
              [167.768163491000337, 51.242596980000258],
              [167.743683491000297, 51.267237980000289],
              [167.719522491000134, 51.292004980000172],
              [167.695677491000197, 51.316901980000296],
              [167.693309750666913, 51.319375391333438],
              [167.64111328100023, 51.370834351000212],
              [167.794998169000223, 51.514167786000257],
              [167.814870244000304, 51.532535754500032],
              [167.996948242000258, 51.700832367000146],
              [168.200271606000342, 51.887222290000238],
              [168.404724121000299, 52.073055267000257],
              [168.610839844000225, 52.258609772000284],
              [168.818054199000244, 52.443889618000298],
              [169.02667236300033, 52.628612518000182],
              [169.179254667000237, 52.762429706000262],
              [169.236663819000228, 52.812778474000197],
              [169.284188144000154, 52.854118703000267],
              [169.44805908300043, 52.9966659560003],
              [169.661117555000146, 53.180278780000208],
              [169.720312851000131, 53.230812557000093],
              [169.87554931700015, 53.36333465700028],
              [170.031517454000209, 53.495414144000165],
              [170.09138488900021, 53.546112062000248],
              [170.272175462000178, 53.697769904000268],
              [170.308609010000282, 53.728332521000141],
              [170.466893784000149, 53.842127608000283],
              [170.5655517590003, 53.913055422000298],
              [170.822784425000236, 54.097221377000267],
              [170.905967248000252, 54.156384440000238],
              [171.080551148000154, 54.280555728000309],
              [171.306116896000276, 54.440150489000246],
              [171.338882446999946, 54.463333132000173],
              [171.422431553000138, 54.522111794000239],
              [171.597503663000566, 54.64527893300027],
              [171.856674195000437, 54.826667788000236],
              [172.116394044000231, 55.007221224000148],
              [172.17824742200014, 55.049998581000295],
              [172.376663209000299, 55.187221530000272],
              [172.637222291000057, 55.36639023100031],
              [172.898605348000501, 55.544998171000145],
              [173.003727429000492, 55.616417926000281],
              [173.160278321000419, 55.722778323000284],
              [173.422775270000216, 55.899723055000209],
              [173.472055972000248, 55.932801246000167],
              [173.597452783000222, 56.016970086000299],
              [173.674962569000286, 56.068996200000129],
              [173.68556213400052, 56.076110840000297],
              [173.94888305700033, 56.251945496000189],
              [173.983788590000131, 56.266385582000225],
              [174.007658905000312, 56.281543232000217],
              [174.04572868300022, 56.278005600000256],
              [174.09707832300046, 56.272947311000166],
              [174.148332596000273, 56.267621994000194],
              [174.199487686000339, 56.262029648000237],
              [174.250535965000381, 56.256166458000223],
              [174.301477432000183, 56.250032425000086],
              [174.352300644000366, 56.243635178000204],
              [174.403005600000483, 56.236967087000153],
              [174.453588486000285, 56.230035782000357],
              [174.504037857000299, 56.222837448000178],
              [174.554357529000526, 56.215372086000116],
              [174.604536057000246, 56.207643509000263],
              [174.654569626000352, 56.199655533000112],
              [174.704458237000154, 56.191400528000202],
              [174.754194260000304, 56.18288612400022],
              [174.803773880000222, 56.174104691000252],
              [174.853185654000299, 56.165067673000181],
              [174.902437210000244, 56.155771255000161],
              [174.951513290000435, 56.146215439000173],
              [175.000417709000317, 56.13640022300018],
              [175.049139023000293, 56.12633323700021],
              [175.097677231000375, 56.116003036000166],
              [175.146024704000411, 56.105424881000033],
              [175.194177628000261, 56.094587326000124],
              [175.242136002000279, 56.083501816000251],
              [175.289888382000186, 56.072160721000046],
              [175.337434769000538, 56.060571671000218],
              [175.384767532000069, 56.048730850000254],
              [175.431890488000136, 56.036642075000202],
              [175.47878837600058, 56.024309158000221],
              [175.525465012000268, 56.011728287000096],
              [175.571912766000423, 55.998899460000302],
              [175.618127823000577, 55.985830307000299],
              [175.664110184000265, 55.972520828000143],
              [175.709848404000354, 55.958967209000036],
              [175.755342484000266, 55.945173264000118],
              [175.800588608000453, 55.931142807000185],
              [175.845582962000208, 55.916872025000146],
              [175.878927231000375, 55.906526566000082],
              [175.927694321000445, 55.896974564000061],
              [175.976285934000543, 55.887166977000106],
              [176.024702072000082, 55.87710380600015],
              [176.072931290000383, 55.866785049000157],
              [176.120973587000435, 55.856210709000095],
              [176.16882133500053, 55.845380783000223],
              [176.216478348000237, 55.834299088000364],
              [176.263933182000414, 55.82296943700004],
              [176.311182022000253, 55.811388016000194],
              [176.358221054000296, 55.7995548250002],
              [176.405046463000076, 55.787477493000097],
              [176.451650620000351, 55.775148392000233],
              [176.498037338000358, 55.762571335000302],
              [176.544195175000254, 55.749757767000233],
              [176.590124130000504, 55.73669624300004],
              [176.632925034000323, 55.725599289000286],
              [176.680246353000371, 55.714269638000133],
              [176.727369308000334, 55.702688217000343],
              [176.774278641000365, 55.690862656000206],
              [176.820974350000142, 55.678785324000103],
              [176.867456436000452, 55.666463852000277],
              [176.92352485700053, 55.659135818000209],
              [176.973791122000307, 55.653280258000279],
              [177.023950577000164, 55.647157669000251],
              [177.073995590000322, 55.64077186600008],
              [177.123926163000419, 55.634119034000321],
              [177.173730850000197, 55.627195358000279],
              [177.223413467000569, 55.62000846900014],
              [177.272962570000345, 55.612558365000268],
              [177.322374344000423, 55.604841232000069],
              [177.37164878800013, 55.596860886000059],
              [177.420774460000302, 55.588624954000181],
              [177.469755173000522, 55.580121994000251],
              [177.518579483000281, 55.571355820000065],
              [177.567243576000578, 55.562330246000272],
              [177.615743637000264, 55.55304908800025],
              [177.664079666000248, 55.543508530000281],
              [177.712244034000264, 55.533712387000207],
              [177.760225296000272, 55.523656845000062],
              [177.808031082000525, 55.51334571800021],
              [177.855649948000178, 55.502782822000199],
              [177.903078079000352, 55.491960526000184],
              [177.950311661000114, 55.480894089000174],
              [177.997346878000485, 55.469572067000058],
              [178.04417991600053, 55.457998276000183],
              [178.064050674000441, 55.453451157000075],
              [178.114900589000399, 55.45084953300011],
              [178.165700912000375, 55.447977066000192],
              [178.216447830000334, 55.444829941000137],
              [178.267137527000386, 55.441415787000096],
              [178.317762375000257, 55.437726974000157],
              [178.368318558000055, 55.433763504000126],
              [178.418802261000337, 55.42953300500011],
              [178.469205856000258, 55.425027847000194],
              [178.519529343000158, 55.420255661000226],
              [178.569765091000249, 55.415216446000329],
              [178.619905472000028, 55.409902573000181],
              [178.669954300000427, 55.404321671000105],
              [178.719896317000575, 55.398469925000143],
              [178.769731522000029, 55.392354965000266],
              [178.819456100000252, 55.385969162000151],
              [178.869066238000187, 55.379316330000165],
              [178.918558121000274, 55.372404099000164],
              [178.967916489000118, 55.365221024000277],
              [179.017152786000338, 55.357774734000202],
              [179.066251755000167, 55.350065231000315],
            ],
          ],
          [
            [
              [-140.94638198922604, 60.29777324389886],
              [-140.521390998572826, 60.222218097912048],
              [-140.497499892247106, 60.251245131603923],
              [-140.471926873225414, 60.283882251003547],
              [-140.450835825482386, 60.309718126509551],
              [-140.00558191912188, 60.193882235957972],
              [-139.979426860742194, 60.187763278984733],
              [-139.913055095538027, 60.220827037257152],
              [-139.866391028773307, 60.244436008721323],
              [-139.77166395559982, 60.292500188596421],
              [-139.676663967658044, 60.340545090094167],
              [-139.519472940189871, 60.34470905196531],
              [-139.066882018683543, 60.344718104420792],
              [-139.082217884052227, 60.287500215700277],
              [-139.129455105357806, 60.201100060440638],
              [-139.155273043590967, 60.154991210943535],
              [-139.186399911672254, 60.095554129532218],
              [-139.161408932008612, 60.070273136017931],
              [-139.116390903394006, 60.04138222443396],
              [-139.047791060681618, 59.99749122652355],
              [-138.971926957742994, 59.97860009309386],
              [-138.690273049674829, 59.906935994210073],
              [-138.67501798585289, 59.866936043402688],
              [-138.667008909708386, 59.838391136782718],
              [-138.650982040239853, 59.810127023919961],
              [-138.615817944663888, 59.77416329477893],
              [-138.538608881537755, 59.732209187059652],
              [-138.491091034113083, 59.708327133189357],
              [-138.303617866768207, 59.613054062841172],
              [-138.117764083121131, 59.516663181882905],
              [-137.910281971735742, 59.40804511581382],
              [-137.590817968889951, 59.238600081144966],
              [-137.566099903994484, 59.186936041484159],
              [-137.54529099111295, 59.143054096029061],
              [-137.499144925965822, 59.041382113140969],
              [-137.483735970401256, 58.990273123109716],
              [-137.497217926420433, 58.964154106913838],
              [-137.506317991085325, 58.937909194531812],
              [-137.497635848113987, 58.914436009899532],
              [-137.471799972608096, 58.906654083331489],
              [-137.445008895413423, 58.907491100184814],
              [-137.423891025579962, 58.912763149659156],
              [-137.392790979589108, 58.928327170432851],
              [-137.33890003588607, 58.965544999930557],
              [-137.31418197099083, 58.981100135886948],
              [-137.296108911313468, 58.989991155886116],
              [-137.251681974512337, 59.006100168005901],
              [-137.033081939086003, 59.077491184483563],
              [-136.969726988481341, 59.098327087093423],
              [-136.941955060974408, 59.109436126317064],
              [-136.888335858573384, 59.131936004349939],
              [-136.808899891405474, 59.16526328965881],
              [-136.719726834929475, 59.16526328965881],
              [-136.611390903722395, 59.164709078219232],
              [-136.583891052793206, 59.163318185202627],
              [-136.558344855861947, 59.186382165320765],
              [-136.486526865235362, 59.255827071513409],
              [-136.462626874092223, 59.295963144428924],
              [-136.462490919622326, 59.372218179332833],
              [-136.46417299995619, 59.41415401450331],
              [-136.463617950326409, 59.469709101267313],
              [-136.371644835267773, 59.452491163354352],
              [-136.296526889351981, 59.47360014837011],
              [-136.23389093840359, 59.525827116650191],
              [-136.239163993706029, 59.561382144191384],
              [-136.29833587170026, 59.583600222638609],
              [-136.345126840479566, 59.601663224032109],
              [-136.310544951899715, 59.612500186677948],
              [-136.20776404321856, 59.639436103159767],
              [-136.15999993547797, 59.646663147735694],
              [-136.120817890784451, 59.651654068176526],
              [-136.071381928631752, 59.657491225564399],
              [-135.949155004529302, 59.669153973313087],
              [-135.823335931991835, 59.70555406433499],
              [-135.506135881199782, 59.793882224968456],
              [-135.473600015190982, 59.801936060476066],
              [-135.336117859628303, 59.726653996895436],
              [-135.177491012142241, 59.636936116711695],
              [-135.154173060357095, 59.627209085687298],
              [-135.126508924125403, 59.622209112791325],
              [-135.097226912938311, 59.621373269404103],
              [-135.014464000752753, 59.567500095336186],
              [-135.015017876916033, 59.540554120570562],
              [-135.0177909457704, 59.498882147712891],
              [-135.028135890698479, 59.469300064391291],
              [-135.06332697600277, 59.458045012413777],
              [-135.079708902890673, 59.444709069148303],
              [-135.091673063878659, 59.426936081605504],
              [-134.951935841079546, 59.279991092607531],
              [-134.738890995817314, 59.250273222453984],
              [-134.688044862270374, 59.243327139273219],
              [-134.673481976014727, 59.207491150336523],
              [-134.650844802407505, 59.185544980829263],
              [-134.566408862343394, 59.130545111332992],
              [-134.532226958184253, 59.132209086756063],
              [-134.466735963368308, 59.129091186404082],
              [-134.382781982738209, 59.053600078157956],
              [-134.325564094017636, 58.97110019009483],
              [-134.327499978380501, 58.925473132393506],
              [-134.238326921904502, 58.854435999869111],
              [-134.087491053441738, 58.808327150372179],
              [-133.826935904467689, 58.726100177077171],
              [-133.808344843172563, 58.709991164957387],
              [-133.73580885871803, 58.644718099624527],
              [-133.558899906888655, 58.528045024581502],
              [-133.429990929556027, 58.459163214645571],
              [-133.38790891399384, 58.412063288914226],
              [-133.408908934268254, 58.400273136236763],
              [-133.430299886507953, 58.35999121820555],
              [-133.361118004437344, 58.280545025115941],
              [-133.30611796730318, 58.257218188513491],
              [-133.23360897257686, 58.211382086146727],
              [-133.211399946585175, 58.196382167457898],
              [-133.18986398742021, 58.180127142584581],
              [-133.136963952313522, 58.135827107797752],
              [-133.108399934954207, 58.085754121002083],
              [-133.091244861315317, 58.040554037449965],
              [-133.070826880399011, 58.012218175495889],
              [-133.055555052770728, 57.997073249882021],
              [-133.038609023797335, 57.982909174580413],
              [-132.994990940655043, 57.951663283473749],
              [-132.964717853233594, 57.933327032036004],
              [-132.933199885549129, 57.909436093348347],
              [-132.877344894288626, 57.85943602911027],
              [-132.815900011784947, 57.798045126063073],
              [-132.795973042757964, 57.771382124349373],
              [-132.786664101065355, 57.751936108928135],
              [-132.762844911469131, 57.720127121564417],
              [-132.619444796972004, 57.583327085284168],
              [-132.498199896648202, 57.470409102874385],
              [-132.471926988709299, 57.451100215389431],
              [-132.451109023372453, 57.435263279847391],
              [-132.37443589913704, 57.375127147709122],
              [-132.354155046157018, 57.354436084386691],
              [-132.226655066752073, 57.20470914171699],
              [-132.261399899530147, 57.168882205236002],
              [-132.324235842688665, 57.089154044922623],
              [-132.221099876587971, 57.068054112362177],
              [-132.027499873280647, 57.036382085296623],
              [-132.036681912958898, 57.013054075227899],
              [-132.061955027484089, 56.959718181154699],
              [-132.091944806577374, 56.893609104797008],
              [-132.103055019267401, 56.866663297669504],
              [-131.861726950403295, 56.795827163183617],
              [-131.858035895510625, 56.718882130008353],
              [-131.824372998797458, 56.598400150513953],
              [-131.611117935403172, 56.602218107420811],
              [-131.578891026346128, 56.603327033214157],
              [-131.548617938924679, 56.599436153749139],
              [-131.314455056190923, 56.509991188333515],
              [-131.290008900235421, 56.500545118704451],
              [-131.213318006365114, 56.469154053034401],
              [-131.164735824872196, 56.445263281984751],
              [-131.144473076803251, 56.43471817720831],
              [-131.125826862585086, 56.424163181786128],
              [-131.062772989943255, 56.400827125090188],
              [-130.920008894260832, 56.382491208928627],
              [-130.847226984766507, 56.374436032316453],
              [-130.774446081100564, 56.366100061947009],
              [-130.75586407226092, 56.353053964894059],
              [-130.720554969759462, 56.325554113965211],
              [-130.627200014139589, 56.258609194220469],
              [-130.560717943089514, 56.25000014144473],
              [-130.532773013100552, 56.246382176748],
              [-130.484709000863518, 56.239436093567178],
              [-130.461946098708324, 56.235264252707054],
              [-130.447508941000478, 56.206382225940558],
              [-130.365264030432627, 56.123882170239085],
              [-130.229154998252113, 56.09027325298274],
              [-130.088590984521431, 56.11804501285161],
              [-130.053890911106578, 56.075553960413231],
              [-130.014463947201705, 56.02485400725817],
              [-130.014735856141499, 55.90999108803004],
              [-130.015072322072001, 55.909182532093041],
              [-130.014285785999903, 55.904516982000075],
              [-130.012543831499897, 55.887020432500037],
              [-130.050630785999829, 55.846002982000073],
              [-130.091648785999837, 55.816704982000147],
              [-130.123875786999918, 55.793265979000125],
              [-130.147314785999924, 55.755178982000132],
              [-130.147314785999924, 55.717091482],
              [-130.138525785999889, 55.676073982000332],
              [-130.132665785999961, 55.629196982000167],
              [-130.12680578599992, 55.561810982000281],
              [-130.106297785999857, 55.520793982000214],
              [-130.082858785999974, 55.47098698200017],
              [-130.053560785999878, 55.418249982000304],
              [-130.024262785999838, 55.371372982000196],
              [-129.994964785999912, 55.318636982000044],
              [-129.968595786999856, 55.286408979000271],
              [-129.992034786999909, 55.262969979000161],
              [-130.056490785999813, 55.2219529810003],
              [-130.103367786999854, 55.183864979000134],
              [-130.138525786999878, 55.136987979000196],
              [-130.173682785999915, 55.090111981000348],
              [-130.194191785999976, 55.046164981000175],
              [-130.22934978699999, 55.013935979000166],
              [-130.267436786999895, 54.972918981000305],
              [-130.29966478699987, 54.943620981000038],
              [-130.331892787999891, 54.917251979000184],
              [-130.361190786999771, 54.893813981000164],
              [-130.393418787999849, 54.876234979000117],
              [-130.443225786999847, 54.849866981000162],
              [-130.487172787999839, 54.823497979000081],
              [-130.534049786999901, 54.800059981000061],
              [-130.586786787999984, 54.782480978000137],
              [-130.627803786999834, 54.767832983000289],
              [-130.630396620999875, 54.752276863000304],
              [-130.630733764333229, 54.750253452666811],
              [-130.627706935, 54.725057289000176],
              [-130.627499918666501, 54.723333622000155],
              [-130.628888887999835, 54.720833335000123],
              [-130.630071858999827, 54.71922019300024],
              [-130.631944442999952, 54.716666668000073],
              [-130.632404195999925, 54.716206916000033],
              [-130.634999998999831, 54.713055557000359],
              [-130.63967983, 54.707205769000211],
              [-130.640555555, 54.706111113000247],
              [-130.649444442999823, 54.685833335000211],
              [-130.649444442999823, 54.665000002000284],
              [-130.653139928999792, 54.657609031000106],
              [-130.654999967499862, 54.653888954000251],
              [-130.664748875999976, 54.656308319000289],
              [-130.69305555499983, 54.663333334000072],
              [-130.706111110999927, 54.667500000000189],
              [-130.745277777999945, 54.678333333000126],
              [-130.749722221999832, 54.678055556000174],
              [-130.764166666999927, 54.679444444000126],
              [-130.808766826666499, 54.684996605666754],
              [-130.82138888899982, 54.684722222000175],
              [-130.888333332999878, 54.689166667000336],
              [-130.894166666999809, 54.69055555600022],
              [-130.9232024289999, 54.701695062000169],
              [-130.938333332999946, 54.707500001000028],
              [-130.940164464999839, 54.707751331500106],
              [-130.952499999999873, 54.709444444000269],
              [-130.961388888999863, 54.71666666700014],
              [-130.981944443999851, 54.729444444000194],
              [-130.995555555999829, 54.736666667000293],
              [-131.051666666999893, 54.760833333000221],
              [-131.078611110999873, 54.771111111000266],
              [-131.216666666999856, 54.703055556000095],
              [-131.231666666999956, 54.681111111000291],
              [-131.267829168999867, 54.655063994000272],
              [-131.271388888999923, 54.652500001000362],
              [-131.277098479999836, 54.648302679000153],
              [-131.322777777999931, 54.614722222000182],
              [-131.563333332999832, 54.498055556000224],
              [-131.633611111, 54.508888889000332],
              [-131.755555555999933, 54.471666667000193],
              [-131.824444443999937, 54.444722222000223],
              [-132.048333332999846, 54.364166667000177],
              [-132.394166666999865, 54.414444444000196],
              [-132.408055555999908, 54.411388889000079],
              [-132.409722221999971, 54.411388889000079],
              [-132.4475, 54.410833333000141],
              [-132.637777777999929, 54.409444444000258],
              [-132.662777777999963, 54.415000000000191],
              [-132.736666666999781, 54.433333333000178],
              [-132.826388888999986, 54.451944444000048],
              [-132.845, 54.456388889000152],
              [-132.931666666999945, 54.473611111000139],
              [-132.941111110999884, 54.475555556000188],
              [-133.116666666999777, 54.500833333000287],
              [-133.128611110999884, 54.502777778000336],
              [-133.191111110999827, 54.5116666670001],
              [-133.233333332999848, 54.517222222000157],
              [-133.282777777999854, 54.501666667000279],
              [-133.739999999999782, 54.366944444000069],
              [-133.81103776849983, 54.345896217000131],
              [-133.8225, 54.34250000100019],
              [-133.848976679999851, 54.336476967000294],
              [-134.180277777999891, 54.261111111000218],
              [-134.396388888999923, 54.215833333000205],
              [-134.417499999999791, 54.2125],
              [-134.939999999999827, 54.125000000000171],
              [-135.765833332999875, 54.000277778000168],
              [-138.755283094333208, 53.474233527833633],
              [-138.775835793999818, 53.500544982000179],
              [-138.796030793999932, 53.526955982000288],
              [-138.815863793999824, 53.553469982000308],
              [-138.835333793999894, 53.58008098200014],
              [-138.846399493000035, 53.595552801000338],
              [-138.854435793999983, 53.606788982000182],
              [-138.873169793999807, 53.633591982000269],
              [-138.89153379399994, 53.660488982000118],
              [-138.942035793999878, 53.68943898200024],
              [-138.951834413999791, 53.69571842500028],
              [-138.975269793999871, 53.710736982000299],
              [-139.008222794999881, 53.732194982000067],
              [-139.031145908999974, 53.747364556000207],
              [-139.040891794999936, 53.753813982000224],
              [-139.073274794999861, 53.775591982000208],
              [-139.105372794999823, 53.797524982000198],
              [-139.1371777949999, 53.81961698200007],
              [-139.168691794999859, 53.841863982000291],
              [-139.199908794999942, 53.864263982000068],
              [-139.230827794999868, 53.886813982000149],
              [-139.261447794999867, 53.909516982000298],
              [-139.291763794999952, 53.93236698200036],
              [-139.321772794999788, 53.955366982000157],
              [-139.33121311199983, 53.962723259000143],
              [-139.351474794999831, 53.9785119820001],
              [-139.380869794999796, 54.001797982000141],
              [-139.409949794999818, 54.025230982000153],
              [-139.429378853999879, 54.041153030000203],
              [-139.438713794999899, 54.048802982000041],
              [-139.467160794999927, 54.072513982000089],
              [-139.495288794999965, 54.096366982000177],
              [-139.523094794999906, 54.120352982000043],
              [-139.550574794999875, 54.14447498200019],
              [-139.559719647999884, 54.152644155000303],
              [-139.577727794999873, 54.168730982000227],
              [-139.604549794999855, 54.193119982000042],
              [-139.63104479499998, 54.217636983000261],
              [-139.657199794999883, 54.242283983000107],
              [-139.683022794999886, 54.267055983000205],
              [-139.699568621999845, 54.283221327000149],
              [-139.708505794999894, 54.291952983000158],
              [-139.733647794999968, 54.316974983000136],
              [-139.742496642, 54.325948014000289],
              [-139.758444794999889, 54.342119983000146],
              [-139.782897794999883, 54.367383983000309],
              [-139.798244393999823, 54.383544229000165],
              [-139.807002794999846, 54.392766983000115],
              [-139.830755794999902, 54.418266983000194],
              [-139.839420028999882, 54.427749771000038],
              [-139.854158794999989, 54.443880983000156],
              [-139.899894794999909, 54.49544798300019],
              [-139.922224794999806, 54.521397983000043],
              [-139.944197794999866, 54.547455983000134],
              [-139.965802794999831, 54.573619983000299],
              [-139.987044794999861, 54.59988698300026],
              [-140.007916794999858, 54.626258983000184],
              [-140.019126532999906, 54.633352761000253],
              [-140.023783794999872, 54.636299983000185],
              [-140.033950770999809, 54.641853929000206],
              [-140.060208794999937, 54.656197983000254],
              [-140.096366795999927, 54.676269983000111],
              [-140.122134375999906, 54.690803553000194],
              [-140.132258795999917, 54.696513983000159],
              [-140.167880795999963, 54.71692498300024],
              [-140.177959301999863, 54.722793610000224],
              [-140.2032307959999, 54.737508983000168],
              [-140.238305795999793, 54.75825598300014],
              [-140.273099796, 54.779169983000202],
              [-140.30761679599982, 54.80024798300019],
              [-140.341847795999797, 54.821491983000215],
              [-140.375794795999809, 54.842894983000065],
              [-140.409452795999982, 54.864458983000304],
              [-140.442819795999867, 54.886183983000137],
              [-140.466029072999788, 54.901537543000245],
              [-140.475894795999864, 54.908063983000034],
              [-140.508669795999879, 54.930099983000048],
              [-140.51847496399995, 54.936799376000238],
              [-140.541149795999843, 54.95229198300018],
              [-140.563550975999874, 54.967850621000196],
              [-140.573324795999781, 54.974638983000204],
              [-140.583066873999826, 54.9815151330003],
              [-140.605199795999965, 54.997136983000246],
              [-140.636766795999961, 55.019783983000195],
              [-140.668024795999884, 55.042580983000278],
              [-140.689328435999869, 55.058376450000253],
              [-140.698969795999972, 55.065524983000273],
              [-140.708576654999888, 55.072766898000225],
              [-140.729602795999824, 55.088616983000236],
              [-140.750345751999873, 55.104516677000049],
              [-140.7599167959998, 55.11185298300029],
              [-140.769451399999923, 55.119283726000219],
              [-140.789913795999837, 55.13523098300027],
              [-140.810091076999811, 55.151222541000209],
              [-140.819588795999891, 55.158749983000291],
              [-140.829048734999844, 55.166376596000248],
              [-140.848938795999857, 55.182411983000179],
              [-140.877963795999847, 55.206208983000238],
              [-140.906658795999988, 55.230144983000059],
              [-140.925680600999954, 55.246286424000061],
              [-140.935022795999828, 55.254213983000056],
              [-140.944324517999974, 55.262246715000174],
              [-140.963052795999829, 55.278419983000219],
              [-140.990747796, 55.302755984000214],
              [-141.018099795999944, 55.327222984000286],
              [-141.045113795999896, 55.351819984000088],
              [-141.071783795999806, 55.376541984000141],
              [-141.089018907999787, 55.392811295000229],
              [-141.0981057959998, 55.401388984000164],
              [-141.107147932999851, 55.410082315000295],
              [-141.124080795999873, 55.426361984000209],
              [-141.149705795999921, 55.451458984000169],
              [-141.174974797, 55.476672984000061],
              [-141.199891796999907, 55.50200598400022],
              [-141.224449796999863, 55.527458984000134],
              [-141.239841473999832, 55.543720808000216],
              [-141.24864779699999, 55.553024984000217],
              [-141.257404151999822, 55.56246030300008],
              [-141.272480796999901, 55.57870598400018],
              [-141.287242885999831, 55.594930507000299],
              [-141.295949796999821, 55.604499984000086],
              [-141.319052796999927, 55.630402984000341],
              [-141.341785796999886, 55.656413984000267],
              [-141.350338049999891, 55.666403469000159],
              [-141.364147796999873, 55.682533984000145],
              [-141.386135796999952, 55.708755984000106],
              [-141.407747796999843, 55.735083984000141],
              [-141.428980796999838, 55.761511984000265],
              [-141.441495348999865, 55.777433976000168],
              [-141.449830796999777, 55.788038984000252],
              [-141.458110936999844, 55.798810569000239],
              [-141.470299797, 55.814666984000098],
              [-141.490385796999874, 55.841386984000053],
              [-141.501917299999832, 55.857085470000243],
              [-141.510083796999879, 55.868202984000163],
              [-141.518191962999822, 55.87950310000025],
              [-141.52939179699996, 55.895111984000152],
              [-141.548308796999834, 55.922111984000196],
              [-141.566830796999795, 55.94919798400025],
              [-141.584958796999842, 55.976372984000193],
              [-141.602688796999928, 56.003630984000267],
              [-141.620019796999884, 56.030972984000186],
              [-141.636947796999891, 56.058397984000237],
              [-141.673288796999884, 56.088483984000163],
              [-141.702813339999864, 56.102012818000276],
              [-141.713430796999774, 56.106877984000107],
              [-141.724031412999977, 56.111814770000194],
              [-141.753322796999896, 56.125455984000155],
              [-141.792958796999869, 56.144211984000037],
              [-141.821776893, 56.158069667000291],
              [-141.832341796999799, 56.163149984000256],
              [-141.871466796999869, 56.182263984000258],
              [-141.881992434999916, 56.187488887000256],
              [-141.910330796999858, 56.201555984000265],
              [-141.938425135999978, 56.215726158000109],
              [-141.948930796999832, 56.221024984000337],
              [-141.959414586999856, 56.226396925000074],
              [-141.987263796999912, 56.240666984000143],
              [-142.025324796999882, 56.260483984000246],
              [-142.063116796999793, 56.280472984000141],
              [-142.10063379699983, 56.300630984000236],
              [-142.137872796999773, 56.320961984000178],
              [-142.17483079699997, 56.341458984000212],
              [-142.211505796999916, 56.362122985000155],
              [-142.237581827999804, 56.377048345000162],
              [-142.247897798, 56.382952985000031],
              [-142.25818585799982, 56.38893630400014],
              [-142.283997797999973, 56.403947985000059],
              [-142.319805797999777, 56.425105985000243],
              [-142.345087429999921, 56.440284645000247],
              [-142.355319797999869, 56.446427985000184],
              [-142.365522694999925, 56.452651002000209],
              [-142.390538797999909, 56.467908985000292],
              [-142.425458797999909, 56.489547985000286],
              [-142.460074797999937, 56.511344985000278],
              [-142.503288797999858, 56.521461985000144],
              [-142.554135797999834, 56.52865898500022],
              [-142.594096660999924, 56.534500406000291],
              [-142.604888797999934, 56.536077985000134],
              [-142.615699889999973, 56.537709594000205],
              [-142.655544797999823, 56.543722985000159],
              [-142.695269031999828, 56.549904039000296],
              [-142.706097797999917, 56.551588985000308],
              [-142.756544797999823, 56.559677985000064],
              [-142.806885797999939, 56.567988985000284],
              [-142.811076362999927, 56.568098228000167],
              [-142.82863579799988, 56.568555985000103],
              [-142.871045928999933, 56.562161875000243],
              [-142.879322797999805, 56.560913985000184],
              [-142.887654916999907, 56.559696355000142],
              [-142.930110797999873, 56.553491985000164],
              [-142.98099179799982, 56.546297985000194],
              [-143.023524525999846, 56.540479825000205],
              [-143.031966797999814, 56.539324985000235],
              [-143.083030797999811, 56.532577985000103],
              [-143.091581210999834, 56.531487808000236],
              [-143.134183797999924, 56.526055985000141],
              [-143.185419797999884, 56.519758985000294],
              [-143.228077607999978, 56.51471293700024],
              [-143.236733797999875, 56.513688985000044],
              [-143.242504750999785, 56.511678883000172],
              [-143.280969797999887, 56.498280985000235],
              [-143.327210798999772, 56.484597985000221],
              [-143.373633798999833, 56.47111998500003],
              [-143.420233798999845, 56.457847985000058],
              [-143.467010798999866, 56.444783985000242],
              [-143.507252635999805, 56.433764366000162],
              [-143.513958798999909, 56.431927985000186],
              [-143.52073100299981, 56.430110172000184],
              [-143.561074798999925, 56.419280985000057],
              [-143.608358798999973, 56.406844985000134],
              [-143.655802798999844, 56.394619985000247],
              [-143.703408798999988, 56.382605985000282],
              [-143.744137948, 56.372541372000171],
              [-143.751172798999903, 56.370802985000182],
              [-143.799091799000053, 56.359213985000167],
              [-143.847160798999937, 56.347838984000191],
              [-143.895377798999903, 56.336674984000183],
              [-143.902667515999894, 56.335025336000115],
              [-143.943738798999902, 56.325730984000074],
              [-143.992244798999934, 56.314997984000229],
              [-144.040891798999837, 56.304483984000171],
              [-144.089672798999942, 56.294183984000199],
              [-144.131048377999974, 56.285656452000239],
              [-144.138585798999941, 56.284102984000299],
              [-144.18763379899994, 56.274238984000192],
              [-144.195292290999873, 56.272736941000176],
              [-144.236805798999882, 56.264594984000212],
              [-144.286105798999841, 56.25516998400019],
              [-144.327746665999967, 56.24741293100027],
              [-144.33552479899987, 56.245963984000184],
              [-144.385063799999983, 56.236977984000077],
              [-144.43471979999984, 56.228213984000206],
              [-144.442673473999861, 56.226848949000157],
              [-144.484485799999987, 56.219672984000169],
              [-144.526352484999876, 56.21268681000015],
              [-144.53436379999988, 56.211349984000037],
              [-144.584349799999956, 56.203249984000252],
              [-144.592474637999857, 56.201972272000035],
              [-144.634438799999913, 56.195372984000073],
              [-144.684627799999902, 56.187719984000125],
              [-144.726679833999953, 56.181506127000148],
              [-144.734916799999979, 56.180288984000072],
              [-144.743208692999843, 56.179103206000207],
              [-144.785299799999933, 56.173083984000073],
              [-144.827428029999879, 56.16725739300017],
              [-144.835774799999939, 56.166102984000133],
              [-144.844175556999943, 56.164980701000104],
              [-144.886338799999947, 56.159347984000078],
              [-144.936991799999959, 56.152816984000253],
              [-144.987724799999825, 56.146511984000256],
              [-145.038538799999827, 56.140430984000204],
              [-145.080818020999828, 56.135568525000338],
              [-145.089430799999974, 56.134577984000146],
              [-145.140397799999931, 56.128952984000136],
              [-145.1914358, 56.123552984000298],
              [-145.200201704999898, 56.122665862000247],
              [-145.242541799999884, 56.118380984000282],
              [-145.284896902999975, 56.114290487000119],
              [-145.293713799999949, 56.113438984000311],
              [-145.302579599999859, 56.112622903000158],
              [-145.344947799999971, 56.108722984000167],
              [-145.396238799999907, 56.104233984000189],
              [-145.438624883999893, 56.100718457000198],
              [-145.447588799999835, 56.099974984000198],
              [-145.456600987999877, 56.099268469000322],
              [-145.498994800999895, 56.095944984000027],
              [-145.550447800999905, 56.092144984000186],
              [-145.601949800999904, 56.088572984000052],
              [-145.653497800999872, 56.085230984000304],
              [-145.705083800999887, 56.082116984000038],
              [-145.756710800999826, 56.079236984000147],
              [-145.808372800999933, 56.076580984000316],
              [-145.86006980099998, 56.074158984000292],
              [-145.911794800999843, 56.071966984000085],
              [-145.963544800999841, 56.070005984000204],
              [-146.015319800999862, 56.068272984000146],
              [-146.067116800999855, 56.066772984000238],
              [-146.109382776999922, 56.065737013000216],
              [-146.118930800999891, 56.065502984000318],
              [-146.170760800999943, 56.064463984000326],
              [-146.180390549999828, 56.064313897000204],
              [-146.222602800999908, 56.063655984000263],
              [-146.264782991999908, 56.06318577900015],
              [-146.27445280099991, 56.063077984000188],
              [-146.284161797999872, 56.063013015000251],
              [-146.326308800999897, 56.062730984000268],
              [-146.378166800999935, 56.062616984000272],
              [-146.430024800999888, 56.062730984000268],
              [-146.472056142999918, 56.063012241000195],
              [-146.48188080099996, 56.063077984000188],
              [-146.491742383999934, 56.063187917000164],
              [-146.533730801999866, 56.063655984000263],
              [-146.575674171999907, 56.064309706000302],
              [-146.585572801999859, 56.064463984000326],
              [-146.637402801999883, 56.065502984000318],
              [-146.647372592999858, 56.065747351000198],
              [-146.689216801999947, 56.066772984000238],
              [-146.693766801999828, 56.05968398400023],
              [-146.716399801999842, 56.033580984000139],
              [-146.739405801999823, 56.007586984000227],
              [-146.762783801999859, 55.98169798400022],
              [-146.786533801999838, 55.955922984000154],
              [-146.810649801999915, 55.930258984000147],
              [-146.835130801999895, 55.904708984000365],
              [-146.859974801999897, 55.879272984000181],
              [-146.885180801999837, 55.853955984000152],
              [-146.910744801999897, 55.828758984000274],
              [-146.936663801999856, 55.803680984000266],
              [-146.962137086999888, 55.779486453000061],
              [-146.962938801999968, 55.778724984000235],
              [-146.963826478999948, 55.777897059000168],
              [-146.989560802, 55.753894984000283],
              [-147.015559076999835, 55.730081786000142],
              [-147.016533801999941, 55.729188984000189],
              [-147.017594798999966, 55.72823447900015],
              [-147.04385280199989, 55.704611984000053],
              [-147.07151680199982, 55.680161984000165],
              [-147.09951980199989, 55.655844984000055],
              [-147.127863801999951, 55.631658984000296],
              [-147.156541801999907, 55.607605984000031],
              [-147.184065540999853, 55.584917445000144],
              [-147.185555801999982, 55.583688984000162],
              [-147.18713065099999, 55.582412746000102],
              [-147.214899801999906, 55.559908984000288],
              [-147.244574801999846, 55.536266984000235],
              [-147.272830028999834, 55.5141308970002],
              [-147.274574801999876, 55.512763984000173],
              [-147.276404174999868, 55.511354806000327],
              [-147.304897801999886, 55.48940598400003],
              [-147.333630646999865, 55.467639075000193],
              [-147.335544801999873, 55.466188984000212],
              [-147.337542426999875, 55.464700507000032],
              [-147.366508801999885, 55.443116984000255],
              [-147.397788801999951, 55.420191984000041],
              [-147.429383801999876, 55.397411984000314],
              [-147.461285801999878, 55.374783984000146],
              [-147.493499801999832, 55.352305984000168],
              [-147.523605141999866, 55.331635872000163],
              [-147.526019801999922, 55.329977984000152],
              [-147.5285175, 55.328290887000264],
              [-147.558844801999896, 55.307805984000254],
              [-147.591969801999852, 55.285786984000026],
              [-147.622729819999876, 55.26566624000003],
              [-147.625391802999843, 55.263924983000265],
              [-147.628135104999814, 55.262159008000253],
              [-147.659108802999839, 55.242219983000155],
              [-147.690294199999897, 55.222464923000189],
              [-147.693119802999917, 55.220674983000265],
              [-147.727419802999833, 55.199288983000201],
              [-147.730407612999841, 55.197455819000027],
              [-147.762008802999929, 55.178066983000178],
              [-147.796883802999815, 55.157008983000253],
              [-147.828889710999874, 55.137985702000037],
              [-147.832038802999932, 55.136113983000143],
              [-147.835267906999945, 55.134224963000179],
              [-147.867474802999965, 55.115383983000186],
              [-147.903188802999892, 55.094822983000029],
              [-147.935786116999878, 55.076348515000177],
              [-147.939174802999844, 55.074427983000078],
              [-147.97543580299984, 55.054202983000152],
              [-148.011963802999873, 55.034149983000077],
              [-148.015590111999984, 55.032190722000053],
              [-148.048758802999913, 55.014269983000304],
              [-148.082111712999875, 54.996534206000206],
              [-148.08581680299983, 54.994563983000148],
              [-148.089599966999856, 54.992584155000316],
              [-148.123135802999883, 54.975033983000174],
              [-148.156853099999893, 54.957663895000223],
              [-148.160713802999936, 54.955674983000165],
              [-148.164652633999964, 54.953678500000251],
              [-148.198547802999855, 54.936497983000265],
              [-148.236633802999847, 54.917497983000089],
              [-148.270879192999956, 54.900687471000253],
              [-148.274972802999827, 54.898677983000255],
              [-148.3135558029999, 54.880038983000304],
              [-148.317801326999898, 54.878020752000168],
              [-148.352383802999924, 54.861580983000181],
              [-148.391455802999957, 54.843305983000164],
              [-148.430763802999905, 54.825216983000189],
              [-148.470310802999876, 54.807311983000204],
              [-148.510091802999966, 54.789594983000143],
              [-148.545480083999877, 54.774089448000211],
              [-148.550102802999817, 54.772063983000237],
              [-148.554799705999955, 54.770039852000366],
              [-148.590341802999859, 54.754722983000192],
              [-148.630805802999873, 54.737572983000291],
              [-148.671494803999877, 54.720611983000254],
              [-148.712399803999858, 54.703841983000245],
              [-148.753524803999852, 54.687266983000143],
              [-148.794863803999903, 54.670883983000181],
              [-148.836413803999875, 54.654697983000119],
              [-148.878174803999826, 54.638708983000242],
              [-148.914859756999903, 54.624900985000295],
              [-148.92013880399989, 54.622913983000217],
              [-148.925489718999955, 54.620935273000271],
              [-148.962308803999832, 54.607319983000309],
              [-149.004677804000011, 54.591922983000188],
              [-149.041752969999834, 54.578685771000039],
              [-149.04724480399986, 54.576724983000361],
              [-149.05280674499997, 54.574774707000074],
              [-149.090005803999844, 54.561730983000359],
              [-149.132960803999822, 54.546936983000194],
              [-149.176102803999839, 54.532344983000201],
              [-149.21943380399992, 54.517958983000085],
              [-149.234291803999866, 54.505786983000178],
              [-149.266374803999923, 54.483572983000158],
              [-149.298752803999861, 54.461511983000037],
              [-149.331424803999909, 54.439608983000198],
              [-149.361793636999977, 54.41957590800007],
              [-149.364388803999844, 54.417863983000245],
              [-149.397638803999826, 54.396277983000175],
              [-149.43117780399993, 54.374849983000217],
              [-149.434014344999895, 54.373066724000182],
              [-149.464999803999916, 54.353586983000184],
              [-149.499102803999847, 54.332486983000194],
              [-149.530487476999951, 54.313374656000235],
              [-149.533483803999815, 54.311549983000305],
              [-149.56814180399985, 54.290777983000282],
              [-149.603072803999822, 54.270174983000175],
              [-149.638272803999854, 54.249738983000213],
              [-149.67374180399986, 54.229474983000273],
              [-149.67713252799993, 54.22756825200014],
              [-149.709474803999854, 54.209380983000358],
              [-149.745472804999935, 54.189461982000068],
              [-149.781727804999861, 54.169711982000194],
              [-149.81461949999985, 54.152082135000171],
              [-149.818244804999978, 54.150138982000044],
              [-149.855010804999893, 54.130741982000245],
              [-149.85878990199987, 54.128780216000109],
              [-149.892033804999869, 54.111522982000224],
              [-149.92930280499985, 54.092483982000203],
              [-149.966822804999822, 54.073622982000188],
              [-150.000575970999876, 54.056924725000158],
              [-150.004583804999839, 54.054941982000116],
              [-150.008667274999851, 54.052954404000161],
              [-150.042585804999902, 54.036444982000205],
              [-150.080827804999927, 54.018130982000116],
              [-150.11507152199988, 54.001995195000234],
              [-150.11930580499984, 53.999999982000077],
              [-150.123615213999898, 53.998002743000143],
              [-150.158016804999846, 53.982058982000126],
              [-150.192575316999836, 53.966299016000164],
              [-150.19695880499998, 53.964299982000171],
              [-150.201416288999837, 53.962300607000202],
              [-150.236127804999882, 53.946730982000076],
              [-150.270991181999847, 53.931349326000145],
              [-150.275522804999895, 53.929349982000303],
              [-150.279123019999901, 53.927141857000095],
              [-150.317183804999928, 53.903797982000299],
              [-150.352012273999861, 53.888414937000221],
              [-150.356535804999851, 53.886416982000185],
              [-150.361132079999834, 53.884420056000351],
              [-150.396110804999864, 53.869222982000224],
              [-150.435905804999862, 53.852222982000171],
              [-150.471174103999857, 53.837406436000094],
              [-150.475916804999969, 53.835413982000262],
              [-150.480731551999895, 53.8334251240002],
              [-150.516141804999933, 53.818797982000149],
              [-150.551690412999847, 53.804362624000191],
              [-150.556577804999847, 53.80237798200028],
              [-150.597222804999831, 53.786149982000154],
              [-150.602253525999885, 53.78417633700019],
              [-150.638074804999889, 53.770122982000146],
              [-150.674026815999895, 53.756256416000156],
              [-150.679127804999979, 53.754288982000276],
              [-150.684300098999842, 53.752329062000285],
              [-150.720383804999898, 53.738655982000353],
              [-150.761835804999947, 53.72321998200016],
              [-150.803485804999951, 53.707986982000307],
              [-150.839942838999889, 53.694886883000038],
              [-150.845324805999866, 53.692952982000179],
              [-150.850776503999924, 53.691029433000267],
              [-150.887355805999874, 53.678122982000218],
              [-150.929572805999982, 53.663494982000145],
              [-150.971972805999854, 53.649069982000356],
              [-151.008895997999929, 53.636739127000169],
              [-151.014552805999898, 53.634849982000219],
              [-151.020277770999826, 53.632973881000169],
              [-151.057313806, 53.620836982000242],
              [-151.100249805999823, 53.60703098200014],
              [-151.143358805999839, 53.593430982000143],
              [-151.18071013099987, 53.581872673000305],
              [-151.186635805999884, 53.58003898200019],
              [-151.192628087999822, 53.578220803000306],
              [-151.230083805999897, 53.566855982000106],
              [-151.273694805999895, 53.553883982000286],
              [-151.317466805999885, 53.541122982000275],
              [-151.318935784499786, 53.537292037500151],
              [-151.352605805999843, 53.516316982000035],
              [-151.355540926999851, 53.514517724000029],
              [-151.386549805999863, 53.495508982000217],
              [-151.417749914999973, 53.476685324000243],
              [-151.42076380599994, 53.474866982000265],
              [-151.423855903999907, 53.473031147000142],
              [-151.455244805999911, 53.454394982000167],
              [-151.486818754999859, 53.435944433000202],
              [-151.489988805999957, 53.434091982000155],
              [-151.493236121999871, 53.432224353000265],
              [-151.524994805999853, 53.413958982000224],
              [-151.556935267999819, 53.395882089000338],
              [-151.560260805999832, 53.39399998200031],
              [-151.595783805999844, 53.374213982000299],
              [-151.63156080599984, 53.354602982000188],
              [-151.667588805999827, 53.335166982000146],
              [-151.671221607999854, 53.333238827000059],
              [-151.703866805999979, 53.315911982000216],
              [-151.74038880599997, 53.29683398200018],
              [-151.777155805999882, 53.277933982000093],
              [-151.810303772999958, 53.261169215000223],
              [-151.814163805999868, 53.259216982000282],
              [-151.818098722999821, 53.257258979000142],
              [-151.85140880599991, 53.240683982000178],
              [-151.888891805999918, 53.222330982000074],
              [-151.922521407999881, 53.206131454000172],
              [-151.926605806999959, 53.204163982000239],
              [-151.930764267999848, 53.202193469000179],
              [-151.964549806999827, 53.186183982000102],
              [-151.998491750999875, 53.170362190000162],
              [-152.002724806999908, 53.168388982000181],
              [-152.007031104999868, 53.166414598000244],
              [-152.04112280699988, 53.150783982000291],
              [-152.075362313999932, 53.13534211300032],
              [-152.07974180699992, 53.133366981000108],
              [-152.118583806999823, 53.116141981000283],
              [-152.123108958999893, 53.114168237000229],
              [-152.157641806999862, 53.099105981000264],
              [-152.192313341999892, 53.084235779000153],
              [-152.196910806999966, 53.082263981000096],
              [-152.236394806999982, 53.06561398100024],
              [-152.241136794999846, 53.06364856600004],
              [-152.276088806999979, 53.049161981000168],
              [-152.311173172999844, 53.034864247000257],
              [-152.315985806999976, 53.032902981000177],
              [-152.356088806999963, 53.016838981000262],
              [-152.396391806999901, 53.00097498100024],
              [-152.401416785999828, 52.999031389000152],
              [-152.436894806999845, 52.98530898100023],
              [-152.472496873999859, 52.97177832400024],
              [-152.477591806999868, 52.969841981000172],
              [-152.518480806999975, 52.954574981000121],
              [-152.523714674999866, 52.952655854000227],
              [-152.559560806999883, 52.939511981000294],
              [-152.595527412999843, 52.926559820000193],
              [-152.60083080699988, 52.924649981000186],
              [-152.642280806999878, 52.909991981000246],
              [-152.647720432999819, 52.908103476000065],
              [-152.683916806999861, 52.895536981000305],
              [-152.72572780699997, 52.881286981000187],
              [-152.76771980699985, 52.867244981000283],
              [-152.809880806999843, 52.853405981000265],
              [-152.846507576, 52.84161578800024],
              [-152.852216806999849, 52.839777981000339],
              [-152.857991891999944, 52.837954678000187],
              [-152.894719806999916, 52.826358981000055],
              [-152.93738580699997, 52.813147981000157],
              [-152.980216808, 52.800147981000293],
              [-153.023208807999822, 52.787358981000295],
              [-153.066355807999884, 52.774780981000163],
              [-153.103557891999827, 52.764156355000239],
              [-153.109658807999864, 52.762413981000293],
              [-153.15311380799983, 52.750263981000046],
              [-153.196716807999877, 52.738324981000233],
              [-153.24046380799993, 52.726602981000212],
              [-153.246818646999827, 52.724936926000169],
              [-153.284358807999865, 52.715094981000163],
              [-153.328391807999964, 52.70380598100013],
              [-153.372560807999832, 52.692730981000238],
              [-153.410325876999849, 52.683477661000211],
              [-153.416866807999867, 52.681874981000306],
              [-153.423468683999971, 52.680294595000078],
              [-153.461305807999878, 52.671236981000277],
              [-153.499211894000013, 52.662374736000231],
              [-153.505874807999817, 52.66081698100021],
              [-153.512598180999845, 52.659283068000207],
              [-153.550569807999892, 52.650619981000261],
              [-153.588603423999899, 52.642149490000293],
              [-153.595385807999833, 52.640638981000279],
              [-153.602228082999829, 52.639153258000363],
              [-153.640324807999832, 52.630880981000246],
              [-153.685383807999898, 52.621344981000277],
              [-153.723598451999891, 52.613463501000069],
              [-153.730558807999984, 52.612027981000153],
              [-153.737577294999909, 52.610619045000249],
              [-153.775844807999817, 52.602936981000255],
              [-153.814162934999928, 52.595449609000298],
              [-153.82123880799989, 52.59406698100014],
              [-153.828372435999853, 52.592711838000184],
              [-153.866741807999944, 52.585422981000249],
              [-153.90515934599992, 52.578327934000242],
              [-153.912349807999959, 52.576999981000029],
              [-153.919597392999833, 52.575700803000188],
              [-153.958060807999885, 52.568805981000253],
              [-154.003866807999856, 52.560833981000258],
              [-154.04241354899986, 52.554330594000305],
              [-154.049772808999847, 52.553088981000144],
              [-154.057186431999952, 52.551877097000045],
              [-154.095769808999876, 52.545569981000085],
              [-154.134387864999837, 52.539457089000223],
              [-154.14185580899985, 52.538274981000313],
              [-154.149378237999912, 52.537123850000171],
              [-154.188030808999969, 52.531208981000248],
              [-154.234291808999927, 52.524372981000226],
              [-154.280633808999852, 52.517761981000149],
              [-154.319370748999859, 52.512434494000161],
              [-154.327052808999895, 52.511377981000237],
              [-154.373549808999854, 52.505224981000254],
              [-154.420119808999885, 52.499302981000199],
              [-154.427957514999918, 52.498345638000274],
              [-154.466760808999851, 52.493605981000258],
              [-154.505580234999854, 52.489064900000301],
              [-154.513469808999929, 52.48814198100024],
              [-154.521409869999815, 52.487253679000275],
              [-154.560244808999954, 52.482908981000151],
              [-154.599091209999898, 52.47875693800006],
              [-154.607080808999882, 52.477902981000227],
              [-154.653974808999891, 52.473127981000175],
              [-154.662063384999897, 52.472345705000293],
              [-154.700927808999921, 52.468586981000158],
              [-154.73979686999985, 52.46502140600029],
              [-154.747933808999875, 52.464274981000244],
              [-154.764351079999955, 52.462892883000109],
              [-154.84209480899986, 52.456347981000192],
              [-154.889244808999848, 52.452730981000059],
              [-154.936435808999846, 52.449347981000301],
              [-154.983666808999857, 52.446197981000239],
              [-155.022518004999824, 52.443797983000081],
              [-155.030935808999885, 52.443277981000222],
              [-155.078238808999828, 52.440591981000182],
              [-155.125572809999852, 52.438138981000293],
              [-155.134124471999883, 52.43773832800008],
              [-155.172935809999842, 52.435919981000154],
              [-155.220322809999885, 52.433933981000166],
              [-155.267733809999868, 52.432180981000272],
              [-155.315163809999831, 52.430661981000185],
              [-155.353886482999854, 52.429611584000213],
              [-155.362608809999898, 52.429374981000251],
              [-155.371372869999817, 52.429180720000261],
              [-155.41006980999984, 52.428322981000065],
              [-155.436289213999856, 52.425483092000206],
              [-155.441672809999972, 52.424899981000181],
              [-155.445224429999854, 52.422999361000336],
              [-155.477399809999923, 52.405780981000305],
              [-155.509742170999942, 52.388752013000158],
              [-155.513369809999887, 52.386841981000202],
              [-155.517071954999864, 52.384924187000138],
              [-155.549574809999939, 52.368086981000204],
              [-155.586016809999876, 52.349511981000205],
              [-155.62269180999985, 52.331119981000086],
              [-155.659594809999817, 52.312911981000241],
              [-155.692728410999877, 52.296832819000201],
              [-155.696727809999828, 52.294891981000205],
              [-155.700799952999972, 52.292948022000189],
              [-155.734083809999817, 52.277058981000266],
              [-155.767515467999829, 52.261360505000084],
              [-155.771660809999815, 52.259413981000137],
              [-155.77587901799987, 52.257466025000269],
              [-155.809458809999853, 52.241958981000266],
              [-155.84318234499986, 52.226640827000267],
              [-155.847472809999857, 52.224691981000149],
              [-155.851835465999869, 52.222743639000043],
              [-155.885699809999863, 52.207619981000164],
              [-155.919704146999919, 52.192686522000088],
              [-155.924138809999846, 52.190738981000152],
              [-155.962788809999978, 52.174049981000223],
              [-155.967365926999861, 52.172107242000152],
              [-156.001641809999825, 52.157558981000193],
              [-156.040699809999865, 52.141261981000184],
              [-156.075241214999863, 52.127099134000161],
              [-156.079960809999818, 52.125163981000185],
              [-156.084749371999891, 52.12323404100016],
              [-156.119416809999933, 52.109261981000202],
              [-156.159072809999969, 52.093558981000285],
              [-156.198916810999862, 52.078055981000318],
              [-156.233955133999842, 52.064663247000283],
              [-156.238952810999791, 52.062752981000301],
              [-156.27917781099984, 52.04765298000035],
              [-156.28431269699982, 52.045759929000099],
              [-156.319585810999854, 52.032755980000161],
              [-156.360177810999886, 52.018061980000141],
              [-156.400949810999805, 52.003572980000172],
              [-156.441897810999819, 51.989286980000145],
              [-156.483019810999934, 51.975211980000154],
              [-156.518839114999963, 51.963177368000231],
              [-156.524310810999793, 51.961338980000164],
              [-156.565774810999869, 51.947677980000151],
              [-156.607402810999844, 51.934224980000295],
              [-156.613073063999849, 51.932428062000099],
              [-156.649194810999859, 51.920980980000309],
              [-156.691147810999979, 51.907947980000188],
              [-156.727455247999984, 51.89689399200023],
              [-156.733255810999964, 51.895127980000325],
              [-156.739121219999873, 51.893378363000238],
              [-156.775522810999945, 51.882519980000211],
              [-156.817938810999806, 51.870122980000303],
              [-156.864073815999831, 51.858562564000181],
              [-156.872005810999866, 51.856574980000232],
              [-156.877524751999971, 51.854751479000242],
              [-156.913366810999833, 51.842908980000288],
              [-156.94931211299982, 51.831260144000169],
              [-156.954897810999853, 51.829449980000334],
              [-156.996588810999867, 51.816202980000071],
              [-157.038438810999907, 51.803166980000071],
              [-157.080449810999937, 51.790341980000335],
              [-157.086295314999887, 51.788593625000146],
              [-157.122613810999837, 51.777730980000115],
              [-157.164930810999863, 51.765330980000158],
              [-157.207397810999879, 51.753144980000172],
              [-157.243973886999839, 51.742870753000147],
              [-157.250010810999896, 51.741174980000267],
              [-157.256110630999842, 51.739498061000347],
              [-157.292769811999818, 51.729419980000216],
              [-157.329507700999926, 51.719535861000111],
              [-157.335669811999963, 51.717877980000196],
              [-157.34189376499998, 51.716240570000252],
              [-157.378705812, 51.706555980000246],
              [-157.415594449999844, 51.697067035000202],
              [-157.421880811999955, 51.695449980000205],
              [-157.465185811999845, 51.684563980000121],
              [-157.471594177999833, 51.682990029000223],
              [-157.508624811999908, 51.673894980000227],
              [-157.552188811999912, 51.663447980000228],
              [-157.589349239999876, 51.654745828000159],
              [-157.595877811999856, 51.653216980000309],
              [-157.60246665299988, 51.651711956000327],
              [-157.639691811999853, 51.643208980000225],
              [-157.683622811999868, 51.633422980000148],
              [-157.727672811999838, 51.623858980000193],
              [-157.76506860499984, 51.615948120000269],
              [-157.771833811999954, 51.614516980000133],
              [-157.778656649999874, 51.613111634000234],
              [-157.816105811999847, 51.605397980000248],
              [-157.853607509999989, 51.59788209300018],
              [-157.860488811999829, 51.596502980000196],
              [-157.867427302999914, 51.5951509650003],
              [-157.904977811999828, 51.587833980000028],
              [-157.949566811999915, 51.579386980000322],
              [-157.987207138999878, 51.572463963000246],
              [-157.994258811999885, 51.571166980000157],
              [-158.001365978999871, 51.569898483000088],
              [-158.039047811999836, 51.563172980000274],
              [-158.076768376999865, 51.556642918000136],
              [-158.083930811999892, 51.555402980000224],
              [-158.091148297999865, 51.554192817000171],
              [-158.128905811999857, 51.547861980000334],
              [-158.17396981199991, 51.540547980000156],
              [-158.219119811999946, 51.533461980000254],
              [-158.256973065999858, 51.527722023000251],
              [-158.264352811999942, 51.526602980000291],
              [-158.271785537999847, 51.525515480000195],
              [-158.309666811999875, 51.519972980000261],
              [-158.355060812999852, 51.51356998000017],
              [-158.392989500999903, 51.508422950000295],
              [-158.40052781299994, 51.507399980000173],
              [-158.408117972999833, 51.506409836000159],
              [-158.446069812999923, 51.501458980000166],
              [-158.491680812999931, 51.495744980000211],
              [-158.529663819999826, 51.491187019000165],
              [-158.537355812999891, 51.490263980000236],
              [-158.583097812999881, 51.48501398000019],
              [-158.590890161999909, 51.484160092000195],
              [-158.62889981299989, 51.479994980000185],
              [-158.674760812999864, 51.475205980000283],
              [-158.712788957999891, 51.471432978000223],
              [-158.720680812999859, 51.470649980000246],
              [-158.766649812999816, 51.466324980000309],
              [-158.774637443999922, 51.465614910000227],
              [-158.8126698129999, 51.462233980000235],
              [-158.858738812999974, 51.458372980000149],
              [-158.904849812999856, 51.454747980000263],
              [-158.94287657799984, 51.451950925000176],
              [-158.951005812999853, 51.451352980000195],
              [-158.997197812999872, 51.448191980000161],
              [-159.043427812999823, 51.445263980000163],
              [-159.051694053999881, 51.444783164000171],
              [-159.08969181299986, 51.442572980000079],
              [-159.135985812999877, 51.440111980000211],
              [-159.182308812999821, 51.437883980000265],
              [-159.220255011999882, 51.436252942000124],
              [-159.228652812999911, 51.435891980000292],
              [-159.237094064999866, 51.435571951000156],
              [-159.275022812999822, 51.434133980000126],
              [-159.321410812999886, 51.432611980000161],
              [-159.367816812999848, 51.431319980000183],
              [-159.40567076899984, 51.430458884000188],
              [-159.414238813999901, 51.430263980000177],
              [-159.422847604999873, 51.430112129000179],
              [-159.460669813999885, 51.429444980000142],
              [-159.507108813999849, 51.428858980000143],
              [-159.553552813999829, 51.428505980000239],
              [-159.591271023999951, 51.428410968000208],
              [-159.599999813999915, 51.428388980000136],
              [-159.608767345999865, 51.428411065000091],
              [-159.646447813999885, 51.428505980000239],
              [-159.692891813999978, 51.428858980000143],
              [-159.739330813999828, 51.429444980000142],
              [-159.776879673999872, 51.430107321000321],
              [-159.785760813999929, 51.430263980000177],
              [-159.807777813999877, 51.421852980000267],
              [-159.813080306999836, 51.420011503000183],
              [-159.848369813999824, 51.407755980000218],
              [-159.883764531999901, 51.395693782000137],
              [-159.889133813999877, 51.393863980000276],
              [-159.89456876, 51.392047064000224],
              [-159.930063813999908, 51.380180980000205],
              [-159.971160813999887, 51.366708980000169],
              [-160.012416813999891, 51.353444980000177],
              [-160.048203734999817, 51.342166903000191],
              [-160.053835813999882, 51.340391980000163],
              [-160.09540881399991, 51.327552980000121],
              [-160.101168852999905, 51.325809521000224],
              [-160.13713581399989, 51.314922980000347],
              [-160.179016813999823, 51.302508980000084],
              [-160.221044813999839, 51.290308980000191],
              [-160.263216813999861, 51.278322980000155],
              [-160.299519889999971, 51.268223112000101],
              [-160.305533813999915, 51.266549980000207],
              [-160.311610688999821, 51.264896580000027],
              [-160.347991813999982, 51.254997980000269],
              [-160.390585813999934, 51.243658980000191],
              [-160.427116411999918, 51.234152527000276],
              [-160.433316813999937, 51.232538980000072],
              [-160.476177813999954, 51.22163898000025],
              [-160.482499663999874, 51.220068075000199],
              [-160.51916981499997, 51.210955980000051],
              [-160.562288814999846, 51.200494980000144],
              [-160.599090694999887, 51.19177894600017],
              [-160.605533814999916, 51.190252980000196],
              [-160.612035574999908, 51.188750336000226],
              [-160.648897814999884, 51.180230980000204],
              [-160.69238081499995, 51.170433980000155],
              [-160.73598081499992, 51.160855980000065],
              [-160.773015625999989, 51.152932052000097],
              [-160.7796948149998, 51.151502980000146],
              [-160.786430571999887, 51.150099172000182],
              [-160.823516814999863, 51.142369980000183],
              [-160.860653459999895, 51.134841379000306],
              [-160.867447814999935, 51.133463980000101],
              [-160.874299170999819, 51.132113499000184],
              [-160.911483814999826, 51.124783980000302],
              [-160.94871500799988, 51.117648823000309],
              [-160.955622814999884, 51.116324980000286],
              [-160.962586990999853, 51.115029370000173],
              [-160.999860814999977, 51.108094980000203],
              [-161.037175000999895, 51.101356644000248],
              [-161.044194814999827, 51.10008898000018],
              [-161.051269934999823, 51.098850077000066],
              [-161.088624814999918, 51.092308980000212],
              [-161.133144814999866, 51.084758980000231],
              [-161.177752814999934, 51.077433980000251],
              [-161.215208616999831, 51.071488149000288],
              [-161.222447814999896, 51.070338980000145],
              [-161.229739683999895, 51.069220373000235],
              [-161.267224814999821, 51.063469980000207],
              [-161.312083814999966, 51.056830980000143],
              [-161.357019814999973, 51.050422980000235],
              [-161.394580217999817, 51.045265110000116],
              [-161.402030814999904, 51.044241980000209],
              [-161.447113814999824, 51.038291980000281],
              [-161.454667042999944, 51.037335237000093],
              [-161.492263814999859, 51.032572980000168],
              [-161.537483814999945, 51.027083980000214],
              [-161.575109333999848, 51.022714002000328],
              [-161.582763815999897, 51.021824980000304],
              [-161.628108815999923, 51.016799980000201],
              [-161.635862840999863, 51.015981195000222],
              [-161.673508815999867, 51.012005980000197],
              [-161.718966815999835, 51.007444980000287],
              [-161.756623174999987, 51.003861221000136],
              [-161.764474815999932, 51.003113980000251],
              [-161.810035815999868, 50.999016980000192],
              [-161.855641815999888, 50.995149980000122],
              [-161.863636927999892, 50.994514265000191],
              [-161.901294815999819, 50.991519980000191],
              [-161.946985815999966, 50.988119980000249],
              [-161.992716815999955, 50.984955979000176],
              [-162.030352363999981, 50.982545837000259],
              [-162.038485815999962, 50.98202497900013],
              [-162.084285815999891, 50.979324979000182],
              [-162.130116815999969, 50.976863979000143],
              [-162.138383190999861, 50.976461999000264],
              [-162.175974815999979, 50.974633979000203],
              [-162.213549768999968, 50.973000321000029],
              [-162.221860815999889, 50.97263897900018],
              [-162.267766815999977, 50.970877979000306],
              [-162.313691815999931, 50.969352979000234],
              [-162.322130439999853, 50.969115859000169],
              [-162.359635815999951, 50.968061979000197],
              [-162.397112045999847, 50.967198385000131],
              [-162.405591815999799, 50.967002979000142],
              [-162.414112710999973, 50.966850605000161],
              [-162.45155881599996, 50.966180979000171],
              [-162.497533815999873, 50.965594979000173],
              [-162.534914635999968, 50.965308016000336],
              [-162.543516815999936, 50.965241979000268],
              [-162.589499815999886, 50.965124979000166],
              [-162.635483816999937, 50.965241979000268],
              [-162.644202988999979, 50.965308914000218],
              [-162.681466816999887, 50.965594979000173],
              [-162.727441816999914, 50.966180979000171],
              [-162.764614387999899, 50.966845714000158],
              [-162.773408816999876, 50.967002979000142],
              [-162.782240036999951, 50.967206489000091],
              [-162.819363816999811, 50.968061979000197],
              [-162.856440592999888, 50.969103793000045],
              [-162.865308816999828, 50.969352979000234],
              [-162.911233816999896, 50.970877979000306],
              [-162.957138816999873, 50.97263897900018],
              [-162.960794492999923, 50.97218531100026],
              [-162.978935816999922, 50.969933979000189],
              [-163.022094816999896, 50.959905979000212],
              [-163.058846156999863, 50.95157764600026],
              [-163.065366816999898, 50.950099979000299],
              [-163.071947301999927, 50.948647153000138],
              [-163.108758816999881, 50.940519979000044],
              [-163.152260816999927, 50.931158979000202],
              [-163.19587481699989, 50.922022979000303],
              [-163.219591495999964, 50.915373315000238],
              [-163.223508816999896, 50.91427497900014],
              [-163.227197104999817, 50.912387857000283],
              [-163.259208816999916, 50.896008979000271],
              [-163.291372075999959, 50.87982125800022],
              [-163.295133816999822, 50.877927979000162],
              [-163.298967482999927, 50.87603018700014],
              [-163.331274816999894, 50.860036979000256],
              [-163.367635816999893, 50.842330979000224],
              [-163.400232421999817, 50.82672204000022],
              [-163.404210816999921, 50.824816979000218],
              [-163.440999816999891, 50.807491979000304],
              [-163.445121369999839, 50.805583709000132],
              [-163.477997816999931, 50.790361979000295],
              [-163.515202816999846, 50.773419979000209],
              [-163.548350171999886, 50.758583368000188],
              [-163.552613816999951, 50.756674979000309],
              [-163.590224816999893, 50.740124979000143],
              [-163.594628771999879, 50.738220415000171],
              [-163.628035816999869, 50.723772979000273],
              [-163.661568232999883, 50.709518659000139],
              [-163.666041816999922, 50.707616979000193],
              [-163.670585076999856, 50.705719188000273],
              [-163.704244817999893, 50.691658979000181],
              [-163.738026175999977, 50.677793260000215],
              [-163.74263881799979, 50.675899979000292],
              [-163.781222817999975, 50.660341979000179],
              [-163.785972888999879, 50.658460648000244],
              [-163.819991817999892, 50.64498697900018],
              [-163.858944817999856, 50.629830979000076],
              [-163.893192051999932, 50.616744865000214],
              [-163.898077817999877, 50.614877979000084],
              [-163.903031574999858, 50.613019785000176],
              [-163.937391817999867, 50.600130979000255],
              [-163.971859699999897, 50.587438028000179],
              [-163.976880817999955, 50.585588979000192],
              [-164.016541817999865, 50.571252979000292],
              [-164.05637481799991, 50.55712497900015],
              [-164.096377817999979, 50.543202979000228],
              [-164.136544817999834, 50.529488979000234],
              [-164.14189684099992, 50.527697186000239],
              [-164.176874817999874, 50.515986979000274],
              [-164.217363817999825, 50.502694979000182],
              [-164.252528805999901, 50.491378198000234],
              [-164.258010817999889, 50.489613979000239],
              [-164.263557108999862, 50.487864715000228],
              [-164.298813817999985, 50.476744979000159],
              [-164.339766817999958, 50.464086979000172],
              [-164.380872817999887, 50.45164497900015],
              [-164.416385470999955, 50.441117737000241],
              [-164.422122817999934, 50.439416979000214],
              [-164.463516817999817, 50.427402979000192],
              [-164.469379317999824, 50.425737920000188],
              [-164.505052817999967, 50.415605979000247],
              [-164.540805345999985, 50.405671480000251],
              [-164.546730817999986, 50.404024979000155],
              [-164.552717235999864, 50.40239804700019],
              [-164.588541817999982, 50.392661979000195],
              [-164.624437790999963, 50.383124009000255],
              [-164.630485817999983, 50.381516979000196],
              [-164.672563817999958, 50.37059197900021],
              [-164.678732916999962, 50.369026718000157],
              [-164.714766817999873, 50.359883979000301],
              [-164.757097817999835, 50.34939797900023],
              [-164.79954981899985, 50.33913397900028],
              [-164.835773516999865, 50.330587082000193],
              [-164.842122818999854, 50.329088979000289],
              [-164.884813818999817, 50.319266979000133],
              [-164.927616818999837, 50.309666979000042],
              [-164.970533818999883, 50.300291979000292],
              [-165.013560818999849, 50.291138979000209],
              [-165.02020123399987, 50.289764680000246],
              [-165.056694818999887, 50.282211979000181],
              [-165.099930818999866, 50.273511979000148],
              [-165.14326681899982, 50.265033979000293],
              [-165.18670281899989, 50.256783979000204],
              [-165.23023581899983, 50.248758979000172],
              [-165.262438818999868, 50.242833979000238],
              [-165.303347818999839, 50.230383979000237],
              [-165.338703194999852, 50.219848413000193],
              [-165.344402818999839, 50.218149979000145],
              [-165.350164584999874, 50.216469015000143],
              [-165.385599818999822, 50.20613097900025],
              [-165.421117026999838, 50.195988360000285],
              [-165.426941818999836, 50.194324979000271],
              [-165.46841981899982, 50.182738979000305],
              [-165.474368128999942, 50.181113893000145],
              [-165.510033818999972, 50.171369979000247],
              [-165.545771225999971, 50.161822483000037],
              [-165.551780818999816, 50.160216979000268],
              [-165.557851811999882, 50.158632473000239],
              [-165.593658818999955, 50.149286979000237],
              [-165.635663818999831, 50.138572979000173],
              [-165.67779781899992, 50.128080979000174],
              [-165.697986608999855, 50.121117050000294],
              [-165.701030818999953, 50.120066979000171],
              [-165.740294818999871, 50.105713979000313],
              [-165.779724818999966, 50.09156697900022],
              [-165.784867214999935, 50.089757271000224],
              [-165.819324818999831, 50.077630979000162],
              [-165.853880630999839, 50.065701037000224],
              [-165.859088819999926, 50.063902979000261],
              [-165.864362295999882, 50.062117461000184],
              [-165.8990168199999, 50.050383979000287],
              [-165.933761917999959, 50.038847354000268],
              [-165.939099819999939, 50.037074979000238],
              [-165.979341819999973, 50.023977979000165],
              [-166.019738819999844, 50.011091979000184],
              [-166.025270136999836, 50.009363706000272],
              [-166.060285819999905, 49.998422979000168],
              [-166.09538639699997, 49.98767674200019],
              [-166.100980819999847, 49.985963979000303],
              [-166.10663882399993, 49.9842681840002],
              [-166.141822819999874, 49.973722979000172],
              [-166.182808819999821, 49.961694979000072],
              [-166.223933819999928, 49.949883979000163],
              [-166.25935265399994, 49.939931444000138],
              [-166.265197819999884, 49.93828897900022],
              [-166.271103929999867, 49.936665822000123],
              [-166.306594819999901, 49.926911979000181],
              [-166.342159521999918, 49.917356528000141],
              [-166.348127819999917, 49.915752979000047],
              [-166.389788819999836, 49.904813978000277],
              [-166.395878108999824, 49.903252058000191],
              [-166.431577819999887, 49.894094978000339],
              [-166.473491819999879, 49.88359497800019],
              [-166.509318385999819, 49.874835214000143],
              [-166.515527819999846, 49.873316978000105],
              [-166.521796581999837, 49.871821761000149],
              [-166.557683819999795, 49.863261978000025],
              [-166.593628650999818, 49.854897356000208],
              [-166.599955819999849, 49.853424978000191],
              [-166.642341819999899, 49.843813978000298],
              [-166.68483881999984, 49.834427978000292],
              [-166.727444819999874, 49.825263978000237],
              [-166.770155819999928, 49.816324978000296],
              [-166.7722938269998, 49.815985813000168],
              [-166.783355819999912, 49.814230978000182],
              [-166.818140383999861, 49.803080405000173],
              [-166.823572819999839, 49.801338978000217],
              [-166.829068716999899, 49.799612984000248],
              [-166.863938819999817, 49.788661978000277],
              [-166.904452819999875, 49.776197978000141],
              [-166.94511382099995, 49.763947978000203],
              [-166.980231435999826, 49.75359076500024],
              [-166.985916820999961, 49.751913978000118],
              [-166.991664157999963, 49.750254937000079],
              [-167.026860820999985, 49.740094978000286],
              [-167.067944820999884, 49.728494978000072],
              [-167.109158820999852, 49.717111978000275],
              [-167.150508820999818, 49.705947978000268],
              [-167.185992513999878, 49.696584499000267],
              [-167.191985820999804, 49.695002978000048],
              [-167.233591820999919, 49.684277978000125],
              [-167.239705753999971, 49.682738910000182],
              [-167.275322820999861, 49.673772978000272],
              [-167.311001038999848, 49.665006019000316],
              [-167.317174820999895, 49.663488978000089],
              [-167.359144820999887, 49.653427978000195],
              [-167.365436518999815, 49.651956891000282],
              [-167.401233820999892, 49.643586978000258],
              [-167.443433820999843, 49.633972978000259],
              [-167.47933928899991, 49.626000868000176],
              [-167.485747820999904, 49.624577978000048],
              [-167.492213851999878, 49.62318078300018],
              [-167.528166820999843, 49.615411978000168],
              [-167.564168759999887, 49.607839084000091],
              [-167.570691820999855, 49.606466978000071],
              [-167.577272070999953, 49.605121169000199],
              [-167.613322820999855, 49.597747978000029],
              [-167.656049820999982, 49.589255978000153],
              [-167.692185020999943, 49.582280875000265],
              [-167.698877820999854, 49.580988978000221],
              [-167.74179982099983, 49.572949978000111],
              [-167.784813820999915, 49.565138978000221],
              [-167.791671744999888, 49.563932481000165],
              [-167.82791682099986, 49.55755597800021],
              [-167.864195580999905, 49.551379887000166],
              [-167.871108820999922, 49.550202978000243],
              [-167.914383820999916, 49.54307497800022],
              [-167.921403700999861, 49.541958727000349],
              [-167.95773882099985, 49.536180978000175],
              [-168.001174821999825, 49.52951197800013],
              [-168.037560282999891, 49.524129127000208],
              [-168.044685821999821, 49.523074978000295],
              [-168.051863329999833, 49.522053125000184],
              [-168.088269821999887, 49.516869978000045],
              [-168.13192482199986, 49.510894978000238],
              [-168.168367355999948, 49.506106598000144],
              [-168.175647821999888, 49.505149978000247],
              [-168.219435821999951, 49.499638978000235],
              [-168.226817153999974, 49.498750188000258],
              [-168.263285821999801, 49.49435897800015],
              [-168.307197821999836, 49.489311978000217],
              [-168.351166821999868, 49.484494978000271],
              [-168.387658600999941, 49.48069758500003],
              [-168.395188821999938, 49.479913978000297],
              [-168.439260821999966, 49.475563978000082],
              [-168.483383821999951, 49.471449978000237],
              [-168.491057896999848, 49.470775853000134],
              [-168.527552821999819, 49.467569978000199],
              [-168.571766821999972, 49.463922978000255],
              [-168.61601982199997, 49.460508978000178],
              [-168.6603108219999, 49.4573309780003],
              [-168.704638821999879, 49.454386978000286],
              [-168.741093536999813, 49.452158227000155],
              [-168.74899782199995, 49.451674978000256],
              [-168.793388821999912, 49.449202978000187],
              [-168.8022091899999, 49.449410808000152],
              [-168.839266821999871, 49.450283978000073],
              [-168.88092282199986, 49.439994978000186],
              [-168.916504610999851, 49.431418282000209],
              [-168.92269982199997, 49.429924978000258],
              [-168.928953918999895, 49.428455358000178],
              [-168.964591821999846, 49.420080978000044],
              [-169.006599821999885, 49.410458978000236],
              [-169.048716821999818, 49.401061978000257],
              [-169.090941822999866, 49.391886978000173],
              [-169.126786811999978, 49.384310002000291],
              [-169.133272822999828, 49.382938978000197],
              [-169.175705822999959, 49.374213978000171],
              [-169.218238822999837, 49.365716978000194],
              [-169.224893840999897, 49.36442612500008],
              [-169.260869822999808, 49.357447978000266],
              [-169.296884542999948, 49.350666505000277],
              [-169.303594822999855, 49.349402978000057],
              [-169.310359963999872, 49.348168011000269],
              [-169.3464108229999, 49.341586978000294],
              [-169.366041906999868, 49.337353803000269],
              [-169.36957482299988, 49.336591978000229],
              [-169.37581316599983, 49.335122772000148],
              [-169.411385822999875, 49.326744978000306],
              [-169.453310822999896, 49.317119978000221],
              [-169.495347822999861, 49.307719978000193],
              [-169.537491822999982, 49.298544978000223],
              [-169.573271886999862, 49.290962992000345],
              [-169.579741822999978, 49.289591978000033],
              [-169.622094822999884, 49.280866978000233],
              [-169.664547822999793, 49.272366978000207],
              [-169.671186521999942, 49.271076280000159],
              [-169.707094822999863, 49.264094978000173],
              [-169.743043792999885, 49.257313028000169],
              [-169.749738822999859, 49.256049978000135],
              [-169.756488405999932, 49.254815071000166],
              [-169.792474822999935, 49.248230978000151],
              [-169.82849492399987, 49.241847871000175],
              [-169.8352998229999, 49.240641978000156],
              [-169.842158476999856, 49.239465437000263],
              [-169.878210822999876, 49.233280978000039],
              [-169.914292800999874, 49.22729654800014],
              [-169.921205822999895, 49.226149978000308],
              [-169.928171636999906, 49.225033831000246],
              [-169.964280822999939, 49.219247978000169],
              [-170.000416547999947, 49.21366035900013],
              [-170.007435822999838, 49.212574978000305],
              [-170.014507135999878, 49.21152093000012],
              [-170.05066682299983, 49.206130978000203],
              [-170.093969822999924, 49.199919978000025],
              [-170.130168616999896, 49.194931012000268],
              [-170.137344822999921, 49.193941978000112],
              [-170.180785823999969, 49.188191978000248],
              [-170.224294823999855, 49.182674978000307],
              [-170.267860823999854, 49.177391978000173],
              [-170.311491823999887, 49.172338978000255],
              [-170.318918306999848, 49.171519707000186],
              [-170.355174823999818, 49.167519978000144],
              [-170.398916823999883, 49.162933978000297],
              [-170.426283823999825, 49.157772978000139],
              [-170.46457282399993, 49.143180978000146],
              [-170.503030823999865, 49.128794978000201],
              [-170.536737444999886, 49.116419739000264],
              [-170.541655823999889, 49.114613978000307],
              [-170.546640553999936, 49.112818976000142],
              [-170.580447823999833, 49.100644978000219],
              [-170.6193998239998, 49.086883978000287],
              [-170.658513823999954, 49.073333978000164],
              [-170.692604530999859, 49.0617516450003],
              [-170.697783823999941, 49.059991978000085],
              [-170.737208823999879, 49.046863978000147],
              [-170.742516393999921, 49.045131846000174],
              [-170.776785823999859, 49.033947978000299],
              [-170.816510823999948, 49.02124797800019],
              [-170.856383823999806, 49.008758978000174],
              [-170.896399823999985, 48.996486978000121],
              [-170.930998887999948, 48.986097523000183],
              [-170.936558823999889, 48.984427978000156],
              [-170.976855823999955, 48.97258897800026],
              [-171.017288823999905, 48.960963978000279],
              [-171.023035024999984, 48.959349036000219],
              [-171.057858823999879, 48.949561978000304],
              [-171.092749643999923, 48.939971010000249],
              [-171.098555823999817, 48.938374978000184],
              [-171.104423071999889, 48.936799092000172],
              [-171.13938382399985, 48.927408978000187],
              [-171.180335823999826, 48.916661978000207],
              [-171.221410824999879, 48.906136978000177],
              [-171.262608824999859, 48.895833978000155],
              [-171.297817142999889, 48.887240264000184],
              [-171.303922824999859, 48.885749978000149],
              [-171.345349824999886, 48.875891978000027],
              [-171.35157313699986, 48.874448535000056],
              [-171.386894824999928, 48.86625597800014],
              [-171.428544824999932, 48.856844978000254],
              [-171.470305824999883, 48.847658978000311],
              [-171.512169824999916, 48.838697977000209],
              [-171.554135824999946, 48.82996197700021],
              [-171.589691142999982, 48.822769599000196],
              [-171.596199824999843, 48.821452977000149],
              [-171.602764565999877, 48.820163352000293],
              [-171.638363824999914, 48.813169977000257],
              [-171.673999208999817, 48.806378711000207],
              [-171.680619824999866, 48.805116977000296],
              [-171.722966824999901, 48.797288977000278],
              [-171.765402824999939, 48.789688977000253],
              [-171.772186882999819, 48.788513310000212],
              [-171.807924824999873, 48.782319977000213],
              [-171.850530824999936, 48.775180977000161],
              [-171.893216824999854, 48.768269977000159],
              [-171.929036522999837, 48.762673883000218],
              [-171.935980824999831, 48.761588977000144],
              [-171.97882282499998, 48.755138977000286],
              [-171.985871257999889, 48.754117463000114],
              [-172.02173382499987, 48.748919977000128],
              [-172.064716824999834, 48.742933977000291],
              [-172.100614944999819, 48.738134219000187],
              [-172.107766824999857, 48.737177977000272],
              [-172.114969312999932, 48.736255490000246],
              [-172.150880824999945, 48.73165597700023],
              [-172.186803287999794, 48.727252928000212],
              [-172.194055824999822, 48.72636397700029],
              [-172.201358405999912, 48.725509679000254],
              [-172.237291824999971, 48.72130597700027],
              [-172.280583824999837, 48.716480977000174],
              [-172.316529571999951, 48.71267303500025],
              [-172.323930825999838, 48.711888977000228],
              [-172.331380049999979, 48.711140914000225],
              [-172.367327825999979, 48.70753097700026],
              [-172.410772825999828, 48.703408977000265],
              [-172.454263825999817, 48.699519977000364],
              [-172.490207428999923, 48.696501560000286],
              [-172.49779982599992, 48.695863977000158],
              [-172.505438619999921, 48.69526461900017],
              [-172.541374825999952, 48.692444977000264],
              [-172.584988825999915, 48.689258977000236],
              [-172.628635825999851, 48.686308977000067],
              [-172.672316825999957, 48.683594977000041],
              [-172.708207198999901, 48.681557870000177],
              [-172.716027825999959, 48.681113977000109],
              [-172.723892212999885, 48.680710473000147],
              [-172.759763825999983, 48.678869977000261],
              [-172.795615852999873, 48.677226524000275],
              [-172.803524825999858, 48.67686397700021],
              [-172.847308825999875, 48.675091977000307],
              [-172.855303293999896, 48.6748116360003],
              [-172.891110825999931, 48.673555977000035],
              [-172.934927825999836, 48.672255977000304],
              [-172.970680126999895, 48.671388088000185],
              [-172.978758825999904, 48.671191977000205],
              [-173.022602825999883, 48.67036397700025],
              [-173.030763387999826, 48.670253991000209],
              [-173.066452825999932, 48.669772977000207],
              [-173.137724769999949, 48.669388642000285],
              [-173.154166825999852, 48.669299977000151],
              [-173.162447036999907, 48.669322633000263],
              [-173.198024825999937, 48.669419977000302],
              [-173.241880825999857, 48.669772977000207],
              [-173.277373505999861, 48.670251339000174],
              [-173.285730825999821, 48.67036397700025],
              [-173.329574825999885, 48.671191977000205],
              [-173.338006826999987, 48.671396665000259],
              [-173.373405826999971, 48.672255977000304],
              [-173.417222826999847, 48.673555977000035],
              [-173.452519518999821, 48.674793723000334],
              [-173.461024826999932, 48.675091977000307],
              [-173.504808826999835, 48.67686397700021],
              [-173.513384184999893, 48.677257071000213],
              [-173.548569826999881, 48.678869977000261],
              [-173.583695286999983, 48.680672189000177],
              [-173.592305826999819, 48.681113977000109],
              [-173.600950711999957, 48.681604654000296],
              [-173.636016826999821, 48.683594977000041],
              [-173.679697826999842, 48.686308977000067],
              [-173.723344826999863, 48.689258977000236],
              [-173.766958826999854, 48.692444977000264],
              [-173.801758353999872, 48.695175433000202],
              [-173.810533826999887, 48.695863977000158],
              [-173.819341006999821, 48.696603573000289],
              [-173.854069826999876, 48.699519977000364],
              [-173.877146843999867, 48.701194322000049],
              [-173.882930826999882, 48.70161397700025],
              [-173.890617139999819, 48.701053086000286],
              [-173.926549826999917, 48.698430977000271],
              [-173.97020882699988, 48.695480977000102],
              [-174.006118128999987, 48.693247640000152],
              [-174.013894826999945, 48.692763977000197],
              [-174.021717291999863, 48.69232077800018],
              [-174.057613826999898, 48.690286977000255],
              [-174.093492585999883, 48.688445674000207],
              [-174.101358826999871, 48.688041977000296],
              [-174.109268642999922, 48.687679097000284],
              [-174.145127826999868, 48.68603397700025],
              [-174.180965782999834, 48.684585467000261],
              [-174.188919826999921, 48.684263977000171],
              [-174.210887059499868, 48.683438529000057],
              [-174.211872826999866, 48.683047977000285],
              [-174.23233082699997, 48.677808977000211],
              [-174.267328163999849, 48.669010998000147],
              [-174.273338826999861, 48.667499977000261],
              [-174.279407354999904, 48.666011253000192],
              [-174.314460826999891, 48.657411977000095],
              [-174.355699826999825, 48.647547977000158],
              [-174.39705282699984, 48.637905977000173],
              [-174.43226960999985, 48.629907221000224],
              [-174.438513826999838, 48.628488977000302],
              [-174.444815798999826, 48.627095631000259],
              [-174.480083827999891, 48.619297977000087],
              [-174.521775151666532, 48.610445645666687],
              [-174.561805827999905, 48.598824977000277],
              [-174.601933827999829, 48.587191977000202],
              [-174.64219482799993, 48.575774977000208],
              [-174.647941872999894, 48.574182236000127],
              [-174.682585827999844, 48.564580977000219],
              [-174.717298257999857, 48.555176410000172],
              [-174.723105827999888, 48.553602977000139],
              [-174.728973479999894, 48.552050310000197],
              [-174.763749827999874, 48.542847977000179],
              [-174.804519827999798, 48.53231197700029],
              [-174.839418646999974, 48.523510025000292],
              [-174.845405827999826, 48.521999977000064],
              [-174.851451815999866, 48.520512108000162],
              [-174.886410827999867, 48.511908977000189],
              [-174.921426122999975, 48.503504285000247],
              [-174.927530827999874, 48.502038977000154],
              [-174.933693476999878, 48.500597488000153],
              [-174.968760827999859, 48.492394977000345],
              [-175.003879086999888, 48.484392510000305],
              [-175.010099827999881, 48.482974977000197],
              [-175.016378698999915, 48.481582264000167],
              [-175.051549827999878, 48.473780977000104],
              [-175.093099827999851, 48.464811977000181],
              [-175.128360768999869, 48.457408983000164],
              [-175.134752827999847, 48.456066977000148],
              [-175.176505827999847, 48.447549977000278],
              [-175.182886314999934, 48.446459374000199],
              [-175.216399827999851, 48.440730977000271],
              [-175.251692687999849, 48.433529336000277],
              [-175.258138827999858, 48.432213977000231],
              [-175.264640462999836, 48.430925492000313],
              [-175.299974827999876, 48.423922977000075],
              [-175.341902827999888, 48.415858977000255],
              [-175.377311890999891, 48.409257802000184],
              [-175.383924827999948, 48.408024977000252],
              [-175.390591874999842, 48.406820890000176],
              [-175.426033827999902, 48.400419977000183],
              [-175.461506716999878, 48.394217224000272],
              [-175.468227827999897, 48.39304197700028],
              [-175.475002252999872, 48.391896695000185],
              [-175.510502827999829, 48.385894977000078],
              [-175.546032703999884, 48.38009048400005],
              [-175.552860828999883, 48.378974977000041],
              [-175.59529782899989, 48.372288977000153],
              [-175.60223113299989, 48.371236129000295],
              [-175.637805828999831, 48.36583397700025],
              [-175.680388828999952, 48.359608977000164],
              [-175.716004432999824, 48.354603103000215],
              [-175.723041828999897, 48.353613977000066],
              [-175.734916828999843, 48.353116977000184],
              [-175.741847003999936, 48.352063768000164],
              [-175.777410828999876, 48.346658977000175],
              [-175.819977828999896, 48.340433977000259],
              [-175.855579141999925, 48.335430621000171],
              [-175.862613828999855, 48.334441977000267],
              [-175.905316828999872, 48.328677977000098],
              [-175.948083828999842, 48.323149977000298],
              [-175.990913828999851, 48.317852977000257],
              [-176.033799828999889, 48.312788977000139],
              [-176.041085988999953, 48.311970016000146],
              [-176.076744828999836, 48.307961977000048],
              [-176.112409928999909, 48.304148300000236],
              [-176.119744828999899, 48.303363977000288],
              [-176.162791828999872, 48.298999977000165],
              [-176.170222951999818, 48.298288367000282],
              [-176.205888828999861, 48.294872977000296],
              [-176.249033828999842, 48.290980977000345],
              [-176.292219828999833, 48.287319977000095],
              [-176.335444828999954, 48.283897977000265],
              [-176.378708828999919, 48.280708977000245],
              [-176.422005828999858, 48.27775597700014],
              [-176.465335828999855, 48.275036977000241],
              [-176.508697828999857, 48.272555977000309],
              [-176.552083828999969, 48.270308977000184],
              [-176.587652275999943, 48.268662919000235],
              [-176.595494829999836, 48.268299977000254],
              [-176.63892782999983, 48.266524977000245],
              [-176.682380829999801, 48.264988977000201],
              [-176.690351087999886, 48.264750238000261],
              [-176.725847829999879, 48.263686977000305],
              [-176.743584806999905, 48.263228417000164],
              [-176.747718828499785, 48.263021777500171],
              [-176.752066829999961, 48.259647977000043],
              [-176.791013829999969, 48.246708977000083],
              [-176.830110829999938, 48.233980977000158],
              [-176.869352829999826, 48.221469977000083],
              [-176.903363696999889, 48.210852915000032],
              [-176.908738829999919, 48.209174977000316],
              [-176.914174973999934, 48.207513406000032],
              [-176.948260829999896, 48.197094977000177],
              [-176.987924829999884, 48.185230977000174],
              [-177.027722829999874, 48.173586977000241],
              [-177.067652829999929, 48.162158977000217],
              [-177.102028150999843, 48.152542674000301],
              [-177.107710829999832, 48.150952977000259],
              [-177.147897829999948, 48.139963977000207],
              [-177.153700933999943, 48.138414122000142],
              [-177.188208829999809, 48.129197977000274],
              [-177.222778682999945, 48.120182096000065],
              [-177.228641829999845, 48.118652977000295],
              [-177.269194829999861, 48.108327977000272],
              [-177.30986382999987, 48.098224977000257],
              [-177.315903755999841, 48.096762238000338],
              [-177.3506478299999, 48.088347977000183],
              [-177.391541829999881, 48.078694977000339],
              [-177.426389200999864, 48.070679818000144],
              [-177.432544829999898, 48.069263977000162],
              [-177.438758024999828, 48.06787280500015],
              [-177.473655829999899, 48.060058977000267],
              [-177.514869829999896, 48.051080977000083],
              [-177.549856960999904, 48.043668420000245],
              [-177.556183829999895, 48.042327977000241],
              [-177.597597829999813, 48.033802977000107],
              [-177.639105829999892, 48.025502977000315],
              [-177.645599966999868, 48.024242955000148],
              [-177.680708830999919, 48.017430977000345],
              [-177.71585056499984, 48.010820881000143],
              [-177.722399830999848, 48.009588977000192],
              [-177.764180830999948, 48.001974977000259],
              [-177.806047830999972, 47.994591977000141],
              [-177.812759152999973, 47.99344721000017],
              [-177.847994830999852, 47.987436977000186],
              [-177.890022830999868, 47.980511977000276],
              [-177.925309979999923, 47.974901064000278],
              [-177.93212783099986, 47.973816977000183],
              [-177.93899854899982, 47.972764567000127],
              [-177.974308830999831, 47.967355977000295],
              [-178.009638178999836, 47.962145876000193],
              [-178.016560830999822, 47.961124977000225],
              [-178.05888083099984, 47.955124977000082],
              [-178.065906220999892, 47.954169342000171],
              [-178.101269830999883, 47.949358977000145],
              [-178.143722830999877, 47.943822977000195],
              [-178.186235830999891, 47.938522977000275],
              [-178.221632784999969, 47.934307569000168],
              [-178.228808830999867, 47.933452977000172],
              [-178.27143583099982, 47.928616977000274],
              [-178.31411683099995, 47.924016977000292],
              [-178.356849830999835, 47.91964997700012],
              [-178.364220930999835, 47.918938384000285],
              [-178.399630830999826, 47.915519977000201],
              [-178.442455830999876, 47.91162297700015],
              [-178.485324830999843, 47.907958977000192],
              [-178.528233830999824, 47.904530977000263],
              [-178.563619820999889, 47.901900759000057],
              [-178.571177830999972, 47.901338977000307],
              [-178.578781862999847, 47.900816190000114],
              [-178.614158830999969, 47.898383977000037],
              [-178.649523205999827, 47.896147702000178],
              [-178.65717283099994, 47.895663977000254],
              [-178.700213830999928, 47.893177977000221],
              [-178.707952276999833, 47.892774255000234],
              [-178.743283831999918, 47.890930977000153],
              [-178.786377831999943, 47.888919977000171],
              [-178.829491831999832, 47.887144977000048],
              [-178.8726248319999, 47.885605977000068],
              [-178.907864105999977, 47.884541857000158],
              [-178.915774831999926, 47.884302977000289],
              [-178.923726680999891, 47.884106580000093],
              [-178.958935831999895, 47.883236977000195],
              [-179.002108831999891, 47.882408977000239],
              [-179.037257228999806, 47.881924682000033],
              [-179.04529183199989, 47.881813977000206],
              [-179.053366550999897, 47.881747600000153],
              [-179.088477831999882, 47.881458977000136],
              [-179.123551676999966, 47.881363961000091],
              [-179.131666831999866, 47.881341977000261],
              [-179.139820540999835, 47.881364065000298],
              [-179.174855831999849, 47.881458977000136],
              [-179.209848791999889, 47.881746628000258],
              [-179.218041831999926, 47.881813977000206],
              [-179.2262738579999, 47.881927402000088],
              [-179.26122483199984, 47.882408977000239],
              [-179.304397831999836, 47.883236977000195],
              [-179.339252351999875, 47.884097821000182],
              [-179.347558831999891, 47.884302977000289],
              [-179.355902292999843, 47.88455492400027],
              [-179.390708831999831, 47.885605977000068],
              [-179.433841831999899, 47.887144977000048],
              [-179.476955831999874, 47.888919977000171],
              [-179.520049831999899, 47.890930977000153],
              [-179.554634235999885, 47.89273527600011],
              [-179.563119831999899, 47.893177977000221],
              [-179.606160831999972, 47.895663977000254],
              [-179.614714150999873, 47.896204848000309],
              [-179.649174831999943, 47.898383977000037],
              [-179.683569338999945, 47.900748644000032],
              [-179.692155831999941, 47.901338977000307],
              [-179.700774409999838, 47.90197959000028],
              [-179.735099831999889, 47.904530977000263],
              [-179.778008831999983, 47.907958977000192],
              [-179.820877832999855, 47.91162297700015],
              [-179.86370283299982, 47.915519977000201],
              [-179.897739744999825, 47.918805838000196],
              [-179.906483832999868, 47.91964997700012],
              [-179.949216832999866, 47.924016977000292],
              [-179.957172143999884, 47.924874371000215],
              [-179.985253665999863, 47.927900893000356],
              [-179.991897832999825, 47.928616977000274],
              [-179.999999832999976, 47.929489977000173],
              [-179.999999832999976, 47.9999999770003],
              [-179.999999832999976, 48.952381978000233],
              [-179.999999832999976, 49.952381979000279],
              [-179.999999832999976, 50.952381979000279],
              [-179.999999832999976, 51.952381980000155],
              [-179.999999832999976, 52.95238198100003],
              [-179.999999832999976, 53.952381982000304],
              [-179.999999832999976, 54.95238198300018],
              [-179.999999832999976, 55.349887984000304],
              [-179.966283797999978, 55.348340990000167],
              [-179.915527342999866, 55.346014025000102],
              [-179.864812849999822, 55.343416216000207],
              [-179.81415176299987, 55.340543749000119],
              [-179.763540266999826, 55.337396624000235],
              [-179.712987898999899, 55.333982470000194],
              [-179.662502287999899, 55.33029365800013],
              [-179.612079619999861, 55.326334002000067],
              [-179.561733244999829, 55.322103503000278],
              [-179.511463164999981, 55.317602160000035],
              [-179.461277006999893, 55.312829974000067],
              [-179.411176680999858, 55.307790759000284],
              [-179.361171721999852, 55.30247688500026],
              [-179.311260222999806, 55.296899798000254],
              [-179.261451720999872, 55.291051867000192],
              [-179.21175002999982, 55.284933092000074],
              [-179.162157057999849, 55.278554919000214],
              [-179.112680434999845, 55.271902087000228],
              [-179.063323973999815, 55.264989855000294],
              [-179.014093397999829, 55.257810595000308],
              [-178.964994429999905, 55.250364306000336],
              [-178.916027068999824, 55.24265480300005],
              [-178.867197035999851, 55.234685900000159],
              [-178.818513869999947, 55.226453783000181],
              [-178.79474639799983, 55.222860339000249],
              [-178.744396208999859, 55.226549151000086],
              [-178.693979262999932, 55.229963305000126],
              [-178.643507002999883, 55.233110430000181],
              [-178.592979430999861, 55.235982897000099],
              [-178.542402266999829, 55.23857689100015],
              [-178.491783141999889, 55.240903857000148],
              [-178.441123961999892, 55.242956164000191],
              [-178.390432356999895, 55.244737627000063],
              [-178.339710234999956, 55.246244433000243],
              [-178.288963316999912, 55.247476580000125],
              [-178.23819923299996, 55.248434069000211],
              [-178.187419890999792, 55.249116900000331],
              [-178.136627196999882, 55.249528887000224],
              [-178.085832594999886, 55.249666216000094],
              [-178.041624068999937, 55.250783923000142],
              [-177.993963240999875, 55.260831835000147],
              [-177.946126936999889, 55.270624163000207],
              [-177.898120879999851, 55.280160906000106],
              [-177.849948881999893, 55.28943443500026],
              [-177.801612852999824, 55.298452380000299],
              [-177.753122328999865, 55.307210925000106],
              [-177.70447731, 55.315706255000123],
              [-177.655683516999829, 55.3239383720001],
              [-177.60674667299989, 55.3319110890003],
              [-177.557672499999882, 55.339624407000258],
              [-177.508460997999862, 55.347066882000092],
              [-177.459119795999868, 55.35424995700015],
              [-177.409652708999857, 55.361166003000221],
              [-177.360065459999845, 55.367818835000207],
              [-177.310363768999849, 55.374200823000308],
              [-177.260549544999975, 55.380315783000185],
              [-177.210630415999844, 55.386167529000261],
              [-177.160608290999818, 55.391748431000224],
              [-177.110488890999846, 55.397058489000074],
              [-177.060274123999847, 55.402101519000269],
              [-177.009977339999836, 55.406877520000251],
              [-176.959594725999921, 55.411378863000152],
              [-176.909132002999854, 55.415609362000282],
              [-176.858598708999892, 55.419569018000175],
              [-176.826061247999888, 55.42286110200024],
              [-176.776510237999844, 55.429777148000142],
              [-176.726839064999837, 55.436429980000128],
              [-176.677049635999964, 55.442815783000299],
              [-176.627149580999827, 55.448934557000143],
              [-176.577144621999878, 55.454782488000205],
              [-176.53841590799982, 55.462823870000307],
              [-176.491771696999848, 55.474645617000249],
              [-176.444919585999941, 55.486219408000181],
              [-176.397863386999802, 55.497541430000297],
              [-176.350606917999812, 55.508607867000308],
              [-176.303159712999872, 55.519430163000095],
              [-176.255521773999931, 55.529996874000176],
              [-176.207698820999866, 55.540304186000185],
              [-176.159694670999841, 55.550359728000274],
              [-176.111511229999877, 55.560159686000247],
              [-176.06315803499993, 55.569700243000284],
              [-176.014635084999867, 55.578985217000252],
              [-175.965950011999979, 55.588006976000202],
              [-175.917104719999884, 55.596769335000033],
              [-175.868104933999888, 55.605272295000134],
              [-175.818958281999841, 55.613515856000163],
              [-175.82358932399984, 55.625341418000232],
              [-175.84709167399987, 55.651132586000244],
              [-175.870227812999957, 55.67703438000018],
              [-175.892993925999889, 55.703046801000141],
              [-175.91538810699987, 55.729162218000283],
              [-175.937410353999894, 55.755380633000243],
              [-175.959053038999883, 55.781705859000226],
              [-175.980318068999878, 55.808134081000219],
              [-176.001201628999837, 55.83465767100023],
              [-176.021701811999975, 55.861284258000239],
              [-176.041818617999894, 55.888002398000026],
              [-176.061546324999881, 55.914815905000182],
              [-176.080884932999822, 55.941724780000072],
              [-176.099830626999847, 55.968721392000191],
              [-176.118383406999982, 55.995805743000176],
              [-176.136541365999932, 56.022981646000289],
              [-176.15429878199987, 56.050237658000242],
              [-176.171657561999865, 56.077577593000058],
              [-176.188613890999875, 56.104997637000224],
              [-176.205165861999916, 56.13250160400014],
              [-176.221311568999823, 56.160078051000198],
              [-176.237049101999872, 56.187730791000035],
              [-176.252374647999858, 56.215456011000242],
              [-176.267292021999822, 56.243257525000161],
              [-176.281791685999963, 56.271123888000034],
              [-176.295877455999857, 56.299058916000149],
              [-176.30954360899986, 56.327058794000152],
              [-176.322792052999858, 56.355123522000213],
              [-176.335617064999894, 56.383249285000034],
              [-176.348016737999927, 56.41143608300024],
              [-176.359994887999875, 56.439676287000225],
              [-176.371543883999863, 56.467973711000127],
              [-176.382663725999947, 56.496324541000035],
              [-176.393352507999879, 56.524728777000291],
              [-176.403610228999867, 56.553182604000256],
              [-176.41343307399984, 56.581682207000142],
              [-176.422821043999903, 56.610227587000281],
              [-176.431772230999854, 56.638814928000158],
              [-176.440282820999897, 56.667444231000275],
              [-176.448352812999872, 56.696115496000118],
              [-176.455980299999823, 56.724821093000173],
              [-176.46316337499988, 56.753561022000213],
              [-176.469902037999844, 56.782335284000283],
              [-176.476194380999914, 56.811136248000139],
              [-176.485416411999921, 56.840841296000178],
              [-176.49264335599986, 56.869577410000147],
              [-176.499422072999863, 56.898351672000103],
              [-176.505754469999857, 56.92715263600013],
              [-176.51163864099982, 56.955984118000174],
              [-176.517068861999888, 56.98484230300005],
              [-176.522047041999826, 57.0137233760002],
              [-176.526571272999888, 57.04262733700017],
              [-176.530641554999875, 57.071550372000047],
              [-176.534252165999845, 57.100492480000185],
              [-176.537405012999841, 57.129449847000103],
              [-176.540102003999976, 57.158422472000041],
              [-176.542335509999845, 57.187406542000303],
              [-176.544107436999838, 57.216398241000206],
              [-176.545415877999829, 57.245397570000193],
              [-176.54626083299982, 57.274404528000218],
              [-176.5466384879999, 57.303407671000173],
              [-176.546548842999954, 57.332418444000041],
              [-176.545993803999949, 57.361425402000236],
              [-176.544971464999804, 57.390424731000053],
              [-176.543478011999895, 57.419424059000164],
              [-176.541511534999898, 57.44841194400027],
              [-176.539073943999909, 57.477388384000051],
              [-176.53616332999988, 57.506353381000054],
              [-176.596284865999934, 57.521272662000172],
              [-176.640474318999821, 57.53789711200028],
              [-176.684429167999923, 57.554716113000211],
              [-176.728153227999854, 57.571722033000242],
              [-176.771640776999931, 57.588918688000149],
              [-176.814887999999826, 57.606302264000135],
              [-176.857894896999852, 57.623872759000051],
              [-176.900655745999842, 57.641630175000273],
              [-176.943166731999838, 57.65957451100013],
              [-176.985427855999859, 57.677698138000153],
              [-177.027433394999889, 57.696004870000195],
              [-177.0691833489999, 57.714494707000142],
              [-177.110673903999867, 57.733163836000188],
              [-177.151903151999875, 57.752016070000252],
              [-177.192863463999828, 57.77103996500017],
              [-177.233558653999893, 57.790246966000154],
              [-177.273979185999849, 57.809621813000149],
              [-177.314126967999812, 57.829179766000209],
              [-177.353996275999833, 57.848905566000212],
              [-177.393589018999791, 57.868803027000297],
              [-177.432893751999814, 57.888872149000292],
              [-177.471916197999946, 57.909116747000041],
              [-177.51064872699996, 57.929521563000094],
              [-177.549091338999972, 57.950094225000157],
              [-177.587236403999867, 57.970838549000234],
              [-177.625085829999847, 57.99174309000017],
              [-177.662635802999887, 58.012807848000307],
              [-177.699880598999897, 58.034040453000216],
              [-177.736822127999886, 58.055433276000201],
              [-177.77345275799982, 58.07698250000027],
              [-177.809772490999848, 58.098688128000106],
              [-177.845777510999881, 58.120553973000142],
              [-177.881465910999822, 58.142572405000351],
              [-177.916835783999886, 58.164747240000167],
              [-177.951879500999866, 58.187070849000293],
              [-177.986598967999839, 58.209547045000136],
              [-178.020990370999954, 58.232172015000231],
              [-178.055051802999827, 58.254949572000214],
              [-178.088777541999832, 58.277868273000308],
              [-178.122165678999892, 58.3009319330003],
              [-178.155216215999872, 58.324144366000212],
              [-178.187925337999872, 58.347494128000221],
              [-178.220289229999821, 58.370985033000068],
              [-178.252302168999847, 58.394617083000185],
              [-178.283966063999884, 58.418382647000101],
              [-178.315277098999843, 58.442289355000185],
              [-178.34623336699994, 58.466329577000124],
              [-178.376829146999853, 58.490499499000123],
              [-178.40706252999982, 58.514806750000218],
              [-178.436935423999898, 58.539239886000303],
              [-178.466438292999811, 58.563802721000059],
              [-178.495574950999895, 58.588491442000304],
              [-178.524335860999798, 58.613302233000297],
              [-178.552724837999847, 58.638238909000165],
              [-178.580736159999901, 58.663301470000192],
              [-178.608366011999834, 58.688478472000043],
              [-178.635612486999833, 58.713777544000038],
              [-178.662473677999856, 58.739191058000074],
              [-178.688949583999886, 58.764719012000171],
              [-178.71503639199986, 58.790365221000229],
              [-178.740724562999901, 58.816122057000257],
              [-178.766021727999828, 58.84198570500007],
              [-178.790918349999828, 58.867959978000215],
              [-178.81705284099985, 58.890382769000212],
              [-178.846117018999877, 58.915178301000083],
              [-178.874799727999886, 58.94009971800017],
              [-178.903104781999815, 58.965143206000164],
              [-178.931026457999934, 58.99030494900029],
              [-178.958562849999851, 59.015588763000267],
              [-178.985713957999877, 59.040987017000305],
              [-179.012472151999987, 59.066499712000279],
              [-179.038839339999896, 59.092126849000351],
              [-179.064807890999958, 59.117868426000257],
              [-179.090379713999852, 59.143716814000243],
              [-179.115549086999863, 59.169675829000028],
              [-179.140316008999889, 59.195741656000337],
              [-179.164676665999963, 59.221910479000258],
              [-179.188629149999826, 59.248186114000305],
              [-179.212171553999866, 59.274560931000224],
              [-179.235300063999858, 59.301034930000185],
              [-179.258010863999942, 59.327608111000188],
              [-179.280305861999892, 59.354276659000163],
              [-179.302177428999869, 59.381040575000213],
              [-179.323623656999956, 59.407896044000267],
              [-179.344646452999882, 59.434843066000212],
              [-179.365242003999896, 59.461877825000158],
              [-179.385404585999908, 59.489000323000255],
              [-179.40513229299998, 59.516206744000215],
              [-179.424427031999841, 59.543497088000322],
              [-179.443283079999901, 59.570871355000122],
              [-179.461700438999827, 59.598321917000305],
              [-179.479671477999858, 59.625852587000054],
              [-179.497200011999865, 59.653459551000196],
              [-179.514280318999823, 59.681138995000197],
              [-179.530910490999844, 59.708890917000076],
              [-179.547090529999849, 59.736711504000255],
              [-179.562816618999847, 59.764600756000164],
              [-179.57444190899983, 59.790105822000157],
              [-179.59162139799983, 59.81778145100003],
              [-179.608348845999899, 59.84552955900017],
              [-179.624624251999876, 59.873346331000107],
              [-179.64044380099989, 59.901227953000273],
              [-179.655805586999946, 59.929182055000183],
              [-179.670707701999987, 59.957197192000137],
              [-179.685144423999873, 59.985273363000147],
              [-179.699119566999883, 60.013410571000122],
              [-179.71262740999984, 60.041608813000209],
              [-179.725664137999985, 60.069860461000246],
              [-179.738229750999864, 60.098169329000029],
              [-179.750324248999874, 60.126527789000193],
              [-179.753301243999886, 60.133807059000162],
              [-179.761943816999832, 60.154939654000259],
              [-179.773086547999839, 60.183397294000144],
              [-179.777771194999843, 60.195923216333483],
              [-179.759652308999847, 60.206035236000048],
              [-179.750477634999953, 60.211071067000262],
              [-179.728995938999873, 60.223257424000224],
              [-179.675003051999937, 60.253887177000252],
              [-179.39389038099992, 60.412223816000221],
              [-179.112228393999828, 60.569721222000226],
              [-178.829162597999954, 60.726387024000246],
              [-178.544998168999854, 60.882499695000206],
              [-178.259994506999845, 61.038055420000262],
              [-177.973892211999811, 61.192501068000183],
              [-177.686386107999823, 61.34638977100019],
              [-177.398056029999822, 61.499721527000304],
              [-177.108612060999889, 61.652221680000366],
              [-176.817779540999879, 61.803890228000228],
              [-176.52610778799982, 61.955001831000232],
              [-176.233062743999909, 62.105278015000181],
              [-175.938613891999836, 62.25472259500026],
              [-175.643325805999865, 62.403610229000265],
              [-175.346664428999873, 62.551666260000275],
              [-175.048889159999817, 62.698890686000311],
              [-174.749725341999891, 62.845554352000306],
              [-174.449447631999817, 62.991390228000171],
              [-174.147781371999969, 63.136390686000254],
              [-173.845001220999876, 63.28055572500017],
              [-173.540832519999896, 63.424167633000309],
              [-173.235275268999885, 63.56694412200028],
              [-172.92832946799993, 63.709167480000247],
              [-172.636947631999959, 63.850276947000282],
              [-172.310836791999833, 63.990833282000096],
              [-171.999999999999886, 64.130554199000187],
              [-171.627777099999975, 64.305557251000209],
              [-171.253890990999849, 64.47944641100031],
              [-170.878616332999826, 64.652221680000252],
              [-170.501663207999883, 64.823890686000141],
              [-170.12306213399998, 64.994720459000291],
              [-169.742782592999845, 65.164169312000183],
              [-169.360549926999852, 65.332778931000121],
              [-168.976943969999923, 65.500000000000284],
              [-168.972209003666592, 72.783530292333296],
              [-168.955244655999962, 72.7887361880002],
              [-168.898842754999919, 72.805676720000179],
              [-168.88658565599988, 72.809358188000147],
              [-168.826843267999834, 72.826930238000102],
              [-168.817160821999863, 72.829778000000204],
              [-168.807301212999846, 72.832617749000235],
              [-168.746977655999956, 72.84999118800016],
              [-168.686076657999877, 72.867172179000193],
              [-168.67604182199986, 72.870003],
              [-168.604355821999889, 72.889803000000143],
              [-168.593969457999918, 72.892612427000188],
              [-168.531924821999894, 72.909395000000302],
              [-168.458755821999972, 72.928773],
              [-168.384855821999849, 72.947937000000138],
              [-168.310227821999888, 72.966884000000221],
              [-168.234880821999866, 72.985612000000231],
              [-168.158816821999864, 73.004120000000228],
              [-168.093466011999936, 73.019683081000153],
              [-168.082044821999858, 73.022403000000224],
              [-168.070454232999822, 73.025104808000094],
              [-168.004572821999972, 73.040462000000161],
              [-167.926402820999868, 73.058292000000165],
              [-167.847544820999957, 73.075892000000124],
              [-167.768005820999917, 73.093262000000266],
              [-167.687791820999792, 73.110398000000203],
              [-167.606910820999929, 73.127298000000167],
              [-167.525369820999884, 73.143962000000158],
              [-167.4431748209999, 73.160387000000242],
              [-167.36033582099995, 73.176567000000148],
              [-167.276858820999848, 73.192509000000257],
              [-167.20598606799993, 73.205731177000303],
              [-167.192752820999857, 73.208200000000204],
              [-167.179361710999871, 73.210641529000156],
              [-167.108024820999816, 73.223648],
              [-167.022683820999873, 73.238848],
              [-166.936738820999864, 73.253795000000252],
              [-166.864055383999869, 73.266136092000181],
              [-166.850197819999835, 73.268489000000159],
              [-166.845126436999891, 73.269329541000161],
              [-166.818660819999849, 73.273716],
              [-166.803863849999971, 73.278072303000215],
              [-166.801466819999973, 73.278778000000216],
              [-166.791486423999828, 73.281655403000173],
              [-166.73039981999986, 73.299267000000157],
              [-166.668707045999923, 73.316684015000192],
              [-166.658544819999946, 73.319553000000155],
              [-166.648201097999845, 73.322412633000226],
              [-166.585908819999958, 73.339634],
              [-166.512497819999879, 73.359506],
              [-166.438310819999828, 73.379167000000223],
              [-166.363360819999968, 73.398617000000286],
              [-166.298713283999973, 73.415039529000296],
              [-166.287649819999842, 73.41785],
              [-166.276408306999912, 73.420645824000246],
              [-166.211185819999855, 73.436867000000177],
              [-166.145393257999984, 73.452884184000311],
              [-166.133974819999878, 73.45566400000024],
              [-166.12237952999979, 73.458427355000197],
              [-166.056019819999818, 73.474242000000174],
              [-165.977330819999935, 73.492595000000279],
              [-165.897913819999957, 73.510723],
              [-165.817772818999828, 73.528623000000152],
              [-165.736919818999866, 73.546292001000211],
              [-165.65535581899988, 73.563731001000235],
              [-165.573091818999984, 73.580937001000279],
              [-165.490133818999936, 73.59790600100024],
              [-165.406491818999967, 73.61463700100029],
              [-165.33531078799993, 73.628557998000247],
              [-165.322169818999868, 73.63112800100015],
              [-165.237177818999925, 73.647375001000285],
              [-165.223704843999911, 73.649892640000303],
              [-165.151522818999922, 73.663381001000175],
              [-165.065213818999894, 73.679142001000173],
              [-164.992060182999893, 73.692191419000267],
              [-164.978260818999985, 73.694653001000177],
              [-164.964299912999849, 73.697085891000029],
              [-164.890669818999839, 73.709917001000122],
              [-164.816569293999834, 73.722525227000233],
              [-164.802447818999923, 73.724928001000308],
              [-164.788167922999833, 73.727300348000028],
              [-164.713608817999898, 73.73968700100022],
              [-164.638593719999818, 73.751848347000134],
              [-164.624155817999821, 73.754189001000157],
              [-164.609562367999899, 73.756497946000195],
              [-164.534102817999894, 73.768437001000279],
              [-164.458204024999958, 73.780149162000271],
              [-164.443455817999876, 73.782425001000149],
              [-164.352227817999903, 73.79615300100005],
              [-164.33717494999982, 73.798361175000309],
              [-164.260424817999876, 73.809620001000212],
              [-164.168058817999821, 73.822823001000302],
              [-164.075135817999836, 73.835759001000042],
              [-163.981672817999964, 73.848431001000279],
              [-163.943976817999896, 73.853405001000283],
              [-163.923178354999976, 73.859375901000305],
              [-163.919774817999922, 73.860353001000306],
              [-163.909404879999869, 73.863268293000147],
              [-163.846555817999928, 73.880937001000291],
              [-163.783072833999825, 73.898408068000151],
              [-163.772513817999965, 73.901314001000173],
              [-163.761766315999949, 73.904210160000275],
              [-163.69765281699992, 73.92148700100023],
              [-163.632913198999944, 73.938565257000164],
              [-163.62197781699993, 73.941450001000135],
              [-163.610854956999844, 73.944322987000191],
              [-163.545491816999885, 73.961206001000164],
              [-163.468205816999927, 73.980748001000222],
              [-163.390124816999815, 74.00007300100026],
              [-163.311249816999918, 74.019184001000042],
              [-163.23159181699998, 74.038073001000271],
              [-163.151155816999875, 74.056742001000202],
              [-163.069949816999866, 74.075187001000302],
              [-162.987977816999944, 74.093406001000176],
              [-162.905247816999946, 74.111398001000055],
              [-162.821766816999883, 74.129159001000176],
              [-162.750491854999979, 74.143993671000146],
              [-162.737541817, 74.146689001000254],
              [-162.724414336999871, 74.149361378000265],
              [-162.652583816999822, 74.163984001000188],
              [-162.566894815999859, 74.181042001000094],
              [-162.480485815999828, 74.197862001000203],
              [-162.393363815999862, 74.214442001000123],
              [-162.319365330999858, 74.228206183000168],
              [-162.305538815999824, 74.230778001000147],
              [-162.291540145999846, 74.233322754000312],
              [-162.217016815999841, 74.246870001000218],
              [-162.127805815999892, 74.262717001000283],
              [-162.037916815999864, 74.278317001000175],
              [-161.961864427999927, 74.291205713000068],
              [-161.94735881599982, 74.293664001000252],
              [-161.887684937999865, 74.303187801000263],
              [-161.578599815999894, 74.352517001000251],
              [-161.484827814999846, 74.366587001000198],
              [-161.390441814999889, 74.380395001000295],
              [-161.31109259899992, 74.391709004000177],
              [-161.295452814999862, 74.393939001000035],
              [-161.279656550999931, 74.396133781000287],
              [-161.199866814999922, 74.407220001000212],
              [-161.103699814999857, 74.42023400100021],
              [-161.00695881499982, 74.432981001000257],
              [-160.909655814999923, 74.445459001000245],
              [-160.828202100999818, 74.455618241000252],
              [-160.811799814999915, 74.457664001000239],
              [-160.795250832999869, 74.459671192000144],
              [-160.713405814999902, 74.469598001000179],
              [-160.614480814999894, 74.481256001000247],
              [-160.515035814999862, 74.492642001000149],
              [-160.415083813999985, 74.503748001000133],
              [-160.331755316999846, 74.512729740000168],
              [-160.314635813999843, 74.51457500100031],
              [-160.213705813999951, 74.525125001000163],
              [-160.196313080999886, 74.526886006000268],
              [-160.112302813999833, 74.535392001000218],
              [-160.027965241999851, 74.543657355000164],
              [-160.010438813999826, 74.545375001000195],
              [-159.908124813999933, 74.555078001000311],
              [-159.890337189999968, 74.55670775900029],
              [-159.805377813999883, 74.564492001000247],
              [-159.702205813999853, 74.573623001000271],
              [-159.616660350999865, 74.580924253000262],
              [-159.598619813999846, 74.582464001000119],
              [-159.580456126999962, 74.583958020000182],
              [-159.494635813999878, 74.591017001000296],
              [-159.408551221999858, 74.597830757000054],
              [-159.39026681299984, 74.599278001000073],
              [-159.285522812999858, 74.607250001000295],
              [-159.267003459999842, 74.608603409000182],
              [-159.180419812999844, 74.614931002000162],
              [-159.074966812999918, 74.622320002000095],
              [-158.969180812999866, 74.629412002000151],
              [-158.863072812999832, 74.636212002000207],
              [-158.775618247999887, 74.641555411000297],
              [-158.756655812999895, 74.642714002000162],
              [-158.737587917999889, 74.643822903000171],
              [-158.649941812999913, 74.648920002000239],
              [-158.562119258999871, 74.653769482000257],
              [-158.542949812999893, 74.654828002000215],
              [-158.435688812999871, 74.660439002000146],
              [-158.328172811999849, 74.665750002000095],
              [-158.308710241999876, 74.666655254000261],
              [-158.220416811999968, 74.670762002000231],
              [-158.112433811999864, 74.675473002000274],
              [-158.00423581199982, 74.679884002000222],
              [-157.895841811999929, 74.683992002000252],
              [-157.807078550999961, 74.687104982000278],
              [-157.787260811999914, 74.687800002000245],
              [-157.678508811999848, 74.691303002000154],
              [-157.569599811999865, 74.69450300200026],
              [-157.460549811999954, 74.697400002000165],
              [-157.440417377999978, 74.697878511000226],
              [-157.351369811999945, 74.699995002000094],
              [-157.242072810999929, 74.702284002000283],
              [-157.132677810999866, 74.704267002000222],
              [-157.023197810999903, 74.705948002000071],
              [-156.913644810999841, 74.707323002000123],
              [-156.804033810999869, 74.708392002000153],
              [-156.69438081099986, 74.70915600200027],
              [-156.605279782999844, 74.70952805800016],
              [-156.584697810999984, 74.709614002000194],
              [-156.564061999999808, 74.709642783000163],
              [-156.474999810999833, 74.709767002000035],
              [-156.385988951999792, 74.709642854000265],
              [-156.365302810999822, 74.709614002000194],
              [-156.344568284999951, 74.709527421000189],
              [-156.255619810999946, 74.70915600200027],
              [-156.145966809999862, 74.708392002000153],
              [-156.03635580999989, 74.707323002000123],
              [-155.926802809999828, 74.705948002000071],
              [-155.817322809999865, 74.704267002000222],
              [-155.707927809999887, 74.702284002000283],
              [-155.598630809999889, 74.699995002000094],
              [-155.510444578999881, 74.697899003000146],
              [-155.489449809999854, 74.697400002000165],
              [-155.380399809999943, 74.69450300200026],
              [-155.271491809999958, 74.691303002000154],
              [-155.162738809999922, 74.687800002000245],
              [-155.141655291999854, 74.687060583000061],
              [-155.054158808999887, 74.683992002000252],
              [-154.945763808999885, 74.679884002000222],
              [-154.837566808999867, 74.675473002000274],
              [-154.729583808999934, 74.670762002000231],
              [-154.642955846999911, 74.666732719000152],
              [-154.621827808999882, 74.665750002000095],
              [-154.514310808999966, 74.660439002000146],
              [-154.407049808999915, 74.654828002000215],
              [-154.385916620999893, 74.653661035000255],
              [-154.300058808999921, 74.648920002000239],
              [-154.214475010999848, 74.643942838000214],
              [-154.193344808999825, 74.642714002000162],
              [-154.172221021999889, 74.641423354000267],
              [-154.086927808999889, 74.636212002000207],
              [-153.98081980799995, 74.629412002000151],
              [-153.875033807999898, 74.622320002000095],
              [-153.769580807999972, 74.614931002000162],
              [-153.685549703999953, 74.608789950000073],
              [-153.664477807999873, 74.607250001000295],
              [-153.559733807999891, 74.599278001000073],
              [-153.53870323799984, 74.597613409000189],
              [-153.455363807999845, 74.591017001000296],
              [-153.372386665999898, 74.584191814000178],
              [-153.351380807999959, 74.582464001000119],
              [-153.330401527999896, 74.5806734330003],
              [-153.247794807999952, 74.573623001000271],
              [-153.144622807999923, 74.564492001000247],
              [-153.06279114599991, 74.556994403000203],
              [-153.041874807999903, 74.555078001000311],
              [-152.939560806999879, 74.545375001000195],
              [-152.918716268999873, 74.543332149000094],
              [-152.837697806999898, 74.535392001000218],
              [-152.757099570999884, 74.527231473000199],
              [-152.736294806999865, 74.525125001000163],
              [-152.635363806999891, 74.51457500100031],
              [-152.614645674999849, 74.512341832000232],
              [-152.534916806999917, 74.503748001000133],
              [-152.43496380699986, 74.492642001000149],
              [-152.335519806999827, 74.481256001000247],
              [-152.236594806999818, 74.469598001000179],
              [-152.158715054999846, 74.460152227000265],
              [-152.138199806999893, 74.457664001000239],
              [-152.117740895999901, 74.455112256000064],
              [-152.040344806999883, 74.445459001000245],
              [-151.943041806999901, 74.432981001000257],
              [-151.846299805999962, 74.42023400100021],
              [-151.75013380599998, 74.407220001000212],
              [-151.674757838999966, 74.396747043000175],
              [-151.654547805999954, 74.393939001000035],
              [-151.634406175999828, 74.391067108000186],
              [-151.559558805999842, 74.380395001000295],
              [-151.46517280599997, 74.366587001000198],
              [-151.371399805999829, 74.352517001000251],
              [-151.27824980599982, 74.338187001000108],
              [-151.205584284999901, 74.326729579000187],
              [-151.185735805999855, 74.323600001000216],
              [-151.165965133999862, 74.320406352000191],
              [-151.093860805999924, 74.308759001000283],
              [-151.022330798999889, 74.296922153000196],
              [-151.0026418059999, 74.293664001000252],
              [-150.983035097999988, 74.290341223000098],
              [-150.912083805999856, 74.278317001000175],
              [-150.822194805999828, 74.262717001000283],
              [-150.732983804999861, 74.246870001000218],
              [-150.6638077019999, 74.23429494300018],
              [-150.644460804999909, 74.230778001000147],
              [-150.625204492999927, 74.227196206000201],
              [-150.556635804999843, 74.214442001000123],
              [-150.469513805, 74.197862001000203],
              [-150.383105804999843, 74.181042001000094],
              [-150.297416804999983, 74.163984001000188],
              [-150.231334154999814, 74.150531478000119],
              [-150.212458804999841, 74.146689001000254],
              [-150.19534341699989, 74.143126723000194],
              [-150.135685804999952, 74.130710001000296],
              [-150.035085804999824, 74.122867001000259],
              [-149.933722804999917, 74.114648001000205],
              [-149.853163605999924, 74.107860752000192],
              [-149.832727804999877, 74.106139001000145],
              [-149.812315570999829, 74.104354295000235],
              [-149.732113803999852, 74.097342001000186],
              [-149.631894803999899, 74.088262001000146],
              [-149.532080803999889, 74.078895001000149],
              [-149.432685803999817, 74.069242001000305],
              [-149.333719803999912, 74.059309001000145],
              [-149.235194803999974, 74.049095001000069],
              [-149.157338106999845, 74.040763306000201],
              [-149.137122803999858, 74.038600001000191],
              [-149.133247899999873, 74.038172350000195],
              [-149.118375803999839, 74.036531001000242],
              [-149.088098285999848, 74.033487906000232],
              [-149.080288803999935, 74.032703001000186],
              [-148.981919803999943, 74.02248900100011],
              [-148.961734048999972, 74.020326264000289],
              [-148.884002803999948, 74.011998001000165],
              [-148.806692583999933, 74.003454252000154],
              [-148.78654780399998, 74.0012280010003],
              [-148.689563803999818, 73.990178001000288],
              [-148.669507387999914, 73.987824780000153],
              [-148.593066802999914, 73.978856001000167],
              [-148.49706680299991, 73.967256001000123],
              [-148.401569802999944, 73.955387001000076],
              [-148.367838802999955, 73.952134001000218],
              [-148.292372839999956, 73.942745050000269],
              [-148.272430802999821, 73.94026400100006],
              [-148.252540011999827, 73.937719145000187],
              [-148.177535802999927, 73.928123001000188],
              [-148.083166802999955, 73.915712001000259],
              [-147.989335802999818, 73.903034001000208],
              [-147.896055803, 73.890087001000097],
              [-147.822988102999915, 73.879676225000253],
              [-147.803327802999888, 73.876875001000258],
              [-147.711169802999819, 73.863400001000173],
              [-147.691637799999853, 73.860470019000218],
              [-147.619588802999857, 73.849662001000183],
              [-147.548059104999851, 73.838658278000196],
              [-147.528594801999901, 73.835664001000168],
              [-147.509199335999966, 73.832605567000257],
              [-147.43819480199997, 73.821409001000234],
              [-147.37860580200001, 73.811779001000275],
              [-147.34564587599985, 73.811249920000137],
              [-147.337863801999816, 73.811125001000164],
              [-147.318040686999922, 73.810749090000286],
              [-147.234347801999917, 73.809162001000288],
              [-147.130924801999896, 73.806895001000157],
              [-147.047492337999955, 73.804819667000174],
              [-147.027605801999869, 73.804325001000279],
              [-147.007692156999866, 73.80377197300021],
              [-146.924405801999939, 73.801459001000296],
              [-146.841275148999983, 73.798902251000129],
              [-146.821335801999822, 73.798289001000228],
              [-146.801373738999899, 73.797616196000263],
              [-146.718410801999966, 73.794820001000232],
              [-146.635626614999836, 73.791783039000165],
              [-146.615644801999878, 73.791050001000258],
              [-146.595645668999822, 73.790257404000101],
              [-146.51304980099988, 73.786984001000292],
              [-146.410635800999955, 73.782617001000233],
              [-146.328449010999861, 73.778866801000106],
              [-146.308422800999864, 73.777953001000185],
              [-146.206413800999968, 73.772989001000155],
              [-146.186368950999849, 73.771953536000183],
              [-146.104627800999964, 73.767731001000129],
              [-146.023127400999897, 73.763271954000231],
              [-146.003077800999932, 73.762175001000116],
              [-145.901772800999822, 73.756325001000164],
              [-145.88172026, 73.755105714000251],
              [-145.800727800999852, 73.750181001000215],
              [-145.69995280099991, 73.743742001000157],
              [-145.599463800999814, 73.73700900100016],
              [-145.499272800999876, 73.729984001000275],
              [-145.399385799999862, 73.722667001000275],
              [-145.299824799999925, 73.715062001000035],
              [-145.200594799999834, 73.7071670010003],
              [-145.101708799999955, 73.698981001000163],
              [-145.003177799999975, 73.690512001000172],
              [-144.924960347999843, 73.683532583000215],
              [-144.905016799999942, 73.681753001000232],
              [-144.885096748999871, 73.679910585000187],
              [-144.807233799999864, 73.672709001000101],
              [-144.709841799999964, 73.663384001000225],
              [-144.612855799999892, 73.653773001000104],
              [-144.516280799999919, 73.643881001000182],
              [-144.420130799999839, 73.633712001000276],
              [-144.324419798999855, 73.623259001000292],
              [-144.229152798999934, 73.612531001000264],
              [-144.154030250999909, 73.603810231000296],
              [-144.134344798999877, 73.601525001000198],
              [-144.114702316999825, 73.599176997000086],
              [-144.040005798999886, 73.590248001000077],
              [-143.946144798999853, 73.57869200100015],
              [-143.852774798999917, 73.566867001000162],
              [-143.759902798999917, 73.554770001000236],
              [-143.686994987999924, 73.545007436000276],
              [-143.667544798999984, 73.542403001000309],
              [-143.648147254999969, 73.539734840000108],
              [-143.575702798999828, 73.529770000000212],
              [-143.484391798999923, 73.516873000000146],
              [-143.393622798999843, 73.503706000000307],
              [-143.303399798999806, 73.490281000000266],
              [-143.213738798, 73.476592000000267],
              [-143.197820797999839, 73.474100000000135],
              [-143.154963797999983, 73.474037],
              [-143.053272797999909, 73.473584],
              [-142.951608797999882, 73.472831000000269],
              [-142.86929967999987, 73.471975715000156],
              [-142.849983797999869, 73.47177500000015],
              [-142.830627309999954, 73.471516787000155],
              [-142.7484087979999, 73.470420000000274],
              [-142.66629191699991, 73.469080386000144],
              [-142.646897797999884, 73.468764000000249],
              [-142.627467656999841, 73.468388937000213],
              [-142.545463797999957, 73.466806000000247],
              [-142.444119797999917, 73.464548],
              [-142.362371327999938, 73.462481720000198],
              [-142.34287779799979, 73.461989000000301],
              [-142.323355717999959, 73.46143728300018],
              [-142.241749797999773, 73.459131],
              [-142.160299378999866, 73.456585801000244],
              [-142.140752796999948, 73.455975000000251],
              [-142.121181873999888, 73.455303995000236],
              [-142.039894796999931, 73.452517000000114],
              [-141.939188796999872, 73.448762000000272],
              [-141.838647796999851, 73.444709000000159],
              [-141.738285796999889, 73.440359000000115],
              [-141.63811379699996, 73.435709000000145],
              [-141.557795071999976, 73.43173600800003],
              [-141.538144796999973, 73.430764],
              [-141.438388796999959, 73.425525000000221],
              [-141.338863796999874, 73.419989000000101],
              [-141.319194203999814, 73.418834051000204],
              [-141.239574796999875, 73.414159000000154],
              [-141.14053879599993, 73.408037000000263],
              [-141.041769795999983, 73.40162],
              [-140.94327279599986, 73.394909000000268],
              [-140.845063795999863, 73.387909000000207],
              [-140.747155795999902, 73.380620000000192],
              [-140.669200382999804, 73.37456469100016],
              [-140.649558795999837, 73.373039000000176],
              [-140.629929662999928, 73.371451114000195],
              [-140.552283795999983, 73.365170000000262],
              [-140.474957991999872, 73.358664164000231],
              [-140.455344795999963, 73.357014000000106],
              [-140.435748690999844, 73.355301585000291],
              [-140.358749795999927, 73.348573000000158],
              [-140.262513795999837, 73.339845000000253],
              [-140.166647796, 73.330834000000209],
              [-140.071160794999798, 73.321537000000149],
              [-139.976063794999902, 73.311959000000286],
              [-139.900843163999866, 73.304127456000288],
              [-139.881369794999955, 73.302100000000223],
              [-139.787085795, 73.291964000000235],
              [-139.767676987999835, 73.289810085000227],
              [-139.693227794999984, 73.2815480000001],
              [-139.599805794999895, 73.270856000000265],
              [-139.506824794999829, 73.259887000000219],
              [-139.414299794999863, 73.248642000000245],
              [-139.34149370099982, 73.239535900000305],
              [-139.322241794999911, 73.237128000000212],
              [-139.230660794999835, 73.22533900000019],
              [-139.211499136999862, 73.22280267900004],
              [-139.139563794999958, 73.213281000000279],
              [-139.048960794999914, 73.200956000000303],
              [-138.958866793999903, 73.188362000000154],
              [-138.888294683999845, 73.17823165100009],
              [-138.869285793999779, 73.175503000000219],
              [-138.850331368999832, 73.172710214000062],
              [-138.78022779399987, 73.162381000000209],
              [-138.691705793999972, 73.148995000000184],
              [-138.622565385999849, 73.138271628000041],
              [-138.603727793999894, 73.13535],
              [-138.584950812999864, 73.132363510000062],
              [-138.516302793999898, 73.121445000000108],
              [-138.429435793999801, 73.107284000000107],
              [-138.361791013999891, 73.095980344000282],
              [-138.343141793999791, 73.092864000000191],
              [-138.32455960399983, 73.089683214000274],
              [-138.257427793999796, 73.078192000000172],
              [-138.172299793999798, 73.063267000000224],
              [-138.087766793999833, 73.048092000000167],
              [-138.003838793999989, 73.03267000000028],
              [-137.938820462999956, 73.020441116000256],
              [-137.9205247929998, 73.01700000000028],
              [-137.902304687999845, 73.013493201000301],
              [-137.837830792999966, 73.001084000000219],
              [-137.773905855999942, 72.988497174000258],
              [-137.755763792999971, 72.98492500000026],
              [-137.674335792999955, 72.968525000000284],
              [-137.656355062999921, 72.96482184100023],
              [-137.593549792999852, 72.951887000000113],
              [-137.568888889, 72.94694444400011],
              [-137.650555555999858, 72.777500000000259],
              [-137.980018296999845, 72.538154668000118],
              [-138.438611110999943, 72.205000001000201],
              [-138.661254686999939, 72.007719189000056],
              [-139.362777776999792, 71.386111112000151],
              [-139.566111110999884, 71.1994444460002],
              [-139.739999998999963, 71.020833335000134],
              [-139.787777776999974, 70.967222224000182],
              [-139.875277776999894, 70.806944446000273],
              [-140.04638888799991, 70.625277779000044],
              [-140.16249999899992, 70.488611112000228],
              [-140.164166665999971, 70.485277779000171],
              [-140.278138032999948, 70.243374696000046],
              [-140.30249999799986, 70.191666667000163],
              [-140.30296661299991, 70.190874064000184],
              [-140.322777776999885, 70.157222224000293],
              [-140.710277776999988, 69.861111112000174],
              [-140.785277776999862, 69.798333335000052],
              [-140.829166665999935, 69.773611112000253],
              [-140.949999998999942, 69.691666668000153],
              [-140.976111110999796, 69.669444446000171],
              [-140.979813442999898, 69.664919373000146],
              [-140.983611110999959, 69.660277779000182],
              [-140.993015453999988, 69.651813870000183],
              [-140.997561119000011, 69.647656255666845],
              [-140.997992474999819, 69.646860700500042],
              [-141.002990906748948, 69.64236358292942],
              [-141.000854972287016, 69.532209204847788],
              [-141.000582057518898, 69.4324821587781],
              [-141.000854972287016, 68.965545107032597],
              [-141.001681930856591, 68.532763304994205],
              [-141.00195501326283, 68.232754034749007],
              [-141.00195501326283, 68.065809154166232],
              [-141.00195501326283, 67.865818117307867],
              [-141.000000018160677, 67.732754062921572],
              [-141.000582057518898, 67.532482232306108],
              [-141.002226922202567, 67.299145134675484],
              [-141.001681930856591, 67.066373144958447],
              [-141.000582057518898, 66.866091088421172],
              [-141.000854972287016, 66.666382186424414],
              [-141.001681930856591, 66.499418195102464],
              [-141.00195501326283, 66.099427068930254],
              [-141.00195501326283, 65.699418173123206],
              [-141.000308975112603, 65.232754036145991],
              [-141.000308975112603, 65.166091083624679],
              [-141.00195501326283, 65.132754075308128],
              [-141.000308975112603, 64.199709109681692],
              [-141.000308975112603, 63.966382237973107],
              [-141.00195501326283, 63.832773192240722],
              [-141.001399963632934, 63.100000224867983],
              [-141.000308975112603, 62.73304502904972],
              [-141.001681930856591, 60.966382239370091],
              [-141.000308975112603, 60.933054115870902],
              [-141.001126881226867, 60.399436006693293],
              [-141.000582057518898, 60.366663268099956],
              [-140.995544868972445, 60.307209087605941],
              [-140.94638198922604, 60.29777324389886],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "UZB", Country: "Uzbekistan" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.970809290556105, 42.254673170755993],
            [70.980536153942268, 42.26204505461935],
            [71.033182217382063, 42.298991142815467],
            [71.064500192856173, 42.302600055056686],
            [71.122545207784498, 42.277600190575797],
            [71.172482240110384, 42.249691135132579],
            [71.231654118104615, 42.218291184645111],
            [71.26388220062816, 42.205653956830346],
            [71.275054104125758, 42.204191147084018],
            [71.275745108225493, 42.198153997486017],
            [71.263736187874457, 42.174545026021988],
            [71.222491189165709, 42.14190908008878],
            [70.980954075635765, 42.042609175806078],
            [70.945118254337359, 42.039545087272685],
            [70.912345180467895, 42.045653985962062],
            [70.8772092480867, 42.041773164781048],
            [70.859427208088363, 42.02704498739412],
            [70.851509159412529, 41.970582140513713],
            [70.846691241453783, 41.931173113881741],
            [70.785263290394511, 41.913573128820943],
            [70.75179116579821, 41.912318022635915],
            [70.700200216333059, 41.91010017104945],
            [70.530818213576339, 41.800100096781179],
            [70.516663358368334, 41.785518099786046],
            [70.50235410850334, 41.750664134628479],
            [70.491645221338359, 41.732045077776732],
            [70.474572290350665, 41.716491115286672],
            [70.308591328635913, 41.633300055485748],
            [70.285263318567019, 41.624964252754452],
            [70.251927148440615, 41.614964139324073],
            [70.204709205512387, 41.598718166905869],
            [70.184291224596137, 41.582609154786084],
            [70.180954221294627, 41.541209090868236],
            [70.206309143195057, 41.519118082073462],
            [70.26639129587673, 41.51468221126261],
            [70.292763277911234, 41.515236087425947],
            [70.323318164918163, 41.5107911641595],
            [70.347763315045398, 41.505518108857146],
            [70.369982231682997, 41.499264203242447],
            [70.413736101657236, 41.477727070610982],
            [70.434982214609562, 41.459254026512937],
            [70.474154201019161, 41.412173211521079],
            [70.488872320122113, 41.410782150866154],
            [70.531245187725318, 41.419118121235513],
            [70.601100304260711, 41.443982198884655],
            [70.634154171887332, 41.45911807204304],
            [70.669700314611163, 41.475854050522187],
            [70.711018235877674, 41.470264159278869],
            [70.771663317178167, 41.394818145671778],
            [70.793445201383037, 41.354953981695971],
            [70.792627127630766, 41.330927088538417],
            [70.813309138497885, 41.250500045136292],
            [70.988309195692779, 41.203564237069841],
            [71.156936156609419, 41.161054073892259],
            [71.208118236836441, 41.186682078198899],
            [71.245472356080228, 41.194600126874732],
            [71.397218163954307, 41.12550005217939],
            [71.423591319455312, 41.120845078418895],
            [71.440391168036825, 41.143144964241444],
            [71.442200150385275, 41.19578214286382],
            [71.442200150385275, 41.230227071145364],
            [71.439145114307308, 41.257454007306208],
            [71.434418223817346, 41.332591063961488],
            [71.447827257278703, 41.350364219142406],
            [71.502072252572788, 41.318145021436251],
            [71.545682289088035, 41.302373129463106],
            [71.57595420304304, 41.301754042092838],
            [71.598454081075857, 41.315782162924549],
            [71.618045103422531, 41.333564202922801],
            [71.651518233847156, 41.397309079664339],
            [71.659772229203242, 41.428764183074847],
            [71.650054082996377, 41.462864112220743],
            [71.625954099642883, 41.484245006176494],
            [71.619418226804584, 41.504118163384888],
            [71.645400282702354, 41.554873101824981],
            [71.68830925683406, 41.55626416247982],
            [71.866782271800304, 41.358000132956548],
            [71.888318230965268, 41.331053990552832],
            [71.89443618211007, 41.302445046192574],
            [71.896236112003095, 41.274809073155623],
            [71.889854130907423, 41.24606417432544],
            [71.888327115782687, 41.200636103006261],
            [71.909009294287756, 41.189453973586765],
            [72.111436112215785, 41.196473146963527],
            [72.150191182760153, 41.203973106307771],
            [72.181091236540823, 41.192718054330427],
            [72.215472294720058, 41.052336095537257],
            [72.195482293781055, 41.006591188277227],
            [72.319154090357046, 41.032153981376936],
            [72.395263280145258, 41.040218042806501],
            [72.503327134774651, 41.016745025812284],
            [72.579709239331038, 40.95382710764035],
            [72.605809144787855, 40.927854104197976],
            [72.621300243003788, 40.89480006893335],
            [72.659991275807812, 40.879109146145069],
            [72.702209245839896, 40.87049120855211],
            [72.72553625008041, 40.869100147897271],
            [72.899427213843893, 40.867718139697828],
            [73.049145104058056, 40.868273189327638],
            [73.152482235835549, 40.847164204311596],
            [73.167545186436172, 40.829109081907077],
            [73.147218232988649, 40.799664126521762],
            [73.10414514119222, 40.77507313127883],
            [72.943591294160711, 40.712718141725802],
            [72.878036093966244, 40.693264247315412],
            [72.851927136054059, 40.688827035399996],
            [72.805882324297471, 40.675073170440967],
            [72.710536163753744, 40.584936027459136],
            [72.673591249024042, 40.535627134958943],
            [72.653882209480315, 40.519373115913837],
            [72.629154086301099, 40.513409056511662],
            [72.591172330145781, 40.52145400720191],
            [72.404609269850056, 40.616436057870786],
            [72.373645178328928, 40.602782105197932],
            [72.379972341777687, 40.526191123613742],
            [72.399718261695483, 40.50716420335236],
            [72.434072330146336, 40.488053966973141],
            [72.453045103313144, 40.458682101783708],
            [72.446363385359092, 40.433818024134538],
            [72.432754192049316, 40.404644977689046],
            [72.420536227032443, 40.387854013924951],
            [72.393872387128425, 40.392709147533893],
            [72.345263383545415, 40.409373209283487],
            [72.283109224392916, 40.44722705759601],
            [72.258045154533505, 40.465491057028302],
            [72.227054073284052, 40.464545075433207],
            [72.206936164502252, 40.464945059853889],
            [72.179563383225769, 40.462027151712292],
            [72.112072298668551, 40.432300061465313],
            [72.012772226747728, 40.334373112926514],
            [72.043872272738753, 40.266327146377549],
            [71.903591232060108, 40.250491216663946],
            [71.807691195352817, 40.193745061455402],
            [71.782763247601423, 40.17438219451374],
            [71.71422710735331, 40.147918011544007],
            [71.524154208912165, 40.215482186297081],
            [71.505554095161557, 40.223818156666439],
            [71.484700255278966, 40.238609030689261],
            [71.47109122960714, 40.268764100913572],
            [71.389982234560904, 40.301882173918713],
            [71.299145202661833, 40.30993600942638],
            [71.246645151975855, 40.287164222453768],
            [71.13916333670457, 40.277436017963097],
            [71.0941451404519, 40.277991067592993],
            [71.067900228069817, 40.277573145899495],
            [70.99567236237678, 40.267645116836533],
            [70.982036179338678, 40.244844999031145],
            [70.957763361141332, 40.244945078955411],
            [70.860672255989499, 40.241191159788784],
            [70.822491178176136, 40.228273138216693],
            [70.79304521696244, 40.219100150993896],
            [70.74888231011218, 40.209100037563516],
            [70.708454211689258, 40.203964110197361],
            [70.644991302171348, 40.205909047015624],
            [70.623591297476167, 40.211882158873195],
            [70.602909286609162, 40.233127098359134],
            [70.589427162951864, 40.255209054698511],
            [70.460963264678952, 40.35035404956561],
            [70.375536248380655, 40.376400143203753],
            [70.343091242202405, 40.445809174851021],
            [70.369982231683053, 40.471327040949589],
            [70.415263284420149, 40.491054017766189],
            [70.534991227902907, 40.55938211917676],
            [70.734009293438049, 40.675082222896478],
            [70.777482202016898, 40.706327108174818],
            [70.796800309595568, 40.725591068658574],
            [70.784154196963271, 40.749182102849872],
            [70.759718266929525, 40.76148221051811],
            [70.712491271545872, 40.770364178061683],
            [70.667209212980509, 40.82744510648412],
            [70.658036225757741, 40.870227010963319],
            [70.54860025357462, 40.977436079104464],
            [70.475118119887071, 41.034464201536636],
            [70.465145163822996, 41.038745007138388],
            [70.46081825775363, 41.043282131339978],
            [70.439009216182484, 41.049254069730964],
            [70.417627148760261, 41.047727054606227],
            [70.391163301066655, 41.036618015382587],
            [70.37573624059101, 41.009182202194083],
            [70.374418270132111, 40.961818078874145],
            [70.360818129277703, 40.934945026666199],
            [70.323472224299081, 40.899244992199712],
            [70.252900287402014, 40.858154052871768],
            [70.050263251341875, 40.769982130913903],
            [70.000000162981536, 40.754109153188281],
            [69.972627214066875, 40.746100077043579],
            [69.939972325032585, 40.740273145577802],
            [69.900272278721417, 40.733882112026706],
            [69.847209299416505, 40.722491105579465],
            [69.818109175528861, 40.712491159787149],
            [69.785954183201142, 40.679718085917656],
            [69.759636180623289, 40.651936100126917],
            [69.72678230520691, 40.63880903388916],
            [69.689700262540981, 40.642636043251727],
            [69.66900919921838, 40.655273103428513],
            [69.588327178320952, 40.724991092027537],
            [69.571518277284071, 40.75124505686513],
            [69.537200251016969, 40.780818087731333],
            [69.451509204767973, 40.811100059970144],
            [69.400609259402557, 40.799854060448311],
            [69.365263276527145, 40.779436079531976],
            [69.348872297183647, 40.763954033771384],
            [69.269991212007568, 40.595545002337914],
            [69.259709298991623, 40.502773091904587],
            [69.328327246614919, 40.374709178052385],
            [69.338736229283342, 40.333464179343721],
            [69.31943622661575, 40.209718119105375],
            [69.302482318653432, 40.199018116757841],
            [69.249145083475838, 40.191100068082093],
            [69.226927172666734, 40.190545018452198],
            [69.194154098797156, 40.195544991348342],
            [69.13414520394943, 40.208744980143948],
            [69.078936122149486, 40.230173148033813],
            [69.061645094040529, 40.234718151224328],
            [69.03581827099012, 40.238744985159073],
            [68.768327264757005, 40.208045091226822],
            [68.727909224617946, 40.202500126984646],
            [68.695536135169192, 40.196100040978152],
            [68.642000248885807, 40.184064130898818],
            [68.605672242231634, 40.165754030998968],
            [68.636654103387485, 40.139991078050755],
            [68.681363342688229, 40.1219361232843],
            [68.7023633629627, 40.117709129501222],
            [68.713882277252736, 40.116391159042124],
            [68.762209145974197, 40.122218090508014],
            [68.795372313618572, 40.128818168724749],
            [68.876227169359851, 40.147626991864897],
            [68.991091262054539, 40.140827089075785],
            [69.008736174116422, 40.103673129680473],
            [68.995045173431521, 40.070618088587295],
            [68.932754221618865, 40.075273229985882],
            [68.822963359654381, 40.078653986822232],
            [68.7845722313468, 40.067909057473557],
            [68.811645108126896, 40.000827009792332],
            [68.829436200580659, 39.977764203140609],
            [68.856654084286077, 39.954853947127233],
            [68.904672331331682, 39.89228220155745],
            [68.757554172213617, 39.829645077142672],
            [68.73553608597669, 39.849300137229804],
            [68.723045206191699, 39.868600139897396],
            [68.656100286446843, 39.862500126025409],
            [68.642672309884205, 39.858236084230029],
            [68.637209153017324, 39.839427093451903],
            [68.628582330606918, 39.771100165507718],
            [68.627618244101001, 39.650545095817066],
            [68.611782314387341, 39.630273127654547],
            [68.54026339006694, 39.554709096850345],
            [68.520272383299613, 39.547773239591336],
            [68.473309250228709, 39.537500043754747],
            [68.384718233110931, 39.535554101108076],
            [68.353318282623462, 39.536664200367696],
            [68.175263356988722, 39.553600171057113],
            [68.148882322498736, 39.556654033668607],
            [68.082491278917331, 39.567218081546244],
            [67.907763298300239, 39.600545031579244],
            [67.863036121726594, 39.611382161862977],
            [67.838036089607698, 39.619500202749251],
            [67.759154166241302, 39.63054503659437],
            [67.72970921085593, 39.629153975939417],
            [67.709718204088603, 39.625827030921826],
            [67.681927165842382, 39.615273208965974],
            [67.504436174654955, 39.52888210616176],
            [67.441954115449306, 39.483582110323582],
            [67.442472284705076, 39.462491062580355],
            [67.439972298257061, 39.437209063237773],
            [67.431509258235138, 39.367909163970168],
            [67.409145167034154, 39.296936069186117],
            [67.366000158508399, 39.288782153754298],
            [67.364700292960293, 39.271664128127341],
            [67.375882254741697, 39.216691080721503],
            [67.521854104778328, 39.167700197628633],
            [67.56666325636516, 39.171664167289507],
            [67.59554511549365, 39.173191182414243],
            [67.625954157384882, 39.168464124286217],
            [67.696863382066653, 39.128464173478775],
            [67.695263276745976, 39.015991102490588],
            [67.6999822882469, 39.006244960727102],
            [67.717345233085354, 38.99513608914144],
            [67.75082724832734, 38.99027324418158],
            [67.8608182701403, 38.977627131549397],
            [67.934709273065863, 38.996936019034408],
            [67.984418149986737, 39.008045058258048],
            [68.110536121192382, 38.998882129319057],
            [68.126363333726573, 38.980964134850907],
            [68.140127256969407, 38.950264240918656],
            [68.153872237111017, 38.931382159944505],
            [68.18026332988498, 38.904709099947084],
            [68.185118128217681, 38.898464246787782],
            [68.193645205980062, 38.851727089827122],
            [68.174282339038399, 38.820691081576442],
            [68.149709281068368, 38.806235986595809],
            [68.12004522273341, 38.7979821588779],
            [68.0741541350815, 38.664709059825597],
            [68.071236226939988, 38.543882081195321],
            [68.124909241159656, 38.427564231209928],
            [68.219845191360918, 38.330127121094208],
            [68.277482342879551, 38.303673164046174],
            [68.337063257749975, 38.267627124615714],
            [68.364291199739199, 38.237491165130692],
            [68.382972282674615, 38.19485409993861],
            [68.379982290165486, 38.160544958488984],
            [68.354982258046533, 38.085545029769989],
            [68.337818131952048, 38.046154108048924],
            [68.26846324739941, 37.952282156639782],
            [68.238391325654817, 37.942145082910983],
            [68.171100233307953, 37.934018157207575],
            [68.14255415322171, 37.916309039767086],
            [68.129972245967878, 37.884436014663081],
            [68.12302716861538, 37.858891158836101],
            [68.095409132851245, 37.802354048293282],
            [68.073045209288267, 37.765273179093853],
            [68.051927171816914, 37.738882086319947],
            [68.00179132074706, 37.68749112906518],
            [67.98122715943893, 37.673327053763657],
            [67.937191322241205, 37.636109056627831],
            [67.917209200291182, 37.61720903838075],
            [67.903045124989688, 37.599718018061523],
            [67.86095405697202, 37.543045120686983],
            [67.838318224469418, 37.505827123551157],
            [67.826382226676145, 37.482073145161635],
            [67.817491206676976, 37.446100195927031],
            [67.817491206676976, 37.416382158135335],
            [67.829572379033635, 37.39069112191676],
            [67.84081821091749, 37.352491101002087],
            [67.843736119059002, 37.321518124663655],
            [67.841372254718891, 37.281664186609746],
            [67.837072338377794, 37.259018128185161],
            [67.814009196450087, 37.218745094971339],
            [67.787418279104003, 37.190818102255363],
            [67.779872219292059, 37.185818129359134],
            [67.773182287072899, 37.205200107040085],
            [67.749563257324922, 37.222627089618854],
            [67.725818163752905, 37.228873116244628],
            [67.649427174379156, 37.246100106613071],
            [67.526382344162442, 37.268600152283952],
            [67.456372330055814, 37.25054502987949],
            [67.400691179467827, 37.219018009739543],
            [67.380809137442014, 37.212491189356641],
            [67.30494520214134, 37.20328215995012],
            [67.292391290444328, 37.194409077223767],
            [67.26679128169431, 37.185827014176553],
            [67.246091165916397, 37.184991170789587],
            [67.225818191925413, 37.197491103030004],
            [67.216236167826509, 37.226382182251953],
            [67.20027233027011, 37.246653982776493],
            [67.092082244178556, 37.343273186778177],
            [67.012909133494958, 37.379709152345512],
            [66.970263351123691, 37.382491106017213],
            [66.926927235205, 37.378873141320483],
            [66.858036205175523, 37.365827044267618],
            [66.836382228813449, 37.363609025043061],
            [66.72110021442532, 37.355554016069007],
            [66.623454059643763, 37.360545104147846],
            [66.580272338381945, 37.368191076245793],
            [66.537736191304447, 37.366382093897428],
            [66.519145130009491, 37.390264147767837],
            [66.516936163240445, 37.531936081463257],
            [66.539836193332036, 37.780827141583913],
            [66.554282235857073, 37.818191151473357],
            [66.616091228235831, 37.884718149524687],
            [66.637763309508813, 37.904853995579302],
            [66.659982226146298, 37.927073247493055],
            [66.670882220704272, 37.964644960943971],
            [66.666091292473851, 37.981636084556314],
            [66.651372335180525, 37.996518153685869],
            [66.620254184278593, 38.015545073947365],
            [66.600263345149273, 38.024436093946534],
            [66.570263340134119, 38.035000141824142],
            [66.541363376094836, 38.040836125745429],
            [66.52053619066433, 38.043327059738104],
            [66.492200161072191, 38.042082179474946],
            [66.451436115969017, 38.037427038076387],
            [66.423872227299597, 38.043609026961619],
            [66.326100176331948, 38.081800163058958],
            [66.306363308869635, 38.095682103498788],
            [66.285954212770633, 38.12221803556001],
            [66.26560915441226, 38.147354022148775],
            [66.078327262098213, 38.233191081151588],
            [66.055818163971736, 38.241100077371911],
            [65.878245197771065, 38.282009129400407],
            [65.840272326433166, 38.273609121290704],
            [65.805054083762371, 38.25742701897498],
            [65.776927098836069, 38.23443612905281],
            [65.739154052070404, 38.225818023821759],
            [65.716382265097906, 38.225818023821759],
            [65.695527251748757, 38.228045095501713],
            [65.657763257438518, 38.236382071699367],
            [65.627763252423307, 38.243609116275493],
            [65.597209203606695, 38.253882144474105],
            [65.564418192464387, 38.268327013532755],
            [65.328182297431596, 38.389854048718334],
            [65.292209180558785, 38.410818026808883],
            [65.256654153017649, 38.434718017952022],
            [65.198863277394452, 38.476935987984106],
            [65.151654051645465, 38.511936133533638],
            [65.123872233492904, 38.533336138228705],
            [65.106645075486284, 38.548045037238055],
            [64.995263328294669, 38.621654072940188],
            [64.976382253149012, 38.631935985956105],
            [64.935254098170873, 38.648882182567505],
            [64.907627177589319, 38.65680006360536],
            [64.874982179200714, 38.666100120480436],
            [64.798036140197183, 38.689991059168264],
            [64.746645182942331, 38.708327142968002],
            [64.671518184570942, 38.739018152082949],
            [64.631654188233227, 38.761382075645926],
            [64.464709140012559, 38.873882136362155],
            [64.434836204649827, 38.896936058196459],
            [64.416382271291155, 38.913882087169767],
            [64.401927176310522, 38.929436049659742],
            [64.388600285500416, 38.94554506177964],
            [64.38302715806347, 38.953126996137129],
            [64.366927198399168, 38.971518065222014],
            [64.338118261828555, 38.990754030149063],
            [64.302900186795938, 38.985409058117241],
            [64.266936122378667, 38.972764118951446],
            [64.23441819364254, 38.959573182611351],
            [64.205672288983948, 38.952073223267107],
            [64.177900193839008, 38.953745077678988],
            [64.132472290157949, 38.975545066794851],
            [64.086382216124235, 39.000691111667422],
            [64.043872220584745, 39.029718145359297],
            [63.983327219208405, 39.068053953105775],
            [63.893054121756705, 39.113882176483642],
            [63.813882184539551, 39.153600160067541],
            [63.710409265930224, 39.207764186176675],
            [63.693882332116999, 39.224164217975598],
            [63.657209158689085, 39.263327151929687],
            [63.643609185472798, 39.279164255109762],
            [63.595827140459249, 39.329718027873028],
            [63.56138221217779, 39.364436038560783],
            [63.518609192515868, 39.398609057902419],
            [63.478600189252887, 39.423327122797858],
            [63.342491324710437, 39.498327051516739],
            [63.28081811916357, 39.52832705653195],
            [63.199154074487382, 39.566664205382821],
            [63.130273270379774, 39.599164196846104],
            [63.09331812972826, 39.617209093328754],
            [63.038609279911185, 39.644436029489597],
            [63.000000222120519, 39.666936075160478],
            [62.955273213184881, 39.697773097029213],
            [62.901654178421751, 39.735827105190069],
            [62.879718234836133, 39.748882087060267],
            [62.813473204008545, 39.787491144851131],
            [62.789436084928951, 39.799718162323401],
            [62.72720917085681, 39.82943603247692],
            [62.584436190357081, 39.909709184136247],
            [62.555409156665206, 39.930545086746079],
            [62.441209245980104, 40.032336092659463],
            [62.421800278570828, 40.082354094170299],
            [62.418327153161357, 40.107491086587558],
            [62.425136108405951, 40.128327156835482],
            [62.410818141361602, 40.220273114527501],
            [62.398882311206449, 40.278045047049645],
            [62.352636166135056, 40.424573120182387],
            [62.336109232321832, 40.439436078572655],
            [62.259227231058645, 40.473391168431235],
            [62.216663256062503, 40.480264161416287],
            [62.192491188341563, 40.4936091571373],
            [62.185527167887841, 40.504209079560567],
            [62.141109283542193, 40.564436071529556],
            [62.112500171543815, 40.60665404156164],
            [62.044718234945577, 40.719154102277955],
            [61.999300221910374, 40.839973201919307],
            [61.986382200338284, 40.877627058021389],
            [61.985336138819122, 40.921244973525816],
            [61.987500178586942, 40.945827083951244],
            [61.984154122829949, 40.971235985308326],
            [61.970545097158237, 41.008609047653366],
            [61.947909264655522, 41.044300197302434],
            [61.893327149214798, 41.111664212207174],
            [61.87416326865511, 41.125544979180617],
            [61.737773275079434, 41.202773153045953],
            [61.632354078547365, 41.259300205304768],
            [61.611382221467721, 41.267491168748805],
            [61.446382277703293, 41.30235401872379],
            [61.414845199279426, 41.298809144223029],
            [61.394854192512099, 41.26790909044233],
            [61.379709266898175, 41.214018146739491],
            [61.361936111717313, 41.192909161723364],
            [61.329300165784133, 41.173045056970452],
            [61.289991219076313, 41.162900104252842],
            [61.245273262596271, 41.167354079974686],
            [61.161518268348033, 41.206100098063658],
            [61.138954184936694, 41.232209055975758],
            [61.085827167891438, 41.237209028872016],
            [61.022491160387887, 41.230273171612893],
            [61.004200171227382, 41.209873127969487],
            [60.981800205480653, 41.234436127655712],
            [60.962773285219185, 41.245827134103067],
            [60.939991272324761, 41.250973119752828],
            [60.811936243289978, 41.257773190180032],
            [60.70499120509956, 41.258045099119826],
            [60.679991172980493, 41.25277321728386],
            [60.656382201516379, 41.245827134103067],
            [60.593882205038057, 41.231654006345948],
            [60.518118182023528, 41.21762705898071],
            [60.47999125130471, 41.22110018439021],
            [60.453745165456183, 41.229018065427951],
            [60.432491173514876, 41.24221805422367],
            [60.408882202050762, 41.263053956833502],
            [60.374436100302802, 41.287773195195243],
            [60.344300140817694, 41.305409054801785],
            [60.325827096719735, 41.314436029270766],
            [60.264573321608935, 41.338745057290097],
            [60.222763382624663, 41.349164265880503],
            [60.194154102988222, 41.359436120612528],
            [60.159718227162074, 41.372773237344617],
            [60.14027321756916, 41.38110015525838],
            [60.092145167591553, 41.410753987671441],
            [60.077773221090666, 41.445473171825668],
            [60.097354185153478, 41.530754007732284],
            [60.121373199322107, 41.557627059939946],
            [60.160691198485409, 41.57360917004543],
            [60.18394511253004, 41.590544973096755],
            [60.160682313667991, 41.631800197727401],
            [60.13610925569796, 41.659718137987952],
            [60.075827110805932, 41.730545052380208],
            [60.071318149799225, 41.759164222662363],
            [60.094436109373902, 41.787354071862737],
            [60.116800200574943, 41.802773253349287],
            [60.143327247818576, 41.806245037654307],
            [60.19331825960117, 41.795000043960769],
            [60.225682296594556, 41.780691129371931],
            [60.259300266306496, 41.774091218793259],
            [60.274154172241339, 41.791036074299981],
            [60.253045187225297, 41.824227069862914],
            [60.180409290484562, 41.860545018233367],
            [60.152763259163834, 41.883873195940154],
            [60.149154179284466, 41.891518162209891],
            [60.137218181491193, 41.911100132101083],
            [60.028045065792497, 42.03249121281668],
            [60.026654172775608, 42.163609156918469],
            [60.014445092576153, 42.217491215803804],
            [59.914227202255148, 42.298464256380342],
            [59.857500157785893, 42.316100115986998],
            [59.735827109846667, 42.322491149537981],
            [59.622218123337149, 42.308882123866184],
            [59.558891168289023, 42.299436054237205],
            [59.529991204249626, 42.293609122771429],
            [59.487209132132335, 42.291664185953167],
            [59.449018163673173, 42.293882205177724],
            [59.425554199134439, 42.299718021460919],
            [59.279300214236201, 42.351109146353664],
            [59.265827143034386, 42.367491073241681],
            [59.259027240245274, 42.394853963872421],
            [59.259718244345009, 42.422082073499652],
            [59.259991159113127, 42.446800138395176],
            [59.2394362178986, 42.476653963018506],
            [59.216100161202661, 42.49735407879642],
            [59.1433911742661, 42.54443606725475],
            [59.09331818747026, 42.531382091212848],
            [59.068054125400494, 42.525553986280599],
            [59.047218222790747, 42.523882131868717],
            [59.023882333732814, 42.525273192523557],
            [58.978045225537556, 42.532354056707888],
            [58.951663352857196, 42.54083603983095],
            [58.850273337192817, 42.604164168345349],
            [58.804573189295837, 42.637627072848076],
            [58.775754194441362, 42.662909072190658],
            [58.730545226071882, 42.703882161959697],
            [58.613536204348719, 42.796173118787451],
            [58.596518258645943, 42.780827027496755],
            [58.591663292675179, 42.759718042480799],
            [58.586236177991935, 42.716382094200085],
            [58.582500196098209, 42.695545018123838],
            [58.57332720887544, 42.673327107314677],
            [58.556518140200438, 42.660964135372396],
            [58.52166333685247, 42.657773144824588],
            [58.461109283020704, 42.659153979557559],
            [58.405754188467, 42.663318109066765],
            [58.367209168416679, 42.674436033107909],
            [58.333600251160163, 42.688600108409432],
            [58.302082283475698, 42.696518157085265],
            [58.266663378042523, 42.694018170637193],
            [58.152973254709906, 42.64241816871656],
            [58.15179123872079, 42.611173115800327],
            [58.199436155797997, 42.588327065165387],
            [58.229154193589494, 42.578609086596501],
            [58.254718160155761, 42.572773102675299],
            [58.29319126347653, 42.561100129004544],
            [58.312909187837732, 42.550136096706225],
            [58.34276334773719, 42.521382145420532],
            [58.50676333045007, 42.302527132498923],
            [58.44165404750575, 42.294718048564661],
            [58.408809224544967, 42.30634508940598],
            [58.416236093693357, 42.358609105697994],
            [58.404363295450224, 42.399018093381628],
            [58.371245222445083, 42.425136103749324],
            [58.325000250840191, 42.446936092864988],
            [58.283336156971444, 42.462773196045063],
            [58.251391215138057, 42.471373196365121],
            [58.101663266640088, 42.503882072645823],
            [58.076382273125745, 42.507500204980616],
            [58.053609145048739, 42.508045028688556],
            [58.021500253188748, 42.501345038185619],
            [57.943600185962993, 42.445545032210148],
            [57.948045109229412, 42.399164106135331],
            [57.961654134901124, 42.370473186761799],
            [57.95416322801222, 42.342218126354496],
            [57.9083271256454, 42.26527309317926],
            [57.872763380924908, 42.210818047391101],
            [57.838600252228986, 42.187764125556882],
            [57.664436206059094, 42.153600158670557],
            [57.620127118817067, 42.154154034833894],
            [57.579991213539557, 42.163045054833063],
            [57.522218107551197, 42.17027310523757],
            [57.497773292700032, 42.173327135487156],
            [57.473873301556893, 42.175273245771919],
            [57.44499127479034, 42.173882185116966],
            [57.417500141040676, 42.171109116262684],
            [57.378191194332913, 42.159291135666436],
            [57.352218190890625, 42.149718164023042],
            [57.323609246530253, 42.13860007234392],
            [57.287491290370241, 42.123045104025536],
            [57.264163280301347, 42.109718045577537],
            [57.245827196501665, 42.095273176518688],
            [57.21110918581391, 42.063609028442542],
            [57.190336147478121, 42.032909134510177],
            [57.167909192003123, 41.990273242784596],
            [57.142491238190615, 41.961109081156522],
            [57.122073257274309, 41.943327041158184],
            [57.097218232080564, 41.927627065914393],
            [57.067354181535364, 41.918464136975516],
            [57.029163380714266, 41.910264121076054],
            [57.004436095725367, 41.904018094450279],
            [56.982627221792285, 41.889018008123642],
            [56.969709200220194, 41.86013598135726],
            [56.977482241970705, 41.681936048797226],
            [57.041100216697856, 41.471791174403634],
            [57.057073274347744, 41.442764140711759],
            [57.089436137874742, 41.406800076294573],
            [57.115963352756438, 41.390827018644686],
            [57.13388218541499, 41.345827094940816],
            [57.057073274347744, 41.26790909044233],
            [57.037909226150049, 41.259445044592084],
            [56.956100174548482, 41.269991155196905],
            [56.922082220415945, 41.276936064911311],
            [56.885273260156197, 41.288327071358665],
            [56.835545104857914, 41.298609152012673],
            [56.79833616017757, 41.298882234418855],
            [56.748054128716063, 41.292500085685205],
            [56.69720916863551, 41.287218145565433],
            [56.666936081214175, 41.288045104134952],
            [56.644154068319693, 41.289164255850082],
            [56.542773272748803, 41.296109165564602],
            [56.533754177268833, 41.298291142605237],
            [56.523609224551109, 41.296945008951539],
            [56.360273256209638, 41.306382193763099],
            [56.215000289261837, 41.315273213762268],
            [56.015000199948048, 41.325553953311911],
            [56.000963361936925, 41.328454091818585],
            [56.000273196027621, 41.353882103914955],
            [55.999436179174182, 41.576382071261619],
            [55.999163264406064, 41.896100045774446],
            [55.999163264406064, 42.132764256060725],
            [56.000000281259503, 42.216382122372679],
            [55.999718146397726, 42.481100059751313],
            [55.998882303010845, 42.760273092110609],
            [55.998600168149068, 43.011664138679166],
            [55.999436179174182, 43.081382127278275],
            [55.999163264406064, 43.346936075682052],
            [55.99832725338095, 43.626654099387352],
            [55.998045118519343, 43.878873110354007],
            [55.998882303010845, 43.948600151408542],
            [55.998600168149068, 44.509436144291485],
            [55.997491242355892, 44.734164189146426],
            [55.998754059891809, 45.002054006333665],
            [56.257218259293921, 45.063609027045445],
            [56.387500192370595, 45.09388211446695],
            [56.648609217507925, 45.153882124497173],
            [56.714154191780523, 45.168600075962118],
            [56.750191178755557, 45.172964197681594],
            [56.757500198344957, 45.17832710698643],
            [56.779436141930518, 45.183600162288784],
            [57.063609147186156, 45.247773186645631],
            [57.392218141698123, 45.320545037856263],
            [57.589709192108415, 45.363609077197268],
            [57.779636077795857, 45.404473202224509],
            [58.008045119259663, 45.453609092242615],
            [58.273045191500017, 45.509436087946497],
            [58.560545141773247, 45.56860008695169],
            [58.56550924012376, 45.569954099594597],
            [58.570827222427255, 45.57059112423768],
            [59.652973337830502, 45.000000104773804],
            [61.149163343032939, 44.211100069562733],
            [61.166100151912673, 44.198327054916049],
            [61.311663300349295, 44.07971809551033],
            [61.614436084079188, 43.829991192002822],
            [61.814709255799272, 43.663044970315568],
            [61.956936239124587, 43.54360905234013],
            [62.025109275326059, 43.484791058298995],
            [62.044718234945577, 43.488327047982452],
            [62.382491164224945, 43.5327730955227],
            [62.488045141760466, 43.546391173650008],
            [62.615000297457499, 43.562491133314396],
            [62.826663302158323, 43.589153967389834],
            [63.211382265426124, 43.636382136240044],
            [63.422491226325548, 43.619436107266736],
            [63.537500158307381, 43.609991211104145],
            [63.767209232957356, 43.590273119105049],
            [63.997218212103718, 43.570544968821949],
            [64.02138223319767, 43.569164134089007],
            [64.458036139237862, 43.548045090789159],
            [64.58553611864275, 43.600273232535429],
            [64.843318198762546, 43.703327055984786],
            [64.931363386343975, 43.737773157732832],
            [65.026927141094944, 43.652491148359829],
            [65.216091273591275, 43.480818036182725],
            [65.26832712668849, 43.432491167461237],
            [65.478591191745437, 43.339427063882326],
            [65.519018116701915, 43.321109084993466],
            [65.638045165439252, 43.153600102325569],
            [65.748027134796672, 42.989436001948192],
            [65.821927190177831, 42.877209191275952],
            [66.123872344785894, 42.996936128930415],
            [66.1105362338823, 42.800273210556313],
            [66.101091170081787, 42.671664137719929],
            [66.09582716723483, 42.604436077285087],
            [66.07078220811465, 42.423864233757953],
            [66.036654115774041, 42.423327121400874],
            [66.034427211732151, 42.336382142433322],
            [66.033054088350156, 42.263882200162513],
            [66.029163376523314, 42.046109123305811],
            [66.029163376523314, 42.003053968782311],
            [66.526382232869338, 42.003053968782311],
            [66.539154074049605, 41.896664147859767],
            [66.545536222783284, 41.836382170605916],
            [66.55761823333026, 41.739018150685851],
            [66.56248225175662, 41.716936026708495],
            [66.636109224731484, 41.460545007243795],
            [66.719982236176719, 41.175000052072633],
            [66.759491175094837, 41.164835988615593],
            [66.777772273609656, 41.161936017746925],
            [66.875809192718322, 41.149991135136233],
            [67.071945224657526, 41.143300197088635],
            [67.159145181120465, 41.145000047057508],
            [67.350536217658686, 41.149164176566629],
            [67.439418254455489, 41.154164149462773],
            [67.48970028591711, 41.158882155135402],
            [67.536100155093237, 41.167500092728389],
            [67.639982278216593, 41.18220915937583],
            [67.75533620933416, 41.192145067427802],
            [67.93552717918007, 41.183327137624573],
            [67.98942717533842, 41.130545119714853],
            [68.107345130644518, 41.064853965050574],
            [68.140272263894786, 41.051653976254883],
            [68.154163256790099, 41.036109066220305],
            [68.144845095003944, 40.98304508108707],
            [68.116091311356428, 40.907491108566788],
            [68.103318129071596, 40.879436040369839],
            [68.081100218262492, 40.857218129560565],
            [68.053863391455849, 40.840827150217152],
            [68.048036124713889, 40.810273101400625],
            [68.212772373803688, 40.70388210701158],
            [68.233318262562733, 40.69137312231571],
            [68.256945171299748, 40.680545044487289],
            [68.265472249061958, 40.67780013882772],
            [68.31054509532359, 40.65624506892344],
            [68.455263347917736, 40.597773247484255],
            [68.479709168597225, 40.594436076544767],
            [68.608036106571689, 40.599718016664539],
            [68.640745142700752, 40.614227091101711],
            [68.651927104482269, 40.671664250410103],
            [68.628582330606918, 40.718045008846829],
            [68.589154193235657, 40.738882084922963],
            [68.568054093037034, 40.861382091431764],
            [68.593600289968464, 40.919991208445353],
            [68.616654211802683, 40.936936063952075],
            [68.646100173016492, 40.946654042520876],
            [68.648745166389944, 40.946800055274579],
            [68.664427204360749, 40.951935982640649],
            [68.721718183277204, 40.977836063525174],
            [68.738872251087884, 41.001245042778848],
            [68.750691237512541, 41.03943601123801],
            [68.769018268856797, 41.070273200744722],
            [68.785818117438311, 41.085682156309403],
            [68.875263250492054, 41.118600069466183],
            [69.031663361574402, 41.199718116967958],
            [69.064563337458281, 41.222218162638839],
            [69.073045152943365, 41.271936092015167],
            [69.070745158705535, 41.357564106352115],
            [69.059627234664475, 41.376791186461944],
            [69.226654090260439, 41.459718048674077],
            [69.25499129331908, 41.470827087897717],
            [69.353872270079904, 41.48443611356943],
            [69.489972249804936, 41.575554106863606],
            [69.637209264310314, 41.67012712065646],
            [69.758609229843302, 41.699153986710158],
            [69.786372272532816, 41.703327001036698],
            [69.845854113307297, 41.706673224431782],
            [69.928382332203313, 41.711900179266607],
            [70.058027240636903, 41.780264155223009],
            [70.139991189809592, 41.828318109176266],
            [70.156645193275295, 41.844418068840568],
            [70.182754151187595, 41.876082216916913],
            [70.24413616941726, 41.955236049223103],
            [70.337772254070472, 42.042882090574281],
            [70.363172270610079, 42.057873124445436],
            [70.387900226151231, 42.069409137817971],
            [70.462209150770406, 42.09649106705352],
            [70.484836098455759, 42.100518068626329],
            [70.50555415150663, 42.091073172463936],
            [70.531100180799996, 42.067391110803769],
            [70.538318172920611, 42.040591148791961],
            [70.628209223224559, 42.024264207188892],
            [70.645536125879261, 42.048573235208138],
            [70.654436198333741, 42.073854061084333],
            [70.665536185101956, 42.096627021523418],
            [70.750682240005034, 42.198845001585724],
            [70.79276324973884, 42.203991154873776],
            [70.840891299716333, 42.194953954482813],
            [70.884145105345709, 42.205100080667009],
            [70.907763296903312, 42.217736135015301],
            [70.966936180726037, 42.250800060925812],
            [70.970809290556105, 42.254673170755993],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ISO_A3: "VCT",
        Country: "Saint Vincent and the Grenadines",
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.347068555999897, 12.703842364000195],
            [-60.327398555999878, 12.550808364000147],
            [-60.315729555999894, 12.447000364000246],
            [-60.311695555999904, 12.411436363000121],
            [-60.288264555999945, 12.331427363000103],
            [-60.346660555999961, 12.298193363000109],
            [-60.76561955699998, 12.063203363000241],
            [-60.823285556999963, 12.036585363000199],
            [-61.25237955699987, 12.48833236400003],
            [-61.265596556999867, 12.502775364000115],
            [-61.285613556999863, 12.527183364000109],
            [-61.286144556999943, 12.52783336400006],
            [-61.286669556999868, 12.528483364000181],
            [-61.307214556999952, 12.554015364000122],
            [-61.335160556999938, 12.563369364000096],
            [-61.344920556999853, 12.567493364000086],
            [-61.345882556999868, 12.567288363999978],
            [-61.380010556999821, 12.560531364000099],
            [-61.389436556999954, 12.559261364000179],
            [-61.394075556999837, 12.559051364000027],
            [-61.400497556999881, 12.557875364000097],
            [-61.412197556999899, 12.555463364],
            [-61.415924556999869, 12.554540364000047],
            [-61.418111556999861, 12.554063364000172],
            [-61.420495556999924, 12.551425364000238],
            [-61.428365556999779, 12.551586864000058],
            [-61.441498556999875, 12.551600364000137],
            [-61.459591556999783, 12.551954364000096],
            [-61.462813556999947, 12.551427364],
            [-61.467429556999889, 12.550206364000246],
            [-61.471497556999907, 12.549385864000044],
            [-61.488417556999877, 12.546588364000058],
            [-61.493989556999907, 12.545770364000191],
            [-61.495607556999857, 12.545452364000198],
            [-61.506034556999907, 12.54376836400013],
            [-61.5342705569999, 12.552604364000047],
            [-61.560714556999926, 12.561087364000116],
            [-62.153484557999946, 12.810755364000102],
            [-62.548984557999916, 12.970167364000133],
            [-63.153148558999931, 13.327949364000176],
            [-63.272821558999851, 13.398903364000034],
            [-63.279710558999795, 13.424163364000208],
            [-63.378359558999875, 13.632210365000219],
            [-63.21100155899984, 13.761321365000242],
            [-63.051053558999854, 13.888847365000231],
            [-62.92136555899981, 13.994758365000223],
            [-62.81388855900002, 14.080555365000066],
            [-62.493224557999838, 13.966909365000106],
            [-62.357597557999952, 13.927927365000073],
            [-62.127269557999909, 13.859477365000203],
            [-62.123801557999968, 13.858442365000201],
            [-62.024250557999949, 13.828667365000058],
            [-61.972390557999915, 13.816010365000125],
            [-61.928937557999916, 13.803971365000223],
            [-61.674841556999922, 13.733261365000089],
            [-61.635129556999857, 13.722239365000064],
            [-61.366248556999892, 13.648573365000175],
            [-61.344734556999924, 13.642595365000091],
            [-61.341184556999849, 13.641501365000124],
            [-61.323334556999953, 13.636515365000093],
            [-61.254242556999827, 13.616964365000115],
            [-61.249875556999882, 13.615701365000064],
            [-61.244054556999913, 13.614142365000248],
            [-61.217622556999913, 13.605443365000099],
            [-61.175991556999975, 13.592221365000171],
            [-61.172500556999921, 13.59099136500015],
            [-61.153283556999952, 13.584652365000238],
            [-61.120452556999908, 13.57330836500023],
            [-61.113072556999782, 13.570918365000011],
            [-61.104796556999929, 13.568505365000021],
            [-61.06579855699988, 13.547668365000106],
            [-61.03431155699991, 13.531064365000134],
            [-61.011664556999904, 13.519039365000083],
            [-60.988839556999892, 13.507757365000089],
            [-60.971245556999975, 13.498471365000228],
            [-60.948501556999986, 13.486570364999977],
            [-60.895694556999842, 13.458778364000068],
            [-60.884835556999917, 13.453033364000078],
            [-60.877842556999894, 13.449265364000212],
            [-60.847878556999831, 13.432901364000202],
            [-60.765133556999899, 13.394669364000208],
            [-60.737750556999913, 13.382242364000149],
            [-60.691870556999902, 13.361830364000241],
            [-60.683074556999884, 13.358109364000029],
            [-60.543866555999955, 13.29894636400013],
            [-60.535835555999824, 13.295578364000164],
            [-60.525745555999833, 13.29135536400014],
            [-60.515593555999828, 13.287181364000105],
            [-60.503340555999841, 13.282152364000069],
            [-60.473036555999897, 13.269977364000226],
            [-60.448154555999906, 13.260312364000242],
            [-60.426310555999919, 13.251904364000211],
            [-60.40399155599988, 13.243542364000064],
            [-60.397209555999893, 13.241023364000171],
            [-60.388103555999862, 13.237647364000054],
            [-60.385322555999892, 13.236625364000076],
            [-60.383276555999828, 13.173700364000126],
            [-60.383265555999913, 13.170555364000165],
            [-60.384472555999935, 13.128199364000182],
            [-60.387698555999862, 13.079438364000112],
            [-60.372177055999941, 12.959272864000241],
            [-60.347068555999897, 12.703842364000195],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "VEN", Country: "Venezuela" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-63.63789652, 16.681776071999977],
            [-63.630249825499817, 16.68045568400008],
            [-63.62902627879987, 16.680056111800113],
            [-63.510537803, 16.645827048000243],
            [-63.482422645999861, 16.63326538000014],
            [-63.477305905, 16.630949531000098],
            [-63.442405558999894, 16.615153367000076],
            [-63.380138498499889, 16.573943459500114],
            [-63.294035934999869, 16.516958330000179],
            [-63.28044552099982, 16.507921848000066],
            [-63.279581763999943, 16.507347508000166],
            [-63.045478559, 16.348982367000175],
            [-62.813888559, 15.841944367000153],
            [-62.813888559, 15.065000366000092],
            [-62.813888559, 14.488611365000096],
            [-62.813888559, 14.08055536500008],
            [-62.921365558999867, 13.994758365000152],
            [-63.051053558999854, 13.88884736500016],
            [-63.211001558999897, 13.761321365000171],
            [-63.378359558999932, 13.632210365000134],
            [-63.279710558999852, 13.424163364000123],
            [-63.27282155899988, 13.398903364000063],
            [-63.249883558999841, 13.231600364000059],
            [-63.247407558999981, 13.214086364000096],
            [-63.127153559000021, 13.01785636400021],
            [-63.04297855899992, 12.880107364000096],
            [-62.948834559, 12.726083364000118],
            [-62.917333558999957, 12.673663364000106],
            [-62.803720557999867, 12.476581364000069],
            [-62.56871055799985, 12.003222363000262],
            [-62.56799355799987, 12.00177436300018],
            [-62.567279557999854, 12.000326363000099],
            [-62.564114558, 11.993902363000061],
            [-62.367843057999949, 11.59580486300014],
            [-62.295227558, 11.443006363000038],
            [-62.291454557999913, 11.441311363000125],
            [-62.225851557999903, 11.414001363000011],
            [-62.214677557999863, 11.409446363000072],
            [-62.202119557999907, 11.406169363],
            [-62.188456557999984, 11.402983363000089],
            [-62.062880557999961, 11.378549363000175],
            [-61.981915557999969, 11.374253363000079],
            [-61.735294557, 11.360834363000109],
            [-61.72914855699986, 11.360453363000019],
            [-61.729444556999937, 11.175000362000091],
            [-61.729444556999937, 10.911111362000085],
            [-61.731111556999934, 10.904166362000197],
            [-61.763055557999849, 10.811389362000142],
            [-61.771388558000012, 10.793889362000272],
            [-61.80277755799986, 10.714444362000194],
            [-61.80277755799986, 10.588889362000216],
            [-61.862499557999882, 10.588611362000137],
            [-62.083055557999899, 10.046111361000158],
            [-61.973611557999988, 10.00805536100016],
            [-61.854999558, 9.986666361000204],
            [-61.63055555699998, 9.986666361000204],
            [-61.499999557000024, 9.970000361000061],
            [-61.223333556999961, 9.875833361000105],
            [-60.890833556999837, 9.848611361000167],
            [-60.664166555999827, 9.831944361000126],
            [-60.267222555999865, 9.890555361000168],
            [-59.987777555999912, 9.954722361000094],
            [-59.922499555999877, 9.969722361000066],
            [-58.954945716, 10.142316940000029],
            [-59.044012250999913, 9.980555900000169],
            [-59.052745564999981, 9.964817966000169],
            [-59.055172204999934, 9.960426555000083],
            [-59.057041902, 9.957043023000082],
            [-59.088970199999864, 9.898548047000148],
            [-59.146968118999894, 9.792291618000078],
            [-59.477450546999876, 9.188301069000104],
            [-59.481781179999899, 9.180354989000065],
            [-59.492528731999926, 9.160634796000181],
            [-59.569407030999912, 9.063848620000044],
            [-59.932522943999913, 8.60223149],
            [-59.957293668, 8.567765228000113],
            [-59.963581512999866, 8.55901625000017],
            [-59.986117837999899, 8.5385189080001],
            [-59.990281909836114, 8.535273210013997],
            [-59.981117807267054, 8.518327013416368],
            [-59.886117819325364, 8.400836032259292],
            [-59.812090861929818, 8.306109126723797],
            [-59.803055002643418, 8.28333599864672],
            [-59.832855015448189, 8.231527119698342],
            [-59.888890888179645, 8.218609098126436],
            [-59.922226890668156, 8.211109138782021],
            [-59.946181867096243, 8.199991047102984],
            [-59.979726914250307, 8.174864112969615],
            [-59.987017828928884, 8.146982047254696],
            [-60.011181850022808, 8.059236093617443],
            [-60.037781987462353, 8.037009130352772],
            [-60.093608815528057, 8.041245008953268],
            [-60.12486392672821, 8.028326987381277],
            [-60.23722685950824, 7.94639103376538],
            [-60.257545933966782, 7.921573056583853],
            [-60.319726915209543, 7.877500171373867],
            [-60.513199848864332, 7.818191165443267],
            [-60.533335862557038, 7.803891135671833],
            [-60.574581867094082, 7.715209091085299],
            [-60.588754994851115, 7.63930911360093],
            [-60.662090948146954, 7.566527036468429],
            [-60.691108929383518, 7.567082086098239],
            [-60.716664011132195, 7.540000156862718],
            [-60.714026896747924, 7.500973177378526],
            [-60.688263943799598, 7.453473099588678],
            [-60.660835841962012, 7.447218020507492],
            [-60.609999934337054, 7.381944955174717],
            [-60.591390935769113, 7.336391155307624],
            [-60.595354905430071, 7.306109015430721],
            [-60.618617871930127, 7.284027059091429],
            [-60.63423587216036, 7.254309021299846],
            [-60.630835836946801, 7.221944984306546],
            [-60.617499893681384, 7.194444965739507],
            [-60.538063926513473, 7.124445009916599],
            [-60.514999946395363, 7.142982091755101],
            [-60.505763927260546, 7.172709014364102],
            [-60.4561179146136, 7.195836026394261],
            [-60.360490960312717, 7.176245004047473],
            [-60.336672944182851, 7.155282199423311],
            [-60.309035797679428, 7.143473103644553],
            [-60.278054942351872, 7.118744980465323],
            [-60.281390939825059, 7.090000081635225],
            [-60.291254931147478, 7.056600041406384],
            [-60.408335869600137, 6.947500015903515],
            [-60.463617873958015, 6.90666422170905],
            [-60.498890934275494, 6.887782140734885],
            [-60.526946002472528, 6.874718106409091],
            [-60.590835886139388, 6.850836052538952],
            [-60.637781920127708, 6.834927032629338],
            [-60.664999971471218, 6.807500104258139],
            [-60.676390977918572, 6.790000031483416],
            [-60.697781930158044, 6.766664142425498],
            [-60.719172882397686, 6.759027055144969],
            [-60.816117807157639, 6.788336056060459],
            [-60.893681927703142, 6.76500016700254],
            [-60.93965499036824, 6.724582126863424],
            [-61.035972948768915, 6.719718108437164],
            [-61.054726786624201, 6.728891095659947],
            [-61.125108957232698, 6.714773120825953],
            [-61.174446012927717, 6.658744959445343],
            [-61.204726811700056, 6.577918099260756],
            [-61.196526795800509, 6.53368210221447],
            [-61.14000879599709, 6.408609019061814],
            [-61.138617902980258, 6.299164162061373],
            [-61.160145983156184, 6.182500139473831],
            [-61.262781884912215, 6.107782177978379],
            [-61.270281844256345, 6.08639105810083],
            [-61.282817818680655, 6.056336067800757],
            [-61.323064029804129, 5.992500163590449],
            [-61.337890946010674, 5.972782071591084],
            [-61.373455026007264, 5.960973143450403],
            [-61.38972681468718, 5.940000112904272],
            [-61.116108804853951, 5.63471812263279],
            [-60.860281887474372, 5.348054015746669],
            [-60.781108944428865, 5.258335967924751],
            [-60.748890920189382, 5.222500146626345],
            [-60.730372949090082, 5.204800081641281],
            [-60.710972866498338, 5.202918009096976],
            [-60.68514604344773, 5.196945064877397],
            [-60.65520890270659, 5.181318012191568],
            [-60.64417295367889, 5.158609089493012],
            [-60.582363961300217, 4.988473218362529],
            [-60.578546004393047, 4.952636055959744],
            [-60.592508914017742, 4.924718115699008],
            [-60.649308881044874, 4.842918116553022],
            [-60.67556401934894, 4.813891082861147],
            [-60.72028197582901, 4.776109151278078],
            [-60.83861785282852, 4.718473173225931],
            [-60.866808875495366, 4.713336072393474],
            [-60.886526799856483, 4.709718107696673],
            [-60.924581981483811, 4.637709009677323],
            [-60.929863921603584, 4.591873074948438],
            [-60.951663910719361, 4.556945013766537],
            [-60.968335851457965, 4.538336015198567],
            [-60.987090862779638, 4.51930909493727],
            [-61.052508935037821, 4.507782134020118],
            [-61.163890849867414, 4.494309062818218],
            [-61.313608907719754, 4.506664155771475],
            [-61.4922267619732, 4.406391112527245],
            [-61.507363976236149, 4.390554009347355],
            [-61.512781870825819, 4.361945064986898],
            [-61.507363976236149, 4.327364182235542],
            [-61.510699806071358, 4.303473075909722],
            [-61.524035749336775, 4.274864131549535],
            [-61.554381926954022, 4.248818037911221],
            [-61.625281931542332, 4.244235986708574],
            [-61.662781895901674, 4.255282161658243],
            [-61.699172934467981, 4.259027028369374],
            [-61.762917811209434, 4.243053970719487],
            [-61.790564010168339, 4.215691080088831],
            [-61.8115268147927, 4.184864116503917],
            [-61.848890824682144, 4.160553915018198],
            [-61.914863946570136, 4.146945056984649],
            [-61.95201790596542, 4.156809048307068],
            [-61.987499843310843, 4.169582062953751],
            [-62.053199882792455, 4.150136047532357],
            [-62.079308840704641, 4.124864106473595],
            [-62.104790832257663, 4.103891075927365],
            [-62.142646021674551, 4.093191073579931],
            [-62.180972944603724, 4.099582107131013],
            [-62.220835767474853, 4.112500128703005],
            [-62.262508913798911, 4.127218080167964],
            [-62.281390827134999, 4.136109100167133],
            [-62.321108810718897, 4.152500079510531],
            [-62.392226912428441, 4.179445048447846],
            [-62.440135859456404, 4.182673087007856],
            [-62.539935828083998, 4.112291084037253],
            [-62.604164005363884, 4.041944955612379],
            [-62.66986387720749, 4.046809141676803],
            [-62.728335866284681, 4.038609125777356],
            [-62.757781995136469, 4.020273209615596],
            [-62.780417827639155, 3.90874494675613],
            [-62.777226837091462, 3.882500034373933],
            [-62.758335871299977, 3.834717989360655],
            [-62.740145967891834, 3.790554076681914],
            [-62.727499855259651, 3.731109116281317],
            [-62.727226772853442, 3.705282125592731],
            [-62.734026843280645, 3.676527000840665],
            [-62.751399846402791, 3.638336032381503],
            [-62.781255012130657, 3.604309025793469],
            [-62.878054929965401, 3.560136060659318],
            [-62.90306401453978, 3.562918014331103],
            [-62.92361778228792, 3.567218098310136],
            [-62.990563875499049, 3.604309025793469],
            [-63.164172872039131, 3.795282140638264],
            [-63.242290868747943, 3.898127087059947],
            [-63.293963960864403, 3.939373091596991],
            [-63.335554964537124, 3.958054006894415],
            [-63.359454955680263, 3.965836101100436],
            [-63.382508877514567, 3.970836073996594],
            [-63.419099908291116, 3.967082154829967],
            [-63.43361786754582, 3.941664201017488],
            [-63.438754968378362, 3.915135980307213],
            [-63.445555038805452, 3.890282128579955],
            [-63.458199810333269, 3.866454054166283],
            [-63.491526928004163, 3.857782137116757],
            [-63.53013581815685, 3.867217980823852],
            [-63.547917858155188, 3.880691052025668],
            [-63.573617779191181, 3.909718085717358],
            [-63.589172915147742, 3.928335969102818],
            [-63.614172947266695, 3.9448640763827],
            [-63.644454919505421, 3.948609110731823],
            [-63.848063921060515, 3.958891023747753],
            [-63.909026844130381, 3.933053974775362],
            [-63.940563922554134, 3.904582158351474],
            [-63.956255012980591, 3.89124504161957],
            [-63.99416401421604, 3.882918123705622],
            [-64.017790922953083, 3.886109114253415],
            [-64.052708925851078, 3.90843598980419],
            [-64.064590944187785, 3.955282111506534],
            [-64.087781993958373, 4.032773141856168],
            [-64.10889097897433, 4.082782090911593],
            [-64.126390884110975, 4.109582052923329],
            [-64.22111778964657, 4.141109073063291],
            [-64.248899943075202, 4.148053982777796],
            [-64.335554908192137, 4.154164222571666],
            [-64.379863995434164, 4.153609005303679],
            [-64.402235797986179, 4.14916408203743],
            [-64.430281981365709, 4.140000147270143],
            [-64.46750886331904, 4.133891080942661],
            [-64.552926827161855, 4.122364120025509],
            [-64.59201784438639, 4.127773129797859],
            [-64.607790909826093, 4.145273202572497],
            [-64.64215503656078, 4.223773078791112],
            [-64.651954990142826, 4.239164097082963],
            [-64.674726777115353, 4.257500013244623],
            [-64.693072919198926, 4.267217991813425],
            [-64.72417296518995, 4.281391119570529],
            [-64.74626397398481, 4.28721805103622],
            [-64.781672988772186, 4.286391092466687],
            [-64.800008904933861, 4.265073062784808],
            [-64.801955015218709, 4.210835946479818],
            [-64.785908867372882, 4.185836081998843],
            [-64.733890943758837, 4.161245086755997],
            [-64.713135842695777, 4.144026981204888],
            [-64.704726782130479, 4.118609027392409],
            [-64.69445492739834, 4.081945074057899],
            [-64.660981964611921, 4.016664129736284],
            [-64.642090831182259, 3.98430914519831],
            [-64.626681875617578, 3.965553966238659],
            [-64.518063977186785, 3.854444966177169],
            [-64.446326788107058, 3.787009034542962],
            [-64.405290834064147, 3.767218019986004],
            [-64.365845932886458, 3.75055395823631],
            [-64.308063942080594, 3.718336101634719],
            [-64.190217903503878, 3.589653938602396],
            [-64.182372945024071, 3.549308988659391],
            [-64.184717866262844, 3.527782081949923],
            [-64.193481984247683, 3.501391156814023],
            [-64.224381870390118, 3.466945055065992],
            [-64.235699954279596, 3.432782094008232],
            [-64.22667297981053, 3.337500138842842],
            [-64.205290912388278, 3.193336097688174],
            [-64.158890875574258, 3.060835977748951],
            [-64.146117860927575, 3.039718107915675],
            [-64.088899972207031, 2.940835957688307],
            [-64.034726893642471, 2.832782161342791],
            [-63.987854955678216, 2.718609072747839],
            [-63.995417779296503, 2.623054035176324],
            [-64.017226820867762, 2.573891155429834],
            [-64.031254941699387, 2.5497180818804],
            [-64.046463905053798, 2.509445048666677],
            [-64.034035889542679, 2.471318117947959],
            [-63.821808950394711, 2.425836067172241],
            [-63.782199931552299, 2.427164095915032],
            [-63.581672955803413, 2.434718034715914],
            [-63.365417841616051, 2.420000083250955],
            [-63.346390921354555, 2.406109090355628],
            [-63.360763873683936, 2.259100063617311],
            [-63.380008891066495, 2.229164096342544],
            [-63.399446021670457, 2.149509026225076],
            [-63.488335769818377, 2.130554022693062],
            [-63.545417871707002, 2.130000146529639],
            [-63.607090909615977, 2.105973085733922],
            [-63.718608946553559, 2.023609152140878],
            [-63.739172940223511, 2.003054043288174],
            [-63.825008825759824, 1.977782102229227],
            [-63.936526862697576, 1.976809130906162],
            [-63.970555042751926, 1.96721805435169],
            [-64.002364030115587, 1.949864161968847],
            [-64.030146015906155, 1.924718117096177],
            [-64.05189085209895, 1.890482065842292],
            [-64.052372979170826, 1.840836053195446],
            [-64.049863940267443, 1.806809046607412],
            [-64.062781961839335, 1.681108997095293],
            [-64.07799092519366, 1.635418069291902],
            [-64.113208832588185, 1.58291801860581],
            [-64.264454911393585, 1.476109102523353],
            [-64.365773010328496, 1.507573090751265],
            [-64.394308864492928, 1.511809136990024],
            [-64.529726892573734, 1.433608997629761],
            [-64.54903578005883, 1.415135953531617],
            [-64.563199855360352, 1.391391027597678],
            [-64.57896403598258, 1.349791139107467],
            [-64.595290977585535, 1.330000124550509],
            [-64.697508957647926, 1.262782122399472],
            [-64.73174601473022, 1.246945019219311],
            [-64.757235885272138, 1.244309078301328],
            [-64.783063881789047, 1.260000168727771],
            [-64.81907287320746, 1.279482058694811],
            [-64.943072905112814, 1.210553980653103],
            [-65.006954909790551, 1.165836024172947],
            [-65.10389094973317, 1.142082045783695],
            [-65.136264039181981, 1.118473074319482],
            [-65.146526841458694, 1.097635998243348],
            [-65.153899898788609, 1.055553982681175],
            [-65.154446063600915, 1.035282182156735],
            [-65.16084598196943, 0.989026984629945],
            [-65.165845954865574, 0.969445014738653],
            [-65.173890905555822, 0.950000005145753],
            [-65.192508956579189, 0.926664116087835],
            [-65.227646062426942, 0.915000027234299],
            [-65.253064016239421, 0.915282162095906],
            [-65.291464029364334, 0.926600078347391],
            [-65.321672911407319, 0.911944991156318],
            [-65.385845935764166, 0.837082190373806],
            [-65.395008864703158, 0.818609146275847],
            [-65.398346035642646, 0.789027062954062],
            [-65.400281920005511, 0.753953994846853],
            [-65.436108856486499, 0.695282181197399],
            [-65.485772974044323, 0.661873088513062],
            [-65.521663948265598, 0.649164111606908],
            [-65.542363896405618, 0.651664098055079],
            [-65.558617915450725, 0.666109134751821],
            [-65.575563944424033, 0.696391106990546],
            [-65.582572891878897, 0.729791147219402],
            [-65.567508935449894, 0.764718034934916],
            [-65.527372862534378, 0.806664096027006],
            [-65.511117837660862, 0.838891172722171],
            [-65.506955049256135, 0.871809085878979],
            [-65.510135813882044, 0.901945045364002],
            [-65.522090922414691, 0.926945077482955],
            [-65.539735834476659, 0.950000005145753],
            [-65.55944604748683, 0.971108990161611],
            [-65.595426875710444, 0.990418045284699],
            [-65.65722681563372, 0.989717988729566],
            [-65.735290832885909, 0.982782131470557],
            [-65.870835762981216, 0.908336078914999],
            [-65.912781991711483, 0.871809085878979],
            [-65.929035843118584, 0.854027045880642],
            [-65.95514597449727, 0.818336063869552],
            [-65.979590956986414, 0.798745041522949],
            [-66.12709082797619, 0.734164153756197],
            [-66.286955012012641, 0.747782064245413],
            [-66.314726771881425, 0.75139114412481],
            [-66.338054949588212, 0.765836013183474],
            [-66.787226892779785, 1.149445044829719],
            [-66.870455000593012, 1.220927088775966],
            [-66.875072926341289, 1.225900071943983],
            [-66.89168200280605, 1.251391115952217],
            [-66.899035781758471, 1.284100152081265],
            [-66.926390961038209, 1.459718123179854],
            [-66.991672911188488, 1.69583600101565],
            [-67.113235820919613, 2.026653962296876],
            [-67.160572954511224, 2.132500132977711],
            [-67.175290905976169, 2.153336035587557],
            [-67.198626795034102, 2.207500061696777],
            [-67.211535764150597, 2.243609133039385],
            [-67.216945947389348, 2.275282165932865],
            [-67.207508930215852, 2.294864135824341],
            [-67.18459096285153, 2.31610907531001],
            [-67.174181980182937, 2.336527056226515],
            [-67.1925088438893, 2.392500064684015],
            [-67.219517850566973, 2.413127090266002],
            [-67.257926916147284, 2.42902705772012],
            [-67.2813999331415, 2.438891049042539],
            [-67.325208956038864, 2.47402698142389],
            [-67.48556398432649, 2.653336007415149],
            [-67.535281913702818, 2.680000014957159],
            [-67.58973578602459, 2.764164213719496],
            [-67.605835913327041, 2.793336086698602],
            [-67.63444603115363, 2.809444931180224],
            [-67.765835884195212, 2.832500026481185],
            [-67.823617875000991, 2.827082131891331],
            [-67.833072997085566, 2.876664106797918],
            [-67.65668187923572, 3.042782196448982],
            [-67.499999968567835, 3.190654056302563],
            [-67.435563920088583, 3.253891157348306],
            [-67.369735805125998, 3.304718012517952],
            [-67.34639991606798, 3.313682122712876],
            [-67.292854977329284, 3.396045050477795],
            [-67.306599957470894, 3.452782153231041],
            [-67.341535897641734, 3.466526965734488],
            [-67.362917797425894, 3.473053953755297],
            [-67.381117926755735, 3.48597314879386],
            [-67.395573021736368, 3.512500028399671],
            [-67.428345927967968, 3.608054060142777],
            [-67.437090935213234, 3.648335978174003],
            [-67.491045916656617, 3.724309045854298],
            [-67.511672942238789, 3.737982109266454],
            [-67.541954914477515, 3.736527010871086],
            [-67.576954892388784, 3.73236422246643],
            [-67.599872859753191, 3.740691140380207],
            [-67.615981871872975, 3.759027056541967],
            [-67.63514592007084, 3.797635946694641],
            [-67.68055504828871, 3.921391059388398],
            [-67.692781898123002, 3.96971809574795],
            [-67.70850886309492, 4.046953980963949],
            [-67.72917293668911, 4.087500096584137],
            [-67.786672960271375, 4.166527026875769],
            [-67.806254930162652, 4.231809144664126],
            [-67.805555041245611, 4.272782066794974],
            [-67.80126400972199, 4.300136072608311],
            [-67.784799940182552, 4.334754003371884],
            [-67.858754980848744, 4.561244930107563],
            [-67.824717915976805, 4.736664082462468],
            [-67.816655028013741, 4.801627017376745],
            [-67.813599824297853, 4.831153947775405],
            [-67.80158185149125, 4.973236091813419],
            [-67.802781972391244, 5.039445080457398],
            [-67.82028187752789, 5.093336024160337],
            [-67.829726773690481, 5.131391038149587],
            [-67.848635844392874, 5.30651799735891],
            [-67.803890898184477, 5.383264211790092],
            [-67.769172887496637, 5.406809145513861],
            [-67.747655033242665, 5.412209102830715],
            [-67.688335801390167, 5.448609026214427],
            [-67.649445949842288, 5.478335948823258],
            [-67.615908949315212, 5.548191065358751],
            [-67.633755027054093, 5.578264160569816],
            [-67.642226784255172, 5.612218076961909],
            [-67.648899952667875, 5.652218027769521],
            [-67.651673021522242, 5.683191171745875],
            [-67.642926840810304, 5.73139113845302],
            [-67.6199998209905, 5.79221810705279],
            [-67.597072968808675, 5.824691108787846],
            [-67.531872993671755, 5.891327071580847],
            [-67.478926858097537, 5.941600050586885],
            [-67.461726857457421, 5.955535970483524],
            [-67.426254978395889, 5.976727098150732],
            [-67.413981860456005, 5.995536088929128],
            [-67.434990933185844, 6.038645055271189],
            [-67.46958187422112, 6.069664132077165],
            [-67.48556398432649, 6.09778206454807],
            [-67.492526831313739, 6.121409140923092],
            [-67.489245986763763, 6.153745014721764],
            [-67.454455053518132, 6.19305396142957],
            [-67.472608914742295, 6.19445407453982],
            [-67.496263986674023, 6.205418106838053],
            [-67.534454955133185, 6.24305402566749],
            [-67.563617943294958, 6.262500041088884],
            [-67.635281874540738, 6.285136041229663],
            [-67.693346000208294, 6.293053922267418],
            [-67.831181872085864, 6.307573054988595],
            [-67.918126851053415, 6.238191180707716],
            [-67.957508887957147, 6.217782084608729],
            [-67.997926928096263, 6.207218036731192],
            [-68.035281885530225, 6.205554061308135],
            [-68.066117901570465, 6.213335987875993],
            [-68.103617865930005, 6.221527118957852],
            [-68.132235862745773, 6.22360901607442],
            [-68.155155003576652, 6.222773172687553],
            [-68.194172930605248, 6.210836001427907],
            [-68.229735837135536, 6.198891118817244],
            [-68.295290869691826, 6.175836023516453],
            [-68.319454890785863, 6.168053929310616],
            [-68.374308915166324, 6.179718018163967],
            [-68.41930883886991, 6.19180908116634],
            [-68.453899947543249, 6.190553974981498],
            [-68.476963927661359, 6.186391186576842],
            [-68.549999976460754, 6.164445017069212],
            [-68.638408938641192, 6.135482188755944],
            [-68.6861178934588, 6.141527049704806],
            [-68.712017806705148, 6.157009095465412],
            [-68.753617862833266, 6.169444989965541],
            [-68.827790832982714, 6.186391186576842],
            [-69.025835759556344, 6.214864176467131],
            [-69.056399866656761, 6.21610905673036],
            [-69.087926886796708, 6.203054074859878],
            [-69.11557291811755, 6.179164142000545],
            [-69.153335906599409, 6.141109128011294],
            [-69.19520887749573, 6.100418005466054],
            [-69.241746042246035, 6.084100116318595],
            [-69.270708870559474, 6.090973109303462],
            [-69.3007088755746, 6.122773211849704],
            [-69.316117831139167, 6.148609087355709],
            [-69.371254996209814, 6.145836018501342],
            [-69.404172909366451, 6.130836099812868],
            [-69.429454908709118, 6.118609082340413],
            [-69.724726894904478, 6.486664151496683],
            [-70.119172889196363, 6.975836045495768],
            [-70.158482003542275, 6.982500161452961],
            [-70.221817843407706, 6.974027063147318],
            [-70.243345923583746, 6.960835959169117],
            [-70.26229992128728, 6.945691033555065],
            [-70.294726822554708, 6.938473209072612],
            [-70.323626786593991, 6.945000029455443],
            [-70.34389992822301, 6.95250015643785],
            [-70.360835898912427, 6.963891162885091],
            [-70.386126783072399, 6.983054037616384],
            [-70.441681869836401, 7.011109105813148],
            [-70.552508902674248, 7.058336101197057],
            [-70.719726865663233, 7.098053917142892],
            [-70.819454917561274, 7.087500095187153],
            [-70.8575089257223, 7.082500122290995],
            [-70.886535791776026, 7.075073085504343],
            [-70.908199826421878, 7.048191148479347],
            [-70.970835777370183, 7.008336036958966],
            [-71.005281879118115, 6.992773189651572],
            [-71.025008855934715, 6.984444930633146],
            [-71.181263960091769, 6.963473073553402],
            [-71.26243581941219, 6.987427044153364],
            [-71.332508865430668, 7.022364157790676],
            [-71.463899891938667, 7.02333595564744],
            [-71.48806391303259, 7.028891145811485],
            [-71.553072948414666, 7.045000157931455],
            [-71.601390932318623, 7.057918011865368],
            [-71.645290982684571, 7.060553952783351],
            [-71.701954995241692, 7.046391050948216],
            [-71.804663987193464, 7.003127019396956],
            [-71.83278191966437, 6.987217999487513],
            [-71.876872909785334, 6.986526995387891],
            [-71.926799883827329, 7.00974503488672],
            [-71.992363968839214, 7.016245033179388],
            [-72.053799966525617, 7.062145005648631],
            [-72.06609990655565, 7.062418088054741],
            [-72.092508936602371, 7.103609107306937],
            [-72.113346012678676, 7.13833600281211],
            [-72.132926809103509, 7.17333598072338],
            [-72.145281902056581, 7.198609095248457],
            [-72.164599841997074, 7.262500152381875],
            [-72.164035907549845, 7.289444953681027],
            [-72.154726798219173, 7.325282116083812],
            [-72.262508853262943, 7.389445082157039],
            [-72.323899923948204, 7.393053994398443],
            [-72.397763937145641, 7.406426985675992],
            [-72.401672921521367, 7.40721806969988],
            [-72.419308948766002, 7.420554012965482],
            [-72.472446024095262, 7.497982179040989],
            [-72.47348185969247, 7.656245084290262],
            [-72.459726821267139, 7.920553984792903],
            [-72.389381866308753, 8.047564125774983],
            [-72.345835867533879, 8.047709132700206],
            [-72.328617929620748, 8.061809002623392],
            [-72.325008849741351, 8.089027053966817],
            [-72.336672938594887, 8.151944972138651],
            [-72.376108954955271, 8.336944975126045],
            [-72.388199850319666, 8.369444966589242],
            [-72.401735785795523, 8.384164091520589],
            [-72.405335813219324, 8.385926973401411],
            [-72.423064041399101, 8.389718108218162],
            [-72.664464026992846, 8.641109154786889],
            [-72.736954916808145, 8.917218098612793],
            [-72.779726930641658, 9.08027310555903],
            [-72.885563881229075, 9.119164130573324],
            [-72.96250891440431, 9.178054041344112],
            [-72.958345958361491, 9.201109136644718],
            [-72.981126797789699, 9.260827011813348],
            [-73.009999939738663, 9.302009146248039],
            [-73.143545953559169, 9.223891149539213],
            [-73.165635788887556, 9.195764164612982],
            [-73.221954969947092, 9.171109131629592],
            [-73.345563902249069, 9.166391125957048],
            [-73.378063893712351, 9.171391098853206],
            [-73.363890933593325, 9.225827033901894],
            [-73.245146019717708, 9.408473063194918],
            [-73.117508912376337, 9.561391164050278],
            [-73.081681975895435, 9.609444950365557],
            [-73.004726884436309, 9.768327110623162],
            [-72.986463890832397, 9.904926987055077],
            [-72.977508833092884, 10.001945002010885],
            [-72.956535802546654, 10.082082199200485],
            [-72.938899942940196, 10.11645403728609],
            [-72.91500883661439, 10.350554055745761],
            [-72.908535828050134, 10.426691073452602],
            [-72.903545913437682, 10.444444950256141],
            [-72.836672910422493, 10.554445024524469],
            [-72.810008902880384, 10.59055392822907],
            [-72.704726834284685, 10.770553958319965],
            [-72.633617785030623, 10.896391135768454],
            [-72.540008857743771, 11.031944950681009],
            [-72.491255014873389, 11.122773097762547],
            [-72.472090966675609, 11.134717980373225],
            [-72.349872927390493, 11.163473105125462],
            [-72.317646018333505, 11.164509108360662],
            [-72.20935501412734, 11.250000162399502],
            [-72.072726806862732, 11.495509124579243],
            [-71.968481914968805, 11.666244972340763],
            [-71.769726873556237, 11.700836081014018],
            [-71.721681972058377, 11.721945066030145],
            [-71.626399849254994, 11.755553983286489],
            [-71.468064021447788, 11.795553934094102],
            [-71.403335779823124, 11.812773213111697],
            [-71.324717886203672, 11.85305395783827],
            [-71.318632168, 11.851975502000215],
            [-71.296065792999883, 11.84313613900008],
            [-71.277804204999967, 11.835982982000019],
            [-71.260494652999853, 11.82864523800022],
            [-71.24506657500001, 11.822060082000178],
            [-71.029623935999837, 11.703766354999971],
            [-70.993728641999837, 11.674460233000246],
            [-70.948840645999979, 11.633227236000025],
            [-70.936406010999917, 11.621805141500104],
            [-70.933580136000046, 11.622823252000188],
            [-70.893195898999863, 11.636901895000037],
            [-70.885694191999931, 11.640333375000097],
            [-70.873388251999927, 11.645922286000228],
            [-70.866827541999868, 11.648901923000125],
            [-70.851750395999915, 11.656701950000055],
            [-70.807044613000016, 11.679830089000106],
            [-70.758309639, 11.705042694000142],
            [-70.749267743999894, 11.753212775000051],
            [-70.747105577999974, 11.764038674000062],
            [-70.746124343999867, 11.768951680000143],
            [-70.742631368999866, 11.786440898],
            [-70.742239174000019, 11.78838232300015],
            [-70.731174352999858, 11.839242625000153],
            [-70.729133074999908, 11.848625514000148],
            [-70.728283030999904, 11.852529546000127],
            [-70.728141639999848, 11.853162128000164],
            [-70.727233112999897, 11.85722686400004],
            [-70.726861572999894, 11.858826306500077],
            [-70.726722915999972, 11.859428857000239],
            [-70.725955935999849, 11.862761835000143],
            [-70.710485624999961, 11.929102410000155],
            [-70.707969489999954, 11.939892229000208],
            [-70.702353356999879, 11.963975614999981],
            [-70.702114332999912, 11.964946324000096],
            [-70.700874348999889, 11.971454568000169],
            [-70.698783687999935, 11.982890792000148],
            [-70.697748740999828, 11.988552103000231],
            [-70.694608899333247, 12.005727491000144],
            [-70.765094989999909, 12.051089860000189],
            [-70.76999426299983, 12.054245712000181],
            [-70.774382140999876, 12.05712691],
            [-70.775104945999828, 12.057601523000102],
            [-70.776322486999845, 12.058403079000172],
            [-70.814356063999952, 12.083503456000159],
            [-70.821905374999915, 12.089077047000089],
            [-70.823295155999972, 12.090103111000076],
            [-70.836442360999854, 12.099837146000155],
            [-70.910723962999839, 12.154834365000241],
            [-70.914904617999895, 12.157882730000139],
            [-70.923624215999894, 12.164240707000133],
            [-70.926167605000018, 12.166121562000058],
            [-70.939437995999867, 12.176074304500077],
            [-70.954119119499865, 12.187087022000128],
            [-70.955582184999912, 12.188196575],
            [-70.972319339999899, 12.201809748000073],
            [-70.979716903999929, 12.207750670000109],
            [-70.982967540999852, 12.21065696100014],
            [-70.984948358999986, 12.212490086000201],
            [-70.987823269999893, 12.215150640000246],
            [-70.990992020999897, 12.218151573000128],
            [-70.992295011999886, 12.219385557000265],
            [-70.99403457799994, 12.221132134000271],
            [-70.998307575999888, 12.225473183000176],
            [-71.010355339999933, 12.237712816000098],
            [-71.013964691999973, 12.242772076000193],
            [-71.014622570999848, 12.243694230000116],
            [-71.041800502999877, 12.281127276000078],
            [-71.045271414000013, 12.286012798000186],
            [-71.04742320399987, 12.289417783000104],
            [-71.049872282000024, 12.293743062000132],
            [-71.058368111999869, 12.311572732000101],
            [-71.060664333999966, 12.316472221000026],
            [-71.061544832499862, 12.318481935000136],
            [-71.061864765, 12.319223165000096],
            [-71.074422575999961, 12.378000798000159],
            [-71.080721360499894, 12.40748839800014],
            [-71.099239102999917, 12.434683432000156],
            [-71.099772332999891, 12.435496574000112],
            [-71.116121390999922, 12.463272853000177],
            [-71.127112860999858, 12.481949582000155],
            [-71.133954950999851, 12.495473790000048],
            [-71.134840539000038, 12.497203674000176],
            [-71.136082139999985, 12.499628987000037],
            [-71.158176434999859, 12.54823637400024],
            [-71.159035863999918, 12.550203905000231],
            [-71.161267295999892, 12.555312428999969],
            [-71.163220053, 12.559782969000025],
            [-71.172174615999893, 12.582160975000093],
            [-71.17981760699999, 12.601261284000117],
            [-71.182075841999875, 12.606971402000241],
            [-71.184043951999911, 12.611947917000151],
            [-71.188130554999901, 12.622521446000064],
            [-71.19404813899996, 12.637832388000177],
            [-71.195623411999918, 12.641908193000177],
            [-71.205001455999849, 12.666421851000194],
            [-71.212184465999911, 12.685951264000025],
            [-71.220180283999866, 12.707690565000092],
            [-71.225980054999866, 12.723459179000059],
            [-71.22844497799997, 12.732346184000235],
            [-71.231184229999968, 12.742222253000165],
            [-71.232440398999984, 12.746751229000083],
            [-71.236525441000026, 12.761630586000109],
            [-71.245369334999907, 12.79384358100009],
            [-71.248355045000039, 12.804718729],
            [-71.250427966999951, 12.819474981000226],
            [-71.252753192999876, 12.836027275000191],
            [-71.256065313000022, 12.859604848000131],
            [-71.257672764999882, 12.87071071900003],
            [-71.258803020999949, 12.878519646000171],
            [-71.259234914999979, 12.881503599999974],
            [-71.265078958999823, 12.922506232000245],
            [-71.265159781499904, 12.923073293500124],
            [-71.267295280999974, 12.938268551000135],
            [-71.269105318999891, 12.951147969000147],
            [-71.271273686, 12.968632190000037],
            [-71.273668705, 12.987943979000164],
            [-71.276319042999916, 13.009314489000175],
            [-71.288687430999914, 13.133734902000157],
            [-71.298768772999864, 13.235148660000192],
            [-71.319711167999913, 13.44581971500007],
            [-71.35353472949987, 13.816676921000152],
            [-71.364093717999879, 13.93245056000022],
            [-71.369362804499872, 14.064483032500064],
            [-71.405277893199894, 14.964444014400144],
            [-70.880555565999828, 15.03555636600008],
            [-70.135832564999987, 15.263889366000072],
            [-69.938332565, 15.317778366000127],
            [-69.697221564999836, 15.379167365999976],
            [-69.577222564999943, 15.413333366000174],
            [-70.416666565999861, 12.816666364000184],
            [-70.416666565999861, 12.350000363000106],
            [-70.164166564999903, 12.350000363000106],
            [-70.140277564999877, 12.365000363000163],
            [-69.73916656499992, 12.26277736300014],
            [-69.547519004999941, 12.151386284000054],
            [-69.079166563999934, 11.879166363000053],
            [-68.954166563999877, 11.758333363000162],
            [-68.829166563999905, 11.741666363000022],
            [-68.599999563999916, 11.666666363000104],
            [-68.390654905999924, 11.666666363000104],
            [-67.989722562999987, 11.666666363000104],
            [-67.989722562999987, 12.450000364000061],
            [-68.591410218999954, 14.374787796],
            [-68.862222564, 15.241111366000027],
            [-68.481888564, 15.212361365999968],
            [-68.475387563999988, 15.210968366000074],
            [-68.458555563999965, 15.207361365999972],
            [-68.45028855899983, 15.206707347000147],
            [-68.447520432000033, 15.206488433000132],
            [-68.430340975999854, 15.205128666000078],
            [-68.413161708999894, 15.20376760600017],
            [-68.40204833899989, 15.202886278000094],
            [-68.39598272499984, 15.202405254000084],
            [-68.389648416999847, 15.201902439000136],
            [-68.37880393, 15.201041609000143],
            [-68.361625417999903, 15.199676580000258],
            [-68.348572502000025, 15.198638382000141],
            [-68.344447094999907, 15.198310258000177],
            [-68.340586301999849, 15.198002885999969],
            [-68.327268960999845, 15.196942643000099],
            [-68.310091014999898, 15.195573734999968],
            [-68.292913352999875, 15.194203535000113],
            [-68.287869971999839, 15.193800830000114],
            [-68.275735974999861, 15.192831951000144],
            [-68.258558689999973, 15.191459165000069],
            [-68.241381689, 15.190084995000049],
            [-68.226172439000038, 15.188867006999985],
            [-68.224204876999863, 15.188709440000011],
            [-68.207028347999852, 15.187332685000044],
            [-68.18985200799986, 15.185954636000176],
            [-68.1803033569999, 15.185187774000141],
            [-68.172675856999945, 15.184575203000165],
            [-68.160463529999987, 15.183593483000109],
            [-68.155499989, 15.183194476000224],
            [-68.154369337999867, 15.183100194000062],
            [-68.148298155999896, 15.182593930000095],
            [-68.131126914999868, 15.181161105000129],
            [-68.11395595699986, 15.179726986000119],
            [-68.101272332999883, 15.178666614000136],
            [-68.096785187999984, 15.178291481000187],
            [-68.07961470199993, 15.176854774000233],
            [-68.062444404999979, 15.17541668200009],
            [-68.061551717999919, 15.175237860000024],
            [-68.044673474999883, 15.171855523000218],
            [-68.040006222999892, 15.170919851999983],
            [-68.027795704999903, 15.168471939000213],
            [-68.010918594999879, 15.165087107000105],
            [-67.994041957999883, 15.161700937000077],
            [-67.981599607999897, 15.159203538000114],
            [-67.977165886999956, 15.158313612000015],
            [-67.9715533319999, 15.157186596000017],
            [-67.960290287999868, 15.154924948000129],
            [-67.943415255999923, 15.151535036000141],
            [-67.926540789999962, 15.148143877000123],
            [-67.911319720999899, 15.145083781000054],
            [-67.90966679600001, 15.144751471000106],
            [-67.892793463999851, 15.141357726000038],
            [-67.875920602999912, 15.137962825000088],
            [-67.859048214999916, 15.134566584000098],
            [-67.850078925999867, 15.132760423999954],
            [-67.842176486999904, 15.131169097000182],
            [-67.828611085999881, 15.128436330000127],
            [-67.825305232, 15.127770361000174],
            [-67.80843444899989, 15.124370378000052],
            [-67.805543250000028, 15.123787476000231],
            [-67.791564326999918, 15.1209691480001],
            [-67.783552042999986, 15.119353087999954],
            [-67.774694676999871, 15.117566578000108],
            [-67.757825498999978, 15.114162851000131],
            [-67.740956981999858, 15.110757786000036],
            [-67.724088936999891, 15.107351472000104],
            [-67.722971947000019, 15.107125818000085],
            [-67.707221459000039, 15.103943910999973],
            [-67.69035454699997, 15.100535102000109],
            [-67.673488107000026, 15.097125044000038],
            [-67.667696909999847, 15.09595371200011],
            [-67.656622233999855, 15.093713739000137],
            [-67.64594566699995, 15.0915534250001],
            [-67.639756927999827, 15.090301185000229],
            [-67.624169237999922, 15.087145820000089],
            [-67.622892092999848, 15.086887292000128],
            [-67.621114115999916, 15.086527248000166],
            [-67.606027824999899, 15.08347224200007],
            [-67.596404859999865, 15.081522031],
            [-67.582426079999834, 15.078688206000109],
            [-67.579541819000042, 15.078103499000136],
            [-67.562679250999963, 15.074683719000163],
            [-67.545817249000038, 15.071262691000257],
            [-67.531703935, 15.068398125000115],
            [-67.528955719999885, 15.067840322000151],
            [-67.52622373799997, 15.067285608000262],
            [-67.512094756999858, 15.064416797000135],
            [-67.503691475999915, 15.062709832000095],
            [-67.495234359999898, 15.060991931000089],
            [-67.478374529999968, 15.0575659080002],
            [-67.4676619009999, 15.055388123000043],
            [-67.461515172, 15.054138545000129],
            [-67.454845797999894, 15.05278217900026],
            [-67.444656380999959, 15.050709934000125],
            [-67.427798156, 15.047280165000117],
            [-67.410940497999889, 15.043849056000184],
            [-67.403576448999871, 15.0423496330002],
            [-67.394083311999907, 15.040416698000044],
            [-67.386071884999978, 15.038803594000072],
            [-67.369207522999858, 15.035406850000143],
            [-67.352343632999862, 15.032008859000101],
            [-67.33548030899982, 15.028609620000125],
            [-67.327035030999866, 15.026906537000073],
            [-67.31861745799992, 15.025209041000224],
            [-67.301755414999974, 15.021807336000123],
            [-67.284893453999871, 15.018404323000127],
            [-67.268032301999938, 15.015000092000108],
            [-67.259871382999989, 15.013351770000142],
            [-67.251171622999834, 15.011594614000231],
            [-67.23431151, 15.008187795000069],
            [-67.217451867999898, 15.004779820000195],
            [-67.200592794000045, 15.001370597000104],
            [-67.193845798999916, 15.000005645000059],
            [-67.183734285999861, 14.997960034000087],
            [-67.166876453999919, 14.994548340000151],
            [-67.150018875, 14.991135346000121],
            [-67.133161971999868, 14.987721038000146],
            [-67.122051696999961, 14.985469865000127],
            [-67.116305541999964, 14.984305574000174],
            [-67.10806978200003, 14.982288625000095],
            [-67.1026944729999, 14.980972205000043],
            [-67.087694423000016, 14.977361118000132],
            [-67.071583282999967, 14.972361065000072],
            [-67.068266843999908, 14.97174655400012],
            [-67.063669868999881, 14.970894769000253],
            [-67.055480035999921, 14.96937674700014],
            [-67.046757160999931, 14.967759923000116],
            [-67.029845019999925, 14.964623930000172],
            [-67.01905462000002, 14.962622188000111],
            [-67.012933256999872, 14.961486605000218],
            [-67.005930947999872, 14.960187040000235],
            [-66.996022059999973, 14.958348040000132],
            [-66.979111335999903, 14.955208235000143],
            [-66.962201083, 14.952067099000132],
            [-66.957736636999897, 14.951237487000228],
            [-66.945291302999919, 14.948924814000108],
            [-66.92838232500003, 14.945781329000155],
            [-66.91147325299994, 14.942636433000175],
            [-66.902430790000039, 14.940953915000065],
            [-66.894564982999896, 14.93949033600019],
            [-66.877657279999823, 14.936342999000104],
            [-66.860749904499926, 14.933194421000096],
            [-66.850022676999856, 14.933013370500092],
            [-66.83281094799986, 14.932721971000078],
            [-66.815599104999848, 14.932429190000207],
            [-66.798387356000035, 14.932135124000197],
            [-66.791996972999925, 14.932025465000109],
            [-66.781175606000033, 14.931839771000185],
            [-66.770362749999862, 14.931653415000127],
            [-66.76396395200004, 14.931543133000147],
            [-66.746752390999973, 14.931245118000092],
            [-66.72954083099998, 14.930945908000226],
            [-66.714069416, 14.930675794000067],
            [-66.712329270999902, 14.930645413000136],
            [-66.695117804999825, 14.930343540000123],
            [-66.67757134299984, 14.930034454000193],
            [-66.660694966999898, 14.929735937000018],
            [-66.643483689999925, 14.929430299000146],
            [-66.626272412999867, 14.929123283000123],
            [-66.609061136, 14.928814889],
            [-66.591849953999969, 14.928505301000158],
            [-66.582205527999918, 14.928331100000221],
            [-66.574487217333228, 14.928385474333439],
            [-66.572583583999858, 14.930783763000207],
            [-66.56385400899984, 14.94178172400008],
            [-66.554304750000028, 14.953810435000136],
            [-66.553335145999824, 14.955031796000114],
            [-66.5517911849999, 14.956976333000071],
            [-66.542814960999976, 14.968281406000116],
            [-66.532293548999945, 14.981530463000055],
            [-66.527152531999917, 14.988003188000121],
            [-66.521770814999968, 14.994778963999963],
            [-66.51124675799997, 15.008027002000105],
            [-66.505554250999893, 15.015191835000238],
            [-66.500721473999874, 15.021274575000106],
            [-66.490194868999851, 15.034521592000075],
            [-66.479666940999891, 15.04776814300017],
            [-66.469137690999901, 15.061014227000058],
            [-66.465796762999958, 15.065216539000133],
            [-66.458607214000011, 15.074259751999975],
            [-66.455795128999853, 15.077796271000111],
            [-66.448075320999891, 15.087504809000151],
            [-66.445824070999976, 15.090335563000238],
            [-66.437542199999854, 15.100749306000253],
            [-66.430080821999866, 15.110129839000038],
            [-66.427007756999899, 15.113993333000153],
            [-66.416471991999913, 15.12723689],
            [-66.40593499900001, 15.140479884999976],
            [-66.397210542999858, 15.151442926000144],
            [-66.395396575499916, 15.153722341000091],
            [-66.384856953999929, 15.166964366000101],
            [-66.374315995999865, 15.180205852000213],
            [-66.363773621999968, 15.193446772000115],
            [-66.353230018999852, 15.206687309000259],
            [-66.348648358999924, 15.212439938500111],
            [-66.342685094999922, 15.219927279000089],
            [-66.337426389000029, 15.226528883000057],
            [-66.332138848999961, 15.233166682000117],
            [-66.329876035999945, 15.23601168500015],
            [-66.319264384, 15.2493521210002],
            [-66.314075796999958, 15.255873894000061],
            [-66.308725880999958, 15.262598448000105],
            [-66.298186055999906, 15.275844295000141],
            [-66.287644908999823, 15.289089571000048],
            [-66.279137524999896, 15.299777633000119],
            [-66.277102439999908, 15.302334366000011],
            [-66.274299765499961, 15.30585487350011],
            [-66.266558648999876, 15.315578680000201],
            [-66.256013549000016, 15.328822393500118],
            [-66.245467006999831, 15.342065676000246],
            [-66.23769197699994, 15.351827272000222],
            [-66.234919249999933, 15.355308448000116],
            [-66.224370171, 15.368550645000042],
            [-66.217907578999871, 15.376661713000047],
            [-66.213819675999957, 15.381792357000094],
            [-66.203267953999983, 15.39503349100012],
            [-66.192714814999874, 15.408274229000071],
            [-66.188163442999894, 15.413983748000149],
            [-66.182160353999961, 15.421514390000098],
            [-66.171604570999989, 15.434753970000074],
            [-66.161047466000014, 15.447993153000098],
            [-66.158120164000024, 15.451663496000108],
            [-66.150488944999836, 15.461231755000156],
            [-66.140497634999861, 15.473757163],
            [-66.139929195999912, 15.474469775000173],
            [-66.139149741999972, 15.47544676200009],
            [-66.129368030999871, 15.487707396000062],
            [-66.118805543999883, 15.5009444330001],
            [-66.114382711999866, 15.505833631000158],
            [-66.102999677999946, 15.518415634000164],
            [-66.098849170999898, 15.52300252600007],
            [-66.09161522799991, 15.530997044000102],
            [-66.08344570599985, 15.540023959000038],
            [-66.080229360999937, 15.543577860000013],
            [-66.077357981999938, 15.546750050000114],
            [-66.068842172999837, 15.556157989000226],
            [-66.060190238000018, 15.565714743000129],
            [-66.057453567999971, 15.568737615000131],
            [-66.046063547000017, 15.581316644000069],
            [-66.043455048999931, 15.584196928000168],
            [-66.034672108999928, 15.593894986000109],
            [-66.024749614999877, 15.604849539000028],
            [-66.023279349999825, 15.606472730000135],
            [-66.021536788999896, 15.608396237000207],
            [-66.011885267999872, 15.619049968],
            [-66.003645921999919, 15.62814317900019],
            [-66.000489675999887, 15.631626516000082],
            [-65.989092761999871, 15.64420246500022],
            [-65.977694431000032, 15.656777814000108],
            [-65.969547691999821, 15.661741084000184],
            [-65.96808156899985, 15.662634296],
            [-65.953457232999881, 15.671542475000152],
            [-65.950801382999856, 15.673159937000221],
            [-65.9388316699999, 15.680449712000225],
            [-65.930546006000014, 15.685494792000028],
            [-65.924204784999887, 15.689355915000206],
            [-65.909576578999861, 15.698261176000116],
            [-65.894947238999919, 15.707165402000086],
            [-65.889501944999864, 15.710479038000088],
            [-65.88031648199987, 15.716068686000014],
            [-65.8745072829999, 15.719603086000248],
            [-65.865684593, 15.724970936000105],
            [-65.85105138099982, 15.733872242000146],
            [-65.844888487999896, 15.737620324000105],
            [-65.836416847999971, 15.742772514000066],
            [-65.821781086999863, 15.751671843000096],
            [-65.807144003999923, 15.76057013700013],
            [-65.792505693999942, 15.769467487000085],
            [-65.777866062000044, 15.778363803000062],
            [-65.774296441999923, 15.780532602000093],
            [-65.763225201999944, 15.787259174000113],
            [-65.748583114999917, 15.796153511000185],
            [-65.745493920999934, 15.798029642000259],
            [-65.733939610999983, 15.805046811999986],
            [-65.719294973999922, 15.813939169000079],
            [-65.704648920999944, 15.822830582000066],
            [-65.696645713999885, 15.827688243000082],
            [-65.690001640999924, 15.831720958999981],
            [-65.675353133000016, 15.840610300000165],
            [-65.665769293000011, 15.84642504100016],
            [-65.660703302999877, 15.849498697000101],
            [-65.657242514999894, 15.851598003999968],
            [-65.646052150999822, 15.858386057000203],
            [-65.635715798999911, 15.864654876000102],
            [-65.631399770999849, 15.867272473000057],
            [-65.616746069999948, 15.876157852000105],
            [-65.612271681999914, 15.878870386000088],
            [-65.602091140999931, 15.885042195000224],
            [-65.594755039999825, 15.88948872800016],
            [-65.587434889999884, 15.893925593],
            [-65.580463975999891, 15.898149950000104],
            [-65.572777316999918, 15.902808046000146],
            [-65.558118516000036, 15.911689370000104],
            [-65.556977714999874, 15.912380412000104],
            [-65.543458393999856, 15.92056974900008],
            [-65.52879704399993, 15.92944918200017],
            [-65.51884278549997, 15.93547648600007],
            [-65.514134277999972, 15.938327486999967],
            [-65.5123584655, 15.939402544000203],
            [-65.499470378999945, 15.947204846000048],
            [-65.492518260999901, 15.951412725000139],
            [-65.484805062999953, 15.956081259000044],
            [-65.475599675000012, 15.961651848000102],
            [-65.470138519999864, 15.964956635000064],
            [-65.464005155, 15.968667436999951],
            [-65.455470654999857, 15.973830972000073],
            [-65.440801467999904, 15.982704273000067],
            [-65.43767754599989, 15.984593554000256],
            [-65.426131052999949, 15.991576626000196],
            [-65.418895614, 15.995951559999966],
            [-65.411459316999867, 16.000447942000037],
            [-65.396786352999868, 16.009318311000257],
            [-65.392447848999893, 16.011940516000053],
            [-65.382111971999848, 16.018187551000111],
            [-65.367436365, 16.027055844000145],
            [-65.358852198000022, 16.032242131000189],
            [-65.352759434999939, 16.035923189000115],
            [-65.338081276999873, 16.04478940500006],
            [-65.334365545999901, 16.047033405000107],
            [-65.323401703999849, 16.053654674000214],
            [-65.313080249999928, 16.05988680500009],
            [-65.308720902999823, 16.062518995000104],
            [-65.294038778999976, 16.071382186000093],
            [-65.279355428999907, 16.08024442900016],
            [-65.278610137999976, 16.08069415900006],
            [-65.264670661999929, 16.089105634000148],
            [-65.249984667, 16.097965799000136],
            [-65.242503491999884, 16.102478356000105],
            [-65.235297350999957, 16.106825016000229],
            [-65.22060871199983, 16.115683103000137],
            [-65.209012491999943, 16.122674952000082],
            [-65.205918845999861, 16.124540241000048],
            [-65.19122756399986, 16.133396431000065],
            [-65.176535053999942, 16.142251490000078],
            [-65.161841221999907, 16.151105601000197],
            [-65.147146314999958, 16.159958520000103],
            [-65.132449592999933, 16.168810702000073],
            [-65.117751889999852, 16.177661693000104],
            [-65.10305277, 16.186511734000234],
            [-65.088352422999975, 16.19536064399999],
            [-65.07365066, 16.204208604000044],
            [-65.058947668999906, 16.213055524000097],
            [-65.048370323999848, 16.219418686000168],
            [-65.044243355999896, 16.221901404000235],
            [-65.035736291999882, 16.227018107500058],
            [-65.029537720999855, 16.230746333000113],
            [-65.024325567499943, 16.233880579500052],
            [-65.014830763999896, 16.239590131000185],
            [-65.00012258000001, 16.248432979000157],
            [-64.994828036999877, 16.251615480000126],
            [-64.98541298, 16.257274785000234],
            [-64.970702056999869, 16.266115551000027],
            [-64.955989906999832, 16.274955275000124],
            [-64.953395643999869, 16.276513690500039],
            [-64.941276339999945, 16.283793958000089],
            [-64.930861195, 16.290049249000035],
            [-64.926561546999864, 16.292631599000188],
            [-64.919013492, 16.29716400300002],
            [-64.911845335999942, 16.301468289],
            [-64.908356587999918, 16.303562746000196],
            [-64.897127897999894, 16.3103038470001],
            [-64.882409043999928, 16.319138454000196],
            [-64.870936519999958, 16.326023150000154],
            [-64.867688961999932, 16.3279720190002],
            [-64.862083446000042, 16.331335207000123],
            [-64.852967559000035, 16.336804542000138],
            [-64.847875941999916, 16.339858715000148],
            [-64.83824473899989, 16.345635931000132],
            [-64.826432484999913, 16.352720082000175],
            [-64.823520690999857, 16.35446636900015],
            [-64.808795226999877, 16.363295765000075],
            [-64.800890194999852, 16.368034721000143],
            [-64.794068535999941, 16.372124210000024],
            [-64.785722141999941, 16.37712666400013],
            [-64.779340522999945, 16.380951521000227],
            [-64.764611092999985, 16.389777789000092],
            [-64.759515151999949, 16.392491352000164],
            [-64.758478330999878, 16.393043454000193],
            [-64.743296893, 16.401126073000242],
            [-64.737117302999849, 16.40441543100026],
            [-64.72811422699985, 16.409207715000107],
            [-64.72251363899997, 16.412188205000078],
            [-64.712930333000031, 16.417288196000172],
            [-64.697745211999916, 16.425367518000215],
            [-64.696801347999894, 16.42586959550006],
            [-64.682558767999978, 16.433445771000081],
            [-64.675896988999909, 16.436988672000027],
            [-64.667371097999961, 16.441522954000121],
            [-64.6521822, 16.449599069000087],
            [-64.63699197899993, 16.457674023000109],
            [-64.633678730999861, 16.459434914000056],
            [-64.621800531999924, 16.465747817000064],
            [-64.606607856999915, 16.47382054100008],
            [-64.591413953999989, 16.481892196000118],
            [-64.576218728999947, 16.48996269100023],
            [-64.568495980999899, 16.494063525999977],
            [-64.561022276999893, 16.498032116000061],
            [-64.548223395999884, 16.504826922000049],
            [-64.545824502999892, 16.506100472000085],
            [-64.530625501999907, 16.514167576000105],
            [-64.515425272999892, 16.522233702000122],
            [-64.50022372199993, 16.530298667000224],
            [-64.485020943, 16.538362471000042],
            [-64.469816937000019, 16.546425205000148],
            [-64.468675046000044, 16.547030621000047],
            [-64.45461160900004, 16.554486870000048],
            [-64.44492251600002, 16.559622745],
            [-64.439405052999945, 16.562547374000147],
            [-64.424197176, 16.570606717000217],
            [-64.420987211999886, 16.572307478000155],
            [-64.408988070999953, 16.578665080000107],
            [-64.393777737999926, 16.586722192000011],
            [-64.386416894999911, 16.590208942000103],
            [-64.374853648, 16.59568536400009],
            [-64.370839251999826, 16.597586605000188],
            [-64.367481686999866, 16.599176394000196],
            [-64.35526047599987, 16.604963071000171],
            [-64.339680566999959, 16.612338339000118],
            [-64.324099335999875, 16.619712410000076],
            [-64.308517065999865, 16.627085376000039],
            [-64.292933474999927, 16.634457144000251],
            [-64.28707739299989, 16.637226693000073],
            [-64.277348749999987, 16.641827715000076],
            [-64.264038194000022, 16.648121310000178],
            [-64.26176279799995, 16.649197180000201],
            [-64.253206935999913, 16.653241624000074],
            [-64.2461757129999, 16.6565653560002],
            [-64.233792718499899, 16.662417585999989],
            [-64.230587399999848, 16.663932427],
            [-64.228772605999978, 16.664789901000077],
            [-64.214997953999955, 16.671298300000089],
            [-64.211870748000024, 16.672775530000095],
            [-64.19940718599986, 16.678663067999977],
            [-64.185293978999823, 16.68532821400008],
            [-64.183815284999866, 16.686026547000225],
            [-64.182606047999911, 16.686597497000022],
            [-64.168222250999861, 16.69338892],
            [-64.16581273299991, 16.694524192000145],
            [-64.163232046999894, 16.695740020000201],
            [-64.150212521999833, 16.701873857000137],
            [-64.141318327999869, 16.706063080000078],
            [-64.134611084999932, 16.709222235000098],
            [-64.131748895999976, 16.710504007000097],
            [-64.126407375999975, 16.712895675000098],
            [-64.115999967999869, 16.717555596000111],
            [-64.112919147999975, 16.718875941],
            [-64.108222241, 16.720888891000115],
            [-64.10367718, 16.722114158000167],
            [-64.102132631999837, 16.722530539999966],
            [-64.085407645999965, 16.72703834050013],
            [-64.068681985999859, 16.731544613000167],
            [-64.051955483999848, 16.736049623000056],
            [-64.043559192999879, 16.738310218000052],
            [-64.03962363100004, 16.739369818000242],
            [-64.035228130999883, 16.740553251000094],
            [-64.018500022999916, 16.745055589000131],
            [-63.986933271999959, 16.739807270000014],
            [-63.63789652, 16.681776071999977],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "VGB", Country: "Virgin Islands, British" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-64.383482559999891, 22.081935373000192],
            [-64.358770559999925, 22.079905373000145],
            [-64.333200059999911, 22.08191087300014],
            [-64.332424559999879, 22.081931373000089],
            [-63.708782558999928, 22.029519372000209],
            [-63.706522558999893, 22.028946372000178],
            [-63.703675558999976, 22.028702372000112],
            [-63.700813558999926, 22.027976372000069],
            [-63.698569558999907, 22.027784372000156],
            [-63.299536558999876, 21.925858372000221],
            [-63.421091558999876, 21.248315372000093],
            [-63.569164558999887, 20.415280371000051],
            [-63.605972558999895, 20.20528237100018],
            [-63.727229558999909, 19.500825370000086],
            [-63.73180755899989, 19.473460370000225],
            [-63.748117558999979, 19.371185370000148],
            [-63.79200855900001, 19.085312370000167],
            [-63.801163558999974, 19.020197370000233],
            [-63.801474558999871, 19.017817370000159],
            [-63.810405558999889, 18.952519370000118],
            [-63.822960558999853, 18.856316370000116],
            [-63.84069255899999, 18.716743369000142],
            [-63.841840558999877, 18.708278369000027],
            [-63.84326455899992, 18.698114369000166],
            [-63.854095558999916, 18.6189573690001],
            [-63.869540558999859, 18.500064369000143],
            [-63.840430558999913, 18.243084369000144],
            [-63.838227558999961, 18.223101369000176],
            [-63.889888559999918, 18.002733369000055],
            [-63.898055547999888, 17.964166664],
            [-63.899939559999893, 17.964731369000106],
            [-63.916625559999943, 17.969729369000106],
            [-63.933312559999877, 17.974726369000052],
            [-63.95017456, 17.979773368999986],
            [-63.967066559999864, 17.984731369000087],
            [-63.983783559999893, 17.989637369000093],
            [-64.000502559999973, 17.994541369000103],
            [-64.01722255999988, 17.999444369000059],
            [-64.024143559999914, 18.000558369000089],
            [-64.041388559999916, 18.003333369000188],
            [-64.053109559999939, 18.004957369000238],
            [-64.070417559999782, 18.007353369000043],
            [-64.087725559999967, 18.009748369000192],
            [-64.105034559999808, 18.012141369000119],
            [-64.122343559999877, 18.014533369000105],
            [-64.139653559999942, 18.016923369000153],
            [-64.156963559999781, 18.019312369000147],
            [-64.174273559999961, 18.021699369000089],
            [-64.19158456, 18.024084369000093],
            [-64.208896559999914, 18.026468369000156],
            [-64.226208559999975, 18.028851369000165],
            [-64.243520559999922, 18.031231369000068],
            [-64.260833559999924, 18.033611369000027],
            [-64.266061559999855, 18.034195369000031],
            [-64.283434559999876, 18.036134369000024],
            [-64.300807559999896, 18.038073369000244],
            [-64.318181559999914, 18.040009369000188],
            [-64.335555559999875, 18.041944369000248],
            [-64.352222559999916, 18.041666369000154],
            [-64.363556559999836, 18.04237136900008],
            [-64.381011559999934, 18.043456369000126],
            [-64.398466559999974, 18.044538369000122],
            [-64.415922559999842, 18.045620369000119],
            [-64.433377559999883, 18.046699369000066],
            [-64.450833559999921, 18.047777369000244],
            [-64.4580915599999, 18.048017369000206],
            [-64.475573559999873, 18.048592369000175],
            [-64.493055559999959, 18.049166369000034],
            [-64.494238559999928, 18.049181369000053],
            [-64.511729559999878, 18.049393369000029],
            [-64.529220559999885, 18.049603369000181],
            [-64.546711559999835, 18.049812369000222],
            [-64.564202559999899, 18.050019369000097],
            [-64.581693559999906, 18.050225369000088],
            [-64.599184559999912, 18.050429369000085],
            [-64.616675559999862, 18.050632369000027],
            [-64.63416656000004, 18.050833369000145],
            [-64.635111559999928, 18.059620369000129],
            [-64.636902559999953, 18.076264369000057],
            [-64.638692559999924, 18.09290936900004],
            [-64.64048356, 18.109554369000023],
            [-64.642275559999916, 18.126199369000233],
            [-64.644067559999939, 18.142844369000045],
            [-64.645859559999963, 18.159488369000144],
            [-64.647651559999929, 18.176133369000183],
            [-64.649444559999949, 18.192777369000055],
            [-64.649847559999898, 18.19567836900012],
            [-64.652155559999983, 18.212265369000136],
            [-64.654462559999899, 18.228852369000151],
            [-64.656771559999925, 18.245438369000226],
            [-64.659079559999896, 18.26202436900013],
            [-64.661388559999978, 18.278611369000146],
            [-64.660277559999884, 18.290000369000097],
            [-64.658819559999984, 18.291854369000191],
            [-64.648299559999941, 18.305233369000177],
            [-64.637777559999961, 18.318611369000223],
            [-64.636944559999961, 18.321111369000107],
            [-64.638480559999834, 18.327820369000108],
            [-64.64222255999988, 18.344166369000106],
            [-64.637777559999961, 18.356111369000075],
            [-64.639444559999959, 18.364166369000031],
            [-64.653346559999932, 18.365028369000129],
            [-64.670833559999892, 18.36611136900018],
            [-64.68323906, 18.373035369000036],
            [-64.700833559999921, 18.371111369000175],
            [-64.707777559999954, 18.374166369000193],
            [-64.716111559999888, 18.373611369000116],
            [-64.726666559999842, 18.375000369000077],
            [-64.728333559999896, 18.377777369000171],
            [-64.739999559999887, 18.376944369000171],
            [-64.743333559999826, 18.378333369000188],
            [-64.744999559999826, 18.377777369000171],
            [-64.755833559999928, 18.377222369000094],
            [-64.761244559999909, 18.379786369000158],
            [-64.77694455999989, 18.387222369000085],
            [-64.777645559999883, 18.389173369000048],
            [-64.783333559999903, 18.405000369000049],
            [-64.799166559999946, 18.408055369000181],
            [-64.800131559999954, 18.407784369000183],
            [-64.816944559999911, 18.403055369000185],
            [-64.828333559999976, 18.396666369000229],
            [-64.838638559999936, 18.393639369000255],
            [-64.856388559999914, 18.395277369000041],
            [-64.86388855999985, 18.397500369000113],
            [-64.871944559999918, 18.408611369000198],
            [-64.877499559999819, 18.422777369000073],
            [-64.879176559999962, 18.428002369000183],
            [-64.884309559999849, 18.444001369000034],
            [-64.889444559999788, 18.460000369000056],
            [-64.892936559999896, 18.473219369000105],
            [-64.897251559999859, 18.489505369000085],
            [-64.9045975599999, 18.504772369000136],
            [-64.911915560000011, 18.519978369000057],
            [-64.919234559999893, 18.535183369000094],
            [-64.926555559999883, 18.550389369000072],
            [-64.933877559999871, 18.56559436900011],
            [-64.94120056, 18.580798369000092],
            [-64.94852556, 18.596002369000246],
            [-64.955851559999928, 18.611206369000058],
            [-64.963178560999893, 18.626410369000155],
            [-64.970506560999809, 18.641614369000138],
            [-64.97783656099989, 18.656817369000237],
            [-64.985167560999912, 18.672019369000168],
            [-64.992499560999818, 18.687222369000096],
            [-64.992698560999884, 18.68819436900003],
            [-64.996050560999947, 18.704617369000118],
            [-64.999403560999781, 18.721041369000147],
            [-65.002756560999956, 18.737465369000176],
            [-65.006111560999898, 18.753888369000094],
            [-65.008278560999884, 18.764132369000038],
            [-65.01174856099999, 18.780534369000179],
            [-65.015220560999978, 18.796935369000039],
            [-65.018691560999798, 18.81333736900018],
            [-65.022164560999954, 18.829738369000097],
            [-65.025637560999826, 18.846139369000184],
            [-65.029111560999922, 18.862541370000031],
            [-65.032586560999846, 18.878942370000118],
            [-65.036061560999883, 18.895343370000148],
            [-65.039537560999975, 18.911744370000235],
            [-65.043014560999893, 18.928144370000041],
            [-65.046491560999982, 18.944545370000128],
            [-65.049969560999898, 18.960946370000158],
            [-65.053448560999982, 18.977346370000134],
            [-65.056927560999952, 18.993747370000222],
            [-65.060407561000034, 19.010147370000027],
            [-65.063888560999828, 19.026547370000173],
            [-65.067370560999905, 19.04294837000009],
            [-65.070852560999924, 19.059348370000237],
            [-65.074335560999884, 19.075748370000042],
            [-65.077818560999901, 19.092148370000189],
            [-65.081302560999916, 19.10854737000011],
            [-65.084787560999928, 19.124947370000086],
            [-65.08827356099988, 19.141347370000062],
            [-65.091759561, 19.157746370000154],
            [-65.095246560999954, 19.17414637000013],
            [-65.098733560999904, 19.190545370000052],
            [-65.102222560999849, 19.206944370000087],
            [-65.107643560999918, 19.216143370000054],
            [-65.116248560999964, 19.230741370000146],
            [-65.12485556099989, 19.245338370000184],
            [-65.133463560999985, 19.259935370000051],
            [-65.142073560999961, 19.274532370000031],
            [-65.150684560999935, 19.289128370000128],
            [-65.159297560999903, 19.303724370000111],
            [-65.167911560999926, 19.318319370000097],
            [-65.176527560999887, 19.33291437000014],
            [-65.185144560999845, 19.347509370000182],
            [-65.193763560999969, 19.362103370000114],
            [-65.202383560999976, 19.376696370000161],
            [-65.211005560999979, 19.391290370000092],
            [-65.219629561000033, 19.405882370000086],
            [-65.228254560999858, 19.420475370000077],
            [-65.236880560999907, 19.43506737000024],
            [-65.245508560999895, 19.449658370000122],
            [-65.25413856099982, 19.464249370000175],
            [-65.262769560999857, 19.478840370000057],
            [-65.271402560999888, 19.493430370000169],
            [-65.280036560999918, 19.508020370000168],
            [-65.288672560999942, 19.522609370000225],
            [-65.297309560999906, 19.537198370000112],
            [-65.305948560999923, 19.551786370000116],
            [-65.314589560999877, 19.566374370000062],
            [-65.323231560999886, 19.580962370000236],
            [-65.331874560999893, 19.595549370000128],
            [-65.340519560999894, 19.610136370000191],
            [-65.34916656099989, 19.6247223700002],
            [-65.353352560999951, 19.637831370000185],
            [-65.358467560999884, 19.653844370000115],
            [-65.36358256099993, 19.669857370000102],
            [-65.368698560999917, 19.685869370000091],
            [-65.373816560999899, 19.701881370000137],
            [-65.37893456099988, 19.717894370000067],
            [-65.384053560999973, 19.733906370000113],
            [-65.389174561, 19.749917370000048],
            [-65.394295560999922, 19.765929370000094],
            [-65.399418560999948, 19.781941370000141],
            [-65.404541560999917, 19.797952370000246],
            [-65.409666560999938, 19.813963370000181],
            [-65.414791561000015, 19.829974370000173],
            [-65.419918561000031, 19.845985370000108],
            [-65.425046561000045, 19.861995370000159],
            [-65.430174560999831, 19.878006370000094],
            [-65.435304560999896, 19.894016370000145],
            [-65.440435560999902, 19.910026370000026],
            [-65.445566560999964, 19.926036370000247],
            [-65.450699560999851, 19.942046371000174],
            [-65.455833560999906, 19.958055371000114],
            [-65.456969560999823, 19.963248371000134],
            [-65.460556560999919, 19.979630371000042],
            [-65.464143560999958, 19.99601237100012],
            [-65.46773156099988, 20.012394371000028],
            [-65.471320560999914, 20.028775371000222],
            [-65.474910560999888, 20.04515737100013],
            [-65.478500560999919, 20.061538371000154],
            [-65.482091560999947, 20.077920371000232],
            [-65.485683560999973, 20.094301371000086],
            [-65.489275560999772, 20.110682371000053],
            [-65.492869560999907, 20.127063371000077],
            [-65.49646256099993, 20.143445371000155],
            [-65.500057560999892, 20.159825371000125],
            [-65.503653560999908, 20.176206371000092],
            [-65.507249560999924, 20.192587371000116],
            [-65.510846560999823, 20.208967371000028],
            [-65.514444560999891, 20.225348371000052],
            [-65.518042560999959, 20.241728371000193],
            [-65.521642560999794, 20.25810937100016],
            [-65.52524256099997, 20.274489371000129],
            [-65.528842560999919, 20.290869371000213],
            [-65.53244456099992, 20.307249371000125],
            [-65.536046560999921, 20.323629371000038],
            [-65.539649560999919, 20.340008371000067],
            [-65.543253560999915, 20.356388371000037],
            [-65.546858560999965, 20.37276837100012],
            [-65.550463560999901, 20.389147371000149],
            [-65.554069560999835, 20.405526371000178],
            [-65.557676560999823, 20.421906371000091],
            [-65.561284560999923, 20.43828537100012],
            [-65.564892560999965, 20.454664371000149],
            [-65.568501560999948, 20.471043371000178],
            [-65.572111560999872, 20.48742237100015],
            [-65.575722560999964, 20.503800371000125],
            [-65.579333560999885, 20.520179371000154],
            [-65.582946560999915, 20.536557371000072],
            [-65.586559560999888, 20.552936371000101],
            [-65.590172560999861, 20.569314371000189],
            [-65.593787560999829, 20.585692371000164],
            [-65.597402560999797, 20.602070371000082],
            [-65.601018560999876, 20.618448371000056],
            [-65.604635560999895, 20.634826371000145],
            [-65.60825356099997, 20.651204371000063],
            [-65.61187156099993, 20.667581371000097],
            [-65.615490560999945, 20.683959371000242],
            [-65.6191105609999, 20.700336371000105],
            [-65.62273156099991, 20.716714371000023],
            [-65.626353560999917, 20.733091371000057],
            [-65.62997556099981, 20.749468371000091],
            [-65.633598560999872, 20.765845371000125],
            [-65.637222560999874, 20.782222371000159],
            [-65.638257560999932, 20.787321371000189],
            [-65.641594560999806, 20.803752371000087],
            [-65.644931560999908, 20.820183371000098],
            [-65.64826956099995, 20.836613371000112],
            [-65.651608560999875, 20.85304437100018],
            [-65.654947560999858, 20.869475371000249],
            [-65.658287560999838, 20.885905371000206],
            [-65.661628560999873, 20.902335371000163],
            [-65.664970560999848, 20.918765371000177],
            [-65.66831256099988, 20.935195371000134],
            [-65.671656560999907, 20.951625371000091],
            [-65.674999560999879, 20.968055371000105],
            [-65.677823560999911, 20.982134371000086],
            [-65.681120560999886, 20.998572371000193],
            [-65.684419560999913, 21.015010372000177],
            [-65.687718560999826, 21.031448372000114],
            [-65.69101756099991, 21.047886372000221],
            [-65.694318560999932, 21.064324372000158],
            [-65.697619560999897, 21.080761372000154],
            [-65.700921560999973, 21.097199372000091],
            [-65.704223560999765, 21.113637372000028],
            [-65.707527560999893, 21.130074372000081],
            [-65.710831560999964, 21.146511372000077],
            [-65.714135560999921, 21.16294837200013],
            [-65.71744156099993, 21.179386372000067],
            [-65.720747560999882, 21.195823372000064],
            [-65.724054560999889, 21.212260372000117],
            [-65.72736256099995, 21.228696372000059],
            [-65.730670560999897, 21.245133372000055],
            [-65.733979560999899, 21.261570372000108],
            [-65.737289560999898, 21.27800637200005],
            [-65.740600560999894, 21.294443372000046],
            [-65.743911560999891, 21.310879372000159],
            [-65.747223560999942, 21.327315372000101],
            [-65.750536560999763, 21.343752372000154],
            [-65.753850560999922, 21.360188372000096],
            [-65.757164560999911, 21.376624372000038],
            [-65.760479560999954, 21.39306037200015],
            [-65.763795560999881, 21.409495372000151],
            [-65.767111560999865, 21.425931372000093],
            [-65.77042856099996, 21.442367372000035],
            [-65.77374756099988, 21.458802372000093],
            [-65.777065560999915, 21.475237372000151],
            [-65.780385560999946, 21.491673372000093],
            [-65.78370556099992, 21.508108372000095],
            [-65.787026560999891, 21.524543372000153],
            [-65.79034856099986, 21.540978372000211],
            [-65.793670560999942, 21.557413372000042],
            [-65.796993560999908, 21.5738483720001],
            [-65.80031856099987, 21.590282372000217],
            [-65.803642560999776, 21.606717372000048],
            [-65.806968560999906, 21.623151372000052],
            [-65.810294560999978, 21.639586372000053],
            [-65.81362156099982, 21.656020372000171],
            [-65.816949561, 21.672454372000175],
            [-65.820277560999841, 21.688888372000122],
            [-65.821145560999923, 21.693709372000228],
            [-65.824114560999931, 21.710203372000137],
            [-65.827084560999879, 21.726697372000103],
            [-65.830055560999881, 21.74319137200024],
            [-65.833026561, 21.759685372000149],
            [-65.835998560999883, 21.776179372000115],
            [-65.83897156099988, 21.792673372000024],
            [-65.841937060999896, 21.809129372000029],
            [-65.842561560999911, 21.812909372000149],
            [-65.637437560999899, 21.874533372000087],
            [-65.033792560999984, 22.027430372000026],
            [-65.018786560999843, 22.028687372000093],
            [-65.010342560999959, 22.030809372000135],
            [-64.386568559999887, 22.081935373000192],
            [-64.385041559999877, 22.081809373000112],
            [-64.383482559999891, 22.081935373000192],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "VNM", Country: "Vietnam" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [109.950928580000067, 16.701448136000167],
            [109.950290398000135, 16.646496121999988],
            [109.990378723000134, 16.430418049000124],
            [110.00128002600016, 16.379309626],
            [110.061812398000114, 16.09265200900002],
            [110.127256328000186, 15.782866547000125],
            [110.210811388000167, 15.383329963000037],
            [110.211211534000228, 15.381428579000115],
            [110.244572105000231, 15.222981343000086],
            [110.306086471000157, 14.934787898000124],
            [110.324196428000135, 14.902680117000145],
            [110.324992588000015, 14.901640621000112],
            [110.429501880000117, 14.76518951300001],
            [110.492638467000148, 14.69756751200002],
            [110.518253935000217, 14.67006602000005],
            [110.842197750000167, 14.320502827000055],
            [111.198124179000132, 13.992925474000018],
            [111.310097247000073, 13.889339134000167],
            [111.310750548000129, 13.888734590000055],
            [111.359794622000123, 13.843346010000076],
            [111.68714651500008, 13.583824078000092],
            [111.689055047000153, 13.582308288000092],
            [111.69090264400009, 13.580842590999964],
            [111.766096426000132, 13.521172141000108],
            [112.054990745000083, 13.353753386000022],
            [112.23989264400015, 13.247067710000152],
            [112.173782020000175, 13.037041066000029],
            [112.120480094000101, 12.865602145000125],
            [112.061699051000033, 12.641480984],
            [112.000967731000145, 12.409324120000065],
            [111.914615199000167, 12.051334486000087],
            [111.799744424000124, 11.708622119000012],
            [111.559822535000109, 11.30336051400009],
            [111.398015721000121, 10.956631626000046],
            [111.247766536000171, 10.644575628000027],
            [111.178420759000034, 10.563672220000072],
            [110.964474469000066, 10.241265286],
            [111.911175952000093, 8.970657066000157],
            [111.917001015000125, 8.962725093000046],
            [111.992648262000131, 8.860325912],
            [112.058064859000154, 8.770720596000103],
            [112.079930878000056, 8.741426864000076],
            [112.163030557000098, 8.629054349000143],
            [112.245407260000178, 8.51997867700014],
            [112.51484310500021, 8.16045802800005],
            [112.545247766000244, 8.119923424000049],
            [112.475564055000092, 7.851237375999972],
            [112.196290367000103, 6.775656593000079],
            [112.035649273000189, 6.153496011000129],
            [111.918753352000152, 6.094584536000184],
            [111.824140570000196, 6.046711357000149],
            [111.215098129000097, 5.740075318000137],
            [111.000039051000073, 5.6734585600001],
            [110.739910980000019, 5.935945307999987],
            [110.130265411000124, 6.548643367000025],
            [109.777529870000109, 6.902325130000179],
            [109.315949775, 7.362523756000087],
            [109.165050026000102, 7.31450112100012],
            [109.126872349000251, 7.302351381000079],
            [108.615197019000135, 7.138346616000121],
            [107.815054223000317, 6.880226304000118],
            [107.745054869000143, 6.857707602000133],
            [107.670270600000066, 6.833649509000224],
            [107.362560600000251, 6.728518509000111],
            [107.198716144000144, 6.670225579000103],
            [107.006342185000193, 6.601782084000078],
            [106.723567737000252, 6.501209199000172],
            [106.629119080000038, 6.465786095000141],
            [106.594642599000139, 6.452855509000074],
            [106.582602138000226, 6.448331255000014],
            [106.569285125000334, 6.443327185000044],
            [106.179303829000077, 6.247045090000114],
            [106.020218130000018, 6.17219412500009],
            [105.820000000000192, 6.096666667000065],
            [105.422285999000025, 6.242514713],
            [105.382154685000188, 6.256456121000028],
            [105.040402988000011, 6.375162515000099],
            [104.882969343000099, 6.473534466000075],
            [104.731675669000168, 6.56787980700004],
            [104.520179985000169, 6.699788537000018],
            [104.369012276000177, 6.80392199500011],
            [104.295646537000124, 6.854479956999967],
            [104.091239123000179, 6.995128357],
            [103.897807733000121, 7.134047697000057],
            [103.625385942000122, 7.320653046000118],
            [103.404792110000159, 7.501803551000123],
            [103.196732208000157, 7.67266125900008],
            [103.133228143000082, 7.724810466000065],
            [103.041667000000103, 7.8],
            [102.203237000000144, 8.781882000000166],
            [102.605756774000128, 9.134379081000077],
            [102.792873185000218, 9.285872090000069],
            [102.795182484000151, 9.287741623000031],
            [102.797473052000129, 9.289605863000091],
            [102.92685870800014, 9.394925446],
            [102.976578144000172, 9.435130557000093],
            [103.070275443000099, 9.505845087000168],
            [103.155498603000154, 9.56963181399999],
            [103.160834082000093, 9.571773489000179],
            [103.183380778000156, 9.581432823000128],
            [103.3553136620001, 9.656470469000041],
            [103.391483501000181, 9.672103801],
            [103.402378793000167, 9.676898784000088],
            [103.432799121000045, 9.69981212200004],
            [103.471183415000127, 9.728987106],
            [103.562640371000185, 9.798844373000023],
            [103.571842666000151, 9.965125233000109],
            [103.57387674400016, 9.998675704],
            [103.578804868000162, 10.009146583000117],
            [103.567353556000086, 10.107604937000048],
            [103.558767364000033, 10.155601515000171],
            [103.603500228000115, 10.232065398000145],
            [103.658255053000204, 10.324289591000024],
            [103.723669036000189, 10.351540434000128],
            [103.725970013000079, 10.352437446000096],
            [103.730960903000124, 10.354437758000046],
            [103.773223878000209, 10.367530710000139],
            [103.783932854000142, 10.373747956000031],
            [103.785019784000184, 10.374398474000031],
            [103.789820344000105, 10.377311580000011],
            [103.801502238000154, 10.384101109000099],
            [103.803451070000193, 10.385167115000016],
            [103.8104972900002, 10.387399071000033],
            [103.819000240000122, 10.389195294000061],
            [103.821003723000075, 10.389759390000108],
            [103.828075855000094, 10.39118884700008],
            [103.83776460500016, 10.401152484000065],
            [103.843502045000065, 10.402910408000125],
            [103.849147182000053, 10.405257400000067],
            [103.853194558000183, 10.40680937600014],
            [103.856002813000174, 10.408396927000041],
            [103.874924640000103, 10.41988463700001],
            [103.880357485000019, 10.424545821000123],
            [103.885955304000078, 10.429117789000131],
            [103.89405157600018, 10.43713726300011],
            [103.897713548000155, 10.454418886000028],
            [103.899360286000132, 10.4634668140001],
            [103.898330050000055, 10.479429640000063],
            [103.897987408000148, 10.484624747000026],
            [103.897752817500219, 10.48924670100017],
            [103.905997338000049, 10.50090868000018],
            [103.91485720700021, 10.502983142000133],
            [103.945198915000077, 10.511421648000052],
            [103.946545416500129, 10.511839356000067],
            [103.951265703000132, 10.51249261800011],
            [103.957792254000225, 10.512498716],
            [103.977352762000038, 10.511091064000055],
            [103.983349300000128, 10.510849575000151],
            [103.985738679000207, 10.510849678000156],
            [103.986642507000141, 10.510797653000026],
            [103.990536895000133, 10.510050535000119],
            [103.99800134900002, 10.508397262000102],
            [104.011482955000105, 10.505649631000139],
            [104.016847109000111, 10.503755381000161],
            [104.018729024000123, 10.503107543000127],
            [104.024451468500132, 10.500901811000134],
            [104.026093563000103, 10.500370605000157],
            [104.031027379000108, 10.498288597000013],
            [104.032969729000143, 10.497512329000088],
            [104.051651165000152, 10.489628038000021],
            [104.056609547000079, 10.487183070000142],
            [104.061932035000183, 10.483989483000045],
            [104.072997606000143, 10.477904831000018],
            [104.074818624000187, 10.476868944000017],
            [104.085370846000188, 10.47451379200011],
            [104.086298323000193, 10.474253174000125],
            [104.089214114000043, 10.47308187500002],
            [104.102500377000155, 10.469979305],
            [104.130679786500224, 10.461552642000086],
            [104.133154881000138, 10.454998939000149],
            [104.1433931400002, 10.428662014000167],
            [104.151816102000083, 10.415259935000165],
            [104.153443690000103, 10.412806076000081],
            [104.161755041000077, 10.400602018000086],
            [104.181096939000014, 10.373171091000088],
            [104.201500140000093, 10.371214991999977],
            [104.220500502000135, 10.369494198000154],
            [104.434866116000165, 10.406499575000083],
            [104.445327628137505, 10.422736120056797],
            [104.487745217706248, 10.422773204259258],
            [104.548572353944195, 10.465209103774598],
            [104.566227156651877, 10.50180901936848],
            [104.594827216194886, 10.532709073149164],
            [104.669827144913796, 10.536944951749661],
            [104.701682232744901, 10.531182058024413],
            [104.715472307525744, 10.528482079365986],
            [104.733036250402819, 10.525000069139168],
            [104.770518277489515, 10.521945033061186],
            [104.80107232630607, 10.520000096242924],
            [104.850527231559994, 10.521391156897764],
            [104.877882243201697, 10.530418131366744],
            [104.894427281925999, 10.544164117336763],
            [104.918300283340756, 10.576945070195265],
            [104.929691289788167, 10.593609131944959],
            [104.939136185950503, 10.611945048106719],
            [104.954409187045343, 10.631245050774396],
            [104.982609262167699, 10.653473187505469],
            [105.059972384674353, 10.693473138312996],
            [105.094145236378182, 10.713191062674099],
            [105.09371826222926, 10.746527065162411],
            [105.06108231629608, 10.823609058635952],
            [105.04913626021883, 10.843891085082277],
            [105.038918217305337, 10.881700174031735],
            [105.049954166333038, 10.906936072906689],
            [105.06108231629608, 10.920273189638777],
            [105.100800299879978, 10.954300028588634],
            [105.191745123053948, 10.906117999154574],
            [105.206091253293181, 10.899444998379963],
            [105.233854128344518, 10.889718134993757],
            [105.300245171925866, 10.875554059692135],
            [105.361909325017308, 10.880691160524592],
            [105.372191238033253, 10.90055392417311],
            [105.430936141878618, 10.965764125231914],
            [105.524291265136668, 10.945764233647012],
            [105.527763384717758, 10.94721799093783],
            [105.554691254572475, 10.953053974859102],
            [105.588527153767501, 10.959709038360899],
            [105.635527167212473, 10.970000171470403],
            [105.694409198994236, 10.996109129382603],
            [105.722054224486584, 11.013053984889524],
            [105.746091343566121, 11.019300011515298],
            [105.781354177961958, 11.020973207031744],
            [105.858309269420914, 10.916664109759381],
            [105.959972367491588, 10.882500142873042],
            [106.082200297422418, 10.802773156026205],
            [106.203309243276323, 10.77055395832005],
            [106.176645235734298, 10.870553919157899],
            [106.145663374578447, 10.919445057602672],
            [106.152263285157204, 10.968709023101653],
            [106.18662724425397, 10.975000144366746],
            [106.209027210000585, 10.97009103130118],
            [106.212400255485846, 10.972500157918574],
            [106.189400313108166, 11.053682075522801],
            [106.155518313445526, 11.093891070996165],
            [106.126454231741519, 11.086773158799531],
            [105.948582366872557, 11.202500084609568],
            [105.929963310020895, 11.220273072152409],
            [105.871136263524164, 11.296735978255597],
            [105.883036219133686, 11.364164198538873],
            [105.900118202576806, 11.435344996884211],
            [105.878645107685799, 11.548953983393773],
            [105.819072407080654, 11.594009060020568],
            [105.816436130886501, 11.617454081458064],
            [105.85314517886016, 11.661945055999553],
            [105.875100233185009, 11.670417986667303],
            [105.898172259930135, 11.667364124055709],
            [105.926363282596895, 11.651944942569159],
            [105.961282291323386, 11.644791155827065],
            [106.014282238716277, 11.720554005375035],
            [106.022263319304244, 11.759854067265607],
            [106.043372304320172, 11.776245046608921],
            [106.083163378099954, 11.773054056061127],
            [106.26864517288314, 11.717773225169822],
            [106.303054226618968, 11.681736070556695],
            [106.417191273030113, 11.67610913130126],
            [106.45820928980018, 11.665864098659284],
            [106.448300203838443, 11.709718048557605],
            [106.437736155960835, 11.793608997275754],
            [106.448582338700163, 11.817218136377861],
            [106.461354179880431, 11.885000072976126],
            [106.421172341773314, 11.96535402618224],
            [106.419545246724482, 11.972636056043484],
            [106.445382295696874, 11.982082125672378],
            [106.628682281077232, 11.971527130250252],
            [106.698582324613852, 11.962773070549289],
            [106.726082343180991, 11.973818072032572],
            [106.747100300728249, 12.000000120140712],
            [106.789200253563337, 12.073464148917438],
            [106.85693625733191, 12.067218122291564],
            [106.907345190808002, 12.064653930465141],
            [106.955836177194016, 12.074718081635893],
            [106.974709205712685, 12.082500175841915],
            [107.052754112225642, 12.139444982156178],
            [107.081936211126646, 12.177773078551724],
            [107.110809185437716, 12.215827086712579],
            [107.162763406587459, 12.27374503198844],
            [107.189972405475402, 12.287218103190156],
            [107.238591299704382, 12.30360908253374],
            [107.296372284681837, 12.322773130731335],
            [107.353727301338864, 12.3318001052005],
            [107.378309244126143, 12.312773184939019],
            [107.397772358630021, 12.287773152819966],
            [107.423391310481264, 12.25349116873673],
            [107.438936220515842, 12.254544941606937],
            [107.534145253123285, 12.338609060444924],
            [107.549909266107505, 12.35930012376754],
            [107.595263409040427, 12.554445079472387],
            [107.593045222177778, 12.603882047453482],
            [107.563182345098966, 12.810827046481776],
            [107.51970926888194, 12.864164114021293],
            [107.509427188227932, 12.884164173244187],
            [107.492482332721096, 12.952500153643868],
            [107.483318230315746, 13.020553999181757],
            [107.606463308094732, 13.311335967471564],
            [107.636382343924964, 13.381664158623607],
            [107.62924515335115, 13.538036106511072],
            [107.588591246456104, 13.595273105971017],
            [107.464191230130183, 13.794364094064008],
            [107.470263416083441, 13.841391097237207],
            [107.473500339460855, 13.931709121690162],
            [107.383045187071588, 14.00458205865327],
            [107.370527149920207, 14.020417988366958],
            [107.344082245327769, 14.128400035621084],
            [107.360682269337104, 14.164717983991338],
            [107.384709330132722, 14.240553923735447],
            [107.389145200943659, 14.292427008062091],
            [107.375454200258702, 14.337354009208084],
            [107.409145260166468, 14.420273160069186],
            [107.432263387379209, 14.435036038535387],
            [107.45671826051418, 14.422218096887562],
            [107.480818243867731, 14.435554040153065],
            [107.50443626778727, 14.482218106917784],
            [107.533245204357883, 14.550135997985862],
            [107.558454113504609, 14.65708220964278],
            [107.553309301321036, 14.701800166122936],
            [107.546600258362588, 14.708618006184778],
            [107.54942713903543, 14.724445051081133],
            [107.551654210715384, 14.847500107219687],
            [107.540118197342764, 14.918891123697264],
            [107.479982232842616, 14.971454038657313],
            [107.46803617676548, 15.023191168514316],
            [107.473309232067919, 15.035827055224601],
            [107.58679131656308, 15.045554086248799],
            [107.615818182616835, 15.063609041015368],
            [107.640545132329606, 15.128053974312039],
            [107.670254117665735, 15.205273095721864],
            [107.681582259839018, 15.226482160661973],
            [107.684709212646368, 15.234718051107265],
            [107.695254149784688, 15.270827122449859],
            [107.684709212646368, 15.288609162448196],
            [107.596363282378064, 15.383891117613402],
            [107.517491249657468, 15.468327057677456],
            [107.420254131751989, 15.503336088044236],
            [107.361100191030602, 15.556391188360138],
            [107.263045167010915, 15.647782096422517],
            [107.18303621294038, 15.765418084504986],
            [107.176300347891726, 15.790345026428199],
            [107.203945205746066, 15.858953921595898],
            [107.229291242829078, 15.866391184304362],
            [107.2555452076667, 15.858473135628415],
            [107.401936152863158, 15.918473145658751],
            [107.463609190771962, 16.003891109501652],
            [107.475818270971416, 16.022500108069508],
            [107.47530026935371, 16.027335963301155],
            [107.460818184644864, 16.080482091085727],
            [107.382472367807026, 16.067500031773292],
            [107.362491251685384, 16.06277314128333],
            [107.337618289218909, 16.056109025325938],
            [107.191654150533367, 16.16082699183643],
            [107.165118218472259, 16.183744959200922],
            [107.152072289057372, 16.205827083178193],
            [107.14860922193165, 16.23308218253392],
            [107.147218161276811, 16.263335991577847],
            [107.093891319659207, 16.300818018664572],
            [107.03054525387185, 16.306382093646036],
            [107.010554247104579, 16.307364117424683],
            [106.98720025313574, 16.299718145326736],
            [106.983872302289654, 16.337218109686276],
            [106.895263347899146, 16.517773189407066],
            [106.875118281750844, 16.536873199864388],
            [106.84338221694523, 16.532354012935613],
            [106.829163323996653, 16.506664150183425],
            [106.813872385629082, 16.473045007005013],
            [106.782491210604832, 16.432500064851482],
            [106.756645109176958, 16.420344964108679],
            [106.702209174128001, 16.439718056972183],
            [106.684700216535958, 16.459300026863488],
            [106.575272290979939, 16.642218132734044],
            [106.560672356712047, 16.677426987673172],
            [106.555254126846222, 16.888744993238348],
            [106.555254126846222, 16.93804500092115],
            [106.561100336689407, 16.996936085158339],
            [106.520200337116393, 16.967354001836654],
            [106.441227218643149, 16.992491161891891],
            [106.43420033391547, 17.011364190410561],
            [106.420536155320605, 17.064718021756605],
            [106.340818220929094, 17.169164079327274],
            [106.248663386209245, 17.246453944000095],
            [106.211645213645653, 17.261664080820893],
            [106.151382347131033, 17.304718061877892],
            [106.090127230915783, 17.356800023232481],
            [106.039145142898946, 17.40666413300049],
            [106.021382381277959, 17.426109142593575],
            [105.931927189940353, 17.529164139509319],
            [105.892209206356455, 17.584436085583192],
            [105.8608092558691, 17.618609104924943],
            [105.832836162685425, 17.640200049374855],
            [105.795536190536239, 17.653327115612697],
            [105.754300244282973, 17.670409099055817],
            [105.681927204026664, 17.814445064729696],
            [105.627245176299851, 17.98750001746815],
            [105.582209210412344, 18.048609120929811],
            [105.504436212839181, 18.168327006128763],
            [105.399982276279474, 18.157564139507272],
            [105.35733615863208, 18.168191051658951],
            [105.332209224498655, 18.1861090461271],
            [105.321927143844533, 18.196682146460134],
            [105.32582724340304, 18.242773226322399],
            [105.308518278021239, 18.256145044133646],
            [105.267072281273869, 18.252500089708505],
            [105.183318292854011, 18.334445095779998],
            [105.108727233373116, 18.437982052129612],
            [105.103872267402295, 18.470273166565207],
            [105.11525422139411, 18.517636116418757],
            [105.150272304216372, 18.598744943827043],
            [105.194136144760506, 18.610000163442479],
            [105.193172393530773, 18.636727035258545],
            [105.146100295718185, 18.673327118490491],
            [105.105554180098267, 18.69666418101481],
            [105.029982270305055, 18.720827028642347],
            [104.935254191303073, 18.744164091166752],
            [104.74456337895802, 18.796245046692846],
            [104.672209281802878, 18.836936001599994],
            [104.573036279534648, 18.902982213683927],
            [104.549145173208927, 18.941664194032356],
            [104.530263427510846, 18.972218075210904],
            [104.278809181392063, 19.110900184035543],
            [104.220754108179989, 19.111326990546289],
            [104.226018278664952, 19.145064150921698],
            [104.174572336125237, 19.190545028231028],
            [104.061091257458401, 19.238609040468091],
            [104.006927231349351, 19.233400022906196],
            [103.877682307336357, 19.309509045056402],
            [103.971645118576276, 19.392082190953445],
            [104.041363274813449, 19.418464231271841],
            [104.103863271291885, 19.478609080589493],
            [104.105945168408454, 19.522636032969942],
            [104.101645252067414, 19.55860914984261],
            [104.078945214186177, 19.569164145264935],
            [104.050536262036275, 19.599444944037273],
            [104.038882231466602, 19.620273135295989],
            [104.021418200875615, 19.669118005635156],
            [104.038827246181683, 19.693735990606328],
            [104.05831818860409, 19.682773131774482],
            [104.087418312491849, 19.664300087676438],
            [104.141591223418374, 19.66263611225348],
            [104.149991231528105, 19.681673090798668],
            [104.158736238773571, 19.687491137447026],
            [104.220536178696847, 19.703191112690817],
            [104.246091260445581, 19.704435992953862],
            [104.42276333969059, 19.669853936736004],
            [104.449845268926111, 19.645273167415141],
            [104.519572309980703, 19.605409003439249],
            [104.568882375947425, 19.605554010364656],
            [104.644227136163892, 19.616664223054684],
            [104.654972400788637, 19.647909108332939],
            [104.667209308906678, 19.68721805504093],
            [104.679282266998285, 19.707909118363446],
            [104.717000328478861, 19.747982159366828],
            [104.770263299994241, 19.75694509609535],
            [104.832000208005383, 19.787427060544516],
            [104.84907230080276, 19.844164163297691],
            [104.82651827567517, 19.863882087658695],
            [104.790154226837245, 19.86399105240038],
            [104.789700262960054, 19.891935982389256],
            [104.842209198463451, 19.938882184015668],
            [104.899427254822086, 19.973327112297213],
            [104.962718167686461, 19.988409005999145],
            [104.977827218754584, 20.003954083671616],
            [104.984709264195061, 20.098327105254029],
            [104.939282366342383, 20.183464107701624],
            [104.84491822957736, 20.225064163829913],
            [104.715000238737588, 20.313054030850267],
            [104.709018242062683, 20.341527020740628],
            [104.615463294232399, 20.416036105208221],
            [104.552754253088239, 20.414227122859884],
            [104.520400274378659, 20.407082053297117],
            [104.420536268010693, 20.424164204378215],
            [104.393736138360708, 20.435273075963877],
            [104.381509288526416, 20.454582131086866],
            [104.454436204946234, 20.504445067388488],
            [104.516100190399612, 20.517218082035171],
            [104.60053613046361, 20.590273073935791],
            [104.629972368669627, 20.618609103528044],
            [104.641891267380316, 20.652353975254286],
            [104.614418238541617, 20.665136042356565],
            [104.588172320331097, 20.672636001700695],
            [104.5019272302807, 20.712218030814896],
            [104.40941817633157, 20.7783271071727],
            [104.34421820119465, 20.843154087617151],
            [104.330691318174303, 20.87708218774749],
            [104.306227224945843, 20.894300125660422],
            [104.230272262176442, 20.928882181878365],
            [104.194427220784661, 20.943327050937015],
            [104.103945246305244, 20.975900132596152],
            [103.856645239049868, 20.852500077321864],
            [103.778872409114712, 20.784445058317502],
            [103.771382340416181, 20.737218062933763],
            [103.738382284608036, 20.670964147288643],
            [103.687054191627311, 20.659582193296885],
            [103.67331826394107, 20.681518136882559],
            [103.637354199523799, 20.717909007810775],
            [103.605400205235043, 20.740273099011731],
            [103.42499130590582, 20.79389112794648],
            [103.360209252462454, 20.80213607084697],
            [103.335272252255521, 20.80555404333343],
            [103.218600183040991, 20.830273114057277],
            [103.189091357553224, 20.844200149136427],
            [103.177127196565237, 20.84388213972899],
            [103.117818190634665, 20.8936091215608],
            [103.088045167558022, 20.955554068409398],
            [103.042072272530987, 21.045973178614744],
            [102.937272331007335, 21.163327031835607],
            [102.914436171018139, 21.168054089963661],
            [102.919072369315501, 21.228882064391897],
            [102.888600295512134, 21.252218121087893],
            [102.904709307631919, 21.381936119717338],
            [102.90498222240015, 21.440273160153012],
            [102.876918269385868, 21.430136086424326],
            [102.890682360266709, 21.466109035659017],
            [102.944427291215931, 21.528609032137425],
            [102.969018286458805, 21.55555400107464],
            [102.98747238745554, 21.574300127578894],
            [102.990809223119044, 21.606944958329606],
            [102.987409187905513, 21.712700101541756],
            [102.970545133945535, 21.745136055264695],
            [102.861363301067485, 21.718327040797291],
            [102.754009226001045, 21.670691176175623],
            [102.675191340374994, 21.658536075432707],
            [102.654145219632909, 21.734853974610601],
            [102.660536253184006, 21.760554063284772],
            [102.661654231432721, 21.786809033950661],
            [102.647218247191319, 21.853054064778277],
            [102.604427290256609, 21.92847308865727],
            [102.585536156827033, 21.93680017420921],
            [102.555254184588222, 21.940554093375837],
            [102.526645240228021, 21.947773091324862],
            [102.501782336045352, 21.966800179224307],
            [102.448027179174261, 22.066936094532068],
            [102.422763284742558, 22.115691110868866],
            [102.304836276981092, 22.210136049180832],
            [102.277418233427312, 22.215900116372651],
            [102.238309278929677, 22.242491201356799],
            [102.184909179478183, 22.300409146632475],
            [102.180118251247706, 22.326109067668554],
            [102.153736210929281, 22.384718017044051],
            [102.140745266799541, 22.396282193611412],
            [102.167482364537506, 22.423327074834717],
            [102.269709229417145, 22.491664228700884],
            [102.388891343363667, 22.609164094675364],
            [102.426091235588586, 22.68832697943698],
            [102.454082266045162, 22.753745051695077],
            [102.474436209220954, 22.771873096657473],
            [102.500963424102821, 22.77180017409971],
            [102.521382243209388, 22.765000103672421],
            [102.702472255992376, 22.684164191032337],
            [102.870009234217008, 22.592882080073466],
            [102.880600271822942, 22.554435966481108],
            [102.928936192999828, 22.482291081629668],
            [103.03040030468847, 22.436518011174925],
            [103.064709278500203, 22.440826979971362],
            [103.166927258562595, 22.542500136325842],
            [103.333672314573022, 22.794509096798251],
            [103.359700303300343, 22.792082200546119],
            [103.394991300890723, 22.771873096657473],
            [103.426609180861277, 22.704744948508718],
            [103.459854155880834, 22.63916409969049],
            [103.486927200299107, 22.610273188106603],
            [103.507627148438957, 22.591527061602434],
            [103.522418190099785, 22.584364222404744],
            [103.53728232195661, 22.587427137471664],
            [103.583736170588992, 22.648609163491258],
            [103.566382278206248, 22.698191138397576],
            [103.618100297323764, 22.779444972731156],
            [103.653809216607755, 22.782873171139514],
            [103.794145242571375, 22.651391117162845],
            [103.826945138531102, 22.607773201658617],
            [103.861100220600093, 22.577218147013681],
            [103.909418204504078, 22.537773078197787],
            [103.968663340332313, 22.503873141262247],
            [104.00858232195506, 22.523245060659306],
            [104.016518307903624, 22.552636036588055],
            [104.011372322253777, 22.573191145440674],
            [104.033872367924658, 22.686382210256809],
            [104.061509179151898, 22.740553947716975],
            [104.111300198724081, 22.798118009039669],
            [104.259154121304931, 22.827882147298695],
            [104.254582296024097, 22.77374494327988],
            [104.26518221844745, 22.73492700846144],
            [104.358991305582805, 22.687264154111475],
            [104.374700333281822, 22.687500188505439],
            [104.461109205720874, 22.741936123554396],
            [104.49429131646653, 22.770973215530077],
            [104.539427194640126, 22.809164183989239],
            [104.573609266437273, 22.830000086598986],
            [104.651300288997192, 22.826173077236419],
            [104.673454162066008, 22.813054057625763],
            [104.73192715697158, 22.818054030522021],
            [104.852209311893859, 22.962500038900217],
            [104.824536123206798, 23.099264200634636],
            [104.907491316251622, 23.180273115756833],
            [105.258045139166683, 23.30444497667763],
            [105.358727219286919, 23.324164242143397],
            [105.399154144243397, 23.280691165926285],
            [105.527400280671117, 23.183073174339526],
            [105.551300271814256, 23.162427038018151],
            [105.561372301974103, 23.134718142423438],
            [105.565263349077128, 23.110553953691422],
            [105.565691329054545, 23.096782151459465],
            [105.573909282226879, 23.063327125945776],
            [105.639263316744547, 23.062909036614158],
            [105.768600274054421, 23.020136016952264],
            [105.788882300500831, 23.004718008932301],
            [105.835818276205458, 22.956382087755415],
            [105.877063274914264, 22.912527132028615],
            [105.991363433161638, 22.938191178519062],
            [106.005691290851615, 22.952809050059656],
            [106.014845167335096, 22.982491045667516],
            [106.077491344205299, 22.990827016036789],
            [106.134718285381354, 22.988609164450494],
            [106.195609291721809, 22.973882160530039],
            [106.223591269722732, 22.955553955719225],
            [106.23749131507364, 22.938609100212474],
            [106.252063421422889, 22.90416417193093],
            [106.256136188187071, 22.87214496643513],
            [106.294291282100602, 22.857082183472599],
            [106.341236142622535, 22.853327090839585],
            [106.473600308092017, 22.89055397279283],
            [106.500263309805547, 22.905691187055666],
            [106.521963386635122, 22.932673204005127],
            [106.592482349904031, 22.913609068093436],
            [106.7029003458658, 22.866945001328716],
            [106.757491346123771, 22.821945077624932],
            [106.778318196278207, 22.803053944195383],
            [106.787491351139153, 22.763882125423763],
            [106.717482342860848, 22.630554041086626],
            [106.631436239192368, 22.615064116336995],
            [106.607127211173037, 22.604927042608395],
            [106.551709252345432, 22.456818142532242],
            [106.553309190028045, 22.431382083808771],
            [106.55712714693513, 22.41495405645334],
            [106.564982331337006, 22.349300117439256],
            [106.586654244971697, 22.335418009361163],
            [106.618600192633636, 22.328745008586552],
            [106.648736152118573, 22.328818098782492],
            [106.692200343518181, 22.216318038065978],
            [106.670054181800452, 22.204164110789563],
            [106.66859120441606, 22.174718149575952],
            [106.693309269311413, 22.030827023189389],
            [106.737491286901133, 21.99971809238103],
            [106.863309185972099, 21.979164156994912],
            [106.901654213812066, 21.970553930752871],
            [107.021100190071394, 21.939164206187385],
            [107.044009272618183, 21.921382166189048],
            [107.042491309948929, 21.889509141084929],
            [107.015827302406848, 21.849854021774988],
            [107.02401826585097, 21.820618111055538],
            [107.213609204858159, 21.711518085552669],
            [107.253391226182686, 21.710200115093684],
            [107.268600189537011, 21.724164198184852],
            [107.298591309734803, 21.729373215746861],
            [107.340400242890695, 21.681382126067561],
            [107.348327176383947, 21.662218077869781],
            [107.362727286079547, 21.605264219100022],
            [107.384718214950141, 21.598327020736534],
            [107.531791279428916, 21.58638213812587],
            [107.660263391966993, 21.61000016204558],
            [107.710118281641513, 21.627218099958426],
            [107.738309304308274, 21.644718005095157],
            [107.756100229123859, 21.656244966012295],
            [107.783054250516642, 21.66693608354241],
            [107.860818195634494, 21.647773208811131],
            [107.916654243793715, 21.588891177029538],
            [107.947482380844832, 21.544444961851113],
            [107.968882217901893, 21.535691069788228],
            [107.990018396823217, 21.542409344101102],
            [107.9946942830002, 21.543049762000081],
            [108.049705692000231, 21.526718250000087],
            [108.101194400000196, 21.47013889],
            [108.100444400000043, 21.467138890000015],
            [108.099361100000095, 21.463916670000046],
            [108.097638900000078, 21.46097222],
            [108.094416700000096, 21.457833329999957],
            [108.094111100000219, 21.456416670000166],
            [108.095472200000046, 21.452277780000102],
            [108.134722200000141, 21.275555560000029],
            [108.20861110000007, 21.20972222000016],
            [108.37916670000007, 20.401388890000149],
            [107.9297222000001, 19.959166669999988],
            [107.527777800000109, 19.659166669999976],
            [107.350000000000108, 19.479444440000023],
            [107.211944400000021, 19.423888890000015],
            [107.189722200000148, 19.267777780000117],
            [107.159444400000126, 19.215277780000051],
            [107.159444400000126, 18.714444440000037],
            [107.566666700000184, 18.230277780000037],
            [107.626111100000088, 18.118888890000036],
            [107.652500000000174, 18.070277779999955],
            [108.442952554000186, 17.366958825000111],
            [108.46291660100016, 17.341569519],
            [108.470994601000115, 17.338199519000156],
            [108.548738601000224, 17.305767518999986],
            [108.596171601000179, 17.281199519],
            [108.761516601000181, 17.195315519000061],
            [108.851065601000215, 17.158175519000011],
            [108.98524660200016, 17.102736519000146],
            [109.085024602000118, 17.067770519],
            [109.15142260200011, 17.036377519000027],
            [109.286839602000015, 16.959631519000069],
            [109.597126602000031, 16.826445519000103],
            [109.917355602000185, 16.71474051899996],
            [109.950928580000067, 16.701448136000167],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "VUT", Country: "Vanuatu" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [171.616697660000199, -14.939974661999855],
            [171.620355660000172, -14.959555661999957],
            [171.622208660000211, -14.965852661999975],
            [171.624129660000136, -14.983920661999946],
            [171.625981660000065, -14.99021266199999],
            [171.627904660000041, -15.008284661999937],
            [171.629755660000257, -15.014572662],
            [171.631679660000117, -15.032648661999914],
            [171.63352966000005, -15.03893166200001],
            [171.635454660000136, -15.057012662],
            [171.637304660000126, -15.063291661999941],
            [171.639231660000206, -15.081376661999983],
            [171.641080660000085, -15.087651661999956],
            [171.643007660000166, -15.105740661999974],
            [171.644855660000161, -15.112011661999958],
            [171.64678566000029, -15.130104661999965],
            [171.648632660000175, -15.136370661999976],
            [171.65056266000019, -15.154468661999942],
            [171.652409660000245, -15.160730662],
            [171.652535659999984, -15.161913662],
            [171.655399660000199, -15.16760666200004],
            [171.660342660000055, -15.18436666199996],
            [171.663601660000239, -15.190842661999952],
            [171.668549660000195, -15.207611662],
            [171.671803660000165, -15.214078661999963],
            [171.676756660000166, -15.230856661999951],
            [171.680006660000089, -15.237313661999963],
            [171.684963660000079, -15.254101662],
            [171.688209660000069, -15.260548661999977],
            [171.693171660000104, -15.277346661999943],
            [171.696413660000161, -15.28378366199999],
            [171.701379660000185, -15.300591661999974],
            [171.704617660000196, -15.307018662],
            [171.70958766000021, -15.323835662000022],
            [171.71282166000006, -15.330253662],
            [171.717796660000289, -15.347080661999968],
            [171.721026660000206, -15.353488662000018],
            [171.726005660000084, -15.370324662000016],
            [171.729231660000181, -15.376723662000032],
            [171.734215660000103, -15.393569661999962],
            [171.737437660000211, -15.399957661999963],
            [171.742425660000123, -15.41681366200001],
            [171.74564366000007, -15.423192661999963],
            [171.750635660000142, -15.440057661999973],
            [171.753849660000157, -15.446426661999979],
            [171.758846660000216, -15.463301661999921],
            [171.762056660000241, -15.469660662],
            [171.767057660000177, -15.486545661999969],
            [171.770263660000211, -15.492894662000012],
            [171.775269660000134, -15.509789662999921],
            [171.778470660000181, -15.516128662999947],
            [171.783481660000149, -15.533032662999972],
            [171.786678660000206, -15.539362662999963],
            [171.791693660000163, -15.55627666300002],
            [171.79488666000023, -15.562595662999982],
            [171.799906660000062, -15.579519662999985],
            [171.803095660000139, -15.585829663],
            [171.808119660000187, -15.602762663000036],
            [171.811304660000104, -15.609062663000017],
            [171.816333660000197, -15.626006662999984],
            [171.819513660000183, -15.63229566300005],
            [171.82454766000015, -15.649249663000035],
            [171.827723660000032, -15.655528662999984],
            [171.832761660000159, -15.672491663],
            [171.835933660000052, -15.678761663],
            [171.840976660000223, -15.695734662999968],
            [171.844143660000071, -15.701994662999937],
            [171.849191660000116, -15.718977663000018],
            [171.852354660000202, -15.725227662999956],
            [171.857406660000066, -15.742220662999983],
            [171.860566660000046, -15.748459663],
            [171.865622660000184, -15.765462662999951],
            [171.868777660000291, -15.77169266300001],
            [171.873839660000073, -15.788704663],
            [171.876989660000135, -15.794924662999946],
            [171.882055660000248, -15.81194766299997],
            [171.885202660000203, -15.818156662999982],
            [171.890272660000136, -15.835189662999937],
            [171.893415660000159, -15.841388663],
            [171.893604160000081, -15.841961663],
            [172.060390660000252, -16.399891662999977],
            [172.064997660000046, -16.441586662999981],
            [172.071262660000258, -16.44983366299995],
            [172.080853660000145, -16.458691663000025],
            [172.08786666000006, -16.467921662999984],
            [172.09744466000015, -16.476763662999971],
            [172.104471660000144, -16.486008663],
            [172.114035660000098, -16.494835663000018],
            [172.121075660000116, -16.504095662999958],
            [172.130625660000163, -16.512907662999964],
            [172.137679660000146, -16.522181663],
            [172.147216660000169, -16.530979663],
            [172.154283660000118, -16.540268662999935],
            [172.163807660000117, -16.549051662999943],
            [172.170887660000261, -16.558355662999972],
            [172.180397660000011, -16.567123662999975],
            [172.187491660000063, -16.576441663000011],
            [172.196988660000187, -16.585194664000014],
            [172.204095660000263, -16.594528663999952],
            [172.213578660000252, -16.60326666399996],
            [172.220699660000065, -16.612614664],
            [172.230168660000317, -16.621337664],
            [172.237303660000208, -16.630701663999943],
            [172.246759660000151, -16.639408663999959],
            [172.253907660000237, -16.648787663999983],
            [172.263349660000046, -16.657479664],
            [172.270510660000099, -16.666873664000022],
            [172.27993966000011, -16.675550663999957],
            [172.287114660000128, -16.684959663999976],
            [172.296529660000175, -16.693621664],
            [172.303718660000101, -16.703045664000015],
            [172.31311966000024, -16.711692663999955],
            [172.320321660000189, -16.721131663999955],
            [172.329709660000134, -16.729763663999989],
            [172.336925661000265, -16.739216664000011],
            [172.346299661000131, -16.747833663999955],
            [172.353528661000126, -16.757302663999951],
            [172.362889661000196, -16.76590466399999],
            [172.370132661000156, -16.775387664],
            [172.379478661000206, -16.783974664000041],
            [172.386735661000245, -16.793473664000047],
            [172.396068661000101, -16.802044664],
            [172.403339661000047, -16.811558664],
            [172.412658661000165, -16.820115663999957],
            [172.419942661000135, -16.82964366399996],
            [172.429247661000119, -16.838185664],
            [172.436545661000167, -16.847729664],
            [172.445837661000013, -16.85625566399996],
            [172.453149661000197, -16.865814664000041],
            [172.462426661000194, -16.874324664000014],
            [172.469752661000058, -16.883898664],
            [172.479015661000204, -16.892394663999966],
            [172.486355661000147, -16.901983663999957],
            [172.495605661000099, -16.910464664000017],
            [172.502958661000235, -16.920068663999913],
            [172.512194661000052, -16.928533663999985],
            [172.519561661000154, -16.938153663999955],
            [172.528783661000062, -16.946603663999937],
            [172.536164661000072, -16.956237664000014],
            [172.545372661000243, -16.964672664],
            [172.552767661000161, -16.97432266399997],
            [172.561961661000197, -16.98274166399996],
            [172.569370661000193, -16.99240666399993],
            [172.578550661000207, -17.000810664000014],
            [172.585973661000111, -17.010490663999988],
            [172.595139661000218, -17.018879663999982],
            [172.602576661000199, -17.028574663999947],
            [172.611728661000171, -17.036948664000036],
            [172.619179661000118, -17.046659663999989],
            [172.628317661000182, -17.055017664],
            [172.635782661000206, -17.064742663999965],
            [172.644906661000135, -17.073086663999959],
            [172.652384661000184, -17.082826664],
            [172.661494661000262, -17.09115466399993],
            [172.668987661000102, -17.100910663999969],
            [172.678083661000215, -17.109222664],
            [172.685590661000191, -17.118994664000027],
            [172.694671661000115, -17.127291663999955],
            [172.702192661000169, -17.137077663999989],
            [172.711260661000296, -17.145359664000026],
            [172.718795661000087, -17.155161663999962],
            [172.727848661000195, -17.163427664],
            [172.735397661000235, -17.173244664],
            [172.744437661000205, -17.181495663999968],
            [172.752000661000324, -17.191328663999982],
            [172.761025661000275, -17.199563664000024],
            [172.768602661000131, -17.209411663999944],
            [172.777613661000174, -17.217631664],
            [172.785204661000165, -17.227494664],
            [172.794201661000073, -17.235699663999966],
            [172.801807661000254, -17.245577663999967],
            [172.8107896610002, -17.25376666399994],
            [172.818409661000061, -17.263660663999943],
            [172.827377661000099, -17.271834664],
            [172.835011661000038, -17.281743664],
            [172.843965661000169, -17.289901663999984],
            [172.851613661000243, -17.299825663999968],
            [172.860553661000239, -17.307968663999958],
            [172.868215661000221, -17.317908663999944],
            [172.877141661000138, -17.326035664000017],
            [172.87871166100021, -17.328071664],
            [172.880535661000152, -17.329733663999932],
            [173.242174661000064, -17.79697666499996],
            [173.581944444000243, -18.313611110999972],
            [173.560000000000315, -18.543333332999978],
            [172.764876111000177, -20.022462973000017],
            [172.143535660000254, -20.399649666999949],
            [172.123802660000166, -20.410949666999926],
            [172.097602660000263, -20.426154666999892],
            [172.0716006600002, -20.441447666999935],
            [172.04579666000015, -20.456828666999982],
            [172.020191660000222, -20.47229766699995],
            [171.996400660000148, -20.486864666999963],
            [171.994782660000197, -20.487853667],
            [171.969542660000144, -20.503484666999938],
            [171.944501660000157, -20.519203666999886],
            [171.919659660000178, -20.535011666999921],
            [171.895016660000266, -20.550907666999947],
            [171.870572660000306, -20.566891666999965],
            [171.866218660000158, -20.569778667],
            [171.840843660000047, -20.58057966699991],
            [171.810175660000112, -20.59386066699993],
            [171.779767660000203, -20.607256666999973],
            [171.749620660000147, -20.620767666999924],
            [171.719733660000173, -20.634393666999884],
            [171.690108660000163, -20.648134666999951],
            [171.66074466000012, -20.661991666999938],
            [171.631641660000156, -20.675963666999934],
            [171.602800660000213, -20.690050666999937],
            [171.574221660000234, -20.704254666999944],
            [171.545904660000105, -20.718573666999873],
            [171.517849660000167, -20.733008667],
            [171.490056660000079, -20.747558666999936],
            [171.462525660000125, -20.762225666999967],
            [171.435258660000017, -20.777008666999919],
            [171.408253660000099, -20.791907666999975],
            [171.381511660000086, -20.806923666999921],
            [171.355032660000205, -20.822054666999989],
            [171.339709660000125, -20.830968667],
            [171.303965660000102, -20.851814666999957],
            [171.038147659000089, -21.005786667999885],
            [171.016881659000234, -21.018278667999908],
            [170.993860659000262, -21.032069667999934],
            [170.977719659000201, -21.041929667999966],
            [170.267115659000211, -21.476803667999903],
            [169.995513658000078, -21.641142667999887],
            [169.959004658000111, -21.629085667999973],
            [169.9333426580001, -21.6208126679999],
            [169.907704658000085, -21.612767667999947],
            [169.471476658000171, -21.47904366799996],
            [169.44197265800014, -21.444192667999943],
            [168.960091657000049, -20.869807666999932],
            [168.948354657000181, -20.855697666999973],
            [168.88708665700014, -20.781859666999935],
            [168.871016657000069, -20.762970666999891],
            [168.854678657000051, -20.744244667],
            [168.838072657000197, -20.725681666999932],
            [168.834647657000147, -20.721947666999895],
            [168.821220657000111, -20.70726766699994],
            [168.804107657000117, -20.689013666999941],
            [168.786725657000062, -20.670922666999928],
            [168.769076657000113, -20.652995666999985],
            [168.75885365700006, -20.642860666999951],
            [168.753105657000191, -20.634050666999926],
            [168.739460657000137, -20.613695666999917],
            [168.725554657000117, -20.593497666999923],
            [168.711389657000069, -20.573457666999943],
            [168.696964657000166, -20.55357566699999],
            [168.682279657000237, -20.533851666999951],
            [168.667334657000112, -20.514284666999941],
            [168.652130657000129, -20.494875666999945],
            [168.63666665700012, -20.475625666999974],
            [168.630377657000082, -20.467988666999929],
            [168.621138657000103, -20.456414666999933],
            [168.605480657000129, -20.437281666999951],
            [168.589563657000127, -20.418307666999979],
            [168.584881657000182, -20.412861666999945],
            [168.57341965700013, -20.399472666999955],
            [168.557029657000186, -20.380786666999924],
            [168.540381657000097, -20.362259666999904],
            [168.523475657000091, -20.3438896669999],
            [168.506310657000228, -20.325677667],
            [168.488887657000163, -20.307623666999945],
            [168.471207657000122, -20.289727667],
            [168.453269657000163, -20.271989666999971],
            [168.4421276570001, -20.261225666999934],
            [168.435092657000069, -20.254398666999947],
            [168.416688657000151, -20.236946666999955],
            [168.398026657000088, -20.219652666999906],
            [168.379108657000103, -20.202516666999955],
            [168.359932657000144, -20.185538666999932],
            [168.341487657000158, -20.169573666999966],
            [168.256816657000144, -20.097714666999877],
            [168.23352365700012, -20.062318666999971],
            [168.226008657000108, -20.050922666999952],
            [168.212106657000078, -20.030350666999922],
            [168.197948657000126, -20.009897666999976],
            [168.183534657000138, -19.989561666999933],
            [168.168863657000117, -19.969344666999959],
            [168.165728657000074, -19.965124666999941],
            [168.155605657000052, -19.951458666999955],
            [168.075352657000082, -19.844956667],
            [168.065850657000141, -19.832399666999834],
            [168.050011657000113, -19.811926666999923],
            [168.033896656000053, -19.791550665999935],
            [168.017506656000052, -19.771269665999924],
            [168.000841656000063, -19.751085665999923],
            [167.983900656000088, -19.730996665999982],
            [167.966549156000127, -19.710849165999903],
            [167.949845656000122, -19.690883665999962],
            [167.932745656000094, -19.670853665999928],
            [167.916202656000195, -19.651866665999947],
            [167.915377656000061, -19.650916665999944],
            [167.897774656000109, -19.631062665999906],
            [167.879901656000101, -19.611303666],
            [167.86411665600022, -19.594193665999882],
            [167.861786656000078, -19.591628665999934],
            [167.843590656000089, -19.571982665999954],
            [167.825125656000097, -19.552431665999947],
            [167.806393656000097, -19.532973665999975],
            [167.787394656000203, -19.513608665999982],
            [167.768127656000132, -19.494338665999948],
            [167.751654656000056, -19.478167665999905],
            [167.744698656000168, -19.472815665999931],
            [167.723836656000202, -19.457064665999908],
            [167.702764656000198, -19.441448665999957],
            [167.681480656000105, -19.425967665999977],
            [167.660180156000109, -19.41075866599995],
            [167.638308656000135, -19.395392666],
            [167.617368656000139, -19.380952665999914],
            [167.616421656000085, -19.38029766599989],
            [167.594348656000108, -19.365322665999955],
            [167.572065656000092, -19.350480665999925],
            [167.552676656000102, -19.337803665999957],
            [167.550318656000144, -19.335303665999959],
            [167.533039656000057, -19.317300665999952],
            [167.515573656000043, -19.299415665999931],
            [167.497922656000156, -19.281649666],
            [167.480085656000114, -19.264001665999956],
            [167.462062656000143, -19.24647166599992],
            [167.443854656000127, -19.229060665999953],
            [167.425460656000183, -19.211768665999969],
            [167.406880656000197, -19.194594665999986],
            [167.38811565600011, -19.177539665999987],
            [167.369164656000095, -19.160602665999889],
            [167.350028656000148, -19.143783665999962],
            [167.330707656000158, -19.127084665999931],
            [167.311201656000122, -19.1105036659999],
            [167.291509656000045, -19.094040665999941],
            [167.271633656000148, -19.077696665999966],
            [167.251572656000207, -19.061470665999906],
            [167.231325656000166, -19.045364665999912],
            [167.210894656000079, -19.029375665999922],
            [167.190278656000061, -19.013506665999913],
            [167.169478656000052, -18.99775566599989],
            [167.14849365600017, -18.982122665999952],
            [167.127324656000127, -18.966608665999914],
            [167.105970656000096, -18.951213665999944],
            [167.084432656000189, -18.935936665999975],
            [167.062710656000064, -18.920778665999904],
            [167.040804656000233, -18.905739665999917],
            [167.018713656000074, -18.890818666],
            [166.996439656000206, -18.876015665999901],
            [166.97398065600018, -18.86133166599997],
            [166.951338655000114, -18.846766665999937],
            [166.928513655000103, -18.832319665999904],
            [166.90550365500016, -18.817990665999943],
            [166.882310655000168, -18.80378066599998],
            [166.858934655000184, -18.789689665999987],
            [166.835374655000209, -18.775715665999925],
            [166.811631655000184, -18.761860665999919],
            [166.78770565500011, -18.74812466600001],
            [166.763596655000043, -18.734506665999987],
            [166.739304655000097, -18.721006664999962],
            [166.714828655000048, -18.70762466499994],
            [166.690170655000173, -18.694361664999988],
            [166.668999655000135, -18.683158664999951],
            [166.620638655000135, -18.657854664999903],
            [166.532477655000122, -18.611376664999923],
            [166.515658655000124, -18.602544664999954],
            [166.48949165500008, -18.589018665000012],
            [166.463127655000136, -18.575603664999917],
            [166.436566655000121, -18.562298664999943],
            [166.40980665500004, -18.549103664999933],
            [166.382849655000172, -18.536018664999972],
            [166.355695655000176, -18.52304366499996],
            [166.338538655000178, -18.514974664999968],
            [166.329537655000166, -18.509525664999956],
            [166.305215655000183, -18.495004664999925],
            [166.280719655000127, -18.48058066499992],
            [166.256049655000112, -18.466253664999954],
            [166.231206655000079, -18.452023664999928],
            [166.206189655000202, -18.437890664999927],
            [166.180998655000081, -18.423853664999967],
            [166.162519655000125, -18.413698664999956],
            [166.155636655000109, -18.409913664999962],
            [166.130105655000108, -18.396066665],
            [166.104401655000089, -18.382316665],
            [166.078524655000166, -18.368663664999929],
            [166.052474655000111, -18.355106664999894],
            [166.026251655000152, -18.341646664999899],
            [165.999855655000061, -18.32828366499993],
            [165.973286655000067, -18.315016665],
            [165.946544655000054, -18.301845664999931],
            [165.919630655000191, -18.288771664999985],
            [165.89254365400015, -18.275793665],
            [165.865284654000135, -18.262911664999947],
            [165.84278265400016, -18.252423665],
            [165.840587654000075, -18.24862866499997],
            [165.828251654000184, -18.227577664999927],
            [165.8157946540002, -18.206592664999917],
            [165.803216654000067, -18.185673664999953],
            [165.790517654000183, -18.164820664999937],
            [165.777696654000209, -18.144033664999981],
            [165.76475565400014, -18.123312664999958],
            [165.751692654000152, -18.102658665],
            [165.738508654000071, -18.082069664999977],
            [165.72520465400018, -18.061547665],
            [165.711778654000256, -18.041091664999968],
            [165.698231654000125, -18.020701664999905],
            [165.684564654000184, -18.000378664999957],
            [165.670776654000093, -17.98012166499997],
            [165.656867654000081, -17.959930664999931],
            [165.642837654000203, -17.939806664999935],
            [165.62868665400012, -17.919747664999889],
            [165.614415654000055, -17.899756664999884],
            [165.600023654000125, -17.879830664999929],
            [165.585511654000044, -17.859972664999901],
            [165.570878654000097, -17.840179664999937],
            [165.556125654000112, -17.820453664999917],
            [165.541251654000206, -17.800794664999941],
            [165.526257654000204, -17.781201665],
            [165.521939654000136, -17.775623664999969],
            [165.513410654000126, -17.765456664999945],
            [165.497513654000073, -17.74671866499996],
            [165.481504654000133, -17.728053664999976],
            [165.465382654000251, -17.709463664999888],
            [165.449147654000143, -17.690948664999965],
            [165.432799654000092, -17.672506664999958],
            [165.416339654000041, -17.654139664999946],
            [165.399765654000049, -17.635846663999914],
            [165.383080654000111, -17.617628663999966],
            [165.366282654000173, -17.599484663999931],
            [165.34937165400018, -17.581415663999962],
            [165.332347654000074, -17.563419664],
            [165.315212654000192, -17.545499664000019],
            [165.297963654000142, -17.527653663999942],
            [165.280603654000146, -17.509881663999948],
            [165.263130654000094, -17.492184663999936],
            [165.24554565400004, -17.474561663999921],
            [165.231062654000112, -17.460201663999982],
            [165.229134654000092, -17.456165664],
            [165.218407654000117, -17.434021663999928],
            [165.20756665400009, -17.411951663999943],
            [165.196610654000125, -17.389955663999956],
            [165.185540654000107, -17.36803466399995],
            [165.17435665400015, -17.346186663999944],
            [165.163057654000141, -17.324413663999934],
            [165.151644654000137, -17.302713663999924],
            [165.140116654000138, -17.281088663999981],
            [165.1284746540002, -17.259538663999948],
            [165.116718654000209, -17.238061663999915],
            [165.104848654000165, -17.216659663999948],
            [165.092864654000124, -17.19533166399998],
            [165.080765654000146, -17.174078664],
            [165.068552654000115, -17.152899663999932],
            [165.056225654000144, -17.131795663999938],
            [165.043784654000177, -17.110765663999942],
            [165.0312296540001, -17.089809663999944],
            [165.018560654000197, -17.06892966399991],
            [165.005777654000127, -17.048123663999974],
            [164.992880654000118, -17.027391663999936],
            [164.979870654000052, -17.006735663999976],
            [164.966745654000107, -16.986153664000014],
            [164.953506654000108, -16.965646663999934],
            [164.940154654000111, -16.945214663999948],
            [164.92668865400006, -16.924856663999947],
            [164.916328654000125, -16.909383663999932],
            [164.693522653000088, -16.578040662999982],
            [164.683282653000134, -16.562867663],
            [164.669262653000118, -16.542340662999962],
            [164.655113653000086, -16.521869662999933],
            [164.640837653000091, -16.50145566299993],
            [164.626433653000078, -16.481098662999926],
            [164.611902653000101, -16.460797662999937],
            [164.598216653000151, -16.441898662999947],
            [164.597244653000104, -16.440552662999963],
            [164.582478653000152, -16.420355662999953],
            [164.567585653000066, -16.400215662999884],
            [164.55256565300013, -16.380131662999901],
            [164.537417653000176, -16.360105662999928],
            [164.529258653000085, -16.349439662999913],
            [164.132440653000145, -15.829336662999893],
            [164.116101653000186, -15.808056662999903],
            [164.099033653000134, -15.78605266299995],
            [164.081813653000069, -15.764073662999948],
            [164.064440653000048, -15.742121662999963],
            [164.046916653000068, -15.720194662999987],
            [164.029239653000133, -15.698293662999959],
            [164.01141065300024, -15.676417662999953],
            [163.993430653000047, -15.654568662999949],
            [163.975297653000126, -15.632744662999897],
            [163.957013653000132, -15.610945662999953],
            [163.938576653000126, -15.589173662999926],
            [163.919988653000104, -15.567426662999935],
            [163.901249653000121, -15.545704662999967],
            [163.882358653000182, -15.524008662999933],
            [163.863315653000114, -15.502338661999914],
            [163.844120653000147, -15.480693661999922],
            [163.824775653000216, -15.459074661999949],
            [163.805278653000101, -15.437480661999913],
            [163.785629653000143, -15.4159116619999],
            [163.765830653000165, -15.394368662],
            [163.745879653000173, -15.372851661999931],
            [163.728057652000217, -15.353796662],
            [163.296547652000157, -14.935241661999939],
            [163.166666652000202, -14.834166661999959],
            [163.556046652000219, -13.593826660999937],
            [163.564885652000129, -13.581235660999894],
            [163.581962652000101, -13.556677660999938],
            [163.598879652000136, -13.532116660999904],
            [163.615636652000234, -13.507553660999875],
            [163.632231652000172, -13.482988660999951],
            [163.648667652000341, -13.458421660999875],
            [163.664942652000235, -13.433852660999889],
            [163.681056652000137, -13.409281660999909],
            [163.697011652000157, -13.38470966099986],
            [163.712805652000242, -13.360134660999904],
            [163.728438652000108, -13.335557659999878],
            [163.743912652000319, -13.310978659999861],
            [163.759225653000129, -13.286398659999946],
            [163.774379653000238, -13.261816659999866],
            [163.789372653000299, -13.237232659999876],
            [163.80420665300008, -13.212646659999905],
            [163.818143653000192, -13.189291659999924],
            [163.897709653000305, -13.054564659999912],
            [163.905489653000103, -13.041365659999926],
            [163.91947665300026, -13.017365659999854],
            [163.933308653000068, -12.993357659999916],
            [163.946932653000232, -12.969430659999858],
            [164.147975653000259, -12.611638659999883],
            [164.246609653000149, -12.579437659999826],
            [164.300865653000102, -12.562078659999898],
            [164.354868653000239, -12.545166659999921],
            [164.408417653000157, -12.528767659999929],
            [164.461511653000258, -12.512882659999846],
            [164.514150653000314, -12.497510659999833],
            [164.5663336530001, -12.482654659999881],
            [164.618061653000069, -12.46831266],
            [164.669332653000282, -12.454487659999813],
            [164.720148653000109, -12.441179659999875],
            [164.770508653000178, -12.428387659999899],
            [164.820412654000137, -12.416113659999894],
            [164.86985965400018, -12.404358659999914],
            [164.918849654000184, -12.393122659999904],
            [164.967382654000147, -12.382405659999932],
            [165.015458654000184, -12.372208659999913],
            [165.063077654000239, -12.362532659999829],
            [165.11023965400031, -12.353377659999865],
            [165.156943654000173, -12.34474365999985],
            [165.203188654000229, -12.336631659999853],
            [165.248976654000131, -12.329042659999885],
            [165.294306654000167, -12.321976659999933],
            [165.339177654000224, -12.315434659999895],
            [165.383589654000133, -12.309415659999885],
            [165.427543654000175, -12.303921659999872],
            [165.471037654000128, -12.298951659999886],
            [165.514072654000159, -12.294507659999894],
            [165.556648654000213, -12.290588659999912],
            [165.598764654000178, -12.287196659999921],
            [165.640420654000224, -12.284330659999924],
            [165.681616654000294, -12.281990658999931],
            [165.722352654000105, -12.280178658999844],
            [165.762628654000167, -12.278893658999834],
            [165.802443654000086, -12.278136658999827],
            [165.841797654000146, -12.277907658999879],
            [165.880690654000347, -12.278207658999918],
            [165.91912265500028, -12.279035658999859],
            [165.957092655000253, -12.28039365899987],
            [165.994601655000196, -12.282279658999869],
            [166.031648655000112, -12.284696659999923],
            [166.068233655000171, -12.287642659999875],
            [166.104356655000203, -12.291118659999881],
            [166.140017655000094, -12.295125659999869],
            [166.175215655000187, -12.29966266],
            [166.20995165500014, -12.30472965999985],
            [166.244223655000127, -12.310328659999925],
            [166.278033655000087, -12.316458659999896],
            [166.304770655000226, -12.321799659999854],
            [166.519127655000176, -12.366970659999865],
            [166.530766655000235, -12.369514659999851],
            [166.644848655000118, -12.395359659999897],
            [166.653902655000223, -12.397529659999947],
            [166.666009655000124, -12.400429659999816],
            [166.668124655000128, -12.400897659999899],
            [166.686115655000293, -12.405273659999864],
            [166.690032655000238, -12.406247659999892],
            [166.714102655000119, -12.411791659999864],
            [166.738245655000043, -12.416912659999923],
            [166.762462655000121, -12.421609659999888],
            [166.786752655000186, -12.425882659999843],
            [166.811115655000179, -12.429731659999902],
            [166.8355526550001, -12.433156659999867],
            [166.860062655000178, -12.436157659999836],
            [166.884645655000242, -12.438735659999892],
            [166.909302655000062, -12.440889659999954],
            [166.934032655000209, -12.442619659999821],
            [166.958836655000113, -12.443926659999875],
            [166.983713656000106, -12.444808659999936],
            [167.00866465600015, -12.445267659999899],
            [167.033688656000237, -12.445302659999953],
            [167.058785656000197, -12.444913659999827],
            [167.083956656000083, -12.444101659999873],
            [167.109200656000127, -12.442865659999839],
            [167.134518656000097, -12.441204659999897],
            [167.154239656000215, -12.439586659999875],
            [167.159895656000145, -12.439205659999885],
            [167.180867656000061, -12.437450659999882],
            [167.185278656000122, -12.43716765999983],
            [167.210988656000126, -12.435094159999863],
            [167.230840656000197, -12.433554659999942],
            [167.236030656000281, -12.433182659999886],
            [167.261447656000229, -12.430950659999866],
            [167.286936656000336, -12.428308659999942],
            [167.312495656000152, -12.425253659999854],
            [167.338127656000353, -12.421788659999862],
            [167.363830656000374, -12.417911659999888],
            [167.389604656000216, -12.413623659999828],
            [167.415449656000163, -12.408923659999886],
            [167.419021656000126, -12.408218659999818],
            [167.441313656000176, -12.404117659999869],
            [167.442423656000102, -12.403896659999887],
            [167.467175656000137, -12.399322659999882],
            [167.493105656000097, -12.394140659999849],
            [167.51910465600011, -12.388554659999869],
            [167.545174656000114, -12.382565659999855],
            [167.571314656000055, -12.376172659999895],
            [167.597524656000104, -12.369375659999903],
            [167.62380465600009, -12.36217465999988],
            [167.650154656000183, -12.35456966],
            [167.676574656000213, -12.346561659999907],
            [167.703064656000123, -12.338148659999874],
            [167.729625656000252, -12.329331659999909],
            [167.756256656000318, -12.3201096599999],
            [167.78295765600015, -12.310484659999872],
            [167.798618656000173, -12.304616659999922],
            [167.807702656000146, -12.301191659999859],
            [168.238470657000249, -12.794516659999914],
            [168.474563657000232, -13.064683659999872],
            [168.515564657000112, -13.111748659999833],
            [168.533284657000223, -13.131601659999873],
            [168.553132657000134, -13.15321465999989],
            [168.573463657000133, -13.17474465999986],
            [168.594277657000106, -13.196190659999914],
            [168.615575657000164, -13.217552659999939],
            [168.63735665700014, -13.238831659999931],
            [168.659620657000261, -13.260026659999909],
            [168.682369657000123, -13.281136659999859],
            [168.705602657000242, -13.302163659999877],
            [168.729319657000218, -13.32310665999988],
            [168.745532657000041, -13.337079659999873],
            [168.767327657000379, -13.351476659999861],
            [168.792321657000315, -13.367574660999892],
            [168.817692657000151, -13.383509660999863],
            [168.843442657000168, -13.399281660999932],
            [168.863129657000144, -13.411043660999866],
            [168.86956265700033, -13.414893660999908],
            [168.896039657000159, -13.43035266099993],
            [168.922894657000114, -13.445648660999865],
            [168.950127657000138, -13.460781660999913],
            [168.977738657000117, -13.47575166099989],
            [169.005729657000103, -13.490558660999866],
            [169.034098657000158, -13.505202660999871],
            [169.062847657000219, -13.519682660999877],
            [169.09197565700012, -13.53399966099991],
            [169.12148365800013, -13.548152660999875],
            [169.151371658000045, -13.562142660999854],
            [169.181639658000194, -13.575968660999848],
            [169.212287658000179, -13.589631660999856],
            [169.218066658000311, -13.592145660999932],
            [169.243278658000207, -13.603146660999869],
            [169.27464165800032, -13.616501660999887],
            [169.306383658000328, -13.629692660999922],
            [169.338506658000114, -13.642719660999887],
            [169.367552658000278, -13.654214660999926],
            [169.370999658000159, -13.655588660999925],
            [169.403771658000295, -13.668336660999898],
            [169.436923658000097, -13.680920660999888],
            [169.461120658000112, -13.689884660999851],
            [169.470429658000199, -13.693353660999918],
            [169.504243658000149, -13.705653660999857],
            [169.538435658000282, -13.717789660999912],
            [169.573007658000193, -13.729763660999879],
            [169.607958658000229, -13.741573660999862],
            [169.638892658000174, -13.751770660999895],
            [169.665513658000151, -13.760384660999847],
            [169.920400658000148, -13.843392660999854],
            [170.098962658000204, -13.979990660999874],
            [170.312065659000126, -14.142664660999941],
            [170.329428659000229, -14.155859660999866],
            [170.357513659000148, -14.176953661000013],
            [170.38587965900021, -14.198008660999861],
            [170.414524659000136, -14.219023660999852],
            [170.44345165900026, -14.23999866099993],
            [170.472657659000248, -14.260934660999879],
            [170.502145659000263, -14.281831660999885],
            [170.53191465900025, -14.302688660999863],
            [170.561963659000156, -14.323505660999913],
            [170.5922956590002, -14.344282660999951],
            [170.622907659000163, -14.365019660999863],
            [170.653801659000152, -14.385716660999847],
            [170.684977659000225, -14.40637366099989],
            [170.716435659000155, -14.426990660999905],
            [170.748175659000168, -14.447566661999829],
            [170.780197659000265, -14.468103661999891],
            [170.812502659000273, -14.488598661999859],
            [170.845090659000135, -14.509053661999886],
            [170.877960659000195, -14.5294686619999],
            [170.911113659000165, -14.54984266199989],
            [170.94454965900033, -14.570175661999855],
            [170.978269659000233, -14.590467661999895],
            [171.012272659000047, -14.610718661999925],
            [171.046559659000167, -14.630928661999931],
            [171.081129659000197, -14.651097661999842],
            [171.115984659000191, -14.671224661999915],
            [171.151123659000262, -14.691310661999879],
            [171.186546659000186, -14.711355662],
            [171.222254659000413, -14.731357661999851],
            [171.258247659000261, -14.751319661999858],
            [171.294524660000405, -14.771238661999945],
            [171.331087660000293, -14.791115661999939],
            [171.36793566000037, -14.810951661999908],
            [171.405069660000294, -14.830744661999873],
            [171.44248866000018, -14.850495661999929],
            [171.480193660000253, -14.870204661999864],
            [171.518184660000173, -14.889870661999893],
            [171.556462660000278, -14.909493661999832],
            [171.595026660000173, -14.929074661999849],
            [171.616697660000199, -14.939974661999855],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "WLF", Country: "Wallis & Futuna" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.621849668000038, -9.900742294999887],
            [-175.259355947999978, -10.183258713999876],
            [-175.032556845999977, -10.359680928999907],
            [-174.987993492999976, -10.394287553999874],
            [-174.958489646999965, -10.417199395999972],
            [-174.847757197000021, -10.503167606000034],
            [-174.365323269000044, -10.875869880999915],
            [-174.273371210000022, -10.946565564999844],
            [-174.323974238999881, -11.479796931999971],
            [-174.328501515000028, -11.529854359999987],
            [-174.385031552000015, -12.147868893],
            [-174.385127524999859, -12.14884068899984],
            [-174.425793115999966, -12.666322321999871],
            [-174.432498657000025, -12.751652305999883],
            [-174.436066552, -12.799734260999969],
            [-174.471805482000036, -13.281362110000046],
            [-174.471848200999943, -13.281937279499985],
            [-174.471898117999984, -13.282611676999963],
            [-174.484357961, -13.451042276999914],
            [-174.488983478, -13.513569446999981],
            [-174.502020225999985, -13.688516767999914],
            [-174.527065971999974, -14.024619457999961],
            [-174.52997972, -14.092548666999875],
            [-174.532155416, -14.143271433999971],
            [-174.570091808999848, -14.15345308299996],
            [-174.626535248999971, -14.168601788999979],
            [-174.684434217999979, -14.182778737999968],
            [-174.782391504000032, -14.206764234],
            [-174.868191862999964, -14.22775607200002],
            [-174.966941835999961, -14.251916149999929],
            [-174.984545587999975, -14.256224681],
            [-175.065335716999869, -14.275917789999951],
            [-175.067239386000011, -14.27637924],
            [-175.071868221000017, -14.277501271999967],
            [-175.298387351000031, -14.33170119499998],
            [-175.562887242999864, -14.394988891],
            [-175.600900909000018, -14.404000688999929],
            [-175.628483066, -14.410539516999961],
            [-175.81021454, -14.453284472999968],
            [-175.85923219599988, -14.464639065000028],
            [-176.002081478000036, -14.497729086],
            [-176.039427187999877, -14.506272205999963],
            [-176.047642386999883, -14.508151496000025],
            [-176.082782740499908, -14.516068675999975],
            [-176.170765247, -14.535413470999984],
            [-176.215421575499988, -14.546050635499967],
            [-176.36227067400003, -14.581025938000025],
            [-176.384913672999971, -14.586353158],
            [-176.642178818999923, -14.646062698999984],
            [-176.769279896999933, -14.869322516999972],
            [-176.783026394000018, -14.893350730999984],
            [-176.808186231000036, -14.93732891099998],
            [-176.870943169999919, -15.046472749000017],
            [-176.916658805999873, -15.125979175999944],
            [-176.947546714, -15.179209168],
            [-176.973905275, -15.224633611999934],
            [-176.979066263999982, -15.233467333999954],
            [-177.189441516999977, -15.593673749999937],
            [-177.201615618, -15.61441365],
            [-177.220593060999988, -15.648176142999944],
            [-177.323963825000021, -15.832081562999988],
            [-177.376235, -15.93662852],
            [-178.516512777999964, -15.296350583],
            [-179.239568333000022, -14.804961574999979],
            [-179.999999630333349, -13.881135718166604],
            [-180.000000257999972, -12.999998134999927],
            [-180.0, -12.943964335999965],
            [-179.931455688999932, -12.898626396999816],
            [-179.890047826999989, -12.871215309999954],
            [-179.729749594000026, -12.764510326],
            [-179.702992385999892, -12.746672702999987],
            [-179.699718081999976, -12.744489896999951],
            [-179.661684262000023, -12.719134750999842],
            [-178.985986079999975, -12.269684078999887],
            [-178.982955671999974, -12.267671271999973],
            [-178.979347673000035, -12.265274826999843],
            [-178.973109662000013, -12.26113152],
            [-178.824399813000014, -12.162249262999936],
            [-178.823394347000033, -12.161580694999955],
            [-178.225824531, -11.764236116999882],
            [-178.220138469, -11.754135258999909],
            [-178.001778257000041, -11.36623489399993],
            [-177.950890524999977, -11.275836695999971],
            [-177.711038978000033, -10.849758603999859],
            [-177.55361436299998, -10.686564319999903],
            [-177.380518448, -10.507124377999858],
            [-177.362452213000012, -10.488430593999979],
            [-177.32012888, -10.444637119],
            [-177.317988215999975, -10.442422095999859],
            [-177.311633756999981, -10.435846908000016],
            [-177.283471222999964, -10.406695578999873],
            [-177.256233347999938, -10.378501373999967],
            [-177.173988819999863, -10.293369214999885],
            [-176.885394346999959, -10.022378974999938],
            [-176.782302987000037, -9.925576176999854],
            [-176.749347613999987, -9.894517621],
            [-176.379913663, -9.863068068999951],
            [-176.202844623, -9.847994353999923],
            [-175.962508935999978, -9.869813576999945],
            [-175.621849668000038, -9.900742294999887],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "WSM", Country: "Samoa" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-173.746666662000024, -11.038055658],
            [-173.743610661999924, -11.041110657999909],
            [-173.263888660999982, -11.36888865899995],
            [-173.146110661, -11.448888658999934],
            [-172.804722660999971, -11.680277658999842],
            [-172.385833661, -11.89916665899986],
            [-172.381388660999903, -11.901666658999858],
            [-172.015277659999867, -12.090833658999856],
            [-171.74638866, -12.230277658999981],
            [-171.740277659999975, -12.233610658999979],
            [-171.62055566, -12.293333659999917],
            [-171.421666659999914, -12.392777659999865],
            [-171.290277659999958, -12.457499659999897],
            [-171.139999658999955, -12.496388659999894],
            [-170.607222658999945, -12.589166659999947],
            [-170.526388658999963, -12.60305566],
            [-170.512222659, -12.604999659999905],
            [-170.710833658999974, -13.151388659999967],
            [-170.738888659, -13.232222659999863],
            [-170.939999659, -13.844444660999855],
            [-170.965833659, -13.895277661],
            [-170.972222658999897, -13.908333660999887],
            [-170.992777659000041, -13.948333660999879],
            [-171.048055659, -14.051388661],
            [-171.05152765899993, -14.057638660999942],
            [-171.079999659, -14.104999660999965],
            [-171.246110658999982, -14.450555661999957],
            [-171.405833660000042, -14.779999662],
            [-171.526944660000026, -15.03277766199993],
            [-171.626944659999879, -15.238610661999985],
            [-171.84555566, -15.836666663000017],
            [-171.945218659999853, -15.728741663],
            [-172.287005660000034, -15.393382661999951],
            [-172.427259661000022, -15.268821661999965],
            [-172.458507661, -15.241100661999951],
            [-172.487615661000035, -15.215307661999944],
            [-172.539516661, -15.169437662],
            [-172.640544660999979, -15.079925661999923],
            [-172.734123660999956, -15.031366661999982],
            [-172.826372660999937, -14.983233662000032],
            [-172.907155660999877, -14.940807661999955],
            [-172.942550660999984, -14.922061662000019],
            [-173.395191661000013, -14.682777662000035],
            [-173.466896661999982, -14.645182662],
            [-173.652719661999868, -14.550575661999957],
            [-173.707569661999941, -14.529599661999981],
            [-173.763338662000024, -14.50890766199997],
            [-173.956658662000024, -14.436812662],
            [-174.110799661999977, -14.379271661000018],
            [-174.442478662000042, -14.25943866099999],
            [-174.532155416, -14.143271433999971],
            [-174.52997972, -14.092548666999875],
            [-174.527065971999974, -14.024619457999961],
            [-174.502020225999985, -13.688516767999914],
            [-174.488983478, -13.513569446999981],
            [-174.484357961, -13.451042276999914],
            [-174.471898117999984, -13.282611676999963],
            [-174.471848200999943, -13.281937279499985],
            [-174.471805482000036, -13.281362110000046],
            [-174.436066552, -12.799734260999969],
            [-174.432498657000025, -12.751652305999883],
            [-174.425793115999966, -12.666322321999871],
            [-174.385127524999859, -12.14884068899984],
            [-174.385031552000015, -12.147868893],
            [-174.328501515000028, -11.529854359999987],
            [-174.323974238999881, -11.479796931999971],
            [-174.273371210000022, -10.946565564999844],
            [-174.075336651999947, -10.958864001999927],
            [-173.854222662000041, -11.006931657999885],
            [-173.746666662000024, -11.038055658],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "YEM", Country: "Yemen" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [57.432575372000173, 14.335011927500219],
            [57.535248554000162, 14.180471365000088],
            [57.775827554000074, 13.649911365000023],
            [57.917259554000083, 13.086697363999988],
            [57.955933554000154, 12.508010364000114],
            [57.955769554, 12.506547364000028],
            [57.955851554000134, 12.504350864000102],
            [57.956546554000084, 12.493018364],
            [57.891986554000113, 11.91638936300015],
            [57.726739554000147, 11.359182363000016],
            [57.466241054000164, 10.838061862000089],
            [57.46545755400021, 10.836505362000096],
            [57.117931553000204, 10.367101362000142],
            [57.116696053000197, 10.365900862000103],
            [56.692661553000079, 9.962068361000135],
            [56.204988552000117, 9.63599836100002],
            [56.148608552000184, 9.610993361000112],
            [56.113186552000087, 9.587234361000085],
            [56.100426552000187, 9.58157136100013],
            [56.081817552000217, 9.569085361000162],
            [55.840508552000216, 9.461814361],
            [55.827087552000165, 9.452788361000145],
            [55.825485552000117, 9.45205586100019],
            [55.818274052000135, 9.4487763610001],
            [55.816456552000119, 9.447898361000099],
            [55.815874552000167, 9.447619361000022],
            [55.808823552000121, 9.442876361000074],
            [55.807223552000067, 9.442143861000218],
            [55.80573805200018, 9.441418861000088],
            [55.26938655100011, 9.201890361000054],
            [54.699809551000186, 9.056945360000057],
            [54.652935551000127, 9.053292360000142],
            [54.455392551000131, 8.964250360000037],
            [54.429914551000167, 8.957717360000132],
            [54.016285550000106, 9.4356673610001],
            [53.934399550000109, 9.526876361000092],
            [53.312974550000177, 10.221156361000098],
            [53.115827549000102, 10.439666362000111],
            [53.101926549000183, 10.455073362000178],
            [53.012993549000129, 10.550887361999983],
            [53.011138549000208, 10.552877362000146],
            [52.658103549000117, 10.889135362000161],
            [52.632276549000068, 10.90319836200004],
            [52.476907549, 10.987400362000074],
            [52.347673549, 11.054034362000124],
            [52.335739549000124, 11.060007361999965],
            [52.299141549000154, 11.079131362000098],
            [52.256516549000054, 11.10135336200004],
            [52.208881549000097, 11.151689362000013],
            [52.130439549000045, 11.235144361999971],
            [52.121474549000169, 11.244577362000015],
            [52.072667548000055, 11.293584362000075],
            [52.057088548000053, 11.323708362000048],
            [52.027645548000095, 11.381301363000063],
            [51.99629954800011, 11.442260363000045],
            [51.965685548000096, 11.502013363000074],
            [51.95478554800016, 11.520548363000046],
            [51.90240754800007, 11.608709363],
            [51.900391548000158, 11.613109363],
            [51.88529954800012, 11.642330363000042],
            [51.847772548000108, 11.714573363000014],
            [51.842346548000052, 11.725236363000135],
            [51.820426548000142, 11.768810363000171],
            [51.790676548000107, 11.824086363000049],
            [51.787767548000119, 11.82952536300013],
            [51.758809548000073, 11.882839363000031],
            [51.734822548000096, 11.927868363000016],
            [51.689267548000117, 12.012670363000041],
            [51.682166548000197, 12.025955363000037],
            [51.616132548000138, 12.148766363],
            [51.57099054800014, 12.23331736300004],
            [51.569199548000171, 12.236664363000131],
            [51.567136548000093, 12.240461363000051],
            [51.552829548, 12.266688363000085],
            [51.529430548000192, 12.310065363000078],
            [51.50626554800013, 12.353168363000137],
            [51.463233548000147, 12.434299364],
            [51.393545548000105, 12.564906364000095],
            [51.384790548000211, 12.57684836400017],
            [51.357787548000175, 12.621162364000057],
            [51.338782548000125, 12.652496364000143],
            [51.296297548000069, 12.722851364000135],
            [51.282417548000041, 12.751811364000076],
            [51.102225548000121, 13.138336364000125],
            [50.984250547000073, 13.388363364],
            [50.932735547000078, 13.497539365000137],
            [50.347007547, 13.38397236400013],
            [50.337275547000075, 13.381615364000069],
            [50.143607547000073, 13.343740364000126],
            [50.000954547000021, 13.251401364000159],
            [49.905540546000083, 13.178219364000029],
            [49.719752546000137, 13.041880364000136],
            [49.61834254600015, 12.958278364],
            [49.54369654600012, 12.898175364000153],
            [49.512969546000107, 12.872932364000178],
            [49.336798546000153, 12.809292364000058],
            [48.995069546000167, 12.701217364000073],
            [48.930366546000215, 12.680396364000046],
            [48.714257545000095, 12.682467364000161],
            [48.569405545000137, 12.670421364],
            [48.465901545000094, 12.657499364000117],
            [48.405846545000117, 12.653008364000144],
            [48.32255254500015, 12.645717364000134],
            [48.319126545000103, 12.644252364000153],
            [48.127523545000173, 12.558144364000157],
            [48.087411545000151, 12.543282364000063],
            [47.793102545000096, 12.431177364000149],
            [47.625758544000149, 12.423543364000182],
            [47.609875544000062, 12.423005364000161],
            [47.599609544000174, 12.421732364],
            [47.367289544000158, 12.414216364000112],
            [47.364820544000082, 12.413800364000153],
            [47.276557544000212, 12.397494363000035],
            [47.250825544000094, 12.392118363000023],
            [47.104360544, 12.361513363000043],
            [46.705091543000066, 12.264479363000106],
            [46.693947543000178, 12.260651363],
            [46.413380543000102, 12.164559363000023],
            [46.399128543000216, 12.159716362999973],
            [46.301323543000166, 12.121459363000042],
            [46.300539543000042, 12.120716363000028],
            [46.260026543000066, 12.082397363000027],
            [46.170438543000074, 12.067281363000149],
            [46.040986543000173, 12.043670363000118],
            [45.806182543000062, 11.955057363000066],
            [45.609606542000137, 11.880754363000094],
            [45.515261542000104, 11.845000363000139],
            [45.486862542000125, 11.834720363000088],
            [45.418157542000159, 11.809154363000118],
            [45.322708542000072, 11.773740363000144],
            [45.298564542000094, 11.76480036300012],
            [45.23902454200018, 11.742800363000057],
            [45.16511254200006, 11.715134363000161],
            [45.136625542000132, 11.707301863000112],
            [45.131704542000222, 11.705637363000051],
            [45.111438542000116, 11.698486363000086],
            [45.077601542000053, 11.686574363000091],
            [44.91814654200013, 11.653045363000118],
            [44.90066754200015, 11.649104363],
            [44.845652542000067, 11.636808363000029],
            [44.816429542000066, 11.630287363000136],
            [44.705469542000202, 11.605588363000052],
            [44.704149542000124, 11.60521036300004],
            [44.610555542000071, 11.578303363000146],
            [44.604650542000087, 11.579709363000035],
            [44.602446542000138, 11.580304363000153],
            [44.56199254200007, 11.596689363000138],
            [44.465807541000032, 11.655939363000115],
            [44.3568165410002, 11.73464736300005],
            [44.316712541000044, 11.762685363],
            [44.282970541000196, 11.784447363000083],
            [44.256742541000079, 11.801199362999967],
            [44.196301541000054, 11.839283363000064],
            [44.146206541000112, 11.870122363000135],
            [44.143650541000198, 11.872747362999988],
            [44.085793541000186, 11.933322363000087],
            [44.01947354100011, 12.003063363000081],
            [43.988571541000084, 12.034967362999978],
            [43.985987541000128, 12.037670363000075],
            [43.96166454100009, 12.063156363000175],
            [43.950311541000161, 12.075012363000056],
            [43.947463541000133, 12.077921363000144],
            [43.920889541000093, 12.104892363],
            [43.909331541000171, 12.116963363000011],
            [43.861558541000164, 12.167898363000162],
            [43.845448541000025, 12.185886363000179],
            [43.827053541000083, 12.207484363],
            [43.810734041000188, 12.225923863000077],
            [43.802775541000102, 12.235728363000135],
            [43.796207541000143, 12.244338363000026],
            [43.776635541000161, 12.269806363000058],
            [43.76002954100008, 12.318659363000165],
            [43.725472541000073, 12.416892364],
            [43.718500041000112, 12.436072364000054],
            [43.694691541000083, 12.465711364000143],
            [43.656479541000152, 12.510924364000076],
            [43.65058054100021, 12.516144364000056],
            [43.574596541000147, 12.538260364000124],
            [43.539763041000157, 12.552455864000038],
            [43.531311541000122, 12.552868364000162],
            [43.527755541000062, 12.553033364000044],
            [43.523826541000147, 12.553152364000027],
            [43.50995254100016, 12.553300364000108],
            [43.497694541000072, 12.553333364],
            [43.432449540000022, 12.55370236400006],
            [43.428270540000113, 12.553681364000013],
            [43.396577540000152, 12.554497364000028],
            [43.389762540000078, 12.554194364000182],
            [43.387901540000115, 12.554530364000186],
            [43.381168540000175, 12.555753864000067],
            [43.363864540000094, 12.570783364],
            [43.350785540000203, 12.580947364000039],
            [43.335191540000011, 12.592162364],
            [43.326114540000191, 12.598547364000012],
            [43.306667540000177, 12.610952364000028],
            [43.303890540000083, 12.614776363999965],
            [43.295016540000091, 12.6272353640001],
            [43.294563540000098, 12.628169364000016],
            [43.294285540000118, 12.628774864000022],
            [43.288162540000116, 12.64391136400009],
            [43.287453540000143, 12.645866364000113],
            [43.284578540000126, 12.654926364000119],
            [43.284337540000166, 12.655729364000123],
            [43.283685540000221, 12.65782936400015],
            [43.283151540000091, 12.659479364000163],
            [43.28057054000007, 12.667344364000144],
            [43.27568254000019, 12.683602364],
            [43.273976540000177, 12.690687364000027],
            [43.282865121000128, 12.725620455000097],
            [43.289958268000163, 12.753498379000121],
            [43.293738380000121, 12.769874138999967],
            [43.294450329000171, 12.772868581000111],
            [43.296037540000185, 12.779545364000185],
            [43.297807381000069, 12.787059647000106],
            [43.300733221000058, 12.799483695000106],
            [43.283506295000137, 12.827571908000209],
            [43.277589795000239, 12.833866544000117],
            [43.274743540000117, 12.836894364000088],
            [43.254119333500086, 12.859236590500075],
            [43.248884254000103, 12.871216277000116],
            [43.248386540000155, 12.87233736400006],
            [43.244117254000258, 12.882324720000142],
            [43.240907540000336, 12.88983336400014],
            [43.235359540000132, 12.909593364000131],
            [43.211046540000069, 12.970375364000205],
            [43.181870540000176, 13.011707364000102],
            [43.14540054, 13.070059364000116],
            [43.104068540000156, 13.106528364000084],
            [43.091912540000152, 13.11625336400013],
            [43.053467540000128, 13.178252364000102],
            [42.996027540000085, 13.241162364000033],
            [42.879780540000269, 13.402540364000174],
            [42.801827540000176, 13.443568364000043],
            [42.726608540000115, 13.457244364000061],
            [42.660390540000122, 13.561002365000078],
            [42.637541540000171, 13.595452365000057],
            [42.646680540000119, 13.603537365000122],
            [42.63087654000023, 13.658282365000133],
            [42.477704540000076, 14.06172736500001],
            [42.52283554000033, 14.14515136500016],
            [42.436675540000095, 14.255927365000062],
            [42.364192539000072, 14.353027365000159],
            [42.279400539000079, 14.448760365000012],
            [42.19187353900017, 14.584153366000137],
            [42.163154539000203, 14.595094366000168],
            [42.014084539000208, 14.720914366000144],
            [41.977159539000098, 14.763310366000141],
            [41.847236539000306, 14.999906366000189],
            [41.776120539000232, 15.061449366000076],
            [41.625683539000221, 15.250179365999983],
            [41.569441539000053, 15.651037367000143],
            [41.568864539000259, 15.721427367000118],
            [41.461623539000044, 15.812816367000082],
            [41.427362539000256, 15.878869367000178],
            [41.418242539000147, 15.896537367000107],
            [41.400100539000249, 15.923454367000019],
            [41.301679486000154, 16.086944411000118],
            [41.293938274000169, 16.09384820700005],
            [41.211717025000013, 16.16658091000015],
            [41.155945462000062, 16.215454304999966],
            [41.145991014000316, 16.224182552000158],
            [41.1446624506668, 16.225347456333409],
            [41.134494698000225, 16.23584211500004],
            [41.082032369571635, 16.290000000000148],
            [41.783332778500125, 16.290000000000148],
            [42.149999908000126, 16.40411097150006],
            [42.772138891000196, 16.404111000000128],
            [42.789682298839494, 16.377500027858716],
            [42.823536135146838, 16.381182197792654],
            [42.918609213284384, 16.423053995222503],
            [43.059854172830995, 16.542364184649912],
            [43.070136085846997, 16.560409081132477],
            [43.079436142722187, 16.601109088495306],
            [43.102427200282449, 16.677773160275322],
            [43.134718147079894, 16.680000064317213],
            [43.206109163557556, 16.672218137749255],
            [43.197773193188283, 16.7158270007982],
            [43.16097328538396, 16.835553938452549],
            [43.123536185298491, 16.92513603180457],
            [43.151663337862971, 17.122218045338755],
            [43.191936203438701, 17.342218026237347],
            [43.25880920645389, 17.414645045950394],
            [43.31388216614593, 17.459718059849919],
            [43.3458361604348, 17.476664088823227],
            [43.37360926140812, 17.495135959454714],
            [43.407218178664635, 17.513882085958969],
            [43.445136064717616, 17.526453934928909],
            [43.513609173053595, 17.521944973922118],
            [43.620827126012131, 17.481244966559373],
            [43.638700193479252, 17.46959110362782],
            [43.653882167105252, 17.44360904773022],
            [43.671527079167191, 17.419718109042392],
            [43.738609126848331, 17.365554082933258],
            [43.760963327403573, 17.351945057261545],
            [43.939163259963522, 17.306453954030317],
            [44.089718167031293, 17.346664122970068],
            [44.185273204602794, 17.409164119448562],
            [44.366109245718889, 17.433336019531524],
            [44.466663250358152, 17.433336019531524],
            [44.566663378834193, 17.40555403374087],
            [44.650000283750813, 17.433336019531524],
            [45.216663375737454, 17.433336019531524],
            [45.400000241491938, 17.333336058693575],
            [46.100000134995327, 17.250000159605335],
            [46.366663344468321, 17.233336097855826],
            [46.750000131898588, 17.283335994455754],
            [47.000000285450454, 16.950000109453626],
            [47.183336145376558, 16.950000109453626],
            [47.429645076397691, 17.094891196891737],
            [47.466663248961169, 17.116664196279274],
            [47.60000021811581, 17.450000081281118],
            [47.836082221405974, 17.740044945003589],
            [48.183336089031485, 18.166664204172193],
            [49.116663357157535, 18.616664111761779],
            [50.783336076644929, 18.788891100102134],
            [51.221991245892781, 18.86500012225234],
            [51.99929122999086, 18.999345099085744],
            [52.782173226343133, 17.349727038037074],
            [52.812282196099858, 17.2855270239519],
            [53.114436334823864, 16.642781833787538],
            [53.11482766860783, 16.643401881592624],
            [53.121894093000122, 16.625366042000067],
            [53.127463829000106, 16.611148764000021],
            [53.130337041000161, 16.605366719000173],
            [53.131193636000063, 16.603642907000093],
            [53.329676549000197, 16.174789867000172],
            [53.587726104000041, 15.617233882000122],
            [53.628001471000147, 15.437013278000038],
            [53.647531499000053, 15.349622060000101],
            [53.877762043000104, 15.086733210999981],
            [54.162469755000217, 14.761639582000086],
            [54.315427324000069, 14.779247698000106],
            [54.440800883000207, 14.794103865000082],
            [54.542574155500205, 14.803991339000063],
            [54.632529117000132, 14.79029725900007],
            [54.696629386000126, 14.780567049000112],
            [54.767408805000144, 14.77112677],
            [54.796978182000061, 14.767182923000021],
            [54.822048662000071, 14.763809116000104],
            [55.070281281000149, 14.730403735000053],
            [55.1528257370002, 14.719295489000032],
            [55.481746001000062, 14.700394489000061],
            [55.754288461000073, 14.678600820000028],
            [56.688996949000085, 14.578649682000034],
            [56.72292464, 14.574950607000048],
            [56.750986429000164, 14.571891082000022],
            [56.857626010000132, 14.535220504000094],
            [57.010730991000088, 14.48257168300016],
            [57.211178765000142, 14.412455712000039],
            [57.416508322000112, 14.340632110000044],
            [57.432575372000173, 14.335011927500219],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ZAF", Country: "South Africa" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [37.893087535000234, -43.268758687999934],
              [37.907873535000078, -43.269686687999979],
              [37.92213653500022, -43.268761687999984],
              [38.716906536000181, -43.315560688],
              [38.717483536000145, -43.315644687999907],
              [39.49230953700004, -43.456736688999968],
              [40.226755537000088, -43.688461688999951],
              [40.245897537000246, -43.697541688999934],
              [40.27256453800004, -43.705821689],
              [40.947753038000201, -44.021899688999952],
              [40.950944538000272, -44.023383689000013],
              [40.953441204666774, -44.024996022333369],
              [40.955079538000291, -44.025773688999948],
              [41.551878539000029, -44.418092689],
              [42.052672539000127, -44.87623469],
              [42.053103872333395, -44.876762356666688],
              [42.056969039000137, -44.880328689999885],
              [42.058289539000157, -44.881537690000016],
              [42.447169540000118, -45.392991690000031],
              [42.448283540000261, -45.395350689999987],
              [42.456427540000163, -45.405943689999958],
              [42.719515540000145, -45.956447691],
              [42.845298540000158, -46.530272690999936],
              [42.825698540000246, -47.110503692],
              [42.825671540000144, -47.111306692000028],
              [42.825254540000287, -47.112721692000036],
              [42.824962540000115, -47.121348692000026],
              [42.761917540000184, -47.334723691999955],
              [42.76050054000018, -47.375321691999886],
              [42.589986540000154, -47.944233692999944],
              [42.2694125390002, -48.484213692999958],
              [42.249484539000207, -48.50560169299996],
              [42.232490872333415, -48.533847359666652],
              [42.231702539000253, -48.534768692999933],
              [42.230072539000219, -48.537339359666646],
              [42.224031539000237, -48.543816693],
              [42.22181453900015, -48.547500692999904],
              [41.756407539000236, -49.040295693999894],
              [41.753940539000212, -49.042087694000031],
              [41.749507539000035, -49.046728693999853],
              [41.151235538000265, -49.476153693999947],
              [41.149253538000039, -49.477141694000011],
              [41.147093538000206, -49.478673694],
              [41.145365538000249, -49.479534693999938],
              [41.140244538000218, -49.483165693999979],
              [41.138711038000167, -49.48394919399999],
              [41.136782538000261, -49.484928694000018],
              [41.134381538000184, -49.486630693999984],
              [41.132848038000134, -49.48741369399994],
              [40.418816538000044, -49.838600693999979],
              [39.612449537000145, -50.099052695000012],
              [38.741432536000161, -50.259398695000016],
              [37.837088535000134, -50.313744694999968],
              [37.836174535000225, -50.313693694999984],
              [37.835271535000118, -50.313744694999968],
              [37.725663535000081, -50.307603694999955],
              [37.719626535000089, -50.307938694999962],
              [37.71650853500023, -50.307761695000011],
              [37.713324535000197, -50.307937694999964],
              [37.667483535000031, -50.305335695],
              [37.651070535000201, -50.306235694999941],
              [37.650180535000118, -50.306184694999956],
              [37.649254535000239, -50.306235694999941],
              [36.745166534000248, -50.251167694999936],
              [36.739362534000179, -50.250117695000029],
              [36.713218534000248, -50.248421694999934],
              [35.842827533000218, -50.087154694999924],
              [35.03733953300025, -49.825852693999934],
              [35.021814533000054, -49.818286694],
              [35.015732533000062, -49.816281694],
              [34.303161532000104, -49.464285693999955],
              [34.302514532000203, -49.463824694000039],
              [34.301468532000172, -49.463285693999921],
              [34.299711032000232, -49.462346193999906],
              [34.298197532000245, -49.461544693999826],
              [33.701238531000229, -49.031461694000029],
              [33.700466531000131, -49.030649693999976],
              [33.698002531000157, -49.028853693999963],
              [33.695178531000067, -49.025907693999955],
              [33.231096531000219, -48.532556692999947],
              [33.229329531000189, -48.529603692999956],
              [33.223331531000042, -48.523154692999981],
              [32.903723531000196, -47.983200692999958],
              [32.902146531000085, -47.977964692999933],
              [32.900204530999986, -47.974647693],
              [32.730615530000193, -47.405606691999935],
              [32.711440530000118, -46.825180191999948],
              [32.711470530000156, -46.824573691999944],
              [32.838401531000159, -46.250792691000015],
              [32.854923531000111, -46.21682569099994],
              [32.85572253100014, -46.21316269099998],
              [33.120256531000052, -45.662699691],
              [33.511274531000254, -45.15133169],
              [33.512585531000184, -45.15013419],
              [33.760442531000137, -44.925862689999974],
              [33.794358531000228, -44.88044269],
              [34.293339865333451, -44.420909022333262],
              [34.888659032000305, -44.026958688999969],
              [34.891084532000036, -44.025346689],
              [34.894087532000185, -44.023920688999965],
              [34.895900032000043, -44.023032189],
              [34.897522533000085, -44.022259689],
              [34.900023533000081, -44.020585688999972],
              [34.901917533000301, -44.019703688999982],
              [34.91494953300014, -44.010978689000012],
              [35.588438533000215, -43.693214688999944],
              [36.322014534000118, -43.459938688999955],
              [37.096135535000144, -43.317225688],
              [37.891061201666787, -43.268765687999974],
              [37.892251535000185, -43.268812687999969],
              [37.893087535000234, -43.268758687999934],
            ],
          ],
          [
            [
              [29.831527168313528, -22.175973004303842],
              [29.902709140125125, -22.194582002871698],
              [29.939027088495607, -22.191255057853994],
              [30.0311091682957, -22.237781996682486],
              [30.300973258962358, -22.344446073477627],
              [30.367500257013774, -22.343608888986239],
              [30.416663304398185, -22.335699892765774],
              [30.460282225731021, -22.323064006055404],
              [30.48624517088939, -22.31180895407806],
              [30.512082219861895, -22.312499958177796],
              [30.559445169715332, -22.321946027806746],
              [30.636800245595111, -22.329726948546295],
              [30.703609210869956, -22.30999997172961],
              [30.781109126037052, -22.293335909980101],
              [30.838891284480837, -22.287781893282443],
              [30.904300136645418, -22.289308908407179],
              [30.928891131888179, -22.293890959609882],
              [30.972636117045113, -22.310835815116718],
              [31.064718196845206, -22.333617828011256],
              [31.089445146558035, -22.337499990296806],
              [31.096945105902307, -22.334726921442439],
              [31.122354174897453, -22.325282025279932],
              [31.157500165562453, -22.323473042931482],
              [31.265627219742044, -22.370417903453529],
              [31.29027320026978, -22.403335816610365],
              [31.297500244846049, -22.414764038707801],
              [31.302918139435747, -22.42482684877416],
              [31.516109165089631, -23.050835827065612],
              [31.561663300232965, -23.186672950306331],
              [31.555691194203746, -23.228226905966949],
              [31.545827202881298, -23.306391003143403],
              [31.537500117329216, -23.417226920798669],
              [31.550827175777272, -23.476673054665639],
              [31.621527188155312, -23.540835853100702],
              [31.651109103838735, -23.573890894193823],
              [31.683609262940308, -23.613608877777722],
              [31.68610924938838, -23.639163959526513],
              [31.688609235836452, -23.680281888582783],
              [31.720000133868496, -23.748608984164946],
              [31.769718230882802, -23.856390871570795],
              [31.844718159601712, -23.907217894378277],
              [31.877400206002449, -23.950646043594247],
              [31.877218151064938, -23.964172926614708],
              [31.884163228417435, -24.14361790707585],
              [31.885827203840449, -24.171117925642989],
              [31.941800212297949, -24.267654986993136],
              [31.963891221092638, -24.306945996428226],
              [32.007500251779675, -24.432226950780318],
              [32.016109136917066, -24.459446007952124],
              [31.995554028064532, -24.704446020969783],
              [32.002491226427992, -24.800835896099841],
              [32.011382246427161, -24.924999878031713],
              [32.018609123365309, -25.034999952300041],
              [32.016663348356644, -25.110555098286738],
              [32.009991185772265, -25.346108874037327],
              [31.991109104798113, -25.421108970394187],
              [31.988191196656658, -25.511117870257237],
              [32.005000265331603, -25.53694603441221],
              [32.00610013866941, -25.615835836767531],
              [31.98236325936233, -25.67139092353159],
              [31.919445173552333, -25.814164071669438],
              [31.968509146841143, -25.957835927468238],
              [31.998609231780506, -25.990835815638206],
              [32.074718086292705, -26.037226967634922],
              [32.10193613763613, -26.155417837709138],
              [32.061100175803517, -26.261390910404657],
              [32.072218099844463, -26.393335813075964],
              [32.089163290627425, -26.424999961152196],
              [32.126109211185565, -26.49902691854777],
              [32.132500244736491, -26.79667299500511],
              [32.133400125863943, -26.839626896138043],
              [32.154163273553991, -26.846945974011305],
              [32.175000181992175, -26.851673032139274],
              [32.348327211308515, -26.860281917276836],
              [32.395000162890824, -26.859726867646941],
              [32.688891146714923, -26.853617968957622],
              [32.767773237719496, -26.851673032139274],
              [32.890427333485661, -26.847145819610773],
              [32.898472531000209, -26.847355673000038],
              [36.532966534000252, -27.702084673999934],
              [36.499331534000135, -27.869849673999965],
              [36.494914534000031, -27.880921673999921],
              [36.492460034000032, -27.893646673999967],
              [36.491750034000233, -27.896872673999951],
              [36.491125534000105, -27.900030673999879],
              [36.488972534000084, -27.905428674],
              [36.478237534000243, -27.962786674000029],
              [36.467645534000155, -27.989312674000018],
              [36.467414534000056, -27.99054767399997],
              [36.446450534000235, -28.043025673999978],
              [36.445880534000139, -28.046067673999971],
              [36.443969534000303, -28.050847673999954],
              [36.443600534000154, -28.052819673999977],
              [36.436924534000241, -28.069521673999986],
              [36.434560534000241, -28.082127673999935],
              [36.415601534000245, -28.129533673999958],
              [36.414555534000044, -28.135108673999923],
              [36.41283853400023, -28.139401673999942],
              [36.412376534000202, -28.141859673999932],
              [36.397582534000037, -28.178827673999876],
              [36.395529534000218, -28.189759673999959],
              [36.394000034000271, -28.193690673999981],
              [36.378236534000223, -28.233164673999966],
              [36.376441534000207, -28.24271567400001],
              [36.365070534000097, -28.271094673999983],
              [36.364882534000174, -28.272097673999951],
              [36.363025534000201, -28.276730673999964],
              [36.362607534000233, -28.278950673999873],
              [36.360924534000105, -28.283150674000012],
              [36.359670534000088, -28.289814674000013],
              [36.346557534000112, -28.322524673999908],
              [36.340169534000211, -28.356656173999909],
              [36.335493534000193, -28.381689674000029],
              [36.336063534000118, -28.425138675000028],
              [36.228367534000199, -28.996490675000018],
              [36.225939534000247, -29.002511675],
              [36.224700534000192, -29.00907667499996],
              [36.224061534000271, -29.01066067499994],
              [36.22388553400026, -29.011594675000026],
              [36.222391034000054, -29.015485674999923],
              [36.22059853400026, -29.020115674999943],
              [36.22015053400014, -29.022485674999928],
              [36.218656034000105, -29.026376174999939],
              [36.103594534000081, -29.311326674999975],
              [36.09470053400014, -29.358171674999937],
              [36.094411534000244, -29.358884674999956],
              [36.093917534000184, -29.361488675000032],
              [36.090880534000291, -29.369013175000021],
              [36.089359534000039, -29.372795674999963],
              [36.088633534000166, -29.376617675000034],
              [36.085592534000284, -29.384148675],
              [36.084901534000096, -29.385877674999961],
              [36.084094534000144, -29.390128674999971],
              [35.861633533000145, -29.936824175999902],
              [35.860922533000121, -29.938271176],
              [35.860213533000092, -29.939882676000025],
              [35.854290533000267, -29.948945676],
              [35.853883533000158, -29.94994167599998],
              [35.849510533000171, -29.956632676000027],
              [35.849090533000094, -29.957660675999989],
              [35.517776533000301, -30.462139675999936],
              [35.517170533000069, -30.462769676],
              [35.513696533000086, -30.468033676],
              [35.508661533000179, -30.473270676000027],
              [35.50150153300018, -30.484116675999928],
              [35.500323533000085, -30.485343675999957],
              [35.488900533000105, -30.497266175999926],
              [35.057073533000221, -30.943846676999954],
              [35.053781866333509, -30.946301010333343],
              [35.049199533000149, -30.951009676999945],
              [35.047909533000166, -30.952336676999892],
              [35.042137533000243, -30.95651567699997],
              [35.040408533000175, -30.958293677000029],
              [35.034982533000289, -30.962221676999945],
              [35.030430533000242, -30.96690167700001],
              [35.027410533000307, -30.969087676999948],
              [34.998134533000041, -30.999166676999948],
              [34.983928533000039, -31.009438676999935],
              [34.983106533000239, -31.010282676999964],
              [34.980405533000237, -31.012235677000035],
              [34.978115533000107, -31.014586677000011],
              [34.93610653300027, -31.044940676999943],
              [34.909901533000266, -31.071807676999981],
              [34.904665533000298, -31.075586676999933],
              [34.889197532000253, -31.091435677],
              [34.883378532000165, -31.095661676999953],
              [34.880692532000182, -31.097627677],
              [34.872534532000202, -31.105981676999932],
              [34.861637532000032, -31.11383867699999],
              [34.825262532000238, -31.151060676999975],
              [34.794974532000083, -31.172866677],
              [34.784722032000218, -31.183303676999969],
              [34.781081532000087, -31.186982677],
              [34.773781532000214, -31.192234676999931],
              [34.739696532000153, -31.227040677],
              [34.736800532000132, -31.229122677000021],
              [34.732121532000235, -31.233896676999962],
              [34.729471532000218, -31.23580167700004],
              [34.728819532000102, -31.236466676999896],
              [34.725927532000242, -31.238544676999879],
              [34.721343532000219, -31.243221676999951],
              [34.718764532000193, -31.245074677],
              [34.715560532000268, -31.248342676999926],
              [34.498089532000051, -31.404153676999954],
              [34.251641532, -31.766568678],
              [34.239633532000028, -31.794473678000031],
              [34.238093532000107, -31.79680617799994],
              [34.237722532000134, -31.797425677999968],
              [34.237158532000194, -31.798737677999952],
              [34.18844003200013, -31.870045678000054],
              [34.184122532000288, -31.876380677999975],
              [34.178873532000125, -31.888550678],
              [34.169759532000285, -31.901869677999969],
              [34.168392032000099, -31.904921177999938],
              [34.160651532000202, -31.922619177999934],
              [34.15987753200011, -31.924235677999945],
              [34.159195532000183, -31.925756678000027],
              [34.150999532000242, -31.937727677999931],
              [34.142641532000084, -31.957081678],
              [34.140041532000225, -31.960876678000034],
              [34.139485532000094, -31.962164678000022],
              [34.136206532000188, -31.966950677999876],
              [34.134629032000248, -31.970596677999936],
              [34.133888532000242, -31.972306678],
              [34.120651532000124, -31.991621677999944],
              [34.118757532000075, -31.996003677999965],
              [34.118014532000245, -31.997720678],
              [34.081935532000045, -32.050321677999989],
              [34.081015032000096, -32.052275177999945],
              [34.08036553200003, -32.053603677999945],
              [34.06623453200018, -32.074189678000039],
              [34.059714532000214, -32.089239677999956],
              [33.927411532000178, -32.281487677999905],
              [33.922120532000122, -32.293642678000026],
              [33.91098853200009, -32.309794177999947],
              [33.910580532000097, -32.310401677999948],
              [33.910120532000235, -32.311457678000011],
              [33.562756531000218, -32.81207767899997],
              [33.561039531000233, -32.813830178999922],
              [33.560539531000245, -32.814368678999983],
              [33.558116531000167, -32.817842679000023],
              [33.548201531000075, -32.827627678999988],
              [33.542625531000226, -32.835617679],
              [33.539939531000016, -32.838343678999934],
              [33.534569531000159, -32.843715678999899],
              [33.51823953100029, -32.867103678999968],
              [33.509797531000061, -32.875425678999989],
              [33.471135531000243, -32.930734678999954],
              [33.470208531000253, -32.93164767899998],
              [33.403186531000244, -33.027365678999956],
              [33.40208503100024, -33.02848317899992],
              [33.397035531000256, -33.033478678999984],
              [33.396296531000189, -33.034532679],
              [33.359783531000176, -33.070385678999969],
              [33.358829531000083, -33.071744678999949],
              [33.357684531000018, -33.072868678999953],
              [33.348327531000166, -33.086197678999952],
              [33.345284531000061, -33.089182679000032],
              [33.333721531000265, -33.105649678999953],
              [33.332161031000112, -33.107229678999943],
              [33.328450531000101, -33.110917679000011],
              [33.327621531000034, -33.11209767899993],
              [33.313869531000137, -33.125579679],
              [33.302749531000018, -33.14139967899996],
              [33.300609031000192, -33.143510178999975],
              [33.271522531000187, -33.172010678999982],
              [33.269001531000129, -33.175570178999976],
              [33.268662531000189, -33.176028678999899],
              [33.264231531000178, -33.180366679000031],
              [33.245246197666859, -33.207235012333342],
              [33.241133031000174, -33.211351678999904],
              [33.232032531000186, -33.220329678999974],
              [33.223442531000217, -33.232522678999942],
              [33.221289531000195, -33.234644345666652],
              [33.218503031000239, -33.238487678999832],
              [33.218161531000248, -33.238949679000029],
              [33.197179531000188, -33.25945467899993],
              [33.193945531000111, -33.264040678999947],
              [33.193314531000169, -33.264656679000041],
              [33.190486864333479, -33.268566012333338],
              [33.188420031000192, -33.270666178999988],
              [33.187905031000184, -33.271247178999886],
              [32.734874530000184, -33.711085679],
              [32.732377530000178, -33.712780679],
              [32.731467530000202, -33.713658679],
              [32.729475530000229, -33.715010678999931],
              [32.727366530000154, -33.717045679000023],
              [32.725593530000026, -33.718247679],
              [32.720681530000036, -33.722987678999971],
              [32.708025530000043, -33.73157267900001],
              [32.684922530000165, -33.753845679],
              [32.681397530000197, -33.756234678999874],
              [32.673241530000212, -33.764093679999974],
              [32.666650530000197, -33.76855968000001],
              [32.660734530000127, -33.774257679999948],
              [32.658872530000224, -33.77551968],
              [32.629158530000154, -33.804126679999968],
              [32.602742530000114, -33.821999680000033],
              [32.600733530000184, -33.82393168],
              [32.596766530000195, -33.826614679999977],
              [32.592605196666796, -33.83056401333323],
              [32.590808530000174, -33.83180368],
              [32.586906030000193, -33.835545679999882],
              [32.585642530000143, -33.836750680000023],
              [32.58351553000017, -33.838189679999971],
              [32.582337530000075, -33.839321680000012],
              [32.40039653000008, -33.962046679999958],
              [32.396502530000134, -33.965773680000041],
              [32.38741353000006, -33.97188868],
              [32.384764530000041, -33.974422679999975],
              [32.382716530000096, -33.975800679999963],
              [32.355021530000101, -34.002238346666672],
              [32.352591030000042, -34.003987179999854],
              [32.346142530000293, -34.008387679999885],
              [32.329474530000198, -34.02430718],
              [32.328197530000011, -34.02552168],
              [32.296407530000124, -34.04686768],
              [32.277373530000034, -34.07318268],
              [32.276103530000114, -34.074399679999885],
              [32.149396530000161, -34.19505568],
              [32.134330530000113, -34.215795679999871],
              [32.133640530000037, -34.216451679999977],
              [32.133153530000243, -34.21712168],
              [32.121558530000243, -34.228136680000034],
              [32.112909530000138, -34.23994768],
              [32.084088530000258, -34.267328679999935],
              [32.076235530000133, -34.278119679999975],
              [32.074969530000146, -34.27932818],
              [31.611988529000143, -34.715731679999976],
              [31.588562529000058, -34.73115268],
              [31.38742952900003, -34.918910681],
              [31.371900529000186, -34.929071680999982],
              [31.280973529000107, -35.013577681000015],
              [31.262789529000088, -35.025439680999966],
              [31.244997529000045, -35.041943681000021],
              [31.24320252900003, -35.043113681000023],
              [31.241689529000269, -35.044516680999948],
              [31.15211852900012, -35.102833681],
              [31.101206529000251, -35.14990468100001],
              [31.097663529000044, -35.152204680999944],
              [31.09458752900008, -35.155046680999931],
              [30.904029529000184, -35.278474681000034],
              [30.807545529000151, -35.367056681],
              [30.768923529000233, -35.391932680999901],
              [30.762279529000125, -35.398017680999942],
              [30.731626529000152, -35.417738681],
              [30.706299529000148, -35.440906681],
              [30.659397529000103, -35.471031680999943],
              [30.635993529000245, -35.492401680999933],
              [30.633740029000137, -35.493917680999971],
              [30.071879528000181, -35.851799680999989],
              [30.070564528000091, -35.852428680999878],
              [30.046152528000107, -35.862975681000023],
              [29.495826527000247, -36.208232681999959],
              [29.489506527000117, -36.210901681999985],
              [29.265890027000182, -36.349724181999932],
              [29.264463527000089, -36.350566682000022],
              [29.198107527000161, -36.378383682],
              [29.19544352700018, -36.380029681999929],
              [29.173465527000189, -36.389231681999945],
              [29.160538527000142, -36.397216682000021],
              [28.519450527000231, -36.663087681999968],
              [28.504900527000189, -36.666677682],
              [28.371058526000127, -36.721572681999987],
              [28.370303526000043, -36.721757681999975],
              [28.368481526000096, -36.72250368199991],
              [28.339671526000188, -36.729569682000033],
              [28.053502526000045, -36.84616368199994],
              [28.048124526000038, -36.847467682],
              [28.04061052600008, -36.850516682000034],
              [28.039658526000153, -36.850746681999965],
              [28.037929526000141, -36.851448681999955],
              [28.004094526000131, -36.859642682000015],
              [27.992399526000042, -36.864381681999987],
              [27.949134526000194, -36.874840682000013],
              [27.776769526000123, -36.944427681999969],
              [27.50372652600015, -37.009617682999988],
              [27.172235525000019, -37.141550682999934],
              [26.473164525000186, -37.303338682999879],
              [26.460825525000132, -37.304367683],
              [26.394342525000184, -37.319524683],
              [26.393632025000159, -37.319600682999976],
              [26.279355524000152, -37.329030683000042],
              [26.277165524000168, -37.32987768299995],
              [25.57465952400014, -37.48699768299997],
              [24.846983523000233, -37.540618682999934],
              [24.844280523000066, -37.540434683],
              [24.841670523000118, -37.540619682999932],
              [24.113756522000244, -37.488867682999967],
              [24.109509022000168, -37.487944682999967],
              [24.077617522000025, -37.480993682999951],
              [23.875476522000099, -37.465850683000042],
              [23.869992522000246, -37.464645683000015],
              [23.862479522000172, -37.464076682999973],
              [23.782070522000254, -37.446364682999899],
              [23.778540522000185, -37.446094682999956],
              [23.759453522000115, -37.441885682999953],
              [23.659920522000277, -37.419868683],
              [23.415321522000283, -37.434823682999877],
              [23.192938522000077, -37.514584682999896],
              [23.185873522000293, -37.516006682999944],
              [23.17048652200009, -37.521504682999932],
              [23.169240522000223, -37.521755683000038],
              [23.153216522000264, -37.527477682999972],
              [23.119040022000263, -37.534393682999962],
              [22.408308521000095, -37.674923683000031],
              [22.385373521000133, -37.676124683000012],
              [22.342970021000156, -37.684317682999961],
              [22.338073521000183, -37.685224682999944],
              [22.100301521000176, -37.697351682999965],
              [21.995837520000066, -37.752301682999985],
              [21.983245520000025, -37.756650683000032],
              [21.952215520000152, -37.772936682999955],
              [21.276978520000227, -38.003420682999945],
              [20.558763519000138, -38.133929683999952],
              [19.82205151800008, -38.160036683999977],
              [19.818526518000283, -38.159664683999949],
              [19.808080518000168, -38.160005684],
              [19.078224518000297, -38.080793684000014],
              [19.063009518000115, -38.076880684],
              [19.060061518000282, -38.076551684000037],
              [19.048461518000238, -38.073566683999957],
              [19.035859518000279, -38.072159683999985],
              [18.989889518000211, -38.060312684],
              [18.71103351700026, -38.028773683],
              [18.01429451700028, -37.845231683],
              [18.012795517000029, -37.844582682999942],
              [18.011720517000072, -37.844296682999982],
              [18.009376517000135, -37.843282683000041],
              [17.997067517000232, -37.840000682999971],
              [17.782594517000121, -37.746957682999948],
              [17.773406517000183, -37.744488682999929],
              [17.302478516000235, -37.538250682999944],
              [16.796081516000243, -37.39868168299995],
              [16.162650515000166, -37.113616682999975],
              [16.135544515000049, -37.095665683],
              [16.129690515000107, -37.093006683],
              [15.573608015000104, -36.721851681999965],
              [15.561312514000178, -36.713514681999982],
              [15.558928514000115, -36.711233682],
              [15.557121514000187, -36.710018681999941],
              [15.55374551400007, -36.706787682000027],
              [15.552138513999978, -36.705706682000027],
              [15.541631514000187, -36.695647681999958],
              [15.536287514000151, -36.69205268199994],
              [15.075287514000195, -36.247703681999951],
              [14.721895180666763, -35.7443470143333],
              [14.71462851400014, -35.727751680999916],
              [14.701292514000215, -35.708635681000032],
              [14.647819014000163, -35.585732180999969],
              [14.647383514000182, -35.584881681000013],
              [14.646029514000247, -35.582935681000038],
              [14.410384513000281, -35.037160680999875],
              [14.319703513000263, -34.583148680000022],
              [14.293893013000229, -34.522713679999924],
              [14.234168513000043, -34.435450680000031],
              [14.002742513000214, -33.88937968],
              [13.891269513000282, -33.318180678999909],
              [13.893310513000188, -33.198973679],
              [13.89188451300015, -33.19164667900003],
              [13.892290513000205, -33.167651679000016],
              [13.885412513000063, -33.13231867899998],
              [13.89469751300021, -32.553492178],
              [13.894719513000268, -32.552746677999892],
              [13.978858513000034, -32.178351678000013],
              [13.971503513000073, -32.160750677999943],
              [13.936016513000197, -32.107689677999929],
              [13.935309513000163, -32.106027177999977],
              [13.921826513000099, -32.073743678000028],
              [13.902177513000254, -32.044323678000012],
              [13.901470513000163, -32.042659177999951],
              [13.864796512999931, -31.954636677999929],
              [13.841907513000251, -31.920297678],
              [13.841462513000181, -31.919228677999911],
              [13.809987513000067, -31.871968677999973],
              [13.788036513000179, -31.819100678000041],
              [13.782707013000277, -31.810968177999939],
              [13.760409513000297, -31.777306678],
              [13.75730251300007, -31.769813678],
              [13.75332451300028, -31.763826677999987],
              [13.749393513000086, -31.754343677999941],
              [13.747897513000112, -31.752091677999942],
              [13.522479513000178, -31.205851676999885],
              [13.513822513000093, -31.16065267700003],
              [13.482001513000171, -31.083132676999981],
              [13.479194513000266, -31.068447677],
              [13.453138513000169, -31.004894677000038],
              [13.450878513000191, -30.993061677000014],
              [13.445737513000097, -30.980513676999976],
              [13.442535513, -30.963736676999986],
              [13.442207513000255, -30.962934676999964],
              [13.437194513000065, -30.936667677],
              [13.382189512000025, -30.802204676999963],
              [13.379272512000284, -30.78687767699995],
              [13.376325512000165, -30.779665677000025],
              [13.376044512000192, -30.778188677],
              [13.375619512000185, -30.777147677000016],
              [13.37487451200019, -30.77323267700001],
              [13.366493512000204, -30.752715677000026],
              [13.363298512000199, -30.735914676999982],
              [13.356792511999913, -30.719979677000026],
              [13.356512512000222, -30.718506677],
              [13.356086512000161, -30.71746167699996],
              [13.354967512000087, -30.71157467699993],
              [13.347733512, -30.693852677],
              [13.347493512000028, -30.692588676999947],
              [13.347023512000249, -30.691435677000015],
              [13.346864512000252, -30.69060067700002],
              [13.345449012000188, -30.687058176999926],
              [13.342443511999988, -30.679618677],
              [13.334894579000263, -30.639873693999959],
              [13.340454376000082, -30.637317813000038],
              [13.343749512000045, -30.635803012999929],
              [13.53087154300016, -30.549781586],
              [13.585109825000245, -30.522717495999984],
              [13.649866990000135, -30.492720212999927],
              [13.770953886000086, -30.416887702999873],
              [13.849887243000012, -30.367257330000029],
              [14.963309240000171, -29.657962281000039],
              [15.34499754899997, -29.419589429999974],
              [15.551673620000088, -29.291295033999972],
              [15.848349139000277, -29.108119939],
              [15.917965031000108, -29.06727508],
              [15.995098170000119, -29.018263360999981],
              [16.074487789000159, -28.969870557000036],
              [16.133473422, -28.933275485999957],
              [16.164709076000065, -28.914504978000025],
              [16.202574059000142, -28.893230167000013],
              [16.220313321000106, -28.883520641000018],
              [16.226693264000119, -28.879209652999918],
              [16.311384746000158, -28.822450432999972],
              [16.318100043000243, -28.818306535000033],
              [16.319891719000168, -28.81722457099994],
              [16.3800175, -28.762002771000027],
              [16.418487004000156, -28.716725317999988],
              [16.443615624000245, -28.68882584399995],
              [16.465359099000125, -28.658028754999975],
              [16.466228183000283, -28.656411059],
              [16.473745041000143, -28.644821620499897],
              [16.478290581500232, -28.633443359499836],
              [16.483327871674021, -28.608338099629918],
              [16.489591198190794, -28.578182352845602],
              [16.625000152911582, -28.496108897716169],
              [16.677118156449922, -28.464308962808104],
              [16.700618163172351, -28.490346003990851],
              [16.719509128964035, -28.499026973495859],
              [16.776663315220276, -28.441672962667326],
              [16.801600147788918, -28.362781986845334],
              [16.822500255777413, -28.237781993888518],
              [16.886945021436077, -28.092364020015395],
              [16.911873304463541, -28.067499942366339],
              [17.075763316606356, -28.030345982970857],
              [17.107909088840472, -28.045835907720459],
              [17.120418073536371, -28.065417877611708],
              [17.134300181614378, -28.087081912257531],
              [17.154136123172464, -28.097499947381436],
              [17.171654133220215, -28.102264053521594],
              [17.189018083886651, -28.114108856208134],
              [17.18832707978703, -28.171654980257813],
              [17.184373168410048, -28.200499959012234],
              [17.198609160441151, -28.223608866131599],
              [17.218809211874316, -28.242073025412139],
              [17.278818106722042, -28.238263953322473],
              [17.309573153577389, -28.22103595712565],
              [17.343118200731453, -28.233055103398556],
              [17.398054032487096, -28.3422268779928],
              [17.399582221078447, -28.363191023721527],
              [17.396109095668919, -28.402226888023108],
              [17.385418145776839, -28.424235921804666],
              [17.361382200163575, -28.534999922730549],
              [17.400900191537232, -28.708399874604595],
              [17.422009176553388, -28.715281920045129],
              [17.46110924623332, -28.702781987804656],
              [17.49777319956786, -28.694726978830602],
              [17.589754025977356, -28.689545956825327],
              [17.602218083672142, -28.720690929817408],
              [17.605273287387888, -28.753126883540233],
              [17.643400218106819, -28.773963959616481],
              [17.679300244783747, -28.769864035485796],
              [17.710827097285517, -28.75667293150758],
              [17.749718122299782, -28.746391018491579],
              [17.912500214477916, -28.779163924723093],
              [17.969927147864155, -28.804099919101589],
              [18.001391136092138, -28.826945969736528],
              [18.026109200987491, -28.849863937100906],
              [18.044445117149053, -28.868126930704818],
              [18.181318075987292, -28.908473054114381],
              [18.338336100973322, -28.884445993318764],
              [18.719163346585958, -28.836526820368931],
              [18.95152713761712, -28.866391038552308],
              [18.970418103408633, -28.877635864607782],
              [18.985691272141366, -28.90499992870491],
              [19.002500173178476, -28.927917896069403],
              [19.108327065482001, -28.961872985927982],
              [19.145827197479548, -28.95499999294293],
              [19.183891263924295, -28.937226837762012],
              [19.253891219747004, -28.899446079645429],
              [19.260000118436324, -28.855417953798565],
              [19.248054062359358, -28.831673027864554],
              [19.239791182185854, -28.802426891223149],
              [19.261245166337346, -28.744864003366843],
              [19.277218223987461, -28.73125497769513],
              [19.301382245081356, -28.725417987945548],
              [19.322500114914817, -28.727499885062002],
              [19.34902716215862, -28.735835855431276],
              [19.418327061426226, -28.718890999924554],
              [19.447773190277928, -28.710835823312323],
              [19.466391241301267, -28.699717899271377],
              [19.490691216865116, -28.669026890156431],
              [19.493891259868121, -28.633054946750121],
              [19.513336101823256, -28.594999932760857],
              [19.567454027464635, -28.528326921955852],
              [19.743609110920318, -28.485555075760431],
              [19.789718128055426, -28.495555021552832],
              [19.812218173726308, -28.492499985474865],
              [19.996945094307421, -28.415590994483352],
              [19.99805402010054, -28.35472697787128],
              [19.998336154962146, -28.064999955918154],
              [19.99777322634344, -27.871946117423363],
              [19.99805402010054, -27.582226974459104],
              [19.998336154962146, -27.485835925862659],
              [19.99876329674936, -27.369499970966274],
              [19.999163281169899, -27.320281938296716],
              [19.999445080755322, -27.030282001575415],
              [20.000000130385217, -26.409172916256011],
              [20.000000130385217, -25.870835795577591],
              [20.000436156989736, -25.616490966321692],
              [20.000273212791512, -25.414999904066804],
              [20.000554174186789, -25.221672983165718],
              [20.00139119104017, -25.083617840700526],
              [20.001663267617971, -24.862782016414883],
              [20.001663267617971, -24.793608851523629],
              [20.000945106152017, -24.765408944039564],
              [20.044445172097255, -24.812226902547081],
              [20.059163291200264, -24.826390977848689],
              [20.13221811546282, -24.884446051060763],
              [20.166109167580942, -24.88639098787894],
              [20.237082262365107, -24.916108858032544],
              [20.261945166547662, -24.931663993988991],
              [20.390000195582445, -25.036946062584605],
              [20.449718238389266, -25.153617964161242],
              [20.536391140779017, -25.298055087722105],
              [20.609718209257551, -25.397364044460232],
              [20.631945172522137, -25.436946073574177],
              [20.650000127288592, -25.479446010829975],
              [20.689091144513213, -25.591181977250699],
              [20.679163283088371, -25.636672912843835],
              [20.682154113787703, -25.685690953303038],
              [20.739436207886911, -25.763055081638242],
              [20.783882255427216, -25.824717893625177],
              [20.810273180563115, -25.880835909007885],
              [20.86028229725656, -26.141808979675488],
              [20.796391240123114, -26.249446027793965],
              [20.757218080247242, -26.304446064928129],
              [20.74444506560036, -26.320835870805126],
              [20.691800175627122, -26.388055046422551],
              [20.652218146513064, -26.425835804539247],
              [20.623245092277784, -26.438863964319239],
              [20.609718209257551, -26.454446090004012],
              [20.603336060523759, -26.516391036852497],
              [20.601945167506813, -26.536390928437299],
              [20.61889119648032, -26.767499948559589],
              [20.641109107289424, -26.825972943465104],
              [20.703891238629524, -26.87555508600957],
              [20.763882196204406, -26.86111792830178],
              [20.80180024989545, -26.841526905955163],
              [20.823473169358635, -26.817635799629272],
              [20.855827148068329, -26.804999912918959],
              [20.892082232164626, -26.795345972090729],
              [20.924163296105974, -26.798617931823401],
              [20.966245144029983, -26.817155013661875],
              [20.988327100369361, -26.837635858852138],
              [21.012500173918795, -26.847500017812763],
              [21.101109128309417, -26.863054986131033],
              [21.12889128173822, -26.866390983604219],
              [21.144163277004509, -26.866672950827819],
              [21.177773200089518, -26.862226854095013],
              [21.276945028891134, -26.845555080994473],
              [21.338891149206233, -26.82902697371469],
              [21.377636161466796, -26.821390892262457],
              [21.41055407462369, -26.821663974668638],
              [21.43569123467887, -26.823755091878695],
              [21.46027317746632, -26.82972686263173],
              [21.481391214937958, -26.84222696251021],
              [21.51666326942717, -26.852499990708907],
              [21.613054150385665, -26.860835961078237],
              [21.668327102287833, -26.863681952490367],
              [21.693054052000491, -26.853055040338688],
              [21.750418121113114, -26.818472984120774],
              [21.772636199560395, -26.789864039760573],
              [21.778745098249715, -26.767499948559589],
              [21.774027092577171, -26.734172998526617],
              [21.769754165964457, -26.683964057260937],
              [21.808327181571485, -26.66639089429043],
              [21.862636046967737, -26.662081925493908],
              [21.891663248297789, -26.668890880738502],
              [21.962218086112273, -26.661808843087726],
              [22.015827062591427, -26.64444606588728],
              [22.05673611461998, -26.624446006664584],
              [22.133891198289177, -26.53986405384677],
              [22.158327128322924, -26.492573020722716],
              [22.175554118691537, -26.431117912297111],
              [22.19708219886752, -26.394172997567466],
              [22.249445121617271, -26.341117897251479],
              [22.366391279066249, -26.28639094252361],
              [22.479163248722671, -26.202781961029132],
              [22.624809210362571, -26.111564055448866],
              [22.626391210772567, -26.093054969167042],
              [22.663609207908451, -26.015208881397825],
              [22.707145148399377, -26.003608997922981],
              [22.722909161383598, -25.973890960131371],
              [22.745000170178457, -25.848335917544659],
              [22.743327142299734, -25.79805505954954],
              [22.756391176625527, -25.736254951988258],
              [22.777218194418026, -25.711873001411163],
              [22.812845138688488, -25.678264084154648],
              [22.830000212327661, -25.614446117217256],
              [22.812218172329324, -25.568408849173636],
              [22.830000212327661, -25.53083579461827],
              [22.910000113942885, -25.396945955128672],
              [22.972500110421208, -25.337226906493527],
              [23.01482721283287, -25.2997269421341],
              [23.194445028138034, -25.264164035603926],
              [23.275273229427285, -25.265835890015978],
              [23.454373210752692, -25.277017851797382],
              [23.530827064400626, -25.337226906493527],
              [23.596109182188627, -25.389445995784399],
              [23.650273208297904, -25.423335874436134],
              [23.670273267520486, -25.43583580667655],
              [23.713745170271295, -25.454445978710908],
              [23.736245048303999, -25.463755088041694],
              [23.871663244023011, -25.583617980166025],
              [23.889300109457963, -25.605135834420082],
              [23.901945048623759, -25.624863984703097],
              [24.006800143070507, -25.657917852329774],
              [24.046391224639933, -25.651526818778677],
              [24.115273202213814, -25.634164041578344],
              [24.189236121869044, -25.623190956824601],
              [24.228327139093665, -25.64965497215627],
              [24.243054143014206, -25.676108929204133],
              [24.281391124227071, -25.720281894338314],
              [24.351663324265758, -25.75555495465585],
              [24.398745145085968, -25.760699934477429],
              [24.438818186089293, -25.739864031867569],
              [24.472218226318148, -25.746672987112163],
              [24.501345172296112, -25.761390938577108],
              [24.53305407973545, -25.771946101637354],
              [24.621945168987622, -25.812363974138407],
              [24.648891143753247, -25.822635828870602],
              [24.684573240947032, -25.828217841124911],
              [24.801663231855287, -25.832226905424875],
              [24.900691227198195, -25.808126922071381],
              [24.953609199577727, -25.771117969601335],
              [25.012354103422922, -25.73417305487169],
              [25.033054051562829, -25.728617864707644],
              [25.08666319568016, -25.735146026195025],
              [25.128336174366325, -25.75278205343966],
              [25.15221822823645, -25.762217897146741],
              [25.175000241131158, -25.761945988206946],
              [25.36083608750539, -25.754581983332514],
              [25.414163264399122, -25.733335870380188],
              [25.509718134332331, -25.677781957082686],
              [25.581700242623526, -25.62502692890132],
              [25.589973181081064, -25.614691036428724],
              [25.622218195048958, -25.550835853841022],
              [25.671391133079339, -25.470281908424568],
              [25.684718191527452, -25.435555012919394],
              [25.769445151270531, -25.208335866433742],
              [25.827773306888673, -25.041946035480862],
              [25.872218180962534, -24.90528195367051],
              [25.875273217040728, -24.813682000942435],
              [25.871391222393015, -24.744445971777225],
              [25.918327198097813, -24.738335899621347],
              [26.018891261020883, -24.710835881054322],
              [26.16782711966647, -24.663963943090209],
              [26.265273282237956, -24.637499927758483],
              [26.36000018777338, -24.61889092919057],
              [26.389963312414523, -24.627426891770199],
              [26.401145106557749, -24.631890925775849],
              [26.411045140064175, -24.628272961079119],
              [26.440282224250211, -24.606390996950097],
              [26.461391209266139, -24.584446000909054],
              [26.477918143079478, -24.554726957289063],
              [26.485000180730196, -24.530555057206129],
              [26.493609233505708, -24.511108874146714],
              [26.514100136980005, -24.480346115940335],
              [26.555000136552906, -24.436945962281243],
              [26.726318191310668, -24.31083587006475],
              [26.759163349547578, -24.304172927573859],
              [26.787773299736273, -24.295281907574747],
              [26.830409191461683, -24.275135835598178],
              [26.852363239958322, -24.253754941642399],
              [26.880554094987019, -24.087782026554635],
              [26.91971820240758, -23.927499920824687],
              [26.963318180639078, -23.759773008673619],
              [26.959191266779897, -23.752073057118935],
              [26.97833620423836, -23.691673062668087],
              [27.005209256446221, -23.643755063184642],
              [27.030554120062789, -23.653817873251],
              [27.06971822748352, -23.636108923448603],
              [27.098609139067321, -23.589172947743975],
              [27.148054153675332, -23.524726840980861],
              [27.339718104981955, -23.399726848023931],
              [27.407218241994599, -23.387217863328033],
              [27.45277321532825, -23.385281978965281],
              [27.502082275466364, -23.383890918310271],
              [27.529091282144122, -23.379381957303565],
              [27.557218099432362, -23.348335890769192],
              [27.565200185848767, -23.310835926409709],
              [27.572773235388922, -23.266673019559391],
              [27.604027173122574, -23.219164056952138],
              [27.704582183590333, -23.200835852141381],
              [27.783882196288232, -23.170281970962833],
              [27.943882167156517, -23.031390985110647],
              [27.972218196748599, -22.952781976512426],
              [28.049445197147463, -22.863055043873089],
              [28.109718121945946, -22.806117948909673],
              [28.156600118193978, -22.771463975962376],
              [28.161945090225856, -22.744173002061075],
              [28.159718186183909, -22.723608840753002],
              [28.16777319515802, -22.703608949168256],
              [28.17963610275541, -22.686435938256352],
              [28.196663268551674, -22.675281972031598],
              [28.298336089629913, -22.609445978079975],
              [28.356527117311884, -22.576390936986911],
              [28.456109156456193, -22.573890950538839],
              [28.63832720577193, -22.561808939991863],
              [28.828891283740518, -22.490835845207698],
              [28.917845069628697, -22.454955096908236],
              [28.934309139168022, -22.439026966259405],
              [28.95055410575776, -22.413064021100922],
              [28.964718181059368, -22.388754993081704],
              [28.968054178532441, -22.368335838698897],
              [28.961109101179943, -22.326045951937431],
              [29.024800166102864, -22.227108984063307],
              [29.045000217536199, -22.224163918555291],
              [29.071663219249729, -22.220555006313987],
              [29.274163295011647, -22.194172965995563],
              [29.373618096865414, -22.192408910648339],
              [29.444582306832217, -22.164581997856544],
              [29.670000182320393, -22.136390975189727],
              [29.72610914524779, -22.139164044044094],
              [29.810554137767269, -22.164717952326384],
              [29.831527168313528, -22.175973004303842],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ZMB", Country: "Zambia" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.77123608225898, -8.192246084728083],
            [30.770836097838384, -8.198335872678271],
            [30.794163269716847, -8.265555048295695],
            [30.835836080764835, -8.352917948956772],
            [30.852773224920753, -8.381672906070747],
            [30.907500179648736, -8.467781874013269],
            [30.960973201658049, -8.53221792249262],
            [30.97694508584155, -8.547499976042786],
            [31.032636127075449, -8.584999940402227],
            [31.059445141542852, -8.599726944322768],
            [31.078891156964147, -8.607499986073208],
            [31.110418177104094, -8.61389101962429],
            [31.142291202208099, -8.612845963933509],
            [31.17027318020908, -8.59528202105642],
            [31.202773171672305, -8.578890874074844],
            [31.226800232468037, -8.579717832644377],
            [31.295554134561229, -8.619999918313596],
            [31.414718143596843, -8.633608943985394],
            [31.48555411044461, -8.666390902672205],
            [31.563891210103094, -8.75139094482158],
            [31.573954020169452, -8.817781988402999],
            [31.592782289325072, -8.839726816806078],
            [31.683609262940223, -8.908890929241679],
            [31.706391275834704, -8.917081892685729],
            [31.741245073354293, -8.912754986616463],
            [31.787382253684001, -8.887682031939548],
            [31.835000181032825, -8.897781890018138],
            [31.897782312372925, -8.914163984544217],
            [31.952082292951985, -8.936254993338991],
            [31.938327086888393, -8.953055009558412],
            [31.931800266505576, -8.973890912168343],
            [31.93278229028428, -9.009726901104642],
            [31.939300225849792, -9.031254981280682],
            [31.981663202807198, -9.070626959900537],
            [32.002636065715222, -9.068335850480125],
            [32.029927207254701, -9.046317931881291],
            [32.058745196280626, -9.041526836012807],
            [32.086391227601467, -9.051946044603113],
            [32.094936074998571, -9.058717951835504],
            [32.160345094801158, -9.064445976843686],
            [32.1997182468875, -9.099726916150175],
            [32.221382281533266, -9.120282025002794],
            [32.258609163486511, -9.133335833406704],
            [32.301663312181773, -9.134726894061544],
            [32.365554034038979, -9.132499990019753],
            [32.421245075272765, -9.129026864610168],
            [32.49069115493208, -9.176735819427776],
            [32.505482196592965, -9.217290987503191],
            [32.540063246982385, -9.261181985413728],
            [32.628327202237443, -9.274172929543582],
            [32.702773254792902, -9.282217880233915],
            [32.767773237719467, -9.33249991169545],
            [32.844163221264893, -9.373890923157816],
            [32.874718108271821, -9.377226920631017],
            [32.915273276347165, -9.390281902501314],
            [32.94040021048059, -9.405081996617611],
            [32.939436123974616, -9.41583581078369],
            [32.943600253483822, -9.455835929229295],
            [32.947082263710712, -9.47736400940525],
            [33.000127138104716, -9.621846059967254],
            [33.01880017441303, -9.631326830675491],
            [33.072218211137539, -9.631673003277641],
            [33.109163293505134, -9.625000002502929],
            [33.134991290022157, -9.5980550335657],
            [33.2069361826631, -9.602499956832048],
            [33.229163313565863, -9.634163937270216],
            [33.234163286462064, -9.65555505714795],
            [33.228882184532523, -9.677635840020841],
            [33.224582268191483, -9.705282038979675],
            [33.238609215556806, -9.731390996891875],
            [33.302500272690139, -9.798890966266526],
            [33.32249127945741, -9.8111089312833],
            [33.359445079004473, -9.933055061628792],
            [33.330827082188733, -9.993608947822452],
            [33.325973289684356, -10.064026825338701],
            [33.461663226704871, -10.165281892361577],
            [33.53388220758049, -10.2061089693768],
            [33.557082309806589, -10.223755054905226],
            [33.560273300354282, -10.244864039921183],
            [33.550554148319094, -10.27110895230328],
            [33.545827090191068, -10.296390951645861],
            [33.544854118867732, -10.331872888991185],
            [33.567500177292374, -10.392499865380785],
            [33.646563317405906, -10.500745942585709],
            [33.687491144897621, -10.548608956784122],
            [33.702282186558449, -10.561855046047341],
            [33.695973295658717, -10.576664024981056],
            [33.680409107246845, -10.596255047327759],
            [33.637773215521321, -10.621108899054931],
            [33.6047181744282, -10.647781959052438],
            [33.547009106180241, -10.712917896448815],
            [33.541800256256323, -10.743608905563747],
            [33.51082711227977, -10.775555020863607],
            [33.460900138237633, -10.803608915594069],
            [33.417773234622729, -10.803055039430646],
            [33.396945043363843, -10.798608942697911],
            [33.344163360730391, -10.813890996248077],
            [33.262563353794548, -10.863682015820245],
            [33.250409258880126, -10.892081915514765],
            [33.349782253358711, -11.079999994281664],
            [33.384436058668058, -11.113055035374771],
            [33.410409229748296, -11.163190886444511],
            [33.400836090466925, -11.193890948014939],
            [33.391109227080705, -11.215554982660791],
            [33.339436134964245, -11.300281942403871],
            [33.293054035423069, -11.368054994184718],
            [33.249509210114667, -11.411045943329697],
            [33.254163345684873, -11.520281923302463],
            [33.270409150464872, -11.570763946974338],
            [33.308745125849356, -11.582499952557257],
            [33.324582229029403, -11.613055007202277],
            [33.330827082188733, -11.744717942649984],
            [33.332218142843629, -11.89722683899133],
            [33.3124271282866, -12.000135823153357],
            [33.279991174563776, -12.0572268098595],
            [33.271382289426271, -12.129926911978743],
            [33.355273238144349, -12.309717897403971],
            [33.368882263816062, -12.32625505713925],
            [33.389582211955911, -12.340135824112693],
            [33.410273275278513, -12.34194597992753],
            [33.436663362223925, -12.336390957401477],
            [33.479718181471327, -12.314445961360406],
            [33.545827090191068, -12.335763991042199],
            [33.541800256256323, -12.364172943192131],
            [33.528663299372766, -12.377808958592155],
            [33.505273263220118, -12.386108886777791],
            [33.476391236453622, -12.412781946775297],
            [33.479582227001487, -12.439581908787204],
            [33.364018245389701, -12.540417880650082],
            [33.238054165926968, -12.58583589368537],
            [33.164300123299512, -12.589308851456778],
            [33.143745182084928, -12.581255015949111],
            [33.069027220589618, -12.585282017522033],
            [33.046391220448839, -12.603891016089904],
            [32.962009259841437, -12.757990965296443],
            [32.972354037131396, -12.853126907708045],
            [33.026736160361821, -12.885764027107626],
            [33.034854033609832, -12.913755057564117],
            [33.028882262856797, -12.941108895739461],
            [33.020554171476448, -12.96278198284071],
            [33.010273264288941, -12.981108846546974],
            [33.00278218976203, -13.002226884018427],
            [32.992773191514203, -13.036946068172654],
            [32.986382157963106, -13.096390860935088],
            [32.986245197664829, -13.140554941251821],
            [32.977491137963938, -13.229172948097911],
            [32.918745060652071, -13.389308873435994],
            [32.896382310555595, -13.430281963204934],
            [32.831663289024306, -13.528608896164329],
            [32.813745126918064, -13.541255008796597],
            [32.75332719519426, -13.563891008937389],
            [32.695482172476346, -13.566045996249798],
            [32.681873314442697, -13.612846017484515],
            [32.710000131730936, -13.631390978311941],
            [32.75416320621926, -13.641945973734167],
            [32.780963335869245, -13.638754983186374],
            [32.830827110361184, -13.704164002989046],
            [32.899163258398943, -13.819999893540484],
            [32.990000122659836, -13.934717973481369],
            [33.078891211912122, -13.981108957839979],
            [33.166727187189764, -13.926946105197317],
            [33.1899911595182, -13.973608830857657],
            [33.204509118772904, -13.997154938047814],
            [33.222227121030727, -14.012564061250472],
            [32.98389122397046, -14.096672939451693],
            [32.372773199625982, -14.308335944152617],
            [32.343327070774279, -14.317217911696289],
            [32.230273301532435, -14.341390985245724],
            [32.094718145515316, -14.37722680654403],
            [31.950836071584348, -14.423608906085235],
            [31.875000131840437, -14.457781925427071],
            [31.855554116418972, -14.465554967177496],
            [31.835554057196163, -14.472782011753623],
            [31.809718181690272, -14.480555053504148],
            [31.782500130346847, -14.48694608705523],
            [31.730827205868451, -14.494999922562897],
            [31.700836085670659, -14.502081960213616],
            [31.534927208323353, -14.588755030241444],
            [31.50374518731897, -14.611526817214056],
            [31.484718099419496, -14.619717948296085],
            [31.463336199635336, -14.625282023277563],
            [31.304718236966579, -14.665555056491371],
            [31.116391288961637, -14.708335787504112],
            [31.094445119454207, -14.7116729584437],
            [31.045827231053721, -14.716390964116329],
            [30.912218185321279, -14.750554931002668],
            [30.80805409497421, -14.778054949569707],
            [30.602500156600769, -14.849726927442489],
            [30.527773310288154, -14.875835885354689],
            [30.504718214987378, -14.884726905353858],
            [30.343609150687797, -14.967782010684971],
            [30.213018093021191, -14.981717930581439],
            [30.219163201532496, -15.096108948659477],
            [30.227773260136331, -15.139446070406677],
            [30.252500209849103, -15.216108968720221],
            [30.271527130110513, -15.257781947406201],
            [30.303336117474174, -15.291390864662716],
            [30.332773193870452, -15.312499849678673],
            [30.361109223462591, -15.337426959239863],
            [30.388054024761828, -15.478891021736104],
            [30.396663245175404, -15.593608934038897],
            [30.415754035539152, -15.631872992693999],
            [30.384863201852028, -15.647226962973633],
            [30.354300268217997, -15.658963974384932],
            [30.293682176645717, -15.650363974064874],
            [30.261245049456505, -15.640135872867418],
            [30.159854027963689, -15.631526820091864],
            [29.835000126084736, -15.616526901403304],
            [29.799445098543657, -15.624445955907532],
            [29.728054082065995, -15.648054927371646],
            [29.656109189424996, -15.670835934437747],
            [29.627218110203074, -15.67125502959766],
            [29.601391287152495, -15.662781931292002],
            [29.578473319788117, -15.660899858747712],
            [29.345136054519543, -15.736664049400332],
            [29.245691143311518, -15.777364056763076],
            [29.079718228223754, -15.88971793708761],
            [28.988891254608603, -15.951946024626295],
            [28.943218096439949, -15.963726957210341],
            [28.92770922858918, -15.972364005542616],
            [28.866109113238196, -16.036526803977679],
            [28.859445164918981, -16.055972987037052],
            [28.847773197076521, -16.159999949447823],
            [28.837500168877881, -16.304173043057901],
            [28.843609235205378, -16.339999979538888],
            [28.853536090801839, -16.362226942803559],
            [28.852363294906212, -16.394026877711639],
            [28.820691267840942, -16.475764012583667],
            [28.752636081198517, -16.555899868668618],
            [28.585554072679457, -16.590281932676206],
            [28.260000114245344, -16.724164060814857],
            [28.138609201167839, -16.823608972022896],
            [28.025836058045201, -16.873090867005132],
            [27.825273207750541, -16.959163960402009],
            [27.807500220207743, -16.983335860484871],
            [27.70582706385332, -17.128054951269348],
            [27.638882144108464, -17.224726961261183],
            [27.614163241022794, -17.341255029378829],
            [27.519700197800006, -17.423935798912993],
            [27.347773281593987, -17.575281957642645],
            [27.14610921685707, -17.806526932234689],
            [27.150209140987869, -17.854173022778326],
            [27.029400267268471, -17.961264073722475],
            [26.996109191781301, -17.966945998262943],
            [26.94388222350139, -17.974445957607188],
            [26.888054054331235, -17.985835790587956],
            [26.841391161032817, -18.000555083157465],
            [26.745000112436287, -18.033754963537788],
            [26.725554097014879, -18.049726847721288],
            [26.698582305987429, -18.074917819595186],
            [26.605482160224767, -18.042746063461124],
            [26.568336079818408, -17.99736392497158],
            [26.429445093966194, -17.949999969289635],
            [26.361663325005992, -17.931117888315484],
            [26.230000221920221, -17.902782026361407],
            [26.190554147276032, -17.901946015336378],
            [26.123609227531261, -17.931390970721679],
            [26.092782263946447, -17.967226959658163],
            [25.967500136127939, -18.004517879351766],
            [25.904445090019635, -17.988890994303929],
            [25.8616633531785, -17.975000001408603],
            [25.841663293955691, -17.940417945190745],
            [25.854336061040129, -17.921946074559173],
            [25.782500133140701, -17.864726844734065],
            [25.685827117320571, -17.807635858027922],
            [25.657082218490388, -17.814026891579005],
            [25.618473160699637, -17.836390982779989],
            [25.513882263841623, -17.863054990321999],
            [25.432782153612692, -17.856946091632608],
            [25.411109234149507, -17.854173022778326],
            [25.339027213572137, -17.843754987654407],
            [25.319582203979223, -17.836255028310177],
            [25.264427101635846, -17.802245958994959],
            [25.251945106668273, -17.783055088706945],
            [25.065282301724494, -17.624726804612678],
            [25.030000188951419, -17.609999968330399],
            [24.984300208692531, -17.583891010418199],
            [24.970000178920998, -17.559717936868779],
            [24.912500155338847, -17.540835855894613],
            [24.817773249803366, -17.515555030018518],
            [24.708609186560068, -17.498335918639015],
            [24.663891230079997, -17.493617912966471],
            [24.638191141405713, -17.496672949044367],
            [24.619163215316036, -17.505835877983344],
            [24.59027330956053, -17.533890946180193],
            [24.565418116728807, -17.533473024486668],
            [24.5422181821408, -17.524999926181025],
            [24.502500198556788, -17.508055070674189],
            [24.440136156548306, -17.482364034455514],
            [24.381245072311202, -17.473473014456346],
            [24.239091179181628, -17.478426886884975],
            [24.029027106335093, -17.519582031591341],
            [23.820827168759763, -17.560282038954085],
            [23.476109162127983, -17.625835898043988],
            [23.454445127482217, -17.615835952251587],
            [23.20166335553472, -17.479726920071059],
            [23.065000111914685, -17.331946087686134],
            [22.762500242952996, -17.069999878057288],
            [22.730554127653022, -17.041390933697016],
            [22.493891258471308, -16.780890937646035],
            [22.293327067072227, -16.612226928717277],
            [22.217773262189922, -16.561672988315934],
            [22.188327133338134, -16.541117879463314],
            [22.135063323632636, -16.48589991284598],
            [22.131527166311002, -16.382081995100961],
            [22.123891252496861, -16.348755045068145],
            [22.111945028781719, -16.322499906764165],
            [22.067218187484258, -16.234099997039309],
            [22.014445054391956, -16.183335838505741],
            [22.000145192258515, -16.171664038301259],
            [21.999718218109678, -16.122781952311982],
            [21.999163336117874, -15.999999978579552],
            [22.000000185333221, -15.605554990115962],
            [22.000000185333221, -15.451108868307372],
            [21.999163336117874, -14.949999970686633],
            [21.999163336117874, -14.898608845793774],
            [21.999163336117874, -14.847226940994418],
            [21.999163336117874, -14.744164065089649],
            [21.999718218109678, -14.452499985307199],
            [21.999445135703382, -14.006672924406161],
            [21.999445135703382, -13.967499932168266],
            [21.998609124678268, -13.916108974913399],
            [21.997773281291416, -13.727217924823265],
            [21.998254067258898, -13.69778202189336],
            [21.996663349669689, -13.452782008875815],
            [21.996391273091888, -13.315554998446643],
            [22.001527200458042, -13.004582031179183],
            [22.500000157160713, -13.004990900417297],
            [22.99582711466158, -13.004717818011002],
            [23.282500106365291, -13.005281920096323],
            [23.423054061812167, -13.005555002502604],
            [23.584718175741472, -13.006391013527633],
            [23.880000220220552, -13.006946063157358],
            [24.019445082236217, -12.999446103813213],
            [24.006245093440498, -12.953054951816426],
            [23.994582178053548, -12.93347298192522],
            [23.961663259068331, -12.896390939259206],
            [23.946391263801985, -12.880281927139507],
            [23.911945162053939, -12.852363986878856],
            [23.89194510283113, -12.825417844475155],
            [23.887009167675416, -12.763608852096468],
            [23.971382243465428, -12.512499940389432],
            [23.985273236360854, -12.4919460050033],
            [24.050691140980945, -12.392426830132933],
            [24.04277325994309, -12.297499932387183],
            [24.039163341873376, -12.277081951470862],
            [24.031109171089554, -12.257635936049383],
            [24.003891287384306, -12.229308958912725],
            [23.984791276926984, -12.211808886138002],
            [23.984163304739212, -11.88222692030277],
            [24.012354159767995, -11.824163968101601],
            [23.998473225156459, -11.715690908958109],
            [23.989718159627188, -11.696945955920327],
            [23.967363288519721, -11.662155022674725],
            [24.031945182114697, -11.452499986704197],
            [24.056245157678518, -11.426390861153919],
            [24.084445065162697, -11.402781889689805],
            [24.027000194503557, -11.24999991094225],
            [24.007782166849154, -11.122735798293263],
            [23.988054016566167, -10.919999855775416],
            [23.986209159672057, -10.870463981336627],
            [23.994718132523445, -10.869163948150458],
            [24.058473235186881, -10.878608844312865],
            [24.092218106913123, -10.893608930639601],
            [24.131945142952617, -10.916664025940207],
            [24.142218171151228, -10.979445989642315],
            [24.185554119431856, -11.029582008350232],
            [24.318473166893, -11.051317959725452],
            [24.376800149044868, -11.088608879419141],
            [24.398609190615929, -11.11180898164524],
            [24.416527185084163, -11.216599870713495],
            [24.410000197063169, -11.280073006153302],
            [24.365973244682834, -11.349863917310188],
            [24.37527330155811, -11.404726826508067],
            [24.44916329865535, -11.46263588696641],
            [24.526391137244616, -11.461391006703366],
            [24.584163237404567, -11.437782035239152],
            [24.602918081088148, -11.404790864248511],
            [24.692773256846465, -11.339999925987783],
            [24.739718117368398, -11.317081958623291],
            [24.980273207119609, -11.264999997268802],
            [25.029445139321609, -11.2619459670194],
            [25.066391227517727, -11.261108950165877],
            [25.126945113711372, -11.262364056350819],
            [25.18930027090255, -11.25236394292051],
            [25.255273225152365, -11.227155033773698],
            [25.284445098131471, -11.207499973686552],
            [25.320209170338501, -11.194445997644749],
            [25.344445108161892, -11.20528195446218],
            [25.345000157791702, -11.2780549791391],
            [25.322500112120821, -11.327217858885689],
            [25.291600225978129, -11.365626924466014],
            [25.316109246207816, -11.516108908975838],
            [25.326109192000217, -11.554446057826695],
            [25.363609156359672, -11.642990974476945],
            [25.498336180340658, -11.70999993196223],
            [25.692218150871554, -11.76749995554438],
            [25.842500143171122, -11.802226851049639],
            [25.983473193777911, -11.895690939049075],
            [26.00471813326385, -11.902499894293683],
            [26.043891125501659, -11.904999880741855],
            [26.071945187870199, -11.904726798335574],
            [26.198054106620333, -11.902226811887488],
            [26.436663253724873, -11.910417942969531],
            [26.457636116632926, -11.915555043801973],
            [26.477500221385924, -11.927226844006455],
            [26.598609167239715, -11.971390924323188],
            [26.678054019225044, -11.989172964321526],
            [26.750827211540042, -11.984446073831563],
            [26.873954016770142, -11.971735923458837],
            [26.902218129632843, -11.961108843669081],
            [26.959863327778493, -11.914581904840659],
            [26.975827165334891, -11.895835945974483],
            [26.98471818533406, -11.877226947406612],
            [26.994445048720166, -11.835690929018753],
            [26.996800195880923, -11.759726913793955],
            [27.033118144251404, -11.596945995082478],
            [27.208973323210529, -11.576490966153941],
            [27.218891126351508, -11.604999998228138],
            [27.228327137696766, -11.682217946171576],
            [27.229163316359887, -11.71701793187259],
            [27.228609104920366, -11.74360884921866],
            [27.231109259006558, -11.775835925913739],
            [27.244718117040151, -11.797364006089779],
            [27.263882165237845, -11.81333589027318],
            [27.352773254490245, -11.87139096348524],
            [27.374718082893224, -11.884163978131923],
            [27.418891215665468, -11.90944597747459],
            [27.453891193576823, -11.936945996041629],
            [27.480836162514066, -11.971526878793085],
            [27.486663261617906, -12.000282003545152],
            [27.486945061203443, -12.020835938931285],
            [27.494027098854161, -12.058890952920635],
            [27.530273298133153, -12.161390900206655],
            [27.535827147192634, -12.173335782817233],
            [27.541382169718787, -12.18166404183556],
            [27.568473319047797, -12.213473029199221],
            [27.586936137223887, -12.226390883133135],
            [27.600063203461701, -12.233363956042268],
            [27.623327175790081, -12.245282016562697],
            [27.640691126456915, -12.26263590894564],
            [27.655418130377342, -12.290281940266382],
            [27.677009242465346, -12.302226822877145],
            [27.797427184219515, -12.297781899610712],
            [27.82194508926645, -12.283335857085476],
            [27.838191229322689, -12.259790923361805],
            [27.860282238117378, -12.254717860269793],
            [27.885554179176239, -12.264717973700286],
            [27.91444509076004, -12.277499873164288],
            [27.935836210637859, -12.290835816429805],
            [27.96395414310868, -12.311599969948176],
            [28.049163229923835, -12.367782023071427],
            [28.163609233286792, -12.429446008524806],
            [28.229445059600408, -12.424446035628648],
            [28.31944507464587, -12.433055088404203],
            [28.346527171519455, -12.440417919812234],
            [28.446245165133718, -12.525835883654949],
            [28.530554035545293, -12.656808988469507],
            [28.530554035545293, -12.689163973007396],
            [28.515691244793089, -12.707155057671486],
            [28.495073271666428, -12.728190952491673],
            [28.49791808961217, -12.754999966958906],
            [28.572709141303307, -12.891808888056659],
            [28.59742720619866, -12.892017932722325],
            [28.617500187979289, -12.863608980572394],
            [28.637145189782558, -12.84000000910828],
            [28.673536060710859, -12.840626807829565],
            [28.742982308008237, -12.908681994472019],
            [28.825000236637521, -13.026673039973957],
            [28.920209101606787, -13.154235883652902],
            [28.950554105757732, -13.250835809277291],
            [28.954445152860643, -13.274726915603011],
            [28.957982148372622, -13.331108960936703],
            [28.970418210510644, -13.360135826990415],
            [29.018745079232104, -13.398472975841358],
            [29.040282211863456, -13.396526865556709],
            [29.065845172601229, -13.387435853347185],
            [29.098054144385486, -13.389164034148763],
            [29.197391264318441, -13.43381795288839],
            [29.308327094259909, -13.373055022028879],
            [29.464300231193334, -13.303335859963283],
            [29.485554055496522, -13.289717949474252],
            [29.518609096589699, -13.26028187890627],
            [29.545136143833389, -13.234726964795485],
            [29.570418143175999, -13.222781914546829],
            [29.594718118739792, -13.22347291864655],
            [29.683473253522294, -13.266872904667707],
            [29.681945064931, -13.300208907155934],
            [29.650973262059011, -13.316391009471758],
            [29.621109211513698, -13.382226835785275],
            [29.634027065447725, -13.415554959284577],
            [29.654582174300316, -13.434717834015871],
            [29.726663356687226, -13.458055064178168],
            [29.801663285406192, -13.449726805159841],
            [29.802218167398024, -13.417782030964446],
            [29.803054178422968, -13.171390957291962],
            [29.804445071439801, -12.780835842464995],
            [29.80555416487104, -12.541108884749804],
            [29.805827079639215, -12.472499989582005],
            [29.806663258302336, -12.225555039746098],
            [29.806391181724535, -12.163890886654642],
            [29.805045048070866, -12.155245959333442],
            [29.770273225564466, -12.154164023268521],
            [29.568882243233901, -12.190835855591956],
            [29.473536082690003, -12.249099973469768],
            [29.446945165343919, -12.338754989379652],
            [29.489582230535831, -12.393545981847964],
            [29.523682159681726, -12.395663921148355],
            [29.527636071058765, -12.425281879015685],
            [29.517082249103026, -12.442499984566794],
            [29.48166334366968, -12.459446013540102],
            [29.470554136808033, -12.457499903255268],
            [29.40000013718381, -12.43389093179124],
            [29.261036061135741, -12.365417823455175],
            [29.176800113281928, -12.369026903334571],
            [29.148882173021462, -12.376254953739092],
            [29.103882249317763, -12.391108859673849],
            [29.057082228082976, -12.387226865026335],
            [29.027082223067822, -12.376945957838814],
            [29.02500015831319, -12.355764050264909],
            [29.00082708476387, -12.305282026593119],
            [28.935418232599346, -12.200555007627145],
            [28.919718089717406, -12.181526913899276],
            [28.835554058593146, -12.0969459669099],
            [28.697636211702417, -11.988190940542779],
            [28.676936095924333, -11.981108902891975],
            [28.632218139444348, -11.956391005634615],
            [28.500273236772927, -11.870554952460211],
            [28.448609197111949, -11.776390975543549],
            [28.444863324572424, -11.724164007263752],
            [28.425827184217468, -11.662499854172296],
            [28.406945103243316, -11.618890991123465],
            [28.39527330303892, -11.594999884797645],
            [28.379163285090755, -11.57666396863597],
            [28.365409252493549, -11.555554983620027],
            [28.357427166077258, -11.524446052811669],
            [28.358054132436536, -11.475835875762115],
            [28.366700233224407, -11.434364062752778],
            [28.410554183122628, -11.369446054839472],
            [28.465000176455447, -11.244582016352567],
            [28.471109242782831, -11.223054942005021],
            [28.485554111841594, -11.16249988234479],
            [28.530282294243563, -10.955835844711714],
            [28.549027079643309, -10.85555492247866],
            [28.553609130845956, -10.832781962039562],
            [28.576382258923246, -10.790282024783963],
            [28.625409184199754, -10.714999961203375],
            [28.661391185889926, -10.710763914964801],
            [28.698745137495592, -10.651872998365704],
            [28.66332707025262, -10.556946100619868],
            [28.63347324562929, -10.519235918128274],
            [28.595000142308407, -10.246108920184227],
            [28.623054037038997, -10.14749985236314],
            [28.622218193651833, -9.986390955701822],
            [28.625827105893251, -9.947781897911057],
            [28.631109213651001, -9.92583589604159],
            [28.660827083804548, -9.852499942745666],
            [28.695554146947785, -9.795764013459078],
            [28.669718103803717, -9.748608934804807],
            [28.537773201132467, -9.470835847917769],
            [28.518954152070279, -9.359026958939154],
            [28.487354041734562, -9.336946008428185],
            [28.42889127275086, -9.32291788759656],
            [28.390273162504599, -9.302781873903854],
            [28.372218207738257, -9.27917290243974],
            [28.377145090438461, -9.250417945325751],
            [28.397636161550707, -9.227499977961443],
            [28.415691283955169, -9.217635819000861],
            [28.442773213190748, -9.205554981920287],
            [28.473891196454616, -9.191526861088576],
            [28.516945177511644, -9.168335811317974],
            [28.603882277490271, -9.108608883693847],
            [28.622500160875546, -9.093890932228902],
            [28.705136171046689, -9.017082021161741],
            [28.728609188040849, -8.991946034572877],
            [28.805273259820893, -8.902782030552373],
            [28.843609235205378, -8.857499971986996],
            [28.868327132462724, -8.826946090808363],
            [28.92582715604496, -8.741946048658988],
            [28.952773298448591, -8.686672929118714],
            [28.961945112204972, -8.664717874793752],
            [28.960554051550133, -8.635555054270142],
            [28.953609141835642, -8.596945996479377],
            [28.930691174471207, -8.529446027104726],
            [28.8997451878611, -8.480408875906335],
            [28.901663302589128, -8.478608946013381],
            [28.922500211027199, -8.474726951365867],
            [28.991945117220183, -8.46417296177205],
            [29.200554091671535, -8.433054978508196],
            [29.235273275825676, -8.427781923205842],
            [29.395000164287723, -8.403335934888204],
            [29.568609160827606, -8.37666404835727],
            [29.575154086121444, -8.37376390985041],
            [29.589445063437466, -8.373608844641211],
            [29.922773237088478, -8.32222693984184],
            [30.082500125550467, -8.297499990129168],
            [30.256109122090407, -8.270555021191853],
            [30.436663363620909, -8.241946076831582],
            [30.50610910800421, -8.231108946547664],
            [30.735273191308096, -8.19555492483488],
            [30.763054171270511, -8.191664045370047],
            [30.77123608225898, -8.192246084728083],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "ZWE", Country: "Zimbabwe" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.415754035539152, -15.631872992693999],
            [30.417291276585871, -15.642146020892511],
            [30.422363333849574, -16.00555500110562],
            [30.820827109620808, -16.004446075312373],
            [31.071109230396218, -16.015282032129818],
            [31.148882227969438, -15.994655006547731],
            [31.276663336407722, -16.018608977147508],
            [31.311945113904613, -16.032499970042835],
            [31.342082246856108, -16.072781888073976],
            [31.343745048812792, -16.093335823460208],
            [31.400836203157013, -16.14639092377611],
            [31.426245104513924, -16.162363981425997],
            [31.510827224969859, -16.186390874583623],
            [31.556245070367055, -16.194999927359177],
            [31.577082146443303, -16.192499940911105],
            [31.662218143062574, -16.198608839600496],
            [31.715136115441993, -16.210555063315638],
            [31.782218163123133, -16.260835921310786],
            [31.902982277479538, -16.365491023547207],
            [31.911182293378971, -16.412708966475449],
            [31.983054095824031, -16.435281934704193],
            [32.055554038094755, -16.44847303868238],
            [32.084854154192925, -16.448199956276184],
            [32.156109216200576, -16.441391001031576],
            [32.230554095289648, -16.439026801415324],
            [32.2547181163836, -16.440281907600351],
            [32.293054091768141, -16.448335910746081],
            [32.353609151428287, -16.463608911840851],
            [32.377782224977722, -16.469999945391933],
            [32.407218127907498, -16.478335915761207],
            [32.579991281060558, -16.545835885135858],
            [32.70736335262265, -16.611735916827897],
            [32.728054080669068, -16.693608838531759],
            [32.767463274939161, -16.718035883748072],
            [32.799436212329255, -16.718335788244516],
            [32.93048223970149, -16.70270890319668],
            [32.981136092389107, -16.709055009746621],
            [32.973609143316452, -16.752226840362738],
            [32.966945194997237, -16.775281935663429],
            [32.959163268429307, -16.796945970309281],
            [32.948327143973785, -16.822782013453264],
            [32.938609165405154, -16.842226855408171],
            [32.926663276966025, -16.859173052019571],
            [32.912773289899093, -16.874446053114326],
            [32.892218181046445, -16.896526835987217],
            [32.865000129702963, -16.918608959964587],
            [32.917500180389055, -17.054726876962533],
            [32.934718118302072, -17.08555501401375],
            [32.968327203196594, -17.147499960862334],
            [32.979854164113732, -17.182917860467228],
            [32.987218168988164, -17.227781997339079],
            [32.988609229643004, -17.252226812190145],
            [32.987809260801697, -17.264999994474906],
            [32.993818247204985, -17.308054981360414],
            [33.017491256409556, -17.324717869643635],
            [33.037491147994359, -17.340973062155214],
            [33.042027098729392, -17.35648193000597],
            [32.956527159873332, -17.505490878847596],
            [32.978263278886601, -17.557435879903863],
            [33.028473225980719, -17.578055026496926],
            [33.040827145467489, -17.599999854899906],
            [33.042354160592225, -17.629308855815395],
            [33.015000154778903, -17.745282041941309],
            [32.974991151515866, -17.817635803820323],
            [32.952354145546707, -17.880835856853949],
            [32.946109124749313, -17.975000001408603],
            [32.954718177524882, -18.041673012213622],
            [32.964573284029967, -18.07833579208156],
            [32.977218223195763, -18.102217845951884],
            [32.988054180013279, -18.122226957630176],
            [32.999300179535197, -18.187708899990625],
            [32.974054054738275, -18.250099931727448],
            [33.020000127675047, -18.301672943919741],
            [33.050282267551864, -18.328890995263251],
            [33.071591244778261, -18.349726897872998],
            [33.020554171476562, -18.459726804503262],
            [32.99624514345723, -18.481526961257089],
            [32.945554075119588, -18.507082043005866],
            [32.915273276347165, -18.514026952720386],
            [32.888327133943534, -18.530555060000083],
            [32.889445112192192, -18.571391021832724],
            [32.905827206718271, -18.613335909458513],
            [32.949718204628795, -18.690281948462143],
            [32.920273249243422, -18.776735915540328],
            [32.893054024433496, -18.791117920325021],
            [32.869163253383846, -18.787499955628306],
            [32.832218171016137, -18.778335853222927],
            [32.790273283390349, -18.788891016283145],
            [32.704163309619418, -18.833054928961886],
            [32.699436083853328, -18.947917848189988],
            [32.71450926037582, -19.017990894208666],
            [32.739718169522547, -19.025835852688559],
            [32.782700233850193, -19.022217887991843],
            [32.834018100909105, -19.023055072483345],
            [32.884854176172126, -19.105282045778353],
            [32.855000183910619, -19.274173036645692],
            [32.840273179990191, -19.302500013782449],
            [32.792845186567803, -19.36243581843425],
            [32.785409264963988, -19.467082035853352],
            [32.812218111793328, -19.474099868125634],
            [32.840209142249847, -19.475554966520917],
            [32.851800140907443, -19.499999949010061],
            [32.850554087177812, -19.617781949846247],
            [32.984718182540092, -19.687226856039075],
            [33.059436144035459, -19.780281907162589],
            [33.018882149426332, -19.943335908280346],
            [32.982773245721916, -20.022226884102253],
            [32.904163231295144, -20.12417295522485],
            [32.89249126345274, -20.166390925256835],
            [32.884445139296048, -20.23083585855359],
            [32.873891149702132, -20.274026967546988],
            [32.80250013322464, -20.362781934691299],
            [32.665836219052295, -20.557226833841113],
            [32.550827119432398, -20.554999929799237],
            [32.502218115849217, -20.59860896048616],
            [32.484163328720769, -20.670281944187352],
            [32.500827222832442, -20.819445958238177],
            [32.510273292461449, -20.859446076683781],
            [32.521382164046969, -20.914173031411664],
            [32.480827163609575, -20.992226822742055],
            [32.376109197099169, -21.106108891657897],
            [32.360554061142722, -21.135555020509685],
            [32.412218100803699, -21.217781993804692],
            [32.476227175134028, -21.321855056683077],
            [32.488873287766381, -21.344445962184636],
            [32.465409155589469, -21.324999946763256],
            [32.416109147906667, -21.307217906764919],
            [32.4041272172839, -21.318399868546408],
            [31.926391256733297, -21.811108925695436],
            [31.688609235836367, -22.054445969118333],
            [31.400409229007977, -22.34722685368294],
            [31.354718133566536, -22.37944605138901],
            [31.306000165241784, -22.409517973133674],
            [31.297500244845963, -22.414764038707801],
            [31.290273200269752, -22.403335816610351],
            [31.265627219741958, -22.370417903453543],
            [31.157500165562425, -22.323473042931511],
            [31.122354174897367, -22.325282025279961],
            [31.096945105902279, -22.334726921442453],
            [31.089445146557949, -22.33749999029682],
            [31.064718196845178, -22.333617828011228],
            [30.972636117045028, -22.310835815116732],
            [30.928891131888264, -22.293890959609911],
            [30.904300136645446, -22.289308908407179],
            [30.838891284480809, -22.287781893282428],
            [30.781109126036966, -22.293335909980087],
            [30.703609210869928, -22.309999971729695],
            [30.636800245595083, -22.329726948546295],
            [30.55944516971536, -22.32194602780676],
            [30.51208221986181, -22.312499958177781],
            [30.486245170889305, -22.31180895407806],
            [30.460282225730936, -22.323064006055404],
            [30.416663304398156, -22.335699892765788],
            [30.367500257013688, -22.343608888986225],
            [30.300973258962387, -22.344446073477641],
            [30.031109168295671, -22.2377819966825],
            [29.939027088495578, -22.191255057853979],
            [29.902709140125154, -22.194582002871684],
            [29.831527168313386, -22.175973004303813],
            [29.810554137767298, -22.164717952326384],
            [29.726109145247818, -22.139164044044094],
            [29.670000182320422, -22.136390975189727],
            [29.444582306832245, -22.164581997856573],
            [29.373618096865385, -22.192408910648368],
            [29.361945123194545, -22.176945975627149],
            [29.267773267288959, -22.077782025814244],
            [29.248745173560991, -22.070135886078205],
            [29.22055415089423, -22.079026906077374],
            [29.18139121694017, -22.080835888425739],
            [29.148336175847049, -22.074446028341129],
            [29.113609112703813, -22.062499972264064],
            [29.093891188342667, -22.053608952264895],
            [29.075827181120729, -22.03944605042976],
            [29.058609243207712, -22.017226966154098],
            [29.046109143329232, -21.993890909458187],
            [29.035000271743655, -21.965555047504111],
            [29.031945068027625, -21.936390885875937],
            [29.035827230313231, -21.903063935843036],
            [29.044863257237779, -21.877635923746752],
            [29.075009107368629, -21.833535881170377],
            [29.072518173375869, -21.809408908088656],
            [28.987082272260324, -21.770554931086409],
            [28.955273284896776, -21.771108974887909],
            [28.925136151945168, -21.769726966688467],
            [28.86347317232017, -21.751808972220232],
            [28.6462450868095, -21.649581939702415],
            [28.567982250813344, -21.632290911593458],
            [28.526391247140651, -21.648055092215756],
            [28.506454052191799, -21.660555024456144],
            [28.462500190007262, -21.654581912598573],
            [28.408054029036549, -21.630555019441033],
            [28.380836145331131, -21.615554933114382],
            [28.350554173092263, -21.602499951244084],
            [28.280482300540115, -21.587781999779139],
            [28.238327194782158, -21.595555041529664],
            [28.202773173069289, -21.596673019778308],
            [28.161945090225771, -21.593608931244916],
            [28.048745140592217, -21.576255038861987],
            [28.015827227435494, -21.566108912677876],
            [28.012773197185908, -21.561390907005247],
            [28.007773224289764, -21.545564029747155],
            [27.970409214400235, -21.440417915621268],
            [27.941945109327179, -21.383335813732543],
            [27.89971808683967, -21.308608967419843],
            [27.842982157552996, -21.224726903519212],
            [27.780000201640661, -21.169172990221611],
            [27.747500210177435, -21.151808871916799],
            [27.727354138200923, -21.131946108268366],
            [27.686736105851452, -21.071182003942482],
            [27.689718219371599, -20.934717914342386],
            [27.694027188167951, -20.864926835547422],
            [27.710000245817895, -20.84389094072732],
            [27.726945101324731, -20.79194610730913],
            [27.730827095972273, -20.748335903155819],
            [27.726391225161336, -20.548891031109846],
            [27.715582258072203, -20.510217935578638],
            [27.691873206683994, -20.489172988303139],
            [27.610691289079767, -20.471317858108861],
            [27.516391190055145, -20.476390921200959],
            [27.432500241337181, -20.472226959329831],
            [27.357427222422331, -20.465835925778734],
            [27.330973265374467, -20.471808869998313],
            [27.293891222708481, -20.489172988303139],
            [27.287454088689827, -20.494964045223185],
            [27.290836186630571, -20.454726886555108],
            [27.295827107071347, -20.385281980362279],
            [27.298882143149314, -20.311526931906442],
            [27.290554051768964, -20.241672988837422],
            [27.282500216261298, -20.221108995167384],
            [27.232845151158955, -20.109099946340436],
            [27.213609186231821, -20.087363994965116],
            [27.079163291284004, -20.026672980835073],
            [26.978954118142241, -20.011317837088967],
            [26.728536210534998, -19.928755084751785],
            [26.695836059223296, -19.877881961476618],
            [26.635273288212233, -19.865699871005404],
            [26.610900222452528, -19.85278201707149],
            [26.595691259098118, -19.827364063259012],
            [26.590136068934072, -19.801254937708734],
            [26.568673199965048, -19.785217842318403],
            [26.536663214562736, -19.763617845412995],
            [26.404445061847127, -19.67583584959192],
            [26.356663352109763, -19.616946106459309],
            [26.263609139176651, -19.576945988013705],
            [26.243473293122008, -19.572091022042855],
            [26.1966632136035, -19.547364072330097],
            [26.169373245530608, -19.529863999555374],
            [26.151800250198022, -19.506526937031055],
            [26.098609195412308, -19.375555005683069],
            [26.054718197501785, -19.264999881784917],
            [26.022218206038502, -19.187781933841478],
            [26.000000127591221, -19.160835791437776],
            [25.964373183320589, -19.104790866250909],
            [25.964163300464435, -19.061673015091429],
            [25.986673236781115, -18.998290907120378],
            [25.986945145720938, -18.988055094571976],
            [25.976663232704908, -18.952782034254412],
            [25.956109129680698, -18.91360887437834],
            [25.940554161362314, -18.893199945917516],
            [25.870282296599783, -18.844026840249128],
            [25.796663202613928, -18.711945983107995],
            [25.71889121086906, -18.591390913417442],
            [25.661663263864625, -18.535835826653468],
            [25.601663253834289, -18.479582024438827],
            [25.526391248537607, -18.39749989043105],
            [25.514445192460471, -18.377646011600035],
            [25.506382304497407, -18.35110890607244],
            [25.500827114333248, -18.328335945633441],
            [25.47944504691111, -18.274446007758897],
            [25.450000259163801, -18.220000014426148],
            [25.396527069516281, -18.127917934626055],
            [25.366391277669351, -18.110554989787715],
            [25.3174272166668, -18.074935924506093],
            [25.275554078132302, -18.005417928117325],
            [25.241391284712449, -17.924446061007259],
            [25.23791815930295, -17.903754997684729],
            [25.241800153950464, -17.855135935817856],
            [25.264427101635846, -17.802245958994959],
            [25.319582203979223, -17.836255028310177],
            [25.339027213572137, -17.843754987654407],
            [25.411109234149507, -17.854173022778326],
            [25.432782153612692, -17.856946091632608],
            [25.513882263841623, -17.863054990321999],
            [25.618473160699637, -17.836390982779989],
            [25.657082218490388, -17.814026891579005],
            [25.685827117320571, -17.807635858027922],
            [25.782500133140701, -17.864726844734065],
            [25.854336061040129, -17.921946074559173],
            [25.841663293955691, -17.940417945190745],
            [25.8616633531785, -17.975000001408603],
            [25.904445090019635, -17.988890994303929],
            [25.967500136127939, -18.004517879351766],
            [26.092782263946447, -17.967226959658163],
            [26.123609227531261, -17.931390970721679],
            [26.190554147276032, -17.901946015336378],
            [26.230000221920221, -17.902782026361407],
            [26.361663325005992, -17.931117888315484],
            [26.429445093966194, -17.949999969289635],
            [26.568336079818408, -17.99736392497158],
            [26.605482160224767, -18.042746063461124],
            [26.698582305987429, -18.074917819595186],
            [26.725554097014879, -18.049726847721288],
            [26.745000112436287, -18.033754963537788],
            [26.841391161032817, -18.000555083157465],
            [26.888054054331235, -17.985835790587956],
            [26.94388222350139, -17.974445957607188],
            [26.996109191781301, -17.966945998262943],
            [27.029400267268471, -17.961264073722475],
            [27.150209140987869, -17.854173022778326],
            [27.14610921685707, -17.806526932234689],
            [27.347773281593987, -17.575281957642645],
            [27.519700197800006, -17.423935798912993],
            [27.614163241022794, -17.341255029378829],
            [27.638882144108464, -17.224726961261183],
            [27.70582706385332, -17.128054951269348],
            [27.807500220207743, -16.983335860484871],
            [27.825273207750541, -16.959163960402009],
            [28.025836058045201, -16.873090867005132],
            [28.138609201167839, -16.823608972022896],
            [28.260000114245344, -16.724164060814857],
            [28.585554072679457, -16.590281932676206],
            [28.752636081198517, -16.555899868668618],
            [28.820691267840942, -16.475764012583667],
            [28.852363294906212, -16.394026877711639],
            [28.853536090801839, -16.362226942803559],
            [28.843609235205378, -16.339999979538888],
            [28.837500168877881, -16.304173043057901],
            [28.847773197076521, -16.159999949447823],
            [28.859445164918981, -16.055972987037052],
            [28.866109113238196, -16.036526803977679],
            [28.92770922858918, -15.972364005542616],
            [28.943218096439949, -15.963726957210341],
            [28.988891254608603, -15.951946024626295],
            [29.079718228223754, -15.88971793708761],
            [29.245691143311518, -15.777364056763076],
            [29.345136054519543, -15.736664049400332],
            [29.578473319788117, -15.660899858747712],
            [29.601391287152495, -15.662781931292002],
            [29.627218110203074, -15.67125502959766],
            [29.656109189424996, -15.670835934437747],
            [29.728054082065995, -15.648054927371646],
            [29.799445098543657, -15.624445955907532],
            [29.835000126084736, -15.616526901403304],
            [30.159854027963689, -15.631526820091864],
            [30.261245049456505, -15.640135872867418],
            [30.293682176645717, -15.650363974064874],
            [30.354300268217997, -15.658963974384932],
            [30.384863201852028, -15.647226962973633],
            [30.415754035539152, -15.631872992693999],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CHL", Country: "Chile (Chilean point of view)" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-79.326877541999863, -29.645940861999975],
              [-79.994633209999961, -29.675322629999926],
              [-79.99640235499993, -29.675126967999802],
              [-80.000836496999909, -29.675310761999924],
              [-80.663795749999792, -29.60028398899982],
              [-80.665320061999978, -29.599864440999909],
              [-80.668916367999884, -29.599448245999838],
              [-80.801454970999828, -29.562887195999906],
              [-80.864361133999921, -29.555526304999887],
              [-81.499875717999714, -29.377755169999887],
              [-82.087483466999799, -29.104203779999864],
              [-82.107113880999975, -29.090703293999923],
              [-82.114837503999979, -29.087080362999927],
              [-82.115859624999757, -29.0863771409999],
              [-82.117872184999896, -29.085432973000025],
              [-82.638557519999722, -28.725085615000012],
              [-83.076391808999915, -28.289893202999849],
              [-83.418492681999737, -27.793924598999922],
              [-83.655501736999838, -27.252956148999885],
              [-83.658609742999715, -27.238981118999902],
              [-83.661869785999954, -27.231507863999923],
              [-83.788035454999829, -26.662453653999762],
              [-83.801276838999797, -26.082693901999846],
              [-83.801269692999824, -26.081923136999876],
              [-83.702957487999981, -25.509248225999841],
              [-83.497587565999709, -24.960593080999899],
              [-83.192572010999754, -24.452082743999881],
              [-83.185702440999989, -24.444233296999926],
              [-83.183415120999882, -24.440395927999873],
              [-82.788807395999868, -23.98676707899989],
              [-82.780912619999924, -23.98032549349989],
              [-82.308774174999769, -23.59452199899988],
              [-81.779995079999765, -23.291658551999831],
              [-81.766460907999942, -23.284345953999889],
              [-81.184812700999828, -23.06597348199989],
              [-80.56988817499996, -22.940800129999786],
              [-80.565497304999838, -22.940617143999816],
              [-80.564038922999714, -22.940316430999928],
              [-79.93500918299975, -22.912577990999978],
              [-79.309500525999908, -22.983130067999923],
              [-78.705091378999896, -23.150038760999905],
              [-78.702215662999834, -23.151360895000039],
              [-78.699234992000015, -23.152176167],
              [-78.55968489299994, -23.216225470999859],
              [-78.489611933999981, -23.235282206999841],
              [-77.922637728999803, -23.492976788999897],
              [-77.921156497999903, -23.493971148000014],
              [-77.919688569999835, -23.49463386799988],
              [-77.406886951999809, -23.83701500799981],
              [-77.406091985999922, -23.837601407999898],
              [-77.401373740999816, -23.842063651999922],
              [-77.400120077999929, -23.842895751000015],
              [-76.956950963999759, -24.259846992999826],
              [-76.596708899999953, -24.739648263999925],
              [-76.330852559999926, -25.268407218999926],
              [-76.168462928999872, -25.830625879999843],
              [-76.115907490999746, -26.409852752499887],
              [-76.115837429499777, -26.411418404499898],
              [-76.115812508499744, -26.41223434949984],
              [-76.115793626999903, -26.413239062499827],
              [-76.115715948999878, -26.414600882999821],
              [-76.176210431999834, -26.992868653999835],
              [-76.349686553999902, -27.552716622999924],
              [-76.632468077999874, -28.076715760999889],
              [-77.017191970999818, -28.548291608999875],
              [-77.492843885999832, -28.952302406999763],
              [-77.494255777999854, -28.953134795999802],
              [-77.495003608499871, -28.953726667499893],
              [-77.496879065999906, -28.955270347999829],
              [-78.04903420699992, -29.278552503999833],
              [-78.056468656, -29.281373955999868],
              [-78.660175288999767, -29.510486931999765],
              [-78.931913197999847, -29.56602981399999],
              [-79.310389706999899, -29.643389929999842],
              [-79.311152119999917, -29.643425492999881],
              [-79.315518318000045, -29.644305856999893],
              [-79.319741672999896, -29.64450263499981],
              [-79.326877541999863, -29.645940861999975],
            ],
          ],
          [
            [
              [-69.487226841231063, -17.632917935694707],
              [-69.376108956352141, -17.726945958141471],
              [-69.355563905783356, -17.745554956709441],
              [-69.327372883116595, -17.797499957765524],
              [-69.318690907783321, -17.82513593080256],
              [-69.321399938897002, -17.891946069543962],
              [-69.315290872569676, -17.926946047455317],
              [-69.30611788534685, -17.95569999874084],
              [-69.29125492695664, -17.979446098141352],
              [-69.133617814600086, -18.028890945111471],
              [-69.074935775028734, -18.037917919580536],
              [-69.071672867751488, -18.038890890903872],
              [-69.073199882876224, -18.068335846289187],
              [-69.082363985281688, -18.086946018323488],
              [-69.118681933651885, -18.108891014364588],
              [-69.139590926457572, -18.125146039238189],
              [-69.148899868150238, -18.150690895065054],
              [-69.135417912130947, -18.179591026742514],
              [-69.11444605505136, -18.200835798590276],
              [-69.090981922874562, -18.228126940129641],
              [-69.085554975829325, -18.285835840739651],
              [-69.074726898000904, -18.392226835128611],
              [-69.060281861304077, -18.414308959105881],
              [-69.039735804907025, -18.440972966648076],
              [-69.030146069457203, -18.459172928339854],
              [-69.023472901044499, -18.483472903903589],
              [-69.025554965799074, -18.507499964699207],
              [-69.032499875513508, -18.560000015385413],
              [-69.029172930495804, -18.611390972640265],
              [-69.024446040005955, -18.658608915568379],
              [-69.000563986135603, -18.74305508155436],
              [-68.951399932922641, -18.845835822597508],
              [-68.932781881899189, -18.882781910793426],
              [-68.965835917163986, -18.95305494902243],
              [-68.899873021197948, -19.064790915443211],
              [-68.782499889599706, -19.141945999112693],
              [-68.740846021653056, -19.177781988049105],
              [-68.658335907668004, -19.267363913763006],
              [-68.642235780365468, -19.282082032865958],
              [-68.608335843429927, -19.297226958479825],
              [-68.582645980677796, -19.303754952329314],
              [-68.551254915007746, -19.319308914819203],
              [-68.491672994308772, -19.369026844195616],
              [-68.44368190462967, -19.43364595344076],
              [-68.551390869477586, -19.539717932593987],
              [-68.572555007416668, -19.566535831878696],
              [-68.601963920618289, -19.607782004053831],
              [-68.687154902522366, -19.703682040761123],
              [-68.689726805699877, -19.742672978061591],
              [-68.626108830972839, -19.785835923860191],
              [-68.566955057889516, -19.833890883641857],
              [-68.523346027202592, -19.916390939343046],
              [-68.577781962251493, -20.053546033042835],
              [-68.610635837667814, -20.051735877227998],
              [-68.650554986928455, -20.056663933394702],
              [-68.777226834297267, -20.089163924857985],
              [-68.784655044550277, -20.114446091838659],
              [-68.766454915220436, -20.136464010437578],
              [-68.721117871370041, -20.237781941734397],
              [-68.703608913777998, -20.338054984978555],
              [-68.735008864265353, -20.352226939269087],
              [-68.754799878822325, -20.37354597477929],
              [-68.75222680217837, -20.424026824984765],
              [-68.739181878591978, -20.45028196328866],
              [-68.724726783611288, -20.470835898674807],
              [-68.690981911884933, -20.504999865561217],
              [-68.661955045831291, -20.523054987965793],
              [-68.629446001912356, -20.538608950455739],
              [-68.599726790654472, -20.54999995690298],
              [-68.570845937354363, -20.558890976902148],
              [-68.549454985114721, -20.567499862039654],
              [-68.49250883769588, -20.604726911630962],
              [-68.467017793687546, -20.630626824877311],
              [-68.472163946975343, -20.650690921840578],
              [-68.509172899445502, -20.671673004842205],
              [-68.528890991444712, -20.687646062492092],
              [-68.564999895149356, -20.733817943901158],
              [-68.565563997234506, -20.870282033501041],
              [-68.56000880707046, -20.891391018517083],
              [-68.557690875559899, -20.894454939412469],
              [-68.552572885466645, -20.904717909327189],
              [-68.5318177844037, -20.926390996428438],
              [-68.505699941674095, -20.937646048405895],
              [-68.478899979662287, -20.941673049978704],
              [-68.453608927864195, -20.939445978298806],
              [-68.424035896998021, -20.938890928668911],
              [-68.229808927331362, -21.233200001824684],
              [-68.18860885562367, -21.296946052032808],
              [-68.186117921631194, -21.609726828182147],
              [-68.170008909511296, -21.627781950586666],
              [-68.138764024233012, -21.66694605800717],
              [-68.121946070740705, -21.698064041270982],
              [-68.105146054521015, -21.745281984199153],
              [-68.090563889888131, -21.819999945694519],
              [-68.087508853810107, -21.842781958589001],
              [-68.086117793155154, -21.880281922948427],
              [-68.087508853810107, -21.91306404927343],
              [-68.085155047753801, -21.961673052856497],
              [-67.996945910145882, -22.04694600977399],
              [-67.972781889051703, -22.060281953039521],
              [-67.944454911915045, -22.104726827113382],
              [-67.922646037981963, -22.239173057337325],
              [-67.922917946921871, -22.273890900387016],
              [-67.932781938244261, -22.300281993160922],
              [-67.890563968212291, -22.426390911910971],
              [-67.849726832913177, -22.554864030277258],
              [-67.884172934661194, -22.712781936390968],
              [-67.876399892910683, -22.828055065961564],
              [-67.793335902762294, -22.878054962561578],
              [-67.579181964068709, -22.901108884395796],
              [-67.499999968567806, -22.885435898880417],
              [-67.335008909620797, -22.852782015674393],
              [-67.1836268763455, -22.821664032410638],
              [-67.104735900523622, -22.900555008232459],
              [-67.000835840127252, -23.002782040750276],
              [-67.047499906891971, -23.124172953827838],
              [-67.09360892402708, -23.241108885355118],
              [-67.138626785003567, -23.35833583656148],
              [-67.150845923486997, -23.391946094922503],
              [-67.190846041932673, -23.528617888083772],
              [-67.25091780105447, -23.733226850690528],
              [-67.335845926474292, -24.021664065379412],
              [-67.577326881252759, -24.121691015945601],
              [-67.639881863016285, -24.147590929191949],
              [-67.899173020987746, -24.253890896112239],
              [-68.18999991627868, -24.371390929724896],
              [-68.253617891005888, -24.398608981068321],
              [-68.277508829693488, -24.447226869468807],
              [-68.318972931351766, -24.496945972311607],
              [-68.365835816860454, -24.489926966572853],
              [-68.39069989450951, -24.503755095194208],
              [-68.463054997493089, -24.604726853888906],
              [-68.508054921196958, -24.670282054083373],
              [-68.540845932339266, -24.729446053088566],
              [-68.567426791401431, -24.781946103774658],
              [-68.570973007006842, -24.813335828340144],
              [-68.548335833399619, -24.869581919203938],
              [-68.500290931901986, -24.894999873016388],
              [-68.355626826402386, -25.121255100634244],
              [-68.420290862648699, -25.152781953136127],
              [-68.485899874661641, -25.162490879249447],
              [-68.496946049611381, -25.171108984480497],
              [-68.514872928897034, -25.198755015801225],
              [-68.530008802055363, -25.251946070587053],
              [-68.534708870455063, -25.269890887145607],
              [-68.539463924139795, -25.273890898990032],
              [-68.56000880707046, -25.315281910452484],
              [-68.57250890694894, -25.346108874037213],
              [-68.593064015801644, -25.423335874436077],
              [-68.597499886612582, -25.443335933658886],
              [-68.599726790654529, -25.473890988303879],
              [-68.596672928042949, -25.498055009397831],
              [-68.553346032217803, -25.617499979828665],
              [-68.501108838016023, -25.718335951691643],
              [-68.493346022187382, -25.742781940009195],
              [-68.454454997172945, -25.905554979731733],
              [-68.39986399691503, -26.159372922552507],
              [-68.419445966806165, -26.187782042340416],
              [-68.479735823049282, -26.238055021346725],
              [-68.519726889039475, -26.263335847222834],
              [-68.545290855605515, -26.281946019257077],
              [-68.564446018985848, -26.303955053038692],
              [-68.577226912621484, -26.350563966880372],
              [-68.581117792086502, -26.381945980095111],
              [-68.585281921595708, -26.43667293482298],
              [-68.585555004002003, -26.457226870209183],
              [-68.583617946172637, -26.505281997628856],
              [-68.578063929475093, -26.525417843683556],
              [-68.568208822970064, -26.54555503084265],
              [-68.54111784127889, -26.584446055856972],
              [-68.495564041411967, -26.633055059440039],
              [-68.450290867663796, -26.681390980616982],
              [-68.436399874768654, -26.69639089930547],
              [-68.424454992157735, -26.712499911425368],
              [-68.295835861037972, -26.891672982946716],
              [-68.287790910347667, -26.912781967962928],
              [-68.323335879604826, -27.023335918394423],
              [-68.353899819067351, -27.040281947367816],
              [-68.392226909634417, -27.058608978712073],
              [-68.411255003362214, -27.066526859749843],
              [-68.645846033711337, -27.138472925857286],
              [-68.694926770806319, -27.109654936831191],
              [-68.738890858912953, -27.103335820009534],
              [-68.782372987585404, -27.106108888863901],
              [-68.807435883978314, -27.11854595683036],
              [-68.847782007388048, -27.176108844686553],
              [-68.866954940402991, -27.209446020641337],
              [-68.878126843900759, -27.240072992015826],
              [-68.86951779112519, -27.278546095336594],
              [-68.903481933439195, -27.364172936207183],
              [-69.045355032811699, -27.601599899684459],
              [-69.073472965282519, -27.630726845662593],
              [-69.113063879213939, -27.733064016388369],
              [-69.120526790546137, -27.774264088096004],
              [-69.155290901701505, -27.902781965825454],
              [-69.164463888924047, -27.925555093902517],
              [-69.177781894916734, -27.951946019038246],
              [-69.193199902936897, -27.970417889669818],
              [-69.222090982158932, -27.976946051157199],
              [-69.25903589688852, -27.980146094160602],
              [-69.295981985084609, -27.996808982443781],
              [-69.326126829387022, -28.059173024452321],
              [-69.337781865785018, -28.103199976832741],
              [-69.350563932887155, -28.126108891741637],
              [-69.398899854064211, -28.180281970306282],
              [-69.531955023633088, -28.314026970508451],
              [-69.605281924473616, -28.368335835904929],
              [-69.655381900997611, -28.400935907292364],
              [-69.662226898426013, -28.541390956281532],
              [-69.669308936076732, -28.575626839897268],
              [-69.700981968970325, -28.590900008630115],
              [-69.745981892674081, -28.669864074647876],
              [-69.746399814367692, -28.71917296714804],
              [-69.739581974305509, -28.741526832427155],
              [-69.729446074043381, -28.764026878098036],
              [-69.723346060171309, -28.797708885550321],
              [-69.757508853591162, -28.886673064998661],
              [-69.788890866805787, -29.016946113257916],
              [-69.78694592998761, -29.07083588349434],
              [-69.792781913908811, -29.095699961143396],
              [-69.805981902704445, -29.113608903156148],
              [-69.838135889203727, -29.132708913613499],
              [-69.901599972188137, -29.138963992694698],
              [-69.960282011759375, -29.20111798420902],
              [-70.028972881940376, -29.291117831616589],
              [-70.03472689084839, -29.352081928152813],
              [-70.029026861397256, -29.374863941047408],
              [-70.003063916238716, -29.423891033961979],
              [-69.965008902249338, -29.535555083653264],
              [-69.959863922427871, -29.555699982163503],
              [-69.956954899103721, -29.600417938643659],
              [-69.963890924000623, -29.628608961310306],
              [-69.968617814490585, -29.655417975777539],
              [-69.96278199820739, -29.678199988672304],
              [-69.950008815922786, -29.694309000792032],
              [-69.917235909691328, -29.713335921053385],
              [-69.914454961847923, -29.720417958704218],
              [-69.905146020155485, -29.799582016932391],
              [-69.907508878667272, -29.91819097633811],
              [-69.918808857645615, -29.974064072509407],
              [-69.924945919529705, -29.999999860301614],
              [-69.863481926286738, -30.104026822712285],
              [-69.841672884715422, -30.120555097630131],
              [-69.829172952475005, -30.143964076883833],
              [-69.831681991378673, -30.190555053452897],
              [-69.898899825891533, -30.32499994257239],
              [-69.938472970188059, -30.372635807193944],
              [-69.998345910566172, -30.395146078786809],
              [-70.029726917952246, -30.397782019704778],
              [-70.051535791885499, -30.394999898394929],
              [-70.133899893116677, -30.439726907330567],
              [-70.211672890689954, -30.559445965996019],
              [-70.252499967705091, -30.637781892188116],
              [-70.269181966727615, -30.678335886797015],
              [-70.316399909655843, -30.824726831993473],
              [-70.32709085954798, -30.891255003511276],
              [-70.331681963206108, -30.931390908788728],
              [-70.327917818117498, -30.956873067979643],
              [-70.29139987753689, -30.995835842085398],
              [-70.295008957416258, -31.02972689420352],
              [-70.361955050627444, -31.093055022718033],
              [-70.430281978571713, -31.113608958104237],
              [-70.451381911132216, -31.094582037842883],
              [-70.520845928064432, -31.159172983893257],
              [-70.531254910732855, -31.181254940232634],
              [-70.56028194442473, -31.322426977221369],
              [-70.567508821362765, -31.469172979837367],
              [-70.579181962671726, -31.530281915660908],
              [-70.586735901472707, -31.563817910359489],
              [-70.458890922931971, -31.832499985037089],
              [-70.446672957915098, -31.84889096438053],
              [-70.430281978571713, -31.868617941197101],
              [-70.411881857031574, -31.878890969395741],
              [-70.373335831152758, -31.886108961516356],
              [-70.339935790924017, -31.884373069363903],
              [-70.304035764247203, -31.882363927167148],
              [-70.28444591536703, -31.891946118904002],
              [-70.237508933834022, -31.938473057732438],
              [-70.232373006468038, -31.965208982004015],
              [-70.264926809749795, -32.042435814764758],
              [-70.306955013493337, -32.04778196026308],
              [-70.3488908486637, -32.086946067683641],
              [-70.316954959285681, -32.228472994453853],
              [-70.320981960858489, -32.2652819547136],
              [-70.313272956848493, -32.284172920505057],
              [-70.244326773895978, -32.309835961167096],
              [-70.230835765421318, -32.341664059269988],
              [-70.154172867107775, -32.475835865983186],
              [-70.146535779827161, -32.506254966158281],
              [-70.152235809278466, -32.543063926418029],
              [-70.164246070733952, -32.581108882123488],
              [-70.17084598131288, -32.627782001343803],
              [-70.144863925414938, -32.759099937655833],
              [-70.04520896371281, -32.853963971127598],
              [-69.993335879385995, -32.876182049574993],
              [-70.021117865176677, -32.948891036511441],
              [-70.071672979044592, -33.005835842825718],
              [-70.099635846306171, -33.051846121141125],
              [-70.084655038356914, -33.076908849896142],
              [-70.090908943971726, -33.122499865413261],
              [-70.100699845098291, -33.153199926983689],
              [-70.095555032914888, -33.179446012832273],
              [-70.000417917036827, -33.298617900856812],
              [-69.975772942337244, -33.295772915273176],
              [-69.955417825694951, -33.265208975810708],
              [-69.927372983420014, -33.240835910051032],
              [-69.902508905770844, -33.237226830171551],
              [-69.875417924079841, -33.245146052313942],
              [-69.803617870726157, -33.278890924040297],
              [-69.774935836170016, -33.387155106156115],
              [-69.783481857033564, -33.412781936996339],
              [-69.809172893252139, -33.437917923585246],
              [-69.869726779446069, -33.555282002727893],
              [-69.905008892218859, -33.778472974174292],
              [-69.904172881193915, -33.827508951906282],
              [-69.884172989609169, -33.891946006214027],
              [-69.848346053128182, -34.097226862181174],
              [-69.817572901362098, -34.23472695501647],
              [-69.888064036712308, -34.278335818065386],
              [-69.934717877555045, -34.275835831617314],
              [-69.957363935979743, -34.270563949781376],
              [-69.98611788726538, -34.269726932927938],
              [-70.029590963482235, -34.276808970578713],
              [-70.047617922692154, -34.286008947529808],
              [-70.052435840650787, -34.296046108972462],
              [-70.052226795985121, -34.354999889845431],
              [-70.045490930936353, -34.400281948410793],
              [-70.149863898311253, -34.53173583919272],
              [-70.232508960937764, -34.661946023178018],
              [-70.297499891408961, -34.853617853473395],
              [-70.359754968675873, -34.996909003229007],
              [-70.361955050627444, -35.004446010585355],
              [-70.362926848484278, -35.042635805578129],
              [-70.361955050627444, -35.079173024536033],
              [-70.357790921118294, -35.100282009552188],
              [-70.362990886224907, -35.14479092136645],
              [-70.381117925358808, -35.169026859189842],
              [-70.450835913957746, -35.198335860105374],
              [-70.48222681198996, -35.203335833001518],
              [-70.516399831331626, -35.204999976062652],
              [-70.540490929867701, -35.210135903428693],
              [-70.564999950097274, -35.241946064258684],
              [-70.567154937409782, -35.267917894234586],
              [-70.550290883449861, -35.29667301898661],
              [-70.532926932783027, -35.30778205821025],
              [-70.421954893019915, -35.364446070767485],
              [-70.396117844047467, -35.515008856824068],
              [-70.404173020659414, -35.614446056681174],
              [-70.418481935248337, -35.634935954326977],
              [-70.408617943925947, -35.67222687402068],
              [-70.374454982867974, -35.735281920128898],
              [-70.343754921297489, -35.811355067733359],
              [-70.375072896771769, -35.83312689365431],
              [-70.402235795192269, -35.870835902679573],
              [-70.411399897597448, -35.893054986955178],
              [-70.407226883270994, -35.971117998379157],
              [-70.396117844047467, -36.075564055949826],
              [-70.424317919169596, -36.136035967130283],
              [-70.43855491702891, -36.151464033434252],
              [-70.467508860525015, -36.164173010340392],
              [-70.504517812995118, -36.162290937796229],
              [-70.588617806378807, -36.164726886503843],
              [-70.704726779336625, -36.270564004729351],
              [-70.718617939869944, -36.343999870311279],
              [-70.696399861422606, -36.390973061666216],
              [-70.706945972027597, -36.414517995389872],
              [-70.952781996070144, -36.486946020931327],
              [-70.98194599006024, -36.483609017629703],
              [-71.016481945810483, -36.475917950892551],
              [-71.034308912809877, -36.483890984853304],
              [-71.054799816284117, -36.547091037886958],
              [-71.136945988032267, -36.741673064973043],
              [-71.186117920234096, -36.845555020458335],
              [-71.14861778823672, -36.975281903905284],
              [-71.112790851755733, -37.114445972163708],
              [-71.130008957306757, -37.191946054968753],
              [-71.147917899319623, -37.235973007349173],
              [-71.183126921896644, -37.268608953282296],
              [-71.196326910692335, -37.302226922994237],
              [-71.16673577491531, -37.379235826271838],
              [-71.145426797688856, -37.405700009241571],
              [-71.118055022240696, -37.435009010157046],
              [-71.108690927625048, -37.458963986585331],
              [-71.123063879954429, -37.588190973325311],
              [-71.157373021403998, -37.608617839058923],
              [-71.175846065502128, -37.626390994239841],
              [-71.182372885884945, -37.658890985703067],
              [-71.179864014619454, -37.696464040258547],
              [-71.160835753253593, -37.745564055730995],
              [-71.137217896972061, -37.791117855598088],
              [-71.130281872074988, -37.814172950898694],
              [-71.123899890979374, -37.837781922362808],
              [-71.09499992693992, -37.882499878843078],
              [-70.99833579593718, -38.070208912944111],
              [-71.000564041083635, -38.154164067040711],
              [-71.009035798284629, -38.204173016096036],
              [-71.013199927793835, -38.227646033090252],
              [-71.002790945125412, -38.284999876280722],
              [-70.971535833925373, -38.415491021661182],
              [-70.950008927215777, -38.450835831070151],
              [-70.907226855098656, -38.48625507177951],
              [-70.880845988246563, -38.498890958489881],
              [-70.843972990246243, -38.5219460537906],
              [-70.825699938358696, -38.555899970182679],
              [-70.829726772293441, -38.588054962510398],
              [-70.86194596999951, -38.690135814636392],
              [-70.923481879971916, -38.740835935429573],
              [-70.946117880112809, -38.749173079265404],
              [-70.994590929226035, -38.748199940303948],
              [-71.026263962119629, -38.745417986632106],
              [-71.053335833071344, -38.748481907527605],
              [-71.097781880611763, -38.766108882316701],
              [-71.238890885688363, -38.831117917698805],
              [-71.33445497571546, -38.875835874178676],
              [-71.360835842567326, -38.888891023687165],
              [-71.393690891450149, -38.913535830748572],
              [-71.410426869929211, -38.935417962515658],
              [-71.418626885828758, -38.960554954932945],
              [-71.422499828020591, -39.013891016644038],
              [-71.422235798069892, -39.039590937680146],
              [-71.412226799822236, -39.066117984923949],
              [-71.402235906485259, -39.129717854740079],
              [-71.387790869788489, -39.240417985563511],
              [-71.403199825353113, -39.330146091669235],
              [-71.450290866266982, -39.43541793434315],
              [-71.485281959360861, -39.561526853093198],
              [-71.49264596423518, -39.599172997844448],
              [-71.507155038672636, -39.617646041942521],
              [-71.599164028276732, -39.621117993885491],
              [-71.625281871006337, -39.611808884554819],
              [-71.666735914380808, -39.566599916185282],
              [-71.68223589741433, -39.567217997727141],
              [-71.689863932239291, -39.575490936184451],
              [-71.709026806970655, -39.626673016411473],
              [-71.705008857853272, -39.727499935819026],
              [-71.669590958248477, -39.854446039060576],
              [-71.633345932435702, -39.950564005250953],
              [-71.666945964874969, -40.047499877555481],
              [-71.749999896739439, -40.089446106285834],
              [-71.791399960657486, -40.115008899385543],
              [-71.818063968199397, -40.204590992737565],
              [-71.791399960657486, -40.245835823808193],
              [-71.719590854848207, -40.302364049533594],
              [-71.702717916070867, -40.278955070279949],
              [-71.668617819286965, -40.296945987305833],
              [-71.663964018992829, -40.334517868394755],
              [-71.701673028018206, -40.404446075126089],
              [-71.715699975383473, -40.423618002312821],
              [-71.735835821438002, -40.427781964183964],
              [-71.758608949515178, -40.410417845879124],
              [-71.779872999740235, -40.40896408858822],
              [-71.837781892560656, -40.531672972124795],
              [-71.840699968340175, -40.573481905280687],
              [-71.839290970412492, -40.600763994364627],
              [-71.859308966908145, -40.643472976286091],
              [-71.879181956478476, -40.664999882995573],
              [-71.900008806632798, -40.68139991479444],
              [-71.930354984250073, -40.701317999004004],
              [-71.950145998807102, -40.726946003310616],
              [-71.914172881934235, -40.806117940527656],
              [-71.86319984637305, -40.89056393887563],
              [-71.850281992439136, -40.912508934916787],
              [-71.838064027422263, -40.955490999244432],
              [-71.859163959982652, -41.011281952764307],
              [-71.84042688593405, -41.058755040825929],
              [-71.8613908640246, -41.241672979058592],
              [-71.865981967682671, -41.265835826686043],
              [-71.883472988001984, -41.293890894882793],
              [-71.899035835309462, -41.356663973767581],
              [-71.893063896918306, -41.386944940178012],
              [-71.882508901496067, -41.406255001129296],
              [-71.86778189757581, -41.432782048373099],
              [-71.850008910032784, -41.549444897494254],
              [-71.892226880064925, -41.603890890827017],
              [-71.914517881070083, -41.623544945085769],
              [-71.91459097126608, -41.652091025172069],
              [-71.850281992439136, -41.766391015781323],
              [-71.787781995960643, -41.861255049253316],
              [-71.777508800124053, -41.887217994411685],
              [-71.7634818527589, -41.946108911010811],
              [-71.759763975775911, -41.999726939945447],
              [-71.731526852641537, -42.029444810098937],
              [-71.726535764562641, -42.095072932851167],
              [-71.77722683290034, -42.130417909898199],
              [-71.907226798753413, -42.1762550180934],
              [-71.937854943594289, -42.172226843054204],
              [-71.947917921298654, -42.1466729347718],
              [-71.961746049920123, -42.129173029635069],
              [-71.993763914311444, -42.124590978432536],
              [-72.017499955427866, -42.133891035307613],
              [-72.038335858037954, -42.149172921219986],
              [-72.130354905925913, -42.285426960325708],
              [-72.108899915945813, -42.315835834578934],
              [-72.081681864602388, -42.333335907353671],
              [-72.056672947666016, -42.349444919473456],
              [-72.044799981784649, -42.36896402509069],
              [-72.111399902393828, -42.526944795478357],
              [-72.126108801403461, -42.547499904331005],
              [-72.135008873857942, -42.569172991432254],
              [-72.138899920961023, -42.590699898141793],
              [-72.128345931367107, -42.668717982564544],
              [-72.110835800308621, -42.720555024707608],
              [-72.107226888067203, -42.760564027970474],
              [-72.10196389104874, -42.854726831420749],
              [-72.104590947149404, -42.881808928294333],
              [-72.113481967148573, -42.914308919757616],
              [-72.126399821082487, -42.936535883022316],
              [-72.133472973915843, -42.956108968096203],
              [-72.136673016919076, -43.005764033198432],
              [-72.118064018351106, -43.039172958244649],
              [-72.078335808845338, -43.088755100789115],
              [-72.052781900562934, -43.104726984972615],
              [-71.999308878553506, -43.121808968415735],
              [-71.958890838414391, -43.121117964315999],
              [-71.93084599613934, -43.12221800529187],
              [-71.868054979981821, -43.126672986841982],
              [-71.835564040974191, -43.132990930197252],
              [-71.739664004266956, -43.176108948994681],
              [-71.73333583498993, -43.278617948736098],
              [-71.740155016156393, -43.297644868997622],
              [-71.758899969194204, -43.307290930836814],
              [-71.795281955304972, -43.300835859545487],
              [-71.895708890291758, -43.325282015500932],
              [-71.929735896879777, -43.386944827487923],
              [-71.935564001812054, -43.453399908809786],
              [-71.848890931784297, -43.500554987463943],
              [-71.701745950575912, -43.601672926550521],
              [-71.690417808402628, -43.666254987783589],
              [-71.702926793098584, -43.689235819421867],
              [-71.766399928538419, -43.71999991873264],
              [-71.78625498083602, -43.725835902654012],
              [-71.807363965851778, -43.734309000959684],
              [-71.807645933075548, -43.763335867013438],
              [-71.756117847884241, -43.819455055862647],
              [-71.698335857078462, -43.866672998790875],
              [-71.648617927702219, -43.916254973697392],
              [-71.646672990884042, -43.938054962813055],
              [-71.655426882946756, -43.956391046612794],
              [-71.746672951721735, -44.058890993898842],
              [-71.779172943185131, -44.083891026017795],
              [-71.818208807486712, -44.162364080146176],
              [-71.800417882670956, -44.190144892470414],
              [-71.793899947105444, -44.215564019749308],
              [-71.797290929863607, -44.328408911963265],
              [-71.842854955652513, -44.352217875637848],
              [-71.853408945246485, -44.372435864343743],
              [-71.827708856572315, -44.400208965317063],
              [-71.778626778372598, -44.408055097263343],
              [-71.626399849254881, -44.410000034081691],
              [-71.545563936614883, -44.409864079611793],
              [-71.480563953688147, -44.402499907099354],
              [-71.401117928236545, -44.400418009982786],
              [-71.195699944333228, -44.4302820605281],
              [-71.161735802018995, -44.451255091074245],
              [-71.108754965365449, -44.53528199426222],
              [-71.121681871754902, -44.568754957048725],
              [-71.145008875995416, -44.58667295151696],
              [-71.189945935425158, -44.591881969078884],
              [-71.232017892703425, -44.663335849830503],
              [-71.227508931696548, -44.688608964355751],
              [-71.220417841590404, -44.717091006701537],
              [-71.225908826375928, -44.746808876855084],
              [-71.277926917628179, -44.798200001747887],
              [-71.305555011676006, -44.80360901152028],
              [-71.339735910006766, -44.803335929113985],
              [-71.369726862566495, -44.79639101939955],
              [-71.39584604640055, -44.786944949770543],
              [-71.442790906922596, -44.761391041488196],
              [-71.471399851282968, -44.747081959261266],
              [-71.491672992911845, -44.743755014243561],
              [-71.518335826987482, -44.743890968713458],
              [-71.555699836877125, -44.754309003837477],
              [-71.608472969969228, -44.774581977828404],
              [-71.62918197056473, -44.780008924873471],
              [-71.653626785415724, -44.782781993727838],
              [-71.673617792183222, -44.777226971201799],
              [-71.693617851405918, -44.770282061487364],
              [-71.714446042664804, -44.764163942704386],
              [-71.771326811238737, -44.75534483943477],
              [-71.795008872898677, -44.763200023836532],
              [-71.81348191699675, -44.78500001295231],
              [-71.841117890033615, -44.792364017826742],
              [-71.951535885995554, -44.783055076134019],
              [-71.974164007147237, -44.77611787777056],
              [-71.994172951187522, -44.769172968056125],
              [-72.019454950530019, -44.759173022263724],
              [-72.047372890790825, -44.754035921431182],
              [-72.075426785521131, -44.766391014384453],
              [-72.081808934254809, -44.787090962524303],
              [-72.066817900383569, -44.901673087995334],
              [-71.934446023563396, -44.922226855743418],
              [-71.806672961752213, -44.930835908519043],
              [-71.740846020255958, -44.937226942070026],
              [-71.582645979280755, -44.975417910529188],
              [-71.549035888557796, -44.999173062385125],
              [-71.498064026463112, -45.075564051758818],
              [-71.329445950363834, -45.23125505383058],
              [-71.298617813312489, -45.305072966560488],
              [-71.467508804179772, -45.477499947111255],
              [-71.606254950744926, -45.522635825284965],
              [-71.629308872579259, -45.521526899491619],
              [-71.649735905950877, -45.523890931469793],
              [-71.672499981572628, -45.527781978572875],
              [-71.712781899603669, -45.538891017796516],
              [-71.75049090862899, -45.558544904417204],
              [-71.781954896856803, -45.655008875571582],
              [-71.792226919227005, -45.729035832967213],
              [-71.753754989372624, -45.849099890768343],
              [-71.72445504091263, -45.86180886767454],
              [-71.69445503589759, -45.868899957780684],
              [-71.649735905950877, -45.883609024428381],
              [-71.629246008305017, -45.903544878272669],
              [-71.60444596839659, -45.976944869308966],
              [-71.63862686672735, -45.996944928531775],
              [-71.645008847822908, -45.999454973263823],
              [-71.665845923898985, -46.012499896850159],
              [-71.699717865277876, -46.044444838683603],
              [-71.720835902749343, -46.068190938084001],
              [-71.740699839864249, -46.096672980429901],
              [-71.765972954389326, -46.117090961346207],
              [-71.7930639360805, -46.125555007196454],
              [-71.824172866888944, -46.130564032548023],
              [-71.851117835826017, -46.132508969366256],
              [-71.901808904163659, -46.149099940920053],
              [-71.834872869236392, -46.200973025246867],
              [-71.80056389542483, -46.209726917309638],
              [-71.766954978168258, -46.2215269606329],
              [-71.746608913981447, -46.247018004641404],
              [-71.740846020255958, -46.271390902763017],
              [-71.725008917075968, -46.430555030244307],
              [-71.689863932239291, -46.490699879561873],
              [-71.670563929571529, -46.538335911821719],
              [-71.666399967700556, -46.652499947961076],
              [-71.672855038991997, -46.683964103827066],
              [-71.686399859285302, -46.702781979422696],
              [-71.813754999402931, -46.786044955953116],
              [-71.850146037969182, -46.796673041571182],
              [-71.917364040120276, -46.800144825876203],
              [-71.939246004249242, -46.816255011462374],
              [-71.976390911189355, -47.034444836546299],
              [-71.89278192969465, -47.115564057514462],
              [-71.862508842273257, -47.139726905141913],
              [-71.852363889555704, -47.172855036430974],
              [-71.867917852045593, -47.218617880963791],
              [-71.882781983902419, -47.232781956265342],
              [-72.017372885775444, -47.277499912745498],
              [-72.081399897378617, -47.352781976326071],
              [-72.159035767015553, -47.411108958477939],
              [-72.194245963059245, -47.418608917822155],
              [-72.229864022512402, -47.410699921601861],
              [-72.256390902118028, -47.411118010933365],
              [-72.293063907907936, -47.420008863294413],
              [-72.313608958476664, -47.42611792962191],
              [-72.335417832409746, -47.4354179864971],
              [-72.350146009796731, -47.449999983492205],
              [-72.359308938735637, -47.478126968418621],
              [-72.341672911491173, -47.48999993429976],
              [-72.308263986444842, -47.505491032515749],
              [-72.329599953399452, -47.595835879058882],
              [-72.339726801206268, -47.617499913704705],
              [-72.353055033120825, -47.636673014357825],
              [-72.374026890200753, -47.659726936192214],
              [-72.404172907969439, -47.691944960431897],
              [-72.428690980654665, -47.721044916681535],
              [-72.455835774163916, -47.763200022439548],
              [-72.535072922588142, -47.915490989297723],
              [-72.531399972606437, -47.941390902544185],
              [-72.515017878080357, -47.961108994543281],
              [-72.478335819834797, -47.988481943457998],
              [-72.450008842698196, -48.001390912574493],
              [-72.406955029279175, -48.008264073197552],
              [-72.321954987129914, -48.078335945749757],
              [-72.300281900028722, -48.190417917134653],
              [-72.291881891918763, -48.347781947084854],
              [-72.426117904010482, -48.40528197066709],
              [-72.490845977997253, -48.422782043441714],
              [-72.55681792641883, -48.446944891069336],
              [-72.577926911434702, -48.45680888239167],
              [-72.59556394450783, -48.473064074903448],
              [-72.603763960407207, -48.494035931983092],
              [-72.590563971611573, -48.533681998837551],
              [-72.579726841327727, -48.564726891905536],
              [-72.573626827455598, -48.587226937576418],
              [-72.561254970696154, -48.651673044339475],
              [-72.556117869863613, -48.694172981595159],
              [-72.552226822760645, -48.731117896324804],
              [-72.552081983473215, -48.776673037296462],
              [-72.561117842759813, -48.799444824269074],
              [-72.586946006914786, -48.824444856388027],
              [-72.708890963793749, -48.903617967071511],
              [-72.736954916808031, -48.91972697919141],
              [-72.755281948152458, -48.928063955389064],
              [-72.781399958519899, -48.93805501636416],
              [-72.810835861449902, -48.945835937103595],
              [-72.856535841708933, -48.952091016184795],
              [-72.895835903599334, -48.950699955529842],
              [-72.915846021105779, -48.954444822241101],
              [-72.951672957586766, -48.964726902895109],
              [-72.982508805989085, -48.978481941320581],
              [-73.006390859859323, -48.998335820151624],
              [-73.077373007098856, -49.067917854280836],
              [-73.176817918306824, -49.22180892645963],
              [-73.174726801096767, -49.242781957005718],
              [-73.167690863913663, -49.252635890044417],
              [-73.086735760610054, -49.267364067431288],
              [-73.136126795761584, -49.304173027690979],
              [-73.168064026244224, -49.30472690385443],
              [-73.423063985054057, -49.309717991933155],
              [-73.464735790273494, -49.311391019811708],
              [-73.540008969036819, -49.443199968013118],
              [-73.582299861626666, -49.540835896872807],
              [-73.568890995803315, -49.559172986500869],
              [-73.515426858611306, -49.605135823244154],
              [-73.446463911852334, -49.790908972982436],
              [-73.466117798473022, -49.809308926884569],
              [-73.501808948122175, -49.831117968455715],
              [-73.529026831827593, -49.852708912905712],
              [-73.554245966896133, -49.940664078846794],
              [-73.514726802056089, -49.968609008835699],
              [-73.490008904798742, -49.983755107916068],
              [-73.469999960758685, -50.001672934746125],
              [-73.47424606528125, -50.0320179388969],
              [-73.501672993652278, -50.056526959126586],
              [-73.525008882710267, -50.087644942390341],
              [-73.536255049870192, -50.120135881398198],
              [-73.522508896262138, -50.148408879078318],
              [-73.485290899126198, -50.166944955088411],
              [-73.436399928319418, -50.191390943405963],
              [-73.349026801736613, -50.236255080277829],
              [-73.332090998685317, -50.24959102354336],
              [-73.317508834052092, -50.267226883149874],
              [-73.277508883244479, -50.328472946909756],
              [-73.266363969475265, -50.355582033511666],
              [-73.2495909429839, -50.500282013556841],
              [-73.228899879661469, -50.598063955170232],
              [-73.176526898627912, -50.739726836410341],
              [-73.158482002145263, -50.758891052246099],
              [-73.137090882267501, -50.769863969361836],
              [-73.109172942006865, -50.77250896273523],
              [-73.083617860258244, -50.768617915632319],
              [-73.052226794588194, -50.761673005917828],
              [-73.018064001168341, -50.749455040900955],
              [-72.983899866643924, -50.72972689061794],
              [-72.957163942372404, -50.706317911364181],
              [-72.92584596689818, -50.679172950216525],
              [-72.906108931797689, -50.667499976545741],
              [-72.873063948988545, -50.654444827037366],
              [-72.766117904969974, -50.620699955311011],
              [-72.737090871278042, -50.620763993051469],
              [-72.708899848611281, -50.637499971530531],
              [-72.690981854143047, -50.652644897144512],
              [-72.671399884251628, -50.663890896666373],
              [-72.639454942418183, -50.671944899812104],
              [-72.606946066137482, -50.669173004424181],
              [-72.586946006914786, -50.659726934795231],
              [-72.564726922639011, -50.631390905203148],
              [-72.542917881068092, -50.613891000066417],
              [-72.518335770642409, -50.605835823454299],
              [-72.491255014873332, -50.603064095704497],
              [-72.370281855851204, -50.614444876229868],
              [-72.293890866477625, -50.652917979550693],
              [-72.300417854498619, -50.677435884597685],
              [-72.338617875413036, -50.731673000902674],
              [-72.33430890661657, -50.756117983391846],
              [-72.302781886476737, -50.780555086891979],
              [-72.280426847731235, -50.800699985402105],
              [-72.254172882893556, -50.831672961740537],
              [-72.242645921976475, -50.854590929104972],
              [-72.265835798280534, -51.010700020508295],
              [-72.281881946126362, -51.030491035065438],
              [-72.334108914406329, -51.034308991972409],
              [-72.395217850229812, -51.108126904702203],
              [-72.376045923043023, -51.158963985793548],
              [-72.351964044600436, -51.17999988061365],
              [-72.326672992802457, -51.191944930862405],
              [-72.290555036642445, -51.204381998828808],
              [-72.249626873874718, -51.237854961615426],
              [-72.262790988124607, -51.265144929688347],
              [-72.29250885827804, -51.284454990639802],
              [-72.311672906475906, -51.31139090712162],
              [-72.313335876070482, -51.359455086996775],
              [-72.309726796191228, -51.399173070580673],
              [-72.347581985608144, -51.484791026633957],
              [-72.369446012464323, -51.498890896556929],
              [-72.400554943272766, -51.513617900477357],
              [-72.289245951001021, -51.696255044952807],
              [-72.258335838936375, -51.706944821378443],
              [-72.20875503749636, -51.712226929136307],
              [-72.181672940622775, -51.719999970886761],
              [-72.161117831770298, -51.728335941256091],
              [-72.135563923487666, -51.740008914926989],
              [-72.087508963706341, -51.764455070882605],
              [-71.959590894969608, -51.85459104039802],
              [-71.942363904600995, -51.880981965533863],
              [-71.942363904600995, -51.914100038539004],
              [-71.910573022148526, -51.995835832306568],
              [-71.69445503589759, -51.996944925737751],
              [-71.233335863162324, -51.997509027823071],
              [-70.662226842080884, -51.997509027823071],
              [-70.434717849382537, -51.997509027823071],
              [-70.110290921652563, -51.996391049574356],
              [-70.066681890965754, -51.996391049574356],
              [-69.998335852282196, -51.996391049574356],
              [-69.94307295866372, -52.01083591863312],
              [-69.88779095430607, -52.026117972183386],
              [-69.765835771505181, -52.060564073931289],
              [-69.461399850542477, -52.143618005796043],
              [-69.287508886778937, -52.143890920564161],
              [-69.233608890620587, -52.143335870934436],
              [-69.032790895192534, -52.194726995827182],
              [-68.991390831274771, -52.206118002274536],
              [-68.935835912148832, -52.230563990591975],
              [-68.880008916444979, -52.255835931650722],
              [-68.828608906734871, -52.279726870338493],
              [-68.727508904921137, -52.289999898536962],
              [-68.648617929099146, -52.297226943113117],
              [-68.575563943027078, -52.305281952087228],
              [-68.435835772683163, -52.328754969081444],
              [-68.441754905042103, -52.377782061998005],
              [-68.617581920924295, -52.641508923070205],
              [-68.618617924083935, -52.650555008348931],
              [-68.619735902332764, -52.719172955972113],
              [-68.62056403436884, -52.795008895716137],
              [-68.623064020816912, -53.138891058960894],
              [-68.623899864203793, -53.276390984158297],
              [-68.623335762118586, -53.324717852879758],
              [-68.623335762118586, -53.338335931006952],
              [-68.624454913833631, -53.510281957952202],
              [-68.62695490028193, -53.840282013119122],
              [-68.629181971961714, -53.998063964762935],
              [-68.631672905954474, -54.334726962420632],
              [-68.630572864978603, -54.35528207127328],
              [-68.632126869831723, -54.546099953270755],
              [-68.635836095998116, -54.788336149259635],
              [-68.636127108593485, -54.804772868381065],
              [-68.63721787019648, -54.884999913254205],
              [-68.643108839201432, -54.888608993066725],
              [-68.642330563999906, -54.893038123999929],
              [-68.641752563999944, -54.899400123999897],
              [-68.640595563999966, -54.90981112399993],
              [-68.625557563999962, -54.910390123999889],
              [-68.601844563999862, -54.90981112399993],
              [-68.571190563999892, -54.907498123999858],
              [-68.539090563999878, -54.90634112399988],
              [-68.40866756399987, -54.89448412400003],
              [-68.307885563999832, -54.870771123999873],
              [-68.183389563999981, -54.870771123999873],
              [-68.070751562999988, -54.882628123999837],
              [-67.910686562999956, -54.888556123999876],
              [-67.756549562999965, -54.90041312399984],
              [-67.620197562999806, -54.90041312399984],
              [-67.495702562999782, -54.90041312399984],
              [-67.412705562999861, -54.90634112399988],
              [-67.288210562999836, -54.912269124000034],
              [-67.199285562999819, -54.930054124000037],
              [-67.062933561999841, -54.947839124000041],
              [-66.926581561999853, -54.971553123999854],
              [-66.831727561999969, -54.989338123999858],
              [-66.754659561999915, -55.036764123999774],
              [-66.683519561999901, -55.072335123999835],
              [-66.612379561999887, -55.101976123999918],
              [-66.52938256199991, -55.113833123999882],
              [-66.416666561999818, -55.121666123999887],
              [-66.0783325619999, -55.183332123999833],
              [-65.726666560999888, -55.381666336499904],
              [-65.72667, -56.379999582999915],
              [-67.26667, -56.38],
              [-67.266666562999944, -58.376147320499967],
              [-67.266666562999944, -59.778079127999831],
              [-67.323841562999917, -59.787756128000012],
              [-68.463867063999828, -59.863920127999847],
              [-68.46545856399996, -59.864013127999897],
              [-68.4670355639999, -59.863972127999887],
              [-68.481780563999905, -59.864895127999887],
              [-69.630801564999956, -59.830217127999845],
              [-70.743355565999877, -59.686183127999925],
              [-71.776457566999852, -59.438443127999903],
              [-72.692349567999941, -59.096561127999905],
              [-73.46050156799987, -58.673391126999888],
              [-74.058700568999825, -58.184309126999892],
              [-74.189616568999838, -58.016195126999946],
              [-74.58613456899991, -57.85290212599989],
              [-75.376790569999855, -57.714710125999886],
              [-76.322150570999725, -57.435040125999876],
              [-77.146755571999847, -57.06571512599993],
              [-77.824356571999942, -56.62032312499997],
              [-78.336441572999945, -56.114685124999767],
              [-78.672267152199936, -55.565996352399907],
              [-78.673668572999929, -55.561043123999923],
              [-78.68594657299991, -55.540698123999903],
              [-78.687253572999964, -55.535937123999815],
              [-78.699555572999827, -55.515529123999841],
              [-78.70028786599994, -55.51285807499994],
              [-78.700843498999916, -55.510831303333248],
              [-78.701713573, -55.509386123999889],
              [-78.718874572999823, -55.491747123999858],
              [-78.721873572999812, -55.489640124],
              [-78.739690572999905, -55.471306123999966],
              [-78.742763572999934, -55.469145123999908],
              [-78.760508572999754, -55.450865123999904],
              [-78.763654572999883, -55.448650123999869],
              [-78.781326572999774, -55.430424123999899],
              [-78.784547572999827, -55.428155123999886],
              [-78.802146572999902, -55.409983123999837],
              [-78.805440572999771, -55.407660123999847],
              [-78.814011726999922, -55.398800444000017],
              [-78.82296729799981, -55.389543068999899],
              [-78.824317888999872, -55.388589440999795],
              [-78.826334572999826, -55.387165123999807],
              [-78.835674238999786, -55.377500133999945],
              [-78.843789242999947, -55.3691021489999],
              [-78.845135008999932, -55.368150932],
              [-78.847229572999822, -55.366670123999825],
              [-78.858586000999878, -55.354904725999916],
              [-78.864612292999936, -55.348661223999898],
              [-78.865635371999872, -55.347937332999834],
              [-78.868125572999929, -55.346175123999899],
              [-78.885436572999879, -55.328220123999927],
              [-78.889022572999863, -55.325680123999916],
              [-78.906261572999881, -55.307779123999921],
              [-78.909920573000022, -55.305184123999879],
              [-78.927087573999813, -55.287338123999859],
              [-78.930819573999941, -55.284689123999897],
              [-78.947915574000035, -55.266897123999911],
              [-78.951719573999924, -55.264194123999914],
              [-78.959567573999834, -55.256019123999863],
              [-78.961344573999895, -55.254756123999925],
              [-79.44361457399981, -54.745455123999875],
              [-79.445696992999956, -54.74182156299986],
              [-79.447224399999868, -54.739156317999914],
              [-79.452972573999944, -54.733001123999884],
              [-79.459630573999732, -54.721373123999854],
              [-79.47032057399997, -54.709917122999855],
              [-79.476934573999813, -54.698351122999895],
              [-79.487670573999821, -54.686833122999815],
              [-79.494241573999943, -54.675329122999926],
              [-79.503613277999989, -54.665264666999917],
              [-79.505023406999953, -54.663750238999853],
              [-79.506405963999953, -54.661326545999884],
              [-79.511550573999841, -54.652307122999844],
              [-79.522378573999958, -54.640666122999903],
              [-79.528862573999902, -54.629285122999875],
              [-79.539736573999789, -54.617582122999977],
              [-79.54617657399973, -54.606263123000019],
              [-79.555311999999844, -54.596421625999881],
              [-79.557096207999962, -54.594499361999908],
              [-79.559201146999953, -54.590795310999852],
              [-79.563493573999892, -54.583241122999823],
              [-79.574458573999948, -54.571415122999781],
              [-79.580774079666469, -54.560288699666501],
              [-79.591823573999932, -54.548332122999909],
              [-79.593692192999754, -54.545035988999928],
              [-79.598135096999897, -54.53719810299981],
              [-79.601408269999922, -54.533660472999827],
              [-79.609190573999854, -54.525248122999983],
              [-79.615459573999942, -54.514176122999913],
              [-79.626560573999939, -54.502165122999827],
              [-79.632786573999965, -54.491154122999887],
              [-79.638866357999916, -54.48456982599987],
              [-79.643933136999919, -54.479082110999855],
              [-79.648548043999938, -54.470909965999851],
              [-79.650116573999981, -54.468132122999805],
              [-79.661307573999977, -54.455998122999915],
              [-79.666660839999906, -54.446507648999898],
              [-79.667448444999764, -54.445111188999817],
              [-79.668423663999988, -54.444052720999935],
              [-79.678684573999817, -54.432915122999873],
              [-79.683080622999853, -54.425111936999826],
              [-79.684783101, -54.422089563999862],
              [-79.692143593999731, -54.414092334999907],
              [-79.69606451199985, -54.409832189999918],
              [-79.69676273399989, -54.408591090999892],
              [-79.702120573999821, -54.399067122999895],
              [-79.710237682999889, -54.390239067999858],
              [-79.713446396999984, -54.386749103999875],
              [-79.714936686999863, -54.384096755999792],
              [-79.71945957399987, -54.376046122999924],
              [-79.730830573999867, -54.363665122999919],
              [-79.736802573999853, -54.353024122999841],
              [-79.748217573999739, -54.340582122999876],
              [-79.754146573999947, -54.330003122999926],
              [-79.765606573999833, -54.317499122999891],
              [-79.771493573999919, -54.306982122999898],
              [-79.782998573999805, -54.294416122999849],
              [-79.788843573999827, -54.283960122999929],
              [-79.800392558999874, -54.271333135999896],
              [-79.804164573999913, -54.264577122999924],
              [-79.81168657399985, -54.256346122999844],
              [-79.959217573999922, -53.990375122999922],
              [-80.121904291999954, -53.809612210000012],
              [-80.129615576999925, -53.801044072000025],
              [-80.177268554999898, -53.712546052999869],
              [-80.42688457499986, -53.24897512199982],
              [-80.508811753000032, -52.879146863999893],
              [-80.554209460999971, -52.674216369999897],
              [-80.553850666999892, -52.667699718499875],
              [-80.554673574999867, -52.663945121999859],
              [-80.553432574999931, -52.646327121999889],
              [-80.556005574999745, -52.634590121999906],
              [-80.555886128999873, -52.632892218999928],
              [-80.554767163999884, -52.616992069999924],
              [-80.557343126999911, -52.605235574999824],
              [-80.556107550666411, -52.587656269999911],
              [-80.558686459999876, -52.575880663999897],
              [-80.558301587999892, -52.570400254999981],
              [-80.558002574999932, -52.566144121999855],
              [-80.561890574999836, -52.558699120999904],
              [-80.564432119999822, -52.54708331799992],
              [-80.56553547999988, -52.542040099999895],
              [-80.566442766999899, -52.540301214999943],
              [-80.571160574999851, -52.531258120999894],
              [-80.574790574999952, -52.514645120999887],
              [-80.580435575000024, -52.503817120999884],
              [-80.584050575, -52.487251120999929],
              [-80.589714574999846, -52.476376120999817],
              [-80.591366574999938, -52.468795120999914],
              [-80.593412328999761, -52.464866173999894],
              [-80.593971224999791, -52.463792454999862],
              [-80.717987188999928, -51.889029147000031],
              [-80.690797981999822, -51.500318216999915],
              [-80.687131574999796, -51.447902119999867],
              [-80.758600574999832, -51.111105119999877],
              [-80.758016574999829, -51.10264512],
              [-80.76145157499991, -51.086364119999928],
              [-80.760766574999934, -51.076437119999866],
              [-80.764809574999845, -51.057265119999897],
              [-80.764526410999792, -51.053154259999928],
              [-80.764127218999874, -51.047364238999819],
              [-80.76599746699992, -51.038488952999842],
              [-80.768172574999767, -51.028165119999869],
              [-80.767666216999942, -51.020811757999866],
              [-80.767492527499826, -51.018290312999795],
              [-80.771540574999904, -50.999065119999898],
              [-80.77086257499991, -50.989216119999924],
              [-80.773309591999919, -50.977590374999863],
              [-80.774914254999771, -50.969965572999854],
              [-80.77423813099972, -50.960142870999903],
              [-80.778292460999864, -50.940865654999925],
              [-80.778170435999812, -50.939092768499904],
              [-80.778977574999885, -50.934966119999899],
              [-80.752088131999869, -50.535503940999888],
              [-80.739982448999911, -50.355667134999919],
              [-80.739188120999813, -50.353368009999883],
              [-80.737789144999908, -50.332093358999884],
              [-80.736793294999757, -50.329208621999953],
              [-80.735534888999837, -50.310027349999928],
              [-80.735042573999863, -50.302525141999865],
              [-80.734047466999868, -50.29963970499989],
              [-80.73230137399986, -50.272957698999846],
              [-80.73130665233316, -50.270070398666519],
              [-80.729564574999927, -50.243389118999914],
              [-80.728570574999935, -50.240501118999873],
              [-80.727658984999891, -50.226496309999902],
              [-80.727011357000038, -50.216550930999915],
              [-80.729066293999892, -50.20675510399991],
              [-80.728663320999942, -50.200560369999891],
              [-80.732915396999886, -50.180280141999845],
              [-80.73343146000002, -50.177818666999826],
              [-80.733030477999876, -50.171653320999894],
              [-80.737801294999826, -50.148882377999939],
              [-80.737402291999786, -50.142746228999897],
              [-80.739353702999836, -50.133425422999892],
              [-80.742175574999862, -50.119945118999908],
              [-80.741778575000012, -50.113839118999863],
              [-80.746554574999948, -50.091009118999807],
              [-80.746159574999979, -50.084931118999862],
              [-80.75093857499985, -50.062073118999876],
              [-80.750711308999882, -50.058575069999904],
              [-80.750545581666529, -50.056024448999892],
              [-80.755326574999856, -50.033136118999892],
              [-80.75493557499999, -50.027117118999797],
              [-80.759719574999906, -50.004200118999904],
              [-80.759330574999922, -49.998210118999907],
              [-80.764117523999801, -49.975263360999918],
              [-80.7637303919999, -49.969302775999836],
              [-80.76440375999988, -49.966072407999832],
              [-80.768519574999857, -49.946326118999821],
              [-80.768338463999726, -49.943536073999894],
              [-80.768134547499926, -49.940395252999906],
              [-80.772926427999835, -49.917390304999913],
              [-80.772543224999879, -49.911487953999881],
              [-80.774421039999822, -49.902466358999845],
              [-80.777337574999933, -49.888453118999905],
              [-80.777127665999899, -49.885217429999898],
              [-80.77695653599983, -49.882580299499864],
              [-80.781753550999895, -49.859516234999944],
              [-80.781374327999799, -49.853672965999863],
              [-80.781992825999907, -49.850697197999864],
              [-80.78617357499985, -49.830580118999933],
              [-80.785796574999836, -49.824765118999892],
              [-80.790598574999876, -49.801643118999891],
              [-80.790223598499892, -49.795857465499807],
              [-80.79502845199994, -49.772706710999898],
              [-80.794935976999852, -49.771281005999981],
              [-80.794654574999868, -49.766950118999887],
              [-80.798563537999712, -49.748104492999914],
              [-80.79946246899982, -49.743770068999936],
              [-80.799274341999819, -49.740869431999897],
              [-80.79990305899986, -49.737836269999832],
              [-80.801706574999798, -49.729133118999869],
              [-80.801255574999857, -49.722180118999972],
              [-80.802816574999895, -49.714648118999889],
              [-80.80254775099985, -49.710496122999814],
              [-80.801906087999896, -49.700593101999857],
              [-80.805066100999966, -49.68533730199988],
              [-80.804157563666649, -49.671301581666626],
              [-80.807319574999951, -49.656026118999819],
              [-80.806732574999899, -49.646951118999937],
              [-80.808781574999898, -49.637052118999861],
              [-80.808696637999844, -49.635737820999907],
              [-80.808513558666561, -49.632905440666548],
              [-80.808690604999811, -49.632049821999772],
              [-80.809788091999849, -49.626745191999873],
              [-80.809029226, -49.615003406999847],
              [-80.812645107999913, -49.597519398999907],
              [-80.811888228000043, -49.585800060999929],
              [-80.814087774999905, -49.575158493999879],
              [-80.815506574999972, -49.568293118999918],
              [-80.814751574999832, -49.556596118999892],
              [-80.818372575000012, -49.539067118999924],
              [-80.81761957499981, -49.527393118999896],
              [-80.821243574999812, -49.50984111899993],
              [-80.820492575, -49.498189118999846],
              [-80.824118574999943, -49.480615118999879],
              [-80.823369575000015, -49.468986118999851],
              [-80.826998574999834, -49.451389118999884],
              [-80.826251574999844, -49.439782118999915],
              [-80.828633588, -49.428226863999839],
              [-80.829883260999964, -49.422163766999915],
              [-80.829138204999765, -49.410578883999904],
              [-80.832772204999856, -49.392937684999879],
              [-80.832403982999949, -49.387208232999967],
              [-80.832033574999855, -49.381451118999905],
              [-80.83525861999982, -49.365789166999889],
              [-80.835646481999845, -49.363905237999916],
              [-80.799214796249885, -48.784547487499822],
              [-80.798222995999936, -48.781497132999903],
              [-80.796139404999849, -48.77522602099981],
              [-80.795032560999857, -48.757183995999924],
              [-80.794897584999944, -48.754984282999828],
              [-80.791832244999739, -48.745748528999854],
              [-80.791095299999938, -48.733699215999877],
              [-80.79059457499983, -48.725516117999859],
              [-80.789278911999929, -48.721547235999822],
              [-80.787529556999914, -48.716270893999877],
              [-80.786296488999938, -48.696047956999791],
              [-80.783231329999865, -48.68679311499983],
              [-80.782002601999977, -48.666579523999985],
              [-80.778937556999949, -48.657315193999921],
              [-80.777713161999941, -48.637110945999837],
              [-80.774648225999726, -48.627837129999818],
              [-80.773428151999951, -48.607642222999914],
              [-80.770363329999839, -48.598358922999921],
              [-80.769147564000036, -48.578173355999979],
              [-80.767914748999942, -48.57443522199992],
              [-80.766082574999871, -48.568880118000038],
              [-80.765400508999733, -48.557516488999852],
              [-80.764871389999826, -48.54870434399993],
              [-80.763274321999916, -48.54385659799982],
              [-80.76180657499998, -48.539402117999884],
              [-80.760536744999797, -48.51904416699989],
              [-80.757535151999718, -48.509923450999906],
              [-80.756332245999943, -48.489765887999795],
              [-80.753861273999917, -48.48224962199987],
              [-80.753267574999825, -48.480444117999866],
              [-80.752712546999931, -48.471109639999895],
              [-80.752069257999835, -48.46029644399988],
              [-80.750132051999913, -48.454397577999885],
              [-80.749004574999731, -48.450965117999885],
              [-80.747810612999899, -48.430826753999781],
              [-80.74474654699992, -48.421486706999985],
              [-80.743556402999786, -48.40135712599988],
              [-80.740492429999847, -48.392007509999871],
              [-80.739306517999864, -48.371887252999898],
              [-80.737591348999956, -48.366647952999926],
              [-80.736242574999835, -48.36252811799983],
              [-80.73506057499992, -48.342417117999844],
              [-80.733756556, -48.338428428999862],
              [-80.73199726799993, -48.333048696999924],
              [-80.731274332999817, -48.320706959999882],
              [-80.730819574999828, -48.312947117999897],
              [-80.729637960999867, -48.309329363999893],
              [-80.727756204999935, -48.303569079999875],
              [-80.727119527999832, -48.292666070999871],
              [-80.726582574999838, -48.283476117999783],
              [-80.725226114999941, -48.279319053],
              [-80.723519474999989, -48.274089323999817],
              [-80.722350382999878, -48.254006337999954],
              [-80.721119060999968, -48.250229180999895],
              [-80.719286574999842, -48.244609116999925],
              [-80.718692055999895, -48.234356212999899],
              [-80.718122154999946, -48.224535753999817],
              [-80.716081447999898, -48.218269176999854],
              [-80.715058574999773, -48.215129116999833],
              [-80.714483468999731, -48.205181264999908],
              [-80.713898231999963, -48.19506503],
              [-80.710835195999834, -48.185649221999881],
              [-80.709756058999801, -48.166937202999918],
              [-80.709678574999828, -48.165594116999898],
              [-80.709343353999941, -48.164562516999865],
              [-80.70661565599994, -48.156168492333165],
              [-80.706359905999761, -48.151719429999844],
              [-80.705463264999821, -48.136123156999929],
              [-80.702400511999826, -48.126688459999912],
              [-80.70125220299974, -48.106652009999891],
              [-80.698189593999984, -48.097207872999888],
              [-80.697045408999827, -48.077180722999913],
              [-80.695512876999885, -48.07244992],
              [-80.693982574999723, -48.067727116999833],
              [-80.692842574999815, -48.04770911699984],
              [-80.690450335999856, -48.040315987999918],
              [-80.68978056099985, -48.038246283999911],
              [-80.688644593999811, -48.01823772899985],
              [-80.685582428999851, -48.008765283999821],
              [-80.684450554999898, -47.988766022999926],
              [-80.681388540999876, -47.97928414699976],
              [-80.68119487599995, -47.97585144999988],
              [-80.680260574999807, -47.959294116999899],
              [-80.67719857499992, -47.949802116999876],
              [-80.676750451999965, -47.941829272999939],
              [-80.676075170999809, -47.929822192999893],
              [-80.673013463999894, -47.920321462999823],
              [-80.67261319399995, -47.91318182099991],
              [-80.671893575000013, -47.900350116999846],
              [-80.670973228999912, -47.897490460999848],
              [-80.668832256999849, -47.89083991699988],
              [-80.667823026999827, -47.872781235999923],
              [-80.667716606, -47.870877663999863],
              [-80.664655258999915, -47.861358233999852],
              [-80.663815732999865, -47.846288534999928],
              [-80.663543574999949, -47.841405116999908],
              [-80.663191722999727, -47.840309788999832],
              [-80.660482462999823, -47.83187641599983],
              [-80.660159355999895, -47.826058165999882],
              [-80.659374574999731, -47.811932116999856],
              [-80.657778574999838, -47.806960116999903],
              [-80.657202574999872, -47.796564116999832],
              [-80.610374475999777, -47.648666850999916],
              [-80.477703277999865, -47.229652071999908],
              [-80.471145192999927, -47.218633181999905],
              [-80.469409574999872, -47.215717116999826],
              [-80.494945574999917, -47.093310115999941],
              [-80.488649527999826, -46.964671398999883],
              [-80.466578172999789, -46.513725364999914],
              [-80.466226604999861, -46.512578317666623],
              [-80.46594457499998, -46.50668411599986],
              [-80.465369334999821, -46.504806401999929],
              [-80.464468457999942, -46.501866781999922],
              [-80.46415092399991, -46.495209423999825],
              [-80.463287574999868, -46.477117115999874],
              [-80.462512261999905, -46.474583984999924],
              [-80.461880422000036, -46.472520348999865],
              [-80.461583590999851, -46.466279757999928],
              [-80.460757574999946, -46.448923115999833],
              [-80.391791384999863, -46.220608272999925],
              [-80.289330960999905, -45.881429251999862],
              [-80.288935306999974, -45.880120743999896],
              [-80.288441512999839, -45.879259601999877],
              [-80.280172753999977, -45.851514872999928],
              [-80.279662785333301, -45.850624543999913],
              [-80.277805916000034, -45.844385883999905],
              [-80.27141335899978, -45.822908826999878],
              [-80.270887576999769, -45.821990121999875],
              [-80.262636124333142, -45.794265945333223],
              [-80.262111574999949, -45.792521114999829],
              [-80.254936574999988, -45.779968114999917],
              [-80.250992663999853, -45.766676413999903],
              [-80.24496731999983, -45.756124914999873],
              [-80.24273439999979, -45.748590959999888],
              [-80.241672574999711, -45.745009114999846],
              [-80.177483291999863, -45.631214281999917],
              [-79.937630488999901, -45.206003820999911],
              [-79.935083790499903, -45.203073495499893],
              [-79.933840573999873, -45.200844114999882],
              [-79.92562663800004, -45.19138831399988],
              [-79.921908573999929, -45.187108372999887],
              [-79.916120661999855, -45.17671465299992],
              [-79.904151427999921, -45.162922607999803],
              [-79.899818235999817, -45.155131903999916],
              [-79.898401573999934, -45.152585114999852],
              [-79.896224752999899, -45.150074219999851],
              [-79.888614223999809, -45.141296273999956],
              [-79.893657189999715, -45.117912630999854],
              [-79.883195922999846, -44.797835368999927],
              [-79.874695573999873, -44.537765113999853],
              [-79.817865874999939, -44.332856333999985],
              [-79.716916144999857, -43.968867649999879],
              [-79.504710186999887, -43.570650322999882],
              [-79.448936573999902, -43.465988112999923],
              [-79.446623573999887, -43.360307112999976],
              [-79.297263573999828, -42.790379111999869],
              [-79.290876594999929, -42.778021318999834],
              [-79.28396044033309, -42.764556969666614],
              [-79.277840747999846, -42.752588742999933],
              [-79.270292299666437, -42.73789312033324],
              [-79.267912869999805, -42.733177441],
              [-79.264612792999969, -42.726740977666566],
              [-78.987665179999851, -42.183971577999898],
              [-78.630456251999817, -41.731103972999847],
              [-78.626169384999855, -41.713336394999885],
              [-78.62263873366652, -41.706177508333212],
              [-78.617382928499865, -41.684585778499923],
              [-78.613944202999903, -41.677607122999916],
              [-78.60880441199987, -41.656252050999854],
              [-78.607882276999874, -41.654378964999978],
              [-78.605251572999975, -41.649036110999873],
              [-78.601552932, -41.633647714],
              [-78.600125550999792, -41.627709649999929],
              [-78.596562207999852, -41.62046511099976],
              [-78.5914491, -41.599167097999896],
              [-78.587874740666564, -41.591893512333229],
              [-78.582774572999881, -41.570624110999802],
              [-78.579189572999837, -41.563322110999763],
              [-78.57652632699984, -41.552199417999873],
              [-78.5741034159999, -41.542081536999831],
              [-78.570507475999932, -41.534751018999827],
              [-78.565434172999801, -41.513538528999845],
              [-78.561827418999826, -41.50617936999987],
              [-78.556767326, -41.484995368999819],
              [-78.554896597666527, -41.481175176666511],
              [-78.553954057999931, -41.477225848999922],
              [-78.552282622999769, -41.470222418999924],
              [-78.550894316999745, -41.467385914999909],
              [-78.548886572999891, -41.463284110999894],
              [-78.547363628499966, -41.456897454499881],
              [-78.540269186999979, -41.442292957999882],
              [-78.538039199999787, -41.432930728999906],
              [-78.537024572999769, -41.428672110999912],
              [-78.529966572999854, -41.414229110999827],
              [-78.528816549999931, -41.409393389999877],
              [-78.526735375999863, -41.400643997999794],
              [-78.522188595999978, -41.391331102999914],
              [-78.519666573, -41.386166110999852],
              [-78.518433276, -41.38097269799988],
              [-78.516448091999905, -41.372615452999923],
              [-78.509369202999835, -41.358102473999907],
              [-78.507493463999822, -41.350195191000012],
              [-78.506162572999813, -41.344586110999899],
              [-78.501825068999779, -41.335684713999967],
              [-78.499073660333181, -41.330038360999879],
              [-78.498831735999886, -41.329017062999924],
              [-78.495880243999864, -41.31655789999985],
              [-78.488780569000028, -41.301974766999933],
              [-78.485599673999843, -41.288528892999921],
              [-78.478489647999879, -41.273910698999828],
              [-78.475321334999762, -41.260499731999914],
              [-78.470320105999917, -41.250207509999825],
              [-78.46820057299999, -41.245846110999935],
              [-78.466833730999838, -41.240051221999863],
              [-78.465045222999947, -41.232470415999785],
              [-78.457914575999951, -41.217782121999846],
              [-78.454771333999929, -41.204440947999899],
              [-78.44763042, -41.189717636999831],
              [-78.444909163999853, -41.1781517739999],
              [-78.444499575999941, -41.176411135999842],
              [-78.437348511999915, -41.161652996999919],
              [-78.434230211999875, -41.148381549999897],
              [-78.429075970999918, -41.1377343609999],
              [-78.427068572999758, -41.133588110999881],
              [-78.423962572999869, -41.12035111099982],
              [-78.421157455999946, -41.114550765999866],
              [-78.41679142199996, -41.105523289999894],
              [-78.41549582999977, -41.099994215999914],
              [-78.413697572999752, -41.092321110999819],
              [-78.411604887999772, -41.087989740999831],
              [-78.40651623299982, -41.077458223999827],
              [-78.403435115999912, -41.064291302999834],
              [-78.396243277999758, -41.04939301499985],
              [-78.393174492999776, -41.0362609149999],
              [-78.388147642333195, -41.025838370999793],
              [-78.386012300999766, -41.016691297999841],
              [-78.355514747999791, -40.952777588999822],
              [-78.350569572999916, -40.942414110999891],
              [-78.296365082999955, -40.594141092999905],
              [-78.267682567999884, -40.409851966999895],
              [-78.193209085999968, -40.218684813999857],
              [-78.13794457299997, -40.076826109999899],
              [-78.137612622999711, -40.074631150999892],
              [-78.137165124999967, -40.071675704999905],
              [-78.065922383999975, -39.88735305799986],
              [-78.047398088999842, -39.763542248999897],
              [-78.046878869999887, -39.762192169499883],
              [-78.044403654999769, -39.746126660999892],
              [-78.037373460000026, -39.727817712999865],
              [-78.021096572999852, -39.685428109999862],
              [-78.020907011999896, -39.68415123349979],
              [-78.015445765999914, -39.669775730999959],
              [-78.010980572999983, -39.658132108999837],
              [-78.005590732999849, -39.621810978999861],
              [-78.001906472999963, -39.596984748999944],
              [-77.973601578999762, -39.522971215666587],
              [-78.004229419000012, -39.422873675999881],
              [-78.14245925799986, -38.971112281999865],
              [-78.145387096499803, -38.932456798999851],
              [-78.14620757299997, -38.929754108999873],
              [-78.147493563999888, -38.912638253499836],
              [-78.158362572999806, -38.876828108999916],
              [-78.183472930999926, -38.540215880999881],
              [-78.201721205999945, -38.295587109999815],
              [-78.201598950499829, -38.294759614999862],
              [-78.201626572999771, -38.293891107999912],
              [-78.142041835999919, -37.886717831999761],
              [-78.116975455999977, -37.715427553999916],
              [-77.909617138999806, -37.157031582999878],
              [-77.82156917899988, -37.014875260999986],
              [-77.812575022999908, -36.950340489999803],
              [-77.806351571999869, -36.905688106999889],
              [-78.337310572999741, -37.008830106999824],
              [-78.343183572999862, -37.009117106999781],
              [-78.350824572999954, -37.010584106999829],
              [-79.075736573999961, -37.043931109999846],
              [-79.615533573999983, -36.99047210699996],
              [-80.230098574999772, -37.100844107999933],
              [-80.956773574999829, -37.124213106999889],
              [-80.958670574999758, -37.124002106999853],
              [-80.965095094999754, -37.124192097999924],
              [-81.033420202999821, -37.116431413999862],
              [-81.684379075999914, -37.042481106999844],
              [-81.698847575999821, -37.040787106999858],
              [-82.386047577, -36.856872106999901],
              [-82.387878576999924, -36.856109106999838],
              [-83.020322576999831, -36.576291107000031],
              [-83.577217077999876, -36.210008105999862],
              [-83.58608357799983, -36.204086105999863],
              [-84.049992577999774, -35.763779105999845],
              [-84.407775725999841, -35.263607430499917],
              [-84.408091095999907, -35.262903152999854],
              [-84.409128626999973, -35.261444310999877],
              [-84.409618578999812, -35.26075510499976],
              [-84.410088578999989, -35.25970510499991],
              [-84.410531980333246, -35.259055988999933],
              [-84.41091457899995, -35.258255104999876],
              [-84.411499793999837, -35.257433253999807],
              [-84.470653733999882, -35.124608837999915],
              [-84.653713068333218, -34.7134201876666],
              [-84.654039971999964, -34.712614154999983],
              [-84.655485578999901, -34.709349104999887],
              [-84.655707374666633, -34.708405367999887],
              [-84.656419493999749, -34.706681092999872],
              [-84.657123078999859, -34.703687604999914],
              [-84.658893553999974, -34.695847287999982],
              [-84.659671578999905, -34.692273104999856],
              [-84.66067357899999, -34.690011104999826],
              [-84.781969578999849, -34.119561104000013],
              [-84.781963528999768, -34.116309083999852],
              [-84.781960347999984, -34.114723704999847],
              [-84.783420987666545, -34.107708043333218],
              [-84.784243437999976, -34.103706778999879],
              [-84.783467331999873, -33.524173399999881],
              [-84.663028317999988, -32.953039909999859],
              [-84.656129201999988, -32.937094398999818],
              [-84.655389578999859, -32.935385102999987],
              [-84.654082578999748, -32.929123102999824],
              [-84.544586647999893, -32.673952049999855],
              [-84.419862581999837, -32.383293443999889],
              [-84.418865387666642, -32.381817500333227],
              [-84.288037528999837, -32.187311505999901],
              [-84.079384784999775, -31.877113884999915],
              [-84.075579934999951, -31.873200735499836],
              [-84.074795707999954, -31.872113371999887],
              [-84.074281441999858, -31.871343124999896],
              [-83.640929726999843, -31.422438444000022],
              [-83.126968886999805, -31.042011470999853],
              [-83.12569368599975, -31.041353677999894],
              [-83.125015255, -31.040847887999973],
              [-82.545586542999786, -30.739607155999977],
              [-81.917174750999948, -30.526000768999836],
              [-81.91437979799997, -30.525500191999924],
              [-81.913303271999894, -30.52513074299992],
              [-81.906531386499807, -30.523917569999867],
              [-81.905582469999786, -30.523604988999864],
              [-81.895859382999959, -30.520266928999888],
              [-81.236039802999812, -30.400163015999851],
              [-80.562866824999787, -30.377044830999921],
              [-80.561329872999806, -30.377220286999886],
              [-80.559008919999883, -30.377134649999903],
              [-80.095223815999816, -30.429248085333256],
              [-80.093711241999813, -30.428703679999899],
              [-80.091959469999836, -30.428073241],
              [-79.435443421999878, -30.299018435999869],
              [-78.763817341999868, -30.266827420999959],
              [-78.761598600999946, -30.267050832999871],
              [-78.758987285999979, -30.266918987999986],
              [-78.75807902099973, -30.267010415999891],
              [-78.75416, -30.266812319999872],
              [-78.752277519999836, -30.267001537999874],
              [-78.748356504999947, -30.266803272],
              [-78.080008973999952, -30.332329838999897],
              [-78.077405630999863, -30.332987375999949],
              [-78.075196708999954, -30.33319826799999],
              [-77.428454271999954, -30.494726994999894],
              [-77.42662399399984, -30.495473291999872],
              [-77.42538381799983, -30.495971639999937],
              [-77.420650686000045, -30.497140365999883],
              [-77.419739076999861, -30.4975164734999],
              [-77.364441921999912, -30.520721406999826],
              [-77.358331462999899, -30.522226849999868],
              [-76.750626679999812, -30.775129413999935],
              [-76.74884784499983, -30.775897301999976],
              [-76.197947901999839, -31.113286024999923],
              [-75.719704081000032, -31.525829821999821],
              [-75.719139136999956, -31.526521667999873],
              [-75.71766895899998, -31.52778183099997],
              [-75.714216706999707, -31.532009110999923],
              [-75.710588505999908, -31.53511848699992],
              [-75.699030241999765, -31.549266796999916],
              [-75.698364872999889, -31.549836787999823],
              [-75.52438094166655, -31.762179992666518],
              [-75.57896656999975, -31.222787101999927],
              [-75.578871060499864, -31.2213501949999],
              [-75.577972606499884, -31.212186458999838],
              [-75.5782212229999, -31.209716440999898],
              [-75.579092071999924, -31.201062034999893],
              [-75.579043676333242, -31.200229081666578],
              [-75.572476153999787, -31.136615478999943],
              [-75.572404606499788, -31.135926445499877],
              [-75.578887245999852, -31.07131227199983],
              [-75.600992081999891, -30.850985104999836],
              [-75.600880817999894, -30.849799377999886],
              [-75.600920089999931, -30.849184896999873],
              [-75.587666570999829, -30.721264121999866],
              [-75.589938074999793, -30.698554996999974],
              [-75.589841208666542, -30.697290355999939],
              [-75.589571752999859, -30.69402950899979],
              [-75.588130569999748, -30.680101100999821],
              [-75.591017028749889, -30.650608658499834],
              [-75.531546569999819, -30.071782100999826],
              [-75.520841605999976, -30.037321610999868],
              [-75.51785952900002, -30.027722094999945],
              [-75.509915571999898, -29.949789167999882],
              [-75.498704388999897, -29.913631173999818],
              [-75.496104455999841, -29.888058346999912],
              [-75.495299581999859, -29.880142218999879],
              [-75.494238193999934, -29.876716096999814],
              [-75.494146099499858, -29.875811153499853],
              [-75.492073569999889, -29.86895909999987],
              [-75.491735569999832, -29.865630099999976],
              [-75.489758569999765, -29.859247099999948],
              [-75.488065785999879, -29.842569450999804],
              [-75.486145267999916, -29.82365175599989],
              [-75.485472481999977, -29.821478114999877],
              [-75.485046015999842, -29.81986293366657],
              [-75.477231942999879, -29.794413458999884],
              [-75.471329569999966, -29.775335099999893],
              [-75.470895485999733, -29.771046659999911],
              [-75.469813147999957, -29.760359001999859],
              [-75.398660306999943, -29.529500162999966],
              [-75.377308569999968, -29.4602241],
              [-75.372482569999988, -29.411957099999881],
              [-75.40429857, -29.095738099999835],
              [-75.404120569999861, -29.093955099999846],
              [-75.404202520999945, -29.093138590999786],
              [-75.404092184999911, -29.0920345319999],
              [-75.403811570000016, -29.089229099999898],
              [-75.403954990499869, -29.087802516499835],
              [-75.403833347999864, -29.085455266999929],
              [-75.403894569999864, -29.084840099999894],
              [-75.346411569999987, -28.506373098999916],
              [-75.176709600999857, -27.945273199999917],
              [-74.90150612699972, -27.418174373999818],
              [-74.900929463999944, -27.417436357999861],
              [-74.900494150999805, -27.416598059999842],
              [-74.898868099999959, -27.414516930999866],
              [-74.898416521999764, -27.413647247999961],
              [-74.862668552999821, -27.367868780999856],
              [-74.823666700999979, -27.292608976999873],
              [-74.818793355999958, -27.286355924999839],
              [-74.814998375999778, -27.279025651999859],
              [-74.741804353999783, -27.030675892999852],
              [-74.74089515799983, -27.028912854999817],
              [-74.703757144999827, -26.902428739999891],
              [-74.696094597999718, -26.876332191999893],
              [-74.695755804999862, -26.875673599999857],
              [-74.69551050699971, -26.874836812999888],
              [-74.69498930199984, -26.873059135999881],
              [-74.687639178999973, -26.858770117999882],
              [-74.687171566999837, -26.857861072499759],
              [-74.670242549999955, -26.672208712999918],
              [-74.66692001624989, -26.660833808499888],
              [-74.666861344999973, -26.660189066999862],
              [-74.503347618999896, -26.0980952889999],
              [-74.434263728333235, -25.962169503333257],
              [-74.444903601666567, -25.861260408499845],
              [-74.444863953374806, -25.860299426499921],
              [-74.404397466999967, -25.400744059999909],
              [-74.393819568999845, -25.280739095999763],
              [-74.352679661000025, -25.136519437999937],
              [-74.249459414999819, -24.774672278999901],
              [-74.245625184, -24.730184857999859],
              [-74.238650568999731, -24.649264095999911],
              [-74.240295566999862, -24.633761105999781],
              [-74.239940485000034, -24.62963772199987],
              [-74.240118402999769, -24.627960347999874],
              [-74.240078764333191, -24.627302907333274],
              [-74.240056379666612, -24.626354594666566],
              [-74.228193007999892, -24.487918434999926],
              [-74.199583568999913, -24.154583094999907],
              [-74.258338568999989, -23.596488094999756],
              [-74.258181568999873, -23.594660094999767],
              [-74.258262568999896, -23.593888094999841],
              [-74.25801839059983, -23.59091843659985],
              [-74.258140216999891, -23.589687236999907],
              [-74.257941117999849, -23.587365563999924],
              [-74.258021196999835, -23.586599262999812],
              [-74.245291208999845, -23.437092974999928],
              [-74.208524163999982, -23.006668964999847],
              [-74.203721407999922, -22.989505488999953],
              [-74.202198568999847, -22.984064093999905],
              [-74.202038323999886, -22.982176871999911],
              [-74.201727195999808, -22.978517667999824],
              [-74.195760483999948, -22.957184655999924],
              [-74.193569568999862, -22.949352093999835],
              [-74.193284606999839, -22.945994768999867],
              [-74.192598120999889, -22.93791265699997],
              [-74.192334987999857, -22.936971959999767],
              [-74.186694568999826, -22.870885093999959],
              [-74.185732724999923, -22.867441984999914],
              [-74.183532491999927, -22.859566683999901],
              [-74.181336207999834, -22.833656644999849],
              [-74.174292569000045, -22.750566093999851],
              [-74.169373529999916, -22.732935403333158],
              [-74.169274911999935, -22.732057902999884],
              [-74.168532375000041, -22.723286420999841],
              [-74.156128094, -22.678803652999875],
              [-74.150435404999826, -22.611430479999967],
              [-74.149656291999861, -22.608633625999914],
              [-74.148768207999922, -22.598116070999936],
              [-74.147691568999733, -22.585370093999757],
              [-74.055731683999966, -22.253727071999833],
              [-73.991428521999921, -22.021825717999945],
              [-73.745249238999861, -21.514609722999921],
              [-73.736869074999845, -21.411032449999823],
              [-73.72592035, -21.370898490999764],
              [-73.775118390999978, -20.90586309299988],
              [-73.759800181999935, -20.716148925999903],
              [-73.728226568999958, -20.325118090999808],
              [-73.725385394999876, -20.314626601999919],
              [-73.722981527, -20.305750678999857],
              [-73.720805410999844, -20.278684919999876],
              [-73.715322439199952, -20.210491349799952],
              [-73.807843469999852, -19.909520558999816],
              [-73.888283982999951, -19.81425922499983],
              [-73.918132916999809, -19.791620682999792],
              [-73.930195952999952, -19.782320523999886],
              [-73.978318823999984, -19.750763279999887],
              [-73.992000864999852, -19.741572221999888],
              [-74.068409569999972, -19.711022115999924],
              [-74.267096130999903, -19.620998405999842],
              [-74.45946945799983, -19.518172719999882],
              [-74.49227693399979, -19.498981097999831],
              [-74.520271804999879, -19.487282297999911],
              [-74.709311094999975, -19.401272344999768],
              [-74.817938233999939, -19.345204130999889],
              [-74.927515328999846, -19.307236783999826],
              [-75.111815533999845, -19.237208047999957],
              [-75.310502095999709, -19.147184337999875],
              [-75.502875422999978, -19.044358651999914],
              [-75.688111740999886, -18.929171306999763],
              [-75.792999012, -18.856141061999836],
              [-75.810638340999844, -18.847862763999842],
              [-75.868772052999873, -18.819890463999911],
              [-76.003081786999871, -18.749818066999865],
              [-76.027795828999956, -18.736200994999876],
              [-76.085859420999839, -18.703447705999906],
              [-76.271095739999851, -18.588260360999868],
              [-76.342653101999872, -18.539087314999961],
              [-76.525700195999832, -18.455588307999903],
              [-76.659417880999911, -18.384114715999885],
              [-76.710872570999925, -18.384115664999911],
              [-76.724608570999862, -18.374882664999859],
              [-76.770891476999907, -18.35411188899991],
              [-76.767777777999868, -18.352222221999909],
              [-73.881944443999885, -18.352222221999909],
              [-73.880814125999905, -18.352526179599977],
              [-73.850041073999876, -18.352423161999923],
              [-73.155290673999843, -18.352222221999966],
              [-70.402785496999797, -18.351028077000024],
              [-70.405490173012822, -18.348545724588064],
              [-70.390017830175452, -18.335282028814277],
              [-70.367090977993485, -18.322781928935797],
              [-70.327790916103254, -18.319446099100674],
              [-70.249173022687387, -18.327781901831941],
              [-70.200835760406108, -18.330281888280012],
              [-70.178073025889034, -18.32889099526318],
              [-70.132508832461838, -18.32125508144901],
              [-69.9908459512219, -18.270146091417871],
              [-69.958481914228344, -18.249445975639958],
              [-69.867782010265785, -18.157781985171255],
              [-69.844590960495168, -18.132364031358804],
              [-69.796672961011836, -18.039717849473391],
              [-69.786390880357885, -18.015281919439758],
              [-69.77444599774708, -17.985281914424547],
              [-69.769590864138195, -17.952573045933548],
              [-69.8124998382699, -17.801108870006942],
              [-69.82167299313079, -17.782499871438972],
              [-69.838063972474174, -17.767499952750484],
              [-69.849726887861323, -17.749235953318191],
              [-69.846117807981955, -17.708335953745177],
              [-69.83487298192631, -17.68139098480799],
              [-69.814726909949798, -17.652226823179717],
              [-69.77028186823793, -17.656946002318648],
              [-69.7341729645334, -17.659999864930228],
              [-69.683899817889198, -17.663608944809653],
              [-69.630008874186274, -17.617499927674658],
              [-69.499726773471593, -17.505282001819921],
              [-69.487226841231063, -17.632917935694707],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "PER", Country: "Peru (Chilean point of view)" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-69.956926903547014, -4.236872903843476],
            [-69.950290950784535, -4.262499902321792],
            [-69.960563978982947, -4.283190965644224],
            [-69.979590899244528, -4.312499966559713],
            [-70.016963961589568, -4.33794608356699],
            [-70.197826824795897, -4.332654923353644],
            [-70.255846023462226, -4.291390981543586],
            [-70.301390938511901, -4.24999997008122],
            [-70.30876399584173, -4.230281878082124],
            [-70.298335902434019, -4.199026934519878],
            [-70.299726795450766, -4.174164030337309],
            [-70.320145949833574, -4.139717928589363],
            [-70.42938192980634, -4.1320178093967],
            [-70.465290841300515, -4.154726899733333],
            [-70.499999967170936, -4.17971787939689],
            [-70.584163998295196, -4.195554982576866],
            [-70.625699849044878, -4.160555004665596],
            [-70.762435847584584, -4.147708899823058],
            [-70.810290982794072, -4.170554950457998],
            [-70.832226926379548, -4.187017846530864],
            [-70.868899932169569, -4.251108895874367],
            [-70.872554944878402, -4.280117824655434],
            [-70.956264006297118, -4.382217955158836],
            [-71.036655007515435, -4.381935820297045],
            [-71.06514593467864, -4.362917952491159],
            [-71.169445979495492, -4.35778202512509],
            [-71.285935826134619, -4.381035939169749],
            [-71.382235847262479, -4.430835843559322],
            [-71.65056403798701, -4.508890975994191],
            [-71.726681944954635, -4.49328202821917],
            [-71.74719095333964, -4.490355067622076],
            [-71.881972962605687, -4.510863908369174],
            [-71.902481970990664, -4.518190865231375],
            [-72.028064003305786, -4.612499849073259],
            [-72.129181942392279, -4.693608844119623],
            [-72.234999949878585, -4.753335939381927],
            [-72.253617833263775, -4.761664030762262],
            [-72.279172915012737, -4.763608967580524],
            [-72.300981956583797, -4.75699095209086],
            [-72.325290984603043, -4.760835898726157],
            [-72.375290881202972, -4.784446043656757],
            [-72.389590910974505, -4.805690983142696],
            [-72.39056388229784, -4.838608896299419],
            [-72.403335891116114, -4.874517807793666],
            [-72.476964037557536, -4.94110884358534],
            [-72.61750894054893, -5.020281954268938],
            [-72.756673008807354, -5.076945966826159],
            [-72.786663961367054, -5.088891017074815],
            [-72.851964016428155, -5.124717953555802],
            [-72.898617857270978, -5.172781965792879],
            [-72.91833578163218, -5.32166401262009],
            [-72.961399820973099, -5.452499989498179],
            [-72.964172889827381, -5.477226939210937],
            [-72.952563953896856, -5.510281980303944],
            [-72.942790990043221, -5.537499864009376],
            [-72.961955038240887, -5.648608864071036],
            [-72.988890954722706, -5.711663910179254],
            [-73.048064006183409, -5.791945946656],
            [-73.082226799603376, -5.827217833507177],
            [-73.130281927023105, -5.87639093917538],
            [-73.205563990603594, -6.017499944252251],
            [-73.218063922844095, -6.045281930042904],
            [-73.229735890686641, -6.093608966402371],
            [-73.225572934643907, -6.12139095219311],
            [-73.218608914190099, -6.146255029842166],
            [-73.166399883183118, -6.240281878822529],
            [-73.118617838169655, -6.381526838369126],
            [-73.120008898824409, -6.418055004871633],
            [-73.123899945927519, -6.447217825395327],
            [-73.145008930943476, -6.489164054125681],
            [-73.160846034123438, -6.51555497926131],
            [-73.186117807544235, -6.547363966624971],
            [-73.216673029827319, -6.566663969292648],
            [-73.276399957451446, -6.574999939661907],
            [-73.298763881014253, -6.574446063498485],
            [-73.335008906826943, -6.574446063498485],
            [-73.361190954934983, -6.581990949843956],
            [-73.587508879188732, -6.734999910530178],
            [-73.676390915985621, -6.804445990189393],
            [-73.744173020221865, -6.87694610009828],
            [-73.754454933237895, -6.894164038011311],
            [-73.799726933519366, -7.080835895410601],
            [-73.795135829861323, -7.122626891293663],
            [-73.775563918253738, -7.137217940744293],
            [-73.719999946672345, -7.223054999747006],
            [-73.705835871370908, -7.309235884419081],
            [-73.738199908364294, -7.33395495514273],
            [-73.931117792389273, -7.359164031927449],
            [-73.930526868213718, -7.442990942905155],
            [-73.962435767863468, -7.527364018695252],
            [-73.987155006225294, -7.528835880896878],
            [-74.002154924913867, -7.530454929318878],
            [-74.00459087362151, -7.554373025372925],
            [-73.974163894457377, -7.574446007153583],
            [-73.932508853044112, -7.609164017841337],
            [-73.86556393329937, -7.675281979016461],
            [-73.851954907627658, -7.699581954580296],
            [-73.842226870774965, -7.717363994578633],
            [-73.821535807452534, -7.731664024350067],
            [-73.751954946789709, -7.756946023692635],
            [-73.724163908543659, -7.764445983036964],
            [-73.706664003407013, -7.776390865647542],
            [-73.693755034290433, -7.803335834584772],
            [-73.698199957556767, -7.846181944442606],
            [-73.725390851533888, -7.879581984671361],
            [-73.777017843182733, -7.872881994168338],
            [-73.771955006012604, -7.948054925369163],
            [-73.756326779860217, -7.967082013268723],
            [-73.709872931227636, -7.983890914305647],
            [-73.651673018728161, -8.016663988175225],
            [-73.595699842632769, -8.121391007141114],
            [-73.55667303078647, -8.249172953769616],
            [-73.541399862053623, -8.302781930248941],
            [-73.533199846154275, -8.352363905155258],
            [-73.4786178983517, -8.398608876760164],
            [-73.404726895425966, -8.450555051282919],
            [-73.345699856718966, -8.602364058707138],
            [-73.273335868917968, -8.674235861152283],
            [-73.240981890208388, -8.679582006650492],
            [-73.21556393639591, -8.686108827033493],
            [-73.183899955957742, -8.700135942036624],
            [-73.166672965589328, -8.714164062868335],
            [-73.152508890287692, -8.734999965478266],
            [-73.141954900693975, -8.76222690163911],
            [-73.132781913471092, -8.790555052242155],
            [-73.121945956653747, -8.807499907749076],
            [-73.058817820349589, -8.902426805494827],
            [-73.005763893500074, -8.930346086859856],
            [-72.962363907479016, -8.988472909163292],
            [-72.951126792774488, -9.045835804809244],
            [-72.94806404534566, -9.066526868131859],
            [-72.949172971138893, -9.090135839595888],
            [-72.955290922283694, -9.11916404675415],
            [-72.966399961507335, -9.143054985441879],
            [-73.023617850227879, -9.221108944410261],
            [-73.119445970205675, -9.31333586349777],
            [-73.158063912813759, -9.3444459677726],
            [-73.182926816996428, -9.364999903158647],
            [-73.200699972177347, -9.400763975365763],
            [-72.980835778110674, -9.410835837887447],
            [-72.861954909764961, -9.412226898542471],
            [-72.840835866465113, -9.411663969923637],
            [-72.730008833627267, -9.413055030578391],
            [-72.606946066137681, -9.446108898205011],
            [-72.4022358501403, -9.483608862564452],
            [-72.371672916506355, -9.492635837033617],
            [-72.301526780291823, -9.533191005108947],
            [-72.252508907470627, -9.656945950164712],
            [-72.257646008303169, -9.692572894435443],
            [-72.269308923690232, -9.730554985866746],
            [-72.260355039417107, -9.759163930227118],
            [-72.226526851572942, -9.777781981250484],
            [-72.180281879967936, -9.803399927273219],
            [-72.164735796466886, -9.834717902747414],
            [-72.150772886842276, -9.88701796122325],
            [-72.16833582389097, -9.954446013868349],
            [-72.185835896665509, -10.002499967821706],
            [-72.143890841401827, -10.004717819408086],
            [-72.052226850933238, -10.004717819408086],
            [-72.010281963307449, -10.00444607810627],
            [-71.979172864860828, -10.00444607810627],
            [-71.930281894054332, -10.00444607810627],
            [-71.64195498521147, -10.00444607810627],
            [-71.459163948993307, -10.004163943244663],
            [-71.368055008154613, -10.003890860838467],
            [-71.296390909270656, -9.995417930170802],
            [-71.180417890782934, -9.932226929592773],
            [-71.158899868890785, -9.889999907105292],
            [-71.138545925714993, -9.863817858997152],
            [-71.013964022089624, -9.820835794669506],
            [-70.985354910091161, -9.796735811315926],
            [-70.969317814700929, -9.763890988355158],
            [-70.881954914039852, -9.6699999262067],
            [-70.843608880371391, -9.645554943717556],
            [-70.779172999530232, -9.605554992909944],
            [-70.643335876289512, -9.490835907140664],
            [-70.588617806378949, -9.440835842902743],
            [-70.568064038630808, -9.433473011494712],
            [-70.514663939179144, -9.427999963981918],
            [-70.545290910553717, -9.50083585293298],
            [-70.577226799931708, -9.538608899698716],
            [-70.607590914821685, -9.575663952636319],
            [-70.590835825603421, -9.631599913081601],
            [-70.595290974791652, -9.781390893491704],
            [-70.612646040641067, -9.798755011796615],
            [-70.625563894574981, -9.825972895502048],
            [-70.628890839592572, -9.895835891026479],
            [-70.628617924824454, -9.94849100692177],
            [-70.629454941677977, -10.06889101140294],
            [-70.629181859271682, -10.186390877377519],
            [-70.629454941677977, -10.338608921677931],
            [-70.63028190024761, -10.608608966814529],
            [-70.630846002332817, -10.781663919552912],
            [-70.631954928125964, -10.913054946060896],
            [-70.631954928125964, -10.975282027771186],
            [-70.631390993678735, -11.009164027433911],
            [-70.58168194911994, -10.971108845806569],
            [-70.526399944762147, -10.935690946201774],
            [-70.499308963071115, -10.953472986200026],
            [-70.47111794040427, -10.981108959236963],
            [-70.456945986113823, -11.001664068089582],
            [-70.43667301212281, -11.026946067432249],
            [-70.402508877598393, -11.045555066000205],
            [-70.352781895766668, -11.06610883374826],
            [-70.328899841896458, -11.069172922281652],
            [-70.226526796624853, -11.052781942938154],
            [-70.17499988490016, -11.037499889387988],
            [-70.154172867107832, -11.029999930043658],
            [-70.051681972277123, -10.979172907236119],
            [-69.999999827705352, -10.946945998179118],
            [-69.969454998982116, -10.92860890855097],
            [-69.950008815922843, -10.919999855775416],
            [-69.925981922765203, -10.914026911555922],
            [-69.780208891472597, -10.924717861447959],
            [-69.737917831244687, -10.961599911903534],
            [-69.688608938744466, -10.958608913565996],
            [-69.666117945528896, -10.953335858263728],
            [-69.586117876275864, -10.950281995652134],
            [-69.567508877707809, -10.950555078058514],
            [-69.568435916201707, -10.951091016948936],
            [-69.500563957963152, -11.062499921506856],
            [-69.432508938958591, -11.173608921568515],
            [-69.248899828988215, -11.472499878288914],
            [-69.214735862101975, -11.528054965052903],
            [-69.180554963771215, -11.583608878350489],
            [-69.069455016165051, -11.764163958071364],
            [-69.039463895967259, -11.812782014109857],
            [-69.000835895075369, -11.875282010588251],
            [-68.987226869403656, -11.896108860742686],
            [-68.957226864388446, -11.944726916781178],
            [-68.925281922554973, -11.998335893260403],
            [-68.880281998851302, -12.086946021117484],
            [-68.835554989915721, -12.176946036163017],
            [-68.749454906790533, -12.350281950296804],
            [-68.736390872464739, -12.378055051270124],
            [-68.698626878154698, -12.454172958237663],
            [-68.677508840683146, -12.495835878639838],
            [-68.67390881325926, -12.501155034409805],
            [-68.743617917041036, -12.584726800254046],
            [-68.768346040220194, -12.644164049303711],
            [-68.815563983148422, -12.733054970917834],
            [-68.879445987826273, -12.757499953407077],
            [-68.922226886477176, -12.802782011972454],
            [-68.97334593479215, -12.865490885478621],
            [-68.97334593479215, -13.0019460902612],
            [-68.963345988999833, -13.026673039973957],
            [-68.968481916365789, -13.096663943341284],
            [-68.968335903612171, -13.166108849534112],
            [-68.960554982872537, -13.27250001156122],
            [-68.959454941896809, -13.452782008875815],
            [-68.96125503942784, -13.506808907048566],
            [-68.979172866257926, -13.566108860523769],
            [-69.023090853896775, -13.642046053658333],
            [-69.062790900207858, -13.707781967685762],
            [-69.014173011807372, -13.791108981956597],
            [-69.001681964384289, -13.839446076599955],
            [-68.975146032323181, -13.966255051905122],
            [-68.960972904566148, -13.987081902059558],
            [-68.937372985557346, -14.005417818221133],
            [-68.898608862557467, -14.031808910995025],
            [-68.857890917921907, -14.200135799777243],
            [-68.912781990314585, -14.216946041918632],
            [-68.958899892266999, -14.223890951633066],
            [-68.986399910834024, -14.231663993383592],
            [-69.002372968484025, -14.248755029282208],
            [-68.999799891839899, -14.336046013213817],
            [-68.984235871066204, -14.378126855309503],
            [-69.115572918117465, -14.484163965745466],
            [-69.235417872969009, -14.588955022451799],
            [-69.244735867117186, -14.616391003278395],
            [-69.248608809309218, -14.654164050044116],
            [-69.248899828988215, -14.687499884894351],
            [-69.310563982079771, -14.766946077984144],
            [-69.343481895236494, -14.779863931918058],
            [-69.364799924918287, -14.800626911970042],
            [-69.381390896471942, -14.964791012347433],
            [-69.295008846123324, -15.079446060376256],
            [-69.197781954139657, -15.158608945137971],
            [-69.170835811735941, -15.187781991583549],
            [-69.137090940009614, -15.227635929637373],
            [-69.143135800958646, -15.254445949933086],
            [-69.169872898696525, -15.263335796465782],
            [-69.195981856608711, -15.261663942053715],
            [-69.268335786125817, -15.326946059841973],
            [-69.332508810482665, -15.438890903290499],
            [-69.420981978041539, -15.621526874299462],
            [-69.213817876063587, -16.157217828138045],
            [-69.159726772512329, -16.211873033774637],
            [-69.140008848151211, -16.222226863520021],
            [-69.070699896428209, -16.227573009018315],
            [-69.044726892985807, -16.207781994461271],
            [-68.968199949142274, -16.20909996492027],
            [-68.932563952416132, -16.250555014123165],
            [-68.892899948288857, -16.283617934205282],
            [-68.838481950512829, -16.29839992341067],
            [-68.824245958481754, -16.326317863671235],
            [-68.83334602314676, -16.354026926904112],
            [-68.990846007566859, -16.419726966385809],
            [-69.034181955847487, -16.473054981469829],
            [-69.041399947968216, -16.547646040950696],
            [-69.032017916079667, -16.608608964020547],
            [-69.014326903550085, -16.629364065083422],
            [-69.012390851549242, -16.656981933209551],
            [-69.050708889660797, -16.683755073131223],
            [-69.107645984624213, -16.711599923195848],
            [-69.191955022673881, -16.778055004517711],
            [-69.320699882341899, -16.926254932062619],
            [-69.386945918998094, -17.052917894614083],
            [-69.406399813408314, -17.072226949737257],
            [-69.465381925114258, -17.097046100385171],
            [-69.531535760835112, -17.129863933617713],
            [-69.592790877050561, -17.184717957998174],
            [-69.618899834962662, -17.214726847830704],
            [-69.656190922294456, -17.287246068478893],
            [-69.590835881948408, -17.295281966713731],
            [-69.533617825589772, -17.347782017399737],
            [-69.501108949309071, -17.378890948208181],
            [-69.500563957963152, -17.426946075627924],
            [-69.49972677347165, -17.505282001819921],
            [-69.630008874186302, -17.617499927674629],
            [-69.683899817889255, -17.663608944809639],
            [-69.734172964533371, -17.659999864930242],
            [-69.770281868237959, -17.656946002318733],
            [-69.814726909949826, -17.652226823179717],
            [-69.834872981926424, -17.681390984807976],
            [-69.846117807981983, -17.708335953745191],
            [-69.84972688786138, -17.749235953318191],
            [-69.838063972474231, -17.767499952750498],
            [-69.821672993130818, -17.782499871438972],
            [-69.812499838269957, -17.801108870006928],
            [-69.769590864138252, -17.952573045933562],
            [-69.77444599774708, -17.985281914424533],
            [-69.786390880357857, -18.01528191943973],
            [-69.796672961011865, -18.039717849473391],
            [-69.844590960495225, -18.13236403135879],
            [-69.867782010265813, -18.157781985171269],
            [-69.9584819142284, -18.249445975639944],
            [-69.990845951221871, -18.270146091417871],
            [-70.132508832461895, -18.321255081449024],
            [-70.178073025889063, -18.328890995263166],
            [-70.200835760406079, -18.330281888280012],
            [-70.249173022687415, -18.327781901831941],
            [-70.327790916103226, -18.319446099100659],
            [-70.367090977993598, -18.322781928935783],
            [-70.390017830175424, -18.335282028814248],
            [-70.405490173012936, -18.34854572458805],
            [-70.402785496999911, -18.351028077000024],
            [-73.155290673999872, -18.35222222199998],
            [-73.850041073999989, -18.352423161999909],
            [-73.880814125999933, -18.352526179599948],
            [-73.881944443999942, -18.352222221999881],
            [-76.767777777999981, -18.352222221999881],
            [-76.770891476999964, -18.354111888999924],
            [-76.798005572000022, -18.341943665000045],
            [-76.79970957199987, -18.340863164999988],
            [-76.801698071999908, -18.339668164999978],
            [-76.803731571999862, -18.338375664999973],
            [-76.807061571999867, -18.336879665],
            [-76.808184571999988, -18.336123664999974],
            [-76.810111571999897, -18.335258665],
            [-76.812699571999985, -18.333516664999919],
            [-76.819201572, -18.330596664999987],
            [-76.853241571999973, -18.307671664999958],
            [-76.883896571999827, -18.293870164999959],
            [-76.884802572000012, -18.293437664999956],
            [-76.913274571999892, -18.274248664999959],
            [-76.922112571999918, -18.27027466499996],
            [-77.419261572000011, -17.934159664999967],
            [-77.434019571999841, -17.919871665000031],
            [-77.435283571999975, -17.919014665],
            [-77.861807572999936, -17.504932664],
            [-77.974862572999854, -17.347345663999917],
            [-77.987918573, -17.334616664],
            [-78.098091573000033, -17.259327663999983],
            [-78.147639573, -17.210946663999948],
            [-78.161199573, -17.201668664000024],
            [-78.584919572999951, -16.786711663999924],
            [-78.585807072999927, -16.785512663999924],
            [-78.819879573, -16.456047662999936],
            [-78.870909572999835, -16.405784662999963],
            [-78.876811073, -16.397485662999955],
            [-78.926802573999879, -16.326956663000047],
            [-78.931560073999975, -16.322221662999951],
            [-78.934436573999847, -16.319339662999965],
            [-79.273724073999887, -15.839125662999905],
            [-79.274576573999894, -15.837814662999961],
            [-79.28836557399984, -15.808205662999967],
            [-79.290243573999987, -15.805542662999983],
            [-79.381356573999938, -15.609725663],
            [-79.463310573999962, -15.493264661999987],
            [-79.463782573999936, -15.492249661999935],
            [-79.464270573999983, -15.491554661999984],
            [-79.709785574000023, -14.961922661999964],
            [-79.80491857399997, -14.594943661999963],
            [-79.964196573999885, -14.483478661999982],
            [-80.380458574999864, -14.066734661],
            [-80.381215074999943, -14.06573966099991],
            [-80.387680574999933, -14.05653966099996],
            [-80.393385575, -14.050816660999928],
            [-80.72731957499991, -13.569950660999908],
            [-80.969212575000029, -13.039880659999881],
            [-81.112284576000036, -12.47690566],
            [-81.122359576, -12.332872659999964],
            [-81.209425575999944, -11.989893658999947],
            [-81.242145576000041, -11.523738658999875],
            [-81.386756576000039, -11.20504565799996],
            [-81.41636657600003, -11.088198658],
            [-81.43514757600002, -11.046765657999913],
            [-81.454345576000037, -10.970980657999888],
            [-81.456333575999849, -10.966594658],
            [-81.45821657599987, -10.959158657999879],
            [-81.46390357599995, -10.94660865799986],
            [-81.56534457600003, -10.798867657999892],
            [-81.593137575999947, -10.73747165799999],
            [-81.642375575999836, -10.665699657999909],
            [-81.742325576000013, -10.444696657999856],
            [-81.91892657599999, -10.186835657999893],
            [-81.984937575999822, -10.040580656999865],
            [-82.437207576999896, -9.822301656999869],
            [-82.439816577000045, -9.820426656999985],
            [-82.441218576999859, -9.819748656999934],
            [-82.917396577000034, -9.476900656999916],
            [-83.325369577999936, -9.057407655999853],
            [-83.326185577999951, -9.056484655999867],
            [-83.6533625779999, -8.573907655999861],
            [-83.654542578000019, -8.571263655999942],
            [-83.65731457799987, -8.56717065599986],
            [-83.658441577999952, -8.56464565599984],
            [-83.661267578, -8.560473655999971],
            [-83.89822757799999, -8.029408655999859],
            [-83.954199577999987, -7.804121655],
            [-84.047664577999853, -7.665926654999922],
            [-84.049485077999861, -7.66196865499991],
            [-84.04997357799985, -7.660997654999861],
            [-84.050615577999963, -7.660049654999938],
            [-84.060193577999826, -7.638565655000036],
            [-84.06953357799992, -7.624646654999893],
            [-84.070391577999942, -7.623271654999897],
            [-84.073696578000011, -7.615858655000011],
            [-84.074463077999923, -7.614623654999875],
            [-84.075363578, -7.613163154999924],
            [-84.078291577999977, -7.608804654999986],
            [-84.07883457799997, -7.607585655000022],
            [-84.079286577999966, -7.606918654999987],
            [-84.316062579, -7.075699654999838],
            [-84.316315578999962, -7.074681654999893],
            [-84.319001578999888, -7.068653654999849],
            [-84.319637578999846, -7.066094654999858],
            [-84.319982578999969, -7.065320654999852],
            [-84.32019957899999, -7.064448655],
            [-84.321015078999949, -7.062484654999949],
            [-84.321928578999859, -7.060300655],
            [-84.323788078999968, -7.052988654999936],
            [-84.464020578999879, -6.489376653999912],
            [-84.503711578999884, -5.910919653999969],
            [-84.503402578999896, -5.908054653999883],
            [-84.503662578999922, -5.904291654000019],
            [-84.503115578999967, -5.899201653999953],
            [-84.504452078999975, -5.87895715400002],
            [-84.504478578999937, -5.877658653999873],
            [-84.501233579000029, -5.847464653999879],
            [-84.50306057899985, -5.821078653000015],
            [-84.502945579000027, -5.820006652999865],
            [-84.503042578999867, -5.818602652999871],
            [-84.499644579, -5.786938652999936],
            [-84.517958578999895, -5.71363865299999],
            [-84.524628578999966, -5.617650653],
            [-84.631355578999973, -5.191075652999899],
            [-84.67220157899996, -4.612193151999932],
            [-84.672216578999979, -4.611515651999909],
            [-84.671872579000024, -4.608239651999924],
            [-84.672169578999984, -4.604058651999935],
            [-84.671940578999966, -4.601870652],
            [-84.672138579, -4.599077652],
            [-84.612042579000018, -4.022738651999987],
            [-84.60526157899983, -3.999070651999943],
            [-84.588085578999852, -3.83279865199988],
            [-84.584449579000022, -3.82008365199998],
            [-84.584002578999986, -3.81574865199994],
            [-84.545109579000012, -3.679606651],
            [-84.543232578999863, -3.661324651],
            [-84.53819857900001, -3.643684651],
            [-84.537114579000018, -3.633117651],
            [-84.53630457899996, -3.630277650999858],
            [-84.536083578999978, -3.628123650999981],
            [-84.469150578999887, -3.39276665099986],
            [-80.34264300435558, -3.39276666367887],
            [-80.340427075914562, -3.380518032595873],
            [-80.259172907943963, -3.417426866442867],
            [-80.234999834394529, -3.497217891030147],
            [-80.212781923585339, -3.595835843668652],
            [-80.206117807628061, -3.636663926512369],
            [-80.207781950689082, -3.717217871928739],
            [-80.211399915385812, -3.739164041436283],
            [-80.176673019880838, -3.859164061496941],
            [-80.153335789718255, -3.884226790251873],
            [-80.175290844043218, -3.903055059407478],
            [-80.29223582802598, -3.996108937064506],
            [-80.318964040946611, -4.011663905382889],
            [-80.345699965217989, -3.998263924376928],
            [-80.389172873796923, -3.9790269536213],
            [-80.420290857060763, -3.97805498812636],
            [-80.465426902872537, -3.985282032702571],
            [-80.501681986968833, -4.050282015629051],
            [-80.501217797169602, -4.055346026265653],
            [-80.483608927291471, -4.114445987530502],
            [-80.480763941707636, -4.174164030337224],
            [-80.464582007030089, -4.207499865187543],
            [-80.445563971585926, -4.221181981055111],
            [-80.410563993674657, -4.209864064803796],
            [-80.382926847171234, -4.196663908370112],
            [-80.340281902990228, -4.199517946409259],
            [-80.382926847171234, -4.263473041283035],
            [-80.399172987227502, -4.281808957444795],
            [-80.431117929060889, -4.315555002637524],
            [-80.479999847412159, -4.388473034239937],
            [-80.481254953597102, -4.416455012240931],
            [-80.463763933277789, -4.441808928312966],
            [-80.40972680918324, -4.471663926402769],
            [-80.390563934451848, -4.478608836117289],
            [-80.358617819151902, -4.465555027713464],
            [-80.334173004300823, -4.452499878205089],
            [-80.262217885737954, -4.397499841070925],
            [-80.226390949257052, -4.364445973444305],
            [-80.186535837736756, -4.321255032088899],
            [-80.174581902670781, -4.298545941752252],
            [-80.134599889135984, -4.284490998830307],
            [-80.077926824123267, -4.300135821150974],
            [-80.058763949392073, -4.319582004210432],
            [-80.025145979680133, -4.354999903815227],
            [-79.997790968038316, -4.373055026219774],
            [-79.81563578299685, -4.478817880783041],
            [-79.783763931359402, -4.481946007056877],
            [-79.705281992413603, -4.457081929407735],
            [-79.668063995277777, -4.434308968968821],
            [-79.643199917628635, -4.435417894761883],
            [-79.621390876057376, -4.447499905308845],
            [-79.539172955217765, -4.520835858604769],
            [-79.51972693979647, -4.581181873599149],
            [-79.515282016530222, -4.620417897749107],
            [-79.451672926620404, -4.766390921252139],
            [-79.445035800391537, -4.797399939774394],
            [-79.365573011133392, -4.886108974089254],
            [-79.285708896349973, -4.964582028217549],
            [-79.231917864933394, -4.963854981934105],
            [-79.203608825069466, -4.962499963462989],
            [-79.183626870757564, -4.963890856479836],
            [-79.100273034396508, -4.975055048626501],
            [-79.063208929003309, -5.00030888477437],
            [-79.036663944486776, -4.995555004556095],
            [-79.011399882417123, -4.972217942031776],
            [-78.928617859492221, -4.86833581890825],
            [-78.918481959229894, -4.782635887841835],
            [-78.918399984216734, -4.741046057635501],
            [-78.906108929003921, -4.716663939420414],
            [-78.876108923988795, -4.677217864776225],
            [-78.85930890776919, -4.661526941987944],
            [-78.823763938511917, -4.635973033705554],
            [-78.790846025355108, -4.614163992134394],
            [-78.767226827969012, -4.603890963935797],
            [-78.723482010450454, -4.588890877609231],
            [-78.709026915469821, -4.584790953478461],
            [-78.683617846474561, -4.574446008550481],
            [-78.666808945437651, -4.55486403865919],
            [-78.650282011624427, -4.473608863221045],
            [-78.652846035812871, -4.417635854763631],
            [-78.670145948739332, -4.365135804077539],
            [-78.672499922433616, -4.340281952350367],
            [-78.672072948284693, -4.307090956787363],
            [-78.659472936119954, -4.300672933507954],
            [-78.641535830912488, -4.278472959971594],
            [-78.563608941596499, -4.095555021739017],
            [-78.558063977354436, -4.073054976068136],
            [-78.555554938450854, -4.04694601815585],
            [-78.555426862969966, -4.010135884429744],
            [-78.553617880621516, -3.978608864289782],
            [-78.540846039441305, -3.931945970991364],
            [-78.531681937036041, -3.912781922793684],
            [-78.466672901653965, -3.832499886316853],
            [-78.420990858667892, -3.785854930291336],
            [-78.414173018605965, -3.776526877859439],
            [-78.39945506714102, -3.746108951150717],
            [-78.388626821674507, -3.711390940462962],
            [-78.372508924737303, -3.633890857658002],
            [-78.367090862509542, -3.572845959574792],
            [-78.370699942388939, -3.539717828285845],
            [-78.36791782107926, -3.518335928501685],
            [-78.352235783108398, -3.456664064059296],
            [-78.346117831963511, -3.434999861775367],
            [-78.32945494368019, -3.417217821777029],
            [-78.30139987548344, -3.407781978069934],
            [-78.279445994624865, -3.409999997294477],
            [-78.261399924675828, -3.424717948759437],
            [-78.252926826370071, -3.451808930450369],
            [-78.255846075616233, -3.516946041313233],
            [-78.246946003161582, -3.51986394945483],
            [-78.181399855422598, -3.472217858911193],
            [-78.170563898605167, -3.451946058386667],
            [-78.181190978394937, -3.413126950101841],
            [-78.203472926944556, -3.392426834323814],
            [-78.215708829234245, -3.360554982686281],
            [-78.200426775684178, -3.334308896837797],
            [-78.175846006363116, -3.314163998327672],
            [-78.141399904615071, -3.294163939104877],
            [-78.116390987678699, -3.27597302986851],
            [-78.067226934465907, -3.215282015738467],
            [-78.034455034062717, -3.15618188683554],
            [-77.925381998288259, -3.039963949136222],
            [-77.859590931337806, -2.985835797572818],
            [-77.810245996653862, -2.968155010965134],
            [-77.794445941485918, -2.963335919540015],
            [-77.43972681559535, -2.841390962661094],
            [-77.224164046612174, -2.767217824873669],
            [-77.043335884485089, -2.70471782839526],
            [-76.904446072099262, -2.656664042080081],
            [-76.660626901604388, -2.572135901080856],
            [-76.403899935459435, -2.380282015847882],
            [-76.074172962698725, -2.13110882086572],
            [-75.977508831695985, -2.016390908562826],
            [-75.781399957123028, -1.781946058605584],
            [-75.719726919214253, -1.708055055679836],
            [-75.627281903005581, -1.596372901077643],
            [-75.588182000963542, -1.555708935898636],
            [-75.56394606314015, -1.539954981198306],
            [-75.5536178820185, -1.513890950287248],
            [-75.483608873740366, -1.241945968332473],
            [-75.46167293015489, -1.153608922881588],
            [-75.444173025018159, -1.085555077343614],
            [-75.431946007545889, -1.03805499955368],
            [-75.402235848743203, -0.922781869982984],
            [-75.376955022867122, -0.950835932351524],
            [-75.359590904562396, -0.967781961324746],
            [-75.330426910572214, -0.979717959118005],
            [-75.216845919619175, -0.96936396173453],
            [-75.263335810435677, -0.814717847715585],
            [-75.292781939287465, -0.730554990057726],
            [-75.283426897127242, -0.656217902243753],
            [-75.277235855786415, -0.650282006036392],
            [-75.266964001054191, -0.646035901513912],
            [-75.249026895846725, -0.629581890258464],
            [-75.244381980370036, -0.56097299509058],
            [-75.26250885186596, -0.523054941399621],
            [-75.339799890005338, -0.464655036689891],
            [-75.363890988541328, -0.466664011248596],
            [-75.38945495510751, -0.446946086887493],
            [-75.413346061433316, -0.419582022790266],
            [-75.434445993993876, -0.384163955547393],
            [-75.448617948284408, -0.346317818585817],
            [-75.459163891251222, -0.29499995152689],
            [-75.464735845221696, -0.273608831649241],
            [-75.481117939747691, -0.232364000578585],
            [-75.571190877351086, -0.180135858832301],
            [-75.624499949333881, -0.172026870401609],
            [-75.615817806362486, -0.106517938312848],
            [-75.519026773345246, -0.116872941524718],
            [-75.479872891846554, -0.143755046187977],
            [-75.425008809182401, -0.164308981574209],
            [-75.349726913239891, -0.151255005532221],
            [-75.305281871527853, -0.134717845797027],
            [-75.285845914390364, -0.119717927108454],
            [-75.259146032302709, -0.122481943507324],
            [-75.225908936271992, -0.095973001174443],
            [-75.216672917137146, -0.068335854671105],
            [-75.205008828283809, -0.03687303990958],
            [-75.137017847019791, -0.045345970577245],
            [-75.021391001134106, -0.123054930409964],
            [-74.960554980078655, -0.170555008199898],
            [-74.93806398686317, -0.198890870153974],
            [-74.860281936834525, -0.232499955048482],
            [-74.841126773454249, -0.225281962927866],
            [-74.812646072212857, -0.205690940581164],
            [-74.791945956434915, -0.200281930808814],
            [-74.772372871361057, -0.20791784462304],
            [-74.749172936772936, -0.235554991126378],
            [-74.733608915999156, -0.260972944938857],
            [-74.721117868576101, -0.28971784376904],
            [-74.716946027715949, -0.311664013276584],
            [-74.715835760818322, -0.333608841679577],
            [-74.708763949089416, -0.361663909876512],
            [-74.632917951061586, -0.406526873281976],
            [-74.60750888206644, -0.383608905917583],
            [-74.556946056847778, -0.41778192525932],
            [-74.393890882263435, -0.543335794379558],
            [-74.376108842265097, -0.568055032741384],
            [-74.370281910799321, -0.577554914188923],
            [-74.358063945782646, -0.633055015667949],
            [-74.338899897584866, -0.706390968963788],
            [-74.316117884690271, -0.781663980088879],
            [-74.290282009184381, -0.809717874819341],
            [-74.247790956746002, -0.853608872729879],
            [-74.264172883633989, -0.898890931295256],
            [-74.28279093465747, -0.930281996965306],
            [-74.255281863634821, -0.986664042298912],
            [-74.240699866639801, -1.012917839498428],
            [-74.215426919752616, -1.034864009005886],
            [-74.077790872447338, -1.072781895058839],
            [-73.868055034930023, -1.208608960015766],
            [-73.840981990511921, -1.265135844636589],
            [-73.752235908184929, -1.28971795506213],
            [-73.713055036958025, -1.2791639654683],
            [-73.653617787908445, -1.284446073226164],
            [-73.555699891825157, -1.375281931658634],
            [-73.54749987592561, -1.436663949888484],
            [-73.542781870253066, -1.468055015558534],
            [-73.520008909813981, -1.493608923840839],
            [-73.476963981212464, -1.552499840439935],
            [-73.447154915952098, -1.610346036624321],
            [-73.476963981212464, -1.636664039202273],
            [-73.486955042187446, -1.654446079200611],
            [-73.507508809935501, -1.74833580024459],
            [-73.490573006884176, -1.766945972278933],
            [-73.416399869096921, -1.816663901655247],
            [-73.296946013848668, -1.873546011333715],
            [-73.238890940636423, -1.845554980877225],
            [-73.197017802102096, -1.806455078835171],
            [-73.132781913471092, -1.849164060756536],
            [-73.090426815502724, -1.923608939845607],
            [-73.058208958901218, -2.145555031028934],
            [-73.077790928792524, -2.200281985756902],
            [-73.101255060969237, -2.23194596619507],
            [-73.1317460778738, -2.246255048422],
            [-73.115290893151865, -2.328754936485126],
            [-73.104735897729654, -2.34694601335957],
            [-73.073899881689499, -2.371390995848714],
            [-73.003063914841647, -2.410554935631211],
            [-72.975008846644727, -2.401663915632042],
            [-72.934581921688363, -2.399999940208986],
            [-72.902508904374002, -2.45360891668831],
            [-72.88195496898777, -2.506390934598016],
            [-72.814735793370346, -2.489999955254518],
            [-72.683335882044929, -2.439999891016598],
            [-72.590146049918189, -2.407217932329701],
            [-72.450835801267971, -2.441390951671451],
            [-72.327781918595718, -2.483055045540098],
            [-72.299454941459061, -2.491391015909457],
            [-72.227508875351589, -2.498890975253687],
            [-72.07000889093149, -2.407781866776929],
            [-71.982226895110415, -2.369446059030452],
            [-71.931399872302876, -2.379008972390125],
            [-71.889799816174673, -2.377782029399896],
            [-71.871399862272455, -2.32028200581756],
            [-71.808481944100635, -2.23083586693545],
            [-71.721955054464587, -2.159164056700646],
            [-71.693199929712421, -2.147917889540722],
            [-71.683208868737438, -2.166526888108592],
            [-71.68417295524344, -2.196108971430277],
            [-71.674317848738355, -2.224999883014178],
            [-71.576672867423184, -2.236663971867614],
            [-71.520145982802461, -2.234581907113053],
            [-71.412226799822292, -2.281255026333369],
            [-71.361681911876246, -2.34694601335957],
            [-71.188064030518859, -2.360555039031283],
            [-71.101117878084835, -2.311946035448202],
            [-70.976390967534229, -2.278335777087207],
            [-70.858964024117512, -2.225346055616313],
            [-70.657645964344738, -2.358191007053108],
            [-70.64972690984041, -2.401255046394013],
            [-70.582372953219647, -2.48389088892705],
            [-70.564454958751412, -2.493335785089641],
            [-70.37774605333999, -2.511673042355866],
            [-70.355808936287843, -2.498172981425654],
            [-70.33564593286701, -2.492399861778438],
            [-70.295390836926117, -2.501281996960188],
            [-70.218826845070168, -2.570346029471708],
            [-70.20886394729007, -2.609426820774459],
            [-70.194590907246777, -2.644017929447799],
            [-70.120681967048398, -2.701663965783752],
            [-70.098481993511939, -2.69833584729966],
            [-70.067508849535415, -2.755555077124782],
            [-70.412226856167194, -3.294717815268299],
            [-70.44723588653406, -3.349445943462655],
            [-70.565846019406251, -3.533608929596539],
            [-70.627499946575824, -3.629163967167955],
            [-70.672226787873313, -3.69778191479125],
            [-70.724163909940557, -3.779717868407232],
            [-70.607726869291696, -3.829481898251075],
            [-70.52799082998925, -3.859235810588402],
            [-70.500835810557874, -3.859308900784256],
            [-70.479446031784619, -3.849999959091576],
            [-70.423899829838064, -3.81610890697344],
            [-70.406535879171315, -3.802499881301728],
            [-70.362226791929174, -3.787781929836783],
            [-70.341673024181034, -3.78999994906124],
            [-70.323064025613263, -3.799164051466619],
            [-70.234454903584577, -3.871254956861321],
            [-70.181881930340637, -3.937426897493168],
            [-70.1626550178689, -3.974863997578566],
            [-70.152508891684789, -4.005835800450626],
            [-70.144308875785327, -4.031808971530992],
            [-70.13612679715871, -4.050835891792389],
            [-70.123072988754785, -4.072363971968329],
            [-70.040846015459778, -4.140554945442702],
            [-70.021117865176791, -4.153890888708219],
            [-69.988617873713508, -4.175973012685574],
            [-69.956681984335432, -4.209445975472192],
            [-69.956926903547014, -4.236872903843476],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { ISO_A3: "CHN", Country: "Paracel Islands" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [112.314027605000206, 13.29789636400011],
            [112.239892644000406, 13.247067710000238],
            [112.054990745000168, 13.353753386],
            [111.766096426000217, 13.521172141000193],
            [111.690902644000175, 13.580842591000135],
            [111.689055047000181, 13.582308288000149],
            [111.687146515000165, 13.583824078000191],
            [111.359794622000209, 13.843346010000175],
            [111.310750548000215, 13.88873459000007],
            [111.310097247000044, 13.889339134000181],
            [111.19812417900016, 13.992925474000174],
            [110.842197750000253, 14.320502827000155],
            [110.518253935000189, 14.670066020000036],
            [110.49263846700012, 14.697567512000035],
            [110.429501880000203, 14.765189513000166],
            [110.324992587999986, 14.901640621000183],
            [110.324196428000221, 14.902680117000216],
            [110.306086471000242, 14.934787898000195],
            [110.244572105000316, 15.222981343000072],
            [110.211211534000256, 15.381428579000215],
            [110.210811388000138, 15.383329963000108],
            [110.127256328000215, 15.782866547000083],
            [110.0618123980002, 16.092652009000119],
            [110.001280026000188, 16.379309626000065],
            [109.990378723000163, 16.430418049000082],
            [109.950290398000107, 16.646496122000087],
            [109.950928580000152, 16.701448136000238],
            [110.534024277000299, 17.246801735000247],
            [110.560897964000105, 17.265233844000079],
            [110.63585619100013, 17.316734458000099],
            [110.67960697500024, 17.346734970000114],
            [110.700684552000098, 17.361188153000171],
            [110.72415977500026, 17.379149659000234],
            [110.748142495000195, 17.397499465000067],
            [110.975028369000142, 17.556104830000038],
            [111.000707393000084, 17.575290633000066],
            [111.065320340000198, 17.608818661000043],
            [111.101360849000116, 17.627520287000038],
            [111.121532503000253, 17.638089269000204],
            [111.150333324000144, 17.653179521000141],
            [111.180256842000176, 17.668813609000154],
            [111.342344226000193, 17.81874325400014],
            [111.644731766000234, 18.095541592000131],
            [111.737469811000153, 18.180308072000173],
            [111.814820204000227, 18.251024943000061],
            [111.820900726000048, 18.256567451000024],
            [111.984023773000104, 18.391985909000027],
            [112.117616369000189, 18.502043665000087],
            [112.142750447000026, 18.51238379300014],
            [112.165135901000212, 18.521593141000096],
            [112.20500010600017, 18.537993226000197],
            [112.251948244000118, 18.557307632000061],
            [112.265488677, 18.562840003000133],
            [112.48222805200021, 18.651436095000122],
            [112.738524313000198, 18.755624822000186],
            [113.112419111000236, 18.874674977999973],
            [113.355687079000177, 18.944830196000225],
            [113.882374319000178, 19.100117237000063],
            [114.073256228000218, 19.118174266000096],
            [114.324549306000222, 19.027919940000061],
            [114.600525607000122, 18.785526369000024],
            [114.646751607000169, 18.744807369000114],
            [115.447483608000198, 18.035608369000215],
            [115.475284608000038, 18.010734369000033],
            [115.768374608000158, 17.748402368000143],
            [116.006309608000151, 17.534741368000084],
            [116.105702608000115, 17.437603368000168],
            [116.133428608000031, 17.406528368000068],
            [116.16595860800021, 17.251349368000206],
            [116.195547608000084, 17.042916368000135],
            [116.211426608000124, 16.833115368000222],
            [116.213580608000143, 16.622783367000125],
            [116.213500608000118, 16.621318367000072],
            [116.213512167086947, 16.619362633742583],
            [116.213512608000201, 16.619288033666805],
            [116.213509256724365, 16.619226926602664],
            [116.201982608000321, 16.409050367000134],
            [116.176864608000187, 16.20014236700024],
            [116.138304608000226, 15.993178367000041],
            [116.086499608000054, 15.788968367000109],
            [116.021692608000194, 15.588306366000097],
            [115.944180608000352, 15.391969366000239],
            [115.854299608000133, 15.200715366000111],
            [115.752435608000155, 15.015277366000191],
            [115.639010608000149, 14.836368366000102],
            [115.514492608000182, 14.664671366000107],
            [115.480290608000018, 14.623198366000167],
            [114.799897607000048, 14.422377365000102],
            [114.359206607000033, 14.28570236500002],
            [113.713634606000028, 14.084056365000038],
            [113.648574606000011, 14.063590365000039],
            [112.948820605000037, 13.691903365000087],
            [112.681575605000035, 13.549240365000173],
            [112.644457605000184, 13.523662365000177],
            [112.353527605000068, 13.324905364000188],
            [112.314027605000206, 13.29789636400011],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        ISO_A3: "AUS",
        Country: "Area of overlap Australia/Indonesia",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [126.00012814100478, -10.460105412999781],
              [126.000016259000176, -10.465833728999939],
              [126.001225000000204, -10.465261109999915],
              [126.531079382954545, -11.331152030027965],
              [126.519013002333509, -11.33186177966661],
              [126.517891667000299, -11.331927777999908],
              [126.497615628000517, -11.338686457499875],
              [126.467891667000572, -11.348594444000014],
              [126.346377760000223, -11.386567539999817],
              [126.201225000000363, -11.431927777999761],
              [126.001225000000204, -11.515261111000029],
              [125.788617856000087, -11.600315780499955],
              [125.751238889000319, -11.615269443999864],
              [125.574448211000458, -11.685985714999902],
              [125.417905556000278, -11.748602777999849],
              [125.334572222000361, -11.781936110999879],
              [125.082662363000395, -11.705267891999881],
              [124.951238889000592, -11.665269443999762],
              [124.567905556000369, -11.565269443999853],
              [124.451238889000251, -11.515269443999841],
              [124.434572222000384, -11.515269443999841],
              [123.96790555600046, -11.565269443999853],
              [123.955565399000307, -11.559099365999828],
              [123.934572222000156, -11.548602777999974],
              [123.707108217000382, -11.535222541999985],
              [123.367905556000437, -11.515269443999841],
              [123.347478696000167, -11.520376158999852],
              [123.301238889000274, -11.531936110999936],
              [123.279035499285897, -11.537486720714185],
              [123.434583330000464, -11.381938889999901],
              [123.667916670000409, -11.465272219999747],
              [124.001250000000596, -11.431938889999969],
              [124.167916670000295, -11.415272219999849],
              [124.567905560000554, -11.115269439999793],
              [125.31790556000044, -11.015269439999713],
              [125.684572220000518, -10.615269439999793],
              [125.999344913857499, -10.466151765428435],
              [126.000016073000097, -10.465833816999861],
              [126.00012814100478, -10.460105412999781],
            ],
          ],
          [
            [
              [132.00736923000045, -9.485284104999891],
              [131.951183333000245, -9.51525],
              [131.903945034500225, -9.53414531899989],
              [131.867850000000203, -9.548583332999897],
              [131.717850000000226, -9.598583332999965],
              [131.658318901000143, -9.618427032999875],
              [131.51785000000018, -9.66525],
              [131.473297967000462, -9.694951354999773],
              [131.467850000000453, -9.698583332999817],
              [131.027845369000147, -9.765250701999946],
              [130.917849895000245, -9.781916649499976],
              [130.717850000000226, -9.748583332999942],
              [130.419048609000242, -9.70012996249983],
              [130.101188889000241, -9.648586110999986],
              [129.501188889000218, -9.748586111],
              [129.160622401000296, -9.912997518499992],
              [129.0178555560002, -9.981919444000027],
              [128.301188889000173, -10.431919443999789],
              [128.263980260000153, -10.444322320999959],
              [128.230945525000266, -10.455333898999982],
              [128.226511082000229, -10.456812046999801],
              [128.202825730225072, -10.464707163947935],
              [127.97967255891308, -9.499131877581419],
              [127.979660680866687, -9.499080481726025],
              [127.97966624150024, -9.499084371999913],
              [127.979104145000179, -9.49644822800002],
              [127.966580208666869, -9.441217795333216],
              [127.97799962500028, -9.432653564999896],
              [128.001189009500195, -9.415262179499905],
              [128.013381690000301, -9.415240115999893],
              [130.167875691000404, -9.415231546999905],
              [133.23452309700042, -8.898551548999762],
              [133.384511949000171, -8.881881074999853],
              [132.767838889000132, -9.098588888999871],
              [132.688303276500164, -9.147533880999788],
              [132.551172222000332, -9.231922221999866],
              [132.501172222000264, -9.265255556],
              [132.334505556000408, -9.331922221999889],
              [132.201172222000423, -9.381922221999787],
              [132.00736923000045, -9.485284104999891],
            ],
          ],
        ],
      },
    },
  ],
};

export { globalTerritories };
