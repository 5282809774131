import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addFolder,
  updateFolder,
  deleteFolder,
  addWorkStation,
  updateWorkStation,
  deleteWorkStation,
  fetchFolderWork,
  fetchUsers,
} from "./Common.services";
import { deleteTabData, updateTabData } from "../layout/Header/Header.store";
import { AlertColor } from "@mui/material";
import { IMapState } from "../pages/Theia/WorkStations";

export type AddFolderType = {
  userId: string;
  folderName: string;
};

export type UpdateFolderType = {
  userId: string;
  folderId: string | undefined;
  newFolderName: string;
};

export type DeleteFolderType = {
  userId: string;
  folderId: string | undefined;
};

export type AddWorkStationType = {
  userId: string;
  folderId: string;
  workStationName: string;
};

export type UpdateWorkStationType = {
  userId: string;
  folderId: string | null;
  workStationId: string;
  newWorkStationName?: string;
  newWorkStationMode?: string;
  updateWorkStationTime?: boolean;
  tabId?: string;
  isTabId?: boolean;
};

export type DeleteWorkStationType = {
  userId: string;
  folderId: string;
  workStationId: string;
  tabId?: string;
};

// interface MapStateType {
//   mapLatLng: {
//     lat: number;
//     lng: number;
//   };
//   showGlobeView: boolean;
//   zoomLevel: number;
//   mapFilters: ICurrentEventTypeFilterOptions
// }

interface WorkStation {
  id: string;
  name: string;
  createdAt: Date | any;
  tabId: string;
  mapState: IMapState;
  mode: string;
  thumbnail?: string;
  downloadURL?:string
}

interface Folder {
  id: string;
  name: string;
  workStations: Record<string, WorkStation>;
}

export type AllProjectsType = {
  [key: string]: Folder;
};

export type UserType = {
  [key: string]: {
    email: string;
    firstName: string;
    isNewUser: boolean;
    lastName: string;
    role: string;
    userId: string;
  };
};

//FOLDER
export const addFolderData = createAsyncThunk(
  "homePanel/addFolderData",
  async (folderData: AddFolderType) => {
    try {
      const data = await addFolder(folderData);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateFolderData = createAsyncThunk(
  "homePanel/updateFolderData",
  async (folderData: UpdateFolderType) => {
    try {
      const data = await updateFolder(folderData);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteFolderData = createAsyncThunk(
  "homePanel/deleteFolderData",
  async (folderData: DeleteFolderType) => {
    try {
      const data = await deleteFolder(folderData);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

//WORK STATION
export const addWorkStationData = createAsyncThunk(
  "theia/addWorkStationData",
  async (workStationsData: AddWorkStationType) => {
    try {
      const data = await addWorkStation(workStationsData);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateWorkStationData = createAsyncThunk(
  "theia/updateWorkStationData",
  async (
    workStationData: UpdateWorkStationType,
    { dispatch, getState }: any
  ) => {
    try {
      const data = await updateWorkStation(workStationData);
      if (workStationData.tabId && data.updateWorkStation.newWorkStationName) {
        const userId = getState()?.theia?.user?.uid;
        const updateTab = {
          userId,
          tabId: workStationData.tabId,
          newWorkStationName: data.updateWorkStation.newWorkStationName,
        };
        dispatch(updateTabData(updateTab));
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteWorkStationData = createAsyncThunk(
  "theia/deleteWorkStationData",
  async (
    workStationData: DeleteWorkStationType,
    { dispatch, getState }: any
  ) => {
    try {
      const data = await deleteWorkStation(workStationData);

      if (workStationData.tabId) {
        const userId = getState()?.theia?.user?.uid;
        const deleteTab = {
          userId,
          tabId: workStationData.tabId,
        };
        dispatch(deleteTabData(deleteTab));
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
);

//FETCH ALL DATA
export const fetchFolderWorkData = createAsyncThunk(
  "recent/fetchFolderWorkData",
  async (userId: string) => {
    try {
      const data = await fetchFolderWork(userId);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

//FETCH ALL USERS
export const fetchUsersData = createAsyncThunk(
  "recent/fetchUsersData",
  async (userId: string) => {
    try {
      const data = await fetchUsers(userId);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const commonSlice = createSlice({
  name: "common",
  initialState: {
    selectedFolderId: "",
    allProjects: {} as AllProjectsType,
    users: {} as UserType,
    loading: false,
    isWorkLoading: false,
    error: "",
    isAllProjectsFetch: false,
    isUserFetch: false,
    isUpdateMapState: false,
    deletedTabsIds: [],
    isTabsDeleted: false,
  },
  reducers: {
    setSelectedFolderId: (state, action) => {
      state.selectedFolderId = action.payload;
    },
    setIsUpdateMapState: (state, action) => {
      state.isUpdateMapState = action.payload;
    },
    setIsTabsDeleted: (state, action) => {
      state.isTabsDeleted = action.payload;
    },
    updateAllProjects: (state, action) => {
      state.allProjects = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //ADD FOLDER
      .addCase(addFolderData.pending, (state) => {
        state.loading = true;
      })
      .addCase(addFolderData.fulfilled, (state, action: PayloadAction<any>) => {
        const addFolderData = action.payload.addFolderData;
        state.loading = false;
        state.selectedFolderId = addFolderData.collectionId;
        state.allProjects = {
          ...state.allProjects,
          [addFolderData.collectionId]: {
            id: addFolderData.id,
            name: addFolderData.name,
            workStations: {},
          },
        };
      })
      .addCase(addFolderData.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //UPDATE FOLDER
      .addCase(updateFolderData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateFolderData.fulfilled,
        (state, action: PayloadAction<any>) => {
          const folder = state.allProjects;
          const updateFolder = action.payload.updateFolderData;
          state.loading = false;
          if (folder[updateFolder.folderId]) {
            folder[updateFolder.folderId].name = updateFolder.newFolderName;
          }
        }
      )
      .addCase(
        updateFolderData.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      //DELETE FOLDER
      .addCase(deleteFolderData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        deleteFolderData.fulfilled,
        (state, action: PayloadAction<any>) => {
          const folder = state.allProjects;
          const deleteFolderId = action.payload.folderId;
          state.loading = false;

          if (folder[deleteFolderId]) {
            delete folder[deleteFolderId];
          }

          // delete all tabs in the folder
          const deletedTabIds = action.payload.deletedTabIds;
          if (deletedTabIds.length > 0) {
            state.isTabsDeleted = true;
            state.deletedTabsIds = deletedTabIds;
          }
        }
      )
      .addCase(
        deleteFolderData.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      //ADD WORK-STATION
      .addCase(addWorkStationData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        addWorkStationData.fulfilled,
        (state, action: PayloadAction<any>) => {
          const addWorkStationData = action.payload.addWorkStation;
          state.loading = false;
          state.allProjects = {
            ...state.allProjects,
            [addWorkStationData.folderId]: {
              ...state.allProjects[addWorkStationData.folderId],
              workStations: {
                ...state.allProjects[addWorkStationData.folderId]?.workStations,
                [addWorkStationData.collectionId]: {
                  id: addWorkStationData.id,
                  name: addWorkStationData.name,
                  createdAt: addWorkStationData.createdAt,
                  mapState: addWorkStationData.mapState,
                  mode: addWorkStationData.mode,
                },
              },
            },
          };
        }
      )
      .addCase(
        addWorkStationData.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      //UPDATE WORK-STATION
      .addCase(updateWorkStationData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateWorkStationData.fulfilled,
        (state, action: PayloadAction<any>) => {
          const updateWorkStation = action.payload.updateWorkStation;
          const workStation = state.allProjects;
          state.loading = false;
          if (
            workStation[updateWorkStation.folderId] &&
            workStation[updateWorkStation.folderId].workStations[
              updateWorkStation.workStationId
            ]
          ) {
            if (updateWorkStation.currentDateTime) {
              workStation[updateWorkStation.folderId].workStations[
                updateWorkStation.workStationId
              ].createdAt = updateWorkStation.currentDateTime;
              workStation[updateWorkStation.folderId].workStations[
                updateWorkStation.workStationId
              ].mapState = updateWorkStation.mapState;
              state.isUpdateMapState = true;
            } else if (updateWorkStation.isTabId) {
              workStation[updateWorkStation.folderId].workStations[
                updateWorkStation.workStationId
              ].tabId = updateWorkStation.tabId;
            } else {
              workStation[updateWorkStation.folderId].workStations[
                updateWorkStation.workStationId
              ].name = updateWorkStation.newWorkStationName;
              workStation[updateWorkStation.folderId].workStations[
                updateWorkStation.workStationId
              ].mode = updateWorkStation.newWorkStationMode;
            }
          }
        }
      )
      .addCase(
        updateWorkStationData.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      //DELETE WORK-STATION
      .addCase(deleteWorkStationData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        deleteWorkStationData.fulfilled,
        (state, action: PayloadAction<any>) => {
          const workStation = state.allProjects;
          const folderId = action.payload.folderId;
          const workStationId = action.payload.deleteWorkStationId;
          state.loading = false;

          if (
            workStation[folderId] &&
            workStation[folderId].workStations[workStationId]
          ) {
            delete workStation[folderId].workStations[workStationId];
          }
        }
      )
      .addCase(
        deleteWorkStationData.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      //FETCH ALL DATA
      .addCase(fetchFolderWorkData.pending, (state) => {
        state.isWorkLoading = true;
      })
      .addCase(
        fetchFolderWorkData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.allProjects = action.payload;
          const folders = Object.keys(action.payload);
          if (folders.length) {
            state.selectedFolderId = folders[0];
          }
          state.isAllProjectsFetch = true;
          state.isWorkLoading = false;
        }
      )
      .addCase(
        fetchFolderWorkData.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
          state.isWorkLoading = false;
        }
      )
      //FETCH ALL USERS
      .addCase(fetchUsersData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchUsersData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.users = action.payload;
          state.isUserFetch = true;
        }
      )
      .addCase(fetchUsersData.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setSelectedFolderId,
  setIsUpdateMapState,
  setIsTabsDeleted,
  updateAllProjects,
} = commonSlice.actions;

export const commonReducer = commonSlice.reducer;
