export const getColorUpdatedShipIcon = (type: any, color: any) => {
  if (type === "light") {
    return (
      "data:image/svg+xml;base64," +
      btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="${color}" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
  <path d="M8.99453 16.1988L8.70042 15.8976L9.05429 15.5128C9.48062 15.0416 9.67182 14.6509 9.7621 14.0685C9.88313 13.2365 9.60721 12.4076 9.01115 11.7972L8.70136 11.4799L9.00255 11.1858L9.30373 10.8917L9.58999 11.1849C10.2409 11.8515 10.5992 12.7647 10.5839 13.7211C10.5685 14.6775 10.2862 15.3846 9.63857 16.1112L9.28863 16.5L8.99453 16.1988Z" fill="white"/>
  <path d="M5.00742 16.1988L5.30153 15.8976L4.94767 15.5128C4.52133 15.0416 4.33014 14.6509 4.23986 14.0685C4.11883 13.2365 4.39475 12.4076 4.9908 11.7972L5.30059 11.4799L4.99941 11.1858L4.69822 10.8917L4.41196 11.1849C3.76101 11.8515 3.40274 12.7647 3.41808 13.7211C3.43342 14.6775 3.7157 15.3846 4.36338 16.1112L4.71332 16.5L5.00742 16.1988Z" fill="white"/>
  <circle cx="6.99951" cy="13.75" r="2" fill="white"/>
  </svg>
  `)
    );
  }

  if (type === "AIS") {
    return (
      "data:image/svg+xml;base64," +
      btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="${color}" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
  </svg>
  
  `)
    );
  }

  if (type === "unattributed") {
    return (
      "data:image/svg+xml;base64," +
      btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="${color}" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
  <circle cx="6.97607" cy="13" r="2.5" stroke="#111326" stroke-width="1.5"/>
  <path d="M9.31696 17.9748C9.54407 18.3212 10.009 18.4179 10.3554 18.1908C10.7018 17.9637 10.7985 17.4987 10.5714 17.1523L9.31696 17.9748ZM7.6721 15.4659L9.31696 17.9748L10.5714 17.1523L8.92653 14.6435L7.6721 15.4659Z" fill="#111326"/>
  </svg>
  
                `)
    );
  }

  if (type === "dark") {
    return (
      "data:image/svg+xml;base64," +
      btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="${color}" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
          <path d="M8.99453 16.1988L8.70042 15.8976L9.05429 15.5128C9.48062 15.0416 9.67182 14.6509 9.7621 14.0685C9.88313 13.2365 9.60721 12.4076 9.01115 11.7972L8.70136 11.4799L9.00255 11.1858L9.30373 10.8917L9.58999 11.1849C10.2409 11.8515 10.5992 12.7647 10.5839 13.7211C10.5685 14.6775 10.2862 15.3846 9.63857 16.1112L9.28863 16.5L8.99453 16.1988Z" fill="#111326"/>
          <path d="M5.00742 16.1988L5.30153 15.8976L4.94767 15.5128C4.52133 15.0416 4.33014 14.6509 4.23986 14.0685C4.11883 13.2365 4.39475 12.4076 4.9908 11.7972L5.30059 11.4799L4.99941 11.1858L4.69822 10.8917L4.41196 11.1849C3.76101 11.8515 3.40274 12.7647 3.41808 13.7211C3.43342 14.6775 3.7157 15.3846 4.36338 16.1112L4.71332 16.5L5.00742 16.1988Z" fill="#111326"/>
          <circle cx="6.99951" cy="13.75" r="2" fill="#111326"/>
          </svg>
          `)
    );
  }

  if (type === "spoofing") {
    return (
      "data:image/svg+xml;base64," +
      btoa(`<svg width="105" height="100" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="11" y="1.5" width="12.7282" height="12.7282" transform="rotate(45 11 1.5)" fill="${color}" stroke="#111326" stroke-width="1.5"/>
  <path d="M11 11.25V7" stroke="#111326" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <circle cx="11" cy="14" r="1" fill="#111326"/>
  </svg>`)
    );
  }

  if (type === "similar") {
    return (
      "data:image/svg+xml;base64," +
      btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="#FF6D99" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
        <path d="M4.99951 11.5C4.99951 10.5 5.49951 9.5 6.99951 9.5C8.49951 9.5 8.99951 10.5 8.99951 11.5C8.99951 12.5 7.99951 13 7.49951 13.5C7.0542 13.9453 6.99951 14 6.99951 15" stroke="#111326" stroke-width="1.5" stroke-linecap="round"/>
        <circle cx="6.99951" cy="17.5" r="1" fill="#111326"/>
        </svg>`)
    );
  }
};

export const getColorUpdatedSanctionedShipIcon = (type: any, color: any) => {
  if (type === "light") {
    return (
      "data:image/svg+xml;base64," +
      btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="${color}"  stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M8.99453 16.1988L8.70042 15.8976L9.05429 15.5128C9.48062 15.0416 9.67182 14.6509 9.7621 14.0685C9.88313 13.2365 9.60721 12.4076 9.01115 11.7972L8.70136 11.4799L9.00255 11.1858L9.30373 10.8917L9.58999 11.1849C10.2409 11.8515 10.5992 12.7647 10.5839 13.7211C10.5685 14.6775 10.2862 15.3846 9.63857 16.1112L9.28863 16.5L8.99453 16.1988Z" fill="white"/>
<path d="M5.00742 16.1988L5.30153 15.8976L4.94767 15.5128C4.52133 15.0416 4.33014 14.6509 4.23986 14.0685C4.11883 13.2365 4.39475 12.4076 4.9908 11.7972L5.30059 11.4799L4.99941 11.1858L4.69822 10.8917L4.41196 11.1849C3.76101 11.8515 3.40274 12.7647 3.41808 13.7211C3.43342 14.6775 3.7157 15.3846 4.36338 16.1112L4.71332 16.5L5.00742 16.1988Z" fill="white"/>
<circle cx="6.99951" cy="13.75" r="2" fill="white"/>
<path d="M12.6763 21.5L3.17627 7.5" stroke="#111326" stroke-width="1.5"/>
</svg>
`)
    );
  }

  if (type === "dark") {
    return (
      "data:image/svg+xml;base64," +
      btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="${color}" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
        <path d="M8.99453 16.1988L8.70042 15.8976L9.05429 15.5128C9.48062 15.0416 9.67182 14.6509 9.7621 14.0685C9.88313 13.2365 9.60721 12.4076 9.01115 11.7972L8.70136 11.4799L9.00255 11.1858L9.30373 10.8917L9.58999 11.1849C10.2409 11.8515 10.5992 12.7647 10.5839 13.7211C10.5685 14.6775 10.2862 15.3846 9.63857 16.1112L9.28863 16.5L8.99453 16.1988Z" fill="#111326"/>
        <path d="M5.00742 16.1988L5.30153 15.8976L4.94767 15.5128C4.52133 15.0416 4.33014 14.6509 4.23986 14.0685C4.11883 13.2365 4.39475 12.4076 4.9908 11.7972L5.30059 11.4799L4.99941 11.1858L4.69822 10.8917L4.41196 11.1849C3.76101 11.8515 3.40274 12.7647 3.41808 13.7211C3.43342 14.6775 3.7157 15.3846 4.36338 16.1112L4.71332 16.5L5.00742 16.1988Z" fill="#111326"/>
        <circle cx="6.99951" cy="13.75" r="2" fill="#111326"/>
        <path d="M12.6763 21.5L3.17627 7.5" stroke="#111326" stroke-width="1.5"/>
        </svg>
        
          `)
    );
  }
  if (type === "AIS") {
    return (
      "data:image/svg+xml;base64," +
      btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="${color}" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M12.6763 21.5L3.17627 7.5" stroke="#111326" stroke-width="1.5"/>
      </svg>`)
    );
  }
};
