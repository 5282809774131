import { Snackbar, Alert } from "@mui/material";
import { useSnackbar } from "../../context/SnackbarContext";

const SnackBar = () => {
  const { message, severity, isOpen, handleSnackBarClose } = useSnackbar();

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={5000}
      onClose={handleSnackBarClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleSnackBarClose}
        severity={severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
