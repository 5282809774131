import React, { Dispatch, SetStateAction, useState } from "react";
import TextInput from "../../../components/TextInput/TextInput";
import Label from "../../../components/Label/Label";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Link } from "@mui/material";
import ArrowBackOutlined from "@mui/icons-material/ArrowBackOutlined";
import styles from "../Auth.module.scss";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router";
import { useSnackbar } from "../../../context/SnackbarContext";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setNewSnackBar } = useSnackbar();
  const auth = getAuth();
  const navigate = useNavigate();

  const sendResetEmail = () => {
    setIsLoading(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setIsLoading(false);
        navigate(`/resend-email?email=${email}`);
        setNewSnackBar({ message: "Reset Email Sent!", severity: "success" });
      })
      .catch((error) => {
        setIsLoading(false);
        const errorMessage = error.message;
        setNewSnackBar({
          message: errorMessage,
          severity: "error",
        });
      });
  };

  return (
    <Box className={styles.loginDetail}>
      <h5 className={styles.loginDetailHead}>Forgot Password?</h5>
      <Box className={styles.loginDetailDescription}>
        No worries, we'll send you reset instructions.
      </Box>
      <form className={styles.detailInputWrapper}>
        <Box className={styles.inputWrapper}>
          <Label label="Email" />
          <TextInput
            id="email"
            type="email"
            name="email"
            value={email}
            placeholder="Enter email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <LoadingButton
          variant="outlined"
          className={styles.buttonWrap}
          onClick={sendResetEmail}
          loading={isLoading}
        >
          Reset Password
        </LoadingButton>
        <Box
          className={styles.backButtonWrapper}
          onClick={(e) => {
            navigate("/login");
          }}
        >
          <ArrowBackOutlined sx={{ fill: "#fff" }} />
          <Link className={styles.inputLink} underline="none">
            Back to Log In
          </Link>
        </Box>
      </form>
    </Box>
  );
};

export default ForgetPassword;
