import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import {
  addFolderData,
  updateFolderData,
  deleteFolderData,
  setSelectedFolderId,
  fetchFolderWorkData,
  updateWorkStationData,
  addWorkStationData,
} from "../../common/Common.store";
import { setIsRecent, setSearchText } from "./HomePanel.store";
import {
  addTabData,
  setIsDashboard,
  setTabValue,
} from "../Header/Header.store";
import { findWorkStationInTabs, onDragStart } from "../../common/Common";
import { addSharedData, setIsShared } from "../../pages/Shared/Shared.store";
import CreateEditFolderDialog from "./Dialog/CreateEditFolderDialog";
import DeleteDialog from "../Dialog/DeleteDialog";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import {
  AccessTime,
  FolderOpen,
  Search,
  Add,
  EditOutlined,
  DeleteOutlined,
  ExpandMore,
  InsertDriveFileOutlined,
  Share,
} from "@mui/icons-material";
import styles from "./HomePanel.module.scss";
import useFireBaseAuth from "../../context/useFireBaseAuth";
import { useSnackbar } from "../../context/SnackbarContext";

interface OldFolderType {
  key: string;
  name: string;
}

const HomePanel: FC = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  const { currentUser } = useFireBaseAuth();
  const { allProjects, selectedFolderId, isAllProjectsFetch } = useSelector(
    (state: RootState) => state.common
  );

  const { setNewSnackBar } = useSnackbar();
  const { isRecent } = useSelector((state: RootState) => state.homePanel);
  const { isShared } = useSelector((state: RootState) => state.shared);
  const { tabs } = useSelector((state: RootState) => state.header);
  const userId = currentUser.uid;

  const [folderName, setFolderName] = useState<string>("");
  const [oldFolder, setOldFolder] = useState<OldFolderType>();
  const [isCreateFolder, setIsCreateFolder] = useState<boolean>(false);
  const [isEditFolder, setIsEditFolder] = useState<boolean>(false);
  const [isDeleteFolder, setIsDeleteFolder] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string | boolean>(false);

  useEffect(() => {
    if (!isAllProjectsFetch && userId) dispatch(fetchFolderWorkData(userId));
  }, [isAllProjectsFetch, dispatch, userId]);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchText(event.target.value));
  };

  const getFolderWorkStations = (folderId: string) => {
    dispatch(setIsRecent(false));
    dispatch(setIsShared(false));
    dispatch(setIsDashboard(true));
    dispatch(setSelectedFolderId(folderId));
  };

  const addFolder = async (name: string) => {
    const data = { userId, folderName: name };
    dispatch(addFolderData(data));
    setNewSnackBar({ message: "Folder Added", severity: "success" });
  };

  const editFolder = async (name: string) => {
    const data = {
      userId,
      folderId: oldFolder?.key,
      newFolderName: name,
    };
    dispatch(updateFolderData(data));
    setNewSnackBar({ message: "Folder Updated", severity: "success" });
  };

  const deleteFolder = async () => {
    const data = {
      userId,
      folderId: oldFolder?.key,
    };
    dispatch(deleteFolderData(data));
    if (Object.keys(allProjects).length > 0 && !allProjects.msg) {
      const firstFolder = Object.keys(allProjects)[0];
      dispatch(setSelectedFolderId(firstFolder));
    }
    setNewSnackBar({ message: "Folder Deleted", severity: "error" });
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      event.stopPropagation();
      setExpanded(isExpanded ? panel : false);

      if (isExpanded) {
        getFolderWorkStations(panel);
      }
    };

  const addTabList = (
    folderId: string,
    workStationId: string,
    tabName: string
  ) => {
    const workStationData = findWorkStationInTabs(workStationId, tabs);
    const updateDatedata = {
      userId,
      folderId,
      workStationId,
      isWorkStationDate: true,
    };

    if (workStationData) {
      dispatch(setTabValue(workStationData.value));
    } else {
      const tabValue =
        Object.keys(tabs).length > 0
          ? Math.max(...Object.values(tabs).map((item) => item.value)) + 1
          : 0;
      const newTab = {
        userId,
        tabName,
        tabValue,
        folderId,
        workStationId,
      };
      dispatch(addTabData(newTab));
      dispatch(setTabValue(tabValue));
    }

    dispatch(setIsDashboard(false));
    dispatch(setIsRecent(false));
    dispatch(setIsShared(false));
    dispatch(updateWorkStationData(updateDatedata));
  };

  const onDrop = (e: any, folderId: string) => {
    e.stopPropagation();
    const workStationName = e.dataTransfer.getData("workId");
    const workStationFolderId = e.dataTransfer.getData("folderId");
    // make sure entire state is shared
    if (folderId !== workStationFolderId) {
      const data = {
        userId,
        folderId,
        workStationName,
      };
      dispatch(addWorkStationData(data));
    }
  };

  const onDropShare = (e: any) => {
    e.stopPropagation();
    const workStationName = e.dataTransfer.getData("workId");
    if (!isShared) {
      const shareData = {
        userId,
        workStationName,
      };
      dispatch(addSharedData(shareData));
    }
  };

  return (
    <Box display="flex" flexDirection="column" className={styles.sideBarMain}>
      <Box display="flex" className={styles.sideBarSearchMain}>
        <FormControl>
          <OutlinedInput
            className={styles.sideBarSearchInput}
            classes={{
              focused: styles.searchInputFocused,
              input: styles.searchInput,
            }}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            placeholder="Search"
            onChange={handleSearch}
            disabled={true}
          />
        </FormControl>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        className={`${styles.recentWrap}  ${isRecent && styles.activeTab}`}
        onClick={() => {
          dispatch(setIsRecent(true));
          dispatch(setIsDashboard(true));
          dispatch(setIsShared(false));
        }}
      >
        <AccessTime className={styles.recentIcon} />
        <Typography className={styles.recentHead} variant="h6">
          Recent
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" className={styles.projectMain}>
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          className={styles.projectHeadWrap}
        >
          <Typography className={styles.projectHead} variant="h6">
            Project
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          className={styles.projectWrap}
          gap="12px"
        >
          {!allProjects.msg &&
            Object.keys(allProjects).map((data: string) => {
              const folder = allProjects[data];
              return (
                <div
                  onDrop={(e) => onDrop(e, data)}
                  onDragOver={(e) => e.preventDefault()}
                  onDragEnter={(e) => e.preventDefault()}
                  key={data}
                >
                  <Accordion
                    expanded={expanded === data}
                    onChange={handleChange(data)}
                    onClick={(e) => e.stopPropagation()}
                    className={`${styles.accordionMain} ${
                      data === selectedFolderId &&
                      !isRecent &&
                      !isShared &&
                      styles.activeTab
                    }`}
                    classes={{ expanded: styles.accordionExpanded }}
                  >
                    <AccordionSummary
                      aria-controls={data}
                      id={data}
                      className={styles.accordionWrap}
                      classes={{
                        content: styles.accordionContent,
                      }}
                      expandIcon={
                        <ExpandMore className={styles.expandMoreIcon} />
                      }
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        className={`${styles.projectCard} ${
                          data === selectedFolderId &&
                          !isRecent &&
                          styles.activeTab
                        }`}
                      >
                        <Box display="flex" alignItems="center" gap={1}>
                          <FolderOpen className={styles.projectIcon} />
                          <Typography
                            className={styles.projectHead}
                            variant="h6"
                          >
                            {folder.name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ pointerEvents: "auto" }}
                          display="flex"
                          gap={1}
                        >
                          <EditOutlined
                            onClick={() => {
                              setIsEditFolder(true);
                              setFolderName(folder.name);
                              setOldFolder({
                                key: data,
                                name: folder.name,
                              });
                            }}
                            className={styles.projectEditDelete}
                          />
                          <DeleteOutlined
                            onClick={() => {
                              setIsDeleteFolder(true);
                              setFolderName(folder.name);
                              setOldFolder({
                                key: data,
                                name: folder.name,
                              });
                            }}
                            className={styles.projectEditDelete}
                          />
                        </Box>
                      </Box>
                    </AccordionSummary>

                    <AccordionDetails className={styles.accordionDetails}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        className={styles.accordionDetailsWrap}
                      >
                        {Object.keys(allProjects[data]?.workStations).map(
                          (work: string) => {
                            const workStationData =
                              allProjects[data].workStations[work];
                            return (
                              <Box
                                display="flex"
                                alignItems="center"
                                gap={1}
                                className={styles.accordionDetails}
                                onClick={() =>
                                  addTabList(data, work, workStationData.name)
                                }
                                key={work}
                                onDragStart={(e) =>
                                  onDragStart(
                                    e,
                                    workStationData.name,
                                    selectedFolderId
                                  )
                                }
                                draggable
                              >
                                <InsertDriveFileOutlined
                                  className={styles.detailsIcon}
                                />
                                <Typography
                                  className={styles.detailsHead}
                                  variant="h6"
                                >
                                  {workStationData.name}
                                </Typography>
                              </Box>
                            );
                          }
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })}
        </Box>
        <Box
          onDragOver={(e) => e.preventDefault()}
          onDragEnter={(e) => e.preventDefault()}
          onDrop={(e: any) => onDropShare(e)}
          draggable
        >
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            className={`${styles.projectShared} ${
              isShared && styles.activeTab
            }`}
            onClick={() => {
              dispatch(setIsShared(true));
              dispatch(setIsDashboard(true));
              dispatch(setIsRecent(false));
            }}
          >
            <Share className={styles.projectSharedIcon} />
            <Typography className={styles.projectSharedHead} variant="h6">
              Shared
            </Typography>
          </Box>
        </Box>
        <Box
          onClick={() => setIsCreateFolder(true)}
          display="flex"
          alignItems="center"
          gap={1}
          className={styles.projectShared}
        >
          <Add className={styles.projectSharedIcon} />
          <Typography className={styles.projectSharedHead} variant="h6">
            Create New Project
          </Typography>
        </Box>
      </Box>
      {isCreateFolder && (
        <CreateEditFolderDialog
          open={isCreateFolder}
          setOpen={setIsCreateFolder}
          title="Create New Project"
          buttonName="Create"
          addEditFolder={addFolder}
        />
      )}
      {isEditFolder && (
        <CreateEditFolderDialog
          open={isEditFolder}
          setOpen={setIsEditFolder}
          title="Edit Project Name"
          buttonName="Change"
          addEditFolder={editFolder}
          oldFolderName={folderName}
        />
      )}
      {isDeleteFolder && (
        <DeleteDialog
          open={isDeleteFolder}
          setOpen={setIsDeleteFolder}
          deleteFolderWorkStation={deleteFolder}
        />
      )}
    </Box>
  );
};

export default HomePanel;
