import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../../../redux/store";
import CustomDatePicker from "../../../../components/CustomDatePicker/CustomDatePicker";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import Label from "../../../../components/Label/Label";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import { DateRange, MapContext } from "../../MainMap";
import { Box } from "@mui/material";
import { fetchPolygonAnalysisData } from "../PolygonShipAnalysis.store";
import styles from "./PolygonShipAnalysisSelectDate.module.scss";

interface PolygonShipAnalysisSelectDateProps {
  setShowPolygonShipAnalysisSlider: (val: boolean) => void;
  dateRange: DateRange;
  setDateRange: (val: DateRange) => void;
  showPolygonShipAnalysisSlider: boolean;
  lastSelectedOption: string;
  setLastSelectedOption: (lastSelectedOption: string) => void;
  limit: number;
  skip: number;
  resetValuesForSlider: () => void;
}

const PolygonShipAnalysisSelectDate = ({
  setShowPolygonShipAnalysisSlider,
  dateRange,
  setDateRange,
  showPolygonShipAnalysisSlider,
  lastSelectedOption,
  setLastSelectedOption,
  limit,
  skip,
  resetValuesForSlider,
}: PolygonShipAnalysisSelectDateProps) => {
  const { analyzeShape } = useSelector(
    (state: RootState) => state.polygonShipAnalysis
  );
  const [isUpdateSaved, setisUpdateSaved] = useState(false);
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  const { loading, error } = useSelector(
    (state: RootState) => state.polygonShipAnalysis
  );
  const { features } = useContext(MapContext);
  const [analyzeShapeArray, setAnalyzeShapeArray] = useState(analyzeShape);
 

  useEffect(() => {
    setAnalyzeShapeArray(analyzeShape);
  }, [analyzeShape]);

 
  useEffect(() => { setisUpdateSaved(false) }, [lastSelectedOption])
  const handleClick = async () => {
    //reset slider and skip value when polygon is changed
    if (showPolygonShipAnalysisSlider) {
      resetValuesForSlider();
    }
    const area = features.data[lastSelectedOption].geometry;
    const shipData = {
      area,
      end: moment(new Date(dateRange.endDate)).format("YYYY-MM-DD") + ' 23:59:59',
      start: moment(new Date(dateRange.startDate)).format(
        "YYYY-MM-DD"
      ) + ' 00:00:00',
      limit,
      skip,
    };

    await dispatch(fetchPolygonAnalysisData(shipData));
    !error && setShowPolygonShipAnalysisSlider(true);
    !error && setisUpdateSaved(true);
  };

  return (
    <Box display="flex" alignItems="flex-end" gap="12px">
      <Box display="flex" flexDirection="column" gap="12px" minWidth="200px">
        <Label label="Analyze Ships In" />
        <SelectInput
          name="analyze-ships"
          value={lastSelectedOption}
          onChange={(e: any) => {
            setLastSelectedOption(e.target.value);
          }}
          optionsArray={analyzeShapeArray}
          className={styles.analyzeShipSelect}
        />
      </Box>
      <CustomDatePicker
        title="Start Date"
        value={dateRange.startDate}
        setValue={(date: any) => {
          setDateRange({ ...dateRange, startDate: date });
          setisUpdateSaved(false);
        }}
        maxDate={dateRange.endDate}
      />
      <CustomDatePicker
        title="End Date"
        value={dateRange.endDate}
        setValue={(date: any) => {
          setDateRange({ ...dateRange, endDate: date });
          setisUpdateSaved(false);
        }}
        minDate={dateRange.startDate}
      />
      <Box width="100%">
        {(!isUpdateSaved || !showPolygonShipAnalysisSlider) && (
          <CustomButton
            buttonName={
              showPolygonShipAnalysisSlider ? "Update" : "Load Ships In Polygon"
            }
            loading={loading}
            onClick={handleClick}
            variant={showPolygonShipAnalysisSlider ? "outlined" : "contained"}
          />
        )}
      </Box>
    </Box>
  );
};

export default PolygonShipAnalysisSelectDate;
