const ShapesIcon = ({ size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.04794 5.83333L4.28604 14.1667M5.00033 15.8333H15.0002M15.7146 14.1667L10.9527 5.83333M3.00033 17.5H3.66699C4.1337 17.5 4.36706 17.5 4.54532 17.4092C4.70212 17.3293 4.8296 17.2018 4.9095 17.045C5.00033 16.8667 5.00033 16.6334 5.00033 16.1667V15.5C5.00033 15.0333 5.00033 14.7999 4.9095 14.6217C4.8296 14.4649 4.70212 14.3374 4.54532 14.2575C4.36706 14.1667 4.1337 14.1667 3.66699 14.1667H3.00033C2.53362 14.1667 2.30026 14.1667 2.122 14.2575C1.9652 14.3374 1.83771 14.4649 1.75782 14.6217C1.66699 14.7999 1.66699 15.0333 1.66699 15.5V16.1667C1.66699 16.6334 1.66699 16.8667 1.75782 17.045C1.83771 17.2018 1.9652 17.3293 2.122 17.4092C2.30026 17.5 2.53362 17.5 3.00033 17.5ZM16.3337 17.5H17.0003C17.467 17.5 17.7004 17.5 17.8787 17.4092C18.0355 17.3293 18.1629 17.2018 18.2428 17.045C18.3337 16.8667 18.3337 16.6334 18.3337 16.1667V15.5C18.3337 15.0333 18.3337 14.7999 18.2428 14.6217C18.1629 14.4649 18.0355 14.3374 17.8787 14.2575C17.7004 14.1667 17.467 14.1667 17.0003 14.1667H16.3337C15.8669 14.1667 15.6336 14.1667 15.4553 14.2575C15.2985 14.3374 15.171 14.4649 15.0912 14.6217C15.0003 14.7999 15.0003 15.0333 15.0003 15.5V16.1667C15.0003 16.6334 15.0003 16.8667 15.0912 17.045C15.171 17.2018 15.2985 17.3293 15.4553 17.4092C15.6336 17.5 15.8669 17.5 16.3337 17.5ZM9.66699 5.83333H10.3337C10.8004 5.83333 11.0337 5.83333 11.212 5.74251C11.3688 5.66261 11.4963 5.53513 11.5762 5.37833C11.667 5.20007 11.667 4.96671 11.667 4.5V3.83333C11.667 3.36662 11.667 3.13327 11.5762 2.95501C11.4963 2.79821 11.3688 2.67072 11.212 2.59083C11.0337 2.5 10.8004 2.5 10.3337 2.5H9.66699C9.20028 2.5 8.96693 2.5 8.78867 2.59083C8.63186 2.67072 8.50438 2.79821 8.42449 2.95501C8.33366 3.13327 8.33366 3.36662 8.33366 3.83333V4.5C8.33366 4.96671 8.33366 5.20007 8.42449 5.37833C8.50438 5.53513 8.63186 5.66261 8.78867 5.74251C8.96693 5.83333 9.20028 5.83333 9.66699 5.83333Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ShapesIcon;
