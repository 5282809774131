import { Box } from "@mui/material";
import styles from "./Ownership.module.scss";
import { AllDetailsTabs } from "../../../../context/useShipDetails";

const Ownership = ({ tabDetails }: { tabDetails: AllDetailsTabs }) => {
  return (
    <Box className={styles.ownershipWrap}>
      <Box
        display="flex"
        flexDirection={"column"}
        className={styles.ownerDetailWrap}
      >
        <label className={styles.ownerDetailHead}>Commercial Owner</label>
        <span className={styles.ownerDetailValue}>
          {tabDetails.commercialowner ?? "No Info"}
        </span>
      </Box>
      <Box
        display="flex"
        flexDirection={"column"}
        className={styles.ownerDetailWrap}
      >
        <label className={styles.ownerDetailHead}>Effective Owner</label>
        <span className={styles.ownerDetailValue}>
          {tabDetails.registeredowner ?? "No Info"}
        </span>
      </Box>
      <Box
        display="flex"
        flexDirection={"column"}
        className={styles.ownerDetailWrap}
      >
        <label className={styles.ownerDetailHead}>Financial Owner</label>
        <span className={styles.ownerDetailValue}>
          {tabDetails.financialowner ?? "No Info"}
        </span>
      </Box>
      <Box
        display="flex"
        flexDirection={"column"}
        className={styles.ownerDetailWrap}
      >
        <label className={styles.ownerDetailHead}>Technical Owner</label>
        <span className={styles.ownerDetailValue}>
          {tabDetails.technicalmanager ?? "No Info"}
        </span>
      </Box>
    </Box>
  );
};

export default Ownership;
