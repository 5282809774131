export type ShipSvgType =
  | "shipSVG"
  | "spoofing"
  | "bunkering"
  | "sanctioned"
  | "pin"
  | "rectangle"
  | "triangle"
  | "selectUnit"
  | "search"
  | "layers"
  | "measureLine"
  | "measureCircle"
  | "deleteSvg";

interface ShipSvgsProps {
  color?: string;
  stroke?: string;
  type: ShipSvgType;
  size?: string;
}

export const ShipSvgs = ({
  color,
  stroke = "white",
  type,
  size = "20",
}: ShipSvgsProps) => {
  const svgs = {
    shipSVG: (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.9999 21H15.8235L15.8235 16.2413C15.8235 5.51595 9.99989 1 9.99989 1C9.99989 1 4.17627 5.51595 4.17628 16.2413L4.17628 21H9.9999Z"
          fill={color}
          stroke={stroke}
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
      </svg>
    ),
    spoofing: (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.98872 2.00001C6.54209 2.0062 3.75 4.8021 3.75 8.2502V16.9583C3.75 17.4224 4.31103 17.6547 4.63916 17.3266L5.83341 16.0976L7.5485 17.8474C7.75194 18.0509 8.08172 18.0509 8.28512 17.8474L10.0002 16.0976L11.7153 17.8474C11.9187 18.0509 12.2485 18.0509 12.4519 17.8474L14.167 16.0976L15.3612 17.3266C15.6893 17.6547 16.2504 17.4223 16.2504 16.9583V8.30373C16.2504 4.85976 13.4327 1.99385 9.98872 2.00001ZM7.39594 10.3336C6.53431 10.3336 5.83341 9.63267 5.83341 8.77104C5.83341 7.90942 6.53434 7.20851 7.39594 7.20851C8.25753 7.20851 8.95847 7.90945 8.95847 8.77104C8.95847 9.63263 8.25756 10.3336 7.39594 10.3336ZM12.6044 10.3336C11.7428 10.3336 11.0419 9.63267 11.0419 8.77104C11.0419 7.90942 11.7428 7.20851 12.6044 7.20851C13.466 7.20851 14.167 7.90945 14.167 8.77104C14.167 9.63263 13.4661 10.3336 12.6044 10.3336Z"
          fill="#FF6D99"
        />
      </svg>
    ),
    bunkering: (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.34103 18H11.9999L11.9999 14.1931C11.9999 5.61276 7.34103 2 7.34103 2C7.34103 2 2.68213 5.61276 2.68214 14.1931L2.68214 18H7.34103Z"
          stroke="#55EFE6"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M12.6589 18H17.3178L17.3178 14.1931C17.3178 5.61276 12.6589 2 12.6589 2C12.6589 2 8 5.61276 8.00001 14.1931L8.00001 18H12.6589Z"
          stroke="#55EFE6"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
      </svg>
    ),
    sanctioned: (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0002 18H14.6591L14.6591 14.1931C14.6591 5.61276 10.0002 2 10.0002 2C10.0002 2 5.34131 5.61276 5.34132 14.1931L5.34132 18H10.0002Z"
          stroke="#D256FE"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path d="M5.5 18L13.5 7" stroke="#D256FE" stroke-width="1.5" />
      </svg>
    ),
    similar: (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.9999 21L15.8235 21L15.8235 16.2413C15.8235 5.51595 9.99989 1 9.99989 1C9.99989 1 4.17627 5.51595 4.17628 16.2413L4.17628 21H9.9999Z"
          fill="#FF6D99"
          stroke="#111326"
          stroke-width="1.5"
          stroke-miterlimit="10"
        />
        <path
          d="M7.99951 11C7.99951 10 8.49951 9 9.99951 9C11.4995 9 11.9995 10 11.9995 11C11.9995 12 10.9995 12.5 10.4995 13C10.0542 13.4453 9.99951 13.5 9.99951 14.5"
          stroke="#111326"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <circle cx="9.99951" cy="17" r="1" fill="#111326" />
      </svg>
    ),
    pin: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.98065 12.0133L1.2666 16.7274M8.74539 4.53458L7.44465 5.83532C7.33854 5.94142 7.28549 5.99447 7.22505 6.03663C7.1714 6.07405 7.11353 6.10502 7.05264 6.1289C6.98404 6.15581 6.91047 6.17052 6.76333 6.19995L3.70961 6.8107C2.91602 6.96941 2.51923 7.04877 2.33359 7.25798C2.17187 7.44024 2.09802 7.68415 2.13148 7.92551C2.16989 8.20255 2.45602 8.48868 3.02829 9.06095L8.93307 14.9657C9.50533 15.538 9.79146 15.8241 10.0685 15.8625C10.3099 15.896 10.5538 15.8221 10.736 15.6604C10.9452 15.4748 11.0246 15.078 11.1833 14.2844L11.7941 11.2307C11.8235 11.0835 11.8382 11.01 11.8651 10.9414C11.889 10.8805 11.92 10.8226 11.9574 10.769C11.9995 10.7085 12.0526 10.6555 12.1587 10.5494L13.4594 9.24863C13.5273 9.18079 13.5612 9.14687 13.5985 9.11725C13.6316 9.09095 13.6667 9.0672 13.7034 9.04622C13.7447 9.0226 13.7888 9.0037 13.877 8.96591L15.9556 8.07507C16.5621 7.81517 16.8653 7.68523 17.003 7.47524C17.1234 7.29161 17.1665 7.06786 17.1229 6.85263C17.073 6.60651 16.8398 6.37325 16.3732 5.90673L12.0873 1.62078C11.6208 1.15426 11.3875 0.921001 11.1414 0.871108C10.9262 0.827477 10.7024 0.870568 10.5188 0.991011C10.3088 1.12874 10.1788 1.43195 9.91895 2.03837L9.0281 4.117C8.99031 4.20518 8.97142 4.24927 8.9478 4.29061C8.92682 4.32734 8.90307 4.36242 8.87676 4.39554C8.84714 4.43282 8.81322 4.46674 8.74539 4.53458Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    triangle: (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.04746 5.83333L4.28555 14.1667M4.99984 15.8333H14.9997M15.7141 14.1667L10.9522 5.83333M2.99984 17.5H3.6665C4.13321 17.5 4.36657 17.5 4.54483 17.4092C4.70163 17.3293 4.82911 17.2018 4.90901 17.045C4.99984 16.8667 4.99984 16.6334 4.99984 16.1667V15.5C4.99984 15.0333 4.99984 14.7999 4.90901 14.6217C4.82911 14.4649 4.70163 14.3374 4.54483 14.2575C4.36657 14.1667 4.13321 14.1667 3.6665 14.1667H2.99984C2.53313 14.1667 2.29977 14.1667 2.12151 14.2575C1.96471 14.3374 1.83723 14.4649 1.75733 14.6217C1.6665 14.7999 1.6665 15.0333 1.6665 15.5V16.1667C1.6665 16.6334 1.6665 16.8667 1.75733 17.045C1.83723 17.2018 1.96471 17.3293 2.12151 17.4092C2.29977 17.5 2.53313 17.5 2.99984 17.5ZM16.3332 17.5H16.9998C17.4665 17.5 17.6999 17.5 17.8782 17.4092C18.035 17.3293 18.1624 17.2018 18.2423 17.045C18.3332 16.8667 18.3332 16.6334 18.3332 16.1667V15.5C18.3332 15.0333 18.3332 14.7999 18.2423 14.6217C18.1624 14.4649 18.035 14.3374 17.8782 14.2575C17.6999 14.1667 17.4665 14.1667 16.9998 14.1667H16.3332C15.8665 14.1667 15.6331 14.1667 15.4548 14.2575C15.298 14.3374 15.1706 14.4649 15.0907 14.6217C14.9998 14.7999 14.9998 15.0333 14.9998 15.5V16.1667C14.9998 16.6334 14.9998 16.8667 15.0907 17.045C15.1706 17.2018 15.298 17.3293 15.4548 17.4092C15.6331 17.5 15.8665 17.5 16.3332 17.5ZM9.6665 5.83333H10.3332C10.7999 5.83333 11.0332 5.83333 11.2115 5.74251C11.3683 5.66261 11.4958 5.53513 11.5757 5.37833C11.6665 5.20007 11.6665 4.96671 11.6665 4.5V3.83333C11.6665 3.36662 11.6665 3.13327 11.5757 2.95501C11.4958 2.79821 11.3683 2.67072 11.2115 2.59083C11.0332 2.5 10.7999 2.5 10.3332 2.5H9.6665C9.19979 2.5 8.96644 2.5 8.78818 2.59083C8.63138 2.67072 8.50389 2.79821 8.424 2.95501C8.33317 3.13327 8.33317 3.36662 8.33317 3.83333V4.5C8.33317 4.96671 8.33317 5.20007 8.424 5.37833C8.50389 5.53513 8.63138 5.66261 8.78818 5.74251C8.96644 5.83333 9.19979 5.83333 9.6665 5.83333Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    rectangle: (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 14V6M6 4H14M6 16H14M16 14V6"
          stroke="white"
          stroke-width="1.5"
        />
        <path
          d="M2.6665 15.5003C2.6665 15.0336 2.6665 14.8003 2.75733 14.622C2.83723 14.4652 2.96471 14.3377 3.12151 14.2578C3.29977 14.167 3.53313 14.167 3.99984 14.167H4.6665C5.13321 14.167 5.36657 14.167 5.54483 14.2578C5.70163 14.3377 5.82911 14.4652 5.90901 14.622C5.99984 14.8003 5.99984 15.0336 5.99984 15.5003V16.167C5.99984 16.6337 5.99984 16.8671 5.90901 17.0453C5.82911 17.2021 5.70163 17.3296 5.54483 17.4095C5.36657 17.5003 5.13321 17.5003 4.6665 17.5003H3.99984C3.53313 17.5003 3.29977 17.5003 3.12151 17.4095C2.96471 17.3296 2.83723 17.2021 2.75733 17.0453C2.6665 16.8671 2.6665 16.6337 2.6665 16.167V15.5003Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14 15.5003C14 15.0336 14 14.8003 14.0908 14.622C14.1707 14.4652 14.2982 14.3377 14.455 14.2578C14.6333 14.167 14.8666 14.167 15.3333 14.167H16C16.4667 14.167 16.7001 14.167 16.8783 14.2578C17.0351 14.3377 17.1626 14.4652 17.2425 14.622C17.3333 14.8003 17.3333 15.0336 17.3333 15.5003V16.167C17.3333 16.6337 17.3333 16.8671 17.2425 17.0453C17.1626 17.2021 17.0351 17.3296 16.8783 17.4095C16.7001 17.5003 16.4667 17.5003 16 17.5003H15.3333C14.8666 17.5003 14.6333 17.5003 14.455 17.4095C14.2982 17.3296 14.1707 17.2021 14.0908 17.0453C14 16.8671 14 16.6337 14 16.167V15.5003Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14 3.50033C14 3.03362 14 2.80026 14.0908 2.622C14.1707 2.4652 14.2982 2.33771 14.455 2.25782C14.6333 2.16699 14.8666 2.16699 15.3333 2.16699H16C16.4667 2.16699 16.7001 2.16699 16.8783 2.25782C17.0351 2.33771 17.1626 2.4652 17.2425 2.622C17.3333 2.80026 17.3333 3.03362 17.3333 3.50033V4.16699C17.3333 4.6337 17.3333 4.86706 17.2425 5.04532C17.1626 5.20212 17.0351 5.3296 16.8783 5.4095C16.7001 5.50033 16.4667 5.50033 16 5.50033H15.3333C14.8666 5.50033 14.6333 5.50033 14.455 5.4095C14.2982 5.3296 14.1707 5.20212 14.0908 5.04532C14 4.86706 14 4.6337 14 4.16699V3.50033Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.6665 3.50033C2.6665 3.03362 2.6665 2.80026 2.75733 2.622C2.83723 2.4652 2.96471 2.33771 3.12151 2.25782C3.29977 2.16699 3.53313 2.16699 3.99984 2.16699H4.6665C5.13321 2.16699 5.36657 2.16699 5.54483 2.25782C5.70163 2.33771 5.82911 2.4652 5.90901 2.622C5.99984 2.80026 5.99984 3.03362 5.99984 3.50033V4.16699C5.99984 4.6337 5.99984 4.86706 5.90901 5.04532C5.82911 5.20212 5.70163 5.3296 5.54483 5.4095C5.36657 5.50033 5.13321 5.50033 4.6665 5.50033H3.99984C3.53313 5.50033 3.29977 5.50033 3.12151 5.4095C2.96471 5.3296 2.83723 5.20212 2.75733 5.04532C2.6665 4.86706 2.6665 4.6337 2.6665 4.16699V3.50033Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    deleteSvg: (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    measureLine: (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.0835 4.58292L13.3335 5.83292M9.58347 7.08292L10.8335 8.33292M7.08347 9.58292L8.33347 10.8329M4.58347 12.0829L5.83347 13.3329M2.13817 14.6377L5.36203 17.8615C5.52704 18.0265 5.60954 18.1091 5.70468 18.14C5.78836 18.1672 5.87851 18.1672 5.96219 18.14C6.05733 18.1091 6.13983 18.0265 6.30484 17.8615L17.862 6.30435C18.027 6.13934 18.1095 6.05684 18.1405 5.9617C18.1676 5.87802 18.1676 5.78787 18.1405 5.70419C18.1095 5.60905 18.027 5.52655 17.862 5.36154L14.6382 2.13768C14.4732 1.97268 14.3907 1.89017 14.2955 1.85926C14.2118 1.83207 14.1217 1.83207 14.038 1.85926C13.9429 1.89017 13.8604 1.97268 13.6954 2.13768L2.13817 13.6949C1.97316 13.8599 1.89066 13.9424 1.85975 14.0375C1.83256 14.1212 1.83256 14.2114 1.85975 14.295C1.89066 14.3902 1.97316 14.4727 2.13817 14.6377Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    measureCircle: (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1674_23643)">
          <path
            d="M18.3332 10.0003C18.3332 14.6027 14.6022 18.3337 9.99984 18.3337M18.3332 10.0003C18.3332 5.39795 14.6022 1.66699 9.99984 1.66699M18.3332 10.0003H15.6665M9.99984 18.3337C5.39746 18.3337 1.6665 14.6027 1.6665 10.0003M9.99984 18.3337V15.667M1.6665 10.0003C1.6665 5.39795 5.39746 1.66699 9.99984 1.66699M1.6665 10.0003H4.33317M9.99984 1.66699V4.33366M15.8924 15.8929L14 14.0002M6 6.00024L4.10728 4.10777M14 6.00024L15.8924 4.10777M4.10728 15.8929L6 14.0002"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1674_23643">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    selectUnit: (
      <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.274148 9.5V0.772727H1.5696V2.13636H1.68324C1.86506 1.67045 2.15862 1.30871 2.56392 1.05114C2.96922 0.789773 3.45597 0.659091 4.02415 0.659091C4.59991 0.659091 5.07907 0.789773 5.46165 1.05114C5.84801 1.30871 6.14915 1.67045 6.36506 2.13636H6.45597C6.67945 1.68561 7.01468 1.32765 7.46165 1.0625C7.90862 0.793561 8.4446 0.659091 9.0696 0.659091C9.84991 0.659091 10.4882 0.903409 10.9844 1.39205C11.4806 1.87689 11.7287 2.63258 11.7287 3.65909V9.5H10.3878V3.65909C10.3878 3.01515 10.2116 2.55492 9.85938 2.27841C9.5071 2.00189 9.09233 1.86364 8.61506 1.86364C8.00142 1.86364 7.52604 2.04924 7.18892 2.42045C6.8518 2.78788 6.68324 3.25379 6.68324 3.81818V9.5H5.3196V3.52273C5.3196 3.02652 5.15862 2.62689 4.83665 2.32386C4.51468 2.01705 4.09991 1.86364 3.59233 1.86364C3.24384 1.86364 2.91809 1.95644 2.61506 2.14205C2.31581 2.32765 2.07339 2.58523 1.88778 2.91477C1.70597 3.24053 1.61506 3.61742 1.61506 4.04545V9.5H0.274148Z"
          fill="white"
        />
      </svg>
    ),
    layers: (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1436_16157)">
          <path
            d="M1.6665 9.99997L9.70169 14.0176C9.81101 14.0722 9.86567 14.0996 9.923 14.1103C9.97378 14.1198 10.0259 14.1198 10.0767 14.1103C10.134 14.0996 10.1887 14.0722 10.298 14.0176L18.3332 9.99997M1.6665 14.1666L9.70169 18.1842C9.81101 18.2389 9.86567 18.2662 9.923 18.277C9.97378 18.2865 10.0259 18.2865 10.0767 18.277C10.134 18.2662 10.1887 18.2389 10.298 18.1842L18.3332 14.1666M1.6665 5.83331L9.70169 1.81571C9.81101 1.76105 9.86567 1.73372 9.923 1.72297C9.97378 1.71344 10.0259 1.71344 10.0767 1.72297C10.134 1.73372 10.1887 1.76105 10.298 1.81571L18.3332 5.83331L10.298 9.8509C10.1887 9.90556 10.134 9.93289 10.0767 9.94365C10.0259 9.95317 9.97378 9.95317 9.923 9.94365C9.86567 9.93289 9.81101 9.90556 9.70169 9.8509L1.6665 5.83331Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1436_16157">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    search: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1436_16143)">
          <path
            d="M18 18L12.825 12.825M14 10C14 12.2091 12.2091 14 10 14C7.79086 14 6 12.2091 6 10C6 7.79086 7.79086 6 10 6C12.2091 6 14 7.79086 14 10Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14 19H3C1.89543 19 1 18.1046 1 17V3C1 1.89543 1.89543 1 3 1H17C18.1046 1 19 1.89543 19 3V13"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1436_16143">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  };

  return svgs[type];
};
