import MovementShiptools from "../assets/icons/MovementShiptools";
import SatelliteIcon from "../assets/icons/Satellite";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import SearchIcon from "../assets/icons/SearchIcon";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import DownloadIcon from "../assets/icons/Download";
import PathIcon from "../assets/icons/Path";
import PathAnalysisIcon from "../assets/icons/PathAnalysis";
import ExtendedPathIcon from "../assets/icons/ExtendedPath";
import PlayBackIcon from "../assets/icons/PlayBack";
import ExtendedPath from "../pages/Theia/ShipTools/ExtendedPath/ExtendedPath";
import FuturePathPrediction from "../pages/Theia/ShipTools/FuturePathPrediction/FuturePathPrediction";
import PathPlayback from "../pages/Theia/ShipTools/PathPlayback/PathPlayback";
import EstimatedLocation from "../pages/Theia/ShipTools/EstimatedLocation/EstimatedLocation";

export const ASPIRE_TYPES = [
  { label: "Special Craft" },
  { label: "Sailing Vessel" },
  { label: "Wing In Ground" },
  { label: "High-Speed Craft" },
  { label: "Search and Rescue" },
  { label: "Pleasure Craft" },
  { label: "Reserved" },
  { label: "Cargo" },
  { label: "Tug" },
  { label: "Passenger" },
  { label: "Other" },
  { label: "Sailing" },
  { label: "Tanker" },
  { label: "Fishing Vessel" },
  { label: "Fishing" },
  { label: "Passenger Ship" },
];

export const ASPIRE_TYPES_UNATTRIBUTED = [
  { label: "Cargo" },
  { label: "Tanker" },
  { label: "Passenger" },
  { label: "Military Vessel" },
  { label: "Other" },
];

export const COUNTRY_CODES = [
  {
    code: "AF",
    label: "Afghanistan",
  },
  {
    code: "AX",
    label: "Åland Islands",
  },
  {
    code: "AL",
    label: "Albania",
  },
  {
    code: "DZ",
    label: "Algeria",
  },
  {
    code: "AS",
    label: "American Samoa",
  },
  {
    code: "AD",
    label: "Andorra",
  },
  {
    code: "AO",
    label: "Angola",
  },
  {
    code: "AI",
    label: "Anguilla",
  },
  {
    code: "AQ",
    label: "Antarctica",
  },
  {
    code: "AG",
    label: "Antigua and Barbuda",
  },
  {
    code: "AR",
    label: "Argentina",
  },
  {
    code: "AM",
    label: "Armenia",
  },
  {
    code: "AW",
    label: "Aruba",
  },
  {
    code: "AU",
    label: "Australia",
  },
  {
    code: "AT",
    label: "Austria",
  },
  {
    code: "AZ",
    label: "Azerbaijan",
  },
  {
    code: "BS",
    label: "Bahamas",
  },
  {
    code: "BH",
    label: "Bahrain",
  },
  {
    code: "BD",
    label: "Bangladesh",
  },
  {
    code: "BB",
    label: "Barbados",
  },
  {
    code: "BY",
    label: "Belarus",
  },
  {
    code: "BE",
    label: "Belgium",
  },
  {
    code: "BZ",
    label: "Belize",
  },
  {
    code: "BJ",
    label: "Benin",
  },
  {
    code: "BM",
    label: "Bermuda",
  },
  {
    code: "BT",
    label: "Bhutan",
  },
  {
    code: "BO",
    label: "Bolivia, Plurinational State of",
  },
  {
    code: "BQ",
    label: "Bonaire, Sint Eustatius and Saba",
  },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
  },
  {
    code: "BW",
    label: "Botswana",
  },
  {
    code: "BV",
    label: "Bouvet Island",
  },
  {
    code: "BR",
    label: "Brazil",
  },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
  },
  {
    code: "BN",
    label: "Brunei Darussalam",
  },
  {
    code: "BG",
    label: "Bulgaria",
  },
  {
    code: "BF",
    label: "Burkina Faso",
  },
  {
    code: "BI",
    label: "Burundi",
  },
  {
    code: "KH",
    label: "Cambodia",
  },
  {
    code: "CM",
    label: "Cameroon",
  },
  {
    code: "CA",
    label: "Canada",
  },
  {
    code: "CV",
    label: "Cape Verde",
  },
  {
    code: "KY",
    label: "Cayman Islands",
  },
  {
    code: "CF",
    label: "Central African Republic",
  },
  {
    code: "TD",
    label: "Chad",
  },
  {
    code: "CL",
    label: "Chile",
  },
  {
    code: "CN",
    label: "China",
  },
  {
    code: "CX",
    label: "Christmas Island",
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
  },
  {
    code: "CO",
    label: "Colombia",
  },
  {
    code: "KM",
    label: "Comoros",
  },
  {
    code: "CG",
    label: "Congo",
  },
  {
    code: "CD",
    label: "Congo, the Democratic Republic of the",
  },
  {
    code: "CK",
    label: "Cook Islands",
  },
  {
    code: "CR",
    label: "Costa Rica",
  },
  {
    code: "CI",
    label: "Côte d'Ivoire",
  },
  {
    code: "HR",
    label: "Croatia",
  },
  {
    code: "CU",
    label: "Cuba",
  },
  {
    code: "CW",
    label: "Curaçao",
  },
  {
    code: "CY",
    label: "Cyprus",
  },
  {
    code: "CZ",
    label: "Czech Republic",
  },
  {
    code: "DK",
    label: "Denmark",
  },
  {
    code: "DJ",
    label: "Djibouti",
  },
  {
    code: "DM",
    label: "Dominica",
  },
  {
    code: "DO",
    label: "Dominican Republic",
  },
  {
    code: "EC",
    label: "Ecuador",
  },
  {
    code: "EG",
    label: "Egypt",
  },
  {
    code: "SV",
    label: "El Salvador",
  },
  {
    code: "GQ",
    label: "Equatorial Guinea",
  },
  {
    code: "ER",
    label: "Eritrea",
  },
  {
    code: "EE",
    label: "Estonia",
  },
  {
    code: "ET",
    label: "Ethiopia",
  },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
  },
  {
    code: "FO",
    label: "Faroe Islands",
  },
  {
    code: "FJ",
    label: "Fiji",
  },
  {
    code: "FI",
    label: "Finland",
  },
  {
    code: "FR",
    label: "France",
  },
  {
    code: "GF",
    label: "French Guiana",
  },
  {
    code: "PF",
    label: "French Polynesia",
  },
  {
    code: "TF",
    label: "French Southern Territories",
  },
  {
    code: "GA",
    label: "Gabon",
  },
  {
    code: "GM",
    label: "Gambia",
  },
  {
    code: "GE",
    label: "Georgia",
  },
  {
    code: "DE",
    label: "Germany",
  },
  {
    code: "GH",
    label: "Ghana",
  },
  {
    code: "GI",
    label: "Gibraltar",
  },
  {
    code: "GR",
    label: "Greece",
  },
  {
    code: "GL",
    label: "Greenland",
  },
  {
    code: "GD",
    label: "Grenada",
  },
  {
    code: "GP",
    label: "Guadeloupe",
  },
  {
    code: "GU",
    label: "Guam",
  },
  {
    code: "GT",
    label: "Guatemala",
  },
  {
    code: "GG",
    label: "Guernsey",
  },
  {
    code: "GN",
    label: "Guinea",
  },
  {
    code: "GW",
    label: "Guinea-Bissau",
  },
  {
    code: "GY",
    label: "Guyana",
  },
  {
    code: "HT",
    label: "Haiti",
  },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
  },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
  },
  {
    code: "HN",
    label: "Honduras",
  },
  {
    code: "HK",
    label: "Hong Kong",
  },
  {
    code: "HU",
    label: "Hungary",
  },
  {
    code: "IS",
    label: "Iceland",
  },
  {
    code: "IN",
    label: "India",
  },
  {
    code: "ID",
    label: "Indonesia",
  },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
  },
  {
    code: "IQ",
    label: "Iraq",
  },
  {
    code: "IE",
    label: "Ireland",
  },
  {
    code: "IM",
    label: "Isle of Man",
  },
  {
    code: "IL",
    label: "Israel",
  },
  {
    code: "IT",
    label: "Italy",
  },
  {
    code: "JM",
    label: "Jamaica",
  },
  {
    code: "JP",
    label: "Japan",
  },
  {
    code: "JE",
    label: "Jersey",
  },
  {
    code: "JO",
    label: "Jordan",
  },
  {
    code: "KZ",
    label: "Kazakhstan",
  },
  {
    code: "KE",
    label: "Kenya",
  },
  {
    code: "KI",
    label: "Kiribati",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
  },
  {
    code: "KR",
    label: "Korea, Republic of",
  },
  {
    code: "KW",
    label: "Kuwait",
  },
  {
    code: "KG",
    label: "Kyrgyzstan",
  },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
  },
  {
    code: "LV",
    label: "Latvia",
  },
  {
    code: "LB",
    label: "Lebanon",
  },
  {
    code: "LS",
    label: "Lesotho",
  },
  {
    code: "LR",
    label: "Liberia",
  },
  {
    code: "LY",
    label: "Libya",
  },
  {
    code: "LI",
    label: "Liechtenstein",
  },
  {
    code: "LT",
    label: "Lithuania",
  },
  {
    code: "LU",
    label: "Luxembourg",
  },
  {
    code: "MO",
    label: "Macao",
  },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
  },
  {
    code: "MG",
    label: "Madagascar",
  },
  {
    code: "MW",
    label: "Malawi",
  },
  {
    code: "MY",
    label: "Malaysia",
  },
  {
    code: "MV",
    label: "Maldives",
  },
  {
    code: "ML",
    label: "Mali",
  },
  {
    code: "MT",
    label: "Malta",
  },
  {
    code: "MH",
    label: "Marshall Islands",
  },
  {
    code: "MQ",
    label: "Martinique",
  },
  {
    code: "MR",
    label: "Mauritania",
  },
  {
    code: "MU",
    label: "Mauritius",
  },
  {
    code: "YT",
    label: "Mayotte",
  },
  {
    code: "MX",
    label: "Mexico",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
  },
  {
    code: "MD",
    label: "Moldova, Republic of",
  },
  {
    code: "MC",
    label: "Monaco",
  },
  {
    code: "MN",
    label: "Mongolia",
  },
  {
    code: "ME",
    label: "Montenegro",
  },
  {
    code: "MS",
    label: "Montserrat",
  },
  {
    code: "MA",
    label: "Morocco",
  },
  {
    code: "MZ",
    label: "Mozambique",
  },
  {
    code: "MM",
    label: "Myanmar",
  },
  {
    code: "NA",
    label: "Namibia",
  },
  {
    code: "NR",
    label: "Nauru",
  },
  {
    code: "NP",
    label: "Nepal",
  },
  {
    code: "NL",
    label: "Netherlands",
  },
  {
    code: "NC",
    label: "New Caledonia",
  },
  {
    code: "NZ",
    label: "New Zealand",
  },
  {
    code: "NI",
    label: "Nicaragua",
  },
  {
    code: "NE",
    label: "Niger",
  },
  {
    code: "NG",
    label: "Nigeria",
  },
  {
    code: "NU",
    label: "Niue",
  },
  {
    code: "NF",
    label: "Norfolk Island",
  },
  {
    code: "MP",
    label: "Northern Mariana Islands",
  },
  {
    code: "NO",
    label: "Norway",
  },
  {
    code: "OM",
    label: "Oman",
  },
  {
    code: "PK",
    label: "Pakistan",
  },
  {
    code: "PW",
    label: "Palau",
  },
  {
    code: "PS",
    label: "Palestine, State of",
  },
  {
    code: "PA",
    label: "Panama",
  },
  {
    code: "PG",
    label: "Papua New Guinea",
  },
  {
    code: "PY",
    label: "Paraguay",
  },
  {
    code: "PE",
    label: "Peru",
  },
  {
    code: "PH",
    label: "Philippines",
  },
  {
    code: "PN",
    label: "Pitcairn",
  },
  {
    code: "PL",
    label: "Poland",
  },
  {
    code: "PT",
    label: "Portugal",
  },
  {
    code: "PR",
    label: "Puerto Rico",
  },
  {
    code: "QA",
    label: "Qatar",
  },
  {
    code: "RE",
    label: "Réunion",
  },
  {
    code: "RO",
    label: "Romania",
  },
  {
    code: "RU",
    label: "Russian Federation",
  },
  {
    code: "RW",
    label: "Rwanda",
  },
  {
    code: "BL",
    label: "Saint Barthélemy",
  },
  {
    code: "SH",
    label: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
  },
  {
    code: "LC",
    label: "Saint Lucia",
  },
  {
    code: "MF",
    label: "Saint Martin (French part)",
  },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
  },
  {
    code: "WS",
    label: "Samoa",
  },
  {
    code: "SM",
    label: "San Marino",
  },
  {
    code: "ST",
    label: "Sao Tome and Principe",
  },
  {
    code: "SA",
    label: "Saudi Arabia",
  },
  {
    code: "SN",
    label: "Senegal",
  },
  {
    code: "RS",
    label: "Serbia",
  },
  {
    code: "SC",
    label: "Seychelles",
  },
  {
    code: "SL",
    label: "Sierra Leone",
  },
  {
    code: "SG",
    label: "Singapore",
  },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
  },
  {
    code: "SK",
    label: "Slovakia",
  },
  {
    code: "SI",
    label: "Slovenia",
  },
  {
    code: "SB",
    label: "Solomon Islands",
  },
  {
    code: "SO",
    label: "Somalia",
  },
  {
    code: "ZA",
    label: "South Africa",
  },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
  },
  {
    code: "SS",
    label: "South Sudan",
  },
  {
    code: "ES",
    label: "Spain",
  },
  {
    code: "LK",
    label: "Sri Lanka",
  },
  {
    code: "SD",
    label: "Sudan",
  },
  {
    code: "SR",
    label: "Surilabel",
  },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
  },
  {
    code: "SZ",
    label: "Swaziland",
  },
  {
    code: "SE",
    label: "Sweden",
  },
  {
    code: "CH",
    label: "Switzerland",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
  },
  {
    code: "TW",
    label: "Taiwan, Province of China",
  },
  {
    code: "TJ",
    label: "Tajikistan",
  },
  {
    code: "TZ",
    label: "Tanzania, United Republic of",
  },
  {
    code: "TH",
    label: "Thailand",
  },
  {
    code: "TL",
    label: "Timor-Leste",
  },
  {
    code: "TG",
    label: "Togo",
  },
  {
    code: "TK",
    label: "Tokelau",
  },
  {
    code: "TO",
    label: "Tonga",
  },
  {
    code: "TT",
    label: "Trinidad and Tobago",
  },
  {
    code: "TN",
    label: "Tunisia",
  },
  {
    code: "TR",
    label: "Turkey",
  },
  {
    code: "TM",
    label: "Turkmenistan",
  },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
  },
  {
    code: "TV",
    label: "Tuvalu",
  },
  {
    code: "UG",
    label: "Uganda",
  },
  {
    code: "UA",
    label: "Ukraine",
  },
  {
    code: "AE",
    label: "United Arab Emirates",
  },
  {
    code: "GB",
    label: "United Kingdom",
  },
  {
    code: "US",
    label: "United States",
  },
  {
    code: "UM",
    label: "United States Minor Outlying Islands",
  },
  {
    code: "UY",
    label: "Uruguay",
  },
  {
    code: "UZ",
    label: "Uzbekistan",
  },
  {
    code: "VU",
    label: "Vanuatu",
  },
  {
    code: "VE",
    label: "Venezuela, Bolivarian Republic of",
  },
  {
    code: "VN",
    label: "Viet Nam",
  },
  {
    code: "VG",
    label: "Virgin Islands, British",
  },
  {
    code: "VI",
    label: "Virgin Islands, U.S.",
  },
  {
    code: "WF",
    label: "Wallis and Futuna",
  },
  {
    code: "EH",
    label: "Western Sahara",
  },
  {
    code: "YE",
    label: "Yemen",
  },
  {
    code: "ZM",
    label: "Zambia",
  },
  {
    code: "ZW",
    label: "Zimbabwe",
  },
];

export const FILTER_ATTRIBUTES = [
  {
    type: "shipSVG",
    text: "AIS",
    color: "#00EB6C",
    stroke: "#201F1F",
  },
  {
    type: "shipSVG",
    text: "light",
    color: "#0094FF",
    stroke: "#201F1F",
  },

  {
    type: "shipSVG",
    text: "dark",
    color: "#FFA949",
    stroke: "#201F1F",
  },
  {
    type: "spoofing",
    text: "spoofing",
  },
  {
    type: "shipSVG",
    text: "unattributed",
    color: "#FA5849",
    stroke: "#201F1F",
  },
  {
    type: "bunkering",
    text: "bunkering",
  },
  {
    type: "sanctioned",
    text: "sanctioned",
  },
  {
    type: "similar",
    text: "similar",
  },
];

export const SHIP_MARKERS_EVENTS = [
  {
    EventName: "by ship event type",
  },
  {
    EventName: "by ship size",
    disabled: true,
  },
  {
    EventName: "by ship type",
    disabled: true,
  },
  {
    EventName: "by ship speeds",
    disabled: true,
  },
  {
    EventName: "by ship event destinations",
    disabled: true,
  },
];

export const SHIP_TOOL_DROPDOWN: any = [
  // {
  //   title: "View path",
  //   icon: <PathIcon />,
  //   value: "path",
  // },
  {
    title: "View path analysis",
    icon: <PathAnalysisIcon />,
    value: "pathAnalysis",
  },
  {
    title: "View extended path",
    icon: <ExtendedPathIcon />,
    value: "extendedPath",
  },
  {
    title: "View path playback",
    icon: <PlayBackIcon />,
    value: "pathPlayback",
  },
  {
    title: "View future path prediction",
    icon: <UpdateOutlinedIcon />,
    value: "futurePrediction",
  },
  {
    title: "Show estimated location",
    icon: <MovementShiptools />,
    value: "estimatedLocation",
  },
  {
    title: "Download path in XLS",
    icon: <DownloadIcon />,
    value: "downloadXLS",
  },
];

export const SATELLITE_IMAGERY_TOOLS = [
  // {
  //   title: "View satellite imagery",
  //   icon: <SatelliteIcon />,
  //   value: "satelliteImage",
  // },
  {
    title: "Satellite imagery timeline",
    icon: <GridViewOutlinedIcon />,
    value: "satelliteTimeline",
  },
  // {
  //   title: "Task new satellite imagery",
  //   icon: <SearchIcon />,
  //   value: "NewsatelliteImage",
  // },
];

export const FUTURE_PATH_PREDICITION_TYPE = [
  { value: "tanker", label: "Tanker" },
  { value: "bunker", label: "Bunker" },
  { value: "cargo", label: "Cargo" },
  { value: "other", label: "Other" },
];

export const MAP_PATHTOOLS_OPTIONS_TO_TITLE: any = {
  extendedPath: "Extended path",
  futurePrediction: "Future Path Prediction",
  pathPlayback: "Path Playback",
  estimatedLocation: "Estimated Location",
};

export const CUSTOM_SLIDER_MENU_ITEMS = [
  { value: "600", label: "1x" },
  { value: "200", label: "2x" },
  { value: "0", label: "3x" },
];

export const GET_SHIP_TOOLS_COMPONENT: any = {
  // extendedPath: () => <ExtendedPath />,
  futurePrediction: ({
    shipDetailTabValue,
    shipDetailsTabs,
    bunkeringValue,
  }: any) => (
    <FuturePathPrediction
      shipDetailTabValue={shipDetailTabValue}
      shipDetailsTabs={shipDetailsTabs}
      bunkeringValue={bunkeringValue}
    />
  ),
  //pathPlayback: () => <PathPlayback />,
  estimatedLocation: () => <EstimatedLocation />,
};

export const GET_SHIP_TOOLS_COMPONENT_ICON: any = {
  extendedPath: <ExtendedPathIcon />,
  futurePrediction: <UpdateOutlinedIcon />,
  pathPlayback: <PlayBackIcon />,
  estimatedLocation: <MovementShiptools />,
};

export const WORKSPACE_MODE = ["cloud", "incognito", "local"];

export const SHIP_DATA_SOURCE = [
  { label: "SAR", value: "sar", checked: true, disabled: false },
  { label: "Optical", value: "optical", checked: true, disabled: false },
];

export interface ProviderFilters {
  checked: boolean;
  type: string;
  label: string;
  value: string;
  disabled: boolean;
}

export const SHIP_SERVICE_PROVIDER_LIST: ProviderFilters[] = [
  {
    checked: true,
    type: "optical",
    label: "PlanetScope",
    value: "planetscope",
    disabled: false,
  },
  {
    checked: true,
    type: "sar",
    label: "Sentinel-1",
    value: "sentinel1",
    disabled: false,
  },
  {
    checked: true,
    type: "optical",
    label: "Sentinel-2",
    value: "sentinel2",
    disabled: false,
  },
];

export const SHIP_FILTER_PROVIDER = ["light", "dark", "unattributed"];

export const INITIAL_STATE_FOR_FILTER_PROVIDER = {
  planetscope: true,
  sentinel1: true,
  sentinel2: true,
};

export const SHIP_MOVING_OPTIONS = [
  { value: "none", label: "None" },
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];
