import {
  RefObject,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { GeoJsonLayer } from "@deck.gl/layers/typed";
import { MapRef } from "react-map-gl";
import { defaultImplementation } from "../utils/utilis";
import { LayerToggleSwitches } from "../types/types";
import { Dayjs } from "dayjs";

interface IGlobalFiltersProviderProps {
  children: React.ReactNode;
}

const DEFAULT_LAYER_TOGGLE: LayerToggleSwitches = {
  territories: false,
  ports: false,
  infrastructure: false,
  submarineCablesVisible: false,
  coverage: false,
  highRisk: false,
};

interface IGlobalFiltersContext {
  layerToggleSwitches: LayerToggleSwitches;
  showCursor: boolean;
  setShowCursor: React.Dispatch<React.SetStateAction<boolean>>;
  onLayerToggle: (key: keyof LayerToggleSwitches) => void;
  date: Dayjs | null;
  setDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
}

const GlobalFiltersContext = createContext<IGlobalFiltersContext>({
  layerToggleSwitches: DEFAULT_LAYER_TOGGLE,
  showCursor: false,
  setShowCursor: defaultImplementation,
  onLayerToggle: defaultImplementation,
  date: null,
  setDate: defaultImplementation,
});

const useGlobalFilters = () => {
  const context = useContext<IGlobalFiltersContext>(GlobalFiltersContext);
  if (!context) {
    throw new Error(
      "useGlobalFilters must be used with a GlobalFiltersProvider"
    );
  }
  return context;
};

export const GlobalFiltersProvider = ({
  children,
}: IGlobalFiltersProviderProps) => {
  const [showCursor, setShowCursor] = useState<boolean>(false);
  const [layerToggleSwitches, setLayerToggleSwitches] =
    useState<LayerToggleSwitches>(DEFAULT_LAYER_TOGGLE);
  const [date, setDate] = useState<Dayjs | null>(null);

  const onLayerToggle = (key: keyof LayerToggleSwitches) => {
    setLayerToggleSwitches((prevState: LayerToggleSwitches) => {
      return {
        ...prevState,
        [key]: !prevState[key],
      };
    });
  };
  return (
    <GlobalFiltersContext.Provider
      value={{
        layerToggleSwitches,
        showCursor,
        setShowCursor,
        onLayerToggle,
        date,
        setDate,
      }}
    >
      {children}
    </GlobalFiltersContext.Provider>
  );
};

export default useGlobalFilters;
