import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";
import styles from "./AllSearchResultsDialog.module.scss";
import {
  FilterOption,
  FilterType,
  ObjectSearchOptionResult,
  VesselSearcOptionResult,
} from "./useSearch";
import AllResultsTable from "./SearchResultsTable";

const SelectFilterButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: 0,
  minWidth: "max-content",
  whiteSpace: "nowrap",
}));

const AllSearchResultsDialog: React.FC<{
  showResultsDialogIsOpen: boolean;
  handleCloseAllResultsDialog: () => void;
  filterOptions: FilterOption[];
  selectedFilter: FilterOption;
  setSelectedFilter: (newState: FilterOption) => void;
  currentSearchValue: string;
  searchTermRefValue: string | null;
  loadShipsOnMap: (
    isAISSearch: boolean,
    searchValue: ObjectSearchOptionResult | VesselSearcOptionResult,
    date: string|undefined
  ) => void;
  currentSearchCounts: { vesselsTotal: number; objectsTotal: number };
  date:string| undefined
}> = ({
  showResultsDialogIsOpen,
  handleCloseAllResultsDialog,
  filterOptions,
  selectedFilter,
  setSelectedFilter,
  currentSearchValue,
  searchTermRefValue,
  currentSearchCounts,
  loadShipsOnMap,
  date
}) => {
  return (
    <Draggable>
      <Dialog
        open={showResultsDialogIsOpen}
        onClose={handleCloseAllResultsDialog}
        PaperProps={{
          style: {
            backgroundColor: "#24263C",
            borderRadius: 0,
          },
        }}
        componentsProps={{
          backdrop: {
            style: { backgroundColor: "transparent" },
          },
        }}
        className={styles.allResultsDialog}
        maxWidth="md"
        fullWidth
        disableRestoreFocus
      >
        <DialogTitle className={styles.dialogTitle}>
          <div className={styles.dialogTitleHeader}>
            <h3 className={styles.dialogTitleText}>
              Search Results For "{searchTermRefValue}"
            </h3>
            <IconButton
              sx={{ ml: "auto", color: "#ffffff" }}
              onClick={handleCloseAllResultsDialog}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>

          <div className={styles.dialogContentButtons}>
            {filterOptions.map((option: FilterOption) => {
              const selectedButtonColor =
                selectedFilter === option
                  ? { borderBottom: "2px solid #ffffff", color: "#ffffff" }
                  : { borderBottom: "1px solid gray", color: "gray" };
              return (
                <SelectFilterButton
                  type="button"
                  variant="text"
                  sx={selectedButtonColor}
                  onClick={() => {
                    setSelectedFilter(option);
                  }}
                  key={JSON.stringify(option)}
                >
                  {`${option.filterType} (${
                    option.filterType === "Vessels"
                      ? currentSearchCounts.vesselsTotal
                      : currentSearchCounts.objectsTotal
                  })`}
                </SelectFilterButton>
              );
            })}
            <div className={styles.dialogContentDivider}></div>
          </div>
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <AllResultsTable
            date={date}
            searchTermRefValue={searchTermRefValue}
            selectedFilter={selectedFilter}
            loadShipsOnMap={loadShipsOnMap}
            columns={
              selectedFilter.filterType === FilterType.Vessel
                ? [
                    { title: "Name", field: "name" },
                    { title: "Ctry", field: "flag" },
                    { title: "Type", field: "ship_type" },
                    { title: "IMO", field: "imo" },
                    { title: "MMSI", field: "mmsi" },
                    { title: "Call Sign", field: "call_sign" },
                    { title: "Length", field: "length" },
                    { title: "Width", field: "width" },
                  ]
                : [
                    { title: "Acquired", field: "acquired" },
                    { title: "Type", field: "ship_type" },
                    { title: "IMO", field: "imo" },
                    { title: "Status", field: "status" },
                    { title: "Provider", field: "provider" },
                    { title: "Length", field: "length" },
                    { title: "Width", field: "width" },
                  ]
            }
            icon={
              selectedFilter.filterType === FilterType.Vessel
                ? "shipBow"
                : "satellite"
            }
            currentSearchValue={currentSearchValue}
          />
        </DialogContent>
      </Dialog>
    </Draggable>
  );
};

export default AllSearchResultsDialog;
