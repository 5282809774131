import { useEffect } from "react";
import { copyCoordinatesToClipboard } from "../utils";

const useCopyCoordinates = (coordinates: [number, number] | null) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === "c" && coordinates) {
        copyCoordinatesToClipboard(coordinates);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [coordinates]);
};

export default useCopyCoordinates;
