import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";

interface UserType {
  email: string;
  isNewUser: boolean;
  lastName: string;
  role: string;
  firstName: string;
  uid: string;
}

export const theiaSlice = createSlice({
  name: "theia",
  initialState: {
    detections: null as any,
    spoofingEvents: null as any,
    AISSTS: null as any,
    opticalSTS: null as any,
    sanctionedShips: null as any,
    intervalIsSet: false,
    changesSaved: true,
  },
  reducers: {
    setDetections: (state, action) => {
      state.detections = action.payload;
    },
    setSpoofingEvents: (state, action) => {
      state.spoofingEvents = action.payload;
    },
    setAISSTSEvents: (state, action) => {
      state.AISSTS = action.payload;
    },
    setOpticalSTSEvents: (state, action) => {
      state.opticalSTS = action.payload;
    },
    setSanctionedShips: (state, action) => {
      state.sanctionedShips = action.payload;
    },
    changeIntervalStatus: (state, action) => {
      state.intervalIsSet = action.payload;
    },
    setChangeSaved: (state, action) => {
      state.changesSaved = action.payload;
    },
  },
});

export const {
  setDetections,
  changeIntervalStatus,
  setChangeSaved,
  setSpoofingEvents,
  setAISSTSEvents,
  setOpticalSTSEvents,
  setSanctionedShips,
} = theiaSlice.actions;

export const theiaReducer = theiaSlice.reducer;
