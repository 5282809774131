import { useEffect, useState } from "react";
import { allPorts } from "../GeoJSONLayers/portData";
import { seaOfAzov } from "../GeoJSONLayers/seaOfAzov";
import { gulfOfGuinea } from "../GeoJSONLayers/gulfOfGuinea";
import { indianOcean } from "../GeoJSONLayers/inidanOcean";
import { persianGulf } from "../GeoJSONLayers/persianGulf";
import { daboDelgado } from "../GeoJSONLayers/daboDelgado";
import { globalTerritories } from "../GeoJSONLayers/territories";
import { aisStations } from "../GeoJSONLayers/aisStationData";
import { elecLink } from "../GeoJSONLayers/elecLink";
import { nemoLink } from "../GeoJSONLayers/nemoLink";
import { submarineCables } from "../GeoJSONLayers/submarine_cables";
import { NSL } from "../GeoJSONLayers/NSL";
import { IFA } from "../GeoJSONLayers/IFA";
import { IFA2 } from "../GeoJSONLayers/IFA2";
import { britNed } from "../GeoJSONLayers/britNed";
import { GeoJsonLayer } from "@deck.gl/layers/typed";
import { getIconHeading, hexToRgb } from "../utils";
import useGlobalFilters from "../../../../context/useGlobalFilter";
import useCurrentMapState from "../../../../context/useCurrentMapState";

const useStaticGeoJson = () => {
  const { layerToggleSwitches } = useGlobalFilters();
  const { zoomLevel } = useCurrentMapState();
  const [staticLayers, setStaticLayers] = useState<GeoJsonLayer[]>([]);

  //TODO: Modify static file to persist coords to float instead of string
  aisStations.features = aisStations.features.map((feature: any) => ({
    ...feature,
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(feature.geometry.coordinates[0]),
        parseFloat(feature.geometry.coordinates[1]),
      ],
    },
  }));

  useEffect(() => {
    setStaticLayers([
      new GeoJsonLayer({
        id: "submarineCables",
        data: submarineCables as any,
        pickable: true,
        stroked: false,
        filled: true,
        extruded: true,
        lineWidthScale: 20,
        lineWidthMinPixels: 8,
        getLineColor: hexToRgb("#a8a8a8") as any,
        getRadius: 100,
        getLineWidth: 1,
        getElevation: 30,
        visible: layerToggleSwitches.submarineCablesVisible,
      }),
      new GeoJsonLayer({
        id: "elecLink-submarineCables",
        data: elecLink as any,
        pickable: true,
        stroked: false,
        filled: true,
        extruded: true,
        lineWidthScale: 20,
        lineWidthMinPixels: 8,
        getLineColor: hexToRgb("#168e9e") as any,
        getRadius: 100,
        getLineWidth: 1,
        getElevation: 30,
        visible: layerToggleSwitches.submarineCablesVisible,
      }),
      new GeoJsonLayer({
        id: "nemoLink-submarineCables",
        data: nemoLink as any,
        pickable: true,
        stroked: false,
        filled: true,
        extruded: true,
        lineWidthScale: 20,
        lineWidthMinPixels: 8,
        getLineColor: hexToRgb("#38e4ae") as any,
        getRadius: 100,
        getLineWidth: 1,
        getElevation: 30,
        visible: layerToggleSwitches.submarineCablesVisible,
      }),
      new GeoJsonLayer({
        id: "nslk-submarineCables",
        data: NSL as any,
        pickable: true,
        stroked: false,
        filled: true,
        extruded: true,
        lineWidthScale: 20,
        lineWidthMinPixels: 8,
        getLineColor: hexToRgb("#F85348") as any,
        getRadius: 100,
        getLineWidth: 1,
        getElevation: 30,
        visible: layerToggleSwitches.submarineCablesVisible,
      }),
      new GeoJsonLayer({
        id: "ifa-submarineCables",
        data: IFA as any,
        pickable: true,
        stroked: false,
        filled: true,
        extruded: true,
        lineWidthScale: 20,
        lineWidthMinPixels: 8,
        getLineColor: hexToRgb("#FFC0CB") as any,
        getRadius: 100,
        getLineWidth: 1,
        getElevation: 30,
        visible: layerToggleSwitches.submarineCablesVisible,
      }),
      new GeoJsonLayer({
        id: "ifa-two-submarineCables",
        data: IFA2 as any,
        pickable: true,
        stroked: false,
        filled: true,
        extruded: true,
        lineWidthScale: 20,
        lineWidthMinPixels: 8,
        getLineColor: hexToRgb("#800080") as any,
        getRadius: 100,
        getLineWidth: 1,
        getElevation: 30,
        visible: layerToggleSwitches.submarineCablesVisible,
      }),
      new GeoJsonLayer({
        id: "britNed-submarineCables",
        data: britNed as any,
        pickable: true,
        stroked: false,
        filled: true,
        extruded: true,
        lineWidthScale: 20,
        lineWidthMinPixels: 8,
        getLineColor: hexToRgb("#fa9f42") as any,
        getRadius: 100,
        getLineWidth: 1,
        getElevation: 30,
        visible: layerToggleSwitches.submarineCablesVisible,
      }),
      new GeoJsonLayer({
        id: "high-risk-seaOfAzov-layer",
        data: seaOfAzov,
        pickable: true,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        getLineColor: hexToRgb("#FF0000") as any,
        getFillColor: hexToRgb("#FF0000") as any,
        getLineWidth: 1,
        opacity: 0.3,
        visible: layerToggleSwitches.highRisk,
      }),
      new GeoJsonLayer({
        id: "high-risk-gulfOfGuinea-layer",
        data: gulfOfGuinea,
        pickable: true,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        getLineColor: hexToRgb("#FF0000") as any,
        getFillColor: hexToRgb("#FF0000") as any,
        getLineWidth: 1,
        opacity: 0.3,
        visible: layerToggleSwitches.highRisk,
      }),
      new GeoJsonLayer({
        id: "high-risk-indianOcean-layer",
        data: indianOcean,
        pickable: true,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        getLineColor: hexToRgb("#FF0000") as any,
        getFillColor: hexToRgb("#FF0000") as any,
        getLineWidth: 1,
        opacity: 0.3,
        visible: layerToggleSwitches.highRisk,
      }),
      new GeoJsonLayer({
        id: "high-risk-persian-gulf-layer",
        data: persianGulf,
        pickable: true,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        getLineColor: hexToRgb("#FF0000") as any,
        getFillColor: hexToRgb("#FF0000") as any,
        getLineWidth: 1,
        opacity: 0.3,
        visible: layerToggleSwitches.highRisk,
      }),
      new GeoJsonLayer({
        id: "high-risk-daboDelgado-layer",
        data: daboDelgado,
        pickable: true,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        getLineColor: hexToRgb("#FF0000") as any,
        getFillColor: hexToRgb("#FF0000") as any,
        getLineWidth: 1,
        opacity: 0.3,
        visible: layerToggleSwitches.highRisk,
      }),
      new GeoJsonLayer({
        id: "territories-layer",
        data: globalTerritories,
        pickable: true,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        getLineColor: hexToRgb("#285eb5") as any,
        getFillColor: hexToRgb("#285eb5") as any,
        getLineWidth: 1,
        opacity: 0.1,
        visible: layerToggleSwitches.territories,
      }),
      new GeoJsonLayer({
        id: "ports",
        data: allPorts as any,
        pickable: true,
        pointType: "icon",
        getIconSize: zoomLevel * 10,
        getIconAngle: (f: any) => getIconHeading(f.properties.heading || 0),
        getIconColor: hexToRgb("#FF6B48") as any,
        // TODO: fix icon so color is not hard coded
        getIcon: (f: any) => ({
          url: "./port_default.svg",
          height: 191,
          width: 102,
          mask: true,
        }),
        visible: layerToggleSwitches.ports,
      }),
      new GeoJsonLayer({
        id: "infastructure-layer",
        data: aisStations as any,
        pickable: true,
        pointType: "icon",
        getIconSize: zoomLevel * 10,
        getIconAngle: (f: any) => getIconHeading(f.properties.heading || 0),
        getIconColor: hexToRgb("#36a879") as any,
        // TODO: fix icon so color is not hard coded
        getIcon: (f: any) => ({
          url: "./infrastructure.svg",
          height: 191,
          width: 102,
          mask: true,
        }),
        visible: layerToggleSwitches.infrastructure,
      }),
    ]);
  }, [zoomLevel, layerToggleSwitches]);

  return { staticLayers };
};

export default useStaticGeoJson;
