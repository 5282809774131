import { PickingInfo } from "@deck.gl/core/typed";

const getTooltip = (info: PickingInfo) => {
  const { object } = info;
  // Intentionally leaving this here so we can fill it in later
  const toolTip = () => {
    if (object && object.properties) {
      if (object.properties.portname) {
        return `<div>${object.properties.portname}</div>`;
      }
      if (object.properties.location) {
        return `<div>${object.properties.location}</div>`;
      }
      if (object.properties.Country) {
        return `<div>${object.properties.Country}</div>`;
      }
      if (object.properties.name) {
        return `<div>${object.properties.name}</div>`;
      }
    }
    return `<div>
    </div>`;
  };

  return (
    object && {
      html: toolTip(),
    }
  );
};

export default getTooltip;
