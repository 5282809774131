import { useEffect, useState } from "react";
import { ProviderFilters, SHIP_DATA_SOURCE, SHIP_SERVICE_PROVIDER_LIST } from "../../../../utils/Constants";
import { FilterParameters } from "../FilterArea/FilterArea";

const useShipFilters = (currentFilter: FilterParameters, currentEventTypeFilter: string | null) => {
  const [currentSelectedShipColor, setCurrentSelectedShipColor] =
    useState<string>(currentFilter?.markerColor);

  const [countryFilter, setCountryFilter] = useState<{
    code: string;
    label: string;
  }>(currentFilter?.country);

  const [shipTypeFilters, setShipTypeFilters] = useState({ label: currentFilter?.type === "None" ? "" : currentFilter?.type });

  const [shipLengthFilter, setShipLengthFilter] = useState<number[]>(
    currentFilter?.length
  );

  const [shipWidthFilter, setShipWidthFilter] = useState<number[]>(
    currentFilter?.width
  );

  const [headingFilter, setHeadingFilter] = useState<number[]>(
    currentFilter?.heading
  );

  const [noImoShips, setNoIMOShips] = useState<boolean>(
    currentFilter?.noImoShips
  );

  const [showOnlySanctionedShip, setShowOnlySanctionedShips] =
    useState<boolean>(currentFilter?.sanctionedShips);

  const [footprintSpoofingIsEnabled, setFootpirintSpoofingISEnabled] =
    useState<boolean>(
      currentFilter?.subType && currentFilter?.subType["footprint-spoofing"]
        ? currentFilter?.subType["footprint-spoofing"]
        : false
    );

  const [opticalSpoofingIsEnabled, setOpticalSpoofingISEnabled] =
    useState<boolean>(
      currentFilter?.subType && currentFilter?.subType["optical-spoofing"]
        ? currentFilter?.subType["optical-spoofing"]
        : false
    );

  const [aisSpoofingIsEnabled, setAisSpoofingIsEnabled] = useState(
    currentFilter?.subType && currentFilter?.subType["ais-spoofing"]
      ? currentFilter?.subType["ais-spoofing"]
      : false
  );

  const [isMovingFilter, setIsMovingFiler] = useState(
    currentFilter?.isMoving || "none"
  );

  const [providerList, setProviderList] = useState<string[]>([]);

  const [providerData, setProviderData] = useState<ProviderFilters[]>([]);

  const defaultDataSource = SHIP_DATA_SOURCE.map((item) => item.label);

  const [shipDataSourceList, setShipDataSourceList] =
    useState<string[]>(defaultDataSource);
  const [shipDataSourceStatus, setShipDataSourceStatus] =
    useState(SHIP_DATA_SOURCE);


    useEffect(() => {
      const sentinel1 = providerData.find(
        (provider) => provider.label === "Sentinel-1"
      );
      if (shipDataSourceList.includes("SAR") && sentinel1) {
        sentinel1.disabled = false;
      } else if (sentinel1) {
        sentinel1.checked = false;
        sentinel1.disabled = true;
      }
  
      const sentinel2 = providerData.find(
        (provider) => provider.label === "Sentinel-2"
      );
      const planetscope = providerData.find(
        (provider) => provider.label === "PlanetScope"
      );
  
      if (shipDataSourceList.includes("Optical") && sentinel2 && planetscope) {
        sentinel2.disabled = false;
        planetscope.disabled = false;
      } else if (sentinel2 && planetscope) {
        sentinel2.disabled = true;
        planetscope.disabled = true;
        sentinel2.checked = false;
        planetscope.checked = false;
      }
      if (sentinel1 && sentinel2 && planetscope) {
        const updatedProviderData: ProviderFilters[] = [sentinel1, sentinel2, planetscope];
        if (updatedProviderData !== undefined) {
          setProviderData(updatedProviderData);
          setProviderList(
            updatedProviderData
              .filter((provider) => provider.checked)
              .map((provider) => provider.label)
          );
        }
      }
    }, [shipDataSourceList]);
  
    useEffect(() => {
      const setDataSourceAndPRoviderValues = () => {
        setProviderList(SHIP_SERVICE_PROVIDER_LIST.filter((value) => currentFilter?.providerFilters[value.value]).map((filter) => filter.label))
        setProviderData(SHIP_SERVICE_PROVIDER_LIST.map((item) => {
          return {...item, checked:  currentFilter?.providerFilters[item.value] }
        }));
        
        let sarOptionIndex = 0;
        const sarOption = shipDataSourceStatus.find((option, index) => {
          if (option.label === "SAR") {
            sarOptionIndex = index;
            return option;
          }
        });
        
        let opticalOptionIndex = 0;
        const opticalOption = shipDataSourceStatus.find((option, index) => {
          if (option.label === "Optical") {
            opticalOptionIndex = index;
            return option;
          }
        });
        
        if (!currentFilter?.providerFilters.planetscope && !currentFilter?.providerFilters.sentinel2 && opticalOption) {
          setShipDataSourceList(defaultDataSource.filter((label) => label !== "Optical"));
          opticalOption.checked = false;
        } else if (opticalOption) {
          opticalOption.checked = true;
        }

        if (!currentFilter?.providerFilters.sentinel1 && sarOption) {
          setShipDataSourceList(defaultDataSource.filter((label) => label !== "SAR"));
          sarOption.checked = false;
        } else if (sarOption) {
          sarOption.checked = true;
        }
        if (currentEventTypeFilter === "dark" && sarOption) {
          sarOption.disabled = true;
          sarOption.checked = false;
          setShipDataSourceList((preVal) =>
            preVal.filter((value: string) => value !== "SAR")
          );
        } else if (sarOption) {
          sarOption.disabled = false;
        }
        const updatedSource = [...shipDataSourceStatus];
        if (sarOption && opticalOption) {
          updatedSource[sarOptionIndex] = sarOption;
          updatedSource[opticalOptionIndex] = opticalOption; 
        }
        setShipDataSourceStatus(updatedSource)
      }
      
      if (currentFilter?.providerFilters) {
        setDataSourceAndPRoviderValues();
      }  
    }, [currentFilter, currentEventTypeFilter]);

    return {
      setProviderList,
      providerData,
      setProviderData,
      setShipDataSourceList,
      setShipDataSourceStatus,
      setCountryFilter,
      setShipTypeFilters,
      setShipLengthFilter,
      setShipWidthFilter,
      setShowOnlySanctionedShips,
      setFootpirintSpoofingISEnabled,
      setOpticalSpoofingISEnabled,
      setAisSpoofingIsEnabled,
      setIsMovingFiler,
      setHeadingFilter,
      setNoIMOShips,
      setCurrentSelectedShipColor,
      providerList,
      currentSelectedShipColor,
      countryFilter,
      shipLengthFilter,
      shipWidthFilter,
      noImoShips,
      showOnlySanctionedShip,
      isMovingFilter,
      headingFilter,
      shipTypeFilters,
      aisSpoofingIsEnabled,
      footprintSpoofingIsEnabled,
      opticalSpoofingIsEnabled,
      shipDataSourceList,
      shipDataSourceStatus
    }
};

export default useShipFilters;
