import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useRef,
  useState,
} from "react";
import { defaultImplementation } from "../utils/utilis";
import { Dayjs } from "dayjs";

const INITIAL_MAP_VIEW_STATE = {
  latitude: 51.47,
  longitude: 0.45,
  zoom: 4,
};

const INITIAL_MAP_LAT_LONG = { lat: 51, lng: 2 };

interface MainMapContextProps {
  zoomLevel: number;
  setZoomLevel: Dispatch<SetStateAction<number>>;
  mapLatLng: { lat: number; lng: number };
  setMapLatLng: Dispatch<SetStateAction<{ lat: number; lng: number }>>;
  mapSnapshot: string;
  setMapSnapshot: Dispatch<SetStateAction<string>>;
  deckCanvas: any;
  setDeckCanvas: Dispatch<SetStateAction<any>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setMapViewState: Dispatch<
    SetStateAction<{
      latitude: number;
      longitude: number;
      zoom: number;
    }>
  >;
  mapViewState: {
    latitude: number;
    longitude: number;
    zoom: number;
  };
  mapRef: any;
}

const MainMapContext = createContext<MainMapContextProps>({
  zoomLevel: 4,
  setZoomLevel: defaultImplementation,
  mapLatLng: INITIAL_MAP_LAT_LONG,
  setMapLatLng: defaultImplementation,
  mapSnapshot: "",
  setMapSnapshot: defaultImplementation,
  deckCanvas: "",
  setDeckCanvas: defaultImplementation,
  loading: false,
  setLoading: defaultImplementation,
  setMapViewState: defaultImplementation,
  mapViewState: INITIAL_MAP_VIEW_STATE,
  mapRef: null,
});

const useCurrentMapState = () => {
  const context = useContext<MainMapContextProps>(MainMapContext);
  if (!context) {
    throw new Error("useCurrentMapState must be used with a MainMapProvider");
  }
  return context;
};

export const MainMapProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [zoomLevel, setZoomLevel] = useState<number>(4);
  const [mapLatLng, setMapLatLng] = useState(INITIAL_MAP_LAT_LONG);
  const [mapSnapshot, setMapSnapshot] = useState<string>("");
  const [deckCanvas, setDeckCanvas] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [mapViewState, setMapViewState] = useState(INITIAL_MAP_VIEW_STATE);
  const mapRef = useRef<any>(null);

  return (
    <MainMapContext.Provider
      value={{
        zoomLevel,
        setZoomLevel,
        mapLatLng,
        setMapLatLng,
        mapSnapshot,
        setMapSnapshot,
        deckCanvas,
        setDeckCanvas,
        loading,
        setLoading,
        setMapViewState,
        mapViewState,
        mapRef,
      }}
    >
      {children}
    </MainMapContext.Provider>
  );
};

export default useCurrentMapState;
