import React, { FC, useState, useEffect } from "react";
import { AttributionInterface } from "../Attribution";
import { Box, Button, Modal, TextField, FormControlLabel, Checkbox, Autocomplete, CircularProgress } from "@mui/material";
import styles from "./ModalAttribution.module.scss";
import { styled } from '@mui/system';
import { searchShips } from "../../../RenderShips/apiCalls";
import { debounce } from "lodash";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';

interface ModalAttribution {
  openManualAttributionModal: boolean;
  handleSave: (attribution: any) => void;
  handleClose: () => void;
  activeCall: boolean;
  // setManualAttribution: React.Dispatch<React.SetStateAction<AttributionInterface>>;
  ManualAttribution: AttributionInterface | null;
}

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: 'white', // This styles the label
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


const shipTypes = [
  { value: "none", label: "None" },
  { value: "Cargo", label: "Cargo" },
  { value: "Tug", label: "Tug" },
  { value: "Other", label: "Other" },
  { value: "Passenger", label: "Passenger" },
  { value: "Sailing", label: "Sailing" },
  { value: "Tanker", label: "Tanker" },
  { value: "Fishing", label: "Fishing" },
  { value: "Pleasure Craft'", label: "Pleasure Craft" },
]

// eslint-disable-next-line @typescript-eslint/no-redeclare
const ModalAttribution: FC<ModalAttribution> = ({
  openManualAttributionModal,
  handleClose,
  handleSave,
  ManualAttribution,
  activeCall
}) => {


  const [length, setLength] = useState<string | undefined>("");
  const [heading, setHeading] = useState<string | undefined>("");
  const [shiptype, setShiptype] = useState<{ value: string; label: string; } | null>(null);
  const [searchOptions, setSearchOptions] = useState<any>([])
  const [selectedValue, setSelectedValue] = useState<any>()
  const [moving, setMoving] = useState<boolean | undefined>(false);
  const [isdark, setIsDark] = useState<boolean | undefined>(false);
  const[dataLoading,setDataLoading] = useState<boolean>(false)



  const onSave = () => {

    const finalInput = {
      "attribution": selectedValue ? selectedValue.id : null,
      "length": length && +length,
      "heading": heading && +heading,
      "ship_type": shiptype?.value,
      "moving": moving,
      "dark": isdark,
    }
    handleSave(finalInput)
  }

  const searchOptionsForShips = debounce(async (newInputValue: string) => {
    setDataLoading(true)
    const data = await searchShips(newInputValue);
    setSearchOptions(data);
    setDataLoading(false)
  }, 250);


  useEffect(() => {
    if (ManualAttribution && Object.keys(ManualAttribution).length > 0) {
      const { length, heading, ship_type, moving, dark,attribution } = ManualAttribution;
      const dropdownData = async (att:string | undefined )=>{
        if(att){
          try{
            const data = await searchShips(att)
            setSearchOptions(data);
            setSelectedValue(data[0]); 
          }catch (e){
            console.error(e);
          }
        }
      }
      dropdownData(attribution);
      setLength(length ? length?.toString() : "");
      setHeading(heading ? heading?.toString() : "")
      setShiptype(ship_type ? { value: ship_type, label: ship_type } : null)
      setMoving(moving)
      setIsDark(dark)
    }

    return () => {
      setLength("");
      setHeading("");
      setShiptype(null);
      setMoving(false);
      setIsDark(false);
      setSearchOptions([]);
      setSelectedValue(undefined);
    }

  }, [openManualAttributionModal, ManualAttribution])



  const closeModal = () => {
    handleClose();
  }

  return (
    <Modal
      keepMounted
      open={openManualAttributionModal}
      onClose={closeModal}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      className={styles.attrubutionModalWrap}
    >
      <Box sx={style} className={styles.attrubutionModal}>
        <h3 className={styles.title}>Manual Attribution</h3>
        <Box className={styles.attributionForm}>
          <Box>
            {openManualAttributionModal &&
              <Autocomplete
              loading={dataLoading}
              noOptionsText={"No ships found"}
                slotProps={{
                  paper: {
                    sx: {
                      backgroundColor: "black",
                      color: "white",
                       '& .MuiAutocomplete-noOptions': {
                        color: 'white', // White "No Options" text
                      },
                      '& .MuiAutocomplete-loading':{
                        color: 'white'
                      }
                    }
                  }
                }}
                clearIcon={<ClearIcon fontSize="small" sx={{ color: "white" }} />}
              popupIcon={!dataLoading ? <ArrowDropDownIcon sx={{ color: "white" }} />:<CircularProgress size={20} />}
                options={searchOptions}
                onInputChange={(event, newInputValue,reason) => {
                  if (newInputValue.length > 2 && reason==="input") {
                    searchOptionsForShips(newInputValue);
                  } else {
                    setSearchOptions([])
                  }
                }}
                onChange={(event, newValue) => {
                  setSelectedValue(newValue)
                }}
                value={selectedValue}
                renderOption={(props, { id, label, type, value, key }: any) => {
                  return (
                    <li {...props} key={key}>
                      <span style={{ fontSize: 16 }}>{label}</span>
                    </li>
                  );
                }}
                renderInput={(params) => {
                  return (
                    <TextField {...params}
                      variant="standard"
                      required
                      placeholder="Search by ship name, IMO or MMSI*"
                      InputProps={{
                        ...params.InputProps,
                        style: { color: 'white', borderColor: "white" }, // Text color
                      }}
                      InputLabelProps={{
                        style: { color: 'white' }, // Label color
                      }}
                      sx={{
                        '.MuiInput-underline:before': {
                          borderBottomColor: 'white', // White underline
                        },
                        '.MuiInput-underline:hover:not(.Mui-disabled):before': {
                          borderBottomColor: 'white', // White underline on hover
                        },
                        '.MuiInput-underline:after': {
                          borderBottomColor: 'white', // White underline after focus
                        },
                      }}
                    />
                  );
                }}
              />
            }
          </Box>
          <div style={{ display: "flex" }}>
            <Box
              display="flex"
              flexDirection="column"
              className={styles.attributionInputField}
            >
              <TextField
                autoFocus
                required
                margin="dense"
                id="length"
                name="length"
                label="Length"
                type="number"
                fullWidth
                variant="standard"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                InputLabelProps={{
                  classes: {
                    root: styles.modalLabel,
                    focused: styles.modalLabelFocused,
                  },
                }}
                InputProps={{
                  inputProps: { min: 0 },
                  classes: {
                    root: styles.modalInput,
                    input: styles.test,
                    focused: styles.modalInputFocused,
                  },
                }}
                className={styles.modalTextFild}
              />
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              className={styles.attributionInputField}
            >
              <TextField
                autoFocus
                required
                margin="dense"
                id="heading"
                name="heading"
                label="Heading"
                type="number"
                fullWidth
                value={heading}
                onChange={(e) => setHeading(e.target.value)}
                variant="standard"
                InputLabelProps={{
                  classes: {
                    root: styles.modalLabel,
                    focused: styles.modalLabelFocused,
                  },
                }}
                InputProps={{
                  inputProps: { min: 0 },
                  classes: {
                    root: styles.modalInput,
                    input: styles.test,
                    focused: styles.modalInputFocused,
                  },
                }}
                className={styles.modalTextFild}
              />
            </Box>
          </div>
          <Box
            display="flex"
            flexDirection="column"
            className={styles.attributionInputField}
          >
            <Autocomplete
              className={styles.noOption}
              classes={{ inputRoot: styles.noOption }}
              slotProps={{
                paper: {
                  sx: {
                    backgroundColor: "black",
                    color: "white"
                  }
                }
              }}
              clearIcon={<ClearIcon fontSize="small" sx={{ color: "white" }} />}
              popupIcon={<ArrowDropDownIcon sx={{ color: "white" }} />}
              value={shiptype}
              onChange={(event, newValue) => {
                setShiptype(newValue);
              }}
              disablePortal
              options={shipTypes}
              renderInput={(params) => <TextField {...params}
                variant="standard"
                label="Ship Type"
                InputProps={{
                  ...params.InputProps,
                  style: { color: 'white', borderColor: "white" }, // Text color
                }}
                InputLabelProps={{
                  style: { color: 'white' }, // Label color
                }}
                sx={{
                  '.MuiInput-underline:before': {
                    borderBottomColor: 'white', // White underline
                  },
                  '.MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottomColor: 'white', // White underline on hover
                  },
                  '.MuiInput-underline:after': {
                    borderBottomColor: 'white', // White underline after focus
                  },
                }}

              />}
            />
          </Box>
          <div style={{ display: "flex" }}>
            <Box className={styles.attributionInputField}>
              <CustomFormControlLabel
                control={
                  <Checkbox
                    checked={moving}
                    onChange={(e) => setMoving(e.target.checked)}
                    className={styles.checkboxMain}
                    classes={{ checked: styles.checkboxChecked }}
                    defaultChecked />}
                label="Is Moving" />

            </Box>
            <Box className={styles.attributionInputField}>
              <CustomFormControlLabel
                control={
                  <Checkbox
                    checked={isdark}
                    onChange={(e) => setIsDark(e.target.checked)}
                    className={styles.checkboxMain}
                    classes={{ checked: styles.checkboxChecked }}
                    defaultChecked />}
                label="Is Dark" />

            </Box>
          </div>
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={1}
          className={styles.btnWrapper}
        >
          <Button
            variant="outlined"
            onClick={closeModal}
            className={styles.linkBtn}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => onSave()}
            className={styles.saveBtn}
            disabled={!selectedValue || !length || !heading  }
          >
            Save
            {activeCall &&
              <CircularProgress size={20} sx={{ padding: "2px" }} />}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalAttribution;
