import { Box } from "@mui/material";
import Header from "../ShipTools/Header/Header";
import styles from "./Modal.module.scss";

interface ModalProps {
  onMinimizeClick: any;
  headerTitle: string;
  handleClose: any;
  onClickMinimize: any;
  children: any;
  className?: any;
  showMinimize?: boolean;
  showExport?: boolean;
  onExportClick?: any;
  isDisabledExport?: boolean;
}

const Modal = ({
  onMinimizeClick,
  headerTitle,
  handleClose,
  onClickMinimize,
  children,
  className,
  showMinimize,
  showExport,
  isDisabledExport,
  onExportClick,
}: ModalProps) => {
  return (
    <Box
      className={`${styles.shipToolsModal} ${className} ${
        onMinimizeClick && styles.minimizeModal
      }`}
    >
      <Header
        headerTitle={headerTitle}
        handleClose={handleClose}
        onClickMinimize={onClickMinimize}
        showMinimize={showMinimize}
        showExport={showExport}
        onExportClick={onExportClick}
        isDisabledExport={isDisabledExport}
      />
      <Box className={styles.shipToolsBody}>{children}</Box>
    </Box>
  );
};

export default Modal;
