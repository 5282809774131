import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { getAuth, signOut } from "firebase/auth";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { get } from "lodash";
import { RootState } from "../../redux/store";
import { setIsRecent } from "../HomePanel/HomePanel.store";
import {
  setNotifications,
  updateNotificationData,
} from "./Notification/Notification.store";
import { setIsTabsDeleted } from "../../common/Common.store";
import { addSharedData, setIsShared } from "../../pages/Shared/Shared.store";
import {
  deleteTabData,
  fetchTabsData,
  setIsDashboard,
  setIsDashboardClick,
  setIsPrevTabId,
  setIsSaveMap,
  setIsTabDelete,
  setIsTabSwitch,
  setPrevTabId,
  setSelectedTabId,
  setTabValue,
  setTabs,
} from "./Header.store";
import Notification from "./Notification/Notification";
import useFireBaseAuth, { db } from "../../context/useFireBaseAuth";
import ListenForOutsideClicks from "../../common/Listen";
import NotificationDialog, {
  LatestNotificationType,
} from "./Notification/Dialog/NotificationDialog";
import { setChangeSaved } from "../../pages/Theia/Theia.store";
import {
  setIsDeleteTabUpdated,
  setIsSwitchTabUpdated,
} from "../../pages/Theia/MainMap.store";
import DeleteDialog from "../Dialog/DeleteDialog";
import { Alert, AppBar, Badge, Box, Tab, Tabs } from "@mui/material";
import {
  GridView,
  NotificationsNoneOutlined,
  Close,
} from "@mui/icons-material";
import UserProfile from "./UserProfile/UserProfile";
import styles from "./Header.module.scss";

const getFirstNotification = (notificationsData: any) => {
  const sortedNotificationsData: any = Object.entries(notificationsData).sort(
    ([, a], [, b]) =>
      (b as any).createdAt.seconds - (a as any).createdAt.seconds
  );
  return sortedNotificationsData[0][1];
};

const getTimeDifference = (createdAtTimestamp: any) => {
  const currentTimestamp = Math.floor(new Date().getTime() / 1000);
  const createdAtSeconds = createdAtTimestamp.seconds;
  const timeDifference = currentTimestamp - createdAtSeconds;
  return timeDifference <= 120;
};

const Header: FC = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();

  const {
    isDashboard,
    tabValue,
    tabs,
    isTabFetch,
    selectedTabId: prevTabIDtoSet,
    isDashboardClick,
  } = useSelector((state: RootState) => state.header);
  const { currentUser } = useFireBaseAuth();
  const { changesSaved } = useSelector((state: RootState) => state.theia);

  const { mapIntervalId } = useSelector(
    (state: RootState) => state.globalTheiaState
  );
  const { isUserFetch, users, isTabsDeleted, deletedTabsIds, allProjects } =
    useSelector((state: RootState) => state.common);

  const { notifications, loading } = useSelector(
    (state: RootState) => state.notification
  );
  const { isSwitchTabUpdated, isDeleteTabUpdated } = useSelector(
    (state: RootState) => state.mainMap
  );
  const userId = currentUser.uid;

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [listening, setListening] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);
  const [latestNotification, setLatestNotification] =
    useState<LatestNotificationType>();
  const [deletedTabData, setDeletedTabData] = useState({
    event: "" as unknown as MouseEvent<HTMLButtonElement, MouseEvent>,
    tabValue: 0 as number,
    tabId: "" as string,
  });
  const [isDeleteTab, setIsDeleteTab] = useState<boolean>(false);
  const [isTabChange, setIsTabChange] = useState<boolean>(false);
  const [currentTabValue, setCurrentTabValue] = useState<number>();
  const [newTabId, setNewTabId] = useState<any>("");
  const [isSingleTab, setIsSingleTab] = useState(false);
  const [deleteTabIdToSet, setDeleteTabIdToSet] = useState("");
  const menuRef = useRef(null);

  useEffect(() => {
    if (!userId) return;
    if (!isTabFetch) dispatch(fetchTabsData(userId));
  }, [dispatch, userId, isTabFetch, isUserFetch]);

  useEffect(() => {
    const handleOutsideClick = ListenForOutsideClicks({
      listening,
      setListening,
      menuRef,
      handleOutsideClick: () => {
        setIsNotificationOpen(false);
      },
    });

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [listening, setListening, menuRef, setIsNotificationOpen]);

  useEffect(() => {
    if (!loading && userId) {
      const notificationsCollectionRef = collection(
        db,
        "users",
        userId,
        "notifications"
      );

      let notificationsData = {} as any;
      const unsubscribe = onSnapshot(
        query(
          notificationsCollectionRef,
          orderBy("createdAt", "desc"),
          limit(5)
        ),
        (querySnapshot) => {
          querySnapshot.docs.map(
            (doc) =>
              (notificationsData[doc.id] = {
                ...doc.data(),
                notificationId: doc.id,
              })
          );
          if (Object.keys(notificationsData).length > 0) {
            const firstNotificationWorkStationName =
              getFirstNotification(notificationsData);
            const createdAtTimestamp =
              firstNotificationWorkStationName.createdAt;
            if (userId && createdAtTimestamp) {
              const isWithinTwoMinutesNotification =
                getTimeDifference(createdAtTimestamp);
              if (
                !firstNotificationWorkStationName.isMarkAsRead &&
                isWithinTwoMinutesNotification
              ) {
                setLatestNotification(firstNotificationWorkStationName);
                setIsNotificationModalOpen(true);
              }
            }
            dispatch(
              setNotifications(JSON.parse(JSON.stringify(notificationsData)))
            );
          }
        },
        (error) => {
          console.error("Error getting documents: ", error);
        }
      );

      return () => unsubscribe();
    }
  }, [userId, dispatch, loading]);

  useEffect(() => {
    if (isTabsDeleted) {
      const updatedTabsData = { ...tabs };
      deletedTabsIds.forEach((tabId) => {
        if (updatedTabsData.hasOwnProperty(tabId)) {
          delete updatedTabsData[tabId];
        }
      });
      dispatch(setTabs(updatedTabsData));
      dispatch(setIsTabsDeleted(false));
    }
  }, [isTabsDeleted, userId, dispatch, deletedTabsIds, tabs]);

  const handleChangeSaved = useCallback(
    (tabId: string) => {
      dispatch(setIsRecent(false));
      dispatch(setIsShared(false));
      dispatch(setSelectedTabId(tabId));
      dispatch(setChangeSaved(true));
    },
    [dispatch]
  );

  const handleChange = (newValue: number) => {
    if (changesSaved) dispatch(setTabValue(newValue));
  };

  // const addTab = () => {
  //   if (Object.keys(tabs).length > 0 && !tabs.msg) {
  //     const lastTab = Object.keys(tabs).length + 1;
  //     const tabValue =
  //       Object.values(tabs)[Object.keys(tabs).length - 1].value + 1;
  //     const newTab = {
  //       userId,
  //       tabName: `Untitled Work Station ${lastTab}`,
  //       tabValue,
  //     };
  //     dispatch(addTabData(newTab));
  //     dispatch(setTabValue(tabValue));
  //     dispatch(setIsDashboard(false));
  //   } else {
  //     const newTabId = 1;
  //     const newTab = {
  //       userId,
  //       tabName: `Untitled Work Station 1`,
  //       tabValue: newTabId,
  //     };
  //     dispatch(addTabData(newTab));
  //     dispatch(setTabValue(newTabId));
  //     dispatch(setIsDashboard(false));
  //   }
  // };

  const handleDeleteTab = async (
    tabId: any,
    shouldSaveChanges: boolean = true
  ) => {
    const tabList = Object.values(tabs);
    const tabIDs = Object.keys(tabs);
    const tabIndex = Object.entries(tabs).findIndex(
      ([key, value]) => key === tabId
    );
    if (tabList.length > 1) {
      let curValue = tabValue;
      let tabIdToSet: any = tabIDs[curValue];
      if (tabIndex > 0) {
        curValue =
          tabList[tabIndex - 1]?.value !== undefined
            ? tabList[tabIndex - 1]?.value
            : curValue;
        tabIdToSet = tabIDs[tabIndex - 1];
      } else if (tabIndex === 0) {
        curValue =
          tabList[tabIndex + 1]?.value !== undefined
            ? tabList[tabIndex + 1]?.value
            : curValue;
        tabIdToSet = tabIDs[tabIndex + 1];
      }

      dispatch(setTabValue(curValue));
      if (!changesSaved && shouldSaveChanges) {
        dispatch(setPrevTabId(prevTabIDtoSet));
        dispatch(setIsSaveMap(true));
        dispatch(setIsTabDelete(true));
      } else {
        dispatch(setSelectedTabId(tabIdToSet));
        const response = await dispatch(deleteTabData({ userId, tabId }));
        if (get(response, "payload.msg") === "Tab Deleted") {
          dispatch(setChangeSaved(shouldSaveChanges));
        }
      }
      setDeleteTabIdToSet(tabIdToSet);
    } else if (tabList.length === 1) {
      if (!changesSaved && shouldSaveChanges) {
        dispatch(setPrevTabId(prevTabIDtoSet));
        dispatch(setIsSaveMap(true));
        dispatch(setIsDashboardClick(true));
        dispatch(setIsTabDelete(true));
      } else {
        dispatch(setSelectedTabId(""));
        const response = await dispatch(deleteTabData({ userId, tabId }));
        if (get(response, "payload.msg") === "Tab Deleted") {
          dispatch(setIsDashboard(true));
          mapIntervalId && clearInterval(mapIntervalId);
        }
      }
      setIsSingleTab(true);
    }
    if (!changesSaved && shouldSaveChanges) {
      dispatch(setChangeSaved(shouldSaveChanges));
    }
  };

  const getDeleteTabResponse = useCallback(
    async ({ tabId, tabIdToSet, isSingleTab }: any) => {
      if (userId && tabId) {
        const response = await dispatch(deleteTabData({ userId, tabId }));
        if (get(response, "payload.msg") === "Tab Deleted") {
          if (!isSingleTab) dispatch(setSelectedTabId(tabIdToSet));
          else {
            dispatch(setIsDashboard(true));
            mapIntervalId && clearInterval(mapIntervalId);
          }
        }
        dispatch(setIsDeleteTabUpdated(false));
        dispatch(setIsTabDelete(false));
      }
    },
    [dispatch, mapIntervalId, userId]
  );

  const deleteTab = async () => {
    deletedTabData.event.stopPropagation();
    dispatch(setTabValue(tabValue));

    if (!changesSaved) {
      handleDeleteTab(deletedTabData.tabId);
    } else handleDeleteTab(deletedTabData.tabId);
  };

  const handleSaveTabChanges = useCallback(async () => {
    dispatch(setTabValue(currentTabValue));
    handleChangeSaved(newTabId);
    dispatch(setIsPrevTabId(true));
    if (!isDashboard) dispatch(setIsDashboard(false));
    dispatch(setIsSwitchTabUpdated(false));
  }, [currentTabValue, dispatch, handleChangeSaved, newTabId, isDashboard]);

  useEffect(() => {
    if (isSwitchTabUpdated) {
      handleSaveTabChanges();
      dispatch(setIsTabSwitch(false));
    } else if (isDeleteTabUpdated) {
      if (!isSingleTab) {
        getDeleteTabResponse({
          tabId: deletedTabData.tabId,
          tabIdToSet: deleteTabIdToSet,
        });
      } else {
        getDeleteTabResponse({
          tabId: deletedTabData.tabId,
          isSingleTab: true,
        });
      }
    }
  }, [
    dispatch,
    handleSaveTabChanges,
    isSwitchTabUpdated,
    isDeleteTabUpdated,
    isSingleTab,
    getDeleteTabResponse,
    deletedTabData.tabId,
    deleteTabIdToSet,
  ]);

  const saveTabChanges = async () => {
    dispatch(setIsSaveMap(true));
    dispatch(setIsTabSwitch(true));
  };

  const handleTabChanges = async () => {
    dispatch(setChangeSaved(true));
    dispatch(setTabValue(currentTabValue));
    handleChangeSaved(newTabId);
    dispatch(setPrevTabId(""));
    if (isDashboardClick) {
      dispatch(setIsDashboard(true));
      dispatch(setIsDashboardClick(false));
    } else {
      dispatch(setIsDashboard(false));
    }
  };

  const addShared = (workStationName: string, notificationId: string) => {
    const sharedData = {
      userId,
      workStationName,
    };
    const notificationData = {
      userId,
      notificationId,
      isApprove: true,
      isMarkAsRead: true,
    };
    dispatch(addSharedData(sharedData));
    dispatch(updateNotificationData(notificationData));
    dispatch(setIsShared(true));
    dispatch(setIsDashboard(true));
    dispatch(setIsRecent(false));
  };

  const rejectShared = (notificationId: string) => {
    const notificationData = {
      userId,
      notificationId,
      isApprove: false,
      isMarkAsRead: true,
    };
    dispatch(updateNotificationData(notificationData));
  };

  const countUnreadNotifications = useMemo(() => {
    const unreadNotifications = Object.values(notifications).filter(
      (notification) => !notification.isMarkAsRead
    );

    return unreadNotifications.length;
  }, [notifications]);

  return (
    <AppBar
      position="sticky"
      ref={menuRef}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        gap={4}
        className={styles.headerWrapper}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <img src="/images/logo.png" alt="logo" />
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%" gap={3}>
          <Box
            sx={{
              maxWidth: {
                xs: 320,
                sm: 450,
                md: 700,
                lg: 1100,
                xl: 1500,
              },
            }}
            display="flex"
            alignItems="center"
            gap={1}
            flex={1}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={` ${isDashboard && styles.activeTab} ${
                styles.tabIconWrap
              }`}
              onClick={() => {
                if (
                  changesSaved ||
                  allProjects[tabs[prevTabIDtoSet]?.folderId]?.workStations[
                    tabs[prevTabIDtoSet]?.workStationId
                  ]?.mode !== "cloud"
                ) {
                  dispatch(setIsDashboard(true));
                  dispatch(setIsRecent(false));
                  dispatch(setIsShared(false));
                  !changesSaved && dispatch(setPrevTabId(prevTabIDtoSet));
                  mapIntervalId && clearInterval(mapIntervalId);
                } else {
                  setIsTabChange(true);
                  dispatch(setIsDashboardClick(true));
                }
              }}
            >
              <GridView className={styles.tabIcon} />
            </Box>
            <Tabs
              value={tabValue}
              onChange={(e, val: any) => {
                setCurrentTabValue(val);
                handleChange(val);
              }}
              className={styles.headerTabs}
              classes={{
                indicator: styles.tabIndicator,
                flexContainer: styles.tabContainer,
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              {Object.keys(tabs)?.map((data: string) => {
                const tab = tabs[data];
                return (
                  <Tab
                    key={`tab-list-${data}`}
                    value={tab.value}
                    label={tab.name}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      if (changesSaved) {
                        handleChangeSaved(data);
                        dispatch(setIsDashboard(false));
                        setNewTabId(data);
                      } else if (
                        allProjects[tabs[prevTabIDtoSet]?.folderId]
                          ?.workStations[tabs[prevTabIDtoSet]?.workStationId]
                          ?.mode !== "cloud"
                      ) {
                        handleChangeSaved(data);
                        dispatch(setIsDashboard(false));
                        setNewTabId(data);
                        dispatch(setTabValue(tab.value));
                      } else if (tabValue !== tab.value) {
                        dispatch(setPrevTabId(prevTabIDtoSet));
                        setNewTabId(data);
                        if (!changesSaved) setIsTabChange(true);
                        dispatch(setChangeSaved(true));
                      }
                    }}
                    icon={
                      <Close
                        id={`${tab.id}`}
                        className={styles.headerTabIcon}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (tabValue !== tab.value) {
                            setDeletedTabData({
                              event: e as unknown as MouseEvent<
                                HTMLButtonElement,
                                MouseEvent
                              >,
                              tabValue: tab.value,
                              tabId: data,
                            });
                            handleDeleteTab(data, false);
                          } else {
                            setDeletedTabData({
                              event: e as unknown as MouseEvent<
                                HTMLButtonElement,
                                MouseEvent
                              >,
                              tabValue: tab.value,
                              tabId: data,
                            });
                            changesSaved ||
                            allProjects[tab?.folderId]?.workStations[
                              tab?.workStationId
                            ]?.mode !== "cloud"
                              ? handleDeleteTab(data, false)
                              : setIsDeleteTab(true);
                            setIsTabChange(false);
                          }
                        }}
                      />
                    }
                    className={styles.headerTab}
                    classes={{
                      selected:
                        prevTabIDtoSet === data && !isDashboard
                          ? styles.activeTab
                          : "",
                    }}
                  />
                );
              })}
            </Tabs>
            {/* {Object.keys(tabs).length > 0 && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                className={styles.tabIconWrap}
                onClick={addTab}
              >
                <Add className={styles.tabIcon} />
              </Box>
            )} */}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={3}
          >
            {/* <div onClick={getUserData}>Get User Data</div> */}

            <div className={styles.notificationIcon}>
              <Badge
                color="secondary"
                badgeContent={countUnreadNotifications}
                onClick={() => setIsNotificationOpen(!isNotificationOpen)}
              >
                <NotificationsNoneOutlined className={styles.icon} />
              </Badge>
              {isNotificationOpen && (
                <Notification
                  setOpen={setIsNotificationOpen}
                  notificationData={notifications}
                  addShared={addShared}
                  rejectShared={rejectShared}
                  users={users}
                  userId={userId}
                />
              )}
            </div>
            <div>
              <UserProfile />
            </div>
          </Box>
        </Box>
      </Box>
      {isNotificationModalOpen && (
        <NotificationDialog
          open={isNotificationModalOpen}
          setOpen={setIsNotificationModalOpen}
          latestNotification={latestNotification}
          users={users}
          addShared={addShared}
          rejectShared={rejectShared}
        />
      )}
      {isDeleteTab && (
        <DeleteDialog
          open={isDeleteTab}
          setOpen={setIsDeleteTab}
          deleteFolderWorkStation={deleteTab}
          deletedTabId={deletedTabData.tabId}
          handleCancel={handleDeleteTab}
          deleteButtonName="Save"
          cancelButtonName="Don't save"
        />
      )}
      {isTabChange && (
        <DeleteDialog
          open={isTabChange}
          setOpen={setIsTabChange}
          deleteFolderWorkStation={saveTabChanges}
          handleSave={handleTabChanges}
          deleteButtonName="Save"
          cancelButtonName="Don't save"
          displayModalTitle="Are you sure you want to switch tab?"
          displayModalContent="This process cannot be undone."
        />
      )}
    </AppBar>
  );
};

export default Header;
