import React from "react";

const SatelliteIcon = ({ size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2219_8088)">
      <path
        d="M-1.67255e-07 14.1737L0.498388 14.6946L3.30953 17.4798L3.83113 18L4.33024 17.479L8.52338 13.3129L9 13.7889L13.7837 9.01123L13.307 8.53592L17.5016 4.36976L18 3.84881L17.5016 3.32786L14.6673 0.520227L14.1689 -6.19341e-07L9.24956 4.91245C8.0453 4.46613 6.63066 4.72044 5.66726 5.68265C5.00999 6.33981 4.69225 7.21652 4.69225 8.08308C4.69852 8.48578 4.77537 8.88426 4.91931 9.26047L0.498388 13.6527L-1.67255e-07 14.1737ZM2.03998 14.1737L5.71296 10.5053L7.50411 12.2949L3.83113 15.9626L2.03998 14.1737ZM6.12067 8.08308C6.12067 7.59039 6.30783 7.08103 6.68725 6.70136C6.86714 6.52098 7.08093 6.37785 7.31635 6.28019C7.55176 6.18252 7.80417 6.13225 8.05908 6.13225C8.314 6.13225 8.56641 6.18252 8.80182 6.28019C9.03724 6.37785 9.25102 6.52098 9.43092 6.70136L11.5166 8.80763L11.7205 9.01123L9 11.7283L6.80114 9.53218L6.68797 9.44161C6.63719 9.39089 6.59657 9.33655 6.55159 9.28294L6.43842 9.14672C6.22586 8.82792 6.12067 8.45115 6.12067 8.08308ZM10.4966 5.72829L14.1689 2.05989L15.96 3.84881L12.287 7.51721L10.4966 5.72829Z"
        fill="currentColor"
      />
      <path
        d="M11.5 19.6802V18.6802C13.45 18.6802 15.101 18.0045 16.453 16.6532C17.805 15.3019 18.4807 13.6509 18.48 11.7002H19.48C19.48 12.8089 19.2714 13.8482 18.854 14.8182C18.4367 15.7882 17.8687 16.6325 17.15 17.3512C16.4314 18.0699 15.587 18.6379 14.617 19.0552C13.647 19.4725 12.3587 19.6802 11.25 19.6802M11.499 16.2972V15.2972C12.499 15.2972 13.3484 14.9479 14.047 14.2492C14.7457 13.5505 15.095 12.7012 15.095 11.7012H16.095C16.095 12.9692 15.646 14.0522 14.748 14.9502C13.85 15.8482 12.5173 16.2979 11.25 16.2972"
        fill="currentColor"
      />
      <path
        d="M11.5 19.6802V18.6802C13.45 18.6802 15.101 18.0045 16.453 16.6532C17.805 15.3019 18.4807 13.6509 18.48 11.7002H19.48C19.48 12.8089 19.2714 13.8482 18.854 14.8182C18.4367 15.7882 17.8687 16.6325 17.15 17.3512C16.4314 18.0699 15.587 18.6379 14.617 19.0552C13.647 19.4725 12.3587 19.6802 11.25 19.6802M11.499 16.2972V15.2972C12.499 15.2972 13.3484 14.9479 14.047 14.2492C14.7457 13.5505 15.095 12.7012 15.095 11.7012H16.095C16.095 12.9692 15.646 14.0522 14.748 14.9502C13.85 15.8482 12.5173 16.2979 11.25 16.2972"
        stroke="currentColor"
        stroke-width="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_2219_8088">
        <rect width={size} height={size} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SatelliteIcon;
