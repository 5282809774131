import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { defaultImplementation } from "../utils/utilis";
import { User, getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import {
  DocumentData,
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { firebaseConfig } from "../App";
import { isEmpty } from "lodash";
import { useSnackbar } from "./SnackbarContext";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";

interface FirebaseAuthContextProps {
  handleSetCurrentUser: (user: DocumentData) => void;
  currentUser: DocumentData;
  isInternalUser: () => boolean;
  setUserData: Dispatch<SetStateAction<DocumentData>>;
  handleUserLogOut: () => void;
  handleAuthStateChange: () => void;
  getCurrentUserLoggedIn: () => User | null;
}

const FireBaseAuthContext = createContext<FirebaseAuthContextProps>({
  handleSetCurrentUser: defaultImplementation,
  currentUser: {},
  isInternalUser: defaultImplementation,
  setUserData: defaultImplementation,
  handleUserLogOut: defaultImplementation,
  handleAuthStateChange: defaultImplementation,
  getCurrentUserLoggedIn: defaultImplementation,
});

const useFireBaseAuth = () => {
  const context = useContext<FirebaseAuthContextProps>(FireBaseAuthContext);
  if (!context) {
    throw new Error("useFireBaseAuth must be used with a FireBaseAuthProvider");
  }
  return context;
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const FirebaseFunctions = getFunctions(app);

export const FireBaseAuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const getCurrentUserLoggedIn = (): User | null => {
    const auth = getAuth();
    const isloggedIn = auth.currentUser;
    return isloggedIn;
  };

  const currentUserIsLoggedIn = getCurrentUserLoggedIn();

  const [userData, setUserData] = useState<DocumentData>({});

  const isMFAVerified = localStorage.getItem("isMFAVerified");

  const { setNewSnackBar } = useSnackbar();

  useEffect(() => {
    const tokenInterval = setInterval(() => {
      if (currentUserIsLoggedIn) {
        refreshToken();
      }
    }, 5000);

    const refreshToken = async () => {
      if (currentUserIsLoggedIn !== null) {
        const token = await currentUserIsLoggedIn.getIdToken();
        localStorage.setItem("token", token);
      }
    };
    return () => clearInterval(tokenInterval);
  }, [currentUserIsLoggedIn]);

  const handleSetCurrentUser = async (user: any) => {
    const auth = getAuth();
    if (user) {
      const { uid, email } = user;
      const docRef = doc(db, "users", uid);
      const docSnap = await getDoc(docRef);
      const userData = docSnap.data() || {};
      setUserData({ ...userData, uid });
      const token = await user.getIdToken();
      if (uid && (!userData || isEmpty(userData))) {
        // It means this is a new user, so it doesn't have any profile data yet
        // So we create a profile for this user on firebase
        let firstName = "";
        let lastName = "";
        await setDoc(doc(db, "users", uid), {
          email,
          role: "new_user",
          firstName,
          lastName,
          isNewUser: true,
        });
      }
      // We are checking the user's role
      // As you need to be a customer, co-worker, admin, or super-user to view the app
      const role = userData ? userData.role : "";
      if (
        role === "super_user" ||
        role === "admin" ||
        role === "co-worker" ||
        role === "theia_customer" ||
        (role === "temporary_theia_user" &&
          userData.expiry_date.seconds > Date.now() / 1000)
      ) {
        localStorage.setItem("token", token);
      } else {
        if (token) {
          signOut(auth).then(() => {
            setNewSnackBar({
              message: "Email info@synmax.com For Access",
              severity: "error",
            });
          });
        } else {
          setNewSnackBar({
            message: "Email info@synmax.com For Access",
            severity: "error",
          });
        }
      }
    }
  };

  const isInternalUser = () => {
    const superRoles = ["super_user", "admin", "co-worker"];
    return superRoles.includes(userData?.role || "");
  };

  const handleUserLogOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        window.location.href = "/login";
        localStorage.setItem("isMFAVerified", "false");
      })
      .catch((error) => {
        console.log(error, "err");
      });
  };

  useEffect(() => {
    const auth = getAuth();
    if (
      !userData ||
      !userData.uid ||
      !userData?.role ||
      userData?.role === "new_user" ||
      isMFAVerified === "false"
    )
      return;
    const userCollectionRef = collection(db, "users");
    const userDocRef = doc(userCollectionRef, userData.uid);
    const userUnsubscribe = onSnapshot(userDocRef, (snapshot) => {
      const userDocData = snapshot.data();
      if (!userDocData) return;
      if (!userDocData?.secret) {
        signOut(auth)
          .then(() => {
            setUserData({});
            window.location.href = "/login";
          })
          .catch((error) => {
            console.log(error, "err");
          });
      }
    });
    return () => {
      userUnsubscribe();
    };
  }, [userData]);

  const handleAuthStateChange = () => {
    const auth = getAuth();
    //extra code to check build
    // This function from  firebase is called when our authentication state changes(eg: logged in/ logged out)
    onAuthStateChanged(auth, handleSetCurrentUser);
  };

  return (
    <FireBaseAuthContext.Provider
      value={{
        handleSetCurrentUser,
        currentUser: userData,
        isInternalUser,
        setUserData,
        handleUserLogOut,
        handleAuthStateChange,
        getCurrentUserLoggedIn,
      }}
    >
      {children}
    </FireBaseAuthContext.Provider>
  );
};

export default useFireBaseAuth;
