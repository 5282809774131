import { Box } from "@mui/material";
import { Clear, Minimize, FileDownload } from "@mui/icons-material";
import styles from "./Header.module.scss";

interface HeaderProps {
  headerTitle: string;
  handleClose?: any;
  onClickMinimize?: any;
  showMinimize?: boolean;
  showExport?: boolean;
  onExportClick?: any;
  isDisabledExport?: boolean;
}

const Header = ({
  headerTitle,
  handleClose,
  onClickMinimize,
  showMinimize = true,
  showExport = false,
  onExportClick,
  isDisabledExport,
}: HeaderProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={styles.modelHeadWrap}
    >
      <h6 className={styles.modelHead}>{headerTitle}</h6>
      <Box display="flex" alignItems="center" gap={3}>
        {showExport && (
          <FileDownload
            fontSize="small"
            className={
              isDisabledExport ? styles.headerIconDisabled : styles.headerIcon
            }
            onClick={isDisabledExport ? null : onExportClick}
          />
        )}
        {showMinimize && (
          <Minimize
            fontSize="small"
            className={styles.headerIcon}
            onClick={onClickMinimize}
          />
        )}
        <Clear
          fontSize="small"
          onClick={handleClose}
          className={styles.headerIcon}
        />
      </Box>
    </Box>
  );
};

export default Header;
