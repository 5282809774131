import { useState, useEffect } from "react";
import { AllDetailsTabsAttribution } from "../Attribution";
import { AttributionInterface } from "../Attribution";
import { getShipAttributes } from "../../../RenderShips/apiCalls";

const useAttribution = ({ details, type, updatedAttribution }: { details: AllDetailsTabsAttribution, type: string, updatedAttribution:any | null | undefined }) => {

    const [attributes, setAttributes] = useState<AttributionInterface | null>(null)



    useEffect(() => {
        const callAttributes = async (object_id: number) => {

            const data = await getShipAttributes(object_id);
            const attributesData = data[type];
            setAttributes(attributesData)
        }

        if (details?.object_id){
            callAttributes(details?.object_id)
        }

    }, [details, type])

    useEffect(() => {

        setAttributes(updatedAttribution)

    }, [updatedAttribution])

    return { attributes, setAttributes }
}


export default useAttribution;