import { createContext, useContext, useEffect, useState } from "react";
import { GeoJsonLayer } from "@deck.gl/layers/typed";
import useStaticGeoJson from "../pages/Theia/RenderShips/hooks/useStaticGeoJson";

interface ILayerProviderProps {
  children: React.ReactNode;
}

interface ILayerContext {
  viewingLayers: GeoJsonLayer[];
}

const LayersContext = createContext<ILayerContext>({
  viewingLayers: [],
});

const useLayers = () => {
  const context = useContext<ILayerContext>(LayersContext);
  if (!context) {
    throw new Error("useLayers must be used with a LayersProvider");
  }
  return context;
};

export const LayersProvider = ({ children }: ILayerProviderProps) => {
  const [viewingLayers, setViewingLayers] = useState<GeoJsonLayer[]>([]);
  const { staticLayers } = useStaticGeoJson();

  useEffect(() => {
    // Order does matter, last index is the highest layer on top of the map
    setViewingLayers([...staticLayers]);
  }, [staticLayers]);

  return (
    <LayersContext.Provider
      value={{
        viewingLayers,
      }}
    >
      {children}
    </LayersContext.Provider>
  );
};

export default useLayers;
