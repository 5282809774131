import { SnackbarProvider } from "../../context/SnackbarContext";
import { FireBaseAuthProvider } from "../../context/useFireBaseAuth";

interface IGlobalProviderLayers {
  children: React.ReactNode;
}

const GlobalProviderLayers = ({ children }: IGlobalProviderLayers) => {
  return (
    <SnackbarProvider>
      <FireBaseAuthProvider>{children}</FireBaseAuthProvider>
    </SnackbarProvider>
  );
};

export default GlobalProviderLayers;
